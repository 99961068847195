const UnitsVeľkýKrtíš = {
  Čelovce: [
    { lat: 48.1526545, lng: 19.1207797 },
    { lat: 48.1525916, lng: 19.1208241 },
    { lat: 48.1525061, lng: 19.1218699 },
    { lat: 48.1526099, lng: 19.1226131 },
    { lat: 48.1525962, lng: 19.1229002 },
    { lat: 48.1526917, lng: 19.1231007 },
    { lat: 48.1526729, lng: 19.12331 },
    { lat: 48.152812, lng: 19.1235653 },
    { lat: 48.1527347, lng: 19.1237995 },
    { lat: 48.1527449, lng: 19.1239371 },
    { lat: 48.1528025, lng: 19.124074 },
    { lat: 48.1530372, lng: 19.1256132 },
    { lat: 48.1529947, lng: 19.1260724 },
    { lat: 48.1530473, lng: 19.1269632 },
    { lat: 48.1530031, lng: 19.127347 },
    { lat: 48.1531818, lng: 19.1277842 },
    { lat: 48.1531795, lng: 19.1279398 },
    { lat: 48.1533366, lng: 19.1280872 },
    { lat: 48.1535673, lng: 19.128889 },
    { lat: 48.1539956, lng: 19.1299247 },
    { lat: 48.1540184, lng: 19.1303388 },
    { lat: 48.1541455, lng: 19.130785 },
    { lat: 48.1540987, lng: 19.1311761 },
    { lat: 48.1541365, lng: 19.1315087 },
    { lat: 48.1545997, lng: 19.1326988 },
    { lat: 48.1551781, lng: 19.1329517 },
    { lat: 48.1549378, lng: 19.1344384 },
    { lat: 48.1553369, lng: 19.1347763 },
    { lat: 48.1560622, lng: 19.1393951 },
    { lat: 48.1554744, lng: 19.1427839 },
    { lat: 48.1550987, lng: 19.1442912 },
    { lat: 48.156101, lng: 19.1449494 },
    { lat: 48.1564981, lng: 19.1451393 },
    { lat: 48.1565496, lng: 19.1452434 },
    { lat: 48.1569083, lng: 19.1454732 },
    { lat: 48.1570178, lng: 19.1459536 },
    { lat: 48.1573692, lng: 19.1466916 },
    { lat: 48.1572295, lng: 19.1467622 },
    { lat: 48.1574696, lng: 19.1486195 },
    { lat: 48.1576478, lng: 19.1486442 },
    { lat: 48.1576382, lng: 19.1505607 },
    { lat: 48.1583728, lng: 19.1510242 },
    { lat: 48.1599571, lng: 19.1512157 },
    { lat: 48.1606188, lng: 19.1520524 },
    { lat: 48.1644037, lng: 19.1536475 },
    { lat: 48.1658085, lng: 19.1543065 },
    { lat: 48.1703709, lng: 19.158621 },
    { lat: 48.1719616, lng: 19.1602127 },
    { lat: 48.1764105, lng: 19.1644236 },
    { lat: 48.1769891, lng: 19.164908 },
    { lat: 48.1770599, lng: 19.1651304 },
    { lat: 48.1770451, lng: 19.1654224 },
    { lat: 48.1772242, lng: 19.1658748 },
    { lat: 48.177394, lng: 19.1658888 },
    { lat: 48.177402, lng: 19.1660493 },
    { lat: 48.1775035, lng: 19.1662288 },
    { lat: 48.1776869, lng: 19.1662932 },
    { lat: 48.1777147, lng: 19.1664445 },
    { lat: 48.1778747, lng: 19.1665557 },
    { lat: 48.177882, lng: 19.1666343 },
    { lat: 48.1778073, lng: 19.1668107 },
    { lat: 48.1778326, lng: 19.1670499 },
    { lat: 48.1777975, lng: 19.1672703 },
    { lat: 48.1779073, lng: 19.1675788 },
    { lat: 48.1778642, lng: 19.1678196 },
    { lat: 48.1778988, lng: 19.1680185 },
    { lat: 48.1776906, lng: 19.1681161 },
    { lat: 48.1778192, lng: 19.1687796 },
    { lat: 48.1778139, lng: 19.1693312 },
    { lat: 48.1778827, lng: 19.1694695 },
    { lat: 48.1779683, lng: 19.1692862 },
    { lat: 48.1780225, lng: 19.1693303 },
    { lat: 48.1781026, lng: 19.1696072 },
    { lat: 48.1785645, lng: 19.1700774 },
    { lat: 48.1785488, lng: 19.17026 },
    { lat: 48.1784287, lng: 19.1703209 },
    { lat: 48.1784862, lng: 19.1707522 },
    { lat: 48.178685, lng: 19.1708019 },
    { lat: 48.1787248, lng: 19.1708568 },
    { lat: 48.1787354, lng: 19.1710457 },
    { lat: 48.1789799, lng: 19.1713622 },
    { lat: 48.1791546, lng: 19.1714785 },
    { lat: 48.1797434, lng: 19.1716422 },
    { lat: 48.1799587, lng: 19.1719168 },
    { lat: 48.1801071, lng: 19.1719756 },
    { lat: 48.180148, lng: 19.1721848 },
    { lat: 48.1800942, lng: 19.1723543 },
    { lat: 48.1801263, lng: 19.1724111 },
    { lat: 48.1802451, lng: 19.1724195 },
    { lat: 48.1803452, lng: 19.1726607 },
    { lat: 48.1803324, lng: 19.1729503 },
    { lat: 48.1818675, lng: 19.1765655 },
    { lat: 48.1831287, lng: 19.1769042 },
    { lat: 48.1831137, lng: 19.1766011 },
    { lat: 48.1867727, lng: 19.1743164 },
    { lat: 48.1892092, lng: 19.1707056 },
    { lat: 48.1904388, lng: 19.1696967 },
    { lat: 48.1904526, lng: 19.1695447 },
    { lat: 48.1916661, lng: 19.1693915 },
    { lat: 48.1923606, lng: 19.168168 },
    { lat: 48.1956278, lng: 19.163715 },
    { lat: 48.1956448, lng: 19.1633757 },
    { lat: 48.1962337, lng: 19.1628142 },
    { lat: 48.1968689, lng: 19.1619844 },
    { lat: 48.1971167, lng: 19.1604139 },
    { lat: 48.1973593, lng: 19.159941 },
    { lat: 48.1976721, lng: 19.1594954 },
    { lat: 48.1983981, lng: 19.1591335 },
    { lat: 48.1983957, lng: 19.1589709 },
    { lat: 48.1984842, lng: 19.1587568 },
    { lat: 48.1993416, lng: 19.1584298 },
    { lat: 48.1991075, lng: 19.1577787 },
    { lat: 48.1995673, lng: 19.1565708 },
    { lat: 48.2003552, lng: 19.1551171 },
    { lat: 48.2004839, lng: 19.1550979 },
    { lat: 48.2010793, lng: 19.1541896 },
    { lat: 48.2011366, lng: 19.1540306 },
    { lat: 48.2013147, lng: 19.153827 },
    { lat: 48.2018335, lng: 19.1535552 },
    { lat: 48.2021027, lng: 19.1537112 },
    { lat: 48.2023114, lng: 19.1537124 },
    { lat: 48.2029955, lng: 19.1530999 },
    { lat: 48.2032034, lng: 19.1536266 },
    { lat: 48.2035362, lng: 19.1539299 },
    { lat: 48.2037459, lng: 19.1538148 },
    { lat: 48.2040531, lng: 19.153441 },
    { lat: 48.2044494, lng: 19.1532499 },
    { lat: 48.2046219, lng: 19.1530222 },
    { lat: 48.20536, lng: 19.1527917 },
    { lat: 48.205826, lng: 19.15257 },
    { lat: 48.2061338, lng: 19.1526838 },
    { lat: 48.2069589, lng: 19.1527741 },
    { lat: 48.2073006, lng: 19.1529145 },
    { lat: 48.2075857, lng: 19.1529083 },
    { lat: 48.2079366, lng: 19.1530951 },
    { lat: 48.2082426, lng: 19.1529027 },
    { lat: 48.2084011, lng: 19.1530739 },
    { lat: 48.2092412, lng: 19.1524468 },
    { lat: 48.2099946, lng: 19.1522234 },
    { lat: 48.2099491, lng: 19.1524879 },
    { lat: 48.2104146, lng: 19.1523053 },
    { lat: 48.2107485, lng: 19.1521251 },
    { lat: 48.2116031, lng: 19.151426 },
    { lat: 48.2121824, lng: 19.1520582 },
    { lat: 48.2124862, lng: 19.152544 },
    { lat: 48.2128233, lng: 19.1520682 },
    { lat: 48.2132961, lng: 19.1515801 },
    { lat: 48.2143489, lng: 19.1519628 },
    { lat: 48.2144289, lng: 19.152166 },
    { lat: 48.2147386, lng: 19.1521386 },
    { lat: 48.2148631, lng: 19.1520333 },
    { lat: 48.2150879, lng: 19.1520641 },
    { lat: 48.215163, lng: 19.1518988 },
    { lat: 48.2154772, lng: 19.1518699 },
    { lat: 48.2156985, lng: 19.1519202 },
    { lat: 48.2162045, lng: 19.151825 },
    { lat: 48.2162748, lng: 19.1516995 },
    { lat: 48.2167883, lng: 19.1515505 },
    { lat: 48.2168819, lng: 19.1517101 },
    { lat: 48.2176248, lng: 19.1513934 },
    { lat: 48.2177995, lng: 19.1512361 },
    { lat: 48.2180515, lng: 19.1513813 },
    { lat: 48.2182685, lng: 19.1513042 },
    { lat: 48.2192105, lng: 19.151316 },
    { lat: 48.220037, lng: 19.1519332 },
    { lat: 48.220422, lng: 19.1523729 },
    { lat: 48.220417, lng: 19.152457 },
    { lat: 48.220495, lng: 19.152393 },
    { lat: 48.220662, lng: 19.152047 },
    { lat: 48.22084, lng: 19.151788 },
    { lat: 48.221049, lng: 19.151765 },
    { lat: 48.221279, lng: 19.151669 },
    { lat: 48.221507, lng: 19.151497 },
    { lat: 48.221855, lng: 19.15142 },
    { lat: 48.222015, lng: 19.151272 },
    { lat: 48.222124, lng: 19.151011 },
    { lat: 48.222243, lng: 19.150725 },
    { lat: 48.222378, lng: 19.150646 },
    { lat: 48.222667, lng: 19.150693 },
    { lat: 48.223162, lng: 19.150727 },
    { lat: 48.223661, lng: 19.150764 },
    { lat: 48.223851, lng: 19.150736 },
    { lat: 48.22409, lng: 19.150651 },
    { lat: 48.224501, lng: 19.150494 },
    { lat: 48.22484, lng: 19.150601 },
    { lat: 48.225246, lng: 19.150699 },
    { lat: 48.225418, lng: 19.150684 },
    { lat: 48.22555, lng: 19.150624 },
    { lat: 48.225789, lng: 19.150715 },
    { lat: 48.225988, lng: 19.150731 },
    { lat: 48.225987, lng: 19.150681 },
    { lat: 48.226026, lng: 19.150605 },
    { lat: 48.226365, lng: 19.149983 },
    { lat: 48.226698, lng: 19.14935 },
    { lat: 48.226544, lng: 19.148253 },
    { lat: 48.226956, lng: 19.14729 },
    { lat: 48.226998, lng: 19.146166 },
    { lat: 48.227126, lng: 19.146197 },
    { lat: 48.227123, lng: 19.145964 },
    { lat: 48.227048, lng: 19.145674 },
    { lat: 48.2269, lng: 19.145395 },
    { lat: 48.226703, lng: 19.14512 },
    { lat: 48.226558, lng: 19.144798 },
    { lat: 48.226429, lng: 19.144187 },
    { lat: 48.226341, lng: 19.143849 },
    { lat: 48.226244, lng: 19.143141 },
    { lat: 48.226157, lng: 19.142362 },
    { lat: 48.22623, lng: 19.142349 },
    { lat: 48.226258, lng: 19.142161 },
    { lat: 48.22627, lng: 19.142083 },
    { lat: 48.226436, lng: 19.141359 },
    { lat: 48.226497, lng: 19.141095 },
    { lat: 48.226705, lng: 19.140349 },
    { lat: 48.226738, lng: 19.140235 },
    { lat: 48.226698, lng: 19.139969 },
    { lat: 48.226643, lng: 19.139572 },
    { lat: 48.226713, lng: 19.139127 },
    { lat: 48.227113, lng: 19.138573 },
    { lat: 48.227355, lng: 19.138239 },
    { lat: 48.227489, lng: 19.138365 },
    { lat: 48.227694, lng: 19.137982 },
    { lat: 48.227804, lng: 19.137787 },
    { lat: 48.228063, lng: 19.137323 },
    { lat: 48.228011, lng: 19.1371 },
    { lat: 48.228174, lng: 19.136447 },
    { lat: 48.2281603, lng: 19.1363382 },
    { lat: 48.228154, lng: 19.136288 },
    { lat: 48.228341, lng: 19.135935 },
    { lat: 48.228466, lng: 19.135696 },
    { lat: 48.228589, lng: 19.135613 },
    { lat: 48.228763, lng: 19.135453 },
    { lat: 48.228832, lng: 19.135541 },
    { lat: 48.230607, lng: 19.135757 },
    { lat: 48.23127, lng: 19.135816 },
    { lat: 48.23129, lng: 19.135817 },
    { lat: 48.231419, lng: 19.135826 },
    { lat: 48.231754, lng: 19.135781 },
    { lat: 48.232232, lng: 19.135287 },
    { lat: 48.232238, lng: 19.135153 },
    { lat: 48.232273, lng: 19.135004 },
    { lat: 48.232288, lng: 19.134939 },
    { lat: 48.232292, lng: 19.134924 },
    { lat: 48.232312, lng: 19.134836 },
    { lat: 48.232349, lng: 19.134674 },
    { lat: 48.232378, lng: 19.13455 },
    { lat: 48.23241, lng: 19.134407 },
    { lat: 48.23241, lng: 19.134405 },
    { lat: 48.232455, lng: 19.134208 },
    { lat: 48.232466, lng: 19.134159 },
    { lat: 48.232526, lng: 19.133882 },
    { lat: 48.232596, lng: 19.133565 },
    { lat: 48.23261, lng: 19.1335 },
    { lat: 48.232684, lng: 19.132749 },
    { lat: 48.232737, lng: 19.132209 },
    { lat: 48.232775, lng: 19.131821 },
    { lat: 48.232738, lng: 19.131298 },
    { lat: 48.232299, lng: 19.130203 },
    { lat: 48.232031, lng: 19.129878 },
    { lat: 48.231341, lng: 19.129452 },
    { lat: 48.230424, lng: 19.129263 },
    { lat: 48.230022, lng: 19.129178 },
    { lat: 48.229653, lng: 19.128999 },
    { lat: 48.229441, lng: 19.12886 },
    { lat: 48.228871, lng: 19.128359 },
    { lat: 48.228372, lng: 19.12783 },
    { lat: 48.228074, lng: 19.12744 },
    { lat: 48.227952, lng: 19.1272 },
    { lat: 48.227837, lng: 19.126798 },
    { lat: 48.227806, lng: 19.126516 },
    { lat: 48.227814, lng: 19.126237 },
    { lat: 48.227826, lng: 19.125774 },
    { lat: 48.227862, lng: 19.125776 },
    { lat: 48.227872, lng: 19.125769 },
    { lat: 48.227881, lng: 19.125709 },
    { lat: 48.227765, lng: 19.125674 },
    { lat: 48.227697, lng: 19.125638 },
    { lat: 48.227521, lng: 19.125472 },
    { lat: 48.227453, lng: 19.125416 },
    { lat: 48.227372, lng: 19.125375 },
    { lat: 48.227164, lng: 19.125398 },
    { lat: 48.22715, lng: 19.124884 },
    { lat: 48.227198, lng: 19.124678 },
    { lat: 48.227207, lng: 19.124638 },
    { lat: 48.227171, lng: 19.12434 },
    { lat: 48.227119, lng: 19.124154 },
    { lat: 48.227018, lng: 19.12414 },
    { lat: 48.226843, lng: 19.123984 },
    { lat: 48.226851, lng: 19.123935 },
    { lat: 48.226901, lng: 19.123665 },
    { lat: 48.226913, lng: 19.123455 },
    { lat: 48.22683, lng: 19.123379 },
    { lat: 48.226788, lng: 19.123328 },
    { lat: 48.226645, lng: 19.12313 },
    { lat: 48.226449, lng: 19.123017 },
    { lat: 48.22624, lng: 19.122903 },
    { lat: 48.226073, lng: 19.122917 },
    { lat: 48.225963, lng: 19.122768 },
    { lat: 48.225334, lng: 19.122384 },
    { lat: 48.225288, lng: 19.12228 },
    { lat: 48.225254, lng: 19.122233 },
    { lat: 48.225179, lng: 19.122252 },
    { lat: 48.224945, lng: 19.122243 },
    { lat: 48.224661, lng: 19.122211 },
    { lat: 48.224616, lng: 19.122061 },
    { lat: 48.224592, lng: 19.122015 },
    { lat: 48.224567, lng: 19.122001 },
    { lat: 48.224479, lng: 19.121987 },
    { lat: 48.224393, lng: 19.121992 },
    { lat: 48.22415, lng: 19.122032 },
    { lat: 48.224062, lng: 19.122122 },
    { lat: 48.223932, lng: 19.122144 },
    { lat: 48.223767, lng: 19.122067 },
    { lat: 48.223731, lng: 19.122059 },
    { lat: 48.223685, lng: 19.12205 },
    { lat: 48.223599, lng: 19.122248 },
    { lat: 48.223491, lng: 19.122356 },
    { lat: 48.223257, lng: 19.12221 },
    { lat: 48.223179, lng: 19.122213 },
    { lat: 48.22302, lng: 19.122256 },
    { lat: 48.222893, lng: 19.122233 },
    { lat: 48.222746, lng: 19.122067 },
    { lat: 48.222666, lng: 19.122044 },
    { lat: 48.22254, lng: 19.122162 },
    { lat: 48.22248, lng: 19.122161 },
    { lat: 48.222419, lng: 19.122042 },
    { lat: 48.222323, lng: 19.12201 },
    { lat: 48.222257, lng: 19.122036 },
    { lat: 48.222136, lng: 19.122135 },
    { lat: 48.221933, lng: 19.122172 },
    { lat: 48.221859, lng: 19.122126 },
    { lat: 48.221802, lng: 19.122004 },
    { lat: 48.221767, lng: 19.121967 },
    { lat: 48.221718, lng: 19.121968 },
    { lat: 48.221683, lng: 19.121987 },
    { lat: 48.221607, lng: 19.122104 },
    { lat: 48.221569, lng: 19.122142 },
    { lat: 48.221521, lng: 19.122135 },
    { lat: 48.221474, lng: 19.122121 },
    { lat: 48.221378, lng: 19.122081 },
    { lat: 48.221327, lng: 19.12204 },
    { lat: 48.221248, lng: 19.121928 },
    { lat: 48.221186, lng: 19.121899 },
    { lat: 48.221148, lng: 19.121908 },
    { lat: 48.221082, lng: 19.121939 },
    { lat: 48.221024, lng: 19.121912 },
    { lat: 48.220951, lng: 19.121819 },
    { lat: 48.220906, lng: 19.121674 },
    { lat: 48.220872, lng: 19.12153 },
    { lat: 48.22081, lng: 19.12139 },
    { lat: 48.220785, lng: 19.121358 },
    { lat: 48.220732, lng: 19.121317 },
    { lat: 48.220601, lng: 19.121257 },
    { lat: 48.220508, lng: 19.121245 },
    { lat: 48.220464, lng: 19.121204 },
    { lat: 48.220287, lng: 19.120877 },
    { lat: 48.220184, lng: 19.120739 },
    { lat: 48.220113, lng: 19.120679 },
    { lat: 48.220011, lng: 19.120631 },
    { lat: 48.219785, lng: 19.120569 },
    { lat: 48.219633, lng: 19.120414 },
    { lat: 48.219424, lng: 19.120022 },
    { lat: 48.219192, lng: 19.119669 },
    { lat: 48.219112, lng: 19.119552 },
    { lat: 48.218965, lng: 19.119319 },
    { lat: 48.218254, lng: 19.118626 },
    { lat: 48.218074, lng: 19.11849 },
    { lat: 48.218019, lng: 19.118395 },
    { lat: 48.217958, lng: 19.118166 },
    { lat: 48.217835, lng: 19.117948 },
    { lat: 48.217801, lng: 19.117939 },
    { lat: 48.217703, lng: 19.117976 },
    { lat: 48.21765, lng: 19.117957 },
    { lat: 48.217625, lng: 19.117895 },
    { lat: 48.217592, lng: 19.117678 },
    { lat: 48.217574, lng: 19.117541 },
    { lat: 48.21755, lng: 19.117495 },
    { lat: 48.217512, lng: 19.117463 },
    { lat: 48.217494, lng: 19.117471 },
    { lat: 48.217462, lng: 19.117492 },
    { lat: 48.217405, lng: 19.117505 },
    { lat: 48.217337, lng: 19.117478 },
    { lat: 48.217257, lng: 19.117399 },
    { lat: 48.217224, lng: 19.117332 },
    { lat: 48.217209, lng: 19.117283 },
    { lat: 48.217171, lng: 19.117047 },
    { lat: 48.217158, lng: 19.11702 },
    { lat: 48.217126, lng: 19.116994 },
    { lat: 48.217018, lng: 19.116972 },
    { lat: 48.21699, lng: 19.116985 },
    { lat: 48.216939, lng: 19.117011 },
    { lat: 48.216916, lng: 19.116998 },
    { lat: 48.216874, lng: 19.116942 },
    { lat: 48.216864, lng: 19.116902 },
    { lat: 48.216846, lng: 19.116767 },
    { lat: 48.216816, lng: 19.116698 },
    { lat: 48.216691, lng: 19.116529 },
    { lat: 48.21654, lng: 19.116431 },
    { lat: 48.216424, lng: 19.116348 },
    { lat: 48.216325, lng: 19.116272 },
    { lat: 48.215978, lng: 19.11608 },
    { lat: 48.215963, lng: 19.116078 },
    { lat: 48.21594, lng: 19.116104 },
    { lat: 48.215921, lng: 19.116148 },
    { lat: 48.215899, lng: 19.116166 },
    { lat: 48.215878, lng: 19.116175 },
    { lat: 48.215808, lng: 19.116126 },
    { lat: 48.215584, lng: 19.116002 },
    { lat: 48.21544, lng: 19.115891 },
    { lat: 48.215246, lng: 19.115788 },
    { lat: 48.215113, lng: 19.115703 },
    { lat: 48.214899, lng: 19.115639 },
    { lat: 48.214871, lng: 19.115604 },
    { lat: 48.214799, lng: 19.115467 },
    { lat: 48.214601, lng: 19.115318 },
    { lat: 48.214435, lng: 19.115298 },
    { lat: 48.21407, lng: 19.115192 },
    { lat: 48.214019, lng: 19.115166 },
    { lat: 48.213947, lng: 19.115109 },
    { lat: 48.213881, lng: 19.115026 },
    { lat: 48.213737, lng: 19.114825 },
    { lat: 48.213662, lng: 19.11478 },
    { lat: 48.213625, lng: 19.114772 },
    { lat: 48.213483, lng: 19.114794 },
    { lat: 48.213483, lng: 19.114737 },
    { lat: 48.213484, lng: 19.114632 },
    { lat: 48.213481, lng: 19.113012 },
    { lat: 48.213574, lng: 19.111697 },
    { lat: 48.213581, lng: 19.111604 },
    { lat: 48.213586, lng: 19.111569 },
    { lat: 48.213453, lng: 19.111099 },
    { lat: 48.213297, lng: 19.110795 },
    { lat: 48.21326, lng: 19.11072 },
    { lat: 48.21327, lng: 19.110563 },
    { lat: 48.213284, lng: 19.110333 },
    { lat: 48.213125, lng: 19.110075 },
    { lat: 48.213097, lng: 19.109742 },
    { lat: 48.21304, lng: 19.109236 },
    { lat: 48.213083, lng: 19.108843 },
    { lat: 48.21309, lng: 19.10853 },
    { lat: 48.213138, lng: 19.108483 },
    { lat: 48.213122, lng: 19.108333 },
    { lat: 48.213128, lng: 19.108082 },
    { lat: 48.212959, lng: 19.107419 },
    { lat: 48.212898, lng: 19.107284 },
    { lat: 48.212892, lng: 19.10715 },
    { lat: 48.21282, lng: 19.106823 },
    { lat: 48.212859, lng: 19.106617 },
    { lat: 48.212853, lng: 19.106535 },
    { lat: 48.21292, lng: 19.106162 },
    { lat: 48.21299, lng: 19.105933 },
    { lat: 48.212985, lng: 19.105764 },
    { lat: 48.212952, lng: 19.105637 },
    { lat: 48.212962, lng: 19.105527 },
    { lat: 48.213148, lng: 19.105273 },
    { lat: 48.213283, lng: 19.105049 },
    { lat: 48.21335, lng: 19.104953 },
    { lat: 48.213563, lng: 19.104938 },
    { lat: 48.213636, lng: 19.104862 },
    { lat: 48.213671, lng: 19.104783 },
    { lat: 48.213895, lng: 19.104647 },
    { lat: 48.21397, lng: 19.104441 },
    { lat: 48.214192, lng: 19.104421 },
    { lat: 48.214194, lng: 19.104149 },
    { lat: 48.214365, lng: 19.104185 },
    { lat: 48.21463, lng: 19.103762 },
    { lat: 48.214652, lng: 19.103598 },
    { lat: 48.214779, lng: 19.103531 },
    { lat: 48.21486, lng: 19.103519 },
    { lat: 48.21495, lng: 19.103297 },
    { lat: 48.21507, lng: 19.103146 },
    { lat: 48.21508, lng: 19.102938 },
    { lat: 48.215228, lng: 19.102963 },
    { lat: 48.215284, lng: 19.102951 },
    { lat: 48.215382, lng: 19.102774 },
    { lat: 48.215408, lng: 19.102698 },
    { lat: 48.215582, lng: 19.102524 },
    { lat: 48.21567, lng: 19.102482 },
    { lat: 48.215762, lng: 19.102419 },
    { lat: 48.215806, lng: 19.102247 },
    { lat: 48.21586, lng: 19.102159 },
    { lat: 48.215926, lng: 19.101944 },
    { lat: 48.216062, lng: 19.101909 },
    { lat: 48.216134, lng: 19.101934 },
    { lat: 48.216206, lng: 19.101823 },
    { lat: 48.21636, lng: 19.101759 },
    { lat: 48.216403, lng: 19.101645 },
    { lat: 48.21644, lng: 19.101361 },
    { lat: 48.216636, lng: 19.101355 },
    { lat: 48.216733, lng: 19.101332 },
    { lat: 48.216773, lng: 19.10121 },
    { lat: 48.216759, lng: 19.101097 },
    { lat: 48.216814, lng: 19.100963 },
    { lat: 48.216876, lng: 19.100876 },
    { lat: 48.217166, lng: 19.100706 },
    { lat: 48.217168, lng: 19.100626 },
    { lat: 48.217147, lng: 19.100483 },
    { lat: 48.217179, lng: 19.10027 },
    { lat: 48.217089, lng: 19.100158 },
    { lat: 48.217011, lng: 19.100089 },
    { lat: 48.216961, lng: 19.099987 },
    { lat: 48.216867, lng: 19.099864 },
    { lat: 48.216758, lng: 19.099786 },
    { lat: 48.216708, lng: 19.099682 },
    { lat: 48.216706, lng: 19.099558 },
    { lat: 48.216724, lng: 19.099507 },
    { lat: 48.216743, lng: 19.099361 },
    { lat: 48.216615, lng: 19.099207 },
    { lat: 48.216643, lng: 19.099144 },
    { lat: 48.216671, lng: 19.098985 },
    { lat: 48.216551, lng: 19.098707 },
    { lat: 48.216446, lng: 19.098723 },
    { lat: 48.21639, lng: 19.098561 },
    { lat: 48.216363, lng: 19.098401 },
    { lat: 48.21636, lng: 19.098325 },
    { lat: 48.216056, lng: 19.098074 },
    { lat: 48.215874, lng: 19.097977 },
    { lat: 48.215714, lng: 19.097842 },
    { lat: 48.215636, lng: 19.097599 },
    { lat: 48.21555, lng: 19.097515 },
    { lat: 48.215484, lng: 19.097496 },
    { lat: 48.215484, lng: 19.097405 },
    { lat: 48.215511, lng: 19.097377 },
    { lat: 48.2155, lng: 19.097309 },
    { lat: 48.215259, lng: 19.0971 },
    { lat: 48.215233, lng: 19.097077 },
    { lat: 48.215161, lng: 19.096962 },
    { lat: 48.215182, lng: 19.096942 },
    { lat: 48.21518, lng: 19.096915 },
    { lat: 48.215651, lng: 19.096975 },
    { lat: 48.21606, lng: 19.097222 },
    { lat: 48.216227, lng: 19.09729 },
    { lat: 48.21638, lng: 19.09737 },
    { lat: 48.217055, lng: 19.097561 },
    { lat: 48.217206, lng: 19.097587 },
    { lat: 48.217553, lng: 19.097679 },
    { lat: 48.21764, lng: 19.097691 },
    { lat: 48.217934, lng: 19.097636 },
    { lat: 48.218058, lng: 19.097629 },
    { lat: 48.218241, lng: 19.097653 },
    { lat: 48.218465, lng: 19.09764 },
    { lat: 48.218575, lng: 19.097658 },
    { lat: 48.218787, lng: 19.097656 },
    { lat: 48.218761, lng: 19.097637 },
    { lat: 48.218835, lng: 19.097636 },
    { lat: 48.218807, lng: 19.097618 },
    { lat: 48.219019, lng: 19.097581 },
    { lat: 48.219335, lng: 19.097448 },
    { lat: 48.220117, lng: 19.096985 },
    { lat: 48.220295, lng: 19.096896 },
    { lat: 48.220376, lng: 19.096894 },
    { lat: 48.220573, lng: 19.096772 },
    { lat: 48.220798, lng: 19.096597 },
    { lat: 48.220819, lng: 19.096558 },
    { lat: 48.22091, lng: 19.096393 },
    { lat: 48.221001, lng: 19.095862 },
    { lat: 48.220998, lng: 19.095824 },
    { lat: 48.220841, lng: 19.095592 },
    { lat: 48.220742, lng: 19.095509 },
    { lat: 48.220629, lng: 19.095251 },
    { lat: 48.22052, lng: 19.095047 },
    { lat: 48.220443, lng: 19.094894 },
    { lat: 48.220412, lng: 19.094819 },
    { lat: 48.22023, lng: 19.094063 },
    { lat: 48.220158, lng: 19.093668 },
    { lat: 48.220064, lng: 19.093264 },
    { lat: 48.220008, lng: 19.093007 },
    { lat: 48.219963, lng: 19.092684 },
    { lat: 48.21989, lng: 19.092497 },
    { lat: 48.219877, lng: 19.092447 },
    { lat: 48.219859, lng: 19.09224 },
    { lat: 48.219892, lng: 19.091933 },
    { lat: 48.220025, lng: 19.090766 },
    { lat: 48.220127, lng: 19.090595 },
    { lat: 48.220156, lng: 19.090567 },
    { lat: 48.220221, lng: 19.090525 },
    { lat: 48.220299, lng: 19.090422 },
    { lat: 48.220441, lng: 19.090186 },
    { lat: 48.220555, lng: 19.089966 },
    { lat: 48.220663, lng: 19.089819 },
    { lat: 48.220789, lng: 19.089592 },
    { lat: 48.220975, lng: 19.08916 },
    { lat: 48.220988, lng: 19.08911 },
    { lat: 48.221021, lng: 19.08892 },
    { lat: 48.221078, lng: 19.088655 },
    { lat: 48.221376, lng: 19.088014 },
    { lat: 48.221452, lng: 19.087881 },
    { lat: 48.221604, lng: 19.087665 },
    { lat: 48.221977, lng: 19.087367 },
    { lat: 48.222195, lng: 19.086971 },
    { lat: 48.222256, lng: 19.086862 },
    { lat: 48.222414, lng: 19.086585 },
    { lat: 48.222635, lng: 19.08616 },
    { lat: 48.222683, lng: 19.086079 },
    { lat: 48.222708, lng: 19.086044 },
    { lat: 48.22278, lng: 19.085976 },
    { lat: 48.222812, lng: 19.085942 },
    { lat: 48.222897, lng: 19.085754 },
    { lat: 48.22296, lng: 19.08564 },
    { lat: 48.223017, lng: 19.085529 },
    { lat: 48.223064, lng: 19.085421 },
    { lat: 48.223119, lng: 19.085143 },
    { lat: 48.223194, lng: 19.084643 },
    { lat: 48.223197, lng: 19.08461 },
    { lat: 48.223203, lng: 19.084516 },
    { lat: 48.223202, lng: 19.084351 },
    { lat: 48.223163, lng: 19.084042 },
    { lat: 48.223098, lng: 19.083538 },
    { lat: 48.223073, lng: 19.083286 },
    { lat: 48.223005, lng: 19.082612 },
    { lat: 48.222987, lng: 19.082416 },
    { lat: 48.222956, lng: 19.082219 },
    { lat: 48.223005, lng: 19.081703 },
    { lat: 48.22312, lng: 19.081261 },
    { lat: 48.223283, lng: 19.080716 },
    { lat: 48.223368, lng: 19.080416 },
    { lat: 48.223426, lng: 19.080237 },
    { lat: 48.223549, lng: 19.079872 },
    { lat: 48.223702, lng: 19.079467 },
    { lat: 48.223815, lng: 19.079155 },
    { lat: 48.224009, lng: 19.078768 },
    { lat: 48.224214, lng: 19.078396 },
    { lat: 48.224336, lng: 19.078156 },
    { lat: 48.224488, lng: 19.077931 },
    { lat: 48.224755, lng: 19.077479 },
    { lat: 48.224835, lng: 19.077339 },
    { lat: 48.224933, lng: 19.077112 },
    { lat: 48.225105, lng: 19.076579 },
    { lat: 48.225301, lng: 19.076227 },
    { lat: 48.225379, lng: 19.076092 },
    { lat: 48.225487, lng: 19.075867 },
    { lat: 48.225606, lng: 19.07545 },
    { lat: 48.225598, lng: 19.075064 },
    { lat: 48.225715, lng: 19.074794 },
    { lat: 48.225838, lng: 19.074583 },
    { lat: 48.22592, lng: 19.074352 },
    { lat: 48.22617, lng: 19.07404 },
    { lat: 48.22649, lng: 19.07391 },
    { lat: 48.226591, lng: 19.073795 },
    { lat: 48.226707, lng: 19.073711 },
    { lat: 48.22695, lng: 19.07337 },
    { lat: 48.227047, lng: 19.073287 },
    { lat: 48.227046, lng: 19.073241 },
    { lat: 48.227057, lng: 19.072982 },
    { lat: 48.227057, lng: 19.07293 },
    { lat: 48.22698, lng: 19.072885 },
    { lat: 48.2268, lng: 19.07264 },
    { lat: 48.226772, lng: 19.072629 },
    { lat: 48.226591, lng: 19.072543 },
    { lat: 48.226506, lng: 19.072481 },
    { lat: 48.226369, lng: 19.072381 },
    { lat: 48.226234, lng: 19.072304 },
    { lat: 48.225887, lng: 19.072098 },
    { lat: 48.225858, lng: 19.072082 },
    { lat: 48.225437, lng: 19.072091 },
    { lat: 48.225139, lng: 19.072139 },
    { lat: 48.224976, lng: 19.072159 },
    { lat: 48.224649, lng: 19.072162 },
    { lat: 48.224595, lng: 19.072162 },
    { lat: 48.22444, lng: 19.072168 },
    { lat: 48.224346, lng: 19.072191 },
    { lat: 48.224329, lng: 19.072197 },
    { lat: 48.22398, lng: 19.072217 },
    { lat: 48.223703, lng: 19.072053 },
    { lat: 48.223587, lng: 19.071961 },
    { lat: 48.223192, lng: 19.071646 },
    { lat: 48.223026, lng: 19.071553 },
    { lat: 48.223, lng: 19.071535 },
    { lat: 48.222953, lng: 19.071503 },
    { lat: 48.222741, lng: 19.071513 },
    { lat: 48.222621, lng: 19.07152 },
    { lat: 48.222324, lng: 19.071377 },
    { lat: 48.221978, lng: 19.07107 },
    { lat: 48.22192, lng: 19.07102 },
    { lat: 48.221496, lng: 19.070808 },
    { lat: 48.221247, lng: 19.070662 },
    { lat: 48.22126, lng: 19.070622 },
    { lat: 48.22127, lng: 19.070591 },
    { lat: 48.221166, lng: 19.070501 },
    { lat: 48.221051, lng: 19.070402 },
    { lat: 48.221055, lng: 19.070349 },
    { lat: 48.220724, lng: 19.070194 },
    { lat: 48.220454, lng: 19.069897 },
    { lat: 48.220421, lng: 19.069957 },
    { lat: 48.220167, lng: 19.069564 },
    { lat: 48.220145, lng: 19.069534 },
    { lat: 48.22, lng: 19.069404 },
    { lat: 48.219925, lng: 19.06917 },
    { lat: 48.219936, lng: 19.06896 },
    { lat: 48.220041, lng: 19.068637 },
    { lat: 48.220092, lng: 19.068438 },
    { lat: 48.220167, lng: 19.068158 },
    { lat: 48.220128, lng: 19.068141 },
    { lat: 48.220176, lng: 19.067857 },
    { lat: 48.220089, lng: 19.06772 },
    { lat: 48.22001, lng: 19.067663 },
    { lat: 48.219714, lng: 19.06746 },
    { lat: 48.219591, lng: 19.067405 },
    { lat: 48.219139, lng: 19.067465 },
    { lat: 48.219041, lng: 19.067478 },
    { lat: 48.219027, lng: 19.067478 },
    { lat: 48.218547, lng: 19.067441 },
    { lat: 48.218556, lng: 19.067538 },
    { lat: 48.218205, lng: 19.067539 },
    { lat: 48.218189, lng: 19.067594 },
    { lat: 48.217987, lng: 19.067542 },
    { lat: 48.217912, lng: 19.067724 },
    { lat: 48.217118, lng: 19.067484 },
    { lat: 48.217119, lng: 19.067333 },
    { lat: 48.217055, lng: 19.067337 },
    { lat: 48.216951, lng: 19.067333 },
    { lat: 48.216598, lng: 19.067044 },
    { lat: 48.216203, lng: 19.066631 },
    { lat: 48.215988, lng: 19.066326 },
    { lat: 48.215852, lng: 19.065921 },
    { lat: 48.215742, lng: 19.065633 },
    { lat: 48.215427, lng: 19.065304 },
    { lat: 48.215218, lng: 19.064929 },
    { lat: 48.21517, lng: 19.064617 },
    { lat: 48.215269, lng: 19.063722 },
    { lat: 48.215027, lng: 19.062998 },
    { lat: 48.215003, lng: 19.06299 },
    { lat: 48.214852, lng: 19.062737 },
    { lat: 48.214499, lng: 19.062589 },
    { lat: 48.214055, lng: 19.062355 },
    { lat: 48.213638, lng: 19.061983 },
    { lat: 48.212983, lng: 19.0614 },
    { lat: 48.212607, lng: 19.061213 },
    { lat: 48.212342, lng: 19.060787 },
    { lat: 48.212166, lng: 19.060181 },
    { lat: 48.21218, lng: 19.059637 },
    { lat: 48.212076, lng: 19.059433 },
    { lat: 48.212042, lng: 19.059354 },
    { lat: 48.211969, lng: 19.059261 },
    { lat: 48.211773, lng: 19.059025 },
    { lat: 48.211745, lng: 19.058995 },
    { lat: 48.211488, lng: 19.058749 },
    { lat: 48.211246, lng: 19.058488 },
    { lat: 48.211094, lng: 19.05838 },
    { lat: 48.211057, lng: 19.058332 },
    { lat: 48.210982, lng: 19.058188 },
    { lat: 48.210925, lng: 19.058153 },
    { lat: 48.210886, lng: 19.058111 },
    { lat: 48.210853, lng: 19.058058 },
    { lat: 48.21084, lng: 19.057936 },
    { lat: 48.2108, lng: 19.057867 },
    { lat: 48.210762, lng: 19.057804 },
    { lat: 48.210659, lng: 19.057629 },
    { lat: 48.210578, lng: 19.057502 },
    { lat: 48.210542, lng: 19.057469 },
    { lat: 48.210523, lng: 19.057469 },
    { lat: 48.21049, lng: 19.057418 },
    { lat: 48.210433, lng: 19.05736 },
    { lat: 48.210375, lng: 19.057288 },
    { lat: 48.210342, lng: 19.057233 },
    { lat: 48.210311, lng: 19.057181 },
    { lat: 48.210257, lng: 19.05708 },
    { lat: 48.210221, lng: 19.057056 },
    { lat: 48.210175, lng: 19.056649 },
    { lat: 48.210168, lng: 19.056423 },
    { lat: 48.210094, lng: 19.056039 },
    { lat: 48.210053, lng: 19.055715 },
    { lat: 48.20989, lng: 19.055356 },
    { lat: 48.209811, lng: 19.055227 },
    { lat: 48.209679, lng: 19.055054 },
    { lat: 48.209484, lng: 19.054904 },
    { lat: 48.209391, lng: 19.054846 },
    { lat: 48.209278, lng: 19.054791 },
    { lat: 48.209047, lng: 19.054566 },
    { lat: 48.208911, lng: 19.054432 },
    { lat: 48.208809, lng: 19.054406 },
    { lat: 48.208709, lng: 19.054438 },
    { lat: 48.208509, lng: 19.054403 },
    { lat: 48.208403, lng: 19.054364 },
    { lat: 48.208289, lng: 19.054357 },
    { lat: 48.208182, lng: 19.054316 },
    { lat: 48.208064, lng: 19.054271 },
    { lat: 48.208048, lng: 19.054266 },
    { lat: 48.207928, lng: 19.054185 },
    { lat: 48.207871, lng: 19.054166 },
    { lat: 48.207833, lng: 19.054182 },
    { lat: 48.207792, lng: 19.054168 },
    { lat: 48.207675, lng: 19.054202 },
    { lat: 48.207651, lng: 19.054202 },
    { lat: 48.207603, lng: 19.054188 },
    { lat: 48.207549, lng: 19.054129 },
    { lat: 48.207484, lng: 19.054082 },
    { lat: 48.207439, lng: 19.054054 },
    { lat: 48.207331, lng: 19.053998 },
    { lat: 48.207305, lng: 19.05392 },
    { lat: 48.207235, lng: 19.053826 },
    { lat: 48.207154, lng: 19.053835 },
    { lat: 48.20712, lng: 19.053756 },
    { lat: 48.207064, lng: 19.053666 },
    { lat: 48.207044, lng: 19.053613 },
    { lat: 48.207025, lng: 19.053559 },
    { lat: 48.20698, lng: 19.053472 },
    { lat: 48.206904, lng: 19.053372 },
    { lat: 48.206894, lng: 19.053353 },
    { lat: 48.206823, lng: 19.053296 },
    { lat: 48.206799, lng: 19.053266 },
    { lat: 48.206807, lng: 19.053189 },
    { lat: 48.206781, lng: 19.053099 },
    { lat: 48.206731, lng: 19.052979 },
    { lat: 48.206697, lng: 19.052918 },
    { lat: 48.206598, lng: 19.052731 },
    { lat: 48.206535, lng: 19.052646 },
    { lat: 48.206423, lng: 19.052531 },
    { lat: 48.206286, lng: 19.052456 },
    { lat: 48.206174, lng: 19.052411 },
    { lat: 48.206093, lng: 19.052305 },
    { lat: 48.206023, lng: 19.052249 },
    { lat: 48.205958, lng: 19.052188 },
    { lat: 48.205913, lng: 19.052032 },
    { lat: 48.205834, lng: 19.051832 },
    { lat: 48.205792, lng: 19.051645 },
    { lat: 48.205431, lng: 19.05086 },
    { lat: 48.205149, lng: 19.050515 },
    { lat: 48.204629, lng: 19.050141 },
    { lat: 48.204493, lng: 19.050431 },
    { lat: 48.204465, lng: 19.050494 },
    { lat: 48.202526, lng: 19.05468 },
    { lat: 48.202018, lng: 19.055779 },
    { lat: 48.201224, lng: 19.057435 },
    { lat: 48.201203, lng: 19.057483 },
    { lat: 48.201178, lng: 19.057539 },
    { lat: 48.201159, lng: 19.057585 },
    { lat: 48.200169, lng: 19.059832 },
    { lat: 48.200148, lng: 19.059892 },
    { lat: 48.200085, lng: 19.060035 },
    { lat: 48.200042, lng: 19.060132 },
    { lat: 48.199987, lng: 19.060261 },
    { lat: 48.198725, lng: 19.062959 },
    { lat: 48.197534, lng: 19.065476 },
    { lat: 48.197035, lng: 19.066483 },
    { lat: 48.196625, lng: 19.067464 },
    { lat: 48.196502, lng: 19.067675 },
    { lat: 48.196321, lng: 19.068107 },
    { lat: 48.195395, lng: 19.07016 },
    { lat: 48.194824, lng: 19.071319 },
    { lat: 48.19479, lng: 19.071378 },
    { lat: 48.194749, lng: 19.071448 },
    { lat: 48.194354, lng: 19.072129 },
    { lat: 48.194166, lng: 19.072108 },
    { lat: 48.1939408, lng: 19.0733447 },
    { lat: 48.1939375, lng: 19.0740383 },
    { lat: 48.1935077, lng: 19.075421 },
    { lat: 48.193393, lng: 19.0760562 },
    { lat: 48.193286, lng: 19.0763937 },
    { lat: 48.1931385, lng: 19.077294 },
    { lat: 48.1938405, lng: 19.0777312 },
    { lat: 48.1946399, lng: 19.0784065 },
    { lat: 48.1951335, lng: 19.0784922 },
    { lat: 48.1955728, lng: 19.0784611 },
    { lat: 48.1958072, lng: 19.0785269 },
    { lat: 48.1958519, lng: 19.0790297 },
    { lat: 48.1959829, lng: 19.0796422 },
    { lat: 48.1964761, lng: 19.0809976 },
    { lat: 48.1970991, lng: 19.0824208 },
    { lat: 48.1990872, lng: 19.0847615 },
    { lat: 48.1991652, lng: 19.0857775 },
    { lat: 48.1991357, lng: 19.086153 },
    { lat: 48.1986498, lng: 19.0874095 },
    { lat: 48.1984767, lng: 19.0876879 },
    { lat: 48.1985002, lng: 19.0877277 },
    { lat: 48.1982977, lng: 19.0878491 },
    { lat: 48.1982335, lng: 19.0887959 },
    { lat: 48.1977375, lng: 19.0905666 },
    { lat: 48.1976036, lng: 19.092369 },
    { lat: 48.1975244, lng: 19.0929037 },
    { lat: 48.1973366, lng: 19.093587 },
    { lat: 48.1962885, lng: 19.0967376 },
    { lat: 48.1962581, lng: 19.0989856 },
    { lat: 48.1961766, lng: 19.0996725 },
    { lat: 48.1962427, lng: 19.1010848 },
    { lat: 48.19608, lng: 19.1023109 },
    { lat: 48.1958883, lng: 19.1033214 },
    { lat: 48.1957334, lng: 19.1037437 },
    { lat: 48.1946806, lng: 19.1042405 },
    { lat: 48.1935411, lng: 19.1051018 },
    { lat: 48.1928391, lng: 19.1057172 },
    { lat: 48.1921845, lng: 19.1059486 },
    { lat: 48.1918503, lng: 19.1061436 },
    { lat: 48.1912012, lng: 19.1067113 },
    { lat: 48.1909003, lng: 19.1068016 },
    { lat: 48.1901166, lng: 19.1072244 },
    { lat: 48.1899732, lng: 19.1073567 },
    { lat: 48.1892218, lng: 19.1081789 },
    { lat: 48.188808, lng: 19.1090548 },
    { lat: 48.1881149, lng: 19.1098952 },
    { lat: 48.1880515, lng: 19.1098642 },
    { lat: 48.1878539, lng: 19.1104007 },
    { lat: 48.1876471, lng: 19.110615 },
    { lat: 48.1872796, lng: 19.1118778 },
    { lat: 48.1872299, lng: 19.1118516 },
    { lat: 48.1871946, lng: 19.1120251 },
    { lat: 48.1868322, lng: 19.111788 },
    { lat: 48.1866036, lng: 19.1117945 },
    { lat: 48.1863289, lng: 19.1119602 },
    { lat: 48.1859094, lng: 19.1118927 },
    { lat: 48.1855439, lng: 19.1116842 },
    { lat: 48.1853222, lng: 19.1117561 },
    { lat: 48.1850619, lng: 19.1123702 },
    { lat: 48.1848498, lng: 19.1124335 },
    { lat: 48.1846274, lng: 19.1122712 },
    { lat: 48.1845545, lng: 19.1119374 },
    { lat: 48.1844842, lng: 19.1119548 },
    { lat: 48.1843311, lng: 19.112228 },
    { lat: 48.1840167, lng: 19.1125257 },
    { lat: 48.1838331, lng: 19.1125304 },
    { lat: 48.18365, lng: 19.1122693 },
    { lat: 48.183526, lng: 19.1123175 },
    { lat: 48.1833263, lng: 19.1126267 },
    { lat: 48.1831203, lng: 19.1125671 },
    { lat: 48.1828028, lng: 19.1122179 },
    { lat: 48.1825056, lng: 19.1122685 },
    { lat: 48.1825175, lng: 19.1119224 },
    { lat: 48.1823966, lng: 19.1117565 },
    { lat: 48.1822393, lng: 19.1118567 },
    { lat: 48.1820842, lng: 19.1116653 },
    { lat: 48.1818985, lng: 19.1112643 },
    { lat: 48.1816174, lng: 19.1110584 },
    { lat: 48.1811977, lng: 19.1111163 },
    { lat: 48.1810468, lng: 19.1107161 },
    { lat: 48.1807249, lng: 19.1107701 },
    { lat: 48.1804898, lng: 19.1110052 },
    { lat: 48.1803632, lng: 19.1110003 },
    { lat: 48.1800004, lng: 19.1105775 },
    { lat: 48.179886, lng: 19.1106108 },
    { lat: 48.1797483, lng: 19.1111257 },
    { lat: 48.179681, lng: 19.1111774 },
    { lat: 48.1794221, lng: 19.1111156 },
    { lat: 48.1788308, lng: 19.1107678 },
    { lat: 48.1783622, lng: 19.110982 },
    { lat: 48.1778645, lng: 19.1113987 },
    { lat: 48.1777291, lng: 19.1118452 },
    { lat: 48.1776337, lng: 19.1119215 },
    { lat: 48.1775185, lng: 19.1117815 },
    { lat: 48.1774588, lng: 19.1113551 },
    { lat: 48.1774135, lng: 19.1113514 },
    { lat: 48.1770053, lng: 19.1115496 },
    { lat: 48.1766203, lng: 19.1119474 },
    { lat: 48.1764911, lng: 19.1122695 },
    { lat: 48.176415, lng: 19.1123476 },
    { lat: 48.1762611, lng: 19.1120441 },
    { lat: 48.1760286, lng: 19.1121524 },
    { lat: 48.175794, lng: 19.1127141 },
    { lat: 48.1754238, lng: 19.1126143 },
    { lat: 48.1750368, lng: 19.1128769 },
    { lat: 48.1748501, lng: 19.1128205 },
    { lat: 48.174696, lng: 19.1128946 },
    { lat: 48.1742929, lng: 19.1128348 },
    { lat: 48.1739069, lng: 19.1131673 },
    { lat: 48.1736731, lng: 19.1132238 },
    { lat: 48.1734163, lng: 19.113498 },
    { lat: 48.1731361, lng: 19.1139376 },
    { lat: 48.1729595, lng: 19.1139475 },
    { lat: 48.1727595, lng: 19.1137145 },
    { lat: 48.172635, lng: 19.1136815 },
    { lat: 48.1723672, lng: 19.1137351 },
    { lat: 48.1721752, lng: 19.1133911 },
    { lat: 48.1720666, lng: 19.1133608 },
    { lat: 48.1717718, lng: 19.1133694 },
    { lat: 48.1716712, lng: 19.1134323 },
    { lat: 48.1713188, lng: 19.1132637 },
    { lat: 48.1707029, lng: 19.1133488 },
    { lat: 48.1705983, lng: 19.1134393 },
    { lat: 48.1701976, lng: 19.1141756 },
    { lat: 48.1698817, lng: 19.1144614 },
    { lat: 48.1696305, lng: 19.1148059 },
    { lat: 48.1693375, lng: 19.1150544 },
    { lat: 48.168851, lng: 19.1157657 },
    { lat: 48.1676982, lng: 19.1161585 },
    { lat: 48.167209, lng: 19.1165663 },
    { lat: 48.1667849, lng: 19.1168285 },
    { lat: 48.1664151, lng: 19.11685 },
    { lat: 48.1661906, lng: 19.1176143 },
    { lat: 48.1661368, lng: 19.118026 },
    { lat: 48.1656825, lng: 19.1181866 },
    { lat: 48.1654405, lng: 19.1188027 },
    { lat: 48.1650942, lng: 19.1187764 },
    { lat: 48.1645172, lng: 19.1189147 },
    { lat: 48.1639907, lng: 19.1193242 },
    { lat: 48.1634964, lng: 19.1199868 },
    { lat: 48.1629758, lng: 19.1202639 },
    { lat: 48.1625243, lng: 19.1200506 },
    { lat: 48.1622709, lng: 19.1203254 },
    { lat: 48.1623961, lng: 19.1208884 },
    { lat: 48.1623392, lng: 19.1210583 },
    { lat: 48.1617695, lng: 19.1213016 },
    { lat: 48.1614213, lng: 19.1222519 },
    { lat: 48.1613756, lng: 19.1232757 },
    { lat: 48.1610024, lng: 19.1238809 },
    { lat: 48.1606717, lng: 19.1240559 },
    { lat: 48.1606045, lng: 19.1240401 },
    { lat: 48.1604296, lng: 19.1237214 },
    { lat: 48.1602177, lng: 19.1235431 },
    { lat: 48.1598478, lng: 19.1233595 },
    { lat: 48.1594832, lng: 19.1232819 },
    { lat: 48.1587792, lng: 19.1229368 },
    { lat: 48.1583577, lng: 19.1226011 },
    { lat: 48.1576193, lng: 19.1223524 },
    { lat: 48.1574065, lng: 19.1224044 },
    { lat: 48.1567492, lng: 19.122177 },
    { lat: 48.1561281, lng: 19.1222223 },
    { lat: 48.1557373, lng: 19.121759 },
    { lat: 48.1554812, lng: 19.1216411 },
    { lat: 48.15523, lng: 19.1213496 },
    { lat: 48.1549035, lng: 19.1211142 },
    { lat: 48.1544858, lng: 19.12108 },
    { lat: 48.1539409, lng: 19.1203737 },
    { lat: 48.153735, lng: 19.1203335 },
    { lat: 48.1535807, lng: 19.1205174 },
    { lat: 48.1534563, lng: 19.1208257 },
    { lat: 48.1530295, lng: 19.1210838 },
    { lat: 48.1528137, lng: 19.1210754 },
    { lat: 48.1526545, lng: 19.1207797 },
  ],
  Muľa: [
    { lat: 48.2178646, lng: 19.4999749 },
    { lat: 48.2176067, lng: 19.5002955 },
    { lat: 48.2174954, lng: 19.5006623 },
    { lat: 48.2171247, lng: 19.5006642 },
    { lat: 48.2170944, lng: 19.5010076 },
    { lat: 48.2169992, lng: 19.5011377 },
    { lat: 48.2165683, lng: 19.5010841 },
    { lat: 48.2165176, lng: 19.5012523 },
    { lat: 48.2165172, lng: 19.5015841 },
    { lat: 48.216193, lng: 19.5020766 },
    { lat: 48.2157883, lng: 19.502215 },
    { lat: 48.215746, lng: 19.5025187 },
    { lat: 48.2155166, lng: 19.5026277 },
    { lat: 48.2151834, lng: 19.5029985 },
    { lat: 48.2151514, lng: 19.5032047 },
    { lat: 48.2145804, lng: 19.5035752 },
    { lat: 48.2143051, lng: 19.5039301 },
    { lat: 48.2138496, lng: 19.5039256 },
    { lat: 48.2134356, lng: 19.5043776 },
    { lat: 48.212804, lng: 19.5049017 },
    { lat: 48.2125821, lng: 19.5054361 },
    { lat: 48.2125188, lng: 19.5057699 },
    { lat: 48.2121497, lng: 19.5060755 },
    { lat: 48.2118497, lng: 19.5066725 },
    { lat: 48.2107384, lng: 19.5083404 },
    { lat: 48.2105378, lng: 19.5087758 },
    { lat: 48.210407, lng: 19.5085838 },
    { lat: 48.2102643, lng: 19.5086113 },
    { lat: 48.2102095, lng: 19.5091297 },
    { lat: 48.2096824, lng: 19.5092623 },
    { lat: 48.2094147, lng: 19.509127 },
    { lat: 48.2093412, lng: 19.5088757 },
    { lat: 48.2087008, lng: 19.5082898 },
    { lat: 48.2084764, lng: 19.5085524 },
    { lat: 48.208294, lng: 19.5082909 },
    { lat: 48.2079338, lng: 19.5086853 },
    { lat: 48.2077461, lng: 19.508696 },
    { lat: 48.2076253, lng: 19.5085711 },
    { lat: 48.2073108, lng: 19.5084979 },
    { lat: 48.2070914, lng: 19.5083339 },
    { lat: 48.2068705, lng: 19.5080686 },
    { lat: 48.206681, lng: 19.5079693 },
    { lat: 48.2063737, lng: 19.5081468 },
    { lat: 48.2063985, lng: 19.5079037 },
    { lat: 48.2054762, lng: 19.5062001 },
    { lat: 48.203904, lng: 19.5055698 },
    { lat: 48.2023809, lng: 19.5060426 },
    { lat: 48.2008093, lng: 19.5062456 },
    { lat: 48.200468, lng: 19.5061787 },
    { lat: 48.1991, lng: 19.5064693 },
    { lat: 48.1986836, lng: 19.5058278 },
    { lat: 48.195279, lng: 19.5059971 },
    { lat: 48.1939614, lng: 19.5062121 },
    { lat: 48.1933768, lng: 19.50651 },
    { lat: 48.1907828, lng: 19.5064917 },
    { lat: 48.1894285, lng: 19.5063001 },
    { lat: 48.1886231, lng: 19.5065521 },
    { lat: 48.1882818, lng: 19.5068957 },
    { lat: 48.187461, lng: 19.5070087 },
    { lat: 48.1865889, lng: 19.5070144 },
    { lat: 48.1859655, lng: 19.5065998 },
    { lat: 48.1853025, lng: 19.5063736 },
    { lat: 48.1841299, lng: 19.5085609 },
    { lat: 48.1838359, lng: 19.508856 },
    { lat: 48.181981, lng: 19.51268 },
    { lat: 48.182055, lng: 19.512774 },
    { lat: 48.18218, lng: 19.51293 },
    { lat: 48.182312, lng: 19.513071 },
    { lat: 48.182343, lng: 19.513121 },
    { lat: 48.182382, lng: 19.513283 },
    { lat: 48.182383, lng: 19.513369 },
    { lat: 48.182326, lng: 19.513554 },
    { lat: 48.182236, lng: 19.513705 },
    { lat: 48.182162, lng: 19.513762 },
    { lat: 48.181885, lng: 19.513892 },
    { lat: 48.181537, lng: 19.513969 },
    { lat: 48.181253, lng: 19.514054 },
    { lat: 48.181159, lng: 19.514151 },
    { lat: 48.181107, lng: 19.514324 },
    { lat: 48.181129, lng: 19.514649 },
    { lat: 48.18118, lng: 19.514836 },
    { lat: 48.181314, lng: 19.515212 },
    { lat: 48.181396, lng: 19.5154 },
    { lat: 48.181457, lng: 19.515585 },
    { lat: 48.181512, lng: 19.515777 },
    { lat: 48.181527, lng: 19.515851 },
    { lat: 48.181535, lng: 19.516193 },
    { lat: 48.18158, lng: 19.516417 },
    { lat: 48.181614, lng: 19.516487 },
    { lat: 48.18166, lng: 19.516552 },
    { lat: 48.181797, lng: 19.516707 },
    { lat: 48.18202, lng: 19.516768 },
    { lat: 48.182156, lng: 19.516761 },
    { lat: 48.182317, lng: 19.516757 },
    { lat: 48.182544, lng: 19.516742 },
    { lat: 48.182743, lng: 19.516781 },
    { lat: 48.18279, lng: 19.516818 },
    { lat: 48.182894, lng: 19.516838 },
    { lat: 48.18297, lng: 19.516745 },
    { lat: 48.183153, lng: 19.516646 },
    { lat: 48.183241, lng: 19.516612 },
    { lat: 48.183388, lng: 19.516577 },
    { lat: 48.183476, lng: 19.51663 },
    { lat: 48.183501, lng: 19.516722 },
    { lat: 48.183479, lng: 19.516886 },
    { lat: 48.18345, lng: 19.517008 },
    { lat: 48.183404, lng: 19.517229 },
    { lat: 48.183394, lng: 19.517302 },
    { lat: 48.183439, lng: 19.517477 },
    { lat: 48.183543, lng: 19.517621 },
    { lat: 48.183582, lng: 19.517785 },
    { lat: 48.183613, lng: 19.517968 },
    { lat: 48.18363, lng: 19.518234 },
    { lat: 48.183523, lng: 19.518438 },
    { lat: 48.18351, lng: 19.518502 },
    { lat: 48.183464, lng: 19.518658 },
    { lat: 48.183453, lng: 19.518763 },
    { lat: 48.183403, lng: 19.518964 },
    { lat: 48.183372, lng: 19.519109 },
    { lat: 48.183344, lng: 19.519144 },
    { lat: 48.183314, lng: 19.519139 },
    { lat: 48.183252, lng: 19.519155 },
    { lat: 48.183243, lng: 19.519175 },
    { lat: 48.183165, lng: 19.519247 },
    { lat: 48.183078, lng: 19.519389 },
    { lat: 48.183069, lng: 19.519553 },
    { lat: 48.183081, lng: 19.519716 },
    { lat: 48.183064, lng: 19.520042 },
    { lat: 48.183063, lng: 19.520149 },
    { lat: 48.183003, lng: 19.520519 },
    { lat: 48.183001, lng: 19.520585 },
    { lat: 48.18296, lng: 19.520727 },
    { lat: 48.182934, lng: 19.520883 },
    { lat: 48.18297, lng: 19.521061 },
    { lat: 48.182999, lng: 19.521126 },
    { lat: 48.183135, lng: 19.521197 },
    { lat: 48.183265, lng: 19.521245 },
    { lat: 48.183361, lng: 19.521281 },
    { lat: 48.183419, lng: 19.521379 },
    { lat: 48.183491, lng: 19.521508 },
    { lat: 48.183562, lng: 19.521674 },
    { lat: 48.183639, lng: 19.522012 },
    { lat: 48.183604, lng: 19.522169 },
    { lat: 48.183659, lng: 19.522422 },
    { lat: 48.183695, lng: 19.522614 },
    { lat: 48.183805, lng: 19.522876 },
    { lat: 48.183913, lng: 19.523 },
    { lat: 48.184001, lng: 19.523128 },
    { lat: 48.184066, lng: 19.523241 },
    { lat: 48.184258, lng: 19.523426 },
    { lat: 48.184261, lng: 19.523552 },
    { lat: 48.18437, lng: 19.52367 },
    { lat: 48.184403, lng: 19.523696 },
    { lat: 48.184516, lng: 19.523701 },
    { lat: 48.184707, lng: 19.523707 },
    { lat: 48.184789, lng: 19.523706 },
    { lat: 48.184938, lng: 19.523705 },
    { lat: 48.184968, lng: 19.523706 },
    { lat: 48.185178, lng: 19.523571 },
    { lat: 48.185256, lng: 19.52355 },
    { lat: 48.185368, lng: 19.52346 },
    { lat: 48.185488, lng: 19.523397 },
    { lat: 48.185698, lng: 19.523353 },
    { lat: 48.185786, lng: 19.523359 },
    { lat: 48.186009, lng: 19.523393 },
    { lat: 48.186186, lng: 19.523453 },
    { lat: 48.186285, lng: 19.523506 },
    { lat: 48.186371, lng: 19.523566 },
    { lat: 48.186436, lng: 19.523622 },
    { lat: 48.186544, lng: 19.523745 },
    { lat: 48.186658, lng: 19.52395 },
    { lat: 48.186763, lng: 19.52417 },
    { lat: 48.186831, lng: 19.524323 },
    { lat: 48.186895, lng: 19.524481 },
    { lat: 48.187035, lng: 19.524805 },
    { lat: 48.187155, lng: 19.525047 },
    { lat: 48.187293, lng: 19.52529 },
    { lat: 48.187438, lng: 19.525512 },
    { lat: 48.187466, lng: 19.525558 },
    { lat: 48.187694, lng: 19.525772 },
    { lat: 48.187717, lng: 19.525807 },
    { lat: 48.187852, lng: 19.525963 },
    { lat: 48.187881, lng: 19.525994 },
    { lat: 48.188079, lng: 19.526266 },
    { lat: 48.18811, lng: 19.526342 },
    { lat: 48.188175, lng: 19.52658 },
    { lat: 48.188289, lng: 19.526895 },
    { lat: 48.188399, lng: 19.527038 },
    { lat: 48.18854, lng: 19.52719 },
    { lat: 48.188649, lng: 19.52729 },
    { lat: 48.189057, lng: 19.527663 },
    { lat: 48.189247, lng: 19.527859 },
    { lat: 48.189452, lng: 19.528087 },
    { lat: 48.189848, lng: 19.528489 },
    { lat: 48.190178, lng: 19.528819 },
    { lat: 48.190358, lng: 19.528967 },
    { lat: 48.190627, lng: 19.529138 },
    { lat: 48.190975, lng: 19.529367 },
    { lat: 48.191251, lng: 19.529532 },
    { lat: 48.191403, lng: 19.529559 },
    { lat: 48.191741, lng: 19.529465 },
    { lat: 48.192203, lng: 19.529303 },
    { lat: 48.192313, lng: 19.529059 },
    { lat: 48.1925, lng: 19.529039 },
    { lat: 48.19266, lng: 19.529092 },
    { lat: 48.192733, lng: 19.529097 },
    { lat: 48.192767, lng: 19.52909 },
    { lat: 48.192965, lng: 19.528957 },
    { lat: 48.193195, lng: 19.528781 },
    { lat: 48.193427, lng: 19.528796 },
    { lat: 48.193585, lng: 19.528901 },
    { lat: 48.19373, lng: 19.52899 },
    { lat: 48.19381, lng: 19.529155 },
    { lat: 48.194039, lng: 19.529266 },
    { lat: 48.194238, lng: 19.529203 },
    { lat: 48.194568, lng: 19.52918 },
    { lat: 48.194644, lng: 19.529177 },
    { lat: 48.194859, lng: 19.529213 },
    { lat: 48.195239, lng: 19.529484 },
    { lat: 48.195479, lng: 19.529486 },
    { lat: 48.195861, lng: 19.529447 },
    { lat: 48.196204, lng: 19.529324 },
    { lat: 48.196456, lng: 19.529242 },
    { lat: 48.196673, lng: 19.529155 },
    { lat: 48.197012, lng: 19.529045 },
    { lat: 48.197235, lng: 19.528936 },
    { lat: 48.197452, lng: 19.528838 },
    { lat: 48.197676, lng: 19.528697 },
    { lat: 48.197941, lng: 19.528488 },
    { lat: 48.198033, lng: 19.528315 },
    { lat: 48.198078, lng: 19.527971 },
    { lat: 48.198141, lng: 19.52756 },
    { lat: 48.19818, lng: 19.527444 },
    { lat: 48.198281, lng: 19.527299 },
    { lat: 48.198432, lng: 19.527139 },
    { lat: 48.198722, lng: 19.526812 },
    { lat: 48.198853, lng: 19.52667 },
    { lat: 48.199016, lng: 19.526532 },
    { lat: 48.199444, lng: 19.526173 },
    { lat: 48.199569, lng: 19.526054 },
    { lat: 48.199782, lng: 19.525894 },
    { lat: 48.20017, lng: 19.525678 },
    { lat: 48.200271, lng: 19.525564 },
    { lat: 48.200313, lng: 19.525515 },
    { lat: 48.200394, lng: 19.52533 },
    { lat: 48.200516, lng: 19.525152 },
    { lat: 48.200671, lng: 19.525018 },
    { lat: 48.200792, lng: 19.524996 },
    { lat: 48.200885, lng: 19.524991 },
    { lat: 48.200931, lng: 19.524988 },
    { lat: 48.200997, lng: 19.525011 },
    { lat: 48.201043, lng: 19.525017 },
    { lat: 48.201094, lng: 19.525048 },
    { lat: 48.201183, lng: 19.525072 },
    { lat: 48.201235, lng: 19.525095 },
    { lat: 48.201287, lng: 19.525109 },
    { lat: 48.201324, lng: 19.525129 },
    { lat: 48.201391, lng: 19.525164 },
    { lat: 48.201484, lng: 19.525193 },
    { lat: 48.201535, lng: 19.525214 },
    { lat: 48.201553, lng: 19.525219 },
    { lat: 48.201664, lng: 19.525254 },
    { lat: 48.201695, lng: 19.525266 },
    { lat: 48.201739, lng: 19.525273 },
    { lat: 48.201806, lng: 19.525282 },
    { lat: 48.201836, lng: 19.525289 },
    { lat: 48.201966, lng: 19.525335 },
    { lat: 48.202126, lng: 19.525404 },
    { lat: 48.202309, lng: 19.525485 },
    { lat: 48.202482, lng: 19.525583 },
    { lat: 48.20265, lng: 19.525687 },
    { lat: 48.202819, lng: 19.525787 },
    { lat: 48.202981, lng: 19.525899 },
    { lat: 48.203069, lng: 19.525987 },
    { lat: 48.20313, lng: 19.526044 },
    { lat: 48.203172, lng: 19.526093 },
    { lat: 48.20324, lng: 19.526176 },
    { lat: 48.203365, lng: 19.52634 },
    { lat: 48.203538, lng: 19.526573 },
    { lat: 48.203604, lng: 19.526667 },
    { lat: 48.20367, lng: 19.526757 },
    { lat: 48.203785, lng: 19.526917 },
    { lat: 48.203924, lng: 19.527132 },
    { lat: 48.203968, lng: 19.527198 },
    { lat: 48.204028, lng: 19.527292 },
    { lat: 48.204064, lng: 19.52735 },
    { lat: 48.204147, lng: 19.527498 },
    { lat: 48.204236, lng: 19.527664 },
    { lat: 48.204307, lng: 19.527826 },
    { lat: 48.204374, lng: 19.527989 },
    { lat: 48.204462, lng: 19.528203 },
    { lat: 48.204546, lng: 19.528422 },
    { lat: 48.204585, lng: 19.528507 },
    { lat: 48.204605, lng: 19.528559 },
    { lat: 48.204656, lng: 19.528699 },
    { lat: 48.204734, lng: 19.528905 },
    { lat: 48.204806, lng: 19.529097 },
    { lat: 48.204867, lng: 19.529325 },
    { lat: 48.204889, lng: 19.529435 },
    { lat: 48.204904, lng: 19.529515 },
    { lat: 48.204945, lng: 19.529772 },
    { lat: 48.205, lng: 19.53008 },
    { lat: 48.205061, lng: 19.530385 },
    { lat: 48.205095, lng: 19.530616 },
    { lat: 48.205135, lng: 19.530855 },
    { lat: 48.205173, lng: 19.531069 },
    { lat: 48.205211, lng: 19.53128 },
    { lat: 48.205254, lng: 19.531507 },
    { lat: 48.205302, lng: 19.531779 },
    { lat: 48.205342, lng: 19.532026 },
    { lat: 48.205426, lng: 19.532495 },
    { lat: 48.205548, lng: 19.533208 },
    { lat: 48.20557, lng: 19.53333 },
    { lat: 48.20562, lng: 19.533614 },
    { lat: 48.205656, lng: 19.533861 },
    { lat: 48.205679, lng: 19.534005 },
    { lat: 48.205713, lng: 19.534214 },
    { lat: 48.205729, lng: 19.534309 },
    { lat: 48.205737, lng: 19.534355 },
    { lat: 48.205749, lng: 19.534429 },
    { lat: 48.205791, lng: 19.53468 },
    { lat: 48.205836, lng: 19.534907 },
    { lat: 48.205853, lng: 19.534984 },
    { lat: 48.205897, lng: 19.535161 },
    { lat: 48.20591, lng: 19.535204 },
    { lat: 48.205965, lng: 19.535381 },
    { lat: 48.205989, lng: 19.535448 },
    { lat: 48.206048, lng: 19.535656 },
    { lat: 48.206106, lng: 19.535832 },
    { lat: 48.206152, lng: 19.535947 },
    { lat: 48.206224, lng: 19.536132 },
    { lat: 48.206289, lng: 19.536272 },
    { lat: 48.206336, lng: 19.536378 },
    { lat: 48.206363, lng: 19.536413 },
    { lat: 48.2064, lng: 19.536472 },
    { lat: 48.206429, lng: 19.536535 },
    { lat: 48.206562, lng: 19.536732 },
    { lat: 48.206596, lng: 19.536772 },
    { lat: 48.206676, lng: 19.536875 },
    { lat: 48.206828, lng: 19.537073 },
    { lat: 48.207002, lng: 19.537324 },
    { lat: 48.207153, lng: 19.537515 },
    { lat: 48.207294, lng: 19.53769 },
    { lat: 48.207349, lng: 19.537772 },
    { lat: 48.207505, lng: 19.537973 },
    { lat: 48.207612, lng: 19.538096 },
    { lat: 48.207737, lng: 19.538248 },
    { lat: 48.2078, lng: 19.538292 },
    { lat: 48.207839, lng: 19.538343 },
    { lat: 48.207907, lng: 19.538409 },
    { lat: 48.207971, lng: 19.53849 },
    { lat: 48.208082, lng: 19.538628 },
    { lat: 48.208154, lng: 19.538712 },
    { lat: 48.208202, lng: 19.538768 },
    { lat: 48.208235, lng: 19.538805 },
    { lat: 48.208364, lng: 19.538946 },
    { lat: 48.208441, lng: 19.53905 },
    { lat: 48.208572, lng: 19.539229 },
    { lat: 48.208696, lng: 19.539382 },
    { lat: 48.208732, lng: 19.539442 },
    { lat: 48.208818, lng: 19.539577 },
    { lat: 48.208905, lng: 19.53974 },
    { lat: 48.20893, lng: 19.539798 },
    { lat: 48.208975, lng: 19.539921 },
    { lat: 48.209021, lng: 19.540042 },
    { lat: 48.209043, lng: 19.540098 },
    { lat: 48.209087, lng: 19.540228 },
    { lat: 48.209138, lng: 19.540417 },
    { lat: 48.209179, lng: 19.540577 },
    { lat: 48.209196, lng: 19.540647 },
    { lat: 48.209205, lng: 19.540733 },
    { lat: 48.209265, lng: 19.540985 },
    { lat: 48.209289, lng: 19.54108 },
    { lat: 48.209321, lng: 19.541305 },
    { lat: 48.209325, lng: 19.541498 },
    { lat: 48.209367, lng: 19.541469 },
    { lat: 48.209391, lng: 19.54146 },
    { lat: 48.20943, lng: 19.541459 },
    { lat: 48.20958, lng: 19.541471 },
    { lat: 48.209609, lng: 19.541474 },
    { lat: 48.209651, lng: 19.54147 },
    { lat: 48.20968, lng: 19.54146 },
    { lat: 48.209711, lng: 19.541415 },
    { lat: 48.209735, lng: 19.541358 },
    { lat: 48.209738, lng: 19.541304 },
    { lat: 48.209716, lng: 19.541279 },
    { lat: 48.209673, lng: 19.541213 },
    { lat: 48.209658, lng: 19.541191 },
    { lat: 48.209641, lng: 19.541151 },
    { lat: 48.209616, lng: 19.54111 },
    { lat: 48.209611, lng: 19.541094 },
    { lat: 48.209614, lng: 19.540924 },
    { lat: 48.209625, lng: 19.540836 },
    { lat: 48.209668, lng: 19.540642 },
    { lat: 48.209681, lng: 19.540621 },
    { lat: 48.209716, lng: 19.540587 },
    { lat: 48.20974, lng: 19.540567 },
    { lat: 48.209793, lng: 19.540551 },
    { lat: 48.210097, lng: 19.540829 },
    { lat: 48.210152, lng: 19.540889 },
    { lat: 48.21022, lng: 19.540981 },
    { lat: 48.210326, lng: 19.540958 },
    { lat: 48.21025, lng: 19.540878 },
    { lat: 48.210282, lng: 19.540888 },
    { lat: 48.21104, lng: 19.541604 },
    { lat: 48.211308, lng: 19.541859 },
    { lat: 48.21154, lng: 19.542077 },
    { lat: 48.211594, lng: 19.542128 },
    { lat: 48.211996, lng: 19.542506 },
    { lat: 48.212693, lng: 19.543193 },
    { lat: 48.213006, lng: 19.543486 },
    { lat: 48.213239, lng: 19.543725 },
    { lat: 48.213455, lng: 19.543906 },
    { lat: 48.213562, lng: 19.544018 },
    { lat: 48.213687, lng: 19.544144 },
    { lat: 48.214026, lng: 19.544505 },
    { lat: 48.214133, lng: 19.544598 },
    { lat: 48.214244, lng: 19.544712 },
    { lat: 48.214448, lng: 19.544848 },
    { lat: 48.214715, lng: 19.545073 },
    { lat: 48.214757, lng: 19.545324 },
    { lat: 48.21477, lng: 19.545363 },
    { lat: 48.214909, lng: 19.545569 },
    { lat: 48.214929, lng: 19.54558 },
    { lat: 48.215126, lng: 19.545588 },
    { lat: 48.215368, lng: 19.54544 },
    { lat: 48.215544, lng: 19.54539 },
    { lat: 48.215559, lng: 19.545382 },
    { lat: 48.215617, lng: 19.545298 },
    { lat: 48.215805, lng: 19.545029 },
    { lat: 48.215821, lng: 19.545014 },
    { lat: 48.215849, lng: 19.545005 },
    { lat: 48.215875, lng: 19.545046 },
    { lat: 48.216222, lng: 19.545392 },
    { lat: 48.216246, lng: 19.545414 },
    { lat: 48.216293, lng: 19.545449 },
    { lat: 48.216587, lng: 19.54561 },
    { lat: 48.216731, lng: 19.545536 },
    { lat: 48.217007, lng: 19.545365 },
    { lat: 48.217165, lng: 19.545289 },
    { lat: 48.217192, lng: 19.545287 },
    { lat: 48.217218, lng: 19.545293 },
    { lat: 48.217349, lng: 19.545371 },
    { lat: 48.217367, lng: 19.545377 },
    { lat: 48.217373, lng: 19.545396 },
    { lat: 48.217477, lng: 19.54574 },
    { lat: 48.217496, lng: 19.545747 },
    { lat: 48.21761, lng: 19.545742 },
    { lat: 48.217671, lng: 19.545723 },
    { lat: 48.217733, lng: 19.545692 },
    { lat: 48.217983, lng: 19.545544 },
    { lat: 48.218073, lng: 19.545511 },
    { lat: 48.218222, lng: 19.545519 },
    { lat: 48.218247, lng: 19.545535 },
    { lat: 48.218428, lng: 19.545748 },
    { lat: 48.218584, lng: 19.545988 },
    { lat: 48.218603, lng: 19.545976 },
    { lat: 48.218826, lng: 19.54582 },
    { lat: 48.218845, lng: 19.545807 },
    { lat: 48.218865, lng: 19.545804 },
    { lat: 48.219278, lng: 19.545735 },
    { lat: 48.219297, lng: 19.54573 },
    { lat: 48.219783, lng: 19.545816 },
    { lat: 48.220136, lng: 19.545908 },
    { lat: 48.220175, lng: 19.545917 },
    { lat: 48.220216, lng: 19.545931 },
    { lat: 48.220247, lng: 19.545956 },
    { lat: 48.220277, lng: 19.546003 },
    { lat: 48.220317, lng: 19.54613 },
    { lat: 48.22034, lng: 19.54622 },
    { lat: 48.220355, lng: 19.546312 },
    { lat: 48.220371, lng: 19.546394 },
    { lat: 48.22039, lng: 19.546426 },
    { lat: 48.220407, lng: 19.54644 },
    { lat: 48.220426, lng: 19.546446 },
    { lat: 48.220473, lng: 19.546468 },
    { lat: 48.220616, lng: 19.546505 },
    { lat: 48.220655, lng: 19.546509 },
    { lat: 48.220692, lng: 19.546513 },
    { lat: 48.220989, lng: 19.54651 },
    { lat: 48.221011, lng: 19.54652 },
    { lat: 48.221198, lng: 19.546659 },
    { lat: 48.221228, lng: 19.546667 },
    { lat: 48.221258, lng: 19.546684 },
    { lat: 48.221584, lng: 19.546806 },
    { lat: 48.221604, lng: 19.54681 },
    { lat: 48.221632, lng: 19.546831 },
    { lat: 48.221675, lng: 19.546864 },
    { lat: 48.221711, lng: 19.546898 },
    { lat: 48.221773, lng: 19.546947 },
    { lat: 48.222105, lng: 19.547187 },
    { lat: 48.222118, lng: 19.547203 },
    { lat: 48.222192, lng: 19.547478 },
    { lat: 48.222201, lng: 19.547514 },
    { lat: 48.222222, lng: 19.547545 },
    { lat: 48.222669, lng: 19.54787 },
    { lat: 48.222719, lng: 19.547896 },
    { lat: 48.2228, lng: 19.547925 },
    { lat: 48.223155, lng: 19.548027 },
    { lat: 48.223195, lng: 19.548038 },
    { lat: 48.223419, lng: 19.548191 },
    { lat: 48.223478, lng: 19.548249 },
    { lat: 48.223536, lng: 19.548324 },
    { lat: 48.223617, lng: 19.548448 },
    { lat: 48.223646, lng: 19.548465 },
    { lat: 48.223717, lng: 19.548444 },
    { lat: 48.223745, lng: 19.548433 },
    { lat: 48.223777, lng: 19.54843 },
    { lat: 48.223901, lng: 19.548441 },
    { lat: 48.22397, lng: 19.548406 },
    { lat: 48.22412, lng: 19.548296 },
    { lat: 48.224162, lng: 19.548278 },
    { lat: 48.224187, lng: 19.54828 },
    { lat: 48.224219, lng: 19.54831 },
    { lat: 48.22444, lng: 19.548695 },
    { lat: 48.224476, lng: 19.548746 },
    { lat: 48.22449, lng: 19.548753 },
    { lat: 48.224515, lng: 19.548752 },
    { lat: 48.224708, lng: 19.548763 },
    { lat: 48.224733, lng: 19.548766 },
    { lat: 48.224763, lng: 19.54876 },
    { lat: 48.224862, lng: 19.548713 },
    { lat: 48.225123, lng: 19.548512 },
    { lat: 48.225144, lng: 19.548501 },
    { lat: 48.225166, lng: 19.548494 },
    { lat: 48.225183, lng: 19.548496 },
    { lat: 48.225205, lng: 19.548518 },
    { lat: 48.225234, lng: 19.548566 },
    { lat: 48.225269, lng: 19.548663 },
    { lat: 48.225315, lng: 19.548758 },
    { lat: 48.225348, lng: 19.548802 },
    { lat: 48.225394, lng: 19.548839 },
    { lat: 48.225436, lng: 19.548869 },
    { lat: 48.225483, lng: 19.548868 },
    { lat: 48.225523, lng: 19.548849 },
    { lat: 48.22602, lng: 19.548404 },
    { lat: 48.226039, lng: 19.548389 },
    { lat: 48.226061, lng: 19.548381 },
    { lat: 48.226081, lng: 19.548393 },
    { lat: 48.226106, lng: 19.54845 },
    { lat: 48.226218, lng: 19.54879 },
    { lat: 48.226275, lng: 19.548888 },
    { lat: 48.22643, lng: 19.549066 },
    { lat: 48.226554, lng: 19.549171 },
    { lat: 48.226672, lng: 19.549192 },
    { lat: 48.226914, lng: 19.549201 },
    { lat: 48.227012, lng: 19.54919 },
    { lat: 48.227138, lng: 19.549078 },
    { lat: 48.227394, lng: 19.548829 },
    { lat: 48.227434, lng: 19.548816 },
    { lat: 48.227517, lng: 19.548813 },
    { lat: 48.227561, lng: 19.548812 },
    { lat: 48.227875, lng: 19.548969 },
    { lat: 48.22795, lng: 19.548985 },
    { lat: 48.228022, lng: 19.548943 },
    { lat: 48.228084, lng: 19.548896 },
    { lat: 48.228194, lng: 19.548732 },
    { lat: 48.228277, lng: 19.548627 },
    { lat: 48.22831, lng: 19.548597 },
    { lat: 48.228472, lng: 19.548551 },
    { lat: 48.228756, lng: 19.548348 },
    { lat: 48.228881, lng: 19.54828 },
    { lat: 48.229075, lng: 19.548228 },
    { lat: 48.22924, lng: 19.548173 },
    { lat: 48.229553, lng: 19.548073 },
    { lat: 48.229746, lng: 19.548 },
    { lat: 48.229964, lng: 19.547906 },
    { lat: 48.230073, lng: 19.547857 },
    { lat: 48.230274, lng: 19.547649 },
    { lat: 48.230313, lng: 19.547638 },
    { lat: 48.230394, lng: 19.547637 },
    { lat: 48.230597, lng: 19.547656 },
    { lat: 48.23065, lng: 19.547644 },
    { lat: 48.230688, lng: 19.547605 },
    { lat: 48.230715, lng: 19.547554 },
    { lat: 48.230744, lng: 19.547482 },
    { lat: 48.230855, lng: 19.547081 },
    { lat: 48.230875, lng: 19.547018 },
    { lat: 48.2309, lng: 19.546976 },
    { lat: 48.230993, lng: 19.546823 },
    { lat: 48.23103, lng: 19.546788 },
    { lat: 48.231068, lng: 19.546769 },
    { lat: 48.231108, lng: 19.546755 },
    { lat: 48.231189, lng: 19.546755 },
    { lat: 48.23153, lng: 19.546752 },
    { lat: 48.231576, lng: 19.546749 },
    { lat: 48.232141, lng: 19.546423 },
    { lat: 48.232176, lng: 19.546397 },
    { lat: 48.232193, lng: 19.546352 },
    { lat: 48.232435, lng: 19.545678 },
    { lat: 48.232461, lng: 19.545648 },
    { lat: 48.232832, lng: 19.545262 },
    { lat: 48.232856, lng: 19.545242 },
    { lat: 48.233107, lng: 19.545069 },
    { lat: 48.233135, lng: 19.545029 },
    { lat: 48.23316, lng: 19.544975 },
    { lat: 48.233198, lng: 19.544892 },
    { lat: 48.233287, lng: 19.544769 },
    { lat: 48.233465, lng: 19.544632 },
    { lat: 48.233569, lng: 19.544533 },
    { lat: 48.233636, lng: 19.544459 },
    { lat: 48.233804, lng: 19.54421 },
    { lat: 48.233857, lng: 19.54409 },
    { lat: 48.233892, lng: 19.544006 },
    { lat: 48.233903, lng: 19.543851 },
    { lat: 48.233927, lng: 19.543708 },
    { lat: 48.233954, lng: 19.543674 },
    { lat: 48.233986, lng: 19.543665 },
    { lat: 48.234023, lng: 19.543666 },
    { lat: 48.234124, lng: 19.543653 },
    { lat: 48.234191, lng: 19.543614 },
    { lat: 48.234265, lng: 19.543553 },
    { lat: 48.234319, lng: 19.543456 },
    { lat: 48.234366, lng: 19.543362 },
    { lat: 48.234407, lng: 19.543265 },
    { lat: 48.234465, lng: 19.543147 },
    { lat: 48.234523, lng: 19.543052 },
    { lat: 48.234571, lng: 19.543038 },
    { lat: 48.234616, lng: 19.543034 },
    { lat: 48.234655, lng: 19.543045 },
    { lat: 48.234759, lng: 19.543101 },
    { lat: 48.234927, lng: 19.543184 },
    { lat: 48.23501, lng: 19.54322 },
    { lat: 48.235051, lng: 19.54323 },
    { lat: 48.235197, lng: 19.543196 },
    { lat: 48.235325, lng: 19.54306 },
    { lat: 48.235446, lng: 19.542947 },
    { lat: 48.23557, lng: 19.542855 },
    { lat: 48.23575, lng: 19.542732 },
    { lat: 48.23594, lng: 19.542607 },
    { lat: 48.236077, lng: 19.542485 },
    { lat: 48.236114, lng: 19.542464 },
    { lat: 48.236156, lng: 19.542457 },
    { lat: 48.23634, lng: 19.542488 },
    { lat: 48.236598, lng: 19.54259 },
    { lat: 48.236647, lng: 19.542601 },
    { lat: 48.236721, lng: 19.542607 },
    { lat: 48.236825, lng: 19.542603 },
    { lat: 48.23689, lng: 19.542587 },
    { lat: 48.237299, lng: 19.542374 },
    { lat: 48.23734, lng: 19.542335 },
    { lat: 48.237361, lng: 19.542299 },
    { lat: 48.237368, lng: 19.542237 },
    { lat: 48.23739, lng: 19.542076 },
    { lat: 48.237426, lng: 19.541915 },
    { lat: 48.237443, lng: 19.541886 },
    { lat: 48.237486, lng: 19.541849 },
    { lat: 48.237589, lng: 19.54184 },
    { lat: 48.2376385, lng: 19.5415509 },
    { lat: 48.2377347, lng: 19.541465 },
    { lat: 48.2377886, lng: 19.5415333 },
    { lat: 48.2378521, lng: 19.5414662 },
    { lat: 48.2379154, lng: 19.5411553 },
    { lat: 48.2380234, lng: 19.5409324 },
    { lat: 48.238073, lng: 19.5409318 },
    { lat: 48.2381359, lng: 19.540587 },
    { lat: 48.2382421, lng: 19.5405651 },
    { lat: 48.2383792, lng: 19.5402422 },
    { lat: 48.2384987, lng: 19.5401956 },
    { lat: 48.2384668, lng: 19.5399186 },
    { lat: 48.2385777, lng: 19.5397837 },
    { lat: 48.2382141, lng: 19.5381369 },
    { lat: 48.2382121, lng: 19.5373389 },
    { lat: 48.2382704, lng: 19.536551 },
    { lat: 48.2384371, lng: 19.5349429 },
    { lat: 48.2390389, lng: 19.5337263 },
    { lat: 48.2372536, lng: 19.5325272 },
    { lat: 48.2359769, lng: 19.5314801 },
    { lat: 48.2355088, lng: 19.5306227 },
    { lat: 48.2350642, lng: 19.5295646 },
    { lat: 48.2331499, lng: 19.5271159 },
    { lat: 48.2322437, lng: 19.5264585 },
    { lat: 48.2313328, lng: 19.5256971 },
    { lat: 48.2310928, lng: 19.5247737 },
    { lat: 48.230685, lng: 19.5227127 },
    { lat: 48.229604, lng: 19.5208832 },
    { lat: 48.2278356, lng: 19.5160034 },
    { lat: 48.225844, lng: 19.5149284 },
    { lat: 48.2250459, lng: 19.5138795 },
    { lat: 48.2246926, lng: 19.5132134 },
    { lat: 48.2247477, lng: 19.5131398 },
    { lat: 48.224717, lng: 19.5130836 },
    { lat: 48.22467, lng: 19.513138 },
    { lat: 48.2241769, lng: 19.5120609 },
    { lat: 48.2227991, lng: 19.5100337 },
    { lat: 48.2205076, lng: 19.5069054 },
    { lat: 48.2192325, lng: 19.5037222 },
    { lat: 48.2179493, lng: 19.5001506 },
    { lat: 48.2178646, lng: 19.4999749 },
  ],
  Nenince: [
    { lat: 48.1658605, lng: 19.2418295 },
    { lat: 48.1652192, lng: 19.24068 },
    { lat: 48.1646076, lng: 19.2396569 },
    { lat: 48.1645155, lng: 19.2392715 },
    { lat: 48.164409, lng: 19.2393047 },
    { lat: 48.1641551, lng: 19.2389293 },
    { lat: 48.1638288, lng: 19.2382517 },
    { lat: 48.1631601, lng: 19.2385459 },
    { lat: 48.1628455, lng: 19.2383586 },
    { lat: 48.1624073, lng: 19.2386187 },
    { lat: 48.1622021, lng: 19.2380907 },
    { lat: 48.1619623, lng: 19.2376608 },
    { lat: 48.1600439, lng: 19.235107 },
    { lat: 48.1582591, lng: 19.2379272 },
    { lat: 48.1573992, lng: 19.2394751 },
    { lat: 48.1569399, lng: 19.2398113 },
    { lat: 48.1565769, lng: 19.2406421 },
    { lat: 48.1553178, lng: 19.2399742 },
    { lat: 48.154701, lng: 19.2398521 },
    { lat: 48.1543084, lng: 19.2390651 },
    { lat: 48.15304, lng: 19.2380241 },
    { lat: 48.1530093, lng: 19.2379713 },
    { lat: 48.1539456, lng: 19.2366058 },
    { lat: 48.1520158, lng: 19.2339128 },
    { lat: 48.1520432, lng: 19.2319231 },
    { lat: 48.1528535, lng: 19.2306521 },
    { lat: 48.1531416, lng: 19.2304348 },
    { lat: 48.1535284, lng: 19.2300282 },
    { lat: 48.1539002, lng: 19.2298166 },
    { lat: 48.1541287, lng: 19.2295571 },
    { lat: 48.1543876, lng: 19.2291577 },
    { lat: 48.1544119, lng: 19.2287089 },
    { lat: 48.1543167, lng: 19.2282861 },
    { lat: 48.1538616, lng: 19.2279028 },
    { lat: 48.1527797, lng: 19.2260251 },
    { lat: 48.1513812, lng: 19.2244494 },
    { lat: 48.1514372, lng: 19.2242336 },
    { lat: 48.1511965, lng: 19.221789 },
    { lat: 48.1500192, lng: 19.2196689 },
    { lat: 48.1498394, lng: 19.2191972 },
    { lat: 48.1496272, lng: 19.218595 },
    { lat: 48.1491691, lng: 19.2186569 },
    { lat: 48.1489885, lng: 19.2190178 },
    { lat: 48.1481481, lng: 19.2187316 },
    { lat: 48.1477304, lng: 19.218721 },
    { lat: 48.1475684, lng: 19.2186586 },
    { lat: 48.1472222, lng: 19.2189292 },
    { lat: 48.1470761, lng: 19.2189184 },
    { lat: 48.1468138, lng: 19.2191523 },
    { lat: 48.146766, lng: 19.2194434 },
    { lat: 48.1462439, lng: 19.2195283 },
    { lat: 48.1462306, lng: 19.2194884 },
    { lat: 48.1441422, lng: 19.2199297 },
    { lat: 48.1419326, lng: 19.2191702 },
    { lat: 48.1412838, lng: 19.2190434 },
    { lat: 48.1379111, lng: 19.2206817 },
    { lat: 48.1372651, lng: 19.2209955 },
    { lat: 48.1363493, lng: 19.2217887 },
    { lat: 48.1351496, lng: 19.2230435 },
    { lat: 48.1340874, lng: 19.2238633 },
    { lat: 48.1339958, lng: 19.2236514 },
    { lat: 48.1334409, lng: 19.2244921 },
    { lat: 48.1332462, lng: 19.2248183 },
    { lat: 48.1346852, lng: 19.2270369 },
    { lat: 48.1358038, lng: 19.2288817 },
    { lat: 48.1364354, lng: 19.2302679 },
    { lat: 48.1370033, lng: 19.2312753 },
    { lat: 48.1376309, lng: 19.2321164 },
    { lat: 48.1364338, lng: 19.2349937 },
    { lat: 48.1360807, lng: 19.2359527 },
    { lat: 48.1357555, lng: 19.2369767 },
    { lat: 48.1356274, lng: 19.2375815 },
    { lat: 48.1355969, lng: 19.2375566 },
    { lat: 48.1356202, lng: 19.2381257 },
    { lat: 48.1355761, lng: 19.2388585 },
    { lat: 48.1354962, lng: 19.2390949 },
    { lat: 48.1355061, lng: 19.2407675 },
    { lat: 48.1353689, lng: 19.2421577 },
    { lat: 48.1353603, lng: 19.2426231 },
    { lat: 48.1352576, lng: 19.2429907 },
    { lat: 48.1352683, lng: 19.2430943 },
    { lat: 48.1352308, lng: 19.2431101 },
    { lat: 48.1355268, lng: 19.2439783 },
    { lat: 48.1355622, lng: 19.2439868 },
    { lat: 48.1356789, lng: 19.2446682 },
    { lat: 48.1357725, lng: 19.244989 },
    { lat: 48.1358233, lng: 19.2449887 },
    { lat: 48.1355302, lng: 19.2461429 },
    { lat: 48.1357105, lng: 19.247154 },
    { lat: 48.1361045, lng: 19.247767 },
    { lat: 48.1362933, lng: 19.2484924 },
    { lat: 48.136176, lng: 19.2491129 },
    { lat: 48.1358457, lng: 19.2501981 },
    { lat: 48.1356769, lng: 19.2509162 },
    { lat: 48.1348894, lng: 19.2552621 },
    { lat: 48.1347592, lng: 19.255711 },
    { lat: 48.1343052, lng: 19.2569208 },
    { lat: 48.1337885, lng: 19.2588473 },
    { lat: 48.1325683, lng: 19.2615889 },
    { lat: 48.132122, lng: 19.2624914 },
    { lat: 48.1330018, lng: 19.2637489 },
    { lat: 48.1334576, lng: 19.2645172 },
    { lat: 48.133644, lng: 19.2649454 },
    { lat: 48.1337209, lng: 19.2649709 },
    { lat: 48.1339837, lng: 19.2656473 },
    { lat: 48.1338302, lng: 19.2669125 },
    { lat: 48.1342285, lng: 19.2677538 },
    { lat: 48.1345962, lng: 19.2682466 },
    { lat: 48.1351369, lng: 19.2678674 },
    { lat: 48.1351647, lng: 19.267954 },
    { lat: 48.1352901, lng: 19.268006 },
    { lat: 48.1354092, lng: 19.2681586 },
    { lat: 48.1354621, lng: 19.2684581 },
    { lat: 48.1357928, lng: 19.2685805 },
    { lat: 48.1360036, lng: 19.2684214 },
    { lat: 48.1361166, lng: 19.2682214 },
    { lat: 48.136175, lng: 19.2678674 },
    { lat: 48.1362516, lng: 19.2677479 },
    { lat: 48.1365312, lng: 19.2677264 },
    { lat: 48.1368455, lng: 19.2675178 },
    { lat: 48.1373776, lng: 19.2684653 },
    { lat: 48.1377777, lng: 19.269435 },
    { lat: 48.1377481, lng: 19.2696171 },
    { lat: 48.1378043, lng: 19.2696548 },
    { lat: 48.1383578, lng: 19.271142 },
    { lat: 48.1383734, lng: 19.271321 },
    { lat: 48.1379176, lng: 19.2724716 },
    { lat: 48.1377831, lng: 19.2730434 },
    { lat: 48.1377727, lng: 19.2734306 },
    { lat: 48.1378036, lng: 19.2740656 },
    { lat: 48.1377473, lng: 19.2752236 },
    { lat: 48.1380177, lng: 19.2765423 },
    { lat: 48.138133, lng: 19.2773594 },
    { lat: 48.1381941, lng: 19.2785463 },
    { lat: 48.1388962, lng: 19.2787968 },
    { lat: 48.1393862, lng: 19.2788142 },
    { lat: 48.1396434, lng: 19.2787743 },
    { lat: 48.1396286, lng: 19.2787222 },
    { lat: 48.1398229, lng: 19.2786376 },
    { lat: 48.1398434, lng: 19.2786915 },
    { lat: 48.1404166, lng: 19.2803271 },
    { lat: 48.1397839, lng: 19.2805271 },
    { lat: 48.1396169, lng: 19.2806401 },
    { lat: 48.1397197, lng: 19.2808986 },
    { lat: 48.1394949, lng: 19.2810747 },
    { lat: 48.1399906, lng: 19.2828747 },
    { lat: 48.1408578, lng: 19.2846472 },
    { lat: 48.1431277, lng: 19.2881011 },
    { lat: 48.1428881, lng: 19.28931 },
    { lat: 48.1426466, lng: 19.289195 },
    { lat: 48.1426135, lng: 19.2893747 },
    { lat: 48.1427116, lng: 19.2898388 },
    { lat: 48.1430754, lng: 19.2908334 },
    { lat: 48.1436199, lng: 19.2926026 },
    { lat: 48.1443993, lng: 19.2945634 },
    { lat: 48.1440522, lng: 19.2958655 },
    { lat: 48.1452577, lng: 19.2975996 },
    { lat: 48.1463739, lng: 19.2996512 },
    { lat: 48.1474435, lng: 19.3022324 },
    { lat: 48.1476879, lng: 19.3029295 },
    { lat: 48.1479411, lng: 19.3040362 },
    { lat: 48.1483382, lng: 19.3053694 },
    { lat: 48.1487591, lng: 19.3064531 },
    { lat: 48.1487778, lng: 19.3069092 },
    { lat: 48.1487341, lng: 19.3073085 },
    { lat: 48.1488176, lng: 19.3082433 },
    { lat: 48.148959, lng: 19.3091648 },
    { lat: 48.1489912, lng: 19.3092248 },
    { lat: 48.1490211, lng: 19.3092138 },
    { lat: 48.151199, lng: 19.3087535 },
    { lat: 48.1517355, lng: 19.3083556 },
    { lat: 48.1527805, lng: 19.3077994 },
    { lat: 48.1533339, lng: 19.3073979 },
    { lat: 48.1537709, lng: 19.3069741 },
    { lat: 48.1544148, lng: 19.3059539 },
    { lat: 48.1547738, lng: 19.3006102 },
    { lat: 48.1548761, lng: 19.299089 },
    { lat: 48.1543045, lng: 19.2990647 },
    { lat: 48.1543484, lng: 19.2985276 },
    { lat: 48.1545261, lng: 19.2974762 },
    { lat: 48.1546487, lng: 19.2970281 },
    { lat: 48.1550791, lng: 19.2957675 },
    { lat: 48.155342, lng: 19.2952177 },
    { lat: 48.15553, lng: 19.2946759 },
    { lat: 48.1557475, lng: 19.2938129 },
    { lat: 48.155995, lng: 19.2931973 },
    { lat: 48.1567572, lng: 19.2924627 },
    { lat: 48.1571597, lng: 19.2918592 },
    { lat: 48.1572641, lng: 19.2892681 },
    { lat: 48.1574789, lng: 19.287869 },
    { lat: 48.1569515, lng: 19.2874514 },
    { lat: 48.1569604, lng: 19.2872146 },
    { lat: 48.1573001, lng: 19.2853428 },
    { lat: 48.1574288, lng: 19.2853723 },
    { lat: 48.1574717, lng: 19.2850485 },
    { lat: 48.1571959, lng: 19.2837577 },
    { lat: 48.1574399, lng: 19.2829546 },
    { lat: 48.1575502, lng: 19.2815344 },
    { lat: 48.1574373, lng: 19.2809999 },
    { lat: 48.1572092, lng: 19.2805749 },
    { lat: 48.1571497, lng: 19.2803455 },
    { lat: 48.1571039, lng: 19.279589 },
    { lat: 48.1570356, lng: 19.2793892 },
    { lat: 48.1556936, lng: 19.2781465 },
    { lat: 48.1537624, lng: 19.2735283 },
    { lat: 48.1539798, lng: 19.2732501 },
    { lat: 48.1541718, lng: 19.2728162 },
    { lat: 48.1543103, lng: 19.2718862 },
    { lat: 48.1536585, lng: 19.2708617 },
    { lat: 48.1530776, lng: 19.26854 },
    { lat: 48.1540957, lng: 19.2682213 },
    { lat: 48.1550503, lng: 19.2676363 },
    { lat: 48.1552533, lng: 19.2675478 },
    { lat: 48.1556389, lng: 19.2675059 },
    { lat: 48.156886, lng: 19.266865 },
    { lat: 48.1574429, lng: 19.2663825 },
    { lat: 48.157772, lng: 19.2660113 },
    { lat: 48.1581522, lng: 19.26553 },
    { lat: 48.1583279, lng: 19.2651561 },
    { lat: 48.1598133, lng: 19.2633256 },
    { lat: 48.161649, lng: 19.2617632 },
    { lat: 48.1616476, lng: 19.261202 },
    { lat: 48.1615232, lng: 19.2605101 },
    { lat: 48.1614787, lng: 19.2602629 },
    { lat: 48.1607969, lng: 19.2578396 },
    { lat: 48.1605881, lng: 19.2573032 },
    { lat: 48.1603606, lng: 19.2568912 },
    { lat: 48.1595912, lng: 19.2557218 },
    { lat: 48.1594956, lng: 19.2551243 },
    { lat: 48.1594865, lng: 19.2536026 },
    { lat: 48.1594168, lng: 19.2524953 },
    { lat: 48.1593026, lng: 19.2516371 },
    { lat: 48.160668, lng: 19.251875 },
    { lat: 48.1605091, lng: 19.2510641 },
    { lat: 48.1604285, lng: 19.2503152 },
    { lat: 48.1610562, lng: 19.2496684 },
    { lat: 48.1630851, lng: 19.2468278 },
    { lat: 48.1640413, lng: 19.2451761 },
    { lat: 48.1645839, lng: 19.2437772 },
    { lat: 48.1657025, lng: 19.2422193 },
    { lat: 48.1658605, lng: 19.2418295 },
  ],
  Bušince: [
    { lat: 48.1693808, lng: 19.477358 },
    { lat: 48.1678929, lng: 19.4769631 },
    { lat: 48.1667068, lng: 19.4748647 },
    { lat: 48.1661459, lng: 19.4747487 },
    { lat: 48.1655777, lng: 19.4748898 },
    { lat: 48.1643332, lng: 19.474958 },
    { lat: 48.1630516, lng: 19.4743996 },
    { lat: 48.1619722, lng: 19.473685 },
    { lat: 48.161655, lng: 19.4735046 },
    { lat: 48.1616201, lng: 19.4735291 },
    { lat: 48.1615604, lng: 19.4739635 },
    { lat: 48.1613533, lng: 19.4744219 },
    { lat: 48.160856, lng: 19.4752107 },
    { lat: 48.1597059, lng: 19.4775337 },
    { lat: 48.1593436, lng: 19.4788173 },
    { lat: 48.1586098, lng: 19.4799717 },
    { lat: 48.1583967, lng: 19.4805234 },
    { lat: 48.1582659, lng: 19.4811442 },
    { lat: 48.1582688, lng: 19.481404 },
    { lat: 48.1584388, lng: 19.4832746 },
    { lat: 48.1586752, lng: 19.4845696 },
    { lat: 48.1586774, lng: 19.4849556 },
    { lat: 48.158484, lng: 19.4857325 },
    { lat: 48.1582525, lng: 19.4863723 },
    { lat: 48.157789, lng: 19.4871376 },
    { lat: 48.1574063, lng: 19.4879425 },
    { lat: 48.1565773, lng: 19.4901475 },
    { lat: 48.1564259, lng: 19.4907185 },
    { lat: 48.1562902, lng: 19.4913369 },
    { lat: 48.1561401, lng: 19.4935267 },
    { lat: 48.1560692, lng: 19.4936725 },
    { lat: 48.1557501, lng: 19.4969469 },
    { lat: 48.1553206, lng: 19.4983695 },
    { lat: 48.1551411, lng: 19.4987991 },
    { lat: 48.1541864, lng: 19.5005835 },
    { lat: 48.1539192, lng: 19.5013754 },
    { lat: 48.1538815, lng: 19.5017062 },
    { lat: 48.1539762, lng: 19.5046785 },
    { lat: 48.1529379, lng: 19.5097731 },
    { lat: 48.152652, lng: 19.5098303 },
    { lat: 48.15231, lng: 19.510196 },
    { lat: 48.152314, lng: 19.510202 },
    { lat: 48.152874, lng: 19.510971 },
    { lat: 48.153006, lng: 19.511155 },
    { lat: 48.153086, lng: 19.511264 },
    { lat: 48.153381, lng: 19.511665 },
    { lat: 48.15348, lng: 19.511801 },
    { lat: 48.153731, lng: 19.512106 },
    { lat: 48.153803, lng: 19.512187 },
    { lat: 48.153924, lng: 19.512299 },
    { lat: 48.153939, lng: 19.51231 },
    { lat: 48.15411, lng: 19.512455 },
    { lat: 48.15427, lng: 19.512591 },
    { lat: 48.15444, lng: 19.5127 },
    { lat: 48.154505, lng: 19.512731 },
    { lat: 48.154663, lng: 19.512822 },
    { lat: 48.154821, lng: 19.512888 },
    { lat: 48.154925, lng: 19.512922 },
    { lat: 48.15503, lng: 19.512944 },
    { lat: 48.15518, lng: 19.512975 },
    { lat: 48.155321, lng: 19.512979 },
    { lat: 48.155504, lng: 19.512983 },
    { lat: 48.155655, lng: 19.512968 },
    { lat: 48.155828, lng: 19.512945 },
    { lat: 48.156032, lng: 19.512915 },
    { lat: 48.156065, lng: 19.512909 },
    { lat: 48.15622, lng: 19.512882 },
    { lat: 48.156268, lng: 19.512877 },
    { lat: 48.156403, lng: 19.512848 },
    { lat: 48.156445, lng: 19.51283 },
    { lat: 48.156559, lng: 19.512775 },
    { lat: 48.156674, lng: 19.512708 },
    { lat: 48.156734, lng: 19.512652 },
    { lat: 48.156974, lng: 19.512475 },
    { lat: 48.156999, lng: 19.512452 },
    { lat: 48.157096, lng: 19.512386 },
    { lat: 48.157288, lng: 19.512241 },
    { lat: 48.157487, lng: 19.512095 },
    { lat: 48.157686, lng: 19.511938 },
    { lat: 48.157885, lng: 19.511781 },
    { lat: 48.158045, lng: 19.511655 },
    { lat: 48.158204, lng: 19.511532 },
    { lat: 48.15843, lng: 19.511376 },
    { lat: 48.158536, lng: 19.511296 },
    { lat: 48.158763, lng: 19.511132 },
    { lat: 48.158932, lng: 19.511018 },
    { lat: 48.158967, lng: 19.511002 },
    { lat: 48.159069, lng: 19.510957 },
    { lat: 48.159103, lng: 19.510943 },
    { lat: 48.159198, lng: 19.51088 },
    { lat: 48.159283, lng: 19.510847 },
    { lat: 48.159457, lng: 19.510761 },
    { lat: 48.159597, lng: 19.510721 },
    { lat: 48.159664, lng: 19.510692 },
    { lat: 48.15981, lng: 19.510687 },
    { lat: 48.159819, lng: 19.510689 },
    { lat: 48.159942, lng: 19.510707 },
    { lat: 48.159982, lng: 19.510712 },
    { lat: 48.160066, lng: 19.510737 },
    { lat: 48.160236, lng: 19.510798 },
    { lat: 48.160381, lng: 19.510865 },
    { lat: 48.160612, lng: 19.511015 },
    { lat: 48.16072, lng: 19.511092 },
    { lat: 48.160933, lng: 19.511261 },
    { lat: 48.161113, lng: 19.511412 },
    { lat: 48.161335, lng: 19.511587 },
    { lat: 48.161534, lng: 19.511719 },
    { lat: 48.161579, lng: 19.511751 },
    { lat: 48.161756, lng: 19.511862 },
    { lat: 48.161923, lng: 19.511955 },
    { lat: 48.162057, lng: 19.51205 },
    { lat: 48.162197, lng: 19.51214 },
    { lat: 48.162288, lng: 19.512157 },
    { lat: 48.162397, lng: 19.512145 },
    { lat: 48.162508, lng: 19.512161 },
    { lat: 48.162552, lng: 19.512159 },
    { lat: 48.162588, lng: 19.512161 },
    { lat: 48.162702, lng: 19.512117 },
    { lat: 48.162847, lng: 19.512059 },
    { lat: 48.162945, lng: 19.512 },
    { lat: 48.162971, lng: 19.511995 },
    { lat: 48.163046, lng: 19.511964 },
    { lat: 48.163124, lng: 19.511983 },
    { lat: 48.163264, lng: 19.511949 },
    { lat: 48.163309, lng: 19.511938 },
    { lat: 48.1633867, lng: 19.5119073 },
    { lat: 48.163464, lng: 19.511877 },
    { lat: 48.163623, lng: 19.511829 },
    { lat: 48.163699, lng: 19.511819 },
    { lat: 48.164016, lng: 19.511726 },
    { lat: 48.164291, lng: 19.511617 },
    { lat: 48.164729, lng: 19.511426 },
    { lat: 48.164918, lng: 19.511354 },
    { lat: 48.165188, lng: 19.511226 },
    { lat: 48.165247, lng: 19.511209 },
    { lat: 48.165417, lng: 19.511199 },
    { lat: 48.165474, lng: 19.511178 },
    { lat: 48.16549, lng: 19.511126 },
    { lat: 48.165606, lng: 19.511115 },
    { lat: 48.165701, lng: 19.511086 },
    { lat: 48.165979, lng: 19.510981 },
    { lat: 48.166037, lng: 19.510978 },
    { lat: 48.166186, lng: 19.510916 },
    { lat: 48.166285, lng: 19.510877 },
    { lat: 48.166307, lng: 19.51086 },
    { lat: 48.166404, lng: 19.510767 },
    { lat: 48.166496, lng: 19.510671 },
    { lat: 48.166665, lng: 19.51033 },
    { lat: 48.166811, lng: 19.510042 },
    { lat: 48.166859, lng: 19.50996 },
    { lat: 48.167017, lng: 19.509755 },
    { lat: 48.167187, lng: 19.509572 },
    { lat: 48.167317, lng: 19.509522 },
    { lat: 48.167504, lng: 19.509489 },
    { lat: 48.167673, lng: 19.509526 },
    { lat: 48.167835, lng: 19.509586 },
    { lat: 48.168143, lng: 19.509733 },
    { lat: 48.168354, lng: 19.509818 },
    { lat: 48.168529, lng: 19.509822 },
    { lat: 48.168666, lng: 19.509831 },
    { lat: 48.168859, lng: 19.50977 },
    { lat: 48.169006, lng: 19.509704 },
    { lat: 48.169222, lng: 19.50957 },
    { lat: 48.169417, lng: 19.5094 },
    { lat: 48.169511, lng: 19.509355 },
    { lat: 48.169639, lng: 19.509268 },
    { lat: 48.169712, lng: 19.509219 },
    { lat: 48.169792, lng: 19.50917 },
    { lat: 48.170137, lng: 19.50898 },
    { lat: 48.170342, lng: 19.508875 },
    { lat: 48.170394, lng: 19.508845 },
    { lat: 48.170437, lng: 19.508842 },
    { lat: 48.170566, lng: 19.508853 },
    { lat: 48.170891, lng: 19.508758 },
    { lat: 48.171037, lng: 19.508657 },
    { lat: 48.171123, lng: 19.508542 },
    { lat: 48.17124, lng: 19.508325 },
    { lat: 48.171317, lng: 19.508144 },
    { lat: 48.171482, lng: 19.507736 },
    { lat: 48.171526, lng: 19.507613 },
    { lat: 48.171551, lng: 19.507554 },
    { lat: 48.171729, lng: 19.50734 },
    { lat: 48.171893, lng: 19.507133 },
    { lat: 48.172038, lng: 19.506874 },
    { lat: 48.172128, lng: 19.506757 },
    { lat: 48.172237, lng: 19.506594 },
    { lat: 48.17236, lng: 19.5062 },
    { lat: 48.172587, lng: 19.505089 },
    { lat: 48.17266, lng: 19.504873 },
    { lat: 48.172801, lng: 19.504741 },
    { lat: 48.172911, lng: 19.504659 },
    { lat: 48.173101, lng: 19.504539 },
    { lat: 48.173186, lng: 19.504484 },
    { lat: 48.173371, lng: 19.504513 },
    { lat: 48.173443, lng: 19.504518 },
    { lat: 48.173549, lng: 19.504594 },
    { lat: 48.17365, lng: 19.504673 },
    { lat: 48.173663, lng: 19.504706 },
    { lat: 48.173705, lng: 19.504757 },
    { lat: 48.173738, lng: 19.50481 },
    { lat: 48.173901, lng: 19.505036 },
    { lat: 48.17392, lng: 19.505087 },
    { lat: 48.17411, lng: 19.505467 },
    { lat: 48.174161, lng: 19.505564 },
    { lat: 48.174296, lng: 19.505892 },
    { lat: 48.17448, lng: 19.506261 },
    { lat: 48.174497, lng: 19.506338 },
    { lat: 48.174543, lng: 19.506466 },
    { lat: 48.174832, lng: 19.507172 },
    { lat: 48.174908, lng: 19.507468 },
    { lat: 48.174927, lng: 19.507685 },
    { lat: 48.174902, lng: 19.507837 },
    { lat: 48.174838, lng: 19.508021 },
    { lat: 48.174789, lng: 19.508182 },
    { lat: 48.174705, lng: 19.508323 },
    { lat: 48.174668, lng: 19.508401 },
    { lat: 48.174651, lng: 19.508433 },
    { lat: 48.174634, lng: 19.508538 },
    { lat: 48.174621, lng: 19.508627 },
    { lat: 48.174612, lng: 19.5087 },
    { lat: 48.174615, lng: 19.508804 },
    { lat: 48.174647, lng: 19.508962 },
    { lat: 48.174685, lng: 19.509103 },
    { lat: 48.174753, lng: 19.50923 },
    { lat: 48.174872, lng: 19.509445 },
    { lat: 48.174934, lng: 19.509571 },
    { lat: 48.174979, lng: 19.509641 },
    { lat: 48.175075, lng: 19.509707 },
    { lat: 48.175178, lng: 19.509745 },
    { lat: 48.175333, lng: 19.509679 },
    { lat: 48.175398, lng: 19.50961 },
    { lat: 48.175435, lng: 19.509532 },
    { lat: 48.175476, lng: 19.50943 },
    { lat: 48.175554, lng: 19.50904 },
    { lat: 48.175575, lng: 19.508794 },
    { lat: 48.175574, lng: 19.508686 },
    { lat: 48.175619, lng: 19.508522 },
    { lat: 48.175644, lng: 19.508345 },
    { lat: 48.175691, lng: 19.508173 },
    { lat: 48.175808, lng: 19.508009 },
    { lat: 48.175893, lng: 19.507963 },
    { lat: 48.176063, lng: 19.507899 },
    { lat: 48.176273, lng: 19.507915 },
    { lat: 48.176382, lng: 19.507964 },
    { lat: 48.176744, lng: 19.508104 },
    { lat: 48.176981, lng: 19.508121 },
    { lat: 48.177192, lng: 19.508088 },
    { lat: 48.177305, lng: 19.508013 },
    { lat: 48.177394, lng: 19.507878 },
    { lat: 48.177578, lng: 19.507577 },
    { lat: 48.177647, lng: 19.507532 },
    { lat: 48.17782, lng: 19.507444 },
    { lat: 48.177864, lng: 19.507421 },
    { lat: 48.17792, lng: 19.507409 },
    { lat: 48.178096, lng: 19.507381 },
    { lat: 48.17818, lng: 19.507396 },
    { lat: 48.178308, lng: 19.507462 },
    { lat: 48.178415, lng: 19.507527 },
    { lat: 48.178532, lng: 19.507615 },
    { lat: 48.17856, lng: 19.507757 },
    { lat: 48.178588, lng: 19.507935 },
    { lat: 48.178586, lng: 19.508118 },
    { lat: 48.178567, lng: 19.508272 },
    { lat: 48.178522, lng: 19.508472 },
    { lat: 48.17851, lng: 19.508494 },
    { lat: 48.178262, lng: 19.509019 },
    { lat: 48.178202, lng: 19.509133 },
    { lat: 48.17803, lng: 19.509409 },
    { lat: 48.177905, lng: 19.50963 },
    { lat: 48.177692, lng: 19.50999 },
    { lat: 48.177593, lng: 19.510263 },
    { lat: 48.177554, lng: 19.510553 },
    { lat: 48.177533, lng: 19.51084 },
    { lat: 48.177531, lng: 19.511129 },
    { lat: 48.177515, lng: 19.511581 },
    { lat: 48.177535, lng: 19.511691 },
    { lat: 48.177549, lng: 19.511777 },
    { lat: 48.17763, lng: 19.511859 },
    { lat: 48.177715, lng: 19.511868 },
    { lat: 48.177864, lng: 19.511778 },
    { lat: 48.177961, lng: 19.511606 },
    { lat: 48.178063, lng: 19.511367 },
    { lat: 48.178203, lng: 19.510979 },
    { lat: 48.178441, lng: 19.51049 },
    { lat: 48.178571, lng: 19.510404 },
    { lat: 48.178607, lng: 19.510388 },
    { lat: 48.178632, lng: 19.510385 },
    { lat: 48.178801, lng: 19.510411 },
    { lat: 48.178937, lng: 19.510507 },
    { lat: 48.179165, lng: 19.510705 },
    { lat: 48.17964, lng: 19.510963 },
    { lat: 48.179883, lng: 19.5111 },
    { lat: 48.18008, lng: 19.511208 },
    { lat: 48.180228, lng: 19.511314 },
    { lat: 48.180309, lng: 19.511393 },
    { lat: 48.180596, lng: 19.511669 },
    { lat: 48.180719, lng: 19.511774 },
    { lat: 48.181115, lng: 19.512071 },
    { lat: 48.181273, lng: 19.512158 },
    { lat: 48.181399, lng: 19.512233 },
    { lat: 48.181558, lng: 19.512322 },
    { lat: 48.181678, lng: 19.512405 },
    { lat: 48.181797, lng: 19.512454 },
    { lat: 48.181981, lng: 19.51268 },
    { lat: 48.1838359, lng: 19.508856 },
    { lat: 48.1841299, lng: 19.5085609 },
    { lat: 48.1853025, lng: 19.5063736 },
    { lat: 48.1859655, lng: 19.5065998 },
    { lat: 48.1865889, lng: 19.5070144 },
    { lat: 48.187461, lng: 19.5070087 },
    { lat: 48.1882818, lng: 19.5068957 },
    { lat: 48.1886231, lng: 19.5065521 },
    { lat: 48.1894285, lng: 19.5063001 },
    { lat: 48.1907828, lng: 19.5064917 },
    { lat: 48.1933768, lng: 19.50651 },
    { lat: 48.1939614, lng: 19.5062121 },
    { lat: 48.195279, lng: 19.5059971 },
    { lat: 48.1986836, lng: 19.5058278 },
    { lat: 48.1991, lng: 19.5064693 },
    { lat: 48.200468, lng: 19.5061787 },
    { lat: 48.2008093, lng: 19.5062456 },
    { lat: 48.2023809, lng: 19.5060426 },
    { lat: 48.203904, lng: 19.5055698 },
    { lat: 48.2054762, lng: 19.5062001 },
    { lat: 48.2063985, lng: 19.5079037 },
    { lat: 48.2063737, lng: 19.5081468 },
    { lat: 48.206681, lng: 19.5079693 },
    { lat: 48.2068705, lng: 19.5080686 },
    { lat: 48.2070914, lng: 19.5083339 },
    { lat: 48.2073108, lng: 19.5084979 },
    { lat: 48.2076253, lng: 19.5085711 },
    { lat: 48.2077461, lng: 19.508696 },
    { lat: 48.2079338, lng: 19.5086853 },
    { lat: 48.208294, lng: 19.5082909 },
    { lat: 48.2084764, lng: 19.5085524 },
    { lat: 48.2087008, lng: 19.5082898 },
    { lat: 48.2093412, lng: 19.5088757 },
    { lat: 48.2094147, lng: 19.509127 },
    { lat: 48.2096824, lng: 19.5092623 },
    { lat: 48.2102095, lng: 19.5091297 },
    { lat: 48.2102643, lng: 19.5086113 },
    { lat: 48.210407, lng: 19.5085838 },
    { lat: 48.2105378, lng: 19.5087758 },
    { lat: 48.2107384, lng: 19.5083404 },
    { lat: 48.2118497, lng: 19.5066725 },
    { lat: 48.2121497, lng: 19.5060755 },
    { lat: 48.2125188, lng: 19.5057699 },
    { lat: 48.2125821, lng: 19.5054361 },
    { lat: 48.212804, lng: 19.5049017 },
    { lat: 48.2134356, lng: 19.5043776 },
    { lat: 48.2138496, lng: 19.5039256 },
    { lat: 48.2143051, lng: 19.5039301 },
    { lat: 48.2145804, lng: 19.5035752 },
    { lat: 48.2151514, lng: 19.5032047 },
    { lat: 48.2151834, lng: 19.5029985 },
    { lat: 48.2155166, lng: 19.5026277 },
    { lat: 48.2154956, lng: 19.5025879 },
    { lat: 48.2137808, lng: 19.4995955 },
    { lat: 48.2126396, lng: 19.4983023 },
    { lat: 48.2110805, lng: 19.4956551 },
    { lat: 48.210531, lng: 19.4965113 },
    { lat: 48.2097476, lng: 19.4963853 },
    { lat: 48.2048491, lng: 19.4920279 },
    { lat: 48.2049789, lng: 19.4915964 },
    { lat: 48.2043887, lng: 19.4907869 },
    { lat: 48.2043134, lng: 19.4903521 },
    { lat: 48.2043495, lng: 19.4899932 },
    { lat: 48.2042309, lng: 19.4892885 },
    { lat: 48.2033065, lng: 19.4871853 },
    { lat: 48.2023626, lng: 19.4869777 },
    { lat: 48.2018872, lng: 19.4872451 },
    { lat: 48.2015465, lng: 19.4869041 },
    { lat: 48.2012118, lng: 19.4869282 },
    { lat: 48.2010319, lng: 19.487272 },
    { lat: 48.2004263, lng: 19.487264 },
    { lat: 48.2003168, lng: 19.4873577 },
    { lat: 48.1998583, lng: 19.4874301 },
    { lat: 48.1989482, lng: 19.4878057 },
    { lat: 48.1984189, lng: 19.4877395 },
    { lat: 48.197883, lng: 19.4873886 },
    { lat: 48.1975501, lng: 19.4873101 },
    { lat: 48.1974426, lng: 19.4873955 },
    { lat: 48.1972902, lng: 19.4873425 },
    { lat: 48.1972258, lng: 19.4871214 },
    { lat: 48.1970967, lng: 19.4870364 },
    { lat: 48.1969889, lng: 19.4868022 },
    { lat: 48.1967067, lng: 19.4869599 },
    { lat: 48.1964626, lng: 19.4869462 },
    { lat: 48.1963633, lng: 19.4868485 },
    { lat: 48.1963562, lng: 19.4866716 },
    { lat: 48.1955085, lng: 19.4859715 },
    { lat: 48.1952701, lng: 19.4858817 },
    { lat: 48.1951394, lng: 19.4860059 },
    { lat: 48.1950235, lng: 19.48584 },
    { lat: 48.1948846, lng: 19.4858344 },
    { lat: 48.1947592, lng: 19.4861633 },
    { lat: 48.1941748, lng: 19.4859072 },
    { lat: 48.1941173, lng: 19.4860305 },
    { lat: 48.1940116, lng: 19.4859621 },
    { lat: 48.1939453, lng: 19.4858235 },
    { lat: 48.1938379, lng: 19.4858644 },
    { lat: 48.1933527, lng: 19.4855845 },
    { lat: 48.1929847, lng: 19.4852334 },
    { lat: 48.1927004, lng: 19.484508 },
    { lat: 48.1893332, lng: 19.4864863 },
    { lat: 48.188569, lng: 19.4847029 },
    { lat: 48.1881987, lng: 19.4835324 },
    { lat: 48.1877404, lng: 19.4813741 },
    { lat: 48.1873397, lng: 19.4798158 },
    { lat: 48.1865295, lng: 19.4779162 },
    { lat: 48.1860816, lng: 19.4763453 },
    { lat: 48.1850729, lng: 19.4749985 },
    { lat: 48.18489, lng: 19.47494 },
    { lat: 48.1840573, lng: 19.4749192 },
    { lat: 48.1832112, lng: 19.475117 },
    { lat: 48.1826428, lng: 19.4759506 },
    { lat: 48.1820404, lng: 19.4763237 },
    { lat: 48.1818249, lng: 19.4761948 },
    { lat: 48.1813236, lng: 19.4756032 },
    { lat: 48.1806497, lng: 19.4750954 },
    { lat: 48.1801534, lng: 19.4749914 },
    { lat: 48.1796078, lng: 19.4752286 },
    { lat: 48.1789166, lng: 19.4750775 },
    { lat: 48.1784612, lng: 19.4757012 },
    { lat: 48.1781784, lng: 19.4762064 },
    { lat: 48.1770393, lng: 19.4760683 },
    { lat: 48.1766016, lng: 19.476087 },
    { lat: 48.1704284, lng: 19.4775156 },
    { lat: 48.1701745, lng: 19.4775542 },
    { lat: 48.1693808, lng: 19.477358 },
  ],
  Brusník: [
    { lat: 48.2833018, lng: 19.4129354 },
    { lat: 48.2833629, lng: 19.4130343 },
    { lat: 48.2835209, lng: 19.4127536 },
    { lat: 48.2837719, lng: 19.4124835 },
    { lat: 48.2840076, lng: 19.4123441 },
    { lat: 48.2840527, lng: 19.4121047 },
    { lat: 48.2843953, lng: 19.4116087 },
    { lat: 48.2845693, lng: 19.4117813 },
    { lat: 48.2856955, lng: 19.4134763 },
    { lat: 48.285666, lng: 19.413496 },
    { lat: 48.2857676, lng: 19.4138579 },
    { lat: 48.2858637, lng: 19.4145816 },
    { lat: 48.2859892, lng: 19.4150163 },
    { lat: 48.2863089, lng: 19.4157251 },
    { lat: 48.2864114, lng: 19.4157952 },
    { lat: 48.2866959, lng: 19.4156635 },
    { lat: 48.286884, lng: 19.4156849 },
    { lat: 48.286986, lng: 19.4160534 },
    { lat: 48.2870815, lng: 19.4161664 },
    { lat: 48.287335, lng: 19.4163526 },
    { lat: 48.2875045, lng: 19.4163869 },
    { lat: 48.287926, lng: 19.4162201 },
    { lat: 48.2882732, lng: 19.4162201 },
    { lat: 48.2884856, lng: 19.4159724 },
    { lat: 48.2886109, lng: 19.416095 },
    { lat: 48.2887547, lng: 19.4159194 },
    { lat: 48.2889478, lng: 19.4160426 },
    { lat: 48.2894962, lng: 19.4154139 },
    { lat: 48.2897572, lng: 19.4152846 },
    { lat: 48.2899953, lng: 19.4154087 },
    { lat: 48.2905484, lng: 19.4151444 },
    { lat: 48.2907965, lng: 19.4150971 },
    { lat: 48.2908468, lng: 19.4148971 },
    { lat: 48.2909922, lng: 19.4147309 },
    { lat: 48.2911167, lng: 19.4147421 },
    { lat: 48.2914312, lng: 19.4144111 },
    { lat: 48.2922077, lng: 19.4142649 },
    { lat: 48.2927709, lng: 19.4139287 },
    { lat: 48.2930099, lng: 19.4136888 },
    { lat: 48.2931983, lng: 19.4136674 },
    { lat: 48.2933621, lng: 19.4135113 },
    { lat: 48.2935725, lng: 19.4134808 },
    { lat: 48.2939508, lng: 19.4133036 },
    { lat: 48.2940691, lng: 19.4133035 },
    { lat: 48.2945738, lng: 19.4135641 },
    { lat: 48.2948172, lng: 19.4139159 },
    { lat: 48.2950019, lng: 19.4140099 },
    { lat: 48.2954597, lng: 19.4148022 },
    { lat: 48.2958516, lng: 19.4160129 },
    { lat: 48.2960282, lng: 19.4164054 },
    { lat: 48.2962047, lng: 19.4171632 },
    { lat: 48.2961603, lng: 19.4180365 },
    { lat: 48.2962183, lng: 19.4185446 },
    { lat: 48.2961607, lng: 19.4192597 },
    { lat: 48.2959089, lng: 19.4199893 },
    { lat: 48.2961065, lng: 19.4212871 },
    { lat: 48.2961386, lng: 19.4220372 },
    { lat: 48.2961111, lng: 19.4230294 },
    { lat: 48.2960161, lng: 19.4233587 },
    { lat: 48.2960398, lng: 19.4239052 },
    { lat: 48.2965664, lng: 19.4257249 },
    { lat: 48.29702, lng: 19.4264042 },
    { lat: 48.2983765, lng: 19.4268289 },
    { lat: 48.2994317, lng: 19.4274034 },
    { lat: 48.2996138, lng: 19.4283054 },
    { lat: 48.2994752, lng: 19.4283771 },
    { lat: 48.2997169, lng: 19.4288899 },
    { lat: 48.2994461, lng: 19.4292672 },
    { lat: 48.2995617, lng: 19.4296847 },
    { lat: 48.3002949, lng: 19.4348644 },
    { lat: 48.3003441, lng: 19.4358588 },
    { lat: 48.3003619, lng: 19.4366459 },
    { lat: 48.3007084, lng: 19.4365123 },
    { lat: 48.3009878, lng: 19.4367231 },
    { lat: 48.3012646, lng: 19.4367632 },
    { lat: 48.3023652, lng: 19.4367505 },
    { lat: 48.3028211, lng: 19.4366467 },
    { lat: 48.3029929, lng: 19.4369439 },
    { lat: 48.3031661, lng: 19.4377252 },
    { lat: 48.3036178, lng: 19.4388704 },
    { lat: 48.3037422, lng: 19.4390696 },
    { lat: 48.3046387, lng: 19.4388909 },
    { lat: 48.3046057, lng: 19.4385949 },
    { lat: 48.3046372, lng: 19.4384273 },
    { lat: 48.3048233, lng: 19.4380204 },
    { lat: 48.3058361, lng: 19.4364332 },
    { lat: 48.3061973, lng: 19.4360629 },
    { lat: 48.3066027, lng: 19.4350978 },
    { lat: 48.3069721, lng: 19.4345886 },
    { lat: 48.3074271, lng: 19.4341308 },
    { lat: 48.3080654, lng: 19.4337535 },
    { lat: 48.3084779, lng: 19.4332032 },
    { lat: 48.308864, lng: 19.4329574 },
    { lat: 48.3092746, lng: 19.4330357 },
    { lat: 48.3094859, lng: 19.4326897 },
    { lat: 48.3098149, lng: 19.4324478 },
    { lat: 48.3099066, lng: 19.4327401 },
    { lat: 48.3108615, lng: 19.4348535 },
    { lat: 48.31219, lng: 19.4361421 },
    { lat: 48.3126361, lng: 19.4354902 },
    { lat: 48.3129665, lng: 19.4351097 },
    { lat: 48.3133987, lng: 19.4349298 },
    { lat: 48.3140861, lng: 19.4351919 },
    { lat: 48.3143461, lng: 19.4351871 },
    { lat: 48.3159481, lng: 19.435555 },
    { lat: 48.316289, lng: 19.4355171 },
    { lat: 48.3167837, lng: 19.435326 },
    { lat: 48.3170133, lng: 19.4345779 },
    { lat: 48.3172573, lng: 19.434419 },
    { lat: 48.3173978, lng: 19.4341485 },
    { lat: 48.3181057, lng: 19.4352038 },
    { lat: 48.3181177, lng: 19.4352683 },
    { lat: 48.318034, lng: 19.4354061 },
    { lat: 48.3183426, lng: 19.4360706 },
    { lat: 48.3183595, lng: 19.4360954 },
    { lat: 48.3190171, lng: 19.4355552 },
    { lat: 48.3198327, lng: 19.435426 },
    { lat: 48.3212806, lng: 19.4354452 },
    { lat: 48.3215146, lng: 19.4353645 },
    { lat: 48.3221141, lng: 19.4349303 },
    { lat: 48.322234, lng: 19.4348492 },
    { lat: 48.3220914, lng: 19.4333515 },
    { lat: 48.3217585, lng: 19.4313584 },
    { lat: 48.322004, lng: 19.431331 },
    { lat: 48.3220103, lng: 19.430887 },
    { lat: 48.3220531, lng: 19.4307422 },
    { lat: 48.3217401, lng: 19.4303901 },
    { lat: 48.3214787, lng: 19.4297709 },
    { lat: 48.3207436, lng: 19.428405 },
    { lat: 48.3207414, lng: 19.4281631 },
    { lat: 48.3208681, lng: 19.4280101 },
    { lat: 48.3210356, lng: 19.4281666 },
    { lat: 48.3213443, lng: 19.4278063 },
    { lat: 48.3213293, lng: 19.427412 },
    { lat: 48.3211483, lng: 19.4271851 },
    { lat: 48.3210513, lng: 19.4266107 },
    { lat: 48.3210962, lng: 19.4266056 },
    { lat: 48.3207384, lng: 19.4258564 },
    { lat: 48.3204482, lng: 19.4260323 },
    { lat: 48.3202966, lng: 19.4252563 },
    { lat: 48.3203013, lng: 19.4241396 },
    { lat: 48.3203893, lng: 19.423352 },
    { lat: 48.3205393, lng: 19.4228775 },
    { lat: 48.3206155, lng: 19.4228892 },
    { lat: 48.3206473, lng: 19.4209882 },
    { lat: 48.320694, lng: 19.4204715 },
    { lat: 48.3208267, lng: 19.4204401 },
    { lat: 48.3207864, lng: 19.4191122 },
    { lat: 48.3207305, lng: 19.4185236 },
    { lat: 48.3204458, lng: 19.417556 },
    { lat: 48.3202183, lng: 19.4171297 },
    { lat: 48.3200347, lng: 19.4169424 },
    { lat: 48.3202868, lng: 19.4159797 },
    { lat: 48.3205923, lng: 19.4143332 },
    { lat: 48.3207659, lng: 19.413904 },
    { lat: 48.3209048, lng: 19.4130751 },
    { lat: 48.3211661, lng: 19.4126674 },
    { lat: 48.3212967, lng: 19.4118783 },
    { lat: 48.3214997, lng: 19.411742 },
    { lat: 48.3210424, lng: 19.4114114 },
    { lat: 48.3207765, lng: 19.4115565 },
    { lat: 48.3204501, lng: 19.4113508 },
    { lat: 48.3204347, lng: 19.4110507 },
    { lat: 48.3202734, lng: 19.4106904 },
    { lat: 48.3200797, lng: 19.4104748 },
    { lat: 48.3195054, lng: 19.410152 },
    { lat: 48.3193382, lng: 19.4098585 },
    { lat: 48.3193935, lng: 19.4096243 },
    { lat: 48.3191746, lng: 19.4096893 },
    { lat: 48.3188178, lng: 19.4093672 },
    { lat: 48.3183273, lng: 19.4085033 },
    { lat: 48.3178068, lng: 19.4081259 },
    { lat: 48.3176185, lng: 19.4076244 },
    { lat: 48.3175553, lng: 19.4077912 },
    { lat: 48.3170884, lng: 19.4077881 },
    { lat: 48.3171853, lng: 19.4075232 },
    { lat: 48.3166795, lng: 19.4075264 },
    { lat: 48.3162998, lng: 19.4069078 },
    { lat: 48.3158132, lng: 19.4066076 },
    { lat: 48.3156037, lng: 19.4063976 },
    { lat: 48.3152475, lng: 19.4063028 },
    { lat: 48.3150918, lng: 19.4060821 },
    { lat: 48.3147801, lng: 19.4054032 },
    { lat: 48.3141257, lng: 19.4046723 },
    { lat: 48.3140377, lng: 19.4047879 },
    { lat: 48.3136883, lng: 19.4044562 },
    { lat: 48.3135629, lng: 19.4042833 },
    { lat: 48.3135716, lng: 19.4040866 },
    { lat: 48.313268, lng: 19.403911 },
    { lat: 48.3127021, lng: 19.4037415 },
    { lat: 48.3126249, lng: 19.4036623 },
    { lat: 48.3126145, lng: 19.4034867 },
    { lat: 48.3124286, lng: 19.4034668 },
    { lat: 48.3122035, lng: 19.4035453 },
    { lat: 48.3118898, lng: 19.4028203 },
    { lat: 48.3114916, lng: 19.4024081 },
    { lat: 48.3112206, lng: 19.4022309 },
    { lat: 48.311469, lng: 19.4014808 },
    { lat: 48.3113795, lng: 19.4013016 },
    { lat: 48.3112851, lng: 19.400765 },
    { lat: 48.3112183, lng: 19.4006861 },
    { lat: 48.3109913, lng: 19.400677 },
    { lat: 48.3106411, lng: 19.4009214 },
    { lat: 48.3100743, lng: 19.4008522 },
    { lat: 48.3097031, lng: 19.4003894 },
    { lat: 48.309371, lng: 19.399757 },
    { lat: 48.3092129, lng: 19.4006317 },
    { lat: 48.3091018, lng: 19.4006403 },
    { lat: 48.3085655, lng: 19.4004567 },
    { lat: 48.3086127, lng: 19.4001674 },
    { lat: 48.3080309, lng: 19.3996367 },
    { lat: 48.3078708, lng: 19.3995674 },
    { lat: 48.3075703, lng: 19.3995753 },
    { lat: 48.3077739, lng: 19.3986643 },
    { lat: 48.307751, lng: 19.3983963 },
    { lat: 48.3078106, lng: 19.3980575 },
    { lat: 48.3076645, lng: 19.3975675 },
    { lat: 48.3075594, lng: 19.3974177 },
    { lat: 48.3072752, lng: 19.3973913 },
    { lat: 48.3070782, lng: 19.3974902 },
    { lat: 48.3069732, lng: 19.3972288 },
    { lat: 48.3067663, lng: 19.3972381 },
    { lat: 48.3064894, lng: 19.3971525 },
    { lat: 48.3062917, lng: 19.3972118 },
    { lat: 48.3061176, lng: 19.3975784 },
    { lat: 48.3060157, lng: 19.3975967 },
    { lat: 48.3059287, lng: 19.3974251 },
    { lat: 48.3057782, lng: 19.3973391 },
    { lat: 48.3056985, lng: 19.3972025 },
    { lat: 48.3058147, lng: 19.3968481 },
    { lat: 48.3059098, lng: 19.3967881 },
    { lat: 48.3060124, lng: 19.3965761 },
    { lat: 48.3059245, lng: 19.3965054 },
    { lat: 48.3057852, lng: 19.3965111 },
    { lat: 48.3056089, lng: 19.3961691 },
    { lat: 48.3053259, lng: 19.3960736 },
    { lat: 48.3050511, lng: 19.3961953 },
    { lat: 48.3050008, lng: 19.3960874 },
    { lat: 48.304753, lng: 19.3961612 },
    { lat: 48.3046463, lng: 19.3962852 },
    { lat: 48.3041962, lng: 19.3963969 },
    { lat: 48.3041943, lng: 19.396904 },
    { lat: 48.3038729, lng: 19.3969938 },
    { lat: 48.3036326, lng: 19.397251 },
    { lat: 48.3036528, lng: 19.3976527 },
    { lat: 48.303172, lng: 19.3983305 },
    { lat: 48.3021232, lng: 19.3986008 },
    { lat: 48.3021844, lng: 19.3988942 },
    { lat: 48.3020524, lng: 19.3992233 },
    { lat: 48.3016855, lng: 19.399257 },
    { lat: 48.3012006, lng: 19.3994896 },
    { lat: 48.3008797, lng: 19.399545 },
    { lat: 48.3001838, lng: 19.3993894 },
    { lat: 48.2997777, lng: 19.3994305 },
    { lat: 48.2989474, lng: 19.3998666 },
    { lat: 48.2986248, lng: 19.3996935 },
    { lat: 48.2983671, lng: 19.3994187 },
    { lat: 48.2981588, lng: 19.398825 },
    { lat: 48.2979226, lng: 19.3984314 },
    { lat: 48.2978908, lng: 19.3982401 },
    { lat: 48.2979189, lng: 19.398144 },
    { lat: 48.2981416, lng: 19.397921 },
    { lat: 48.2981751, lng: 19.3977953 },
    { lat: 48.2978661, lng: 19.3974099 },
    { lat: 48.2976311, lng: 19.3973011 },
    { lat: 48.2975634, lng: 19.3967303 },
    { lat: 48.2974749, lng: 19.3966962 },
    { lat: 48.2972761, lng: 19.3967811 },
    { lat: 48.2972946, lng: 19.3971929 },
    { lat: 48.2972306, lng: 19.3972924 },
    { lat: 48.297085, lng: 19.3973589 },
    { lat: 48.2969329, lng: 19.3973387 },
    { lat: 48.2965397, lng: 19.3971049 },
    { lat: 48.2962275, lng: 19.3968433 },
    { lat: 48.2960421, lng: 19.3968765 },
    { lat: 48.2959135, lng: 19.3970449 },
    { lat: 48.2955227, lng: 19.3978808 },
    { lat: 48.295452, lng: 19.3979587 },
    { lat: 48.29531, lng: 19.3978149 },
    { lat: 48.2951773, lng: 19.3979384 },
    { lat: 48.2950814, lng: 19.3983171 },
    { lat: 48.2950203, lng: 19.3983583 },
    { lat: 48.2946834, lng: 19.3983163 },
    { lat: 48.2945183, lng: 19.3978972 },
    { lat: 48.2944369, lng: 19.3978456 },
    { lat: 48.2941605, lng: 19.3978425 },
    { lat: 48.2938598, lng: 19.398106 },
    { lat: 48.2935781, lng: 19.3984917 },
    { lat: 48.293451, lng: 19.3990882 },
    { lat: 48.2933252, lng: 19.3992637 },
    { lat: 48.2932045, lng: 19.3997862 },
    { lat: 48.2931431, lng: 19.3998667 },
    { lat: 48.2928167, lng: 19.3996424 },
    { lat: 48.2926342, lng: 19.3996187 },
    { lat: 48.292397, lng: 19.4000095 },
    { lat: 48.2922867, lng: 19.40004 },
    { lat: 48.2920814, lng: 19.4002983 },
    { lat: 48.2920279, lng: 19.4006434 },
    { lat: 48.2921323, lng: 19.4011557 },
    { lat: 48.2921379, lng: 19.4014386 },
    { lat: 48.2920337, lng: 19.401749 },
    { lat: 48.2919918, lng: 19.4018739 },
    { lat: 48.2920525, lng: 19.4023035 },
    { lat: 48.2919554, lng: 19.4028213 },
    { lat: 48.291833, lng: 19.4030941 },
    { lat: 48.2914168, lng: 19.4032018 },
    { lat: 48.2912333, lng: 19.4029478 },
    { lat: 48.2907644, lng: 19.4029704 },
    { lat: 48.2904216, lng: 19.4034411 },
    { lat: 48.2904528, lng: 19.4036257 },
    { lat: 48.2906104, lng: 19.4038128 },
    { lat: 48.2905048, lng: 19.4039865 },
    { lat: 48.2902062, lng: 19.4041301 },
    { lat: 48.28975, lng: 19.4045853 },
    { lat: 48.289628, lng: 19.4046025 },
    { lat: 48.2894591, lng: 19.4043473 },
    { lat: 48.2893705, lng: 19.4040676 },
    { lat: 48.2891445, lng: 19.4040569 },
    { lat: 48.2890436, lng: 19.4036426 },
    { lat: 48.2890098, lng: 19.4030442 },
    { lat: 48.2889523, lng: 19.402998 },
    { lat: 48.2888412, lng: 19.4030548 },
    { lat: 48.2883752, lng: 19.4039017 },
    { lat: 48.2881819, lng: 19.4040779 },
    { lat: 48.2880754, lng: 19.4042584 },
    { lat: 48.2879387, lng: 19.4046523 },
    { lat: 48.2878367, lng: 19.4048083 },
    { lat: 48.2875344, lng: 19.4050167 },
    { lat: 48.2872347, lng: 19.4055907 },
    { lat: 48.287338, lng: 19.4059173 },
    { lat: 48.2871583, lng: 19.4063123 },
    { lat: 48.287054, lng: 19.4063428 },
    { lat: 48.2870197, lng: 19.4065259 },
    { lat: 48.2868848, lng: 19.4064847 },
    { lat: 48.2866387, lng: 19.4061672 },
    { lat: 48.2864941, lng: 19.4062046 },
    { lat: 48.2864242, lng: 19.40635 },
    { lat: 48.2864728, lng: 19.4066127 },
    { lat: 48.2863484, lng: 19.4071518 },
    { lat: 48.2864464, lng: 19.4074117 },
    { lat: 48.2861249, lng: 19.4084071 },
    { lat: 48.2862513, lng: 19.4085794 },
    { lat: 48.2864479, lng: 19.4085666 },
    { lat: 48.2864675, lng: 19.4086693 },
    { lat: 48.286423, lng: 19.4088478 },
    { lat: 48.2861356, lng: 19.4091335 },
    { lat: 48.286109, lng: 19.4095684 },
    { lat: 48.2857574, lng: 19.4102374 },
    { lat: 48.2855542, lng: 19.4104706 },
    { lat: 48.2852079, lng: 19.4105598 },
    { lat: 48.28521, lng: 19.4105345 },
    { lat: 48.2847457, lng: 19.4111026 },
    { lat: 48.2842854, lng: 19.4113068 },
    { lat: 48.2838837, lng: 19.4117281 },
    { lat: 48.2835126, lng: 19.4119597 },
    { lat: 48.2833999, lng: 19.4122187 },
    { lat: 48.28339, lng: 19.4127034 },
    { lat: 48.2833176, lng: 19.41291 },
    { lat: 48.2833018, lng: 19.4129354 },
  ],
  ModrýKameň: [
    { lat: 48.2306957, lng: 19.3585354 },
    { lat: 48.2312187, lng: 19.3582315 },
    { lat: 48.2318465, lng: 19.3589366 },
    { lat: 48.2324253, lng: 19.3593939 },
    { lat: 48.2326591, lng: 19.3593851 },
    { lat: 48.2331444, lng: 19.358806 },
    { lat: 48.2333852, lng: 19.3583482 },
    { lat: 48.2337621, lng: 19.3590449 },
    { lat: 48.2346016, lng: 19.3625514 },
    { lat: 48.234812, lng: 19.3630163 },
    { lat: 48.2349986, lng: 19.3631936 },
    { lat: 48.2357664, lng: 19.3629615 },
    { lat: 48.2360513, lng: 19.3627697 },
    { lat: 48.2361772, lng: 19.362548 },
    { lat: 48.2366348, lng: 19.3617247 },
    { lat: 48.2373605, lng: 19.3601663 },
    { lat: 48.2373298, lng: 19.3601371 },
    { lat: 48.2384178, lng: 19.3581162 },
    { lat: 48.2385479, lng: 19.3573948 },
    { lat: 48.2389506, lng: 19.3571183 },
    { lat: 48.2391294, lng: 19.3570882 },
    { lat: 48.2394274, lng: 19.3572599 },
    { lat: 48.2396799, lng: 19.3575464 },
    { lat: 48.2398871, lng: 19.3576129 },
    { lat: 48.2401221, lng: 19.3575527 },
    { lat: 48.2408348, lng: 19.3576301 },
    { lat: 48.2408709, lng: 19.3577033 },
    { lat: 48.2410876, lng: 19.3570741 },
    { lat: 48.2423429, lng: 19.3564073 },
    { lat: 48.2433126, lng: 19.3560837 },
    { lat: 48.2440198, lng: 19.3560252 },
    { lat: 48.2445296, lng: 19.3560769 },
    { lat: 48.2448726, lng: 19.3562268 },
    { lat: 48.2450653, lng: 19.3561818 },
    { lat: 48.2455433, lng: 19.3558314 },
    { lat: 48.245543, lng: 19.3557059 },
    { lat: 48.2457603, lng: 19.3554205 },
    { lat: 48.2458163, lng: 19.3555531 },
    { lat: 48.2459836, lng: 19.3551604 },
    { lat: 48.2462612, lng: 19.3547225 },
    { lat: 48.2473909, lng: 19.3531253 },
    { lat: 48.2475, lng: 19.3528632 },
    { lat: 48.2475503, lng: 19.3525215 },
    { lat: 48.2476736, lng: 19.3523442 },
    { lat: 48.2482289, lng: 19.3520537 },
    { lat: 48.2481718, lng: 19.3519481 },
    { lat: 48.2484112, lng: 19.351742 },
    { lat: 48.2483976, lng: 19.3516964 },
    { lat: 48.2485942, lng: 19.3514469 },
    { lat: 48.2487237, lng: 19.3514436 },
    { lat: 48.2490552, lng: 19.3512089 },
    { lat: 48.2493671, lng: 19.3511316 },
    { lat: 48.2496482, lng: 19.3506494 },
    { lat: 48.2506182, lng: 19.3496477 },
    { lat: 48.2512492, lng: 19.3493412 },
    { lat: 48.2515734, lng: 19.3492604 },
    { lat: 48.252075, lng: 19.3493077 },
    { lat: 48.2526709, lng: 19.349116 },
    { lat: 48.2529891, lng: 19.3489542 },
    { lat: 48.2531526, lng: 19.3489352 },
    { lat: 48.2535757, lng: 19.3486627 },
    { lat: 48.2541425, lng: 19.3485873 },
    { lat: 48.2544463, lng: 19.3482922 },
    { lat: 48.2548074, lng: 19.3483019 },
    { lat: 48.2551551, lng: 19.3479866 },
    { lat: 48.2557707, lng: 19.347919 },
    { lat: 48.256598, lng: 19.3470258 },
    { lat: 48.2569719, lng: 19.3466411 },
    { lat: 48.2578048, lng: 19.3455853 },
    { lat: 48.2581709, lng: 19.3452241 },
    { lat: 48.2587067, lng: 19.3443159 },
    { lat: 48.2599148, lng: 19.3431393 },
    { lat: 48.2606163, lng: 19.3423271 },
    { lat: 48.2608536, lng: 19.3419171 },
    { lat: 48.2624071, lng: 19.3405105 },
    { lat: 48.2632431, lng: 19.3399161 },
    { lat: 48.2634931, lng: 19.339816 },
    { lat: 48.2640944, lng: 19.3397371 },
    { lat: 48.2645502, lng: 19.3398631 },
    { lat: 48.2649954, lng: 19.3403052 },
    { lat: 48.2659621, lng: 19.3397586 },
    { lat: 48.2666642, lng: 19.339521 },
    { lat: 48.2672655, lng: 19.3391559 },
    { lat: 48.2678582, lng: 19.3382618 },
    { lat: 48.2681374, lng: 19.3373626 },
    { lat: 48.2685405, lng: 19.3366268 },
    { lat: 48.2686511, lng: 19.3357138 },
    { lat: 48.2689212, lng: 19.334859 },
    { lat: 48.2697104, lng: 19.3332435 },
    { lat: 48.2696758, lng: 19.3323603 },
    { lat: 48.2695215, lng: 19.3309296 },
    { lat: 48.2702101, lng: 19.3281652 },
    { lat: 48.2717962, lng: 19.3247678 },
    { lat: 48.2712472, lng: 19.3244856 },
    { lat: 48.2708851, lng: 19.3244798 },
    { lat: 48.2706854, lng: 19.3239381 },
    { lat: 48.270486, lng: 19.3239869 },
    { lat: 48.2703538, lng: 19.3236286 },
    { lat: 48.270161, lng: 19.3236041 },
    { lat: 48.2699528, lng: 19.323443 },
    { lat: 48.2698644, lng: 19.3231327 },
    { lat: 48.269739, lng: 19.322986 },
    { lat: 48.2696961, lng: 19.3226793 },
    { lat: 48.269612, lng: 19.3225465 },
    { lat: 48.269483, lng: 19.3218337 },
    { lat: 48.2695371, lng: 19.3215979 },
    { lat: 48.2695026, lng: 19.3213519 },
    { lat: 48.2693768, lng: 19.3211249 },
    { lat: 48.2693707, lng: 19.3209525 },
    { lat: 48.2699891, lng: 19.3203123 },
    { lat: 48.2700916, lng: 19.3202616 },
    { lat: 48.2704368, lng: 19.3204111 },
    { lat: 48.2706729, lng: 19.3202087 },
    { lat: 48.2708149, lng: 19.3202107 },
    { lat: 48.2710132, lng: 19.3197427 },
    { lat: 48.2711626, lng: 19.3196123 },
    { lat: 48.2715471, lng: 19.3195927 },
    { lat: 48.2717836, lng: 19.3190973 },
    { lat: 48.2720056, lng: 19.3189789 },
    { lat: 48.2723546, lng: 19.3185704 },
    { lat: 48.2724477, lng: 19.3181393 },
    { lat: 48.2728397, lng: 19.3178333 },
    { lat: 48.272989, lng: 19.3176088 },
    { lat: 48.2729896, lng: 19.3174357 },
    { lat: 48.2731025, lng: 19.3171111 },
    { lat: 48.2733743, lng: 19.3168549 },
    { lat: 48.2737266, lng: 19.3167493 },
    { lat: 48.2739951, lng: 19.3162631 },
    { lat: 48.2742334, lng: 19.3161793 },
    { lat: 48.274406, lng: 19.3159972 },
    { lat: 48.2745169, lng: 19.315775 },
    { lat: 48.2746217, lng: 19.3157042 },
    { lat: 48.2747817, lng: 19.3157464 },
    { lat: 48.2748834, lng: 19.315591 },
    { lat: 48.2749671, lng: 19.3153131 },
    { lat: 48.2751339, lng: 19.3153152 },
    { lat: 48.2752198, lng: 19.3154595 },
    { lat: 48.27532, lng: 19.3154313 },
    { lat: 48.2754498, lng: 19.3152485 },
    { lat: 48.2759223, lng: 19.3151627 },
    { lat: 48.2761295, lng: 19.3153215 },
    { lat: 48.2763095, lng: 19.3153346 },
    { lat: 48.2767003, lng: 19.3150977 },
    { lat: 48.277039, lng: 19.3152012 },
    { lat: 48.2773964, lng: 19.3149909 },
    { lat: 48.2776515, lng: 19.3145553 },
    { lat: 48.2778539, lng: 19.3144521 },
    { lat: 48.2780297, lng: 19.3142183 },
    { lat: 48.2781927, lng: 19.3140899 },
    { lat: 48.278305, lng: 19.3141521 },
    { lat: 48.2784877, lng: 19.314018 },
    { lat: 48.2787269, lng: 19.314157 },
    { lat: 48.2789332, lng: 19.3141853 },
    { lat: 48.2796877, lng: 19.3141716 },
    { lat: 48.2797812, lng: 19.3140971 },
    { lat: 48.2798959, lng: 19.3141838 },
    { lat: 48.280142, lng: 19.3141696 },
    { lat: 48.2803364, lng: 19.3145077 },
    { lat: 48.2804507, lng: 19.3144975 },
    { lat: 48.2807553, lng: 19.3143141 },
    { lat: 48.2809519, lng: 19.314462 },
    { lat: 48.2809859, lng: 19.3143738 },
    { lat: 48.281585, lng: 19.3139211 },
    { lat: 48.2822341, lng: 19.3136814 },
    { lat: 48.2833381, lng: 19.3134126 },
    { lat: 48.2826183, lng: 19.3107111 },
    { lat: 48.2828119, lng: 19.3103504 },
    { lat: 48.2828676, lng: 19.3101303 },
    { lat: 48.2827302, lng: 19.3096855 },
    { lat: 48.2826243, lng: 19.3095713 },
    { lat: 48.2827821, lng: 19.3074666 },
    { lat: 48.2829255, lng: 19.3046912 },
    { lat: 48.2829703, lng: 19.3034955 },
    { lat: 48.2829512, lng: 19.3022391 },
    { lat: 48.2832102, lng: 19.3012743 },
    { lat: 48.2835155, lng: 19.2990329 },
    { lat: 48.2834337, lng: 19.2980395 },
    { lat: 48.2833555, lng: 19.2977748 },
    { lat: 48.2834403, lng: 19.2971484 },
    { lat: 48.2834016, lng: 19.2970281 },
    { lat: 48.2835015, lng: 19.2966467 },
    { lat: 48.2834837, lng: 19.2964639 },
    { lat: 48.2835591, lng: 19.296392 },
    { lat: 48.2835138, lng: 19.2958509 },
    { lat: 48.2835748, lng: 19.2957583 },
    { lat: 48.2835405, lng: 19.2954927 },
    { lat: 48.2834298, lng: 19.2952998 },
    { lat: 48.2833673, lng: 19.2950581 },
    { lat: 48.2835315, lng: 19.2944663 },
    { lat: 48.2835086, lng: 19.2944136 },
    { lat: 48.2833551, lng: 19.2939896 },
    { lat: 48.2832513, lng: 19.293939 },
    { lat: 48.2830915, lng: 19.2940618 },
    { lat: 48.2828713, lng: 19.294544 },
    { lat: 48.2827842, lng: 19.2946047 },
    { lat: 48.282565, lng: 19.2945273 },
    { lat: 48.2822661, lng: 19.2941047 },
    { lat: 48.2821597, lng: 19.2941651 },
    { lat: 48.282004, lng: 19.2940907 },
    { lat: 48.2816314, lng: 19.2937525 },
    { lat: 48.2815371, lng: 19.2937355 },
    { lat: 48.281379, lng: 19.293823 },
    { lat: 48.2811977, lng: 19.2941279 },
    { lat: 48.2810682, lng: 19.2942043 },
    { lat: 48.2807941, lng: 19.2940805 },
    { lat: 48.2806085, lng: 19.2938317 },
    { lat: 48.2804873, lng: 19.2938208 },
    { lat: 48.2803369, lng: 19.2939054 },
    { lat: 48.2802471, lng: 19.2940514 },
    { lat: 48.2800467, lng: 19.2940774 },
    { lat: 48.2795416, lng: 19.293657 },
    { lat: 48.2791537, lng: 19.2936315 },
    { lat: 48.2789452, lng: 19.2934708 },
    { lat: 48.2784937, lng: 19.2933171 },
    { lat: 48.2780529, lng: 19.2928957 },
    { lat: 48.2779313, lng: 19.2925947 },
    { lat: 48.277739, lng: 19.2923258 },
    { lat: 48.2774377, lng: 19.2923652 },
    { lat: 48.2770756, lng: 19.292757 },
    { lat: 48.2768695, lng: 19.2926898 },
    { lat: 48.2767013, lng: 19.292494 },
    { lat: 48.2764748, lng: 19.2923827 },
    { lat: 48.2761746, lng: 19.292582 },
    { lat: 48.2758407, lng: 19.2926731 },
    { lat: 48.2757158, lng: 19.2928395 },
    { lat: 48.2755041, lng: 19.2928186 },
    { lat: 48.274957, lng: 19.292509 },
    { lat: 48.2746951, lng: 19.2927097 },
    { lat: 48.274626, lng: 19.2926783 },
    { lat: 48.2744841, lng: 19.292215 },
    { lat: 48.2746033, lng: 19.2914427 },
    { lat: 48.2745777, lng: 19.2901427 },
    { lat: 48.2743682, lng: 19.2894939 },
    { lat: 48.2742335, lng: 19.2894395 },
    { lat: 48.2740405, lng: 19.2891081 },
    { lat: 48.2738292, lng: 19.2884216 },
    { lat: 48.2737976, lng: 19.2879976 },
    { lat: 48.2736326, lng: 19.2877802 },
    { lat: 48.273694, lng: 19.2875131 },
    { lat: 48.2735334, lng: 19.2869227 },
    { lat: 48.2735613, lng: 19.286776 },
    { lat: 48.273497, lng: 19.2861914 },
    { lat: 48.2733998, lng: 19.2859532 },
    { lat: 48.2730895, lng: 19.2855507 },
    { lat: 48.2729291, lng: 19.2855207 },
    { lat: 48.2726073, lng: 19.2852996 },
    { lat: 48.2724355, lng: 19.2850582 },
    { lat: 48.2717315, lng: 19.2843938 },
    { lat: 48.2715967, lng: 19.2841279 },
    { lat: 48.2713979, lng: 19.2842068 },
    { lat: 48.270885, lng: 19.2837425 },
    { lat: 48.2704934, lng: 19.2818264 },
    { lat: 48.2703601, lng: 19.2818448 },
    { lat: 48.2702123, lng: 19.2818648 },
    { lat: 48.2698112, lng: 19.2820786 },
    { lat: 48.269545, lng: 19.2822844 },
    { lat: 48.2693399, lng: 19.2825654 },
    { lat: 48.2683346, lng: 19.2833784 },
    { lat: 48.267258, lng: 19.2839349 },
    { lat: 48.2666617, lng: 19.2841207 },
    { lat: 48.266171, lng: 19.2844599 },
    { lat: 48.265912, lng: 19.2845352 },
    { lat: 48.2655206, lng: 19.2847638 },
    { lat: 48.264749, lng: 19.2852871 },
    { lat: 48.2642436, lng: 19.2858588 },
    { lat: 48.2637935, lng: 19.286529 },
    { lat: 48.2632486, lng: 19.2871439 },
    { lat: 48.262645, lng: 19.2876439 },
    { lat: 48.2624367, lng: 19.2879322 },
    { lat: 48.2617004, lng: 19.2885075 },
    { lat: 48.2614527, lng: 19.2887668 },
    { lat: 48.2613352, lng: 19.288926 },
    { lat: 48.2612045, lng: 19.2893229 },
    { lat: 48.260863, lng: 19.2897682 },
    { lat: 48.2591471, lng: 19.2913911 },
    { lat: 48.258355, lng: 19.2924912 },
    { lat: 48.2579039, lng: 19.2933268 },
    { lat: 48.2577448, lng: 19.2939486 },
    { lat: 48.2574739, lng: 19.2946865 },
    { lat: 48.2573925, lng: 19.2959853 },
    { lat: 48.2571792, lng: 19.2964937 },
    { lat: 48.2562603, lng: 19.2992179 },
    { lat: 48.2558789, lng: 19.2996982 },
    { lat: 48.2557178, lng: 19.3000241 },
    { lat: 48.2549959, lng: 19.3005756 },
    { lat: 48.2542583, lng: 19.3015008 },
    { lat: 48.2541512, lng: 19.301522 },
    { lat: 48.2535501, lng: 19.302564 },
    { lat: 48.2527163, lng: 19.3036687 },
    { lat: 48.2522086, lng: 19.3040084 },
    { lat: 48.2518623, lng: 19.304164 },
    { lat: 48.2507026, lng: 19.3050556 },
    { lat: 48.2500057, lng: 19.3054434 },
    { lat: 48.2496035, lng: 19.3055916 },
    { lat: 48.2474735, lng: 19.305343 },
    { lat: 48.2468587, lng: 19.3050747 },
    { lat: 48.2463012, lng: 19.3051312 },
    { lat: 48.2449408, lng: 19.3060433 },
    { lat: 48.2446832, lng: 19.3063336 },
    { lat: 48.2440633, lng: 19.306801 },
    { lat: 48.2426579, lng: 19.3070391 },
    { lat: 48.2414574, lng: 19.3075007 },
    { lat: 48.2409458, lng: 19.3075511 },
    { lat: 48.2402884, lng: 19.3077106 },
    { lat: 48.239983, lng: 19.3077585 },
    { lat: 48.2397654, lng: 19.307641 },
    { lat: 48.2395079, lng: 19.3076062 },
    { lat: 48.2389715, lng: 19.3076554 },
    { lat: 48.2383821, lng: 19.3079367 },
    { lat: 48.2373642, lng: 19.3081935 },
    { lat: 48.2349109, lng: 19.3100984 },
    { lat: 48.2337811, lng: 19.3108361 },
    { lat: 48.2332695, lng: 19.3113061 },
    { lat: 48.2326309, lng: 19.3116202 },
    { lat: 48.2318493, lng: 19.3124032 },
    { lat: 48.2315628, lng: 19.3124908 },
    { lat: 48.2309104, lng: 19.312427 },
    { lat: 48.2305235, lng: 19.3124702 },
    { lat: 48.2293586, lng: 19.3133919 },
    { lat: 48.2290499, lng: 19.3138625 },
    { lat: 48.2283323, lng: 19.3148065 },
    { lat: 48.2280657, lng: 19.3155169 },
    { lat: 48.2268286, lng: 19.3227334 },
    { lat: 48.2265515, lng: 19.3241893 },
    { lat: 48.2263275, lng: 19.3248741 },
    { lat: 48.2281121, lng: 19.3332433 },
    { lat: 48.2268473, lng: 19.3338278 },
    { lat: 48.2269033, lng: 19.3344969 },
    { lat: 48.227189, lng: 19.3353067 },
    { lat: 48.2268523, lng: 19.3355247 },
    { lat: 48.2269518, lng: 19.3356006 },
    { lat: 48.2265055, lng: 19.3359551 },
    { lat: 48.226543, lng: 19.3361027 },
    { lat: 48.2267658, lng: 19.3361186 },
    { lat: 48.2268198, lng: 19.3368187 },
    { lat: 48.226811, lng: 19.3377377 },
    { lat: 48.2268814, lng: 19.3382193 },
    { lat: 48.2270188, lng: 19.338662 },
    { lat: 48.2271608, lng: 19.3386863 },
    { lat: 48.2272525, lng: 19.3388355 },
    { lat: 48.2275851, lng: 19.338886 },
    { lat: 48.2281342, lng: 19.339332 },
    { lat: 48.2285806, lng: 19.3394986 },
    { lat: 48.2285963, lng: 19.3398016 },
    { lat: 48.2288124, lng: 19.3401697 },
    { lat: 48.2295619, lng: 19.3409011 },
    { lat: 48.2300509, lng: 19.341295 },
    { lat: 48.2304113, lng: 19.3419006 },
    { lat: 48.2305444, lng: 19.3419708 },
    { lat: 48.2306471, lng: 19.3421139 },
    { lat: 48.2308687, lng: 19.3426709 },
    { lat: 48.2309465, lng: 19.3430475 },
    { lat: 48.2311373, lng: 19.3431946 },
    { lat: 48.2312809, lng: 19.3437214 },
    { lat: 48.2315855, lng: 19.3436798 },
    { lat: 48.2318512, lng: 19.3442356 },
    { lat: 48.2318066, lng: 19.3444575 },
    { lat: 48.2318915, lng: 19.3446082 },
    { lat: 48.2318778, lng: 19.3454701 },
    { lat: 48.2319666, lng: 19.3456575 },
    { lat: 48.2318979, lng: 19.3458186 },
    { lat: 48.2319393, lng: 19.3460478 },
    { lat: 48.2318625, lng: 19.3463272 },
    { lat: 48.2319537, lng: 19.3465589 },
    { lat: 48.2319116, lng: 19.346859 },
    { lat: 48.2320856, lng: 19.3479454 },
    { lat: 48.2320194, lng: 19.3482597 },
    { lat: 48.2320608, lng: 19.3486565 },
    { lat: 48.231981, lng: 19.3488478 },
    { lat: 48.2320666, lng: 19.3491016 },
    { lat: 48.2316596, lng: 19.3513131 },
    { lat: 48.2315211, lng: 19.3516228 },
    { lat: 48.2315173, lng: 19.3517299 },
    { lat: 48.2314102, lng: 19.3518471 },
    { lat: 48.2312195, lng: 19.3525944 },
    { lat: 48.2309043, lng: 19.3547224 },
    { lat: 48.2310864, lng: 19.3558903 },
    { lat: 48.2311488, lng: 19.3565828 },
    { lat: 48.2310522, lng: 19.3571305 },
    { lat: 48.230729, lng: 19.3583586 },
    { lat: 48.2306957, lng: 19.3585354 },
  ],
  Kiarov: [
    { lat: 48.1364283, lng: 19.4396213 },
    { lat: 48.1363909, lng: 19.4395709 },
    { lat: 48.1359234, lng: 19.4389319 },
    { lat: 48.1351794, lng: 19.4383311 },
    { lat: 48.1350041, lng: 19.4380292 },
    { lat: 48.1332872, lng: 19.4373561 },
    { lat: 48.1327041, lng: 19.4370118 },
    { lat: 48.1320452, lng: 19.4364974 },
    { lat: 48.1312042, lng: 19.4360375 },
    { lat: 48.130175, lng: 19.4356034 },
    { lat: 48.1294031, lng: 19.4348216 },
    { lat: 48.1291241, lng: 19.4342089 },
    { lat: 48.1290164, lng: 19.4336492 },
    { lat: 48.1289262, lng: 19.4328159 },
    { lat: 48.1288089, lng: 19.4322391 },
    { lat: 48.1288885, lng: 19.4314952 },
    { lat: 48.1282635, lng: 19.4300993 },
    { lat: 48.1281515, lng: 19.4295883 },
    { lat: 48.1281407, lng: 19.4285036 },
    { lat: 48.1271224, lng: 19.426752 },
    { lat: 48.1270139, lng: 19.426676 },
    { lat: 48.126653, lng: 19.4260513 },
    { lat: 48.1262247, lng: 19.4248823 },
    { lat: 48.1257692, lng: 19.4240464 },
    { lat: 48.1252015, lng: 19.4237678 },
    { lat: 48.1243893, lng: 19.42285 },
    { lat: 48.1235596, lng: 19.4217973 },
    { lat: 48.1228899, lng: 19.4211961 },
    { lat: 48.1226813, lng: 19.4205526 },
    { lat: 48.1226031, lng: 19.4200233 },
    { lat: 48.1224643, lng: 19.4195928 },
    { lat: 48.12211, lng: 19.4181569 },
    { lat: 48.1207921, lng: 19.4133528 },
    { lat: 48.1204057, lng: 19.412476 },
    { lat: 48.1203774, lng: 19.4119051 },
    { lat: 48.1206237, lng: 19.4079496 },
    { lat: 48.120928, lng: 19.4053185 },
    { lat: 48.1216801, lng: 19.4038109 },
    { lat: 48.1219516, lng: 19.4034255 },
    { lat: 48.122302, lng: 19.4021223 },
    { lat: 48.1226087, lng: 19.3996823 },
    { lat: 48.1226676, lng: 19.3988362 },
    { lat: 48.122641, lng: 19.3978513 },
    { lat: 48.1225417, lng: 19.3973285 },
    { lat: 48.1220975, lng: 19.3964 },
    { lat: 48.1218342, lng: 19.3952262 },
    { lat: 48.1212396, lng: 19.3935571 },
    { lat: 48.1199175, lng: 19.3904595 },
    { lat: 48.1193389, lng: 19.3895292 },
    { lat: 48.1188159, lng: 19.3891502 },
    { lat: 48.1171822, lng: 19.3896107 },
    { lat: 48.1161323, lng: 19.3895999 },
    { lat: 48.1152356, lng: 19.3898438 },
    { lat: 48.1148633, lng: 19.3897864 },
    { lat: 48.1148562, lng: 19.3898062 },
    { lat: 48.1138197, lng: 19.3902147 },
    { lat: 48.1135774, lng: 19.390408 },
    { lat: 48.1130297, lng: 19.3912601 },
    { lat: 48.1130071, lng: 19.3912336 },
    { lat: 48.1124867, lng: 19.3923071 },
    { lat: 48.1117124, lng: 19.3932266 },
    { lat: 48.1108742, lng: 19.394653 },
    { lat: 48.1104159, lng: 19.3951619 },
    { lat: 48.1100895, lng: 19.3957625 },
    { lat: 48.1101537, lng: 19.3958301 },
    { lat: 48.1101051, lng: 19.3960903 },
    { lat: 48.1092838, lng: 19.3981301 },
    { lat: 48.1087875, lng: 19.3982872 },
    { lat: 48.1082724, lng: 19.3985707 },
    { lat: 48.106749, lng: 19.3999793 },
    { lat: 48.1061235, lng: 19.4006913 },
    { lat: 48.1040814, lng: 19.4023269 },
    { lat: 48.1042717, lng: 19.4027482 },
    { lat: 48.1042023, lng: 19.4030454 },
    { lat: 48.104074, lng: 19.4032642 },
    { lat: 48.1040755, lng: 19.4035278 },
    { lat: 48.1037263, lng: 19.4038437 },
    { lat: 48.1037269, lng: 19.4039015 },
    { lat: 48.1029236, lng: 19.4044556 },
    { lat: 48.1029972, lng: 19.404652 },
    { lat: 48.1028005, lng: 19.4052543 },
    { lat: 48.1026368, lng: 19.4061024 },
    { lat: 48.1025222, lng: 19.4075768 },
    { lat: 48.1027224, lng: 19.4087593 },
    { lat: 48.1005233, lng: 19.4112178 },
    { lat: 48.1011399, lng: 19.4130717 },
    { lat: 48.102469, lng: 19.4176629 },
    { lat: 48.1018114, lng: 19.4181978 },
    { lat: 48.1006321, lng: 19.4190185 },
    { lat: 48.0968745, lng: 19.4204018 },
    { lat: 48.0961726, lng: 19.4202116 },
    { lat: 48.0952574, lng: 19.4211192 },
    { lat: 48.0942756, lng: 19.4214965 },
    { lat: 48.0924089, lng: 19.4224872 },
    { lat: 48.0922025, lng: 19.4223892 },
    { lat: 48.0918386, lng: 19.4220599 },
    { lat: 48.0915918, lng: 19.4220349 },
    { lat: 48.0913184, lng: 19.4221878 },
    { lat: 48.0910004, lng: 19.4221146 },
    { lat: 48.0908083, lng: 19.4218899 },
    { lat: 48.0906099, lng: 19.4214603 },
    { lat: 48.0904335, lng: 19.4213256 },
    { lat: 48.0902443, lng: 19.4215956 },
    { lat: 48.0899564, lng: 19.4218445 },
    { lat: 48.0901059, lng: 19.4225958 },
    { lat: 48.0900614, lng: 19.4232026 },
    { lat: 48.0899771, lng: 19.4234205 },
    { lat: 48.089538, lng: 19.423213 },
    { lat: 48.08952, lng: 19.423247 },
    { lat: 48.089482, lng: 19.423306 },
    { lat: 48.089327, lng: 19.423469 },
    { lat: 48.089168, lng: 19.423587 },
    { lat: 48.089092, lng: 19.423626 },
    { lat: 48.088995, lng: 19.423702 },
    { lat: 48.088858, lng: 19.423847 },
    { lat: 48.088783, lng: 19.423943 },
    { lat: 48.088682, lng: 19.424157 },
    { lat: 48.088647, lng: 19.424323 },
    { lat: 48.088636, lng: 19.424592 },
    { lat: 48.088641, lng: 19.424758 },
    { lat: 48.088669, lng: 19.424914 },
    { lat: 48.088703, lng: 19.425023 },
    { lat: 48.088782, lng: 19.425214 },
    { lat: 48.088895, lng: 19.425381 },
    { lat: 48.089079, lng: 19.42557 },
    { lat: 48.089264, lng: 19.42567 },
    { lat: 48.089603, lng: 19.425757 },
    { lat: 48.089784, lng: 19.425784 },
    { lat: 48.090018, lng: 19.425788 },
    { lat: 48.090205, lng: 19.425862 },
    { lat: 48.09029, lng: 19.425929 },
    { lat: 48.090413, lng: 19.426102 },
    { lat: 48.090559, lng: 19.426234 },
    { lat: 48.090662, lng: 19.426348 },
    { lat: 48.090731, lng: 19.426397 },
    { lat: 48.090911, lng: 19.42659 },
    { lat: 48.091012, lng: 19.426709 },
    { lat: 48.091148, lng: 19.426868 },
    { lat: 48.091229, lng: 19.426956 },
    { lat: 48.091376, lng: 19.427179 },
    { lat: 48.091501, lng: 19.427333 },
    { lat: 48.091601, lng: 19.42749 },
    { lat: 48.091939, lng: 19.427772 },
    { lat: 48.092146, lng: 19.428052 },
    { lat: 48.092241, lng: 19.42817 },
    { lat: 48.09247, lng: 19.428479 },
    { lat: 48.092528, lng: 19.428609 },
    { lat: 48.092565, lng: 19.428739 },
    { lat: 48.092654, lng: 19.42894 },
    { lat: 48.092821, lng: 19.429071 },
    { lat: 48.092975, lng: 19.429123 },
    { lat: 48.093114, lng: 19.429184 },
    { lat: 48.093159, lng: 19.429247 },
    { lat: 48.093239, lng: 19.429543 },
    { lat: 48.093262, lng: 19.429626 },
    { lat: 48.09328, lng: 19.430021 },
    { lat: 48.093309, lng: 19.430292 },
    { lat: 48.09333, lng: 19.430566 },
    { lat: 48.09338, lng: 19.430825 },
    { lat: 48.093448, lng: 19.430983 },
    { lat: 48.093584, lng: 19.431078 },
    { lat: 48.09379, lng: 19.431401 },
    { lat: 48.093914, lng: 19.431629 },
    { lat: 48.094096, lng: 19.431953 },
    { lat: 48.094238, lng: 19.432199 },
    { lat: 48.094457, lng: 19.432553 },
    { lat: 48.094819, lng: 19.433055 },
    { lat: 48.094929, lng: 19.433206 },
    { lat: 48.094982, lng: 19.433295 },
    { lat: 48.095121, lng: 19.433534 },
    { lat: 48.095247, lng: 19.433746 },
    { lat: 48.095463, lng: 19.434008 },
    { lat: 48.095569, lng: 19.43421 },
    { lat: 48.095623, lng: 19.434277 },
    { lat: 48.095759, lng: 19.434407 },
    { lat: 48.095819, lng: 19.43444 },
    { lat: 48.095945, lng: 19.434449 },
    { lat: 48.095983, lng: 19.434438 },
    { lat: 48.096031, lng: 19.434404 },
    { lat: 48.096111, lng: 19.434248 },
    { lat: 48.09617, lng: 19.434072 },
    { lat: 48.096228, lng: 19.433917 },
    { lat: 48.096336, lng: 19.433754 },
    { lat: 48.096467, lng: 19.433613 },
    { lat: 48.09656, lng: 19.433543 },
    { lat: 48.096749, lng: 19.433535 },
    { lat: 48.096857, lng: 19.433578 },
    { lat: 48.09693, lng: 19.433657 },
    { lat: 48.097006, lng: 19.433763 },
    { lat: 48.097042, lng: 19.433893 },
    { lat: 48.097076, lng: 19.434041 },
    { lat: 48.09709, lng: 19.43421 },
    { lat: 48.097087, lng: 19.434361 },
    { lat: 48.097078, lng: 19.434464 },
    { lat: 48.097055, lng: 19.43461 },
    { lat: 48.097004, lng: 19.43484 },
    { lat: 48.096916, lng: 19.434952 },
    { lat: 48.096877, lng: 19.435036 },
    { lat: 48.09684, lng: 19.435147 },
    { lat: 48.096839, lng: 19.435191 },
    { lat: 48.096856, lng: 19.435289 },
    { lat: 48.096887, lng: 19.435378 },
    { lat: 48.096961, lng: 19.435495 },
    { lat: 48.097069, lng: 19.435628 },
    { lat: 48.097148, lng: 19.435701 },
    { lat: 48.097351, lng: 19.435823 },
    { lat: 48.097455, lng: 19.435882 },
    { lat: 48.097552, lng: 19.435925 },
    { lat: 48.097745, lng: 19.435959 },
    { lat: 48.097898, lng: 19.435916 },
    { lat: 48.098074, lng: 19.435824 },
    { lat: 48.098307, lng: 19.435638 },
    { lat: 48.098489, lng: 19.435453 },
    { lat: 48.098567, lng: 19.435339 },
    { lat: 48.098705, lng: 19.435065 },
    { lat: 48.098764, lng: 19.434977 },
    { lat: 48.098811, lng: 19.434931 },
    { lat: 48.098918, lng: 19.434892 },
    { lat: 48.099094, lng: 19.434945 },
    { lat: 48.099165, lng: 19.434972 },
    { lat: 48.099242, lng: 19.43501 },
    { lat: 48.09958, lng: 19.435307 },
    { lat: 48.09961, lng: 19.435354 },
    { lat: 48.099665, lng: 19.435491 },
    { lat: 48.099722, lng: 19.435741 },
    { lat: 48.099743, lng: 19.436015 },
    { lat: 48.099754, lng: 19.436295 },
    { lat: 48.099745, lng: 19.436457 },
    { lat: 48.099629, lng: 19.437166 },
    { lat: 48.099563, lng: 19.437602 },
    { lat: 48.099496, lng: 19.438207 },
    { lat: 48.099481, lng: 19.438513 },
    { lat: 48.099475, lng: 19.438615 },
    { lat: 48.099498, lng: 19.439023 },
    { lat: 48.099516, lng: 19.43918 },
    { lat: 48.099639, lng: 19.439824 },
    { lat: 48.099679, lng: 19.439985 },
    { lat: 48.099739, lng: 19.440163 },
    { lat: 48.099833, lng: 19.440395 },
    { lat: 48.100002, lng: 19.440797 },
    { lat: 48.100154, lng: 19.441183 },
    { lat: 48.100304, lng: 19.441473 },
    { lat: 48.100466, lng: 19.441775 },
    { lat: 48.100545, lng: 19.441858 },
    { lat: 48.100691, lng: 19.441958 },
    { lat: 48.100798, lng: 19.442027 },
    { lat: 48.100887, lng: 19.442095 },
    { lat: 48.100973, lng: 19.442172 },
    { lat: 48.101103, lng: 19.442301 },
    { lat: 48.101164, lng: 19.442414 },
    { lat: 48.101229, lng: 19.44254 },
    { lat: 48.101269, lng: 19.442644 },
    { lat: 48.10127, lng: 19.442663 },
    { lat: 48.1013361, lng: 19.4427581 },
    { lat: 48.10131, lng: 19.4426555 },
    { lat: 48.1016373, lng: 19.4429041 },
    { lat: 48.1019688, lng: 19.4434824 },
    { lat: 48.1022173, lng: 19.443683 },
    { lat: 48.1028081, lng: 19.4435915 },
    { lat: 48.1033002, lng: 19.4432996 },
    { lat: 48.1037158, lng: 19.4434086 },
    { lat: 48.1039993, lng: 19.4433064 },
    { lat: 48.1041618, lng: 19.4434258 },
    { lat: 48.1041516, lng: 19.4431649 },
    { lat: 48.1046045, lng: 19.442638 },
    { lat: 48.1043688, lng: 19.4423487 },
    { lat: 48.1044108, lng: 19.4422572 },
    { lat: 48.1048171, lng: 19.4419601 },
    { lat: 48.1052599, lng: 19.4420997 },
    { lat: 48.1039876, lng: 19.4402185 },
    { lat: 48.1036447, lng: 19.4389236 },
    { lat: 48.1036902, lng: 19.4388856 },
    { lat: 48.1031384, lng: 19.4366748 },
    { lat: 48.1026664, lng: 19.4350141 },
    { lat: 48.1037776, lng: 19.4340116 },
    { lat: 48.1044453, lng: 19.4338329 },
    { lat: 48.1057546, lng: 19.4337524 },
    { lat: 48.105986, lng: 19.4338306 },
    { lat: 48.106705, lng: 19.4338778 },
    { lat: 48.1080411, lng: 19.4337944 },
    { lat: 48.1095327, lng: 19.4336132 },
    { lat: 48.1105113, lng: 19.433741 },
    { lat: 48.110991, lng: 19.4340871 },
    { lat: 48.1117105, lng: 19.4342634 },
    { lat: 48.1118139, lng: 19.4344271 },
    { lat: 48.1122822, lng: 19.4347977 },
    { lat: 48.1124189, lng: 19.4347752 },
    { lat: 48.1127638, lng: 19.4351624 },
    { lat: 48.1134622, lng: 19.4355091 },
    { lat: 48.1134585, lng: 19.4356009 },
    { lat: 48.1144814, lng: 19.4362107 },
    { lat: 48.1150135, lng: 19.4363546 },
    { lat: 48.1155575, lng: 19.4366129 },
    { lat: 48.1157169, lng: 19.4366229 },
    { lat: 48.1161631, lng: 19.4365234 },
    { lat: 48.1163832, lng: 19.436363 },
    { lat: 48.1186861, lng: 19.4339158 },
    { lat: 48.1190598, lng: 19.4342129 },
    { lat: 48.1194567, lng: 19.43465 },
    { lat: 48.1197098, lng: 19.4350173 },
    { lat: 48.1197573, lng: 19.4349738 },
    { lat: 48.1202772, lng: 19.435865 },
    { lat: 48.1204016, lng: 19.4367086 },
    { lat: 48.1205575, lng: 19.4370971 },
    { lat: 48.1206487, lng: 19.4371647 },
    { lat: 48.1211129, lng: 19.4369966 },
    { lat: 48.121825, lng: 19.4360129 },
    { lat: 48.1219977, lng: 19.4358801 },
    { lat: 48.1222905, lng: 19.4361078 },
    { lat: 48.1224769, lng: 19.4363837 },
    { lat: 48.1229034, lng: 19.4359892 },
    { lat: 48.1234931, lng: 19.4356579 },
    { lat: 48.12385, lng: 19.4357687 },
    { lat: 48.1240675, lng: 19.4356989 },
    { lat: 48.1245064, lng: 19.435921 },
    { lat: 48.1249314, lng: 19.4356833 },
    { lat: 48.1257563, lng: 19.4359723 },
    { lat: 48.1260792, lng: 19.4361739 },
    { lat: 48.1263238, lng: 19.4365087 },
    { lat: 48.1267737, lng: 19.4373033 },
    { lat: 48.1266552, lng: 19.4374625 },
    { lat: 48.127782, lng: 19.4392243 },
    { lat: 48.128132, lng: 19.4396199 },
    { lat: 48.1289441, lng: 19.4402071 },
    { lat: 48.129093, lng: 19.4419998 },
    { lat: 48.1289767, lng: 19.4424738 },
    { lat: 48.1297751, lng: 19.4433877 },
    { lat: 48.1302476, lng: 19.4440167 },
    { lat: 48.1303729, lng: 19.4441407 },
    { lat: 48.1304772, lng: 19.4442931 },
    { lat: 48.1311143, lng: 19.4438715 },
    { lat: 48.1324485, lng: 19.443393 },
    { lat: 48.1330936, lng: 19.4430397 },
    { lat: 48.1335605, lng: 19.4426892 },
    { lat: 48.1336045, lng: 19.4427597 },
    { lat: 48.1344177, lng: 19.441822 },
    { lat: 48.1346892, lng: 19.441388 },
    { lat: 48.1354095, lng: 19.4409378 },
    { lat: 48.1356423, lng: 19.440927 },
    { lat: 48.1363648, lng: 19.4396626 },
    { lat: 48.1364283, lng: 19.4396213 },
  ],
  MaláČalomija: [
    { lat: 48.104359, lng: 19.210318 },
    { lat: 48.1044184, lng: 19.2099214 },
    { lat: 48.1046941, lng: 19.2094781 },
    { lat: 48.1047782, lng: 19.2094015 },
    { lat: 48.1049645, lng: 19.2097729 },
    { lat: 48.1060806, lng: 19.2086261 },
    { lat: 48.1054129, lng: 19.2075461 },
    { lat: 48.1051685, lng: 19.2069812 },
    { lat: 48.1043694, lng: 19.2055203 },
    { lat: 48.1042263, lng: 19.2053597 },
    { lat: 48.1039421, lng: 19.2052862 },
    { lat: 48.1036658, lng: 19.2050337 },
    { lat: 48.1031775, lng: 19.2040474 },
    { lat: 48.1029488, lng: 19.2033911 },
    { lat: 48.1026257, lng: 19.2028254 },
    { lat: 48.1023588, lng: 19.2021462 },
    { lat: 48.102336, lng: 19.2020958 },
    { lat: 48.1015987, lng: 19.2026167 },
    { lat: 48.1009529, lng: 19.2029437 },
    { lat: 48.0995661, lng: 19.2038974 },
    { lat: 48.0992166, lng: 19.2040579 },
    { lat: 48.0985502, lng: 19.2045307 },
    { lat: 48.0979504, lng: 19.2048516 },
    { lat: 48.0969567, lng: 19.2055952 },
    { lat: 48.0929839, lng: 19.2048329 },
    { lat: 48.0928757, lng: 19.2054263 },
    { lat: 48.0927166, lng: 19.2058115 },
    { lat: 48.0926638, lng: 19.2061833 },
    { lat: 48.0921048, lng: 19.2075307 },
    { lat: 48.0917193, lng: 19.2089305 },
    { lat: 48.0916569, lng: 19.2096771 },
    { lat: 48.091521, lng: 19.2104625 },
    { lat: 48.0912894, lng: 19.2113891 },
    { lat: 48.0908178, lng: 19.2126386 },
    { lat: 48.0906322, lng: 19.2129245 },
    { lat: 48.0903794, lng: 19.2131243 },
    { lat: 48.0901004, lng: 19.2136172 },
    { lat: 48.0895996, lng: 19.2134979 },
    { lat: 48.0893873, lng: 19.2137853 },
    { lat: 48.0892932, lng: 19.2137745 },
    { lat: 48.0885418, lng: 19.2149305 },
    { lat: 48.0880199, lng: 19.2155244 },
    { lat: 48.0877986, lng: 19.2156784 },
    { lat: 48.0876689, lng: 19.2157011 },
    { lat: 48.0866773, lng: 19.2169878 },
    { lat: 48.085963, lng: 19.2177998 },
    { lat: 48.0847542, lng: 19.2184311 },
    { lat: 48.0838453, lng: 19.2187698 },
    { lat: 48.0837275, lng: 19.2189625 },
    { lat: 48.0815758, lng: 19.2191983 },
    { lat: 48.0750402, lng: 19.2201451 },
    { lat: 48.0744793, lng: 19.2199929 },
    { lat: 48.073069, lng: 19.2199673 },
    { lat: 48.0729351, lng: 19.2199066 },
    { lat: 48.0720995, lng: 19.2191892 },
    { lat: 48.0708969, lng: 19.2184117 },
    { lat: 48.0710915, lng: 19.2178289 },
    { lat: 48.0702314, lng: 19.2173071 },
    { lat: 48.0684968, lng: 19.2168773 },
    { lat: 48.062974, lng: 19.216613 },
    { lat: 48.062946, lng: 19.216692 },
    { lat: 48.062863, lng: 19.217016 },
    { lat: 48.062787, lng: 19.217396 },
    { lat: 48.062755, lng: 19.217775 },
    { lat: 48.062729, lng: 19.218436 },
    { lat: 48.062707, lng: 19.218982 },
    { lat: 48.062722, lng: 19.219495 },
    { lat: 48.0627, lng: 19.220265 },
    { lat: 48.06266, lng: 19.220709 },
    { lat: 48.062561, lng: 19.221287 },
    { lat: 48.062379, lng: 19.221931 },
    { lat: 48.062161, lng: 19.222432 },
    { lat: 48.062061, lng: 19.222651 },
    { lat: 48.0622645, lng: 19.2228839 },
    { lat: 48.0628241, lng: 19.2230313 },
    { lat: 48.0632427, lng: 19.2235655 },
    { lat: 48.0635087, lng: 19.2237926 },
    { lat: 48.0637365, lng: 19.223817 },
    { lat: 48.0646372, lng: 19.2241391 },
    { lat: 48.065165, lng: 19.2245786 },
    { lat: 48.0654804, lng: 19.2247129 },
    { lat: 48.0657419, lng: 19.2250127 },
    { lat: 48.0669743, lng: 19.2252013 },
    { lat: 48.0674408, lng: 19.22568 },
    { lat: 48.0678794, lng: 19.2260003 },
    { lat: 48.0688256, lng: 19.2259661 },
    { lat: 48.0693791, lng: 19.2257364 },
    { lat: 48.0699854, lng: 19.2257102 },
    { lat: 48.0702594, lng: 19.223899 },
    { lat: 48.0706961, lng: 19.223749 },
    { lat: 48.0705477, lng: 19.2230466 },
    { lat: 48.0705218, lng: 19.2227037 },
    { lat: 48.0718272, lng: 19.2233675 },
    { lat: 48.0714668, lng: 19.2244058 },
    { lat: 48.0730776, lng: 19.2249538 },
    { lat: 48.0723602, lng: 19.2285918 },
    { lat: 48.0721691, lng: 19.2302318 },
    { lat: 48.0719467, lng: 19.2328879 },
    { lat: 48.0717077, lng: 19.2341029 },
    { lat: 48.071601, lng: 19.2349403 },
    { lat: 48.0738742, lng: 19.2350878 },
    { lat: 48.0738505, lng: 19.2355725 },
    { lat: 48.0776392, lng: 19.2379179 },
    { lat: 48.0778716, lng: 19.2396662 },
    { lat: 48.0781046, lng: 19.240785 },
    { lat: 48.0781629, lng: 19.2408956 },
    { lat: 48.0783557, lng: 19.2437255 },
    { lat: 48.0783699, lng: 19.2453035 },
    { lat: 48.0798572, lng: 19.2452184 },
    { lat: 48.0821472, lng: 19.2449552 },
    { lat: 48.082215, lng: 19.2480186 },
    { lat: 48.0826825, lng: 19.2480396 },
    { lat: 48.0833631, lng: 19.2492521 },
    { lat: 48.0834307, lng: 19.2494611 },
    { lat: 48.084506, lng: 19.2492068 },
    { lat: 48.0851494, lng: 19.2475894 },
    { lat: 48.0857024, lng: 19.2454614 },
    { lat: 48.0857355, lng: 19.2454677 },
    { lat: 48.0857362, lng: 19.2453234 },
    { lat: 48.0866081, lng: 19.2434232 },
    { lat: 48.0881299, lng: 19.2403411 },
    { lat: 48.090051, lng: 19.2403693 },
    { lat: 48.0909957, lng: 19.2400493 },
    { lat: 48.0914904, lng: 19.2398133 },
    { lat: 48.0916317, lng: 19.2395691 },
    { lat: 48.0924538, lng: 19.2379712 },
    { lat: 48.0926017, lng: 19.2373754 },
    { lat: 48.0926215, lng: 19.2357806 },
    { lat: 48.0940948, lng: 19.2323823 },
    { lat: 48.0948957, lng: 19.2277326 },
    { lat: 48.0968483, lng: 19.2259214 },
    { lat: 48.0971203, lng: 19.2251254 },
    { lat: 48.0978264, lng: 19.2222481 },
    { lat: 48.1012134, lng: 19.2170607 },
    { lat: 48.1020785, lng: 19.2135633 },
    { lat: 48.1024454, lng: 19.2119057 },
    { lat: 48.1026172, lng: 19.2116378 },
    { lat: 48.1043233, lng: 19.2103452 },
    { lat: 48.104359, lng: 19.210318 },
  ],
  Olováry: [
    { lat: 48.1831866, lng: 19.401274 },
    { lat: 48.1830529, lng: 19.4012804 },
    { lat: 48.1824907, lng: 19.4013063 },
    { lat: 48.1819347, lng: 19.4017792 },
    { lat: 48.1816446, lng: 19.4021939 },
    { lat: 48.1812458, lng: 19.4029073 },
    { lat: 48.1811404, lng: 19.4030163 },
    { lat: 48.1809014, lng: 19.4030844 },
    { lat: 48.1796039, lng: 19.4024163 },
    { lat: 48.1792306, lng: 19.4023373 },
    { lat: 48.1780656, lng: 19.4017583 },
    { lat: 48.1770146, lng: 19.4013643 },
    { lat: 48.1767247, lng: 19.4014527 },
    { lat: 48.1764346, lng: 19.4014192 },
    { lat: 48.1761508, lng: 19.401591 },
    { lat: 48.1753389, lng: 19.401727 },
    { lat: 48.1751872, lng: 19.4019497 },
    { lat: 48.1749044, lng: 19.4021164 },
    { lat: 48.1747246, lng: 19.4023177 },
    { lat: 48.1747558, lng: 19.4024524 },
    { lat: 48.1745614, lng: 19.4024865 },
    { lat: 48.1743554, lng: 19.4027788 },
    { lat: 48.1741709, lng: 19.4028334 },
    { lat: 48.1739543, lng: 19.4031604 },
    { lat: 48.1735079, lng: 19.4036064 },
    { lat: 48.1731737, lng: 19.404119 },
    { lat: 48.1730556, lng: 19.4041488 },
    { lat: 48.1727467, lng: 19.4047169 },
    { lat: 48.1722929, lng: 19.4050574 },
    { lat: 48.1720485, lng: 19.4054417 },
    { lat: 48.1715685, lng: 19.4057967 },
    { lat: 48.1709792, lng: 19.4071299 },
    { lat: 48.1699045, lng: 19.4089676 },
    { lat: 48.170224, lng: 19.4073894 },
    { lat: 48.170303, lng: 19.4065866 },
    { lat: 48.1702332, lng: 19.4054243 },
    { lat: 48.1700064, lng: 19.4051105 },
    { lat: 48.1701814, lng: 19.4041651 },
    { lat: 48.1702232, lng: 19.4036229 },
    { lat: 48.1701632, lng: 19.4025994 },
    { lat: 48.1700944, lng: 19.4020068 },
    { lat: 48.1698779, lng: 19.4010773 },
    { lat: 48.1699104, lng: 19.4009079 },
    { lat: 48.1695773, lng: 19.4000906 },
    { lat: 48.1694369, lng: 19.3999332 },
    { lat: 48.1689303, lng: 19.3997547 },
    { lat: 48.1686402, lng: 19.3997241 },
    { lat: 48.1683531, lng: 19.3997758 },
    { lat: 48.1682531, lng: 19.3997582 },
    { lat: 48.1682123, lng: 19.3996809 },
    { lat: 48.1683715, lng: 19.3994035 },
    { lat: 48.1686274, lng: 19.3985996 },
    { lat: 48.1687558, lng: 19.3970787 },
    { lat: 48.1689506, lng: 19.3962237 },
    { lat: 48.1690033, lng: 19.3952708 },
    { lat: 48.1689564, lng: 19.3942357 },
    { lat: 48.1687985, lng: 19.3930026 },
    { lat: 48.1688182, lng: 19.392871 },
    { lat: 48.1688584, lng: 19.3928667 },
    { lat: 48.1696295, lng: 19.3873774 },
    { lat: 48.1684734, lng: 19.3867919 },
    { lat: 48.1683689, lng: 19.3857587 },
    { lat: 48.1679163, lng: 19.3855594 },
    { lat: 48.1673149, lng: 19.3854727 },
    { lat: 48.165863, lng: 19.3849262 },
    { lat: 48.1657617, lng: 19.384997 },
    { lat: 48.1654707, lng: 19.3848024 },
    { lat: 48.165084, lng: 19.3848009 },
    { lat: 48.1644932, lng: 19.3849422 },
    { lat: 48.1640641, lng: 19.3847101 },
    { lat: 48.1634746, lng: 19.3849917 },
    { lat: 48.1630418, lng: 19.3850152 },
    { lat: 48.1626807, lng: 19.3848175 },
    { lat: 48.1618121, lng: 19.3849669 },
    { lat: 48.1614339, lng: 19.3847181 },
    { lat: 48.1610862, lng: 19.3848511 },
    { lat: 48.1604526, lng: 19.3844406 },
    { lat: 48.1598919, lng: 19.3836281 },
    { lat: 48.1595049, lng: 19.3825323 },
    { lat: 48.1591707, lng: 19.382002 },
    { lat: 48.1590575, lng: 19.3817772 },
    { lat: 48.1577606, lng: 19.3810058 },
    { lat: 48.1574458, lng: 19.3808812 },
    { lat: 48.1571601, lng: 19.3806554 },
    { lat: 48.1570002, lng: 19.3804476 },
    { lat: 48.1563365, lng: 19.380051 },
    { lat: 48.155865, lng: 19.3798513 },
    { lat: 48.1553751, lng: 19.3793907 },
    { lat: 48.1550239, lng: 19.3791881 },
    { lat: 48.154485, lng: 19.3786639 },
    { lat: 48.1525478, lng: 19.3777985 },
    { lat: 48.1508084, lng: 19.3776257 },
    { lat: 48.1501744, lng: 19.3774713 },
    { lat: 48.1497928, lng: 19.3773133 },
    { lat: 48.1496691, lng: 19.3771582 },
    { lat: 48.1493192, lng: 19.3769562 },
    { lat: 48.1487869, lng: 19.3772096 },
    { lat: 48.1487584, lng: 19.3772264 },
    { lat: 48.1487538, lng: 19.3772738 },
    { lat: 48.1487258, lng: 19.3776279 },
    { lat: 48.1490016, lng: 19.3783086 },
    { lat: 48.1490226, lng: 19.3785601 },
    { lat: 48.1486945, lng: 19.3793807 },
    { lat: 48.1484457, lng: 19.3797644 },
    { lat: 48.148402, lng: 19.3807393 },
    { lat: 48.1484138, lng: 19.3808238 },
    { lat: 48.1485131, lng: 19.3809191 },
    { lat: 48.1485794, lng: 19.3812939 },
    { lat: 48.148655, lng: 19.3814132 },
    { lat: 48.1486241, lng: 19.3816371 },
    { lat: 48.148501, lng: 19.3817741 },
    { lat: 48.148442, lng: 19.3821668 },
    { lat: 48.148334, lng: 19.3823379 },
    { lat: 48.148228, lng: 19.3833539 },
    { lat: 48.148288, lng: 19.383548 },
    { lat: 48.148197, lng: 19.3837645 },
    { lat: 48.1480772, lng: 19.3843515 },
    { lat: 48.1481727, lng: 19.3849085 },
    { lat: 48.1481608, lng: 19.3850149 },
    { lat: 48.1480652, lng: 19.3851599 },
    { lat: 48.148023, lng: 19.3854093 },
    { lat: 48.1481189, lng: 19.385841 },
    { lat: 48.1480853, lng: 19.3859624 },
    { lat: 48.1481633, lng: 19.3861574 },
    { lat: 48.1479336, lng: 19.3865161 },
    { lat: 48.1478286, lng: 19.3869148 },
    { lat: 48.1479323, lng: 19.3871483 },
    { lat: 48.14758, lng: 19.3876308 },
    { lat: 48.1472616, lng: 19.3881892 },
    { lat: 48.1469433, lng: 19.3889832 },
    { lat: 48.1467033, lng: 19.3897661 },
    { lat: 48.1463432, lng: 19.3905794 },
    { lat: 48.1456172, lng: 19.3912393 },
    { lat: 48.1452566, lng: 19.3917367 },
    { lat: 48.1431975, lng: 19.3932522 },
    { lat: 48.1424458, lng: 19.3952426 },
    { lat: 48.1416305, lng: 19.3969384 },
    { lat: 48.1400092, lng: 19.3987017 },
    { lat: 48.1383197, lng: 19.4012184 },
    { lat: 48.1377756, lng: 19.4023107 },
    { lat: 48.1373575, lng: 19.4029757 },
    { lat: 48.1370084, lng: 19.4031483 },
    { lat: 48.1367115, lng: 19.4034966 },
    { lat: 48.1365343, lng: 19.403798 },
    { lat: 48.1363698, lng: 19.4051023 },
    { lat: 48.1361223, lng: 19.4059171 },
    { lat: 48.1357897, lng: 19.4065815 },
    { lat: 48.1353719, lng: 19.4071092 },
    { lat: 48.1352057, lng: 19.4074782 },
    { lat: 48.1345711, lng: 19.4078327 },
    { lat: 48.1344405, lng: 19.4080501 },
    { lat: 48.1341663, lng: 19.4082291 },
    { lat: 48.1337671, lng: 19.4083219 },
    { lat: 48.1336643, lng: 19.4084859 },
    { lat: 48.1335181, lng: 19.4085141 },
    { lat: 48.133422, lng: 19.4084601 },
    { lat: 48.1333601, lng: 19.4085111 },
    { lat: 48.1331044, lng: 19.4084415 },
    { lat: 48.1320659, lng: 19.4091672 },
    { lat: 48.1319711, lng: 19.409329 },
    { lat: 48.1317412, lng: 19.4094962 },
    { lat: 48.131398, lng: 19.4096056 },
    { lat: 48.1310604, lng: 19.4099014 },
    { lat: 48.1297992, lng: 19.4103233 },
    { lat: 48.129504, lng: 19.4105172 },
    { lat: 48.1294331, lng: 19.4106358 },
    { lat: 48.129418, lng: 19.4107704 },
    { lat: 48.1291278, lng: 19.4110912 },
    { lat: 48.1289882, lng: 19.4114729 },
    { lat: 48.128796, lng: 19.4116033 },
    { lat: 48.1280742, lng: 19.4118572 },
    { lat: 48.1278144, lng: 19.412011 },
    { lat: 48.1272686, lng: 19.4131458 },
    { lat: 48.1269759, lng: 19.4129835 },
    { lat: 48.1267766, lng: 19.4135122 },
    { lat: 48.1266518, lng: 19.4136157 },
    { lat: 48.1263096, lng: 19.4143127 },
    { lat: 48.1261227, lng: 19.4145738 },
    { lat: 48.1260248, lng: 19.4150046 },
    { lat: 48.1258895, lng: 19.4151502 },
    { lat: 48.1258616, lng: 19.4152985 },
    { lat: 48.1257014, lng: 19.4154364 },
    { lat: 48.1255526, lng: 19.4154599 },
    { lat: 48.1253038, lng: 19.4157716 },
    { lat: 48.1251384, lng: 19.4161327 },
    { lat: 48.1250257, lng: 19.4161119 },
    { lat: 48.124922, lng: 19.4161817 },
    { lat: 48.1248315, lng: 19.4164594 },
    { lat: 48.1246905, lng: 19.4165805 },
    { lat: 48.1246183, lng: 19.416815 },
    { lat: 48.1241137, lng: 19.4176299 },
    { lat: 48.12362, lng: 19.4186974 },
    { lat: 48.1232961, lng: 19.4190699 },
    { lat: 48.123159, lng: 19.4193401 },
    { lat: 48.1230721, lng: 19.4193613 },
    { lat: 48.1229598, lng: 19.4197263 },
    { lat: 48.1226031, lng: 19.4200233 },
    { lat: 48.1226813, lng: 19.4205526 },
    { lat: 48.1228899, lng: 19.4211961 },
    { lat: 48.1235596, lng: 19.4217973 },
    { lat: 48.1243893, lng: 19.42285 },
    { lat: 48.1252015, lng: 19.4237678 },
    { lat: 48.1257692, lng: 19.4240464 },
    { lat: 48.1262247, lng: 19.4248823 },
    { lat: 48.126653, lng: 19.4260513 },
    { lat: 48.1270139, lng: 19.426676 },
    { lat: 48.1271224, lng: 19.426752 },
    { lat: 48.1281407, lng: 19.4285036 },
    { lat: 48.1281515, lng: 19.4295883 },
    { lat: 48.1282635, lng: 19.4300993 },
    { lat: 48.1288885, lng: 19.4314952 },
    { lat: 48.1288089, lng: 19.4322391 },
    { lat: 48.1289262, lng: 19.4328159 },
    { lat: 48.1290164, lng: 19.4336492 },
    { lat: 48.1291241, lng: 19.4342089 },
    { lat: 48.1294031, lng: 19.4348216 },
    { lat: 48.130175, lng: 19.4356034 },
    { lat: 48.1312042, lng: 19.4360375 },
    { lat: 48.1320452, lng: 19.4364974 },
    { lat: 48.1327041, lng: 19.4370118 },
    { lat: 48.1332872, lng: 19.4373561 },
    { lat: 48.1350041, lng: 19.4380292 },
    { lat: 48.1351794, lng: 19.4383311 },
    { lat: 48.1359234, lng: 19.4389319 },
    { lat: 48.1363909, lng: 19.4395709 },
    { lat: 48.1364283, lng: 19.4396213 },
    { lat: 48.1366757, lng: 19.4393747 },
    { lat: 48.137709, lng: 19.4387017 },
    { lat: 48.1387395, lng: 19.4374685 },
    { lat: 48.1393443, lng: 19.4378673 },
    { lat: 48.1407692, lng: 19.4360086 },
    { lat: 48.1409207, lng: 19.4362107 },
    { lat: 48.1412012, lng: 19.4359193 },
    { lat: 48.1419443, lng: 19.4346877 },
    { lat: 48.1423581, lng: 19.4340992 },
    { lat: 48.14257, lng: 19.433904 },
    { lat: 48.1428446, lng: 19.4338538 },
    { lat: 48.1434246, lng: 19.4340082 },
    { lat: 48.1439325, lng: 19.4339056 },
    { lat: 48.1458217, lng: 19.4327936 },
    { lat: 48.1464807, lng: 19.4324757 },
    { lat: 48.1472115, lng: 19.4321713 },
    { lat: 48.1476968, lng: 19.4321412 },
    { lat: 48.1483497, lng: 19.4321944 },
    { lat: 48.1486344, lng: 19.4323768 },
    { lat: 48.1488442, lng: 19.4325936 },
    { lat: 48.1492005, lng: 19.4326466 },
    { lat: 48.1493138, lng: 19.4343739 },
    { lat: 48.1493065, lng: 19.4344145 },
    { lat: 48.1497305, lng: 19.4342766 },
    { lat: 48.1504399, lng: 19.433569 },
    { lat: 48.1506888, lng: 19.4334703 },
    { lat: 48.1509244, lng: 19.433112 },
    { lat: 48.1511866, lng: 19.4329396 },
    { lat: 48.1513478, lng: 19.4329159 },
    { lat: 48.1519513, lng: 19.4331624 },
    { lat: 48.1530602, lng: 19.4332349 },
    { lat: 48.153702, lng: 19.433818 },
    { lat: 48.1539963, lng: 19.4345115 },
    { lat: 48.1542522, lng: 19.4348692 },
    { lat: 48.1547105, lng: 19.4357435 },
    { lat: 48.1549662, lng: 19.4364319 },
    { lat: 48.1561182, lng: 19.4376308 },
    { lat: 48.1569585, lng: 19.4391472 },
    { lat: 48.1572936, lng: 19.4401831 },
    { lat: 48.1577353, lng: 19.4406339 },
    { lat: 48.1583101, lng: 19.4415189 },
    { lat: 48.160252, lng: 19.4435744 },
    { lat: 48.1603886, lng: 19.4437958 },
    { lat: 48.1606831, lng: 19.4436557 },
    { lat: 48.1613233, lng: 19.44311 },
    { lat: 48.1618045, lng: 19.4443738 },
    { lat: 48.1623789, lng: 19.4438894 },
    { lat: 48.1631441, lng: 19.4461128 },
    { lat: 48.1631947, lng: 19.4466607 },
    { lat: 48.164455, lng: 19.4461622 },
    { lat: 48.165076, lng: 19.4459944 },
    { lat: 48.1666069, lng: 19.4457908 },
    { lat: 48.1668712, lng: 19.445274 },
    { lat: 48.1671005, lng: 19.4449734 },
    { lat: 48.1672613, lng: 19.4448231 },
    { lat: 48.1673222, lng: 19.4449423 },
    { lat: 48.1676229, lng: 19.4445336 },
    { lat: 48.1680081, lng: 19.4441922 },
    { lat: 48.1685737, lng: 19.4433305 },
    { lat: 48.1690264, lng: 19.4421527 },
    { lat: 48.1694964, lng: 19.441122 },
    { lat: 48.1700731, lng: 19.4400663 },
    { lat: 48.1702531, lng: 19.4395565 },
    { lat: 48.1706047, lng: 19.4382435 },
    { lat: 48.1711245, lng: 19.4369314 },
    { lat: 48.1716358, lng: 19.4358274 },
    { lat: 48.172126, lng: 19.4352301 },
    { lat: 48.1723806, lng: 19.4347743 },
    { lat: 48.1727218, lng: 19.4339844 },
    { lat: 48.1729444, lng: 19.4336619 },
    { lat: 48.1732042, lng: 19.4331458 },
    { lat: 48.1734529, lng: 19.4322817 },
    { lat: 48.1735471, lng: 19.4317915 },
    { lat: 48.1735664, lng: 19.4314607 },
    { lat: 48.1735076, lng: 19.4306704 },
    { lat: 48.1735614, lng: 19.4301891 },
    { lat: 48.1735639, lng: 19.4287839 },
    { lat: 48.1735063, lng: 19.4271418 },
    { lat: 48.1735645, lng: 19.4262745 },
    { lat: 48.1737642, lng: 19.4255645 },
    { lat: 48.174469, lng: 19.4242615 },
    { lat: 48.1750792, lng: 19.423507 },
    { lat: 48.1755231, lng: 19.4227916 },
    { lat: 48.176013, lng: 19.4224489 },
    { lat: 48.1764997, lng: 19.4222529 },
    { lat: 48.1768891, lng: 19.4222672 },
    { lat: 48.177025, lng: 19.4221853 },
    { lat: 48.1771527, lng: 19.4219783 },
    { lat: 48.1772529, lng: 19.4215712 },
    { lat: 48.1779564, lng: 19.4203177 },
    { lat: 48.1781243, lng: 19.4198874 },
    { lat: 48.178128, lng: 19.4196081 },
    { lat: 48.1781293, lng: 19.4195408 },
    { lat: 48.1780475, lng: 19.4191932 },
    { lat: 48.1780388, lng: 19.4184611 },
    { lat: 48.1779578, lng: 19.4176797 },
    { lat: 48.1779831, lng: 19.4171922 },
    { lat: 48.1780566, lng: 19.4168638 },
    { lat: 48.1783674, lng: 19.4162408 },
    { lat: 48.1789835, lng: 19.4155858 },
    { lat: 48.1795827, lng: 19.4146748 },
    { lat: 48.1805317, lng: 19.4136536 },
    { lat: 48.1820337, lng: 19.4116954 },
    { lat: 48.1822828, lng: 19.4113148 },
    { lat: 48.1825614, lng: 19.4106122 },
    { lat: 48.1828801, lng: 19.4092498 },
    { lat: 48.1831132, lng: 19.4073229 },
    { lat: 48.1832237, lng: 19.4048553 },
    { lat: 48.1835129, lng: 19.4024221 },
    { lat: 48.1836714, lng: 19.4018455 },
    { lat: 48.1839222, lng: 19.4013583 },
    { lat: 48.1843583, lng: 19.4008571 },
    { lat: 48.1835324, lng: 19.4010077 },
    { lat: 48.1831866, lng: 19.401274 },
  ],
  DolnáStrehová: [
    { lat: 48.2390389, lng: 19.5337263 },
    { lat: 48.239046, lng: 19.53368 },
    { lat: 48.239391, lng: 19.5315182 },
    { lat: 48.2395662, lng: 19.5308251 },
    { lat: 48.2415081, lng: 19.5280322 },
    { lat: 48.2429706, lng: 19.5245489 },
    { lat: 48.2440078, lng: 19.5219129 },
    { lat: 48.2445737, lng: 19.5214298 },
    { lat: 48.2456472, lng: 19.520692 },
    { lat: 48.2463989, lng: 19.5194499 },
    { lat: 48.2470479, lng: 19.5187703 },
    { lat: 48.2479393, lng: 19.5157369 },
    { lat: 48.2483048, lng: 19.5132206 },
    { lat: 48.248812, lng: 19.511379 },
    { lat: 48.2499211, lng: 19.5105333 },
    { lat: 48.2506369, lng: 19.5103922 },
    { lat: 48.2514058, lng: 19.5100359 },
    { lat: 48.2524136, lng: 19.5077639 },
    { lat: 48.2536439, lng: 19.5065235 },
    { lat: 48.254091, lng: 19.5059107 },
    { lat: 48.2548694, lng: 19.5045006 },
    { lat: 48.2558468, lng: 19.5048452 },
    { lat: 48.2562256, lng: 19.5052099 },
    { lat: 48.2565566, lng: 19.5056902 },
    { lat: 48.2565773, lng: 19.5056478 },
    { lat: 48.2566918, lng: 19.505953 },
    { lat: 48.2577406, lng: 19.5051215 },
    { lat: 48.2604177, lng: 19.5015258 },
    { lat: 48.2606466, lng: 19.5019878 },
    { lat: 48.2606451, lng: 19.5022246 },
    { lat: 48.2607703, lng: 19.5025149 },
    { lat: 48.2607227, lng: 19.5031862 },
    { lat: 48.2612183, lng: 19.5063055 },
    { lat: 48.2612752, lng: 19.5071696 },
    { lat: 48.2617072, lng: 19.5083387 },
    { lat: 48.2619953, lng: 19.5096137 },
    { lat: 48.262053, lng: 19.5096194 },
    { lat: 48.2621196, lng: 19.5097415 },
    { lat: 48.2623622, lng: 19.5112529 },
    { lat: 48.2639457, lng: 19.5107211 },
    { lat: 48.2660001, lng: 19.51026 },
    { lat: 48.2669797, lng: 19.509199 },
    { lat: 48.2673002, lng: 19.5094754 },
    { lat: 48.2681716, lng: 19.5106243 },
    { lat: 48.2684568, lng: 19.511348 },
    { lat: 48.2687077, lng: 19.5123112 },
    { lat: 48.2687076, lng: 19.512649 },
    { lat: 48.2692376, lng: 19.5130692 },
    { lat: 48.269184, lng: 19.5131742 },
    { lat: 48.2695366, lng: 19.5133942 },
    { lat: 48.2697686, lng: 19.5137011 },
    { lat: 48.2699112, lng: 19.5142745 },
    { lat: 48.2698682, lng: 19.5150982 },
    { lat: 48.270782, lng: 19.5160161 },
    { lat: 48.2709368, lng: 19.5160502 },
    { lat: 48.2711648, lng: 19.5159349 },
    { lat: 48.2723561, lng: 19.5173948 },
    { lat: 48.2737337, lng: 19.5188882 },
    { lat: 48.2742821, lng: 19.5192924 },
    { lat: 48.2746668, lng: 19.5196624 },
    { lat: 48.2746958, lng: 19.5197534 },
    { lat: 48.275467, lng: 19.520239 },
    { lat: 48.275512, lng: 19.520156 },
    { lat: 48.275522, lng: 19.520129 },
    { lat: 48.275603, lng: 19.519846 },
    { lat: 48.275636, lng: 19.519662 },
    { lat: 48.275685, lng: 19.519306 },
    { lat: 48.275822, lng: 19.518823 },
    { lat: 48.275899, lng: 19.518456 },
    { lat: 48.275981, lng: 19.517797 },
    { lat: 48.276002, lng: 19.517602 },
    { lat: 48.276061, lng: 19.51692 },
    { lat: 48.276082, lng: 19.516509 },
    { lat: 48.276081, lng: 19.516331 },
    { lat: 48.276094, lng: 19.516185 },
    { lat: 48.276142, lng: 19.515797 },
    { lat: 48.2762, lng: 19.51544 },
    { lat: 48.276229, lng: 19.515311 },
    { lat: 48.276317, lng: 19.514971 },
    { lat: 48.276455, lng: 19.514582 },
    { lat: 48.276476, lng: 19.514523 },
    { lat: 48.276619, lng: 19.514212 },
    { lat: 48.276756, lng: 19.513966 },
    { lat: 48.277141, lng: 19.513005 },
    { lat: 48.277293, lng: 19.512578 },
    { lat: 48.277312, lng: 19.512513 },
    { lat: 48.277204, lng: 19.511978 },
    { lat: 48.27719, lng: 19.511843 },
    { lat: 48.27718, lng: 19.511618 },
    { lat: 48.277081, lng: 19.510975 },
    { lat: 48.277017, lng: 19.51084 },
    { lat: 48.276996, lng: 19.510612 },
    { lat: 48.27692, lng: 19.510373 },
    { lat: 48.276901, lng: 19.510313 },
    { lat: 48.276862, lng: 19.510187 },
    { lat: 48.276842, lng: 19.510124 },
    { lat: 48.276787, lng: 19.510014 },
    { lat: 48.27674, lng: 19.50992 },
    { lat: 48.276726, lng: 19.509827 },
    { lat: 48.276686, lng: 19.509557 },
    { lat: 48.276691, lng: 19.509373 },
    { lat: 48.276701, lng: 19.50918 },
    { lat: 48.276712, lng: 19.509104 },
    { lat: 48.27674, lng: 19.508919 },
    { lat: 48.276749, lng: 19.508735 },
    { lat: 48.276754, lng: 19.508669 },
    { lat: 48.276753, lng: 19.508545 },
    { lat: 48.276845, lng: 19.507917 },
    { lat: 48.276936, lng: 19.507611 },
    { lat: 48.276949, lng: 19.507566 },
    { lat: 48.276964, lng: 19.50747 },
    { lat: 48.277007, lng: 19.507397 },
    { lat: 48.277082, lng: 19.507267 },
    { lat: 48.27715, lng: 19.507142 },
    { lat: 48.277201, lng: 19.507049 },
    { lat: 48.277399, lng: 19.506689 },
    { lat: 48.27742, lng: 19.506651 },
    { lat: 48.277438, lng: 19.506626 },
    { lat: 48.277455, lng: 19.506605 },
    { lat: 48.277758, lng: 19.506459 },
    { lat: 48.277879, lng: 19.506434 },
    { lat: 48.277916, lng: 19.506428 },
    { lat: 48.277948, lng: 19.506558 },
    { lat: 48.278135, lng: 19.506427 },
    { lat: 48.2790678, lng: 19.4997353 },
    { lat: 48.2785517, lng: 19.4994701 },
    { lat: 48.2770936, lng: 19.4982406 },
    { lat: 48.2764735, lng: 19.4974606 },
    { lat: 48.2750266, lng: 19.4958639 },
    { lat: 48.274023, lng: 19.4949079 },
    { lat: 48.272439, lng: 19.4928651 },
    { lat: 48.2724979, lng: 19.4927496 },
    { lat: 48.2725783, lng: 19.4922756 },
    { lat: 48.2729391, lng: 19.4911908 },
    { lat: 48.2733202, lng: 19.4895293 },
    { lat: 48.2735877, lng: 19.4878991 },
    { lat: 48.2735013, lng: 19.4865023 },
    { lat: 48.2737047, lng: 19.4852347 },
    { lat: 48.2737675, lng: 19.4851085 },
    { lat: 48.2737183, lng: 19.4843467 },
    { lat: 48.2737572, lng: 19.4840939 },
    { lat: 48.2736123, lng: 19.4837154 },
    { lat: 48.2736012, lng: 19.4831894 },
    { lat: 48.2733956, lng: 19.4828639 },
    { lat: 48.2732176, lng: 19.4828401 },
    { lat: 48.2729732, lng: 19.4825573 },
    { lat: 48.2729657, lng: 19.4822751 },
    { lat: 48.2727638, lng: 19.4819326 },
    { lat: 48.2727239, lng: 19.4808397 },
    { lat: 48.2730436, lng: 19.4796972 },
    { lat: 48.273212, lng: 19.4795164 },
    { lat: 48.2732766, lng: 19.4793515 },
    { lat: 48.2734537, lng: 19.4786091 },
    { lat: 48.275206, lng: 19.479689 },
    { lat: 48.2761981, lng: 19.4758401 },
    { lat: 48.2762131, lng: 19.4757774 },
    { lat: 48.2762968, lng: 19.4754463 },
    { lat: 48.276177, lng: 19.4752685 },
    { lat: 48.2760461, lng: 19.4752918 },
    { lat: 48.2759097, lng: 19.4758711 },
    { lat: 48.275715, lng: 19.4758031 },
    { lat: 48.2756341, lng: 19.4757094 },
    { lat: 48.2754427, lng: 19.4759799 },
    { lat: 48.2752747, lng: 19.4760987 },
    { lat: 48.2751812, lng: 19.4762692 },
    { lat: 48.2750354, lng: 19.4763089 },
    { lat: 48.2748649, lng: 19.4765925 },
    { lat: 48.27483, lng: 19.4769064 },
    { lat: 48.2747122, lng: 19.4769364 },
    { lat: 48.2743375, lng: 19.476034 },
    { lat: 48.2742404, lng: 19.47555 },
    { lat: 48.2743194, lng: 19.475073 },
    { lat: 48.2745306, lng: 19.4748421 },
    { lat: 48.2747758, lng: 19.4744438 },
    { lat: 48.2749096, lng: 19.4740993 },
    { lat: 48.2744349, lng: 19.4734222 },
    { lat: 48.2740649, lng: 19.4719904 },
    { lat: 48.2736527, lng: 19.4713024 },
    { lat: 48.2729613, lng: 19.4705872 },
    { lat: 48.2726515, lng: 19.469631 },
    { lat: 48.2723037, lng: 19.4689654 },
    { lat: 48.2720463, lng: 19.469198 },
    { lat: 48.2719823, lng: 19.469274 },
    { lat: 48.2694768, lng: 19.471774 },
    { lat: 48.2688404, lng: 19.4725776 },
    { lat: 48.2681527, lng: 19.4732183 },
    { lat: 48.267875, lng: 19.4735729 },
    { lat: 48.2677116, lng: 19.4739317 },
    { lat: 48.2672528, lng: 19.4745834 },
    { lat: 48.2667882, lng: 19.4754658 },
    { lat: 48.2666824, lng: 19.4757125 },
    { lat: 48.266668, lng: 19.4759549 },
    { lat: 48.26652, lng: 19.4762389 },
    { lat: 48.2662373, lng: 19.4763135 },
    { lat: 48.2660397, lng: 19.476497 },
    { lat: 48.2658667, lng: 19.4765593 },
    { lat: 48.2657349, lng: 19.4764136 },
    { lat: 48.2645964, lng: 19.4766258 },
    { lat: 48.2645457, lng: 19.4762445 },
    { lat: 48.2635148, lng: 19.4764641 },
    { lat: 48.2631211, lng: 19.4760254 },
    { lat: 48.2626001, lng: 19.4758933 },
    { lat: 48.2625046, lng: 19.4758061 },
    { lat: 48.2623584, lng: 19.4755215 },
    { lat: 48.262356, lng: 19.4753746 },
    { lat: 48.26206, lng: 19.4749753 },
    { lat: 48.2622147, lng: 19.4746407 },
    { lat: 48.2617417, lng: 19.4742635 },
    { lat: 48.2619829, lng: 19.4739778 },
    { lat: 48.2601722, lng: 19.4720847 },
    { lat: 48.2594114, lng: 19.4714824 },
    { lat: 48.2590774, lng: 19.4711625 },
    { lat: 48.2587895, lng: 19.4723017 },
    { lat: 48.2587128, lng: 19.4722033 },
    { lat: 48.2587379, lng: 19.4720674 },
    { lat: 48.258607, lng: 19.4720418 },
    { lat: 48.2585491, lng: 19.4721003 },
    { lat: 48.2583738, lng: 19.4726188 },
    { lat: 48.2582852, lng: 19.4726991 },
    { lat: 48.2581457, lng: 19.4725836 },
    { lat: 48.257894, lng: 19.4726953 },
    { lat: 48.2577545, lng: 19.4725108 },
    { lat: 48.2575777, lng: 19.4719381 },
    { lat: 48.2572299, lng: 19.471587 },
    { lat: 48.2572392, lng: 19.4716847 },
    { lat: 48.257127, lng: 19.4718652 },
    { lat: 48.2570954, lng: 19.4724913 },
    { lat: 48.2571437, lng: 19.4727256 },
    { lat: 48.2571059, lng: 19.4728817 },
    { lat: 48.2567619, lng: 19.4731402 },
    { lat: 48.2566458, lng: 19.4734042 },
    { lat: 48.2562886, lng: 19.4735994 },
    { lat: 48.2562329, lng: 19.4735391 },
    { lat: 48.2560286, lng: 19.4739217 },
    { lat: 48.2559692, lng: 19.4739244 },
    { lat: 48.2558302, lng: 19.4736288 },
    { lat: 48.2558004, lng: 19.4733306 },
    { lat: 48.2557242, lng: 19.4732209 },
    { lat: 48.2555893, lng: 19.4727456 },
    { lat: 48.2551151, lng: 19.472586 },
    { lat: 48.2550579, lng: 19.4726599 },
    { lat: 48.2542817, lng: 19.4721621 },
    { lat: 48.2538631, lng: 19.471983 },
    { lat: 48.2535659, lng: 19.4717026 },
    { lat: 48.2532239, lng: 19.4711126 },
    { lat: 48.2531894, lng: 19.4711919 },
    { lat: 48.2519852, lng: 19.4703855 },
    { lat: 48.2516608, lng: 19.4704617 },
    { lat: 48.251298, lng: 19.4701539 },
    { lat: 48.2512102, lng: 19.4697922 },
    { lat: 48.2510219, lng: 19.4696239 },
    { lat: 48.2508326, lng: 19.4695041 },
    { lat: 48.2507651, lng: 19.4696968 },
    { lat: 48.2505561, lng: 19.4697124 },
    { lat: 48.2504713, lng: 19.4698486 },
    { lat: 48.2502496, lng: 19.4697845 },
    { lat: 48.2502488, lng: 19.4701297 },
    { lat: 48.2500583, lng: 19.4697686 },
    { lat: 48.2500384, lng: 19.4691016 },
    { lat: 48.24989, lng: 19.4681218 },
    { lat: 48.2499699, lng: 19.4680494 },
    { lat: 48.2498613, lng: 19.4679173 },
    { lat: 48.2498631, lng: 19.4674277 },
    { lat: 48.2497105, lng: 19.4669374 },
    { lat: 48.249708, lng: 19.4665975 },
    { lat: 48.2495144, lng: 19.4664124 },
    { lat: 48.2494044, lng: 19.466181 },
    { lat: 48.2491779, lng: 19.4662518 },
    { lat: 48.2491851, lng: 19.4659258 },
    { lat: 48.249099, lng: 19.465793 },
    { lat: 48.2489598, lng: 19.4657811 },
    { lat: 48.2488047, lng: 19.4658632 },
    { lat: 48.2487139, lng: 19.4655885 },
    { lat: 48.2483992, lng: 19.4654589 },
    { lat: 48.2480945, lng: 19.4648746 },
    { lat: 48.2478257, lng: 19.4648371 },
    { lat: 48.2478816, lng: 19.464443 },
    { lat: 48.2477421, lng: 19.464278 },
    { lat: 48.2474885, lng: 19.4641123 },
    { lat: 48.2472979, lng: 19.4636992 },
    { lat: 48.2471894, lng: 19.463647 },
    { lat: 48.2471973, lng: 19.4634782 },
    { lat: 48.2470216, lng: 19.4633637 },
    { lat: 48.2468198, lng: 19.463049 },
    { lat: 48.2467244, lng: 19.4629841 },
    { lat: 48.2466163, lng: 19.4630086 },
    { lat: 48.246426, lng: 19.4632266 },
    { lat: 48.2462387, lng: 19.4631508 },
    { lat: 48.245869, lng: 19.4636773 },
    { lat: 48.245677, lng: 19.4636228 },
    { lat: 48.2453877, lng: 19.46274 },
    { lat: 48.245326, lng: 19.4623614 },
    { lat: 48.2453836, lng: 19.461214 },
    { lat: 48.2452726, lng: 19.4599256 },
    { lat: 48.2453468, lng: 19.4598768 },
    { lat: 48.2450484, lng: 19.4585855 },
    { lat: 48.2446376, lng: 19.4589565 },
    { lat: 48.2443956, lng: 19.4584841 },
    { lat: 48.2444372, lng: 19.4569474 },
    { lat: 48.2440348, lng: 19.4526744 },
    { lat: 48.2441023, lng: 19.4513075 },
    { lat: 48.2435458, lng: 19.4502807 },
    { lat: 48.242892, lng: 19.4510935 },
    { lat: 48.2425784, lng: 19.4512528 },
    { lat: 48.2424165, lng: 19.4511917 },
    { lat: 48.2418538, lng: 19.4502985 },
    { lat: 48.241805, lng: 19.4500498 },
    { lat: 48.2417379, lng: 19.4499883 },
    { lat: 48.2416338, lng: 19.4500997 },
    { lat: 48.2406035, lng: 19.4511006 },
    { lat: 48.2388207, lng: 19.4538639 },
    { lat: 48.2383095, lng: 19.4541115 },
    { lat: 48.2381337, lng: 19.4542569 },
    { lat: 48.2376549, lng: 19.4551943 },
    { lat: 48.2369871, lng: 19.4557578 },
    { lat: 48.2361474, lng: 19.4566211 },
    { lat: 48.2343938, lng: 19.4592137 },
    { lat: 48.2337041, lng: 19.4605592 },
    { lat: 48.2333239, lng: 19.461631 },
    { lat: 48.2322649, lng: 19.463945 },
    { lat: 48.2316588, lng: 19.4656548 },
    { lat: 48.2309403, lng: 19.4684521 },
    { lat: 48.230047, lng: 19.4705302 },
    { lat: 48.2296564, lng: 19.4713308 },
    { lat: 48.2286082, lng: 19.4730642 },
    { lat: 48.227321, lng: 19.4760912 },
    { lat: 48.2262272, lng: 19.4783198 },
    { lat: 48.2237849, lng: 19.4819555 },
    { lat: 48.2230679, lng: 19.483803 },
    { lat: 48.223095, lng: 19.483827 },
    { lat: 48.2238706, lng: 19.4845102 },
    { lat: 48.2260013, lng: 19.4868372 },
    { lat: 48.2260028, lng: 19.4871113 },
    { lat: 48.2261042, lng: 19.4872099 },
    { lat: 48.2262165, lng: 19.4870564 },
    { lat: 48.2262405, lng: 19.4870832 },
    { lat: 48.2262605, lng: 19.4872246 },
    { lat: 48.2261766, lng: 19.4873327 },
    { lat: 48.2261866, lng: 19.4875978 },
    { lat: 48.2261116, lng: 19.4877272 },
    { lat: 48.2258942, lng: 19.4878511 },
    { lat: 48.2258819, lng: 19.4880923 },
    { lat: 48.2258179, lng: 19.4881492 },
    { lat: 48.2258101, lng: 19.48839 },
    { lat: 48.2257544, lng: 19.488527 },
    { lat: 48.2256936, lng: 19.4884807 },
    { lat: 48.225616, lng: 19.4886733 },
    { lat: 48.2256917, lng: 19.4888491 },
    { lat: 48.2254329, lng: 19.4889759 },
    { lat: 48.2254599, lng: 19.4891943 },
    { lat: 48.2253513, lng: 19.4892813 },
    { lat: 48.2253306, lng: 19.4895372 },
    { lat: 48.2252153, lng: 19.489703 },
    { lat: 48.2250909, lng: 19.4896432 },
    { lat: 48.2250709, lng: 19.4898422 },
    { lat: 48.2248483, lng: 19.4900195 },
    { lat: 48.2247099, lng: 19.4898329 },
    { lat: 48.2245081, lng: 19.4901438 },
    { lat: 48.2244721, lng: 19.4902423 },
    { lat: 48.224544, lng: 19.4905034 },
    { lat: 48.2244314, lng: 19.4907014 },
    { lat: 48.2244632, lng: 19.491019 },
    { lat: 48.2242264, lng: 19.4914498 },
    { lat: 48.2241986, lng: 19.4918199 },
    { lat: 48.2241287, lng: 19.4918916 },
    { lat: 48.2241784, lng: 19.4921376 },
    { lat: 48.2240803, lng: 19.4923773 },
    { lat: 48.2238893, lng: 19.4926319 },
    { lat: 48.2238837, lng: 19.4927488 },
    { lat: 48.2237867, lng: 19.4927762 },
    { lat: 48.2237352, lng: 19.492876 },
    { lat: 48.2237532, lng: 19.4929985 },
    { lat: 48.2236721, lng: 19.4930232 },
    { lat: 48.2236882, lng: 19.4932262 },
    { lat: 48.2236172, lng: 19.4934661 },
    { lat: 48.223391, lng: 19.4934007 },
    { lat: 48.2232784, lng: 19.4934733 },
    { lat: 48.2232208, lng: 19.4938313 },
    { lat: 48.2231327, lng: 19.4940239 },
    { lat: 48.2229193, lng: 19.4940353 },
    { lat: 48.2230589, lng: 19.49435 },
    { lat: 48.2226857, lng: 19.4946576 },
    { lat: 48.2224099, lng: 19.4945673 },
    { lat: 48.2224242, lng: 19.4949504 },
    { lat: 48.2222823, lng: 19.4950977 },
    { lat: 48.2221659, lng: 19.4950424 },
    { lat: 48.2221594, lng: 19.4952632 },
    { lat: 48.2219187, lng: 19.4952258 },
    { lat: 48.2218142, lng: 19.4954502 },
    { lat: 48.2219363, lng: 19.4957452 },
    { lat: 48.2217592, lng: 19.4957447 },
    { lat: 48.221729, lng: 19.4958662 },
    { lat: 48.2217813, lng: 19.4960459 },
    { lat: 48.2217156, lng: 19.4961878 },
    { lat: 48.2216883, lng: 19.4964225 },
    { lat: 48.2216005, lng: 19.4964224 },
    { lat: 48.2215975, lng: 19.4963136 },
    { lat: 48.2215167, lng: 19.4963188 },
    { lat: 48.2215065, lng: 19.4967058 },
    { lat: 48.2214526, lng: 19.4968075 },
    { lat: 48.2213567, lng: 19.4967055 },
    { lat: 48.2212501, lng: 19.4964492 },
    { lat: 48.2211937, lng: 19.4964501 },
    { lat: 48.2212038, lng: 19.4967134 },
    { lat: 48.2207895, lng: 19.4968683 },
    { lat: 48.2207105, lng: 19.4969617 },
    { lat: 48.2206691, lng: 19.4971375 },
    { lat: 48.2207555, lng: 19.497227 },
    { lat: 48.220899, lng: 19.4972364 },
    { lat: 48.2208953, lng: 19.4973499 },
    { lat: 48.2208014, lng: 19.4974625 },
    { lat: 48.2208243, lng: 19.4977619 },
    { lat: 48.2206859, lng: 19.4980291 },
    { lat: 48.220559, lng: 19.4980625 },
    { lat: 48.2204624, lng: 19.4979266 },
    { lat: 48.2204149, lng: 19.4980924 },
    { lat: 48.2203493, lng: 19.4980628 },
    { lat: 48.2201862, lng: 19.4984102 },
    { lat: 48.220052, lng: 19.4985239 },
    { lat: 48.2198399, lng: 19.4984019 },
    { lat: 48.2197697, lng: 19.4984417 },
    { lat: 48.2194138, lng: 19.4983237 },
    { lat: 48.2190257, lng: 19.4986569 },
    { lat: 48.2189685, lng: 19.4988787 },
    { lat: 48.2188847, lng: 19.4988228 },
    { lat: 48.218781, lng: 19.4989195 },
    { lat: 48.2187542, lng: 19.498993 },
    { lat: 48.2187936, lng: 19.499261 },
    { lat: 48.218651, lng: 19.4992974 },
    { lat: 48.2185721, lng: 19.4994382 },
    { lat: 48.2183758, lng: 19.4995339 },
    { lat: 48.2181879, lng: 19.4993486 },
    { lat: 48.2180906, lng: 19.49951 },
    { lat: 48.2180023, lng: 19.4994033 },
    { lat: 48.2179225, lng: 19.4994304 },
    { lat: 48.2178703, lng: 19.4996167 },
    { lat: 48.2179163, lng: 19.4996875 },
    { lat: 48.2180478, lng: 19.4997007 },
    { lat: 48.2179649, lng: 19.5000253 },
    { lat: 48.2178646, lng: 19.4999749 },
    { lat: 48.2179493, lng: 19.5001506 },
    { lat: 48.2192325, lng: 19.5037222 },
    { lat: 48.2205076, lng: 19.5069054 },
    { lat: 48.2227991, lng: 19.5100337 },
    { lat: 48.2241769, lng: 19.5120609 },
    { lat: 48.22467, lng: 19.513138 },
    { lat: 48.224717, lng: 19.5130836 },
    { lat: 48.2247477, lng: 19.5131398 },
    { lat: 48.2246926, lng: 19.5132134 },
    { lat: 48.2250459, lng: 19.5138795 },
    { lat: 48.225844, lng: 19.5149284 },
    { lat: 48.2278356, lng: 19.5160034 },
    { lat: 48.229604, lng: 19.5208832 },
    { lat: 48.230685, lng: 19.5227127 },
    { lat: 48.2310928, lng: 19.5247737 },
    { lat: 48.2313328, lng: 19.5256971 },
    { lat: 48.2322437, lng: 19.5264585 },
    { lat: 48.2331499, lng: 19.5271159 },
    { lat: 48.2350642, lng: 19.5295646 },
    { lat: 48.2355088, lng: 19.5306227 },
    { lat: 48.2359769, lng: 19.5314801 },
    { lat: 48.2372536, lng: 19.5325272 },
    { lat: 48.2390389, lng: 19.5337263 },
  ],
  Príbelce: [
    { lat: 48.2416733, lng: 19.2392306 },
    { lat: 48.2414826, lng: 19.2391613 },
    { lat: 48.240887, lng: 19.2389659 },
    { lat: 48.2401713, lng: 19.2388304 },
    { lat: 48.2398662, lng: 19.2386987 },
    { lat: 48.2391932, lng: 19.238189 },
    { lat: 48.2386044, lng: 19.2373748 },
    { lat: 48.237684, lng: 19.2353163 },
    { lat: 48.2374959, lng: 19.235135 },
    { lat: 48.2371174, lng: 19.2342635 },
    { lat: 48.2356648, lng: 19.2328426 },
    { lat: 48.2351545, lng: 19.2319294 },
    { lat: 48.2337273, lng: 19.2320644 },
    { lat: 48.2328505, lng: 19.2320025 },
    { lat: 48.2320899, lng: 19.2320513 },
    { lat: 48.2300853, lng: 19.2324826 },
    { lat: 48.2292271, lng: 19.2327955 },
    { lat: 48.2284471, lng: 19.2328192 },
    { lat: 48.2269941, lng: 19.2318105 },
    { lat: 48.2258803, lng: 19.2313942 },
    { lat: 48.2246036, lng: 19.230619 },
    { lat: 48.2223374, lng: 19.2294137 },
    { lat: 48.2214712, lng: 19.2292203 },
    { lat: 48.2197598, lng: 19.2293561 },
    { lat: 48.218019, lng: 19.2293912 },
    { lat: 48.217188, lng: 19.2295262 },
    { lat: 48.2152485, lng: 19.2294463 },
    { lat: 48.2142741, lng: 19.2292771 },
    { lat: 48.213581, lng: 19.229439 },
    { lat: 48.2102341, lng: 19.2294598 },
    { lat: 48.2090532, lng: 19.2296077 },
    { lat: 48.2070149, lng: 19.2296712 },
    { lat: 48.2053181, lng: 19.229871 },
    { lat: 48.2036029, lng: 19.2299284 },
    { lat: 48.2023345, lng: 19.2305233 },
    { lat: 48.2024263, lng: 19.231891 },
    { lat: 48.2029545, lng: 19.2357695 },
    { lat: 48.2012147, lng: 19.2357632 },
    { lat: 48.2003525, lng: 19.2356937 },
    { lat: 48.1992834, lng: 19.2358914 },
    { lat: 48.1990518, lng: 19.2362704 },
    { lat: 48.1983562, lng: 19.2363883 },
    { lat: 48.1978361, lng: 19.2362404 },
    { lat: 48.1970149, lng: 19.2361596 },
    { lat: 48.1962848, lng: 19.235634 },
    { lat: 48.1957694, lng: 19.2357937 },
    { lat: 48.1953733, lng: 19.2358095 },
    { lat: 48.1948897, lng: 19.2360826 },
    { lat: 48.1943714, lng: 19.2360181 },
    { lat: 48.1940864, lng: 19.2358384 },
    { lat: 48.1931033, lng: 19.235997 },
    { lat: 48.1927373, lng: 19.2359332 },
    { lat: 48.1914768, lng: 19.2383004 },
    { lat: 48.1905834, lng: 19.2398113 },
    { lat: 48.1895708, lng: 19.2417622 },
    { lat: 48.1886999, lng: 19.2432931 },
    { lat: 48.1883381, lng: 19.2443539 },
    { lat: 48.1879026, lng: 19.2447855 },
    { lat: 48.1877286, lng: 19.2448212 },
    { lat: 48.1866076, lng: 19.2447632 },
    { lat: 48.1863655, lng: 19.2446297 },
    { lat: 48.1856431, lng: 19.2448885 },
    { lat: 48.1855429, lng: 19.2454423 },
    { lat: 48.1853367, lng: 19.2458386 },
    { lat: 48.1845723, lng: 19.247009 },
    { lat: 48.1839607, lng: 19.2481959 },
    { lat: 48.1832835, lng: 19.2493334 },
    { lat: 48.183134, lng: 19.2501261 },
    { lat: 48.1827907, lng: 19.2503678 },
    { lat: 48.1824223, lng: 19.2507662 },
    { lat: 48.1823059, lng: 19.2510374 },
    { lat: 48.1819797, lng: 19.2512556 },
    { lat: 48.1817672, lng: 19.2515473 },
    { lat: 48.1808381, lng: 19.2520002 },
    { lat: 48.18076, lng: 19.2522044 },
    { lat: 48.1804692, lng: 19.2525716 },
    { lat: 48.1801587, lng: 19.2532321 },
    { lat: 48.1798906, lng: 19.2531646 },
    { lat: 48.1797766, lng: 19.2537612 },
    { lat: 48.179322, lng: 19.2536667 },
    { lat: 48.1789878, lng: 19.2540698 },
    { lat: 48.1784804, lng: 19.2542961 },
    { lat: 48.1784388, lng: 19.2540683 },
    { lat: 48.1779159, lng: 19.2532367 },
    { lat: 48.1769974, lng: 19.252157 },
    { lat: 48.1765192, lng: 19.2529858 },
    { lat: 48.1763202, lng: 19.2532014 },
    { lat: 48.1753025, lng: 19.2519373 },
    { lat: 48.1743446, lng: 19.2506046 },
    { lat: 48.1736194, lng: 19.2493951 },
    { lat: 48.1727698, lng: 19.2483193 },
    { lat: 48.1724201, lng: 19.2480602 },
    { lat: 48.1718587, lng: 19.2478492 },
    { lat: 48.1711646, lng: 19.2484064 },
    { lat: 48.1706527, lng: 19.2489143 },
    { lat: 48.1703129, lng: 19.2489747 },
    { lat: 48.1702516, lng: 19.2489182 },
    { lat: 48.1695123, lng: 19.2492433 },
    { lat: 48.1688199, lng: 19.2503931 },
    { lat: 48.1683803, lng: 19.2497928 },
    { lat: 48.1674698, lng: 19.2483689 },
    { lat: 48.1672847, lng: 19.2482005 },
    { lat: 48.1669686, lng: 19.2476828 },
    { lat: 48.1667738, lng: 19.2471826 },
    { lat: 48.1662025, lng: 19.2462171 },
    { lat: 48.1653694, lng: 19.2453385 },
    { lat: 48.1656141, lng: 19.2448362 },
    { lat: 48.1658047, lng: 19.2441215 },
    { lat: 48.1662334, lng: 19.2429998 },
    { lat: 48.1663916, lng: 19.2427583 },
    { lat: 48.1658605, lng: 19.2418295 },
    { lat: 48.1657025, lng: 19.2422193 },
    { lat: 48.1645839, lng: 19.2437772 },
    { lat: 48.1640413, lng: 19.2451761 },
    { lat: 48.1630851, lng: 19.2468278 },
    { lat: 48.1610562, lng: 19.2496684 },
    { lat: 48.1604285, lng: 19.2503152 },
    { lat: 48.1605091, lng: 19.2510641 },
    { lat: 48.160668, lng: 19.251875 },
    { lat: 48.1593026, lng: 19.2516371 },
    { lat: 48.1594168, lng: 19.2524953 },
    { lat: 48.1594865, lng: 19.2536026 },
    { lat: 48.1594956, lng: 19.2551243 },
    { lat: 48.1595912, lng: 19.2557218 },
    { lat: 48.1603606, lng: 19.2568912 },
    { lat: 48.1605881, lng: 19.2573032 },
    { lat: 48.1607969, lng: 19.2578396 },
    { lat: 48.1614787, lng: 19.2602629 },
    { lat: 48.1615232, lng: 19.2605101 },
    { lat: 48.1616476, lng: 19.261202 },
    { lat: 48.161649, lng: 19.2617632 },
    { lat: 48.1598133, lng: 19.2633256 },
    { lat: 48.1583279, lng: 19.2651561 },
    { lat: 48.1581522, lng: 19.26553 },
    { lat: 48.157772, lng: 19.2660113 },
    { lat: 48.1574429, lng: 19.2663825 },
    { lat: 48.156886, lng: 19.266865 },
    { lat: 48.1556389, lng: 19.2675059 },
    { lat: 48.1552533, lng: 19.2675478 },
    { lat: 48.1550503, lng: 19.2676363 },
    { lat: 48.1540957, lng: 19.2682213 },
    { lat: 48.1530776, lng: 19.26854 },
    { lat: 48.1536585, lng: 19.2708617 },
    { lat: 48.1543103, lng: 19.2718862 },
    { lat: 48.1541718, lng: 19.2728162 },
    { lat: 48.1539798, lng: 19.2732501 },
    { lat: 48.1537624, lng: 19.2735283 },
    { lat: 48.1556936, lng: 19.2781465 },
    { lat: 48.1570356, lng: 19.2793892 },
    { lat: 48.1571039, lng: 19.279589 },
    { lat: 48.1571497, lng: 19.2803455 },
    { lat: 48.1572092, lng: 19.2805749 },
    { lat: 48.1574373, lng: 19.2809999 },
    { lat: 48.1575502, lng: 19.2815344 },
    { lat: 48.1574399, lng: 19.2829546 },
    { lat: 48.1571959, lng: 19.2837577 },
    { lat: 48.1574717, lng: 19.2850485 },
    { lat: 48.1574288, lng: 19.2853723 },
    { lat: 48.1573001, lng: 19.2853428 },
    { lat: 48.1569604, lng: 19.2872146 },
    { lat: 48.1569515, lng: 19.2874514 },
    { lat: 48.1574789, lng: 19.287869 },
    { lat: 48.1572641, lng: 19.2892681 },
    { lat: 48.1571597, lng: 19.2918592 },
    { lat: 48.1567572, lng: 19.2924627 },
    { lat: 48.155995, lng: 19.2931973 },
    { lat: 48.1557475, lng: 19.2938129 },
    { lat: 48.15553, lng: 19.2946759 },
    { lat: 48.155342, lng: 19.2952177 },
    { lat: 48.1550791, lng: 19.2957675 },
    { lat: 48.1546487, lng: 19.2970281 },
    { lat: 48.1545261, lng: 19.2974762 },
    { lat: 48.1543484, lng: 19.2985276 },
    { lat: 48.1543045, lng: 19.2990647 },
    { lat: 48.1548761, lng: 19.299089 },
    { lat: 48.155037, lng: 19.2988764 },
    { lat: 48.1577997, lng: 19.2953145 },
    { lat: 48.1583967, lng: 19.29468 },
    { lat: 48.158546, lng: 19.2949474 },
    { lat: 48.158733, lng: 19.2951319 },
    { lat: 48.159046, lng: 19.2958919 },
    { lat: 48.1591665, lng: 19.2966107 },
    { lat: 48.160019, lng: 19.2972927 },
    { lat: 48.1605193, lng: 19.2977772 },
    { lat: 48.1619302, lng: 19.297978 },
    { lat: 48.162323, lng: 19.2977346 },
    { lat: 48.1628775, lng: 19.2977815 },
    { lat: 48.1630837, lng: 19.2976148 },
    { lat: 48.163823, lng: 19.2977269 },
    { lat: 48.1642416, lng: 19.2980159 },
    { lat: 48.1650419, lng: 19.2972288 },
    { lat: 48.1662701, lng: 19.2958675 },
    { lat: 48.1669008, lng: 19.2955083 },
    { lat: 48.1671075, lng: 19.2956338 },
    { lat: 48.1673306, lng: 19.2955828 },
    { lat: 48.1676476, lng: 19.2954274 },
    { lat: 48.1676148, lng: 19.2952152 },
    { lat: 48.1678179, lng: 19.2951258 },
    { lat: 48.1682151, lng: 19.2950895 },
    { lat: 48.1687315, lng: 19.2950908 },
    { lat: 48.1694837, lng: 19.2953324 },
    { lat: 48.1695889, lng: 19.2954573 },
    { lat: 48.1702745, lng: 19.2950215 },
    { lat: 48.1708568, lng: 19.294082 },
    { lat: 48.1712915, lng: 19.2936309 },
    { lat: 48.1713573, lng: 19.2936863 },
    { lat: 48.1719848, lng: 19.2931451 },
    { lat: 48.172169, lng: 19.2928515 },
    { lat: 48.1723814, lng: 19.2926286 },
    { lat: 48.17303, lng: 19.2919662 },
    { lat: 48.1731112, lng: 19.2919641 },
    { lat: 48.1731572, lng: 19.292333 },
    { lat: 48.1731286, lng: 19.2927426 },
    { lat: 48.1728702, lng: 19.2935754 },
    { lat: 48.1728591, lng: 19.2939502 },
    { lat: 48.1731591, lng: 19.294 },
    { lat: 48.1731218, lng: 19.2943706 },
    { lat: 48.1731616, lng: 19.2946311 },
    { lat: 48.1733895, lng: 19.2951728 },
    { lat: 48.1734703, lng: 19.2953279 },
    { lat: 48.1738513, lng: 19.2957235 },
    { lat: 48.1738995, lng: 19.2959976 },
    { lat: 48.1743763, lng: 19.2965325 },
    { lat: 48.174922, lng: 19.2968156 },
    { lat: 48.1770367, lng: 19.2987042 },
    { lat: 48.1771004, lng: 19.2987174 },
    { lat: 48.1774668, lng: 19.2976197 },
    { lat: 48.1805202, lng: 19.3009237 },
    { lat: 48.1811285, lng: 19.2997556 },
    { lat: 48.1812554, lng: 19.2998831 },
    { lat: 48.1815576, lng: 19.300305 },
    { lat: 48.1817567, lng: 19.2998554 },
    { lat: 48.1818838, lng: 19.29871 },
    { lat: 48.1818325, lng: 19.2973226 },
    { lat: 48.1818835, lng: 19.2966785 },
    { lat: 48.1820331, lng: 19.2962762 },
    { lat: 48.1829428, lng: 19.2943923 },
    { lat: 48.1834348, lng: 19.2934009 },
    { lat: 48.1835403, lng: 19.2933467 },
    { lat: 48.1836271, lng: 19.2931586 },
    { lat: 48.1837076, lng: 19.2926801 },
    { lat: 48.1838557, lng: 19.2923193 },
    { lat: 48.1841088, lng: 19.2918458 },
    { lat: 48.1844777, lng: 19.2914095 },
    { lat: 48.1845832, lng: 19.2910039 },
    { lat: 48.1846828, lng: 19.2910376 },
    { lat: 48.1848764, lng: 19.2909816 },
    { lat: 48.1850761, lng: 19.2907791 },
    { lat: 48.1853526, lng: 19.2903433 },
    { lat: 48.1855845, lng: 19.2904976 },
    { lat: 48.1862686, lng: 19.291381 },
    { lat: 48.1868331, lng: 19.2928874 },
    { lat: 48.1870079, lng: 19.2931454 },
    { lat: 48.1876231, lng: 19.2939526 },
    { lat: 48.189596, lng: 19.2959403 },
    { lat: 48.1898696, lng: 19.2961423 },
    { lat: 48.1901683, lng: 19.2966526 },
    { lat: 48.1904559, lng: 19.2963766 },
    { lat: 48.1905712, lng: 19.2955255 },
    { lat: 48.1909391, lng: 19.2951363 },
    { lat: 48.1911814, lng: 19.2947409 },
    { lat: 48.1910926, lng: 19.2944446 },
    { lat: 48.1913164, lng: 19.2940862 },
    { lat: 48.1912472, lng: 19.2937259 },
    { lat: 48.1914843, lng: 19.293548 },
    { lat: 48.1916621, lng: 19.2931723 },
    { lat: 48.191977, lng: 19.2929446 },
    { lat: 48.1920909, lng: 19.2926231 },
    { lat: 48.1921277, lng: 19.292287 },
    { lat: 48.1920686, lng: 19.2919971 },
    { lat: 48.1921018, lng: 19.2918931 },
    { lat: 48.1925594, lng: 19.2917771 },
    { lat: 48.1927023, lng: 19.2915394 },
    { lat: 48.1930673, lng: 19.2902535 },
    { lat: 48.19334, lng: 19.2899738 },
    { lat: 48.1933701, lng: 19.2896201 },
    { lat: 48.1939497, lng: 19.2893668 },
    { lat: 48.1942213, lng: 19.2887365 },
    { lat: 48.1945789, lng: 19.288514 },
    { lat: 48.1948805, lng: 19.2879905 },
    { lat: 48.1948425, lng: 19.2876258 },
    { lat: 48.1949185, lng: 19.2873583 },
    { lat: 48.1949163, lng: 19.2868017 },
    { lat: 48.1949556, lng: 19.2867975 },
    { lat: 48.1950405, lng: 19.2863862 },
    { lat: 48.1961112, lng: 19.2856178 },
    { lat: 48.1962366, lng: 19.2858791 },
    { lat: 48.1971909, lng: 19.2837525 },
    { lat: 48.1972275, lng: 19.2831833 },
    { lat: 48.1973807, lng: 19.2821399 },
    { lat: 48.1979392, lng: 19.2806629 },
    { lat: 48.1984953, lng: 19.2788362 },
    { lat: 48.1984271, lng: 19.2786638 },
    { lat: 48.1995579, lng: 19.2776484 },
    { lat: 48.1996759, lng: 19.2774321 },
    { lat: 48.2003069, lng: 19.2768024 },
    { lat: 48.2004162, lng: 19.2770788 },
    { lat: 48.2006454, lng: 19.2772009 },
    { lat: 48.2009466, lng: 19.2771395 },
    { lat: 48.2011187, lng: 19.276806 },
    { lat: 48.2010929, lng: 19.2764597 },
    { lat: 48.2011738, lng: 19.2763612 },
    { lat: 48.2016006, lng: 19.2767935 },
    { lat: 48.2018206, lng: 19.2767523 },
    { lat: 48.2019289, lng: 19.2763603 },
    { lat: 48.2018581, lng: 19.2760394 },
    { lat: 48.2019723, lng: 19.2759984 },
    { lat: 48.2023186, lng: 19.2761639 },
    { lat: 48.2029587, lng: 19.2758148 },
    { lat: 48.2031257, lng: 19.2754701 },
    { lat: 48.2031737, lng: 19.2755776 },
    { lat: 48.2050582, lng: 19.2729787 },
    { lat: 48.2074041, lng: 19.2702233 },
    { lat: 48.2081755, lng: 19.2697665 },
    { lat: 48.2126196, lng: 19.2658901 },
    { lat: 48.2137498, lng: 19.265393 },
    { lat: 48.215182, lng: 19.2641095 },
    { lat: 48.2153486, lng: 19.2640585 },
    { lat: 48.215054, lng: 19.262966 },
    { lat: 48.2147276, lng: 19.262535 },
    { lat: 48.2141367, lng: 19.261465 },
    { lat: 48.214386, lng: 19.2613551 },
    { lat: 48.2148302, lng: 19.2617703 },
    { lat: 48.2152982, lng: 19.2616362 },
    { lat: 48.2156628, lng: 19.2613904 },
    { lat: 48.2161415, lng: 19.2607593 },
    { lat: 48.2163475, lng: 19.2602897 },
    { lat: 48.2164857, lng: 19.2601435 },
    { lat: 48.216784, lng: 19.2596239 },
    { lat: 48.216985, lng: 19.2591255 },
    { lat: 48.2172639, lng: 19.2588081 },
    { lat: 48.2175798, lng: 19.258563 },
    { lat: 48.2183474, lng: 19.2582806 },
    { lat: 48.2188808, lng: 19.258427 },
    { lat: 48.2192924, lng: 19.2584305 },
    { lat: 48.2195782, lng: 19.2582795 },
    { lat: 48.2196781, lng: 19.2580841 },
    { lat: 48.2196591, lng: 19.2574874 },
    { lat: 48.219737, lng: 19.2568897 },
    { lat: 48.2198414, lng: 19.2566715 },
    { lat: 48.2201841, lng: 19.2561675 },
    { lat: 48.2206856, lng: 19.2558621 },
    { lat: 48.2210291, lng: 19.2555768 },
    { lat: 48.2212172, lng: 19.2552505 },
    { lat: 48.2218122, lng: 19.2549936 },
    { lat: 48.2222274, lng: 19.2547001 },
    { lat: 48.2228988, lng: 19.2545091 },
    { lat: 48.2234391, lng: 19.2546767 },
    { lat: 48.2242777, lng: 19.2554367 },
    { lat: 48.2246716, lng: 19.2552848 },
    { lat: 48.2256058, lng: 19.2553959 },
    { lat: 48.2261716, lng: 19.2552818 },
    { lat: 48.2265473, lng: 19.2550997 },
    { lat: 48.2267356, lng: 19.2548257 },
    { lat: 48.2272916, lng: 19.2544174 },
    { lat: 48.2279775, lng: 19.2541258 },
    { lat: 48.2286915, lng: 19.2536752 },
    { lat: 48.2296787, lng: 19.2534623 },
    { lat: 48.2299592, lng: 19.2534557 },
    { lat: 48.2304709, lng: 19.2530657 },
    { lat: 48.2306889, lng: 19.2530009 },
    { lat: 48.2306725, lng: 19.2529645 },
    { lat: 48.2308763, lng: 19.2529843 },
    { lat: 48.2313005, lng: 19.2528775 },
    { lat: 48.2315864, lng: 19.252661 },
    { lat: 48.2317151, lng: 19.2524072 },
    { lat: 48.233024, lng: 19.2517452 },
    { lat: 48.2333877, lng: 19.2517829 },
    { lat: 48.2346408, lng: 19.252423 },
    { lat: 48.2350539, lng: 19.252572 },
    { lat: 48.2352444, lng: 19.2528945 },
    { lat: 48.2354933, lng: 19.2530777 },
    { lat: 48.2360501, lng: 19.2533308 },
    { lat: 48.2360615, lng: 19.2533076 },
    { lat: 48.2371762, lng: 19.253927 },
    { lat: 48.2374225, lng: 19.2541734 },
    { lat: 48.2377223, lng: 19.2547032 },
    { lat: 48.2380133, lng: 19.2549147 },
    { lat: 48.2386175, lng: 19.2548235 },
    { lat: 48.2390927, lng: 19.254162 },
    { lat: 48.2395184, lng: 19.25381 },
    { lat: 48.2398683, lng: 19.2537046 },
    { lat: 48.2398865, lng: 19.2537779 },
    { lat: 48.2402509, lng: 19.2536217 },
    { lat: 48.2410461, lng: 19.253218 },
    { lat: 48.2412569, lng: 19.2530553 },
    { lat: 48.2413681, lng: 19.2527914 },
    { lat: 48.2419087, lng: 19.2519041 },
    { lat: 48.2423052, lng: 19.2515535 },
    { lat: 48.2428298, lng: 19.2507726 },
    { lat: 48.2432977, lng: 19.2496694 },
    { lat: 48.2437622, lng: 19.2480548 },
    { lat: 48.2444273, lng: 19.2468967 },
    { lat: 48.2451093, lng: 19.2450542 },
    { lat: 48.2456121, lng: 19.242536 },
    { lat: 48.2459181, lng: 19.2412329 },
    { lat: 48.2476935, lng: 19.2395711 },
    { lat: 48.2458119, lng: 19.2400506 },
    { lat: 48.2449771, lng: 19.2400841 },
    { lat: 48.2424156, lng: 19.2395421 },
    { lat: 48.2416733, lng: 19.2392306 },
  ],
  MalýKrtíš: [
    { lat: 48.1923307, lng: 19.3337971 },
    { lat: 48.1916507, lng: 19.3333705 },
    { lat: 48.1914811, lng: 19.3331248 },
    { lat: 48.1903424, lng: 19.3322318 },
    { lat: 48.1902229, lng: 19.3322273 },
    { lat: 48.1887994, lng: 19.3327479 },
    { lat: 48.1868514, lng: 19.333678 },
    { lat: 48.1833714, lng: 19.3360111 },
    { lat: 48.1792244, lng: 19.3386554 },
    { lat: 48.1779016, lng: 19.3395995 },
    { lat: 48.1789501, lng: 19.3408748 },
    { lat: 48.1801685, lng: 19.3426065 },
    { lat: 48.1810164, lng: 19.3441859 },
    { lat: 48.1810698, lng: 19.3446191 },
    { lat: 48.1810549, lng: 19.3451487 },
    { lat: 48.1809515, lng: 19.3459823 },
    { lat: 48.181079, lng: 19.3475074 },
    { lat: 48.1807808, lng: 19.3483647 },
    { lat: 48.1807618, lng: 19.3490017 },
    { lat: 48.1805816, lng: 19.3502041 },
    { lat: 48.1805709, lng: 19.3513891 },
    { lat: 48.1806781, lng: 19.3529205 },
    { lat: 48.1809282, lng: 19.3545416 },
    { lat: 48.1815377, lng: 19.3543706 },
    { lat: 48.1817762, lng: 19.3569486 },
    { lat: 48.1817536, lng: 19.357182 },
    { lat: 48.1820299, lng: 19.3572803 },
    { lat: 48.1828565, lng: 19.3573118 },
    { lat: 48.1827188, lng: 19.3582504 },
    { lat: 48.182717, lng: 19.3587041 },
    { lat: 48.1828349, lng: 19.3590952 },
    { lat: 48.1827676, lng: 19.3592429 },
    { lat: 48.1826776, lng: 19.3591791 },
    { lat: 48.1824736, lng: 19.3594372 },
    { lat: 48.1819402, lng: 19.3598984 },
    { lat: 48.1816956, lng: 19.3601827 },
    { lat: 48.1816214, lng: 19.360565 },
    { lat: 48.1816859, lng: 19.360735 },
    { lat: 48.1818104, lng: 19.3607948 },
    { lat: 48.1824055, lng: 19.3609345 },
    { lat: 48.1828492, lng: 19.3608796 },
    { lat: 48.1837235, lng: 19.3612928 },
    { lat: 48.1841285, lng: 19.3616069 },
    { lat: 48.1854635, lng: 19.363129 },
    { lat: 48.1855909, lng: 19.3634819 },
    { lat: 48.1857142, lng: 19.3640533 },
    { lat: 48.1860997, lng: 19.3663668 },
    { lat: 48.1862196, lng: 19.3667979 },
    { lat: 48.1864081, lng: 19.3672973 },
    { lat: 48.1869228, lng: 19.3683029 },
    { lat: 48.1871076, lng: 19.3699305 },
    { lat: 48.1869352, lng: 19.3702163 },
    { lat: 48.186737, lng: 19.3713278 },
    { lat: 48.1866749, lng: 19.3722237 },
    { lat: 48.1868349, lng: 19.3738967 },
    { lat: 48.1867647, lng: 19.374524 },
    { lat: 48.1866307, lng: 19.37519 },
    { lat: 48.1861223, lng: 19.376296 },
    { lat: 48.1851318, lng: 19.3778597 },
    { lat: 48.1847525, lng: 19.3787722 },
    { lat: 48.1852505, lng: 19.3793085 },
    { lat: 48.1848914, lng: 19.3800288 },
    { lat: 48.1841785, lng: 19.3817153 },
    { lat: 48.1839515, lng: 19.382746 },
    { lat: 48.1840085, lng: 19.3832326 },
    { lat: 48.1842671, lng: 19.3837868 },
    { lat: 48.1843638, lng: 19.3841614 },
    { lat: 48.1843369, lng: 19.3844228 },
    { lat: 48.1842162, lng: 19.3846356 },
    { lat: 48.1838229, lng: 19.3849667 },
    { lat: 48.1837713, lng: 19.3860165 },
    { lat: 48.1832239, lng: 19.386517 },
    { lat: 48.1829988, lng: 19.3869729 },
    { lat: 48.1829749, lng: 19.3874424 },
    { lat: 48.1824596, lng: 19.3889021 },
    { lat: 48.1824175, lng: 19.3893671 },
    { lat: 48.1821605, lng: 19.3900549 },
    { lat: 48.1830316, lng: 19.3912127 },
    { lat: 48.1825241, lng: 19.3926475 },
    { lat: 48.1824293, lng: 19.3930486 },
    { lat: 48.1824765, lng: 19.3940645 },
    { lat: 48.1822304, lng: 19.3955994 },
    { lat: 48.1823088, lng: 19.3971969 },
    { lat: 48.1822957, lng: 19.3978466 },
    { lat: 48.1824641, lng: 19.3990432 },
    { lat: 48.1824625, lng: 19.4008127 },
    { lat: 48.1819347, lng: 19.4017792 },
    { lat: 48.1824907, lng: 19.4013063 },
    { lat: 48.1830529, lng: 19.4012804 },
    { lat: 48.1831866, lng: 19.401274 },
    { lat: 48.1840801, lng: 19.4004862 },
    { lat: 48.1846557, lng: 19.4002618 },
    { lat: 48.1852144, lng: 19.3994269 },
    { lat: 48.1856593, lng: 19.3984006 },
    { lat: 48.1859025, lng: 19.3979488 },
    { lat: 48.1859776, lng: 19.3970829 },
    { lat: 48.1861021, lng: 19.3966226 },
    { lat: 48.1862205, lng: 19.3952937 },
    { lat: 48.1865296, lng: 19.3947049 },
    { lat: 48.1866421, lng: 19.3942995 },
    { lat: 48.1874817, lng: 19.3903211 },
    { lat: 48.1884289, lng: 19.3862372 },
    { lat: 48.1885189, lng: 19.385499 },
    { lat: 48.1885078, lng: 19.3841803 },
    { lat: 48.1884685, lng: 19.3837331 },
    { lat: 48.1883371, lng: 19.3830816 },
    { lat: 48.1882665, lng: 19.3817632 },
    { lat: 48.188323, lng: 19.3806121 },
    { lat: 48.1882726, lng: 19.3803816 },
    { lat: 48.1883103, lng: 19.3803207 },
    { lat: 48.1883154, lng: 19.3800259 },
    { lat: 48.1894457, lng: 19.3810847 },
    { lat: 48.1903568, lng: 19.3821086 },
    { lat: 48.1906344, lng: 19.3823521 },
    { lat: 48.1917119, lng: 19.3807921 },
    { lat: 48.1917991, lng: 19.380591 },
    { lat: 48.1923314, lng: 19.3808829 },
    { lat: 48.1931743, lng: 19.380758 },
    { lat: 48.1940635, lng: 19.3802622 },
    { lat: 48.1942853, lng: 19.3800655 },
    { lat: 48.1944552, lng: 19.3800368 },
    { lat: 48.1952133, lng: 19.3796503 },
    { lat: 48.1959996, lng: 19.3794774 },
    { lat: 48.1961029, lng: 19.3793957 },
    { lat: 48.1966697, lng: 19.378593 },
    { lat: 48.19716, lng: 19.3778098 },
    { lat: 48.1978882, lng: 19.3776028 },
    { lat: 48.1999109, lng: 19.3786085 },
    { lat: 48.2003791, lng: 19.3788529 },
    { lat: 48.2002301, lng: 19.3782604 },
    { lat: 48.2003902, lng: 19.3776198 },
    { lat: 48.2001758, lng: 19.3773335 },
    { lat: 48.2001561, lng: 19.3767788 },
    { lat: 48.1999481, lng: 19.3762206 },
    { lat: 48.199633, lng: 19.3759236 },
    { lat: 48.1997669, lng: 19.3753038 },
    { lat: 48.1990819, lng: 19.3737981 },
    { lat: 48.1992324, lng: 19.3732451 },
    { lat: 48.2005952, lng: 19.3709854 },
    { lat: 48.2011587, lng: 19.3699458 },
    { lat: 48.2018677, lng: 19.3684575 },
    { lat: 48.2021869, lng: 19.3649292 },
    { lat: 48.1996479, lng: 19.363709 },
    { lat: 48.1974744, lng: 19.3638197 },
    { lat: 48.1972915, lng: 19.3639308 },
    { lat: 48.1967188, lng: 19.3648455 },
    { lat: 48.1966092, lng: 19.3648407 },
    { lat: 48.1963713, lng: 19.3643889 },
    { lat: 48.1963466, lng: 19.3642541 },
    { lat: 48.1965026, lng: 19.3636739 },
    { lat: 48.1965298, lng: 19.36318 },
    { lat: 48.1964655, lng: 19.362729 },
    { lat: 48.1963481, lng: 19.3624223 },
    { lat: 48.1963669, lng: 19.3613799 },
    { lat: 48.1961126, lng: 19.3606801 },
    { lat: 48.1961298, lng: 19.3596976 },
    { lat: 48.1960966, lng: 19.3593094 },
    { lat: 48.1961316, lng: 19.3588616 },
    { lat: 48.1960204, lng: 19.3585414 },
    { lat: 48.1956949, lng: 19.35824 },
    { lat: 48.1956548, lng: 19.3581083 },
    { lat: 48.1956118, lng: 19.357235 },
    { lat: 48.1955363, lng: 19.356744 },
    { lat: 48.1953872, lng: 19.3563579 },
    { lat: 48.1952881, lng: 19.3561979 },
    { lat: 48.1949167, lng: 19.3559046 },
    { lat: 48.1934696, lng: 19.3551748 },
    { lat: 48.1925624, lng: 19.3544002 },
    { lat: 48.1923359, lng: 19.3540254 },
    { lat: 48.1922638, lng: 19.3536509 },
    { lat: 48.1929879, lng: 19.3535648 },
    { lat: 48.1929819, lng: 19.3533711 },
    { lat: 48.1928677, lng: 19.353034 },
    { lat: 48.1926622, lng: 19.3527229 },
    { lat: 48.1917387, lng: 19.3518908 },
    { lat: 48.1914384, lng: 19.351479 },
    { lat: 48.1914175, lng: 19.3512298 },
    { lat: 48.1910885, lng: 19.3508571 },
    { lat: 48.1912492, lng: 19.3504732 },
    { lat: 48.1914953, lng: 19.3505527 },
    { lat: 48.1917199, lng: 19.3504637 },
    { lat: 48.191884, lng: 19.3502405 },
    { lat: 48.1918449, lng: 19.349943 },
    { lat: 48.1917554, lng: 19.3499088 },
    { lat: 48.1912951, lng: 19.3501355 },
    { lat: 48.1909765, lng: 19.3480772 },
    { lat: 48.1910925, lng: 19.3477938 },
    { lat: 48.1908547, lng: 19.3457963 },
    { lat: 48.1902457, lng: 19.3424577 },
    { lat: 48.1902462, lng: 19.3418582 },
    { lat: 48.1904227, lng: 19.3411186 },
    { lat: 48.1905833, lng: 19.3387884 },
    { lat: 48.1907933, lng: 19.3376193 },
    { lat: 48.1916258, lng: 19.3361094 },
    { lat: 48.1918628, lng: 19.3355081 },
    { lat: 48.1922936, lng: 19.3338474 },
    { lat: 48.1923307, lng: 19.3337971 },
  ],
  Koláre: [
    { lat: 48.0818129, lng: 19.2609834 },
    { lat: 48.0818102, lng: 19.2606284 },
    { lat: 48.082024, lng: 19.2601842 },
    { lat: 48.08228, lng: 19.2591662 },
    { lat: 48.0822394, lng: 19.2587751 },
    { lat: 48.0823319, lng: 19.2577833 },
    { lat: 48.0822033, lng: 19.2575741 },
    { lat: 48.082407, lng: 19.2568793 },
    { lat: 48.0824622, lng: 19.255998 },
    { lat: 48.0819163, lng: 19.2556503 },
    { lat: 48.0814637, lng: 19.2551754 },
    { lat: 48.0809964, lng: 19.2550007 },
    { lat: 48.0818586, lng: 19.2517952 },
    { lat: 48.0815767, lng: 19.2514681 },
    { lat: 48.0815959, lng: 19.2508963 },
    { lat: 48.0820419, lng: 19.2505876 },
    { lat: 48.0820387, lng: 19.2513035 },
    { lat: 48.0822434, lng: 19.2515971 },
    { lat: 48.0826575, lng: 19.2518576 },
    { lat: 48.082688, lng: 19.2514425 },
    { lat: 48.0827969, lng: 19.2511339 },
    { lat: 48.0827993, lng: 19.250793 },
    { lat: 48.0829524, lng: 19.2502141 },
    { lat: 48.0830759, lng: 19.2500392 },
    { lat: 48.0831897, lng: 19.249878 },
    { lat: 48.0832728, lng: 19.2496412 },
    { lat: 48.083379, lng: 19.2495531 },
    { lat: 48.0834307, lng: 19.2494611 },
    { lat: 48.0833631, lng: 19.2492521 },
    { lat: 48.0826825, lng: 19.2480396 },
    { lat: 48.082215, lng: 19.2480186 },
    { lat: 48.0821472, lng: 19.2449552 },
    { lat: 48.0798572, lng: 19.2452184 },
    { lat: 48.0783699, lng: 19.2453035 },
    { lat: 48.0783557, lng: 19.2437255 },
    { lat: 48.0781629, lng: 19.2408956 },
    { lat: 48.0781046, lng: 19.240785 },
    { lat: 48.0778716, lng: 19.2396662 },
    { lat: 48.0776392, lng: 19.2379179 },
    { lat: 48.0738505, lng: 19.2355725 },
    { lat: 48.0738742, lng: 19.2350878 },
    { lat: 48.071601, lng: 19.2349403 },
    { lat: 48.0717077, lng: 19.2341029 },
    { lat: 48.0719467, lng: 19.2328879 },
    { lat: 48.0721691, lng: 19.2302318 },
    { lat: 48.0723602, lng: 19.2285918 },
    { lat: 48.0730776, lng: 19.2249538 },
    { lat: 48.0714668, lng: 19.2244058 },
    { lat: 48.0718272, lng: 19.2233675 },
    { lat: 48.0705218, lng: 19.2227037 },
    { lat: 48.0705477, lng: 19.2230466 },
    { lat: 48.0706961, lng: 19.223749 },
    { lat: 48.0702594, lng: 19.223899 },
    { lat: 48.0699854, lng: 19.2257102 },
    { lat: 48.0693791, lng: 19.2257364 },
    { lat: 48.0688256, lng: 19.2259661 },
    { lat: 48.0678794, lng: 19.2260003 },
    { lat: 48.0674408, lng: 19.22568 },
    { lat: 48.0669743, lng: 19.2252013 },
    { lat: 48.0657419, lng: 19.2250127 },
    { lat: 48.0654804, lng: 19.2247129 },
    { lat: 48.065165, lng: 19.2245786 },
    { lat: 48.0646372, lng: 19.2241391 },
    { lat: 48.0637365, lng: 19.223817 },
    { lat: 48.0635087, lng: 19.2237926 },
    { lat: 48.0632427, lng: 19.2235655 },
    { lat: 48.0628241, lng: 19.2230313 },
    { lat: 48.0622645, lng: 19.2228839 },
    { lat: 48.062061, lng: 19.222651 },
    { lat: 48.061952, lng: 19.222895 },
    { lat: 48.061591, lng: 19.223464 },
    { lat: 48.061386, lng: 19.223701 },
    { lat: 48.060998, lng: 19.224014 },
    { lat: 48.060377, lng: 19.224529 },
    { lat: 48.060117, lng: 19.224674 },
    { lat: 48.060092, lng: 19.224688 },
    { lat: 48.059947, lng: 19.22479 },
    { lat: 48.05973, lng: 19.224944 },
    { lat: 48.058639, lng: 19.225761 },
    { lat: 48.05763, lng: 19.226673 },
    { lat: 48.057145, lng: 19.22732 },
    { lat: 48.056557, lng: 19.228435 },
    { lat: 48.056206, lng: 19.229518 },
    { lat: 48.056015, lng: 19.230518 },
    { lat: 48.055818, lng: 19.232774 },
    { lat: 48.05564, lng: 19.234107 },
    { lat: 48.055404, lng: 19.235137 },
    { lat: 48.055083, lng: 19.236118 },
    { lat: 48.054084, lng: 19.238261 },
    { lat: 48.053843, lng: 19.23888 },
    { lat: 48.053721, lng: 19.239526 },
    { lat: 48.053646, lng: 19.240288 },
    { lat: 48.053653, lng: 19.241368 },
    { lat: 48.0536858, lng: 19.2416242 },
    { lat: 48.053736, lng: 19.241894 },
    { lat: 48.053811, lng: 19.242157 },
    { lat: 48.053829, lng: 19.242244 },
    { lat: 48.053986, lng: 19.242726 },
    { lat: 48.054139, lng: 19.243058 },
    { lat: 48.054341, lng: 19.243526 },
    { lat: 48.054459, lng: 19.243756 },
    { lat: 48.054597, lng: 19.243958 },
    { lat: 48.054621, lng: 19.243995 },
    { lat: 48.054759, lng: 19.244131 },
    { lat: 48.055057, lng: 19.244397 },
    { lat: 48.055634, lng: 19.244904 },
    { lat: 48.055875, lng: 19.245146 },
    { lat: 48.056226, lng: 19.245509 },
    { lat: 48.056443, lng: 19.245809 },
    { lat: 48.056598, lng: 19.246055 },
    { lat: 48.056645, lng: 19.246129 },
    { lat: 48.0566777, lng: 19.2461808 },
    { lat: 48.056698, lng: 19.246213 },
    { lat: 48.056753, lng: 19.246302 },
    { lat: 48.05708, lng: 19.246915 },
    { lat: 48.057298, lng: 19.247317 },
    { lat: 48.057308, lng: 19.247331 },
    { lat: 48.057459, lng: 19.247543 },
    { lat: 48.057732, lng: 19.247909 },
    { lat: 48.058166, lng: 19.248273 },
    { lat: 48.058259, lng: 19.248335 },
    { lat: 48.058381, lng: 19.248416 },
    { lat: 48.05865, lng: 19.248582 },
    { lat: 48.058935, lng: 19.248673 },
    { lat: 48.059321, lng: 19.248768 },
    { lat: 48.059487, lng: 19.248734 },
    { lat: 48.059609, lng: 19.248694 },
    { lat: 48.05972, lng: 19.248664 },
    { lat: 48.060007, lng: 19.248637 },
    { lat: 48.060206, lng: 19.248652 },
    { lat: 48.060448, lng: 19.248665 },
    { lat: 48.060675, lng: 19.248651 },
    { lat: 48.060881, lng: 19.248637 },
    { lat: 48.061211, lng: 19.248558 },
    { lat: 48.061639, lng: 19.248521 },
    { lat: 48.061943, lng: 19.248511 },
    { lat: 48.062215, lng: 19.248562 },
    { lat: 48.062475, lng: 19.248605 },
    { lat: 48.062838, lng: 19.248688 },
    { lat: 48.063236, lng: 19.248834 },
    { lat: 48.063622, lng: 19.248999 },
    { lat: 48.064023, lng: 19.249241 },
    { lat: 48.064344, lng: 19.249467 },
    { lat: 48.064578, lng: 19.249655 },
    { lat: 48.064848, lng: 19.249924 },
    { lat: 48.065028, lng: 19.250107 },
    { lat: 48.06518, lng: 19.250283 },
    { lat: 48.065498, lng: 19.250681 },
    { lat: 48.065821, lng: 19.251061 },
    { lat: 48.066094, lng: 19.251344 },
    { lat: 48.066357, lng: 19.251604 },
    { lat: 48.066684, lng: 19.251892 },
    { lat: 48.06698, lng: 19.2521047 },
    { lat: 48.067387, lng: 19.252343 },
    { lat: 48.067524, lng: 19.252418 },
    { lat: 48.067668, lng: 19.2525 },
    { lat: 48.067911, lng: 19.252633 },
    { lat: 48.068198, lng: 19.252763 },
    { lat: 48.068441, lng: 19.252912 },
    { lat: 48.068706, lng: 19.253064 },
    { lat: 48.068876, lng: 19.25314 },
    { lat: 48.069013, lng: 19.253195 },
    { lat: 48.069163, lng: 19.253271 },
    { lat: 48.069303, lng: 19.25336 },
    { lat: 48.069493, lng: 19.253483 },
    { lat: 48.069629, lng: 19.253555 },
    { lat: 48.069811, lng: 19.253674 },
    { lat: 48.070216, lng: 19.253913 },
    { lat: 48.070382, lng: 19.254023 },
    { lat: 48.070432, lng: 19.254083 },
    { lat: 48.070487, lng: 19.254147 },
    { lat: 48.070514, lng: 19.254178 },
    { lat: 48.070539, lng: 19.254207 },
    { lat: 48.070738, lng: 19.254481 },
    { lat: 48.070898, lng: 19.254697 },
    { lat: 48.071071, lng: 19.254972 },
    { lat: 48.071267, lng: 19.255282 },
    { lat: 48.071504, lng: 19.255692 },
    { lat: 48.071556, lng: 19.255782 },
    { lat: 48.071838, lng: 19.25635 },
    { lat: 48.072105, lng: 19.256958 },
    { lat: 48.072173, lng: 19.257125 },
    { lat: 48.072298, lng: 19.257433 },
    { lat: 48.07243, lng: 19.257869 },
    { lat: 48.072451, lng: 19.257939 },
    { lat: 48.072506, lng: 19.258157 },
    { lat: 48.073195, lng: 19.260882 },
    { lat: 48.073223, lng: 19.260994 },
    { lat: 48.073248, lng: 19.261095 },
    { lat: 48.0740031, lng: 19.2608128 },
    { lat: 48.0743591, lng: 19.260741 },
    { lat: 48.0755646, lng: 19.2609563 },
    { lat: 48.0760922, lng: 19.2612105 },
    { lat: 48.0761395, lng: 19.2611444 },
    { lat: 48.076311, lng: 19.2611674 },
    { lat: 48.0776924, lng: 19.2615701 },
    { lat: 48.0778656, lng: 19.2615622 },
    { lat: 48.0783396, lng: 19.2618068 },
    { lat: 48.0786993, lng: 19.26183 },
    { lat: 48.0805722, lng: 19.260737 },
    { lat: 48.081514, lng: 19.2607244 },
    { lat: 48.0817132, lng: 19.2608085 },
    { lat: 48.0818129, lng: 19.2609834 },
  ],
  Vrbovka: [
    { lat: 48.089538, lng: 19.423213 },
    { lat: 48.0899771, lng: 19.4234205 },
    { lat: 48.0900614, lng: 19.4232026 },
    { lat: 48.0901059, lng: 19.4225958 },
    { lat: 48.0899564, lng: 19.4218445 },
    { lat: 48.0902443, lng: 19.4215956 },
    { lat: 48.0904335, lng: 19.4213256 },
    { lat: 48.0906099, lng: 19.4214603 },
    { lat: 48.0908083, lng: 19.4218899 },
    { lat: 48.0910004, lng: 19.4221146 },
    { lat: 48.0913184, lng: 19.4221878 },
    { lat: 48.0915918, lng: 19.4220349 },
    { lat: 48.0918386, lng: 19.4220599 },
    { lat: 48.0922025, lng: 19.4223892 },
    { lat: 48.0924089, lng: 19.4224872 },
    { lat: 48.0942756, lng: 19.4214965 },
    { lat: 48.0952574, lng: 19.4211192 },
    { lat: 48.0961726, lng: 19.4202116 },
    { lat: 48.0968745, lng: 19.4204018 },
    { lat: 48.1006321, lng: 19.4190185 },
    { lat: 48.1018114, lng: 19.4181978 },
    { lat: 48.102469, lng: 19.4176629 },
    { lat: 48.1011399, lng: 19.4130717 },
    { lat: 48.1005233, lng: 19.4112178 },
    { lat: 48.1027224, lng: 19.4087593 },
    { lat: 48.1025222, lng: 19.4075768 },
    { lat: 48.1026368, lng: 19.4061024 },
    { lat: 48.1028005, lng: 19.4052543 },
    { lat: 48.1029972, lng: 19.404652 },
    { lat: 48.1029236, lng: 19.4044556 },
    { lat: 48.1037269, lng: 19.4039015 },
    { lat: 48.1037263, lng: 19.4038437 },
    { lat: 48.1040755, lng: 19.4035278 },
    { lat: 48.104074, lng: 19.4032642 },
    { lat: 48.1042023, lng: 19.4030454 },
    { lat: 48.1042717, lng: 19.4027482 },
    { lat: 48.1040814, lng: 19.4023269 },
    { lat: 48.1061235, lng: 19.4006913 },
    { lat: 48.106749, lng: 19.3999793 },
    { lat: 48.1082724, lng: 19.3985707 },
    { lat: 48.1087875, lng: 19.3982872 },
    { lat: 48.1092838, lng: 19.3981301 },
    { lat: 48.1101051, lng: 19.3960903 },
    { lat: 48.1101537, lng: 19.3958301 },
    { lat: 48.1100895, lng: 19.3957625 },
    { lat: 48.1104159, lng: 19.3951619 },
    { lat: 48.1108742, lng: 19.394653 },
    { lat: 48.1117124, lng: 19.3932266 },
    { lat: 48.1124867, lng: 19.3923071 },
    { lat: 48.1130071, lng: 19.3912336 },
    { lat: 48.1130297, lng: 19.3912601 },
    { lat: 48.1135774, lng: 19.390408 },
    { lat: 48.1138197, lng: 19.3902147 },
    { lat: 48.1148562, lng: 19.3898062 },
    { lat: 48.1148633, lng: 19.3897864 },
    { lat: 48.1152332, lng: 19.3882082 },
    { lat: 48.1152968, lng: 19.3879369 },
    { lat: 48.1145795, lng: 19.3866984 },
    { lat: 48.1144281, lng: 19.3855792 },
    { lat: 48.1135658, lng: 19.3811411 },
    { lat: 48.1136416, lng: 19.3802953 },
    { lat: 48.1138388, lng: 19.3799389 },
    { lat: 48.1139659, lng: 19.3782385 },
    { lat: 48.1140427, lng: 19.377982 },
    { lat: 48.1140828, lng: 19.3775908 },
    { lat: 48.1140587, lng: 19.3772559 },
    { lat: 48.1135083, lng: 19.3756664 },
    { lat: 48.1132172, lng: 19.3751155 },
    { lat: 48.1130401, lng: 19.3744784 },
    { lat: 48.1127587, lng: 19.3739441 },
    { lat: 48.1127498, lng: 19.3737814 },
    { lat: 48.112647, lng: 19.3736816 },
    { lat: 48.1124504, lng: 19.3732949 },
    { lat: 48.1124612, lng: 19.372721 },
    { lat: 48.1121861, lng: 19.3722915 },
    { lat: 48.1118767, lng: 19.372188 },
    { lat: 48.1115276, lng: 19.3717431 },
    { lat: 48.111098, lng: 19.3715326 },
    { lat: 48.1108938, lng: 19.3715247 },
    { lat: 48.1107809, lng: 19.371237 },
    { lat: 48.1105677, lng: 19.3712086 },
    { lat: 48.1103596, lng: 19.3710806 },
    { lat: 48.1099181, lng: 19.3713206 },
    { lat: 48.1096779, lng: 19.3713409 },
    { lat: 48.1095489, lng: 19.3712848 },
    { lat: 48.1092119, lng: 19.371306 },
    { lat: 48.1090052, lng: 19.3713936 },
    { lat: 48.1088203, lng: 19.37137 },
    { lat: 48.1084314, lng: 19.3716043 },
    { lat: 48.1080334, lng: 19.3717417 },
    { lat: 48.107664, lng: 19.3720336 },
    { lat: 48.1063577, lng: 19.3685435 },
    { lat: 48.1038934, lng: 19.3623231 },
    { lat: 48.1013511, lng: 19.355703 },
    { lat: 48.1011874, lng: 19.3550882 },
    { lat: 48.100927, lng: 19.3545251 },
    { lat: 48.1005311, lng: 19.3544494 },
    { lat: 48.1004344, lng: 19.3543596 },
    { lat: 48.0998523, lng: 19.3544437 },
    { lat: 48.0996941, lng: 19.3543873 },
    { lat: 48.0995111, lng: 19.3540532 },
    { lat: 48.0993547, lng: 19.353516 },
    { lat: 48.0992462, lng: 19.3534839 },
    { lat: 48.0990143, lng: 19.3531649 },
    { lat: 48.0987114, lng: 19.3542022 },
    { lat: 48.0984653, lng: 19.3543153 },
    { lat: 48.0977707, lng: 19.3541671 },
    { lat: 48.097565, lng: 19.3542716 },
    { lat: 48.0975551, lng: 19.3542085 },
    { lat: 48.0977071, lng: 19.354132 },
    { lat: 48.0976688, lng: 19.3541107 },
    { lat: 48.0975315, lng: 19.3541349 },
    { lat: 48.0973654, lng: 19.3543155 },
    { lat: 48.0972695, lng: 19.3542666 },
    { lat: 48.0971725, lng: 19.3544049 },
    { lat: 48.0969491, lng: 19.3543082 },
    { lat: 48.096859, lng: 19.3543549 },
    { lat: 48.0966663, lng: 19.3538382 },
    { lat: 48.0964638, lng: 19.3534484 },
    { lat: 48.0960269, lng: 19.3538075 },
    { lat: 48.0956163, lng: 19.3539431 },
    { lat: 48.0928286, lng: 19.3526453 },
    { lat: 48.08869, lng: 19.351837 },
    { lat: 48.088719, lng: 19.352029 },
    { lat: 48.088808, lng: 19.352497 },
    { lat: 48.088857, lng: 19.352615 },
    { lat: 48.088879, lng: 19.352771 },
    { lat: 48.0889136, lng: 19.3531253 },
    { lat: 48.088927, lng: 19.353248 },
    { lat: 48.088996, lng: 19.353555 },
    { lat: 48.088998, lng: 19.353705 },
    { lat: 48.089019, lng: 19.353772 },
    { lat: 48.089195, lng: 19.354188 },
    { lat: 48.089267, lng: 19.354301 },
    { lat: 48.089391, lng: 19.354476 },
    { lat: 48.089455, lng: 19.354619 },
    { lat: 48.089514, lng: 19.354807 },
    { lat: 48.089588, lng: 19.354966 },
    { lat: 48.089641, lng: 19.355114 },
    { lat: 48.089709, lng: 19.355239 },
    { lat: 48.089816, lng: 19.355378 },
    { lat: 48.089845, lng: 19.355418 },
    { lat: 48.08994, lng: 19.355504 },
    { lat: 48.09, lng: 19.355546 },
    { lat: 48.090058, lng: 19.355573 },
    { lat: 48.090114, lng: 19.355568 },
    { lat: 48.09023, lng: 19.35551 },
    { lat: 48.090334, lng: 19.355472 },
    { lat: 48.0905, lng: 19.355461 },
    { lat: 48.090586, lng: 19.355476 },
    { lat: 48.090623, lng: 19.355496 },
    { lat: 48.090685, lng: 19.355579 },
    { lat: 48.090759, lng: 19.355804 },
    { lat: 48.090761, lng: 19.355848 },
    { lat: 48.09075, lng: 19.355894 },
    { lat: 48.090717, lng: 19.356098 },
    { lat: 48.090676, lng: 19.356272 },
    { lat: 48.090648, lng: 19.356414 },
    { lat: 48.090531, lng: 19.356883 },
    { lat: 48.090489, lng: 19.356999 },
    { lat: 48.090407, lng: 19.357169 },
    { lat: 48.090191, lng: 19.357475 },
    { lat: 48.090154, lng: 19.357544 },
    { lat: 48.090124, lng: 19.357638 },
    { lat: 48.090091, lng: 19.357704 },
    { lat: 48.090065, lng: 19.357805 },
    { lat: 48.090066, lng: 19.357857 },
    { lat: 48.090075, lng: 19.357902 },
    { lat: 48.090169, lng: 19.358078 },
    { lat: 48.090273, lng: 19.358248 },
    { lat: 48.090324, lng: 19.358369 },
    { lat: 48.090365, lng: 19.35849 },
    { lat: 48.090386, lng: 19.358563 },
    { lat: 48.09039, lng: 19.358648 },
    { lat: 48.090392, lng: 19.358834 },
    { lat: 48.090379, lng: 19.358987 },
    { lat: 48.090345, lng: 19.359148 },
    { lat: 48.090315, lng: 19.359418 },
    { lat: 48.09033, lng: 19.359491 },
    { lat: 48.090345, lng: 19.359658 },
    { lat: 48.090337, lng: 19.359734 },
    { lat: 48.090291, lng: 19.359902 },
    { lat: 48.090193, lng: 19.360127 },
    { lat: 48.090093, lng: 19.360296 },
    { lat: 48.089981, lng: 19.360461 },
    { lat: 48.08986, lng: 19.360589 },
    { lat: 48.08974, lng: 19.360624 },
    { lat: 48.089626, lng: 19.360623 },
    { lat: 48.089535, lng: 19.360598 },
    { lat: 48.089409, lng: 19.360513 },
    { lat: 48.089333, lng: 19.360495 },
    { lat: 48.089101, lng: 19.360296 },
    { lat: 48.089026, lng: 19.360222 },
    { lat: 48.088961, lng: 19.36017 },
    { lat: 48.088874, lng: 19.360166 },
    { lat: 48.088734, lng: 19.360207 },
    { lat: 48.088636, lng: 19.360297 },
    { lat: 48.088546, lng: 19.360386 },
    { lat: 48.088478, lng: 19.360473 },
    { lat: 48.08842, lng: 19.36056 },
    { lat: 48.088357, lng: 19.360672 },
    { lat: 48.08831, lng: 19.360811 },
    { lat: 48.088253, lng: 19.361012 },
    { lat: 48.088185, lng: 19.361239 },
    { lat: 48.088124, lng: 19.361473 },
    { lat: 48.088089, lng: 19.361654 },
    { lat: 48.088076, lng: 19.361835 },
    { lat: 48.088065, lng: 19.362022 },
    { lat: 48.088083, lng: 19.362078 },
    { lat: 48.088089, lng: 19.362188 },
    { lat: 48.088123, lng: 19.36243 },
    { lat: 48.088184, lng: 19.362574 },
    { lat: 48.088234, lng: 19.362695 },
    { lat: 48.088329, lng: 19.362728 },
    { lat: 48.088464, lng: 19.362758 },
    { lat: 48.08855, lng: 19.362775 },
    { lat: 48.08859, lng: 19.362802 },
    { lat: 48.088636, lng: 19.362852 },
    { lat: 48.08872, lng: 19.363001 },
    { lat: 48.088731, lng: 19.363052 },
    { lat: 48.088727, lng: 19.363154 },
    { lat: 48.088701, lng: 19.363391 },
    { lat: 48.088687, lng: 19.363499 },
    { lat: 48.088655, lng: 19.363667 },
    { lat: 48.088608, lng: 19.363834 },
    { lat: 48.088582, lng: 19.364041 },
    { lat: 48.088583, lng: 19.364139 },
    { lat: 48.088596, lng: 19.364279 },
    { lat: 48.088598, lng: 19.364336 },
    { lat: 48.088676, lng: 19.364609 },
    { lat: 48.088743, lng: 19.364843 },
    { lat: 48.088854, lng: 19.365187 },
    { lat: 48.088888, lng: 19.365347 },
    { lat: 48.088884, lng: 19.36544 },
    { lat: 48.088865, lng: 19.365486 },
    { lat: 48.088837, lng: 19.36551 },
    { lat: 48.088742, lng: 19.365587 },
    { lat: 48.088658, lng: 19.365592 },
    { lat: 48.088632, lng: 19.365586 },
    { lat: 48.088581, lng: 19.365564 },
    { lat: 48.088454, lng: 19.365529 },
    { lat: 48.088341, lng: 19.365533 },
    { lat: 48.088272, lng: 19.365553 },
    { lat: 48.088203, lng: 19.365584 },
    { lat: 48.088098, lng: 19.365664 },
    { lat: 48.088041, lng: 19.365725 },
    { lat: 48.088004, lng: 19.36582 },
    { lat: 48.087968, lng: 19.366006 },
    { lat: 48.087936, lng: 19.366685 },
    { lat: 48.087889, lng: 19.367352 },
    { lat: 48.087908, lng: 19.367552 },
    { lat: 48.087906, lng: 19.367711 },
    { lat: 48.087868, lng: 19.367848 },
    { lat: 48.087812, lng: 19.367907 },
    { lat: 48.087575, lng: 19.368021 },
    { lat: 48.087348, lng: 19.367956 },
    { lat: 48.087023, lng: 19.367668 },
    { lat: 48.08695, lng: 19.367554 },
    { lat: 48.086929, lng: 19.367507 },
    { lat: 48.086903, lng: 19.367419 },
    { lat: 48.086861, lng: 19.367332 },
    { lat: 48.086791, lng: 19.367254 },
    { lat: 48.086758, lng: 19.367226 },
    { lat: 48.086686, lng: 19.367193 },
    { lat: 48.086562, lng: 19.367262 },
    { lat: 48.086483, lng: 19.367372 },
    { lat: 48.086477, lng: 19.367444 },
    { lat: 48.086472, lng: 19.367514 },
    { lat: 48.086476, lng: 19.367613 },
    { lat: 48.086475, lng: 19.367879 },
    { lat: 48.08644, lng: 19.368113 },
    { lat: 48.086324, lng: 19.368276 },
    { lat: 48.086172, lng: 19.368399 },
    { lat: 48.085988, lng: 19.368743 },
    { lat: 48.085928, lng: 19.36895 },
    { lat: 48.085896, lng: 19.369109 },
    { lat: 48.085907, lng: 19.369159 },
    { lat: 48.085908, lng: 19.369387 },
    { lat: 48.085913, lng: 19.369607 },
    { lat: 48.085932, lng: 19.369948 },
    { lat: 48.085972, lng: 19.370228 },
    { lat: 48.086037, lng: 19.370641 },
    { lat: 48.086069, lng: 19.370822 },
    { lat: 48.086106, lng: 19.371028 },
    { lat: 48.086173, lng: 19.37117 },
    { lat: 48.086234, lng: 19.371281 },
    { lat: 48.086381, lng: 19.371501 },
    { lat: 48.086457, lng: 19.371594 },
    { lat: 48.086762, lng: 19.371925 },
    { lat: 48.086796, lng: 19.371957 },
    { lat: 48.086913, lng: 19.372227 },
    { lat: 48.08707, lng: 19.372887 },
    { lat: 48.087077, lng: 19.373067 },
    { lat: 48.08709, lng: 19.373186 },
    { lat: 48.087122, lng: 19.373339 },
    { lat: 48.087153, lng: 19.373423 },
    { lat: 48.087216, lng: 19.373515 },
    { lat: 48.087261, lng: 19.373553 },
    { lat: 48.087316, lng: 19.373562 },
    { lat: 48.087351, lng: 19.373563 },
    { lat: 48.087376, lng: 19.373514 },
    { lat: 48.08742, lng: 19.373389 },
    { lat: 48.087465, lng: 19.373232 },
    { lat: 48.087462, lng: 19.373193 },
    { lat: 48.087457, lng: 19.373162 },
    { lat: 48.08745, lng: 19.373123 },
    { lat: 48.087379, lng: 19.372995 },
    { lat: 48.087354, lng: 19.372818 },
    { lat: 48.087344, lng: 19.37264 },
    { lat: 48.087378, lng: 19.372468 },
    { lat: 48.08741, lng: 19.372383 },
    { lat: 48.08743, lng: 19.372339 },
    { lat: 48.087474, lng: 19.372258 },
    { lat: 48.087562, lng: 19.37218 },
    { lat: 48.08761, lng: 19.372178 },
    { lat: 48.087656, lng: 19.372197 },
    { lat: 48.087694, lng: 19.372246 },
    { lat: 48.087754, lng: 19.372367 },
    { lat: 48.087771, lng: 19.372487 },
    { lat: 48.08776, lng: 19.372673 },
    { lat: 48.087732, lng: 19.372817 },
    { lat: 48.087714, lng: 19.372992 },
    { lat: 48.087714, lng: 19.373089 },
    { lat: 48.087733, lng: 19.373158 },
    { lat: 48.087762, lng: 19.373225 },
    { lat: 48.087786, lng: 19.373264 },
    { lat: 48.087836, lng: 19.373291 },
    { lat: 48.087881, lng: 19.373297 },
    { lat: 48.087915, lng: 19.373289 },
    { lat: 48.087986, lng: 19.373222 },
    { lat: 48.088039, lng: 19.373168 },
    { lat: 48.088172, lng: 19.372983 },
    { lat: 48.088205, lng: 19.37292 },
    { lat: 48.088267, lng: 19.372768 },
    { lat: 48.088332, lng: 19.372532 },
    { lat: 48.088405, lng: 19.372293 },
    { lat: 48.088452, lng: 19.372138 },
    { lat: 48.088491, lng: 19.371872 },
    { lat: 48.088526, lng: 19.371664 },
    { lat: 48.088546, lng: 19.371542 },
    { lat: 48.088588, lng: 19.371346 },
    { lat: 48.088678, lng: 19.371188 },
    { lat: 48.088822, lng: 19.370953 },
    { lat: 48.088855, lng: 19.370905 },
    { lat: 48.08893, lng: 19.370839 },
    { lat: 48.088992, lng: 19.370807 },
    { lat: 48.08908, lng: 19.370784 },
    { lat: 48.0892, lng: 19.370824 },
    { lat: 48.089238, lng: 19.370846 },
    { lat: 48.089281, lng: 19.370884 },
    { lat: 48.089409, lng: 19.371048 },
    { lat: 48.089465, lng: 19.371169 },
    { lat: 48.089634, lng: 19.371534 },
    { lat: 48.089678, lng: 19.371646 },
    { lat: 48.089777, lng: 19.372111 },
    { lat: 48.089847, lng: 19.372508 },
    { lat: 48.089887, lng: 19.372811 },
    { lat: 48.08992, lng: 19.373213 },
    { lat: 48.089917, lng: 19.373357 },
    { lat: 48.089826, lng: 19.373445 },
    { lat: 48.089702, lng: 19.373431 },
    { lat: 48.089662, lng: 19.373441 },
    { lat: 48.089596, lng: 19.373467 },
    { lat: 48.089551, lng: 19.373508 },
    { lat: 48.089518, lng: 19.37358 },
    { lat: 48.089497, lng: 19.373677 },
    { lat: 48.089497, lng: 19.37376 },
    { lat: 48.089516, lng: 19.373836 },
    { lat: 48.089548, lng: 19.3739 },
    { lat: 48.089627, lng: 19.373981 },
    { lat: 48.089712, lng: 19.374077 },
    { lat: 48.089852, lng: 19.374243 },
    { lat: 48.090012, lng: 19.374571 },
    { lat: 48.090043, lng: 19.374711 },
    { lat: 48.090032, lng: 19.374793 },
    { lat: 48.089995, lng: 19.375118 },
    { lat: 48.089996, lng: 19.375285 },
    { lat: 48.090002, lng: 19.375465 },
    { lat: 48.090037, lng: 19.375682 },
    { lat: 48.090104, lng: 19.375919 },
    { lat: 48.090142, lng: 19.376035 },
    { lat: 48.090359, lng: 19.376522 },
    { lat: 48.090431, lng: 19.3767 },
    { lat: 48.090531, lng: 19.376856 },
    { lat: 48.090576, lng: 19.37694 },
    { lat: 48.09065, lng: 19.377117 },
    { lat: 48.090672, lng: 19.377222 },
    { lat: 48.0907, lng: 19.377477 },
    { lat: 48.090715, lng: 19.377566 },
    { lat: 48.09079, lng: 19.377807 },
    { lat: 48.090896, lng: 19.378118 },
    { lat: 48.090953, lng: 19.378204 },
    { lat: 48.091163, lng: 19.378406 },
    { lat: 48.091271, lng: 19.378574 },
    { lat: 48.091363, lng: 19.378735 },
    { lat: 48.091379, lng: 19.378832 },
    { lat: 48.091317, lng: 19.379194 },
    { lat: 48.091306, lng: 19.379304 },
    { lat: 48.091306, lng: 19.379417 },
    { lat: 48.091313, lng: 19.37957 },
    { lat: 48.09133, lng: 19.379801 },
    { lat: 48.091357, lng: 19.380015 },
    { lat: 48.091402, lng: 19.380236 },
    { lat: 48.09144, lng: 19.380356 },
    { lat: 48.091481, lng: 19.380487 },
    { lat: 48.091567, lng: 19.380798 },
    { lat: 48.09163, lng: 19.381097 },
    { lat: 48.091691, lng: 19.381321 },
    { lat: 48.091738, lng: 19.381471 },
    { lat: 48.091812, lng: 19.381675 },
    { lat: 48.091866, lng: 19.381893 },
    { lat: 48.091878, lng: 19.38203 },
    { lat: 48.091853, lng: 19.382223 },
    { lat: 48.091835, lng: 19.382291 },
    { lat: 48.091779, lng: 19.382384 },
    { lat: 48.091689, lng: 19.382404 },
    { lat: 48.091658, lng: 19.38237 },
    { lat: 48.091599, lng: 19.382297 },
    { lat: 48.091534, lng: 19.382074 },
    { lat: 48.091474, lng: 19.38196 },
    { lat: 48.091421, lng: 19.381892 },
    { lat: 48.091346, lng: 19.381825 },
    { lat: 48.091186, lng: 19.381714 },
    { lat: 48.091134, lng: 19.381676 },
    { lat: 48.090988, lng: 19.381721 },
    { lat: 48.090923, lng: 19.381789 },
    { lat: 48.090861, lng: 19.381874 },
    { lat: 48.090786, lng: 19.382004 },
    { lat: 48.090721, lng: 19.382166 },
    { lat: 48.090683, lng: 19.382401 },
    { lat: 48.090682, lng: 19.38243 },
    { lat: 48.090685, lng: 19.382523 },
    { lat: 48.090693, lng: 19.382551 },
    { lat: 48.090709, lng: 19.382596 },
    { lat: 48.090783, lng: 19.382707 },
    { lat: 48.090857, lng: 19.382802 },
    { lat: 48.090945, lng: 19.382919 },
    { lat: 48.09102, lng: 19.383004 },
    { lat: 48.091215, lng: 19.383188 },
    { lat: 48.091345, lng: 19.383355 },
    { lat: 48.09139, lng: 19.383369 },
    { lat: 48.091465, lng: 19.383533 },
    { lat: 48.09149, lng: 19.383581 },
    { lat: 48.091493, lng: 19.383739 },
    { lat: 48.091612, lng: 19.384122 },
    { lat: 48.091643, lng: 19.384246 },
    { lat: 48.091693, lng: 19.384463 },
    { lat: 48.091782, lng: 19.384882 },
    { lat: 48.091821, lng: 19.385071 },
    { lat: 48.091847, lng: 19.385246 },
    { lat: 48.091884, lng: 19.385529 },
    { lat: 48.091889, lng: 19.385636 },
    { lat: 48.091903, lng: 19.3859 },
    { lat: 48.091909, lng: 19.386108 },
    { lat: 48.091925, lng: 19.386334 },
    { lat: 48.091914, lng: 19.386597 },
    { lat: 48.091886, lng: 19.386807 },
    { lat: 48.091858, lng: 19.386991 },
    { lat: 48.091831, lng: 19.38709 },
    { lat: 48.091799, lng: 19.387152 },
    { lat: 48.091735, lng: 19.387212 },
    { lat: 48.091624, lng: 19.387246 },
    { lat: 48.091544, lng: 19.387244 },
    { lat: 48.091418, lng: 19.387167 },
    { lat: 48.091356, lng: 19.387101 },
    { lat: 48.091324, lng: 19.387001 },
    { lat: 48.091317, lng: 19.386965 },
    { lat: 48.091313, lng: 19.386848 },
    { lat: 48.091373, lng: 19.386625 },
    { lat: 48.091385, lng: 19.386545 },
    { lat: 48.09138, lng: 19.386472 },
    { lat: 48.091366, lng: 19.386385 },
    { lat: 48.09129, lng: 19.386237 },
    { lat: 48.091209, lng: 19.386155 },
    { lat: 48.091082, lng: 19.386119 },
    { lat: 48.090895, lng: 19.386127 },
    { lat: 48.090806, lng: 19.386135 },
    { lat: 48.090738, lng: 19.386141 },
    { lat: 48.090616, lng: 19.386156 },
    { lat: 48.090549, lng: 19.386191 },
    { lat: 48.090475, lng: 19.386265 },
    { lat: 48.090448, lng: 19.386306 },
    { lat: 48.090439, lng: 19.386357 },
    { lat: 48.090422, lng: 19.386459 },
    { lat: 48.09042, lng: 19.386561 },
    { lat: 48.090526, lng: 19.386926 },
    { lat: 48.090581, lng: 19.387096 },
    { lat: 48.090697, lng: 19.387322 },
    { lat: 48.090799, lng: 19.387368 },
    { lat: 48.090831, lng: 19.387369 },
    { lat: 48.090924, lng: 19.387356 },
    { lat: 48.091042, lng: 19.387404 },
    { lat: 48.09116, lng: 19.387472 },
    { lat: 48.091246, lng: 19.387587 },
    { lat: 48.091282, lng: 19.38775 },
    { lat: 48.091294, lng: 19.387903 },
    { lat: 48.091258, lng: 19.388073 },
    { lat: 48.0912, lng: 19.388164 },
    { lat: 48.091128, lng: 19.388259 },
    { lat: 48.091039, lng: 19.388343 },
    { lat: 48.090984, lng: 19.388331 },
    { lat: 48.090866, lng: 19.388357 },
    { lat: 48.090809, lng: 19.388356 },
    { lat: 48.090701, lng: 19.388332 },
    { lat: 48.090574, lng: 19.38825 },
    { lat: 48.090457, lng: 19.388135 },
    { lat: 48.090345, lng: 19.387995 },
    { lat: 48.090277, lng: 19.387943 },
    { lat: 48.090205, lng: 19.387915 },
    { lat: 48.090172, lng: 19.387896 },
    { lat: 48.090141, lng: 19.387799 },
    { lat: 48.09014, lng: 19.387761 },
    { lat: 48.090141, lng: 19.387611 },
    { lat: 48.090137, lng: 19.387557 },
    { lat: 48.090132, lng: 19.387442 },
    { lat: 48.090113, lng: 19.387344 },
    { lat: 48.090034, lng: 19.387221 },
    { lat: 48.089991, lng: 19.387178 },
    { lat: 48.08996, lng: 19.387164 },
    { lat: 48.089913, lng: 19.387156 },
    { lat: 48.089867, lng: 19.387164 },
    { lat: 48.08969, lng: 19.387238 },
    { lat: 48.08955, lng: 19.387335 },
    { lat: 48.089429, lng: 19.387424 },
    { lat: 48.089378, lng: 19.38754 },
    { lat: 48.08934, lng: 19.387637 },
    { lat: 48.089339, lng: 19.387692 },
    { lat: 48.089349, lng: 19.387998 },
    { lat: 48.089359, lng: 19.388079 },
    { lat: 48.089387, lng: 19.388225 },
    { lat: 48.089417, lng: 19.388326 },
    { lat: 48.08947, lng: 19.388541 },
    { lat: 48.08957, lng: 19.389053 },
    { lat: 48.089652, lng: 19.389181 },
    { lat: 48.089673, lng: 19.389257 },
    { lat: 48.089718, lng: 19.389445 },
    { lat: 48.089688, lng: 19.389662 },
    { lat: 48.089654, lng: 19.38975 },
    { lat: 48.089568, lng: 19.389854 },
    { lat: 48.089484, lng: 19.389925 },
    { lat: 48.08936, lng: 19.389992 },
    { lat: 48.08922, lng: 19.390027 },
    { lat: 48.089137, lng: 19.389994 },
    { lat: 48.089103, lng: 19.389924 },
    { lat: 48.089028, lng: 19.389647 },
    { lat: 48.089007, lng: 19.389586 },
    { lat: 48.088955, lng: 19.389509 },
    { lat: 48.088869, lng: 19.389428 },
    { lat: 48.088835, lng: 19.389407 },
    { lat: 48.088763, lng: 19.38939 },
    { lat: 48.088686, lng: 19.389395 },
    { lat: 48.088529, lng: 19.389391 },
    { lat: 48.088457, lng: 19.389389 },
    { lat: 48.088407, lng: 19.389389 },
    { lat: 48.088375, lng: 19.38939 },
    { lat: 48.088251, lng: 19.389486 },
    { lat: 48.088234, lng: 19.389538 },
    { lat: 48.088212, lng: 19.389672 },
    { lat: 48.088179, lng: 19.389902 },
    { lat: 48.088204, lng: 19.390091 },
    { lat: 48.088217, lng: 19.390298 },
    { lat: 48.088237, lng: 19.390343 },
    { lat: 48.088355, lng: 19.390477 },
    { lat: 48.088403, lng: 19.390604 },
    { lat: 48.08843, lng: 19.390698 },
    { lat: 48.088426, lng: 19.390758 },
    { lat: 48.088399, lng: 19.390935 },
    { lat: 48.088339, lng: 19.391172 },
    { lat: 48.088231, lng: 19.39147 },
    { lat: 48.088167, lng: 19.391598 },
    { lat: 48.08808, lng: 19.391739 },
    { lat: 48.087994, lng: 19.391807 },
    { lat: 48.087822, lng: 19.391931 },
    { lat: 48.087776, lng: 19.391953 },
    { lat: 48.087608, lng: 19.391934 },
    { lat: 48.087526, lng: 19.39186 },
    { lat: 48.087384, lng: 19.391609 },
    { lat: 48.087336, lng: 19.391516 },
    { lat: 48.0873, lng: 19.391374 },
    { lat: 48.087267, lng: 19.391219 },
    { lat: 48.087254, lng: 19.391067 },
    { lat: 48.087251, lng: 19.390948 },
    { lat: 48.08734, lng: 19.390806 },
    { lat: 48.087409, lng: 19.390646 },
    { lat: 48.087439, lng: 19.390547 },
    { lat: 48.087462, lng: 19.39045 },
    { lat: 48.087484, lng: 19.390281 },
    { lat: 48.087471, lng: 19.390152 },
    { lat: 48.087462, lng: 19.390112 },
    { lat: 48.087457, lng: 19.390079 },
    { lat: 48.087438, lng: 19.390048 },
    { lat: 48.087384, lng: 19.389964 },
    { lat: 48.087305, lng: 19.389921 },
    { lat: 48.087113, lng: 19.389897 },
    { lat: 48.087054, lng: 19.389891 },
    { lat: 48.086954, lng: 19.389859 },
    { lat: 48.086857, lng: 19.389798 },
    { lat: 48.086759, lng: 19.389763 },
    { lat: 48.086649, lng: 19.38974 },
    { lat: 48.086514, lng: 19.389727 },
    { lat: 48.086395, lng: 19.389759 },
    { lat: 48.086359, lng: 19.389779 },
    { lat: 48.086333, lng: 19.389811 },
    { lat: 48.086322, lng: 19.389846 },
    { lat: 48.086319, lng: 19.389954 },
    { lat: 48.086332, lng: 19.390036 },
    { lat: 48.086368, lng: 19.39017 },
    { lat: 48.086429, lng: 19.390401 },
    { lat: 48.086695, lng: 19.390977 },
    { lat: 48.086797, lng: 19.39129 },
    { lat: 48.086836, lng: 19.391442 },
    { lat: 48.086873, lng: 19.391644 },
    { lat: 48.08689, lng: 19.392038 },
    { lat: 48.086901, lng: 19.392211 },
    { lat: 48.086901, lng: 19.392579 },
    { lat: 48.086879, lng: 19.392948 },
    { lat: 48.086836, lng: 19.393203 },
    { lat: 48.086838, lng: 19.393379 },
    { lat: 48.086862, lng: 19.393579 },
    { lat: 48.086853, lng: 19.393767 },
    { lat: 48.086803, lng: 19.39425 },
    { lat: 48.086717, lng: 19.394605 },
    { lat: 48.086628, lng: 19.394968 },
    { lat: 48.086507, lng: 19.395254 },
    { lat: 48.0864, lng: 19.395427 },
    { lat: 48.08625, lng: 19.395668 },
    { lat: 48.08617, lng: 19.395824 },
    { lat: 48.086107, lng: 19.395966 },
    { lat: 48.086061, lng: 19.396097 },
    { lat: 48.086023, lng: 19.396333 },
    { lat: 48.085982, lng: 19.396419 },
    { lat: 48.085914, lng: 19.396499 },
    { lat: 48.0858, lng: 19.396595 },
    { lat: 48.085714, lng: 19.396618 },
    { lat: 48.085555, lng: 19.396631 },
    { lat: 48.08538, lng: 19.396588 },
    { lat: 48.08528, lng: 19.396532 },
    { lat: 48.085233, lng: 19.396459 },
    { lat: 48.085181, lng: 19.396371 },
    { lat: 48.085119, lng: 19.396233 },
    { lat: 48.084963, lng: 19.396118 },
    { lat: 48.084826, lng: 19.396034 },
    { lat: 48.084655, lng: 19.395943 },
    { lat: 48.084599, lng: 19.395926 },
    { lat: 48.084526, lng: 19.395897 },
    { lat: 48.084475, lng: 19.395859 },
    { lat: 48.084264, lng: 19.39573 },
    { lat: 48.084206, lng: 19.395697 },
    { lat: 48.083989, lng: 19.395638 },
    { lat: 48.083954, lng: 19.395633 },
    { lat: 48.08379, lng: 19.395634 },
    { lat: 48.083683, lng: 19.395686 },
    { lat: 48.08362, lng: 19.395786 },
    { lat: 48.083601, lng: 19.395831 },
    { lat: 48.0836, lng: 19.395866 },
    { lat: 48.083617, lng: 19.39595 },
    { lat: 48.083659, lng: 19.39608 },
    { lat: 48.083695, lng: 19.396119 },
    { lat: 48.08381, lng: 19.396246 },
    { lat: 48.083971, lng: 19.396342 },
    { lat: 48.084124, lng: 19.396458 },
    { lat: 48.084194, lng: 19.396525 },
    { lat: 48.084289, lng: 19.396582 },
    { lat: 48.084335, lng: 19.396578 },
    { lat: 48.084492, lng: 19.396562 },
    { lat: 48.084587, lng: 19.396564 },
    { lat: 48.084622, lng: 19.396571 },
    { lat: 48.08467, lng: 19.396588 },
    { lat: 48.084713, lng: 19.39661 },
    { lat: 48.084813, lng: 19.396719 },
    { lat: 48.08489, lng: 19.396864 },
    { lat: 48.084904, lng: 19.396927 },
    { lat: 48.084933, lng: 19.39708 },
    { lat: 48.084919, lng: 19.397392 },
    { lat: 48.084908, lng: 19.397483 },
    { lat: 48.084902, lng: 19.397617 },
    { lat: 48.084846, lng: 19.397872 },
    { lat: 48.084714, lng: 19.398302 },
    { lat: 48.084559, lng: 19.398753 },
    { lat: 48.084289, lng: 19.399356 },
    { lat: 48.084275, lng: 19.399395 },
    { lat: 48.08424, lng: 19.399459 },
    { lat: 48.084138, lng: 19.399563 },
    { lat: 48.084034, lng: 19.399592 },
    { lat: 48.083961, lng: 19.399595 },
    { lat: 48.083901, lng: 19.399574 },
    { lat: 48.083783, lng: 19.399491 },
    { lat: 48.083674, lng: 19.399343 },
    { lat: 48.083624, lng: 19.399183 },
    { lat: 48.083611, lng: 19.399113 },
    { lat: 48.08364, lng: 19.399042 },
    { lat: 48.083713, lng: 19.398879 },
    { lat: 48.083736, lng: 19.398739 },
    { lat: 48.083739, lng: 19.398673 },
    { lat: 48.083735, lng: 19.3986 },
    { lat: 48.083679, lng: 19.398441 },
    { lat: 48.083642, lng: 19.398392 },
    { lat: 48.083545, lng: 19.398312 },
    { lat: 48.083459, lng: 19.39826 },
    { lat: 48.083313, lng: 19.398194 },
    { lat: 48.083165, lng: 19.398154 },
    { lat: 48.08308, lng: 19.398123 },
    { lat: 48.083036, lng: 19.398104 },
    { lat: 48.082738, lng: 19.398021 },
    { lat: 48.082654, lng: 19.398018 },
    { lat: 48.082556, lng: 19.398026 },
    { lat: 48.082453, lng: 19.398071 },
    { lat: 48.082347, lng: 19.398198 },
    { lat: 48.082279, lng: 19.398357 },
    { lat: 48.082265, lng: 19.398551 },
    { lat: 48.082277, lng: 19.398779 },
    { lat: 48.082296, lng: 19.398987 },
    { lat: 48.082318, lng: 19.399234 },
    { lat: 48.082325, lng: 19.399366 },
    { lat: 48.082308, lng: 19.39977 },
    { lat: 48.082302, lng: 19.399961 },
    { lat: 48.082304, lng: 19.400018 },
    { lat: 48.08234, lng: 19.400216 },
    { lat: 48.082414, lng: 19.400369 },
    { lat: 48.082481, lng: 19.400456 },
    { lat: 48.082514, lng: 19.400476 },
    { lat: 48.082665, lng: 19.400559 },
    { lat: 48.082815, lng: 19.400615 },
    { lat: 48.083006, lng: 19.400603 },
    { lat: 48.083195, lng: 19.40063 },
    { lat: 48.083354, lng: 19.400666 },
    { lat: 48.083472, lng: 19.400709 },
    { lat: 48.08365, lng: 19.400818 },
    { lat: 48.08376, lng: 19.40086 },
    { lat: 48.083979, lng: 19.400961 },
    { lat: 48.084259, lng: 19.401134 },
    { lat: 48.084346, lng: 19.401199 },
    { lat: 48.084412, lng: 19.401227 },
    { lat: 48.084571, lng: 19.401228 },
    { lat: 48.084838, lng: 19.401337 },
    { lat: 48.085182, lng: 19.401554 },
    { lat: 48.085356, lng: 19.401716 },
    { lat: 48.085421, lng: 19.401789 },
    { lat: 48.085527, lng: 19.401932 },
    { lat: 48.085607, lng: 19.402107 },
    { lat: 48.08563, lng: 19.402357 },
    { lat: 48.085612, lng: 19.402662 },
    { lat: 48.085581, lng: 19.402925 },
    { lat: 48.085498, lng: 19.403381 },
    { lat: 48.085436, lng: 19.403742 },
    { lat: 48.08539, lng: 19.404252 },
    { lat: 48.085382, lng: 19.404396 },
    { lat: 48.085314, lng: 19.404611 },
    { lat: 48.08524, lng: 19.40475 },
    { lat: 48.085178, lng: 19.404851 },
    { lat: 48.085155, lng: 19.404909 },
    { lat: 48.08516, lng: 19.404974 },
    { lat: 48.085212, lng: 19.405184 },
    { lat: 48.085279, lng: 19.405283 },
    { lat: 48.085411, lng: 19.405467 },
    { lat: 48.085557, lng: 19.405641 },
    { lat: 48.085633, lng: 19.405733 },
    { lat: 48.085705, lng: 19.405802 },
    { lat: 48.085906, lng: 19.405945 },
    { lat: 48.085963, lng: 19.406023 },
    { lat: 48.086005, lng: 19.406092 },
    { lat: 48.086077, lng: 19.406231 },
    { lat: 48.086152, lng: 19.406446 },
    { lat: 48.086239, lng: 19.406567 },
    { lat: 48.086298, lng: 19.406632 },
    { lat: 48.086485, lng: 19.406792 },
    { lat: 48.086728, lng: 19.406914 },
    { lat: 48.086982, lng: 19.407037 },
    { lat: 48.087131, lng: 19.407101 },
    { lat: 48.087406, lng: 19.407321 },
    { lat: 48.087564, lng: 19.407486 },
    { lat: 48.087629, lng: 19.407528 },
    { lat: 48.087779, lng: 19.407646 },
    { lat: 48.087872, lng: 19.407695 },
    { lat: 48.088062, lng: 19.407898 },
    { lat: 48.088207, lng: 19.408017 },
    { lat: 48.088365, lng: 19.408169 },
    { lat: 48.088495, lng: 19.408317 },
    { lat: 48.088562, lng: 19.408503 },
    { lat: 48.088576, lng: 19.408538 },
    { lat: 48.088684, lng: 19.40867 },
    { lat: 48.088792, lng: 19.408821 },
    { lat: 48.088901, lng: 19.408963 },
    { lat: 48.088953, lng: 19.409117 },
    { lat: 48.088973, lng: 19.409256 },
    { lat: 48.088972, lng: 19.409378 },
    { lat: 48.088957, lng: 19.409519 },
    { lat: 48.088873, lng: 19.409784 },
    { lat: 48.088733, lng: 19.410023 },
    { lat: 48.088573, lng: 19.410205 },
    { lat: 48.088503, lng: 19.410296 },
    { lat: 48.088426, lng: 19.410434 },
    { lat: 48.088393, lng: 19.410512 },
    { lat: 48.088363, lng: 19.41067 },
    { lat: 48.088341, lng: 19.410904 },
    { lat: 48.088333, lng: 19.411108 },
    { lat: 48.088375, lng: 19.411517 },
    { lat: 48.088471, lng: 19.412077 },
    { lat: 48.088542, lng: 19.412338 },
    { lat: 48.088584, lng: 19.412519 },
    { lat: 48.088737, lng: 19.412936 },
    { lat: 48.0888873, lng: 19.4132977 },
    { lat: 48.088908, lng: 19.413361 },
    { lat: 48.088951, lng: 19.413488 },
    { lat: 48.088962, lng: 19.413587 },
    { lat: 48.08899, lng: 19.413801 },
    { lat: 48.088999, lng: 19.413952 },
    { lat: 48.088977, lng: 19.414161 },
    { lat: 48.088901, lng: 19.414424 },
    { lat: 48.08878, lng: 19.414747 },
    { lat: 48.088643, lng: 19.415016 },
    { lat: 48.088514, lng: 19.415224 },
    { lat: 48.088406, lng: 19.41532 },
    { lat: 48.088296, lng: 19.415408 },
    { lat: 48.088253, lng: 19.415432 },
    { lat: 48.088107, lng: 19.415501 },
    { lat: 48.088066, lng: 19.415513 },
    { lat: 48.087785, lng: 19.415547 },
    { lat: 48.087627, lng: 19.415509 },
    { lat: 48.087587, lng: 19.415506 },
    { lat: 48.087548, lng: 19.415508 },
    { lat: 48.087463, lng: 19.415528 },
    { lat: 48.087391, lng: 19.415563 },
    { lat: 48.087306, lng: 19.415676 },
    { lat: 48.087275, lng: 19.41573 },
    { lat: 48.087235, lng: 19.415871 },
    { lat: 48.087224, lng: 19.415994 },
    { lat: 48.087244, lng: 19.416198 },
    { lat: 48.087362, lng: 19.416545 },
    { lat: 48.087442, lng: 19.416711 },
    { lat: 48.087553, lng: 19.416895 },
    { lat: 48.087635, lng: 19.417003 },
    { lat: 48.087942, lng: 19.417368 },
    { lat: 48.088223, lng: 19.417676 },
    { lat: 48.088257, lng: 19.417719 },
    { lat: 48.0883, lng: 19.417789 },
    { lat: 48.088432, lng: 19.418016 },
    { lat: 48.088534, lng: 19.418204 },
    { lat: 48.088615, lng: 19.418366 },
    { lat: 48.08868, lng: 19.418567 },
    { lat: 48.0887, lng: 19.418714 },
    { lat: 48.088728, lng: 19.41887 },
    { lat: 48.088734, lng: 19.418962 },
    { lat: 48.08872, lng: 19.419151 },
    { lat: 48.088685, lng: 19.419288 },
    { lat: 48.088627, lng: 19.419456 },
    { lat: 48.088419, lng: 19.419785 },
    { lat: 48.088379, lng: 19.419885 },
    { lat: 48.088329, lng: 19.420031 },
    { lat: 48.088301, lng: 19.420147 },
    { lat: 48.088293, lng: 19.420425 },
    { lat: 48.088296, lng: 19.420509 },
    { lat: 48.088351, lng: 19.420834 },
    { lat: 48.088426, lng: 19.421122 },
    { lat: 48.088509, lng: 19.421315 },
    { lat: 48.088623, lng: 19.421418 },
    { lat: 48.088706, lng: 19.421486 },
    { lat: 48.088773, lng: 19.421591 },
    { lat: 48.089054, lng: 19.421765 },
    { lat: 48.089223, lng: 19.421904 },
    { lat: 48.089369, lng: 19.422038 },
    { lat: 48.089474, lng: 19.422161 },
    { lat: 48.089527, lng: 19.422241 },
    { lat: 48.089654, lng: 19.422526 },
    { lat: 48.089687, lng: 19.422643 },
    { lat: 48.089692, lng: 19.422713 },
    { lat: 48.089682, lng: 19.422814 },
    { lat: 48.089658, lng: 19.422934 },
    { lat: 48.089612, lng: 19.42306 },
    { lat: 48.089538, lng: 19.423213 },
  ],
  Širákov: [
    { lat: 48.1569083, lng: 19.1454732 },
    { lat: 48.1569852, lng: 19.1464762 },
    { lat: 48.1569598, lng: 19.1471195 },
    { lat: 48.1568721, lng: 19.1475314 },
    { lat: 48.1569231, lng: 19.1479003 },
    { lat: 48.1566991, lng: 19.1488104 },
    { lat: 48.1560162, lng: 19.1497474 },
    { lat: 48.1557255, lng: 19.1499304 },
    { lat: 48.155128, lng: 19.1505889 },
    { lat: 48.1549053, lng: 19.1509844 },
    { lat: 48.1547862, lng: 19.151106 },
    { lat: 48.1545465, lng: 19.1512512 },
    { lat: 48.1541028, lng: 19.1512836 },
    { lat: 48.1541265, lng: 19.1513745 },
    { lat: 48.1539582, lng: 19.1514098 },
    { lat: 48.1538961, lng: 19.1517062 },
    { lat: 48.1537337, lng: 19.1519985 },
    { lat: 48.1533184, lng: 19.1524877 },
    { lat: 48.1530539, lng: 19.1532017 },
    { lat: 48.1522618, lng: 19.1544543 },
    { lat: 48.1521703, lng: 19.1547003 },
    { lat: 48.1519807, lng: 19.1556491 },
    { lat: 48.151712, lng: 19.1566388 },
    { lat: 48.1514104, lng: 19.157421 },
    { lat: 48.15107, lng: 19.1580099 },
    { lat: 48.1492244, lng: 19.1605262 },
    { lat: 48.1477283, lng: 19.1623187 },
    { lat: 48.1469312, lng: 19.1635166 },
    { lat: 48.1470078, lng: 19.1643803 },
    { lat: 48.1465384, lng: 19.1662125 },
    { lat: 48.1461229, lng: 19.1674036 },
    { lat: 48.1453347, lng: 19.1687613 },
    { lat: 48.1452499, lng: 19.1695101 },
    { lat: 48.1448819, lng: 19.1706109 },
    { lat: 48.144517, lng: 19.1713904 },
    { lat: 48.1441966, lng: 19.1722984 },
    { lat: 48.1442359, lng: 19.1723618 },
    { lat: 48.1441805, lng: 19.1729497 },
    { lat: 48.1443292, lng: 19.1729706 },
    { lat: 48.1444433, lng: 19.1730678 },
    { lat: 48.1442932, lng: 19.1737233 },
    { lat: 48.1447109, lng: 19.1741423 },
    { lat: 48.1421129, lng: 19.1787258 },
    { lat: 48.141509, lng: 19.1797113 },
    { lat: 48.1403526, lng: 19.1819723 },
    { lat: 48.1402258, lng: 19.1821889 },
    { lat: 48.1409764, lng: 19.1831885 },
    { lat: 48.1424072, lng: 19.1854471 },
    { lat: 48.1424095, lng: 19.1859057 },
    { lat: 48.1435854, lng: 19.1871609 },
    { lat: 48.143503, lng: 19.187433 },
    { lat: 48.1444311, lng: 19.1882175 },
    { lat: 48.14445, lng: 19.1883464 },
    { lat: 48.1454119, lng: 19.189542 },
    { lat: 48.1454897, lng: 19.1908857 },
    { lat: 48.1456481, lng: 19.191056 },
    { lat: 48.1454519, lng: 19.191785 },
    { lat: 48.1460902, lng: 19.1924982 },
    { lat: 48.146941, lng: 19.1935876 },
    { lat: 48.1469197, lng: 19.193639 },
    { lat: 48.1474466, lng: 19.1943307 },
    { lat: 48.1483313, lng: 19.1953313 },
    { lat: 48.148425, lng: 19.1951953 },
    { lat: 48.1489613, lng: 19.194714 },
    { lat: 48.1504205, lng: 19.1938542 },
    { lat: 48.1493591, lng: 19.1924265 },
    { lat: 48.1494511, lng: 19.1916888 },
    { lat: 48.1505408, lng: 19.1909463 },
    { lat: 48.1507127, lng: 19.1907543 },
    { lat: 48.1518979, lng: 19.1924727 },
    { lat: 48.1536838, lng: 19.190656 },
    { lat: 48.1547866, lng: 19.1899324 },
    { lat: 48.1549706, lng: 19.1897547 },
    { lat: 48.1553097, lng: 19.1891114 },
    { lat: 48.155699, lng: 19.1885703 },
    { lat: 48.1563007, lng: 19.1874246 },
    { lat: 48.1565994, lng: 19.1871491 },
    { lat: 48.156741, lng: 19.1866597 },
    { lat: 48.1569156, lng: 19.1864978 },
    { lat: 48.1569984, lng: 19.1860985 },
    { lat: 48.1572206, lng: 19.1860538 },
    { lat: 48.1577097, lng: 19.1856408 },
    { lat: 48.1577228, lng: 19.1854762 },
    { lat: 48.1578653, lng: 19.1852331 },
    { lat: 48.1578243, lng: 19.1851112 },
    { lat: 48.1580406, lng: 19.1849475 },
    { lat: 48.1585447, lng: 19.1843584 },
    { lat: 48.1587792, lng: 19.1838562 },
    { lat: 48.1590502, lng: 19.1840293 },
    { lat: 48.1592552, lng: 19.1833407 },
    { lat: 48.15941, lng: 19.182161 },
    { lat: 48.1599343, lng: 19.1803404 },
    { lat: 48.1601896, lng: 19.1798956 },
    { lat: 48.1603594, lng: 19.1793878 },
    { lat: 48.1607025, lng: 19.178845 },
    { lat: 48.1611531, lng: 19.1786567 },
    { lat: 48.1619515, lng: 19.1777062 },
    { lat: 48.1620136, lng: 19.1778685 },
    { lat: 48.1619876, lng: 19.1779116 },
    { lat: 48.1620994, lng: 19.1785567 },
    { lat: 48.1622307, lng: 19.1790676 },
    { lat: 48.1623677, lng: 19.1793296 },
    { lat: 48.1623261, lng: 19.1794663 },
    { lat: 48.1626288, lng: 19.1798897 },
    { lat: 48.1644952, lng: 19.181999 },
    { lat: 48.1648117, lng: 19.1823373 },
    { lat: 48.1650299, lng: 19.1825067 },
    { lat: 48.1650576, lng: 19.1824521 },
    { lat: 48.1651879, lng: 19.1826502 },
    { lat: 48.1652626, lng: 19.1826088 },
    { lat: 48.1652592, lng: 19.181856 },
    { lat: 48.1653721, lng: 19.1815419 },
    { lat: 48.1655421, lng: 19.181354 },
    { lat: 48.1655166, lng: 19.1810659 },
    { lat: 48.1656975, lng: 19.1806211 },
    { lat: 48.1657534, lng: 19.1802469 },
    { lat: 48.1657338, lng: 19.1799899 },
    { lat: 48.1658808, lng: 19.1798871 },
    { lat: 48.1660073, lng: 19.1796592 },
    { lat: 48.1662679, lng: 19.1786858 },
    { lat: 48.1662137, lng: 19.1781832 },
    { lat: 48.1662965, lng: 19.1777613 },
    { lat: 48.1664481, lng: 19.177497 },
    { lat: 48.1663916, lng: 19.1772673 },
    { lat: 48.1664129, lng: 19.1771485 },
    { lat: 48.1665754, lng: 19.1769045 },
    { lat: 48.1667639, lng: 19.1764747 },
    { lat: 48.1668982, lng: 19.1763207 },
    { lat: 48.166953, lng: 19.1761536 },
    { lat: 48.1671191, lng: 19.1759544 },
    { lat: 48.1671738, lng: 19.1756454 },
    { lat: 48.1674535, lng: 19.1751928 },
    { lat: 48.167632, lng: 19.1751826 },
    { lat: 48.1678267, lng: 19.1749248 },
    { lat: 48.1681598, lng: 19.1750935 },
    { lat: 48.1686061, lng: 19.1749975 },
    { lat: 48.1691806, lng: 19.1746736 },
    { lat: 48.1693061, lng: 19.1745231 },
    { lat: 48.1694232, lng: 19.1745405 },
    { lat: 48.1698268, lng: 19.1743132 },
    { lat: 48.1699818, lng: 19.1741428 },
    { lat: 48.1704552, lng: 19.1740053 },
    { lat: 48.1706171, lng: 19.1741548 },
    { lat: 48.1706817, lng: 19.174118 },
    { lat: 48.1709985, lng: 19.1742754 },
    { lat: 48.1713402, lng: 19.1742549 },
    { lat: 48.1718689, lng: 19.1745749 },
    { lat: 48.1721161, lng: 19.1745147 },
    { lat: 48.1725088, lng: 19.1739051 },
    { lat: 48.1727262, lng: 19.1737444 },
    { lat: 48.173084, lng: 19.1730705 },
    { lat: 48.1747788, lng: 19.170828 },
    { lat: 48.1755468, lng: 19.1693546 },
    { lat: 48.1767219, lng: 19.167588 },
    { lat: 48.1772942, lng: 19.1663767 },
    { lat: 48.1775035, lng: 19.1662288 },
    { lat: 48.177402, lng: 19.1660493 },
    { lat: 48.177394, lng: 19.1658888 },
    { lat: 48.1772242, lng: 19.1658748 },
    { lat: 48.1770451, lng: 19.1654224 },
    { lat: 48.1770599, lng: 19.1651304 },
    { lat: 48.1769891, lng: 19.164908 },
    { lat: 48.1764105, lng: 19.1644236 },
    { lat: 48.1719616, lng: 19.1602127 },
    { lat: 48.1703709, lng: 19.158621 },
    { lat: 48.1658085, lng: 19.1543065 },
    { lat: 48.1644037, lng: 19.1536475 },
    { lat: 48.1606188, lng: 19.1520524 },
    { lat: 48.1599571, lng: 19.1512157 },
    { lat: 48.1583728, lng: 19.1510242 },
    { lat: 48.1576382, lng: 19.1505607 },
    { lat: 48.1576478, lng: 19.1486442 },
    { lat: 48.1574696, lng: 19.1486195 },
    { lat: 48.1572295, lng: 19.1467622 },
    { lat: 48.1573692, lng: 19.1466916 },
    { lat: 48.1570178, lng: 19.1459536 },
    { lat: 48.1569083, lng: 19.1454732 },
  ],
  SlovenskéKľačany: [
    { lat: 48.2583807, lng: 19.4168968 },
    { lat: 48.2588929, lng: 19.417669 },
    { lat: 48.2591288, lng: 19.4178873 },
    { lat: 48.2593646, lng: 19.4183953 },
    { lat: 48.2581994, lng: 19.4199 },
    { lat: 48.2560212, lng: 19.4229818 },
    { lat: 48.2544988, lng: 19.4238713 },
    { lat: 48.2540209, lng: 19.4240132 },
    { lat: 48.2524468, lng: 19.4247889 },
    { lat: 48.2510934, lng: 19.426391 },
    { lat: 48.2499662, lng: 19.4288916 },
    { lat: 48.2496025, lng: 19.4304025 },
    { lat: 48.2488807, lng: 19.4315125 },
    { lat: 48.2488275, lng: 19.4315118 },
    { lat: 48.2486351, lng: 19.4321135 },
    { lat: 48.2478619, lng: 19.4338816 },
    { lat: 48.247736, lng: 19.4344126 },
    { lat: 48.2472231, lng: 19.4358695 },
    { lat: 48.2472353, lng: 19.435958 },
    { lat: 48.2465227, lng: 19.4379353 },
    { lat: 48.2465529, lng: 19.438843 },
    { lat: 48.2466591, lng: 19.4394668 },
    { lat: 48.2468902, lng: 19.44167 },
    { lat: 48.2477664, lng: 19.4427593 },
    { lat: 48.2484518, lng: 19.4437686 },
    { lat: 48.2491416, lng: 19.4455395 },
    { lat: 48.2503514, lng: 19.4483525 },
    { lat: 48.2503114, lng: 19.4483907 },
    { lat: 48.2504246, lng: 19.4487136 },
    { lat: 48.2508585, lng: 19.4497191 },
    { lat: 48.2511885, lng: 19.4518269 },
    { lat: 48.2513444, lng: 19.452093 },
    { lat: 48.2518646, lng: 19.4529721 },
    { lat: 48.2541017, lng: 19.4547942 },
    { lat: 48.2551692, lng: 19.4557608 },
    { lat: 48.2554749, lng: 19.4564366 },
    { lat: 48.2557587, lng: 19.4568162 },
    { lat: 48.2560881, lng: 19.4573997 },
    { lat: 48.2573572, lng: 19.4589619 },
    { lat: 48.2581904, lng: 19.4594252 },
    { lat: 48.2593599, lng: 19.4601859 },
    { lat: 48.2607838, lng: 19.4613748 },
    { lat: 48.2609977, lng: 19.461585 },
    { lat: 48.2610252, lng: 19.4617391 },
    { lat: 48.26172, lng: 19.4614878 },
    { lat: 48.2620657, lng: 19.4615776 },
    { lat: 48.2624201, lng: 19.4607 },
    { lat: 48.2624239, lng: 19.4605196 },
    { lat: 48.2622138, lng: 19.4601936 },
    { lat: 48.26226, lng: 19.4598086 },
    { lat: 48.2626099, lng: 19.4593646 },
    { lat: 48.2626357, lng: 19.4591698 },
    { lat: 48.2625443, lng: 19.4587122 },
    { lat: 48.2623127, lng: 19.4585566 },
    { lat: 48.2622144, lng: 19.4585754 },
    { lat: 48.2621767, lng: 19.4583962 },
    { lat: 48.2624111, lng: 19.4579163 },
    { lat: 48.2625761, lng: 19.4577083 },
    { lat: 48.2626927, lng: 19.4576964 },
    { lat: 48.2627036, lng: 19.4577125 },
    { lat: 48.2628292, lng: 19.4574362 },
    { lat: 48.2629933, lng: 19.4572785 },
    { lat: 48.2635813, lng: 19.4573931 },
    { lat: 48.2640956, lng: 19.4570202 },
    { lat: 48.2643753, lng: 19.4565886 },
    { lat: 48.2646036, lng: 19.4560534 },
    { lat: 48.2644865, lng: 19.4559851 },
    { lat: 48.2646502, lng: 19.4555318 },
    { lat: 48.2647995, lng: 19.4556653 },
    { lat: 48.2649931, lng: 19.4554914 },
    { lat: 48.2657106, lng: 19.4542556 },
    { lat: 48.2659417, lng: 19.4534184 },
    { lat: 48.2655718, lng: 19.4531005 },
    { lat: 48.2655681, lng: 19.4521119 },
    { lat: 48.2653811, lng: 19.4515607 },
    { lat: 48.2651233, lng: 19.4512801 },
    { lat: 48.2653533, lng: 19.4505615 },
    { lat: 48.2654837, lng: 19.4503175 },
    { lat: 48.2659683, lng: 19.4498593 },
    { lat: 48.2659429, lng: 19.4497767 },
    { lat: 48.2663932, lng: 19.4493833 },
    { lat: 48.266615, lng: 19.4490426 },
    { lat: 48.2665288, lng: 19.4481182 },
    { lat: 48.2665996, lng: 19.4480669 },
    { lat: 48.2671044, lng: 19.4481342 },
    { lat: 48.2673125, lng: 19.4479839 },
    { lat: 48.2674601, lng: 19.4478385 },
    { lat: 48.2678656, lng: 19.4471158 },
    { lat: 48.26787, lng: 19.4463711 },
    { lat: 48.2680533, lng: 19.4458845 },
    { lat: 48.2681785, lng: 19.4458341 },
    { lat: 48.2682536, lng: 19.4450108 },
    { lat: 48.2683274, lng: 19.4448085 },
    { lat: 48.2683212, lng: 19.4444604 },
    { lat: 48.2682905, lng: 19.4444307 },
    { lat: 48.2683644, lng: 19.443512 },
    { lat: 48.2683787, lng: 19.4426424 },
    { lat: 48.2684767, lng: 19.4427357 },
    { lat: 48.2686417, lng: 19.4421474 },
    { lat: 48.26884, lng: 19.4419308 },
    { lat: 48.2693669, lng: 19.4417294 },
    { lat: 48.269681, lng: 19.4417447 },
    { lat: 48.2698892, lng: 19.4413573 },
    { lat: 48.2700597, lng: 19.4412653 },
    { lat: 48.2702134, lng: 19.4413004 },
    { lat: 48.2704548, lng: 19.4407793 },
    { lat: 48.2705447, lng: 19.4403668 },
    { lat: 48.2707413, lng: 19.440183 },
    { lat: 48.2710901, lng: 19.4396962 },
    { lat: 48.2711632, lng: 19.4395692 },
    { lat: 48.2711552, lng: 19.4393985 },
    { lat: 48.2712066, lng: 19.4392024 },
    { lat: 48.2718608, lng: 19.4381331 },
    { lat: 48.2713775, lng: 19.4375903 },
    { lat: 48.271498, lng: 19.4374403 },
    { lat: 48.2714812, lng: 19.4372301 },
    { lat: 48.2716426, lng: 19.4370917 },
    { lat: 48.2715571, lng: 19.4367849 },
    { lat: 48.2714859, lng: 19.4368985 },
    { lat: 48.2714122, lng: 19.4368872 },
    { lat: 48.2714611, lng: 19.4367076 },
    { lat: 48.2717146, lng: 19.4366596 },
    { lat: 48.2717939, lng: 19.436549 },
    { lat: 48.271699, lng: 19.436495 },
    { lat: 48.2717592, lng: 19.4362625 },
    { lat: 48.2716879, lng: 19.4360419 },
    { lat: 48.2717548, lng: 19.4359538 },
    { lat: 48.271742, lng: 19.4358975 },
    { lat: 48.2716306, lng: 19.4358339 },
    { lat: 48.27148, lng: 19.4361067 },
    { lat: 48.2714288, lng: 19.4359713 },
    { lat: 48.2715594, lng: 19.4357569 },
    { lat: 48.2714982, lng: 19.4356314 },
    { lat: 48.2716288, lng: 19.4355055 },
    { lat: 48.2716729, lng: 19.4357948 },
    { lat: 48.2717046, lng: 19.4356315 },
    { lat: 48.2717943, lng: 19.4355739 },
    { lat: 48.2718185, lng: 19.4354914 },
    { lat: 48.2720802, lng: 19.435618 },
    { lat: 48.2723654, lng: 19.4354258 },
    { lat: 48.273053, lng: 19.4351879 },
    { lat: 48.273767, lng: 19.4346776 },
    { lat: 48.2739545, lng: 19.4343722 },
    { lat: 48.2738142, lng: 19.4341957 },
    { lat: 48.2741171, lng: 19.433922 },
    { lat: 48.2742068, lng: 19.4336769 },
    { lat: 48.2737499, lng: 19.4332621 },
    { lat: 48.2730103, lng: 19.4322974 },
    { lat: 48.2733124, lng: 19.4319871 },
    { lat: 48.2740532, lng: 19.4315662 },
    { lat: 48.2742468, lng: 19.4313695 },
    { lat: 48.2744633, lng: 19.4305824 },
    { lat: 48.2747021, lng: 19.4301022 },
    { lat: 48.2752948, lng: 19.429479 },
    { lat: 48.2756811, lng: 19.4292817 },
    { lat: 48.2759364, lng: 19.4287212 },
    { lat: 48.2761059, lng: 19.4285405 },
    { lat: 48.2764406, lng: 19.4279651 },
    { lat: 48.2767562, lng: 19.4271562 },
    { lat: 48.2772075, lng: 19.4263756 },
    { lat: 48.2773803, lng: 19.4259094 },
    { lat: 48.2775154, lng: 19.4257146 },
    { lat: 48.278007, lng: 19.4252422 },
    { lat: 48.2781439, lng: 19.4249903 },
    { lat: 48.278101, lng: 19.4247012 },
    { lat: 48.2781481, lng: 19.4244783 },
    { lat: 48.2784357, lng: 19.4240777 },
    { lat: 48.2783643, lng: 19.4240244 },
    { lat: 48.2799043, lng: 19.4219091 },
    { lat: 48.2797804, lng: 19.42155 },
    { lat: 48.279499, lng: 19.4215572 },
    { lat: 48.2794004, lng: 19.4215004 },
    { lat: 48.2793439, lng: 19.4213787 },
    { lat: 48.279305, lng: 19.4211514 },
    { lat: 48.2793616, lng: 19.4208238 },
    { lat: 48.2793536, lng: 19.4202953 },
    { lat: 48.2797519, lng: 19.4198522 },
    { lat: 48.2798633, lng: 19.4196369 },
    { lat: 48.2799691, lng: 19.4193918 },
    { lat: 48.2799556, lng: 19.4189156 },
    { lat: 48.2800926, lng: 19.418641 },
    { lat: 48.2806512, lng: 19.4182792 },
    { lat: 48.2809458, lng: 19.4172869 },
    { lat: 48.2811064, lng: 19.4169174 },
    { lat: 48.2813965, lng: 19.4170186 },
    { lat: 48.2815981, lng: 19.4168957 },
    { lat: 48.2820362, lng: 19.4167721 },
    { lat: 48.2822693, lng: 19.4165509 },
    { lat: 48.2824471, lng: 19.4162603 },
    { lat: 48.2824916, lng: 19.4161293 },
    { lat: 48.2824542, lng: 19.4160076 },
    { lat: 48.2822499, lng: 19.4157625 },
    { lat: 48.282294, lng: 19.4154808 },
    { lat: 48.2824, lng: 19.4153142 },
    { lat: 48.2827302, lng: 19.4150516 },
    { lat: 48.2832933, lng: 19.4143276 },
    { lat: 48.2832652, lng: 19.414114 },
    { lat: 48.2831256, lng: 19.4138334 },
    { lat: 48.2828974, lng: 19.4136082 },
    { lat: 48.2829379, lng: 19.4132702 },
    { lat: 48.283166, lng: 19.4130163 },
    { lat: 48.2833018, lng: 19.4129354 },
    { lat: 48.2833176, lng: 19.41291 },
    { lat: 48.28339, lng: 19.4127034 },
    { lat: 48.2833999, lng: 19.4122187 },
    { lat: 48.2835126, lng: 19.4119597 },
    { lat: 48.2838837, lng: 19.4117281 },
    { lat: 48.2842854, lng: 19.4113068 },
    { lat: 48.2847457, lng: 19.4111026 },
    { lat: 48.28521, lng: 19.4105345 },
    { lat: 48.2851675, lng: 19.41047 },
    { lat: 48.284312, lng: 19.4090605 },
    { lat: 48.2838404, lng: 19.4085268 },
    { lat: 48.283112, lng: 19.4077581 },
    { lat: 48.281478, lng: 19.4064797 },
    { lat: 48.2805284, lng: 19.4056076 },
    { lat: 48.2799088, lng: 19.4047585 },
    { lat: 48.2791048, lng: 19.4041802 },
    { lat: 48.2789968, lng: 19.4039239 },
    { lat: 48.2786583, lng: 19.4034843 },
    { lat: 48.2782972, lng: 19.4033596 },
    { lat: 48.2778867, lng: 19.4030449 },
    { lat: 48.2773977, lng: 19.4029292 },
    { lat: 48.2771168, lng: 19.4030157 },
    { lat: 48.2764718, lng: 19.4024192 },
    { lat: 48.2763181, lng: 19.4021454 },
    { lat: 48.276277, lng: 19.401756 },
    { lat: 48.275468, lng: 19.4008079 },
    { lat: 48.2752731, lng: 19.4003843 },
    { lat: 48.2749524, lng: 19.3999666 },
    { lat: 48.2737344, lng: 19.4018277 },
    { lat: 48.272702, lng: 19.403276 },
    { lat: 48.2724059, lng: 19.4035215 },
    { lat: 48.271817, lng: 19.4041764 },
    { lat: 48.2712355, lng: 19.4053427 },
    { lat: 48.271015, lng: 19.4056131 },
    { lat: 48.2704429, lng: 19.4060503 },
    { lat: 48.2697777, lng: 19.4067355 },
    { lat: 48.269042, lng: 19.4073819 },
    { lat: 48.2683922, lng: 19.4078443 },
    { lat: 48.2679241, lng: 19.4078583 },
    { lat: 48.2673975, lng: 19.4077109 },
    { lat: 48.26665, lng: 19.4080885 },
    { lat: 48.2661992, lng: 19.4084449 },
    { lat: 48.2649558, lng: 19.4098911 },
    { lat: 48.2627045, lng: 19.412069 },
    { lat: 48.2618243, lng: 19.4131875 },
    { lat: 48.2617661, lng: 19.4131763 },
    { lat: 48.2612266, lng: 19.4138968 },
    { lat: 48.2604939, lng: 19.4145341 },
    { lat: 48.2596644, lng: 19.4149171 },
    { lat: 48.2583807, lng: 19.4168968 },
  ],
  SlovenskéĎarmoty: [
    { lat: 48.1047186, lng: 19.2604384 },
    { lat: 48.1040345, lng: 19.2600988 },
    { lat: 48.1031783, lng: 19.2595297 },
    { lat: 48.1001588, lng: 19.2573913 },
    { lat: 48.099432, lng: 19.2567966 },
    { lat: 48.0991616, lng: 19.2559237 },
    { lat: 48.0991228, lng: 19.2555449 },
    { lat: 48.0983215, lng: 19.2546488 },
    { lat: 48.0970095, lng: 19.2528745 },
    { lat: 48.0967819, lng: 19.2526923 },
    { lat: 48.0937974, lng: 19.2588124 },
    { lat: 48.0930561, lng: 19.2598588 },
    { lat: 48.0924148, lng: 19.2610826 },
    { lat: 48.0862447, lng: 19.2639498 },
    { lat: 48.0833899, lng: 19.2623002 },
    { lat: 48.0818531, lng: 19.2613421 },
    { lat: 48.0818129, lng: 19.2609834 },
    { lat: 48.0817132, lng: 19.2608085 },
    { lat: 48.081514, lng: 19.2607244 },
    { lat: 48.0805722, lng: 19.260737 },
    { lat: 48.0786993, lng: 19.26183 },
    { lat: 48.0783396, lng: 19.2618068 },
    { lat: 48.0778656, lng: 19.2615622 },
    { lat: 48.0776924, lng: 19.2615701 },
    { lat: 48.076311, lng: 19.2611674 },
    { lat: 48.0761395, lng: 19.2611444 },
    { lat: 48.0760922, lng: 19.2612105 },
    { lat: 48.0755646, lng: 19.2609563 },
    { lat: 48.0743591, lng: 19.260741 },
    { lat: 48.0740031, lng: 19.2608128 },
    { lat: 48.073248, lng: 19.261095 },
    { lat: 48.073282, lng: 19.261225 },
    { lat: 48.073727, lng: 19.262987 },
    { lat: 48.074022, lng: 19.264225 },
    { lat: 48.074192, lng: 19.265166 },
    { lat: 48.074284, lng: 19.266358 },
    { lat: 48.074301, lng: 19.26723 },
    { lat: 48.074251, lng: 19.268226 },
    { lat: 48.074178, lng: 19.268761 },
    { lat: 48.074164, lng: 19.268866 },
    { lat: 48.074156, lng: 19.268924 },
    { lat: 48.074069, lng: 19.269554 },
    { lat: 48.07394, lng: 19.270576 },
    { lat: 48.073639, lng: 19.272657 },
    { lat: 48.073571, lng: 19.273362 },
    { lat: 48.073559, lng: 19.273979 },
    { lat: 48.073593, lng: 19.275357 },
    { lat: 48.073697, lng: 19.275984 },
    { lat: 48.073948, lng: 19.276736 },
    { lat: 48.07467, lng: 19.27827 },
    { lat: 48.075024, lng: 19.27888 },
    { lat: 48.075677, lng: 19.279628 },
    { lat: 48.076195, lng: 19.280062 },
    { lat: 48.077332, lng: 19.28055 },
    { lat: 48.078636, lng: 19.281095 },
    { lat: 48.079603, lng: 19.281526 },
    { lat: 48.080211, lng: 19.281843 },
    { lat: 48.081023, lng: 19.282569 },
    { lat: 48.081638, lng: 19.283268 },
    { lat: 48.082076, lng: 19.283857 },
    { lat: 48.082327, lng: 19.284359 },
    { lat: 48.082888, lng: 19.285917 },
    { lat: 48.083273, lng: 19.286943 },
    { lat: 48.083979, lng: 19.288879 },
    { lat: 48.084557, lng: 19.290477 },
    { lat: 48.086417, lng: 19.295634 },
    { lat: 48.086506, lng: 19.295889 },
    { lat: 48.086594, lng: 19.296135 },
    { lat: 48.086698, lng: 19.296431 },
    { lat: 48.0873109, lng: 19.2981852 },
    { lat: 48.088134, lng: 19.3005 },
    { lat: 48.088285, lng: 19.300955 },
    { lat: 48.088536, lng: 19.302228 },
    { lat: 48.088711, lng: 19.303112 },
    { lat: 48.088711, lng: 19.304001 },
    { lat: 48.088636, lng: 19.305015 },
    { lat: 48.08854, lng: 19.305657 },
    { lat: 48.088406, lng: 19.306549 },
    { lat: 48.088162, lng: 19.307775 },
    { lat: 48.087908, lng: 19.308846 },
    { lat: 48.087709, lng: 19.309682 },
    { lat: 48.087524, lng: 19.310671 },
    { lat: 48.087314, lng: 19.311794 },
    { lat: 48.087259, lng: 19.312939 },
    { lat: 48.087263, lng: 19.314206 },
    { lat: 48.087264, lng: 19.314657 },
    { lat: 48.087413, lng: 19.315737 },
    { lat: 48.08746, lng: 19.316087 },
    { lat: 48.087506, lng: 19.316419 },
    { lat: 48.087634, lng: 19.316887 },
    { lat: 48.087724, lng: 19.317216 },
    { lat: 48.088051, lng: 19.318413 },
    { lat: 48.088103, lng: 19.318633 },
    { lat: 48.089756, lng: 19.324024 },
    { lat: 48.090204, lng: 19.325496 },
    { lat: 48.090254, lng: 19.325666 },
    { lat: 48.0932934, lng: 19.3194757 },
    { lat: 48.0934482, lng: 19.3197855 },
    { lat: 48.0934941, lng: 19.319733 },
    { lat: 48.0936241, lng: 19.319182 },
    { lat: 48.0938958, lng: 19.3186021 },
    { lat: 48.0942221, lng: 19.318238 },
    { lat: 48.0945417, lng: 19.3186697 },
    { lat: 48.0947368, lng: 19.3183962 },
    { lat: 48.0948127, lng: 19.3181927 },
    { lat: 48.0948466, lng: 19.3177494 },
    { lat: 48.0950178, lng: 19.3173738 },
    { lat: 48.095367, lng: 19.3180692 },
    { lat: 48.0956088, lng: 19.3176624 },
    { lat: 48.0957717, lng: 19.3170204 },
    { lat: 48.0957307, lng: 19.3167092 },
    { lat: 48.095815, lng: 19.315702 },
    { lat: 48.0958004, lng: 19.3146768 },
    { lat: 48.0954241, lng: 19.314448 },
    { lat: 48.0952477, lng: 19.3144814 },
    { lat: 48.094912, lng: 19.3144182 },
    { lat: 48.0938391, lng: 19.3148846 },
    { lat: 48.0934277, lng: 19.3140461 },
    { lat: 48.09361, lng: 19.3138784 },
    { lat: 48.0938848, lng: 19.3137592 },
    { lat: 48.0937437, lng: 19.3132632 },
    { lat: 48.0935163, lng: 19.3131526 },
    { lat: 48.093301, lng: 19.3131557 },
    { lat: 48.0931556, lng: 19.3130339 },
    { lat: 48.093111, lng: 19.3127912 },
    { lat: 48.0934196, lng: 19.3120627 },
    { lat: 48.0935, lng: 19.3114201 },
    { lat: 48.0937286, lng: 19.311272 },
    { lat: 48.0938787, lng: 19.3110203 },
    { lat: 48.0942319, lng: 19.3108673 },
    { lat: 48.0947891, lng: 19.3110124 },
    { lat: 48.0953186, lng: 19.3106842 },
    { lat: 48.0957245, lng: 19.3103118 },
    { lat: 48.0958107, lng: 19.3100906 },
    { lat: 48.0958075, lng: 19.3096488 },
    { lat: 48.095724, lng: 19.3093246 },
    { lat: 48.0957902, lng: 19.3091549 },
    { lat: 48.0965637, lng: 19.3095352 },
    { lat: 48.0968749, lng: 19.3092548 },
    { lat: 48.0975054, lng: 19.3091007 },
    { lat: 48.0982064, lng: 19.3079581 },
    { lat: 48.1003851, lng: 19.306163 },
    { lat: 48.1006015, lng: 19.3062091 },
    { lat: 48.1006517, lng: 19.3061286 },
    { lat: 48.1008131, lng: 19.3047145 },
    { lat: 48.101237, lng: 19.3038315 },
    { lat: 48.1019949, lng: 19.3020271 },
    { lat: 48.1014116, lng: 19.3017794 },
    { lat: 48.1022168, lng: 19.3000696 },
    { lat: 48.1024151, lng: 19.2997421 },
    { lat: 48.1031791, lng: 19.2990184 },
    { lat: 48.1033355, lng: 19.2985959 },
    { lat: 48.103592, lng: 19.2981415 },
    { lat: 48.1037315, lng: 19.2973457 },
    { lat: 48.1042548, lng: 19.2960495 },
    { lat: 48.1049565, lng: 19.2937395 },
    { lat: 48.1051587, lng: 19.2925877 },
    { lat: 48.1052493, lng: 19.2925268 },
    { lat: 48.1054271, lng: 19.2919176 },
    { lat: 48.1056656, lng: 19.2913518 },
    { lat: 48.1059383, lng: 19.2908815 },
    { lat: 48.1057497, lng: 19.2899938 },
    { lat: 48.1058102, lng: 19.289338 },
    { lat: 48.1057334, lng: 19.2882678 },
    { lat: 48.1054312, lng: 19.286526 },
    { lat: 48.1051511, lng: 19.285632 },
    { lat: 48.1049486, lng: 19.2838525 },
    { lat: 48.1045928, lng: 19.2820781 },
    { lat: 48.1044591, lng: 19.2816031 },
    { lat: 48.1043726, lng: 19.2794983 },
    { lat: 48.1042632, lng: 19.2787791 },
    { lat: 48.1036255, lng: 19.276306 },
    { lat: 48.1031619, lng: 19.2759049 },
    { lat: 48.1030964, lng: 19.2747239 },
    { lat: 48.1047186, lng: 19.2604384 },
  ],
  Obeckov: [
    { lat: 48.1548761, lng: 19.299089 },
    { lat: 48.1547738, lng: 19.3006102 },
    { lat: 48.1544148, lng: 19.3059539 },
    { lat: 48.1537709, lng: 19.3069741 },
    { lat: 48.1533339, lng: 19.3073979 },
    { lat: 48.1527805, lng: 19.3077994 },
    { lat: 48.1517355, lng: 19.3083556 },
    { lat: 48.151199, lng: 19.3087535 },
    { lat: 48.1490211, lng: 19.3092138 },
    { lat: 48.1557766, lng: 19.3147046 },
    { lat: 48.1568483, lng: 19.315493 },
    { lat: 48.157136, lng: 19.3158012 },
    { lat: 48.1587489, lng: 19.3171293 },
    { lat: 48.1595362, lng: 19.317909 },
    { lat: 48.1597322, lng: 19.3180008 },
    { lat: 48.1598129, lng: 19.3182982 },
    { lat: 48.1596431, lng: 19.3186071 },
    { lat: 48.1595234, lng: 19.3190726 },
    { lat: 48.1593469, lng: 19.3194785 },
    { lat: 48.1593047, lng: 19.3194206 },
    { lat: 48.1588097, lng: 19.3201655 },
    { lat: 48.158544, lng: 19.3204508 },
    { lat: 48.158031, lng: 19.3213607 },
    { lat: 48.1565397, lng: 19.3236246 },
    { lat: 48.155997, lng: 19.3246397 },
    { lat: 48.1559145, lng: 19.3245886 },
    { lat: 48.1558926, lng: 19.3243947 },
    { lat: 48.1556467, lng: 19.324178 },
    { lat: 48.1554052, lng: 19.3245234 },
    { lat: 48.1554926, lng: 19.3245589 },
    { lat: 48.1555109, lng: 19.3246586 },
    { lat: 48.1553298, lng: 19.3247148 },
    { lat: 48.155277, lng: 19.3252055 },
    { lat: 48.1550278, lng: 19.3253227 },
    { lat: 48.1549941, lng: 19.3259324 },
    { lat: 48.1548984, lng: 19.3261668 },
    { lat: 48.1546715, lng: 19.3259541 },
    { lat: 48.1545694, lng: 19.3261479 },
    { lat: 48.154569, lng: 19.3263989 },
    { lat: 48.1546728, lng: 19.3268424 },
    { lat: 48.1546366, lng: 19.3269095 },
    { lat: 48.1544839, lng: 19.3268045 },
    { lat: 48.1543257, lng: 19.3268699 },
    { lat: 48.154191, lng: 19.3272794 },
    { lat: 48.1540486, lng: 19.3273879 },
    { lat: 48.1540584, lng: 19.3277525 },
    { lat: 48.1541995, lng: 19.3280642 },
    { lat: 48.1538905, lng: 19.328341 },
    { lat: 48.1536268, lng: 19.3288301 },
    { lat: 48.1534389, lng: 19.3293769 },
    { lat: 48.1532904, lng: 19.3294504 },
    { lat: 48.1530908, lng: 19.3293321 },
    { lat: 48.1529901, lng: 19.33017 },
    { lat: 48.1529821, lng: 19.3339806 },
    { lat: 48.1534984, lng: 19.3351976 },
    { lat: 48.1538326, lng: 19.3363861 },
    { lat: 48.1545138, lng: 19.3368827 },
    { lat: 48.1556062, lng: 19.3384583 },
    { lat: 48.1577203, lng: 19.3422261 },
    { lat: 48.1578994, lng: 19.3422482 },
    { lat: 48.1579558, lng: 19.3425062 },
    { lat: 48.1582602, lng: 19.3430475 },
    { lat: 48.1584873, lng: 19.3441104 },
    { lat: 48.1591454, lng: 19.3449781 },
    { lat: 48.1602441, lng: 19.3456994 },
    { lat: 48.1604815, lng: 19.3457399 },
    { lat: 48.1606117, lng: 19.3456578 },
    { lat: 48.1645767, lng: 19.3493592 },
    { lat: 48.1653895, lng: 19.3502815 },
    { lat: 48.1663863, lng: 19.3512543 },
    { lat: 48.1666528, lng: 19.3514266 },
    { lat: 48.166751, lng: 19.351618 },
    { lat: 48.1675488, lng: 19.3521101 },
    { lat: 48.1681091, lng: 19.3525786 },
    { lat: 48.1684602, lng: 19.3529206 },
    { lat: 48.1685774, lng: 19.3531107 },
    { lat: 48.1688573, lng: 19.3534945 },
    { lat: 48.1695647, lng: 19.353579 },
    { lat: 48.1697034, lng: 19.3534169 },
    { lat: 48.1697299, lng: 19.3532153 },
    { lat: 48.1700792, lng: 19.3530733 },
    { lat: 48.1707181, lng: 19.3529755 },
    { lat: 48.1707787, lng: 19.3525683 },
    { lat: 48.1708844, lng: 19.3522357 },
    { lat: 48.1713763, lng: 19.3513048 },
    { lat: 48.1718125, lng: 19.350293 },
    { lat: 48.1725417, lng: 19.3481357 },
    { lat: 48.1727369, lng: 19.3473595 },
    { lat: 48.1729297, lng: 19.346895 },
    { lat: 48.1731035, lng: 19.3461701 },
    { lat: 48.1735174, lng: 19.3439244 },
    { lat: 48.17368, lng: 19.343509 },
    { lat: 48.1757116, lng: 19.3415009 },
    { lat: 48.1779016, lng: 19.3395995 },
    { lat: 48.1792244, lng: 19.3386554 },
    { lat: 48.1833714, lng: 19.3360111 },
    { lat: 48.1868514, lng: 19.333678 },
    { lat: 48.184789, lng: 19.3292324 },
    { lat: 48.1845617, lng: 19.3285127 },
    { lat: 48.1831901, lng: 19.325168 },
    { lat: 48.1818049, lng: 19.3222001 },
    { lat: 48.1819912, lng: 19.3216947 },
    { lat: 48.1818397, lng: 19.3212858 },
    { lat: 48.1802571, lng: 19.3187377 },
    { lat: 48.1803573, lng: 19.3185284 },
    { lat: 48.1800919, lng: 19.3179768 },
    { lat: 48.1800376, lng: 19.3176454 },
    { lat: 48.1800175, lng: 19.316997 },
    { lat: 48.1801289, lng: 19.3165502 },
    { lat: 48.1802646, lng: 19.3156714 },
    { lat: 48.1801663, lng: 19.3152204 },
    { lat: 48.1802128, lng: 19.3148099 },
    { lat: 48.180409, lng: 19.3143674 },
    { lat: 48.1803816, lng: 19.3139119 },
    { lat: 48.1785615, lng: 19.3125446 },
    { lat: 48.1780418, lng: 19.3119799 },
    { lat: 48.1784625, lng: 19.3099331 },
    { lat: 48.179018, lng: 19.308181 },
    { lat: 48.1792009, lng: 19.3077671 },
    { lat: 48.1795046, lng: 19.307195 },
    { lat: 48.1797576, lng: 19.3068304 },
    { lat: 48.1812256, lng: 19.3051647 },
    { lat: 48.1818253, lng: 19.3041392 },
    { lat: 48.1821048, lng: 19.3035548 },
    { lat: 48.1827779, lng: 19.3018785 },
    { lat: 48.1822737, lng: 19.3014068 },
    { lat: 48.1817177, lng: 19.3007733 },
    { lat: 48.1817454, lng: 19.3005298 },
    { lat: 48.1815576, lng: 19.300305 },
    { lat: 48.1812554, lng: 19.2998831 },
    { lat: 48.1811285, lng: 19.2997556 },
    { lat: 48.1805202, lng: 19.3009237 },
    { lat: 48.1774668, lng: 19.2976197 },
    { lat: 48.1771004, lng: 19.2987174 },
    { lat: 48.1770367, lng: 19.2987042 },
    { lat: 48.174922, lng: 19.2968156 },
    { lat: 48.1743763, lng: 19.2965325 },
    { lat: 48.1738995, lng: 19.2959976 },
    { lat: 48.1738513, lng: 19.2957235 },
    { lat: 48.1734703, lng: 19.2953279 },
    { lat: 48.1733895, lng: 19.2951728 },
    { lat: 48.1731616, lng: 19.2946311 },
    { lat: 48.1731218, lng: 19.2943706 },
    { lat: 48.1731591, lng: 19.294 },
    { lat: 48.1728591, lng: 19.2939502 },
    { lat: 48.1728702, lng: 19.2935754 },
    { lat: 48.1731286, lng: 19.2927426 },
    { lat: 48.1731572, lng: 19.292333 },
    { lat: 48.1731112, lng: 19.2919641 },
    { lat: 48.17303, lng: 19.2919662 },
    { lat: 48.1723814, lng: 19.2926286 },
    { lat: 48.172169, lng: 19.2928515 },
    { lat: 48.1719848, lng: 19.2931451 },
    { lat: 48.1713573, lng: 19.2936863 },
    { lat: 48.1712915, lng: 19.2936309 },
    { lat: 48.1708568, lng: 19.294082 },
    { lat: 48.1702745, lng: 19.2950215 },
    { lat: 48.1695889, lng: 19.2954573 },
    { lat: 48.1694837, lng: 19.2953324 },
    { lat: 48.1687315, lng: 19.2950908 },
    { lat: 48.1682151, lng: 19.2950895 },
    { lat: 48.1678179, lng: 19.2951258 },
    { lat: 48.1676148, lng: 19.2952152 },
    { lat: 48.1676476, lng: 19.2954274 },
    { lat: 48.1673306, lng: 19.2955828 },
    { lat: 48.1671075, lng: 19.2956338 },
    { lat: 48.1669008, lng: 19.2955083 },
    { lat: 48.1662701, lng: 19.2958675 },
    { lat: 48.1650419, lng: 19.2972288 },
    { lat: 48.1642416, lng: 19.2980159 },
    { lat: 48.163823, lng: 19.2977269 },
    { lat: 48.1630837, lng: 19.2976148 },
    { lat: 48.1628775, lng: 19.2977815 },
    { lat: 48.162323, lng: 19.2977346 },
    { lat: 48.1619302, lng: 19.297978 },
    { lat: 48.1605193, lng: 19.2977772 },
    { lat: 48.160019, lng: 19.2972927 },
    { lat: 48.1591665, lng: 19.2966107 },
    { lat: 48.159046, lng: 19.2958919 },
    { lat: 48.158733, lng: 19.2951319 },
    { lat: 48.158546, lng: 19.2949474 },
    { lat: 48.1583967, lng: 19.29468 },
    { lat: 48.1577997, lng: 19.2953145 },
    { lat: 48.155037, lng: 19.2988764 },
    { lat: 48.1548761, lng: 19.299089 },
  ],
  VeľkéStraciny: [
    { lat: 48.2139209, lng: 19.3839098 },
    { lat: 48.2108809, lng: 19.3822022 },
    { lat: 48.2107425, lng: 19.3826777 },
    { lat: 48.210286, lng: 19.3824158 },
    { lat: 48.2095831, lng: 19.3822131 },
    { lat: 48.2088898, lng: 19.3818782 },
    { lat: 48.2072812, lng: 19.3814283 },
    { lat: 48.2057489, lng: 19.3808685 },
    { lat: 48.2051208, lng: 19.3808175 },
    { lat: 48.2046081, lng: 19.3809817 },
    { lat: 48.2034096, lng: 19.3808361 },
    { lat: 48.2022278, lng: 19.379543 },
    { lat: 48.2006934, lng: 19.378991 },
    { lat: 48.2003791, lng: 19.3788529 },
    { lat: 48.1999109, lng: 19.3786085 },
    { lat: 48.1978882, lng: 19.3776028 },
    { lat: 48.19716, lng: 19.3778098 },
    { lat: 48.1966697, lng: 19.378593 },
    { lat: 48.1961029, lng: 19.3793957 },
    { lat: 48.1959996, lng: 19.3794774 },
    { lat: 48.1952133, lng: 19.3796503 },
    { lat: 48.1944552, lng: 19.3800368 },
    { lat: 48.1942853, lng: 19.3800655 },
    { lat: 48.1940635, lng: 19.3802622 },
    { lat: 48.1931743, lng: 19.380758 },
    { lat: 48.1923314, lng: 19.3808829 },
    { lat: 48.1917991, lng: 19.380591 },
    { lat: 48.1917119, lng: 19.3807921 },
    { lat: 48.1906344, lng: 19.3823521 },
    { lat: 48.1903568, lng: 19.3821086 },
    { lat: 48.1894457, lng: 19.3810847 },
    { lat: 48.1883154, lng: 19.3800259 },
    { lat: 48.1883103, lng: 19.3803207 },
    { lat: 48.1882726, lng: 19.3803816 },
    { lat: 48.188323, lng: 19.3806121 },
    { lat: 48.1882665, lng: 19.3817632 },
    { lat: 48.1883371, lng: 19.3830816 },
    { lat: 48.1884685, lng: 19.3837331 },
    { lat: 48.1885078, lng: 19.3841803 },
    { lat: 48.1885189, lng: 19.385499 },
    { lat: 48.1884289, lng: 19.3862372 },
    { lat: 48.1874817, lng: 19.3903211 },
    { lat: 48.1866421, lng: 19.3942995 },
    { lat: 48.1865296, lng: 19.3947049 },
    { lat: 48.1862205, lng: 19.3952937 },
    { lat: 48.1861021, lng: 19.3966226 },
    { lat: 48.1859776, lng: 19.3970829 },
    { lat: 48.1859025, lng: 19.3979488 },
    { lat: 48.1856593, lng: 19.3984006 },
    { lat: 48.1852144, lng: 19.3994269 },
    { lat: 48.1846557, lng: 19.4002618 },
    { lat: 48.1840801, lng: 19.4004862 },
    { lat: 48.1831866, lng: 19.401274 },
    { lat: 48.1835324, lng: 19.4010077 },
    { lat: 48.1843583, lng: 19.4008571 },
    { lat: 48.1839222, lng: 19.4013583 },
    { lat: 48.1836714, lng: 19.4018455 },
    { lat: 48.1835129, lng: 19.4024221 },
    { lat: 48.1832237, lng: 19.4048553 },
    { lat: 48.1831132, lng: 19.4073229 },
    { lat: 48.1828801, lng: 19.4092498 },
    { lat: 48.1825614, lng: 19.4106122 },
    { lat: 48.1822828, lng: 19.4113148 },
    { lat: 48.1820337, lng: 19.4116954 },
    { lat: 48.1805317, lng: 19.4136536 },
    { lat: 48.1795827, lng: 19.4146748 },
    { lat: 48.1789835, lng: 19.4155858 },
    { lat: 48.1783674, lng: 19.4162408 },
    { lat: 48.1780566, lng: 19.4168638 },
    { lat: 48.1779831, lng: 19.4171922 },
    { lat: 48.1779578, lng: 19.4176797 },
    { lat: 48.1780388, lng: 19.4184611 },
    { lat: 48.1780475, lng: 19.4191932 },
    { lat: 48.1781293, lng: 19.4195408 },
    { lat: 48.1781353, lng: 19.4181899 },
    { lat: 48.1806321, lng: 19.4171775 },
    { lat: 48.182257, lng: 19.4177872 },
    { lat: 48.182648, lng: 19.4182833 },
    { lat: 48.1826532, lng: 19.4184421 },
    { lat: 48.1831289, lng: 19.4191128 },
    { lat: 48.1834667, lng: 19.4197961 },
    { lat: 48.1840296, lng: 19.4205911 },
    { lat: 48.1859192, lng: 19.4242255 },
    { lat: 48.1871471, lng: 19.4261182 },
    { lat: 48.1872979, lng: 19.4263513 },
    { lat: 48.187419, lng: 19.4261947 },
    { lat: 48.1876334, lng: 19.4265334 },
    { lat: 48.1878307, lng: 19.4263709 },
    { lat: 48.1878635, lng: 19.4262544 },
    { lat: 48.1876291, lng: 19.4259175 },
    { lat: 48.1878505, lng: 19.4256447 },
    { lat: 48.1880569, lng: 19.4259104 },
    { lat: 48.1882298, lng: 19.4258257 },
    { lat: 48.1883515, lng: 19.4256317 },
    { lat: 48.1884567, lng: 19.4255937 },
    { lat: 48.1886731, lng: 19.4256662 },
    { lat: 48.1887003, lng: 19.4257403 },
    { lat: 48.1894173, lng: 19.4252664 },
    { lat: 48.1900546, lng: 19.4246596 },
    { lat: 48.1901811, lng: 19.4243792 },
    { lat: 48.1902409, lng: 19.4244043 },
    { lat: 48.1904449, lng: 19.4242634 },
    { lat: 48.190411, lng: 19.4238057 },
    { lat: 48.1902791, lng: 19.4234943 },
    { lat: 48.1903115, lng: 19.4232751 },
    { lat: 48.1905812, lng: 19.4232356 },
    { lat: 48.1906439, lng: 19.4231332 },
    { lat: 48.1906633, lng: 19.4228998 },
    { lat: 48.1902749, lng: 19.4225016 },
    { lat: 48.1903456, lng: 19.4222592 },
    { lat: 48.1907516, lng: 19.4218616 },
    { lat: 48.190837, lng: 19.4217088 },
    { lat: 48.1908562, lng: 19.4215662 },
    { lat: 48.1906033, lng: 19.421132 },
    { lat: 48.1905526, lng: 19.4208199 },
    { lat: 48.1906103, lng: 19.4207555 },
    { lat: 48.1907984, lng: 19.4201344 },
    { lat: 48.1908356, lng: 19.4196331 },
    { lat: 48.1907896, lng: 19.419425 },
    { lat: 48.1908243, lng: 19.4192501 },
    { lat: 48.1907464, lng: 19.4192255 },
    { lat: 48.1907677, lng: 19.4191271 },
    { lat: 48.1908461, lng: 19.4191623 },
    { lat: 48.1908838, lng: 19.4189368 },
    { lat: 48.1909778, lng: 19.4188502 },
    { lat: 48.191202, lng: 19.4181843 },
    { lat: 48.1912967, lng: 19.4181333 },
    { lat: 48.1913594, lng: 19.4176039 },
    { lat: 48.1914518, lng: 19.4174144 },
    { lat: 48.1914554, lng: 19.4170131 },
    { lat: 48.1918703, lng: 19.4169214 },
    { lat: 48.192025, lng: 19.4166433 },
    { lat: 48.192235, lng: 19.4166479 },
    { lat: 48.1924123, lng: 19.4163727 },
    { lat: 48.1926047, lng: 19.416626 },
    { lat: 48.1927705, lng: 19.4164414 },
    { lat: 48.1928483, lng: 19.4162982 },
    { lat: 48.1929005, lng: 19.416044 },
    { lat: 48.1931322, lng: 19.4157256 },
    { lat: 48.1932035, lng: 19.415446 },
    { lat: 48.1932962, lng: 19.4154041 },
    { lat: 48.1934069, lng: 19.4150292 },
    { lat: 48.1933805, lng: 19.4148738 },
    { lat: 48.1934518, lng: 19.4147143 },
    { lat: 48.1934835, lng: 19.4143627 },
    { lat: 48.1937075, lng: 19.4138066 },
    { lat: 48.193902, lng: 19.4134405 },
    { lat: 48.1939921, lng: 19.4133681 },
    { lat: 48.194067, lng: 19.4130454 },
    { lat: 48.1941982, lng: 19.4128178 },
    { lat: 48.1941674, lng: 19.412761 },
    { lat: 48.194262, lng: 19.4126899 },
    { lat: 48.1945003, lng: 19.4130779 },
    { lat: 48.1946009, lng: 19.4127543 },
    { lat: 48.1944134, lng: 19.412102 },
    { lat: 48.1944829, lng: 19.4119045 },
    { lat: 48.1949035, lng: 19.4114343 },
    { lat: 48.1948129, lng: 19.4112132 },
    { lat: 48.1950201, lng: 19.410731 },
    { lat: 48.1949714, lng: 19.4104408 },
    { lat: 48.1953258, lng: 19.4091944 },
    { lat: 48.1952023, lng: 19.4090111 },
    { lat: 48.1954389, lng: 19.4085109 },
    { lat: 48.1955486, lng: 19.4084004 },
    { lat: 48.1956254, lng: 19.4084049 },
    { lat: 48.1956643, lng: 19.4082242 },
    { lat: 48.1956016, lng: 19.407855 },
    { lat: 48.195826, lng: 19.4075705 },
    { lat: 48.1962113, lng: 19.4073286 },
    { lat: 48.196313, lng: 19.4073386 },
    { lat: 48.1968233, lng: 19.4077345 },
    { lat: 48.1970264, lng: 19.4077387 },
    { lat: 48.1971676, lng: 19.4071313 },
    { lat: 48.1973689, lng: 19.406525 },
    { lat: 48.1975031, lng: 19.4062935 },
    { lat: 48.197626, lng: 19.4063384 },
    { lat: 48.1978263, lng: 19.4059772 },
    { lat: 48.1983053, lng: 19.4058822 },
    { lat: 48.1992257, lng: 19.4050426 },
    { lat: 48.1992601, lng: 19.4049104 },
    { lat: 48.1994461, lng: 19.4049146 },
    { lat: 48.1996681, lng: 19.4047695 },
    { lat: 48.2001702, lng: 19.4047342 },
    { lat: 48.2007312, lng: 19.4044171 },
    { lat: 48.2010486, lng: 19.4044321 },
    { lat: 48.2012149, lng: 19.4043527 },
    { lat: 48.2016943, lng: 19.4038856 },
    { lat: 48.2020502, lng: 19.4039506 },
    { lat: 48.2022362, lng: 19.4036891 },
    { lat: 48.2025758, lng: 19.40351 },
    { lat: 48.202543, lng: 19.4031718 },
    { lat: 48.202877, lng: 19.4026841 },
    { lat: 48.2030161, lng: 19.4026701 },
    { lat: 48.2031405, lng: 19.4025152 },
    { lat: 48.203624, lng: 19.4015865 },
    { lat: 48.2037731, lng: 19.4014979 },
    { lat: 48.2039369, lng: 19.4012706 },
    { lat: 48.2041472, lng: 19.4009049 },
    { lat: 48.2041652, lng: 19.4005493 },
    { lat: 48.2043976, lng: 19.4000759 },
    { lat: 48.2045897, lng: 19.3998255 },
    { lat: 48.2047044, lng: 19.3997744 },
    { lat: 48.2052239, lng: 19.3990617 },
    { lat: 48.2055145, lng: 19.3988446 },
    { lat: 48.20621, lng: 19.397994 },
    { lat: 48.2066683, lng: 19.3976979 },
    { lat: 48.2067637, lng: 19.3978068 },
    { lat: 48.2071724, lng: 19.3977288 },
    { lat: 48.2072013, lng: 19.3975525 },
    { lat: 48.2072893, lng: 19.3973909 },
    { lat: 48.2073112, lng: 19.3971176 },
    { lat: 48.2071921, lng: 19.3966741 },
    { lat: 48.207006, lng: 19.3963353 },
    { lat: 48.2071079, lng: 19.3960403 },
    { lat: 48.2070869, lng: 19.3951215 },
    { lat: 48.2072448, lng: 19.3946284 },
    { lat: 48.2074169, lng: 19.3943832 },
    { lat: 48.2079111, lng: 19.3946779 },
    { lat: 48.2082446, lng: 19.3944389 },
    { lat: 48.208371, lng: 19.394253 },
    { lat: 48.2082345, lng: 19.3938506 },
    { lat: 48.2083205, lng: 19.3932391 },
    { lat: 48.2084546, lng: 19.3928622 },
    { lat: 48.2090998, lng: 19.3921611 },
    { lat: 48.2095589, lng: 19.391574 },
    { lat: 48.2097164, lng: 19.3914491 },
    { lat: 48.2101505, lng: 19.3906266 },
    { lat: 48.2105038, lng: 19.3902084 },
    { lat: 48.2108738, lng: 19.3894365 },
    { lat: 48.2111575, lng: 19.3894536 },
    { lat: 48.2112212, lng: 19.3893723 },
    { lat: 48.2115927, lng: 19.3893369 },
    { lat: 48.2119446, lng: 19.3889839 },
    { lat: 48.211907, lng: 19.3885066 },
    { lat: 48.2122541, lng: 19.3881735 },
    { lat: 48.212678, lng: 19.3875573 },
    { lat: 48.2129998, lng: 19.3869802 },
    { lat: 48.213007, lng: 19.3868922 },
    { lat: 48.212917, lng: 19.3867576 },
    { lat: 48.2129939, lng: 19.3865225 },
    { lat: 48.2132802, lng: 19.3858846 },
    { lat: 48.2134361, lng: 19.3856814 },
    { lat: 48.2134769, lng: 19.3855908 },
    { lat: 48.2133352, lng: 19.3852447 },
    { lat: 48.2135266, lng: 19.3848833 },
    { lat: 48.2136448, lng: 19.3843611 },
    { lat: 48.2138819, lng: 19.3839443 },
    { lat: 48.2139209, lng: 19.3839098 },
  ],
  DolnéStrháre: [
    { lat: 48.2134769, lng: 19.3855908 },
    { lat: 48.2136051, lng: 19.3857983 },
    { lat: 48.2138714, lng: 19.3859459 },
    { lat: 48.2140655, lng: 19.3857598 },
    { lat: 48.2146494, lng: 19.3861436 },
    { lat: 48.2172239, lng: 19.3845852 },
    { lat: 48.2182981, lng: 19.3841843 },
    { lat: 48.2188591, lng: 19.3833968 },
    { lat: 48.2203121, lng: 19.383661 },
    { lat: 48.2206147, lng: 19.3835333 },
    { lat: 48.2226556, lng: 19.3833113 },
    { lat: 48.2240035, lng: 19.3851313 },
    { lat: 48.2240852, lng: 19.3851201 },
    { lat: 48.2246962, lng: 19.3856459 },
    { lat: 48.2252786, lng: 19.3856167 },
    { lat: 48.2256284, lng: 19.3854574 },
    { lat: 48.2267988, lng: 19.3871027 },
    { lat: 48.2281705, lng: 19.3881205 },
    { lat: 48.2290125, lng: 19.3880021 },
    { lat: 48.2296925, lng: 19.3880113 },
    { lat: 48.2308777, lng: 19.3896117 },
    { lat: 48.2320214, lng: 19.3910481 },
    { lat: 48.2326373, lng: 19.3916294 },
    { lat: 48.2338383, lng: 19.3930459 },
    { lat: 48.2343496, lng: 19.3938399 },
    { lat: 48.2344968, lng: 19.393832 },
    { lat: 48.2346509, lng: 19.393824 },
    { lat: 48.2348221, lng: 19.3941569 },
    { lat: 48.2350349, lng: 19.3949105 },
    { lat: 48.2352432, lng: 19.3952836 },
    { lat: 48.2352439, lng: 19.3958198 },
    { lat: 48.2351322, lng: 19.396601 },
    { lat: 48.234905, lng: 19.3971325 },
    { lat: 48.234801, lng: 19.3975041 },
    { lat: 48.2348902, lng: 19.3978153 },
    { lat: 48.2348241, lng: 19.3984187 },
    { lat: 48.2344618, lng: 19.3993828 },
    { lat: 48.2350362, lng: 19.3998961 },
    { lat: 48.2351488, lng: 19.4003416 },
    { lat: 48.2351824, lng: 19.4007923 },
    { lat: 48.2352649, lng: 19.4007227 },
    { lat: 48.235541, lng: 19.4008425 },
    { lat: 48.2356218, lng: 19.4007639 },
    { lat: 48.235834, lng: 19.4009613 },
    { lat: 48.2358929, lng: 19.4011806 },
    { lat: 48.236116, lng: 19.4013438 },
    { lat: 48.2361162, lng: 19.4015174 },
    { lat: 48.2362439, lng: 19.401571 },
    { lat: 48.2363165, lng: 19.401343 },
    { lat: 48.236521, lng: 19.4014217 },
    { lat: 48.2366714, lng: 19.4011282 },
    { lat: 48.2369646, lng: 19.4016283 },
    { lat: 48.2379404, lng: 19.4026216 },
    { lat: 48.2378729, lng: 19.4026974 },
    { lat: 48.2378818, lng: 19.4028103 },
    { lat: 48.2377378, lng: 19.4030798 },
    { lat: 48.2377317, lng: 19.4033742 },
    { lat: 48.2376656, lng: 19.4034109 },
    { lat: 48.2376233, lng: 19.4032998 },
    { lat: 48.237455, lng: 19.4037695 },
    { lat: 48.2373599, lng: 19.4038313 },
    { lat: 48.2374016, lng: 19.4039739 },
    { lat: 48.2373732, lng: 19.4041367 },
    { lat: 48.23733, lng: 19.4040823 },
    { lat: 48.2372567, lng: 19.4041254 },
    { lat: 48.2372734, lng: 19.4042438 },
    { lat: 48.2371007, lng: 19.4047812 },
    { lat: 48.2370923, lng: 19.4049366 },
    { lat: 48.2370179, lng: 19.4051754 },
    { lat: 48.2369326, lng: 19.4052293 },
    { lat: 48.2368995, lng: 19.405458 },
    { lat: 48.2368221, lng: 19.405538 },
    { lat: 48.2375249, lng: 19.4061704 },
    { lat: 48.237897, lng: 19.4066516 },
    { lat: 48.2382808, lng: 19.4069202 },
    { lat: 48.2406465, lng: 19.4050718 },
    { lat: 48.2413128, lng: 19.4043352 },
    { lat: 48.2420007, lng: 19.4038459 },
    { lat: 48.2423926, lng: 19.4036724 },
    { lat: 48.2433488, lng: 19.4047277 },
    { lat: 48.242825, lng: 19.4061831 },
    { lat: 48.2425302, lng: 19.4066006 },
    { lat: 48.2431341, lng: 19.4085433 },
    { lat: 48.2433974, lng: 19.4084616 },
    { lat: 48.2437621, lng: 19.4081202 },
    { lat: 48.244387, lng: 19.4077006 },
    { lat: 48.2447304, lng: 19.4073862 },
    { lat: 48.2448832, lng: 19.4071383 },
    { lat: 48.2451393, lng: 19.4069997 },
    { lat: 48.2452539, lng: 19.4070166 },
    { lat: 48.2467637, lng: 19.4062214 },
    { lat: 48.247034, lng: 19.4061948 },
    { lat: 48.2471682, lng: 19.4057484 },
    { lat: 48.2472798, lng: 19.4056757 },
    { lat: 48.2478765, lng: 19.4061113 },
    { lat: 48.2480755, lng: 19.4056021 },
    { lat: 48.2486793, lng: 19.4049577 },
    { lat: 48.2489496, lng: 19.4048306 },
    { lat: 48.2491936, lng: 19.4051046 },
    { lat: 48.2496987, lng: 19.4048928 },
    { lat: 48.2498922, lng: 19.4046505 },
    { lat: 48.2499118, lng: 19.4043029 },
    { lat: 48.2502635, lng: 19.4040636 },
    { lat: 48.2504888, lng: 19.4043477 },
    { lat: 48.2505282, lng: 19.4044665 },
    { lat: 48.250343, lng: 19.4048643 },
    { lat: 48.2504092, lng: 19.4051649 },
    { lat: 48.2505864, lng: 19.4054088 },
    { lat: 48.2507133, lng: 19.4054462 },
    { lat: 48.2508909, lng: 19.4053655 },
    { lat: 48.2509345, lng: 19.4049064 },
    { lat: 48.2510983, lng: 19.4046305 },
    { lat: 48.2512456, lng: 19.4046428 },
    { lat: 48.2514103, lng: 19.4048396 },
    { lat: 48.251448, lng: 19.4049919 },
    { lat: 48.2513996, lng: 19.4052067 },
    { lat: 48.2514873, lng: 19.4053915 },
    { lat: 48.2519737, lng: 19.4054558 },
    { lat: 48.2522384, lng: 19.405334 },
    { lat: 48.2523745, lng: 19.4049217 },
    { lat: 48.2525188, lng: 19.4047769 },
    { lat: 48.2527983, lng: 19.4046846 },
    { lat: 48.2531457, lng: 19.4049284 },
    { lat: 48.2533155, lng: 19.4049677 },
    { lat: 48.2536866, lng: 19.4046414 },
    { lat: 48.253816, lng: 19.4043274 },
    { lat: 48.2543055, lng: 19.4035033 },
    { lat: 48.2546138, lng: 19.4032569 },
    { lat: 48.2549458, lng: 19.4032449 },
    { lat: 48.2554263, lng: 19.4036805 },
    { lat: 48.2557102, lng: 19.4037256 },
    { lat: 48.2560615, lng: 19.4049084 },
    { lat: 48.2573264, lng: 19.4084557 },
    { lat: 48.2572279, lng: 19.4095159 },
    { lat: 48.2573167, lng: 19.4102488 },
    { lat: 48.25753, lng: 19.4107337 },
    { lat: 48.2574799, lng: 19.4109335 },
    { lat: 48.257523, lng: 19.4110106 },
    { lat: 48.2575494, lng: 19.4118082 },
    { lat: 48.257635, lng: 19.4121386 },
    { lat: 48.2576189, lng: 19.4123034 },
    { lat: 48.2576901, lng: 19.4129233 },
    { lat: 48.2576565, lng: 19.413167 },
    { lat: 48.2579301, lng: 19.4139482 },
    { lat: 48.2580401, lng: 19.4146039 },
    { lat: 48.2579688, lng: 19.4149786 },
    { lat: 48.257982, lng: 19.4154936 },
    { lat: 48.2580531, lng: 19.4155681 },
    { lat: 48.2583725, lng: 19.4168626 },
    { lat: 48.2583807, lng: 19.4168968 },
    { lat: 48.2596644, lng: 19.4149171 },
    { lat: 48.2604939, lng: 19.4145341 },
    { lat: 48.2612266, lng: 19.4138968 },
    { lat: 48.2617661, lng: 19.4131763 },
    { lat: 48.2618243, lng: 19.4131875 },
    { lat: 48.2627045, lng: 19.412069 },
    { lat: 48.2649558, lng: 19.4098911 },
    { lat: 48.2661992, lng: 19.4084449 },
    { lat: 48.26665, lng: 19.4080885 },
    { lat: 48.2673975, lng: 19.4077109 },
    { lat: 48.2679241, lng: 19.4078583 },
    { lat: 48.2683922, lng: 19.4078443 },
    { lat: 48.269042, lng: 19.4073819 },
    { lat: 48.2697777, lng: 19.4067355 },
    { lat: 48.2704429, lng: 19.4060503 },
    { lat: 48.271015, lng: 19.4056131 },
    { lat: 48.2712355, lng: 19.4053427 },
    { lat: 48.271817, lng: 19.4041764 },
    { lat: 48.2724059, lng: 19.4035215 },
    { lat: 48.272702, lng: 19.403276 },
    { lat: 48.2737344, lng: 19.4018277 },
    { lat: 48.2749524, lng: 19.3999666 },
    { lat: 48.2751199, lng: 19.3994605 },
    { lat: 48.275731, lng: 19.3982025 },
    { lat: 48.2759462, lng: 19.3973736 },
    { lat: 48.2765207, lng: 19.3956336 },
    { lat: 48.276843, lng: 19.3948017 },
    { lat: 48.2811294, lng: 19.3899382 },
    { lat: 48.281249, lng: 19.3888533 },
    { lat: 48.28164, lng: 19.3805775 },
    { lat: 48.2898346, lng: 19.3700913 },
    { lat: 48.288965, lng: 19.3663934 },
    { lat: 48.2874913, lng: 19.3617981 },
    { lat: 48.2868986, lng: 19.3592807 },
    { lat: 48.2864419, lng: 19.357716 },
    { lat: 48.286124, lng: 19.3566095 },
    { lat: 48.285588, lng: 19.3565963 },
    { lat: 48.2851049, lng: 19.3565798 },
    { lat: 48.2850195, lng: 19.3566569 },
    { lat: 48.2845964, lng: 19.356788 },
    { lat: 48.2845801, lng: 19.3570148 },
    { lat: 48.2843816, lng: 19.3570828 },
    { lat: 48.2838406, lng: 19.3569406 },
    { lat: 48.283191, lng: 19.3573588 },
    { lat: 48.2827972, lng: 19.3574652 },
    { lat: 48.2821188, lng: 19.3583635 },
    { lat: 48.2812669, lng: 19.3599694 },
    { lat: 48.2810727, lng: 19.3602266 },
    { lat: 48.2808179, lng: 19.3608831 },
    { lat: 48.2804537, lng: 19.3622247 },
    { lat: 48.2802587, lng: 19.3626008 },
    { lat: 48.2798158, lng: 19.362937 },
    { lat: 48.2795378, lng: 19.3633419 },
    { lat: 48.2791114, lng: 19.3645627 },
    { lat: 48.2785149, lng: 19.3660487 },
    { lat: 48.2781582, lng: 19.3666506 },
    { lat: 48.2775912, lng: 19.3673107 },
    { lat: 48.277471, lng: 19.3677163 },
    { lat: 48.2773428, lng: 19.3679158 },
    { lat: 48.2771646, lng: 19.3680757 },
    { lat: 48.2763465, lng: 19.368458 },
    { lat: 48.2762194, lng: 19.3684366 },
    { lat: 48.2753719, lng: 19.3688911 },
    { lat: 48.2743189, lng: 19.3699 },
    { lat: 48.2737225, lng: 19.3702992 },
    { lat: 48.2730335, lng: 19.3711393 },
    { lat: 48.2728366, lng: 19.3715478 },
    { lat: 48.272475, lng: 19.3725919 },
    { lat: 48.2719732, lng: 19.3734172 },
    { lat: 48.271833, lng: 19.3737864 },
    { lat: 48.271541, lng: 19.3742796 },
    { lat: 48.2713852, lng: 19.3746533 },
    { lat: 48.2712324, lng: 19.374757 },
    { lat: 48.2708588, lng: 19.3751978 },
    { lat: 48.270529, lng: 19.375677 },
    { lat: 48.2702913, lng: 19.3762695 },
    { lat: 48.2692981, lng: 19.3780872 },
    { lat: 48.2668563, lng: 19.3817251 },
    { lat: 48.266264, lng: 19.3835323 },
    { lat: 48.2656849, lng: 19.3848424 },
    { lat: 48.2655326, lng: 19.3850971 },
    { lat: 48.2647778, lng: 19.3859608 },
    { lat: 48.2645781, lng: 19.3862853 },
    { lat: 48.2641996, lng: 19.3867392 },
    { lat: 48.2634735, lng: 19.3874915 },
    { lat: 48.2631863, lng: 19.3880892 },
    { lat: 48.2627671, lng: 19.3876408 },
    { lat: 48.2628533, lng: 19.387488 },
    { lat: 48.2624063, lng: 19.3870476 },
    { lat: 48.2617147, lng: 19.3859378 },
    { lat: 48.2614958, lng: 19.3860086 },
    { lat: 48.2611656, lng: 19.3862231 },
    { lat: 48.2604166, lng: 19.387135 },
    { lat: 48.2603751, lng: 19.3870902 },
    { lat: 48.2589817, lng: 19.3852104 },
    { lat: 48.2551584, lng: 19.3803538 },
    { lat: 48.2540764, lng: 19.3788156 },
    { lat: 48.2529675, lng: 19.3773536 },
    { lat: 48.2524476, lng: 19.3767768 },
    { lat: 48.2518522, lng: 19.3758761 },
    { lat: 48.2518271, lng: 19.3757493 },
    { lat: 48.2520014, lng: 19.3746309 },
    { lat: 48.2522587, lng: 19.3736181 },
    { lat: 48.2522738, lng: 19.37337 },
    { lat: 48.2522545, lng: 19.3722574 },
    { lat: 48.2521691, lng: 19.3714811 },
    { lat: 48.2521393, lng: 19.369229 },
    { lat: 48.2520688, lng: 19.3687408 },
    { lat: 48.2519969, lng: 19.3675673 },
    { lat: 48.2520453, lng: 19.3669043 },
    { lat: 48.2518112, lng: 19.3669368 },
    { lat: 48.2508745, lng: 19.3665576 },
    { lat: 48.2505215, lng: 19.3666473 },
    { lat: 48.2499669, lng: 19.3661034 },
    { lat: 48.2494261, lng: 19.3658466 },
    { lat: 48.2490686, lng: 19.3657491 },
    { lat: 48.2488432, lng: 19.3655596 },
    { lat: 48.2485793, lng: 19.3648467 },
    { lat: 48.2485788, lng: 19.364486 },
    { lat: 48.2488066, lng: 19.3638934 },
    { lat: 48.248948, lng: 19.3632951 },
    { lat: 48.2490552, lng: 19.3630612 },
    { lat: 48.2492987, lng: 19.3628738 },
    { lat: 48.2493437, lng: 19.3627279 },
    { lat: 48.2495687, lng: 19.3623901 },
    { lat: 48.2502866, lng: 19.3606197 },
    { lat: 48.2504542, lng: 19.3603301 },
    { lat: 48.2499663, lng: 19.3597943 },
    { lat: 48.2497797, lng: 19.3591912 },
    { lat: 48.2501266, lng: 19.3589722 },
    { lat: 48.2503207, lng: 19.3585522 },
    { lat: 48.2506465, lng: 19.3582725 },
    { lat: 48.2508997, lng: 19.3581442 },
    { lat: 48.2516967, lng: 19.3573225 },
    { lat: 48.2519539, lng: 19.3569755 },
    { lat: 48.2528122, lng: 19.3552195 },
    { lat: 48.2533276, lng: 19.3543769 },
    { lat: 48.2535931, lng: 19.3540358 },
    { lat: 48.253739, lng: 19.3536937 },
    { lat: 48.2535982, lng: 19.3532257 },
    { lat: 48.2534618, lng: 19.3529412 },
    { lat: 48.2531703, lng: 19.3531617 },
    { lat: 48.2529078, lng: 19.3519407 },
    { lat: 48.2526367, lng: 19.3514089 },
    { lat: 48.2526096, lng: 19.3507742 },
    { lat: 48.2526825, lng: 19.3503696 },
    { lat: 48.2529305, lng: 19.3500869 },
    { lat: 48.2531635, lng: 19.3500311 },
    { lat: 48.2536687, lng: 19.3496614 },
    { lat: 48.2547786, lng: 19.3492783 },
    { lat: 48.2552901, lng: 19.3486596 },
    { lat: 48.2557707, lng: 19.347919 },
    { lat: 48.2551551, lng: 19.3479866 },
    { lat: 48.2548074, lng: 19.3483019 },
    { lat: 48.2544463, lng: 19.3482922 },
    { lat: 48.2541425, lng: 19.3485873 },
    { lat: 48.2535757, lng: 19.3486627 },
    { lat: 48.2531526, lng: 19.3489352 },
    { lat: 48.2529891, lng: 19.3489542 },
    { lat: 48.2526709, lng: 19.349116 },
    { lat: 48.252075, lng: 19.3493077 },
    { lat: 48.2515734, lng: 19.3492604 },
    { lat: 48.2512492, lng: 19.3493412 },
    { lat: 48.2506182, lng: 19.3496477 },
    { lat: 48.2496482, lng: 19.3506494 },
    { lat: 48.2493671, lng: 19.3511316 },
    { lat: 48.2490552, lng: 19.3512089 },
    { lat: 48.2487237, lng: 19.3514436 },
    { lat: 48.2485942, lng: 19.3514469 },
    { lat: 48.2483976, lng: 19.3516964 },
    { lat: 48.2484112, lng: 19.351742 },
    { lat: 48.2481718, lng: 19.3519481 },
    { lat: 48.2482289, lng: 19.3520537 },
    { lat: 48.2476736, lng: 19.3523442 },
    { lat: 48.2475503, lng: 19.3525215 },
    { lat: 48.2475, lng: 19.3528632 },
    { lat: 48.2473909, lng: 19.3531253 },
    { lat: 48.2462612, lng: 19.3547225 },
    { lat: 48.2459836, lng: 19.3551604 },
    { lat: 48.2458163, lng: 19.3555531 },
    { lat: 48.2457603, lng: 19.3554205 },
    { lat: 48.245543, lng: 19.3557059 },
    { lat: 48.2455433, lng: 19.3558314 },
    { lat: 48.2450653, lng: 19.3561818 },
    { lat: 48.2448726, lng: 19.3562268 },
    { lat: 48.2445296, lng: 19.3560769 },
    { lat: 48.2440198, lng: 19.3560252 },
    { lat: 48.2433126, lng: 19.3560837 },
    { lat: 48.2423429, lng: 19.3564073 },
    { lat: 48.2410876, lng: 19.3570741 },
    { lat: 48.2408709, lng: 19.3577033 },
    { lat: 48.2408348, lng: 19.3576301 },
    { lat: 48.2401221, lng: 19.3575527 },
    { lat: 48.2398871, lng: 19.3576129 },
    { lat: 48.2396799, lng: 19.3575464 },
    { lat: 48.2394274, lng: 19.3572599 },
    { lat: 48.2391294, lng: 19.3570882 },
    { lat: 48.2389506, lng: 19.3571183 },
    { lat: 48.2385479, lng: 19.3573948 },
    { lat: 48.2384178, lng: 19.3581162 },
    { lat: 48.2373298, lng: 19.3601371 },
    { lat: 48.2373605, lng: 19.3601663 },
    { lat: 48.2366348, lng: 19.3617247 },
    { lat: 48.2361772, lng: 19.362548 },
    { lat: 48.2360513, lng: 19.3627697 },
    { lat: 48.2357664, lng: 19.3629615 },
    { lat: 48.2349986, lng: 19.3631936 },
    { lat: 48.234812, lng: 19.3630163 },
    { lat: 48.2346016, lng: 19.3625514 },
    { lat: 48.2337621, lng: 19.3590449 },
    { lat: 48.2333852, lng: 19.3583482 },
    { lat: 48.2331444, lng: 19.358806 },
    { lat: 48.2326591, lng: 19.3593851 },
    { lat: 48.2324253, lng: 19.3593939 },
    { lat: 48.2318465, lng: 19.3589366 },
    { lat: 48.2312187, lng: 19.3582315 },
    { lat: 48.2306957, lng: 19.3585354 },
    { lat: 48.2286943, lng: 19.3601207 },
    { lat: 48.2267673, lng: 19.3611886 },
    { lat: 48.2260153, lng: 19.3631189 },
    { lat: 48.2250906, lng: 19.3662658 },
    { lat: 48.2252914, lng: 19.3689822 },
    { lat: 48.2248613, lng: 19.3697479 },
    { lat: 48.2247827, lng: 19.3699673 },
    { lat: 48.224737, lng: 19.3703108 },
    { lat: 48.2245402, lng: 19.3708376 },
    { lat: 48.2241913, lng: 19.3712036 },
    { lat: 48.2240153, lng: 19.3716915 },
    { lat: 48.2238686, lng: 19.3719486 },
    { lat: 48.2236482, lng: 19.3722192 },
    { lat: 48.2235605, lng: 19.3721303 },
    { lat: 48.2234674, lng: 19.3722827 },
    { lat: 48.2234446, lng: 19.3724184 },
    { lat: 48.2232107, lng: 19.3727831 },
    { lat: 48.2229425, lng: 19.3727852 },
    { lat: 48.2228627, lng: 19.3734042 },
    { lat: 48.2226777, lng: 19.373754 },
    { lat: 48.2226899, lng: 19.3739143 },
    { lat: 48.2225957, lng: 19.3740636 },
    { lat: 48.2219983, lng: 19.3746325 },
    { lat: 48.2216537, lng: 19.3751122 },
    { lat: 48.2214854, lng: 19.375104 },
    { lat: 48.2211203, lng: 19.37546 },
    { lat: 48.220944, lng: 19.3759436 },
    { lat: 48.2205834, lng: 19.3763956 },
    { lat: 48.2201217, lng: 19.3766443 },
    { lat: 48.2195883, lng: 19.3771763 },
    { lat: 48.2193057, lng: 19.3771386 },
    { lat: 48.2188143, lng: 19.3777749 },
    { lat: 48.2185126, lng: 19.3779513 },
    { lat: 48.2184155, lng: 19.3781578 },
    { lat: 48.2181962, lng: 19.3784046 },
    { lat: 48.2178355, lng: 19.37857 },
    { lat: 48.2173913, lng: 19.379132 },
    { lat: 48.2170499, lng: 19.3797731 },
    { lat: 48.2165836, lng: 19.3803257 },
    { lat: 48.2163769, lng: 19.3804598 },
    { lat: 48.2162882, lng: 19.3803735 },
    { lat: 48.2157711, lng: 19.3806574 },
    { lat: 48.2150188, lng: 19.3808603 },
    { lat: 48.2149877, lng: 19.3818375 },
    { lat: 48.2148805, lng: 19.3816253 },
    { lat: 48.2148783, lng: 19.3820503 },
    { lat: 48.2147845, lng: 19.3822373 },
    { lat: 48.2145389, lng: 19.3823572 },
    { lat: 48.2145117, lng: 19.3827315 },
    { lat: 48.2144224, lng: 19.3829163 },
    { lat: 48.214324, lng: 19.382977 },
    { lat: 48.2141468, lng: 19.3833962 },
    { lat: 48.2141535, lng: 19.3835383 },
    { lat: 48.2139915, lng: 19.3838051 },
    { lat: 48.2139209, lng: 19.3839098 },
    { lat: 48.2138819, lng: 19.3839443 },
    { lat: 48.2136448, lng: 19.3843611 },
    { lat: 48.2135266, lng: 19.3848833 },
    { lat: 48.2133352, lng: 19.3852447 },
    { lat: 48.2134769, lng: 19.3855908 },
  ],
  StrednéPlachtince: [
    { lat: 48.2722541, lng: 19.2555703 },
    { lat: 48.2716592, lng: 19.2561989 },
    { lat: 48.2713265, lng: 19.2563797 },
    { lat: 48.2708674, lng: 19.256795 },
    { lat: 48.2704316, lng: 19.2570988 },
    { lat: 48.2695781, lng: 19.25733 },
    { lat: 48.2691289, lng: 19.2575256 },
    { lat: 48.2685507, lng: 19.2580112 },
    { lat: 48.2675945, lng: 19.2590504 },
    { lat: 48.2672034, lng: 19.2595789 },
    { lat: 48.2668869, lng: 19.2601862 },
    { lat: 48.2649715, lng: 19.261225 },
    { lat: 48.2635047, lng: 19.2618419 },
    { lat: 48.2632968, lng: 19.2622059 },
    { lat: 48.2631344, lng: 19.2622286 },
    { lat: 48.2625452, lng: 19.2621189 },
    { lat: 48.26226, lng: 19.2624429 },
    { lat: 48.2619674, lng: 19.2630791 },
    { lat: 48.261829, lng: 19.2632438 },
    { lat: 48.2615152, lng: 19.263422 },
    { lat: 48.2612473, lng: 19.2637516 },
    { lat: 48.2609824, lng: 19.2639376 },
    { lat: 48.2603463, lng: 19.2641849 },
    { lat: 48.2596987, lng: 19.2645223 },
    { lat: 48.2591278, lng: 19.2644643 },
    { lat: 48.2587834, lng: 19.2644973 },
    { lat: 48.2584518, lng: 19.2646357 },
    { lat: 48.2570239, lng: 19.2658686 },
    { lat: 48.2560605, lng: 19.2670107 },
    { lat: 48.2553477, lng: 19.2676724 },
    { lat: 48.2546948, lng: 19.2684358 },
    { lat: 48.2543811, lng: 19.2689167 },
    { lat: 48.2541584, lng: 19.2693884 },
    { lat: 48.2522093, lng: 19.27166 },
    { lat: 48.251555, lng: 19.2734824 },
    { lat: 48.2511043, lng: 19.2743177 },
    { lat: 48.250977, lng: 19.2745091 },
    { lat: 48.2508952, lng: 19.2745163 },
    { lat: 48.2507339, lng: 19.2757423 },
    { lat: 48.2506894, lng: 19.2766614 },
    { lat: 48.2506406, lng: 19.276793 },
    { lat: 48.2498622, lng: 19.2777113 },
    { lat: 48.2496209, lng: 19.2781276 },
    { lat: 48.2493999, lng: 19.2781977 },
    { lat: 48.2489054, lng: 19.27818 },
    { lat: 48.2477769, lng: 19.2784287 },
    { lat: 48.2475827, lng: 19.2785889 },
    { lat: 48.2471404, lng: 19.2793227 },
    { lat: 48.2468912, lng: 19.2801349 },
    { lat: 48.2464935, lng: 19.2805541 },
    { lat: 48.2463416, lng: 19.2808602 },
    { lat: 48.2459064, lng: 19.2813252 },
    { lat: 48.2457194, lng: 19.2816299 },
    { lat: 48.2456269, lng: 19.2818836 },
    { lat: 48.244976, lng: 19.2828504 },
    { lat: 48.2447288, lng: 19.2831436 },
    { lat: 48.2444716, lng: 19.2833243 },
    { lat: 48.2441475, lng: 19.2833588 },
    { lat: 48.2441448, lng: 19.2835123 },
    { lat: 48.2432717, lng: 19.2844558 },
    { lat: 48.2432733, lng: 19.2845812 },
    { lat: 48.2427787, lng: 19.2845628 },
    { lat: 48.2427462, lng: 19.2841254 },
    { lat: 48.2423823, lng: 19.2838927 },
    { lat: 48.2418446, lng: 19.2837541 },
    { lat: 48.2417798, lng: 19.2836756 },
    { lat: 48.2410463, lng: 19.284424 },
    { lat: 48.2411307, lng: 19.2846047 },
    { lat: 48.2405329, lng: 19.2852726 },
    { lat: 48.240341, lng: 19.28513 },
    { lat: 48.240314, lng: 19.2849675 },
    { lat: 48.2399615, lng: 19.2850199 },
    { lat: 48.2394843, lng: 19.2848229 },
    { lat: 48.2392724, lng: 19.2848469 },
    { lat: 48.2390682, lng: 19.2850042 },
    { lat: 48.2386632, lng: 19.2857225 },
    { lat: 48.2384161, lng: 19.2860633 },
    { lat: 48.2381104, lng: 19.2863638 },
    { lat: 48.2379492, lng: 19.2866646 },
    { lat: 48.2377913, lng: 19.2872234 },
    { lat: 48.2376422, lng: 19.2874203 },
    { lat: 48.2364457, lng: 19.2870616 },
    { lat: 48.2357781, lng: 19.2873568 },
    { lat: 48.235992, lng: 19.2879357 },
    { lat: 48.2356651, lng: 19.2880723 },
    { lat: 48.2354887, lng: 19.2876937 },
    { lat: 48.2352141, lng: 19.2874899 },
    { lat: 48.2350075, lng: 19.2871117 },
    { lat: 48.2349075, lng: 19.2870953 },
    { lat: 48.2346447, lng: 19.2872766 },
    { lat: 48.2343433, lng: 19.287735 },
    { lat: 48.2340655, lng: 19.2880031 },
    { lat: 48.2337723, lng: 19.2881372 },
    { lat: 48.233455, lng: 19.2881013 },
    { lat: 48.2333876, lng: 19.2881793 },
    { lat: 48.2332522, lng: 19.2879921 },
    { lat: 48.2330966, lng: 19.2879888 },
    { lat: 48.2330129, lng: 19.2881227 },
    { lat: 48.2329258, lng: 19.2879982 },
    { lat: 48.2325204, lng: 19.286889 },
    { lat: 48.2320537, lng: 19.2853123 },
    { lat: 48.2322398, lng: 19.2852433 },
    { lat: 48.2320232, lng: 19.2846838 },
    { lat: 48.2318489, lng: 19.2836213 },
    { lat: 48.23202, lng: 19.2830335 },
    { lat: 48.2323239, lng: 19.2824924 },
    { lat: 48.2324736, lng: 19.2821155 },
    { lat: 48.232438, lng: 19.282053 },
    { lat: 48.2325021, lng: 19.2812371 },
    { lat: 48.2324379, lng: 19.2807961 },
    { lat: 48.2325016, lng: 19.2805756 },
    { lat: 48.2325098, lng: 19.2801657 },
    { lat: 48.2324711, lng: 19.2800207 },
    { lat: 48.2325193, lng: 19.2795969 },
    { lat: 48.2324498, lng: 19.2794393 },
    { lat: 48.2324768, lng: 19.2791832 },
    { lat: 48.2324236, lng: 19.2788761 },
    { lat: 48.2323826, lng: 19.2778705 },
    { lat: 48.232322, lng: 19.2777619 },
    { lat: 48.2323349, lng: 19.2772825 },
    { lat: 48.2322426, lng: 19.2771723 },
    { lat: 48.232201, lng: 19.2768445 },
    { lat: 48.2322365, lng: 19.2767632 },
    { lat: 48.2319963, lng: 19.2765298 },
    { lat: 48.2319494, lng: 19.2763101 },
    { lat: 48.2319351, lng: 19.2757562 },
    { lat: 48.2315823, lng: 19.2753898 },
    { lat: 48.2310152, lng: 19.2751086 },
    { lat: 48.2309671, lng: 19.2749484 },
    { lat: 48.2308187, lng: 19.2748461 },
    { lat: 48.2307089, lng: 19.2748803 },
    { lat: 48.2304629, lng: 19.2745532 },
    { lat: 48.2303437, lng: 19.2745549 },
    { lat: 48.2302894, lng: 19.2744826 },
    { lat: 48.230285, lng: 19.2743908 },
    { lat: 48.2301835, lng: 19.274252 },
    { lat: 48.2302181, lng: 19.273918 },
    { lat: 48.2300429, lng: 19.2732152 },
    { lat: 48.2298439, lng: 19.2730124 },
    { lat: 48.2298308, lng: 19.2726765 },
    { lat: 48.2297727, lng: 19.2725485 },
    { lat: 48.2298394, lng: 19.2723913 },
    { lat: 48.2298412, lng: 19.2721953 },
    { lat: 48.2297903, lng: 19.272075 },
    { lat: 48.2298275, lng: 19.2717962 },
    { lat: 48.2300029, lng: 19.2715809 },
    { lat: 48.2300791, lng: 19.2711996 },
    { lat: 48.2302316, lng: 19.2709978 },
    { lat: 48.2305115, lng: 19.2702846 },
    { lat: 48.2307761, lng: 19.2689824 },
    { lat: 48.2308048, lng: 19.2683213 },
    { lat: 48.2308594, lng: 19.2678886 },
    { lat: 48.2309092, lng: 19.2678012 },
    { lat: 48.2308683, lng: 19.2676306 },
    { lat: 48.2312109, lng: 19.2668647 },
    { lat: 48.2312538, lng: 19.2662423 },
    { lat: 48.2315161, lng: 19.2660246 },
    { lat: 48.2316753, lng: 19.2651517 },
    { lat: 48.2315583, lng: 19.2651315 },
    { lat: 48.2316206, lng: 19.2632853 },
    { lat: 48.2317124, lng: 19.2613039 },
    { lat: 48.2318292, lng: 19.2608117 },
    { lat: 48.2320899, lng: 19.2603049 },
    { lat: 48.2323448, lng: 19.2599624 },
    { lat: 48.232527, lng: 19.2598383 },
    { lat: 48.2328954, lng: 19.2597447 },
    { lat: 48.2333923, lng: 19.2593005 },
    { lat: 48.2338818, lng: 19.2590741 },
    { lat: 48.234119, lng: 19.2590603 },
    { lat: 48.2345421, lng: 19.2592078 },
    { lat: 48.2348171, lng: 19.2591842 },
    { lat: 48.2348968, lng: 19.2586674 },
    { lat: 48.2347363, lng: 19.2584045 },
    { lat: 48.234701, lng: 19.2580966 },
    { lat: 48.2344275, lng: 19.257549 },
    { lat: 48.2352562, lng: 19.2569971 },
    { lat: 48.2352143, lng: 19.2564385 },
    { lat: 48.2352748, lng: 19.2554355 },
    { lat: 48.235378, lng: 19.2550332 },
    { lat: 48.236039, lng: 19.2533532 },
    { lat: 48.2360501, lng: 19.2533308 },
    { lat: 48.2354933, lng: 19.2530777 },
    { lat: 48.2352444, lng: 19.2528945 },
    { lat: 48.2350539, lng: 19.252572 },
    { lat: 48.2346408, lng: 19.252423 },
    { lat: 48.2333877, lng: 19.2517829 },
    { lat: 48.233024, lng: 19.2517452 },
    { lat: 48.2317151, lng: 19.2524072 },
    { lat: 48.2315864, lng: 19.252661 },
    { lat: 48.2313005, lng: 19.2528775 },
    { lat: 48.2308763, lng: 19.2529843 },
    { lat: 48.2306725, lng: 19.2529645 },
    { lat: 48.2306889, lng: 19.2530009 },
    { lat: 48.2304709, lng: 19.2530657 },
    { lat: 48.2299592, lng: 19.2534557 },
    { lat: 48.2296787, lng: 19.2534623 },
    { lat: 48.2286915, lng: 19.2536752 },
    { lat: 48.2279775, lng: 19.2541258 },
    { lat: 48.2272916, lng: 19.2544174 },
    { lat: 48.2267356, lng: 19.2548257 },
    { lat: 48.2265473, lng: 19.2550997 },
    { lat: 48.2261716, lng: 19.2552818 },
    { lat: 48.2256058, lng: 19.2553959 },
    { lat: 48.2246716, lng: 19.2552848 },
    { lat: 48.2242777, lng: 19.2554367 },
    { lat: 48.2234391, lng: 19.2546767 },
    { lat: 48.2228988, lng: 19.2545091 },
    { lat: 48.2222274, lng: 19.2547001 },
    { lat: 48.2218122, lng: 19.2549936 },
    { lat: 48.2212172, lng: 19.2552505 },
    { lat: 48.2210291, lng: 19.2555768 },
    { lat: 48.2206856, lng: 19.2558621 },
    { lat: 48.2201841, lng: 19.2561675 },
    { lat: 48.2198414, lng: 19.2566715 },
    { lat: 48.219737, lng: 19.2568897 },
    { lat: 48.2196591, lng: 19.2574874 },
    { lat: 48.2196781, lng: 19.2580841 },
    { lat: 48.2195782, lng: 19.2582795 },
    { lat: 48.2192924, lng: 19.2584305 },
    { lat: 48.2188808, lng: 19.258427 },
    { lat: 48.2183474, lng: 19.2582806 },
    { lat: 48.2175798, lng: 19.258563 },
    { lat: 48.2172639, lng: 19.2588081 },
    { lat: 48.216985, lng: 19.2591255 },
    { lat: 48.216784, lng: 19.2596239 },
    { lat: 48.2164857, lng: 19.2601435 },
    { lat: 48.2163475, lng: 19.2602897 },
    { lat: 48.2161415, lng: 19.2607593 },
    { lat: 48.2156628, lng: 19.2613904 },
    { lat: 48.2152982, lng: 19.2616362 },
    { lat: 48.2148302, lng: 19.2617703 },
    { lat: 48.214386, lng: 19.2613551 },
    { lat: 48.2141367, lng: 19.261465 },
    { lat: 48.2147276, lng: 19.262535 },
    { lat: 48.215054, lng: 19.262966 },
    { lat: 48.2153486, lng: 19.2640585 },
    { lat: 48.215182, lng: 19.2641095 },
    { lat: 48.2137498, lng: 19.265393 },
    { lat: 48.2126196, lng: 19.2658901 },
    { lat: 48.2081755, lng: 19.2697665 },
    { lat: 48.2074041, lng: 19.2702233 },
    { lat: 48.2050582, lng: 19.2729787 },
    { lat: 48.2031737, lng: 19.2755776 },
    { lat: 48.2031257, lng: 19.2754701 },
    { lat: 48.2029587, lng: 19.2758148 },
    { lat: 48.2023186, lng: 19.2761639 },
    { lat: 48.2019723, lng: 19.2759984 },
    { lat: 48.2018581, lng: 19.2760394 },
    { lat: 48.2019289, lng: 19.2763603 },
    { lat: 48.2018206, lng: 19.2767523 },
    { lat: 48.2016006, lng: 19.2767935 },
    { lat: 48.2011738, lng: 19.2763612 },
    { lat: 48.2010929, lng: 19.2764597 },
    { lat: 48.2011187, lng: 19.276806 },
    { lat: 48.2009466, lng: 19.2771395 },
    { lat: 48.2006454, lng: 19.2772009 },
    { lat: 48.2004162, lng: 19.2770788 },
    { lat: 48.2003069, lng: 19.2768024 },
    { lat: 48.1996759, lng: 19.2774321 },
    { lat: 48.1995579, lng: 19.2776484 },
    { lat: 48.1984271, lng: 19.2786638 },
    { lat: 48.1984953, lng: 19.2788362 },
    { lat: 48.1979392, lng: 19.2806629 },
    { lat: 48.1973807, lng: 19.2821399 },
    { lat: 48.1972275, lng: 19.2831833 },
    { lat: 48.1971909, lng: 19.2837525 },
    { lat: 48.1962366, lng: 19.2858791 },
    { lat: 48.1973129, lng: 19.2870811 },
    { lat: 48.1976992, lng: 19.2879341 },
    { lat: 48.1988726, lng: 19.2896582 },
    { lat: 48.1989599, lng: 19.2909916 },
    { lat: 48.1989563, lng: 19.2914545 },
    { lat: 48.1988859, lng: 19.2918624 },
    { lat: 48.1994628, lng: 19.2923673 },
    { lat: 48.1996458, lng: 19.292634 },
    { lat: 48.1997993, lng: 19.2931502 },
    { lat: 48.1999467, lng: 19.2932819 },
    { lat: 48.1999147, lng: 19.2935288 },
    { lat: 48.1997649, lng: 19.2938835 },
    { lat: 48.199808, lng: 19.29433 },
    { lat: 48.2006608, lng: 19.2955067 },
    { lat: 48.2028602, lng: 19.2979781 },
    { lat: 48.202687, lng: 19.2981724 },
    { lat: 48.2022884, lng: 19.2983707 },
    { lat: 48.2023003, lng: 19.2985659 },
    { lat: 48.2024556, lng: 19.2988671 },
    { lat: 48.2024337, lng: 19.299044 },
    { lat: 48.2023021, lng: 19.2992586 },
    { lat: 48.2039582, lng: 19.3002173 },
    { lat: 48.2043547, lng: 19.3012366 },
    { lat: 48.2048709, lng: 19.3030796 },
    { lat: 48.2058043, lng: 19.3052467 },
    { lat: 48.2058592, lng: 19.3051274 },
    { lat: 48.2059151, lng: 19.3052584 },
    { lat: 48.2059494, lng: 19.3052197 },
    { lat: 48.2060344, lng: 19.3052727 },
    { lat: 48.2061262, lng: 19.3051674 },
    { lat: 48.2061912, lng: 19.3052328 },
    { lat: 48.2062165, lng: 19.3051491 },
    { lat: 48.2063312, lng: 19.3052109 },
    { lat: 48.2063328, lng: 19.3050843 },
    { lat: 48.2064368, lng: 19.3051826 },
    { lat: 48.2064616, lng: 19.3050177 },
    { lat: 48.2065619, lng: 19.3049952 },
    { lat: 48.2066216, lng: 19.3050869 },
    { lat: 48.2066428, lng: 19.3049871 },
    { lat: 48.206723, lng: 19.3050389 },
    { lat: 48.2066543, lng: 19.3048551 },
    { lat: 48.2067819, lng: 19.3048364 },
    { lat: 48.2068165, lng: 19.3047351 },
    { lat: 48.2068802, lng: 19.3047696 },
    { lat: 48.2068787, lng: 19.30488 },
    { lat: 48.2069534, lng: 19.3048857 },
    { lat: 48.2070448, lng: 19.3047733 },
    { lat: 48.2070674, lng: 19.3048634 },
    { lat: 48.2071448, lng: 19.3048572 },
    { lat: 48.2071112, lng: 19.3050039 },
    { lat: 48.2071571, lng: 19.3050449 },
    { lat: 48.207215, lng: 19.3049121 },
    { lat: 48.2072678, lng: 19.3049545 },
    { lat: 48.2073614, lng: 19.3048387 },
    { lat: 48.2073966, lng: 19.3049112 },
    { lat: 48.2074578, lng: 19.3048491 },
    { lat: 48.2075655, lng: 19.3045364 },
    { lat: 48.2078053, lng: 19.3041808 },
    { lat: 48.2078704, lng: 19.3042232 },
    { lat: 48.2079732, lng: 19.3041789 },
    { lat: 48.2080123, lng: 19.3042909 },
    { lat: 48.2081706, lng: 19.3044176 },
    { lat: 48.2084561, lng: 19.3051447 },
    { lat: 48.2089715, lng: 19.3056437 },
    { lat: 48.2095164, lng: 19.30647 },
    { lat: 48.2104898, lng: 19.3055017 },
    { lat: 48.2106495, lng: 19.3055176 },
    { lat: 48.2106765, lng: 19.3054016 },
    { lat: 48.2107239, lng: 19.3054 },
    { lat: 48.2112362, lng: 19.3063917 },
    { lat: 48.2119938, lng: 19.3074208 },
    { lat: 48.2121266, lng: 19.3075104 },
    { lat: 48.2124493, lng: 19.3075408 },
    { lat: 48.212579, lng: 19.3074042 },
    { lat: 48.2128855, lng: 19.3073781 },
    { lat: 48.2129853, lng: 19.3072984 },
    { lat: 48.2131451, lng: 19.3075002 },
    { lat: 48.2133544, lng: 19.3075659 },
    { lat: 48.21375, lng: 19.3073302 },
    { lat: 48.2141429, lng: 19.306642 },
    { lat: 48.2144759, lng: 19.3071206 },
    { lat: 48.2145426, lng: 19.3070791 },
    { lat: 48.2147836, lng: 19.3071679 },
    { lat: 48.2148184, lng: 19.3073256 },
    { lat: 48.2152934, lng: 19.3075474 },
    { lat: 48.2158215, lng: 19.3074617 },
    { lat: 48.2159176, lng: 19.3073064 },
    { lat: 48.2162808, lng: 19.3074993 },
    { lat: 48.2164754, lng: 19.3077479 },
    { lat: 48.2165908, lng: 19.3079906 },
    { lat: 48.2165458, lng: 19.3084821 },
    { lat: 48.2167539, lng: 19.3087067 },
    { lat: 48.216846, lng: 19.3091431 },
    { lat: 48.2175863, lng: 19.3094175 },
    { lat: 48.2178881, lng: 19.3094365 },
    { lat: 48.2183357, lng: 19.3099501 },
    { lat: 48.2185029, lng: 19.3101942 },
    { lat: 48.2185898, lng: 19.310569 },
    { lat: 48.2188522, lng: 19.3108185 },
    { lat: 48.2189349, lng: 19.3107367 },
    { lat: 48.2194266, lng: 19.3108582 },
    { lat: 48.219773, lng: 19.3111441 },
    { lat: 48.220428, lng: 19.3113112 },
    { lat: 48.2207495, lng: 19.3115521 },
    { lat: 48.221503, lng: 19.3108864 },
    { lat: 48.2219378, lng: 19.3103682 },
    { lat: 48.2220617, lng: 19.3103662 },
    { lat: 48.2223825, lng: 19.3097935 },
    { lat: 48.2226846, lng: 19.3098863 },
    { lat: 48.2229451, lng: 19.3096118 },
    { lat: 48.2232519, lng: 19.3095443 },
    { lat: 48.2234667, lng: 19.3092083 },
    { lat: 48.2261538, lng: 19.3119838 },
    { lat: 48.2270351, lng: 19.3122396 },
    { lat: 48.2274252, lng: 19.3122639 },
    { lat: 48.2282362, lng: 19.3126517 },
    { lat: 48.2285549, lng: 19.3126692 },
    { lat: 48.2286186, lng: 19.3128681 },
    { lat: 48.2290499, lng: 19.3138625 },
    { lat: 48.2293586, lng: 19.3133919 },
    { lat: 48.2305235, lng: 19.3124702 },
    { lat: 48.2309104, lng: 19.312427 },
    { lat: 48.2315628, lng: 19.3124908 },
    { lat: 48.2318493, lng: 19.3124032 },
    { lat: 48.2326309, lng: 19.3116202 },
    { lat: 48.2332695, lng: 19.3113061 },
    { lat: 48.2337811, lng: 19.3108361 },
    { lat: 48.2349109, lng: 19.3100984 },
    { lat: 48.2373642, lng: 19.3081935 },
    { lat: 48.2383821, lng: 19.3079367 },
    { lat: 48.2389715, lng: 19.3076554 },
    { lat: 48.2395079, lng: 19.3076062 },
    { lat: 48.2397654, lng: 19.307641 },
    { lat: 48.239983, lng: 19.3077585 },
    { lat: 48.2402884, lng: 19.3077106 },
    { lat: 48.2409458, lng: 19.3075511 },
    { lat: 48.2414574, lng: 19.3075007 },
    { lat: 48.2426579, lng: 19.3070391 },
    { lat: 48.2440633, lng: 19.306801 },
    { lat: 48.2446832, lng: 19.3063336 },
    { lat: 48.2449408, lng: 19.3060433 },
    { lat: 48.2463012, lng: 19.3051312 },
    { lat: 48.2468587, lng: 19.3050747 },
    { lat: 48.2474735, lng: 19.305343 },
    { lat: 48.2496035, lng: 19.3055916 },
    { lat: 48.2500057, lng: 19.3054434 },
    { lat: 48.2507026, lng: 19.3050556 },
    { lat: 48.2518623, lng: 19.304164 },
    { lat: 48.2522086, lng: 19.3040084 },
    { lat: 48.2527163, lng: 19.3036687 },
    { lat: 48.2535501, lng: 19.302564 },
    { lat: 48.2541512, lng: 19.301522 },
    { lat: 48.2542583, lng: 19.3015008 },
    { lat: 48.2549959, lng: 19.3005756 },
    { lat: 48.2557178, lng: 19.3000241 },
    { lat: 48.2558789, lng: 19.2996982 },
    { lat: 48.2562603, lng: 19.2992179 },
    { lat: 48.2571792, lng: 19.2964937 },
    { lat: 48.2573925, lng: 19.2959853 },
    { lat: 48.2574739, lng: 19.2946865 },
    { lat: 48.2577448, lng: 19.2939486 },
    { lat: 48.2579039, lng: 19.2933268 },
    { lat: 48.258355, lng: 19.2924912 },
    { lat: 48.2591471, lng: 19.2913911 },
    { lat: 48.260863, lng: 19.2897682 },
    { lat: 48.2612045, lng: 19.2893229 },
    { lat: 48.2613352, lng: 19.288926 },
    { lat: 48.2614527, lng: 19.2887668 },
    { lat: 48.2617004, lng: 19.2885075 },
    { lat: 48.2624367, lng: 19.2879322 },
    { lat: 48.262645, lng: 19.2876439 },
    { lat: 48.2632486, lng: 19.2871439 },
    { lat: 48.2637935, lng: 19.286529 },
    { lat: 48.2642436, lng: 19.2858588 },
    { lat: 48.264749, lng: 19.2852871 },
    { lat: 48.2655206, lng: 19.2847638 },
    { lat: 48.265912, lng: 19.2845352 },
    { lat: 48.266171, lng: 19.2844599 },
    { lat: 48.2666617, lng: 19.2841207 },
    { lat: 48.267258, lng: 19.2839349 },
    { lat: 48.2683346, lng: 19.2833784 },
    { lat: 48.2693399, lng: 19.2825654 },
    { lat: 48.269545, lng: 19.2822844 },
    { lat: 48.2698112, lng: 19.2820786 },
    { lat: 48.2702123, lng: 19.2818648 },
    { lat: 48.2703601, lng: 19.2818448 },
    { lat: 48.2704934, lng: 19.2818264 },
    { lat: 48.2707721, lng: 19.2817381 },
    { lat: 48.2711721, lng: 19.2818039 },
    { lat: 48.2715446, lng: 19.281653 },
    { lat: 48.2717718, lng: 19.2816675 },
    { lat: 48.2729583, lng: 19.2809387 },
    { lat: 48.2733587, lng: 19.2805728 },
    { lat: 48.2734377, lng: 19.2799224 },
    { lat: 48.2734218, lng: 19.2795713 },
    { lat: 48.2732517, lng: 19.278805 },
    { lat: 48.2732556, lng: 19.278213 },
    { lat: 48.2733673, lng: 19.2777023 },
    { lat: 48.273875, lng: 19.2763651 },
    { lat: 48.2741504, lng: 19.2748653 },
    { lat: 48.2741565, lng: 19.2744559 },
    { lat: 48.2740379, lng: 19.2740333 },
    { lat: 48.2740129, lng: 19.27345 },
    { lat: 48.2737064, lng: 19.2729605 },
    { lat: 48.2735963, lng: 19.272901 },
    { lat: 48.2733705, lng: 19.2723131 },
    { lat: 48.2734042, lng: 19.271917 },
    { lat: 48.2732416, lng: 19.2715597 },
    { lat: 48.2731855, lng: 19.271058 },
    { lat: 48.2732014, lng: 19.270781 },
    { lat: 48.2732604, lng: 19.2706483 },
    { lat: 48.2732654, lng: 19.270335 },
    { lat: 48.273402, lng: 19.2698969 },
    { lat: 48.2734785, lng: 19.2688525 },
    { lat: 48.2735627, lng: 19.268544 },
    { lat: 48.273608, lng: 19.2678763 },
    { lat: 48.2735209, lng: 19.2675175 },
    { lat: 48.2732793, lng: 19.2671645 },
    { lat: 48.2730531, lng: 19.2669422 },
    { lat: 48.2730304, lng: 19.2668693 },
    { lat: 48.2730898, lng: 19.266676 },
    { lat: 48.2729769, lng: 19.2664877 },
    { lat: 48.2728174, lng: 19.2665193 },
    { lat: 48.2726597, lng: 19.2663804 },
    { lat: 48.2727411, lng: 19.2659462 },
    { lat: 48.2725144, lng: 19.2657831 },
    { lat: 48.2723758, lng: 19.26576 },
    { lat: 48.2721344, lng: 19.264831 },
    { lat: 48.2721375, lng: 19.2646619 },
    { lat: 48.2719433, lng: 19.2643756 },
    { lat: 48.2717914, lng: 19.2642917 },
    { lat: 48.2715624, lng: 19.2637562 },
    { lat: 48.2716056, lng: 19.263667 },
    { lat: 48.2717948, lng: 19.2636191 },
    { lat: 48.2719799, lng: 19.2634849 },
    { lat: 48.2723691, lng: 19.2630034 },
    { lat: 48.2725507, lng: 19.2626824 },
    { lat: 48.2728239, lng: 19.2626413 },
    { lat: 48.2731004, lng: 19.2623478 },
    { lat: 48.2730602, lng: 19.2622892 },
    { lat: 48.2730022, lng: 19.2613986 },
    { lat: 48.2730323, lng: 19.2613911 },
    { lat: 48.2729502, lng: 19.2608824 },
    { lat: 48.2727453, lng: 19.260357 },
    { lat: 48.2726281, lng: 19.2597779 },
    { lat: 48.272747, lng: 19.2593035 },
    { lat: 48.2727707, lng: 19.2589814 },
    { lat: 48.2728048, lng: 19.2590063 },
    { lat: 48.2726812, lng: 19.2580701 },
    { lat: 48.2724682, lng: 19.2570949 },
    { lat: 48.2722789, lng: 19.2555952 },
    { lat: 48.2722541, lng: 19.2555703 },
  ],
  MaléZlievce: [
    { lat: 48.1872979, lng: 19.4263513 },
    { lat: 48.1871471, lng: 19.4261182 },
    { lat: 48.1859192, lng: 19.4242255 },
    { lat: 48.1840296, lng: 19.4205911 },
    { lat: 48.1834667, lng: 19.4197961 },
    { lat: 48.1831289, lng: 19.4191128 },
    { lat: 48.1826532, lng: 19.4184421 },
    { lat: 48.182648, lng: 19.4182833 },
    { lat: 48.182257, lng: 19.4177872 },
    { lat: 48.1806321, lng: 19.4171775 },
    { lat: 48.1781353, lng: 19.4181899 },
    { lat: 48.1781293, lng: 19.4195408 },
    { lat: 48.178128, lng: 19.4196081 },
    { lat: 48.1781243, lng: 19.4198874 },
    { lat: 48.1779564, lng: 19.4203177 },
    { lat: 48.1772529, lng: 19.4215712 },
    { lat: 48.1771527, lng: 19.4219783 },
    { lat: 48.177025, lng: 19.4221853 },
    { lat: 48.1768891, lng: 19.4222672 },
    { lat: 48.1764997, lng: 19.4222529 },
    { lat: 48.176013, lng: 19.4224489 },
    { lat: 48.1755231, lng: 19.4227916 },
    { lat: 48.1750792, lng: 19.423507 },
    { lat: 48.174469, lng: 19.4242615 },
    { lat: 48.1737642, lng: 19.4255645 },
    { lat: 48.1735645, lng: 19.4262745 },
    { lat: 48.1735063, lng: 19.4271418 },
    { lat: 48.1735639, lng: 19.4287839 },
    { lat: 48.1735614, lng: 19.4301891 },
    { lat: 48.1735076, lng: 19.4306704 },
    { lat: 48.1735664, lng: 19.4314607 },
    { lat: 48.1735471, lng: 19.4317915 },
    { lat: 48.1734529, lng: 19.4322817 },
    { lat: 48.1732042, lng: 19.4331458 },
    { lat: 48.1729444, lng: 19.4336619 },
    { lat: 48.1727218, lng: 19.4339844 },
    { lat: 48.1723806, lng: 19.4347743 },
    { lat: 48.172126, lng: 19.4352301 },
    { lat: 48.1716358, lng: 19.4358274 },
    { lat: 48.1711245, lng: 19.4369314 },
    { lat: 48.1706047, lng: 19.4382435 },
    { lat: 48.1702531, lng: 19.4395565 },
    { lat: 48.1700731, lng: 19.4400663 },
    { lat: 48.1694964, lng: 19.441122 },
    { lat: 48.1690264, lng: 19.4421527 },
    { lat: 48.1685737, lng: 19.4433305 },
    { lat: 48.1680081, lng: 19.4441922 },
    { lat: 48.1676229, lng: 19.4445336 },
    { lat: 48.1673222, lng: 19.4449423 },
    { lat: 48.1672613, lng: 19.4448231 },
    { lat: 48.1671005, lng: 19.4449734 },
    { lat: 48.1668712, lng: 19.445274 },
    { lat: 48.1666069, lng: 19.4457908 },
    { lat: 48.165076, lng: 19.4459944 },
    { lat: 48.164455, lng: 19.4461622 },
    { lat: 48.1631947, lng: 19.4466607 },
    { lat: 48.1632009, lng: 19.4467176 },
    { lat: 48.163436, lng: 19.4478581 },
    { lat: 48.1638638, lng: 19.4493787 },
    { lat: 48.1633608, lng: 19.449947 },
    { lat: 48.1648403, lng: 19.4523807 },
    { lat: 48.1650471, lng: 19.4534434 },
    { lat: 48.1652021, lng: 19.4553271 },
    { lat: 48.1647175, lng: 19.4563816 },
    { lat: 48.1645175, lng: 19.4573519 },
    { lat: 48.1640517, lng: 19.4585655 },
    { lat: 48.1635852, lng: 19.4594066 },
    { lat: 48.1633545, lng: 19.4599403 },
    { lat: 48.1618384, lng: 19.4615433 },
    { lat: 48.1612519, lng: 19.4634524 },
    { lat: 48.1613341, lng: 19.4635673 },
    { lat: 48.1603479, lng: 19.4659674 },
    { lat: 48.1601386, lng: 19.4671266 },
    { lat: 48.160018, lng: 19.4680133 },
    { lat: 48.1600541, lng: 19.4680628 },
    { lat: 48.1601164, lng: 19.468118 },
    { lat: 48.1607027, lng: 19.4698518 },
    { lat: 48.1610111, lng: 19.4709229 },
    { lat: 48.1614575, lng: 19.4720311 },
    { lat: 48.1616518, lng: 19.4734093 },
    { lat: 48.161655, lng: 19.4735046 },
    { lat: 48.1619722, lng: 19.473685 },
    { lat: 48.1630516, lng: 19.4743996 },
    { lat: 48.1643332, lng: 19.474958 },
    { lat: 48.1655777, lng: 19.4748898 },
    { lat: 48.1661459, lng: 19.4747487 },
    { lat: 48.1667068, lng: 19.4748647 },
    { lat: 48.1678929, lng: 19.4769631 },
    { lat: 48.1693808, lng: 19.477358 },
    { lat: 48.1694382, lng: 19.477017 },
    { lat: 48.1695071, lng: 19.4766119 },
    { lat: 48.1701592, lng: 19.4743452 },
    { lat: 48.1703216, lng: 19.4740817 },
    { lat: 48.1705572, lng: 19.4738639 },
    { lat: 48.1708793, lng: 19.4730676 },
    { lat: 48.1712526, lng: 19.472687 },
    { lat: 48.1714338, lng: 19.4725889 },
    { lat: 48.1715889, lng: 19.4720992 },
    { lat: 48.1718341, lng: 19.4699954 },
    { lat: 48.1720471, lng: 19.4689174 },
    { lat: 48.1718674, lng: 19.4680183 },
    { lat: 48.172382, lng: 19.4676007 },
    { lat: 48.1733547, lng: 19.4643149 },
    { lat: 48.1744263, lng: 19.4637332 },
    { lat: 48.1750161, lng: 19.4579855 },
    { lat: 48.1758354, lng: 19.45772 },
    { lat: 48.1772937, lng: 19.4570734 },
    { lat: 48.1778037, lng: 19.4569647 },
    { lat: 48.1785945, lng: 19.4566708 },
    { lat: 48.1792443, lng: 19.4563019 },
    { lat: 48.1795807, lng: 19.455977 },
    { lat: 48.1804765, lng: 19.4567155 },
    { lat: 48.1806568, lng: 19.4563056 },
    { lat: 48.1825058, lng: 19.4574987 },
    { lat: 48.1826207, lng: 19.4573764 },
    { lat: 48.1835465, lng: 19.4556394 },
    { lat: 48.1840763, lng: 19.4559976 },
    { lat: 48.1862204, lng: 19.4531586 },
    { lat: 48.1879569, lng: 19.4509956 },
    { lat: 48.1879373, lng: 19.4509666 },
    { lat: 48.1881758, lng: 19.450619 },
    { lat: 48.1889699, lng: 19.4496525 },
    { lat: 48.189748, lng: 19.4484675 },
    { lat: 48.1906599, lng: 19.4474153 },
    { lat: 48.1913342, lng: 19.4462995 },
    { lat: 48.1924299, lng: 19.445348 },
    { lat: 48.1932156, lng: 19.4441292 },
    { lat: 48.1936911, lng: 19.4436263 },
    { lat: 48.1953569, lng: 19.4425753 },
    { lat: 48.1959883, lng: 19.4422689 },
    { lat: 48.1963989, lng: 19.4422553 },
    { lat: 48.1972829, lng: 19.441736 },
    { lat: 48.1981904, lng: 19.441547 },
    { lat: 48.198545, lng: 19.4415647 },
    { lat: 48.1992882, lng: 19.4417647 },
    { lat: 48.2015937, lng: 19.4397975 },
    { lat: 48.2017624, lng: 19.4389785 },
    { lat: 48.2018898, lng: 19.4385941 },
    { lat: 48.2020351, lng: 19.4383307 },
    { lat: 48.2024942, lng: 19.4365477 },
    { lat: 48.2024727, lng: 19.4361172 },
    { lat: 48.2024106, lng: 19.4358284 },
    { lat: 48.2036028, lng: 19.4355124 },
    { lat: 48.2043935, lng: 19.4354084 },
    { lat: 48.2048942, lng: 19.4352234 },
    { lat: 48.2053138, lng: 19.4353275 },
    { lat: 48.2056603, lng: 19.4353368 },
    { lat: 48.2059056, lng: 19.4354957 },
    { lat: 48.2064594, lng: 19.4356965 },
    { lat: 48.2068768, lng: 19.4355828 },
    { lat: 48.2075293, lng: 19.4344352 },
    { lat: 48.208393, lng: 19.4330861 },
    { lat: 48.2078779, lng: 19.432965 },
    { lat: 48.2057008, lng: 19.4325693 },
    { lat: 48.2028284, lng: 19.4323612 },
    { lat: 48.2024759, lng: 19.4305533 },
    { lat: 48.2022529, lng: 19.4301257 },
    { lat: 48.202047, lng: 19.4301574 },
    { lat: 48.2016148, lng: 19.4303829 },
    { lat: 48.2002309, lng: 19.4306231 },
    { lat: 48.1995644, lng: 19.4308282 },
    { lat: 48.1983686, lng: 19.4309952 },
    { lat: 48.1970762, lng: 19.4309541 },
    { lat: 48.1967919, lng: 19.4308064 },
    { lat: 48.1955503, lng: 19.4297656 },
    { lat: 48.1953159, lng: 19.4289783 },
    { lat: 48.1952947, lng: 19.4286238 },
    { lat: 48.195152, lng: 19.4284178 },
    { lat: 48.1950798, lng: 19.4280845 },
    { lat: 48.1948323, lng: 19.4277352 },
    { lat: 48.1947538, lng: 19.427528 },
    { lat: 48.194595, lng: 19.427512 },
    { lat: 48.194334, lng: 19.4266621 },
    { lat: 48.1941626, lng: 19.4264235 },
    { lat: 48.194093, lng: 19.426209 },
    { lat: 48.1939329, lng: 19.4259504 },
    { lat: 48.1937361, lng: 19.4258646 },
    { lat: 48.193572, lng: 19.4256107 },
    { lat: 48.193369, lng: 19.425512 },
    { lat: 48.1929479, lng: 19.4255417 },
    { lat: 48.1928538, lng: 19.4254358 },
    { lat: 48.1925726, lng: 19.4254267 },
    { lat: 48.1924706, lng: 19.4253429 },
    { lat: 48.1923598, lng: 19.4254319 },
    { lat: 48.1922837, lng: 19.4253909 },
    { lat: 48.1921612, lng: 19.425472 },
    { lat: 48.191264, lng: 19.4256616 },
    { lat: 48.1908676, lng: 19.4255509 },
    { lat: 48.1907156, lng: 19.4251937 },
    { lat: 48.190446, lng: 19.4250958 },
    { lat: 48.1903921, lng: 19.4252184 },
    { lat: 48.1901757, lng: 19.4253126 },
    { lat: 48.1885029, lng: 19.4258311 },
    { lat: 48.1881571, lng: 19.4260962 },
    { lat: 48.1875887, lng: 19.4266607 },
    { lat: 48.1872979, lng: 19.4263513 },
  ],
  Čebovce: [
    { lat: 48.2476935, lng: 19.2395711 },
    { lat: 48.2481116, lng: 19.2390641 },
    { lat: 48.2484286, lng: 19.2379811 },
    { lat: 48.2486002, lng: 19.2375495 },
    { lat: 48.2493401, lng: 19.2364979 },
    { lat: 48.2502224, lng: 19.2357536 },
    { lat: 48.2506578, lng: 19.2355287 },
    { lat: 48.2511116, lng: 19.2351528 },
    { lat: 48.25249, lng: 19.2339717 },
    { lat: 48.2528051, lng: 19.2335703 },
    { lat: 48.2529894, lng: 19.2334889 },
    { lat: 48.2536495, lng: 19.2334101 },
    { lat: 48.2545213, lng: 19.2321288 },
    { lat: 48.2548209, lng: 19.2318215 },
    { lat: 48.2555611, lng: 19.2308683 },
    { lat: 48.2557311, lng: 19.2307527 },
    { lat: 48.2562488, lng: 19.2306435 },
    { lat: 48.2566015, lng: 19.2304566 },
    { lat: 48.2569964, lng: 19.2301183 },
    { lat: 48.2572005, lng: 19.2297938 },
    { lat: 48.2577802, lng: 19.2282614 },
    { lat: 48.2582855, lng: 19.2273697 },
    { lat: 48.258846, lng: 19.226598 },
    { lat: 48.258841, lng: 19.22629 },
    { lat: 48.258861, lng: 19.225417 },
    { lat: 48.258929, lng: 19.225013 },
    { lat: 48.259029, lng: 19.224574 },
    { lat: 48.259042, lng: 19.224524 },
    { lat: 48.259064, lng: 19.224443 },
    { lat: 48.259075, lng: 19.224403 },
    { lat: 48.25911, lng: 19.224215 },
    { lat: 48.259137, lng: 19.224041 },
    { lat: 48.258554, lng: 19.22392 },
    { lat: 48.25852, lng: 19.223913 },
    { lat: 48.25835, lng: 19.223877 },
    { lat: 48.25796, lng: 19.223768 },
    { lat: 48.257621, lng: 19.223672 },
    { lat: 48.257209, lng: 19.223635 },
    { lat: 48.256822, lng: 19.2236 },
    { lat: 48.256737, lng: 19.223592 },
    { lat: 48.256229, lng: 19.223539 },
    { lat: 48.25572, lng: 19.22349 },
    { lat: 48.255404, lng: 19.22346 },
    { lat: 48.255197, lng: 19.223436 },
    { lat: 48.254783, lng: 19.223387 },
    { lat: 48.254548, lng: 19.223359 },
    { lat: 48.254344, lng: 19.223335 },
    { lat: 48.253937, lng: 19.223287 },
    { lat: 48.253782, lng: 19.223226 },
    { lat: 48.253454, lng: 19.223068 },
    { lat: 48.252981, lng: 19.222839 },
    { lat: 48.252536, lng: 19.222624 },
    { lat: 48.252517, lng: 19.222608 },
    { lat: 48.251868, lng: 19.222406 },
    { lat: 48.251751, lng: 19.22237 },
    { lat: 48.251404, lng: 19.22227 },
    { lat: 48.251395, lng: 19.222269 },
    { lat: 48.251048, lng: 19.222224 },
    { lat: 48.25104, lng: 19.222223 },
    { lat: 48.250804, lng: 19.222119 },
    { lat: 48.25063, lng: 19.22199 },
    { lat: 48.25039, lng: 19.221811 },
    { lat: 48.250194, lng: 19.221638 },
    { lat: 48.250055, lng: 19.22143 },
    { lat: 48.250028, lng: 19.221352 },
    { lat: 48.249887, lng: 19.22094 },
    { lat: 48.249873, lng: 19.220912 },
    { lat: 48.249575, lng: 19.220214 },
    { lat: 48.249586, lng: 19.220197 },
    { lat: 48.249235, lng: 19.219599 },
    { lat: 48.248446, lng: 19.218763 },
    { lat: 48.248374, lng: 19.218617 },
    { lat: 48.248185, lng: 19.217957 },
    { lat: 48.24808, lng: 19.217813 },
    { lat: 48.247749, lng: 19.21773 },
    { lat: 48.247366, lng: 19.217374 },
    { lat: 48.247223, lng: 19.217121 },
    { lat: 48.247147, lng: 19.217039 },
    { lat: 48.246825, lng: 19.21669 },
    { lat: 48.24644, lng: 19.216274 },
    { lat: 48.245976, lng: 19.215881 },
    { lat: 48.245834, lng: 19.215529 },
    { lat: 48.245736, lng: 19.21498 },
    { lat: 48.245307, lng: 19.21423 },
    { lat: 48.245228, lng: 19.214181 },
    { lat: 48.245224, lng: 19.214221 },
    { lat: 48.2445655, lng: 19.2138704 },
    { lat: 48.2431228, lng: 19.2141032 },
    { lat: 48.2427359, lng: 19.2143968 },
    { lat: 48.2419553, lng: 19.2145718 },
    { lat: 48.2416979, lng: 19.2146995 },
    { lat: 48.2412499, lng: 19.2146919 },
    { lat: 48.2408719, lng: 19.2147968 },
    { lat: 48.2404422, lng: 19.2149778 },
    { lat: 48.239827, lng: 19.2153754 },
    { lat: 48.2395041, lng: 19.2154526 },
    { lat: 48.2390141, lng: 19.2154422 },
    { lat: 48.2379696, lng: 19.2150826 },
    { lat: 48.2362922, lng: 19.2148082 },
    { lat: 48.2354824, lng: 19.215027 },
    { lat: 48.2343951, lng: 19.2151764 },
    { lat: 48.2333577, lng: 19.2159252 },
    { lat: 48.2322073, lng: 19.2157321 },
    { lat: 48.2321121, lng: 19.2157892 },
    { lat: 48.2315508, lng: 19.2167794 },
    { lat: 48.2314407, lng: 19.2171295 },
    { lat: 48.2311304, lng: 19.21742 },
    { lat: 48.229221, lng: 19.2161631 },
    { lat: 48.2287814, lng: 19.2157748 },
    { lat: 48.2281339, lng: 19.2153985 },
    { lat: 48.2261234, lng: 19.2150649 },
    { lat: 48.2252792, lng: 19.2148167 },
    { lat: 48.2232481, lng: 19.2160122 },
    { lat: 48.2222478, lng: 19.2155475 },
    { lat: 48.2216737, lng: 19.2156318 },
    { lat: 48.221107, lng: 19.2152901 },
    { lat: 48.2210425, lng: 19.2147599 },
    { lat: 48.2207381, lng: 19.2141172 },
    { lat: 48.2196724, lng: 19.2129816 },
    { lat: 48.2192147, lng: 19.2128196 },
    { lat: 48.2190175, lng: 19.2125458 },
    { lat: 48.2183672, lng: 19.2121628 },
    { lat: 48.2177879, lng: 19.2116125 },
    { lat: 48.2166721, lng: 19.2110662 },
    { lat: 48.2164601, lng: 19.2109044 },
    { lat: 48.2162763, lng: 19.2108444 },
    { lat: 48.2162025, lng: 19.2108203 },
    { lat: 48.2157811, lng: 19.2105051 },
    { lat: 48.2153791, lng: 19.2103988 },
    { lat: 48.215038, lng: 19.2104081 },
    { lat: 48.2145792, lng: 19.2102256 },
    { lat: 48.2141972, lng: 19.2098411 },
    { lat: 48.2140169, lng: 19.2097287 },
    { lat: 48.2138404, lng: 19.2097121 },
    { lat: 48.2136112, lng: 19.209543 },
    { lat: 48.2133445, lng: 19.2096925 },
    { lat: 48.2131883, lng: 19.2096821 },
    { lat: 48.2129777, lng: 19.2095675 },
    { lat: 48.2127206, lng: 19.2097495 },
    { lat: 48.2119709, lng: 19.2099801 },
    { lat: 48.211628, lng: 19.2097241 },
    { lat: 48.211146, lng: 19.2097313 },
    { lat: 48.2107805, lng: 19.209633 },
    { lat: 48.2104208, lng: 19.2094047 },
    { lat: 48.2095536, lng: 19.2090753 },
    { lat: 48.209248, lng: 19.2091408 },
    { lat: 48.2090498, lng: 19.2092842 },
    { lat: 48.2088301, lng: 19.2092401 },
    { lat: 48.2083289, lng: 19.2093851 },
    { lat: 48.2081479, lng: 19.2092831 },
    { lat: 48.2078776, lng: 19.2094694 },
    { lat: 48.2075882, lng: 19.2095709 },
    { lat: 48.2073189, lng: 19.2098005 },
    { lat: 48.2066933, lng: 19.2100521 },
    { lat: 48.2065203, lng: 19.2100167 },
    { lat: 48.2060626, lng: 19.2104993 },
    { lat: 48.205846, lng: 19.2104761 },
    { lat: 48.2054591, lng: 19.2107894 },
    { lat: 48.2052922, lng: 19.2108593 },
    { lat: 48.2044391, lng: 19.2116204 },
    { lat: 48.2041756, lng: 19.2117617 },
    { lat: 48.2041532, lng: 19.2120176 },
    { lat: 48.2031269, lng: 19.2118273 },
    { lat: 48.2023987, lng: 19.2121922 },
    { lat: 48.201753, lng: 19.2126322 },
    { lat: 48.2013973, lng: 19.212778 },
    { lat: 48.2008528, lng: 19.2127343 },
    { lat: 48.2000615, lng: 19.2122309 },
    { lat: 48.1987206, lng: 19.2127911 },
    { lat: 48.1979664, lng: 19.2129741 },
    { lat: 48.1963277, lng: 19.2132557 },
    { lat: 48.1960294, lng: 19.2131938 },
    { lat: 48.1957835, lng: 19.2133103 },
    { lat: 48.1943815, lng: 19.2133627 },
    { lat: 48.1937266, lng: 19.2140943 },
    { lat: 48.1927387, lng: 19.2149116 },
    { lat: 48.1920905, lng: 19.2152086 },
    { lat: 48.1916085, lng: 19.2155863 },
    { lat: 48.1906456, lng: 19.2161994 },
    { lat: 48.1905512, lng: 19.2164144 },
    { lat: 48.1897359, lng: 19.2172338 },
    { lat: 48.1892929, lng: 19.21751 },
    { lat: 48.1889883, lng: 19.2174834 },
    { lat: 48.188049, lng: 19.2191752 },
    { lat: 48.1873106, lng: 19.2199551 },
    { lat: 48.1860805, lng: 19.2209574 },
    { lat: 48.1855634, lng: 19.2215871 },
    { lat: 48.1850387, lng: 19.2221018 },
    { lat: 48.184938, lng: 19.2222785 },
    { lat: 48.18443, lng: 19.2227232 },
    { lat: 48.184133, lng: 19.22315 },
    { lat: 48.18352, lng: 19.2237266 },
    { lat: 48.1833989, lng: 19.2238091 },
    { lat: 48.1829024, lng: 19.2228096 },
    { lat: 48.1825876, lng: 19.2227813 },
    { lat: 48.1824339, lng: 19.222866 },
    { lat: 48.182382, lng: 19.2227512 },
    { lat: 48.1823996, lng: 19.2226048 },
    { lat: 48.1822919, lng: 19.2224312 },
    { lat: 48.1820797, lng: 19.2224485 },
    { lat: 48.181821, lng: 19.2226213 },
    { lat: 48.1817057, lng: 19.2226351 },
    { lat: 48.1816553, lng: 19.2225512 },
    { lat: 48.1812883, lng: 19.222789 },
    { lat: 48.1811066, lng: 19.2228315 },
    { lat: 48.1808931, lng: 19.2231205 },
    { lat: 48.1807978, lng: 19.2231571 },
    { lat: 48.1807292, lng: 19.2229782 },
    { lat: 48.1805504, lng: 19.2232609 },
    { lat: 48.1804994, lng: 19.2234604 },
    { lat: 48.1799661, lng: 19.2239421 },
    { lat: 48.1793854, lng: 19.2238441 },
    { lat: 48.1791894, lng: 19.224058 },
    { lat: 48.1789545, lng: 19.2242085 },
    { lat: 48.178896, lng: 19.2245091 },
    { lat: 48.1786436, lng: 19.224901 },
    { lat: 48.1782347, lng: 19.2254357 },
    { lat: 48.178078, lng: 19.2255507 },
    { lat: 48.1775166, lng: 19.2253887 },
    { lat: 48.1771683, lng: 19.2257123 },
    { lat: 48.1765735, lng: 19.2264772 },
    { lat: 48.176368, lng: 19.2266325 },
    { lat: 48.1761517, lng: 19.2265418 },
    { lat: 48.1760505, lng: 19.2273737 },
    { lat: 48.1749853, lng: 19.2283197 },
    { lat: 48.1748495, lng: 19.2281467 },
    { lat: 48.174721, lng: 19.2280986 },
    { lat: 48.17443, lng: 19.2282573 },
    { lat: 48.1741295, lng: 19.2285414 },
    { lat: 48.1740455, lng: 19.2285103 },
    { lat: 48.1737876, lng: 19.2286509 },
    { lat: 48.1732607, lng: 19.2291483 },
    { lat: 48.1728687, lng: 19.2293389 },
    { lat: 48.1725007, lng: 19.2302695 },
    { lat: 48.1725831, lng: 19.2309382 },
    { lat: 48.1725117, lng: 19.2315043 },
    { lat: 48.1724445, lng: 19.2314697 },
    { lat: 48.1721774, lng: 19.2310328 },
    { lat: 48.1718957, lng: 19.2311978 },
    { lat: 48.1712695, lng: 19.2318301 },
    { lat: 48.1698367, lng: 19.2314831 },
    { lat: 48.1689943, lng: 19.2305344 },
    { lat: 48.1670578, lng: 19.2294976 },
    { lat: 48.1654573, lng: 19.2283535 },
    { lat: 48.1648929, lng: 19.2284281 },
    { lat: 48.1646698, lng: 19.2285468 },
    { lat: 48.1643664, lng: 19.2285878 },
    { lat: 48.1636238, lng: 19.228296 },
    { lat: 48.1628475, lng: 19.227754 },
    { lat: 48.1607614, lng: 19.2293698 },
    { lat: 48.1600092, lng: 19.2296623 },
    { lat: 48.159569, lng: 19.229742 },
    { lat: 48.1595562, lng: 19.2295969 },
    { lat: 48.1588487, lng: 19.2290558 },
    { lat: 48.1574324, lng: 19.2275503 },
    { lat: 48.157246, lng: 19.2272453 },
    { lat: 48.1563838, lng: 19.2282813 },
    { lat: 48.1562847, lng: 19.2286286 },
    { lat: 48.1554904, lng: 19.2276008 },
    { lat: 48.1549669, lng: 19.2279377 },
    { lat: 48.1545719, lng: 19.2285709 },
    { lat: 48.1544119, lng: 19.2287089 },
    { lat: 48.1543876, lng: 19.2291577 },
    { lat: 48.1541287, lng: 19.2295571 },
    { lat: 48.1539002, lng: 19.2298166 },
    { lat: 48.1535284, lng: 19.2300282 },
    { lat: 48.1531416, lng: 19.2304348 },
    { lat: 48.1528535, lng: 19.2306521 },
    { lat: 48.1520432, lng: 19.2319231 },
    { lat: 48.1520158, lng: 19.2339128 },
    { lat: 48.1539456, lng: 19.2366058 },
    { lat: 48.1530093, lng: 19.2379713 },
    { lat: 48.15304, lng: 19.2380241 },
    { lat: 48.1543084, lng: 19.2390651 },
    { lat: 48.154701, lng: 19.2398521 },
    { lat: 48.1553178, lng: 19.2399742 },
    { lat: 48.1565769, lng: 19.2406421 },
    { lat: 48.1569399, lng: 19.2398113 },
    { lat: 48.1573992, lng: 19.2394751 },
    { lat: 48.1582591, lng: 19.2379272 },
    { lat: 48.1600439, lng: 19.235107 },
    { lat: 48.1619623, lng: 19.2376608 },
    { lat: 48.1622021, lng: 19.2380907 },
    { lat: 48.1624073, lng: 19.2386187 },
    { lat: 48.1628455, lng: 19.2383586 },
    { lat: 48.1631601, lng: 19.2385459 },
    { lat: 48.1638288, lng: 19.2382517 },
    { lat: 48.1641551, lng: 19.2389293 },
    { lat: 48.164409, lng: 19.2393047 },
    { lat: 48.1645155, lng: 19.2392715 },
    { lat: 48.1646076, lng: 19.2396569 },
    { lat: 48.1652192, lng: 19.24068 },
    { lat: 48.1658605, lng: 19.2418295 },
    { lat: 48.1663916, lng: 19.2427583 },
    { lat: 48.1662334, lng: 19.2429998 },
    { lat: 48.1658047, lng: 19.2441215 },
    { lat: 48.1656141, lng: 19.2448362 },
    { lat: 48.1653694, lng: 19.2453385 },
    { lat: 48.1662025, lng: 19.2462171 },
    { lat: 48.1667738, lng: 19.2471826 },
    { lat: 48.1669686, lng: 19.2476828 },
    { lat: 48.1672847, lng: 19.2482005 },
    { lat: 48.1674698, lng: 19.2483689 },
    { lat: 48.1683803, lng: 19.2497928 },
    { lat: 48.1688199, lng: 19.2503931 },
    { lat: 48.1695123, lng: 19.2492433 },
    { lat: 48.1702516, lng: 19.2489182 },
    { lat: 48.1703129, lng: 19.2489747 },
    { lat: 48.1706527, lng: 19.2489143 },
    { lat: 48.1711646, lng: 19.2484064 },
    { lat: 48.1718587, lng: 19.2478492 },
    { lat: 48.1724201, lng: 19.2480602 },
    { lat: 48.1727698, lng: 19.2483193 },
    { lat: 48.1736194, lng: 19.2493951 },
    { lat: 48.1743446, lng: 19.2506046 },
    { lat: 48.1753025, lng: 19.2519373 },
    { lat: 48.1763202, lng: 19.2532014 },
    { lat: 48.1765192, lng: 19.2529858 },
    { lat: 48.1769974, lng: 19.252157 },
    { lat: 48.1779159, lng: 19.2532367 },
    { lat: 48.1784388, lng: 19.2540683 },
    { lat: 48.1784804, lng: 19.2542961 },
    { lat: 48.1789878, lng: 19.2540698 },
    { lat: 48.179322, lng: 19.2536667 },
    { lat: 48.1797766, lng: 19.2537612 },
    { lat: 48.1798906, lng: 19.2531646 },
    { lat: 48.1801587, lng: 19.2532321 },
    { lat: 48.1804692, lng: 19.2525716 },
    { lat: 48.18076, lng: 19.2522044 },
    { lat: 48.1808381, lng: 19.2520002 },
    { lat: 48.1817672, lng: 19.2515473 },
    { lat: 48.1819797, lng: 19.2512556 },
    { lat: 48.1823059, lng: 19.2510374 },
    { lat: 48.1824223, lng: 19.2507662 },
    { lat: 48.1827907, lng: 19.2503678 },
    { lat: 48.183134, lng: 19.2501261 },
    { lat: 48.1832835, lng: 19.2493334 },
    { lat: 48.1839607, lng: 19.2481959 },
    { lat: 48.1845723, lng: 19.247009 },
    { lat: 48.1853367, lng: 19.2458386 },
    { lat: 48.1855429, lng: 19.2454423 },
    { lat: 48.1856431, lng: 19.2448885 },
    { lat: 48.1863655, lng: 19.2446297 },
    { lat: 48.1866076, lng: 19.2447632 },
    { lat: 48.1877286, lng: 19.2448212 },
    { lat: 48.1879026, lng: 19.2447855 },
    { lat: 48.1883381, lng: 19.2443539 },
    { lat: 48.1886999, lng: 19.2432931 },
    { lat: 48.1895708, lng: 19.2417622 },
    { lat: 48.1905834, lng: 19.2398113 },
    { lat: 48.1914768, lng: 19.2383004 },
    { lat: 48.1927373, lng: 19.2359332 },
    { lat: 48.1931033, lng: 19.235997 },
    { lat: 48.1940864, lng: 19.2358384 },
    { lat: 48.1943714, lng: 19.2360181 },
    { lat: 48.1948897, lng: 19.2360826 },
    { lat: 48.1953733, lng: 19.2358095 },
    { lat: 48.1957694, lng: 19.2357937 },
    { lat: 48.1962848, lng: 19.235634 },
    { lat: 48.1970149, lng: 19.2361596 },
    { lat: 48.1978361, lng: 19.2362404 },
    { lat: 48.1983562, lng: 19.2363883 },
    { lat: 48.1990518, lng: 19.2362704 },
    { lat: 48.1992834, lng: 19.2358914 },
    { lat: 48.2003525, lng: 19.2356937 },
    { lat: 48.2012147, lng: 19.2357632 },
    { lat: 48.2029545, lng: 19.2357695 },
    { lat: 48.2024263, lng: 19.231891 },
    { lat: 48.2023345, lng: 19.2305233 },
    { lat: 48.2036029, lng: 19.2299284 },
    { lat: 48.2053181, lng: 19.229871 },
    { lat: 48.2070149, lng: 19.2296712 },
    { lat: 48.2090532, lng: 19.2296077 },
    { lat: 48.2102341, lng: 19.2294598 },
    { lat: 48.213581, lng: 19.229439 },
    { lat: 48.2142741, lng: 19.2292771 },
    { lat: 48.2152485, lng: 19.2294463 },
    { lat: 48.217188, lng: 19.2295262 },
    { lat: 48.218019, lng: 19.2293912 },
    { lat: 48.2197598, lng: 19.2293561 },
    { lat: 48.2214712, lng: 19.2292203 },
    { lat: 48.2223374, lng: 19.2294137 },
    { lat: 48.2246036, lng: 19.230619 },
    { lat: 48.2258803, lng: 19.2313942 },
    { lat: 48.2269941, lng: 19.2318105 },
    { lat: 48.2284471, lng: 19.2328192 },
    { lat: 48.2292271, lng: 19.2327955 },
    { lat: 48.2300853, lng: 19.2324826 },
    { lat: 48.2320899, lng: 19.2320513 },
    { lat: 48.2328505, lng: 19.2320025 },
    { lat: 48.2337273, lng: 19.2320644 },
    { lat: 48.2351545, lng: 19.2319294 },
    { lat: 48.2356648, lng: 19.2328426 },
    { lat: 48.2371174, lng: 19.2342635 },
    { lat: 48.2374959, lng: 19.235135 },
    { lat: 48.237684, lng: 19.2353163 },
    { lat: 48.2386044, lng: 19.2373748 },
    { lat: 48.2391932, lng: 19.238189 },
    { lat: 48.2398662, lng: 19.2386987 },
    { lat: 48.2401713, lng: 19.2388304 },
    { lat: 48.240887, lng: 19.2389659 },
    { lat: 48.2414826, lng: 19.2391613 },
    { lat: 48.2416733, lng: 19.2392306 },
    { lat: 48.2424156, lng: 19.2395421 },
    { lat: 48.2449771, lng: 19.2400841 },
    { lat: 48.2458119, lng: 19.2400506 },
    { lat: 48.2476935, lng: 19.2395711 },
  ],
  VeľkýLom: [
    { lat: 48.3447956, lng: 19.3896062 },
    { lat: 48.3447537, lng: 19.3889368 },
    { lat: 48.3457075, lng: 19.3889199 },
    { lat: 48.3461615, lng: 19.3888218 },
    { lat: 48.3496706, lng: 19.3875969 },
    { lat: 48.3560367, lng: 19.3836422 },
    { lat: 48.3569988, lng: 19.3829 },
    { lat: 48.3582367, lng: 19.3828814 },
    { lat: 48.3587341, lng: 19.3827722 },
    { lat: 48.3590626, lng: 19.3829454 },
    { lat: 48.3594858, lng: 19.3833367 },
    { lat: 48.3605352, lng: 19.3833893 },
    { lat: 48.3611626, lng: 19.3836886 },
    { lat: 48.3619008, lng: 19.383072 },
    { lat: 48.3630052, lng: 19.3832551 },
    { lat: 48.3638841, lng: 19.3834216 },
    { lat: 48.3653307, lng: 19.3826264 },
    { lat: 48.3656928, lng: 19.3817774 },
    { lat: 48.3664914, lng: 19.3807247 },
    { lat: 48.3667517, lng: 19.3801542 },
    { lat: 48.3672884, lng: 19.3792577 },
    { lat: 48.3678819, lng: 19.3783917 },
    { lat: 48.3681099, lng: 19.377663 },
    { lat: 48.369061, lng: 19.376479 },
    { lat: 48.368905, lng: 19.376543 },
    { lat: 48.368431, lng: 19.376833 },
    { lat: 48.368218, lng: 19.376958 },
    { lat: 48.367469, lng: 19.3774 },
    { lat: 48.36716, lng: 19.377579 },
    { lat: 48.366861, lng: 19.377678 },
    { lat: 48.366221, lng: 19.378055 },
    { lat: 48.365547, lng: 19.378478 },
    { lat: 48.364958, lng: 19.378256 },
    { lat: 48.364254, lng: 19.37798 },
    { lat: 48.363709, lng: 19.377826 },
    { lat: 48.363631, lng: 19.377832 },
    { lat: 48.363172, lng: 19.377852 },
    { lat: 48.362705, lng: 19.377769 },
    { lat: 48.362527, lng: 19.377218 },
    { lat: 48.362521, lng: 19.377185 },
    { lat: 48.362404, lng: 19.376777 },
    { lat: 48.361824, lng: 19.376698 },
    { lat: 48.361124, lng: 19.376616 },
    { lat: 48.361015, lng: 19.376597 },
    { lat: 48.360554, lng: 19.376505 },
    { lat: 48.359955, lng: 19.376453 },
    { lat: 48.359334, lng: 19.376365 },
    { lat: 48.359162, lng: 19.376341 },
    { lat: 48.359083, lng: 19.376331 },
    { lat: 48.358555, lng: 19.37626 },
    { lat: 48.357549, lng: 19.376133 },
    { lat: 48.356962, lng: 19.37606 },
    { lat: 48.356496, lng: 19.375872 },
    { lat: 48.356339, lng: 19.375808 },
    { lat: 48.356058, lng: 19.375686 },
    { lat: 48.355675, lng: 19.375514 },
    { lat: 48.355615, lng: 19.375189 },
    { lat: 48.355498, lng: 19.374789 },
    { lat: 48.355421, lng: 19.374631 },
    { lat: 48.355085, lng: 19.373961 },
    { lat: 48.354915, lng: 19.374012 },
    { lat: 48.354826, lng: 19.374096 },
    { lat: 48.354726, lng: 19.37401 },
    { lat: 48.354657, lng: 19.37382 },
    { lat: 48.354518, lng: 19.373429 },
    { lat: 48.354411, lng: 19.373306 },
    { lat: 48.354309, lng: 19.373198 },
    { lat: 48.354061, lng: 19.373026 },
    { lat: 48.353947, lng: 19.373003 },
    { lat: 48.353748, lng: 19.373018 },
    { lat: 48.353678, lng: 19.373051 },
    { lat: 48.353281, lng: 19.373337 },
    { lat: 48.352907, lng: 19.373632 },
    { lat: 48.352814, lng: 19.373422 },
    { lat: 48.3527, lng: 19.373201 },
    { lat: 48.352577, lng: 19.372928 },
    { lat: 48.352373, lng: 19.372526 },
    { lat: 48.352171, lng: 19.372219 },
    { lat: 48.35211, lng: 19.372135 },
    { lat: 48.351923, lng: 19.3718 },
    { lat: 48.351705, lng: 19.371434 },
    { lat: 48.351847, lng: 19.371391 },
    { lat: 48.351813, lng: 19.371202 },
    { lat: 48.351789, lng: 19.371105 },
    { lat: 48.351754, lng: 19.370969 },
    { lat: 48.351686, lng: 19.370801 },
    { lat: 48.351645, lng: 19.370703 },
    { lat: 48.351618, lng: 19.370581 },
    { lat: 48.351613, lng: 19.370557 },
    { lat: 48.351603, lng: 19.3705 },
    { lat: 48.351548, lng: 19.370123 },
    { lat: 48.351552, lng: 19.370017 },
    { lat: 48.351555, lng: 19.369924 },
    { lat: 48.351505, lng: 19.369743 },
    { lat: 48.351505, lng: 19.369614 },
    { lat: 48.351505, lng: 19.369313 },
    { lat: 48.351468, lng: 19.369127 },
    { lat: 48.351448, lng: 19.369027 },
    { lat: 48.351421, lng: 19.368888 },
    { lat: 48.35149, lng: 19.368841 },
    { lat: 48.351476, lng: 19.368714 },
    { lat: 48.351456, lng: 19.368619 },
    { lat: 48.351448, lng: 19.368552 },
    { lat: 48.351419, lng: 19.368325 },
    { lat: 48.351388, lng: 19.368148 },
    { lat: 48.35138, lng: 19.368124 },
    { lat: 48.351325, lng: 19.367964 },
    { lat: 48.35135, lng: 19.36794 },
    { lat: 48.351326, lng: 19.367862 },
    { lat: 48.351294, lng: 19.367698 },
    { lat: 48.351268, lng: 19.367534 },
    { lat: 48.351209, lng: 19.367194 },
    { lat: 48.351141, lng: 19.366837 },
    { lat: 48.351104, lng: 19.366667 },
    { lat: 48.351054, lng: 19.366421 },
    { lat: 48.351834, lng: 19.366102 },
    { lat: 48.351774, lng: 19.36545 },
    { lat: 48.351509, lng: 19.365417 },
    { lat: 48.351002, lng: 19.365453 },
    { lat: 48.350996, lng: 19.365386 },
    { lat: 48.350995, lng: 19.365372 },
    { lat: 48.350989, lng: 19.365309 },
    { lat: 48.350975, lng: 19.365157 },
    { lat: 48.351141, lng: 19.365095 },
    { lat: 48.35114, lng: 19.365041 },
    { lat: 48.351137, lng: 19.364975 },
    { lat: 48.351091, lng: 19.364593 },
    { lat: 48.351022, lng: 19.364293 },
    { lat: 48.351009, lng: 19.364141 },
    { lat: 48.351002, lng: 19.364066 },
    { lat: 48.350995, lng: 19.363986 },
    { lat: 48.350982, lng: 19.363878 },
    { lat: 48.350971, lng: 19.36376 },
    { lat: 48.350962, lng: 19.363683 },
    { lat: 48.350957, lng: 19.36364 },
    { lat: 48.350954, lng: 19.363587 },
    { lat: 48.350914, lng: 19.363368 },
    { lat: 48.350835, lng: 19.363096 },
    { lat: 48.350616, lng: 19.362934 },
    { lat: 48.3505, lng: 19.362883 },
    { lat: 48.350376, lng: 19.362826 },
    { lat: 48.350323, lng: 19.362806 },
    { lat: 48.350252, lng: 19.36278 },
    { lat: 48.350129, lng: 19.362754 },
    { lat: 48.349812, lng: 19.362696 },
    { lat: 48.349612, lng: 19.362694 },
    { lat: 48.349215, lng: 19.362548 },
    { lat: 48.349068, lng: 19.36251 },
    { lat: 48.349051, lng: 19.361922 },
    { lat: 48.349049, lng: 19.361868 },
    { lat: 48.349048, lng: 19.361804 },
    { lat: 48.349026, lng: 19.361757 },
    { lat: 48.348902, lng: 19.360941 },
    { lat: 48.348723, lng: 19.360358 },
    { lat: 48.348718, lng: 19.360292 },
    { lat: 48.348701, lng: 19.360082 },
    { lat: 48.348687, lng: 19.359892 },
    { lat: 48.348673, lng: 19.359703 },
    { lat: 48.348663, lng: 19.359565 },
    { lat: 48.348656, lng: 19.359482 },
    { lat: 48.348644, lng: 19.359436 },
    { lat: 48.348688, lng: 19.359415 },
    { lat: 48.348628, lng: 19.358482 },
    { lat: 48.348549, lng: 19.357466 },
    { lat: 48.348504, lng: 19.356925 },
    { lat: 48.348482, lng: 19.356624 },
    { lat: 48.34846, lng: 19.356348 },
    { lat: 48.348456, lng: 19.356024 },
    { lat: 48.348454, lng: 19.355435 },
    { lat: 48.348441, lng: 19.354665 },
    { lat: 48.348406, lng: 19.354671 },
    { lat: 48.348299, lng: 19.354671 },
    { lat: 48.348248, lng: 19.354663 },
    { lat: 48.347805, lng: 19.354598 },
    { lat: 48.347291, lng: 19.354528 },
    { lat: 48.347241, lng: 19.354518 },
    { lat: 48.34706, lng: 19.354405 },
    { lat: 48.347095, lng: 19.354279 },
    { lat: 48.346495, lng: 19.354332 },
    { lat: 48.346458, lng: 19.354344 },
    { lat: 48.346247, lng: 19.354404 },
    { lat: 48.345867, lng: 19.354312 },
    { lat: 48.345539, lng: 19.354549 },
    { lat: 48.345254, lng: 19.353697 },
    { lat: 48.345443, lng: 19.353468 },
    { lat: 48.345708, lng: 19.353276 },
    { lat: 48.346147, lng: 19.353073 },
    { lat: 48.346129, lng: 19.352999 },
    { lat: 48.346069, lng: 19.352649 },
    { lat: 48.34571, lng: 19.35256 },
    { lat: 48.345597, lng: 19.352546 },
    { lat: 48.345606, lng: 19.352455 },
    { lat: 48.345613, lng: 19.352137 },
    { lat: 48.345667, lng: 19.351538 },
    { lat: 48.34525, lng: 19.351646 },
    { lat: 48.344829, lng: 19.351878 },
    { lat: 48.344502, lng: 19.352191 },
    { lat: 48.343982, lng: 19.352535 },
    { lat: 48.343637, lng: 19.352671 },
    { lat: 48.343311, lng: 19.35268 },
    { lat: 48.342463, lng: 19.352538 },
    { lat: 48.342099, lng: 19.352077 },
    { lat: 48.341738, lng: 19.351621 },
    { lat: 48.341683, lng: 19.351473 },
    { lat: 48.34124, lng: 19.35116 },
    { lat: 48.341036, lng: 19.350896 },
    { lat: 48.3396, lng: 19.35029 },
    { lat: 48.338824, lng: 19.35014 },
    { lat: 48.336973, lng: 19.350189 },
    { lat: 48.336504, lng: 19.350403 },
    { lat: 48.336453, lng: 19.350391 },
    { lat: 48.336428, lng: 19.350384 },
    { lat: 48.33639, lng: 19.350374 },
    { lat: 48.336348, lng: 19.350368 },
    { lat: 48.33631, lng: 19.350374 },
    { lat: 48.336231, lng: 19.350375 },
    { lat: 48.336141, lng: 19.35036 },
    { lat: 48.33605, lng: 19.350337 },
    { lat: 48.336012, lng: 19.350318 },
    { lat: 48.335965, lng: 19.350302 },
    { lat: 48.33588, lng: 19.350259 },
    { lat: 48.335716, lng: 19.350231 },
    { lat: 48.335581, lng: 19.350203 },
    { lat: 48.335409, lng: 19.350199 },
    { lat: 48.335291, lng: 19.350197 },
    { lat: 48.335257, lng: 19.350195 },
    { lat: 48.335225, lng: 19.350196 },
    { lat: 48.335189, lng: 19.350204 },
    { lat: 48.33514, lng: 19.350208 },
    { lat: 48.335039, lng: 19.350082 },
    { lat: 48.334605, lng: 19.349891 },
    { lat: 48.334426, lng: 19.349779 },
    { lat: 48.334166, lng: 19.349549 },
    { lat: 48.333842, lng: 19.34942 },
    { lat: 48.33381, lng: 19.349611 },
    { lat: 48.333582, lng: 19.349279 },
    { lat: 48.333338, lng: 19.349062 },
    { lat: 48.33322, lng: 19.348954 },
    { lat: 48.333013, lng: 19.34877 },
    { lat: 48.332939, lng: 19.348682 },
    { lat: 48.332841, lng: 19.34857 },
    { lat: 48.332744, lng: 19.34846 },
    { lat: 48.332553, lng: 19.34828 },
    { lat: 48.332477, lng: 19.348192 },
    { lat: 48.332418, lng: 19.348118 },
    { lat: 48.332234, lng: 19.347865 },
    { lat: 48.331993, lng: 19.34739 },
    { lat: 48.331268, lng: 19.346454 },
    { lat: 48.330771, lng: 19.346062 },
    { lat: 48.329953, lng: 19.345675 },
    { lat: 48.32948, lng: 19.345411 },
    { lat: 48.329355, lng: 19.345343 },
    { lat: 48.3291669, lng: 19.3461371 },
    { lat: 48.3285442, lng: 19.3472051 },
    { lat: 48.3276062, lng: 19.3496525 },
    { lat: 48.3257704, lng: 19.3521567 },
    { lat: 48.3255021, lng: 19.3527203 },
    { lat: 48.3251026, lng: 19.3545019 },
    { lat: 48.3250753, lng: 19.3549691 },
    { lat: 48.3247835, lng: 19.3555604 },
    { lat: 48.3243367, lng: 19.3571079 },
    { lat: 48.3242603, lng: 19.3575152 },
    { lat: 48.3242455, lng: 19.3579577 },
    { lat: 48.3240918, lng: 19.3584485 },
    { lat: 48.3236349, lng: 19.359311 },
    { lat: 48.3224015, lng: 19.3598255 },
    { lat: 48.3220839, lng: 19.3599939 },
    { lat: 48.3218944, lng: 19.3601817 },
    { lat: 48.3217086, lng: 19.3605823 },
    { lat: 48.3210088, lng: 19.3633251 },
    { lat: 48.3206125, lng: 19.3645182 },
    { lat: 48.3197655, lng: 19.3643385 },
    { lat: 48.3181462, lng: 19.3645107 },
    { lat: 48.317503, lng: 19.364851 },
    { lat: 48.3167716, lng: 19.3651093 },
    { lat: 48.3160339, lng: 19.3657365 },
    { lat: 48.3157882, lng: 19.3679342 },
    { lat: 48.3154559, lng: 19.3692183 },
    { lat: 48.3149707, lng: 19.3702458 },
    { lat: 48.3146445, lng: 19.3713458 },
    { lat: 48.3144051, lng: 19.37174 },
    { lat: 48.3132432, lng: 19.3732581 },
    { lat: 48.3115551, lng: 19.3744908 },
    { lat: 48.3101805, lng: 19.3753775 },
    { lat: 48.3101621, lng: 19.375463 },
    { lat: 48.3101458, lng: 19.3755472 },
    { lat: 48.3100763, lng: 19.3760068 },
    { lat: 48.3102263, lng: 19.3769301 },
    { lat: 48.3114411, lng: 19.3774944 },
    { lat: 48.3133167, lng: 19.3779962 },
    { lat: 48.3143256, lng: 19.3792997 },
    { lat: 48.3160101, lng: 19.3822058 },
    { lat: 48.3170011, lng: 19.3825442 },
    { lat: 48.3177067, lng: 19.383018 },
    { lat: 48.3184631, lng: 19.3837824 },
    { lat: 48.3187168, lng: 19.383971 },
    { lat: 48.3198928, lng: 19.3844105 },
    { lat: 48.3207961, lng: 19.3854379 },
    { lat: 48.3215968, lng: 19.385636 },
    { lat: 48.3224309, lng: 19.3857349 },
    { lat: 48.3228244, lng: 19.3855714 },
    { lat: 48.3229839, lng: 19.385349 },
    { lat: 48.3231042, lng: 19.3852908 },
    { lat: 48.3236651, lng: 19.3853311 },
    { lat: 48.3240559, lng: 19.3849703 },
    { lat: 48.3242553, lng: 19.3849684 },
    { lat: 48.3245637, lng: 19.3851501 },
    { lat: 48.3247422, lng: 19.3851841 },
    { lat: 48.3250461, lng: 19.3851481 },
    { lat: 48.3252923, lng: 19.3848013 },
    { lat: 48.3256445, lng: 19.3847431 },
    { lat: 48.325923, lng: 19.3845561 },
    { lat: 48.3264458, lng: 19.3844103 },
    { lat: 48.3267096, lng: 19.3840814 },
    { lat: 48.3271446, lng: 19.3836985 },
    { lat: 48.3272739, lng: 19.3836698 },
    { lat: 48.3280955, lng: 19.3837881 },
    { lat: 48.3285607, lng: 19.3839463 },
    { lat: 48.3286841, lng: 19.3840531 },
    { lat: 48.3287901, lng: 19.3844087 },
    { lat: 48.3289353, lng: 19.3846677 },
    { lat: 48.3292739, lng: 19.3845802 },
    { lat: 48.3293532, lng: 19.3846638 },
    { lat: 48.3294808, lng: 19.3851878 },
    { lat: 48.3297911, lng: 19.3854808 },
    { lat: 48.3298557, lng: 19.3865322 },
    { lat: 48.3301104, lng: 19.3871489 },
    { lat: 48.3305991, lng: 19.3877321 },
    { lat: 48.3311884, lng: 19.3879926 },
    { lat: 48.3313022, lng: 19.3884384 },
    { lat: 48.3314988, lng: 19.3886203 },
    { lat: 48.3320518, lng: 19.3887522 },
    { lat: 48.3324579, lng: 19.3885002 },
    { lat: 48.3336063, lng: 19.3885922 },
    { lat: 48.3339648, lng: 19.3884275 },
    { lat: 48.3342919, lng: 19.3885271 },
    { lat: 48.3363289, lng: 19.3882525 },
    { lat: 48.3363848, lng: 19.3880283 },
    { lat: 48.3384432, lng: 19.3866575 },
    { lat: 48.3388101, lng: 19.3870275 },
    { lat: 48.3389308, lng: 19.3870025 },
    { lat: 48.3391848, lng: 19.3868018 },
    { lat: 48.3397606, lng: 19.3867509 },
    { lat: 48.339934, lng: 19.3868167 },
    { lat: 48.3402044, lng: 19.3867229 },
    { lat: 48.3405181, lng: 19.3869183 },
    { lat: 48.3406197, lng: 19.3870424 },
    { lat: 48.340847, lng: 19.3869405 },
    { lat: 48.3410245, lng: 19.3870672 },
    { lat: 48.3412847, lng: 19.3870911 },
    { lat: 48.3413528, lng: 19.3872659 },
    { lat: 48.34155, lng: 19.3872161 },
    { lat: 48.3417246, lng: 19.3872634 },
    { lat: 48.3419202, lng: 19.3875362 },
    { lat: 48.3422478, lng: 19.3876216 },
    { lat: 48.3425131, lng: 19.3877729 },
    { lat: 48.3430777, lng: 19.3882482 },
    { lat: 48.3432132, lng: 19.3882267 },
    { lat: 48.3433063, lng: 19.3883365 },
    { lat: 48.3433664, lng: 19.388538 },
    { lat: 48.3435048, lng: 19.3886731 },
    { lat: 48.3436737, lng: 19.3886681 },
    { lat: 48.3438104, lng: 19.3887478 },
    { lat: 48.3438693, lng: 19.3889675 },
    { lat: 48.343904, lng: 19.3889882 },
    { lat: 48.3440284, lng: 19.3888524 },
    { lat: 48.3441315, lng: 19.3891028 },
    { lat: 48.3445059, lng: 19.3895826 },
    { lat: 48.3446637, lng: 19.389678 },
    { lat: 48.3447956, lng: 19.3896062 },
  ],
  Hrušov: [
    { lat: 48.1526545, lng: 19.1207797 },
    { lat: 48.1528137, lng: 19.1210754 },
    { lat: 48.1530295, lng: 19.1210838 },
    { lat: 48.1534563, lng: 19.1208257 },
    { lat: 48.1535807, lng: 19.1205174 },
    { lat: 48.153735, lng: 19.1203335 },
    { lat: 48.1539409, lng: 19.1203737 },
    { lat: 48.1544858, lng: 19.12108 },
    { lat: 48.1549035, lng: 19.1211142 },
    { lat: 48.15523, lng: 19.1213496 },
    { lat: 48.1554812, lng: 19.1216411 },
    { lat: 48.1557373, lng: 19.121759 },
    { lat: 48.1561281, lng: 19.1222223 },
    { lat: 48.1567492, lng: 19.122177 },
    { lat: 48.1574065, lng: 19.1224044 },
    { lat: 48.1576193, lng: 19.1223524 },
    { lat: 48.1583577, lng: 19.1226011 },
    { lat: 48.1587792, lng: 19.1229368 },
    { lat: 48.1594832, lng: 19.1232819 },
    { lat: 48.1598478, lng: 19.1233595 },
    { lat: 48.1602177, lng: 19.1235431 },
    { lat: 48.1604296, lng: 19.1237214 },
    { lat: 48.1606045, lng: 19.1240401 },
    { lat: 48.1606717, lng: 19.1240559 },
    { lat: 48.1610024, lng: 19.1238809 },
    { lat: 48.1613756, lng: 19.1232757 },
    { lat: 48.1614213, lng: 19.1222519 },
    { lat: 48.1617695, lng: 19.1213016 },
    { lat: 48.1623392, lng: 19.1210583 },
    { lat: 48.1623961, lng: 19.1208884 },
    { lat: 48.1622709, lng: 19.1203254 },
    { lat: 48.1625243, lng: 19.1200506 },
    { lat: 48.1629758, lng: 19.1202639 },
    { lat: 48.1634964, lng: 19.1199868 },
    { lat: 48.1639907, lng: 19.1193242 },
    { lat: 48.1645172, lng: 19.1189147 },
    { lat: 48.1650942, lng: 19.1187764 },
    { lat: 48.1654405, lng: 19.1188027 },
    { lat: 48.1656825, lng: 19.1181866 },
    { lat: 48.1661368, lng: 19.118026 },
    { lat: 48.1661906, lng: 19.1176143 },
    { lat: 48.1664151, lng: 19.11685 },
    { lat: 48.1667849, lng: 19.1168285 },
    { lat: 48.167209, lng: 19.1165663 },
    { lat: 48.1676982, lng: 19.1161585 },
    { lat: 48.168851, lng: 19.1157657 },
    { lat: 48.1693375, lng: 19.1150544 },
    { lat: 48.1696305, lng: 19.1148059 },
    { lat: 48.1698817, lng: 19.1144614 },
    { lat: 48.1701976, lng: 19.1141756 },
    { lat: 48.1705983, lng: 19.1134393 },
    { lat: 48.1707029, lng: 19.1133488 },
    { lat: 48.1713188, lng: 19.1132637 },
    { lat: 48.1716712, lng: 19.1134323 },
    { lat: 48.1717718, lng: 19.1133694 },
    { lat: 48.1720666, lng: 19.1133608 },
    { lat: 48.1721752, lng: 19.1133911 },
    { lat: 48.1723672, lng: 19.1137351 },
    { lat: 48.172635, lng: 19.1136815 },
    { lat: 48.1727595, lng: 19.1137145 },
    { lat: 48.1729595, lng: 19.1139475 },
    { lat: 48.1731361, lng: 19.1139376 },
    { lat: 48.1734163, lng: 19.113498 },
    { lat: 48.1736731, lng: 19.1132238 },
    { lat: 48.1739069, lng: 19.1131673 },
    { lat: 48.1742929, lng: 19.1128348 },
    { lat: 48.174696, lng: 19.1128946 },
    { lat: 48.1748501, lng: 19.1128205 },
    { lat: 48.1750368, lng: 19.1128769 },
    { lat: 48.1754238, lng: 19.1126143 },
    { lat: 48.175794, lng: 19.1127141 },
    { lat: 48.1760286, lng: 19.1121524 },
    { lat: 48.1762611, lng: 19.1120441 },
    { lat: 48.176415, lng: 19.1123476 },
    { lat: 48.1764911, lng: 19.1122695 },
    { lat: 48.1766203, lng: 19.1119474 },
    { lat: 48.1770053, lng: 19.1115496 },
    { lat: 48.1774135, lng: 19.1113514 },
    { lat: 48.1774588, lng: 19.1113551 },
    { lat: 48.1775185, lng: 19.1117815 },
    { lat: 48.1776337, lng: 19.1119215 },
    { lat: 48.1777291, lng: 19.1118452 },
    { lat: 48.1778645, lng: 19.1113987 },
    { lat: 48.1783622, lng: 19.110982 },
    { lat: 48.1788308, lng: 19.1107678 },
    { lat: 48.1794221, lng: 19.1111156 },
    { lat: 48.179681, lng: 19.1111774 },
    { lat: 48.1797483, lng: 19.1111257 },
    { lat: 48.179886, lng: 19.1106108 },
    { lat: 48.1800004, lng: 19.1105775 },
    { lat: 48.1803632, lng: 19.1110003 },
    { lat: 48.1804898, lng: 19.1110052 },
    { lat: 48.1807249, lng: 19.1107701 },
    { lat: 48.1810468, lng: 19.1107161 },
    { lat: 48.1811977, lng: 19.1111163 },
    { lat: 48.1816174, lng: 19.1110584 },
    { lat: 48.1818985, lng: 19.1112643 },
    { lat: 48.1820842, lng: 19.1116653 },
    { lat: 48.1822393, lng: 19.1118567 },
    { lat: 48.1823966, lng: 19.1117565 },
    { lat: 48.1825175, lng: 19.1119224 },
    { lat: 48.1825056, lng: 19.1122685 },
    { lat: 48.1828028, lng: 19.1122179 },
    { lat: 48.1831203, lng: 19.1125671 },
    { lat: 48.1833263, lng: 19.1126267 },
    { lat: 48.183526, lng: 19.1123175 },
    { lat: 48.18365, lng: 19.1122693 },
    { lat: 48.1838331, lng: 19.1125304 },
    { lat: 48.1840167, lng: 19.1125257 },
    { lat: 48.1843311, lng: 19.112228 },
    { lat: 48.1844842, lng: 19.1119548 },
    { lat: 48.1845545, lng: 19.1119374 },
    { lat: 48.1846274, lng: 19.1122712 },
    { lat: 48.1848498, lng: 19.1124335 },
    { lat: 48.1850619, lng: 19.1123702 },
    { lat: 48.1853222, lng: 19.1117561 },
    { lat: 48.1855439, lng: 19.1116842 },
    { lat: 48.1859094, lng: 19.1118927 },
    { lat: 48.1863289, lng: 19.1119602 },
    { lat: 48.1866036, lng: 19.1117945 },
    { lat: 48.1868322, lng: 19.111788 },
    { lat: 48.1871946, lng: 19.1120251 },
    { lat: 48.1872299, lng: 19.1118516 },
    { lat: 48.1872796, lng: 19.1118778 },
    { lat: 48.1876471, lng: 19.110615 },
    { lat: 48.1878539, lng: 19.1104007 },
    { lat: 48.1880515, lng: 19.1098642 },
    { lat: 48.1881149, lng: 19.1098952 },
    { lat: 48.188808, lng: 19.1090548 },
    { lat: 48.1892218, lng: 19.1081789 },
    { lat: 48.1899732, lng: 19.1073567 },
    { lat: 48.1901166, lng: 19.1072244 },
    { lat: 48.1909003, lng: 19.1068016 },
    { lat: 48.1912012, lng: 19.1067113 },
    { lat: 48.1918503, lng: 19.1061436 },
    { lat: 48.1921845, lng: 19.1059486 },
    { lat: 48.1928391, lng: 19.1057172 },
    { lat: 48.1935411, lng: 19.1051018 },
    { lat: 48.1946806, lng: 19.1042405 },
    { lat: 48.1957334, lng: 19.1037437 },
    { lat: 48.1958883, lng: 19.1033214 },
    { lat: 48.19608, lng: 19.1023109 },
    { lat: 48.1962427, lng: 19.1010848 },
    { lat: 48.1961766, lng: 19.0996725 },
    { lat: 48.1962581, lng: 19.0989856 },
    { lat: 48.1962885, lng: 19.0967376 },
    { lat: 48.1973366, lng: 19.093587 },
    { lat: 48.1975244, lng: 19.0929037 },
    { lat: 48.1976036, lng: 19.092369 },
    { lat: 48.1977375, lng: 19.0905666 },
    { lat: 48.1982335, lng: 19.0887959 },
    { lat: 48.1982977, lng: 19.0878491 },
    { lat: 48.1985002, lng: 19.0877277 },
    { lat: 48.1984767, lng: 19.0876879 },
    { lat: 48.1986498, lng: 19.0874095 },
    { lat: 48.1991357, lng: 19.086153 },
    { lat: 48.1991652, lng: 19.0857775 },
    { lat: 48.1990872, lng: 19.0847615 },
    { lat: 48.1970991, lng: 19.0824208 },
    { lat: 48.1964761, lng: 19.0809976 },
    { lat: 48.1959829, lng: 19.0796422 },
    { lat: 48.1958519, lng: 19.0790297 },
    { lat: 48.1958072, lng: 19.0785269 },
    { lat: 48.1955728, lng: 19.0784611 },
    { lat: 48.1951335, lng: 19.0784922 },
    { lat: 48.1946399, lng: 19.0784065 },
    { lat: 48.1938405, lng: 19.0777312 },
    { lat: 48.1931385, lng: 19.077294 },
    { lat: 48.193286, lng: 19.0763937 },
    { lat: 48.193393, lng: 19.0760562 },
    { lat: 48.1935077, lng: 19.075421 },
    { lat: 48.1939375, lng: 19.0740383 },
    { lat: 48.1939408, lng: 19.0733447 },
    { lat: 48.194166, lng: 19.072108 },
    { lat: 48.19416, lng: 19.071745 },
    { lat: 48.194229, lng: 19.071545 },
    { lat: 48.194268, lng: 19.071342 },
    { lat: 48.194271, lng: 19.070953 },
    { lat: 48.194236, lng: 19.070829 },
    { lat: 48.193839, lng: 19.070542 },
    { lat: 48.19351, lng: 19.070484 },
    { lat: 48.193385, lng: 19.070841 },
    { lat: 48.193331, lng: 19.070869 },
    { lat: 48.193296, lng: 19.070842 },
    { lat: 48.19311, lng: 19.070338 },
    { lat: 48.192878, lng: 19.069989 },
    { lat: 48.192811, lng: 19.069945 },
    { lat: 48.192722, lng: 19.069808 },
    { lat: 48.192571, lng: 19.069367 },
    { lat: 48.192529, lng: 19.069239 },
    { lat: 48.192465, lng: 19.069151 },
    { lat: 48.191886, lng: 19.068352 },
    { lat: 48.190999, lng: 19.066866 },
    { lat: 48.190438, lng: 19.065897 },
    { lat: 48.190244, lng: 19.065544 },
    { lat: 48.189855, lng: 19.064886 },
    { lat: 48.189602, lng: 19.064456 },
    { lat: 48.189299, lng: 19.063936 },
    { lat: 48.189067, lng: 19.063536 },
    { lat: 48.188357, lng: 19.062315 },
    { lat: 48.188256, lng: 19.06238 },
    { lat: 48.188021, lng: 19.062839 },
    { lat: 48.187959, lng: 19.063553 },
    { lat: 48.18788, lng: 19.063808 },
    { lat: 48.187979, lng: 19.06386 },
    { lat: 48.187848, lng: 19.064733 },
    { lat: 48.187828, lng: 19.065565 },
    { lat: 48.18773, lng: 19.066698 },
    { lat: 48.187707, lng: 19.066906 },
    { lat: 48.187707, lng: 19.066933 },
    { lat: 48.187468, lng: 19.067016 },
    { lat: 48.186998, lng: 19.067091 },
    { lat: 48.186698, lng: 19.067137 },
    { lat: 48.186254, lng: 19.067169 },
    { lat: 48.18613, lng: 19.067336 },
    { lat: 48.186058, lng: 19.067448 },
    { lat: 48.185984, lng: 19.067634 },
    { lat: 48.185933, lng: 19.068156 },
    { lat: 48.186083, lng: 19.06923 },
    { lat: 48.186055, lng: 19.069231 },
    { lat: 48.185467, lng: 19.069218 },
    { lat: 48.185067, lng: 19.06912 },
    { lat: 48.184962, lng: 19.069249 },
    { lat: 48.184882, lng: 19.06935 },
    { lat: 48.184638, lng: 19.069895 },
    { lat: 48.184314, lng: 19.070362 },
    { lat: 48.18418, lng: 19.070556 },
    { lat: 48.183933, lng: 19.070089 },
    { lat: 48.18389, lng: 19.06979 },
    { lat: 48.183776, lng: 19.069631 },
    { lat: 48.183769, lng: 19.069533 },
    { lat: 48.183843, lng: 19.069294 },
    { lat: 48.183735, lng: 19.069138 },
    { lat: 48.183729, lng: 19.069067 },
    { lat: 48.18388, lng: 19.068871 },
    { lat: 48.183763, lng: 19.068613 },
    { lat: 48.183731, lng: 19.068405 },
    { lat: 48.183838, lng: 19.068365 },
    { lat: 48.183864, lng: 19.068264 },
    { lat: 48.183751, lng: 19.068183 },
    { lat: 48.183642, lng: 19.067942 },
    { lat: 48.183607, lng: 19.067809 },
    { lat: 48.183669, lng: 19.067649 },
    { lat: 48.183555, lng: 19.067169 },
    { lat: 48.183563, lng: 19.067021 },
    { lat: 48.183683, lng: 19.066778 },
    { lat: 48.183682, lng: 19.066521 },
    { lat: 48.183565, lng: 19.066446 },
    { lat: 48.183471, lng: 19.066335 },
    { lat: 48.183458, lng: 19.06605 },
    { lat: 48.183283, lng: 19.065849 },
    { lat: 48.183181, lng: 19.065861 },
    { lat: 48.183121, lng: 19.065658 },
    { lat: 48.183125, lng: 19.06534 },
    { lat: 48.183101, lng: 19.065191 },
    { lat: 48.183019, lng: 19.065153 },
    { lat: 48.18281, lng: 19.065238 },
    { lat: 48.182813, lng: 19.065009 },
    { lat: 48.18269, lng: 19.064934 },
    { lat: 48.182771, lng: 19.064663 },
    { lat: 48.182783, lng: 19.06443 },
    { lat: 48.182714, lng: 19.064358 },
    { lat: 48.182654, lng: 19.064446 },
    { lat: 48.18261, lng: 19.064336 },
    { lat: 48.182643, lng: 19.064227 },
    { lat: 48.182556, lng: 19.064046 },
    { lat: 48.182607, lng: 19.063865 },
    { lat: 48.182411, lng: 19.063771 },
    { lat: 48.182336, lng: 19.063581 },
    { lat: 48.182299, lng: 19.063305 },
    { lat: 48.182142, lng: 19.063274 },
    { lat: 48.182139, lng: 19.06311 },
    { lat: 48.182086, lng: 19.063048 },
    { lat: 48.182176, lng: 19.062881 },
    { lat: 48.182035, lng: 19.062676 },
    { lat: 48.181933, lng: 19.062644 },
    { lat: 48.181847, lng: 19.062459 },
    { lat: 48.181967, lng: 19.062212 },
    { lat: 48.181855, lng: 19.061967 },
    { lat: 48.181726, lng: 19.061924 },
    { lat: 48.181696, lng: 19.061747 },
    { lat: 48.181544, lng: 19.061703 },
    { lat: 48.181541, lng: 19.061565 },
    { lat: 48.181297, lng: 19.061337 },
    { lat: 48.181127, lng: 19.061208 },
    { lat: 48.180921, lng: 19.061268 },
    { lat: 48.180784, lng: 19.061123 },
    { lat: 48.1806, lng: 19.060743 },
    { lat: 48.180367, lng: 19.060461 },
    { lat: 48.180211, lng: 19.060334 },
    { lat: 48.180102, lng: 19.060166 },
    { lat: 48.179934, lng: 19.060168 },
    { lat: 48.179926, lng: 19.059969 },
    { lat: 48.179857, lng: 19.059835 },
    { lat: 48.179437, lng: 19.059689 },
    { lat: 48.179427, lng: 19.059693 },
    { lat: 48.179256, lng: 19.059578 },
    { lat: 48.179126, lng: 19.059675 },
    { lat: 48.178991, lng: 19.059181 },
    { lat: 48.178666, lng: 19.059057 },
    { lat: 48.178084, lng: 19.058546 },
    { lat: 48.177858, lng: 19.058526 },
    { lat: 48.177853, lng: 19.058329 },
    { lat: 48.177814, lng: 19.058245 },
    { lat: 48.177672, lng: 19.058124 },
    { lat: 48.177441, lng: 19.058188 },
    { lat: 48.177243, lng: 19.058011 },
    { lat: 48.177041, lng: 19.057948 },
    { lat: 48.176825, lng: 19.057683 },
    { lat: 48.176615, lng: 19.057578 },
    { lat: 48.176554, lng: 19.057533 },
    { lat: 48.176276, lng: 19.057515 },
    { lat: 48.176142, lng: 19.057461 },
    { lat: 48.17584, lng: 19.057407 },
    { lat: 48.175644, lng: 19.057464 },
    { lat: 48.175565, lng: 19.057484 },
    { lat: 48.175419, lng: 19.057412 },
    { lat: 48.175322, lng: 19.057477 },
    { lat: 48.175255, lng: 19.057479 },
    { lat: 48.175104, lng: 19.057351 },
    { lat: 48.174966, lng: 19.057171 },
    { lat: 48.174808, lng: 19.056928 },
    { lat: 48.17479, lng: 19.056992 },
    { lat: 48.174768, lng: 19.057071 },
    { lat: 48.174709, lng: 19.057328 },
    { lat: 48.17445, lng: 19.057519 },
    { lat: 48.174408, lng: 19.057551 },
    { lat: 48.174287, lng: 19.057638 },
    { lat: 48.173643, lng: 19.058191 },
    { lat: 48.173309, lng: 19.058467 },
    { lat: 48.172901, lng: 19.058793 },
    { lat: 48.172307, lng: 19.059124 },
    { lat: 48.1722, lng: 19.059176 },
    { lat: 48.171926, lng: 19.059185 },
    { lat: 48.170978, lng: 19.05926 },
    { lat: 48.17068, lng: 19.059589 },
    { lat: 48.170333, lng: 19.059979 },
    { lat: 48.170049, lng: 19.060119 },
    { lat: 48.16991, lng: 19.059772 },
    { lat: 48.169937, lng: 19.05944 },
    { lat: 48.169939, lng: 19.05908 },
    { lat: 48.169936, lng: 19.058746 },
    { lat: 48.169906, lng: 19.058605 },
    { lat: 48.169781, lng: 19.058238 },
    { lat: 48.169745, lng: 19.058238 },
    { lat: 48.169658, lng: 19.058245 },
    { lat: 48.169452, lng: 19.058179 },
    { lat: 48.169396, lng: 19.058158 },
    { lat: 48.169063, lng: 19.058 },
    { lat: 48.16892, lng: 19.057916 },
    { lat: 48.168232, lng: 19.057922 },
    { lat: 48.168005, lng: 19.057918 },
    { lat: 48.167732, lng: 19.057973 },
    { lat: 48.167625, lng: 19.058016 },
    { lat: 48.167198, lng: 19.058184 },
    { lat: 48.166816, lng: 19.058288 },
    { lat: 48.1663, lng: 19.058454 },
    { lat: 48.166112, lng: 19.058505 },
    { lat: 48.165644, lng: 19.058769 },
    { lat: 48.164828, lng: 19.059117 },
    { lat: 48.164782, lng: 19.05914 },
    { lat: 48.164655, lng: 19.059009 },
    { lat: 48.164556, lng: 19.058764 },
    { lat: 48.164786, lng: 19.058286 },
    { lat: 48.164697, lng: 19.058092 },
    { lat: 48.164547, lng: 19.057904 },
    { lat: 48.164483, lng: 19.057569 },
    { lat: 48.16447, lng: 19.05722 },
    { lat: 48.164273, lng: 19.05703 },
    { lat: 48.164305, lng: 19.056664 },
    { lat: 48.164337, lng: 19.05655 },
    { lat: 48.16417, lng: 19.056268 },
    { lat: 48.164018, lng: 19.056161 },
    { lat: 48.163951, lng: 19.055961 },
    { lat: 48.163922, lng: 19.055851 },
    { lat: 48.163933, lng: 19.055749 },
    { lat: 48.16396, lng: 19.055581 },
    { lat: 48.163846, lng: 19.055317 },
    { lat: 48.163442, lng: 19.055333 },
    { lat: 48.163408, lng: 19.055286 },
    { lat: 48.163265, lng: 19.054778 },
    { lat: 48.163295, lng: 19.054529 },
    { lat: 48.163383, lng: 19.054165 },
    { lat: 48.163082, lng: 19.0541 },
    { lat: 48.162968, lng: 19.05362 },
    { lat: 48.162696, lng: 19.053463 },
    { lat: 48.162559, lng: 19.053311 },
    { lat: 48.162603, lng: 19.052705 },
    { lat: 48.162619, lng: 19.052481 },
    { lat: 48.162445, lng: 19.052067 },
    { lat: 48.162172, lng: 19.052047 },
    { lat: 48.161953, lng: 19.051843 },
    { lat: 48.161952, lng: 19.051786 },
    { lat: 48.161983, lng: 19.051609 },
    { lat: 48.16197, lng: 19.051397 },
    { lat: 48.161866, lng: 19.051274 },
    { lat: 48.161664, lng: 19.051157 },
    { lat: 48.161658, lng: 19.051124 },
    { lat: 48.161695, lng: 19.050742 },
    { lat: 48.161385, lng: 19.050616 },
    { lat: 48.161113, lng: 19.050257 },
    { lat: 48.160824, lng: 19.050034 },
    { lat: 48.160594, lng: 19.049525 },
    { lat: 48.160572, lng: 19.04959 },
    { lat: 48.160307, lng: 19.049995 },
    { lat: 48.160287, lng: 19.050025 },
    { lat: 48.160277, lng: 19.050041 },
    { lat: 48.160256, lng: 19.050073 },
    { lat: 48.160069, lng: 19.050204 },
    { lat: 48.159982, lng: 19.050176 },
    { lat: 48.159862, lng: 19.050346 },
    { lat: 48.159584, lng: 19.050519 },
    { lat: 48.159514, lng: 19.050722 },
    { lat: 48.159383, lng: 19.050996 },
    { lat: 48.159193, lng: 19.051263 },
    { lat: 48.159102, lng: 19.051432 },
    { lat: 48.15897, lng: 19.051594 },
    { lat: 48.158868, lng: 19.05189 },
    { lat: 48.158786, lng: 19.051982 },
    { lat: 48.158708, lng: 19.052227 },
    { lat: 48.158612, lng: 19.052347 },
    { lat: 48.158417, lng: 19.053131 },
    { lat: 48.15836, lng: 19.05327 },
    { lat: 48.15833, lng: 19.053365 },
    { lat: 48.157975, lng: 19.053883 },
    { lat: 48.157708, lng: 19.054152 },
    { lat: 48.157431, lng: 19.054519 },
    { lat: 48.15709, lng: 19.054765 },
    { lat: 48.156667, lng: 19.055042 },
    { lat: 48.156298, lng: 19.055193 },
    { lat: 48.155906, lng: 19.055491 },
    { lat: 48.155397, lng: 19.055666 },
    { lat: 48.154778, lng: 19.055587 },
    { lat: 48.154307, lng: 19.055376 },
    { lat: 48.153748, lng: 19.055017 },
    { lat: 48.153358, lng: 19.054842 },
    { lat: 48.153045, lng: 19.055256 },
    { lat: 48.15302, lng: 19.055308 },
    { lat: 48.15288, lng: 19.055603 },
    { lat: 48.152661, lng: 19.055695 },
    { lat: 48.152474, lng: 19.055894 },
    { lat: 48.152391, lng: 19.055985 },
    { lat: 48.152274, lng: 19.056288 },
    { lat: 48.152066, lng: 19.056573 },
    { lat: 48.151884, lng: 19.056885 },
    { lat: 48.151891, lng: 19.05712 },
    { lat: 48.151718, lng: 19.057502 },
    { lat: 48.151474, lng: 19.057888 },
    { lat: 48.151304, lng: 19.058507 },
    { lat: 48.151106, lng: 19.058986 },
    { lat: 48.150824, lng: 19.05963 },
    { lat: 48.15083, lng: 19.059795 },
    { lat: 48.150797, lng: 19.059986 },
    { lat: 48.15063, lng: 19.060232 },
    { lat: 48.150537, lng: 19.060344 },
    { lat: 48.150198, lng: 19.060484 },
    { lat: 48.1503516, lng: 19.0606715 },
    { lat: 48.1503653, lng: 19.0609881 },
    { lat: 48.1505675, lng: 19.0612823 },
    { lat: 48.1511051, lng: 19.0617053 },
    { lat: 48.1511503, lng: 19.0619971 },
    { lat: 48.1513842, lng: 19.0618941 },
    { lat: 48.1514771, lng: 19.062105 },
    { lat: 48.151441, lng: 19.0624374 },
    { lat: 48.1515496, lng: 19.0628255 },
    { lat: 48.1516836, lng: 19.0628676 },
    { lat: 48.1518834, lng: 19.0630728 },
    { lat: 48.1518709, lng: 19.0632267 },
    { lat: 48.1519193, lng: 19.0634271 },
    { lat: 48.1520562, lng: 19.0635229 },
    { lat: 48.1524086, lng: 19.0635812 },
    { lat: 48.1525127, lng: 19.0638142 },
    { lat: 48.1524413, lng: 19.064206 },
    { lat: 48.1526258, lng: 19.0643797 },
    { lat: 48.1528223, lng: 19.0643396 },
    { lat: 48.1530828, lng: 19.064432 },
    { lat: 48.1532219, lng: 19.0647485 },
    { lat: 48.1532272, lng: 19.0649925 },
    { lat: 48.1530463, lng: 19.0656037 },
    { lat: 48.1528927, lng: 19.0658237 },
    { lat: 48.1528869, lng: 19.0659744 },
    { lat: 48.1528085, lng: 19.0661206 },
    { lat: 48.1528822, lng: 19.0664191 },
    { lat: 48.1528263, lng: 19.0666093 },
    { lat: 48.152618, lng: 19.0667917 },
    { lat: 48.1524323, lng: 19.0673302 },
    { lat: 48.1520428, lng: 19.0690413 },
    { lat: 48.1520227, lng: 19.0694715 },
    { lat: 48.1518458, lng: 19.0698088 },
    { lat: 48.1518688, lng: 19.0699746 },
    { lat: 48.1518364, lng: 19.0700673 },
    { lat: 48.1515891, lng: 19.0704199 },
    { lat: 48.1513213, lng: 19.0704275 },
    { lat: 48.1506104, lng: 19.0707964 },
    { lat: 48.1505396, lng: 19.070755 },
    { lat: 48.1501477, lng: 19.0711218 },
    { lat: 48.1498459, lng: 19.0712173 },
    { lat: 48.1497892, lng: 19.0713684 },
    { lat: 48.1496496, lng: 19.0713972 },
    { lat: 48.1494993, lng: 19.0713199 },
    { lat: 48.1487361, lng: 19.0715474 },
    { lat: 48.1482052, lng: 19.0719066 },
    { lat: 48.1478519, lng: 19.0719192 },
    { lat: 48.1470285, lng: 19.0730982 },
    { lat: 48.1468872, lng: 19.0736275 },
    { lat: 48.1457778, lng: 19.0741146 },
    { lat: 48.1448451, lng: 19.0748925 },
    { lat: 48.1437545, lng: 19.076476 },
    { lat: 48.1433361, lng: 19.076875 },
    { lat: 48.1426717, lng: 19.0780073 },
    { lat: 48.1422584, lng: 19.0784644 },
    { lat: 48.1414744, lng: 19.0798879 },
    { lat: 48.1411987, lng: 19.0802108 },
    { lat: 48.1406963, lng: 19.0805981 },
    { lat: 48.1402959, lng: 19.0811087 },
    { lat: 48.1399603, lng: 19.0813406 },
    { lat: 48.1404562, lng: 19.0818111 },
    { lat: 48.1411486, lng: 19.0824152 },
    { lat: 48.141689, lng: 19.0831395 },
    { lat: 48.1421281, lng: 19.0840665 },
    { lat: 48.1427619, lng: 19.0848583 },
    { lat: 48.1427852, lng: 19.0854522 },
    { lat: 48.1423783, lng: 19.0861263 },
    { lat: 48.1411421, lng: 19.0894991 },
    { lat: 48.1408279, lng: 19.0901341 },
    { lat: 48.1403147, lng: 19.0905793 },
    { lat: 48.140214, lng: 19.091245 },
    { lat: 48.1398944, lng: 19.0915237 },
    { lat: 48.1396888, lng: 19.0923438 },
    { lat: 48.1393869, lng: 19.0926643 },
    { lat: 48.1389043, lng: 19.0934025 },
    { lat: 48.1386806, lng: 19.0940901 },
    { lat: 48.1388082, lng: 19.0941831 },
    { lat: 48.1387363, lng: 19.0948364 },
    { lat: 48.1384798, lng: 19.0960599 },
    { lat: 48.1385445, lng: 19.0966315 },
    { lat: 48.1384611, lng: 19.0969419 },
    { lat: 48.1382099, lng: 19.0975101 },
    { lat: 48.1381648, lng: 19.098159 },
    { lat: 48.1379331, lng: 19.0987998 },
    { lat: 48.1376362, lng: 19.0998693 },
    { lat: 48.1389519, lng: 19.1007034 },
    { lat: 48.1399592, lng: 19.1017468 },
    { lat: 48.140231, lng: 19.1018163 },
    { lat: 48.140709, lng: 19.1023296 },
    { lat: 48.1408682, lng: 19.1026255 },
    { lat: 48.141084, lng: 19.102883 },
    { lat: 48.1416586, lng: 19.1031 },
    { lat: 48.1422372, lng: 19.1035766 },
    { lat: 48.1427954, lng: 19.1038717 },
    { lat: 48.1432643, lng: 19.1042293 },
    { lat: 48.1443022, lng: 19.1055025 },
    { lat: 48.1452227, lng: 19.1062568 },
    { lat: 48.1454339, lng: 19.1066917 },
    { lat: 48.1457964, lng: 19.1072625 },
    { lat: 48.1461077, lng: 19.1074691 },
    { lat: 48.1466995, lng: 19.1089724 },
    { lat: 48.1467561, lng: 19.1097667 },
    { lat: 48.1469565, lng: 19.1106416 },
    { lat: 48.1474595, lng: 19.1114043 },
    { lat: 48.1480587, lng: 19.1127927 },
    { lat: 48.1480942, lng: 19.1135022 },
    { lat: 48.1484515, lng: 19.1148479 },
    { lat: 48.1489979, lng: 19.1161922 },
    { lat: 48.1491199, lng: 19.116671 },
    { lat: 48.1489669, lng: 19.1169472 },
    { lat: 48.1494067, lng: 19.1177615 },
    { lat: 48.149712, lng: 19.1186125 },
    { lat: 48.1504841, lng: 19.1194642 },
    { lat: 48.1508902, lng: 19.1197193 },
    { lat: 48.1515467, lng: 19.1198376 },
    { lat: 48.1516082, lng: 19.119829 },
    { lat: 48.1517221, lng: 19.1195564 },
    { lat: 48.1519954, lng: 19.11957 },
    { lat: 48.1526545, lng: 19.1207797 },
  ],
  IpeľskéPredmostie: [
    { lat: 48.0719433, lng: 19.0822013 },
    { lat: 48.0719498, lng: 19.0819169 },
    { lat: 48.0721702, lng: 19.0812805 },
    { lat: 48.0727014, lng: 19.0799906 },
    { lat: 48.0727427, lng: 19.0796423 },
    { lat: 48.0730304, lng: 19.0784848 },
    { lat: 48.0737534, lng: 19.0764597 },
    { lat: 48.0737904, lng: 19.0763682 },
    { lat: 48.0740529, lng: 19.0767197 },
    { lat: 48.0740853, lng: 19.076647 },
    { lat: 48.075237, lng: 19.0728432 },
    { lat: 48.0758572, lng: 19.0704767 },
    { lat: 48.0765115, lng: 19.0682536 },
    { lat: 48.0780654, lng: 19.0689104 },
    { lat: 48.0791024, lng: 19.0690856 },
    { lat: 48.0795716, lng: 19.0690842 },
    { lat: 48.0801548, lng: 19.0689805 },
    { lat: 48.0808085, lng: 19.0687553 },
    { lat: 48.0815253, lng: 19.0683496 },
    { lat: 48.0818249, lng: 19.0680327 },
    { lat: 48.0829527, lng: 19.0665628 },
    { lat: 48.0836144, lng: 19.0654034 },
    { lat: 48.0849996, lng: 19.0634339 },
    { lat: 48.085168, lng: 19.0633067 },
    { lat: 48.0858816, lng: 19.0630183 },
    { lat: 48.0863122, lng: 19.0624807 },
    { lat: 48.0869274, lng: 19.0620926 },
    { lat: 48.0873528, lng: 19.0617459 },
    { lat: 48.0873693, lng: 19.0618478 },
    { lat: 48.0877909, lng: 19.061626 },
    { lat: 48.0878839, lng: 19.0611893 },
    { lat: 48.0880776, lng: 19.0606702 },
    { lat: 48.08833, lng: 19.0601347 },
    { lat: 48.0884361, lng: 19.0601587 },
    { lat: 48.0885598, lng: 19.0599995 },
    { lat: 48.0886585, lng: 19.0597401 },
    { lat: 48.08883, lng: 19.0589422 },
    { lat: 48.0892068, lng: 19.0578552 },
    { lat: 48.0899202, lng: 19.0569845 },
    { lat: 48.0900192, lng: 19.0570699 },
    { lat: 48.0901698, lng: 19.0568572 },
    { lat: 48.09043, lng: 19.0566304 },
    { lat: 48.0906315, lng: 19.0565353 },
    { lat: 48.0906886, lng: 19.0566185 },
    { lat: 48.090824, lng: 19.0564177 },
    { lat: 48.0911118, lng: 19.0562237 },
    { lat: 48.0922508, lng: 19.0556258 },
    { lat: 48.0930882, lng: 19.0553349 },
    { lat: 48.0943115, lng: 19.0547796 },
    { lat: 48.0959139, lng: 19.0535129 },
    { lat: 48.0967497, lng: 19.0526105 },
    { lat: 48.0978914, lng: 19.0497334 },
    { lat: 48.0977284, lng: 19.0477161 },
    { lat: 48.0991974, lng: 19.0458935 },
    { lat: 48.0998353, lng: 19.0434014 },
    { lat: 48.0999716, lng: 19.0425776 },
    { lat: 48.1002655, lng: 19.041368 },
    { lat: 48.1010353, lng: 19.039075 },
    { lat: 48.1004443, lng: 19.0373427 },
    { lat: 48.10037, lng: 19.034703 },
    { lat: 48.1000013, lng: 19.0347476 },
    { lat: 48.099866, lng: 19.0347022 },
    { lat: 48.0997868, lng: 19.0346325 },
    { lat: 48.0997255, lng: 19.0344667 },
    { lat: 48.0996633, lng: 19.0344423 },
    { lat: 48.0992215, lng: 19.0345767 },
    { lat: 48.099104, lng: 19.0347289 },
    { lat: 48.098758, lng: 19.0347551 },
    { lat: 48.0985866, lng: 19.0346884 },
    { lat: 48.0985302, lng: 19.0344842 },
    { lat: 48.0984781, lng: 19.0344581 },
    { lat: 48.0982907, lng: 19.0349649 },
    { lat: 48.0982493, lng: 19.0349721 },
    { lat: 48.0981347, lng: 19.0347999 },
    { lat: 48.0979598, lng: 19.0343531 },
    { lat: 48.0979036, lng: 19.0343112 },
    { lat: 48.0977735, lng: 19.0343915 },
    { lat: 48.0977323, lng: 19.0342699 },
    { lat: 48.0977752, lng: 19.0341381 },
    { lat: 48.0980307, lng: 19.0339765 },
    { lat: 48.0980241, lng: 19.0338238 },
    { lat: 48.0979554, lng: 19.033778 },
    { lat: 48.0975173, lng: 19.0338322 },
    { lat: 48.097482, lng: 19.0337837 },
    { lat: 48.0974198, lng: 19.0333544 },
    { lat: 48.0975183, lng: 19.0329232 },
    { lat: 48.0978021, lng: 19.0325846 },
    { lat: 48.0977479, lng: 19.0321823 },
    { lat: 48.09712, lng: 19.0318313 },
    { lat: 48.0971245, lng: 19.0316967 },
    { lat: 48.0972714, lng: 19.0313052 },
    { lat: 48.0971678, lng: 19.0309744 },
    { lat: 48.0974717, lng: 19.0305852 },
    { lat: 48.0973674, lng: 19.0301082 },
    { lat: 48.0975715, lng: 19.0298198 },
    { lat: 48.0975398, lng: 19.029755 },
    { lat: 48.0973336, lng: 19.0297131 },
    { lat: 48.0972736, lng: 19.0295291 },
    { lat: 48.0973569, lng: 19.0294149 },
    { lat: 48.0976397, lng: 19.029368 },
    { lat: 48.0976504, lng: 19.0287383 },
    { lat: 48.0976312, lng: 19.0286671 },
    { lat: 48.0975444, lng: 19.0286703 },
    { lat: 48.0974734, lng: 19.0283154 },
    { lat: 48.0974292, lng: 19.0282865 },
    { lat: 48.0972893, lng: 19.0283973 },
    { lat: 48.0971292, lng: 19.0283992 },
    { lat: 48.0971305, lng: 19.0280026 },
    { lat: 48.0971472, lng: 19.0278886 },
    { lat: 48.097311, lng: 19.0278725 },
    { lat: 48.0975703, lng: 19.0282032 },
    { lat: 48.0977683, lng: 19.0283059 },
    { lat: 48.0977974, lng: 19.0281686 },
    { lat: 48.0975652, lng: 19.0276585 },
    { lat: 48.0973264, lng: 19.0277109 },
    { lat: 48.097245, lng: 19.027638 },
    { lat: 48.0972199, lng: 19.0274793 },
    { lat: 48.0974241, lng: 19.0271937 },
    { lat: 48.0974318, lng: 19.0270984 },
    { lat: 48.0974094, lng: 19.0270342 },
    { lat: 48.0972372, lng: 19.0270633 },
    { lat: 48.0971222, lng: 19.0269748 },
    { lat: 48.097126, lng: 19.0268505 },
    { lat: 48.0974207, lng: 19.0267497 },
    { lat: 48.0973074, lng: 19.0263161 },
    { lat: 48.0973623, lng: 19.0260439 },
    { lat: 48.097299, lng: 19.025917 },
    { lat: 48.097234, lng: 19.025792 },
    { lat: 48.097123, lng: 19.025578 },
    { lat: 48.09708, lng: 19.025533 },
    { lat: 48.097021, lng: 19.025472 },
    { lat: 48.096927, lng: 19.025396 },
    { lat: 48.096841, lng: 19.025327 },
    { lat: 48.096787, lng: 19.025244 },
    { lat: 48.096834, lng: 19.025154 },
    { lat: 48.096883, lng: 19.025083 },
    { lat: 48.097072, lng: 19.025017 },
    { lat: 48.097191, lng: 19.024971 },
    { lat: 48.097215, lng: 19.024919 },
    { lat: 48.097209, lng: 19.024867 },
    { lat: 48.097135, lng: 19.024793 },
    { lat: 48.097068, lng: 19.024717 },
    { lat: 48.097029, lng: 19.024643 },
    { lat: 48.097007, lng: 19.024552 },
    { lat: 48.096945, lng: 19.024522 },
    { lat: 48.096889, lng: 19.024507 },
    { lat: 48.096847, lng: 19.024465 },
    { lat: 48.096816, lng: 19.0244 },
    { lat: 48.096794, lng: 19.024262 },
    { lat: 48.096828, lng: 19.023891 },
    { lat: 48.097049, lng: 19.023553 },
    { lat: 48.097102, lng: 19.023472 },
    { lat: 48.097061, lng: 19.023405 },
    { lat: 48.096816, lng: 19.023247 },
    { lat: 48.096767, lng: 19.023206 },
    { lat: 48.096732, lng: 19.023106 },
    { lat: 48.096725, lng: 19.022965 },
    { lat: 48.096807, lng: 19.022786 },
    { lat: 48.096824, lng: 19.022718 },
    { lat: 48.096792, lng: 19.022655 },
    { lat: 48.096729, lng: 19.022595 },
    { lat: 48.096654, lng: 19.022566 },
    { lat: 48.096561, lng: 19.022551 },
    { lat: 48.096516, lng: 19.022538 },
    { lat: 48.09647, lng: 19.022492 },
    { lat: 48.096362, lng: 19.022329 },
    { lat: 48.096319, lng: 19.022291 },
    { lat: 48.096263, lng: 19.022317 },
    { lat: 48.096161, lng: 19.022366 },
    { lat: 48.096133, lng: 19.022389 },
    { lat: 48.096126, lng: 19.022464 },
    { lat: 48.096075, lng: 19.022793 },
    { lat: 48.096061, lng: 19.022859 },
    { lat: 48.096001, lng: 19.022899 },
    { lat: 48.095885, lng: 19.022935 },
    { lat: 48.095805, lng: 19.02261 },
    { lat: 48.095769, lng: 19.022508 },
    { lat: 48.095713, lng: 19.022436 },
    { lat: 48.095661, lng: 19.022366 },
    { lat: 48.095584, lng: 19.022276 },
    { lat: 48.095596, lng: 19.022156 },
    { lat: 48.095616, lng: 19.02206 },
    { lat: 48.095633, lng: 19.021911 },
    { lat: 48.09566, lng: 19.021744 },
    { lat: 48.095686, lng: 19.021588 },
    { lat: 48.095694, lng: 19.021492 },
    { lat: 48.095677, lng: 19.021448 },
    { lat: 48.09564, lng: 19.021378 },
    { lat: 48.095668, lng: 19.021327 },
    { lat: 48.095693, lng: 19.021278 },
    { lat: 48.095753, lng: 19.021177 },
    { lat: 48.09579, lng: 19.021096 },
    { lat: 48.095716, lng: 19.021033 },
    { lat: 48.095661, lng: 19.021047 },
    { lat: 48.095586, lng: 19.021073 },
    { lat: 48.095508, lng: 19.021111 },
    { lat: 48.095435, lng: 19.021136 },
    { lat: 48.095376, lng: 19.021106 },
    { lat: 48.095309, lng: 19.021027 },
    { lat: 48.095244, lng: 19.020918 },
    { lat: 48.09515, lng: 19.020683 },
    { lat: 48.095115, lng: 19.020627 },
    { lat: 48.095068, lng: 19.020643 },
    { lat: 48.095027, lng: 19.020702 },
    { lat: 48.09499, lng: 19.02077 },
    { lat: 48.09494, lng: 19.020809 },
    { lat: 48.094868, lng: 19.020795 },
    { lat: 48.094823, lng: 19.02071 },
    { lat: 48.094729, lng: 19.020444 },
    { lat: 48.094684, lng: 19.020337 },
    { lat: 48.094625, lng: 19.020326 },
    { lat: 48.094488, lng: 19.020312 },
    { lat: 48.094403, lng: 19.020315 },
    { lat: 48.094361, lng: 19.020356 },
    { lat: 48.094287, lng: 19.020436 },
    { lat: 48.094211, lng: 19.020483 },
    { lat: 48.094187, lng: 19.020355 },
    { lat: 48.094201, lng: 19.020275 },
    { lat: 48.094234, lng: 19.020192 },
    { lat: 48.094447, lng: 19.019929 },
    { lat: 48.094475, lng: 19.019857 },
    { lat: 48.094463, lng: 19.019773 },
    { lat: 48.094374, lng: 19.019592 },
    { lat: 48.094311, lng: 19.019448 },
    { lat: 48.094273, lng: 19.019321 },
    { lat: 48.094264, lng: 19.019184 },
    { lat: 48.094274, lng: 19.018678 },
    { lat: 48.094284, lng: 19.018557 },
    { lat: 48.09434, lng: 19.01851 },
    { lat: 48.094396, lng: 19.018474 },
    { lat: 48.094475, lng: 19.018423 },
    { lat: 48.094572, lng: 19.018418 },
    { lat: 48.094687, lng: 19.018431 },
    { lat: 48.094758, lng: 19.018419 },
    { lat: 48.094779, lng: 19.01834 },
    { lat: 48.09475, lng: 19.018167 },
    { lat: 48.094753, lng: 19.018046 },
    { lat: 48.094819, lng: 19.017997 },
    { lat: 48.094984, lng: 19.017912 },
    { lat: 48.095065, lng: 19.017884 },
    { lat: 48.095101, lng: 19.017786 },
    { lat: 48.095071, lng: 19.01774 },
    { lat: 48.094982, lng: 19.017658 },
    { lat: 48.094882, lng: 19.017577 },
    { lat: 48.094828, lng: 19.017507 },
    { lat: 48.094807, lng: 19.017379 },
    { lat: 48.094764, lng: 19.0172 },
    { lat: 48.094756, lng: 19.017067 },
    { lat: 48.0948, lng: 19.016945 },
    { lat: 48.094806, lng: 19.016848 },
    { lat: 48.094735, lng: 19.016771 },
    { lat: 48.094537, lng: 19.01675 },
    { lat: 48.09434, lng: 19.016742 },
    { lat: 48.09423, lng: 19.016696 },
    { lat: 48.094194, lng: 19.016544 },
    { lat: 48.094168, lng: 19.016425 },
    { lat: 48.094115, lng: 19.016057 },
    { lat: 48.094103, lng: 19.015954 },
    { lat: 48.094079, lng: 19.01584 },
    { lat: 48.094023, lng: 19.015805 },
    { lat: 48.093962, lng: 19.015757 },
    { lat: 48.093963, lng: 19.015684 },
    { lat: 48.093977, lng: 19.015545 },
    { lat: 48.094008, lng: 19.015353 },
    { lat: 48.094046, lng: 19.015124 },
    { lat: 48.09408, lng: 19.014956 },
    { lat: 48.094093, lng: 19.014813 },
    { lat: 48.094023, lng: 19.014749 },
    { lat: 48.09382, lng: 19.014598 },
    { lat: 48.093761, lng: 19.01454 },
    { lat: 48.093823, lng: 19.014414 },
    { lat: 48.093875, lng: 19.014386 },
    { lat: 48.093939, lng: 19.014346 },
    { lat: 48.093993, lng: 19.014313 },
    { lat: 48.094027, lng: 19.014234 },
    { lat: 48.094096, lng: 19.014068 },
    { lat: 48.094102, lng: 19.013993 },
    { lat: 48.09405, lng: 19.013949 },
    { lat: 48.093787, lng: 19.013903 },
    { lat: 48.093718, lng: 19.013852 },
    { lat: 48.09355, lng: 19.013648 },
    { lat: 48.093447, lng: 19.01348 },
    { lat: 48.093382, lng: 19.013331 },
    { lat: 48.093356, lng: 19.013342 },
    { lat: 48.093294, lng: 19.013368 },
    { lat: 48.093193, lng: 19.013411 },
    { lat: 48.092734, lng: 19.013123 },
    { lat: 48.092276, lng: 19.012985 },
    { lat: 48.091232, lng: 19.012669 },
    { lat: 48.090401, lng: 19.012417 },
    { lat: 48.088932, lng: 19.012246 },
    { lat: 48.088618, lng: 19.012209 },
    { lat: 48.087621, lng: 19.012181 },
    { lat: 48.087184, lng: 19.012182 },
    { lat: 48.087129, lng: 19.012182 },
    { lat: 48.085926, lng: 19.012156 },
    { lat: 48.084217, lng: 19.012932 },
    { lat: 48.084151, lng: 19.012948 },
    { lat: 48.084106, lng: 19.01296 },
    { lat: 48.082797, lng: 19.01328 },
    { lat: 48.081293, lng: 19.013649 },
    { lat: 48.080493, lng: 19.013548 },
    { lat: 48.079837, lng: 19.013611 },
    { lat: 48.078688, lng: 19.013782 },
    { lat: 48.078609, lng: 19.013794 },
    { lat: 48.078605, lng: 19.013794 },
    { lat: 48.078391, lng: 19.013827 },
    { lat: 48.078188, lng: 19.013832 },
    { lat: 48.078163, lng: 19.013833 },
    { lat: 48.077991, lng: 19.013837 },
    { lat: 48.077978, lng: 19.013842 },
    { lat: 48.0779, lng: 19.013869 },
    { lat: 48.077792, lng: 19.013906 },
    { lat: 48.07778, lng: 19.01391 },
    { lat: 48.077779, lng: 19.014188 },
    { lat: 48.077774, lng: 19.014372 },
    { lat: 48.078026, lng: 19.01443 },
    { lat: 48.078148, lng: 19.014661 },
    { lat: 48.078179, lng: 19.014864 },
    { lat: 48.078007, lng: 19.01552 },
    { lat: 48.07792, lng: 19.016064 },
    { lat: 48.078006, lng: 19.017859 },
    { lat: 48.077863, lng: 19.018202 },
    { lat: 48.077668, lng: 19.018268 },
    { lat: 48.077524, lng: 19.018177 },
    { lat: 48.077296, lng: 19.017745 },
    { lat: 48.077291, lng: 19.017319 },
    { lat: 48.077346, lng: 19.017191 },
    { lat: 48.077372, lng: 19.016767 },
    { lat: 48.077355, lng: 19.016488 },
    { lat: 48.077295, lng: 19.016296 },
    { lat: 48.076646, lng: 19.015564 },
    { lat: 48.076396, lng: 19.015023 },
    { lat: 48.076237, lng: 19.014801 },
    { lat: 48.076029, lng: 19.014694 },
    { lat: 48.075693, lng: 19.01478 },
    { lat: 48.07563, lng: 19.014837 },
    { lat: 48.075579, lng: 19.015061 },
    { lat: 48.07564, lng: 19.015196 },
    { lat: 48.075979, lng: 19.01529 },
    { lat: 48.076225, lng: 19.015581 },
    { lat: 48.076445, lng: 19.016245 },
    { lat: 48.076424, lng: 19.016576 },
    { lat: 48.076355, lng: 19.016693 },
    { lat: 48.076143, lng: 19.016686 },
    { lat: 48.075834, lng: 19.016122 },
    { lat: 48.075764, lng: 19.015873 },
    { lat: 48.075605, lng: 19.015886 },
    { lat: 48.075483, lng: 19.016039 },
    { lat: 48.07544, lng: 19.016367 },
    { lat: 48.075604, lng: 19.016909 },
    { lat: 48.075892, lng: 19.016867 },
    { lat: 48.076025, lng: 19.016965 },
    { lat: 48.076054, lng: 19.017252 },
    { lat: 48.075934, lng: 19.017598 },
    { lat: 48.075745, lng: 19.017725 },
    { lat: 48.075634, lng: 19.017722 },
    { lat: 48.075413, lng: 19.017588 },
    { lat: 48.074914, lng: 19.017611 },
    { lat: 48.07466, lng: 19.01776 },
    { lat: 48.074409, lng: 19.018004 },
    { lat: 48.074317, lng: 19.018303 },
    { lat: 48.07428, lng: 19.01883 },
    { lat: 48.074322, lng: 19.018935 },
    { lat: 48.074487, lng: 19.019086 },
    { lat: 48.074651, lng: 19.019443 },
    { lat: 48.074675, lng: 19.019821 },
    { lat: 48.07449, lng: 19.020334 },
    { lat: 48.074254, lng: 19.020598 },
    { lat: 48.074191, lng: 19.020858 },
    { lat: 48.074107, lng: 19.020973 },
    { lat: 48.073856, lng: 19.021252 },
    { lat: 48.073579, lng: 19.021389 },
    { lat: 48.073341, lng: 19.021392 },
    { lat: 48.072987, lng: 19.021212 },
    { lat: 48.07288, lng: 19.021043 },
    { lat: 48.072829, lng: 19.020708 },
    { lat: 48.07268, lng: 19.020568 },
    { lat: 48.07254, lng: 19.020587 },
    { lat: 48.072281, lng: 19.020778 },
    { lat: 48.072097, lng: 19.020813 },
    { lat: 48.071954, lng: 19.020754 },
    { lat: 48.071527, lng: 19.020376 },
    { lat: 48.0711082, lng: 19.0203062 },
    { lat: 48.070945, lng: 19.02051 },
    { lat: 48.070921, lng: 19.020859 },
    { lat: 48.07101, lng: 19.020979 },
    { lat: 48.071369, lng: 19.021211 },
    { lat: 48.07192, lng: 19.021859 },
    { lat: 48.072368, lng: 19.022222 },
    { lat: 48.072581, lng: 19.022772 },
    { lat: 48.07292, lng: 19.023332 },
    { lat: 48.072992, lng: 19.023617 },
    { lat: 48.07299, lng: 19.023813 },
    { lat: 48.072628, lng: 19.024658 },
    { lat: 48.072047, lng: 19.025587 },
    { lat: 48.071378, lng: 19.026238 },
    { lat: 48.070768, lng: 19.026962 },
    { lat: 48.070405, lng: 19.027203 },
    { lat: 48.070098, lng: 19.027505 },
    { lat: 48.06989, lng: 19.027794 },
    { lat: 48.069287, lng: 19.029014 },
    { lat: 48.069093, lng: 19.029301 },
    { lat: 48.068866, lng: 19.029517 },
    { lat: 48.068677, lng: 19.029601 },
    { lat: 48.068027, lng: 19.029631 },
    { lat: 48.067741, lng: 19.029722 },
    { lat: 48.067515, lng: 19.029902 },
    { lat: 48.067316, lng: 19.030178 },
    { lat: 48.067159, lng: 19.030591 },
    { lat: 48.067165, lng: 19.030975 },
    { lat: 48.067296, lng: 19.031222 },
    { lat: 48.067531, lng: 19.031359 },
    { lat: 48.068287, lng: 19.031275 },
    { lat: 48.068537, lng: 19.031358 },
    { lat: 48.068638, lng: 19.031482 },
    { lat: 48.068685, lng: 19.031785 },
    { lat: 48.068644, lng: 19.031951 },
    { lat: 48.068325, lng: 19.032484 },
    { lat: 48.067983, lng: 19.032727 },
    { lat: 48.067571, lng: 19.032904 },
    { lat: 48.067098, lng: 19.032996 },
    { lat: 48.066962, lng: 19.033278 },
    { lat: 48.066844, lng: 19.033344 },
    { lat: 48.066203, lng: 19.033398 },
    { lat: 48.066122, lng: 19.033221 },
    { lat: 48.06592, lng: 19.033082 },
    { lat: 48.064989, lng: 19.033012 },
    { lat: 48.064582, lng: 19.033093 },
    { lat: 48.064458, lng: 19.033308 },
    { lat: 48.064401, lng: 19.033689 },
    { lat: 48.064413, lng: 19.034226 },
    { lat: 48.064612, lng: 19.034813 },
    { lat: 48.064619, lng: 19.035205 },
    { lat: 48.064805, lng: 19.035466 },
    { lat: 48.065589, lng: 19.035867 },
    { lat: 48.066209, lng: 19.035366 },
    { lat: 48.066355, lng: 19.035309 },
    { lat: 48.066483, lng: 19.03536 },
    { lat: 48.066633, lng: 19.03558 },
    { lat: 48.0666429, lng: 19.0357543 },
    { lat: 48.066656, lng: 19.035987 },
    { lat: 48.066575, lng: 19.036142 },
    { lat: 48.066465, lng: 19.036806 },
    { lat: 48.066316, lng: 19.037154 },
    { lat: 48.065826, lng: 19.037795 },
    { lat: 48.06547, lng: 19.03775 },
    { lat: 48.065242, lng: 19.037766 },
    { lat: 48.06513, lng: 19.037836 },
    { lat: 48.065001, lng: 19.038057 },
    { lat: 48.064915, lng: 19.038331 },
    { lat: 48.064853, lng: 19.038977 },
    { lat: 48.064911, lng: 19.039216 },
    { lat: 48.065422, lng: 19.039729 },
    { lat: 48.0654639, lng: 19.0399445 },
    { lat: 48.065527, lng: 19.040269 },
    { lat: 48.065439, lng: 19.040651 },
    { lat: 48.065168, lng: 19.041089 },
    { lat: 48.065053, lng: 19.041461 },
    { lat: 48.065002, lng: 19.041679 },
    { lat: 48.065018, lng: 19.042184 },
    { lat: 48.065126, lng: 19.04257 },
    { lat: 48.065402, lng: 19.043209 },
    { lat: 48.06601, lng: 19.043797 },
    { lat: 48.06617, lng: 19.044122 },
    { lat: 48.066292, lng: 19.044498 },
    { lat: 48.0664426, lng: 19.0458469 },
    { lat: 48.066486, lng: 19.046236 },
    { lat: 48.066305, lng: 19.046731 },
    { lat: 48.065633, lng: 19.048106 },
    { lat: 48.065513, lng: 19.048264 },
    { lat: 48.065222, lng: 19.048479 },
    { lat: 48.063999, lng: 19.049222 },
    { lat: 48.063642, lng: 19.049967 },
    { lat: 48.063195, lng: 19.050227 },
    { lat: 48.061281, lng: 19.052944 },
    { lat: 48.060433, lng: 19.053881 },
    { lat: 48.059495, lng: 19.055063 },
    { lat: 48.0586, lng: 19.056533 },
    { lat: 48.057605, lng: 19.05787 },
    { lat: 48.05742, lng: 19.058246 },
    { lat: 48.057353, lng: 19.058525 },
    { lat: 48.057327, lng: 19.059132 },
    { lat: 48.057425, lng: 19.059781 },
    { lat: 48.057557, lng: 19.060082 },
    { lat: 48.057841, lng: 19.060385 },
    { lat: 48.058511, lng: 19.060978 },
    { lat: 48.059163, lng: 19.06135 },
    { lat: 48.059331, lng: 19.061545 },
    { lat: 48.059843, lng: 19.06184 },
    { lat: 48.060067, lng: 19.062064 },
    { lat: 48.061044, lng: 19.062701 },
    { lat: 48.061699, lng: 19.063325 },
    { lat: 48.062481, lng: 19.063672 },
    { lat: 48.062739, lng: 19.063928 },
    { lat: 48.063235, lng: 19.064578 },
    { lat: 48.063641, lng: 19.064938 },
    { lat: 48.064649, lng: 19.065467 },
    { lat: 48.064714, lng: 19.065655 },
    { lat: 48.064734, lng: 19.065929 },
    { lat: 48.064662, lng: 19.066208 },
    { lat: 48.064403, lng: 19.066684 },
    { lat: 48.064362, lng: 19.066921 },
    { lat: 48.064489, lng: 19.067294 },
    { lat: 48.064933, lng: 19.067944 },
    { lat: 48.065076, lng: 19.068296 },
    { lat: 48.065115, lng: 19.068878 },
    { lat: 48.06538, lng: 19.069867 },
    { lat: 48.065376, lng: 19.069997 },
    { lat: 48.065244, lng: 19.070511 },
    { lat: 48.064854, lng: 19.070767 },
    { lat: 48.065339, lng: 19.071667 },
    { lat: 48.065486, lng: 19.072109 },
    { lat: 48.065562, lng: 19.072343 },
    { lat: 48.065515, lng: 19.072605 },
    { lat: 48.065522, lng: 19.073002 },
    { lat: 48.065628, lng: 19.073475 },
    { lat: 48.066014, lng: 19.07428 },
    { lat: 48.066187, lng: 19.074335 },
    { lat: 48.066773, lng: 19.074978 },
    { lat: 48.066993, lng: 19.07551 },
    { lat: 48.067045, lng: 19.075717 },
    { lat: 48.067147, lng: 19.077457 },
    { lat: 48.067427, lng: 19.078465 },
    { lat: 48.067537, lng: 19.078661 },
    { lat: 48.067649, lng: 19.078751 },
    { lat: 48.068373, lng: 19.079159 },
    { lat: 48.069002, lng: 19.079291 },
    { lat: 48.069667, lng: 19.079192 },
    { lat: 48.069937, lng: 19.07938 },
    { lat: 48.07008, lng: 19.079576 },
    { lat: 48.07026, lng: 19.08007 },
    { lat: 48.070411, lng: 19.080282 },
    { lat: 48.071142, lng: 19.081043 },
    { lat: 48.071651, lng: 19.081674 },
    { lat: 48.0719433, lng: 19.0822013 },
  ],
  Pôtor: [
    { lat: 48.2210096, lng: 19.4151986 },
    { lat: 48.2209062, lng: 19.4151526 },
    { lat: 48.2198426, lng: 19.4145434 },
    { lat: 48.21899, lng: 19.4148858 },
    { lat: 48.2187862, lng: 19.4152714 },
    { lat: 48.2171394, lng: 19.4146474 },
    { lat: 48.2155586, lng: 19.4152804 },
    { lat: 48.2145465, lng: 19.4160157 },
    { lat: 48.2142063, lng: 19.4163779 },
    { lat: 48.2139601, lng: 19.4162001 },
    { lat: 48.2138403, lng: 19.4161944 },
    { lat: 48.2134816, lng: 19.4163742 },
    { lat: 48.2128938, lng: 19.4168895 },
    { lat: 48.211674, lng: 19.4192389 },
    { lat: 48.2116052, lng: 19.4217391 },
    { lat: 48.2122251, lng: 19.4238342 },
    { lat: 48.2118475, lng: 19.4242385 },
    { lat: 48.2120152, lng: 19.4260232 },
    { lat: 48.212096, lng: 19.4262295 },
    { lat: 48.2128903, lng: 19.4269154 },
    { lat: 48.2134051, lng: 19.4281037 },
    { lat: 48.2139746, lng: 19.4277502 },
    { lat: 48.2144965, lng: 19.4275151 },
    { lat: 48.215306, lng: 19.4272574 },
    { lat: 48.2157179, lng: 19.4278185 },
    { lat: 48.2157902, lng: 19.4277483 },
    { lat: 48.2160675, lng: 19.4280394 },
    { lat: 48.2162866, lng: 19.4286418 },
    { lat: 48.2163554, lng: 19.4294285 },
    { lat: 48.2165884, lng: 19.4299025 },
    { lat: 48.2167822, lng: 19.43007 },
    { lat: 48.2169303, lng: 19.4300798 },
    { lat: 48.2171997, lng: 19.4302687 },
    { lat: 48.2174018, lng: 19.4301572 },
    { lat: 48.2178913, lng: 19.430526 },
    { lat: 48.2180761, lng: 19.4304983 },
    { lat: 48.2182233, lng: 19.4306792 },
    { lat: 48.2184153, lng: 19.4307593 },
    { lat: 48.2187436, lng: 19.4318416 },
    { lat: 48.2189716, lng: 19.4322773 },
    { lat: 48.2197835, lng: 19.4330284 },
    { lat: 48.2207055, lng: 19.4344149 },
    { lat: 48.2212401, lng: 19.434973 },
    { lat: 48.2219382, lng: 19.4351537 },
    { lat: 48.2220762, lng: 19.4357604 },
    { lat: 48.2219876, lng: 19.4359592 },
    { lat: 48.2219659, lng: 19.436216 },
    { lat: 48.2220688, lng: 19.4364589 },
    { lat: 48.2220518, lng: 19.4365791 },
    { lat: 48.2218972, lng: 19.436741 },
    { lat: 48.2217842, lng: 19.4366148 },
    { lat: 48.2216892, lng: 19.436634 },
    { lat: 48.2216536, lng: 19.4367844 },
    { lat: 48.2217739, lng: 19.4369968 },
    { lat: 48.2217445, lng: 19.4371141 },
    { lat: 48.2214687, lng: 19.437215 },
    { lat: 48.2212604, lng: 19.4370754 },
    { lat: 48.2211588, lng: 19.4371692 },
    { lat: 48.2210911, lng: 19.4373368 },
    { lat: 48.2211232, lng: 19.4374656 },
    { lat: 48.2213245, lng: 19.4375981 },
    { lat: 48.2211953, lng: 19.4378231 },
    { lat: 48.2210651, lng: 19.4377863 },
    { lat: 48.2209464, lng: 19.4376589 },
    { lat: 48.220838, lng: 19.4377481 },
    { lat: 48.2208785, lng: 19.4378915 },
    { lat: 48.2208385, lng: 19.4380951 },
    { lat: 48.2210389, lng: 19.4384284 },
    { lat: 48.2211529, lng: 19.4385022 },
    { lat: 48.22124, lng: 19.438651 },
    { lat: 48.2214017, lng: 19.438665 },
    { lat: 48.2216138, lng: 19.4388105 },
    { lat: 48.2217731, lng: 19.4386979 },
    { lat: 48.2219112, lng: 19.4386862 },
    { lat: 48.2220419, lng: 19.4388091 },
    { lat: 48.222452, lng: 19.4389454 },
    { lat: 48.2225263, lng: 19.4393291 },
    { lat: 48.2225774, lng: 19.4393814 },
    { lat: 48.2227007, lng: 19.4393217 },
    { lat: 48.2227628, lng: 19.4393717 },
    { lat: 48.2228384, lng: 19.4395687 },
    { lat: 48.2227644, lng: 19.4398628 },
    { lat: 48.2225153, lng: 19.4400046 },
    { lat: 48.2222143, lng: 19.4399304 },
    { lat: 48.222125, lng: 19.4400214 },
    { lat: 48.2222687, lng: 19.4403183 },
    { lat: 48.2223782, lng: 19.4402969 },
    { lat: 48.2225973, lng: 19.4404749 },
    { lat: 48.2226193, lng: 19.4409415 },
    { lat: 48.2224338, lng: 19.4411901 },
    { lat: 48.2222963, lng: 19.4412156 },
    { lat: 48.2221453, lng: 19.4414337 },
    { lat: 48.2219566, lng: 19.4415186 },
    { lat: 48.2216491, lng: 19.4413525 },
    { lat: 48.2215821, lng: 19.4414154 },
    { lat: 48.2216725, lng: 19.4415645 },
    { lat: 48.2217393, lng: 19.4418345 },
    { lat: 48.2218906, lng: 19.4417914 },
    { lat: 48.2220134, lng: 19.4420021 },
    { lat: 48.2222155, lng: 19.4418698 },
    { lat: 48.2223789, lng: 19.4416755 },
    { lat: 48.2227271, lng: 19.4418409 },
    { lat: 48.2226785, lng: 19.4421528 },
    { lat: 48.2224844, lng: 19.4426501 },
    { lat: 48.2223271, lng: 19.4427296 },
    { lat: 48.2219491, lng: 19.4423362 },
    { lat: 48.2218271, lng: 19.4422837 },
    { lat: 48.2216663, lng: 19.4423904 },
    { lat: 48.2215958, lng: 19.4424714 },
    { lat: 48.2216174, lng: 19.4427161 },
    { lat: 48.2214262, lng: 19.4427667 },
    { lat: 48.221059, lng: 19.4431581 },
    { lat: 48.2209911, lng: 19.4437484 },
    { lat: 48.2210642, lng: 19.443746 },
    { lat: 48.2212471, lng: 19.443562 },
    { lat: 48.221568, lng: 19.4436522 },
    { lat: 48.2216458, lng: 19.4437702 },
    { lat: 48.2216015, lng: 19.4439063 },
    { lat: 48.2213387, lng: 19.4441878 },
    { lat: 48.2213238, lng: 19.4443039 },
    { lat: 48.2214224, lng: 19.4444366 },
    { lat: 48.2215217, lng: 19.4444389 },
    { lat: 48.2217029, lng: 19.44429 },
    { lat: 48.2217621, lng: 19.444018 },
    { lat: 48.2219049, lng: 19.4437694 },
    { lat: 48.2219912, lng: 19.443421 },
    { lat: 48.2220618, lng: 19.4433662 },
    { lat: 48.2221791, lng: 19.4436288 },
    { lat: 48.2221851, lng: 19.444283 },
    { lat: 48.222012, lng: 19.4442938 },
    { lat: 48.2219261, lng: 19.4446012 },
    { lat: 48.2220636, lng: 19.4446475 },
    { lat: 48.2223201, lng: 19.4449491 },
    { lat: 48.2223103, lng: 19.4453633 },
    { lat: 48.2223672, lng: 19.4455428 },
    { lat: 48.2224619, lng: 19.4456431 },
    { lat: 48.2226037, lng: 19.4456356 },
    { lat: 48.2226811, lng: 19.4458451 },
    { lat: 48.2228069, lng: 19.4459089 },
    { lat: 48.223389, lng: 19.4455355 },
    { lat: 48.2235271, lng: 19.4456446 },
    { lat: 48.2235225, lng: 19.4457875 },
    { lat: 48.223398, lng: 19.4460869 },
    { lat: 48.2232885, lng: 19.4461805 },
    { lat: 48.2232627, lng: 19.4460835 },
    { lat: 48.2230021, lng: 19.446222 },
    { lat: 48.2229212, lng: 19.4463683 },
    { lat: 48.2230765, lng: 19.4468439 },
    { lat: 48.2232472, lng: 19.4468581 },
    { lat: 48.2233204, lng: 19.4466627 },
    { lat: 48.2235793, lng: 19.4466075 },
    { lat: 48.2236859, lng: 19.4464538 },
    { lat: 48.223742, lng: 19.4464963 },
    { lat: 48.2237494, lng: 19.4467744 },
    { lat: 48.2236315, lng: 19.4469745 },
    { lat: 48.2236139, lng: 19.4471775 },
    { lat: 48.2236619, lng: 19.4472844 },
    { lat: 48.2238489, lng: 19.4473343 },
    { lat: 48.223957, lng: 19.4472371 },
    { lat: 48.224136, lng: 19.4472758 },
    { lat: 48.2241795, lng: 19.447413 },
    { lat: 48.2240953, lng: 19.4475413 },
    { lat: 48.2238396, lng: 19.4475933 },
    { lat: 48.2238031, lng: 19.4477258 },
    { lat: 48.2238969, lng: 19.4478725 },
    { lat: 48.2239844, lng: 19.4479135 },
    { lat: 48.2240585, lng: 19.4477622 },
    { lat: 48.2241364, lng: 19.4478618 },
    { lat: 48.2240672, lng: 19.4481179 },
    { lat: 48.2234167, lng: 19.4482069 },
    { lat: 48.2231923, lng: 19.4483709 },
    { lat: 48.2224503, lng: 19.4499416 },
    { lat: 48.2219686, lng: 19.450702 },
    { lat: 48.2215814, lng: 19.4518143 },
    { lat: 48.2213564, lng: 19.4521561 },
    { lat: 48.2210403, lng: 19.4525064 },
    { lat: 48.2204565, lng: 19.4529824 },
    { lat: 48.2202104, lng: 19.4528762 },
    { lat: 48.2199655, lng: 19.4530882 },
    { lat: 48.2198331, lng: 19.4535322 },
    { lat: 48.2198331, lng: 19.4539885 },
    { lat: 48.2195394, lng: 19.4543081 },
    { lat: 48.219264, lng: 19.454734 },
    { lat: 48.2189471, lng: 19.4548695 },
    { lat: 48.2185635, lng: 19.4548639 },
    { lat: 48.2183424, lng: 19.455048 },
    { lat: 48.2181994, lng: 19.455268 },
    { lat: 48.218085, lng: 19.4555663 },
    { lat: 48.2177565, lng: 19.4560136 },
    { lat: 48.2176946, lng: 19.4565148 },
    { lat: 48.217085, lng: 19.4573833 },
    { lat: 48.2171382, lng: 19.4581088 },
    { lat: 48.2172772, lng: 19.4584289 },
    { lat: 48.2171433, lng: 19.4588154 },
    { lat: 48.2172428, lng: 19.4589883 },
    { lat: 48.2171449, lng: 19.4592563 },
    { lat: 48.2172516, lng: 19.4594677 },
    { lat: 48.2170289, lng: 19.4597381 },
    { lat: 48.2170614, lng: 19.460405 },
    { lat: 48.2171911, lng: 19.4606476 },
    { lat: 48.2176212, lng: 19.4609049 },
    { lat: 48.2178619, lng: 19.4612291 },
    { lat: 48.2182013, lng: 19.4619783 },
    { lat: 48.2178847, lng: 19.4627713 },
    { lat: 48.2176114, lng: 19.4631668 },
    { lat: 48.2170474, lng: 19.4637604 },
    { lat: 48.2168235, lng: 19.4642737 },
    { lat: 48.2173046, lng: 19.4646766 },
    { lat: 48.217547, lng: 19.4650151 },
    { lat: 48.2176872, lng: 19.4653106 },
    { lat: 48.2177719, lng: 19.465677 },
    { lat: 48.2177656, lng: 19.466 },
    { lat: 48.2175398, lng: 19.4669775 },
    { lat: 48.2176122, lng: 19.4673883 },
    { lat: 48.2175935, lng: 19.4685302 },
    { lat: 48.2169618, lng: 19.4712811 },
    { lat: 48.2168325, lng: 19.4721552 },
    { lat: 48.2167801, lng: 19.4731996 },
    { lat: 48.2168612, lng: 19.4732006 },
    { lat: 48.2168848, lng: 19.473584 },
    { lat: 48.2163633, lng: 19.4742133 },
    { lat: 48.2162083, lng: 19.4745642 },
    { lat: 48.2160849, lng: 19.4750818 },
    { lat: 48.2161647, lng: 19.476831 },
    { lat: 48.2162664, lng: 19.477943 },
    { lat: 48.21646, lng: 19.4789518 },
    { lat: 48.2166636, lng: 19.4794015 },
    { lat: 48.2170117, lng: 19.4796134 },
    { lat: 48.2173805, lng: 19.4792832 },
    { lat: 48.217608, lng: 19.4793269 },
    { lat: 48.2178535, lng: 19.4795411 },
    { lat: 48.2179018, lng: 19.4793933 },
    { lat: 48.2185955, lng: 19.4796726 },
    { lat: 48.2187515, lng: 19.4795389 },
    { lat: 48.218873, lng: 19.4796262 },
    { lat: 48.2189785, lng: 19.4794748 },
    { lat: 48.2196208, lng: 19.4795235 },
    { lat: 48.2200855, lng: 19.4803505 },
    { lat: 48.220471, lng: 19.4807363 },
    { lat: 48.2208707, lng: 19.4809708 },
    { lat: 48.2211598, lng: 19.4813197 },
    { lat: 48.2211985, lng: 19.481445 },
    { lat: 48.2213857, lng: 19.4816413 },
    { lat: 48.2215705, lng: 19.4821941 },
    { lat: 48.2217737, lng: 19.4825641 },
    { lat: 48.2221362, lng: 19.4827972 },
    { lat: 48.2230679, lng: 19.483803 },
    { lat: 48.2237849, lng: 19.4819555 },
    { lat: 48.2262272, lng: 19.4783198 },
    { lat: 48.227321, lng: 19.4760912 },
    { lat: 48.2286082, lng: 19.4730642 },
    { lat: 48.2296564, lng: 19.4713308 },
    { lat: 48.230047, lng: 19.4705302 },
    { lat: 48.2309403, lng: 19.4684521 },
    { lat: 48.2316588, lng: 19.4656548 },
    { lat: 48.2322649, lng: 19.463945 },
    { lat: 48.2333239, lng: 19.461631 },
    { lat: 48.2337041, lng: 19.4605592 },
    { lat: 48.2343938, lng: 19.4592137 },
    { lat: 48.2361474, lng: 19.4566211 },
    { lat: 48.2369871, lng: 19.4557578 },
    { lat: 48.2376549, lng: 19.4551943 },
    { lat: 48.2381337, lng: 19.4542569 },
    { lat: 48.2383095, lng: 19.4541115 },
    { lat: 48.2388207, lng: 19.4538639 },
    { lat: 48.2406035, lng: 19.4511006 },
    { lat: 48.2416338, lng: 19.4500997 },
    { lat: 48.2417379, lng: 19.4499883 },
    { lat: 48.241805, lng: 19.4500498 },
    { lat: 48.2441549, lng: 19.4480622 },
    { lat: 48.2468699, lng: 19.4461257 },
    { lat: 48.2472759, lng: 19.4450514 },
    { lat: 48.2484475, lng: 19.4437736 },
    { lat: 48.2484518, lng: 19.4437686 },
    { lat: 48.2477664, lng: 19.4427593 },
    { lat: 48.2468902, lng: 19.44167 },
    { lat: 48.2466591, lng: 19.4394668 },
    { lat: 48.2465529, lng: 19.438843 },
    { lat: 48.2465227, lng: 19.4379353 },
    { lat: 48.2472353, lng: 19.435958 },
    { lat: 48.2472231, lng: 19.4358695 },
    { lat: 48.247736, lng: 19.4344126 },
    { lat: 48.2478619, lng: 19.4338816 },
    { lat: 48.2486351, lng: 19.4321135 },
    { lat: 48.2488275, lng: 19.4315118 },
    { lat: 48.2488807, lng: 19.4315125 },
    { lat: 48.2496025, lng: 19.4304025 },
    { lat: 48.2499662, lng: 19.4288916 },
    { lat: 48.2510934, lng: 19.426391 },
    { lat: 48.2524468, lng: 19.4247889 },
    { lat: 48.2540209, lng: 19.4240132 },
    { lat: 48.2544988, lng: 19.4238713 },
    { lat: 48.2560212, lng: 19.4229818 },
    { lat: 48.2581994, lng: 19.4199 },
    { lat: 48.2593646, lng: 19.4183953 },
    { lat: 48.2591288, lng: 19.4178873 },
    { lat: 48.2588929, lng: 19.417669 },
    { lat: 48.2583807, lng: 19.4168968 },
    { lat: 48.2583725, lng: 19.4168626 },
    { lat: 48.2580531, lng: 19.4155681 },
    { lat: 48.257982, lng: 19.4154936 },
    { lat: 48.2579688, lng: 19.4149786 },
    { lat: 48.2580401, lng: 19.4146039 },
    { lat: 48.2579301, lng: 19.4139482 },
    { lat: 48.2576565, lng: 19.413167 },
    { lat: 48.2576901, lng: 19.4129233 },
    { lat: 48.2576189, lng: 19.4123034 },
    { lat: 48.257635, lng: 19.4121386 },
    { lat: 48.2575494, lng: 19.4118082 },
    { lat: 48.257523, lng: 19.4110106 },
    { lat: 48.2574799, lng: 19.4109335 },
    { lat: 48.25753, lng: 19.4107337 },
    { lat: 48.2573167, lng: 19.4102488 },
    { lat: 48.2572279, lng: 19.4095159 },
    { lat: 48.2573264, lng: 19.4084557 },
    { lat: 48.2560615, lng: 19.4049084 },
    { lat: 48.2557102, lng: 19.4037256 },
    { lat: 48.2554263, lng: 19.4036805 },
    { lat: 48.2549458, lng: 19.4032449 },
    { lat: 48.2546138, lng: 19.4032569 },
    { lat: 48.2543055, lng: 19.4035033 },
    { lat: 48.253816, lng: 19.4043274 },
    { lat: 48.2536866, lng: 19.4046414 },
    { lat: 48.2533155, lng: 19.4049677 },
    { lat: 48.2531457, lng: 19.4049284 },
    { lat: 48.2527983, lng: 19.4046846 },
    { lat: 48.2525188, lng: 19.4047769 },
    { lat: 48.2523745, lng: 19.4049217 },
    { lat: 48.2522384, lng: 19.405334 },
    { lat: 48.2519737, lng: 19.4054558 },
    { lat: 48.2514873, lng: 19.4053915 },
    { lat: 48.2513996, lng: 19.4052067 },
    { lat: 48.251448, lng: 19.4049919 },
    { lat: 48.2514103, lng: 19.4048396 },
    { lat: 48.2512456, lng: 19.4046428 },
    { lat: 48.2510983, lng: 19.4046305 },
    { lat: 48.2509345, lng: 19.4049064 },
    { lat: 48.2508909, lng: 19.4053655 },
    { lat: 48.2507133, lng: 19.4054462 },
    { lat: 48.2505864, lng: 19.4054088 },
    { lat: 48.2504092, lng: 19.4051649 },
    { lat: 48.250343, lng: 19.4048643 },
    { lat: 48.2505282, lng: 19.4044665 },
    { lat: 48.2504888, lng: 19.4043477 },
    { lat: 48.2502635, lng: 19.4040636 },
    { lat: 48.2499118, lng: 19.4043029 },
    { lat: 48.2498922, lng: 19.4046505 },
    { lat: 48.2496987, lng: 19.4048928 },
    { lat: 48.2491936, lng: 19.4051046 },
    { lat: 48.2489496, lng: 19.4048306 },
    { lat: 48.2486793, lng: 19.4049577 },
    { lat: 48.2480755, lng: 19.4056021 },
    { lat: 48.2478765, lng: 19.4061113 },
    { lat: 48.2472798, lng: 19.4056757 },
    { lat: 48.2471682, lng: 19.4057484 },
    { lat: 48.247034, lng: 19.4061948 },
    { lat: 48.2467637, lng: 19.4062214 },
    { lat: 48.2452539, lng: 19.4070166 },
    { lat: 48.2451393, lng: 19.4069997 },
    { lat: 48.2448832, lng: 19.4071383 },
    { lat: 48.2447304, lng: 19.4073862 },
    { lat: 48.244387, lng: 19.4077006 },
    { lat: 48.2437621, lng: 19.4081202 },
    { lat: 48.2433974, lng: 19.4084616 },
    { lat: 48.2431341, lng: 19.4085433 },
    { lat: 48.2425302, lng: 19.4066006 },
    { lat: 48.242825, lng: 19.4061831 },
    { lat: 48.2433488, lng: 19.4047277 },
    { lat: 48.2423926, lng: 19.4036724 },
    { lat: 48.2420007, lng: 19.4038459 },
    { lat: 48.2413128, lng: 19.4043352 },
    { lat: 48.2406465, lng: 19.4050718 },
    { lat: 48.2382808, lng: 19.4069202 },
    { lat: 48.237897, lng: 19.4066516 },
    { lat: 48.2375249, lng: 19.4061704 },
    { lat: 48.2368221, lng: 19.405538 },
    { lat: 48.2368995, lng: 19.405458 },
    { lat: 48.2369326, lng: 19.4052293 },
    { lat: 48.2370179, lng: 19.4051754 },
    { lat: 48.2370923, lng: 19.4049366 },
    { lat: 48.2371007, lng: 19.4047812 },
    { lat: 48.2372734, lng: 19.4042438 },
    { lat: 48.2372567, lng: 19.4041254 },
    { lat: 48.23733, lng: 19.4040823 },
    { lat: 48.2373732, lng: 19.4041367 },
    { lat: 48.2374016, lng: 19.4039739 },
    { lat: 48.2373599, lng: 19.4038313 },
    { lat: 48.237455, lng: 19.4037695 },
    { lat: 48.2376233, lng: 19.4032998 },
    { lat: 48.2376656, lng: 19.4034109 },
    { lat: 48.2377317, lng: 19.4033742 },
    { lat: 48.2377378, lng: 19.4030798 },
    { lat: 48.2378818, lng: 19.4028103 },
    { lat: 48.2378729, lng: 19.4026974 },
    { lat: 48.2379404, lng: 19.4026216 },
    { lat: 48.2369646, lng: 19.4016283 },
    { lat: 48.2366714, lng: 19.4011282 },
    { lat: 48.236521, lng: 19.4014217 },
    { lat: 48.2363165, lng: 19.401343 },
    { lat: 48.2362439, lng: 19.401571 },
    { lat: 48.2361162, lng: 19.4015174 },
    { lat: 48.236116, lng: 19.4013438 },
    { lat: 48.2358929, lng: 19.4011806 },
    { lat: 48.235834, lng: 19.4009613 },
    { lat: 48.2356218, lng: 19.4007639 },
    { lat: 48.235541, lng: 19.4008425 },
    { lat: 48.2352649, lng: 19.4007227 },
    { lat: 48.2351824, lng: 19.4007923 },
    { lat: 48.2351488, lng: 19.4003416 },
    { lat: 48.2350362, lng: 19.3998961 },
    { lat: 48.2344618, lng: 19.3993828 },
    { lat: 48.2348241, lng: 19.3984187 },
    { lat: 48.2348902, lng: 19.3978153 },
    { lat: 48.234801, lng: 19.3975041 },
    { lat: 48.234905, lng: 19.3971325 },
    { lat: 48.2351322, lng: 19.396601 },
    { lat: 48.2352439, lng: 19.3958198 },
    { lat: 48.2352432, lng: 19.3952836 },
    { lat: 48.2350349, lng: 19.3949105 },
    { lat: 48.2348221, lng: 19.3941569 },
    { lat: 48.2346509, lng: 19.393824 },
    { lat: 48.2344968, lng: 19.393832 },
    { lat: 48.2343496, lng: 19.3938399 },
    { lat: 48.2338383, lng: 19.3930459 },
    { lat: 48.2326373, lng: 19.3916294 },
    { lat: 48.2320214, lng: 19.3910481 },
    { lat: 48.2308777, lng: 19.3896117 },
    { lat: 48.2296925, lng: 19.3880113 },
    { lat: 48.2290125, lng: 19.3880021 },
    { lat: 48.2281705, lng: 19.3881205 },
    { lat: 48.2267988, lng: 19.3871027 },
    { lat: 48.2256284, lng: 19.3854574 },
    { lat: 48.2252786, lng: 19.3856167 },
    { lat: 48.2246962, lng: 19.3856459 },
    { lat: 48.2240852, lng: 19.3851201 },
    { lat: 48.2240035, lng: 19.3851313 },
    { lat: 48.2226556, lng: 19.3833113 },
    { lat: 48.2206147, lng: 19.3835333 },
    { lat: 48.2203121, lng: 19.383661 },
    { lat: 48.2188591, lng: 19.3833968 },
    { lat: 48.2182981, lng: 19.3841843 },
    { lat: 48.2172239, lng: 19.3845852 },
    { lat: 48.2146494, lng: 19.3861436 },
    { lat: 48.2140655, lng: 19.3857598 },
    { lat: 48.2138714, lng: 19.3859459 },
    { lat: 48.2136051, lng: 19.3857983 },
    { lat: 48.2137277, lng: 19.3860273 },
    { lat: 48.2137906, lng: 19.3862856 },
    { lat: 48.2140745, lng: 19.3868971 },
    { lat: 48.2141876, lng: 19.3867865 },
    { lat: 48.2159844, lng: 19.3888294 },
    { lat: 48.2166077, lng: 19.3903197 },
    { lat: 48.2169382, lng: 19.3918896 },
    { lat: 48.2176768, lng: 19.3923474 },
    { lat: 48.2172589, lng: 19.3935627 },
    { lat: 48.2147993, lng: 19.3962103 },
    { lat: 48.2137885, lng: 19.4000329 },
    { lat: 48.2148276, lng: 19.4004348 },
    { lat: 48.2154412, lng: 19.4046236 },
    { lat: 48.2155791, lng: 19.405867 },
    { lat: 48.2154857, lng: 19.4073431 },
    { lat: 48.2156967, lng: 19.4075648 },
    { lat: 48.2158249, lng: 19.4077937 },
    { lat: 48.2154536, lng: 19.4083801 },
    { lat: 48.2166412, lng: 19.4103189 },
    { lat: 48.2193763, lng: 19.4092938 },
    { lat: 48.2201596, lng: 19.4105508 },
    { lat: 48.2208169, lng: 19.4131229 },
    { lat: 48.2209185, lng: 19.4132941 },
    { lat: 48.2210096, lng: 19.4151986 },
  ],
  HornéPlachtince: [
    { lat: 48.258846, lng: 19.226598 },
    { lat: 48.2582855, lng: 19.2273697 },
    { lat: 48.2577802, lng: 19.2282614 },
    { lat: 48.2572005, lng: 19.2297938 },
    { lat: 48.2569964, lng: 19.2301183 },
    { lat: 48.2566015, lng: 19.2304566 },
    { lat: 48.2562488, lng: 19.2306435 },
    { lat: 48.2557311, lng: 19.2307527 },
    { lat: 48.2555611, lng: 19.2308683 },
    { lat: 48.2548209, lng: 19.2318215 },
    { lat: 48.2545213, lng: 19.2321288 },
    { lat: 48.2536495, lng: 19.2334101 },
    { lat: 48.2529894, lng: 19.2334889 },
    { lat: 48.2528051, lng: 19.2335703 },
    { lat: 48.25249, lng: 19.2339717 },
    { lat: 48.2511116, lng: 19.2351528 },
    { lat: 48.2506578, lng: 19.2355287 },
    { lat: 48.2502224, lng: 19.2357536 },
    { lat: 48.2493401, lng: 19.2364979 },
    { lat: 48.2486002, lng: 19.2375495 },
    { lat: 48.2484286, lng: 19.2379811 },
    { lat: 48.2481116, lng: 19.2390641 },
    { lat: 48.2476935, lng: 19.2395711 },
    { lat: 48.2459181, lng: 19.2412329 },
    { lat: 48.2456121, lng: 19.242536 },
    { lat: 48.2451093, lng: 19.2450542 },
    { lat: 48.2444273, lng: 19.2468967 },
    { lat: 48.2437622, lng: 19.2480548 },
    { lat: 48.2432977, lng: 19.2496694 },
    { lat: 48.2428298, lng: 19.2507726 },
    { lat: 48.2423052, lng: 19.2515535 },
    { lat: 48.2419087, lng: 19.2519041 },
    { lat: 48.2413681, lng: 19.2527914 },
    { lat: 48.2412569, lng: 19.2530553 },
    { lat: 48.2410461, lng: 19.253218 },
    { lat: 48.2402509, lng: 19.2536217 },
    { lat: 48.2398865, lng: 19.2537779 },
    { lat: 48.2398683, lng: 19.2537046 },
    { lat: 48.2395184, lng: 19.25381 },
    { lat: 48.2390927, lng: 19.254162 },
    { lat: 48.2386175, lng: 19.2548235 },
    { lat: 48.2380133, lng: 19.2549147 },
    { lat: 48.2377223, lng: 19.2547032 },
    { lat: 48.2374225, lng: 19.2541734 },
    { lat: 48.2371762, lng: 19.253927 },
    { lat: 48.2360615, lng: 19.2533076 },
    { lat: 48.2360501, lng: 19.2533308 },
    { lat: 48.236039, lng: 19.2533532 },
    { lat: 48.235378, lng: 19.2550332 },
    { lat: 48.2352748, lng: 19.2554355 },
    { lat: 48.2352143, lng: 19.2564385 },
    { lat: 48.2352562, lng: 19.2569971 },
    { lat: 48.2344275, lng: 19.257549 },
    { lat: 48.234701, lng: 19.2580966 },
    { lat: 48.2347363, lng: 19.2584045 },
    { lat: 48.2348968, lng: 19.2586674 },
    { lat: 48.2348171, lng: 19.2591842 },
    { lat: 48.2345421, lng: 19.2592078 },
    { lat: 48.234119, lng: 19.2590603 },
    { lat: 48.2338818, lng: 19.2590741 },
    { lat: 48.2333923, lng: 19.2593005 },
    { lat: 48.2328954, lng: 19.2597447 },
    { lat: 48.232527, lng: 19.2598383 },
    { lat: 48.2323448, lng: 19.2599624 },
    { lat: 48.2320899, lng: 19.2603049 },
    { lat: 48.2318292, lng: 19.2608117 },
    { lat: 48.2317124, lng: 19.2613039 },
    { lat: 48.2316206, lng: 19.2632853 },
    { lat: 48.2315583, lng: 19.2651315 },
    { lat: 48.2316753, lng: 19.2651517 },
    { lat: 48.2315161, lng: 19.2660246 },
    { lat: 48.2312538, lng: 19.2662423 },
    { lat: 48.2312109, lng: 19.2668647 },
    { lat: 48.2308683, lng: 19.2676306 },
    { lat: 48.2309092, lng: 19.2678012 },
    { lat: 48.2308594, lng: 19.2678886 },
    { lat: 48.2308048, lng: 19.2683213 },
    { lat: 48.2307761, lng: 19.2689824 },
    { lat: 48.2305115, lng: 19.2702846 },
    { lat: 48.2302316, lng: 19.2709978 },
    { lat: 48.2300791, lng: 19.2711996 },
    { lat: 48.2300029, lng: 19.2715809 },
    { lat: 48.2298275, lng: 19.2717962 },
    { lat: 48.2297903, lng: 19.272075 },
    { lat: 48.2298412, lng: 19.2721953 },
    { lat: 48.2298394, lng: 19.2723913 },
    { lat: 48.2297727, lng: 19.2725485 },
    { lat: 48.2298308, lng: 19.2726765 },
    { lat: 48.2298439, lng: 19.2730124 },
    { lat: 48.2300429, lng: 19.2732152 },
    { lat: 48.2302181, lng: 19.273918 },
    { lat: 48.2301835, lng: 19.274252 },
    { lat: 48.230285, lng: 19.2743908 },
    { lat: 48.2302894, lng: 19.2744826 },
    { lat: 48.2303437, lng: 19.2745549 },
    { lat: 48.2304629, lng: 19.2745532 },
    { lat: 48.2307089, lng: 19.2748803 },
    { lat: 48.2308187, lng: 19.2748461 },
    { lat: 48.2309671, lng: 19.2749484 },
    { lat: 48.2310152, lng: 19.2751086 },
    { lat: 48.2315823, lng: 19.2753898 },
    { lat: 48.2319351, lng: 19.2757562 },
    { lat: 48.2319494, lng: 19.2763101 },
    { lat: 48.2319963, lng: 19.2765298 },
    { lat: 48.2322365, lng: 19.2767632 },
    { lat: 48.232201, lng: 19.2768445 },
    { lat: 48.2322426, lng: 19.2771723 },
    { lat: 48.2323349, lng: 19.2772825 },
    { lat: 48.232322, lng: 19.2777619 },
    { lat: 48.2323826, lng: 19.2778705 },
    { lat: 48.2324236, lng: 19.2788761 },
    { lat: 48.2324768, lng: 19.2791832 },
    { lat: 48.2324498, lng: 19.2794393 },
    { lat: 48.2325193, lng: 19.2795969 },
    { lat: 48.2324711, lng: 19.2800207 },
    { lat: 48.2325098, lng: 19.2801657 },
    { lat: 48.2325016, lng: 19.2805756 },
    { lat: 48.2324379, lng: 19.2807961 },
    { lat: 48.2325021, lng: 19.2812371 },
    { lat: 48.232438, lng: 19.282053 },
    { lat: 48.2324736, lng: 19.2821155 },
    { lat: 48.2323239, lng: 19.2824924 },
    { lat: 48.23202, lng: 19.2830335 },
    { lat: 48.2318489, lng: 19.2836213 },
    { lat: 48.2320232, lng: 19.2846838 },
    { lat: 48.2322398, lng: 19.2852433 },
    { lat: 48.2320537, lng: 19.2853123 },
    { lat: 48.2325204, lng: 19.286889 },
    { lat: 48.2329258, lng: 19.2879982 },
    { lat: 48.2330129, lng: 19.2881227 },
    { lat: 48.2330966, lng: 19.2879888 },
    { lat: 48.2332522, lng: 19.2879921 },
    { lat: 48.2333876, lng: 19.2881793 },
    { lat: 48.233455, lng: 19.2881013 },
    { lat: 48.2337723, lng: 19.2881372 },
    { lat: 48.2340655, lng: 19.2880031 },
    { lat: 48.2343433, lng: 19.287735 },
    { lat: 48.2346447, lng: 19.2872766 },
    { lat: 48.2349075, lng: 19.2870953 },
    { lat: 48.2350075, lng: 19.2871117 },
    { lat: 48.2352141, lng: 19.2874899 },
    { lat: 48.2354887, lng: 19.2876937 },
    { lat: 48.2356651, lng: 19.2880723 },
    { lat: 48.235992, lng: 19.2879357 },
    { lat: 48.2357781, lng: 19.2873568 },
    { lat: 48.2364457, lng: 19.2870616 },
    { lat: 48.2376422, lng: 19.2874203 },
    { lat: 48.2377913, lng: 19.2872234 },
    { lat: 48.2379492, lng: 19.2866646 },
    { lat: 48.2381104, lng: 19.2863638 },
    { lat: 48.2384161, lng: 19.2860633 },
    { lat: 48.2386632, lng: 19.2857225 },
    { lat: 48.2390682, lng: 19.2850042 },
    { lat: 48.2392724, lng: 19.2848469 },
    { lat: 48.2394843, lng: 19.2848229 },
    { lat: 48.2399615, lng: 19.2850199 },
    { lat: 48.240314, lng: 19.2849675 },
    { lat: 48.240341, lng: 19.28513 },
    { lat: 48.2405329, lng: 19.2852726 },
    { lat: 48.2411307, lng: 19.2846047 },
    { lat: 48.2410463, lng: 19.284424 },
    { lat: 48.2417798, lng: 19.2836756 },
    { lat: 48.2418446, lng: 19.2837541 },
    { lat: 48.2423823, lng: 19.2838927 },
    { lat: 48.2427462, lng: 19.2841254 },
    { lat: 48.2427787, lng: 19.2845628 },
    { lat: 48.2432733, lng: 19.2845812 },
    { lat: 48.2432717, lng: 19.2844558 },
    { lat: 48.2441448, lng: 19.2835123 },
    { lat: 48.2441475, lng: 19.2833588 },
    { lat: 48.2444716, lng: 19.2833243 },
    { lat: 48.2447288, lng: 19.2831436 },
    { lat: 48.244976, lng: 19.2828504 },
    { lat: 48.2456269, lng: 19.2818836 },
    { lat: 48.2457194, lng: 19.2816299 },
    { lat: 48.2459064, lng: 19.2813252 },
    { lat: 48.2463416, lng: 19.2808602 },
    { lat: 48.2464935, lng: 19.2805541 },
    { lat: 48.2468912, lng: 19.2801349 },
    { lat: 48.2471404, lng: 19.2793227 },
    { lat: 48.2475827, lng: 19.2785889 },
    { lat: 48.2477769, lng: 19.2784287 },
    { lat: 48.2489054, lng: 19.27818 },
    { lat: 48.2493999, lng: 19.2781977 },
    { lat: 48.2496209, lng: 19.2781276 },
    { lat: 48.2498622, lng: 19.2777113 },
    { lat: 48.2506406, lng: 19.276793 },
    { lat: 48.2506894, lng: 19.2766614 },
    { lat: 48.2507339, lng: 19.2757423 },
    { lat: 48.2508952, lng: 19.2745163 },
    { lat: 48.250977, lng: 19.2745091 },
    { lat: 48.2511043, lng: 19.2743177 },
    { lat: 48.251555, lng: 19.2734824 },
    { lat: 48.2522093, lng: 19.27166 },
    { lat: 48.2541584, lng: 19.2693884 },
    { lat: 48.2543811, lng: 19.2689167 },
    { lat: 48.2546948, lng: 19.2684358 },
    { lat: 48.2553477, lng: 19.2676724 },
    { lat: 48.2560605, lng: 19.2670107 },
    { lat: 48.2570239, lng: 19.2658686 },
    { lat: 48.2584518, lng: 19.2646357 },
    { lat: 48.2587834, lng: 19.2644973 },
    { lat: 48.2591278, lng: 19.2644643 },
    { lat: 48.2596987, lng: 19.2645223 },
    { lat: 48.2603463, lng: 19.2641849 },
    { lat: 48.2609824, lng: 19.2639376 },
    { lat: 48.2612473, lng: 19.2637516 },
    { lat: 48.2615152, lng: 19.263422 },
    { lat: 48.261829, lng: 19.2632438 },
    { lat: 48.2619674, lng: 19.2630791 },
    { lat: 48.26226, lng: 19.2624429 },
    { lat: 48.2625452, lng: 19.2621189 },
    { lat: 48.2631344, lng: 19.2622286 },
    { lat: 48.2632968, lng: 19.2622059 },
    { lat: 48.2635047, lng: 19.2618419 },
    { lat: 48.2649715, lng: 19.261225 },
    { lat: 48.2668869, lng: 19.2601862 },
    { lat: 48.2672034, lng: 19.2595789 },
    { lat: 48.2675945, lng: 19.2590504 },
    { lat: 48.2685507, lng: 19.2580112 },
    { lat: 48.2691289, lng: 19.2575256 },
    { lat: 48.2695781, lng: 19.25733 },
    { lat: 48.2704316, lng: 19.2570988 },
    { lat: 48.2708674, lng: 19.256795 },
    { lat: 48.2713265, lng: 19.2563797 },
    { lat: 48.2716592, lng: 19.2561989 },
    { lat: 48.2722541, lng: 19.2555703 },
    { lat: 48.2722445, lng: 19.2550477 },
    { lat: 48.2725653, lng: 19.253788 },
    { lat: 48.2724102, lng: 19.2511636 },
    { lat: 48.2722265, lng: 19.2502832 },
    { lat: 48.2717643, lng: 19.2499831 },
    { lat: 48.2715602, lng: 19.2497758 },
    { lat: 48.2714687, lng: 19.2497695 },
    { lat: 48.2716673, lng: 19.2493805 },
    { lat: 48.2713443, lng: 19.2487443 },
    { lat: 48.271279, lng: 19.2483775 },
    { lat: 48.271496, lng: 19.2480183 },
    { lat: 48.2715877, lng: 19.2477238 },
    { lat: 48.2718543, lng: 19.2474115 },
    { lat: 48.2719529, lng: 19.2474237 },
    { lat: 48.2720034, lng: 19.2471656 },
    { lat: 48.2718217, lng: 19.2462832 },
    { lat: 48.2718645, lng: 19.2460952 },
    { lat: 48.2718239, lng: 19.2460724 },
    { lat: 48.2716659, lng: 19.2459772 },
    { lat: 48.2713306, lng: 19.2456197 },
    { lat: 48.2710449, lng: 19.2454968 },
    { lat: 48.270949, lng: 19.2453081 },
    { lat: 48.2705895, lng: 19.245265 },
    { lat: 48.2704408, lng: 19.2451274 },
    { lat: 48.2701143, lng: 19.2451589 },
    { lat: 48.2699991, lng: 19.2450827 },
    { lat: 48.2699899, lng: 19.2445278 },
    { lat: 48.2699593, lng: 19.2444081 },
    { lat: 48.2698496, lng: 19.2443337 },
    { lat: 48.2697982, lng: 19.2441816 },
    { lat: 48.2695421, lng: 19.2424908 },
    { lat: 48.2692117, lng: 19.2423968 },
    { lat: 48.2690463, lng: 19.242192 },
    { lat: 48.2690748, lng: 19.2417841 },
    { lat: 48.2687589, lng: 19.2413174 },
    { lat: 48.2688369, lng: 19.2409076 },
    { lat: 48.2687983, lng: 19.2407607 },
    { lat: 48.2686632, lng: 19.240673 },
    { lat: 48.2685546, lng: 19.2405062 },
    { lat: 48.2683652, lng: 19.2404346 },
    { lat: 48.2682087, lng: 19.2402766 },
    { lat: 48.268032, lng: 19.2397982 },
    { lat: 48.2678968, lng: 19.239611 },
    { lat: 48.2677126, lng: 19.2390276 },
    { lat: 48.2674924, lng: 19.2385574 },
    { lat: 48.2673791, lng: 19.2383364 },
    { lat: 48.2670338, lng: 19.2380342 },
    { lat: 48.266727, lng: 19.2373818 },
    { lat: 48.2666128, lng: 19.2361529 },
    { lat: 48.2664907, lng: 19.2354523 },
    { lat: 48.2663083, lng: 19.2351366 },
    { lat: 48.2660343, lng: 19.2343019 },
    { lat: 48.2660764, lng: 19.2339644 },
    { lat: 48.2658366, lng: 19.2338329 },
    { lat: 48.2656544, lng: 19.2335649 },
    { lat: 48.2656389, lng: 19.2332921 },
    { lat: 48.2654869, lng: 19.2330926 },
    { lat: 48.2654238, lng: 19.2327429 },
    { lat: 48.265272, lng: 19.232369 },
    { lat: 48.265239, lng: 19.232414 },
    { lat: 48.26508, lng: 19.232481 },
    { lat: 48.264724, lng: 19.23241 },
    { lat: 48.264408, lng: 19.23247 },
    { lat: 48.264133, lng: 19.232695 },
    { lat: 48.263864, lng: 19.232621 },
    { lat: 48.263758, lng: 19.232553 },
    { lat: 48.263438, lng: 19.232792 },
    { lat: 48.262944, lng: 19.23297 },
    { lat: 48.262769, lng: 19.233035 },
    { lat: 48.262616, lng: 19.233237 },
    { lat: 48.262266, lng: 19.233363 },
    { lat: 48.261984, lng: 19.233593 },
    { lat: 48.261415, lng: 19.233811 },
    { lat: 48.261372, lng: 19.233725 },
    { lat: 48.26106, lng: 19.233274 },
    { lat: 48.260968, lng: 19.233143 },
    { lat: 48.260598, lng: 19.232656 },
    { lat: 48.260354, lng: 19.231886 },
    { lat: 48.260153, lng: 19.231509 },
    { lat: 48.259845, lng: 19.231162 },
    { lat: 48.259646, lng: 19.230509 },
    { lat: 48.259635, lng: 19.23013 },
    { lat: 48.25963, lng: 19.230042 },
    { lat: 48.259615, lng: 19.229717 },
    { lat: 48.259634, lng: 19.229155 },
    { lat: 48.259647, lng: 19.228985 },
    { lat: 48.259662, lng: 19.228798 },
    { lat: 48.259666, lng: 19.228695 },
    { lat: 48.259667, lng: 19.228447 },
    { lat: 48.259291, lng: 19.227605 },
    { lat: 48.258833, lng: 19.226676 },
    { lat: 48.258846, lng: 19.226598 },
  ],
  VeľkáČalomija: [
    { lat: 48.0996667, lng: 19.1863798 },
    { lat: 48.0993013, lng: 19.1864674 },
    { lat: 48.0988585, lng: 19.18686 },
    { lat: 48.0986356, lng: 19.1869576 },
    { lat: 48.0984064, lng: 19.1869508 },
    { lat: 48.0979719, lng: 19.1874169 },
    { lat: 48.0978918, lng: 19.1882376 },
    { lat: 48.0977659, lng: 19.1885212 },
    { lat: 48.0975284, lng: 19.1887516 },
    { lat: 48.0972266, lng: 19.188873 },
    { lat: 48.0969993, lng: 19.1888342 },
    { lat: 48.0962868, lng: 19.1884946 },
    { lat: 48.0954266, lng: 19.1888799 },
    { lat: 48.0951322, lng: 19.1888785 },
    { lat: 48.0946276, lng: 19.1888157 },
    { lat: 48.0942192, lng: 19.1888536 },
    { lat: 48.0937242, lng: 19.1886695 },
    { lat: 48.0930763, lng: 19.1885625 },
    { lat: 48.0835257, lng: 19.1882161 },
    { lat: 48.0767322, lng: 19.1872773 },
    { lat: 48.0757473, lng: 19.1871902 },
    { lat: 48.0756632, lng: 19.1871315 },
    { lat: 48.0739338, lng: 19.1870369 },
    { lat: 48.073677, lng: 19.1869077 },
    { lat: 48.0733524, lng: 19.1866357 },
    { lat: 48.0730609, lng: 19.1865963 },
    { lat: 48.0727786, lng: 19.1865862 },
    { lat: 48.0714185, lng: 19.1875098 },
    { lat: 48.0705338, lng: 19.1878575 },
    { lat: 48.0668932, lng: 19.1871146 },
    { lat: 48.0653787, lng: 19.1858782 },
    { lat: 48.0639902, lng: 19.1843572 },
    { lat: 48.0632079, lng: 19.1837912 },
    { lat: 48.0628601, lng: 19.1838708 },
    { lat: 48.0627761, lng: 19.1837443 },
    { lat: 48.0626978, lng: 19.1831655 },
    { lat: 48.0625944, lng: 19.1830324 },
    { lat: 48.0621464, lng: 19.1831153 },
    { lat: 48.061633, lng: 19.18281 },
    { lat: 48.0614654, lng: 19.1825445 },
    { lat: 48.0613515, lng: 19.1815196 },
    { lat: 48.061274, lng: 19.181416 },
    { lat: 48.061279, lng: 19.181678 },
    { lat: 48.061429, lng: 19.18324 },
    { lat: 48.061458, lng: 19.183737 },
    { lat: 48.061455, lng: 19.184356 },
    { lat: 48.061385, lng: 19.185075 },
    { lat: 48.061229, lng: 19.18586 },
    { lat: 48.061136, lng: 19.186141 },
    { lat: 48.061023, lng: 19.186432 },
    { lat: 48.060907, lng: 19.186708 },
    { lat: 48.060812, lng: 19.186964 },
    { lat: 48.060752, lng: 19.187123 },
    { lat: 48.060595, lng: 19.187562 },
    { lat: 48.060502, lng: 19.187932 },
    { lat: 48.060417, lng: 19.188325 },
    { lat: 48.060361, lng: 19.188858 },
    { lat: 48.060348, lng: 19.189289 },
    { lat: 48.060361, lng: 19.189981 },
    { lat: 48.060381, lng: 19.190271 },
    { lat: 48.060543, lng: 19.191003 },
    { lat: 48.060551, lng: 19.191036 },
    { lat: 48.060745, lng: 19.191554 },
    { lat: 48.060985, lng: 19.192013 },
    { lat: 48.061172, lng: 19.192369 },
    { lat: 48.06139, lng: 19.192958 },
    { lat: 48.061428, lng: 19.193176 },
    { lat: 48.061487, lng: 19.193434 },
    { lat: 48.061519, lng: 19.193605 },
    { lat: 48.061583, lng: 19.193944 },
    { lat: 48.0616, lng: 19.194383 },
    { lat: 48.06161, lng: 19.194645 },
    { lat: 48.061597, lng: 19.195388 },
    { lat: 48.061604, lng: 19.195464 },
    { lat: 48.061642, lng: 19.195884 },
    { lat: 48.061694, lng: 19.196208 },
    { lat: 48.061742, lng: 19.196501 },
    { lat: 48.061866, lng: 19.197139 },
    { lat: 48.061986, lng: 19.197528 },
    { lat: 48.062224, lng: 19.198062 },
    { lat: 48.062425, lng: 19.198507 },
    { lat: 48.062562, lng: 19.198772 },
    { lat: 48.06284, lng: 19.199364 },
    { lat: 48.063069, lng: 19.200054 },
    { lat: 48.063222, lng: 19.200584 },
    { lat: 48.063314, lng: 19.20104 },
    { lat: 48.063374, lng: 19.201476 },
    { lat: 48.0634, lng: 19.202039 },
    { lat: 48.063403, lng: 19.202331 },
    { lat: 48.063317, lng: 19.203483 },
    { lat: 48.063292, lng: 19.203676 },
    { lat: 48.063229, lng: 19.204161 },
    { lat: 48.063204, lng: 19.204553 },
    { lat: 48.063188, lng: 19.205307 },
    { lat: 48.063185, lng: 19.205452 },
    { lat: 48.06322, lng: 19.205809 },
    { lat: 48.063229, lng: 19.206036 },
    { lat: 48.063247, lng: 19.206202 },
    { lat: 48.063296, lng: 19.206682 },
    { lat: 48.063328, lng: 19.206961 },
    { lat: 48.063409, lng: 19.207359 },
    { lat: 48.063472, lng: 19.20766 },
    { lat: 48.063581, lng: 19.208088 },
    { lat: 48.063862, lng: 19.209052 },
    { lat: 48.063948, lng: 19.209329 },
    { lat: 48.064066, lng: 19.209756 },
    { lat: 48.064102, lng: 19.209957 },
    { lat: 48.064237, lng: 19.210506 },
    { lat: 48.064287, lng: 19.210714 },
    { lat: 48.06437, lng: 19.211084 },
    { lat: 48.064406, lng: 19.211449 },
    { lat: 48.064408, lng: 19.211473 },
    { lat: 48.064396, lng: 19.212269 },
    { lat: 48.064362, lng: 19.212823 },
    { lat: 48.064162, lng: 19.213761 },
    { lat: 48.063948, lng: 19.214397 },
    { lat: 48.063766, lng: 19.21482 },
    { lat: 48.063484, lng: 19.215413 },
    { lat: 48.063436, lng: 19.215518 },
    { lat: 48.063066, lng: 19.216343 },
    { lat: 48.062974, lng: 19.216613 },
    { lat: 48.0684968, lng: 19.2168773 },
    { lat: 48.0702314, lng: 19.2173071 },
    { lat: 48.0710915, lng: 19.2178289 },
    { lat: 48.0708969, lng: 19.2184117 },
    { lat: 48.0720995, lng: 19.2191892 },
    { lat: 48.0729351, lng: 19.2199066 },
    { lat: 48.073069, lng: 19.2199673 },
    { lat: 48.0744793, lng: 19.2199929 },
    { lat: 48.0750402, lng: 19.2201451 },
    { lat: 48.0815758, lng: 19.2191983 },
    { lat: 48.0837275, lng: 19.2189625 },
    { lat: 48.0838453, lng: 19.2187698 },
    { lat: 48.0847542, lng: 19.2184311 },
    { lat: 48.085963, lng: 19.2177998 },
    { lat: 48.0866773, lng: 19.2169878 },
    { lat: 48.0876689, lng: 19.2157011 },
    { lat: 48.0877986, lng: 19.2156784 },
    { lat: 48.0880199, lng: 19.2155244 },
    { lat: 48.0885418, lng: 19.2149305 },
    { lat: 48.0892932, lng: 19.2137745 },
    { lat: 48.0893873, lng: 19.2137853 },
    { lat: 48.0895996, lng: 19.2134979 },
    { lat: 48.0901004, lng: 19.2136172 },
    { lat: 48.0903794, lng: 19.2131243 },
    { lat: 48.0906322, lng: 19.2129245 },
    { lat: 48.0908178, lng: 19.2126386 },
    { lat: 48.0912894, lng: 19.2113891 },
    { lat: 48.091521, lng: 19.2104625 },
    { lat: 48.0916569, lng: 19.2096771 },
    { lat: 48.0917193, lng: 19.2089305 },
    { lat: 48.0921048, lng: 19.2075307 },
    { lat: 48.0926638, lng: 19.2061833 },
    { lat: 48.0927166, lng: 19.2058115 },
    { lat: 48.0928757, lng: 19.2054263 },
    { lat: 48.0929839, lng: 19.2048329 },
    { lat: 48.0969567, lng: 19.2055952 },
    { lat: 48.0979504, lng: 19.2048516 },
    { lat: 48.0985502, lng: 19.2045307 },
    { lat: 48.0992166, lng: 19.2040579 },
    { lat: 48.0995661, lng: 19.2038974 },
    { lat: 48.1009529, lng: 19.2029437 },
    { lat: 48.1015987, lng: 19.2026167 },
    { lat: 48.102336, lng: 19.2020958 },
    { lat: 48.1030823, lng: 19.2014565 },
    { lat: 48.104189, lng: 19.2003317 },
    { lat: 48.103818, lng: 19.1971758 },
    { lat: 48.1033866, lng: 19.1959995 },
    { lat: 48.1027022, lng: 19.1946973 },
    { lat: 48.1022169, lng: 19.1933612 },
    { lat: 48.1021902, lng: 19.1930023 },
    { lat: 48.1025444, lng: 19.1918446 },
    { lat: 48.1026328, lng: 19.1911475 },
    { lat: 48.1025994, lng: 19.1907209 },
    { lat: 48.1024771, lng: 19.1903259 },
    { lat: 48.1024799, lng: 19.1897423 },
    { lat: 48.1025358, lng: 19.1894332 },
    { lat: 48.1022824, lng: 19.1889385 },
    { lat: 48.101437, lng: 19.1875741 },
    { lat: 48.1011946, lng: 19.1873014 },
    { lat: 48.1007152, lng: 19.187022 },
    { lat: 48.1001558, lng: 19.1868108 },
    { lat: 48.0998249, lng: 19.1865683 },
    { lat: 48.0996667, lng: 19.1863798 },
  ],
  NováVes: [
    { lat: 48.1684602, lng: 19.3529206 },
    { lat: 48.1679415, lng: 19.3543485 },
    { lat: 48.1675474, lng: 19.3557013 },
    { lat: 48.1657253, lng: 19.3578461 },
    { lat: 48.1654409, lng: 19.3579107 },
    { lat: 48.1652755, lng: 19.3582925 },
    { lat: 48.164775, lng: 19.358954 },
    { lat: 48.1638005, lng: 19.3607239 },
    { lat: 48.1638656, lng: 19.361989 },
    { lat: 48.1639149, lng: 19.3622877 },
    { lat: 48.1642125, lng: 19.3625262 },
    { lat: 48.1641901, lng: 19.3633301 },
    { lat: 48.1640348, lng: 19.3634074 },
    { lat: 48.1641239, lng: 19.3651493 },
    { lat: 48.1641053, lng: 19.3656558 },
    { lat: 48.1640365, lng: 19.3654651 },
    { lat: 48.1638343, lng: 19.3655298 },
    { lat: 48.1640211, lng: 19.3673103 },
    { lat: 48.1639546, lng: 19.3674064 },
    { lat: 48.1640266, lng: 19.3691014 },
    { lat: 48.1646897, lng: 19.3704773 },
    { lat: 48.1647009, lng: 19.3709919 },
    { lat: 48.164778, lng: 19.3711171 },
    { lat: 48.1642866, lng: 19.3722082 },
    { lat: 48.1633394, lng: 19.3722893 },
    { lat: 48.1618425, lng: 19.3736783 },
    { lat: 48.1609532, lng: 19.3743645 },
    { lat: 48.1610856, lng: 19.3745396 },
    { lat: 48.1607648, lng: 19.3750677 },
    { lat: 48.1606117, lng: 19.3754268 },
    { lat: 48.1595176, lng: 19.378477 },
    { lat: 48.1603564, lng: 19.3793107 },
    { lat: 48.1603335, lng: 19.381098 },
    { lat: 48.1590575, lng: 19.3817772 },
    { lat: 48.1591707, lng: 19.382002 },
    { lat: 48.1595049, lng: 19.3825323 },
    { lat: 48.1598919, lng: 19.3836281 },
    { lat: 48.1604526, lng: 19.3844406 },
    { lat: 48.1610862, lng: 19.3848511 },
    { lat: 48.1614339, lng: 19.3847181 },
    { lat: 48.1618121, lng: 19.3849669 },
    { lat: 48.1626807, lng: 19.3848175 },
    { lat: 48.1630418, lng: 19.3850152 },
    { lat: 48.1634746, lng: 19.3849917 },
    { lat: 48.1640641, lng: 19.3847101 },
    { lat: 48.1644932, lng: 19.3849422 },
    { lat: 48.165084, lng: 19.3848009 },
    { lat: 48.1654707, lng: 19.3848024 },
    { lat: 48.1657617, lng: 19.384997 },
    { lat: 48.165863, lng: 19.3849262 },
    { lat: 48.1673149, lng: 19.3854727 },
    { lat: 48.1679163, lng: 19.3855594 },
    { lat: 48.1683689, lng: 19.3857587 },
    { lat: 48.1684734, lng: 19.3867919 },
    { lat: 48.1696295, lng: 19.3873774 },
    { lat: 48.1688584, lng: 19.3928667 },
    { lat: 48.1688182, lng: 19.392871 },
    { lat: 48.1687985, lng: 19.3930026 },
    { lat: 48.1689564, lng: 19.3942357 },
    { lat: 48.1690033, lng: 19.3952708 },
    { lat: 48.1689506, lng: 19.3962237 },
    { lat: 48.1687558, lng: 19.3970787 },
    { lat: 48.1686274, lng: 19.3985996 },
    { lat: 48.1683715, lng: 19.3994035 },
    { lat: 48.1682123, lng: 19.3996809 },
    { lat: 48.1682531, lng: 19.3997582 },
    { lat: 48.1683531, lng: 19.3997758 },
    { lat: 48.1686402, lng: 19.3997241 },
    { lat: 48.1689303, lng: 19.3997547 },
    { lat: 48.1694369, lng: 19.3999332 },
    { lat: 48.1695773, lng: 19.4000906 },
    { lat: 48.1699104, lng: 19.4009079 },
    { lat: 48.1698779, lng: 19.4010773 },
    { lat: 48.1700944, lng: 19.4020068 },
    { lat: 48.1701632, lng: 19.4025994 },
    { lat: 48.1702232, lng: 19.4036229 },
    { lat: 48.1701814, lng: 19.4041651 },
    { lat: 48.1700064, lng: 19.4051105 },
    { lat: 48.1702332, lng: 19.4054243 },
    { lat: 48.170303, lng: 19.4065866 },
    { lat: 48.170224, lng: 19.4073894 },
    { lat: 48.1699045, lng: 19.4089676 },
    { lat: 48.1709792, lng: 19.4071299 },
    { lat: 48.1715685, lng: 19.4057967 },
    { lat: 48.1720485, lng: 19.4054417 },
    { lat: 48.1722929, lng: 19.4050574 },
    { lat: 48.1727467, lng: 19.4047169 },
    { lat: 48.1730556, lng: 19.4041488 },
    { lat: 48.1731737, lng: 19.404119 },
    { lat: 48.1735079, lng: 19.4036064 },
    { lat: 48.1739543, lng: 19.4031604 },
    { lat: 48.1741709, lng: 19.4028334 },
    { lat: 48.1743554, lng: 19.4027788 },
    { lat: 48.1745614, lng: 19.4024865 },
    { lat: 48.1747558, lng: 19.4024524 },
    { lat: 48.1747246, lng: 19.4023177 },
    { lat: 48.1749044, lng: 19.4021164 },
    { lat: 48.1751872, lng: 19.4019497 },
    { lat: 48.1753389, lng: 19.401727 },
    { lat: 48.1761508, lng: 19.401591 },
    { lat: 48.1764346, lng: 19.4014192 },
    { lat: 48.1767247, lng: 19.4014527 },
    { lat: 48.1770146, lng: 19.4013643 },
    { lat: 48.1780656, lng: 19.4017583 },
    { lat: 48.1792306, lng: 19.4023373 },
    { lat: 48.1796039, lng: 19.4024163 },
    { lat: 48.1809014, lng: 19.4030844 },
    { lat: 48.1811404, lng: 19.4030163 },
    { lat: 48.1812458, lng: 19.4029073 },
    { lat: 48.1816446, lng: 19.4021939 },
    { lat: 48.1819347, lng: 19.4017792 },
    { lat: 48.1824625, lng: 19.4008127 },
    { lat: 48.1824641, lng: 19.3990432 },
    { lat: 48.1822957, lng: 19.3978466 },
    { lat: 48.1823088, lng: 19.3971969 },
    { lat: 48.1822304, lng: 19.3955994 },
    { lat: 48.1824765, lng: 19.3940645 },
    { lat: 48.1824293, lng: 19.3930486 },
    { lat: 48.1825241, lng: 19.3926475 },
    { lat: 48.1830316, lng: 19.3912127 },
    { lat: 48.1821605, lng: 19.3900549 },
    { lat: 48.1824175, lng: 19.3893671 },
    { lat: 48.1824596, lng: 19.3889021 },
    { lat: 48.1829749, lng: 19.3874424 },
    { lat: 48.1829988, lng: 19.3869729 },
    { lat: 48.1832239, lng: 19.386517 },
    { lat: 48.1837713, lng: 19.3860165 },
    { lat: 48.1838229, lng: 19.3849667 },
    { lat: 48.1842162, lng: 19.3846356 },
    { lat: 48.1843369, lng: 19.3844228 },
    { lat: 48.1843638, lng: 19.3841614 },
    { lat: 48.1842671, lng: 19.3837868 },
    { lat: 48.1840085, lng: 19.3832326 },
    { lat: 48.1839515, lng: 19.382746 },
    { lat: 48.1841785, lng: 19.3817153 },
    { lat: 48.1848914, lng: 19.3800288 },
    { lat: 48.1852505, lng: 19.3793085 },
    { lat: 48.1847525, lng: 19.3787722 },
    { lat: 48.1851318, lng: 19.3778597 },
    { lat: 48.1861223, lng: 19.376296 },
    { lat: 48.1866307, lng: 19.37519 },
    { lat: 48.1867647, lng: 19.374524 },
    { lat: 48.1868349, lng: 19.3738967 },
    { lat: 48.1866749, lng: 19.3722237 },
    { lat: 48.186737, lng: 19.3713278 },
    { lat: 48.1869352, lng: 19.3702163 },
    { lat: 48.1871076, lng: 19.3699305 },
    { lat: 48.1869228, lng: 19.3683029 },
    { lat: 48.1864081, lng: 19.3672973 },
    { lat: 48.1862196, lng: 19.3667979 },
    { lat: 48.1860997, lng: 19.3663668 },
    { lat: 48.1857142, lng: 19.3640533 },
    { lat: 48.1855909, lng: 19.3634819 },
    { lat: 48.1854635, lng: 19.363129 },
    { lat: 48.1841285, lng: 19.3616069 },
    { lat: 48.1837235, lng: 19.3612928 },
    { lat: 48.1828492, lng: 19.3608796 },
    { lat: 48.1824055, lng: 19.3609345 },
    { lat: 48.1818104, lng: 19.3607948 },
    { lat: 48.1816859, lng: 19.360735 },
    { lat: 48.1816214, lng: 19.360565 },
    { lat: 48.1816956, lng: 19.3601827 },
    { lat: 48.1819402, lng: 19.3598984 },
    { lat: 48.1824736, lng: 19.3594372 },
    { lat: 48.1826776, lng: 19.3591791 },
    { lat: 48.1827676, lng: 19.3592429 },
    { lat: 48.1828349, lng: 19.3590952 },
    { lat: 48.182717, lng: 19.3587041 },
    { lat: 48.1827188, lng: 19.3582504 },
    { lat: 48.1828565, lng: 19.3573118 },
    { lat: 48.1820299, lng: 19.3572803 },
    { lat: 48.1817536, lng: 19.357182 },
    { lat: 48.1817762, lng: 19.3569486 },
    { lat: 48.1815377, lng: 19.3543706 },
    { lat: 48.1809282, lng: 19.3545416 },
    { lat: 48.1806781, lng: 19.3529205 },
    { lat: 48.1805709, lng: 19.3513891 },
    { lat: 48.1805816, lng: 19.3502041 },
    { lat: 48.1807618, lng: 19.3490017 },
    { lat: 48.1807808, lng: 19.3483647 },
    { lat: 48.181079, lng: 19.3475074 },
    { lat: 48.1809515, lng: 19.3459823 },
    { lat: 48.1810549, lng: 19.3451487 },
    { lat: 48.1810698, lng: 19.3446191 },
    { lat: 48.1810164, lng: 19.3441859 },
    { lat: 48.1801685, lng: 19.3426065 },
    { lat: 48.1789501, lng: 19.3408748 },
    { lat: 48.1779016, lng: 19.3395995 },
    { lat: 48.1757116, lng: 19.3415009 },
    { lat: 48.17368, lng: 19.343509 },
    { lat: 48.1735174, lng: 19.3439244 },
    { lat: 48.1731035, lng: 19.3461701 },
    { lat: 48.1729297, lng: 19.346895 },
    { lat: 48.1727369, lng: 19.3473595 },
    { lat: 48.1725417, lng: 19.3481357 },
    { lat: 48.1718125, lng: 19.350293 },
    { lat: 48.1713763, lng: 19.3513048 },
    { lat: 48.1708844, lng: 19.3522357 },
    { lat: 48.1707787, lng: 19.3525683 },
    { lat: 48.1707181, lng: 19.3529755 },
    { lat: 48.1700792, lng: 19.3530733 },
    { lat: 48.1697299, lng: 19.3532153 },
    { lat: 48.1697034, lng: 19.3534169 },
    { lat: 48.1695647, lng: 19.353579 },
    { lat: 48.1688573, lng: 19.3534945 },
    { lat: 48.1685774, lng: 19.3531107 },
    { lat: 48.1684602, lng: 19.3529206 },
  ],
  Kováčovce: [
    { lat: 48.1288917, lng: 19.4506826 },
    { lat: 48.1290434, lng: 19.4503121 },
    { lat: 48.1291224, lng: 19.4499334 },
    { lat: 48.1291834, lng: 19.4490739 },
    { lat: 48.1291426, lng: 19.4484736 },
    { lat: 48.1290349, lng: 19.448008 },
    { lat: 48.1290524, lng: 19.4474434 },
    { lat: 48.1296007, lng: 19.4454472 },
    { lat: 48.1298476, lng: 19.4447852 },
    { lat: 48.1302476, lng: 19.4440167 },
    { lat: 48.1297751, lng: 19.4433877 },
    { lat: 48.1289767, lng: 19.4424738 },
    { lat: 48.129093, lng: 19.4419998 },
    { lat: 48.1289441, lng: 19.4402071 },
    { lat: 48.128132, lng: 19.4396199 },
    { lat: 48.127782, lng: 19.4392243 },
    { lat: 48.1266552, lng: 19.4374625 },
    { lat: 48.1267737, lng: 19.4373033 },
    { lat: 48.1263238, lng: 19.4365087 },
    { lat: 48.1260792, lng: 19.4361739 },
    { lat: 48.1257563, lng: 19.4359723 },
    { lat: 48.1249314, lng: 19.4356833 },
    { lat: 48.1245064, lng: 19.435921 },
    { lat: 48.1240675, lng: 19.4356989 },
    { lat: 48.12385, lng: 19.4357687 },
    { lat: 48.1234931, lng: 19.4356579 },
    { lat: 48.1229034, lng: 19.4359892 },
    { lat: 48.1224769, lng: 19.4363837 },
    { lat: 48.1222905, lng: 19.4361078 },
    { lat: 48.1219977, lng: 19.4358801 },
    { lat: 48.121825, lng: 19.4360129 },
    { lat: 48.1211129, lng: 19.4369966 },
    { lat: 48.1206487, lng: 19.4371647 },
    { lat: 48.1205575, lng: 19.4370971 },
    { lat: 48.1204016, lng: 19.4367086 },
    { lat: 48.1202772, lng: 19.435865 },
    { lat: 48.1197573, lng: 19.4349738 },
    { lat: 48.1197098, lng: 19.4350173 },
    { lat: 48.1194567, lng: 19.43465 },
    { lat: 48.1190598, lng: 19.4342129 },
    { lat: 48.1186861, lng: 19.4339158 },
    { lat: 48.1163832, lng: 19.436363 },
    { lat: 48.1161631, lng: 19.4365234 },
    { lat: 48.1157169, lng: 19.4366229 },
    { lat: 48.1155575, lng: 19.4366129 },
    { lat: 48.1150135, lng: 19.4363546 },
    { lat: 48.1144814, lng: 19.4362107 },
    { lat: 48.1134585, lng: 19.4356009 },
    { lat: 48.1134622, lng: 19.4355091 },
    { lat: 48.1127638, lng: 19.4351624 },
    { lat: 48.1124189, lng: 19.4347752 },
    { lat: 48.1122822, lng: 19.4347977 },
    { lat: 48.1118139, lng: 19.4344271 },
    { lat: 48.1117105, lng: 19.4342634 },
    { lat: 48.110991, lng: 19.4340871 },
    { lat: 48.1105113, lng: 19.433741 },
    { lat: 48.1095327, lng: 19.4336132 },
    { lat: 48.1080411, lng: 19.4337944 },
    { lat: 48.106705, lng: 19.4338778 },
    { lat: 48.105986, lng: 19.4338306 },
    { lat: 48.1057546, lng: 19.4337524 },
    { lat: 48.1044453, lng: 19.4338329 },
    { lat: 48.1037776, lng: 19.4340116 },
    { lat: 48.1026664, lng: 19.4350141 },
    { lat: 48.1031384, lng: 19.4366748 },
    { lat: 48.1036902, lng: 19.4388856 },
    { lat: 48.1036447, lng: 19.4389236 },
    { lat: 48.1039876, lng: 19.4402185 },
    { lat: 48.1052599, lng: 19.4420997 },
    { lat: 48.1048171, lng: 19.4419601 },
    { lat: 48.1044108, lng: 19.4422572 },
    { lat: 48.1043688, lng: 19.4423487 },
    { lat: 48.1046045, lng: 19.442638 },
    { lat: 48.1041516, lng: 19.4431649 },
    { lat: 48.1041618, lng: 19.4434258 },
    { lat: 48.1039993, lng: 19.4433064 },
    { lat: 48.1037158, lng: 19.4434086 },
    { lat: 48.1033002, lng: 19.4432996 },
    { lat: 48.1028081, lng: 19.4435915 },
    { lat: 48.1022173, lng: 19.443683 },
    { lat: 48.1019688, lng: 19.4434824 },
    { lat: 48.1016373, lng: 19.4429041 },
    { lat: 48.10131, lng: 19.4426555 },
    { lat: 48.1013361, lng: 19.4427581 },
    { lat: 48.10127, lng: 19.442663 },
    { lat: 48.101276, lng: 19.442847 },
    { lat: 48.101277, lng: 19.442999 },
    { lat: 48.101242, lng: 19.443432 },
    { lat: 48.101167, lng: 19.443889 },
    { lat: 48.101142, lng: 19.444081 },
    { lat: 48.101109, lng: 19.444249 },
    { lat: 48.101091, lng: 19.444418 },
    { lat: 48.101059, lng: 19.444717 },
    { lat: 48.100977, lng: 19.44497 },
    { lat: 48.100862, lng: 19.445306 },
    { lat: 48.100826, lng: 19.44539 },
    { lat: 48.100659, lng: 19.445738 },
    { lat: 48.100551, lng: 19.446012 },
    { lat: 48.100505, lng: 19.446154 },
    { lat: 48.100453, lng: 19.44632 },
    { lat: 48.100406, lng: 19.446492 },
    { lat: 48.100332, lng: 19.446835 },
    { lat: 48.100309, lng: 19.447015 },
    { lat: 48.100243, lng: 19.447664 },
    { lat: 48.100237, lng: 19.447815 },
    { lat: 48.100232, lng: 19.447923 },
    { lat: 48.100227, lng: 19.448037 },
    { lat: 48.100208, lng: 19.4483 },
    { lat: 48.100185, lng: 19.448442 },
    { lat: 48.100187, lng: 19.448515 },
    { lat: 48.100211, lng: 19.448856 },
    { lat: 48.10023, lng: 19.449028 },
    { lat: 48.10027, lng: 19.44925 },
    { lat: 48.100381, lng: 19.449685 },
    { lat: 48.100513, lng: 19.450089 },
    { lat: 48.100679, lng: 19.450637 },
    { lat: 48.100834, lng: 19.451095 },
    { lat: 48.100946, lng: 19.451458 },
    { lat: 48.10104, lng: 19.451806 },
    { lat: 48.101127, lng: 19.452132 },
    { lat: 48.101197, lng: 19.452393 },
    { lat: 48.101242, lng: 19.452588 },
    { lat: 48.101272, lng: 19.452938 },
    { lat: 48.101287, lng: 19.453236 },
    { lat: 48.101282, lng: 19.453364 },
    { lat: 48.101281, lng: 19.453451 },
    { lat: 48.101331, lng: 19.453617 },
    { lat: 48.101381, lng: 19.45379 },
    { lat: 48.101418, lng: 19.453946 },
    { lat: 48.101436, lng: 19.454053 },
    { lat: 48.101429, lng: 19.454163 },
    { lat: 48.101372, lng: 19.454292 },
    { lat: 48.101308, lng: 19.454346 },
    { lat: 48.101156, lng: 19.454416 },
    { lat: 48.101033, lng: 19.454426 },
    { lat: 48.100912, lng: 19.454414 },
    { lat: 48.100838, lng: 19.454398 },
    { lat: 48.100618, lng: 19.454318 },
    { lat: 48.100508, lng: 19.454274 },
    { lat: 48.100279, lng: 19.454195 },
    { lat: 48.100064, lng: 19.454115 },
    { lat: 48.099788, lng: 19.45409 },
    { lat: 48.09959, lng: 19.454104 },
    { lat: 48.09941, lng: 19.454105 },
    { lat: 48.099286, lng: 19.454021 },
    { lat: 48.099155, lng: 19.454006 },
    { lat: 48.099044, lng: 19.454042 },
    { lat: 48.098778, lng: 19.454186 },
    { lat: 48.098646, lng: 19.454279 },
    { lat: 48.09852, lng: 19.454446 },
    { lat: 48.098418, lng: 19.454794 },
    { lat: 48.098386, lng: 19.455053 },
    { lat: 48.0984, lng: 19.455182 },
    { lat: 48.098434, lng: 19.455445 },
    { lat: 48.098488, lng: 19.455606 },
    { lat: 48.09863, lng: 19.455859 },
    { lat: 48.098751, lng: 19.456167 },
    { lat: 48.098797, lng: 19.456301 },
    { lat: 48.0988, lng: 19.456415 },
    { lat: 48.098799, lng: 19.456463 },
    { lat: 48.098714, lng: 19.456682 },
    { lat: 48.098567, lng: 19.456813 },
    { lat: 48.098483, lng: 19.456866 },
    { lat: 48.098247, lng: 19.456977 },
    { lat: 48.098112, lng: 19.457005 },
    { lat: 48.097929, lng: 19.456991 },
    { lat: 48.097823, lng: 19.456994 },
    { lat: 48.097618, lng: 19.456905 },
    { lat: 48.097468, lng: 19.456818 },
    { lat: 48.097248, lng: 19.456658 },
    { lat: 48.097068, lng: 19.456498 },
    { lat: 48.096841, lng: 19.456303 },
    { lat: 48.096704, lng: 19.45617 },
    { lat: 48.096659, lng: 19.456097 },
    { lat: 48.096615, lng: 19.455932 },
    { lat: 48.096585, lng: 19.455799 },
    { lat: 48.09655, lng: 19.455578 },
    { lat: 48.096551, lng: 19.455328 },
    { lat: 48.0966, lng: 19.45466 },
    { lat: 48.096603, lng: 19.454564 },
    { lat: 48.096631, lng: 19.454336 },
    { lat: 48.096678, lng: 19.454057 },
    { lat: 48.096708, lng: 19.453969 },
    { lat: 48.096797, lng: 19.45374 },
    { lat: 48.09697, lng: 19.453507 },
    { lat: 48.097102, lng: 19.453335 },
    { lat: 48.097172, lng: 19.453218 },
    { lat: 48.097207, lng: 19.453139 },
    { lat: 48.097264, lng: 19.452974 },
    { lat: 48.09728, lng: 19.452893 },
    { lat: 48.097281, lng: 19.452789 },
    { lat: 48.097282, lng: 19.452655 },
    { lat: 48.097259, lng: 19.452546 },
    { lat: 48.097223, lng: 19.452404 },
    { lat: 48.097184, lng: 19.452335 },
    { lat: 48.097105, lng: 19.452147 },
    { lat: 48.097079, lng: 19.452074 },
    { lat: 48.096993, lng: 19.45196 },
    { lat: 48.096908, lng: 19.451869 },
    { lat: 48.096842, lng: 19.451803 },
    { lat: 48.096672, lng: 19.451642 },
    { lat: 48.096521, lng: 19.451518 },
    { lat: 48.096339, lng: 19.451378 },
    { lat: 48.096236, lng: 19.451323 },
    { lat: 48.096027, lng: 19.451265 },
    { lat: 48.095834, lng: 19.451255 },
    { lat: 48.095603, lng: 19.451275 },
    { lat: 48.095365, lng: 19.451259 },
    { lat: 48.095194, lng: 19.451226 },
    { lat: 48.095087, lng: 19.45121 },
    { lat: 48.094887, lng: 19.451181 },
    { lat: 48.094712, lng: 19.451172 },
    { lat: 48.094563, lng: 19.451144 },
    { lat: 48.094438, lng: 19.451144 },
    { lat: 48.093908, lng: 19.451396 },
    { lat: 48.093806, lng: 19.451465 },
    { lat: 48.093681, lng: 19.451561 },
    { lat: 48.093613, lng: 19.451605 },
    { lat: 48.093427, lng: 19.451678 },
    { lat: 48.093331, lng: 19.451722 },
    { lat: 48.093245, lng: 19.451765 },
    { lat: 48.093097, lng: 19.451872 },
    { lat: 48.092876, lng: 19.451979 },
    { lat: 48.092719, lng: 19.452 },
    { lat: 48.092592, lng: 19.451988 },
    { lat: 48.092273, lng: 19.451916 },
    { lat: 48.091909, lng: 19.4518 },
    { lat: 48.091763, lng: 19.451778 },
    { lat: 48.09173, lng: 19.451796 },
    { lat: 48.091681, lng: 19.451869 },
    { lat: 48.091649, lng: 19.451928 },
    { lat: 48.091612, lng: 19.452031 },
    { lat: 48.091606, lng: 19.452074 },
    { lat: 48.091624, lng: 19.452307 },
    { lat: 48.091708, lng: 19.452687 },
    { lat: 48.09172, lng: 19.452768 },
    { lat: 48.091738, lng: 19.452926 },
    { lat: 48.091722, lng: 19.453091 },
    { lat: 48.091685, lng: 19.453208 },
    { lat: 48.091578, lng: 19.453395 },
    { lat: 48.091464, lng: 19.45355 },
    { lat: 48.091283, lng: 19.453709 },
    { lat: 48.091133, lng: 19.453816 },
    { lat: 48.090927, lng: 19.454022 },
    { lat: 48.090736, lng: 19.454221 },
    { lat: 48.090649, lng: 19.454303 },
    { lat: 48.090569, lng: 19.454352 },
    { lat: 48.090523, lng: 19.454369 },
    { lat: 48.090428, lng: 19.454443 },
    { lat: 48.090347, lng: 19.454535 },
    { lat: 48.090213, lng: 19.454668 },
    { lat: 48.090127, lng: 19.454774 },
    { lat: 48.090033, lng: 19.454865 },
    { lat: 48.089859, lng: 19.455033 },
    { lat: 48.089742, lng: 19.455153 },
    { lat: 48.089562, lng: 19.455329 },
    { lat: 48.089363, lng: 19.455543 },
    { lat: 48.089154, lng: 19.455688 },
    { lat: 48.088934, lng: 19.455768 },
    { lat: 48.088792, lng: 19.45578 },
    { lat: 48.088619, lng: 19.45577 },
    { lat: 48.088426, lng: 19.455795 },
    { lat: 48.088206, lng: 19.455772 },
    { lat: 48.088105, lng: 19.455691 },
    { lat: 48.087991, lng: 19.455603 },
    { lat: 48.087926, lng: 19.455592 },
    { lat: 48.08787, lng: 19.455614 },
    { lat: 48.087821, lng: 19.455668 },
    { lat: 48.087805, lng: 19.455699 },
    { lat: 48.087786, lng: 19.455786 },
    { lat: 48.087777, lng: 19.455869 },
    { lat: 48.087739, lng: 19.456089 },
    { lat: 48.087721, lng: 19.456235 },
    { lat: 48.087652, lng: 19.456629 },
    { lat: 48.087598, lng: 19.456865 },
    { lat: 48.08746, lng: 19.457376 },
    { lat: 48.087391, lng: 19.457771 },
    { lat: 48.087333, lng: 19.458243 },
    { lat: 48.087285, lng: 19.458723 },
    { lat: 48.087234, lng: 19.459082 },
    { lat: 48.087205, lng: 19.459402 },
    { lat: 48.087152, lng: 19.459677 },
    { lat: 48.08704, lng: 19.460101 },
    { lat: 48.086987, lng: 19.460323 },
    { lat: 48.086921, lng: 19.460559 },
    { lat: 48.086875, lng: 19.460646 },
    { lat: 48.086776, lng: 19.460805 },
    { lat: 48.086692, lng: 19.460913 },
    { lat: 48.08665, lng: 19.460957 },
    { lat: 48.086564, lng: 19.461039 },
    { lat: 48.086427, lng: 19.461073 },
    { lat: 48.08627, lng: 19.461058 },
    { lat: 48.086088, lng: 19.461026 },
    { lat: 48.085841, lng: 19.460948 },
    { lat: 48.085724, lng: 19.460881 },
    { lat: 48.085627, lng: 19.460806 },
    { lat: 48.085411, lng: 19.460638 },
    { lat: 48.085346, lng: 19.460622 },
    { lat: 48.085116, lng: 19.460515 },
    { lat: 48.084972, lng: 19.460483 },
    { lat: 48.084934, lng: 19.460491 },
    { lat: 48.084855, lng: 19.460491 },
    { lat: 48.084636, lng: 19.46053 },
    { lat: 48.084522, lng: 19.46059 },
    { lat: 48.08444, lng: 19.460633 },
    { lat: 48.084355, lng: 19.460694 },
    { lat: 48.084144, lng: 19.460701 },
    { lat: 48.083949, lng: 19.460699 },
    { lat: 48.083756, lng: 19.460794 },
    { lat: 48.083552, lng: 19.460866 },
    { lat: 48.083383, lng: 19.461083 },
    { lat: 48.083329, lng: 19.461156 },
    { lat: 48.083249, lng: 19.461264 },
    { lat: 48.083198, lng: 19.461304 },
    { lat: 48.08306, lng: 19.461655 },
    { lat: 48.08298, lng: 19.461919 },
    { lat: 48.082945, lng: 19.462042 },
    { lat: 48.082927, lng: 19.462426 },
    { lat: 48.082934, lng: 19.462459 },
    { lat: 48.082947, lng: 19.462631 },
    { lat: 48.083028, lng: 19.462976 },
    { lat: 48.083085, lng: 19.46315 },
    { lat: 48.0832, lng: 19.463422 },
    { lat: 48.083403, lng: 19.463782 },
    { lat: 48.083605, lng: 19.4642 },
    { lat: 48.083621, lng: 19.464249 },
    { lat: 48.083744, lng: 19.464613 },
    { lat: 48.083805, lng: 19.464922 },
    { lat: 48.083818, lng: 19.46502 },
    { lat: 48.083832, lng: 19.465455 },
    { lat: 48.083832, lng: 19.465781 },
    { lat: 48.08383, lng: 19.466142 },
    { lat: 48.083831, lng: 19.466424 },
    { lat: 48.083839, lng: 19.4667 },
    { lat: 48.08389, lng: 19.467042 },
    { lat: 48.083933, lng: 19.467354 },
    { lat: 48.084016, lng: 19.467655 },
    { lat: 48.084072, lng: 19.467825 },
    { lat: 48.084144, lng: 19.468014 },
    { lat: 48.084239, lng: 19.468218 },
    { lat: 48.084373, lng: 19.468467 },
    { lat: 48.084531, lng: 19.468643 },
    { lat: 48.084743, lng: 19.468845 },
    { lat: 48.084883, lng: 19.468971 },
    { lat: 48.085062, lng: 19.469129 },
    { lat: 48.085135, lng: 19.469174 },
    { lat: 48.085296, lng: 19.469215 },
    { lat: 48.085407, lng: 19.469211 },
    { lat: 48.085637, lng: 19.469166 },
    { lat: 48.085882, lng: 19.469087 },
    { lat: 48.085985, lng: 19.469032 },
    { lat: 48.086042, lng: 19.469003 },
    { lat: 48.086212, lng: 19.468889 },
    { lat: 48.086393, lng: 19.46877 },
    { lat: 48.086469, lng: 19.468712 },
    { lat: 48.086596, lng: 19.468589 },
    { lat: 48.08666, lng: 19.46853 },
    { lat: 48.086809, lng: 19.468355 },
    { lat: 48.086985, lng: 19.468163 },
    { lat: 48.087161, lng: 19.467955 },
    { lat: 48.087358, lng: 19.467735 },
    { lat: 48.087593, lng: 19.467486 },
    { lat: 48.087797, lng: 19.467337 },
    { lat: 48.087992, lng: 19.467214 },
    { lat: 48.088044, lng: 19.467196 },
    { lat: 48.088197, lng: 19.467122 },
    { lat: 48.088422, lng: 19.46709 },
    { lat: 48.088654, lng: 19.46709 },
    { lat: 48.088929, lng: 19.467162 },
    { lat: 48.089013, lng: 19.467203 },
    { lat: 48.089376, lng: 19.467339 },
    { lat: 48.089464, lng: 19.467389 },
    { lat: 48.089663, lng: 19.467501 },
    { lat: 48.089894, lng: 19.467628 },
    { lat: 48.089981, lng: 19.467674 },
    { lat: 48.090239, lng: 19.467768 },
    { lat: 48.090433, lng: 19.467872 },
    { lat: 48.090577, lng: 19.467915 },
    { lat: 48.090631, lng: 19.46793 },
    { lat: 48.090826, lng: 19.467916 },
    { lat: 48.09093, lng: 19.467914 },
    { lat: 48.091038, lng: 19.467912 },
    { lat: 48.091239, lng: 19.46792 },
    { lat: 48.091491, lng: 19.467979 },
    { lat: 48.091713, lng: 19.468045 },
    { lat: 48.091884, lng: 19.468162 },
    { lat: 48.092037, lng: 19.468313 },
    { lat: 48.092169, lng: 19.468498 },
    { lat: 48.092287, lng: 19.468712 },
    { lat: 48.092328, lng: 19.468783 },
    { lat: 48.092443, lng: 19.468983 },
    { lat: 48.092598, lng: 19.469246 },
    { lat: 48.092666, lng: 19.469351 },
    { lat: 48.092889, lng: 19.469647 },
    { lat: 48.092957, lng: 19.469741 },
    { lat: 48.093115, lng: 19.469965 },
    { lat: 48.09327, lng: 19.47018 },
    { lat: 48.093349, lng: 19.470277 },
    { lat: 48.093577, lng: 19.470476 },
    { lat: 48.093648, lng: 19.470552 },
    { lat: 48.093767, lng: 19.470773 },
    { lat: 48.093842, lng: 19.470844 },
    { lat: 48.093919, lng: 19.470908 },
    { lat: 48.09407, lng: 19.471052 },
    { lat: 48.094146, lng: 19.471121 },
    { lat: 48.094231, lng: 19.471208 },
    { lat: 48.094353, lng: 19.471368 },
    { lat: 48.094416, lng: 19.471466 },
    { lat: 48.094485, lng: 19.47157 },
    { lat: 48.094547, lng: 19.471711 },
    { lat: 48.094569, lng: 19.471772 },
    { lat: 48.094595, lng: 19.471843 },
    { lat: 48.094626, lng: 19.471953 },
    { lat: 48.094666, lng: 19.472154 },
    { lat: 48.094672, lng: 19.472233 },
    { lat: 48.094688, lng: 19.472389 },
    { lat: 48.0947, lng: 19.472762 },
    { lat: 48.094705, lng: 19.473082 },
    { lat: 48.094717, lng: 19.473413 },
    { lat: 48.094731, lng: 19.47357 },
    { lat: 48.094761, lng: 19.473796 },
    { lat: 48.094774, lng: 19.473937 },
    { lat: 48.094815, lng: 19.474242 },
    { lat: 48.094864, lng: 19.474621 },
    { lat: 48.094867, lng: 19.474761 },
    { lat: 48.094868, lng: 19.474856 },
    { lat: 48.094867, lng: 19.475033 },
    { lat: 48.094873, lng: 19.475343 },
    { lat: 48.094884, lng: 19.475476 },
    { lat: 48.094886, lng: 19.475622 },
    { lat: 48.094902, lng: 19.475837 },
    { lat: 48.094913, lng: 19.476019 },
    { lat: 48.094912, lng: 19.476102 },
    { lat: 48.094906, lng: 19.476393 },
    { lat: 48.094921, lng: 19.476526 },
    { lat: 48.094917, lng: 19.47662 },
    { lat: 48.094913, lng: 19.476802 },
    { lat: 48.094909, lng: 19.476954 },
    { lat: 48.094904, lng: 19.477092 },
    { lat: 48.094898, lng: 19.477321 },
    { lat: 48.094869, lng: 19.477646 },
    { lat: 48.094836, lng: 19.477985 },
    { lat: 48.09483, lng: 19.47817 },
    { lat: 48.094835, lng: 19.478458 },
    { lat: 48.094837, lng: 19.478566 },
    { lat: 48.094838, lng: 19.478749 },
    { lat: 48.094863, lng: 19.478893 },
    { lat: 48.094887, lng: 19.479003 },
    { lat: 48.094946, lng: 19.479253 },
    { lat: 48.095057, lng: 19.479499 },
    { lat: 48.095105, lng: 19.479612 },
    { lat: 48.095186, lng: 19.479764 },
    { lat: 48.095259, lng: 19.479901 },
    { lat: 48.095416, lng: 19.480174 },
    { lat: 48.095534, lng: 19.480353 },
    { lat: 48.095656, lng: 19.480525 },
    { lat: 48.095792, lng: 19.480717 },
    { lat: 48.095931, lng: 19.480891 },
    { lat: 48.096007, lng: 19.480973 },
    { lat: 48.096099, lng: 19.481067 },
    { lat: 48.096178, lng: 19.481143 },
    { lat: 48.096216, lng: 19.481182 },
    { lat: 48.096384, lng: 19.481345 },
    { lat: 48.096552, lng: 19.481488 },
    { lat: 48.096704, lng: 19.481624 },
    { lat: 48.096872, lng: 19.481783 },
    { lat: 48.097048, lng: 19.481945 },
    { lat: 48.097191, lng: 19.482094 },
    { lat: 48.097429, lng: 19.482302 },
    { lat: 48.09752, lng: 19.482382 },
    { lat: 48.097566, lng: 19.482429 },
    { lat: 48.097661, lng: 19.482519 },
    { lat: 48.097771, lng: 19.482623 },
    { lat: 48.097935, lng: 19.482788 },
    { lat: 48.098145, lng: 19.483028 },
    { lat: 48.098225, lng: 19.483136 },
    { lat: 48.098388, lng: 19.4834 },
    { lat: 48.09846, lng: 19.483523 },
    { lat: 48.098611, lng: 19.483798 },
    { lat: 48.098616, lng: 19.483829 },
    { lat: 48.098704, lng: 19.484089 },
    { lat: 48.098778, lng: 19.484324 },
    { lat: 48.098849, lng: 19.484578 },
    { lat: 48.098909, lng: 19.48481 },
    { lat: 48.098941, lng: 19.485017 },
    { lat: 48.098963, lng: 19.485153 },
    { lat: 48.099019, lng: 19.485498 },
    { lat: 48.099049, lng: 19.485736 },
    { lat: 48.099087, lng: 19.486007 },
    { lat: 48.099145, lng: 19.486219 },
    { lat: 48.099156, lng: 19.486256 },
    { lat: 48.099205, lng: 19.486461 },
    { lat: 48.09932, lng: 19.486772 },
    { lat: 48.099411, lng: 19.486975 },
    { lat: 48.099541, lng: 19.487216 },
    { lat: 48.099609, lng: 19.48729 },
    { lat: 48.099776, lng: 19.487398 },
    { lat: 48.099947, lng: 19.487489 },
    { lat: 48.100073, lng: 19.487516 },
    { lat: 48.100263, lng: 19.487543 },
    { lat: 48.100433, lng: 19.487551 },
    { lat: 48.100587, lng: 19.487563 },
    { lat: 48.100659, lng: 19.487568 },
    { lat: 48.100755, lng: 19.487543 },
    { lat: 48.100853, lng: 19.487521 },
    { lat: 48.101035, lng: 19.487325 },
    { lat: 48.101145, lng: 19.4872 },
    { lat: 48.101295, lng: 19.487035 },
    { lat: 48.101448, lng: 19.486853 },
    { lat: 48.101581, lng: 19.486692 },
    { lat: 48.101731, lng: 19.486483 },
    { lat: 48.101846, lng: 19.486319 },
    { lat: 48.101992, lng: 19.486126 },
    { lat: 48.102134, lng: 19.485934 },
    { lat: 48.102284, lng: 19.485732 },
    { lat: 48.102406, lng: 19.485583 },
    { lat: 48.102514, lng: 19.485442 },
    { lat: 48.102706, lng: 19.485174 },
    { lat: 48.102817, lng: 19.485031 },
    { lat: 48.102968, lng: 19.484833 },
    { lat: 48.103109, lng: 19.484695 },
    { lat: 48.103286, lng: 19.484548 },
    { lat: 48.103472, lng: 19.484455 },
    { lat: 48.103652, lng: 19.484351 },
    { lat: 48.103885, lng: 19.484255 },
    { lat: 48.104059, lng: 19.484228 },
    { lat: 48.104279, lng: 19.484204 },
    { lat: 48.104485, lng: 19.48425 },
    { lat: 48.104651, lng: 19.484328 },
    { lat: 48.104713, lng: 19.484364 },
    { lat: 48.104814, lng: 19.484423 },
    { lat: 48.105004, lng: 19.484514 },
    { lat: 48.105193, lng: 19.484642 },
    { lat: 48.105327, lng: 19.484764 },
    { lat: 48.105355, lng: 19.484794 },
    { lat: 48.105383, lng: 19.484831 },
    { lat: 48.105479, lng: 19.484959 },
    { lat: 48.105507, lng: 19.485026 },
    { lat: 48.105542, lng: 19.485104 },
    { lat: 48.105607, lng: 19.48529 },
    { lat: 48.105705, lng: 19.485566 },
    { lat: 48.105787, lng: 19.485829 },
    { lat: 48.105848, lng: 19.486083 },
    { lat: 48.105934, lng: 19.486422 },
    { lat: 48.105968, lng: 19.486572 },
    { lat: 48.106071, lng: 19.486888 },
    { lat: 48.106174, lng: 19.487164 },
    { lat: 48.106255, lng: 19.487378 },
    { lat: 48.10642, lng: 19.48766 },
    { lat: 48.106495, lng: 19.487782 },
    { lat: 48.10664, lng: 19.487996 },
    { lat: 48.106736, lng: 19.48809 },
    { lat: 48.106875, lng: 19.488182 },
    { lat: 48.106962, lng: 19.48825 },
    { lat: 48.107168, lng: 19.488422 },
    { lat: 48.107318, lng: 19.488569 },
    { lat: 48.107363, lng: 19.488627 },
    { lat: 48.107504, lng: 19.488831 },
    { lat: 48.107626, lng: 19.489021 },
    { lat: 48.107739, lng: 19.489253 },
    { lat: 48.107855, lng: 19.489512 },
    { lat: 48.107997, lng: 19.489867 },
    { lat: 48.108057, lng: 19.490052 },
    { lat: 48.108138, lng: 19.490286 },
    { lat: 48.108198, lng: 19.490503 },
    { lat: 48.108214, lng: 19.49059 },
    { lat: 48.108289, lng: 19.490912 },
    { lat: 48.108315, lng: 19.491058 },
    { lat: 48.108326, lng: 19.491144 },
    { lat: 48.108353, lng: 19.491403 },
    { lat: 48.108367, lng: 19.491764 },
    { lat: 48.108379, lng: 19.492023 },
    { lat: 48.1084, lng: 19.492322 },
    { lat: 48.108447, lng: 19.492633 },
    { lat: 48.108497, lng: 19.492876 },
    { lat: 48.108618, lng: 19.493151 },
    { lat: 48.108692, lng: 19.493323 },
    { lat: 48.108812, lng: 19.493567 },
    { lat: 48.108923, lng: 19.493765 },
    { lat: 48.109007, lng: 19.493899 },
    { lat: 48.109134, lng: 19.493986 },
    { lat: 48.109368, lng: 19.494105 },
    { lat: 48.109577, lng: 19.494246 },
    { lat: 48.109714, lng: 19.494324 },
    { lat: 48.109748, lng: 19.494341 },
    { lat: 48.109825, lng: 19.494379 },
    { lat: 48.109906, lng: 19.49442 },
    { lat: 48.110077, lng: 19.494493 },
    { lat: 48.110239, lng: 19.494506 },
    { lat: 48.110446, lng: 19.494507 },
    { lat: 48.110519, lng: 19.494507 },
    { lat: 48.110643, lng: 19.494502 },
    { lat: 48.110755, lng: 19.494496 },
    { lat: 48.110804, lng: 19.494477 },
    { lat: 48.110871, lng: 19.49447 },
    { lat: 48.1109726, lng: 19.494401 },
    { lat: 48.111176, lng: 19.494291 },
    { lat: 48.11121, lng: 19.494272 },
    { lat: 48.11141, lng: 19.494193 },
    { lat: 48.111592, lng: 19.49424 },
    { lat: 48.111721, lng: 19.49422 },
    { lat: 48.111817, lng: 19.494081 },
    { lat: 48.111895, lng: 19.493859 },
    { lat: 48.111876, lng: 19.493358 },
    { lat: 48.11177, lng: 19.492823 },
    { lat: 48.111728, lng: 19.492488 },
    { lat: 48.111663, lng: 19.492324 },
    { lat: 48.111677, lng: 19.492004 },
    { lat: 48.111834, lng: 19.491439 },
    { lat: 48.111932, lng: 19.49124 },
    { lat: 48.1120534, lng: 19.4911094 },
    { lat: 48.1120321, lng: 19.490965 },
    { lat: 48.1123315, lng: 19.4879723 },
    { lat: 48.1125898, lng: 19.4861948 },
    { lat: 48.1126218, lng: 19.4842808 },
    { lat: 48.1122875, lng: 19.4826509 },
    { lat: 48.1120594, lng: 19.4818238 },
    { lat: 48.1117883, lng: 19.480055 },
    { lat: 48.1117085, lng: 19.479819 },
    { lat: 48.1124916, lng: 19.4792646 },
    { lat: 48.1136748, lng: 19.4789487 },
    { lat: 48.1156617, lng: 19.4757273 },
    { lat: 48.117247, lng: 19.4741616 },
    { lat: 48.1182152, lng: 19.4734396 },
    { lat: 48.1188481, lng: 19.473118 },
    { lat: 48.1192395, lng: 19.4726717 },
    { lat: 48.1194615, lng: 19.4721889 },
    { lat: 48.1197779, lng: 19.4712469 },
    { lat: 48.1199385, lng: 19.4696277 },
    { lat: 48.1198604, lng: 19.4689005 },
    { lat: 48.1198135, lng: 19.4676615 },
    { lat: 48.1202092, lng: 19.4678337 },
    { lat: 48.1205843, lng: 19.4677556 },
    { lat: 48.1206296, lng: 19.4676398 },
    { lat: 48.1205331, lng: 19.4657301 },
    { lat: 48.120389, lng: 19.4645594 },
    { lat: 48.1203353, lng: 19.4632508 },
    { lat: 48.1205019, lng: 19.4619527 },
    { lat: 48.1206742, lng: 19.4600088 },
    { lat: 48.1209046, lng: 19.4594439 },
    { lat: 48.1212839, lng: 19.4582359 },
    { lat: 48.1215299, lng: 19.4576912 },
    { lat: 48.122204, lng: 19.4564981 },
    { lat: 48.1233609, lng: 19.4550231 },
    { lat: 48.1234478, lng: 19.4549569 },
    { lat: 48.1235954, lng: 19.4549569 },
    { lat: 48.1236013, lng: 19.4548173 },
    { lat: 48.1241216, lng: 19.4548586 },
    { lat: 48.1244013, lng: 19.4547435 },
    { lat: 48.124824, lng: 19.4541918 },
    { lat: 48.1258072, lng: 19.4544628 },
    { lat: 48.1266064, lng: 19.4539421 },
    { lat: 48.1269254, lng: 19.4536539 },
    { lat: 48.1272385, lng: 19.4531973 },
    { lat: 48.1275767, lng: 19.4525504 },
    { lat: 48.1284655, lng: 19.4513497 },
    { lat: 48.1288348, lng: 19.450673 },
    { lat: 48.1288917, lng: 19.4506826 },
  ],
  VeľkýKrtíš: [
    { lat: 48.1923307, lng: 19.3337971 },
    { lat: 48.1922936, lng: 19.3338474 },
    { lat: 48.1918628, lng: 19.3355081 },
    { lat: 48.1916258, lng: 19.3361094 },
    { lat: 48.1907933, lng: 19.3376193 },
    { lat: 48.1905833, lng: 19.3387884 },
    { lat: 48.1904227, lng: 19.3411186 },
    { lat: 48.1902462, lng: 19.3418582 },
    { lat: 48.1902457, lng: 19.3424577 },
    { lat: 48.1908547, lng: 19.3457963 },
    { lat: 48.1910925, lng: 19.3477938 },
    { lat: 48.1909765, lng: 19.3480772 },
    { lat: 48.1912951, lng: 19.3501355 },
    { lat: 48.1917554, lng: 19.3499088 },
    { lat: 48.1918449, lng: 19.349943 },
    { lat: 48.191884, lng: 19.3502405 },
    { lat: 48.1917199, lng: 19.3504637 },
    { lat: 48.1914953, lng: 19.3505527 },
    { lat: 48.1912492, lng: 19.3504732 },
    { lat: 48.1910885, lng: 19.3508571 },
    { lat: 48.1914175, lng: 19.3512298 },
    { lat: 48.1914384, lng: 19.351479 },
    { lat: 48.1917387, lng: 19.3518908 },
    { lat: 48.1926622, lng: 19.3527229 },
    { lat: 48.1928677, lng: 19.353034 },
    { lat: 48.1929819, lng: 19.3533711 },
    { lat: 48.1929879, lng: 19.3535648 },
    { lat: 48.1922638, lng: 19.3536509 },
    { lat: 48.1923359, lng: 19.3540254 },
    { lat: 48.1925624, lng: 19.3544002 },
    { lat: 48.1934696, lng: 19.3551748 },
    { lat: 48.1949167, lng: 19.3559046 },
    { lat: 48.1952881, lng: 19.3561979 },
    { lat: 48.1953872, lng: 19.3563579 },
    { lat: 48.1955363, lng: 19.356744 },
    { lat: 48.1956118, lng: 19.357235 },
    { lat: 48.1956548, lng: 19.3581083 },
    { lat: 48.1956949, lng: 19.35824 },
    { lat: 48.1960204, lng: 19.3585414 },
    { lat: 48.1961316, lng: 19.3588616 },
    { lat: 48.1960966, lng: 19.3593094 },
    { lat: 48.1961298, lng: 19.3596976 },
    { lat: 48.1961126, lng: 19.3606801 },
    { lat: 48.1963669, lng: 19.3613799 },
    { lat: 48.1963481, lng: 19.3624223 },
    { lat: 48.1964655, lng: 19.362729 },
    { lat: 48.1965298, lng: 19.36318 },
    { lat: 48.1965026, lng: 19.3636739 },
    { lat: 48.1963466, lng: 19.3642541 },
    { lat: 48.1963713, lng: 19.3643889 },
    { lat: 48.1966092, lng: 19.3648407 },
    { lat: 48.1967188, lng: 19.3648455 },
    { lat: 48.1972915, lng: 19.3639308 },
    { lat: 48.1974744, lng: 19.3638197 },
    { lat: 48.1996479, lng: 19.363709 },
    { lat: 48.2021869, lng: 19.3649292 },
    { lat: 48.2018677, lng: 19.3684575 },
    { lat: 48.2011587, lng: 19.3699458 },
    { lat: 48.2005952, lng: 19.3709854 },
    { lat: 48.1992324, lng: 19.3732451 },
    { lat: 48.1990819, lng: 19.3737981 },
    { lat: 48.1997669, lng: 19.3753038 },
    { lat: 48.199633, lng: 19.3759236 },
    { lat: 48.1999481, lng: 19.3762206 },
    { lat: 48.2001561, lng: 19.3767788 },
    { lat: 48.2001758, lng: 19.3773335 },
    { lat: 48.2003902, lng: 19.3776198 },
    { lat: 48.2002301, lng: 19.3782604 },
    { lat: 48.2003791, lng: 19.3788529 },
    { lat: 48.2006934, lng: 19.378991 },
    { lat: 48.2022278, lng: 19.379543 },
    { lat: 48.2034096, lng: 19.3808361 },
    { lat: 48.2046081, lng: 19.3809817 },
    { lat: 48.2051208, lng: 19.3808175 },
    { lat: 48.2057489, lng: 19.3808685 },
    { lat: 48.2072812, lng: 19.3814283 },
    { lat: 48.2088898, lng: 19.3818782 },
    { lat: 48.2095831, lng: 19.3822131 },
    { lat: 48.210286, lng: 19.3824158 },
    { lat: 48.2107425, lng: 19.3826777 },
    { lat: 48.2108809, lng: 19.3822022 },
    { lat: 48.2139209, lng: 19.3839098 },
    { lat: 48.2139915, lng: 19.3838051 },
    { lat: 48.2141535, lng: 19.3835383 },
    { lat: 48.2141468, lng: 19.3833962 },
    { lat: 48.214324, lng: 19.382977 },
    { lat: 48.2144224, lng: 19.3829163 },
    { lat: 48.2145117, lng: 19.3827315 },
    { lat: 48.2145389, lng: 19.3823572 },
    { lat: 48.2147845, lng: 19.3822373 },
    { lat: 48.2148783, lng: 19.3820503 },
    { lat: 48.2148805, lng: 19.3816253 },
    { lat: 48.2149877, lng: 19.3818375 },
    { lat: 48.2150188, lng: 19.3808603 },
    { lat: 48.2157711, lng: 19.3806574 },
    { lat: 48.2162882, lng: 19.3803735 },
    { lat: 48.2163769, lng: 19.3804598 },
    { lat: 48.2165836, lng: 19.3803257 },
    { lat: 48.2170499, lng: 19.3797731 },
    { lat: 48.2173913, lng: 19.379132 },
    { lat: 48.2178355, lng: 19.37857 },
    { lat: 48.2181962, lng: 19.3784046 },
    { lat: 48.2184155, lng: 19.3781578 },
    { lat: 48.2185126, lng: 19.3779513 },
    { lat: 48.2188143, lng: 19.3777749 },
    { lat: 48.2193057, lng: 19.3771386 },
    { lat: 48.2195883, lng: 19.3771763 },
    { lat: 48.2201217, lng: 19.3766443 },
    { lat: 48.2205834, lng: 19.3763956 },
    { lat: 48.220944, lng: 19.3759436 },
    { lat: 48.2211203, lng: 19.37546 },
    { lat: 48.2214854, lng: 19.375104 },
    { lat: 48.2216537, lng: 19.3751122 },
    { lat: 48.2219983, lng: 19.3746325 },
    { lat: 48.2225957, lng: 19.3740636 },
    { lat: 48.2226899, lng: 19.3739143 },
    { lat: 48.2226777, lng: 19.373754 },
    { lat: 48.2228627, lng: 19.3734042 },
    { lat: 48.2229425, lng: 19.3727852 },
    { lat: 48.2232107, lng: 19.3727831 },
    { lat: 48.2234446, lng: 19.3724184 },
    { lat: 48.2234674, lng: 19.3722827 },
    { lat: 48.2235605, lng: 19.3721303 },
    { lat: 48.2236482, lng: 19.3722192 },
    { lat: 48.2238686, lng: 19.3719486 },
    { lat: 48.2240153, lng: 19.3716915 },
    { lat: 48.2241913, lng: 19.3712036 },
    { lat: 48.2245402, lng: 19.3708376 },
    { lat: 48.224737, lng: 19.3703108 },
    { lat: 48.2247827, lng: 19.3699673 },
    { lat: 48.2248613, lng: 19.3697479 },
    { lat: 48.2252914, lng: 19.3689822 },
    { lat: 48.2250906, lng: 19.3662658 },
    { lat: 48.2260153, lng: 19.3631189 },
    { lat: 48.2267673, lng: 19.3611886 },
    { lat: 48.2286943, lng: 19.3601207 },
    { lat: 48.2306957, lng: 19.3585354 },
    { lat: 48.230729, lng: 19.3583586 },
    { lat: 48.2310522, lng: 19.3571305 },
    { lat: 48.2311488, lng: 19.3565828 },
    { lat: 48.2310864, lng: 19.3558903 },
    { lat: 48.2309043, lng: 19.3547224 },
    { lat: 48.2312195, lng: 19.3525944 },
    { lat: 48.2314102, lng: 19.3518471 },
    { lat: 48.2315173, lng: 19.3517299 },
    { lat: 48.2315211, lng: 19.3516228 },
    { lat: 48.2316596, lng: 19.3513131 },
    { lat: 48.2320666, lng: 19.3491016 },
    { lat: 48.231981, lng: 19.3488478 },
    { lat: 48.2320608, lng: 19.3486565 },
    { lat: 48.2320194, lng: 19.3482597 },
    { lat: 48.2320856, lng: 19.3479454 },
    { lat: 48.2319116, lng: 19.346859 },
    { lat: 48.2319537, lng: 19.3465589 },
    { lat: 48.2318625, lng: 19.3463272 },
    { lat: 48.2319393, lng: 19.3460478 },
    { lat: 48.2318979, lng: 19.3458186 },
    { lat: 48.2319666, lng: 19.3456575 },
    { lat: 48.2318778, lng: 19.3454701 },
    { lat: 48.2318915, lng: 19.3446082 },
    { lat: 48.2318066, lng: 19.3444575 },
    { lat: 48.2318512, lng: 19.3442356 },
    { lat: 48.2315855, lng: 19.3436798 },
    { lat: 48.2312809, lng: 19.3437214 },
    { lat: 48.2311373, lng: 19.3431946 },
    { lat: 48.2309465, lng: 19.3430475 },
    { lat: 48.2308687, lng: 19.3426709 },
    { lat: 48.2306471, lng: 19.3421139 },
    { lat: 48.2305444, lng: 19.3419708 },
    { lat: 48.2304113, lng: 19.3419006 },
    { lat: 48.2300509, lng: 19.341295 },
    { lat: 48.2295619, lng: 19.3409011 },
    { lat: 48.2288124, lng: 19.3401697 },
    { lat: 48.2285963, lng: 19.3398016 },
    { lat: 48.2285806, lng: 19.3394986 },
    { lat: 48.2281342, lng: 19.339332 },
    { lat: 48.2275851, lng: 19.338886 },
    { lat: 48.2272525, lng: 19.3388355 },
    { lat: 48.2271608, lng: 19.3386863 },
    { lat: 48.2270188, lng: 19.338662 },
    { lat: 48.2268814, lng: 19.3382193 },
    { lat: 48.226811, lng: 19.3377377 },
    { lat: 48.2268198, lng: 19.3368187 },
    { lat: 48.2267658, lng: 19.3361186 },
    { lat: 48.226543, lng: 19.3361027 },
    { lat: 48.2265055, lng: 19.3359551 },
    { lat: 48.2269518, lng: 19.3356006 },
    { lat: 48.2268523, lng: 19.3355247 },
    { lat: 48.227189, lng: 19.3353067 },
    { lat: 48.2269033, lng: 19.3344969 },
    { lat: 48.2268473, lng: 19.3338278 },
    { lat: 48.2281121, lng: 19.3332433 },
    { lat: 48.2263275, lng: 19.3248741 },
    { lat: 48.2265515, lng: 19.3241893 },
    { lat: 48.2268286, lng: 19.3227334 },
    { lat: 48.2280657, lng: 19.3155169 },
    { lat: 48.2283323, lng: 19.3148065 },
    { lat: 48.2282221, lng: 19.3148573 },
    { lat: 48.2277336, lng: 19.3150819 },
    { lat: 48.2262363, lng: 19.3152125 },
    { lat: 48.2260008, lng: 19.3153111 },
    { lat: 48.2248097, lng: 19.3160846 },
    { lat: 48.2239572, lng: 19.3164506 },
    { lat: 48.2235389, lng: 19.3167294 },
    { lat: 48.2229252, lng: 19.3168374 },
    { lat: 48.2225425, lng: 19.3171039 },
    { lat: 48.22093, lng: 19.3179086 },
    { lat: 48.2203106, lng: 19.318132 },
    { lat: 48.2197927, lng: 19.3180497 },
    { lat: 48.2195952, lng: 19.3181852 },
    { lat: 48.2172759, lng: 19.3191743 },
    { lat: 48.2156354, lng: 19.320008 },
    { lat: 48.2151422, lng: 19.3192201 },
    { lat: 48.214887, lng: 19.3192618 },
    { lat: 48.2138863, lng: 19.3191941 },
    { lat: 48.2137541, lng: 19.3195388 },
    { lat: 48.2136238, lng: 19.3196621 },
    { lat: 48.2133408, lng: 19.3201524 },
    { lat: 48.2131615, lng: 19.3202933 },
    { lat: 48.2129341, lng: 19.3206041 },
    { lat: 48.2127588, lng: 19.3209156 },
    { lat: 48.2124166, lng: 19.321113 },
    { lat: 48.2123618, lng: 19.3210535 },
    { lat: 48.2122263, lng: 19.3211194 },
    { lat: 48.2119774, lng: 19.3213063 },
    { lat: 48.2114856, lng: 19.3221432 },
    { lat: 48.2113822, lng: 19.3224056 },
    { lat: 48.2110755, lng: 19.3226627 },
    { lat: 48.2108141, lng: 19.3231528 },
    { lat: 48.2093946, lng: 19.3244335 },
    { lat: 48.2057668, lng: 19.3279093 },
    { lat: 48.1971861, lng: 19.3336065 },
    { lat: 48.1960656, lng: 19.3342848 },
    { lat: 48.1952272, lng: 19.3348714 },
    { lat: 48.1947721, lng: 19.3351111 },
    { lat: 48.1941535, lng: 19.3348131 },
    { lat: 48.1927514, lng: 19.3339469 },
    { lat: 48.1923082, lng: 19.3337275 },
    { lat: 48.1923307, lng: 19.3337971 },
  ],
  Senné: [
    { lat: 48.322234, lng: 19.4348492 },
    { lat: 48.3222475, lng: 19.4348453 },
    { lat: 48.3225859, lng: 19.4346583 },
    { lat: 48.3233547, lng: 19.4344487 },
    { lat: 48.3239072, lng: 19.4343824 },
    { lat: 48.3242212, lng: 19.4342546 },
    { lat: 48.3249046, lng: 19.4339011 },
    { lat: 48.3254186, lng: 19.4335485 },
    { lat: 48.3262085, lng: 19.4332307 },
    { lat: 48.328683, lng: 19.4330791 },
    { lat: 48.330304, lng: 19.4327257 },
    { lat: 48.330695, lng: 19.4325324 },
    { lat: 48.331805, lng: 19.4323882 },
    { lat: 48.3321211, lng: 19.4326356 },
    { lat: 48.3341349, lng: 19.4328714 },
    { lat: 48.3356683, lng: 19.432721 },
    { lat: 48.3363847, lng: 19.4325675 },
    { lat: 48.3374609, lng: 19.4326877 },
    { lat: 48.338087, lng: 19.4326018 },
    { lat: 48.3391931, lng: 19.4323235 },
    { lat: 48.3395998, lng: 19.4323194 },
    { lat: 48.3403543, lng: 19.4325742 },
    { lat: 48.3406394, lng: 19.4325946 },
    { lat: 48.3408626, lng: 19.4325696 },
    { lat: 48.3417001, lng: 19.4322162 },
    { lat: 48.342648, lng: 19.431456 },
    { lat: 48.342687, lng: 19.431447 },
    { lat: 48.343524, lng: 19.431203 },
    { lat: 48.34376, lng: 19.431252 },
    { lat: 48.344123, lng: 19.431288 },
    { lat: 48.344756, lng: 19.430979 },
    { lat: 48.345606, lng: 19.430673 },
    { lat: 48.346288, lng: 19.43003 },
    { lat: 48.346367, lng: 19.430001 },
    { lat: 48.34695, lng: 19.429804 },
    { lat: 48.346992, lng: 19.429762 },
    { lat: 48.347013, lng: 19.42974 },
    { lat: 48.347061, lng: 19.429693 },
    { lat: 48.3469302, lng: 19.4273017 },
    { lat: 48.3465693, lng: 19.4274162 },
    { lat: 48.3463568, lng: 19.4269717 },
    { lat: 48.3466002, lng: 19.426468 },
    { lat: 48.3466727, lng: 19.4259508 },
    { lat: 48.3466366, lng: 19.4256418 },
    { lat: 48.3461239, lng: 19.4256927 },
    { lat: 48.3455534, lng: 19.4255885 },
    { lat: 48.3455588, lng: 19.4248418 },
    { lat: 48.3452298, lng: 19.4236991 },
    { lat: 48.3453451, lng: 19.4229652 },
    { lat: 48.3452878, lng: 19.4227062 },
    { lat: 48.344989, lng: 19.4227256 },
    { lat: 48.3443581, lng: 19.4219688 },
    { lat: 48.3441718, lng: 19.4216028 },
    { lat: 48.3437859, lng: 19.4211624 },
    { lat: 48.3437832, lng: 19.4206295 },
    { lat: 48.3436974, lng: 19.4202939 },
    { lat: 48.3435908, lng: 19.4201319 },
    { lat: 48.343626, lng: 19.4198526 },
    { lat: 48.3433654, lng: 19.4192738 },
    { lat: 48.3433085, lng: 19.418765 },
    { lat: 48.3434132, lng: 19.4183054 },
    { lat: 48.3433394, lng: 19.417722 },
    { lat: 48.3433647, lng: 19.4171585 },
    { lat: 48.3434933, lng: 19.4168253 },
    { lat: 48.343464, lng: 19.4162998 },
    { lat: 48.3434986, lng: 19.415939 },
    { lat: 48.3434695, lng: 19.415251 },
    { lat: 48.3433749, lng: 19.4148242 },
    { lat: 48.3435489, lng: 19.414043 },
    { lat: 48.3434818, lng: 19.4134121 },
    { lat: 48.3435611, lng: 19.4124177 },
    { lat: 48.343489, lng: 19.4124402 },
    { lat: 48.3433971, lng: 19.4112614 },
    { lat: 48.3431361, lng: 19.4111758 },
    { lat: 48.3429767, lng: 19.4106925 },
    { lat: 48.342883, lng: 19.4106869 },
    { lat: 48.3428502, lng: 19.4103234 },
    { lat: 48.3428802, lng: 19.4100535 },
    { lat: 48.3429429, lng: 19.4098059 },
    { lat: 48.3431008, lng: 19.4096208 },
    { lat: 48.3431647, lng: 19.4093285 },
    { lat: 48.3430855, lng: 19.4088165 },
    { lat: 48.3428874, lng: 19.4084184 },
    { lat: 48.3423726, lng: 19.4079496 },
    { lat: 48.3418062, lng: 19.4082193 },
    { lat: 48.3417142, lng: 19.4074423 },
    { lat: 48.3417763, lng: 19.4072578 },
    { lat: 48.3419151, lng: 19.4071162 },
    { lat: 48.3422515, lng: 19.4065341 },
    { lat: 48.3423613, lng: 19.4054214 },
    { lat: 48.3423772, lng: 19.4047597 },
    { lat: 48.342647, lng: 19.403649 },
    { lat: 48.3428979, lng: 19.4018796 },
    { lat: 48.3428797, lng: 19.4016403 },
    { lat: 48.3424419, lng: 19.4003889 },
    { lat: 48.3423887, lng: 19.4003376 },
    { lat: 48.3424186, lng: 19.4000612 },
    { lat: 48.3422842, lng: 19.3995835 },
    { lat: 48.3420234, lng: 19.3991228 },
    { lat: 48.3418582, lng: 19.3986076 },
    { lat: 48.341793, lng: 19.398538 },
    { lat: 48.3417935, lng: 19.397456 },
    { lat: 48.3418592, lng: 19.3968457 },
    { lat: 48.3421006, lng: 19.3955889 },
    { lat: 48.3422501, lng: 19.3953458 },
    { lat: 48.3421376, lng: 19.3949664 },
    { lat: 48.3422701, lng: 19.3933864 },
    { lat: 48.3422328, lng: 19.3924115 },
    { lat: 48.3422973, lng: 19.3917758 },
    { lat: 48.3427075, lng: 19.3896142 },
    { lat: 48.3439621, lng: 19.3896895 },
    { lat: 48.3448053, lng: 19.3898092 },
    { lat: 48.3448095, lng: 19.3897344 },
    { lat: 48.3447956, lng: 19.3896062 },
    { lat: 48.3446637, lng: 19.389678 },
    { lat: 48.3445059, lng: 19.3895826 },
    { lat: 48.3441315, lng: 19.3891028 },
    { lat: 48.3440284, lng: 19.3888524 },
    { lat: 48.343904, lng: 19.3889882 },
    { lat: 48.3438693, lng: 19.3889675 },
    { lat: 48.3438104, lng: 19.3887478 },
    { lat: 48.3436737, lng: 19.3886681 },
    { lat: 48.3435048, lng: 19.3886731 },
    { lat: 48.3433664, lng: 19.388538 },
    { lat: 48.3433063, lng: 19.3883365 },
    { lat: 48.3432132, lng: 19.3882267 },
    { lat: 48.3430777, lng: 19.3882482 },
    { lat: 48.3425131, lng: 19.3877729 },
    { lat: 48.3422478, lng: 19.3876216 },
    { lat: 48.3419202, lng: 19.3875362 },
    { lat: 48.3417246, lng: 19.3872634 },
    { lat: 48.34155, lng: 19.3872161 },
    { lat: 48.3413528, lng: 19.3872659 },
    { lat: 48.3412847, lng: 19.3870911 },
    { lat: 48.3410245, lng: 19.3870672 },
    { lat: 48.340847, lng: 19.3869405 },
    { lat: 48.3406197, lng: 19.3870424 },
    { lat: 48.3405181, lng: 19.3869183 },
    { lat: 48.3402044, lng: 19.3867229 },
    { lat: 48.339934, lng: 19.3868167 },
    { lat: 48.3397606, lng: 19.3867509 },
    { lat: 48.3391848, lng: 19.3868018 },
    { lat: 48.3389308, lng: 19.3870025 },
    { lat: 48.3388101, lng: 19.3870275 },
    { lat: 48.3384432, lng: 19.3866575 },
    { lat: 48.3363848, lng: 19.3880283 },
    { lat: 48.3363289, lng: 19.3882525 },
    { lat: 48.3342919, lng: 19.3885271 },
    { lat: 48.3339648, lng: 19.3884275 },
    { lat: 48.3336063, lng: 19.3885922 },
    { lat: 48.3324579, lng: 19.3885002 },
    { lat: 48.3320518, lng: 19.3887522 },
    { lat: 48.3314988, lng: 19.3886203 },
    { lat: 48.3313022, lng: 19.3884384 },
    { lat: 48.3311884, lng: 19.3879926 },
    { lat: 48.3305991, lng: 19.3877321 },
    { lat: 48.3301104, lng: 19.3871489 },
    { lat: 48.3298557, lng: 19.3865322 },
    { lat: 48.3297911, lng: 19.3854808 },
    { lat: 48.3294808, lng: 19.3851878 },
    { lat: 48.3293532, lng: 19.3846638 },
    { lat: 48.3292739, lng: 19.3845802 },
    { lat: 48.3289353, lng: 19.3846677 },
    { lat: 48.3287901, lng: 19.3844087 },
    { lat: 48.3286841, lng: 19.3840531 },
    { lat: 48.3285607, lng: 19.3839463 },
    { lat: 48.3280955, lng: 19.3837881 },
    { lat: 48.3272739, lng: 19.3836698 },
    { lat: 48.3271446, lng: 19.3836985 },
    { lat: 48.3267096, lng: 19.3840814 },
    { lat: 48.3264458, lng: 19.3844103 },
    { lat: 48.325923, lng: 19.3845561 },
    { lat: 48.3256445, lng: 19.3847431 },
    { lat: 48.3252923, lng: 19.3848013 },
    { lat: 48.3250461, lng: 19.3851481 },
    { lat: 48.3247422, lng: 19.3851841 },
    { lat: 48.3245637, lng: 19.3851501 },
    { lat: 48.3242553, lng: 19.3849684 },
    { lat: 48.3240559, lng: 19.3849703 },
    { lat: 48.3236651, lng: 19.3853311 },
    { lat: 48.3231042, lng: 19.3852908 },
    { lat: 48.3229839, lng: 19.385349 },
    { lat: 48.3228244, lng: 19.3855714 },
    { lat: 48.3224309, lng: 19.3857349 },
    { lat: 48.3215968, lng: 19.385636 },
    { lat: 48.3207961, lng: 19.3854379 },
    { lat: 48.3198928, lng: 19.3844105 },
    { lat: 48.3187168, lng: 19.383971 },
    { lat: 48.3184631, lng: 19.3837824 },
    { lat: 48.3177067, lng: 19.383018 },
    { lat: 48.3170011, lng: 19.3825442 },
    { lat: 48.3160101, lng: 19.3822058 },
    { lat: 48.3143256, lng: 19.3792997 },
    { lat: 48.3133167, lng: 19.3779962 },
    { lat: 48.3114411, lng: 19.3774944 },
    { lat: 48.3102263, lng: 19.3769301 },
    { lat: 48.3100763, lng: 19.3760068 },
    { lat: 48.3101458, lng: 19.3755472 },
    { lat: 48.3101621, lng: 19.375463 },
    { lat: 48.3100238, lng: 19.3753999 },
    { lat: 48.3098464, lng: 19.3754844 },
    { lat: 48.3094182, lng: 19.3760793 },
    { lat: 48.3086217, lng: 19.376633 },
    { lat: 48.3085035, lng: 19.3767805 },
    { lat: 48.3083793, lng: 19.3771967 },
    { lat: 48.3081285, lng: 19.3775652 },
    { lat: 48.3073881, lng: 19.3778033 },
    { lat: 48.3069048, lng: 19.3781878 },
    { lat: 48.3059566, lng: 19.3785136 },
    { lat: 48.3057379, lng: 19.3787261 },
    { lat: 48.3054946, lng: 19.3792021 },
    { lat: 48.3053082, lng: 19.3793845 },
    { lat: 48.3048409, lng: 19.3794606 },
    { lat: 48.3040767, lng: 19.3798153 },
    { lat: 48.3035671, lng: 19.3797423 },
    { lat: 48.3031349, lng: 19.3801096 },
    { lat: 48.3029591, lng: 19.380133 },
    { lat: 48.3027144, lng: 19.379968 },
    { lat: 48.3025636, lng: 19.3797565 },
    { lat: 48.3021053, lng: 19.379482 },
    { lat: 48.3019561, lng: 19.3795241 },
    { lat: 48.3016575, lng: 19.3799231 },
    { lat: 48.3015418, lng: 19.3799344 },
    { lat: 48.3013078, lng: 19.379707 },
    { lat: 48.3011975, lng: 19.3796884 },
    { lat: 48.3005906, lng: 19.3799816 },
    { lat: 48.3001729, lng: 19.3803466 },
    { lat: 48.3000137, lng: 19.3803668 },
    { lat: 48.2995786, lng: 19.3801758 },
    { lat: 48.2992233, lng: 19.3802393 },
    { lat: 48.2987435, lng: 19.3802024 },
    { lat: 48.2982865, lng: 19.3798099 },
    { lat: 48.2982088, lng: 19.3798104 },
    { lat: 48.2976707, lng: 19.3802243 },
    { lat: 48.2971401, lng: 19.380257 },
    { lat: 48.2968506, lng: 19.3803523 },
    { lat: 48.2964, lng: 19.3806649 },
    { lat: 48.2963165, lng: 19.3806869 },
    { lat: 48.2960159, lng: 19.3805754 },
    { lat: 48.2958163, lng: 19.3805992 },
    { lat: 48.2952092, lng: 19.3809398 },
    { lat: 48.294949, lng: 19.3812989 },
    { lat: 48.2944953, lng: 19.3821129 },
    { lat: 48.2942241, lng: 19.3821699 },
    { lat: 48.2935422, lng: 19.3819302 },
    { lat: 48.2932545, lng: 19.3819751 },
    { lat: 48.2931021, lng: 19.3823963 },
    { lat: 48.292837, lng: 19.3827704 },
    { lat: 48.2926531, lng: 19.3829567 },
    { lat: 48.2919784, lng: 19.3833208 },
    { lat: 48.2917907, lng: 19.3831879 },
    { lat: 48.2916407, lng: 19.3828295 },
    { lat: 48.2915683, lng: 19.3827767 },
    { lat: 48.2913978, lng: 19.3828672 },
    { lat: 48.2913748, lng: 19.383043 },
    { lat: 48.2916626, lng: 19.3838114 },
    { lat: 48.2916371, lng: 19.3838708 },
    { lat: 48.2910425, lng: 19.3834997 },
    { lat: 48.290867, lng: 19.3835545 },
    { lat: 48.2905821, lng: 19.3840358 },
    { lat: 48.290404, lng: 19.3840796 },
    { lat: 48.2902512, lng: 19.3840188 },
    { lat: 48.2900353, lng: 19.3840794 },
    { lat: 48.2898472, lng: 19.384435 },
    { lat: 48.2893841, lng: 19.3850995 },
    { lat: 48.2891585, lng: 19.3855961 },
    { lat: 48.2889545, lng: 19.3858305 },
    { lat: 48.2885727, lng: 19.385981 },
    { lat: 48.2882765, lng: 19.3864136 },
    { lat: 48.2881943, lng: 19.3867451 },
    { lat: 48.2873139, lng: 19.3874538 },
    { lat: 48.2864529, lng: 19.387907 },
    { lat: 48.2859805, lng: 19.3879967 },
    { lat: 48.2855446, lng: 19.387765 },
    { lat: 48.2853269, lng: 19.3879291 },
    { lat: 48.2851578, lng: 19.3883311 },
    { lat: 48.2848551, lng: 19.3885517 },
    { lat: 48.2843328, lng: 19.3881184 },
    { lat: 48.2841074, lng: 19.3879775 },
    { lat: 48.2839282, lng: 19.3879538 },
    { lat: 48.2836022, lng: 19.3883248 },
    { lat: 48.283263, lng: 19.3883971 },
    { lat: 48.2829029, lng: 19.3883152 },
    { lat: 48.2827537, lng: 19.388375 },
    { lat: 48.2826952, lng: 19.3886647 },
    { lat: 48.2825119, lng: 19.3889574 },
    { lat: 48.2822958, lng: 19.3889634 },
    { lat: 48.2819032, lng: 19.3886741 },
    { lat: 48.2816734, lng: 19.3886488 },
    { lat: 48.281249, lng: 19.3888533 },
    { lat: 48.2811294, lng: 19.3899382 },
    { lat: 48.276843, lng: 19.3948017 },
    { lat: 48.2765207, lng: 19.3956336 },
    { lat: 48.2759462, lng: 19.3973736 },
    { lat: 48.275731, lng: 19.3982025 },
    { lat: 48.2751199, lng: 19.3994605 },
    { lat: 48.2749524, lng: 19.3999666 },
    { lat: 48.2752731, lng: 19.4003843 },
    { lat: 48.275468, lng: 19.4008079 },
    { lat: 48.276277, lng: 19.401756 },
    { lat: 48.2763181, lng: 19.4021454 },
    { lat: 48.2764718, lng: 19.4024192 },
    { lat: 48.2771168, lng: 19.4030157 },
    { lat: 48.2773977, lng: 19.4029292 },
    { lat: 48.2778867, lng: 19.4030449 },
    { lat: 48.2782972, lng: 19.4033596 },
    { lat: 48.2786583, lng: 19.4034843 },
    { lat: 48.2789968, lng: 19.4039239 },
    { lat: 48.2791048, lng: 19.4041802 },
    { lat: 48.2799088, lng: 19.4047585 },
    { lat: 48.2805284, lng: 19.4056076 },
    { lat: 48.281478, lng: 19.4064797 },
    { lat: 48.283112, lng: 19.4077581 },
    { lat: 48.2838404, lng: 19.4085268 },
    { lat: 48.284312, lng: 19.4090605 },
    { lat: 48.2851675, lng: 19.41047 },
    { lat: 48.28521, lng: 19.4105345 },
    { lat: 48.2852079, lng: 19.4105598 },
    { lat: 48.2855542, lng: 19.4104706 },
    { lat: 48.2857574, lng: 19.4102374 },
    { lat: 48.286109, lng: 19.4095684 },
    { lat: 48.2861356, lng: 19.4091335 },
    { lat: 48.286423, lng: 19.4088478 },
    { lat: 48.2864675, lng: 19.4086693 },
    { lat: 48.2864479, lng: 19.4085666 },
    { lat: 48.2862513, lng: 19.4085794 },
    { lat: 48.2861249, lng: 19.4084071 },
    { lat: 48.2864464, lng: 19.4074117 },
    { lat: 48.2863484, lng: 19.4071518 },
    { lat: 48.2864728, lng: 19.4066127 },
    { lat: 48.2864242, lng: 19.40635 },
    { lat: 48.2864941, lng: 19.4062046 },
    { lat: 48.2866387, lng: 19.4061672 },
    { lat: 48.2868848, lng: 19.4064847 },
    { lat: 48.2870197, lng: 19.4065259 },
    { lat: 48.287054, lng: 19.4063428 },
    { lat: 48.2871583, lng: 19.4063123 },
    { lat: 48.287338, lng: 19.4059173 },
    { lat: 48.2872347, lng: 19.4055907 },
    { lat: 48.2875344, lng: 19.4050167 },
    { lat: 48.2878367, lng: 19.4048083 },
    { lat: 48.2879387, lng: 19.4046523 },
    { lat: 48.2880754, lng: 19.4042584 },
    { lat: 48.2881819, lng: 19.4040779 },
    { lat: 48.2883752, lng: 19.4039017 },
    { lat: 48.2888412, lng: 19.4030548 },
    { lat: 48.2889523, lng: 19.402998 },
    { lat: 48.2890098, lng: 19.4030442 },
    { lat: 48.2890436, lng: 19.4036426 },
    { lat: 48.2891445, lng: 19.4040569 },
    { lat: 48.2893705, lng: 19.4040676 },
    { lat: 48.2894591, lng: 19.4043473 },
    { lat: 48.289628, lng: 19.4046025 },
    { lat: 48.28975, lng: 19.4045853 },
    { lat: 48.2902062, lng: 19.4041301 },
    { lat: 48.2905048, lng: 19.4039865 },
    { lat: 48.2906104, lng: 19.4038128 },
    { lat: 48.2904528, lng: 19.4036257 },
    { lat: 48.2904216, lng: 19.4034411 },
    { lat: 48.2907644, lng: 19.4029704 },
    { lat: 48.2912333, lng: 19.4029478 },
    { lat: 48.2914168, lng: 19.4032018 },
    { lat: 48.291833, lng: 19.4030941 },
    { lat: 48.2919554, lng: 19.4028213 },
    { lat: 48.2920525, lng: 19.4023035 },
    { lat: 48.2919918, lng: 19.4018739 },
    { lat: 48.2920337, lng: 19.401749 },
    { lat: 48.2921379, lng: 19.4014386 },
    { lat: 48.2921323, lng: 19.4011557 },
    { lat: 48.2920279, lng: 19.4006434 },
    { lat: 48.2920814, lng: 19.4002983 },
    { lat: 48.2922867, lng: 19.40004 },
    { lat: 48.292397, lng: 19.4000095 },
    { lat: 48.2926342, lng: 19.3996187 },
    { lat: 48.2928167, lng: 19.3996424 },
    { lat: 48.2931431, lng: 19.3998667 },
    { lat: 48.2932045, lng: 19.3997862 },
    { lat: 48.2933252, lng: 19.3992637 },
    { lat: 48.293451, lng: 19.3990882 },
    { lat: 48.2935781, lng: 19.3984917 },
    { lat: 48.2938598, lng: 19.398106 },
    { lat: 48.2941605, lng: 19.3978425 },
    { lat: 48.2944369, lng: 19.3978456 },
    { lat: 48.2945183, lng: 19.3978972 },
    { lat: 48.2946834, lng: 19.3983163 },
    { lat: 48.2950203, lng: 19.3983583 },
    { lat: 48.2950814, lng: 19.3983171 },
    { lat: 48.2951773, lng: 19.3979384 },
    { lat: 48.29531, lng: 19.3978149 },
    { lat: 48.295452, lng: 19.3979587 },
    { lat: 48.2955227, lng: 19.3978808 },
    { lat: 48.2959135, lng: 19.3970449 },
    { lat: 48.2960421, lng: 19.3968765 },
    { lat: 48.2962275, lng: 19.3968433 },
    { lat: 48.2965397, lng: 19.3971049 },
    { lat: 48.2969329, lng: 19.3973387 },
    { lat: 48.297085, lng: 19.3973589 },
    { lat: 48.2972306, lng: 19.3972924 },
    { lat: 48.2972946, lng: 19.3971929 },
    { lat: 48.2972761, lng: 19.3967811 },
    { lat: 48.2974749, lng: 19.3966962 },
    { lat: 48.2975634, lng: 19.3967303 },
    { lat: 48.2976311, lng: 19.3973011 },
    { lat: 48.2978661, lng: 19.3974099 },
    { lat: 48.2981751, lng: 19.3977953 },
    { lat: 48.2981416, lng: 19.397921 },
    { lat: 48.2979189, lng: 19.398144 },
    { lat: 48.2978908, lng: 19.3982401 },
    { lat: 48.2979226, lng: 19.3984314 },
    { lat: 48.2981588, lng: 19.398825 },
    { lat: 48.2983671, lng: 19.3994187 },
    { lat: 48.2986248, lng: 19.3996935 },
    { lat: 48.2989474, lng: 19.3998666 },
    { lat: 48.2997777, lng: 19.3994305 },
    { lat: 48.3001838, lng: 19.3993894 },
    { lat: 48.3008797, lng: 19.399545 },
    { lat: 48.3012006, lng: 19.3994896 },
    { lat: 48.3016855, lng: 19.399257 },
    { lat: 48.3020524, lng: 19.3992233 },
    { lat: 48.3021844, lng: 19.3988942 },
    { lat: 48.3021232, lng: 19.3986008 },
    { lat: 48.303172, lng: 19.3983305 },
    { lat: 48.3036528, lng: 19.3976527 },
    { lat: 48.3036326, lng: 19.397251 },
    { lat: 48.3038729, lng: 19.3969938 },
    { lat: 48.3041943, lng: 19.396904 },
    { lat: 48.3041962, lng: 19.3963969 },
    { lat: 48.3046463, lng: 19.3962852 },
    { lat: 48.304753, lng: 19.3961612 },
    { lat: 48.3050008, lng: 19.3960874 },
    { lat: 48.3050511, lng: 19.3961953 },
    { lat: 48.3053259, lng: 19.3960736 },
    { lat: 48.3056089, lng: 19.3961691 },
    { lat: 48.3057852, lng: 19.3965111 },
    { lat: 48.3059245, lng: 19.3965054 },
    { lat: 48.3060124, lng: 19.3965761 },
    { lat: 48.3059098, lng: 19.3967881 },
    { lat: 48.3058147, lng: 19.3968481 },
    { lat: 48.3056985, lng: 19.3972025 },
    { lat: 48.3057782, lng: 19.3973391 },
    { lat: 48.3059287, lng: 19.3974251 },
    { lat: 48.3060157, lng: 19.3975967 },
    { lat: 48.3061176, lng: 19.3975784 },
    { lat: 48.3062917, lng: 19.3972118 },
    { lat: 48.3064894, lng: 19.3971525 },
    { lat: 48.3067663, lng: 19.3972381 },
    { lat: 48.3069732, lng: 19.3972288 },
    { lat: 48.3070782, lng: 19.3974902 },
    { lat: 48.3072752, lng: 19.3973913 },
    { lat: 48.3075594, lng: 19.3974177 },
    { lat: 48.3076645, lng: 19.3975675 },
    { lat: 48.3078106, lng: 19.3980575 },
    { lat: 48.307751, lng: 19.3983963 },
    { lat: 48.3077739, lng: 19.3986643 },
    { lat: 48.3075703, lng: 19.3995753 },
    { lat: 48.3078708, lng: 19.3995674 },
    { lat: 48.3080309, lng: 19.3996367 },
    { lat: 48.3086127, lng: 19.4001674 },
    { lat: 48.3085655, lng: 19.4004567 },
    { lat: 48.3091018, lng: 19.4006403 },
    { lat: 48.3092129, lng: 19.4006317 },
    { lat: 48.309371, lng: 19.399757 },
    { lat: 48.3097031, lng: 19.4003894 },
    { lat: 48.3100743, lng: 19.4008522 },
    { lat: 48.3106411, lng: 19.4009214 },
    { lat: 48.3109913, lng: 19.400677 },
    { lat: 48.3112183, lng: 19.4006861 },
    { lat: 48.3112851, lng: 19.400765 },
    { lat: 48.3113795, lng: 19.4013016 },
    { lat: 48.311469, lng: 19.4014808 },
    { lat: 48.3112206, lng: 19.4022309 },
    { lat: 48.3114916, lng: 19.4024081 },
    { lat: 48.3118898, lng: 19.4028203 },
    { lat: 48.3122035, lng: 19.4035453 },
    { lat: 48.3124286, lng: 19.4034668 },
    { lat: 48.3126145, lng: 19.4034867 },
    { lat: 48.3126249, lng: 19.4036623 },
    { lat: 48.3127021, lng: 19.4037415 },
    { lat: 48.313268, lng: 19.403911 },
    { lat: 48.3135716, lng: 19.4040866 },
    { lat: 48.3135629, lng: 19.4042833 },
    { lat: 48.3136883, lng: 19.4044562 },
    { lat: 48.3140377, lng: 19.4047879 },
    { lat: 48.3141257, lng: 19.4046723 },
    { lat: 48.3147801, lng: 19.4054032 },
    { lat: 48.3150918, lng: 19.4060821 },
    { lat: 48.3152475, lng: 19.4063028 },
    { lat: 48.3156037, lng: 19.4063976 },
    { lat: 48.3158132, lng: 19.4066076 },
    { lat: 48.3162998, lng: 19.4069078 },
    { lat: 48.3166795, lng: 19.4075264 },
    { lat: 48.3171853, lng: 19.4075232 },
    { lat: 48.3170884, lng: 19.4077881 },
    { lat: 48.3175553, lng: 19.4077912 },
    { lat: 48.3176185, lng: 19.4076244 },
    { lat: 48.3178068, lng: 19.4081259 },
    { lat: 48.3183273, lng: 19.4085033 },
    { lat: 48.3188178, lng: 19.4093672 },
    { lat: 48.3191746, lng: 19.4096893 },
    { lat: 48.3193935, lng: 19.4096243 },
    { lat: 48.3193382, lng: 19.4098585 },
    { lat: 48.3195054, lng: 19.410152 },
    { lat: 48.3200797, lng: 19.4104748 },
    { lat: 48.3202734, lng: 19.4106904 },
    { lat: 48.3204347, lng: 19.4110507 },
    { lat: 48.3204501, lng: 19.4113508 },
    { lat: 48.3207765, lng: 19.4115565 },
    { lat: 48.3210424, lng: 19.4114114 },
    { lat: 48.3214997, lng: 19.411742 },
    { lat: 48.3212967, lng: 19.4118783 },
    { lat: 48.3211661, lng: 19.4126674 },
    { lat: 48.3209048, lng: 19.4130751 },
    { lat: 48.3207659, lng: 19.413904 },
    { lat: 48.3205923, lng: 19.4143332 },
    { lat: 48.3202868, lng: 19.4159797 },
    { lat: 48.3200347, lng: 19.4169424 },
    { lat: 48.3202183, lng: 19.4171297 },
    { lat: 48.3204458, lng: 19.417556 },
    { lat: 48.3207305, lng: 19.4185236 },
    { lat: 48.3207864, lng: 19.4191122 },
    { lat: 48.3208267, lng: 19.4204401 },
    { lat: 48.320694, lng: 19.4204715 },
    { lat: 48.3206473, lng: 19.4209882 },
    { lat: 48.3206155, lng: 19.4228892 },
    { lat: 48.3205393, lng: 19.4228775 },
    { lat: 48.3203893, lng: 19.423352 },
    { lat: 48.3203013, lng: 19.4241396 },
    { lat: 48.3202966, lng: 19.4252563 },
    { lat: 48.3204482, lng: 19.4260323 },
    { lat: 48.3207384, lng: 19.4258564 },
    { lat: 48.3210962, lng: 19.4266056 },
    { lat: 48.3210513, lng: 19.4266107 },
    { lat: 48.3211483, lng: 19.4271851 },
    { lat: 48.3213293, lng: 19.427412 },
    { lat: 48.3213443, lng: 19.4278063 },
    { lat: 48.3210356, lng: 19.4281666 },
    { lat: 48.3208681, lng: 19.4280101 },
    { lat: 48.3207414, lng: 19.4281631 },
    { lat: 48.3207436, lng: 19.428405 },
    { lat: 48.3214787, lng: 19.4297709 },
    { lat: 48.3217401, lng: 19.4303901 },
    { lat: 48.3220531, lng: 19.4307422 },
    { lat: 48.3220103, lng: 19.430887 },
    { lat: 48.322004, lng: 19.431331 },
    { lat: 48.3217585, lng: 19.4313584 },
    { lat: 48.3220914, lng: 19.4333515 },
    { lat: 48.322234, lng: 19.4348492 },
  ],
  Pravica: [
    { lat: 48.322234, lng: 19.4348492 },
    { lat: 48.3221141, lng: 19.4349303 },
    { lat: 48.3215146, lng: 19.4353645 },
    { lat: 48.3212806, lng: 19.4354452 },
    { lat: 48.3198327, lng: 19.435426 },
    { lat: 48.3190171, lng: 19.4355552 },
    { lat: 48.3183595, lng: 19.4360954 },
    { lat: 48.3184234, lng: 19.4362335 },
    { lat: 48.3181937, lng: 19.4365262 },
    { lat: 48.3186902, lng: 19.4374966 },
    { lat: 48.3188573, lng: 19.4373819 },
    { lat: 48.3193064, lng: 19.4387362 },
    { lat: 48.3202556, lng: 19.4407048 },
    { lat: 48.3202116, lng: 19.4412049 },
    { lat: 48.320072, lng: 19.441857 },
    { lat: 48.3199682, lng: 19.4421155 },
    { lat: 48.3199953, lng: 19.4426679 },
    { lat: 48.3197134, lng: 19.4431952 },
    { lat: 48.3199765, lng: 19.4446875 },
    { lat: 48.3194581, lng: 19.4455517 },
    { lat: 48.318954, lng: 19.4461482 },
    { lat: 48.3187619, lng: 19.4465225 },
    { lat: 48.3186691, lng: 19.4468248 },
    { lat: 48.3185923, lng: 19.4474657 },
    { lat: 48.3184264, lng: 19.4482544 },
    { lat: 48.3183211, lng: 19.4484606 },
    { lat: 48.3174796, lng: 19.4497169 },
    { lat: 48.3165753, lng: 19.4505966 },
    { lat: 48.3158716, lng: 19.4509959 },
    { lat: 48.3156934, lng: 19.4511833 },
    { lat: 48.3155813, lng: 19.4514114 },
    { lat: 48.3152717, lng: 19.4515886 },
    { lat: 48.3149343, lng: 19.4516741 },
    { lat: 48.3144172, lng: 19.4520147 },
    { lat: 48.3138057, lng: 19.4521681 },
    { lat: 48.3137801, lng: 19.451981 },
    { lat: 48.3136485, lng: 19.4520346 },
    { lat: 48.3124919, lng: 19.4532039 },
    { lat: 48.3124203, lng: 19.4531424 },
    { lat: 48.3118401, lng: 19.4534606 },
    { lat: 48.3117252, lng: 19.4534133 },
    { lat: 48.3112981, lng: 19.4536828 },
    { lat: 48.3106069, lng: 19.4539858 },
    { lat: 48.3104859, lng: 19.4540747 },
    { lat: 48.3103939, lng: 19.4542724 },
    { lat: 48.3099284, lng: 19.4542981 },
    { lat: 48.3093796, lng: 19.4545401 },
    { lat: 48.3090597, lng: 19.4548043 },
    { lat: 48.3084446, lng: 19.4546652 },
    { lat: 48.3076158, lng: 19.4545876 },
    { lat: 48.3071351, lng: 19.4547863 },
    { lat: 48.3068895, lng: 19.4549839 },
    { lat: 48.3035241, lng: 19.4585114 },
    { lat: 48.3030145, lng: 19.4591318 },
    { lat: 48.3024608, lng: 19.4603965 },
    { lat: 48.3017939, lng: 19.4623976 },
    { lat: 48.3011611, lng: 19.4638054 },
    { lat: 48.3003251, lng: 19.4650172 },
    { lat: 48.2990732, lng: 19.4659796 },
    { lat: 48.2989845, lng: 19.4662031 },
    { lat: 48.2983308, lng: 19.4669734 },
    { lat: 48.2974932, lng: 19.4680996 },
    { lat: 48.2971298, lng: 19.4687873 },
    { lat: 48.2965764, lng: 19.4696528 },
    { lat: 48.2965473, lng: 19.4697033 },
    { lat: 48.2965667, lng: 19.4697086 },
    { lat: 48.2967087, lng: 19.4697093 },
    { lat: 48.2973513, lng: 19.4705078 },
    { lat: 48.2974553, lng: 19.4709743 },
    { lat: 48.2982388, lng: 19.4723038 },
    { lat: 48.2979992, lng: 19.4726253 },
    { lat: 48.298097, lng: 19.4728132 },
    { lat: 48.2983314, lng: 19.4728395 },
    { lat: 48.2983287, lng: 19.4726827 },
    { lat: 48.2986727, lng: 19.4725697 },
    { lat: 48.2986869, lng: 19.4727509 },
    { lat: 48.2989357, lng: 19.4727236 },
    { lat: 48.299042, lng: 19.4726332 },
    { lat: 48.299038, lng: 19.472451 },
    { lat: 48.2992146, lng: 19.4724448 },
    { lat: 48.2992686, lng: 19.4719836 },
    { lat: 48.2995122, lng: 19.4718915 },
    { lat: 48.299546, lng: 19.471701 },
    { lat: 48.2996581, lng: 19.4714917 },
    { lat: 48.2995863, lng: 19.4713556 },
    { lat: 48.2995913, lng: 19.4712701 },
    { lat: 48.2998541, lng: 19.4710121 },
    { lat: 48.2998204, lng: 19.4709618 },
    { lat: 48.299877, lng: 19.4708001 },
    { lat: 48.300045, lng: 19.4707563 },
    { lat: 48.3001447, lng: 19.4710286 },
    { lat: 48.3002433, lng: 19.4710674 },
    { lat: 48.3004086, lng: 19.470866 },
    { lat: 48.3006182, lng: 19.4708673 },
    { lat: 48.3006332, lng: 19.4706796 },
    { lat: 48.300449, lng: 19.4705984 },
    { lat: 48.3003847, lng: 19.4702134 },
    { lat: 48.3004335, lng: 19.4699045 },
    { lat: 48.300559, lng: 19.4698899 },
    { lat: 48.3007652, lng: 19.4696736 },
    { lat: 48.3008201, lng: 19.469832 },
    { lat: 48.3010706, lng: 19.4699129 },
    { lat: 48.3015334, lng: 19.4694724 },
    { lat: 48.3014989, lng: 19.4691688 },
    { lat: 48.3015956, lng: 19.4688992 },
    { lat: 48.3016761, lng: 19.4688377 },
    { lat: 48.3016804, lng: 19.4686609 },
    { lat: 48.3017864, lng: 19.4685662 },
    { lat: 48.3016883, lng: 19.4683975 },
    { lat: 48.3017287, lng: 19.4682761 },
    { lat: 48.3021585, lng: 19.4678018 },
    { lat: 48.3022216, lng: 19.4674136 },
    { lat: 48.3023689, lng: 19.4673631 },
    { lat: 48.3027025, lng: 19.4676473 },
    { lat: 48.3028035, lng: 19.4675191 },
    { lat: 48.3028646, lng: 19.467523 },
    { lat: 48.3029171, lng: 19.4673695 },
    { lat: 48.3028719, lng: 19.4671505 },
    { lat: 48.3029771, lng: 19.4668952 },
    { lat: 48.3032445, lng: 19.466563 },
    { lat: 48.3034599, lng: 19.466906 },
    { lat: 48.3035992, lng: 19.4673491 },
    { lat: 48.3037906, lng: 19.4676603 },
    { lat: 48.3039085, lng: 19.4677725 },
    { lat: 48.3042575, lng: 19.4679322 },
    { lat: 48.305662, lng: 19.4692617 },
    { lat: 48.3061362, lng: 19.4690883 },
    { lat: 48.3062897, lng: 19.4691444 },
    { lat: 48.3066512, lng: 19.4689212 },
    { lat: 48.3068051, lng: 19.4690749 },
    { lat: 48.3070925, lng: 19.4690553 },
    { lat: 48.3071063, lng: 19.4692288 },
    { lat: 48.3072661, lng: 19.4692732 },
    { lat: 48.3075598, lng: 19.4691202 },
    { lat: 48.3078384, lng: 19.4691681 },
    { lat: 48.3081213, lng: 19.4689765 },
    { lat: 48.308291, lng: 19.4690911 },
    { lat: 48.3087993, lng: 19.4689449 },
    { lat: 48.3089562, lng: 19.4689974 },
    { lat: 48.3093006, lng: 19.4688178 },
    { lat: 48.3098494, lng: 19.4688181 },
    { lat: 48.3100141, lng: 19.4686288 },
    { lat: 48.3102722, lng: 19.4686519 },
    { lat: 48.3105011, lng: 19.4688006 },
    { lat: 48.3107345, lng: 19.4688263 },
    { lat: 48.3109492, lng: 19.4687665 },
    { lat: 48.3110826, lng: 19.4685851 },
    { lat: 48.3111122, lng: 19.4684442 },
    { lat: 48.3112971, lng: 19.4684248 },
    { lat: 48.3114195, lng: 19.4682448 },
    { lat: 48.3117175, lng: 19.4683034 },
    { lat: 48.3118907, lng: 19.4682455 },
    { lat: 48.3120267, lng: 19.4680931 },
    { lat: 48.3121981, lng: 19.4681679 },
    { lat: 48.312332, lng: 19.4679258 },
    { lat: 48.3123064, lng: 19.4677879 },
    { lat: 48.3124117, lng: 19.4677025 },
    { lat: 48.3125454, lng: 19.4677628 },
    { lat: 48.3127172, lng: 19.4676038 },
    { lat: 48.3129996, lng: 19.4676672 },
    { lat: 48.3132284, lng: 19.4675472 },
    { lat: 48.3133662, lng: 19.467578 },
    { lat: 48.3134953, lng: 19.4675219 },
    { lat: 48.3137277, lng: 19.4676433 },
    { lat: 48.3138189, lng: 19.4675729 },
    { lat: 48.3139085, lng: 19.4673429 },
    { lat: 48.3141832, lng: 19.4670725 },
    { lat: 48.3146222, lng: 19.4670875 },
    { lat: 48.314775, lng: 19.4669309 },
    { lat: 48.3149886, lng: 19.4668922 },
    { lat: 48.3150629, lng: 19.466725 },
    { lat: 48.3151679, lng: 19.4666559 },
    { lat: 48.3154204, lng: 19.4667274 },
    { lat: 48.3156178, lng: 19.4664439 },
    { lat: 48.3157429, lng: 19.4661549 },
    { lat: 48.3161142, lng: 19.4659749 },
    { lat: 48.3161895, lng: 19.4660651 },
    { lat: 48.3163773, lng: 19.4659154 },
    { lat: 48.3165132, lng: 19.4660498 },
    { lat: 48.3167823, lng: 19.4659712 },
    { lat: 48.3169973, lng: 19.465797 },
    { lat: 48.317242, lng: 19.4657766 },
    { lat: 48.3172681, lng: 19.4655377 },
    { lat: 48.3174258, lng: 19.4655104 },
    { lat: 48.3175463, lng: 19.4653144 },
    { lat: 48.3176816, lng: 19.4652498 },
    { lat: 48.317791, lng: 19.4652967 },
    { lat: 48.3178875, lng: 19.4651935 },
    { lat: 48.3181312, lng: 19.4651735 },
    { lat: 48.3182853, lng: 19.4649289 },
    { lat: 48.3184278, lng: 19.4648901 },
    { lat: 48.3184857, lng: 19.4647991 },
    { lat: 48.3187406, lng: 19.4648781 },
    { lat: 48.318943, lng: 19.4647453 },
    { lat: 48.3192594, lng: 19.4644018 },
    { lat: 48.3194281, lng: 19.4643734 },
    { lat: 48.3195547, lng: 19.4643785 },
    { lat: 48.3196501, lng: 19.4644955 },
    { lat: 48.319762, lng: 19.4644285 },
    { lat: 48.3198529, lng: 19.4645139 },
    { lat: 48.3201961, lng: 19.4643587 },
    { lat: 48.3203551, lng: 19.4644348 },
    { lat: 48.3209154, lng: 19.4643414 },
    { lat: 48.3209947, lng: 19.4643756 },
    { lat: 48.3212674, lng: 19.4646624 },
    { lat: 48.3214228, lng: 19.4650713 },
    { lat: 48.3219302, lng: 19.4655777 },
    { lat: 48.3219885, lng: 19.46584 },
    { lat: 48.3221182, lng: 19.4660569 },
    { lat: 48.3221968, lng: 19.4660964 },
    { lat: 48.3225319, lng: 19.4660374 },
    { lat: 48.3225748, lng: 19.4661101 },
    { lat: 48.3227427, lng: 19.4659887 },
    { lat: 48.3229511, lng: 19.4659855 },
    { lat: 48.3232646, lng: 19.466064 },
    { lat: 48.3241335, lng: 19.4664503 },
    { lat: 48.3242644, lng: 19.4665456 },
    { lat: 48.3245113, lng: 19.4669123 },
    { lat: 48.324898, lng: 19.4670812 },
    { lat: 48.3253279, lng: 19.4676228 },
    { lat: 48.3255894, lng: 19.4676491 },
    { lat: 48.3255215, lng: 19.4679293 },
    { lat: 48.325687, lng: 19.468022 },
    { lat: 48.32574, lng: 19.468032 },
    { lat: 48.325783, lng: 19.467991 },
    { lat: 48.325852, lng: 19.468112 },
    { lat: 48.325955, lng: 19.468169 },
    { lat: 48.326072, lng: 19.468126 },
    { lat: 48.326118, lng: 19.46815 },
    { lat: 48.326171, lng: 19.468234 },
    { lat: 48.326268, lng: 19.468185 },
    { lat: 48.326333, lng: 19.468123 },
    { lat: 48.326425, lng: 19.468072 },
    { lat: 48.326507, lng: 19.468052 },
    { lat: 48.326764, lng: 19.468135 },
    { lat: 48.326889, lng: 19.468108 },
    { lat: 48.327033, lng: 19.468031 },
    { lat: 48.327111, lng: 19.467967 },
    { lat: 48.327194, lng: 19.467915 },
    { lat: 48.327264, lng: 19.467968 },
    { lat: 48.327741, lng: 19.467001 },
    { lat: 48.328152, lng: 19.466733 },
    { lat: 48.328819, lng: 19.466487 },
    { lat: 48.328951, lng: 19.466411 },
    { lat: 48.329549, lng: 19.466067 },
    { lat: 48.329861, lng: 19.46612 },
    { lat: 48.33001, lng: 19.466237 },
    { lat: 48.330133, lng: 19.466229 },
    { lat: 48.330416, lng: 19.465998 },
    { lat: 48.330837, lng: 19.465995 },
    { lat: 48.330906, lng: 19.466034 },
    { lat: 48.331037, lng: 19.46594 },
    { lat: 48.331111, lng: 19.465916 },
    { lat: 48.331259, lng: 19.465949 },
    { lat: 48.331372, lng: 19.465795 },
    { lat: 48.331692, lng: 19.46563 },
    { lat: 48.331832, lng: 19.465505 },
    { lat: 48.331951, lng: 19.465284 },
    { lat: 48.332199, lng: 19.465217 },
    { lat: 48.332345, lng: 19.465223 },
    { lat: 48.332419, lng: 19.465307 },
    { lat: 48.33256, lng: 19.465245 },
    { lat: 48.332771, lng: 19.465326 },
    { lat: 48.333077, lng: 19.465235 },
    { lat: 48.333204, lng: 19.464996 },
    { lat: 48.333223, lng: 19.464985 },
    { lat: 48.333496, lng: 19.464823 },
    { lat: 48.33361, lng: 19.464782 },
    { lat: 48.333759, lng: 19.464831 },
    { lat: 48.333889, lng: 19.464795 },
    { lat: 48.33416, lng: 19.4646 },
    { lat: 48.334409, lng: 19.46456 },
    { lat: 48.334658, lng: 19.46452 },
    { lat: 48.334736, lng: 19.46452 },
    { lat: 48.334883, lng: 19.464494 },
    { lat: 48.335176, lng: 19.464396 },
    { lat: 48.335279, lng: 19.464411 },
    { lat: 48.335483, lng: 19.46441 },
    { lat: 48.335658, lng: 19.464397 },
    { lat: 48.33579, lng: 19.464416 },
    { lat: 48.335894, lng: 19.464296 },
    { lat: 48.336488, lng: 19.46409 },
    { lat: 48.336553, lng: 19.463994 },
    { lat: 48.336741, lng: 19.463963 },
    { lat: 48.33703, lng: 19.463783 },
    { lat: 48.337195, lng: 19.463724 },
    { lat: 48.337382, lng: 19.463688 },
    { lat: 48.337552, lng: 19.463564 },
    { lat: 48.337849, lng: 19.463253 },
    { lat: 48.337998, lng: 19.463149 },
    { lat: 48.33821, lng: 19.463153 },
    { lat: 48.338349, lng: 19.463207 },
    { lat: 48.33845, lng: 19.463153 },
    { lat: 48.338644, lng: 19.463133 },
    { lat: 48.338783, lng: 19.463164 },
    { lat: 48.338953, lng: 19.463067 },
    { lat: 48.339114, lng: 19.463118 },
    { lat: 48.339377, lng: 19.462978 },
    { lat: 48.339543, lng: 19.463011 },
    { lat: 48.33975, lng: 19.462995 },
    { lat: 48.339831, lng: 19.463041 },
    { lat: 48.340204, lng: 19.463016 },
    { lat: 48.340356, lng: 19.463041 },
    { lat: 48.340454, lng: 19.46303 },
    { lat: 48.34072, lng: 19.462545 },
    { lat: 48.340798, lng: 19.462265 },
    { lat: 48.341149, lng: 19.462316 },
    { lat: 48.341748, lng: 19.461632 },
    { lat: 48.341756, lng: 19.461624 },
    { lat: 48.341927, lng: 19.461457 },
    { lat: 48.342099, lng: 19.461292 },
    { lat: 48.34222, lng: 19.461126 },
    { lat: 48.342446, lng: 19.460686 },
    { lat: 48.34259, lng: 19.460455 },
    { lat: 48.342668, lng: 19.460376 },
    { lat: 48.342782, lng: 19.460344 },
    { lat: 48.342962, lng: 19.460364 },
    { lat: 48.343224, lng: 19.460126 },
    { lat: 48.343525, lng: 19.459779 },
    { lat: 48.343738, lng: 19.459631 },
    { lat: 48.34426, lng: 19.45958 },
    { lat: 48.344467, lng: 19.459577 },
    { lat: 48.344761, lng: 19.459616 },
    { lat: 48.345098, lng: 19.459532 },
    { lat: 48.345431, lng: 19.459503 },
    { lat: 48.346008, lng: 19.45932 },
    { lat: 48.346233, lng: 19.458794 },
    { lat: 48.346597, lng: 19.458068 },
    { lat: 48.346745, lng: 19.457832 },
    { lat: 48.346848, lng: 19.457513 },
    { lat: 48.346965, lng: 19.457392 },
    { lat: 48.346973, lng: 19.457352 },
    { lat: 48.347007, lng: 19.457281 },
    { lat: 48.34712, lng: 19.457188 },
    { lat: 48.347162, lng: 19.457103 },
    { lat: 48.34724, lng: 19.457009 },
    { lat: 48.347469, lng: 19.456431 },
    { lat: 48.34772, lng: 19.456175 },
    { lat: 48.347754, lng: 19.456115 },
    { lat: 48.348644, lng: 19.455827 },
    { lat: 48.348588, lng: 19.455036 },
    { lat: 48.34854, lng: 19.454365 },
    { lat: 48.348065, lng: 19.454 },
    { lat: 48.347328, lng: 19.453594 },
    { lat: 48.347289, lng: 19.453031 },
    { lat: 48.347245, lng: 19.452681 },
    { lat: 48.347163, lng: 19.452053 },
    { lat: 48.347018, lng: 19.450862 },
    { lat: 48.347013, lng: 19.450815 },
    { lat: 48.347012, lng: 19.450723 },
    { lat: 48.346861, lng: 19.449627 },
    { lat: 48.346795, lng: 19.449217 },
    { lat: 48.346569, lng: 19.448075 },
    { lat: 48.34617, lng: 19.446905 },
    { lat: 48.346018, lng: 19.445924 },
    { lat: 48.345807, lng: 19.444974 },
    { lat: 48.34593, lng: 19.443763 },
    { lat: 48.346113, lng: 19.44257 },
    { lat: 48.34616, lng: 19.441576 },
    { lat: 48.346201, lng: 19.440663 },
    { lat: 48.346091, lng: 19.439746 },
    { lat: 48.346148, lng: 19.438922 },
    { lat: 48.345908, lng: 19.437885 },
    { lat: 48.345686, lng: 19.437608 },
    { lat: 48.345517, lng: 19.437397 },
    { lat: 48.345495, lng: 19.437345 },
    { lat: 48.345457, lng: 19.437255 },
    { lat: 48.344995, lng: 19.436158 },
    { lat: 48.34501, lng: 19.436097 },
    { lat: 48.344546, lng: 19.435708 },
    { lat: 48.344311, lng: 19.435493 },
    { lat: 48.344125, lng: 19.435464 },
    { lat: 48.343939, lng: 19.435294 },
    { lat: 48.343894, lng: 19.435195 },
    { lat: 48.34386, lng: 19.435094 },
    { lat: 48.343808, lng: 19.434944 },
    { lat: 48.343635, lng: 19.434421 },
    { lat: 48.343619, lng: 19.434383 },
    { lat: 48.343318, lng: 19.433713 },
    { lat: 48.343175, lng: 19.433357 },
    { lat: 48.343027, lng: 19.432907 },
    { lat: 48.342836, lng: 19.432719 },
    { lat: 48.342751, lng: 19.432364 },
    { lat: 48.34268, lng: 19.431846 },
    { lat: 48.342656, lng: 19.431544 },
    { lat: 48.342662, lng: 19.431507 },
    { lat: 48.342648, lng: 19.431456 },
    { lat: 48.3417001, lng: 19.4322162 },
    { lat: 48.3408626, lng: 19.4325696 },
    { lat: 48.3406394, lng: 19.4325946 },
    { lat: 48.3403543, lng: 19.4325742 },
    { lat: 48.3395998, lng: 19.4323194 },
    { lat: 48.3391931, lng: 19.4323235 },
    { lat: 48.338087, lng: 19.4326018 },
    { lat: 48.3374609, lng: 19.4326877 },
    { lat: 48.3363847, lng: 19.4325675 },
    { lat: 48.3356683, lng: 19.432721 },
    { lat: 48.3341349, lng: 19.4328714 },
    { lat: 48.3321211, lng: 19.4326356 },
    { lat: 48.331805, lng: 19.4323882 },
    { lat: 48.330695, lng: 19.4325324 },
    { lat: 48.330304, lng: 19.4327257 },
    { lat: 48.328683, lng: 19.4330791 },
    { lat: 48.3262085, lng: 19.4332307 },
    { lat: 48.3254186, lng: 19.4335485 },
    { lat: 48.3249046, lng: 19.4339011 },
    { lat: 48.3242212, lng: 19.4342546 },
    { lat: 48.3239072, lng: 19.4343824 },
    { lat: 48.3233547, lng: 19.4344487 },
    { lat: 48.3225859, lng: 19.4346583 },
    { lat: 48.3222475, lng: 19.4348453 },
    { lat: 48.322234, lng: 19.4348492 },
  ],
  Trebušovce: [
    { lat: 48.1339958, lng: 19.2236514 },
    { lat: 48.1339755, lng: 19.2236035 },
    { lat: 48.1338024, lng: 19.2232018 },
    { lat: 48.1335191, lng: 19.2228959 },
    { lat: 48.1332299, lng: 19.222929 },
    { lat: 48.1329102, lng: 19.222547 },
    { lat: 48.1325896, lng: 19.2224688 },
    { lat: 48.1325505, lng: 19.2223179 },
    { lat: 48.1324175, lng: 19.2222022 },
    { lat: 48.1323855, lng: 19.2219171 },
    { lat: 48.1322038, lng: 19.2217514 },
    { lat: 48.1321485, lng: 19.2215009 },
    { lat: 48.1318726, lng: 19.221299 },
    { lat: 48.1317163, lng: 19.221007 },
    { lat: 48.1317733, lng: 19.2205858 },
    { lat: 48.1316381, lng: 19.2200809 },
    { lat: 48.1316313, lng: 19.2191867 },
    { lat: 48.1314614, lng: 19.2189891 },
    { lat: 48.1314434, lng: 19.2173361 },
    { lat: 48.1313638, lng: 19.21721 },
    { lat: 48.1314126, lng: 19.2171023 },
    { lat: 48.1309133, lng: 19.215222 },
    { lat: 48.1310447, lng: 19.2148932 },
    { lat: 48.1326457, lng: 19.2131101 },
    { lat: 48.1312594, lng: 19.2108021 },
    { lat: 48.1299955, lng: 19.2088542 },
    { lat: 48.1299644, lng: 19.2086339 },
    { lat: 48.1290937, lng: 19.2071816 },
    { lat: 48.1291183, lng: 19.2071111 },
    { lat: 48.1286821, lng: 19.2052149 },
    { lat: 48.1287466, lng: 19.2039156 },
    { lat: 48.1290038, lng: 19.2020663 },
    { lat: 48.1289783, lng: 19.2015489 },
    { lat: 48.1287784, lng: 19.2007862 },
    { lat: 48.1283546, lng: 19.1996222 },
    { lat: 48.1276664, lng: 19.1969655 },
    { lat: 48.1276518, lng: 19.1966206 },
    { lat: 48.1278058, lng: 19.196363 },
    { lat: 48.128013, lng: 19.1957403 },
    { lat: 48.1278647, lng: 19.1949049 },
    { lat: 48.1278001, lng: 19.1946251 },
    { lat: 48.1276618, lng: 19.1944516 },
    { lat: 48.1274122, lng: 19.1944181 },
    { lat: 48.1273229, lng: 19.1943471 },
    { lat: 48.1272711, lng: 19.1943937 },
    { lat: 48.1265128, lng: 19.1922996 },
    { lat: 48.1264367, lng: 19.1922649 },
    { lat: 48.1261114, lng: 19.1907017 },
    { lat: 48.1260306, lng: 19.1905045 },
    { lat: 48.125948, lng: 19.1904738 },
    { lat: 48.1253763, lng: 19.1881936 },
    { lat: 48.1250613, lng: 19.1867242 },
    { lat: 48.1246482, lng: 19.1843663 },
    { lat: 48.1246305, lng: 19.1832787 },
    { lat: 48.1246679, lng: 19.1823731 },
    { lat: 48.1248067, lng: 19.1813261 },
    { lat: 48.1252041, lng: 19.1795194 },
    { lat: 48.1252576, lng: 19.1788442 },
    { lat: 48.1246788, lng: 19.1771743 },
    { lat: 48.1247276, lng: 19.1753606 },
    { lat: 48.1243355, lng: 19.1747939 },
    { lat: 48.1242768, lng: 19.1746357 },
    { lat: 48.1232792, lng: 19.1741829 },
    { lat: 48.1241316, lng: 19.1729115 },
    { lat: 48.1244932, lng: 19.1720424 },
    { lat: 48.1246074, lng: 19.1711403 },
    { lat: 48.124607, lng: 19.1707702 },
    { lat: 48.1245117, lng: 19.1700066 },
    { lat: 48.1243861, lng: 19.1696793 },
    { lat: 48.1241699, lng: 19.1694548 },
    { lat: 48.1231899, lng: 19.1688573 },
    { lat: 48.1224062, lng: 19.1687172 },
    { lat: 48.1220431, lng: 19.1687835 },
    { lat: 48.1215443, lng: 19.1686602 },
    { lat: 48.1215059, lng: 19.1686348 },
    { lat: 48.1213021, lng: 19.1690271 },
    { lat: 48.1196141, lng: 19.1711998 },
    { lat: 48.1192298, lng: 19.1717719 },
    { lat: 48.117454, lng: 19.1729889 },
    { lat: 48.116779, lng: 19.1732251 },
    { lat: 48.1161329, lng: 19.1736966 },
    { lat: 48.1151819, lng: 19.17366 },
    { lat: 48.1147223, lng: 19.1733284 },
    { lat: 48.1139639, lng: 19.1733777 },
    { lat: 48.1130674, lng: 19.1733258 },
    { lat: 48.1121692, lng: 19.1734664 },
    { lat: 48.1113885, lng: 19.1735919 },
    { lat: 48.1098787, lng: 19.1742664 },
    { lat: 48.1089111, lng: 19.1748281 },
    { lat: 48.1087308, lng: 19.1749647 },
    { lat: 48.1078496, lng: 19.1761125 },
    { lat: 48.1069019, lng: 19.1781681 },
    { lat: 48.1061257, lng: 19.1791779 },
    { lat: 48.1047577, lng: 19.1808051 },
    { lat: 48.103257, lng: 19.1820739 },
    { lat: 48.1032498, lng: 19.1820853 },
    { lat: 48.1026122, lng: 19.1830307 },
    { lat: 48.1010097, lng: 19.1853401 },
    { lat: 48.1006482, lng: 19.1857115 },
    { lat: 48.0996667, lng: 19.1863798 },
    { lat: 48.0998249, lng: 19.1865683 },
    { lat: 48.1001558, lng: 19.1868108 },
    { lat: 48.1007152, lng: 19.187022 },
    { lat: 48.1011946, lng: 19.1873014 },
    { lat: 48.101437, lng: 19.1875741 },
    { lat: 48.1022824, lng: 19.1889385 },
    { lat: 48.1025358, lng: 19.1894332 },
    { lat: 48.1024799, lng: 19.1897423 },
    { lat: 48.1024771, lng: 19.1903259 },
    { lat: 48.1025994, lng: 19.1907209 },
    { lat: 48.1026328, lng: 19.1911475 },
    { lat: 48.1025444, lng: 19.1918446 },
    { lat: 48.1021902, lng: 19.1930023 },
    { lat: 48.1022169, lng: 19.1933612 },
    { lat: 48.1027022, lng: 19.1946973 },
    { lat: 48.1033866, lng: 19.1959995 },
    { lat: 48.103818, lng: 19.1971758 },
    { lat: 48.104189, lng: 19.2003317 },
    { lat: 48.1030823, lng: 19.2014565 },
    { lat: 48.102336, lng: 19.2020958 },
    { lat: 48.1023588, lng: 19.2021462 },
    { lat: 48.1026257, lng: 19.2028254 },
    { lat: 48.1029488, lng: 19.2033911 },
    { lat: 48.1031775, lng: 19.2040474 },
    { lat: 48.1036658, lng: 19.2050337 },
    { lat: 48.1039421, lng: 19.2052862 },
    { lat: 48.1042263, lng: 19.2053597 },
    { lat: 48.1043694, lng: 19.2055203 },
    { lat: 48.1051685, lng: 19.2069812 },
    { lat: 48.1054129, lng: 19.2075461 },
    { lat: 48.1060806, lng: 19.2086261 },
    { lat: 48.1049645, lng: 19.2097729 },
    { lat: 48.1047782, lng: 19.2094015 },
    { lat: 48.1046941, lng: 19.2094781 },
    { lat: 48.1044184, lng: 19.2099214 },
    { lat: 48.104359, lng: 19.210318 },
    { lat: 48.1043538, lng: 19.2104177 },
    { lat: 48.104593, lng: 19.2108344 },
    { lat: 48.1062856, lng: 19.2157001 },
    { lat: 48.106248, lng: 19.2159198 },
    { lat: 48.1082865, lng: 19.2191117 },
    { lat: 48.1083182, lng: 19.2191594 },
    { lat: 48.1084078, lng: 19.2190811 },
    { lat: 48.1091015, lng: 19.2189976 },
    { lat: 48.1093742, lng: 19.2188556 },
    { lat: 48.1096152, lng: 19.2186214 },
    { lat: 48.1115188, lng: 19.2193022 },
    { lat: 48.1118306, lng: 19.2192959 },
    { lat: 48.1128163, lng: 19.2196463 },
    { lat: 48.1135906, lng: 19.2201258 },
    { lat: 48.1147245, lng: 19.2212234 },
    { lat: 48.1158169, lng: 19.2220293 },
    { lat: 48.1168099, lng: 19.2229448 },
    { lat: 48.1168773, lng: 19.2227113 },
    { lat: 48.1170456, lng: 19.2224392 },
    { lat: 48.1170187, lng: 19.2221476 },
    { lat: 48.1170912, lng: 19.2220607 },
    { lat: 48.1174167, lng: 19.2220557 },
    { lat: 48.1175436, lng: 19.2218393 },
    { lat: 48.1175461, lng: 19.2216848 },
    { lat: 48.1176107, lng: 19.2216682 },
    { lat: 48.1177653, lng: 19.2216739 },
    { lat: 48.1178429, lng: 19.2218075 },
    { lat: 48.1179532, lng: 19.2218497 },
    { lat: 48.1180373, lng: 19.2217248 },
    { lat: 48.1180102, lng: 19.221586 },
    { lat: 48.1180671, lng: 19.2213896 },
    { lat: 48.1182014, lng: 19.2211671 },
    { lat: 48.1184135, lng: 19.2211459 },
    { lat: 48.1184402, lng: 19.2207236 },
    { lat: 48.1186749, lng: 19.220501 },
    { lat: 48.1187412, lng: 19.2203589 },
    { lat: 48.1189683, lng: 19.2203704 },
    { lat: 48.1192789, lng: 19.2200576 },
    { lat: 48.119442, lng: 19.2201667 },
    { lat: 48.1194664, lng: 19.2200921 },
    { lat: 48.1197505, lng: 19.2198881 },
    { lat: 48.1197527, lng: 19.2195876 },
    { lat: 48.1198011, lng: 19.2194952 },
    { lat: 48.1199816, lng: 19.2194531 },
    { lat: 48.1201321, lng: 19.2190969 },
    { lat: 48.1203674, lng: 19.2189723 },
    { lat: 48.1204032, lng: 19.2186918 },
    { lat: 48.1205164, lng: 19.2184543 },
    { lat: 48.1207516, lng: 19.2183995 },
    { lat: 48.1208753, lng: 19.2183207 },
    { lat: 48.120973, lng: 19.2181558 },
    { lat: 48.1210847, lng: 19.2181792 },
    { lat: 48.121221, lng: 19.2180387 },
    { lat: 48.121884, lng: 19.2189361 },
    { lat: 48.1220832, lng: 19.2190921 },
    { lat: 48.1235448, lng: 19.2196995 },
    { lat: 48.1245373, lng: 19.2203079 },
    { lat: 48.1249613, lng: 19.2206534 },
    { lat: 48.1257367, lng: 19.2210894 },
    { lat: 48.1270269, lng: 19.2224785 },
    { lat: 48.127516, lng: 19.2228836 },
    { lat: 48.1279015, lng: 19.2232983 },
    { lat: 48.1281168, lng: 19.2239394 },
    { lat: 48.1282064, lng: 19.2253738 },
    { lat: 48.1286157, lng: 19.2264125 },
    { lat: 48.1297138, lng: 19.2279499 },
    { lat: 48.1298587, lng: 19.2282841 },
    { lat: 48.1303417, lng: 19.2291006 },
    { lat: 48.1303496, lng: 19.2291213 },
    { lat: 48.1304493, lng: 19.2289738 },
    { lat: 48.13119, lng: 19.2278754 },
    { lat: 48.1315899, lng: 19.2272673 },
    { lat: 48.1332462, lng: 19.2248183 },
    { lat: 48.1334409, lng: 19.2244921 },
    { lat: 48.1339958, lng: 19.2236514 },
  ],
  VeľkéZlievce: [
    { lat: 48.208393, lng: 19.4330861 },
    { lat: 48.2075293, lng: 19.4344352 },
    { lat: 48.2068768, lng: 19.4355828 },
    { lat: 48.2064594, lng: 19.4356965 },
    { lat: 48.2059056, lng: 19.4354957 },
    { lat: 48.2056603, lng: 19.4353368 },
    { lat: 48.2053138, lng: 19.4353275 },
    { lat: 48.2048942, lng: 19.4352234 },
    { lat: 48.2043935, lng: 19.4354084 },
    { lat: 48.2036028, lng: 19.4355124 },
    { lat: 48.2024106, lng: 19.4358284 },
    { lat: 48.2024727, lng: 19.4361172 },
    { lat: 48.2024942, lng: 19.4365477 },
    { lat: 48.2020351, lng: 19.4383307 },
    { lat: 48.2018898, lng: 19.4385941 },
    { lat: 48.2017624, lng: 19.4389785 },
    { lat: 48.2015937, lng: 19.4397975 },
    { lat: 48.1992882, lng: 19.4417647 },
    { lat: 48.198545, lng: 19.4415647 },
    { lat: 48.1981904, lng: 19.441547 },
    { lat: 48.1972829, lng: 19.441736 },
    { lat: 48.1963989, lng: 19.4422553 },
    { lat: 48.1959883, lng: 19.4422689 },
    { lat: 48.1953569, lng: 19.4425753 },
    { lat: 48.1936911, lng: 19.4436263 },
    { lat: 48.1932156, lng: 19.4441292 },
    { lat: 48.1924299, lng: 19.445348 },
    { lat: 48.1913342, lng: 19.4462995 },
    { lat: 48.1906599, lng: 19.4474153 },
    { lat: 48.189748, lng: 19.4484675 },
    { lat: 48.1889699, lng: 19.4496525 },
    { lat: 48.1881758, lng: 19.450619 },
    { lat: 48.1879373, lng: 19.4509666 },
    { lat: 48.1879569, lng: 19.4509956 },
    { lat: 48.1862204, lng: 19.4531586 },
    { lat: 48.1840763, lng: 19.4559976 },
    { lat: 48.1835465, lng: 19.4556394 },
    { lat: 48.1826207, lng: 19.4573764 },
    { lat: 48.1825058, lng: 19.4574987 },
    { lat: 48.1806568, lng: 19.4563056 },
    { lat: 48.1804765, lng: 19.4567155 },
    { lat: 48.1795807, lng: 19.455977 },
    { lat: 48.1792443, lng: 19.4563019 },
    { lat: 48.1785945, lng: 19.4566708 },
    { lat: 48.1778037, lng: 19.4569647 },
    { lat: 48.1772937, lng: 19.4570734 },
    { lat: 48.1758354, lng: 19.45772 },
    { lat: 48.1750161, lng: 19.4579855 },
    { lat: 48.1744263, lng: 19.4637332 },
    { lat: 48.1733547, lng: 19.4643149 },
    { lat: 48.172382, lng: 19.4676007 },
    { lat: 48.1718674, lng: 19.4680183 },
    { lat: 48.1720471, lng: 19.4689174 },
    { lat: 48.1718341, lng: 19.4699954 },
    { lat: 48.1715889, lng: 19.4720992 },
    { lat: 48.1714338, lng: 19.4725889 },
    { lat: 48.1712526, lng: 19.472687 },
    { lat: 48.1708793, lng: 19.4730676 },
    { lat: 48.1705572, lng: 19.4738639 },
    { lat: 48.1703216, lng: 19.4740817 },
    { lat: 48.1701592, lng: 19.4743452 },
    { lat: 48.1695071, lng: 19.4766119 },
    { lat: 48.1694382, lng: 19.477017 },
    { lat: 48.1693808, lng: 19.477358 },
    { lat: 48.1701745, lng: 19.4775542 },
    { lat: 48.1704284, lng: 19.4775156 },
    { lat: 48.1766016, lng: 19.476087 },
    { lat: 48.1770393, lng: 19.4760683 },
    { lat: 48.1781784, lng: 19.4762064 },
    { lat: 48.1784612, lng: 19.4757012 },
    { lat: 48.1789166, lng: 19.4750775 },
    { lat: 48.1796078, lng: 19.4752286 },
    { lat: 48.1801534, lng: 19.4749914 },
    { lat: 48.1806497, lng: 19.4750954 },
    { lat: 48.1813236, lng: 19.4756032 },
    { lat: 48.1818249, lng: 19.4761948 },
    { lat: 48.1820404, lng: 19.4763237 },
    { lat: 48.1826428, lng: 19.4759506 },
    { lat: 48.1832112, lng: 19.475117 },
    { lat: 48.1840573, lng: 19.4749192 },
    { lat: 48.18489, lng: 19.47494 },
    { lat: 48.1850729, lng: 19.4749985 },
    { lat: 48.1860816, lng: 19.4763453 },
    { lat: 48.1865295, lng: 19.4779162 },
    { lat: 48.1873397, lng: 19.4798158 },
    { lat: 48.1877404, lng: 19.4813741 },
    { lat: 48.1881987, lng: 19.4835324 },
    { lat: 48.188569, lng: 19.4847029 },
    { lat: 48.1893332, lng: 19.4864863 },
    { lat: 48.1927004, lng: 19.484508 },
    { lat: 48.1929847, lng: 19.4852334 },
    { lat: 48.1933527, lng: 19.4855845 },
    { lat: 48.1938379, lng: 19.4858644 },
    { lat: 48.1939453, lng: 19.4858235 },
    { lat: 48.1940116, lng: 19.4859621 },
    { lat: 48.1941173, lng: 19.4860305 },
    { lat: 48.1941748, lng: 19.4859072 },
    { lat: 48.1947592, lng: 19.4861633 },
    { lat: 48.1948846, lng: 19.4858344 },
    { lat: 48.1950235, lng: 19.48584 },
    { lat: 48.1951394, lng: 19.4860059 },
    { lat: 48.1952701, lng: 19.4858817 },
    { lat: 48.1955085, lng: 19.4859715 },
    { lat: 48.1963562, lng: 19.4866716 },
    { lat: 48.1963633, lng: 19.4868485 },
    { lat: 48.1964626, lng: 19.4869462 },
    { lat: 48.1967067, lng: 19.4869599 },
    { lat: 48.1969889, lng: 19.4868022 },
    { lat: 48.1970967, lng: 19.4870364 },
    { lat: 48.1972258, lng: 19.4871214 },
    { lat: 48.1972902, lng: 19.4873425 },
    { lat: 48.1974426, lng: 19.4873955 },
    { lat: 48.1975501, lng: 19.4873101 },
    { lat: 48.197883, lng: 19.4873886 },
    { lat: 48.1984189, lng: 19.4877395 },
    { lat: 48.1989482, lng: 19.4878057 },
    { lat: 48.1998583, lng: 19.4874301 },
    { lat: 48.2003168, lng: 19.4873577 },
    { lat: 48.2004263, lng: 19.487264 },
    { lat: 48.2010319, lng: 19.487272 },
    { lat: 48.2012118, lng: 19.4869282 },
    { lat: 48.2015465, lng: 19.4869041 },
    { lat: 48.2018872, lng: 19.4872451 },
    { lat: 48.2023626, lng: 19.4869777 },
    { lat: 48.2033065, lng: 19.4871853 },
    { lat: 48.2042309, lng: 19.4892885 },
    { lat: 48.2043495, lng: 19.4899932 },
    { lat: 48.2043134, lng: 19.4903521 },
    { lat: 48.2043887, lng: 19.4907869 },
    { lat: 48.2049789, lng: 19.4915964 },
    { lat: 48.2048491, lng: 19.4920279 },
    { lat: 48.2097476, lng: 19.4963853 },
    { lat: 48.210531, lng: 19.4965113 },
    { lat: 48.2110805, lng: 19.4956551 },
    { lat: 48.2126396, lng: 19.4983023 },
    { lat: 48.2137808, lng: 19.4995955 },
    { lat: 48.2154956, lng: 19.5025879 },
    { lat: 48.2155166, lng: 19.5026277 },
    { lat: 48.215746, lng: 19.5025187 },
    { lat: 48.2157883, lng: 19.502215 },
    { lat: 48.216193, lng: 19.5020766 },
    { lat: 48.2165172, lng: 19.5015841 },
    { lat: 48.2165176, lng: 19.5012523 },
    { lat: 48.2165683, lng: 19.5010841 },
    { lat: 48.2169992, lng: 19.5011377 },
    { lat: 48.2170944, lng: 19.5010076 },
    { lat: 48.2171247, lng: 19.5006642 },
    { lat: 48.2174954, lng: 19.5006623 },
    { lat: 48.2176067, lng: 19.5002955 },
    { lat: 48.2178646, lng: 19.4999749 },
    { lat: 48.2179649, lng: 19.5000253 },
    { lat: 48.2180478, lng: 19.4997007 },
    { lat: 48.2179163, lng: 19.4996875 },
    { lat: 48.2178703, lng: 19.4996167 },
    { lat: 48.2179225, lng: 19.4994304 },
    { lat: 48.2180023, lng: 19.4994033 },
    { lat: 48.2180906, lng: 19.49951 },
    { lat: 48.2181879, lng: 19.4993486 },
    { lat: 48.2183758, lng: 19.4995339 },
    { lat: 48.2185721, lng: 19.4994382 },
    { lat: 48.218651, lng: 19.4992974 },
    { lat: 48.2187936, lng: 19.499261 },
    { lat: 48.2187542, lng: 19.498993 },
    { lat: 48.218781, lng: 19.4989195 },
    { lat: 48.2188847, lng: 19.4988228 },
    { lat: 48.2189685, lng: 19.4988787 },
    { lat: 48.2190257, lng: 19.4986569 },
    { lat: 48.2194138, lng: 19.4983237 },
    { lat: 48.2197697, lng: 19.4984417 },
    { lat: 48.2198399, lng: 19.4984019 },
    { lat: 48.220052, lng: 19.4985239 },
    { lat: 48.2201862, lng: 19.4984102 },
    { lat: 48.2203493, lng: 19.4980628 },
    { lat: 48.2204149, lng: 19.4980924 },
    { lat: 48.2204624, lng: 19.4979266 },
    { lat: 48.220559, lng: 19.4980625 },
    { lat: 48.2206859, lng: 19.4980291 },
    { lat: 48.2208243, lng: 19.4977619 },
    { lat: 48.2208014, lng: 19.4974625 },
    { lat: 48.2208953, lng: 19.4973499 },
    { lat: 48.220899, lng: 19.4972364 },
    { lat: 48.2207555, lng: 19.497227 },
    { lat: 48.2206691, lng: 19.4971375 },
    { lat: 48.2207105, lng: 19.4969617 },
    { lat: 48.2207895, lng: 19.4968683 },
    { lat: 48.2212038, lng: 19.4967134 },
    { lat: 48.2211937, lng: 19.4964501 },
    { lat: 48.2212501, lng: 19.4964492 },
    { lat: 48.2213567, lng: 19.4967055 },
    { lat: 48.2214526, lng: 19.4968075 },
    { lat: 48.2215065, lng: 19.4967058 },
    { lat: 48.2215167, lng: 19.4963188 },
    { lat: 48.2215975, lng: 19.4963136 },
    { lat: 48.2216005, lng: 19.4964224 },
    { lat: 48.2216883, lng: 19.4964225 },
    { lat: 48.2217156, lng: 19.4961878 },
    { lat: 48.2217813, lng: 19.4960459 },
    { lat: 48.221729, lng: 19.4958662 },
    { lat: 48.2217592, lng: 19.4957447 },
    { lat: 48.2219363, lng: 19.4957452 },
    { lat: 48.2218142, lng: 19.4954502 },
    { lat: 48.2219187, lng: 19.4952258 },
    { lat: 48.2221594, lng: 19.4952632 },
    { lat: 48.2221659, lng: 19.4950424 },
    { lat: 48.2222823, lng: 19.4950977 },
    { lat: 48.2224242, lng: 19.4949504 },
    { lat: 48.2224099, lng: 19.4945673 },
    { lat: 48.2226857, lng: 19.4946576 },
    { lat: 48.2230589, lng: 19.49435 },
    { lat: 48.2229193, lng: 19.4940353 },
    { lat: 48.2231327, lng: 19.4940239 },
    { lat: 48.2232208, lng: 19.4938313 },
    { lat: 48.2232784, lng: 19.4934733 },
    { lat: 48.223391, lng: 19.4934007 },
    { lat: 48.2236172, lng: 19.4934661 },
    { lat: 48.2236882, lng: 19.4932262 },
    { lat: 48.2236721, lng: 19.4930232 },
    { lat: 48.2237532, lng: 19.4929985 },
    { lat: 48.2237352, lng: 19.492876 },
    { lat: 48.2237867, lng: 19.4927762 },
    { lat: 48.2238837, lng: 19.4927488 },
    { lat: 48.2238893, lng: 19.4926319 },
    { lat: 48.2240803, lng: 19.4923773 },
    { lat: 48.2241784, lng: 19.4921376 },
    { lat: 48.2241287, lng: 19.4918916 },
    { lat: 48.2241986, lng: 19.4918199 },
    { lat: 48.2242264, lng: 19.4914498 },
    { lat: 48.2244632, lng: 19.491019 },
    { lat: 48.2244314, lng: 19.4907014 },
    { lat: 48.224544, lng: 19.4905034 },
    { lat: 48.2244721, lng: 19.4902423 },
    { lat: 48.2245081, lng: 19.4901438 },
    { lat: 48.2247099, lng: 19.4898329 },
    { lat: 48.2248483, lng: 19.4900195 },
    { lat: 48.2250709, lng: 19.4898422 },
    { lat: 48.2250909, lng: 19.4896432 },
    { lat: 48.2252153, lng: 19.489703 },
    { lat: 48.2253306, lng: 19.4895372 },
    { lat: 48.2253513, lng: 19.4892813 },
    { lat: 48.2254599, lng: 19.4891943 },
    { lat: 48.2254329, lng: 19.4889759 },
    { lat: 48.2256917, lng: 19.4888491 },
    { lat: 48.225616, lng: 19.4886733 },
    { lat: 48.2256936, lng: 19.4884807 },
    { lat: 48.2257544, lng: 19.488527 },
    { lat: 48.2258101, lng: 19.48839 },
    { lat: 48.2258179, lng: 19.4881492 },
    { lat: 48.2258819, lng: 19.4880923 },
    { lat: 48.2258942, lng: 19.4878511 },
    { lat: 48.2261116, lng: 19.4877272 },
    { lat: 48.2261866, lng: 19.4875978 },
    { lat: 48.2261766, lng: 19.4873327 },
    { lat: 48.2262605, lng: 19.4872246 },
    { lat: 48.2262405, lng: 19.4870832 },
    { lat: 48.2262165, lng: 19.4870564 },
    { lat: 48.2261042, lng: 19.4872099 },
    { lat: 48.2260028, lng: 19.4871113 },
    { lat: 48.2260013, lng: 19.4868372 },
    { lat: 48.2238706, lng: 19.4845102 },
    { lat: 48.223095, lng: 19.483827 },
    { lat: 48.2230679, lng: 19.483803 },
    { lat: 48.2221362, lng: 19.4827972 },
    { lat: 48.2217737, lng: 19.4825641 },
    { lat: 48.2215705, lng: 19.4821941 },
    { lat: 48.2213857, lng: 19.4816413 },
    { lat: 48.2211985, lng: 19.481445 },
    { lat: 48.2211598, lng: 19.4813197 },
    { lat: 48.2208707, lng: 19.4809708 },
    { lat: 48.220471, lng: 19.4807363 },
    { lat: 48.2200855, lng: 19.4803505 },
    { lat: 48.2196208, lng: 19.4795235 },
    { lat: 48.2189785, lng: 19.4794748 },
    { lat: 48.218873, lng: 19.4796262 },
    { lat: 48.2187515, lng: 19.4795389 },
    { lat: 48.2185955, lng: 19.4796726 },
    { lat: 48.2179018, lng: 19.4793933 },
    { lat: 48.2178535, lng: 19.4795411 },
    { lat: 48.217608, lng: 19.4793269 },
    { lat: 48.2173805, lng: 19.4792832 },
    { lat: 48.2170117, lng: 19.4796134 },
    { lat: 48.2166636, lng: 19.4794015 },
    { lat: 48.21646, lng: 19.4789518 },
    { lat: 48.2162664, lng: 19.477943 },
    { lat: 48.2161647, lng: 19.476831 },
    { lat: 48.2160849, lng: 19.4750818 },
    { lat: 48.2162083, lng: 19.4745642 },
    { lat: 48.2163633, lng: 19.4742133 },
    { lat: 48.2168848, lng: 19.473584 },
    { lat: 48.2168612, lng: 19.4732006 },
    { lat: 48.2167801, lng: 19.4731996 },
    { lat: 48.2168325, lng: 19.4721552 },
    { lat: 48.2169618, lng: 19.4712811 },
    { lat: 48.2175935, lng: 19.4685302 },
    { lat: 48.2176122, lng: 19.4673883 },
    { lat: 48.2175398, lng: 19.4669775 },
    { lat: 48.2177656, lng: 19.466 },
    { lat: 48.2177719, lng: 19.465677 },
    { lat: 48.2176872, lng: 19.4653106 },
    { lat: 48.217547, lng: 19.4650151 },
    { lat: 48.2173046, lng: 19.4646766 },
    { lat: 48.2168235, lng: 19.4642737 },
    { lat: 48.2170474, lng: 19.4637604 },
    { lat: 48.2176114, lng: 19.4631668 },
    { lat: 48.2178847, lng: 19.4627713 },
    { lat: 48.2182013, lng: 19.4619783 },
    { lat: 48.2178619, lng: 19.4612291 },
    { lat: 48.2176212, lng: 19.4609049 },
    { lat: 48.2171911, lng: 19.4606476 },
    { lat: 48.2170614, lng: 19.460405 },
    { lat: 48.2170289, lng: 19.4597381 },
    { lat: 48.2172516, lng: 19.4594677 },
    { lat: 48.2171449, lng: 19.4592563 },
    { lat: 48.2172428, lng: 19.4589883 },
    { lat: 48.2171433, lng: 19.4588154 },
    { lat: 48.2172772, lng: 19.4584289 },
    { lat: 48.2171382, lng: 19.4581088 },
    { lat: 48.217085, lng: 19.4573833 },
    { lat: 48.2176946, lng: 19.4565148 },
    { lat: 48.2177565, lng: 19.4560136 },
    { lat: 48.218085, lng: 19.4555663 },
    { lat: 48.2181994, lng: 19.455268 },
    { lat: 48.2183424, lng: 19.455048 },
    { lat: 48.2185635, lng: 19.4548639 },
    { lat: 48.2189471, lng: 19.4548695 },
    { lat: 48.219264, lng: 19.454734 },
    { lat: 48.2195394, lng: 19.4543081 },
    { lat: 48.2198331, lng: 19.4539885 },
    { lat: 48.2198331, lng: 19.4535322 },
    { lat: 48.2199655, lng: 19.4530882 },
    { lat: 48.2202104, lng: 19.4528762 },
    { lat: 48.2204565, lng: 19.4529824 },
    { lat: 48.2210403, lng: 19.4525064 },
    { lat: 48.2213564, lng: 19.4521561 },
    { lat: 48.2215814, lng: 19.4518143 },
    { lat: 48.2219686, lng: 19.450702 },
    { lat: 48.2224503, lng: 19.4499416 },
    { lat: 48.2231923, lng: 19.4483709 },
    { lat: 48.2234167, lng: 19.4482069 },
    { lat: 48.2240672, lng: 19.4481179 },
    { lat: 48.2241364, lng: 19.4478618 },
    { lat: 48.2240585, lng: 19.4477622 },
    { lat: 48.2239844, lng: 19.4479135 },
    { lat: 48.2238969, lng: 19.4478725 },
    { lat: 48.2238031, lng: 19.4477258 },
    { lat: 48.2238396, lng: 19.4475933 },
    { lat: 48.2240953, lng: 19.4475413 },
    { lat: 48.2241795, lng: 19.447413 },
    { lat: 48.224136, lng: 19.4472758 },
    { lat: 48.223957, lng: 19.4472371 },
    { lat: 48.2238489, lng: 19.4473343 },
    { lat: 48.2236619, lng: 19.4472844 },
    { lat: 48.2236139, lng: 19.4471775 },
    { lat: 48.2236315, lng: 19.4469745 },
    { lat: 48.2237494, lng: 19.4467744 },
    { lat: 48.223742, lng: 19.4464963 },
    { lat: 48.2236859, lng: 19.4464538 },
    { lat: 48.2235793, lng: 19.4466075 },
    { lat: 48.2233204, lng: 19.4466627 },
    { lat: 48.2232472, lng: 19.4468581 },
    { lat: 48.2230765, lng: 19.4468439 },
    { lat: 48.2229212, lng: 19.4463683 },
    { lat: 48.2230021, lng: 19.446222 },
    { lat: 48.2232627, lng: 19.4460835 },
    { lat: 48.2232885, lng: 19.4461805 },
    { lat: 48.223398, lng: 19.4460869 },
    { lat: 48.2235225, lng: 19.4457875 },
    { lat: 48.2235271, lng: 19.4456446 },
    { lat: 48.223389, lng: 19.4455355 },
    { lat: 48.2228069, lng: 19.4459089 },
    { lat: 48.2226811, lng: 19.4458451 },
    { lat: 48.2226037, lng: 19.4456356 },
    { lat: 48.2224619, lng: 19.4456431 },
    { lat: 48.2223672, lng: 19.4455428 },
    { lat: 48.2223103, lng: 19.4453633 },
    { lat: 48.2223201, lng: 19.4449491 },
    { lat: 48.2220636, lng: 19.4446475 },
    { lat: 48.2219261, lng: 19.4446012 },
    { lat: 48.222012, lng: 19.4442938 },
    { lat: 48.2221851, lng: 19.444283 },
    { lat: 48.2221791, lng: 19.4436288 },
    { lat: 48.2220618, lng: 19.4433662 },
    { lat: 48.2219912, lng: 19.443421 },
    { lat: 48.2219049, lng: 19.4437694 },
    { lat: 48.2217621, lng: 19.444018 },
    { lat: 48.2217029, lng: 19.44429 },
    { lat: 48.2215217, lng: 19.4444389 },
    { lat: 48.2214224, lng: 19.4444366 },
    { lat: 48.2213238, lng: 19.4443039 },
    { lat: 48.2213387, lng: 19.4441878 },
    { lat: 48.2216015, lng: 19.4439063 },
    { lat: 48.2216458, lng: 19.4437702 },
    { lat: 48.221568, lng: 19.4436522 },
    { lat: 48.2212471, lng: 19.443562 },
    { lat: 48.2210642, lng: 19.443746 },
    { lat: 48.2209911, lng: 19.4437484 },
    { lat: 48.221059, lng: 19.4431581 },
    { lat: 48.2214262, lng: 19.4427667 },
    { lat: 48.2216174, lng: 19.4427161 },
    { lat: 48.2215958, lng: 19.4424714 },
    { lat: 48.2216663, lng: 19.4423904 },
    { lat: 48.2218271, lng: 19.4422837 },
    { lat: 48.2219491, lng: 19.4423362 },
    { lat: 48.2223271, lng: 19.4427296 },
    { lat: 48.2224844, lng: 19.4426501 },
    { lat: 48.2226785, lng: 19.4421528 },
    { lat: 48.2227271, lng: 19.4418409 },
    { lat: 48.2223789, lng: 19.4416755 },
    { lat: 48.2222155, lng: 19.4418698 },
    { lat: 48.2220134, lng: 19.4420021 },
    { lat: 48.2218906, lng: 19.4417914 },
    { lat: 48.2217393, lng: 19.4418345 },
    { lat: 48.2216725, lng: 19.4415645 },
    { lat: 48.2215821, lng: 19.4414154 },
    { lat: 48.2216491, lng: 19.4413525 },
    { lat: 48.2219566, lng: 19.4415186 },
    { lat: 48.2221453, lng: 19.4414337 },
    { lat: 48.2222963, lng: 19.4412156 },
    { lat: 48.2224338, lng: 19.4411901 },
    { lat: 48.2226193, lng: 19.4409415 },
    { lat: 48.2225973, lng: 19.4404749 },
    { lat: 48.2223782, lng: 19.4402969 },
    { lat: 48.2222687, lng: 19.4403183 },
    { lat: 48.2221915, lng: 19.4406658 },
    { lat: 48.2212515, lng: 19.4405943 },
    { lat: 48.2211645, lng: 19.440947 },
    { lat: 48.2204991, lng: 19.4411504 },
    { lat: 48.2199527, lng: 19.4408907 },
    { lat: 48.2197437, lng: 19.4406463 },
    { lat: 48.219299, lng: 19.4408223 },
    { lat: 48.2192948, lng: 19.441068 },
    { lat: 48.2189848, lng: 19.4410901 },
    { lat: 48.2177367, lng: 19.440721 },
    { lat: 48.2168854, lng: 19.4409282 },
    { lat: 48.2159839, lng: 19.4406198 },
    { lat: 48.2151776, lng: 19.4401285 },
    { lat: 48.2149037, lng: 19.4400595 },
    { lat: 48.2146215, lng: 19.4402399 },
    { lat: 48.214509, lng: 19.4399333 },
    { lat: 48.2140003, lng: 19.439398 },
    { lat: 48.2131048, lng: 19.4389328 },
    { lat: 48.2127119, lng: 19.4386283 },
    { lat: 48.212485, lng: 19.4385251 },
    { lat: 48.2123421, lng: 19.4383631 },
    { lat: 48.2121702, lng: 19.4379194 },
    { lat: 48.2118314, lng: 19.4376919 },
    { lat: 48.211538, lng: 19.4370841 },
    { lat: 48.2111991, lng: 19.4368082 },
    { lat: 48.2110516, lng: 19.4366192 },
    { lat: 48.2105712, lng: 19.4367092 },
    { lat: 48.2103263, lng: 19.4366548 },
    { lat: 48.2098875, lng: 19.4367153 },
    { lat: 48.2093862, lng: 19.4368866 },
    { lat: 48.2088694, lng: 19.436685 },
    { lat: 48.2086763, lng: 19.4364867 },
    { lat: 48.2084587, lng: 19.4364839 },
    { lat: 48.2082633, lng: 19.4362575 },
    { lat: 48.208067, lng: 19.4347748 },
    { lat: 48.208393, lng: 19.4330861 },
  ],
  SuchéBrezovo: [
    { lat: 48.281249, lng: 19.3888533 },
    { lat: 48.2816734, lng: 19.3886488 },
    { lat: 48.2819032, lng: 19.3886741 },
    { lat: 48.2822958, lng: 19.3889634 },
    { lat: 48.2825119, lng: 19.3889574 },
    { lat: 48.2826952, lng: 19.3886647 },
    { lat: 48.2827537, lng: 19.388375 },
    { lat: 48.2829029, lng: 19.3883152 },
    { lat: 48.283263, lng: 19.3883971 },
    { lat: 48.2836022, lng: 19.3883248 },
    { lat: 48.2839282, lng: 19.3879538 },
    { lat: 48.2841074, lng: 19.3879775 },
    { lat: 48.2843328, lng: 19.3881184 },
    { lat: 48.2848551, lng: 19.3885517 },
    { lat: 48.2851578, lng: 19.3883311 },
    { lat: 48.2853269, lng: 19.3879291 },
    { lat: 48.2855446, lng: 19.387765 },
    { lat: 48.2859805, lng: 19.3879967 },
    { lat: 48.2864529, lng: 19.387907 },
    { lat: 48.2873139, lng: 19.3874538 },
    { lat: 48.2881943, lng: 19.3867451 },
    { lat: 48.2882765, lng: 19.3864136 },
    { lat: 48.2885727, lng: 19.385981 },
    { lat: 48.2889545, lng: 19.3858305 },
    { lat: 48.2891585, lng: 19.3855961 },
    { lat: 48.2893841, lng: 19.3850995 },
    { lat: 48.2898472, lng: 19.384435 },
    { lat: 48.2900353, lng: 19.3840794 },
    { lat: 48.2902512, lng: 19.3840188 },
    { lat: 48.290404, lng: 19.3840796 },
    { lat: 48.2905821, lng: 19.3840358 },
    { lat: 48.290867, lng: 19.3835545 },
    { lat: 48.2910425, lng: 19.3834997 },
    { lat: 48.2916371, lng: 19.3838708 },
    { lat: 48.2916626, lng: 19.3838114 },
    { lat: 48.2913748, lng: 19.383043 },
    { lat: 48.2913978, lng: 19.3828672 },
    { lat: 48.2915683, lng: 19.3827767 },
    { lat: 48.2916407, lng: 19.3828295 },
    { lat: 48.2917907, lng: 19.3831879 },
    { lat: 48.2919784, lng: 19.3833208 },
    { lat: 48.2926531, lng: 19.3829567 },
    { lat: 48.292837, lng: 19.3827704 },
    { lat: 48.2931021, lng: 19.3823963 },
    { lat: 48.2932545, lng: 19.3819751 },
    { lat: 48.2935422, lng: 19.3819302 },
    { lat: 48.2942241, lng: 19.3821699 },
    { lat: 48.2944953, lng: 19.3821129 },
    { lat: 48.294949, lng: 19.3812989 },
    { lat: 48.2952092, lng: 19.3809398 },
    { lat: 48.2958163, lng: 19.3805992 },
    { lat: 48.2960159, lng: 19.3805754 },
    { lat: 48.2963165, lng: 19.3806869 },
    { lat: 48.2964, lng: 19.3806649 },
    { lat: 48.2968506, lng: 19.3803523 },
    { lat: 48.2971401, lng: 19.380257 },
    { lat: 48.2976707, lng: 19.3802243 },
    { lat: 48.2982088, lng: 19.3798104 },
    { lat: 48.2982865, lng: 19.3798099 },
    { lat: 48.2987435, lng: 19.3802024 },
    { lat: 48.2992233, lng: 19.3802393 },
    { lat: 48.2995786, lng: 19.3801758 },
    { lat: 48.3000137, lng: 19.3803668 },
    { lat: 48.3001729, lng: 19.3803466 },
    { lat: 48.3005906, lng: 19.3799816 },
    { lat: 48.3011975, lng: 19.3796884 },
    { lat: 48.3013078, lng: 19.379707 },
    { lat: 48.3015418, lng: 19.3799344 },
    { lat: 48.3016575, lng: 19.3799231 },
    { lat: 48.3019561, lng: 19.3795241 },
    { lat: 48.3021053, lng: 19.379482 },
    { lat: 48.3025636, lng: 19.3797565 },
    { lat: 48.3027144, lng: 19.379968 },
    { lat: 48.3029591, lng: 19.380133 },
    { lat: 48.3031349, lng: 19.3801096 },
    { lat: 48.3035671, lng: 19.3797423 },
    { lat: 48.3040767, lng: 19.3798153 },
    { lat: 48.3048409, lng: 19.3794606 },
    { lat: 48.3053082, lng: 19.3793845 },
    { lat: 48.3054946, lng: 19.3792021 },
    { lat: 48.3057379, lng: 19.3787261 },
    { lat: 48.3059566, lng: 19.3785136 },
    { lat: 48.3069048, lng: 19.3781878 },
    { lat: 48.3073881, lng: 19.3778033 },
    { lat: 48.3081285, lng: 19.3775652 },
    { lat: 48.3083793, lng: 19.3771967 },
    { lat: 48.3085035, lng: 19.3767805 },
    { lat: 48.3086217, lng: 19.376633 },
    { lat: 48.3094182, lng: 19.3760793 },
    { lat: 48.3098464, lng: 19.3754844 },
    { lat: 48.3100238, lng: 19.3753999 },
    { lat: 48.3101621, lng: 19.375463 },
    { lat: 48.3101805, lng: 19.3753775 },
    { lat: 48.3115551, lng: 19.3744908 },
    { lat: 48.3132432, lng: 19.3732581 },
    { lat: 48.3144051, lng: 19.37174 },
    { lat: 48.3146445, lng: 19.3713458 },
    { lat: 48.3149707, lng: 19.3702458 },
    { lat: 48.3154559, lng: 19.3692183 },
    { lat: 48.3157882, lng: 19.3679342 },
    { lat: 48.3160339, lng: 19.3657365 },
    { lat: 48.3167716, lng: 19.3651093 },
    { lat: 48.317503, lng: 19.364851 },
    { lat: 48.3181462, lng: 19.3645107 },
    { lat: 48.3197655, lng: 19.3643385 },
    { lat: 48.3206125, lng: 19.3645182 },
    { lat: 48.3210088, lng: 19.3633251 },
    { lat: 48.3217086, lng: 19.3605823 },
    { lat: 48.3218944, lng: 19.3601817 },
    { lat: 48.3220839, lng: 19.3599939 },
    { lat: 48.3224015, lng: 19.3598255 },
    { lat: 48.3236349, lng: 19.359311 },
    { lat: 48.3240918, lng: 19.3584485 },
    { lat: 48.3242455, lng: 19.3579577 },
    { lat: 48.3242603, lng: 19.3575152 },
    { lat: 48.3243367, lng: 19.3571079 },
    { lat: 48.3247835, lng: 19.3555604 },
    { lat: 48.3250753, lng: 19.3549691 },
    { lat: 48.3251026, lng: 19.3545019 },
    { lat: 48.3255021, lng: 19.3527203 },
    { lat: 48.3257704, lng: 19.3521567 },
    { lat: 48.3276062, lng: 19.3496525 },
    { lat: 48.3285442, lng: 19.3472051 },
    { lat: 48.3291669, lng: 19.3461371 },
    { lat: 48.329355, lng: 19.345343 },
    { lat: 48.328929, lng: 19.345207 },
    { lat: 48.328513, lng: 19.345009 },
    { lat: 48.328303, lng: 19.3446 },
    { lat: 48.328079, lng: 19.344482 },
    { lat: 48.327579, lng: 19.343673 },
    { lat: 48.327271, lng: 19.343293 },
    { lat: 48.326998, lng: 19.342714 },
    { lat: 48.326909, lng: 19.3425 },
    { lat: 48.326865, lng: 19.34237 },
    { lat: 48.326812, lng: 19.34218 },
    { lat: 48.326501, lng: 19.341764 },
    { lat: 48.326445, lng: 19.341691 },
    { lat: 48.326386, lng: 19.34163 },
    { lat: 48.326229, lng: 19.341466 },
    { lat: 48.326198, lng: 19.341382 },
    { lat: 48.325964, lng: 19.341371 },
    { lat: 48.325483, lng: 19.341242 },
    { lat: 48.324703, lng: 19.340393 },
    { lat: 48.324968, lng: 19.339546 },
    { lat: 48.325821, lng: 19.337624 },
    { lat: 48.325643, lng: 19.337241 },
    { lat: 48.325563, lng: 19.336934 },
    { lat: 48.325533, lng: 19.336797 },
    { lat: 48.325497, lng: 19.336627 },
    { lat: 48.32504, lng: 19.336584 },
    { lat: 48.323592, lng: 19.336429 },
    { lat: 48.322719, lng: 19.336664 },
    { lat: 48.321959, lng: 19.336808 },
    { lat: 48.321466, lng: 19.336915 },
    { lat: 48.320902, lng: 19.337294 },
    { lat: 48.320406, lng: 19.337448 },
    { lat: 48.319976, lng: 19.337503 },
    { lat: 48.31955, lng: 19.337677 },
    { lat: 48.318577, lng: 19.33785 },
    { lat: 48.318137, lng: 19.337857 },
    { lat: 48.317732, lng: 19.337803 },
    { lat: 48.317258, lng: 19.337892 },
    { lat: 48.316871, lng: 19.337943 },
    { lat: 48.316479, lng: 19.337992 },
    { lat: 48.315577, lng: 19.337507 },
    { lat: 48.314777, lng: 19.337116 },
    { lat: 48.314381, lng: 19.336967 },
    { lat: 48.313791, lng: 19.336862 },
    { lat: 48.312901, lng: 19.336797 },
    { lat: 48.312229, lng: 19.336721 },
    { lat: 48.311312, lng: 19.336919 },
    { lat: 48.310941, lng: 19.337024 },
    { lat: 48.310361, lng: 19.337073 },
    { lat: 48.309555, lng: 19.337172 },
    { lat: 48.309001, lng: 19.337198 },
    { lat: 48.308489, lng: 19.337185 },
    { lat: 48.308004, lng: 19.337133 },
    { lat: 48.307402, lng: 19.337282 },
    { lat: 48.307007, lng: 19.337374 },
    { lat: 48.306837, lng: 19.337406 },
    { lat: 48.30591, lng: 19.337598 },
    { lat: 48.30513, lng: 19.337818 },
    { lat: 48.304187, lng: 19.338186 },
    { lat: 48.303713, lng: 19.338333 },
    { lat: 48.303151, lng: 19.338646 },
    { lat: 48.301785, lng: 19.33946 },
    { lat: 48.300624, lng: 19.339685 },
    { lat: 48.300079, lng: 19.339765 },
    { lat: 48.299192, lng: 19.340369 },
    { lat: 48.298266, lng: 19.341294 },
    { lat: 48.297379, lng: 19.342222 },
    { lat: 48.297304, lng: 19.342288 },
    { lat: 48.296575, lng: 19.342939 },
    { lat: 48.295841, lng: 19.343682 },
    { lat: 48.295831, lng: 19.343627 },
    { lat: 48.29579, lng: 19.343639 },
    { lat: 48.295759, lng: 19.343691 },
    { lat: 48.295228, lng: 19.344746 },
    { lat: 48.294577, lng: 19.34601 },
    { lat: 48.294387, lng: 19.346432 },
    { lat: 48.292969, lng: 19.346573 },
    { lat: 48.291359, lng: 19.346254 },
    { lat: 48.291163, lng: 19.346245 },
    { lat: 48.290674, lng: 19.346466 },
    { lat: 48.290245, lng: 19.346732 },
    { lat: 48.290213, lng: 19.346829 },
    { lat: 48.289935, lng: 19.347674 },
    { lat: 48.289661, lng: 19.348653 },
    { lat: 48.289538, lng: 19.349589 },
    { lat: 48.289465, lng: 19.350133 },
    { lat: 48.289081, lng: 19.351321 },
    { lat: 48.288762, lng: 19.352853 },
    { lat: 48.28885, lng: 19.353974 },
    { lat: 48.2885, lng: 19.354414 },
    { lat: 48.287684, lng: 19.355427 },
    { lat: 48.287641, lng: 19.355479 },
    { lat: 48.287118, lng: 19.355759 },
    { lat: 48.287031, lng: 19.355801 },
    { lat: 48.286686, lng: 19.355967 },
    { lat: 48.286484, lng: 19.356124 },
    { lat: 48.286467, lng: 19.356145 },
    { lat: 48.286124, lng: 19.3566095 },
    { lat: 48.2864419, lng: 19.357716 },
    { lat: 48.2868986, lng: 19.3592807 },
    { lat: 48.2874913, lng: 19.3617981 },
    { lat: 48.288965, lng: 19.3663934 },
    { lat: 48.2898346, lng: 19.3700913 },
    { lat: 48.28164, lng: 19.3805775 },
    { lat: 48.281249, lng: 19.3888533 },
  ],
  MaléStraciny: [
    { lat: 48.2134769, lng: 19.3855908 },
    { lat: 48.2134361, lng: 19.3856814 },
    { lat: 48.2132802, lng: 19.3858846 },
    { lat: 48.2129939, lng: 19.3865225 },
    { lat: 48.212917, lng: 19.3867576 },
    { lat: 48.213007, lng: 19.3868922 },
    { lat: 48.2129998, lng: 19.3869802 },
    { lat: 48.212678, lng: 19.3875573 },
    { lat: 48.2122541, lng: 19.3881735 },
    { lat: 48.211907, lng: 19.3885066 },
    { lat: 48.2119446, lng: 19.3889839 },
    { lat: 48.2115927, lng: 19.3893369 },
    { lat: 48.2112212, lng: 19.3893723 },
    { lat: 48.2111575, lng: 19.3894536 },
    { lat: 48.2108738, lng: 19.3894365 },
    { lat: 48.2105038, lng: 19.3902084 },
    { lat: 48.2101505, lng: 19.3906266 },
    { lat: 48.2097164, lng: 19.3914491 },
    { lat: 48.2095589, lng: 19.391574 },
    { lat: 48.2090998, lng: 19.3921611 },
    { lat: 48.2084546, lng: 19.3928622 },
    { lat: 48.2083205, lng: 19.3932391 },
    { lat: 48.2082345, lng: 19.3938506 },
    { lat: 48.208371, lng: 19.394253 },
    { lat: 48.2082446, lng: 19.3944389 },
    { lat: 48.2079111, lng: 19.3946779 },
    { lat: 48.2074169, lng: 19.3943832 },
    { lat: 48.2072448, lng: 19.3946284 },
    { lat: 48.2070869, lng: 19.3951215 },
    { lat: 48.2071079, lng: 19.3960403 },
    { lat: 48.207006, lng: 19.3963353 },
    { lat: 48.2071921, lng: 19.3966741 },
    { lat: 48.2073112, lng: 19.3971176 },
    { lat: 48.2072893, lng: 19.3973909 },
    { lat: 48.2072013, lng: 19.3975525 },
    { lat: 48.2071724, lng: 19.3977288 },
    { lat: 48.2067637, lng: 19.3978068 },
    { lat: 48.2066683, lng: 19.3976979 },
    { lat: 48.20621, lng: 19.397994 },
    { lat: 48.2055145, lng: 19.3988446 },
    { lat: 48.2052239, lng: 19.3990617 },
    { lat: 48.2047044, lng: 19.3997744 },
    { lat: 48.2045897, lng: 19.3998255 },
    { lat: 48.2043976, lng: 19.4000759 },
    { lat: 48.2041652, lng: 19.4005493 },
    { lat: 48.2041472, lng: 19.4009049 },
    { lat: 48.2039369, lng: 19.4012706 },
    { lat: 48.2037731, lng: 19.4014979 },
    { lat: 48.203624, lng: 19.4015865 },
    { lat: 48.2031405, lng: 19.4025152 },
    { lat: 48.2030161, lng: 19.4026701 },
    { lat: 48.202877, lng: 19.4026841 },
    { lat: 48.202543, lng: 19.4031718 },
    { lat: 48.2025758, lng: 19.40351 },
    { lat: 48.2022362, lng: 19.4036891 },
    { lat: 48.2020502, lng: 19.4039506 },
    { lat: 48.2016943, lng: 19.4038856 },
    { lat: 48.2012149, lng: 19.4043527 },
    { lat: 48.2010486, lng: 19.4044321 },
    { lat: 48.2007312, lng: 19.4044171 },
    { lat: 48.2001702, lng: 19.4047342 },
    { lat: 48.1996681, lng: 19.4047695 },
    { lat: 48.1994461, lng: 19.4049146 },
    { lat: 48.1992601, lng: 19.4049104 },
    { lat: 48.1992257, lng: 19.4050426 },
    { lat: 48.1983053, lng: 19.4058822 },
    { lat: 48.1978263, lng: 19.4059772 },
    { lat: 48.197626, lng: 19.4063384 },
    { lat: 48.1975031, lng: 19.4062935 },
    { lat: 48.1973689, lng: 19.406525 },
    { lat: 48.1971676, lng: 19.4071313 },
    { lat: 48.1970264, lng: 19.4077387 },
    { lat: 48.1968233, lng: 19.4077345 },
    { lat: 48.196313, lng: 19.4073386 },
    { lat: 48.1962113, lng: 19.4073286 },
    { lat: 48.195826, lng: 19.4075705 },
    { lat: 48.1956016, lng: 19.407855 },
    { lat: 48.1956643, lng: 19.4082242 },
    { lat: 48.1956254, lng: 19.4084049 },
    { lat: 48.1955486, lng: 19.4084004 },
    { lat: 48.1954389, lng: 19.4085109 },
    { lat: 48.1952023, lng: 19.4090111 },
    { lat: 48.1953258, lng: 19.4091944 },
    { lat: 48.1949714, lng: 19.4104408 },
    { lat: 48.1950201, lng: 19.410731 },
    { lat: 48.1948129, lng: 19.4112132 },
    { lat: 48.1949035, lng: 19.4114343 },
    { lat: 48.1944829, lng: 19.4119045 },
    { lat: 48.1944134, lng: 19.412102 },
    { lat: 48.1946009, lng: 19.4127543 },
    { lat: 48.1945003, lng: 19.4130779 },
    { lat: 48.194262, lng: 19.4126899 },
    { lat: 48.1941674, lng: 19.412761 },
    { lat: 48.1941982, lng: 19.4128178 },
    { lat: 48.194067, lng: 19.4130454 },
    { lat: 48.1939921, lng: 19.4133681 },
    { lat: 48.193902, lng: 19.4134405 },
    { lat: 48.1937075, lng: 19.4138066 },
    { lat: 48.1934835, lng: 19.4143627 },
    { lat: 48.1934518, lng: 19.4147143 },
    { lat: 48.1933805, lng: 19.4148738 },
    { lat: 48.1934069, lng: 19.4150292 },
    { lat: 48.1932962, lng: 19.4154041 },
    { lat: 48.1932035, lng: 19.415446 },
    { lat: 48.1931322, lng: 19.4157256 },
    { lat: 48.1929005, lng: 19.416044 },
    { lat: 48.1928483, lng: 19.4162982 },
    { lat: 48.1927705, lng: 19.4164414 },
    { lat: 48.1926047, lng: 19.416626 },
    { lat: 48.1924123, lng: 19.4163727 },
    { lat: 48.192235, lng: 19.4166479 },
    { lat: 48.192025, lng: 19.4166433 },
    { lat: 48.1918703, lng: 19.4169214 },
    { lat: 48.1914554, lng: 19.4170131 },
    { lat: 48.1914518, lng: 19.4174144 },
    { lat: 48.1913594, lng: 19.4176039 },
    { lat: 48.1912967, lng: 19.4181333 },
    { lat: 48.191202, lng: 19.4181843 },
    { lat: 48.1909778, lng: 19.4188502 },
    { lat: 48.1908838, lng: 19.4189368 },
    { lat: 48.1908461, lng: 19.4191623 },
    { lat: 48.1907677, lng: 19.4191271 },
    { lat: 48.1907464, lng: 19.4192255 },
    { lat: 48.1908243, lng: 19.4192501 },
    { lat: 48.1907896, lng: 19.419425 },
    { lat: 48.1908356, lng: 19.4196331 },
    { lat: 48.1907984, lng: 19.4201344 },
    { lat: 48.1906103, lng: 19.4207555 },
    { lat: 48.1905526, lng: 19.4208199 },
    { lat: 48.1906033, lng: 19.421132 },
    { lat: 48.1908562, lng: 19.4215662 },
    { lat: 48.190837, lng: 19.4217088 },
    { lat: 48.1907516, lng: 19.4218616 },
    { lat: 48.1903456, lng: 19.4222592 },
    { lat: 48.1902749, lng: 19.4225016 },
    { lat: 48.1906633, lng: 19.4228998 },
    { lat: 48.1906439, lng: 19.4231332 },
    { lat: 48.1905812, lng: 19.4232356 },
    { lat: 48.1903115, lng: 19.4232751 },
    { lat: 48.1902791, lng: 19.4234943 },
    { lat: 48.190411, lng: 19.4238057 },
    { lat: 48.1904449, lng: 19.4242634 },
    { lat: 48.1902409, lng: 19.4244043 },
    { lat: 48.1901811, lng: 19.4243792 },
    { lat: 48.1900546, lng: 19.4246596 },
    { lat: 48.1894173, lng: 19.4252664 },
    { lat: 48.1887003, lng: 19.4257403 },
    { lat: 48.1886731, lng: 19.4256662 },
    { lat: 48.1884567, lng: 19.4255937 },
    { lat: 48.1883515, lng: 19.4256317 },
    { lat: 48.1882298, lng: 19.4258257 },
    { lat: 48.1880569, lng: 19.4259104 },
    { lat: 48.1878505, lng: 19.4256447 },
    { lat: 48.1876291, lng: 19.4259175 },
    { lat: 48.1878635, lng: 19.4262544 },
    { lat: 48.1878307, lng: 19.4263709 },
    { lat: 48.1876334, lng: 19.4265334 },
    { lat: 48.187419, lng: 19.4261947 },
    { lat: 48.1872979, lng: 19.4263513 },
    { lat: 48.1875887, lng: 19.4266607 },
    { lat: 48.1881571, lng: 19.4260962 },
    { lat: 48.1885029, lng: 19.4258311 },
    { lat: 48.1901757, lng: 19.4253126 },
    { lat: 48.1903921, lng: 19.4252184 },
    { lat: 48.190446, lng: 19.4250958 },
    { lat: 48.1907156, lng: 19.4251937 },
    { lat: 48.1908676, lng: 19.4255509 },
    { lat: 48.191264, lng: 19.4256616 },
    { lat: 48.1921612, lng: 19.425472 },
    { lat: 48.1922837, lng: 19.4253909 },
    { lat: 48.1923598, lng: 19.4254319 },
    { lat: 48.1924706, lng: 19.4253429 },
    { lat: 48.1925726, lng: 19.4254267 },
    { lat: 48.1928538, lng: 19.4254358 },
    { lat: 48.1929479, lng: 19.4255417 },
    { lat: 48.193369, lng: 19.425512 },
    { lat: 48.193572, lng: 19.4256107 },
    { lat: 48.1937361, lng: 19.4258646 },
    { lat: 48.1939329, lng: 19.4259504 },
    { lat: 48.194093, lng: 19.426209 },
    { lat: 48.1941626, lng: 19.4264235 },
    { lat: 48.194334, lng: 19.4266621 },
    { lat: 48.194595, lng: 19.427512 },
    { lat: 48.1947538, lng: 19.427528 },
    { lat: 48.1948323, lng: 19.4277352 },
    { lat: 48.1950798, lng: 19.4280845 },
    { lat: 48.195152, lng: 19.4284178 },
    { lat: 48.1952947, lng: 19.4286238 },
    { lat: 48.1953159, lng: 19.4289783 },
    { lat: 48.1955503, lng: 19.4297656 },
    { lat: 48.1967919, lng: 19.4308064 },
    { lat: 48.1970762, lng: 19.4309541 },
    { lat: 48.1983686, lng: 19.4309952 },
    { lat: 48.1995644, lng: 19.4308282 },
    { lat: 48.2002309, lng: 19.4306231 },
    { lat: 48.2016148, lng: 19.4303829 },
    { lat: 48.202047, lng: 19.4301574 },
    { lat: 48.2022529, lng: 19.4301257 },
    { lat: 48.2024759, lng: 19.4305533 },
    { lat: 48.2028284, lng: 19.4323612 },
    { lat: 48.2057008, lng: 19.4325693 },
    { lat: 48.2078779, lng: 19.432965 },
    { lat: 48.208393, lng: 19.4330861 },
    { lat: 48.208067, lng: 19.4347748 },
    { lat: 48.2082633, lng: 19.4362575 },
    { lat: 48.2084587, lng: 19.4364839 },
    { lat: 48.2086763, lng: 19.4364867 },
    { lat: 48.2088694, lng: 19.436685 },
    { lat: 48.2093862, lng: 19.4368866 },
    { lat: 48.2098875, lng: 19.4367153 },
    { lat: 48.2103263, lng: 19.4366548 },
    { lat: 48.2105712, lng: 19.4367092 },
    { lat: 48.2110516, lng: 19.4366192 },
    { lat: 48.2111991, lng: 19.4368082 },
    { lat: 48.211538, lng: 19.4370841 },
    { lat: 48.2118314, lng: 19.4376919 },
    { lat: 48.2121702, lng: 19.4379194 },
    { lat: 48.2123421, lng: 19.4383631 },
    { lat: 48.212485, lng: 19.4385251 },
    { lat: 48.2127119, lng: 19.4386283 },
    { lat: 48.2131048, lng: 19.4389328 },
    { lat: 48.2140003, lng: 19.439398 },
    { lat: 48.214509, lng: 19.4399333 },
    { lat: 48.2146215, lng: 19.4402399 },
    { lat: 48.2149037, lng: 19.4400595 },
    { lat: 48.2151776, lng: 19.4401285 },
    { lat: 48.2159839, lng: 19.4406198 },
    { lat: 48.2168854, lng: 19.4409282 },
    { lat: 48.2177367, lng: 19.440721 },
    { lat: 48.2189848, lng: 19.4410901 },
    { lat: 48.2192948, lng: 19.441068 },
    { lat: 48.219299, lng: 19.4408223 },
    { lat: 48.2197437, lng: 19.4406463 },
    { lat: 48.2199527, lng: 19.4408907 },
    { lat: 48.2204991, lng: 19.4411504 },
    { lat: 48.2211645, lng: 19.440947 },
    { lat: 48.2212515, lng: 19.4405943 },
    { lat: 48.2221915, lng: 19.4406658 },
    { lat: 48.2222687, lng: 19.4403183 },
    { lat: 48.222125, lng: 19.4400214 },
    { lat: 48.2222143, lng: 19.4399304 },
    { lat: 48.2225153, lng: 19.4400046 },
    { lat: 48.2227644, lng: 19.4398628 },
    { lat: 48.2228384, lng: 19.4395687 },
    { lat: 48.2227628, lng: 19.4393717 },
    { lat: 48.2227007, lng: 19.4393217 },
    { lat: 48.2225774, lng: 19.4393814 },
    { lat: 48.2225263, lng: 19.4393291 },
    { lat: 48.222452, lng: 19.4389454 },
    { lat: 48.2220419, lng: 19.4388091 },
    { lat: 48.2219112, lng: 19.4386862 },
    { lat: 48.2217731, lng: 19.4386979 },
    { lat: 48.2216138, lng: 19.4388105 },
    { lat: 48.2214017, lng: 19.438665 },
    { lat: 48.22124, lng: 19.438651 },
    { lat: 48.2211529, lng: 19.4385022 },
    { lat: 48.2210389, lng: 19.4384284 },
    { lat: 48.2208385, lng: 19.4380951 },
    { lat: 48.2208785, lng: 19.4378915 },
    { lat: 48.220838, lng: 19.4377481 },
    { lat: 48.2209464, lng: 19.4376589 },
    { lat: 48.2210651, lng: 19.4377863 },
    { lat: 48.2211953, lng: 19.4378231 },
    { lat: 48.2213245, lng: 19.4375981 },
    { lat: 48.2211232, lng: 19.4374656 },
    { lat: 48.2210911, lng: 19.4373368 },
    { lat: 48.2211588, lng: 19.4371692 },
    { lat: 48.2212604, lng: 19.4370754 },
    { lat: 48.2214687, lng: 19.437215 },
    { lat: 48.2217445, lng: 19.4371141 },
    { lat: 48.2217739, lng: 19.4369968 },
    { lat: 48.2216536, lng: 19.4367844 },
    { lat: 48.2216892, lng: 19.436634 },
    { lat: 48.2217842, lng: 19.4366148 },
    { lat: 48.2218972, lng: 19.436741 },
    { lat: 48.2220518, lng: 19.4365791 },
    { lat: 48.2220688, lng: 19.4364589 },
    { lat: 48.2219659, lng: 19.436216 },
    { lat: 48.2219876, lng: 19.4359592 },
    { lat: 48.2220762, lng: 19.4357604 },
    { lat: 48.2219382, lng: 19.4351537 },
    { lat: 48.2212401, lng: 19.434973 },
    { lat: 48.2207055, lng: 19.4344149 },
    { lat: 48.2197835, lng: 19.4330284 },
    { lat: 48.2189716, lng: 19.4322773 },
    { lat: 48.2187436, lng: 19.4318416 },
    { lat: 48.2184153, lng: 19.4307593 },
    { lat: 48.2182233, lng: 19.4306792 },
    { lat: 48.2180761, lng: 19.4304983 },
    { lat: 48.2178913, lng: 19.430526 },
    { lat: 48.2174018, lng: 19.4301572 },
    { lat: 48.2171997, lng: 19.4302687 },
    { lat: 48.2169303, lng: 19.4300798 },
    { lat: 48.2167822, lng: 19.43007 },
    { lat: 48.2165884, lng: 19.4299025 },
    { lat: 48.2163554, lng: 19.4294285 },
    { lat: 48.2162866, lng: 19.4286418 },
    { lat: 48.2160675, lng: 19.4280394 },
    { lat: 48.2157902, lng: 19.4277483 },
    { lat: 48.2157179, lng: 19.4278185 },
    { lat: 48.215306, lng: 19.4272574 },
    { lat: 48.2144965, lng: 19.4275151 },
    { lat: 48.2139746, lng: 19.4277502 },
    { lat: 48.2134051, lng: 19.4281037 },
    { lat: 48.2128903, lng: 19.4269154 },
    { lat: 48.212096, lng: 19.4262295 },
    { lat: 48.2120152, lng: 19.4260232 },
    { lat: 48.2118475, lng: 19.4242385 },
    { lat: 48.2122251, lng: 19.4238342 },
    { lat: 48.2116052, lng: 19.4217391 },
    { lat: 48.211674, lng: 19.4192389 },
    { lat: 48.2128938, lng: 19.4168895 },
    { lat: 48.2134816, lng: 19.4163742 },
    { lat: 48.2138403, lng: 19.4161944 },
    { lat: 48.2139601, lng: 19.4162001 },
    { lat: 48.2142063, lng: 19.4163779 },
    { lat: 48.2145465, lng: 19.4160157 },
    { lat: 48.2155586, lng: 19.4152804 },
    { lat: 48.2171394, lng: 19.4146474 },
    { lat: 48.2187862, lng: 19.4152714 },
    { lat: 48.21899, lng: 19.4148858 },
    { lat: 48.2198426, lng: 19.4145434 },
    { lat: 48.2209062, lng: 19.4151526 },
    { lat: 48.2210096, lng: 19.4151986 },
    { lat: 48.2209185, lng: 19.4132941 },
    { lat: 48.2208169, lng: 19.4131229 },
    { lat: 48.2201596, lng: 19.4105508 },
    { lat: 48.2193763, lng: 19.4092938 },
    { lat: 48.2166412, lng: 19.4103189 },
    { lat: 48.2154536, lng: 19.4083801 },
    { lat: 48.2158249, lng: 19.4077937 },
    { lat: 48.2156967, lng: 19.4075648 },
    { lat: 48.2154857, lng: 19.4073431 },
    { lat: 48.2155791, lng: 19.405867 },
    { lat: 48.2154412, lng: 19.4046236 },
    { lat: 48.2148276, lng: 19.4004348 },
    { lat: 48.2137885, lng: 19.4000329 },
    { lat: 48.2147993, lng: 19.3962103 },
    { lat: 48.2172589, lng: 19.3935627 },
    { lat: 48.2176768, lng: 19.3923474 },
    { lat: 48.2169382, lng: 19.3918896 },
    { lat: 48.2166077, lng: 19.3903197 },
    { lat: 48.2159844, lng: 19.3888294 },
    { lat: 48.2141876, lng: 19.3867865 },
    { lat: 48.2140745, lng: 19.3868971 },
    { lat: 48.2137906, lng: 19.3862856 },
    { lat: 48.2137277, lng: 19.3860273 },
    { lat: 48.2136051, lng: 19.3857983 },
    { lat: 48.2134769, lng: 19.3855908 },
  ],
  Zombor: [
    { lat: 48.1364283, lng: 19.4396213 },
    { lat: 48.1363648, lng: 19.4396626 },
    { lat: 48.1356423, lng: 19.440927 },
    { lat: 48.1354095, lng: 19.4409378 },
    { lat: 48.1346892, lng: 19.441388 },
    { lat: 48.1344177, lng: 19.441822 },
    { lat: 48.1336045, lng: 19.4427597 },
    { lat: 48.1335605, lng: 19.4426892 },
    { lat: 48.1330936, lng: 19.4430397 },
    { lat: 48.1324485, lng: 19.443393 },
    { lat: 48.1311143, lng: 19.4438715 },
    { lat: 48.1304772, lng: 19.4442931 },
    { lat: 48.1303729, lng: 19.4441407 },
    { lat: 48.1302476, lng: 19.4440167 },
    { lat: 48.1298476, lng: 19.4447852 },
    { lat: 48.1296007, lng: 19.4454472 },
    { lat: 48.1290524, lng: 19.4474434 },
    { lat: 48.1290349, lng: 19.448008 },
    { lat: 48.1291426, lng: 19.4484736 },
    { lat: 48.1291834, lng: 19.4490739 },
    { lat: 48.1291224, lng: 19.4499334 },
    { lat: 48.1290434, lng: 19.4503121 },
    { lat: 48.1288917, lng: 19.4506826 },
    { lat: 48.1289476, lng: 19.4506961 },
    { lat: 48.1283485, lng: 19.4582695 },
    { lat: 48.1297989, lng: 19.4596807 },
    { lat: 48.1304353, lng: 19.4594593 },
    { lat: 48.1327673, lng: 19.4594872 },
    { lat: 48.1327587, lng: 19.4603073 },
    { lat: 48.132955, lng: 19.4618002 },
    { lat: 48.1328241, lng: 19.4632558 },
    { lat: 48.1326085, lng: 19.4643319 },
    { lat: 48.132459, lng: 19.4659439 },
    { lat: 48.1321211, lng: 19.4668356 },
    { lat: 48.1314821, lng: 19.4681578 },
    { lat: 48.1309071, lng: 19.4688241 },
    { lat: 48.1305551, lng: 19.4681925 },
    { lat: 48.1302512, lng: 19.4677668 },
    { lat: 48.1294302, lng: 19.4674221 },
    { lat: 48.1290518, lng: 19.4687469 },
    { lat: 48.1288935, lng: 19.4690923 },
    { lat: 48.1285668, lng: 19.4694842 },
    { lat: 48.1280097, lng: 19.4698812 },
    { lat: 48.1285357, lng: 19.4716531 },
    { lat: 48.1292065, lng: 19.4731456 },
    { lat: 48.1294863, lng: 19.473898 },
    { lat: 48.129709, lng: 19.474322 },
    { lat: 48.1306825, lng: 19.4724149 },
    { lat: 48.1312354, lng: 19.4728894 },
    { lat: 48.1328904, lng: 19.474075 },
    { lat: 48.1337713, lng: 19.4727473 },
    { lat: 48.1345337, lng: 19.4713659 },
    { lat: 48.1354927, lng: 19.4682659 },
    { lat: 48.1375864, lng: 19.4631756 },
    { lat: 48.1371734, lng: 19.4630221 },
    { lat: 48.1358875, lng: 19.462233 },
    { lat: 48.1364018, lng: 19.4608948 },
    { lat: 48.1381643, lng: 19.4581154 },
    { lat: 48.1410711, lng: 19.4557222 },
    { lat: 48.1426451, lng: 19.4539449 },
    { lat: 48.1427676, lng: 19.453552 },
    { lat: 48.1428171, lng: 19.4533609 },
    { lat: 48.1427728, lng: 19.4532064 },
    { lat: 48.1445067, lng: 19.4497264 },
    { lat: 48.1448674, lng: 19.4487953 },
    { lat: 48.1451138, lng: 19.4463483 },
    { lat: 48.1453395, lng: 19.445064 },
    { lat: 48.1453411, lng: 19.4442109 },
    { lat: 48.146135, lng: 19.4428371 },
    { lat: 48.1466388, lng: 19.4422889 },
    { lat: 48.1472625, lng: 19.441727 },
    { lat: 48.1482867, lng: 19.4412902 },
    { lat: 48.148951, lng: 19.4406785 },
    { lat: 48.1491574, lng: 19.439988 },
    { lat: 48.1492758, lng: 19.4389887 },
    { lat: 48.1494375, lng: 19.4382831 },
    { lat: 48.1492489, lng: 19.4368441 },
    { lat: 48.1491678, lng: 19.4356524 },
    { lat: 48.1493065, lng: 19.4344145 },
    { lat: 48.1493138, lng: 19.4343739 },
    { lat: 48.1492005, lng: 19.4326466 },
    { lat: 48.1488442, lng: 19.4325936 },
    { lat: 48.1486344, lng: 19.4323768 },
    { lat: 48.1483497, lng: 19.4321944 },
    { lat: 48.1476968, lng: 19.4321412 },
    { lat: 48.1472115, lng: 19.4321713 },
    { lat: 48.1464807, lng: 19.4324757 },
    { lat: 48.1458217, lng: 19.4327936 },
    { lat: 48.1439325, lng: 19.4339056 },
    { lat: 48.1434246, lng: 19.4340082 },
    { lat: 48.1428446, lng: 19.4338538 },
    { lat: 48.14257, lng: 19.433904 },
    { lat: 48.1423581, lng: 19.4340992 },
    { lat: 48.1419443, lng: 19.4346877 },
    { lat: 48.1412012, lng: 19.4359193 },
    { lat: 48.1409207, lng: 19.4362107 },
    { lat: 48.1407692, lng: 19.4360086 },
    { lat: 48.1393443, lng: 19.4378673 },
    { lat: 48.1387395, lng: 19.4374685 },
    { lat: 48.137709, lng: 19.4387017 },
    { lat: 48.1366757, lng: 19.4393747 },
    { lat: 48.1364283, lng: 19.4396213 },
  ],
  Závada: [
    { lat: 48.2965473, lng: 19.4697033 },
    { lat: 48.2964464, lng: 19.4698834 },
    { lat: 48.296042, lng: 19.471406 },
    { lat: 48.2957832, lng: 19.472042 },
    { lat: 48.2955875, lng: 19.471979 },
    { lat: 48.295276, lng: 19.472211 },
    { lat: 48.2947688, lng: 19.4718686 },
    { lat: 48.2934645, lng: 19.4712133 },
    { lat: 48.2921972, lng: 19.4739217 },
    { lat: 48.2914467, lng: 19.475322 },
    { lat: 48.2909823, lng: 19.4758489 },
    { lat: 48.2902259, lng: 19.4768597 },
    { lat: 48.2895561, lng: 19.4764395 },
    { lat: 48.2894686, lng: 19.4768819 },
    { lat: 48.2893368, lng: 19.4771225 },
    { lat: 48.2882115, lng: 19.4786338 },
    { lat: 48.2874911, lng: 19.4797126 },
    { lat: 48.2871329, lng: 19.4792842 },
    { lat: 48.2866733, lng: 19.4800121 },
    { lat: 48.2860941, lng: 19.4807608 },
    { lat: 48.2856241, lng: 19.4812658 },
    { lat: 48.285418, lng: 19.4814134 },
    { lat: 48.2855004, lng: 19.4824748 },
    { lat: 48.2851764, lng: 19.4825612 },
    { lat: 48.2847375, lng: 19.4824281 },
    { lat: 48.2840017, lng: 19.4834461 },
    { lat: 48.2837349, lng: 19.4833311 },
    { lat: 48.2837276, lng: 19.4835385 },
    { lat: 48.2835484, lng: 19.4835142 },
    { lat: 48.2834123, lng: 19.4835684 },
    { lat: 48.2831714, lng: 19.4839358 },
    { lat: 48.2829783, lng: 19.48407 },
    { lat: 48.2829151, lng: 19.4834896 },
    { lat: 48.2827645, lng: 19.4833296 },
    { lat: 48.2827121, lng: 19.4831022 },
    { lat: 48.2827754, lng: 19.4824559 },
    { lat: 48.2826978, lng: 19.4823611 },
    { lat: 48.2824602, lng: 19.4823651 },
    { lat: 48.2823744, lng: 19.4820252 },
    { lat: 48.2824878, lng: 19.4818198 },
    { lat: 48.2824875, lng: 19.4815958 },
    { lat: 48.2822941, lng: 19.481268 },
    { lat: 48.2823137, lng: 19.4810147 },
    { lat: 48.282428, lng: 19.4806347 },
    { lat: 48.2823361, lng: 19.4804589 },
    { lat: 48.2819594, lng: 19.4809786 },
    { lat: 48.2818523, lng: 19.4810263 },
    { lat: 48.2817474, lng: 19.4808827 },
    { lat: 48.2816295, lng: 19.4802394 },
    { lat: 48.281773, lng: 19.4796918 },
    { lat: 48.2816374, lng: 19.4794895 },
    { lat: 48.281591, lng: 19.4792449 },
    { lat: 48.2814472, lng: 19.4792289 },
    { lat: 48.2812021, lng: 19.4796038 },
    { lat: 48.2809716, lng: 19.4796899 },
    { lat: 48.2808273, lng: 19.4796389 },
    { lat: 48.2807336, lng: 19.4798529 },
    { lat: 48.2803381, lng: 19.4798547 },
    { lat: 48.2800774, lng: 19.4795364 },
    { lat: 48.2800655, lng: 19.4794227 },
    { lat: 48.2798004, lng: 19.4792286 },
    { lat: 48.2798311, lng: 19.4791589 },
    { lat: 48.279698, lng: 19.4788424 },
    { lat: 48.2794743, lng: 19.4786387 },
    { lat: 48.2792512, lng: 19.478666 },
    { lat: 48.2791461, lng: 19.4784881 },
    { lat: 48.2790097, lng: 19.4784911 },
    { lat: 48.2788981, lng: 19.4788255 },
    { lat: 48.2788175, lng: 19.4788141 },
    { lat: 48.278716, lng: 19.4785219 },
    { lat: 48.2787032, lng: 19.4782937 },
    { lat: 48.2783757, lng: 19.4777743 },
    { lat: 48.2780271, lng: 19.4777179 },
    { lat: 48.2779192, lng: 19.4777989 },
    { lat: 48.2778766, lng: 19.4778978 },
    { lat: 48.2779245, lng: 19.4779848 },
    { lat: 48.2778237, lng: 19.478116 },
    { lat: 48.2777103, lng: 19.4782217 },
    { lat: 48.2776604, lng: 19.4781943 },
    { lat: 48.2774602, lng: 19.477742 },
    { lat: 48.2775047, lng: 19.4776117 },
    { lat: 48.2773801, lng: 19.4774026 },
    { lat: 48.277239, lng: 19.4769193 },
    { lat: 48.2772984, lng: 19.4768617 },
    { lat: 48.2772761, lng: 19.4767937 },
    { lat: 48.2769931, lng: 19.4765757 },
    { lat: 48.2767769, lng: 19.4766727 },
    { lat: 48.2766927, lng: 19.4765138 },
    { lat: 48.2767113, lng: 19.4760191 },
    { lat: 48.2765132, lng: 19.475737 },
    { lat: 48.2762375, lng: 19.4758151 },
    { lat: 48.2761981, lng: 19.4758401 },
    { lat: 48.275206, lng: 19.479689 },
    { lat: 48.2734537, lng: 19.4786091 },
    { lat: 48.2732766, lng: 19.4793515 },
    { lat: 48.273212, lng: 19.4795164 },
    { lat: 48.2730436, lng: 19.4796972 },
    { lat: 48.2727239, lng: 19.4808397 },
    { lat: 48.2727638, lng: 19.4819326 },
    { lat: 48.2729657, lng: 19.4822751 },
    { lat: 48.2729732, lng: 19.4825573 },
    { lat: 48.2732176, lng: 19.4828401 },
    { lat: 48.2733956, lng: 19.4828639 },
    { lat: 48.2736012, lng: 19.4831894 },
    { lat: 48.2736123, lng: 19.4837154 },
    { lat: 48.2737572, lng: 19.4840939 },
    { lat: 48.2737183, lng: 19.4843467 },
    { lat: 48.2737675, lng: 19.4851085 },
    { lat: 48.2737047, lng: 19.4852347 },
    { lat: 48.2735013, lng: 19.4865023 },
    { lat: 48.2735877, lng: 19.4878991 },
    { lat: 48.2733202, lng: 19.4895293 },
    { lat: 48.2729391, lng: 19.4911908 },
    { lat: 48.2725783, lng: 19.4922756 },
    { lat: 48.2724979, lng: 19.4927496 },
    { lat: 48.272439, lng: 19.4928651 },
    { lat: 48.274023, lng: 19.4949079 },
    { lat: 48.2750266, lng: 19.4958639 },
    { lat: 48.2764735, lng: 19.4974606 },
    { lat: 48.2770936, lng: 19.4982406 },
    { lat: 48.2785517, lng: 19.4994701 },
    { lat: 48.2790678, lng: 19.4997353 },
    { lat: 48.278135, lng: 19.506427 },
    { lat: 48.278345, lng: 19.506396 },
    { lat: 48.278552, lng: 19.506404 },
    { lat: 48.279026, lng: 19.506393 },
    { lat: 48.279358, lng: 19.506389 },
    { lat: 48.279775, lng: 19.506414 },
    { lat: 48.280053, lng: 19.506382 },
    { lat: 48.280103, lng: 19.506395 },
    { lat: 48.280132, lng: 19.506403 },
    { lat: 48.2802, lng: 19.50642 },
    { lat: 48.280414, lng: 19.506407 },
    { lat: 48.281144, lng: 19.506419 },
    { lat: 48.28142, lng: 19.506424 },
    { lat: 48.281529, lng: 19.506417 },
    { lat: 48.282409, lng: 19.506362 },
    { lat: 48.282511, lng: 19.50631 },
    { lat: 48.282672, lng: 19.506242 },
    { lat: 48.282853, lng: 19.506166 },
    { lat: 48.28305, lng: 19.50619 },
    { lat: 48.283204, lng: 19.506209 },
    { lat: 48.283606, lng: 19.506013 },
    { lat: 48.283791, lng: 19.505924 },
    { lat: 48.284144, lng: 19.505827 },
    { lat: 48.284857, lng: 19.505696 },
    { lat: 48.28494, lng: 19.505681 },
    { lat: 48.285052, lng: 19.505528 },
    { lat: 48.285105, lng: 19.505454 },
    { lat: 48.285374, lng: 19.505085 },
    { lat: 48.285655, lng: 19.504578 },
    { lat: 48.285725, lng: 19.5044 },
    { lat: 48.285791, lng: 19.504237 },
    { lat: 48.285865, lng: 19.504053 },
    { lat: 48.286423, lng: 19.503571 },
    { lat: 48.286469, lng: 19.503533 },
    { lat: 48.287358, lng: 19.502797 },
    { lat: 48.287478, lng: 19.502777 },
    { lat: 48.287668, lng: 19.502745 },
    { lat: 48.287828, lng: 19.502718 },
    { lat: 48.288192, lng: 19.502657 },
    { lat: 48.288393, lng: 19.502591 },
    { lat: 48.28856, lng: 19.502533 },
    { lat: 48.288758, lng: 19.50243 },
    { lat: 48.289023, lng: 19.502268 },
    { lat: 48.289229, lng: 19.502125 },
    { lat: 48.289446, lng: 19.502063 },
    { lat: 48.28977, lng: 19.50207 },
    { lat: 48.289793, lng: 19.50206 },
    { lat: 48.290048, lng: 19.501951 },
    { lat: 48.290133, lng: 19.501916 },
    { lat: 48.290174, lng: 19.501905 },
    { lat: 48.290275, lng: 19.501878 },
    { lat: 48.290478, lng: 19.501898 },
    { lat: 48.290561, lng: 19.501907 },
    { lat: 48.290619, lng: 19.501891 },
    { lat: 48.29066, lng: 19.501881 },
    { lat: 48.290714, lng: 19.501853 },
    { lat: 48.291499, lng: 19.501442 },
    { lat: 48.291709, lng: 19.501331 },
    { lat: 48.292059, lng: 19.501146 },
    { lat: 48.292481, lng: 19.500956 },
    { lat: 48.292694, lng: 19.500921 },
    { lat: 48.292854, lng: 19.500923 },
    { lat: 48.293112, lng: 19.500736 },
    { lat: 48.293229, lng: 19.500649 },
    { lat: 48.293387, lng: 19.500515 },
    { lat: 48.293781, lng: 19.500183 },
    { lat: 48.294514, lng: 19.499626 },
    { lat: 48.29489, lng: 19.499462 },
    { lat: 48.295367, lng: 19.499337 },
    { lat: 48.295833, lng: 19.499234 },
    { lat: 48.296322, lng: 19.499073 },
    { lat: 48.296607, lng: 19.499058 },
    { lat: 48.297161, lng: 19.498887 },
    { lat: 48.29743, lng: 19.498892 },
    { lat: 48.29772, lng: 19.498875 },
    { lat: 48.297918, lng: 19.498831 },
    { lat: 48.298009, lng: 19.498802 },
    { lat: 48.298026, lng: 19.498799 },
    { lat: 48.298076, lng: 19.498789 },
    { lat: 48.298962, lng: 19.498972 },
    { lat: 48.30073, lng: 19.499343 },
    { lat: 48.303091, lng: 19.499831 },
    { lat: 48.303844, lng: 19.499987 },
    { lat: 48.305007, lng: 19.499075 },
    { lat: 48.3053, lng: 19.498773 },
    { lat: 48.305653, lng: 19.498269 },
    { lat: 48.305818, lng: 19.497985 },
    { lat: 48.307128, lng: 19.496379 },
    { lat: 48.307246, lng: 19.496152 },
    { lat: 48.30767, lng: 19.495627 },
    { lat: 48.308179, lng: 19.495083 },
    { lat: 48.308485, lng: 19.494835 },
    { lat: 48.308753, lng: 19.494505 },
    { lat: 48.3087721, lng: 19.4944677 },
    { lat: 48.308872, lng: 19.494272 },
    { lat: 48.309008, lng: 19.493851 },
    { lat: 48.309411, lng: 19.492487 },
    { lat: 48.309423, lng: 19.491881 },
    { lat: 48.309464, lng: 19.491094 },
    { lat: 48.309502, lng: 19.490811 },
    { lat: 48.309521, lng: 19.490493 },
    { lat: 48.309581, lng: 19.490139 },
    { lat: 48.309893, lng: 19.489376 },
    { lat: 48.309862, lng: 19.489341 },
    { lat: 48.309847, lng: 19.489323 },
    { lat: 48.310054, lng: 19.488881 },
    { lat: 48.310166, lng: 19.48857 },
    { lat: 48.310297, lng: 19.488292 },
    { lat: 48.310471, lng: 19.488058 },
    { lat: 48.310801, lng: 19.487721 },
    { lat: 48.311036, lng: 19.487393 },
    { lat: 48.311533, lng: 19.486891 },
    { lat: 48.311702, lng: 19.486601 },
    { lat: 48.311802, lng: 19.48651 },
    { lat: 48.312081, lng: 19.48636 },
    { lat: 48.312159, lng: 19.486268 },
    { lat: 48.312284, lng: 19.485968 },
    { lat: 48.312293, lng: 19.485943 },
    { lat: 48.312343, lng: 19.485871 },
    { lat: 48.312409, lng: 19.485835 },
    { lat: 48.312654, lng: 19.485773 },
    { lat: 48.312866, lng: 19.485795 },
    { lat: 48.312881, lng: 19.485793 },
    { lat: 48.313197, lng: 19.485743 },
    { lat: 48.31344, lng: 19.48578 },
    { lat: 48.313559, lng: 19.485836 },
    { lat: 48.313703, lng: 19.48595 },
    { lat: 48.313778, lng: 19.486031 },
    { lat: 48.31399, lng: 19.486314 },
    { lat: 48.314309, lng: 19.48653 },
    { lat: 48.31441, lng: 19.486583 },
    { lat: 48.314923, lng: 19.486806 },
    { lat: 48.315129, lng: 19.48683 },
    { lat: 48.31576, lng: 19.486657 },
    { lat: 48.315921, lng: 19.486635 },
    { lat: 48.316169, lng: 19.486667 },
    { lat: 48.316168, lng: 19.486642 },
    { lat: 48.316166, lng: 19.486595 },
    { lat: 48.316395, lng: 19.485872 },
    { lat: 48.316803, lng: 19.485073 },
    { lat: 48.317111, lng: 19.484039 },
    { lat: 48.317149, lng: 19.483909 },
    { lat: 48.317159, lng: 19.483837 },
    { lat: 48.317224, lng: 19.483381 },
    { lat: 48.317248, lng: 19.483216 },
    { lat: 48.317286, lng: 19.482948 },
    { lat: 48.317318, lng: 19.482275 },
    { lat: 48.317225, lng: 19.482026 },
    { lat: 48.316331, lng: 19.479622 },
    { lat: 48.316462, lng: 19.479604 },
    { lat: 48.316555, lng: 19.479237 },
    { lat: 48.316678, lng: 19.478929 },
    { lat: 48.316854, lng: 19.478558 },
    { lat: 48.316944, lng: 19.478314 },
    { lat: 48.317087, lng: 19.478116 },
    { lat: 48.317162, lng: 19.47792 },
    { lat: 48.317469, lng: 19.477432 },
    { lat: 48.317974, lng: 19.476953 },
    { lat: 48.318155, lng: 19.476484 },
    { lat: 48.318171, lng: 19.476177 },
    { lat: 48.318124, lng: 19.476072 },
    { lat: 48.318191, lng: 19.476042 },
    { lat: 48.318237, lng: 19.47597 },
    { lat: 48.318528, lng: 19.475808 },
    { lat: 48.318591, lng: 19.475792 },
    { lat: 48.318676, lng: 19.47582 },
    { lat: 48.318726, lng: 19.475764 },
    { lat: 48.318862, lng: 19.475694 },
    { lat: 48.318923, lng: 19.475774 },
    { lat: 48.319085, lng: 19.475711 },
    { lat: 48.319141, lng: 19.475797 },
    { lat: 48.319256, lng: 19.475879 },
    { lat: 48.319366, lng: 19.475882 },
    { lat: 48.319691, lng: 19.475516 },
    { lat: 48.31981, lng: 19.475651 },
    { lat: 48.31992, lng: 19.475525 },
    { lat: 48.320215, lng: 19.475143 },
    { lat: 48.320303, lng: 19.475124 },
    { lat: 48.320383, lng: 19.475286 },
    { lat: 48.320495, lng: 19.475618 },
    { lat: 48.32054, lng: 19.475608 },
    { lat: 48.320613, lng: 19.47548 },
    { lat: 48.320804, lng: 19.475373 },
    { lat: 48.320927, lng: 19.475507 },
    { lat: 48.32105, lng: 19.475401 },
    { lat: 48.321189, lng: 19.475374 },
    { lat: 48.321415, lng: 19.475355 },
    { lat: 48.321472, lng: 19.475276 },
    { lat: 48.321541, lng: 19.475374 },
    { lat: 48.321672, lng: 19.475399 },
    { lat: 48.321743, lng: 19.475338 },
    { lat: 48.321808, lng: 19.475354 },
    { lat: 48.321844, lng: 19.475444 },
    { lat: 48.321918, lng: 19.475472 },
    { lat: 48.322012, lng: 19.475565 },
    { lat: 48.322103, lng: 19.475495 },
    { lat: 48.322223, lng: 19.475453 },
    { lat: 48.322291, lng: 19.475367 },
    { lat: 48.322442, lng: 19.475567 },
    { lat: 48.322576, lng: 19.475626 },
    { lat: 48.322758, lng: 19.475584 },
    { lat: 48.322824, lng: 19.475526 },
    { lat: 48.322867, lng: 19.475555 },
    { lat: 48.322886, lng: 19.47563 },
    { lat: 48.322916, lng: 19.475672 },
    { lat: 48.322967, lng: 19.475701 },
    { lat: 48.32303, lng: 19.475648 },
    { lat: 48.323079, lng: 19.475579 },
    { lat: 48.323098, lng: 19.475488 },
    { lat: 48.323126, lng: 19.475472 },
    { lat: 48.323219, lng: 19.475512 },
    { lat: 48.323339, lng: 19.475492 },
    { lat: 48.323414, lng: 19.47541 },
    { lat: 48.323543, lng: 19.475391 },
    { lat: 48.323644, lng: 19.475284 },
    { lat: 48.323628, lng: 19.475216 },
    { lat: 48.32362, lng: 19.47513 },
    { lat: 48.323664, lng: 19.475137 },
    { lat: 48.323716, lng: 19.475092 },
    { lat: 48.323822, lng: 19.474989 },
    { lat: 48.323829, lng: 19.474919 },
    { lat: 48.323736, lng: 19.474871 },
    { lat: 48.323751, lng: 19.474809 },
    { lat: 48.323868, lng: 19.474798 },
    { lat: 48.32389, lng: 19.474757 },
    { lat: 48.323842, lng: 19.474596 },
    { lat: 48.323794, lng: 19.474532 },
    { lat: 48.323816, lng: 19.474415 },
    { lat: 48.323888, lng: 19.474372 },
    { lat: 48.323979, lng: 19.474356 },
    { lat: 48.32403, lng: 19.474285 },
    { lat: 48.324146, lng: 19.474236 },
    { lat: 48.324193, lng: 19.474145 },
    { lat: 48.324358, lng: 19.474041 },
    { lat: 48.324504, lng: 19.473881 },
    { lat: 48.324615, lng: 19.473885 },
    { lat: 48.324648, lng: 19.473804 },
    { lat: 48.324743, lng: 19.4737 },
    { lat: 48.324775, lng: 19.473746 },
    { lat: 48.324824, lng: 19.473696 },
    { lat: 48.324832, lng: 19.47362 },
    { lat: 48.324866, lng: 19.473598 },
    { lat: 48.324906, lng: 19.47361 },
    { lat: 48.325021, lng: 19.473535 },
    { lat: 48.325189, lng: 19.47327 },
    { lat: 48.325274, lng: 19.473226 },
    { lat: 48.325294, lng: 19.473147 },
    { lat: 48.325444, lng: 19.473066 },
    { lat: 48.32548, lng: 19.472968 },
    { lat: 48.325461, lng: 19.472863 },
    { lat: 48.325629, lng: 19.47262 },
    { lat: 48.325696, lng: 19.472626 },
    { lat: 48.325748, lng: 19.472506 },
    { lat: 48.32583, lng: 19.472526 },
    { lat: 48.325886, lng: 19.472456 },
    { lat: 48.325891, lng: 19.472361 },
    { lat: 48.326238, lng: 19.472202 },
    { lat: 48.326429, lng: 19.471763 },
    { lat: 48.326372, lng: 19.471533 },
    { lat: 48.326373, lng: 19.471102 },
    { lat: 48.326374, lng: 19.470581 },
    { lat: 48.326391, lng: 19.470224 },
    { lat: 48.326148, lng: 19.469403 },
    { lat: 48.325938, lng: 19.468685 },
    { lat: 48.325931, lng: 19.468666 },
    { lat: 48.325789, lng: 19.468421 },
    { lat: 48.325699, lng: 19.468185 },
    { lat: 48.325687, lng: 19.468022 },
    { lat: 48.3255215, lng: 19.4679293 },
    { lat: 48.3255894, lng: 19.4676491 },
    { lat: 48.3253279, lng: 19.4676228 },
    { lat: 48.324898, lng: 19.4670812 },
    { lat: 48.3245113, lng: 19.4669123 },
    { lat: 48.3242644, lng: 19.4665456 },
    { lat: 48.3241335, lng: 19.4664503 },
    { lat: 48.3232646, lng: 19.466064 },
    { lat: 48.3229511, lng: 19.4659855 },
    { lat: 48.3227427, lng: 19.4659887 },
    { lat: 48.3225748, lng: 19.4661101 },
    { lat: 48.3225319, lng: 19.4660374 },
    { lat: 48.3221968, lng: 19.4660964 },
    { lat: 48.3221182, lng: 19.4660569 },
    { lat: 48.3219885, lng: 19.46584 },
    { lat: 48.3219302, lng: 19.4655777 },
    { lat: 48.3214228, lng: 19.4650713 },
    { lat: 48.3212674, lng: 19.4646624 },
    { lat: 48.3209947, lng: 19.4643756 },
    { lat: 48.3209154, lng: 19.4643414 },
    { lat: 48.3203551, lng: 19.4644348 },
    { lat: 48.3201961, lng: 19.4643587 },
    { lat: 48.3198529, lng: 19.4645139 },
    { lat: 48.319762, lng: 19.4644285 },
    { lat: 48.3196501, lng: 19.4644955 },
    { lat: 48.3195547, lng: 19.4643785 },
    { lat: 48.3194281, lng: 19.4643734 },
    { lat: 48.3192594, lng: 19.4644018 },
    { lat: 48.318943, lng: 19.4647453 },
    { lat: 48.3187406, lng: 19.4648781 },
    { lat: 48.3184857, lng: 19.4647991 },
    { lat: 48.3184278, lng: 19.4648901 },
    { lat: 48.3182853, lng: 19.4649289 },
    { lat: 48.3181312, lng: 19.4651735 },
    { lat: 48.3178875, lng: 19.4651935 },
    { lat: 48.317791, lng: 19.4652967 },
    { lat: 48.3176816, lng: 19.4652498 },
    { lat: 48.3175463, lng: 19.4653144 },
    { lat: 48.3174258, lng: 19.4655104 },
    { lat: 48.3172681, lng: 19.4655377 },
    { lat: 48.317242, lng: 19.4657766 },
    { lat: 48.3169973, lng: 19.465797 },
    { lat: 48.3167823, lng: 19.4659712 },
    { lat: 48.3165132, lng: 19.4660498 },
    { lat: 48.3163773, lng: 19.4659154 },
    { lat: 48.3161895, lng: 19.4660651 },
    { lat: 48.3161142, lng: 19.4659749 },
    { lat: 48.3157429, lng: 19.4661549 },
    { lat: 48.3156178, lng: 19.4664439 },
    { lat: 48.3154204, lng: 19.4667274 },
    { lat: 48.3151679, lng: 19.4666559 },
    { lat: 48.3150629, lng: 19.466725 },
    { lat: 48.3149886, lng: 19.4668922 },
    { lat: 48.314775, lng: 19.4669309 },
    { lat: 48.3146222, lng: 19.4670875 },
    { lat: 48.3141832, lng: 19.4670725 },
    { lat: 48.3139085, lng: 19.4673429 },
    { lat: 48.3138189, lng: 19.4675729 },
    { lat: 48.3137277, lng: 19.4676433 },
    { lat: 48.3134953, lng: 19.4675219 },
    { lat: 48.3133662, lng: 19.467578 },
    { lat: 48.3132284, lng: 19.4675472 },
    { lat: 48.3129996, lng: 19.4676672 },
    { lat: 48.3127172, lng: 19.4676038 },
    { lat: 48.3125454, lng: 19.4677628 },
    { lat: 48.3124117, lng: 19.4677025 },
    { lat: 48.3123064, lng: 19.4677879 },
    { lat: 48.312332, lng: 19.4679258 },
    { lat: 48.3121981, lng: 19.4681679 },
    { lat: 48.3120267, lng: 19.4680931 },
    { lat: 48.3118907, lng: 19.4682455 },
    { lat: 48.3117175, lng: 19.4683034 },
    { lat: 48.3114195, lng: 19.4682448 },
    { lat: 48.3112971, lng: 19.4684248 },
    { lat: 48.3111122, lng: 19.4684442 },
    { lat: 48.3110826, lng: 19.4685851 },
    { lat: 48.3109492, lng: 19.4687665 },
    { lat: 48.3107345, lng: 19.4688263 },
    { lat: 48.3105011, lng: 19.4688006 },
    { lat: 48.3102722, lng: 19.4686519 },
    { lat: 48.3100141, lng: 19.4686288 },
    { lat: 48.3098494, lng: 19.4688181 },
    { lat: 48.3093006, lng: 19.4688178 },
    { lat: 48.3089562, lng: 19.4689974 },
    { lat: 48.3087993, lng: 19.4689449 },
    { lat: 48.308291, lng: 19.4690911 },
    { lat: 48.3081213, lng: 19.4689765 },
    { lat: 48.3078384, lng: 19.4691681 },
    { lat: 48.3075598, lng: 19.4691202 },
    { lat: 48.3072661, lng: 19.4692732 },
    { lat: 48.3071063, lng: 19.4692288 },
    { lat: 48.3070925, lng: 19.4690553 },
    { lat: 48.3068051, lng: 19.4690749 },
    { lat: 48.3066512, lng: 19.4689212 },
    { lat: 48.3062897, lng: 19.4691444 },
    { lat: 48.3061362, lng: 19.4690883 },
    { lat: 48.305662, lng: 19.4692617 },
    { lat: 48.3042575, lng: 19.4679322 },
    { lat: 48.3039085, lng: 19.4677725 },
    { lat: 48.3037906, lng: 19.4676603 },
    { lat: 48.3035992, lng: 19.4673491 },
    { lat: 48.3034599, lng: 19.466906 },
    { lat: 48.3032445, lng: 19.466563 },
    { lat: 48.3029771, lng: 19.4668952 },
    { lat: 48.3028719, lng: 19.4671505 },
    { lat: 48.3029171, lng: 19.4673695 },
    { lat: 48.3028646, lng: 19.467523 },
    { lat: 48.3028035, lng: 19.4675191 },
    { lat: 48.3027025, lng: 19.4676473 },
    { lat: 48.3023689, lng: 19.4673631 },
    { lat: 48.3022216, lng: 19.4674136 },
    { lat: 48.3021585, lng: 19.4678018 },
    { lat: 48.3017287, lng: 19.4682761 },
    { lat: 48.3016883, lng: 19.4683975 },
    { lat: 48.3017864, lng: 19.4685662 },
    { lat: 48.3016804, lng: 19.4686609 },
    { lat: 48.3016761, lng: 19.4688377 },
    { lat: 48.3015956, lng: 19.4688992 },
    { lat: 48.3014989, lng: 19.4691688 },
    { lat: 48.3015334, lng: 19.4694724 },
    { lat: 48.3010706, lng: 19.4699129 },
    { lat: 48.3008201, lng: 19.469832 },
    { lat: 48.3007652, lng: 19.4696736 },
    { lat: 48.300559, lng: 19.4698899 },
    { lat: 48.3004335, lng: 19.4699045 },
    { lat: 48.3003847, lng: 19.4702134 },
    { lat: 48.300449, lng: 19.4705984 },
    { lat: 48.3006332, lng: 19.4706796 },
    { lat: 48.3006182, lng: 19.4708673 },
    { lat: 48.3004086, lng: 19.470866 },
    { lat: 48.3002433, lng: 19.4710674 },
    { lat: 48.3001447, lng: 19.4710286 },
    { lat: 48.300045, lng: 19.4707563 },
    { lat: 48.299877, lng: 19.4708001 },
    { lat: 48.2998204, lng: 19.4709618 },
    { lat: 48.2998541, lng: 19.4710121 },
    { lat: 48.2995913, lng: 19.4712701 },
    { lat: 48.2995863, lng: 19.4713556 },
    { lat: 48.2996581, lng: 19.4714917 },
    { lat: 48.299546, lng: 19.471701 },
    { lat: 48.2995122, lng: 19.4718915 },
    { lat: 48.2992686, lng: 19.4719836 },
    { lat: 48.2992146, lng: 19.4724448 },
    { lat: 48.299038, lng: 19.472451 },
    { lat: 48.299042, lng: 19.4726332 },
    { lat: 48.2989357, lng: 19.4727236 },
    { lat: 48.2986869, lng: 19.4727509 },
    { lat: 48.2986727, lng: 19.4725697 },
    { lat: 48.2983287, lng: 19.4726827 },
    { lat: 48.2983314, lng: 19.4728395 },
    { lat: 48.298097, lng: 19.4728132 },
    { lat: 48.2979992, lng: 19.4726253 },
    { lat: 48.2982388, lng: 19.4723038 },
    { lat: 48.2974553, lng: 19.4709743 },
    { lat: 48.2973513, lng: 19.4705078 },
    { lat: 48.2967087, lng: 19.4697093 },
    { lat: 48.2965667, lng: 19.4697086 },
    { lat: 48.2965473, lng: 19.4697033 },
  ],
  OpatovskáNováVes: [
    { lat: 48.13119, lng: 19.2278754 },
    { lat: 48.1304493, lng: 19.2289738 },
    { lat: 48.1303496, lng: 19.2291213 },
    { lat: 48.1303864, lng: 19.2292086 },
    { lat: 48.1302411, lng: 19.229486 },
    { lat: 48.1301029, lng: 19.2296296 },
    { lat: 48.1300458, lng: 19.2298673 },
    { lat: 48.1297742, lng: 19.2299412 },
    { lat: 48.129752, lng: 19.2302252 },
    { lat: 48.1295746, lng: 19.2305801 },
    { lat: 48.1287178, lng: 19.2314333 },
    { lat: 48.127721, lng: 19.2330546 },
    { lat: 48.1275192, lng: 19.2337228 },
    { lat: 48.1274426, lng: 19.2343874 },
    { lat: 48.1270243, lng: 19.2356744 },
    { lat: 48.1266329, lng: 19.2359918 },
    { lat: 48.1258874, lng: 19.23725 },
    { lat: 48.1256601, lng: 19.2373937 },
    { lat: 48.1254965, lng: 19.2375965 },
    { lat: 48.125185, lng: 19.2383023 },
    { lat: 48.1250452, lng: 19.2382791 },
    { lat: 48.1248497, lng: 19.2385697 },
    { lat: 48.1246491, lng: 19.2387081 },
    { lat: 48.124074, lng: 19.2394183 },
    { lat: 48.1235842, lng: 19.2396387 },
    { lat: 48.1233849, lng: 19.2401693 },
    { lat: 48.1230527, lng: 19.2404035 },
    { lat: 48.1226664, lng: 19.2409911 },
    { lat: 48.1220945, lng: 19.2415535 },
    { lat: 48.1219319, lng: 19.2418024 },
    { lat: 48.1216352, lng: 19.2425361 },
    { lat: 48.1213834, lng: 19.2428501 },
    { lat: 48.1210588, lng: 19.2430327 },
    { lat: 48.1204252, lng: 19.2435848 },
    { lat: 48.1195577, lng: 19.2445615 },
    { lat: 48.1193472, lng: 19.2447066 },
    { lat: 48.1186011, lng: 19.2459525 },
    { lat: 48.1182771, lng: 19.2466352 },
    { lat: 48.1178555, lng: 19.2478274 },
    { lat: 48.1175274, lng: 19.2484508 },
    { lat: 48.1174407, lng: 19.2488653 },
    { lat: 48.1171101, lng: 19.2496696 },
    { lat: 48.116573, lng: 19.2512941 },
    { lat: 48.1157294, lng: 19.2543065 },
    { lat: 48.1145995, lng: 19.2570889 },
    { lat: 48.1136919, lng: 19.2596693 },
    { lat: 48.1135811, lng: 19.2596401 },
    { lat: 48.1133052, lng: 19.2598299 },
    { lat: 48.1132566, lng: 19.2599174 },
    { lat: 48.1132655, lng: 19.2600991 },
    { lat: 48.1131235, lng: 19.2604278 },
    { lat: 48.1128045, lng: 19.2605175 },
    { lat: 48.1126944, lng: 19.2611496 },
    { lat: 48.1123799, lng: 19.261332 },
    { lat: 48.1127791, lng: 19.262261 },
    { lat: 48.1128515, lng: 19.2629603 },
    { lat: 48.1121808, lng: 19.2646928 },
    { lat: 48.111742, lng: 19.2647998 },
    { lat: 48.1116326, lng: 19.264751 },
    { lat: 48.1114203, lng: 19.2629418 },
    { lat: 48.1114317, lng: 19.2621392 },
    { lat: 48.1061581, lng: 19.261339 },
    { lat: 48.1060735, lng: 19.2608795 },
    { lat: 48.1047325, lng: 19.2604714 },
    { lat: 48.1047186, lng: 19.2604384 },
    { lat: 48.1030964, lng: 19.2747239 },
    { lat: 48.1031619, lng: 19.2759049 },
    { lat: 48.1036255, lng: 19.276306 },
    { lat: 48.1042632, lng: 19.2787791 },
    { lat: 48.1043726, lng: 19.2794983 },
    { lat: 48.1044591, lng: 19.2816031 },
    { lat: 48.1045928, lng: 19.2820781 },
    { lat: 48.1049486, lng: 19.2838525 },
    { lat: 48.1051511, lng: 19.285632 },
    { lat: 48.1054312, lng: 19.286526 },
    { lat: 48.1057334, lng: 19.2882678 },
    { lat: 48.1058102, lng: 19.289338 },
    { lat: 48.1057497, lng: 19.2899938 },
    { lat: 48.1059383, lng: 19.2908815 },
    { lat: 48.1056656, lng: 19.2913518 },
    { lat: 48.1054271, lng: 19.2919176 },
    { lat: 48.1052493, lng: 19.2925268 },
    { lat: 48.1058262, lng: 19.2928685 },
    { lat: 48.1062325, lng: 19.2932545 },
    { lat: 48.1065263, lng: 19.2932925 },
    { lat: 48.1070461, lng: 19.2931068 },
    { lat: 48.1089514, lng: 19.2916049 },
    { lat: 48.1099667, lng: 19.2904161 },
    { lat: 48.110388, lng: 19.2904333 },
    { lat: 48.111264, lng: 19.2910876 },
    { lat: 48.1116382, lng: 19.2911821 },
    { lat: 48.1122684, lng: 19.291577 },
    { lat: 48.1126376, lng: 19.2915177 },
    { lat: 48.1126233, lng: 19.2917143 },
    { lat: 48.1126761, lng: 19.2917812 },
    { lat: 48.1128918, lng: 19.2918108 },
    { lat: 48.1131611, lng: 19.2920641 },
    { lat: 48.114404, lng: 19.2927066 },
    { lat: 48.1147518, lng: 19.292972 },
    { lat: 48.1162087, lng: 19.2943787 },
    { lat: 48.1174807, lng: 19.2966697 },
    { lat: 48.1176295, lng: 19.296854 },
    { lat: 48.1178859, lng: 19.2969843 },
    { lat: 48.118311, lng: 19.2970801 },
    { lat: 48.1188921, lng: 19.2974162 },
    { lat: 48.1190953, lng: 19.2976094 },
    { lat: 48.1193566, lng: 19.2985798 },
    { lat: 48.1194636, lng: 19.2972987 },
    { lat: 48.1200279, lng: 19.2947115 },
    { lat: 48.1208751, lng: 19.2929583 },
    { lat: 48.1242331, lng: 19.2919891 },
    { lat: 48.1257438, lng: 19.2935514 },
    { lat: 48.1270628, lng: 19.294297 },
    { lat: 48.1281913, lng: 19.2950707 },
    { lat: 48.1282113, lng: 19.295084 },
    { lat: 48.1286694, lng: 19.2925636 },
    { lat: 48.1293553, lng: 19.2883479 },
    { lat: 48.1296165, lng: 19.285951 },
    { lat: 48.1298882, lng: 19.2852558 },
    { lat: 48.1301812, lng: 19.2837957 },
    { lat: 48.1302367, lng: 19.2832171 },
    { lat: 48.130225, lng: 19.2828401 },
    { lat: 48.1301518, lng: 19.2826554 },
    { lat: 48.1301262, lng: 19.2823579 },
    { lat: 48.1302775, lng: 19.2820402 },
    { lat: 48.1303388, lng: 19.2817943 },
    { lat: 48.1304177, lng: 19.2817706 },
    { lat: 48.130535, lng: 19.2814234 },
    { lat: 48.1305406, lng: 19.2810279 },
    { lat: 48.1303007, lng: 19.2798737 },
    { lat: 48.1303562, lng: 19.2789517 },
    { lat: 48.130301, lng: 19.2787432 },
    { lat: 48.1303197, lng: 19.2784088 },
    { lat: 48.1296401, lng: 19.2778111 },
    { lat: 48.1290214, lng: 19.2770728 },
    { lat: 48.1284383, lng: 19.2759093 },
    { lat: 48.127869, lng: 19.2745627 },
    { lat: 48.1240701, lng: 19.2736805 },
    { lat: 48.1240358, lng: 19.2735519 },
    { lat: 48.1232135, lng: 19.2737023 },
    { lat: 48.1231247, lng: 19.2732903 },
    { lat: 48.1228409, lng: 19.2727391 },
    { lat: 48.1226542, lng: 19.2722422 },
    { lat: 48.1224893, lng: 19.2714705 },
    { lat: 48.1226188, lng: 19.2712339 },
    { lat: 48.1230052, lng: 19.2699342 },
    { lat: 48.1230317, lng: 19.2696932 },
    { lat: 48.1229972, lng: 19.2685246 },
    { lat: 48.1236852, lng: 19.2681841 },
    { lat: 48.1245733, lng: 19.2676021 },
    { lat: 48.1248687, lng: 19.2686658 },
    { lat: 48.1259369, lng: 19.2679894 },
    { lat: 48.1247139, lng: 19.2642468 },
    { lat: 48.1256186, lng: 19.2636802 },
    { lat: 48.1253204, lng: 19.2627668 },
    { lat: 48.1257539, lng: 19.2626427 },
    { lat: 48.1263577, lng: 19.2621694 },
    { lat: 48.127024, lng: 19.2617808 },
    { lat: 48.1272287, lng: 19.2615659 },
    { lat: 48.1286229, lng: 19.2589986 },
    { lat: 48.1289418, lng: 19.2586699 },
    { lat: 48.1292419, lng: 19.2582131 },
    { lat: 48.1292766, lng: 19.2577478 },
    { lat: 48.1295148, lng: 19.2571298 },
    { lat: 48.1295996, lng: 19.2566763 },
    { lat: 48.1299133, lng: 19.2556228 },
    { lat: 48.1299779, lng: 19.2551461 },
    { lat: 48.1302904, lng: 19.2546177 },
    { lat: 48.1304576, lng: 19.2542124 },
    { lat: 48.1307985, lng: 19.2539897 },
    { lat: 48.1308512, lng: 19.2538706 },
    { lat: 48.1309526, lng: 19.2534743 },
    { lat: 48.1311741, lng: 19.2529801 },
    { lat: 48.1313338, lng: 19.252281 },
    { lat: 48.1317312, lng: 19.2513449 },
    { lat: 48.131725, lng: 19.2510146 },
    { lat: 48.1319536, lng: 19.2506605 },
    { lat: 48.1321749, lng: 19.2496801 },
    { lat: 48.1323493, lng: 19.2494408 },
    { lat: 48.1326297, lng: 19.2486964 },
    { lat: 48.1326917, lng: 19.2482595 },
    { lat: 48.1328663, lng: 19.2480958 },
    { lat: 48.1327972, lng: 19.247953 },
    { lat: 48.1322791, lng: 19.2473624 },
    { lat: 48.1334594, lng: 19.2455888 },
    { lat: 48.1326445, lng: 19.2437458 },
    { lat: 48.1323644, lng: 19.2432549 },
    { lat: 48.1327183, lng: 19.2419632 },
    { lat: 48.1348157, lng: 19.2330506 },
    { lat: 48.134207, lng: 19.2322474 },
    { lat: 48.1313928, lng: 19.2281704 },
    { lat: 48.13119, lng: 19.2278754 },
  ],
  HornáStrehová: [
    { lat: 48.2833018, lng: 19.4129354 },
    { lat: 48.283166, lng: 19.4130163 },
    { lat: 48.2829379, lng: 19.4132702 },
    { lat: 48.2828974, lng: 19.4136082 },
    { lat: 48.2831256, lng: 19.4138334 },
    { lat: 48.2832652, lng: 19.414114 },
    { lat: 48.2832933, lng: 19.4143276 },
    { lat: 48.2827302, lng: 19.4150516 },
    { lat: 48.2824, lng: 19.4153142 },
    { lat: 48.282294, lng: 19.4154808 },
    { lat: 48.2822499, lng: 19.4157625 },
    { lat: 48.2824542, lng: 19.4160076 },
    { lat: 48.2824916, lng: 19.4161293 },
    { lat: 48.2824471, lng: 19.4162603 },
    { lat: 48.2822693, lng: 19.4165509 },
    { lat: 48.2820362, lng: 19.4167721 },
    { lat: 48.2815981, lng: 19.4168957 },
    { lat: 48.2813965, lng: 19.4170186 },
    { lat: 48.2811064, lng: 19.4169174 },
    { lat: 48.2809458, lng: 19.4172869 },
    { lat: 48.2806512, lng: 19.4182792 },
    { lat: 48.2800926, lng: 19.418641 },
    { lat: 48.2799556, lng: 19.4189156 },
    { lat: 48.2799691, lng: 19.4193918 },
    { lat: 48.2798633, lng: 19.4196369 },
    { lat: 48.2797519, lng: 19.4198522 },
    { lat: 48.2793536, lng: 19.4202953 },
    { lat: 48.2793616, lng: 19.4208238 },
    { lat: 48.279305, lng: 19.4211514 },
    { lat: 48.2793439, lng: 19.4213787 },
    { lat: 48.2794004, lng: 19.4215004 },
    { lat: 48.279499, lng: 19.4215572 },
    { lat: 48.2797804, lng: 19.42155 },
    { lat: 48.2799043, lng: 19.4219091 },
    { lat: 48.2783643, lng: 19.4240244 },
    { lat: 48.2784357, lng: 19.4240777 },
    { lat: 48.2781481, lng: 19.4244783 },
    { lat: 48.278101, lng: 19.4247012 },
    { lat: 48.2781439, lng: 19.4249903 },
    { lat: 48.278007, lng: 19.4252422 },
    { lat: 48.2775154, lng: 19.4257146 },
    { lat: 48.2773803, lng: 19.4259094 },
    { lat: 48.2772075, lng: 19.4263756 },
    { lat: 48.2767562, lng: 19.4271562 },
    { lat: 48.2764406, lng: 19.4279651 },
    { lat: 48.2761059, lng: 19.4285405 },
    { lat: 48.2759364, lng: 19.4287212 },
    { lat: 48.2756811, lng: 19.4292817 },
    { lat: 48.2752948, lng: 19.429479 },
    { lat: 48.2747021, lng: 19.4301022 },
    { lat: 48.2744633, lng: 19.4305824 },
    { lat: 48.2742468, lng: 19.4313695 },
    { lat: 48.2740532, lng: 19.4315662 },
    { lat: 48.2733124, lng: 19.4319871 },
    { lat: 48.2730103, lng: 19.4322974 },
    { lat: 48.2737499, lng: 19.4332621 },
    { lat: 48.2742068, lng: 19.4336769 },
    { lat: 48.2741171, lng: 19.433922 },
    { lat: 48.2738142, lng: 19.4341957 },
    { lat: 48.2739545, lng: 19.4343722 },
    { lat: 48.273767, lng: 19.4346776 },
    { lat: 48.273053, lng: 19.4351879 },
    { lat: 48.2723654, lng: 19.4354258 },
    { lat: 48.2720802, lng: 19.435618 },
    { lat: 48.2718185, lng: 19.4354914 },
    { lat: 48.2717943, lng: 19.4355739 },
    { lat: 48.2717046, lng: 19.4356315 },
    { lat: 48.2716729, lng: 19.4357948 },
    { lat: 48.2716288, lng: 19.4355055 },
    { lat: 48.2714982, lng: 19.4356314 },
    { lat: 48.2715594, lng: 19.4357569 },
    { lat: 48.2714288, lng: 19.4359713 },
    { lat: 48.27148, lng: 19.4361067 },
    { lat: 48.2716306, lng: 19.4358339 },
    { lat: 48.271742, lng: 19.4358975 },
    { lat: 48.2717548, lng: 19.4359538 },
    { lat: 48.2716879, lng: 19.4360419 },
    { lat: 48.2717592, lng: 19.4362625 },
    { lat: 48.271699, lng: 19.436495 },
    { lat: 48.2717939, lng: 19.436549 },
    { lat: 48.2717146, lng: 19.4366596 },
    { lat: 48.2714611, lng: 19.4367076 },
    { lat: 48.2714122, lng: 19.4368872 },
    { lat: 48.2714859, lng: 19.4368985 },
    { lat: 48.2715571, lng: 19.4367849 },
    { lat: 48.2716426, lng: 19.4370917 },
    { lat: 48.2714812, lng: 19.4372301 },
    { lat: 48.271498, lng: 19.4374403 },
    { lat: 48.2713775, lng: 19.4375903 },
    { lat: 48.2718608, lng: 19.4381331 },
    { lat: 48.2712066, lng: 19.4392024 },
    { lat: 48.2711552, lng: 19.4393985 },
    { lat: 48.2711632, lng: 19.4395692 },
    { lat: 48.2710901, lng: 19.4396962 },
    { lat: 48.2707413, lng: 19.440183 },
    { lat: 48.2705447, lng: 19.4403668 },
    { lat: 48.2704548, lng: 19.4407793 },
    { lat: 48.2702134, lng: 19.4413004 },
    { lat: 48.2700597, lng: 19.4412653 },
    { lat: 48.2698892, lng: 19.4413573 },
    { lat: 48.269681, lng: 19.4417447 },
    { lat: 48.2693669, lng: 19.4417294 },
    { lat: 48.26884, lng: 19.4419308 },
    { lat: 48.2686417, lng: 19.4421474 },
    { lat: 48.2684767, lng: 19.4427357 },
    { lat: 48.2683787, lng: 19.4426424 },
    { lat: 48.2683644, lng: 19.443512 },
    { lat: 48.2682905, lng: 19.4444307 },
    { lat: 48.2683212, lng: 19.4444604 },
    { lat: 48.2683274, lng: 19.4448085 },
    { lat: 48.2682536, lng: 19.4450108 },
    { lat: 48.2681785, lng: 19.4458341 },
    { lat: 48.2680533, lng: 19.4458845 },
    { lat: 48.26787, lng: 19.4463711 },
    { lat: 48.2678656, lng: 19.4471158 },
    { lat: 48.2674601, lng: 19.4478385 },
    { lat: 48.2673125, lng: 19.4479839 },
    { lat: 48.2671044, lng: 19.4481342 },
    { lat: 48.2665996, lng: 19.4480669 },
    { lat: 48.2665288, lng: 19.4481182 },
    { lat: 48.266615, lng: 19.4490426 },
    { lat: 48.2663932, lng: 19.4493833 },
    { lat: 48.2659429, lng: 19.4497767 },
    { lat: 48.2659683, lng: 19.4498593 },
    { lat: 48.2654837, lng: 19.4503175 },
    { lat: 48.2653533, lng: 19.4505615 },
    { lat: 48.2651233, lng: 19.4512801 },
    { lat: 48.2653811, lng: 19.4515607 },
    { lat: 48.2655681, lng: 19.4521119 },
    { lat: 48.2655718, lng: 19.4531005 },
    { lat: 48.2659417, lng: 19.4534184 },
    { lat: 48.2657106, lng: 19.4542556 },
    { lat: 48.2649931, lng: 19.4554914 },
    { lat: 48.2647995, lng: 19.4556653 },
    { lat: 48.2646502, lng: 19.4555318 },
    { lat: 48.2644865, lng: 19.4559851 },
    { lat: 48.2646036, lng: 19.4560534 },
    { lat: 48.2643753, lng: 19.4565886 },
    { lat: 48.2640956, lng: 19.4570202 },
    { lat: 48.2635813, lng: 19.4573931 },
    { lat: 48.2629933, lng: 19.4572785 },
    { lat: 48.2628292, lng: 19.4574362 },
    { lat: 48.2627036, lng: 19.4577125 },
    { lat: 48.2627447, lng: 19.4580092 },
    { lat: 48.2656197, lng: 19.4600757 },
    { lat: 48.266387, lng: 19.4608863 },
    { lat: 48.2670404, lng: 19.4617974 },
    { lat: 48.2676039, lng: 19.4623719 },
    { lat: 48.267472, lng: 19.4633561 },
    { lat: 48.2677015, lng: 19.4637331 },
    { lat: 48.2684101, lng: 19.4646175 },
    { lat: 48.2704198, lng: 19.4667614 },
    { lat: 48.2705056, lng: 19.4668843 },
    { lat: 48.2705886, lng: 19.4667805 },
    { lat: 48.2786138, lng: 19.4568994 },
    { lat: 48.2875845, lng: 19.4470805 },
    { lat: 48.2881294, lng: 19.4463729 },
    { lat: 48.2899066, lng: 19.4446459 },
    { lat: 48.2937737, lng: 19.4412953 },
    { lat: 48.2967567, lng: 19.4384448 },
    { lat: 48.2992656, lng: 19.4357192 },
    { lat: 48.3003619, lng: 19.4366459 },
    { lat: 48.3003441, lng: 19.4358588 },
    { lat: 48.3002949, lng: 19.4348644 },
    { lat: 48.2995617, lng: 19.4296847 },
    { lat: 48.2994461, lng: 19.4292672 },
    { lat: 48.2997169, lng: 19.4288899 },
    { lat: 48.2994752, lng: 19.4283771 },
    { lat: 48.2996138, lng: 19.4283054 },
    { lat: 48.2994317, lng: 19.4274034 },
    { lat: 48.2983765, lng: 19.4268289 },
    { lat: 48.29702, lng: 19.4264042 },
    { lat: 48.2965664, lng: 19.4257249 },
    { lat: 48.2960398, lng: 19.4239052 },
    { lat: 48.2960161, lng: 19.4233587 },
    { lat: 48.2961111, lng: 19.4230294 },
    { lat: 48.2961386, lng: 19.4220372 },
    { lat: 48.2961065, lng: 19.4212871 },
    { lat: 48.2959089, lng: 19.4199893 },
    { lat: 48.2961607, lng: 19.4192597 },
    { lat: 48.2962183, lng: 19.4185446 },
    { lat: 48.2961603, lng: 19.4180365 },
    { lat: 48.2962047, lng: 19.4171632 },
    { lat: 48.2960282, lng: 19.4164054 },
    { lat: 48.2958516, lng: 19.4160129 },
    { lat: 48.2954597, lng: 19.4148022 },
    { lat: 48.2950019, lng: 19.4140099 },
    { lat: 48.2948172, lng: 19.4139159 },
    { lat: 48.2945738, lng: 19.4135641 },
    { lat: 48.2940691, lng: 19.4133035 },
    { lat: 48.2939508, lng: 19.4133036 },
    { lat: 48.2935725, lng: 19.4134808 },
    { lat: 48.2933621, lng: 19.4135113 },
    { lat: 48.2931983, lng: 19.4136674 },
    { lat: 48.2930099, lng: 19.4136888 },
    { lat: 48.2927709, lng: 19.4139287 },
    { lat: 48.2922077, lng: 19.4142649 },
    { lat: 48.2914312, lng: 19.4144111 },
    { lat: 48.2911167, lng: 19.4147421 },
    { lat: 48.2909922, lng: 19.4147309 },
    { lat: 48.2908468, lng: 19.4148971 },
    { lat: 48.2907965, lng: 19.4150971 },
    { lat: 48.2905484, lng: 19.4151444 },
    { lat: 48.2899953, lng: 19.4154087 },
    { lat: 48.2897572, lng: 19.4152846 },
    { lat: 48.2894962, lng: 19.4154139 },
    { lat: 48.2889478, lng: 19.4160426 },
    { lat: 48.2887547, lng: 19.4159194 },
    { lat: 48.2886109, lng: 19.416095 },
    { lat: 48.2884856, lng: 19.4159724 },
    { lat: 48.2882732, lng: 19.4162201 },
    { lat: 48.287926, lng: 19.4162201 },
    { lat: 48.2875045, lng: 19.4163869 },
    { lat: 48.287335, lng: 19.4163526 },
    { lat: 48.2870815, lng: 19.4161664 },
    { lat: 48.286986, lng: 19.4160534 },
    { lat: 48.286884, lng: 19.4156849 },
    { lat: 48.2866959, lng: 19.4156635 },
    { lat: 48.2864114, lng: 19.4157952 },
    { lat: 48.2863089, lng: 19.4157251 },
    { lat: 48.2859892, lng: 19.4150163 },
    { lat: 48.2858637, lng: 19.4145816 },
    { lat: 48.2857676, lng: 19.4138579 },
    { lat: 48.285666, lng: 19.413496 },
    { lat: 48.2856955, lng: 19.4134763 },
    { lat: 48.2845693, lng: 19.4117813 },
    { lat: 48.2843953, lng: 19.4116087 },
    { lat: 48.2840527, lng: 19.4121047 },
    { lat: 48.2840076, lng: 19.4123441 },
    { lat: 48.2837719, lng: 19.4124835 },
    { lat: 48.2835209, lng: 19.4127536 },
    { lat: 48.2833629, lng: 19.4130343 },
    { lat: 48.2833018, lng: 19.4129354 },
  ],
  Ľuboriečka: [
    { lat: 48.2390389, lng: 19.5337263 },
    { lat: 48.2384371, lng: 19.5349429 },
    { lat: 48.2382704, lng: 19.536551 },
    { lat: 48.2382121, lng: 19.5373389 },
    { lat: 48.2382141, lng: 19.5381369 },
    { lat: 48.2385777, lng: 19.5397837 },
    { lat: 48.2384668, lng: 19.5399186 },
    { lat: 48.2384987, lng: 19.5401956 },
    { lat: 48.2383792, lng: 19.5402422 },
    { lat: 48.2382421, lng: 19.5405651 },
    { lat: 48.2381359, lng: 19.540587 },
    { lat: 48.238073, lng: 19.5409318 },
    { lat: 48.2380234, lng: 19.5409324 },
    { lat: 48.2379154, lng: 19.5411553 },
    { lat: 48.2378521, lng: 19.5414662 },
    { lat: 48.2377886, lng: 19.5415333 },
    { lat: 48.2377347, lng: 19.541465 },
    { lat: 48.2376385, lng: 19.5415509 },
    { lat: 48.237589, lng: 19.54184 },
    { lat: 48.237745, lng: 19.541981 },
    { lat: 48.23791, lng: 19.54219 },
    { lat: 48.2384, lng: 19.542392 },
    { lat: 48.238405, lng: 19.542334 },
    { lat: 48.239169, lng: 19.542562 },
    { lat: 48.239439, lng: 19.542775 },
    { lat: 48.239913, lng: 19.542977 },
    { lat: 48.240379, lng: 19.543283 },
    { lat: 48.240727, lng: 19.543449 },
    { lat: 48.240957, lng: 19.54372 },
    { lat: 48.241575, lng: 19.544002 },
    { lat: 48.241678, lng: 19.543899 },
    { lat: 48.242286, lng: 19.544343 },
    { lat: 48.243012, lng: 19.544455 },
    { lat: 48.24303, lng: 19.544462 },
    { lat: 48.243496, lng: 19.54463 },
    { lat: 48.243724, lng: 19.544609 },
    { lat: 48.243799, lng: 19.544601 },
    { lat: 48.244505, lng: 19.545559 },
    { lat: 48.244652, lng: 19.54564 },
    { lat: 48.244817, lng: 19.545731 },
    { lat: 48.24549, lng: 19.546089 },
    { lat: 48.24582, lng: 19.545991 },
    { lat: 48.246209, lng: 19.546177 },
    { lat: 48.246387, lng: 19.546193 },
    { lat: 48.24692, lng: 19.546214 },
    { lat: 48.247342, lng: 19.546399 },
    { lat: 48.247639, lng: 19.546634 },
    { lat: 48.248298, lng: 19.54707 },
    { lat: 48.249049, lng: 19.54744 },
    { lat: 48.249092, lng: 19.547424 },
    { lat: 48.249167, lng: 19.547422 },
    { lat: 48.249556, lng: 19.547416 },
    { lat: 48.249663, lng: 19.547295 },
    { lat: 48.249958, lng: 19.546961 },
    { lat: 48.250666, lng: 19.546129 },
    { lat: 48.250702, lng: 19.546087 },
    { lat: 48.250748, lng: 19.546032 },
    { lat: 48.250756, lng: 19.546025 },
    { lat: 48.250819, lng: 19.545971 },
    { lat: 48.251939, lng: 19.544997 },
    { lat: 48.252407, lng: 19.544841 },
    { lat: 48.253186, lng: 19.544486 },
    { lat: 48.254044, lng: 19.544376 },
    { lat: 48.254781, lng: 19.543991 },
    { lat: 48.255285, lng: 19.543668 },
    { lat: 48.256289, lng: 19.543317 },
    { lat: 48.257201, lng: 19.543061 },
    { lat: 48.258487, lng: 19.54284 },
    { lat: 48.259937, lng: 19.542729 },
    { lat: 48.260049, lng: 19.542695 },
    { lat: 48.26056, lng: 19.542536 },
    { lat: 48.261014, lng: 19.5422 },
    { lat: 48.262405, lng: 19.541428 },
    { lat: 48.262461, lng: 19.541458 },
    { lat: 48.262859, lng: 19.541672 },
    { lat: 48.263449, lng: 19.54199 },
    { lat: 48.26406, lng: 19.541967 },
    { lat: 48.264076, lng: 19.541966 },
    { lat: 48.264465, lng: 19.542232 },
    { lat: 48.264714, lng: 19.542523 },
    { lat: 48.265306, lng: 19.543194 },
    { lat: 48.265543, lng: 19.5432 },
    { lat: 48.265921, lng: 19.543504 },
    { lat: 48.266314, lng: 19.544008 },
    { lat: 48.266681, lng: 19.544697 },
    { lat: 48.266816, lng: 19.544946 },
    { lat: 48.267047, lng: 19.545376 },
    { lat: 48.267356, lng: 19.545783 },
    { lat: 48.267521, lng: 19.545994 },
    { lat: 48.267584, lng: 19.548646 },
    { lat: 48.267593, lng: 19.54898 },
    { lat: 48.26758, lng: 19.549014 },
    { lat: 48.267463, lng: 19.549359 },
    { lat: 48.266935, lng: 19.550905 },
    { lat: 48.266907, lng: 19.551496 },
    { lat: 48.266948, lng: 19.551811 },
    { lat: 48.266978, lng: 19.552055 },
    { lat: 48.267007, lng: 19.552287 },
    { lat: 48.267068, lng: 19.552763 },
    { lat: 48.267083, lng: 19.552883 },
    { lat: 48.267223, lng: 19.553854 },
    { lat: 48.267369, lng: 19.554657 },
    { lat: 48.26743, lng: 19.554939 },
    { lat: 48.267679, lng: 19.556086 },
    { lat: 48.267877, lng: 19.557296 },
    { lat: 48.267625, lng: 19.558785 },
    { lat: 48.267791, lng: 19.559228 },
    { lat: 48.268084, lng: 19.56001 },
    { lat: 48.268068, lng: 19.560442 },
    { lat: 48.26779, lng: 19.560988 },
    { lat: 48.267721, lng: 19.561596 },
    { lat: 48.267621, lng: 19.562919 },
    { lat: 48.268189, lng: 19.563994 },
    { lat: 48.268198, lng: 19.564044 },
    { lat: 48.2682, lng: 19.564056 },
    { lat: 48.268287, lng: 19.564142 },
    { lat: 48.268412, lng: 19.56398 },
    { lat: 48.268411, lng: 19.563918 },
    { lat: 48.268321, lng: 19.563697 },
    { lat: 48.26834, lng: 19.563638 },
    { lat: 48.268362, lng: 19.563574 },
    { lat: 48.268368, lng: 19.563557 },
    { lat: 48.268376, lng: 19.563534 },
    { lat: 48.268433, lng: 19.563506 },
    { lat: 48.268417, lng: 19.563402 },
    { lat: 48.268465, lng: 19.563265 },
    { lat: 48.268591, lng: 19.563159 },
    { lat: 48.268548, lng: 19.563017 },
    { lat: 48.268424, lng: 19.5628 },
    { lat: 48.268531, lng: 19.562727 },
    { lat: 48.2686, lng: 19.562634 },
    { lat: 48.268544, lng: 19.562484 },
    { lat: 48.268563, lng: 19.562458 },
    { lat: 48.26869, lng: 19.562481 },
    { lat: 48.26873, lng: 19.562444 },
    { lat: 48.268738, lng: 19.562404 },
    { lat: 48.268735, lng: 19.562303 },
    { lat: 48.268784, lng: 19.561966 },
    { lat: 48.268865, lng: 19.561846 },
    { lat: 48.268879, lng: 19.561684 },
    { lat: 48.268924, lng: 19.561586 },
    { lat: 48.269089, lng: 19.561473 },
    { lat: 48.269119, lng: 19.561367 },
    { lat: 48.269002, lng: 19.561248 },
    { lat: 48.268996, lng: 19.561193 },
    { lat: 48.269089, lng: 19.561056 },
    { lat: 48.269062, lng: 19.560983 },
    { lat: 48.269075, lng: 19.560924 },
    { lat: 48.269172, lng: 19.560782 },
    { lat: 48.269209, lng: 19.560564 },
    { lat: 48.269209, lng: 19.56051 },
    { lat: 48.269181, lng: 19.560463 },
    { lat: 48.269161, lng: 19.560401 },
    { lat: 48.269176, lng: 19.560361 },
    { lat: 48.269237, lng: 19.560312 },
    { lat: 48.269271, lng: 19.560234 },
    { lat: 48.269293, lng: 19.560079 },
    { lat: 48.269316, lng: 19.560017 },
    { lat: 48.26938, lng: 19.559987 },
    { lat: 48.269412, lng: 19.559935 },
    { lat: 48.26943, lng: 19.559866 },
    { lat: 48.269437, lng: 19.559714 },
    { lat: 48.269771, lng: 19.559476 },
    { lat: 48.269719, lng: 19.559394 },
    { lat: 48.269639, lng: 19.559357 },
    { lat: 48.269683, lng: 19.559153 },
    { lat: 48.269802, lng: 19.558949 },
    { lat: 48.27003, lng: 19.558859 },
    { lat: 48.270036, lng: 19.559078 },
    { lat: 48.270044, lng: 19.559116 },
    { lat: 48.270154, lng: 19.559082 },
    { lat: 48.270372, lng: 19.558848 },
    { lat: 48.270385, lng: 19.558617 },
    { lat: 48.270363, lng: 19.558427 },
    { lat: 48.270397, lng: 19.558367 },
    { lat: 48.270472, lng: 19.558234 },
    { lat: 48.270466, lng: 19.55821 },
    { lat: 48.270405, lng: 19.558157 },
    { lat: 48.270386, lng: 19.5581 },
    { lat: 48.270396, lng: 19.557949 },
    { lat: 48.270339, lng: 19.557747 },
    { lat: 48.270342, lng: 19.557626 },
    { lat: 48.270358, lng: 19.557621 },
    { lat: 48.270488, lng: 19.557581 },
    { lat: 48.270722, lng: 19.557553 },
    { lat: 48.270831, lng: 19.55746 },
    { lat: 48.271021, lng: 19.557079 },
    { lat: 48.271193, lng: 19.556794 },
    { lat: 48.27128, lng: 19.556827 },
    { lat: 48.271442, lng: 19.556809 },
    { lat: 48.271497, lng: 19.556722 },
    { lat: 48.271811, lng: 19.556393 },
    { lat: 48.271952, lng: 19.556414 },
    { lat: 48.272006, lng: 19.556198 },
    { lat: 48.272016, lng: 19.555963 },
    { lat: 48.272056, lng: 19.555914 },
    { lat: 48.272184, lng: 19.555877 },
    { lat: 48.272277, lng: 19.555784 },
    { lat: 48.272286, lng: 19.555607 },
    { lat: 48.272261, lng: 19.555482 },
    { lat: 48.272304, lng: 19.555203 },
    { lat: 48.27239, lng: 19.555234 },
    { lat: 48.272468, lng: 19.555143 },
    { lat: 48.272546, lng: 19.555134 },
    { lat: 48.272571, lng: 19.555007 },
    { lat: 48.272697, lng: 19.554861 },
    { lat: 48.272866, lng: 19.554854 },
    { lat: 48.273005, lng: 19.554797 },
    { lat: 48.27301, lng: 19.554665 },
    { lat: 48.272978, lng: 19.554525 },
    { lat: 48.273043, lng: 19.554502 },
    { lat: 48.273055, lng: 19.554414 },
    { lat: 48.273123, lng: 19.554458 },
    { lat: 48.27322, lng: 19.554448 },
    { lat: 48.273403, lng: 19.5542 },
    { lat: 48.273384, lng: 19.554098 },
    { lat: 48.273355, lng: 19.554035 },
    { lat: 48.273382, lng: 19.553839 },
    { lat: 48.273431, lng: 19.553742 },
    { lat: 48.273467, lng: 19.553539 },
    { lat: 48.273565, lng: 19.553446 },
    { lat: 48.273617, lng: 19.553354 },
    { lat: 48.273638, lng: 19.553269 },
    { lat: 48.273721, lng: 19.553104 },
    { lat: 48.273812, lng: 19.55304 },
    { lat: 48.273886, lng: 19.553125 },
    { lat: 48.273942, lng: 19.553204 },
    { lat: 48.274043, lng: 19.553174 },
    { lat: 48.274126, lng: 19.553089 },
    { lat: 48.274116, lng: 19.552933 },
    { lat: 48.274192, lng: 19.552872 },
    { lat: 48.274209, lng: 19.552798 },
    { lat: 48.27436, lng: 19.552802 },
    { lat: 48.274453, lng: 19.552613 },
    { lat: 48.274527, lng: 19.552624 },
    { lat: 48.274539, lng: 19.552365 },
    { lat: 48.274638, lng: 19.552398 },
    { lat: 48.274742, lng: 19.552505 },
    { lat: 48.274961, lng: 19.552565 },
    { lat: 48.275112, lng: 19.552547 },
    { lat: 48.275164, lng: 19.552356 },
    { lat: 48.275282, lng: 19.552315 },
    { lat: 48.275294, lng: 19.552235 },
    { lat: 48.275341, lng: 19.55215 },
    { lat: 48.275532, lng: 19.551973 },
    { lat: 48.275617, lng: 19.551898 },
    { lat: 48.275706, lng: 19.551818 },
    { lat: 48.275925, lng: 19.551812 },
    { lat: 48.275984, lng: 19.551899 },
    { lat: 48.27616, lng: 19.551886 },
    { lat: 48.276322, lng: 19.551805 },
    { lat: 48.276489, lng: 19.551683 },
    { lat: 48.276481, lng: 19.551639 },
    { lat: 48.276339, lng: 19.551531 },
    { lat: 48.276459, lng: 19.551297 },
    { lat: 48.276594, lng: 19.551327 },
    { lat: 48.276799, lng: 19.551304 },
    { lat: 48.276864, lng: 19.551081 },
    { lat: 48.276938, lng: 19.551014 },
    { lat: 48.277132, lng: 19.5511 },
    { lat: 48.277214, lng: 19.551058 },
    { lat: 48.277292, lng: 19.551038 },
    { lat: 48.277339, lng: 19.55093 },
    { lat: 48.277354, lng: 19.550846 },
    { lat: 48.277389, lng: 19.550771 },
    { lat: 48.277404, lng: 19.550704 },
    { lat: 48.277389, lng: 19.550606 },
    { lat: 48.277467, lng: 19.550512 },
    { lat: 48.277478, lng: 19.55037 },
    { lat: 48.277529, lng: 19.550316 },
    { lat: 48.277589, lng: 19.55034 },
    { lat: 48.277598, lng: 19.550397 },
    { lat: 48.277585, lng: 19.550469 },
    { lat: 48.277634, lng: 19.550493 },
    { lat: 48.277769, lng: 19.550349 },
    { lat: 48.277812, lng: 19.550446 },
    { lat: 48.277883, lng: 19.550466 },
    { lat: 48.27796, lng: 19.550398 },
    { lat: 48.278, lng: 19.550468 },
    { lat: 48.278096, lng: 19.550573 },
    { lat: 48.27827, lng: 19.550616 },
    { lat: 48.278384, lng: 19.55042 },
    { lat: 48.278549, lng: 19.550457 },
    { lat: 48.278693, lng: 19.550383 },
    { lat: 48.278843, lng: 19.550402 },
    { lat: 48.278916, lng: 19.550313 },
    { lat: 48.278918, lng: 19.550175 },
    { lat: 48.278869, lng: 19.550059 },
    { lat: 48.278879, lng: 19.549934 },
    { lat: 48.278954, lng: 19.549707 },
    { lat: 48.278997, lng: 19.549627 },
    { lat: 48.279035, lng: 19.549607 },
    { lat: 48.279118, lng: 19.549637 },
    { lat: 48.279141, lng: 19.549758 },
    { lat: 48.279187, lng: 19.549849 },
    { lat: 48.279349, lng: 19.549962 },
    { lat: 48.279397, lng: 19.549961 },
    { lat: 48.279571, lng: 19.549805 },
    { lat: 48.279671, lng: 19.5498 },
    { lat: 48.279752, lng: 19.549699 },
    { lat: 48.279831, lng: 19.54969 },
    { lat: 48.279903, lng: 19.549593 },
    { lat: 48.27993, lng: 19.549515 },
    { lat: 48.279965, lng: 19.54947 },
    { lat: 48.279885, lng: 19.54943 },
    { lat: 48.279841, lng: 19.549356 },
    { lat: 48.279873, lng: 19.549272 },
    { lat: 48.279874, lng: 19.54913 },
    { lat: 48.279895, lng: 19.54899 },
    { lat: 48.279864, lng: 19.548812 },
    { lat: 48.279781, lng: 19.548604 },
    { lat: 48.279849, lng: 19.548486 },
    { lat: 48.279845, lng: 19.548342 },
    { lat: 48.279819, lng: 19.548212 },
    { lat: 48.279853, lng: 19.548176 },
    { lat: 48.279885, lng: 19.548128 },
    { lat: 48.279884, lng: 19.548089 },
    { lat: 48.279971, lng: 19.547997 },
    { lat: 48.280053, lng: 19.547853 },
    { lat: 48.280203, lng: 19.547771 },
    { lat: 48.28034, lng: 19.547542 },
    { lat: 48.280439, lng: 19.547307 },
    { lat: 48.280428, lng: 19.54708 },
    { lat: 48.280452, lng: 19.547025 },
    { lat: 48.280446, lng: 19.546855 },
    { lat: 48.280455, lng: 19.546784 },
    { lat: 48.280459, lng: 19.546534 },
    { lat: 48.28043, lng: 19.546305 },
    { lat: 48.280458, lng: 19.546231 },
    { lat: 48.280439, lng: 19.546093 },
    { lat: 48.280447, lng: 19.546002 },
    { lat: 48.28047, lng: 19.545861 },
    { lat: 48.280459, lng: 19.545766 },
    { lat: 48.280472, lng: 19.545667 },
    { lat: 48.280526, lng: 19.545557 },
    { lat: 48.280608, lng: 19.545486 },
    { lat: 48.280627, lng: 19.545388 },
    { lat: 48.280667, lng: 19.545335 },
    { lat: 48.280675, lng: 19.545263 },
    { lat: 48.280694, lng: 19.545204 },
    { lat: 48.280807, lng: 19.545163 },
    { lat: 48.280925, lng: 19.54506 },
    { lat: 48.28097, lng: 19.545051 },
    { lat: 48.280999, lng: 19.544992 },
    { lat: 48.281086, lng: 19.544967 },
    { lat: 48.281119, lng: 19.544927 },
    { lat: 48.281133, lng: 19.544866 },
    { lat: 48.281194, lng: 19.544781 },
    { lat: 48.281266, lng: 19.544669 },
    { lat: 48.281291, lng: 19.544461 },
    { lat: 48.281367, lng: 19.544313 },
    { lat: 48.281424, lng: 19.544141 },
    { lat: 48.28157, lng: 19.543985 },
    { lat: 48.281637, lng: 19.543973 },
    { lat: 48.28179, lng: 19.543906 },
    { lat: 48.281908, lng: 19.543819 },
    { lat: 48.281955, lng: 19.543709 },
    { lat: 48.282059, lng: 19.543631 },
    { lat: 48.282147, lng: 19.543487 },
    { lat: 48.282417, lng: 19.543373 },
    { lat: 48.282573, lng: 19.543352 },
    { lat: 48.282756, lng: 19.543284 },
    { lat: 48.282815, lng: 19.543289 },
    { lat: 48.282913, lng: 19.543262 },
    { lat: 48.283066, lng: 19.543244 },
    { lat: 48.283169, lng: 19.543294 },
    { lat: 48.283251, lng: 19.543507 },
    { lat: 48.283277, lng: 19.543494 },
    { lat: 48.283414, lng: 19.54343 },
    { lat: 48.283751, lng: 19.543333 },
    { lat: 48.283859, lng: 19.543302 },
    { lat: 48.28396, lng: 19.543294 },
    { lat: 48.283986, lng: 19.543293 },
    { lat: 48.283842, lng: 19.541948 },
    { lat: 48.283784, lng: 19.541394 },
    { lat: 48.283779, lng: 19.541336 },
    { lat: 48.28377, lng: 19.541208 },
    { lat: 48.283767, lng: 19.541162 },
    { lat: 48.283696, lng: 19.54027 },
    { lat: 48.283685, lng: 19.540166 },
    { lat: 48.283681, lng: 19.540131 },
    { lat: 48.283668, lng: 19.540021 },
    { lat: 48.283668, lng: 19.540012 },
    { lat: 48.283569, lng: 19.539423 },
    { lat: 48.283574, lng: 19.539313 },
    { lat: 48.283587, lng: 19.539211 },
    { lat: 48.283644, lng: 19.538673 },
    { lat: 48.283679, lng: 19.538427 },
    { lat: 48.283691, lng: 19.538053 },
    { lat: 48.283685, lng: 19.537846 },
    { lat: 48.283686, lng: 19.537653 },
    { lat: 48.283884, lng: 19.536868 },
    { lat: 48.283731, lng: 19.536533 },
    { lat: 48.283787, lng: 19.535802 },
    { lat: 48.283823, lng: 19.535625 },
    { lat: 48.283696, lng: 19.535087 },
    { lat: 48.283597, lng: 19.533973 },
    { lat: 48.283506, lng: 19.533599 },
    { lat: 48.283362, lng: 19.532841 },
    { lat: 48.283293, lng: 19.532717 },
    { lat: 48.283333, lng: 19.532433 },
    { lat: 48.283203, lng: 19.532256 },
    { lat: 48.283182, lng: 19.53193 },
    { lat: 48.283233, lng: 19.531728 },
    { lat: 48.283271, lng: 19.531382 },
    { lat: 48.283446, lng: 19.530862 },
    { lat: 48.283284, lng: 19.530239 },
    { lat: 48.283282, lng: 19.529979 },
    { lat: 48.283116, lng: 19.529764 },
    { lat: 48.283081, lng: 19.529655 },
    { lat: 48.282834, lng: 19.529036 },
    { lat: 48.283019, lng: 19.528624 },
    { lat: 48.283031, lng: 19.528535 },
    { lat: 48.282919, lng: 19.528012 },
    { lat: 48.283066, lng: 19.527752 },
    { lat: 48.283118, lng: 19.527577 },
    { lat: 48.283131, lng: 19.527427 },
    { lat: 48.283091, lng: 19.527189 },
    { lat: 48.283136, lng: 19.527159 },
    { lat: 48.283186, lng: 19.527099 },
    { lat: 48.283209, lng: 19.527077 },
    { lat: 48.283282, lng: 19.527019 },
    { lat: 48.283319, lng: 19.526956 },
    { lat: 48.283321, lng: 19.526988 },
    { lat: 48.283375, lng: 19.526939 },
    { lat: 48.283345, lng: 19.526764 },
    { lat: 48.282943, lng: 19.526612 },
    { lat: 48.28256, lng: 19.526553 },
    { lat: 48.282441, lng: 19.526489 },
    { lat: 48.282333, lng: 19.526348 },
    { lat: 48.281978, lng: 19.526294 },
    { lat: 48.281557, lng: 19.526427 },
    { lat: 48.281506, lng: 19.526326 },
    { lat: 48.28146, lng: 19.526187 },
    { lat: 48.28147, lng: 19.52561 },
    { lat: 48.281184, lng: 19.5254 },
    { lat: 48.281094, lng: 19.525192 },
    { lat: 48.280798, lng: 19.524797 },
    { lat: 48.280402, lng: 19.524355 },
    { lat: 48.280253, lng: 19.524245 },
    { lat: 48.280075, lng: 19.524256 },
    { lat: 48.279893, lng: 19.524384 },
    { lat: 48.279649, lng: 19.524333 },
    { lat: 48.279566, lng: 19.524454 },
    { lat: 48.279416, lng: 19.524529 },
    { lat: 48.2793, lng: 19.524573 },
    { lat: 48.279143, lng: 19.524522 },
    { lat: 48.27866, lng: 19.524682 },
    { lat: 48.278579, lng: 19.52447 },
    { lat: 48.278432, lng: 19.524314 },
    { lat: 48.278341, lng: 19.524285 },
    { lat: 48.278291, lng: 19.52431 },
    { lat: 48.2781068, lng: 19.5244953 },
    { lat: 48.278084, lng: 19.524625 },
    { lat: 48.278039, lng: 19.524778 },
    { lat: 48.277911, lng: 19.524464 },
    { lat: 48.277855, lng: 19.524437 },
    { lat: 48.277805, lng: 19.524446 },
    { lat: 48.277699, lng: 19.524504 },
    { lat: 48.277559, lng: 19.524445 },
    { lat: 48.277499, lng: 19.524512 },
    { lat: 48.277433, lng: 19.524675 },
    { lat: 48.277377, lng: 19.524694 },
    { lat: 48.277329, lng: 19.524683 },
    { lat: 48.277323, lng: 19.524675 },
    { lat: 48.277247, lng: 19.524561 },
    { lat: 48.277162, lng: 19.524509 },
    { lat: 48.276964, lng: 19.524639 },
    { lat: 48.276833, lng: 19.52462 },
    { lat: 48.276658, lng: 19.524734 },
    { lat: 48.276554, lng: 19.524706 },
    { lat: 48.276452, lng: 19.524689 },
    { lat: 48.276339, lng: 19.524748 },
    { lat: 48.276207, lng: 19.524969 },
    { lat: 48.276181, lng: 19.525124 },
    { lat: 48.276135, lng: 19.525155 },
    { lat: 48.276052, lng: 19.525179 },
    { lat: 48.275986, lng: 19.525164 },
    { lat: 48.275933, lng: 19.525082 },
    { lat: 48.275919, lng: 19.524992 },
    { lat: 48.275916, lng: 19.524971 },
    { lat: 48.275944, lng: 19.524946 },
    { lat: 48.275949, lng: 19.524986 },
    { lat: 48.27601, lng: 19.524944 },
    { lat: 48.276043, lng: 19.52479 },
    { lat: 48.275977, lng: 19.524368 },
    { lat: 48.275945, lng: 19.523675 },
    { lat: 48.276031, lng: 19.523224 },
    { lat: 48.27607, lng: 19.523088 },
    { lat: 48.2761, lng: 19.522469 },
    { lat: 48.276023, lng: 19.522231 },
    { lat: 48.275889, lng: 19.521985 },
    { lat: 48.275862, lng: 19.521543 },
    { lat: 48.275704, lng: 19.52128 },
    { lat: 48.275646, lng: 19.5212 },
    { lat: 48.275574, lng: 19.521125 },
    { lat: 48.275483, lng: 19.521021 },
    { lat: 48.275479, lng: 19.520814 },
    { lat: 48.275529, lng: 19.520474 },
    { lat: 48.275512, lng: 19.520351 },
    { lat: 48.275467, lng: 19.520239 },
    { lat: 48.2746958, lng: 19.5197534 },
    { lat: 48.2746668, lng: 19.5196624 },
    { lat: 48.2742821, lng: 19.5192924 },
    { lat: 48.2737337, lng: 19.5188882 },
    { lat: 48.2723561, lng: 19.5173948 },
    { lat: 48.2711648, lng: 19.5159349 },
    { lat: 48.2709368, lng: 19.5160502 },
    { lat: 48.270782, lng: 19.5160161 },
    { lat: 48.2698682, lng: 19.5150982 },
    { lat: 48.2699112, lng: 19.5142745 },
    { lat: 48.2697686, lng: 19.5137011 },
    { lat: 48.2695366, lng: 19.5133942 },
    { lat: 48.269184, lng: 19.5131742 },
    { lat: 48.2692376, lng: 19.5130692 },
    { lat: 48.2687076, lng: 19.512649 },
    { lat: 48.2687077, lng: 19.5123112 },
    { lat: 48.2684568, lng: 19.511348 },
    { lat: 48.2681716, lng: 19.5106243 },
    { lat: 48.2673002, lng: 19.5094754 },
    { lat: 48.2669797, lng: 19.509199 },
    { lat: 48.2660001, lng: 19.51026 },
    { lat: 48.2639457, lng: 19.5107211 },
    { lat: 48.2623622, lng: 19.5112529 },
    { lat: 48.2621196, lng: 19.5097415 },
    { lat: 48.262053, lng: 19.5096194 },
    { lat: 48.2619953, lng: 19.5096137 },
    { lat: 48.2617072, lng: 19.5083387 },
    { lat: 48.2612752, lng: 19.5071696 },
    { lat: 48.2612183, lng: 19.5063055 },
    { lat: 48.2607227, lng: 19.5031862 },
    { lat: 48.2607703, lng: 19.5025149 },
    { lat: 48.2606451, lng: 19.5022246 },
    { lat: 48.2606466, lng: 19.5019878 },
    { lat: 48.2604177, lng: 19.5015258 },
    { lat: 48.2577406, lng: 19.5051215 },
    { lat: 48.2566918, lng: 19.505953 },
    { lat: 48.2565773, lng: 19.5056478 },
    { lat: 48.2565566, lng: 19.5056902 },
    { lat: 48.2562256, lng: 19.5052099 },
    { lat: 48.2558468, lng: 19.5048452 },
    { lat: 48.2548694, lng: 19.5045006 },
    { lat: 48.254091, lng: 19.5059107 },
    { lat: 48.2536439, lng: 19.5065235 },
    { lat: 48.2524136, lng: 19.5077639 },
    { lat: 48.2514058, lng: 19.5100359 },
    { lat: 48.2506369, lng: 19.5103922 },
    { lat: 48.2499211, lng: 19.5105333 },
    { lat: 48.248812, lng: 19.511379 },
    { lat: 48.2483048, lng: 19.5132206 },
    { lat: 48.2479393, lng: 19.5157369 },
    { lat: 48.2470479, lng: 19.5187703 },
    { lat: 48.2463989, lng: 19.5194499 },
    { lat: 48.2456472, lng: 19.520692 },
    { lat: 48.2445737, lng: 19.5214298 },
    { lat: 48.2440078, lng: 19.5219129 },
    { lat: 48.2429706, lng: 19.5245489 },
    { lat: 48.2415081, lng: 19.5280322 },
    { lat: 48.2395662, lng: 19.5308251 },
    { lat: 48.239391, lng: 19.5315182 },
    { lat: 48.239046, lng: 19.53368 },
    { lat: 48.2390389, lng: 19.5337263 },
  ],
  Vinica: [
    { lat: 48.1399603, lng: 19.0813406 },
    { lat: 48.1396387, lng: 19.0795903 },
    { lat: 48.1395985, lng: 19.0789479 },
    { lat: 48.1395253, lng: 19.0787701 },
    { lat: 48.1394016, lng: 19.0779715 },
    { lat: 48.1392625, lng: 19.0775643 },
    { lat: 48.1390279, lng: 19.0765522 },
    { lat: 48.1387477, lng: 19.0761867 },
    { lat: 48.138611, lng: 19.0761325 },
    { lat: 48.1382906, lng: 19.0762625 },
    { lat: 48.1381727, lng: 19.0761829 },
    { lat: 48.1375204, lng: 19.0761921 },
    { lat: 48.1371759, lng: 19.0762711 },
    { lat: 48.1371317, lng: 19.0763774 },
    { lat: 48.1369666, lng: 19.0764355 },
    { lat: 48.1368372, lng: 19.0763523 },
    { lat: 48.1366215, lng: 19.0764115 },
    { lat: 48.1362651, lng: 19.0767654 },
    { lat: 48.1361284, lng: 19.0768277 },
    { lat: 48.1358973, lng: 19.0768283 },
    { lat: 48.1357625, lng: 19.0767489 },
    { lat: 48.1357553, lng: 19.0765845 },
    { lat: 48.1356663, lng: 19.0764484 },
    { lat: 48.1355339, lng: 19.0764379 },
    { lat: 48.1353939, lng: 19.0765468 },
    { lat: 48.1352901, lng: 19.0765447 },
    { lat: 48.1350832, lng: 19.0762659 },
    { lat: 48.1350007, lng: 19.0763306 },
    { lat: 48.1350142, lng: 19.0765747 },
    { lat: 48.1348996, lng: 19.0766063 },
    { lat: 48.1346977, lng: 19.0764448 },
    { lat: 48.1344615, lng: 19.0763655 },
    { lat: 48.134185, lng: 19.0767439 },
    { lat: 48.1341256, lng: 19.0766617 },
    { lat: 48.1341477, lng: 19.0765851 },
    { lat: 48.1340735, lng: 19.0764777 },
    { lat: 48.1339619, lng: 19.0764574 },
    { lat: 48.1338703, lng: 19.0762468 },
    { lat: 48.1337186, lng: 19.0761488 },
    { lat: 48.1335146, lng: 19.0761926 },
    { lat: 48.1334293, lng: 19.0759766 },
    { lat: 48.1333188, lng: 19.0758843 },
    { lat: 48.1332896, lng: 19.0756204 },
    { lat: 48.1331196, lng: 19.0752654 },
    { lat: 48.1327609, lng: 19.0750393 },
    { lat: 48.1325273, lng: 19.0748104 },
    { lat: 48.1322959, lng: 19.0748732 },
    { lat: 48.1321941, lng: 19.074758 },
    { lat: 48.1320289, lng: 19.0747003 },
    { lat: 48.1316894, lng: 19.0749206 },
    { lat: 48.1315099, lng: 19.0749355 },
    { lat: 48.1314499, lng: 19.0752021 },
    { lat: 48.1313739, lng: 19.0752607 },
    { lat: 48.1310059, lng: 19.0754497 },
    { lat: 48.1308114, lng: 19.0753964 },
    { lat: 48.1306847, lng: 19.0755627 },
    { lat: 48.1305401, lng: 19.0754922 },
    { lat: 48.1304069, lng: 19.0756438 },
    { lat: 48.1300893, lng: 19.0754491 },
    { lat: 48.1297837, lng: 19.0755364 },
    { lat: 48.1296894, lng: 19.075704 },
    { lat: 48.1294541, lng: 19.075775 },
    { lat: 48.1289584, lng: 19.0756042 },
    { lat: 48.128554, lng: 19.0758368 },
    { lat: 48.1282515, lng: 19.0762329 },
    { lat: 48.128207, lng: 19.076445 },
    { lat: 48.1280574, lng: 19.0762491 },
    { lat: 48.1279759, lng: 19.0762434 },
    { lat: 48.1278388, lng: 19.0766121 },
    { lat: 48.1277858, lng: 19.0766346 },
    { lat: 48.127739, lng: 19.0766187 },
    { lat: 48.1276382, lng: 19.0763636 },
    { lat: 48.1275788, lng: 19.0763513 },
    { lat: 48.1271356, lng: 19.0769287 },
    { lat: 48.1270364, lng: 19.0769522 },
    { lat: 48.1269102, lng: 19.0768397 },
    { lat: 48.1267759, lng: 19.0768598 },
    { lat: 48.1266001, lng: 19.0771738 },
    { lat: 48.1261993, lng: 19.0773442 },
    { lat: 48.125695, lng: 19.0776938 },
    { lat: 48.1254808, lng: 19.0780517 },
    { lat: 48.1249739, lng: 19.0778131 },
    { lat: 48.1247809, lng: 19.0779212 },
    { lat: 48.1244352, lng: 19.0778881 },
    { lat: 48.1238837, lng: 19.0781474 },
    { lat: 48.1233748, lng: 19.0785646 },
    { lat: 48.1230743, lng: 19.0787349 },
    { lat: 48.1227459, lng: 19.0793092 },
    { lat: 48.1225642, lng: 19.0793286 },
    { lat: 48.1225232, lng: 19.0794528 },
    { lat: 48.1225161, lng: 19.0798506 },
    { lat: 48.1222845, lng: 19.080131 },
    { lat: 48.1222627, lng: 19.0803921 },
    { lat: 48.1221107, lng: 19.080531 },
    { lat: 48.1219124, lng: 19.0805095 },
    { lat: 48.1217887, lng: 19.0805823 },
    { lat: 48.1218694, lng: 19.0811099 },
    { lat: 48.1218028, lng: 19.0811767 },
    { lat: 48.1217026, lng: 19.0811322 },
    { lat: 48.1211372, lng: 19.0821312 },
    { lat: 48.1210489, lng: 19.0821884 },
    { lat: 48.1209156, lng: 19.0824309 },
    { lat: 48.1209394, lng: 19.0825239 },
    { lat: 48.1208274, lng: 19.083053 },
    { lat: 48.1206041, lng: 19.0831832 },
    { lat: 48.1207047, lng: 19.0834129 },
    { lat: 48.1206852, lng: 19.0836067 },
    { lat: 48.1204817, lng: 19.0836193 },
    { lat: 48.1203933, lng: 19.0834772 },
    { lat: 48.1203385, lng: 19.0835292 },
    { lat: 48.1203471, lng: 19.0836571 },
    { lat: 48.120305, lng: 19.0837369 },
    { lat: 48.1201866, lng: 19.0837587 },
    { lat: 48.1193735, lng: 19.0850744 },
    { lat: 48.1167677, lng: 19.0865698 },
    { lat: 48.1165836, lng: 19.0868985 },
    { lat: 48.1163422, lng: 19.086782 },
    { lat: 48.1163483, lng: 19.0870625 },
    { lat: 48.1162915, lng: 19.0871454 },
    { lat: 48.1161203, lng: 19.087189 },
    { lat: 48.11581, lng: 19.0868481 },
    { lat: 48.1155605, lng: 19.0868193 },
    { lat: 48.1150709, lng: 19.0872648 },
    { lat: 48.114796, lng: 19.0872654 },
    { lat: 48.1147322, lng: 19.0874306 },
    { lat: 48.114567, lng: 19.0874851 },
    { lat: 48.1144282, lng: 19.087416 },
    { lat: 48.1141673, lng: 19.0876771 },
    { lat: 48.1138145, lng: 19.0875213 },
    { lat: 48.1135726, lng: 19.0872404 },
    { lat: 48.1135365, lng: 19.0870632 },
    { lat: 48.1134517, lng: 19.0869464 },
    { lat: 48.1131794, lng: 19.0869523 },
    { lat: 48.1130935, lng: 19.0866359 },
    { lat: 48.1128464, lng: 19.0870341 },
    { lat: 48.1128081, lng: 19.0875499 },
    { lat: 48.1126297, lng: 19.0876571 },
    { lat: 48.1124415, lng: 19.087481 },
    { lat: 48.1118827, lng: 19.087602 },
    { lat: 48.1118082, lng: 19.0874403 },
    { lat: 48.1118108, lng: 19.087247 },
    { lat: 48.1110517, lng: 19.0877545 },
    { lat: 48.1109871, lng: 19.0875917 },
    { lat: 48.110846, lng: 19.0876753 },
    { lat: 48.1103682, lng: 19.087772 },
    { lat: 48.1102074, lng: 19.0878916 },
    { lat: 48.1097252, lng: 19.0884343 },
    { lat: 48.1098502, lng: 19.0886792 },
    { lat: 48.1097622, lng: 19.089038 },
    { lat: 48.1097593, lng: 19.0893847 },
    { lat: 48.1098535, lng: 19.0897075 },
    { lat: 48.1098277, lng: 19.090049 },
    { lat: 48.1096908, lng: 19.0904179 },
    { lat: 48.1095752, lng: 19.0905857 },
    { lat: 48.1094901, lng: 19.0905501 },
    { lat: 48.1092063, lng: 19.0907142 },
    { lat: 48.1090489, lng: 19.0909234 },
    { lat: 48.1086837, lng: 19.0912156 },
    { lat: 48.1087371, lng: 19.0917182 },
    { lat: 48.1086699, lng: 19.0920637 },
    { lat: 48.1087271, lng: 19.0923744 },
    { lat: 48.1086119, lng: 19.092527 },
    { lat: 48.1081618, lng: 19.0926756 },
    { lat: 48.1081084, lng: 19.0925571 },
    { lat: 48.1075124, lng: 19.0931703 },
    { lat: 48.1072264, lng: 19.0936454 },
    { lat: 48.1070094, lng: 19.093389 },
    { lat: 48.1067656, lng: 19.0937193 },
    { lat: 48.1067717, lng: 19.0938625 },
    { lat: 48.1066706, lng: 19.0941162 },
    { lat: 48.1064215, lng: 19.0944108 },
    { lat: 48.1062239, lng: 19.0942689 },
    { lat: 48.1060969, lng: 19.0943872 },
    { lat: 48.1060515, lng: 19.0946075 },
    { lat: 48.1060503, lng: 19.0950771 },
    { lat: 48.1058783, lng: 19.0952653 },
    { lat: 48.1058759, lng: 19.0957573 },
    { lat: 48.1056073, lng: 19.0960629 },
    { lat: 48.1056547, lng: 19.0961789 },
    { lat: 48.1052673, lng: 19.0968146 },
    { lat: 48.1051486, lng: 19.0968974 },
    { lat: 48.1051251, lng: 19.0973737 },
    { lat: 48.1049032, lng: 19.0973996 },
    { lat: 48.1047356, lng: 19.0976107 },
    { lat: 48.1044868, lng: 19.0970304 },
    { lat: 48.104122, lng: 19.0973122 },
    { lat: 48.1038764, lng: 19.0969098 },
    { lat: 48.1035462, lng: 19.0974942 },
    { lat: 48.1033162, lng: 19.0984173 },
    { lat: 48.1032874, lng: 19.0984294 },
    { lat: 48.1030624, lng: 19.098524 },
    { lat: 48.1029287, lng: 19.0983789 },
    { lat: 48.1024791, lng: 19.099031 },
    { lat: 48.1022024, lng: 19.0986637 },
    { lat: 48.1018443, lng: 19.0986277 },
    { lat: 48.1016403, lng: 19.0988734 },
    { lat: 48.1015874, lng: 19.0991654 },
    { lat: 48.1014506, lng: 19.0993155 },
    { lat: 48.1013826, lng: 19.0996451 },
    { lat: 48.1012951, lng: 19.0997233 },
    { lat: 48.1011422, lng: 19.0996434 },
    { lat: 48.1009928, lng: 19.0997402 },
    { lat: 48.1004121, lng: 19.0998053 },
    { lat: 48.1003366, lng: 19.0998726 },
    { lat: 48.1002797, lng: 19.1005145 },
    { lat: 48.100097, lng: 19.1006955 },
    { lat: 48.1000057, lng: 19.1005607 },
    { lat: 48.0998795, lng: 19.1006271 },
    { lat: 48.0998938, lng: 19.100756 },
    { lat: 48.0998485, lng: 19.1008884 },
    { lat: 48.0999325, lng: 19.101099 },
    { lat: 48.0998446, lng: 19.1011685 },
    { lat: 48.0997797, lng: 19.1013817 },
    { lat: 48.0997082, lng: 19.1014585 },
    { lat: 48.0996318, lng: 19.1014413 },
    { lat: 48.0996382, lng: 19.101546 },
    { lat: 48.0994724, lng: 19.1017254 },
    { lat: 48.0991153, lng: 19.101977 },
    { lat: 48.0988937, lng: 19.1027714 },
    { lat: 48.0985995, lng: 19.1030424 },
    { lat: 48.0985543, lng: 19.1029282 },
    { lat: 48.0985116, lng: 19.1029774 },
    { lat: 48.0984668, lng: 19.1028474 },
    { lat: 48.0984262, lng: 19.1028946 },
    { lat: 48.098387, lng: 19.1029409 },
    { lat: 48.0984128, lng: 19.1030079 },
    { lat: 48.097384, lng: 19.1041193 },
    { lat: 48.0973614, lng: 19.104277 },
    { lat: 48.0971699, lng: 19.1043922 },
    { lat: 48.0972178, lng: 19.1046486 },
    { lat: 48.0971644, lng: 19.1047057 },
    { lat: 48.0971348, lng: 19.1049728 },
    { lat: 48.096932, lng: 19.105243 },
    { lat: 48.0969168, lng: 19.1053872 },
    { lat: 48.09676, lng: 19.1056336 },
    { lat: 48.0965645, lng: 19.1056262 },
    { lat: 48.0965432, lng: 19.1057383 },
    { lat: 48.0964802, lng: 19.1057626 },
    { lat: 48.0964264, lng: 19.1058826 },
    { lat: 48.0965043, lng: 19.1061778 },
    { lat: 48.0962777, lng: 19.1064451 },
    { lat: 48.0961687, lng: 19.1063395 },
    { lat: 48.0961207, lng: 19.1064415 },
    { lat: 48.0960838, lng: 19.106422 },
    { lat: 48.0959702, lng: 19.1067243 },
    { lat: 48.0960556, lng: 19.1068491 },
    { lat: 48.0960558, lng: 19.1069471 },
    { lat: 48.0957918, lng: 19.1067837 },
    { lat: 48.095673, lng: 19.1063237 },
    { lat: 48.095578, lng: 19.1061862 },
    { lat: 48.0953941, lng: 19.1063641 },
    { lat: 48.095056, lng: 19.1063211 },
    { lat: 48.0950324, lng: 19.1062748 },
    { lat: 48.0950808, lng: 19.1061424 },
    { lat: 48.0950473, lng: 19.106101 },
    { lat: 48.0947877, lng: 19.1061389 },
    { lat: 48.0947358, lng: 19.1061831 },
    { lat: 48.094733, lng: 19.1063767 },
    { lat: 48.0945489, lng: 19.1064364 },
    { lat: 48.0945425, lng: 19.1063074 },
    { lat: 48.0943736, lng: 19.106201 },
    { lat: 48.094117, lng: 19.1064357 },
    { lat: 48.0940578, lng: 19.1065583 },
    { lat: 48.0938221, lng: 19.1064669 },
    { lat: 48.0936879, lng: 19.1065556 },
    { lat: 48.0937305, lng: 19.1067081 },
    { lat: 48.0935638, lng: 19.1068703 },
    { lat: 48.0936333, lng: 19.1069267 },
    { lat: 48.0936518, lng: 19.1070266 },
    { lat: 48.0935448, lng: 19.1071168 },
    { lat: 48.0935369, lng: 19.1073402 },
    { lat: 48.0934839, lng: 19.1074339 },
    { lat: 48.0934036, lng: 19.1073156 },
    { lat: 48.0933084, lng: 19.1073957 },
    { lat: 48.0932518, lng: 19.1078637 },
    { lat: 48.0930071, lng: 19.1078367 },
    { lat: 48.0929309, lng: 19.1076887 },
    { lat: 48.092855, lng: 19.1077476 },
    { lat: 48.0927686, lng: 19.1079614 },
    { lat: 48.0928551, lng: 19.1080657 },
    { lat: 48.0928397, lng: 19.108121 },
    { lat: 48.0927325, lng: 19.108164 },
    { lat: 48.0928094, lng: 19.1084583 },
    { lat: 48.0926865, lng: 19.1085052 },
    { lat: 48.0927564, lng: 19.1086589 },
    { lat: 48.0927446, lng: 19.1087625 },
    { lat: 48.0926806, lng: 19.1087929 },
    { lat: 48.0926102, lng: 19.1087116 },
    { lat: 48.0924543, lng: 19.1089103 },
    { lat: 48.0925103, lng: 19.1091036 },
    { lat: 48.0925118, lng: 19.1094903 },
    { lat: 48.0921278, lng: 19.1097709 },
    { lat: 48.0921485, lng: 19.1098031 },
    { lat: 48.0926646, lng: 19.1107437 },
    { lat: 48.0927549, lng: 19.1110358 },
    { lat: 48.0929078, lng: 19.1111824 },
    { lat: 48.0932809, lng: 19.1117909 },
    { lat: 48.0938912, lng: 19.1122686 },
    { lat: 48.0942375, lng: 19.1124532 },
    { lat: 48.0950268, lng: 19.1126365 },
    { lat: 48.0953966, lng: 19.1125677 },
    { lat: 48.0956151, lng: 19.1126335 },
    { lat: 48.095951, lng: 19.1125858 },
    { lat: 48.0960988, lng: 19.1124748 },
    { lat: 48.0964021, lng: 19.1126772 },
    { lat: 48.0968382, lng: 19.1128559 },
    { lat: 48.097115, lng: 19.1131138 },
    { lat: 48.0971805, lng: 19.1129831 },
    { lat: 48.0974474, lng: 19.1131789 },
    { lat: 48.0977183, lng: 19.1132541 },
    { lat: 48.096911, lng: 19.1164664 },
    { lat: 48.0967291, lng: 19.1179891 },
    { lat: 48.096502, lng: 19.1192847 },
    { lat: 48.0960551, lng: 19.1209853 },
    { lat: 48.0958949, lng: 19.1217724 },
    { lat: 48.0966622, lng: 19.1220789 },
    { lat: 48.0965842, lng: 19.1226169 },
    { lat: 48.0966411, lng: 19.1227376 },
    { lat: 48.0965047, lng: 19.1236143 },
    { lat: 48.0959515, lng: 19.1266196 },
    { lat: 48.0962794, lng: 19.1267985 },
    { lat: 48.0958145, lng: 19.1286354 },
    { lat: 48.0951973, lng: 19.1307026 },
    { lat: 48.0939983, lng: 19.1362844 },
    { lat: 48.0936172, lng: 19.1361466 },
    { lat: 48.0932378, lng: 19.1375559 },
    { lat: 48.0924343, lng: 19.1373139 },
    { lat: 48.0916185, lng: 19.1401896 },
    { lat: 48.0914211, lng: 19.1411153 },
    { lat: 48.0908697, lng: 19.1408634 },
    { lat: 48.0901435, lng: 19.1426243 },
    { lat: 48.0936397, lng: 19.147423 },
    { lat: 48.0936451, lng: 19.1476435 },
    { lat: 48.0970384, lng: 19.1524196 },
    { lat: 48.0971071, lng: 19.1525104 },
    { lat: 48.0973831, lng: 19.1524114 },
    { lat: 48.0979504, lng: 19.1535711 },
    { lat: 48.0982464, lng: 19.1539032 },
    { lat: 48.0986643, lng: 19.1546677 },
    { lat: 48.098913, lng: 19.1550185 },
    { lat: 48.0992193, lng: 19.1552874 },
    { lat: 48.0993205, lng: 19.1551898 },
    { lat: 48.1005282, lng: 19.1557178 },
    { lat: 48.1009422, lng: 19.156062 },
    { lat: 48.1011648, lng: 19.155868 },
    { lat: 48.1012661, lng: 19.1559347 },
    { lat: 48.1013704, lng: 19.1557662 },
    { lat: 48.101441, lng: 19.1559122 },
    { lat: 48.1014874, lng: 19.1558488 },
    { lat: 48.1015445, lng: 19.15598 },
    { lat: 48.101641, lng: 19.1560392 },
    { lat: 48.1016966, lng: 19.1559741 },
    { lat: 48.101697, lng: 19.1561444 },
    { lat: 48.1019336, lng: 19.1560699 },
    { lat: 48.1037207, lng: 19.1563513 },
    { lat: 48.1060902, lng: 19.1566194 },
    { lat: 48.1070507, lng: 19.1568459 },
    { lat: 48.1082093, lng: 19.1574757 },
    { lat: 48.1086118, lng: 19.1575419 },
    { lat: 48.1089331, lng: 19.1577034 },
    { lat: 48.1106878, lng: 19.1592091 },
    { lat: 48.1132432, lng: 19.1607027 },
    { lat: 48.1160332, lng: 19.1618085 },
    { lat: 48.1164556, lng: 19.1620509 },
    { lat: 48.1168755, lng: 19.1623819 },
    { lat: 48.1172865, lng: 19.1625988 },
    { lat: 48.1186568, lng: 19.1635403 },
    { lat: 48.1206088, lng: 19.1650056 },
    { lat: 48.122238, lng: 19.1662117 },
    { lat: 48.1231541, lng: 19.1651379 },
    { lat: 48.124531, lng: 19.1633986 },
    { lat: 48.1247552, lng: 19.1629666 },
    { lat: 48.1253573, lng: 19.1630139 },
    { lat: 48.1256867, lng: 19.1627015 },
    { lat: 48.1262156, lng: 19.1628401 },
    { lat: 48.1272675, lng: 19.1625669 },
    { lat: 48.1275233, lng: 19.1621814 },
    { lat: 48.1278931, lng: 19.1610956 },
    { lat: 48.1280509, lng: 19.1607521 },
    { lat: 48.1281112, lng: 19.1600354 },
    { lat: 48.1287934, lng: 19.1591799 },
    { lat: 48.1289047, lng: 19.1592188 },
    { lat: 48.1292859, lng: 19.1590826 },
    { lat: 48.1304947, lng: 19.1581864 },
    { lat: 48.1305152, lng: 19.1581647 },
    { lat: 48.1307531, lng: 19.1581658 },
    { lat: 48.1308838, lng: 19.1580949 },
    { lat: 48.1317342, lng: 19.158105 },
    { lat: 48.1324173, lng: 19.1575606 },
    { lat: 48.1344802, lng: 19.1558816 },
    { lat: 48.1353457, lng: 19.1550769 },
    { lat: 48.135555, lng: 19.1549595 },
    { lat: 48.135687, lng: 19.1547768 },
    { lat: 48.1360471, lng: 19.1544704 },
    { lat: 48.1369975, lng: 19.1533654 },
    { lat: 48.1371873, lng: 19.1530112 },
    { lat: 48.1375653, lng: 19.1527875 },
    { lat: 48.1380594, lng: 19.1523874 },
    { lat: 48.1390253, lng: 19.1512708 },
    { lat: 48.1392136, lng: 19.1511606 },
    { lat: 48.1399541, lng: 19.1504755 },
    { lat: 48.1401808, lng: 19.1505025 },
    { lat: 48.1408488, lng: 19.1498805 },
    { lat: 48.1415223, lng: 19.1493673 },
    { lat: 48.1417496, lng: 19.1489069 },
    { lat: 48.1421567, lng: 19.1483209 },
    { lat: 48.1424456, lng: 19.1480124 },
    { lat: 48.1427939, lng: 19.1460401 },
    { lat: 48.1430649, lng: 19.1436275 },
    { lat: 48.1437114, lng: 19.1418704 },
    { lat: 48.1440161, lng: 19.1416992 },
    { lat: 48.1443666, lng: 19.1413987 },
    { lat: 48.1444649, lng: 19.1408844 },
    { lat: 48.144621, lng: 19.140556 },
    { lat: 48.1448598, lng: 19.1402372 },
    { lat: 48.1451553, lng: 19.140128 },
    { lat: 48.1454332, lng: 19.1401158 },
    { lat: 48.1457274, lng: 19.1399549 },
    { lat: 48.146074, lng: 19.1394924 },
    { lat: 48.146361, lng: 19.139345 },
    { lat: 48.1473438, lng: 19.1397493 },
    { lat: 48.1480773, lng: 19.1403976 },
    { lat: 48.1486653, lng: 19.140724 },
    { lat: 48.1488762, lng: 19.1409318 },
    { lat: 48.1491123, lng: 19.1415505 },
    { lat: 48.1503924, lng: 19.142986 },
    { lat: 48.1506355, lng: 19.143114 },
    { lat: 48.1514888, lng: 19.1433191 },
    { lat: 48.1522273, lng: 19.1431158 },
    { lat: 48.1534301, lng: 19.1435448 },
    { lat: 48.1542702, lng: 19.144 },
    { lat: 48.1550987, lng: 19.1442912 },
    { lat: 48.1554744, lng: 19.1427839 },
    { lat: 48.1560622, lng: 19.1393951 },
    { lat: 48.1553369, lng: 19.1347763 },
    { lat: 48.1549378, lng: 19.1344384 },
    { lat: 48.1551781, lng: 19.1329517 },
    { lat: 48.1545997, lng: 19.1326988 },
    { lat: 48.1541365, lng: 19.1315087 },
    { lat: 48.1540987, lng: 19.1311761 },
    { lat: 48.1541455, lng: 19.130785 },
    { lat: 48.1540184, lng: 19.1303388 },
    { lat: 48.1539956, lng: 19.1299247 },
    { lat: 48.1535673, lng: 19.128889 },
    { lat: 48.1533366, lng: 19.1280872 },
    { lat: 48.1531795, lng: 19.1279398 },
    { lat: 48.1531818, lng: 19.1277842 },
    { lat: 48.1530031, lng: 19.127347 },
    { lat: 48.1530473, lng: 19.1269632 },
    { lat: 48.1529947, lng: 19.1260724 },
    { lat: 48.1530372, lng: 19.1256132 },
    { lat: 48.1528025, lng: 19.124074 },
    { lat: 48.1527449, lng: 19.1239371 },
    { lat: 48.1527347, lng: 19.1237995 },
    { lat: 48.152812, lng: 19.1235653 },
    { lat: 48.1526729, lng: 19.12331 },
    { lat: 48.1526917, lng: 19.1231007 },
    { lat: 48.1525962, lng: 19.1229002 },
    { lat: 48.1526099, lng: 19.1226131 },
    { lat: 48.1525061, lng: 19.1218699 },
    { lat: 48.1525916, lng: 19.1208241 },
    { lat: 48.1526545, lng: 19.1207797 },
    { lat: 48.1519954, lng: 19.11957 },
    { lat: 48.1517221, lng: 19.1195564 },
    { lat: 48.1516082, lng: 19.119829 },
    { lat: 48.1515467, lng: 19.1198376 },
    { lat: 48.1508902, lng: 19.1197193 },
    { lat: 48.1504841, lng: 19.1194642 },
    { lat: 48.149712, lng: 19.1186125 },
    { lat: 48.1494067, lng: 19.1177615 },
    { lat: 48.1489669, lng: 19.1169472 },
    { lat: 48.1491199, lng: 19.116671 },
    { lat: 48.1489979, lng: 19.1161922 },
    { lat: 48.1484515, lng: 19.1148479 },
    { lat: 48.1480942, lng: 19.1135022 },
    { lat: 48.1480587, lng: 19.1127927 },
    { lat: 48.1474595, lng: 19.1114043 },
    { lat: 48.1469565, lng: 19.1106416 },
    { lat: 48.1467561, lng: 19.1097667 },
    { lat: 48.1466995, lng: 19.1089724 },
    { lat: 48.1461077, lng: 19.1074691 },
    { lat: 48.1457964, lng: 19.1072625 },
    { lat: 48.1454339, lng: 19.1066917 },
    { lat: 48.1452227, lng: 19.1062568 },
    { lat: 48.1443022, lng: 19.1055025 },
    { lat: 48.1432643, lng: 19.1042293 },
    { lat: 48.1427954, lng: 19.1038717 },
    { lat: 48.1422372, lng: 19.1035766 },
    { lat: 48.1416586, lng: 19.1031 },
    { lat: 48.141084, lng: 19.102883 },
    { lat: 48.1408682, lng: 19.1026255 },
    { lat: 48.140709, lng: 19.1023296 },
    { lat: 48.140231, lng: 19.1018163 },
    { lat: 48.1399592, lng: 19.1017468 },
    { lat: 48.1389519, lng: 19.1007034 },
    { lat: 48.1376362, lng: 19.0998693 },
    { lat: 48.1379331, lng: 19.0987998 },
    { lat: 48.1381648, lng: 19.098159 },
    { lat: 48.1382099, lng: 19.0975101 },
    { lat: 48.1384611, lng: 19.0969419 },
    { lat: 48.1385445, lng: 19.0966315 },
    { lat: 48.1384798, lng: 19.0960599 },
    { lat: 48.1387363, lng: 19.0948364 },
    { lat: 48.1388082, lng: 19.0941831 },
    { lat: 48.1386806, lng: 19.0940901 },
    { lat: 48.1389043, lng: 19.0934025 },
    { lat: 48.1393869, lng: 19.0926643 },
    { lat: 48.1396888, lng: 19.0923438 },
    { lat: 48.1398944, lng: 19.0915237 },
    { lat: 48.140214, lng: 19.091245 },
    { lat: 48.1403147, lng: 19.0905793 },
    { lat: 48.1408279, lng: 19.0901341 },
    { lat: 48.1411421, lng: 19.0894991 },
    { lat: 48.1423783, lng: 19.0861263 },
    { lat: 48.1427852, lng: 19.0854522 },
    { lat: 48.1427619, lng: 19.0848583 },
    { lat: 48.1421281, lng: 19.0840665 },
    { lat: 48.141689, lng: 19.0831395 },
    { lat: 48.1411486, lng: 19.0824152 },
    { lat: 48.1404562, lng: 19.0818111 },
    { lat: 48.1399603, lng: 19.0813406 },
  ],
  Opava: [
    { lat: 48.1831287, lng: 19.1769042 },
    { lat: 48.1827785, lng: 19.1796898 },
    { lat: 48.1830801, lng: 19.1823438 },
    { lat: 48.1832542, lng: 19.1838167 },
    { lat: 48.1841168, lng: 19.1853915 },
    { lat: 48.1842533, lng: 19.1853478 },
    { lat: 48.1847136, lng: 19.1856092 },
    { lat: 48.1848536, lng: 19.185587 },
    { lat: 48.1852794, lng: 19.1857201 },
    { lat: 48.1853704, lng: 19.1856673 },
    { lat: 48.1855556, lng: 19.1857214 },
    { lat: 48.1857683, lng: 19.1855735 },
    { lat: 48.1860501, lng: 19.1854834 },
    { lat: 48.1861223, lng: 19.1856008 },
    { lat: 48.1860394, lng: 19.1856545 },
    { lat: 48.186117, lng: 19.1860868 },
    { lat: 48.1861793, lng: 19.1862057 },
    { lat: 48.1863989, lng: 19.1863174 },
    { lat: 48.1864273, lng: 19.1865238 },
    { lat: 48.1867258, lng: 19.1865897 },
    { lat: 48.1867526, lng: 19.1867637 },
    { lat: 48.1870046, lng: 19.1870075 },
    { lat: 48.1870362, lng: 19.1872821 },
    { lat: 48.1871565, lng: 19.187432 },
    { lat: 48.1871319, lng: 19.1876226 },
    { lat: 48.187177, lng: 19.1877367 },
    { lat: 48.1872532, lng: 19.1877502 },
    { lat: 48.1875749, lng: 19.1881898 },
    { lat: 48.187731, lng: 19.1883174 },
    { lat: 48.1881307, lng: 19.1883276 },
    { lat: 48.1883807, lng: 19.1885533 },
    { lat: 48.1884852, lng: 19.1888431 },
    { lat: 48.1886971, lng: 19.1888187 },
    { lat: 48.1887057, lng: 19.1889248 },
    { lat: 48.1888564, lng: 19.1890314 },
    { lat: 48.1889742, lng: 19.1888806 },
    { lat: 48.1891038, lng: 19.1891097 },
    { lat: 48.1893077, lng: 19.1890611 },
    { lat: 48.18941, lng: 19.1892822 },
    { lat: 48.1895558, lng: 19.1894081 },
    { lat: 48.189492, lng: 19.1895325 },
    { lat: 48.1897443, lng: 19.1897989 },
    { lat: 48.1900416, lng: 19.190343 },
    { lat: 48.1901901, lng: 19.1902888 },
    { lat: 48.190868, lng: 19.1897713 },
    { lat: 48.1922342, lng: 19.1915964 },
    { lat: 48.1927193, lng: 19.1906235 },
    { lat: 48.193268, lng: 19.1898843 },
    { lat: 48.1935477, lng: 19.1900473 },
    { lat: 48.1938732, lng: 19.1900418 },
    { lat: 48.194191, lng: 19.1902696 },
    { lat: 48.1945856, lng: 19.1904062 },
    { lat: 48.1957185, lng: 19.1904517 },
    { lat: 48.1959978, lng: 19.1907883 },
    { lat: 48.1963822, lng: 19.1908579 },
    { lat: 48.1979549, lng: 19.1923022 },
    { lat: 48.198176, lng: 19.1924173 },
    { lat: 48.198399, lng: 19.1924789 },
    { lat: 48.1989405, lng: 19.192422 },
    { lat: 48.1990033, lng: 19.1925044 },
    { lat: 48.2001134, lng: 19.1925918 },
    { lat: 48.2006153, lng: 19.1929396 },
    { lat: 48.2011802, lng: 19.1931709 },
    { lat: 48.2019318, lng: 19.1929363 },
    { lat: 48.20217, lng: 19.1930086 },
    { lat: 48.20276, lng: 19.1926774 },
    { lat: 48.2029996, lng: 19.1928273 },
    { lat: 48.2031541, lng: 19.1927834 },
    { lat: 48.2031593, lng: 19.1928655 },
    { lat: 48.2035364, lng: 19.1926221 },
    { lat: 48.2036726, lng: 19.1924812 },
    { lat: 48.2036838, lng: 19.1923443 },
    { lat: 48.2040538, lng: 19.1922119 },
    { lat: 48.2047075, lng: 19.191821 },
    { lat: 48.2050834, lng: 19.191765 },
    { lat: 48.2053945, lng: 19.1915108 },
    { lat: 48.2054381, lng: 19.1913463 },
    { lat: 48.2058073, lng: 19.1909687 },
    { lat: 48.2066642, lng: 19.1905158 },
    { lat: 48.2069422, lng: 19.1902564 },
    { lat: 48.2084061, lng: 19.1899272 },
    { lat: 48.2087793, lng: 19.1897199 },
    { lat: 48.2089723, lng: 19.1895197 },
    { lat: 48.2091956, lng: 19.1894293 },
    { lat: 48.2100808, lng: 19.189345 },
    { lat: 48.2117292, lng: 19.1890529 },
    { lat: 48.2134206, lng: 19.189222 },
    { lat: 48.213763, lng: 19.1895584 },
    { lat: 48.2142874, lng: 19.1906361 },
    { lat: 48.2146455, lng: 19.1904912 },
    { lat: 48.2149793, lng: 19.1905162 },
    { lat: 48.2157264, lng: 19.1903998 },
    { lat: 48.2169327, lng: 19.1899194 },
    { lat: 48.2172876, lng: 19.1896183 },
    { lat: 48.2174117, lng: 19.1896215 },
    { lat: 48.2178851, lng: 19.1890698 },
    { lat: 48.2181718, lng: 19.1888967 },
    { lat: 48.2184167, lng: 19.1886512 },
    { lat: 48.2186827, lng: 19.1886034 },
    { lat: 48.2190918, lng: 19.1880994 },
    { lat: 48.219536, lng: 19.1883744 },
    { lat: 48.2198201, lng: 19.1881673 },
    { lat: 48.2199768, lng: 19.1881458 },
    { lat: 48.2201704, lng: 19.1883255 },
    { lat: 48.220414, lng: 19.1882357 },
    { lat: 48.220452, lng: 19.1881343 },
    { lat: 48.2205165, lng: 19.1881194 },
    { lat: 48.2206566, lng: 19.1883068 },
    { lat: 48.2209454, lng: 19.1880599 },
    { lat: 48.2209936, lng: 19.1880999 },
    { lat: 48.2211253, lng: 19.1880489 },
    { lat: 48.2213164, lng: 19.1877849 },
    { lat: 48.2214763, lng: 19.1878277 },
    { lat: 48.221537, lng: 19.1877563 },
    { lat: 48.2217542, lng: 19.1877485 },
    { lat: 48.2217501, lng: 19.1876659 },
    { lat: 48.2219617, lng: 19.1875697 },
    { lat: 48.2221239, lng: 19.1876812 },
    { lat: 48.2224562, lng: 19.1875607 },
    { lat: 48.2225301, lng: 19.1874599 },
    { lat: 48.2225919, lng: 19.1871133 },
    { lat: 48.2227862, lng: 19.1871675 },
    { lat: 48.2228142, lng: 19.1873245 },
    { lat: 48.2230929, lng: 19.1872852 },
    { lat: 48.2234003, lng: 19.1869564 },
    { lat: 48.2235539, lng: 19.1869377 },
    { lat: 48.2236107, lng: 19.1868596 },
    { lat: 48.2238317, lng: 19.1869236 },
    { lat: 48.2239002, lng: 19.1870327 },
    { lat: 48.2239851, lng: 19.1870208 },
    { lat: 48.2241678, lng: 19.1868168 },
    { lat: 48.2246006, lng: 19.1868608 },
    { lat: 48.2247247, lng: 19.1867237 },
    { lat: 48.224941, lng: 19.1867458 },
    { lat: 48.2250558, lng: 19.1866691 },
    { lat: 48.2250959, lng: 19.1865484 },
    { lat: 48.2252991, lng: 19.1864633 },
    { lat: 48.2253672, lng: 19.1865848 },
    { lat: 48.2256058, lng: 19.1863926 },
    { lat: 48.2257939, lng: 19.1863892 },
    { lat: 48.2258644, lng: 19.1862 },
    { lat: 48.2259792, lng: 19.1864256 },
    { lat: 48.2260198, lng: 19.1864243 },
    { lat: 48.2261577, lng: 19.1862266 },
    { lat: 48.2262623, lng: 19.1862886 },
    { lat: 48.2265056, lng: 19.1861026 },
    { lat: 48.2267683, lng: 19.1860361 },
    { lat: 48.2268548, lng: 19.185936 },
    { lat: 48.2272784, lng: 19.1859312 },
    { lat: 48.2273951, lng: 19.1858515 },
    { lat: 48.2275302, lng: 19.1858935 },
    { lat: 48.2277132, lng: 19.1857435 },
    { lat: 48.2278682, lng: 19.1858013 },
    { lat: 48.2282803, lng: 19.1855839 },
    { lat: 48.2284235, lng: 19.1857236 },
    { lat: 48.2287504, lng: 19.185493 },
    { lat: 48.2289073, lng: 19.1852939 },
    { lat: 48.2291834, lng: 19.1853589 },
    { lat: 48.2293131, lng: 19.185201 },
    { lat: 48.2296139, lng: 19.1851949 },
    { lat: 48.2298376, lng: 19.1850694 },
    { lat: 48.2299864, lng: 19.1850922 },
    { lat: 48.2301792, lng: 19.1852997 },
    { lat: 48.2305199, lng: 19.1855073 },
    { lat: 48.2306352, lng: 19.185741 },
    { lat: 48.2308041, lng: 19.1857849 },
    { lat: 48.2311646, lng: 19.1855743 },
    { lat: 48.231754, lng: 19.185663 },
    { lat: 48.231775, lng: 19.185591 },
    { lat: 48.231819, lng: 19.185446 },
    { lat: 48.23193, lng: 19.185073 },
    { lat: 48.232001, lng: 19.184775 },
    { lat: 48.232041, lng: 19.184371 },
    { lat: 48.232089, lng: 19.183885 },
    { lat: 48.23213, lng: 19.183641 },
    { lat: 48.232287, lng: 19.183426 },
    { lat: 48.232528, lng: 19.183253 },
    { lat: 48.232767, lng: 19.183023 },
    { lat: 48.233, lng: 19.183001 },
    { lat: 48.233181, lng: 19.182962 },
    { lat: 48.233389, lng: 19.183092 },
    { lat: 48.233564, lng: 19.183102 },
    { lat: 48.233902, lng: 19.183026 },
    { lat: 48.233994, lng: 19.182856 },
    { lat: 48.234156, lng: 19.182735 },
    { lat: 48.234378, lng: 19.182712 },
    { lat: 48.234546, lng: 19.182624 },
    { lat: 48.234762, lng: 19.182336 },
    { lat: 48.235, lng: 19.181917 },
    { lat: 48.235182, lng: 19.181961 },
    { lat: 48.235513, lng: 19.182011 },
    { lat: 48.2357, lng: 19.182098 },
    { lat: 48.236061, lng: 19.182071 },
    { lat: 48.236297, lng: 19.181934 },
    { lat: 48.236618, lng: 19.181804 },
    { lat: 48.236905, lng: 19.18164 },
    { lat: 48.237071, lng: 19.181587 },
    { lat: 48.2372217, lng: 19.1811877 },
    { lat: 48.237312, lng: 19.181104 },
    { lat: 48.237498, lng: 19.180999 },
    { lat: 48.237607, lng: 19.180836 },
    { lat: 48.237722, lng: 19.180769 },
    { lat: 48.238033, lng: 19.180694 },
    { lat: 48.238501, lng: 19.180548 },
    { lat: 48.238623, lng: 19.180493 },
    { lat: 48.238749, lng: 19.180315 },
    { lat: 48.238968, lng: 19.179767 },
    { lat: 48.239031, lng: 19.179299 },
    { lat: 48.239171, lng: 19.178684 },
    { lat: 48.239237, lng: 19.178396 },
    { lat: 48.239314, lng: 19.17825 },
    { lat: 48.239588, lng: 19.177968 },
    { lat: 48.239778, lng: 19.177676 },
    { lat: 48.239948, lng: 19.177537 },
    { lat: 48.240065, lng: 19.177274 },
    { lat: 48.240397, lng: 19.176848 },
    { lat: 48.24046, lng: 19.176714 },
    { lat: 48.240472, lng: 19.176547 },
    { lat: 48.240427, lng: 19.176399 },
    { lat: 48.240546, lng: 19.17614 },
    { lat: 48.240464, lng: 19.175875 },
    { lat: 48.240421, lng: 19.175528 },
    { lat: 48.240363, lng: 19.174716 },
    { lat: 48.240244, lng: 19.174547 },
    { lat: 48.240005, lng: 19.174399 },
    { lat: 48.239691, lng: 19.174236 },
    { lat: 48.239507, lng: 19.174079 },
    { lat: 48.239446, lng: 19.173733 },
    { lat: 48.239363, lng: 19.173391 },
    { lat: 48.239312, lng: 19.173183 },
    { lat: 48.23918, lng: 19.173073 },
    { lat: 48.239012, lng: 19.173029 },
    { lat: 48.238563, lng: 19.173009 },
    { lat: 48.238425, lng: 19.172864 },
    { lat: 48.238276, lng: 19.172261 },
    { lat: 48.238208, lng: 19.171853 },
    { lat: 48.238052, lng: 19.171309 },
    { lat: 48.237793, lng: 19.17109 },
    { lat: 48.237541, lng: 19.170853 },
    { lat: 48.237298, lng: 19.170693 },
    { lat: 48.236939, lng: 19.170616 },
    { lat: 48.236636, lng: 19.17044 },
    { lat: 48.236447, lng: 19.170253 },
    { lat: 48.236233, lng: 19.169919 },
    { lat: 48.23615, lng: 19.16959 },
    { lat: 48.236052, lng: 19.169395 },
    { lat: 48.235895, lng: 19.169237 },
    { lat: 48.235647, lng: 19.169285 },
    { lat: 48.235325, lng: 19.169856 },
    { lat: 48.235081, lng: 19.170197 },
    { lat: 48.234904, lng: 19.170403 },
    { lat: 48.234848, lng: 19.1704401 },
    { lat: 48.23472, lng: 19.170525 },
    { lat: 48.234571, lng: 19.170511 },
    { lat: 48.234289, lng: 19.170553 },
    { lat: 48.234049, lng: 19.170543 },
    { lat: 48.23383, lng: 19.170547 },
    { lat: 48.233525, lng: 19.170497 },
    { lat: 48.233183, lng: 19.170452 },
    { lat: 48.23287, lng: 19.170392 },
    { lat: 48.232735, lng: 19.170269 },
    { lat: 48.232599, lng: 19.169965 },
    { lat: 48.232289, lng: 19.169795 },
    { lat: 48.232156, lng: 19.169483 },
    { lat: 48.232109, lng: 19.16929 },
    { lat: 48.232039, lng: 19.169009 },
    { lat: 48.231784, lng: 19.168706 },
    { lat: 48.231386, lng: 19.168282 },
    { lat: 48.230863, lng: 19.167922 },
    { lat: 48.230718, lng: 19.167827 },
    { lat: 48.230264, lng: 19.167719 },
    { lat: 48.230008, lng: 19.167668 },
    { lat: 48.229728, lng: 19.167587 },
    { lat: 48.229432, lng: 19.167523 },
    { lat: 48.229237, lng: 19.167696 },
    { lat: 48.22907, lng: 19.167934 },
    { lat: 48.228867, lng: 19.168269 },
    { lat: 48.228674, lng: 19.168422 },
    { lat: 48.228521, lng: 19.168462 },
    { lat: 48.228383, lng: 19.168599 },
    { lat: 48.228233, lng: 19.168481 },
    { lat: 48.228157, lng: 19.168047 },
    { lat: 48.2281, lng: 19.167937 },
    { lat: 48.227987, lng: 19.167885 },
    { lat: 48.227766, lng: 19.167984 },
    { lat: 48.227519, lng: 19.168294 },
    { lat: 48.227363, lng: 19.168276 },
    { lat: 48.226867, lng: 19.167941 },
    { lat: 48.226665, lng: 19.167641 },
    { lat: 48.22646, lng: 19.167402 },
    { lat: 48.226251, lng: 19.167268 },
    { lat: 48.225992, lng: 19.167312 },
    { lat: 48.225784, lng: 19.167421 },
    { lat: 48.22526, lng: 19.167621 },
    { lat: 48.225092, lng: 19.167663 },
    { lat: 48.224851, lng: 19.167571 },
    { lat: 48.224652, lng: 19.167511 },
    { lat: 48.224331, lng: 19.167278 },
    { lat: 48.224186, lng: 19.167191 },
    { lat: 48.223858, lng: 19.167284 },
    { lat: 48.223408, lng: 19.167576 },
    { lat: 48.223177, lng: 19.167573 },
    { lat: 48.222906, lng: 19.167431 },
    { lat: 48.22265, lng: 19.167261 },
    { lat: 48.222449, lng: 19.167107 },
    { lat: 48.222311, lng: 19.166942 },
    { lat: 48.22219, lng: 19.166751 },
    { lat: 48.222101, lng: 19.166514 },
    { lat: 48.221647, lng: 19.166123 },
    { lat: 48.221395, lng: 19.165957 },
    { lat: 48.221163, lng: 19.165786 },
    { lat: 48.221037, lng: 19.165681 },
    { lat: 48.220973, lng: 19.165508 },
    { lat: 48.220918, lng: 19.165252 },
    { lat: 48.220925, lng: 19.164881 },
    { lat: 48.220963, lng: 19.164361 },
    { lat: 48.220604, lng: 19.164249 },
    { lat: 48.220391, lng: 19.164066 },
    { lat: 48.220165, lng: 19.163953 },
    { lat: 48.219757, lng: 19.163749 },
    { lat: 48.219775, lng: 19.163651 },
    { lat: 48.219403, lng: 19.16299 },
    { lat: 48.219243, lng: 19.162765 },
    { lat: 48.219102, lng: 19.162517 },
    { lat: 48.218882, lng: 19.162218 },
    { lat: 48.218679, lng: 19.162019 },
    { lat: 48.218619, lng: 19.162106 },
    { lat: 48.218581, lng: 19.162158 },
    { lat: 48.21835, lng: 19.161735 },
    { lat: 48.218127, lng: 19.161335 },
    { lat: 48.217975, lng: 19.160993 },
    { lat: 48.217728, lng: 19.160283 },
    { lat: 48.217757, lng: 19.159925 },
    { lat: 48.217809, lng: 19.159694 },
    { lat: 48.217946, lng: 19.15952 },
    { lat: 48.218331, lng: 19.158889 },
    { lat: 48.218588, lng: 19.158619 },
    { lat: 48.218713, lng: 19.158436 },
    { lat: 48.218917, lng: 19.157987 },
    { lat: 48.219044, lng: 19.157628 },
    { lat: 48.219175, lng: 19.157157 },
    { lat: 48.219323, lng: 19.156752 },
    { lat: 48.21974, lng: 19.156106 },
    { lat: 48.219832, lng: 19.155835 },
    { lat: 48.21983, lng: 19.154538 },
    { lat: 48.219871, lng: 19.154181 },
    { lat: 48.219872, lng: 19.153816 },
    { lat: 48.21981, lng: 19.153468 },
    { lat: 48.219843, lng: 19.15323 },
    { lat: 48.21993, lng: 19.153014 },
    { lat: 48.2200677, lng: 19.152789 },
    { lat: 48.220127, lng: 19.152692 },
    { lat: 48.220417, lng: 19.152457 },
    { lat: 48.220422, lng: 19.1523729 },
    { lat: 48.220037, lng: 19.1519332 },
    { lat: 48.2192105, lng: 19.151316 },
    { lat: 48.2182685, lng: 19.1513042 },
    { lat: 48.2180515, lng: 19.1513813 },
    { lat: 48.2177995, lng: 19.1512361 },
    { lat: 48.2176248, lng: 19.1513934 },
    { lat: 48.2168819, lng: 19.1517101 },
    { lat: 48.2167883, lng: 19.1515505 },
    { lat: 48.2162748, lng: 19.1516995 },
    { lat: 48.2162045, lng: 19.151825 },
    { lat: 48.2156985, lng: 19.1519202 },
    { lat: 48.2154772, lng: 19.1518699 },
    { lat: 48.215163, lng: 19.1518988 },
    { lat: 48.2150879, lng: 19.1520641 },
    { lat: 48.2148631, lng: 19.1520333 },
    { lat: 48.2147386, lng: 19.1521386 },
    { lat: 48.2144289, lng: 19.152166 },
    { lat: 48.2143489, lng: 19.1519628 },
    { lat: 48.2132961, lng: 19.1515801 },
    { lat: 48.2128233, lng: 19.1520682 },
    { lat: 48.2124862, lng: 19.152544 },
    { lat: 48.2121824, lng: 19.1520582 },
    { lat: 48.2116031, lng: 19.151426 },
    { lat: 48.2107485, lng: 19.1521251 },
    { lat: 48.2104146, lng: 19.1523053 },
    { lat: 48.2099491, lng: 19.1524879 },
    { lat: 48.2099946, lng: 19.1522234 },
    { lat: 48.2092412, lng: 19.1524468 },
    { lat: 48.2084011, lng: 19.1530739 },
    { lat: 48.2082426, lng: 19.1529027 },
    { lat: 48.2079366, lng: 19.1530951 },
    { lat: 48.2075857, lng: 19.1529083 },
    { lat: 48.2073006, lng: 19.1529145 },
    { lat: 48.2069589, lng: 19.1527741 },
    { lat: 48.2061338, lng: 19.1526838 },
    { lat: 48.205826, lng: 19.15257 },
    { lat: 48.20536, lng: 19.1527917 },
    { lat: 48.2046219, lng: 19.1530222 },
    { lat: 48.2044494, lng: 19.1532499 },
    { lat: 48.2040531, lng: 19.153441 },
    { lat: 48.2037459, lng: 19.1538148 },
    { lat: 48.2035362, lng: 19.1539299 },
    { lat: 48.2032034, lng: 19.1536266 },
    { lat: 48.2029955, lng: 19.1530999 },
    { lat: 48.2023114, lng: 19.1537124 },
    { lat: 48.2021027, lng: 19.1537112 },
    { lat: 48.2018335, lng: 19.1535552 },
    { lat: 48.2013147, lng: 19.153827 },
    { lat: 48.2011366, lng: 19.1540306 },
    { lat: 48.2010793, lng: 19.1541896 },
    { lat: 48.2004839, lng: 19.1550979 },
    { lat: 48.2003552, lng: 19.1551171 },
    { lat: 48.1995673, lng: 19.1565708 },
    { lat: 48.1991075, lng: 19.1577787 },
    { lat: 48.1993416, lng: 19.1584298 },
    { lat: 48.1984842, lng: 19.1587568 },
    { lat: 48.1983957, lng: 19.1589709 },
    { lat: 48.1983981, lng: 19.1591335 },
    { lat: 48.1976721, lng: 19.1594954 },
    { lat: 48.1973593, lng: 19.159941 },
    { lat: 48.1971167, lng: 19.1604139 },
    { lat: 48.1968689, lng: 19.1619844 },
    { lat: 48.1962337, lng: 19.1628142 },
    { lat: 48.1956448, lng: 19.1633757 },
    { lat: 48.1956278, lng: 19.163715 },
    { lat: 48.1923606, lng: 19.168168 },
    { lat: 48.1916661, lng: 19.1693915 },
    { lat: 48.1904526, lng: 19.1695447 },
    { lat: 48.1904388, lng: 19.1696967 },
    { lat: 48.1892092, lng: 19.1707056 },
    { lat: 48.1867727, lng: 19.1743164 },
    { lat: 48.1831137, lng: 19.1766011 },
    { lat: 48.1831287, lng: 19.1769042 },
  ],
  Sklabiná: [
    { lat: 48.1426994, lng: 19.3135144 },
    { lat: 48.1429525, lng: 19.3136198 },
    { lat: 48.1435083, lng: 19.313832 },
    { lat: 48.1438201, lng: 19.3143842 },
    { lat: 48.1442291, lng: 19.3159219 },
    { lat: 48.1442755, lng: 19.3163704 },
    { lat: 48.1450007, lng: 19.318102 },
    { lat: 48.144921, lng: 19.3203753 },
    { lat: 48.144643, lng: 19.3211097 },
    { lat: 48.1455529, lng: 19.3218848 },
    { lat: 48.1454553, lng: 19.322315 },
    { lat: 48.1455271, lng: 19.3224029 },
    { lat: 48.1453932, lng: 19.3228059 },
    { lat: 48.1451245, lng: 19.3232386 },
    { lat: 48.1450721, lng: 19.3237175 },
    { lat: 48.1448629, lng: 19.3243799 },
    { lat: 48.1446695, lng: 19.3253028 },
    { lat: 48.1444848, lng: 19.3255619 },
    { lat: 48.144324, lng: 19.3259445 },
    { lat: 48.1442232, lng: 19.3267551 },
    { lat: 48.1442266, lng: 19.3270349 },
    { lat: 48.1439316, lng: 19.3276984 },
    { lat: 48.143374, lng: 19.3295269 },
    { lat: 48.1431704, lng: 19.3299334 },
    { lat: 48.1430088, lng: 19.3304865 },
    { lat: 48.1429385, lng: 19.3310379 },
    { lat: 48.1427416, lng: 19.3314469 },
    { lat: 48.1427165, lng: 19.3318572 },
    { lat: 48.142565, lng: 19.3323884 },
    { lat: 48.1425589, lng: 19.3327536 },
    { lat: 48.1422275, lng: 19.3348121 },
    { lat: 48.1422447, lng: 19.3355676 },
    { lat: 48.1423222, lng: 19.3357932 },
    { lat: 48.1422231, lng: 19.3360075 },
    { lat: 48.1421532, lng: 19.3365462 },
    { lat: 48.1422925, lng: 19.3370507 },
    { lat: 48.1421701, lng: 19.3372957 },
    { lat: 48.1421849, lng: 19.3379766 },
    { lat: 48.1421238, lng: 19.3384451 },
    { lat: 48.1421684, lng: 19.3389012 },
    { lat: 48.1420757, lng: 19.3391532 },
    { lat: 48.1418685, lng: 19.3405624 },
    { lat: 48.1419094, lng: 19.3410132 },
    { lat: 48.1418767, lng: 19.3412475 },
    { lat: 48.1417336, lng: 19.3414598 },
    { lat: 48.1416558, lng: 19.3417424 },
    { lat: 48.1416971, lng: 19.3419179 },
    { lat: 48.141383, lng: 19.3422339 },
    { lat: 48.1413218, lng: 19.342508 },
    { lat: 48.1411482, lng: 19.3428823 },
    { lat: 48.1409899, lng: 19.3434805 },
    { lat: 48.1409883, lng: 19.3437309 },
    { lat: 48.1408458, lng: 19.3439078 },
    { lat: 48.1405942, lng: 19.3447016 },
    { lat: 48.1403201, lng: 19.3451862 },
    { lat: 48.1404073, lng: 19.3454765 },
    { lat: 48.1402943, lng: 19.3459858 },
    { lat: 48.1402754, lng: 19.3462765 },
    { lat: 48.1403824, lng: 19.3465075 },
    { lat: 48.1403746, lng: 19.3474265 },
    { lat: 48.140524, lng: 19.3479073 },
    { lat: 48.1406207, lng: 19.3484196 },
    { lat: 48.1404884, lng: 19.3493975 },
    { lat: 48.1402261, lng: 19.3505069 },
    { lat: 48.1394823, lng: 19.3516138 },
    { lat: 48.1394798, lng: 19.3517937 },
    { lat: 48.1393902, lng: 19.3519924 },
    { lat: 48.139321, lng: 19.3523859 },
    { lat: 48.1392999, lng: 19.3530505 },
    { lat: 48.1390585, lng: 19.3539846 },
    { lat: 48.1386081, lng: 19.3546493 },
    { lat: 48.1384546, lng: 19.3551869 },
    { lat: 48.138219, lng: 19.3580997 },
    { lat: 48.138111, lng: 19.3581049 },
    { lat: 48.1381041, lng: 19.3588819 },
    { lat: 48.1383166, lng: 19.3595759 },
    { lat: 48.1390213, lng: 19.3602143 },
    { lat: 48.1390811, lng: 19.361226 },
    { lat: 48.1409808, lng: 19.3645315 },
    { lat: 48.1413965, lng: 19.3654484 },
    { lat: 48.1413773, lng: 19.3657969 },
    { lat: 48.1412863, lng: 19.3662495 },
    { lat: 48.141183, lng: 19.3662634 },
    { lat: 48.1416528, lng: 19.3676466 },
    { lat: 48.1420151, lng: 19.3683613 },
    { lat: 48.1422585, lng: 19.3689758 },
    { lat: 48.1427416, lng: 19.3715396 },
    { lat: 48.1428627, lng: 19.3721826 },
    { lat: 48.1450836, lng: 19.3758022 },
    { lat: 48.1463773, lng: 19.3763386 },
    { lat: 48.1469041, lng: 19.3766575 },
    { lat: 48.1475005, lng: 19.3767768 },
    { lat: 48.1476774, lng: 19.3765895 },
    { lat: 48.1477932, lng: 19.3765797 },
    { lat: 48.1481369, lng: 19.377102 },
    { lat: 48.1483618, lng: 19.3771557 },
    { lat: 48.1483958, lng: 19.377067 },
    { lat: 48.1486267, lng: 19.3769972 },
    { lat: 48.1486903, lng: 19.3772654 },
    { lat: 48.1487584, lng: 19.3772264 },
    { lat: 48.1487869, lng: 19.3772096 },
    { lat: 48.1493192, lng: 19.3769562 },
    { lat: 48.1496691, lng: 19.3771582 },
    { lat: 48.1497928, lng: 19.3773133 },
    { lat: 48.1501744, lng: 19.3774713 },
    { lat: 48.1508084, lng: 19.3776257 },
    { lat: 48.1525478, lng: 19.3777985 },
    { lat: 48.154485, lng: 19.3786639 },
    { lat: 48.1550239, lng: 19.3791881 },
    { lat: 48.1553751, lng: 19.3793907 },
    { lat: 48.155865, lng: 19.3798513 },
    { lat: 48.1563365, lng: 19.380051 },
    { lat: 48.1570002, lng: 19.3804476 },
    { lat: 48.1571601, lng: 19.3806554 },
    { lat: 48.1574458, lng: 19.3808812 },
    { lat: 48.1577606, lng: 19.3810058 },
    { lat: 48.1590575, lng: 19.3817772 },
    { lat: 48.1603335, lng: 19.381098 },
    { lat: 48.1603564, lng: 19.3793107 },
    { lat: 48.1595176, lng: 19.378477 },
    { lat: 48.1606117, lng: 19.3754268 },
    { lat: 48.1607648, lng: 19.3750677 },
    { lat: 48.1610856, lng: 19.3745396 },
    { lat: 48.1609532, lng: 19.3743645 },
    { lat: 48.1618425, lng: 19.3736783 },
    { lat: 48.1633394, lng: 19.3722893 },
    { lat: 48.1642866, lng: 19.3722082 },
    { lat: 48.164778, lng: 19.3711171 },
    { lat: 48.1647009, lng: 19.3709919 },
    { lat: 48.1646897, lng: 19.3704773 },
    { lat: 48.1640266, lng: 19.3691014 },
    { lat: 48.1639546, lng: 19.3674064 },
    { lat: 48.1640211, lng: 19.3673103 },
    { lat: 48.1638343, lng: 19.3655298 },
    { lat: 48.1640365, lng: 19.3654651 },
    { lat: 48.1641053, lng: 19.3656558 },
    { lat: 48.1641239, lng: 19.3651493 },
    { lat: 48.1640348, lng: 19.3634074 },
    { lat: 48.1641901, lng: 19.3633301 },
    { lat: 48.1642125, lng: 19.3625262 },
    { lat: 48.1639149, lng: 19.3622877 },
    { lat: 48.1638656, lng: 19.361989 },
    { lat: 48.1638005, lng: 19.3607239 },
    { lat: 48.164775, lng: 19.358954 },
    { lat: 48.1652755, lng: 19.3582925 },
    { lat: 48.1654409, lng: 19.3579107 },
    { lat: 48.1657253, lng: 19.3578461 },
    { lat: 48.1675474, lng: 19.3557013 },
    { lat: 48.1679415, lng: 19.3543485 },
    { lat: 48.1684602, lng: 19.3529206 },
    { lat: 48.1681091, lng: 19.3525786 },
    { lat: 48.1675488, lng: 19.3521101 },
    { lat: 48.166751, lng: 19.351618 },
    { lat: 48.1666528, lng: 19.3514266 },
    { lat: 48.1663863, lng: 19.3512543 },
    { lat: 48.1653895, lng: 19.3502815 },
    { lat: 48.1645767, lng: 19.3493592 },
    { lat: 48.1606117, lng: 19.3456578 },
    { lat: 48.1604815, lng: 19.3457399 },
    { lat: 48.1602441, lng: 19.3456994 },
    { lat: 48.1591454, lng: 19.3449781 },
    { lat: 48.1584873, lng: 19.3441104 },
    { lat: 48.1582602, lng: 19.3430475 },
    { lat: 48.1579558, lng: 19.3425062 },
    { lat: 48.1578994, lng: 19.3422482 },
    { lat: 48.1577203, lng: 19.3422261 },
    { lat: 48.1556062, lng: 19.3384583 },
    { lat: 48.1545138, lng: 19.3368827 },
    { lat: 48.1538326, lng: 19.3363861 },
    { lat: 48.1534984, lng: 19.3351976 },
    { lat: 48.1529821, lng: 19.3339806 },
    { lat: 48.1529901, lng: 19.33017 },
    { lat: 48.1530908, lng: 19.3293321 },
    { lat: 48.1532904, lng: 19.3294504 },
    { lat: 48.1534389, lng: 19.3293769 },
    { lat: 48.1536268, lng: 19.3288301 },
    { lat: 48.1538905, lng: 19.328341 },
    { lat: 48.1541995, lng: 19.3280642 },
    { lat: 48.1540584, lng: 19.3277525 },
    { lat: 48.1540486, lng: 19.3273879 },
    { lat: 48.154191, lng: 19.3272794 },
    { lat: 48.1543257, lng: 19.3268699 },
    { lat: 48.1544839, lng: 19.3268045 },
    { lat: 48.1546366, lng: 19.3269095 },
    { lat: 48.1546728, lng: 19.3268424 },
    { lat: 48.154569, lng: 19.3263989 },
    { lat: 48.1545694, lng: 19.3261479 },
    { lat: 48.1546715, lng: 19.3259541 },
    { lat: 48.1548984, lng: 19.3261668 },
    { lat: 48.1549941, lng: 19.3259324 },
    { lat: 48.1550278, lng: 19.3253227 },
    { lat: 48.155277, lng: 19.3252055 },
    { lat: 48.1553298, lng: 19.3247148 },
    { lat: 48.1555109, lng: 19.3246586 },
    { lat: 48.1554926, lng: 19.3245589 },
    { lat: 48.1554052, lng: 19.3245234 },
    { lat: 48.1556467, lng: 19.324178 },
    { lat: 48.1558926, lng: 19.3243947 },
    { lat: 48.1559145, lng: 19.3245886 },
    { lat: 48.155997, lng: 19.3246397 },
    { lat: 48.1565397, lng: 19.3236246 },
    { lat: 48.158031, lng: 19.3213607 },
    { lat: 48.158544, lng: 19.3204508 },
    { lat: 48.1588097, lng: 19.3201655 },
    { lat: 48.1593047, lng: 19.3194206 },
    { lat: 48.1593469, lng: 19.3194785 },
    { lat: 48.1595234, lng: 19.3190726 },
    { lat: 48.1596431, lng: 19.3186071 },
    { lat: 48.1598129, lng: 19.3182982 },
    { lat: 48.1597322, lng: 19.3180008 },
    { lat: 48.1595362, lng: 19.317909 },
    { lat: 48.1587489, lng: 19.3171293 },
    { lat: 48.157136, lng: 19.3158012 },
    { lat: 48.1568483, lng: 19.315493 },
    { lat: 48.1557766, lng: 19.3147046 },
    { lat: 48.1490211, lng: 19.3092138 },
    { lat: 48.1489912, lng: 19.3092248 },
    { lat: 48.1485821, lng: 19.3094783 },
    { lat: 48.1472315, lng: 19.3102951 },
    { lat: 48.1465867, lng: 19.3108962 },
    { lat: 48.1457533, lng: 19.311446 },
    { lat: 48.145366, lng: 19.3118291 },
    { lat: 48.1448648, lng: 19.3120925 },
    { lat: 48.144384, lng: 19.312666 },
    { lat: 48.1431133, lng: 19.3134034 },
    { lat: 48.1430959, lng: 19.3134152 },
    { lat: 48.1426994, lng: 19.3135144 },
  ],
  BalognadIpľom: [
    { lat: 48.0901435, lng: 19.1426243 },
    { lat: 48.0908697, lng: 19.1408634 },
    { lat: 48.0914211, lng: 19.1411153 },
    { lat: 48.0916185, lng: 19.1401896 },
    { lat: 48.0924343, lng: 19.1373139 },
    { lat: 48.0932378, lng: 19.1375559 },
    { lat: 48.0936172, lng: 19.1361466 },
    { lat: 48.0939983, lng: 19.1362844 },
    { lat: 48.0951973, lng: 19.1307026 },
    { lat: 48.0958145, lng: 19.1286354 },
    { lat: 48.0962794, lng: 19.1267985 },
    { lat: 48.0959515, lng: 19.1266196 },
    { lat: 48.0965047, lng: 19.1236143 },
    { lat: 48.0966411, lng: 19.1227376 },
    { lat: 48.0965842, lng: 19.1226169 },
    { lat: 48.0966622, lng: 19.1220789 },
    { lat: 48.0958949, lng: 19.1217724 },
    { lat: 48.0960551, lng: 19.1209853 },
    { lat: 48.096502, lng: 19.1192847 },
    { lat: 48.0967291, lng: 19.1179891 },
    { lat: 48.096911, lng: 19.1164664 },
    { lat: 48.0977183, lng: 19.1132541 },
    { lat: 48.0974474, lng: 19.1131789 },
    { lat: 48.0971805, lng: 19.1129831 },
    { lat: 48.097115, lng: 19.1131138 },
    { lat: 48.0968382, lng: 19.1128559 },
    { lat: 48.0964021, lng: 19.1126772 },
    { lat: 48.0960988, lng: 19.1124748 },
    { lat: 48.095951, lng: 19.1125858 },
    { lat: 48.0956151, lng: 19.1126335 },
    { lat: 48.0953966, lng: 19.1125677 },
    { lat: 48.0950268, lng: 19.1126365 },
    { lat: 48.0942375, lng: 19.1124532 },
    { lat: 48.0938912, lng: 19.1122686 },
    { lat: 48.0932809, lng: 19.1117909 },
    { lat: 48.0929078, lng: 19.1111824 },
    { lat: 48.0927549, lng: 19.1110358 },
    { lat: 48.0926646, lng: 19.1107437 },
    { lat: 48.0921485, lng: 19.1098031 },
    { lat: 48.0921278, lng: 19.1097709 },
    { lat: 48.0910079, lng: 19.1103248 },
    { lat: 48.0890119, lng: 19.10951 },
    { lat: 48.0879509, lng: 19.1090769 },
    { lat: 48.0858616, lng: 19.1122422 },
    { lat: 48.0843331, lng: 19.1137066 },
    { lat: 48.0839955, lng: 19.1146617 },
    { lat: 48.0836408, lng: 19.114807 },
    { lat: 48.08357, lng: 19.1150579 },
    { lat: 48.0834781, lng: 19.1151323 },
    { lat: 48.0830141, lng: 19.1152352 },
    { lat: 48.0829412, lng: 19.1151965 },
    { lat: 48.0828951, lng: 19.1148607 },
    { lat: 48.0828709, lng: 19.1148306 },
    { lat: 48.0828657, lng: 19.1150611 },
    { lat: 48.082816, lng: 19.114994 },
    { lat: 48.082856, lng: 19.1145992 },
    { lat: 48.0822587, lng: 19.1145358 },
    { lat: 48.082092, lng: 19.1143584 },
    { lat: 48.0820605, lng: 19.1141356 },
    { lat: 48.0821258, lng: 19.1139312 },
    { lat: 48.0816036, lng: 19.1134332 },
    { lat: 48.0813403, lng: 19.1133748 },
    { lat: 48.0812816, lng: 19.1135742 },
    { lat: 48.0811129, lng: 19.1136065 },
    { lat: 48.0810321, lng: 19.1135228 },
    { lat: 48.0810114, lng: 19.1133594 },
    { lat: 48.0807049, lng: 19.1132261 },
    { lat: 48.0806365, lng: 19.1131432 },
    { lat: 48.0806451, lng: 19.1128443 },
    { lat: 48.0805257, lng: 19.1127561 },
    { lat: 48.0804559, lng: 19.1126229 },
    { lat: 48.0804509, lng: 19.11248 },
    { lat: 48.0802288, lng: 19.1123905 },
    { lat: 48.0802549, lng: 19.112144 },
    { lat: 48.0803099, lng: 19.1120959 },
    { lat: 48.080333, lng: 19.1119724 },
    { lat: 48.0798474, lng: 19.1116635 },
    { lat: 48.079679, lng: 19.111113 },
    { lat: 48.0794164, lng: 19.1108702 },
    { lat: 48.0791055, lng: 19.1103803 },
    { lat: 48.0787332, lng: 19.1101272 },
    { lat: 48.0782802, lng: 19.1099977 },
    { lat: 48.0781105, lng: 19.1098502 },
    { lat: 48.0777997, lng: 19.109231 },
    { lat: 48.0776157, lng: 19.1084161 },
    { lat: 48.0776189, lng: 19.1078263 },
    { lat: 48.0768248, lng: 19.1075935 },
    { lat: 48.0745902, lng: 19.1073223 },
    { lat: 48.0741475, lng: 19.1070158 },
    { lat: 48.0733628, lng: 19.1067453 },
    { lat: 48.0725758, lng: 19.106362 },
    { lat: 48.0725992, lng: 19.1059301 },
    { lat: 48.072547, lng: 19.105723 },
    { lat: 48.0725842, lng: 19.1055888 },
    { lat: 48.0730481, lng: 19.1057325 },
    { lat: 48.0731998, lng: 19.1055887 },
    { lat: 48.073278, lng: 19.1051463 },
    { lat: 48.073075, lng: 19.1044968 },
    { lat: 48.0729775, lng: 19.1044411 },
    { lat: 48.0728378, lng: 19.1045093 },
    { lat: 48.0727565, lng: 19.1044144 },
    { lat: 48.072605, lng: 19.1040055 },
    { lat: 48.0723749, lng: 19.1039579 },
    { lat: 48.0723566, lng: 19.1038616 },
    { lat: 48.07258, lng: 19.1032398 },
    { lat: 48.0725724, lng: 19.1030169 },
    { lat: 48.0723801, lng: 19.1023316 },
    { lat: 48.0723421, lng: 19.101824 },
    { lat: 48.0723732, lng: 19.1016993 },
    { lat: 48.0727375, lng: 19.1014146 },
    { lat: 48.0727788, lng: 19.1012901 },
    { lat: 48.0726751, lng: 19.100822 },
    { lat: 48.0727243, lng: 19.1007009 },
    { lat: 48.0729453, lng: 19.100637 },
    { lat: 48.073176, lng: 19.1006954 },
    { lat: 48.0732649, lng: 19.1005554 },
    { lat: 48.0732574, lng: 19.1003641 },
    { lat: 48.0729095, lng: 19.0999483 },
    { lat: 48.0717116, lng: 19.1003351 },
    { lat: 48.0716789, lng: 19.1002902 },
    { lat: 48.0715873, lng: 19.1004195 },
    { lat: 48.0714533, lng: 19.1004705 },
    { lat: 48.0710473, lng: 19.1004676 },
    { lat: 48.0705264, lng: 19.1007773 },
    { lat: 48.0703947, lng: 19.100761 },
    { lat: 48.0702753, lng: 19.1009192 },
    { lat: 48.0703097, lng: 19.0990951 },
    { lat: 48.0692296, lng: 19.0981071 },
    { lat: 48.0690577, lng: 19.0977573 },
    { lat: 48.068578, lng: 19.0970975 },
    { lat: 48.067862, lng: 19.098015 },
    { lat: 48.067492, lng: 19.098657 },
    { lat: 48.067318, lng: 19.099118 },
    { lat: 48.067081, lng: 19.100631 },
    { lat: 48.067048, lng: 19.101897 },
    { lat: 48.066971, lng: 19.102652 },
    { lat: 48.066713, lng: 19.103649 },
    { lat: 48.06613, lng: 19.104842 },
    { lat: 48.065733, lng: 19.106138 },
    { lat: 48.06564, lng: 19.106724 },
    { lat: 48.065596, lng: 19.107402 },
    { lat: 48.065635, lng: 19.10812 },
    { lat: 48.065652, lng: 19.108217 },
    { lat: 48.065714, lng: 19.108497 },
    { lat: 48.065728, lng: 19.108561 },
    { lat: 48.065781, lng: 19.108816 },
    { lat: 48.065805, lng: 19.10891 },
    { lat: 48.065879, lng: 19.109187 },
    { lat: 48.065896, lng: 19.109258 },
    { lat: 48.065924, lng: 19.109362 },
    { lat: 48.065947, lng: 19.109449 },
    { lat: 48.065969, lng: 19.109523 },
    { lat: 48.065979, lng: 19.109556 },
    { lat: 48.065991, lng: 19.109605 },
    { lat: 48.066035, lng: 19.109707 },
    { lat: 48.066105, lng: 19.109894 },
    { lat: 48.066156, lng: 19.110013 },
    { lat: 48.066186, lng: 19.11008 },
    { lat: 48.066335, lng: 19.110456 },
    { lat: 48.066447, lng: 19.110767 },
    { lat: 48.06651, lng: 19.110944 },
    { lat: 48.066548, lng: 19.111041 },
    { lat: 48.066564, lng: 19.111097 },
    { lat: 48.066604, lng: 19.111231 },
    { lat: 48.066611, lng: 19.111279 },
    { lat: 48.066677, lng: 19.111676 },
    { lat: 48.066732, lng: 19.111972 },
    { lat: 48.06674, lng: 19.11229 },
    { lat: 48.066739, lng: 19.112462 },
    { lat: 48.066738, lng: 19.112565 },
    { lat: 48.066739, lng: 19.112895 },
    { lat: 48.066741, lng: 19.113362 },
    { lat: 48.066725, lng: 19.113611 },
    { lat: 48.066703, lng: 19.113953 },
    { lat: 48.066671, lng: 19.114276 },
    { lat: 48.066614, lng: 19.114627 },
    { lat: 48.066598, lng: 19.114702 },
    { lat: 48.066581, lng: 19.114779 },
    { lat: 48.06656, lng: 19.114868 },
    { lat: 48.066539, lng: 19.114951 },
    { lat: 48.066477, lng: 19.115195 },
    { lat: 48.066464, lng: 19.115243 },
    { lat: 48.06642, lng: 19.115391 },
    { lat: 48.066387, lng: 19.115506 },
    { lat: 48.066363, lng: 19.115576 },
    { lat: 48.066302, lng: 19.115826 },
    { lat: 48.066243, lng: 19.116125 },
    { lat: 48.066225, lng: 19.11624 },
    { lat: 48.066201, lng: 19.116425 },
    { lat: 48.066166, lng: 19.116882 },
    { lat: 48.066168, lng: 19.117246 },
    { lat: 48.066169, lng: 19.117329 },
    { lat: 48.066174, lng: 19.117383 },
    { lat: 48.066183, lng: 19.117482 },
    { lat: 48.066214, lng: 19.117703 },
    { lat: 48.066261, lng: 19.118031 },
    { lat: 48.066323, lng: 19.118428 },
    { lat: 48.066409, lng: 19.118793 },
    { lat: 48.066427, lng: 19.118857 },
    { lat: 48.066458, lng: 19.118962 },
    { lat: 48.066483, lng: 19.119023 },
    { lat: 48.066512, lng: 19.119087 },
    { lat: 48.066639, lng: 19.119331 },
    { lat: 48.06678, lng: 19.119578 },
    { lat: 48.066812, lng: 19.119629 },
    { lat: 48.066836, lng: 19.119675 },
    { lat: 48.067171, lng: 19.120133 },
    { lat: 48.067341, lng: 19.120333 },
    { lat: 48.067393, lng: 19.120387 },
    { lat: 48.067631, lng: 19.120615 },
    { lat: 48.067699, lng: 19.120681 },
    { lat: 48.067725, lng: 19.120699 },
    { lat: 48.067813, lng: 19.12076 },
    { lat: 48.067889, lng: 19.120811 },
    { lat: 48.068154, lng: 19.120944 },
    { lat: 48.068204, lng: 19.12096 },
    { lat: 48.068244, lng: 19.12098 },
    { lat: 48.068373, lng: 19.121044 },
    { lat: 48.068418, lng: 19.121067 },
    { lat: 48.068521, lng: 19.121114 },
    { lat: 48.06865, lng: 19.121174 },
    { lat: 48.069361, lng: 19.121483 },
    { lat: 48.069603, lng: 19.121589 },
    { lat: 48.069771, lng: 19.12168 },
    { lat: 48.070026, lng: 19.121832 },
    { lat: 48.070088, lng: 19.121884 },
    { lat: 48.07014, lng: 19.121925 },
    { lat: 48.070214, lng: 19.121986 },
    { lat: 48.070259, lng: 19.122051 },
    { lat: 48.070315, lng: 19.122111 },
    { lat: 48.070342, lng: 19.122146 },
    { lat: 48.070431, lng: 19.122262 },
    { lat: 48.070472, lng: 19.122316 },
    { lat: 48.070505, lng: 19.122371 },
    { lat: 48.070584, lng: 19.122503 },
    { lat: 48.070622, lng: 19.122563 },
    { lat: 48.070654, lng: 19.122633 },
    { lat: 48.070744, lng: 19.122858 },
    { lat: 48.070852, lng: 19.123167 },
    { lat: 48.070903, lng: 19.123354 },
    { lat: 48.070964, lng: 19.12363 },
    { lat: 48.07105, lng: 19.124007 },
    { lat: 48.071113, lng: 19.124222 },
    { lat: 48.071167, lng: 19.124384 },
    { lat: 48.071203, lng: 19.124474 },
    { lat: 48.07124, lng: 19.124556 },
    { lat: 48.071283, lng: 19.124636 },
    { lat: 48.071385, lng: 19.124789 },
    { lat: 48.071491, lng: 19.124924 },
    { lat: 48.071594, lng: 19.125029 },
    { lat: 48.071634, lng: 19.125093 },
    { lat: 48.071715, lng: 19.125188 },
    { lat: 48.071783, lng: 19.125268 },
    { lat: 48.0722, lng: 19.12574 },
    { lat: 48.072424, lng: 19.126018 },
    { lat: 48.07258, lng: 19.126207 },
    { lat: 48.072759, lng: 19.126419 },
    { lat: 48.072936, lng: 19.126615 },
    { lat: 48.073139, lng: 19.126843 },
    { lat: 48.073298, lng: 19.127032 },
    { lat: 48.073519, lng: 19.127288 },
    { lat: 48.073629, lng: 19.127445 },
    { lat: 48.073696, lng: 19.127588 },
    { lat: 48.073723, lng: 19.127645 },
    { lat: 48.073785, lng: 19.127875 },
    { lat: 48.073862, lng: 19.128248 },
    { lat: 48.073886, lng: 19.128463 },
    { lat: 48.073905, lng: 19.128669 },
    { lat: 48.073909, lng: 19.128871 },
    { lat: 48.073909, lng: 19.128993 },
    { lat: 48.073897, lng: 19.129208 },
    { lat: 48.07388, lng: 19.129437 },
    { lat: 48.073868, lng: 19.12962 },
    { lat: 48.073826, lng: 19.129936 },
    { lat: 48.073796, lng: 19.130161 },
    { lat: 48.073791, lng: 19.130279 },
    { lat: 48.073722, lng: 19.130651 },
    { lat: 48.073628, lng: 19.131278 },
    { lat: 48.073596, lng: 19.131495 },
    { lat: 48.073548, lng: 19.131874 },
    { lat: 48.073516, lng: 19.132161 },
    { lat: 48.073496, lng: 19.132482 },
    { lat: 48.0735, lng: 19.132738 },
    { lat: 48.073528, lng: 19.13308 },
    { lat: 48.073538, lng: 19.133153 },
    { lat: 48.073563, lng: 19.133236 },
    { lat: 48.073635, lng: 19.133493 },
    { lat: 48.073655, lng: 19.133557 },
    { lat: 48.073766, lng: 19.133902 },
    { lat: 48.073804, lng: 19.134051 },
    { lat: 48.073891, lng: 19.134295 },
    { lat: 48.073927, lng: 19.134387 },
    { lat: 48.074028, lng: 19.134686 },
    { lat: 48.074057, lng: 19.134787 },
    { lat: 48.074113, lng: 19.135005 },
    { lat: 48.07414, lng: 19.135196 },
    { lat: 48.074146, lng: 19.135289 },
    { lat: 48.074141, lng: 19.135487 },
    { lat: 48.074126, lng: 19.13566 },
    { lat: 48.074101, lng: 19.135869 },
    { lat: 48.074098, lng: 19.135893 },
    { lat: 48.074067, lng: 19.136068 },
    { lat: 48.074043, lng: 19.136158 },
    { lat: 48.073971, lng: 19.136438 },
    { lat: 48.073943, lng: 19.13654 },
    { lat: 48.073841, lng: 19.136939 },
    { lat: 48.07367, lng: 19.137216 },
    { lat: 48.073626, lng: 19.137301 },
    { lat: 48.073459, lng: 19.137565 },
    { lat: 48.073288, lng: 19.137815 },
    { lat: 48.073147, lng: 19.138063 },
    { lat: 48.07311, lng: 19.138143 },
    { lat: 48.072996, lng: 19.138381 },
    { lat: 48.072903, lng: 19.138653 },
    { lat: 48.072886, lng: 19.138734 },
    { lat: 48.072856, lng: 19.138869 },
    { lat: 48.072804, lng: 19.139105 },
    { lat: 48.072786, lng: 19.139191 },
    { lat: 48.072761, lng: 19.139404 },
    { lat: 48.072738, lng: 19.139711 },
    { lat: 48.072735, lng: 19.139805 },
    { lat: 48.072737, lng: 19.139898 },
    { lat: 48.072744, lng: 19.140078 },
    { lat: 48.072759, lng: 19.140266 },
    { lat: 48.07278, lng: 19.140538 },
    { lat: 48.072789, lng: 19.140655 },
    { lat: 48.072826, lng: 19.141094 },
    { lat: 48.072825, lng: 19.141297 },
    { lat: 48.072826, lng: 19.141399 },
    { lat: 48.072817, lng: 19.141531 },
    { lat: 48.07281, lng: 19.141609 },
    { lat: 48.072791, lng: 19.141738 },
    { lat: 48.072754, lng: 19.141892 },
    { lat: 48.072721, lng: 19.142015 },
    { lat: 48.072642, lng: 19.142265 },
    { lat: 48.072608, lng: 19.142353 },
    { lat: 48.07252, lng: 19.142567 },
    { lat: 48.072429, lng: 19.142751 },
    { lat: 48.072403, lng: 19.1428 },
    { lat: 48.072326, lng: 19.142938 },
    { lat: 48.072115, lng: 19.143229 },
    { lat: 48.071967, lng: 19.143413 },
    { lat: 48.071805, lng: 19.143576 },
    { lat: 48.071699, lng: 19.143662 },
    { lat: 48.0715, lng: 19.143772 },
    { lat: 48.071274, lng: 19.143889 },
    { lat: 48.071176, lng: 19.143921 },
    { lat: 48.071132, lng: 19.143933 },
    { lat: 48.07083, lng: 19.143925 },
    { lat: 48.070583, lng: 19.143914 },
    { lat: 48.070538, lng: 19.143908 },
    { lat: 48.070268, lng: 19.143933 },
    { lat: 48.070157, lng: 19.143955 },
    { lat: 48.069815, lng: 19.14409 },
    { lat: 48.069655, lng: 19.144164 },
    { lat: 48.069503, lng: 19.144261 },
    { lat: 48.069462, lng: 19.144292 },
    { lat: 48.069311, lng: 19.144434 },
    { lat: 48.069153, lng: 19.144629 },
    { lat: 48.069098, lng: 19.144701 },
    { lat: 48.06904, lng: 19.144804 },
    { lat: 48.068997, lng: 19.144885 },
    { lat: 48.068974, lng: 19.144941 },
    { lat: 48.068887, lng: 19.145145 },
    { lat: 48.068797, lng: 19.145396 },
    { lat: 48.068788, lng: 19.145427 },
    { lat: 48.068766, lng: 19.145523 },
    { lat: 48.068728, lng: 19.145747 },
    { lat: 48.068705, lng: 19.145946 },
    { lat: 48.068691, lng: 19.146346 },
    { lat: 48.068685, lng: 19.146593 },
    { lat: 48.068678, lng: 19.146834 },
    { lat: 48.068664, lng: 19.14707 },
    { lat: 48.068614, lng: 19.147346 },
    { lat: 48.068596, lng: 19.147442 },
    { lat: 48.068529, lng: 19.147694 },
    { lat: 48.068505, lng: 19.147788 },
    { lat: 48.068409, lng: 19.148044 },
    { lat: 48.068319, lng: 19.148221 },
    { lat: 48.068295, lng: 19.148264 },
    { lat: 48.068179, lng: 19.148458 },
    { lat: 48.068156, lng: 19.148487 },
    { lat: 48.068124, lng: 19.148519 },
    { lat: 48.068031, lng: 19.148644 },
    { lat: 48.06795, lng: 19.148726 },
    { lat: 48.067752, lng: 19.148919 },
    { lat: 48.067519, lng: 19.149152 },
    { lat: 48.068046, lng: 19.1492625 },
    { lat: 48.068324, lng: 19.1494783 },
    { lat: 48.068811, lng: 19.1507901 },
    { lat: 48.0688242, lng: 19.1511439 },
    { lat: 48.0689365, lng: 19.1515209 },
    { lat: 48.0691659, lng: 19.1520041 },
    { lat: 48.0692622, lng: 19.152084 },
    { lat: 48.0694275, lng: 19.1520723 },
    { lat: 48.0694844, lng: 19.1523141 },
    { lat: 48.0696024, lng: 19.1524399 },
    { lat: 48.0698282, lng: 19.1520166 },
    { lat: 48.0699704, lng: 19.1520449 },
    { lat: 48.071114, lng: 19.1525522 },
    { lat: 48.0720308, lng: 19.1530554 },
    { lat: 48.0727263, lng: 19.1535299 },
    { lat: 48.0735362, lng: 19.1537404 },
    { lat: 48.0740574, lng: 19.1538183 },
    { lat: 48.0763169, lng: 19.1538015 },
    { lat: 48.0768342, lng: 19.153502 },
    { lat: 48.0772361, lng: 19.1534708 },
    { lat: 48.083441, lng: 19.1509582 },
    { lat: 48.0831268, lng: 19.1501695 },
    { lat: 48.0830125, lng: 19.1497591 },
    { lat: 48.0828083, lng: 19.1486008 },
    { lat: 48.0826833, lng: 19.1475189 },
    { lat: 48.084763, lng: 19.1476497 },
    { lat: 48.0850567, lng: 19.1462569 },
    { lat: 48.0855502, lng: 19.1447608 },
    { lat: 48.0875076, lng: 19.145153 },
    { lat: 48.088453, lng: 19.1416874 },
    { lat: 48.0889929, lng: 19.1419056 },
    { lat: 48.0896415, lng: 19.1422775 },
    { lat: 48.0901435, lng: 19.1426243 },
  ],
  Seľany: [
    { lat: 48.1775035, lng: 19.1662288 },
    { lat: 48.1772942, lng: 19.1663767 },
    { lat: 48.1767219, lng: 19.167588 },
    { lat: 48.1755468, lng: 19.1693546 },
    { lat: 48.1747788, lng: 19.170828 },
    { lat: 48.173084, lng: 19.1730705 },
    { lat: 48.1727262, lng: 19.1737444 },
    { lat: 48.1725088, lng: 19.1739051 },
    { lat: 48.1721161, lng: 19.1745147 },
    { lat: 48.1718689, lng: 19.1745749 },
    { lat: 48.1713402, lng: 19.1742549 },
    { lat: 48.1709985, lng: 19.1742754 },
    { lat: 48.1706817, lng: 19.174118 },
    { lat: 48.1706171, lng: 19.1741548 },
    { lat: 48.1704552, lng: 19.1740053 },
    { lat: 48.1699818, lng: 19.1741428 },
    { lat: 48.1698268, lng: 19.1743132 },
    { lat: 48.1694232, lng: 19.1745405 },
    { lat: 48.1693061, lng: 19.1745231 },
    { lat: 48.1691806, lng: 19.1746736 },
    { lat: 48.1686061, lng: 19.1749975 },
    { lat: 48.1681598, lng: 19.1750935 },
    { lat: 48.1678267, lng: 19.1749248 },
    { lat: 48.167632, lng: 19.1751826 },
    { lat: 48.1674535, lng: 19.1751928 },
    { lat: 48.1671738, lng: 19.1756454 },
    { lat: 48.1671191, lng: 19.1759544 },
    { lat: 48.166953, lng: 19.1761536 },
    { lat: 48.1668982, lng: 19.1763207 },
    { lat: 48.1667639, lng: 19.1764747 },
    { lat: 48.1665754, lng: 19.1769045 },
    { lat: 48.1664129, lng: 19.1771485 },
    { lat: 48.1663916, lng: 19.1772673 },
    { lat: 48.1664481, lng: 19.177497 },
    { lat: 48.1662965, lng: 19.1777613 },
    { lat: 48.1662137, lng: 19.1781832 },
    { lat: 48.1662679, lng: 19.1786858 },
    { lat: 48.1660073, lng: 19.1796592 },
    { lat: 48.1658808, lng: 19.1798871 },
    { lat: 48.1657338, lng: 19.1799899 },
    { lat: 48.1657534, lng: 19.1802469 },
    { lat: 48.1656975, lng: 19.1806211 },
    { lat: 48.1655166, lng: 19.1810659 },
    { lat: 48.1655421, lng: 19.181354 },
    { lat: 48.1653721, lng: 19.1815419 },
    { lat: 48.1652592, lng: 19.181856 },
    { lat: 48.1652626, lng: 19.1826088 },
    { lat: 48.1651879, lng: 19.1826502 },
    { lat: 48.1650576, lng: 19.1824521 },
    { lat: 48.1650299, lng: 19.1825067 },
    { lat: 48.1648117, lng: 19.1823373 },
    { lat: 48.1644952, lng: 19.181999 },
    { lat: 48.1626288, lng: 19.1798897 },
    { lat: 48.1623261, lng: 19.1794663 },
    { lat: 48.1623677, lng: 19.1793296 },
    { lat: 48.1622307, lng: 19.1790676 },
    { lat: 48.1620994, lng: 19.1785567 },
    { lat: 48.1619876, lng: 19.1779116 },
    { lat: 48.1620136, lng: 19.1778685 },
    { lat: 48.1619515, lng: 19.1777062 },
    { lat: 48.1611531, lng: 19.1786567 },
    { lat: 48.1607025, lng: 19.178845 },
    { lat: 48.1603594, lng: 19.1793878 },
    { lat: 48.1601896, lng: 19.1798956 },
    { lat: 48.1599343, lng: 19.1803404 },
    { lat: 48.15941, lng: 19.182161 },
    { lat: 48.1592552, lng: 19.1833407 },
    { lat: 48.1590502, lng: 19.1840293 },
    { lat: 48.1587792, lng: 19.1838562 },
    { lat: 48.1585447, lng: 19.1843584 },
    { lat: 48.1580406, lng: 19.1849475 },
    { lat: 48.1578243, lng: 19.1851112 },
    { lat: 48.1578653, lng: 19.1852331 },
    { lat: 48.1577228, lng: 19.1854762 },
    { lat: 48.1577097, lng: 19.1856408 },
    { lat: 48.1572206, lng: 19.1860538 },
    { lat: 48.1569984, lng: 19.1860985 },
    { lat: 48.1569156, lng: 19.1864978 },
    { lat: 48.156741, lng: 19.1866597 },
    { lat: 48.1565994, lng: 19.1871491 },
    { lat: 48.1563007, lng: 19.1874246 },
    { lat: 48.155699, lng: 19.1885703 },
    { lat: 48.1553097, lng: 19.1891114 },
    { lat: 48.1549706, lng: 19.1897547 },
    { lat: 48.1547866, lng: 19.1899324 },
    { lat: 48.1536838, lng: 19.190656 },
    { lat: 48.1518979, lng: 19.1924727 },
    { lat: 48.1507127, lng: 19.1907543 },
    { lat: 48.1505408, lng: 19.1909463 },
    { lat: 48.1494511, lng: 19.1916888 },
    { lat: 48.1493591, lng: 19.1924265 },
    { lat: 48.1504205, lng: 19.1938542 },
    { lat: 48.1489613, lng: 19.194714 },
    { lat: 48.148425, lng: 19.1951953 },
    { lat: 48.1483313, lng: 19.1953313 },
    { lat: 48.1483647, lng: 19.1953672 },
    { lat: 48.1470447, lng: 19.1974018 },
    { lat: 48.1459791, lng: 19.1988559 },
    { lat: 48.1431316, lng: 19.2019018 },
    { lat: 48.1424631, lng: 19.2027437 },
    { lat: 48.1421273, lng: 19.2033641 },
    { lat: 48.1415068, lng: 19.205207 },
    { lat: 48.1408606, lng: 19.2066228 },
    { lat: 48.1406709, lng: 19.2069104 },
    { lat: 48.1396559, lng: 19.2080226 },
    { lat: 48.1382757, lng: 19.2097074 },
    { lat: 48.1369557, lng: 19.2108304 },
    { lat: 48.1358809, lng: 19.2116445 },
    { lat: 48.1358631, lng: 19.2115804 },
    { lat: 48.1326457, lng: 19.2131101 },
    { lat: 48.1310447, lng: 19.2148932 },
    { lat: 48.1309133, lng: 19.215222 },
    { lat: 48.1314126, lng: 19.2171023 },
    { lat: 48.1313638, lng: 19.21721 },
    { lat: 48.1314434, lng: 19.2173361 },
    { lat: 48.1314614, lng: 19.2189891 },
    { lat: 48.1316313, lng: 19.2191867 },
    { lat: 48.1316381, lng: 19.2200809 },
    { lat: 48.1317733, lng: 19.2205858 },
    { lat: 48.1317163, lng: 19.221007 },
    { lat: 48.1318726, lng: 19.221299 },
    { lat: 48.1321485, lng: 19.2215009 },
    { lat: 48.1322038, lng: 19.2217514 },
    { lat: 48.1323855, lng: 19.2219171 },
    { lat: 48.1324175, lng: 19.2222022 },
    { lat: 48.1325505, lng: 19.2223179 },
    { lat: 48.1325896, lng: 19.2224688 },
    { lat: 48.1329102, lng: 19.222547 },
    { lat: 48.1332299, lng: 19.222929 },
    { lat: 48.1335191, lng: 19.2228959 },
    { lat: 48.1338024, lng: 19.2232018 },
    { lat: 48.1339755, lng: 19.2236035 },
    { lat: 48.1339958, lng: 19.2236514 },
    { lat: 48.1340874, lng: 19.2238633 },
    { lat: 48.1351496, lng: 19.2230435 },
    { lat: 48.1363493, lng: 19.2217887 },
    { lat: 48.1372651, lng: 19.2209955 },
    { lat: 48.1379111, lng: 19.2206817 },
    { lat: 48.1412838, lng: 19.2190434 },
    { lat: 48.1419326, lng: 19.2191702 },
    { lat: 48.1441422, lng: 19.2199297 },
    { lat: 48.1462306, lng: 19.2194884 },
    { lat: 48.1462439, lng: 19.2195283 },
    { lat: 48.146766, lng: 19.2194434 },
    { lat: 48.1468138, lng: 19.2191523 },
    { lat: 48.1470761, lng: 19.2189184 },
    { lat: 48.1472222, lng: 19.2189292 },
    { lat: 48.1475684, lng: 19.2186586 },
    { lat: 48.1477304, lng: 19.218721 },
    { lat: 48.1481481, lng: 19.2187316 },
    { lat: 48.1489885, lng: 19.2190178 },
    { lat: 48.1491691, lng: 19.2186569 },
    { lat: 48.1496272, lng: 19.218595 },
    { lat: 48.150028, lng: 19.2182665 },
    { lat: 48.1503081, lng: 19.2183 },
    { lat: 48.1508795, lng: 19.2186891 },
    { lat: 48.1516042, lng: 19.2185443 },
    { lat: 48.1525356, lng: 19.2162134 },
    { lat: 48.1527172, lng: 19.2160113 },
    { lat: 48.1527167, lng: 19.215771 },
    { lat: 48.1527842, lng: 19.2154706 },
    { lat: 48.1532058, lng: 19.2146914 },
    { lat: 48.1532778, lng: 19.213976 },
    { lat: 48.1533434, lng: 19.2137048 },
    { lat: 48.1534295, lng: 19.2134147 },
    { lat: 48.1537618, lng: 19.2127459 },
    { lat: 48.154154, lng: 19.2115096 },
    { lat: 48.1541958, lng: 19.2111435 },
    { lat: 48.1544092, lng: 19.2107613 },
    { lat: 48.1543901, lng: 19.2104898 },
    { lat: 48.1544952, lng: 19.2102202 },
    { lat: 48.1546623, lng: 19.2100871 },
    { lat: 48.1549717, lng: 19.2100538 },
    { lat: 48.1554859, lng: 19.2097346 },
    { lat: 48.155554, lng: 19.2080731 },
    { lat: 48.1564988, lng: 19.2077772 },
    { lat: 48.1570293, lng: 19.2065107 },
    { lat: 48.1573856, lng: 19.2060083 },
    { lat: 48.1574364, lng: 19.2060768 },
    { lat: 48.1578132, lng: 19.2056258 },
    { lat: 48.1581461, lng: 19.2058861 },
    { lat: 48.1586833, lng: 19.2050531 },
    { lat: 48.1586483, lng: 19.2047738 },
    { lat: 48.1589194, lng: 19.2045568 },
    { lat: 48.1593479, lng: 19.2038039 },
    { lat: 48.1597902, lng: 19.2039727 },
    { lat: 48.160007, lng: 19.2037762 },
    { lat: 48.1616853, lng: 19.20434 },
    { lat: 48.1623835, lng: 19.2032755 },
    { lat: 48.1625423, lng: 19.2030225 },
    { lat: 48.1627815, lng: 19.2024535 },
    { lat: 48.162928, lng: 19.2013523 },
    { lat: 48.1628806, lng: 19.2007121 },
    { lat: 48.1629433, lng: 19.1999965 },
    { lat: 48.1632561, lng: 19.1985447 },
    { lat: 48.1632122, lng: 19.1973346 },
    { lat: 48.1639239, lng: 19.197253 },
    { lat: 48.1646513, lng: 19.1976894 },
    { lat: 48.1648404, lng: 19.1974337 },
    { lat: 48.1649715, lng: 19.1968247 },
    { lat: 48.1650188, lng: 19.1961605 },
    { lat: 48.1650677, lng: 19.1961463 },
    { lat: 48.1651486, lng: 19.195594 },
    { lat: 48.1652655, lng: 19.1954017 },
    { lat: 48.1653452, lng: 19.1951203 },
    { lat: 48.1653057, lng: 19.1947977 },
    { lat: 48.1655503, lng: 19.1943887 },
    { lat: 48.1657484, lng: 19.1941761 },
    { lat: 48.1658938, lng: 19.1941558 },
    { lat: 48.1659948, lng: 19.1940289 },
    { lat: 48.1661562, lng: 19.1940111 },
    { lat: 48.1663781, lng: 19.1937341 },
    { lat: 48.1665284, lng: 19.1936501 },
    { lat: 48.1670617, lng: 19.1934712 },
    { lat: 48.1671204, lng: 19.193516 },
    { lat: 48.1675762, lng: 19.1931359 },
    { lat: 48.1679998, lng: 19.192903 },
    { lat: 48.1681398, lng: 19.1927011 },
    { lat: 48.1683538, lng: 19.1927429 },
    { lat: 48.1686163, lng: 19.1923512 },
    { lat: 48.168761, lng: 19.1922925 },
    { lat: 48.1687588, lng: 19.1921992 },
    { lat: 48.1691282, lng: 19.1916429 },
    { lat: 48.1697616, lng: 19.1913251 },
    { lat: 48.1701662, lng: 19.1907966 },
    { lat: 48.1703822, lng: 19.1906502 },
    { lat: 48.1706787, lng: 19.1902841 },
    { lat: 48.1708198, lng: 19.1902891 },
    { lat: 48.1709749, lng: 19.1900735 },
    { lat: 48.1711132, lng: 19.1903609 },
    { lat: 48.1712807, lng: 19.1903999 },
    { lat: 48.1715931, lng: 19.1907907 },
    { lat: 48.1719436, lng: 19.191358 },
    { lat: 48.1727269, lng: 19.1919034 },
    { lat: 48.1735476, lng: 19.1921585 },
    { lat: 48.1739567, lng: 19.1921106 },
    { lat: 48.1740344, lng: 19.1925396 },
    { lat: 48.1741535, lng: 19.1925784 },
    { lat: 48.1744805, lng: 19.1923982 },
    { lat: 48.1746616, lng: 19.1921868 },
    { lat: 48.1749421, lng: 19.1921541 },
    { lat: 48.1751778, lng: 19.1919098 },
    { lat: 48.1752261, lng: 19.1916968 },
    { lat: 48.1754113, lng: 19.1915432 },
    { lat: 48.1759843, lng: 19.1913935 },
    { lat: 48.1762334, lng: 19.1908655 },
    { lat: 48.1762395, lng: 19.1906243 },
    { lat: 48.1763742, lng: 19.1903389 },
    { lat: 48.1764669, lng: 19.1902545 },
    { lat: 48.1770857, lng: 19.1900227 },
    { lat: 48.1774229, lng: 19.1898198 },
    { lat: 48.1777319, lng: 19.1894145 },
    { lat: 48.1780866, lng: 19.1892432 },
    { lat: 48.1782726, lng: 19.1889023 },
    { lat: 48.178581, lng: 19.1886878 },
    { lat: 48.1789901, lng: 19.1886154 },
    { lat: 48.1792632, lng: 19.1884401 },
    { lat: 48.1793144, lng: 19.1884966 },
    { lat: 48.1797127, lng: 19.1881799 },
    { lat: 48.1804136, lng: 19.1871488 },
    { lat: 48.1805097, lng: 19.1871577 },
    { lat: 48.1811846, lng: 19.1861188 },
    { lat: 48.1825638, lng: 19.1843052 },
    { lat: 48.1832542, lng: 19.1838167 },
    { lat: 48.1830801, lng: 19.1823438 },
    { lat: 48.1827785, lng: 19.1796898 },
    { lat: 48.1831287, lng: 19.1769042 },
    { lat: 48.1818675, lng: 19.1765655 },
    { lat: 48.1803324, lng: 19.1729503 },
    { lat: 48.1803452, lng: 19.1726607 },
    { lat: 48.1802451, lng: 19.1724195 },
    { lat: 48.1801263, lng: 19.1724111 },
    { lat: 48.1800942, lng: 19.1723543 },
    { lat: 48.180148, lng: 19.1721848 },
    { lat: 48.1801071, lng: 19.1719756 },
    { lat: 48.1799587, lng: 19.1719168 },
    { lat: 48.1797434, lng: 19.1716422 },
    { lat: 48.1791546, lng: 19.1714785 },
    { lat: 48.1789799, lng: 19.1713622 },
    { lat: 48.1787354, lng: 19.1710457 },
    { lat: 48.1787248, lng: 19.1708568 },
    { lat: 48.178685, lng: 19.1708019 },
    { lat: 48.1784862, lng: 19.1707522 },
    { lat: 48.1784287, lng: 19.1703209 },
    { lat: 48.1785488, lng: 19.17026 },
    { lat: 48.1785645, lng: 19.1700774 },
    { lat: 48.1781026, lng: 19.1696072 },
    { lat: 48.1780225, lng: 19.1693303 },
    { lat: 48.1779683, lng: 19.1692862 },
    { lat: 48.1778827, lng: 19.1694695 },
    { lat: 48.1778139, lng: 19.1693312 },
    { lat: 48.1778192, lng: 19.1687796 },
    { lat: 48.1776906, lng: 19.1681161 },
    { lat: 48.1778988, lng: 19.1680185 },
    { lat: 48.1778642, lng: 19.1678196 },
    { lat: 48.1779073, lng: 19.1675788 },
    { lat: 48.1777975, lng: 19.1672703 },
    { lat: 48.1778326, lng: 19.1670499 },
    { lat: 48.1778073, lng: 19.1668107 },
    { lat: 48.177882, lng: 19.1666343 },
    { lat: 48.1778747, lng: 19.1665557 },
    { lat: 48.1777147, lng: 19.1664445 },
    { lat: 48.1776869, lng: 19.1662932 },
    { lat: 48.1775035, lng: 19.1662288 },
  ],
  Kosihovce: [
    { lat: 48.1544119, lng: 19.2287089 },
    { lat: 48.1545719, lng: 19.2285709 },
    { lat: 48.1549669, lng: 19.2279377 },
    { lat: 48.1554904, lng: 19.2276008 },
    { lat: 48.1562847, lng: 19.2286286 },
    { lat: 48.1563838, lng: 19.2282813 },
    { lat: 48.157246, lng: 19.2272453 },
    { lat: 48.1574324, lng: 19.2275503 },
    { lat: 48.1588487, lng: 19.2290558 },
    { lat: 48.1595562, lng: 19.2295969 },
    { lat: 48.159569, lng: 19.229742 },
    { lat: 48.1600092, lng: 19.2296623 },
    { lat: 48.1607614, lng: 19.2293698 },
    { lat: 48.1628475, lng: 19.227754 },
    { lat: 48.1636238, lng: 19.228296 },
    { lat: 48.1643664, lng: 19.2285878 },
    { lat: 48.1646698, lng: 19.2285468 },
    { lat: 48.1648929, lng: 19.2284281 },
    { lat: 48.1654573, lng: 19.2283535 },
    { lat: 48.1670578, lng: 19.2294976 },
    { lat: 48.1689943, lng: 19.2305344 },
    { lat: 48.1698367, lng: 19.2314831 },
    { lat: 48.1712695, lng: 19.2318301 },
    { lat: 48.1718957, lng: 19.2311978 },
    { lat: 48.1721774, lng: 19.2310328 },
    { lat: 48.1724445, lng: 19.2314697 },
    { lat: 48.1725117, lng: 19.2315043 },
    { lat: 48.1725831, lng: 19.2309382 },
    { lat: 48.1725007, lng: 19.2302695 },
    { lat: 48.1728687, lng: 19.2293389 },
    { lat: 48.1732607, lng: 19.2291483 },
    { lat: 48.1737876, lng: 19.2286509 },
    { lat: 48.1740455, lng: 19.2285103 },
    { lat: 48.1741295, lng: 19.2285414 },
    { lat: 48.17443, lng: 19.2282573 },
    { lat: 48.174721, lng: 19.2280986 },
    { lat: 48.1748495, lng: 19.2281467 },
    { lat: 48.1749853, lng: 19.2283197 },
    { lat: 48.1760505, lng: 19.2273737 },
    { lat: 48.1761517, lng: 19.2265418 },
    { lat: 48.176368, lng: 19.2266325 },
    { lat: 48.1765735, lng: 19.2264772 },
    { lat: 48.1771683, lng: 19.2257123 },
    { lat: 48.1775166, lng: 19.2253887 },
    { lat: 48.178078, lng: 19.2255507 },
    { lat: 48.1782347, lng: 19.2254357 },
    { lat: 48.1786436, lng: 19.224901 },
    { lat: 48.178896, lng: 19.2245091 },
    { lat: 48.1789545, lng: 19.2242085 },
    { lat: 48.1791894, lng: 19.224058 },
    { lat: 48.1793854, lng: 19.2238441 },
    { lat: 48.1799661, lng: 19.2239421 },
    { lat: 48.1804994, lng: 19.2234604 },
    { lat: 48.1805504, lng: 19.2232609 },
    { lat: 48.1807292, lng: 19.2229782 },
    { lat: 48.1807978, lng: 19.2231571 },
    { lat: 48.1808931, lng: 19.2231205 },
    { lat: 48.1811066, lng: 19.2228315 },
    { lat: 48.1812883, lng: 19.222789 },
    { lat: 48.1816553, lng: 19.2225512 },
    { lat: 48.1817057, lng: 19.2226351 },
    { lat: 48.181821, lng: 19.2226213 },
    { lat: 48.1820797, lng: 19.2224485 },
    { lat: 48.1822919, lng: 19.2224312 },
    { lat: 48.1823996, lng: 19.2226048 },
    { lat: 48.182382, lng: 19.2227512 },
    { lat: 48.1824339, lng: 19.222866 },
    { lat: 48.1825876, lng: 19.2227813 },
    { lat: 48.1829024, lng: 19.2228096 },
    { lat: 48.1833989, lng: 19.2238091 },
    { lat: 48.18352, lng: 19.2237266 },
    { lat: 48.184133, lng: 19.22315 },
    { lat: 48.18443, lng: 19.2227232 },
    { lat: 48.184938, lng: 19.2222785 },
    { lat: 48.1850387, lng: 19.2221018 },
    { lat: 48.1855634, lng: 19.2215871 },
    { lat: 48.1860805, lng: 19.2209574 },
    { lat: 48.1873106, lng: 19.2199551 },
    { lat: 48.188049, lng: 19.2191752 },
    { lat: 48.1889883, lng: 19.2174834 },
    { lat: 48.1892929, lng: 19.21751 },
    { lat: 48.1897359, lng: 19.2172338 },
    { lat: 48.1905512, lng: 19.2164144 },
    { lat: 48.1906456, lng: 19.2161994 },
    { lat: 48.1916085, lng: 19.2155863 },
    { lat: 48.1920905, lng: 19.2152086 },
    { lat: 48.1927387, lng: 19.2149116 },
    { lat: 48.1937266, lng: 19.2140943 },
    { lat: 48.1943815, lng: 19.2133627 },
    { lat: 48.1957835, lng: 19.2133103 },
    { lat: 48.1960294, lng: 19.2131938 },
    { lat: 48.1963277, lng: 19.2132557 },
    { lat: 48.1979664, lng: 19.2129741 },
    { lat: 48.1987206, lng: 19.2127911 },
    { lat: 48.2000615, lng: 19.2122309 },
    { lat: 48.2008528, lng: 19.2127343 },
    { lat: 48.2013973, lng: 19.212778 },
    { lat: 48.201753, lng: 19.2126322 },
    { lat: 48.2023987, lng: 19.2121922 },
    { lat: 48.2031269, lng: 19.2118273 },
    { lat: 48.2041532, lng: 19.2120176 },
    { lat: 48.2041756, lng: 19.2117617 },
    { lat: 48.2044391, lng: 19.2116204 },
    { lat: 48.2052922, lng: 19.2108593 },
    { lat: 48.2054591, lng: 19.2107894 },
    { lat: 48.205846, lng: 19.2104761 },
    { lat: 48.2060626, lng: 19.2104993 },
    { lat: 48.2065203, lng: 19.2100167 },
    { lat: 48.2066933, lng: 19.2100521 },
    { lat: 48.2073189, lng: 19.2098005 },
    { lat: 48.2075882, lng: 19.2095709 },
    { lat: 48.2078776, lng: 19.2094694 },
    { lat: 48.2081479, lng: 19.2092831 },
    { lat: 48.2083289, lng: 19.2093851 },
    { lat: 48.2088301, lng: 19.2092401 },
    { lat: 48.2090498, lng: 19.2092842 },
    { lat: 48.209248, lng: 19.2091408 },
    { lat: 48.2095536, lng: 19.2090753 },
    { lat: 48.2104208, lng: 19.2094047 },
    { lat: 48.2107805, lng: 19.209633 },
    { lat: 48.211146, lng: 19.2097313 },
    { lat: 48.211628, lng: 19.2097241 },
    { lat: 48.2119709, lng: 19.2099801 },
    { lat: 48.2127206, lng: 19.2097495 },
    { lat: 48.2129777, lng: 19.2095675 },
    { lat: 48.2131883, lng: 19.2096821 },
    { lat: 48.2133445, lng: 19.2096925 },
    { lat: 48.2136112, lng: 19.209543 },
    { lat: 48.2138404, lng: 19.2097121 },
    { lat: 48.2140169, lng: 19.2097287 },
    { lat: 48.2141972, lng: 19.2098411 },
    { lat: 48.2145792, lng: 19.2102256 },
    { lat: 48.215038, lng: 19.2104081 },
    { lat: 48.2153791, lng: 19.2103988 },
    { lat: 48.2157811, lng: 19.2105051 },
    { lat: 48.2162025, lng: 19.2108203 },
    { lat: 48.2162763, lng: 19.2108444 },
    { lat: 48.2164601, lng: 19.2109044 },
    { lat: 48.2166721, lng: 19.2110662 },
    { lat: 48.2177879, lng: 19.2116125 },
    { lat: 48.2183672, lng: 19.2121628 },
    { lat: 48.2190175, lng: 19.2125458 },
    { lat: 48.2192147, lng: 19.2128196 },
    { lat: 48.2196724, lng: 19.2129816 },
    { lat: 48.2207381, lng: 19.2141172 },
    { lat: 48.2210425, lng: 19.2147599 },
    { lat: 48.221107, lng: 19.2152901 },
    { lat: 48.2216737, lng: 19.2156318 },
    { lat: 48.2222478, lng: 19.2155475 },
    { lat: 48.2232481, lng: 19.2160122 },
    { lat: 48.2252792, lng: 19.2148167 },
    { lat: 48.2261234, lng: 19.2150649 },
    { lat: 48.2281339, lng: 19.2153985 },
    { lat: 48.2287814, lng: 19.2157748 },
    { lat: 48.229221, lng: 19.2161631 },
    { lat: 48.2311304, lng: 19.21742 },
    { lat: 48.2314407, lng: 19.2171295 },
    { lat: 48.2315508, lng: 19.2167794 },
    { lat: 48.2321121, lng: 19.2157892 },
    { lat: 48.2322073, lng: 19.2157321 },
    { lat: 48.2333577, lng: 19.2159252 },
    { lat: 48.2343951, lng: 19.2151764 },
    { lat: 48.2354824, lng: 19.215027 },
    { lat: 48.2362922, lng: 19.2148082 },
    { lat: 48.2379696, lng: 19.2150826 },
    { lat: 48.2390141, lng: 19.2154422 },
    { lat: 48.2395041, lng: 19.2154526 },
    { lat: 48.239827, lng: 19.2153754 },
    { lat: 48.2404422, lng: 19.2149778 },
    { lat: 48.2408719, lng: 19.2147968 },
    { lat: 48.2412499, lng: 19.2146919 },
    { lat: 48.2416979, lng: 19.2146995 },
    { lat: 48.2419553, lng: 19.2145718 },
    { lat: 48.2427359, lng: 19.2143968 },
    { lat: 48.2431228, lng: 19.2141032 },
    { lat: 48.2445655, lng: 19.2138704 },
    { lat: 48.245224, lng: 19.214221 },
    { lat: 48.245201, lng: 19.2142 },
    { lat: 48.245362, lng: 19.214038 },
    { lat: 48.245356, lng: 19.213827 },
    { lat: 48.245512, lng: 19.21374 },
    { lat: 48.245683, lng: 19.213955 },
    { lat: 48.245763, lng: 19.213896 },
    { lat: 48.245758, lng: 19.213757 },
    { lat: 48.2458, lng: 19.213679 },
    { lat: 48.245906, lng: 19.213701 },
    { lat: 48.246018, lng: 19.213648 },
    { lat: 48.24608, lng: 19.213769 },
    { lat: 48.24623, lng: 19.213585 },
    { lat: 48.246351, lng: 19.21361 },
    { lat: 48.246561, lng: 19.213431 },
    { lat: 48.246608, lng: 19.213208 },
    { lat: 48.24665, lng: 19.213189 },
    { lat: 48.246703, lng: 19.21307 },
    { lat: 48.24675, lng: 19.2131 },
    { lat: 48.246766, lng: 19.213039 },
    { lat: 48.246835, lng: 19.213069 },
    { lat: 48.24691, lng: 19.21304 },
    { lat: 48.246964, lng: 19.212699 },
    { lat: 48.247124, lng: 19.212661 },
    { lat: 48.247163, lng: 19.21253 },
    { lat: 48.247232, lng: 19.212427 },
    { lat: 48.247267, lng: 19.211992 },
    { lat: 48.247313, lng: 19.21193 },
    { lat: 48.247398, lng: 19.21193 },
    { lat: 48.247519, lng: 19.211679 },
    { lat: 48.247608, lng: 19.211664 },
    { lat: 48.247697, lng: 19.211635 },
    { lat: 48.247805, lng: 19.211355 },
    { lat: 48.247978, lng: 19.211296 },
    { lat: 48.248086, lng: 19.211017 },
    { lat: 48.248059, lng: 19.210849 },
    { lat: 48.248023, lng: 19.210814 },
    { lat: 48.248041, lng: 19.210746 },
    { lat: 48.248153, lng: 19.210739 },
    { lat: 48.248263, lng: 19.210622 },
    { lat: 48.248265, lng: 19.210419 },
    { lat: 48.248232, lng: 19.210348 },
    { lat: 48.248204, lng: 19.21012 },
    { lat: 48.248247, lng: 19.210062 },
    { lat: 48.248239, lng: 19.209774 },
    { lat: 48.248271, lng: 19.209428 },
    { lat: 48.248074, lng: 19.20906 },
    { lat: 48.248096, lng: 19.208945 },
    { lat: 48.248121, lng: 19.208851 },
    { lat: 48.248142, lng: 19.20873 },
    { lat: 48.248086, lng: 19.208652 },
    { lat: 48.248049, lng: 19.208548 },
    { lat: 48.247876, lng: 19.208309 },
    { lat: 48.247821, lng: 19.208228 },
    { lat: 48.247803, lng: 19.208226 },
    { lat: 48.247773, lng: 19.208069 },
    { lat: 48.247709, lng: 19.208021 },
    { lat: 48.247646, lng: 19.208022 },
    { lat: 48.247592, lng: 19.207847 },
    { lat: 48.247492, lng: 19.207723 },
    { lat: 48.24722, lng: 19.207897 },
    { lat: 48.24717, lng: 19.207782 },
    { lat: 48.247223, lng: 19.207549 },
    { lat: 48.247249, lng: 19.207455 },
    { lat: 48.24704, lng: 19.207372 },
    { lat: 48.246846, lng: 19.207482 },
    { lat: 48.246797, lng: 19.2076 },
    { lat: 48.246738, lng: 19.207603 },
    { lat: 48.246675, lng: 19.207546 },
    { lat: 48.246589, lng: 19.207285 },
    { lat: 48.246349, lng: 19.2072 },
    { lat: 48.24625, lng: 19.207089 },
    { lat: 48.246258, lng: 19.206888 },
    { lat: 48.246246, lng: 19.206792 },
    { lat: 48.246128, lng: 19.206681 },
    { lat: 48.246057, lng: 19.20651 },
    { lat: 48.246047, lng: 19.206399 },
    { lat: 48.246077, lng: 19.206198 },
    { lat: 48.246006, lng: 19.205929 },
    { lat: 48.245898, lng: 19.205711 },
    { lat: 48.24585, lng: 19.20566 },
    { lat: 48.245887, lng: 19.205559 },
    { lat: 48.245851, lng: 19.205345 },
    { lat: 48.245687, lng: 19.205163 },
    { lat: 48.245592, lng: 19.205216 },
    { lat: 48.245422, lng: 19.20525 },
    { lat: 48.245356, lng: 19.205109 },
    { lat: 48.245132, lng: 19.204774 },
    { lat: 48.245069, lng: 19.204592 },
    { lat: 48.245119, lng: 19.204078 },
    { lat: 48.244805, lng: 19.203613 },
    { lat: 48.24492, lng: 19.20344 },
    { lat: 48.245025, lng: 19.203426 },
    { lat: 48.245137, lng: 19.203467 },
    { lat: 48.245175, lng: 19.20334 },
    { lat: 48.245163, lng: 19.203147 },
    { lat: 48.245034, lng: 19.202802 },
    { lat: 48.244993, lng: 19.202594 },
    { lat: 48.244864, lng: 19.202288 },
    { lat: 48.244771, lng: 19.202222 },
    { lat: 48.244717, lng: 19.201924 },
    { lat: 48.24477, lng: 19.201395 },
    { lat: 48.244869, lng: 19.201232 },
    { lat: 48.244912, lng: 19.201026 },
    { lat: 48.244897, lng: 19.200862 },
    { lat: 48.244818, lng: 19.200671 },
    { lat: 48.24473, lng: 19.200582 },
    { lat: 48.244682, lng: 19.200227 },
    { lat: 48.244699, lng: 19.200151 },
    { lat: 48.244749, lng: 19.200103 },
    { lat: 48.244799, lng: 19.199995 },
    { lat: 48.244803, lng: 19.199779 },
    { lat: 48.244631, lng: 19.199614 },
    { lat: 48.244548, lng: 19.199495 },
    { lat: 48.244482, lng: 19.19934 },
    { lat: 48.244507, lng: 19.199103 },
    { lat: 48.244511, lng: 19.198939 },
    { lat: 48.244472, lng: 19.198887 },
    { lat: 48.244422, lng: 19.198617 },
    { lat: 48.244448, lng: 19.198533 },
    { lat: 48.244422, lng: 19.198383 },
    { lat: 48.244316, lng: 19.198309 },
    { lat: 48.244294, lng: 19.198186 },
    { lat: 48.244303, lng: 19.198143 },
    { lat: 48.244382, lng: 19.197991 },
    { lat: 48.244422, lng: 19.197851 },
    { lat: 48.244418, lng: 19.197711 },
    { lat: 48.244389, lng: 19.19757 },
    { lat: 48.244302, lng: 19.19748 },
    { lat: 48.24428, lng: 19.197471 },
    { lat: 48.244223, lng: 19.197392 },
    { lat: 48.244055, lng: 19.197361 },
    { lat: 48.243945, lng: 19.197118 },
    { lat: 48.243858, lng: 19.196766 },
    { lat: 48.243707, lng: 19.196396 },
    { lat: 48.243618, lng: 19.196288 },
    { lat: 48.243519, lng: 19.19598 },
    { lat: 48.243437, lng: 19.195816 },
    { lat: 48.243271, lng: 19.195557 },
    { lat: 48.243199, lng: 19.195424 },
    { lat: 48.243068, lng: 19.195325 },
    { lat: 48.243004, lng: 19.195184 },
    { lat: 48.242924, lng: 19.195118 },
    { lat: 48.242547, lng: 19.19473 },
    { lat: 48.242473, lng: 19.19455 },
    { lat: 48.242258, lng: 19.194593 },
    { lat: 48.24209, lng: 19.194492 },
    { lat: 48.241839, lng: 19.194368 },
    { lat: 48.241632, lng: 19.194395 },
    { lat: 48.241453, lng: 19.194294 },
    { lat: 48.241197, lng: 19.194043 },
    { lat: 48.241044, lng: 19.193758 },
    { lat: 48.240986, lng: 19.19349 },
    { lat: 48.240869, lng: 19.193441 },
    { lat: 48.240791, lng: 19.19351 },
    { lat: 48.240725, lng: 19.193934 },
    { lat: 48.240558, lng: 19.193985 },
    { lat: 48.240497, lng: 19.193844 },
    { lat: 48.240341, lng: 19.193722 },
    { lat: 48.240279, lng: 19.193607 },
    { lat: 48.240146, lng: 19.193555 },
    { lat: 48.239975, lng: 19.1935016 },
    { lat: 48.239912, lng: 19.19344 },
    { lat: 48.239866, lng: 19.193313 },
    { lat: 48.239918, lng: 19.193085 },
    { lat: 48.240031, lng: 19.192937 },
    { lat: 48.240049, lng: 19.192711 },
    { lat: 48.240042, lng: 19.192497 },
    { lat: 48.240018, lng: 19.192244 },
    { lat: 48.239873, lng: 19.192086 },
    { lat: 48.239767, lng: 19.191692 },
    { lat: 48.239762, lng: 19.191442 },
    { lat: 48.239701, lng: 19.191263 },
    { lat: 48.239577, lng: 19.191081 },
    { lat: 48.239389, lng: 19.191055 },
    { lat: 48.239134, lng: 19.191341 },
    { lat: 48.239001, lng: 19.191348 },
    { lat: 48.238696, lng: 19.191268 },
    { lat: 48.238598, lng: 19.191003 },
    { lat: 48.238482, lng: 19.190687 },
    { lat: 48.238415, lng: 19.190393 },
    { lat: 48.238304, lng: 19.190393 },
    { lat: 48.238137, lng: 19.190488 },
    { lat: 48.237895, lng: 19.190666 },
    { lat: 48.237686, lng: 19.190649 },
    { lat: 48.237607, lng: 19.190549 },
    { lat: 48.237443, lng: 19.190619 },
    { lat: 48.237079, lng: 19.190622 },
    { lat: 48.23696, lng: 19.190541 },
    { lat: 48.236868, lng: 19.190353 },
    { lat: 48.236787, lng: 19.190295 },
    { lat: 48.236639, lng: 19.190058 },
    { lat: 48.236639, lng: 19.189849 },
    { lat: 48.236545, lng: 19.189772 },
    { lat: 48.236247, lng: 19.189724 },
    { lat: 48.236149, lng: 19.18977 },
    { lat: 48.236087, lng: 19.19002 },
    { lat: 48.235985, lng: 19.190074 },
    { lat: 48.235792, lng: 19.189875 },
    { lat: 48.235402, lng: 19.189479 },
    { lat: 48.235106, lng: 19.189083 },
    { lat: 48.234972, lng: 19.188873 },
    { lat: 48.234838, lng: 19.188609 },
    { lat: 48.234772, lng: 19.188545 },
    { lat: 48.234654, lng: 19.188487 },
    { lat: 48.23454, lng: 19.188573 },
    { lat: 48.234393, lng: 19.189017 },
    { lat: 48.23433, lng: 19.189055 },
    { lat: 48.234107, lng: 19.188959 },
    { lat: 48.233833, lng: 19.18879 },
    { lat: 48.233507, lng: 19.18842 },
    { lat: 48.233418, lng: 19.18825 },
    { lat: 48.233112, lng: 19.188262 },
    { lat: 48.232772, lng: 19.188242 },
    { lat: 48.232594, lng: 19.188248 },
    { lat: 48.232483, lng: 19.188212 },
    { lat: 48.232286, lng: 19.188241 },
    { lat: 48.232178, lng: 19.188203 },
    { lat: 48.23177, lng: 19.187963 },
    { lat: 48.231595, lng: 19.187748 },
    { lat: 48.231365, lng: 19.187306 },
    { lat: 48.231405, lng: 19.187242 },
    { lat: 48.231375, lng: 19.187012 },
    { lat: 48.231386, lng: 19.186866 },
    { lat: 48.231271, lng: 19.186475 },
    { lat: 48.231326, lng: 19.186259 },
    { lat: 48.23137, lng: 19.186224 },
    { lat: 48.231397, lng: 19.186287 },
    { lat: 48.231501, lng: 19.186262 },
    { lat: 48.23159, lng: 19.186095 },
    { lat: 48.231667, lng: 19.185863 },
    { lat: 48.231754, lng: 19.185663 },
    { lat: 48.2311646, lng: 19.1855743 },
    { lat: 48.2308041, lng: 19.1857849 },
    { lat: 48.2306352, lng: 19.185741 },
    { lat: 48.2305199, lng: 19.1855073 },
    { lat: 48.2301792, lng: 19.1852997 },
    { lat: 48.2299864, lng: 19.1850922 },
    { lat: 48.2298376, lng: 19.1850694 },
    { lat: 48.2296139, lng: 19.1851949 },
    { lat: 48.2293131, lng: 19.185201 },
    { lat: 48.2291834, lng: 19.1853589 },
    { lat: 48.2289073, lng: 19.1852939 },
    { lat: 48.2287504, lng: 19.185493 },
    { lat: 48.2284235, lng: 19.1857236 },
    { lat: 48.2282803, lng: 19.1855839 },
    { lat: 48.2278682, lng: 19.1858013 },
    { lat: 48.2277132, lng: 19.1857435 },
    { lat: 48.2275302, lng: 19.1858935 },
    { lat: 48.2273951, lng: 19.1858515 },
    { lat: 48.2272784, lng: 19.1859312 },
    { lat: 48.2268548, lng: 19.185936 },
    { lat: 48.2267683, lng: 19.1860361 },
    { lat: 48.2265056, lng: 19.1861026 },
    { lat: 48.2262623, lng: 19.1862886 },
    { lat: 48.2261577, lng: 19.1862266 },
    { lat: 48.2260198, lng: 19.1864243 },
    { lat: 48.2259792, lng: 19.1864256 },
    { lat: 48.2258644, lng: 19.1862 },
    { lat: 48.2257939, lng: 19.1863892 },
    { lat: 48.2256058, lng: 19.1863926 },
    { lat: 48.2253672, lng: 19.1865848 },
    { lat: 48.2252991, lng: 19.1864633 },
    { lat: 48.2250959, lng: 19.1865484 },
    { lat: 48.2250558, lng: 19.1866691 },
    { lat: 48.224941, lng: 19.1867458 },
    { lat: 48.2247247, lng: 19.1867237 },
    { lat: 48.2246006, lng: 19.1868608 },
    { lat: 48.2241678, lng: 19.1868168 },
    { lat: 48.2239851, lng: 19.1870208 },
    { lat: 48.2239002, lng: 19.1870327 },
    { lat: 48.2238317, lng: 19.1869236 },
    { lat: 48.2236107, lng: 19.1868596 },
    { lat: 48.2235539, lng: 19.1869377 },
    { lat: 48.2234003, lng: 19.1869564 },
    { lat: 48.2230929, lng: 19.1872852 },
    { lat: 48.2228142, lng: 19.1873245 },
    { lat: 48.2227862, lng: 19.1871675 },
    { lat: 48.2225919, lng: 19.1871133 },
    { lat: 48.2225301, lng: 19.1874599 },
    { lat: 48.2224562, lng: 19.1875607 },
    { lat: 48.2221239, lng: 19.1876812 },
    { lat: 48.2219617, lng: 19.1875697 },
    { lat: 48.2217501, lng: 19.1876659 },
    { lat: 48.2217542, lng: 19.1877485 },
    { lat: 48.221537, lng: 19.1877563 },
    { lat: 48.2214763, lng: 19.1878277 },
    { lat: 48.2213164, lng: 19.1877849 },
    { lat: 48.2211253, lng: 19.1880489 },
    { lat: 48.2209936, lng: 19.1880999 },
    { lat: 48.2209454, lng: 19.1880599 },
    { lat: 48.2206566, lng: 19.1883068 },
    { lat: 48.2205165, lng: 19.1881194 },
    { lat: 48.220452, lng: 19.1881343 },
    { lat: 48.220414, lng: 19.1882357 },
    { lat: 48.2201704, lng: 19.1883255 },
    { lat: 48.2199768, lng: 19.1881458 },
    { lat: 48.2198201, lng: 19.1881673 },
    { lat: 48.219536, lng: 19.1883744 },
    { lat: 48.2190918, lng: 19.1880994 },
    { lat: 48.2186827, lng: 19.1886034 },
    { lat: 48.2184167, lng: 19.1886512 },
    { lat: 48.2181718, lng: 19.1888967 },
    { lat: 48.2178851, lng: 19.1890698 },
    { lat: 48.2174117, lng: 19.1896215 },
    { lat: 48.2172876, lng: 19.1896183 },
    { lat: 48.2169327, lng: 19.1899194 },
    { lat: 48.2157264, lng: 19.1903998 },
    { lat: 48.2149793, lng: 19.1905162 },
    { lat: 48.2146455, lng: 19.1904912 },
    { lat: 48.2142874, lng: 19.1906361 },
    { lat: 48.213763, lng: 19.1895584 },
    { lat: 48.2134206, lng: 19.189222 },
    { lat: 48.2117292, lng: 19.1890529 },
    { lat: 48.2100808, lng: 19.189345 },
    { lat: 48.2091956, lng: 19.1894293 },
    { lat: 48.2089723, lng: 19.1895197 },
    { lat: 48.2087793, lng: 19.1897199 },
    { lat: 48.2084061, lng: 19.1899272 },
    { lat: 48.2069422, lng: 19.1902564 },
    { lat: 48.2066642, lng: 19.1905158 },
    { lat: 48.2058073, lng: 19.1909687 },
    { lat: 48.2054381, lng: 19.1913463 },
    { lat: 48.2053945, lng: 19.1915108 },
    { lat: 48.2050834, lng: 19.191765 },
    { lat: 48.2047075, lng: 19.191821 },
    { lat: 48.2040538, lng: 19.1922119 },
    { lat: 48.2036838, lng: 19.1923443 },
    { lat: 48.2036726, lng: 19.1924812 },
    { lat: 48.2035364, lng: 19.1926221 },
    { lat: 48.2031593, lng: 19.1928655 },
    { lat: 48.2031541, lng: 19.1927834 },
    { lat: 48.2029996, lng: 19.1928273 },
    { lat: 48.20276, lng: 19.1926774 },
    { lat: 48.20217, lng: 19.1930086 },
    { lat: 48.2019318, lng: 19.1929363 },
    { lat: 48.2011802, lng: 19.1931709 },
    { lat: 48.2006153, lng: 19.1929396 },
    { lat: 48.2001134, lng: 19.1925918 },
    { lat: 48.1990033, lng: 19.1925044 },
    { lat: 48.1989405, lng: 19.192422 },
    { lat: 48.198399, lng: 19.1924789 },
    { lat: 48.198176, lng: 19.1924173 },
    { lat: 48.1979549, lng: 19.1923022 },
    { lat: 48.1963822, lng: 19.1908579 },
    { lat: 48.1959978, lng: 19.1907883 },
    { lat: 48.1957185, lng: 19.1904517 },
    { lat: 48.1945856, lng: 19.1904062 },
    { lat: 48.194191, lng: 19.1902696 },
    { lat: 48.1938732, lng: 19.1900418 },
    { lat: 48.1935477, lng: 19.1900473 },
    { lat: 48.193268, lng: 19.1898843 },
    { lat: 48.1927193, lng: 19.1906235 },
    { lat: 48.1922342, lng: 19.1915964 },
    { lat: 48.190868, lng: 19.1897713 },
    { lat: 48.1901901, lng: 19.1902888 },
    { lat: 48.1900416, lng: 19.190343 },
    { lat: 48.1897443, lng: 19.1897989 },
    { lat: 48.189492, lng: 19.1895325 },
    { lat: 48.1895558, lng: 19.1894081 },
    { lat: 48.18941, lng: 19.1892822 },
    { lat: 48.1893077, lng: 19.1890611 },
    { lat: 48.1891038, lng: 19.1891097 },
    { lat: 48.1889742, lng: 19.1888806 },
    { lat: 48.1888564, lng: 19.1890314 },
    { lat: 48.1887057, lng: 19.1889248 },
    { lat: 48.1886971, lng: 19.1888187 },
    { lat: 48.1884852, lng: 19.1888431 },
    { lat: 48.1883807, lng: 19.1885533 },
    { lat: 48.1881307, lng: 19.1883276 },
    { lat: 48.187731, lng: 19.1883174 },
    { lat: 48.1875749, lng: 19.1881898 },
    { lat: 48.1872532, lng: 19.1877502 },
    { lat: 48.187177, lng: 19.1877367 },
    { lat: 48.1871319, lng: 19.1876226 },
    { lat: 48.1871565, lng: 19.187432 },
    { lat: 48.1870362, lng: 19.1872821 },
    { lat: 48.1870046, lng: 19.1870075 },
    { lat: 48.1867526, lng: 19.1867637 },
    { lat: 48.1867258, lng: 19.1865897 },
    { lat: 48.1864273, lng: 19.1865238 },
    { lat: 48.1863989, lng: 19.1863174 },
    { lat: 48.1861793, lng: 19.1862057 },
    { lat: 48.186117, lng: 19.1860868 },
    { lat: 48.1860394, lng: 19.1856545 },
    { lat: 48.1861223, lng: 19.1856008 },
    { lat: 48.1860501, lng: 19.1854834 },
    { lat: 48.1857683, lng: 19.1855735 },
    { lat: 48.1855556, lng: 19.1857214 },
    { lat: 48.1853704, lng: 19.1856673 },
    { lat: 48.1852794, lng: 19.1857201 },
    { lat: 48.1848536, lng: 19.185587 },
    { lat: 48.1847136, lng: 19.1856092 },
    { lat: 48.1842533, lng: 19.1853478 },
    { lat: 48.1841168, lng: 19.1853915 },
    { lat: 48.1832542, lng: 19.1838167 },
    { lat: 48.1825638, lng: 19.1843052 },
    { lat: 48.1811846, lng: 19.1861188 },
    { lat: 48.1805097, lng: 19.1871577 },
    { lat: 48.1804136, lng: 19.1871488 },
    { lat: 48.1797127, lng: 19.1881799 },
    { lat: 48.1793144, lng: 19.1884966 },
    { lat: 48.1792632, lng: 19.1884401 },
    { lat: 48.1789901, lng: 19.1886154 },
    { lat: 48.178581, lng: 19.1886878 },
    { lat: 48.1782726, lng: 19.1889023 },
    { lat: 48.1780866, lng: 19.1892432 },
    { lat: 48.1777319, lng: 19.1894145 },
    { lat: 48.1774229, lng: 19.1898198 },
    { lat: 48.1770857, lng: 19.1900227 },
    { lat: 48.1764669, lng: 19.1902545 },
    { lat: 48.1763742, lng: 19.1903389 },
    { lat: 48.1762395, lng: 19.1906243 },
    { lat: 48.1762334, lng: 19.1908655 },
    { lat: 48.1759843, lng: 19.1913935 },
    { lat: 48.1754113, lng: 19.1915432 },
    { lat: 48.1752261, lng: 19.1916968 },
    { lat: 48.1751778, lng: 19.1919098 },
    { lat: 48.1749421, lng: 19.1921541 },
    { lat: 48.1746616, lng: 19.1921868 },
    { lat: 48.1744805, lng: 19.1923982 },
    { lat: 48.1741535, lng: 19.1925784 },
    { lat: 48.1740344, lng: 19.1925396 },
    { lat: 48.1739567, lng: 19.1921106 },
    { lat: 48.1735476, lng: 19.1921585 },
    { lat: 48.1727269, lng: 19.1919034 },
    { lat: 48.1719436, lng: 19.191358 },
    { lat: 48.1715931, lng: 19.1907907 },
    { lat: 48.1712807, lng: 19.1903999 },
    { lat: 48.1711132, lng: 19.1903609 },
    { lat: 48.1709749, lng: 19.1900735 },
    { lat: 48.1708198, lng: 19.1902891 },
    { lat: 48.1706787, lng: 19.1902841 },
    { lat: 48.1703822, lng: 19.1906502 },
    { lat: 48.1701662, lng: 19.1907966 },
    { lat: 48.1697616, lng: 19.1913251 },
    { lat: 48.1691282, lng: 19.1916429 },
    { lat: 48.1687588, lng: 19.1921992 },
    { lat: 48.168761, lng: 19.1922925 },
    { lat: 48.1686163, lng: 19.1923512 },
    { lat: 48.1683538, lng: 19.1927429 },
    { lat: 48.1681398, lng: 19.1927011 },
    { lat: 48.1679998, lng: 19.192903 },
    { lat: 48.1675762, lng: 19.1931359 },
    { lat: 48.1671204, lng: 19.193516 },
    { lat: 48.1670617, lng: 19.1934712 },
    { lat: 48.1665284, lng: 19.1936501 },
    { lat: 48.1663781, lng: 19.1937341 },
    { lat: 48.1661562, lng: 19.1940111 },
    { lat: 48.1659948, lng: 19.1940289 },
    { lat: 48.1658938, lng: 19.1941558 },
    { lat: 48.1657484, lng: 19.1941761 },
    { lat: 48.1655503, lng: 19.1943887 },
    { lat: 48.1653057, lng: 19.1947977 },
    { lat: 48.1653452, lng: 19.1951203 },
    { lat: 48.1652655, lng: 19.1954017 },
    { lat: 48.1651486, lng: 19.195594 },
    { lat: 48.1650677, lng: 19.1961463 },
    { lat: 48.1650188, lng: 19.1961605 },
    { lat: 48.1649715, lng: 19.1968247 },
    { lat: 48.1648404, lng: 19.1974337 },
    { lat: 48.1646513, lng: 19.1976894 },
    { lat: 48.1639239, lng: 19.197253 },
    { lat: 48.1632122, lng: 19.1973346 },
    { lat: 48.1632561, lng: 19.1985447 },
    { lat: 48.1629433, lng: 19.1999965 },
    { lat: 48.1628806, lng: 19.2007121 },
    { lat: 48.162928, lng: 19.2013523 },
    { lat: 48.1627815, lng: 19.2024535 },
    { lat: 48.1625423, lng: 19.2030225 },
    { lat: 48.1623835, lng: 19.2032755 },
    { lat: 48.1616853, lng: 19.20434 },
    { lat: 48.160007, lng: 19.2037762 },
    { lat: 48.1597902, lng: 19.2039727 },
    { lat: 48.1593479, lng: 19.2038039 },
    { lat: 48.1589194, lng: 19.2045568 },
    { lat: 48.1586483, lng: 19.2047738 },
    { lat: 48.1586833, lng: 19.2050531 },
    { lat: 48.1581461, lng: 19.2058861 },
    { lat: 48.1578132, lng: 19.2056258 },
    { lat: 48.1574364, lng: 19.2060768 },
    { lat: 48.1573856, lng: 19.2060083 },
    { lat: 48.1570293, lng: 19.2065107 },
    { lat: 48.1564988, lng: 19.2077772 },
    { lat: 48.155554, lng: 19.2080731 },
    { lat: 48.1554859, lng: 19.2097346 },
    { lat: 48.1549717, lng: 19.2100538 },
    { lat: 48.1546623, lng: 19.2100871 },
    { lat: 48.1544952, lng: 19.2102202 },
    { lat: 48.1543901, lng: 19.2104898 },
    { lat: 48.1544092, lng: 19.2107613 },
    { lat: 48.1541958, lng: 19.2111435 },
    { lat: 48.154154, lng: 19.2115096 },
    { lat: 48.1537618, lng: 19.2127459 },
    { lat: 48.1534295, lng: 19.2134147 },
    { lat: 48.1533434, lng: 19.2137048 },
    { lat: 48.1532778, lng: 19.213976 },
    { lat: 48.1532058, lng: 19.2146914 },
    { lat: 48.1527842, lng: 19.2154706 },
    { lat: 48.1527167, lng: 19.215771 },
    { lat: 48.1527172, lng: 19.2160113 },
    { lat: 48.1525356, lng: 19.2162134 },
    { lat: 48.1516042, lng: 19.2185443 },
    { lat: 48.1508795, lng: 19.2186891 },
    { lat: 48.1503081, lng: 19.2183 },
    { lat: 48.150028, lng: 19.2182665 },
    { lat: 48.1496272, lng: 19.218595 },
    { lat: 48.1498394, lng: 19.2191972 },
    { lat: 48.1500192, lng: 19.2196689 },
    { lat: 48.1511965, lng: 19.221789 },
    { lat: 48.1514372, lng: 19.2242336 },
    { lat: 48.1513812, lng: 19.2244494 },
    { lat: 48.1527797, lng: 19.2260251 },
    { lat: 48.1538616, lng: 19.2279028 },
    { lat: 48.1543167, lng: 19.2282861 },
    { lat: 48.1544119, lng: 19.2287089 },
  ],
  Sečianky: [
    { lat: 48.0984262, lng: 19.1028946 },
    { lat: 48.0984668, lng: 19.1028474 },
    { lat: 48.0985116, lng: 19.1029774 },
    { lat: 48.0985543, lng: 19.1029282 },
    { lat: 48.0985995, lng: 19.1030424 },
    { lat: 48.0988937, lng: 19.1027714 },
    { lat: 48.0991153, lng: 19.101977 },
    { lat: 48.0994724, lng: 19.1017254 },
    { lat: 48.0996382, lng: 19.101546 },
    { lat: 48.0996318, lng: 19.1014413 },
    { lat: 48.0997082, lng: 19.1014585 },
    { lat: 48.0997797, lng: 19.1013817 },
    { lat: 48.0998446, lng: 19.1011685 },
    { lat: 48.0999325, lng: 19.101099 },
    { lat: 48.0998485, lng: 19.1008884 },
    { lat: 48.0998938, lng: 19.100756 },
    { lat: 48.0998795, lng: 19.1006271 },
    { lat: 48.1000057, lng: 19.1005607 },
    { lat: 48.100097, lng: 19.1006955 },
    { lat: 48.1002797, lng: 19.1005145 },
    { lat: 48.1003366, lng: 19.0998726 },
    { lat: 48.1004121, lng: 19.0998053 },
    { lat: 48.1009928, lng: 19.0997402 },
    { lat: 48.1011422, lng: 19.0996434 },
    { lat: 48.1012951, lng: 19.0997233 },
    { lat: 48.1013826, lng: 19.0996451 },
    { lat: 48.1014506, lng: 19.0993155 },
    { lat: 48.1015874, lng: 19.0991654 },
    { lat: 48.1016403, lng: 19.0988734 },
    { lat: 48.1018443, lng: 19.0986277 },
    { lat: 48.1022024, lng: 19.0986637 },
    { lat: 48.1024791, lng: 19.099031 },
    { lat: 48.1029287, lng: 19.0983789 },
    { lat: 48.1030624, lng: 19.098524 },
    { lat: 48.1032874, lng: 19.0984294 },
    { lat: 48.1033162, lng: 19.0984173 },
    { lat: 48.1035462, lng: 19.0974942 },
    { lat: 48.1038764, lng: 19.0969098 },
    { lat: 48.104122, lng: 19.0973122 },
    { lat: 48.1044868, lng: 19.0970304 },
    { lat: 48.1047356, lng: 19.0976107 },
    { lat: 48.1049032, lng: 19.0973996 },
    { lat: 48.1051251, lng: 19.0973737 },
    { lat: 48.1051486, lng: 19.0968974 },
    { lat: 48.1052673, lng: 19.0968146 },
    { lat: 48.1056547, lng: 19.0961789 },
    { lat: 48.1056073, lng: 19.0960629 },
    { lat: 48.1058759, lng: 19.0957573 },
    { lat: 48.1058783, lng: 19.0952653 },
    { lat: 48.1060503, lng: 19.0950771 },
    { lat: 48.1060515, lng: 19.0946075 },
    { lat: 48.1060969, lng: 19.0943872 },
    { lat: 48.1062239, lng: 19.0942689 },
    { lat: 48.1064215, lng: 19.0944108 },
    { lat: 48.1066706, lng: 19.0941162 },
    { lat: 48.1067717, lng: 19.0938625 },
    { lat: 48.1067656, lng: 19.0937193 },
    { lat: 48.1070094, lng: 19.093389 },
    { lat: 48.1072264, lng: 19.0936454 },
    { lat: 48.1075124, lng: 19.0931703 },
    { lat: 48.1081084, lng: 19.0925571 },
    { lat: 48.1081618, lng: 19.0926756 },
    { lat: 48.1086119, lng: 19.092527 },
    { lat: 48.1087271, lng: 19.0923744 },
    { lat: 48.1086699, lng: 19.0920637 },
    { lat: 48.1087371, lng: 19.0917182 },
    { lat: 48.1086837, lng: 19.0912156 },
    { lat: 48.1084562, lng: 19.0906389 },
    { lat: 48.1083275, lng: 19.0908834 },
    { lat: 48.1080949, lng: 19.0911447 },
    { lat: 48.1076742, lng: 19.0902663 },
    { lat: 48.1074738, lng: 19.0893325 },
    { lat: 48.1072661, lng: 19.0889043 },
    { lat: 48.1070308, lng: 19.0886845 },
    { lat: 48.1064095, lng: 19.0882802 },
    { lat: 48.1060615, lng: 19.0879288 },
    { lat: 48.1059181, lng: 19.0876986 },
    { lat: 48.1060578, lng: 19.0864237 },
    { lat: 48.1062984, lng: 19.085112 },
    { lat: 48.1064829, lng: 19.0832877 },
    { lat: 48.1064828, lng: 19.0816778 },
    { lat: 48.1063766, lng: 19.0809772 },
    { lat: 48.1068047, lng: 19.0807715 },
    { lat: 48.1077245, lng: 19.0805912 },
    { lat: 48.1073934, lng: 19.0785645 },
    { lat: 48.1077696, lng: 19.0782862 },
    { lat: 48.1079655, lng: 19.07779 },
    { lat: 48.1080177, lng: 19.0775277 },
    { lat: 48.1081137, lng: 19.0756978 },
    { lat: 48.1072578, lng: 19.0752247 },
    { lat: 48.1071866, lng: 19.0731681 },
    { lat: 48.106365, lng: 19.0732172 },
    { lat: 48.106629, lng: 19.0729991 },
    { lat: 48.1068464, lng: 19.072303 },
    { lat: 48.1071563, lng: 19.0716515 },
    { lat: 48.1073786, lng: 19.0703605 },
    { lat: 48.1081082, lng: 19.0690465 },
    { lat: 48.1080997, lng: 19.0686531 },
    { lat: 48.1080357, lng: 19.0682567 },
    { lat: 48.1080769, lng: 19.0678937 },
    { lat: 48.1081586, lng: 19.0676368 },
    { lat: 48.1089375, lng: 19.0660467 },
    { lat: 48.109383, lng: 19.0648468 },
    { lat: 48.1096296, lng: 19.0648194 },
    { lat: 48.109811, lng: 19.0644158 },
    { lat: 48.1098036, lng: 19.0638677 },
    { lat: 48.110002, lng: 19.0634407 },
    { lat: 48.110284, lng: 19.063065 },
    { lat: 48.1108859, lng: 19.0624796 },
    { lat: 48.1110894, lng: 19.0620238 },
    { lat: 48.1111776, lng: 19.0616519 },
    { lat: 48.1115788, lng: 19.0611805 },
    { lat: 48.1118328, lng: 19.0607419 },
    { lat: 48.1119289, lng: 19.060392 },
    { lat: 48.1125124, lng: 19.0596203 },
    { lat: 48.113046, lng: 19.0584929 },
    { lat: 48.1144785, lng: 19.0550731 },
    { lat: 48.1154929, lng: 19.0532665 },
    { lat: 48.1154579, lng: 19.0532186 },
    { lat: 48.1160637, lng: 19.052248 },
    { lat: 48.1164554, lng: 19.0514332 },
    { lat: 48.1169668, lng: 19.0506468 },
    { lat: 48.117162, lng: 19.050492 },
    { lat: 48.117162, lng: 19.05041 },
    { lat: 48.117195, lng: 19.050356 },
    { lat: 48.117083, lng: 19.050205 },
    { lat: 48.116957, lng: 19.050036 },
    { lat: 48.116808, lng: 19.049812 },
    { lat: 48.116708, lng: 19.049683 },
    { lat: 48.116695, lng: 19.04966 },
    { lat: 48.116633, lng: 19.049557 },
    { lat: 48.116578, lng: 19.049443 },
    { lat: 48.1165, lng: 19.049281 },
    { lat: 48.116448, lng: 19.049223 },
    { lat: 48.116378, lng: 19.049055 },
    { lat: 48.116321, lng: 19.048922 },
    { lat: 48.116196, lng: 19.048646 },
    { lat: 48.116097, lng: 19.048366 },
    { lat: 48.116084, lng: 19.048327 },
    { lat: 48.116008, lng: 19.048224 },
    { lat: 48.115994, lng: 19.048205 },
    { lat: 48.116002, lng: 19.048194 },
    { lat: 48.115942, lng: 19.048083 },
    { lat: 48.115879, lng: 19.047997 },
    { lat: 48.115862, lng: 19.047976 },
    { lat: 48.115669, lng: 19.047714 },
    { lat: 48.115552, lng: 19.047596 },
    { lat: 48.115427, lng: 19.04745 },
    { lat: 48.115408, lng: 19.047457 },
    { lat: 48.115284, lng: 19.047506 },
    { lat: 48.115183, lng: 19.047589 },
    { lat: 48.115126, lng: 19.047601 },
    { lat: 48.115078, lng: 19.047639 },
    { lat: 48.115055, lng: 19.047658 },
    { lat: 48.115051, lng: 19.047606 },
    { lat: 48.115044, lng: 19.047531 },
    { lat: 48.115037, lng: 19.047459 },
    { lat: 48.115037, lng: 19.047399 },
    { lat: 48.115035, lng: 19.047377 },
    { lat: 48.115035, lng: 19.047343 },
    { lat: 48.115034, lng: 19.047306 },
    { lat: 48.115011, lng: 19.047146 },
    { lat: 48.115011, lng: 19.047066 },
    { lat: 48.11501, lng: 19.04698 },
    { lat: 48.114981, lng: 19.046788 },
    { lat: 48.114969, lng: 19.046716 },
    { lat: 48.114965, lng: 19.046572 },
    { lat: 48.11495, lng: 19.046529 },
    { lat: 48.11488, lng: 19.046339 },
    { lat: 48.11487, lng: 19.04629 },
    { lat: 48.114861, lng: 19.04625 },
    { lat: 48.114853, lng: 19.046212 },
    { lat: 48.114858, lng: 19.046156 },
    { lat: 48.114864, lng: 19.046046 },
    { lat: 48.11486, lng: 19.045916 },
    { lat: 48.114843, lng: 19.045834 },
    { lat: 48.114856, lng: 19.045749 },
    { lat: 48.11487, lng: 19.045647 },
    { lat: 48.11489, lng: 19.045503 },
    { lat: 48.114897, lng: 19.045371 },
    { lat: 48.114877, lng: 19.045289 },
    { lat: 48.114856, lng: 19.045203 },
    { lat: 48.114832, lng: 19.045105 },
    { lat: 48.114662, lng: 19.045145 },
    { lat: 48.114554, lng: 19.045012 },
    { lat: 48.114546, lng: 19.045003 },
    { lat: 48.114534, lng: 19.044981 },
    { lat: 48.114524, lng: 19.044952 },
    { lat: 48.114502, lng: 19.044897 },
    { lat: 48.11449, lng: 19.044866 },
    { lat: 48.114449, lng: 19.044801 },
    { lat: 48.114443, lng: 19.04479 },
    { lat: 48.114307, lng: 19.04462 },
    { lat: 48.114284, lng: 19.044589 },
    { lat: 48.114189, lng: 19.044388 },
    { lat: 48.114149, lng: 19.044302 },
    { lat: 48.11413, lng: 19.04426 },
    { lat: 48.11411, lng: 19.044207 },
    { lat: 48.114104, lng: 19.044188 },
    { lat: 48.114059, lng: 19.044064 },
    { lat: 48.114056, lng: 19.044051 },
    { lat: 48.114025, lng: 19.043918 },
    { lat: 48.114013, lng: 19.043822 },
    { lat: 48.114005, lng: 19.043748 },
    { lat: 48.113987, lng: 19.04359 },
    { lat: 48.114035, lng: 19.043341 },
    { lat: 48.113998, lng: 19.043319 },
    { lat: 48.113998, lng: 19.043266 },
    { lat: 48.114022, lng: 19.042139 },
    { lat: 48.113786, lng: 19.041662 },
    { lat: 48.113591, lng: 19.041309 },
    { lat: 48.113456, lng: 19.041083 },
    { lat: 48.113279, lng: 19.040818 },
    { lat: 48.113103, lng: 19.040426 },
    { lat: 48.112984, lng: 19.040113 },
    { lat: 48.112969, lng: 19.040074 },
    { lat: 48.112873, lng: 19.039823 },
    { lat: 48.112859, lng: 19.039782 },
    { lat: 48.112627, lng: 19.039321 },
    { lat: 48.112374, lng: 19.0389 },
    { lat: 48.112302, lng: 19.038738 },
    { lat: 48.11205, lng: 19.038131 },
    { lat: 48.111973, lng: 19.038037 },
    { lat: 48.111685, lng: 19.037704 },
    { lat: 48.111373, lng: 19.037007 },
    { lat: 48.111255, lng: 19.036829 },
    { lat: 48.111176, lng: 19.036579 },
    { lat: 48.110787, lng: 19.035635 },
    { lat: 48.11059, lng: 19.035362 },
    { lat: 48.110366, lng: 19.034733 },
    { lat: 48.110272, lng: 19.034423 },
    { lat: 48.110179, lng: 19.034087 },
    { lat: 48.110151, lng: 19.03386 },
    { lat: 48.110088, lng: 19.033607 },
    { lat: 48.109954, lng: 19.03321 },
    { lat: 48.109829, lng: 19.032675 },
    { lat: 48.109644, lng: 19.032119 },
    { lat: 48.109463, lng: 19.031802 },
    { lat: 48.109308, lng: 19.030926 },
    { lat: 48.109252, lng: 19.030776 },
    { lat: 48.109235, lng: 19.030551 },
    { lat: 48.1092, lng: 19.030356 },
    { lat: 48.109088, lng: 19.030261 },
    { lat: 48.108926, lng: 19.030065 },
    { lat: 48.108906, lng: 19.030044 },
    { lat: 48.1050079, lng: 19.0428428 },
    { lat: 48.1030358, lng: 19.0480154 },
    { lat: 48.1025094, lng: 19.050266 },
    { lat: 48.1024821, lng: 19.0506599 },
    { lat: 48.1010824, lng: 19.0526073 },
    { lat: 48.1008952, lng: 19.0530295 },
    { lat: 48.10088, lng: 19.05333 },
    { lat: 48.1003816, lng: 19.0555769 },
    { lat: 48.0996945, lng: 19.0566812 },
    { lat: 48.099138, lng: 19.0578497 },
    { lat: 48.0988512, lng: 19.0585733 },
    { lat: 48.0988363, lng: 19.0611088 },
    { lat: 48.0988797, lng: 19.0611434 },
    { lat: 48.0977408, lng: 19.0625894 },
    { lat: 48.0972576, lng: 19.0634417 },
    { lat: 48.0971006, lng: 19.064061 },
    { lat: 48.0969184, lng: 19.0642713 },
    { lat: 48.0967444, lng: 19.0643332 },
    { lat: 48.0962953, lng: 19.0651283 },
    { lat: 48.0956745, lng: 19.0670748 },
    { lat: 48.0955816, lng: 19.0674884 },
    { lat: 48.095389, lng: 19.0689656 },
    { lat: 48.0944754, lng: 19.0698528 },
    { lat: 48.0941208, lng: 19.0699054 },
    { lat: 48.09387, lng: 19.0700757 },
    { lat: 48.0936966, lng: 19.0703703 },
    { lat: 48.0933907, lng: 19.0711005 },
    { lat: 48.0925887, lng: 19.0712236 },
    { lat: 48.0924164, lng: 19.0714105 },
    { lat: 48.0921917, lng: 19.071848 },
    { lat: 48.0915943, lng: 19.0725197 },
    { lat: 48.0914462, lng: 19.0732927 },
    { lat: 48.0906176, lng: 19.074971 },
    { lat: 48.0905571, lng: 19.0752226 },
    { lat: 48.0905239, lng: 19.0762839 },
    { lat: 48.0903683, lng: 19.0769125 },
    { lat: 48.0898433, lng: 19.07714 },
    { lat: 48.0897636, lng: 19.0772368 },
    { lat: 48.0896981, lng: 19.0781494 },
    { lat: 48.0895768, lng: 19.0787173 },
    { lat: 48.0891161, lng: 19.0787264 },
    { lat: 48.088982, lng: 19.0794221 },
    { lat: 48.0884427, lng: 19.0812214 },
    { lat: 48.0883121, lng: 19.0819875 },
    { lat: 48.0882209, lng: 19.0833786 },
    { lat: 48.0878583, lng: 19.0836319 },
    { lat: 48.0874052, lng: 19.0837445 },
    { lat: 48.0869603, lng: 19.0839549 },
    { lat: 48.0859708, lng: 19.084164 },
    { lat: 48.0864341, lng: 19.0874275 },
    { lat: 48.087672, lng: 19.095157 },
    { lat: 48.0889527, lng: 19.0960971 },
    { lat: 48.0907031, lng: 19.0969381 },
    { lat: 48.0914066, lng: 19.0973406 },
    { lat: 48.0918157, lng: 19.0976555 },
    { lat: 48.0928206, lng: 19.0992554 },
    { lat: 48.0934844, lng: 19.1005524 },
    { lat: 48.0938382, lng: 19.1011275 },
    { lat: 48.0941871, lng: 19.1013868 },
    { lat: 48.0945447, lng: 19.1015735 },
    { lat: 48.0969069, lng: 19.1024094 },
    { lat: 48.0972406, lng: 19.1024552 },
    { lat: 48.0979041, lng: 19.1022406 },
    { lat: 48.0980747, lng: 19.1023164 },
    { lat: 48.098327, lng: 19.1026934 },
    { lat: 48.0984262, lng: 19.1028946 },
  ],
  Ďurkovce: [
    { lat: 48.1569083, lng: 19.1454732 },
    { lat: 48.1565496, lng: 19.1452434 },
    { lat: 48.1564981, lng: 19.1451393 },
    { lat: 48.156101, lng: 19.1449494 },
    { lat: 48.1550987, lng: 19.1442912 },
    { lat: 48.1542702, lng: 19.144 },
    { lat: 48.1534301, lng: 19.1435448 },
    { lat: 48.1522273, lng: 19.1431158 },
    { lat: 48.1514888, lng: 19.1433191 },
    { lat: 48.1506355, lng: 19.143114 },
    { lat: 48.1503924, lng: 19.142986 },
    { lat: 48.1491123, lng: 19.1415505 },
    { lat: 48.1488762, lng: 19.1409318 },
    { lat: 48.1486653, lng: 19.140724 },
    { lat: 48.1480773, lng: 19.1403976 },
    { lat: 48.1473438, lng: 19.1397493 },
    { lat: 48.146361, lng: 19.139345 },
    { lat: 48.146074, lng: 19.1394924 },
    { lat: 48.1457274, lng: 19.1399549 },
    { lat: 48.1454332, lng: 19.1401158 },
    { lat: 48.1451553, lng: 19.140128 },
    { lat: 48.1448598, lng: 19.1402372 },
    { lat: 48.144621, lng: 19.140556 },
    { lat: 48.1444649, lng: 19.1408844 },
    { lat: 48.1443666, lng: 19.1413987 },
    { lat: 48.1440161, lng: 19.1416992 },
    { lat: 48.1437114, lng: 19.1418704 },
    { lat: 48.1430649, lng: 19.1436275 },
    { lat: 48.1427939, lng: 19.1460401 },
    { lat: 48.1424456, lng: 19.1480124 },
    { lat: 48.1421567, lng: 19.1483209 },
    { lat: 48.1417496, lng: 19.1489069 },
    { lat: 48.1415223, lng: 19.1493673 },
    { lat: 48.1408488, lng: 19.1498805 },
    { lat: 48.1401808, lng: 19.1505025 },
    { lat: 48.1399541, lng: 19.1504755 },
    { lat: 48.1392136, lng: 19.1511606 },
    { lat: 48.1390253, lng: 19.1512708 },
    { lat: 48.1380594, lng: 19.1523874 },
    { lat: 48.1375653, lng: 19.1527875 },
    { lat: 48.1371873, lng: 19.1530112 },
    { lat: 48.1369975, lng: 19.1533654 },
    { lat: 48.1360471, lng: 19.1544704 },
    { lat: 48.135687, lng: 19.1547768 },
    { lat: 48.135555, lng: 19.1549595 },
    { lat: 48.1353457, lng: 19.1550769 },
    { lat: 48.1344802, lng: 19.1558816 },
    { lat: 48.1324173, lng: 19.1575606 },
    { lat: 48.1317342, lng: 19.158105 },
    { lat: 48.1308838, lng: 19.1580949 },
    { lat: 48.1307531, lng: 19.1581658 },
    { lat: 48.1305152, lng: 19.1581647 },
    { lat: 48.1304947, lng: 19.1581864 },
    { lat: 48.1305268, lng: 19.1582654 },
    { lat: 48.131264, lng: 19.1603927 },
    { lat: 48.1314385, lng: 19.1610015 },
    { lat: 48.1314784, lng: 19.1613288 },
    { lat: 48.1313407, lng: 19.1625265 },
    { lat: 48.1306955, lng: 19.1657899 },
    { lat: 48.1306457, lng: 19.1662845 },
    { lat: 48.1306721, lng: 19.1670706 },
    { lat: 48.1312265, lng: 19.1705645 },
    { lat: 48.1319099, lng: 19.1723411 },
    { lat: 48.1322681, lng: 19.1737194 },
    { lat: 48.1323328, lng: 19.1741386 },
    { lat: 48.1323519, lng: 19.1754584 },
    { lat: 48.1327906, lng: 19.1789448 },
    { lat: 48.1327373, lng: 19.1794849 },
    { lat: 48.1324266, lng: 19.1802 },
    { lat: 48.1329779, lng: 19.1821364 },
    { lat: 48.132953, lng: 19.1822006 },
    { lat: 48.1331029, lng: 19.1830867 },
    { lat: 48.133351, lng: 19.1841618 },
    { lat: 48.1337699, lng: 19.1848552 },
    { lat: 48.1342933, lng: 19.1855283 },
    { lat: 48.1342758, lng: 19.1856269 },
    { lat: 48.1347254, lng: 19.1860343 },
    { lat: 48.1364538, lng: 19.1880937 },
    { lat: 48.1366529, lng: 19.1875812 },
    { lat: 48.1382655, lng: 19.1852659 },
    { lat: 48.1402258, lng: 19.1821889 },
    { lat: 48.1403526, lng: 19.1819723 },
    { lat: 48.141509, lng: 19.1797113 },
    { lat: 48.1421129, lng: 19.1787258 },
    { lat: 48.1447109, lng: 19.1741423 },
    { lat: 48.1442932, lng: 19.1737233 },
    { lat: 48.1444433, lng: 19.1730678 },
    { lat: 48.1443292, lng: 19.1729706 },
    { lat: 48.1441805, lng: 19.1729497 },
    { lat: 48.1442359, lng: 19.1723618 },
    { lat: 48.1441966, lng: 19.1722984 },
    { lat: 48.144517, lng: 19.1713904 },
    { lat: 48.1448819, lng: 19.1706109 },
    { lat: 48.1452499, lng: 19.1695101 },
    { lat: 48.1453347, lng: 19.1687613 },
    { lat: 48.1461229, lng: 19.1674036 },
    { lat: 48.1465384, lng: 19.1662125 },
    { lat: 48.1470078, lng: 19.1643803 },
    { lat: 48.1469312, lng: 19.1635166 },
    { lat: 48.1477283, lng: 19.1623187 },
    { lat: 48.1492244, lng: 19.1605262 },
    { lat: 48.15107, lng: 19.1580099 },
    { lat: 48.1514104, lng: 19.157421 },
    { lat: 48.151712, lng: 19.1566388 },
    { lat: 48.1519807, lng: 19.1556491 },
    { lat: 48.1521703, lng: 19.1547003 },
    { lat: 48.1522618, lng: 19.1544543 },
    { lat: 48.1530539, lng: 19.1532017 },
    { lat: 48.1533184, lng: 19.1524877 },
    { lat: 48.1537337, lng: 19.1519985 },
    { lat: 48.1538961, lng: 19.1517062 },
    { lat: 48.1539582, lng: 19.1514098 },
    { lat: 48.1541265, lng: 19.1513745 },
    { lat: 48.1541028, lng: 19.1512836 },
    { lat: 48.1545465, lng: 19.1512512 },
    { lat: 48.1547862, lng: 19.151106 },
    { lat: 48.1549053, lng: 19.1509844 },
    { lat: 48.155128, lng: 19.1505889 },
    { lat: 48.1557255, lng: 19.1499304 },
    { lat: 48.1560162, lng: 19.1497474 },
    { lat: 48.1566991, lng: 19.1488104 },
    { lat: 48.1569231, lng: 19.1479003 },
    { lat: 48.1568721, lng: 19.1475314 },
    { lat: 48.1569598, lng: 19.1471195 },
    { lat: 48.1569852, lng: 19.1464762 },
    { lat: 48.1569083, lng: 19.1454732 },
  ],
  Lesenice: [
    { lat: 48.1083182, lng: 19.2191594 },
    { lat: 48.1082823, lng: 19.2191647 },
    { lat: 48.1081365, lng: 19.219246 },
    { lat: 48.1075774, lng: 19.2200046 },
    { lat: 48.1067296, lng: 19.2222243 },
    { lat: 48.1056222, lng: 19.2246462 },
    { lat: 48.1043143, lng: 19.2272128 },
    { lat: 48.1025769, lng: 19.2309452 },
    { lat: 48.1011124, lng: 19.2338152 },
    { lat: 48.1001829, lng: 19.2353652 },
    { lat: 48.0996184, lng: 19.2365853 },
    { lat: 48.09891, lng: 19.2393354 },
    { lat: 48.0987199, lng: 19.240633 },
    { lat: 48.0984036, lng: 19.242344 },
    { lat: 48.0974994, lng: 19.2468304 },
    { lat: 48.0958415, lng: 19.2474549 },
    { lat: 48.0968276, lng: 19.2510681 },
    { lat: 48.0968672, lng: 19.2518096 },
    { lat: 48.0967819, lng: 19.2526923 },
    { lat: 48.0970095, lng: 19.2528745 },
    { lat: 48.0983215, lng: 19.2546488 },
    { lat: 48.0991228, lng: 19.2555449 },
    { lat: 48.0991616, lng: 19.2559237 },
    { lat: 48.099432, lng: 19.2567966 },
    { lat: 48.1001588, lng: 19.2573913 },
    { lat: 48.1031783, lng: 19.2595297 },
    { lat: 48.1040345, lng: 19.2600988 },
    { lat: 48.1047186, lng: 19.2604384 },
    { lat: 48.1047325, lng: 19.2604714 },
    { lat: 48.1060735, lng: 19.2608795 },
    { lat: 48.1061581, lng: 19.261339 },
    { lat: 48.1114317, lng: 19.2621392 },
    { lat: 48.1114203, lng: 19.2629418 },
    { lat: 48.1116326, lng: 19.264751 },
    { lat: 48.111742, lng: 19.2647998 },
    { lat: 48.1121808, lng: 19.2646928 },
    { lat: 48.1128515, lng: 19.2629603 },
    { lat: 48.1127791, lng: 19.262261 },
    { lat: 48.1123799, lng: 19.261332 },
    { lat: 48.1126944, lng: 19.2611496 },
    { lat: 48.1128045, lng: 19.2605175 },
    { lat: 48.1131235, lng: 19.2604278 },
    { lat: 48.1132655, lng: 19.2600991 },
    { lat: 48.1132566, lng: 19.2599174 },
    { lat: 48.1133052, lng: 19.2598299 },
    { lat: 48.1135811, lng: 19.2596401 },
    { lat: 48.1136919, lng: 19.2596693 },
    { lat: 48.1145995, lng: 19.2570889 },
    { lat: 48.1157294, lng: 19.2543065 },
    { lat: 48.116573, lng: 19.2512941 },
    { lat: 48.1171101, lng: 19.2496696 },
    { lat: 48.1174407, lng: 19.2488653 },
    { lat: 48.1175274, lng: 19.2484508 },
    { lat: 48.1178555, lng: 19.2478274 },
    { lat: 48.1182771, lng: 19.2466352 },
    { lat: 48.1186011, lng: 19.2459525 },
    { lat: 48.1193472, lng: 19.2447066 },
    { lat: 48.1195577, lng: 19.2445615 },
    { lat: 48.1204252, lng: 19.2435848 },
    { lat: 48.1210588, lng: 19.2430327 },
    { lat: 48.1213834, lng: 19.2428501 },
    { lat: 48.1216352, lng: 19.2425361 },
    { lat: 48.1219319, lng: 19.2418024 },
    { lat: 48.1220945, lng: 19.2415535 },
    { lat: 48.1226664, lng: 19.2409911 },
    { lat: 48.1230527, lng: 19.2404035 },
    { lat: 48.1233849, lng: 19.2401693 },
    { lat: 48.1235842, lng: 19.2396387 },
    { lat: 48.124074, lng: 19.2394183 },
    { lat: 48.1246491, lng: 19.2387081 },
    { lat: 48.1248497, lng: 19.2385697 },
    { lat: 48.1250452, lng: 19.2382791 },
    { lat: 48.125185, lng: 19.2383023 },
    { lat: 48.1254965, lng: 19.2375965 },
    { lat: 48.1256601, lng: 19.2373937 },
    { lat: 48.1258874, lng: 19.23725 },
    { lat: 48.1266329, lng: 19.2359918 },
    { lat: 48.1270243, lng: 19.2356744 },
    { lat: 48.1274426, lng: 19.2343874 },
    { lat: 48.1275192, lng: 19.2337228 },
    { lat: 48.127721, lng: 19.2330546 },
    { lat: 48.1287178, lng: 19.2314333 },
    { lat: 48.1295746, lng: 19.2305801 },
    { lat: 48.129752, lng: 19.2302252 },
    { lat: 48.1297742, lng: 19.2299412 },
    { lat: 48.1300458, lng: 19.2298673 },
    { lat: 48.1301029, lng: 19.2296296 },
    { lat: 48.1302411, lng: 19.229486 },
    { lat: 48.1303864, lng: 19.2292086 },
    { lat: 48.1303496, lng: 19.2291213 },
    { lat: 48.1303417, lng: 19.2291006 },
    { lat: 48.1298587, lng: 19.2282841 },
    { lat: 48.1297138, lng: 19.2279499 },
    { lat: 48.1286157, lng: 19.2264125 },
    { lat: 48.1282064, lng: 19.2253738 },
    { lat: 48.1281168, lng: 19.2239394 },
    { lat: 48.1279015, lng: 19.2232983 },
    { lat: 48.127516, lng: 19.2228836 },
    { lat: 48.1270269, lng: 19.2224785 },
    { lat: 48.1257367, lng: 19.2210894 },
    { lat: 48.1249613, lng: 19.2206534 },
    { lat: 48.1245373, lng: 19.2203079 },
    { lat: 48.1235448, lng: 19.2196995 },
    { lat: 48.1220832, lng: 19.2190921 },
    { lat: 48.121884, lng: 19.2189361 },
    { lat: 48.121221, lng: 19.2180387 },
    { lat: 48.1210847, lng: 19.2181792 },
    { lat: 48.120973, lng: 19.2181558 },
    { lat: 48.1208753, lng: 19.2183207 },
    { lat: 48.1207516, lng: 19.2183995 },
    { lat: 48.1205164, lng: 19.2184543 },
    { lat: 48.1204032, lng: 19.2186918 },
    { lat: 48.1203674, lng: 19.2189723 },
    { lat: 48.1201321, lng: 19.2190969 },
    { lat: 48.1199816, lng: 19.2194531 },
    { lat: 48.1198011, lng: 19.2194952 },
    { lat: 48.1197527, lng: 19.2195876 },
    { lat: 48.1197505, lng: 19.2198881 },
    { lat: 48.1194664, lng: 19.2200921 },
    { lat: 48.119442, lng: 19.2201667 },
    { lat: 48.1192789, lng: 19.2200576 },
    { lat: 48.1189683, lng: 19.2203704 },
    { lat: 48.1187412, lng: 19.2203589 },
    { lat: 48.1186749, lng: 19.220501 },
    { lat: 48.1184402, lng: 19.2207236 },
    { lat: 48.1184135, lng: 19.2211459 },
    { lat: 48.1182014, lng: 19.2211671 },
    { lat: 48.1180671, lng: 19.2213896 },
    { lat: 48.1180102, lng: 19.221586 },
    { lat: 48.1180373, lng: 19.2217248 },
    { lat: 48.1179532, lng: 19.2218497 },
    { lat: 48.1178429, lng: 19.2218075 },
    { lat: 48.1177653, lng: 19.2216739 },
    { lat: 48.1176107, lng: 19.2216682 },
    { lat: 48.1175461, lng: 19.2216848 },
    { lat: 48.1175436, lng: 19.2218393 },
    { lat: 48.1174167, lng: 19.2220557 },
    { lat: 48.1170912, lng: 19.2220607 },
    { lat: 48.1170187, lng: 19.2221476 },
    { lat: 48.1170456, lng: 19.2224392 },
    { lat: 48.1168773, lng: 19.2227113 },
    { lat: 48.1168099, lng: 19.2229448 },
    { lat: 48.1158169, lng: 19.2220293 },
    { lat: 48.1147245, lng: 19.2212234 },
    { lat: 48.1135906, lng: 19.2201258 },
    { lat: 48.1128163, lng: 19.2196463 },
    { lat: 48.1118306, lng: 19.2192959 },
    { lat: 48.1115188, lng: 19.2193022 },
    { lat: 48.1096152, lng: 19.2186214 },
    { lat: 48.1093742, lng: 19.2188556 },
    { lat: 48.1091015, lng: 19.2189976 },
    { lat: 48.1084078, lng: 19.2190811 },
    { lat: 48.1083182, lng: 19.2191594 },
  ],
  Čeláre: [
    { lat: 48.1600541, lng: 19.4680628 },
    { lat: 48.1598386, lng: 19.4686092 },
    { lat: 48.1595212, lng: 19.4693894 },
    { lat: 48.1592785, lng: 19.4697883 },
    { lat: 48.1586128, lng: 19.4705637 },
    { lat: 48.1582186, lng: 19.4708563 },
    { lat: 48.1571415, lng: 19.4714397 },
    { lat: 48.1560209, lng: 19.4723961 },
    { lat: 48.1537952, lng: 19.470884 },
    { lat: 48.153047, lng: 19.4700925 },
    { lat: 48.1515781, lng: 19.4689318 },
    { lat: 48.1510497, lng: 19.4686717 },
    { lat: 48.1507384, lng: 19.4684248 },
    { lat: 48.1506668, lng: 19.4686769 },
    { lat: 48.1491634, lng: 19.4662068 },
    { lat: 48.1487459, lng: 19.4651418 },
    { lat: 48.146581, lng: 19.4624339 },
    { lat: 48.1464946, lng: 19.4623682 },
    { lat: 48.1460249, lng: 19.4622996 },
    { lat: 48.1455123, lng: 19.4618999 },
    { lat: 48.1453111, lng: 19.4619098 },
    { lat: 48.1448197, lng: 19.4625796 },
    { lat: 48.1446867, lng: 19.4626744 },
    { lat: 48.1438542, lng: 19.4629452 },
    { lat: 48.1439295, lng: 19.4622715 },
    { lat: 48.143913, lng: 19.4618497 },
    { lat: 48.143775, lng: 19.4612149 },
    { lat: 48.1436882, lng: 19.4610921 },
    { lat: 48.1434195, lng: 19.4597928 },
    { lat: 48.1434035, lng: 19.4595221 },
    { lat: 48.1434983, lng: 19.4589976 },
    { lat: 48.1434679, lng: 19.4584962 },
    { lat: 48.1433882, lng: 19.4581672 },
    { lat: 48.1431533, lng: 19.4576277 },
    { lat: 48.143, lng: 19.456864 },
    { lat: 48.1429944, lng: 19.4561172 },
    { lat: 48.1428171, lng: 19.4533609 },
    { lat: 48.1427676, lng: 19.453552 },
    { lat: 48.1426451, lng: 19.4539449 },
    { lat: 48.1410711, lng: 19.4557222 },
    { lat: 48.1381643, lng: 19.4581154 },
    { lat: 48.1364018, lng: 19.4608948 },
    { lat: 48.1358875, lng: 19.462233 },
    { lat: 48.1371734, lng: 19.4630221 },
    { lat: 48.1375864, lng: 19.4631756 },
    { lat: 48.1354927, lng: 19.4682659 },
    { lat: 48.1345337, lng: 19.4713659 },
    { lat: 48.1337713, lng: 19.4727473 },
    { lat: 48.1328904, lng: 19.474075 },
    { lat: 48.1312354, lng: 19.4728894 },
    { lat: 48.1306825, lng: 19.4724149 },
    { lat: 48.129709, lng: 19.474322 },
    { lat: 48.1294863, lng: 19.473898 },
    { lat: 48.1292065, lng: 19.4731456 },
    { lat: 48.1285357, lng: 19.4716531 },
    { lat: 48.1280097, lng: 19.4698812 },
    { lat: 48.1285668, lng: 19.4694842 },
    { lat: 48.1288935, lng: 19.4690923 },
    { lat: 48.1290518, lng: 19.4687469 },
    { lat: 48.1294302, lng: 19.4674221 },
    { lat: 48.1302512, lng: 19.4677668 },
    { lat: 48.1305551, lng: 19.4681925 },
    { lat: 48.1309071, lng: 19.4688241 },
    { lat: 48.1314821, lng: 19.4681578 },
    { lat: 48.1321211, lng: 19.4668356 },
    { lat: 48.132459, lng: 19.4659439 },
    { lat: 48.1326085, lng: 19.4643319 },
    { lat: 48.1328241, lng: 19.4632558 },
    { lat: 48.132955, lng: 19.4618002 },
    { lat: 48.1327587, lng: 19.4603073 },
    { lat: 48.1327673, lng: 19.4594872 },
    { lat: 48.1304353, lng: 19.4594593 },
    { lat: 48.1297989, lng: 19.4596807 },
    { lat: 48.1283485, lng: 19.4582695 },
    { lat: 48.1289476, lng: 19.4506961 },
    { lat: 48.1288917, lng: 19.4506826 },
    { lat: 48.1288348, lng: 19.450673 },
    { lat: 48.1284655, lng: 19.4513497 },
    { lat: 48.1275767, lng: 19.4525504 },
    { lat: 48.1272385, lng: 19.4531973 },
    { lat: 48.1269254, lng: 19.4536539 },
    { lat: 48.1266064, lng: 19.4539421 },
    { lat: 48.1258072, lng: 19.4544628 },
    { lat: 48.124824, lng: 19.4541918 },
    { lat: 48.1244013, lng: 19.4547435 },
    { lat: 48.1241216, lng: 19.4548586 },
    { lat: 48.1236013, lng: 19.4548173 },
    { lat: 48.1235954, lng: 19.4549569 },
    { lat: 48.1234478, lng: 19.4549569 },
    { lat: 48.1233609, lng: 19.4550231 },
    { lat: 48.122204, lng: 19.4564981 },
    { lat: 48.1215299, lng: 19.4576912 },
    { lat: 48.1212839, lng: 19.4582359 },
    { lat: 48.1209046, lng: 19.4594439 },
    { lat: 48.1206742, lng: 19.4600088 },
    { lat: 48.1205019, lng: 19.4619527 },
    { lat: 48.1203353, lng: 19.4632508 },
    { lat: 48.120389, lng: 19.4645594 },
    { lat: 48.1205331, lng: 19.4657301 },
    { lat: 48.1206296, lng: 19.4676398 },
    { lat: 48.1205843, lng: 19.4677556 },
    { lat: 48.1202092, lng: 19.4678337 },
    { lat: 48.1198135, lng: 19.4676615 },
    { lat: 48.1198604, lng: 19.4689005 },
    { lat: 48.1199385, lng: 19.4696277 },
    { lat: 48.1197779, lng: 19.4712469 },
    { lat: 48.1194615, lng: 19.4721889 },
    { lat: 48.1192395, lng: 19.4726717 },
    { lat: 48.1188481, lng: 19.473118 },
    { lat: 48.1182152, lng: 19.4734396 },
    { lat: 48.117247, lng: 19.4741616 },
    { lat: 48.1156617, lng: 19.4757273 },
    { lat: 48.1136748, lng: 19.4789487 },
    { lat: 48.1124916, lng: 19.4792646 },
    { lat: 48.1117085, lng: 19.479819 },
    { lat: 48.1117883, lng: 19.480055 },
    { lat: 48.1120594, lng: 19.4818238 },
    { lat: 48.1122875, lng: 19.4826509 },
    { lat: 48.1126218, lng: 19.4842808 },
    { lat: 48.1125898, lng: 19.4861948 },
    { lat: 48.1123315, lng: 19.4879723 },
    { lat: 48.1120321, lng: 19.490965 },
    { lat: 48.1120534, lng: 19.4911094 },
    { lat: 48.112063, lng: 19.491099 },
    { lat: 48.112105, lng: 19.491054 },
    { lat: 48.112202, lng: 19.490976 },
    { lat: 48.112277, lng: 19.490924 },
    { lat: 48.112423, lng: 19.49092 },
    { lat: 48.112498, lng: 19.490953 },
    { lat: 48.112641, lng: 19.491034 },
    { lat: 48.112666, lng: 19.491056 },
    { lat: 48.112779, lng: 19.491186 },
    { lat: 48.112805, lng: 19.491299 },
    { lat: 48.112793, lng: 19.491542 },
    { lat: 48.11279, lng: 19.491742 },
    { lat: 48.112852, lng: 19.491921 },
    { lat: 48.112962, lng: 19.492107 },
    { lat: 48.113027, lng: 19.492198 },
    { lat: 48.113169, lng: 19.492376 },
    { lat: 48.113334, lng: 19.492448 },
    { lat: 48.113446, lng: 19.492371 },
    { lat: 48.113578, lng: 19.492211 },
    { lat: 48.113639, lng: 19.492185 },
    { lat: 48.113687, lng: 19.492206 },
    { lat: 48.113703, lng: 19.492312 },
    { lat: 48.113701, lng: 19.492458 },
    { lat: 48.113705, lng: 19.492546 },
    { lat: 48.113703, lng: 19.492743 },
    { lat: 48.113787, lng: 19.492892 },
    { lat: 48.113878, lng: 19.493011 },
    { lat: 48.114003, lng: 19.493011 },
    { lat: 48.11404, lng: 19.493039 },
    { lat: 48.114087, lng: 19.493104 },
    { lat: 48.114104, lng: 19.493151 },
    { lat: 48.114098, lng: 19.493292 },
    { lat: 48.114076, lng: 19.493347 },
    { lat: 48.114071, lng: 19.493429 },
    { lat: 48.114046, lng: 19.493584 },
    { lat: 48.114046, lng: 19.493666 },
    { lat: 48.114082, lng: 19.493861 },
    { lat: 48.114107, lng: 19.493924 },
    { lat: 48.114229, lng: 19.494103 },
    { lat: 48.114375, lng: 19.494242 },
    { lat: 48.114418, lng: 19.494337 },
    { lat: 48.114475, lng: 19.494432 },
    { lat: 48.114601, lng: 19.494634 },
    { lat: 48.114631, lng: 19.494672 },
    { lat: 48.114667, lng: 19.494696 },
    { lat: 48.114709, lng: 19.49471 },
    { lat: 48.114823, lng: 19.494671 },
    { lat: 48.114854, lng: 19.494649 },
    { lat: 48.114872, lng: 19.494628 },
    { lat: 48.114889, lng: 19.494596 },
    { lat: 48.114918, lng: 19.4945 },
    { lat: 48.115046, lng: 19.494158 },
    { lat: 48.11514, lng: 19.494007 },
    { lat: 48.115189, lng: 19.493978 },
    { lat: 48.115254, lng: 19.493952 },
    { lat: 48.115404, lng: 19.4939 },
    { lat: 48.115552, lng: 19.493815 },
    { lat: 48.115651, lng: 19.49373 },
    { lat: 48.115794, lng: 19.49357 },
    { lat: 48.115859, lng: 19.493469 },
    { lat: 48.115907, lng: 19.493377 },
    { lat: 48.11598, lng: 19.493305 },
    { lat: 48.116048, lng: 19.493295 },
    { lat: 48.116106, lng: 19.493314 },
    { lat: 48.116188, lng: 19.493334 },
    { lat: 48.116278, lng: 19.493342 },
    { lat: 48.116408, lng: 19.493317 },
    { lat: 48.116581, lng: 19.493216 },
    { lat: 48.116743, lng: 19.493069 },
    { lat: 48.11687, lng: 19.492953 },
    { lat: 48.116909, lng: 19.492884 },
    { lat: 48.116968, lng: 19.492752 },
    { lat: 48.117, lng: 19.492591 },
    { lat: 48.117056, lng: 19.492468 },
    { lat: 48.117164, lng: 19.492324 },
    { lat: 48.117221, lng: 19.492253 },
    { lat: 48.117275, lng: 19.492207 },
    { lat: 48.117406, lng: 19.492103 },
    { lat: 48.117447, lng: 19.49209 },
    { lat: 48.117546, lng: 19.492051 },
    { lat: 48.117771, lng: 19.492012 },
    { lat: 48.117845, lng: 19.491997 },
    { lat: 48.117918, lng: 19.491965 },
    { lat: 48.118017, lng: 19.491979 },
    { lat: 48.118134, lng: 19.492029 },
    { lat: 48.118249, lng: 19.492085 },
    { lat: 48.118319, lng: 19.492129 },
    { lat: 48.118363, lng: 19.492173 },
    { lat: 48.118384, lng: 19.492196 },
    { lat: 48.118447, lng: 19.492327 },
    { lat: 48.118475, lng: 19.492431 },
    { lat: 48.118522, lng: 19.492502 },
    { lat: 48.11857, lng: 19.492734 },
    { lat: 48.118603, lng: 19.492937 },
    { lat: 48.118646, lng: 19.493079 },
    { lat: 48.118685, lng: 19.493144 },
    { lat: 48.118741, lng: 19.493178 },
    { lat: 48.118818, lng: 19.493168 },
    { lat: 48.118878, lng: 19.49315 },
    { lat: 48.118978, lng: 19.493083 },
    { lat: 48.119115, lng: 19.493079 },
    { lat: 48.119263, lng: 19.493166 },
    { lat: 48.119323, lng: 19.493222 },
    { lat: 48.119418, lng: 19.493399 },
    { lat: 48.119556, lng: 19.493545 },
    { lat: 48.119622, lng: 19.493763 },
    { lat: 48.119621, lng: 19.493978 },
    { lat: 48.119609, lng: 19.494015 },
    { lat: 48.119583, lng: 19.494047 },
    { lat: 48.11954, lng: 19.494345 },
    { lat: 48.119546, lng: 19.494397 },
    { lat: 48.119583, lng: 19.494572 },
    { lat: 48.119608, lng: 19.49463 },
    { lat: 48.119706, lng: 19.494712 },
    { lat: 48.119756, lng: 19.494776 },
    { lat: 48.119986, lng: 19.494794 },
    { lat: 48.120146, lng: 19.494872 },
    { lat: 48.120207, lng: 19.494899 },
    { lat: 48.120278, lng: 19.494978 },
    { lat: 48.120336, lng: 19.494975 },
    { lat: 48.120426, lng: 19.495043 },
    { lat: 48.120506, lng: 19.495178 },
    { lat: 48.120528, lng: 19.495251 },
    { lat: 48.120574, lng: 19.495366 },
    { lat: 48.120621, lng: 19.495444 },
    { lat: 48.120696, lng: 19.495577 },
    { lat: 48.12077, lng: 19.495625 },
    { lat: 48.120853, lng: 19.495619 },
    { lat: 48.12098, lng: 19.495564 },
    { lat: 48.121035, lng: 19.495518 },
    { lat: 48.121102, lng: 19.495471 },
    { lat: 48.121184, lng: 19.4953 },
    { lat: 48.121261, lng: 19.495138 },
    { lat: 48.121373, lng: 19.495067 },
    { lat: 48.121421, lng: 19.495055 },
    { lat: 48.121488, lng: 19.495097 },
    { lat: 48.121512, lng: 19.495136 },
    { lat: 48.12155, lng: 19.495285 },
    { lat: 48.121613, lng: 19.495419 },
    { lat: 48.121647, lng: 19.495454 },
    { lat: 48.121705, lng: 19.495504 },
    { lat: 48.121783, lng: 19.495547 },
    { lat: 48.121903, lng: 19.495539 },
    { lat: 48.122049, lng: 19.495502 },
    { lat: 48.122194, lng: 19.495414 },
    { lat: 48.122282, lng: 19.49533 },
    { lat: 48.122344, lng: 19.495221 },
    { lat: 48.122397, lng: 19.495135 },
    { lat: 48.122455, lng: 19.495007 },
    { lat: 48.122503, lng: 19.494932 },
    { lat: 48.12268, lng: 19.494815 },
    { lat: 48.122849, lng: 19.494699 },
    { lat: 48.122963, lng: 19.494565 },
    { lat: 48.123081, lng: 19.494464 },
    { lat: 48.123215, lng: 19.494379 },
    { lat: 48.123305, lng: 19.494351 },
    { lat: 48.123396, lng: 19.494359 },
    { lat: 48.123467, lng: 19.4944 },
    { lat: 48.123504, lng: 19.494419 },
    { lat: 48.123552, lng: 19.494536 },
    { lat: 48.123554, lng: 19.49458 },
    { lat: 48.123533, lng: 19.49463 },
    { lat: 48.123445, lng: 19.49465 },
    { lat: 48.12343, lng: 19.494628 },
    { lat: 48.123354, lng: 19.494611 },
    { lat: 48.123241, lng: 19.494633 },
    { lat: 48.123177, lng: 19.49466 },
    { lat: 48.123067, lng: 19.494754 },
    { lat: 48.122973, lng: 19.494867 },
    { lat: 48.12292, lng: 19.49496 },
    { lat: 48.122874, lng: 19.49507 },
    { lat: 48.122837, lng: 19.495226 },
    { lat: 48.12283, lng: 19.495301 },
    { lat: 48.122842, lng: 19.495448 },
    { lat: 48.122863, lng: 19.495581 },
    { lat: 48.123036, lng: 19.49585 },
    { lat: 48.123112, lng: 19.495921 },
    { lat: 48.123151, lng: 19.495963 },
    { lat: 48.123272, lng: 19.496072 },
    { lat: 48.123313, lng: 19.496094 },
    { lat: 48.123358, lng: 19.496145 },
    { lat: 48.123435, lng: 19.49627 },
    { lat: 48.123515, lng: 19.496389 },
    { lat: 48.123684, lng: 19.4965 },
    { lat: 48.123795, lng: 19.496539 },
    { lat: 48.123912, lng: 19.496588 },
    { lat: 48.123945, lng: 19.496588 },
    { lat: 48.124131, lng: 19.496619 },
    { lat: 48.124195, lng: 19.496623 },
    { lat: 48.124341, lng: 19.496675 },
    { lat: 48.124387, lng: 19.496707 },
    { lat: 48.124536, lng: 19.496894 },
    { lat: 48.124648, lng: 19.497016 },
    { lat: 48.124773, lng: 19.497077 },
    { lat: 48.124792, lng: 19.497063 },
    { lat: 48.124872, lng: 19.496964 },
    { lat: 48.12488, lng: 19.496824 },
    { lat: 48.124885, lng: 19.496689 },
    { lat: 48.124824, lng: 19.496535 },
    { lat: 48.124768, lng: 19.4963 },
    { lat: 48.1247, lng: 19.495908 },
    { lat: 48.12469, lng: 19.495756 },
    { lat: 48.124718, lng: 19.495586 },
    { lat: 48.12476, lng: 19.495464 },
    { lat: 48.124807, lng: 19.495433 },
    { lat: 48.124881, lng: 19.495422 },
    { lat: 48.124989, lng: 19.495523 },
    { lat: 48.125016, lng: 19.495576 },
    { lat: 48.125027, lng: 19.495665 },
    { lat: 48.125051, lng: 19.495851 },
    { lat: 48.125061, lng: 19.495894 },
    { lat: 48.125112, lng: 19.496002 },
    { lat: 48.12521, lng: 19.49613 },
    { lat: 48.125257, lng: 19.496147 },
    { lat: 48.125368, lng: 19.49616 },
    { lat: 48.125401, lng: 19.496164 },
    { lat: 48.125501, lng: 19.496118 },
    { lat: 48.125667, lng: 19.496042 },
    { lat: 48.125737, lng: 19.495986 },
    { lat: 48.125862, lng: 19.495914 },
    { lat: 48.125957, lng: 19.495818 },
    { lat: 48.12601, lng: 19.495647 },
    { lat: 48.126103, lng: 19.495439 },
    { lat: 48.12618, lng: 19.495354 },
    { lat: 48.126262, lng: 19.495276 },
    { lat: 48.126351, lng: 19.49523 },
    { lat: 48.126484, lng: 19.495169 },
    { lat: 48.126581, lng: 19.495134 },
    { lat: 48.126642, lng: 19.495101 },
    { lat: 48.126736, lng: 19.495057 },
    { lat: 48.126818, lng: 19.494923 },
    { lat: 48.126866, lng: 19.494829 },
    { lat: 48.126939, lng: 19.494683 },
    { lat: 48.126941, lng: 19.494631 },
    { lat: 48.126942, lng: 19.494478 },
    { lat: 48.12695, lng: 19.49433 },
    { lat: 48.126973, lng: 19.494236 },
    { lat: 48.127037, lng: 19.494146 },
    { lat: 48.127125, lng: 19.494052 },
    { lat: 48.127206, lng: 19.494077 },
    { lat: 48.127276, lng: 19.494126 },
    { lat: 48.127319, lng: 19.494145 },
    { lat: 48.127396, lng: 19.49421 },
    { lat: 48.127454, lng: 19.49426 },
    { lat: 48.127596, lng: 19.494446 },
    { lat: 48.127734, lng: 19.494652 },
    { lat: 48.127908, lng: 19.494877 },
    { lat: 48.12803, lng: 19.495008 },
    { lat: 48.128108, lng: 19.495078 },
    { lat: 48.12818, lng: 19.495139 },
    { lat: 48.128375, lng: 19.495347 },
    { lat: 48.128451, lng: 19.495399 },
    { lat: 48.128544, lng: 19.495411 },
    { lat: 48.12859, lng: 19.495419 },
    { lat: 48.128677, lng: 19.495419 },
    { lat: 48.128912, lng: 19.495296 },
    { lat: 48.12909, lng: 19.495149 },
    { lat: 48.129165, lng: 19.495085 },
    { lat: 48.12924, lng: 19.494994 },
    { lat: 48.129348, lng: 19.494896 },
    { lat: 48.129441, lng: 19.4948 },
    { lat: 48.129574, lng: 19.494646 },
    { lat: 48.129712, lng: 19.494429 },
    { lat: 48.129816, lng: 19.494293 },
    { lat: 48.12992, lng: 19.494174 },
    { lat: 48.129997, lng: 19.494136 },
    { lat: 48.130041, lng: 19.494119 },
    { lat: 48.130143, lng: 19.494101 },
    { lat: 48.130167, lng: 19.494186 },
    { lat: 48.130143, lng: 19.494468 },
    { lat: 48.130139, lng: 19.494548 },
    { lat: 48.130157, lng: 19.494621 },
    { lat: 48.130232, lng: 19.494734 },
    { lat: 48.130319, lng: 19.494816 },
    { lat: 48.130338, lng: 19.494829 },
    { lat: 48.130423, lng: 19.49485 },
    { lat: 48.130568, lng: 19.494877 },
    { lat: 48.130693, lng: 19.494886 },
    { lat: 48.130806, lng: 19.4949 },
    { lat: 48.130911, lng: 19.494897 },
    { lat: 48.131033, lng: 19.494913 },
    { lat: 48.131085, lng: 19.494987 },
    { lat: 48.131155, lng: 19.495125 },
    { lat: 48.131164, lng: 19.495153 },
    { lat: 48.131158, lng: 19.495207 },
    { lat: 48.131128, lng: 19.495344 },
    { lat: 48.131101, lng: 19.495389 },
    { lat: 48.13101, lng: 19.495457 },
    { lat: 48.130933, lng: 19.495506 },
    { lat: 48.130831, lng: 19.495567 },
    { lat: 48.130771, lng: 19.49564 },
    { lat: 48.130737, lng: 19.4957 },
    { lat: 48.130717, lng: 19.495757 },
    { lat: 48.130711, lng: 19.49587 },
    { lat: 48.130756, lng: 19.495974 },
    { lat: 48.130796, lng: 19.496026 },
    { lat: 48.130836, lng: 19.49604 },
    { lat: 48.13095, lng: 19.496057 },
    { lat: 48.131471, lng: 19.496001 },
    { lat: 48.131552, lng: 19.495996 },
    { lat: 48.13177, lng: 19.496064 },
    { lat: 48.1318, lng: 19.496052 },
    { lat: 48.131977, lng: 19.496162 },
    { lat: 48.132007, lng: 19.49622 },
    { lat: 48.132096, lng: 19.496301 },
    { lat: 48.132202, lng: 19.496408 },
    { lat: 48.132298, lng: 19.496452 },
    { lat: 48.132405, lng: 19.496494 },
    { lat: 48.132517, lng: 19.496495 },
    { lat: 48.132783, lng: 19.496484 },
    { lat: 48.132957, lng: 19.496489 },
    { lat: 48.133133, lng: 19.496477 },
    { lat: 48.133284, lng: 19.496449 },
    { lat: 48.133495, lng: 19.496386 },
    { lat: 48.133687, lng: 19.496393 },
    { lat: 48.133783, lng: 19.496409 },
    { lat: 48.133927, lng: 19.496402 },
    { lat: 48.134064, lng: 19.496419 },
    { lat: 48.13411, lng: 19.496423 },
    { lat: 48.134196, lng: 19.496414 },
    { lat: 48.134234, lng: 19.496394 },
    { lat: 48.134306, lng: 19.496363 },
    { lat: 48.134493, lng: 19.496326 },
    { lat: 48.134688, lng: 19.496354 },
    { lat: 48.134832, lng: 19.496361 },
    { lat: 48.134958, lng: 19.496432 },
    { lat: 48.135121, lng: 19.496513 },
    { lat: 48.135196, lng: 19.496508 },
    { lat: 48.135305, lng: 19.496422 },
    { lat: 48.135314, lng: 19.496383 },
    { lat: 48.135406, lng: 19.496227 },
    { lat: 48.13546, lng: 19.49609 },
    { lat: 48.135494, lng: 19.495844 },
    { lat: 48.135506, lng: 19.495706 },
    { lat: 48.135523, lng: 19.495556 },
    { lat: 48.135557, lng: 19.49536 },
    { lat: 48.13563, lng: 19.495202 },
    { lat: 48.135662, lng: 19.495154 },
    { lat: 48.135783, lng: 19.495047 },
    { lat: 48.135828, lng: 19.495001 },
    { lat: 48.136023, lng: 19.494883 },
    { lat: 48.136086, lng: 19.494805 },
    { lat: 48.13621, lng: 19.494785 },
    { lat: 48.136254, lng: 19.49479 },
    { lat: 48.136312, lng: 19.494843 },
    { lat: 48.136352, lng: 19.494872 },
    { lat: 48.136422, lng: 19.4951 },
    { lat: 48.136437, lng: 19.495256 },
    { lat: 48.13643, lng: 19.495354 },
    { lat: 48.136395, lng: 19.495448 },
    { lat: 48.136278, lng: 19.495697 },
    { lat: 48.136228, lng: 19.495793 },
    { lat: 48.136228, lng: 19.495822 },
    { lat: 48.136243, lng: 19.495911 },
    { lat: 48.136259, lng: 19.495943 },
    { lat: 48.136292, lng: 19.495997 },
    { lat: 48.136383, lng: 19.496015 },
    { lat: 48.136546, lng: 19.49604 },
    { lat: 48.136613, lng: 19.496038 },
    { lat: 48.136692, lng: 19.496048 },
    { lat: 48.136839, lng: 19.496024 },
    { lat: 48.13693, lng: 19.495982 },
    { lat: 48.137167, lng: 19.495953 },
    { lat: 48.137312, lng: 19.495995 },
    { lat: 48.137559, lng: 19.496014 },
    { lat: 48.137626, lng: 19.495978 },
    { lat: 48.137674, lng: 19.495942 },
    { lat: 48.137721, lng: 19.495889 },
    { lat: 48.137753, lng: 19.495862 },
    { lat: 48.137819, lng: 19.495644 },
    { lat: 48.13786, lng: 19.495465 },
    { lat: 48.137875, lng: 19.495286 },
    { lat: 48.137866, lng: 19.495243 },
    { lat: 48.137878, lng: 19.49506 },
    { lat: 48.137851, lng: 19.494697 },
    { lat: 48.137836, lng: 19.494577 },
    { lat: 48.137812, lng: 19.494425 },
    { lat: 48.137819, lng: 19.494283 },
    { lat: 48.137847, lng: 19.494119 },
    { lat: 48.137908, lng: 19.493926 },
    { lat: 48.137939, lng: 19.493856 },
    { lat: 48.138023, lng: 19.493758 },
    { lat: 48.138068, lng: 19.493726 },
    { lat: 48.13817, lng: 19.493721 },
    { lat: 48.138252, lng: 19.493746 },
    { lat: 48.138264, lng: 19.493799 },
    { lat: 48.138335, lng: 19.493922 },
    { lat: 48.138391, lng: 19.494066 },
    { lat: 48.138421, lng: 19.494231 },
    { lat: 48.138457, lng: 19.494476 },
    { lat: 48.138484, lng: 19.494593 },
    { lat: 48.138547, lng: 19.494711 },
    { lat: 48.138583, lng: 19.494731 },
    { lat: 48.138617, lng: 19.494735 },
    { lat: 48.138689, lng: 19.494704 },
    { lat: 48.138766, lng: 19.494609 },
    { lat: 48.138765, lng: 19.494573 },
    { lat: 48.138859, lng: 19.494397 },
    { lat: 48.138928, lng: 19.494134 },
    { lat: 48.138971, lng: 19.493962 },
    { lat: 48.139076, lng: 19.493361 },
    { lat: 48.139116, lng: 19.493146 },
    { lat: 48.13912, lng: 19.493095 },
    { lat: 48.13917, lng: 19.492917 },
    { lat: 48.139206, lng: 19.492781 },
    { lat: 48.13924, lng: 19.492687 },
    { lat: 48.139257, lng: 19.492648 },
    { lat: 48.139363, lng: 19.492538 },
    { lat: 48.139497, lng: 19.49247 },
    { lat: 48.139646, lng: 19.492378 },
    { lat: 48.139664, lng: 19.492377 },
    { lat: 48.139678, lng: 19.492401 },
    { lat: 48.139723, lng: 19.492423 },
    { lat: 48.139807, lng: 19.492464 },
    { lat: 48.139861, lng: 19.492516 },
    { lat: 48.139941, lng: 19.492613 },
    { lat: 48.140034, lng: 19.492702 },
    { lat: 48.14009, lng: 19.492764 },
    { lat: 48.14014, lng: 19.492872 },
    { lat: 48.140207, lng: 19.493083 },
    { lat: 48.140233, lng: 19.493184 },
    { lat: 48.140289, lng: 19.493379 },
    { lat: 48.14038, lng: 19.493595 },
    { lat: 48.140416, lng: 19.493657 },
    { lat: 48.140559, lng: 19.493825 },
    { lat: 48.14068, lng: 19.493925 },
    { lat: 48.140728, lng: 19.49395 },
    { lat: 48.140839, lng: 19.493975 },
    { lat: 48.140947, lng: 19.493997 },
    { lat: 48.141032, lng: 19.493931 },
    { lat: 48.141167, lng: 19.493938 },
    { lat: 48.1413, lng: 19.494011 },
    { lat: 48.141327, lng: 19.494071 },
    { lat: 48.141354, lng: 19.494175 },
    { lat: 48.14141, lng: 19.494373 },
    { lat: 48.141366, lng: 19.49448 },
    { lat: 48.141202, lng: 19.494619 },
    { lat: 48.140969, lng: 19.494624 },
    { lat: 48.140898, lng: 19.494602 },
    { lat: 48.140725, lng: 19.494612 },
    { lat: 48.140596, lng: 19.494666 },
    { lat: 48.140503, lng: 19.494775 },
    { lat: 48.140476, lng: 19.494902 },
    { lat: 48.140457, lng: 19.495034 },
    { lat: 48.140457, lng: 19.495087 },
    { lat: 48.140483, lng: 19.495267 },
    { lat: 48.140509, lng: 19.495332 },
    { lat: 48.14058, lng: 19.495577 },
    { lat: 48.14062, lng: 19.495694 },
    { lat: 48.140649, lng: 19.495804 },
    { lat: 48.140705, lng: 19.496223 },
    { lat: 48.140768, lng: 19.496636 },
    { lat: 48.140796, lng: 19.496681 },
    { lat: 48.14085, lng: 19.496883 },
    { lat: 48.140846, lng: 19.496918 },
    { lat: 48.140953, lng: 19.497196 },
    { lat: 48.140997, lng: 19.497252 },
    { lat: 48.141039, lng: 19.497258 },
    { lat: 48.141164, lng: 19.497216 },
    { lat: 48.141301, lng: 19.497064 },
    { lat: 48.141366, lng: 19.496957 },
    { lat: 48.141396, lng: 19.496889 },
    { lat: 48.141462, lng: 19.496724 },
    { lat: 48.141498, lng: 19.496569 },
    { lat: 48.141552, lng: 19.496309 },
    { lat: 48.141588, lng: 19.496164 },
    { lat: 48.141646, lng: 19.496003 },
    { lat: 48.141719, lng: 19.495864 },
    { lat: 48.141836, lng: 19.495749 },
    { lat: 48.142, lng: 19.495659 },
    { lat: 48.142087, lng: 19.495619 },
    { lat: 48.142188, lng: 19.495591 },
    { lat: 48.142254, lng: 19.495597 },
    { lat: 48.142315, lng: 19.495616 },
    { lat: 48.142467, lng: 19.49569 },
    { lat: 48.142498, lng: 19.495725 },
    { lat: 48.14262, lng: 19.495893 },
    { lat: 48.14264, lng: 19.495925 },
    { lat: 48.142727, lng: 19.49616 },
    { lat: 48.142818, lng: 19.496604 },
    { lat: 48.142867, lng: 19.496849 },
    { lat: 48.142938, lng: 19.497047 },
    { lat: 48.143014, lng: 19.497144 },
    { lat: 48.143102, lng: 19.497237 },
    { lat: 48.143151, lng: 19.497404 },
    { lat: 48.143199, lng: 19.497612 },
    { lat: 48.143238, lng: 19.497887 },
    { lat: 48.143239, lng: 19.498161 },
    { lat: 48.143253, lng: 19.498299 },
    { lat: 48.14329, lng: 19.498348 },
    { lat: 48.143315, lng: 19.498455 },
    { lat: 48.143311, lng: 19.498562 },
    { lat: 48.143296, lng: 19.49871 },
    { lat: 48.143258, lng: 19.498868 },
    { lat: 48.143154, lng: 19.498987 },
    { lat: 48.143076, lng: 19.499057 },
    { lat: 48.142963, lng: 19.499067 },
    { lat: 48.142912, lng: 19.49906 },
    { lat: 48.142553, lng: 19.498897 },
    { lat: 48.142454, lng: 19.498925 },
    { lat: 48.142291, lng: 19.499002 },
    { lat: 48.142157, lng: 19.499118 },
    { lat: 48.142031, lng: 19.499277 },
    { lat: 48.141917, lng: 19.499514 },
    { lat: 48.141827, lng: 19.499773 },
    { lat: 48.141837, lng: 19.499919 },
    { lat: 48.141901, lng: 19.50009 },
    { lat: 48.142008, lng: 19.500142 },
    { lat: 48.142125, lng: 19.500188 },
    { lat: 48.142259, lng: 19.500211 },
    { lat: 48.142311, lng: 19.500214 },
    { lat: 48.142446, lng: 19.500165 },
    { lat: 48.14261, lng: 19.500127 },
    { lat: 48.142788, lng: 19.500081 },
    { lat: 48.142915, lng: 19.500061 },
    { lat: 48.143068, lng: 19.50004 },
    { lat: 48.143453, lng: 19.500087 },
    { lat: 48.143877, lng: 19.500155 },
    { lat: 48.144048, lng: 19.500092 },
    { lat: 48.144138, lng: 19.499976 },
    { lat: 48.14419, lng: 19.499909 },
    { lat: 48.144257, lng: 19.499874 },
    { lat: 48.144401, lng: 19.499866 },
    { lat: 48.144522, lng: 19.499842 },
    { lat: 48.144542, lng: 19.499849 },
    { lat: 48.144594, lng: 19.499876 },
    { lat: 48.144626, lng: 19.499881 },
    { lat: 48.144789, lng: 19.499912 },
    { lat: 48.144957, lng: 19.499976 },
    { lat: 48.145084, lng: 19.50006 },
    { lat: 48.145109, lng: 19.500084 },
    { lat: 48.145188, lng: 19.500159 },
    { lat: 48.145233, lng: 19.500201 },
    { lat: 48.145313, lng: 19.500331 },
    { lat: 48.145455, lng: 19.500554 },
    { lat: 48.145497, lng: 19.500634 },
    { lat: 48.145638, lng: 19.500895 },
    { lat: 48.145677, lng: 19.500979 },
    { lat: 48.145791, lng: 19.501239 },
    { lat: 48.145836, lng: 19.501358 },
    { lat: 48.145922, lng: 19.50164 },
    { lat: 48.146007, lng: 19.501915 },
    { lat: 48.146037, lng: 19.502006 },
    { lat: 48.146123, lng: 19.5023 },
    { lat: 48.146194, lng: 19.502619 },
    { lat: 48.146207, lng: 19.502681 },
    { lat: 48.146222, lng: 19.502758 },
    { lat: 48.146297, lng: 19.503142 },
    { lat: 48.146348, lng: 19.503371 },
    { lat: 48.146433, lng: 19.503778 },
    { lat: 48.146473, lng: 19.50397 },
    { lat: 48.146491, lng: 19.50405 },
    { lat: 48.146509, lng: 19.50413 },
    { lat: 48.146552, lng: 19.504314 },
    { lat: 48.146584, lng: 19.50443 },
    { lat: 48.146661, lng: 19.504644 },
    { lat: 48.146712, lng: 19.504772 },
    { lat: 48.146819, lng: 19.504978 },
    { lat: 48.146875, lng: 19.505082 },
    { lat: 48.146895, lng: 19.505228 },
    { lat: 48.146905, lng: 19.505295 },
    { lat: 48.146899, lng: 19.505482 },
    { lat: 48.146875, lng: 19.505549 },
    { lat: 48.146783, lng: 19.505887 },
    { lat: 48.146774, lng: 19.50608 },
    { lat: 48.146767, lng: 19.506249 },
    { lat: 48.14678, lng: 19.506387 },
    { lat: 48.146776, lng: 19.506489 },
    { lat: 48.14679, lng: 19.506525 },
    { lat: 48.146836, lng: 19.506662 },
    { lat: 48.146889, lng: 19.506784 },
    { lat: 48.146983, lng: 19.506896 },
    { lat: 48.147118, lng: 19.507057 },
    { lat: 48.147184, lng: 19.507187 },
    { lat: 48.147198, lng: 19.507225 },
    { lat: 48.14723, lng: 19.507487 },
    { lat: 48.147249, lng: 19.507631 },
    { lat: 48.147343, lng: 19.50779 },
    { lat: 48.147353, lng: 19.507805 },
    { lat: 48.147397, lng: 19.507892 },
    { lat: 48.147448, lng: 19.507958 },
    { lat: 48.147585, lng: 19.508151 },
    { lat: 48.1477, lng: 19.508303 },
    { lat: 48.147828, lng: 19.508466 },
    { lat: 48.147881, lng: 19.508531 },
    { lat: 48.148095, lng: 19.508728 },
    { lat: 48.14816, lng: 19.508785 },
    { lat: 48.148178, lng: 19.508801 },
    { lat: 48.148202, lng: 19.508826 },
    { lat: 48.148258, lng: 19.508861 },
    { lat: 48.148335, lng: 19.508909 },
    { lat: 48.148472, lng: 19.508972 },
    { lat: 48.148621, lng: 19.509018 },
    { lat: 48.148763, lng: 19.509067 },
    { lat: 48.149025, lng: 19.509118 },
    { lat: 48.149061, lng: 19.509126 },
    { lat: 48.149275, lng: 19.50917 },
    { lat: 48.149368, lng: 19.509195 },
    { lat: 48.149414, lng: 19.509202 },
    { lat: 48.149637, lng: 19.509213 },
    { lat: 48.149677, lng: 19.509208 },
    { lat: 48.149783, lng: 19.509197 },
    { lat: 48.149825, lng: 19.509221 },
    { lat: 48.149973, lng: 19.509263 },
    { lat: 48.150037, lng: 19.50932 },
    { lat: 48.150086, lng: 19.509339 },
    { lat: 48.150126, lng: 19.509363 },
    { lat: 48.150168, lng: 19.509388 },
    { lat: 48.150335, lng: 19.509472 },
    { lat: 48.150422, lng: 19.509479 },
    { lat: 48.150474, lng: 19.509482 },
    { lat: 48.150518, lng: 19.509526 },
    { lat: 48.150537, lng: 19.509546 },
    { lat: 48.150595, lng: 19.509565 },
    { lat: 48.150725, lng: 19.509608 },
    { lat: 48.150781, lng: 19.509614 },
    { lat: 48.150899, lng: 19.509588 },
    { lat: 48.150993, lng: 19.509569 },
    { lat: 48.151116, lng: 19.509513 },
    { lat: 48.151233, lng: 19.509468 },
    { lat: 48.151244, lng: 19.509455 },
    { lat: 48.151322, lng: 19.509422 },
    { lat: 48.151383, lng: 19.509396 },
    { lat: 48.151516, lng: 19.509388 },
    { lat: 48.151647, lng: 19.509407 },
    { lat: 48.151751, lng: 19.50946 },
    { lat: 48.151779, lng: 19.509489 },
    { lat: 48.151867, lng: 19.509592 },
    { lat: 48.15192, lng: 19.509661 },
    { lat: 48.151965, lng: 19.509722 },
    { lat: 48.152132, lng: 19.509941 },
    { lat: 48.15217, lng: 19.509998 },
    { lat: 48.15231, lng: 19.510196 },
    { lat: 48.152652, lng: 19.5098303 },
    { lat: 48.1529379, lng: 19.5097731 },
    { lat: 48.1539762, lng: 19.5046785 },
    { lat: 48.1538815, lng: 19.5017062 },
    { lat: 48.1539192, lng: 19.5013754 },
    { lat: 48.1541864, lng: 19.5005835 },
    { lat: 48.1551411, lng: 19.4987991 },
    { lat: 48.1553206, lng: 19.4983695 },
    { lat: 48.1557501, lng: 19.4969469 },
    { lat: 48.1560692, lng: 19.4936725 },
    { lat: 48.1561401, lng: 19.4935267 },
    { lat: 48.1562902, lng: 19.4913369 },
    { lat: 48.1564259, lng: 19.4907185 },
    { lat: 48.1565773, lng: 19.4901475 },
    { lat: 48.1574063, lng: 19.4879425 },
    { lat: 48.157789, lng: 19.4871376 },
    { lat: 48.1582525, lng: 19.4863723 },
    { lat: 48.158484, lng: 19.4857325 },
    { lat: 48.1586774, lng: 19.4849556 },
    { lat: 48.1586752, lng: 19.4845696 },
    { lat: 48.1584388, lng: 19.4832746 },
    { lat: 48.1582688, lng: 19.481404 },
    { lat: 48.1582659, lng: 19.4811442 },
    { lat: 48.1583967, lng: 19.4805234 },
    { lat: 48.1586098, lng: 19.4799717 },
    { lat: 48.1593436, lng: 19.4788173 },
    { lat: 48.1597059, lng: 19.4775337 },
    { lat: 48.160856, lng: 19.4752107 },
    { lat: 48.1613533, lng: 19.4744219 },
    { lat: 48.1615604, lng: 19.4739635 },
    { lat: 48.1616201, lng: 19.4735291 },
    { lat: 48.161655, lng: 19.4735046 },
    { lat: 48.1616518, lng: 19.4734093 },
    { lat: 48.1614575, lng: 19.4720311 },
    { lat: 48.1610111, lng: 19.4709229 },
    { lat: 48.1607027, lng: 19.4698518 },
    { lat: 48.1601164, lng: 19.468118 },
    { lat: 48.1600541, lng: 19.4680628 },
  ],
  Vieska: [
    { lat: 48.2720463, lng: 19.469198 },
    { lat: 48.2720161, lng: 19.4691537 },
    { lat: 48.2709511, lng: 19.4676126 },
    { lat: 48.2705056, lng: 19.4668843 },
    { lat: 48.2704198, lng: 19.4667614 },
    { lat: 48.2684101, lng: 19.4646175 },
    { lat: 48.2677015, lng: 19.4637331 },
    { lat: 48.267472, lng: 19.4633561 },
    { lat: 48.2676039, lng: 19.4623719 },
    { lat: 48.2670404, lng: 19.4617974 },
    { lat: 48.266387, lng: 19.4608863 },
    { lat: 48.2656197, lng: 19.4600757 },
    { lat: 48.2627447, lng: 19.4580092 },
    { lat: 48.2627036, lng: 19.4577125 },
    { lat: 48.2626927, lng: 19.4576964 },
    { lat: 48.2625761, lng: 19.4577083 },
    { lat: 48.2624111, lng: 19.4579163 },
    { lat: 48.2621767, lng: 19.4583962 },
    { lat: 48.2622144, lng: 19.4585754 },
    { lat: 48.2623127, lng: 19.4585566 },
    { lat: 48.2625443, lng: 19.4587122 },
    { lat: 48.2626357, lng: 19.4591698 },
    { lat: 48.2626099, lng: 19.4593646 },
    { lat: 48.26226, lng: 19.4598086 },
    { lat: 48.2622138, lng: 19.4601936 },
    { lat: 48.2624239, lng: 19.4605196 },
    { lat: 48.2624201, lng: 19.4607 },
    { lat: 48.2620657, lng: 19.4615776 },
    { lat: 48.26172, lng: 19.4614878 },
    { lat: 48.2610252, lng: 19.4617391 },
    { lat: 48.2609977, lng: 19.461585 },
    { lat: 48.2607838, lng: 19.4613748 },
    { lat: 48.2593599, lng: 19.4601859 },
    { lat: 48.2581904, lng: 19.4594252 },
    { lat: 48.2573572, lng: 19.4589619 },
    { lat: 48.2560881, lng: 19.4573997 },
    { lat: 48.2557587, lng: 19.4568162 },
    { lat: 48.2554749, lng: 19.4564366 },
    { lat: 48.2551692, lng: 19.4557608 },
    { lat: 48.2541017, lng: 19.4547942 },
    { lat: 48.2518646, lng: 19.4529721 },
    { lat: 48.2513444, lng: 19.452093 },
    { lat: 48.2511885, lng: 19.4518269 },
    { lat: 48.2508585, lng: 19.4497191 },
    { lat: 48.2504246, lng: 19.4487136 },
    { lat: 48.2503114, lng: 19.4483907 },
    { lat: 48.2503514, lng: 19.4483525 },
    { lat: 48.2491416, lng: 19.4455395 },
    { lat: 48.2484518, lng: 19.4437686 },
    { lat: 48.2484475, lng: 19.4437736 },
    { lat: 48.2472759, lng: 19.4450514 },
    { lat: 48.2468699, lng: 19.4461257 },
    { lat: 48.2441549, lng: 19.4480622 },
    { lat: 48.241805, lng: 19.4500498 },
    { lat: 48.2418538, lng: 19.4502985 },
    { lat: 48.2424165, lng: 19.4511917 },
    { lat: 48.2425784, lng: 19.4512528 },
    { lat: 48.242892, lng: 19.4510935 },
    { lat: 48.2435458, lng: 19.4502807 },
    { lat: 48.2441023, lng: 19.4513075 },
    { lat: 48.2440348, lng: 19.4526744 },
    { lat: 48.2444372, lng: 19.4569474 },
    { lat: 48.2443956, lng: 19.4584841 },
    { lat: 48.2446376, lng: 19.4589565 },
    { lat: 48.2450484, lng: 19.4585855 },
    { lat: 48.2453468, lng: 19.4598768 },
    { lat: 48.2452726, lng: 19.4599256 },
    { lat: 48.2453836, lng: 19.461214 },
    { lat: 48.245326, lng: 19.4623614 },
    { lat: 48.2453877, lng: 19.46274 },
    { lat: 48.245677, lng: 19.4636228 },
    { lat: 48.245869, lng: 19.4636773 },
    { lat: 48.2462387, lng: 19.4631508 },
    { lat: 48.246426, lng: 19.4632266 },
    { lat: 48.2466163, lng: 19.4630086 },
    { lat: 48.2467244, lng: 19.4629841 },
    { lat: 48.2468198, lng: 19.463049 },
    { lat: 48.2470216, lng: 19.4633637 },
    { lat: 48.2471973, lng: 19.4634782 },
    { lat: 48.2471894, lng: 19.463647 },
    { lat: 48.2472979, lng: 19.4636992 },
    { lat: 48.2474885, lng: 19.4641123 },
    { lat: 48.2477421, lng: 19.464278 },
    { lat: 48.2478816, lng: 19.464443 },
    { lat: 48.2478257, lng: 19.4648371 },
    { lat: 48.2480945, lng: 19.4648746 },
    { lat: 48.2483992, lng: 19.4654589 },
    { lat: 48.2487139, lng: 19.4655885 },
    { lat: 48.2488047, lng: 19.4658632 },
    { lat: 48.2489598, lng: 19.4657811 },
    { lat: 48.249099, lng: 19.465793 },
    { lat: 48.2491851, lng: 19.4659258 },
    { lat: 48.2491779, lng: 19.4662518 },
    { lat: 48.2494044, lng: 19.466181 },
    { lat: 48.2495144, lng: 19.4664124 },
    { lat: 48.249708, lng: 19.4665975 },
    { lat: 48.2497105, lng: 19.4669374 },
    { lat: 48.2498631, lng: 19.4674277 },
    { lat: 48.2498613, lng: 19.4679173 },
    { lat: 48.2499699, lng: 19.4680494 },
    { lat: 48.24989, lng: 19.4681218 },
    { lat: 48.2500384, lng: 19.4691016 },
    { lat: 48.2500583, lng: 19.4697686 },
    { lat: 48.2502488, lng: 19.4701297 },
    { lat: 48.2502496, lng: 19.4697845 },
    { lat: 48.2504713, lng: 19.4698486 },
    { lat: 48.2505561, lng: 19.4697124 },
    { lat: 48.2507651, lng: 19.4696968 },
    { lat: 48.2508326, lng: 19.4695041 },
    { lat: 48.2510219, lng: 19.4696239 },
    { lat: 48.2512102, lng: 19.4697922 },
    { lat: 48.251298, lng: 19.4701539 },
    { lat: 48.2516608, lng: 19.4704617 },
    { lat: 48.2519852, lng: 19.4703855 },
    { lat: 48.2531894, lng: 19.4711919 },
    { lat: 48.2532239, lng: 19.4711126 },
    { lat: 48.2535659, lng: 19.4717026 },
    { lat: 48.2538631, lng: 19.471983 },
    { lat: 48.2542817, lng: 19.4721621 },
    { lat: 48.2550579, lng: 19.4726599 },
    { lat: 48.2551151, lng: 19.472586 },
    { lat: 48.2555893, lng: 19.4727456 },
    { lat: 48.2557242, lng: 19.4732209 },
    { lat: 48.2558004, lng: 19.4733306 },
    { lat: 48.2558302, lng: 19.4736288 },
    { lat: 48.2559692, lng: 19.4739244 },
    { lat: 48.2560286, lng: 19.4739217 },
    { lat: 48.2562329, lng: 19.4735391 },
    { lat: 48.2562886, lng: 19.4735994 },
    { lat: 48.2566458, lng: 19.4734042 },
    { lat: 48.2567619, lng: 19.4731402 },
    { lat: 48.2571059, lng: 19.4728817 },
    { lat: 48.2571437, lng: 19.4727256 },
    { lat: 48.2570954, lng: 19.4724913 },
    { lat: 48.257127, lng: 19.4718652 },
    { lat: 48.2572392, lng: 19.4716847 },
    { lat: 48.2572299, lng: 19.471587 },
    { lat: 48.2575777, lng: 19.4719381 },
    { lat: 48.2577545, lng: 19.4725108 },
    { lat: 48.257894, lng: 19.4726953 },
    { lat: 48.2581457, lng: 19.4725836 },
    { lat: 48.2582852, lng: 19.4726991 },
    { lat: 48.2583738, lng: 19.4726188 },
    { lat: 48.2585491, lng: 19.4721003 },
    { lat: 48.258607, lng: 19.4720418 },
    { lat: 48.2587379, lng: 19.4720674 },
    { lat: 48.2587128, lng: 19.4722033 },
    { lat: 48.2587895, lng: 19.4723017 },
    { lat: 48.2590774, lng: 19.4711625 },
    { lat: 48.2594114, lng: 19.4714824 },
    { lat: 48.2601722, lng: 19.4720847 },
    { lat: 48.2619829, lng: 19.4739778 },
    { lat: 48.2617417, lng: 19.4742635 },
    { lat: 48.2622147, lng: 19.4746407 },
    { lat: 48.26206, lng: 19.4749753 },
    { lat: 48.262356, lng: 19.4753746 },
    { lat: 48.2623584, lng: 19.4755215 },
    { lat: 48.2625046, lng: 19.4758061 },
    { lat: 48.2626001, lng: 19.4758933 },
    { lat: 48.2631211, lng: 19.4760254 },
    { lat: 48.2635148, lng: 19.4764641 },
    { lat: 48.2645457, lng: 19.4762445 },
    { lat: 48.2645964, lng: 19.4766258 },
    { lat: 48.2657349, lng: 19.4764136 },
    { lat: 48.2658667, lng: 19.4765593 },
    { lat: 48.2660397, lng: 19.476497 },
    { lat: 48.2662373, lng: 19.4763135 },
    { lat: 48.26652, lng: 19.4762389 },
    { lat: 48.266668, lng: 19.4759549 },
    { lat: 48.2666824, lng: 19.4757125 },
    { lat: 48.2667882, lng: 19.4754658 },
    { lat: 48.2672528, lng: 19.4745834 },
    { lat: 48.2677116, lng: 19.4739317 },
    { lat: 48.267875, lng: 19.4735729 },
    { lat: 48.2681527, lng: 19.4732183 },
    { lat: 48.2688404, lng: 19.4725776 },
    { lat: 48.2694768, lng: 19.471774 },
    { lat: 48.2719823, lng: 19.469274 },
    { lat: 48.2720463, lng: 19.469198 },
  ],
  Chrastince: [
    { lat: 48.1083182, lng: 19.2191594 },
    { lat: 48.1082865, lng: 19.2191117 },
    { lat: 48.106248, lng: 19.2159198 },
    { lat: 48.1062856, lng: 19.2157001 },
    { lat: 48.104593, lng: 19.2108344 },
    { lat: 48.1043538, lng: 19.2104177 },
    { lat: 48.104359, lng: 19.210318 },
    { lat: 48.1043233, lng: 19.2103452 },
    { lat: 48.1026172, lng: 19.2116378 },
    { lat: 48.1024454, lng: 19.2119057 },
    { lat: 48.1020785, lng: 19.2135633 },
    { lat: 48.1012134, lng: 19.2170607 },
    { lat: 48.0978264, lng: 19.2222481 },
    { lat: 48.0971203, lng: 19.2251254 },
    { lat: 48.0968483, lng: 19.2259214 },
    { lat: 48.0948957, lng: 19.2277326 },
    { lat: 48.0940948, lng: 19.2323823 },
    { lat: 48.0926215, lng: 19.2357806 },
    { lat: 48.0926017, lng: 19.2373754 },
    { lat: 48.0924538, lng: 19.2379712 },
    { lat: 48.0916317, lng: 19.2395691 },
    { lat: 48.0914904, lng: 19.2398133 },
    { lat: 48.0909957, lng: 19.2400493 },
    { lat: 48.090051, lng: 19.2403693 },
    { lat: 48.0881299, lng: 19.2403411 },
    { lat: 48.0866081, lng: 19.2434232 },
    { lat: 48.0857362, lng: 19.2453234 },
    { lat: 48.0857355, lng: 19.2454677 },
    { lat: 48.0857024, lng: 19.2454614 },
    { lat: 48.0851494, lng: 19.2475894 },
    { lat: 48.084506, lng: 19.2492068 },
    { lat: 48.0834307, lng: 19.2494611 },
    { lat: 48.083379, lng: 19.2495531 },
    { lat: 48.0832728, lng: 19.2496412 },
    { lat: 48.0831897, lng: 19.249878 },
    { lat: 48.0830759, lng: 19.2500392 },
    { lat: 48.0829524, lng: 19.2502141 },
    { lat: 48.0827993, lng: 19.250793 },
    { lat: 48.0827969, lng: 19.2511339 },
    { lat: 48.082688, lng: 19.2514425 },
    { lat: 48.0826575, lng: 19.2518576 },
    { lat: 48.0822434, lng: 19.2515971 },
    { lat: 48.0820387, lng: 19.2513035 },
    { lat: 48.0820419, lng: 19.2505876 },
    { lat: 48.0815959, lng: 19.2508963 },
    { lat: 48.0815767, lng: 19.2514681 },
    { lat: 48.0818586, lng: 19.2517952 },
    { lat: 48.0809964, lng: 19.2550007 },
    { lat: 48.0814637, lng: 19.2551754 },
    { lat: 48.0819163, lng: 19.2556503 },
    { lat: 48.0824622, lng: 19.255998 },
    { lat: 48.082407, lng: 19.2568793 },
    { lat: 48.0822033, lng: 19.2575741 },
    { lat: 48.0823319, lng: 19.2577833 },
    { lat: 48.0822394, lng: 19.2587751 },
    { lat: 48.08228, lng: 19.2591662 },
    { lat: 48.082024, lng: 19.2601842 },
    { lat: 48.0818102, lng: 19.2606284 },
    { lat: 48.0818129, lng: 19.2609834 },
    { lat: 48.0818531, lng: 19.2613421 },
    { lat: 48.0833899, lng: 19.2623002 },
    { lat: 48.0862447, lng: 19.2639498 },
    { lat: 48.0924148, lng: 19.2610826 },
    { lat: 48.0930561, lng: 19.2598588 },
    { lat: 48.0937974, lng: 19.2588124 },
    { lat: 48.0967819, lng: 19.2526923 },
    { lat: 48.0968672, lng: 19.2518096 },
    { lat: 48.0968276, lng: 19.2510681 },
    { lat: 48.0958415, lng: 19.2474549 },
    { lat: 48.0974994, lng: 19.2468304 },
    { lat: 48.0984036, lng: 19.242344 },
    { lat: 48.0987199, lng: 19.240633 },
    { lat: 48.09891, lng: 19.2393354 },
    { lat: 48.0996184, lng: 19.2365853 },
    { lat: 48.1001829, lng: 19.2353652 },
    { lat: 48.1011124, lng: 19.2338152 },
    { lat: 48.1025769, lng: 19.2309452 },
    { lat: 48.1043143, lng: 19.2272128 },
    { lat: 48.1056222, lng: 19.2246462 },
    { lat: 48.1067296, lng: 19.2222243 },
    { lat: 48.1075774, lng: 19.2200046 },
    { lat: 48.1081365, lng: 19.219246 },
    { lat: 48.1082823, lng: 19.2191647 },
    { lat: 48.1083182, lng: 19.2191594 },
  ],
  Bátorová: [
    { lat: 48.13119, lng: 19.2278754 },
    { lat: 48.1313928, lng: 19.2281704 },
    { lat: 48.134207, lng: 19.2322474 },
    { lat: 48.1348157, lng: 19.2330506 },
    { lat: 48.1327183, lng: 19.2419632 },
    { lat: 48.1323644, lng: 19.2432549 },
    { lat: 48.1326445, lng: 19.2437458 },
    { lat: 48.1334594, lng: 19.2455888 },
    { lat: 48.1322791, lng: 19.2473624 },
    { lat: 48.1327972, lng: 19.247953 },
    { lat: 48.1328663, lng: 19.2480958 },
    { lat: 48.1326917, lng: 19.2482595 },
    { lat: 48.1326297, lng: 19.2486964 },
    { lat: 48.1323493, lng: 19.2494408 },
    { lat: 48.1321749, lng: 19.2496801 },
    { lat: 48.1319536, lng: 19.2506605 },
    { lat: 48.131725, lng: 19.2510146 },
    { lat: 48.1317312, lng: 19.2513449 },
    { lat: 48.1313338, lng: 19.252281 },
    { lat: 48.1311741, lng: 19.2529801 },
    { lat: 48.1309526, lng: 19.2534743 },
    { lat: 48.1308512, lng: 19.2538706 },
    { lat: 48.1307985, lng: 19.2539897 },
    { lat: 48.1304576, lng: 19.2542124 },
    { lat: 48.1302904, lng: 19.2546177 },
    { lat: 48.1299779, lng: 19.2551461 },
    { lat: 48.1299133, lng: 19.2556228 },
    { lat: 48.1295996, lng: 19.2566763 },
    { lat: 48.1295148, lng: 19.2571298 },
    { lat: 48.1292766, lng: 19.2577478 },
    { lat: 48.1292419, lng: 19.2582131 },
    { lat: 48.1289418, lng: 19.2586699 },
    { lat: 48.1286229, lng: 19.2589986 },
    { lat: 48.1272287, lng: 19.2615659 },
    { lat: 48.127024, lng: 19.2617808 },
    { lat: 48.1263577, lng: 19.2621694 },
    { lat: 48.1257539, lng: 19.2626427 },
    { lat: 48.1253204, lng: 19.2627668 },
    { lat: 48.1256186, lng: 19.2636802 },
    { lat: 48.1247139, lng: 19.2642468 },
    { lat: 48.1259369, lng: 19.2679894 },
    { lat: 48.1248687, lng: 19.2686658 },
    { lat: 48.1245733, lng: 19.2676021 },
    { lat: 48.1236852, lng: 19.2681841 },
    { lat: 48.1229972, lng: 19.2685246 },
    { lat: 48.1230317, lng: 19.2696932 },
    { lat: 48.1230052, lng: 19.2699342 },
    { lat: 48.1226188, lng: 19.2712339 },
    { lat: 48.1224893, lng: 19.2714705 },
    { lat: 48.1226542, lng: 19.2722422 },
    { lat: 48.1228409, lng: 19.2727391 },
    { lat: 48.1231247, lng: 19.2732903 },
    { lat: 48.1232135, lng: 19.2737023 },
    { lat: 48.1240358, lng: 19.2735519 },
    { lat: 48.1240701, lng: 19.2736805 },
    { lat: 48.127869, lng: 19.2745627 },
    { lat: 48.1284383, lng: 19.2759093 },
    { lat: 48.1290214, lng: 19.2770728 },
    { lat: 48.1296401, lng: 19.2778111 },
    { lat: 48.1303197, lng: 19.2784088 },
    { lat: 48.130301, lng: 19.2787432 },
    { lat: 48.1303562, lng: 19.2789517 },
    { lat: 48.1303007, lng: 19.2798737 },
    { lat: 48.1305406, lng: 19.2810279 },
    { lat: 48.130535, lng: 19.2814234 },
    { lat: 48.1304177, lng: 19.2817706 },
    { lat: 48.1303388, lng: 19.2817943 },
    { lat: 48.1302775, lng: 19.2820402 },
    { lat: 48.1301262, lng: 19.2823579 },
    { lat: 48.1301518, lng: 19.2826554 },
    { lat: 48.130225, lng: 19.2828401 },
    { lat: 48.1302367, lng: 19.2832171 },
    { lat: 48.1301812, lng: 19.2837957 },
    { lat: 48.1298882, lng: 19.2852558 },
    { lat: 48.1296165, lng: 19.285951 },
    { lat: 48.1293553, lng: 19.2883479 },
    { lat: 48.1286694, lng: 19.2925636 },
    { lat: 48.1282113, lng: 19.295084 },
    { lat: 48.1295861, lng: 19.2963808 },
    { lat: 48.1308779, lng: 19.297404 },
    { lat: 48.13123, lng: 19.297784 },
    { lat: 48.1326197, lng: 19.300901 },
    { lat: 48.133341, lng: 19.3028301 },
    { lat: 48.1335534, lng: 19.3030273 },
    { lat: 48.1338923, lng: 19.303484 },
    { lat: 48.1340591, lng: 19.3035975 },
    { lat: 48.1345001, lng: 19.3036025 },
    { lat: 48.1346954, lng: 19.3038901 },
    { lat: 48.135202, lng: 19.3041339 },
    { lat: 48.1362132, lng: 19.3050476 },
    { lat: 48.136789, lng: 19.30567 },
    { lat: 48.1373149, lng: 19.3066143 },
    { lat: 48.1375934, lng: 19.3074801 },
    { lat: 48.1377366, lng: 19.308204 },
    { lat: 48.1380231, lng: 19.3088128 },
    { lat: 48.1386637, lng: 19.3096841 },
    { lat: 48.1402745, lng: 19.3110107 },
    { lat: 48.1415243, lng: 19.3123602 },
    { lat: 48.1422322, lng: 19.3125912 },
    { lat: 48.1426725, lng: 19.3128415 },
    { lat: 48.1428666, lng: 19.3132398 },
    { lat: 48.1430505, lng: 19.3133604 },
    { lat: 48.1431133, lng: 19.3134034 },
    { lat: 48.144384, lng: 19.312666 },
    { lat: 48.1448648, lng: 19.3120925 },
    { lat: 48.145366, lng: 19.3118291 },
    { lat: 48.1457533, lng: 19.311446 },
    { lat: 48.1465867, lng: 19.3108962 },
    { lat: 48.1472315, lng: 19.3102951 },
    { lat: 48.1485821, lng: 19.3094783 },
    { lat: 48.1489912, lng: 19.3092248 },
    { lat: 48.148959, lng: 19.3091648 },
    { lat: 48.1488176, lng: 19.3082433 },
    { lat: 48.1487341, lng: 19.3073085 },
    { lat: 48.1487778, lng: 19.3069092 },
    { lat: 48.1487591, lng: 19.3064531 },
    { lat: 48.1483382, lng: 19.3053694 },
    { lat: 48.1479411, lng: 19.3040362 },
    { lat: 48.1476879, lng: 19.3029295 },
    { lat: 48.1474435, lng: 19.3022324 },
    { lat: 48.1463739, lng: 19.2996512 },
    { lat: 48.1452577, lng: 19.2975996 },
    { lat: 48.1440522, lng: 19.2958655 },
    { lat: 48.1443993, lng: 19.2945634 },
    { lat: 48.1436199, lng: 19.2926026 },
    { lat: 48.1430754, lng: 19.2908334 },
    { lat: 48.1427116, lng: 19.2898388 },
    { lat: 48.1426135, lng: 19.2893747 },
    { lat: 48.1426466, lng: 19.289195 },
    { lat: 48.1428881, lng: 19.28931 },
    { lat: 48.1431277, lng: 19.2881011 },
    { lat: 48.1408578, lng: 19.2846472 },
    { lat: 48.1399906, lng: 19.2828747 },
    { lat: 48.1394949, lng: 19.2810747 },
    { lat: 48.1397197, lng: 19.2808986 },
    { lat: 48.1396169, lng: 19.2806401 },
    { lat: 48.1397839, lng: 19.2805271 },
    { lat: 48.1404166, lng: 19.2803271 },
    { lat: 48.1398434, lng: 19.2786915 },
    { lat: 48.1398229, lng: 19.2786376 },
    { lat: 48.1396286, lng: 19.2787222 },
    { lat: 48.1396434, lng: 19.2787743 },
    { lat: 48.1393862, lng: 19.2788142 },
    { lat: 48.1388962, lng: 19.2787968 },
    { lat: 48.1381941, lng: 19.2785463 },
    { lat: 48.138133, lng: 19.2773594 },
    { lat: 48.1380177, lng: 19.2765423 },
    { lat: 48.1377473, lng: 19.2752236 },
    { lat: 48.1378036, lng: 19.2740656 },
    { lat: 48.1377727, lng: 19.2734306 },
    { lat: 48.1377831, lng: 19.2730434 },
    { lat: 48.1379176, lng: 19.2724716 },
    { lat: 48.1383734, lng: 19.271321 },
    { lat: 48.1383578, lng: 19.271142 },
    { lat: 48.1378043, lng: 19.2696548 },
    { lat: 48.1377481, lng: 19.2696171 },
    { lat: 48.1377777, lng: 19.269435 },
    { lat: 48.1373776, lng: 19.2684653 },
    { lat: 48.1368455, lng: 19.2675178 },
    { lat: 48.1365312, lng: 19.2677264 },
    { lat: 48.1362516, lng: 19.2677479 },
    { lat: 48.136175, lng: 19.2678674 },
    { lat: 48.1361166, lng: 19.2682214 },
    { lat: 48.1360036, lng: 19.2684214 },
    { lat: 48.1357928, lng: 19.2685805 },
    { lat: 48.1354621, lng: 19.2684581 },
    { lat: 48.1354092, lng: 19.2681586 },
    { lat: 48.1352901, lng: 19.268006 },
    { lat: 48.1351647, lng: 19.267954 },
    { lat: 48.1351369, lng: 19.2678674 },
    { lat: 48.1345962, lng: 19.2682466 },
    { lat: 48.1342285, lng: 19.2677538 },
    { lat: 48.1338302, lng: 19.2669125 },
    { lat: 48.1339837, lng: 19.2656473 },
    { lat: 48.1337209, lng: 19.2649709 },
    { lat: 48.133644, lng: 19.2649454 },
    { lat: 48.1334576, lng: 19.2645172 },
    { lat: 48.1330018, lng: 19.2637489 },
    { lat: 48.132122, lng: 19.2624914 },
    { lat: 48.1325683, lng: 19.2615889 },
    { lat: 48.1337885, lng: 19.2588473 },
    { lat: 48.1343052, lng: 19.2569208 },
    { lat: 48.1347592, lng: 19.255711 },
    { lat: 48.1348894, lng: 19.2552621 },
    { lat: 48.1356769, lng: 19.2509162 },
    { lat: 48.1358457, lng: 19.2501981 },
    { lat: 48.136176, lng: 19.2491129 },
    { lat: 48.1362933, lng: 19.2484924 },
    { lat: 48.1361045, lng: 19.247767 },
    { lat: 48.1357105, lng: 19.247154 },
    { lat: 48.1355302, lng: 19.2461429 },
    { lat: 48.1358233, lng: 19.2449887 },
    { lat: 48.1357725, lng: 19.244989 },
    { lat: 48.1356789, lng: 19.2446682 },
    { lat: 48.1355622, lng: 19.2439868 },
    { lat: 48.1355268, lng: 19.2439783 },
    { lat: 48.1352308, lng: 19.2431101 },
    { lat: 48.1352683, lng: 19.2430943 },
    { lat: 48.1352576, lng: 19.2429907 },
    { lat: 48.1353603, lng: 19.2426231 },
    { lat: 48.1353689, lng: 19.2421577 },
    { lat: 48.1355061, lng: 19.2407675 },
    { lat: 48.1354962, lng: 19.2390949 },
    { lat: 48.1355761, lng: 19.2388585 },
    { lat: 48.1356202, lng: 19.2381257 },
    { lat: 48.1355969, lng: 19.2375566 },
    { lat: 48.1356274, lng: 19.2375815 },
    { lat: 48.1357555, lng: 19.2369767 },
    { lat: 48.1360807, lng: 19.2359527 },
    { lat: 48.1364338, lng: 19.2349937 },
    { lat: 48.1376309, lng: 19.2321164 },
    { lat: 48.1370033, lng: 19.2312753 },
    { lat: 48.1364354, lng: 19.2302679 },
    { lat: 48.1358038, lng: 19.2288817 },
    { lat: 48.1346852, lng: 19.2270369 },
    { lat: 48.1332462, lng: 19.2248183 },
    { lat: 48.1315899, lng: 19.2272673 },
    { lat: 48.13119, lng: 19.2278754 },
  ],
  KosihynadIpľom: [
    { lat: 48.0996667, lng: 19.1863798 },
    { lat: 48.1006482, lng: 19.1857115 },
    { lat: 48.1010097, lng: 19.1853401 },
    { lat: 48.1026122, lng: 19.1830307 },
    { lat: 48.1032498, lng: 19.1820853 },
    { lat: 48.1016426, lng: 19.1799086 },
    { lat: 48.1011231, lng: 19.1787528 },
    { lat: 48.1004902, lng: 19.1776278 },
    { lat: 48.0998697, lng: 19.1762092 },
    { lat: 48.0989674, lng: 19.1744049 },
    { lat: 48.0987367, lng: 19.1739603 },
    { lat: 48.098177, lng: 19.1731712 },
    { lat: 48.0976915, lng: 19.1717282 },
    { lat: 48.0971317, lng: 19.1717147 },
    { lat: 48.0969618, lng: 19.171652 },
    { lat: 48.0964993, lng: 19.1716915 },
    { lat: 48.0964666, lng: 19.1715318 },
    { lat: 48.0951468, lng: 19.1717881 },
    { lat: 48.0938662, lng: 19.1719459 },
    { lat: 48.0930631, lng: 19.171961 },
    { lat: 48.0923013, lng: 19.1716239 },
    { lat: 48.0920212, lng: 19.1715692 },
    { lat: 48.0907712, lng: 19.1715702 },
    { lat: 48.0904117, lng: 19.1716202 },
    { lat: 48.090061, lng: 19.1717549 },
    { lat: 48.0894397, lng: 19.1720915 },
    { lat: 48.0888887, lng: 19.1702153 },
    { lat: 48.0874801, lng: 19.1709708 },
    { lat: 48.0864389, lng: 19.1675285 },
    { lat: 48.0848565, lng: 19.1684012 },
    { lat: 48.0851449, lng: 19.166989 },
    { lat: 48.0848351, lng: 19.1665625 },
    { lat: 48.0847711, lng: 19.1660443 },
    { lat: 48.0845444, lng: 19.1659079 },
    { lat: 48.0844964, lng: 19.166007 },
    { lat: 48.0843465, lng: 19.1659192 },
    { lat: 48.0840028, lng: 19.1652863 },
    { lat: 48.0834447, lng: 19.1640352 },
    { lat: 48.082989, lng: 19.1636439 },
    { lat: 48.0828989, lng: 19.163443 },
    { lat: 48.0828936, lng: 19.1633601 },
    { lat: 48.0834092, lng: 19.1628016 },
    { lat: 48.0836086, lng: 19.161344 },
    { lat: 48.0835954, lng: 19.1610796 },
    { lat: 48.0836905, lng: 19.1607006 },
    { lat: 48.0839048, lng: 19.1602986 },
    { lat: 48.0837617, lng: 19.1601202 },
    { lat: 48.0820426, lng: 19.1590545 },
    { lat: 48.0808639, lng: 19.1585865 },
    { lat: 48.0788571, lng: 19.1585849 },
    { lat: 48.0773491, lng: 19.1583646 },
    { lat: 48.0768796, lng: 19.1581057 },
    { lat: 48.0767011, lng: 19.1581216 },
    { lat: 48.0762711, lng: 19.1583585 },
    { lat: 48.074322, lng: 19.1602636 },
    { lat: 48.0739694, lng: 19.1605399 },
    { lat: 48.0736526, lng: 19.1607018 },
    { lat: 48.0732409, lng: 19.161377 },
    { lat: 48.0729479, lng: 19.1620351 },
    { lat: 48.0714276, lng: 19.1628357 },
    { lat: 48.0709164, lng: 19.1630054 },
    { lat: 48.0694407, lng: 19.1631455 },
    { lat: 48.0679368, lng: 19.1635283 },
    { lat: 48.0677045, lng: 19.1631413 },
    { lat: 48.067619, lng: 19.1630997 },
    { lat: 48.0671954, lng: 19.1633305 },
    { lat: 48.0667242, lng: 19.1626954 },
    { lat: 48.0666442, lng: 19.1626986 },
    { lat: 48.0658015, lng: 19.1634132 },
    { lat: 48.065685, lng: 19.163446 },
    { lat: 48.065686, lng: 19.163483 },
    { lat: 48.065765, lng: 19.164195 },
    { lat: 48.065772, lng: 19.164639 },
    { lat: 48.065708, lng: 19.165639 },
    { lat: 48.065647, lng: 19.166223 },
    { lat: 48.065553, lng: 19.16669 },
    { lat: 48.06552, lng: 19.166861 },
    { lat: 48.065412, lng: 19.167284 },
    { lat: 48.065312, lng: 19.167721 },
    { lat: 48.065298, lng: 19.167756 },
    { lat: 48.065148, lng: 19.168116 },
    { lat: 48.064827, lng: 19.168956 },
    { lat: 48.064728, lng: 19.169123 },
    { lat: 48.06455, lng: 19.169426 },
    { lat: 48.064383, lng: 19.1697 },
    { lat: 48.064286, lng: 19.169859 },
    { lat: 48.064077, lng: 19.17031 },
    { lat: 48.06388, lng: 19.170864 },
    { lat: 48.063792, lng: 19.171353 },
    { lat: 48.063781, lng: 19.171409 },
    { lat: 48.06373, lng: 19.172042 },
    { lat: 48.063739, lng: 19.172507 },
    { lat: 48.063741, lng: 19.17257 },
    { lat: 48.063743, lng: 19.17265 },
    { lat: 48.063728, lng: 19.172914 },
    { lat: 48.063707, lng: 19.173305 },
    { lat: 48.063675, lng: 19.173723 },
    { lat: 48.063661, lng: 19.173857 },
    { lat: 48.063608, lng: 19.174355 },
    { lat: 48.063508, lng: 19.174957 },
    { lat: 48.063488, lng: 19.175074 },
    { lat: 48.06334, lng: 19.175742 },
    { lat: 48.063262, lng: 19.176031 },
    { lat: 48.06325, lng: 19.176073 },
    { lat: 48.063049, lng: 19.176616 },
    { lat: 48.062843, lng: 19.17711 },
    { lat: 48.062676, lng: 19.177454 },
    { lat: 48.062417, lng: 19.177884 },
    { lat: 48.062402, lng: 19.177907 },
    { lat: 48.061909, lng: 19.17872 },
    { lat: 48.061772, lng: 19.17907 },
    { lat: 48.061613, lng: 19.179476 },
    { lat: 48.061542, lng: 19.179771 },
    { lat: 48.061426, lng: 19.180249 },
    { lat: 48.06127, lng: 19.181172 },
    { lat: 48.061274, lng: 19.181416 },
    { lat: 48.0613515, lng: 19.1815196 },
    { lat: 48.0614654, lng: 19.1825445 },
    { lat: 48.061633, lng: 19.18281 },
    { lat: 48.0621464, lng: 19.1831153 },
    { lat: 48.0625944, lng: 19.1830324 },
    { lat: 48.0626978, lng: 19.1831655 },
    { lat: 48.0627761, lng: 19.1837443 },
    { lat: 48.0628601, lng: 19.1838708 },
    { lat: 48.0632079, lng: 19.1837912 },
    { lat: 48.0639902, lng: 19.1843572 },
    { lat: 48.0653787, lng: 19.1858782 },
    { lat: 48.0668932, lng: 19.1871146 },
    { lat: 48.0705338, lng: 19.1878575 },
    { lat: 48.0714185, lng: 19.1875098 },
    { lat: 48.0727786, lng: 19.1865862 },
    { lat: 48.0730609, lng: 19.1865963 },
    { lat: 48.0733524, lng: 19.1866357 },
    { lat: 48.073677, lng: 19.1869077 },
    { lat: 48.0739338, lng: 19.1870369 },
    { lat: 48.0756632, lng: 19.1871315 },
    { lat: 48.0757473, lng: 19.1871902 },
    { lat: 48.0767322, lng: 19.1872773 },
    { lat: 48.0835257, lng: 19.1882161 },
    { lat: 48.0930763, lng: 19.1885625 },
    { lat: 48.0937242, lng: 19.1886695 },
    { lat: 48.0942192, lng: 19.1888536 },
    { lat: 48.0946276, lng: 19.1888157 },
    { lat: 48.0951322, lng: 19.1888785 },
    { lat: 48.0954266, lng: 19.1888799 },
    { lat: 48.0962868, lng: 19.1884946 },
    { lat: 48.0969993, lng: 19.1888342 },
    { lat: 48.0972266, lng: 19.188873 },
    { lat: 48.0975284, lng: 19.1887516 },
    { lat: 48.0977659, lng: 19.1885212 },
    { lat: 48.0978918, lng: 19.1882376 },
    { lat: 48.0979719, lng: 19.1874169 },
    { lat: 48.0984064, lng: 19.1869508 },
    { lat: 48.0986356, lng: 19.1869576 },
    { lat: 48.0988585, lng: 19.18686 },
    { lat: 48.0993013, lng: 19.1864674 },
    { lat: 48.0996667, lng: 19.1863798 },
  ],
  Kleňany: [
    { lat: 48.1086837, lng: 19.0912156 },
    { lat: 48.1090489, lng: 19.0909234 },
    { lat: 48.1092063, lng: 19.0907142 },
    { lat: 48.1094901, lng: 19.0905501 },
    { lat: 48.1095752, lng: 19.0905857 },
    { lat: 48.1096908, lng: 19.0904179 },
    { lat: 48.1098277, lng: 19.090049 },
    { lat: 48.1098535, lng: 19.0897075 },
    { lat: 48.1097593, lng: 19.0893847 },
    { lat: 48.1097622, lng: 19.089038 },
    { lat: 48.1098502, lng: 19.0886792 },
    { lat: 48.1097252, lng: 19.0884343 },
    { lat: 48.1102074, lng: 19.0878916 },
    { lat: 48.1103682, lng: 19.087772 },
    { lat: 48.110846, lng: 19.0876753 },
    { lat: 48.1109871, lng: 19.0875917 },
    { lat: 48.1110517, lng: 19.0877545 },
    { lat: 48.1118108, lng: 19.087247 },
    { lat: 48.1118082, lng: 19.0874403 },
    { lat: 48.1118827, lng: 19.087602 },
    { lat: 48.1124415, lng: 19.087481 },
    { lat: 48.1126297, lng: 19.0876571 },
    { lat: 48.1128081, lng: 19.0875499 },
    { lat: 48.1128464, lng: 19.0870341 },
    { lat: 48.1130935, lng: 19.0866359 },
    { lat: 48.1131794, lng: 19.0869523 },
    { lat: 48.1134517, lng: 19.0869464 },
    { lat: 48.1135365, lng: 19.0870632 },
    { lat: 48.1135726, lng: 19.0872404 },
    { lat: 48.1138145, lng: 19.0875213 },
    { lat: 48.1141673, lng: 19.0876771 },
    { lat: 48.1144282, lng: 19.087416 },
    { lat: 48.114567, lng: 19.0874851 },
    { lat: 48.1147322, lng: 19.0874306 },
    { lat: 48.114796, lng: 19.0872654 },
    { lat: 48.1150709, lng: 19.0872648 },
    { lat: 48.1155605, lng: 19.0868193 },
    { lat: 48.11581, lng: 19.0868481 },
    { lat: 48.1161203, lng: 19.087189 },
    { lat: 48.1162915, lng: 19.0871454 },
    { lat: 48.1163483, lng: 19.0870625 },
    { lat: 48.1163422, lng: 19.086782 },
    { lat: 48.1165836, lng: 19.0868985 },
    { lat: 48.1167677, lng: 19.0865698 },
    { lat: 48.1193735, lng: 19.0850744 },
    { lat: 48.1201866, lng: 19.0837587 },
    { lat: 48.120305, lng: 19.0837369 },
    { lat: 48.1203471, lng: 19.0836571 },
    { lat: 48.1203385, lng: 19.0835292 },
    { lat: 48.1203933, lng: 19.0834772 },
    { lat: 48.1204817, lng: 19.0836193 },
    { lat: 48.1206852, lng: 19.0836067 },
    { lat: 48.1207047, lng: 19.0834129 },
    { lat: 48.1206041, lng: 19.0831832 },
    { lat: 48.1208274, lng: 19.083053 },
    { lat: 48.1209394, lng: 19.0825239 },
    { lat: 48.1209156, lng: 19.0824309 },
    { lat: 48.1210489, lng: 19.0821884 },
    { lat: 48.1211372, lng: 19.0821312 },
    { lat: 48.1217026, lng: 19.0811322 },
    { lat: 48.1218028, lng: 19.0811767 },
    { lat: 48.1218694, lng: 19.0811099 },
    { lat: 48.1217887, lng: 19.0805823 },
    { lat: 48.1219124, lng: 19.0805095 },
    { lat: 48.1221107, lng: 19.080531 },
    { lat: 48.1222627, lng: 19.0803921 },
    { lat: 48.1222845, lng: 19.080131 },
    { lat: 48.1225161, lng: 19.0798506 },
    { lat: 48.1225232, lng: 19.0794528 },
    { lat: 48.1225642, lng: 19.0793286 },
    { lat: 48.1227459, lng: 19.0793092 },
    { lat: 48.1230743, lng: 19.0787349 },
    { lat: 48.1233748, lng: 19.0785646 },
    { lat: 48.1238837, lng: 19.0781474 },
    { lat: 48.1244352, lng: 19.0778881 },
    { lat: 48.1247809, lng: 19.0779212 },
    { lat: 48.1249739, lng: 19.0778131 },
    { lat: 48.1254808, lng: 19.0780517 },
    { lat: 48.125695, lng: 19.0776938 },
    { lat: 48.1261993, lng: 19.0773442 },
    { lat: 48.1266001, lng: 19.0771738 },
    { lat: 48.1267759, lng: 19.0768598 },
    { lat: 48.1269102, lng: 19.0768397 },
    { lat: 48.1270364, lng: 19.0769522 },
    { lat: 48.1271356, lng: 19.0769287 },
    { lat: 48.1275788, lng: 19.0763513 },
    { lat: 48.1276382, lng: 19.0763636 },
    { lat: 48.127739, lng: 19.0766187 },
    { lat: 48.1277858, lng: 19.0766346 },
    { lat: 48.1278388, lng: 19.0766121 },
    { lat: 48.1279759, lng: 19.0762434 },
    { lat: 48.1280574, lng: 19.0762491 },
    { lat: 48.128207, lng: 19.076445 },
    { lat: 48.1282515, lng: 19.0762329 },
    { lat: 48.128554, lng: 19.0758368 },
    { lat: 48.1289584, lng: 19.0756042 },
    { lat: 48.1294541, lng: 19.075775 },
    { lat: 48.1296894, lng: 19.075704 },
    { lat: 48.1297837, lng: 19.0755364 },
    { lat: 48.1300893, lng: 19.0754491 },
    { lat: 48.1304069, lng: 19.0756438 },
    { lat: 48.1305401, lng: 19.0754922 },
    { lat: 48.1306847, lng: 19.0755627 },
    { lat: 48.1308114, lng: 19.0753964 },
    { lat: 48.1310059, lng: 19.0754497 },
    { lat: 48.1313739, lng: 19.0752607 },
    { lat: 48.1314499, lng: 19.0752021 },
    { lat: 48.1315099, lng: 19.0749355 },
    { lat: 48.1316894, lng: 19.0749206 },
    { lat: 48.1320289, lng: 19.0747003 },
    { lat: 48.1321941, lng: 19.074758 },
    { lat: 48.1322959, lng: 19.0748732 },
    { lat: 48.1325273, lng: 19.0748104 },
    { lat: 48.1327609, lng: 19.0750393 },
    { lat: 48.1331196, lng: 19.0752654 },
    { lat: 48.1332896, lng: 19.0756204 },
    { lat: 48.1333188, lng: 19.0758843 },
    { lat: 48.1334293, lng: 19.0759766 },
    { lat: 48.1335146, lng: 19.0761926 },
    { lat: 48.1337186, lng: 19.0761488 },
    { lat: 48.1338703, lng: 19.0762468 },
    { lat: 48.1339619, lng: 19.0764574 },
    { lat: 48.1340735, lng: 19.0764777 },
    { lat: 48.1341477, lng: 19.0765851 },
    { lat: 48.1341256, lng: 19.0766617 },
    { lat: 48.134185, lng: 19.0767439 },
    { lat: 48.1344615, lng: 19.0763655 },
    { lat: 48.1346977, lng: 19.0764448 },
    { lat: 48.1348996, lng: 19.0766063 },
    { lat: 48.1350142, lng: 19.0765747 },
    { lat: 48.1350007, lng: 19.0763306 },
    { lat: 48.1350832, lng: 19.0762659 },
    { lat: 48.1352901, lng: 19.0765447 },
    { lat: 48.1353939, lng: 19.0765468 },
    { lat: 48.1355339, lng: 19.0764379 },
    { lat: 48.1356663, lng: 19.0764484 },
    { lat: 48.1357553, lng: 19.0765845 },
    { lat: 48.1357625, lng: 19.0767489 },
    { lat: 48.1358973, lng: 19.0768283 },
    { lat: 48.1361284, lng: 19.0768277 },
    { lat: 48.1362651, lng: 19.0767654 },
    { lat: 48.1366215, lng: 19.0764115 },
    { lat: 48.1368372, lng: 19.0763523 },
    { lat: 48.1369666, lng: 19.0764355 },
    { lat: 48.1371317, lng: 19.0763774 },
    { lat: 48.1371759, lng: 19.0762711 },
    { lat: 48.1375204, lng: 19.0761921 },
    { lat: 48.1381727, lng: 19.0761829 },
    { lat: 48.1382906, lng: 19.0762625 },
    { lat: 48.138611, lng: 19.0761325 },
    { lat: 48.1387477, lng: 19.0761867 },
    { lat: 48.1390279, lng: 19.0765522 },
    { lat: 48.1392625, lng: 19.0775643 },
    { lat: 48.1394016, lng: 19.0779715 },
    { lat: 48.1395253, lng: 19.0787701 },
    { lat: 48.1395985, lng: 19.0789479 },
    { lat: 48.1396387, lng: 19.0795903 },
    { lat: 48.1399603, lng: 19.0813406 },
    { lat: 48.1402959, lng: 19.0811087 },
    { lat: 48.1406963, lng: 19.0805981 },
    { lat: 48.1411987, lng: 19.0802108 },
    { lat: 48.1414744, lng: 19.0798879 },
    { lat: 48.1422584, lng: 19.0784644 },
    { lat: 48.1426717, lng: 19.0780073 },
    { lat: 48.1433361, lng: 19.076875 },
    { lat: 48.1437545, lng: 19.076476 },
    { lat: 48.1448451, lng: 19.0748925 },
    { lat: 48.1457778, lng: 19.0741146 },
    { lat: 48.1468872, lng: 19.0736275 },
    { lat: 48.1470285, lng: 19.0730982 },
    { lat: 48.1478519, lng: 19.0719192 },
    { lat: 48.1482052, lng: 19.0719066 },
    { lat: 48.1487361, lng: 19.0715474 },
    { lat: 48.1494993, lng: 19.0713199 },
    { lat: 48.1496496, lng: 19.0713972 },
    { lat: 48.1497892, lng: 19.0713684 },
    { lat: 48.1498459, lng: 19.0712173 },
    { lat: 48.1501477, lng: 19.0711218 },
    { lat: 48.1505396, lng: 19.070755 },
    { lat: 48.1506104, lng: 19.0707964 },
    { lat: 48.1513213, lng: 19.0704275 },
    { lat: 48.1515891, lng: 19.0704199 },
    { lat: 48.1518364, lng: 19.0700673 },
    { lat: 48.1518688, lng: 19.0699746 },
    { lat: 48.1518458, lng: 19.0698088 },
    { lat: 48.1520227, lng: 19.0694715 },
    { lat: 48.1520428, lng: 19.0690413 },
    { lat: 48.1524323, lng: 19.0673302 },
    { lat: 48.152618, lng: 19.0667917 },
    { lat: 48.1528263, lng: 19.0666093 },
    { lat: 48.1528822, lng: 19.0664191 },
    { lat: 48.1528085, lng: 19.0661206 },
    { lat: 48.1528869, lng: 19.0659744 },
    { lat: 48.1528927, lng: 19.0658237 },
    { lat: 48.1530463, lng: 19.0656037 },
    { lat: 48.1532272, lng: 19.0649925 },
    { lat: 48.1532219, lng: 19.0647485 },
    { lat: 48.1530828, lng: 19.064432 },
    { lat: 48.1528223, lng: 19.0643396 },
    { lat: 48.1526258, lng: 19.0643797 },
    { lat: 48.1524413, lng: 19.064206 },
    { lat: 48.1525127, lng: 19.0638142 },
    { lat: 48.1524086, lng: 19.0635812 },
    { lat: 48.1520562, lng: 19.0635229 },
    { lat: 48.1519193, lng: 19.0634271 },
    { lat: 48.1518709, lng: 19.0632267 },
    { lat: 48.1518834, lng: 19.0630728 },
    { lat: 48.1516836, lng: 19.0628676 },
    { lat: 48.1515496, lng: 19.0628255 },
    { lat: 48.151441, lng: 19.0624374 },
    { lat: 48.1514771, lng: 19.062105 },
    { lat: 48.1513842, lng: 19.0618941 },
    { lat: 48.1511503, lng: 19.0619971 },
    { lat: 48.1511051, lng: 19.0617053 },
    { lat: 48.1505675, lng: 19.0612823 },
    { lat: 48.1503653, lng: 19.0609881 },
    { lat: 48.1503516, lng: 19.0606715 },
    { lat: 48.150198, lng: 19.060484 },
    { lat: 48.150091, lng: 19.060219 },
    { lat: 48.150051, lng: 19.060186 },
    { lat: 48.150002, lng: 19.060169 },
    { lat: 48.149819, lng: 19.060192 },
    { lat: 48.14976, lng: 19.060146 },
    { lat: 48.149728, lng: 19.060027 },
    { lat: 48.149792, lng: 19.059819 },
    { lat: 48.149803, lng: 19.059755 },
    { lat: 48.149788, lng: 19.059708 },
    { lat: 48.149757, lng: 19.05969 },
    { lat: 48.149612, lng: 19.059692 },
    { lat: 48.149524, lng: 19.05973 },
    { lat: 48.149463, lng: 19.059675 },
    { lat: 48.14935, lng: 19.059353 },
    { lat: 48.149316, lng: 19.059309 },
    { lat: 48.14928, lng: 19.059304 },
    { lat: 48.149244, lng: 19.059333 },
    { lat: 48.149223, lng: 19.059391 },
    { lat: 48.149204, lng: 19.059516 },
    { lat: 48.149179, lng: 19.059575 },
    { lat: 48.149136, lng: 19.059617 },
    { lat: 48.149057, lng: 19.059585 },
    { lat: 48.148914, lng: 19.05934 },
    { lat: 48.148918, lng: 19.05892 },
    { lat: 48.148903, lng: 19.058827 },
    { lat: 48.148771, lng: 19.058661 },
    { lat: 48.148558, lng: 19.058414 },
    { lat: 48.148404, lng: 19.058144 },
    { lat: 48.148261, lng: 19.058051 },
    { lat: 48.148185, lng: 19.058023 },
    { lat: 48.148025, lng: 19.058061 },
    { lat: 48.147873, lng: 19.057999 },
    { lat: 48.147865, lng: 19.058081 },
    { lat: 48.148, lng: 19.058448 },
    { lat: 48.147993, lng: 19.058549 },
    { lat: 48.147953, lng: 19.058578 },
    { lat: 48.147987, lng: 19.058643 },
    { lat: 48.147987, lng: 19.058709 },
    { lat: 48.147948, lng: 19.058803 },
    { lat: 48.147933, lng: 19.058842 },
    { lat: 48.147865, lng: 19.059003 },
    { lat: 48.147797, lng: 19.059096 },
    { lat: 48.147798, lng: 19.059242 },
    { lat: 48.14764, lng: 19.059505 },
    { lat: 48.147507, lng: 19.059743 },
    { lat: 48.14741, lng: 19.060059 },
    { lat: 48.14737, lng: 19.060167 },
    { lat: 48.147349, lng: 19.060225 },
    { lat: 48.147342, lng: 19.060261 },
    { lat: 48.147222, lng: 19.060423 },
    { lat: 48.147124, lng: 19.060517 },
    { lat: 48.147005, lng: 19.060711 },
    { lat: 48.146902, lng: 19.06092 },
    { lat: 48.14679, lng: 19.061037 },
    { lat: 48.146742, lng: 19.061226 },
    { lat: 48.146597, lng: 19.061438 },
    { lat: 48.14657, lng: 19.061543 },
    { lat: 48.146567, lng: 19.061684 },
    { lat: 48.146547, lng: 19.061724 },
    { lat: 48.146471, lng: 19.061752 },
    { lat: 48.146482, lng: 19.06185 },
    { lat: 48.146399, lng: 19.062015 },
    { lat: 48.14639, lng: 19.062077 },
    { lat: 48.146351, lng: 19.06211 },
    { lat: 48.146259, lng: 19.062152 },
    { lat: 48.146194, lng: 19.062282 },
    { lat: 48.146225, lng: 19.062374 },
    { lat: 48.146225, lng: 19.06243 },
    { lat: 48.146141, lng: 19.062475 },
    { lat: 48.146108, lng: 19.062603 },
    { lat: 48.146066, lng: 19.062696 },
    { lat: 48.145875, lng: 19.062887 },
    { lat: 48.145801, lng: 19.063055 },
    { lat: 48.145729, lng: 19.063132 },
    { lat: 48.145691, lng: 19.063259 },
    { lat: 48.145536, lng: 19.063405 },
    { lat: 48.145419, lng: 19.06356 },
    { lat: 48.145371, lng: 19.063542 },
    { lat: 48.14515, lng: 19.063626 },
    { lat: 48.14502, lng: 19.063649 },
    { lat: 48.144765, lng: 19.063695 },
    { lat: 48.144037, lng: 19.063962 },
    { lat: 48.143665, lng: 19.064162 },
    { lat: 48.143401, lng: 19.06451 },
    { lat: 48.142521, lng: 19.064411 },
    { lat: 48.141937, lng: 19.064404 },
    { lat: 48.141841, lng: 19.064412 },
    { lat: 48.141525, lng: 19.064539 },
    { lat: 48.141209, lng: 19.06465 },
    { lat: 48.140999, lng: 19.064829 },
    { lat: 48.140416, lng: 19.064988 },
    { lat: 48.13998, lng: 19.064838 },
    { lat: 48.139545, lng: 19.064492 },
    { lat: 48.139241, lng: 19.064004 },
    { lat: 48.138765, lng: 19.064305 },
    { lat: 48.137462, lng: 19.065031 },
    { lat: 48.136125, lng: 19.065785 },
    { lat: 48.135236, lng: 19.066612 },
    { lat: 48.134864, lng: 19.066479 },
    { lat: 48.134538, lng: 19.06607 },
    { lat: 48.134368, lng: 19.065739 },
    { lat: 48.134143, lng: 19.065056 },
    { lat: 48.1338638, lng: 19.0643497 },
    { lat: 48.133934, lng: 19.063975 },
    { lat: 48.133901, lng: 19.063765 },
    { lat: 48.133859, lng: 19.063636 },
    { lat: 48.133757, lng: 19.063617 },
    { lat: 48.133642, lng: 19.063595 },
    { lat: 48.133615, lng: 19.063592 },
    { lat: 48.133415, lng: 19.063573 },
    { lat: 48.13339, lng: 19.063568 },
    { lat: 48.133124, lng: 19.063364 },
    { lat: 48.132519, lng: 19.062943 },
    { lat: 48.132485, lng: 19.062914 },
    { lat: 48.132443, lng: 19.062878 },
    { lat: 48.132021, lng: 19.062516 },
    { lat: 48.130904, lng: 19.06173 },
    { lat: 48.130744, lng: 19.061512 },
    { lat: 48.129992, lng: 19.061145 },
    { lat: 48.128486, lng: 19.060438 },
    { lat: 48.128198, lng: 19.059929 },
    { lat: 48.128125, lng: 19.059802 },
    { lat: 48.127862, lng: 19.059749 },
    { lat: 48.12759, lng: 19.059638 },
    { lat: 48.127463, lng: 19.059582 },
    { lat: 48.12741, lng: 19.059564 },
    { lat: 48.127376, lng: 19.059552 },
    { lat: 48.127229, lng: 19.059557 },
    { lat: 48.127083, lng: 19.059565 },
    { lat: 48.126758, lng: 19.059591 },
    { lat: 48.12665, lng: 19.059566 },
    { lat: 48.126641, lng: 19.05933 },
    { lat: 48.126582, lng: 19.058911 },
    { lat: 48.126193, lng: 19.058386 },
    { lat: 48.125513, lng: 19.058139 },
    { lat: 48.125388, lng: 19.05778 },
    { lat: 48.124911, lng: 19.057429 },
    { lat: 48.124732, lng: 19.057185 },
    { lat: 48.124765, lng: 19.057059 },
    { lat: 48.123802, lng: 19.055824 },
    { lat: 48.123614, lng: 19.055588 },
    { lat: 48.123395, lng: 19.055313 },
    { lat: 48.123244, lng: 19.055139 },
    { lat: 48.122858, lng: 19.054699 },
    { lat: 48.122412, lng: 19.054187 },
    { lat: 48.122194, lng: 19.053949 },
    { lat: 48.121919, lng: 19.053652 },
    { lat: 48.121601, lng: 19.053205 },
    { lat: 48.121451, lng: 19.052996 },
    { lat: 48.121167, lng: 19.052643 },
    { lat: 48.120776, lng: 19.052196 },
    { lat: 48.120492, lng: 19.051864 },
    { lat: 48.120003, lng: 19.051282 },
    { lat: 48.119945, lng: 19.051212 },
    { lat: 48.119479, lng: 19.050651 },
    { lat: 48.119222, lng: 19.050304 },
    { lat: 48.118818, lng: 19.04973 },
    { lat: 48.118666, lng: 19.049502 },
    { lat: 48.118578, lng: 19.049399 },
    { lat: 48.11849, lng: 19.049543 },
    { lat: 48.118461, lng: 19.049591 },
    { lat: 48.118416, lng: 19.049664 },
    { lat: 48.118206, lng: 19.050017 },
    { lat: 48.117904, lng: 19.050162 },
    { lat: 48.117854, lng: 19.050203 },
    { lat: 48.117568, lng: 19.050517 },
    { lat: 48.117492, lng: 19.050553 },
    { lat: 48.117434, lng: 19.050558 },
    { lat: 48.117377, lng: 19.050549 },
    { lat: 48.117305, lng: 19.05053 },
    { lat: 48.117241, lng: 19.050513 },
    { lat: 48.117162, lng: 19.050492 },
    { lat: 48.1169668, lng: 19.0506468 },
    { lat: 48.1164554, lng: 19.0514332 },
    { lat: 48.1160637, lng: 19.052248 },
    { lat: 48.1154579, lng: 19.0532186 },
    { lat: 48.1154929, lng: 19.0532665 },
    { lat: 48.1144785, lng: 19.0550731 },
    { lat: 48.113046, lng: 19.0584929 },
    { lat: 48.1125124, lng: 19.0596203 },
    { lat: 48.1119289, lng: 19.060392 },
    { lat: 48.1118328, lng: 19.0607419 },
    { lat: 48.1115788, lng: 19.0611805 },
    { lat: 48.1111776, lng: 19.0616519 },
    { lat: 48.1110894, lng: 19.0620238 },
    { lat: 48.1108859, lng: 19.0624796 },
    { lat: 48.110284, lng: 19.063065 },
    { lat: 48.110002, lng: 19.0634407 },
    { lat: 48.1098036, lng: 19.0638677 },
    { lat: 48.109811, lng: 19.0644158 },
    { lat: 48.1096296, lng: 19.0648194 },
    { lat: 48.109383, lng: 19.0648468 },
    { lat: 48.1089375, lng: 19.0660467 },
    { lat: 48.1081586, lng: 19.0676368 },
    { lat: 48.1080769, lng: 19.0678937 },
    { lat: 48.1080357, lng: 19.0682567 },
    { lat: 48.1080997, lng: 19.0686531 },
    { lat: 48.1081082, lng: 19.0690465 },
    { lat: 48.1073786, lng: 19.0703605 },
    { lat: 48.1071563, lng: 19.0716515 },
    { lat: 48.1068464, lng: 19.072303 },
    { lat: 48.106629, lng: 19.0729991 },
    { lat: 48.106365, lng: 19.0732172 },
    { lat: 48.1071866, lng: 19.0731681 },
    { lat: 48.1072578, lng: 19.0752247 },
    { lat: 48.1081137, lng: 19.0756978 },
    { lat: 48.1080177, lng: 19.0775277 },
    { lat: 48.1079655, lng: 19.07779 },
    { lat: 48.1077696, lng: 19.0782862 },
    { lat: 48.1073934, lng: 19.0785645 },
    { lat: 48.1077245, lng: 19.0805912 },
    { lat: 48.1068047, lng: 19.0807715 },
    { lat: 48.1063766, lng: 19.0809772 },
    { lat: 48.1064828, lng: 19.0816778 },
    { lat: 48.1064829, lng: 19.0832877 },
    { lat: 48.1062984, lng: 19.085112 },
    { lat: 48.1060578, lng: 19.0864237 },
    { lat: 48.1059181, lng: 19.0876986 },
    { lat: 48.1060615, lng: 19.0879288 },
    { lat: 48.1064095, lng: 19.0882802 },
    { lat: 48.1070308, lng: 19.0886845 },
    { lat: 48.1072661, lng: 19.0889043 },
    { lat: 48.1074738, lng: 19.0893325 },
    { lat: 48.1076742, lng: 19.0902663 },
    { lat: 48.1080949, lng: 19.0911447 },
    { lat: 48.1083275, lng: 19.0908834 },
    { lat: 48.1084562, lng: 19.0906389 },
    { lat: 48.1086837, lng: 19.0912156 },
  ],
  KamennéKosihy: [
    { lat: 48.1483313, lng: 19.1953313 },
    { lat: 48.1474466, lng: 19.1943307 },
    { lat: 48.1469197, lng: 19.193639 },
    { lat: 48.146941, lng: 19.1935876 },
    { lat: 48.1460902, lng: 19.1924982 },
    { lat: 48.1454519, lng: 19.191785 },
    { lat: 48.1456481, lng: 19.191056 },
    { lat: 48.1454897, lng: 19.1908857 },
    { lat: 48.1454119, lng: 19.189542 },
    { lat: 48.14445, lng: 19.1883464 },
    { lat: 48.1444311, lng: 19.1882175 },
    { lat: 48.143503, lng: 19.187433 },
    { lat: 48.1435854, lng: 19.1871609 },
    { lat: 48.1424095, lng: 19.1859057 },
    { lat: 48.1424072, lng: 19.1854471 },
    { lat: 48.1409764, lng: 19.1831885 },
    { lat: 48.1402258, lng: 19.1821889 },
    { lat: 48.1382655, lng: 19.1852659 },
    { lat: 48.1366529, lng: 19.1875812 },
    { lat: 48.1364538, lng: 19.1880937 },
    { lat: 48.1347254, lng: 19.1860343 },
    { lat: 48.1342758, lng: 19.1856269 },
    { lat: 48.1342933, lng: 19.1855283 },
    { lat: 48.1337699, lng: 19.1848552 },
    { lat: 48.133351, lng: 19.1841618 },
    { lat: 48.1331029, lng: 19.1830867 },
    { lat: 48.132953, lng: 19.1822006 },
    { lat: 48.1329779, lng: 19.1821364 },
    { lat: 48.1324266, lng: 19.1802 },
    { lat: 48.1327373, lng: 19.1794849 },
    { lat: 48.1327906, lng: 19.1789448 },
    { lat: 48.1323519, lng: 19.1754584 },
    { lat: 48.1323328, lng: 19.1741386 },
    { lat: 48.1322681, lng: 19.1737194 },
    { lat: 48.1319099, lng: 19.1723411 },
    { lat: 48.1312265, lng: 19.1705645 },
    { lat: 48.1306721, lng: 19.1670706 },
    { lat: 48.1306457, lng: 19.1662845 },
    { lat: 48.1306955, lng: 19.1657899 },
    { lat: 48.1313407, lng: 19.1625265 },
    { lat: 48.1314784, lng: 19.1613288 },
    { lat: 48.1314385, lng: 19.1610015 },
    { lat: 48.131264, lng: 19.1603927 },
    { lat: 48.1305268, lng: 19.1582654 },
    { lat: 48.1304947, lng: 19.1581864 },
    { lat: 48.1292859, lng: 19.1590826 },
    { lat: 48.1289047, lng: 19.1592188 },
    { lat: 48.1287934, lng: 19.1591799 },
    { lat: 48.1281112, lng: 19.1600354 },
    { lat: 48.1280509, lng: 19.1607521 },
    { lat: 48.1278931, lng: 19.1610956 },
    { lat: 48.1275233, lng: 19.1621814 },
    { lat: 48.1272675, lng: 19.1625669 },
    { lat: 48.1262156, lng: 19.1628401 },
    { lat: 48.1256867, lng: 19.1627015 },
    { lat: 48.1253573, lng: 19.1630139 },
    { lat: 48.1247552, lng: 19.1629666 },
    { lat: 48.124531, lng: 19.1633986 },
    { lat: 48.1231541, lng: 19.1651379 },
    { lat: 48.122238, lng: 19.1662117 },
    { lat: 48.1220482, lng: 19.1667288 },
    { lat: 48.1215316, lng: 19.1686107 },
    { lat: 48.1215443, lng: 19.1686602 },
    { lat: 48.1220431, lng: 19.1687835 },
    { lat: 48.1224062, lng: 19.1687172 },
    { lat: 48.1231899, lng: 19.1688573 },
    { lat: 48.1241699, lng: 19.1694548 },
    { lat: 48.1243861, lng: 19.1696793 },
    { lat: 48.1245117, lng: 19.1700066 },
    { lat: 48.124607, lng: 19.1707702 },
    { lat: 48.1246074, lng: 19.1711403 },
    { lat: 48.1244932, lng: 19.1720424 },
    { lat: 48.1241316, lng: 19.1729115 },
    { lat: 48.1232792, lng: 19.1741829 },
    { lat: 48.1242768, lng: 19.1746357 },
    { lat: 48.1243355, lng: 19.1747939 },
    { lat: 48.1247276, lng: 19.1753606 },
    { lat: 48.1246788, lng: 19.1771743 },
    { lat: 48.1252576, lng: 19.1788442 },
    { lat: 48.1252041, lng: 19.1795194 },
    { lat: 48.1248067, lng: 19.1813261 },
    { lat: 48.1246679, lng: 19.1823731 },
    { lat: 48.1246305, lng: 19.1832787 },
    { lat: 48.1246482, lng: 19.1843663 },
    { lat: 48.1250613, lng: 19.1867242 },
    { lat: 48.1253763, lng: 19.1881936 },
    { lat: 48.125948, lng: 19.1904738 },
    { lat: 48.1260306, lng: 19.1905045 },
    { lat: 48.1261114, lng: 19.1907017 },
    { lat: 48.1264367, lng: 19.1922649 },
    { lat: 48.1265128, lng: 19.1922996 },
    { lat: 48.1272711, lng: 19.1943937 },
    { lat: 48.1273229, lng: 19.1943471 },
    { lat: 48.1274122, lng: 19.1944181 },
    { lat: 48.1276618, lng: 19.1944516 },
    { lat: 48.1278001, lng: 19.1946251 },
    { lat: 48.1278647, lng: 19.1949049 },
    { lat: 48.128013, lng: 19.1957403 },
    { lat: 48.1278058, lng: 19.196363 },
    { lat: 48.1276518, lng: 19.1966206 },
    { lat: 48.1276664, lng: 19.1969655 },
    { lat: 48.1283546, lng: 19.1996222 },
    { lat: 48.1287784, lng: 19.2007862 },
    { lat: 48.1289783, lng: 19.2015489 },
    { lat: 48.1290038, lng: 19.2020663 },
    { lat: 48.1287466, lng: 19.2039156 },
    { lat: 48.1286821, lng: 19.2052149 },
    { lat: 48.1291183, lng: 19.2071111 },
    { lat: 48.1290937, lng: 19.2071816 },
    { lat: 48.1299644, lng: 19.2086339 },
    { lat: 48.1299955, lng: 19.2088542 },
    { lat: 48.1312594, lng: 19.2108021 },
    { lat: 48.1326457, lng: 19.2131101 },
    { lat: 48.1358631, lng: 19.2115804 },
    { lat: 48.1358809, lng: 19.2116445 },
    { lat: 48.1369557, lng: 19.2108304 },
    { lat: 48.1382757, lng: 19.2097074 },
    { lat: 48.1396559, lng: 19.2080226 },
    { lat: 48.1406709, lng: 19.2069104 },
    { lat: 48.1408606, lng: 19.2066228 },
    { lat: 48.1415068, lng: 19.205207 },
    { lat: 48.1421273, lng: 19.2033641 },
    { lat: 48.1424631, lng: 19.2027437 },
    { lat: 48.1431316, lng: 19.2019018 },
    { lat: 48.1459791, lng: 19.1988559 },
    { lat: 48.1470447, lng: 19.1974018 },
    { lat: 48.1483647, lng: 19.1953672 },
    { lat: 48.1483313, lng: 19.1953313 },
  ],
  Glabušovce: [
    { lat: 48.1631947, lng: 19.4466607 },
    { lat: 48.1631441, lng: 19.4461128 },
    { lat: 48.1623789, lng: 19.4438894 },
    { lat: 48.1618045, lng: 19.4443738 },
    { lat: 48.1613233, lng: 19.44311 },
    { lat: 48.1606831, lng: 19.4436557 },
    { lat: 48.1603886, lng: 19.4437958 },
    { lat: 48.160252, lng: 19.4435744 },
    { lat: 48.1583101, lng: 19.4415189 },
    { lat: 48.1577353, lng: 19.4406339 },
    { lat: 48.1572936, lng: 19.4401831 },
    { lat: 48.1569585, lng: 19.4391472 },
    { lat: 48.1561182, lng: 19.4376308 },
    { lat: 48.1549662, lng: 19.4364319 },
    { lat: 48.1547105, lng: 19.4357435 },
    { lat: 48.1542522, lng: 19.4348692 },
    { lat: 48.1539963, lng: 19.4345115 },
    { lat: 48.153702, lng: 19.433818 },
    { lat: 48.1530602, lng: 19.4332349 },
    { lat: 48.1519513, lng: 19.4331624 },
    { lat: 48.1513478, lng: 19.4329159 },
    { lat: 48.1511866, lng: 19.4329396 },
    { lat: 48.1509244, lng: 19.433112 },
    { lat: 48.1506888, lng: 19.4334703 },
    { lat: 48.1504399, lng: 19.433569 },
    { lat: 48.1497305, lng: 19.4342766 },
    { lat: 48.1493065, lng: 19.4344145 },
    { lat: 48.1491678, lng: 19.4356524 },
    { lat: 48.1492489, lng: 19.4368441 },
    { lat: 48.1494375, lng: 19.4382831 },
    { lat: 48.1492758, lng: 19.4389887 },
    { lat: 48.1491574, lng: 19.439988 },
    { lat: 48.148951, lng: 19.4406785 },
    { lat: 48.1482867, lng: 19.4412902 },
    { lat: 48.1472625, lng: 19.441727 },
    { lat: 48.1466388, lng: 19.4422889 },
    { lat: 48.146135, lng: 19.4428371 },
    { lat: 48.1453411, lng: 19.4442109 },
    { lat: 48.1453395, lng: 19.445064 },
    { lat: 48.1451138, lng: 19.4463483 },
    { lat: 48.1448674, lng: 19.4487953 },
    { lat: 48.1445067, lng: 19.4497264 },
    { lat: 48.1427728, lng: 19.4532064 },
    { lat: 48.1428171, lng: 19.4533609 },
    { lat: 48.1429944, lng: 19.4561172 },
    { lat: 48.143, lng: 19.456864 },
    { lat: 48.1431533, lng: 19.4576277 },
    { lat: 48.1433882, lng: 19.4581672 },
    { lat: 48.1434679, lng: 19.4584962 },
    { lat: 48.1434983, lng: 19.4589976 },
    { lat: 48.1434035, lng: 19.4595221 },
    { lat: 48.1434195, lng: 19.4597928 },
    { lat: 48.1436882, lng: 19.4610921 },
    { lat: 48.143775, lng: 19.4612149 },
    { lat: 48.143913, lng: 19.4618497 },
    { lat: 48.1439295, lng: 19.4622715 },
    { lat: 48.1438542, lng: 19.4629452 },
    { lat: 48.1446867, lng: 19.4626744 },
    { lat: 48.1448197, lng: 19.4625796 },
    { lat: 48.1453111, lng: 19.4619098 },
    { lat: 48.1455123, lng: 19.4618999 },
    { lat: 48.1460249, lng: 19.4622996 },
    { lat: 48.1464946, lng: 19.4623682 },
    { lat: 48.146581, lng: 19.4624339 },
    { lat: 48.1487459, lng: 19.4651418 },
    { lat: 48.1491634, lng: 19.4662068 },
    { lat: 48.1506668, lng: 19.4686769 },
    { lat: 48.1507384, lng: 19.4684248 },
    { lat: 48.1510497, lng: 19.4686717 },
    { lat: 48.1515781, lng: 19.4689318 },
    { lat: 48.153047, lng: 19.4700925 },
    { lat: 48.1537952, lng: 19.470884 },
    { lat: 48.1560209, lng: 19.4723961 },
    { lat: 48.1571415, lng: 19.4714397 },
    { lat: 48.1582186, lng: 19.4708563 },
    { lat: 48.1586128, lng: 19.4705637 },
    { lat: 48.1592785, lng: 19.4697883 },
    { lat: 48.1595212, lng: 19.4693894 },
    { lat: 48.1598386, lng: 19.4686092 },
    { lat: 48.1600541, lng: 19.4680628 },
    { lat: 48.160018, lng: 19.4680133 },
    { lat: 48.1601386, lng: 19.4671266 },
    { lat: 48.1603479, lng: 19.4659674 },
    { lat: 48.1613341, lng: 19.4635673 },
    { lat: 48.1612519, lng: 19.4634524 },
    { lat: 48.1618384, lng: 19.4615433 },
    { lat: 48.1633545, lng: 19.4599403 },
    { lat: 48.1635852, lng: 19.4594066 },
    { lat: 48.1640517, lng: 19.4585655 },
    { lat: 48.1645175, lng: 19.4573519 },
    { lat: 48.1647175, lng: 19.4563816 },
    { lat: 48.1652021, lng: 19.4553271 },
    { lat: 48.1650471, lng: 19.4534434 },
    { lat: 48.1648403, lng: 19.4523807 },
    { lat: 48.1633608, lng: 19.449947 },
    { lat: 48.1638638, lng: 19.4493787 },
    { lat: 48.163436, lng: 19.4478581 },
    { lat: 48.1632009, lng: 19.4467176 },
    { lat: 48.1631947, lng: 19.4466607 },
  ],
  Záhorce: [
    { lat: 48.08869, lng: 19.351837 },
    { lat: 48.0928286, lng: 19.3526453 },
    { lat: 48.0956163, lng: 19.3539431 },
    { lat: 48.0960269, lng: 19.3538075 },
    { lat: 48.0964638, lng: 19.3534484 },
    { lat: 48.0966663, lng: 19.3538382 },
    { lat: 48.096859, lng: 19.3543549 },
    { lat: 48.0969491, lng: 19.3543082 },
    { lat: 48.0971725, lng: 19.3544049 },
    { lat: 48.0972695, lng: 19.3542666 },
    { lat: 48.0973654, lng: 19.3543155 },
    { lat: 48.0975315, lng: 19.3541349 },
    { lat: 48.0976688, lng: 19.3541107 },
    { lat: 48.0977071, lng: 19.354132 },
    { lat: 48.0975551, lng: 19.3542085 },
    { lat: 48.097565, lng: 19.3542716 },
    { lat: 48.0977707, lng: 19.3541671 },
    { lat: 48.0984653, lng: 19.3543153 },
    { lat: 48.0987114, lng: 19.3542022 },
    { lat: 48.0990143, lng: 19.3531649 },
    { lat: 48.0992462, lng: 19.3534839 },
    { lat: 48.0993547, lng: 19.353516 },
    { lat: 48.0995111, lng: 19.3540532 },
    { lat: 48.0996941, lng: 19.3543873 },
    { lat: 48.0998523, lng: 19.3544437 },
    { lat: 48.1004344, lng: 19.3543596 },
    { lat: 48.1005311, lng: 19.3544494 },
    { lat: 48.100927, lng: 19.3545251 },
    { lat: 48.1011874, lng: 19.3550882 },
    { lat: 48.1019107, lng: 19.3541642 },
    { lat: 48.1022038, lng: 19.3537228 },
    { lat: 48.1023533, lng: 19.3533626 },
    { lat: 48.1023846, lng: 19.3528388 },
    { lat: 48.1025416, lng: 19.3526594 },
    { lat: 48.102741, lng: 19.3526067 },
    { lat: 48.1031408, lng: 19.3527198 },
    { lat: 48.104559, lng: 19.3518313 },
    { lat: 48.1050703, lng: 19.3518951 },
    { lat: 48.1056318, lng: 19.3520628 },
    { lat: 48.1056686, lng: 19.3517277 },
    { lat: 48.1060739, lng: 19.3516505 },
    { lat: 48.1063729, lng: 19.3517715 },
    { lat: 48.1064429, lng: 19.3519858 },
    { lat: 48.1068487, lng: 19.3518193 },
    { lat: 48.107038, lng: 19.3520075 },
    { lat: 48.1066945, lng: 19.3525277 },
    { lat: 48.1066514, lng: 19.3528748 },
    { lat: 48.1069355, lng: 19.3528786 },
    { lat: 48.1069125, lng: 19.353148 },
    { lat: 48.1069606, lng: 19.3532829 },
    { lat: 48.107145, lng: 19.353346 },
    { lat: 48.1073198, lng: 19.35351 },
    { lat: 48.1073312, lng: 19.3536092 },
    { lat: 48.1071357, lng: 19.3539948 },
    { lat: 48.1072176, lng: 19.354131 },
    { lat: 48.1074306, lng: 19.3542912 },
    { lat: 48.1074417, lng: 19.3545926 },
    { lat: 48.1072885, lng: 19.3545945 },
    { lat: 48.1072175, lng: 19.3545034 },
    { lat: 48.1071911, lng: 19.3546307 },
    { lat: 48.1072969, lng: 19.3549601 },
    { lat: 48.106997, lng: 19.3549325 },
    { lat: 48.1069816, lng: 19.3551296 },
    { lat: 48.1071342, lng: 19.3556306 },
    { lat: 48.106882, lng: 19.3556861 },
    { lat: 48.1070122, lng: 19.3560707 },
    { lat: 48.1069906, lng: 19.3561883 },
    { lat: 48.1067802, lng: 19.3561935 },
    { lat: 48.1067714, lng: 19.3564078 },
    { lat: 48.1069283, lng: 19.3564651 },
    { lat: 48.1072536, lng: 19.356358 },
    { lat: 48.1073085, lng: 19.3565172 },
    { lat: 48.1072856, lng: 19.3565806 },
    { lat: 48.1069275, lng: 19.3567977 },
    { lat: 48.1069381, lng: 19.3570872 },
    { lat: 48.107354, lng: 19.357067 },
    { lat: 48.1073713, lng: 19.3571463 },
    { lat: 48.1073041, lng: 19.3572723 },
    { lat: 48.1071641, lng: 19.3574058 },
    { lat: 48.1072959, lng: 19.3575684 },
    { lat: 48.1074066, lng: 19.3579522 },
    { lat: 48.1076264, lng: 19.3579992 },
    { lat: 48.1079669, lng: 19.3576483 },
    { lat: 48.1080158, lng: 19.3577263 },
    { lat: 48.1078109, lng: 19.3581336 },
    { lat: 48.1078573, lng: 19.3582539 },
    { lat: 48.1079944, lng: 19.3582212 },
    { lat: 48.1081064, lng: 19.358114 },
    { lat: 48.1085343, lng: 19.3581511 },
    { lat: 48.1085412, lng: 19.3584819 },
    { lat: 48.1086967, lng: 19.3585785 },
    { lat: 48.1088426, lng: 19.3585615 },
    { lat: 48.1088728, lng: 19.3588164 },
    { lat: 48.1089482, lng: 19.3589078 },
    { lat: 48.1091447, lng: 19.3590109 },
    { lat: 48.1091948, lng: 19.3589706 },
    { lat: 48.1092681, lng: 19.3585982 },
    { lat: 48.1094067, lng: 19.3585735 },
    { lat: 48.1093976, lng: 19.3587384 },
    { lat: 48.1094526, lng: 19.3588958 },
    { lat: 48.1095624, lng: 19.358998 },
    { lat: 48.1100473, lng: 19.3587479 },
    { lat: 48.1099744, lng: 19.3582126 },
    { lat: 48.1100244, lng: 19.3578959 },
    { lat: 48.1104568, lng: 19.3579329 },
    { lat: 48.1105753, lng: 19.3580309 },
    { lat: 48.1106732, lng: 19.3579305 },
    { lat: 48.1105354, lng: 19.3572177 },
    { lat: 48.1105546, lng: 19.3571021 },
    { lat: 48.1107525, lng: 19.3568601 },
    { lat: 48.1109137, lng: 19.3569321 },
    { lat: 48.1109531, lng: 19.3574665 },
    { lat: 48.1110707, lng: 19.3574532 },
    { lat: 48.1112826, lng: 19.3572901 },
    { lat: 48.1113253, lng: 19.3573582 },
    { lat: 48.1113381, lng: 19.3574821 },
    { lat: 48.1112165, lng: 19.3578382 },
    { lat: 48.1112196, lng: 19.3579712 },
    { lat: 48.1116033, lng: 19.3585248 },
    { lat: 48.1118704, lng: 19.3580085 },
    { lat: 48.1121903, lng: 19.3583305 },
    { lat: 48.1158724, lng: 19.3540262 },
    { lat: 48.1155314, lng: 19.353359 },
    { lat: 48.1155546, lng: 19.3530641 },
    { lat: 48.1162784, lng: 19.3527172 },
    { lat: 48.1169119, lng: 19.3521573 },
    { lat: 48.1186745, lng: 19.3502959 },
    { lat: 48.1192153, lng: 19.3488158 },
    { lat: 48.1200155, lng: 19.3469127 },
    { lat: 48.1199753, lng: 19.3465223 },
    { lat: 48.1199397, lng: 19.3464841 },
    { lat: 48.1202542, lng: 19.3458282 },
    { lat: 48.1206247, lng: 19.3452142 },
    { lat: 48.1208256, lng: 19.3447012 },
    { lat: 48.1211535, lng: 19.3443486 },
    { lat: 48.121457, lng: 19.3435329 },
    { lat: 48.1215972, lng: 19.3427454 },
    { lat: 48.1219281, lng: 19.3422672 },
    { lat: 48.1220914, lng: 19.3421481 },
    { lat: 48.1225163, lng: 19.3416312 },
    { lat: 48.1228678, lng: 19.3413666 },
    { lat: 48.1233443, lng: 19.3412224 },
    { lat: 48.1235812, lng: 19.3406869 },
    { lat: 48.1240236, lng: 19.3405103 },
    { lat: 48.1241913, lng: 19.3402496 },
    { lat: 48.1242648, lng: 19.3399524 },
    { lat: 48.1245114, lng: 19.3396864 },
    { lat: 48.1245141, lng: 19.3394642 },
    { lat: 48.1248448, lng: 19.3391648 },
    { lat: 48.1249331, lng: 19.3390114 },
    { lat: 48.124945, lng: 19.3388369 },
    { lat: 48.1251386, lng: 19.3386888 },
    { lat: 48.1252161, lng: 19.3384476 },
    { lat: 48.1254549, lng: 19.3380519 },
    { lat: 48.1254647, lng: 19.3379507 },
    { lat: 48.125525, lng: 19.3379118 },
    { lat: 48.1257327, lng: 19.3371479 },
    { lat: 48.1259335, lng: 19.3360009 },
    { lat: 48.1259729, lng: 19.3348522 },
    { lat: 48.1261091, lng: 19.3345226 },
    { lat: 48.1263729, lng: 19.3334732 },
    { lat: 48.1267332, lng: 19.332621 },
    { lat: 48.1270114, lng: 19.3321751 },
    { lat: 48.1274003, lng: 19.3313776 },
    { lat: 48.1274308, lng: 19.331144 },
    { lat: 48.1274933, lng: 19.3311069 },
    { lat: 48.1275464, lng: 19.3308327 },
    { lat: 48.1279373, lng: 19.3303359 },
    { lat: 48.1281377, lng: 19.3301916 },
    { lat: 48.1283032, lng: 19.3301861 },
    { lat: 48.1284556, lng: 19.3296769 },
    { lat: 48.1286857, lng: 19.3295604 },
    { lat: 48.1287654, lng: 19.3294156 },
    { lat: 48.1287777, lng: 19.3291564 },
    { lat: 48.1289209, lng: 19.3290648 },
    { lat: 48.1292448, lng: 19.3286313 },
    { lat: 48.1293415, lng: 19.3282938 },
    { lat: 48.1294657, lng: 19.3281122 },
    { lat: 48.1296833, lng: 19.3279953 },
    { lat: 48.1300231, lng: 19.3275184 },
    { lat: 48.1302858, lng: 19.3270054 },
    { lat: 48.1305835, lng: 19.3262589 },
    { lat: 48.1306543, lng: 19.3259041 },
    { lat: 48.1309516, lng: 19.3251301 },
    { lat: 48.1309489, lng: 19.3247204 },
    { lat: 48.1309937, lng: 19.3246217 },
    { lat: 48.1312911, lng: 19.3244806 },
    { lat: 48.1315155, lng: 19.3242702 },
    { lat: 48.1316755, lng: 19.323989 },
    { lat: 48.1317688, lng: 19.3239398 },
    { lat: 48.1321294, lng: 19.3223464 },
    { lat: 48.1328538, lng: 19.3211037 },
    { lat: 48.1342337, lng: 19.3191481 },
    { lat: 48.1343191, lng: 19.3186032 },
    { lat: 48.1342759, lng: 19.3178934 },
    { lat: 48.1331259, lng: 19.3169309 },
    { lat: 48.1338035, lng: 19.3168427 },
    { lat: 48.134157, lng: 19.3164591 },
    { lat: 48.1347428, lng: 19.31606 },
    { lat: 48.1351171, lng: 19.3160328 },
    { lat: 48.1355397, lng: 19.316195 },
    { lat: 48.136115, lng: 19.3161598 },
    { lat: 48.1378676, lng: 19.3150611 },
    { lat: 48.139278, lng: 19.3144862 },
    { lat: 48.1397068, lng: 19.3147276 },
    { lat: 48.1401239, lng: 19.3144973 },
    { lat: 48.1406095, lng: 19.3143987 },
    { lat: 48.1410786, lng: 19.314448 },
    { lat: 48.1419285, lng: 19.3141474 },
    { lat: 48.1420566, lng: 19.3140902 },
    { lat: 48.1426994, lng: 19.3135144 },
    { lat: 48.1430959, lng: 19.3134152 },
    { lat: 48.1431133, lng: 19.3134034 },
    { lat: 48.1430505, lng: 19.3133604 },
    { lat: 48.1428666, lng: 19.3132398 },
    { lat: 48.1426725, lng: 19.3128415 },
    { lat: 48.1422322, lng: 19.3125912 },
    { lat: 48.1415243, lng: 19.3123602 },
    { lat: 48.1402745, lng: 19.3110107 },
    { lat: 48.1386637, lng: 19.3096841 },
    { lat: 48.1380231, lng: 19.3088128 },
    { lat: 48.1377366, lng: 19.308204 },
    { lat: 48.1375934, lng: 19.3074801 },
    { lat: 48.1373149, lng: 19.3066143 },
    { lat: 48.136789, lng: 19.30567 },
    { lat: 48.1362132, lng: 19.3050476 },
    { lat: 48.135202, lng: 19.3041339 },
    { lat: 48.1346954, lng: 19.3038901 },
    { lat: 48.1345001, lng: 19.3036025 },
    { lat: 48.1340591, lng: 19.3035975 },
    { lat: 48.1338923, lng: 19.303484 },
    { lat: 48.1335534, lng: 19.3030273 },
    { lat: 48.133341, lng: 19.3028301 },
    { lat: 48.1326197, lng: 19.300901 },
    { lat: 48.13123, lng: 19.297784 },
    { lat: 48.1308779, lng: 19.297404 },
    { lat: 48.1295861, lng: 19.2963808 },
    { lat: 48.1282113, lng: 19.295084 },
    { lat: 48.1281913, lng: 19.2950707 },
    { lat: 48.1270628, lng: 19.294297 },
    { lat: 48.1257438, lng: 19.2935514 },
    { lat: 48.1242331, lng: 19.2919891 },
    { lat: 48.1208751, lng: 19.2929583 },
    { lat: 48.1200279, lng: 19.2947115 },
    { lat: 48.1194636, lng: 19.2972987 },
    { lat: 48.1193566, lng: 19.2985798 },
    { lat: 48.1190953, lng: 19.2976094 },
    { lat: 48.1188921, lng: 19.2974162 },
    { lat: 48.118311, lng: 19.2970801 },
    { lat: 48.1178859, lng: 19.2969843 },
    { lat: 48.1176295, lng: 19.296854 },
    { lat: 48.1174807, lng: 19.2966697 },
    { lat: 48.1162087, lng: 19.2943787 },
    { lat: 48.1147518, lng: 19.292972 },
    { lat: 48.114404, lng: 19.2927066 },
    { lat: 48.1131611, lng: 19.2920641 },
    { lat: 48.1128918, lng: 19.2918108 },
    { lat: 48.1126761, lng: 19.2917812 },
    { lat: 48.1126233, lng: 19.2917143 },
    { lat: 48.1126376, lng: 19.2915177 },
    { lat: 48.1122684, lng: 19.291577 },
    { lat: 48.1116382, lng: 19.2911821 },
    { lat: 48.111264, lng: 19.2910876 },
    { lat: 48.110388, lng: 19.2904333 },
    { lat: 48.1099667, lng: 19.2904161 },
    { lat: 48.1089514, lng: 19.2916049 },
    { lat: 48.1070461, lng: 19.2931068 },
    { lat: 48.1065263, lng: 19.2932925 },
    { lat: 48.1062325, lng: 19.2932545 },
    { lat: 48.1058262, lng: 19.2928685 },
    { lat: 48.1052493, lng: 19.2925268 },
    { lat: 48.1051587, lng: 19.2925877 },
    { lat: 48.1049565, lng: 19.2937395 },
    { lat: 48.1042548, lng: 19.2960495 },
    { lat: 48.1037315, lng: 19.2973457 },
    { lat: 48.103592, lng: 19.2981415 },
    { lat: 48.1033355, lng: 19.2985959 },
    { lat: 48.1031791, lng: 19.2990184 },
    { lat: 48.1024151, lng: 19.2997421 },
    { lat: 48.1022168, lng: 19.3000696 },
    { lat: 48.1014116, lng: 19.3017794 },
    { lat: 48.1019949, lng: 19.3020271 },
    { lat: 48.101237, lng: 19.3038315 },
    { lat: 48.1008131, lng: 19.3047145 },
    { lat: 48.1006517, lng: 19.3061286 },
    { lat: 48.1006015, lng: 19.3062091 },
    { lat: 48.1003851, lng: 19.306163 },
    { lat: 48.0982064, lng: 19.3079581 },
    { lat: 48.0975054, lng: 19.3091007 },
    { lat: 48.0968749, lng: 19.3092548 },
    { lat: 48.0965637, lng: 19.3095352 },
    { lat: 48.0957902, lng: 19.3091549 },
    { lat: 48.095724, lng: 19.3093246 },
    { lat: 48.0958075, lng: 19.3096488 },
    { lat: 48.0958107, lng: 19.3100906 },
    { lat: 48.0957245, lng: 19.3103118 },
    { lat: 48.0953186, lng: 19.3106842 },
    { lat: 48.0947891, lng: 19.3110124 },
    { lat: 48.0942319, lng: 19.3108673 },
    { lat: 48.0938787, lng: 19.3110203 },
    { lat: 48.0937286, lng: 19.311272 },
    { lat: 48.0935, lng: 19.3114201 },
    { lat: 48.0934196, lng: 19.3120627 },
    { lat: 48.093111, lng: 19.3127912 },
    { lat: 48.0931556, lng: 19.3130339 },
    { lat: 48.093301, lng: 19.3131557 },
    { lat: 48.0935163, lng: 19.3131526 },
    { lat: 48.0937437, lng: 19.3132632 },
    { lat: 48.0938848, lng: 19.3137592 },
    { lat: 48.09361, lng: 19.3138784 },
    { lat: 48.0934277, lng: 19.3140461 },
    { lat: 48.0938391, lng: 19.3148846 },
    { lat: 48.094912, lng: 19.3144182 },
    { lat: 48.0952477, lng: 19.3144814 },
    { lat: 48.0954241, lng: 19.314448 },
    { lat: 48.0958004, lng: 19.3146768 },
    { lat: 48.095815, lng: 19.315702 },
    { lat: 48.0957307, lng: 19.3167092 },
    { lat: 48.0957717, lng: 19.3170204 },
    { lat: 48.0956088, lng: 19.3176624 },
    { lat: 48.095367, lng: 19.3180692 },
    { lat: 48.0950178, lng: 19.3173738 },
    { lat: 48.0948466, lng: 19.3177494 },
    { lat: 48.0948127, lng: 19.3181927 },
    { lat: 48.0947368, lng: 19.3183962 },
    { lat: 48.0945417, lng: 19.3186697 },
    { lat: 48.0942221, lng: 19.318238 },
    { lat: 48.0938958, lng: 19.3186021 },
    { lat: 48.0936241, lng: 19.319182 },
    { lat: 48.0934941, lng: 19.319733 },
    { lat: 48.0934482, lng: 19.3197855 },
    { lat: 48.0932934, lng: 19.3194757 },
    { lat: 48.090254, lng: 19.325666 },
    { lat: 48.090306, lng: 19.325844 },
    { lat: 48.090403, lng: 19.326313 },
    { lat: 48.090425, lng: 19.326703 },
    { lat: 48.090364, lng: 19.327144 },
    { lat: 48.090344, lng: 19.327291 },
    { lat: 48.090358, lng: 19.327875 },
    { lat: 48.090361, lng: 19.328059 },
    { lat: 48.090409, lng: 19.328591 },
    { lat: 48.090455, lng: 19.328959 },
    { lat: 48.090441, lng: 19.329532 },
    { lat: 48.090297, lng: 19.330416 },
    { lat: 48.090167, lng: 19.331176 },
    { lat: 48.090105, lng: 19.33138 },
    { lat: 48.089965, lng: 19.332096 },
    { lat: 48.089684, lng: 19.333585 },
    { lat: 48.089541, lng: 19.334346 },
    { lat: 48.088962, lng: 19.337122 },
    { lat: 48.08881, lng: 19.337772 },
    { lat: 48.088749, lng: 19.338067 },
    { lat: 48.08869, lng: 19.338622 },
    { lat: 48.088732, lng: 19.339253 },
    { lat: 48.088785, lng: 19.339548 },
    { lat: 48.088864, lng: 19.339837 },
    { lat: 48.088969, lng: 19.340349 },
    { lat: 48.089212, lng: 19.341335 },
    { lat: 48.089432, lng: 19.342184 },
    { lat: 48.089646, lng: 19.343114 },
    { lat: 48.089751, lng: 19.343842 },
    { lat: 48.089816, lng: 19.344486 },
    { lat: 48.08982, lng: 19.345117 },
    { lat: 48.089825, lng: 19.345477 },
    { lat: 48.089811, lng: 19.345645 },
    { lat: 48.089779, lng: 19.34607 },
    { lat: 48.089734, lng: 19.346444 },
    { lat: 48.089656, lng: 19.346937 },
    { lat: 48.089585, lng: 19.347332 },
    { lat: 48.089302, lng: 19.348688 },
    { lat: 48.089185, lng: 19.349283 },
    { lat: 48.089078, lng: 19.349698 },
    { lat: 48.088925, lng: 19.350319 },
    { lat: 48.088818, lng: 19.350603 },
    { lat: 48.088736, lng: 19.350921 },
    { lat: 48.088705, lng: 19.35109 },
    { lat: 48.08867, lng: 19.351539 },
    { lat: 48.088685, lng: 19.351809 },
    { lat: 48.08869, lng: 19.351837 },
  ],
  DolnéPlachtince: [
    { lat: 48.2283323, lng: 19.3148065 },
    { lat: 48.2290499, lng: 19.3138625 },
    { lat: 48.2286186, lng: 19.3128681 },
    { lat: 48.2285549, lng: 19.3126692 },
    { lat: 48.2282362, lng: 19.3126517 },
    { lat: 48.2274252, lng: 19.3122639 },
    { lat: 48.2270351, lng: 19.3122396 },
    { lat: 48.2261538, lng: 19.3119838 },
    { lat: 48.2234667, lng: 19.3092083 },
    { lat: 48.2232519, lng: 19.3095443 },
    { lat: 48.2229451, lng: 19.3096118 },
    { lat: 48.2226846, lng: 19.3098863 },
    { lat: 48.2223825, lng: 19.3097935 },
    { lat: 48.2220617, lng: 19.3103662 },
    { lat: 48.2219378, lng: 19.3103682 },
    { lat: 48.221503, lng: 19.3108864 },
    { lat: 48.2207495, lng: 19.3115521 },
    { lat: 48.220428, lng: 19.3113112 },
    { lat: 48.219773, lng: 19.3111441 },
    { lat: 48.2194266, lng: 19.3108582 },
    { lat: 48.2189349, lng: 19.3107367 },
    { lat: 48.2188522, lng: 19.3108185 },
    { lat: 48.2185898, lng: 19.310569 },
    { lat: 48.2185029, lng: 19.3101942 },
    { lat: 48.2183357, lng: 19.3099501 },
    { lat: 48.2178881, lng: 19.3094365 },
    { lat: 48.2175863, lng: 19.3094175 },
    { lat: 48.216846, lng: 19.3091431 },
    { lat: 48.2167539, lng: 19.3087067 },
    { lat: 48.2165458, lng: 19.3084821 },
    { lat: 48.2165908, lng: 19.3079906 },
    { lat: 48.2164754, lng: 19.3077479 },
    { lat: 48.2162808, lng: 19.3074993 },
    { lat: 48.2159176, lng: 19.3073064 },
    { lat: 48.2158215, lng: 19.3074617 },
    { lat: 48.2152934, lng: 19.3075474 },
    { lat: 48.2148184, lng: 19.3073256 },
    { lat: 48.2147836, lng: 19.3071679 },
    { lat: 48.2145426, lng: 19.3070791 },
    { lat: 48.2144759, lng: 19.3071206 },
    { lat: 48.2141429, lng: 19.306642 },
    { lat: 48.21375, lng: 19.3073302 },
    { lat: 48.2133544, lng: 19.3075659 },
    { lat: 48.2131451, lng: 19.3075002 },
    { lat: 48.2129853, lng: 19.3072984 },
    { lat: 48.2128855, lng: 19.3073781 },
    { lat: 48.212579, lng: 19.3074042 },
    { lat: 48.2124493, lng: 19.3075408 },
    { lat: 48.2121266, lng: 19.3075104 },
    { lat: 48.2119938, lng: 19.3074208 },
    { lat: 48.2112362, lng: 19.3063917 },
    { lat: 48.2107239, lng: 19.3054 },
    { lat: 48.2106765, lng: 19.3054016 },
    { lat: 48.2106495, lng: 19.3055176 },
    { lat: 48.2104898, lng: 19.3055017 },
    { lat: 48.2095164, lng: 19.30647 },
    { lat: 48.2089715, lng: 19.3056437 },
    { lat: 48.2084561, lng: 19.3051447 },
    { lat: 48.2081706, lng: 19.3044176 },
    { lat: 48.2080123, lng: 19.3042909 },
    { lat: 48.2079732, lng: 19.3041789 },
    { lat: 48.2078704, lng: 19.3042232 },
    { lat: 48.2078053, lng: 19.3041808 },
    { lat: 48.2075655, lng: 19.3045364 },
    { lat: 48.2074578, lng: 19.3048491 },
    { lat: 48.2073966, lng: 19.3049112 },
    { lat: 48.2073614, lng: 19.3048387 },
    { lat: 48.2072678, lng: 19.3049545 },
    { lat: 48.207215, lng: 19.3049121 },
    { lat: 48.2071571, lng: 19.3050449 },
    { lat: 48.2071112, lng: 19.3050039 },
    { lat: 48.2071448, lng: 19.3048572 },
    { lat: 48.2070674, lng: 19.3048634 },
    { lat: 48.2070448, lng: 19.3047733 },
    { lat: 48.2069534, lng: 19.3048857 },
    { lat: 48.2068787, lng: 19.30488 },
    { lat: 48.2068802, lng: 19.3047696 },
    { lat: 48.2068165, lng: 19.3047351 },
    { lat: 48.2067819, lng: 19.3048364 },
    { lat: 48.2066543, lng: 19.3048551 },
    { lat: 48.206723, lng: 19.3050389 },
    { lat: 48.2066428, lng: 19.3049871 },
    { lat: 48.2066216, lng: 19.3050869 },
    { lat: 48.2065619, lng: 19.3049952 },
    { lat: 48.2064616, lng: 19.3050177 },
    { lat: 48.2064368, lng: 19.3051826 },
    { lat: 48.2063328, lng: 19.3050843 },
    { lat: 48.2063312, lng: 19.3052109 },
    { lat: 48.2062165, lng: 19.3051491 },
    { lat: 48.2061912, lng: 19.3052328 },
    { lat: 48.2061262, lng: 19.3051674 },
    { lat: 48.2060344, lng: 19.3052727 },
    { lat: 48.2059494, lng: 19.3052197 },
    { lat: 48.2059151, lng: 19.3052584 },
    { lat: 48.2058592, lng: 19.3051274 },
    { lat: 48.2058043, lng: 19.3052467 },
    { lat: 48.2048709, lng: 19.3030796 },
    { lat: 48.2043547, lng: 19.3012366 },
    { lat: 48.2039582, lng: 19.3002173 },
    { lat: 48.2023021, lng: 19.2992586 },
    { lat: 48.2024337, lng: 19.299044 },
    { lat: 48.2024556, lng: 19.2988671 },
    { lat: 48.2023003, lng: 19.2985659 },
    { lat: 48.2022884, lng: 19.2983707 },
    { lat: 48.202687, lng: 19.2981724 },
    { lat: 48.2028602, lng: 19.2979781 },
    { lat: 48.2006608, lng: 19.2955067 },
    { lat: 48.199808, lng: 19.29433 },
    { lat: 48.1997649, lng: 19.2938835 },
    { lat: 48.1999147, lng: 19.2935288 },
    { lat: 48.1999467, lng: 19.2932819 },
    { lat: 48.1997993, lng: 19.2931502 },
    { lat: 48.1996458, lng: 19.292634 },
    { lat: 48.1994628, lng: 19.2923673 },
    { lat: 48.1988859, lng: 19.2918624 },
    { lat: 48.1989563, lng: 19.2914545 },
    { lat: 48.1989599, lng: 19.2909916 },
    { lat: 48.1988726, lng: 19.2896582 },
    { lat: 48.1976992, lng: 19.2879341 },
    { lat: 48.1973129, lng: 19.2870811 },
    { lat: 48.1962366, lng: 19.2858791 },
    { lat: 48.1961112, lng: 19.2856178 },
    { lat: 48.1950405, lng: 19.2863862 },
    { lat: 48.1949556, lng: 19.2867975 },
    { lat: 48.1949163, lng: 19.2868017 },
    { lat: 48.1949185, lng: 19.2873583 },
    { lat: 48.1948425, lng: 19.2876258 },
    { lat: 48.1948805, lng: 19.2879905 },
    { lat: 48.1945789, lng: 19.288514 },
    { lat: 48.1942213, lng: 19.2887365 },
    { lat: 48.1939497, lng: 19.2893668 },
    { lat: 48.1933701, lng: 19.2896201 },
    { lat: 48.19334, lng: 19.2899738 },
    { lat: 48.1930673, lng: 19.2902535 },
    { lat: 48.1927023, lng: 19.2915394 },
    { lat: 48.1925594, lng: 19.2917771 },
    { lat: 48.1921018, lng: 19.2918931 },
    { lat: 48.1920686, lng: 19.2919971 },
    { lat: 48.1921277, lng: 19.292287 },
    { lat: 48.1920909, lng: 19.2926231 },
    { lat: 48.191977, lng: 19.2929446 },
    { lat: 48.1916621, lng: 19.2931723 },
    { lat: 48.1914843, lng: 19.293548 },
    { lat: 48.1912472, lng: 19.2937259 },
    { lat: 48.1913164, lng: 19.2940862 },
    { lat: 48.1910926, lng: 19.2944446 },
    { lat: 48.1911814, lng: 19.2947409 },
    { lat: 48.1909391, lng: 19.2951363 },
    { lat: 48.1905712, lng: 19.2955255 },
    { lat: 48.1904559, lng: 19.2963766 },
    { lat: 48.1901683, lng: 19.2966526 },
    { lat: 48.1898696, lng: 19.2961423 },
    { lat: 48.189596, lng: 19.2959403 },
    { lat: 48.1876231, lng: 19.2939526 },
    { lat: 48.1870079, lng: 19.2931454 },
    { lat: 48.1868331, lng: 19.2928874 },
    { lat: 48.1862686, lng: 19.291381 },
    { lat: 48.1855845, lng: 19.2904976 },
    { lat: 48.1853526, lng: 19.2903433 },
    { lat: 48.1850761, lng: 19.2907791 },
    { lat: 48.1848764, lng: 19.2909816 },
    { lat: 48.1846828, lng: 19.2910376 },
    { lat: 48.1845832, lng: 19.2910039 },
    { lat: 48.1844777, lng: 19.2914095 },
    { lat: 48.1841088, lng: 19.2918458 },
    { lat: 48.1838557, lng: 19.2923193 },
    { lat: 48.1837076, lng: 19.2926801 },
    { lat: 48.1836271, lng: 19.2931586 },
    { lat: 48.1835403, lng: 19.2933467 },
    { lat: 48.1834348, lng: 19.2934009 },
    { lat: 48.1829428, lng: 19.2943923 },
    { lat: 48.1820331, lng: 19.2962762 },
    { lat: 48.1818835, lng: 19.2966785 },
    { lat: 48.1818325, lng: 19.2973226 },
    { lat: 48.1818838, lng: 19.29871 },
    { lat: 48.1817567, lng: 19.2998554 },
    { lat: 48.1815576, lng: 19.300305 },
    { lat: 48.1817454, lng: 19.3005298 },
    { lat: 48.1817177, lng: 19.3007733 },
    { lat: 48.1822737, lng: 19.3014068 },
    { lat: 48.1827779, lng: 19.3018785 },
    { lat: 48.1821048, lng: 19.3035548 },
    { lat: 48.1818253, lng: 19.3041392 },
    { lat: 48.1812256, lng: 19.3051647 },
    { lat: 48.1797576, lng: 19.3068304 },
    { lat: 48.1795046, lng: 19.307195 },
    { lat: 48.1792009, lng: 19.3077671 },
    { lat: 48.179018, lng: 19.308181 },
    { lat: 48.1784625, lng: 19.3099331 },
    { lat: 48.1780418, lng: 19.3119799 },
    { lat: 48.1785615, lng: 19.3125446 },
    { lat: 48.1803816, lng: 19.3139119 },
    { lat: 48.180409, lng: 19.3143674 },
    { lat: 48.1802128, lng: 19.3148099 },
    { lat: 48.1801663, lng: 19.3152204 },
    { lat: 48.1802646, lng: 19.3156714 },
    { lat: 48.1801289, lng: 19.3165502 },
    { lat: 48.1800175, lng: 19.316997 },
    { lat: 48.1800376, lng: 19.3176454 },
    { lat: 48.1800919, lng: 19.3179768 },
    { lat: 48.1803573, lng: 19.3185284 },
    { lat: 48.1802571, lng: 19.3187377 },
    { lat: 48.1818397, lng: 19.3212858 },
    { lat: 48.1819912, lng: 19.3216947 },
    { lat: 48.1818049, lng: 19.3222001 },
    { lat: 48.1831901, lng: 19.325168 },
    { lat: 48.1845617, lng: 19.3285127 },
    { lat: 48.184789, lng: 19.3292324 },
    { lat: 48.1868514, lng: 19.333678 },
    { lat: 48.1887994, lng: 19.3327479 },
    { lat: 48.1902229, lng: 19.3322273 },
    { lat: 48.1903424, lng: 19.3322318 },
    { lat: 48.1914811, lng: 19.3331248 },
    { lat: 48.1916507, lng: 19.3333705 },
    { lat: 48.1923307, lng: 19.3337971 },
    { lat: 48.1923082, lng: 19.3337275 },
    { lat: 48.1927514, lng: 19.3339469 },
    { lat: 48.1941535, lng: 19.3348131 },
    { lat: 48.1947721, lng: 19.3351111 },
    { lat: 48.1952272, lng: 19.3348714 },
    { lat: 48.1960656, lng: 19.3342848 },
    { lat: 48.1971861, lng: 19.3336065 },
    { lat: 48.2057668, lng: 19.3279093 },
    { lat: 48.2093946, lng: 19.3244335 },
    { lat: 48.2108141, lng: 19.3231528 },
    { lat: 48.2110755, lng: 19.3226627 },
    { lat: 48.2113822, lng: 19.3224056 },
    { lat: 48.2114856, lng: 19.3221432 },
    { lat: 48.2119774, lng: 19.3213063 },
    { lat: 48.2122263, lng: 19.3211194 },
    { lat: 48.2123618, lng: 19.3210535 },
    { lat: 48.2124166, lng: 19.321113 },
    { lat: 48.2127588, lng: 19.3209156 },
    { lat: 48.2129341, lng: 19.3206041 },
    { lat: 48.2131615, lng: 19.3202933 },
    { lat: 48.2133408, lng: 19.3201524 },
    { lat: 48.2136238, lng: 19.3196621 },
    { lat: 48.2137541, lng: 19.3195388 },
    { lat: 48.2138863, lng: 19.3191941 },
    { lat: 48.214887, lng: 19.3192618 },
    { lat: 48.2151422, lng: 19.3192201 },
    { lat: 48.2156354, lng: 19.320008 },
    { lat: 48.2172759, lng: 19.3191743 },
    { lat: 48.2195952, lng: 19.3181852 },
    { lat: 48.2197927, lng: 19.3180497 },
    { lat: 48.2203106, lng: 19.318132 },
    { lat: 48.22093, lng: 19.3179086 },
    { lat: 48.2225425, lng: 19.3171039 },
    { lat: 48.2229252, lng: 19.3168374 },
    { lat: 48.2235389, lng: 19.3167294 },
    { lat: 48.2239572, lng: 19.3164506 },
    { lat: 48.2248097, lng: 19.3160846 },
    { lat: 48.2260008, lng: 19.3153111 },
    { lat: 48.2262363, lng: 19.3152125 },
    { lat: 48.2277336, lng: 19.3150819 },
    { lat: 48.2282221, lng: 19.3148573 },
    { lat: 48.2283323, lng: 19.3148065 },
  ],
  HornéStrháre: [
    { lat: 48.2835315, lng: 19.2944663 },
    { lat: 48.2833673, lng: 19.2950581 },
    { lat: 48.2834298, lng: 19.2952998 },
    { lat: 48.2835405, lng: 19.2954927 },
    { lat: 48.2835748, lng: 19.2957583 },
    { lat: 48.2835138, lng: 19.2958509 },
    { lat: 48.2835591, lng: 19.296392 },
    { lat: 48.2834837, lng: 19.2964639 },
    { lat: 48.2835015, lng: 19.2966467 },
    { lat: 48.2834016, lng: 19.2970281 },
    { lat: 48.2834403, lng: 19.2971484 },
    { lat: 48.2833555, lng: 19.2977748 },
    { lat: 48.2834337, lng: 19.2980395 },
    { lat: 48.2835155, lng: 19.2990329 },
    { lat: 48.2832102, lng: 19.3012743 },
    { lat: 48.2829512, lng: 19.3022391 },
    { lat: 48.2829703, lng: 19.3034955 },
    { lat: 48.2829255, lng: 19.3046912 },
    { lat: 48.2827821, lng: 19.3074666 },
    { lat: 48.2826243, lng: 19.3095713 },
    { lat: 48.2827302, lng: 19.3096855 },
    { lat: 48.2828676, lng: 19.3101303 },
    { lat: 48.2828119, lng: 19.3103504 },
    { lat: 48.2826183, lng: 19.3107111 },
    { lat: 48.2833381, lng: 19.3134126 },
    { lat: 48.2822341, lng: 19.3136814 },
    { lat: 48.281585, lng: 19.3139211 },
    { lat: 48.2809859, lng: 19.3143738 },
    { lat: 48.2809519, lng: 19.314462 },
    { lat: 48.2807553, lng: 19.3143141 },
    { lat: 48.2804507, lng: 19.3144975 },
    { lat: 48.2803364, lng: 19.3145077 },
    { lat: 48.280142, lng: 19.3141696 },
    { lat: 48.2798959, lng: 19.3141838 },
    { lat: 48.2797812, lng: 19.3140971 },
    { lat: 48.2796877, lng: 19.3141716 },
    { lat: 48.2789332, lng: 19.3141853 },
    { lat: 48.2787269, lng: 19.314157 },
    { lat: 48.2784877, lng: 19.314018 },
    { lat: 48.278305, lng: 19.3141521 },
    { lat: 48.2781927, lng: 19.3140899 },
    { lat: 48.2780297, lng: 19.3142183 },
    { lat: 48.2778539, lng: 19.3144521 },
    { lat: 48.2776515, lng: 19.3145553 },
    { lat: 48.2773964, lng: 19.3149909 },
    { lat: 48.277039, lng: 19.3152012 },
    { lat: 48.2767003, lng: 19.3150977 },
    { lat: 48.2763095, lng: 19.3153346 },
    { lat: 48.2761295, lng: 19.3153215 },
    { lat: 48.2759223, lng: 19.3151627 },
    { lat: 48.2754498, lng: 19.3152485 },
    { lat: 48.27532, lng: 19.3154313 },
    { lat: 48.2752198, lng: 19.3154595 },
    { lat: 48.2751339, lng: 19.3153152 },
    { lat: 48.2749671, lng: 19.3153131 },
    { lat: 48.2748834, lng: 19.315591 },
    { lat: 48.2747817, lng: 19.3157464 },
    { lat: 48.2746217, lng: 19.3157042 },
    { lat: 48.2745169, lng: 19.315775 },
    { lat: 48.274406, lng: 19.3159972 },
    { lat: 48.2742334, lng: 19.3161793 },
    { lat: 48.2739951, lng: 19.3162631 },
    { lat: 48.2737266, lng: 19.3167493 },
    { lat: 48.2733743, lng: 19.3168549 },
    { lat: 48.2731025, lng: 19.3171111 },
    { lat: 48.2729896, lng: 19.3174357 },
    { lat: 48.272989, lng: 19.3176088 },
    { lat: 48.2728397, lng: 19.3178333 },
    { lat: 48.2724477, lng: 19.3181393 },
    { lat: 48.2723546, lng: 19.3185704 },
    { lat: 48.2720056, lng: 19.3189789 },
    { lat: 48.2717836, lng: 19.3190973 },
    { lat: 48.2715471, lng: 19.3195927 },
    { lat: 48.2711626, lng: 19.3196123 },
    { lat: 48.2710132, lng: 19.3197427 },
    { lat: 48.2708149, lng: 19.3202107 },
    { lat: 48.2706729, lng: 19.3202087 },
    { lat: 48.2704368, lng: 19.3204111 },
    { lat: 48.2700916, lng: 19.3202616 },
    { lat: 48.2699891, lng: 19.3203123 },
    { lat: 48.2693707, lng: 19.3209525 },
    { lat: 48.2693768, lng: 19.3211249 },
    { lat: 48.2695026, lng: 19.3213519 },
    { lat: 48.2695371, lng: 19.3215979 },
    { lat: 48.269483, lng: 19.3218337 },
    { lat: 48.269612, lng: 19.3225465 },
    { lat: 48.2696961, lng: 19.3226793 },
    { lat: 48.269739, lng: 19.322986 },
    { lat: 48.2698644, lng: 19.3231327 },
    { lat: 48.2699528, lng: 19.323443 },
    { lat: 48.270161, lng: 19.3236041 },
    { lat: 48.2703538, lng: 19.3236286 },
    { lat: 48.270486, lng: 19.3239869 },
    { lat: 48.2706854, lng: 19.3239381 },
    { lat: 48.2708851, lng: 19.3244798 },
    { lat: 48.2712472, lng: 19.3244856 },
    { lat: 48.2717962, lng: 19.3247678 },
    { lat: 48.2702101, lng: 19.3281652 },
    { lat: 48.2695215, lng: 19.3309296 },
    { lat: 48.2696758, lng: 19.3323603 },
    { lat: 48.2697104, lng: 19.3332435 },
    { lat: 48.2689212, lng: 19.334859 },
    { lat: 48.2686511, lng: 19.3357138 },
    { lat: 48.2685405, lng: 19.3366268 },
    { lat: 48.2681374, lng: 19.3373626 },
    { lat: 48.2678582, lng: 19.3382618 },
    { lat: 48.2672655, lng: 19.3391559 },
    { lat: 48.2666642, lng: 19.339521 },
    { lat: 48.2659621, lng: 19.3397586 },
    { lat: 48.2649954, lng: 19.3403052 },
    { lat: 48.2645502, lng: 19.3398631 },
    { lat: 48.2640944, lng: 19.3397371 },
    { lat: 48.2634931, lng: 19.339816 },
    { lat: 48.2632431, lng: 19.3399161 },
    { lat: 48.2624071, lng: 19.3405105 },
    { lat: 48.2608536, lng: 19.3419171 },
    { lat: 48.2606163, lng: 19.3423271 },
    { lat: 48.2599148, lng: 19.3431393 },
    { lat: 48.2587067, lng: 19.3443159 },
    { lat: 48.2581709, lng: 19.3452241 },
    { lat: 48.2578048, lng: 19.3455853 },
    { lat: 48.2569719, lng: 19.3466411 },
    { lat: 48.256598, lng: 19.3470258 },
    { lat: 48.2557707, lng: 19.347919 },
    { lat: 48.2552901, lng: 19.3486596 },
    { lat: 48.2547786, lng: 19.3492783 },
    { lat: 48.2536687, lng: 19.3496614 },
    { lat: 48.2531635, lng: 19.3500311 },
    { lat: 48.2529305, lng: 19.3500869 },
    { lat: 48.2526825, lng: 19.3503696 },
    { lat: 48.2526096, lng: 19.3507742 },
    { lat: 48.2526367, lng: 19.3514089 },
    { lat: 48.2529078, lng: 19.3519407 },
    { lat: 48.2531703, lng: 19.3531617 },
    { lat: 48.2534618, lng: 19.3529412 },
    { lat: 48.2535982, lng: 19.3532257 },
    { lat: 48.253739, lng: 19.3536937 },
    { lat: 48.2535931, lng: 19.3540358 },
    { lat: 48.2533276, lng: 19.3543769 },
    { lat: 48.2528122, lng: 19.3552195 },
    { lat: 48.2519539, lng: 19.3569755 },
    { lat: 48.2516967, lng: 19.3573225 },
    { lat: 48.2508997, lng: 19.3581442 },
    { lat: 48.2506465, lng: 19.3582725 },
    { lat: 48.2503207, lng: 19.3585522 },
    { lat: 48.2501266, lng: 19.3589722 },
    { lat: 48.2497797, lng: 19.3591912 },
    { lat: 48.2499663, lng: 19.3597943 },
    { lat: 48.2504542, lng: 19.3603301 },
    { lat: 48.2502866, lng: 19.3606197 },
    { lat: 48.2495687, lng: 19.3623901 },
    { lat: 48.2493437, lng: 19.3627279 },
    { lat: 48.2492987, lng: 19.3628738 },
    { lat: 48.2490552, lng: 19.3630612 },
    { lat: 48.248948, lng: 19.3632951 },
    { lat: 48.2488066, lng: 19.3638934 },
    { lat: 48.2485788, lng: 19.364486 },
    { lat: 48.2485793, lng: 19.3648467 },
    { lat: 48.2488432, lng: 19.3655596 },
    { lat: 48.2490686, lng: 19.3657491 },
    { lat: 48.2494261, lng: 19.3658466 },
    { lat: 48.2499669, lng: 19.3661034 },
    { lat: 48.2505215, lng: 19.3666473 },
    { lat: 48.2508745, lng: 19.3665576 },
    { lat: 48.2518112, lng: 19.3669368 },
    { lat: 48.2520453, lng: 19.3669043 },
    { lat: 48.2519969, lng: 19.3675673 },
    { lat: 48.2520688, lng: 19.3687408 },
    { lat: 48.2521393, lng: 19.369229 },
    { lat: 48.2521691, lng: 19.3714811 },
    { lat: 48.2522545, lng: 19.3722574 },
    { lat: 48.2522738, lng: 19.37337 },
    { lat: 48.2522587, lng: 19.3736181 },
    { lat: 48.2520014, lng: 19.3746309 },
    { lat: 48.2518271, lng: 19.3757493 },
    { lat: 48.2518522, lng: 19.3758761 },
    { lat: 48.2524476, lng: 19.3767768 },
    { lat: 48.2529675, lng: 19.3773536 },
    { lat: 48.2540764, lng: 19.3788156 },
    { lat: 48.2551584, lng: 19.3803538 },
    { lat: 48.2589817, lng: 19.3852104 },
    { lat: 48.2603751, lng: 19.3870902 },
    { lat: 48.2604166, lng: 19.387135 },
    { lat: 48.2611656, lng: 19.3862231 },
    { lat: 48.2614958, lng: 19.3860086 },
    { lat: 48.2617147, lng: 19.3859378 },
    { lat: 48.2624063, lng: 19.3870476 },
    { lat: 48.2628533, lng: 19.387488 },
    { lat: 48.2627671, lng: 19.3876408 },
    { lat: 48.2631863, lng: 19.3880892 },
    { lat: 48.2634735, lng: 19.3874915 },
    { lat: 48.2641996, lng: 19.3867392 },
    { lat: 48.2645781, lng: 19.3862853 },
    { lat: 48.2647778, lng: 19.3859608 },
    { lat: 48.2655326, lng: 19.3850971 },
    { lat: 48.2656849, lng: 19.3848424 },
    { lat: 48.266264, lng: 19.3835323 },
    { lat: 48.2668563, lng: 19.3817251 },
    { lat: 48.2692981, lng: 19.3780872 },
    { lat: 48.2702913, lng: 19.3762695 },
    { lat: 48.270529, lng: 19.375677 },
    { lat: 48.2708588, lng: 19.3751978 },
    { lat: 48.2712324, lng: 19.374757 },
    { lat: 48.2713852, lng: 19.3746533 },
    { lat: 48.271541, lng: 19.3742796 },
    { lat: 48.271833, lng: 19.3737864 },
    { lat: 48.2719732, lng: 19.3734172 },
    { lat: 48.272475, lng: 19.3725919 },
    { lat: 48.2728366, lng: 19.3715478 },
    { lat: 48.2730335, lng: 19.3711393 },
    { lat: 48.2737225, lng: 19.3702992 },
    { lat: 48.2743189, lng: 19.3699 },
    { lat: 48.2753719, lng: 19.3688911 },
    { lat: 48.2762194, lng: 19.3684366 },
    { lat: 48.2763465, lng: 19.368458 },
    { lat: 48.2771646, lng: 19.3680757 },
    { lat: 48.2773428, lng: 19.3679158 },
    { lat: 48.277471, lng: 19.3677163 },
    { lat: 48.2775912, lng: 19.3673107 },
    { lat: 48.2781582, lng: 19.3666506 },
    { lat: 48.2785149, lng: 19.3660487 },
    { lat: 48.2791114, lng: 19.3645627 },
    { lat: 48.2795378, lng: 19.3633419 },
    { lat: 48.2798158, lng: 19.362937 },
    { lat: 48.2802587, lng: 19.3626008 },
    { lat: 48.2804537, lng: 19.3622247 },
    { lat: 48.2808179, lng: 19.3608831 },
    { lat: 48.2810727, lng: 19.3602266 },
    { lat: 48.2812669, lng: 19.3599694 },
    { lat: 48.2821188, lng: 19.3583635 },
    { lat: 48.2827972, lng: 19.3574652 },
    { lat: 48.283191, lng: 19.3573588 },
    { lat: 48.2838406, lng: 19.3569406 },
    { lat: 48.2843816, lng: 19.3570828 },
    { lat: 48.2845801, lng: 19.3570148 },
    { lat: 48.2845964, lng: 19.356788 },
    { lat: 48.2850195, lng: 19.3566569 },
    { lat: 48.2851049, lng: 19.3565798 },
    { lat: 48.285588, lng: 19.3565963 },
    { lat: 48.286124, lng: 19.3566095 },
    { lat: 48.286467, lng: 19.356145 },
    { lat: 48.286404, lng: 19.355781 },
    { lat: 48.286306, lng: 19.355206 },
    { lat: 48.286301, lng: 19.355179 },
    { lat: 48.286298, lng: 19.355166 },
    { lat: 48.286092, lng: 19.354004 },
    { lat: 48.285934, lng: 19.353099 },
    { lat: 48.285765, lng: 19.352234 },
    { lat: 48.285087, lng: 19.350721 },
    { lat: 48.284674, lng: 19.350082 },
    { lat: 48.284151, lng: 19.348895 },
    { lat: 48.284133, lng: 19.348855 },
    { lat: 48.284129, lng: 19.348847 },
    { lat: 48.284318, lng: 19.348406 },
    { lat: 48.284818, lng: 19.347247 },
    { lat: 48.285133, lng: 19.346522 },
    { lat: 48.285147, lng: 19.346466 },
    { lat: 48.284777, lng: 19.346332 },
    { lat: 48.285219, lng: 19.345211 },
    { lat: 48.284953, lng: 19.344998 },
    { lat: 48.284894, lng: 19.344983 },
    { lat: 48.284704, lng: 19.344827 },
    { lat: 48.284412, lng: 19.344659 },
    { lat: 48.284167, lng: 19.344539 },
    { lat: 48.283942, lng: 19.344254 },
    { lat: 48.283814, lng: 19.344008 },
    { lat: 48.283658, lng: 19.343986 },
    { lat: 48.283555, lng: 19.343828 },
    { lat: 48.283458, lng: 19.343733 },
    { lat: 48.283303, lng: 19.343478 },
    { lat: 48.283141, lng: 19.343326 },
    { lat: 48.282994, lng: 19.343232 },
    { lat: 48.282894, lng: 19.34309 },
    { lat: 48.282703, lng: 19.34236 },
    { lat: 48.282647, lng: 19.342292 },
    { lat: 48.282046, lng: 19.341534 },
    { lat: 48.282044, lng: 19.341516 },
    { lat: 48.282032, lng: 19.341419 },
    { lat: 48.282032, lng: 19.341418 },
    { lat: 48.282025, lng: 19.341366 },
    { lat: 48.282091, lng: 19.341151 },
    { lat: 48.282264, lng: 19.340713 },
    { lat: 48.282476, lng: 19.340336 },
    { lat: 48.282583, lng: 19.340264 },
    { lat: 48.282595, lng: 19.340068 },
    { lat: 48.282966, lng: 19.33965 },
    { lat: 48.282924, lng: 19.339565 },
    { lat: 48.282927, lng: 19.339545 },
    { lat: 48.28297, lng: 19.33925 },
    { lat: 48.282879, lng: 19.3384 },
    { lat: 48.282819, lng: 19.338228 },
    { lat: 48.28281, lng: 19.337989 },
    { lat: 48.282853, lng: 19.337531 },
    { lat: 48.282912, lng: 19.337179 },
    { lat: 48.282977, lng: 19.336958 },
    { lat: 48.283412, lng: 19.336001 },
    { lat: 48.283666, lng: 19.3356 },
    { lat: 48.28388, lng: 19.33503 },
    { lat: 48.28399, lng: 19.334407 },
    { lat: 48.284008, lng: 19.334112 },
    { lat: 48.284011, lng: 19.333743 },
    { lat: 48.284057, lng: 19.333295 },
    { lat: 48.283895, lng: 19.331973 },
    { lat: 48.283835, lng: 19.331548 },
    { lat: 48.283732, lng: 19.330944 },
    { lat: 48.283732, lng: 19.330307 },
    { lat: 48.283744, lng: 19.329658 },
    { lat: 48.283745, lng: 19.328871 },
    { lat: 48.28355, lng: 19.327911 },
    { lat: 48.283463, lng: 19.327754 },
    { lat: 48.283435, lng: 19.327606 },
    { lat: 48.283399, lng: 19.327331 },
    { lat: 48.283392, lng: 19.327267 },
    { lat: 48.283411, lng: 19.327215 },
    { lat: 48.283205, lng: 19.32683 },
    { lat: 48.28302, lng: 19.32661 },
    { lat: 48.282943, lng: 19.326473 },
    { lat: 48.282797, lng: 19.326212 },
    { lat: 48.282587, lng: 19.325926 },
    { lat: 48.282467, lng: 19.325805 },
    { lat: 48.282448, lng: 19.325829 },
    { lat: 48.282421, lng: 19.325723 },
    { lat: 48.282468, lng: 19.325396 },
    { lat: 48.282587, lng: 19.324568 },
    { lat: 48.282587, lng: 19.324566 },
    { lat: 48.282652, lng: 19.324117 },
    { lat: 48.282662, lng: 19.324033 },
    { lat: 48.282667, lng: 19.323993 },
    { lat: 48.282719, lng: 19.323619 },
    { lat: 48.282719, lng: 19.323618 },
    { lat: 48.282757, lng: 19.323354 },
    { lat: 48.28284, lng: 19.322773 },
    { lat: 48.28284, lng: 19.322772 },
    { lat: 48.282938, lng: 19.322079 },
    { lat: 48.282948, lng: 19.321996 },
    { lat: 48.282958, lng: 19.321936 },
    { lat: 48.282968, lng: 19.321891 },
    { lat: 48.282988, lng: 19.321784 },
    { lat: 48.282988, lng: 19.321782 },
    { lat: 48.282996, lng: 19.32174 },
    { lat: 48.282996, lng: 19.321739 },
    { lat: 48.283249, lng: 19.320433 },
    { lat: 48.283503, lng: 19.319128 },
    { lat: 48.283741, lng: 19.317897 },
    { lat: 48.283898, lng: 19.317084 },
    { lat: 48.284331, lng: 19.314847 },
    { lat: 48.284332, lng: 19.314846 },
    { lat: 48.284358, lng: 19.314707 },
    { lat: 48.284386, lng: 19.314558 },
    { lat: 48.284536, lng: 19.314045 },
    { lat: 48.284556, lng: 19.313981 },
    { lat: 48.284651, lng: 19.313624 },
    { lat: 48.284726, lng: 19.313767 },
    { lat: 48.285009, lng: 19.313868 },
    { lat: 48.285056, lng: 19.313885 },
    { lat: 48.285189, lng: 19.313932 },
    { lat: 48.285501, lng: 19.314043 },
    { lat: 48.285608, lng: 19.313806 },
    { lat: 48.285691, lng: 19.313511 },
    { lat: 48.285685, lng: 19.313499 },
    { lat: 48.285627, lng: 19.313462 },
    { lat: 48.285633, lng: 19.313443 },
    { lat: 48.285683, lng: 19.313261 },
    { lat: 48.285741, lng: 19.313055 },
    { lat: 48.285792, lng: 19.31287 },
    { lat: 48.28583, lng: 19.312729 },
    { lat: 48.285851, lng: 19.312655 },
    { lat: 48.28591, lng: 19.312441 },
    { lat: 48.285533, lng: 19.312196 },
    { lat: 48.28545, lng: 19.312143 },
    { lat: 48.285378, lng: 19.312097 },
    { lat: 48.285139, lng: 19.311871 },
    { lat: 48.285338, lng: 19.311171 },
    { lat: 48.285408, lng: 19.310919 },
    { lat: 48.285466, lng: 19.310713 },
    { lat: 48.285587, lng: 19.310291 },
    { lat: 48.285669, lng: 19.310002 },
    { lat: 48.285745, lng: 19.30973 },
    { lat: 48.28579, lng: 19.309572 },
    { lat: 48.286206, lng: 19.308136 },
    { lat: 48.286453, lng: 19.307266 },
    { lat: 48.286793, lng: 19.306074 },
    { lat: 48.286867, lng: 19.305813 },
    { lat: 48.287149, lng: 19.304808 },
    { lat: 48.287181, lng: 19.304691 },
    { lat: 48.287208, lng: 19.304613 },
    { lat: 48.287338, lng: 19.304238 },
    { lat: 48.287569, lng: 19.30378 },
    { lat: 48.28757, lng: 19.303778 },
    { lat: 48.287722, lng: 19.303488 },
    { lat: 48.287945, lng: 19.303097 },
    { lat: 48.287977, lng: 19.303039 },
    { lat: 48.288011, lng: 19.302979 },
    { lat: 48.288543, lng: 19.301925 },
    { lat: 48.289207, lng: 19.300647 },
    { lat: 48.289471, lng: 19.30015 },
    { lat: 48.289639, lng: 19.299836 },
    { lat: 48.28991, lng: 19.299452 },
    { lat: 48.290129, lng: 19.299141 },
    { lat: 48.290428, lng: 19.298854 },
    { lat: 48.291119, lng: 19.298257 },
    { lat: 48.291902, lng: 19.297597 },
    { lat: 48.291949, lng: 19.297545 },
    { lat: 48.291988, lng: 19.297502 },
    { lat: 48.292049, lng: 19.297434 },
    { lat: 48.292193, lng: 19.297195 },
    { lat: 48.292278, lng: 19.297032 },
    { lat: 48.292288, lng: 19.297014 },
    { lat: 48.292374, lng: 19.29685 },
    { lat: 48.292424, lng: 19.2967 },
    { lat: 48.292486, lng: 19.296473 },
    { lat: 48.292501, lng: 19.296154 },
    { lat: 48.292433, lng: 19.295587 },
    { lat: 48.292301, lng: 19.294919 },
    { lat: 48.292235, lng: 19.294534 },
    { lat: 48.292122, lng: 19.293969 },
    { lat: 48.292046, lng: 19.293602 },
    { lat: 48.29198, lng: 19.293252 },
    { lat: 48.291981, lng: 19.292396 },
    { lat: 48.291944, lng: 19.292028 },
    { lat: 48.291855, lng: 19.291679 },
    { lat: 48.291841, lng: 19.291624 },
    { lat: 48.291786, lng: 19.291459 },
    { lat: 48.2913075, lng: 19.2916168 },
    { lat: 48.2911677, lng: 19.2918225 },
    { lat: 48.2910645, lng: 19.2922089 },
    { lat: 48.290782, lng: 19.2921199 },
    { lat: 48.2904325, lng: 19.292258 },
    { lat: 48.290183, lng: 19.2920908 },
    { lat: 48.2900684, lng: 19.2918166 },
    { lat: 48.2897361, lng: 19.2915437 },
    { lat: 48.2891606, lng: 19.2918355 },
    { lat: 48.2887187, lng: 19.2919044 },
    { lat: 48.2882948, lng: 19.2924817 },
    { lat: 48.2880942, lng: 19.2925778 },
    { lat: 48.2877497, lng: 19.2924447 },
    { lat: 48.2875692, lng: 19.2926964 },
    { lat: 48.2874591, lng: 19.293268 },
    { lat: 48.2873573, lng: 19.2935597 },
    { lat: 48.2871176, lng: 19.2936636 },
    { lat: 48.286655, lng: 19.2940727 },
    { lat: 48.2865961, lng: 19.2942058 },
    { lat: 48.2865365, lng: 19.2943963 },
    { lat: 48.2866439, lng: 19.2949353 },
    { lat: 48.2865966, lng: 19.2950565 },
    { lat: 48.2862098, lng: 19.2950288 },
    { lat: 48.2859064, lng: 19.2951194 },
    { lat: 48.2856575, lng: 19.2950035 },
    { lat: 48.2854353, lng: 19.295259 },
    { lat: 48.2854517, lng: 19.2953875 },
    { lat: 48.2853748, lng: 19.2955218 },
    { lat: 48.2852223, lng: 19.2956067 },
    { lat: 48.2846886, lng: 19.295087 },
    { lat: 48.2845218, lng: 19.2948072 },
    { lat: 48.2842476, lng: 19.2945881 },
    { lat: 48.2836448, lng: 19.2945725 },
    { lat: 48.2835315, lng: 19.2944663 },
  ],
  VeľkáVesnadIpľom: [
    { lat: 48.0984262, lng: 19.1028946 },
    { lat: 48.098327, lng: 19.1026934 },
    { lat: 48.0980747, lng: 19.1023164 },
    { lat: 48.0979041, lng: 19.1022406 },
    { lat: 48.0972406, lng: 19.1024552 },
    { lat: 48.0969069, lng: 19.1024094 },
    { lat: 48.0945447, lng: 19.1015735 },
    { lat: 48.0941871, lng: 19.1013868 },
    { lat: 48.0938382, lng: 19.1011275 },
    { lat: 48.0934844, lng: 19.1005524 },
    { lat: 48.0928206, lng: 19.0992554 },
    { lat: 48.0918157, lng: 19.0976555 },
    { lat: 48.0914066, lng: 19.0973406 },
    { lat: 48.0907031, lng: 19.0969381 },
    { lat: 48.0889527, lng: 19.0960971 },
    { lat: 48.087672, lng: 19.095157 },
    { lat: 48.0864341, lng: 19.0874275 },
    { lat: 48.0859708, lng: 19.084164 },
    { lat: 48.0869603, lng: 19.0839549 },
    { lat: 48.0874052, lng: 19.0837445 },
    { lat: 48.0878583, lng: 19.0836319 },
    { lat: 48.0882209, lng: 19.0833786 },
    { lat: 48.0883121, lng: 19.0819875 },
    { lat: 48.0884427, lng: 19.0812214 },
    { lat: 48.088982, lng: 19.0794221 },
    { lat: 48.0891161, lng: 19.0787264 },
    { lat: 48.0895768, lng: 19.0787173 },
    { lat: 48.0896981, lng: 19.0781494 },
    { lat: 48.0897636, lng: 19.0772368 },
    { lat: 48.0898433, lng: 19.07714 },
    { lat: 48.0903683, lng: 19.0769125 },
    { lat: 48.0905239, lng: 19.0762839 },
    { lat: 48.0905571, lng: 19.0752226 },
    { lat: 48.0906176, lng: 19.074971 },
    { lat: 48.0914462, lng: 19.0732927 },
    { lat: 48.0915943, lng: 19.0725197 },
    { lat: 48.0921917, lng: 19.071848 },
    { lat: 48.0924164, lng: 19.0714105 },
    { lat: 48.0925887, lng: 19.0712236 },
    { lat: 48.0933907, lng: 19.0711005 },
    { lat: 48.0936966, lng: 19.0703703 },
    { lat: 48.09387, lng: 19.0700757 },
    { lat: 48.0941208, lng: 19.0699054 },
    { lat: 48.0944754, lng: 19.0698528 },
    { lat: 48.095389, lng: 19.0689656 },
    { lat: 48.0955816, lng: 19.0674884 },
    { lat: 48.0956745, lng: 19.0670748 },
    { lat: 48.0962953, lng: 19.0651283 },
    { lat: 48.0967444, lng: 19.0643332 },
    { lat: 48.0969184, lng: 19.0642713 },
    { lat: 48.0971006, lng: 19.064061 },
    { lat: 48.0972576, lng: 19.0634417 },
    { lat: 48.0977408, lng: 19.0625894 },
    { lat: 48.0988797, lng: 19.0611434 },
    { lat: 48.0988363, lng: 19.0611088 },
    { lat: 48.0988512, lng: 19.0585733 },
    { lat: 48.099138, lng: 19.0578497 },
    { lat: 48.0996945, lng: 19.0566812 },
    { lat: 48.1003816, lng: 19.0555769 },
    { lat: 48.10088, lng: 19.05333 },
    { lat: 48.1008952, lng: 19.0530295 },
    { lat: 48.1010824, lng: 19.0526073 },
    { lat: 48.1024821, lng: 19.0506599 },
    { lat: 48.1025094, lng: 19.050266 },
    { lat: 48.1030358, lng: 19.0480154 },
    { lat: 48.1050079, lng: 19.0428428 },
    { lat: 48.108906, lng: 19.030044 },
    { lat: 48.108654, lng: 19.029795 },
    { lat: 48.108589, lng: 19.029731 },
    { lat: 48.108494, lng: 19.029635 },
    { lat: 48.107706, lng: 19.028856 },
    { lat: 48.107366, lng: 19.028544 },
    { lat: 48.106883, lng: 19.028047 },
    { lat: 48.106776, lng: 19.027939 },
    { lat: 48.106551, lng: 19.02771 },
    { lat: 48.105938, lng: 19.027021 },
    { lat: 48.105358, lng: 19.026416 },
    { lat: 48.104631, lng: 19.025638 },
    { lat: 48.104605, lng: 19.025611 },
    { lat: 48.10457, lng: 19.025578 },
    { lat: 48.103814, lng: 19.024841 },
    { lat: 48.103057, lng: 19.023987 },
    { lat: 48.102357, lng: 19.02321 },
    { lat: 48.102331, lng: 19.023196 },
    { lat: 48.102135, lng: 19.023351 },
    { lat: 48.101996, lng: 19.023515 },
    { lat: 48.101943, lng: 19.023579 },
    { lat: 48.101584, lng: 19.024002 },
    { lat: 48.1014, lng: 19.024129 },
    { lat: 48.101275, lng: 19.024176 },
    { lat: 48.101105, lng: 19.024243 },
    { lat: 48.101001, lng: 19.024326 },
    { lat: 48.100718, lng: 19.024556 },
    { lat: 48.100578, lng: 19.024648 },
    { lat: 48.100406, lng: 19.024753 },
    { lat: 48.100278, lng: 19.02479 },
    { lat: 48.100147, lng: 19.024975 },
    { lat: 48.100023, lng: 19.025132 },
    { lat: 48.09987, lng: 19.02531 },
    { lat: 48.099571, lng: 19.025612 },
    { lat: 48.099134, lng: 19.025853 },
    { lat: 48.098682, lng: 19.025989 },
    { lat: 48.098297, lng: 19.025984 },
    { lat: 48.098051, lng: 19.025861 },
    { lat: 48.097682, lng: 19.025748 },
    { lat: 48.097431, lng: 19.025786 },
    { lat: 48.097332, lng: 19.025793 },
    { lat: 48.097346, lng: 19.025863 },
    { lat: 48.097299, lng: 19.025917 },
    { lat: 48.0973623, lng: 19.0260439 },
    { lat: 48.0973074, lng: 19.0263161 },
    { lat: 48.0974207, lng: 19.0267497 },
    { lat: 48.097126, lng: 19.0268505 },
    { lat: 48.0971222, lng: 19.0269748 },
    { lat: 48.0972372, lng: 19.0270633 },
    { lat: 48.0974094, lng: 19.0270342 },
    { lat: 48.0974318, lng: 19.0270984 },
    { lat: 48.0974241, lng: 19.0271937 },
    { lat: 48.0972199, lng: 19.0274793 },
    { lat: 48.097245, lng: 19.027638 },
    { lat: 48.0973264, lng: 19.0277109 },
    { lat: 48.0975652, lng: 19.0276585 },
    { lat: 48.0977974, lng: 19.0281686 },
    { lat: 48.0977683, lng: 19.0283059 },
    { lat: 48.0975703, lng: 19.0282032 },
    { lat: 48.097311, lng: 19.0278725 },
    { lat: 48.0971472, lng: 19.0278886 },
    { lat: 48.0971305, lng: 19.0280026 },
    { lat: 48.0971292, lng: 19.0283992 },
    { lat: 48.0972893, lng: 19.0283973 },
    { lat: 48.0974292, lng: 19.0282865 },
    { lat: 48.0974734, lng: 19.0283154 },
    { lat: 48.0975444, lng: 19.0286703 },
    { lat: 48.0976312, lng: 19.0286671 },
    { lat: 48.0976504, lng: 19.0287383 },
    { lat: 48.0976397, lng: 19.029368 },
    { lat: 48.0973569, lng: 19.0294149 },
    { lat: 48.0972736, lng: 19.0295291 },
    { lat: 48.0973336, lng: 19.0297131 },
    { lat: 48.0975398, lng: 19.029755 },
    { lat: 48.0975715, lng: 19.0298198 },
    { lat: 48.0973674, lng: 19.0301082 },
    { lat: 48.0974717, lng: 19.0305852 },
    { lat: 48.0971678, lng: 19.0309744 },
    { lat: 48.0972714, lng: 19.0313052 },
    { lat: 48.0971245, lng: 19.0316967 },
    { lat: 48.09712, lng: 19.0318313 },
    { lat: 48.0977479, lng: 19.0321823 },
    { lat: 48.0978021, lng: 19.0325846 },
    { lat: 48.0975183, lng: 19.0329232 },
    { lat: 48.0974198, lng: 19.0333544 },
    { lat: 48.097482, lng: 19.0337837 },
    { lat: 48.0975173, lng: 19.0338322 },
    { lat: 48.0979554, lng: 19.033778 },
    { lat: 48.0980241, lng: 19.0338238 },
    { lat: 48.0980307, lng: 19.0339765 },
    { lat: 48.0977752, lng: 19.0341381 },
    { lat: 48.0977323, lng: 19.0342699 },
    { lat: 48.0977735, lng: 19.0343915 },
    { lat: 48.0979036, lng: 19.0343112 },
    { lat: 48.0979598, lng: 19.0343531 },
    { lat: 48.0981347, lng: 19.0347999 },
    { lat: 48.0982493, lng: 19.0349721 },
    { lat: 48.0982907, lng: 19.0349649 },
    { lat: 48.0984781, lng: 19.0344581 },
    { lat: 48.0985302, lng: 19.0344842 },
    { lat: 48.0985866, lng: 19.0346884 },
    { lat: 48.098758, lng: 19.0347551 },
    { lat: 48.099104, lng: 19.0347289 },
    { lat: 48.0992215, lng: 19.0345767 },
    { lat: 48.0996633, lng: 19.0344423 },
    { lat: 48.0997255, lng: 19.0344667 },
    { lat: 48.0997868, lng: 19.0346325 },
    { lat: 48.099866, lng: 19.0347022 },
    { lat: 48.1000013, lng: 19.0347476 },
    { lat: 48.10037, lng: 19.034703 },
    { lat: 48.1004443, lng: 19.0373427 },
    { lat: 48.1010353, lng: 19.039075 },
    { lat: 48.1002655, lng: 19.041368 },
    { lat: 48.0999716, lng: 19.0425776 },
    { lat: 48.0998353, lng: 19.0434014 },
    { lat: 48.0991974, lng: 19.0458935 },
    { lat: 48.0977284, lng: 19.0477161 },
    { lat: 48.0978914, lng: 19.0497334 },
    { lat: 48.0967497, lng: 19.0526105 },
    { lat: 48.0959139, lng: 19.0535129 },
    { lat: 48.0943115, lng: 19.0547796 },
    { lat: 48.0930882, lng: 19.0553349 },
    { lat: 48.0922508, lng: 19.0556258 },
    { lat: 48.0911118, lng: 19.0562237 },
    { lat: 48.090824, lng: 19.0564177 },
    { lat: 48.0906886, lng: 19.0566185 },
    { lat: 48.0906315, lng: 19.0565353 },
    { lat: 48.09043, lng: 19.0566304 },
    { lat: 48.0901698, lng: 19.0568572 },
    { lat: 48.0900192, lng: 19.0570699 },
    { lat: 48.0899202, lng: 19.0569845 },
    { lat: 48.0892068, lng: 19.0578552 },
    { lat: 48.08883, lng: 19.0589422 },
    { lat: 48.0886585, lng: 19.0597401 },
    { lat: 48.0885598, lng: 19.0599995 },
    { lat: 48.0884361, lng: 19.0601587 },
    { lat: 48.08833, lng: 19.0601347 },
    { lat: 48.0880776, lng: 19.0606702 },
    { lat: 48.0878839, lng: 19.0611893 },
    { lat: 48.0877909, lng: 19.061626 },
    { lat: 48.0873693, lng: 19.0618478 },
    { lat: 48.0873528, lng: 19.0617459 },
    { lat: 48.0869274, lng: 19.0620926 },
    { lat: 48.0863122, lng: 19.0624807 },
    { lat: 48.0858816, lng: 19.0630183 },
    { lat: 48.085168, lng: 19.0633067 },
    { lat: 48.0849996, lng: 19.0634339 },
    { lat: 48.0836144, lng: 19.0654034 },
    { lat: 48.0829527, lng: 19.0665628 },
    { lat: 48.0818249, lng: 19.0680327 },
    { lat: 48.0815253, lng: 19.0683496 },
    { lat: 48.0808085, lng: 19.0687553 },
    { lat: 48.0801548, lng: 19.0689805 },
    { lat: 48.0795716, lng: 19.0690842 },
    { lat: 48.0791024, lng: 19.0690856 },
    { lat: 48.0780654, lng: 19.0689104 },
    { lat: 48.0765115, lng: 19.0682536 },
    { lat: 48.0758572, lng: 19.0704767 },
    { lat: 48.075237, lng: 19.0728432 },
    { lat: 48.0740853, lng: 19.076647 },
    { lat: 48.0740529, lng: 19.0767197 },
    { lat: 48.0737904, lng: 19.0763682 },
    { lat: 48.0737534, lng: 19.0764597 },
    { lat: 48.0730304, lng: 19.0784848 },
    { lat: 48.0727427, lng: 19.0796423 },
    { lat: 48.0727014, lng: 19.0799906 },
    { lat: 48.0721702, lng: 19.0812805 },
    { lat: 48.0719498, lng: 19.0819169 },
    { lat: 48.0719433, lng: 19.0822013 },
    { lat: 48.072186, lng: 19.082639 },
    { lat: 48.072518, lng: 19.083512 },
    { lat: 48.072781, lng: 19.084362 },
    { lat: 48.072954, lng: 19.085689 },
    { lat: 48.072979, lng: 19.086405 },
    { lat: 48.072903, lng: 19.087697 },
    { lat: 48.07275, lng: 19.088653 },
    { lat: 48.072475, lng: 19.089784 },
    { lat: 48.072276, lng: 19.090279 },
    { lat: 48.070147, lng: 19.094801 },
    { lat: 48.069219, lng: 19.096276 },
    { lat: 48.068578, lng: 19.0970975 },
    { lat: 48.0690577, lng: 19.0977573 },
    { lat: 48.0692296, lng: 19.0981071 },
    { lat: 48.0703097, lng: 19.0990951 },
    { lat: 48.0702753, lng: 19.1009192 },
    { lat: 48.0703947, lng: 19.100761 },
    { lat: 48.0705264, lng: 19.1007773 },
    { lat: 48.0710473, lng: 19.1004676 },
    { lat: 48.0714533, lng: 19.1004705 },
    { lat: 48.0715873, lng: 19.1004195 },
    { lat: 48.0716789, lng: 19.1002902 },
    { lat: 48.0717116, lng: 19.1003351 },
    { lat: 48.0729095, lng: 19.0999483 },
    { lat: 48.0732574, lng: 19.1003641 },
    { lat: 48.0732649, lng: 19.1005554 },
    { lat: 48.073176, lng: 19.1006954 },
    { lat: 48.0729453, lng: 19.100637 },
    { lat: 48.0727243, lng: 19.1007009 },
    { lat: 48.0726751, lng: 19.100822 },
    { lat: 48.0727788, lng: 19.1012901 },
    { lat: 48.0727375, lng: 19.1014146 },
    { lat: 48.0723732, lng: 19.1016993 },
    { lat: 48.0723421, lng: 19.101824 },
    { lat: 48.0723801, lng: 19.1023316 },
    { lat: 48.0725724, lng: 19.1030169 },
    { lat: 48.07258, lng: 19.1032398 },
    { lat: 48.0723566, lng: 19.1038616 },
    { lat: 48.0723749, lng: 19.1039579 },
    { lat: 48.072605, lng: 19.1040055 },
    { lat: 48.0727565, lng: 19.1044144 },
    { lat: 48.0728378, lng: 19.1045093 },
    { lat: 48.0729775, lng: 19.1044411 },
    { lat: 48.073075, lng: 19.1044968 },
    { lat: 48.073278, lng: 19.1051463 },
    { lat: 48.0731998, lng: 19.1055887 },
    { lat: 48.0730481, lng: 19.1057325 },
    { lat: 48.0725842, lng: 19.1055888 },
    { lat: 48.072547, lng: 19.105723 },
    { lat: 48.0725992, lng: 19.1059301 },
    { lat: 48.0725758, lng: 19.106362 },
    { lat: 48.0733628, lng: 19.1067453 },
    { lat: 48.0741475, lng: 19.1070158 },
    { lat: 48.0745902, lng: 19.1073223 },
    { lat: 48.0768248, lng: 19.1075935 },
    { lat: 48.0776189, lng: 19.1078263 },
    { lat: 48.0776157, lng: 19.1084161 },
    { lat: 48.0777997, lng: 19.109231 },
    { lat: 48.0781105, lng: 19.1098502 },
    { lat: 48.0782802, lng: 19.1099977 },
    { lat: 48.0787332, lng: 19.1101272 },
    { lat: 48.0791055, lng: 19.1103803 },
    { lat: 48.0794164, lng: 19.1108702 },
    { lat: 48.079679, lng: 19.111113 },
    { lat: 48.0798474, lng: 19.1116635 },
    { lat: 48.080333, lng: 19.1119724 },
    { lat: 48.0803099, lng: 19.1120959 },
    { lat: 48.0802549, lng: 19.112144 },
    { lat: 48.0802288, lng: 19.1123905 },
    { lat: 48.0804509, lng: 19.11248 },
    { lat: 48.0804559, lng: 19.1126229 },
    { lat: 48.0805257, lng: 19.1127561 },
    { lat: 48.0806451, lng: 19.1128443 },
    { lat: 48.0806365, lng: 19.1131432 },
    { lat: 48.0807049, lng: 19.1132261 },
    { lat: 48.0810114, lng: 19.1133594 },
    { lat: 48.0810321, lng: 19.1135228 },
    { lat: 48.0811129, lng: 19.1136065 },
    { lat: 48.0812816, lng: 19.1135742 },
    { lat: 48.0813403, lng: 19.1133748 },
    { lat: 48.0816036, lng: 19.1134332 },
    { lat: 48.0821258, lng: 19.1139312 },
    { lat: 48.0820605, lng: 19.1141356 },
    { lat: 48.082092, lng: 19.1143584 },
    { lat: 48.0822587, lng: 19.1145358 },
    { lat: 48.082856, lng: 19.1145992 },
    { lat: 48.082816, lng: 19.114994 },
    { lat: 48.0828657, lng: 19.1150611 },
    { lat: 48.0828709, lng: 19.1148306 },
    { lat: 48.0828951, lng: 19.1148607 },
    { lat: 48.0829412, lng: 19.1151965 },
    { lat: 48.0830141, lng: 19.1152352 },
    { lat: 48.0834781, lng: 19.1151323 },
    { lat: 48.08357, lng: 19.1150579 },
    { lat: 48.0836408, lng: 19.114807 },
    { lat: 48.0839955, lng: 19.1146617 },
    { lat: 48.0843331, lng: 19.1137066 },
    { lat: 48.0858616, lng: 19.1122422 },
    { lat: 48.0879509, lng: 19.1090769 },
    { lat: 48.0890119, lng: 19.10951 },
    { lat: 48.0910079, lng: 19.1103248 },
    { lat: 48.0921278, lng: 19.1097709 },
    { lat: 48.0925118, lng: 19.1094903 },
    { lat: 48.0925103, lng: 19.1091036 },
    { lat: 48.0924543, lng: 19.1089103 },
    { lat: 48.0926102, lng: 19.1087116 },
    { lat: 48.0926806, lng: 19.1087929 },
    { lat: 48.0927446, lng: 19.1087625 },
    { lat: 48.0927564, lng: 19.1086589 },
    { lat: 48.0926865, lng: 19.1085052 },
    { lat: 48.0928094, lng: 19.1084583 },
    { lat: 48.0927325, lng: 19.108164 },
    { lat: 48.0928397, lng: 19.108121 },
    { lat: 48.0928551, lng: 19.1080657 },
    { lat: 48.0927686, lng: 19.1079614 },
    { lat: 48.092855, lng: 19.1077476 },
    { lat: 48.0929309, lng: 19.1076887 },
    { lat: 48.0930071, lng: 19.1078367 },
    { lat: 48.0932518, lng: 19.1078637 },
    { lat: 48.0933084, lng: 19.1073957 },
    { lat: 48.0934036, lng: 19.1073156 },
    { lat: 48.0934839, lng: 19.1074339 },
    { lat: 48.0935369, lng: 19.1073402 },
    { lat: 48.0935448, lng: 19.1071168 },
    { lat: 48.0936518, lng: 19.1070266 },
    { lat: 48.0936333, lng: 19.1069267 },
    { lat: 48.0935638, lng: 19.1068703 },
    { lat: 48.0937305, lng: 19.1067081 },
    { lat: 48.0936879, lng: 19.1065556 },
    { lat: 48.0938221, lng: 19.1064669 },
    { lat: 48.0940578, lng: 19.1065583 },
    { lat: 48.094117, lng: 19.1064357 },
    { lat: 48.0943736, lng: 19.106201 },
    { lat: 48.0945425, lng: 19.1063074 },
    { lat: 48.0945489, lng: 19.1064364 },
    { lat: 48.094733, lng: 19.1063767 },
    { lat: 48.0947358, lng: 19.1061831 },
    { lat: 48.0947877, lng: 19.1061389 },
    { lat: 48.0950473, lng: 19.106101 },
    { lat: 48.0950808, lng: 19.1061424 },
    { lat: 48.0950324, lng: 19.1062748 },
    { lat: 48.095056, lng: 19.1063211 },
    { lat: 48.0953941, lng: 19.1063641 },
    { lat: 48.095578, lng: 19.1061862 },
    { lat: 48.095673, lng: 19.1063237 },
    { lat: 48.0957918, lng: 19.1067837 },
    { lat: 48.0960558, lng: 19.1069471 },
    { lat: 48.0960556, lng: 19.1068491 },
    { lat: 48.0959702, lng: 19.1067243 },
    { lat: 48.0960838, lng: 19.106422 },
    { lat: 48.0961207, lng: 19.1064415 },
    { lat: 48.0961687, lng: 19.1063395 },
    { lat: 48.0962777, lng: 19.1064451 },
    { lat: 48.0965043, lng: 19.1061778 },
    { lat: 48.0964264, lng: 19.1058826 },
    { lat: 48.0964802, lng: 19.1057626 },
    { lat: 48.0965432, lng: 19.1057383 },
    { lat: 48.0965645, lng: 19.1056262 },
    { lat: 48.09676, lng: 19.1056336 },
    { lat: 48.0969168, lng: 19.1053872 },
    { lat: 48.096932, lng: 19.105243 },
    { lat: 48.0971348, lng: 19.1049728 },
    { lat: 48.0971644, lng: 19.1047057 },
    { lat: 48.0972178, lng: 19.1046486 },
    { lat: 48.0971699, lng: 19.1043922 },
    { lat: 48.0973614, lng: 19.104277 },
    { lat: 48.097384, lng: 19.1041193 },
    { lat: 48.0984128, lng: 19.1030079 },
    { lat: 48.098387, lng: 19.1029409 },
    { lat: 48.0984262, lng: 19.1028946 },
  ],
  DačovLom: [
    { lat: 48.325411, lng: 19.263566 },
    { lat: 48.325421, lng: 19.263541 },
    { lat: 48.325552, lng: 19.263305 },
    { lat: 48.325512, lng: 19.263149 },
    { lat: 48.325476, lng: 19.263038 },
    { lat: 48.325463, lng: 19.262999 },
    { lat: 48.325455, lng: 19.262975 },
    { lat: 48.325637, lng: 19.262737 },
    { lat: 48.32567, lng: 19.262737 },
    { lat: 48.325858, lng: 19.262751 },
    { lat: 48.326096, lng: 19.262873 },
    { lat: 48.326249, lng: 19.262927 },
    { lat: 48.326314, lng: 19.262879 },
    { lat: 48.326578, lng: 19.262901 },
    { lat: 48.326969, lng: 19.262934 },
    { lat: 48.327153, lng: 19.263062 },
    { lat: 48.327172, lng: 19.263218 },
    { lat: 48.327192, lng: 19.263326 },
    { lat: 48.327329, lng: 19.26346 },
    { lat: 48.327361, lng: 19.263565 },
    { lat: 48.327495, lng: 19.2636 },
    { lat: 48.327575, lng: 19.263686 },
    { lat: 48.32773, lng: 19.263736 },
    { lat: 48.327859, lng: 19.263743 },
    { lat: 48.327949, lng: 19.263766 },
    { lat: 48.328128, lng: 19.263898 },
    { lat: 48.328157, lng: 19.263889 },
    { lat: 48.328227, lng: 19.263846 },
    { lat: 48.328318, lng: 19.263678 },
    { lat: 48.328452, lng: 19.263651 },
    { lat: 48.328538, lng: 19.263558 },
    { lat: 48.328524, lng: 19.263434 },
    { lat: 48.328432, lng: 19.263447 },
    { lat: 48.328387, lng: 19.263059 },
    { lat: 48.328331, lng: 19.263041 },
    { lat: 48.328291, lng: 19.262859 },
    { lat: 48.328346, lng: 19.262832 },
    { lat: 48.328336, lng: 19.262702 },
    { lat: 48.328373, lng: 19.262669 },
    { lat: 48.328477, lng: 19.262439 },
    { lat: 48.328558, lng: 19.262522 },
    { lat: 48.328555, lng: 19.262576 },
    { lat: 48.328554, lng: 19.262598 },
    { lat: 48.32881, lng: 19.262873 },
    { lat: 48.328784, lng: 19.263053 },
    { lat: 48.328768, lng: 19.263154 },
    { lat: 48.328749, lng: 19.263296 },
    { lat: 48.328703, lng: 19.263474 },
    { lat: 48.328655, lng: 19.26356 },
    { lat: 48.328678, lng: 19.263563 },
    { lat: 48.328709, lng: 19.263566 },
    { lat: 48.328701, lng: 19.26361 },
    { lat: 48.328822, lng: 19.263718 },
    { lat: 48.328916, lng: 19.263709 },
    { lat: 48.328916, lng: 19.263681 },
    { lat: 48.329037, lng: 19.263542 },
    { lat: 48.329388, lng: 19.263441 },
    { lat: 48.329479, lng: 19.263353 },
    { lat: 48.329768, lng: 19.263355 },
    { lat: 48.33, lng: 19.263362 },
    { lat: 48.330092, lng: 19.2633 },
    { lat: 48.330174, lng: 19.263311 },
    { lat: 48.33024, lng: 19.263426 },
    { lat: 48.330278, lng: 19.263571 },
    { lat: 48.330437, lng: 19.263564 },
    { lat: 48.330616, lng: 19.263565 },
    { lat: 48.330622, lng: 19.263446 },
    { lat: 48.330676, lng: 19.263344 },
    { lat: 48.33083, lng: 19.26331 },
    { lat: 48.330962, lng: 19.263394 },
    { lat: 48.330927, lng: 19.263482 },
    { lat: 48.331066, lng: 19.26362 },
    { lat: 48.331112, lng: 19.263618 },
    { lat: 48.331309, lng: 19.263626 },
    { lat: 48.331497, lng: 19.26353 },
    { lat: 48.331718, lng: 19.263385 },
    { lat: 48.331819, lng: 19.263176 },
    { lat: 48.331919, lng: 19.262987 },
    { lat: 48.331951, lng: 19.262948 },
    { lat: 48.331984, lng: 19.26294 },
    { lat: 48.332027, lng: 19.262971 },
    { lat: 48.332135, lng: 19.263085 },
    { lat: 48.332348, lng: 19.263361 },
    { lat: 48.332676, lng: 19.263456 },
    { lat: 48.332879, lng: 19.263553 },
    { lat: 48.332921, lng: 19.263617 },
    { lat: 48.332972, lng: 19.264065 },
    { lat: 48.333182, lng: 19.264213 },
    { lat: 48.333231, lng: 19.26423 },
    { lat: 48.33356, lng: 19.264049 },
    { lat: 48.333687, lng: 19.264134 },
    { lat: 48.33384, lng: 19.264452 },
    { lat: 48.334032, lng: 19.264519 },
    { lat: 48.334128, lng: 19.264487 },
    { lat: 48.334255, lng: 19.264606 },
    { lat: 48.334563, lng: 19.264746 },
    { lat: 48.334713, lng: 19.264779 },
    { lat: 48.33486, lng: 19.264723 },
    { lat: 48.334869, lng: 19.264639 },
    { lat: 48.334706, lng: 19.264538 },
    { lat: 48.334598, lng: 19.264152 },
    { lat: 48.334539, lng: 19.263791 },
    { lat: 48.33456, lng: 19.263626 },
    { lat: 48.335118, lng: 19.261628 },
    { lat: 48.335224, lng: 19.261322 },
    { lat: 48.335275, lng: 19.26159 },
    { lat: 48.33556, lng: 19.261233 },
    { lat: 48.335718, lng: 19.260956 },
    { lat: 48.335801, lng: 19.26074 },
    { lat: 48.336063, lng: 19.260675 },
    { lat: 48.336302, lng: 19.260472 },
    { lat: 48.336569, lng: 19.26017 },
    { lat: 48.336743, lng: 19.259876 },
    { lat: 48.337363, lng: 19.259886 },
    { lat: 48.337454, lng: 19.259817 },
    { lat: 48.337543, lng: 19.259628 },
    { lat: 48.337685, lng: 19.259564 },
    { lat: 48.337805, lng: 19.25937 },
    { lat: 48.337883, lng: 19.259345 },
    { lat: 48.338138, lng: 19.258879 },
    { lat: 48.33841, lng: 19.258665 },
    { lat: 48.338658, lng: 19.258603 },
    { lat: 48.338774, lng: 19.258531 },
    { lat: 48.338813, lng: 19.258361 },
    { lat: 48.338953, lng: 19.258239 },
    { lat: 48.339328, lng: 19.257854 },
    { lat: 48.339494, lng: 19.258196 },
    { lat: 48.34003, lng: 19.257925 },
    { lat: 48.340104, lng: 19.258312 },
    { lat: 48.340219, lng: 19.258254 },
    { lat: 48.340234, lng: 19.258081 },
    { lat: 48.340174, lng: 19.257856 },
    { lat: 48.340348, lng: 19.257747 },
    { lat: 48.340419, lng: 19.257703 },
    { lat: 48.340359, lng: 19.25748 },
    { lat: 48.340485, lng: 19.25733 },
    { lat: 48.340903, lng: 19.257148 },
    { lat: 48.341172, lng: 19.257024 },
    { lat: 48.341297, lng: 19.256906 },
    { lat: 48.34152, lng: 19.25675 },
    { lat: 48.34201, lng: 19.256652 },
    { lat: 48.342159, lng: 19.256555 },
    { lat: 48.342289, lng: 19.256434 },
    { lat: 48.342587, lng: 19.254992 },
    { lat: 48.342711, lng: 19.255163 },
    { lat: 48.34276, lng: 19.255188 },
    { lat: 48.342777, lng: 19.255103 },
    { lat: 48.342916, lng: 19.254453 },
    { lat: 48.343114, lng: 19.254351 },
    { lat: 48.343486, lng: 19.252117 },
    { lat: 48.343818, lng: 19.252004 },
    { lat: 48.343991, lng: 19.251953 },
    { lat: 48.344275, lng: 19.251828 },
    { lat: 48.344708, lng: 19.251746 },
    { lat: 48.344926, lng: 19.252263 },
    { lat: 48.345392, lng: 19.252205 },
    { lat: 48.345466, lng: 19.252189 },
    { lat: 48.346234, lng: 19.252018 },
    { lat: 48.347432, lng: 19.251689 },
    { lat: 48.347188, lng: 19.251146 },
    { lat: 48.348196, lng: 19.250941 },
    { lat: 48.350174, lng: 19.250478 },
    { lat: 48.350703, lng: 19.25039 },
    { lat: 48.35073, lng: 19.250385 },
    { lat: 48.350764, lng: 19.250413 },
    { lat: 48.35096, lng: 19.250357 },
    { lat: 48.35098, lng: 19.250283 },
    { lat: 48.350998, lng: 19.248315 },
    { lat: 48.350999, lng: 19.248116 },
    { lat: 48.351004, lng: 19.247612 },
    { lat: 48.350963, lng: 19.245893 },
    { lat: 48.35096, lng: 19.245733 },
    { lat: 48.351023, lng: 19.245712 },
    { lat: 48.350933, lng: 19.244747 },
    { lat: 48.350919, lng: 19.244588 },
    { lat: 48.350831, lng: 19.243653 },
    { lat: 48.350827, lng: 19.243611 },
    { lat: 48.351421, lng: 19.24339 },
    { lat: 48.351586, lng: 19.243239 },
    { lat: 48.352462, lng: 19.242807 },
    { lat: 48.352995, lng: 19.242772 },
    { lat: 48.353053, lng: 19.242768 },
    { lat: 48.353164, lng: 19.242761 },
    { lat: 48.353377, lng: 19.242807 },
    { lat: 48.353499, lng: 19.242807 },
    { lat: 48.353673, lng: 19.242628 },
    { lat: 48.353963, lng: 19.2425 },
    { lat: 48.354306, lng: 19.242322 },
    { lat: 48.354732, lng: 19.241892 },
    { lat: 48.354964, lng: 19.241568 },
    { lat: 48.355039, lng: 19.241315 },
    { lat: 48.355066, lng: 19.241187 },
    { lat: 48.355114, lng: 19.241053 },
    { lat: 48.355547, lng: 19.239834 },
    { lat: 48.355802, lng: 19.239188 },
    { lat: 48.356108, lng: 19.237383 },
    { lat: 48.356178, lng: 19.236966 },
    { lat: 48.356329, lng: 19.236635 },
    { lat: 48.356528, lng: 19.236389 },
    { lat: 48.356633, lng: 19.236123 },
    { lat: 48.35676, lng: 19.235972 },
    { lat: 48.357191, lng: 19.235763 },
    { lat: 48.357508, lng: 19.235788 },
    { lat: 48.357615, lng: 19.235806 },
    { lat: 48.357819, lng: 19.235741 },
    { lat: 48.359036, lng: 19.233605 },
    { lat: 48.359419, lng: 19.232996 },
    { lat: 48.359656, lng: 19.232618 },
    { lat: 48.359667, lng: 19.2326 },
    { lat: 48.359619, lng: 19.232493 },
    { lat: 48.359552, lng: 19.232482 },
    { lat: 48.359333, lng: 19.232255 },
    { lat: 48.359201, lng: 19.232235 },
    { lat: 48.359067, lng: 19.23218 },
    { lat: 48.358993, lng: 19.232208 },
    { lat: 48.358903, lng: 19.232159 },
    { lat: 48.358852, lng: 19.232093 },
    { lat: 48.358697, lng: 19.232041 },
    { lat: 48.358578, lng: 19.232144 },
    { lat: 48.358389, lng: 19.232069 },
    { lat: 48.358249, lng: 19.231902 },
    { lat: 48.35806, lng: 19.231823 },
    { lat: 48.358013, lng: 19.231751 },
    { lat: 48.357925, lng: 19.231704 },
    { lat: 48.357753, lng: 19.231488 },
    { lat: 48.357702, lng: 19.23144 },
    { lat: 48.357612, lng: 19.231356 },
    { lat: 48.357541, lng: 19.231323 },
    { lat: 48.357439, lng: 19.231172 },
    { lat: 48.357448, lng: 19.231089 },
    { lat: 48.357438, lng: 19.230996 },
    { lat: 48.357393, lng: 19.230917 },
    { lat: 48.357394, lng: 19.230802 },
    { lat: 48.357279, lng: 19.230644 },
    { lat: 48.357234, lng: 19.23051 },
    { lat: 48.356997, lng: 19.230551 },
    { lat: 48.35698, lng: 19.230639 },
    { lat: 48.356936, lng: 19.230671 },
    { lat: 48.356845, lng: 19.230668 },
    { lat: 48.356847, lng: 19.230638 },
    { lat: 48.35681, lng: 19.230464 },
    { lat: 48.356721, lng: 19.230423 },
    { lat: 48.356728, lng: 19.230267 },
    { lat: 48.356705, lng: 19.230252 },
    { lat: 48.356593, lng: 19.230139 },
    { lat: 48.356506, lng: 19.230263 },
    { lat: 48.356477, lng: 19.230305 },
    { lat: 48.356217, lng: 19.230117 },
    { lat: 48.356151, lng: 19.230069 },
    { lat: 48.355977, lng: 19.229857 },
    { lat: 48.355823, lng: 19.229792 },
    { lat: 48.35558, lng: 19.22969 },
    { lat: 48.35518, lng: 19.229451 },
    { lat: 48.354968, lng: 19.229422 },
    { lat: 48.354681, lng: 19.229533 },
    { lat: 48.354497, lng: 19.229688 },
    { lat: 48.354479, lng: 19.229704 },
    { lat: 48.354282, lng: 19.229618 },
    { lat: 48.354166, lng: 19.229655 },
    { lat: 48.354066, lng: 19.229807 },
    { lat: 48.354016, lng: 19.229835 },
    { lat: 48.353893, lng: 19.229677 },
    { lat: 48.353828, lng: 19.229683 },
    { lat: 48.353721, lng: 19.229751 },
    { lat: 48.353592, lng: 19.229754 },
    { lat: 48.353578, lng: 19.229731 },
    { lat: 48.353562, lng: 19.229677 },
    { lat: 48.353451, lng: 19.229321 },
    { lat: 48.353387, lng: 19.229264 },
    { lat: 48.353355, lng: 19.229236 },
    { lat: 48.35321, lng: 19.229105 },
    { lat: 48.353066, lng: 19.22906 },
    { lat: 48.353004, lng: 19.229088 },
    { lat: 48.352947, lng: 19.229114 },
    { lat: 48.352865, lng: 19.22915 },
    { lat: 48.352849, lng: 19.229158 },
    { lat: 48.352795, lng: 19.229109 },
    { lat: 48.352748, lng: 19.22904 },
    { lat: 48.352742, lng: 19.229057 },
    { lat: 48.352713, lng: 19.229239 },
    { lat: 48.352552, lng: 19.229198 },
    { lat: 48.352528, lng: 19.229189 },
    { lat: 48.352429, lng: 19.229241 },
    { lat: 48.352378, lng: 19.229263 },
    { lat: 48.352326, lng: 19.229189 },
    { lat: 48.352387, lng: 19.22912 },
    { lat: 48.352351, lng: 19.229102 },
    { lat: 48.352286, lng: 19.229117 },
    { lat: 48.352259, lng: 19.229081 },
    { lat: 48.352228, lng: 19.229084 },
    { lat: 48.352235, lng: 19.229125 },
    { lat: 48.352251, lng: 19.229193 },
    { lat: 48.35224, lng: 19.229223 },
    { lat: 48.3522, lng: 19.229227 },
    { lat: 48.35219, lng: 19.229369 },
    { lat: 48.352086, lng: 19.22941 },
    { lat: 48.351967, lng: 19.229313 },
    { lat: 48.35194, lng: 19.229379 },
    { lat: 48.351842, lng: 19.229406 },
    { lat: 48.351848, lng: 19.229514 },
    { lat: 48.351824, lng: 19.22953 },
    { lat: 48.35169, lng: 19.229446 },
    { lat: 48.351564, lng: 19.229265 },
    { lat: 48.351502, lng: 19.229175 },
    { lat: 48.351476, lng: 19.22916 },
    { lat: 48.351373, lng: 19.229209 },
    { lat: 48.35127, lng: 19.229356 },
    { lat: 48.351081, lng: 19.229386 },
    { lat: 48.350583, lng: 19.229454 },
    { lat: 48.350401, lng: 19.229541 },
    { lat: 48.350336, lng: 19.229602 },
    { lat: 48.350235, lng: 19.22965 },
    { lat: 48.350066, lng: 19.229694 },
    { lat: 48.349924, lng: 19.22971 },
    { lat: 48.349837, lng: 19.229816 },
    { lat: 48.349761, lng: 19.229829 },
    { lat: 48.349635, lng: 19.229892 },
    { lat: 48.349544, lng: 19.229906 },
    { lat: 48.349438, lng: 19.229853 },
    { lat: 48.349255, lng: 19.229991 },
    { lat: 48.349164, lng: 19.230266 },
    { lat: 48.34918, lng: 19.230396 },
    { lat: 48.349097, lng: 19.230583 },
    { lat: 48.348956, lng: 19.230782 },
    { lat: 48.348904, lng: 19.230868 },
    { lat: 48.348837, lng: 19.230995 },
    { lat: 48.348789, lng: 19.23104 },
    { lat: 48.34871, lng: 19.231066 },
    { lat: 48.348415, lng: 19.231045 },
    { lat: 48.34825, lng: 19.230969 },
    { lat: 48.347994, lng: 19.231153 },
    { lat: 48.347755, lng: 19.231173 },
    { lat: 48.347731, lng: 19.231395 },
    { lat: 48.347844, lng: 19.231625 },
    { lat: 48.347824, lng: 19.231724 },
    { lat: 48.347529, lng: 19.23201 },
    { lat: 48.347179, lng: 19.23179 },
    { lat: 48.347136, lng: 19.231697 },
    { lat: 48.346797, lng: 19.231254 },
    { lat: 48.346718, lng: 19.231156 },
    { lat: 48.345771, lng: 19.22998 },
    { lat: 48.345578, lng: 19.229712 },
    { lat: 48.344783, lng: 19.228495 },
    { lat: 48.344376, lng: 19.227857 },
    { lat: 48.343969, lng: 19.226457 },
    { lat: 48.343952, lng: 19.226396 },
    { lat: 48.344001, lng: 19.226375 },
    { lat: 48.344462, lng: 19.226176 },
    { lat: 48.343929, lng: 19.224819 },
    { lat: 48.343828, lng: 19.224431 },
    { lat: 48.343768, lng: 19.224076 },
    { lat: 48.343729, lng: 19.223633 },
    { lat: 48.343708, lng: 19.223278 },
    { lat: 48.343676, lng: 19.223057 },
    { lat: 48.343559, lng: 19.222054 },
    { lat: 48.343534, lng: 19.221922 },
    { lat: 48.34349, lng: 19.221443 },
    { lat: 48.343492, lng: 19.221202 },
    { lat: 48.343477, lng: 19.2211 },
    { lat: 48.343467, lng: 19.221032 },
    { lat: 48.343372, lng: 19.220633 },
    { lat: 48.343339, lng: 19.220513 },
    { lat: 48.343294, lng: 19.22035 },
    { lat: 48.343164, lng: 19.219705 },
    { lat: 48.343136, lng: 19.219566 },
    { lat: 48.343084, lng: 19.219227 },
    { lat: 48.342968, lng: 19.218751 },
    { lat: 48.342959, lng: 19.21871 },
    { lat: 48.342862, lng: 19.218271 },
    { lat: 48.342789, lng: 19.217942 },
    { lat: 48.342778, lng: 19.217745 },
    { lat: 48.342773, lng: 19.217731 },
    { lat: 48.342616, lng: 19.217111 },
    { lat: 48.342541, lng: 19.216513 },
    { lat: 48.342564, lng: 19.216108 },
    { lat: 48.342576, lng: 19.215897 },
    { lat: 48.342545, lng: 19.215811 },
    { lat: 48.3417985, lng: 19.2163509 },
    { lat: 48.3408286, lng: 19.2163423 },
    { lat: 48.340803, lng: 19.2168801 },
    { lat: 48.3405554, lng: 19.2173709 },
    { lat: 48.3396954, lng: 19.2183422 },
    { lat: 48.3387424, lng: 19.2198007 },
    { lat: 48.3381234, lng: 19.2206526 },
    { lat: 48.337584, lng: 19.2210319 },
    { lat: 48.3367938, lng: 19.2217918 },
    { lat: 48.3363852, lng: 19.2219863 },
    { lat: 48.335926, lng: 19.222373 },
    { lat: 48.3350252, lng: 19.2233356 },
    { lat: 48.3341338, lng: 19.2235265 },
    { lat: 48.3335016, lng: 19.2242423 },
    { lat: 48.3332611, lng: 19.224397 },
    { lat: 48.3307448, lng: 19.2255915 },
    { lat: 48.3283568, lng: 19.2269162 },
    { lat: 48.3278138, lng: 19.2271541 },
    { lat: 48.3268785, lng: 19.2274376 },
    { lat: 48.3254728, lng: 19.2281289 },
    { lat: 48.3250418, lng: 19.2282786 },
    { lat: 48.3248803, lng: 19.2284108 },
    { lat: 48.3231073, lng: 19.2291555 },
    { lat: 48.3216669, lng: 19.2295957 },
    { lat: 48.3209609, lng: 19.2296599 },
    { lat: 48.3201248, lng: 19.2298942 },
    { lat: 48.3201057, lng: 19.2298258 },
    { lat: 48.3199685, lng: 19.2298554 },
    { lat: 48.319581, lng: 19.2297478 },
    { lat: 48.3186587, lng: 19.2304786 },
    { lat: 48.3179107, lng: 19.2311827 },
    { lat: 48.3174438, lng: 19.231314 },
    { lat: 48.3171247, lng: 19.2313147 },
    { lat: 48.3170959, lng: 19.2311847 },
    { lat: 48.3169892, lng: 19.2312386 },
    { lat: 48.3168503, lng: 19.2312139 },
    { lat: 48.3161533, lng: 19.2313407 },
    { lat: 48.3152255, lng: 19.2316187 },
    { lat: 48.3151202, lng: 19.2316097 },
    { lat: 48.3150852, lng: 19.2315158 },
    { lat: 48.3149468, lng: 19.2314503 },
    { lat: 48.3147431, lng: 19.2314337 },
    { lat: 48.3146003, lng: 19.2315131 },
    { lat: 48.3145424, lng: 19.2314342 },
    { lat: 48.3143836, lng: 19.2314378 },
    { lat: 48.3141481, lng: 19.2315788 },
    { lat: 48.3141027, lng: 19.2315012 },
    { lat: 48.313192, lng: 19.2315284 },
    { lat: 48.3125649, lng: 19.2317995 },
    { lat: 48.3125002, lng: 19.2315328 },
    { lat: 48.3114208, lng: 19.2323526 },
    { lat: 48.3109338, lng: 19.2329971 },
    { lat: 48.3105404, lng: 19.2332255 },
    { lat: 48.3093993, lng: 19.2342371 },
    { lat: 48.3089351, lng: 19.2346769 },
    { lat: 48.3086971, lng: 19.2349973 },
    { lat: 48.3079648, lng: 19.2356049 },
    { lat: 48.3074509, lng: 19.2359329 },
    { lat: 48.3067296, lng: 19.2367174 },
    { lat: 48.3064706, lng: 19.2369321 },
    { lat: 48.3063836, lng: 19.2370914 },
    { lat: 48.3062206, lng: 19.2369829 },
    { lat: 48.3060264, lng: 19.2373703 },
    { lat: 48.3057109, lng: 19.237304 },
    { lat: 48.3055995, lng: 19.237539 },
    { lat: 48.3055108, lng: 19.237497 },
    { lat: 48.3051397, lng: 19.2377909 },
    { lat: 48.3050476, lng: 19.2380287 },
    { lat: 48.3048684, lng: 19.2380076 },
    { lat: 48.3030206, lng: 19.2390197 },
    { lat: 48.3028142, lng: 19.2392477 },
    { lat: 48.3021195, lng: 19.2397017 },
    { lat: 48.3017106, lng: 19.2401714 },
    { lat: 48.3009422, lng: 19.2406649 },
    { lat: 48.3006976, lng: 19.2407356 },
    { lat: 48.3000191, lng: 19.2412894 },
    { lat: 48.2997715, lng: 19.2413661 },
    { lat: 48.2995059, lng: 19.2412384 },
    { lat: 48.2989829, lng: 19.241653 },
    { lat: 48.298354, lng: 19.2411714 },
    { lat: 48.2980409, lng: 19.2411314 },
    { lat: 48.2978145, lng: 19.2409941 },
    { lat: 48.2975333, lng: 19.2404291 },
    { lat: 48.2973344, lng: 19.2403515 },
    { lat: 48.2972209, lng: 19.2402184 },
    { lat: 48.2967553, lng: 19.2407934 },
    { lat: 48.2963776, lng: 19.2411368 },
    { lat: 48.2951043, lng: 19.2417015 },
    { lat: 48.294467, lng: 19.2422245 },
    { lat: 48.2937618, lng: 19.2419121 },
    { lat: 48.2923552, lng: 19.243142 },
    { lat: 48.2919257, lng: 19.243833 },
    { lat: 48.2906229, lng: 19.2453604 },
    { lat: 48.2897878, lng: 19.246478 },
    { lat: 48.2894743, lng: 19.2469598 },
    { lat: 48.2892531, lng: 19.2475786 },
    { lat: 48.2892376, lng: 19.2478136 },
    { lat: 48.289286, lng: 19.2479714 },
    { lat: 48.2890706, lng: 19.2480648 },
    { lat: 48.288975, lng: 19.2482072 },
    { lat: 48.2881026, lng: 19.2478855 },
    { lat: 48.287689, lng: 19.2475421 },
    { lat: 48.2874191, lng: 19.2470209 },
    { lat: 48.2871017, lng: 19.2468218 },
    { lat: 48.2868214, lng: 19.2468769 },
    { lat: 48.286557, lng: 19.2472106 },
    { lat: 48.2863585, lng: 19.2471399 },
    { lat: 48.2855938, lng: 19.2465763 },
    { lat: 48.2848716, lng: 19.2467191 },
    { lat: 48.2844238, lng: 19.2465321 },
    { lat: 48.2842796, lng: 19.246326 },
    { lat: 48.2841484, lng: 19.2459475 },
    { lat: 48.2838041, lng: 19.245867 },
    { lat: 48.2836067, lng: 19.2460284 },
    { lat: 48.2834889, lng: 19.2466387 },
    { lat: 48.2832043, lng: 19.246907 },
    { lat: 48.2827308, lng: 19.2468364 },
    { lat: 48.2824638, lng: 19.2462392 },
    { lat: 48.282325, lng: 19.246212 },
    { lat: 48.2820396, lng: 19.2463462 },
    { lat: 48.281531, lng: 19.2463407 },
    { lat: 48.2813323, lng: 19.2461267 },
    { lat: 48.2811955, lng: 19.2457482 },
    { lat: 48.2812203, lng: 19.2454227 },
    { lat: 48.2811704, lng: 19.2453242 },
    { lat: 48.2809369, lng: 19.2452312 },
    { lat: 48.280332, lng: 19.245376 },
    { lat: 48.2800692, lng: 19.2445413 },
    { lat: 48.2797883, lng: 19.244491 },
    { lat: 48.2794133, lng: 19.245006 },
    { lat: 48.2790798, lng: 19.2449395 },
    { lat: 48.2789444, lng: 19.2447314 },
    { lat: 48.2785355, lng: 19.244343 },
    { lat: 48.2780758, lng: 19.2444203 },
    { lat: 48.2777335, lng: 19.2444004 },
    { lat: 48.2770392, lng: 19.2438703 },
    { lat: 48.2768548, lng: 19.2439064 },
    { lat: 48.2766421, lng: 19.2443303 },
    { lat: 48.2763262, lng: 19.2443663 },
    { lat: 48.2761015, lng: 19.2445017 },
    { lat: 48.2756651, lng: 19.2446334 },
    { lat: 48.2754897, lng: 19.2446222 },
    { lat: 48.2751356, lng: 19.2444076 },
    { lat: 48.2749476, lng: 19.2443708 },
    { lat: 48.2744841, lng: 19.2446212 },
    { lat: 48.2736747, lng: 19.2446178 },
    { lat: 48.2733525, lng: 19.2449659 },
    { lat: 48.2729964, lng: 19.2451327 },
    { lat: 48.2727267, lng: 19.2453795 },
    { lat: 48.2725277, lng: 19.245437 },
    { lat: 48.2719617, lng: 19.245851 },
    { lat: 48.2718645, lng: 19.2460952 },
    { lat: 48.2718217, lng: 19.2462832 },
    { lat: 48.2720034, lng: 19.2471656 },
    { lat: 48.2719529, lng: 19.2474237 },
    { lat: 48.2718543, lng: 19.2474115 },
    { lat: 48.2715877, lng: 19.2477238 },
    { lat: 48.271496, lng: 19.2480183 },
    { lat: 48.271279, lng: 19.2483775 },
    { lat: 48.2713443, lng: 19.2487443 },
    { lat: 48.2716673, lng: 19.2493805 },
    { lat: 48.2714687, lng: 19.2497695 },
    { lat: 48.2715602, lng: 19.2497758 },
    { lat: 48.2717643, lng: 19.2499831 },
    { lat: 48.2722265, lng: 19.2502832 },
    { lat: 48.2724102, lng: 19.2511636 },
    { lat: 48.2725653, lng: 19.253788 },
    { lat: 48.2722445, lng: 19.2550477 },
    { lat: 48.2722541, lng: 19.2555703 },
    { lat: 48.2722789, lng: 19.2555952 },
    { lat: 48.2724682, lng: 19.2570949 },
    { lat: 48.2726812, lng: 19.2580701 },
    { lat: 48.2728048, lng: 19.2590063 },
    { lat: 48.2727707, lng: 19.2589814 },
    { lat: 48.272747, lng: 19.2593035 },
    { lat: 48.2726281, lng: 19.2597779 },
    { lat: 48.2727453, lng: 19.260357 },
    { lat: 48.2729502, lng: 19.2608824 },
    { lat: 48.2730323, lng: 19.2613911 },
    { lat: 48.2730022, lng: 19.2613986 },
    { lat: 48.2730602, lng: 19.2622892 },
    { lat: 48.2731004, lng: 19.2623478 },
    { lat: 48.2728239, lng: 19.2626413 },
    { lat: 48.2725507, lng: 19.2626824 },
    { lat: 48.2723691, lng: 19.2630034 },
    { lat: 48.2719799, lng: 19.2634849 },
    { lat: 48.2717948, lng: 19.2636191 },
    { lat: 48.2716056, lng: 19.263667 },
    { lat: 48.2715624, lng: 19.2637562 },
    { lat: 48.2717914, lng: 19.2642917 },
    { lat: 48.2719433, lng: 19.2643756 },
    { lat: 48.2721375, lng: 19.2646619 },
    { lat: 48.2721344, lng: 19.264831 },
    { lat: 48.2723758, lng: 19.26576 },
    { lat: 48.2725144, lng: 19.2657831 },
    { lat: 48.2727411, lng: 19.2659462 },
    { lat: 48.2726597, lng: 19.2663804 },
    { lat: 48.2728174, lng: 19.2665193 },
    { lat: 48.2729769, lng: 19.2664877 },
    { lat: 48.2730898, lng: 19.266676 },
    { lat: 48.2730304, lng: 19.2668693 },
    { lat: 48.2730531, lng: 19.2669422 },
    { lat: 48.2732793, lng: 19.2671645 },
    { lat: 48.2735209, lng: 19.2675175 },
    { lat: 48.273608, lng: 19.2678763 },
    { lat: 48.2735627, lng: 19.268544 },
    { lat: 48.2734785, lng: 19.2688525 },
    { lat: 48.273402, lng: 19.2698969 },
    { lat: 48.2732654, lng: 19.270335 },
    { lat: 48.2732604, lng: 19.2706483 },
    { lat: 48.2732014, lng: 19.270781 },
    { lat: 48.2731855, lng: 19.271058 },
    { lat: 48.2732416, lng: 19.2715597 },
    { lat: 48.2734042, lng: 19.271917 },
    { lat: 48.2733705, lng: 19.2723131 },
    { lat: 48.2735963, lng: 19.272901 },
    { lat: 48.2737064, lng: 19.2729605 },
    { lat: 48.2740129, lng: 19.27345 },
    { lat: 48.2740379, lng: 19.2740333 },
    { lat: 48.2741565, lng: 19.2744559 },
    { lat: 48.2741504, lng: 19.2748653 },
    { lat: 48.273875, lng: 19.2763651 },
    { lat: 48.2733673, lng: 19.2777023 },
    { lat: 48.2732556, lng: 19.278213 },
    { lat: 48.2732517, lng: 19.278805 },
    { lat: 48.2734218, lng: 19.2795713 },
    { lat: 48.2734377, lng: 19.2799224 },
    { lat: 48.2733587, lng: 19.2805728 },
    { lat: 48.2729583, lng: 19.2809387 },
    { lat: 48.2717718, lng: 19.2816675 },
    { lat: 48.2715446, lng: 19.281653 },
    { lat: 48.2711721, lng: 19.2818039 },
    { lat: 48.2707721, lng: 19.2817381 },
    { lat: 48.2704934, lng: 19.2818264 },
    { lat: 48.270885, lng: 19.2837425 },
    { lat: 48.2713979, lng: 19.2842068 },
    { lat: 48.2715967, lng: 19.2841279 },
    { lat: 48.2717315, lng: 19.2843938 },
    { lat: 48.2724355, lng: 19.2850582 },
    { lat: 48.2726073, lng: 19.2852996 },
    { lat: 48.2729291, lng: 19.2855207 },
    { lat: 48.2730895, lng: 19.2855507 },
    { lat: 48.2733998, lng: 19.2859532 },
    { lat: 48.273497, lng: 19.2861914 },
    { lat: 48.2735613, lng: 19.286776 },
    { lat: 48.2735334, lng: 19.2869227 },
    { lat: 48.273694, lng: 19.2875131 },
    { lat: 48.2736326, lng: 19.2877802 },
    { lat: 48.2737976, lng: 19.2879976 },
    { lat: 48.2738292, lng: 19.2884216 },
    { lat: 48.2740405, lng: 19.2891081 },
    { lat: 48.2742335, lng: 19.2894395 },
    { lat: 48.2743682, lng: 19.2894939 },
    { lat: 48.2745777, lng: 19.2901427 },
    { lat: 48.2746033, lng: 19.2914427 },
    { lat: 48.2744841, lng: 19.292215 },
    { lat: 48.274626, lng: 19.2926783 },
    { lat: 48.2746951, lng: 19.2927097 },
    { lat: 48.274957, lng: 19.292509 },
    { lat: 48.2755041, lng: 19.2928186 },
    { lat: 48.2757158, lng: 19.2928395 },
    { lat: 48.2758407, lng: 19.2926731 },
    { lat: 48.2761746, lng: 19.292582 },
    { lat: 48.2764748, lng: 19.2923827 },
    { lat: 48.2767013, lng: 19.292494 },
    { lat: 48.2768695, lng: 19.2926898 },
    { lat: 48.2770756, lng: 19.292757 },
    { lat: 48.2774377, lng: 19.2923652 },
    { lat: 48.277739, lng: 19.2923258 },
    { lat: 48.2779313, lng: 19.2925947 },
    { lat: 48.2780529, lng: 19.2928957 },
    { lat: 48.2784937, lng: 19.2933171 },
    { lat: 48.2789452, lng: 19.2934708 },
    { lat: 48.2791537, lng: 19.2936315 },
    { lat: 48.2795416, lng: 19.293657 },
    { lat: 48.2800467, lng: 19.2940774 },
    { lat: 48.2802471, lng: 19.2940514 },
    { lat: 48.2803369, lng: 19.2939054 },
    { lat: 48.2804873, lng: 19.2938208 },
    { lat: 48.2806085, lng: 19.2938317 },
    { lat: 48.2807941, lng: 19.2940805 },
    { lat: 48.2810682, lng: 19.2942043 },
    { lat: 48.2811977, lng: 19.2941279 },
    { lat: 48.281379, lng: 19.293823 },
    { lat: 48.2815371, lng: 19.2937355 },
    { lat: 48.2816314, lng: 19.2937525 },
    { lat: 48.282004, lng: 19.2940907 },
    { lat: 48.2821597, lng: 19.2941651 },
    { lat: 48.2822661, lng: 19.2941047 },
    { lat: 48.282565, lng: 19.2945273 },
    { lat: 48.2827842, lng: 19.2946047 },
    { lat: 48.2828713, lng: 19.294544 },
    { lat: 48.2830915, lng: 19.2940618 },
    { lat: 48.2832513, lng: 19.293939 },
    { lat: 48.2833551, lng: 19.2939896 },
    { lat: 48.2835086, lng: 19.2944136 },
    { lat: 48.2835315, lng: 19.2944663 },
    { lat: 48.2836448, lng: 19.2945725 },
    { lat: 48.2842476, lng: 19.2945881 },
    { lat: 48.2845218, lng: 19.2948072 },
    { lat: 48.2846886, lng: 19.295087 },
    { lat: 48.2852223, lng: 19.2956067 },
    { lat: 48.2853748, lng: 19.2955218 },
    { lat: 48.2854517, lng: 19.2953875 },
    { lat: 48.2854353, lng: 19.295259 },
    { lat: 48.2856575, lng: 19.2950035 },
    { lat: 48.2859064, lng: 19.2951194 },
    { lat: 48.2862098, lng: 19.2950288 },
    { lat: 48.2865966, lng: 19.2950565 },
    { lat: 48.2866439, lng: 19.2949353 },
    { lat: 48.2865365, lng: 19.2943963 },
    { lat: 48.2865961, lng: 19.2942058 },
    { lat: 48.286655, lng: 19.2940727 },
    { lat: 48.2871176, lng: 19.2936636 },
    { lat: 48.2873573, lng: 19.2935597 },
    { lat: 48.2874591, lng: 19.293268 },
    { lat: 48.2875692, lng: 19.2926964 },
    { lat: 48.2877497, lng: 19.2924447 },
    { lat: 48.2880942, lng: 19.2925778 },
    { lat: 48.2882948, lng: 19.2924817 },
    { lat: 48.2887187, lng: 19.2919044 },
    { lat: 48.2891606, lng: 19.2918355 },
    { lat: 48.2897361, lng: 19.2915437 },
    { lat: 48.2900684, lng: 19.2918166 },
    { lat: 48.290183, lng: 19.2920908 },
    { lat: 48.2904325, lng: 19.292258 },
    { lat: 48.290782, lng: 19.2921199 },
    { lat: 48.2910645, lng: 19.2922089 },
    { lat: 48.2911677, lng: 19.2918225 },
    { lat: 48.2913075, lng: 19.2916168 },
    { lat: 48.291786, lng: 19.291459 },
    { lat: 48.291761, lng: 19.291399 },
    { lat: 48.291626, lng: 19.291056 },
    { lat: 48.291577, lng: 19.290781 },
    { lat: 48.291493, lng: 19.290394 },
    { lat: 48.291387, lng: 19.289907 },
    { lat: 48.291327, lng: 19.289592 },
    { lat: 48.291262, lng: 19.289256 },
    { lat: 48.291187, lng: 19.288862 },
    { lat: 48.291132, lng: 19.28855 },
    { lat: 48.291046, lng: 19.288149 },
    { lat: 48.290945, lng: 19.287837 },
    { lat: 48.290672, lng: 19.286317 },
    { lat: 48.290718, lng: 19.285968 },
    { lat: 48.290761, lng: 19.285685 },
    { lat: 48.290813, lng: 19.28539 },
    { lat: 48.290872, lng: 19.285128 },
    { lat: 48.290949, lng: 19.284887 },
    { lat: 48.291107, lng: 19.28465 },
    { lat: 48.291287, lng: 19.284456 },
    { lat: 48.29149, lng: 19.284237 },
    { lat: 48.291731, lng: 19.284017 },
    { lat: 48.291978, lng: 19.283835 },
    { lat: 48.292192, lng: 19.283634 },
    { lat: 48.292442, lng: 19.283371 },
    { lat: 48.292738, lng: 19.283096 },
    { lat: 48.29321, lng: 19.282678 },
    { lat: 48.293401, lng: 19.282527 },
    { lat: 48.29391, lng: 19.282152 },
    { lat: 48.294183, lng: 19.281915 },
    { lat: 48.294425, lng: 19.281719 },
    { lat: 48.294692, lng: 19.281456 },
    { lat: 48.294944, lng: 19.281189 },
    { lat: 48.295341, lng: 19.280716 },
    { lat: 48.29557, lng: 19.280446 },
    { lat: 48.295804, lng: 19.28017 },
    { lat: 48.296055, lng: 19.279864 },
    { lat: 48.29629, lng: 19.279574 },
    { lat: 48.296516, lng: 19.279338 },
    { lat: 48.296756, lng: 19.278949 },
    { lat: 48.296918, lng: 19.278624 },
    { lat: 48.297064, lng: 19.278261 },
    { lat: 48.297251, lng: 19.277642 },
    { lat: 48.297343, lng: 19.277289 },
    { lat: 48.297434, lng: 19.27693 },
    { lat: 48.297522, lng: 19.276587 },
    { lat: 48.297603, lng: 19.276278 },
    { lat: 48.297696, lng: 19.275896 },
    { lat: 48.297788, lng: 19.275542 },
    { lat: 48.297884, lng: 19.275175 },
    { lat: 48.297973, lng: 19.274816 },
    { lat: 48.298159, lng: 19.274161 },
    { lat: 48.298251, lng: 19.273814 },
    { lat: 48.298346, lng: 19.273443 },
    { lat: 48.29843, lng: 19.273086 },
    { lat: 48.29852, lng: 19.272735 },
    { lat: 48.298602, lng: 19.272418 },
    { lat: 48.298686, lng: 19.272089 },
    { lat: 48.298787, lng: 19.271793 },
    { lat: 48.29894, lng: 19.271526 },
    { lat: 48.299097, lng: 19.27128 },
    { lat: 48.299194, lng: 19.2711 },
    { lat: 48.299219, lng: 19.271054 },
    { lat: 48.299239, lng: 19.271017 },
    { lat: 48.299376, lng: 19.270848 },
    { lat: 48.299605, lng: 19.270671 },
    { lat: 48.299824, lng: 19.270704 },
    { lat: 48.30003, lng: 19.270725 },
    { lat: 48.300237, lng: 19.27075 },
    { lat: 48.300415, lng: 19.270793 },
    { lat: 48.30067, lng: 19.270875 },
    { lat: 48.300907, lng: 19.270931 },
    { lat: 48.301157, lng: 19.270944 },
    { lat: 48.301414, lng: 19.27089 },
    { lat: 48.301649, lng: 19.270754 },
    { lat: 48.30187, lng: 19.270628 },
    { lat: 48.30208, lng: 19.270419 },
    { lat: 48.30226, lng: 19.270198 },
    { lat: 48.302489, lng: 19.269759 },
    { lat: 48.302516, lng: 19.269708 },
    { lat: 48.302582, lng: 19.269581 },
    { lat: 48.302678, lng: 19.269227 },
    { lat: 48.302748, lng: 19.26891 },
    { lat: 48.302818, lng: 19.268558 },
    { lat: 48.302879, lng: 19.26816 },
    { lat: 48.302916, lng: 19.267837 },
    { lat: 48.302957, lng: 19.267461 },
    { lat: 48.303047, lng: 19.267146 },
    { lat: 48.303096, lng: 19.266912 },
    { lat: 48.303261, lng: 19.266643 },
    { lat: 48.303446, lng: 19.266438 },
    { lat: 48.303693, lng: 19.266285 },
    { lat: 48.303875, lng: 19.266217 },
    { lat: 48.304072, lng: 19.266175 },
    { lat: 48.304349, lng: 19.266133 },
    { lat: 48.304508, lng: 19.266143 },
    { lat: 48.30478, lng: 19.266188 },
    { lat: 48.305046, lng: 19.266211 },
    { lat: 48.30531, lng: 19.266422 },
    { lat: 48.305728, lng: 19.266518 },
    { lat: 48.305854, lng: 19.266595 },
    { lat: 48.305907, lng: 19.266805 },
    { lat: 48.306013, lng: 19.267382 },
    { lat: 48.306188, lng: 19.26794 },
    { lat: 48.306319, lng: 19.268178 },
    { lat: 48.306715, lng: 19.268382 },
    { lat: 48.307128, lng: 19.268488 },
    { lat: 48.30732, lng: 19.268324 },
    { lat: 48.307391, lng: 19.268181 },
    { lat: 48.307448, lng: 19.268009 },
    { lat: 48.307484, lng: 19.26792 },
    { lat: 48.307551, lng: 19.267792 },
    { lat: 48.307638, lng: 19.267707 },
    { lat: 48.307881, lng: 19.267661 },
    { lat: 48.308107, lng: 19.267961 },
    { lat: 48.308158, lng: 19.267969 },
    { lat: 48.308673, lng: 19.267951 },
    { lat: 48.308718, lng: 19.267908 },
    { lat: 48.308724, lng: 19.268059 },
    { lat: 48.308865, lng: 19.268023 },
    { lat: 48.309359, lng: 19.267693 },
    { lat: 48.3103, lng: 19.266948 },
    { lat: 48.310477, lng: 19.266861 },
    { lat: 48.310879, lng: 19.266527 },
    { lat: 48.310958, lng: 19.266425 },
    { lat: 48.311084, lng: 19.266412 },
    { lat: 48.311229, lng: 19.266256 },
    { lat: 48.311354, lng: 19.266216 },
    { lat: 48.311618, lng: 19.266014 },
    { lat: 48.311699, lng: 19.26596 },
    { lat: 48.311978, lng: 19.265856 },
    { lat: 48.312215, lng: 19.265717 },
    { lat: 48.312826, lng: 19.265645 },
    { lat: 48.313032, lng: 19.265649 },
    { lat: 48.313237, lng: 19.265678 },
    { lat: 48.313343, lng: 19.265698 },
    { lat: 48.314074, lng: 19.265599 },
    { lat: 48.31446, lng: 19.265547 },
    { lat: 48.315529, lng: 19.265697 },
    { lat: 48.315859, lng: 19.26586 },
    { lat: 48.31599, lng: 19.265969 },
    { lat: 48.316034, lng: 19.266037 },
    { lat: 48.316147, lng: 19.266054 },
    { lat: 48.316401, lng: 19.266155 },
    { lat: 48.316402, lng: 19.26623 },
    { lat: 48.316623, lng: 19.266313 },
    { lat: 48.317257, lng: 19.26646 },
    { lat: 48.317541, lng: 19.266488 },
    { lat: 48.317812, lng: 19.266386 },
    { lat: 48.318031, lng: 19.266332 },
    { lat: 48.318109, lng: 19.266375 },
    { lat: 48.318241, lng: 19.266369 },
    { lat: 48.318374, lng: 19.266278 },
    { lat: 48.318627, lng: 19.26619 },
    { lat: 48.318757, lng: 19.266195 },
    { lat: 48.318895, lng: 19.266098 },
    { lat: 48.319503, lng: 19.265952 },
    { lat: 48.319988, lng: 19.265838 },
    { lat: 48.3204, lng: 19.265752 },
    { lat: 48.320501, lng: 19.265746 },
    { lat: 48.320787, lng: 19.265507 },
    { lat: 48.320885, lng: 19.265652 },
    { lat: 48.32115, lng: 19.265812 },
    { lat: 48.321234, lng: 19.265843 },
    { lat: 48.321281, lng: 19.265905 },
    { lat: 48.321664, lng: 19.266 },
    { lat: 48.321722, lng: 19.265925 },
    { lat: 48.322046, lng: 19.265946 },
    { lat: 48.322226, lng: 19.265959 },
    { lat: 48.322283, lng: 19.265955 },
    { lat: 48.322732, lng: 19.265828 },
    { lat: 48.322877, lng: 19.26578 },
    { lat: 48.322928, lng: 19.265772 },
    { lat: 48.323377, lng: 19.26557 },
    { lat: 48.323454, lng: 19.26558 },
    { lat: 48.323571, lng: 19.26549 },
    { lat: 48.323883, lng: 19.265382 },
    { lat: 48.323983, lng: 19.265335 },
    { lat: 48.324057, lng: 19.2653 },
    { lat: 48.324152, lng: 19.265272 },
    { lat: 48.324411, lng: 19.265103 },
    { lat: 48.324589, lng: 19.264992 },
    { lat: 48.324768, lng: 19.26486 },
    { lat: 48.324917, lng: 19.264737 },
    { lat: 48.324893, lng: 19.264541 },
    { lat: 48.324919, lng: 19.264517 },
    { lat: 48.324904, lng: 19.264471 },
    { lat: 48.324884, lng: 19.264388 },
    { lat: 48.324959, lng: 19.264344 },
    { lat: 48.325078, lng: 19.264162 },
    { lat: 48.32512, lng: 19.264264 },
    { lat: 48.325401, lng: 19.263591 },
    { lat: 48.325411, lng: 19.263566 },
  ],
  Šuľa: [
    { lat: 48.342883, lng: 19.4106869 },
    { lat: 48.3429767, lng: 19.4106925 },
    { lat: 48.3431361, lng: 19.4111758 },
    { lat: 48.3433971, lng: 19.4112614 },
    { lat: 48.343489, lng: 19.4124402 },
    { lat: 48.3435611, lng: 19.4124177 },
    { lat: 48.3434818, lng: 19.4134121 },
    { lat: 48.3435489, lng: 19.414043 },
    { lat: 48.3433749, lng: 19.4148242 },
    { lat: 48.3434695, lng: 19.415251 },
    { lat: 48.3434986, lng: 19.415939 },
    { lat: 48.343464, lng: 19.4162998 },
    { lat: 48.3434933, lng: 19.4168253 },
    { lat: 48.3433647, lng: 19.4171585 },
    { lat: 48.3433394, lng: 19.417722 },
    { lat: 48.3434132, lng: 19.4183054 },
    { lat: 48.3433085, lng: 19.418765 },
    { lat: 48.3433654, lng: 19.4192738 },
    { lat: 48.343626, lng: 19.4198526 },
    { lat: 48.3435908, lng: 19.4201319 },
    { lat: 48.3436974, lng: 19.4202939 },
    { lat: 48.3437832, lng: 19.4206295 },
    { lat: 48.3437859, lng: 19.4211624 },
    { lat: 48.3441718, lng: 19.4216028 },
    { lat: 48.3443581, lng: 19.4219688 },
    { lat: 48.344989, lng: 19.4227256 },
    { lat: 48.3452878, lng: 19.4227062 },
    { lat: 48.3453451, lng: 19.4229652 },
    { lat: 48.3452298, lng: 19.4236991 },
    { lat: 48.3455588, lng: 19.4248418 },
    { lat: 48.3455534, lng: 19.4255885 },
    { lat: 48.3461239, lng: 19.4256927 },
    { lat: 48.3466366, lng: 19.4256418 },
    { lat: 48.3466727, lng: 19.4259508 },
    { lat: 48.3466002, lng: 19.426468 },
    { lat: 48.3463568, lng: 19.4269717 },
    { lat: 48.3465693, lng: 19.4274162 },
    { lat: 48.3469302, lng: 19.4273017 },
    { lat: 48.347061, lng: 19.429693 },
    { lat: 48.347358, lng: 19.429901 },
    { lat: 48.347444, lng: 19.429958 },
    { lat: 48.347454, lng: 19.429979 },
    { lat: 48.347459, lng: 19.429989 },
    { lat: 48.347613, lng: 19.430317 },
    { lat: 48.347638, lng: 19.430341 },
    { lat: 48.348006, lng: 19.430691 },
    { lat: 48.348461, lng: 19.430905 },
    { lat: 48.348696, lng: 19.431 },
    { lat: 48.348967, lng: 19.431039 },
    { lat: 48.349266, lng: 19.431041 },
    { lat: 48.349567, lng: 19.431024 },
    { lat: 48.349833, lng: 19.431038 },
    { lat: 48.350017, lng: 19.430946 },
    { lat: 48.350246, lng: 19.430833 },
    { lat: 48.350387, lng: 19.430771 },
    { lat: 48.350471, lng: 19.430734 },
    { lat: 48.35057, lng: 19.43069 },
    { lat: 48.351176, lng: 19.430269 },
    { lat: 48.351328, lng: 19.430194 },
    { lat: 48.351481, lng: 19.430118 },
    { lat: 48.351803, lng: 19.430035 },
    { lat: 48.352113, lng: 19.430048 },
    { lat: 48.352123, lng: 19.430051 },
    { lat: 48.352147, lng: 19.430057 },
    { lat: 48.352246, lng: 19.430083 },
    { lat: 48.352252, lng: 19.430085 },
    { lat: 48.352276, lng: 19.430092 },
    { lat: 48.352277, lng: 19.430123 },
    { lat: 48.352618, lng: 19.430203 },
    { lat: 48.352798, lng: 19.430238 },
    { lat: 48.352992, lng: 19.430263 },
    { lat: 48.353083, lng: 19.43026 },
    { lat: 48.353277, lng: 19.430254 },
    { lat: 48.353329, lng: 19.430261 },
    { lat: 48.353502, lng: 19.430284 },
    { lat: 48.35354, lng: 19.430289 },
    { lat: 48.353621, lng: 19.430299 },
    { lat: 48.353654, lng: 19.43029 },
    { lat: 48.353825, lng: 19.430242 },
    { lat: 48.353922, lng: 19.430215 },
    { lat: 48.354098, lng: 19.430166 },
    { lat: 48.354273, lng: 19.430117 },
    { lat: 48.35432, lng: 19.430096 },
    { lat: 48.354379, lng: 19.430069 },
    { lat: 48.354539, lng: 19.430018 },
    { lat: 48.354576, lng: 19.430006 },
    { lat: 48.354921, lng: 19.430063 },
    { lat: 48.355273, lng: 19.430182 },
    { lat: 48.35561, lng: 19.43057 },
    { lat: 48.355841, lng: 19.430648 },
    { lat: 48.356113, lng: 19.431201 },
    { lat: 48.356341, lng: 19.431592 },
    { lat: 48.35663, lng: 19.432107 },
    { lat: 48.356721, lng: 19.43233 },
    { lat: 48.357231, lng: 19.433276 },
    { lat: 48.357392, lng: 19.433388 },
    { lat: 48.358086, lng: 19.433661 },
    { lat: 48.358451, lng: 19.433828 },
    { lat: 48.358791, lng: 19.43388 },
    { lat: 48.359016, lng: 19.433899 },
    { lat: 48.359366, lng: 19.434048 },
    { lat: 48.359582, lng: 19.434214 },
    { lat: 48.359639, lng: 19.434368 },
    { lat: 48.359964, lng: 19.434578 },
    { lat: 48.360094, lng: 19.434686 },
    { lat: 48.360254, lng: 19.434775 },
    { lat: 48.360279, lng: 19.434801 },
    { lat: 48.360409, lng: 19.434871 },
    { lat: 48.36042, lng: 19.434877 },
    { lat: 48.360442, lng: 19.434904 },
    { lat: 48.360503, lng: 19.434981 },
    { lat: 48.360701, lng: 19.435125 },
    { lat: 48.36101, lng: 19.435087 },
    { lat: 48.361333, lng: 19.435165 },
    { lat: 48.361514, lng: 19.435295 },
    { lat: 48.361534, lng: 19.435312 },
    { lat: 48.361697, lng: 19.43546 },
    { lat: 48.361861, lng: 19.43573 },
    { lat: 48.362354, lng: 19.436336 },
    { lat: 48.362475, lng: 19.436506 },
    { lat: 48.362627, lng: 19.43664 },
    { lat: 48.362766, lng: 19.4367 },
    { lat: 48.362868, lng: 19.436744 },
    { lat: 48.363379, lng: 19.437148 },
    { lat: 48.363353, lng: 19.437453 },
    { lat: 48.363571, lng: 19.437636 },
    { lat: 48.363806, lng: 19.437871 },
    { lat: 48.364098, lng: 19.438067 },
    { lat: 48.364352, lng: 19.438282 },
    { lat: 48.364857, lng: 19.438772 },
    { lat: 48.36524, lng: 19.439061 },
    { lat: 48.365693, lng: 19.439301 },
    { lat: 48.366015, lng: 19.439417 },
    { lat: 48.366374, lng: 19.439532 },
    { lat: 48.366673, lng: 19.439714 },
    { lat: 48.366695, lng: 19.439728 },
    { lat: 48.36685, lng: 19.439838 },
    { lat: 48.367068, lng: 19.439993 },
    { lat: 48.3679, lng: 19.43951 },
    { lat: 48.368815, lng: 19.438793 },
    { lat: 48.368945, lng: 19.43864 },
    { lat: 48.36886, lng: 19.438272 },
    { lat: 48.368798, lng: 19.437907 },
    { lat: 48.368667, lng: 19.437553 },
    { lat: 48.368595, lng: 19.4373 },
    { lat: 48.368726, lng: 19.434416 },
    { lat: 48.369018, lng: 19.434041 },
    { lat: 48.368594, lng: 19.432588 },
    { lat: 48.368604, lng: 19.43232 },
    { lat: 48.368695, lng: 19.432072 },
    { lat: 48.368768, lng: 19.431902 },
    { lat: 48.368805, lng: 19.431859 },
    { lat: 48.368787, lng: 19.431705 },
    { lat: 48.368783, lng: 19.431677 },
    { lat: 48.368722, lng: 19.43155 },
    { lat: 48.368541, lng: 19.43148 },
    { lat: 48.367975, lng: 19.431358 },
    { lat: 48.367959, lng: 19.431516 },
    { lat: 48.36748, lng: 19.431436 },
    { lat: 48.367367, lng: 19.431376 },
    { lat: 48.367358, lng: 19.430956 },
    { lat: 48.367453, lng: 19.430661 },
    { lat: 48.367856, lng: 19.429542 },
    { lat: 48.367855, lng: 19.429195 },
    { lat: 48.367861, lng: 19.429113 },
    { lat: 48.367882, lng: 19.428846 },
    { lat: 48.367889, lng: 19.428812 },
    { lat: 48.367944, lng: 19.428479 },
    { lat: 48.367961, lng: 19.428305 },
    { lat: 48.367972, lng: 19.428208 },
    { lat: 48.367999, lng: 19.428104 },
    { lat: 48.368032, lng: 19.42798 },
    { lat: 48.368084, lng: 19.427777 },
    { lat: 48.368182, lng: 19.427428 },
    { lat: 48.368262, lng: 19.427223 },
    { lat: 48.368318, lng: 19.426968 },
    { lat: 48.368255, lng: 19.426879 },
    { lat: 48.368276, lng: 19.426741 },
    { lat: 48.368281, lng: 19.426719 },
    { lat: 48.36836, lng: 19.426412 },
    { lat: 48.368364, lng: 19.42632 },
    { lat: 48.368355, lng: 19.426304 },
    { lat: 48.368484, lng: 19.42588 },
    { lat: 48.368558, lng: 19.425557 },
    { lat: 48.368692, lng: 19.425166 },
    { lat: 48.368739, lng: 19.425006 },
    { lat: 48.368826, lng: 19.424528 },
    { lat: 48.368906, lng: 19.424095 },
    { lat: 48.369014, lng: 19.423589 },
    { lat: 48.369097, lng: 19.423019 },
    { lat: 48.369152, lng: 19.42238 },
    { lat: 48.369258, lng: 19.421658 },
    { lat: 48.369836, lng: 19.420494 },
    { lat: 48.370137, lng: 19.420092 },
    { lat: 48.370308, lng: 19.419745 },
    { lat: 48.370505, lng: 19.419524 },
    { lat: 48.370591, lng: 19.41936 },
    { lat: 48.370742, lng: 19.41918 },
    { lat: 48.371072, lng: 19.418966 },
    { lat: 48.371359, lng: 19.418722 },
    { lat: 48.371572, lng: 19.4183 },
    { lat: 48.371585, lng: 19.418307 },
    { lat: 48.371626, lng: 19.41831 },
    { lat: 48.371943, lng: 19.418335 },
    { lat: 48.372161, lng: 19.41838 },
    { lat: 48.372381, lng: 19.418446 },
    { lat: 48.372641, lng: 19.418479 },
    { lat: 48.372654, lng: 19.418302 },
    { lat: 48.372301, lng: 19.418211 },
    { lat: 48.372359, lng: 19.418019 },
    { lat: 48.372366, lng: 19.417767 },
    { lat: 48.372619, lng: 19.417807 },
    { lat: 48.372734, lng: 19.417727 },
    { lat: 48.372809, lng: 19.417705 },
    { lat: 48.37269, lng: 19.417555 },
    { lat: 48.372712, lng: 19.417452 },
    { lat: 48.37272, lng: 19.417412 },
    { lat: 48.372746, lng: 19.417289 },
    { lat: 48.373077, lng: 19.417562 },
    { lat: 48.373155, lng: 19.417646 },
    { lat: 48.373308, lng: 19.417734 },
    { lat: 48.373503, lng: 19.417755 },
    { lat: 48.373697, lng: 19.417749 },
    { lat: 48.373899, lng: 19.417725 },
    { lat: 48.374038, lng: 19.41772 },
    { lat: 48.374394, lng: 19.417684 },
    { lat: 48.374614, lng: 19.417635 },
    { lat: 48.374896, lng: 19.417551 },
    { lat: 48.374912, lng: 19.417483 },
    { lat: 48.374882, lng: 19.417267 },
    { lat: 48.374688, lng: 19.417216 },
    { lat: 48.374684, lng: 19.416787 },
    { lat: 48.374696, lng: 19.416436 },
    { lat: 48.374693, lng: 19.415999 },
    { lat: 48.374861, lng: 19.415554 },
    { lat: 48.374989, lng: 19.414287 },
    { lat: 48.375136, lng: 19.412837 },
    { lat: 48.375135, lng: 19.412695 },
    { lat: 48.375134, lng: 19.412132 },
    { lat: 48.37516, lng: 19.411483 },
    { lat: 48.375116, lng: 19.410595 },
    { lat: 48.375021, lng: 19.410512 },
    { lat: 48.374924, lng: 19.410388 },
    { lat: 48.374899, lng: 19.410347 },
    { lat: 48.374671, lng: 19.409971 },
    { lat: 48.374586, lng: 19.409788 },
    { lat: 48.374558, lng: 19.409573 },
    { lat: 48.374756, lng: 19.409388 },
    { lat: 48.374829, lng: 19.409616 },
    { lat: 48.374988, lng: 19.409835 },
    { lat: 48.37508, lng: 19.409584 },
    { lat: 48.37538, lng: 19.409317 },
    { lat: 48.375779, lng: 19.409099 },
    { lat: 48.376098, lng: 19.409229 },
    { lat: 48.376251, lng: 19.40917 },
    { lat: 48.378276, lng: 19.408401 },
    { lat: 48.3772624, lng: 19.4053684 },
    { lat: 48.37709, lng: 19.4046792 },
    { lat: 48.3765111, lng: 19.4030166 },
    { lat: 48.3763426, lng: 19.4024596 },
    { lat: 48.3763803, lng: 19.4023038 },
    { lat: 48.3758669, lng: 19.4010519 },
    { lat: 48.3758105, lng: 19.4006229 },
    { lat: 48.3753983, lng: 19.3993208 },
    { lat: 48.3751813, lng: 19.3991852 },
    { lat: 48.3743913, lng: 19.3982595 },
    { lat: 48.3742308, lng: 19.3979916 },
    { lat: 48.3741515, lng: 19.3976495 },
    { lat: 48.3734637, lng: 19.3963319 },
    { lat: 48.3723273, lng: 19.3938506 },
    { lat: 48.3714118, lng: 19.3929927 },
    { lat: 48.3707319, lng: 19.3934127 },
    { lat: 48.3702997, lng: 19.3935673 },
    { lat: 48.3700645, lng: 19.393836 },
    { lat: 48.3698593, lng: 19.3938366 },
    { lat: 48.369682, lng: 19.3931165 },
    { lat: 48.369498, lng: 19.3927764 },
    { lat: 48.369413, lng: 19.3925037 },
    { lat: 48.3689305, lng: 19.3918615 },
    { lat: 48.368814, lng: 19.3914454 },
    { lat: 48.367939, lng: 19.3904405 },
    { lat: 48.3677325, lng: 19.3900056 },
    { lat: 48.3671874, lng: 19.3891845 },
    { lat: 48.3663898, lng: 19.3872679 },
    { lat: 48.3649319, lng: 19.3849746 },
    { lat: 48.3643347, lng: 19.3842708 },
    { lat: 48.3638841, lng: 19.3834216 },
    { lat: 48.3630052, lng: 19.3832551 },
    { lat: 48.3619008, lng: 19.383072 },
    { lat: 48.3611626, lng: 19.3836886 },
    { lat: 48.3605352, lng: 19.3833893 },
    { lat: 48.3594858, lng: 19.3833367 },
    { lat: 48.3590626, lng: 19.3829454 },
    { lat: 48.3587341, lng: 19.3827722 },
    { lat: 48.3582367, lng: 19.3828814 },
    { lat: 48.3569988, lng: 19.3829 },
    { lat: 48.3560367, lng: 19.3836422 },
    { lat: 48.3496706, lng: 19.3875969 },
    { lat: 48.3461615, lng: 19.3888218 },
    { lat: 48.3457075, lng: 19.3889199 },
    { lat: 48.3447537, lng: 19.3889368 },
    { lat: 48.3447956, lng: 19.3896062 },
    { lat: 48.3448095, lng: 19.3897344 },
    { lat: 48.3448053, lng: 19.3898092 },
    { lat: 48.3439621, lng: 19.3896895 },
    { lat: 48.3427075, lng: 19.3896142 },
    { lat: 48.3422973, lng: 19.3917758 },
    { lat: 48.3422328, lng: 19.3924115 },
    { lat: 48.3422701, lng: 19.3933864 },
    { lat: 48.3421376, lng: 19.3949664 },
    { lat: 48.3422501, lng: 19.3953458 },
    { lat: 48.3421006, lng: 19.3955889 },
    { lat: 48.3418592, lng: 19.3968457 },
    { lat: 48.3417935, lng: 19.397456 },
    { lat: 48.341793, lng: 19.398538 },
    { lat: 48.3418582, lng: 19.3986076 },
    { lat: 48.3420234, lng: 19.3991228 },
    { lat: 48.3422842, lng: 19.3995835 },
    { lat: 48.3424186, lng: 19.4000612 },
    { lat: 48.3423887, lng: 19.4003376 },
    { lat: 48.3424419, lng: 19.4003889 },
    { lat: 48.3428797, lng: 19.4016403 },
    { lat: 48.3428979, lng: 19.4018796 },
    { lat: 48.342647, lng: 19.403649 },
    { lat: 48.3423772, lng: 19.4047597 },
    { lat: 48.3423613, lng: 19.4054214 },
    { lat: 48.3422515, lng: 19.4065341 },
    { lat: 48.3419151, lng: 19.4071162 },
    { lat: 48.3417763, lng: 19.4072578 },
    { lat: 48.3417142, lng: 19.4074423 },
    { lat: 48.3418062, lng: 19.4082193 },
    { lat: 48.3423726, lng: 19.4079496 },
    { lat: 48.3428874, lng: 19.4084184 },
    { lat: 48.3430855, lng: 19.4088165 },
    { lat: 48.3431647, lng: 19.4093285 },
    { lat: 48.3431008, lng: 19.4096208 },
    { lat: 48.3429429, lng: 19.4098059 },
    { lat: 48.3428802, lng: 19.4100535 },
    { lat: 48.3428502, lng: 19.4103234 },
    { lat: 48.342883, lng: 19.4106869 },
  ],
  Chrťany: [
    { lat: 48.2705056, lng: 19.4668843 },
    { lat: 48.2709511, lng: 19.4676126 },
    { lat: 48.2720161, lng: 19.4691537 },
    { lat: 48.2720463, lng: 19.469198 },
    { lat: 48.2723037, lng: 19.4689654 },
    { lat: 48.2726515, lng: 19.469631 },
    { lat: 48.2729613, lng: 19.4705872 },
    { lat: 48.2736527, lng: 19.4713024 },
    { lat: 48.2740649, lng: 19.4719904 },
    { lat: 48.2744349, lng: 19.4734222 },
    { lat: 48.2749096, lng: 19.4740993 },
    { lat: 48.2747758, lng: 19.4744438 },
    { lat: 48.2745306, lng: 19.4748421 },
    { lat: 48.2743194, lng: 19.475073 },
    { lat: 48.2742404, lng: 19.47555 },
    { lat: 48.2743375, lng: 19.476034 },
    { lat: 48.2747122, lng: 19.4769364 },
    { lat: 48.27483, lng: 19.4769064 },
    { lat: 48.2748649, lng: 19.4765925 },
    { lat: 48.2750354, lng: 19.4763089 },
    { lat: 48.2751812, lng: 19.4762692 },
    { lat: 48.2752747, lng: 19.4760987 },
    { lat: 48.2754427, lng: 19.4759799 },
    { lat: 48.2756341, lng: 19.4757094 },
    { lat: 48.275715, lng: 19.4758031 },
    { lat: 48.2759097, lng: 19.4758711 },
    { lat: 48.2760461, lng: 19.4752918 },
    { lat: 48.276177, lng: 19.4752685 },
    { lat: 48.2762968, lng: 19.4754463 },
    { lat: 48.2762131, lng: 19.4757774 },
    { lat: 48.2761981, lng: 19.4758401 },
    { lat: 48.2762375, lng: 19.4758151 },
    { lat: 48.2765132, lng: 19.475737 },
    { lat: 48.2767113, lng: 19.4760191 },
    { lat: 48.2766927, lng: 19.4765138 },
    { lat: 48.2767769, lng: 19.4766727 },
    { lat: 48.2769931, lng: 19.4765757 },
    { lat: 48.2772761, lng: 19.4767937 },
    { lat: 48.2772984, lng: 19.4768617 },
    { lat: 48.277239, lng: 19.4769193 },
    { lat: 48.2773801, lng: 19.4774026 },
    { lat: 48.2775047, lng: 19.4776117 },
    { lat: 48.2774602, lng: 19.477742 },
    { lat: 48.2776604, lng: 19.4781943 },
    { lat: 48.2777103, lng: 19.4782217 },
    { lat: 48.2778237, lng: 19.478116 },
    { lat: 48.2779245, lng: 19.4779848 },
    { lat: 48.2778766, lng: 19.4778978 },
    { lat: 48.2779192, lng: 19.4777989 },
    { lat: 48.2780271, lng: 19.4777179 },
    { lat: 48.2783757, lng: 19.4777743 },
    { lat: 48.2787032, lng: 19.4782937 },
    { lat: 48.278716, lng: 19.4785219 },
    { lat: 48.2788175, lng: 19.4788141 },
    { lat: 48.2788981, lng: 19.4788255 },
    { lat: 48.2790097, lng: 19.4784911 },
    { lat: 48.2791461, lng: 19.4784881 },
    { lat: 48.2792512, lng: 19.478666 },
    { lat: 48.2794743, lng: 19.4786387 },
    { lat: 48.279698, lng: 19.4788424 },
    { lat: 48.2798311, lng: 19.4791589 },
    { lat: 48.2798004, lng: 19.4792286 },
    { lat: 48.2800655, lng: 19.4794227 },
    { lat: 48.2800774, lng: 19.4795364 },
    { lat: 48.2803381, lng: 19.4798547 },
    { lat: 48.2807336, lng: 19.4798529 },
    { lat: 48.2808273, lng: 19.4796389 },
    { lat: 48.2809716, lng: 19.4796899 },
    { lat: 48.2812021, lng: 19.4796038 },
    { lat: 48.2814472, lng: 19.4792289 },
    { lat: 48.281591, lng: 19.4792449 },
    { lat: 48.2816374, lng: 19.4794895 },
    { lat: 48.281773, lng: 19.4796918 },
    { lat: 48.2816295, lng: 19.4802394 },
    { lat: 48.2817474, lng: 19.4808827 },
    { lat: 48.2818523, lng: 19.4810263 },
    { lat: 48.2819594, lng: 19.4809786 },
    { lat: 48.2823361, lng: 19.4804589 },
    { lat: 48.282428, lng: 19.4806347 },
    { lat: 48.2823137, lng: 19.4810147 },
    { lat: 48.2822941, lng: 19.481268 },
    { lat: 48.2824875, lng: 19.4815958 },
    { lat: 48.2824878, lng: 19.4818198 },
    { lat: 48.2823744, lng: 19.4820252 },
    { lat: 48.2824602, lng: 19.4823651 },
    { lat: 48.2826978, lng: 19.4823611 },
    { lat: 48.2827754, lng: 19.4824559 },
    { lat: 48.2827121, lng: 19.4831022 },
    { lat: 48.2827645, lng: 19.4833296 },
    { lat: 48.2829151, lng: 19.4834896 },
    { lat: 48.2829783, lng: 19.48407 },
    { lat: 48.2831714, lng: 19.4839358 },
    { lat: 48.2834123, lng: 19.4835684 },
    { lat: 48.2835484, lng: 19.4835142 },
    { lat: 48.2837276, lng: 19.4835385 },
    { lat: 48.2837349, lng: 19.4833311 },
    { lat: 48.2840017, lng: 19.4834461 },
    { lat: 48.2847375, lng: 19.4824281 },
    { lat: 48.2851764, lng: 19.4825612 },
    { lat: 48.2855004, lng: 19.4824748 },
    { lat: 48.285418, lng: 19.4814134 },
    { lat: 48.2856241, lng: 19.4812658 },
    { lat: 48.2860941, lng: 19.4807608 },
    { lat: 48.2866733, lng: 19.4800121 },
    { lat: 48.2871329, lng: 19.4792842 },
    { lat: 48.2874911, lng: 19.4797126 },
    { lat: 48.2882115, lng: 19.4786338 },
    { lat: 48.2893368, lng: 19.4771225 },
    { lat: 48.2894686, lng: 19.4768819 },
    { lat: 48.2895561, lng: 19.4764395 },
    { lat: 48.2902259, lng: 19.4768597 },
    { lat: 48.2909823, lng: 19.4758489 },
    { lat: 48.2914467, lng: 19.475322 },
    { lat: 48.2921972, lng: 19.4739217 },
    { lat: 48.2934645, lng: 19.4712133 },
    { lat: 48.2947688, lng: 19.4718686 },
    { lat: 48.295276, lng: 19.472211 },
    { lat: 48.2955875, lng: 19.471979 },
    { lat: 48.2957832, lng: 19.472042 },
    { lat: 48.296042, lng: 19.471406 },
    { lat: 48.2964464, lng: 19.4698834 },
    { lat: 48.2965473, lng: 19.4697033 },
    { lat: 48.2965764, lng: 19.4696528 },
    { lat: 48.2971298, lng: 19.4687873 },
    { lat: 48.2974932, lng: 19.4680996 },
    { lat: 48.2983308, lng: 19.4669734 },
    { lat: 48.2989845, lng: 19.4662031 },
    { lat: 48.2990732, lng: 19.4659796 },
    { lat: 48.3003251, lng: 19.4650172 },
    { lat: 48.3011611, lng: 19.4638054 },
    { lat: 48.3017939, lng: 19.4623976 },
    { lat: 48.3024608, lng: 19.4603965 },
    { lat: 48.3030145, lng: 19.4591318 },
    { lat: 48.3035241, lng: 19.4585114 },
    { lat: 48.3068895, lng: 19.4549839 },
    { lat: 48.3071351, lng: 19.4547863 },
    { lat: 48.3076158, lng: 19.4545876 },
    { lat: 48.3084446, lng: 19.4546652 },
    { lat: 48.3090597, lng: 19.4548043 },
    { lat: 48.3093796, lng: 19.4545401 },
    { lat: 48.3099284, lng: 19.4542981 },
    { lat: 48.3103939, lng: 19.4542724 },
    { lat: 48.3104859, lng: 19.4540747 },
    { lat: 48.3106069, lng: 19.4539858 },
    { lat: 48.3112981, lng: 19.4536828 },
    { lat: 48.3117252, lng: 19.4534133 },
    { lat: 48.3118401, lng: 19.4534606 },
    { lat: 48.3124203, lng: 19.4531424 },
    { lat: 48.3124919, lng: 19.4532039 },
    { lat: 48.3136485, lng: 19.4520346 },
    { lat: 48.3137801, lng: 19.451981 },
    { lat: 48.3138057, lng: 19.4521681 },
    { lat: 48.3144172, lng: 19.4520147 },
    { lat: 48.3149343, lng: 19.4516741 },
    { lat: 48.3152717, lng: 19.4515886 },
    { lat: 48.3155813, lng: 19.4514114 },
    { lat: 48.3156934, lng: 19.4511833 },
    { lat: 48.3158716, lng: 19.4509959 },
    { lat: 48.3165753, lng: 19.4505966 },
    { lat: 48.3174796, lng: 19.4497169 },
    { lat: 48.3183211, lng: 19.4484606 },
    { lat: 48.3184264, lng: 19.4482544 },
    { lat: 48.3185923, lng: 19.4474657 },
    { lat: 48.3186691, lng: 19.4468248 },
    { lat: 48.3187619, lng: 19.4465225 },
    { lat: 48.318954, lng: 19.4461482 },
    { lat: 48.3194581, lng: 19.4455517 },
    { lat: 48.3199765, lng: 19.4446875 },
    { lat: 48.3197134, lng: 19.4431952 },
    { lat: 48.3199953, lng: 19.4426679 },
    { lat: 48.3199682, lng: 19.4421155 },
    { lat: 48.320072, lng: 19.441857 },
    { lat: 48.3202116, lng: 19.4412049 },
    { lat: 48.3202556, lng: 19.4407048 },
    { lat: 48.3193064, lng: 19.4387362 },
    { lat: 48.3188573, lng: 19.4373819 },
    { lat: 48.3186902, lng: 19.4374966 },
    { lat: 48.3181937, lng: 19.4365262 },
    { lat: 48.3184234, lng: 19.4362335 },
    { lat: 48.3183595, lng: 19.4360954 },
    { lat: 48.3183426, lng: 19.4360706 },
    { lat: 48.318034, lng: 19.4354061 },
    { lat: 48.3181177, lng: 19.4352683 },
    { lat: 48.3181057, lng: 19.4352038 },
    { lat: 48.3173978, lng: 19.4341485 },
    { lat: 48.3172573, lng: 19.434419 },
    { lat: 48.3170133, lng: 19.4345779 },
    { lat: 48.3167837, lng: 19.435326 },
    { lat: 48.316289, lng: 19.4355171 },
    { lat: 48.3159481, lng: 19.435555 },
    { lat: 48.3143461, lng: 19.4351871 },
    { lat: 48.3140861, lng: 19.4351919 },
    { lat: 48.3133987, lng: 19.4349298 },
    { lat: 48.3129665, lng: 19.4351097 },
    { lat: 48.3126361, lng: 19.4354902 },
    { lat: 48.31219, lng: 19.4361421 },
    { lat: 48.3108615, lng: 19.4348535 },
    { lat: 48.3099066, lng: 19.4327401 },
    { lat: 48.3098149, lng: 19.4324478 },
    { lat: 48.3094859, lng: 19.4326897 },
    { lat: 48.3092746, lng: 19.4330357 },
    { lat: 48.308864, lng: 19.4329574 },
    { lat: 48.3084779, lng: 19.4332032 },
    { lat: 48.3080654, lng: 19.4337535 },
    { lat: 48.3074271, lng: 19.4341308 },
    { lat: 48.3069721, lng: 19.4345886 },
    { lat: 48.3066027, lng: 19.4350978 },
    { lat: 48.3061973, lng: 19.4360629 },
    { lat: 48.3058361, lng: 19.4364332 },
    { lat: 48.3048233, lng: 19.4380204 },
    { lat: 48.3046372, lng: 19.4384273 },
    { lat: 48.3046057, lng: 19.4385949 },
    { lat: 48.3046387, lng: 19.4388909 },
    { lat: 48.3037422, lng: 19.4390696 },
    { lat: 48.3036178, lng: 19.4388704 },
    { lat: 48.3031661, lng: 19.4377252 },
    { lat: 48.3029929, lng: 19.4369439 },
    { lat: 48.3028211, lng: 19.4366467 },
    { lat: 48.3023652, lng: 19.4367505 },
    { lat: 48.3012646, lng: 19.4367632 },
    { lat: 48.3009878, lng: 19.4367231 },
    { lat: 48.3007084, lng: 19.4365123 },
    { lat: 48.3003619, lng: 19.4366459 },
    { lat: 48.2992656, lng: 19.4357192 },
    { lat: 48.2967567, lng: 19.4384448 },
    { lat: 48.2937737, lng: 19.4412953 },
    { lat: 48.2899066, lng: 19.4446459 },
    { lat: 48.2881294, lng: 19.4463729 },
    { lat: 48.2875845, lng: 19.4470805 },
    { lat: 48.2786138, lng: 19.4568994 },
    { lat: 48.2705886, lng: 19.4667805 },
    { lat: 48.2705056, lng: 19.4668843 },
  ],
  Sucháň: [
    { lat: 48.342545, lng: 19.215811 },
    { lat: 48.342463, lng: 19.215457 },
    { lat: 48.342415, lng: 19.215373 },
    { lat: 48.342317, lng: 19.21506 },
    { lat: 48.342328, lng: 19.214944 },
    { lat: 48.342302, lng: 19.21486 },
    { lat: 48.342291, lng: 19.214787 },
    { lat: 48.342205, lng: 19.21419 },
    { lat: 48.342092, lng: 19.214158 },
    { lat: 48.342078, lng: 19.214157 },
    { lat: 48.341996, lng: 19.214059 },
    { lat: 48.342003, lng: 19.213933 },
    { lat: 48.341962, lng: 19.213841 },
    { lat: 48.341942, lng: 19.213832 },
    { lat: 48.341863, lng: 19.213804 },
    { lat: 48.341695, lng: 19.213488 },
    { lat: 48.341567, lng: 19.213051 },
    { lat: 48.341485, lng: 19.213058 },
    { lat: 48.341428, lng: 19.212959 },
    { lat: 48.341389, lng: 19.212754 },
    { lat: 48.341507, lng: 19.212687 },
    { lat: 48.341295, lng: 19.212308 },
    { lat: 48.341208, lng: 19.212074 },
    { lat: 48.341181, lng: 19.211833 },
    { lat: 48.341088, lng: 19.211814 },
    { lat: 48.341025, lng: 19.211545 },
    { lat: 48.340858, lng: 19.211496 },
    { lat: 48.340788, lng: 19.211578 },
    { lat: 48.340613, lng: 19.211466 },
    { lat: 48.340578, lng: 19.211392 },
    { lat: 48.340435, lng: 19.211376 },
    { lat: 48.340358, lng: 19.211176 },
    { lat: 48.340395, lng: 19.211069 },
    { lat: 48.340278, lng: 19.210845 },
    { lat: 48.340262, lng: 19.210814 },
    { lat: 48.340112, lng: 19.210779 },
    { lat: 48.339927, lng: 19.210569 },
    { lat: 48.339802, lng: 19.210463 },
    { lat: 48.339783, lng: 19.21039 },
    { lat: 48.339551, lng: 19.210335 },
    { lat: 48.339433, lng: 19.210418 },
    { lat: 48.33925, lng: 19.210309 },
    { lat: 48.339235, lng: 19.210126 },
    { lat: 48.339188, lng: 19.209857 },
    { lat: 48.339015, lng: 19.209772 },
    { lat: 48.338932, lng: 19.209784 },
    { lat: 48.338938, lng: 19.209882 },
    { lat: 48.338783, lng: 19.209758 },
    { lat: 48.338754, lng: 19.209669 },
    { lat: 48.33867, lng: 19.209784 },
    { lat: 48.338611, lng: 19.209763 },
    { lat: 48.338648, lng: 19.209604 },
    { lat: 48.338588, lng: 19.209578 },
    { lat: 48.338472, lng: 19.209712 },
    { lat: 48.338355, lng: 19.209604 },
    { lat: 48.338321, lng: 19.209567 },
    { lat: 48.338318, lng: 19.209562 },
    { lat: 48.338246, lng: 19.209419 },
    { lat: 48.338148, lng: 19.209299 },
    { lat: 48.338094, lng: 19.209296 },
    { lat: 48.338046, lng: 19.209372 },
    { lat: 48.337994, lng: 19.20944 },
    { lat: 48.337891, lng: 19.20939 },
    { lat: 48.33781, lng: 19.20934 },
    { lat: 48.337712, lng: 19.209292 },
    { lat: 48.337558, lng: 19.209475 },
    { lat: 48.337502, lng: 19.20945 },
    { lat: 48.337378, lng: 19.209384 },
    { lat: 48.337327, lng: 19.209356 },
    { lat: 48.337338, lng: 19.209291 },
    { lat: 48.337196, lng: 19.209338 },
    { lat: 48.337073, lng: 19.209383 },
    { lat: 48.337002, lng: 19.209408 },
    { lat: 48.336879, lng: 19.209363 },
    { lat: 48.336768, lng: 19.209214 },
    { lat: 48.336703, lng: 19.20913 },
    { lat: 48.336575, lng: 19.209206 },
    { lat: 48.336523, lng: 19.209231 },
    { lat: 48.336448, lng: 19.209337 },
    { lat: 48.336253, lng: 19.209276 },
    { lat: 48.33612, lng: 19.209231 },
    { lat: 48.336086, lng: 19.209296 },
    { lat: 48.336006, lng: 19.209329 },
    { lat: 48.335977, lng: 19.209336 },
    { lat: 48.33594, lng: 19.209336 },
    { lat: 48.33571, lng: 19.209299 },
    { lat: 48.335603, lng: 19.209129 },
    { lat: 48.335497, lng: 19.209098 },
    { lat: 48.33538, lng: 19.209059 },
    { lat: 48.335223, lng: 19.209111 },
    { lat: 48.335095, lng: 19.209226 },
    { lat: 48.335051, lng: 19.209233 },
    { lat: 48.334935, lng: 19.208944 },
    { lat: 48.334833, lng: 19.208836 },
    { lat: 48.334734, lng: 19.20879 },
    { lat: 48.334572, lng: 19.208808 },
    { lat: 48.334474, lng: 19.208909 },
    { lat: 48.334397, lng: 19.208946 },
    { lat: 48.334283, lng: 19.208902 },
    { lat: 48.334013, lng: 19.208863 },
    { lat: 48.333964, lng: 19.208853 },
    { lat: 48.333918, lng: 19.208825 },
    { lat: 48.333789, lng: 19.208758 },
    { lat: 48.333723, lng: 19.208721 },
    { lat: 48.333599, lng: 19.208762 },
    { lat: 48.333467, lng: 19.208808 },
    { lat: 48.333359, lng: 19.208844 },
    { lat: 48.333122, lng: 19.209017 },
    { lat: 48.333128, lng: 19.209134 },
    { lat: 48.33305, lng: 19.209131 },
    { lat: 48.332959, lng: 19.209125 },
    { lat: 48.332952, lng: 19.209141 },
    { lat: 48.332926, lng: 19.209188 },
    { lat: 48.332867, lng: 19.209307 },
    { lat: 48.33275, lng: 19.209384 },
    { lat: 48.332664, lng: 19.20934 },
    { lat: 48.332531, lng: 19.209297 },
    { lat: 48.332299, lng: 19.209178 },
    { lat: 48.332204, lng: 19.209079 },
    { lat: 48.332061, lng: 19.20893 },
    { lat: 48.331952, lng: 19.208809 },
    { lat: 48.331845, lng: 19.208901 },
    { lat: 48.331725, lng: 19.208821 },
    { lat: 48.331578, lng: 19.20872 },
    { lat: 48.331519, lng: 19.20868 },
    { lat: 48.33142, lng: 19.208769 },
    { lat: 48.331329, lng: 19.208845 },
    { lat: 48.3311, lng: 19.208771 },
    { lat: 48.331137, lng: 19.208524 },
    { lat: 48.331069, lng: 19.20844 },
    { lat: 48.330968, lng: 19.208339 },
    { lat: 48.330936, lng: 19.208307 },
    { lat: 48.330794, lng: 19.208436 },
    { lat: 48.3307, lng: 19.208424 },
    { lat: 48.330638, lng: 19.208524 },
    { lat: 48.330642, lng: 19.208623 },
    { lat: 48.330588, lng: 19.208675 },
    { lat: 48.330514, lng: 19.208738 },
    { lat: 48.33044, lng: 19.208764 },
    { lat: 48.330304, lng: 19.208694 },
    { lat: 48.330317, lng: 19.208628 },
    { lat: 48.33019, lng: 19.208655 },
    { lat: 48.330163, lng: 19.208532 },
    { lat: 48.330084, lng: 19.208532 },
    { lat: 48.330075, lng: 19.208633 },
    { lat: 48.330028, lng: 19.208595 },
    { lat: 48.330006, lng: 19.208522 },
    { lat: 48.329945, lng: 19.208504 },
    { lat: 48.329936, lng: 19.208408 },
    { lat: 48.329804, lng: 19.208432 },
    { lat: 48.329808, lng: 19.208548 },
    { lat: 48.329736, lng: 19.208685 },
    { lat: 48.329618, lng: 19.208703 },
    { lat: 48.329573, lng: 19.208711 },
    { lat: 48.329469, lng: 19.208774 },
    { lat: 48.329319, lng: 19.208864 },
    { lat: 48.329302, lng: 19.208864 },
    { lat: 48.329245, lng: 19.208816 },
    { lat: 48.328969, lng: 19.208849 },
    { lat: 48.32892, lng: 19.20889 },
    { lat: 48.328872, lng: 19.208861 },
    { lat: 48.32883, lng: 19.208827 },
    { lat: 48.328799, lng: 19.208785 },
    { lat: 48.32868, lng: 19.208829 },
    { lat: 48.328524, lng: 19.208888 },
    { lat: 48.328458, lng: 19.208914 },
    { lat: 48.328402, lng: 19.20894 },
    { lat: 48.328374, lng: 19.20893 },
    { lat: 48.328261, lng: 19.208878 },
    { lat: 48.328323, lng: 19.208757 },
    { lat: 48.328282, lng: 19.208678 },
    { lat: 48.32808, lng: 19.208801 },
    { lat: 48.328094, lng: 19.208901 },
    { lat: 48.327954, lng: 19.208967 },
    { lat: 48.327928, lng: 19.209099 },
    { lat: 48.327888, lng: 19.209154 },
    { lat: 48.327758, lng: 19.209151 },
    { lat: 48.327646, lng: 19.209039 },
    { lat: 48.327589, lng: 19.209249 },
    { lat: 48.327354, lng: 19.209272 },
    { lat: 48.327274, lng: 19.209408 },
    { lat: 48.327216, lng: 19.209333 },
    { lat: 48.327192, lng: 19.209344 },
    { lat: 48.327162, lng: 19.20935 },
    { lat: 48.326932, lng: 19.209406 },
    { lat: 48.326852, lng: 19.209255 },
    { lat: 48.326733, lng: 19.209295 },
    { lat: 48.326592, lng: 19.209344 },
    { lat: 48.32661, lng: 19.209207 },
    { lat: 48.326493, lng: 19.209203 },
    { lat: 48.326329, lng: 19.209512 },
    { lat: 48.326235, lng: 19.20963 },
    { lat: 48.326049, lng: 19.209471 },
    { lat: 48.325961, lng: 19.209489 },
    { lat: 48.325927, lng: 19.209557 },
    { lat: 48.325853, lng: 19.209571 },
    { lat: 48.325868, lng: 19.209414 },
    { lat: 48.325629, lng: 19.209457 },
    { lat: 48.325548, lng: 19.209426 },
    { lat: 48.325558, lng: 19.209567 },
    { lat: 48.325446, lng: 19.209724 },
    { lat: 48.32541, lng: 19.209772 },
    { lat: 48.325353, lng: 19.20974 },
    { lat: 48.325245, lng: 19.209679 },
    { lat: 48.325065, lng: 19.209809 },
    { lat: 48.324933, lng: 19.209904 },
    { lat: 48.324803, lng: 19.20989 },
    { lat: 48.324688, lng: 19.209877 },
    { lat: 48.324521, lng: 19.209938 },
    { lat: 48.324473, lng: 19.209955 },
    { lat: 48.324439, lng: 19.210098 },
    { lat: 48.324402, lng: 19.210067 },
    { lat: 48.324329, lng: 19.209996 },
    { lat: 48.324235, lng: 19.210157 },
    { lat: 48.324189, lng: 19.210334 },
    { lat: 48.324083, lng: 19.210428 },
    { lat: 48.324018, lng: 19.210432 },
    { lat: 48.323883, lng: 19.21044 },
    { lat: 48.323886, lng: 19.21053 },
    { lat: 48.323821, lng: 19.210671 },
    { lat: 48.323634, lng: 19.211088 },
    { lat: 48.32358, lng: 19.211139 },
    { lat: 48.323599, lng: 19.211306 },
    { lat: 48.323527, lng: 19.211375 },
    { lat: 48.323415, lng: 19.21138 },
    { lat: 48.323307, lng: 19.211518 },
    { lat: 48.323332, lng: 19.21173 },
    { lat: 48.323118, lng: 19.211929 },
    { lat: 48.32303, lng: 19.212013 },
    { lat: 48.323038, lng: 19.212099 },
    { lat: 48.322959, lng: 19.212191 },
    { lat: 48.322881, lng: 19.212163 },
    { lat: 48.322851, lng: 19.21216 },
    { lat: 48.322733, lng: 19.21216 },
    { lat: 48.322541, lng: 19.212039 },
    { lat: 48.322409, lng: 19.212192 },
    { lat: 48.322294, lng: 19.212138 },
    { lat: 48.322216, lng: 19.21198 },
    { lat: 48.322138, lng: 19.211818 },
    { lat: 48.322115, lng: 19.211667 },
    { lat: 48.321977, lng: 19.211645 },
    { lat: 48.321864, lng: 19.211824 },
    { lat: 48.321695, lng: 19.211834 },
    { lat: 48.321658, lng: 19.211642 },
    { lat: 48.321566, lng: 19.211667 },
    { lat: 48.321546, lng: 19.211682 },
    { lat: 48.321495, lng: 19.211718 },
    { lat: 48.321397, lng: 19.211683 },
    { lat: 48.321316, lng: 19.211488 },
    { lat: 48.321171, lng: 19.211368 },
    { lat: 48.321125, lng: 19.211333 },
    { lat: 48.321095, lng: 19.211221 },
    { lat: 48.321129, lng: 19.211131 },
    { lat: 48.321187, lng: 19.2111 },
    { lat: 48.321175, lng: 19.210983 },
    { lat: 48.320905, lng: 19.210894 },
    { lat: 48.320702, lng: 19.210789 },
    { lat: 48.32065, lng: 19.210555 },
    { lat: 48.320502, lng: 19.210556 },
    { lat: 48.320355, lng: 19.210557 },
    { lat: 48.320287, lng: 19.211013 },
    { lat: 48.320113, lng: 19.210892 },
    { lat: 48.320139, lng: 19.210551 },
    { lat: 48.320094, lng: 19.210577 },
    { lat: 48.319832, lng: 19.210729 },
    { lat: 48.319838, lng: 19.210514 },
    { lat: 48.319719, lng: 19.210508 },
    { lat: 48.319613, lng: 19.21036 },
    { lat: 48.319609, lng: 19.210508 },
    { lat: 48.319517, lng: 19.210488 },
    { lat: 48.319494, lng: 19.210486 },
    { lat: 48.319465, lng: 19.210324 },
    { lat: 48.319415, lng: 19.210403 },
    { lat: 48.319363, lng: 19.210488 },
    { lat: 48.319285, lng: 19.210517 },
    { lat: 48.319181, lng: 19.210399 },
    { lat: 48.319225, lng: 19.210254 },
    { lat: 48.319147, lng: 19.210203 },
    { lat: 48.31903, lng: 19.210374 },
    { lat: 48.318937, lng: 19.210218 },
    { lat: 48.318882, lng: 19.210208 },
    { lat: 48.318899, lng: 19.210076 },
    { lat: 48.318814, lng: 19.210089 },
    { lat: 48.318696, lng: 19.21026 },
    { lat: 48.318647, lng: 19.210332 },
    { lat: 48.318542, lng: 19.210277 },
    { lat: 48.318433, lng: 19.210245 },
    { lat: 48.318363, lng: 19.210134 },
    { lat: 48.318328, lng: 19.209904 },
    { lat: 48.318197, lng: 19.209823 },
    { lat: 48.31799, lng: 19.209691 },
    { lat: 48.317924, lng: 19.20979 },
    { lat: 48.317898, lng: 19.209648 },
    { lat: 48.317576, lng: 19.209695 },
    { lat: 48.317464, lng: 19.209607 },
    { lat: 48.317391, lng: 19.209437 },
    { lat: 48.317255, lng: 19.209309 },
    { lat: 48.317337, lng: 19.209224 },
    { lat: 48.317273, lng: 19.209031 },
    { lat: 48.317163, lng: 19.208948 },
    { lat: 48.316972, lng: 19.208882 },
    { lat: 48.316902, lng: 19.208856 },
    { lat: 48.316894, lng: 19.209025 },
    { lat: 48.316723, lng: 19.208987 },
    { lat: 48.31662, lng: 19.208964 },
    { lat: 48.316402, lng: 19.20867 },
    { lat: 48.316377, lng: 19.208733 },
    { lat: 48.316322, lng: 19.208798 },
    { lat: 48.316204, lng: 19.20865 },
    { lat: 48.316193, lng: 19.208592 },
    { lat: 48.316312, lng: 19.208552 },
    { lat: 48.316279, lng: 19.208458 },
    { lat: 48.316156, lng: 19.208478 },
    { lat: 48.316117, lng: 19.208412 },
    { lat: 48.316172, lng: 19.208369 },
    { lat: 48.316188, lng: 19.208252 },
    { lat: 48.316107, lng: 19.208132 },
    { lat: 48.316021, lng: 19.208178 },
    { lat: 48.315651, lng: 19.208369 },
    { lat: 48.315541, lng: 19.208167 },
    { lat: 48.315289, lng: 19.208157 },
    { lat: 48.31528, lng: 19.208046 },
    { lat: 48.31514, lng: 19.208079 },
    { lat: 48.315087, lng: 19.208461 },
    { lat: 48.314926, lng: 19.208557 },
    { lat: 48.314809, lng: 19.208507 },
    { lat: 48.314651, lng: 19.208533 },
    { lat: 48.31466, lng: 19.208128 },
    { lat: 48.314631, lng: 19.20813 },
    { lat: 48.314458, lng: 19.208143 },
    { lat: 48.314428, lng: 19.20761 },
    { lat: 48.314361, lng: 19.206585 },
    { lat: 48.314328, lng: 19.206354 },
    { lat: 48.314302, lng: 19.206143 },
    { lat: 48.314033, lng: 19.204941 },
    { lat: 48.313964, lng: 19.204832 },
    { lat: 48.313835, lng: 19.204305 },
    { lat: 48.313825, lng: 19.204262 },
    { lat: 48.313753, lng: 19.204047 },
    { lat: 48.313661, lng: 19.203786 },
    { lat: 48.313568, lng: 19.203808 },
    { lat: 48.312795, lng: 19.203943 },
    { lat: 48.31267, lng: 19.203965 },
    { lat: 48.31259, lng: 19.203976 },
    { lat: 48.312469, lng: 19.204014 },
    { lat: 48.312266, lng: 19.204071 },
    { lat: 48.312205, lng: 19.203191 },
    { lat: 48.312069, lng: 19.203221 },
    { lat: 48.311773, lng: 19.203285 },
    { lat: 48.311646, lng: 19.203311 },
    { lat: 48.311494, lng: 19.203343 },
    { lat: 48.311118, lng: 19.203426 },
    { lat: 48.310872, lng: 19.20343 },
    { lat: 48.310459, lng: 19.203361 },
    { lat: 48.310433, lng: 19.203355 },
    { lat: 48.310293, lng: 19.203323 },
    { lat: 48.310146, lng: 19.203286 },
    { lat: 48.310145, lng: 19.203286 },
    { lat: 48.309967, lng: 19.203242 },
    { lat: 48.309875, lng: 19.203219 },
    { lat: 48.309707, lng: 19.203165 },
    { lat: 48.309227, lng: 19.203022 },
    { lat: 48.309054, lng: 19.202963 },
    { lat: 48.308854, lng: 19.202892 },
    { lat: 48.308561, lng: 19.202792 },
    { lat: 48.308484, lng: 19.202774 },
    { lat: 48.308416, lng: 19.20276 },
    { lat: 48.308351, lng: 19.202737 },
    { lat: 48.308285, lng: 19.202713 },
    { lat: 48.308146, lng: 19.202663 },
    { lat: 48.308121, lng: 19.202655 },
    { lat: 48.307986, lng: 19.202615 },
    { lat: 48.307852, lng: 19.20258 },
    { lat: 48.307698, lng: 19.20254 },
    { lat: 48.307525, lng: 19.20249 },
    { lat: 48.307499, lng: 19.202487 },
    { lat: 48.307373, lng: 19.202435 },
    { lat: 48.307225, lng: 19.202371 },
    { lat: 48.307146, lng: 19.20234 },
    { lat: 48.306978, lng: 19.202243 },
    { lat: 48.306791, lng: 19.202141 },
    { lat: 48.306612, lng: 19.202042 },
    { lat: 48.306382, lng: 19.201898 },
    { lat: 48.306183, lng: 19.201777 },
    { lat: 48.306062, lng: 19.201705 },
    { lat: 48.306034, lng: 19.201689 },
    { lat: 48.306, lng: 19.201667 },
    { lat: 48.305929, lng: 19.201625 },
    { lat: 48.30573, lng: 19.201519 },
    { lat: 48.305575, lng: 19.201439 },
    { lat: 48.305557, lng: 19.201027 },
    { lat: 48.305242, lng: 19.201104 },
    { lat: 48.30521, lng: 19.20111 },
    { lat: 48.305212, lng: 19.20075 },
    { lat: 48.305185, lng: 19.200754 },
    { lat: 48.304989, lng: 19.200786 },
    { lat: 48.304779, lng: 19.200824 },
    { lat: 48.304481, lng: 19.200877 },
    { lat: 48.304391, lng: 19.200892 },
    { lat: 48.304006, lng: 19.200952 },
    { lat: 48.303916, lng: 19.200964 },
    { lat: 48.303811, lng: 19.200977 },
    { lat: 48.303709, lng: 19.20098 },
    { lat: 48.303606, lng: 19.200984 },
    { lat: 48.303515, lng: 19.200985 },
    { lat: 48.303508, lng: 19.200985 },
    { lat: 48.303413, lng: 19.200988 },
    { lat: 48.303313, lng: 19.20099 },
    { lat: 48.303145, lng: 19.200994 },
    { lat: 48.302955, lng: 19.201001 },
    { lat: 48.302737, lng: 19.201009 },
    { lat: 48.302633, lng: 19.201014 },
    { lat: 48.302365, lng: 19.201021 },
    { lat: 48.302305, lng: 19.201023 },
    { lat: 48.302079, lng: 19.201009 },
    { lat: 48.302022, lng: 19.201008 },
    { lat: 48.301867, lng: 19.201007 },
    { lat: 48.301787, lng: 19.201006 },
    { lat: 48.301742, lng: 19.201007 },
    { lat: 48.301698, lng: 19.201006 },
    { lat: 48.301626, lng: 19.201005 },
    { lat: 48.301579, lng: 19.201006 },
    { lat: 48.301532, lng: 19.201005 },
    { lat: 48.301476, lng: 19.201006 },
    { lat: 48.301423, lng: 19.201005 },
    { lat: 48.30137, lng: 19.201005 },
    { lat: 48.301309, lng: 19.201005 },
    { lat: 48.301256, lng: 19.201006 },
    { lat: 48.301184, lng: 19.201007 },
    { lat: 48.301143, lng: 19.201016 },
    { lat: 48.301017, lng: 19.201051 },
    { lat: 48.300801, lng: 19.201107 },
    { lat: 48.300605, lng: 19.20116 },
    { lat: 48.300336, lng: 19.201127 },
    { lat: 48.300291, lng: 19.201125 },
    { lat: 48.300108, lng: 19.201118 },
    { lat: 48.299889, lng: 19.201098 },
    { lat: 48.299343, lng: 19.201049 },
    { lat: 48.299093, lng: 19.201024 },
    { lat: 48.29862, lng: 19.201006 },
    { lat: 48.298612, lng: 19.201005 },
    { lat: 48.298226, lng: 19.200993 },
    { lat: 48.298124, lng: 19.200989 },
    { lat: 48.297854, lng: 19.200927 },
    { lat: 48.297731, lng: 19.201553 },
    { lat: 48.297139, lng: 19.201482 },
    { lat: 48.296868, lng: 19.201449 },
    { lat: 48.296657, lng: 19.202378 },
    { lat: 48.296636, lng: 19.202473 },
    { lat: 48.296542, lng: 19.202734 },
    { lat: 48.296412, lng: 19.203087 },
    { lat: 48.296328, lng: 19.203318 },
    { lat: 48.29633, lng: 19.203346 },
    { lat: 48.296335, lng: 19.203419 },
    { lat: 48.296382, lng: 19.204122 },
    { lat: 48.296603, lng: 19.205838 },
    { lat: 48.296039, lng: 19.206733 },
    { lat: 48.295833, lng: 19.207335 },
    { lat: 48.295402, lng: 19.207902 },
    { lat: 48.295318, lng: 19.208014 },
    { lat: 48.295238, lng: 19.208189 },
    { lat: 48.295065, lng: 19.208383 },
    { lat: 48.295031, lng: 19.208422 },
    { lat: 48.294927, lng: 19.208623 },
    { lat: 48.294766, lng: 19.208662 },
    { lat: 48.294702, lng: 19.208823 },
    { lat: 48.294554, lng: 19.208881 },
    { lat: 48.293906, lng: 19.20894 },
    { lat: 48.293466, lng: 19.208982 },
    { lat: 48.2932, lng: 19.209027 },
    { lat: 48.292925, lng: 19.209074 },
    { lat: 48.292453, lng: 19.209154 },
    { lat: 48.292398, lng: 19.209171 },
    { lat: 48.291981, lng: 19.209323 },
    { lat: 48.291612, lng: 19.209769 },
    { lat: 48.29125, lng: 19.210202 },
    { lat: 48.290905, lng: 19.210536 },
    { lat: 48.29076, lng: 19.21073 },
    { lat: 48.290482, lng: 19.211032 },
    { lat: 48.290372, lng: 19.211116 },
    { lat: 48.29031, lng: 19.211164 },
    { lat: 48.290296, lng: 19.211087 },
    { lat: 48.290024, lng: 19.211483 },
    { lat: 48.289861, lng: 19.211719 },
    { lat: 48.289656, lng: 19.211712 },
    { lat: 48.289132, lng: 19.211699 },
    { lat: 48.28865, lng: 19.211653 },
    { lat: 48.288408, lng: 19.211618 },
    { lat: 48.288299, lng: 19.211602 },
    { lat: 48.287973, lng: 19.211554 },
    { lat: 48.287753, lng: 19.211643 },
    { lat: 48.287701, lng: 19.211664 },
    { lat: 48.287263, lng: 19.21185 },
    { lat: 48.28696, lng: 19.211973 },
    { lat: 48.286906, lng: 19.211996 },
    { lat: 48.286573, lng: 19.212132 },
    { lat: 48.286198, lng: 19.212315 },
    { lat: 48.286141, lng: 19.212341 },
    { lat: 48.286119, lng: 19.212241 },
    { lat: 48.285866, lng: 19.2123 },
    { lat: 48.285744, lng: 19.212329 },
    { lat: 48.28567, lng: 19.212348 },
    { lat: 48.285693, lng: 19.212858 },
    { lat: 48.285502, lng: 19.212876 },
    { lat: 48.285516, lng: 19.213396 },
    { lat: 48.285517, lng: 19.213485 },
    { lat: 48.285097, lng: 19.213504 },
    { lat: 48.284932, lng: 19.213455 },
    { lat: 48.284359, lng: 19.213293 },
    { lat: 48.284325, lng: 19.213278 },
    { lat: 48.284028, lng: 19.213194 },
    { lat: 48.283816, lng: 19.213123 },
    { lat: 48.283568, lng: 19.212996 },
    { lat: 48.283433, lng: 19.212934 },
    { lat: 48.283191, lng: 19.212883 },
    { lat: 48.282991, lng: 19.212841 },
    { lat: 48.282993, lng: 19.212983 },
    { lat: 48.282599, lng: 19.212929 },
    { lat: 48.282517, lng: 19.213278 },
    { lat: 48.28238, lng: 19.213699 },
    { lat: 48.282342, lng: 19.214039 },
    { lat: 48.282218, lng: 19.214443 },
    { lat: 48.282043, lng: 19.214518 },
    { lat: 48.281843, lng: 19.21485 },
    { lat: 48.281831, lng: 19.214978 },
    { lat: 48.281821, lng: 19.214977 },
    { lat: 48.281665, lng: 19.215022 },
    { lat: 48.281652, lng: 19.214877 },
    { lat: 48.281521, lng: 19.21479 },
    { lat: 48.281454, lng: 19.214908 },
    { lat: 48.281294, lng: 19.214816 },
    { lat: 48.281164, lng: 19.214918 },
    { lat: 48.281136, lng: 19.214967 },
    { lat: 48.281104, lng: 19.215026 },
    { lat: 48.281068, lng: 19.215033 },
    { lat: 48.280976, lng: 19.215054 },
    { lat: 48.28088, lng: 19.215058 },
    { lat: 48.280831, lng: 19.214978 },
    { lat: 48.280716, lng: 19.214865 },
    { lat: 48.280555, lng: 19.214761 },
    { lat: 48.280412, lng: 19.214761 },
    { lat: 48.280328, lng: 19.214808 },
    { lat: 48.280275, lng: 19.214813 },
    { lat: 48.280132, lng: 19.214618 },
    { lat: 48.280076, lng: 19.21459 },
    { lat: 48.280001, lng: 19.214553 },
    { lat: 48.279822, lng: 19.214628 },
    { lat: 48.279715, lng: 19.214583 },
    { lat: 48.279506, lng: 19.214707 },
    { lat: 48.279337, lng: 19.214783 },
    { lat: 48.279322, lng: 19.214809 },
    { lat: 48.279273, lng: 19.214896 },
    { lat: 48.279195, lng: 19.214859 },
    { lat: 48.278937, lng: 19.214567 },
    { lat: 48.2787, lng: 19.214321 },
    { lat: 48.27864, lng: 19.21426 },
    { lat: 48.278632, lng: 19.214273 },
    { lat: 48.278363, lng: 19.214461 },
    { lat: 48.278136, lng: 19.214528 },
    { lat: 48.277967, lng: 19.214614 },
    { lat: 48.27734, lng: 19.214641 },
    { lat: 48.277231, lng: 19.214644 },
    { lat: 48.276758, lng: 19.214582 },
    { lat: 48.276604, lng: 19.214563 },
    { lat: 48.276524, lng: 19.214556 },
    { lat: 48.276237, lng: 19.214747 },
    { lat: 48.276099, lng: 19.21484 },
    { lat: 48.275487, lng: 19.215223 },
    { lat: 48.275346, lng: 19.215313 },
    { lat: 48.274945, lng: 19.215449 },
    { lat: 48.274675, lng: 19.215551 },
    { lat: 48.2746, lng: 19.215572 },
    { lat: 48.274537, lng: 19.215578 },
    { lat: 48.27446, lng: 19.215585 },
    { lat: 48.274339, lng: 19.215597 },
    { lat: 48.274303, lng: 19.2156 },
    { lat: 48.274207, lng: 19.215609 },
    { lat: 48.273755, lng: 19.215897 },
    { lat: 48.273637, lng: 19.215973 },
    { lat: 48.273649, lng: 19.216176 },
    { lat: 48.273684, lng: 19.216291 },
    { lat: 48.27365, lng: 19.216449 },
    { lat: 48.273676, lng: 19.216747 },
    { lat: 48.273439, lng: 19.217246 },
    { lat: 48.273198, lng: 19.217675 },
    { lat: 48.273229, lng: 19.217761 },
    { lat: 48.273082, lng: 19.217968 },
    { lat: 48.272737, lng: 19.218465 },
    { lat: 48.272688, lng: 19.2185 },
    { lat: 48.272336, lng: 19.218781 },
    { lat: 48.272144, lng: 19.218937 },
    { lat: 48.271973, lng: 19.219074 },
    { lat: 48.271876, lng: 19.219341 },
    { lat: 48.271686, lng: 19.219445 },
    { lat: 48.271549, lng: 19.219612 },
    { lat: 48.271314, lng: 19.219669 },
    { lat: 48.271013, lng: 19.219921 },
    { lat: 48.270886, lng: 19.220029 },
    { lat: 48.270776, lng: 19.220195 },
    { lat: 48.270696, lng: 19.220357 },
    { lat: 48.270518, lng: 19.2204 },
    { lat: 48.270319, lng: 19.220713 },
    { lat: 48.270257, lng: 19.220808 },
    { lat: 48.270103, lng: 19.22096 },
    { lat: 48.269769, lng: 19.221291 },
    { lat: 48.269606, lng: 19.221441 },
    { lat: 48.269538, lng: 19.221503 },
    { lat: 48.269487, lng: 19.221645 },
    { lat: 48.269465, lng: 19.221692 },
    { lat: 48.269415, lng: 19.221731 },
    { lat: 48.269367, lng: 19.221786 },
    { lat: 48.269341, lng: 19.221812 },
    { lat: 48.269132, lng: 19.221732 },
    { lat: 48.269051, lng: 19.221762 },
    { lat: 48.268993, lng: 19.221845 },
    { lat: 48.268858, lng: 19.222011 },
    { lat: 48.268564, lng: 19.222383 },
    { lat: 48.268525, lng: 19.222436 },
    { lat: 48.268438, lng: 19.222661 },
    { lat: 48.268298, lng: 19.22288 },
    { lat: 48.268161, lng: 19.223019 },
    { lat: 48.267989, lng: 19.223366 },
    { lat: 48.267817, lng: 19.223737 },
    { lat: 48.267796, lng: 19.223783 },
    { lat: 48.267555, lng: 19.224083 },
    { lat: 48.267393, lng: 19.224503 },
    { lat: 48.267241, lng: 19.224889 },
    { lat: 48.267154, lng: 19.225168 },
    { lat: 48.267103, lng: 19.22534 },
    { lat: 48.266938, lng: 19.225559 },
    { lat: 48.266851, lng: 19.226007 },
    { lat: 48.266786, lng: 19.226165 },
    { lat: 48.266667, lng: 19.226404 },
    { lat: 48.266554, lng: 19.226625 },
    { lat: 48.266414, lng: 19.226898 },
    { lat: 48.266284, lng: 19.227152 },
    { lat: 48.266234, lng: 19.227335 },
    { lat: 48.266032, lng: 19.228146 },
    { lat: 48.265842, lng: 19.228641 },
    { lat: 48.265606, lng: 19.228986 },
    { lat: 48.265503, lng: 19.229285 },
    { lat: 48.265523, lng: 19.229693 },
    { lat: 48.265441, lng: 19.230249 },
    { lat: 48.265414, lng: 19.230414 },
    { lat: 48.265331, lng: 19.23102 },
    { lat: 48.265358, lng: 19.231182 },
    { lat: 48.265288, lng: 19.231632 },
    { lat: 48.265272, lng: 19.232369 },
    { lat: 48.2654238, lng: 19.2327429 },
    { lat: 48.2654869, lng: 19.2330926 },
    { lat: 48.2656389, lng: 19.2332921 },
    { lat: 48.2656544, lng: 19.2335649 },
    { lat: 48.2658366, lng: 19.2338329 },
    { lat: 48.2660764, lng: 19.2339644 },
    { lat: 48.2660343, lng: 19.2343019 },
    { lat: 48.2663083, lng: 19.2351366 },
    { lat: 48.2664907, lng: 19.2354523 },
    { lat: 48.2666128, lng: 19.2361529 },
    { lat: 48.266727, lng: 19.2373818 },
    { lat: 48.2670338, lng: 19.2380342 },
    { lat: 48.2673791, lng: 19.2383364 },
    { lat: 48.2674924, lng: 19.2385574 },
    { lat: 48.2677126, lng: 19.2390276 },
    { lat: 48.2678968, lng: 19.239611 },
    { lat: 48.268032, lng: 19.2397982 },
    { lat: 48.2682087, lng: 19.2402766 },
    { lat: 48.2683652, lng: 19.2404346 },
    { lat: 48.2685546, lng: 19.2405062 },
    { lat: 48.2686632, lng: 19.240673 },
    { lat: 48.2687983, lng: 19.2407607 },
    { lat: 48.2688369, lng: 19.2409076 },
    { lat: 48.2687589, lng: 19.2413174 },
    { lat: 48.2690748, lng: 19.2417841 },
    { lat: 48.2690463, lng: 19.242192 },
    { lat: 48.2692117, lng: 19.2423968 },
    { lat: 48.2695421, lng: 19.2424908 },
    { lat: 48.2697982, lng: 19.2441816 },
    { lat: 48.2698496, lng: 19.2443337 },
    { lat: 48.2699593, lng: 19.2444081 },
    { lat: 48.2699899, lng: 19.2445278 },
    { lat: 48.2699991, lng: 19.2450827 },
    { lat: 48.2701143, lng: 19.2451589 },
    { lat: 48.2704408, lng: 19.2451274 },
    { lat: 48.2705895, lng: 19.245265 },
    { lat: 48.270949, lng: 19.2453081 },
    { lat: 48.2710449, lng: 19.2454968 },
    { lat: 48.2713306, lng: 19.2456197 },
    { lat: 48.2716659, lng: 19.2459772 },
    { lat: 48.2718239, lng: 19.2460724 },
    { lat: 48.2718645, lng: 19.2460952 },
    { lat: 48.2719617, lng: 19.245851 },
    { lat: 48.2725277, lng: 19.245437 },
    { lat: 48.2727267, lng: 19.2453795 },
    { lat: 48.2729964, lng: 19.2451327 },
    { lat: 48.2733525, lng: 19.2449659 },
    { lat: 48.2736747, lng: 19.2446178 },
    { lat: 48.2744841, lng: 19.2446212 },
    { lat: 48.2749476, lng: 19.2443708 },
    { lat: 48.2751356, lng: 19.2444076 },
    { lat: 48.2754897, lng: 19.2446222 },
    { lat: 48.2756651, lng: 19.2446334 },
    { lat: 48.2761015, lng: 19.2445017 },
    { lat: 48.2763262, lng: 19.2443663 },
    { lat: 48.2766421, lng: 19.2443303 },
    { lat: 48.2768548, lng: 19.2439064 },
    { lat: 48.2770392, lng: 19.2438703 },
    { lat: 48.2777335, lng: 19.2444004 },
    { lat: 48.2780758, lng: 19.2444203 },
    { lat: 48.2785355, lng: 19.244343 },
    { lat: 48.2789444, lng: 19.2447314 },
    { lat: 48.2790798, lng: 19.2449395 },
    { lat: 48.2794133, lng: 19.245006 },
    { lat: 48.2797883, lng: 19.244491 },
    { lat: 48.2800692, lng: 19.2445413 },
    { lat: 48.280332, lng: 19.245376 },
    { lat: 48.2809369, lng: 19.2452312 },
    { lat: 48.2811704, lng: 19.2453242 },
    { lat: 48.2812203, lng: 19.2454227 },
    { lat: 48.2811955, lng: 19.2457482 },
    { lat: 48.2813323, lng: 19.2461267 },
    { lat: 48.281531, lng: 19.2463407 },
    { lat: 48.2820396, lng: 19.2463462 },
    { lat: 48.282325, lng: 19.246212 },
    { lat: 48.2824638, lng: 19.2462392 },
    { lat: 48.2827308, lng: 19.2468364 },
    { lat: 48.2832043, lng: 19.246907 },
    { lat: 48.2834889, lng: 19.2466387 },
    { lat: 48.2836067, lng: 19.2460284 },
    { lat: 48.2838041, lng: 19.245867 },
    { lat: 48.2841484, lng: 19.2459475 },
    { lat: 48.2842796, lng: 19.246326 },
    { lat: 48.2844238, lng: 19.2465321 },
    { lat: 48.2848716, lng: 19.2467191 },
    { lat: 48.2855938, lng: 19.2465763 },
    { lat: 48.2863585, lng: 19.2471399 },
    { lat: 48.286557, lng: 19.2472106 },
    { lat: 48.2868214, lng: 19.2468769 },
    { lat: 48.2871017, lng: 19.2468218 },
    { lat: 48.2874191, lng: 19.2470209 },
    { lat: 48.287689, lng: 19.2475421 },
    { lat: 48.2881026, lng: 19.2478855 },
    { lat: 48.288975, lng: 19.2482072 },
    { lat: 48.2890706, lng: 19.2480648 },
    { lat: 48.289286, lng: 19.2479714 },
    { lat: 48.2892376, lng: 19.2478136 },
    { lat: 48.2892531, lng: 19.2475786 },
    { lat: 48.2894743, lng: 19.2469598 },
    { lat: 48.2897878, lng: 19.246478 },
    { lat: 48.2906229, lng: 19.2453604 },
    { lat: 48.2919257, lng: 19.243833 },
    { lat: 48.2923552, lng: 19.243142 },
    { lat: 48.2937618, lng: 19.2419121 },
    { lat: 48.294467, lng: 19.2422245 },
    { lat: 48.2951043, lng: 19.2417015 },
    { lat: 48.2963776, lng: 19.2411368 },
    { lat: 48.2967553, lng: 19.2407934 },
    { lat: 48.2972209, lng: 19.2402184 },
    { lat: 48.2973344, lng: 19.2403515 },
    { lat: 48.2975333, lng: 19.2404291 },
    { lat: 48.2978145, lng: 19.2409941 },
    { lat: 48.2980409, lng: 19.2411314 },
    { lat: 48.298354, lng: 19.2411714 },
    { lat: 48.2989829, lng: 19.241653 },
    { lat: 48.2995059, lng: 19.2412384 },
    { lat: 48.2997715, lng: 19.2413661 },
    { lat: 48.3000191, lng: 19.2412894 },
    { lat: 48.3006976, lng: 19.2407356 },
    { lat: 48.3009422, lng: 19.2406649 },
    { lat: 48.3017106, lng: 19.2401714 },
    { lat: 48.3021195, lng: 19.2397017 },
    { lat: 48.3028142, lng: 19.2392477 },
    { lat: 48.3030206, lng: 19.2390197 },
    { lat: 48.3048684, lng: 19.2380076 },
    { lat: 48.3050476, lng: 19.2380287 },
    { lat: 48.3051397, lng: 19.2377909 },
    { lat: 48.3055108, lng: 19.237497 },
    { lat: 48.3055995, lng: 19.237539 },
    { lat: 48.3057109, lng: 19.237304 },
    { lat: 48.3060264, lng: 19.2373703 },
    { lat: 48.3062206, lng: 19.2369829 },
    { lat: 48.3063836, lng: 19.2370914 },
    { lat: 48.3064706, lng: 19.2369321 },
    { lat: 48.3067296, lng: 19.2367174 },
    { lat: 48.3074509, lng: 19.2359329 },
    { lat: 48.3079648, lng: 19.2356049 },
    { lat: 48.3086971, lng: 19.2349973 },
    { lat: 48.3089351, lng: 19.2346769 },
    { lat: 48.3093993, lng: 19.2342371 },
    { lat: 48.3105404, lng: 19.2332255 },
    { lat: 48.3109338, lng: 19.2329971 },
    { lat: 48.3114208, lng: 19.2323526 },
    { lat: 48.3125002, lng: 19.2315328 },
    { lat: 48.3125649, lng: 19.2317995 },
    { lat: 48.313192, lng: 19.2315284 },
    { lat: 48.3141027, lng: 19.2315012 },
    { lat: 48.3141481, lng: 19.2315788 },
    { lat: 48.3143836, lng: 19.2314378 },
    { lat: 48.3145424, lng: 19.2314342 },
    { lat: 48.3146003, lng: 19.2315131 },
    { lat: 48.3147431, lng: 19.2314337 },
    { lat: 48.3149468, lng: 19.2314503 },
    { lat: 48.3150852, lng: 19.2315158 },
    { lat: 48.3151202, lng: 19.2316097 },
    { lat: 48.3152255, lng: 19.2316187 },
    { lat: 48.3161533, lng: 19.2313407 },
    { lat: 48.3168503, lng: 19.2312139 },
    { lat: 48.3169892, lng: 19.2312386 },
    { lat: 48.3170959, lng: 19.2311847 },
    { lat: 48.3171247, lng: 19.2313147 },
    { lat: 48.3174438, lng: 19.231314 },
    { lat: 48.3179107, lng: 19.2311827 },
    { lat: 48.3186587, lng: 19.2304786 },
    { lat: 48.319581, lng: 19.2297478 },
    { lat: 48.3199685, lng: 19.2298554 },
    { lat: 48.3201057, lng: 19.2298258 },
    { lat: 48.3201248, lng: 19.2298942 },
    { lat: 48.3209609, lng: 19.2296599 },
    { lat: 48.3216669, lng: 19.2295957 },
    { lat: 48.3231073, lng: 19.2291555 },
    { lat: 48.3248803, lng: 19.2284108 },
    { lat: 48.3250418, lng: 19.2282786 },
    { lat: 48.3254728, lng: 19.2281289 },
    { lat: 48.3268785, lng: 19.2274376 },
    { lat: 48.3278138, lng: 19.2271541 },
    { lat: 48.3283568, lng: 19.2269162 },
    { lat: 48.3307448, lng: 19.2255915 },
    { lat: 48.3332611, lng: 19.224397 },
    { lat: 48.3335016, lng: 19.2242423 },
    { lat: 48.3341338, lng: 19.2235265 },
    { lat: 48.3350252, lng: 19.2233356 },
    { lat: 48.335926, lng: 19.222373 },
    { lat: 48.3363852, lng: 19.2219863 },
    { lat: 48.3367938, lng: 19.2217918 },
    { lat: 48.337584, lng: 19.2210319 },
    { lat: 48.3381234, lng: 19.2206526 },
    { lat: 48.3387424, lng: 19.2198007 },
    { lat: 48.3396954, lng: 19.2183422 },
    { lat: 48.3405554, lng: 19.2173709 },
    { lat: 48.340803, lng: 19.2168801 },
    { lat: 48.3408286, lng: 19.2163423 },
    { lat: 48.3417985, lng: 19.2163509 },
    { lat: 48.342545, lng: 19.215811 },
  ],
  Želovce: [
    { lat: 48.1487584, lng: 19.3772264 },
    { lat: 48.1486903, lng: 19.3772654 },
    { lat: 48.1486267, lng: 19.3769972 },
    { lat: 48.1483958, lng: 19.377067 },
    { lat: 48.1483618, lng: 19.3771557 },
    { lat: 48.1481369, lng: 19.377102 },
    { lat: 48.1477932, lng: 19.3765797 },
    { lat: 48.1476774, lng: 19.3765895 },
    { lat: 48.1475005, lng: 19.3767768 },
    { lat: 48.1469041, lng: 19.3766575 },
    { lat: 48.1463773, lng: 19.3763386 },
    { lat: 48.1450836, lng: 19.3758022 },
    { lat: 48.1428627, lng: 19.3721826 },
    { lat: 48.1427416, lng: 19.3715396 },
    { lat: 48.1422585, lng: 19.3689758 },
    { lat: 48.1420151, lng: 19.3683613 },
    { lat: 48.1416528, lng: 19.3676466 },
    { lat: 48.141183, lng: 19.3662634 },
    { lat: 48.1412863, lng: 19.3662495 },
    { lat: 48.1413773, lng: 19.3657969 },
    { lat: 48.1413965, lng: 19.3654484 },
    { lat: 48.1409808, lng: 19.3645315 },
    { lat: 48.1390811, lng: 19.361226 },
    { lat: 48.1390213, lng: 19.3602143 },
    { lat: 48.1383166, lng: 19.3595759 },
    { lat: 48.1381041, lng: 19.3588819 },
    { lat: 48.138111, lng: 19.3581049 },
    { lat: 48.138219, lng: 19.3580997 },
    { lat: 48.1384546, lng: 19.3551869 },
    { lat: 48.1386081, lng: 19.3546493 },
    { lat: 48.1390585, lng: 19.3539846 },
    { lat: 48.1392999, lng: 19.3530505 },
    { lat: 48.139321, lng: 19.3523859 },
    { lat: 48.1393902, lng: 19.3519924 },
    { lat: 48.1394798, lng: 19.3517937 },
    { lat: 48.1394823, lng: 19.3516138 },
    { lat: 48.1402261, lng: 19.3505069 },
    { lat: 48.1404884, lng: 19.3493975 },
    { lat: 48.1406207, lng: 19.3484196 },
    { lat: 48.140524, lng: 19.3479073 },
    { lat: 48.1403746, lng: 19.3474265 },
    { lat: 48.1403824, lng: 19.3465075 },
    { lat: 48.1402754, lng: 19.3462765 },
    { lat: 48.1402943, lng: 19.3459858 },
    { lat: 48.1404073, lng: 19.3454765 },
    { lat: 48.1403201, lng: 19.3451862 },
    { lat: 48.1405942, lng: 19.3447016 },
    { lat: 48.1408458, lng: 19.3439078 },
    { lat: 48.1409883, lng: 19.3437309 },
    { lat: 48.1409899, lng: 19.3434805 },
    { lat: 48.1411482, lng: 19.3428823 },
    { lat: 48.1413218, lng: 19.342508 },
    { lat: 48.141383, lng: 19.3422339 },
    { lat: 48.1416971, lng: 19.3419179 },
    { lat: 48.1416558, lng: 19.3417424 },
    { lat: 48.1417336, lng: 19.3414598 },
    { lat: 48.1418767, lng: 19.3412475 },
    { lat: 48.1419094, lng: 19.3410132 },
    { lat: 48.1418685, lng: 19.3405624 },
    { lat: 48.1420757, lng: 19.3391532 },
    { lat: 48.1421684, lng: 19.3389012 },
    { lat: 48.1421238, lng: 19.3384451 },
    { lat: 48.1421849, lng: 19.3379766 },
    { lat: 48.1421701, lng: 19.3372957 },
    { lat: 48.1422925, lng: 19.3370507 },
    { lat: 48.1421532, lng: 19.3365462 },
    { lat: 48.1422231, lng: 19.3360075 },
    { lat: 48.1423222, lng: 19.3357932 },
    { lat: 48.1422447, lng: 19.3355676 },
    { lat: 48.1422275, lng: 19.3348121 },
    { lat: 48.1425589, lng: 19.3327536 },
    { lat: 48.142565, lng: 19.3323884 },
    { lat: 48.1427165, lng: 19.3318572 },
    { lat: 48.1427416, lng: 19.3314469 },
    { lat: 48.1429385, lng: 19.3310379 },
    { lat: 48.1430088, lng: 19.3304865 },
    { lat: 48.1431704, lng: 19.3299334 },
    { lat: 48.143374, lng: 19.3295269 },
    { lat: 48.1439316, lng: 19.3276984 },
    { lat: 48.1442266, lng: 19.3270349 },
    { lat: 48.1442232, lng: 19.3267551 },
    { lat: 48.144324, lng: 19.3259445 },
    { lat: 48.1444848, lng: 19.3255619 },
    { lat: 48.1446695, lng: 19.3253028 },
    { lat: 48.1448629, lng: 19.3243799 },
    { lat: 48.1450721, lng: 19.3237175 },
    { lat: 48.1451245, lng: 19.3232386 },
    { lat: 48.1453932, lng: 19.3228059 },
    { lat: 48.1455271, lng: 19.3224029 },
    { lat: 48.1454553, lng: 19.322315 },
    { lat: 48.1455529, lng: 19.3218848 },
    { lat: 48.144643, lng: 19.3211097 },
    { lat: 48.144921, lng: 19.3203753 },
    { lat: 48.1450007, lng: 19.318102 },
    { lat: 48.1442755, lng: 19.3163704 },
    { lat: 48.1442291, lng: 19.3159219 },
    { lat: 48.1438201, lng: 19.3143842 },
    { lat: 48.1435083, lng: 19.313832 },
    { lat: 48.1429525, lng: 19.3136198 },
    { lat: 48.1426994, lng: 19.3135144 },
    { lat: 48.1420566, lng: 19.3140902 },
    { lat: 48.1419285, lng: 19.3141474 },
    { lat: 48.1410786, lng: 19.314448 },
    { lat: 48.1406095, lng: 19.3143987 },
    { lat: 48.1401239, lng: 19.3144973 },
    { lat: 48.1397068, lng: 19.3147276 },
    { lat: 48.139278, lng: 19.3144862 },
    { lat: 48.1378676, lng: 19.3150611 },
    { lat: 48.136115, lng: 19.3161598 },
    { lat: 48.1355397, lng: 19.316195 },
    { lat: 48.1351171, lng: 19.3160328 },
    { lat: 48.1347428, lng: 19.31606 },
    { lat: 48.134157, lng: 19.3164591 },
    { lat: 48.1338035, lng: 19.3168427 },
    { lat: 48.1331259, lng: 19.3169309 },
    { lat: 48.1342759, lng: 19.3178934 },
    { lat: 48.1343191, lng: 19.3186032 },
    { lat: 48.1342337, lng: 19.3191481 },
    { lat: 48.1328538, lng: 19.3211037 },
    { lat: 48.1321294, lng: 19.3223464 },
    { lat: 48.1317688, lng: 19.3239398 },
    { lat: 48.1316755, lng: 19.323989 },
    { lat: 48.1315155, lng: 19.3242702 },
    { lat: 48.1312911, lng: 19.3244806 },
    { lat: 48.1309937, lng: 19.3246217 },
    { lat: 48.1309489, lng: 19.3247204 },
    { lat: 48.1309516, lng: 19.3251301 },
    { lat: 48.1306543, lng: 19.3259041 },
    { lat: 48.1305835, lng: 19.3262589 },
    { lat: 48.1302858, lng: 19.3270054 },
    { lat: 48.1300231, lng: 19.3275184 },
    { lat: 48.1296833, lng: 19.3279953 },
    { lat: 48.1294657, lng: 19.3281122 },
    { lat: 48.1293415, lng: 19.3282938 },
    { lat: 48.1292448, lng: 19.3286313 },
    { lat: 48.1289209, lng: 19.3290648 },
    { lat: 48.1287777, lng: 19.3291564 },
    { lat: 48.1287654, lng: 19.3294156 },
    { lat: 48.1286857, lng: 19.3295604 },
    { lat: 48.1284556, lng: 19.3296769 },
    { lat: 48.1283032, lng: 19.3301861 },
    { lat: 48.1281377, lng: 19.3301916 },
    { lat: 48.1279373, lng: 19.3303359 },
    { lat: 48.1275464, lng: 19.3308327 },
    { lat: 48.1274933, lng: 19.3311069 },
    { lat: 48.1274308, lng: 19.331144 },
    { lat: 48.1274003, lng: 19.3313776 },
    { lat: 48.1270114, lng: 19.3321751 },
    { lat: 48.1267332, lng: 19.332621 },
    { lat: 48.1263729, lng: 19.3334732 },
    { lat: 48.1261091, lng: 19.3345226 },
    { lat: 48.1259729, lng: 19.3348522 },
    { lat: 48.1259335, lng: 19.3360009 },
    { lat: 48.1257327, lng: 19.3371479 },
    { lat: 48.125525, lng: 19.3379118 },
    { lat: 48.1254647, lng: 19.3379507 },
    { lat: 48.1254549, lng: 19.3380519 },
    { lat: 48.1252161, lng: 19.3384476 },
    { lat: 48.1251386, lng: 19.3386888 },
    { lat: 48.124945, lng: 19.3388369 },
    { lat: 48.1249331, lng: 19.3390114 },
    { lat: 48.1248448, lng: 19.3391648 },
    { lat: 48.1245141, lng: 19.3394642 },
    { lat: 48.1245114, lng: 19.3396864 },
    { lat: 48.1242648, lng: 19.3399524 },
    { lat: 48.1241913, lng: 19.3402496 },
    { lat: 48.1240236, lng: 19.3405103 },
    { lat: 48.1235812, lng: 19.3406869 },
    { lat: 48.1233443, lng: 19.3412224 },
    { lat: 48.1228678, lng: 19.3413666 },
    { lat: 48.1225163, lng: 19.3416312 },
    { lat: 48.1220914, lng: 19.3421481 },
    { lat: 48.1219281, lng: 19.3422672 },
    { lat: 48.1215972, lng: 19.3427454 },
    { lat: 48.121457, lng: 19.3435329 },
    { lat: 48.1211535, lng: 19.3443486 },
    { lat: 48.1208256, lng: 19.3447012 },
    { lat: 48.1206247, lng: 19.3452142 },
    { lat: 48.1202542, lng: 19.3458282 },
    { lat: 48.1199397, lng: 19.3464841 },
    { lat: 48.1199753, lng: 19.3465223 },
    { lat: 48.1200155, lng: 19.3469127 },
    { lat: 48.1192153, lng: 19.3488158 },
    { lat: 48.1186745, lng: 19.3502959 },
    { lat: 48.1169119, lng: 19.3521573 },
    { lat: 48.1162784, lng: 19.3527172 },
    { lat: 48.1155546, lng: 19.3530641 },
    { lat: 48.1155314, lng: 19.353359 },
    { lat: 48.1158724, lng: 19.3540262 },
    { lat: 48.1121903, lng: 19.3583305 },
    { lat: 48.1118704, lng: 19.3580085 },
    { lat: 48.1116033, lng: 19.3585248 },
    { lat: 48.1112196, lng: 19.3579712 },
    { lat: 48.1112165, lng: 19.3578382 },
    { lat: 48.1113381, lng: 19.3574821 },
    { lat: 48.1113253, lng: 19.3573582 },
    { lat: 48.1112826, lng: 19.3572901 },
    { lat: 48.1110707, lng: 19.3574532 },
    { lat: 48.1109531, lng: 19.3574665 },
    { lat: 48.1109137, lng: 19.3569321 },
    { lat: 48.1107525, lng: 19.3568601 },
    { lat: 48.1105546, lng: 19.3571021 },
    { lat: 48.1105354, lng: 19.3572177 },
    { lat: 48.1106732, lng: 19.3579305 },
    { lat: 48.1105753, lng: 19.3580309 },
    { lat: 48.1104568, lng: 19.3579329 },
    { lat: 48.1100244, lng: 19.3578959 },
    { lat: 48.1099744, lng: 19.3582126 },
    { lat: 48.1100473, lng: 19.3587479 },
    { lat: 48.1095624, lng: 19.358998 },
    { lat: 48.1094526, lng: 19.3588958 },
    { lat: 48.1093976, lng: 19.3587384 },
    { lat: 48.1094067, lng: 19.3585735 },
    { lat: 48.1092681, lng: 19.3585982 },
    { lat: 48.1091948, lng: 19.3589706 },
    { lat: 48.1091447, lng: 19.3590109 },
    { lat: 48.1089482, lng: 19.3589078 },
    { lat: 48.1088728, lng: 19.3588164 },
    { lat: 48.1088426, lng: 19.3585615 },
    { lat: 48.1086967, lng: 19.3585785 },
    { lat: 48.1085412, lng: 19.3584819 },
    { lat: 48.1085343, lng: 19.3581511 },
    { lat: 48.1081064, lng: 19.358114 },
    { lat: 48.1079944, lng: 19.3582212 },
    { lat: 48.1078573, lng: 19.3582539 },
    { lat: 48.1078109, lng: 19.3581336 },
    { lat: 48.1080158, lng: 19.3577263 },
    { lat: 48.1079669, lng: 19.3576483 },
    { lat: 48.1076264, lng: 19.3579992 },
    { lat: 48.1074066, lng: 19.3579522 },
    { lat: 48.1072959, lng: 19.3575684 },
    { lat: 48.1071641, lng: 19.3574058 },
    { lat: 48.1073041, lng: 19.3572723 },
    { lat: 48.1073713, lng: 19.3571463 },
    { lat: 48.107354, lng: 19.357067 },
    { lat: 48.1069381, lng: 19.3570872 },
    { lat: 48.1069275, lng: 19.3567977 },
    { lat: 48.1072856, lng: 19.3565806 },
    { lat: 48.1073085, lng: 19.3565172 },
    { lat: 48.1072536, lng: 19.356358 },
    { lat: 48.1069283, lng: 19.3564651 },
    { lat: 48.1067714, lng: 19.3564078 },
    { lat: 48.1067802, lng: 19.3561935 },
    { lat: 48.1069906, lng: 19.3561883 },
    { lat: 48.1070122, lng: 19.3560707 },
    { lat: 48.106882, lng: 19.3556861 },
    { lat: 48.1071342, lng: 19.3556306 },
    { lat: 48.1069816, lng: 19.3551296 },
    { lat: 48.106997, lng: 19.3549325 },
    { lat: 48.1072969, lng: 19.3549601 },
    { lat: 48.1071911, lng: 19.3546307 },
    { lat: 48.1072175, lng: 19.3545034 },
    { lat: 48.1072885, lng: 19.3545945 },
    { lat: 48.1074417, lng: 19.3545926 },
    { lat: 48.1074306, lng: 19.3542912 },
    { lat: 48.1072176, lng: 19.354131 },
    { lat: 48.1071357, lng: 19.3539948 },
    { lat: 48.1073312, lng: 19.3536092 },
    { lat: 48.1073198, lng: 19.35351 },
    { lat: 48.107145, lng: 19.353346 },
    { lat: 48.1069606, lng: 19.3532829 },
    { lat: 48.1069125, lng: 19.353148 },
    { lat: 48.1069355, lng: 19.3528786 },
    { lat: 48.1066514, lng: 19.3528748 },
    { lat: 48.1066945, lng: 19.3525277 },
    { lat: 48.107038, lng: 19.3520075 },
    { lat: 48.1068487, lng: 19.3518193 },
    { lat: 48.1064429, lng: 19.3519858 },
    { lat: 48.1063729, lng: 19.3517715 },
    { lat: 48.1060739, lng: 19.3516505 },
    { lat: 48.1056686, lng: 19.3517277 },
    { lat: 48.1056318, lng: 19.3520628 },
    { lat: 48.1050703, lng: 19.3518951 },
    { lat: 48.104559, lng: 19.3518313 },
    { lat: 48.1031408, lng: 19.3527198 },
    { lat: 48.102741, lng: 19.3526067 },
    { lat: 48.1025416, lng: 19.3526594 },
    { lat: 48.1023846, lng: 19.3528388 },
    { lat: 48.1023533, lng: 19.3533626 },
    { lat: 48.1022038, lng: 19.3537228 },
    { lat: 48.1019107, lng: 19.3541642 },
    { lat: 48.1011874, lng: 19.3550882 },
    { lat: 48.1013511, lng: 19.355703 },
    { lat: 48.1038934, lng: 19.3623231 },
    { lat: 48.1063577, lng: 19.3685435 },
    { lat: 48.107664, lng: 19.3720336 },
    { lat: 48.1080334, lng: 19.3717417 },
    { lat: 48.1084314, lng: 19.3716043 },
    { lat: 48.1088203, lng: 19.37137 },
    { lat: 48.1090052, lng: 19.3713936 },
    { lat: 48.1092119, lng: 19.371306 },
    { lat: 48.1095489, lng: 19.3712848 },
    { lat: 48.1096779, lng: 19.3713409 },
    { lat: 48.1099181, lng: 19.3713206 },
    { lat: 48.1103596, lng: 19.3710806 },
    { lat: 48.1105677, lng: 19.3712086 },
    { lat: 48.1107809, lng: 19.371237 },
    { lat: 48.1108938, lng: 19.3715247 },
    { lat: 48.111098, lng: 19.3715326 },
    { lat: 48.1115276, lng: 19.3717431 },
    { lat: 48.1118767, lng: 19.372188 },
    { lat: 48.1121861, lng: 19.3722915 },
    { lat: 48.1124612, lng: 19.372721 },
    { lat: 48.1124504, lng: 19.3732949 },
    { lat: 48.112647, lng: 19.3736816 },
    { lat: 48.1127498, lng: 19.3737814 },
    { lat: 48.1127587, lng: 19.3739441 },
    { lat: 48.1130401, lng: 19.3744784 },
    { lat: 48.1132172, lng: 19.3751155 },
    { lat: 48.1135083, lng: 19.3756664 },
    { lat: 48.1140587, lng: 19.3772559 },
    { lat: 48.1140828, lng: 19.3775908 },
    { lat: 48.1140427, lng: 19.377982 },
    { lat: 48.1139659, lng: 19.3782385 },
    { lat: 48.1138388, lng: 19.3799389 },
    { lat: 48.1136416, lng: 19.3802953 },
    { lat: 48.1135658, lng: 19.3811411 },
    { lat: 48.1144281, lng: 19.3855792 },
    { lat: 48.1145795, lng: 19.3866984 },
    { lat: 48.1152968, lng: 19.3879369 },
    { lat: 48.1152332, lng: 19.3882082 },
    { lat: 48.1148633, lng: 19.3897864 },
    { lat: 48.1152356, lng: 19.3898438 },
    { lat: 48.1161323, lng: 19.3895999 },
    { lat: 48.1171822, lng: 19.3896107 },
    { lat: 48.1188159, lng: 19.3891502 },
    { lat: 48.1193389, lng: 19.3895292 },
    { lat: 48.1199175, lng: 19.3904595 },
    { lat: 48.1212396, lng: 19.3935571 },
    { lat: 48.1218342, lng: 19.3952262 },
    { lat: 48.1220975, lng: 19.3964 },
    { lat: 48.1225417, lng: 19.3973285 },
    { lat: 48.122641, lng: 19.3978513 },
    { lat: 48.1226676, lng: 19.3988362 },
    { lat: 48.1226087, lng: 19.3996823 },
    { lat: 48.122302, lng: 19.4021223 },
    { lat: 48.1219516, lng: 19.4034255 },
    { lat: 48.1216801, lng: 19.4038109 },
    { lat: 48.120928, lng: 19.4053185 },
    { lat: 48.1206237, lng: 19.4079496 },
    { lat: 48.1203774, lng: 19.4119051 },
    { lat: 48.1204057, lng: 19.412476 },
    { lat: 48.1207921, lng: 19.4133528 },
    { lat: 48.12211, lng: 19.4181569 },
    { lat: 48.1224643, lng: 19.4195928 },
    { lat: 48.1226031, lng: 19.4200233 },
    { lat: 48.1229598, lng: 19.4197263 },
    { lat: 48.1230721, lng: 19.4193613 },
    { lat: 48.123159, lng: 19.4193401 },
    { lat: 48.1232961, lng: 19.4190699 },
    { lat: 48.12362, lng: 19.4186974 },
    { lat: 48.1241137, lng: 19.4176299 },
    { lat: 48.1246183, lng: 19.416815 },
    { lat: 48.1246905, lng: 19.4165805 },
    { lat: 48.1248315, lng: 19.4164594 },
    { lat: 48.124922, lng: 19.4161817 },
    { lat: 48.1250257, lng: 19.4161119 },
    { lat: 48.1251384, lng: 19.4161327 },
    { lat: 48.1253038, lng: 19.4157716 },
    { lat: 48.1255526, lng: 19.4154599 },
    { lat: 48.1257014, lng: 19.4154364 },
    { lat: 48.1258616, lng: 19.4152985 },
    { lat: 48.1258895, lng: 19.4151502 },
    { lat: 48.1260248, lng: 19.4150046 },
    { lat: 48.1261227, lng: 19.4145738 },
    { lat: 48.1263096, lng: 19.4143127 },
    { lat: 48.1266518, lng: 19.4136157 },
    { lat: 48.1267766, lng: 19.4135122 },
    { lat: 48.1269759, lng: 19.4129835 },
    { lat: 48.1272686, lng: 19.4131458 },
    { lat: 48.1278144, lng: 19.412011 },
    { lat: 48.1280742, lng: 19.4118572 },
    { lat: 48.128796, lng: 19.4116033 },
    { lat: 48.1289882, lng: 19.4114729 },
    { lat: 48.1291278, lng: 19.4110912 },
    { lat: 48.129418, lng: 19.4107704 },
    { lat: 48.1294331, lng: 19.4106358 },
    { lat: 48.129504, lng: 19.4105172 },
    { lat: 48.1297992, lng: 19.4103233 },
    { lat: 48.1310604, lng: 19.4099014 },
    { lat: 48.131398, lng: 19.4096056 },
    { lat: 48.1317412, lng: 19.4094962 },
    { lat: 48.1319711, lng: 19.409329 },
    { lat: 48.1320659, lng: 19.4091672 },
    { lat: 48.1331044, lng: 19.4084415 },
    { lat: 48.1333601, lng: 19.4085111 },
    { lat: 48.133422, lng: 19.4084601 },
    { lat: 48.1335181, lng: 19.4085141 },
    { lat: 48.1336643, lng: 19.4084859 },
    { lat: 48.1337671, lng: 19.4083219 },
    { lat: 48.1341663, lng: 19.4082291 },
    { lat: 48.1344405, lng: 19.4080501 },
    { lat: 48.1345711, lng: 19.4078327 },
    { lat: 48.1352057, lng: 19.4074782 },
    { lat: 48.1353719, lng: 19.4071092 },
    { lat: 48.1357897, lng: 19.4065815 },
    { lat: 48.1361223, lng: 19.4059171 },
    { lat: 48.1363698, lng: 19.4051023 },
    { lat: 48.1365343, lng: 19.403798 },
    { lat: 48.1367115, lng: 19.4034966 },
    { lat: 48.1370084, lng: 19.4031483 },
    { lat: 48.1373575, lng: 19.4029757 },
    { lat: 48.1377756, lng: 19.4023107 },
    { lat: 48.1383197, lng: 19.4012184 },
    { lat: 48.1400092, lng: 19.3987017 },
    { lat: 48.1416305, lng: 19.3969384 },
    { lat: 48.1424458, lng: 19.3952426 },
    { lat: 48.1431975, lng: 19.3932522 },
    { lat: 48.1452566, lng: 19.3917367 },
    { lat: 48.1456172, lng: 19.3912393 },
    { lat: 48.1463432, lng: 19.3905794 },
    { lat: 48.1467033, lng: 19.3897661 },
    { lat: 48.1469433, lng: 19.3889832 },
    { lat: 48.1472616, lng: 19.3881892 },
    { lat: 48.14758, lng: 19.3876308 },
    { lat: 48.1479323, lng: 19.3871483 },
    { lat: 48.1478286, lng: 19.3869148 },
    { lat: 48.1479336, lng: 19.3865161 },
    { lat: 48.1481633, lng: 19.3861574 },
    { lat: 48.1480853, lng: 19.3859624 },
    { lat: 48.1481189, lng: 19.385841 },
    { lat: 48.148023, lng: 19.3854093 },
    { lat: 48.1480652, lng: 19.3851599 },
    { lat: 48.1481608, lng: 19.3850149 },
    { lat: 48.1481727, lng: 19.3849085 },
    { lat: 48.1480772, lng: 19.3843515 },
    { lat: 48.148197, lng: 19.3837645 },
    { lat: 48.148288, lng: 19.383548 },
    { lat: 48.148228, lng: 19.3833539 },
    { lat: 48.148334, lng: 19.3823379 },
    { lat: 48.148442, lng: 19.3821668 },
    { lat: 48.148501, lng: 19.3817741 },
    { lat: 48.1486241, lng: 19.3816371 },
    { lat: 48.148655, lng: 19.3814132 },
    { lat: 48.1485794, lng: 19.3812939 },
    { lat: 48.1485131, lng: 19.3809191 },
    { lat: 48.1484138, lng: 19.3808238 },
    { lat: 48.148402, lng: 19.3807393 },
    { lat: 48.1484457, lng: 19.3797644 },
    { lat: 48.1486945, lng: 19.3793807 },
    { lat: 48.1490226, lng: 19.3785601 },
    { lat: 48.1490016, lng: 19.3783086 },
    { lat: 48.1487258, lng: 19.3776279 },
    { lat: 48.1487538, lng: 19.3772738 },
    { lat: 48.1487584, lng: 19.3772264 },
  ],
  Červeňany: [
    { lat: 48.3638841, lng: 19.3834216 },
    { lat: 48.3643347, lng: 19.3842708 },
    { lat: 48.3649319, lng: 19.3849746 },
    { lat: 48.3663898, lng: 19.3872679 },
    { lat: 48.3671874, lng: 19.3891845 },
    { lat: 48.3677325, lng: 19.3900056 },
    { lat: 48.367939, lng: 19.3904405 },
    { lat: 48.368814, lng: 19.3914454 },
    { lat: 48.3689305, lng: 19.3918615 },
    { lat: 48.369413, lng: 19.3925037 },
    { lat: 48.369498, lng: 19.3927764 },
    { lat: 48.369682, lng: 19.3931165 },
    { lat: 48.3698593, lng: 19.3938366 },
    { lat: 48.3700645, lng: 19.393836 },
    { lat: 48.3702997, lng: 19.3935673 },
    { lat: 48.3707319, lng: 19.3934127 },
    { lat: 48.3714118, lng: 19.3929927 },
    { lat: 48.3723273, lng: 19.3938506 },
    { lat: 48.3734637, lng: 19.3963319 },
    { lat: 48.3741515, lng: 19.3976495 },
    { lat: 48.3742308, lng: 19.3979916 },
    { lat: 48.3743913, lng: 19.3982595 },
    { lat: 48.3751813, lng: 19.3991852 },
    { lat: 48.3753983, lng: 19.3993208 },
    { lat: 48.3758105, lng: 19.4006229 },
    { lat: 48.3758669, lng: 19.4010519 },
    { lat: 48.3763803, lng: 19.4023038 },
    { lat: 48.3763426, lng: 19.4024596 },
    { lat: 48.3765111, lng: 19.4030166 },
    { lat: 48.37709, lng: 19.4046792 },
    { lat: 48.3772624, lng: 19.4053684 },
    { lat: 48.378276, lng: 19.408401 },
    { lat: 48.378634, lng: 19.408082 },
    { lat: 48.378765, lng: 19.407887 },
    { lat: 48.378923, lng: 19.407699 },
    { lat: 48.379227, lng: 19.407272 },
    { lat: 48.379423, lng: 19.40704 },
    { lat: 48.379785, lng: 19.406734 },
    { lat: 48.379873, lng: 19.40671 },
    { lat: 48.380011, lng: 19.406702 },
    { lat: 48.380133, lng: 19.406737 },
    { lat: 48.380219, lng: 19.40679 },
    { lat: 48.380293, lng: 19.406939 },
    { lat: 48.381871, lng: 19.406186 },
    { lat: 48.382291, lng: 19.406516 },
    { lat: 48.382555, lng: 19.406723 },
    { lat: 48.382665, lng: 19.406471 },
    { lat: 48.382773, lng: 19.406315 },
    { lat: 48.382852, lng: 19.406152 },
    { lat: 48.383024, lng: 19.405395 },
    { lat: 48.382964, lng: 19.40508 },
    { lat: 48.382833, lng: 19.404747 },
    { lat: 48.382793, lng: 19.404566 },
    { lat: 48.382915, lng: 19.404424 },
    { lat: 48.38328, lng: 19.403999 },
    { lat: 48.383558, lng: 19.40446 },
    { lat: 48.38366, lng: 19.404615 },
    { lat: 48.383776, lng: 19.404727 },
    { lat: 48.383909, lng: 19.404826 },
    { lat: 48.384132, lng: 19.404946 },
    { lat: 48.384225, lng: 19.404972 },
    { lat: 48.384503, lng: 19.404966 },
    { lat: 48.384772, lng: 19.404653 },
    { lat: 48.384886, lng: 19.404526 },
    { lat: 48.385066, lng: 19.404364 },
    { lat: 48.385088, lng: 19.404139 },
    { lat: 48.385124, lng: 19.404088 },
    { lat: 48.385153, lng: 19.404007 },
    { lat: 48.385302, lng: 19.403498 },
    { lat: 48.385456, lng: 19.402773 },
    { lat: 48.385503, lng: 19.402572 },
    { lat: 48.385724, lng: 19.40208 },
    { lat: 48.385751, lng: 19.401964 },
    { lat: 48.385791, lng: 19.401656 },
    { lat: 48.385778, lng: 19.401455 },
    { lat: 48.386111, lng: 19.401351 },
    { lat: 48.386422, lng: 19.401301 },
    { lat: 48.386562, lng: 19.4013 },
    { lat: 48.387116, lng: 19.401137 },
    { lat: 48.387292, lng: 19.400694 },
    { lat: 48.387292, lng: 19.400565 },
    { lat: 48.387472, lng: 19.400396 },
    { lat: 48.387385, lng: 19.400034 },
    { lat: 48.387496, lng: 19.399919 },
    { lat: 48.388007, lng: 19.399552 },
    { lat: 48.388369, lng: 19.399167 },
    { lat: 48.38857, lng: 19.399341 },
    { lat: 48.38871, lng: 19.399415 },
    { lat: 48.388986, lng: 19.399474 },
    { lat: 48.389272, lng: 19.399479 },
    { lat: 48.389401, lng: 19.399466 },
    { lat: 48.389525, lng: 19.399372 },
    { lat: 48.389622, lng: 19.399192 },
    { lat: 48.389712, lng: 19.399106 },
    { lat: 48.389805, lng: 19.399265 },
    { lat: 48.389858, lng: 19.399143 },
    { lat: 48.389898, lng: 19.399016 },
    { lat: 48.389973, lng: 19.398621 },
    { lat: 48.39, lng: 19.398421 },
    { lat: 48.39, lng: 19.398267 },
    { lat: 48.390154, lng: 19.398152 },
    { lat: 48.390205, lng: 19.397467 },
    { lat: 48.390212, lng: 19.397041 },
    { lat: 48.390266, lng: 19.396905 },
    { lat: 48.39033, lng: 19.396657 },
    { lat: 48.390409, lng: 19.396406 },
    { lat: 48.390772, lng: 19.39586 },
    { lat: 48.390758, lng: 19.396036 },
    { lat: 48.390726, lng: 19.396129 },
    { lat: 48.390622, lng: 19.396667 },
    { lat: 48.390548, lng: 19.398148 },
    { lat: 48.390839, lng: 19.39837 },
    { lat: 48.390919, lng: 19.398298 },
    { lat: 48.391123, lng: 19.397877 },
    { lat: 48.391146, lng: 19.397679 },
    { lat: 48.391219, lng: 19.397472 },
    { lat: 48.391221, lng: 19.397268 },
    { lat: 48.391208, lng: 19.397093 },
    { lat: 48.391353, lng: 19.396692 },
    { lat: 48.391442, lng: 19.396451 },
    { lat: 48.391446, lng: 19.396443 },
    { lat: 48.391537, lng: 19.396251 },
    { lat: 48.391733, lng: 19.396429 },
    { lat: 48.391851, lng: 19.396675 },
    { lat: 48.391945, lng: 19.396902 },
    { lat: 48.392173, lng: 19.397586 },
    { lat: 48.392264, lng: 19.3975 },
    { lat: 48.392579, lng: 19.397023 },
    { lat: 48.392828, lng: 19.3968 },
    { lat: 48.393307, lng: 19.396333 },
    { lat: 48.393714, lng: 19.395999 },
    { lat: 48.393777, lng: 19.395747 },
    { lat: 48.394116, lng: 19.396441 },
    { lat: 48.395079, lng: 19.394715 },
    { lat: 48.395223, lng: 19.394732 },
    { lat: 48.395472, lng: 19.394736 },
    { lat: 48.395641, lng: 19.394803 },
    { lat: 48.395685, lng: 19.394815 },
    { lat: 48.39604, lng: 19.394883 },
    { lat: 48.396408, lng: 19.394853 },
    { lat: 48.39655, lng: 19.394799 },
    { lat: 48.397335, lng: 19.394215 },
    { lat: 48.397596, lng: 19.39447 },
    { lat: 48.398071, lng: 19.394366 },
    { lat: 48.398324, lng: 19.394336 },
    { lat: 48.398145, lng: 19.393902 },
    { lat: 48.39804, lng: 19.393646 },
    { lat: 48.39778, lng: 19.393827 },
    { lat: 48.397648, lng: 19.393622 },
    { lat: 48.397529, lng: 19.393185 },
    { lat: 48.397204, lng: 19.393384 },
    { lat: 48.396427, lng: 19.392911 },
    { lat: 48.395649, lng: 19.391845 },
    { lat: 48.3949, lng: 19.39166 },
    { lat: 48.394417, lng: 19.391323 },
    { lat: 48.394092, lng: 19.391238 },
    { lat: 48.394021, lng: 19.39122 },
    { lat: 48.394012, lng: 19.390804 },
    { lat: 48.394006, lng: 19.390577 },
    { lat: 48.393935, lng: 19.390436 },
    { lat: 48.393442, lng: 19.389457 },
    { lat: 48.392833, lng: 19.388247 },
    { lat: 48.392781, lng: 19.388142 },
    { lat: 48.392465, lng: 19.387691 },
    { lat: 48.392178, lng: 19.387519 },
    { lat: 48.391818, lng: 19.387557 },
    { lat: 48.391437, lng: 19.385895 },
    { lat: 48.391291, lng: 19.385619 },
    { lat: 48.391069, lng: 19.38517 },
    { lat: 48.390989, lng: 19.385014 },
    { lat: 48.390876, lng: 19.384831 },
    { lat: 48.390805, lng: 19.384718 },
    { lat: 48.390724, lng: 19.384589 },
    { lat: 48.39046, lng: 19.384144 },
    { lat: 48.390282, lng: 19.383346 },
    { lat: 48.389524, lng: 19.382687 },
    { lat: 48.389185, lng: 19.382639 },
    { lat: 48.389051, lng: 19.38262 },
    { lat: 48.388851, lng: 19.382401 },
    { lat: 48.388823, lng: 19.382371 },
    { lat: 48.388793, lng: 19.382337 },
    { lat: 48.388631, lng: 19.382103 },
    { lat: 48.388597, lng: 19.382052 },
    { lat: 48.388572, lng: 19.382018 },
    { lat: 48.388449, lng: 19.381849 },
    { lat: 48.388415, lng: 19.381801 },
    { lat: 48.388313, lng: 19.381662 },
    { lat: 48.388295, lng: 19.381637 },
    { lat: 48.388255, lng: 19.381581 },
    { lat: 48.388151, lng: 19.381436 },
    { lat: 48.388102, lng: 19.381368 },
    { lat: 48.388032, lng: 19.38127 },
    { lat: 48.387938, lng: 19.381139 },
    { lat: 48.387911, lng: 19.381097 },
    { lat: 48.387856, lng: 19.381012 },
    { lat: 48.387699, lng: 19.380766 },
    { lat: 48.387581, lng: 19.380501 },
    { lat: 48.387515, lng: 19.380421 },
    { lat: 48.387351, lng: 19.380224 },
    { lat: 48.387084, lng: 19.379791 },
    { lat: 48.387053, lng: 19.379741 },
    { lat: 48.387021, lng: 19.3797 },
    { lat: 48.386843, lng: 19.379479 },
    { lat: 48.386746, lng: 19.379259 },
    { lat: 48.386686, lng: 19.379192 },
    { lat: 48.386591, lng: 19.379016 },
    { lat: 48.386499, lng: 19.378848 },
    { lat: 48.386422, lng: 19.378706 },
    { lat: 48.386398, lng: 19.378671 },
    { lat: 48.385912, lng: 19.378299 },
    { lat: 48.385808, lng: 19.378201 },
    { lat: 48.385723, lng: 19.378122 },
    { lat: 48.385655, lng: 19.378057 },
    { lat: 48.385572, lng: 19.377979 },
    { lat: 48.385484, lng: 19.377887 },
    { lat: 48.384926, lng: 19.376882 },
    { lat: 48.384737, lng: 19.376222 },
    { lat: 48.384645, lng: 19.375594 },
    { lat: 48.384635, lng: 19.375514 },
    { lat: 48.38462, lng: 19.375406 },
    { lat: 48.384614, lng: 19.375329 },
    { lat: 48.384582, lng: 19.375121 },
    { lat: 48.384569, lng: 19.375047 },
    { lat: 48.384561, lng: 19.374972 },
    { lat: 48.384539, lng: 19.374868 },
    { lat: 48.384344, lng: 19.374399 },
    { lat: 48.384333, lng: 19.374384 },
    { lat: 48.384269, lng: 19.374305 },
    { lat: 48.384143, lng: 19.374148 },
    { lat: 48.384117, lng: 19.374116 },
    { lat: 48.384066, lng: 19.374051 },
    { lat: 48.383791, lng: 19.373702 },
    { lat: 48.383539, lng: 19.373198 },
    { lat: 48.383124, lng: 19.372355 },
    { lat: 48.383107, lng: 19.372323 },
    { lat: 48.383094, lng: 19.372299 },
    { lat: 48.383038, lng: 19.372187 },
    { lat: 48.382912, lng: 19.371939 },
    { lat: 48.382907, lng: 19.371594 },
    { lat: 48.382901, lng: 19.371133 },
    { lat: 48.38271, lng: 19.370433 },
    { lat: 48.382805, lng: 19.369811 },
    { lat: 48.382607, lng: 19.368659 },
    { lat: 48.382518, lng: 19.368137 },
    { lat: 48.382435, lng: 19.36786 },
    { lat: 48.382421, lng: 19.367559 },
    { lat: 48.382395, lng: 19.367013 },
    { lat: 48.382385, lng: 19.366816 },
    { lat: 48.382518, lng: 19.366642 },
    { lat: 48.38256, lng: 19.366587 },
    { lat: 48.382587, lng: 19.366478 },
    { lat: 48.382674, lng: 19.366113 },
    { lat: 48.382749, lng: 19.365794 },
    { lat: 48.382759, lng: 19.365626 },
    { lat: 48.382803, lng: 19.365091 },
    { lat: 48.382867, lng: 19.364715 },
    { lat: 48.382941, lng: 19.364293 },
    { lat: 48.382945, lng: 19.364257 },
    { lat: 48.383147, lng: 19.36291 },
    { lat: 48.383156, lng: 19.362863 },
    { lat: 48.383231, lng: 19.362459 },
    { lat: 48.383247, lng: 19.362339 },
    { lat: 48.383251, lng: 19.361442 },
    { lat: 48.383254, lng: 19.36053 },
    { lat: 48.383266, lng: 19.359983 },
    { lat: 48.383266, lng: 19.359948 },
    { lat: 48.383265, lng: 19.359714 },
    { lat: 48.383263, lng: 19.359241 },
    { lat: 48.38332, lng: 19.358818 },
    { lat: 48.383254, lng: 19.35855 },
    { lat: 48.38352, lng: 19.358418 },
    { lat: 48.383583, lng: 19.358387 },
    { lat: 48.383682, lng: 19.358338 },
    { lat: 48.383813, lng: 19.358272 },
    { lat: 48.384663, lng: 19.358026 },
    { lat: 48.38522, lng: 19.358138 },
    { lat: 48.385472, lng: 19.358302 },
    { lat: 48.385894, lng: 19.357682 },
    { lat: 48.386168, lng: 19.35707 },
    { lat: 48.386689, lng: 19.356162 },
    { lat: 48.387195, lng: 19.355592 },
    { lat: 48.387561, lng: 19.354718 },
    { lat: 48.388531, lng: 19.354332 },
    { lat: 48.388971, lng: 19.354062 },
    { lat: 48.389227, lng: 19.353975 },
    { lat: 48.389208, lng: 19.353833 },
    { lat: 48.389294, lng: 19.353803 },
    { lat: 48.389343, lng: 19.353782 },
    { lat: 48.389395, lng: 19.353762 },
    { lat: 48.389551, lng: 19.353701 },
    { lat: 48.390097, lng: 19.353532 },
    { lat: 48.389194, lng: 19.352438 },
    { lat: 48.388616, lng: 19.352429 },
    { lat: 48.388399, lng: 19.352466 },
    { lat: 48.388154, lng: 19.35255 },
    { lat: 48.387671, lng: 19.352759 },
    { lat: 48.386885, lng: 19.353089 },
    { lat: 48.386623, lng: 19.353202 },
    { lat: 48.386529, lng: 19.353191 },
    { lat: 48.386136, lng: 19.353318 },
    { lat: 48.385983, lng: 19.353406 },
    { lat: 48.385755, lng: 19.353437 },
    { lat: 48.385105, lng: 19.353617 },
    { lat: 48.384967, lng: 19.354318 },
    { lat: 48.384877, lng: 19.354641 },
    { lat: 48.384706, lng: 19.355262 },
    { lat: 48.384627, lng: 19.355601 },
    { lat: 48.384607, lng: 19.355584 },
    { lat: 48.384574, lng: 19.355555 },
    { lat: 48.384557, lng: 19.355541 },
    { lat: 48.384526, lng: 19.355513 },
    { lat: 48.384499, lng: 19.355409 },
    { lat: 48.384256, lng: 19.354423 },
    { lat: 48.384252, lng: 19.354404 },
    { lat: 48.384242, lng: 19.354367 },
    { lat: 48.384238, lng: 19.354345 },
    { lat: 48.384183, lng: 19.354126 },
    { lat: 48.384142, lng: 19.35396 },
    { lat: 48.384021, lng: 19.353691 },
    { lat: 48.384004, lng: 19.35365 },
    { lat: 48.383824, lng: 19.353802 },
    { lat: 48.383398, lng: 19.354026 },
    { lat: 48.383074, lng: 19.354209 },
    { lat: 48.382865, lng: 19.354331 },
    { lat: 48.3827, lng: 19.354459 },
    { lat: 48.382247, lng: 19.354781 },
    { lat: 48.382025, lng: 19.35486 },
    { lat: 48.381504, lng: 19.355004 },
    { lat: 48.381272, lng: 19.355111 },
    { lat: 48.381159, lng: 19.355179 },
    { lat: 48.380907, lng: 19.355277 },
    { lat: 48.380758, lng: 19.355238 },
    { lat: 48.380602, lng: 19.35528 },
    { lat: 48.380146, lng: 19.355406 },
    { lat: 48.379965, lng: 19.355102 },
    { lat: 48.379827, lng: 19.354938 },
    { lat: 48.379707, lng: 19.354852 },
    { lat: 48.379453, lng: 19.354746 },
    { lat: 48.379081, lng: 19.355252 },
    { lat: 48.379055, lng: 19.355709 },
    { lat: 48.379183, lng: 19.356554 },
    { lat: 48.378551, lng: 19.357056 },
    { lat: 48.378581, lng: 19.35712 },
    { lat: 48.377603, lng: 19.356934 },
    { lat: 48.377674, lng: 19.357282 },
    { lat: 48.377689, lng: 19.357366 },
    { lat: 48.37756, lng: 19.35837 },
    { lat: 48.376909, lng: 19.359665 },
    { lat: 48.376381, lng: 19.359988 },
    { lat: 48.37605, lng: 19.360731 },
    { lat: 48.375779, lng: 19.361129 },
    { lat: 48.375202, lng: 19.361536 },
    { lat: 48.374507, lng: 19.362348 },
    { lat: 48.373695, lng: 19.363523 },
    { lat: 48.372808, lng: 19.364601 },
    { lat: 48.372101, lng: 19.365755 },
    { lat: 48.371456, lng: 19.366402 },
    { lat: 48.371148, lng: 19.366959 },
    { lat: 48.370799, lng: 19.368557 },
    { lat: 48.370438, lng: 19.369796 },
    { lat: 48.370136, lng: 19.370548 },
    { lat: 48.37006, lng: 19.371019 },
    { lat: 48.370078, lng: 19.371754 },
    { lat: 48.369944, lng: 19.372844 },
    { lat: 48.36964, lng: 19.373868 },
    { lat: 48.369579, lng: 19.375925 },
    { lat: 48.369481, lng: 19.37603 },
    { lat: 48.369061, lng: 19.376479 },
    { lat: 48.3681099, lng: 19.377663 },
    { lat: 48.3678819, lng: 19.3783917 },
    { lat: 48.3672884, lng: 19.3792577 },
    { lat: 48.3667517, lng: 19.3801542 },
    { lat: 48.3664914, lng: 19.3807247 },
    { lat: 48.3656928, lng: 19.3817774 },
    { lat: 48.3653307, lng: 19.3826264 },
    { lat: 48.3638841, lng: 19.3834216 },
  ],
  Dolinka: [
    { lat: 48.065685, lng: 19.163446 },
    { lat: 48.0658015, lng: 19.1634132 },
    { lat: 48.0666442, lng: 19.1626986 },
    { lat: 48.0667242, lng: 19.1626954 },
    { lat: 48.0671954, lng: 19.1633305 },
    { lat: 48.067619, lng: 19.1630997 },
    { lat: 48.0677045, lng: 19.1631413 },
    { lat: 48.0679368, lng: 19.1635283 },
    { lat: 48.0694407, lng: 19.1631455 },
    { lat: 48.0709164, lng: 19.1630054 },
    { lat: 48.0714276, lng: 19.1628357 },
    { lat: 48.0729479, lng: 19.1620351 },
    { lat: 48.0732409, lng: 19.161377 },
    { lat: 48.0736526, lng: 19.1607018 },
    { lat: 48.0739694, lng: 19.1605399 },
    { lat: 48.074322, lng: 19.1602636 },
    { lat: 48.0762711, lng: 19.1583585 },
    { lat: 48.0767011, lng: 19.1581216 },
    { lat: 48.0768796, lng: 19.1581057 },
    { lat: 48.0773491, lng: 19.1583646 },
    { lat: 48.0788571, lng: 19.1585849 },
    { lat: 48.0808639, lng: 19.1585865 },
    { lat: 48.0820426, lng: 19.1590545 },
    { lat: 48.0837617, lng: 19.1601202 },
    { lat: 48.0839048, lng: 19.1602986 },
    { lat: 48.0836905, lng: 19.1607006 },
    { lat: 48.0835954, lng: 19.1610796 },
    { lat: 48.0836086, lng: 19.161344 },
    { lat: 48.0834092, lng: 19.1628016 },
    { lat: 48.0828936, lng: 19.1633601 },
    { lat: 48.0828989, lng: 19.163443 },
    { lat: 48.082989, lng: 19.1636439 },
    { lat: 48.0834447, lng: 19.1640352 },
    { lat: 48.0840028, lng: 19.1652863 },
    { lat: 48.0843465, lng: 19.1659192 },
    { lat: 48.0844964, lng: 19.166007 },
    { lat: 48.0845444, lng: 19.1659079 },
    { lat: 48.0847711, lng: 19.1660443 },
    { lat: 48.0848351, lng: 19.1665625 },
    { lat: 48.0851449, lng: 19.166989 },
    { lat: 48.0848565, lng: 19.1684012 },
    { lat: 48.0864389, lng: 19.1675285 },
    { lat: 48.0874801, lng: 19.1709708 },
    { lat: 48.0888887, lng: 19.1702153 },
    { lat: 48.0894397, lng: 19.1720915 },
    { lat: 48.090061, lng: 19.1717549 },
    { lat: 48.0904117, lng: 19.1716202 },
    { lat: 48.0907712, lng: 19.1715702 },
    { lat: 48.0920212, lng: 19.1715692 },
    { lat: 48.0923013, lng: 19.1716239 },
    { lat: 48.0930631, lng: 19.171961 },
    { lat: 48.0938662, lng: 19.1719459 },
    { lat: 48.0951468, lng: 19.1717881 },
    { lat: 48.0964666, lng: 19.1715318 },
    { lat: 48.0964993, lng: 19.1716915 },
    { lat: 48.0969618, lng: 19.171652 },
    { lat: 48.0971317, lng: 19.1717147 },
    { lat: 48.0976915, lng: 19.1717282 },
    { lat: 48.098177, lng: 19.1731712 },
    { lat: 48.0987367, lng: 19.1739603 },
    { lat: 48.0989674, lng: 19.1744049 },
    { lat: 48.0998697, lng: 19.1762092 },
    { lat: 48.1004902, lng: 19.1776278 },
    { lat: 48.1011231, lng: 19.1787528 },
    { lat: 48.1016426, lng: 19.1799086 },
    { lat: 48.1032498, lng: 19.1820853 },
    { lat: 48.103257, lng: 19.1820739 },
    { lat: 48.1047577, lng: 19.1808051 },
    { lat: 48.1061257, lng: 19.1791779 },
    { lat: 48.1069019, lng: 19.1781681 },
    { lat: 48.1078496, lng: 19.1761125 },
    { lat: 48.1087308, lng: 19.1749647 },
    { lat: 48.1089111, lng: 19.1748281 },
    { lat: 48.1098787, lng: 19.1742664 },
    { lat: 48.1113885, lng: 19.1735919 },
    { lat: 48.1121692, lng: 19.1734664 },
    { lat: 48.1130674, lng: 19.1733258 },
    { lat: 48.1139639, lng: 19.1733777 },
    { lat: 48.1147223, lng: 19.1733284 },
    { lat: 48.1151819, lng: 19.17366 },
    { lat: 48.1161329, lng: 19.1736966 },
    { lat: 48.116779, lng: 19.1732251 },
    { lat: 48.117454, lng: 19.1729889 },
    { lat: 48.1192298, lng: 19.1717719 },
    { lat: 48.1196141, lng: 19.1711998 },
    { lat: 48.1213021, lng: 19.1690271 },
    { lat: 48.1215059, lng: 19.1686348 },
    { lat: 48.1215443, lng: 19.1686602 },
    { lat: 48.1215316, lng: 19.1686107 },
    { lat: 48.1220482, lng: 19.1667288 },
    { lat: 48.122238, lng: 19.1662117 },
    { lat: 48.1206088, lng: 19.1650056 },
    { lat: 48.1186568, lng: 19.1635403 },
    { lat: 48.1172865, lng: 19.1625988 },
    { lat: 48.1168755, lng: 19.1623819 },
    { lat: 48.1164556, lng: 19.1620509 },
    { lat: 48.1160332, lng: 19.1618085 },
    { lat: 48.1132432, lng: 19.1607027 },
    { lat: 48.1106878, lng: 19.1592091 },
    { lat: 48.1089331, lng: 19.1577034 },
    { lat: 48.1086118, lng: 19.1575419 },
    { lat: 48.1082093, lng: 19.1574757 },
    { lat: 48.1070507, lng: 19.1568459 },
    { lat: 48.1060902, lng: 19.1566194 },
    { lat: 48.1037207, lng: 19.1563513 },
    { lat: 48.1019336, lng: 19.1560699 },
    { lat: 48.101697, lng: 19.1561444 },
    { lat: 48.1016966, lng: 19.1559741 },
    { lat: 48.101641, lng: 19.1560392 },
    { lat: 48.1015445, lng: 19.15598 },
    { lat: 48.1014874, lng: 19.1558488 },
    { lat: 48.101441, lng: 19.1559122 },
    { lat: 48.1013704, lng: 19.1557662 },
    { lat: 48.1012661, lng: 19.1559347 },
    { lat: 48.1011648, lng: 19.155868 },
    { lat: 48.1009422, lng: 19.156062 },
    { lat: 48.1005282, lng: 19.1557178 },
    { lat: 48.0993205, lng: 19.1551898 },
    { lat: 48.0992193, lng: 19.1552874 },
    { lat: 48.098913, lng: 19.1550185 },
    { lat: 48.0986643, lng: 19.1546677 },
    { lat: 48.0982464, lng: 19.1539032 },
    { lat: 48.0979504, lng: 19.1535711 },
    { lat: 48.0973831, lng: 19.1524114 },
    { lat: 48.0971071, lng: 19.1525104 },
    { lat: 48.0970384, lng: 19.1524196 },
    { lat: 48.0936451, lng: 19.1476435 },
    { lat: 48.0936397, lng: 19.147423 },
    { lat: 48.0901435, lng: 19.1426243 },
    { lat: 48.0896415, lng: 19.1422775 },
    { lat: 48.0889929, lng: 19.1419056 },
    { lat: 48.088453, lng: 19.1416874 },
    { lat: 48.0875076, lng: 19.145153 },
    { lat: 48.0855502, lng: 19.1447608 },
    { lat: 48.0850567, lng: 19.1462569 },
    { lat: 48.084763, lng: 19.1476497 },
    { lat: 48.0826833, lng: 19.1475189 },
    { lat: 48.0828083, lng: 19.1486008 },
    { lat: 48.0830125, lng: 19.1497591 },
    { lat: 48.0831268, lng: 19.1501695 },
    { lat: 48.083441, lng: 19.1509582 },
    { lat: 48.0772361, lng: 19.1534708 },
    { lat: 48.0768342, lng: 19.153502 },
    { lat: 48.0763169, lng: 19.1538015 },
    { lat: 48.0740574, lng: 19.1538183 },
    { lat: 48.0735362, lng: 19.1537404 },
    { lat: 48.0727263, lng: 19.1535299 },
    { lat: 48.0720308, lng: 19.1530554 },
    { lat: 48.071114, lng: 19.1525522 },
    { lat: 48.0699704, lng: 19.1520449 },
    { lat: 48.0698282, lng: 19.1520166 },
    { lat: 48.0696024, lng: 19.1524399 },
    { lat: 48.0694844, lng: 19.1523141 },
    { lat: 48.0694275, lng: 19.1520723 },
    { lat: 48.0692622, lng: 19.152084 },
    { lat: 48.0691659, lng: 19.1520041 },
    { lat: 48.0689365, lng: 19.1515209 },
    { lat: 48.0688242, lng: 19.1511439 },
    { lat: 48.068811, lng: 19.1507901 },
    { lat: 48.068324, lng: 19.1494783 },
    { lat: 48.068046, lng: 19.1492625 },
    { lat: 48.067519, lng: 19.149152 },
    { lat: 48.067383, lng: 19.149305 },
    { lat: 48.067359, lng: 19.149338 },
    { lat: 48.067339, lng: 19.14938 },
    { lat: 48.067242, lng: 19.149557 },
    { lat: 48.067115, lng: 19.149811 },
    { lat: 48.067029, lng: 19.150036 },
    { lat: 48.06696, lng: 19.150229 },
    { lat: 48.066924, lng: 19.15036 },
    { lat: 48.066912, lng: 19.150459 },
    { lat: 48.06692, lng: 19.150601 },
    { lat: 48.066891, lng: 19.150815 },
    { lat: 48.066871, lng: 19.150912 },
    { lat: 48.066867, lng: 19.151026 },
    { lat: 48.06687, lng: 19.151127 },
    { lat: 48.066883, lng: 19.151371 },
    { lat: 48.066882, lng: 19.151421 },
    { lat: 48.066852, lng: 19.15167 },
    { lat: 48.066849, lng: 19.151831 },
    { lat: 48.06684, lng: 19.152033 },
    { lat: 48.066827, lng: 19.152231 },
    { lat: 48.066801, lng: 19.152601 },
    { lat: 48.066789, lng: 19.152701 },
    { lat: 48.066766, lng: 19.152915 },
    { lat: 48.066723, lng: 19.153268 },
    { lat: 48.066714, lng: 19.153341 },
    { lat: 48.066678, lng: 19.153524 },
    { lat: 48.066641, lng: 19.153693 },
    { lat: 48.066604, lng: 19.153778 },
    { lat: 48.066578, lng: 19.153919 },
    { lat: 48.066551, lng: 19.153994 },
    { lat: 48.066408, lng: 19.15428 },
    { lat: 48.066325, lng: 19.154465 },
    { lat: 48.066222, lng: 19.154663 },
    { lat: 48.066054, lng: 19.154986 },
    { lat: 48.066003, lng: 19.155066 },
    { lat: 48.065944, lng: 19.155143 },
    { lat: 48.06592, lng: 19.155171 },
    { lat: 48.065835, lng: 19.155348 },
    { lat: 48.065781, lng: 19.155462 },
    { lat: 48.065735, lng: 19.15556 },
    { lat: 48.065623, lng: 19.155845 },
    { lat: 48.065461, lng: 19.156364 },
    { lat: 48.065386, lng: 19.156587 },
    { lat: 48.065357, lng: 19.156677 },
    { lat: 48.065299, lng: 19.1569 },
    { lat: 48.065234, lng: 19.157238 },
    { lat: 48.065203, lng: 19.157442 },
    { lat: 48.065182, lng: 19.157538 },
    { lat: 48.065136, lng: 19.157939 },
    { lat: 48.065128, lng: 19.157994 },
    { lat: 48.065077, lng: 19.158196 },
    { lat: 48.065063, lng: 19.158618 },
    { lat: 48.065074, lng: 19.158707 },
    { lat: 48.065079, lng: 19.158809 },
    { lat: 48.06507, lng: 19.159005 },
    { lat: 48.065066, lng: 19.159112 },
    { lat: 48.065073, lng: 19.159234 },
    { lat: 48.065091, lng: 19.159516 },
    { lat: 48.065096, lng: 19.159618 },
    { lat: 48.065106, lng: 19.159751 },
    { lat: 48.065119, lng: 19.159921 },
    { lat: 48.06516, lng: 19.160143 },
    { lat: 48.065167, lng: 19.160289 },
    { lat: 48.065264, lng: 19.160729 },
    { lat: 48.065293, lng: 19.160902 },
    { lat: 48.065358, lng: 19.161297 },
    { lat: 48.0654471, lng: 19.1617318 },
    { lat: 48.065524, lng: 19.162107 },
    { lat: 48.065585, lng: 19.162511 },
    { lat: 48.065645, lng: 19.162925 },
    { lat: 48.065662, lng: 19.163033 },
    { lat: 48.065685, lng: 19.163446 },
  ],
};

export default UnitsVeľkýKrtíš;
