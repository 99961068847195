import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHouse, faBuilding, faX, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import Rating from "../NewMenu.jsx/Rating";
import Share from "../NewMenu.jsx/Share";
import Interest from "../NewMenu.jsx/Interest";
import LV from "../NewMenu.jsx/LV";
import Owner from "../NewMenu.jsx/Owner";

library.add(faHouse);
library.add(faBuilding);
library.add(faX);

export default function HistoryList({
  historyItems,
  extended,
  map,
  setCurrentIndex,
  setVisibilityOfMarkers,
  lvSubMenu,
  setLvSubMenu,
  setSubMenu,
  subMenu,
  setInputValues,
  inputValues,
  rating,
  setRating,
  ownerMenu,
  setOwnerMenu,
  indexCur,
  setHistoryMenu,
  historyMenu,
  idx,
  indx,
  size,
  blockOfFlats,
  setHighlight,
  highlight,
}) {
  const druhStavby = useRef("");
  const historyItem = useRef("");
  // const indx = useRef(indexCur);
  const [previous, setPrevious] = useState("");

  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  // console.log("historyItems = ", historyItems);

  useEffect(() => {
    if (isSmallDevice) {
      if (indexCur.current !== null) {
        // indx.current.scrollIntoView({ behavior: "smooth", inline: "start", block: "start" });
        console.log(indx.current);
        // indx.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log("scroll to top");
      }
    }
  });

  function icon(index) {
    if (historyItems[index].response?.List?.length !== undefined) {
      historyItem.current = historyItems[index].response?.List[0];
      if (historyItems[index].response?.List[0]?.table_stavby?.length === undefined) {
        druhStavby.current = historyItems[index].response?.List[0]?.table_stavby?.druh_stavby;
      } else {
        druhStavby.current = historyItems[index].response?.List[0]?.table_stavby[0]?.druh_stavby;
      }
    } else {
      historyItem.current = historyItems[index].response?.List;
      // console.log("druh stavby = ", historyItems[index].response?.List?.table_stavby?.length);
      if (historyItems[index].response?.List?.table_stavby?.length === undefined) {
        druhStavby.current = historyItems[index].response?.List?.table_stavby?.druh_stavby;
      } else {
        druhStavby.current = historyItems[index].response?.List?.table_stavby[0]?.druh_stavby;
      }
    }

    if (historyItem.current?.hasOwnProperty("table_stavby")) {
      if (druhStavby.current === "9") {
        return <FontAwesomeIcon icon="building" className="h-3 w-3" />;
      } else {
        return <FontAwesomeIcon icon="house" className="h-3 w-3" />;
      }
    } else {
      return <FontAwesomeIcon icon="x" className="h-3 w-3" />;
    }
  }

  const showMenu = (menu) => {
    if (subMenu.lv) setPrevious("lv");
    if (subMenu.interest) setPrevious("in");
    if (subMenu.owner) setPrevious("ow");
    if (subMenu.rating) setPrevious("ra");
    if (subMenu.share) setPrevious("sh");

    if (menu === "lv") {
      setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, rating: false, share: false });
    }
    if (menu === "in") {
      setSubMenu({ ...subMenu, lv: false, interest: true, owner: false, rating: false, share: false });
    }
    if (menu === "ow") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
    }
    if (menu === "ra") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: true, share: false });
    }
    if (menu === "sh") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: false, share: true });
    }
  };

  // const iconMenu = () => {
  //   if (historyItems[indx.current]?.response?.List?.hasOwnProperty("byt")) {
  //     return <FontAwesomeIcon icon={faBuilding} className="mr-1" />;
  //   } else return <FontAwesomeIcon icon={faHouse} className="mr-1" />;
  // };

  // const title = () => {
  //   const type = historyItems[indx.current]?.response?.List?.hasOwnProperty("byt") ? "Byt: " : "Rodinný dom";
  //   const entranceTitle = blockOfFlats.entrance !== null ? "vchod " + blockOfFlats.entrance : "";
  //   const floorTitle = blockOfFlats.floor !== null ? ", poschodie č. " + blockOfFlats.floor : "";
  //   const flatTitle = blockOfFlats.flat !== null ? ", byt č. " + blockOfFlats.flat : "";
  //   return `${type} ${entranceTitle} ${floorTitle} ${flatTitle}`;
  // };

  const highlightPin = (index) => {
    if (index !== highlight.index)
      setHighlight({
        ...highlight,
        history: true,
        forSale: false,
        reo: false,
        finIn: false,
        indexH: index,
      });
    map.panTo({
      lat: Number(historyItems[index]?.latAndLng?.lat),
      lng: Number(historyItems[index]?.latAndLng?.lng),
    });
  };

  return (
    <>
      {historyMenu ? (
        <>
          <AnimatePresence>
            <motion.div className="w-full h-auto flex flex-col bg-sky-100 px-2 py-1">
              <div className={clsx("w-full h-auto flex flex-row", {})}>
                <div className="flex w-11/12 flex-col">
                  <div className="flex flex-row gap-x-2">
                    <p className="h-auto overflow-hidden">
                      {historyItems[indx.current]?.location} {historyItems[indx.current]?.number}
                    </p>
                  </div>
                  <p>Trhová cena: {historyItems[indx.current]?.avgPrice?.avgPrice} €</p>
                  {/* <div className="w-full h-full text-start overflow-hidden truncate">
                    {iconMenu()}
                    {title()}
                  </div> */}
                  <img
                    src={historyItems[indx.current]?.response?.img}
                    alt=""
                    className="w-44 h-24 rounded-xl object-cover mt-1"
                  />
                </div>
                <div
                  onClick={() => {
                    window.open(
                      `https://kataster.skgeodesy.sk/Portal45/api/Bo/GeneratePrfPublic?prfNumber=${historyItem?.response?.List?.cislo_listu_vlastnictva}&cadastralUnitCode=${historyItem?.response?.List?.nazov_katastralneho_uzemia}&outputType=pdf`,
                      "_blank",
                    );
                  }}
                  className="w-1/12 h-full flex justify-end items-start cursor-pointer"
                >
                  <FontAwesomeIcon icon={faFilePdf} className="w-5 h-5" />
                </div>
              </div>
              <motion.div
                className={clsx("flex flex-col items-start overflow-x-scroll", {
                  "no-scrollbar w-auto": isSmallDevice,
                  "w-full": !isSmallDevice && size.full,
                })}
              >
                <div
                  className={clsx("text-base w-auto flex items-center font-semibold flex-row", {
                    "w-auto": isSmallDevice || !size.full,
                    "w-full": !isSmallDevice && size.full,
                  })}
                >
                  <button
                    onClick={(e) => {
                      showMenu("lv");
                      e.stopPropagation();
                    }}
                    className={clsx(" py-1 border-b-2 border-gray-300", {
                      "border-b-2 !border-sky-500 text-sky-500": subMenu?.lv,
                      "w-32": isSmallDevice || !size.full,
                      "w-[20%]": !isSmallDevice && size.full,
                    })}
                  >
                    List Vlastnictva
                  </button>
                  <button
                    onClick={(e) => {
                      showMenu("in");
                      e.stopPropagation();
                    }}
                    className={clsx("py-1 border-b-2 border-gray-300", {
                      "border-b-2 !border-sky-500 text-sky-500": subMenu?.interest,
                      "w-32": isSmallDevice || !size.full,
                      "w-[20%]": !isSmallDevice && size.full,
                    })}
                  >
                    Mám záujem
                  </button>
                  <button
                    onClick={(e) => {
                      showMenu("ow");
                      e.stopPropagation();
                    }}
                    className={clsx("py-1 border-b-2 border-gray-300", {
                      "border-b-2 !border-sky-500 text-sky-500": subMenu?.owner,
                      "w-32": isSmallDevice || !size.full,
                      "w-[20%]": !isSmallDevice && size.full,
                    })}
                  >
                    Som vlastník
                  </button>
                  <button
                    onClick={(e) => {
                      showMenu("ra");
                      e.stopPropagation();
                    }}
                    className={clsx("py-1 border-b-2 border-gray-300", {
                      "border-b-2 !border-sky-500 text-sky-500": subMenu?.rating,
                      "w-24": isSmallDevice || !size.full,
                      "w-[20%]": !isSmallDevice && size.full,
                    })}
                  >
                    Hodnotiť
                  </button>
                  <button
                    onClick={(e) => {
                      showMenu("sh");
                      e.stopPropagation();
                    }}
                    className={clsx("py-1 border-b-2 border-gray-300", {
                      "border-b-2 !border-sky-500 text-sky-500": subMenu?.share,
                      "w-24": isSmallDevice || !size.full,
                      "w-[20%]": !isSmallDevice && size.full,
                    })}
                  >
                    Zdielať
                  </button>
                </div>
              </motion.div>
              {/* LV */}
              <LV
                subMenu={subMenu}
                historyItem={historyItems[indx?.current]}
                lvSubMenu={lvSubMenu}
                setLvSubMenu={setLvSubMenu}
                previous={previous}
              />
              {/* LV */}
              {/* Interest */}
              <Interest
                subMenu={subMenu}
                inputValues={inputValues}
                setInputValues={setInputValues}
                previous={previous}
                size={size}
              />
              {/* Interest */}
              {/* Owner */}
              <Owner
                subMenu={subMenu}
                ownerMenu={ownerMenu}
                setOwnerMenu={setOwnerMenu}
                inputValues={inputValues}
                setInputValues={setInputValues}
                previous={previous}
                size={size}
              />
              {/* Owner */}
              {/* Rating */}
              <Rating
                subMenu={subMenu}
                rating={rating}
                setRating={setRating}
                inputValues={inputValues}
                setInputValues={setInputValues}
                previous={previous}
                size={size}
              />
              {/* Rating */}
              {/* Share */}
              <Share subMenu={subMenu} size={size} items={historyItems[indx?.current]} active={"history"} />
              {/* Share */}
            </motion.div>
          </AnimatePresence>
        </>
      ) : (
        <div
          className={clsx("flex flex-col gap-4", {
            "grid sm:grid-cols-2 gap-4": !isSmallDevice && size.full,
          })}
        >
          {historyItems.map((historyItem, index) => (
            <div
              key={index}
              className={clsx(
                "transition-all duration-500 ease-linear min-h-max flex flex-col justify-center overflow-ellipsis w-full px-2 select-none",
                {
                  // hidden: !extended,
                  // "w-full px-2": isSmallDevice || !isSmallDevice,
                },
              )}
            >
              <div
                onClick={() => {
                  map.panTo({ lat: historyItem.latAndLng.lat, lng: historyItem.latAndLng.lng });
                  setCurrentIndex(index);
                  setVisibilityOfMarkers("singleHistoryPin");
                  icon(index);
                  indexCur.current = index;
                  setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, rating: false, share: false });
                  indx.current = index;
                  setHistoryMenu(true);
                }}
                className={clsx(
                  "w-full border border-opacity-50 border-sky-500 h-auto flex flex-col gap-2 cursor-pointer hover:bg-sky-300 rounded-lg shadow-lg shadow-sky-200",
                  {
                    "bg-sky-100 rounded-3xl": extended,
                    "text-xs": isSmallDevice,
                    "items-center justify-center": !extended,
                  },
                )}
                onMouseOver={() => highlightPin(index)}
                onMouseLeave={() =>
                  setHighlight({
                    ...highlight,
                    history: false,
                    forSale: false,
                    reo: false,
                    finIn: false,
                    indexH: null,
                  })
                }
              >
                <div
                  className={clsx("", {
                    "flex flex-row w-full gap-x-1 items-center text-xs": extended,
                  })}
                >
                  <img src={historyItem?.response?.img} alt="" className="w-1/3 h-24 rounded-l-lg object-cover" />
                  <div
                    className={clsx("w-2/3 flex justify-between", {
                      "": isSmallDevice,
                    })}
                  >
                    <div className="flex flex-col w-full h-24 py-2 px-1 items-start justify-between">
                      <div className="flex flex-col gap-x-2">
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden gap-1">
                          {icon(index)}
                          {historyItem.location} {historyItem.number}
                        </p>
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden gap-1">
                          okres{" "}
                          {historyItem?.response?.List?.length > 1
                            ? historyItem?.response?.List[0]?.nazov_okresu
                            : historyItem?.response?.List?.nazov_okresu}
                        </p>
                        {/* <p className="h-auto flex flex-row justify-start items-center overflow-hidden gap-1">

                          {blockOfFlats.entrance !== null ? "vchod " + blockOfFlats.entrance : ""}
                          {blockOfFlats.floor !== null ? ", poschodie č. " + blockOfFlats.floor : ""}
                          {blockOfFlats.flat !== null ? ", byt č. " + blockOfFlats.flat : ""}
                        </p> */}
                      </div>
                      <div className="w-full h-auto flex justify-end text-xl font-semibold">
                        <p>Trhová cena: {historyItem.avgPrice?.avgPrice} €</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
