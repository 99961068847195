const UnitsNovéZámky = {
  Svodín: [
    { lat: 47.9452081, lng: 18.4641885 },
    { lat: 47.9450427, lng: 18.4641767 },
    { lat: 47.9447847, lng: 18.4642843 },
    { lat: 47.9446095, lng: 18.4640355 },
    { lat: 47.9442839, lng: 18.4638609 },
    { lat: 47.9436797, lng: 18.462803 },
    { lat: 47.9433699, lng: 18.462466 },
    { lat: 47.9431993, lng: 18.4620207 },
    { lat: 47.9430891, lng: 18.4613835 },
    { lat: 47.943248, lng: 18.4595071 },
    { lat: 47.942972, lng: 18.4591938 },
    { lat: 47.9427667, lng: 18.4591538 },
    { lat: 47.9423931, lng: 18.4587893 },
    { lat: 47.9421104, lng: 18.4584525 },
    { lat: 47.9418204, lng: 18.4584104 },
    { lat: 47.9416525, lng: 18.4582336 },
    { lat: 47.9410799, lng: 18.4573171 },
    { lat: 47.9404273, lng: 18.4561225 },
    { lat: 47.9394533, lng: 18.4550477 },
    { lat: 47.9390553, lng: 18.4540456 },
    { lat: 47.9379666, lng: 18.452499 },
    { lat: 47.9378667, lng: 18.4521684 },
    { lat: 47.9378523, lng: 18.4522103 },
    { lat: 47.9362649, lng: 18.4534352 },
    { lat: 47.9357999, lng: 18.4540122 },
    { lat: 47.9349938, lng: 18.4552684 },
    { lat: 47.9347424, lng: 18.455873 },
    { lat: 47.9345191, lng: 18.4562585 },
    { lat: 47.9340801, lng: 18.4568179 },
    { lat: 47.9336809, lng: 18.4577043 },
    { lat: 47.9334061, lng: 18.4585208 },
    { lat: 47.9324812, lng: 18.4580999 },
    { lat: 47.9312076, lng: 18.4578585 },
    { lat: 47.9305623, lng: 18.4578584 },
    { lat: 47.9300282, lng: 18.4572842 },
    { lat: 47.9289221, lng: 18.4568128 },
    { lat: 47.9281909, lng: 18.4561885 },
    { lat: 47.9275981, lng: 18.4555541 },
    { lat: 47.925877, lng: 18.4545953 },
    { lat: 47.9243195, lng: 18.4539405 },
    { lat: 47.9240048, lng: 18.4528359 },
    { lat: 47.923412, lng: 18.4526064 },
    { lat: 47.9213592, lng: 18.4523501 },
    { lat: 47.9201816, lng: 18.4526769 },
    { lat: 47.9195852, lng: 18.4533039 },
    { lat: 47.918379, lng: 18.4540642 },
    { lat: 47.9165085, lng: 18.4564171 },
    { lat: 47.9142496, lng: 18.4584053 },
    { lat: 47.9076888, lng: 18.4538227 },
    { lat: 47.9019322, lng: 18.4556732 },
    { lat: 47.9006888, lng: 18.4549241 },
    { lat: 47.8993661, lng: 18.4537905 },
    { lat: 47.893691, lng: 18.4542215 },
    { lat: 47.8916873, lng: 18.4552239 },
    { lat: 47.8882428, lng: 18.4595566 },
    { lat: 47.8867294, lng: 18.4602901 },
    { lat: 47.886867, lng: 18.4604882 },
    { lat: 47.8892752, lng: 18.4627351 },
    { lat: 47.8897038, lng: 18.4631982 },
    { lat: 47.8899014, lng: 18.4636609 },
    { lat: 47.8871549, lng: 18.4667676 },
    { lat: 47.8854576, lng: 18.4682055 },
    { lat: 47.8865256, lng: 18.4697095 },
    { lat: 47.8875551, lng: 18.4695212 },
    { lat: 47.887743, lng: 18.4701941 },
    { lat: 47.8877721, lng: 18.4701751 },
    { lat: 47.8876706, lng: 18.4697706 },
    { lat: 47.887585, lng: 18.4694293 },
    { lat: 47.8892379, lng: 18.4685708 },
    { lat: 47.8894466, lng: 18.4694338 },
    { lat: 47.8928049, lng: 18.4679858 },
    { lat: 47.8928843, lng: 18.4684368 },
    { lat: 47.8927385, lng: 18.4689508 },
    { lat: 47.891979, lng: 18.4704996 },
    { lat: 47.8901, lng: 18.4725607 },
    { lat: 47.8894481, lng: 18.4733471 },
    { lat: 47.8889115, lng: 18.4738592 },
    { lat: 47.8879945, lng: 18.4750271 },
    { lat: 47.8872838, lng: 18.4756924 },
    { lat: 47.8870523, lng: 18.4757713 },
    { lat: 47.8866058, lng: 18.4757345 },
    { lat: 47.8858242, lng: 18.4754953 },
    { lat: 47.8855348, lng: 18.4753051 },
    { lat: 47.8840239, lng: 18.4786429 },
    { lat: 47.8834902, lng: 18.4800299 },
    { lat: 47.8824912, lng: 18.4822577 },
    { lat: 47.8823866, lng: 18.4824449 },
    { lat: 47.8820321, lng: 18.4820955 },
    { lat: 47.8814053, lng: 18.4835761 },
    { lat: 47.8804587, lng: 18.4853889 },
    { lat: 47.8800467, lng: 18.4864174 },
    { lat: 47.88037, lng: 18.4868086 },
    { lat: 47.8790299, lng: 18.4905867 },
    { lat: 47.8774173, lng: 18.4898884 },
    { lat: 47.8747865, lng: 18.4885551 },
    { lat: 47.8736373, lng: 18.4880965 },
    { lat: 47.8723095, lng: 18.4877109 },
    { lat: 47.8696516, lng: 18.4872838 },
    { lat: 47.8692634, lng: 18.4873011 },
    { lat: 47.8693667, lng: 18.4893317 },
    { lat: 47.8701253, lng: 18.4895057 },
    { lat: 47.8702504, lng: 18.4902671 },
    { lat: 47.8697801, lng: 18.4908145 },
    { lat: 47.8687265, lng: 18.4949171 },
    { lat: 47.8683903, lng: 18.495767 },
    { lat: 47.8683234, lng: 18.4972535 },
    { lat: 47.86854, lng: 18.4986103 },
    { lat: 47.8674207, lng: 18.5017793 },
    { lat: 47.8670161, lng: 18.5041933 },
    { lat: 47.8672433, lng: 18.5044432 },
    { lat: 47.8673994, lng: 18.5067784 },
    { lat: 47.8663587, lng: 18.5068099 },
    { lat: 47.8656672, lng: 18.5060779 },
    { lat: 47.8633808, lng: 18.5085106 },
    { lat: 47.8658652, lng: 18.5105659 },
    { lat: 47.8681886, lng: 18.5123622 },
    { lat: 47.8689757, lng: 18.5123571 },
    { lat: 47.8700632, lng: 18.5119943 },
    { lat: 47.871165, lng: 18.511095 },
    { lat: 47.871546, lng: 18.5109069 },
    { lat: 47.8718144, lng: 18.5106631 },
    { lat: 47.8720731, lng: 18.5105701 },
    { lat: 47.8721468, lng: 18.5104546 },
    { lat: 47.8729935, lng: 18.5101621 },
    { lat: 47.8744129, lng: 18.5125171 },
    { lat: 47.8761058, lng: 18.5148857 },
    { lat: 47.877012, lng: 18.5177697 },
    { lat: 47.87552, lng: 18.5198057 },
    { lat: 47.8718061, lng: 18.523511 },
    { lat: 47.8703997, lng: 18.5258309 },
    { lat: 47.8675759, lng: 18.5282641 },
    { lat: 47.8670951, lng: 18.5287668 },
    { lat: 47.8676592, lng: 18.5345986 },
    { lat: 47.8679527, lng: 18.5372904 },
    { lat: 47.8681732, lng: 18.5407988 },
    { lat: 47.8676187, lng: 18.5440628 },
    { lat: 47.8670755, lng: 18.5464572 },
    { lat: 47.8666497, lng: 18.5510124 },
    { lat: 47.8669065, lng: 18.5544105 },
    { lat: 47.8668957, lng: 18.5571515 },
    { lat: 47.8669672, lng: 18.5579549 },
    { lat: 47.8682941, lng: 18.5572291 },
    { lat: 47.8683076, lng: 18.5573883 },
    { lat: 47.8692074, lng: 18.5558961 },
    { lat: 47.8698208, lng: 18.5550318 },
    { lat: 47.870555, lng: 18.5543365 },
    { lat: 47.8714348, lng: 18.5528698 },
    { lat: 47.8720978, lng: 18.5518876 },
    { lat: 47.8730787, lng: 18.5532036 },
    { lat: 47.8739383, lng: 18.5541631 },
    { lat: 47.8739113, lng: 18.554204 },
    { lat: 47.8768886, lng: 18.5558031 },
    { lat: 47.8777265, lng: 18.5558614 },
    { lat: 47.8795972, lng: 18.5524867 },
    { lat: 47.8826747, lng: 18.5514081 },
    { lat: 47.8842779, lng: 18.5525769 },
    { lat: 47.8875919, lng: 18.5547379 },
    { lat: 47.8858962, lng: 18.5627169 },
    { lat: 47.883441, lng: 18.5700028 },
    { lat: 47.8809691, lng: 18.5778934 },
    { lat: 47.8827118, lng: 18.5795502 },
    { lat: 47.885515, lng: 18.5774011 },
    { lat: 47.8862939, lng: 18.5775371 },
    { lat: 47.8863292, lng: 18.5765205 },
    { lat: 47.8893171, lng: 18.5720944 },
    { lat: 47.8909139, lng: 18.5686121 },
    { lat: 47.8918784, lng: 18.5661978 },
    { lat: 47.8925324, lng: 18.5648862 },
    { lat: 47.8935765, lng: 18.5630201 },
    { lat: 47.894087, lng: 18.5619277 },
    { lat: 47.8943873, lng: 18.5614018 },
    { lat: 47.8948982, lng: 18.5607661 },
    { lat: 47.8960437, lng: 18.5599405 },
    { lat: 47.8969061, lng: 18.5582961 },
    { lat: 47.8993798, lng: 18.5532324 },
    { lat: 47.9025302, lng: 18.5507617 },
    { lat: 47.9040873, lng: 18.5505287 },
    { lat: 47.9051662, lng: 18.5494961 },
    { lat: 47.9065847, lng: 18.5481212 },
    { lat: 47.9078837, lng: 18.5485413 },
    { lat: 47.909181, lng: 18.5491936 },
    { lat: 47.9107163, lng: 18.546853 },
    { lat: 47.912436, lng: 18.5483313 },
    { lat: 47.9159692, lng: 18.5517406 },
    { lat: 47.9178546, lng: 18.553201 },
    { lat: 47.919104, lng: 18.5543763 },
    { lat: 47.9206514, lng: 18.5551686 },
    { lat: 47.921583, lng: 18.5541646 },
    { lat: 47.9221981, lng: 18.5541101 },
    { lat: 47.9230794, lng: 18.5544845 },
    { lat: 47.9235835, lng: 18.5548892 },
    { lat: 47.9240076, lng: 18.5546242 },
    { lat: 47.927017, lng: 18.5483161 },
    { lat: 47.9290032, lng: 18.5446185 },
    { lat: 47.932017, lng: 18.537815 },
    { lat: 47.932027, lng: 18.537795 },
    { lat: 47.932068, lng: 18.537691 },
    { lat: 47.932734, lng: 18.536126 },
    { lat: 47.933079, lng: 18.535302 },
    { lat: 47.933105, lng: 18.535208 },
    { lat: 47.933222, lng: 18.534759 },
    { lat: 47.933356, lng: 18.534227 },
    { lat: 47.933445, lng: 18.533882 },
    { lat: 47.933479, lng: 18.533766 },
    { lat: 47.933734, lng: 18.532839 },
    { lat: 47.933982, lng: 18.531917 },
    { lat: 47.934116, lng: 18.53141 },
    { lat: 47.934178, lng: 18.531183 },
    { lat: 47.934526, lng: 18.530073 },
    { lat: 47.934931, lng: 18.528774 },
    { lat: 47.935114, lng: 18.528172 },
    { lat: 47.9354, lng: 18.52713 },
    { lat: 47.935448, lng: 18.526947 },
    { lat: 47.935587, lng: 18.526445 },
    { lat: 47.935674, lng: 18.526112 },
    { lat: 47.935784, lng: 18.5257 },
    { lat: 47.935819, lng: 18.525528 },
    { lat: 47.935906, lng: 18.525102 },
    { lat: 47.935962, lng: 18.524755 },
    { lat: 47.936025, lng: 18.524421 },
    { lat: 47.93609, lng: 18.52408 },
    { lat: 47.936213, lng: 18.523461 },
    { lat: 47.936331, lng: 18.52285 },
    { lat: 47.936391, lng: 18.522553 },
    { lat: 47.936474, lng: 18.522248 },
    { lat: 47.936662, lng: 18.521517 },
    { lat: 47.936846, lng: 18.520815 },
    { lat: 47.936924, lng: 18.52053 },
    { lat: 47.936997, lng: 18.520247 },
    { lat: 47.937152, lng: 18.519667 },
    { lat: 47.937215, lng: 18.519422 },
    { lat: 47.937363, lng: 18.518822 },
    { lat: 47.937428, lng: 18.518545 },
    { lat: 47.937581, lng: 18.517937 },
    { lat: 47.937755, lng: 18.517233 },
    { lat: 47.937816, lng: 18.516997 },
    { lat: 47.937822, lng: 18.516938 },
    { lat: 47.937974, lng: 18.516062 },
    { lat: 47.938197, lng: 18.514805 },
    { lat: 47.938238, lng: 18.514673 },
    { lat: 47.938352, lng: 18.514375 },
    { lat: 47.938811, lng: 18.513045 },
    { lat: 47.938897, lng: 18.512794 },
    { lat: 47.939084, lng: 18.512237 },
    { lat: 47.939107, lng: 18.512143 },
    { lat: 47.939152, lng: 18.51194 },
    { lat: 47.939206, lng: 18.511726 },
    { lat: 47.939229, lng: 18.511622 },
    { lat: 47.939307, lng: 18.511297 },
    { lat: 47.939355, lng: 18.511084 },
    { lat: 47.93941, lng: 18.510866 },
    { lat: 47.93975, lng: 18.509409 },
    { lat: 47.939798, lng: 18.509164 },
    { lat: 47.940061, lng: 18.507834 },
    { lat: 47.940256, lng: 18.506807 },
    { lat: 47.940728, lng: 18.505449 },
    { lat: 47.941116, lng: 18.504318 },
    { lat: 47.94115, lng: 18.504135 },
    { lat: 47.941306, lng: 18.503335 },
    { lat: 47.941515, lng: 18.502219 },
    { lat: 47.941566, lng: 18.501957 },
    { lat: 47.941572, lng: 18.501927 },
    { lat: 47.941586, lng: 18.501843 },
    { lat: 47.941595, lng: 18.501816 },
    { lat: 47.941626, lng: 18.501549 },
    { lat: 47.941778, lng: 18.50038 },
    { lat: 47.941903, lng: 18.499389 },
    { lat: 47.941911, lng: 18.499291 },
    { lat: 47.941921, lng: 18.499229 },
    { lat: 47.942054, lng: 18.497955 },
    { lat: 47.942097, lng: 18.497522 },
    { lat: 47.942227, lng: 18.496212 },
    { lat: 47.942256, lng: 18.495969 },
    { lat: 47.942316, lng: 18.495492 },
    { lat: 47.942355, lng: 18.495196 },
    { lat: 47.942492, lng: 18.494064 },
    { lat: 47.94257, lng: 18.493705 },
    { lat: 47.942666, lng: 18.493287 },
    { lat: 47.942738, lng: 18.492954 },
    { lat: 47.942785, lng: 18.492793 },
    { lat: 47.942842, lng: 18.492582 },
    { lat: 47.942932, lng: 18.492262 },
    { lat: 47.942958, lng: 18.492163 },
    { lat: 47.94313, lng: 18.491722 },
    { lat: 47.943385, lng: 18.49107 },
    { lat: 47.943287, lng: 18.489693 },
    { lat: 47.943175, lng: 18.489232 },
    { lat: 47.943108, lng: 18.488942 },
    { lat: 47.942749, lng: 18.487492 },
    { lat: 47.942557, lng: 18.486728 },
    { lat: 47.942485, lng: 18.486393 },
    { lat: 47.942289, lng: 18.485459 },
    { lat: 47.942218, lng: 18.48512 },
    { lat: 47.942124, lng: 18.484668 },
    { lat: 47.9421, lng: 18.48456 },
    { lat: 47.942196, lng: 18.484371 },
    { lat: 47.942298, lng: 18.484148 },
    { lat: 47.942425, lng: 18.483895 },
    { lat: 47.942551, lng: 18.483625 },
    { lat: 47.9427, lng: 18.483327 },
    { lat: 47.942829, lng: 18.483089 },
    { lat: 47.943071, lng: 18.48263 },
    { lat: 47.943155, lng: 18.482499 },
    { lat: 47.943451, lng: 18.481931 },
    { lat: 47.943511, lng: 18.481839 },
    { lat: 47.943655, lng: 18.481571 },
    { lat: 47.943897, lng: 18.48114 },
    { lat: 47.944004, lng: 18.480941 },
    { lat: 47.944096, lng: 18.480767 },
    { lat: 47.944226, lng: 18.480544 },
    { lat: 47.944353, lng: 18.480294 },
    { lat: 47.944428, lng: 18.480172 },
    { lat: 47.944469, lng: 18.480098 },
    { lat: 47.944529, lng: 18.479971 },
    { lat: 47.944549, lng: 18.47989 },
    { lat: 47.944575, lng: 18.47982 },
    { lat: 47.944588, lng: 18.479759 },
    { lat: 47.944666, lng: 18.479728 },
    { lat: 47.944755, lng: 18.479584 },
    { lat: 47.944917, lng: 18.479333 },
    { lat: 47.945336, lng: 18.478678 },
    { lat: 47.945467, lng: 18.478467 },
    { lat: 47.945556, lng: 18.478172 },
    { lat: 47.945848, lng: 18.477233 },
    { lat: 47.9459, lng: 18.47718 },
    { lat: 47.946158, lng: 18.476886 },
    { lat: 47.946984, lng: 18.475952 },
    { lat: 47.947538, lng: 18.475421 },
    { lat: 47.94775, lng: 18.475216 },
    { lat: 47.948223, lng: 18.474776 },
    { lat: 47.948813, lng: 18.474237 },
    { lat: 47.949218, lng: 18.47386 },
    { lat: 47.94936, lng: 18.473736 },
    { lat: 47.949589, lng: 18.473528 },
    { lat: 47.949665, lng: 18.473401 },
    { lat: 47.949904, lng: 18.473016 },
    { lat: 47.950502, lng: 18.472041 },
    { lat: 47.951476, lng: 18.470445 },
    { lat: 47.951832, lng: 18.469864 },
    { lat: 47.951866, lng: 18.469805 },
    { lat: 47.951367, lng: 18.46926 },
    { lat: 47.951066, lng: 18.468926 },
    { lat: 47.950813, lng: 18.468652 },
    { lat: 47.950659, lng: 18.468481 },
    { lat: 47.950047, lng: 18.467814 },
    { lat: 47.949801, lng: 18.467542 },
    { lat: 47.949395, lng: 18.467098 },
    { lat: 47.949329, lng: 18.467028 },
    { lat: 47.948695, lng: 18.466344 },
    { lat: 47.948582, lng: 18.466213 },
    { lat: 47.9476097, lng: 18.4656156 },
    { lat: 47.9470511, lng: 18.4650502 },
    { lat: 47.9462493, lng: 18.4646926 },
    { lat: 47.9456584, lng: 18.4643142 },
    { lat: 47.9452645, lng: 18.4641913 },
    { lat: 47.9452081, lng: 18.4641885 },
  ],
  Leľa: [
    { lat: 47.877967, lng: 18.763566 },
    { lat: 47.8774151, lng: 18.7620615 },
    { lat: 47.8764574, lng: 18.7601576 },
    { lat: 47.8756855, lng: 18.7588227 },
    { lat: 47.8746416, lng: 18.7572923 },
    { lat: 47.8709846, lng: 18.7564952 },
    { lat: 47.8700216, lng: 18.7559308 },
    { lat: 47.8691378, lng: 18.7546293 },
    { lat: 47.8692665, lng: 18.7525489 },
    { lat: 47.8690274, lng: 18.7507986 },
    { lat: 47.8670706, lng: 18.7510937 },
    { lat: 47.8664792, lng: 18.7513375 },
    { lat: 47.8657861, lng: 18.7513878 },
    { lat: 47.8651749, lng: 18.751896 },
    { lat: 47.8650896, lng: 18.7521136 },
    { lat: 47.8639695, lng: 18.7528442 },
    { lat: 47.8638389, lng: 18.7525769 },
    { lat: 47.8616216, lng: 18.7544271 },
    { lat: 47.8610403, lng: 18.7546103 },
    { lat: 47.8595725, lng: 18.7546194 },
    { lat: 47.8594161, lng: 18.7563326 },
    { lat: 47.8587778, lng: 18.7565232 },
    { lat: 47.856554, lng: 18.7565447 },
    { lat: 47.853558, lng: 18.7600742 },
    { lat: 47.8525324, lng: 18.7615822 },
    { lat: 47.8509359, lng: 18.7611906 },
    { lat: 47.8493604, lng: 18.7623277 },
    { lat: 47.8450978, lng: 18.7691301 },
    { lat: 47.8427281, lng: 18.7741491 },
    { lat: 47.836435, lng: 18.7808209 },
    { lat: 47.8342702, lng: 18.7830926 },
    { lat: 47.8349591, lng: 18.7829197 },
    { lat: 47.8353963, lng: 18.7831459 },
    { lat: 47.8373228, lng: 18.7838519 },
    { lat: 47.837983, lng: 18.783629 },
    { lat: 47.8385067, lng: 18.7832125 },
    { lat: 47.840327, lng: 18.7841866 },
    { lat: 47.8411256, lng: 18.7848495 },
    { lat: 47.841591, lng: 18.7850718 },
    { lat: 47.8419315, lng: 18.7853684 },
    { lat: 47.842552, lng: 18.7860912 },
    { lat: 47.8430665, lng: 18.7865225 },
    { lat: 47.8437237, lng: 18.7872976 },
    { lat: 47.8442882, lng: 18.7874176 },
    { lat: 47.8447543, lng: 18.7876588 },
    { lat: 47.8447806, lng: 18.7880846 },
    { lat: 47.8448562, lng: 18.7882976 },
    { lat: 47.8447765, lng: 18.7887103 },
    { lat: 47.8447901, lng: 18.7891728 },
    { lat: 47.8448452, lng: 18.7893433 },
    { lat: 47.8447856, lng: 18.7896216 },
    { lat: 47.8448803, lng: 18.7898741 },
    { lat: 47.8448514, lng: 18.7900938 },
    { lat: 47.8454863, lng: 18.7900925 },
    { lat: 47.8464353, lng: 18.79023 },
    { lat: 47.8472353, lng: 18.7894991 },
    { lat: 47.8477962, lng: 18.7896123 },
    { lat: 47.8481496, lng: 18.7906784 },
    { lat: 47.8499995, lng: 18.7955215 },
    { lat: 47.851011, lng: 18.7959445 },
    { lat: 47.8524277, lng: 18.7968992 },
    { lat: 47.8535614, lng: 18.7977766 },
    { lat: 47.854837, lng: 18.7984794 },
    { lat: 47.857532, lng: 18.79944 },
    { lat: 47.857695, lng: 18.798511 },
    { lat: 47.858139, lng: 18.796523 },
    { lat: 47.858407, lng: 18.795917 },
    { lat: 47.858574, lng: 18.795706 },
    { lat: 47.858867, lng: 18.795441 },
    { lat: 47.859318, lng: 18.795172 },
    { lat: 47.859959, lng: 18.794923 },
    { lat: 47.860671, lng: 18.794549 },
    { lat: 47.861141, lng: 18.794441 },
    { lat: 47.861892, lng: 18.794407 },
    { lat: 47.862806, lng: 18.793975 },
    { lat: 47.863408, lng: 18.793802 },
    { lat: 47.86372, lng: 18.793811 },
    { lat: 47.865192, lng: 18.793039 },
    { lat: 47.8653583, lng: 18.7927648 },
    { lat: 47.865399, lng: 18.792346 },
    { lat: 47.865639, lng: 18.790126 },
    { lat: 47.865897, lng: 18.788826 },
    { lat: 47.8660221, lng: 18.7879589 },
    { lat: 47.865943, lng: 18.78734 },
    { lat: 47.86603, lng: 18.786741 },
    { lat: 47.866108, lng: 18.786532 },
    { lat: 47.8661213, lng: 18.7863731 },
    { lat: 47.8660205, lng: 18.785495 },
    { lat: 47.8655163, lng: 18.7845407 },
    { lat: 47.865385, lng: 18.783419 },
    { lat: 47.865396, lng: 18.782909 },
    { lat: 47.865237, lng: 18.782401 },
    { lat: 47.865235, lng: 18.782211 },
    { lat: 47.865441, lng: 18.781604 },
    { lat: 47.865621, lng: 18.781418 },
    { lat: 47.866019, lng: 18.781402 },
    { lat: 47.866432, lng: 18.781218 },
    { lat: 47.866825, lng: 18.781191 },
    { lat: 47.86741, lng: 18.7809 },
    { lat: 47.868509, lng: 18.779196 },
    { lat: 47.869258, lng: 18.778726 },
    { lat: 47.869459, lng: 18.778439 },
    { lat: 47.869591, lng: 18.778081 },
    { lat: 47.869643, lng: 18.777778 },
    { lat: 47.869592, lng: 18.777147 },
    { lat: 47.86894, lng: 18.77599 },
    { lat: 47.868701, lng: 18.775174 },
    { lat: 47.868652, lng: 18.774723 },
    { lat: 47.8687, lng: 18.774354 },
    { lat: 47.86888, lng: 18.773663 },
    { lat: 47.868914, lng: 18.772896 },
    { lat: 47.868469, lng: 18.77086 },
    { lat: 47.868401, lng: 18.769853 },
    { lat: 47.868457, lng: 18.769701 },
    { lat: 47.868468, lng: 18.769383 },
    { lat: 47.868561, lng: 18.76892 },
    { lat: 47.868667, lng: 18.76875 },
    { lat: 47.868825, lng: 18.768627 },
    { lat: 47.869036, lng: 18.768586 },
    { lat: 47.869373, lng: 18.7686 },
    { lat: 47.869958, lng: 18.769249 },
    { lat: 47.870519, lng: 18.769524 },
    { lat: 47.870798, lng: 18.769566 },
    { lat: 47.871148, lng: 18.769489 },
    { lat: 47.87143, lng: 18.769346 },
    { lat: 47.871817, lng: 18.768984 },
    { lat: 47.87197, lng: 18.76869 },
    { lat: 47.872114, lng: 18.768251 },
    { lat: 47.872161, lng: 18.767964 },
    { lat: 47.872127, lng: 18.767442 },
    { lat: 47.871647, lng: 18.766107 },
    { lat: 47.871482, lng: 18.765867 },
    { lat: 47.871185, lng: 18.765175 },
    { lat: 47.87112, lng: 18.764865 },
    { lat: 47.871218, lng: 18.764337 },
    { lat: 47.871406, lng: 18.76384 },
    { lat: 47.8713527, lng: 18.7637763 },
    { lat: 47.871605, lng: 18.76353 },
    { lat: 47.87181, lng: 18.763323 },
    { lat: 47.872077, lng: 18.763195 },
    { lat: 47.873146, lng: 18.763153 },
    { lat: 47.87362, lng: 18.763367 },
    { lat: 47.874501, lng: 18.763895 },
    { lat: 47.874933, lng: 18.763929 },
    { lat: 47.875225, lng: 18.76393 },
    { lat: 47.87624, lng: 18.763682 },
    { lat: 47.877967, lng: 18.763566 },
  ],
  Bánov: [
    { lat: 48.0578888, lng: 18.1945785 },
    { lat: 48.057887, lng: 18.1945477 },
    { lat: 48.0578471, lng: 18.1942763 },
    { lat: 48.0576142, lng: 18.1926914 },
    { lat: 48.0573284, lng: 18.1911807 },
    { lat: 48.0563678, lng: 18.1867835 },
    { lat: 48.0561853, lng: 18.1837943 },
    { lat: 48.0555499, lng: 18.17993 },
    { lat: 48.0549605, lng: 18.1736708 },
    { lat: 48.0548791, lng: 18.1728201 },
    { lat: 48.0547096, lng: 18.1708077 },
    { lat: 48.0542131, lng: 18.1675494 },
    { lat: 48.054149, lng: 18.1662242 },
    { lat: 48.0538808, lng: 18.1645164 },
    { lat: 48.053275, lng: 18.1571846 },
    { lat: 48.0530268, lng: 18.1538362 },
    { lat: 48.0530458, lng: 18.1502562 },
    { lat: 48.052269, lng: 18.1485402 },
    { lat: 48.0514682, lng: 18.1415188 },
    { lat: 48.0488398, lng: 18.1402845 },
    { lat: 48.0445316, lng: 18.1381039 },
    { lat: 48.0408063, lng: 18.1354587 },
    { lat: 48.040242, lng: 18.1364536 },
    { lat: 48.0396453, lng: 18.1377828 },
    { lat: 48.0392192, lng: 18.1393643 },
    { lat: 48.038874, lng: 18.1404031 },
    { lat: 48.0378317, lng: 18.1432111 },
    { lat: 48.036141, lng: 18.1483158 },
    { lat: 48.0342499, lng: 18.1530691 },
    { lat: 48.0320789, lng: 18.1624023 },
    { lat: 48.0310091, lng: 18.1661387 },
    { lat: 48.0302467, lng: 18.1737194 },
    { lat: 48.0303868, lng: 18.1772537 },
    { lat: 48.0303621, lng: 18.1800544 },
    { lat: 48.030132, lng: 18.1839541 },
    { lat: 48.0300274, lng: 18.1883293 },
    { lat: 48.028607, lng: 18.2001339 },
    { lat: 48.0285317, lng: 18.2012798 },
    { lat: 48.0281189, lng: 18.2181327 },
    { lat: 48.0288868, lng: 18.2175403 },
    { lat: 48.029109, lng: 18.2176526 },
    { lat: 48.0291798, lng: 18.2178829 },
    { lat: 48.0291758, lng: 18.219133 },
    { lat: 48.0296708, lng: 18.2200414 },
    { lat: 48.0299286, lng: 18.2203024 },
    { lat: 48.0300923, lng: 18.2202837 },
    { lat: 48.0301644, lng: 18.2201652 },
    { lat: 48.0300496, lng: 18.2195497 },
    { lat: 48.0300584, lng: 18.2192729 },
    { lat: 48.0302149, lng: 18.2191871 },
    { lat: 48.0306015, lng: 18.2195901 },
    { lat: 48.0306834, lng: 18.219619 },
    { lat: 48.0307682, lng: 18.2195443 },
    { lat: 48.0308793, lng: 18.2192997 },
    { lat: 48.0308445, lng: 18.2185664 },
    { lat: 48.0309498, lng: 18.2181089 },
    { lat: 48.0313051, lng: 18.2170735 },
    { lat: 48.0313693, lng: 18.2165107 },
    { lat: 48.0315138, lng: 18.2164137 },
    { lat: 48.0317897, lng: 18.2163671 },
    { lat: 48.0319886, lng: 18.2165639 },
    { lat: 48.0319938, lng: 18.217024 },
    { lat: 48.0319024, lng: 18.2182859 },
    { lat: 48.0319134, lng: 18.2190368 },
    { lat: 48.0319563, lng: 18.2193471 },
    { lat: 48.0321511, lng: 18.2197704 },
    { lat: 48.0323122, lng: 18.2196868 },
    { lat: 48.0324041, lng: 18.2194811 },
    { lat: 48.0324595, lng: 18.2189014 },
    { lat: 48.0323883, lng: 18.218355 },
    { lat: 48.0324352, lng: 18.2180965 },
    { lat: 48.0326025, lng: 18.2179434 },
    { lat: 48.0328352, lng: 18.2180857 },
    { lat: 48.0334625, lng: 18.218684 },
    { lat: 48.0345836, lng: 18.2188886 },
    { lat: 48.0350539, lng: 18.2193112 },
    { lat: 48.0351837, lng: 18.2193318 },
    { lat: 48.0354133, lng: 18.2196131 },
    { lat: 48.0357841, lng: 18.2197436 },
    { lat: 48.0368105, lng: 18.2195793 },
    { lat: 48.0369771, lng: 18.21963 },
    { lat: 48.0373234, lng: 18.2199379 },
    { lat: 48.037608, lng: 18.2200986 },
    { lat: 48.0378221, lng: 18.2202884 },
    { lat: 48.0378371, lng: 18.2207011 },
    { lat: 48.0377298, lng: 18.2210284 },
    { lat: 48.0378017, lng: 18.2212765 },
    { lat: 48.0379076, lng: 18.2213485 },
    { lat: 48.0384006, lng: 18.2213289 },
    { lat: 48.0385051, lng: 18.2212266 },
    { lat: 48.0386536, lng: 18.2212557 },
    { lat: 48.0387557, lng: 18.2216332 },
    { lat: 48.0388633, lng: 18.2217959 },
    { lat: 48.0391623, lng: 18.2219727 },
    { lat: 48.039252, lng: 18.2221378 },
    { lat: 48.0392888, lng: 18.2225555 },
    { lat: 48.0392694, lng: 18.2229786 },
    { lat: 48.039594, lng: 18.2233023 },
    { lat: 48.0398337, lng: 18.2234286 },
    { lat: 48.0400157, lng: 18.2234344 },
    { lat: 48.0402017, lng: 18.2235274 },
    { lat: 48.0404686, lng: 18.2234813 },
    { lat: 48.0407497, lng: 18.2235205 },
    { lat: 48.0409892, lng: 18.223741 },
    { lat: 48.0412532, lng: 18.2242076 },
    { lat: 48.0414307, lng: 18.2249103 },
    { lat: 48.0416761, lng: 18.2249375 },
    { lat: 48.0418084, lng: 18.2244963 },
    { lat: 48.041974, lng: 18.2243381 },
    { lat: 48.0422235, lng: 18.2243394 },
    { lat: 48.0424922, lng: 18.2244376 },
    { lat: 48.0428537, lng: 18.2247383 },
    { lat: 48.0430092, lng: 18.225007 },
    { lat: 48.0433154, lng: 18.225849 },
    { lat: 48.0437805, lng: 18.226142 },
    { lat: 48.0440471, lng: 18.2265568 },
    { lat: 48.0441217, lng: 18.2267742 },
    { lat: 48.0440165, lng: 18.227333 },
    { lat: 48.0442406, lng: 18.2279666 },
    { lat: 48.0442456, lng: 18.2285185 },
    { lat: 48.044384, lng: 18.2292535 },
    { lat: 48.0449363, lng: 18.2299982 },
    { lat: 48.0450814, lng: 18.229915 },
    { lat: 48.0452781, lng: 18.2301714 },
    { lat: 48.0453936, lng: 18.2302185 },
    { lat: 48.0456483, lng: 18.2299787 },
    { lat: 48.0459614, lng: 18.2300473 },
    { lat: 48.046207, lng: 18.2303894 },
    { lat: 48.0463439, lng: 18.2308245 },
    { lat: 48.0462811, lng: 18.2311616 },
    { lat: 48.0461855, lng: 18.2313633 },
    { lat: 48.0459787, lng: 18.2315902 },
    { lat: 48.0457791, lng: 18.2316703 },
    { lat: 48.0456168, lng: 18.2315181 },
    { lat: 48.0453716, lng: 18.2315712 },
    { lat: 48.0448049, lng: 18.2323069 },
    { lat: 48.0448141, lng: 18.232348 },
    { lat: 48.0448248, lng: 18.2325113 },
    { lat: 48.0449786, lng: 18.2328113 },
    { lat: 48.0451583, lng: 18.2330103 },
    { lat: 48.0456622, lng: 18.2331787 },
    { lat: 48.0459496, lng: 18.2334823 },
    { lat: 48.0460665, lng: 18.2336869 },
    { lat: 48.0463559, lng: 18.2345552 },
    { lat: 48.0470457, lng: 18.2347156 },
    { lat: 48.0471804, lng: 18.2348012 },
    { lat: 48.0472864, lng: 18.2349769 },
    { lat: 48.0475483, lng: 18.235172 },
    { lat: 48.0477195, lng: 18.2353788 },
    { lat: 48.0482675, lng: 18.2357655 },
    { lat: 48.0484447, lng: 18.2361923 },
    { lat: 48.0485095, lng: 18.23624 },
    { lat: 48.0486425, lng: 18.2361982 },
    { lat: 48.0487429, lng: 18.2360615 },
    { lat: 48.048977, lng: 18.2359714 },
    { lat: 48.0491237, lng: 18.2361492 },
    { lat: 48.0492423, lng: 18.2368696 },
    { lat: 48.0493409, lng: 18.2371305 },
    { lat: 48.049741, lng: 18.2373756 },
    { lat: 48.0503192, lng: 18.2374428 },
    { lat: 48.0504884, lng: 18.2376171 },
    { lat: 48.0505817, lng: 18.2378058 },
    { lat: 48.0505842, lng: 18.2380456 },
    { lat: 48.0505603, lng: 18.2382529 },
    { lat: 48.05036, lng: 18.2386934 },
    { lat: 48.0502481, lng: 18.2391786 },
    { lat: 48.0502922, lng: 18.2394303 },
    { lat: 48.050381, lng: 18.2395829 },
    { lat: 48.0504427, lng: 18.2395954 },
    { lat: 48.0505704, lng: 18.2395991 },
    { lat: 48.0506963, lng: 18.2394915 },
    { lat: 48.0507579, lng: 18.23929 },
    { lat: 48.0507247, lng: 18.2389888 },
    { lat: 48.0509053, lng: 18.2386207 },
    { lat: 48.0510508, lng: 18.238598 },
    { lat: 48.0513799, lng: 18.2388674 },
    { lat: 48.0514004, lng: 18.239143 },
    { lat: 48.0512337, lng: 18.2395961 },
    { lat: 48.0513774, lng: 18.2401877 },
    { lat: 48.0512783, lng: 18.2403835 },
    { lat: 48.05126, lng: 18.240594 },
    { lat: 48.0513974, lng: 18.2409004 },
    { lat: 48.0515971, lng: 18.2411755 },
    { lat: 48.0518705, lng: 18.241239 },
    { lat: 48.0519947, lng: 18.2412036 },
    { lat: 48.052187, lng: 18.2410325 },
    { lat: 48.0522205, lng: 18.2408511 },
    { lat: 48.0520382, lng: 18.2406478 },
    { lat: 48.052022, lng: 18.2405628 },
    { lat: 48.052055, lng: 18.2400793 },
    { lat: 48.052132, lng: 18.2398906 },
    { lat: 48.0522691, lng: 18.2397632 },
    { lat: 48.0525921, lng: 18.2394938 },
    { lat: 48.0527067, lng: 18.239485 },
    { lat: 48.0528169, lng: 18.2395587 },
    { lat: 48.0529036, lng: 18.2397111 },
    { lat: 48.0529071, lng: 18.2398655 },
    { lat: 48.0528077, lng: 18.2401961 },
    { lat: 48.0528495, lng: 18.2405501 },
    { lat: 48.0529296, lng: 18.2406667 },
    { lat: 48.0537677, lng: 18.2409918 },
    { lat: 48.0541835, lng: 18.2412762 },
    { lat: 48.0542037, lng: 18.2413515 },
    { lat: 48.0541191, lng: 18.2418787 },
    { lat: 48.0541924, lng: 18.2420707 },
    { lat: 48.0544936, lng: 18.2424787 },
    { lat: 48.0549156, lng: 18.2427926 },
    { lat: 48.0548906, lng: 18.2434137 },
    { lat: 48.0549593, lng: 18.2439547 },
    { lat: 48.0549215, lng: 18.244435 },
    { lat: 48.0551696, lng: 18.2448605 },
    { lat: 48.0553566, lng: 18.2448925 },
    { lat: 48.0556989, lng: 18.2446298 },
    { lat: 48.0559886, lng: 18.2446013 },
    { lat: 48.0562593, lng: 18.244389 },
    { lat: 48.056448, lng: 18.2443524 },
    { lat: 48.0565143, lng: 18.2444433 },
    { lat: 48.0563384, lng: 18.2451298 },
    { lat: 48.0563571, lng: 18.2461935 },
    { lat: 48.0564698, lng: 18.246543 },
    { lat: 48.0566205, lng: 18.2475881 },
    { lat: 48.0568651, lng: 18.2480105 },
    { lat: 48.0569068, lng: 18.2479537 },
    { lat: 48.056884, lng: 18.2447477 },
    { lat: 48.0570917, lng: 18.2434827 },
    { lat: 48.0573174, lng: 18.2414954 },
    { lat: 48.057404, lng: 18.2400099 },
    { lat: 48.0577574, lng: 18.2365266 },
    { lat: 48.0580194, lng: 18.2352446 },
    { lat: 48.0595589, lng: 18.2356602 },
    { lat: 48.0604139, lng: 18.2360236 },
    { lat: 48.0610097, lng: 18.2360051 },
    { lat: 48.0616703, lng: 18.2358945 },
    { lat: 48.0636056, lng: 18.2347293 },
    { lat: 48.0646153, lng: 18.2342583 },
    { lat: 48.0649439, lng: 18.2344773 },
    { lat: 48.0663604, lng: 18.2343655 },
    { lat: 48.0671219, lng: 18.2348321 },
    { lat: 48.0670575, lng: 18.2340502 },
    { lat: 48.0670144, lng: 18.2322549 },
    { lat: 48.0667936, lng: 18.2312684 },
    { lat: 48.0674046, lng: 18.2298159 },
    { lat: 48.0660368, lng: 18.2288794 },
    { lat: 48.0652923, lng: 18.2280661 },
    { lat: 48.0647145, lng: 18.2272489 },
    { lat: 48.0643542, lng: 18.2265451 },
    { lat: 48.0634378, lng: 18.224326 },
    { lat: 48.0640792, lng: 18.2236139 },
    { lat: 48.0653023, lng: 18.222562 },
    { lat: 48.0650913, lng: 18.2214533 },
    { lat: 48.0637014, lng: 18.21971 },
    { lat: 48.0617848, lng: 18.216595 },
    { lat: 48.0609382, lng: 18.215503 },
    { lat: 48.0602926, lng: 18.2147971 },
    { lat: 48.0594325, lng: 18.2136692 },
    { lat: 48.0585296, lng: 18.2126171 },
    { lat: 48.0588229, lng: 18.2113548 },
    { lat: 48.0595431, lng: 18.2088786 },
    { lat: 48.0591357, lng: 18.2082153 },
    { lat: 48.0589669, lng: 18.2078339 },
    { lat: 48.0587525, lng: 18.2071562 },
    { lat: 48.0585595, lng: 18.2068846 },
    { lat: 48.0584216, lng: 18.2068432 },
    { lat: 48.0582775, lng: 18.2069271 },
    { lat: 48.0579485, lng: 18.2074208 },
    { lat: 48.0578574, lng: 18.207464 },
    { lat: 48.0575188, lng: 18.2073061 },
    { lat: 48.0573991, lng: 18.2070331 },
    { lat: 48.0573313, lng: 18.2066648 },
    { lat: 48.0573239, lng: 18.2065166 },
    { lat: 48.0574405, lng: 18.2057696 },
    { lat: 48.0578893, lng: 18.2051598 },
    { lat: 48.0579286, lng: 18.2043646 },
    { lat: 48.0579955, lng: 18.2039055 },
    { lat: 48.0580772, lng: 18.203697 },
    { lat: 48.0584756, lng: 18.2031767 },
    { lat: 48.0585122, lng: 18.2029518 },
    { lat: 48.0584628, lng: 18.2027049 },
    { lat: 48.0581745, lng: 18.2023868 },
    { lat: 48.0581729, lng: 18.2010808 },
    { lat: 48.0580738, lng: 18.1983322 },
    { lat: 48.0578888, lng: 18.1945785 },
  ],
  Bruty: [
    { lat: 47.9163083, lng: 18.6015593 },
    { lat: 47.9164544, lng: 18.6015905 },
    { lat: 47.9169618, lng: 18.6005159 },
    { lat: 47.9173615, lng: 18.5994724 },
    { lat: 47.9178232, lng: 18.5992166 },
    { lat: 47.9178917, lng: 18.5977287 },
    { lat: 47.9178664, lng: 18.5966906 },
    { lat: 47.9188056, lng: 18.5944665 },
    { lat: 47.9200575, lng: 18.5925323 },
    { lat: 47.9208795, lng: 18.5931628 },
    { lat: 47.9220475, lng: 18.5939316 },
    { lat: 47.9244871, lng: 18.5949363 },
    { lat: 47.9251832, lng: 18.5951271 },
    { lat: 47.9261217, lng: 18.5941381 },
    { lat: 47.9287184, lng: 18.5958483 },
    { lat: 47.929589, lng: 18.5944662 },
    { lat: 47.9300892, lng: 18.5946178 },
    { lat: 47.9317985, lng: 18.595492 },
    { lat: 47.9352876, lng: 18.5975924 },
    { lat: 47.9359373, lng: 18.5981688 },
    { lat: 47.9363544, lng: 18.5984336 },
    { lat: 47.936682, lng: 18.598733 },
    { lat: 47.937304, lng: 18.597049 },
    { lat: 47.937872, lng: 18.596015 },
    { lat: 47.938258, lng: 18.595306 },
    { lat: 47.938827, lng: 18.594275 },
    { lat: 47.940021, lng: 18.591176 },
    { lat: 47.940227, lng: 18.590491 },
    { lat: 47.940604, lng: 18.589284 },
    { lat: 47.941126, lng: 18.587578 },
    { lat: 47.941543, lng: 18.585819 },
    { lat: 47.941968, lng: 18.584003 },
    { lat: 47.942178, lng: 18.583097 },
    { lat: 47.942303, lng: 18.582244 },
    { lat: 47.942712, lng: 18.579388 },
    { lat: 47.943022, lng: 18.576595 },
    { lat: 47.943043, lng: 18.576251 },
    { lat: 47.943089, lng: 18.575363 },
    { lat: 47.943094, lng: 18.575282 },
    { lat: 47.943095, lng: 18.575175 },
    { lat: 47.943096, lng: 18.575034 },
    { lat: 47.943175, lng: 18.572187 },
    { lat: 47.943123, lng: 18.570958 },
    { lat: 47.943121, lng: 18.570872 },
    { lat: 47.943128, lng: 18.568704 },
    { lat: 47.943277, lng: 18.566089 },
    { lat: 47.943337, lng: 18.565092 },
    { lat: 47.943385, lng: 18.564225 },
    { lat: 47.943305, lng: 18.561892 },
    { lat: 47.94337, lng: 18.561664 },
    { lat: 47.943722, lng: 18.560402 },
    { lat: 47.943809, lng: 18.560202 },
    { lat: 47.943908, lng: 18.559959 },
    { lat: 47.94409, lng: 18.559501 },
    { lat: 47.944225, lng: 18.559179 },
    { lat: 47.94444, lng: 18.558879 },
    { lat: 47.944923, lng: 18.558197 },
    { lat: 47.945085, lng: 18.557966 },
    { lat: 47.945408, lng: 18.557495 },
    { lat: 47.945537, lng: 18.557339 },
    { lat: 47.945978, lng: 18.556781 },
    { lat: 47.946166, lng: 18.556507 },
    { lat: 47.946251, lng: 18.556379 },
    { lat: 47.946446, lng: 18.556091 },
    { lat: 47.946688, lng: 18.555714 },
    { lat: 47.946659, lng: 18.55566 },
    { lat: 47.946121, lng: 18.554683 },
    { lat: 47.945478, lng: 18.553498 },
    { lat: 47.945376, lng: 18.553316 },
    { lat: 47.945363, lng: 18.553299 },
    { lat: 47.945297, lng: 18.553198 },
    { lat: 47.945282, lng: 18.553176 },
    { lat: 47.945065, lng: 18.552848 },
    { lat: 47.944856, lng: 18.552528 },
    { lat: 47.94434, lng: 18.551753 },
    { lat: 47.943375, lng: 18.550267 },
    { lat: 47.943161, lng: 18.549944 },
    { lat: 47.9428676, lng: 18.549497 },
    { lat: 47.942536, lng: 18.548992 },
    { lat: 47.942388, lng: 18.548763 },
    { lat: 47.94181, lng: 18.547876 },
    { lat: 47.941726, lng: 18.54775 },
    { lat: 47.941677, lng: 18.547671 },
    { lat: 47.941222, lng: 18.546981 },
    { lat: 47.940872, lng: 18.546453 },
    { lat: 47.940639, lng: 18.546095 },
    { lat: 47.940211, lng: 18.54545 },
    { lat: 47.939793, lng: 18.544806 },
    { lat: 47.939706, lng: 18.544671 },
    { lat: 47.939009, lng: 18.544043 },
    { lat: 47.93828, lng: 18.543396 },
    { lat: 47.937909, lng: 18.543062 },
    { lat: 47.937592, lng: 18.542784 },
    { lat: 47.937087, lng: 18.542334 },
    { lat: 47.937002, lng: 18.542262 },
    { lat: 47.936674, lng: 18.541962 },
    { lat: 47.936427, lng: 18.541751 },
    { lat: 47.936315, lng: 18.54165 },
    { lat: 47.93604, lng: 18.541419 },
    { lat: 47.935588, lng: 18.541003 },
    { lat: 47.935364, lng: 18.540806 },
    { lat: 47.935189, lng: 18.540645 },
    { lat: 47.933946, lng: 18.539518 },
    { lat: 47.933776, lng: 18.539359 },
    { lat: 47.933633, lng: 18.539232 },
    { lat: 47.933266, lng: 18.538913 },
    { lat: 47.933179, lng: 18.538835 },
    { lat: 47.932089, lng: 18.537886 },
    { lat: 47.932017, lng: 18.537815 },
    { lat: 47.9290032, lng: 18.5446185 },
    { lat: 47.927017, lng: 18.5483161 },
    { lat: 47.9240076, lng: 18.5546242 },
    { lat: 47.9235835, lng: 18.5548892 },
    { lat: 47.9230794, lng: 18.5544845 },
    { lat: 47.9221981, lng: 18.5541101 },
    { lat: 47.921583, lng: 18.5541646 },
    { lat: 47.9206514, lng: 18.5551686 },
    { lat: 47.919104, lng: 18.5543763 },
    { lat: 47.9178546, lng: 18.553201 },
    { lat: 47.9159692, lng: 18.5517406 },
    { lat: 47.912436, lng: 18.5483313 },
    { lat: 47.9107163, lng: 18.546853 },
    { lat: 47.909181, lng: 18.5491936 },
    { lat: 47.9078837, lng: 18.5485413 },
    { lat: 47.9065847, lng: 18.5481212 },
    { lat: 47.9051662, lng: 18.5494961 },
    { lat: 47.9040873, lng: 18.5505287 },
    { lat: 47.9025302, lng: 18.5507617 },
    { lat: 47.8993798, lng: 18.5532324 },
    { lat: 47.8969061, lng: 18.5582961 },
    { lat: 47.8960437, lng: 18.5599405 },
    { lat: 47.8948982, lng: 18.5607661 },
    { lat: 47.8943873, lng: 18.5614018 },
    { lat: 47.894087, lng: 18.5619277 },
    { lat: 47.8935765, lng: 18.5630201 },
    { lat: 47.8925324, lng: 18.5648862 },
    { lat: 47.8918784, lng: 18.5661978 },
    { lat: 47.8909139, lng: 18.5686121 },
    { lat: 47.8893171, lng: 18.5720944 },
    { lat: 47.8863292, lng: 18.5765205 },
    { lat: 47.8862939, lng: 18.5775371 },
    { lat: 47.885515, lng: 18.5774011 },
    { lat: 47.8827118, lng: 18.5795502 },
    { lat: 47.8809691, lng: 18.5778934 },
    { lat: 47.8809548, lng: 18.5779991 },
    { lat: 47.8796689, lng: 18.5807448 },
    { lat: 47.8801378, lng: 18.5810658 },
    { lat: 47.8809242, lng: 18.5837361 },
    { lat: 47.8812438, lng: 18.5858343 },
    { lat: 47.8814895, lng: 18.5868107 },
    { lat: 47.8819232, lng: 18.587459 },
    { lat: 47.8819524, lng: 18.5875169 },
    { lat: 47.882837, lng: 18.5892043 },
    { lat: 47.8863353, lng: 18.5918613 },
    { lat: 47.8880487, lng: 18.5931164 },
    { lat: 47.8905126, lng: 18.5939013 },
    { lat: 47.8956445, lng: 18.5908164 },
    { lat: 47.8987337, lng: 18.5909774 },
    { lat: 47.9023763, lng: 18.5906978 },
    { lat: 47.9042242, lng: 18.5919124 },
    { lat: 47.9066466, lng: 18.5937519 },
    { lat: 47.9112598, lng: 18.5981278 },
    { lat: 47.9123895, lng: 18.5989188 },
    { lat: 47.9150457, lng: 18.6009953 },
    { lat: 47.9157061, lng: 18.6014359 },
    { lat: 47.9163083, lng: 18.6015593 },
  ],
  Komoča: [
    { lat: 47.955956, lng: 18.015642 },
    { lat: 47.955771, lng: 18.015235 },
    { lat: 47.955308, lng: 18.014251 },
    { lat: 47.955039, lng: 18.013632 },
    { lat: 47.954808, lng: 18.013123 },
    { lat: 47.954626, lng: 18.012818 },
    { lat: 47.954417, lng: 18.012515 },
    { lat: 47.954273, lng: 18.012323 },
    { lat: 47.95412, lng: 18.012151 },
    { lat: 47.953916, lng: 18.011961 },
    { lat: 47.953684, lng: 18.011775 },
    { lat: 47.953606, lng: 18.011744 },
    { lat: 47.953487, lng: 18.011661 },
    { lat: 47.953314, lng: 18.011571 },
    { lat: 47.953241, lng: 18.011518 },
    { lat: 47.95317, lng: 18.011495 },
    { lat: 47.953093, lng: 18.011454 },
    { lat: 47.95293, lng: 18.011387 },
    { lat: 47.952736, lng: 18.011363 },
    { lat: 47.952652, lng: 18.011333 },
    { lat: 47.952405, lng: 18.011321 },
    { lat: 47.952227, lng: 18.011344 },
    { lat: 47.951736, lng: 18.011371 },
    { lat: 47.951302, lng: 18.011435 },
    { lat: 47.95113, lng: 18.011444 },
    { lat: 47.950994, lng: 18.01147 },
    { lat: 47.950846, lng: 18.011485 },
    { lat: 47.950732, lng: 18.01151 },
    { lat: 47.95065, lng: 18.011518 },
    { lat: 47.950565, lng: 18.011545 },
    { lat: 47.950443, lng: 18.011568 },
    { lat: 47.950329, lng: 18.011579 },
    { lat: 47.950114, lng: 18.011613 },
    { lat: 47.950058, lng: 18.011644 },
    { lat: 47.949936, lng: 18.011674 },
    { lat: 47.949833, lng: 18.011692 },
    { lat: 47.949731, lng: 18.011732 },
    { lat: 47.949677, lng: 18.011766 },
    { lat: 47.949561, lng: 18.011775 },
    { lat: 47.949492, lng: 18.01181 },
    { lat: 47.949442, lng: 18.011849 },
    { lat: 47.949375, lng: 18.011861 },
    { lat: 47.94897, lng: 18.012036 },
    { lat: 47.948782, lng: 18.012145 },
    { lat: 47.948693, lng: 18.012168 },
    { lat: 47.948461, lng: 18.012337 },
    { lat: 47.948236, lng: 18.012487 },
    { lat: 47.947956, lng: 18.012605 },
    { lat: 47.947836, lng: 18.012608 },
    { lat: 47.947439, lng: 18.01275 },
    { lat: 47.946918, lng: 18.012947 },
    { lat: 47.946711, lng: 18.012992 },
    { lat: 47.946336, lng: 18.013198 },
    { lat: 47.946138, lng: 18.013331 },
    { lat: 47.946035, lng: 18.013352 },
    { lat: 47.945608, lng: 18.01331 },
    { lat: 47.945238, lng: 18.013312 },
    { lat: 47.944469, lng: 18.013149 },
    { lat: 47.944083, lng: 18.012947 },
    { lat: 47.943339, lng: 18.012392 },
    { lat: 47.943052, lng: 18.012139 },
    { lat: 47.942483, lng: 18.011501 },
    { lat: 47.942499, lng: 18.012046 },
    { lat: 47.942514, lng: 18.012531 },
    { lat: 47.942516, lng: 18.012584 },
    { lat: 47.942515, lng: 18.012611 },
    { lat: 47.942516, lng: 18.012658 },
    { lat: 47.942515, lng: 18.012745 },
    { lat: 47.942515, lng: 18.012796 },
    { lat: 47.942509, lng: 18.013024 },
    { lat: 47.942508, lng: 18.013072 },
    { lat: 47.942511, lng: 18.013105 },
    { lat: 47.942514, lng: 18.013161 },
    { lat: 47.942517, lng: 18.013194 },
    { lat: 47.942519, lng: 18.013218 },
    { lat: 47.942521, lng: 18.013231 },
    { lat: 47.942533, lng: 18.013318 },
    { lat: 47.942561, lng: 18.013536 },
    { lat: 47.942564, lng: 18.013552 },
    { lat: 47.942568, lng: 18.013584 },
    { lat: 47.942569, lng: 18.013591 },
    { lat: 47.94258, lng: 18.013668 },
    { lat: 47.942588, lng: 18.013727 },
    { lat: 47.942588, lng: 18.013736 },
    { lat: 47.942596, lng: 18.013787 },
    { lat: 47.942597, lng: 18.013802 },
    { lat: 47.942611, lng: 18.013905 },
    { lat: 47.942613, lng: 18.013927 },
    { lat: 47.942623, lng: 18.013996 },
    { lat: 47.942623, lng: 18.014006 },
    { lat: 47.942628, lng: 18.01404 },
    { lat: 47.942631, lng: 18.014064 },
    { lat: 47.942641, lng: 18.014142 },
    { lat: 47.942644, lng: 18.014152 },
    { lat: 47.94266, lng: 18.014243 },
    { lat: 47.942707, lng: 18.014498 },
    { lat: 47.942709, lng: 18.014507 },
    { lat: 47.942712, lng: 18.014515 },
    { lat: 47.942775, lng: 18.014757 },
    { lat: 47.942779, lng: 18.014776 },
    { lat: 47.942781, lng: 18.014781 },
    { lat: 47.942846, lng: 18.015039 },
    { lat: 47.942859, lng: 18.015092 },
    { lat: 47.942903, lng: 18.015265 },
    { lat: 47.942967, lng: 18.015513 },
    { lat: 47.943088, lng: 18.01599 },
    { lat: 47.94309, lng: 18.015998 },
    { lat: 47.943121, lng: 18.016117 },
    { lat: 47.943126, lng: 18.016139 },
    { lat: 47.943136, lng: 18.016165 },
    { lat: 47.94325, lng: 18.016476 },
    { lat: 47.943369, lng: 18.016794 },
    { lat: 47.943371, lng: 18.016802 },
    { lat: 47.943535, lng: 18.017243 },
    { lat: 47.943545, lng: 18.017272 },
    { lat: 47.943564, lng: 18.017315 },
    { lat: 47.943634, lng: 18.017473 },
    { lat: 47.943677, lng: 18.017572 },
    { lat: 47.94378, lng: 18.017804 },
    { lat: 47.943794, lng: 18.017833 },
    { lat: 47.94387, lng: 18.017957 },
    { lat: 47.944001, lng: 18.018169 },
    { lat: 47.944031, lng: 18.018331 },
    { lat: 47.944064, lng: 18.018523 },
    { lat: 47.944073, lng: 18.018574 },
    { lat: 47.94408, lng: 18.018597 },
    { lat: 47.944083, lng: 18.018631 },
    { lat: 47.944096, lng: 18.018762 },
    { lat: 47.944095, lng: 18.018783 },
    { lat: 47.944094, lng: 18.019129 },
    { lat: 47.944093, lng: 18.019184 },
    { lat: 47.944096, lng: 18.01923 },
    { lat: 47.944138, lng: 18.01985 },
    { lat: 47.944151, lng: 18.019877 },
    { lat: 47.944168, lng: 18.019915 },
    { lat: 47.944218, lng: 18.020026 },
    { lat: 47.944233, lng: 18.020055 },
    { lat: 47.944239, lng: 18.020071 },
    { lat: 47.944236, lng: 18.020094 },
    { lat: 47.944229, lng: 18.020158 },
    { lat: 47.944214, lng: 18.020284 },
    { lat: 47.944213, lng: 18.020289 },
    { lat: 47.944215, lng: 18.020312 },
    { lat: 47.944221, lng: 18.020387 },
    { lat: 47.944229, lng: 18.020486 },
    { lat: 47.944231, lng: 18.020516 },
    { lat: 47.94423, lng: 18.020528 },
    { lat: 47.944219, lng: 18.020829 },
    { lat: 47.944202, lng: 18.021255 },
    { lat: 47.944191, lng: 18.021511 },
    { lat: 47.944191, lng: 18.021533 },
    { lat: 47.94416, lng: 18.02204 },
    { lat: 47.944185, lng: 18.022191 },
    { lat: 47.944186, lng: 18.022201 },
    { lat: 47.944244, lng: 18.022262 },
    { lat: 47.944271, lng: 18.022297 },
    { lat: 47.944277, lng: 18.022306 },
    { lat: 47.944369, lng: 18.022483 },
    { lat: 47.944392, lng: 18.022616 },
    { lat: 47.944391, lng: 18.022652 },
    { lat: 47.944374, lng: 18.022997 },
    { lat: 47.944358, lng: 18.02336 },
    { lat: 47.944343, lng: 18.023675 },
    { lat: 47.944334, lng: 18.023837 },
    { lat: 47.944318, lng: 18.024109 },
    { lat: 47.944339, lng: 18.024177 },
    { lat: 47.944342, lng: 18.024189 },
    { lat: 47.944576, lng: 18.024432 },
    { lat: 47.944604, lng: 18.024461 },
    { lat: 47.944662, lng: 18.024556 },
    { lat: 47.944684, lng: 18.024593 },
    { lat: 47.944688, lng: 18.0246 },
    { lat: 47.944703, lng: 18.024654 },
    { lat: 47.944705, lng: 18.024666 },
    { lat: 47.944719, lng: 18.024761 },
    { lat: 47.944725, lng: 18.024809 },
    { lat: 47.94475, lng: 18.0249 },
    { lat: 47.944873, lng: 18.025372 },
    { lat: 47.945014, lng: 18.025912 },
    { lat: 47.945021, lng: 18.025938 },
    { lat: 47.945045, lng: 18.026065 },
    { lat: 47.945102, lng: 18.026353 },
    { lat: 47.945107, lng: 18.026373 },
    { lat: 47.945139, lng: 18.026713 },
    { lat: 47.94514, lng: 18.026743 },
    { lat: 47.945152, lng: 18.02704 },
    { lat: 47.945157, lng: 18.027128 },
    { lat: 47.945169, lng: 18.027293 },
    { lat: 47.945212, lng: 18.027897 },
    { lat: 47.945237, lng: 18.028249 },
    { lat: 47.94524, lng: 18.028356 },
    { lat: 47.945243, lng: 18.028491 },
    { lat: 47.945244, lng: 18.02851 },
    { lat: 47.945263, lng: 18.028909 },
    { lat: 47.945288, lng: 18.029391 },
    { lat: 47.945302, lng: 18.029688 },
    { lat: 47.945305, lng: 18.029714 },
    { lat: 47.945398, lng: 18.03053 },
    { lat: 47.945439, lng: 18.030892 },
    { lat: 47.945461, lng: 18.03108 },
    { lat: 47.945437, lng: 18.0312 },
    { lat: 47.945459, lng: 18.03139 },
    { lat: 47.945455, lng: 18.031711 },
    { lat: 47.945462, lng: 18.031855 },
    { lat: 47.945466, lng: 18.031932 },
    { lat: 47.945465, lng: 18.031957 },
    { lat: 47.945465, lng: 18.031992 },
    { lat: 47.945463, lng: 18.032053 },
    { lat: 47.945461, lng: 18.032117 },
    { lat: 47.94546, lng: 18.032177 },
    { lat: 47.945455, lng: 18.032347 },
    { lat: 47.945453, lng: 18.032415 },
    { lat: 47.945452, lng: 18.032476 },
    { lat: 47.945448, lng: 18.032586 },
    { lat: 47.945447, lng: 18.032653 },
    { lat: 47.945444, lng: 18.032715 },
    { lat: 47.945439, lng: 18.032806 },
    { lat: 47.945437, lng: 18.032854 },
    { lat: 47.945431, lng: 18.032975 },
    { lat: 47.945437, lng: 18.033099 },
    { lat: 47.945444, lng: 18.033219 },
    { lat: 47.945454, lng: 18.033323 },
    { lat: 47.94548, lng: 18.0336 },
    { lat: 47.945492, lng: 18.033728 },
    { lat: 47.945498, lng: 18.033791 },
    { lat: 47.945499, lng: 18.033814 },
    { lat: 47.945497, lng: 18.03386 },
    { lat: 47.94549, lng: 18.033981 },
    { lat: 47.945486, lng: 18.034055 },
    { lat: 47.945483, lng: 18.034099 },
    { lat: 47.945473, lng: 18.034272 },
    { lat: 47.945485, lng: 18.034489 },
    { lat: 47.945496, lng: 18.034567 },
    { lat: 47.945532, lng: 18.0348 },
    { lat: 47.945543, lng: 18.034871 },
    { lat: 47.945549, lng: 18.034943 },
    { lat: 47.94556, lng: 18.035073 },
    { lat: 47.945572, lng: 18.03522 },
    { lat: 47.945585, lng: 18.035375 },
    { lat: 47.9456, lng: 18.035552 },
    { lat: 47.945606, lng: 18.035632 },
    { lat: 47.945614, lng: 18.035727 },
    { lat: 47.945622, lng: 18.035823 },
    { lat: 47.945645, lng: 18.035928 },
    { lat: 47.945668, lng: 18.03603 },
    { lat: 47.945691, lng: 18.036134 },
    { lat: 47.945748, lng: 18.03639 },
    { lat: 47.945787, lng: 18.036631 },
    { lat: 47.945805, lng: 18.03675 },
    { lat: 47.945825, lng: 18.036876 },
    { lat: 47.94584, lng: 18.036976 },
    { lat: 47.945857, lng: 18.037098 },
    { lat: 47.945891, lng: 18.037345 },
    { lat: 47.945919, lng: 18.037542 },
    { lat: 47.945922, lng: 18.037572 },
    { lat: 47.945947, lng: 18.037824 },
    { lat: 47.945962, lng: 18.037978 },
    { lat: 47.946004, lng: 18.038236 },
    { lat: 47.946028, lng: 18.038424 },
    { lat: 47.946053, lng: 18.038622 },
    { lat: 47.946061, lng: 18.038812 },
    { lat: 47.946059, lng: 18.039007 },
    { lat: 47.946075, lng: 18.039168 },
    { lat: 47.946079, lng: 18.039217 },
    { lat: 47.946086, lng: 18.039303 },
    { lat: 47.946096, lng: 18.039414 },
    { lat: 47.946098, lng: 18.039427 },
    { lat: 47.946103, lng: 18.039487 },
    { lat: 47.946125, lng: 18.039715 },
    { lat: 47.946148, lng: 18.039984 },
    { lat: 47.946184, lng: 18.040254 },
    { lat: 47.946204, lng: 18.040542 },
    { lat: 47.946221, lng: 18.040762 },
    { lat: 47.946229, lng: 18.040994 },
    { lat: 47.946228, lng: 18.041091 },
    { lat: 47.946232, lng: 18.04131 },
    { lat: 47.946234, lng: 18.041421 },
    { lat: 47.946238, lng: 18.041645 },
    { lat: 47.946242, lng: 18.041862 },
    { lat: 47.946246, lng: 18.042093 },
    { lat: 47.94625, lng: 18.042311 },
    { lat: 47.946252, lng: 18.042438 },
    { lat: 47.946255, lng: 18.042552 },
    { lat: 47.946252, lng: 18.042681 },
    { lat: 47.946248, lng: 18.042899 },
    { lat: 47.946237, lng: 18.043017 },
    { lat: 47.946227, lng: 18.043137 },
    { lat: 47.946216, lng: 18.043265 },
    { lat: 47.946227, lng: 18.043522 },
    { lat: 47.946237, lng: 18.043748 },
    { lat: 47.94624, lng: 18.043995 },
    { lat: 47.946243, lng: 18.044188 },
    { lat: 47.946248, lng: 18.044349 },
    { lat: 47.94625, lng: 18.044448 },
    { lat: 47.946256, lng: 18.044599 },
    { lat: 47.946259, lng: 18.044709 },
    { lat: 47.946264, lng: 18.044818 },
    { lat: 47.946263, lng: 18.044829 },
    { lat: 47.946255, lng: 18.044935 },
    { lat: 47.946249, lng: 18.04502 },
    { lat: 47.946247, lng: 18.04503 },
    { lat: 47.946246, lng: 18.045041 },
    { lat: 47.946249, lng: 18.04511 },
    { lat: 47.94625, lng: 18.045117 },
    { lat: 47.946253, lng: 18.045191 },
    { lat: 47.946265, lng: 18.045441 },
    { lat: 47.946269, lng: 18.045514 },
    { lat: 47.946281, lng: 18.045755 },
    { lat: 47.946291, lng: 18.045955 },
    { lat: 47.946318, lng: 18.046464 },
    { lat: 47.94633, lng: 18.046683 },
    { lat: 47.946354, lng: 18.047102 },
    { lat: 47.946371, lng: 18.04741 },
    { lat: 47.946389, lng: 18.047723 },
    { lat: 47.946401, lng: 18.047932 },
    { lat: 47.946412, lng: 18.048128 },
    { lat: 47.94644, lng: 18.048475 },
    { lat: 47.946467, lng: 18.048966 },
    { lat: 47.946473, lng: 18.049065 },
    { lat: 47.94649, lng: 18.049265 },
    { lat: 47.946535, lng: 18.04982 },
    { lat: 47.946551, lng: 18.050038 },
    { lat: 47.946577, lng: 18.050389 },
    { lat: 47.946604, lng: 18.050763 },
    { lat: 47.946621, lng: 18.051152 },
    { lat: 47.946623, lng: 18.051475 },
    { lat: 47.946623, lng: 18.051522 },
    { lat: 47.946669, lng: 18.051792 },
    { lat: 47.946712, lng: 18.052049 },
    { lat: 47.946773, lng: 18.052405 },
    { lat: 47.946841, lng: 18.052802 },
    { lat: 47.946853, lng: 18.05282 },
    { lat: 47.94699, lng: 18.053037 },
    { lat: 47.947072, lng: 18.053152 },
    { lat: 47.94724, lng: 18.053391 },
    { lat: 47.947263, lng: 18.053424 },
    { lat: 47.947671, lng: 18.054007 },
    { lat: 47.947862, lng: 18.054281 },
    { lat: 47.947873, lng: 18.054288 },
    { lat: 47.948259, lng: 18.054511 },
    { lat: 47.948266, lng: 18.054512 },
    { lat: 47.948553, lng: 18.054565 },
    { lat: 47.948582, lng: 18.05457 },
    { lat: 47.948903, lng: 18.0546 },
    { lat: 47.94893, lng: 18.054593 },
    { lat: 47.949292, lng: 18.0545 },
    { lat: 47.949469, lng: 18.054455 },
    { lat: 47.949536, lng: 18.054432 },
    { lat: 47.949804, lng: 18.054344 },
    { lat: 47.94998, lng: 18.054286 },
    { lat: 47.949991, lng: 18.054279 },
    { lat: 47.950121, lng: 18.054199 },
    { lat: 47.950279, lng: 18.054092 },
    { lat: 47.950326, lng: 18.05406 },
    { lat: 47.950351, lng: 18.054055 },
    { lat: 47.950424, lng: 18.054042 },
    { lat: 47.950447, lng: 18.054036 },
    { lat: 47.951106, lng: 18.053848 },
    { lat: 47.951133, lng: 18.05384 },
    { lat: 47.951148, lng: 18.053835 },
    { lat: 47.951319, lng: 18.053785 },
    { lat: 47.951348, lng: 18.053776 },
    { lat: 47.951354, lng: 18.053783 },
    { lat: 47.951486, lng: 18.053935 },
    { lat: 47.951578, lng: 18.054039 },
    { lat: 47.951611, lng: 18.054077 },
    { lat: 47.951784, lng: 18.054226 },
    { lat: 47.951827, lng: 18.05427 },
    { lat: 47.952164, lng: 18.054618 },
    { lat: 47.952203, lng: 18.054644 },
    { lat: 47.952309, lng: 18.054716 },
    { lat: 47.952316, lng: 18.054728 },
    { lat: 47.95237, lng: 18.054822 },
    { lat: 47.952379, lng: 18.05485 },
    { lat: 47.952445, lng: 18.055034 },
    { lat: 47.952451, lng: 18.055059 },
    { lat: 47.952513, lng: 18.055353 },
    { lat: 47.952591, lng: 18.055772 },
    { lat: 47.952641, lng: 18.056036 },
    { lat: 47.952652, lng: 18.05615 },
    { lat: 47.952665, lng: 18.056266 },
    { lat: 47.952616, lng: 18.056858 },
    { lat: 47.952612, lng: 18.056916 },
    { lat: 47.952605, lng: 18.056974 },
    { lat: 47.952578, lng: 18.057209 },
    { lat: 47.952569, lng: 18.057281 },
    { lat: 47.952533, lng: 18.057598 },
    { lat: 47.952529, lng: 18.057611 },
    { lat: 47.952472, lng: 18.057804 },
    { lat: 47.952452, lng: 18.057871 },
    { lat: 47.952385, lng: 18.058087 },
    { lat: 47.952217, lng: 18.058632 },
    { lat: 47.952118, lng: 18.058948 },
    { lat: 47.951949, lng: 18.059519 },
    { lat: 47.951797, lng: 18.060026 },
    { lat: 47.95161, lng: 18.060661 },
    { lat: 47.951532, lng: 18.060919 },
    { lat: 47.951485, lng: 18.061077 },
    { lat: 47.951485, lng: 18.061078 },
    { lat: 47.951458, lng: 18.061298 },
    { lat: 47.951416, lng: 18.061626 },
    { lat: 47.951301, lng: 18.062536 },
    { lat: 47.95122, lng: 18.063178 },
    { lat: 47.951175, lng: 18.063537 },
    { lat: 47.951156, lng: 18.063684 },
    { lat: 47.951148, lng: 18.063727 },
    { lat: 47.951143, lng: 18.063756 },
    { lat: 47.951126, lng: 18.06384 },
    { lat: 47.95112, lng: 18.063872 },
    { lat: 47.951104, lng: 18.063959 },
    { lat: 47.950952, lng: 18.064838 },
    { lat: 47.950782, lng: 18.065375 },
    { lat: 47.950698, lng: 18.06564 },
    { lat: 47.950522, lng: 18.066197 },
    { lat: 47.950503, lng: 18.066259 },
    { lat: 47.950303, lng: 18.066679 },
    { lat: 47.950005, lng: 18.06731 },
    { lat: 47.94976, lng: 18.067827 },
    { lat: 47.949467, lng: 18.068443 },
    { lat: 47.948977, lng: 18.069685 },
    { lat: 47.948817, lng: 18.070088 },
    { lat: 47.947567, lng: 18.070232 },
    { lat: 47.947548, lng: 18.070234 },
    { lat: 47.947633, lng: 18.070519 },
    { lat: 47.947767, lng: 18.070955 },
    { lat: 47.948489, lng: 18.072189 },
    { lat: 47.949038, lng: 18.07233 },
    { lat: 47.949351, lng: 18.072336 },
    { lat: 47.949844, lng: 18.072345 },
    { lat: 47.949982, lng: 18.072355 },
    { lat: 47.950035, lng: 18.072359 },
    { lat: 47.950069, lng: 18.072362 },
    { lat: 47.950206, lng: 18.072375 },
    { lat: 47.9548443, lng: 18.0707236 },
    { lat: 47.9551316, lng: 18.0706786 },
    { lat: 47.9607863, lng: 18.0708404 },
    { lat: 47.9630018, lng: 18.0715609 },
    { lat: 47.9666254, lng: 18.0715974 },
    { lat: 47.9669891, lng: 18.0723953 },
    { lat: 47.9673123, lng: 18.0727636 },
    { lat: 47.9679057, lng: 18.0723828 },
    { lat: 47.9681548, lng: 18.0716728 },
    { lat: 47.9686771, lng: 18.0709679 },
    { lat: 47.9727081, lng: 18.0696553 },
    { lat: 47.9729634, lng: 18.0695258 },
    { lat: 47.9729577, lng: 18.0694501 },
    { lat: 47.9729148, lng: 18.06875 },
    { lat: 47.9730081, lng: 18.0674722 },
    { lat: 47.9729237, lng: 18.0669998 },
    { lat: 47.9726585, lng: 18.0666404 },
    { lat: 47.9723049, lng: 18.0656991 },
    { lat: 47.9722333, lng: 18.0655219 },
    { lat: 47.9721768, lng: 18.0653402 },
    { lat: 47.9720578, lng: 18.0650257 },
    { lat: 47.9718172, lng: 18.0643598 },
    { lat: 47.9712169, lng: 18.062601 },
    { lat: 47.9706088, lng: 18.0608485 },
    { lat: 47.9725279, lng: 18.0581009 },
    { lat: 47.9748025, lng: 18.0567471 },
    { lat: 47.9746588, lng: 18.05519 },
    { lat: 47.9743157, lng: 18.0537103 },
    { lat: 47.9753419, lng: 18.0528078 },
    { lat: 47.9764162, lng: 18.0514463 },
    { lat: 47.9771595, lng: 18.0511514 },
    { lat: 47.9777371, lng: 18.0510143 },
    { lat: 47.97821, lng: 18.0495587 },
    { lat: 47.9804852, lng: 18.0460812 },
    { lat: 47.9819987, lng: 18.0436583 },
    { lat: 47.9824348, lng: 18.0431391 },
    { lat: 47.9825076, lng: 18.0429766 },
    { lat: 47.9830105, lng: 18.041647 },
    { lat: 47.9832445, lng: 18.0407674 },
    { lat: 47.9831167, lng: 18.0393087 },
    { lat: 47.9836923, lng: 18.0386067 },
    { lat: 47.9840115, lng: 18.0385299 },
    { lat: 47.984355, lng: 18.0383258 },
    { lat: 47.9845507, lng: 18.037881 },
    { lat: 47.9842398, lng: 18.0375696 },
    { lat: 47.9840821, lng: 18.0375006 },
    { lat: 47.983851, lng: 18.0375066 },
    { lat: 47.9829039, lng: 18.0370848 },
    { lat: 47.9827131, lng: 18.0368216 },
    { lat: 47.9824371, lng: 18.0362086 },
    { lat: 47.9822385, lng: 18.0355639 },
    { lat: 47.9818057, lng: 18.0331992 },
    { lat: 47.9813504, lng: 18.031567 },
    { lat: 47.9812998, lng: 18.031195 },
    { lat: 47.9813314, lng: 18.0295338 },
    { lat: 47.9816772, lng: 18.0283722 },
    { lat: 47.982177, lng: 18.0270493 },
    { lat: 47.9826356, lng: 18.0255497 },
    { lat: 47.9813748, lng: 18.0247753 },
    { lat: 47.9790026, lng: 18.0237593 },
    { lat: 47.9779136, lng: 18.0232178 },
    { lat: 47.9751681, lng: 18.0214711 },
    { lat: 47.9740081, lng: 18.0208553 },
    { lat: 47.9733486, lng: 18.0206207 },
    { lat: 47.972402, lng: 18.018025 },
    { lat: 47.972387, lng: 18.018036 },
    { lat: 47.972081, lng: 18.018251 },
    { lat: 47.971362, lng: 18.018757 },
    { lat: 47.970985, lng: 18.019023 },
    { lat: 47.970472, lng: 18.019309 },
    { lat: 47.970202, lng: 18.01946 },
    { lat: 47.969846, lng: 18.019659 },
    { lat: 47.96982, lng: 18.019674 },
    { lat: 47.969806, lng: 18.019682 },
    { lat: 47.969569, lng: 18.019814 },
    { lat: 47.969362, lng: 18.019849 },
    { lat: 47.969064, lng: 18.019899 },
    { lat: 47.968251, lng: 18.020035 },
    { lat: 47.967272, lng: 18.020254 },
    { lat: 47.967204, lng: 18.020269 },
    { lat: 47.96715, lng: 18.020282 },
    { lat: 47.967141, lng: 18.020283 },
    { lat: 47.967005, lng: 18.020315 },
    { lat: 47.96699, lng: 18.020319 },
    { lat: 47.966835, lng: 18.020359 },
    { lat: 47.966736, lng: 18.020385 },
    { lat: 47.966604, lng: 18.020421 },
    { lat: 47.966589, lng: 18.020425 },
    { lat: 47.966575, lng: 18.020428 },
    { lat: 47.966524, lng: 18.020441 },
    { lat: 47.966395, lng: 18.020476 },
    { lat: 47.965629, lng: 18.02068 },
    { lat: 47.964848, lng: 18.021481 },
    { lat: 47.96399, lng: 18.022465 },
    { lat: 47.963218, lng: 18.023449 },
    { lat: 47.963203, lng: 18.02347 },
    { lat: 47.963135, lng: 18.023453 },
    { lat: 47.963116, lng: 18.023465 },
    { lat: 47.96309, lng: 18.023483 },
    { lat: 47.962818, lng: 18.023666 },
    { lat: 47.962722, lng: 18.02373 },
    { lat: 47.962656, lng: 18.023781 },
    { lat: 47.962551, lng: 18.023861 },
    { lat: 47.962529, lng: 18.023877 },
    { lat: 47.962498, lng: 18.023898 },
    { lat: 47.962474, lng: 18.023915 },
    { lat: 47.962401, lng: 18.023964 },
    { lat: 47.962309, lng: 18.024025 },
    { lat: 47.961907, lng: 18.024295 },
    { lat: 47.961852, lng: 18.024325 },
    { lat: 47.96173, lng: 18.024389 },
    { lat: 47.961348, lng: 18.024592 },
    { lat: 47.961282, lng: 18.024627 },
    { lat: 47.961207, lng: 18.024667 },
    { lat: 47.960908, lng: 18.0248 },
    { lat: 47.960891, lng: 18.024806 },
    { lat: 47.960689, lng: 18.024875 },
    { lat: 47.96066, lng: 18.024908 },
    { lat: 47.960467, lng: 18.024654 },
    { lat: 47.96045, lng: 18.02463 },
    { lat: 47.960326, lng: 18.024468 },
    { lat: 47.960308, lng: 18.024443 },
    { lat: 47.960254, lng: 18.024372 },
    { lat: 47.960226, lng: 18.024334 },
    { lat: 47.960149, lng: 18.024234 },
    { lat: 47.960119, lng: 18.024195 },
    { lat: 47.960054, lng: 18.02411 },
    { lat: 47.960012, lng: 18.024053 },
    { lat: 47.959934, lng: 18.023952 },
    { lat: 47.959879, lng: 18.023925 },
    { lat: 47.959869, lng: 18.023922 },
    { lat: 47.959617, lng: 18.023834 },
    { lat: 47.959553, lng: 18.023833 },
    { lat: 47.959426, lng: 18.023831 },
    { lat: 47.959414, lng: 18.02383 },
    { lat: 47.959363, lng: 18.023829 },
    { lat: 47.959359, lng: 18.023829 },
    { lat: 47.959282, lng: 18.023825 },
    { lat: 47.959184, lng: 18.02382 },
    { lat: 47.959179, lng: 18.02382 },
    { lat: 47.959145, lng: 18.023818 },
    { lat: 47.959134, lng: 18.023817 },
    { lat: 47.959002, lng: 18.023806 },
    { lat: 47.958991, lng: 18.023805 },
    { lat: 47.958884, lng: 18.023796 },
    { lat: 47.958879, lng: 18.023795 },
    { lat: 47.958778, lng: 18.023784 },
    { lat: 47.958146, lng: 18.023715 },
    { lat: 47.958032, lng: 18.023703 },
    { lat: 47.95802, lng: 18.023702 },
    { lat: 47.957977, lng: 18.023697 },
    { lat: 47.957848, lng: 18.023505 },
    { lat: 47.957832, lng: 18.023483 },
    { lat: 47.957791, lng: 18.023422 },
    { lat: 47.957771, lng: 18.023392 },
    { lat: 47.957633, lng: 18.023183 },
    { lat: 47.957539, lng: 18.023038 },
    { lat: 47.95753, lng: 18.023026 },
    { lat: 47.957457, lng: 18.02291 },
    { lat: 47.957293, lng: 18.022654 },
    { lat: 47.957103, lng: 18.022144 },
    { lat: 47.956967, lng: 18.021611 },
    { lat: 47.956857, lng: 18.020932 },
    { lat: 47.956848, lng: 18.020871 },
    { lat: 47.956821, lng: 18.020709 },
    { lat: 47.95681, lng: 18.020629 },
    { lat: 47.956747, lng: 18.020219 },
    { lat: 47.956739, lng: 18.020173 },
    { lat: 47.956731, lng: 18.020111 },
    { lat: 47.956721, lng: 18.020045 },
    { lat: 47.956713, lng: 18.01999 },
    { lat: 47.956662, lng: 18.019646 },
    { lat: 47.956655, lng: 18.019605 },
    { lat: 47.956628, lng: 18.019424 },
    { lat: 47.956615, lng: 18.019349 },
    { lat: 47.956548, lng: 18.018903 },
    { lat: 47.956544, lng: 18.018867 },
    { lat: 47.956457, lng: 18.018124 },
    { lat: 47.956369, lng: 18.017679 },
    { lat: 47.956325, lng: 18.017456 },
    { lat: 47.956315, lng: 18.01741 },
    { lat: 47.95628, lng: 18.017233 },
    { lat: 47.955956, lng: 18.015642 },
  ],
  Štúrovo: [
    { lat: 47.7992178, lng: 18.6784887 },
    { lat: 47.7986122, lng: 18.6769939 },
    { lat: 47.7983988, lng: 18.6762842 },
    { lat: 47.7977771, lng: 18.6728145 },
    { lat: 47.7975358, lng: 18.6704832 },
    { lat: 47.7973846, lng: 18.6706323 },
    { lat: 47.7944623, lng: 18.6692849 },
    { lat: 47.7942728, lng: 18.6701764 },
    { lat: 47.7942336, lng: 18.6701597 },
    { lat: 47.7942002, lng: 18.6703117 },
    { lat: 47.7922871, lng: 18.6694552 },
    { lat: 47.7920236, lng: 18.6707079 },
    { lat: 47.7917838, lng: 18.6706142 },
    { lat: 47.7886656, lng: 18.6705164 },
    { lat: 47.7884685, lng: 18.6704604 },
    { lat: 47.7875678, lng: 18.6725416 },
    { lat: 47.7873557, lng: 18.6729969 },
    { lat: 47.7870429, lng: 18.6734902 },
    { lat: 47.7854276, lng: 18.6771387 },
    { lat: 47.7830797, lng: 18.6826698 },
    { lat: 47.7827811, lng: 18.6835484 },
    { lat: 47.7824127, lng: 18.6842466 },
    { lat: 47.7818288, lng: 18.6857036 },
    { lat: 47.7805492, lng: 18.6878554 },
    { lat: 47.7802255, lng: 18.6883144 },
    { lat: 47.7798564, lng: 18.6886821 },
    { lat: 47.7792257, lng: 18.6890319 },
    { lat: 47.7784813, lng: 18.689613 },
    { lat: 47.7771499, lng: 18.690893 },
    { lat: 47.7769522, lng: 18.6911396 },
    { lat: 47.7766465, lng: 18.691722 },
    { lat: 47.776414, lng: 18.6928394 },
    { lat: 47.7764473, lng: 18.6941828 },
    { lat: 47.7765999, lng: 18.6945868 },
    { lat: 47.7743066, lng: 18.6965205 },
    { lat: 47.7746198, lng: 18.6979853 },
    { lat: 47.7750495, lng: 18.6999946 },
    { lat: 47.7752781, lng: 18.7008582 },
    { lat: 47.7755564, lng: 18.7016892 },
    { lat: 47.7766859, lng: 18.7044441 },
    { lat: 47.7769606, lng: 18.705044 },
    { lat: 47.7772619, lng: 18.7056149 },
    { lat: 47.7791473, lng: 18.708341 },
    { lat: 47.7800091, lng: 18.7095998 },
    { lat: 47.7808419, lng: 18.7109008 },
    { lat: 47.781645, lng: 18.7122424 },
    { lat: 47.7824174, lng: 18.7136231 },
    { lat: 47.7833584, lng: 18.7151229 },
    { lat: 47.7843207, lng: 18.7169021 },
    { lat: 47.7852125, lng: 18.7187603 },
    { lat: 47.786031, lng: 18.7206913 },
    { lat: 47.7864178, lng: 18.7215899 },
    { lat: 47.7868438, lng: 18.7224485 },
    { lat: 47.7876732, lng: 18.7237592 },
    { lat: 47.788558, lng: 18.7249881 },
    { lat: 47.7894941, lng: 18.7261297 },
    { lat: 47.7904779, lng: 18.7271797 },
    { lat: 47.7915053, lng: 18.7281331 },
    { lat: 47.7921415, lng: 18.7285808 },
    { lat: 47.7929459, lng: 18.7290279 },
    { lat: 47.7937808, lng: 18.729349 },
    { lat: 47.794641, lng: 18.7295813 },
    { lat: 47.7952547, lng: 18.7297902 },
    { lat: 47.7960566, lng: 18.7300585 },
    { lat: 47.7974261, lng: 18.7303718 },
    { lat: 47.7988247, lng: 18.7305014 },
    { lat: 47.8011734, lng: 18.7304002 },
    { lat: 47.8017306, lng: 18.7304243 },
    { lat: 47.802285, lng: 18.7305124 },
    { lat: 47.8034354, lng: 18.7308082 },
    { lat: 47.8041213, lng: 18.7310063 },
    { lat: 47.8047993, lng: 18.7312578 },
    { lat: 47.8054276, lng: 18.7315565 },
    { lat: 47.8059915, lng: 18.7318417 },
    { lat: 47.8065503, lng: 18.7321486 },
    { lat: 47.8071035, lng: 18.7324771 },
    { lat: 47.8076507, lng: 18.7328268 },
    { lat: 47.8082536, lng: 18.7333196 },
    { lat: 47.8088397, lng: 18.7338546 },
    { lat: 47.809408, lng: 18.7344311 },
    { lat: 47.8099567, lng: 18.7350473 },
    { lat: 47.8104849, lng: 18.7357021 },
    { lat: 47.8109913, lng: 18.7363941 },
    { lat: 47.8121342, lng: 18.7380362 },
    { lat: 47.8124531, lng: 18.7385101 },
    { lat: 47.8127646, lng: 18.7389951 },
    { lat: 47.8131346, lng: 18.7395871 },
    { lat: 47.813415, lng: 18.740174 },
    { lat: 47.813668, lng: 18.7407877 },
    { lat: 47.8138927, lng: 18.7414253 },
    { lat: 47.8140878, lng: 18.7420839 },
    { lat: 47.8142529, lng: 18.7427606 },
    { lat: 47.8146576, lng: 18.7451506 },
    { lat: 47.8150305, lng: 18.7472538 },
    { lat: 47.8186396, lng: 18.7407516 },
    { lat: 47.8192771, lng: 18.7408671 },
    { lat: 47.8192498, lng: 18.7403156 },
    { lat: 47.8190633, lng: 18.7403093 },
    { lat: 47.8190182, lng: 18.7396371 },
    { lat: 47.819064, lng: 18.7391039 },
    { lat: 47.8193249, lng: 18.7383734 },
    { lat: 47.8197425, lng: 18.7375832 },
    { lat: 47.8191784, lng: 18.7371819 },
    { lat: 47.8193469, lng: 18.7367904 },
    { lat: 47.8192446, lng: 18.7360828 },
    { lat: 47.8192466, lng: 18.7354089 },
    { lat: 47.8191901, lng: 18.734925 },
    { lat: 47.8186537, lng: 18.7334216 },
    { lat: 47.8185568, lng: 18.7332558 },
    { lat: 47.8182855, lng: 18.7330757 },
    { lat: 47.8180382, lng: 18.732797 },
    { lat: 47.8167865, lng: 18.7304524 },
    { lat: 47.8160605, lng: 18.7293855 },
    { lat: 47.8142607, lng: 18.7277138 },
    { lat: 47.8138307, lng: 18.7272245 },
    { lat: 47.8136745, lng: 18.7270055 },
    { lat: 47.8134505, lng: 18.7263862 },
    { lat: 47.8133588, lng: 18.7258775 },
    { lat: 47.8133626, lng: 18.7246887 },
    { lat: 47.8132522, lng: 18.724232 },
    { lat: 47.8138852, lng: 18.7241541 },
    { lat: 47.8148412, lng: 18.7238743 },
    { lat: 47.8150774, lng: 18.7238859 },
    { lat: 47.815575, lng: 18.7239493 },
    { lat: 47.8163346, lng: 18.7244844 },
    { lat: 47.8168227, lng: 18.724504 },
    { lat: 47.8164243, lng: 18.7239955 },
    { lat: 47.8150561, lng: 18.7196556 },
    { lat: 47.8127699, lng: 18.7142806 },
    { lat: 47.8122292, lng: 18.7132071 },
    { lat: 47.812229, lng: 18.7111108 },
    { lat: 47.8121568, lng: 18.7109247 },
    { lat: 47.8118381, lng: 18.7112461 },
    { lat: 47.8114833, lng: 18.7103954 },
    { lat: 47.8113719, lng: 18.7097101 },
    { lat: 47.8109201, lng: 18.7081146 },
    { lat: 47.810599, lng: 18.7084538 },
    { lat: 47.8100095, lng: 18.7075634 },
    { lat: 47.810305, lng: 18.7071158 },
    { lat: 47.8095501, lng: 18.7050042 },
    { lat: 47.8093722, lng: 18.704721 },
    { lat: 47.8078313, lng: 18.7007301 },
    { lat: 47.8077196, lng: 18.7006487 },
    { lat: 47.8045021, lng: 18.6925633 },
    { lat: 47.804584, lng: 18.6913864 },
    { lat: 47.8040932, lng: 18.6902575 },
    { lat: 47.8036598, lng: 18.6890681 },
    { lat: 47.8033611, lng: 18.6885092 },
    { lat: 47.8033257, lng: 18.688519 },
    { lat: 47.8017967, lng: 18.684449 },
    { lat: 47.8012387, lng: 18.683184 },
    { lat: 47.8007537, lng: 18.682676 },
    { lat: 47.8002536, lng: 18.6819719 },
    { lat: 47.8000321, lng: 18.6820886 },
    { lat: 47.7995669, lng: 18.680934 },
    { lat: 47.7995382, lng: 18.679656 },
    { lat: 47.7994849, lng: 18.6795263 },
    { lat: 47.7996215, lng: 18.6794054 },
    { lat: 47.7992693, lng: 18.678485 },
    { lat: 47.7992232, lng: 18.6784895 },
    { lat: 47.7992178, lng: 18.6784887 },
  ],
  Komjatice: [
    { lat: 48.1274278, lng: 18.1643284 },
    { lat: 48.1283659, lng: 18.1680611 },
    { lat: 48.1285921, lng: 18.1700171 },
    { lat: 48.1286924, lng: 18.1719287 },
    { lat: 48.1293064, lng: 18.1760073 },
    { lat: 48.1297692, lng: 18.1781949 },
    { lat: 48.1298932, lng: 18.1781926 },
    { lat: 48.1298735, lng: 18.1783371 },
    { lat: 48.12991, lng: 18.178497 },
    { lat: 48.1305764, lng: 18.1791863 },
    { lat: 48.1306906, lng: 18.1795176 },
    { lat: 48.1307307, lng: 18.1799535 },
    { lat: 48.1306873, lng: 18.1801383 },
    { lat: 48.1305392, lng: 18.1803681 },
    { lat: 48.1303508, lng: 18.1805593 },
    { lat: 48.1300876, lng: 18.1807095 },
    { lat: 48.1298251, lng: 18.1807571 },
    { lat: 48.1291606, lng: 18.1805267 },
    { lat: 48.1289758, lng: 18.1805688 },
    { lat: 48.1287106, lng: 18.1807658 },
    { lat: 48.1284513, lng: 18.1811719 },
    { lat: 48.1283688, lng: 18.181403 },
    { lat: 48.1282422, lng: 18.1824068 },
    { lat: 48.1282898, lng: 18.1826234 },
    { lat: 48.12861, lng: 18.1832366 },
    { lat: 48.1286258, lng: 18.1833734 },
    { lat: 48.1285427, lng: 18.1837844 },
    { lat: 48.1278962, lng: 18.1859952 },
    { lat: 48.1277636, lng: 18.1866606 },
    { lat: 48.1278549, lng: 18.1867187 },
    { lat: 48.1279063, lng: 18.1869229 },
    { lat: 48.1278816, lng: 18.1871931 },
    { lat: 48.1279244, lng: 18.1874242 },
    { lat: 48.1278768, lng: 18.1883322 },
    { lat: 48.1279184, lng: 18.188485 },
    { lat: 48.1278398, lng: 18.1888198 },
    { lat: 48.1277936, lng: 18.1893821 },
    { lat: 48.1290195, lng: 18.189429 },
    { lat: 48.1293738, lng: 18.1893491 },
    { lat: 48.1294836, lng: 18.1894514 },
    { lat: 48.1295264, lng: 18.1897231 },
    { lat: 48.1292098, lng: 18.1900398 },
    { lat: 48.12914, lng: 18.1901972 },
    { lat: 48.1291413, lng: 18.1903386 },
    { lat: 48.1292345, lng: 18.1905614 },
    { lat: 48.1293706, lng: 18.1906497 },
    { lat: 48.129494, lng: 18.1906411 },
    { lat: 48.1300454, lng: 18.1900872 },
    { lat: 48.1308176, lng: 18.1898834 },
    { lat: 48.1313161, lng: 18.1894134 },
    { lat: 48.1315773, lng: 18.1894034 },
    { lat: 48.1317119, lng: 18.1897572 },
    { lat: 48.1317176, lng: 18.1906459 },
    { lat: 48.1316565, lng: 18.1908764 },
    { lat: 48.13127, lng: 18.1917387 },
    { lat: 48.1315377, lng: 18.1921889 },
    { lat: 48.1316625, lng: 18.1921808 },
    { lat: 48.1319367, lng: 18.1917157 },
    { lat: 48.1321203, lng: 18.1916522 },
    { lat: 48.1323571, lng: 18.1919224 },
    { lat: 48.132545, lng: 18.1920259 },
    { lat: 48.1327039, lng: 18.1920059 },
    { lat: 48.1333478, lng: 18.191279 },
    { lat: 48.1344846, lng: 18.1908228 },
    { lat: 48.1346984, lng: 18.1911391 },
    { lat: 48.134441, lng: 18.1919331 },
    { lat: 48.1344, lng: 18.1921225 },
    { lat: 48.1344104, lng: 18.1923585 },
    { lat: 48.1346844, lng: 18.1929376 },
    { lat: 48.1349098, lng: 18.193145 },
    { lat: 48.1351034, lng: 18.1932329 },
    { lat: 48.1349542, lng: 18.1959233 },
    { lat: 48.1347878, lng: 18.1978327 },
    { lat: 48.1318755, lng: 18.2075391 },
    { lat: 48.1313868, lng: 18.2091896 },
    { lat: 48.1312608, lng: 18.2095432 },
    { lat: 48.130659, lng: 18.211559 },
    { lat: 48.1303754, lng: 18.2122895 },
    { lat: 48.1301602, lng: 18.2127059 },
    { lat: 48.1303405, lng: 18.2151311 },
    { lat: 48.1326681, lng: 18.2160575 },
    { lat: 48.1368781, lng: 18.2156275 },
    { lat: 48.1386456, lng: 18.2151286 },
    { lat: 48.1404074, lng: 18.2144715 },
    { lat: 48.1415837, lng: 18.2137051 },
    { lat: 48.1420097, lng: 18.2134224 },
    { lat: 48.1440876, lng: 18.2112162 },
    { lat: 48.1466042, lng: 18.208576 },
    { lat: 48.1508207, lng: 18.2089175 },
    { lat: 48.1542982, lng: 18.2090076 },
    { lat: 48.1549517, lng: 18.2085259 },
    { lat: 48.1585118, lng: 18.2057079 },
    { lat: 48.1620748, lng: 18.2027449 },
    { lat: 48.1663087, lng: 18.1961089 },
    { lat: 48.168665, lng: 18.192544 },
    { lat: 48.168726, lng: 18.1921 },
    { lat: 48.168782, lng: 18.191905 },
    { lat: 48.168853, lng: 18.191811 },
    { lat: 48.168921, lng: 18.19173 },
    { lat: 48.1691, lng: 18.191615 },
    { lat: 48.169217, lng: 18.191561 },
    { lat: 48.169419, lng: 18.191522 },
    { lat: 48.169552, lng: 18.191649 },
    { lat: 48.169609, lng: 18.191893 },
    { lat: 48.169626, lng: 18.192085 },
    { lat: 48.169649, lng: 18.192264 },
    { lat: 48.169682, lng: 18.192338 },
    { lat: 48.169732, lng: 18.192379 },
    { lat: 48.169798, lng: 18.192386 },
    { lat: 48.16989, lng: 18.192326 },
    { lat: 48.169954, lng: 18.192219 },
    { lat: 48.169947, lng: 18.192077 },
    { lat: 48.16984, lng: 18.191548 },
    { lat: 48.169851, lng: 18.191322 },
    { lat: 48.169879, lng: 18.191125 },
    { lat: 48.169931, lng: 18.190891 },
    { lat: 48.16998, lng: 18.190822 },
    { lat: 48.170049, lng: 18.190768 },
    { lat: 48.170154, lng: 18.190751 },
    { lat: 48.170233, lng: 18.190792 },
    { lat: 48.1703, lng: 18.190848 },
    { lat: 48.170485, lng: 18.191131 },
    { lat: 48.17063, lng: 18.191261 },
    { lat: 48.170695, lng: 18.191295 },
    { lat: 48.170812, lng: 18.191301 },
    { lat: 48.171127, lng: 18.191188 },
    { lat: 48.171318, lng: 18.191045 },
    { lat: 48.171514, lng: 18.190661 },
    { lat: 48.171585, lng: 18.190458 },
    { lat: 48.171613, lng: 18.190207 },
    { lat: 48.17161, lng: 18.189892 },
    { lat: 48.171552, lng: 18.189625 },
    { lat: 48.171436, lng: 18.189498 },
    { lat: 48.170851, lng: 18.18929 },
    { lat: 48.170664, lng: 18.189216 },
    { lat: 48.170532, lng: 18.189051 },
    { lat: 48.170477, lng: 18.188924 },
    { lat: 48.170413, lng: 18.18874 },
    { lat: 48.170402, lng: 18.188593 },
    { lat: 48.170406, lng: 18.188348 },
    { lat: 48.170439, lng: 18.188158 },
    { lat: 48.170518, lng: 18.187974 },
    { lat: 48.170596, lng: 18.18782 },
    { lat: 48.171068, lng: 18.187442 },
    { lat: 48.171336, lng: 18.187179 },
    { lat: 48.171352, lng: 18.187145 },
    { lat: 48.171386, lng: 18.187074 },
    { lat: 48.171703, lng: 18.186385 },
    { lat: 48.171754, lng: 18.18629 },
    { lat: 48.171849, lng: 18.186155 },
    { lat: 48.17194, lng: 18.186133 },
    { lat: 48.172052, lng: 18.186123 },
    { lat: 48.172347, lng: 18.186487 },
    { lat: 48.172411, lng: 18.186509 },
    { lat: 48.172566, lng: 18.186504 },
    { lat: 48.172618, lng: 18.186452 },
    { lat: 48.172821, lng: 18.186173 },
    { lat: 48.17299, lng: 18.185966 },
    { lat: 48.173239, lng: 18.185779 },
    { lat: 48.173345, lng: 18.185739 },
    { lat: 48.173496, lng: 18.185727 },
    { lat: 48.17365, lng: 18.185846 },
    { lat: 48.173807, lng: 18.186113 },
    { lat: 48.173996, lng: 18.186304 },
    { lat: 48.17416, lng: 18.186324 },
    { lat: 48.174253, lng: 18.186304 },
    { lat: 48.174789, lng: 18.185828 },
    { lat: 48.174918, lng: 18.185774 },
    { lat: 48.1701134, lng: 18.1697484 },
    { lat: 48.1674327, lng: 18.160991 },
    { lat: 48.167212, lng: 18.1609421 },
    { lat: 48.1671486, lng: 18.1608238 },
    { lat: 48.1670033, lng: 18.1603649 },
    { lat: 48.166887, lng: 18.1601521 },
    { lat: 48.1669251, lng: 18.1600718 },
    { lat: 48.1668853, lng: 18.1598716 },
    { lat: 48.1655909, lng: 18.1564586 },
    { lat: 48.1654129, lng: 18.1559824 },
    { lat: 48.1651058, lng: 18.1553615 },
    { lat: 48.1632894, lng: 18.1518992 },
    { lat: 48.1616344, lng: 18.1485949 },
    { lat: 48.1559157, lng: 18.1363119 },
    { lat: 48.1468259, lng: 18.1135049 },
    { lat: 48.1511858, lng: 18.1090721 },
    { lat: 48.1520336, lng: 18.1082123 },
    { lat: 48.1506167, lng: 18.1019648 },
    { lat: 48.1505438, lng: 18.1019366 },
    { lat: 48.1448949, lng: 18.10962 },
    { lat: 48.1414401, lng: 18.0936313 },
    { lat: 48.1376692, lng: 18.0960887 },
    { lat: 48.1342544, lng: 18.0989414 },
    { lat: 48.1336628, lng: 18.0976938 },
    { lat: 48.1291339, lng: 18.1029701 },
    { lat: 48.12674, lng: 18.1056631 },
    { lat: 48.1253188, lng: 18.1073959 },
    { lat: 48.1212577, lng: 18.112114 },
    { lat: 48.1212173, lng: 18.112155 },
    { lat: 48.1212354, lng: 18.1122325 },
    { lat: 48.122123, lng: 18.115267 },
    { lat: 48.1222897, lng: 18.1160296 },
    { lat: 48.1227757, lng: 18.1178596 },
    { lat: 48.1235304, lng: 18.1201527 },
    { lat: 48.1241835, lng: 18.1223328 },
    { lat: 48.1247943, lng: 18.1250815 },
    { lat: 48.1253262, lng: 18.1277505 },
    { lat: 48.1252625, lng: 18.1288952 },
    { lat: 48.1251619, lng: 18.1295809 },
    { lat: 48.1248627, lng: 18.1310207 },
    { lat: 48.1248652, lng: 18.1316014 },
    { lat: 48.1249579, lng: 18.1329099 },
    { lat: 48.1251708, lng: 18.1346682 },
    { lat: 48.1256687, lng: 18.1367067 },
    { lat: 48.1259675, lng: 18.1392227 },
    { lat: 48.1257776, lng: 18.1407319 },
    { lat: 48.1255995, lng: 18.1437939 },
    { lat: 48.1257405, lng: 18.1466411 },
    { lat: 48.1258831, lng: 18.1474196 },
    { lat: 48.1263422, lng: 18.1488975 },
    { lat: 48.126639, lng: 18.1508784 },
    { lat: 48.1265858, lng: 18.1515317 },
    { lat: 48.1263773, lng: 18.1528087 },
    { lat: 48.1263703, lng: 18.153113 },
    { lat: 48.1265226, lng: 18.1543802 },
    { lat: 48.1264337, lng: 18.1582405 },
    { lat: 48.1263927, lng: 18.1585214 },
    { lat: 48.1260801, lng: 18.1596603 },
    { lat: 48.1260234, lng: 18.1604243 },
    { lat: 48.1261564, lng: 18.1610781 },
    { lat: 48.1268296, lng: 18.1624952 },
    { lat: 48.1272263, lng: 18.1635886 },
    { lat: 48.1274278, lng: 18.1643284 },
  ],
  Šarkan: [
    { lat: 47.8809691, lng: 18.5778934 },
    { lat: 47.883441, lng: 18.5700028 },
    { lat: 47.8858962, lng: 18.5627169 },
    { lat: 47.8875919, lng: 18.5547379 },
    { lat: 47.8842779, lng: 18.5525769 },
    { lat: 47.8826747, lng: 18.5514081 },
    { lat: 47.8795972, lng: 18.5524867 },
    { lat: 47.8777265, lng: 18.5558614 },
    { lat: 47.8768886, lng: 18.5558031 },
    { lat: 47.8739113, lng: 18.554204 },
    { lat: 47.8739383, lng: 18.5541631 },
    { lat: 47.8730787, lng: 18.5532036 },
    { lat: 47.8720978, lng: 18.5518876 },
    { lat: 47.8714348, lng: 18.5528698 },
    { lat: 47.870555, lng: 18.5543365 },
    { lat: 47.8698208, lng: 18.5550318 },
    { lat: 47.8692074, lng: 18.5558961 },
    { lat: 47.8683076, lng: 18.5573883 },
    { lat: 47.8682941, lng: 18.5572291 },
    { lat: 47.8669672, lng: 18.5579549 },
    { lat: 47.8668957, lng: 18.5571515 },
    { lat: 47.8669065, lng: 18.5544105 },
    { lat: 47.8666497, lng: 18.5510124 },
    { lat: 47.8670755, lng: 18.5464572 },
    { lat: 47.8676187, lng: 18.5440628 },
    { lat: 47.8681732, lng: 18.5407988 },
    { lat: 47.8679527, lng: 18.5372904 },
    { lat: 47.8676592, lng: 18.5345986 },
    { lat: 47.8670951, lng: 18.5287668 },
    { lat: 47.8670586, lng: 18.5288001 },
    { lat: 47.8649338, lng: 18.5307574 },
    { lat: 47.8645517, lng: 18.5312278 },
    { lat: 47.86437, lng: 18.5330391 },
    { lat: 47.8594027, lng: 18.5372119 },
    { lat: 47.8588666, lng: 18.5380436 },
    { lat: 47.8579177, lng: 18.5388528 },
    { lat: 47.8566597, lng: 18.5406768 },
    { lat: 47.8550636, lng: 18.5432253 },
    { lat: 47.854007, lng: 18.5445128 },
    { lat: 47.8528161, lng: 18.5446785 },
    { lat: 47.8514115, lng: 18.5446937 },
    { lat: 47.8505621, lng: 18.545122 },
    { lat: 47.8501359, lng: 18.5455845 },
    { lat: 47.8497511, lng: 18.5462452 },
    { lat: 47.8489908, lng: 18.5479929 },
    { lat: 47.8478029, lng: 18.550133 },
    { lat: 47.8450426, lng: 18.5490274 },
    { lat: 47.8421237, lng: 18.5487732 },
    { lat: 47.8405287, lng: 18.5488112 },
    { lat: 47.8372896, lng: 18.549471 },
    { lat: 47.8356547, lng: 18.5484273 },
    { lat: 47.8335595, lng: 18.5466876 },
    { lat: 47.8335752, lng: 18.5466456 },
    { lat: 47.8335227, lng: 18.5465941 },
    { lat: 47.8328131, lng: 18.5479984 },
    { lat: 47.8316474, lng: 18.5500866 },
    { lat: 47.8309929, lng: 18.5510379 },
    { lat: 47.828357, lng: 18.5552592 },
    { lat: 47.828417, lng: 18.555327 },
    { lat: 47.8300312, lng: 18.5565064 },
    { lat: 47.8323698, lng: 18.5583297 },
    { lat: 47.8369956, lng: 18.5622643 },
    { lat: 47.8378009, lng: 18.5630385 },
    { lat: 47.8383616, lng: 18.5641818 },
    { lat: 47.8389354, lng: 18.5656102 },
    { lat: 47.840162, lng: 18.568273 },
    { lat: 47.8403075, lng: 18.5687001 },
    { lat: 47.8407585, lng: 18.5696156 },
    { lat: 47.8421647, lng: 18.57331 },
    { lat: 47.8432934, lng: 18.575655 },
    { lat: 47.8433385, lng: 18.5763496 },
    { lat: 47.8434669, lng: 18.5769586 },
    { lat: 47.8441937, lng: 18.5779277 },
    { lat: 47.8444348, lng: 18.5780697 },
    { lat: 47.8444429, lng: 18.5781724 },
    { lat: 47.8452587, lng: 18.5793839 },
    { lat: 47.8456009, lng: 18.5804564 },
    { lat: 47.8468752, lng: 18.5802249 },
    { lat: 47.8477632, lng: 18.5799669 },
    { lat: 47.8491064, lng: 18.579739 },
    { lat: 47.8505852, lng: 18.5793891 },
    { lat: 47.8526325, lng: 18.5797403 },
    { lat: 47.8535697, lng: 18.5801481 },
    { lat: 47.8550348, lng: 18.5806333 },
    { lat: 47.8553619, lng: 18.5807962 },
    { lat: 47.8555297, lng: 18.5811832 },
    { lat: 47.855417, lng: 18.5815737 },
    { lat: 47.856622, lng: 18.5821106 },
    { lat: 47.8583224, lng: 18.5823471 },
    { lat: 47.8586117, lng: 18.5828911 },
    { lat: 47.8597057, lng: 18.5826018 },
    { lat: 47.863256, lng: 18.582835 },
    { lat: 47.8652075, lng: 18.5821654 },
    { lat: 47.8669301, lng: 18.5815743 },
    { lat: 47.867759, lng: 18.5810793 },
    { lat: 47.8687961, lng: 18.5808436 },
    { lat: 47.8697091, lng: 18.5807898 },
    { lat: 47.8708093, lng: 18.5812862 },
    { lat: 47.8733278, lng: 18.5832483 },
    { lat: 47.8739459, lng: 18.582227 },
    { lat: 47.875584, lng: 18.5789754 },
    { lat: 47.8761947, lng: 18.5782065 },
    { lat: 47.8796689, lng: 18.5807448 },
    { lat: 47.8809548, lng: 18.5779991 },
    { lat: 47.8809691, lng: 18.5778934 },
  ],
  Čechy: [
    { lat: 48.0213787, lng: 18.3851119 },
    { lat: 48.0216084, lng: 18.3856039 },
    { lat: 48.0217419, lng: 18.3857478 },
    { lat: 48.0219228, lng: 18.3858166 },
    { lat: 48.0222009, lng: 18.3862045 },
    { lat: 48.0225133, lng: 18.386482 },
    { lat: 48.0226607, lng: 18.3865343 },
    { lat: 48.0233595, lng: 18.3865415 },
    { lat: 48.0237585, lng: 18.3864751 },
    { lat: 48.0239306, lng: 18.3865666 },
    { lat: 48.0244857, lng: 18.3866247 },
    { lat: 48.0251648, lng: 18.3872825 },
    { lat: 48.0260111, lng: 18.3877438 },
    { lat: 48.0261493, lng: 18.3882889 },
    { lat: 48.0267062, lng: 18.3886428 },
    { lat: 48.0276735, lng: 18.3895259 },
    { lat: 48.028476, lng: 18.3905891 },
    { lat: 48.0286867, lng: 18.3919812 },
    { lat: 48.0291773, lng: 18.3931358 },
    { lat: 48.0301755, lng: 18.3937477 },
    { lat: 48.0302889, lng: 18.3937585 },
    { lat: 48.0308878, lng: 18.3941949 },
    { lat: 48.031093, lng: 18.3941899 },
    { lat: 48.0317745, lng: 18.3939756 },
    { lat: 48.0323234, lng: 18.3939665 },
    { lat: 48.032984, lng: 18.3937379 },
    { lat: 48.0331988, lng: 18.393522 },
    { lat: 48.0336588, lng: 18.3928188 },
    { lat: 48.034435, lng: 18.3919841 },
    { lat: 48.0350977, lng: 18.3910333 },
    { lat: 48.0358128, lng: 18.390133 },
    { lat: 48.0369417, lng: 18.3894491 },
    { lat: 48.0377271, lng: 18.3888942 },
    { lat: 48.0381289, lng: 18.3886956 },
    { lat: 48.0390259, lng: 18.3886969 },
    { lat: 48.0398658, lng: 18.3884072 },
    { lat: 48.0404888, lng: 18.3893483 },
    { lat: 48.0410858, lng: 18.389992 },
    { lat: 48.0417556, lng: 18.390144 },
    { lat: 48.0420379, lng: 18.3900693 },
    { lat: 48.0433845, lng: 18.3901812 },
    { lat: 48.0442111, lng: 18.3905718 },
    { lat: 48.0445736, lng: 18.3906391 },
    { lat: 48.0449658, lng: 18.3908485 },
    { lat: 48.0455813, lng: 18.3909001 },
    { lat: 48.0458287, lng: 18.3907276 },
    { lat: 48.0466871, lng: 18.390548 },
    { lat: 48.0470707, lng: 18.3904077 },
    { lat: 48.0472042, lng: 18.3903931 },
    { lat: 48.047615, lng: 18.3905138 },
    { lat: 48.0484471, lng: 18.3913434 },
    { lat: 48.0488292, lng: 18.3917967 },
    { lat: 48.0495345, lng: 18.3922154 },
    { lat: 48.0497939, lng: 18.3925551 },
    { lat: 48.0514809, lng: 18.3936211 },
    { lat: 48.0518555, lng: 18.3937397 },
    { lat: 48.0527245, lng: 18.3943035 },
    { lat: 48.0528513, lng: 18.3943306 },
    { lat: 48.0535341, lng: 18.3950128 },
    { lat: 48.0536952, lng: 18.3957662 },
    { lat: 48.0537119, lng: 18.3962217 },
    { lat: 48.0536419, lng: 18.3979774 },
    { lat: 48.0539076, lng: 18.4003831 },
    { lat: 48.0539391, lng: 18.4003851 },
    { lat: 48.0546217, lng: 18.4003868 },
    { lat: 48.0553125, lng: 18.3999125 },
    { lat: 48.0557908, lng: 18.3994334 },
    { lat: 48.0559425, lng: 18.3991802 },
    { lat: 48.0562602, lng: 18.3981748 },
    { lat: 48.0563679, lng: 18.3980054 },
    { lat: 48.0573577, lng: 18.3971514 },
    { lat: 48.0575583, lng: 18.3969064 },
    { lat: 48.0578841, lng: 18.3963259 },
    { lat: 48.0581788, lng: 18.3960692 },
    { lat: 48.0587074, lng: 18.3952233 },
    { lat: 48.0592429, lng: 18.3947187 },
    { lat: 48.059831, lng: 18.3939267 },
    { lat: 48.0602517, lng: 18.3936597 },
    { lat: 48.0610933, lng: 18.3928647 },
    { lat: 48.0616972, lng: 18.3917924 },
    { lat: 48.0627678, lng: 18.3905319 },
    { lat: 48.0633752, lng: 18.3900019 },
    { lat: 48.0633603, lng: 18.38996 },
    { lat: 48.0629094, lng: 18.3884092 },
    { lat: 48.0614326, lng: 18.3862591 },
    { lat: 48.0601644, lng: 18.3845987 },
    { lat: 48.0586721, lng: 18.3824736 },
    { lat: 48.0575436, lng: 18.3810951 },
    { lat: 48.0549398, lng: 18.3784678 },
    { lat: 48.0532672, lng: 18.3763867 },
    { lat: 48.0520617, lng: 18.3737512 },
    { lat: 48.0515501, lng: 18.3724519 },
    { lat: 48.0507638, lng: 18.3686946 },
    { lat: 48.0506278, lng: 18.3676098 },
    { lat: 48.0507156, lng: 18.3623847 },
    { lat: 48.0508178, lng: 18.3609454 },
    { lat: 48.0514354, lng: 18.3562318 },
    { lat: 48.0516909, lng: 18.355465 },
    { lat: 48.0518528, lng: 18.3542265 },
    { lat: 48.0523086, lng: 18.3526804 },
    { lat: 48.052276, lng: 18.3526591 },
    { lat: 48.0523266, lng: 18.3524972 },
    { lat: 48.0523649, lng: 18.3519451 },
    { lat: 48.0522846, lng: 18.3498991 },
    { lat: 48.0520751, lng: 18.3495494 },
    { lat: 48.0515535, lng: 18.3492829 },
    { lat: 48.050982, lng: 18.3491363 },
    { lat: 48.0502613, lng: 18.3491394 },
    { lat: 48.0496522, lng: 18.349341 },
    { lat: 48.0486922, lng: 18.3498367 },
    { lat: 48.0485795, lng: 18.3498013 },
    { lat: 48.0484484, lng: 18.3497167 },
    { lat: 48.0480661, lng: 18.3491646 },
    { lat: 48.0477129, lng: 18.3481723 },
    { lat: 48.0475829, lng: 18.3479526 },
    { lat: 48.0474344, lng: 18.3478187 },
    { lat: 48.046647, lng: 18.347385 },
    { lat: 48.0452114, lng: 18.3474561 },
    { lat: 48.0448241, lng: 18.3464418 },
    { lat: 48.0444155, lng: 18.3447141 },
    { lat: 48.0441553, lng: 18.344111 },
    { lat: 48.0436162, lng: 18.3433427 },
    { lat: 48.0434153, lng: 18.343261 },
    { lat: 48.0433962, lng: 18.3433029 },
    { lat: 48.0426252, lng: 18.342839 },
    { lat: 48.0416135, lng: 18.3423897 },
    { lat: 48.0406664, lng: 18.341212 },
    { lat: 48.0406375, lng: 18.3412404 },
    { lat: 48.0396413, lng: 18.3419343 },
    { lat: 48.0389827, lng: 18.3431261 },
    { lat: 48.0379967, lng: 18.3443982 },
    { lat: 48.0362726, lng: 18.3449276 },
    { lat: 48.0336998, lng: 18.3449814 },
    { lat: 48.0317951, lng: 18.3463027 },
    { lat: 48.0310234, lng: 18.3469951 },
    { lat: 48.0300696, lng: 18.3476632 },
    { lat: 48.0291254, lng: 18.3485194 },
    { lat: 48.0285419, lng: 18.349466 },
    { lat: 48.0270272, lng: 18.3514553 },
    { lat: 48.0242503, lng: 18.3544012 },
    { lat: 48.0233602, lng: 18.3551781 },
    { lat: 48.0224717, lng: 18.3556426 },
    { lat: 48.0219644, lng: 18.3558272 },
    { lat: 48.0207776, lng: 18.3568622 },
    { lat: 48.0196031, lng: 18.3576739 },
    { lat: 48.0205511, lng: 18.3596829 },
    { lat: 48.0212487, lng: 18.3615327 },
    { lat: 48.0213288, lng: 18.3646516 },
    { lat: 48.0210659, lng: 18.3672521 },
    { lat: 48.0212124, lng: 18.367408 },
    { lat: 48.0218115, lng: 18.3688362 },
    { lat: 48.0224777, lng: 18.3707711 },
    { lat: 48.0227941, lng: 18.3720555 },
    { lat: 48.0228886, lng: 18.3729832 },
    { lat: 48.0230611, lng: 18.3738358 },
    { lat: 48.0232306, lng: 18.3743557 },
    { lat: 48.0234035, lng: 18.3746197 },
    { lat: 48.0236273, lng: 18.3747889 },
    { lat: 48.023996, lng: 18.3748411 },
    { lat: 48.0239707, lng: 18.3752696 },
    { lat: 48.0238097, lng: 18.3757742 },
    { lat: 48.023548, lng: 18.3768912 },
    { lat: 48.023496, lng: 18.3777044 },
    { lat: 48.0235292, lng: 18.3777115 },
    { lat: 48.023421, lng: 18.3785872 },
    { lat: 48.0234022, lng: 18.3799693 },
    { lat: 48.0232748, lng: 18.3808452 },
    { lat: 48.0232467, lng: 18.3816438 },
    { lat: 48.0230922, lng: 18.3823843 },
    { lat: 48.0228855, lng: 18.3828378 },
    { lat: 48.0227711, lng: 18.3833463 },
    { lat: 48.0226025, lng: 18.3836339 },
    { lat: 48.0224724, lng: 18.3841088 },
    { lat: 48.0223022, lng: 18.3844083 },
    { lat: 48.0218847, lng: 18.3848474 },
    { lat: 48.0213787, lng: 18.3851119 },
  ],
  Kolta: [
    { lat: 47.9900881, lng: 18.37372 },
    { lat: 47.9900417, lng: 18.3738978 },
    { lat: 47.9893138, lng: 18.3747255 },
    { lat: 47.9888833, lng: 18.3753929 },
    { lat: 47.989036, lng: 18.3763704 },
    { lat: 47.9892706, lng: 18.3785381 },
    { lat: 47.9897114, lng: 18.3813574 },
    { lat: 47.9903057, lng: 18.3847756 },
    { lat: 47.9904723, lng: 18.3853823 },
    { lat: 47.9919308, lng: 18.3830909 },
    { lat: 47.9948295, lng: 18.3871779 },
    { lat: 47.9966446, lng: 18.3843076 },
    { lat: 47.9976829, lng: 18.3857877 },
    { lat: 47.9937683, lng: 18.3919467 },
    { lat: 47.9936465, lng: 18.3917659 },
    { lat: 47.9923806, lng: 18.39294 },
    { lat: 47.9927227, lng: 18.3944019 },
    { lat: 47.9927278, lng: 18.3946714 },
    { lat: 47.9933096, lng: 18.3946846 },
    { lat: 47.9933789, lng: 18.3949729 },
    { lat: 47.9934456, lng: 18.3950747 },
    { lat: 47.993513, lng: 18.395826 },
    { lat: 47.9932487, lng: 18.3957809 },
    { lat: 47.9931466, lng: 18.3958896 },
    { lat: 47.9929942, lng: 18.3954892 },
    { lat: 47.9927428, lng: 18.3954338 },
    { lat: 47.9927503, lng: 18.397259 },
    { lat: 47.9928072, lng: 18.3987029 },
    { lat: 47.9931153, lng: 18.4007827 },
    { lat: 47.9933181, lng: 18.4012951 },
    { lat: 47.9929495, lng: 18.4007648 },
    { lat: 47.9928769, lng: 18.400776 },
    { lat: 47.9917426, lng: 18.4013449 },
    { lat: 47.9909299, lng: 18.4018976 },
    { lat: 47.9910362, lng: 18.4022016 },
    { lat: 47.9906569, lng: 18.4023957 },
    { lat: 47.9908917, lng: 18.4031164 },
    { lat: 47.9907842, lng: 18.4031517 },
    { lat: 47.9905647, lng: 18.4024625 },
    { lat: 47.9903721, lng: 18.402586 },
    { lat: 47.9910694, lng: 18.4046839 },
    { lat: 47.9906412, lng: 18.4049721 },
    { lat: 47.9906819, lng: 18.4050716 },
    { lat: 47.99015, lng: 18.4054665 },
    { lat: 47.99109, lng: 18.4075054 },
    { lat: 47.9911753, lng: 18.4077754 },
    { lat: 47.9930754, lng: 18.4080029 },
    { lat: 47.9939692, lng: 18.4107036 },
    { lat: 47.9936021, lng: 18.4109757 },
    { lat: 47.9940798, lng: 18.4124032 },
    { lat: 47.995652, lng: 18.411717 },
    { lat: 47.996492, lng: 18.4141865 },
    { lat: 47.9970645, lng: 18.4162764 },
    { lat: 47.9986112, lng: 18.4200342 },
    { lat: 47.9986557, lng: 18.4216474 },
    { lat: 47.9987072, lng: 18.4221415 },
    { lat: 47.9990386, lng: 18.4232195 },
    { lat: 48.0004348, lng: 18.4288998 },
    { lat: 48.0015934, lng: 18.4331515 },
    { lat: 48.002491, lng: 18.4351258 },
    { lat: 48.0036039, lng: 18.4380906 },
    { lat: 48.004339, lng: 18.4395848 },
    { lat: 48.0044548, lng: 18.4399216 },
    { lat: 48.004423, lng: 18.4399368 },
    { lat: 48.0044789, lng: 18.4405907 },
    { lat: 48.0044675, lng: 18.4410096 },
    { lat: 48.0042445, lng: 18.4431055 },
    { lat: 48.0042556, lng: 18.4435853 },
    { lat: 48.0041096, lng: 18.4441375 },
    { lat: 48.0035946, lng: 18.4453152 },
    { lat: 48.003158, lng: 18.4466423 },
    { lat: 48.0030272, lng: 18.4473857 },
    { lat: 48.002821, lng: 18.4491374 },
    { lat: 48.0029229, lng: 18.4497711 },
    { lat: 48.0032603, lng: 18.4510356 },
    { lat: 48.0032871, lng: 18.4511928 },
    { lat: 48.0032344, lng: 18.4516823 },
    { lat: 48.0032893, lng: 18.4520168 },
    { lat: 48.0034139, lng: 18.4523677 },
    { lat: 48.0043063, lng: 18.4533539 },
    { lat: 48.0048946, lng: 18.4537689 },
    { lat: 48.005273, lng: 18.453937 },
    { lat: 48.005292, lng: 18.453969 },
    { lat: 48.005569, lng: 18.454486 },
    { lat: 48.005806, lng: 18.454911 },
    { lat: 48.00611, lng: 18.45566 },
    { lat: 48.006633, lng: 18.45693 },
    { lat: 48.006754, lng: 18.457157 },
    { lat: 48.007015, lng: 18.457639 },
    { lat: 48.007343, lng: 18.45826 },
    { lat: 48.007677, lng: 18.458873 },
    { lat: 48.0079, lng: 18.459205 },
    { lat: 48.008288, lng: 18.459768 },
    { lat: 48.008759, lng: 18.460455 },
    { lat: 48.009072, lng: 18.460919 },
    { lat: 48.01042, lng: 18.462558 },
    { lat: 48.010578, lng: 18.462771 },
    { lat: 48.010978, lng: 18.463285 },
    { lat: 48.011504, lng: 18.463938 },
    { lat: 48.011521, lng: 18.463957 },
    { lat: 48.011544, lng: 18.463983 },
    { lat: 48.011563, lng: 18.464004 },
    { lat: 48.011589, lng: 18.464033 },
    { lat: 48.012516, lng: 18.464989 },
    { lat: 48.012521, lng: 18.464994 },
    { lat: 48.012532, lng: 18.465005 },
    { lat: 48.012559, lng: 18.465032 },
    { lat: 48.012606, lng: 18.465081 },
    { lat: 48.012672, lng: 18.465124 },
    { lat: 48.01291, lng: 18.465265 },
    { lat: 48.013469, lng: 18.465612 },
    { lat: 48.013513, lng: 18.46564 },
    { lat: 48.013524, lng: 18.465648 },
    { lat: 48.013771, lng: 18.465807 },
    { lat: 48.013795, lng: 18.465824 },
    { lat: 48.014017, lng: 18.465967 },
    { lat: 48.014041, lng: 18.465983 },
    { lat: 48.014151, lng: 18.466054 },
    { lat: 48.014182, lng: 18.466072 },
    { lat: 48.014558, lng: 18.466316 },
    { lat: 48.014961, lng: 18.466541 },
    { lat: 48.015746, lng: 18.466992 },
    { lat: 48.016529, lng: 18.467428 },
    { lat: 48.016865, lng: 18.467613 },
    { lat: 48.017057, lng: 18.467772 },
    { lat: 48.017411, lng: 18.468083 },
    { lat: 48.01751, lng: 18.468173 },
    { lat: 48.01753, lng: 18.468192 },
    { lat: 48.017872, lng: 18.467694 },
    { lat: 48.01818, lng: 18.467254 },
    { lat: 48.018331, lng: 18.467025 },
    { lat: 48.018603, lng: 18.466478 },
    { lat: 48.018892, lng: 18.465893 },
    { lat: 48.019093, lng: 18.465492 },
    { lat: 48.019659, lng: 18.464238 },
    { lat: 48.019681, lng: 18.464187 },
    { lat: 48.019824, lng: 18.463882 },
    { lat: 48.020029, lng: 18.463391 },
    { lat: 48.020251, lng: 18.462887 },
    { lat: 48.020329, lng: 18.462684 },
    { lat: 48.020582, lng: 18.462109 },
    { lat: 48.020705, lng: 18.461843 },
    { lat: 48.02099, lng: 18.461445 },
    { lat: 48.021078, lng: 18.461245 },
    { lat: 48.021191, lng: 18.460917 },
    { lat: 48.021287, lng: 18.460544 },
    { lat: 48.021558, lng: 18.459802 },
    { lat: 48.021611, lng: 18.459579 },
    { lat: 48.021637, lng: 18.459186 },
    { lat: 48.021637, lng: 18.458843 },
    { lat: 48.021757, lng: 18.45793 },
    { lat: 48.021843, lng: 18.457643 },
    { lat: 48.022144, lng: 18.457124 },
    { lat: 48.022278, lng: 18.456806 },
    { lat: 48.022422, lng: 18.456367 },
    { lat: 48.022694, lng: 18.45571 },
    { lat: 48.022765, lng: 18.45502 },
    { lat: 48.023049, lng: 18.454076 },
    { lat: 48.023384, lng: 18.453413 },
    { lat: 48.023602, lng: 18.452705 },
    { lat: 48.024155, lng: 18.451573 },
    { lat: 48.024342, lng: 18.451104 },
    { lat: 48.024564, lng: 18.45065 },
    { lat: 48.024849, lng: 18.450104 },
    { lat: 48.025098, lng: 18.449569 },
    { lat: 48.025925, lng: 18.449979 },
    { lat: 48.026841, lng: 18.44925 },
    { lat: 48.027852, lng: 18.448698 },
    { lat: 48.028179, lng: 18.448431 },
    { lat: 48.028451, lng: 18.448089 },
    { lat: 48.028463, lng: 18.448073 },
    { lat: 48.028927, lng: 18.447891 },
    { lat: 48.029602, lng: 18.447468 },
    { lat: 48.029846, lng: 18.447279 },
    { lat: 48.030525, lng: 18.446764 },
    { lat: 48.030675, lng: 18.446602 },
    { lat: 48.030923, lng: 18.446328 },
    { lat: 48.031081, lng: 18.446139 },
    { lat: 48.031181, lng: 18.446002 },
    { lat: 48.031209, lng: 18.445961 },
    { lat: 48.031761, lng: 18.445199 },
    { lat: 48.031833, lng: 18.445009 },
    { lat: 48.031856, lng: 18.444942 },
    { lat: 48.031914, lng: 18.444838 },
    { lat: 48.032058, lng: 18.44464 },
    { lat: 48.032108, lng: 18.444588 },
    { lat: 48.032127, lng: 18.444568 },
    { lat: 48.032315, lng: 18.444373 },
    { lat: 48.032539, lng: 18.444133 },
    { lat: 48.032973, lng: 18.443643 },
    { lat: 48.033138, lng: 18.443486 },
    { lat: 48.033288, lng: 18.443328 },
    { lat: 48.033532, lng: 18.443026 },
    { lat: 48.033608, lng: 18.442939 },
    { lat: 48.033759, lng: 18.442724 },
    { lat: 48.033902, lng: 18.442536 },
    { lat: 48.034107, lng: 18.442234 },
    { lat: 48.034387, lng: 18.441837 },
    { lat: 48.034462, lng: 18.441748 },
    { lat: 48.034614, lng: 18.441583 },
    { lat: 48.034767, lng: 18.441458 },
    { lat: 48.034789, lng: 18.441446 },
    { lat: 48.034931, lng: 18.441344 },
    { lat: 48.034963, lng: 18.441324 },
    { lat: 48.0371357, lng: 18.4360814 },
    { lat: 48.0375989, lng: 18.434829 },
    { lat: 48.037929, lng: 18.4335572 },
    { lat: 48.0384053, lng: 18.43099 },
    { lat: 48.0387276, lng: 18.4278568 },
    { lat: 48.03876, lng: 18.4274284 },
    { lat: 48.0387349, lng: 18.4268643 },
    { lat: 48.038571, lng: 18.4259761 },
    { lat: 48.0384237, lng: 18.4255648 },
    { lat: 48.03885, lng: 18.4223315 },
    { lat: 48.0390857, lng: 18.4212914 },
    { lat: 48.0397624, lng: 18.4200039 },
    { lat: 48.0405433, lng: 18.4188457 },
    { lat: 48.0418873, lng: 18.4162495 },
    { lat: 48.0428866, lng: 18.415084 },
    { lat: 48.0433133, lng: 18.4147277 },
    { lat: 48.0437825, lng: 18.4141481 },
    { lat: 48.0440844, lng: 18.4135554 },
    { lat: 48.044635, lng: 18.4122405 },
    { lat: 48.0448529, lng: 18.4119829 },
    { lat: 48.0457132, lng: 18.4112704 },
    { lat: 48.0459106, lng: 18.4110316 },
    { lat: 48.0467227, lng: 18.409448 },
    { lat: 48.0473537, lng: 18.4079138 },
    { lat: 48.0480794, lng: 18.4066976 },
    { lat: 48.0483968, lng: 18.4058257 },
    { lat: 48.0487087, lng: 18.4053155 },
    { lat: 48.0497751, lng: 18.4039839 },
    { lat: 48.050251, lng: 18.4030571 },
    { lat: 48.0513013, lng: 18.4022983 },
    { lat: 48.0515253, lng: 18.4020706 },
    { lat: 48.0519405, lng: 18.4013943 },
    { lat: 48.0526125, lng: 18.4008857 },
    { lat: 48.0535403, lng: 18.4004352 },
    { lat: 48.0539076, lng: 18.4003831 },
    { lat: 48.0536419, lng: 18.3979774 },
    { lat: 48.0537119, lng: 18.3962217 },
    { lat: 48.0536952, lng: 18.3957662 },
    { lat: 48.0535341, lng: 18.3950128 },
    { lat: 48.0528513, lng: 18.3943306 },
    { lat: 48.0527245, lng: 18.3943035 },
    { lat: 48.0518555, lng: 18.3937397 },
    { lat: 48.0514809, lng: 18.3936211 },
    { lat: 48.0497939, lng: 18.3925551 },
    { lat: 48.0495345, lng: 18.3922154 },
    { lat: 48.0488292, lng: 18.3917967 },
    { lat: 48.0484471, lng: 18.3913434 },
    { lat: 48.047615, lng: 18.3905138 },
    { lat: 48.0472042, lng: 18.3903931 },
    { lat: 48.0470707, lng: 18.3904077 },
    { lat: 48.0466871, lng: 18.390548 },
    { lat: 48.0458287, lng: 18.3907276 },
    { lat: 48.0455813, lng: 18.3909001 },
    { lat: 48.0449658, lng: 18.3908485 },
    { lat: 48.0445736, lng: 18.3906391 },
    { lat: 48.0442111, lng: 18.3905718 },
    { lat: 48.0433845, lng: 18.3901812 },
    { lat: 48.0420379, lng: 18.3900693 },
    { lat: 48.0417556, lng: 18.390144 },
    { lat: 48.0410858, lng: 18.389992 },
    { lat: 48.0404888, lng: 18.3893483 },
    { lat: 48.0398658, lng: 18.3884072 },
    { lat: 48.0390259, lng: 18.3886969 },
    { lat: 48.0381289, lng: 18.3886956 },
    { lat: 48.0377271, lng: 18.3888942 },
    { lat: 48.0369417, lng: 18.3894491 },
    { lat: 48.0358128, lng: 18.390133 },
    { lat: 48.0350977, lng: 18.3910333 },
    { lat: 48.034435, lng: 18.3919841 },
    { lat: 48.0336588, lng: 18.3928188 },
    { lat: 48.0331988, lng: 18.393522 },
    { lat: 48.032984, lng: 18.3937379 },
    { lat: 48.0323234, lng: 18.3939665 },
    { lat: 48.0317745, lng: 18.3939756 },
    { lat: 48.031093, lng: 18.3941899 },
    { lat: 48.0308878, lng: 18.3941949 },
    { lat: 48.0302889, lng: 18.3937585 },
    { lat: 48.0301755, lng: 18.3937477 },
    { lat: 48.0291773, lng: 18.3931358 },
    { lat: 48.0286867, lng: 18.3919812 },
    { lat: 48.028476, lng: 18.3905891 },
    { lat: 48.0276735, lng: 18.3895259 },
    { lat: 48.0267062, lng: 18.3886428 },
    { lat: 48.0261493, lng: 18.3882889 },
    { lat: 48.0260111, lng: 18.3877438 },
    { lat: 48.0251648, lng: 18.3872825 },
    { lat: 48.0244857, lng: 18.3866247 },
    { lat: 48.0239306, lng: 18.3865666 },
    { lat: 48.0237585, lng: 18.3864751 },
    { lat: 48.0233595, lng: 18.3865415 },
    { lat: 48.0226607, lng: 18.3865343 },
    { lat: 48.0225133, lng: 18.386482 },
    { lat: 48.0222009, lng: 18.3862045 },
    { lat: 48.0219228, lng: 18.3858166 },
    { lat: 48.0217419, lng: 18.3857478 },
    { lat: 48.0216084, lng: 18.3856039 },
    { lat: 48.0213787, lng: 18.3851119 },
    { lat: 48.0206308, lng: 18.3855521 },
    { lat: 48.019848, lng: 18.3859149 },
    { lat: 48.018837, lng: 18.3855925 },
    { lat: 48.0180026, lng: 18.3862796 },
    { lat: 48.0168969, lng: 18.3863377 },
    { lat: 48.0150028, lng: 18.386322 },
    { lat: 48.0132965, lng: 18.3864711 },
    { lat: 48.0124112, lng: 18.3866751 },
    { lat: 48.0121958, lng: 18.3864588 },
    { lat: 48.0121179, lng: 18.3866778 },
    { lat: 48.0117884, lng: 18.3871759 },
    { lat: 48.0116824, lng: 18.3875166 },
    { lat: 48.0116117, lng: 18.3875191 },
    { lat: 48.0116118, lng: 18.3874212 },
    { lat: 48.0109698, lng: 18.3879413 },
    { lat: 48.0100402, lng: 18.3885173 },
    { lat: 48.0098198, lng: 18.3885897 },
    { lat: 48.009668, lng: 18.3885602 },
    { lat: 48.0089479, lng: 18.3877552 },
    { lat: 48.008976, lng: 18.3877171 },
    { lat: 48.0087212, lng: 18.3872013 },
    { lat: 48.0073955, lng: 18.3846328 },
    { lat: 48.0065378, lng: 18.3847238 },
    { lat: 48.0061504, lng: 18.3840827 },
    { lat: 48.0050109, lng: 18.3843378 },
    { lat: 48.0033232, lng: 18.3841214 },
    { lat: 48.0020607, lng: 18.3832872 },
    { lat: 48.0014281, lng: 18.3822752 },
    { lat: 47.9999359, lng: 18.3794191 },
    { lat: 47.9995789, lng: 18.3785563 },
    { lat: 47.9989403, lng: 18.377382 },
    { lat: 47.9988872, lng: 18.3771418 },
    { lat: 47.997198, lng: 18.3742168 },
    { lat: 47.9959266, lng: 18.37521 },
    { lat: 47.9955628, lng: 18.3751604 },
    { lat: 47.9938093, lng: 18.3720793 },
    { lat: 47.9927221, lng: 18.3703363 },
    { lat: 47.9916969, lng: 18.3713376 },
    { lat: 47.9901011, lng: 18.3736906 },
    { lat: 47.9900881, lng: 18.37372 },
  ],
  Černík: [
    { lat: 48.1493318, lng: 18.2526167 },
    { lat: 48.1499434, lng: 18.2530456 },
    { lat: 48.1507104, lng: 18.254098 },
    { lat: 48.1500084, lng: 18.2580476 },
    { lat: 48.1517304, lng: 18.2583825 },
    { lat: 48.1540038, lng: 18.2593584 },
    { lat: 48.1554411, lng: 18.2597629 },
    { lat: 48.1560095, lng: 18.2602689 },
    { lat: 48.1562081, lng: 18.2605222 },
    { lat: 48.156191, lng: 18.2605583 },
    { lat: 48.1562088, lng: 18.2605751 },
    { lat: 48.1569286, lng: 18.2610606 },
    { lat: 48.1595725, lng: 18.2619877 },
    { lat: 48.1601716, lng: 18.2622785 },
    { lat: 48.1790829, lng: 18.2544208 },
    { lat: 48.1791115, lng: 18.2544083 },
    { lat: 48.1791528, lng: 18.2539529 },
    { lat: 48.1790902, lng: 18.2528091 },
    { lat: 48.1791904, lng: 18.252763 },
    { lat: 48.179705, lng: 18.2522183 },
    { lat: 48.1805114, lng: 18.2510767 },
    { lat: 48.1814173, lng: 18.2499575 },
    { lat: 48.1821058, lng: 18.2492157 },
    { lat: 48.1827406, lng: 18.2483291 },
    { lat: 48.1830395, lng: 18.2476244 },
    { lat: 48.1839412, lng: 18.246335 },
    { lat: 48.184307, lng: 18.245962 },
    { lat: 48.184287, lng: 18.245942 },
    { lat: 48.184172, lng: 18.244823 },
    { lat: 48.18383, lng: 18.244601 },
    { lat: 48.182886, lng: 18.243983 },
    { lat: 48.182286, lng: 18.243649 },
    { lat: 48.182233, lng: 18.24362 },
    { lat: 48.181994, lng: 18.243487 },
    { lat: 48.181933, lng: 18.243416 },
    { lat: 48.181892, lng: 18.243367 },
    { lat: 48.181505, lng: 18.242905 },
    { lat: 48.180854, lng: 18.241972 },
    { lat: 48.180434, lng: 18.241423 },
    { lat: 48.180112, lng: 18.240999 },
    { lat: 48.180088, lng: 18.240936 },
    { lat: 48.179812, lng: 18.239443 },
    { lat: 48.179391, lng: 18.238008 },
    { lat: 48.178951, lng: 18.236956 },
    { lat: 48.178971, lng: 18.23669 },
    { lat: 48.178898, lng: 18.236545 },
    { lat: 48.178938, lng: 18.236531 },
    { lat: 48.178728, lng: 18.235629 },
    { lat: 48.178698, lng: 18.235074 },
    { lat: 48.178653, lng: 18.234827 },
    { lat: 48.178516, lng: 18.234507 },
    { lat: 48.178252, lng: 18.233995 },
    { lat: 48.178153, lng: 18.233674 },
    { lat: 48.178023, lng: 18.232681 },
    { lat: 48.1780305, lng: 18.232119 },
    { lat: 48.178032, lng: 18.232005 },
    { lat: 48.178065, lng: 18.231603 },
    { lat: 48.177914, lng: 18.231158 },
    { lat: 48.177867, lng: 18.230809 },
    { lat: 48.177842, lng: 18.230423 },
    { lat: 48.177754, lng: 18.230147 },
    { lat: 48.177723, lng: 18.229686 },
    { lat: 48.177909, lng: 18.229461 },
    { lat: 48.178141, lng: 18.22899 },
    { lat: 48.178289, lng: 18.228655 },
    { lat: 48.17837, lng: 18.228324 },
    { lat: 48.178384, lng: 18.22818 },
    { lat: 48.178389, lng: 18.22795 },
    { lat: 48.178376, lng: 18.227736 },
    { lat: 48.178347, lng: 18.227298 },
    { lat: 48.178302, lng: 18.226955 },
    { lat: 48.178001, lng: 18.225425 },
    { lat: 48.177253, lng: 18.222796 },
    { lat: 48.176543, lng: 18.220486 },
    { lat: 48.176497, lng: 18.22037 },
    { lat: 48.175727, lng: 18.21893 },
    { lat: 48.174946, lng: 18.21745 },
    { lat: 48.174491, lng: 18.217005 },
    { lat: 48.174191, lng: 18.216788 },
    { lat: 48.173611, lng: 18.21639 },
    { lat: 48.173581, lng: 18.21637 },
    { lat: 48.17354, lng: 18.215509 },
    { lat: 48.173719, lng: 18.214895 },
    { lat: 48.17395, lng: 18.214093 },
    { lat: 48.173772, lng: 18.213411 },
    { lat: 48.17351, lng: 18.212977 },
    { lat: 48.173311, lng: 18.212768 },
    { lat: 48.173083, lng: 18.212526 },
    { lat: 48.172993, lng: 18.212431 },
    { lat: 48.173318, lng: 18.212149 },
    { lat: 48.173391, lng: 18.212073 },
    { lat: 48.173437, lng: 18.211922 },
    { lat: 48.173447, lng: 18.211645 },
    { lat: 48.173132, lng: 18.211249 },
    { lat: 48.173039, lng: 18.211133 },
    { lat: 48.172965, lng: 18.21088 },
    { lat: 48.172967, lng: 18.210827 },
    { lat: 48.172977, lng: 18.210642 },
    { lat: 48.173055, lng: 18.210475 },
    { lat: 48.173028, lng: 18.210429 },
    { lat: 48.173017, lng: 18.21034 },
    { lat: 48.17301, lng: 18.210176 },
    { lat: 48.172948, lng: 18.208734 },
    { lat: 48.172425, lng: 18.207372 },
    { lat: 48.170645, lng: 18.203558 },
    { lat: 48.170282, lng: 18.203353 },
    { lat: 48.170176, lng: 18.203437 },
    { lat: 48.170173, lng: 18.203384 },
    { lat: 48.170118, lng: 18.203194 },
    { lat: 48.169905, lng: 18.202758 },
    { lat: 48.169799, lng: 18.202449 },
    { lat: 48.169739, lng: 18.202304 },
    { lat: 48.169683, lng: 18.2022 },
    { lat: 48.169523, lng: 18.202022 },
    { lat: 48.169145, lng: 18.201673 },
    { lat: 48.169045, lng: 18.201463 },
    { lat: 48.168942, lng: 18.20112 },
    { lat: 48.168923, lng: 18.200877 },
    { lat: 48.168924, lng: 18.20075 },
    { lat: 48.168964, lng: 18.200309 },
    { lat: 48.169012, lng: 18.200147 },
    { lat: 48.169084, lng: 18.200071 },
    { lat: 48.169141, lng: 18.200065 },
    { lat: 48.169197, lng: 18.200092 },
    { lat: 48.16926, lng: 18.200168 },
    { lat: 48.169362, lng: 18.200342 },
    { lat: 48.169393, lng: 18.200409 },
    { lat: 48.169492, lng: 18.200534 },
    { lat: 48.169638, lng: 18.200613 },
    { lat: 48.169705, lng: 18.200617 },
    { lat: 48.169759, lng: 18.200604 },
    { lat: 48.169819, lng: 18.200516 },
    { lat: 48.169854, lng: 18.20041 },
    { lat: 48.16985, lng: 18.200299 },
    { lat: 48.16982, lng: 18.200153 },
    { lat: 48.169717, lng: 18.199759 },
    { lat: 48.169653, lng: 18.199587 },
    { lat: 48.169565, lng: 18.199442 },
    { lat: 48.169399, lng: 18.199265 },
    { lat: 48.169151, lng: 18.199039 },
    { lat: 48.169067, lng: 18.198889 },
    { lat: 48.168998, lng: 18.198672 },
    { lat: 48.168921, lng: 18.197926 },
    { lat: 48.168921, lng: 18.197276 },
    { lat: 48.16894, lng: 18.196952 },
    { lat: 48.168968, lng: 18.19689 },
    { lat: 48.169035, lng: 18.196834 },
    { lat: 48.169104, lng: 18.196847 },
    { lat: 48.169365, lng: 18.197049 },
    { lat: 48.169467, lng: 18.197128 },
    { lat: 48.169646, lng: 18.197101 },
    { lat: 48.169699, lng: 18.19704 },
    { lat: 48.169729, lng: 18.196984 },
    { lat: 48.169752, lng: 18.196872 },
    { lat: 48.169747, lng: 18.196668 },
    { lat: 48.169526, lng: 18.196103 },
    { lat: 48.169453, lng: 18.195917 },
    { lat: 48.169369, lng: 18.19553 },
    { lat: 48.169374, lng: 18.195278 },
    { lat: 48.169398, lng: 18.19518 },
    { lat: 48.169447, lng: 18.195104 },
    { lat: 48.169551, lng: 18.195046 },
    { lat: 48.169677, lng: 18.195037 },
    { lat: 48.169963, lng: 18.195071 },
    { lat: 48.170077, lng: 18.195111 },
    { lat: 48.170298, lng: 18.195278 },
    { lat: 48.170479, lng: 18.195441 },
    { lat: 48.170638, lng: 18.195567 },
    { lat: 48.170719, lng: 18.195585 },
    { lat: 48.17085, lng: 18.195568 },
    { lat: 48.170972, lng: 18.19549 },
    { lat: 48.171136, lng: 18.195316 },
    { lat: 48.171241, lng: 18.195141 },
    { lat: 48.171308, lng: 18.194859 },
    { lat: 48.171264, lng: 18.194597 },
    { lat: 48.17117, lng: 18.19449 },
    { lat: 48.170948, lng: 18.194365 },
    { lat: 48.170839, lng: 18.194331 },
    { lat: 48.170826, lng: 18.194293 },
    { lat: 48.170416, lng: 18.194357 },
    { lat: 48.170106, lng: 18.19434 },
    { lat: 48.1698, lng: 18.194238 },
    { lat: 48.169665, lng: 18.194151 },
    { lat: 48.169636, lng: 18.194119 },
    { lat: 48.169611, lng: 18.194078 },
    { lat: 48.169591, lng: 18.193982 },
    { lat: 48.169565, lng: 18.193733 },
    { lat: 48.169532, lng: 18.193605 },
    { lat: 48.169435, lng: 18.1934 },
    { lat: 48.169363, lng: 18.193296 },
    { lat: 48.169129, lng: 18.193089 },
    { lat: 48.168999, lng: 18.193007 },
    { lat: 48.168848, lng: 18.192904 },
    { lat: 48.168749, lng: 18.192769 },
    { lat: 48.168687, lng: 18.192643 },
    { lat: 48.168665, lng: 18.192544 },
    { lat: 48.1663087, lng: 18.1961089 },
    { lat: 48.1620748, lng: 18.2027449 },
    { lat: 48.1585118, lng: 18.2057079 },
    { lat: 48.1549517, lng: 18.2085259 },
    { lat: 48.1542982, lng: 18.2090076 },
    { lat: 48.1508207, lng: 18.2089175 },
    { lat: 48.1466042, lng: 18.208576 },
    { lat: 48.1440876, lng: 18.2112162 },
    { lat: 48.1420097, lng: 18.2134224 },
    { lat: 48.1430709, lng: 18.2172437 },
    { lat: 48.1436743, lng: 18.2182078 },
    { lat: 48.1448959, lng: 18.2221333 },
    { lat: 48.1454969, lng: 18.2237384 },
    { lat: 48.1463119, lng: 18.2250675 },
    { lat: 48.1449606, lng: 18.2264245 },
    { lat: 48.1455678, lng: 18.2281581 },
    { lat: 48.14718, lng: 18.2270031 },
    { lat: 48.1473371, lng: 18.2274944 },
    { lat: 48.1476155, lng: 18.2272962 },
    { lat: 48.1486494, lng: 18.2299324 },
    { lat: 48.1494652, lng: 18.2327396 },
    { lat: 48.1509814, lng: 18.2371218 },
    { lat: 48.1517495, lng: 18.2397869 },
    { lat: 48.1519887, lng: 18.2409009 },
    { lat: 48.1530518, lng: 18.2438535 },
    { lat: 48.1536918, lng: 18.2458286 },
    { lat: 48.1536967, lng: 18.2459542 },
    { lat: 48.1531586, lng: 18.2465211 },
    { lat: 48.1526903, lng: 18.2472278 },
    { lat: 48.1526078, lng: 18.2476172 },
    { lat: 48.1521748, lng: 18.2482462 },
    { lat: 48.1517906, lng: 18.2486081 },
    { lat: 48.1523972, lng: 18.2504168 },
    { lat: 48.1497429, lng: 18.2522916 },
    { lat: 48.1493318, lng: 18.2526167 },
  ],
  Dedinka: [
    { lat: 48.094771, lng: 18.4026272 },
    { lat: 48.09477, lng: 18.4025899 },
    { lat: 48.0948017, lng: 18.4001519 },
    { lat: 48.0947464, lng: 18.3992131 },
    { lat: 48.0948916, lng: 18.3980077 },
    { lat: 48.0953112, lng: 18.3932327 },
    { lat: 48.0953173, lng: 18.3912228 },
    { lat: 48.0950296, lng: 18.3894077 },
    { lat: 48.0947139, lng: 18.3862219 },
    { lat: 48.0946821, lng: 18.383366 },
    { lat: 48.0944004, lng: 18.3813967 },
    { lat: 48.0943547, lng: 18.3806091 },
    { lat: 48.0941983, lng: 18.3794219 },
    { lat: 48.0940768, lng: 18.378729 },
    { lat: 48.093886, lng: 18.3780691 },
    { lat: 48.0930335, lng: 18.3756697 },
    { lat: 48.0928342, lng: 18.3749189 },
    { lat: 48.0923831, lng: 18.3751233 },
    { lat: 48.092213, lng: 18.3752835 },
    { lat: 48.0916085, lng: 18.3759276 },
    { lat: 48.0908995, lng: 18.3768167 },
    { lat: 48.0905043, lng: 18.3771687 },
    { lat: 48.0900882, lng: 18.3777106 },
    { lat: 48.0899472, lng: 18.3778092 },
    { lat: 48.0897547, lng: 18.3780541 },
    { lat: 48.0893613, lng: 18.3789778 },
    { lat: 48.0887971, lng: 18.3797515 },
    { lat: 48.0877368, lng: 18.3804961 },
    { lat: 48.087546, lng: 18.3807045 },
    { lat: 48.0869157, lng: 18.3807161 },
    { lat: 48.0861979, lng: 18.3812921 },
    { lat: 48.0859364, lng: 18.3814341 },
    { lat: 48.0857371, lng: 18.3814621 },
    { lat: 48.0856271, lng: 18.3813945 },
    { lat: 48.0854572, lng: 18.3814417 },
    { lat: 48.0848513, lng: 18.381818 },
    { lat: 48.0843895, lng: 18.3819298 },
    { lat: 48.0827452, lng: 18.3829399 },
    { lat: 48.0818965, lng: 18.3831333 },
    { lat: 48.0810698, lng: 18.3831003 },
    { lat: 48.0803057, lng: 18.3832125 },
    { lat: 48.0795033, lng: 18.3835885 },
    { lat: 48.0788037, lng: 18.3838262 },
    { lat: 48.078127, lng: 18.3834494 },
    { lat: 48.0774276, lng: 18.3834518 },
    { lat: 48.0758961, lng: 18.3830264 },
    { lat: 48.0754928, lng: 18.3830138 },
    { lat: 48.0728231, lng: 18.3836684 },
    { lat: 48.0722862, lng: 18.3835506 },
    { lat: 48.0709362, lng: 18.3838174 },
    { lat: 48.0704198, lng: 18.384481 },
    { lat: 48.0697061, lng: 18.3846863 },
    { lat: 48.0688603, lng: 18.3851925 },
    { lat: 48.068157, lng: 18.385466 },
    { lat: 48.0679073, lng: 18.3856992 },
    { lat: 48.0671471, lng: 18.3860588 },
    { lat: 48.066355, lng: 18.3865963 },
    { lat: 48.0656716, lng: 18.3873796 },
    { lat: 48.064771, lng: 18.388251 },
    { lat: 48.0644787, lng: 18.3886878 },
    { lat: 48.0638007, lng: 18.3894655 },
    { lat: 48.0633752, lng: 18.3900019 },
    { lat: 48.0627678, lng: 18.3905319 },
    { lat: 48.0616972, lng: 18.3917924 },
    { lat: 48.0610933, lng: 18.3928647 },
    { lat: 48.0602517, lng: 18.3936597 },
    { lat: 48.059831, lng: 18.3939267 },
    { lat: 48.0592429, lng: 18.3947187 },
    { lat: 48.0587074, lng: 18.3952233 },
    { lat: 48.0581788, lng: 18.3960692 },
    { lat: 48.0578841, lng: 18.3963259 },
    { lat: 48.0575583, lng: 18.3969064 },
    { lat: 48.0573577, lng: 18.3971514 },
    { lat: 48.0563679, lng: 18.3980054 },
    { lat: 48.0562602, lng: 18.3981748 },
    { lat: 48.0559425, lng: 18.3991802 },
    { lat: 48.0557908, lng: 18.3994334 },
    { lat: 48.0553125, lng: 18.3999125 },
    { lat: 48.0546217, lng: 18.4003868 },
    { lat: 48.0539391, lng: 18.4003851 },
    { lat: 48.0539076, lng: 18.4003831 },
    { lat: 48.0535403, lng: 18.4004352 },
    { lat: 48.0526125, lng: 18.4008857 },
    { lat: 48.0519405, lng: 18.4013943 },
    { lat: 48.0515253, lng: 18.4020706 },
    { lat: 48.0513013, lng: 18.4022983 },
    { lat: 48.050251, lng: 18.4030571 },
    { lat: 48.0497751, lng: 18.4039839 },
    { lat: 48.0487087, lng: 18.4053155 },
    { lat: 48.0483968, lng: 18.4058257 },
    { lat: 48.0480794, lng: 18.4066976 },
    { lat: 48.0473537, lng: 18.4079138 },
    { lat: 48.0467227, lng: 18.409448 },
    { lat: 48.0459106, lng: 18.4110316 },
    { lat: 48.0457132, lng: 18.4112704 },
    { lat: 48.0448529, lng: 18.4119829 },
    { lat: 48.044635, lng: 18.4122405 },
    { lat: 48.0440844, lng: 18.4135554 },
    { lat: 48.0437825, lng: 18.4141481 },
    { lat: 48.0433133, lng: 18.4147277 },
    { lat: 48.0428866, lng: 18.415084 },
    { lat: 48.0418873, lng: 18.4162495 },
    { lat: 48.0405433, lng: 18.4188457 },
    { lat: 48.0397624, lng: 18.4200039 },
    { lat: 48.0390857, lng: 18.4212914 },
    { lat: 48.03885, lng: 18.4223315 },
    { lat: 48.0384237, lng: 18.4255648 },
    { lat: 48.038571, lng: 18.4259761 },
    { lat: 48.0387349, lng: 18.4268643 },
    { lat: 48.03876, lng: 18.4274284 },
    { lat: 48.0387276, lng: 18.4278568 },
    { lat: 48.0384053, lng: 18.43099 },
    { lat: 48.037929, lng: 18.4335572 },
    { lat: 48.0375989, lng: 18.434829 },
    { lat: 48.0371357, lng: 18.4360814 },
    { lat: 48.034963, lng: 18.441324 },
    { lat: 48.035002, lng: 18.441273 },
    { lat: 48.035036, lng: 18.441223 },
    { lat: 48.035097, lng: 18.441141 },
    { lat: 48.03513, lng: 18.441094 },
    { lat: 48.035168, lng: 18.441045 },
    { lat: 48.035203, lng: 18.440995 },
    { lat: 48.035264, lng: 18.440909 },
    { lat: 48.03528, lng: 18.440886 },
    { lat: 48.035297, lng: 18.440868 },
    { lat: 48.035326, lng: 18.440824 },
    { lat: 48.035368, lng: 18.440775 },
    { lat: 48.035432, lng: 18.440715 },
    { lat: 48.035465, lng: 18.440688 },
    { lat: 48.0355, lng: 18.440658 },
    { lat: 48.035523, lng: 18.440638 },
    { lat: 48.035562, lng: 18.440611 },
    { lat: 48.035582, lng: 18.440591 },
    { lat: 48.035663, lng: 18.440527 },
    { lat: 48.035739, lng: 18.440467 },
    { lat: 48.035775, lng: 18.440437 },
    { lat: 48.035816, lng: 18.440403 },
    { lat: 48.035853, lng: 18.440373 },
    { lat: 48.035943, lng: 18.440301 },
    { lat: 48.036018, lng: 18.440259 },
    { lat: 48.03606, lng: 18.440234 },
    { lat: 48.036105, lng: 18.440207 },
    { lat: 48.03615, lng: 18.440182 },
    { lat: 48.036225, lng: 18.440139 },
    { lat: 48.036273, lng: 18.44011 },
    { lat: 48.036319, lng: 18.440083 },
    { lat: 48.036345, lng: 18.44007 },
    { lat: 48.036407, lng: 18.440035 },
    { lat: 48.036453, lng: 18.440015 },
    { lat: 48.036503, lng: 18.43999 },
    { lat: 48.036552, lng: 18.439966 },
    { lat: 48.036602, lng: 18.43994 },
    { lat: 48.036716, lng: 18.439886 },
    { lat: 48.037039, lng: 18.439731 },
    { lat: 48.037455, lng: 18.439619 },
    { lat: 48.037665, lng: 18.439599 },
    { lat: 48.037803, lng: 18.439585 },
    { lat: 48.038604, lng: 18.439599 },
    { lat: 48.039919, lng: 18.439648 },
    { lat: 48.040558, lng: 18.440374 },
    { lat: 48.040991, lng: 18.441085 },
    { lat: 48.040993, lng: 18.44109 },
    { lat: 48.040994, lng: 18.441092 },
    { lat: 48.041031, lng: 18.441156 },
    { lat: 48.041032, lng: 18.441157 },
    { lat: 48.041034, lng: 18.441162 },
    { lat: 48.041135, lng: 18.441319 },
    { lat: 48.041902, lng: 18.442528 },
    { lat: 48.041919, lng: 18.442555 },
    { lat: 48.041927, lng: 18.442565 },
    { lat: 48.041954, lng: 18.442608 },
    { lat: 48.042128, lng: 18.442891 },
    { lat: 48.04215, lng: 18.442927 },
    { lat: 48.042201, lng: 18.443009 },
    { lat: 48.042407, lng: 18.443346 },
    { lat: 48.042889, lng: 18.443791 },
    { lat: 48.04291, lng: 18.443813 },
    { lat: 48.042959, lng: 18.443854 },
    { lat: 48.043655, lng: 18.444345 },
    { lat: 48.044362, lng: 18.444723 },
    { lat: 48.044729, lng: 18.445144 },
    { lat: 48.045135, lng: 18.445611 },
    { lat: 48.045476, lng: 18.445994 },
    { lat: 48.046439, lng: 18.446386 },
    { lat: 48.046659, lng: 18.446472 },
    { lat: 48.047267, lng: 18.446345 },
    { lat: 48.047995, lng: 18.44619 },
    { lat: 48.048455, lng: 18.446366 },
    { lat: 48.048527, lng: 18.446412 },
    { lat: 48.048655, lng: 18.446493 },
    { lat: 48.048673, lng: 18.44651 },
    { lat: 48.048695, lng: 18.446533 },
    { lat: 48.048851, lng: 18.446399 },
    { lat: 48.049115, lng: 18.446346 },
    { lat: 48.049284, lng: 18.446329 },
    { lat: 48.049444, lng: 18.446353 },
    { lat: 48.049866, lng: 18.446506 },
    { lat: 48.050204, lng: 18.446665 },
    { lat: 48.050581, lng: 18.446659 },
    { lat: 48.05117, lng: 18.446354 },
    { lat: 48.051559, lng: 18.446184 },
    { lat: 48.051763, lng: 18.446111 },
    { lat: 48.052171, lng: 18.446049 },
    { lat: 48.052438, lng: 18.445978 },
    { lat: 48.052717, lng: 18.445839 },
    { lat: 48.053293, lng: 18.445612 },
    { lat: 48.054058, lng: 18.445258 },
    { lat: 48.054215, lng: 18.445206 },
    { lat: 48.054389, lng: 18.445181 },
    { lat: 48.05503, lng: 18.445199 },
    { lat: 48.05538, lng: 18.445271 },
    { lat: 48.055387, lng: 18.44523 },
    { lat: 48.055527, lng: 18.44529 },
    { lat: 48.055754, lng: 18.445313 },
    { lat: 48.056252, lng: 18.445301 },
    { lat: 48.056573, lng: 18.445266 },
    { lat: 48.056845, lng: 18.445216 },
    { lat: 48.057256, lng: 18.44511 },
    { lat: 48.057694, lng: 18.444956 },
    { lat: 48.057991, lng: 18.444805 },
    { lat: 48.058254, lng: 18.444647 },
    { lat: 48.05844, lng: 18.444447 },
    { lat: 48.059012, lng: 18.443632 },
    { lat: 48.059378, lng: 18.443287 },
    { lat: 48.059711, lng: 18.442908 },
    { lat: 48.059732, lng: 18.442884 },
    { lat: 48.0598, lng: 18.442778 },
    { lat: 48.059879, lng: 18.442655 },
    { lat: 48.060325, lng: 18.441974 },
    { lat: 48.060496, lng: 18.441653 },
    { lat: 48.060618, lng: 18.441333 },
    { lat: 48.06108, lng: 18.440001 },
    { lat: 48.061284, lng: 18.439291 },
    { lat: 48.061358, lng: 18.439019 },
    { lat: 48.06189, lng: 18.437464 },
    { lat: 48.061984, lng: 18.437229 },
    { lat: 48.062011, lng: 18.437162 },
    { lat: 48.062568, lng: 18.436093 },
    { lat: 48.062738, lng: 18.435817 },
    { lat: 48.063042, lng: 18.435388 },
    { lat: 48.06326, lng: 18.435162 },
    { lat: 48.063473, lng: 18.434959 },
    { lat: 48.06442, lng: 18.433916 },
    { lat: 48.065593, lng: 18.432869 },
    { lat: 48.065924, lng: 18.432547 },
    { lat: 48.066667, lng: 18.431923 },
    { lat: 48.066905, lng: 18.431686 },
    { lat: 48.06695, lng: 18.431636 },
    { lat: 48.067096, lng: 18.431472 },
    { lat: 48.067286, lng: 18.431227 },
    { lat: 48.067696, lng: 18.430657 },
    { lat: 48.067776, lng: 18.430542 },
    { lat: 48.067788, lng: 18.430561 },
    { lat: 48.068101, lng: 18.430282 },
    { lat: 48.068411, lng: 18.430017 },
    { lat: 48.068829, lng: 18.429617 },
    { lat: 48.069368, lng: 18.429203 },
    { lat: 48.069871, lng: 18.428894 },
    { lat: 48.070448, lng: 18.428558 },
    { lat: 48.070798, lng: 18.428437 },
    { lat: 48.071002, lng: 18.428406 },
    { lat: 48.071156, lng: 18.428334 },
    { lat: 48.071179, lng: 18.428322 },
    { lat: 48.071285, lng: 18.428235 },
    { lat: 48.071362, lng: 18.428171 },
    { lat: 48.071744, lng: 18.42777 },
    { lat: 48.071861, lng: 18.427664 },
    { lat: 48.072004, lng: 18.427555 },
    { lat: 48.072202, lng: 18.427458 },
    { lat: 48.072596, lng: 18.427309 },
    { lat: 48.072806, lng: 18.427251 },
    { lat: 48.072941, lng: 18.427213 },
    { lat: 48.073478, lng: 18.427052 },
    { lat: 48.073732, lng: 18.426897 },
    { lat: 48.074009, lng: 18.42669 },
    { lat: 48.074421, lng: 18.426491 },
    { lat: 48.074538, lng: 18.426451 },
    { lat: 48.074932, lng: 18.426438 },
    { lat: 48.075038, lng: 18.426424 },
    { lat: 48.075104, lng: 18.426413 },
    { lat: 48.075235, lng: 18.426374 },
    { lat: 48.075392, lng: 18.426346 },
    { lat: 48.07545, lng: 18.426266 },
    { lat: 48.075662, lng: 18.426092 },
    { lat: 48.075948, lng: 18.425858 },
    { lat: 48.076123, lng: 18.425769 },
    { lat: 48.076366, lng: 18.425673 },
    { lat: 48.076858, lng: 18.425572 },
    { lat: 48.077388, lng: 18.425505 },
    { lat: 48.077536, lng: 18.425468 },
    { lat: 48.077753, lng: 18.425381 },
    { lat: 48.077928, lng: 18.42529 },
    { lat: 48.078076, lng: 18.425192 },
    { lat: 48.078108, lng: 18.425182 },
    { lat: 48.078343, lng: 18.425106 },
    { lat: 48.079341, lng: 18.424693 },
    { lat: 48.079745, lng: 18.42449 },
    { lat: 48.080057, lng: 18.424407 },
    { lat: 48.080463, lng: 18.424269 },
    { lat: 48.080787, lng: 18.424166 },
    { lat: 48.081077, lng: 18.423997 },
    { lat: 48.081485, lng: 18.423805 },
    { lat: 48.081736, lng: 18.423672 },
    { lat: 48.081949, lng: 18.423527 },
    { lat: 48.08233, lng: 18.423384 },
    { lat: 48.082677, lng: 18.423246 },
    { lat: 48.082774, lng: 18.42323 },
    { lat: 48.082854, lng: 18.423357 },
    { lat: 48.083067, lng: 18.42327 },
    { lat: 48.083481, lng: 18.423072 },
    { lat: 48.083514, lng: 18.422913 },
    { lat: 48.083729, lng: 18.422823 },
    { lat: 48.084041, lng: 18.422557 },
    { lat: 48.084385, lng: 18.422323 },
    { lat: 48.085079, lng: 18.422019 },
    { lat: 48.08544, lng: 18.421924 },
    { lat: 48.08573, lng: 18.421637 },
    { lat: 48.086055, lng: 18.421412 },
    { lat: 48.086394, lng: 18.421235 },
    { lat: 48.086729, lng: 18.420997 },
    { lat: 48.087079, lng: 18.420886 },
    { lat: 48.087138, lng: 18.420856 },
    { lat: 48.0871582, lng: 18.4207923 },
    { lat: 48.0876644, lng: 18.4205845 },
    { lat: 48.0886688, lng: 18.4200102 },
    { lat: 48.0895866, lng: 18.4190168 },
    { lat: 48.0892087, lng: 18.4170734 },
    { lat: 48.0886315, lng: 18.4126547 },
    { lat: 48.0877224, lng: 18.4076539 },
    { lat: 48.0884051, lng: 18.4072756 },
    { lat: 48.0888795, lng: 18.4068466 },
    { lat: 48.0897976, lng: 18.4064797 },
    { lat: 48.0902285, lng: 18.4062396 },
    { lat: 48.0906486, lng: 18.4059295 },
    { lat: 48.0913721, lng: 18.4051138 },
    { lat: 48.0921206, lng: 18.4044001 },
    { lat: 48.0930118, lng: 18.40372 },
    { lat: 48.0946286, lng: 18.4027015 },
    { lat: 48.094771, lng: 18.4026272 },
  ],
  VeľkýKýr: [
    { lat: 48.20074, lng: 18.174804 },
    { lat: 48.200728, lng: 18.174779 },
    { lat: 48.200127, lng: 18.173122 },
    { lat: 48.200089, lng: 18.172987 },
    { lat: 48.199861, lng: 18.1722 },
    { lat: 48.199765, lng: 18.171913 },
    { lat: 48.199408, lng: 18.170829 },
    { lat: 48.19934, lng: 18.170624 },
    { lat: 48.199083, lng: 18.169851 },
    { lat: 48.19882, lng: 18.168905 },
    { lat: 48.198751, lng: 18.168662 },
    { lat: 48.198695, lng: 18.168469 },
    { lat: 48.198335, lng: 18.167203 },
    { lat: 48.198027, lng: 18.166134 },
    { lat: 48.197762, lng: 18.165201 },
    { lat: 48.197444, lng: 18.164214 },
    { lat: 48.197034, lng: 18.162924 },
    { lat: 48.196708, lng: 18.162072 },
    { lat: 48.196332, lng: 18.160894 },
    { lat: 48.19597, lng: 18.159585 },
    { lat: 48.195715, lng: 18.158615 },
    { lat: 48.195643, lng: 18.158339 },
    { lat: 48.195361, lng: 18.157169 },
    { lat: 48.194914, lng: 18.155395 },
    { lat: 48.194624, lng: 18.154052 },
    { lat: 48.194347, lng: 18.152863 },
    { lat: 48.194285, lng: 18.152597 },
    { lat: 48.194097, lng: 18.151701 },
    { lat: 48.194075, lng: 18.151599 },
    { lat: 48.194037, lng: 18.151418 },
    { lat: 48.193966, lng: 18.151063 },
    { lat: 48.193904, lng: 18.150796 },
    { lat: 48.193883, lng: 18.1507 },
    { lat: 48.19397, lng: 18.150678 },
    { lat: 48.194036, lng: 18.150681 },
    { lat: 48.194087, lng: 18.150731 },
    { lat: 48.194152, lng: 18.150818 },
    { lat: 48.194215, lng: 18.15096 },
    { lat: 48.194243, lng: 18.151093 },
    { lat: 48.194294, lng: 18.151373 },
    { lat: 48.194325, lng: 18.151559 },
    { lat: 48.19439, lng: 18.151918 },
    { lat: 48.194461, lng: 18.152156 },
    { lat: 48.194611, lng: 18.152549 },
    { lat: 48.194725, lng: 18.152814 },
    { lat: 48.194839, lng: 18.153119 },
    { lat: 48.19493, lng: 18.153487 },
    { lat: 48.194958, lng: 18.153599 },
    { lat: 48.194981, lng: 18.153666 },
    { lat: 48.195035, lng: 18.153746 },
    { lat: 48.19516, lng: 18.153859 },
    { lat: 48.19548, lng: 18.153941 },
    { lat: 48.195633, lng: 18.153977 },
    { lat: 48.195959, lng: 18.153953 },
    { lat: 48.19606, lng: 18.153758 },
    { lat: 48.196068, lng: 18.153723 },
    { lat: 48.196075, lng: 18.153679 },
    { lat: 48.196073, lng: 18.153627 },
    { lat: 48.196062, lng: 18.15356 },
    { lat: 48.196027, lng: 18.153445 },
    { lat: 48.195988, lng: 18.153346 },
    { lat: 48.195945, lng: 18.153251 },
    { lat: 48.195763, lng: 18.152917 },
    { lat: 48.195714, lng: 18.152817 },
    { lat: 48.19566, lng: 18.152624 },
    { lat: 48.195641, lng: 18.152551 },
    { lat: 48.195652, lng: 18.152324 },
    { lat: 48.195709, lng: 18.152178 },
    { lat: 48.195946, lng: 18.151881 },
    { lat: 48.196043, lng: 18.15175 },
    { lat: 48.196334, lng: 18.151332 },
    { lat: 48.19645, lng: 18.151011 },
    { lat: 48.196535, lng: 18.150774 },
    { lat: 48.196572, lng: 18.150597 },
    { lat: 48.196627, lng: 18.150309 },
    { lat: 48.19664, lng: 18.150203 },
    { lat: 48.196657, lng: 18.150125 },
    { lat: 48.196685, lng: 18.149976 },
    { lat: 48.196746, lng: 18.149809 },
    { lat: 48.196765, lng: 18.149767 },
    { lat: 48.196835, lng: 18.149661 },
    { lat: 48.196881, lng: 18.149612 },
    { lat: 48.196919, lng: 18.149575 },
    { lat: 48.197077, lng: 18.149549 },
    { lat: 48.197114, lng: 18.149582 },
    { lat: 48.19717, lng: 18.149643 },
    { lat: 48.197207, lng: 18.149765 },
    { lat: 48.197201, lng: 18.150122 },
    { lat: 48.197253, lng: 18.150414 },
    { lat: 48.197261, lng: 18.150457 },
    { lat: 48.197284, lng: 18.150519 },
    { lat: 48.197378, lng: 18.150695 },
    { lat: 48.197414, lng: 18.150768 },
    { lat: 48.197523, lng: 18.151104 },
    { lat: 48.197544, lng: 18.151142 },
    { lat: 48.197584, lng: 18.151172 },
    { lat: 48.197648, lng: 18.151197 },
    { lat: 48.197674, lng: 18.151184 },
    { lat: 48.197823, lng: 18.15108 },
    { lat: 48.197869, lng: 18.151039 },
    { lat: 48.198235, lng: 18.150411 },
    { lat: 48.198213, lng: 18.150355 },
    { lat: 48.198161, lng: 18.150226 },
    { lat: 48.19785, lng: 18.149394 },
    { lat: 48.197691, lng: 18.148914 },
    { lat: 48.197492, lng: 18.148316 },
    { lat: 48.197306, lng: 18.147695 },
    { lat: 48.1973, lng: 18.147674 },
    { lat: 48.197248, lng: 18.147512 },
    { lat: 48.197239, lng: 18.147485 },
    { lat: 48.197169, lng: 18.14725 },
    { lat: 48.197054, lng: 18.146868 },
    { lat: 48.196785, lng: 18.145973 },
    { lat: 48.196703, lng: 18.145697 },
    { lat: 48.196598, lng: 18.145344 },
    { lat: 48.196543, lng: 18.145158 },
    { lat: 48.196516, lng: 18.145067 },
    { lat: 48.196456, lng: 18.144866 },
    { lat: 48.196195, lng: 18.144005 },
    { lat: 48.196116, lng: 18.143761 },
    { lat: 48.1961, lng: 18.143706 },
    { lat: 48.196089, lng: 18.143665 },
    { lat: 48.19603, lng: 18.143453 },
    { lat: 48.195946, lng: 18.143125 },
    { lat: 48.195843, lng: 18.142817 },
    { lat: 48.195789, lng: 18.142508 },
    { lat: 48.195771, lng: 18.142409 },
    { lat: 48.195708, lng: 18.142071 },
    { lat: 48.195673, lng: 18.141902 },
    { lat: 48.195588, lng: 18.141544 },
    { lat: 48.195553, lng: 18.141401 },
    { lat: 48.195358, lng: 18.14075 },
    { lat: 48.195315, lng: 18.140609 },
    { lat: 48.195234, lng: 18.140345 },
    { lat: 48.195074, lng: 18.139996 },
    { lat: 48.19504, lng: 18.139925 },
    { lat: 48.195021, lng: 18.139884 },
    { lat: 48.194956, lng: 18.139974 },
    { lat: 48.19491, lng: 18.14004 },
    { lat: 48.194866, lng: 18.140086 },
    { lat: 48.194841, lng: 18.140114 },
    { lat: 48.194829, lng: 18.14013 },
    { lat: 48.194815, lng: 18.140148 },
    { lat: 48.194788, lng: 18.140171 },
    { lat: 48.194768, lng: 18.140192 },
    { lat: 48.194744, lng: 18.140129 },
    { lat: 48.194729, lng: 18.140089 },
    { lat: 48.194717, lng: 18.140057 },
    { lat: 48.194702, lng: 18.140005 },
    { lat: 48.194614, lng: 18.139705 },
    { lat: 48.194599, lng: 18.139635 },
    { lat: 48.194551, lng: 18.139295 },
    { lat: 48.194485, lng: 18.138726 },
    { lat: 48.194429, lng: 18.13832 },
    { lat: 48.19438, lng: 18.13805 },
    { lat: 48.194337, lng: 18.137815 },
    { lat: 48.194282, lng: 18.137503 },
    { lat: 48.194228, lng: 18.137183 },
    { lat: 48.194145, lng: 18.136652 },
    { lat: 48.194104, lng: 18.136147 },
    { lat: 48.194016, lng: 18.135015 },
    { lat: 48.193983, lng: 18.134573 },
    { lat: 48.193979, lng: 18.134526 },
    { lat: 48.193977, lng: 18.134496 },
    { lat: 48.193892, lng: 18.134093 },
    { lat: 48.193774, lng: 18.133539 },
    { lat: 48.193505, lng: 18.132294 },
    { lat: 48.193385, lng: 18.13176 },
    { lat: 48.193332, lng: 18.131549 },
    { lat: 48.193316, lng: 18.131501 },
    { lat: 48.19322, lng: 18.131539 },
    { lat: 48.193086, lng: 18.131634 },
    { lat: 48.192891, lng: 18.131645 },
    { lat: 48.192736, lng: 18.131706 },
    { lat: 48.19267, lng: 18.13174 },
    { lat: 48.19241, lng: 18.131983 },
    { lat: 48.192158, lng: 18.132188 },
    { lat: 48.191913, lng: 18.132408 },
    { lat: 48.191732, lng: 18.132569 },
    { lat: 48.191718, lng: 18.132506 },
    { lat: 48.191609, lng: 18.132602 },
    { lat: 48.191305, lng: 18.132871 },
    { lat: 48.190995, lng: 18.133126 },
    { lat: 48.190721, lng: 18.13333 },
    { lat: 48.190346, lng: 18.133605 },
    { lat: 48.190056, lng: 18.133824 },
    { lat: 48.189808, lng: 18.133913 },
    { lat: 48.189784, lng: 18.133861 },
    { lat: 48.189408, lng: 18.132906 },
    { lat: 48.189147, lng: 18.132234 },
    { lat: 48.188915, lng: 18.131661 },
    { lat: 48.188596, lng: 18.130876 },
    { lat: 48.188283, lng: 18.130102 },
    { lat: 48.188041, lng: 18.129509 },
    { lat: 48.187908, lng: 18.129194 },
    { lat: 48.187631, lng: 18.12852 },
    { lat: 48.187371, lng: 18.1279 },
    { lat: 48.187243, lng: 18.127599 },
    { lat: 48.187, lng: 18.127007 },
    { lat: 48.186795, lng: 18.126507 },
    { lat: 48.186568, lng: 18.125962 },
    { lat: 48.186387, lng: 18.125525 },
    { lat: 48.18618, lng: 18.12501 },
    { lat: 48.186061, lng: 18.124731 },
    { lat: 48.185925, lng: 18.124399 },
    { lat: 48.185767, lng: 18.124007 },
    { lat: 48.1856, lng: 18.123598 },
    { lat: 48.185483, lng: 18.123324 },
    { lat: 48.185415, lng: 18.12315 },
    { lat: 48.185188, lng: 18.122595 },
    { lat: 48.185028, lng: 18.122194 },
    { lat: 48.184861, lng: 18.121799 },
    { lat: 48.18464, lng: 18.121253 },
    { lat: 48.184494, lng: 18.120907 },
    { lat: 48.184248, lng: 18.120303 },
    { lat: 48.184117, lng: 18.119985 },
    { lat: 48.184035, lng: 18.119767 },
    { lat: 48.18385, lng: 18.119338 },
    { lat: 48.183677, lng: 18.118901 },
    { lat: 48.183508, lng: 18.118493 },
    { lat: 48.183299, lng: 18.117982 },
    { lat: 48.18315, lng: 18.117613 },
    { lat: 48.182858, lng: 18.116891 },
    { lat: 48.182721, lng: 18.116554 },
    { lat: 48.182541, lng: 18.116124 },
    { lat: 48.182273, lng: 18.115456 },
    { lat: 48.182098, lng: 18.115015 },
    { lat: 48.181853, lng: 18.114402 },
    { lat: 48.181651, lng: 18.114041 },
    { lat: 48.181403, lng: 18.113588 },
    { lat: 48.181151, lng: 18.113106 },
    { lat: 48.180858, lng: 18.112579 },
    { lat: 48.180588, lng: 18.112075 },
    { lat: 48.180356, lng: 18.111644 },
    { lat: 48.180123, lng: 18.111225 },
    { lat: 48.179803, lng: 18.110624 },
    { lat: 48.179575, lng: 18.110214 },
    { lat: 48.179469, lng: 18.110023 },
    { lat: 48.179187, lng: 18.109498 },
    { lat: 48.178905, lng: 18.108987 },
    { lat: 48.178635, lng: 18.108448 },
    { lat: 48.178312, lng: 18.107854 },
    { lat: 48.177982, lng: 18.10725 },
    { lat: 48.177705, lng: 18.106733 },
    { lat: 48.177377, lng: 18.106134 },
    { lat: 48.177016, lng: 18.105478 },
    { lat: 48.176618, lng: 18.104754 },
    { lat: 48.176283, lng: 18.104137 },
    { lat: 48.176166, lng: 18.103914 },
    { lat: 48.175895, lng: 18.103402 },
    { lat: 48.175707, lng: 18.103056 },
    { lat: 48.175431, lng: 18.102556 },
    { lat: 48.175197, lng: 18.102121 },
    { lat: 48.174921, lng: 18.101596 },
    { lat: 48.174651, lng: 18.101094 },
    { lat: 48.174385, lng: 18.1006 },
    { lat: 48.174175, lng: 18.100202 },
    { lat: 48.173961, lng: 18.099808 },
    { lat: 48.173657, lng: 18.099235 },
    { lat: 48.173441, lng: 18.098846 },
    { lat: 48.173202, lng: 18.098405 },
    { lat: 48.172979, lng: 18.097989 },
    { lat: 48.172735, lng: 18.09755 },
    { lat: 48.172454, lng: 18.097025 },
    { lat: 48.172231, lng: 18.096611 },
    { lat: 48.172174, lng: 18.096507 },
    { lat: 48.171902, lng: 18.096005 },
    { lat: 48.171584, lng: 18.095404 },
    { lat: 48.171301, lng: 18.094874 },
    { lat: 48.17101, lng: 18.094326 },
    { lat: 48.17082, lng: 18.093972 },
    { lat: 48.170453, lng: 18.093304 },
    { lat: 48.170023, lng: 18.092511 },
    { lat: 48.169655, lng: 18.091849 },
    { lat: 48.1652807, lng: 18.0948459 },
    { lat: 48.1520336, lng: 18.1082123 },
    { lat: 48.1511858, lng: 18.1090721 },
    { lat: 48.1468259, lng: 18.1135049 },
    { lat: 48.1559157, lng: 18.1363119 },
    { lat: 48.1616344, lng: 18.1485949 },
    { lat: 48.1632894, lng: 18.1518992 },
    { lat: 48.1651058, lng: 18.1553615 },
    { lat: 48.1654129, lng: 18.1559824 },
    { lat: 48.1655909, lng: 18.1564586 },
    { lat: 48.1668853, lng: 18.1598716 },
    { lat: 48.1669251, lng: 18.1600718 },
    { lat: 48.166887, lng: 18.1601521 },
    { lat: 48.1670033, lng: 18.1603649 },
    { lat: 48.1671486, lng: 18.1608238 },
    { lat: 48.167212, lng: 18.1609421 },
    { lat: 48.1674327, lng: 18.160991 },
    { lat: 48.1701134, lng: 18.1697484 },
    { lat: 48.174918, lng: 18.185774 },
    { lat: 48.175017, lng: 18.185703 },
    { lat: 48.175121, lng: 18.185695 },
    { lat: 48.175256, lng: 18.185739 },
    { lat: 48.175441, lng: 18.185854 },
    { lat: 48.175754, lng: 18.186139 },
    { lat: 48.175865, lng: 18.186232 },
    { lat: 48.176009, lng: 18.186332 },
    { lat: 48.176191, lng: 18.186524 },
    { lat: 48.176354, lng: 18.186646 },
    { lat: 48.176472, lng: 18.186674 },
    { lat: 48.176582, lng: 18.186662 },
    { lat: 48.176665, lng: 18.186622 },
    { lat: 48.176719, lng: 18.186539 },
    { lat: 48.176811, lng: 18.186356 },
    { lat: 48.176869, lng: 18.18629 },
    { lat: 48.176972, lng: 18.18621 },
    { lat: 48.17711, lng: 18.186134 },
    { lat: 48.177208, lng: 18.186134 },
    { lat: 48.177286, lng: 18.186171 },
    { lat: 48.177422, lng: 18.186271 },
    { lat: 48.177501, lng: 18.186343 },
    { lat: 48.177602, lng: 18.186406 },
    { lat: 48.177758, lng: 18.186471 },
    { lat: 48.177966, lng: 18.186522 },
    { lat: 48.178071, lng: 18.186504 },
    { lat: 48.178181, lng: 18.186419 },
    { lat: 48.178222, lng: 18.186317 },
    { lat: 48.178306, lng: 18.186168 },
    { lat: 48.178389, lng: 18.186084 },
    { lat: 48.178525, lng: 18.186016 },
    { lat: 48.178617, lng: 18.186009 },
    { lat: 48.178761, lng: 18.186068 },
    { lat: 48.178967, lng: 18.186176 },
    { lat: 48.179053, lng: 18.186235 },
    { lat: 48.179199, lng: 18.186266 },
    { lat: 48.179397, lng: 18.186282 },
    { lat: 48.17952, lng: 18.186316 },
    { lat: 48.179666, lng: 18.186318 },
    { lat: 48.17973, lng: 18.186338 },
    { lat: 48.179779, lng: 18.186352 },
    { lat: 48.17989, lng: 18.186358 },
    { lat: 48.180094, lng: 18.186378 },
    { lat: 48.180196, lng: 18.186391 },
    { lat: 48.180303, lng: 18.18642 },
    { lat: 48.180496, lng: 18.186497 },
    { lat: 48.180594, lng: 18.18653 },
    { lat: 48.180651, lng: 18.186514 },
    { lat: 48.180733, lng: 18.186472 },
    { lat: 48.180889, lng: 18.186349 },
    { lat: 48.180934, lng: 18.186299 },
    { lat: 48.181005, lng: 18.186172 },
    { lat: 48.18106, lng: 18.186048 },
    { lat: 48.181117, lng: 18.185842 },
    { lat: 48.18113, lng: 18.185719 },
    { lat: 48.181125, lng: 18.185492 },
    { lat: 48.181125, lng: 18.185267 },
    { lat: 48.181135, lng: 18.185139 },
    { lat: 48.181183, lng: 18.185012 },
    { lat: 48.181264, lng: 18.18486 },
    { lat: 48.181338, lng: 18.184788 },
    { lat: 48.181408, lng: 18.184753 },
    { lat: 48.181491, lng: 18.184732 },
    { lat: 48.181582, lng: 18.184733 },
    { lat: 48.181732, lng: 18.184773 },
    { lat: 48.181949, lng: 18.184803 },
    { lat: 48.182034, lng: 18.184792 },
    { lat: 48.182107, lng: 18.184744 },
    { lat: 48.182185, lng: 18.184593 },
    { lat: 48.18222, lng: 18.184447 },
    { lat: 48.182225, lng: 18.184382 },
    { lat: 48.182202, lng: 18.184284 },
    { lat: 48.182155, lng: 18.184178 },
    { lat: 48.182054, lng: 18.184019 },
    { lat: 48.181912, lng: 18.18384 },
    { lat: 48.18179, lng: 18.183691 },
    { lat: 48.181726, lng: 18.183558 },
    { lat: 48.181699, lng: 18.183401 },
    { lat: 48.181692, lng: 18.183218 },
    { lat: 48.181728, lng: 18.182936 },
    { lat: 48.181774, lng: 18.182642 },
    { lat: 48.181763, lng: 18.182464 },
    { lat: 48.181732, lng: 18.182264 },
    { lat: 48.181668, lng: 18.181934 },
    { lat: 48.181627, lng: 18.181756 },
    { lat: 48.18155, lng: 18.181542 },
    { lat: 48.18148, lng: 18.181326 },
    { lat: 48.181452, lng: 18.181154 },
    { lat: 48.181454, lng: 18.181027 },
    { lat: 48.181478, lng: 18.180921 },
    { lat: 48.181581, lng: 18.18089 },
    { lat: 48.18167, lng: 18.180926 },
    { lat: 48.181835, lng: 18.18111 },
    { lat: 48.182048, lng: 18.181347 },
    { lat: 48.182192, lng: 18.181459 },
    { lat: 48.182393, lng: 18.181528 },
    { lat: 48.182489, lng: 18.181549 },
    { lat: 48.182607, lng: 18.181518 },
    { lat: 48.182757, lng: 18.181474 },
    { lat: 48.183133, lng: 18.181339 },
    { lat: 48.183315, lng: 18.181191 },
    { lat: 48.183405, lng: 18.181066 },
    { lat: 48.183465, lng: 18.1809 },
    { lat: 48.183516, lng: 18.180697 },
    { lat: 48.183607, lng: 18.180563 },
    { lat: 48.183743, lng: 18.180566 },
    { lat: 48.183896, lng: 18.180667 },
    { lat: 48.184021, lng: 18.180834 },
    { lat: 48.184129, lng: 18.180991 },
    { lat: 48.184212, lng: 18.181091 },
    { lat: 48.184334, lng: 18.181161 },
    { lat: 48.184452, lng: 18.181222 },
    { lat: 48.184606, lng: 18.181274 },
    { lat: 48.184902, lng: 18.181345 },
    { lat: 48.185147, lng: 18.181386 },
    { lat: 48.18527, lng: 18.181395 },
    { lat: 48.185691, lng: 18.181353 },
    { lat: 48.186058, lng: 18.181336 },
    { lat: 48.186209, lng: 18.181344 },
    { lat: 48.186297, lng: 18.18136 },
    { lat: 48.186494, lng: 18.18141 },
    { lat: 48.186596, lng: 18.181438 },
    { lat: 48.186683, lng: 18.181489 },
    { lat: 48.186773, lng: 18.181619 },
    { lat: 48.186816, lng: 18.181803 },
    { lat: 48.186793, lng: 18.181913 },
    { lat: 48.186756, lng: 18.181987 },
    { lat: 48.186659, lng: 18.182149 },
    { lat: 48.186519, lng: 18.182345 },
    { lat: 48.186391, lng: 18.18254 },
    { lat: 48.186316, lng: 18.182672 },
    { lat: 48.186248, lng: 18.182858 },
    { lat: 48.186185, lng: 18.183093 },
    { lat: 48.186117, lng: 18.18344 },
    { lat: 48.186011, lng: 18.18395 },
    { lat: 48.186011, lng: 18.184132 },
    { lat: 48.186058, lng: 18.184475 },
    { lat: 48.186118, lng: 18.184692 },
    { lat: 48.186176, lng: 18.184859 },
    { lat: 48.186263, lng: 18.184998 },
    { lat: 48.18641, lng: 18.185209 },
    { lat: 48.186496, lng: 18.185292 },
    { lat: 48.18663, lng: 18.185394 },
    { lat: 48.186748, lng: 18.185504 },
    { lat: 48.186862, lng: 18.185633 },
    { lat: 48.186979, lng: 18.185797 },
    { lat: 48.18716, lng: 18.185998 },
    { lat: 48.187218, lng: 18.186049 },
    { lat: 48.187277, lng: 18.186075 },
    { lat: 48.187388, lng: 18.186101 },
    { lat: 48.187404, lng: 18.186276 },
    { lat: 48.187564, lng: 18.18663 },
    { lat: 48.187748, lng: 18.187033 },
    { lat: 48.187827, lng: 18.187205 },
    { lat: 48.187967, lng: 18.18764 },
    { lat: 48.188058, lng: 18.187921 },
    { lat: 48.188287, lng: 18.188612 },
    { lat: 48.188327, lng: 18.188722 },
    { lat: 48.188536, lng: 18.189229 },
    { lat: 48.188601, lng: 18.189346 },
    { lat: 48.188809, lng: 18.189734 },
    { lat: 48.188978, lng: 18.190048 },
    { lat: 48.189133, lng: 18.190335 },
    { lat: 48.189401, lng: 18.190312 },
    { lat: 48.189751, lng: 18.190673 },
    { lat: 48.189996, lng: 18.190927 },
    { lat: 48.190184, lng: 18.191238 },
    { lat: 48.190449, lng: 18.191681 },
    { lat: 48.190618, lng: 18.191963 },
    { lat: 48.190784, lng: 18.192366 },
    { lat: 48.190893, lng: 18.192614 },
    { lat: 48.191058, lng: 18.192612 },
    { lat: 48.191297, lng: 18.192765 },
    { lat: 48.191398, lng: 18.193008 },
    { lat: 48.191531, lng: 18.193026 },
    { lat: 48.191873, lng: 18.192977 },
    { lat: 48.192149, lng: 18.192981 },
    { lat: 48.192266, lng: 18.192975 },
    { lat: 48.192373, lng: 18.19306 },
    { lat: 48.192383, lng: 18.193131 },
    { lat: 48.192456, lng: 18.193352 },
    { lat: 48.192545, lng: 18.1935 },
    { lat: 48.192697, lng: 18.193732 },
    { lat: 48.192818, lng: 18.193859 },
    { lat: 48.19296, lng: 18.193931 },
    { lat: 48.193137, lng: 18.193936 },
    { lat: 48.193391, lng: 18.193967 },
    { lat: 48.193471, lng: 18.193978 },
    { lat: 48.193582, lng: 18.193991 },
    { lat: 48.193676, lng: 18.194011 },
    { lat: 48.193783, lng: 18.194092 },
    { lat: 48.193923, lng: 18.194301 },
    { lat: 48.19417, lng: 18.194625 },
    { lat: 48.194286, lng: 18.194904 },
    { lat: 48.194295, lng: 18.19504 },
    { lat: 48.194272, lng: 18.195206 },
    { lat: 48.194235, lng: 18.195303 },
    { lat: 48.194167, lng: 18.195379 },
    { lat: 48.19413, lng: 18.195474 },
    { lat: 48.194143, lng: 18.19558 },
    { lat: 48.194122, lng: 18.195776 },
    { lat: 48.193992, lng: 18.196022 },
    { lat: 48.193849, lng: 18.196305 },
    { lat: 48.193785, lng: 18.196534 },
    { lat: 48.193669, lng: 18.196982 },
    { lat: 48.193692, lng: 18.197162 },
    { lat: 48.193832, lng: 18.197086 },
    { lat: 48.19395, lng: 18.197039 },
    { lat: 48.194105, lng: 18.196941 },
    { lat: 48.194281, lng: 18.196891 },
    { lat: 48.194564, lng: 18.196809 },
    { lat: 48.19464, lng: 18.19678 },
    { lat: 48.194713, lng: 18.196739 },
    { lat: 48.194841, lng: 18.196619 },
    { lat: 48.195149, lng: 18.196396 },
    { lat: 48.195361, lng: 18.196353 },
    { lat: 48.195579, lng: 18.196317 },
    { lat: 48.195801, lng: 18.196311 },
    { lat: 48.195959, lng: 18.196329 },
    { lat: 48.196034, lng: 18.196352 },
    { lat: 48.196079, lng: 18.196397 },
    { lat: 48.196121, lng: 18.196431 },
    { lat: 48.196182, lng: 18.196434 },
    { lat: 48.196245, lng: 18.196391 },
    { lat: 48.196304, lng: 18.196361 },
    { lat: 48.196366, lng: 18.196301 },
    { lat: 48.196416, lng: 18.196205 },
    { lat: 48.196426, lng: 18.196108 },
    { lat: 48.196383, lng: 18.195976 },
    { lat: 48.19633, lng: 18.195891 },
    { lat: 48.196256, lng: 18.195821 },
    { lat: 48.196171, lng: 18.195922 },
    { lat: 48.196156, lng: 18.195931 },
    { lat: 48.196128, lng: 18.195934 },
    { lat: 48.196084, lng: 18.195927 },
    { lat: 48.195996, lng: 18.195875 },
    { lat: 48.1959, lng: 18.195794 },
    { lat: 48.195843, lng: 18.195734 },
    { lat: 48.195695, lng: 18.195634 },
    { lat: 48.195579, lng: 18.195504 },
    { lat: 48.195515, lng: 18.195412 },
    { lat: 48.195517, lng: 18.195207 },
    { lat: 48.195558, lng: 18.195143 },
    { lat: 48.195631, lng: 18.19509 },
    { lat: 48.195705, lng: 18.195068 },
    { lat: 48.195754, lng: 18.195128 },
    { lat: 48.195796, lng: 18.195216 },
    { lat: 48.195821, lng: 18.19524 },
    { lat: 48.19599, lng: 18.195349 },
    { lat: 48.196038, lng: 18.195257 },
    { lat: 48.196114, lng: 18.195185 },
    { lat: 48.196177, lng: 18.195161 },
    { lat: 48.196265, lng: 18.195156 },
    { lat: 48.196381, lng: 18.19519 },
    { lat: 48.196448, lng: 18.195188 },
    { lat: 48.196494, lng: 18.19514 },
    { lat: 48.196525, lng: 18.195045 },
    { lat: 48.196501, lng: 18.19492 },
    { lat: 48.19637, lng: 18.194782 },
    { lat: 48.196181, lng: 18.194577 },
    { lat: 48.196046, lng: 18.194406 },
    { lat: 48.195945, lng: 18.194201 },
    { lat: 48.19591, lng: 18.194011 },
    { lat: 48.19591, lng: 18.193915 },
    { lat: 48.19592, lng: 18.193799 },
    { lat: 48.195943, lng: 18.193694 },
    { lat: 48.195977, lng: 18.193614 },
    { lat: 48.196098, lng: 18.193443 },
    { lat: 48.196182, lng: 18.193359 },
    { lat: 48.196359, lng: 18.193213 },
    { lat: 48.196481, lng: 18.193091 },
    { lat: 48.196599, lng: 18.193003 },
    { lat: 48.196763, lng: 18.192957 },
    { lat: 48.196972, lng: 18.193048 },
    { lat: 48.197121, lng: 18.193103 },
    { lat: 48.197212, lng: 18.193118 },
    { lat: 48.19736, lng: 18.193178 },
    { lat: 48.197436, lng: 18.193248 },
    { lat: 48.197543, lng: 18.193341 },
    { lat: 48.197587, lng: 18.193416 },
    { lat: 48.197617, lng: 18.193554 },
    { lat: 48.197625, lng: 18.193675 },
    { lat: 48.197613, lng: 18.19387 },
    { lat: 48.197603, lng: 18.194009 },
    { lat: 48.197621, lng: 18.194127 },
    { lat: 48.197665, lng: 18.194222 },
    { lat: 48.197722, lng: 18.19429 },
    { lat: 48.197782, lng: 18.194318 },
    { lat: 48.197845, lng: 18.194331 },
    { lat: 48.197925, lng: 18.194302 },
    { lat: 48.198012, lng: 18.19422 },
    { lat: 48.198091, lng: 18.194131 },
    { lat: 48.198144, lng: 18.194066 },
    { lat: 48.198283, lng: 18.193941 },
    { lat: 48.198394, lng: 18.193866 },
    { lat: 48.198505, lng: 18.193811 },
    { lat: 48.198577, lng: 18.193769 },
    { lat: 48.198629, lng: 18.193718 },
    { lat: 48.19868, lng: 18.193616 },
    { lat: 48.19869, lng: 18.193536 },
    { lat: 48.198634, lng: 18.19339 },
    { lat: 48.198581, lng: 18.193186 },
    { lat: 48.198479, lng: 18.193253 },
    { lat: 48.198031, lng: 18.193298 },
    { lat: 48.197858, lng: 18.193198 },
    { lat: 48.19762, lng: 18.193069 },
    { lat: 48.197435, lng: 18.192822 },
    { lat: 48.197343, lng: 18.192688 },
    { lat: 48.197243, lng: 18.192399 },
    { lat: 48.197371, lng: 18.192079 },
    { lat: 48.197533, lng: 18.191915 },
    { lat: 48.197613, lng: 18.19187 },
    { lat: 48.197708, lng: 18.191808 },
    { lat: 48.197821, lng: 18.191956 },
    { lat: 48.197843, lng: 18.191982 },
    { lat: 48.19798, lng: 18.192303 },
    { lat: 48.198083, lng: 18.192542 },
    { lat: 48.198163, lng: 18.192434 },
    { lat: 48.198293, lng: 18.192355 },
    { lat: 48.198265, lng: 18.192223 },
    { lat: 48.198197, lng: 18.192084 },
    { lat: 48.19814, lng: 18.192028 },
    { lat: 48.198044, lng: 18.191977 },
    { lat: 48.197984, lng: 18.191887 },
    { lat: 48.197971, lng: 18.191806 },
    { lat: 48.197984, lng: 18.191689 },
    { lat: 48.198017, lng: 18.191565 },
    { lat: 48.198084, lng: 18.19138 },
    { lat: 48.198152, lng: 18.191242 },
    { lat: 48.198279, lng: 18.190991 },
    { lat: 48.198344, lng: 18.190848 },
    { lat: 48.198442, lng: 18.190539 },
    { lat: 48.198501, lng: 18.190347 },
    { lat: 48.198543, lng: 18.190153 },
    { lat: 48.198583, lng: 18.190043 },
    { lat: 48.198625, lng: 18.189953 },
    { lat: 48.198715, lng: 18.189891 },
    { lat: 48.198798, lng: 18.189879 },
    { lat: 48.198943, lng: 18.189909 },
    { lat: 48.199015, lng: 18.190024 },
    { lat: 48.199047, lng: 18.190262 },
    { lat: 48.199053, lng: 18.190366 },
    { lat: 48.199052, lng: 18.190509 },
    { lat: 48.19911, lng: 18.190686 },
    { lat: 48.19917, lng: 18.190769 },
    { lat: 48.199225, lng: 18.190801 },
    { lat: 48.199343, lng: 18.1908 },
    { lat: 48.199519, lng: 18.190721 },
    { lat: 48.199612, lng: 18.190699 },
    { lat: 48.199742, lng: 18.190712 },
    { lat: 48.199893, lng: 18.19078 },
    { lat: 48.200004, lng: 18.190834 },
    { lat: 48.200113, lng: 18.190828 },
    { lat: 48.200219, lng: 18.190771 },
    { lat: 48.200374, lng: 18.190692 },
    { lat: 48.200548, lng: 18.190655 },
    { lat: 48.20073, lng: 18.190665 },
    { lat: 48.200925, lng: 18.190702 },
    { lat: 48.201063, lng: 18.190747 },
    { lat: 48.201229, lng: 18.190829 },
    { lat: 48.201297, lng: 18.190852 },
    { lat: 48.201397, lng: 18.190859 },
    { lat: 48.201473, lng: 18.190842 },
    { lat: 48.201527, lng: 18.190801 },
    { lat: 48.201573, lng: 18.190715 },
    { lat: 48.201607, lng: 18.190549 },
    { lat: 48.201505, lng: 18.190303 },
    { lat: 48.201442, lng: 18.190103 },
    { lat: 48.201389, lng: 18.189872 },
    { lat: 48.201374, lng: 18.189771 },
    { lat: 48.201356, lng: 18.189583 },
    { lat: 48.201351, lng: 18.189321 },
    { lat: 48.201357, lng: 18.189217 },
    { lat: 48.201369, lng: 18.18913 },
    { lat: 48.201383, lng: 18.188994 },
    { lat: 48.201428, lng: 18.188752 },
    { lat: 48.201454, lng: 18.188642 },
    { lat: 48.201486, lng: 18.188565 },
    { lat: 48.201556, lng: 18.188458 },
    { lat: 48.201634, lng: 18.188376 },
    { lat: 48.201728, lng: 18.188315 },
    { lat: 48.201917, lng: 18.188253 },
    { lat: 48.201992, lng: 18.188215 },
    { lat: 48.202088, lng: 18.188194 },
    { lat: 48.202149, lng: 18.188223 },
    { lat: 48.202198, lng: 18.188262 },
    { lat: 48.202233, lng: 18.188311 },
    { lat: 48.202265, lng: 18.188412 },
    { lat: 48.202287, lng: 18.188557 },
    { lat: 48.202295, lng: 18.18867 },
    { lat: 48.202292, lng: 18.188841 },
    { lat: 48.202244, lng: 18.189228 },
    { lat: 48.202169, lng: 18.189656 },
    { lat: 48.20215, lng: 18.189901 },
    { lat: 48.202158, lng: 18.189992 },
    { lat: 48.202174, lng: 18.190086 },
    { lat: 48.202202, lng: 18.190162 },
    { lat: 48.202248, lng: 18.190219 },
    { lat: 48.202326, lng: 18.190264 },
    { lat: 48.202409, lng: 18.190281 },
    { lat: 48.202534, lng: 18.190247 },
    { lat: 48.202731, lng: 18.19019 },
    { lat: 48.202891, lng: 18.190123 },
    { lat: 48.203013, lng: 18.19007 },
    { lat: 48.203153, lng: 18.190026 },
    { lat: 48.203213, lng: 18.190013 },
    { lat: 48.203261, lng: 18.190042 },
    { lat: 48.203301, lng: 18.190071 },
    { lat: 48.203382, lng: 18.190174 },
    { lat: 48.203453, lng: 18.190331 },
    { lat: 48.203479, lng: 18.190452 },
    { lat: 48.203515, lng: 18.190575 },
    { lat: 48.203569, lng: 18.190732 },
    { lat: 48.203615, lng: 18.190811 },
    { lat: 48.203713, lng: 18.190884 },
    { lat: 48.204077, lng: 18.191193 },
    { lat: 48.204173, lng: 18.191322 },
    { lat: 48.204274, lng: 18.191487 },
    { lat: 48.204426, lng: 18.19175 },
    { lat: 48.204509, lng: 18.191822 },
    { lat: 48.204647, lng: 18.191873 },
    { lat: 48.204879, lng: 18.191909 },
    { lat: 48.205014, lng: 18.191895 },
    { lat: 48.205054, lng: 18.191853 },
    { lat: 48.205076, lng: 18.191771 },
    { lat: 48.205081, lng: 18.191731 },
    { lat: 48.20506, lng: 18.191627 },
    { lat: 48.205012, lng: 18.191524 },
    { lat: 48.204936, lng: 18.191422 },
    { lat: 48.204823, lng: 18.191305 },
    { lat: 48.204717, lng: 18.19119 },
    { lat: 48.204609, lng: 18.19109 },
    { lat: 48.204559, lng: 18.191046 },
    { lat: 48.204513, lng: 18.190994 },
    { lat: 48.204474, lng: 18.190928 },
    { lat: 48.204447, lng: 18.190854 },
    { lat: 48.204442, lng: 18.190758 },
    { lat: 48.204469, lng: 18.190516 },
    { lat: 48.204527, lng: 18.190305 },
    { lat: 48.204582, lng: 18.19021 },
    { lat: 48.20462, lng: 18.19016 },
    { lat: 48.204729, lng: 18.190089 },
    { lat: 48.204897, lng: 18.190018 },
    { lat: 48.205012, lng: 18.190017 },
    { lat: 48.205119, lng: 18.190062 },
    { lat: 48.205269, lng: 18.190159 },
    { lat: 48.205434, lng: 18.190352 },
    { lat: 48.205652, lng: 18.190634 },
    { lat: 48.205751, lng: 18.190746 },
    { lat: 48.205794, lng: 18.190765 },
    { lat: 48.20584, lng: 18.190752 },
    { lat: 48.205891, lng: 18.1907 },
    { lat: 48.205934, lng: 18.190605 },
    { lat: 48.205976, lng: 18.190464 },
    { lat: 48.206027, lng: 18.190196 },
    { lat: 48.206052, lng: 18.190048 },
    { lat: 48.206093, lng: 18.189914 },
    { lat: 48.206143, lng: 18.189792 },
    { lat: 48.20624, lng: 18.189669 },
    { lat: 48.20637, lng: 18.189539 },
    { lat: 48.206537, lng: 18.189453 },
    { lat: 48.206619, lng: 18.189412 },
    { lat: 48.206587, lng: 18.189267 },
    { lat: 48.206581, lng: 18.189245 },
    { lat: 48.20624, lng: 18.188414 },
    { lat: 48.205972, lng: 18.187691 },
    { lat: 48.205676, lng: 18.186891 },
    { lat: 48.205145, lng: 18.185653 },
    { lat: 48.204951, lng: 18.185223 },
    { lat: 48.204767, lng: 18.18473 },
    { lat: 48.204678, lng: 18.184383 },
    { lat: 48.204564, lng: 18.183987 },
    { lat: 48.204293, lng: 18.183012 },
    { lat: 48.204103, lng: 18.182335 },
    { lat: 48.203797, lng: 18.181168 },
    { lat: 48.203633, lng: 18.180537 },
    { lat: 48.203374, lng: 18.179938 },
    { lat: 48.202971, lng: 18.179014 },
    { lat: 48.202727, lng: 18.178438 },
    { lat: 48.202621, lng: 18.17824 },
    { lat: 48.202009, lng: 18.177034 },
    { lat: 48.2018, lng: 18.176612 },
    { lat: 48.201474, lng: 18.175994 },
    { lat: 48.201007, lng: 18.175234 },
    { lat: 48.200763, lng: 18.174834 },
    { lat: 48.20074, lng: 18.174804 },
  ],
  Bajtava: [
    { lat: 47.8657861, lng: 18.7513878 },
    { lat: 47.8657833, lng: 18.7513351 },
    { lat: 47.8654767, lng: 18.7495909 },
    { lat: 47.865756, lng: 18.7488491 },
    { lat: 47.8656146, lng: 18.7471541 },
    { lat: 47.8659196, lng: 18.7464089 },
    { lat: 47.8651717, lng: 18.7418406 },
    { lat: 47.8643906, lng: 18.7392008 },
    { lat: 47.8640703, lng: 18.737774 },
    { lat: 47.8643732, lng: 18.7370283 },
    { lat: 47.8653602, lng: 18.735583 },
    { lat: 47.86598, lng: 18.7353427 },
    { lat: 47.8666328, lng: 18.7343539 },
    { lat: 47.8667098, lng: 18.733099 },
    { lat: 47.8666984, lng: 18.7325314 },
    { lat: 47.8670237, lng: 18.7291211 },
    { lat: 47.8672337, lng: 18.7275105 },
    { lat: 47.8659846, lng: 18.7263664 },
    { lat: 47.8666722, lng: 18.7251211 },
    { lat: 47.8673968, lng: 18.7245679 },
    { lat: 47.8681796, lng: 18.7237907 },
    { lat: 47.8683541, lng: 18.7236175 },
    { lat: 47.8704662, lng: 18.7225014 },
    { lat: 47.8715884, lng: 18.72202 },
    { lat: 47.8719959, lng: 18.7215644 },
    { lat: 47.8708387, lng: 18.7187585 },
    { lat: 47.8710929, lng: 18.7184747 },
    { lat: 47.8710126, lng: 18.7166065 },
    { lat: 47.8705878, lng: 18.715126 },
    { lat: 47.8699575, lng: 18.714416 },
    { lat: 47.86782, lng: 18.7081386 },
    { lat: 47.8672724, lng: 18.7055363 },
    { lat: 47.866575, lng: 18.7060709 },
    { lat: 47.8661269, lng: 18.7066102 },
    { lat: 47.865726, lng: 18.7073096 },
    { lat: 47.8656941, lng: 18.7073653 },
    { lat: 47.8653054, lng: 18.7083528 },
    { lat: 47.8650548, lng: 18.7087672 },
    { lat: 47.8646075, lng: 18.7093431 },
    { lat: 47.8642702, lng: 18.7096527 },
    { lat: 47.864149, lng: 18.7096732 },
    { lat: 47.8636479, lng: 18.7097578 },
    { lat: 47.863565, lng: 18.7097718 },
    { lat: 47.8633699, lng: 18.709879 },
    { lat: 47.8616333, lng: 18.7117896 },
    { lat: 47.8597383, lng: 18.7138744 },
    { lat: 47.859005, lng: 18.7154798 },
    { lat: 47.8514886, lng: 18.7264209 },
    { lat: 47.8517248, lng: 18.7271032 },
    { lat: 47.851232, lng: 18.7275845 },
    { lat: 47.8511489, lng: 18.7280739 },
    { lat: 47.8505096, lng: 18.7295247 },
    { lat: 47.8498375, lng: 18.7297133 },
    { lat: 47.8498099, lng: 18.7302289 },
    { lat: 47.8486547, lng: 18.7302013 },
    { lat: 47.8469345, lng: 18.7297766 },
    { lat: 47.8463843, lng: 18.7297812 },
    { lat: 47.8456005, lng: 18.7295045 },
    { lat: 47.8443336, lng: 18.7294147 },
    { lat: 47.8437646, lng: 18.7304304 },
    { lat: 47.8433139, lng: 18.7309448 },
    { lat: 47.8428066, lng: 18.7320192 },
    { lat: 47.842644, lng: 18.7326651 },
    { lat: 47.8425667, lng: 18.7326511 },
    { lat: 47.8425351, lng: 18.7333643 },
    { lat: 47.8426061, lng: 18.7339292 },
    { lat: 47.8427457, lng: 18.7341348 },
    { lat: 47.8427534, lng: 18.7342382 },
    { lat: 47.8426438, lng: 18.7347152 },
    { lat: 47.8427129, lng: 18.7351606 },
    { lat: 47.8426324, lng: 18.7354924 },
    { lat: 47.8426135, lng: 18.7359584 },
    { lat: 47.8428328, lng: 18.736407 },
    { lat: 47.8428948, lng: 18.7372046 },
    { lat: 47.8431606, lng: 18.7377664 },
    { lat: 47.843377, lng: 18.7390642 },
    { lat: 47.839861, lng: 18.7538951 },
    { lat: 47.8373317, lng: 18.765135 },
    { lat: 47.8333541, lng: 18.7831919 },
    { lat: 47.8334927, lng: 18.7834245 },
    { lat: 47.833846, lng: 18.7834061 },
    { lat: 47.8342702, lng: 18.7830926 },
    { lat: 47.836435, lng: 18.7808209 },
    { lat: 47.8427281, lng: 18.7741491 },
    { lat: 47.8450978, lng: 18.7691301 },
    { lat: 47.8493604, lng: 18.7623277 },
    { lat: 47.8509359, lng: 18.7611906 },
    { lat: 47.8525324, lng: 18.7615822 },
    { lat: 47.853558, lng: 18.7600742 },
    { lat: 47.856554, lng: 18.7565447 },
    { lat: 47.8587778, lng: 18.7565232 },
    { lat: 47.8594161, lng: 18.7563326 },
    { lat: 47.8595725, lng: 18.7546194 },
    { lat: 47.8610403, lng: 18.7546103 },
    { lat: 47.8616216, lng: 18.7544271 },
    { lat: 47.8638389, lng: 18.7525769 },
    { lat: 47.8639695, lng: 18.7528442 },
    { lat: 47.8650896, lng: 18.7521136 },
    { lat: 47.8651749, lng: 18.751896 },
    { lat: 47.8657861, lng: 18.7513878 },
  ],
  Mužla: [
    { lat: 47.8335227, lng: 18.5465941 },
    { lat: 47.8334499, lng: 18.5465223 },
    { lat: 47.8310313, lng: 18.5440939 },
    { lat: 47.8258821, lng: 18.5386683 },
    { lat: 47.8219196, lng: 18.5345848 },
    { lat: 47.8217909, lng: 18.5344586 },
    { lat: 47.8217106, lng: 18.5343747 },
    { lat: 47.815291, lng: 18.528475 },
    { lat: 47.815043, lng: 18.528293 },
    { lat: 47.814183, lng: 18.527649 },
    { lat: 47.814157, lng: 18.527629 },
    { lat: 47.814087, lng: 18.527577 },
    { lat: 47.814059, lng: 18.527555 },
    { lat: 47.814044, lng: 18.527544 },
    { lat: 47.813781, lng: 18.527451 },
    { lat: 47.813161, lng: 18.52723 },
    { lat: 47.812794, lng: 18.527099 },
    { lat: 47.812415, lng: 18.526964 },
    { lat: 47.812211, lng: 18.526891 },
    { lat: 47.811804, lng: 18.526746 },
    { lat: 47.811025, lng: 18.526468 },
    { lat: 47.810184, lng: 18.526169 },
    { lat: 47.809504, lng: 18.526056 },
    { lat: 47.808839, lng: 18.525946 },
    { lat: 47.80805, lng: 18.525816 },
    { lat: 47.807389, lng: 18.525706 },
    { lat: 47.807355, lng: 18.525701 },
    { lat: 47.807331, lng: 18.525697 },
    { lat: 47.807297, lng: 18.525692 },
    { lat: 47.807276, lng: 18.525689 },
    { lat: 47.807257, lng: 18.525691 },
    { lat: 47.807053, lng: 18.525713 },
    { lat: 47.80616, lng: 18.525812 },
    { lat: 47.80544, lng: 18.525892 },
    { lat: 47.804621, lng: 18.525983 },
    { lat: 47.803583, lng: 18.526099 },
    { lat: 47.803078, lng: 18.526155 },
    { lat: 47.802387, lng: 18.526232 },
    { lat: 47.801365, lng: 18.526345 },
    { lat: 47.801334, lng: 18.526348 },
    { lat: 47.800823, lng: 18.5264 },
    { lat: 47.80078, lng: 18.526403 },
    { lat: 47.800053, lng: 18.526473 },
    { lat: 47.800014, lng: 18.526477 },
    { lat: 47.799834, lng: 18.526494 },
    { lat: 47.799745, lng: 18.526575 },
    { lat: 47.799516, lng: 18.526785 },
    { lat: 47.799029, lng: 18.527227 },
    { lat: 47.798831, lng: 18.527409 },
    { lat: 47.798751, lng: 18.527471 },
    { lat: 47.798322, lng: 18.527803 },
    { lat: 47.798104, lng: 18.527972 },
    { lat: 47.798065, lng: 18.528002 },
    { lat: 47.797915, lng: 18.528118 },
    { lat: 47.797872, lng: 18.528151 },
    { lat: 47.797606, lng: 18.528357 },
    { lat: 47.79754, lng: 18.528409 },
    { lat: 47.79751, lng: 18.528432 },
    { lat: 47.797381, lng: 18.528531 },
    { lat: 47.797207, lng: 18.528666 },
    { lat: 47.797035, lng: 18.5288 },
    { lat: 47.796463, lng: 18.529133 },
    { lat: 47.79563, lng: 18.529616 },
    { lat: 47.795046, lng: 18.529957 },
    { lat: 47.794771, lng: 18.530116 },
    { lat: 47.794543, lng: 18.530249 },
    { lat: 47.794304, lng: 18.530388 },
    { lat: 47.794197, lng: 18.530434 },
    { lat: 47.794027, lng: 18.530507 },
    { lat: 47.793439, lng: 18.530759 },
    { lat: 47.793285, lng: 18.530824 },
    { lat: 47.792867, lng: 18.531004 },
    { lat: 47.792617, lng: 18.53111 },
    { lat: 47.791768, lng: 18.531474 },
    { lat: 47.791547, lng: 18.531569 },
    { lat: 47.791292, lng: 18.531712 },
    { lat: 47.791261, lng: 18.531729 },
    { lat: 47.791239, lng: 18.531742 },
    { lat: 47.790891, lng: 18.531937 },
    { lat: 47.790811, lng: 18.531982 },
    { lat: 47.790585, lng: 18.532109 },
    { lat: 47.790102, lng: 18.532381 },
    { lat: 47.78916, lng: 18.53291 },
    { lat: 47.789084, lng: 18.53293 },
    { lat: 47.788608, lng: 18.53306 },
    { lat: 47.788132, lng: 18.533189 },
    { lat: 47.787833, lng: 18.53327 },
    { lat: 47.78735, lng: 18.533402 },
    { lat: 47.787017, lng: 18.533493 },
    { lat: 47.786341, lng: 18.533739 },
    { lat: 47.785911, lng: 18.533896 },
    { lat: 47.785651, lng: 18.53399 },
    { lat: 47.785618, lng: 18.534002 },
    { lat: 47.785611, lng: 18.534005 },
    { lat: 47.785165, lng: 18.534157 },
    { lat: 47.784387, lng: 18.534422 },
    { lat: 47.78382, lng: 18.534611 },
    { lat: 47.783754, lng: 18.534632 },
    { lat: 47.783767, lng: 18.534604 },
    { lat: 47.783973, lng: 18.534136 },
    { lat: 47.78433, lng: 18.533324 },
    { lat: 47.784684, lng: 18.532617 },
    { lat: 47.784999, lng: 18.531985 },
    { lat: 47.785198, lng: 18.531419 },
    { lat: 47.785425, lng: 18.530898 },
    { lat: 47.785821, lng: 18.529989 },
    { lat: 47.785977, lng: 18.529542 },
    { lat: 47.786256, lng: 18.528742 },
    { lat: 47.786424, lng: 18.528246 },
    { lat: 47.786536, lng: 18.527916 },
    { lat: 47.78656, lng: 18.527845 },
    { lat: 47.786886, lng: 18.527065 },
    { lat: 47.787208, lng: 18.526247 },
    { lat: 47.787426, lng: 18.525695 },
    { lat: 47.787621, lng: 18.525132 },
    { lat: 47.788003, lng: 18.524025 },
    { lat: 47.788198, lng: 18.523459 },
    { lat: 47.788424, lng: 18.522826 },
    { lat: 47.788766, lng: 18.521869 },
    { lat: 47.789068, lng: 18.521055 },
    { lat: 47.789254, lng: 18.520504 },
    { lat: 47.789388, lng: 18.52011 },
    { lat: 47.789635, lng: 18.519546 },
    { lat: 47.78967, lng: 18.519466 },
    { lat: 47.78968, lng: 18.519443 },
    { lat: 47.789696, lng: 18.519406 },
    { lat: 47.78971, lng: 18.519376 },
    { lat: 47.78975, lng: 18.519284 },
    { lat: 47.789764, lng: 18.519252 },
    { lat: 47.789888, lng: 18.518916 },
    { lat: 47.790092, lng: 18.518364 },
    { lat: 47.790339, lng: 18.517693 },
    { lat: 47.790652, lng: 18.516819 },
    { lat: 47.790813, lng: 18.516447 },
    { lat: 47.790987, lng: 18.516041 },
    { lat: 47.791001, lng: 18.515998 },
    { lat: 47.791031, lng: 18.515897 },
    { lat: 47.791175, lng: 18.515427 },
    { lat: 47.791284, lng: 18.514978 },
    { lat: 47.791427, lng: 18.514383 },
    { lat: 47.791597, lng: 18.513782 },
    { lat: 47.791809, lng: 18.513031 },
    { lat: 47.791995, lng: 18.512248 },
    { lat: 47.792084, lng: 18.51164 },
    { lat: 47.792223, lng: 18.510699 },
    { lat: 47.79229, lng: 18.510235 },
    { lat: 47.792333, lng: 18.509947 },
    { lat: 47.792445, lng: 18.50963 },
    { lat: 47.792584, lng: 18.509236 },
    { lat: 47.792711, lng: 18.508729 },
    { lat: 47.792828, lng: 18.508262 },
    { lat: 47.792875, lng: 18.507792 },
    { lat: 47.792863, lng: 18.507141 },
    { lat: 47.792628, lng: 18.506521 },
    { lat: 47.792548, lng: 18.506061 },
    { lat: 47.792542, lng: 18.505978 },
    { lat: 47.792524, lng: 18.505709 },
    { lat: 47.79207, lng: 18.505871 },
    { lat: 47.792002, lng: 18.505891 },
    { lat: 47.791194, lng: 18.506169 },
    { lat: 47.790084, lng: 18.506559 },
    { lat: 47.789561, lng: 18.506602 },
    { lat: 47.788512, lng: 18.506694 },
    { lat: 47.787345, lng: 18.506791 },
    { lat: 47.78662, lng: 18.506976 },
    { lat: 47.7858, lng: 18.507184 },
    { lat: 47.785331, lng: 18.507305 },
    { lat: 47.784677, lng: 18.507498 },
    { lat: 47.783907, lng: 18.50772 },
    { lat: 47.783611, lng: 18.507803 },
    { lat: 47.783013, lng: 18.507952 },
    { lat: 47.782279, lng: 18.508122 },
    { lat: 47.782018, lng: 18.508181 },
    { lat: 47.781545, lng: 18.508287 },
    { lat: 47.781143, lng: 18.508379 },
    { lat: 47.780424, lng: 18.508546 },
    { lat: 47.779246, lng: 18.508806 },
    { lat: 47.778703, lng: 18.508929 },
    { lat: 47.778459, lng: 18.508984 },
    { lat: 47.777718, lng: 18.509158 },
    { lat: 47.777196, lng: 18.509279 },
    { lat: 47.775883, lng: 18.50958 },
    { lat: 47.774739, lng: 18.509832 },
    { lat: 47.774678, lng: 18.509845 },
    { lat: 47.774645, lng: 18.509848 },
    { lat: 47.774613, lng: 18.50986 },
    { lat: 47.773414, lng: 18.510116 },
    { lat: 47.772727, lng: 18.510241 },
    { lat: 47.771906, lng: 18.510397 },
    { lat: 47.771307, lng: 18.510508 },
    { lat: 47.770749, lng: 18.510611 },
    { lat: 47.770366, lng: 18.510685 },
    { lat: 47.769735, lng: 18.510757 },
    { lat: 47.76897, lng: 18.510839 },
    { lat: 47.768793, lng: 18.510858 },
    { lat: 47.768622, lng: 18.510879 },
    { lat: 47.768273, lng: 18.510914 },
    { lat: 47.767481, lng: 18.510992 },
    { lat: 47.767128, lng: 18.511025 },
    { lat: 47.76626, lng: 18.511084 },
    { lat: 47.765621, lng: 18.511148 },
    { lat: 47.765386, lng: 18.511172 },
    { lat: 47.765, lng: 18.511208 },
    { lat: 47.764268, lng: 18.511263 },
    { lat: 47.763685, lng: 18.511307 },
    { lat: 47.763316, lng: 18.511332 },
    { lat: 47.762669, lng: 18.511377 },
    { lat: 47.762363, lng: 18.511395 },
    { lat: 47.761999, lng: 18.511415 },
    { lat: 47.760851, lng: 18.511489 },
    { lat: 47.760089, lng: 18.511527 },
    { lat: 47.759478, lng: 18.511562 },
    { lat: 47.759184, lng: 18.511579 },
    { lat: 47.758518, lng: 18.511624 },
    { lat: 47.758109, lng: 18.511633 },
    { lat: 47.75794, lng: 18.511654 },
    { lat: 47.757568, lng: 18.511667 },
    { lat: 47.757445, lng: 18.511688 },
    { lat: 47.755531, lng: 18.512217 },
    { lat: 47.75534, lng: 18.51227 },
    { lat: 47.7551703, lng: 18.5123163 },
    { lat: 47.755218, lng: 18.5126113 },
    { lat: 47.7557669, lng: 18.5156233 },
    { lat: 47.7558931, lng: 18.5162723 },
    { lat: 47.756292, lng: 18.5183236 },
    { lat: 47.7565398, lng: 18.51944 },
    { lat: 47.7568075, lng: 18.5205463 },
    { lat: 47.7570952, lng: 18.5216417 },
    { lat: 47.7574024, lng: 18.5227253 },
    { lat: 47.7577291, lng: 18.5237963 },
    { lat: 47.7584257, lng: 18.5257576 },
    { lat: 47.7587729, lng: 18.5266992 },
    { lat: 47.7591526, lng: 18.5276128 },
    { lat: 47.7595637, lng: 18.5284961 },
    { lat: 47.7599809, lng: 18.5293059 },
    { lat: 47.7604383, lng: 18.5300665 },
    { lat: 47.7609331, lng: 18.5307738 },
    { lat: 47.7614196, lng: 18.5313688 },
    { lat: 47.7619249, lng: 18.5319826 },
    { lat: 47.7624459, lng: 18.5327247 },
    { lat: 47.7629142, lng: 18.5335406 },
    { lat: 47.7633248, lng: 18.5344221 },
    { lat: 47.7636739, lng: 18.5353601 },
    { lat: 47.7640169, lng: 18.5365527 },
    { lat: 47.7643151, lng: 18.5378012 },
    { lat: 47.7645238, lng: 18.5390893 },
    { lat: 47.7646407, lng: 18.5404024 },
    { lat: 47.7646465, lng: 18.5415618 },
    { lat: 47.7646262, lng: 18.5429693 },
    { lat: 47.764658, lng: 18.5443763 },
    { lat: 47.7646906, lng: 18.5454889 },
    { lat: 47.764685, lng: 18.5466025 },
    { lat: 47.7646673, lng: 18.5482417 },
    { lat: 47.7646577, lng: 18.5493409 },
    { lat: 47.7647129, lng: 18.5504372 },
    { lat: 47.7648391, lng: 18.5516651 },
    { lat: 47.7649938, lng: 18.5528858 },
    { lat: 47.7651856, lng: 18.5542781 },
    { lat: 47.7653571, lng: 18.5556761 },
    { lat: 47.7655081, lng: 18.5570794 },
    { lat: 47.7656388, lng: 18.5584874 },
    { lat: 47.765749, lng: 18.5598989 },
    { lat: 47.7658386, lng: 18.5613138 },
    { lat: 47.7658926, lng: 18.5623621 },
    { lat: 47.7659586, lng: 18.5635787 },
    { lat: 47.7660252, lng: 18.564795 },
    { lat: 47.7660926, lng: 18.5660113 },
    { lat: 47.7661169, lng: 18.5671758 },
    { lat: 47.7660804, lng: 18.5677448 },
    { lat: 47.7660234, lng: 18.5683103 },
    { lat: 47.7659462, lng: 18.5688705 },
    { lat: 47.7658073, lng: 18.5697089 },
    { lat: 47.7656744, lng: 18.5705495 },
    { lat: 47.7654137, lng: 18.5719239 },
    { lat: 47.7652361, lng: 18.5727382 },
    { lat: 47.7650588, lng: 18.5735526 },
    { lat: 47.7648821, lng: 18.5743673 },
    { lat: 47.7647184, lng: 18.575282 },
    { lat: 47.7645698, lng: 18.5762023 },
    { lat: 47.7644366, lng: 18.5771278 },
    { lat: 47.7637717, lng: 18.5816078 },
    { lat: 47.7629988, lng: 18.5860496 },
    { lat: 47.7621189, lng: 18.5904471 },
    { lat: 47.7609494, lng: 18.5958511 },
    { lat: 47.7597798, lng: 18.6012547 },
    { lat: 47.7590662, lng: 18.6045502 },
    { lat: 47.7619438, lng: 18.6052464 },
    { lat: 47.7674908, lng: 18.6080773 },
    { lat: 47.7714353, lng: 18.6100905 },
    { lat: 47.7729912, lng: 18.6107787 },
    { lat: 47.7759697, lng: 18.6171461 },
    { lat: 47.7797732, lng: 18.6206153 },
    { lat: 47.7801138, lng: 18.6209334 },
    { lat: 47.7818904, lng: 18.6224572 },
    { lat: 47.7823845, lng: 18.6229364 },
    { lat: 47.7835896, lng: 18.6238943 },
    { lat: 47.7835833, lng: 18.6239431 },
    { lat: 47.7853756, lng: 18.6245009 },
    { lat: 47.7872468, lng: 18.6248894 },
    { lat: 47.7891512, lng: 18.6249592 },
    { lat: 47.7907499, lng: 18.6248478 },
    { lat: 47.790839, lng: 18.6255252 },
    { lat: 47.7921289, lng: 18.6253725 },
    { lat: 47.7949824, lng: 18.6255977 },
    { lat: 47.7959097, lng: 18.6254999 },
    { lat: 47.7969109, lng: 18.6255517 },
    { lat: 47.7972026, lng: 18.62559 },
    { lat: 47.7985285, lng: 18.6257639 },
    { lat: 47.7990541, lng: 18.6259272 },
    { lat: 47.8012091, lng: 18.626003 },
    { lat: 47.801917, lng: 18.6258913 },
    { lat: 47.805881, lng: 18.6247887 },
    { lat: 47.8070412, lng: 18.6247618 },
    { lat: 47.8086315, lng: 18.6245131 },
    { lat: 47.8087281, lng: 18.6237822 },
    { lat: 47.8087971, lng: 18.6237872 },
    { lat: 47.8087969, lng: 18.623843 },
    { lat: 47.809751, lng: 18.6238858 },
    { lat: 47.8109834, lng: 18.6240637 },
    { lat: 47.8130087, lng: 18.624486 },
    { lat: 47.8143533, lng: 18.6246936 },
    { lat: 47.8154274, lng: 18.6249511 },
    { lat: 47.8154492, lng: 18.6249786 },
    { lat: 47.8154712, lng: 18.6249658 },
    { lat: 47.8155195, lng: 18.6248243 },
    { lat: 47.8158498, lng: 18.6238553 },
    { lat: 47.8160097, lng: 18.6233863 },
    { lat: 47.8164547, lng: 18.6226389 },
    { lat: 47.8173863, lng: 18.621587 },
    { lat: 47.8181991, lng: 18.6207937 },
    { lat: 47.8188179, lng: 18.6199814 },
    { lat: 47.8194586, lng: 18.619385 },
    { lat: 47.8203109, lng: 18.6187206 },
    { lat: 47.8205929, lng: 18.6185802 },
    { lat: 47.8217981, lng: 18.6187525 },
    { lat: 47.8224141, lng: 18.6193644 },
    { lat: 47.8233488, lng: 18.6202927 },
    { lat: 47.8247034, lng: 18.6221289 },
    { lat: 47.8255963, lng: 18.6232326 },
    { lat: 47.8256792, lng: 18.6232989 },
    { lat: 47.8262876, lng: 18.6237855 },
    { lat: 47.826398, lng: 18.6238431 },
    { lat: 47.8267798, lng: 18.6240423 },
    { lat: 47.8270904, lng: 18.6241119 },
    { lat: 47.8280938, lng: 18.6241428 },
    { lat: 47.8292056, lng: 18.6247183 },
    { lat: 47.8297676, lng: 18.6238741 },
    { lat: 47.8315325, lng: 18.620801 },
    { lat: 47.8324777, lng: 18.619255 },
    { lat: 47.8329398, lng: 18.6192079 },
    { lat: 47.833211, lng: 18.6190734 },
    { lat: 47.83349, lng: 18.6187933 },
    { lat: 47.8338719, lng: 18.6185776 },
    { lat: 47.8341146, lng: 18.6185202 },
    { lat: 47.8347271, lng: 18.6185827 },
    { lat: 47.8354708, lng: 18.6189176 },
    { lat: 47.8357287, lng: 18.6189739 },
    { lat: 47.8360028, lng: 18.6189337 },
    { lat: 47.8364898, lng: 18.6187036 },
    { lat: 47.8376652, lng: 18.6179602 },
    { lat: 47.8382593, lng: 18.6176873 },
    { lat: 47.8383081, lng: 18.6172408 },
    { lat: 47.8382683, lng: 18.6164101 },
    { lat: 47.8382955, lng: 18.6153845 },
    { lat: 47.8384319, lng: 18.6129955 },
    { lat: 47.8386737, lng: 18.6107612 },
    { lat: 47.8386724, lng: 18.6104872 },
    { lat: 47.838576, lng: 18.6102335 },
    { lat: 47.8379579, lng: 18.6093074 },
    { lat: 47.8381118, lng: 18.6080407 },
    { lat: 47.8377584, lng: 18.6076875 },
    { lat: 47.8371092, lng: 18.6072823 },
    { lat: 47.8362462, lng: 18.60648 },
    { lat: 47.8362198, lng: 18.6065338 },
    { lat: 47.8354753, lng: 18.6073744 },
    { lat: 47.8350222, lng: 18.6079783 },
    { lat: 47.8344993, lng: 18.608109 },
    { lat: 47.8339779, lng: 18.6084922 },
    { lat: 47.8336646, lng: 18.6086025 },
    { lat: 47.8332834, lng: 18.6086416 },
    { lat: 47.8325541, lng: 18.608303 },
    { lat: 47.8312339, lng: 18.607965 },
    { lat: 47.829529, lng: 18.6073995 },
    { lat: 47.8281621, lng: 18.6068646 },
    { lat: 47.8275081, lng: 18.6065426 },
    { lat: 47.8268644, lng: 18.6064671 },
    { lat: 47.8257643, lng: 18.6060472 },
    { lat: 47.8253641, lng: 18.6058882 },
    { lat: 47.8249533, lng: 18.6056164 },
    { lat: 47.8248103, lng: 18.6055986 },
    { lat: 47.8240273, lng: 18.6051213 },
    { lat: 47.8229964, lng: 18.6047751 },
    { lat: 47.8221305, lng: 18.6043511 },
    { lat: 47.822052, lng: 18.6043974 },
    { lat: 47.8208507, lng: 18.6039477 },
    { lat: 47.8202425, lng: 18.603817 },
    { lat: 47.8199258, lng: 18.6036582 },
    { lat: 47.8121502, lng: 18.6018566 },
    { lat: 47.813037, lng: 18.5984978 },
    { lat: 47.8142757, lng: 18.5948085 },
    { lat: 47.817529, lng: 18.5862665 },
    { lat: 47.8181372, lng: 18.5847781 },
    { lat: 47.81882, lng: 18.5829203 },
    { lat: 47.8189315, lng: 18.5825489 },
    { lat: 47.8190264, lng: 18.5822964 },
    { lat: 47.8191961, lng: 18.5814962 },
    { lat: 47.8197403, lng: 18.5766148 },
    { lat: 47.8201199, lng: 18.5747036 },
    { lat: 47.820482, lng: 18.5732344 },
    { lat: 47.8208332, lng: 18.5720549 },
    { lat: 47.8215848, lng: 18.5702647 },
    { lat: 47.8226016, lng: 18.5674343 },
    { lat: 47.8230995, lng: 18.5659294 },
    { lat: 47.82363, lng: 18.5639764 },
    { lat: 47.8242012, lng: 18.5626191 },
    { lat: 47.8245688, lng: 18.56188 },
    { lat: 47.8264187, lng: 18.558454 },
    { lat: 47.8272059, lng: 18.5570456 },
    { lat: 47.828357, lng: 18.5552592 },
    { lat: 47.8309929, lng: 18.5510379 },
    { lat: 47.8316474, lng: 18.5500866 },
    { lat: 47.8328131, lng: 18.5479984 },
    { lat: 47.8335227, lng: 18.5465941 },
  ],
  Semerovo: [
    { lat: 48.0014351, lng: 18.3059851 },
    { lat: 48.0012726, lng: 18.3060425 },
    { lat: 48.000462, lng: 18.3066047 },
    { lat: 48.0003143, lng: 18.3066475 },
    { lat: 48.0000959, lng: 18.3068514 },
    { lat: 47.9999802, lng: 18.3068623 },
    { lat: 47.9985382, lng: 18.3078054 },
    { lat: 47.9980548, lng: 18.3081502 },
    { lat: 47.9977799, lng: 18.3084314 },
    { lat: 47.996645, lng: 18.3091652 },
    { lat: 47.994928, lng: 18.3110947 },
    { lat: 47.9930283, lng: 18.3134047 },
    { lat: 47.9926858, lng: 18.3137677 },
    { lat: 47.9912454, lng: 18.3149562 },
    { lat: 47.9906948, lng: 18.3156461 },
    { lat: 47.9893024, lng: 18.3175919 },
    { lat: 47.9857192, lng: 18.3218723 },
    { lat: 47.9838218, lng: 18.3243246 },
    { lat: 47.9822721, lng: 18.3263624 },
    { lat: 47.9801589, lng: 18.3294593 },
    { lat: 47.9781411, lng: 18.3318597 },
    { lat: 47.9761075, lng: 18.3340045 },
    { lat: 47.9761438, lng: 18.3340643 },
    { lat: 47.9760419, lng: 18.3341589 },
    { lat: 47.9748662, lng: 18.3360135 },
    { lat: 47.9745462, lng: 18.3369882 },
    { lat: 47.976132, lng: 18.3374742 },
    { lat: 47.9774992, lng: 18.3375271 },
    { lat: 47.9781308, lng: 18.3378802 },
    { lat: 47.97855, lng: 18.3381087 },
    { lat: 47.9806929, lng: 18.3395768 },
    { lat: 47.9833259, lng: 18.342554 },
    { lat: 47.9846505, lng: 18.3439203 },
    { lat: 47.9856903, lng: 18.3456728 },
    { lat: 47.9862084, lng: 18.3464246 },
    { lat: 47.9866954, lng: 18.3485961 },
    { lat: 47.9874395, lng: 18.3534977 },
    { lat: 47.9872614, lng: 18.3580376 },
    { lat: 47.9870774, lng: 18.3606908 },
    { lat: 47.9878644, lng: 18.3683598 },
    { lat: 47.9887514, lng: 18.3703684 },
    { lat: 47.9900881, lng: 18.37372 },
    { lat: 47.9901011, lng: 18.3736906 },
    { lat: 47.9916969, lng: 18.3713376 },
    { lat: 47.9927221, lng: 18.3703363 },
    { lat: 47.9938093, lng: 18.3720793 },
    { lat: 47.9955628, lng: 18.3751604 },
    { lat: 47.9959266, lng: 18.37521 },
    { lat: 47.997198, lng: 18.3742168 },
    { lat: 47.9988872, lng: 18.3771418 },
    { lat: 47.9989403, lng: 18.377382 },
    { lat: 47.9995789, lng: 18.3785563 },
    { lat: 47.9999359, lng: 18.3794191 },
    { lat: 48.0014281, lng: 18.3822752 },
    { lat: 48.0020607, lng: 18.3832872 },
    { lat: 48.0033232, lng: 18.3841214 },
    { lat: 48.0050109, lng: 18.3843378 },
    { lat: 48.0061504, lng: 18.3840827 },
    { lat: 48.0065378, lng: 18.3847238 },
    { lat: 48.0073955, lng: 18.3846328 },
    { lat: 48.0087212, lng: 18.3872013 },
    { lat: 48.008976, lng: 18.3877171 },
    { lat: 48.0089479, lng: 18.3877552 },
    { lat: 48.009668, lng: 18.3885602 },
    { lat: 48.0098198, lng: 18.3885897 },
    { lat: 48.0100402, lng: 18.3885173 },
    { lat: 48.0109698, lng: 18.3879413 },
    { lat: 48.0116118, lng: 18.3874212 },
    { lat: 48.0116117, lng: 18.3875191 },
    { lat: 48.0116824, lng: 18.3875166 },
    { lat: 48.0117884, lng: 18.3871759 },
    { lat: 48.0121179, lng: 18.3866778 },
    { lat: 48.0121958, lng: 18.3864588 },
    { lat: 48.0124112, lng: 18.3866751 },
    { lat: 48.0132965, lng: 18.3864711 },
    { lat: 48.0150028, lng: 18.386322 },
    { lat: 48.0168969, lng: 18.3863377 },
    { lat: 48.0180026, lng: 18.3862796 },
    { lat: 48.018837, lng: 18.3855925 },
    { lat: 48.019848, lng: 18.3859149 },
    { lat: 48.0206308, lng: 18.3855521 },
    { lat: 48.0213787, lng: 18.3851119 },
    { lat: 48.0218847, lng: 18.3848474 },
    { lat: 48.0223022, lng: 18.3844083 },
    { lat: 48.0224724, lng: 18.3841088 },
    { lat: 48.0226025, lng: 18.3836339 },
    { lat: 48.0227711, lng: 18.3833463 },
    { lat: 48.0228855, lng: 18.3828378 },
    { lat: 48.0230922, lng: 18.3823843 },
    { lat: 48.0232467, lng: 18.3816438 },
    { lat: 48.0232748, lng: 18.3808452 },
    { lat: 48.0234022, lng: 18.3799693 },
    { lat: 48.023421, lng: 18.3785872 },
    { lat: 48.0235292, lng: 18.3777115 },
    { lat: 48.023496, lng: 18.3777044 },
    { lat: 48.023548, lng: 18.3768912 },
    { lat: 48.0238097, lng: 18.3757742 },
    { lat: 48.0239707, lng: 18.3752696 },
    { lat: 48.023996, lng: 18.3748411 },
    { lat: 48.0236273, lng: 18.3747889 },
    { lat: 48.0234035, lng: 18.3746197 },
    { lat: 48.0232306, lng: 18.3743557 },
    { lat: 48.0230611, lng: 18.3738358 },
    { lat: 48.0228886, lng: 18.3729832 },
    { lat: 48.0227941, lng: 18.3720555 },
    { lat: 48.0224777, lng: 18.3707711 },
    { lat: 48.0218115, lng: 18.3688362 },
    { lat: 48.0212124, lng: 18.367408 },
    { lat: 48.0210659, lng: 18.3672521 },
    { lat: 48.0213288, lng: 18.3646516 },
    { lat: 48.0212487, lng: 18.3615327 },
    { lat: 48.0205511, lng: 18.3596829 },
    { lat: 48.0196031, lng: 18.3576739 },
    { lat: 48.0207776, lng: 18.3568622 },
    { lat: 48.0219644, lng: 18.3558272 },
    { lat: 48.0224717, lng: 18.3556426 },
    { lat: 48.0233602, lng: 18.3551781 },
    { lat: 48.0242503, lng: 18.3544012 },
    { lat: 48.0270272, lng: 18.3514553 },
    { lat: 48.0285419, lng: 18.349466 },
    { lat: 48.0291254, lng: 18.3485194 },
    { lat: 48.0300696, lng: 18.3476632 },
    { lat: 48.0310234, lng: 18.3469951 },
    { lat: 48.0317951, lng: 18.3463027 },
    { lat: 48.0336998, lng: 18.3449814 },
    { lat: 48.0362726, lng: 18.3449276 },
    { lat: 48.0379967, lng: 18.3443982 },
    { lat: 48.0389827, lng: 18.3431261 },
    { lat: 48.0396413, lng: 18.3419343 },
    { lat: 48.0406375, lng: 18.3412404 },
    { lat: 48.0406664, lng: 18.341212 },
    { lat: 48.0405072, lng: 18.3408377 },
    { lat: 48.0394733, lng: 18.3383173 },
    { lat: 48.0386042, lng: 18.3371041 },
    { lat: 48.0388152, lng: 18.3366916 },
    { lat: 48.0387409, lng: 18.3366154 },
    { lat: 48.0395541, lng: 18.3349487 },
    { lat: 48.0398583, lng: 18.3345248 },
    { lat: 48.0405848, lng: 18.3337736 },
    { lat: 48.0415824, lng: 18.3332828 },
    { lat: 48.0407907, lng: 18.3317855 },
    { lat: 48.039214, lng: 18.3297626 },
    { lat: 48.0335593, lng: 18.3293099 },
    { lat: 48.0314956, lng: 18.3282445 },
    { lat: 48.0280596, lng: 18.3242281 },
    { lat: 48.0273639, lng: 18.3235076 },
    { lat: 48.0256902, lng: 18.3212126 },
    { lat: 48.0249393, lng: 18.3204735 },
    { lat: 48.0244884, lng: 18.3203401 },
    { lat: 48.0241478, lng: 18.3203831 },
    { lat: 48.0238491, lng: 18.3205635 },
    { lat: 48.0233489, lng: 18.3214451 },
    { lat: 48.0221143, lng: 18.3208721 },
    { lat: 48.0211182, lng: 18.3203023 },
    { lat: 48.0194158, lng: 18.3199285 },
    { lat: 48.0187352, lng: 18.3194547 },
    { lat: 48.0186312, lng: 18.319587 },
    { lat: 48.0177355, lng: 18.3201231 },
    { lat: 48.0171372, lng: 18.3204127 },
    { lat: 48.0161706, lng: 18.3207139 },
    { lat: 48.0157759, lng: 18.320973 },
    { lat: 48.0136198, lng: 18.3227105 },
    { lat: 48.0111772, lng: 18.3238957 },
    { lat: 48.0102772, lng: 18.3233677 },
    { lat: 48.009915, lng: 18.3219684 },
    { lat: 48.0091051, lng: 18.3201761 },
    { lat: 48.007518, lng: 18.3176825 },
    { lat: 48.0045467, lng: 18.312454 },
    { lat: 48.0027749, lng: 18.3094766 },
    { lat: 48.0014351, lng: 18.3059851 },
  ],
  DolnýOhaj: [
    { lat: 48.0671219, lng: 18.2348321 },
    { lat: 48.0663604, lng: 18.2343655 },
    { lat: 48.0649439, lng: 18.2344773 },
    { lat: 48.0646153, lng: 18.2342583 },
    { lat: 48.0636056, lng: 18.2347293 },
    { lat: 48.0616703, lng: 18.2358945 },
    { lat: 48.0610097, lng: 18.2360051 },
    { lat: 48.0604139, lng: 18.2360236 },
    { lat: 48.0595589, lng: 18.2356602 },
    { lat: 48.0580194, lng: 18.2352446 },
    { lat: 48.0577574, lng: 18.2365266 },
    { lat: 48.057404, lng: 18.2400099 },
    { lat: 48.0573174, lng: 18.2414954 },
    { lat: 48.0570917, lng: 18.2434827 },
    { lat: 48.056884, lng: 18.2447477 },
    { lat: 48.0569068, lng: 18.2479537 },
    { lat: 48.0568651, lng: 18.2480105 },
    { lat: 48.05697, lng: 18.2482464 },
    { lat: 48.0571531, lng: 18.2482725 },
    { lat: 48.057235, lng: 18.2481051 },
    { lat: 48.0572366, lng: 18.2475486 },
    { lat: 48.0572707, lng: 18.2474349 },
    { lat: 48.0575327, lng: 18.2472435 },
    { lat: 48.0576474, lng: 18.2473332 },
    { lat: 48.0577631, lng: 18.2478336 },
    { lat: 48.0579151, lng: 18.2480214 },
    { lat: 48.0581342, lng: 18.2481054 },
    { lat: 48.0586112, lng: 18.2480624 },
    { lat: 48.0606299, lng: 18.2538194 },
    { lat: 48.0622179, lng: 18.2601753 },
    { lat: 48.0624903, lng: 18.2636088 },
    { lat: 48.0627125, lng: 18.265777 },
    { lat: 48.0629612, lng: 18.2711091 },
    { lat: 48.0608966, lng: 18.2880368 },
    { lat: 48.060506, lng: 18.2892507 },
    { lat: 48.0593351, lng: 18.2961707 },
    { lat: 48.0592681, lng: 18.2963585 },
    { lat: 48.0594447, lng: 18.298237 },
    { lat: 48.059484, lng: 18.2999439 },
    { lat: 48.0596723, lng: 18.3030709 },
    { lat: 48.0596705, lng: 18.3031381 },
    { lat: 48.0602978, lng: 18.3031967 },
    { lat: 48.0618901, lng: 18.3035364 },
    { lat: 48.0629926, lng: 18.3040899 },
    { lat: 48.0638681, lng: 18.304757 },
    { lat: 48.0643971, lng: 18.3050677 },
    { lat: 48.0647998, lng: 18.3052632 },
    { lat: 48.0657775, lng: 18.3055889 },
    { lat: 48.0673263, lng: 18.305699 },
    { lat: 48.0682183, lng: 18.3058304 },
    { lat: 48.069427, lng: 18.3062692 },
    { lat: 48.0716573, lng: 18.3069323 },
    { lat: 48.0720624, lng: 18.3070905 },
    { lat: 48.0726368, lng: 18.3076622 },
    { lat: 48.0729868, lng: 18.3082952 },
    { lat: 48.0731234, lng: 18.3085541 },
    { lat: 48.0734886, lng: 18.3080584 },
    { lat: 48.0746419, lng: 18.3056756 },
    { lat: 48.0761182, lng: 18.3019627 },
    { lat: 48.0766761, lng: 18.3008309 },
    { lat: 48.0773064, lng: 18.2996423 },
    { lat: 48.0790288, lng: 18.296642 },
    { lat: 48.0793793, lng: 18.2958104 },
    { lat: 48.0799858, lng: 18.2946419 },
    { lat: 48.0814435, lng: 18.2926529 },
    { lat: 48.0820703, lng: 18.2914052 },
    { lat: 48.0830032, lng: 18.2898095 },
    { lat: 48.0834418, lng: 18.2889005 },
    { lat: 48.0840888, lng: 18.2873371 },
    { lat: 48.0847144, lng: 18.286131 },
    { lat: 48.0881102, lng: 18.2841983 },
    { lat: 48.0892852, lng: 18.2832824 },
    { lat: 48.0902133, lng: 18.2823512 },
    { lat: 48.0904531, lng: 18.2800649 },
    { lat: 48.0903943, lng: 18.2782941 },
    { lat: 48.0902171, lng: 18.276332 },
    { lat: 48.0897418, lng: 18.2742695 },
    { lat: 48.0898475, lng: 18.2726392 },
    { lat: 48.090448, lng: 18.2687411 },
    { lat: 48.0906152, lng: 18.2671011 },
    { lat: 48.0912181, lng: 18.2645031 },
    { lat: 48.0922348, lng: 18.2605121 },
    { lat: 48.0922357, lng: 18.2603111 },
    { lat: 48.0939822, lng: 18.2532238 },
    { lat: 48.0949443, lng: 18.2493192 },
    { lat: 48.096658, lng: 18.2486464 },
    { lat: 48.0967079, lng: 18.2486354 },
    { lat: 48.096766, lng: 18.248253 },
    { lat: 48.0968713, lng: 18.2480616 },
    { lat: 48.0971473, lng: 18.2478591 },
    { lat: 48.0972315, lng: 18.2476951 },
    { lat: 48.0971922, lng: 18.2474442 },
    { lat: 48.0972836, lng: 18.2473488 },
    { lat: 48.0972912, lng: 18.2467566 },
    { lat: 48.0971741, lng: 18.2465464 },
    { lat: 48.0973977, lng: 18.2464516 },
    { lat: 48.0973762, lng: 18.2462546 },
    { lat: 48.0976027, lng: 18.2458213 },
    { lat: 48.0976883, lng: 18.245428 },
    { lat: 48.0976995, lng: 18.2449193 },
    { lat: 48.0976518, lng: 18.2447599 },
    { lat: 48.0976978, lng: 18.2443622 },
    { lat: 48.0977744, lng: 18.2442422 },
    { lat: 48.0977551, lng: 18.2440847 },
    { lat: 48.0979894, lng: 18.2439201 },
    { lat: 48.098044, lng: 18.2436751 },
    { lat: 48.0980012, lng: 18.2434837 },
    { lat: 48.0981127, lng: 18.2433052 },
    { lat: 48.0981323, lng: 18.2426094 },
    { lat: 48.0982569, lng: 18.2422453 },
    { lat: 48.0983108, lng: 18.2417141 },
    { lat: 48.0980805, lng: 18.2410103 },
    { lat: 48.0978699, lng: 18.2410163 },
    { lat: 48.0977533, lng: 18.2413218 },
    { lat: 48.0970571, lng: 18.2410875 },
    { lat: 48.0963576, lng: 18.2405427 },
    { lat: 48.0960782, lng: 18.2405127 },
    { lat: 48.0957658, lng: 18.240691 },
    { lat: 48.0954504, lng: 18.2405855 },
    { lat: 48.0953697, lng: 18.2405154 },
    { lat: 48.0953286, lng: 18.2402941 },
    { lat: 48.0953967, lng: 18.239812 },
    { lat: 48.0953146, lng: 18.2395836 },
    { lat: 48.0951078, lng: 18.2395185 },
    { lat: 48.0947508, lng: 18.239806 },
    { lat: 48.0945088, lng: 18.2402816 },
    { lat: 48.0944653, lng: 18.2405851 },
    { lat: 48.0942872, lng: 18.240919 },
    { lat: 48.0938333, lng: 18.2405815 },
    { lat: 48.0932922, lng: 18.2406283 },
    { lat: 48.0931721, lng: 18.2405637 },
    { lat: 48.0930146, lng: 18.2401219 },
    { lat: 48.0929373, lng: 18.2395294 },
    { lat: 48.0928348, lng: 18.2392991 },
    { lat: 48.0926872, lng: 18.2392253 },
    { lat: 48.0923656, lng: 18.239162 },
    { lat: 48.0920647, lng: 18.2394827 },
    { lat: 48.0918733, lng: 18.2395505 },
    { lat: 48.091144, lng: 18.2393657 },
    { lat: 48.0907251, lng: 18.2394613 },
    { lat: 48.0905023, lng: 18.2393743 },
    { lat: 48.0900418, lng: 18.2389999 },
    { lat: 48.0896178, lng: 18.239044 },
    { lat: 48.0894027, lng: 18.2389563 },
    { lat: 48.0890278, lng: 18.2384428 },
    { lat: 48.0891912, lng: 18.2381662 },
    { lat: 48.0891698, lng: 18.2377596 },
    { lat: 48.0886517, lng: 18.2373665 },
    { lat: 48.0886044, lng: 18.2371503 },
    { lat: 48.0882199, lng: 18.2368067 },
    { lat: 48.0880727, lng: 18.2365059 },
    { lat: 48.0882567, lng: 18.2361551 },
    { lat: 48.0885126, lng: 18.2358994 },
    { lat: 48.0886142, lng: 18.2346694 },
    { lat: 48.0885354, lng: 18.2343824 },
    { lat: 48.0883876, lng: 18.2340916 },
    { lat: 48.0883022, lng: 18.2331804 },
    { lat: 48.0885333, lng: 18.2325545 },
    { lat: 48.0885877, lng: 18.2321099 },
    { lat: 48.0885159, lng: 18.2316907 },
    { lat: 48.0880755, lng: 18.2312933 },
    { lat: 48.0867418, lng: 18.2297154 },
    { lat: 48.0850236, lng: 18.228576 },
    { lat: 48.084347, lng: 18.2280571 },
    { lat: 48.0840645, lng: 18.2286321 },
    { lat: 48.0836432, lng: 18.2301274 },
    { lat: 48.0829594, lng: 18.2310619 },
    { lat: 48.0825945, lng: 18.2320476 },
    { lat: 48.0824191, lng: 18.2322735 },
    { lat: 48.0822797, lng: 18.2323197 },
    { lat: 48.0815256, lng: 18.2321199 },
    { lat: 48.0809523, lng: 18.2320969 },
    { lat: 48.0804845, lng: 18.2321653 },
    { lat: 48.0796533, lng: 18.2318989 },
    { lat: 48.0772428, lng: 18.2320512 },
    { lat: 48.075582, lng: 18.2319186 },
    { lat: 48.074366, lng: 18.2320621 },
    { lat: 48.0734666, lng: 18.2323327 },
    { lat: 48.0727211, lng: 18.233179 },
    { lat: 48.0721462, lng: 18.2336758 },
    { lat: 48.0711126, lng: 18.2332249 },
    { lat: 48.0707527, lng: 18.2333228 },
    { lat: 48.0696413, lng: 18.2343376 },
    { lat: 48.0687747, lng: 18.2349986 },
    { lat: 48.0681116, lng: 18.2353561 },
    { lat: 48.0671219, lng: 18.2348321 },
  ],
  Nána: [
    { lat: 47.7992178, lng: 18.6784887 },
    { lat: 47.7992232, lng: 18.6784895 },
    { lat: 47.7992693, lng: 18.678485 },
    { lat: 47.7996215, lng: 18.6794054 },
    { lat: 47.7994849, lng: 18.6795263 },
    { lat: 47.7995382, lng: 18.679656 },
    { lat: 47.7995669, lng: 18.680934 },
    { lat: 47.8000321, lng: 18.6820886 },
    { lat: 47.8002536, lng: 18.6819719 },
    { lat: 47.8007537, lng: 18.682676 },
    { lat: 47.8012387, lng: 18.683184 },
    { lat: 47.8017967, lng: 18.684449 },
    { lat: 47.8033257, lng: 18.688519 },
    { lat: 47.8033611, lng: 18.6885092 },
    { lat: 47.8036598, lng: 18.6890681 },
    { lat: 47.8040932, lng: 18.6902575 },
    { lat: 47.804584, lng: 18.6913864 },
    { lat: 47.8045021, lng: 18.6925633 },
    { lat: 47.8077196, lng: 18.7006487 },
    { lat: 47.8078313, lng: 18.7007301 },
    { lat: 47.8093722, lng: 18.704721 },
    { lat: 47.8095501, lng: 18.7050042 },
    { lat: 47.810305, lng: 18.7071158 },
    { lat: 47.8100095, lng: 18.7075634 },
    { lat: 47.810599, lng: 18.7084538 },
    { lat: 47.8109201, lng: 18.7081146 },
    { lat: 47.8113719, lng: 18.7097101 },
    { lat: 47.8114833, lng: 18.7103954 },
    { lat: 47.8118381, lng: 18.7112461 },
    { lat: 47.8121568, lng: 18.7109247 },
    { lat: 47.812229, lng: 18.7111108 },
    { lat: 47.8122292, lng: 18.7132071 },
    { lat: 47.8127699, lng: 18.7142806 },
    { lat: 47.8150561, lng: 18.7196556 },
    { lat: 47.8164243, lng: 18.7239955 },
    { lat: 47.8168227, lng: 18.724504 },
    { lat: 47.8169696, lng: 18.7245515 },
    { lat: 47.8172854, lng: 18.7237312 },
    { lat: 47.817781, lng: 18.7234292 },
    { lat: 47.8180523, lng: 18.7240878 },
    { lat: 47.8226167, lng: 18.7202421 },
    { lat: 47.8222202, lng: 18.7193488 },
    { lat: 47.8230484, lng: 18.7181236 },
    { lat: 47.82406, lng: 18.7184143 },
    { lat: 47.8247917, lng: 18.7188927 },
    { lat: 47.8251641, lng: 18.7192296 },
    { lat: 47.8264549, lng: 18.7206449 },
    { lat: 47.8264833, lng: 18.7205011 },
    { lat: 47.8267884, lng: 18.7203652 },
    { lat: 47.8268017, lng: 18.7202959 },
    { lat: 47.8272427, lng: 18.7204846 },
    { lat: 47.8274469, lng: 18.7186256 },
    { lat: 47.8275231, lng: 18.7170496 },
    { lat: 47.8278289, lng: 18.7151068 },
    { lat: 47.8282626, lng: 18.7134651 },
    { lat: 47.8288356, lng: 18.7119819 },
    { lat: 47.8294041, lng: 18.7110431 },
    { lat: 47.8299211, lng: 18.7104675 },
    { lat: 47.8302952, lng: 18.7102085 },
    { lat: 47.831612, lng: 18.7099718 },
    { lat: 47.8318266, lng: 18.7098483 },
    { lat: 47.832377, lng: 18.70941 },
    { lat: 47.8326513, lng: 18.7091022 },
    { lat: 47.8328268, lng: 18.7085713 },
    { lat: 47.8328157, lng: 18.7078868 },
    { lat: 47.8326145, lng: 18.7063096 },
    { lat: 47.8325757, lng: 18.7053644 },
    { lat: 47.833002, lng: 18.7026682 },
    { lat: 47.8333541, lng: 18.6996467 },
    { lat: 47.8333405, lng: 18.6993556 },
    { lat: 47.8330007, lng: 18.6988717 },
    { lat: 47.8312411, lng: 18.6968851 },
    { lat: 47.8306077, lng: 18.6959547 },
    { lat: 47.8304003, lng: 18.6954544 },
    { lat: 47.830371, lng: 18.6949121 },
    { lat: 47.8304062, lng: 18.694605 },
    { lat: 47.8306252, lng: 18.6940672 },
    { lat: 47.8309341, lng: 18.6936035 },
    { lat: 47.8315355, lng: 18.693153 },
    { lat: 47.8317004, lng: 18.6930951 },
    { lat: 47.8319141, lng: 18.6931665 },
    { lat: 47.8327112, lng: 18.6937891 },
    { lat: 47.8329021, lng: 18.6941679 },
    { lat: 47.8333088, lng: 18.6952429 },
    { lat: 47.8334932, lng: 18.6955659 },
    { lat: 47.8338201, lng: 18.6954192 },
    { lat: 47.8340217, lng: 18.6951831 },
    { lat: 47.8346536, lng: 18.694002 },
    { lat: 47.8355252, lng: 18.6919316 },
    { lat: 47.8355683, lng: 18.6916232 },
    { lat: 47.8354988, lng: 18.6908414 },
    { lat: 47.835572, lng: 18.6902998 },
    { lat: 47.8363723, lng: 18.6880381 },
    { lat: 47.8363924, lng: 18.6875766 },
    { lat: 47.8360659, lng: 18.6866092 },
    { lat: 47.8367552, lng: 18.6860708 },
    { lat: 47.8362641, lng: 18.6847946 },
    { lat: 47.8358387, lng: 18.684248 },
    { lat: 47.8352933, lng: 18.6836968 },
    { lat: 47.8349188, lng: 18.6830701 },
    { lat: 47.8347861, lng: 18.6821175 },
    { lat: 47.8347907, lng: 18.6813514 },
    { lat: 47.834892, lng: 18.6807454 },
    { lat: 47.8350799, lng: 18.68009 },
    { lat: 47.8351943, lng: 18.6798517 },
    { lat: 47.836085, lng: 18.6788269 },
    { lat: 47.8375927, lng: 18.6777205 },
    { lat: 47.8382917, lng: 18.6775055 },
    { lat: 47.8390083, lng: 18.6774924 },
    { lat: 47.8397166, lng: 18.6777629 },
    { lat: 47.8403189, lng: 18.6784705 },
    { lat: 47.8407188, lng: 18.6790841 },
    { lat: 47.8410458, lng: 18.6792918 },
    { lat: 47.8418735, lng: 18.6795192 },
    { lat: 47.8424153, lng: 18.6794646 },
    { lat: 47.8429486, lng: 18.6792747 },
    { lat: 47.8435029, lng: 18.6788889 },
    { lat: 47.8440446, lng: 18.6783537 },
    { lat: 47.8441499, lng: 18.678194 },
    { lat: 47.8438942, lng: 18.6777622 },
    { lat: 47.842174, lng: 18.6748688 },
    { lat: 47.8393955, lng: 18.6708696 },
    { lat: 47.8373476, lng: 18.6675333 },
    { lat: 47.8380484, lng: 18.6669774 },
    { lat: 47.8372932, lng: 18.665079 },
    { lat: 47.8357824, lng: 18.6605659 },
    { lat: 47.8349734, lng: 18.6584676 },
    { lat: 47.8342835, lng: 18.6560698 },
    { lat: 47.8335689, lng: 18.654297 },
    { lat: 47.8332167, lng: 18.6532757 },
    { lat: 47.8324165, lng: 18.6506175 },
    { lat: 47.8324002, lng: 18.6502969 },
    { lat: 47.8315263, lng: 18.6474233 },
    { lat: 47.830791, lng: 18.6481091 },
    { lat: 47.8308084, lng: 18.6481797 },
    { lat: 47.8299981, lng: 18.6485 },
    { lat: 47.8292544, lng: 18.6491341 },
    { lat: 47.8288946, lng: 18.6492156 },
    { lat: 47.8287628, lng: 18.649429 },
    { lat: 47.8285488, lng: 18.6495569 },
    { lat: 47.8278612, lng: 18.6502057 },
    { lat: 47.8276546, lng: 18.6502849 },
    { lat: 47.8264349, lng: 18.6513303 },
    { lat: 47.8255644, lng: 18.6519382 },
    { lat: 47.8252699, lng: 18.6523895 },
    { lat: 47.8249067, lng: 18.6527195 },
    { lat: 47.8248241, lng: 18.6527792 },
    { lat: 47.8247955, lng: 18.6527093 },
    { lat: 47.8245469, lng: 18.6529273 },
    { lat: 47.824106, lng: 18.6531589 },
    { lat: 47.8236612, lng: 18.6537921 },
    { lat: 47.8228619, lng: 18.6546251 },
    { lat: 47.8227941, lng: 18.654583 },
    { lat: 47.822614, lng: 18.6548173 },
    { lat: 47.8226728, lng: 18.6549036 },
    { lat: 47.8225533, lng: 18.6550488 },
    { lat: 47.8218182, lng: 18.6558204 },
    { lat: 47.8215155, lng: 18.6560599 },
    { lat: 47.8210281, lng: 18.6566185 },
    { lat: 47.821065, lng: 18.656693 },
    { lat: 47.8207671, lng: 18.6570032 },
    { lat: 47.8208154, lng: 18.6571055 },
    { lat: 47.8204984, lng: 18.6574734 },
    { lat: 47.8202046, lng: 18.6569693 },
    { lat: 47.8202508, lng: 18.6568009 },
    { lat: 47.8206168, lng: 18.6563966 },
    { lat: 47.8210943, lng: 18.6555556 },
    { lat: 47.8213297, lng: 18.6553217 },
    { lat: 47.8212787, lng: 18.6552352 },
    { lat: 47.8214107, lng: 18.6550674 },
    { lat: 47.8219183, lng: 18.6545116 },
    { lat: 47.8220287, lng: 18.65455 },
    { lat: 47.8225204, lng: 18.6539663 },
    { lat: 47.8228635, lng: 18.6531677 },
    { lat: 47.8229339, lng: 18.6527582 },
    { lat: 47.8232763, lng: 18.6521938 },
    { lat: 47.8235695, lng: 18.6515061 },
    { lat: 47.8238082, lng: 18.6512328 },
    { lat: 47.8240027, lng: 18.6505764 },
    { lat: 47.8240951, lng: 18.6500392 },
    { lat: 47.824205, lng: 18.6488682 },
    { lat: 47.8240307, lng: 18.6484627 },
    { lat: 47.8239243, lng: 18.6484366 },
    { lat: 47.8235805, lng: 18.6480252 },
    { lat: 47.8236159, lng: 18.6479514 },
    { lat: 47.8234594, lng: 18.6477302 },
    { lat: 47.822999, lng: 18.6472479 },
    { lat: 47.8216302, lng: 18.6453967 },
    { lat: 47.8214307, lng: 18.6452743 },
    { lat: 47.8213, lng: 18.6450479 },
    { lat: 47.8211966, lng: 18.6450168 },
    { lat: 47.8213527, lng: 18.6447755 },
    { lat: 47.8210374, lng: 18.6443299 },
    { lat: 47.821082, lng: 18.6441329 },
    { lat: 47.8202934, lng: 18.6431171 },
    { lat: 47.8202374, lng: 18.6427322 },
    { lat: 47.8199759, lng: 18.6420923 },
    { lat: 47.8197055, lng: 18.6408798 },
    { lat: 47.8196359, lng: 18.6392546 },
    { lat: 47.8196964, lng: 18.6385444 },
    { lat: 47.8198059, lng: 18.6381522 },
    { lat: 47.8198236, lng: 18.6378578 },
    { lat: 47.8197151, lng: 18.6373802 },
    { lat: 47.8196234, lng: 18.6371649 },
    { lat: 47.8193742, lng: 18.6366531 },
    { lat: 47.8192811, lng: 18.636558 },
    { lat: 47.8190345, lng: 18.6364382 },
    { lat: 47.8187744, lng: 18.6364291 },
    { lat: 47.8185743, lng: 18.6365203 },
    { lat: 47.818123, lng: 18.6369343 },
    { lat: 47.8176972, lng: 18.63746 },
    { lat: 47.8163601, lng: 18.638567 },
    { lat: 47.8159022, lng: 18.6390183 },
    { lat: 47.8149731, lng: 18.639334 },
    { lat: 47.8129079, lng: 18.6398107 },
    { lat: 47.8125952, lng: 18.6408802 },
    { lat: 47.8123476, lng: 18.6422946 },
    { lat: 47.8096387, lng: 18.6591597 },
    { lat: 47.8071736, lng: 18.6579903 },
    { lat: 47.8067192, lng: 18.6577848 },
    { lat: 47.8046367, lng: 18.6572268 },
    { lat: 47.8022625, lng: 18.6562278 },
    { lat: 47.8022671, lng: 18.6562471 },
    { lat: 47.8021192, lng: 18.6565564 },
    { lat: 47.8020725, lng: 18.6566471 },
    { lat: 47.8009217, lng: 18.6584845 },
    { lat: 47.8003522, lng: 18.6605179 },
    { lat: 47.8000645, lng: 18.6641637 },
    { lat: 47.7994213, lng: 18.675198 },
    { lat: 47.7995081, lng: 18.6759 },
    { lat: 47.7992582, lng: 18.6781314 },
    { lat: 47.7992178, lng: 18.6784887 },
  ],
  Andovce: [
    { lat: 47.9729634, lng: 18.0695258 },
    { lat: 47.9729677, lng: 18.0695794 },
    { lat: 47.9732353, lng: 18.0696403 },
    { lat: 47.9739358, lng: 18.0695373 },
    { lat: 47.9748562, lng: 18.0702322 },
    { lat: 47.9755557, lng: 18.0706654 },
    { lat: 47.9760729, lng: 18.0713322 },
    { lat: 47.9765468, lng: 18.0721467 },
    { lat: 47.9770282, lng: 18.0727658 },
    { lat: 47.9773666, lng: 18.0726852 },
    { lat: 47.9775027, lng: 18.0727705 },
    { lat: 47.9779211, lng: 18.0743425 },
    { lat: 47.9780922, lng: 18.0746028 },
    { lat: 47.9789634, lng: 18.0739177 },
    { lat: 47.978957, lng: 18.07455 },
    { lat: 47.9785197, lng: 18.0763248 },
    { lat: 47.9782038, lng: 18.0772361 },
    { lat: 47.9777059, lng: 18.0781661 },
    { lat: 47.9777284, lng: 18.0787319 },
    { lat: 47.9779153, lng: 18.079012 },
    { lat: 47.9788249, lng: 18.0798086 },
    { lat: 47.9791893, lng: 18.0805094 },
    { lat: 47.9793145, lng: 18.0810804 },
    { lat: 47.9793019, lng: 18.0814371 },
    { lat: 47.9792296, lng: 18.0817957 },
    { lat: 47.9787991, lng: 18.0823959 },
    { lat: 47.9783463, lng: 18.0828078 },
    { lat: 47.9785478, lng: 18.0831824 },
    { lat: 47.9788367, lng: 18.0839023 },
    { lat: 47.9788072, lng: 18.084412 },
    { lat: 47.9787215, lng: 18.0848534 },
    { lat: 47.9780627, lng: 18.0871179 },
    { lat: 47.9775997, lng: 18.0902156 },
    { lat: 47.978031, lng: 18.0905801 },
    { lat: 47.9788807, lng: 18.0909198 },
    { lat: 47.9799347, lng: 18.0915846 },
    { lat: 47.9803279, lng: 18.0921264 },
    { lat: 47.9804815, lng: 18.0928895 },
    { lat: 47.9804088, lng: 18.093072 },
    { lat: 47.980778, lng: 18.0943891 },
    { lat: 47.9816323, lng: 18.0968604 },
    { lat: 47.981867, lng: 18.0977556 },
    { lat: 47.9819573, lng: 18.097891 },
    { lat: 47.9820301, lng: 18.0978994 },
    { lat: 47.9823046, lng: 18.0991638 },
    { lat: 47.9826194, lng: 18.1023821 },
    { lat: 47.9826907, lng: 18.1027676 },
    { lat: 47.9827393, lng: 18.1028809 },
    { lat: 47.9830578, lng: 18.1028092 },
    { lat: 47.983578, lng: 18.1032481 },
    { lat: 47.9837385, lng: 18.1035937 },
    { lat: 47.9837577, lng: 18.1044126 },
    { lat: 47.983637, lng: 18.1048554 },
    { lat: 47.9876488, lng: 18.1099647 },
    { lat: 47.9891085, lng: 18.1119002 },
    { lat: 47.9894228, lng: 18.1122363 },
    { lat: 47.9900866, lng: 18.1131761 },
    { lat: 47.990441, lng: 18.1135943 },
    { lat: 47.9906723, lng: 18.1137461 },
    { lat: 47.9909556, lng: 18.1142832 },
    { lat: 47.9921652, lng: 18.1159044 },
    { lat: 47.9944949, lng: 18.1195023 },
    { lat: 47.9976511, lng: 18.1248135 },
    { lat: 47.9982767, lng: 18.1257019 },
    { lat: 48.0029124, lng: 18.1334317 },
    { lat: 48.0036379, lng: 18.1347699 },
    { lat: 48.0048156, lng: 18.1366608 },
    { lat: 48.0084693, lng: 18.1323986 },
    { lat: 48.0130286, lng: 18.127269 },
    { lat: 48.013035, lng: 18.1272618 },
    { lat: 48.0114296, lng: 18.1251647 },
    { lat: 48.0065998, lng: 18.1182251 },
    { lat: 48.0059172, lng: 18.1173709 },
    { lat: 48.0059708, lng: 18.1159272 },
    { lat: 48.0047517, lng: 18.1129035 },
    { lat: 48.0039611, lng: 18.1118225 },
    { lat: 48.0036095, lng: 18.1087603 },
    { lat: 48.0026636, lng: 18.1068689 },
    { lat: 48.0021186, lng: 18.1057793 },
    { lat: 48.0015108, lng: 18.1030603 },
    { lat: 48.0009646, lng: 18.1014202 },
    { lat: 47.9981588, lng: 18.0964219 },
    { lat: 47.9974142, lng: 18.0934741 },
    { lat: 47.9946796, lng: 18.0833133 },
    { lat: 47.993362, lng: 18.078875 },
    { lat: 47.9925406, lng: 18.0750813 },
    { lat: 47.991905, lng: 18.071642 },
    { lat: 47.9914348, lng: 18.0703769 },
    { lat: 47.9907888, lng: 18.0683194 },
    { lat: 47.9904165, lng: 18.0662008 },
    { lat: 47.9876389, lng: 18.0565516 },
    { lat: 47.987258, lng: 18.0552565 },
    { lat: 47.9867163, lng: 18.0537584 },
    { lat: 47.984944, lng: 18.0491568 },
    { lat: 47.9843744, lng: 18.0465901 },
    { lat: 47.9835914, lng: 18.0447972 },
    { lat: 47.982802, lng: 18.0437125 },
    { lat: 47.9824348, lng: 18.0431391 },
    { lat: 47.9819987, lng: 18.0436583 },
    { lat: 47.9804852, lng: 18.0460812 },
    { lat: 47.97821, lng: 18.0495587 },
    { lat: 47.9777371, lng: 18.0510143 },
    { lat: 47.9771595, lng: 18.0511514 },
    { lat: 47.9764162, lng: 18.0514463 },
    { lat: 47.9753419, lng: 18.0528078 },
    { lat: 47.9743157, lng: 18.0537103 },
    { lat: 47.9746588, lng: 18.05519 },
    { lat: 47.9748025, lng: 18.0567471 },
    { lat: 47.9725279, lng: 18.0581009 },
    { lat: 47.9706088, lng: 18.0608485 },
    { lat: 47.9712169, lng: 18.062601 },
    { lat: 47.9718172, lng: 18.0643598 },
    { lat: 47.9720578, lng: 18.0650257 },
    { lat: 47.9721768, lng: 18.0653402 },
    { lat: 47.9722333, lng: 18.0655219 },
    { lat: 47.9723049, lng: 18.0656991 },
    { lat: 47.9726585, lng: 18.0666404 },
    { lat: 47.9729237, lng: 18.0669998 },
    { lat: 47.9730081, lng: 18.0674722 },
    { lat: 47.9729148, lng: 18.06875 },
    { lat: 47.9729577, lng: 18.0694501 },
    { lat: 47.9729634, lng: 18.0695258 },
  ],
  NováVieska: [
    { lat: 47.8687265, lng: 18.4949171 },
    { lat: 47.8697801, lng: 18.4908145 },
    { lat: 47.8702504, lng: 18.4902671 },
    { lat: 47.8701253, lng: 18.4895057 },
    { lat: 47.8693667, lng: 18.4893317 },
    { lat: 47.8692634, lng: 18.4873011 },
    { lat: 47.8696516, lng: 18.4872838 },
    { lat: 47.8723095, lng: 18.4877109 },
    { lat: 47.8736373, lng: 18.4880965 },
    { lat: 47.8747865, lng: 18.4885551 },
    { lat: 47.8774173, lng: 18.4898884 },
    { lat: 47.8790299, lng: 18.4905867 },
    { lat: 47.88037, lng: 18.4868086 },
    { lat: 47.8800467, lng: 18.4864174 },
    { lat: 47.8804587, lng: 18.4853889 },
    { lat: 47.8814053, lng: 18.4835761 },
    { lat: 47.8820321, lng: 18.4820955 },
    { lat: 47.8823866, lng: 18.4824449 },
    { lat: 47.8824912, lng: 18.4822577 },
    { lat: 47.8834902, lng: 18.4800299 },
    { lat: 47.8840239, lng: 18.4786429 },
    { lat: 47.8855348, lng: 18.4753051 },
    { lat: 47.8858242, lng: 18.4754953 },
    { lat: 47.8866058, lng: 18.4757345 },
    { lat: 47.8870523, lng: 18.4757713 },
    { lat: 47.8872838, lng: 18.4756924 },
    { lat: 47.8879945, lng: 18.4750271 },
    { lat: 47.8889115, lng: 18.4738592 },
    { lat: 47.8894481, lng: 18.4733471 },
    { lat: 47.8901, lng: 18.4725607 },
    { lat: 47.891979, lng: 18.4704996 },
    { lat: 47.8927385, lng: 18.4689508 },
    { lat: 47.8928843, lng: 18.4684368 },
    { lat: 47.8928049, lng: 18.4679858 },
    { lat: 47.8894466, lng: 18.4694338 },
    { lat: 47.8892379, lng: 18.4685708 },
    { lat: 47.887585, lng: 18.4694293 },
    { lat: 47.8876706, lng: 18.4697706 },
    { lat: 47.8877721, lng: 18.4701751 },
    { lat: 47.887743, lng: 18.4701941 },
    { lat: 47.8875551, lng: 18.4695212 },
    { lat: 47.8865256, lng: 18.4697095 },
    { lat: 47.8854576, lng: 18.4682055 },
    { lat: 47.8871549, lng: 18.4667676 },
    { lat: 47.8899014, lng: 18.4636609 },
    { lat: 47.8897038, lng: 18.4631982 },
    { lat: 47.8892752, lng: 18.4627351 },
    { lat: 47.886867, lng: 18.4604882 },
    { lat: 47.8867294, lng: 18.4602901 },
    { lat: 47.8858527, lng: 18.4597013 },
    { lat: 47.8856526, lng: 18.4592524 },
    { lat: 47.8829939, lng: 18.4568285 },
    { lat: 47.8811845, lng: 18.4553202 },
    { lat: 47.8804294, lng: 18.454592 },
    { lat: 47.8812808, lng: 18.4533622 },
    { lat: 47.8821113, lng: 18.451839 },
    { lat: 47.8823768, lng: 18.4507844 },
    { lat: 47.8828783, lng: 18.4492894 },
    { lat: 47.8840017, lng: 18.446494 },
    { lat: 47.8824342, lng: 18.4446 },
    { lat: 47.883293, lng: 18.4433852 },
    { lat: 47.8834039, lng: 18.4422472 },
    { lat: 47.8838213, lng: 18.4413065 },
    { lat: 47.8845514, lng: 18.4406273 },
    { lat: 47.885702, lng: 18.4400656 },
    { lat: 47.8869438, lng: 18.4396193 },
    { lat: 47.8877999, lng: 18.4386348 },
    { lat: 47.8865682, lng: 18.4370973 },
    { lat: 47.8842663, lng: 18.4340639 },
    { lat: 47.8842455, lng: 18.4340967 },
    { lat: 47.8840035, lng: 18.4337849 },
    { lat: 47.884214, lng: 18.4332786 },
    { lat: 47.8811627, lng: 18.4301034 },
    { lat: 47.8682349, lng: 18.4166524 },
    { lat: 47.8677503, lng: 18.4161431 },
    { lat: 47.867204, lng: 18.415431 },
    { lat: 47.867148, lng: 18.415524 },
    { lat: 47.867015, lng: 18.415749 },
    { lat: 47.866835, lng: 18.416057 },
    { lat: 47.866715, lng: 18.416262 },
    { lat: 47.86649, lng: 18.416647 },
    { lat: 47.86625, lng: 18.417055 },
    { lat: 47.866183, lng: 18.417171 },
    { lat: 47.866024, lng: 18.417442 },
    { lat: 47.86599, lng: 18.417499 },
    { lat: 47.865757, lng: 18.417896 },
    { lat: 47.86551, lng: 18.418313 },
    { lat: 47.865354, lng: 18.418582 },
    { lat: 47.865186, lng: 18.418871 },
    { lat: 47.865017, lng: 18.41916 },
    { lat: 47.864809, lng: 18.419513 },
    { lat: 47.864505, lng: 18.420031 },
    { lat: 47.864095, lng: 18.420735 },
    { lat: 47.863671, lng: 18.421455 },
    { lat: 47.863425, lng: 18.42187 },
    { lat: 47.863284, lng: 18.422114 },
    { lat: 47.863262, lng: 18.422151 },
    { lat: 47.863, lng: 18.4226 },
    { lat: 47.862824, lng: 18.422898 },
    { lat: 47.862656, lng: 18.423185 },
    { lat: 47.862489, lng: 18.423468 },
    { lat: 47.86219, lng: 18.423979 },
    { lat: 47.861925, lng: 18.424428 },
    { lat: 47.86163, lng: 18.424934 },
    { lat: 47.861321, lng: 18.425457 },
    { lat: 47.860905, lng: 18.426168 },
    { lat: 47.860776, lng: 18.426387 },
    { lat: 47.860713, lng: 18.426493 },
    { lat: 47.860511, lng: 18.426838 },
    { lat: 47.860264, lng: 18.427255 },
    { lat: 47.859914, lng: 18.427855 },
    { lat: 47.859826, lng: 18.428 },
    { lat: 47.859618, lng: 18.428358 },
    { lat: 47.859437, lng: 18.428664 },
    { lat: 47.859333, lng: 18.428848 },
    { lat: 47.859223, lng: 18.429024 },
    { lat: 47.858938, lng: 18.429509 },
    { lat: 47.85873, lng: 18.42986 },
    { lat: 47.858399, lng: 18.430427 },
    { lat: 47.857989, lng: 18.431125 },
    { lat: 47.857808, lng: 18.43143 },
    { lat: 47.857688, lng: 18.431635 },
    { lat: 47.857529, lng: 18.431904 },
    { lat: 47.857365, lng: 18.432185 },
    { lat: 47.857154, lng: 18.43254 },
    { lat: 47.856971, lng: 18.432854 },
    { lat: 47.856867, lng: 18.433026 },
    { lat: 47.85673, lng: 18.433263 },
    { lat: 47.85652, lng: 18.43362 },
    { lat: 47.856452, lng: 18.433736 },
    { lat: 47.85632, lng: 18.433962 },
    { lat: 47.856288, lng: 18.434015 },
    { lat: 47.856191, lng: 18.43418 },
    { lat: 47.856092, lng: 18.434348 },
    { lat: 47.855987, lng: 18.434527 },
    { lat: 47.855922, lng: 18.434638 },
    { lat: 47.855781, lng: 18.43488 },
    { lat: 47.855565, lng: 18.435245 },
    { lat: 47.855251, lng: 18.43578 },
    { lat: 47.855076, lng: 18.436075 },
    { lat: 47.854801, lng: 18.436547 },
    { lat: 47.854723, lng: 18.436679 },
    { lat: 47.854629, lng: 18.436837 },
    { lat: 47.854543, lng: 18.436983 },
    { lat: 47.854413, lng: 18.437204 },
    { lat: 47.854281, lng: 18.437429 },
    { lat: 47.854153, lng: 18.437648 },
    { lat: 47.853932, lng: 18.438023 },
    { lat: 47.85386, lng: 18.438143 },
    { lat: 47.853666, lng: 18.438482 },
    { lat: 47.85346, lng: 18.438833 },
    { lat: 47.853219, lng: 18.439234 },
    { lat: 47.853141, lng: 18.439363 },
    { lat: 47.852933, lng: 18.439715 },
    { lat: 47.852792, lng: 18.439953 },
    { lat: 47.852692, lng: 18.440122 },
    { lat: 47.85259, lng: 18.440297 },
    { lat: 47.85249, lng: 18.440466 },
    { lat: 47.852386, lng: 18.440645 },
    { lat: 47.852316, lng: 18.440764 },
    { lat: 47.852133, lng: 18.441071 },
    { lat: 47.851972, lng: 18.441341 },
    { lat: 47.851861, lng: 18.441532 },
    { lat: 47.851715, lng: 18.441775 },
    { lat: 47.851529, lng: 18.442094 },
    { lat: 47.851414, lng: 18.442293 },
    { lat: 47.851212, lng: 18.442627 },
    { lat: 47.851165, lng: 18.442709 },
    { lat: 47.851149, lng: 18.442739 },
    { lat: 47.85114, lng: 18.442751 },
    { lat: 47.851096, lng: 18.442828 },
    { lat: 47.851091, lng: 18.44284 },
    { lat: 47.851365, lng: 18.443195 },
    { lat: 47.851388, lng: 18.443227 },
    { lat: 47.851376, lng: 18.443249 },
    { lat: 47.851207, lng: 18.443517 },
    { lat: 47.851047, lng: 18.44377 },
    { lat: 47.850842, lng: 18.444097 },
    { lat: 47.850668, lng: 18.444373 },
    { lat: 47.850446, lng: 18.444725 },
    { lat: 47.850277, lng: 18.444993 },
    { lat: 47.850061, lng: 18.445337 },
    { lat: 47.849884, lng: 18.445617 },
    { lat: 47.849795, lng: 18.445749 },
    { lat: 47.84952, lng: 18.446151 },
    { lat: 47.849377, lng: 18.44636 },
    { lat: 47.849279, lng: 18.446506 },
    { lat: 47.849248, lng: 18.446555 },
    { lat: 47.849023, lng: 18.446905 },
    { lat: 47.848899, lng: 18.447099 },
    { lat: 47.848716, lng: 18.447386 },
    { lat: 47.848594, lng: 18.447577 },
    { lat: 47.848363, lng: 18.44794 },
    { lat: 47.848333, lng: 18.447977 },
    { lat: 47.848135, lng: 18.44826 },
    { lat: 47.847914, lng: 18.44858 },
    { lat: 47.847698, lng: 18.44889 },
    { lat: 47.847499, lng: 18.449173 },
    { lat: 47.847324, lng: 18.449423 },
    { lat: 47.847183, lng: 18.449629 },
    { lat: 47.847054, lng: 18.449816 },
    { lat: 47.84702, lng: 18.449863 },
    { lat: 47.846962, lng: 18.449927 },
    { lat: 47.846777, lng: 18.450132 },
    { lat: 47.846656, lng: 18.450266 },
    { lat: 47.846553, lng: 18.450398 },
    { lat: 47.846526, lng: 18.45043 },
    { lat: 47.846459, lng: 18.450532 },
    { lat: 47.846333, lng: 18.450718 },
    { lat: 47.846138, lng: 18.451008 },
    { lat: 47.846075, lng: 18.451102 },
    { lat: 47.845996, lng: 18.451221 },
    { lat: 47.845891, lng: 18.451427 },
    { lat: 47.845869, lng: 18.451471 },
    { lat: 47.845785, lng: 18.451635 },
    { lat: 47.845687, lng: 18.451828 },
    { lat: 47.845614, lng: 18.451976 },
    { lat: 47.84551, lng: 18.452179 },
    { lat: 47.845402, lng: 18.452438 },
    { lat: 47.845274, lng: 18.452696 },
    { lat: 47.845124, lng: 18.453005 },
    { lat: 47.845072, lng: 18.453109 },
    { lat: 47.844873, lng: 18.453427 },
    { lat: 47.844823, lng: 18.453513 },
    { lat: 47.844784, lng: 18.453703 },
    { lat: 47.844738, lng: 18.453846 },
    { lat: 47.844628, lng: 18.454145 },
    { lat: 47.844504, lng: 18.454491 },
    { lat: 47.844445, lng: 18.454747 },
    { lat: 47.844396, lng: 18.454957 },
    { lat: 47.844326, lng: 18.455258 },
    { lat: 47.844276, lng: 18.45547 },
    { lat: 47.844232, lng: 18.455655 },
    { lat: 47.844161, lng: 18.455956 },
    { lat: 47.844071, lng: 18.45634 },
    { lat: 47.843971, lng: 18.456784 },
    { lat: 47.843911, lng: 18.457033 },
    { lat: 47.843803, lng: 18.457504 },
    { lat: 47.843693, lng: 18.457977 },
    { lat: 47.843671, lng: 18.458063 },
    { lat: 47.843645, lng: 18.458123 },
    { lat: 47.843455, lng: 18.458607 },
    { lat: 47.843271, lng: 18.459072 },
    { lat: 47.843211, lng: 18.459225 },
    { lat: 47.843091, lng: 18.459536 },
    { lat: 47.843083, lng: 18.459558 },
    { lat: 47.843161, lng: 18.460123 },
    { lat: 47.843218, lng: 18.460554 },
    { lat: 47.843236, lng: 18.460677 },
    { lat: 47.8433, lng: 18.461131 },
    { lat: 47.843342, lng: 18.461408 },
    { lat: 47.843355, lng: 18.461496 },
    { lat: 47.843378, lng: 18.461641 },
    { lat: 47.8444026, lng: 18.4617299 },
    { lat: 47.8444506, lng: 18.4619027 },
    { lat: 47.8449449, lng: 18.4627692 },
    { lat: 47.8453071, lng: 18.4632553 },
    { lat: 47.8460838, lng: 18.4645079 },
    { lat: 47.846581, lng: 18.4651069 },
    { lat: 47.8470805, lng: 18.4658485 },
    { lat: 47.8478195, lng: 18.4666549 },
    { lat: 47.8481283, lng: 18.4672495 },
    { lat: 47.8482421, lng: 18.4673478 },
    { lat: 47.8488429, lng: 18.4683659 },
    { lat: 47.8491913, lng: 18.4686859 },
    { lat: 47.8495002, lng: 18.4687822 },
    { lat: 47.8499114, lng: 18.4687327 },
    { lat: 47.8502492, lng: 18.4688798 },
    { lat: 47.8514166, lng: 18.4709317 },
    { lat: 47.8518829, lng: 18.4713007 },
    { lat: 47.8521885, lng: 18.4718813 },
    { lat: 47.8524158, lng: 18.472112 },
    { lat: 47.8533219, lng: 18.4727185 },
    { lat: 47.8539534, lng: 18.4729772 },
    { lat: 47.8551381, lng: 18.4736641 },
    { lat: 47.8553418, lng: 18.4740203 },
    { lat: 47.8557259, lng: 18.4743121 },
    { lat: 47.8558495, lng: 18.4746062 },
    { lat: 47.8559833, lng: 18.4747379 },
    { lat: 47.856901, lng: 18.4751891 },
    { lat: 47.8571531, lng: 18.4754231 },
    { lat: 47.8573214, lng: 18.4754652 },
    { lat: 47.8575635, lng: 18.4764458 },
    { lat: 47.857689, lng: 18.4772557 },
    { lat: 47.8576974, lng: 18.4788745 },
    { lat: 47.8587836, lng: 18.4799644 },
    { lat: 47.858463, lng: 18.4811069 },
    { lat: 47.8604894, lng: 18.4830094 },
    { lat: 47.8589934, lng: 18.4861103 },
    { lat: 47.8627002, lng: 18.4890363 },
    { lat: 47.8686571, lng: 18.4948463 },
    { lat: 47.8687265, lng: 18.4949171 },
  ],
  MalánadHronom: [
    { lat: 47.8659151, lng: 18.6610699 },
    { lat: 47.8655718, lng: 18.6614978 },
    { lat: 47.8654206, lng: 18.6617471 },
    { lat: 47.8652475, lng: 18.6621925 },
    { lat: 47.8649414, lng: 18.6636142 },
    { lat: 47.8647803, lng: 18.6641607 },
    { lat: 47.8646516, lng: 18.664448 },
    { lat: 47.8643977, lng: 18.664778 },
    { lat: 47.8639281, lng: 18.6651659 },
    { lat: 47.8634235, lng: 18.6653617 },
    { lat: 47.8623588, lng: 18.6655495 },
    { lat: 47.8623231, lng: 18.6652875 },
    { lat: 47.862206, lng: 18.6653114 },
    { lat: 47.8610246, lng: 18.6647824 },
    { lat: 47.8606174, lng: 18.6647409 },
    { lat: 47.8600468, lng: 18.6649527 },
    { lat: 47.859587, lng: 18.6652277 },
    { lat: 47.8594368, lng: 18.6653908 },
    { lat: 47.8590148, lng: 18.6661164 },
    { lat: 47.8587535, lng: 18.666783 },
    { lat: 47.8585319, lng: 18.6675249 },
    { lat: 47.8574211, lng: 18.668896 },
    { lat: 47.8575777, lng: 18.6698475 },
    { lat: 47.857026, lng: 18.670137 },
    { lat: 47.856312, lng: 18.6708431 },
    { lat: 47.855877, lng: 18.67139 },
    { lat: 47.8551632, lng: 18.6725118 },
    { lat: 47.8546219, lng: 18.6738426 },
    { lat: 47.8541737, lng: 18.6751875 },
    { lat: 47.8539081, lng: 18.6756761 },
    { lat: 47.8537285, lng: 18.6758992 },
    { lat: 47.8531973, lng: 18.676171 },
    { lat: 47.8527352, lng: 18.6762179 },
    { lat: 47.8520242, lng: 18.6757546 },
    { lat: 47.8516752, lng: 18.6751636 },
    { lat: 47.8514573, lng: 18.6746367 },
    { lat: 47.8513566, lng: 18.6738011 },
    { lat: 47.8514063, lng: 18.6732232 },
    { lat: 47.8515987, lng: 18.6724268 },
    { lat: 47.8516136, lng: 18.6723651 },
    { lat: 47.8519555, lng: 18.6717448 },
    { lat: 47.8525815, lng: 18.6710166 },
    { lat: 47.8528509, lng: 18.6706195 },
    { lat: 47.853145, lng: 18.670034 },
    { lat: 47.853334, lng: 18.6695264 },
    { lat: 47.853431, lng: 18.6689635 },
    { lat: 47.853451, lng: 18.6684182 },
    { lat: 47.8533632, lng: 18.6675568 },
    { lat: 47.8533494, lng: 18.6674216 },
    { lat: 47.8531595, lng: 18.6668937 },
    { lat: 47.8525537, lng: 18.6656043 },
    { lat: 47.851847, lng: 18.6645721 },
    { lat: 47.8516166, lng: 18.6643206 },
    { lat: 47.8512545, lng: 18.6641347 },
    { lat: 47.850925, lng: 18.6640897 },
    { lat: 47.8503981, lng: 18.664189 },
    { lat: 47.8500409, lng: 18.6643822 },
    { lat: 47.8494933, lng: 18.664931 },
    { lat: 47.8489356, lng: 18.6658971 },
    { lat: 47.8487766, lng: 18.666334 },
    { lat: 47.8485221, lng: 18.6673564 },
    { lat: 47.8484459, lng: 18.6690091 },
    { lat: 47.8483155, lng: 18.670116 },
    { lat: 47.8481298, lng: 18.6710181 },
    { lat: 47.8478932, lng: 18.6717929 },
    { lat: 47.8475101, lng: 18.6725021 },
    { lat: 47.8473138, lng: 18.6727313 },
    { lat: 47.8468155, lng: 18.6731077 },
    { lat: 47.8459428, lng: 18.6735939 },
    { lat: 47.8451108, lng: 18.6743062 },
    { lat: 47.844987, lng: 18.674498 },
    { lat: 47.8448593, lng: 18.674845 },
    { lat: 47.844704, lng: 18.6757255 },
    { lat: 47.8445595, lng: 18.6770741 },
    { lat: 47.8444571, lng: 18.6775168 },
    { lat: 47.8441499, lng: 18.678194 },
    { lat: 47.8440446, lng: 18.6783537 },
    { lat: 47.8435029, lng: 18.6788889 },
    { lat: 47.8429486, lng: 18.6792747 },
    { lat: 47.8424153, lng: 18.6794646 },
    { lat: 47.8418735, lng: 18.6795192 },
    { lat: 47.8410458, lng: 18.6792918 },
    { lat: 47.8407188, lng: 18.6790841 },
    { lat: 47.8403189, lng: 18.6784705 },
    { lat: 47.8397166, lng: 18.6777629 },
    { lat: 47.8390083, lng: 18.6774924 },
    { lat: 47.8382917, lng: 18.6775055 },
    { lat: 47.8375927, lng: 18.6777205 },
    { lat: 47.836085, lng: 18.6788269 },
    { lat: 47.8351943, lng: 18.6798517 },
    { lat: 47.8350799, lng: 18.68009 },
    { lat: 47.834892, lng: 18.6807454 },
    { lat: 47.8347907, lng: 18.6813514 },
    { lat: 47.8347861, lng: 18.6821175 },
    { lat: 47.8349188, lng: 18.6830701 },
    { lat: 47.8352933, lng: 18.6836968 },
    { lat: 47.8358387, lng: 18.684248 },
    { lat: 47.8362641, lng: 18.6847946 },
    { lat: 47.8367552, lng: 18.6860708 },
    { lat: 47.8376018, lng: 18.6854179 },
    { lat: 47.8380127, lng: 18.68505 },
    { lat: 47.8386217, lng: 18.6847168 },
    { lat: 47.8392781, lng: 18.6847798 },
    { lat: 47.8398449, lng: 18.6849163 },
    { lat: 47.8401145, lng: 18.6851849 },
    { lat: 47.8401555, lng: 18.6857147 },
    { lat: 47.8404045, lng: 18.6869575 },
    { lat: 47.8408165, lng: 18.6871547 },
    { lat: 47.840994, lng: 18.6884113 },
    { lat: 47.8415348, lng: 18.6889839 },
    { lat: 47.8411717, lng: 18.6908822 },
    { lat: 47.8411856, lng: 18.6912836 },
    { lat: 47.8412785, lng: 18.6916853 },
    { lat: 47.8415022, lng: 18.6921947 },
    { lat: 47.8417073, lng: 18.6924419 },
    { lat: 47.8427406, lng: 18.6929241 },
    { lat: 47.8433768, lng: 18.6925726 },
    { lat: 47.8440325, lng: 18.692641 },
    { lat: 47.8440426, lng: 18.6927058 },
    { lat: 47.8447234, lng: 18.692633 },
    { lat: 47.8455358, lng: 18.6935137 },
    { lat: 47.8464282, lng: 18.6939366 },
    { lat: 47.8471455, lng: 18.6948334 },
    { lat: 47.8472653, lng: 18.695108 },
    { lat: 47.8475455, lng: 18.6954727 },
    { lat: 47.8476642, lng: 18.6957939 },
    { lat: 47.8485634, lng: 18.6953979 },
    { lat: 47.8486035, lng: 18.6956196 },
    { lat: 47.8491696, lng: 18.6960591 },
    { lat: 47.8499236, lng: 18.6961127 },
    { lat: 47.849896, lng: 18.6963934 },
    { lat: 47.8501159, lng: 18.6972263 },
    { lat: 47.8502023, lng: 18.6973634 },
    { lat: 47.8503663, lng: 18.6974376 },
    { lat: 47.8509329, lng: 18.697943 },
    { lat: 47.8519639, lng: 18.698636 },
    { lat: 47.853771, lng: 18.698767 },
    { lat: 47.854159, lng: 18.6992692 },
    { lat: 47.854124, lng: 18.7002485 },
    { lat: 47.8537223, lng: 18.7021444 },
    { lat: 47.8543884, lng: 18.7023656 },
    { lat: 47.8553934, lng: 18.7020123 },
    { lat: 47.8564158, lng: 18.7013127 },
    { lat: 47.8578952, lng: 18.6997618 },
    { lat: 47.8590069, lng: 18.6992977 },
    { lat: 47.8600102, lng: 18.6993519 },
    { lat: 47.861248, lng: 18.6998205 },
    { lat: 47.8624203, lng: 18.7004972 },
    { lat: 47.8628948, lng: 18.7005962 },
    { lat: 47.863552, lng: 18.7004641 },
    { lat: 47.8672556, lng: 18.6993332 },
    { lat: 47.8692167, lng: 18.6991417 },
    { lat: 47.869248, lng: 18.6991393 },
    { lat: 47.8726979, lng: 18.6986936 },
    { lat: 47.8725671, lng: 18.6973185 },
    { lat: 47.8722799, lng: 18.6931338 },
    { lat: 47.8719881, lng: 18.6880272 },
    { lat: 47.8708493, lng: 18.6731099 },
    { lat: 47.8707122, lng: 18.6713136 },
    { lat: 47.8705324, lng: 18.6694548 },
    { lat: 47.8703471, lng: 18.6682009 },
    { lat: 47.8698975, lng: 18.6655716 },
    { lat: 47.8697678, lng: 18.6650315 },
    { lat: 47.8694058, lng: 18.6648687 },
    { lat: 47.8691739, lng: 18.6644582 },
    { lat: 47.868754, lng: 18.6638939 },
    { lat: 47.8682517, lng: 18.6637803 },
    { lat: 47.8681955, lng: 18.6638675 },
    { lat: 47.8672202, lng: 18.6627715 },
    { lat: 47.8661102, lng: 18.6615242 },
    { lat: 47.8659151, lng: 18.6610699 },
  ],
  Salka: [
    { lat: 47.9446921, lng: 18.7728819 },
    { lat: 47.944862, lng: 18.772575 },
    { lat: 47.944899, lng: 18.772511 },
    { lat: 47.944923, lng: 18.772467 },
    { lat: 47.945016, lng: 18.772226 },
    { lat: 47.9451, lng: 18.771998 },
    { lat: 47.945328, lng: 18.771379 },
    { lat: 47.945426, lng: 18.771038 },
    { lat: 47.945563, lng: 18.770581 },
    { lat: 47.9457, lng: 18.770143 },
    { lat: 47.946359, lng: 18.768859 },
    { lat: 47.946727, lng: 18.768161 },
    { lat: 47.946826, lng: 18.76795 },
    { lat: 47.947965, lng: 18.765686 },
    { lat: 47.947989, lng: 18.765639 },
    { lat: 47.948178, lng: 18.764532 },
    { lat: 47.948196, lng: 18.764199 },
    { lat: 47.948201, lng: 18.764121 },
    { lat: 47.948236, lng: 18.763497 },
    { lat: 47.94829, lng: 18.761594 },
    { lat: 47.9483, lng: 18.76133 },
    { lat: 47.948314, lng: 18.760464 },
    { lat: 47.948293, lng: 18.760018 },
    { lat: 47.948221, lng: 18.758286 },
    { lat: 47.948189, lng: 18.756205 },
    { lat: 47.948179, lng: 18.755332 },
    { lat: 47.948244, lng: 18.754313 },
    { lat: 47.948267, lng: 18.754056 },
    { lat: 47.948272, lng: 18.752969 },
    { lat: 47.948298, lng: 18.752071 },
    { lat: 47.948316, lng: 18.751663 },
    { lat: 47.948322, lng: 18.751551 },
    { lat: 47.948306, lng: 18.751039 },
    { lat: 47.948291, lng: 18.750594 },
    { lat: 47.948291, lng: 18.750585 },
    { lat: 47.94829, lng: 18.750547 },
    { lat: 47.948278, lng: 18.750118 },
    { lat: 47.947347, lng: 18.746813 },
    { lat: 47.947268, lng: 18.745206 },
    { lat: 47.947909, lng: 18.744629 },
    { lat: 47.947923, lng: 18.744616 },
    { lat: 47.947936, lng: 18.744561 },
    { lat: 47.948156, lng: 18.74361 },
    { lat: 47.948235, lng: 18.743305 },
    { lat: 47.948397, lng: 18.742753 },
    { lat: 47.948445, lng: 18.742572 },
    { lat: 47.948796, lng: 18.741462 },
    { lat: 47.948832, lng: 18.74137 },
    { lat: 47.948618, lng: 18.740925 },
    { lat: 47.948502, lng: 18.740681 },
    { lat: 47.948464, lng: 18.740422 },
    { lat: 47.948481, lng: 18.740343 },
    { lat: 47.948657, lng: 18.739952 },
    { lat: 47.948659, lng: 18.739876 },
    { lat: 47.948586, lng: 18.73975 },
    { lat: 47.948476, lng: 18.739631 },
    { lat: 47.948416, lng: 18.739629 },
    { lat: 47.94789, lng: 18.739819 },
    { lat: 47.947819, lng: 18.739832 },
    { lat: 47.947788, lng: 18.73965 },
    { lat: 47.947755, lng: 18.739458 },
    { lat: 47.947742, lng: 18.739314 },
    { lat: 47.9477374, lng: 18.7392634 },
    { lat: 47.947733, lng: 18.739213 },
    { lat: 47.947435, lng: 18.739156 },
    { lat: 47.947138, lng: 18.739014 },
    { lat: 47.946914, lng: 18.738959 },
    { lat: 47.946912, lng: 18.738945 },
    { lat: 47.946894, lng: 18.738767 },
    { lat: 47.946884, lng: 18.738612 },
    { lat: 47.946858, lng: 18.738207 },
    { lat: 47.946774, lng: 18.737179 },
    { lat: 47.946772, lng: 18.737145 },
    { lat: 47.946746, lng: 18.736816 },
    { lat: 47.946736, lng: 18.73672 },
    { lat: 47.946681, lng: 18.736048 },
    { lat: 47.946603, lng: 18.735271 },
    { lat: 47.946596, lng: 18.7352 },
    { lat: 47.946591, lng: 18.734754 },
    { lat: 47.94659, lng: 18.734712 },
    { lat: 47.946566, lng: 18.732605 },
    { lat: 47.946556, lng: 18.731832 },
    { lat: 47.946549, lng: 18.731247 },
    { lat: 47.946547, lng: 18.730167 },
    { lat: 47.94655, lng: 18.728691 },
    { lat: 47.946689, lng: 18.727192 },
    { lat: 47.946686, lng: 18.726735 },
    { lat: 47.946684, lng: 18.726653 },
    { lat: 47.946682, lng: 18.726243 },
    { lat: 47.947202, lng: 18.725257 },
    { lat: 47.947273, lng: 18.725173 },
    { lat: 47.947441, lng: 18.724971 },
    { lat: 47.948294, lng: 18.723951 },
    { lat: 47.948861, lng: 18.722369 },
    { lat: 47.949464, lng: 18.720994 },
    { lat: 47.949514, lng: 18.720879 },
    { lat: 47.949598, lng: 18.719254 },
    { lat: 47.949844, lng: 18.717931 },
    { lat: 47.94938, lng: 18.717373 },
    { lat: 47.948671, lng: 18.716501 },
    { lat: 47.947709, lng: 18.715318 },
    { lat: 47.946769, lng: 18.714157 },
    { lat: 47.946541, lng: 18.713899 },
    { lat: 47.945956, lng: 18.713249 },
    { lat: 47.945889, lng: 18.713174 },
    { lat: 47.94583, lng: 18.713106 },
    { lat: 47.9444188, lng: 18.7121167 },
    { lat: 47.9430367, lng: 18.7113318 },
    { lat: 47.9415515, lng: 18.710917 },
    { lat: 47.9401165, lng: 18.7119139 },
    { lat: 47.9376509, lng: 18.7129163 },
    { lat: 47.936668, lng: 18.7139528 },
    { lat: 47.936315, lng: 18.71431 },
    { lat: 47.9367143, lng: 18.7211237 },
    { lat: 47.9367348, lng: 18.7263334 },
    { lat: 47.9371142, lng: 18.7265503 },
    { lat: 47.9372098, lng: 18.7272001 },
    { lat: 47.9372238, lng: 18.7280052 },
    { lat: 47.9373124, lng: 18.7289203 },
    { lat: 47.9371755, lng: 18.7337014 },
    { lat: 47.9367381, lng: 18.7382283 },
    { lat: 47.9365383, lng: 18.743483 },
    { lat: 47.9359568, lng: 18.7522579 },
    { lat: 47.9345188, lng: 18.7578907 },
    { lat: 47.9362049, lng: 18.7644783 },
    { lat: 47.9376514, lng: 18.7696695 },
    { lat: 47.938384, lng: 18.773788 },
    { lat: 47.93888, lng: 18.77369 },
    { lat: 47.939339, lng: 18.773503 },
    { lat: 47.940791, lng: 18.772653 },
    { lat: 47.941525, lng: 18.772363 },
    { lat: 47.941915, lng: 18.77233 },
    { lat: 47.942844, lng: 18.772401 },
    { lat: 47.9446921, lng: 18.7728819 },
  ],
  SalkaExt: [
    { lat: 47.9098775, lng: 18.7116662 },
    { lat: 47.909217, lng: 18.7110043 },
    { lat: 47.9079171, lng: 18.7100087 },
    { lat: 47.9072698, lng: 18.7090268 },
    { lat: 47.9062559, lng: 18.7072732 },
    { lat: 47.905362, lng: 18.7056048 },
    { lat: 47.9042209, lng: 18.7043557 },
    { lat: 47.9029641, lng: 18.7033669 },
    { lat: 47.9016917, lng: 18.7021487 },
    { lat: 47.9002247, lng: 18.7024678 },
    { lat: 47.8988114, lng: 18.7032378 },
    { lat: 47.8976936, lng: 18.7047857 },
    { lat: 47.896028, lng: 18.7047275 },
    { lat: 47.8949123, lng: 18.7061643 },
    { lat: 47.8940494, lng: 18.7069141 },
    { lat: 47.8912909, lng: 18.7088865 },
    { lat: 47.8906531, lng: 18.7098336 },
    { lat: 47.8900535, lng: 18.7102817 },
    { lat: 47.8890543, lng: 18.7102205 },
    { lat: 47.8871175, lng: 18.708602 },
    { lat: 47.8863273, lng: 18.7077324 },
    { lat: 47.8849024, lng: 18.704756 },
    { lat: 47.8837652, lng: 18.7039342 },
    { lat: 47.8830571, lng: 18.7055455 },
    { lat: 47.8822393, lng: 18.7060253 },
    { lat: 47.8782845, lng: 18.7071227 },
    { lat: 47.8766621, lng: 18.7070481 },
    { lat: 47.8754041, lng: 18.7067898 },
    { lat: 47.8737901, lng: 18.7060966 },
    { lat: 47.8722418, lng: 18.7050795 },
    { lat: 47.8707731, lng: 18.7030895 },
    { lat: 47.8700392, lng: 18.7022402 },
    { lat: 47.8693831, lng: 18.7023258 },
    { lat: 47.8689967, lng: 18.7050712 },
    { lat: 47.8675096, lng: 18.7050391 },
    { lat: 47.8672724, lng: 18.7055363 },
    { lat: 47.86782, lng: 18.7081386 },
    { lat: 47.8699575, lng: 18.714416 },
    { lat: 47.8705878, lng: 18.715126 },
    { lat: 47.8710126, lng: 18.7166065 },
    { lat: 47.8710929, lng: 18.7184747 },
    { lat: 47.8708387, lng: 18.7187585 },
    { lat: 47.8719959, lng: 18.7215644 },
    { lat: 47.8715884, lng: 18.72202 },
    { lat: 47.8704662, lng: 18.7225014 },
    { lat: 47.8683541, lng: 18.7236175 },
    { lat: 47.8681796, lng: 18.7237907 },
    { lat: 47.8673968, lng: 18.7245679 },
    { lat: 47.8666722, lng: 18.7251211 },
    { lat: 47.8659846, lng: 18.7263664 },
    { lat: 47.8672337, lng: 18.7275105 },
    { lat: 47.8670237, lng: 18.7291211 },
    { lat: 47.8666984, lng: 18.7325314 },
    { lat: 47.8667098, lng: 18.733099 },
    { lat: 47.8666328, lng: 18.7343539 },
    { lat: 47.86598, lng: 18.7353427 },
    { lat: 47.8653602, lng: 18.735583 },
    { lat: 47.8643732, lng: 18.7370283 },
    { lat: 47.8640703, lng: 18.737774 },
    { lat: 47.8643906, lng: 18.7392008 },
    { lat: 47.8651717, lng: 18.7418406 },
    { lat: 47.8659196, lng: 18.7464089 },
    { lat: 47.8656146, lng: 18.7471541 },
    { lat: 47.865756, lng: 18.7488491 },
    { lat: 47.8654767, lng: 18.7495909 },
    { lat: 47.8657833, lng: 18.7513351 },
    { lat: 47.8657861, lng: 18.7513878 },
    { lat: 47.8664792, lng: 18.7513375 },
    { lat: 47.8670706, lng: 18.7510937 },
    { lat: 47.8690274, lng: 18.7507986 },
    { lat: 47.8692665, lng: 18.7525489 },
    { lat: 47.8691378, lng: 18.7546293 },
    { lat: 47.8700216, lng: 18.7559308 },
    { lat: 47.8709846, lng: 18.7564952 },
    { lat: 47.8746416, lng: 18.7572923 },
    { lat: 47.8756855, lng: 18.7588227 },
    { lat: 47.8764574, lng: 18.7601576 },
    { lat: 47.8774151, lng: 18.7620615 },
    { lat: 47.877967, lng: 18.763566 },
    { lat: 47.878479, lng: 18.763703 },
    { lat: 47.880451, lng: 18.764457 },
    { lat: 47.881289, lng: 18.764616 },
    { lat: 47.881657, lng: 18.764535 },
    { lat: 47.882108, lng: 18.764382 },
    { lat: 47.883857, lng: 18.763568 },
    { lat: 47.889738, lng: 18.761955 },
    { lat: 47.890183, lng: 18.761724 },
    { lat: 47.89041, lng: 18.761318 },
    { lat: 47.890507, lng: 18.761282 },
    { lat: 47.890755, lng: 18.761279 },
    { lat: 47.891268, lng: 18.761485 },
    { lat: 47.89167, lng: 18.761514 },
    { lat: 47.892004, lng: 18.761259 },
    { lat: 47.892148, lng: 18.761273 },
    { lat: 47.892219, lng: 18.761083 },
    { lat: 47.892979, lng: 18.761135 },
    { lat: 47.893005, lng: 18.761027 },
    { lat: 47.894124, lng: 18.760543 },
    { lat: 47.894419, lng: 18.760541 },
    { lat: 47.894918, lng: 18.760817 },
    { lat: 47.895233, lng: 18.760847 },
    { lat: 47.895683, lng: 18.760414 },
    { lat: 47.896021, lng: 18.758965 },
    { lat: 47.89614, lng: 18.757987 },
    { lat: 47.896294, lng: 18.757365 },
    { lat: 47.896367, lng: 18.757231 },
    { lat: 47.896838, lng: 18.756858 },
    { lat: 47.89757, lng: 18.756801 },
    { lat: 47.898043, lng: 18.756854 },
    { lat: 47.89855, lng: 18.757096 },
    { lat: 47.898851, lng: 18.757309 },
    { lat: 47.899251, lng: 18.757775 },
    { lat: 47.899997, lng: 18.759033 },
    { lat: 47.900527, lng: 18.759665 },
    { lat: 47.901035, lng: 18.759868 },
    { lat: 47.901874, lng: 18.759982 },
    { lat: 47.902182, lng: 18.760132 },
    { lat: 47.902557, lng: 18.760557 },
    { lat: 47.902805, lng: 18.760932 },
    { lat: 47.903021, lng: 18.761533 },
    { lat: 47.90309, lng: 18.761866 },
    { lat: 47.903264, lng: 18.76229 },
    { lat: 47.903692, lng: 18.762862 },
    { lat: 47.903926, lng: 18.762917 },
    { lat: 47.904095, lng: 18.762726 },
    { lat: 47.904187, lng: 18.762489 },
    { lat: 47.904174, lng: 18.761619 },
    { lat: 47.904262, lng: 18.761482 },
    { lat: 47.904182, lng: 18.760548 },
    { lat: 47.904246, lng: 18.760083 },
    { lat: 47.904241, lng: 18.759777 },
    { lat: 47.90482, lng: 18.758888 },
    { lat: 47.905131, lng: 18.758717 },
    { lat: 47.90537, lng: 18.758687 },
    { lat: 47.905772, lng: 18.758808 },
    { lat: 47.906425, lng: 18.759194 },
    { lat: 47.906921, lng: 18.759244 },
    { lat: 47.907529, lng: 18.759023 },
    { lat: 47.907802, lng: 18.75904 },
    { lat: 47.908021, lng: 18.759186 },
    { lat: 47.908242, lng: 18.759412 },
    { lat: 47.908657, lng: 18.760701 },
    { lat: 47.908905, lng: 18.761179 },
    { lat: 47.908863, lng: 18.762108 },
    { lat: 47.909027, lng: 18.762453 },
    { lat: 47.909234, lng: 18.762609 },
    { lat: 47.909556, lng: 18.762674 },
    { lat: 47.910013, lng: 18.762528 },
    { lat: 47.910091, lng: 18.762437 },
    { lat: 47.910445, lng: 18.762044 },
    { lat: 47.911135, lng: 18.761611 },
    { lat: 47.911504, lng: 18.761594 },
    { lat: 47.911747, lng: 18.761812 },
    { lat: 47.912002, lng: 18.761911 },
    { lat: 47.912653, lng: 18.761734 },
    { lat: 47.913057, lng: 18.761495 },
    { lat: 47.913451, lng: 18.761066 },
    { lat: 47.914138, lng: 18.76052 },
    { lat: 47.914662, lng: 18.760301 },
    { lat: 47.914933, lng: 18.759977 },
    { lat: 47.9144542, lng: 18.7586429 },
    { lat: 47.9133791, lng: 18.735894 },
    { lat: 47.9130137, lng: 18.727385 },
    { lat: 47.9128148, lng: 18.7239338 },
    { lat: 47.9125294, lng: 18.7225521 },
    { lat: 47.9117142, lng: 18.7191134 },
    { lat: 47.9115792, lng: 18.7168386 },
    { lat: 47.9117501, lng: 18.714353 },
    { lat: 47.9115579, lng: 18.7132552 },
    { lat: 47.9113063, lng: 18.7121473 },
    { lat: 47.9112083, lng: 18.7113723 },
    { lat: 47.9106438, lng: 18.7114881 },
    { lat: 47.9098775, lng: 18.7116662 },
  ],
  Vlkas: [
    { lat: 48.1347823, lng: 18.2668228 },
    { lat: 48.1347678, lng: 18.2675724 },
    { lat: 48.134651, lng: 18.267717 },
    { lat: 48.1343545, lng: 18.2676262 },
    { lat: 48.1339762, lng: 18.2680326 },
    { lat: 48.1338543, lng: 18.2680102 },
    { lat: 48.1335602, lng: 18.2678024 },
    { lat: 48.1334581, lng: 18.2678519 },
    { lat: 48.1334897, lng: 18.2686564 },
    { lat: 48.1334592, lng: 18.2687123 },
    { lat: 48.1333466, lng: 18.2686801 },
    { lat: 48.1330067, lng: 18.2679096 },
    { lat: 48.1326514, lng: 18.2677751 },
    { lat: 48.1323902, lng: 18.2673332 },
    { lat: 48.1318163, lng: 18.2678121 },
    { lat: 48.1317032, lng: 18.2678075 },
    { lat: 48.1312948, lng: 18.2675351 },
    { lat: 48.1313828, lng: 18.2672596 },
    { lat: 48.1313773, lng: 18.2671246 },
    { lat: 48.1312759, lng: 18.2669893 },
    { lat: 48.1312014, lng: 18.2669713 },
    { lat: 48.1312138, lng: 18.2667352 },
    { lat: 48.1310691, lng: 18.2667683 },
    { lat: 48.1309206, lng: 18.2664444 },
    { lat: 48.1309231, lng: 18.2663512 },
    { lat: 48.1310455, lng: 18.266162 },
    { lat: 48.1308428, lng: 18.2659546 },
    { lat: 48.1306202, lng: 18.2663608 },
    { lat: 48.1304704, lng: 18.2664638 },
    { lat: 48.1303548, lng: 18.2664567 },
    { lat: 48.1302895, lng: 18.2662422 },
    { lat: 48.1302683, lng: 18.2658741 },
    { lat: 48.1300125, lng: 18.2656248 },
    { lat: 48.1299613, lng: 18.2656762 },
    { lat: 48.1298619, lng: 18.2662619 },
    { lat: 48.1297092, lng: 18.2665697 },
    { lat: 48.1295787, lng: 18.2665745 },
    { lat: 48.1292656, lng: 18.2664303 },
    { lat: 48.1292368, lng: 18.2659501 },
    { lat: 48.1293099, lng: 18.2654954 },
    { lat: 48.1294995, lng: 18.2650846 },
    { lat: 48.1293691, lng: 18.2649749 },
    { lat: 48.1291561, lng: 18.265234 },
    { lat: 48.1289703, lng: 18.2653374 },
    { lat: 48.1288266, lng: 18.2652742 },
    { lat: 48.1286743, lng: 18.265018 },
    { lat: 48.1284872, lng: 18.2649018 },
    { lat: 48.1284241, lng: 18.2650628 },
    { lat: 48.1283546, lng: 18.2656019 },
    { lat: 48.1284113, lng: 18.2659001 },
    { lat: 48.1283799, lng: 18.2661355 },
    { lat: 48.1282989, lng: 18.2662596 },
    { lat: 48.1281716, lng: 18.2662619 },
    { lat: 48.1278497, lng: 18.2660595 },
    { lat: 48.1277936, lng: 18.2659265 },
    { lat: 48.1277802, lng: 18.2656372 },
    { lat: 48.1275998, lng: 18.2653461 },
    { lat: 48.1275067, lng: 18.2653185 },
    { lat: 48.1273092, lng: 18.2657263 },
    { lat: 48.1271876, lng: 18.2657501 },
    { lat: 48.1270351, lng: 18.2655542 },
    { lat: 48.1267067, lng: 18.2638874 },
    { lat: 48.1264619, lng: 18.2635336 },
    { lat: 48.1260858, lng: 18.2631376 },
    { lat: 48.1259032, lng: 18.2623569 },
    { lat: 48.1260398, lng: 18.2615172 },
    { lat: 48.1267941, lng: 18.2597458 },
    { lat: 48.1263638, lng: 18.2594635 },
    { lat: 48.1258664, lng: 18.2594862 },
    { lat: 48.1257372, lng: 18.259673 },
    { lat: 48.1254796, lng: 18.2596306 },
    { lat: 48.1253721, lng: 18.2594261 },
    { lat: 48.1254349, lng: 18.2591662 },
    { lat: 48.1253943, lng: 18.2591068 },
    { lat: 48.1249303, lng: 18.2590839 },
    { lat: 48.1245205, lng: 18.2591786 },
    { lat: 48.1240911, lng: 18.2596785 },
    { lat: 48.1239671, lng: 18.2595408 },
    { lat: 48.123453, lng: 18.2595472 },
    { lat: 48.1229068, lng: 18.2599586 },
    { lat: 48.1227524, lng: 18.2603359 },
    { lat: 48.1225454, lng: 18.2616172 },
    { lat: 48.1223635, lng: 18.2624172 },
    { lat: 48.1222051, lng: 18.2627035 },
    { lat: 48.1221042, lng: 18.2631847 },
    { lat: 48.121932, lng: 18.2635621 },
    { lat: 48.1216955, lng: 18.2638439 },
    { lat: 48.1214804, lng: 18.2645959 },
    { lat: 48.1211757, lng: 18.2650284 },
    { lat: 48.1210143, lng: 18.2659447 },
    { lat: 48.1212135, lng: 18.2665265 },
    { lat: 48.1202553, lng: 18.2663632 },
    { lat: 48.1199357, lng: 18.2662835 },
    { lat: 48.1199531, lng: 18.2664448 },
    { lat: 48.11994, lng: 18.2668264 },
    { lat: 48.1197902, lng: 18.2670077 },
    { lat: 48.1197324, lng: 18.2672564 },
    { lat: 48.1197782, lng: 18.2675429 },
    { lat: 48.1198861, lng: 18.2678122 },
    { lat: 48.1198989, lng: 18.268014 },
    { lat: 48.1197091, lng: 18.2684995 },
    { lat: 48.1198136, lng: 18.2687075 },
    { lat: 48.1199113, lng: 18.2687377 },
    { lat: 48.1201124, lng: 18.268666 },
    { lat: 48.1204052, lng: 18.2691842 },
    { lat: 48.1205275, lng: 18.2691734 },
    { lat: 48.1189421, lng: 18.2762893 },
    { lat: 48.1185742, lng: 18.2782885 },
    { lat: 48.1159642, lng: 18.2900136 },
    { lat: 48.1161891, lng: 18.2916119 },
    { lat: 48.1173003, lng: 18.296113 },
    { lat: 48.1175631, lng: 18.2968608 },
    { lat: 48.1178692, lng: 18.2979855 },
    { lat: 48.1192503, lng: 18.3042726 },
    { lat: 48.118036, lng: 18.3072832 },
    { lat: 48.1172747, lng: 18.3094746 },
    { lat: 48.1172339, lng: 18.310062 },
    { lat: 48.117334, lng: 18.3103158 },
    { lat: 48.1184424, lng: 18.3119985 },
    { lat: 48.1186044, lng: 18.3121477 },
    { lat: 48.1224423, lng: 18.3141922 },
    { lat: 48.1226002, lng: 18.3143479 },
    { lat: 48.1230242, lng: 18.3150156 },
    { lat: 48.126812, lng: 18.3134759 },
    { lat: 48.1292491, lng: 18.3133003 },
    { lat: 48.130783, lng: 18.3132873 },
    { lat: 48.1307856, lng: 18.3132159 },
    { lat: 48.1308217, lng: 18.312761 },
    { lat: 48.1312625, lng: 18.3103257 },
    { lat: 48.1315951, lng: 18.3091389 },
    { lat: 48.132063, lng: 18.3080107 },
    { lat: 48.1330241, lng: 18.3034705 },
    { lat: 48.1334935, lng: 18.3010267 },
    { lat: 48.1336682, lng: 18.3003958 },
    { lat: 48.1339101, lng: 18.2991816 },
    { lat: 48.1342641, lng: 18.2964385 },
    { lat: 48.1354082, lng: 18.2923639 },
    { lat: 48.1357028, lng: 18.291128 },
    { lat: 48.1358691, lng: 18.2891495 },
    { lat: 48.1359815, lng: 18.2856618 },
    { lat: 48.1359506, lng: 18.2849056 },
    { lat: 48.1360767, lng: 18.2832935 },
    { lat: 48.136438, lng: 18.2815071 },
    { lat: 48.1366022, lng: 18.2793967 },
    { lat: 48.136745, lng: 18.2783066 },
    { lat: 48.1370831, lng: 18.2765126 },
    { lat: 48.1371184, lng: 18.2758154 },
    { lat: 48.1369577, lng: 18.2755283 },
    { lat: 48.1365475, lng: 18.2751267 },
    { lat: 48.1361832, lng: 18.2748764 },
    { lat: 48.1359403, lng: 18.2742219 },
    { lat: 48.1359991, lng: 18.2733398 },
    { lat: 48.1357325, lng: 18.271374 },
    { lat: 48.135126, lng: 18.2679139 },
    { lat: 48.1348242, lng: 18.2669053 },
    { lat: 48.1347823, lng: 18.2668228 },
  ],
  NovéZámky: [
    { lat: 48.0443555, lng: 18.1186217 },
    { lat: 48.0443271, lng: 18.1185979 },
    { lat: 48.0347032, lng: 18.127618 },
    { lat: 48.0313448, lng: 18.1213382 },
    { lat: 48.0281875, lng: 18.114882 },
    { lat: 48.0269636, lng: 18.1121771 },
    { lat: 48.0269458, lng: 18.1121995 },
    { lat: 48.0256479, lng: 18.1138611 },
    { lat: 48.024647, lng: 18.1152404 },
    { lat: 48.0228524, lng: 18.1173378 },
    { lat: 48.0162127, lng: 18.1237384 },
    { lat: 48.0148218, lng: 18.125199 },
    { lat: 48.013035, lng: 18.1272618 },
    { lat: 48.0130286, lng: 18.127269 },
    { lat: 48.0084693, lng: 18.1323986 },
    { lat: 48.0048156, lng: 18.1366608 },
    { lat: 48.0036379, lng: 18.1347699 },
    { lat: 48.0029124, lng: 18.1334317 },
    { lat: 47.9982767, lng: 18.1257019 },
    { lat: 47.9976511, lng: 18.1248135 },
    { lat: 47.9944949, lng: 18.1195023 },
    { lat: 47.9921652, lng: 18.1159044 },
    { lat: 47.9909556, lng: 18.1142832 },
    { lat: 47.9906723, lng: 18.1137461 },
    { lat: 47.990441, lng: 18.1135943 },
    { lat: 47.9900866, lng: 18.1131761 },
    { lat: 47.9894228, lng: 18.1122363 },
    { lat: 47.9891085, lng: 18.1119002 },
    { lat: 47.9876488, lng: 18.1099647 },
    { lat: 47.983637, lng: 18.1048554 },
    { lat: 47.9837577, lng: 18.1044126 },
    { lat: 47.9837385, lng: 18.1035937 },
    { lat: 47.983578, lng: 18.1032481 },
    { lat: 47.9830578, lng: 18.1028092 },
    { lat: 47.9827393, lng: 18.1028809 },
    { lat: 47.9826907, lng: 18.1027676 },
    { lat: 47.9826194, lng: 18.1023821 },
    { lat: 47.9823046, lng: 18.0991638 },
    { lat: 47.9820301, lng: 18.0978994 },
    { lat: 47.9819573, lng: 18.097891 },
    { lat: 47.981867, lng: 18.0977556 },
    { lat: 47.9816323, lng: 18.0968604 },
    { lat: 47.980778, lng: 18.0943891 },
    { lat: 47.9804088, lng: 18.093072 },
    { lat: 47.9804815, lng: 18.0928895 },
    { lat: 47.9803279, lng: 18.0921264 },
    { lat: 47.9799347, lng: 18.0915846 },
    { lat: 47.9788807, lng: 18.0909198 },
    { lat: 47.978031, lng: 18.0905801 },
    { lat: 47.9775997, lng: 18.0902156 },
    { lat: 47.9780627, lng: 18.0871179 },
    { lat: 47.9787215, lng: 18.0848534 },
    { lat: 47.9788072, lng: 18.084412 },
    { lat: 47.9788367, lng: 18.0839023 },
    { lat: 47.9785478, lng: 18.0831824 },
    { lat: 47.9783463, lng: 18.0828078 },
    { lat: 47.9787991, lng: 18.0823959 },
    { lat: 47.9792296, lng: 18.0817957 },
    { lat: 47.9793019, lng: 18.0814371 },
    { lat: 47.9793145, lng: 18.0810804 },
    { lat: 47.9791893, lng: 18.0805094 },
    { lat: 47.9788249, lng: 18.0798086 },
    { lat: 47.9779153, lng: 18.079012 },
    { lat: 47.9777284, lng: 18.0787319 },
    { lat: 47.9777059, lng: 18.0781661 },
    { lat: 47.9782038, lng: 18.0772361 },
    { lat: 47.9785197, lng: 18.0763248 },
    { lat: 47.978957, lng: 18.07455 },
    { lat: 47.9789634, lng: 18.0739177 },
    { lat: 47.9780922, lng: 18.0746028 },
    { lat: 47.9779211, lng: 18.0743425 },
    { lat: 47.9775027, lng: 18.0727705 },
    { lat: 47.9773666, lng: 18.0726852 },
    { lat: 47.9770282, lng: 18.0727658 },
    { lat: 47.9765468, lng: 18.0721467 },
    { lat: 47.9760729, lng: 18.0713322 },
    { lat: 47.9755557, lng: 18.0706654 },
    { lat: 47.9748562, lng: 18.0702322 },
    { lat: 47.9739358, lng: 18.0695373 },
    { lat: 47.9732353, lng: 18.0696403 },
    { lat: 47.9729677, lng: 18.0695794 },
    { lat: 47.9729634, lng: 18.0695258 },
    { lat: 47.9727081, lng: 18.0696553 },
    { lat: 47.9686771, lng: 18.0709679 },
    { lat: 47.9681548, lng: 18.0716728 },
    { lat: 47.9679057, lng: 18.0723828 },
    { lat: 47.9673123, lng: 18.0727636 },
    { lat: 47.9669891, lng: 18.0723953 },
    { lat: 47.9666254, lng: 18.0715974 },
    { lat: 47.9630018, lng: 18.0715609 },
    { lat: 47.9607863, lng: 18.0708404 },
    { lat: 47.9551316, lng: 18.0706786 },
    { lat: 47.9548443, lng: 18.0707236 },
    { lat: 47.950206, lng: 18.072375 },
    { lat: 47.950225, lng: 18.072426 },
    { lat: 47.95026, lng: 18.072524 },
    { lat: 47.950285, lng: 18.072598 },
    { lat: 47.950298, lng: 18.072632 },
    { lat: 47.950313, lng: 18.072672 },
    { lat: 47.950361, lng: 18.072809 },
    { lat: 47.950609, lng: 18.073421 },
    { lat: 47.95104, lng: 18.073895 },
    { lat: 47.95122, lng: 18.074043 },
    { lat: 47.951402, lng: 18.074192 },
    { lat: 47.951845, lng: 18.074785 },
    { lat: 47.952673, lng: 18.07493 },
    { lat: 47.953036, lng: 18.075086 },
    { lat: 47.953294, lng: 18.075545 },
    { lat: 47.953596, lng: 18.076665 },
    { lat: 47.953571, lng: 18.07706 },
    { lat: 47.953263, lng: 18.077669 },
    { lat: 47.953304, lng: 18.078342 },
    { lat: 47.953267, lng: 18.078899 },
    { lat: 47.95319, lng: 18.079549 },
    { lat: 47.953069, lng: 18.07981 },
    { lat: 47.952458, lng: 18.080871 },
    { lat: 47.951098, lng: 18.082182 },
    { lat: 47.950206, lng: 18.082998 },
    { lat: 47.950153, lng: 18.083047 },
    { lat: 47.949981, lng: 18.083205 },
    { lat: 47.94941, lng: 18.083747 },
    { lat: 47.949126, lng: 18.084017 },
    { lat: 47.948476, lng: 18.084791 },
    { lat: 47.948527, lng: 18.084814 },
    { lat: 47.948989, lng: 18.085022 },
    { lat: 47.950012, lng: 18.085551 },
    { lat: 47.950921, lng: 18.085989 },
    { lat: 47.951822, lng: 18.08642 },
    { lat: 47.953459, lng: 18.087218 },
    { lat: 47.953968, lng: 18.088181 },
    { lat: 47.954028, lng: 18.088282 },
    { lat: 47.954337, lng: 18.088793 },
    { lat: 47.954899, lng: 18.089725 },
    { lat: 47.95555, lng: 18.090862 },
    { lat: 47.9551, lng: 18.091881 },
    { lat: 47.955102, lng: 18.091888 },
    { lat: 47.955425, lng: 18.093224 },
    { lat: 47.955942, lng: 18.095302 },
    { lat: 47.956472, lng: 18.095062 },
    { lat: 47.956552, lng: 18.095273 },
    { lat: 47.956617, lng: 18.095439 },
    { lat: 47.956674, lng: 18.095598 },
    { lat: 47.957149, lng: 18.094851 },
    { lat: 47.957226, lng: 18.094641 },
    { lat: 47.957309, lng: 18.094275 },
    { lat: 47.957366, lng: 18.093922 },
    { lat: 47.957368, lng: 18.093631 },
    { lat: 47.957413, lng: 18.093413 },
    { lat: 47.95748, lng: 18.093212 },
    { lat: 47.957598, lng: 18.093075 },
    { lat: 47.957748, lng: 18.09302 },
    { lat: 47.95786, lng: 18.09297 },
    { lat: 47.957971, lng: 18.092992 },
    { lat: 47.958112, lng: 18.093057 },
    { lat: 47.958246, lng: 18.093177 },
    { lat: 47.958397, lng: 18.093393 },
    { lat: 47.958481, lng: 18.09351 },
    { lat: 47.95865, lng: 18.093671 },
    { lat: 47.958764, lng: 18.093838 },
    { lat: 47.95902, lng: 18.093821 },
    { lat: 47.959056, lng: 18.094123 },
    { lat: 47.95922, lng: 18.094181 },
    { lat: 47.959395, lng: 18.094437 },
    { lat: 47.959482, lng: 18.094521 },
    { lat: 47.959649, lng: 18.094611 },
    { lat: 47.959754, lng: 18.094496 },
    { lat: 47.959904, lng: 18.094381 },
    { lat: 47.959985, lng: 18.094267 },
    { lat: 47.960035, lng: 18.09398 },
    { lat: 47.959817, lng: 18.093656 },
    { lat: 47.959397, lng: 18.093563 },
    { lat: 47.959268, lng: 18.093448 },
    { lat: 47.959235, lng: 18.093419 },
    { lat: 47.959153, lng: 18.093345 },
    { lat: 47.959243, lng: 18.093041 },
    { lat: 47.95971, lng: 18.0926 },
    { lat: 47.959836, lng: 18.092316 },
    { lat: 47.95996, lng: 18.09201 },
    { lat: 47.960073, lng: 18.091804 },
    { lat: 47.960334, lng: 18.091703 },
    { lat: 47.960459, lng: 18.09175 },
    { lat: 47.960569, lng: 18.091836 },
    { lat: 47.960686, lng: 18.091978 },
    { lat: 47.960754, lng: 18.092136 },
    { lat: 47.960754, lng: 18.092331 },
    { lat: 47.960674, lng: 18.092488 },
    { lat: 47.960467, lng: 18.092816 },
    { lat: 47.960425, lng: 18.093002 },
    { lat: 47.960413, lng: 18.093164 },
    { lat: 47.96044, lng: 18.093394 },
    { lat: 47.960473, lng: 18.093521 },
    { lat: 47.960567, lng: 18.093725 },
    { lat: 47.960669, lng: 18.093831 },
    { lat: 47.960789, lng: 18.093904 },
    { lat: 47.960918, lng: 18.093927 },
    { lat: 47.961033, lng: 18.093928 },
    { lat: 47.96122, lng: 18.093834 },
    { lat: 47.961548, lng: 18.09358 },
    { lat: 47.961744, lng: 18.093405 },
    { lat: 47.961804, lng: 18.093274 },
    { lat: 47.961841, lng: 18.093022 },
    { lat: 47.961911, lng: 18.092664 },
    { lat: 47.961993, lng: 18.092517 },
    { lat: 47.962031, lng: 18.092479 },
    { lat: 47.962096, lng: 18.092443 },
    { lat: 47.96215, lng: 18.092461 },
    { lat: 47.962314, lng: 18.092926 },
    { lat: 47.962502, lng: 18.093318 },
    { lat: 47.962671, lng: 18.093488 },
    { lat: 47.962827, lng: 18.093602 },
    { lat: 47.963497, lng: 18.093651 },
    { lat: 47.963581, lng: 18.093729 },
    { lat: 47.963617, lng: 18.093801 },
    { lat: 47.963647, lng: 18.093985 },
    { lat: 47.96364, lng: 18.094128 },
    { lat: 47.963613, lng: 18.094333 },
    { lat: 47.963528, lng: 18.094603 },
    { lat: 47.96323, lng: 18.095261 },
    { lat: 47.963155, lng: 18.095711 },
    { lat: 47.963368, lng: 18.096005 },
    { lat: 47.963723, lng: 18.096104 },
    { lat: 47.964044, lng: 18.096321 },
    { lat: 47.964161, lng: 18.096442 },
    { lat: 47.964593, lng: 18.096998 },
    { lat: 47.965033, lng: 18.097709 },
    { lat: 47.965083, lng: 18.097926 },
    { lat: 47.965077, lng: 18.098103 },
    { lat: 47.965038, lng: 18.098254 },
    { lat: 47.964978, lng: 18.098381 },
    { lat: 47.96456, lng: 18.098617 },
    { lat: 47.964212, lng: 18.098918 },
    { lat: 47.964189, lng: 18.098981 },
    { lat: 47.964099, lng: 18.099581 },
    { lat: 47.964144, lng: 18.099728 },
    { lat: 47.964004, lng: 18.100464 },
    { lat: 47.964013, lng: 18.100868 },
    { lat: 47.964189, lng: 18.101695 },
    { lat: 47.96412, lng: 18.102466 },
    { lat: 47.964206, lng: 18.102783 },
    { lat: 47.964396, lng: 18.103065 },
    { lat: 47.964552, lng: 18.10313 },
    { lat: 47.96502, lng: 18.103539 },
    { lat: 47.965268, lng: 18.103619 },
    { lat: 47.965472, lng: 18.10362 },
    { lat: 47.965779, lng: 18.103486 },
    { lat: 47.966372, lng: 18.103054 },
    { lat: 47.966566, lng: 18.10279 },
    { lat: 47.966783, lng: 18.102771 },
    { lat: 47.966873, lng: 18.102809 },
    { lat: 47.966941, lng: 18.103201 },
    { lat: 47.966926, lng: 18.103522 },
    { lat: 47.966844, lng: 18.103819 },
    { lat: 47.966323, lng: 18.104734 },
    { lat: 47.966266, lng: 18.105009 },
    { lat: 47.966275, lng: 18.105068 },
    { lat: 47.966289, lng: 18.105154 },
    { lat: 47.966299, lng: 18.105222 },
    { lat: 47.966309, lng: 18.105281 },
    { lat: 47.966321, lng: 18.105354 },
    { lat: 47.966377, lng: 18.105504 },
    { lat: 47.966584, lng: 18.105737 },
    { lat: 47.96683, lng: 18.105844 },
    { lat: 47.96708, lng: 18.10581 },
    { lat: 47.967451, lng: 18.105444 },
    { lat: 47.967523, lng: 18.105413 },
    { lat: 47.967617, lng: 18.105577 },
    { lat: 47.967639, lng: 18.105732 },
    { lat: 47.967574, lng: 18.106123 },
    { lat: 47.967585, lng: 18.106202 },
    { lat: 47.967605, lng: 18.10623 },
    { lat: 47.967824, lng: 18.106537 },
    { lat: 47.967828, lng: 18.106541 },
    { lat: 47.967665, lng: 18.106596 },
    { lat: 47.967664, lng: 18.106596 },
    { lat: 47.967604, lng: 18.10662 },
    { lat: 47.967602, lng: 18.106621 },
    { lat: 47.967487, lng: 18.106743 },
    { lat: 47.967449, lng: 18.1068 },
    { lat: 47.967419, lng: 18.106862 },
    { lat: 47.967417, lng: 18.106865 },
    { lat: 47.967229, lng: 18.107259 },
    { lat: 47.967228, lng: 18.107261 },
    { lat: 47.96719, lng: 18.10734 },
    { lat: 47.96719, lng: 18.107341 },
    { lat: 47.967055, lng: 18.10762 },
    { lat: 47.966987, lng: 18.107763 },
    { lat: 47.966929, lng: 18.107884 },
    { lat: 47.966827, lng: 18.108095 },
    { lat: 47.966792, lng: 18.108167 },
    { lat: 47.966767, lng: 18.108218 },
    { lat: 47.966714, lng: 18.108329 },
    { lat: 47.968821, lng: 18.108946 },
    { lat: 47.969181, lng: 18.109014 },
    { lat: 47.969329, lng: 18.109082 },
    { lat: 47.969246, lng: 18.109251 },
    { lat: 47.969168, lng: 18.109413 },
    { lat: 47.970198, lng: 18.109835 },
    { lat: 47.970248, lng: 18.109856 },
    { lat: 47.970366, lng: 18.109903 },
    { lat: 47.970423, lng: 18.109926 },
    { lat: 47.970534, lng: 18.109972 },
    { lat: 47.970554, lng: 18.10998 },
    { lat: 47.970649, lng: 18.11002 },
    { lat: 47.971399, lng: 18.110326 },
    { lat: 47.97143, lng: 18.110339 },
    { lat: 47.971514, lng: 18.110445 },
    { lat: 47.971983, lng: 18.111027 },
    { lat: 47.972017, lng: 18.11107 },
    { lat: 47.97239, lng: 18.111534 },
    { lat: 47.972448, lng: 18.111541 },
    { lat: 47.972479, lng: 18.111546 },
    { lat: 47.972531, lng: 18.111553 },
    { lat: 47.972576, lng: 18.111558 },
    { lat: 47.972655, lng: 18.111569 },
    { lat: 47.972742, lng: 18.111569 },
    { lat: 47.972911, lng: 18.111578 },
    { lat: 47.972919, lng: 18.111571 },
    { lat: 47.972924, lng: 18.111568 },
    { lat: 47.973166, lng: 18.111566 },
    { lat: 47.973281, lng: 18.111669 },
    { lat: 47.973417, lng: 18.111793 },
    { lat: 47.973452, lng: 18.111833 },
    { lat: 47.973609, lng: 18.112017 },
    { lat: 47.973716, lng: 18.112142 },
    { lat: 47.973768, lng: 18.112203 },
    { lat: 47.973798, lng: 18.112237 },
    { lat: 47.973839, lng: 18.112282 },
    { lat: 47.973862, lng: 18.112305 },
    { lat: 47.973907, lng: 18.112377 },
    { lat: 47.973935, lng: 18.112421 },
    { lat: 47.973991, lng: 18.112524 },
    { lat: 47.974019, lng: 18.112573 },
    { lat: 47.974053, lng: 18.112671 },
    { lat: 47.974111, lng: 18.112835 },
    { lat: 47.974138, lng: 18.112912 },
    { lat: 47.974169, lng: 18.113015 },
    { lat: 47.974188, lng: 18.113077 },
    { lat: 47.974205, lng: 18.113134 },
    { lat: 47.97421, lng: 18.113152 },
    { lat: 47.974222, lng: 18.11319 },
    { lat: 47.974226, lng: 18.113206 },
    { lat: 47.974237, lng: 18.113243 },
    { lat: 47.974246, lng: 18.113273 },
    { lat: 47.974277, lng: 18.113366 },
    { lat: 47.974308, lng: 18.113475 },
    { lat: 47.974357, lng: 18.113646 },
    { lat: 47.974483, lng: 18.113714 },
    { lat: 47.974607, lng: 18.113742 },
    { lat: 47.974636, lng: 18.113725 },
    { lat: 47.974715, lng: 18.113799 },
    { lat: 47.974873, lng: 18.114137 },
    { lat: 47.9749, lng: 18.114249 },
    { lat: 47.974884, lng: 18.114328 },
    { lat: 47.974865, lng: 18.114436 },
    { lat: 47.974804, lng: 18.114452 },
    { lat: 47.974703, lng: 18.114479 },
    { lat: 47.974295, lng: 18.114535 },
    { lat: 47.974052, lng: 18.114586 },
    { lat: 47.973703, lng: 18.114957 },
    { lat: 47.97369, lng: 18.114992 },
    { lat: 47.973674, lng: 18.115037 },
    { lat: 47.973604, lng: 18.115228 },
    { lat: 47.973554, lng: 18.115368 },
    { lat: 47.973549, lng: 18.115419 },
    { lat: 47.973533, lng: 18.115594 },
    { lat: 47.973517, lng: 18.115766 },
    { lat: 47.9735, lng: 18.115956 },
    { lat: 47.973583, lng: 18.116098 },
    { lat: 47.973605, lng: 18.116135 },
    { lat: 47.973641, lng: 18.116197 },
    { lat: 47.973648, lng: 18.116209 },
    { lat: 47.973661, lng: 18.116231 },
    { lat: 47.973687, lng: 18.116277 },
    { lat: 47.9737, lng: 18.116297 },
    { lat: 47.973707, lng: 18.11631 },
    { lat: 47.973749, lng: 18.116343 },
    { lat: 47.973784, lng: 18.116372 },
    { lat: 47.97381, lng: 18.116392 },
    { lat: 47.973894, lng: 18.116457 },
    { lat: 47.973897, lng: 18.11646 },
    { lat: 47.973907, lng: 18.116469 },
    { lat: 47.97395, lng: 18.116503 },
    { lat: 47.97398, lng: 18.116527 },
    { lat: 47.973984, lng: 18.116527 },
    { lat: 47.974131, lng: 18.116535 },
    { lat: 47.974227, lng: 18.116518 },
    { lat: 47.974264, lng: 18.116511 },
    { lat: 47.974274, lng: 18.11651 },
    { lat: 47.974308, lng: 18.116503 },
    { lat: 47.974319, lng: 18.1165 },
    { lat: 47.974471, lng: 18.116455 },
    { lat: 47.974586, lng: 18.11644 },
    { lat: 47.974608, lng: 18.116437 },
    { lat: 47.97467, lng: 18.116439 },
    { lat: 47.974732, lng: 18.11649 },
    { lat: 47.974745, lng: 18.116511 },
    { lat: 47.974801, lng: 18.116604 },
    { lat: 47.974818, lng: 18.116654 },
    { lat: 47.974859, lng: 18.116778 },
    { lat: 47.974816, lng: 18.117058 },
    { lat: 47.974804, lng: 18.117137 },
    { lat: 47.974745, lng: 18.117319 },
    { lat: 47.974732, lng: 18.117364 },
    { lat: 47.974683, lng: 18.117519 },
    { lat: 47.974641, lng: 18.117655 },
    { lat: 47.974639, lng: 18.117686 },
    { lat: 47.974632, lng: 18.117877 },
    { lat: 47.974632, lng: 18.117882 },
    { lat: 47.974632, lng: 18.117946 },
    { lat: 47.974633, lng: 18.117959 },
    { lat: 47.974635, lng: 18.118028 },
    { lat: 47.97464, lng: 18.118301 },
    { lat: 47.974641, lng: 18.118362 },
    { lat: 47.974642, lng: 18.118392 },
    { lat: 47.974634, lng: 18.118494 },
    { lat: 47.974597, lng: 18.118584 },
    { lat: 47.974519, lng: 18.118689 },
    { lat: 47.974505, lng: 18.118678 },
    { lat: 47.974496, lng: 18.11867 },
    { lat: 47.974459, lng: 18.118705 },
    { lat: 47.974409, lng: 18.118706 },
    { lat: 47.9744, lng: 18.118706 },
    { lat: 47.974395, lng: 18.118706 },
    { lat: 47.974339, lng: 18.118707 },
    { lat: 47.974315, lng: 18.118707 },
    { lat: 47.974276, lng: 18.118689 },
    { lat: 47.974259, lng: 18.118681 },
    { lat: 47.974185, lng: 18.118601 },
    { lat: 47.97415, lng: 18.118563 },
    { lat: 47.97415, lng: 18.118475 },
    { lat: 47.974151, lng: 18.11833 },
    { lat: 47.974151, lng: 18.118186 },
    { lat: 47.974152, lng: 18.118041 },
    { lat: 47.974152, lng: 18.118027 },
    { lat: 47.974153, lng: 18.117906 },
    { lat: 47.97415, lng: 18.117895 },
    { lat: 47.97415, lng: 18.117891 },
    { lat: 47.974125, lng: 18.117766 },
    { lat: 47.9741, lng: 18.117638 },
    { lat: 47.974074, lng: 18.11751 },
    { lat: 47.974048, lng: 18.117378 },
    { lat: 47.974043, lng: 18.117355 },
    { lat: 47.973995, lng: 18.117269 },
    { lat: 47.973935, lng: 18.117158 },
    { lat: 47.973875, lng: 18.11705 },
    { lat: 47.973868, lng: 18.117037 },
    { lat: 47.973763, lng: 18.117008 },
    { lat: 47.973674, lng: 18.116983 },
    { lat: 47.973588, lng: 18.116997 },
    { lat: 47.97354, lng: 18.117004 },
    { lat: 47.973484, lng: 18.117012 },
    { lat: 47.973414, lng: 18.117023 },
    { lat: 47.973245, lng: 18.117188 },
    { lat: 47.973212, lng: 18.117282 },
    { lat: 47.973201, lng: 18.117494 },
    { lat: 47.973188, lng: 18.117738 },
    { lat: 47.973221, lng: 18.118121 },
    { lat: 47.973264, lng: 18.118509 },
    { lat: 47.97326, lng: 18.118834 },
    { lat: 47.973201, lng: 18.119424 },
    { lat: 47.973169, lng: 18.119751 },
    { lat: 47.973163, lng: 18.119812 },
    { lat: 47.973105, lng: 18.120068 },
    { lat: 47.973038, lng: 18.120304 },
    { lat: 47.972885, lng: 18.120416 },
    { lat: 47.972777, lng: 18.120428 },
    { lat: 47.972704, lng: 18.120392 },
    { lat: 47.972622, lng: 18.120238 },
    { lat: 47.972731, lng: 18.119636 },
    { lat: 47.972655, lng: 18.11922 },
    { lat: 47.972393, lng: 18.118763 },
    { lat: 47.972293, lng: 18.118493 },
    { lat: 47.972213, lng: 18.118177 },
    { lat: 47.972072, lng: 18.117847 },
    { lat: 47.972012, lng: 18.117795 },
    { lat: 47.971806, lng: 18.117826 },
    { lat: 47.971724, lng: 18.117977 },
    { lat: 47.971733, lng: 18.11832 },
    { lat: 47.971822, lng: 18.118558 },
    { lat: 47.971864, lng: 18.119067 },
    { lat: 47.97174, lng: 18.119511 },
    { lat: 47.971625, lng: 18.119704 },
    { lat: 47.971217, lng: 18.12017 },
    { lat: 47.971151, lng: 18.120436 },
    { lat: 47.971144, lng: 18.120585 },
    { lat: 47.971205, lng: 18.120654 },
    { lat: 47.971677, lng: 18.120896 },
    { lat: 47.971708, lng: 18.120945 },
    { lat: 47.971911, lng: 18.121137 },
    { lat: 47.971997, lng: 18.121318 },
    { lat: 47.972031, lng: 18.121456 },
    { lat: 47.972029, lng: 18.121662 },
    { lat: 47.971881, lng: 18.122041 },
    { lat: 47.971688, lng: 18.122394 },
    { lat: 47.971646, lng: 18.12254 },
    { lat: 47.97163, lng: 18.122655 },
    { lat: 47.971664, lng: 18.122761 },
    { lat: 47.971751, lng: 18.122886 },
    { lat: 47.97202, lng: 18.122876 },
    { lat: 47.972021, lng: 18.123082 },
    { lat: 47.97186, lng: 18.123465 },
    { lat: 47.971236, lng: 18.124734 },
    { lat: 47.97109, lng: 18.125028 },
    { lat: 47.970736, lng: 18.12574 },
    { lat: 47.970496, lng: 18.12654 },
    { lat: 47.970386, lng: 18.12691 },
    { lat: 47.970349, lng: 18.127033 },
    { lat: 47.970153, lng: 18.127688 },
    { lat: 47.970098, lng: 18.12787 },
    { lat: 47.970721, lng: 18.128993 },
    { lat: 47.971501, lng: 18.130453 },
    { lat: 47.971625, lng: 18.130685 },
    { lat: 47.972209, lng: 18.132045 },
    { lat: 47.972262, lng: 18.13217 },
    { lat: 47.972633, lng: 18.133028 },
    { lat: 47.973081, lng: 18.134055 },
    { lat: 47.972669, lng: 18.134635 },
    { lat: 47.972616, lng: 18.134709 },
    { lat: 47.97226, lng: 18.135209 },
    { lat: 47.971954, lng: 18.135639 },
    { lat: 47.97144, lng: 18.136735 },
    { lat: 47.970415, lng: 18.13892 },
    { lat: 47.96898, lng: 18.140664 },
    { lat: 47.968282, lng: 18.141513 },
    { lat: 47.968279, lng: 18.143616 },
    { lat: 47.968044, lng: 18.144108 },
    { lat: 47.967859, lng: 18.14451 },
    { lat: 47.967622, lng: 18.145025 },
    { lat: 47.96757, lng: 18.145136 },
    { lat: 47.967547, lng: 18.145186 },
    { lat: 47.967527, lng: 18.14523 },
    { lat: 47.967503, lng: 18.145282 },
    { lat: 47.967439, lng: 18.145422 },
    { lat: 47.967266, lng: 18.145796 },
    { lat: 47.967193, lng: 18.145955 },
    { lat: 47.967135, lng: 18.146082 },
    { lat: 47.965908, lng: 18.148034 },
    { lat: 47.965836, lng: 18.14815 },
    { lat: 47.965238, lng: 18.149113 },
    { lat: 47.964838, lng: 18.14971 },
    { lat: 47.964765, lng: 18.149817 },
    { lat: 47.964738, lng: 18.149816 },
    { lat: 47.964609, lng: 18.149812 },
    { lat: 47.964573, lng: 18.149811 },
    { lat: 47.964505, lng: 18.149808 },
    { lat: 47.964297, lng: 18.149801 },
    { lat: 47.961808, lng: 18.150516 },
    { lat: 47.959916, lng: 18.151676 },
    { lat: 47.958959, lng: 18.152159 },
    { lat: 47.95888, lng: 18.152199 },
    { lat: 47.958239, lng: 18.152479 },
    { lat: 47.958235, lng: 18.15248 },
    { lat: 47.958163, lng: 18.152501 },
    { lat: 47.957087, lng: 18.152817 },
    { lat: 47.956784, lng: 18.152907 },
    { lat: 47.956419, lng: 18.15301 },
    { lat: 47.956004, lng: 18.153133 },
    { lat: 47.955035, lng: 18.153418 },
    { lat: 47.954613, lng: 18.153542 },
    { lat: 47.954471, lng: 18.153584 },
    { lat: 47.954412, lng: 18.153602 },
    { lat: 47.954342, lng: 18.153514 },
    { lat: 47.95417, lng: 18.153293 },
    { lat: 47.953272, lng: 18.152148 },
    { lat: 47.953236, lng: 18.152214 },
    { lat: 47.953178, lng: 18.152314 },
    { lat: 47.95313, lng: 18.152399 },
    { lat: 47.952557, lng: 18.153414 },
    { lat: 47.95255, lng: 18.153426 },
    { lat: 47.950419, lng: 18.157193 },
    { lat: 47.949953, lng: 18.158018 },
    { lat: 47.949846, lng: 18.1582 },
    { lat: 47.949221, lng: 18.159267 },
    { lat: 47.948602, lng: 18.160205 },
    { lat: 47.945489, lng: 18.165095 },
    { lat: 47.945378, lng: 18.165236 },
    { lat: 47.94509, lng: 18.1656 },
    { lat: 47.944001, lng: 18.166981 },
    { lat: 47.94393, lng: 18.167069 },
    { lat: 47.944167, lng: 18.167601 },
    { lat: 47.944198, lng: 18.16767 },
    { lat: 47.944945, lng: 18.169349 },
    { lat: 47.94579, lng: 18.171312 },
    { lat: 47.946463, lng: 18.173721 },
    { lat: 47.94693, lng: 18.175454 },
    { lat: 47.94698, lng: 18.17564 },
    { lat: 47.947108, lng: 18.176116 },
    { lat: 47.947397, lng: 18.177189 },
    { lat: 47.947724, lng: 18.178455 },
    { lat: 47.948001, lng: 18.17953 },
    { lat: 47.948661, lng: 18.182676 },
    { lat: 47.949283, lng: 18.18565 },
    { lat: 47.950012, lng: 18.187731 },
    { lat: 47.951656, lng: 18.192363 },
    { lat: 47.951889, lng: 18.193021 },
    { lat: 47.951994, lng: 18.19332 },
    { lat: 47.952239, lng: 18.194014 },
    { lat: 47.953119, lng: 18.196482 },
    { lat: 47.953828, lng: 18.198667 },
    { lat: 47.954098, lng: 18.199485 },
    { lat: 47.954148, lng: 18.199639 },
    { lat: 47.954231, lng: 18.199888 },
    { lat: 47.954805, lng: 18.201629 },
    { lat: 47.955483, lng: 18.203722 },
    { lat: 47.955822, lng: 18.204316 },
    { lat: 47.95477, lng: 18.205858 },
    { lat: 47.953522, lng: 18.207685 },
    { lat: 47.952563, lng: 18.209087 },
    { lat: 47.951528, lng: 18.210528 },
    { lat: 47.950744, lng: 18.211643 },
    { lat: 47.951218, lng: 18.212171 },
    { lat: 47.951998, lng: 18.213051 },
    { lat: 47.953037, lng: 18.214232 },
    { lat: 47.953788, lng: 18.215047 },
    { lat: 47.955201, lng: 18.216688 },
    { lat: 47.956132, lng: 18.217709 },
    { lat: 47.960767, lng: 18.219272 },
    { lat: 47.9629584, lng: 18.2168371 },
    { lat: 47.9630375, lng: 18.2168787 },
    { lat: 47.9635646, lng: 18.2167043 },
    { lat: 47.9666884, lng: 18.2166146 },
    { lat: 47.9695815, lng: 18.216324 },
    { lat: 47.9710801, lng: 18.2165957 },
    { lat: 47.9719396, lng: 18.2165904 },
    { lat: 47.9731808, lng: 18.2167257 },
    { lat: 47.9742564, lng: 18.2169232 },
    { lat: 47.9759995, lng: 18.2170933 },
    { lat: 47.9764877, lng: 18.2172588 },
    { lat: 47.9768652, lng: 18.2174838 },
    { lat: 47.9770216, lng: 18.2174731 },
    { lat: 47.9772692, lng: 18.2173848 },
    { lat: 47.9780559, lng: 18.2169255 },
    { lat: 47.9780241, lng: 18.2168834 },
    { lat: 47.9785452, lng: 18.2167602 },
    { lat: 47.9793376, lng: 18.2169034 },
    { lat: 47.9794268, lng: 18.2167872 },
    { lat: 47.9804299, lng: 18.2166905 },
    { lat: 47.9820298, lng: 18.2155042 },
    { lat: 47.9850779, lng: 18.2141135 },
    { lat: 47.9896271, lng: 18.2123016 },
    { lat: 47.9899066, lng: 18.2121401 },
    { lat: 47.9900954, lng: 18.2119508 },
    { lat: 47.9907473, lng: 18.2118892 },
    { lat: 47.9940963, lng: 18.212334 },
    { lat: 47.9979612, lng: 18.2139055 },
    { lat: 48.0022998, lng: 18.2159963 },
    { lat: 48.0035761, lng: 18.2166978 },
    { lat: 48.005739, lng: 18.2184389 },
    { lat: 48.0065419, lng: 18.219688 },
    { lat: 48.0073807, lng: 18.2211541 },
    { lat: 48.00834, lng: 18.2226878 },
    { lat: 48.0097829, lng: 18.2235975 },
    { lat: 48.0122171, lng: 18.2249792 },
    { lat: 48.0153505, lng: 18.2272893 },
    { lat: 48.017437, lng: 18.2286717 },
    { lat: 48.0187211, lng: 18.2297992 },
    { lat: 48.0204518, lng: 18.230435 },
    { lat: 48.022081, lng: 18.2313091 },
    { lat: 48.0254051, lng: 18.2323776 },
    { lat: 48.0296575, lng: 18.2350311 },
    { lat: 48.0332943, lng: 18.2375837 },
    { lat: 48.0351125, lng: 18.238448 },
    { lat: 48.0365095, lng: 18.2375711 },
    { lat: 48.0448141, lng: 18.232348 },
    { lat: 48.0448049, lng: 18.2323069 },
    { lat: 48.0453716, lng: 18.2315712 },
    { lat: 48.0456168, lng: 18.2315181 },
    { lat: 48.0457791, lng: 18.2316703 },
    { lat: 48.0459787, lng: 18.2315902 },
    { lat: 48.0461855, lng: 18.2313633 },
    { lat: 48.0462811, lng: 18.2311616 },
    { lat: 48.0463439, lng: 18.2308245 },
    { lat: 48.046207, lng: 18.2303894 },
    { lat: 48.0459614, lng: 18.2300473 },
    { lat: 48.0456483, lng: 18.2299787 },
    { lat: 48.0453936, lng: 18.2302185 },
    { lat: 48.0452781, lng: 18.2301714 },
    { lat: 48.0450814, lng: 18.229915 },
    { lat: 48.0449363, lng: 18.2299982 },
    { lat: 48.044384, lng: 18.2292535 },
    { lat: 48.0442456, lng: 18.2285185 },
    { lat: 48.0442406, lng: 18.2279666 },
    { lat: 48.0440165, lng: 18.227333 },
    { lat: 48.0441217, lng: 18.2267742 },
    { lat: 48.0440471, lng: 18.2265568 },
    { lat: 48.0437805, lng: 18.226142 },
    { lat: 48.0433154, lng: 18.225849 },
    { lat: 48.0430092, lng: 18.225007 },
    { lat: 48.0428537, lng: 18.2247383 },
    { lat: 48.0424922, lng: 18.2244376 },
    { lat: 48.0422235, lng: 18.2243394 },
    { lat: 48.041974, lng: 18.2243381 },
    { lat: 48.0418084, lng: 18.2244963 },
    { lat: 48.0416761, lng: 18.2249375 },
    { lat: 48.0414307, lng: 18.2249103 },
    { lat: 48.0412532, lng: 18.2242076 },
    { lat: 48.0409892, lng: 18.223741 },
    { lat: 48.0407497, lng: 18.2235205 },
    { lat: 48.0404686, lng: 18.2234813 },
    { lat: 48.0402017, lng: 18.2235274 },
    { lat: 48.0400157, lng: 18.2234344 },
    { lat: 48.0398337, lng: 18.2234286 },
    { lat: 48.039594, lng: 18.2233023 },
    { lat: 48.0392694, lng: 18.2229786 },
    { lat: 48.0392888, lng: 18.2225555 },
    { lat: 48.039252, lng: 18.2221378 },
    { lat: 48.0391623, lng: 18.2219727 },
    { lat: 48.0388633, lng: 18.2217959 },
    { lat: 48.0387557, lng: 18.2216332 },
    { lat: 48.0386536, lng: 18.2212557 },
    { lat: 48.0385051, lng: 18.2212266 },
    { lat: 48.0384006, lng: 18.2213289 },
    { lat: 48.0379076, lng: 18.2213485 },
    { lat: 48.0378017, lng: 18.2212765 },
    { lat: 48.0377298, lng: 18.2210284 },
    { lat: 48.0378371, lng: 18.2207011 },
    { lat: 48.0378221, lng: 18.2202884 },
    { lat: 48.037608, lng: 18.2200986 },
    { lat: 48.0373234, lng: 18.2199379 },
    { lat: 48.0369771, lng: 18.21963 },
    { lat: 48.0368105, lng: 18.2195793 },
    { lat: 48.0357841, lng: 18.2197436 },
    { lat: 48.0354133, lng: 18.2196131 },
    { lat: 48.0351837, lng: 18.2193318 },
    { lat: 48.0350539, lng: 18.2193112 },
    { lat: 48.0345836, lng: 18.2188886 },
    { lat: 48.0334625, lng: 18.218684 },
    { lat: 48.0328352, lng: 18.2180857 },
    { lat: 48.0326025, lng: 18.2179434 },
    { lat: 48.0324352, lng: 18.2180965 },
    { lat: 48.0323883, lng: 18.218355 },
    { lat: 48.0324595, lng: 18.2189014 },
    { lat: 48.0324041, lng: 18.2194811 },
    { lat: 48.0323122, lng: 18.2196868 },
    { lat: 48.0321511, lng: 18.2197704 },
    { lat: 48.0319563, lng: 18.2193471 },
    { lat: 48.0319134, lng: 18.2190368 },
    { lat: 48.0319024, lng: 18.2182859 },
    { lat: 48.0319938, lng: 18.217024 },
    { lat: 48.0319886, lng: 18.2165639 },
    { lat: 48.0317897, lng: 18.2163671 },
    { lat: 48.0315138, lng: 18.2164137 },
    { lat: 48.0313693, lng: 18.2165107 },
    { lat: 48.0313051, lng: 18.2170735 },
    { lat: 48.0309498, lng: 18.2181089 },
    { lat: 48.0308445, lng: 18.2185664 },
    { lat: 48.0308793, lng: 18.2192997 },
    { lat: 48.0307682, lng: 18.2195443 },
    { lat: 48.0306834, lng: 18.219619 },
    { lat: 48.0306015, lng: 18.2195901 },
    { lat: 48.0302149, lng: 18.2191871 },
    { lat: 48.0300584, lng: 18.2192729 },
    { lat: 48.0300496, lng: 18.2195497 },
    { lat: 48.0301644, lng: 18.2201652 },
    { lat: 48.0300923, lng: 18.2202837 },
    { lat: 48.0299286, lng: 18.2203024 },
    { lat: 48.0296708, lng: 18.2200414 },
    { lat: 48.0291758, lng: 18.219133 },
    { lat: 48.0291798, lng: 18.2178829 },
    { lat: 48.029109, lng: 18.2176526 },
    { lat: 48.0288868, lng: 18.2175403 },
    { lat: 48.0281189, lng: 18.2181327 },
    { lat: 48.0285317, lng: 18.2012798 },
    { lat: 48.028607, lng: 18.2001339 },
    { lat: 48.0300274, lng: 18.1883293 },
    { lat: 48.030132, lng: 18.1839541 },
    { lat: 48.0303621, lng: 18.1800544 },
    { lat: 48.0303868, lng: 18.1772537 },
    { lat: 48.0302467, lng: 18.1737194 },
    { lat: 48.0310091, lng: 18.1661387 },
    { lat: 48.0320789, lng: 18.1624023 },
    { lat: 48.0342499, lng: 18.1530691 },
    { lat: 48.036141, lng: 18.1483158 },
    { lat: 48.0378317, lng: 18.1432111 },
    { lat: 48.038874, lng: 18.1404031 },
    { lat: 48.0392192, lng: 18.1393643 },
    { lat: 48.0396453, lng: 18.1377828 },
    { lat: 48.040242, lng: 18.1364536 },
    { lat: 48.0408063, lng: 18.1354587 },
    { lat: 48.0413321, lng: 18.1345355 },
    { lat: 48.0439001, lng: 18.129982 },
    { lat: 48.0456949, lng: 18.1265239 },
    { lat: 48.0454345, lng: 18.1251861 },
    { lat: 48.0454569, lng: 18.1229258 },
    { lat: 48.0451071, lng: 18.1212217 },
    { lat: 48.0444594, lng: 18.1191762 },
    { lat: 48.0443555, lng: 18.1186217 },
  ],
  MaléKosihy: [
    { lat: 47.9128148, lng: 18.7239338 },
    { lat: 47.9130137, lng: 18.727385 },
    { lat: 47.9133791, lng: 18.735894 },
    { lat: 47.9144542, lng: 18.7586429 },
    { lat: 47.914933, lng: 18.759977 },
    { lat: 47.914972, lng: 18.759654 },
    { lat: 47.915669, lng: 18.758494 },
    { lat: 47.916, lng: 18.758199 },
    { lat: 47.916796, lng: 18.757678 },
    { lat: 47.917723, lng: 18.75737 },
    { lat: 47.918109, lng: 18.757417 },
    { lat: 47.918337, lng: 18.757655 },
    { lat: 47.91882, lng: 18.757544 },
    { lat: 47.919722, lng: 18.75776 },
    { lat: 47.920361, lng: 18.758056 },
    { lat: 47.920963, lng: 18.758472 },
    { lat: 47.921632, lng: 18.759178 },
    { lat: 47.921887, lng: 18.759558 },
    { lat: 47.922539, lng: 18.76077 },
    { lat: 47.92317, lng: 18.762477 },
    { lat: 47.92368, lng: 18.763346 },
    { lat: 47.924569, lng: 18.764426 },
    { lat: 47.926442, lng: 18.766415 },
    { lat: 47.928532, lng: 18.76939 },
    { lat: 47.929162, lng: 18.770022 },
    { lat: 47.92988, lng: 18.77057 },
    { lat: 47.930809, lng: 18.770915 },
    { lat: 47.931473, lng: 18.771291 },
    { lat: 47.931818, lng: 18.771604 },
    { lat: 47.931832, lng: 18.7716045 },
    { lat: 47.931938, lng: 18.771796 },
    { lat: 47.931989, lng: 18.772001 },
    { lat: 47.931995, lng: 18.772984 },
    { lat: 47.932043, lng: 18.773312 },
    { lat: 47.932347, lng: 18.774048 },
    { lat: 47.93264, lng: 18.774172 },
    { lat: 47.932813, lng: 18.77392 },
    { lat: 47.932901, lng: 18.773446 },
    { lat: 47.932793, lng: 18.773131 },
    { lat: 47.932763, lng: 18.772593 },
    { lat: 47.932992, lng: 18.772056 },
    { lat: 47.93333, lng: 18.771606 },
    { lat: 47.933551, lng: 18.771491 },
    { lat: 47.933855, lng: 18.771446 },
    { lat: 47.934117, lng: 18.771532 },
    { lat: 47.934677, lng: 18.771879 },
    { lat: 47.935827, lng: 18.77293 },
    { lat: 47.936488, lng: 18.773406 },
    { lat: 47.937148, lng: 18.773657 },
    { lat: 47.938249, lng: 18.77379 },
    { lat: 47.938384, lng: 18.773788 },
    { lat: 47.9376514, lng: 18.7696695 },
    { lat: 47.9362049, lng: 18.7644783 },
    { lat: 47.9345188, lng: 18.7578907 },
    { lat: 47.9359568, lng: 18.7522579 },
    { lat: 47.9365383, lng: 18.743483 },
    { lat: 47.9367381, lng: 18.7382283 },
    { lat: 47.9371755, lng: 18.7337014 },
    { lat: 47.9373124, lng: 18.7289203 },
    { lat: 47.9372238, lng: 18.7280052 },
    { lat: 47.9372098, lng: 18.7272001 },
    { lat: 47.9371142, lng: 18.7265503 },
    { lat: 47.9367348, lng: 18.7263334 },
    { lat: 47.9367143, lng: 18.7211237 },
    { lat: 47.936315, lng: 18.71431 },
    { lat: 47.9351316, lng: 18.716149 },
    { lat: 47.9337115, lng: 18.7172958 },
    { lat: 47.9320108, lng: 18.717318 },
    { lat: 47.9302828, lng: 18.7171468 },
    { lat: 47.9284598, lng: 18.7179178 },
    { lat: 47.9269851, lng: 18.71845 },
    { lat: 47.9258309, lng: 18.7201388 },
    { lat: 47.9244823, lng: 18.721697 },
    { lat: 47.922903, lng: 18.7225294 },
    { lat: 47.9204778, lng: 18.7248519 },
    { lat: 47.9197123, lng: 18.7245604 },
    { lat: 47.9192274, lng: 18.7240957 },
    { lat: 47.9175873, lng: 18.7247034 },
    { lat: 47.9160545, lng: 18.7236943 },
    { lat: 47.9144412, lng: 18.7237484 },
    { lat: 47.9134189, lng: 18.7238644 },
    { lat: 47.9128148, lng: 18.7239338 },
  ],
  Ľubá: [
    { lat: 47.8819232, lng: 18.587459 },
    { lat: 47.8814895, lng: 18.5868107 },
    { lat: 47.8812438, lng: 18.5858343 },
    { lat: 47.8809242, lng: 18.5837361 },
    { lat: 47.8801378, lng: 18.5810658 },
    { lat: 47.8796689, lng: 18.5807448 },
    { lat: 47.8761947, lng: 18.5782065 },
    { lat: 47.875584, lng: 18.5789754 },
    { lat: 47.8739459, lng: 18.582227 },
    { lat: 47.8733278, lng: 18.5832483 },
    { lat: 47.8708093, lng: 18.5812862 },
    { lat: 47.8697091, lng: 18.5807898 },
    { lat: 47.8687961, lng: 18.5808436 },
    { lat: 47.867759, lng: 18.5810793 },
    { lat: 47.8669301, lng: 18.5815743 },
    { lat: 47.8652075, lng: 18.5821654 },
    { lat: 47.863256, lng: 18.582835 },
    { lat: 47.8597057, lng: 18.5826018 },
    { lat: 47.8586117, lng: 18.5828911 },
    { lat: 47.8583224, lng: 18.5823471 },
    { lat: 47.856622, lng: 18.5821106 },
    { lat: 47.855417, lng: 18.5815737 },
    { lat: 47.8555297, lng: 18.5811832 },
    { lat: 47.8553619, lng: 18.5807962 },
    { lat: 47.8550348, lng: 18.5806333 },
    { lat: 47.8535697, lng: 18.5801481 },
    { lat: 47.8526325, lng: 18.5797403 },
    { lat: 47.8505852, lng: 18.5793891 },
    { lat: 47.8491064, lng: 18.579739 },
    { lat: 47.8477632, lng: 18.5799669 },
    { lat: 47.8468752, lng: 18.5802249 },
    { lat: 47.8456009, lng: 18.5804564 },
    { lat: 47.8452587, lng: 18.5793839 },
    { lat: 47.8444429, lng: 18.5781724 },
    { lat: 47.8447105, lng: 18.5804695 },
    { lat: 47.8446965, lng: 18.581403 },
    { lat: 47.8445379, lng: 18.5826814 },
    { lat: 47.8444709, lng: 18.5830278 },
    { lat: 47.8443859, lng: 18.5834667 },
    { lat: 47.8442293, lng: 18.5839794 },
    { lat: 47.8434109, lng: 18.585978 },
    { lat: 47.842908, lng: 18.5865335 },
    { lat: 47.8425906, lng: 18.5867704 },
    { lat: 47.8424102, lng: 18.5870399 },
    { lat: 47.8424398, lng: 18.5874744 },
    { lat: 47.8425215, lng: 18.5877475 },
    { lat: 47.842511, lng: 18.5879489 },
    { lat: 47.842345, lng: 18.5882945 },
    { lat: 47.841988, lng: 18.588735 },
    { lat: 47.8416019, lng: 18.5898755 },
    { lat: 47.8416029, lng: 18.5901823 },
    { lat: 47.8415529, lng: 18.5903983 },
    { lat: 47.8411265, lng: 18.5914429 },
    { lat: 47.8408264, lng: 18.5918736 },
    { lat: 47.8402585, lng: 18.5921297 },
    { lat: 47.8398793, lng: 18.592188 },
    { lat: 47.839515, lng: 18.5925173 },
    { lat: 47.8394937, lng: 18.5932395 },
    { lat: 47.8396498, lng: 18.5939635 },
    { lat: 47.8396281, lng: 18.5941231 },
    { lat: 47.8394432, lng: 18.5947802 },
    { lat: 47.8393242, lng: 18.5950362 },
    { lat: 47.8389649, lng: 18.5953968 },
    { lat: 47.8387862, lng: 18.595963 },
    { lat: 47.8387498, lng: 18.5963462 },
    { lat: 47.8389435, lng: 18.5972439 },
    { lat: 47.838713, lng: 18.5979354 },
    { lat: 47.838566, lng: 18.599282 },
    { lat: 47.8387255, lng: 18.5997233 },
    { lat: 47.8386944, lng: 18.6015158 },
    { lat: 47.8385997, lng: 18.6029551 },
    { lat: 47.8383525, lng: 18.6034035 },
    { lat: 47.8362462, lng: 18.60648 },
    { lat: 47.8371092, lng: 18.6072823 },
    { lat: 47.8377584, lng: 18.6076875 },
    { lat: 47.8381118, lng: 18.6080407 },
    { lat: 47.8379579, lng: 18.6093074 },
    { lat: 47.838576, lng: 18.6102335 },
    { lat: 47.8386724, lng: 18.6104872 },
    { lat: 47.8386737, lng: 18.6107612 },
    { lat: 47.8384319, lng: 18.6129955 },
    { lat: 47.8382955, lng: 18.6153845 },
    { lat: 47.8382683, lng: 18.6164101 },
    { lat: 47.8383081, lng: 18.6172408 },
    { lat: 47.8382593, lng: 18.6176873 },
    { lat: 47.8388252, lng: 18.6180542 },
    { lat: 47.8397677, lng: 18.6181049 },
    { lat: 47.8404413, lng: 18.6178642 },
    { lat: 47.8407113, lng: 18.6176669 },
    { lat: 47.8415774, lng: 18.6175597 },
    { lat: 47.842083, lng: 18.6176686 },
    { lat: 47.8423033, lng: 18.6177771 },
    { lat: 47.8428068, lng: 18.6178079 },
    { lat: 47.8432808, lng: 18.6179577 },
    { lat: 47.8438271, lng: 18.6179198 },
    { lat: 47.8446718, lng: 18.6176948 },
    { lat: 47.8455537, lng: 18.6175869 },
    { lat: 47.8458637, lng: 18.6172969 },
    { lat: 47.8473211, lng: 18.6170288 },
    { lat: 47.8477161, lng: 18.6170944 },
    { lat: 47.848125, lng: 18.6169442 },
    { lat: 47.8488576, lng: 18.6170154 },
    { lat: 47.8495399, lng: 18.6168901 },
    { lat: 47.851584, lng: 18.6157813 },
    { lat: 47.8540685, lng: 18.6130363 },
    { lat: 47.8542303, lng: 18.6132549 },
    { lat: 47.8554055, lng: 18.6121596 },
    { lat: 47.8564159, lng: 18.6109216 },
    { lat: 47.856825, lng: 18.6098649 },
    { lat: 47.8568913, lng: 18.6094702 },
    { lat: 47.8638044, lng: 18.604882 },
    { lat: 47.8650382, lng: 18.6057051 },
    { lat: 47.8667703, lng: 18.6083264 },
    { lat: 47.8671533, lng: 18.6090391 },
    { lat: 47.8680837, lng: 18.608689 },
    { lat: 47.8681995, lng: 18.6086581 },
    { lat: 47.8684243, lng: 18.6083558 },
    { lat: 47.868723, lng: 18.6081486 },
    { lat: 47.8689292, lng: 18.6078781 },
    { lat: 47.8692136, lng: 18.607694 },
    { lat: 47.8695524, lng: 18.6076997 },
    { lat: 47.8697535, lng: 18.607212 },
    { lat: 47.8702757, lng: 18.6068424 },
    { lat: 47.8707843, lng: 18.6061235 },
    { lat: 47.8715662, lng: 18.6047051 },
    { lat: 47.8718199, lng: 18.6044566 },
    { lat: 47.8721129, lng: 18.6038565 },
    { lat: 47.8723045, lng: 18.603261 },
    { lat: 47.8726128, lng: 18.6028956 },
    { lat: 47.8732521, lng: 18.6018439 },
    { lat: 47.873595, lng: 18.6014721 },
    { lat: 47.8735719, lng: 18.6010523 },
    { lat: 47.8736229, lng: 18.6008704 },
    { lat: 47.874197, lng: 18.600232 },
    { lat: 47.8746411, lng: 18.5993874 },
    { lat: 47.8747057, lng: 18.5989404 },
    { lat: 47.8750521, lng: 18.5985519 },
    { lat: 47.8751344, lng: 18.5981615 },
    { lat: 47.8757658, lng: 18.5977023 },
    { lat: 47.8763272, lng: 18.5967506 },
    { lat: 47.876486, lng: 18.5962774 },
    { lat: 47.8766582, lng: 18.596104 },
    { lat: 47.8770966, lng: 18.5952758 },
    { lat: 47.8773293, lng: 18.5942539 },
    { lat: 47.8779644, lng: 18.593498 },
    { lat: 47.87829, lng: 18.5932031 },
    { lat: 47.8785214, lng: 18.5924677 },
    { lat: 47.8786011, lng: 18.5923393 },
    { lat: 47.8788718, lng: 18.5921504 },
    { lat: 47.8789859, lng: 18.5919303 },
    { lat: 47.8792565, lng: 18.5917446 },
    { lat: 47.8797524, lng: 18.5912244 },
    { lat: 47.880247, lng: 18.5902681 },
    { lat: 47.8809131, lng: 18.5896028 },
    { lat: 47.8812147, lng: 18.5888501 },
    { lat: 47.8813789, lng: 18.5885775 },
    { lat: 47.8814754, lng: 18.5880756 },
    { lat: 47.8815965, lng: 18.5878153 },
    { lat: 47.8817908, lng: 18.5876357 },
    { lat: 47.8819232, lng: 18.587459 },
  ],
  Mojzesovo: [
    { lat: 48.1493318, lng: 18.2526167 },
    { lat: 48.1497429, lng: 18.2522916 },
    { lat: 48.1523972, lng: 18.2504168 },
    { lat: 48.1517906, lng: 18.2486081 },
    { lat: 48.1521748, lng: 18.2482462 },
    { lat: 48.1526078, lng: 18.2476172 },
    { lat: 48.1526903, lng: 18.2472278 },
    { lat: 48.1531586, lng: 18.2465211 },
    { lat: 48.1536967, lng: 18.2459542 },
    { lat: 48.1536918, lng: 18.2458286 },
    { lat: 48.1530518, lng: 18.2438535 },
    { lat: 48.1519887, lng: 18.2409009 },
    { lat: 48.1517495, lng: 18.2397869 },
    { lat: 48.1509814, lng: 18.2371218 },
    { lat: 48.1494652, lng: 18.2327396 },
    { lat: 48.1486494, lng: 18.2299324 },
    { lat: 48.1476155, lng: 18.2272962 },
    { lat: 48.1473371, lng: 18.2274944 },
    { lat: 48.14718, lng: 18.2270031 },
    { lat: 48.1455678, lng: 18.2281581 },
    { lat: 48.1449606, lng: 18.2264245 },
    { lat: 48.1463119, lng: 18.2250675 },
    { lat: 48.1454969, lng: 18.2237384 },
    { lat: 48.1448959, lng: 18.2221333 },
    { lat: 48.1436743, lng: 18.2182078 },
    { lat: 48.1430709, lng: 18.2172437 },
    { lat: 48.1420097, lng: 18.2134224 },
    { lat: 48.1415837, lng: 18.2137051 },
    { lat: 48.1404074, lng: 18.2144715 },
    { lat: 48.1386456, lng: 18.2151286 },
    { lat: 48.1368781, lng: 18.2156275 },
    { lat: 48.1326681, lng: 18.2160575 },
    { lat: 48.1303405, lng: 18.2151311 },
    { lat: 48.129324, lng: 18.2151353 },
    { lat: 48.1281452, lng: 18.2151028 },
    { lat: 48.1268853, lng: 18.2171143 },
    { lat: 48.1263525, lng: 18.2178109 },
    { lat: 48.1256566, lng: 18.2185158 },
    { lat: 48.1256282, lng: 18.218612 },
    { lat: 48.1255813, lng: 18.2188307 },
    { lat: 48.1242617, lng: 18.2216447 },
    { lat: 48.1236512, lng: 18.2232038 },
    { lat: 48.1233519, lng: 18.2241922 },
    { lat: 48.1231541, lng: 18.2254097 },
    { lat: 48.1235172, lng: 18.2287744 },
    { lat: 48.1236517, lng: 18.2293658 },
    { lat: 48.1238717, lng: 18.2297503 },
    { lat: 48.1230103, lng: 18.2318082 },
    { lat: 48.1216445, lng: 18.2345322 },
    { lat: 48.1216138, lng: 18.2356204 },
    { lat: 48.1218526, lng: 18.2370375 },
    { lat: 48.1218017, lng: 18.2373637 },
    { lat: 48.1216602, lng: 18.2374921 },
    { lat: 48.121665, lng: 18.2379884 },
    { lat: 48.1217414, lng: 18.2384669 },
    { lat: 48.1215966, lng: 18.239283 },
    { lat: 48.1254045, lng: 18.2458821 },
    { lat: 48.1261988, lng: 18.2472588 },
    { lat: 48.1272081, lng: 18.2489268 },
    { lat: 48.1271554, lng: 18.2490502 },
    { lat: 48.1276542, lng: 18.2494616 },
    { lat: 48.1282029, lng: 18.2499183 },
    { lat: 48.1295373, lng: 18.250788 },
    { lat: 48.1307585, lng: 18.2518694 },
    { lat: 48.1310743, lng: 18.2524327 },
    { lat: 48.1318028, lng: 18.2528949 },
    { lat: 48.1320818, lng: 18.2531743 },
    { lat: 48.1324291, lng: 18.2537354 },
    { lat: 48.1325159, lng: 18.253247 },
    { lat: 48.1332184, lng: 18.2527475 },
    { lat: 48.1338479, lng: 18.2524906 },
    { lat: 48.1342007, lng: 18.2525076 },
    { lat: 48.1364387, lng: 18.2534134 },
    { lat: 48.1366552, lng: 18.2525307 },
    { lat: 48.1373785, lng: 18.2526657 },
    { lat: 48.1379989, lng: 18.2528578 },
    { lat: 48.1385687, lng: 18.2510001 },
    { lat: 48.1409264, lng: 18.2520869 },
    { lat: 48.1416826, lng: 18.2522276 },
    { lat: 48.1421763, lng: 18.2521976 },
    { lat: 48.1433134, lng: 18.2514894 },
    { lat: 48.1464213, lng: 18.2508664 },
    { lat: 48.1481325, lng: 18.2515214 },
    { lat: 48.1487191, lng: 18.2519723 },
    { lat: 48.1493318, lng: 18.2526167 },
  ],
  Zemné: [
    { lat: 47.962941, lng: 17.970447 },
    { lat: 47.963619, lng: 17.971429 },
    { lat: 47.96392, lng: 17.97188 },
    { lat: 47.964721, lng: 17.972912 },
    { lat: 47.965068, lng: 17.973345 },
    { lat: 47.965304, lng: 17.973661 },
    { lat: 47.965325, lng: 17.973689 },
    { lat: 47.965342, lng: 17.973712 },
    { lat: 47.965406, lng: 17.973798 },
    { lat: 47.965435, lng: 17.973836 },
    { lat: 47.965507, lng: 17.973933 },
    { lat: 47.965511, lng: 17.973937 },
    { lat: 47.965581, lng: 17.974033 },
    { lat: 47.965713, lng: 17.974209 },
    { lat: 47.965988, lng: 17.974582 },
    { lat: 47.966041, lng: 17.974653 },
    { lat: 47.966219, lng: 17.974832 },
    { lat: 47.966691, lng: 17.975305 },
    { lat: 47.966964, lng: 17.97558 },
    { lat: 47.967135, lng: 17.975753 },
    { lat: 47.967236, lng: 17.975853 },
    { lat: 47.967237, lng: 17.975854 },
    { lat: 47.967295, lng: 17.975914 },
    { lat: 47.967296, lng: 17.975915 },
    { lat: 47.967343, lng: 17.975951 },
    { lat: 47.967348, lng: 17.975969 },
    { lat: 47.967589, lng: 17.976774 },
    { lat: 47.967594, lng: 17.976794 },
    { lat: 47.967616, lng: 17.976867 },
    { lat: 47.96772, lng: 17.977214 },
    { lat: 47.967998, lng: 17.978195 },
    { lat: 47.96803, lng: 17.978306 },
    { lat: 47.968156, lng: 17.978749 },
    { lat: 47.968378, lng: 17.979508 },
    { lat: 47.968439, lng: 17.979717 },
    { lat: 47.96872, lng: 17.980704 },
    { lat: 47.968774, lng: 17.980909 },
    { lat: 47.969013, lng: 17.98178 },
    { lat: 47.969014, lng: 17.981785 },
    { lat: 47.969036, lng: 17.981864 },
    { lat: 47.969156, lng: 17.98229 },
    { lat: 47.969166, lng: 17.982328 },
    { lat: 47.969314, lng: 17.982851 },
    { lat: 47.969659, lng: 17.983903 },
    { lat: 47.969974, lng: 17.984808 },
    { lat: 47.970139, lng: 17.985282 },
    { lat: 47.970177, lng: 17.985395 },
    { lat: 47.970191, lng: 17.985434 },
    { lat: 47.970196, lng: 17.985449 },
    { lat: 47.970205, lng: 17.985473 },
    { lat: 47.970207, lng: 17.985478 },
    { lat: 47.970243, lng: 17.985582 },
    { lat: 47.970245, lng: 17.985588 },
    { lat: 47.970273, lng: 17.985665 },
    { lat: 47.970326, lng: 17.985817 },
    { lat: 47.970344, lng: 17.985866 },
    { lat: 47.9704, lng: 17.985981 },
    { lat: 47.970433, lng: 17.986049 },
    { lat: 47.970434, lng: 17.98605 },
    { lat: 47.970589, lng: 17.986376 },
    { lat: 47.970623, lng: 17.98645 },
    { lat: 47.970649, lng: 17.986503 },
    { lat: 47.970715, lng: 17.986641 },
    { lat: 47.970715, lng: 17.986642 },
    { lat: 47.970985, lng: 17.987229 },
    { lat: 47.970991, lng: 17.987253 },
    { lat: 47.970944, lng: 17.987383 },
    { lat: 47.970706, lng: 17.988208 },
    { lat: 47.970648, lng: 17.988407 },
    { lat: 47.970635, lng: 17.988464 },
    { lat: 47.970569, lng: 17.988779 },
    { lat: 47.970568, lng: 17.988783 },
    { lat: 47.970469, lng: 17.989748 },
    { lat: 47.97046, lng: 17.989839 },
    { lat: 47.970422, lng: 17.990194 },
    { lat: 47.970373, lng: 17.990664 },
    { lat: 47.970265, lng: 17.992093 },
    { lat: 47.970261, lng: 17.992133 },
    { lat: 47.970271, lng: 17.992186 },
    { lat: 47.970319, lng: 17.992328 },
    { lat: 47.970536, lng: 17.992976 },
    { lat: 47.970621, lng: 17.993234 },
    { lat: 47.970686, lng: 17.993426 },
    { lat: 47.971015, lng: 17.994418 },
    { lat: 47.971034, lng: 17.994471 },
    { lat: 47.971048, lng: 17.994516 },
    { lat: 47.971363, lng: 17.995137 },
    { lat: 47.971375, lng: 17.99516 },
    { lat: 47.971584, lng: 17.995559 },
    { lat: 47.971666, lng: 17.995715 },
    { lat: 47.971675, lng: 17.995734 },
    { lat: 47.971842, lng: 17.996061 },
    { lat: 47.971849, lng: 17.996074 },
    { lat: 47.971865, lng: 17.9961 },
    { lat: 47.972151, lng: 17.996575 },
    { lat: 47.97217, lng: 17.996664 },
    { lat: 47.972171, lng: 17.996669 },
    { lat: 47.972255, lng: 17.997048 },
    { lat: 47.972304, lng: 17.997274 },
    { lat: 47.972343, lng: 17.997449 },
    { lat: 47.972392, lng: 17.99767 },
    { lat: 47.972432, lng: 17.997853 },
    { lat: 47.97246, lng: 17.997982 },
    { lat: 47.972468, lng: 17.998014 },
    { lat: 47.972571, lng: 17.998427 },
    { lat: 47.972604, lng: 17.998592 },
    { lat: 47.972605, lng: 17.998653 },
    { lat: 47.972605, lng: 17.998719 },
    { lat: 47.972619, lng: 17.99904 },
    { lat: 47.972622, lng: 17.999082 },
    { lat: 47.972399, lng: 17.999735 },
    { lat: 47.971964, lng: 18.000315 },
    { lat: 47.971727, lng: 18.000682 },
    { lat: 47.97128, lng: 18.001658 },
    { lat: 47.971141, lng: 18.002135 },
    { lat: 47.971076, lng: 18.002383 },
    { lat: 47.970972, lng: 18.0028 },
    { lat: 47.970851, lng: 18.003289 },
    { lat: 47.970454, lng: 18.004853 },
    { lat: 47.970476, lng: 18.004932 },
    { lat: 47.97075, lng: 18.005925 },
    { lat: 47.970759, lng: 18.005919 },
    { lat: 47.971069, lng: 18.005696 },
    { lat: 47.971108, lng: 18.005668 },
    { lat: 47.971298, lng: 18.005609 },
    { lat: 47.971323, lng: 18.005601 },
    { lat: 47.971912, lng: 18.005178 },
    { lat: 47.971937, lng: 18.005168 },
    { lat: 47.97204, lng: 18.005124 },
    { lat: 47.972362, lng: 18.004835 },
    { lat: 47.972391, lng: 18.004809 },
    { lat: 47.972541, lng: 18.004626 },
    { lat: 47.972555, lng: 18.00461 },
    { lat: 47.972702, lng: 18.004392 },
    { lat: 47.972931, lng: 18.004042 },
    { lat: 47.972973, lng: 18.003963 },
    { lat: 47.973333, lng: 18.003289 },
    { lat: 47.973345, lng: 18.003266 },
    { lat: 47.973418, lng: 18.003103 },
    { lat: 47.973774, lng: 18.002307 },
    { lat: 47.974418, lng: 18.002957 },
    { lat: 47.97457, lng: 18.003111 },
    { lat: 47.974584, lng: 18.003135 },
    { lat: 47.974599, lng: 18.003161 },
    { lat: 47.974616, lng: 18.003187 },
    { lat: 47.974659, lng: 18.003257 },
    { lat: 47.974812, lng: 18.003507 },
    { lat: 47.974838, lng: 18.003541 },
    { lat: 47.974948, lng: 18.003689 },
    { lat: 47.974987, lng: 18.00375 },
    { lat: 47.975271, lng: 18.00418 },
    { lat: 47.975372, lng: 18.004366 },
    { lat: 47.975396, lng: 18.004408 },
    { lat: 47.97557, lng: 18.004681 },
    { lat: 47.9756, lng: 18.004728 },
    { lat: 47.975647, lng: 18.004793 },
    { lat: 47.975658, lng: 18.004811 },
    { lat: 47.975689, lng: 18.00486 },
    { lat: 47.975729, lng: 18.004924 },
    { lat: 47.975891, lng: 18.005169 },
    { lat: 47.976006, lng: 18.005347 },
    { lat: 47.976146, lng: 18.005956 },
    { lat: 47.976369, lng: 18.00693 },
    { lat: 47.976741, lng: 18.008707 },
    { lat: 47.9768, lng: 18.008818 },
    { lat: 47.976837, lng: 18.008878 },
    { lat: 47.976875, lng: 18.008834 },
    { lat: 47.976979, lng: 18.008713 },
    { lat: 47.977021, lng: 18.008666 },
    { lat: 47.977079, lng: 18.008599 },
    { lat: 47.977088, lng: 18.00859 },
    { lat: 47.977215, lng: 18.00848 },
    { lat: 47.977286, lng: 18.008419 },
    { lat: 47.977432, lng: 18.008336 },
    { lat: 47.977487, lng: 18.008304 },
    { lat: 47.977581, lng: 18.008251 },
    { lat: 47.977602, lng: 18.008238 },
    { lat: 47.977806, lng: 18.008156 },
    { lat: 47.977958, lng: 18.008095 },
    { lat: 47.978139, lng: 18.008022 },
    { lat: 47.978148, lng: 18.008017 },
    { lat: 47.978281, lng: 18.007946 },
    { lat: 47.97842, lng: 18.007871 },
    { lat: 47.978433, lng: 18.007868 },
    { lat: 47.978657, lng: 18.007823 },
    { lat: 47.978814, lng: 18.007792 },
    { lat: 47.97884, lng: 18.007799 },
    { lat: 47.978923, lng: 18.007823 },
    { lat: 47.97893, lng: 18.007825 },
    { lat: 47.979052, lng: 18.00792 },
    { lat: 47.979074, lng: 18.007937 },
    { lat: 47.979152, lng: 18.008036 },
    { lat: 47.979173, lng: 18.00807 },
    { lat: 47.979304, lng: 18.008277 },
    { lat: 47.979348, lng: 18.008347 },
    { lat: 47.979354, lng: 18.008356 },
    { lat: 47.979367, lng: 18.008388 },
    { lat: 47.979435, lng: 18.008556 },
    { lat: 47.979456, lng: 18.008608 },
    { lat: 47.979472, lng: 18.008709 },
    { lat: 47.979489, lng: 18.008816 },
    { lat: 47.979515, lng: 18.008978 },
    { lat: 47.979516, lng: 18.008989 },
    { lat: 47.979519, lng: 18.009002 },
    { lat: 47.979552, lng: 18.009184 },
    { lat: 47.979555, lng: 18.0092 },
    { lat: 47.979575, lng: 18.009225 },
    { lat: 47.979609, lng: 18.009272 },
    { lat: 47.979623, lng: 18.009284 },
    { lat: 47.979667, lng: 18.009322 },
    { lat: 47.979677, lng: 18.00933 },
    { lat: 47.97973, lng: 18.009338 },
    { lat: 47.979806, lng: 18.009348 },
    { lat: 47.979842, lng: 18.009322 },
    { lat: 47.980054, lng: 18.00917 },
    { lat: 47.98006, lng: 18.009165 },
    { lat: 47.980108, lng: 18.009149 },
    { lat: 47.980262, lng: 18.009102 },
    { lat: 47.980325, lng: 18.009098 },
    { lat: 47.980375, lng: 18.009096 },
    { lat: 47.980442, lng: 18.009092 },
    { lat: 47.980455, lng: 18.009095 },
    { lat: 47.980507, lng: 18.00911 },
    { lat: 47.980588, lng: 18.009134 },
    { lat: 47.980689, lng: 18.009184 },
    { lat: 47.980884, lng: 18.009283 },
    { lat: 47.981055, lng: 18.009368 },
    { lat: 47.981126, lng: 18.009404 },
    { lat: 47.981147, lng: 18.009403 },
    { lat: 47.9812, lng: 18.009402 },
    { lat: 47.981253, lng: 18.009401 },
    { lat: 47.981303, lng: 18.009399 },
    { lat: 47.981361, lng: 18.009363 },
    { lat: 47.981373, lng: 18.009354 },
    { lat: 47.981526, lng: 18.009239 },
    { lat: 47.981595, lng: 18.009297 },
    { lat: 47.981541, lng: 18.00934 },
    { lat: 47.981535, lng: 18.009343 },
    { lat: 47.981522, lng: 18.009351 },
    { lat: 47.981451, lng: 18.009663 },
    { lat: 47.981449, lng: 18.009672 },
    { lat: 47.981446, lng: 18.009681 },
    { lat: 47.981386, lng: 18.009944 },
    { lat: 47.981386, lng: 18.009959 },
    { lat: 47.981387, lng: 18.009975 },
    { lat: 47.981401, lng: 18.010448 },
    { lat: 47.981381, lng: 18.010489 },
    { lat: 47.981057, lng: 18.011169 },
    { lat: 47.98088, lng: 18.01154 },
    { lat: 47.980625, lng: 18.012077 },
    { lat: 47.980565, lng: 18.012203 },
    { lat: 47.980554, lng: 18.012213 },
    { lat: 47.979342, lng: 18.013254 },
    { lat: 47.97929, lng: 18.013299 },
    { lat: 47.978192, lng: 18.014657 },
    { lat: 47.978162, lng: 18.014686 },
    { lat: 47.977944, lng: 18.014907 },
    { lat: 47.977875, lng: 18.014977 },
    { lat: 47.977824, lng: 18.015027 },
    { lat: 47.977574, lng: 18.015279 },
    { lat: 47.976824, lng: 18.016036 },
    { lat: 47.975849, lng: 18.016203 },
    { lat: 47.975711, lng: 18.016227 },
    { lat: 47.975419, lng: 18.016094 },
    { lat: 47.97487, lng: 18.015845 },
    { lat: 47.974651, lng: 18.015746 },
    { lat: 47.974607, lng: 18.015725 },
    { lat: 47.974543, lng: 18.015696 },
    { lat: 47.974413, lng: 18.015708 },
    { lat: 47.974103, lng: 18.015734 },
    { lat: 47.973928, lng: 18.015749 },
    { lat: 47.97382, lng: 18.015758 },
    { lat: 47.973785, lng: 18.015795 },
    { lat: 47.973568, lng: 18.016023 },
    { lat: 47.973511, lng: 18.016083 },
    { lat: 47.973457, lng: 18.016139 },
    { lat: 47.973368, lng: 18.016233 },
    { lat: 47.973398, lng: 18.016464 },
    { lat: 47.973074, lng: 18.016978 },
    { lat: 47.972504, lng: 18.01788 },
    { lat: 47.972483, lng: 18.017911 },
    { lat: 47.972408, lng: 18.01803 },
    { lat: 47.972402, lng: 18.018025 },
    { lat: 47.9733486, lng: 18.0206207 },
    { lat: 47.9740081, lng: 18.0208553 },
    { lat: 47.9751681, lng: 18.0214711 },
    { lat: 47.9779136, lng: 18.0232178 },
    { lat: 47.9790026, lng: 18.0237593 },
    { lat: 47.9813748, lng: 18.0247753 },
    { lat: 47.9826356, lng: 18.0255497 },
    { lat: 47.982177, lng: 18.0270493 },
    { lat: 47.9816772, lng: 18.0283722 },
    { lat: 47.9813314, lng: 18.0295338 },
    { lat: 47.9812998, lng: 18.031195 },
    { lat: 47.9813504, lng: 18.031567 },
    { lat: 47.9818057, lng: 18.0331992 },
    { lat: 47.9822385, lng: 18.0355639 },
    { lat: 47.9824371, lng: 18.0362086 },
    { lat: 47.9827131, lng: 18.0368216 },
    { lat: 47.9829039, lng: 18.0370848 },
    { lat: 47.983851, lng: 18.0375066 },
    { lat: 47.9840821, lng: 18.0375006 },
    { lat: 47.9842398, lng: 18.0375696 },
    { lat: 47.9845507, lng: 18.037881 },
    { lat: 47.984355, lng: 18.0383258 },
    { lat: 47.9840115, lng: 18.0385299 },
    { lat: 47.9836923, lng: 18.0386067 },
    { lat: 47.9831167, lng: 18.0393087 },
    { lat: 47.9832445, lng: 18.0407674 },
    { lat: 47.9830105, lng: 18.041647 },
    { lat: 47.9825076, lng: 18.0429766 },
    { lat: 47.9824348, lng: 18.0431391 },
    { lat: 47.982802, lng: 18.0437125 },
    { lat: 47.9835914, lng: 18.0447972 },
    { lat: 47.9843744, lng: 18.0465901 },
    { lat: 47.984944, lng: 18.0491568 },
    { lat: 47.9867163, lng: 18.0537584 },
    { lat: 47.987258, lng: 18.0552565 },
    { lat: 47.9876389, lng: 18.0565516 },
    { lat: 47.9904165, lng: 18.0662008 },
    { lat: 47.9907888, lng: 18.0683194 },
    { lat: 47.9914348, lng: 18.0703769 },
    { lat: 47.991905, lng: 18.071642 },
    { lat: 47.9925406, lng: 18.0750813 },
    { lat: 47.993362, lng: 18.078875 },
    { lat: 47.9946796, lng: 18.0833133 },
    { lat: 47.9974142, lng: 18.0934741 },
    { lat: 47.9981588, lng: 18.0964219 },
    { lat: 48.0009646, lng: 18.1014202 },
    { lat: 48.0015108, lng: 18.1030603 },
    { lat: 48.0021186, lng: 18.1057793 },
    { lat: 48.0026636, lng: 18.1068689 },
    { lat: 48.0036095, lng: 18.1087603 },
    { lat: 48.0039611, lng: 18.1118225 },
    { lat: 48.0047517, lng: 18.1129035 },
    { lat: 48.0059708, lng: 18.1159272 },
    { lat: 48.0059172, lng: 18.1173709 },
    { lat: 48.0065998, lng: 18.1182251 },
    { lat: 48.0114296, lng: 18.1251647 },
    { lat: 48.013035, lng: 18.1272618 },
    { lat: 48.0148218, lng: 18.125199 },
    { lat: 48.0162127, lng: 18.1237384 },
    { lat: 48.0228524, lng: 18.1173378 },
    { lat: 48.024647, lng: 18.1152404 },
    { lat: 48.0256479, lng: 18.1138611 },
    { lat: 48.0269458, lng: 18.1121995 },
    { lat: 48.0269636, lng: 18.1121771 },
    { lat: 48.0262862, lng: 18.1107032 },
    { lat: 48.0245939, lng: 18.1075414 },
    { lat: 48.0231478, lng: 18.104478 },
    { lat: 48.0219774, lng: 18.1020944 },
    { lat: 48.0216623, lng: 18.1015531 },
    { lat: 48.0209701, lng: 18.0997543 },
    { lat: 48.0201653, lng: 18.0973482 },
    { lat: 48.0166711, lng: 18.0904494 },
    { lat: 48.0139834, lng: 18.0863848 },
    { lat: 48.0121508, lng: 18.0831962 },
    { lat: 48.0100149, lng: 18.0793252 },
    { lat: 48.0086032, lng: 18.0776351 },
    { lat: 48.0075899, lng: 18.076101 },
    { lat: 48.0044683, lng: 18.0722868 },
    { lat: 48.0013823, lng: 18.0635882 },
    { lat: 48.0008557, lng: 18.0626841 },
    { lat: 47.9993983, lng: 18.0593269 },
    { lat: 47.998774, lng: 18.0577319 },
    { lat: 47.9962473, lng: 18.0505189 },
    { lat: 47.9953329, lng: 18.0477549 },
    { lat: 47.9938694, lng: 18.0439987 },
    { lat: 47.9937776, lng: 18.0428002 },
    { lat: 47.993253, lng: 18.0411017 },
    { lat: 47.9927171, lng: 18.0389319 },
    { lat: 47.994036, lng: 18.0385476 },
    { lat: 47.9947153, lng: 18.0382074 },
    { lat: 47.9970336, lng: 18.0361119 },
    { lat: 47.9975382, lng: 18.035773 },
    { lat: 47.9992288, lng: 18.0350898 },
    { lat: 47.9999199, lng: 18.0349646 },
    { lat: 47.9998196, lng: 18.0341568 },
    { lat: 47.9992615, lng: 18.0316543 },
    { lat: 47.999532, lng: 18.0313844 },
    { lat: 48.00011, lng: 18.0309722 },
    { lat: 48.0012421, lng: 18.0300296 },
    { lat: 48.0020817, lng: 18.0291202 },
    { lat: 48.0032174, lng: 18.0272979 },
    { lat: 48.0036251, lng: 18.0272264 },
    { lat: 48.0045126, lng: 18.0254225 },
    { lat: 48.0053144, lng: 18.0236376 },
    { lat: 48.0056694, lng: 18.0217465 },
    { lat: 48.0064836, lng: 18.0202288 },
    { lat: 48.0073078, lng: 18.0190632 },
    { lat: 48.0082214, lng: 18.0183355 },
    { lat: 48.0095873, lng: 18.0179613 },
    { lat: 48.0103427, lng: 18.0170856 },
    { lat: 48.0109128, lng: 18.014957 },
    { lat: 48.0118071, lng: 18.0137974 },
    { lat: 48.0129259, lng: 18.0129949 },
    { lat: 48.0133837, lng: 18.0122123 },
    { lat: 48.013439, lng: 18.012008 },
    { lat: 48.013662, lng: 18.010475 },
    { lat: 48.01366, lng: 18.009685 },
    { lat: 48.013305, lng: 18.00919 },
    { lat: 48.013281, lng: 18.009188 },
    { lat: 48.01309, lng: 18.009156 },
    { lat: 48.012924, lng: 18.009036 },
    { lat: 48.012097, lng: 18.009298 },
    { lat: 48.011058, lng: 18.00985 },
    { lat: 48.011044, lng: 18.009857 },
    { lat: 48.011107, lng: 18.009158 },
    { lat: 48.010908, lng: 18.008944 },
    { lat: 48.010865, lng: 18.008907 },
    { lat: 48.010668, lng: 18.008827 },
    { lat: 48.010394, lng: 18.009004 },
    { lat: 48.010067, lng: 18.008695 },
    { lat: 48.009984, lng: 18.008612 },
    { lat: 48.00995, lng: 18.008579 },
    { lat: 48.00981, lng: 18.008442 },
    { lat: 48.009771, lng: 18.008405 },
    { lat: 48.009738, lng: 18.008372 },
    { lat: 48.009083, lng: 18.007726 },
    { lat: 48.008693, lng: 18.006349 },
    { lat: 48.008439, lng: 18.005401 },
    { lat: 48.008438, lng: 18.005385 },
    { lat: 48.008313, lng: 18.003214 },
    { lat: 48.00825, lng: 18.002512 },
    { lat: 48.008223, lng: 18.00222 },
    { lat: 48.00821, lng: 18.002081 },
    { lat: 48.008206, lng: 18.002021 },
    { lat: 48.007986, lng: 18.000584 },
    { lat: 48.007971, lng: 18.000471 },
    { lat: 48.007627, lng: 17.997246 },
    { lat: 48.007202, lng: 17.995374 },
    { lat: 48.007191, lng: 17.995325 },
    { lat: 48.007167, lng: 17.995205 },
    { lat: 48.006991, lng: 17.994329 },
    { lat: 48.006932, lng: 17.994029 },
    { lat: 48.006478, lng: 17.992458 },
    { lat: 48.006166, lng: 17.991222 },
    { lat: 48.005984, lng: 17.990501 },
    { lat: 48.005963, lng: 17.990425 },
    { lat: 48.005732, lng: 17.989623 },
    { lat: 48.005451, lng: 17.988584 },
    { lat: 48.00514, lng: 17.98789 },
    { lat: 48.005137, lng: 17.987882 },
    { lat: 48.005058, lng: 17.987685 },
    { lat: 48.004907, lng: 17.98744 },
    { lat: 48.004667, lng: 17.986765 },
    { lat: 48.004571, lng: 17.986444 },
    { lat: 48.004527, lng: 17.986158 },
    { lat: 48.004531, lng: 17.986004 },
    { lat: 48.00464, lng: 17.985703 },
    { lat: 48.004826, lng: 17.985411 },
    { lat: 48.005032, lng: 17.985113 },
    { lat: 48.005302, lng: 17.98484 },
    { lat: 48.005595, lng: 17.984539 },
    { lat: 48.00591, lng: 17.984142 },
    { lat: 48.005954, lng: 17.984066 },
    { lat: 48.006026, lng: 17.983935 },
    { lat: 48.006027, lng: 17.983743 },
    { lat: 48.005994, lng: 17.983501 },
    { lat: 48.005924, lng: 17.983353 },
    { lat: 48.005801, lng: 17.983234 },
    { lat: 48.005563, lng: 17.983137 },
    { lat: 48.005271, lng: 17.983129 },
    { lat: 48.005131, lng: 17.983205 },
    { lat: 48.004674, lng: 17.983565 },
    { lat: 48.004447, lng: 17.983893 },
    { lat: 48.004255, lng: 17.984206 },
    { lat: 48.004022, lng: 17.984802 },
    { lat: 48.003825, lng: 17.985389 },
    { lat: 48.003762, lng: 17.985661 },
    { lat: 48.003683, lng: 17.985946 },
    { lat: 48.003626, lng: 17.986155 },
    { lat: 48.003617, lng: 17.986199 },
    { lat: 48.00347, lng: 17.986928 },
    { lat: 48.003422, lng: 17.987194 },
    { lat: 48.003399, lng: 17.987327 },
    { lat: 48.003391, lng: 17.987361 },
    { lat: 48.003371, lng: 17.987467 },
    { lat: 48.003366, lng: 17.987495 },
    { lat: 48.003323, lng: 17.987739 },
    { lat: 48.00328, lng: 17.987988 },
    { lat: 48.00327, lng: 17.988042 },
    { lat: 48.003239, lng: 17.988202 },
    { lat: 48.003104, lng: 17.988636 },
    { lat: 48.003077, lng: 17.98872 },
    { lat: 48.002936, lng: 17.989075 },
    { lat: 48.002846, lng: 17.989262 },
    { lat: 48.002669, lng: 17.989492 },
    { lat: 48.002602, lng: 17.98957 },
    { lat: 48.002419, lng: 17.989656 },
    { lat: 48.002207, lng: 17.989679 },
    { lat: 48.002043, lng: 17.98967 },
    { lat: 48.00177, lng: 17.989526 },
    { lat: 48.001443, lng: 17.989304 },
    { lat: 48.001007, lng: 17.98898 },
    { lat: 48.000899, lng: 17.988895 },
    { lat: 48.000706, lng: 17.988665 },
    { lat: 48.000694, lng: 17.988651 },
    { lat: 48.000623, lng: 17.988568 },
    { lat: 48.0006, lng: 17.98854 },
    { lat: 48.000481, lng: 17.988413 },
    { lat: 48.000359, lng: 17.988272 },
    { lat: 48.000051, lng: 17.987916 },
    { lat: 47.998706, lng: 17.987938 },
    { lat: 47.998376, lng: 17.987625 },
    { lat: 47.998072, lng: 17.987339 },
    { lat: 47.997963, lng: 17.987237 },
    { lat: 47.997901, lng: 17.987178 },
    { lat: 47.997881, lng: 17.987159 },
    { lat: 47.997728, lng: 17.987014 },
    { lat: 47.997708, lng: 17.986994 },
    { lat: 47.997689, lng: 17.986976 },
    { lat: 47.997174, lng: 17.986497 },
    { lat: 47.995672, lng: 17.985143 },
    { lat: 47.995515, lng: 17.984886 },
    { lat: 47.994303, lng: 17.982894 },
    { lat: 47.994281, lng: 17.982864 },
    { lat: 47.993382, lng: 17.981678 },
    { lat: 47.993377, lng: 17.981671 },
    { lat: 47.99335, lng: 17.981635 },
    { lat: 47.993227, lng: 17.981474 },
    { lat: 47.993135, lng: 17.981351 },
    { lat: 47.992978, lng: 17.981146 },
    { lat: 47.992709, lng: 17.98079 },
    { lat: 47.992537, lng: 17.980563 },
    { lat: 47.992014, lng: 17.98008 },
    { lat: 47.99149, lng: 17.979597 },
    { lat: 47.991417, lng: 17.979528 },
    { lat: 47.991367, lng: 17.979478 },
    { lat: 47.991074, lng: 17.978836 },
    { lat: 47.990466, lng: 17.979425 },
    { lat: 47.989549, lng: 17.980273 },
    { lat: 47.988589, lng: 17.981059 },
    { lat: 47.987454, lng: 17.982251 },
    { lat: 47.986786, lng: 17.982996 },
    { lat: 47.986702, lng: 17.983139 },
    { lat: 47.986071, lng: 17.983536 },
    { lat: 47.985825, lng: 17.982682 },
    { lat: 47.98581, lng: 17.982633 },
    { lat: 47.985755, lng: 17.982436 },
    { lat: 47.985462, lng: 17.981546 },
    { lat: 47.985445, lng: 17.981502 },
    { lat: 47.985412, lng: 17.981399 },
    { lat: 47.984935, lng: 17.979957 },
    { lat: 47.984891, lng: 17.979826 },
    { lat: 47.984875, lng: 17.979779 },
    { lat: 47.984691, lng: 17.97922 },
    { lat: 47.984631, lng: 17.979043 },
    { lat: 47.984612, lng: 17.978985 },
    { lat: 47.984551, lng: 17.978801 },
    { lat: 47.984538, lng: 17.978764 },
    { lat: 47.984513, lng: 17.978692 },
    { lat: 47.98448, lng: 17.978597 },
    { lat: 47.984472, lng: 17.978571 },
    { lat: 47.984458, lng: 17.978534 },
    { lat: 47.984194, lng: 17.977784 },
    { lat: 47.983817, lng: 17.976651 },
    { lat: 47.983078, lng: 17.975912 },
    { lat: 47.981797, lng: 17.974545 },
    { lat: 47.980704, lng: 17.975557 },
    { lat: 47.980315, lng: 17.975138 },
    { lat: 47.980059, lng: 17.974945 },
    { lat: 47.979242, lng: 17.97415 },
    { lat: 47.979022, lng: 17.973585 },
    { lat: 47.978753, lng: 17.97291 },
    { lat: 47.978443, lng: 17.972008 },
    { lat: 47.977343, lng: 17.97096 },
    { lat: 47.976448, lng: 17.970103 },
    { lat: 47.975154, lng: 17.968969 },
    { lat: 47.975137, lng: 17.968955 },
    { lat: 47.97476, lng: 17.968508 },
    { lat: 47.974132, lng: 17.968414 },
    { lat: 47.973329, lng: 17.968753 },
    { lat: 47.972508, lng: 17.968972 },
    { lat: 47.970981, lng: 17.969433 },
    { lat: 47.969516, lng: 17.969447 },
    { lat: 47.968604, lng: 17.969826 },
    { lat: 47.967828, lng: 17.970036 },
    { lat: 47.965833, lng: 17.9707 },
    { lat: 47.964484, lng: 17.971165 },
    { lat: 47.963797, lng: 17.971321 },
    { lat: 47.96336, lng: 17.970876 },
    { lat: 47.963343, lng: 17.970858 },
    { lat: 47.962941, lng: 17.970447 },
  ],
  MichalnadŽitavou: [
    { lat: 48.193332, lng: 18.294303 },
    { lat: 48.193315, lng: 18.29408 },
    { lat: 48.193277, lng: 18.293621 },
    { lat: 48.193265, lng: 18.293461 },
    { lat: 48.193189, lng: 18.292474 },
    { lat: 48.193213, lng: 18.292277 },
    { lat: 48.193486, lng: 18.289291 },
    { lat: 48.193218, lng: 18.289193 },
    { lat: 48.193228, lng: 18.289135 },
    { lat: 48.193228, lng: 18.289132 },
    { lat: 48.193307, lng: 18.288494 },
    { lat: 48.193376, lng: 18.28793 },
    { lat: 48.193426, lng: 18.287533 },
    { lat: 48.193427, lng: 18.287528 },
    { lat: 48.193526, lng: 18.286751 },
    { lat: 48.193499, lng: 18.286156 },
    { lat: 48.193492, lng: 18.285981 },
    { lat: 48.193577, lng: 18.285662 },
    { lat: 48.193606, lng: 18.285552 },
    { lat: 48.193616, lng: 18.28548 },
    { lat: 48.193646, lng: 18.285183 },
    { lat: 48.193695, lng: 18.284539 },
    { lat: 48.193688, lng: 18.284523 },
    { lat: 48.193675, lng: 18.284498 },
    { lat: 48.193674, lng: 18.284288 },
    { lat: 48.193681, lng: 18.283861 },
    { lat: 48.193687, lng: 18.283521 },
    { lat: 48.193698, lng: 18.282962 },
    { lat: 48.193703, lng: 18.282668 },
    { lat: 48.193709, lng: 18.28214 },
    { lat: 48.193709, lng: 18.282134 },
    { lat: 48.19371, lng: 18.281345 },
    { lat: 48.193709, lng: 18.280945 },
    { lat: 48.19371, lng: 18.280934 },
    { lat: 48.193712, lng: 18.280834 },
    { lat: 48.193977, lng: 18.280739 },
    { lat: 48.193978, lng: 18.280716 },
    { lat: 48.193982, lng: 18.280405 },
    { lat: 48.193984, lng: 18.280305 },
    { lat: 48.193997, lng: 18.279988 },
    { lat: 48.194018, lng: 18.279509 },
    { lat: 48.194055, lng: 18.278981 },
    { lat: 48.19409, lng: 18.278442 },
    { lat: 48.1941, lng: 18.278297 },
    { lat: 48.194172, lng: 18.277599 },
    { lat: 48.1941939, lng: 18.2774038 },
    { lat: 48.194216, lng: 18.277207 },
    { lat: 48.194253, lng: 18.276814 },
    { lat: 48.194261, lng: 18.276742 },
    { lat: 48.194301, lng: 18.276416 },
    { lat: 48.194349, lng: 18.276023 },
    { lat: 48.1943671, lng: 18.2758637 },
    { lat: 48.194427, lng: 18.275337 },
    { lat: 48.19447, lng: 18.274961 },
    { lat: 48.1944984, lng: 18.2748008 },
    { lat: 48.194612, lng: 18.274161 },
    { lat: 48.194694, lng: 18.273674 },
    { lat: 48.19473, lng: 18.273465 },
    { lat: 48.19481, lng: 18.273081 },
    { lat: 48.194867, lng: 18.272843 },
    { lat: 48.194953, lng: 18.272474 },
    { lat: 48.1950252, lng: 18.2721987 },
    { lat: 48.195076, lng: 18.272005 },
    { lat: 48.195083, lng: 18.271976 },
    { lat: 48.195193, lng: 18.271117 },
    { lat: 48.195272, lng: 18.270882 },
    { lat: 48.195364, lng: 18.270604 },
    { lat: 48.195399, lng: 18.270496 },
    { lat: 48.195454, lng: 18.270318 },
    { lat: 48.195609, lng: 18.269825 },
    { lat: 48.195838, lng: 18.269105 },
    { lat: 48.19604, lng: 18.268475 },
    { lat: 48.19635, lng: 18.26749 },
    { lat: 48.196412, lng: 18.26728 },
    { lat: 48.196445, lng: 18.267171 },
    { lat: 48.196483, lng: 18.267146 },
    { lat: 48.196504, lng: 18.266865 },
    { lat: 48.196531, lng: 18.266546 },
    { lat: 48.196563, lng: 18.266178 },
    { lat: 48.196562, lng: 18.265827 },
    { lat: 48.196605, lng: 18.265615 },
    { lat: 48.196645, lng: 18.265468 },
    { lat: 48.196768, lng: 18.265132 },
    { lat: 48.196834, lng: 18.264951 },
    { lat: 48.196957, lng: 18.264618 },
    { lat: 48.197035, lng: 18.264409 },
    { lat: 48.197137, lng: 18.26407 },
    { lat: 48.197207, lng: 18.263816 },
    { lat: 48.197312, lng: 18.263348 },
    { lat: 48.197427, lng: 18.262979 },
    { lat: 48.19749, lng: 18.262798 },
    { lat: 48.197552, lng: 18.262598 },
    { lat: 48.197583, lng: 18.262463 },
    { lat: 48.197595, lng: 18.262329 },
    { lat: 48.197581, lng: 18.261831 },
    { lat: 48.197604, lng: 18.261685 },
    { lat: 48.197796, lng: 18.261285 },
    { lat: 48.197935, lng: 18.260956 },
    { lat: 48.198065, lng: 18.260634 },
    { lat: 48.198178, lng: 18.260392 },
    { lat: 48.19832, lng: 18.260125 },
    { lat: 48.198407, lng: 18.259973 },
    { lat: 48.198478, lng: 18.259851 },
    { lat: 48.198581, lng: 18.259639 },
    { lat: 48.198747, lng: 18.259258 },
    { lat: 48.198952, lng: 18.25891 },
    { lat: 48.199094, lng: 18.258688 },
    { lat: 48.199261, lng: 18.25846 },
    { lat: 48.199275, lng: 18.258439 },
    { lat: 48.199377, lng: 18.258292 },
    { lat: 48.199532, lng: 18.258049 },
    { lat: 48.199697, lng: 18.257801 },
    { lat: 48.199774, lng: 18.257689 },
    { lat: 48.199833, lng: 18.257605 },
    { lat: 48.200031, lng: 18.257329 },
    { lat: 48.200107, lng: 18.257247 },
    { lat: 48.200435, lng: 18.256905 },
    { lat: 48.200521, lng: 18.256813 },
    { lat: 48.200625, lng: 18.256689 },
    { lat: 48.200681, lng: 18.256617 },
    { lat: 48.200695, lng: 18.256593 },
    { lat: 48.200731, lng: 18.256531 },
    { lat: 48.200801, lng: 18.256408 },
    { lat: 48.200998, lng: 18.25609 },
    { lat: 48.20117, lng: 18.25581 },
    { lat: 48.201332, lng: 18.255575 },
    { lat: 48.201392, lng: 18.255484 },
    { lat: 48.201539, lng: 18.255282 },
    { lat: 48.201634, lng: 18.255164 },
    { lat: 48.201933, lng: 18.254754 },
    { lat: 48.202126, lng: 18.254444 },
    { lat: 48.202255, lng: 18.25418 },
    { lat: 48.202339, lng: 18.254001 },
    { lat: 48.202428, lng: 18.253793 },
    { lat: 48.202433, lng: 18.253778 },
    { lat: 48.202523, lng: 18.253531 },
    { lat: 48.202569, lng: 18.253406 },
    { lat: 48.202593, lng: 18.253248 },
    { lat: 48.202667, lng: 18.252825 },
    { lat: 48.202687, lng: 18.252679 },
    { lat: 48.202698, lng: 18.252583 },
    { lat: 48.202737, lng: 18.252457 },
    { lat: 48.202699, lng: 18.252357 },
    { lat: 48.202833, lng: 18.252083 },
    { lat: 48.202834, lng: 18.25208 },
    { lat: 48.202971, lng: 18.251774 },
    { lat: 48.203086, lng: 18.2516 },
    { lat: 48.203138, lng: 18.25152 },
    { lat: 48.203276, lng: 18.25131 },
    { lat: 48.203393, lng: 18.251131 },
    { lat: 48.203425, lng: 18.251082 },
    { lat: 48.203555, lng: 18.250884 },
    { lat: 48.203654, lng: 18.250733 },
    { lat: 48.203749, lng: 18.250585 },
    { lat: 48.20383, lng: 18.250461 },
    { lat: 48.20401, lng: 18.250186 },
    { lat: 48.204051, lng: 18.250124 },
    { lat: 48.204194, lng: 18.249905 },
    { lat: 48.20435, lng: 18.249666 },
    { lat: 48.20438, lng: 18.249621 },
    { lat: 48.204553, lng: 18.249361 },
    { lat: 48.204571, lng: 18.249331 },
    { lat: 48.20473, lng: 18.249085 },
    { lat: 48.204765, lng: 18.249035 },
    { lat: 48.204856, lng: 18.248901 },
    { lat: 48.204935, lng: 18.248708 },
    { lat: 48.205027, lng: 18.248482 },
    { lat: 48.205077, lng: 18.248362 },
    { lat: 48.20522, lng: 18.248022 },
    { lat: 48.205236, lng: 18.247984 },
    { lat: 48.205361, lng: 18.247688 },
    { lat: 48.205492, lng: 18.247378 },
    { lat: 48.2055, lng: 18.247357 },
    { lat: 48.205637, lng: 18.247031 },
    { lat: 48.205693, lng: 18.2469 },
    { lat: 48.205774, lng: 18.246709 },
    { lat: 48.205917, lng: 18.246372 },
    { lat: 48.205917, lng: 18.24637 },
    { lat: 48.206087, lng: 18.24597 },
    { lat: 48.206243, lng: 18.245602 },
    { lat: 48.206337, lng: 18.24538 },
    { lat: 48.206559, lng: 18.244856 },
    { lat: 48.206784, lng: 18.244334 },
    { lat: 48.206851, lng: 18.244199 },
    { lat: 48.206682, lng: 18.243723 },
    { lat: 48.206332, lng: 18.242777 },
    { lat: 48.206316, lng: 18.242738 },
    { lat: 48.206136, lng: 18.242405 },
    { lat: 48.205746, lng: 18.241679 },
    { lat: 48.205292, lng: 18.240836 },
    { lat: 48.204843, lng: 18.239999 },
    { lat: 48.204384, lng: 18.23914 },
    { lat: 48.204358, lng: 18.239076 },
    { lat: 48.204279, lng: 18.239193 },
    { lat: 48.204135, lng: 18.239419 },
    { lat: 48.203977, lng: 18.239668 },
    { lat: 48.203782, lng: 18.23997 },
    { lat: 48.203561, lng: 18.240319 },
    { lat: 48.203439, lng: 18.240505 },
    { lat: 48.203317, lng: 18.240687 },
    { lat: 48.203109, lng: 18.240996 },
    { lat: 48.203006, lng: 18.241148 },
    { lat: 48.202915, lng: 18.24128 },
    { lat: 48.202777, lng: 18.241484 },
    { lat: 48.202587, lng: 18.241593 },
    { lat: 48.202277, lng: 18.241776 },
    { lat: 48.202009, lng: 18.241935 },
    { lat: 48.201812, lng: 18.242051 },
    { lat: 48.201526, lng: 18.242121 },
    { lat: 48.201233, lng: 18.242196 },
    { lat: 48.200946, lng: 18.24227 },
    { lat: 48.200709, lng: 18.24233 },
    { lat: 48.200458, lng: 18.242459 },
    { lat: 48.200137, lng: 18.242628 },
    { lat: 48.199869, lng: 18.242769 },
    { lat: 48.199638, lng: 18.242888 },
    { lat: 48.199541, lng: 18.242916 },
    { lat: 48.199421, lng: 18.242953 },
    { lat: 48.199335, lng: 18.242986 },
    { lat: 48.198658, lng: 18.243247 },
    { lat: 48.19841, lng: 18.243337 },
    { lat: 48.197818, lng: 18.243733 },
    { lat: 48.197334, lng: 18.244059 },
    { lat: 48.196816, lng: 18.244406 },
    { lat: 48.19666, lng: 18.24451 },
    { lat: 48.196264, lng: 18.244775 },
    { lat: 48.195817, lng: 18.245077 },
    { lat: 48.195365, lng: 18.245378 },
    { lat: 48.195246, lng: 18.245455 },
    { lat: 48.194803, lng: 18.245803 },
    { lat: 48.194509, lng: 18.246031 },
    { lat: 48.194245, lng: 18.246233 },
    { lat: 48.193836, lng: 18.246093 },
    { lat: 48.193631, lng: 18.246026 },
    { lat: 48.193261, lng: 18.245783 },
    { lat: 48.192934, lng: 18.245571 },
    { lat: 48.192483, lng: 18.24558 },
    { lat: 48.191895, lng: 18.245591 },
    { lat: 48.191255, lng: 18.245603 },
    { lat: 48.19117, lng: 18.245605 },
    { lat: 48.190813, lng: 18.245614 },
    { lat: 48.190501, lng: 18.245617 },
    { lat: 48.19003, lng: 18.245669 },
    { lat: 48.189549, lng: 18.245717 },
    { lat: 48.18926, lng: 18.245782 },
    { lat: 48.188875, lng: 18.245871 },
    { lat: 48.188414, lng: 18.245981 },
    { lat: 48.188154, lng: 18.24604 },
    { lat: 48.187385, lng: 18.246218 },
    { lat: 48.187352, lng: 18.246229 },
    { lat: 48.186831, lng: 18.246177 },
    { lat: 48.186562, lng: 18.24614 },
    { lat: 48.1865, lng: 18.246132 },
    { lat: 48.186434, lng: 18.246123 },
    { lat: 48.186312, lng: 18.246106 },
    { lat: 48.186186, lng: 18.246088 },
    { lat: 48.18614, lng: 18.246082 },
    { lat: 48.185892, lng: 18.246037 },
    { lat: 48.185741, lng: 18.246011 },
    { lat: 48.185594, lng: 18.245986 },
    { lat: 48.185447, lng: 18.245959 },
    { lat: 48.185285, lng: 18.245928 },
    { lat: 48.185231, lng: 18.245934 },
    { lat: 48.185175, lng: 18.245933 },
    { lat: 48.185127, lng: 18.245939 },
    { lat: 48.184934, lng: 18.245947 },
    { lat: 48.184762, lng: 18.245958 },
    { lat: 48.18473, lng: 18.245966 },
    { lat: 48.184693, lng: 18.245974 },
    { lat: 48.184586, lng: 18.246001 },
    { lat: 48.184537, lng: 18.246009 },
    { lat: 48.184507, lng: 18.246015 },
    { lat: 48.184384, lng: 18.246044 },
    { lat: 48.184342, lng: 18.245994 },
    { lat: 48.184307, lng: 18.245962 },
    { lat: 48.1839412, lng: 18.246335 },
    { lat: 48.1830395, lng: 18.2476244 },
    { lat: 48.1827406, lng: 18.2483291 },
    { lat: 48.1821058, lng: 18.2492157 },
    { lat: 48.1814173, lng: 18.2499575 },
    { lat: 48.1805114, lng: 18.2510767 },
    { lat: 48.179705, lng: 18.2522183 },
    { lat: 48.1791904, lng: 18.252763 },
    { lat: 48.1790902, lng: 18.2528091 },
    { lat: 48.1791528, lng: 18.2539529 },
    { lat: 48.1791115, lng: 18.2544083 },
    { lat: 48.179021, lng: 18.2555011 },
    { lat: 48.1788564, lng: 18.2565433 },
    { lat: 48.1788519, lng: 18.2581111 },
    { lat: 48.1780023, lng: 18.261911 },
    { lat: 48.1764968, lng: 18.2678275 },
    { lat: 48.1760892, lng: 18.2700812 },
    { lat: 48.1758147, lng: 18.2719201 },
    { lat: 48.1757101, lng: 18.272987 },
    { lat: 48.1753994, lng: 18.2793055 },
    { lat: 48.1754492, lng: 18.2794646 },
    { lat: 48.1754638, lng: 18.2812507 },
    { lat: 48.1752758, lng: 18.2812784 },
    { lat: 48.1749887, lng: 18.2820738 },
    { lat: 48.174962, lng: 18.2823778 },
    { lat: 48.1750075, lng: 18.2827763 },
    { lat: 48.1747544, lng: 18.2838117 },
    { lat: 48.1749942, lng: 18.2848838 },
    { lat: 48.174962, lng: 18.2854861 },
    { lat: 48.174903, lng: 18.2860684 },
    { lat: 48.174674, lng: 18.2869932 },
    { lat: 48.1753362, lng: 18.2880515 },
    { lat: 48.175418, lng: 18.2888527 },
    { lat: 48.1754858, lng: 18.2898738 },
    { lat: 48.1753895, lng: 18.2899101 },
    { lat: 48.1753876, lng: 18.2899719 },
    { lat: 48.175486, lng: 18.2904507 },
    { lat: 48.1752643, lng: 18.2906173 },
    { lat: 48.1749928, lng: 18.2910572 },
    { lat: 48.1749892, lng: 18.291134 },
    { lat: 48.1751031, lng: 18.2915252 },
    { lat: 48.1755779, lng: 18.2913852 },
    { lat: 48.1756146, lng: 18.2915307 },
    { lat: 48.1756099, lng: 18.2916052 },
    { lat: 48.1756569, lng: 18.2916416 },
    { lat: 48.1758568, lng: 18.2917016 },
    { lat: 48.1759526, lng: 18.2918795 },
    { lat: 48.1758799, lng: 18.2920673 },
    { lat: 48.1758732, lng: 18.292562 },
    { lat: 48.1759254, lng: 18.2926062 },
    { lat: 48.1761975, lng: 18.292192 },
    { lat: 48.1763021, lng: 18.2921893 },
    { lat: 48.1765006, lng: 18.2922451 },
    { lat: 48.1765295, lng: 18.2923167 },
    { lat: 48.1765207, lng: 18.2923981 },
    { lat: 48.1763958, lng: 18.2925414 },
    { lat: 48.1764218, lng: 18.2928177 },
    { lat: 48.1766152, lng: 18.2928265 },
    { lat: 48.1767572, lng: 18.2926891 },
    { lat: 48.1768743, lng: 18.2927805 },
    { lat: 48.17698, lng: 18.2930314 },
    { lat: 48.1768504, lng: 18.2931728 },
    { lat: 48.1767436, lng: 18.2936899 },
    { lat: 48.1767789, lng: 18.2937939 },
    { lat: 48.176884, lng: 18.2938784 },
    { lat: 48.1771279, lng: 18.2933858 },
    { lat: 48.1772324, lng: 18.2933031 },
    { lat: 48.1773166, lng: 18.2933114 },
    { lat: 48.177392, lng: 18.2935669 },
    { lat: 48.1774688, lng: 18.2935662 },
    { lat: 48.1775865, lng: 18.2931199 },
    { lat: 48.177639, lng: 18.2930704 },
    { lat: 48.1778427, lng: 18.2932992 },
    { lat: 48.17788, lng: 18.2935373 },
    { lat: 48.1780028, lng: 18.2937502 },
    { lat: 48.1780655, lng: 18.2937581 },
    { lat: 48.1783243, lng: 18.2935719 },
    { lat: 48.1783968, lng: 18.2933791 },
    { lat: 48.1782553, lng: 18.2930565 },
    { lat: 48.1784368, lng: 18.2923661 },
    { lat: 48.1785207, lng: 18.2922539 },
    { lat: 48.1786283, lng: 18.2922419 },
    { lat: 48.1789364, lng: 18.2923979 },
    { lat: 48.1790491, lng: 18.2923393 },
    { lat: 48.1792216, lng: 18.2920983 },
    { lat: 48.1790936, lng: 18.2918323 },
    { lat: 48.1790535, lng: 18.2916851 },
    { lat: 48.1790745, lng: 18.2915989 },
    { lat: 48.1791775, lng: 18.2915267 },
    { lat: 48.1793868, lng: 18.2916929 },
    { lat: 48.1795384, lng: 18.2915038 },
    { lat: 48.1793086, lng: 18.2912348 },
    { lat: 48.1792787, lng: 18.2911468 },
    { lat: 48.1793315, lng: 18.2910452 },
    { lat: 48.1797925, lng: 18.2910565 },
    { lat: 48.179862, lng: 18.2909857 },
    { lat: 48.1799017, lng: 18.2907763 },
    { lat: 48.1798065, lng: 18.2905912 },
    { lat: 48.1798326, lng: 18.2903369 },
    { lat: 48.1799829, lng: 18.2897499 },
    { lat: 48.1801244, lng: 18.28962 },
    { lat: 48.1801817, lng: 18.2896385 },
    { lat: 48.1802353, lng: 18.2897854 },
    { lat: 48.1802215, lng: 18.2899576 },
    { lat: 48.1802934, lng: 18.2903043 },
    { lat: 48.1807257, lng: 18.2907595 },
    { lat: 48.1808767, lng: 18.2904025 },
    { lat: 48.1810048, lng: 18.2902369 },
    { lat: 48.1811991, lng: 18.290773 },
    { lat: 48.1815027, lng: 18.2912468 },
    { lat: 48.1815508, lng: 18.2914548 },
    { lat: 48.1815491, lng: 18.2918797 },
    { lat: 48.1817677, lng: 18.2919521 },
    { lat: 48.1819548, lng: 18.2919072 },
    { lat: 48.1821176, lng: 18.2917156 },
    { lat: 48.1821836, lng: 18.2918079 },
    { lat: 48.18213, lng: 18.292062 },
    { lat: 48.182185, lng: 18.292223 },
    { lat: 48.182192, lng: 18.292465 },
    { lat: 48.182194, lng: 18.292546 },
    { lat: 48.182164, lng: 18.292622 },
    { lat: 48.182176, lng: 18.292701 },
    { lat: 48.182384, lng: 18.292906 },
    { lat: 48.182379, lng: 18.292992 },
    { lat: 48.18236, lng: 18.293058 },
    { lat: 48.182321, lng: 18.293186 },
    { lat: 48.182346, lng: 18.293346 },
    { lat: 48.182408, lng: 18.293428 },
    { lat: 48.182551, lng: 18.29356 },
    { lat: 48.182684, lng: 18.293642 },
    { lat: 48.182709, lng: 18.293667 },
    { lat: 48.182724, lng: 18.293782 },
    { lat: 48.182797, lng: 18.293848 },
    { lat: 48.182856, lng: 18.293872 },
    { lat: 48.182891, lng: 18.293844 },
    { lat: 48.182932, lng: 18.293767 },
    { lat: 48.18335, lng: 18.293785 },
    { lat: 48.183397, lng: 18.293789 },
    { lat: 48.183428, lng: 18.294017 },
    { lat: 48.183493, lng: 18.29412 },
    { lat: 48.183545, lng: 18.294153 },
    { lat: 48.183643, lng: 18.293889 },
    { lat: 48.183722, lng: 18.293846 },
    { lat: 48.183812, lng: 18.293842 },
    { lat: 48.183917, lng: 18.293839 },
    { lat: 48.183943, lng: 18.293786 },
    { lat: 48.183969, lng: 18.293729 },
    { lat: 48.183999, lng: 18.293666 },
    { lat: 48.18402, lng: 18.29362 },
    { lat: 48.184051, lng: 18.293549 },
    { lat: 48.184074, lng: 18.293456 },
    { lat: 48.184091, lng: 18.293384 },
    { lat: 48.184106, lng: 18.293325 },
    { lat: 48.18412, lng: 18.293269 },
    { lat: 48.184135, lng: 18.29321 },
    { lat: 48.184154, lng: 18.293125 },
    { lat: 48.184204, lng: 18.29307 },
    { lat: 48.184281, lng: 18.293046 },
    { lat: 48.184409, lng: 18.293042 },
    { lat: 48.184445, lng: 18.293042 },
    { lat: 48.184526, lng: 18.293082 },
    { lat: 48.184512, lng: 18.29316 },
    { lat: 48.184457, lng: 18.293202 },
    { lat: 48.184365, lng: 18.29329 },
    { lat: 48.184335, lng: 18.293367 },
    { lat: 48.184316, lng: 18.293421 },
    { lat: 48.184296, lng: 18.29347 },
    { lat: 48.184277, lng: 18.293522 },
    { lat: 48.184253, lng: 18.293579 },
    { lat: 48.184203, lng: 18.293701 },
    { lat: 48.184211, lng: 18.293779 },
    { lat: 48.184219, lng: 18.293855 },
    { lat: 48.184191, lng: 18.293918 },
    { lat: 48.184169, lng: 18.293969 },
    { lat: 48.184146, lng: 18.294026 },
    { lat: 48.184123, lng: 18.294086 },
    { lat: 48.1841, lng: 18.294241 },
    { lat: 48.184119, lng: 18.29441 },
    { lat: 48.18418, lng: 18.294648 },
    { lat: 48.184243, lng: 18.2947 },
    { lat: 48.1843, lng: 18.294713 },
    { lat: 48.184373, lng: 18.294678 },
    { lat: 48.184417, lng: 18.294626 },
    { lat: 48.184515, lng: 18.29445 },
    { lat: 48.184515, lng: 18.294395 },
    { lat: 48.184513, lng: 18.294315 },
    { lat: 48.184528, lng: 18.294253 },
    { lat: 48.184541, lng: 18.294199 },
    { lat: 48.184547, lng: 18.294178 },
    { lat: 48.184558, lng: 18.294135 },
    { lat: 48.184576, lng: 18.294077 },
    { lat: 48.18467, lng: 18.293854 },
    { lat: 48.184711, lng: 18.293814 },
    { lat: 48.184748, lng: 18.293787 },
    { lat: 48.184789, lng: 18.29376 },
    { lat: 48.18485, lng: 18.293757 },
    { lat: 48.184908, lng: 18.29382 },
    { lat: 48.184924, lng: 18.293864 },
    { lat: 48.184951, lng: 18.293942 },
    { lat: 48.18495, lng: 18.293989 },
    { lat: 48.184948, lng: 18.294041 },
    { lat: 48.184947, lng: 18.294078 },
    { lat: 48.184966, lng: 18.294164 },
    { lat: 48.184998, lng: 18.294237 },
    { lat: 48.184997, lng: 18.294383 },
    { lat: 48.184975, lng: 18.294443 },
    { lat: 48.184956, lng: 18.2945 },
    { lat: 48.184951, lng: 18.294515 },
    { lat: 48.184955, lng: 18.294557 },
    { lat: 48.184964, lng: 18.294634 },
    { lat: 48.184974, lng: 18.294716 },
    { lat: 48.184977, lng: 18.294741 },
    { lat: 48.185031, lng: 18.294856 },
    { lat: 48.185078, lng: 18.294884 },
    { lat: 48.185123, lng: 18.294839 },
    { lat: 48.185135, lng: 18.294818 },
    { lat: 48.185163, lng: 18.294762 },
    { lat: 48.185185, lng: 18.294718 },
    { lat: 48.185205, lng: 18.294712 },
    { lat: 48.185246, lng: 18.294697 },
    { lat: 48.185339, lng: 18.294732 },
    { lat: 48.185472, lng: 18.294829 },
    { lat: 48.185506, lng: 18.294855 },
    { lat: 48.18552, lng: 18.294886 },
    { lat: 48.185548, lng: 18.294942 },
    { lat: 48.185546, lng: 18.294967 },
    { lat: 48.185545, lng: 18.294997 },
    { lat: 48.185541, lng: 18.295048 },
    { lat: 48.185532, lng: 18.295153 },
    { lat: 48.185583, lng: 18.295328 },
    { lat: 48.185667, lng: 18.295429 },
    { lat: 48.185787, lng: 18.295415 },
    { lat: 48.185943, lng: 18.295292 },
    { lat: 48.185964, lng: 18.295267 },
    { lat: 48.185991, lng: 18.295233 },
    { lat: 48.186002, lng: 18.295218 },
    { lat: 48.185997, lng: 18.295192 },
    { lat: 48.185981, lng: 18.29512 },
    { lat: 48.185984, lng: 18.295073 },
    { lat: 48.18599, lng: 18.295011 },
    { lat: 48.186026, lng: 18.29497 },
    { lat: 48.186043, lng: 18.294952 },
    { lat: 48.186139, lng: 18.294783 },
    { lat: 48.186219, lng: 18.294794 },
    { lat: 48.186226, lng: 18.294822 },
    { lat: 48.186294, lng: 18.295071 },
    { lat: 48.186328, lng: 18.295152 },
    { lat: 48.186368, lng: 18.295171 },
    { lat: 48.186401, lng: 18.295174 },
    { lat: 48.186499, lng: 18.295185 },
    { lat: 48.186531, lng: 18.295233 },
    { lat: 48.186561, lng: 18.295308 },
    { lat: 48.186575, lng: 18.295343 },
    { lat: 48.186585, lng: 18.295363 },
    { lat: 48.18659, lng: 18.295374 },
    { lat: 48.186657, lng: 18.295394 },
    { lat: 48.186724, lng: 18.295376 },
    { lat: 48.186834, lng: 18.295343 },
    { lat: 48.186838, lng: 18.295337 },
    { lat: 48.186877, lng: 18.295283 },
    { lat: 48.187016, lng: 18.295056 },
    { lat: 48.187025, lng: 18.295047 },
    { lat: 48.187037, lng: 18.295039 },
    { lat: 48.187052, lng: 18.295028 },
    { lat: 48.187066, lng: 18.295017 },
    { lat: 48.187081, lng: 18.295006 },
    { lat: 48.187144, lng: 18.294962 },
    { lat: 48.187182, lng: 18.294935 },
    { lat: 48.187337, lng: 18.294927 },
    { lat: 48.18737, lng: 18.294939 },
    { lat: 48.187383, lng: 18.294944 },
    { lat: 48.187401, lng: 18.294971 },
    { lat: 48.187472, lng: 18.295072 },
    { lat: 48.187558, lng: 18.295213 },
    { lat: 48.18759, lng: 18.295209 },
    { lat: 48.187593, lng: 18.295207 },
    { lat: 48.187633, lng: 18.295132 },
    { lat: 48.187694, lng: 18.29507 },
    { lat: 48.187698, lng: 18.295068 },
    { lat: 48.187761, lng: 18.295054 },
    { lat: 48.187801, lng: 18.295055 },
    { lat: 48.187848, lng: 18.295056 },
    { lat: 48.187854, lng: 18.295056 },
    { lat: 48.18794, lng: 18.295057 },
    { lat: 48.187946, lng: 18.295057 },
    { lat: 48.188033, lng: 18.295038 },
    { lat: 48.188083, lng: 18.295016 },
    { lat: 48.18814, lng: 18.295014 },
    { lat: 48.188177, lng: 18.29506 },
    { lat: 48.188187, lng: 18.295252 },
    { lat: 48.188245, lng: 18.295435 },
    { lat: 48.188286, lng: 18.295497 },
    { lat: 48.188335, lng: 18.295528 },
    { lat: 48.188387, lng: 18.295536 },
    { lat: 48.188408, lng: 18.295539 },
    { lat: 48.188441, lng: 18.295528 },
    { lat: 48.188452, lng: 18.295525 },
    { lat: 48.188493, lng: 18.295461 },
    { lat: 48.188559, lng: 18.295295 },
    { lat: 48.188601, lng: 18.295251 },
    { lat: 48.188625, lng: 18.295269 },
    { lat: 48.188639, lng: 18.29532 },
    { lat: 48.188681, lng: 18.295364 },
    { lat: 48.188763, lng: 18.295376 },
    { lat: 48.188923, lng: 18.2954 },
    { lat: 48.189069, lng: 18.295454 },
    { lat: 48.189123, lng: 18.295487 },
    { lat: 48.189211, lng: 18.295543 },
    { lat: 48.18924, lng: 18.295561 },
    { lat: 48.189299, lng: 18.295619 },
    { lat: 48.189321, lng: 18.295642 },
    { lat: 48.189335, lng: 18.295676 },
    { lat: 48.189359, lng: 18.295736 },
    { lat: 48.189378, lng: 18.295783 },
    { lat: 48.189422, lng: 18.295788 },
    { lat: 48.18948, lng: 18.295777 },
    { lat: 48.189541, lng: 18.29575 },
    { lat: 48.18962, lng: 18.295693 },
    { lat: 48.189679, lng: 18.295627 },
    { lat: 48.189714, lng: 18.295585 },
    { lat: 48.189747, lng: 18.295545 },
    { lat: 48.18978, lng: 18.295535 },
    { lat: 48.189797, lng: 18.295527 },
    { lat: 48.189815, lng: 18.29552 },
    { lat: 48.189877, lng: 18.295539 },
    { lat: 48.189917, lng: 18.295571 },
    { lat: 48.189966, lng: 18.295617 },
    { lat: 48.190174, lng: 18.295608 },
    { lat: 48.190298, lng: 18.295619 },
    { lat: 48.190455, lng: 18.295621 },
    { lat: 48.190534, lng: 18.29555 },
    { lat: 48.190598, lng: 18.295472 },
    { lat: 48.190615, lng: 18.295449 },
    { lat: 48.190632, lng: 18.295426 },
    { lat: 48.190667, lng: 18.295379 },
    { lat: 48.190689, lng: 18.295361 },
    { lat: 48.190712, lng: 18.295347 },
    { lat: 48.190758, lng: 18.295319 },
    { lat: 48.190859, lng: 18.29531 },
    { lat: 48.190915, lng: 18.295316 },
    { lat: 48.190932, lng: 18.295325 },
    { lat: 48.191025, lng: 18.295374 },
    { lat: 48.191049, lng: 18.295387 },
    { lat: 48.191053, lng: 18.295394 },
    { lat: 48.191116, lng: 18.295509 },
    { lat: 48.191126, lng: 18.29554 },
    { lat: 48.191153, lng: 18.295635 },
    { lat: 48.191152, lng: 18.295837 },
    { lat: 48.191187, lng: 18.295911 },
    { lat: 48.191302, lng: 18.295956 },
    { lat: 48.191317, lng: 18.295961 },
    { lat: 48.19141, lng: 18.295992 },
    { lat: 48.191428, lng: 18.295993 },
    { lat: 48.191495, lng: 18.295999 },
    { lat: 48.191546, lng: 18.295904 },
    { lat: 48.191649, lng: 18.295713 },
    { lat: 48.191727, lng: 18.295564 },
    { lat: 48.191745, lng: 18.295528 },
    { lat: 48.191764, lng: 18.295491 },
    { lat: 48.191802, lng: 18.295418 },
    { lat: 48.191964, lng: 18.295111 },
    { lat: 48.192031, lng: 18.295052 },
    { lat: 48.192106, lng: 18.295055 },
    { lat: 48.192364, lng: 18.295439 },
    { lat: 48.192369, lng: 18.295447 },
    { lat: 48.192407, lng: 18.295505 },
    { lat: 48.192437, lng: 18.295525 },
    { lat: 48.192503, lng: 18.295495 },
    { lat: 48.192511, lng: 18.29549 },
    { lat: 48.192512, lng: 18.295486 },
    { lat: 48.19253, lng: 18.295411 },
    { lat: 48.192585, lng: 18.295149 },
    { lat: 48.192627, lng: 18.294998 },
    { lat: 48.192645, lng: 18.294961 },
    { lat: 48.192714, lng: 18.294818 },
    { lat: 48.192758, lng: 18.294722 },
    { lat: 48.192792, lng: 18.294659 },
    { lat: 48.192811, lng: 18.294623 },
    { lat: 48.192818, lng: 18.294609 },
    { lat: 48.192825, lng: 18.294595 },
    { lat: 48.192831, lng: 18.294583 },
    { lat: 48.192892, lng: 18.294495 },
    { lat: 48.19295, lng: 18.294408 },
    { lat: 48.192986, lng: 18.294354 },
    { lat: 48.193011, lng: 18.294353 },
    { lat: 48.193036, lng: 18.294353 },
    { lat: 48.193088, lng: 18.294334 },
    { lat: 48.193153, lng: 18.294337 },
    { lat: 48.193248, lng: 18.294336 },
    { lat: 48.193274, lng: 18.294326 },
    { lat: 48.193332, lng: 18.294303 },
  ],
  Strekov: [
    { lat: 47.878482, lng: 18.36002 },
    { lat: 47.878468, lng: 18.360054 },
    { lat: 47.87836, lng: 18.360232 },
    { lat: 47.8783, lng: 18.360336 },
    { lat: 47.87828, lng: 18.360368 },
    { lat: 47.878158, lng: 18.360576 },
    { lat: 47.878018, lng: 18.360819 },
    { lat: 47.877952, lng: 18.360932 },
    { lat: 47.877803, lng: 18.36119 },
    { lat: 47.877633, lng: 18.361482 },
    { lat: 47.8775, lng: 18.361709 },
    { lat: 47.877322, lng: 18.362014 },
    { lat: 47.877195, lng: 18.362232 },
    { lat: 47.877042, lng: 18.362489 },
    { lat: 47.876903, lng: 18.362727 },
    { lat: 47.876791, lng: 18.362918 },
    { lat: 47.876607, lng: 18.363225 },
    { lat: 47.876504, lng: 18.363397 },
    { lat: 47.876276, lng: 18.363765 },
    { lat: 47.876092, lng: 18.364073 },
    { lat: 47.875929, lng: 18.364347 },
    { lat: 47.875667, lng: 18.364783 },
    { lat: 47.875463, lng: 18.365124 },
    { lat: 47.875371, lng: 18.365275 },
    { lat: 47.875334, lng: 18.365335 },
    { lat: 47.875232, lng: 18.365519 },
    { lat: 47.87501, lng: 18.365907 },
    { lat: 47.874784, lng: 18.36631 },
    { lat: 47.874144, lng: 18.366195 },
    { lat: 47.873946, lng: 18.366159 },
    { lat: 47.873912, lng: 18.366147 },
    { lat: 47.873778, lng: 18.366304 },
    { lat: 47.873211, lng: 18.366959 },
    { lat: 47.872832, lng: 18.367398 },
    { lat: 47.872738, lng: 18.368087 },
    { lat: 47.872676, lng: 18.368531 },
    { lat: 47.872534, lng: 18.369577 },
    { lat: 47.872406, lng: 18.370542 },
    { lat: 47.872343, lng: 18.371013 },
    { lat: 47.872323, lng: 18.371127 },
    { lat: 47.872166, lng: 18.372 },
    { lat: 47.872062, lng: 18.372591 },
    { lat: 47.871957, lng: 18.37318 },
    { lat: 47.871927, lng: 18.373361 },
    { lat: 47.871891, lng: 18.37361 },
    { lat: 47.871766, lng: 18.374479 },
    { lat: 47.871692, lng: 18.374991 },
    { lat: 47.871676, lng: 18.375083 },
    { lat: 47.871656, lng: 18.375243 },
    { lat: 47.871604, lng: 18.375596 },
    { lat: 47.871494, lng: 18.376328 },
    { lat: 47.871406, lng: 18.37694 },
    { lat: 47.871385, lng: 18.377029 },
    { lat: 47.871254, lng: 18.377681 },
    { lat: 47.871121, lng: 18.378334 },
    { lat: 47.870999, lng: 18.378934 },
    { lat: 47.870977, lng: 18.379038 },
    { lat: 47.870878, lng: 18.379701 },
    { lat: 47.870733, lng: 18.380712 },
    { lat: 47.870584, lng: 18.381735 },
    { lat: 47.870509, lng: 18.382248 },
    { lat: 47.870444, lng: 18.382628 },
    { lat: 47.870389, lng: 18.38297 },
    { lat: 47.870301, lng: 18.383532 },
    { lat: 47.870216, lng: 18.384074 },
    { lat: 47.870104, lng: 18.38478 },
    { lat: 47.870038, lng: 18.385198 },
    { lat: 47.869948, lng: 18.385802 },
    { lat: 47.869834, lng: 18.386578 },
    { lat: 47.869736, lng: 18.387261 },
    { lat: 47.86967, lng: 18.387717 },
    { lat: 47.869608, lng: 18.388143 },
    { lat: 47.869557, lng: 18.388468 },
    { lat: 47.869465, lng: 18.38904 },
    { lat: 47.869357, lng: 18.38974 },
    { lat: 47.869334, lng: 18.389888 },
    { lat: 47.869285, lng: 18.390211 },
    { lat: 47.869152, lng: 18.391041 },
    { lat: 47.869111, lng: 18.391268 },
    { lat: 47.869042, lng: 18.391685 },
    { lat: 47.868925, lng: 18.392395 },
    { lat: 47.868772, lng: 18.393319 },
    { lat: 47.868654, lng: 18.394012 },
    { lat: 47.868542, lng: 18.394662 },
    { lat: 47.868517, lng: 18.39482 },
    { lat: 47.868488, lng: 18.395049 },
    { lat: 47.868435, lng: 18.395457 },
    { lat: 47.868311, lng: 18.396366 },
    { lat: 47.868291, lng: 18.396492 },
    { lat: 47.868159, lng: 18.397317 },
    { lat: 47.868045, lng: 18.398014 },
    { lat: 47.867906, lng: 18.398859 },
    { lat: 47.867834, lng: 18.399283 },
    { lat: 47.86772, lng: 18.399858 },
    { lat: 47.867549, lng: 18.400718 },
    { lat: 47.867468, lng: 18.40113 },
    { lat: 47.867351, lng: 18.401827 },
    { lat: 47.867297, lng: 18.402147 },
    { lat: 47.867197, lng: 18.402741 },
    { lat: 47.867139, lng: 18.403088 },
    { lat: 47.86712, lng: 18.403208 },
    { lat: 47.867057, lng: 18.403568 },
    { lat: 47.867034, lng: 18.403696 },
    { lat: 47.866994, lng: 18.403918 },
    { lat: 47.866905, lng: 18.404423 },
    { lat: 47.866833, lng: 18.40484 },
    { lat: 47.866803, lng: 18.405013 },
    { lat: 47.866759, lng: 18.405277 },
    { lat: 47.866711, lng: 18.405565 },
    { lat: 47.866624, lng: 18.406063 },
    { lat: 47.866612, lng: 18.406127 },
    { lat: 47.866582, lng: 18.406302 },
    { lat: 47.866492, lng: 18.406795 },
    { lat: 47.866483, lng: 18.406889 },
    { lat: 47.86645, lng: 18.407142 },
    { lat: 47.866354, lng: 18.407862 },
    { lat: 47.866314, lng: 18.408165 },
    { lat: 47.866238, lng: 18.408753 },
    { lat: 47.866142, lng: 18.409465 },
    { lat: 47.866103, lng: 18.409741 },
    { lat: 47.866035, lng: 18.410185 },
    { lat: 47.865971, lng: 18.410589 },
    { lat: 47.865878, lng: 18.411217 },
    { lat: 47.865807, lng: 18.4117 },
    { lat: 47.865703, lng: 18.412377 },
    { lat: 47.865682, lng: 18.412506 },
    { lat: 47.865615, lng: 18.412942 },
    { lat: 47.865533, lng: 18.413476 },
    { lat: 47.865483, lng: 18.413782 },
    { lat: 47.865494, lng: 18.413802 },
    { lat: 47.865528, lng: 18.413893 },
    { lat: 47.865644, lng: 18.413996 },
    { lat: 47.865869, lng: 18.41419 },
    { lat: 47.866071, lng: 18.414364 },
    { lat: 47.86619, lng: 18.414467 },
    { lat: 47.866365, lng: 18.414616 },
    { lat: 47.866466, lng: 18.414702 },
    { lat: 47.866576, lng: 18.414797 },
    { lat: 47.866663, lng: 18.414871 },
    { lat: 47.866847, lng: 18.415028 },
    { lat: 47.866916, lng: 18.415109 },
    { lat: 47.867078, lng: 18.415293 },
    { lat: 47.867204, lng: 18.415431 },
    { lat: 47.8677503, lng: 18.4161431 },
    { lat: 47.8682349, lng: 18.4166524 },
    { lat: 47.8811627, lng: 18.4301034 },
    { lat: 47.884214, lng: 18.4332786 },
    { lat: 47.8840035, lng: 18.4337849 },
    { lat: 47.8842455, lng: 18.4340967 },
    { lat: 47.8842663, lng: 18.4340639 },
    { lat: 47.8865682, lng: 18.4370973 },
    { lat: 47.8877999, lng: 18.4386348 },
    { lat: 47.8869438, lng: 18.4396193 },
    { lat: 47.885702, lng: 18.4400656 },
    { lat: 47.8845514, lng: 18.4406273 },
    { lat: 47.8838213, lng: 18.4413065 },
    { lat: 47.8834039, lng: 18.4422472 },
    { lat: 47.883293, lng: 18.4433852 },
    { lat: 47.8824342, lng: 18.4446 },
    { lat: 47.8840017, lng: 18.446494 },
    { lat: 47.8828783, lng: 18.4492894 },
    { lat: 47.8823768, lng: 18.4507844 },
    { lat: 47.8821113, lng: 18.451839 },
    { lat: 47.8812808, lng: 18.4533622 },
    { lat: 47.8804294, lng: 18.454592 },
    { lat: 47.8811845, lng: 18.4553202 },
    { lat: 47.8829939, lng: 18.4568285 },
    { lat: 47.8856526, lng: 18.4592524 },
    { lat: 47.8858527, lng: 18.4597013 },
    { lat: 47.8867294, lng: 18.4602901 },
    { lat: 47.8882428, lng: 18.4595566 },
    { lat: 47.8916873, lng: 18.4552239 },
    { lat: 47.893691, lng: 18.4542215 },
    { lat: 47.8993661, lng: 18.4537905 },
    { lat: 47.9006888, lng: 18.4549241 },
    { lat: 47.9019322, lng: 18.4556732 },
    { lat: 47.9076888, lng: 18.4538227 },
    { lat: 47.9142496, lng: 18.4584053 },
    { lat: 47.9165085, lng: 18.4564171 },
    { lat: 47.918379, lng: 18.4540642 },
    { lat: 47.9195852, lng: 18.4533039 },
    { lat: 47.9201816, lng: 18.4526769 },
    { lat: 47.9213592, lng: 18.4523501 },
    { lat: 47.923412, lng: 18.4526064 },
    { lat: 47.9240048, lng: 18.4528359 },
    { lat: 47.9243195, lng: 18.4539405 },
    { lat: 47.925877, lng: 18.4545953 },
    { lat: 47.9275981, lng: 18.4555541 },
    { lat: 47.9281909, lng: 18.4561885 },
    { lat: 47.9289221, lng: 18.4568128 },
    { lat: 47.9300282, lng: 18.4572842 },
    { lat: 47.9305623, lng: 18.4578584 },
    { lat: 47.9312076, lng: 18.4578585 },
    { lat: 47.9324812, lng: 18.4580999 },
    { lat: 47.9334061, lng: 18.4585208 },
    { lat: 47.9336809, lng: 18.4577043 },
    { lat: 47.9340801, lng: 18.4568179 },
    { lat: 47.9345191, lng: 18.4562585 },
    { lat: 47.9347424, lng: 18.455873 },
    { lat: 47.9349938, lng: 18.4552684 },
    { lat: 47.9357999, lng: 18.4540122 },
    { lat: 47.9362649, lng: 18.4534352 },
    { lat: 47.9378523, lng: 18.4522103 },
    { lat: 47.9378667, lng: 18.4521684 },
    { lat: 47.9380632, lng: 18.451987 },
    { lat: 47.9387487, lng: 18.4514188 },
    { lat: 47.9388868, lng: 18.4513474 },
    { lat: 47.9393727, lng: 18.4513415 },
    { lat: 47.9393981, lng: 18.4512494 },
    { lat: 47.937967, lng: 18.4493735 },
    { lat: 47.9373538, lng: 18.4486575 },
    { lat: 47.9368485, lng: 18.447494 },
    { lat: 47.9365422, lng: 18.4469411 },
    { lat: 47.9334372, lng: 18.4404738 },
    { lat: 47.9333752, lng: 18.4401924 },
    { lat: 47.9333892, lng: 18.439378 },
    { lat: 47.933441, lng: 18.4387495 },
    { lat: 47.9334891, lng: 18.4381657 },
    { lat: 47.933014, lng: 18.4352562 },
    { lat: 47.9328249, lng: 18.4346047 },
    { lat: 47.9326685, lng: 18.4335914 },
    { lat: 47.93259, lng: 18.4333177 },
    { lat: 47.9319431, lng: 18.4327659 },
    { lat: 47.9316463, lng: 18.4323435 },
    { lat: 47.9314365, lng: 18.4318047 },
    { lat: 47.9313995, lng: 18.4318276 },
    { lat: 47.9309956, lng: 18.4310876 },
    { lat: 47.9308701, lng: 18.4297999 },
    { lat: 47.9307743, lng: 18.4294425 },
    { lat: 47.9306506, lng: 18.4282282 },
    { lat: 47.9308915, lng: 18.4267193 },
    { lat: 47.9312145, lng: 18.4252642 },
    { lat: 47.9311297, lng: 18.4244981 },
    { lat: 47.9310688, lng: 18.4244589 },
    { lat: 47.9304736, lng: 18.4232908 },
    { lat: 47.9300257, lng: 18.4218669 },
    { lat: 47.9294027, lng: 18.4203245 },
    { lat: 47.9279359, lng: 18.417302 },
    { lat: 47.9276625, lng: 18.4166168 },
    { lat: 47.9267136, lng: 18.4151952 },
    { lat: 47.9253354, lng: 18.4128864 },
    { lat: 47.9249446, lng: 18.4123208 },
    { lat: 47.9244259, lng: 18.4112655 },
    { lat: 47.9242226, lng: 18.4089495 },
    { lat: 47.9239684, lng: 18.4083219 },
    { lat: 47.923498, lng: 18.4076878 },
    { lat: 47.9228384, lng: 18.4065967 },
    { lat: 47.9213616, lng: 18.4037633 },
    { lat: 47.919156, lng: 18.4035852 },
    { lat: 47.9184687, lng: 18.4046584 },
    { lat: 47.9172313, lng: 18.4061751 },
    { lat: 47.9164171, lng: 18.4069395 },
    { lat: 47.9163493, lng: 18.4068988 },
    { lat: 47.9154512, lng: 18.4055624 },
    { lat: 47.9143512, lng: 18.4037449 },
    { lat: 47.9135, lng: 18.4019216 },
    { lat: 47.9111398, lng: 18.3972037 },
    { lat: 47.9140622, lng: 18.3943434 },
    { lat: 47.9155677, lng: 18.3930971 },
    { lat: 47.9167942, lng: 18.3918263 },
    { lat: 47.9161205, lng: 18.3906093 },
    { lat: 47.9153548, lng: 18.3894236 },
    { lat: 47.9137252, lng: 18.3873476 },
    { lat: 47.9140021, lng: 18.3868202 },
    { lat: 47.9132639, lng: 18.38586 },
    { lat: 47.9133013, lng: 18.3857604 },
    { lat: 47.9119775, lng: 18.3841424 },
    { lat: 47.9121723, lng: 18.3837234 },
    { lat: 47.9111826, lng: 18.3823269 },
    { lat: 47.9103646, lng: 18.3813104 },
    { lat: 47.9093098, lng: 18.3802228 },
    { lat: 47.9095554, lng: 18.3783079 },
    { lat: 47.9101363, lng: 18.3770776 },
    { lat: 47.911579, lng: 18.3732992 },
    { lat: 47.9131135, lng: 18.3701308 },
    { lat: 47.917031, lng: 18.362704 },
    { lat: 47.91833, lng: 18.3607716 },
    { lat: 47.9197128, lng: 18.3589038 },
    { lat: 47.919695, lng: 18.358861 },
    { lat: 47.919683, lng: 18.35884 },
    { lat: 47.919055, lng: 18.358197 },
    { lat: 47.918811, lng: 18.357946 },
    { lat: 47.918782, lng: 18.357917 },
    { lat: 47.918691, lng: 18.357824 },
    { lat: 47.91795, lng: 18.357181 },
    { lat: 47.917528, lng: 18.356809 },
    { lat: 47.916574, lng: 18.356085 },
    { lat: 47.915983, lng: 18.35562 },
    { lat: 47.915314, lng: 18.355081 },
    { lat: 47.915289, lng: 18.355062 },
    { lat: 47.915122, lng: 18.354924 },
    { lat: 47.914202, lng: 18.354259 },
    { lat: 47.913669, lng: 18.353882 },
    { lat: 47.912991, lng: 18.353389 },
    { lat: 47.912363, lng: 18.35302 },
    { lat: 47.911311, lng: 18.35233 },
    { lat: 47.910862, lng: 18.352009 },
    { lat: 47.91079, lng: 18.351957 },
    { lat: 47.910359, lng: 18.351649 },
    { lat: 47.909655, lng: 18.351196 },
    { lat: 47.909613, lng: 18.351167 },
    { lat: 47.909499, lng: 18.351091 },
    { lat: 47.908676, lng: 18.350525 },
    { lat: 47.907974, lng: 18.350092 },
    { lat: 47.907739, lng: 18.349945 },
    { lat: 47.907397, lng: 18.349738 },
    { lat: 47.906853, lng: 18.349403 },
    { lat: 47.906458, lng: 18.349158 },
    { lat: 47.906305, lng: 18.349051 },
    { lat: 47.905918, lng: 18.348806 },
    { lat: 47.90534, lng: 18.348427 },
    { lat: 47.904969, lng: 18.348188 },
    { lat: 47.904337, lng: 18.347776 },
    { lat: 47.903687, lng: 18.347395 },
    { lat: 47.903327, lng: 18.347188 },
    { lat: 47.903195, lng: 18.347111 },
    { lat: 47.902598, lng: 18.346767 },
    { lat: 47.902253, lng: 18.346498 },
    { lat: 47.901364, lng: 18.345788 },
    { lat: 47.900711, lng: 18.345587 },
    { lat: 47.900579, lng: 18.345548 },
    { lat: 47.900267, lng: 18.345457 },
    { lat: 47.899812, lng: 18.345322 },
    { lat: 47.899449, lng: 18.345218 },
    { lat: 47.899417, lng: 18.345265 },
    { lat: 47.898975, lng: 18.345916 },
    { lat: 47.898781, lng: 18.346208 },
    { lat: 47.898384, lng: 18.346795 },
    { lat: 47.898085, lng: 18.347225 },
    { lat: 47.897641, lng: 18.347867 },
    { lat: 47.897489, lng: 18.348085 },
    { lat: 47.897441, lng: 18.348159 },
    { lat: 47.897398, lng: 18.348219 },
    { lat: 47.8973, lng: 18.34836 },
    { lat: 47.897183, lng: 18.348525 },
    { lat: 47.896713, lng: 18.349105 },
    { lat: 47.896221, lng: 18.349726 },
    { lat: 47.896062, lng: 18.349924 },
    { lat: 47.895988, lng: 18.350015 },
    { lat: 47.895912, lng: 18.350109 },
    { lat: 47.895619, lng: 18.350484 },
    { lat: 47.895306, lng: 18.350871 },
    { lat: 47.894995, lng: 18.351259 },
    { lat: 47.894914, lng: 18.35136 },
    { lat: 47.894693, lng: 18.351634 },
    { lat: 47.894625, lng: 18.351718 },
    { lat: 47.89456, lng: 18.351802 },
    { lat: 47.89439, lng: 18.352014 },
    { lat: 47.894108, lng: 18.352368 },
    { lat: 47.894006, lng: 18.352509 },
    { lat: 47.893784, lng: 18.352787 },
    { lat: 47.893523, lng: 18.353108 },
    { lat: 47.893172, lng: 18.353554 },
    { lat: 47.892759, lng: 18.354066 },
    { lat: 47.892503, lng: 18.35439 },
    { lat: 47.891896, lng: 18.355163 },
    { lat: 47.891589, lng: 18.355711 },
    { lat: 47.891297, lng: 18.356235 },
    { lat: 47.890867, lng: 18.35698 },
    { lat: 47.890629, lng: 18.357399 },
    { lat: 47.889746, lng: 18.357019 },
    { lat: 47.888706, lng: 18.356008 },
    { lat: 47.888036, lng: 18.355276 },
    { lat: 47.886897, lng: 18.355584 },
    { lat: 47.885646, lng: 18.355911 },
    { lat: 47.884812, lng: 18.356127 },
    { lat: 47.883761, lng: 18.356399 },
    { lat: 47.883003, lng: 18.356589 },
    { lat: 47.882652, lng: 18.356684 },
    { lat: 47.882345, lng: 18.356766 },
    { lat: 47.882045, lng: 18.356848 },
    { lat: 47.881735, lng: 18.357127 },
    { lat: 47.881436, lng: 18.357393 },
    { lat: 47.881092, lng: 18.357695 },
    { lat: 47.880588, lng: 18.358138 },
    { lat: 47.880499, lng: 18.358217 },
    { lat: 47.880173, lng: 18.358506 },
    { lat: 47.879992, lng: 18.358675 },
    { lat: 47.87971, lng: 18.358922 },
    { lat: 47.879078, lng: 18.359486 },
    { lat: 47.878742, lng: 18.359783 },
    { lat: 47.878508, lng: 18.35999 },
    { lat: 47.878482, lng: 18.36002 },
  ],
  Lipová: [
    { lat: 48.1313868, lng: 18.2091896 },
    { lat: 48.1318755, lng: 18.2075391 },
    { lat: 48.1347878, lng: 18.1978327 },
    { lat: 48.1349542, lng: 18.1959233 },
    { lat: 48.1351034, lng: 18.1932329 },
    { lat: 48.1349098, lng: 18.193145 },
    { lat: 48.1346844, lng: 18.1929376 },
    { lat: 48.1344104, lng: 18.1923585 },
    { lat: 48.1344, lng: 18.1921225 },
    { lat: 48.134441, lng: 18.1919331 },
    { lat: 48.1346984, lng: 18.1911391 },
    { lat: 48.1344846, lng: 18.1908228 },
    { lat: 48.1333478, lng: 18.191279 },
    { lat: 48.1327039, lng: 18.1920059 },
    { lat: 48.132545, lng: 18.1920259 },
    { lat: 48.1323571, lng: 18.1919224 },
    { lat: 48.1321203, lng: 18.1916522 },
    { lat: 48.1319367, lng: 18.1917157 },
    { lat: 48.1316625, lng: 18.1921808 },
    { lat: 48.1315377, lng: 18.1921889 },
    { lat: 48.13127, lng: 18.1917387 },
    { lat: 48.1316565, lng: 18.1908764 },
    { lat: 48.1317176, lng: 18.1906459 },
    { lat: 48.1317119, lng: 18.1897572 },
    { lat: 48.1315773, lng: 18.1894034 },
    { lat: 48.1313161, lng: 18.1894134 },
    { lat: 48.1308176, lng: 18.1898834 },
    { lat: 48.1300454, lng: 18.1900872 },
    { lat: 48.129494, lng: 18.1906411 },
    { lat: 48.1293706, lng: 18.1906497 },
    { lat: 48.1292345, lng: 18.1905614 },
    { lat: 48.1291413, lng: 18.1903386 },
    { lat: 48.12914, lng: 18.1901972 },
    { lat: 48.1292098, lng: 18.1900398 },
    { lat: 48.1295264, lng: 18.1897231 },
    { lat: 48.1294836, lng: 18.1894514 },
    { lat: 48.1293738, lng: 18.1893491 },
    { lat: 48.1290195, lng: 18.189429 },
    { lat: 48.1277936, lng: 18.1893821 },
    { lat: 48.1278398, lng: 18.1888198 },
    { lat: 48.1279184, lng: 18.188485 },
    { lat: 48.1278768, lng: 18.1883322 },
    { lat: 48.1279244, lng: 18.1874242 },
    { lat: 48.1278816, lng: 18.1871931 },
    { lat: 48.1279063, lng: 18.1869229 },
    { lat: 48.1278549, lng: 18.1867187 },
    { lat: 48.1277636, lng: 18.1866606 },
    { lat: 48.1278962, lng: 18.1859952 },
    { lat: 48.1285427, lng: 18.1837844 },
    { lat: 48.1286258, lng: 18.1833734 },
    { lat: 48.12861, lng: 18.1832366 },
    { lat: 48.1282898, lng: 18.1826234 },
    { lat: 48.1282422, lng: 18.1824068 },
    { lat: 48.1283688, lng: 18.181403 },
    { lat: 48.1284513, lng: 18.1811719 },
    { lat: 48.1287106, lng: 18.1807658 },
    { lat: 48.1289758, lng: 18.1805688 },
    { lat: 48.1291606, lng: 18.1805267 },
    { lat: 48.1298251, lng: 18.1807571 },
    { lat: 48.1300876, lng: 18.1807095 },
    { lat: 48.1303508, lng: 18.1805593 },
    { lat: 48.1305392, lng: 18.1803681 },
    { lat: 48.1306873, lng: 18.1801383 },
    { lat: 48.1307307, lng: 18.1799535 },
    { lat: 48.1306906, lng: 18.1795176 },
    { lat: 48.1305764, lng: 18.1791863 },
    { lat: 48.12991, lng: 18.178497 },
    { lat: 48.1298735, lng: 18.1783371 },
    { lat: 48.1298932, lng: 18.1781926 },
    { lat: 48.1297692, lng: 18.1781949 },
    { lat: 48.1293064, lng: 18.1760073 },
    { lat: 48.1286924, lng: 18.1719287 },
    { lat: 48.1285921, lng: 18.1700171 },
    { lat: 48.1283659, lng: 18.1680611 },
    { lat: 48.1274278, lng: 18.1643284 },
    { lat: 48.1272263, lng: 18.1635886 },
    { lat: 48.1268296, lng: 18.1624952 },
    { lat: 48.1261564, lng: 18.1610781 },
    { lat: 48.1260234, lng: 18.1604243 },
    { lat: 48.1260801, lng: 18.1596603 },
    { lat: 48.1263927, lng: 18.1585214 },
    { lat: 48.1264337, lng: 18.1582405 },
    { lat: 48.1265226, lng: 18.1543802 },
    { lat: 48.1263703, lng: 18.153113 },
    { lat: 48.1263773, lng: 18.1528087 },
    { lat: 48.1265858, lng: 18.1515317 },
    { lat: 48.126639, lng: 18.1508784 },
    { lat: 48.1263422, lng: 18.1488975 },
    { lat: 48.1258831, lng: 18.1474196 },
    { lat: 48.1257405, lng: 18.1466411 },
    { lat: 48.1255995, lng: 18.1437939 },
    { lat: 48.1257776, lng: 18.1407319 },
    { lat: 48.1259675, lng: 18.1392227 },
    { lat: 48.1256687, lng: 18.1367067 },
    { lat: 48.1251708, lng: 18.1346682 },
    { lat: 48.1249579, lng: 18.1329099 },
    { lat: 48.1248652, lng: 18.1316014 },
    { lat: 48.1248627, lng: 18.1310207 },
    { lat: 48.1251619, lng: 18.1295809 },
    { lat: 48.1252625, lng: 18.1288952 },
    { lat: 48.1253262, lng: 18.1277505 },
    { lat: 48.1247943, lng: 18.1250815 },
    { lat: 48.1241835, lng: 18.1223328 },
    { lat: 48.1235304, lng: 18.1201527 },
    { lat: 48.1227757, lng: 18.1178596 },
    { lat: 48.1222897, lng: 18.1160296 },
    { lat: 48.119802, lng: 18.1185484 },
    { lat: 48.117188, lng: 18.1212273 },
    { lat: 48.1143997, lng: 18.135102 },
    { lat: 48.1143045, lng: 18.1351925 },
    { lat: 48.1142258, lng: 18.135176 },
    { lat: 48.1125769, lng: 18.1408793 },
    { lat: 48.1115811, lng: 18.1453704 },
    { lat: 48.1101801, lng: 18.1644285 },
    { lat: 48.1098327, lng: 18.1680595 },
    { lat: 48.1095496, lng: 18.1726171 },
    { lat: 48.1093404, lng: 18.1752527 },
    { lat: 48.1090842, lng: 18.1766073 },
    { lat: 48.1083036, lng: 18.1796084 },
    { lat: 48.1074483, lng: 18.1819297 },
    { lat: 48.1071578, lng: 18.1824219 },
    { lat: 48.106709, lng: 18.1828711 },
    { lat: 48.1055135, lng: 18.18391 },
    { lat: 48.1054035, lng: 18.1839017 },
    { lat: 48.1053524, lng: 18.1845131 },
    { lat: 48.1052013, lng: 18.1854461 },
    { lat: 48.1052773, lng: 18.1860873 },
    { lat: 48.1055299, lng: 18.1866479 },
    { lat: 48.1064203, lng: 18.1878654 },
    { lat: 48.1065645, lng: 18.1881905 },
    { lat: 48.1063781, lng: 18.1885177 },
    { lat: 48.1064002, lng: 18.1885678 },
    { lat: 48.106142, lng: 18.1889 },
    { lat: 48.1056181, lng: 18.1902126 },
    { lat: 48.1056215, lng: 18.1902867 },
    { lat: 48.1038755, lng: 18.1901471 },
    { lat: 48.10348, lng: 18.1900018 },
    { lat: 48.1029603, lng: 18.1897039 },
    { lat: 48.1026206, lng: 18.1897205 },
    { lat: 48.1025197, lng: 18.1899091 },
    { lat: 48.1007319, lng: 18.1922494 },
    { lat: 48.099988, lng: 18.193445 },
    { lat: 48.0981602, lng: 18.1963827 },
    { lat: 48.0996985, lng: 18.1995137 },
    { lat: 48.0989105, lng: 18.1999556 },
    { lat: 48.0988506, lng: 18.199892 },
    { lat: 48.0986548, lng: 18.2003308 },
    { lat: 48.0986061, lng: 18.2006767 },
    { lat: 48.0986618, lng: 18.2012837 },
    { lat: 48.0988607, lng: 18.201618 },
    { lat: 48.0990855, lng: 18.201795 },
    { lat: 48.0990106, lng: 18.2031454 },
    { lat: 48.0987658, lng: 18.2035348 },
    { lat: 48.0972057, lng: 18.2041789 },
    { lat: 48.0972191, lng: 18.2056568 },
    { lat: 48.0969578, lng: 18.2069933 },
    { lat: 48.0966308, lng: 18.2073753 },
    { lat: 48.0966652, lng: 18.2078039 },
    { lat: 48.0964564, lng: 18.2079238 },
    { lat: 48.0964587, lng: 18.2084372 },
    { lat: 48.0965467, lng: 18.2096765 },
    { lat: 48.0968596, lng: 18.2110398 },
    { lat: 48.0976149, lng: 18.2115339 },
    { lat: 48.0985176, lng: 18.2115159 },
    { lat: 48.0987809, lng: 18.2114031 },
    { lat: 48.0991241, lng: 18.2110963 },
    { lat: 48.0993223, lng: 18.2111091 },
    { lat: 48.0991825, lng: 18.2114771 },
    { lat: 48.09904, lng: 18.211708 },
    { lat: 48.0995205, lng: 18.2128108 },
    { lat: 48.1000341, lng: 18.2124241 },
    { lat: 48.1002224, lng: 18.2124777 },
    { lat: 48.1004724, lng: 18.2122388 },
    { lat: 48.1006684, lng: 18.2124673 },
    { lat: 48.1010757, lng: 18.2133183 },
    { lat: 48.1013075, lng: 18.2139671 },
    { lat: 48.1015221, lng: 18.2144177 },
    { lat: 48.101977, lng: 18.2146525 },
    { lat: 48.102494, lng: 18.2151021 },
    { lat: 48.1055909, lng: 18.2114023 },
    { lat: 48.1062577, lng: 18.2116538 },
    { lat: 48.1067724, lng: 18.2106265 },
    { lat: 48.1070216, lng: 18.2092434 },
    { lat: 48.1073738, lng: 18.2083341 },
    { lat: 48.1103282, lng: 18.2033124 },
    { lat: 48.108271, lng: 18.2003822 },
    { lat: 48.1076193, lng: 18.2007774 },
    { lat: 48.1074258, lng: 18.2005561 },
    { lat: 48.107093, lng: 18.2004209 },
    { lat: 48.1069406, lng: 18.2002476 },
    { lat: 48.1065544, lng: 18.2001049 },
    { lat: 48.1064981, lng: 18.1998153 },
    { lat: 48.1064884, lng: 18.1993396 },
    { lat: 48.1063729, lng: 18.1991597 },
    { lat: 48.1062342, lng: 18.198056 },
    { lat: 48.1061719, lng: 18.1970642 },
    { lat: 48.106278, lng: 18.1948544 },
    { lat: 48.1061567, lng: 18.1928118 },
    { lat: 48.1063615, lng: 18.1931287 },
    { lat: 48.1067643, lng: 18.1946617 },
    { lat: 48.1069296, lng: 18.1949615 },
    { lat: 48.1072897, lng: 18.1953095 },
    { lat: 48.1072263, lng: 18.1959268 },
    { lat: 48.1074558, lng: 18.1963231 },
    { lat: 48.1082956, lng: 18.1963864 },
    { lat: 48.1101117, lng: 18.1967809 },
    { lat: 48.1104789, lng: 18.196789 },
    { lat: 48.1111243, lng: 18.1966116 },
    { lat: 48.1114113, lng: 18.1964222 },
    { lat: 48.1121776, lng: 18.1964661 },
    { lat: 48.1122205, lng: 18.1965816 },
    { lat: 48.1125795, lng: 18.1966024 },
    { lat: 48.1127105, lng: 18.1967775 },
    { lat: 48.1127952, lng: 18.197322 },
    { lat: 48.1126838, lng: 18.1981987 },
    { lat: 48.1126646, lng: 18.1990723 },
    { lat: 48.1127311, lng: 18.1994217 },
    { lat: 48.1129051, lng: 18.1997343 },
    { lat: 48.1132673, lng: 18.1998112 },
    { lat: 48.1134, lng: 18.1996683 },
    { lat: 48.1134389, lng: 18.1994439 },
    { lat: 48.1133949, lng: 18.19801 },
    { lat: 48.113576, lng: 18.1976111 },
    { lat: 48.1137133, lng: 18.1975478 },
    { lat: 48.1138714, lng: 18.1977416 },
    { lat: 48.1139636, lng: 18.1984765 },
    { lat: 48.114446, lng: 18.1990459 },
    { lat: 48.1151793, lng: 18.1988095 },
    { lat: 48.1153583, lng: 18.1988622 },
    { lat: 48.1154647, lng: 18.1989639 },
    { lat: 48.1155574, lng: 18.1993947 },
    { lat: 48.1151026, lng: 18.2005762 },
    { lat: 48.1147596, lng: 18.2010042 },
    { lat: 48.1145488, lng: 18.2017632 },
    { lat: 48.1145469, lng: 18.2020234 },
    { lat: 48.1145848, lng: 18.2021293 },
    { lat: 48.1148305, lng: 18.2024354 },
    { lat: 48.1149725, lng: 18.2024131 },
    { lat: 48.1155743, lng: 18.2017785 },
    { lat: 48.1157259, lng: 18.2015148 },
    { lat: 48.1159409, lng: 18.2014668 },
    { lat: 48.1160316, lng: 18.2015705 },
    { lat: 48.1160474, lng: 18.2017253 },
    { lat: 48.1163336, lng: 18.202144 },
    { lat: 48.1166156, lng: 18.2022603 },
    { lat: 48.1167712, lng: 18.201887 },
    { lat: 48.1168528, lng: 18.2014075 },
    { lat: 48.1170565, lng: 18.2014181 },
    { lat: 48.1175491, lng: 18.2021661 },
    { lat: 48.1175564, lng: 18.2023689 },
    { lat: 48.1173054, lng: 18.2031967 },
    { lat: 48.1173742, lng: 18.2035254 },
    { lat: 48.1176675, lng: 18.2037374 },
    { lat: 48.1183512, lng: 18.2048783 },
    { lat: 48.1186394, lng: 18.2055649 },
    { lat: 48.1188787, lng: 18.2058009 },
    { lat: 48.1195274, lng: 18.2061684 },
    { lat: 48.1202973, lng: 18.2063271 },
    { lat: 48.1214394, lng: 18.2067169 },
    { lat: 48.121636, lng: 18.2068969 },
    { lat: 48.12199, lng: 18.2078989 },
    { lat: 48.1222101, lng: 18.2079791 },
    { lat: 48.1224691, lng: 18.2049422 },
    { lat: 48.1224749, lng: 18.2042273 },
    { lat: 48.1226376, lng: 18.2017489 },
    { lat: 48.1273008, lng: 18.2057308 },
    { lat: 48.1292651, lng: 18.2072824 },
    { lat: 48.1313868, lng: 18.2091896 },
  ],
  Sikenička: [
    { lat: 47.951268, lng: 18.687582 },
    { lat: 47.951233, lng: 18.687642 },
    { lat: 47.951183, lng: 18.687741 },
    { lat: 47.95112, lng: 18.687817 },
    { lat: 47.95104, lng: 18.687893 },
    { lat: 47.950964, lng: 18.687923 },
    { lat: 47.950873, lng: 18.68795 },
    { lat: 47.950766, lng: 18.687936 },
    { lat: 47.950695, lng: 18.687907 },
    { lat: 47.950625, lng: 18.687853 },
    { lat: 47.95055, lng: 18.687755 },
    { lat: 47.95048, lng: 18.687537 },
    { lat: 47.95052, lng: 18.687424 },
    { lat: 47.95061, lng: 18.687162 },
    { lat: 47.950959, lng: 18.686102 },
    { lat: 47.95056, lng: 18.685666 },
    { lat: 47.950246, lng: 18.685231 },
    { lat: 47.950018, lng: 18.684895 },
    { lat: 47.94945, lng: 18.683912 },
    { lat: 47.949413, lng: 18.68384 },
    { lat: 47.949144, lng: 18.683312 },
    { lat: 47.948591, lng: 18.682806 },
    { lat: 47.948244, lng: 18.682431 },
    { lat: 47.947812, lng: 18.681964 },
    { lat: 47.947358, lng: 18.681569 },
    { lat: 47.946815, lng: 18.681118 },
    { lat: 47.94645, lng: 18.680705 },
    { lat: 47.94615, lng: 18.679888 },
    { lat: 47.945792, lng: 18.679052 },
    { lat: 47.94519, lng: 18.678516 },
    { lat: 47.944934, lng: 18.678318 },
    { lat: 47.944892, lng: 18.678286 },
    { lat: 47.944655, lng: 18.678102 },
    { lat: 47.944404, lng: 18.677462 },
    { lat: 47.944336, lng: 18.676925 },
    { lat: 47.943965, lng: 18.676365 },
    { lat: 47.943478, lng: 18.675704 },
    { lat: 47.942938, lng: 18.675093 },
    { lat: 47.942318, lng: 18.67447 },
    { lat: 47.942071, lng: 18.674014 },
    { lat: 47.941865, lng: 18.672989 },
    { lat: 47.94158, lng: 18.672178 },
    { lat: 47.941069, lng: 18.671463 },
    { lat: 47.940432, lng: 18.670702 },
    { lat: 47.939791, lng: 18.669665 },
    { lat: 47.939669, lng: 18.669533 },
    { lat: 47.939069, lng: 18.668882 },
    { lat: 47.938651, lng: 18.668211 },
    { lat: 47.938825, lng: 18.667258 },
    { lat: 47.9378296, lng: 18.6671307 },
    { lat: 47.9354672, lng: 18.6652465 },
    { lat: 47.935046, lng: 18.6651771 },
    { lat: 47.9344212, lng: 18.6647612 },
    { lat: 47.9328077, lng: 18.6666826 },
    { lat: 47.9326368, lng: 18.6665255 },
    { lat: 47.9324242, lng: 18.6666836 },
    { lat: 47.9322027, lng: 18.6666308 },
    { lat: 47.9313619, lng: 18.6678712 },
    { lat: 47.9311551, lng: 18.6679439 },
    { lat: 47.9311058, lng: 18.667763 },
    { lat: 47.9311378, lng: 18.6666285 },
    { lat: 47.9310822, lng: 18.6655208 },
    { lat: 47.9310233, lng: 18.6654318 },
    { lat: 47.9308479, lng: 18.6653622 },
    { lat: 47.9306892, lng: 18.6651382 },
    { lat: 47.9304189, lng: 18.6651049 },
    { lat: 47.9297099, lng: 18.6654451 },
    { lat: 47.9295212, lng: 18.6657311 },
    { lat: 47.9294627, lng: 18.666208 },
    { lat: 47.9293296, lng: 18.6663326 },
    { lat: 47.9291645, lng: 18.6666577 },
    { lat: 47.929033, lng: 18.6667906 },
    { lat: 47.9282943, lng: 18.6667316 },
    { lat: 47.9280834, lng: 18.6668962 },
    { lat: 47.9277899, lng: 18.6673503 },
    { lat: 47.9273571, lng: 18.6676069 },
    { lat: 47.9261648, lng: 18.6677691 },
    { lat: 47.9254819, lng: 18.6679893 },
    { lat: 47.925115, lng: 18.6680231 },
    { lat: 47.924185, lng: 18.6678883 },
    { lat: 47.923754, lng: 18.6676978 },
    { lat: 47.9235466, lng: 18.6675335 },
    { lat: 47.9234359, lng: 18.6675499 },
    { lat: 47.923196, lng: 18.6677637 },
    { lat: 47.9231753, lng: 18.6679054 },
    { lat: 47.9234049, lng: 18.6681458 },
    { lat: 47.9236055, lng: 18.6688295 },
    { lat: 47.9231299, lng: 18.6691032 },
    { lat: 47.9230903, lng: 18.6692877 },
    { lat: 47.9231739, lng: 18.6695442 },
    { lat: 47.9235458, lng: 18.6697249 },
    { lat: 47.9238054, lng: 18.669705 },
    { lat: 47.9241775, lng: 18.669557 },
    { lat: 47.9242442, lng: 18.6696024 },
    { lat: 47.9242551, lng: 18.6697639 },
    { lat: 47.9242332, lng: 18.6700007 },
    { lat: 47.9241752, lng: 18.6701388 },
    { lat: 47.9238128, lng: 18.6703634 },
    { lat: 47.9233858, lng: 18.6710152 },
    { lat: 47.9233889, lng: 18.6710943 },
    { lat: 47.9236214, lng: 18.6711797 },
    { lat: 47.9239002, lng: 18.6711824 },
    { lat: 47.9241822, lng: 18.6713698 },
    { lat: 47.9243333, lng: 18.6713896 },
    { lat: 47.9244982, lng: 18.6713136 },
    { lat: 47.9247338, lng: 18.6708331 },
    { lat: 47.9248207, lng: 18.67081 },
    { lat: 47.9248975, lng: 18.6708359 },
    { lat: 47.9249264, lng: 18.6709116 },
    { lat: 47.9249338, lng: 18.6712145 },
    { lat: 47.9248716, lng: 18.671606 },
    { lat: 47.9248186, lng: 18.6716651 },
    { lat: 47.9228966, lng: 18.6733303 },
    { lat: 47.9225089, lng: 18.6734809 },
    { lat: 47.9223192, lng: 18.6734136 },
    { lat: 47.9222157, lng: 18.6732748 },
    { lat: 47.9220837, lng: 18.6728575 },
    { lat: 47.9220701, lng: 18.6726278 },
    { lat: 47.9220507, lng: 18.6714778 },
    { lat: 47.9221287, lng: 18.6711506 },
    { lat: 47.9220993, lng: 18.6710659 },
    { lat: 47.9219197, lng: 18.6709753 },
    { lat: 47.9214653, lng: 18.6709802 },
    { lat: 47.9210328, lng: 18.6713433 },
    { lat: 47.9208342, lng: 18.6714237 },
    { lat: 47.9199292, lng: 18.6714799 },
    { lat: 47.9197729, lng: 18.6715493 },
    { lat: 47.9196016, lng: 18.6717415 },
    { lat: 47.9193008, lng: 18.6722652 },
    { lat: 47.9180974, lng: 18.676681 },
    { lat: 47.9170764, lng: 18.6834468 },
    { lat: 47.9167515, lng: 18.6845267 },
    { lat: 47.9161923, lng: 18.6858853 },
    { lat: 47.915234, lng: 18.6877093 },
    { lat: 47.9145122, lng: 18.6895008 },
    { lat: 47.9136063, lng: 18.6913971 },
    { lat: 47.9127053, lng: 18.6930892 },
    { lat: 47.9118534, lng: 18.6951906 },
    { lat: 47.9111858, lng: 18.6969901 },
    { lat: 47.910932, lng: 18.6976999 },
    { lat: 47.9106112, lng: 18.6988361 },
    { lat: 47.9104414, lng: 18.6997053 },
    { lat: 47.9102008, lng: 18.7012213 },
    { lat: 47.9101057, lng: 18.7026373 },
    { lat: 47.9101825, lng: 18.7036022 },
    { lat: 47.910633, lng: 18.7066447 },
    { lat: 47.9098775, lng: 18.7116662 },
    { lat: 47.9106438, lng: 18.7114881 },
    { lat: 47.9112083, lng: 18.7113723 },
    { lat: 47.9113063, lng: 18.7121473 },
    { lat: 47.9115579, lng: 18.7132552 },
    { lat: 47.9117501, lng: 18.714353 },
    { lat: 47.9115792, lng: 18.7168386 },
    { lat: 47.9117142, lng: 18.7191134 },
    { lat: 47.9125294, lng: 18.7225521 },
    { lat: 47.9128148, lng: 18.7239338 },
    { lat: 47.9134189, lng: 18.7238644 },
    { lat: 47.9144412, lng: 18.7237484 },
    { lat: 47.9160545, lng: 18.7236943 },
    { lat: 47.9175873, lng: 18.7247034 },
    { lat: 47.9192274, lng: 18.7240957 },
    { lat: 47.9197123, lng: 18.7245604 },
    { lat: 47.9204778, lng: 18.7248519 },
    { lat: 47.922903, lng: 18.7225294 },
    { lat: 47.9244823, lng: 18.721697 },
    { lat: 47.9258309, lng: 18.7201388 },
    { lat: 47.9269851, lng: 18.71845 },
    { lat: 47.9284598, lng: 18.7179178 },
    { lat: 47.9302828, lng: 18.7171468 },
    { lat: 47.9320108, lng: 18.717318 },
    { lat: 47.9337115, lng: 18.7172958 },
    { lat: 47.9351316, lng: 18.716149 },
    { lat: 47.936315, lng: 18.71431 },
    { lat: 47.936668, lng: 18.7139528 },
    { lat: 47.9376509, lng: 18.7129163 },
    { lat: 47.9401165, lng: 18.7119139 },
    { lat: 47.9415515, lng: 18.710917 },
    { lat: 47.9430367, lng: 18.7113318 },
    { lat: 47.9444188, lng: 18.7121167 },
    { lat: 47.94583, lng: 18.713106 },
    { lat: 47.946334, lng: 18.712181 },
    { lat: 47.94689, lng: 18.711143 },
    { lat: 47.946916, lng: 18.711087 },
    { lat: 47.947202, lng: 18.7105 },
    { lat: 47.947661, lng: 18.709542 },
    { lat: 47.947704, lng: 18.70944 },
    { lat: 47.948181, lng: 18.708211 },
    { lat: 47.948272, lng: 18.707944 },
    { lat: 47.948619, lng: 18.706866 },
    { lat: 47.949076, lng: 18.705602 },
    { lat: 47.949507, lng: 18.704003 },
    { lat: 47.949534, lng: 18.703687 },
    { lat: 47.949534, lng: 18.703675 },
    { lat: 47.949587, lng: 18.703051 },
    { lat: 47.949641, lng: 18.702325 },
    { lat: 47.949649, lng: 18.702268 },
    { lat: 47.949695, lng: 18.701555 },
    { lat: 47.949733, lng: 18.700903 },
    { lat: 47.94979, lng: 18.70009 },
    { lat: 47.949816, lng: 18.698953 },
    { lat: 47.949816, lng: 18.698935 },
    { lat: 47.949758, lng: 18.697358 },
    { lat: 47.949803, lng: 18.697098 },
    { lat: 47.949805, lng: 18.69709 },
    { lat: 47.94982, lng: 18.696994 },
    { lat: 47.949823, lng: 18.696978 },
    { lat: 47.949867, lng: 18.696709 },
    { lat: 47.949871, lng: 18.69669 },
    { lat: 47.949893, lng: 18.696562 },
    { lat: 47.949895, lng: 18.696545 },
    { lat: 47.950026, lng: 18.695755 },
    { lat: 47.949993, lng: 18.695723 },
    { lat: 47.949732, lng: 18.695472 },
    { lat: 47.949673, lng: 18.695418 },
    { lat: 47.949442, lng: 18.694957 },
    { lat: 47.949459, lng: 18.694104 },
    { lat: 47.949152, lng: 18.693532 },
    { lat: 47.949103, lng: 18.692852 },
    { lat: 47.949508, lng: 18.692541 },
    { lat: 47.949731, lng: 18.692152 },
    { lat: 47.949753, lng: 18.692124 },
    { lat: 47.949948, lng: 18.691484 },
    { lat: 47.950201, lng: 18.691507 },
    { lat: 47.95029, lng: 18.690992 },
    { lat: 47.950312, lng: 18.690929 },
    { lat: 47.950328, lng: 18.690882 },
    { lat: 47.950345, lng: 18.690835 },
    { lat: 47.950401, lng: 18.690684 },
    { lat: 47.950443, lng: 18.690569 },
    { lat: 47.951203, lng: 18.68866 },
    { lat: 47.951367, lng: 18.687836 },
    { lat: 47.951405, lng: 18.687659 },
    { lat: 47.951321, lng: 18.687612 },
    { lat: 47.951317, lng: 18.687609 },
    { lat: 47.951268, lng: 18.687582 },
  ],
  Tvrdošovce: [
    { lat: 48.113873, lng: 18.004233 },
    { lat: 48.112328, lng: 18.003509 },
    { lat: 48.111334, lng: 18.002815 },
    { lat: 48.10936, lng: 18.001432 },
    { lat: 48.108648, lng: 18.00099 },
    { lat: 48.107507, lng: 18.000298 },
    { lat: 48.107199, lng: 18.000107 },
    { lat: 48.106892, lng: 17.999936 },
    { lat: 48.106493, lng: 17.999717 },
    { lat: 48.105759, lng: 17.999184 },
    { lat: 48.104445, lng: 17.998126 },
    { lat: 48.103678, lng: 17.997452 },
    { lat: 48.10356, lng: 17.99737 },
    { lat: 48.103537, lng: 17.997355 },
    { lat: 48.103502, lng: 17.997329 },
    { lat: 48.103482, lng: 17.997314 },
    { lat: 48.103095, lng: 17.997044 },
    { lat: 48.101394, lng: 17.995721 },
    { lat: 48.101361, lng: 17.995695 },
    { lat: 48.100882, lng: 17.995316 },
    { lat: 48.098734, lng: 17.993587 },
    { lat: 48.0984854, lng: 17.9933902 },
    { lat: 48.097488, lng: 17.992609 },
    { lat: 48.096871, lng: 17.992061 },
    { lat: 48.096107, lng: 17.991417 },
    { lat: 48.094693, lng: 17.990141 },
    { lat: 48.094615, lng: 17.990071 },
    { lat: 48.093139, lng: 17.98873 },
    { lat: 48.090416, lng: 17.986186 },
    { lat: 48.090047, lng: 17.985903 },
    { lat: 48.089683, lng: 17.985675 },
    { lat: 48.08948, lng: 17.985569 },
    { lat: 48.088981, lng: 17.98535 },
    { lat: 48.08789, lng: 17.984828 },
    { lat: 48.087263, lng: 17.984477 },
    { lat: 48.087209, lng: 17.984446 },
    { lat: 48.087184, lng: 17.98443 },
    { lat: 48.087162, lng: 17.984423 },
    { lat: 48.086648, lng: 17.984351 },
    { lat: 48.085736, lng: 17.984135 },
    { lat: 48.083773, lng: 17.98327 },
    { lat: 48.083636, lng: 17.983199 },
    { lat: 48.082958, lng: 17.982953 },
    { lat: 48.082646, lng: 17.982845 },
    { lat: 48.082103, lng: 17.982642 },
    { lat: 48.081445, lng: 17.982374 },
    { lat: 48.080738, lng: 17.982105 },
    { lat: 48.07958, lng: 17.981709 },
    { lat: 48.078775, lng: 17.981386 },
    { lat: 48.078554, lng: 17.981304 },
    { lat: 48.077766, lng: 17.980971 },
    { lat: 48.076949, lng: 17.980589 },
    { lat: 48.076834, lng: 17.980503 },
    { lat: 48.07654, lng: 17.980319 },
    { lat: 48.075453, lng: 17.979741 },
    { lat: 48.074013, lng: 17.978976 },
    { lat: 48.073506, lng: 17.978563 },
    { lat: 48.072042, lng: 17.977749 },
    { lat: 48.070506, lng: 17.9791 },
    { lat: 48.067833, lng: 17.981315 },
    { lat: 48.06781, lng: 17.981335 },
    { lat: 48.06772, lng: 17.981409 },
    { lat: 48.067694, lng: 17.981428 },
    { lat: 48.067584, lng: 17.981523 },
    { lat: 48.065683, lng: 17.981768 },
    { lat: 48.064158, lng: 17.982184 },
    { lat: 48.063267, lng: 17.982282 },
    { lat: 48.062473, lng: 17.982377 },
    { lat: 48.061318, lng: 17.982738 },
    { lat: 48.060502, lng: 17.983048 },
    { lat: 48.059015, lng: 17.983952 },
    { lat: 48.05869, lng: 17.98416 },
    { lat: 48.058456, lng: 17.984325 },
    { lat: 48.056578, lng: 17.98657 },
    { lat: 48.054646, lng: 17.989163 },
    { lat: 48.054531, lng: 17.989331 },
    { lat: 48.053012, lng: 17.991582 },
    { lat: 48.052985, lng: 17.991622 },
    { lat: 48.052119, lng: 17.992539 },
    { lat: 48.052105, lng: 17.992556 },
    { lat: 48.051033, lng: 17.993489 },
    { lat: 48.051011, lng: 17.993514 },
    { lat: 48.049292, lng: 17.995585 },
    { lat: 48.047428, lng: 17.997827 },
    { lat: 48.047221, lng: 17.9981 },
    { lat: 48.0488471, lng: 18.000539 },
    { lat: 48.0501631, lng: 18.0023429 },
    { lat: 48.0519129, lng: 18.0051716 },
    { lat: 48.053852, lng: 18.0077678 },
    { lat: 48.055447, lng: 18.0101634 },
    { lat: 48.0607744, lng: 18.0300816 },
    { lat: 48.0624029, lng: 18.0368985 },
    { lat: 48.0636487, lng: 18.0399045 },
    { lat: 48.0686997, lng: 18.0502889 },
    { lat: 48.0694281, lng: 18.0517865 },
    { lat: 48.0708789, lng: 18.0569937 },
    { lat: 48.0732155, lng: 18.0649907 },
    { lat: 48.07507, lng: 18.0679376 },
    { lat: 48.077755, lng: 18.0735632 },
    { lat: 48.0654016, lng: 18.0882225 },
    { lat: 48.0666683, lng: 18.0905549 },
    { lat: 48.0684648, lng: 18.0937329 },
    { lat: 48.0687087, lng: 18.0941739 },
    { lat: 48.0700902, lng: 18.0975141 },
    { lat: 48.0710865, lng: 18.0995942 },
    { lat: 48.0724648, lng: 18.1017949 },
    { lat: 48.0742004, lng: 18.1043871 },
    { lat: 48.0761381, lng: 18.1077302 },
    { lat: 48.0787797, lng: 18.1116075 },
    { lat: 48.0800988, lng: 18.1139557 },
    { lat: 48.082676, lng: 18.1162651 },
    { lat: 48.0867506, lng: 18.1118915 },
    { lat: 48.0878865, lng: 18.1143125 },
    { lat: 48.0888712, lng: 18.1162367 },
    { lat: 48.090944, lng: 18.1161367 },
    { lat: 48.0922439, lng: 18.1155589 },
    { lat: 48.0936022, lng: 18.1157825 },
    { lat: 48.0949629, lng: 18.1150675 },
    { lat: 48.09736, lng: 18.1126382 },
    { lat: 48.1002396, lng: 18.1093583 },
    { lat: 48.1017959, lng: 18.1074861 },
    { lat: 48.1041166, lng: 18.1051601 },
    { lat: 48.1056606, lng: 18.1034043 },
    { lat: 48.1098127, lng: 18.0989334 },
    { lat: 48.1116432, lng: 18.0967376 },
    { lat: 48.1136519, lng: 18.0950924 },
    { lat: 48.1171585, lng: 18.093395 },
    { lat: 48.1284943, lng: 18.078815 },
    { lat: 48.1264491, lng: 18.0721295 },
    { lat: 48.1207764, lng: 18.05305 },
    { lat: 48.1195796, lng: 18.0487361 },
    { lat: 48.1185305, lng: 18.0454666 },
    { lat: 48.1223623, lng: 18.0425273 },
    { lat: 48.1267634, lng: 18.0383158 },
    { lat: 48.1268296, lng: 18.0382519 },
    { lat: 48.1241806, lng: 18.033764 },
    { lat: 48.1227157, lng: 18.0280337 },
    { lat: 48.1217674, lng: 18.0262932 },
    { lat: 48.1190636, lng: 18.020937 },
    { lat: 48.1169972, lng: 18.0175978 },
    { lat: 48.1154861, lng: 18.012506 },
    { lat: 48.113873, lng: 18.004233 },
  ],
  Podhájska: [
    { lat: 48.1170662, lng: 18.3118888 },
    { lat: 48.1161385, lng: 18.3121992 },
    { lat: 48.1135842, lng: 18.3132643 },
    { lat: 48.1111995, lng: 18.3149513 },
    { lat: 48.1090842, lng: 18.3163379 },
    { lat: 48.1060504, lng: 18.318688 },
    { lat: 48.1045532, lng: 18.3196804 },
    { lat: 48.102232, lng: 18.3214685 },
    { lat: 48.1005757, lng: 18.3229123 },
    { lat: 48.0978149, lng: 18.32562 },
    { lat: 48.0972279, lng: 18.3259104 },
    { lat: 48.0964217, lng: 18.3260969 },
    { lat: 48.0940961, lng: 18.326295 },
    { lat: 48.0923622, lng: 18.3266931 },
    { lat: 48.0905292, lng: 18.3274531 },
    { lat: 48.0890775, lng: 18.3282303 },
    { lat: 48.0861843, lng: 18.3299454 },
    { lat: 48.0849925, lng: 18.330473 },
    { lat: 48.0833836, lng: 18.3315583 },
    { lat: 48.0830907, lng: 18.3316668 },
    { lat: 48.0829051, lng: 18.3316575 },
    { lat: 48.0821936, lng: 18.3314861 },
    { lat: 48.0818642, lng: 18.3313481 },
    { lat: 48.0818623, lng: 18.3314143 },
    { lat: 48.0814556, lng: 18.3337181 },
    { lat: 48.081093, lng: 18.3363024 },
    { lat: 48.0812044, lng: 18.3379205 },
    { lat: 48.0813588, lng: 18.3391231 },
    { lat: 48.0830236, lng: 18.3419681 },
    { lat: 48.0832841, lng: 18.3438478 },
    { lat: 48.0826362, lng: 18.3462411 },
    { lat: 48.0824375, lng: 18.3473065 },
    { lat: 48.0818897, lng: 18.3492223 },
    { lat: 48.0817948, lng: 18.3507445 },
    { lat: 48.0821878, lng: 18.3511653 },
    { lat: 48.084356, lng: 18.3524322 },
    { lat: 48.0850188, lng: 18.352718 },
    { lat: 48.0856245, lng: 18.3531332 },
    { lat: 48.0868984, lng: 18.3549214 },
    { lat: 48.0872668, lng: 18.355584 },
    { lat: 48.0877436, lng: 18.3553204 },
    { lat: 48.0883191, lng: 18.3564537 },
    { lat: 48.0888519, lng: 18.3565761 },
    { lat: 48.0897324, lng: 18.3574181 },
    { lat: 48.0925794, lng: 18.3550846 },
    { lat: 48.0929792, lng: 18.3544982 },
    { lat: 48.0941873, lng: 18.3533193 },
    { lat: 48.096582, lng: 18.3513659 },
    { lat: 48.0971537, lng: 18.3505841 },
    { lat: 48.0979181, lng: 18.350357 },
    { lat: 48.0981918, lng: 18.3501921 },
    { lat: 48.0990875, lng: 18.3491185 },
    { lat: 48.1000607, lng: 18.3481759 },
    { lat: 48.1001495, lng: 18.3484901 },
    { lat: 48.1004016, lng: 18.3493687 },
    { lat: 48.1003905, lng: 18.34971 },
    { lat: 48.1001708, lng: 18.3504311 },
    { lat: 48.1002108, lng: 18.3507363 },
    { lat: 48.0998653, lng: 18.3515862 },
    { lat: 48.0996514, lng: 18.3519712 },
    { lat: 48.0992032, lng: 18.3526407 },
    { lat: 48.0991316, lng: 18.3525136 },
    { lat: 48.0988983, lng: 18.3526765 },
    { lat: 48.098698, lng: 18.3531826 },
    { lat: 48.0987555, lng: 18.3533991 },
    { lat: 48.099088, lng: 18.3540085 },
    { lat: 48.0991126, lng: 18.354778 },
    { lat: 48.0992537, lng: 18.3552144 },
    { lat: 48.0992742, lng: 18.3562904 },
    { lat: 48.1023485, lng: 18.3607152 },
    { lat: 48.1025705, lng: 18.361824 },
    { lat: 48.1021797, lng: 18.362752 },
    { lat: 48.1013729, lng: 18.3639836 },
    { lat: 48.1020332, lng: 18.3661371 },
    { lat: 48.1024287, lng: 18.3672399 },
    { lat: 48.1025106, lng: 18.3676496 },
    { lat: 48.1024402, lng: 18.3678014 },
    { lat: 48.1022807, lng: 18.3679529 },
    { lat: 48.1018769, lng: 18.3681525 },
    { lat: 48.1020191, lng: 18.3685514 },
    { lat: 48.1019875, lng: 18.3690072 },
    { lat: 48.1020557, lng: 18.3692167 },
    { lat: 48.1025065, lng: 18.3694449 },
    { lat: 48.1030149, lng: 18.3701148 },
    { lat: 48.1025887, lng: 18.3702774 },
    { lat: 48.1024283, lng: 18.3704138 },
    { lat: 48.1021682, lng: 18.3709006 },
    { lat: 48.1018652, lng: 18.3711281 },
    { lat: 48.1015347, lng: 18.371329 },
    { lat: 48.1011044, lng: 18.3714398 },
    { lat: 48.1012746, lng: 18.3727539 },
    { lat: 48.1012248, lng: 18.3733857 },
    { lat: 48.1013584, lng: 18.3733737 },
    { lat: 48.1019788, lng: 18.3733734 },
    { lat: 48.1026772, lng: 18.3731747 },
    { lat: 48.1030895, lng: 18.3731441 },
    { lat: 48.1040948, lng: 18.3727873 },
    { lat: 48.1050728, lng: 18.3722646 },
    { lat: 48.1070897, lng: 18.3716596 },
    { lat: 48.1076061, lng: 18.3712177 },
    { lat: 48.1084258, lng: 18.3703142 },
    { lat: 48.1093864, lng: 18.3694056 },
    { lat: 48.1100706, lng: 18.3691352 },
    { lat: 48.1113564, lng: 18.3682941 },
    { lat: 48.1116038, lng: 18.3678794 },
    { lat: 48.1119957, lng: 18.3673911 },
    { lat: 48.1129254, lng: 18.3653625 },
    { lat: 48.1135187, lng: 18.363231 },
    { lat: 48.1140319, lng: 18.3611643 },
    { lat: 48.1140577, lng: 18.361023 },
    { lat: 48.1144647, lng: 18.3582935 },
    { lat: 48.1145553, lng: 18.3552395 },
    { lat: 48.1148624, lng: 18.3528425 },
    { lat: 48.1148315, lng: 18.3525569 },
    { lat: 48.1148894, lng: 18.3520666 },
    { lat: 48.1169711, lng: 18.3478991 },
    { lat: 48.1174192, lng: 18.3470323 },
    { lat: 48.117588, lng: 18.3466285 },
    { lat: 48.1180604, lng: 18.3460054 },
    { lat: 48.1181298, lng: 18.3458381 },
    { lat: 48.1185982, lng: 18.3433052 },
    { lat: 48.1187072, lng: 18.3419666 },
    { lat: 48.1191327, lng: 18.3418709 },
    { lat: 48.1192231, lng: 18.3403281 },
    { lat: 48.1191601, lng: 18.3387472 },
    { lat: 48.1189711, lng: 18.3376254 },
    { lat: 48.1185042, lng: 18.3356311 },
    { lat: 48.1186766, lng: 18.3354895 },
    { lat: 48.1185601, lng: 18.3350672 },
    { lat: 48.1184933, lng: 18.3342315 },
    { lat: 48.1186884, lng: 18.3341703 },
    { lat: 48.1182372, lng: 18.3311833 },
    { lat: 48.1181472, lng: 18.3302019 },
    { lat: 48.1180349, lng: 18.329635 },
    { lat: 48.1177652, lng: 18.3287237 },
    { lat: 48.1197041, lng: 18.3265011 },
    { lat: 48.1195947, lng: 18.3260054 },
    { lat: 48.1195163, lng: 18.325187 },
    { lat: 48.1194785, lng: 18.3242268 },
    { lat: 48.1194862, lng: 18.3239886 },
    { lat: 48.1195469, lng: 18.3238053 },
    { lat: 48.1170662, lng: 18.3118888 },
  ],
  Dubník: [
    { lat: 47.940153, lng: 18.27199 },
    { lat: 47.940105, lng: 18.272097 },
    { lat: 47.939761, lng: 18.272989 },
    { lat: 47.939426, lng: 18.273752 },
    { lat: 47.939093, lng: 18.274495 },
    { lat: 47.938889, lng: 18.274801 },
    { lat: 47.938627, lng: 18.275177 },
    { lat: 47.938405, lng: 18.275499 },
    { lat: 47.938061, lng: 18.276197 },
    { lat: 47.938029, lng: 18.27723 },
    { lat: 47.937988, lng: 18.278616 },
    { lat: 47.937982, lng: 18.279488 },
    { lat: 47.937975, lng: 18.280586 },
    { lat: 47.937964, lng: 18.281636 },
    { lat: 47.937631, lng: 18.282711 },
    { lat: 47.937396, lng: 18.283474 },
    { lat: 47.937154, lng: 18.284031 },
    { lat: 47.936825, lng: 18.2848 },
    { lat: 47.936715, lng: 18.285046 },
    { lat: 47.936506, lng: 18.285411 },
    { lat: 47.936198, lng: 18.285964 },
    { lat: 47.93579, lng: 18.286667 },
    { lat: 47.935249, lng: 18.287625 },
    { lat: 47.934828, lng: 18.288365 },
    { lat: 47.934798, lng: 18.288416 },
    { lat: 47.934782, lng: 18.288444 },
    { lat: 47.934774, lng: 18.288459 },
    { lat: 47.934729, lng: 18.288535 },
    { lat: 47.934684, lng: 18.288629 },
    { lat: 47.93464, lng: 18.288718 },
    { lat: 47.934441, lng: 18.289174 },
    { lat: 47.93432, lng: 18.289439 },
    { lat: 47.934199, lng: 18.289706 },
    { lat: 47.934139, lng: 18.289838 },
    { lat: 47.934043, lng: 18.29005 },
    { lat: 47.933995, lng: 18.290156 },
    { lat: 47.933807, lng: 18.290422 },
    { lat: 47.933727, lng: 18.290538 },
    { lat: 47.933617, lng: 18.290691 },
    { lat: 47.933374, lng: 18.291043 },
    { lat: 47.932953, lng: 18.291648 },
    { lat: 47.932849, lng: 18.291797 },
    { lat: 47.932721, lng: 18.291976 },
    { lat: 47.932571, lng: 18.292327 },
    { lat: 47.932444, lng: 18.292631 },
    { lat: 47.932242, lng: 18.293103 },
    { lat: 47.931964, lng: 18.293761 },
    { lat: 47.931686, lng: 18.294416 },
    { lat: 47.931576, lng: 18.294684 },
    { lat: 47.931173, lng: 18.295527 },
    { lat: 47.930632, lng: 18.296643 },
    { lat: 47.93042, lng: 18.29709 },
    { lat: 47.93027, lng: 18.297404 },
    { lat: 47.929882, lng: 18.298232 },
    { lat: 47.929609, lng: 18.298814 },
    { lat: 47.929561, lng: 18.298936 },
    { lat: 47.929384, lng: 18.299232 },
    { lat: 47.929099, lng: 18.29971 },
    { lat: 47.928864, lng: 18.300098 },
    { lat: 47.928505, lng: 18.300692 },
    { lat: 47.928291, lng: 18.301044 },
    { lat: 47.928206, lng: 18.301157 },
    { lat: 47.927841, lng: 18.301676 },
    { lat: 47.927479, lng: 18.302202 },
    { lat: 47.927309, lng: 18.302444 },
    { lat: 47.927202, lng: 18.302607 },
    { lat: 47.927037, lng: 18.302866 },
    { lat: 47.926956, lng: 18.302993 },
    { lat: 47.926755, lng: 18.303302 },
    { lat: 47.926426, lng: 18.30382 },
    { lat: 47.926161, lng: 18.304233 },
    { lat: 47.925864, lng: 18.304698 },
    { lat: 47.925752, lng: 18.304864 },
    { lat: 47.925626, lng: 18.30506 },
    { lat: 47.92539, lng: 18.305416 },
    { lat: 47.92533, lng: 18.305508 },
    { lat: 47.924981, lng: 18.306048 },
    { lat: 47.924866, lng: 18.30624 },
    { lat: 47.924473, lng: 18.306924 },
    { lat: 47.924101, lng: 18.307565 },
    { lat: 47.923929, lng: 18.307861 },
    { lat: 47.923526, lng: 18.308574 },
    { lat: 47.923228, lng: 18.309099 },
    { lat: 47.922974, lng: 18.309544 },
    { lat: 47.922972, lng: 18.30958 },
    { lat: 47.923165, lng: 18.309895 },
    { lat: 47.923394, lng: 18.310261 },
    { lat: 47.923716, lng: 18.310793 },
    { lat: 47.923955, lng: 18.311195 },
    { lat: 47.924044, lng: 18.311364 },
    { lat: 47.924118, lng: 18.311519 },
    { lat: 47.924491, lng: 18.312257 },
    { lat: 47.924549, lng: 18.312371 },
    { lat: 47.924817, lng: 18.31275 },
    { lat: 47.925057, lng: 18.313096 },
    { lat: 47.925266, lng: 18.313389 },
    { lat: 47.925595, lng: 18.31384 },
    { lat: 47.925991, lng: 18.314398 },
    { lat: 47.926368, lng: 18.314927 },
    { lat: 47.926804, lng: 18.315456 },
    { lat: 47.927134, lng: 18.315858 },
    { lat: 47.927419, lng: 18.316189 },
    { lat: 47.927701, lng: 18.316513 },
    { lat: 47.928051, lng: 18.316911 },
    { lat: 47.92835, lng: 18.317244 },
    { lat: 47.928817, lng: 18.317757 },
    { lat: 47.929192, lng: 18.318167 },
    { lat: 47.929686, lng: 18.318722 },
    { lat: 47.93028, lng: 18.319388 },
    { lat: 47.930976, lng: 18.320095 },
    { lat: 47.931124, lng: 18.320278 },
    { lat: 47.931427, lng: 18.320637 },
    { lat: 47.932037, lng: 18.321391 },
    { lat: 47.932612, lng: 18.322148 },
    { lat: 47.932961, lng: 18.322601 },
    { lat: 47.933403, lng: 18.323196 },
    { lat: 47.933986, lng: 18.323987 },
    { lat: 47.934012, lng: 18.323878 },
    { lat: 47.934337, lng: 18.323885 },
    { lat: 47.93486, lng: 18.322781 },
    { lat: 47.935738, lng: 18.320971 },
    { lat: 47.936166, lng: 18.318954 },
    { lat: 47.937439, lng: 18.316168 },
    { lat: 47.937629, lng: 18.316135 },
    { lat: 47.938483, lng: 18.314352 },
    { lat: 47.939092, lng: 18.31362 },
    { lat: 47.939739, lng: 18.312263 },
    { lat: 47.939921, lng: 18.311883 },
    { lat: 47.939782, lng: 18.311609 },
    { lat: 47.939884, lng: 18.311409 },
    { lat: 47.939952, lng: 18.311297 },
    { lat: 47.939956, lng: 18.311293 },
    { lat: 47.939993, lng: 18.311236 },
    { lat: 47.940174, lng: 18.311249 },
    { lat: 47.940341, lng: 18.310866 },
    { lat: 47.940854, lng: 18.30965 },
    { lat: 47.941011, lng: 18.309293 },
    { lat: 47.941473, lng: 18.30818 },
    { lat: 47.941759, lng: 18.307504 },
    { lat: 47.941871, lng: 18.30724 },
    { lat: 47.94217, lng: 18.306646 },
    { lat: 47.94235, lng: 18.306301 },
    { lat: 47.942749, lng: 18.305523 },
    { lat: 47.942694, lng: 18.305423 },
    { lat: 47.943069, lng: 18.304681 },
    { lat: 47.943145, lng: 18.304731 },
    { lat: 47.943326, lng: 18.30436 },
    { lat: 47.943626, lng: 18.303746 },
    { lat: 47.944091, lng: 18.302801 },
    { lat: 47.944303, lng: 18.30237 },
    { lat: 47.944468, lng: 18.30205 },
    { lat: 47.944515, lng: 18.301959 },
    { lat: 47.944362, lng: 18.301767 },
    { lat: 47.944612, lng: 18.301211 },
    { lat: 47.944934, lng: 18.301058 },
    { lat: 47.945191, lng: 18.300495 },
    { lat: 47.945679, lng: 18.299418 },
    { lat: 47.945742, lng: 18.299265 },
    { lat: 47.945916, lng: 18.298888 },
    { lat: 47.946511, lng: 18.297545 },
    { lat: 47.947144, lng: 18.296135 },
    { lat: 47.947462, lng: 18.295441 },
    { lat: 47.947886, lng: 18.294515 },
    { lat: 47.947995, lng: 18.294288 },
    { lat: 47.948042, lng: 18.294188 },
    { lat: 47.948317, lng: 18.29359 },
    { lat: 47.948678, lng: 18.29277 },
    { lat: 47.948649, lng: 18.292672 },
    { lat: 47.94891, lng: 18.292101 },
    { lat: 47.949108, lng: 18.291676 },
    { lat: 47.949324, lng: 18.291224 },
    { lat: 47.949501, lng: 18.290845 },
    { lat: 47.949733, lng: 18.29011 },
    { lat: 47.949914, lng: 18.28954 },
    { lat: 47.949906, lng: 18.289534 },
    { lat: 47.949733, lng: 18.28934 },
    { lat: 47.949839, lng: 18.288953 },
    { lat: 47.950153, lng: 18.289131 },
    { lat: 47.950536, lng: 18.288264 },
    { lat: 47.950881, lng: 18.287475 },
    { lat: 47.951078, lng: 18.287017 },
    { lat: 47.951217, lng: 18.286698 },
    { lat: 47.951287, lng: 18.286558 },
    { lat: 47.951525, lng: 18.286156 },
    { lat: 47.951737, lng: 18.285793 },
    { lat: 47.951767, lng: 18.285733 },
    { lat: 47.952071, lng: 18.285092 },
    { lat: 47.952413, lng: 18.284372 },
    { lat: 47.952717, lng: 18.283751 },
    { lat: 47.952898, lng: 18.283363 },
    { lat: 47.9516275, lng: 18.2819974 },
    { lat: 47.9504858, lng: 18.2805306 },
    { lat: 47.9490344, lng: 18.2784703 },
    { lat: 47.9477435, lng: 18.2774341 },
    { lat: 47.9441904, lng: 18.2751038 },
    { lat: 47.943349, lng: 18.274155 },
    { lat: 47.940153, lng: 18.27199 },
  ],
  DubníkExt: [
    { lat: 47.9349423, lng: 18.3253767 },
    { lat: 47.935483, lng: 18.325899 },
    { lat: 47.936161, lng: 18.326764 },
    { lat: 47.936588, lng: 18.327312 },
    { lat: 47.93719, lng: 18.328099 },
    { lat: 47.937761, lng: 18.328834 },
    { lat: 47.938047, lng: 18.329132 },
    { lat: 47.938062, lng: 18.329153 },
    { lat: 47.937968, lng: 18.329294 },
    { lat: 47.937677, lng: 18.329992 },
    { lat: 47.937328, lng: 18.330629 },
    { lat: 47.936971, lng: 18.331266 },
    { lat: 47.936538, lng: 18.331966 },
    { lat: 47.935875, lng: 18.333 },
    { lat: 47.935299, lng: 18.334124 },
    { lat: 47.935048, lng: 18.334601 },
    { lat: 47.934935, lng: 18.334789 },
    { lat: 47.934577, lng: 18.335416 },
    { lat: 47.934168, lng: 18.336105 },
    { lat: 47.933926, lng: 18.336516 },
    { lat: 47.933775, lng: 18.336761 },
    { lat: 47.933615, lng: 18.336983 },
    { lat: 47.933347, lng: 18.337397 },
    { lat: 47.932932, lng: 18.338182 },
    { lat: 47.932751, lng: 18.338522 },
    { lat: 47.932316, lng: 18.339177 },
    { lat: 47.931908, lng: 18.339798 },
    { lat: 47.931427, lng: 18.340522 },
    { lat: 47.931146, lng: 18.340952 },
    { lat: 47.930737, lng: 18.341443 },
    { lat: 47.930407, lng: 18.341851 },
    { lat: 47.930165, lng: 18.342139 },
    { lat: 47.929992, lng: 18.342308 },
    { lat: 47.929677, lng: 18.342617 },
    { lat: 47.929439, lng: 18.342854 },
    { lat: 47.929408, lng: 18.342866 },
    { lat: 47.9346003, lng: 18.3586548 },
    { lat: 47.9358561, lng: 18.3642018 },
    { lat: 47.9362049, lng: 18.3652555 },
    { lat: 47.9374165, lng: 18.3680698 },
    { lat: 47.9378643, lng: 18.3697911 },
    { lat: 47.9402363, lng: 18.375854 },
    { lat: 47.9402423, lng: 18.376297 },
    { lat: 47.9394502, lng: 18.377665 },
    { lat: 47.938118, lng: 18.3802327 },
    { lat: 47.9384901, lng: 18.3810015 },
    { lat: 47.9376332, lng: 18.3822231 },
    { lat: 47.9396568, lng: 18.387046 },
    { lat: 47.940489, lng: 18.3893896 },
    { lat: 47.9415422, lng: 18.3892176 },
    { lat: 47.9415696, lng: 18.3899396 },
    { lat: 47.9417566, lng: 18.3908735 },
    { lat: 47.9421942, lng: 18.3918653 },
    { lat: 47.9444746, lng: 18.4010527 },
    { lat: 47.944379, lng: 18.4024538 },
    { lat: 47.9444968, lng: 18.4029605 },
    { lat: 47.943187, lng: 18.4044188 },
    { lat: 47.940686, lng: 18.4062347 },
    { lat: 47.9418185, lng: 18.4123768 },
    { lat: 47.941123, lng: 18.4120504 },
    { lat: 47.9410486, lng: 18.412311 },
    { lat: 47.9403917, lng: 18.4134434 },
    { lat: 47.9398464, lng: 18.4145329 },
    { lat: 47.9400532, lng: 18.4156572 },
    { lat: 47.9407309, lng: 18.4202304 },
    { lat: 47.9409964, lng: 18.4213575 },
    { lat: 47.9410545, lng: 18.4213851 },
    { lat: 47.9414451, lng: 18.4231944 },
    { lat: 47.9418366, lng: 18.425513 },
    { lat: 47.9419147, lng: 18.4263195 },
    { lat: 47.9423851, lng: 18.4274395 },
    { lat: 47.9428029, lng: 18.4288281 },
    { lat: 47.9430993, lng: 18.4294818 },
    { lat: 47.9432085, lng: 18.4302212 },
    { lat: 47.943519, lng: 18.4314857 },
    { lat: 47.943599, lng: 18.4320453 },
    { lat: 47.9435711, lng: 18.4320706 },
    { lat: 47.9440658, lng: 18.4332612 },
    { lat: 47.9441965, lng: 18.434102 },
    { lat: 47.9445741, lng: 18.4350767 },
    { lat: 47.9447941, lng: 18.4361794 },
    { lat: 47.9449279, lng: 18.4372978 },
    { lat: 47.9450263, lng: 18.4388239 },
    { lat: 47.9451655, lng: 18.4391352 },
    { lat: 47.9455017, lng: 18.4396563 },
    { lat: 47.9459594, lng: 18.4406119 },
    { lat: 47.9461396, lng: 18.4411077 },
    { lat: 47.9463594, lng: 18.4415068 },
    { lat: 47.9466345, lng: 18.4421875 },
    { lat: 47.9468106, lng: 18.4427881 },
    { lat: 47.9468414, lng: 18.4430759 },
    { lat: 47.9467937, lng: 18.4435136 },
    { lat: 47.9460135, lng: 18.4447445 },
    { lat: 47.9456272, lng: 18.4457792 },
    { lat: 47.9453057, lng: 18.4464856 },
    { lat: 47.945143, lng: 18.4473221 },
    { lat: 47.9448226, lng: 18.4486294 },
    { lat: 47.944562, lng: 18.4493729 },
    { lat: 47.9442763, lng: 18.4507138 },
    { lat: 47.9440109, lng: 18.4521338 },
    { lat: 47.9439797, lng: 18.4526017 },
    { lat: 47.943857, lng: 18.45301 },
    { lat: 47.9438134, lng: 18.4535175 },
    { lat: 47.9438062, lng: 18.4536922 },
    { lat: 47.9441377, lng: 18.4554176 },
    { lat: 47.9441904, lng: 18.4561967 },
    { lat: 47.9441136, lng: 18.4570187 },
    { lat: 47.9441665, lng: 18.4583459 },
    { lat: 47.9440688, lng: 18.459921 },
    { lat: 47.9442085, lng: 18.4613307 },
    { lat: 47.9446533, lng: 18.4636275 },
    { lat: 47.9452081, lng: 18.4641885 },
    { lat: 47.9452645, lng: 18.4641913 },
    { lat: 47.9456584, lng: 18.4643142 },
    { lat: 47.9462493, lng: 18.4646926 },
    { lat: 47.9470511, lng: 18.4650502 },
    { lat: 47.9476097, lng: 18.4656156 },
    { lat: 47.948582, lng: 18.466213 },
    { lat: 47.948596, lng: 18.466204 },
    { lat: 47.948757, lng: 18.466117 },
    { lat: 47.9489, lng: 18.466049 },
    { lat: 47.949116, lng: 18.465903 },
    { lat: 47.949303, lng: 18.465781 },
    { lat: 47.949321, lng: 18.465767 },
    { lat: 47.949687, lng: 18.465543 },
    { lat: 47.950048, lng: 18.46531 },
    { lat: 47.9501, lng: 18.465262 },
    { lat: 47.950424, lng: 18.464926 },
    { lat: 47.950733, lng: 18.464617 },
    { lat: 47.95089, lng: 18.464425 },
    { lat: 47.951344, lng: 18.463883 },
    { lat: 47.951844, lng: 18.463588 },
    { lat: 47.952034, lng: 18.463458 },
    { lat: 47.952549, lng: 18.463078 },
    { lat: 47.952634, lng: 18.463029 },
    { lat: 47.952921, lng: 18.462654 },
    { lat: 47.953082, lng: 18.462268 },
    { lat: 47.95328, lng: 18.461783 },
    { lat: 47.953347, lng: 18.461627 },
    { lat: 47.95378, lng: 18.460752 },
    { lat: 47.953907, lng: 18.46065 },
    { lat: 47.954123, lng: 18.460469 },
    { lat: 47.954598, lng: 18.460079 },
    { lat: 47.954692, lng: 18.459999 },
    { lat: 47.955404, lng: 18.459293 },
    { lat: 47.955531, lng: 18.459163 },
    { lat: 47.956182, lng: 18.458524 },
    { lat: 47.956661, lng: 18.457755 },
    { lat: 47.956746, lng: 18.457624 },
    { lat: 47.957747, lng: 18.456524 },
    { lat: 47.959, lng: 18.455633 },
    { lat: 47.959319, lng: 18.455515 },
    { lat: 47.959412, lng: 18.455479 },
    { lat: 47.959941, lng: 18.455381 },
    { lat: 47.960228, lng: 18.45532 },
    { lat: 47.960586, lng: 18.455113 },
    { lat: 47.961281, lng: 18.4547 },
    { lat: 47.96283, lng: 18.454033 },
    { lat: 47.963079, lng: 18.453898 },
    { lat: 47.963434, lng: 18.453672 },
    { lat: 47.964617, lng: 18.453239 },
    { lat: 47.965066, lng: 18.453168 },
    { lat: 47.965487, lng: 18.453091 },
    { lat: 47.965947, lng: 18.45303 },
    { lat: 47.966287, lng: 18.452978 },
    { lat: 47.966337, lng: 18.452966 },
    { lat: 47.967145, lng: 18.452727 },
    { lat: 47.968449, lng: 18.452693 },
    { lat: 47.968602, lng: 18.452686 },
    { lat: 47.969954, lng: 18.452603 },
    { lat: 47.970228, lng: 18.452578 },
    { lat: 47.970688, lng: 18.452405 },
    { lat: 47.971574, lng: 18.452058 },
    { lat: 47.972134, lng: 18.4513 },
    { lat: 47.972421, lng: 18.450918 },
    { lat: 47.973177, lng: 18.450439 },
    { lat: 47.973897, lng: 18.449978 },
    { lat: 47.975548, lng: 18.450139 },
    { lat: 47.975773, lng: 18.450251 },
    { lat: 47.975919, lng: 18.450319 },
    { lat: 47.975981, lng: 18.450299 },
    { lat: 47.976804, lng: 18.449943 },
    { lat: 47.977003, lng: 18.449853 },
    { lat: 47.977123, lng: 18.449784 },
    { lat: 47.9762584, lng: 18.448662 },
    { lat: 47.9754071, lng: 18.4473217 },
    { lat: 47.9740682, lng: 18.4448138 },
    { lat: 47.9733245, lng: 18.4435852 },
    { lat: 47.9726832, lng: 18.44269 },
    { lat: 47.9718859, lng: 18.4412319 },
    { lat: 47.9733187, lng: 18.4391455 },
    { lat: 47.9735491, lng: 18.4385894 },
    { lat: 47.9736549, lng: 18.4380816 },
    { lat: 47.9742732, lng: 18.4365167 },
    { lat: 47.9757769, lng: 18.4337087 },
    { lat: 47.976756, lng: 18.4321033 },
    { lat: 47.9767712, lng: 18.4321549 },
    { lat: 47.9768397, lng: 18.4320653 },
    { lat: 47.9774021, lng: 18.4308743 },
    { lat: 47.9779514, lng: 18.4297109 },
    { lat: 47.9784222, lng: 18.4288103 },
    { lat: 47.9785248, lng: 18.428614 },
    { lat: 47.977983, lng: 18.4274466 },
    { lat: 47.9776896, lng: 18.4265234 },
    { lat: 47.9772308, lng: 18.4255083 },
    { lat: 47.9768782, lng: 18.4243145 },
    { lat: 47.9765917, lng: 18.4227969 },
    { lat: 47.9763673, lng: 18.4211353 },
    { lat: 47.9759631, lng: 18.4195089 },
    { lat: 47.9755606, lng: 18.418433 },
    { lat: 47.9753124, lng: 18.4175536 },
    { lat: 47.9749955, lng: 18.4161353 },
    { lat: 47.9760805, lng: 18.4144117 },
    { lat: 47.976026, lng: 18.4140449 },
    { lat: 47.9760956, lng: 18.4131592 },
    { lat: 47.9760905, lng: 18.4121314 },
    { lat: 47.9768872, lng: 18.4114954 },
    { lat: 47.9773703, lng: 18.4107223 },
    { lat: 47.9785743, lng: 18.4106842 },
    { lat: 47.9788054, lng: 18.4080046 },
    { lat: 47.9783467, lng: 18.4069946 },
    { lat: 47.9774032, lng: 18.4057301 },
    { lat: 47.9762711, lng: 18.4040468 },
    { lat: 47.975113, lng: 18.4020197 },
    { lat: 47.976485, lng: 18.400107 },
    { lat: 47.9737166, lng: 18.3947268 },
    { lat: 47.9725817, lng: 18.3926777 },
    { lat: 47.9734414, lng: 18.3917621 },
    { lat: 47.9760417, lng: 18.3895676 },
    { lat: 47.9701702, lng: 18.3773056 },
    { lat: 47.9711473, lng: 18.3758568 },
    { lat: 47.9704966, lng: 18.3746104 },
    { lat: 47.9699507, lng: 18.3734047 },
    { lat: 47.9696167, lng: 18.3724903 },
    { lat: 47.9681811, lng: 18.3691071 },
    { lat: 47.9665204, lng: 18.3656962 },
    { lat: 47.9703137, lng: 18.3569976 },
    { lat: 47.9708971, lng: 18.3557716 },
    { lat: 47.9712098, lng: 18.3549249 },
    { lat: 47.9716722, lng: 18.3533751 },
    { lat: 47.9718989, lng: 18.3528409 },
    { lat: 47.9718685, lng: 18.3527796 },
    { lat: 47.9747771, lng: 18.3420583 },
    { lat: 47.9747565, lng: 18.3420166 },
    { lat: 47.9781308, lng: 18.3378802 },
    { lat: 47.9774992, lng: 18.3375271 },
    { lat: 47.976132, lng: 18.3374742 },
    { lat: 47.9745462, lng: 18.3369882 },
    { lat: 47.9748662, lng: 18.3360135 },
    { lat: 47.9760419, lng: 18.3341589 },
    { lat: 47.9761438, lng: 18.3340643 },
    { lat: 47.9761075, lng: 18.3340045 },
    { lat: 47.9760529, lng: 18.3339378 },
    { lat: 47.97376, lng: 18.3308533 },
    { lat: 47.9604934, lng: 18.343602 },
    { lat: 47.9612226, lng: 18.3445337 },
    { lat: 47.9616798, lng: 18.3448778 },
    { lat: 47.9609158, lng: 18.3468517 },
    { lat: 47.9596268, lng: 18.3474519 },
    { lat: 47.958211, lng: 18.3471406 },
    { lat: 47.957085, lng: 18.3479123 },
    { lat: 47.9562554, lng: 18.347787 },
    { lat: 47.9525405, lng: 18.343939 },
    { lat: 47.9507592, lng: 18.341988 },
    { lat: 47.9490085, lng: 18.3409458 },
    { lat: 47.9472157, lng: 18.3397777 },
    { lat: 47.9418179, lng: 18.3350586 },
    { lat: 47.940906, lng: 18.3338517 },
    { lat: 47.939502, lng: 18.3313583 },
    { lat: 47.9383243, lng: 18.329407 },
    { lat: 47.9381366, lng: 18.3291841 },
    { lat: 47.9381079, lng: 18.3292353 },
    { lat: 47.938062, lng: 18.329153 },
    { lat: 47.9387886, lng: 18.3277616 },
    { lat: 47.9402668, lng: 18.3252026 },
    { lat: 47.9418704, lng: 18.3222539 },
    { lat: 47.9434209, lng: 18.3202889 },
    { lat: 47.9447735, lng: 18.3187311 },
    { lat: 47.9454444, lng: 18.3178168 },
    { lat: 47.9461749, lng: 18.3167063 },
    { lat: 47.9480833, lng: 18.3135679 },
    { lat: 47.9494337, lng: 18.3117735 },
    { lat: 47.949539, lng: 18.3115243 },
    { lat: 47.9496495, lng: 18.3110725 },
    { lat: 47.9498125, lng: 18.3093892 },
    { lat: 47.9497676, lng: 18.3082733 },
    { lat: 47.9499643, lng: 18.306275 },
    { lat: 47.9503731, lng: 18.3035499 },
    { lat: 47.9513248, lng: 18.2983603 },
    { lat: 47.9517955, lng: 18.294671 },
    { lat: 47.9518174, lng: 18.2930345 },
    { lat: 47.9517806, lng: 18.2916877 },
    { lat: 47.952017, lng: 18.2896781 },
    { lat: 47.9526271, lng: 18.2880467 },
    { lat: 47.9529565, lng: 18.2866345 },
    { lat: 47.952967, lng: 18.2851545 },
    { lat: 47.952938, lng: 18.28428 },
    { lat: 47.952738, lng: 18.284756 },
    { lat: 47.952415, lng: 18.285506 },
    { lat: 47.952273, lng: 18.285855 },
    { lat: 47.952154, lng: 18.286137 },
    { lat: 47.95205, lng: 18.286369 },
    { lat: 47.951806, lng: 18.286904 },
    { lat: 47.951326, lng: 18.287943 },
    { lat: 47.951019, lng: 18.288597 },
    { lat: 47.950697, lng: 18.289307 },
    { lat: 47.95041, lng: 18.289929 },
    { lat: 47.95003, lng: 18.290726 },
    { lat: 47.949882, lng: 18.291012 },
    { lat: 47.949596, lng: 18.291564 },
    { lat: 47.94923, lng: 18.292256 },
    { lat: 47.948991, lng: 18.292705 },
    { lat: 47.948858, lng: 18.293011 },
    { lat: 47.948711, lng: 18.293353 },
    { lat: 47.948321, lng: 18.29429 },
    { lat: 47.948023, lng: 18.29498 },
    { lat: 47.947852, lng: 18.295327 },
    { lat: 47.947728, lng: 18.295594 },
    { lat: 47.947211, lng: 18.296659 },
    { lat: 47.946856, lng: 18.297386 },
    { lat: 47.94625, lng: 18.298674 },
    { lat: 47.94587, lng: 18.299501 },
    { lat: 47.945557, lng: 18.300164 },
    { lat: 47.945287, lng: 18.300821 },
    { lat: 47.944736, lng: 18.30212 },
    { lat: 47.944713, lng: 18.302174 },
    { lat: 47.944661, lng: 18.302298 },
    { lat: 47.944178, lng: 18.303426 },
    { lat: 47.943766, lng: 18.304399 },
    { lat: 47.943254, lng: 18.305579 },
    { lat: 47.942746, lng: 18.306739 },
    { lat: 47.942334, lng: 18.307662 },
    { lat: 47.942235, lng: 18.307857 },
    { lat: 47.942006, lng: 18.308334 },
    { lat: 47.94145, lng: 18.309496 },
    { lat: 47.941148, lng: 18.310133 },
    { lat: 47.940953, lng: 18.310534 },
    { lat: 47.940872, lng: 18.310711 },
    { lat: 47.940738, lng: 18.310972 },
    { lat: 47.940588, lng: 18.311283 },
    { lat: 47.940451, lng: 18.311563 },
    { lat: 47.940503, lng: 18.311788 },
    { lat: 47.940472, lng: 18.311852 },
    { lat: 47.940415, lng: 18.311963 },
    { lat: 47.940389, lng: 18.312017 },
    { lat: 47.940223, lng: 18.312013 },
    { lat: 47.939391, lng: 18.313894 },
    { lat: 47.93931, lng: 18.314431 },
    { lat: 47.939236, lng: 18.314928 },
    { lat: 47.939215, lng: 18.315059 },
    { lat: 47.939114, lng: 18.31573 },
    { lat: 47.938458, lng: 18.317127 },
    { lat: 47.937213, lng: 18.319915 },
    { lat: 47.936073, lng: 18.321209 },
    { lat: 47.935281, lng: 18.322976 },
    { lat: 47.934867, lng: 18.323899 },
    { lat: 47.934614, lng: 18.324497 },
    { lat: 47.934686, lng: 18.324726 },
    { lat: 47.934643, lng: 18.324806 },
    { lat: 47.934875, lng: 18.325111 },
    { lat: 47.9349423, lng: 18.3253767 },
  ],
  Maňa: [
    { lat: 48.1307856, lng: 18.3132159 },
    { lat: 48.1308191, lng: 18.3132272 },
    { lat: 48.131263, lng: 18.313415 },
    { lat: 48.1315234, lng: 18.3136279 },
    { lat: 48.1322104, lng: 18.3144166 },
    { lat: 48.1326805, lng: 18.315394 },
    { lat: 48.1325562, lng: 18.3157464 },
    { lat: 48.1320397, lng: 18.3167995 },
    { lat: 48.1320458, lng: 18.3169071 },
    { lat: 48.1332975, lng: 18.3178421 },
    { lat: 48.1349102, lng: 18.3191719 },
    { lat: 48.1353195, lng: 18.3198174 },
    { lat: 48.13541, lng: 18.3200767 },
    { lat: 48.1353872, lng: 18.3201132 },
    { lat: 48.1356282, lng: 18.3201849 },
    { lat: 48.1365393, lng: 18.3198812 },
    { lat: 48.137535, lng: 18.3202051 },
    { lat: 48.1379726, lng: 18.3202825 },
    { lat: 48.1391342, lng: 18.3199776 },
    { lat: 48.1395039, lng: 18.3200506 },
    { lat: 48.1396961, lng: 18.3200165 },
    { lat: 48.1397654, lng: 18.3199833 },
    { lat: 48.1400651, lng: 18.3195604 },
    { lat: 48.1405215, lng: 18.3193161 },
    { lat: 48.1410101, lng: 18.3193361 },
    { lat: 48.1419422, lng: 18.3190014 },
    { lat: 48.1424524, lng: 18.3191844 },
    { lat: 48.1428054, lng: 18.3194563 },
    { lat: 48.1433421, lng: 18.3200029 },
    { lat: 48.1436827, lng: 18.3206936 },
    { lat: 48.1442538, lng: 18.3211931 },
    { lat: 48.1452301, lng: 18.3222836 },
    { lat: 48.1456142, lng: 18.3225509 },
    { lat: 48.1459215, lng: 18.3228721 },
    { lat: 48.1466424, lng: 18.3240591 },
    { lat: 48.1469566, lng: 18.3244229 },
    { lat: 48.1474111, lng: 18.3246777 },
    { lat: 48.148154, lng: 18.3249014 },
    { lat: 48.1487245, lng: 18.3252127 },
    { lat: 48.1496955, lng: 18.326173 },
    { lat: 48.1504872, lng: 18.3265453 },
    { lat: 48.1512274, lng: 18.3267216 },
    { lat: 48.151212, lng: 18.3267879 },
    { lat: 48.1514682, lng: 18.3268899 },
    { lat: 48.1519241, lng: 18.3273046 },
    { lat: 48.1526903, lng: 18.3277232 },
    { lat: 48.1531975, lng: 18.3278116 },
    { lat: 48.1541318, lng: 18.3277563 },
    { lat: 48.1549444, lng: 18.3278171 },
    { lat: 48.1552683, lng: 18.3279384 },
    { lat: 48.1556692, lng: 18.3283015 },
    { lat: 48.1562441, lng: 18.32857 },
    { lat: 48.1571561, lng: 18.3287538 },
    { lat: 48.1576956, lng: 18.3290141 },
    { lat: 48.1601186, lng: 18.3290441 },
    { lat: 48.1618649, lng: 18.3293561 },
    { lat: 48.1625622, lng: 18.3293952 },
    { lat: 48.1632207, lng: 18.3295481 },
    { lat: 48.1638867, lng: 18.3293541 },
    { lat: 48.1648508, lng: 18.3294427 },
    { lat: 48.1658604, lng: 18.3294406 },
    { lat: 48.166279, lng: 18.3295583 },
    { lat: 48.1687705, lng: 18.3294842 },
    { lat: 48.1694017, lng: 18.3297318 },
    { lat: 48.1708851, lng: 18.3301589 },
    { lat: 48.1726431, lng: 18.3305031 },
    { lat: 48.1757625, lng: 18.3303974 },
    { lat: 48.1761253, lng: 18.3303091 },
    { lat: 48.176684, lng: 18.329978 },
    { lat: 48.177155, lng: 18.329654 },
    { lat: 48.177799, lng: 18.329231 },
    { lat: 48.178435, lng: 18.328682 },
    { lat: 48.179828, lng: 18.327536 },
    { lat: 48.179973, lng: 18.327223 },
    { lat: 48.180483, lng: 18.32608 },
    { lat: 48.180664, lng: 18.325588 },
    { lat: 48.180826, lng: 18.324632 },
    { lat: 48.180833, lng: 18.324585 },
    { lat: 48.180838, lng: 18.324552 },
    { lat: 48.180943, lng: 18.323914 },
    { lat: 48.181114, lng: 18.322699 },
    { lat: 48.18121, lng: 18.322224 },
    { lat: 48.181302, lng: 18.321828 },
    { lat: 48.181499, lng: 18.321038 },
    { lat: 48.181814, lng: 18.319682 },
    { lat: 48.181744, lng: 18.319614 },
    { lat: 48.181699, lng: 18.319569 },
    { lat: 48.181689, lng: 18.319603 },
    { lat: 48.181023, lng: 18.318935 },
    { lat: 48.180668, lng: 18.318596 },
    { lat: 48.180435, lng: 18.318466 },
    { lat: 48.180092, lng: 18.318297 },
    { lat: 48.179723, lng: 18.318142 },
    { lat: 48.179346, lng: 18.317997 },
    { lat: 48.17889, lng: 18.317843 },
    { lat: 48.178894, lng: 18.317786 },
    { lat: 48.178901, lng: 18.31746 },
    { lat: 48.178998, lng: 18.316289 },
    { lat: 48.179033, lng: 18.316051 },
    { lat: 48.179328, lng: 18.315185 },
    { lat: 48.179837, lng: 18.313811 },
    { lat: 48.179972, lng: 18.31338 },
    { lat: 48.180076, lng: 18.312875 },
    { lat: 48.18019, lng: 18.312283 },
    { lat: 48.180208, lng: 18.312185 },
    { lat: 48.179994, lng: 18.312107 },
    { lat: 48.179579, lng: 18.312026 },
    { lat: 48.179573, lng: 18.311655 },
    { lat: 48.179553, lng: 18.311378 },
    { lat: 48.179572, lng: 18.311259 },
    { lat: 48.17958, lng: 18.311121 },
    { lat: 48.179617, lng: 18.310832 },
    { lat: 48.179714, lng: 18.310315 },
    { lat: 48.179834, lng: 18.309777 },
    { lat: 48.179876, lng: 18.309673 },
    { lat: 48.17995, lng: 18.309441 },
    { lat: 48.180149, lng: 18.309048 },
    { lat: 48.180277, lng: 18.308754 },
    { lat: 48.180692, lng: 18.307559 },
    { lat: 48.180735, lng: 18.307419 },
    { lat: 48.180978, lng: 18.306893 },
    { lat: 48.181115, lng: 18.306644 },
    { lat: 48.181245, lng: 18.305522 },
    { lat: 48.181347, lng: 18.305171 },
    { lat: 48.181425, lng: 18.304886 },
    { lat: 48.181459, lng: 18.304701 },
    { lat: 48.181515, lng: 18.304277 },
    { lat: 48.181515, lng: 18.304221 },
    { lat: 48.18152, lng: 18.304103 },
    { lat: 48.181518, lng: 18.303692 },
    { lat: 48.181519, lng: 18.303677 },
    { lat: 48.181521, lng: 18.303657 },
    { lat: 48.181535, lng: 18.303563 },
    { lat: 48.181537, lng: 18.303531 },
    { lat: 48.181525, lng: 18.303526 },
    { lat: 48.181507, lng: 18.303358 },
    { lat: 48.181503, lng: 18.302785 },
    { lat: 48.181517, lng: 18.302201 },
    { lat: 48.18154, lng: 18.301612 },
    { lat: 48.181538, lng: 18.301586 },
    { lat: 48.181559, lng: 18.301276 },
    { lat: 48.181588, lng: 18.301009 },
    { lat: 48.181606, lng: 18.300893 },
    { lat: 48.181738, lng: 18.300049 },
    { lat: 48.181993, lng: 18.29995 },
    { lat: 48.182245, lng: 18.298452 },
    { lat: 48.18249, lng: 18.296889 },
    { lat: 48.182504, lng: 18.296772 },
    { lat: 48.182557, lng: 18.295531 },
    { lat: 48.182578, lng: 18.294907 },
    { lat: 48.182521, lng: 18.294821 },
    { lat: 48.182419, lng: 18.294563 },
    { lat: 48.182262, lng: 18.294474 },
    { lat: 48.182248, lng: 18.294343 },
    { lat: 48.182356, lng: 18.294226 },
    { lat: 48.18258, lng: 18.29412 },
    { lat: 48.182618, lng: 18.294055 },
    { lat: 48.182585, lng: 18.293879 },
    { lat: 48.182072, lng: 18.293791 },
    { lat: 48.182141, lng: 18.293073 },
    { lat: 48.182059, lng: 18.292985 },
    { lat: 48.181993, lng: 18.29298 },
    { lat: 48.181886, lng: 18.293025 },
    { lat: 48.181735, lng: 18.292955 },
    { lat: 48.181736, lng: 18.292871 },
    { lat: 48.181894, lng: 18.292603 },
    { lat: 48.181944, lng: 18.292263 },
    { lat: 48.181959, lng: 18.292164 },
    { lat: 48.182071, lng: 18.292076 },
    { lat: 48.18213, lng: 18.292062 },
    { lat: 48.1821836, lng: 18.2918079 },
    { lat: 48.1821176, lng: 18.2917156 },
    { lat: 48.1819548, lng: 18.2919072 },
    { lat: 48.1817677, lng: 18.2919521 },
    { lat: 48.1815491, lng: 18.2918797 },
    { lat: 48.1815508, lng: 18.2914548 },
    { lat: 48.1815027, lng: 18.2912468 },
    { lat: 48.1811991, lng: 18.290773 },
    { lat: 48.1810048, lng: 18.2902369 },
    { lat: 48.1808767, lng: 18.2904025 },
    { lat: 48.1807257, lng: 18.2907595 },
    { lat: 48.1802934, lng: 18.2903043 },
    { lat: 48.1802215, lng: 18.2899576 },
    { lat: 48.1802353, lng: 18.2897854 },
    { lat: 48.1801817, lng: 18.2896385 },
    { lat: 48.1801244, lng: 18.28962 },
    { lat: 48.1799829, lng: 18.2897499 },
    { lat: 48.1798326, lng: 18.2903369 },
    { lat: 48.1798065, lng: 18.2905912 },
    { lat: 48.1799017, lng: 18.2907763 },
    { lat: 48.179862, lng: 18.2909857 },
    { lat: 48.1797925, lng: 18.2910565 },
    { lat: 48.1793315, lng: 18.2910452 },
    { lat: 48.1792787, lng: 18.2911468 },
    { lat: 48.1793086, lng: 18.2912348 },
    { lat: 48.1795384, lng: 18.2915038 },
    { lat: 48.1793868, lng: 18.2916929 },
    { lat: 48.1791775, lng: 18.2915267 },
    { lat: 48.1790745, lng: 18.2915989 },
    { lat: 48.1790535, lng: 18.2916851 },
    { lat: 48.1790936, lng: 18.2918323 },
    { lat: 48.1792216, lng: 18.2920983 },
    { lat: 48.1790491, lng: 18.2923393 },
    { lat: 48.1789364, lng: 18.2923979 },
    { lat: 48.1786283, lng: 18.2922419 },
    { lat: 48.1785207, lng: 18.2922539 },
    { lat: 48.1784368, lng: 18.2923661 },
    { lat: 48.1782553, lng: 18.2930565 },
    { lat: 48.1783968, lng: 18.2933791 },
    { lat: 48.1783243, lng: 18.2935719 },
    { lat: 48.1780655, lng: 18.2937581 },
    { lat: 48.1780028, lng: 18.2937502 },
    { lat: 48.17788, lng: 18.2935373 },
    { lat: 48.1778427, lng: 18.2932992 },
    { lat: 48.177639, lng: 18.2930704 },
    { lat: 48.1775865, lng: 18.2931199 },
    { lat: 48.1774688, lng: 18.2935662 },
    { lat: 48.177392, lng: 18.2935669 },
    { lat: 48.1773166, lng: 18.2933114 },
    { lat: 48.1772324, lng: 18.2933031 },
    { lat: 48.1771279, lng: 18.2933858 },
    { lat: 48.176884, lng: 18.2938784 },
    { lat: 48.1767789, lng: 18.2937939 },
    { lat: 48.1767436, lng: 18.2936899 },
    { lat: 48.1768504, lng: 18.2931728 },
    { lat: 48.17698, lng: 18.2930314 },
    { lat: 48.1768743, lng: 18.2927805 },
    { lat: 48.1767572, lng: 18.2926891 },
    { lat: 48.1766152, lng: 18.2928265 },
    { lat: 48.1764218, lng: 18.2928177 },
    { lat: 48.1763958, lng: 18.2925414 },
    { lat: 48.1765207, lng: 18.2923981 },
    { lat: 48.1765295, lng: 18.2923167 },
    { lat: 48.1765006, lng: 18.2922451 },
    { lat: 48.1763021, lng: 18.2921893 },
    { lat: 48.1761975, lng: 18.292192 },
    { lat: 48.1759254, lng: 18.2926062 },
    { lat: 48.1758732, lng: 18.292562 },
    { lat: 48.1758799, lng: 18.2920673 },
    { lat: 48.1759526, lng: 18.2918795 },
    { lat: 48.1758568, lng: 18.2917016 },
    { lat: 48.1756569, lng: 18.2916416 },
    { lat: 48.1756099, lng: 18.2916052 },
    { lat: 48.1751058, lng: 18.2919067 },
    { lat: 48.1747359, lng: 18.2922644 },
    { lat: 48.1746448, lng: 18.2924486 },
    { lat: 48.1746806, lng: 18.2925826 },
    { lat: 48.1748546, lng: 18.2926434 },
    { lat: 48.1749854, lng: 18.292546 },
    { lat: 48.1751152, lng: 18.2925665 },
    { lat: 48.1751912, lng: 18.2926511 },
    { lat: 48.1751943, lng: 18.2928611 },
    { lat: 48.1751417, lng: 18.2930294 },
    { lat: 48.174718, lng: 18.2930158 },
    { lat: 48.1745389, lng: 18.2931791 },
    { lat: 48.1744762, lng: 18.2936198 },
    { lat: 48.174611, lng: 18.2936502 },
    { lat: 48.1747512, lng: 18.2938518 },
    { lat: 48.1746992, lng: 18.294067 },
    { lat: 48.1745571, lng: 18.2942075 },
    { lat: 48.1743182, lng: 18.2949405 },
    { lat: 48.1738202, lng: 18.2961521 },
    { lat: 48.1738198, lng: 18.2963437 },
    { lat: 48.1737268, lng: 18.2967303 },
    { lat: 48.1737666, lng: 18.2971119 },
    { lat: 48.1737231, lng: 18.2973543 },
    { lat: 48.1735541, lng: 18.2975174 },
    { lat: 48.1734254, lng: 18.2974172 },
    { lat: 48.173334, lng: 18.297419 },
    { lat: 48.1733378, lng: 18.297679 },
    { lat: 48.1735155, lng: 18.2981225 },
    { lat: 48.1734669, lng: 18.2983599 },
    { lat: 48.1735533, lng: 18.2985846 },
    { lat: 48.1739382, lng: 18.29861 },
    { lat: 48.1739845, lng: 18.2987278 },
    { lat: 48.1738123, lng: 18.2988718 },
    { lat: 48.1735291, lng: 18.2993269 },
    { lat: 48.173368, lng: 18.2994087 },
    { lat: 48.173173, lng: 18.299037 },
    { lat: 48.1728774, lng: 18.2986863 },
    { lat: 48.1727464, lng: 18.2987017 },
    { lat: 48.1724066, lng: 18.2991139 },
    { lat: 48.1722591, lng: 18.2991762 },
    { lat: 48.1721205, lng: 18.2994768 },
    { lat: 48.1720242, lng: 18.2995665 },
    { lat: 48.1714804, lng: 18.2996672 },
    { lat: 48.1713261, lng: 18.2997509 },
    { lat: 48.1701803, lng: 18.2994649 },
    { lat: 48.1695157, lng: 18.2996221 },
    { lat: 48.1693236, lng: 18.2996007 },
    { lat: 48.1676482, lng: 18.2991658 },
    { lat: 48.1678442, lng: 18.298766 },
    { lat: 48.1678919, lng: 18.2985574 },
    { lat: 48.1677517, lng: 18.2976048 },
    { lat: 48.1661057, lng: 18.2965456 },
    { lat: 48.1658371, lng: 18.2972918 },
    { lat: 48.1657583, lng: 18.2971014 },
    { lat: 48.1653397, lng: 18.2970416 },
    { lat: 48.1653246, lng: 18.2967943 },
    { lat: 48.165452, lng: 18.2961245 },
    { lat: 48.1656878, lng: 18.2952595 },
    { lat: 48.1656769, lng: 18.2949863 },
    { lat: 48.1654628, lng: 18.2946207 },
    { lat: 48.1653551, lng: 18.294591 },
    { lat: 48.1651542, lng: 18.2947072 },
    { lat: 48.1649213, lng: 18.2946204 },
    { lat: 48.1646951, lng: 18.2947911 },
    { lat: 48.1646141, lng: 18.2943014 },
    { lat: 48.1642934, lng: 18.2941604 },
    { lat: 48.1640956, lng: 18.293955 },
    { lat: 48.1640297, lng: 18.2936506 },
    { lat: 48.164077, lng: 18.2933739 },
    { lat: 48.1638026, lng: 18.2927007 },
    { lat: 48.1637002, lng: 18.2922753 },
    { lat: 48.1633346, lng: 18.291704 },
    { lat: 48.1629265, lng: 18.2907836 },
    { lat: 48.162528, lng: 18.2900904 },
    { lat: 48.1624765, lng: 18.2897446 },
    { lat: 48.1625059, lng: 18.2897822 },
    { lat: 48.1626339, lng: 18.2895361 },
    { lat: 48.1628728, lng: 18.2889612 },
    { lat: 48.1629475, lng: 18.2884905 },
    { lat: 48.1629112, lng: 18.288311 },
    { lat: 48.1628336, lng: 18.2881986 },
    { lat: 48.162396, lng: 18.2881222 },
    { lat: 48.162133, lng: 18.2877668 },
    { lat: 48.1620192, lng: 18.2877118 },
    { lat: 48.1614732, lng: 18.2876937 },
    { lat: 48.161074, lng: 18.2880927 },
    { lat: 48.1606704, lng: 18.2880387 },
    { lat: 48.1604519, lng: 18.2878685 },
    { lat: 48.1603417, lng: 18.2875225 },
    { lat: 48.1603506, lng: 18.2872453 },
    { lat: 48.160476, lng: 18.2867147 },
    { lat: 48.1606369, lng: 18.2863537 },
    { lat: 48.1606888, lng: 18.2861177 },
    { lat: 48.1606797, lng: 18.2859009 },
    { lat: 48.160527, lng: 18.2854808 },
    { lat: 48.1603515, lng: 18.285352 },
    { lat: 48.160034, lng: 18.285538 },
    { lat: 48.1598788, lng: 18.2857844 },
    { lat: 48.1597265, lng: 18.2862945 },
    { lat: 48.1596008, lng: 18.2865071 },
    { lat: 48.1594495, lng: 18.28656 },
    { lat: 48.1593175, lng: 18.2864444 },
    { lat: 48.1592278, lng: 18.2862566 },
    { lat: 48.1592558, lng: 18.2860781 },
    { lat: 48.1592112, lng: 18.2854194 },
    { lat: 48.1591188, lng: 18.2847899 },
    { lat: 48.1589733, lng: 18.2844385 },
    { lat: 48.158618, lng: 18.2841632 },
    { lat: 48.1585759, lng: 18.2839817 },
    { lat: 48.1585806, lng: 18.2836895 },
    { lat: 48.1585031, lng: 18.2833807 },
    { lat: 48.1582654, lng: 18.2829963 },
    { lat: 48.1583316, lng: 18.2821432 },
    { lat: 48.1581277, lng: 18.2815403 },
    { lat: 48.1579627, lng: 18.2813392 },
    { lat: 48.1584972, lng: 18.279626 },
    { lat: 48.1580654, lng: 18.2787636 },
    { lat: 48.1580323, lng: 18.2780526 },
    { lat: 48.1573526, lng: 18.2775356 },
    { lat: 48.1573783, lng: 18.2771757 },
    { lat: 48.1573359, lng: 18.276891 },
    { lat: 48.1572881, lng: 18.2768039 },
    { lat: 48.1568547, lng: 18.2764874 },
    { lat: 48.1567059, lng: 18.2761185 },
    { lat: 48.1567788, lng: 18.2759727 },
    { lat: 48.16005, lng: 18.2627939 },
    { lat: 48.1601716, lng: 18.2622785 },
    { lat: 48.1595725, lng: 18.2619877 },
    { lat: 48.1569286, lng: 18.2610606 },
    { lat: 48.1562088, lng: 18.2605751 },
    { lat: 48.156191, lng: 18.2605583 },
    { lat: 48.1562081, lng: 18.2605222 },
    { lat: 48.1560095, lng: 18.2602689 },
    { lat: 48.1554411, lng: 18.2597629 },
    { lat: 48.1540038, lng: 18.2593584 },
    { lat: 48.1517304, lng: 18.2583825 },
    { lat: 48.1500084, lng: 18.2580476 },
    { lat: 48.1507104, lng: 18.254098 },
    { lat: 48.1499434, lng: 18.2530456 },
    { lat: 48.1493318, lng: 18.2526167 },
    { lat: 48.1487191, lng: 18.2519723 },
    { lat: 48.1481325, lng: 18.2515214 },
    { lat: 48.1464213, lng: 18.2508664 },
    { lat: 48.1433134, lng: 18.2514894 },
    { lat: 48.1421763, lng: 18.2521976 },
    { lat: 48.1416826, lng: 18.2522276 },
    { lat: 48.1409264, lng: 18.2520869 },
    { lat: 48.1385687, lng: 18.2510001 },
    { lat: 48.1379989, lng: 18.2528578 },
    { lat: 48.1373785, lng: 18.2526657 },
    { lat: 48.1366552, lng: 18.2525307 },
    { lat: 48.1364387, lng: 18.2534134 },
    { lat: 48.1342007, lng: 18.2525076 },
    { lat: 48.1338479, lng: 18.2524906 },
    { lat: 48.1332184, lng: 18.2527475 },
    { lat: 48.1325159, lng: 18.253247 },
    { lat: 48.1324291, lng: 18.2537354 },
    { lat: 48.1320818, lng: 18.2531743 },
    { lat: 48.1318028, lng: 18.2528949 },
    { lat: 48.1310743, lng: 18.2524327 },
    { lat: 48.1307585, lng: 18.2518694 },
    { lat: 48.1295373, lng: 18.250788 },
    { lat: 48.1282029, lng: 18.2499183 },
    { lat: 48.1276542, lng: 18.2494616 },
    { lat: 48.1271554, lng: 18.2490502 },
    { lat: 48.1270043, lng: 18.2492671 },
    { lat: 48.1263875, lng: 18.2508779 },
    { lat: 48.1259869, lng: 18.2522045 },
    { lat: 48.12561, lng: 18.2529667 },
    { lat: 48.1247307, lng: 18.2551718 },
    { lat: 48.1240154, lng: 18.2564836 },
    { lat: 48.1236797, lng: 18.2572967 },
    { lat: 48.1231362, lng: 18.2584007 },
    { lat: 48.1230787, lng: 18.2586132 },
    { lat: 48.122447, lng: 18.2594216 },
    { lat: 48.1224467, lng: 18.2597872 },
    { lat: 48.1219015, lng: 18.2601561 },
    { lat: 48.121531, lng: 18.260227 },
    { lat: 48.1211711, lng: 18.260738 },
    { lat: 48.1209239, lng: 18.2612653 },
    { lat: 48.120741, lng: 18.2618291 },
    { lat: 48.1201119, lng: 18.2630742 },
    { lat: 48.1198071, lng: 18.2638119 },
    { lat: 48.1195322, lng: 18.2646629 },
    { lat: 48.1193884, lng: 18.2649872 },
    { lat: 48.1192447, lng: 18.265178 },
    { lat: 48.1198007, lng: 18.2660127 },
    { lat: 48.1198702, lng: 18.2661235 },
    { lat: 48.1199357, lng: 18.2662835 },
    { lat: 48.1202553, lng: 18.2663632 },
    { lat: 48.1212135, lng: 18.2665265 },
    { lat: 48.1210143, lng: 18.2659447 },
    { lat: 48.1211757, lng: 18.2650284 },
    { lat: 48.1214804, lng: 18.2645959 },
    { lat: 48.1216955, lng: 18.2638439 },
    { lat: 48.121932, lng: 18.2635621 },
    { lat: 48.1221042, lng: 18.2631847 },
    { lat: 48.1222051, lng: 18.2627035 },
    { lat: 48.1223635, lng: 18.2624172 },
    { lat: 48.1225454, lng: 18.2616172 },
    { lat: 48.1227524, lng: 18.2603359 },
    { lat: 48.1229068, lng: 18.2599586 },
    { lat: 48.123453, lng: 18.2595472 },
    { lat: 48.1239671, lng: 18.2595408 },
    { lat: 48.1240911, lng: 18.2596785 },
    { lat: 48.1245205, lng: 18.2591786 },
    { lat: 48.1249303, lng: 18.2590839 },
    { lat: 48.1253943, lng: 18.2591068 },
    { lat: 48.1254349, lng: 18.2591662 },
    { lat: 48.1253721, lng: 18.2594261 },
    { lat: 48.1254796, lng: 18.2596306 },
    { lat: 48.1257372, lng: 18.259673 },
    { lat: 48.1258664, lng: 18.2594862 },
    { lat: 48.1263638, lng: 18.2594635 },
    { lat: 48.1267941, lng: 18.2597458 },
    { lat: 48.1260398, lng: 18.2615172 },
    { lat: 48.1259032, lng: 18.2623569 },
    { lat: 48.1260858, lng: 18.2631376 },
    { lat: 48.1264619, lng: 18.2635336 },
    { lat: 48.1267067, lng: 18.2638874 },
    { lat: 48.1270351, lng: 18.2655542 },
    { lat: 48.1271876, lng: 18.2657501 },
    { lat: 48.1273092, lng: 18.2657263 },
    { lat: 48.1275067, lng: 18.2653185 },
    { lat: 48.1275998, lng: 18.2653461 },
    { lat: 48.1277802, lng: 18.2656372 },
    { lat: 48.1277936, lng: 18.2659265 },
    { lat: 48.1278497, lng: 18.2660595 },
    { lat: 48.1281716, lng: 18.2662619 },
    { lat: 48.1282989, lng: 18.2662596 },
    { lat: 48.1283799, lng: 18.2661355 },
    { lat: 48.1284113, lng: 18.2659001 },
    { lat: 48.1283546, lng: 18.2656019 },
    { lat: 48.1284241, lng: 18.2650628 },
    { lat: 48.1284872, lng: 18.2649018 },
    { lat: 48.1286743, lng: 18.265018 },
    { lat: 48.1288266, lng: 18.2652742 },
    { lat: 48.1289703, lng: 18.2653374 },
    { lat: 48.1291561, lng: 18.265234 },
    { lat: 48.1293691, lng: 18.2649749 },
    { lat: 48.1294995, lng: 18.2650846 },
    { lat: 48.1293099, lng: 18.2654954 },
    { lat: 48.1292368, lng: 18.2659501 },
    { lat: 48.1292656, lng: 18.2664303 },
    { lat: 48.1295787, lng: 18.2665745 },
    { lat: 48.1297092, lng: 18.2665697 },
    { lat: 48.1298619, lng: 18.2662619 },
    { lat: 48.1299613, lng: 18.2656762 },
    { lat: 48.1300125, lng: 18.2656248 },
    { lat: 48.1302683, lng: 18.2658741 },
    { lat: 48.1302895, lng: 18.2662422 },
    { lat: 48.1303548, lng: 18.2664567 },
    { lat: 48.1304704, lng: 18.2664638 },
    { lat: 48.1306202, lng: 18.2663608 },
    { lat: 48.1308428, lng: 18.2659546 },
    { lat: 48.1310455, lng: 18.266162 },
    { lat: 48.1309231, lng: 18.2663512 },
    { lat: 48.1309206, lng: 18.2664444 },
    { lat: 48.1310691, lng: 18.2667683 },
    { lat: 48.1312138, lng: 18.2667352 },
    { lat: 48.1312014, lng: 18.2669713 },
    { lat: 48.1312759, lng: 18.2669893 },
    { lat: 48.1313773, lng: 18.2671246 },
    { lat: 48.1313828, lng: 18.2672596 },
    { lat: 48.1312948, lng: 18.2675351 },
    { lat: 48.1317032, lng: 18.2678075 },
    { lat: 48.1318163, lng: 18.2678121 },
    { lat: 48.1323902, lng: 18.2673332 },
    { lat: 48.1326514, lng: 18.2677751 },
    { lat: 48.1330067, lng: 18.2679096 },
    { lat: 48.1333466, lng: 18.2686801 },
    { lat: 48.1334592, lng: 18.2687123 },
    { lat: 48.1334897, lng: 18.2686564 },
    { lat: 48.1334581, lng: 18.2678519 },
    { lat: 48.1335602, lng: 18.2678024 },
    { lat: 48.1338543, lng: 18.2680102 },
    { lat: 48.1339762, lng: 18.2680326 },
    { lat: 48.1343545, lng: 18.2676262 },
    { lat: 48.134651, lng: 18.267717 },
    { lat: 48.1347678, lng: 18.2675724 },
    { lat: 48.1347823, lng: 18.2668228 },
    { lat: 48.1348242, lng: 18.2669053 },
    { lat: 48.135126, lng: 18.2679139 },
    { lat: 48.1357325, lng: 18.271374 },
    { lat: 48.1359991, lng: 18.2733398 },
    { lat: 48.1359403, lng: 18.2742219 },
    { lat: 48.1361832, lng: 18.2748764 },
    { lat: 48.1365475, lng: 18.2751267 },
    { lat: 48.1369577, lng: 18.2755283 },
    { lat: 48.1371184, lng: 18.2758154 },
    { lat: 48.1370831, lng: 18.2765126 },
    { lat: 48.136745, lng: 18.2783066 },
    { lat: 48.1366022, lng: 18.2793967 },
    { lat: 48.136438, lng: 18.2815071 },
    { lat: 48.1360767, lng: 18.2832935 },
    { lat: 48.1359506, lng: 18.2849056 },
    { lat: 48.1359815, lng: 18.2856618 },
    { lat: 48.1358691, lng: 18.2891495 },
    { lat: 48.1357028, lng: 18.291128 },
    { lat: 48.1354082, lng: 18.2923639 },
    { lat: 48.1342641, lng: 18.2964385 },
    { lat: 48.1339101, lng: 18.2991816 },
    { lat: 48.1336682, lng: 18.3003958 },
    { lat: 48.1334935, lng: 18.3010267 },
    { lat: 48.1330241, lng: 18.3034705 },
    { lat: 48.132063, lng: 18.3080107 },
    { lat: 48.1315951, lng: 18.3091389 },
    { lat: 48.1312625, lng: 18.3103257 },
    { lat: 48.1308217, lng: 18.312761 },
    { lat: 48.1307856, lng: 18.3132159 },
  ],
  Pozba: [
    { lat: 48.094771, lng: 18.4026272 },
    { lat: 48.0950709, lng: 18.4027063 },
    { lat: 48.0957223, lng: 18.4025527 },
    { lat: 48.095918, lng: 18.4024215 },
    { lat: 48.0962412, lng: 18.4018688 },
    { lat: 48.0964236, lng: 18.4014425 },
    { lat: 48.0966265, lng: 18.4012014 },
    { lat: 48.098184, lng: 18.4003307 },
    { lat: 48.0983236, lng: 18.4001854 },
    { lat: 48.098726, lng: 18.3994399 },
    { lat: 48.0989339, lng: 18.3992881 },
    { lat: 48.099094, lng: 18.3992835 },
    { lat: 48.0994455, lng: 18.3991157 },
    { lat: 48.0998095, lng: 18.3996876 },
    { lat: 48.1004153, lng: 18.4004648 },
    { lat: 48.100452, lng: 18.4004365 },
    { lat: 48.1011461, lng: 18.4020617 },
    { lat: 48.1018334, lng: 18.4031647 },
    { lat: 48.1030269, lng: 18.4046841 },
    { lat: 48.1038006, lng: 18.4055593 },
    { lat: 48.1043366, lng: 18.4057828 },
    { lat: 48.1045398, lng: 18.4059648 },
    { lat: 48.1049253, lng: 18.4065372 },
    { lat: 48.1054185, lng: 18.4075038 },
    { lat: 48.105615, lng: 18.4077477 },
    { lat: 48.1063274, lng: 18.4083352 },
    { lat: 48.1065739, lng: 18.4084278 },
    { lat: 48.1076271, lng: 18.4085355 },
    { lat: 48.1080903, lng: 18.4083277 },
    { lat: 48.108885, lng: 18.4075165 },
    { lat: 48.1096891, lng: 18.407047 },
    { lat: 48.110327, lng: 18.4067966 },
    { lat: 48.1108374, lng: 18.4067459 },
    { lat: 48.1109634, lng: 18.4067375 },
    { lat: 48.1112003, lng: 18.4069188 },
    { lat: 48.1119379, lng: 18.4065741 },
    { lat: 48.1127515, lng: 18.4058763 },
    { lat: 48.1130498, lng: 18.4044821 },
    { lat: 48.113191, lng: 18.404069 },
    { lat: 48.1141033, lng: 18.4021199 },
    { lat: 48.1155203, lng: 18.3997584 },
    { lat: 48.1160389, lng: 18.3991124 },
    { lat: 48.1167907, lng: 18.3974036 },
    { lat: 48.1175491, lng: 18.3952887 },
    { lat: 48.1178778, lng: 18.3945578 },
    { lat: 48.1184549, lng: 18.3938874 },
    { lat: 48.1197206, lng: 18.3926638 },
    { lat: 48.1220205, lng: 18.3899201 },
    { lat: 48.1246056, lng: 18.3857948 },
    { lat: 48.1252145, lng: 18.3850326 },
    { lat: 48.1277699, lng: 18.3815197 },
    { lat: 48.1280118, lng: 18.3813508 },
    { lat: 48.1285345, lng: 18.3811951 },
    { lat: 48.1298771, lng: 18.3811362 },
    { lat: 48.1312055, lng: 18.3812062 },
    { lat: 48.1320122, lng: 18.3810651 },
    { lat: 48.1323792, lng: 18.380892 },
    { lat: 48.133984, lng: 18.3789069 },
    { lat: 48.1344473, lng: 18.3784207 },
    { lat: 48.1351198, lng: 18.3778851 },
    { lat: 48.1368266, lng: 18.3772856 },
    { lat: 48.1380937, lng: 18.3771238 },
    { lat: 48.1384198, lng: 18.3771681 },
    { lat: 48.1340601, lng: 18.3684379 },
    { lat: 48.133871, lng: 18.3683887 },
    { lat: 48.1335066, lng: 18.3685245 },
    { lat: 48.1327809, lng: 18.3681434 },
    { lat: 48.132268, lng: 18.3679526 },
    { lat: 48.1300025, lng: 18.3680502 },
    { lat: 48.1298186, lng: 18.3674342 },
    { lat: 48.1254773, lng: 18.3627839 },
    { lat: 48.1239804, lng: 18.3648742 },
    { lat: 48.1236403, lng: 18.3641096 },
    { lat: 48.1216584, lng: 18.3636592 },
    { lat: 48.1162152, lng: 18.3651419 },
    { lat: 48.115753, lng: 18.3617496 },
    { lat: 48.1151634, lng: 18.3602058 },
    { lat: 48.1144554, lng: 18.3608106 },
    { lat: 48.1140319, lng: 18.3611643 },
    { lat: 48.1135187, lng: 18.363231 },
    { lat: 48.1129254, lng: 18.3653625 },
    { lat: 48.1119957, lng: 18.3673911 },
    { lat: 48.1116038, lng: 18.3678794 },
    { lat: 48.1113564, lng: 18.3682941 },
    { lat: 48.1100706, lng: 18.3691352 },
    { lat: 48.1093864, lng: 18.3694056 },
    { lat: 48.1084258, lng: 18.3703142 },
    { lat: 48.1076061, lng: 18.3712177 },
    { lat: 48.1070897, lng: 18.3716596 },
    { lat: 48.1050728, lng: 18.3722646 },
    { lat: 48.1040948, lng: 18.3727873 },
    { lat: 48.1030895, lng: 18.3731441 },
    { lat: 48.1026772, lng: 18.3731747 },
    { lat: 48.1019788, lng: 18.3733734 },
    { lat: 48.1013584, lng: 18.3733737 },
    { lat: 48.1012248, lng: 18.3733857 },
    { lat: 48.1003278, lng: 18.3736228 },
    { lat: 48.0998323, lng: 18.3736604 },
    { lat: 48.099006, lng: 18.3732554 },
    { lat: 48.0982828, lng: 18.3722809 },
    { lat: 48.0952407, lng: 18.3698221 },
    { lat: 48.095103, lng: 18.3701998 },
    { lat: 48.0947708, lng: 18.3716838 },
    { lat: 48.0943263, lng: 18.3740144 },
    { lat: 48.094249, lng: 18.3742229 },
    { lat: 48.0941331, lng: 18.374469 },
    { lat: 48.0937497, lng: 18.3742326 },
    { lat: 48.0933432, lng: 18.374664 },
    { lat: 48.0928342, lng: 18.3749189 },
    { lat: 48.0930335, lng: 18.3756697 },
    { lat: 48.093886, lng: 18.3780691 },
    { lat: 48.0940768, lng: 18.378729 },
    { lat: 48.0941983, lng: 18.3794219 },
    { lat: 48.0943547, lng: 18.3806091 },
    { lat: 48.0944004, lng: 18.3813967 },
    { lat: 48.0946821, lng: 18.383366 },
    { lat: 48.0947139, lng: 18.3862219 },
    { lat: 48.0950296, lng: 18.3894077 },
    { lat: 48.0953173, lng: 18.3912228 },
    { lat: 48.0953112, lng: 18.3932327 },
    { lat: 48.0948916, lng: 18.3980077 },
    { lat: 48.0947464, lng: 18.3992131 },
    { lat: 48.0948017, lng: 18.4001519 },
    { lat: 48.09477, lng: 18.4025899 },
    { lat: 48.094771, lng: 18.4026272 },
  ],
  DvorynadŽitavou: [
    { lat: 47.9761075, lng: 18.3340045 },
    { lat: 47.9781411, lng: 18.3318597 },
    { lat: 47.9801589, lng: 18.3294593 },
    { lat: 47.9822721, lng: 18.3263624 },
    { lat: 47.9838218, lng: 18.3243246 },
    { lat: 47.9857192, lng: 18.3218723 },
    { lat: 47.9893024, lng: 18.3175919 },
    { lat: 47.9906948, lng: 18.3156461 },
    { lat: 47.9912454, lng: 18.3149562 },
    { lat: 47.9926858, lng: 18.3137677 },
    { lat: 47.9930283, lng: 18.3134047 },
    { lat: 47.994928, lng: 18.3110947 },
    { lat: 47.996645, lng: 18.3091652 },
    { lat: 47.9977799, lng: 18.3084314 },
    { lat: 47.9980548, lng: 18.3081502 },
    { lat: 47.9985382, lng: 18.3078054 },
    { lat: 47.9999802, lng: 18.3068623 },
    { lat: 48.0000959, lng: 18.3068514 },
    { lat: 48.0003143, lng: 18.3066475 },
    { lat: 48.000462, lng: 18.3066047 },
    { lat: 48.0012726, lng: 18.3060425 },
    { lat: 48.0014351, lng: 18.3059851 },
    { lat: 48.0020923, lng: 18.3052838 },
    { lat: 48.0028603, lng: 18.3047076 },
    { lat: 48.0035955, lng: 18.3038938 },
    { lat: 48.0041278, lng: 18.3035959 },
    { lat: 48.0054323, lng: 18.3022205 },
    { lat: 48.0059651, lng: 18.3015396 },
    { lat: 48.0069343, lng: 18.2999877 },
    { lat: 48.0082422, lng: 18.298637 },
    { lat: 48.0090505, lng: 18.2979712 },
    { lat: 48.0097627, lng: 18.2971901 },
    { lat: 48.0099865, lng: 18.2975143 },
    { lat: 48.0103102, lng: 18.297256 },
    { lat: 48.0115904, lng: 18.2959122 },
    { lat: 48.0122773, lng: 18.295086 },
    { lat: 48.012808, lng: 18.2942445 },
    { lat: 48.0132893, lng: 18.2931562 },
    { lat: 48.0143045, lng: 18.2916428 },
    { lat: 48.0143475, lng: 18.2914696 },
    { lat: 48.0151389, lng: 18.2904953 },
    { lat: 48.0149723, lng: 18.2901465 },
    { lat: 48.0171362, lng: 18.2873187 },
    { lat: 48.0198199, lng: 18.2840542 },
    { lat: 48.0231874, lng: 18.2805939 },
    { lat: 48.0249858, lng: 18.2791193 },
    { lat: 48.0250664, lng: 18.2792088 },
    { lat: 48.0267851, lng: 18.2741412 },
    { lat: 48.0263918, lng: 18.2717602 },
    { lat: 48.0258502, lng: 18.2688628 },
    { lat: 48.0257162, lng: 18.2672649 },
    { lat: 48.0258606, lng: 18.2652313 },
    { lat: 48.0260444, lng: 18.2639962 },
    { lat: 48.026326, lng: 18.262915 },
    { lat: 48.0276961, lng: 18.2586387 },
    { lat: 48.0281167, lng: 18.2570902 },
    { lat: 48.0283718, lng: 18.2559033 },
    { lat: 48.0288101, lng: 18.2543309 },
    { lat: 48.0293352, lng: 18.2525588 },
    { lat: 48.02981, lng: 18.2511702 },
    { lat: 48.0321953, lng: 18.2452591 },
    { lat: 48.0336617, lng: 18.2418814 },
    { lat: 48.0351125, lng: 18.238448 },
    { lat: 48.0332943, lng: 18.2375837 },
    { lat: 48.0296575, lng: 18.2350311 },
    { lat: 48.0254051, lng: 18.2323776 },
    { lat: 48.022081, lng: 18.2313091 },
    { lat: 48.0204518, lng: 18.230435 },
    { lat: 48.0187211, lng: 18.2297992 },
    { lat: 48.017437, lng: 18.2286717 },
    { lat: 48.0153505, lng: 18.2272893 },
    { lat: 48.0122171, lng: 18.2249792 },
    { lat: 48.0097829, lng: 18.2235975 },
    { lat: 48.00834, lng: 18.2226878 },
    { lat: 48.0073807, lng: 18.2211541 },
    { lat: 48.0065419, lng: 18.219688 },
    { lat: 48.005739, lng: 18.2184389 },
    { lat: 48.0035761, lng: 18.2166978 },
    { lat: 48.0022998, lng: 18.2159963 },
    { lat: 47.9979612, lng: 18.2139055 },
    { lat: 47.9940963, lng: 18.212334 },
    { lat: 47.9907473, lng: 18.2118892 },
    { lat: 47.9900954, lng: 18.2119508 },
    { lat: 47.9899066, lng: 18.2121401 },
    { lat: 47.9896271, lng: 18.2123016 },
    { lat: 47.9850779, lng: 18.2141135 },
    { lat: 47.9820298, lng: 18.2155042 },
    { lat: 47.9804299, lng: 18.2166905 },
    { lat: 47.9794268, lng: 18.2167872 },
    { lat: 47.9793376, lng: 18.2169034 },
    { lat: 47.9785452, lng: 18.2167602 },
    { lat: 47.9780241, lng: 18.2168834 },
    { lat: 47.9780559, lng: 18.2169255 },
    { lat: 47.9772692, lng: 18.2173848 },
    { lat: 47.9770216, lng: 18.2174731 },
    { lat: 47.9768652, lng: 18.2174838 },
    { lat: 47.9764877, lng: 18.2172588 },
    { lat: 47.9759995, lng: 18.2170933 },
    { lat: 47.9742564, lng: 18.2169232 },
    { lat: 47.9731808, lng: 18.2167257 },
    { lat: 47.9719396, lng: 18.2165904 },
    { lat: 47.9710801, lng: 18.2165957 },
    { lat: 47.9695815, lng: 18.216324 },
    { lat: 47.9666884, lng: 18.2166146 },
    { lat: 47.9635646, lng: 18.2167043 },
    { lat: 47.9630375, lng: 18.2168787 },
    { lat: 47.9629584, lng: 18.2168371 },
    { lat: 47.960767, lng: 18.219272 },
    { lat: 47.960172, lng: 18.222266 },
    { lat: 47.959805, lng: 18.224196 },
    { lat: 47.959786, lng: 18.224293 },
    { lat: 47.959749, lng: 18.224491 },
    { lat: 47.959719, lng: 18.224642 },
    { lat: 47.959712, lng: 18.224679 },
    { lat: 47.95967, lng: 18.2249 },
    { lat: 47.959647, lng: 18.225018 },
    { lat: 47.959613, lng: 18.225194 },
    { lat: 47.959607, lng: 18.225222 },
    { lat: 47.959375, lng: 18.226427 },
    { lat: 47.959281, lng: 18.228538 },
    { lat: 47.959286, lng: 18.229425 },
    { lat: 47.959287, lng: 18.229611 },
    { lat: 47.959296, lng: 18.230983 },
    { lat: 47.958658, lng: 18.236538 },
    { lat: 47.957523, lng: 18.238884 },
    { lat: 47.956407, lng: 18.241211 },
    { lat: 47.955273, lng: 18.243571 },
    { lat: 47.95521, lng: 18.243703 },
    { lat: 47.95514, lng: 18.24385 },
    { lat: 47.955012, lng: 18.244117 },
    { lat: 47.954906, lng: 18.244351 },
    { lat: 47.954681, lng: 18.24507 },
    { lat: 47.954414, lng: 18.246056 },
    { lat: 47.953469, lng: 18.247605 },
    { lat: 47.953447, lng: 18.247643 },
    { lat: 47.953415, lng: 18.247697 },
    { lat: 47.952865, lng: 18.247294 },
    { lat: 47.952833, lng: 18.247358 },
    { lat: 47.952677, lng: 18.247692 },
    { lat: 47.95222, lng: 18.248336 },
    { lat: 47.951373, lng: 18.249315 },
    { lat: 47.950561, lng: 18.250261 },
    { lat: 47.949999, lng: 18.251104 },
    { lat: 47.949777, lng: 18.251458 },
    { lat: 47.949593, lng: 18.251848 },
    { lat: 47.949192, lng: 18.252912 },
    { lat: 47.949156, lng: 18.252982 },
    { lat: 47.949125, lng: 18.253038 },
    { lat: 47.949068, lng: 18.253145 },
    { lat: 47.948312, lng: 18.254179 },
    { lat: 47.948225, lng: 18.254311 },
    { lat: 47.947704, lng: 18.255092 },
    { lat: 47.947032, lng: 18.256261 },
    { lat: 47.946584, lng: 18.257109 },
    { lat: 47.946314, lng: 18.257613 },
    { lat: 47.945864, lng: 18.258452 },
    { lat: 47.945296, lng: 18.259487 },
    { lat: 47.944987, lng: 18.260094 },
    { lat: 47.944131, lng: 18.261906 },
    { lat: 47.943892, lng: 18.262403 },
    { lat: 47.943248, lng: 18.263503 },
    { lat: 47.942919, lng: 18.26415 },
    { lat: 47.942797, lng: 18.264402 },
    { lat: 47.942293, lng: 18.265542 },
    { lat: 47.941945, lng: 18.266253 },
    { lat: 47.941632, lng: 18.266869 },
    { lat: 47.94142, lng: 18.267347 },
    { lat: 47.941241, lng: 18.26783 },
    { lat: 47.941014, lng: 18.268591 },
    { lat: 47.940851, lng: 18.269169 },
    { lat: 47.940761, lng: 18.269462 },
    { lat: 47.940506, lng: 18.270155 },
    { lat: 47.940223, lng: 18.270798 },
    { lat: 47.939878, lng: 18.271643 },
    { lat: 47.940171, lng: 18.27196 },
    { lat: 47.940153, lng: 18.27199 },
    { lat: 47.943349, lng: 18.274155 },
    { lat: 47.9441904, lng: 18.2751038 },
    { lat: 47.9477435, lng: 18.2774341 },
    { lat: 47.9490344, lng: 18.2784703 },
    { lat: 47.9504858, lng: 18.2805306 },
    { lat: 47.9516275, lng: 18.2819974 },
    { lat: 47.952898, lng: 18.283363 },
    { lat: 47.952938, lng: 18.28428 },
    { lat: 47.952967, lng: 18.2851545 },
    { lat: 47.9529565, lng: 18.2866345 },
    { lat: 47.9526271, lng: 18.2880467 },
    { lat: 47.952017, lng: 18.2896781 },
    { lat: 47.9517806, lng: 18.2916877 },
    { lat: 47.9518174, lng: 18.2930345 },
    { lat: 47.9517955, lng: 18.294671 },
    { lat: 47.9513248, lng: 18.2983603 },
    { lat: 47.9503731, lng: 18.3035499 },
    { lat: 47.9499643, lng: 18.306275 },
    { lat: 47.9497676, lng: 18.3082733 },
    { lat: 47.9498125, lng: 18.3093892 },
    { lat: 47.9496495, lng: 18.3110725 },
    { lat: 47.949539, lng: 18.3115243 },
    { lat: 47.9494337, lng: 18.3117735 },
    { lat: 47.9480833, lng: 18.3135679 },
    { lat: 47.9461749, lng: 18.3167063 },
    { lat: 47.9454444, lng: 18.3178168 },
    { lat: 47.9447735, lng: 18.3187311 },
    { lat: 47.9434209, lng: 18.3202889 },
    { lat: 47.9418704, lng: 18.3222539 },
    { lat: 47.9402668, lng: 18.3252026 },
    { lat: 47.9387886, lng: 18.3277616 },
    { lat: 47.938062, lng: 18.329153 },
    { lat: 47.9381079, lng: 18.3292353 },
    { lat: 47.9381366, lng: 18.3291841 },
    { lat: 47.9383243, lng: 18.329407 },
    { lat: 47.939502, lng: 18.3313583 },
    { lat: 47.940906, lng: 18.3338517 },
    { lat: 47.9418179, lng: 18.3350586 },
    { lat: 47.9472157, lng: 18.3397777 },
    { lat: 47.9490085, lng: 18.3409458 },
    { lat: 47.9507592, lng: 18.341988 },
    { lat: 47.9525405, lng: 18.343939 },
    { lat: 47.9562554, lng: 18.347787 },
    { lat: 47.957085, lng: 18.3479123 },
    { lat: 47.958211, lng: 18.3471406 },
    { lat: 47.9596268, lng: 18.3474519 },
    { lat: 47.9609158, lng: 18.3468517 },
    { lat: 47.9616798, lng: 18.3448778 },
    { lat: 47.9612226, lng: 18.3445337 },
    { lat: 47.9604934, lng: 18.343602 },
    { lat: 47.97376, lng: 18.3308533 },
    { lat: 47.9760529, lng: 18.3339378 },
    { lat: 47.9761075, lng: 18.3340045 },
  ],
  Trávnica: [
    { lat: 48.156451, lng: 18.374806 },
    { lat: 48.156463, lng: 18.374573 },
    { lat: 48.156452, lng: 18.374095 },
    { lat: 48.156331, lng: 18.373633 },
    { lat: 48.156248, lng: 18.373182 },
    { lat: 48.156283, lng: 18.37223 },
    { lat: 48.156332, lng: 18.371773 },
    { lat: 48.156429, lng: 18.371376 },
    { lat: 48.156614, lng: 18.370522 },
    { lat: 48.156594, lng: 18.369981 },
    { lat: 48.156423, lng: 18.369465 },
    { lat: 48.156629, lng: 18.36818 },
    { lat: 48.156725, lng: 18.367781 },
    { lat: 48.156731, lng: 18.367753 },
    { lat: 48.156748, lng: 18.367609 },
    { lat: 48.156765, lng: 18.367286 },
    { lat: 48.156771, lng: 18.367153 },
    { lat: 48.156779, lng: 18.366979 },
    { lat: 48.15678, lng: 18.366971 },
    { lat: 48.156791, lng: 18.36684 },
    { lat: 48.156889, lng: 18.365818 },
    { lat: 48.157046, lng: 18.364712 },
    { lat: 48.157126, lng: 18.363941 },
    { lat: 48.157172, lng: 18.363573 },
    { lat: 48.157198, lng: 18.36348 },
    { lat: 48.157203, lng: 18.363458 },
    { lat: 48.157401, lng: 18.362682 },
    { lat: 48.157596, lng: 18.361843 },
    { lat: 48.157991, lng: 18.360169 },
    { lat: 48.158293, lng: 18.359143 },
    { lat: 48.158394, lng: 18.358827 },
    { lat: 48.158691, lng: 18.357788 },
    { lat: 48.158931, lng: 18.357247 },
    { lat: 48.159246, lng: 18.356624 },
    { lat: 48.159534, lng: 18.356056 },
    { lat: 48.159666, lng: 18.355755 },
    { lat: 48.159848, lng: 18.3555 },
    { lat: 48.160544, lng: 18.35469 },
    { lat: 48.160724, lng: 18.354425 },
    { lat: 48.160938, lng: 18.353893 },
    { lat: 48.160948, lng: 18.353845 },
    { lat: 48.161103, lng: 18.353293 },
    { lat: 48.161446, lng: 18.351839 },
    { lat: 48.16153, lng: 18.351474 },
    { lat: 48.161609, lng: 18.351156 },
    { lat: 48.161667, lng: 18.351016 },
    { lat: 48.161818, lng: 18.350712 },
    { lat: 48.162035, lng: 18.350347 },
    { lat: 48.162306, lng: 18.349929 },
    { lat: 48.162369, lng: 18.349917 },
    { lat: 48.162623, lng: 18.349579 },
    { lat: 48.162894, lng: 18.349202 },
    { lat: 48.163047, lng: 18.349093 },
    { lat: 48.163596, lng: 18.348984 },
    { lat: 48.163873, lng: 18.348962 },
    { lat: 48.164352, lng: 18.348968 },
    { lat: 48.165032, lng: 18.34887 },
    { lat: 48.165522, lng: 18.348783 },
    { lat: 48.165788, lng: 18.34869 },
    { lat: 48.166308, lng: 18.348561 },
    { lat: 48.166619, lng: 18.348483 },
    { lat: 48.166839, lng: 18.348402 },
    { lat: 48.166929, lng: 18.348355 },
    { lat: 48.166976, lng: 18.348336 },
    { lat: 48.167065, lng: 18.348255 },
    { lat: 48.167176, lng: 18.348179 },
    { lat: 48.167334, lng: 18.348084 },
    { lat: 48.167417, lng: 18.348026 },
    { lat: 48.167498, lng: 18.348006 },
    { lat: 48.167665, lng: 18.347986 },
    { lat: 48.167812, lng: 18.347991 },
    { lat: 48.16798, lng: 18.347991 },
    { lat: 48.168162, lng: 18.347983 },
    { lat: 48.168266, lng: 18.347967 },
    { lat: 48.168458, lng: 18.347872 },
    { lat: 48.168605, lng: 18.347817 },
    { lat: 48.168772, lng: 18.347752 },
    { lat: 48.168916, lng: 18.347723 },
    { lat: 48.169063, lng: 18.347706 },
    { lat: 48.169228, lng: 18.347707 },
    { lat: 48.169376, lng: 18.347691 },
    { lat: 48.169559, lng: 18.347701 },
    { lat: 48.169723, lng: 18.347757 },
    { lat: 48.17001, lng: 18.347717 },
    { lat: 48.1703, lng: 18.347755 },
    { lat: 48.170479, lng: 18.347791 },
    { lat: 48.170711, lng: 18.347749 },
    { lat: 48.170848, lng: 18.347749 },
    { lat: 48.170912, lng: 18.347744 },
    { lat: 48.171097, lng: 18.347651 },
    { lat: 48.171264, lng: 18.347591 },
    { lat: 48.171346, lng: 18.34757 },
    { lat: 48.171489, lng: 18.347545 },
    { lat: 48.171566, lng: 18.347539 },
    { lat: 48.171734, lng: 18.347506 },
    { lat: 48.171833, lng: 18.347477 },
    { lat: 48.172036, lng: 18.347447 },
    { lat: 48.172138, lng: 18.347437 },
    { lat: 48.172386, lng: 18.347502 },
    { lat: 48.172528, lng: 18.347532 },
    { lat: 48.172812, lng: 18.347507 },
    { lat: 48.173112, lng: 18.347488 },
    { lat: 48.173519, lng: 18.347514 },
    { lat: 48.173642, lng: 18.347456 },
    { lat: 48.173844, lng: 18.347425 },
    { lat: 48.174139, lng: 18.347325 },
    { lat: 48.174568, lng: 18.347189 },
    { lat: 48.175125, lng: 18.347027 },
    { lat: 48.175243, lng: 18.347007 },
    { lat: 48.175413, lng: 18.346986 },
    { lat: 48.175462, lng: 18.346973 },
    { lat: 48.175513, lng: 18.346928 },
    { lat: 48.175822, lng: 18.346698 },
    { lat: 48.175966, lng: 18.346618 },
    { lat: 48.176314, lng: 18.346417 },
    { lat: 48.176769, lng: 18.346166 },
    { lat: 48.176907, lng: 18.34621 },
    { lat: 48.176959, lng: 18.346224 },
    { lat: 48.177021, lng: 18.346233 },
    { lat: 48.177018, lng: 18.346208 },
    { lat: 48.177004, lng: 18.346125 },
    { lat: 48.177017, lng: 18.34611 },
    { lat: 48.177051, lng: 18.346042 },
    { lat: 48.177199, lng: 18.345653 },
    { lat: 48.177288, lng: 18.345383 },
    { lat: 48.177371, lng: 18.345096 },
    { lat: 48.177735, lng: 18.343748 },
    { lat: 48.177776, lng: 18.343632 },
    { lat: 48.177793, lng: 18.343409 },
    { lat: 48.177722, lng: 18.343147 },
    { lat: 48.177661, lng: 18.342887 },
    { lat: 48.177632, lng: 18.342264 },
    { lat: 48.17759, lng: 18.341198 },
    { lat: 48.17748, lng: 18.340569 },
    { lat: 48.177467, lng: 18.340403 },
    { lat: 48.177486, lng: 18.339898 },
    { lat: 48.177498, lng: 18.339319 },
    { lat: 48.177523, lng: 18.33878 },
    { lat: 48.177555, lng: 18.338375 },
    { lat: 48.177674, lng: 18.337441 },
    { lat: 48.177729, lng: 18.336686 },
    { lat: 48.177757, lng: 18.336415 },
    { lat: 48.177804, lng: 18.336009 },
    { lat: 48.177799, lng: 18.335605 },
    { lat: 48.177766, lng: 18.334888 },
    { lat: 48.177673, lng: 18.334448 },
    { lat: 48.177444, lng: 18.33352 },
    { lat: 48.177409, lng: 18.333146 },
    { lat: 48.177321, lng: 18.332805 },
    { lat: 48.177156, lng: 18.331881 },
    { lat: 48.177072, lng: 18.330974 },
    { lat: 48.17705, lng: 18.330811 },
    { lat: 48.17702, lng: 18.330705 },
    { lat: 48.176979, lng: 18.330602 },
    { lat: 48.176718, lng: 18.330081 },
    { lat: 48.17671, lng: 18.330049 },
    { lat: 48.176684, lng: 18.329978 },
    { lat: 48.1761253, lng: 18.3303091 },
    { lat: 48.1757625, lng: 18.3303974 },
    { lat: 48.1726431, lng: 18.3305031 },
    { lat: 48.1708851, lng: 18.3301589 },
    { lat: 48.1694017, lng: 18.3297318 },
    { lat: 48.1687705, lng: 18.3294842 },
    { lat: 48.166279, lng: 18.3295583 },
    { lat: 48.1658604, lng: 18.3294406 },
    { lat: 48.1648508, lng: 18.3294427 },
    { lat: 48.1638867, lng: 18.3293541 },
    { lat: 48.1632207, lng: 18.3295481 },
    { lat: 48.1625622, lng: 18.3293952 },
    { lat: 48.1618649, lng: 18.3293561 },
    { lat: 48.1601186, lng: 18.3290441 },
    { lat: 48.1576956, lng: 18.3290141 },
    { lat: 48.1571561, lng: 18.3287538 },
    { lat: 48.1562441, lng: 18.32857 },
    { lat: 48.1556692, lng: 18.3283015 },
    { lat: 48.1552683, lng: 18.3279384 },
    { lat: 48.1549444, lng: 18.3278171 },
    { lat: 48.1541318, lng: 18.3277563 },
    { lat: 48.1531975, lng: 18.3278116 },
    { lat: 48.1526903, lng: 18.3277232 },
    { lat: 48.1519241, lng: 18.3273046 },
    { lat: 48.1514682, lng: 18.3268899 },
    { lat: 48.151212, lng: 18.3267879 },
    { lat: 48.1512274, lng: 18.3267216 },
    { lat: 48.1504872, lng: 18.3265453 },
    { lat: 48.1496955, lng: 18.326173 },
    { lat: 48.1487245, lng: 18.3252127 },
    { lat: 48.148154, lng: 18.3249014 },
    { lat: 48.1474111, lng: 18.3246777 },
    { lat: 48.1469566, lng: 18.3244229 },
    { lat: 48.1466424, lng: 18.3240591 },
    { lat: 48.1459215, lng: 18.3228721 },
    { lat: 48.1456142, lng: 18.3225509 },
    { lat: 48.1452301, lng: 18.3222836 },
    { lat: 48.1442538, lng: 18.3211931 },
    { lat: 48.1436827, lng: 18.3206936 },
    { lat: 48.1433421, lng: 18.3200029 },
    { lat: 48.1428054, lng: 18.3194563 },
    { lat: 48.1424524, lng: 18.3191844 },
    { lat: 48.1419422, lng: 18.3190014 },
    { lat: 48.1410101, lng: 18.3193361 },
    { lat: 48.1405215, lng: 18.3193161 },
    { lat: 48.1400651, lng: 18.3195604 },
    { lat: 48.1397654, lng: 18.3199833 },
    { lat: 48.1396961, lng: 18.3200165 },
    { lat: 48.1395039, lng: 18.3200506 },
    { lat: 48.1391342, lng: 18.3199776 },
    { lat: 48.1379726, lng: 18.3202825 },
    { lat: 48.137535, lng: 18.3202051 },
    { lat: 48.1365393, lng: 18.3198812 },
    { lat: 48.1356282, lng: 18.3201849 },
    { lat: 48.1353872, lng: 18.3201132 },
    { lat: 48.13541, lng: 18.3200767 },
    { lat: 48.1353195, lng: 18.3198174 },
    { lat: 48.1349102, lng: 18.3191719 },
    { lat: 48.1332975, lng: 18.3178421 },
    { lat: 48.1320458, lng: 18.3169071 },
    { lat: 48.1320397, lng: 18.3167995 },
    { lat: 48.1325562, lng: 18.3157464 },
    { lat: 48.1326805, lng: 18.315394 },
    { lat: 48.1322104, lng: 18.3144166 },
    { lat: 48.1315234, lng: 18.3136279 },
    { lat: 48.131263, lng: 18.313415 },
    { lat: 48.1308191, lng: 18.3132272 },
    { lat: 48.1307856, lng: 18.3132159 },
    { lat: 48.130783, lng: 18.3132873 },
    { lat: 48.1292491, lng: 18.3133003 },
    { lat: 48.126812, lng: 18.3134759 },
    { lat: 48.1230242, lng: 18.3150156 },
    { lat: 48.1226002, lng: 18.3143479 },
    { lat: 48.1224423, lng: 18.3141922 },
    { lat: 48.1186044, lng: 18.3121477 },
    { lat: 48.1184424, lng: 18.3119985 },
    { lat: 48.117334, lng: 18.3103158 },
    { lat: 48.1173215, lng: 18.3105321 },
    { lat: 48.1172451, lng: 18.3118003 },
    { lat: 48.1170662, lng: 18.3118888 },
    { lat: 48.1195469, lng: 18.3238053 },
    { lat: 48.1194862, lng: 18.3239886 },
    { lat: 48.1194785, lng: 18.3242268 },
    { lat: 48.1195163, lng: 18.325187 },
    { lat: 48.1195947, lng: 18.3260054 },
    { lat: 48.1197041, lng: 18.3265011 },
    { lat: 48.1177652, lng: 18.3287237 },
    { lat: 48.1180349, lng: 18.329635 },
    { lat: 48.1181472, lng: 18.3302019 },
    { lat: 48.1182372, lng: 18.3311833 },
    { lat: 48.1186884, lng: 18.3341703 },
    { lat: 48.1184933, lng: 18.3342315 },
    { lat: 48.1185601, lng: 18.3350672 },
    { lat: 48.1186766, lng: 18.3354895 },
    { lat: 48.1185042, lng: 18.3356311 },
    { lat: 48.1189711, lng: 18.3376254 },
    { lat: 48.1191601, lng: 18.3387472 },
    { lat: 48.1192231, lng: 18.3403281 },
    { lat: 48.1191327, lng: 18.3418709 },
    { lat: 48.1187072, lng: 18.3419666 },
    { lat: 48.1185982, lng: 18.3433052 },
    { lat: 48.1181298, lng: 18.3458381 },
    { lat: 48.1180604, lng: 18.3460054 },
    { lat: 48.117588, lng: 18.3466285 },
    { lat: 48.1174192, lng: 18.3470323 },
    { lat: 48.1169711, lng: 18.3478991 },
    { lat: 48.1148894, lng: 18.3520666 },
    { lat: 48.1148315, lng: 18.3525569 },
    { lat: 48.1148624, lng: 18.3528425 },
    { lat: 48.1145553, lng: 18.3552395 },
    { lat: 48.1144647, lng: 18.3582935 },
    { lat: 48.1140577, lng: 18.361023 },
    { lat: 48.1140319, lng: 18.3611643 },
    { lat: 48.1144554, lng: 18.3608106 },
    { lat: 48.1151634, lng: 18.3602058 },
    { lat: 48.115753, lng: 18.3617496 },
    { lat: 48.1162152, lng: 18.3651419 },
    { lat: 48.1216584, lng: 18.3636592 },
    { lat: 48.1236403, lng: 18.3641096 },
    { lat: 48.1239804, lng: 18.3648742 },
    { lat: 48.1254773, lng: 18.3627839 },
    { lat: 48.1298186, lng: 18.3674342 },
    { lat: 48.1300025, lng: 18.3680502 },
    { lat: 48.132268, lng: 18.3679526 },
    { lat: 48.1327809, lng: 18.3681434 },
    { lat: 48.1335066, lng: 18.3685245 },
    { lat: 48.133871, lng: 18.3683887 },
    { lat: 48.1340601, lng: 18.3684379 },
    { lat: 48.1384198, lng: 18.3771681 },
    { lat: 48.139477, lng: 18.3798772 },
    { lat: 48.1403377, lng: 18.383767 },
    { lat: 48.1409596, lng: 18.3855892 },
    { lat: 48.1415216, lng: 18.3869076 },
    { lat: 48.141614, lng: 18.386987 },
    { lat: 48.141822, lng: 18.386003 },
    { lat: 48.142657, lng: 18.383801 },
    { lat: 48.143345, lng: 18.384016 },
    { lat: 48.143543, lng: 18.384077 },
    { lat: 48.143815, lng: 18.384189 },
    { lat: 48.144113, lng: 18.38429 },
    { lat: 48.144173, lng: 18.384301 },
    { lat: 48.144211, lng: 18.384296 },
    { lat: 48.14428, lng: 18.384275 },
    { lat: 48.144455, lng: 18.384178 },
    { lat: 48.144844, lng: 18.383908 },
    { lat: 48.144906, lng: 18.383872 },
    { lat: 48.14501, lng: 18.383813 },
    { lat: 48.145255, lng: 18.383672 },
    { lat: 48.145443, lng: 18.383535 },
    { lat: 48.145612, lng: 18.383412 },
    { lat: 48.145714, lng: 18.383326 },
    { lat: 48.146246, lng: 18.382849 },
    { lat: 48.146746, lng: 18.382367 },
    { lat: 48.146838, lng: 18.382331 },
    { lat: 48.147155, lng: 18.382079 },
    { lat: 48.14748, lng: 18.381837 },
    { lat: 48.147906, lng: 18.381374 },
    { lat: 48.148106, lng: 18.381202 },
    { lat: 48.148419, lng: 18.381089 },
    { lat: 48.148626, lng: 18.381049 },
    { lat: 48.148862, lng: 18.381025 },
    { lat: 48.149106, lng: 18.381011 },
    { lat: 48.149141, lng: 18.380882 },
    { lat: 48.149492, lng: 18.380941 },
    { lat: 48.149992, lng: 18.380992 },
    { lat: 48.150654, lng: 18.380993 },
    { lat: 48.15098, lng: 18.380975 },
    { lat: 48.151245, lng: 18.380946 },
    { lat: 48.151862, lng: 18.380946 },
    { lat: 48.152261, lng: 18.380996 },
    { lat: 48.152633, lng: 18.381032 },
    { lat: 48.152705, lng: 18.380646 },
    { lat: 48.152771, lng: 18.380383 },
    { lat: 48.152811, lng: 18.380247 },
    { lat: 48.152924, lng: 18.37944 },
    { lat: 48.152982, lng: 18.37922 },
    { lat: 48.153045, lng: 18.379017 },
    { lat: 48.1531, lng: 18.378339 },
    { lat: 48.153109, lng: 18.377747 },
    { lat: 48.153098, lng: 18.377609 },
    { lat: 48.153132, lng: 18.377475 },
    { lat: 48.153046, lng: 18.37736 },
    { lat: 48.153489, lng: 18.376983 },
    { lat: 48.153526, lng: 18.376931 },
    { lat: 48.153547, lng: 18.377029 },
    { lat: 48.153897, lng: 18.377136 },
    { lat: 48.153919, lng: 18.377156 },
    { lat: 48.153991, lng: 18.377247 },
    { lat: 48.154159, lng: 18.377378 },
    { lat: 48.154452, lng: 18.377609 },
    { lat: 48.154674, lng: 18.377769 },
    { lat: 48.154702, lng: 18.377754 },
    { lat: 48.154719, lng: 18.377665 },
    { lat: 48.154706, lng: 18.377421 },
    { lat: 48.15473, lng: 18.37706 },
    { lat: 48.154768, lng: 18.376694 },
    { lat: 48.15476, lng: 18.376346 },
    { lat: 48.154761, lng: 18.376178 },
    { lat: 48.15479, lng: 18.375691 },
    { lat: 48.154817, lng: 18.375367 },
    { lat: 48.154822, lng: 18.375021 },
    { lat: 48.154823, lng: 18.374636 },
    { lat: 48.154892, lng: 18.374639 },
    { lat: 48.156451, lng: 18.374806 },
  ],
  VeľkéLovce: [
    { lat: 48.041605, lng: 18.311244 },
    { lat: 48.0403981, lng: 18.3117425 },
    { lat: 48.0389435, lng: 18.3126653 },
    { lat: 48.0384016, lng: 18.3130537 },
    { lat: 48.0371519, lng: 18.3141234 },
    { lat: 48.0363819, lng: 18.3144925 },
    { lat: 48.0355042, lng: 18.3147685 },
    { lat: 48.0357864, lng: 18.3164067 },
    { lat: 48.0369678, lng: 18.3194042 },
    { lat: 48.0391479, lng: 18.3265113 },
    { lat: 48.0394789, lng: 18.3271116 },
    { lat: 48.0394068, lng: 18.3277268 },
    { lat: 48.039214, lng: 18.3297626 },
    { lat: 48.0407907, lng: 18.3317855 },
    { lat: 48.0415824, lng: 18.3332828 },
    { lat: 48.0405848, lng: 18.3337736 },
    { lat: 48.0398583, lng: 18.3345248 },
    { lat: 48.0395541, lng: 18.3349487 },
    { lat: 48.0387409, lng: 18.3366154 },
    { lat: 48.0388152, lng: 18.3366916 },
    { lat: 48.0386042, lng: 18.3371041 },
    { lat: 48.0394733, lng: 18.3383173 },
    { lat: 48.0405072, lng: 18.3408377 },
    { lat: 48.0406664, lng: 18.341212 },
    { lat: 48.0416135, lng: 18.3423897 },
    { lat: 48.0426252, lng: 18.342839 },
    { lat: 48.0433962, lng: 18.3433029 },
    { lat: 48.0434153, lng: 18.343261 },
    { lat: 48.0436162, lng: 18.3433427 },
    { lat: 48.0441553, lng: 18.344111 },
    { lat: 48.0444155, lng: 18.3447141 },
    { lat: 48.0448241, lng: 18.3464418 },
    { lat: 48.0452114, lng: 18.3474561 },
    { lat: 48.046647, lng: 18.347385 },
    { lat: 48.0474344, lng: 18.3478187 },
    { lat: 48.0475829, lng: 18.3479526 },
    { lat: 48.0477129, lng: 18.3481723 },
    { lat: 48.0480661, lng: 18.3491646 },
    { lat: 48.0484484, lng: 18.3497167 },
    { lat: 48.0485795, lng: 18.3498013 },
    { lat: 48.0486922, lng: 18.3498367 },
    { lat: 48.0496522, lng: 18.349341 },
    { lat: 48.0502613, lng: 18.3491394 },
    { lat: 48.050982, lng: 18.3491363 },
    { lat: 48.0515535, lng: 18.3492829 },
    { lat: 48.0520751, lng: 18.3495494 },
    { lat: 48.0522846, lng: 18.3498991 },
    { lat: 48.0523649, lng: 18.3519451 },
    { lat: 48.0523266, lng: 18.3524972 },
    { lat: 48.052276, lng: 18.3526591 },
    { lat: 48.0523086, lng: 18.3526804 },
    { lat: 48.0518528, lng: 18.3542265 },
    { lat: 48.0516909, lng: 18.355465 },
    { lat: 48.0514354, lng: 18.3562318 },
    { lat: 48.0508178, lng: 18.3609454 },
    { lat: 48.0507156, lng: 18.3623847 },
    { lat: 48.0506278, lng: 18.3676098 },
    { lat: 48.0507638, lng: 18.3686946 },
    { lat: 48.0515501, lng: 18.3724519 },
    { lat: 48.0520617, lng: 18.3737512 },
    { lat: 48.0532672, lng: 18.3763867 },
    { lat: 48.0549398, lng: 18.3784678 },
    { lat: 48.0575436, lng: 18.3810951 },
    { lat: 48.0586721, lng: 18.3824736 },
    { lat: 48.0601644, lng: 18.3845987 },
    { lat: 48.0614326, lng: 18.3862591 },
    { lat: 48.0629094, lng: 18.3884092 },
    { lat: 48.0633603, lng: 18.38996 },
    { lat: 48.0633752, lng: 18.3900019 },
    { lat: 48.0638007, lng: 18.3894655 },
    { lat: 48.0644787, lng: 18.3886878 },
    { lat: 48.064771, lng: 18.388251 },
    { lat: 48.0656716, lng: 18.3873796 },
    { lat: 48.066355, lng: 18.3865963 },
    { lat: 48.0671471, lng: 18.3860588 },
    { lat: 48.0679073, lng: 18.3856992 },
    { lat: 48.068157, lng: 18.385466 },
    { lat: 48.0688603, lng: 18.3851925 },
    { lat: 48.0697061, lng: 18.3846863 },
    { lat: 48.0704198, lng: 18.384481 },
    { lat: 48.0709362, lng: 18.3838174 },
    { lat: 48.0722862, lng: 18.3835506 },
    { lat: 48.0728231, lng: 18.3836684 },
    { lat: 48.0754928, lng: 18.3830138 },
    { lat: 48.0758961, lng: 18.3830264 },
    { lat: 48.0774276, lng: 18.3834518 },
    { lat: 48.078127, lng: 18.3834494 },
    { lat: 48.0788037, lng: 18.3838262 },
    { lat: 48.0795033, lng: 18.3835885 },
    { lat: 48.0803057, lng: 18.3832125 },
    { lat: 48.0810698, lng: 18.3831003 },
    { lat: 48.0818965, lng: 18.3831333 },
    { lat: 48.0827452, lng: 18.3829399 },
    { lat: 48.0843895, lng: 18.3819298 },
    { lat: 48.0848513, lng: 18.381818 },
    { lat: 48.0854572, lng: 18.3814417 },
    { lat: 48.0856271, lng: 18.3813945 },
    { lat: 48.0857371, lng: 18.3814621 },
    { lat: 48.0859364, lng: 18.3814341 },
    { lat: 48.0861979, lng: 18.3812921 },
    { lat: 48.0869157, lng: 18.3807161 },
    { lat: 48.087546, lng: 18.3807045 },
    { lat: 48.0877368, lng: 18.3804961 },
    { lat: 48.0887971, lng: 18.3797515 },
    { lat: 48.0893613, lng: 18.3789778 },
    { lat: 48.0897547, lng: 18.3780541 },
    { lat: 48.0899472, lng: 18.3778092 },
    { lat: 48.0900882, lng: 18.3777106 },
    { lat: 48.0905043, lng: 18.3771687 },
    { lat: 48.0908995, lng: 18.3768167 },
    { lat: 48.0916085, lng: 18.3759276 },
    { lat: 48.092213, lng: 18.3752835 },
    { lat: 48.0923831, lng: 18.3751233 },
    { lat: 48.0928342, lng: 18.3749189 },
    { lat: 48.0933432, lng: 18.374664 },
    { lat: 48.0937497, lng: 18.3742326 },
    { lat: 48.0941331, lng: 18.374469 },
    { lat: 48.094249, lng: 18.3742229 },
    { lat: 48.0943263, lng: 18.3740144 },
    { lat: 48.0947708, lng: 18.3716838 },
    { lat: 48.095103, lng: 18.3701998 },
    { lat: 48.0952407, lng: 18.3698221 },
    { lat: 48.0982828, lng: 18.3722809 },
    { lat: 48.099006, lng: 18.3732554 },
    { lat: 48.0998323, lng: 18.3736604 },
    { lat: 48.1003278, lng: 18.3736228 },
    { lat: 48.1012248, lng: 18.3733857 },
    { lat: 48.1012746, lng: 18.3727539 },
    { lat: 48.1011044, lng: 18.3714398 },
    { lat: 48.1015347, lng: 18.371329 },
    { lat: 48.1018652, lng: 18.3711281 },
    { lat: 48.1021682, lng: 18.3709006 },
    { lat: 48.1024283, lng: 18.3704138 },
    { lat: 48.1025887, lng: 18.3702774 },
    { lat: 48.1030149, lng: 18.3701148 },
    { lat: 48.1025065, lng: 18.3694449 },
    { lat: 48.1020557, lng: 18.3692167 },
    { lat: 48.1019875, lng: 18.3690072 },
    { lat: 48.1020191, lng: 18.3685514 },
    { lat: 48.1018769, lng: 18.3681525 },
    { lat: 48.1022807, lng: 18.3679529 },
    { lat: 48.1024402, lng: 18.3678014 },
    { lat: 48.1025106, lng: 18.3676496 },
    { lat: 48.1024287, lng: 18.3672399 },
    { lat: 48.1020332, lng: 18.3661371 },
    { lat: 48.1013729, lng: 18.3639836 },
    { lat: 48.1021797, lng: 18.362752 },
    { lat: 48.1025705, lng: 18.361824 },
    { lat: 48.1023485, lng: 18.3607152 },
    { lat: 48.0992742, lng: 18.3562904 },
    { lat: 48.0992537, lng: 18.3552144 },
    { lat: 48.0991126, lng: 18.354778 },
    { lat: 48.099088, lng: 18.3540085 },
    { lat: 48.0987555, lng: 18.3533991 },
    { lat: 48.098698, lng: 18.3531826 },
    { lat: 48.0988983, lng: 18.3526765 },
    { lat: 48.0991316, lng: 18.3525136 },
    { lat: 48.0992032, lng: 18.3526407 },
    { lat: 48.0996514, lng: 18.3519712 },
    { lat: 48.0998653, lng: 18.3515862 },
    { lat: 48.1002108, lng: 18.3507363 },
    { lat: 48.1001708, lng: 18.3504311 },
    { lat: 48.1003905, lng: 18.34971 },
    { lat: 48.1004016, lng: 18.3493687 },
    { lat: 48.1001495, lng: 18.3484901 },
    { lat: 48.1000607, lng: 18.3481759 },
    { lat: 48.0990875, lng: 18.3491185 },
    { lat: 48.0981918, lng: 18.3501921 },
    { lat: 48.0979181, lng: 18.350357 },
    { lat: 48.0971537, lng: 18.3505841 },
    { lat: 48.096582, lng: 18.3513659 },
    { lat: 48.0941873, lng: 18.3533193 },
    { lat: 48.0929792, lng: 18.3544982 },
    { lat: 48.0925794, lng: 18.3550846 },
    { lat: 48.0897324, lng: 18.3574181 },
    { lat: 48.0888519, lng: 18.3565761 },
    { lat: 48.0883191, lng: 18.3564537 },
    { lat: 48.0877436, lng: 18.3553204 },
    { lat: 48.0872668, lng: 18.355584 },
    { lat: 48.0868984, lng: 18.3549214 },
    { lat: 48.0856245, lng: 18.3531332 },
    { lat: 48.0850188, lng: 18.352718 },
    { lat: 48.084356, lng: 18.3524322 },
    { lat: 48.0821878, lng: 18.3511653 },
    { lat: 48.0817948, lng: 18.3507445 },
    { lat: 48.0818897, lng: 18.3492223 },
    { lat: 48.0824375, lng: 18.3473065 },
    { lat: 48.0826362, lng: 18.3462411 },
    { lat: 48.0832841, lng: 18.3438478 },
    { lat: 48.0830236, lng: 18.3419681 },
    { lat: 48.0813588, lng: 18.3391231 },
    { lat: 48.0812044, lng: 18.3379205 },
    { lat: 48.081093, lng: 18.3363024 },
    { lat: 48.0814556, lng: 18.3337181 },
    { lat: 48.0818623, lng: 18.3314143 },
    { lat: 48.0818642, lng: 18.3313481 },
    { lat: 48.0813545, lng: 18.3303631 },
    { lat: 48.080541, lng: 18.3289444 },
    { lat: 48.0803788, lng: 18.3287495 },
    { lat: 48.0795141, lng: 18.3282469 },
    { lat: 48.0786791, lng: 18.3278499 },
    { lat: 48.0752932, lng: 18.3264518 },
    { lat: 48.0741892, lng: 18.3243474 },
    { lat: 48.0724912, lng: 18.3235149 },
    { lat: 48.0720377, lng: 18.3220959 },
    { lat: 48.0720088, lng: 18.3216318 },
    { lat: 48.0717882, lng: 18.3211477 },
    { lat: 48.0718851, lng: 18.3207924 },
    { lat: 48.0720644, lng: 18.3205114 },
    { lat: 48.0722348, lng: 18.3198194 },
    { lat: 48.0724403, lng: 18.3195901 },
    { lat: 48.0724802, lng: 18.3192989 },
    { lat: 48.0729666, lng: 18.3180188 },
    { lat: 48.0731557, lng: 18.3171417 },
    { lat: 48.0733308, lng: 18.316714 },
    { lat: 48.073439, lng: 18.3162568 },
    { lat: 48.0735878, lng: 18.3155439 },
    { lat: 48.073709, lng: 18.3153362 },
    { lat: 48.0737258, lng: 18.3151383 },
    { lat: 48.0738545, lng: 18.3148986 },
    { lat: 48.0738609, lng: 18.3128417 },
    { lat: 48.073784, lng: 18.3122084 },
    { lat: 48.0731234, lng: 18.3085541 },
    { lat: 48.0729868, lng: 18.3082952 },
    { lat: 48.0726368, lng: 18.3076622 },
    { lat: 48.0720624, lng: 18.3070905 },
    { lat: 48.0716573, lng: 18.3069323 },
    { lat: 48.069427, lng: 18.3062692 },
    { lat: 48.0682183, lng: 18.3058304 },
    { lat: 48.0673263, lng: 18.305699 },
    { lat: 48.0657775, lng: 18.3055889 },
    { lat: 48.0647998, lng: 18.3052632 },
    { lat: 48.0643971, lng: 18.3050677 },
    { lat: 48.0638681, lng: 18.304757 },
    { lat: 48.0629926, lng: 18.3040899 },
    { lat: 48.0618901, lng: 18.3035364 },
    { lat: 48.0602978, lng: 18.3031967 },
    { lat: 48.0596705, lng: 18.3031381 },
    { lat: 48.0596554, lng: 18.3033651 },
    { lat: 48.0562927, lng: 18.303636 },
    { lat: 48.0554686, lng: 18.3039835 },
    { lat: 48.0553319, lng: 18.3038602 },
    { lat: 48.0528586, lng: 18.3042352 },
    { lat: 48.0527411, lng: 18.3043878 },
    { lat: 48.049017, lng: 18.3067608 },
    { lat: 48.0475589, lng: 18.3075602 },
    { lat: 48.0462233, lng: 18.3085721 },
    { lat: 48.0450064, lng: 18.3094059 },
    { lat: 48.0416723, lng: 18.3112185 },
    { lat: 48.041605, lng: 18.311244 },
  ],
  Gbelce: [
    { lat: 47.843378, lng: 18.461641 },
    { lat: 47.843364, lng: 18.461649 },
    { lat: 47.843212, lng: 18.462153 },
    { lat: 47.84316, lng: 18.462289 },
    { lat: 47.842977, lng: 18.463269 },
    { lat: 47.842946, lng: 18.464647 },
    { lat: 47.842912, lng: 18.465353 },
    { lat: 47.842899, lng: 18.465402 },
    { lat: 47.842519, lng: 18.46609 },
    { lat: 47.84245, lng: 18.46625 },
    { lat: 47.842402, lng: 18.466396 },
    { lat: 47.842208, lng: 18.467102 },
    { lat: 47.842095, lng: 18.467399 },
    { lat: 47.841716, lng: 18.467868 },
    { lat: 47.841857, lng: 18.46807 },
    { lat: 47.841757, lng: 18.468344 },
    { lat: 47.841649, lng: 18.468639 },
    { lat: 47.841636, lng: 18.468788 },
    { lat: 47.841425, lng: 18.469895 },
    { lat: 47.841329, lng: 18.470351 },
    { lat: 47.841223, lng: 18.470753 },
    { lat: 47.841156, lng: 18.470918 },
    { lat: 47.841126, lng: 18.47098 },
    { lat: 47.841109, lng: 18.470964 },
    { lat: 47.840762, lng: 18.471768 },
    { lat: 47.840616, lng: 18.472072 },
    { lat: 47.840567, lng: 18.472158 },
    { lat: 47.83958, lng: 18.473739 },
    { lat: 47.839475, lng: 18.473901 },
    { lat: 47.838316, lng: 18.476374 },
    { lat: 47.838065, lng: 18.476993 },
    { lat: 47.837966, lng: 18.477212 },
    { lat: 47.837915, lng: 18.477206 },
    { lat: 47.83785, lng: 18.4773 },
    { lat: 47.837744, lng: 18.477104 },
    { lat: 47.837586, lng: 18.476525 },
    { lat: 47.837559, lng: 18.476426 },
    { lat: 47.836853, lng: 18.476824 },
    { lat: 47.833229, lng: 18.478825 },
    { lat: 47.833178, lng: 18.478771 },
    { lat: 47.833119, lng: 18.478837 },
    { lat: 47.833087, lng: 18.478897 },
    { lat: 47.8329817, lng: 18.4799484 },
    { lat: 47.832457, lng: 18.480365 },
    { lat: 47.831404, lng: 18.482433 },
    { lat: 47.830316, lng: 18.484365 },
    { lat: 47.828947, lng: 18.486698 },
    { lat: 47.826565, lng: 18.490121 },
    { lat: 47.825011, lng: 18.492104 },
    { lat: 47.822985, lng: 18.494578 },
    { lat: 47.821464, lng: 18.496559 },
    { lat: 47.820377, lng: 18.498011 },
    { lat: 47.818919, lng: 18.499965 },
    { lat: 47.817176, lng: 18.502348 },
    { lat: 47.816923, lng: 18.503268 },
    { lat: 47.816463, lng: 18.504924 },
    { lat: 47.81544, lng: 18.508706 },
    { lat: 47.814826, lng: 18.512403 },
    { lat: 47.814886, lng: 18.515234 },
    { lat: 47.814927, lng: 18.516545 },
    { lat: 47.815019, lng: 18.519501 },
    { lat: 47.815202, lng: 18.523866 },
    { lat: 47.815292, lng: 18.528437 },
    { lat: 47.815291, lng: 18.528475 },
    { lat: 47.8217106, lng: 18.5343747 },
    { lat: 47.8217909, lng: 18.5344586 },
    { lat: 47.8219196, lng: 18.5345848 },
    { lat: 47.8258821, lng: 18.5386683 },
    { lat: 47.8310313, lng: 18.5440939 },
    { lat: 47.8334499, lng: 18.5465223 },
    { lat: 47.8335227, lng: 18.5465941 },
    { lat: 47.8335752, lng: 18.5466456 },
    { lat: 47.8335595, lng: 18.5466876 },
    { lat: 47.8356547, lng: 18.5484273 },
    { lat: 47.8372896, lng: 18.549471 },
    { lat: 47.8405287, lng: 18.5488112 },
    { lat: 47.8421237, lng: 18.5487732 },
    { lat: 47.8450426, lng: 18.5490274 },
    { lat: 47.8478029, lng: 18.550133 },
    { lat: 47.8489908, lng: 18.5479929 },
    { lat: 47.8497511, lng: 18.5462452 },
    { lat: 47.8501359, lng: 18.5455845 },
    { lat: 47.8505621, lng: 18.545122 },
    { lat: 47.8514115, lng: 18.5446937 },
    { lat: 47.8528161, lng: 18.5446785 },
    { lat: 47.854007, lng: 18.5445128 },
    { lat: 47.8550636, lng: 18.5432253 },
    { lat: 47.8566597, lng: 18.5406768 },
    { lat: 47.8579177, lng: 18.5388528 },
    { lat: 47.8588666, lng: 18.5380436 },
    { lat: 47.8594027, lng: 18.5372119 },
    { lat: 47.86437, lng: 18.5330391 },
    { lat: 47.8645517, lng: 18.5312278 },
    { lat: 47.8649338, lng: 18.5307574 },
    { lat: 47.8670586, lng: 18.5288001 },
    { lat: 47.8670951, lng: 18.5287668 },
    { lat: 47.8675759, lng: 18.5282641 },
    { lat: 47.8703997, lng: 18.5258309 },
    { lat: 47.8718061, lng: 18.523511 },
    { lat: 47.87552, lng: 18.5198057 },
    { lat: 47.877012, lng: 18.5177697 },
    { lat: 47.8761058, lng: 18.5148857 },
    { lat: 47.8744129, lng: 18.5125171 },
    { lat: 47.8729935, lng: 18.5101621 },
    { lat: 47.8721468, lng: 18.5104546 },
    { lat: 47.8720731, lng: 18.5105701 },
    { lat: 47.8718144, lng: 18.5106631 },
    { lat: 47.871546, lng: 18.5109069 },
    { lat: 47.871165, lng: 18.511095 },
    { lat: 47.8700632, lng: 18.5119943 },
    { lat: 47.8689757, lng: 18.5123571 },
    { lat: 47.8681886, lng: 18.5123622 },
    { lat: 47.8658652, lng: 18.5105659 },
    { lat: 47.8633808, lng: 18.5085106 },
    { lat: 47.8656672, lng: 18.5060779 },
    { lat: 47.8663587, lng: 18.5068099 },
    { lat: 47.8673994, lng: 18.5067784 },
    { lat: 47.8672433, lng: 18.5044432 },
    { lat: 47.8670161, lng: 18.5041933 },
    { lat: 47.8674207, lng: 18.5017793 },
    { lat: 47.86854, lng: 18.4986103 },
    { lat: 47.8683234, lng: 18.4972535 },
    { lat: 47.8683903, lng: 18.495767 },
    { lat: 47.8687265, lng: 18.4949171 },
    { lat: 47.8686571, lng: 18.4948463 },
    { lat: 47.8627002, lng: 18.4890363 },
    { lat: 47.8589934, lng: 18.4861103 },
    { lat: 47.8604894, lng: 18.4830094 },
    { lat: 47.858463, lng: 18.4811069 },
    { lat: 47.8587836, lng: 18.4799644 },
    { lat: 47.8576974, lng: 18.4788745 },
    { lat: 47.857689, lng: 18.4772557 },
    { lat: 47.8575635, lng: 18.4764458 },
    { lat: 47.8573214, lng: 18.4754652 },
    { lat: 47.8571531, lng: 18.4754231 },
    { lat: 47.856901, lng: 18.4751891 },
    { lat: 47.8559833, lng: 18.4747379 },
    { lat: 47.8558495, lng: 18.4746062 },
    { lat: 47.8557259, lng: 18.4743121 },
    { lat: 47.8553418, lng: 18.4740203 },
    { lat: 47.8551381, lng: 18.4736641 },
    { lat: 47.8539534, lng: 18.4729772 },
    { lat: 47.8533219, lng: 18.4727185 },
    { lat: 47.8524158, lng: 18.472112 },
    { lat: 47.8521885, lng: 18.4718813 },
    { lat: 47.8518829, lng: 18.4713007 },
    { lat: 47.8514166, lng: 18.4709317 },
    { lat: 47.8502492, lng: 18.4688798 },
    { lat: 47.8499114, lng: 18.4687327 },
    { lat: 47.8495002, lng: 18.4687822 },
    { lat: 47.8491913, lng: 18.4686859 },
    { lat: 47.8488429, lng: 18.4683659 },
    { lat: 47.8482421, lng: 18.4673478 },
    { lat: 47.8481283, lng: 18.4672495 },
    { lat: 47.8478195, lng: 18.4666549 },
    { lat: 47.8470805, lng: 18.4658485 },
    { lat: 47.846581, lng: 18.4651069 },
    { lat: 47.8460838, lng: 18.4645079 },
    { lat: 47.8453071, lng: 18.4632553 },
    { lat: 47.8449449, lng: 18.4627692 },
    { lat: 47.8444506, lng: 18.4619027 },
    { lat: 47.8444026, lng: 18.4617299 },
    { lat: 47.843378, lng: 18.461641 },
  ],
  Obid: [
    { lat: 47.7585519, lng: 18.6069758 },
    { lat: 47.7584071, lng: 18.6077694 },
    { lat: 47.7582597, lng: 18.6088998 },
    { lat: 47.7574705, lng: 18.6153968 },
    { lat: 47.7573469, lng: 18.6168563 },
    { lat: 47.7572948, lng: 18.6183251 },
    { lat: 47.7573147, lng: 18.6197958 },
    { lat: 47.7574047, lng: 18.6208965 },
    { lat: 47.757739, lng: 18.6236671 },
    { lat: 47.7580157, lng: 18.6260251 },
    { lat: 47.7582302, lng: 18.6283974 },
    { lat: 47.7583825, lng: 18.6307803 },
    { lat: 47.7584573, lng: 18.6320861 },
    { lat: 47.7585834, lng: 18.6333832 },
    { lat: 47.7587608, lng: 18.6346671 },
    { lat: 47.7592673, lng: 18.6387111 },
    { lat: 47.7593755, lng: 18.6399056 },
    { lat: 47.7594243, lng: 18.6411085 },
    { lat: 47.7594134, lng: 18.6423136 },
    { lat: 47.7593428, lng: 18.6444668 },
    { lat: 47.7593444, lng: 18.6455517 },
    { lat: 47.7593869, lng: 18.6466347 },
    { lat: 47.7594703, lng: 18.6477124 },
    { lat: 47.7595877, lng: 18.6486118 },
    { lat: 47.7597551, lng: 18.6500846 },
    { lat: 47.7598606, lng: 18.6515702 },
    { lat: 47.7599039, lng: 18.6530623 },
    { lat: 47.7599452, lng: 18.6540635 },
    { lat: 47.7600215, lng: 18.6550601 },
    { lat: 47.7600971, lng: 18.655735 },
    { lat: 47.7601324, lng: 18.6560496 },
    { lat: 47.7604708, lng: 18.6587646 },
    { lat: 47.7607724, lng: 18.6608335 },
    { lat: 47.7611332, lng: 18.6628817 },
    { lat: 47.7615526, lng: 18.6649051 },
    { lat: 47.7620297, lng: 18.6669004 },
    { lat: 47.7625637, lng: 18.6688636 },
    { lat: 47.763249, lng: 18.6708792 },
    { lat: 47.7648251, lng: 18.6751258 },
    { lat: 47.7665414, lng: 18.679251 },
    { lat: 47.7678165, lng: 18.6822582 },
    { lat: 47.7690916, lng: 18.6852654 },
    { lat: 47.7694213, lng: 18.6859461 },
    { lat: 47.7697858, lng: 18.6865862 },
    { lat: 47.7714875, lng: 18.6891562 },
    { lat: 47.7718794, lng: 18.6898626 },
    { lat: 47.7722291, lng: 18.6906165 },
    { lat: 47.7728217, lng: 18.692066 },
    { lat: 47.7734142, lng: 18.6935157 },
    { lat: 47.7738553, lng: 18.6947091 },
    { lat: 47.7741903, lng: 18.6959762 },
    { lat: 47.7743066, lng: 18.6965205 },
    { lat: 47.7765999, lng: 18.6945868 },
    { lat: 47.7764473, lng: 18.6941828 },
    { lat: 47.776414, lng: 18.6928394 },
    { lat: 47.7766465, lng: 18.691722 },
    { lat: 47.7769522, lng: 18.6911396 },
    { lat: 47.7771499, lng: 18.690893 },
    { lat: 47.7784813, lng: 18.689613 },
    { lat: 47.7792257, lng: 18.6890319 },
    { lat: 47.7798564, lng: 18.6886821 },
    { lat: 47.7802255, lng: 18.6883144 },
    { lat: 47.7805492, lng: 18.6878554 },
    { lat: 47.7818288, lng: 18.6857036 },
    { lat: 47.7824127, lng: 18.6842466 },
    { lat: 47.7827811, lng: 18.6835484 },
    { lat: 47.7830797, lng: 18.6826698 },
    { lat: 47.7854276, lng: 18.6771387 },
    { lat: 47.7870429, lng: 18.6734902 },
    { lat: 47.7873557, lng: 18.6729969 },
    { lat: 47.7875678, lng: 18.6725416 },
    { lat: 47.7884685, lng: 18.6704604 },
    { lat: 47.7886656, lng: 18.6705164 },
    { lat: 47.7917838, lng: 18.6706142 },
    { lat: 47.7920236, lng: 18.6707079 },
    { lat: 47.7922871, lng: 18.6694552 },
    { lat: 47.7942002, lng: 18.6703117 },
    { lat: 47.7942336, lng: 18.6701597 },
    { lat: 47.7942728, lng: 18.6701764 },
    { lat: 47.7944623, lng: 18.6692849 },
    { lat: 47.7973846, lng: 18.6706323 },
    { lat: 47.7975358, lng: 18.6704832 },
    { lat: 47.7977771, lng: 18.6728145 },
    { lat: 47.7983988, lng: 18.6762842 },
    { lat: 47.7986122, lng: 18.6769939 },
    { lat: 47.7992178, lng: 18.6784887 },
    { lat: 47.7992582, lng: 18.6781314 },
    { lat: 47.7995081, lng: 18.6759 },
    { lat: 47.7994213, lng: 18.675198 },
    { lat: 47.8000645, lng: 18.6641637 },
    { lat: 47.8003522, lng: 18.6605179 },
    { lat: 47.8009217, lng: 18.6584845 },
    { lat: 47.8020725, lng: 18.6566471 },
    { lat: 47.8021192, lng: 18.6565564 },
    { lat: 47.8022671, lng: 18.6562471 },
    { lat: 47.8022625, lng: 18.6562278 },
    { lat: 47.8046367, lng: 18.6572268 },
    { lat: 47.8067192, lng: 18.6577848 },
    { lat: 47.8071736, lng: 18.6579903 },
    { lat: 47.8096387, lng: 18.6591597 },
    { lat: 47.8123476, lng: 18.6422946 },
    { lat: 47.8125952, lng: 18.6408802 },
    { lat: 47.8129079, lng: 18.6398107 },
    { lat: 47.8129332, lng: 18.6396947 },
    { lat: 47.8130432, lng: 18.6389832 },
    { lat: 47.8132859, lng: 18.6380553 },
    { lat: 47.8133763, lng: 18.6371715 },
    { lat: 47.8134927, lng: 18.6367016 },
    { lat: 47.8137478, lng: 18.6336457 },
    { lat: 47.8137873, lng: 18.6331731 },
    { lat: 47.8138793, lng: 18.6301531 },
    { lat: 47.8141327, lng: 18.6287232 },
    { lat: 47.8142428, lng: 18.6277671 },
    { lat: 47.8145386, lng: 18.6268451 },
    { lat: 47.8154492, lng: 18.6249786 },
    { lat: 47.8154274, lng: 18.6249511 },
    { lat: 47.8143533, lng: 18.6246936 },
    { lat: 47.8130087, lng: 18.624486 },
    { lat: 47.8109834, lng: 18.6240637 },
    { lat: 47.809751, lng: 18.6238858 },
    { lat: 47.8087969, lng: 18.623843 },
    { lat: 47.8087971, lng: 18.6237872 },
    { lat: 47.8087281, lng: 18.6237822 },
    { lat: 47.8086315, lng: 18.6245131 },
    { lat: 47.8070412, lng: 18.6247618 },
    { lat: 47.805881, lng: 18.6247887 },
    { lat: 47.801917, lng: 18.6258913 },
    { lat: 47.8012091, lng: 18.626003 },
    { lat: 47.7990541, lng: 18.6259272 },
    { lat: 47.7985285, lng: 18.6257639 },
    { lat: 47.7972026, lng: 18.62559 },
    { lat: 47.7969109, lng: 18.6255517 },
    { lat: 47.7959097, lng: 18.6254999 },
    { lat: 47.7949824, lng: 18.6255977 },
    { lat: 47.7921289, lng: 18.6253725 },
    { lat: 47.790839, lng: 18.6255252 },
    { lat: 47.7907499, lng: 18.6248478 },
    { lat: 47.7891512, lng: 18.6249592 },
    { lat: 47.7872468, lng: 18.6248894 },
    { lat: 47.7853756, lng: 18.6245009 },
    { lat: 47.7835833, lng: 18.6239431 },
    { lat: 47.7835896, lng: 18.6238943 },
    { lat: 47.7823845, lng: 18.6229364 },
    { lat: 47.7818904, lng: 18.6224572 },
    { lat: 47.7801138, lng: 18.6209334 },
    { lat: 47.7797732, lng: 18.6206153 },
    { lat: 47.7759697, lng: 18.6171461 },
    { lat: 47.7729912, lng: 18.6107787 },
    { lat: 47.7714353, lng: 18.6100905 },
    { lat: 47.7674908, lng: 18.6080773 },
    { lat: 47.7619438, lng: 18.6052464 },
    { lat: 47.7590662, lng: 18.6045502 },
    { lat: 47.7586098, lng: 18.6066582 },
    { lat: 47.7585519, lng: 18.6069758 },
  ],
  Chľaba: [
    { lat: 47.8572279, lng: 18.8006945 },
    { lat: 47.857219, lng: 18.800247 },
    { lat: 47.857532, lng: 18.79944 },
    { lat: 47.854837, lng: 18.7984794 },
    { lat: 47.8535614, lng: 18.7977766 },
    { lat: 47.8524277, lng: 18.7968992 },
    { lat: 47.851011, lng: 18.7959445 },
    { lat: 47.8499995, lng: 18.7955215 },
    { lat: 47.8481496, lng: 18.7906784 },
    { lat: 47.8477962, lng: 18.7896123 },
    { lat: 47.8472353, lng: 18.7894991 },
    { lat: 47.8464353, lng: 18.79023 },
    { lat: 47.8454863, lng: 18.7900925 },
    { lat: 47.8448514, lng: 18.7900938 },
    { lat: 47.8448803, lng: 18.7898741 },
    { lat: 47.8447856, lng: 18.7896216 },
    { lat: 47.8448452, lng: 18.7893433 },
    { lat: 47.8447901, lng: 18.7891728 },
    { lat: 47.8447765, lng: 18.7887103 },
    { lat: 47.8448562, lng: 18.7882976 },
    { lat: 47.8447806, lng: 18.7880846 },
    { lat: 47.8447543, lng: 18.7876588 },
    { lat: 47.8442882, lng: 18.7874176 },
    { lat: 47.8437237, lng: 18.7872976 },
    { lat: 47.8430665, lng: 18.7865225 },
    { lat: 47.842552, lng: 18.7860912 },
    { lat: 47.8419315, lng: 18.7853684 },
    { lat: 47.841591, lng: 18.7850718 },
    { lat: 47.8411256, lng: 18.7848495 },
    { lat: 47.840327, lng: 18.7841866 },
    { lat: 47.8385067, lng: 18.7832125 },
    { lat: 47.837983, lng: 18.783629 },
    { lat: 47.8373228, lng: 18.7838519 },
    { lat: 47.8353963, lng: 18.7831459 },
    { lat: 47.8349591, lng: 18.7829197 },
    { lat: 47.8342702, lng: 18.7830926 },
    { lat: 47.833846, lng: 18.7834061 },
    { lat: 47.8334927, lng: 18.7834245 },
    { lat: 47.8333541, lng: 18.7831919 },
    { lat: 47.833317, lng: 18.7831286 },
    { lat: 47.8329823, lng: 18.7825653 },
    { lat: 47.8323684, lng: 18.7818384 },
    { lat: 47.8316035, lng: 18.7812201 },
    { lat: 47.8307198, lng: 18.78038 },
    { lat: 47.8300896, lng: 18.7795617 },
    { lat: 47.8291912, lng: 18.7780049 },
    { lat: 47.8285797, lng: 18.7776607 },
    { lat: 47.8276867, lng: 18.7769888 },
    { lat: 47.8267646, lng: 18.7767236 },
    { lat: 47.826615, lng: 18.7765595 },
    { lat: 47.8261941, lng: 18.7770196 },
    { lat: 47.8259755, lng: 18.7776569 },
    { lat: 47.8259014, lng: 18.7780208 },
    { lat: 47.8257735, lng: 18.7782868 },
    { lat: 47.8255604, lng: 18.7785213 },
    { lat: 47.8255595, lng: 18.7789474 },
    { lat: 47.8255171, lng: 18.779083 },
    { lat: 47.8253846, lng: 18.7792217 },
    { lat: 47.8248276, lng: 18.7794792 },
    { lat: 47.8244278, lng: 18.7802059 },
    { lat: 47.8239138, lng: 18.7807608 },
    { lat: 47.8235572, lng: 18.7808403 },
    { lat: 47.8230462, lng: 18.7810695 },
    { lat: 47.8209442, lng: 18.7813326 },
    { lat: 47.8210525, lng: 18.7821464 },
    { lat: 47.8211778, lng: 18.7829546 },
    { lat: 47.8213199, lng: 18.7837568 },
    { lat: 47.8214787, lng: 18.784552 },
    { lat: 47.8216541, lng: 18.7853396 },
    { lat: 47.8220032, lng: 18.786804 },
    { lat: 47.8222901, lng: 18.7882978 },
    { lat: 47.8225139, lng: 18.7898153 },
    { lat: 47.8226738, lng: 18.7913506 },
    { lat: 47.8227397, lng: 18.7923085 },
    { lat: 47.8227749, lng: 18.7932699 },
    { lat: 47.8227792, lng: 18.7942329 },
    { lat: 47.8227528, lng: 18.795195 },
    { lat: 47.8225936, lng: 18.7978035 },
    { lat: 47.8225214, lng: 18.7987799 },
    { lat: 47.8224596, lng: 18.7997579 },
    { lat: 47.822285, lng: 18.8019303 },
    { lat: 47.8221303, lng: 18.803282 },
    { lat: 47.821875, lng: 18.8045996 },
    { lat: 47.8213183, lng: 18.8067042 },
    { lat: 47.8210004, lng: 18.8078997 },
    { lat: 47.8206526, lng: 18.8090768 },
    { lat: 47.8202755, lng: 18.8102336 },
    { lat: 47.8198692, lng: 18.8113685 },
    { lat: 47.8196319, lng: 18.8119897 },
    { lat: 47.8194224, lng: 18.8126324 },
    { lat: 47.8192415, lng: 18.813294 },
    { lat: 47.8190831, lng: 18.8140892 },
    { lat: 47.8189457, lng: 18.8151568 },
    { lat: 47.8188497, lng: 18.8162345 },
    { lat: 47.818795, lng: 18.8173184 },
    { lat: 47.818782, lng: 18.8184051 },
    { lat: 47.8188082, lng: 18.8206193 },
    { lat: 47.8187963, lng: 18.8214134 },
    { lat: 47.818775, lng: 18.8228331 },
    { lat: 47.8187243, lng: 18.8240846 },
    { lat: 47.8186827, lng: 18.825337 },
    { lat: 47.8186446, lng: 18.8264531 },
    { lat: 47.818572, lng: 18.8275656 },
    { lat: 47.8184651, lng: 18.828672 },
    { lat: 47.8183245, lng: 18.8297701 },
    { lat: 47.8181931, lng: 18.8308212 },
    { lat: 47.8180742, lng: 18.8318758 },
    { lat: 47.8179674, lng: 18.8329333 },
    { lat: 47.8178733, lng: 18.8339934 },
    { lat: 47.8177214, lng: 18.8353368 },
    { lat: 47.8175259, lng: 18.8366676 },
    { lat: 47.8172873, lng: 18.837983 },
    { lat: 47.8171078, lng: 18.8390953 },
    { lat: 47.8169535, lng: 18.8402158 },
    { lat: 47.8168249, lng: 18.8413434 },
    { lat: 47.8167209, lng: 18.8424734 },
    { lat: 47.8166452, lng: 18.8434295 },
    { lat: 47.8165808, lng: 18.8443874 },
    { lat: 47.8165151, lng: 18.8451413 },
    { lat: 47.8164153, lng: 18.8458868 },
    { lat: 47.8162817, lng: 18.8466205 },
    { lat: 47.8161148, lng: 18.8473391 },
    { lat: 47.8159239, lng: 18.8480126 },
    { lat: 47.8183742, lng: 18.8485063 },
    { lat: 47.818875, lng: 18.848342 },
    { lat: 47.81912, lng: 18.848337 },
    { lat: 47.820568, lng: 18.848994 },
    { lat: 47.821517, lng: 18.849971 },
    { lat: 47.822964, lng: 18.852109 },
    { lat: 47.8235593, lng: 18.8528099 },
    { lat: 47.8236022, lng: 18.8528524 },
    { lat: 47.823638, lng: 18.8528912 },
    { lat: 47.825138, lng: 18.854849 },
    { lat: 47.826077, lng: 18.855876 },
    { lat: 47.82641, lng: 18.85605 },
    { lat: 47.826796, lng: 18.856137 },
    { lat: 47.827046, lng: 18.856084 },
    { lat: 47.827274, lng: 18.855938 },
    { lat: 47.827475, lng: 18.855626 },
    { lat: 47.827624, lng: 18.854014 },
    { lat: 47.827683, lng: 18.853644 },
    { lat: 47.827887, lng: 18.852922 },
    { lat: 47.82797, lng: 18.8527165 },
    { lat: 47.82824, lng: 18.85241 },
    { lat: 47.828819, lng: 18.852021 },
    { lat: 47.829465, lng: 18.85204 },
    { lat: 47.829821, lng: 18.851584 },
    { lat: 47.82994, lng: 18.851308 },
    { lat: 47.830106, lng: 18.8504 },
    { lat: 47.830058, lng: 18.849527 },
    { lat: 47.829921, lng: 18.848651 },
    { lat: 47.829618, lng: 18.847135 },
    { lat: 47.82933, lng: 18.846061 },
    { lat: 47.82912, lng: 18.845031 },
    { lat: 47.829005, lng: 18.844179 },
    { lat: 47.828788, lng: 18.841974 },
    { lat: 47.828797, lng: 18.840798 },
    { lat: 47.828879, lng: 18.840254 },
    { lat: 47.82918, lng: 18.839194 },
    { lat: 47.829678, lng: 18.838078 },
    { lat: 47.830059, lng: 18.837394 },
    { lat: 47.830717, lng: 18.836425 },
    { lat: 47.831309, lng: 18.835255 },
    { lat: 47.831683, lng: 18.834263 },
    { lat: 47.832201, lng: 18.832335 },
    { lat: 47.832408, lng: 18.831732 },
    { lat: 47.832744, lng: 18.830867 },
    { lat: 47.8332138, lng: 18.8299052 },
    { lat: 47.8332358, lng: 18.82986 },
    { lat: 47.83344, lng: 18.829442 },
    { lat: 47.834291, lng: 18.828014 },
    { lat: 47.83471, lng: 18.827765 },
    { lat: 47.835088, lng: 18.827835 },
    { lat: 47.835739, lng: 18.828353 },
    { lat: 47.836308, lng: 18.828552 },
    { lat: 47.836588, lng: 18.828517 },
    { lat: 47.837237, lng: 18.828135 },
    { lat: 47.83751, lng: 18.827877 },
    { lat: 47.837994, lng: 18.827265 },
    { lat: 47.838912, lng: 18.825823 },
    { lat: 47.839268, lng: 18.825387 },
    { lat: 47.840778, lng: 18.823825 },
    { lat: 47.840897, lng: 18.823582 },
    { lat: 47.841266, lng: 18.8231 },
    { lat: 47.842149, lng: 18.822501 },
    { lat: 47.844601, lng: 18.821905 },
    { lat: 47.845088, lng: 18.822011 },
    { lat: 47.846059, lng: 18.82208 },
    { lat: 47.846935, lng: 18.822336 },
    { lat: 47.848058, lng: 18.822811 },
    { lat: 47.848356, lng: 18.82272 },
    { lat: 47.848662, lng: 18.822515 },
    { lat: 47.849225, lng: 18.82252 },
    { lat: 47.849976, lng: 18.822265 },
    { lat: 47.850504, lng: 18.821745 },
    { lat: 47.852272, lng: 18.818656 },
    { lat: 47.852607, lng: 18.818266 },
    { lat: 47.852972, lng: 18.817713 },
    { lat: 47.853206, lng: 18.817174 },
    { lat: 47.853756, lng: 18.816242 },
    { lat: 47.85397, lng: 18.815757 },
    { lat: 47.854426, lng: 18.815141 },
    { lat: 47.854789, lng: 18.814531 },
    { lat: 47.855098, lng: 18.814322 },
    { lat: 47.855255, lng: 18.813958 },
    { lat: 47.855408, lng: 18.813828 },
    { lat: 47.85555, lng: 18.813575 },
    { lat: 47.855741, lng: 18.812595 },
    { lat: 47.855722, lng: 18.81233 },
    { lat: 47.855774, lng: 18.811264 },
    { lat: 47.85586, lng: 18.810551 },
    { lat: 47.855876, lng: 18.809085 },
    { lat: 47.855926, lng: 18.808438 },
    { lat: 47.856553, lng: 18.804186 },
    { lat: 47.856643, lng: 18.803083 },
    { lat: 47.857104, lng: 18.801007 },
    { lat: 47.8572279, lng: 18.8006945 },
  ],
  Pavlová: [
    { lat: 47.8988643, lng: 18.6584013 },
    { lat: 47.8986488, lng: 18.6589368 },
    { lat: 47.8980473, lng: 18.6585367 },
    { lat: 47.8979837, lng: 18.6580316 },
    { lat: 47.897603, lng: 18.6574363 },
    { lat: 47.8972579, lng: 18.657211 },
    { lat: 47.8969452, lng: 18.6572877 },
    { lat: 47.8967468, lng: 18.6571646 },
    { lat: 47.8964848, lng: 18.6583232 },
    { lat: 47.8967113, lng: 18.6587117 },
    { lat: 47.8966014, lng: 18.6595951 },
    { lat: 47.8957437, lng: 18.6600025 },
    { lat: 47.8953219, lng: 18.6616621 },
    { lat: 47.8946744, lng: 18.6614555 },
    { lat: 47.8945046, lng: 18.6620234 },
    { lat: 47.8937286, lng: 18.6627696 },
    { lat: 47.893209, lng: 18.6645828 },
    { lat: 47.8933066, lng: 18.6644691 },
    { lat: 47.8934816, lng: 18.6644762 },
    { lat: 47.8936393, lng: 18.6646545 },
    { lat: 47.8937113, lng: 18.6648405 },
    { lat: 47.8938695, lng: 18.6649073 },
    { lat: 47.8934116, lng: 18.669024 },
    { lat: 47.8934061, lng: 18.6702953 },
    { lat: 47.8933367, lng: 18.6705922 },
    { lat: 47.8929281, lng: 18.6712952 },
    { lat: 47.8926824, lng: 18.6722548 },
    { lat: 47.8911529, lng: 18.6803472 },
    { lat: 47.8908068, lng: 18.6819327 },
    { lat: 47.8889528, lng: 18.6885203 },
    { lat: 47.8875327, lng: 18.6937955 },
    { lat: 47.8906358, lng: 18.7097678 },
    { lat: 47.8906531, lng: 18.7098336 },
    { lat: 47.8912909, lng: 18.7088865 },
    { lat: 47.8940494, lng: 18.7069141 },
    { lat: 47.8949123, lng: 18.7061643 },
    { lat: 47.896028, lng: 18.7047275 },
    { lat: 47.8976936, lng: 18.7047857 },
    { lat: 47.8988114, lng: 18.7032378 },
    { lat: 47.9002247, lng: 18.7024678 },
    { lat: 47.9016917, lng: 18.7021487 },
    { lat: 47.9029641, lng: 18.7033669 },
    { lat: 47.9042209, lng: 18.7043557 },
    { lat: 47.905362, lng: 18.7056048 },
    { lat: 47.9062559, lng: 18.7072732 },
    { lat: 47.9072698, lng: 18.7090268 },
    { lat: 47.9079171, lng: 18.7100087 },
    { lat: 47.909217, lng: 18.7110043 },
    { lat: 47.9098775, lng: 18.7116662 },
    { lat: 47.910633, lng: 18.7066447 },
    { lat: 47.9101825, lng: 18.7036022 },
    { lat: 47.9101057, lng: 18.7026373 },
    { lat: 47.9099204, lng: 18.7025062 },
    { lat: 47.9090833, lng: 18.7021874 },
    { lat: 47.9091337, lng: 18.7017927 },
    { lat: 47.9094498, lng: 18.6993178 },
    { lat: 47.9095852, lng: 18.6982573 },
    { lat: 47.9095879, lng: 18.697659 },
    { lat: 47.9095144, lng: 18.6973633 },
    { lat: 47.9094366, lng: 18.69705 },
    { lat: 47.9093273, lng: 18.6963552 },
    { lat: 47.9091822, lng: 18.6954332 },
    { lat: 47.9091718, lng: 18.6949887 },
    { lat: 47.9092201, lng: 18.6938391 },
    { lat: 47.9092574, lng: 18.6936712 },
    { lat: 47.9094982, lng: 18.6933295 },
    { lat: 47.909558, lng: 18.6931417 },
    { lat: 47.9096363, lng: 18.6923555 },
    { lat: 47.909526, lng: 18.6908582 },
    { lat: 47.9095837, lng: 18.6896079 },
    { lat: 47.9093553, lng: 18.6894532 },
    { lat: 47.9093443, lng: 18.689207 },
    { lat: 47.9094321, lng: 18.6885574 },
    { lat: 47.9095438, lng: 18.6880397 },
    { lat: 47.9098343, lng: 18.6871314 },
    { lat: 47.9105585, lng: 18.6858023 },
    { lat: 47.9106859, lng: 18.6855685 },
    { lat: 47.9107999, lng: 18.6851566 },
    { lat: 47.9108745, lng: 18.6848868 },
    { lat: 47.9110218, lng: 18.6846702 },
    { lat: 47.9112235, lng: 18.6837718 },
    { lat: 47.9115428, lng: 18.6827496 },
    { lat: 47.9126477, lng: 18.6802447 },
    { lat: 47.9128838, lng: 18.6799162 },
    { lat: 47.9131058, lng: 18.679808 },
    { lat: 47.9134149, lng: 18.6790405 },
    { lat: 47.9158426, lng: 18.6720121 },
    { lat: 47.9152939, lng: 18.6714433 },
    { lat: 47.9152405, lng: 18.6714564 },
    { lat: 47.9151824, lng: 18.6716107 },
    { lat: 47.9151822, lng: 18.6721296 },
    { lat: 47.915127, lng: 18.6722315 },
    { lat: 47.9150139, lng: 18.6722897 },
    { lat: 47.9149192, lng: 18.6721879 },
    { lat: 47.9146506, lng: 18.671331 },
    { lat: 47.9145632, lng: 18.6712191 },
    { lat: 47.9143305, lng: 18.6712731 },
    { lat: 47.9140935, lng: 18.6715008 },
    { lat: 47.9140125, lng: 18.6714613 },
    { lat: 47.9139032, lng: 18.6712343 },
    { lat: 47.9138585, lng: 18.6703481 },
    { lat: 47.9133829, lng: 18.6696058 },
    { lat: 47.9132402, lng: 18.6696549 },
    { lat: 47.913135, lng: 18.6699206 },
    { lat: 47.9130385, lng: 18.6704722 },
    { lat: 47.9128812, lng: 18.6705867 },
    { lat: 47.9124456, lng: 18.6703742 },
    { lat: 47.9121213, lng: 18.6699889 },
    { lat: 47.912122, lng: 18.669877 },
    { lat: 47.9122441, lng: 18.6695913 },
    { lat: 47.9123829, lng: 18.6694045 },
    { lat: 47.9125036, lng: 18.6694044 },
    { lat: 47.9127008, lng: 18.66907 },
    { lat: 47.912698, lng: 18.6689356 },
    { lat: 47.912564, lng: 18.6687911 },
    { lat: 47.9120972, lng: 18.668774 },
    { lat: 47.9119249, lng: 18.6685085 },
    { lat: 47.9118318, lng: 18.6681435 },
    { lat: 47.9116383, lng: 18.6681959 },
    { lat: 47.9115799, lng: 18.6685963 },
    { lat: 47.9114937, lng: 18.6686291 },
    { lat: 47.9113776, lng: 18.6685665 },
    { lat: 47.9110095, lng: 18.6678112 },
    { lat: 47.9112726, lng: 18.6674408 },
    { lat: 47.9112747, lng: 18.6671487 },
    { lat: 47.9114842, lng: 18.6669763 },
    { lat: 47.9115296, lng: 18.6668773 },
    { lat: 47.9115123, lng: 18.6667673 },
    { lat: 47.9112777, lng: 18.6664544 },
    { lat: 47.9112778, lng: 18.6662906 },
    { lat: 47.9118345, lng: 18.6663028 },
    { lat: 47.9118869, lng: 18.6662312 },
    { lat: 47.9118723, lng: 18.6661064 },
    { lat: 47.9117333, lng: 18.6660401 },
    { lat: 47.9116574, lng: 18.6658035 },
    { lat: 47.911437, lng: 18.6658584 },
    { lat: 47.9113699, lng: 18.6657234 },
    { lat: 47.9113391, lng: 18.6655098 },
    { lat: 47.9113739, lng: 18.6654016 },
    { lat: 47.9115666, lng: 18.6654586 },
    { lat: 47.9116278, lng: 18.6653634 },
    { lat: 47.911621, lng: 18.6652387 },
    { lat: 47.9115532, lng: 18.6651301 },
    { lat: 47.9111372, lng: 18.6650722 },
    { lat: 47.9109414, lng: 18.6649143 },
    { lat: 47.910426, lng: 18.6647276 },
    { lat: 47.9100526, lng: 18.6649982 },
    { lat: 47.9091869, lng: 18.6652554 },
    { lat: 47.9085608, lng: 18.6647551 },
    { lat: 47.9079579, lng: 18.6638942 },
    { lat: 47.9078571, lng: 18.6638434 },
    { lat: 47.9074239, lng: 18.6638389 },
    { lat: 47.9070599, lng: 18.6640137 },
    { lat: 47.9060983, lng: 18.6636676 },
    { lat: 47.9059544, lng: 18.6637591 },
    { lat: 47.9048843, lng: 18.6636377 },
    { lat: 47.9039021, lng: 18.6632263 },
    { lat: 47.9034778, lng: 18.6627458 },
    { lat: 47.9030397, lng: 18.6628002 },
    { lat: 47.9020657, lng: 18.6621858 },
    { lat: 47.9014835, lng: 18.6620142 },
    { lat: 47.9014427, lng: 18.6618859 },
    { lat: 47.90154, lng: 18.6612708 },
    { lat: 47.9015933, lng: 18.661212 },
    { lat: 47.901927, lng: 18.6612731 },
    { lat: 47.9019743, lng: 18.6611687 },
    { lat: 47.9019907, lng: 18.661031 },
    { lat: 47.9019286, lng: 18.6606807 },
    { lat: 47.9018601, lng: 18.6605817 },
    { lat: 47.9017561, lng: 18.6606202 },
    { lat: 47.9017143, lng: 18.6605549 },
    { lat: 47.9018009, lng: 18.6601361 },
    { lat: 47.9017671, lng: 18.6599261 },
    { lat: 47.9015497, lng: 18.6599751 },
    { lat: 47.9014494, lng: 18.659874 },
    { lat: 47.901374, lng: 18.6595428 },
    { lat: 47.9013171, lng: 18.6594705 },
    { lat: 47.9007646, lng: 18.6591851 },
    { lat: 47.9005888, lng: 18.6588548 },
    { lat: 47.9004566, lng: 18.6587491 },
    { lat: 47.9003839, lng: 18.6591121 },
    { lat: 47.9000786, lng: 18.6590117 },
    { lat: 47.8999816, lng: 18.6586803 },
    { lat: 47.8998531, lng: 18.65883 },
    { lat: 47.8997965, lng: 18.6591577 },
    { lat: 47.8996062, lng: 18.659183 },
    { lat: 47.8994171, lng: 18.6589397 },
    { lat: 47.8991496, lng: 18.6587698 },
    { lat: 47.8990101, lng: 18.6587574 },
    { lat: 47.8988968, lng: 18.6585418 },
    { lat: 47.8988643, lng: 18.6584013 },
  ],
  Palárikovo: [
    { lat: 48.013439, lng: 18.012008 },
    { lat: 48.0133837, lng: 18.0122123 },
    { lat: 48.0129259, lng: 18.0129949 },
    { lat: 48.0118071, lng: 18.0137974 },
    { lat: 48.0109128, lng: 18.014957 },
    { lat: 48.0103427, lng: 18.0170856 },
    { lat: 48.0095873, lng: 18.0179613 },
    { lat: 48.0082214, lng: 18.0183355 },
    { lat: 48.0073078, lng: 18.0190632 },
    { lat: 48.0064836, lng: 18.0202288 },
    { lat: 48.0056694, lng: 18.0217465 },
    { lat: 48.0053144, lng: 18.0236376 },
    { lat: 48.0045126, lng: 18.0254225 },
    { lat: 48.0036251, lng: 18.0272264 },
    { lat: 48.0032174, lng: 18.0272979 },
    { lat: 48.0020817, lng: 18.0291202 },
    { lat: 48.0012421, lng: 18.0300296 },
    { lat: 48.00011, lng: 18.0309722 },
    { lat: 47.999532, lng: 18.0313844 },
    { lat: 47.9992615, lng: 18.0316543 },
    { lat: 47.9998196, lng: 18.0341568 },
    { lat: 47.9999199, lng: 18.0349646 },
    { lat: 47.9992288, lng: 18.0350898 },
    { lat: 47.9975382, lng: 18.035773 },
    { lat: 47.9970336, lng: 18.0361119 },
    { lat: 47.9947153, lng: 18.0382074 },
    { lat: 47.994036, lng: 18.0385476 },
    { lat: 47.9927171, lng: 18.0389319 },
    { lat: 47.993253, lng: 18.0411017 },
    { lat: 47.9937776, lng: 18.0428002 },
    { lat: 47.9938694, lng: 18.0439987 },
    { lat: 47.9953329, lng: 18.0477549 },
    { lat: 47.9962473, lng: 18.0505189 },
    { lat: 47.998774, lng: 18.0577319 },
    { lat: 47.9993983, lng: 18.0593269 },
    { lat: 48.0008557, lng: 18.0626841 },
    { lat: 48.0013823, lng: 18.0635882 },
    { lat: 48.0044683, lng: 18.0722868 },
    { lat: 48.0075899, lng: 18.076101 },
    { lat: 48.0086032, lng: 18.0776351 },
    { lat: 48.0100149, lng: 18.0793252 },
    { lat: 48.0121508, lng: 18.0831962 },
    { lat: 48.0139834, lng: 18.0863848 },
    { lat: 48.0166711, lng: 18.0904494 },
    { lat: 48.0201653, lng: 18.0973482 },
    { lat: 48.0209701, lng: 18.0997543 },
    { lat: 48.0216623, lng: 18.1015531 },
    { lat: 48.0219774, lng: 18.1020944 },
    { lat: 48.0231478, lng: 18.104478 },
    { lat: 48.0245939, lng: 18.1075414 },
    { lat: 48.0262862, lng: 18.1107032 },
    { lat: 48.0269636, lng: 18.1121771 },
    { lat: 48.0281875, lng: 18.114882 },
    { lat: 48.0313448, lng: 18.1213382 },
    { lat: 48.0347032, lng: 18.127618 },
    { lat: 48.0443271, lng: 18.1185979 },
    { lat: 48.0443555, lng: 18.1186217 },
    { lat: 48.044437, lng: 18.1185479 },
    { lat: 48.0517894, lng: 18.1118346 },
    { lat: 48.0526648, lng: 18.1106625 },
    { lat: 48.0535776, lng: 18.1086163 },
    { lat: 48.054228, lng: 18.1078564 },
    { lat: 48.0560837, lng: 18.1052876 },
    { lat: 48.056426, lng: 18.1049078 },
    { lat: 48.0596748, lng: 18.1016185 },
    { lat: 48.0613774, lng: 18.1001047 },
    { lat: 48.0613983, lng: 18.1001538 },
    { lat: 48.0625763, lng: 18.0990628 },
    { lat: 48.0658971, lng: 18.0967046 },
    { lat: 48.0662648, lng: 18.0963394 },
    { lat: 48.0684648, lng: 18.0937329 },
    { lat: 48.0666683, lng: 18.0905549 },
    { lat: 48.0654016, lng: 18.0882225 },
    { lat: 48.077755, lng: 18.0735632 },
    { lat: 48.07507, lng: 18.0679376 },
    { lat: 48.0732155, lng: 18.0649907 },
    { lat: 48.0708789, lng: 18.0569937 },
    { lat: 48.0694281, lng: 18.0517865 },
    { lat: 48.0686997, lng: 18.0502889 },
    { lat: 48.0636487, lng: 18.0399045 },
    { lat: 48.0624029, lng: 18.0368985 },
    { lat: 48.0607744, lng: 18.0300816 },
    { lat: 48.055447, lng: 18.0101634 },
    { lat: 48.053852, lng: 18.0077678 },
    { lat: 48.0519129, lng: 18.0051716 },
    { lat: 48.0501631, lng: 18.0023429 },
    { lat: 48.0488471, lng: 18.000539 },
    { lat: 48.047221, lng: 17.9981 },
    { lat: 48.046739, lng: 17.998679 },
    { lat: 48.046711, lng: 17.998711 },
    { lat: 48.046388, lng: 17.999105 },
    { lat: 48.046217, lng: 17.999306 },
    { lat: 48.046191, lng: 17.999335 },
    { lat: 48.045088, lng: 18.000684 },
    { lat: 48.044204, lng: 18.001654 },
    { lat: 48.04338, lng: 18.002444 },
    { lat: 48.042719, lng: 18.003707 },
    { lat: 48.043241, lng: 18.005085 },
    { lat: 48.043307, lng: 18.005262 },
    { lat: 48.04339, lng: 18.005483 },
    { lat: 48.043399, lng: 18.005508 },
    { lat: 48.043459, lng: 18.005664 },
    { lat: 48.043503, lng: 18.005774 },
    { lat: 48.043606, lng: 18.006039 },
    { lat: 48.043638, lng: 18.006129 },
    { lat: 48.043717, lng: 18.006341 },
    { lat: 48.043696, lng: 18.006335 },
    { lat: 48.043176, lng: 18.006187 },
    { lat: 48.043162, lng: 18.006183 },
    { lat: 48.043043, lng: 18.006149 },
    { lat: 48.043016, lng: 18.006142 },
    { lat: 48.043001, lng: 18.006138 },
    { lat: 48.041914, lng: 18.005819 },
    { lat: 48.041849, lng: 18.0058 },
    { lat: 48.041733, lng: 18.005767 },
    { lat: 48.041521, lng: 18.005707 },
    { lat: 48.041391, lng: 18.005671 },
    { lat: 48.041158, lng: 18.005605 },
    { lat: 48.040678, lng: 18.00547 },
    { lat: 48.038385, lng: 18.00486 },
    { lat: 48.037907, lng: 18.004726 },
    { lat: 48.035423, lng: 18.004028 },
    { lat: 48.03392, lng: 18.003609 },
    { lat: 48.033031, lng: 18.004537 },
    { lat: 48.032774, lng: 18.004804 },
    { lat: 48.031481, lng: 18.006181 },
    { lat: 48.031267, lng: 18.00641 },
    { lat: 48.02978, lng: 18.007985 },
    { lat: 48.027971, lng: 18.009891 },
    { lat: 48.025652, lng: 18.012301 },
    { lat: 48.024824, lng: 18.013177 },
    { lat: 48.02222, lng: 18.015913 },
    { lat: 48.022211, lng: 18.015923 },
    { lat: 48.02219, lng: 18.015945 },
    { lat: 48.022125, lng: 18.016015 },
    { lat: 48.022111, lng: 18.016029 },
    { lat: 48.022107, lng: 18.016034 },
    { lat: 48.022048, lng: 18.016097 },
    { lat: 48.021435, lng: 18.016745 },
    { lat: 48.019329, lng: 18.018971 },
    { lat: 48.019262, lng: 18.019035 },
    { lat: 48.019142, lng: 18.018822 },
    { lat: 48.017895, lng: 18.017186 },
    { lat: 48.01733, lng: 18.016468 },
    { lat: 48.017033, lng: 18.01612 },
    { lat: 48.017, lng: 18.016081 },
    { lat: 48.016375, lng: 18.015347 },
    { lat: 48.015647, lng: 18.014444 },
    { lat: 48.014999, lng: 18.013654 },
    { lat: 48.014307, lng: 18.012849 },
    { lat: 48.013669, lng: 18.012269 },
    { lat: 48.013439, lng: 18.012008 },
  ],
  ÚľanynadŽitavou: [
    { lat: 48.1271554, lng: 18.2490502 },
    { lat: 48.1272081, lng: 18.2489268 },
    { lat: 48.1261988, lng: 18.2472588 },
    { lat: 48.1254045, lng: 18.2458821 },
    { lat: 48.1215966, lng: 18.239283 },
    { lat: 48.1217414, lng: 18.2384669 },
    { lat: 48.121665, lng: 18.2379884 },
    { lat: 48.1216602, lng: 18.2374921 },
    { lat: 48.1218017, lng: 18.2373637 },
    { lat: 48.1218526, lng: 18.2370375 },
    { lat: 48.1216138, lng: 18.2356204 },
    { lat: 48.1216445, lng: 18.2345322 },
    { lat: 48.1230103, lng: 18.2318082 },
    { lat: 48.1238717, lng: 18.2297503 },
    { lat: 48.1236517, lng: 18.2293658 },
    { lat: 48.1235172, lng: 18.2287744 },
    { lat: 48.1231541, lng: 18.2254097 },
    { lat: 48.1233519, lng: 18.2241922 },
    { lat: 48.1236512, lng: 18.2232038 },
    { lat: 48.1242617, lng: 18.2216447 },
    { lat: 48.1255813, lng: 18.2188307 },
    { lat: 48.1256282, lng: 18.218612 },
    { lat: 48.1244962, lng: 18.2195815 },
    { lat: 48.1244738, lng: 18.2194844 },
    { lat: 48.1215409, lng: 18.2214072 },
    { lat: 48.1209835, lng: 18.2216633 },
    { lat: 48.1176443, lng: 18.224286 },
    { lat: 48.1176391, lng: 18.2246246 },
    { lat: 48.1159218, lng: 18.2255239 },
    { lat: 48.1150457, lng: 18.2262738 },
    { lat: 48.1129198, lng: 18.226663 },
    { lat: 48.1118233, lng: 18.2267673 },
    { lat: 48.1113272, lng: 18.2264052 },
    { lat: 48.1105345, lng: 18.2262559 },
    { lat: 48.1099332, lng: 18.225714 },
    { lat: 48.1095346, lng: 18.2252214 },
    { lat: 48.1086005, lng: 18.2250328 },
    { lat: 48.1078612, lng: 18.2244057 },
    { lat: 48.1078729, lng: 18.2243339 },
    { lat: 48.1078244, lng: 18.2242982 },
    { lat: 48.1038328, lng: 18.221975 },
    { lat: 48.1035771, lng: 18.2219237 },
    { lat: 48.1035588, lng: 18.2216496 },
    { lat: 48.1024989, lng: 18.2223604 },
    { lat: 48.1005069, lng: 18.2228403 },
    { lat: 48.0994451, lng: 18.2228844 },
    { lat: 48.0991007, lng: 18.2229748 },
    { lat: 48.0988042, lng: 18.2232891 },
    { lat: 48.098448, lng: 18.2235494 },
    { lat: 48.0982017, lng: 18.223001 },
    { lat: 48.0979225, lng: 18.2225658 },
    { lat: 48.0969349, lng: 18.2215113 },
    { lat: 48.0958592, lng: 18.2208622 },
    { lat: 48.0930875, lng: 18.2200413 },
    { lat: 48.0922964, lng: 18.2203875 },
    { lat: 48.0896148, lng: 18.2219381 },
    { lat: 48.0893702, lng: 18.222347 },
    { lat: 48.0886211, lng: 18.2240627 },
    { lat: 48.0879384, lng: 18.226399 },
    { lat: 48.0878144, lng: 18.2274689 },
    { lat: 48.0876245, lng: 18.2282642 },
    { lat: 48.0872303, lng: 18.2289772 },
    { lat: 48.0867835, lng: 18.2296761 },
    { lat: 48.0867418, lng: 18.2297154 },
    { lat: 48.0880755, lng: 18.2312933 },
    { lat: 48.0885159, lng: 18.2316907 },
    { lat: 48.0885877, lng: 18.2321099 },
    { lat: 48.0885333, lng: 18.2325545 },
    { lat: 48.0883022, lng: 18.2331804 },
    { lat: 48.0883876, lng: 18.2340916 },
    { lat: 48.0885354, lng: 18.2343824 },
    { lat: 48.0886142, lng: 18.2346694 },
    { lat: 48.0885126, lng: 18.2358994 },
    { lat: 48.0882567, lng: 18.2361551 },
    { lat: 48.0880727, lng: 18.2365059 },
    { lat: 48.0882199, lng: 18.2368067 },
    { lat: 48.0886044, lng: 18.2371503 },
    { lat: 48.0886517, lng: 18.2373665 },
    { lat: 48.0891698, lng: 18.2377596 },
    { lat: 48.0891912, lng: 18.2381662 },
    { lat: 48.0890278, lng: 18.2384428 },
    { lat: 48.0894027, lng: 18.2389563 },
    { lat: 48.0896178, lng: 18.239044 },
    { lat: 48.0900418, lng: 18.2389999 },
    { lat: 48.0905023, lng: 18.2393743 },
    { lat: 48.0907251, lng: 18.2394613 },
    { lat: 48.091144, lng: 18.2393657 },
    { lat: 48.0918733, lng: 18.2395505 },
    { lat: 48.0920647, lng: 18.2394827 },
    { lat: 48.0923656, lng: 18.239162 },
    { lat: 48.0926872, lng: 18.2392253 },
    { lat: 48.0928348, lng: 18.2392991 },
    { lat: 48.0929373, lng: 18.2395294 },
    { lat: 48.0930146, lng: 18.2401219 },
    { lat: 48.0931721, lng: 18.2405637 },
    { lat: 48.0932922, lng: 18.2406283 },
    { lat: 48.0938333, lng: 18.2405815 },
    { lat: 48.0942872, lng: 18.240919 },
    { lat: 48.0944653, lng: 18.2405851 },
    { lat: 48.0945088, lng: 18.2402816 },
    { lat: 48.0947508, lng: 18.239806 },
    { lat: 48.0951078, lng: 18.2395185 },
    { lat: 48.0953146, lng: 18.2395836 },
    { lat: 48.0953967, lng: 18.239812 },
    { lat: 48.0953286, lng: 18.2402941 },
    { lat: 48.0953697, lng: 18.2405154 },
    { lat: 48.0954504, lng: 18.2405855 },
    { lat: 48.0957658, lng: 18.240691 },
    { lat: 48.0960782, lng: 18.2405127 },
    { lat: 48.0963576, lng: 18.2405427 },
    { lat: 48.0970571, lng: 18.2410875 },
    { lat: 48.0977533, lng: 18.2413218 },
    { lat: 48.0978699, lng: 18.2410163 },
    { lat: 48.0980805, lng: 18.2410103 },
    { lat: 48.0983108, lng: 18.2417141 },
    { lat: 48.0982569, lng: 18.2422453 },
    { lat: 48.0981323, lng: 18.2426094 },
    { lat: 48.0981127, lng: 18.2433052 },
    { lat: 48.0980012, lng: 18.2434837 },
    { lat: 48.098044, lng: 18.2436751 },
    { lat: 48.0979894, lng: 18.2439201 },
    { lat: 48.0977551, lng: 18.2440847 },
    { lat: 48.0977744, lng: 18.2442422 },
    { lat: 48.0976978, lng: 18.2443622 },
    { lat: 48.0976518, lng: 18.2447599 },
    { lat: 48.0976995, lng: 18.2449193 },
    { lat: 48.0976883, lng: 18.245428 },
    { lat: 48.0976027, lng: 18.2458213 },
    { lat: 48.0973762, lng: 18.2462546 },
    { lat: 48.0973977, lng: 18.2464516 },
    { lat: 48.0971741, lng: 18.2465464 },
    { lat: 48.0972912, lng: 18.2467566 },
    { lat: 48.0972836, lng: 18.2473488 },
    { lat: 48.0971922, lng: 18.2474442 },
    { lat: 48.0972315, lng: 18.2476951 },
    { lat: 48.0971473, lng: 18.2478591 },
    { lat: 48.0968713, lng: 18.2480616 },
    { lat: 48.096766, lng: 18.248253 },
    { lat: 48.0967079, lng: 18.2486354 },
    { lat: 48.0968094, lng: 18.2488083 },
    { lat: 48.0969202, lng: 18.2488145 },
    { lat: 48.097117, lng: 18.2490733 },
    { lat: 48.0971469, lng: 18.249223 },
    { lat: 48.0971219, lng: 18.2494525 },
    { lat: 48.0971944, lng: 18.2497697 },
    { lat: 48.0976282, lng: 18.2502472 },
    { lat: 48.0980361, lng: 18.250122 },
    { lat: 48.0982685, lng: 18.2501771 },
    { lat: 48.0983227, lng: 18.2509771 },
    { lat: 48.0985802, lng: 18.2511006 },
    { lat: 48.0990526, lng: 18.2511525 },
    { lat: 48.0991728, lng: 18.2513378 },
    { lat: 48.099462, lng: 18.2521661 },
    { lat: 48.0995148, lng: 18.2525109 },
    { lat: 48.0997672, lng: 18.2525822 },
    { lat: 48.1002296, lng: 18.252892 },
    { lat: 48.1003706, lng: 18.2527944 },
    { lat: 48.1004457, lng: 18.2524741 },
    { lat: 48.1003971, lng: 18.2517306 },
    { lat: 48.1004774, lng: 18.2516715 },
    { lat: 48.1008105, lng: 18.2517367 },
    { lat: 48.1008527, lng: 18.2516472 },
    { lat: 48.1008897, lng: 18.2511348 },
    { lat: 48.1010204, lng: 18.2510517 },
    { lat: 48.1012896, lng: 18.2511222 },
    { lat: 48.101496, lng: 18.2507087 },
    { lat: 48.1020668, lng: 18.2507461 },
    { lat: 48.1023646, lng: 18.2505878 },
    { lat: 48.1025566, lng: 18.2503597 },
    { lat: 48.1027041, lng: 18.2503714 },
    { lat: 48.1028633, lng: 18.2502944 },
    { lat: 48.103013, lng: 18.2500161 },
    { lat: 48.1035933, lng: 18.2501397 },
    { lat: 48.1038522, lng: 18.2504796 },
    { lat: 48.1040691, lng: 18.2506405 },
    { lat: 48.1043487, lng: 18.2502448 },
    { lat: 48.1047853, lng: 18.2504187 },
    { lat: 48.1051225, lng: 18.2504562 },
    { lat: 48.1055149, lng: 18.2499636 },
    { lat: 48.1056, lng: 18.2499499 },
    { lat: 48.1058164, lng: 18.2504343 },
    { lat: 48.1058224, lng: 18.2506358 },
    { lat: 48.1058767, lng: 18.2507379 },
    { lat: 48.106391, lng: 18.2505951 },
    { lat: 48.1066343, lng: 18.2509019 },
    { lat: 48.106912, lng: 18.2506088 },
    { lat: 48.1072967, lng: 18.2506454 },
    { lat: 48.1075145, lng: 18.2502764 },
    { lat: 48.1108166, lng: 18.2505563 },
    { lat: 48.1109802, lng: 18.2511255 },
    { lat: 48.1112904, lng: 18.2527839 },
    { lat: 48.1111871, lng: 18.2533773 },
    { lat: 48.1114126, lng: 18.253844 },
    { lat: 48.1116117, lng: 18.2538925 },
    { lat: 48.1116638, lng: 18.254014 },
    { lat: 48.1116594, lng: 18.2541346 },
    { lat: 48.1115115, lng: 18.2544431 },
    { lat: 48.1114917, lng: 18.2545911 },
    { lat: 48.1116406, lng: 18.255156 },
    { lat: 48.1116531, lng: 18.2554294 },
    { lat: 48.1117146, lng: 18.2555197 },
    { lat: 48.111882, lng: 18.2554875 },
    { lat: 48.1119563, lng: 18.2555828 },
    { lat: 48.1119951, lng: 18.2559228 },
    { lat: 48.1119498, lng: 18.2569571 },
    { lat: 48.1120141, lng: 18.2572479 },
    { lat: 48.1118697, lng: 18.2577573 },
    { lat: 48.1121663, lng: 18.257931 },
    { lat: 48.1125131, lng: 18.2583281 },
    { lat: 48.1128028, lng: 18.2579321 },
    { lat: 48.1131724, lng: 18.2581006 },
    { lat: 48.1133383, lng: 18.2578487 },
    { lat: 48.1134802, lng: 18.2577418 },
    { lat: 48.113635, lng: 18.2577855 },
    { lat: 48.1139127, lng: 18.2580217 },
    { lat: 48.1139241, lng: 18.2581053 },
    { lat: 48.1137279, lng: 18.2587121 },
    { lat: 48.1137309, lng: 18.2589207 },
    { lat: 48.1138201, lng: 18.2590406 },
    { lat: 48.1141815, lng: 18.2590672 },
    { lat: 48.1144417, lng: 18.2589783 },
    { lat: 48.1146207, lng: 18.2590683 },
    { lat: 48.11468, lng: 18.2592354 },
    { lat: 48.1145728, lng: 18.2593933 },
    { lat: 48.1144391, lng: 18.2594422 },
    { lat: 48.1144014, lng: 18.2595498 },
    { lat: 48.1144229, lng: 18.2596901 },
    { lat: 48.1144797, lng: 18.2598339 },
    { lat: 48.1146783, lng: 18.2599316 },
    { lat: 48.1148821, lng: 18.2603944 },
    { lat: 48.1150821, lng: 18.2603987 },
    { lat: 48.1151637, lng: 18.2600334 },
    { lat: 48.1154301, lng: 18.2598978 },
    { lat: 48.1156529, lng: 18.2601407 },
    { lat: 48.1157921, lng: 18.2606393 },
    { lat: 48.1157044, lng: 18.2611329 },
    { lat: 48.1154632, lng: 18.2613536 },
    { lat: 48.1153067, lng: 18.2613786 },
    { lat: 48.1152068, lng: 18.2615246 },
    { lat: 48.1151212, lng: 18.2618032 },
    { lat: 48.1151733, lng: 18.2620582 },
    { lat: 48.1156504, lng: 18.26225 },
    { lat: 48.1158133, lng: 18.2624353 },
    { lat: 48.1163564, lng: 18.2627921 },
    { lat: 48.11728, lng: 18.2639982 },
    { lat: 48.1176043, lng: 18.2644968 },
    { lat: 48.118401, lng: 18.2645939 },
    { lat: 48.1188225, lng: 18.2650967 },
    { lat: 48.1191059, lng: 18.2652135 },
    { lat: 48.1192776, lng: 18.265629 },
    { lat: 48.1196785, lng: 18.2661071 },
    { lat: 48.1198007, lng: 18.2660127 },
    { lat: 48.1192447, lng: 18.265178 },
    { lat: 48.1193884, lng: 18.2649872 },
    { lat: 48.1195322, lng: 18.2646629 },
    { lat: 48.1198071, lng: 18.2638119 },
    { lat: 48.1201119, lng: 18.2630742 },
    { lat: 48.120741, lng: 18.2618291 },
    { lat: 48.1209239, lng: 18.2612653 },
    { lat: 48.1211711, lng: 18.260738 },
    { lat: 48.121531, lng: 18.260227 },
    { lat: 48.1219015, lng: 18.2601561 },
    { lat: 48.1224467, lng: 18.2597872 },
    { lat: 48.122447, lng: 18.2594216 },
    { lat: 48.1230787, lng: 18.2586132 },
    { lat: 48.1231362, lng: 18.2584007 },
    { lat: 48.1236797, lng: 18.2572967 },
    { lat: 48.1240154, lng: 18.2564836 },
    { lat: 48.1247307, lng: 18.2551718 },
    { lat: 48.12561, lng: 18.2529667 },
    { lat: 48.1259869, lng: 18.2522045 },
    { lat: 48.1263875, lng: 18.2508779 },
    { lat: 48.1270043, lng: 18.2492671 },
    { lat: 48.1271554, lng: 18.2490502 },
  ],
  Hul: [
    { lat: 48.073439, lng: 18.3162568 },
    { lat: 48.0765565, lng: 18.3134575 },
    { lat: 48.077723, lng: 18.3116185 },
    { lat: 48.0778543, lng: 18.3114874 },
    { lat: 48.0776222, lng: 18.3112002 },
    { lat: 48.0779947, lng: 18.3105706 },
    { lat: 48.0777506, lng: 18.3102711 },
    { lat: 48.0787047, lng: 18.3088814 },
    { lat: 48.0793536, lng: 18.3099478 },
    { lat: 48.085403, lng: 18.3037154 },
    { lat: 48.0885427, lng: 18.3017777 },
    { lat: 48.0901271, lng: 18.3009148 },
    { lat: 48.0900624, lng: 18.3008517 },
    { lat: 48.0896027, lng: 18.2997773 },
    { lat: 48.0904524, lng: 18.2992114 },
    { lat: 48.0898921, lng: 18.2978607 },
    { lat: 48.0904216, lng: 18.2973756 },
    { lat: 48.0910287, lng: 18.2988297 },
    { lat: 48.0917302, lng: 18.2983727 },
    { lat: 48.09074, lng: 18.2921658 },
    { lat: 48.0915635, lng: 18.2913574 },
    { lat: 48.0915032, lng: 18.2909166 },
    { lat: 48.0916455, lng: 18.2908793 },
    { lat: 48.0917067, lng: 18.2906901 },
    { lat: 48.0919116, lng: 18.2905019 },
    { lat: 48.0919969, lng: 18.2904973 },
    { lat: 48.0939322, lng: 18.292504 },
    { lat: 48.0948588, lng: 18.2933151 },
    { lat: 48.0967289, lng: 18.2951302 },
    { lat: 48.0970558, lng: 18.295542 },
    { lat: 48.0973369, lng: 18.2960143 },
    { lat: 48.0974455, lng: 18.2956453 },
    { lat: 48.0975396, lng: 18.2948653 },
    { lat: 48.097972, lng: 18.2930445 },
    { lat: 48.0978387, lng: 18.2923683 },
    { lat: 48.0975618, lng: 18.2923027 },
    { lat: 48.0971611, lng: 18.289824 },
    { lat: 48.0980258, lng: 18.2895365 },
    { lat: 48.0983116, lng: 18.2893658 },
    { lat: 48.09842, lng: 18.2902128 },
    { lat: 48.0985503, lng: 18.2923059 },
    { lat: 48.0984878, lng: 18.2927907 },
    { lat: 48.0980033, lng: 18.2943927 },
    { lat: 48.0977244, lng: 18.2958861 },
    { lat: 48.0975908, lng: 18.2963482 },
    { lat: 48.0974858, lng: 18.2965039 },
    { lat: 48.0978285, lng: 18.2973474 },
    { lat: 48.0980286, lng: 18.2980031 },
    { lat: 48.0981632, lng: 18.2989541 },
    { lat: 48.0995402, lng: 18.29892 },
    { lat: 48.0997127, lng: 18.2990381 },
    { lat: 48.1015726, lng: 18.2992989 },
    { lat: 48.1068481, lng: 18.2997432 },
    { lat: 48.1069263, lng: 18.299808 },
    { lat: 48.1097286, lng: 18.300138 },
    { lat: 48.1099411, lng: 18.3065063 },
    { lat: 48.1157511, lng: 18.3071815 },
    { lat: 48.117099, lng: 18.3101311 },
    { lat: 48.1173215, lng: 18.3105321 },
    { lat: 48.117334, lng: 18.3103158 },
    { lat: 48.1172339, lng: 18.310062 },
    { lat: 48.1172747, lng: 18.3094746 },
    { lat: 48.118036, lng: 18.3072832 },
    { lat: 48.1192503, lng: 18.3042726 },
    { lat: 48.1178692, lng: 18.2979855 },
    { lat: 48.1175631, lng: 18.2968608 },
    { lat: 48.1173003, lng: 18.296113 },
    { lat: 48.1161891, lng: 18.2916119 },
    { lat: 48.1159642, lng: 18.2900136 },
    { lat: 48.1185742, lng: 18.2782885 },
    { lat: 48.1189421, lng: 18.2762893 },
    { lat: 48.1205275, lng: 18.2691734 },
    { lat: 48.1204052, lng: 18.2691842 },
    { lat: 48.1201124, lng: 18.268666 },
    { lat: 48.1199113, lng: 18.2687377 },
    { lat: 48.1198136, lng: 18.2687075 },
    { lat: 48.1197091, lng: 18.2684995 },
    { lat: 48.1198989, lng: 18.268014 },
    { lat: 48.1198861, lng: 18.2678122 },
    { lat: 48.1197782, lng: 18.2675429 },
    { lat: 48.1197324, lng: 18.2672564 },
    { lat: 48.1197902, lng: 18.2670077 },
    { lat: 48.11994, lng: 18.2668264 },
    { lat: 48.1199531, lng: 18.2664448 },
    { lat: 48.1199357, lng: 18.2662835 },
    { lat: 48.1198702, lng: 18.2661235 },
    { lat: 48.1198007, lng: 18.2660127 },
    { lat: 48.1196785, lng: 18.2661071 },
    { lat: 48.1192776, lng: 18.265629 },
    { lat: 48.1191059, lng: 18.2652135 },
    { lat: 48.1188225, lng: 18.2650967 },
    { lat: 48.118401, lng: 18.2645939 },
    { lat: 48.1176043, lng: 18.2644968 },
    { lat: 48.11728, lng: 18.2639982 },
    { lat: 48.1163564, lng: 18.2627921 },
    { lat: 48.1158133, lng: 18.2624353 },
    { lat: 48.1156504, lng: 18.26225 },
    { lat: 48.1151733, lng: 18.2620582 },
    { lat: 48.1151212, lng: 18.2618032 },
    { lat: 48.1152068, lng: 18.2615246 },
    { lat: 48.1153067, lng: 18.2613786 },
    { lat: 48.1154632, lng: 18.2613536 },
    { lat: 48.1157044, lng: 18.2611329 },
    { lat: 48.1157921, lng: 18.2606393 },
    { lat: 48.1156529, lng: 18.2601407 },
    { lat: 48.1154301, lng: 18.2598978 },
    { lat: 48.1151637, lng: 18.2600334 },
    { lat: 48.1150821, lng: 18.2603987 },
    { lat: 48.1148821, lng: 18.2603944 },
    { lat: 48.1146783, lng: 18.2599316 },
    { lat: 48.1144797, lng: 18.2598339 },
    { lat: 48.1144229, lng: 18.2596901 },
    { lat: 48.1144014, lng: 18.2595498 },
    { lat: 48.1144391, lng: 18.2594422 },
    { lat: 48.1145728, lng: 18.2593933 },
    { lat: 48.11468, lng: 18.2592354 },
    { lat: 48.1146207, lng: 18.2590683 },
    { lat: 48.1144417, lng: 18.2589783 },
    { lat: 48.1141815, lng: 18.2590672 },
    { lat: 48.1138201, lng: 18.2590406 },
    { lat: 48.1137309, lng: 18.2589207 },
    { lat: 48.1137279, lng: 18.2587121 },
    { lat: 48.1139241, lng: 18.2581053 },
    { lat: 48.1139127, lng: 18.2580217 },
    { lat: 48.113635, lng: 18.2577855 },
    { lat: 48.1134802, lng: 18.2577418 },
    { lat: 48.1133383, lng: 18.2578487 },
    { lat: 48.1131724, lng: 18.2581006 },
    { lat: 48.1128028, lng: 18.2579321 },
    { lat: 48.1125131, lng: 18.2583281 },
    { lat: 48.1121663, lng: 18.257931 },
    { lat: 48.1118697, lng: 18.2577573 },
    { lat: 48.1120141, lng: 18.2572479 },
    { lat: 48.1119498, lng: 18.2569571 },
    { lat: 48.1119951, lng: 18.2559228 },
    { lat: 48.1119563, lng: 18.2555828 },
    { lat: 48.111882, lng: 18.2554875 },
    { lat: 48.1117146, lng: 18.2555197 },
    { lat: 48.1116531, lng: 18.2554294 },
    { lat: 48.1116406, lng: 18.255156 },
    { lat: 48.1114917, lng: 18.2545911 },
    { lat: 48.1115115, lng: 18.2544431 },
    { lat: 48.1116594, lng: 18.2541346 },
    { lat: 48.1116638, lng: 18.254014 },
    { lat: 48.1116117, lng: 18.2538925 },
    { lat: 48.1114126, lng: 18.253844 },
    { lat: 48.1111871, lng: 18.2533773 },
    { lat: 48.1112904, lng: 18.2527839 },
    { lat: 48.1109802, lng: 18.2511255 },
    { lat: 48.1108166, lng: 18.2505563 },
    { lat: 48.1075145, lng: 18.2502764 },
    { lat: 48.1072967, lng: 18.2506454 },
    { lat: 48.106912, lng: 18.2506088 },
    { lat: 48.1066343, lng: 18.2509019 },
    { lat: 48.106391, lng: 18.2505951 },
    { lat: 48.1058767, lng: 18.2507379 },
    { lat: 48.1058224, lng: 18.2506358 },
    { lat: 48.1058164, lng: 18.2504343 },
    { lat: 48.1056, lng: 18.2499499 },
    { lat: 48.1055149, lng: 18.2499636 },
    { lat: 48.1051225, lng: 18.2504562 },
    { lat: 48.1047853, lng: 18.2504187 },
    { lat: 48.1043487, lng: 18.2502448 },
    { lat: 48.1040691, lng: 18.2506405 },
    { lat: 48.1038522, lng: 18.2504796 },
    { lat: 48.1035933, lng: 18.2501397 },
    { lat: 48.103013, lng: 18.2500161 },
    { lat: 48.1028633, lng: 18.2502944 },
    { lat: 48.1027041, lng: 18.2503714 },
    { lat: 48.1025566, lng: 18.2503597 },
    { lat: 48.1023646, lng: 18.2505878 },
    { lat: 48.1020668, lng: 18.2507461 },
    { lat: 48.101496, lng: 18.2507087 },
    { lat: 48.1012896, lng: 18.2511222 },
    { lat: 48.1010204, lng: 18.2510517 },
    { lat: 48.1008897, lng: 18.2511348 },
    { lat: 48.1008527, lng: 18.2516472 },
    { lat: 48.1008105, lng: 18.2517367 },
    { lat: 48.1004774, lng: 18.2516715 },
    { lat: 48.1003971, lng: 18.2517306 },
    { lat: 48.1004457, lng: 18.2524741 },
    { lat: 48.1003706, lng: 18.2527944 },
    { lat: 48.1002296, lng: 18.252892 },
    { lat: 48.0997672, lng: 18.2525822 },
    { lat: 48.0995148, lng: 18.2525109 },
    { lat: 48.099462, lng: 18.2521661 },
    { lat: 48.0991728, lng: 18.2513378 },
    { lat: 48.0990526, lng: 18.2511525 },
    { lat: 48.0985802, lng: 18.2511006 },
    { lat: 48.0983227, lng: 18.2509771 },
    { lat: 48.0982685, lng: 18.2501771 },
    { lat: 48.0980361, lng: 18.250122 },
    { lat: 48.0976282, lng: 18.2502472 },
    { lat: 48.0971944, lng: 18.2497697 },
    { lat: 48.0971219, lng: 18.2494525 },
    { lat: 48.0971469, lng: 18.249223 },
    { lat: 48.097117, lng: 18.2490733 },
    { lat: 48.0969202, lng: 18.2488145 },
    { lat: 48.0968094, lng: 18.2488083 },
    { lat: 48.0967079, lng: 18.2486354 },
    { lat: 48.096658, lng: 18.2486464 },
    { lat: 48.0949443, lng: 18.2493192 },
    { lat: 48.0939822, lng: 18.2532238 },
    { lat: 48.0922357, lng: 18.2603111 },
    { lat: 48.0922348, lng: 18.2605121 },
    { lat: 48.0912181, lng: 18.2645031 },
    { lat: 48.0906152, lng: 18.2671011 },
    { lat: 48.090448, lng: 18.2687411 },
    { lat: 48.0898475, lng: 18.2726392 },
    { lat: 48.0897418, lng: 18.2742695 },
    { lat: 48.0902171, lng: 18.276332 },
    { lat: 48.0903943, lng: 18.2782941 },
    { lat: 48.0904531, lng: 18.2800649 },
    { lat: 48.0902133, lng: 18.2823512 },
    { lat: 48.0892852, lng: 18.2832824 },
    { lat: 48.0881102, lng: 18.2841983 },
    { lat: 48.0847144, lng: 18.286131 },
    { lat: 48.0840888, lng: 18.2873371 },
    { lat: 48.0834418, lng: 18.2889005 },
    { lat: 48.0830032, lng: 18.2898095 },
    { lat: 48.0820703, lng: 18.2914052 },
    { lat: 48.0814435, lng: 18.2926529 },
    { lat: 48.0799858, lng: 18.2946419 },
    { lat: 48.0793793, lng: 18.2958104 },
    { lat: 48.0790288, lng: 18.296642 },
    { lat: 48.0773064, lng: 18.2996423 },
    { lat: 48.0766761, lng: 18.3008309 },
    { lat: 48.0761182, lng: 18.3019627 },
    { lat: 48.0746419, lng: 18.3056756 },
    { lat: 48.0734886, lng: 18.3080584 },
    { lat: 48.0731234, lng: 18.3085541 },
    { lat: 48.073784, lng: 18.3122084 },
    { lat: 48.0738609, lng: 18.3128417 },
    { lat: 48.0738545, lng: 18.3148986 },
    { lat: 48.0737258, lng: 18.3151383 },
    { lat: 48.073709, lng: 18.3153362 },
    { lat: 48.0735878, lng: 18.3155439 },
    { lat: 48.073439, lng: 18.3162568 },
  ],
  Jasová: [
    { lat: 47.996572, lng: 18.465616 },
    { lat: 47.996805, lng: 18.465346 },
    { lat: 47.99701, lng: 18.465191 },
    { lat: 47.997275, lng: 18.465027 },
    { lat: 47.997436, lng: 18.46487 },
    { lat: 47.997605, lng: 18.464616 },
    { lat: 47.997744, lng: 18.464457 },
    { lat: 47.997888, lng: 18.464284 },
    { lat: 47.997927, lng: 18.464242 },
    { lat: 47.997994, lng: 18.464199 },
    { lat: 47.998147, lng: 18.464109 },
    { lat: 47.998272, lng: 18.463986 },
    { lat: 47.99844, lng: 18.463775 },
    { lat: 47.99883, lng: 18.463252 },
    { lat: 47.999017, lng: 18.463074 },
    { lat: 47.999463, lng: 18.462697 },
    { lat: 47.999629, lng: 18.462542 },
    { lat: 47.999899, lng: 18.462299 },
    { lat: 48.000015, lng: 18.462181 },
    { lat: 48.000165, lng: 18.461929 },
    { lat: 48.000313, lng: 18.46167 },
    { lat: 48.000583, lng: 18.461332 },
    { lat: 48.000706, lng: 18.461216 },
    { lat: 48.000791, lng: 18.46107 },
    { lat: 48.000892, lng: 18.460893 },
    { lat: 48.001039, lng: 18.460636 },
    { lat: 48.001302, lng: 18.460054 },
    { lat: 48.001368, lng: 18.459899 },
    { lat: 48.001487, lng: 18.459591 },
    { lat: 48.001587, lng: 18.459345 },
    { lat: 48.001689, lng: 18.459145 },
    { lat: 48.001918, lng: 18.458712 },
    { lat: 48.002031, lng: 18.458318 },
    { lat: 48.002132, lng: 18.458135 },
    { lat: 48.002274, lng: 18.457856 },
    { lat: 48.002377, lng: 18.457691 },
    { lat: 48.00249, lng: 18.457511 },
    { lat: 48.002686, lng: 18.457011 },
    { lat: 48.002939, lng: 18.456582 },
    { lat: 48.003116, lng: 18.456274 },
    { lat: 48.003257, lng: 18.456081 },
    { lat: 48.003414, lng: 18.45594 },
    { lat: 48.003725, lng: 18.455611 },
    { lat: 48.004095, lng: 18.455298 },
    { lat: 48.004225, lng: 18.455184 },
    { lat: 48.004386, lng: 18.454917 },
    { lat: 48.00454, lng: 18.454699 },
    { lat: 48.004718, lng: 18.454538 },
    { lat: 48.004875, lng: 18.45439 },
    { lat: 48.005063, lng: 18.454201 },
    { lat: 48.005273, lng: 18.453937 },
    { lat: 48.0048946, lng: 18.4537689 },
    { lat: 48.0043063, lng: 18.4533539 },
    { lat: 48.0034139, lng: 18.4523677 },
    { lat: 48.0032893, lng: 18.4520168 },
    { lat: 48.0032344, lng: 18.4516823 },
    { lat: 48.0032871, lng: 18.4511928 },
    { lat: 48.0032603, lng: 18.4510356 },
    { lat: 48.0029229, lng: 18.4497711 },
    { lat: 48.002821, lng: 18.4491374 },
    { lat: 48.0030272, lng: 18.4473857 },
    { lat: 48.003158, lng: 18.4466423 },
    { lat: 48.0035946, lng: 18.4453152 },
    { lat: 48.0041096, lng: 18.4441375 },
    { lat: 48.0042556, lng: 18.4435853 },
    { lat: 48.0042445, lng: 18.4431055 },
    { lat: 48.0044675, lng: 18.4410096 },
    { lat: 48.0044789, lng: 18.4405907 },
    { lat: 48.004423, lng: 18.4399368 },
    { lat: 48.0044548, lng: 18.4399216 },
    { lat: 48.004339, lng: 18.4395848 },
    { lat: 48.0036039, lng: 18.4380906 },
    { lat: 48.002491, lng: 18.4351258 },
    { lat: 48.0015934, lng: 18.4331515 },
    { lat: 48.0004348, lng: 18.4288998 },
    { lat: 47.9990386, lng: 18.4232195 },
    { lat: 47.9987072, lng: 18.4221415 },
    { lat: 47.9986557, lng: 18.4216474 },
    { lat: 47.9986112, lng: 18.4200342 },
    { lat: 47.9970645, lng: 18.4162764 },
    { lat: 47.996492, lng: 18.4141865 },
    { lat: 47.995652, lng: 18.411717 },
    { lat: 47.9940798, lng: 18.4124032 },
    { lat: 47.9936021, lng: 18.4109757 },
    { lat: 47.9939692, lng: 18.4107036 },
    { lat: 47.9930754, lng: 18.4080029 },
    { lat: 47.9911753, lng: 18.4077754 },
    { lat: 47.99109, lng: 18.4075054 },
    { lat: 47.99015, lng: 18.4054665 },
    { lat: 47.9906819, lng: 18.4050716 },
    { lat: 47.9906412, lng: 18.4049721 },
    { lat: 47.9910694, lng: 18.4046839 },
    { lat: 47.9903721, lng: 18.402586 },
    { lat: 47.9905647, lng: 18.4024625 },
    { lat: 47.9907842, lng: 18.4031517 },
    { lat: 47.9908917, lng: 18.4031164 },
    { lat: 47.9906569, lng: 18.4023957 },
    { lat: 47.9910362, lng: 18.4022016 },
    { lat: 47.9909299, lng: 18.4018976 },
    { lat: 47.9917426, lng: 18.4013449 },
    { lat: 47.9928769, lng: 18.400776 },
    { lat: 47.9929495, lng: 18.4007648 },
    { lat: 47.9933181, lng: 18.4012951 },
    { lat: 47.9931153, lng: 18.4007827 },
    { lat: 47.9928072, lng: 18.3987029 },
    { lat: 47.9927503, lng: 18.397259 },
    { lat: 47.9927428, lng: 18.3954338 },
    { lat: 47.9929942, lng: 18.3954892 },
    { lat: 47.9931466, lng: 18.3958896 },
    { lat: 47.9932487, lng: 18.3957809 },
    { lat: 47.993513, lng: 18.395826 },
    { lat: 47.9934456, lng: 18.3950747 },
    { lat: 47.9933789, lng: 18.3949729 },
    { lat: 47.9933096, lng: 18.3946846 },
    { lat: 47.9927278, lng: 18.3946714 },
    { lat: 47.9927227, lng: 18.3944019 },
    { lat: 47.9923806, lng: 18.39294 },
    { lat: 47.9936465, lng: 18.3917659 },
    { lat: 47.9937683, lng: 18.3919467 },
    { lat: 47.9976829, lng: 18.3857877 },
    { lat: 47.9966446, lng: 18.3843076 },
    { lat: 47.9948295, lng: 18.3871779 },
    { lat: 47.9919308, lng: 18.3830909 },
    { lat: 47.9904723, lng: 18.3853823 },
    { lat: 47.9903057, lng: 18.3847756 },
    { lat: 47.9897114, lng: 18.3813574 },
    { lat: 47.9892706, lng: 18.3785381 },
    { lat: 47.989036, lng: 18.3763704 },
    { lat: 47.9888833, lng: 18.3753929 },
    { lat: 47.9893138, lng: 18.3747255 },
    { lat: 47.9900417, lng: 18.3738978 },
    { lat: 47.9900881, lng: 18.37372 },
    { lat: 47.9887514, lng: 18.3703684 },
    { lat: 47.9878644, lng: 18.3683598 },
    { lat: 47.9870774, lng: 18.3606908 },
    { lat: 47.9872614, lng: 18.3580376 },
    { lat: 47.9874395, lng: 18.3534977 },
    { lat: 47.9866954, lng: 18.3485961 },
    { lat: 47.9862084, lng: 18.3464246 },
    { lat: 47.9856903, lng: 18.3456728 },
    { lat: 47.9846505, lng: 18.3439203 },
    { lat: 47.9833259, lng: 18.342554 },
    { lat: 47.9806929, lng: 18.3395768 },
    { lat: 47.97855, lng: 18.3381087 },
    { lat: 47.9781308, lng: 18.3378802 },
    { lat: 47.9747565, lng: 18.3420166 },
    { lat: 47.9747771, lng: 18.3420583 },
    { lat: 47.9718685, lng: 18.3527796 },
    { lat: 47.9718989, lng: 18.3528409 },
    { lat: 47.9716722, lng: 18.3533751 },
    { lat: 47.9712098, lng: 18.3549249 },
    { lat: 47.9708971, lng: 18.3557716 },
    { lat: 47.9703137, lng: 18.3569976 },
    { lat: 47.9665204, lng: 18.3656962 },
    { lat: 47.9681811, lng: 18.3691071 },
    { lat: 47.9696167, lng: 18.3724903 },
    { lat: 47.9699507, lng: 18.3734047 },
    { lat: 47.9704966, lng: 18.3746104 },
    { lat: 47.9711473, lng: 18.3758568 },
    { lat: 47.9701702, lng: 18.3773056 },
    { lat: 47.9760417, lng: 18.3895676 },
    { lat: 47.9734414, lng: 18.3917621 },
    { lat: 47.9725817, lng: 18.3926777 },
    { lat: 47.9737166, lng: 18.3947268 },
    { lat: 47.976485, lng: 18.400107 },
    { lat: 47.975113, lng: 18.4020197 },
    { lat: 47.9762711, lng: 18.4040468 },
    { lat: 47.9774032, lng: 18.4057301 },
    { lat: 47.9783467, lng: 18.4069946 },
    { lat: 47.9788054, lng: 18.4080046 },
    { lat: 47.9785743, lng: 18.4106842 },
    { lat: 47.9773703, lng: 18.4107223 },
    { lat: 47.9768872, lng: 18.4114954 },
    { lat: 47.9760905, lng: 18.4121314 },
    { lat: 47.9760956, lng: 18.4131592 },
    { lat: 47.976026, lng: 18.4140449 },
    { lat: 47.9760805, lng: 18.4144117 },
    { lat: 47.9749955, lng: 18.4161353 },
    { lat: 47.9753124, lng: 18.4175536 },
    { lat: 47.9755606, lng: 18.418433 },
    { lat: 47.9759631, lng: 18.4195089 },
    { lat: 47.9763673, lng: 18.4211353 },
    { lat: 47.9765917, lng: 18.4227969 },
    { lat: 47.9768782, lng: 18.4243145 },
    { lat: 47.9772308, lng: 18.4255083 },
    { lat: 47.9776896, lng: 18.4265234 },
    { lat: 47.977983, lng: 18.4274466 },
    { lat: 47.9785248, lng: 18.428614 },
    { lat: 47.9784222, lng: 18.4288103 },
    { lat: 47.9779514, lng: 18.4297109 },
    { lat: 47.9774021, lng: 18.4308743 },
    { lat: 47.9768397, lng: 18.4320653 },
    { lat: 47.9767712, lng: 18.4321549 },
    { lat: 47.976756, lng: 18.4321033 },
    { lat: 47.9757769, lng: 18.4337087 },
    { lat: 47.9742732, lng: 18.4365167 },
    { lat: 47.9736549, lng: 18.4380816 },
    { lat: 47.9735491, lng: 18.4385894 },
    { lat: 47.9733187, lng: 18.4391455 },
    { lat: 47.9718859, lng: 18.4412319 },
    { lat: 47.9726832, lng: 18.44269 },
    { lat: 47.9733245, lng: 18.4435852 },
    { lat: 47.9740682, lng: 18.4448138 },
    { lat: 47.9754071, lng: 18.4473217 },
    { lat: 47.9762584, lng: 18.448662 },
    { lat: 47.977123, lng: 18.449784 },
    { lat: 47.977247, lng: 18.449718 },
    { lat: 47.977319, lng: 18.44982 },
    { lat: 47.977493, lng: 18.450051 },
    { lat: 47.977512, lng: 18.450079 },
    { lat: 47.977898, lng: 18.450605 },
    { lat: 47.978169, lng: 18.450964 },
    { lat: 47.978727, lng: 18.451481 },
    { lat: 47.979213, lng: 18.451419 },
    { lat: 47.980299, lng: 18.451582 },
    { lat: 47.980852, lng: 18.451563 },
    { lat: 47.98128, lng: 18.451541 },
    { lat: 47.981536, lng: 18.451532 },
    { lat: 47.981898, lng: 18.451546 },
    { lat: 47.982694, lng: 18.451673 },
    { lat: 47.984288, lng: 18.451873 },
    { lat: 47.984726, lng: 18.452653 },
    { lat: 47.985785, lng: 18.454527 },
    { lat: 47.98636, lng: 18.455757 },
    { lat: 47.987476, lng: 18.456497 },
    { lat: 47.988186, lng: 18.456901 },
    { lat: 47.988788, lng: 18.457039 },
    { lat: 47.989327, lng: 18.457311 },
    { lat: 47.989672, lng: 18.457714 },
    { lat: 47.989703, lng: 18.457753 },
    { lat: 47.989725, lng: 18.458483 },
    { lat: 47.989706, lng: 18.459034 },
    { lat: 47.989685, lng: 18.459472 },
    { lat: 47.989688, lng: 18.460562 },
    { lat: 47.989752, lng: 18.461257 },
    { lat: 47.989856, lng: 18.461781 },
    { lat: 47.989908, lng: 18.461928 },
    { lat: 47.990126, lng: 18.462296 },
    { lat: 47.99036, lng: 18.462502 },
    { lat: 47.990673, lng: 18.462906 },
    { lat: 47.991138, lng: 18.463172 },
    { lat: 47.991482, lng: 18.463184 },
    { lat: 47.991603, lng: 18.46321 },
    { lat: 47.992016, lng: 18.463381 },
    { lat: 47.992166, lng: 18.463357 },
    { lat: 47.992336, lng: 18.463428 },
    { lat: 47.992452, lng: 18.463543 },
    { lat: 47.992678, lng: 18.463771 },
    { lat: 47.992886, lng: 18.463894 },
    { lat: 47.992995, lng: 18.463909 },
    { lat: 47.993141, lng: 18.463974 },
    { lat: 47.993426, lng: 18.464239 },
    { lat: 47.993522, lng: 18.464284 },
    { lat: 47.993704, lng: 18.464311 },
    { lat: 47.993869, lng: 18.464415 },
    { lat: 47.994, lng: 18.464481 },
    { lat: 47.994444, lng: 18.464461 },
    { lat: 47.994713, lng: 18.464501 },
    { lat: 47.99486, lng: 18.464462 },
    { lat: 47.994949, lng: 18.464444 },
    { lat: 47.995032, lng: 18.464462 },
    { lat: 47.995146, lng: 18.464508 },
    { lat: 47.995311, lng: 18.464623 },
    { lat: 47.99552, lng: 18.464803 },
    { lat: 47.995733, lng: 18.46495 },
    { lat: 47.995951, lng: 18.465065 },
    { lat: 47.996225, lng: 18.465236 },
    { lat: 47.996372, lng: 18.465369 },
    { lat: 47.996556, lng: 18.465588 },
    { lat: 47.996572, lng: 18.465616 },
  ],
  Belá: [
    { lat: 47.8444429, lng: 18.5781724 },
    { lat: 47.8444348, lng: 18.5780697 },
    { lat: 47.8441937, lng: 18.5779277 },
    { lat: 47.8434669, lng: 18.5769586 },
    { lat: 47.8433385, lng: 18.5763496 },
    { lat: 47.8432934, lng: 18.575655 },
    { lat: 47.8421647, lng: 18.57331 },
    { lat: 47.8407585, lng: 18.5696156 },
    { lat: 47.8403075, lng: 18.5687001 },
    { lat: 47.840162, lng: 18.568273 },
    { lat: 47.8389354, lng: 18.5656102 },
    { lat: 47.8383616, lng: 18.5641818 },
    { lat: 47.8378009, lng: 18.5630385 },
    { lat: 47.8369956, lng: 18.5622643 },
    { lat: 47.8323698, lng: 18.5583297 },
    { lat: 47.8300312, lng: 18.5565064 },
    { lat: 47.828417, lng: 18.555327 },
    { lat: 47.828357, lng: 18.5552592 },
    { lat: 47.8272059, lng: 18.5570456 },
    { lat: 47.8264187, lng: 18.558454 },
    { lat: 47.8245688, lng: 18.56188 },
    { lat: 47.8242012, lng: 18.5626191 },
    { lat: 47.82363, lng: 18.5639764 },
    { lat: 47.8230995, lng: 18.5659294 },
    { lat: 47.8226016, lng: 18.5674343 },
    { lat: 47.8215848, lng: 18.5702647 },
    { lat: 47.8208332, lng: 18.5720549 },
    { lat: 47.820482, lng: 18.5732344 },
    { lat: 47.8201199, lng: 18.5747036 },
    { lat: 47.8197403, lng: 18.5766148 },
    { lat: 47.8191961, lng: 18.5814962 },
    { lat: 47.8190264, lng: 18.5822964 },
    { lat: 47.8189315, lng: 18.5825489 },
    { lat: 47.81882, lng: 18.5829203 },
    { lat: 47.8181372, lng: 18.5847781 },
    { lat: 47.817529, lng: 18.5862665 },
    { lat: 47.8142757, lng: 18.5948085 },
    { lat: 47.813037, lng: 18.5984978 },
    { lat: 47.8121502, lng: 18.6018566 },
    { lat: 47.8199258, lng: 18.6036582 },
    { lat: 47.8202425, lng: 18.603817 },
    { lat: 47.8208507, lng: 18.6039477 },
    { lat: 47.822052, lng: 18.6043974 },
    { lat: 47.8221305, lng: 18.6043511 },
    { lat: 47.8229964, lng: 18.6047751 },
    { lat: 47.8240273, lng: 18.6051213 },
    { lat: 47.8248103, lng: 18.6055986 },
    { lat: 47.8249533, lng: 18.6056164 },
    { lat: 47.8253641, lng: 18.6058882 },
    { lat: 47.8257643, lng: 18.6060472 },
    { lat: 47.8268644, lng: 18.6064671 },
    { lat: 47.8275081, lng: 18.6065426 },
    { lat: 47.8281621, lng: 18.6068646 },
    { lat: 47.829529, lng: 18.6073995 },
    { lat: 47.8312339, lng: 18.607965 },
    { lat: 47.8325541, lng: 18.608303 },
    { lat: 47.8332834, lng: 18.6086416 },
    { lat: 47.8336646, lng: 18.6086025 },
    { lat: 47.8339779, lng: 18.6084922 },
    { lat: 47.8344993, lng: 18.608109 },
    { lat: 47.8350222, lng: 18.6079783 },
    { lat: 47.8354753, lng: 18.6073744 },
    { lat: 47.8362198, lng: 18.6065338 },
    { lat: 47.8362462, lng: 18.60648 },
    { lat: 47.8383525, lng: 18.6034035 },
    { lat: 47.8385997, lng: 18.6029551 },
    { lat: 47.8386944, lng: 18.6015158 },
    { lat: 47.8387255, lng: 18.5997233 },
    { lat: 47.838566, lng: 18.599282 },
    { lat: 47.838713, lng: 18.5979354 },
    { lat: 47.8389435, lng: 18.5972439 },
    { lat: 47.8387498, lng: 18.5963462 },
    { lat: 47.8387862, lng: 18.595963 },
    { lat: 47.8389649, lng: 18.5953968 },
    { lat: 47.8393242, lng: 18.5950362 },
    { lat: 47.8394432, lng: 18.5947802 },
    { lat: 47.8396281, lng: 18.5941231 },
    { lat: 47.8396498, lng: 18.5939635 },
    { lat: 47.8394937, lng: 18.5932395 },
    { lat: 47.839515, lng: 18.5925173 },
    { lat: 47.8398793, lng: 18.592188 },
    { lat: 47.8402585, lng: 18.5921297 },
    { lat: 47.8408264, lng: 18.5918736 },
    { lat: 47.8411265, lng: 18.5914429 },
    { lat: 47.8415529, lng: 18.5903983 },
    { lat: 47.8416029, lng: 18.5901823 },
    { lat: 47.8416019, lng: 18.5898755 },
    { lat: 47.841988, lng: 18.588735 },
    { lat: 47.842345, lng: 18.5882945 },
    { lat: 47.842511, lng: 18.5879489 },
    { lat: 47.8425215, lng: 18.5877475 },
    { lat: 47.8424398, lng: 18.5874744 },
    { lat: 47.8424102, lng: 18.5870399 },
    { lat: 47.8425906, lng: 18.5867704 },
    { lat: 47.842908, lng: 18.5865335 },
    { lat: 47.8434109, lng: 18.585978 },
    { lat: 47.8442293, lng: 18.5839794 },
    { lat: 47.8443859, lng: 18.5834667 },
    { lat: 47.8444709, lng: 18.5830278 },
    { lat: 47.8445379, lng: 18.5826814 },
    { lat: 47.8446965, lng: 18.581403 },
    { lat: 47.8447105, lng: 18.5804695 },
    { lat: 47.8444429, lng: 18.5781724 },
  ],
  KamenicanadHronom: [
    { lat: 47.8692167, lng: 18.6991417 },
    { lat: 47.8672556, lng: 18.6993332 },
    { lat: 47.863552, lng: 18.7004641 },
    { lat: 47.8628948, lng: 18.7005962 },
    { lat: 47.8624203, lng: 18.7004972 },
    { lat: 47.861248, lng: 18.6998205 },
    { lat: 47.8600102, lng: 18.6993519 },
    { lat: 47.8590069, lng: 18.6992977 },
    { lat: 47.8578952, lng: 18.6997618 },
    { lat: 47.8564158, lng: 18.7013127 },
    { lat: 47.8553934, lng: 18.7020123 },
    { lat: 47.8543884, lng: 18.7023656 },
    { lat: 47.8537223, lng: 18.7021444 },
    { lat: 47.854124, lng: 18.7002485 },
    { lat: 47.854159, lng: 18.6992692 },
    { lat: 47.853771, lng: 18.698767 },
    { lat: 47.8519639, lng: 18.698636 },
    { lat: 47.8509329, lng: 18.697943 },
    { lat: 47.8503663, lng: 18.6974376 },
    { lat: 47.8502023, lng: 18.6973634 },
    { lat: 47.8501159, lng: 18.6972263 },
    { lat: 47.849896, lng: 18.6963934 },
    { lat: 47.8499236, lng: 18.6961127 },
    { lat: 47.8491696, lng: 18.6960591 },
    { lat: 47.8486035, lng: 18.6956196 },
    { lat: 47.8485634, lng: 18.6953979 },
    { lat: 47.8476642, lng: 18.6957939 },
    { lat: 47.8475455, lng: 18.6954727 },
    { lat: 47.8472653, lng: 18.695108 },
    { lat: 47.8471455, lng: 18.6948334 },
    { lat: 47.8464282, lng: 18.6939366 },
    { lat: 47.8455358, lng: 18.6935137 },
    { lat: 47.8447234, lng: 18.692633 },
    { lat: 47.8440426, lng: 18.6927058 },
    { lat: 47.8440325, lng: 18.692641 },
    { lat: 47.8433768, lng: 18.6925726 },
    { lat: 47.8427406, lng: 18.6929241 },
    { lat: 47.8417073, lng: 18.6924419 },
    { lat: 47.8415022, lng: 18.6921947 },
    { lat: 47.8412785, lng: 18.6916853 },
    { lat: 47.8411856, lng: 18.6912836 },
    { lat: 47.8411717, lng: 18.6908822 },
    { lat: 47.8415348, lng: 18.6889839 },
    { lat: 47.840994, lng: 18.6884113 },
    { lat: 47.8408165, lng: 18.6871547 },
    { lat: 47.8404045, lng: 18.6869575 },
    { lat: 47.8401555, lng: 18.6857147 },
    { lat: 47.8401145, lng: 18.6851849 },
    { lat: 47.8398449, lng: 18.6849163 },
    { lat: 47.8392781, lng: 18.6847798 },
    { lat: 47.8386217, lng: 18.6847168 },
    { lat: 47.8380127, lng: 18.68505 },
    { lat: 47.8376018, lng: 18.6854179 },
    { lat: 47.8367552, lng: 18.6860708 },
    { lat: 47.8360659, lng: 18.6866092 },
    { lat: 47.8363924, lng: 18.6875766 },
    { lat: 47.8363723, lng: 18.6880381 },
    { lat: 47.835572, lng: 18.6902998 },
    { lat: 47.8354988, lng: 18.6908414 },
    { lat: 47.8355683, lng: 18.6916232 },
    { lat: 47.8355252, lng: 18.6919316 },
    { lat: 47.8346536, lng: 18.694002 },
    { lat: 47.8340217, lng: 18.6951831 },
    { lat: 47.8338201, lng: 18.6954192 },
    { lat: 47.8334932, lng: 18.6955659 },
    { lat: 47.8333088, lng: 18.6952429 },
    { lat: 47.8329021, lng: 18.6941679 },
    { lat: 47.8327112, lng: 18.6937891 },
    { lat: 47.8319141, lng: 18.6931665 },
    { lat: 47.8317004, lng: 18.6930951 },
    { lat: 47.8315355, lng: 18.693153 },
    { lat: 47.8309341, lng: 18.6936035 },
    { lat: 47.8306252, lng: 18.6940672 },
    { lat: 47.8304062, lng: 18.694605 },
    { lat: 47.830371, lng: 18.6949121 },
    { lat: 47.8304003, lng: 18.6954544 },
    { lat: 47.8306077, lng: 18.6959547 },
    { lat: 47.8312411, lng: 18.6968851 },
    { lat: 47.8330007, lng: 18.6988717 },
    { lat: 47.8333405, lng: 18.6993556 },
    { lat: 47.8333541, lng: 18.6996467 },
    { lat: 47.833002, lng: 18.7026682 },
    { lat: 47.8325757, lng: 18.7053644 },
    { lat: 47.8326145, lng: 18.7063096 },
    { lat: 47.8328157, lng: 18.7078868 },
    { lat: 47.8328268, lng: 18.7085713 },
    { lat: 47.8326513, lng: 18.7091022 },
    { lat: 47.832377, lng: 18.70941 },
    { lat: 47.8318266, lng: 18.7098483 },
    { lat: 47.831612, lng: 18.7099718 },
    { lat: 47.8302952, lng: 18.7102085 },
    { lat: 47.8299211, lng: 18.7104675 },
    { lat: 47.8294041, lng: 18.7110431 },
    { lat: 47.8288356, lng: 18.7119819 },
    { lat: 47.8282626, lng: 18.7134651 },
    { lat: 47.8278289, lng: 18.7151068 },
    { lat: 47.8275231, lng: 18.7170496 },
    { lat: 47.8274469, lng: 18.7186256 },
    { lat: 47.8272427, lng: 18.7204846 },
    { lat: 47.8268017, lng: 18.7202959 },
    { lat: 47.8267884, lng: 18.7203652 },
    { lat: 47.8264833, lng: 18.7205011 },
    { lat: 47.8264549, lng: 18.7206449 },
    { lat: 47.8251641, lng: 18.7192296 },
    { lat: 47.8247917, lng: 18.7188927 },
    { lat: 47.82406, lng: 18.7184143 },
    { lat: 47.8230484, lng: 18.7181236 },
    { lat: 47.8222202, lng: 18.7193488 },
    { lat: 47.8226167, lng: 18.7202421 },
    { lat: 47.8180523, lng: 18.7240878 },
    { lat: 47.817781, lng: 18.7234292 },
    { lat: 47.8172854, lng: 18.7237312 },
    { lat: 47.8169696, lng: 18.7245515 },
    { lat: 47.8168227, lng: 18.724504 },
    { lat: 47.8163346, lng: 18.7244844 },
    { lat: 47.815575, lng: 18.7239493 },
    { lat: 47.8150774, lng: 18.7238859 },
    { lat: 47.8148412, lng: 18.7238743 },
    { lat: 47.8138852, lng: 18.7241541 },
    { lat: 47.8132522, lng: 18.724232 },
    { lat: 47.8133626, lng: 18.7246887 },
    { lat: 47.8133588, lng: 18.7258775 },
    { lat: 47.8134505, lng: 18.7263862 },
    { lat: 47.8136745, lng: 18.7270055 },
    { lat: 47.8138307, lng: 18.7272245 },
    { lat: 47.8142607, lng: 18.7277138 },
    { lat: 47.8160605, lng: 18.7293855 },
    { lat: 47.8167865, lng: 18.7304524 },
    { lat: 47.8180382, lng: 18.732797 },
    { lat: 47.8182855, lng: 18.7330757 },
    { lat: 47.8185568, lng: 18.7332558 },
    { lat: 47.8186537, lng: 18.7334216 },
    { lat: 47.8191901, lng: 18.734925 },
    { lat: 47.8192466, lng: 18.7354089 },
    { lat: 47.8192446, lng: 18.7360828 },
    { lat: 47.8193469, lng: 18.7367904 },
    { lat: 47.8191784, lng: 18.7371819 },
    { lat: 47.8197425, lng: 18.7375832 },
    { lat: 47.8193249, lng: 18.7383734 },
    { lat: 47.819064, lng: 18.7391039 },
    { lat: 47.8190182, lng: 18.7396371 },
    { lat: 47.8190633, lng: 18.7403093 },
    { lat: 47.8192498, lng: 18.7403156 },
    { lat: 47.8192771, lng: 18.7408671 },
    { lat: 47.8186396, lng: 18.7407516 },
    { lat: 47.8150305, lng: 18.7472538 },
    { lat: 47.8150802, lng: 18.7475339 },
    { lat: 47.8160207, lng: 18.7531615 },
    { lat: 47.8162385, lng: 18.7545226 },
    { lat: 47.8165066, lng: 18.7558639 },
    { lat: 47.8168241, lng: 18.7571814 },
    { lat: 47.8171242, lng: 18.7581373 },
    { lat: 47.817355, lng: 18.758883 },
    { lat: 47.817557, lng: 18.7596472 },
    { lat: 47.8177293, lng: 18.760427 },
    { lat: 47.8178921, lng: 18.7615353 },
    { lat: 47.8180599, lng: 18.7626812 },
    { lat: 47.8182512, lng: 18.763819 },
    { lat: 47.8184659, lng: 18.7649476 },
    { lat: 47.8187384, lng: 18.766304 },
    { lat: 47.8190694, lng: 18.7678648 },
    { lat: 47.8194175, lng: 18.7694172 },
    { lat: 47.8195417, lng: 18.7700293 },
    { lat: 47.819654, lng: 18.7706465 },
    { lat: 47.8197543, lng: 18.7712681 },
    { lat: 47.8199254, lng: 18.7722842 },
    { lat: 47.8200291, lng: 18.772996 },
    { lat: 47.8201114, lng: 18.7737137 },
    { lat: 47.8202395, lng: 18.7749861 },
    { lat: 47.8203719, lng: 18.7762574 },
    { lat: 47.8205086, lng: 18.7775278 },
    { lat: 47.8206495, lng: 18.7787971 },
    { lat: 47.8207947, lng: 18.7800655 },
    { lat: 47.8209442, lng: 18.7813326 },
    { lat: 47.8230462, lng: 18.7810695 },
    { lat: 47.8235572, lng: 18.7808403 },
    { lat: 47.8239138, lng: 18.7807608 },
    { lat: 47.8244278, lng: 18.7802059 },
    { lat: 47.8248276, lng: 18.7794792 },
    { lat: 47.8253846, lng: 18.7792217 },
    { lat: 47.8255171, lng: 18.779083 },
    { lat: 47.8255595, lng: 18.7789474 },
    { lat: 47.8255604, lng: 18.7785213 },
    { lat: 47.8257735, lng: 18.7782868 },
    { lat: 47.8259014, lng: 18.7780208 },
    { lat: 47.8259755, lng: 18.7776569 },
    { lat: 47.8261941, lng: 18.7770196 },
    { lat: 47.826615, lng: 18.7765595 },
    { lat: 47.8267646, lng: 18.7767236 },
    { lat: 47.8276867, lng: 18.7769888 },
    { lat: 47.8285797, lng: 18.7776607 },
    { lat: 47.8291912, lng: 18.7780049 },
    { lat: 47.8300896, lng: 18.7795617 },
    { lat: 47.8307198, lng: 18.78038 },
    { lat: 47.8316035, lng: 18.7812201 },
    { lat: 47.8323684, lng: 18.7818384 },
    { lat: 47.8329823, lng: 18.7825653 },
    { lat: 47.833317, lng: 18.7831286 },
    { lat: 47.8333541, lng: 18.7831919 },
    { lat: 47.8373317, lng: 18.765135 },
    { lat: 47.839861, lng: 18.7538951 },
    { lat: 47.843377, lng: 18.7390642 },
    { lat: 47.8431606, lng: 18.7377664 },
    { lat: 47.8428948, lng: 18.7372046 },
    { lat: 47.8428328, lng: 18.736407 },
    { lat: 47.8426135, lng: 18.7359584 },
    { lat: 47.8426324, lng: 18.7354924 },
    { lat: 47.8427129, lng: 18.7351606 },
    { lat: 47.8426438, lng: 18.7347152 },
    { lat: 47.8427534, lng: 18.7342382 },
    { lat: 47.8427457, lng: 18.7341348 },
    { lat: 47.8426061, lng: 18.7339292 },
    { lat: 47.8425351, lng: 18.7333643 },
    { lat: 47.8425667, lng: 18.7326511 },
    { lat: 47.842644, lng: 18.7326651 },
    { lat: 47.8428066, lng: 18.7320192 },
    { lat: 47.8433139, lng: 18.7309448 },
    { lat: 47.8437646, lng: 18.7304304 },
    { lat: 47.8443336, lng: 18.7294147 },
    { lat: 47.8456005, lng: 18.7295045 },
    { lat: 47.8463843, lng: 18.7297812 },
    { lat: 47.8469345, lng: 18.7297766 },
    { lat: 47.8486547, lng: 18.7302013 },
    { lat: 47.8498099, lng: 18.7302289 },
    { lat: 47.8498375, lng: 18.7297133 },
    { lat: 47.8505096, lng: 18.7295247 },
    { lat: 47.8511489, lng: 18.7280739 },
    { lat: 47.851232, lng: 18.7275845 },
    { lat: 47.8517248, lng: 18.7271032 },
    { lat: 47.8514886, lng: 18.7264209 },
    { lat: 47.859005, lng: 18.7154798 },
    { lat: 47.8597383, lng: 18.7138744 },
    { lat: 47.8616333, lng: 18.7117896 },
    { lat: 47.8633699, lng: 18.709879 },
    { lat: 47.863565, lng: 18.7097718 },
    { lat: 47.8636479, lng: 18.7097578 },
    { lat: 47.864149, lng: 18.7096732 },
    { lat: 47.8642702, lng: 18.7096527 },
    { lat: 47.8646075, lng: 18.7093431 },
    { lat: 47.8650548, lng: 18.7087672 },
    { lat: 47.8653054, lng: 18.7083528 },
    { lat: 47.8656941, lng: 18.7073653 },
    { lat: 47.865726, lng: 18.7073096 },
    { lat: 47.8661269, lng: 18.7066102 },
    { lat: 47.866575, lng: 18.7060709 },
    { lat: 47.8672724, lng: 18.7055363 },
    { lat: 47.8675096, lng: 18.7050391 },
    { lat: 47.8689967, lng: 18.7050712 },
    { lat: 47.8693831, lng: 18.7023258 },
    { lat: 47.8692247, lng: 18.7011888 },
    { lat: 47.8692167, lng: 18.6991417 },
  ],
  Jatov: [
    { lat: 48.131467, lng: 17.961021 },
    { lat: 48.131032, lng: 17.961326 },
    { lat: 48.130271, lng: 17.96187 },
    { lat: 48.12945, lng: 17.962417 },
    { lat: 48.128156, lng: 17.963399 },
    { lat: 48.128087, lng: 17.963475 },
    { lat: 48.127676, lng: 17.964212 },
    { lat: 48.127335, lng: 17.965161 },
    { lat: 48.126718, lng: 17.967102 },
    { lat: 48.12656, lng: 17.968027 },
    { lat: 48.126263, lng: 17.969169 },
    { lat: 48.126179, lng: 17.969625 },
    { lat: 48.126061, lng: 17.970065 },
    { lat: 48.125764, lng: 17.971008 },
    { lat: 48.125735, lng: 17.971092 },
    { lat: 48.125531, lng: 17.971754 },
    { lat: 48.126854, lng: 17.973047 },
    { lat: 48.126888, lng: 17.97308 },
    { lat: 48.12723, lng: 17.973444 },
    { lat: 48.126828, lng: 17.974773 },
    { lat: 48.126629, lng: 17.975438 },
    { lat: 48.126462, lng: 17.97611 },
    { lat: 48.126998, lng: 17.977377 },
    { lat: 48.126564, lng: 17.979109 },
    { lat: 48.12593, lng: 17.980599 },
    { lat: 48.125289, lng: 17.982142 },
    { lat: 48.124696, lng: 17.983881 },
    { lat: 48.124261, lng: 17.984889 },
    { lat: 48.124063, lng: 17.985439 },
    { lat: 48.12355, lng: 17.986501 },
    { lat: 48.122247, lng: 17.989225 },
    { lat: 48.121681, lng: 17.990359 },
    { lat: 48.120653, lng: 17.992489 },
    { lat: 48.120116, lng: 17.99379 },
    { lat: 48.119693, lng: 17.994768 },
    { lat: 48.11927, lng: 17.995806 },
    { lat: 48.11884, lng: 17.99667 },
    { lat: 48.118818, lng: 17.996717 },
    { lat: 48.118364, lng: 17.997645 },
    { lat: 48.118008, lng: 17.998156 },
    { lat: 48.117209, lng: 17.999396 },
    { lat: 48.116679, lng: 18.00017 },
    { lat: 48.116658, lng: 18.000192 },
    { lat: 48.116566, lng: 18.000278 },
    { lat: 48.116531, lng: 18.000336 },
    { lat: 48.116042, lng: 18.001154 },
    { lat: 48.115592, lng: 18.001795 },
    { lat: 48.115497, lng: 18.001935 },
    { lat: 48.115333, lng: 18.002174 },
    { lat: 48.114757, lng: 18.003073 },
    { lat: 48.114542, lng: 18.003355 },
    { lat: 48.114475, lng: 18.003443 },
    { lat: 48.113873, lng: 18.004233 },
    { lat: 48.1154861, lng: 18.012506 },
    { lat: 48.1169972, lng: 18.0175978 },
    { lat: 48.1190636, lng: 18.020937 },
    { lat: 48.1217674, lng: 18.0262932 },
    { lat: 48.1227157, lng: 18.0280337 },
    { lat: 48.1241806, lng: 18.033764 },
    { lat: 48.1268296, lng: 18.0382519 },
    { lat: 48.128226, lng: 18.0399406 },
    { lat: 48.1296326, lng: 18.0381836 },
    { lat: 48.1315534, lng: 18.0368209 },
    { lat: 48.1318342, lng: 18.0364765 },
    { lat: 48.1330246, lng: 18.036339 },
    { lat: 48.1356256, lng: 18.0334493 },
    { lat: 48.1360203, lng: 18.0331244 },
    { lat: 48.1370321, lng: 18.0326215 },
    { lat: 48.1379373, lng: 18.0324724 },
    { lat: 48.1383087, lng: 18.0326795 },
    { lat: 48.1390151, lng: 18.0328296 },
    { lat: 48.1405954, lng: 18.0336298 },
    { lat: 48.1421561, lng: 18.0362359 },
    { lat: 48.142721, lng: 18.036926 },
    { lat: 48.143131, lng: 18.036507 },
    { lat: 48.143558, lng: 18.03609 },
    { lat: 48.144061, lng: 18.035587 },
    { lat: 48.144541, lng: 18.035118 },
    { lat: 48.144627, lng: 18.035047 },
    { lat: 48.145279, lng: 18.034463 },
    { lat: 48.145877, lng: 18.033924 },
    { lat: 48.146429, lng: 18.033414 },
    { lat: 48.147203, lng: 18.032705 },
    { lat: 48.147569, lng: 18.032329 },
    { lat: 48.147781, lng: 18.032111 },
    { lat: 48.148341, lng: 18.031534 },
    { lat: 48.148802, lng: 18.031139 },
    { lat: 48.149527, lng: 18.030516 },
    { lat: 48.150327, lng: 18.029817 },
    { lat: 48.150946, lng: 18.029286 },
    { lat: 48.151533, lng: 18.028805 },
    { lat: 48.152052, lng: 18.028385 },
    { lat: 48.152355, lng: 18.028169 },
    { lat: 48.152763, lng: 18.027864 },
    { lat: 48.153231, lng: 18.027521 },
    { lat: 48.153357, lng: 18.027424 },
    { lat: 48.153728, lng: 18.027136 },
    { lat: 48.154108, lng: 18.026842 },
    { lat: 48.154149, lng: 18.02681 },
    { lat: 48.154111, lng: 18.026568 },
    { lat: 48.154066, lng: 18.026252 },
    { lat: 48.153892, lng: 18.02514 },
    { lat: 48.153744, lng: 18.024197 },
    { lat: 48.153575, lng: 18.023115 },
    { lat: 48.153562, lng: 18.022912 },
    { lat: 48.153514, lng: 18.022345 },
    { lat: 48.153466, lng: 18.021761 },
    { lat: 48.15338, lng: 18.020749 },
    { lat: 48.153284, lng: 18.019585 },
    { lat: 48.153201, lng: 18.018648 },
    { lat: 48.153347, lng: 18.018465 },
    { lat: 48.1538, lng: 18.017914 },
    { lat: 48.153933, lng: 18.017761 },
    { lat: 48.154256, lng: 18.017374 },
    { lat: 48.154339, lng: 18.01727 },
    { lat: 48.154613, lng: 18.017033 },
    { lat: 48.154935, lng: 18.016769 },
    { lat: 48.155178, lng: 18.016502 },
    { lat: 48.155396, lng: 18.016277 },
    { lat: 48.155686, lng: 18.015815 },
    { lat: 48.155918, lng: 18.015448 },
    { lat: 48.156183, lng: 18.015006 },
    { lat: 48.156397, lng: 18.014638 },
    { lat: 48.156533, lng: 18.014436 },
    { lat: 48.15678, lng: 18.014075 },
    { lat: 48.157156, lng: 18.013609 },
    { lat: 48.157402, lng: 18.013297 },
    { lat: 48.15747, lng: 18.013209 },
    { lat: 48.157592, lng: 18.013065 },
    { lat: 48.15807, lng: 18.012465 },
    { lat: 48.158397, lng: 18.012043 },
    { lat: 48.158767, lng: 18.011564 },
    { lat: 48.159043, lng: 18.011214 },
    { lat: 48.159449, lng: 18.0107 },
    { lat: 48.159964, lng: 18.010073 },
    { lat: 48.160254, lng: 18.009722 },
    { lat: 48.160605, lng: 18.009307 },
    { lat: 48.16111, lng: 18.008717 },
    { lat: 48.161356, lng: 18.008423 },
    { lat: 48.161546, lng: 18.008198 },
    { lat: 48.16214, lng: 18.00747 },
    { lat: 48.162362, lng: 18.007092 },
    { lat: 48.162836, lng: 18.006282 },
    { lat: 48.163206, lng: 18.005625 },
    { lat: 48.163591, lng: 18.004919 },
    { lat: 48.163896, lng: 18.004366 },
    { lat: 48.164249, lng: 18.003796 },
    { lat: 48.16436, lng: 18.003665 },
    { lat: 48.164433, lng: 18.003561 },
    { lat: 48.164466, lng: 18.003524 },
    { lat: 48.164754, lng: 18.003175 },
    { lat: 48.164941, lng: 18.002829 },
    { lat: 48.165185, lng: 18.002395 },
    { lat: 48.165378, lng: 18.002122 },
    { lat: 48.165545, lng: 18.001913 },
    { lat: 48.165749, lng: 18.001626 },
    { lat: 48.165946, lng: 18.001168 },
    { lat: 48.165356, lng: 18.000525 },
    { lat: 48.165083, lng: 18.000206 },
    { lat: 48.164406, lng: 17.999449 },
    { lat: 48.164243, lng: 17.999253 },
    { lat: 48.163909, lng: 17.998879 },
    { lat: 48.163434, lng: 17.998334 },
    { lat: 48.161157, lng: 17.995742 },
    { lat: 48.160675, lng: 17.995189 },
    { lat: 48.159266, lng: 17.993578 },
    { lat: 48.15883, lng: 17.993078 },
    { lat: 48.158741, lng: 17.992977 },
    { lat: 48.156249, lng: 17.990154 },
    { lat: 48.156059, lng: 17.989941 },
    { lat: 48.155228, lng: 17.988997 },
    { lat: 48.152219, lng: 17.985578 },
    { lat: 48.151522, lng: 17.984783 },
    { lat: 48.150869, lng: 17.984044 },
    { lat: 48.150381, lng: 17.983483 },
    { lat: 48.150353, lng: 17.983451 },
    { lat: 48.15034, lng: 17.983436 },
    { lat: 48.150317, lng: 17.98341 },
    { lat: 48.150301, lng: 17.983391 },
    { lat: 48.150251, lng: 17.983334 },
    { lat: 48.150165, lng: 17.983236 },
    { lat: 48.150102, lng: 17.983164 },
    { lat: 48.150085, lng: 17.983145 },
    { lat: 48.150048, lng: 17.983103 },
    { lat: 48.150027, lng: 17.98308 },
    { lat: 48.149951, lng: 17.982993 },
    { lat: 48.149939, lng: 17.982978 },
    { lat: 48.149863, lng: 17.982892 },
    { lat: 48.149849, lng: 17.982876 },
    { lat: 48.147946, lng: 17.980717 },
    { lat: 48.147786, lng: 17.980535 },
    { lat: 48.146965, lng: 17.979609 },
    { lat: 48.144842, lng: 17.977159 },
    { lat: 48.144578, lng: 17.976852 },
    { lat: 48.144537, lng: 17.976805 },
    { lat: 48.14446, lng: 17.976716 },
    { lat: 48.144423, lng: 17.976671 },
    { lat: 48.143571, lng: 17.975681 },
    { lat: 48.143448, lng: 17.97553 },
    { lat: 48.143278, lng: 17.975336 },
    { lat: 48.14326, lng: 17.975315 },
    { lat: 48.143113, lng: 17.975143 },
    { lat: 48.142207, lng: 17.9741 },
    { lat: 48.14103, lng: 17.972658 },
    { lat: 48.140325, lng: 17.971791 },
    { lat: 48.140288, lng: 17.971745 },
    { lat: 48.140049, lng: 17.971456 },
    { lat: 48.140003, lng: 17.971402 },
    { lat: 48.139917, lng: 17.971301 },
    { lat: 48.139872, lng: 17.971246 },
    { lat: 48.13968, lng: 17.9710143 },
    { lat: 48.139408, lng: 17.970686 },
    { lat: 48.137964, lng: 17.968925 },
    { lat: 48.137069, lng: 17.96784 },
    { lat: 48.136826, lng: 17.967542 },
    { lat: 48.136775, lng: 17.967487 },
    { lat: 48.136719, lng: 17.967416 },
    { lat: 48.136321, lng: 17.966922 },
    { lat: 48.136126, lng: 17.966681 },
    { lat: 48.135903, lng: 17.966412 },
    { lat: 48.135528, lng: 17.965955 },
    { lat: 48.131467, lng: 17.961021 },
  ],
  Bardoňovo: [
    { lat: 48.106306, lng: 18.459213 },
    { lat: 48.106331, lng: 18.459166 },
    { lat: 48.106397, lng: 18.459053 },
    { lat: 48.106476, lng: 18.458926 },
    { lat: 48.106603, lng: 18.458632 },
    { lat: 48.106736, lng: 18.458463 },
    { lat: 48.107006, lng: 18.458118 },
    { lat: 48.107194, lng: 18.457959 },
    { lat: 48.107318, lng: 18.457883 },
    { lat: 48.107472, lng: 18.457863 },
    { lat: 48.107944, lng: 18.45773 },
    { lat: 48.108183, lng: 18.457607 },
    { lat: 48.108397, lng: 18.457537 },
    { lat: 48.108906, lng: 18.457515 },
    { lat: 48.109111, lng: 18.457453 },
    { lat: 48.109156, lng: 18.457435 },
    { lat: 48.109239, lng: 18.457371 },
    { lat: 48.109328, lng: 18.457316 },
    { lat: 48.109504, lng: 18.457221 },
    { lat: 48.109776, lng: 18.457162 },
    { lat: 48.110024, lng: 18.457119 },
    { lat: 48.110089, lng: 18.457104 },
    { lat: 48.110314, lng: 18.457041 },
    { lat: 48.110469, lng: 18.457006 },
    { lat: 48.110596, lng: 18.45697 },
    { lat: 48.110844, lng: 18.456865 },
    { lat: 48.11104, lng: 18.45674 },
    { lat: 48.111251, lng: 18.456574 },
    { lat: 48.111845, lng: 18.456213 },
    { lat: 48.112004, lng: 18.456074 },
    { lat: 48.112159, lng: 18.455958 },
    { lat: 48.112298, lng: 18.455862 },
    { lat: 48.112367, lng: 18.455814 },
    { lat: 48.112506, lng: 18.455658 },
    { lat: 48.112778, lng: 18.455429 },
    { lat: 48.112952, lng: 18.455324 },
    { lat: 48.113005, lng: 18.455302 },
    { lat: 48.113126, lng: 18.455228 },
    { lat: 48.113336, lng: 18.455138 },
    { lat: 48.113737, lng: 18.454934 },
    { lat: 48.113914, lng: 18.454852 },
    { lat: 48.114166, lng: 18.454741 },
    { lat: 48.1144, lng: 18.454648 },
    { lat: 48.114466, lng: 18.454618 },
    { lat: 48.11455, lng: 18.454589 },
    { lat: 48.114716, lng: 18.45451 },
    { lat: 48.114907, lng: 18.454386 },
    { lat: 48.115025, lng: 18.454327 },
    { lat: 48.115074, lng: 18.454294 },
    { lat: 48.115099, lng: 18.454271 },
    { lat: 48.115269, lng: 18.454162 },
    { lat: 48.115334, lng: 18.454113 },
    { lat: 48.115352, lng: 18.454104 },
    { lat: 48.115407, lng: 18.454058 },
    { lat: 48.115592, lng: 18.453882 },
    { lat: 48.115814, lng: 18.453695 },
    { lat: 48.115854, lng: 18.453648 },
    { lat: 48.11593, lng: 18.453574 },
    { lat: 48.116098, lng: 18.453445 },
    { lat: 48.11622, lng: 18.453354 },
    { lat: 48.116344, lng: 18.453273 },
    { lat: 48.116459, lng: 18.453212 },
    { lat: 48.116587, lng: 18.45317 },
    { lat: 48.116872, lng: 18.453091 },
    { lat: 48.116992, lng: 18.453051 },
    { lat: 48.117005, lng: 18.453047 },
    { lat: 48.117017, lng: 18.453043 },
    { lat: 48.117029, lng: 18.453039 },
    { lat: 48.117193, lng: 18.452989 },
    { lat: 48.117357, lng: 18.452928 },
    { lat: 48.117396, lng: 18.452907 },
    { lat: 48.117498, lng: 18.452866 },
    { lat: 48.117832, lng: 18.452751 },
    { lat: 48.117888, lng: 18.452721 },
    { lat: 48.11793, lng: 18.452705 },
    { lat: 48.118022, lng: 18.452654 },
    { lat: 48.118081, lng: 18.452628 },
    { lat: 48.118252, lng: 18.452544 },
    { lat: 48.118324, lng: 18.452516 },
    { lat: 48.118426, lng: 18.452484 },
    { lat: 48.118484, lng: 18.452451 },
    { lat: 48.118647, lng: 18.452342 },
    { lat: 48.119064, lng: 18.452033 },
    { lat: 48.119235, lng: 18.451963 },
    { lat: 48.11941, lng: 18.451886 },
    { lat: 48.119531, lng: 18.45183 },
    { lat: 48.119736, lng: 18.451715 },
    { lat: 48.119787, lng: 18.451682 },
    { lat: 48.119967, lng: 18.451528 },
    { lat: 48.120107, lng: 18.451426 },
    { lat: 48.120298, lng: 18.4513 },
    { lat: 48.120472, lng: 18.451207 },
    { lat: 48.120675, lng: 18.451043 },
    { lat: 48.120795, lng: 18.450972 },
    { lat: 48.121023, lng: 18.450906 },
    { lat: 48.121285, lng: 18.450862 },
    { lat: 48.121523, lng: 18.450791 },
    { lat: 48.121914, lng: 18.45048 },
    { lat: 48.122056, lng: 18.450384 },
    { lat: 48.122231, lng: 18.450304 },
    { lat: 48.12238, lng: 18.450183 },
    { lat: 48.122621, lng: 18.450084 },
    { lat: 48.122762, lng: 18.450092 },
    { lat: 48.122772, lng: 18.450082 },
    { lat: 48.122868, lng: 18.449996 },
    { lat: 48.122885, lng: 18.449982 },
    { lat: 48.122888, lng: 18.44998 },
    { lat: 48.123084, lng: 18.449918 },
    { lat: 48.123782, lng: 18.449525 },
    { lat: 48.124003, lng: 18.449368 },
    { lat: 48.124657, lng: 18.448883 },
    { lat: 48.12492, lng: 18.448678 },
    { lat: 48.125213, lng: 18.448467 },
    { lat: 48.125522, lng: 18.448281 },
    { lat: 48.125831, lng: 18.448033 },
    { lat: 48.125991, lng: 18.447889 },
    { lat: 48.126143, lng: 18.447733 },
    { lat: 48.126351, lng: 18.447476 },
    { lat: 48.126819, lng: 18.446846 },
    { lat: 48.127017, lng: 18.446577 },
    { lat: 48.127179, lng: 18.446365 },
    { lat: 48.127677, lng: 18.445707 },
    { lat: 48.127802, lng: 18.445477 },
    { lat: 48.127915, lng: 18.44521 },
    { lat: 48.12793, lng: 18.445176 },
    { lat: 48.12805, lng: 18.444819 },
    { lat: 48.128159, lng: 18.444521 },
    { lat: 48.128272, lng: 18.444209 },
    { lat: 48.128493, lng: 18.443565 },
    { lat: 48.128605, lng: 18.443247 },
    { lat: 48.128828, lng: 18.442807 },
    { lat: 48.128974, lng: 18.442459 },
    { lat: 48.129111, lng: 18.442173 },
    { lat: 48.129138, lng: 18.442124 },
    { lat: 48.129159, lng: 18.442084 },
    { lat: 48.129275, lng: 18.44187 },
    { lat: 48.12957, lng: 18.441343 },
    { lat: 48.129863, lng: 18.440912 },
    { lat: 48.130691, lng: 18.439854 },
    { lat: 48.130835, lng: 18.439735 },
    { lat: 48.131058, lng: 18.439575 },
    { lat: 48.13116, lng: 18.439476 },
    { lat: 48.131465, lng: 18.439079 },
    { lat: 48.131569, lng: 18.438983 },
    { lat: 48.131832, lng: 18.438742 },
    { lat: 48.13223, lng: 18.438334 },
    { lat: 48.132407, lng: 18.43816 },
    { lat: 48.132512, lng: 18.438023 },
    { lat: 48.132544, lng: 18.437968 },
    { lat: 48.132598, lng: 18.437857 },
    { lat: 48.132661, lng: 18.437706 },
    { lat: 48.132693, lng: 18.437632 },
    { lat: 48.132772, lng: 18.437421 },
    { lat: 48.132779, lng: 18.437403 },
    { lat: 48.13287, lng: 18.437102 },
    { lat: 48.132889, lng: 18.437055 },
    { lat: 48.132991, lng: 18.436797 },
    { lat: 48.133022, lng: 18.436707 },
    { lat: 48.133096, lng: 18.436493 },
    { lat: 48.133149, lng: 18.436342 },
    { lat: 48.133221, lng: 18.436133 },
    { lat: 48.133292, lng: 18.43593 },
    { lat: 48.1333, lng: 18.435907 },
    { lat: 48.133549, lng: 18.435474 },
    { lat: 48.133638, lng: 18.435331 },
    { lat: 48.133742, lng: 18.435104 },
    { lat: 48.13388, lng: 18.434704 },
    { lat: 48.133995, lng: 18.434349 },
    { lat: 48.134053, lng: 18.434083 },
    { lat: 48.134134, lng: 18.433623 },
    { lat: 48.134292, lng: 18.432916 },
    { lat: 48.134448, lng: 18.432062 },
    { lat: 48.134512, lng: 18.431744 },
    { lat: 48.134516, lng: 18.431725 },
    { lat: 48.134598, lng: 18.431323 },
    { lat: 48.134777, lng: 18.430344 },
    { lat: 48.13491, lng: 18.429648 },
    { lat: 48.135223, lng: 18.428313 },
    { lat: 48.13556, lng: 18.427244 },
    { lat: 48.135671, lng: 18.426803 },
    { lat: 48.135784, lng: 18.426269 },
    { lat: 48.135803, lng: 18.426037 },
    { lat: 48.135886, lng: 18.425165 },
    { lat: 48.135903, lng: 18.424982 },
    { lat: 48.135927, lng: 18.424636 },
    { lat: 48.136012, lng: 18.424031 },
    { lat: 48.136047, lng: 18.423754 },
    { lat: 48.136171, lng: 18.423039 },
    { lat: 48.13628, lng: 18.421919 },
    { lat: 48.136313, lng: 18.421692 },
    { lat: 48.136363, lng: 18.421299 },
    { lat: 48.136364, lng: 18.421262 },
    { lat: 48.136414, lng: 18.421062 },
    { lat: 48.136647, lng: 18.420087 },
    { lat: 48.136652, lng: 18.420068 },
    { lat: 48.136778, lng: 18.419078 },
    { lat: 48.136784, lng: 18.419002 },
    { lat: 48.136768, lng: 18.418343 },
    { lat: 48.136766, lng: 18.418002 },
    { lat: 48.136704, lng: 18.417162 },
    { lat: 48.136674, lng: 18.416785 },
    { lat: 48.136758, lng: 18.416414 },
    { lat: 48.137143, lng: 18.415681 },
    { lat: 48.137168, lng: 18.415125 },
    { lat: 48.137175, lng: 18.415032 },
    { lat: 48.137207, lng: 18.414867 },
    { lat: 48.137309, lng: 18.41438 },
    { lat: 48.137391, lng: 18.413865 },
    { lat: 48.137493, lng: 18.412715 },
    { lat: 48.1375, lng: 18.412595 },
    { lat: 48.137511, lng: 18.412317 },
    { lat: 48.137475, lng: 18.411884 },
    { lat: 48.137293, lng: 18.410649 },
    { lat: 48.137342, lng: 18.410059 },
    { lat: 48.137361, lng: 18.409646 },
    { lat: 48.137278, lng: 18.408786 },
    { lat: 48.137223, lng: 18.40821 },
    { lat: 48.137204, lng: 18.407989 },
    { lat: 48.137181, lng: 18.40778 },
    { lat: 48.137165, lng: 18.407591 },
    { lat: 48.13752, lng: 18.406995 },
    { lat: 48.137463, lng: 18.406801 },
    { lat: 48.137381, lng: 18.406536 },
    { lat: 48.137383, lng: 18.406502 },
    { lat: 48.137396, lng: 18.406488 },
    { lat: 48.137592, lng: 18.405873 },
    { lat: 48.137603, lng: 18.405852 },
    { lat: 48.137768, lng: 18.405625 },
    { lat: 48.137968, lng: 18.404615 },
    { lat: 48.13798, lng: 18.404582 },
    { lat: 48.138097, lng: 18.40378 },
    { lat: 48.138138, lng: 18.403418 },
    { lat: 48.138236, lng: 18.402898 },
    { lat: 48.138248, lng: 18.402825 },
    { lat: 48.138265, lng: 18.402754 },
    { lat: 48.138295, lng: 18.402602 },
    { lat: 48.13833, lng: 18.402454 },
    { lat: 48.138375, lng: 18.402254 },
    { lat: 48.138437, lng: 18.401969 },
    { lat: 48.138637, lng: 18.401067 },
    { lat: 48.138645, lng: 18.401027 },
    { lat: 48.138651, lng: 18.400999 },
    { lat: 48.138656, lng: 18.400974 },
    { lat: 48.13868, lng: 18.400845 },
    { lat: 48.138683, lng: 18.400827 },
    { lat: 48.138398, lng: 18.400508 },
    { lat: 48.138048, lng: 18.39998 },
    { lat: 48.137898, lng: 18.399751 },
    { lat: 48.137794, lng: 18.399617 },
    { lat: 48.13759, lng: 18.399341 },
    { lat: 48.137533, lng: 18.399292 },
    { lat: 48.136616, lng: 18.39884 },
    { lat: 48.136841, lng: 18.397816 },
    { lat: 48.136906, lng: 18.397504 },
    { lat: 48.136935, lng: 18.397386 },
    { lat: 48.137092, lng: 18.396647 },
    { lat: 48.13715, lng: 18.396375 },
    { lat: 48.137172, lng: 18.396283 },
    { lat: 48.137206, lng: 18.396155 },
    { lat: 48.137345, lng: 18.396221 },
    { lat: 48.138684, lng: 18.396896 },
    { lat: 48.138895, lng: 18.396379 },
    { lat: 48.138908, lng: 18.396369 },
    { lat: 48.139046, lng: 18.395796 },
    { lat: 48.139241, lng: 18.394973 },
    { lat: 48.139264, lng: 18.394894 },
    { lat: 48.139665, lng: 18.395135 },
    { lat: 48.139681, lng: 18.395143 },
    { lat: 48.139784, lng: 18.394849 },
    { lat: 48.139824, lng: 18.394744 },
    { lat: 48.139845, lng: 18.394676 },
    { lat: 48.139893, lng: 18.394491 },
    { lat: 48.14019, lng: 18.393412 },
    { lat: 48.140284, lng: 18.393086 },
    { lat: 48.140301, lng: 18.393096 },
    { lat: 48.140309, lng: 18.393071 },
    { lat: 48.14067, lng: 18.392031 },
    { lat: 48.140929, lng: 18.391326 },
    { lat: 48.141133, lng: 18.390829 },
    { lat: 48.141447, lng: 18.389947 },
    { lat: 48.141715, lng: 18.389132 },
    { lat: 48.141845, lng: 18.388467 },
    { lat: 48.141902, lng: 18.388108 },
    { lat: 48.141959, lng: 18.387708 },
    { lat: 48.141958, lng: 18.387689 },
    { lat: 48.141938, lng: 18.387336 },
    { lat: 48.141864, lng: 18.387263 },
    { lat: 48.141614, lng: 18.386987 },
    { lat: 48.1415216, lng: 18.3869076 },
    { lat: 48.1409596, lng: 18.3855892 },
    { lat: 48.1403377, lng: 18.383767 },
    { lat: 48.139477, lng: 18.3798772 },
    { lat: 48.1384198, lng: 18.3771681 },
    { lat: 48.1380937, lng: 18.3771238 },
    { lat: 48.1368266, lng: 18.3772856 },
    { lat: 48.1351198, lng: 18.3778851 },
    { lat: 48.1344473, lng: 18.3784207 },
    { lat: 48.133984, lng: 18.3789069 },
    { lat: 48.1323792, lng: 18.380892 },
    { lat: 48.1320122, lng: 18.3810651 },
    { lat: 48.1312055, lng: 18.3812062 },
    { lat: 48.1298771, lng: 18.3811362 },
    { lat: 48.1285345, lng: 18.3811951 },
    { lat: 48.1280118, lng: 18.3813508 },
    { lat: 48.1277699, lng: 18.3815197 },
    { lat: 48.1252145, lng: 18.3850326 },
    { lat: 48.1246056, lng: 18.3857948 },
    { lat: 48.1220205, lng: 18.3899201 },
    { lat: 48.1197206, lng: 18.3926638 },
    { lat: 48.1184549, lng: 18.3938874 },
    { lat: 48.1178778, lng: 18.3945578 },
    { lat: 48.1175491, lng: 18.3952887 },
    { lat: 48.1167907, lng: 18.3974036 },
    { lat: 48.1160389, lng: 18.3991124 },
    { lat: 48.1155203, lng: 18.3997584 },
    { lat: 48.1141033, lng: 18.4021199 },
    { lat: 48.113191, lng: 18.404069 },
    { lat: 48.1130498, lng: 18.4044821 },
    { lat: 48.1127515, lng: 18.4058763 },
    { lat: 48.1119379, lng: 18.4065741 },
    { lat: 48.1112003, lng: 18.4069188 },
    { lat: 48.1109634, lng: 18.4067375 },
    { lat: 48.1108374, lng: 18.4067459 },
    { lat: 48.110327, lng: 18.4067966 },
    { lat: 48.1096891, lng: 18.407047 },
    { lat: 48.108885, lng: 18.4075165 },
    { lat: 48.1080903, lng: 18.4083277 },
    { lat: 48.1076271, lng: 18.4085355 },
    { lat: 48.1065739, lng: 18.4084278 },
    { lat: 48.1063274, lng: 18.4083352 },
    { lat: 48.105615, lng: 18.4077477 },
    { lat: 48.1054185, lng: 18.4075038 },
    { lat: 48.1049253, lng: 18.4065372 },
    { lat: 48.1045398, lng: 18.4059648 },
    { lat: 48.1043366, lng: 18.4057828 },
    { lat: 48.1038006, lng: 18.4055593 },
    { lat: 48.1030269, lng: 18.4046841 },
    { lat: 48.1018334, lng: 18.4031647 },
    { lat: 48.1011461, lng: 18.4020617 },
    { lat: 48.100452, lng: 18.4004365 },
    { lat: 48.1004153, lng: 18.4004648 },
    { lat: 48.0998095, lng: 18.3996876 },
    { lat: 48.0994455, lng: 18.3991157 },
    { lat: 48.099094, lng: 18.3992835 },
    { lat: 48.0989339, lng: 18.3992881 },
    { lat: 48.098726, lng: 18.3994399 },
    { lat: 48.0983236, lng: 18.4001854 },
    { lat: 48.098184, lng: 18.4003307 },
    { lat: 48.0966265, lng: 18.4012014 },
    { lat: 48.0964236, lng: 18.4014425 },
    { lat: 48.0962412, lng: 18.4018688 },
    { lat: 48.095918, lng: 18.4024215 },
    { lat: 48.0957223, lng: 18.4025527 },
    { lat: 48.0950709, lng: 18.4027063 },
    { lat: 48.094771, lng: 18.4026272 },
    { lat: 48.0946286, lng: 18.4027015 },
    { lat: 48.0930118, lng: 18.40372 },
    { lat: 48.0921206, lng: 18.4044001 },
    { lat: 48.0913721, lng: 18.4051138 },
    { lat: 48.0906486, lng: 18.4059295 },
    { lat: 48.0902285, lng: 18.4062396 },
    { lat: 48.0897976, lng: 18.4064797 },
    { lat: 48.0888795, lng: 18.4068466 },
    { lat: 48.0884051, lng: 18.4072756 },
    { lat: 48.0877224, lng: 18.4076539 },
    { lat: 48.0886315, lng: 18.4126547 },
    { lat: 48.0892087, lng: 18.4170734 },
    { lat: 48.0895866, lng: 18.4190168 },
    { lat: 48.0886688, lng: 18.4200102 },
    { lat: 48.0876644, lng: 18.4205845 },
    { lat: 48.0871582, lng: 18.4207923 },
    { lat: 48.087138, lng: 18.420856 },
    { lat: 48.087118, lng: 18.420898 },
    { lat: 48.087086, lng: 18.42097 },
    { lat: 48.087036, lng: 18.421078 },
    { lat: 48.087001, lng: 18.421154 },
    { lat: 48.086976, lng: 18.42121 },
    { lat: 48.086943, lng: 18.421283 },
    { lat: 48.086904, lng: 18.421368 },
    { lat: 48.086865, lng: 18.421454 },
    { lat: 48.086737, lng: 18.421733 },
    { lat: 48.086661, lng: 18.421899 },
    { lat: 48.086612, lng: 18.422032 },
    { lat: 48.086572, lng: 18.42214 },
    { lat: 48.086527, lng: 18.422259 },
    { lat: 48.086484, lng: 18.422377 },
    { lat: 48.086366, lng: 18.422694 },
    { lat: 48.086354, lng: 18.422742 },
    { lat: 48.086303, lng: 18.422963 },
    { lat: 48.086267, lng: 18.42312 },
    { lat: 48.086225, lng: 18.423302 },
    { lat: 48.086129, lng: 18.423717 },
    { lat: 48.08611, lng: 18.423955 },
    { lat: 48.086025, lng: 18.424973 },
    { lat: 48.085963, lng: 18.425714 },
    { lat: 48.085914, lng: 18.425825 },
    { lat: 48.08577, lng: 18.426144 },
    { lat: 48.08568, lng: 18.426346 },
    { lat: 48.085621, lng: 18.42657 },
    { lat: 48.085365, lng: 18.427545 },
    { lat: 48.084993, lng: 18.428691 },
    { lat: 48.084738, lng: 18.429069 },
    { lat: 48.084342, lng: 18.430092 },
    { lat: 48.083976, lng: 18.430797 },
    { lat: 48.083825, lng: 18.431195 },
    { lat: 48.083839, lng: 18.431392 },
    { lat: 48.083911, lng: 18.4324 },
    { lat: 48.083925, lng: 18.434099 },
    { lat: 48.083941, lng: 18.434695 },
    { lat: 48.083945, lng: 18.435076 },
    { lat: 48.083944, lng: 18.435085 },
    { lat: 48.083945, lng: 18.435158 },
    { lat: 48.083946, lng: 18.435172 },
    { lat: 48.083956, lng: 18.435393 },
    { lat: 48.083998, lng: 18.436311 },
    { lat: 48.083998, lng: 18.436312 },
    { lat: 48.084004, lng: 18.43643 },
    { lat: 48.084004, lng: 18.43644 },
    { lat: 48.084016, lng: 18.436723 },
    { lat: 48.084008, lng: 18.436986 },
    { lat: 48.083998, lng: 18.437345 },
    { lat: 48.083989, lng: 18.437662 },
    { lat: 48.083925, lng: 18.438277 },
    { lat: 48.083924, lng: 18.438291 },
    { lat: 48.083923, lng: 18.438352 },
    { lat: 48.083915, lng: 18.438966 },
    { lat: 48.083918, lng: 18.439118 },
    { lat: 48.083931, lng: 18.439792 },
    { lat: 48.083645, lng: 18.440573 },
    { lat: 48.083622, lng: 18.441026 },
    { lat: 48.08362, lng: 18.441059 },
    { lat: 48.083619, lng: 18.441083 },
    { lat: 48.083491, lng: 18.441626 },
    { lat: 48.083341, lng: 18.442033 },
    { lat: 48.08326, lng: 18.442362 },
    { lat: 48.083114, lng: 18.442976 },
    { lat: 48.08308, lng: 18.443439 },
    { lat: 48.083051, lng: 18.443653 },
    { lat: 48.082923, lng: 18.443874 },
    { lat: 48.08282, lng: 18.444318 },
    { lat: 48.082772, lng: 18.445336 },
    { lat: 48.082648, lng: 18.445727 },
    { lat: 48.082505, lng: 18.446414 },
    { lat: 48.082332, lng: 18.446815 },
    { lat: 48.082214, lng: 18.447348 },
    { lat: 48.08211, lng: 18.447856 },
    { lat: 48.08166, lng: 18.449107 },
    { lat: 48.081508, lng: 18.449189 },
    { lat: 48.081516, lng: 18.449228 },
    { lat: 48.081543, lng: 18.449345 },
    { lat: 48.081539, lng: 18.449722 },
    { lat: 48.081475, lng: 18.4506 },
    { lat: 48.081419, lng: 18.45121 },
    { lat: 48.081235, lng: 18.451862 },
    { lat: 48.081159, lng: 18.452131 },
    { lat: 48.081044, lng: 18.452502 },
    { lat: 48.08103, lng: 18.452549 },
    { lat: 48.081063, lng: 18.452522 },
    { lat: 48.081086, lng: 18.452504 },
    { lat: 48.081617, lng: 18.452074 },
    { lat: 48.081626, lng: 18.452066 },
    { lat: 48.081643, lng: 18.452052 },
    { lat: 48.081761, lng: 18.451957 },
    { lat: 48.081785, lng: 18.451934 },
    { lat: 48.082094, lng: 18.451639 },
    { lat: 48.082354, lng: 18.451391 },
    { lat: 48.082408, lng: 18.451364 },
    { lat: 48.082677, lng: 18.451229 },
    { lat: 48.082818, lng: 18.451157 },
    { lat: 48.082867, lng: 18.451133 },
    { lat: 48.083099, lng: 18.451016 },
    { lat: 48.083241, lng: 18.450945 },
    { lat: 48.083252, lng: 18.450937 },
    { lat: 48.083442, lng: 18.450805 },
    { lat: 48.084038, lng: 18.450274 },
    { lat: 48.084248, lng: 18.450066 },
    { lat: 48.084569, lng: 18.449813 },
    { lat: 48.084847, lng: 18.44974 },
    { lat: 48.085046, lng: 18.449644 },
    { lat: 48.085294, lng: 18.449596 },
    { lat: 48.085553, lng: 18.449592 },
    { lat: 48.085803, lng: 18.44955 },
    { lat: 48.086138, lng: 18.449541 },
    { lat: 48.086367, lng: 18.449638 },
    { lat: 48.08643, lng: 18.449721 },
    { lat: 48.08648, lng: 18.449853 },
    { lat: 48.086522, lng: 18.450031 },
    { lat: 48.086582, lng: 18.450355 },
    { lat: 48.086673, lng: 18.450608 },
    { lat: 48.086867, lng: 18.450998 },
    { lat: 48.087075, lng: 18.45119 },
    { lat: 48.087223, lng: 18.451374 },
    { lat: 48.087274, lng: 18.451519 },
    { lat: 48.087452, lng: 18.452478 },
    { lat: 48.087463, lng: 18.452682 },
    { lat: 48.087466, lng: 18.452733 },
    { lat: 48.087486, lng: 18.453067 },
    { lat: 48.087539, lng: 18.453567 },
    { lat: 48.087659, lng: 18.454479 },
    { lat: 48.087661, lng: 18.454496 },
    { lat: 48.08765, lng: 18.454499 },
    { lat: 48.087719, lng: 18.454695 },
    { lat: 48.087721, lng: 18.454697 },
    { lat: 48.087734, lng: 18.454736 },
    { lat: 48.087745, lng: 18.454764 },
    { lat: 48.087755, lng: 18.454793 },
    { lat: 48.087761, lng: 18.454809 },
    { lat: 48.088019, lng: 18.454734 },
    { lat: 48.088388, lng: 18.454777 },
    { lat: 48.088764, lng: 18.454851 },
    { lat: 48.089051, lng: 18.455002 },
    { lat: 48.089461, lng: 18.454999 },
    { lat: 48.090108, lng: 18.454834 },
    { lat: 48.090605, lng: 18.454573 },
    { lat: 48.091146, lng: 18.454343 },
    { lat: 48.091906, lng: 18.454128 },
    { lat: 48.09222, lng: 18.454056 },
    { lat: 48.092644, lng: 18.454026 },
    { lat: 48.093167, lng: 18.454047 },
    { lat: 48.093558, lng: 18.454041 },
    { lat: 48.094461, lng: 18.453984 },
    { lat: 48.094915, lng: 18.453921 },
    { lat: 48.09528, lng: 18.45385 },
    { lat: 48.095414, lng: 18.453912 },
    { lat: 48.096018, lng: 18.454382 },
    { lat: 48.096389, lng: 18.454334 },
    { lat: 48.096732, lng: 18.454254 },
    { lat: 48.097276, lng: 18.454105 },
    { lat: 48.097597, lng: 18.454003 },
    { lat: 48.097751, lng: 18.45403 },
    { lat: 48.097763, lng: 18.454032 },
    { lat: 48.097761, lng: 18.454058 },
    { lat: 48.097762, lng: 18.454064 },
    { lat: 48.097847, lng: 18.454138 },
    { lat: 48.097886, lng: 18.45417 },
    { lat: 48.097977, lng: 18.454306 },
    { lat: 48.098202, lng: 18.454728 },
    { lat: 48.098238, lng: 18.454794 },
    { lat: 48.098452, lng: 18.455139 },
    { lat: 48.098652, lng: 18.455381 },
    { lat: 48.0988, lng: 18.455639 },
    { lat: 48.098821, lng: 18.455676 },
    { lat: 48.099012, lng: 18.456062 },
    { lat: 48.099102, lng: 18.456264 },
    { lat: 48.09913, lng: 18.456326 },
    { lat: 48.099314, lng: 18.457011 },
    { lat: 48.099554, lng: 18.457732 },
    { lat: 48.099567, lng: 18.457784 },
    { lat: 48.099663, lng: 18.458152 },
    { lat: 48.099695, lng: 18.458458 },
    { lat: 48.099747, lng: 18.458783 },
    { lat: 48.09977, lng: 18.459006 },
    { lat: 48.099826, lng: 18.459477 },
    { lat: 48.09983, lng: 18.459501 },
    { lat: 48.099836, lng: 18.459541 },
    { lat: 48.09987, lng: 18.45977 },
    { lat: 48.099872, lng: 18.459868 },
    { lat: 48.099865, lng: 18.46001 },
    { lat: 48.099795, lng: 18.460266 },
    { lat: 48.099841, lng: 18.460848 },
    { lat: 48.09986, lng: 18.460902 },
    { lat: 48.099907, lng: 18.461045 },
    { lat: 48.100063, lng: 18.461506 },
    { lat: 48.100141, lng: 18.461737 },
    { lat: 48.100172, lng: 18.461828 },
    { lat: 48.100189, lng: 18.461812 },
    { lat: 48.100213, lng: 18.461784 },
    { lat: 48.10028, lng: 18.461705 },
    { lat: 48.100308, lng: 18.461673 },
    { lat: 48.100371, lng: 18.461637 },
    { lat: 48.100607, lng: 18.461523 },
    { lat: 48.100768, lng: 18.461441 },
    { lat: 48.100858, lng: 18.461387 },
    { lat: 48.101039, lng: 18.461332 },
    { lat: 48.101199, lng: 18.461195 },
    { lat: 48.101338, lng: 18.461091 },
    { lat: 48.101374, lng: 18.461083 },
    { lat: 48.101504, lng: 18.461015 },
    { lat: 48.101712, lng: 18.460886 },
    { lat: 48.101876, lng: 18.460808 },
    { lat: 48.101919, lng: 18.460801 },
    { lat: 48.101987, lng: 18.460814 },
    { lat: 48.10214, lng: 18.460786 },
    { lat: 48.10218, lng: 18.46081 },
    { lat: 48.102345, lng: 18.460821 },
    { lat: 48.102513, lng: 18.460837 },
    { lat: 48.102604, lng: 18.460784 },
    { lat: 48.102822, lng: 18.460741 },
    { lat: 48.10288, lng: 18.460732 },
    { lat: 48.10308, lng: 18.460722 },
    { lat: 48.103178, lng: 18.460722 },
    { lat: 48.103335, lng: 18.460729 },
    { lat: 48.103483, lng: 18.460656 },
    { lat: 48.103902, lng: 18.460548 },
    { lat: 48.104101, lng: 18.460489 },
    { lat: 48.10425, lng: 18.460438 },
    { lat: 48.104515, lng: 18.460324 },
    { lat: 48.104724, lng: 18.460209 },
    { lat: 48.104849, lng: 18.460127 },
    { lat: 48.104954, lng: 18.460069 },
    { lat: 48.105096, lng: 18.459985 },
    { lat: 48.105308, lng: 18.459869 },
    { lat: 48.105544, lng: 18.459791 },
    { lat: 48.105724, lng: 18.459687 },
    { lat: 48.105842, lng: 18.459637 },
    { lat: 48.105959, lng: 18.459592 },
    { lat: 48.106012, lng: 18.459549 },
    { lat: 48.106101, lng: 18.459463 },
    { lat: 48.106277, lng: 18.459228 },
    { lat: 48.106306, lng: 18.459213 },
  ],
  Rastislavice: [
    { lat: 48.1116432, lng: 18.0967376 },
    { lat: 48.1117182, lng: 18.0967464 },
    { lat: 48.1121046, lng: 18.0967912 },
    { lat: 48.1123206, lng: 18.0968962 },
    { lat: 48.1128609, lng: 18.0973303 },
    { lat: 48.1150981, lng: 18.0999925 },
    { lat: 48.1160571, lng: 18.1010373 },
    { lat: 48.1162366, lng: 18.101306 },
    { lat: 48.1171792, lng: 18.1030872 },
    { lat: 48.1192758, lng: 18.1062745 },
    { lat: 48.1199514, lng: 18.1075471 },
    { lat: 48.1203192, lng: 18.1085784 },
    { lat: 48.1212173, lng: 18.112155 },
    { lat: 48.1212577, lng: 18.112114 },
    { lat: 48.1253188, lng: 18.1073959 },
    { lat: 48.12674, lng: 18.1056631 },
    { lat: 48.1291339, lng: 18.1029701 },
    { lat: 48.1336628, lng: 18.0976938 },
    { lat: 48.1342544, lng: 18.0989414 },
    { lat: 48.1376692, lng: 18.0960887 },
    { lat: 48.1414401, lng: 18.0936313 },
    { lat: 48.1448949, lng: 18.10962 },
    { lat: 48.1505438, lng: 18.1019366 },
    { lat: 48.1506167, lng: 18.1019648 },
    { lat: 48.1520336, lng: 18.1082123 },
    { lat: 48.1652807, lng: 18.0948459 },
    { lat: 48.169655, lng: 18.091849 },
    { lat: 48.169713, lng: 18.09181 },
    { lat: 48.170576, lng: 18.091237 },
    { lat: 48.170524, lng: 18.091129 },
    { lat: 48.169851, lng: 18.089749 },
    { lat: 48.16927, lng: 18.088542 },
    { lat: 48.169025, lng: 18.088022 },
    { lat: 48.167362, lng: 18.084629 },
    { lat: 48.166353, lng: 18.082552 },
    { lat: 48.16628, lng: 18.082401 },
    { lat: 48.166214, lng: 18.082264 },
    { lat: 48.165819, lng: 18.081439 },
    { lat: 48.164792, lng: 18.079384 },
    { lat: 48.163789, lng: 18.077292 },
    { lat: 48.162796, lng: 18.075207 },
    { lat: 48.161785, lng: 18.073129 },
    { lat: 48.161235, lng: 18.07221 },
    { lat: 48.161144, lng: 18.072058 },
    { lat: 48.161106, lng: 18.071992 },
    { lat: 48.160747, lng: 18.071395 },
    { lat: 48.159617, lng: 18.069481 },
    { lat: 48.158479, lng: 18.067571 },
    { lat: 48.157905, lng: 18.066584 },
    { lat: 48.157041, lng: 18.065159 },
    { lat: 48.156049, lng: 18.063512 },
    { lat: 48.155966, lng: 18.063372 },
    { lat: 48.15493, lng: 18.061655 },
    { lat: 48.153798, lng: 18.059748 },
    { lat: 48.152651, lng: 18.057839 },
    { lat: 48.151524, lng: 18.055951 },
    { lat: 48.151078, lng: 18.055175 },
    { lat: 48.15037, lng: 18.054012 },
    { lat: 48.14981, lng: 18.053057 },
    { lat: 48.148125, lng: 18.050158 },
    { lat: 48.14698, lng: 18.048258 },
    { lat: 48.145863, lng: 18.046344 },
    { lat: 48.144714, lng: 18.044408 },
    { lat: 48.143639, lng: 18.042584 },
    { lat: 48.143596, lng: 18.042513 },
    { lat: 48.142455, lng: 18.040627 },
    { lat: 48.141295, lng: 18.038686 },
    { lat: 48.142017, lng: 18.037822 },
    { lat: 48.142721, lng: 18.036926 },
    { lat: 48.1421561, lng: 18.0362359 },
    { lat: 48.1405954, lng: 18.0336298 },
    { lat: 48.1390151, lng: 18.0328296 },
    { lat: 48.1383087, lng: 18.0326795 },
    { lat: 48.1379373, lng: 18.0324724 },
    { lat: 48.1370321, lng: 18.0326215 },
    { lat: 48.1360203, lng: 18.0331244 },
    { lat: 48.1356256, lng: 18.0334493 },
    { lat: 48.1330246, lng: 18.036339 },
    { lat: 48.1318342, lng: 18.0364765 },
    { lat: 48.1315534, lng: 18.0368209 },
    { lat: 48.1296326, lng: 18.0381836 },
    { lat: 48.128226, lng: 18.0399406 },
    { lat: 48.1268296, lng: 18.0382519 },
    { lat: 48.1267634, lng: 18.0383158 },
    { lat: 48.1223623, lng: 18.0425273 },
    { lat: 48.1185305, lng: 18.0454666 },
    { lat: 48.1195796, lng: 18.0487361 },
    { lat: 48.1207764, lng: 18.05305 },
    { lat: 48.1264491, lng: 18.0721295 },
    { lat: 48.1284943, lng: 18.078815 },
    { lat: 48.1171585, lng: 18.093395 },
    { lat: 48.1136519, lng: 18.0950924 },
    { lat: 48.1116432, lng: 18.0967376 },
  ],
  KamennýMost: [
    { lat: 47.8129079, lng: 18.6398107 },
    { lat: 47.8149731, lng: 18.639334 },
    { lat: 47.8159022, lng: 18.6390183 },
    { lat: 47.8163601, lng: 18.638567 },
    { lat: 47.8176972, lng: 18.63746 },
    { lat: 47.818123, lng: 18.6369343 },
    { lat: 47.8185743, lng: 18.6365203 },
    { lat: 47.8187744, lng: 18.6364291 },
    { lat: 47.8190345, lng: 18.6364382 },
    { lat: 47.8192811, lng: 18.636558 },
    { lat: 47.8193742, lng: 18.6366531 },
    { lat: 47.8196234, lng: 18.6371649 },
    { lat: 47.8197151, lng: 18.6373802 },
    { lat: 47.8198236, lng: 18.6378578 },
    { lat: 47.8198059, lng: 18.6381522 },
    { lat: 47.8196964, lng: 18.6385444 },
    { lat: 47.8196359, lng: 18.6392546 },
    { lat: 47.8197055, lng: 18.6408798 },
    { lat: 47.8199759, lng: 18.6420923 },
    { lat: 47.8202374, lng: 18.6427322 },
    { lat: 47.8202934, lng: 18.6431171 },
    { lat: 47.821082, lng: 18.6441329 },
    { lat: 47.8210374, lng: 18.6443299 },
    { lat: 47.8213527, lng: 18.6447755 },
    { lat: 47.8211966, lng: 18.6450168 },
    { lat: 47.8213, lng: 18.6450479 },
    { lat: 47.8214307, lng: 18.6452743 },
    { lat: 47.8216302, lng: 18.6453967 },
    { lat: 47.822999, lng: 18.6472479 },
    { lat: 47.8234594, lng: 18.6477302 },
    { lat: 47.8236159, lng: 18.6479514 },
    { lat: 47.8235805, lng: 18.6480252 },
    { lat: 47.8239243, lng: 18.6484366 },
    { lat: 47.8240307, lng: 18.6484627 },
    { lat: 47.824205, lng: 18.6488682 },
    { lat: 47.8240951, lng: 18.6500392 },
    { lat: 47.8240027, lng: 18.6505764 },
    { lat: 47.8238082, lng: 18.6512328 },
    { lat: 47.8235695, lng: 18.6515061 },
    { lat: 47.8232763, lng: 18.6521938 },
    { lat: 47.8229339, lng: 18.6527582 },
    { lat: 47.8228635, lng: 18.6531677 },
    { lat: 47.8225204, lng: 18.6539663 },
    { lat: 47.8220287, lng: 18.65455 },
    { lat: 47.8219183, lng: 18.6545116 },
    { lat: 47.8214107, lng: 18.6550674 },
    { lat: 47.8212787, lng: 18.6552352 },
    { lat: 47.8213297, lng: 18.6553217 },
    { lat: 47.8210943, lng: 18.6555556 },
    { lat: 47.8206168, lng: 18.6563966 },
    { lat: 47.8202508, lng: 18.6568009 },
    { lat: 47.8202046, lng: 18.6569693 },
    { lat: 47.8204984, lng: 18.6574734 },
    { lat: 47.8208154, lng: 18.6571055 },
    { lat: 47.8207671, lng: 18.6570032 },
    { lat: 47.821065, lng: 18.656693 },
    { lat: 47.8210281, lng: 18.6566185 },
    { lat: 47.8215155, lng: 18.6560599 },
    { lat: 47.8218182, lng: 18.6558204 },
    { lat: 47.8225533, lng: 18.6550488 },
    { lat: 47.8226728, lng: 18.6549036 },
    { lat: 47.822614, lng: 18.6548173 },
    { lat: 47.8227941, lng: 18.654583 },
    { lat: 47.8228619, lng: 18.6546251 },
    { lat: 47.8236612, lng: 18.6537921 },
    { lat: 47.824106, lng: 18.6531589 },
    { lat: 47.8245469, lng: 18.6529273 },
    { lat: 47.8247955, lng: 18.6527093 },
    { lat: 47.8248241, lng: 18.6527792 },
    { lat: 47.8249067, lng: 18.6527195 },
    { lat: 47.8252699, lng: 18.6523895 },
    { lat: 47.8255644, lng: 18.6519382 },
    { lat: 47.8264349, lng: 18.6513303 },
    { lat: 47.8276546, lng: 18.6502849 },
    { lat: 47.8278612, lng: 18.6502057 },
    { lat: 47.8285488, lng: 18.6495569 },
    { lat: 47.8287628, lng: 18.649429 },
    { lat: 47.8288946, lng: 18.6492156 },
    { lat: 47.8292544, lng: 18.6491341 },
    { lat: 47.8299981, lng: 18.6485 },
    { lat: 47.8308084, lng: 18.6481797 },
    { lat: 47.830791, lng: 18.6481091 },
    { lat: 47.8315263, lng: 18.6474233 },
    { lat: 47.8324002, lng: 18.6502969 },
    { lat: 47.8324165, lng: 18.6506175 },
    { lat: 47.8332167, lng: 18.6532757 },
    { lat: 47.8335689, lng: 18.654297 },
    { lat: 47.8342835, lng: 18.6560698 },
    { lat: 47.8349734, lng: 18.6584676 },
    { lat: 47.8357824, lng: 18.6605659 },
    { lat: 47.8372932, lng: 18.665079 },
    { lat: 47.8380484, lng: 18.6669774 },
    { lat: 47.8373476, lng: 18.6675333 },
    { lat: 47.8393955, lng: 18.6708696 },
    { lat: 47.842174, lng: 18.6748688 },
    { lat: 47.8438942, lng: 18.6777622 },
    { lat: 47.8441499, lng: 18.678194 },
    { lat: 47.8444571, lng: 18.6775168 },
    { lat: 47.8445595, lng: 18.6770741 },
    { lat: 47.844704, lng: 18.6757255 },
    { lat: 47.8448593, lng: 18.674845 },
    { lat: 47.844987, lng: 18.674498 },
    { lat: 47.8451108, lng: 18.6743062 },
    { lat: 47.8459428, lng: 18.6735939 },
    { lat: 47.8468155, lng: 18.6731077 },
    { lat: 47.8473138, lng: 18.6727313 },
    { lat: 47.8475101, lng: 18.6725021 },
    { lat: 47.8478932, lng: 18.6717929 },
    { lat: 47.8481298, lng: 18.6710181 },
    { lat: 47.8483155, lng: 18.670116 },
    { lat: 47.8484459, lng: 18.6690091 },
    { lat: 47.8485221, lng: 18.6673564 },
    { lat: 47.8487766, lng: 18.666334 },
    { lat: 47.8489356, lng: 18.6658971 },
    { lat: 47.8494933, lng: 18.664931 },
    { lat: 47.8500409, lng: 18.6643822 },
    { lat: 47.8503981, lng: 18.664189 },
    { lat: 47.850925, lng: 18.6640897 },
    { lat: 47.8512545, lng: 18.6641347 },
    { lat: 47.8516166, lng: 18.6643206 },
    { lat: 47.851847, lng: 18.6645721 },
    { lat: 47.8525537, lng: 18.6656043 },
    { lat: 47.8531595, lng: 18.6668937 },
    { lat: 47.8533494, lng: 18.6674216 },
    { lat: 47.8533632, lng: 18.6675568 },
    { lat: 47.853451, lng: 18.6684182 },
    { lat: 47.853431, lng: 18.6689635 },
    { lat: 47.853334, lng: 18.6695264 },
    { lat: 47.853145, lng: 18.670034 },
    { lat: 47.8528509, lng: 18.6706195 },
    { lat: 47.8525815, lng: 18.6710166 },
    { lat: 47.8519555, lng: 18.6717448 },
    { lat: 47.8516136, lng: 18.6723651 },
    { lat: 47.8515987, lng: 18.6724268 },
    { lat: 47.8514063, lng: 18.6732232 },
    { lat: 47.8513566, lng: 18.6738011 },
    { lat: 47.8514573, lng: 18.6746367 },
    { lat: 47.8516752, lng: 18.6751636 },
    { lat: 47.8520242, lng: 18.6757546 },
    { lat: 47.8527352, lng: 18.6762179 },
    { lat: 47.8531973, lng: 18.676171 },
    { lat: 47.8537285, lng: 18.6758992 },
    { lat: 47.8539081, lng: 18.6756761 },
    { lat: 47.8541737, lng: 18.6751875 },
    { lat: 47.8546219, lng: 18.6738426 },
    { lat: 47.8551632, lng: 18.6725118 },
    { lat: 47.855877, lng: 18.67139 },
    { lat: 47.856312, lng: 18.6708431 },
    { lat: 47.857026, lng: 18.670137 },
    { lat: 47.8575777, lng: 18.6698475 },
    { lat: 47.8574211, lng: 18.668896 },
    { lat: 47.8585319, lng: 18.6675249 },
    { lat: 47.8587535, lng: 18.666783 },
    { lat: 47.8590148, lng: 18.6661164 },
    { lat: 47.8594368, lng: 18.6653908 },
    { lat: 47.859587, lng: 18.6652277 },
    { lat: 47.8600468, lng: 18.6649527 },
    { lat: 47.8606174, lng: 18.6647409 },
    { lat: 47.8610246, lng: 18.6647824 },
    { lat: 47.862206, lng: 18.6653114 },
    { lat: 47.8623231, lng: 18.6652875 },
    { lat: 47.8623588, lng: 18.6655495 },
    { lat: 47.8634235, lng: 18.6653617 },
    { lat: 47.8639281, lng: 18.6651659 },
    { lat: 47.8643977, lng: 18.664778 },
    { lat: 47.8646516, lng: 18.664448 },
    { lat: 47.8647803, lng: 18.6641607 },
    { lat: 47.8649414, lng: 18.6636142 },
    { lat: 47.8652475, lng: 18.6621925 },
    { lat: 47.8654206, lng: 18.6617471 },
    { lat: 47.8655718, lng: 18.6614978 },
    { lat: 47.8659151, lng: 18.6610699 },
    { lat: 47.8662354, lng: 18.6607357 },
    { lat: 47.8665047, lng: 18.6606504 },
    { lat: 47.8667887, lng: 18.6606879 },
    { lat: 47.8670272, lng: 18.6597626 },
    { lat: 47.8670879, lng: 18.6596748 },
    { lat: 47.8684603, lng: 18.6593389 },
    { lat: 47.8687735, lng: 18.6591654 },
    { lat: 47.8689346, lng: 18.6586648 },
    { lat: 47.8689838, lng: 18.6583048 },
    { lat: 47.869218, lng: 18.6573873 },
    { lat: 47.8694633, lng: 18.6567962 },
    { lat: 47.8696778, lng: 18.6565716 },
    { lat: 47.8700963, lng: 18.6563014 },
    { lat: 47.8703147, lng: 18.6562323 },
    { lat: 47.8710008, lng: 18.6562802 },
    { lat: 47.8717543, lng: 18.6566758 },
    { lat: 47.8724448, lng: 18.657261 },
    { lat: 47.8725276, lng: 18.6564181 },
    { lat: 47.8721854, lng: 18.6538391 },
    { lat: 47.8720775, lng: 18.6523953 },
    { lat: 47.8721869, lng: 18.6521907 },
    { lat: 47.8719094, lng: 18.6518981 },
    { lat: 47.8716315, lng: 18.6518082 },
    { lat: 47.871107, lng: 18.6519935 },
    { lat: 47.8710931, lng: 18.6515083 },
    { lat: 47.8711402, lng: 18.6512764 },
    { lat: 47.8710974, lng: 18.6508625 },
    { lat: 47.8717627, lng: 18.6506539 },
    { lat: 47.8719957, lng: 18.6500449 },
    { lat: 47.8721226, lng: 18.6493516 },
    { lat: 47.8720952, lng: 18.6492425 },
    { lat: 47.8718832, lng: 18.6489301 },
    { lat: 47.871344, lng: 18.6485383 },
    { lat: 47.8710816, lng: 18.6482544 },
    { lat: 47.8709317, lng: 18.6481941 },
    { lat: 47.8706393, lng: 18.6470905 },
    { lat: 47.8700372, lng: 18.6468672 },
    { lat: 47.8696452, lng: 18.646895 },
    { lat: 47.8688553, lng: 18.6471594 },
    { lat: 47.8679943, lng: 18.6465096 },
    { lat: 47.8677012, lng: 18.6452898 },
    { lat: 47.8691736, lng: 18.6313655 },
    { lat: 47.8709967, lng: 18.6131682 },
    { lat: 47.8713904, lng: 18.6104476 },
    { lat: 47.8704065, lng: 18.6098244 },
    { lat: 47.8682883, lng: 18.6086526 },
    { lat: 47.8681995, lng: 18.6086581 },
    { lat: 47.8680837, lng: 18.608689 },
    { lat: 47.8671533, lng: 18.6090391 },
    { lat: 47.8667703, lng: 18.6083264 },
    { lat: 47.8650382, lng: 18.6057051 },
    { lat: 47.8638044, lng: 18.604882 },
    { lat: 47.8568913, lng: 18.6094702 },
    { lat: 47.856825, lng: 18.6098649 },
    { lat: 47.8564159, lng: 18.6109216 },
    { lat: 47.8554055, lng: 18.6121596 },
    { lat: 47.8542303, lng: 18.6132549 },
    { lat: 47.8540685, lng: 18.6130363 },
    { lat: 47.851584, lng: 18.6157813 },
    { lat: 47.8495399, lng: 18.6168901 },
    { lat: 47.8488576, lng: 18.6170154 },
    { lat: 47.848125, lng: 18.6169442 },
    { lat: 47.8477161, lng: 18.6170944 },
    { lat: 47.8473211, lng: 18.6170288 },
    { lat: 47.8458637, lng: 18.6172969 },
    { lat: 47.8455537, lng: 18.6175869 },
    { lat: 47.8446718, lng: 18.6176948 },
    { lat: 47.8438271, lng: 18.6179198 },
    { lat: 47.8432808, lng: 18.6179577 },
    { lat: 47.8428068, lng: 18.6178079 },
    { lat: 47.8423033, lng: 18.6177771 },
    { lat: 47.842083, lng: 18.6176686 },
    { lat: 47.8415774, lng: 18.6175597 },
    { lat: 47.8407113, lng: 18.6176669 },
    { lat: 47.8404413, lng: 18.6178642 },
    { lat: 47.8397677, lng: 18.6181049 },
    { lat: 47.8388252, lng: 18.6180542 },
    { lat: 47.8382593, lng: 18.6176873 },
    { lat: 47.8376652, lng: 18.6179602 },
    { lat: 47.8364898, lng: 18.6187036 },
    { lat: 47.8360028, lng: 18.6189337 },
    { lat: 47.8357287, lng: 18.6189739 },
    { lat: 47.8354708, lng: 18.6189176 },
    { lat: 47.8347271, lng: 18.6185827 },
    { lat: 47.8341146, lng: 18.6185202 },
    { lat: 47.8338719, lng: 18.6185776 },
    { lat: 47.83349, lng: 18.6187933 },
    { lat: 47.833211, lng: 18.6190734 },
    { lat: 47.8329398, lng: 18.6192079 },
    { lat: 47.8324777, lng: 18.619255 },
    { lat: 47.8315325, lng: 18.620801 },
    { lat: 47.8297676, lng: 18.6238741 },
    { lat: 47.8292056, lng: 18.6247183 },
    { lat: 47.8280938, lng: 18.6241428 },
    { lat: 47.8270904, lng: 18.6241119 },
    { lat: 47.8267798, lng: 18.6240423 },
    { lat: 47.826398, lng: 18.6238431 },
    { lat: 47.8262876, lng: 18.6237855 },
    { lat: 47.8256792, lng: 18.6232989 },
    { lat: 47.8255963, lng: 18.6232326 },
    { lat: 47.8247034, lng: 18.6221289 },
    { lat: 47.8233488, lng: 18.6202927 },
    { lat: 47.8224141, lng: 18.6193644 },
    { lat: 47.8217981, lng: 18.6187525 },
    { lat: 47.8205929, lng: 18.6185802 },
    { lat: 47.8203109, lng: 18.6187206 },
    { lat: 47.8194586, lng: 18.619385 },
    { lat: 47.8188179, lng: 18.6199814 },
    { lat: 47.8181991, lng: 18.6207937 },
    { lat: 47.8173863, lng: 18.621587 },
    { lat: 47.8164547, lng: 18.6226389 },
    { lat: 47.8160097, lng: 18.6233863 },
    { lat: 47.8158498, lng: 18.6238553 },
    { lat: 47.8155195, lng: 18.6248243 },
    { lat: 47.8154712, lng: 18.6249658 },
    { lat: 47.8154492, lng: 18.6249786 },
    { lat: 47.8145386, lng: 18.6268451 },
    { lat: 47.8142428, lng: 18.6277671 },
    { lat: 47.8141327, lng: 18.6287232 },
    { lat: 47.8138793, lng: 18.6301531 },
    { lat: 47.8137873, lng: 18.6331731 },
    { lat: 47.8137478, lng: 18.6336457 },
    { lat: 47.8134927, lng: 18.6367016 },
    { lat: 47.8133763, lng: 18.6371715 },
    { lat: 47.8132859, lng: 18.6380553 },
    { lat: 47.8130432, lng: 18.6389832 },
    { lat: 47.8129332, lng: 18.6396947 },
    { lat: 47.8129079, lng: 18.6398107 },
  ],
  Šurany: [
    { lat: 48.1303405, lng: 18.2151311 },
    { lat: 48.1301602, lng: 18.2127059 },
    { lat: 48.1303754, lng: 18.2122895 },
    { lat: 48.130659, lng: 18.211559 },
    { lat: 48.1312608, lng: 18.2095432 },
    { lat: 48.1313868, lng: 18.2091896 },
    { lat: 48.1292651, lng: 18.2072824 },
    { lat: 48.1273008, lng: 18.2057308 },
    { lat: 48.1226376, lng: 18.2017489 },
    { lat: 48.1224749, lng: 18.2042273 },
    { lat: 48.1224691, lng: 18.2049422 },
    { lat: 48.1222101, lng: 18.2079791 },
    { lat: 48.12199, lng: 18.2078989 },
    { lat: 48.121636, lng: 18.2068969 },
    { lat: 48.1214394, lng: 18.2067169 },
    { lat: 48.1202973, lng: 18.2063271 },
    { lat: 48.1195274, lng: 18.2061684 },
    { lat: 48.1188787, lng: 18.2058009 },
    { lat: 48.1186394, lng: 18.2055649 },
    { lat: 48.1183512, lng: 18.2048783 },
    { lat: 48.1176675, lng: 18.2037374 },
    { lat: 48.1173742, lng: 18.2035254 },
    { lat: 48.1173054, lng: 18.2031967 },
    { lat: 48.1175564, lng: 18.2023689 },
    { lat: 48.1175491, lng: 18.2021661 },
    { lat: 48.1170565, lng: 18.2014181 },
    { lat: 48.1168528, lng: 18.2014075 },
    { lat: 48.1167712, lng: 18.201887 },
    { lat: 48.1166156, lng: 18.2022603 },
    { lat: 48.1163336, lng: 18.202144 },
    { lat: 48.1160474, lng: 18.2017253 },
    { lat: 48.1160316, lng: 18.2015705 },
    { lat: 48.1159409, lng: 18.2014668 },
    { lat: 48.1157259, lng: 18.2015148 },
    { lat: 48.1155743, lng: 18.2017785 },
    { lat: 48.1149725, lng: 18.2024131 },
    { lat: 48.1148305, lng: 18.2024354 },
    { lat: 48.1145848, lng: 18.2021293 },
    { lat: 48.1145469, lng: 18.2020234 },
    { lat: 48.1145488, lng: 18.2017632 },
    { lat: 48.1147596, lng: 18.2010042 },
    { lat: 48.1151026, lng: 18.2005762 },
    { lat: 48.1155574, lng: 18.1993947 },
    { lat: 48.1154647, lng: 18.1989639 },
    { lat: 48.1153583, lng: 18.1988622 },
    { lat: 48.1151793, lng: 18.1988095 },
    { lat: 48.114446, lng: 18.1990459 },
    { lat: 48.1139636, lng: 18.1984765 },
    { lat: 48.1138714, lng: 18.1977416 },
    { lat: 48.1137133, lng: 18.1975478 },
    { lat: 48.113576, lng: 18.1976111 },
    { lat: 48.1133949, lng: 18.19801 },
    { lat: 48.1134389, lng: 18.1994439 },
    { lat: 48.1134, lng: 18.1996683 },
    { lat: 48.1132673, lng: 18.1998112 },
    { lat: 48.1129051, lng: 18.1997343 },
    { lat: 48.1127311, lng: 18.1994217 },
    { lat: 48.1126646, lng: 18.1990723 },
    { lat: 48.1126838, lng: 18.1981987 },
    { lat: 48.1127952, lng: 18.197322 },
    { lat: 48.1127105, lng: 18.1967775 },
    { lat: 48.1125795, lng: 18.1966024 },
    { lat: 48.1122205, lng: 18.1965816 },
    { lat: 48.1121776, lng: 18.1964661 },
    { lat: 48.1114113, lng: 18.1964222 },
    { lat: 48.1111243, lng: 18.1966116 },
    { lat: 48.1104789, lng: 18.196789 },
    { lat: 48.1101117, lng: 18.1967809 },
    { lat: 48.1082956, lng: 18.1963864 },
    { lat: 48.1074558, lng: 18.1963231 },
    { lat: 48.1072263, lng: 18.1959268 },
    { lat: 48.1072897, lng: 18.1953095 },
    { lat: 48.1069296, lng: 18.1949615 },
    { lat: 48.1067643, lng: 18.1946617 },
    { lat: 48.1063615, lng: 18.1931287 },
    { lat: 48.1061567, lng: 18.1928118 },
    { lat: 48.106278, lng: 18.1948544 },
    { lat: 48.1061719, lng: 18.1970642 },
    { lat: 48.1062342, lng: 18.198056 },
    { lat: 48.1063729, lng: 18.1991597 },
    { lat: 48.1064884, lng: 18.1993396 },
    { lat: 48.1064981, lng: 18.1998153 },
    { lat: 48.1065544, lng: 18.2001049 },
    { lat: 48.1069406, lng: 18.2002476 },
    { lat: 48.107093, lng: 18.2004209 },
    { lat: 48.1074258, lng: 18.2005561 },
    { lat: 48.1076193, lng: 18.2007774 },
    { lat: 48.108271, lng: 18.2003822 },
    { lat: 48.1103282, lng: 18.2033124 },
    { lat: 48.1073738, lng: 18.2083341 },
    { lat: 48.1070216, lng: 18.2092434 },
    { lat: 48.1067724, lng: 18.2106265 },
    { lat: 48.1062577, lng: 18.2116538 },
    { lat: 48.1055909, lng: 18.2114023 },
    { lat: 48.102494, lng: 18.2151021 },
    { lat: 48.101977, lng: 18.2146525 },
    { lat: 48.1015221, lng: 18.2144177 },
    { lat: 48.1013075, lng: 18.2139671 },
    { lat: 48.1010757, lng: 18.2133183 },
    { lat: 48.1006684, lng: 18.2124673 },
    { lat: 48.1004724, lng: 18.2122388 },
    { lat: 48.1002224, lng: 18.2124777 },
    { lat: 48.1000341, lng: 18.2124241 },
    { lat: 48.0995205, lng: 18.2128108 },
    { lat: 48.09904, lng: 18.211708 },
    { lat: 48.0991825, lng: 18.2114771 },
    { lat: 48.0993223, lng: 18.2111091 },
    { lat: 48.0991241, lng: 18.2110963 },
    { lat: 48.0987809, lng: 18.2114031 },
    { lat: 48.0985176, lng: 18.2115159 },
    { lat: 48.0976149, lng: 18.2115339 },
    { lat: 48.0968596, lng: 18.2110398 },
    { lat: 48.0965467, lng: 18.2096765 },
    { lat: 48.0964587, lng: 18.2084372 },
    { lat: 48.0964564, lng: 18.2079238 },
    { lat: 48.0966652, lng: 18.2078039 },
    { lat: 48.0966308, lng: 18.2073753 },
    { lat: 48.0969578, lng: 18.2069933 },
    { lat: 48.0972191, lng: 18.2056568 },
    { lat: 48.0972057, lng: 18.2041789 },
    { lat: 48.0987658, lng: 18.2035348 },
    { lat: 48.0990106, lng: 18.2031454 },
    { lat: 48.0990855, lng: 18.201795 },
    { lat: 48.0988607, lng: 18.201618 },
    { lat: 48.0986618, lng: 18.2012837 },
    { lat: 48.0986061, lng: 18.2006767 },
    { lat: 48.0986548, lng: 18.2003308 },
    { lat: 48.0988506, lng: 18.199892 },
    { lat: 48.0989105, lng: 18.1999556 },
    { lat: 48.0996985, lng: 18.1995137 },
    { lat: 48.0981602, lng: 18.1963827 },
    { lat: 48.099988, lng: 18.193445 },
    { lat: 48.1007319, lng: 18.1922494 },
    { lat: 48.1025197, lng: 18.1899091 },
    { lat: 48.1026206, lng: 18.1897205 },
    { lat: 48.1029603, lng: 18.1897039 },
    { lat: 48.10348, lng: 18.1900018 },
    { lat: 48.1038755, lng: 18.1901471 },
    { lat: 48.1056215, lng: 18.1902867 },
    { lat: 48.1056181, lng: 18.1902126 },
    { lat: 48.106142, lng: 18.1889 },
    { lat: 48.1064002, lng: 18.1885678 },
    { lat: 48.1063781, lng: 18.1885177 },
    { lat: 48.1065645, lng: 18.1881905 },
    { lat: 48.1064203, lng: 18.1878654 },
    { lat: 48.1055299, lng: 18.1866479 },
    { lat: 48.1052773, lng: 18.1860873 },
    { lat: 48.1052013, lng: 18.1854461 },
    { lat: 48.1053524, lng: 18.1845131 },
    { lat: 48.1054035, lng: 18.1839017 },
    { lat: 48.1055135, lng: 18.18391 },
    { lat: 48.106709, lng: 18.1828711 },
    { lat: 48.1071578, lng: 18.1824219 },
    { lat: 48.1074483, lng: 18.1819297 },
    { lat: 48.1083036, lng: 18.1796084 },
    { lat: 48.1090842, lng: 18.1766073 },
    { lat: 48.1093404, lng: 18.1752527 },
    { lat: 48.1095496, lng: 18.1726171 },
    { lat: 48.1098327, lng: 18.1680595 },
    { lat: 48.1101801, lng: 18.1644285 },
    { lat: 48.1115811, lng: 18.1453704 },
    { lat: 48.1125769, lng: 18.1408793 },
    { lat: 48.1142258, lng: 18.135176 },
    { lat: 48.1143045, lng: 18.1351925 },
    { lat: 48.1143997, lng: 18.135102 },
    { lat: 48.117188, lng: 18.1212273 },
    { lat: 48.119802, lng: 18.1185484 },
    { lat: 48.1222897, lng: 18.1160296 },
    { lat: 48.122123, lng: 18.115267 },
    { lat: 48.1212354, lng: 18.1122325 },
    { lat: 48.1212173, lng: 18.112155 },
    { lat: 48.1203192, lng: 18.1085784 },
    { lat: 48.1199514, lng: 18.1075471 },
    { lat: 48.1192758, lng: 18.1062745 },
    { lat: 48.1171792, lng: 18.1030872 },
    { lat: 48.1162366, lng: 18.101306 },
    { lat: 48.1160571, lng: 18.1010373 },
    { lat: 48.1150981, lng: 18.0999925 },
    { lat: 48.1128609, lng: 18.0973303 },
    { lat: 48.1123206, lng: 18.0968962 },
    { lat: 48.1121046, lng: 18.0967912 },
    { lat: 48.1117182, lng: 18.0967464 },
    { lat: 48.1116432, lng: 18.0967376 },
    { lat: 48.1098127, lng: 18.0989334 },
    { lat: 48.1056606, lng: 18.1034043 },
    { lat: 48.1041166, lng: 18.1051601 },
    { lat: 48.1017959, lng: 18.1074861 },
    { lat: 48.1002396, lng: 18.1093583 },
    { lat: 48.09736, lng: 18.1126382 },
    { lat: 48.0949629, lng: 18.1150675 },
    { lat: 48.0936022, lng: 18.1157825 },
    { lat: 48.0922439, lng: 18.1155589 },
    { lat: 48.090944, lng: 18.1161367 },
    { lat: 48.0888712, lng: 18.1162367 },
    { lat: 48.0878865, lng: 18.1143125 },
    { lat: 48.0867506, lng: 18.1118915 },
    { lat: 48.082676, lng: 18.1162651 },
    { lat: 48.0800988, lng: 18.1139557 },
    { lat: 48.0787797, lng: 18.1116075 },
    { lat: 48.0761381, lng: 18.1077302 },
    { lat: 48.0742004, lng: 18.1043871 },
    { lat: 48.0724648, lng: 18.1017949 },
    { lat: 48.0710865, lng: 18.0995942 },
    { lat: 48.0700902, lng: 18.0975141 },
    { lat: 48.0687087, lng: 18.0941739 },
    { lat: 48.0684648, lng: 18.0937329 },
    { lat: 48.0662648, lng: 18.0963394 },
    { lat: 48.0658971, lng: 18.0967046 },
    { lat: 48.0625763, lng: 18.0990628 },
    { lat: 48.0613983, lng: 18.1001538 },
    { lat: 48.0613774, lng: 18.1001047 },
    { lat: 48.0596748, lng: 18.1016185 },
    { lat: 48.056426, lng: 18.1049078 },
    { lat: 48.0560837, lng: 18.1052876 },
    { lat: 48.054228, lng: 18.1078564 },
    { lat: 48.0535776, lng: 18.1086163 },
    { lat: 48.0526648, lng: 18.1106625 },
    { lat: 48.0517894, lng: 18.1118346 },
    { lat: 48.044437, lng: 18.1185479 },
    { lat: 48.0443555, lng: 18.1186217 },
    { lat: 48.0444594, lng: 18.1191762 },
    { lat: 48.0451071, lng: 18.1212217 },
    { lat: 48.0454569, lng: 18.1229258 },
    { lat: 48.0454345, lng: 18.1251861 },
    { lat: 48.0456949, lng: 18.1265239 },
    { lat: 48.0439001, lng: 18.129982 },
    { lat: 48.0413321, lng: 18.1345355 },
    { lat: 48.0408063, lng: 18.1354587 },
    { lat: 48.0445316, lng: 18.1381039 },
    { lat: 48.0488398, lng: 18.1402845 },
    { lat: 48.0514682, lng: 18.1415188 },
    { lat: 48.052269, lng: 18.1485402 },
    { lat: 48.0530458, lng: 18.1502562 },
    { lat: 48.0530268, lng: 18.1538362 },
    { lat: 48.053275, lng: 18.1571846 },
    { lat: 48.0538808, lng: 18.1645164 },
    { lat: 48.054149, lng: 18.1662242 },
    { lat: 48.0542131, lng: 18.1675494 },
    { lat: 48.0547096, lng: 18.1708077 },
    { lat: 48.0548791, lng: 18.1728201 },
    { lat: 48.0549605, lng: 18.1736708 },
    { lat: 48.0555499, lng: 18.17993 },
    { lat: 48.0561853, lng: 18.1837943 },
    { lat: 48.0563678, lng: 18.1867835 },
    { lat: 48.0573284, lng: 18.1911807 },
    { lat: 48.0576142, lng: 18.1926914 },
    { lat: 48.0578471, lng: 18.1942763 },
    { lat: 48.057887, lng: 18.1945477 },
    { lat: 48.0578888, lng: 18.1945785 },
    { lat: 48.0580738, lng: 18.1983322 },
    { lat: 48.0581729, lng: 18.2010808 },
    { lat: 48.0581745, lng: 18.2023868 },
    { lat: 48.0584628, lng: 18.2027049 },
    { lat: 48.0585122, lng: 18.2029518 },
    { lat: 48.0584756, lng: 18.2031767 },
    { lat: 48.0580772, lng: 18.203697 },
    { lat: 48.0579955, lng: 18.2039055 },
    { lat: 48.0579286, lng: 18.2043646 },
    { lat: 48.0578893, lng: 18.2051598 },
    { lat: 48.0574405, lng: 18.2057696 },
    { lat: 48.0573239, lng: 18.2065166 },
    { lat: 48.0573313, lng: 18.2066648 },
    { lat: 48.0573991, lng: 18.2070331 },
    { lat: 48.0575188, lng: 18.2073061 },
    { lat: 48.0578574, lng: 18.207464 },
    { lat: 48.0579485, lng: 18.2074208 },
    { lat: 48.0582775, lng: 18.2069271 },
    { lat: 48.0584216, lng: 18.2068432 },
    { lat: 48.0585595, lng: 18.2068846 },
    { lat: 48.0587525, lng: 18.2071562 },
    { lat: 48.0589669, lng: 18.2078339 },
    { lat: 48.0591357, lng: 18.2082153 },
    { lat: 48.0595431, lng: 18.2088786 },
    { lat: 48.0588229, lng: 18.2113548 },
    { lat: 48.0585296, lng: 18.2126171 },
    { lat: 48.0594325, lng: 18.2136692 },
    { lat: 48.0602926, lng: 18.2147971 },
    { lat: 48.0609382, lng: 18.215503 },
    { lat: 48.0617848, lng: 18.216595 },
    { lat: 48.0637014, lng: 18.21971 },
    { lat: 48.0650913, lng: 18.2214533 },
    { lat: 48.0653023, lng: 18.222562 },
    { lat: 48.0640792, lng: 18.2236139 },
    { lat: 48.0634378, lng: 18.224326 },
    { lat: 48.0643542, lng: 18.2265451 },
    { lat: 48.0647145, lng: 18.2272489 },
    { lat: 48.0652923, lng: 18.2280661 },
    { lat: 48.0660368, lng: 18.2288794 },
    { lat: 48.0674046, lng: 18.2298159 },
    { lat: 48.0667936, lng: 18.2312684 },
    { lat: 48.0670144, lng: 18.2322549 },
    { lat: 48.0670575, lng: 18.2340502 },
    { lat: 48.0671219, lng: 18.2348321 },
    { lat: 48.0681116, lng: 18.2353561 },
    { lat: 48.0687747, lng: 18.2349986 },
    { lat: 48.0696413, lng: 18.2343376 },
    { lat: 48.0707527, lng: 18.2333228 },
    { lat: 48.0711126, lng: 18.2332249 },
    { lat: 48.0721462, lng: 18.2336758 },
    { lat: 48.0727211, lng: 18.233179 },
    { lat: 48.0734666, lng: 18.2323327 },
    { lat: 48.074366, lng: 18.2320621 },
    { lat: 48.075582, lng: 18.2319186 },
    { lat: 48.0772428, lng: 18.2320512 },
    { lat: 48.0796533, lng: 18.2318989 },
    { lat: 48.0804845, lng: 18.2321653 },
    { lat: 48.0809523, lng: 18.2320969 },
    { lat: 48.0815256, lng: 18.2321199 },
    { lat: 48.0822797, lng: 18.2323197 },
    { lat: 48.0824191, lng: 18.2322735 },
    { lat: 48.0825945, lng: 18.2320476 },
    { lat: 48.0829594, lng: 18.2310619 },
    { lat: 48.0836432, lng: 18.2301274 },
    { lat: 48.0840645, lng: 18.2286321 },
    { lat: 48.084347, lng: 18.2280571 },
    { lat: 48.0850236, lng: 18.228576 },
    { lat: 48.0867418, lng: 18.2297154 },
    { lat: 48.0867835, lng: 18.2296761 },
    { lat: 48.0872303, lng: 18.2289772 },
    { lat: 48.0876245, lng: 18.2282642 },
    { lat: 48.0878144, lng: 18.2274689 },
    { lat: 48.0879384, lng: 18.226399 },
    { lat: 48.0886211, lng: 18.2240627 },
    { lat: 48.0893702, lng: 18.222347 },
    { lat: 48.0896148, lng: 18.2219381 },
    { lat: 48.0922964, lng: 18.2203875 },
    { lat: 48.0930875, lng: 18.2200413 },
    { lat: 48.0958592, lng: 18.2208622 },
    { lat: 48.0969349, lng: 18.2215113 },
    { lat: 48.0979225, lng: 18.2225658 },
    { lat: 48.0982017, lng: 18.223001 },
    { lat: 48.098448, lng: 18.2235494 },
    { lat: 48.0988042, lng: 18.2232891 },
    { lat: 48.0991007, lng: 18.2229748 },
    { lat: 48.0994451, lng: 18.2228844 },
    { lat: 48.1005069, lng: 18.2228403 },
    { lat: 48.1024989, lng: 18.2223604 },
    { lat: 48.1035588, lng: 18.2216496 },
    { lat: 48.1035771, lng: 18.2219237 },
    { lat: 48.1038328, lng: 18.221975 },
    { lat: 48.1078244, lng: 18.2242982 },
    { lat: 48.1078729, lng: 18.2243339 },
    { lat: 48.1078612, lng: 18.2244057 },
    { lat: 48.1086005, lng: 18.2250328 },
    { lat: 48.1095346, lng: 18.2252214 },
    { lat: 48.1099332, lng: 18.225714 },
    { lat: 48.1105345, lng: 18.2262559 },
    { lat: 48.1113272, lng: 18.2264052 },
    { lat: 48.1118233, lng: 18.2267673 },
    { lat: 48.1129198, lng: 18.226663 },
    { lat: 48.1150457, lng: 18.2262738 },
    { lat: 48.1159218, lng: 18.2255239 },
    { lat: 48.1176391, lng: 18.2246246 },
    { lat: 48.1176443, lng: 18.224286 },
    { lat: 48.1209835, lng: 18.2216633 },
    { lat: 48.1215409, lng: 18.2214072 },
    { lat: 48.1244738, lng: 18.2194844 },
    { lat: 48.1244962, lng: 18.2195815 },
    { lat: 48.1256282, lng: 18.218612 },
    { lat: 48.1256566, lng: 18.2185158 },
    { lat: 48.1263525, lng: 18.2178109 },
    { lat: 48.1268853, lng: 18.2171143 },
    { lat: 48.1281452, lng: 18.2151028 },
    { lat: 48.129324, lng: 18.2151353 },
    { lat: 48.1303405, lng: 18.2151311 },
  ],
  Branovo: [
    { lat: 48.039214, lng: 18.3297626 },
    { lat: 48.0394068, lng: 18.3277268 },
    { lat: 48.0394789, lng: 18.3271116 },
    { lat: 48.0391479, lng: 18.3265113 },
    { lat: 48.0369678, lng: 18.3194042 },
    { lat: 48.0357864, lng: 18.3164067 },
    { lat: 48.0355042, lng: 18.3147685 },
    { lat: 48.0363819, lng: 18.3144925 },
    { lat: 48.0371519, lng: 18.3141234 },
    { lat: 48.0384016, lng: 18.3130537 },
    { lat: 48.0389435, lng: 18.3126653 },
    { lat: 48.0403981, lng: 18.3117425 },
    { lat: 48.041605, lng: 18.311244 },
    { lat: 48.0410337, lng: 18.3096829 },
    { lat: 48.0389458, lng: 18.3046726 },
    { lat: 48.0378847, lng: 18.3010455 },
    { lat: 48.0367312, lng: 18.2974219 },
    { lat: 48.036384, lng: 18.296819 },
    { lat: 48.0357309, lng: 18.2960161 },
    { lat: 48.0351741, lng: 18.2951797 },
    { lat: 48.0344766, lng: 18.293706 },
    { lat: 48.0334529, lng: 18.291815 },
    { lat: 48.0331487, lng: 18.2913308 },
    { lat: 48.0326848, lng: 18.290759 },
    { lat: 48.0320895, lng: 18.2894726 },
    { lat: 48.031167, lng: 18.2877342 },
    { lat: 48.0300089, lng: 18.2858229 },
    { lat: 48.0294387, lng: 18.2849889 },
    { lat: 48.0284032, lng: 18.2840349 },
    { lat: 48.027453, lng: 18.2821899 },
    { lat: 48.026873, lng: 18.2815034 },
    { lat: 48.0258868, lng: 18.2805218 },
    { lat: 48.0250809, lng: 18.2792224 },
    { lat: 48.0250664, lng: 18.2792088 },
    { lat: 48.0249858, lng: 18.2791193 },
    { lat: 48.0231874, lng: 18.2805939 },
    { lat: 48.0198199, lng: 18.2840542 },
    { lat: 48.0171362, lng: 18.2873187 },
    { lat: 48.0149723, lng: 18.2901465 },
    { lat: 48.0151389, lng: 18.2904953 },
    { lat: 48.0143475, lng: 18.2914696 },
    { lat: 48.0143045, lng: 18.2916428 },
    { lat: 48.0132893, lng: 18.2931562 },
    { lat: 48.012808, lng: 18.2942445 },
    { lat: 48.0122773, lng: 18.295086 },
    { lat: 48.0115904, lng: 18.2959122 },
    { lat: 48.0103102, lng: 18.297256 },
    { lat: 48.0099865, lng: 18.2975143 },
    { lat: 48.0097627, lng: 18.2971901 },
    { lat: 48.0090505, lng: 18.2979712 },
    { lat: 48.0082422, lng: 18.298637 },
    { lat: 48.0069343, lng: 18.2999877 },
    { lat: 48.0059651, lng: 18.3015396 },
    { lat: 48.0054323, lng: 18.3022205 },
    { lat: 48.0041278, lng: 18.3035959 },
    { lat: 48.0035955, lng: 18.3038938 },
    { lat: 48.0028603, lng: 18.3047076 },
    { lat: 48.0020923, lng: 18.3052838 },
    { lat: 48.0014351, lng: 18.3059851 },
    { lat: 48.0027749, lng: 18.3094766 },
    { lat: 48.0045467, lng: 18.312454 },
    { lat: 48.007518, lng: 18.3176825 },
    { lat: 48.0091051, lng: 18.3201761 },
    { lat: 48.009915, lng: 18.3219684 },
    { lat: 48.0102772, lng: 18.3233677 },
    { lat: 48.0111772, lng: 18.3238957 },
    { lat: 48.0136198, lng: 18.3227105 },
    { lat: 48.0157759, lng: 18.320973 },
    { lat: 48.0161706, lng: 18.3207139 },
    { lat: 48.0171372, lng: 18.3204127 },
    { lat: 48.0177355, lng: 18.3201231 },
    { lat: 48.0186312, lng: 18.319587 },
    { lat: 48.0187352, lng: 18.3194547 },
    { lat: 48.0194158, lng: 18.3199285 },
    { lat: 48.0211182, lng: 18.3203023 },
    { lat: 48.0221143, lng: 18.3208721 },
    { lat: 48.0233489, lng: 18.3214451 },
    { lat: 48.0238491, lng: 18.3205635 },
    { lat: 48.0241478, lng: 18.3203831 },
    { lat: 48.0244884, lng: 18.3203401 },
    { lat: 48.0249393, lng: 18.3204735 },
    { lat: 48.0256902, lng: 18.3212126 },
    { lat: 48.0273639, lng: 18.3235076 },
    { lat: 48.0280596, lng: 18.3242281 },
    { lat: 48.0314956, lng: 18.3282445 },
    { lat: 48.0335593, lng: 18.3293099 },
    { lat: 48.039214, lng: 18.3297626 },
  ],
  Kamenín: [
    { lat: 47.8693831, lng: 18.7023258 },
    { lat: 47.8700392, lng: 18.7022402 },
    { lat: 47.8707731, lng: 18.7030895 },
    { lat: 47.8722418, lng: 18.7050795 },
    { lat: 47.8737901, lng: 18.7060966 },
    { lat: 47.8754041, lng: 18.7067898 },
    { lat: 47.8766621, lng: 18.7070481 },
    { lat: 47.8782845, lng: 18.7071227 },
    { lat: 47.8822393, lng: 18.7060253 },
    { lat: 47.8830571, lng: 18.7055455 },
    { lat: 47.8837652, lng: 18.7039342 },
    { lat: 47.8849024, lng: 18.704756 },
    { lat: 47.8863273, lng: 18.7077324 },
    { lat: 47.8871175, lng: 18.708602 },
    { lat: 47.8890543, lng: 18.7102205 },
    { lat: 47.8900535, lng: 18.7102817 },
    { lat: 47.8906531, lng: 18.7098336 },
    { lat: 47.8906358, lng: 18.7097678 },
    { lat: 47.8875327, lng: 18.6937955 },
    { lat: 47.8889528, lng: 18.6885203 },
    { lat: 47.8908068, lng: 18.6819327 },
    { lat: 47.8911529, lng: 18.6803472 },
    { lat: 47.8926824, lng: 18.6722548 },
    { lat: 47.8929281, lng: 18.6712952 },
    { lat: 47.8933367, lng: 18.6705922 },
    { lat: 47.8934061, lng: 18.6702953 },
    { lat: 47.8934116, lng: 18.669024 },
    { lat: 47.8938695, lng: 18.6649073 },
    { lat: 47.8937113, lng: 18.6648405 },
    { lat: 47.8936393, lng: 18.6646545 },
    { lat: 47.8934816, lng: 18.6644762 },
    { lat: 47.8933066, lng: 18.6644691 },
    { lat: 47.893209, lng: 18.6645828 },
    { lat: 47.8937286, lng: 18.6627696 },
    { lat: 47.8945046, lng: 18.6620234 },
    { lat: 47.8946744, lng: 18.6614555 },
    { lat: 47.8953219, lng: 18.6616621 },
    { lat: 47.8957437, lng: 18.6600025 },
    { lat: 47.8966014, lng: 18.6595951 },
    { lat: 47.8967113, lng: 18.6587117 },
    { lat: 47.8964848, lng: 18.6583232 },
    { lat: 47.8967468, lng: 18.6571646 },
    { lat: 47.8969452, lng: 18.6572877 },
    { lat: 47.8972579, lng: 18.657211 },
    { lat: 47.897603, lng: 18.6574363 },
    { lat: 47.8979837, lng: 18.6580316 },
    { lat: 47.8980473, lng: 18.6585367 },
    { lat: 47.8986488, lng: 18.6589368 },
    { lat: 47.8988643, lng: 18.6584013 },
    { lat: 47.8988991, lng: 18.6578963 },
    { lat: 47.8988445, lng: 18.6578065 },
    { lat: 47.8987893, lng: 18.6578072 },
    { lat: 47.8987157, lng: 18.6579489 },
    { lat: 47.8987387, lng: 18.6581644 },
    { lat: 47.8986835, lng: 18.658287 },
    { lat: 47.8986109, lng: 18.6583432 },
    { lat: 47.8983968, lng: 18.6582881 },
    { lat: 47.8983835, lng: 18.6578345 },
    { lat: 47.898537, lng: 18.657588 },
    { lat: 47.8986127, lng: 18.6573202 },
    { lat: 47.8988068, lng: 18.6574044 },
    { lat: 47.8989099, lng: 18.6573298 },
    { lat: 47.8989024, lng: 18.6571483 },
    { lat: 47.898824, lng: 18.6571591 },
    { lat: 47.8988099, lng: 18.657087 },
    { lat: 47.8989118, lng: 18.6567212 },
    { lat: 47.8987866, lng: 18.6566361 },
    { lat: 47.8986276, lng: 18.6567642 },
    { lat: 47.8985416, lng: 18.6569857 },
    { lat: 47.8984486, lng: 18.657057 },
    { lat: 47.898259, lng: 18.6570359 },
    { lat: 47.8982315, lng: 18.6568859 },
    { lat: 47.8982593, lng: 18.6566281 },
    { lat: 47.898517, lng: 18.6562861 },
    { lat: 47.8980941, lng: 18.6541281 },
    { lat: 47.8985602, lng: 18.6538847 },
    { lat: 47.8990459, lng: 18.6533159 },
    { lat: 47.8992172, lng: 18.6528584 },
    { lat: 47.8992949, lng: 18.6517982 },
    { lat: 47.8992563, lng: 18.6509664 },
    { lat: 47.8991895, lng: 18.6500697 },
    { lat: 47.8990039, lng: 18.6492092 },
    { lat: 47.8987622, lng: 18.6489764 },
    { lat: 47.8984336, lng: 18.6484484 },
    { lat: 47.8980257, lng: 18.6476069 },
    { lat: 47.8977661, lng: 18.6468382 },
    { lat: 47.8975288, lng: 18.6457748 },
    { lat: 47.8975794, lng: 18.644734 },
    { lat: 47.8977953, lng: 18.6437728 },
    { lat: 47.8981691, lng: 18.6432192 },
    { lat: 47.8984461, lng: 18.6429858 },
    { lat: 47.8989962, lng: 18.6426876 },
    { lat: 47.8990389, lng: 18.6420238 },
    { lat: 47.8991162, lng: 18.6417632 },
    { lat: 47.8992891, lng: 18.6414612 },
    { lat: 47.8996691, lng: 18.6413349 },
    { lat: 47.8998326, lng: 18.6413774 },
    { lat: 47.8999064, lng: 18.6412181 },
    { lat: 47.8998618, lng: 18.641002 },
    { lat: 47.8999662, lng: 18.640846 },
    { lat: 47.9001696, lng: 18.6408946 },
    { lat: 47.9004432, lng: 18.6411365 },
    { lat: 47.9005281, lng: 18.6414493 },
    { lat: 47.9006018, lng: 18.6415623 },
    { lat: 47.9007844, lng: 18.6414946 },
    { lat: 47.9008532, lng: 18.6413303 },
    { lat: 47.9010572, lng: 18.6411219 },
    { lat: 47.9011739, lng: 18.6410909 },
    { lat: 47.9016049, lng: 18.6416485 },
    { lat: 47.9019491, lng: 18.6417588 },
    { lat: 47.9021922, lng: 18.641688 },
    { lat: 47.9022384, lng: 18.641602 },
    { lat: 47.9022195, lng: 18.64107 },
    { lat: 47.9020081, lng: 18.6406691 },
    { lat: 47.9019491, lng: 18.6401244 },
    { lat: 47.9020487, lng: 18.6400045 },
    { lat: 47.9021892, lng: 18.6402656 },
    { lat: 47.9023123, lng: 18.6403091 },
    { lat: 47.9023454, lng: 18.6402351 },
    { lat: 47.9023192, lng: 18.6401272 },
    { lat: 47.9023552, lng: 18.6400818 },
    { lat: 47.9022918, lng: 18.6397674 },
    { lat: 47.9023587, lng: 18.6397109 },
    { lat: 47.9023493, lng: 18.6394136 },
    { lat: 47.9022146, lng: 18.6392404 },
    { lat: 47.9023201, lng: 18.638653 },
    { lat: 47.9022435, lng: 18.6384053 },
    { lat: 47.9023363, lng: 18.6382257 },
    { lat: 47.9025622, lng: 18.638048 },
    { lat: 47.90284, lng: 18.6379709 },
    { lat: 47.9029214, lng: 18.6376606 },
    { lat: 47.9026978, lng: 18.6372436 },
    { lat: 47.9028657, lng: 18.6369727 },
    { lat: 47.9029729, lng: 18.6365988 },
    { lat: 47.9029353, lng: 18.6364888 },
    { lat: 47.9027067, lng: 18.6362503 },
    { lat: 47.9027022, lng: 18.6361847 },
    { lat: 47.9028258, lng: 18.6359083 },
    { lat: 47.9031866, lng: 18.6355337 },
    { lat: 47.9032595, lng: 18.6356085 },
    { lat: 47.9036211, lng: 18.6352492 },
    { lat: 47.9039321, lng: 18.6345811 },
    { lat: 47.9038328, lng: 18.6342933 },
    { lat: 47.9037724, lng: 18.6342999 },
    { lat: 47.9037095, lng: 18.6342048 },
    { lat: 47.9037709, lng: 18.6340259 },
    { lat: 47.9038747, lng: 18.6339655 },
    { lat: 47.9040345, lng: 18.6337322 },
    { lat: 47.9041387, lng: 18.6334061 },
    { lat: 47.9043656, lng: 18.6330391 },
    { lat: 47.904375, lng: 18.6328176 },
    { lat: 47.9042734, lng: 18.6325493 },
    { lat: 47.9043596, lng: 18.6321843 },
    { lat: 47.9042901, lng: 18.6316549 },
    { lat: 47.9043665, lng: 18.6314825 },
    { lat: 47.9044528, lng: 18.631515 },
    { lat: 47.904844, lng: 18.6310769 },
    { lat: 47.9051214, lng: 18.6308508 },
    { lat: 47.905123, lng: 18.6307764 },
    { lat: 47.9059786, lng: 18.6302302 },
    { lat: 47.9063993, lng: 18.6296113 },
    { lat: 47.9065492, lng: 18.629236 },
    { lat: 47.9067237, lng: 18.6283196 },
    { lat: 47.9065072, lng: 18.6281974 },
    { lat: 47.9064389, lng: 18.6279382 },
    { lat: 47.9064387, lng: 18.6276282 },
    { lat: 47.9065599, lng: 18.626973 },
    { lat: 47.9069743, lng: 18.6259267 },
    { lat: 47.9070795, lng: 18.6257674 },
    { lat: 47.9073115, lng: 18.6256796 },
    { lat: 47.9076299, lng: 18.6263678 },
    { lat: 47.9078722, lng: 18.6266542 },
    { lat: 47.9080585, lng: 18.6266774 },
    { lat: 47.9081525, lng: 18.6265627 },
    { lat: 47.9083355, lng: 18.6265254 },
    { lat: 47.9085017, lng: 18.6262023 },
    { lat: 47.9085973, lng: 18.6258663 },
    { lat: 47.9086666, lng: 18.6258326 },
    { lat: 47.9088743, lng: 18.6253417 },
    { lat: 47.9093306, lng: 18.6250032 },
    { lat: 47.9094269, lng: 18.6250313 },
    { lat: 47.9097665, lng: 18.6246791 },
    { lat: 47.9097894, lng: 18.624605 },
    { lat: 47.9096846, lng: 18.6245256 },
    { lat: 47.9096427, lng: 18.6243788 },
    { lat: 47.9098062, lng: 18.6235293 },
    { lat: 47.9097443, lng: 18.6233871 },
    { lat: 47.9097213, lng: 18.6230689 },
    { lat: 47.9099281, lng: 18.6227675 },
    { lat: 47.9098575, lng: 18.6225089 },
    { lat: 47.9101215, lng: 18.6220544 },
    { lat: 47.9101401, lng: 18.6219404 },
    { lat: 47.9104114, lng: 18.6219536 },
    { lat: 47.9105594, lng: 18.6217283 },
    { lat: 47.9105447, lng: 18.6216454 },
    { lat: 47.9107588, lng: 18.62152 },
    { lat: 47.9109186, lng: 18.6213034 },
    { lat: 47.9108997, lng: 18.621227 },
    { lat: 47.9110175, lng: 18.6210731 },
    { lat: 47.9111069, lng: 18.6207498 },
    { lat: 47.9110742, lng: 18.6206057 },
    { lat: 47.9112438, lng: 18.6203001 },
    { lat: 47.9112757, lng: 18.6200793 },
    { lat: 47.9113995, lng: 18.6198503 },
    { lat: 47.9115675, lng: 18.6197617 },
    { lat: 47.911527, lng: 18.6196837 },
    { lat: 47.911601, lng: 18.6195548 },
    { lat: 47.9116279, lng: 18.6193638 },
    { lat: 47.9117776, lng: 18.6191696 },
    { lat: 47.9118437, lng: 18.618732 },
    { lat: 47.9120197, lng: 18.6182544 },
    { lat: 47.9121107, lng: 18.6177142 },
    { lat: 47.9120956, lng: 18.6174169 },
    { lat: 47.9121782, lng: 18.6169875 },
    { lat: 47.9120604, lng: 18.6167947 },
    { lat: 47.9120938, lng: 18.6164985 },
    { lat: 47.9119312, lng: 18.6163301 },
    { lat: 47.9118692, lng: 18.6161642 },
    { lat: 47.9119017, lng: 18.6159996 },
    { lat: 47.911852, lng: 18.615954 },
    { lat: 47.9117731, lng: 18.6155023 },
    { lat: 47.9118849, lng: 18.61532 },
    { lat: 47.9118358, lng: 18.6152271 },
    { lat: 47.9120775, lng: 18.614841 },
    { lat: 47.9121459, lng: 18.6144007 },
    { lat: 47.9122472, lng: 18.6142306 },
    { lat: 47.9122596, lng: 18.613861 },
    { lat: 47.9123583, lng: 18.6138079 },
    { lat: 47.9124614, lng: 18.6134238 },
    { lat: 47.9126199, lng: 18.6131247 },
    { lat: 47.9125422, lng: 18.6130051 },
    { lat: 47.9127313, lng: 18.612587 },
    { lat: 47.9127555, lng: 18.6123285 },
    { lat: 47.9128901, lng: 18.6121885 },
    { lat: 47.9128987, lng: 18.612018 },
    { lat: 47.9130242, lng: 18.6119609 },
    { lat: 47.9131182, lng: 18.6114949 },
    { lat: 47.9130491, lng: 18.6113852 },
    { lat: 47.9131575, lng: 18.6111189 },
    { lat: 47.9133456, lng: 18.610972 },
    { lat: 47.9134075, lng: 18.6107609 },
    { lat: 47.9134368, lng: 18.6106611 },
    { lat: 47.9137165, lng: 18.6104936 },
    { lat: 47.9139934, lng: 18.6101123 },
    { lat: 47.9141641, lng: 18.609669 },
    { lat: 47.9142458, lng: 18.6095719 },
    { lat: 47.9142469, lng: 18.6094705 },
    { lat: 47.9141937, lng: 18.6094242 },
    { lat: 47.9141945, lng: 18.6090894 },
    { lat: 47.9142764, lng: 18.6087332 },
    { lat: 47.9142574, lng: 18.6086318 },
    { lat: 47.9144009, lng: 18.6081412 },
    { lat: 47.9144735, lng: 18.6080244 },
    { lat: 47.9144913, lng: 18.6077322 },
    { lat: 47.9145717, lng: 18.6075519 },
    { lat: 47.914512, lng: 18.6074723 },
    { lat: 47.914559, lng: 18.607384 },
    { lat: 47.9145425, lng: 18.607123 },
    { lat: 47.9147884, lng: 18.6068777 },
    { lat: 47.9148504, lng: 18.6066504 },
    { lat: 47.9148142, lng: 18.6065427 },
    { lat: 47.9149907, lng: 18.6063296 },
    { lat: 47.9151208, lng: 18.6060685 },
    { lat: 47.9150907, lng: 18.6059888 },
    { lat: 47.9152028, lng: 18.6058145 },
    { lat: 47.9153191, lng: 18.6053008 },
    { lat: 47.9154461, lng: 18.605027 },
    { lat: 47.915408, lng: 18.6049085 },
    { lat: 47.9155586, lng: 18.6045693 },
    { lat: 47.9157731, lng: 18.6038288 },
    { lat: 47.9158407, lng: 18.6033171 },
    { lat: 47.9159335, lng: 18.6029708 },
    { lat: 47.916136, lng: 18.6024687 },
    { lat: 47.9161697, lng: 18.6021563 },
    { lat: 47.9163757, lng: 18.6017583 },
    { lat: 47.9163897, lng: 18.6016644 },
    { lat: 47.9163017, lng: 18.601602 },
    { lat: 47.9163083, lng: 18.6015593 },
    { lat: 47.9157061, lng: 18.6014359 },
    { lat: 47.9150457, lng: 18.6009953 },
    { lat: 47.9123895, lng: 18.5989188 },
    { lat: 47.9112598, lng: 18.5981278 },
    { lat: 47.9066466, lng: 18.5937519 },
    { lat: 47.9042242, lng: 18.5919124 },
    { lat: 47.9023763, lng: 18.5906978 },
    { lat: 47.8987337, lng: 18.5909774 },
    { lat: 47.8956445, lng: 18.5908164 },
    { lat: 47.8905126, lng: 18.5939013 },
    { lat: 47.8880487, lng: 18.5931164 },
    { lat: 47.8863353, lng: 18.5918613 },
    { lat: 47.882837, lng: 18.5892043 },
    { lat: 47.8819524, lng: 18.5875169 },
    { lat: 47.8819232, lng: 18.587459 },
    { lat: 47.8817908, lng: 18.5876357 },
    { lat: 47.8815965, lng: 18.5878153 },
    { lat: 47.8814754, lng: 18.5880756 },
    { lat: 47.8813789, lng: 18.5885775 },
    { lat: 47.8812147, lng: 18.5888501 },
    { lat: 47.8809131, lng: 18.5896028 },
    { lat: 47.880247, lng: 18.5902681 },
    { lat: 47.8797524, lng: 18.5912244 },
    { lat: 47.8792565, lng: 18.5917446 },
    { lat: 47.8789859, lng: 18.5919303 },
    { lat: 47.8788718, lng: 18.5921504 },
    { lat: 47.8786011, lng: 18.5923393 },
    { lat: 47.8785214, lng: 18.5924677 },
    { lat: 47.87829, lng: 18.5932031 },
    { lat: 47.8779644, lng: 18.593498 },
    { lat: 47.8773293, lng: 18.5942539 },
    { lat: 47.8770966, lng: 18.5952758 },
    { lat: 47.8766582, lng: 18.596104 },
    { lat: 47.876486, lng: 18.5962774 },
    { lat: 47.8763272, lng: 18.5967506 },
    { lat: 47.8757658, lng: 18.5977023 },
    { lat: 47.8751344, lng: 18.5981615 },
    { lat: 47.8750521, lng: 18.5985519 },
    { lat: 47.8747057, lng: 18.5989404 },
    { lat: 47.8746411, lng: 18.5993874 },
    { lat: 47.874197, lng: 18.600232 },
    { lat: 47.8736229, lng: 18.6008704 },
    { lat: 47.8735719, lng: 18.6010523 },
    { lat: 47.873595, lng: 18.6014721 },
    { lat: 47.8732521, lng: 18.6018439 },
    { lat: 47.8726128, lng: 18.6028956 },
    { lat: 47.8723045, lng: 18.603261 },
    { lat: 47.8721129, lng: 18.6038565 },
    { lat: 47.8718199, lng: 18.6044566 },
    { lat: 47.8715662, lng: 18.6047051 },
    { lat: 47.8707843, lng: 18.6061235 },
    { lat: 47.8702757, lng: 18.6068424 },
    { lat: 47.8697535, lng: 18.607212 },
    { lat: 47.8695524, lng: 18.6076997 },
    { lat: 47.8692136, lng: 18.607694 },
    { lat: 47.8689292, lng: 18.6078781 },
    { lat: 47.868723, lng: 18.6081486 },
    { lat: 47.8684243, lng: 18.6083558 },
    { lat: 47.8681995, lng: 18.6086581 },
    { lat: 47.8682883, lng: 18.6086526 },
    { lat: 47.8704065, lng: 18.6098244 },
    { lat: 47.8713904, lng: 18.6104476 },
    { lat: 47.8709967, lng: 18.6131682 },
    { lat: 47.8691736, lng: 18.6313655 },
    { lat: 47.8677012, lng: 18.6452898 },
    { lat: 47.8679943, lng: 18.6465096 },
    { lat: 47.8688553, lng: 18.6471594 },
    { lat: 47.8696452, lng: 18.646895 },
    { lat: 47.8700372, lng: 18.6468672 },
    { lat: 47.8706393, lng: 18.6470905 },
    { lat: 47.8709317, lng: 18.6481941 },
    { lat: 47.8710816, lng: 18.6482544 },
    { lat: 47.871344, lng: 18.6485383 },
    { lat: 47.8718832, lng: 18.6489301 },
    { lat: 47.8720952, lng: 18.6492425 },
    { lat: 47.8721226, lng: 18.6493516 },
    { lat: 47.8719957, lng: 18.6500449 },
    { lat: 47.8717627, lng: 18.6506539 },
    { lat: 47.8710974, lng: 18.6508625 },
    { lat: 47.8711402, lng: 18.6512764 },
    { lat: 47.8710931, lng: 18.6515083 },
    { lat: 47.871107, lng: 18.6519935 },
    { lat: 47.8716315, lng: 18.6518082 },
    { lat: 47.8719094, lng: 18.6518981 },
    { lat: 47.8721869, lng: 18.6521907 },
    { lat: 47.8720775, lng: 18.6523953 },
    { lat: 47.8721854, lng: 18.6538391 },
    { lat: 47.8725276, lng: 18.6564181 },
    { lat: 47.8724448, lng: 18.657261 },
    { lat: 47.8717543, lng: 18.6566758 },
    { lat: 47.8710008, lng: 18.6562802 },
    { lat: 47.8703147, lng: 18.6562323 },
    { lat: 47.8700963, lng: 18.6563014 },
    { lat: 47.8696778, lng: 18.6565716 },
    { lat: 47.8694633, lng: 18.6567962 },
    { lat: 47.869218, lng: 18.6573873 },
    { lat: 47.8689838, lng: 18.6583048 },
    { lat: 47.8689346, lng: 18.6586648 },
    { lat: 47.8687735, lng: 18.6591654 },
    { lat: 47.8684603, lng: 18.6593389 },
    { lat: 47.8670879, lng: 18.6596748 },
    { lat: 47.8670272, lng: 18.6597626 },
    { lat: 47.8667887, lng: 18.6606879 },
    { lat: 47.8665047, lng: 18.6606504 },
    { lat: 47.8662354, lng: 18.6607357 },
    { lat: 47.8659151, lng: 18.6610699 },
    { lat: 47.8661102, lng: 18.6615242 },
    { lat: 47.8672202, lng: 18.6627715 },
    { lat: 47.8681955, lng: 18.6638675 },
    { lat: 47.8682517, lng: 18.6637803 },
    { lat: 47.868754, lng: 18.6638939 },
    { lat: 47.8691739, lng: 18.6644582 },
    { lat: 47.8694058, lng: 18.6648687 },
    { lat: 47.8697678, lng: 18.6650315 },
    { lat: 47.8698975, lng: 18.6655716 },
    { lat: 47.8703471, lng: 18.6682009 },
    { lat: 47.8705324, lng: 18.6694548 },
    { lat: 47.8707122, lng: 18.6713136 },
    { lat: 47.8708493, lng: 18.6731099 },
    { lat: 47.8719881, lng: 18.6880272 },
    { lat: 47.8722799, lng: 18.6931338 },
    { lat: 47.8725671, lng: 18.6973185 },
    { lat: 47.8726979, lng: 18.6986936 },
    { lat: 47.869248, lng: 18.6991393 },
    { lat: 47.8692167, lng: 18.6991417 },
    { lat: 47.8692247, lng: 18.7011888 },
    { lat: 47.8693831, lng: 18.7023258 },
  ],
  Radava: [
    { lat: 48.1173215, lng: 18.3105321 },
    { lat: 48.117099, lng: 18.3101311 },
    { lat: 48.1157511, lng: 18.3071815 },
    { lat: 48.1099411, lng: 18.3065063 },
    { lat: 48.1097286, lng: 18.300138 },
    { lat: 48.1069263, lng: 18.299808 },
    { lat: 48.1068481, lng: 18.2997432 },
    { lat: 48.1015726, lng: 18.2992989 },
    { lat: 48.0997127, lng: 18.2990381 },
    { lat: 48.0995402, lng: 18.29892 },
    { lat: 48.0981632, lng: 18.2989541 },
    { lat: 48.0980286, lng: 18.2980031 },
    { lat: 48.0978285, lng: 18.2973474 },
    { lat: 48.0974858, lng: 18.2965039 },
    { lat: 48.0975908, lng: 18.2963482 },
    { lat: 48.0977244, lng: 18.2958861 },
    { lat: 48.0980033, lng: 18.2943927 },
    { lat: 48.0984878, lng: 18.2927907 },
    { lat: 48.0985503, lng: 18.2923059 },
    { lat: 48.09842, lng: 18.2902128 },
    { lat: 48.0983116, lng: 18.2893658 },
    { lat: 48.0980258, lng: 18.2895365 },
    { lat: 48.0971611, lng: 18.289824 },
    { lat: 48.0975618, lng: 18.2923027 },
    { lat: 48.0978387, lng: 18.2923683 },
    { lat: 48.097972, lng: 18.2930445 },
    { lat: 48.0975396, lng: 18.2948653 },
    { lat: 48.0974455, lng: 18.2956453 },
    { lat: 48.0973369, lng: 18.2960143 },
    { lat: 48.0970558, lng: 18.295542 },
    { lat: 48.0967289, lng: 18.2951302 },
    { lat: 48.0948588, lng: 18.2933151 },
    { lat: 48.0939322, lng: 18.292504 },
    { lat: 48.0919969, lng: 18.2904973 },
    { lat: 48.0919116, lng: 18.2905019 },
    { lat: 48.0917067, lng: 18.2906901 },
    { lat: 48.0916455, lng: 18.2908793 },
    { lat: 48.0915032, lng: 18.2909166 },
    { lat: 48.0915635, lng: 18.2913574 },
    { lat: 48.09074, lng: 18.2921658 },
    { lat: 48.0917302, lng: 18.2983727 },
    { lat: 48.0910287, lng: 18.2988297 },
    { lat: 48.0904216, lng: 18.2973756 },
    { lat: 48.0898921, lng: 18.2978607 },
    { lat: 48.0904524, lng: 18.2992114 },
    { lat: 48.0896027, lng: 18.2997773 },
    { lat: 48.0900624, lng: 18.3008517 },
    { lat: 48.0901271, lng: 18.3009148 },
    { lat: 48.0885427, lng: 18.3017777 },
    { lat: 48.085403, lng: 18.3037154 },
    { lat: 48.0793536, lng: 18.3099478 },
    { lat: 48.0787047, lng: 18.3088814 },
    { lat: 48.0777506, lng: 18.3102711 },
    { lat: 48.0779947, lng: 18.3105706 },
    { lat: 48.0776222, lng: 18.3112002 },
    { lat: 48.0778543, lng: 18.3114874 },
    { lat: 48.077723, lng: 18.3116185 },
    { lat: 48.0765565, lng: 18.3134575 },
    { lat: 48.073439, lng: 18.3162568 },
    { lat: 48.0733308, lng: 18.316714 },
    { lat: 48.0731557, lng: 18.3171417 },
    { lat: 48.0729666, lng: 18.3180188 },
    { lat: 48.0724802, lng: 18.3192989 },
    { lat: 48.0724403, lng: 18.3195901 },
    { lat: 48.0722348, lng: 18.3198194 },
    { lat: 48.0720644, lng: 18.3205114 },
    { lat: 48.0718851, lng: 18.3207924 },
    { lat: 48.0717882, lng: 18.3211477 },
    { lat: 48.0720088, lng: 18.3216318 },
    { lat: 48.0720377, lng: 18.3220959 },
    { lat: 48.0724912, lng: 18.3235149 },
    { lat: 48.0741892, lng: 18.3243474 },
    { lat: 48.0752932, lng: 18.3264518 },
    { lat: 48.0786791, lng: 18.3278499 },
    { lat: 48.0795141, lng: 18.3282469 },
    { lat: 48.0803788, lng: 18.3287495 },
    { lat: 48.080541, lng: 18.3289444 },
    { lat: 48.0813545, lng: 18.3303631 },
    { lat: 48.0818642, lng: 18.3313481 },
    { lat: 48.0821936, lng: 18.3314861 },
    { lat: 48.0829051, lng: 18.3316575 },
    { lat: 48.0830907, lng: 18.3316668 },
    { lat: 48.0833836, lng: 18.3315583 },
    { lat: 48.0849925, lng: 18.330473 },
    { lat: 48.0861843, lng: 18.3299454 },
    { lat: 48.0890775, lng: 18.3282303 },
    { lat: 48.0905292, lng: 18.3274531 },
    { lat: 48.0923622, lng: 18.3266931 },
    { lat: 48.0940961, lng: 18.326295 },
    { lat: 48.0964217, lng: 18.3260969 },
    { lat: 48.0972279, lng: 18.3259104 },
    { lat: 48.0978149, lng: 18.32562 },
    { lat: 48.1005757, lng: 18.3229123 },
    { lat: 48.102232, lng: 18.3214685 },
    { lat: 48.1045532, lng: 18.3196804 },
    { lat: 48.1060504, lng: 18.318688 },
    { lat: 48.1090842, lng: 18.3163379 },
    { lat: 48.1111995, lng: 18.3149513 },
    { lat: 48.1135842, lng: 18.3132643 },
    { lat: 48.1161385, lng: 18.3121992 },
    { lat: 48.1170662, lng: 18.3118888 },
    { lat: 48.1172451, lng: 18.3118003 },
    { lat: 48.1173215, lng: 18.3105321 },
  ],
  Kmeťovo: [
    { lat: 48.1791115, lng: 18.2544083 },
    { lat: 48.1790829, lng: 18.2544208 },
    { lat: 48.1601716, lng: 18.2622785 },
    { lat: 48.16005, lng: 18.2627939 },
    { lat: 48.1567788, lng: 18.2759727 },
    { lat: 48.1567059, lng: 18.2761185 },
    { lat: 48.1568547, lng: 18.2764874 },
    { lat: 48.1572881, lng: 18.2768039 },
    { lat: 48.1573359, lng: 18.276891 },
    { lat: 48.1573783, lng: 18.2771757 },
    { lat: 48.1573526, lng: 18.2775356 },
    { lat: 48.1580323, lng: 18.2780526 },
    { lat: 48.1580654, lng: 18.2787636 },
    { lat: 48.1584972, lng: 18.279626 },
    { lat: 48.1579627, lng: 18.2813392 },
    { lat: 48.1581277, lng: 18.2815403 },
    { lat: 48.1583316, lng: 18.2821432 },
    { lat: 48.1582654, lng: 18.2829963 },
    { lat: 48.1585031, lng: 18.2833807 },
    { lat: 48.1585806, lng: 18.2836895 },
    { lat: 48.1585759, lng: 18.2839817 },
    { lat: 48.158618, lng: 18.2841632 },
    { lat: 48.1589733, lng: 18.2844385 },
    { lat: 48.1591188, lng: 18.2847899 },
    { lat: 48.1592112, lng: 18.2854194 },
    { lat: 48.1592558, lng: 18.2860781 },
    { lat: 48.1592278, lng: 18.2862566 },
    { lat: 48.1593175, lng: 18.2864444 },
    { lat: 48.1594495, lng: 18.28656 },
    { lat: 48.1596008, lng: 18.2865071 },
    { lat: 48.1597265, lng: 18.2862945 },
    { lat: 48.1598788, lng: 18.2857844 },
    { lat: 48.160034, lng: 18.285538 },
    { lat: 48.1603515, lng: 18.285352 },
    { lat: 48.160527, lng: 18.2854808 },
    { lat: 48.1606797, lng: 18.2859009 },
    { lat: 48.1606888, lng: 18.2861177 },
    { lat: 48.1606369, lng: 18.2863537 },
    { lat: 48.160476, lng: 18.2867147 },
    { lat: 48.1603506, lng: 18.2872453 },
    { lat: 48.1603417, lng: 18.2875225 },
    { lat: 48.1604519, lng: 18.2878685 },
    { lat: 48.1606704, lng: 18.2880387 },
    { lat: 48.161074, lng: 18.2880927 },
    { lat: 48.1614732, lng: 18.2876937 },
    { lat: 48.1620192, lng: 18.2877118 },
    { lat: 48.162133, lng: 18.2877668 },
    { lat: 48.162396, lng: 18.2881222 },
    { lat: 48.1628336, lng: 18.2881986 },
    { lat: 48.1629112, lng: 18.288311 },
    { lat: 48.1629475, lng: 18.2884905 },
    { lat: 48.1628728, lng: 18.2889612 },
    { lat: 48.1626339, lng: 18.2895361 },
    { lat: 48.1625059, lng: 18.2897822 },
    { lat: 48.1624765, lng: 18.2897446 },
    { lat: 48.162528, lng: 18.2900904 },
    { lat: 48.1629265, lng: 18.2907836 },
    { lat: 48.1633346, lng: 18.291704 },
    { lat: 48.1637002, lng: 18.2922753 },
    { lat: 48.1638026, lng: 18.2927007 },
    { lat: 48.164077, lng: 18.2933739 },
    { lat: 48.1640297, lng: 18.2936506 },
    { lat: 48.1640956, lng: 18.293955 },
    { lat: 48.1642934, lng: 18.2941604 },
    { lat: 48.1646141, lng: 18.2943014 },
    { lat: 48.1646951, lng: 18.2947911 },
    { lat: 48.1649213, lng: 18.2946204 },
    { lat: 48.1651542, lng: 18.2947072 },
    { lat: 48.1653551, lng: 18.294591 },
    { lat: 48.1654628, lng: 18.2946207 },
    { lat: 48.1656769, lng: 18.2949863 },
    { lat: 48.1656878, lng: 18.2952595 },
    { lat: 48.165452, lng: 18.2961245 },
    { lat: 48.1653246, lng: 18.2967943 },
    { lat: 48.1653397, lng: 18.2970416 },
    { lat: 48.1657583, lng: 18.2971014 },
    { lat: 48.1658371, lng: 18.2972918 },
    { lat: 48.1661057, lng: 18.2965456 },
    { lat: 48.1677517, lng: 18.2976048 },
    { lat: 48.1678919, lng: 18.2985574 },
    { lat: 48.1678442, lng: 18.298766 },
    { lat: 48.1676482, lng: 18.2991658 },
    { lat: 48.1693236, lng: 18.2996007 },
    { lat: 48.1695157, lng: 18.2996221 },
    { lat: 48.1701803, lng: 18.2994649 },
    { lat: 48.1713261, lng: 18.2997509 },
    { lat: 48.1714804, lng: 18.2996672 },
    { lat: 48.1720242, lng: 18.2995665 },
    { lat: 48.1721205, lng: 18.2994768 },
    { lat: 48.1722591, lng: 18.2991762 },
    { lat: 48.1724066, lng: 18.2991139 },
    { lat: 48.1727464, lng: 18.2987017 },
    { lat: 48.1728774, lng: 18.2986863 },
    { lat: 48.173173, lng: 18.299037 },
    { lat: 48.173368, lng: 18.2994087 },
    { lat: 48.1735291, lng: 18.2993269 },
    { lat: 48.1738123, lng: 18.2988718 },
    { lat: 48.1739845, lng: 18.2987278 },
    { lat: 48.1739382, lng: 18.29861 },
    { lat: 48.1735533, lng: 18.2985846 },
    { lat: 48.1734669, lng: 18.2983599 },
    { lat: 48.1735155, lng: 18.2981225 },
    { lat: 48.1733378, lng: 18.297679 },
    { lat: 48.173334, lng: 18.297419 },
    { lat: 48.1734254, lng: 18.2974172 },
    { lat: 48.1735541, lng: 18.2975174 },
    { lat: 48.1737231, lng: 18.2973543 },
    { lat: 48.1737666, lng: 18.2971119 },
    { lat: 48.1737268, lng: 18.2967303 },
    { lat: 48.1738198, lng: 18.2963437 },
    { lat: 48.1738202, lng: 18.2961521 },
    { lat: 48.1743182, lng: 18.2949405 },
    { lat: 48.1745571, lng: 18.2942075 },
    { lat: 48.1746992, lng: 18.294067 },
    { lat: 48.1747512, lng: 18.2938518 },
    { lat: 48.174611, lng: 18.2936502 },
    { lat: 48.1744762, lng: 18.2936198 },
    { lat: 48.1745389, lng: 18.2931791 },
    { lat: 48.174718, lng: 18.2930158 },
    { lat: 48.1751417, lng: 18.2930294 },
    { lat: 48.1751943, lng: 18.2928611 },
    { lat: 48.1751912, lng: 18.2926511 },
    { lat: 48.1751152, lng: 18.2925665 },
    { lat: 48.1749854, lng: 18.292546 },
    { lat: 48.1748546, lng: 18.2926434 },
    { lat: 48.1746806, lng: 18.2925826 },
    { lat: 48.1746448, lng: 18.2924486 },
    { lat: 48.1747359, lng: 18.2922644 },
    { lat: 48.1751058, lng: 18.2919067 },
    { lat: 48.1756099, lng: 18.2916052 },
    { lat: 48.1756146, lng: 18.2915307 },
    { lat: 48.1755779, lng: 18.2913852 },
    { lat: 48.1751031, lng: 18.2915252 },
    { lat: 48.1749892, lng: 18.291134 },
    { lat: 48.1749928, lng: 18.2910572 },
    { lat: 48.1752643, lng: 18.2906173 },
    { lat: 48.175486, lng: 18.2904507 },
    { lat: 48.1753876, lng: 18.2899719 },
    { lat: 48.1753895, lng: 18.2899101 },
    { lat: 48.1754858, lng: 18.2898738 },
    { lat: 48.175418, lng: 18.2888527 },
    { lat: 48.1753362, lng: 18.2880515 },
    { lat: 48.174674, lng: 18.2869932 },
    { lat: 48.174903, lng: 18.2860684 },
    { lat: 48.174962, lng: 18.2854861 },
    { lat: 48.1749942, lng: 18.2848838 },
    { lat: 48.1747544, lng: 18.2838117 },
    { lat: 48.1750075, lng: 18.2827763 },
    { lat: 48.174962, lng: 18.2823778 },
    { lat: 48.1749887, lng: 18.2820738 },
    { lat: 48.1752758, lng: 18.2812784 },
    { lat: 48.1754638, lng: 18.2812507 },
    { lat: 48.1754492, lng: 18.2794646 },
    { lat: 48.1753994, lng: 18.2793055 },
    { lat: 48.1757101, lng: 18.272987 },
    { lat: 48.1758147, lng: 18.2719201 },
    { lat: 48.1760892, lng: 18.2700812 },
    { lat: 48.1764968, lng: 18.2678275 },
    { lat: 48.1780023, lng: 18.261911 },
    { lat: 48.1788519, lng: 18.2581111 },
    { lat: 48.1788564, lng: 18.2565433 },
    { lat: 48.179021, lng: 18.2555011 },
    { lat: 48.1791115, lng: 18.2544083 },
  ],
  Bíňa: [
    { lat: 47.947442, lng: 18.617415 },
    { lat: 47.947074, lng: 18.616617 },
    { lat: 47.946872, lng: 18.616174 },
    { lat: 47.946502, lng: 18.615243 },
    { lat: 47.946155, lng: 18.614476 },
    { lat: 47.94582, lng: 18.6138 },
    { lat: 47.945202, lng: 18.612231 },
    { lat: 47.9449, lng: 18.611561 },
    { lat: 47.943932, lng: 18.60932 },
    { lat: 47.94371, lng: 18.608904 },
    { lat: 47.943639, lng: 18.608782 },
    { lat: 47.942734, lng: 18.606821 },
    { lat: 47.942, lng: 18.605263 },
    { lat: 47.941634, lng: 18.604294 },
    { lat: 47.941343, lng: 18.603631 },
    { lat: 47.94056, lng: 18.602585 },
    { lat: 47.939932, lng: 18.601813 },
    { lat: 47.93949, lng: 18.601309 },
    { lat: 47.938663, lng: 18.600595 },
    { lat: 47.936682, lng: 18.598733 },
    { lat: 47.9363544, lng: 18.5984336 },
    { lat: 47.9359373, lng: 18.5981688 },
    { lat: 47.9352876, lng: 18.5975924 },
    { lat: 47.9317985, lng: 18.595492 },
    { lat: 47.9300892, lng: 18.5946178 },
    { lat: 47.929589, lng: 18.5944662 },
    { lat: 47.9287184, lng: 18.5958483 },
    { lat: 47.9261217, lng: 18.5941381 },
    { lat: 47.9251832, lng: 18.5951271 },
    { lat: 47.9244871, lng: 18.5949363 },
    { lat: 47.9220475, lng: 18.5939316 },
    { lat: 47.9208795, lng: 18.5931628 },
    { lat: 47.9200575, lng: 18.5925323 },
    { lat: 47.9188056, lng: 18.5944665 },
    { lat: 47.9178664, lng: 18.5966906 },
    { lat: 47.9178917, lng: 18.5977287 },
    { lat: 47.9178232, lng: 18.5992166 },
    { lat: 47.9173615, lng: 18.5994724 },
    { lat: 47.9169618, lng: 18.6005159 },
    { lat: 47.9164544, lng: 18.6015905 },
    { lat: 47.9163083, lng: 18.6015593 },
    { lat: 47.9163017, lng: 18.601602 },
    { lat: 47.9163897, lng: 18.6016644 },
    { lat: 47.9163757, lng: 18.6017583 },
    { lat: 47.9161697, lng: 18.6021563 },
    { lat: 47.916136, lng: 18.6024687 },
    { lat: 47.9159335, lng: 18.6029708 },
    { lat: 47.9158407, lng: 18.6033171 },
    { lat: 47.9157731, lng: 18.6038288 },
    { lat: 47.9155586, lng: 18.6045693 },
    { lat: 47.915408, lng: 18.6049085 },
    { lat: 47.9154461, lng: 18.605027 },
    { lat: 47.9153191, lng: 18.6053008 },
    { lat: 47.9152028, lng: 18.6058145 },
    { lat: 47.9150907, lng: 18.6059888 },
    { lat: 47.9151208, lng: 18.6060685 },
    { lat: 47.9149907, lng: 18.6063296 },
    { lat: 47.9148142, lng: 18.6065427 },
    { lat: 47.9148504, lng: 18.6066504 },
    { lat: 47.9147884, lng: 18.6068777 },
    { lat: 47.9145425, lng: 18.607123 },
    { lat: 47.914559, lng: 18.607384 },
    { lat: 47.914512, lng: 18.6074723 },
    { lat: 47.9145717, lng: 18.6075519 },
    { lat: 47.9144913, lng: 18.6077322 },
    { lat: 47.9144735, lng: 18.6080244 },
    { lat: 47.9144009, lng: 18.6081412 },
    { lat: 47.9142574, lng: 18.6086318 },
    { lat: 47.9142764, lng: 18.6087332 },
    { lat: 47.9141945, lng: 18.6090894 },
    { lat: 47.9141937, lng: 18.6094242 },
    { lat: 47.9142469, lng: 18.6094705 },
    { lat: 47.9142458, lng: 18.6095719 },
    { lat: 47.9141641, lng: 18.609669 },
    { lat: 47.9139934, lng: 18.6101123 },
    { lat: 47.9137165, lng: 18.6104936 },
    { lat: 47.9134368, lng: 18.6106611 },
    { lat: 47.9134075, lng: 18.6107609 },
    { lat: 47.9133456, lng: 18.610972 },
    { lat: 47.9131575, lng: 18.6111189 },
    { lat: 47.9130491, lng: 18.6113852 },
    { lat: 47.9131182, lng: 18.6114949 },
    { lat: 47.9130242, lng: 18.6119609 },
    { lat: 47.9128987, lng: 18.612018 },
    { lat: 47.9128901, lng: 18.6121885 },
    { lat: 47.9127555, lng: 18.6123285 },
    { lat: 47.9127313, lng: 18.612587 },
    { lat: 47.9125422, lng: 18.6130051 },
    { lat: 47.9126199, lng: 18.6131247 },
    { lat: 47.9124614, lng: 18.6134238 },
    { lat: 47.9123583, lng: 18.6138079 },
    { lat: 47.9122596, lng: 18.613861 },
    { lat: 47.9122472, lng: 18.6142306 },
    { lat: 47.9121459, lng: 18.6144007 },
    { lat: 47.9120775, lng: 18.614841 },
    { lat: 47.9118358, lng: 18.6152271 },
    { lat: 47.9118849, lng: 18.61532 },
    { lat: 47.9117731, lng: 18.6155023 },
    { lat: 47.911852, lng: 18.615954 },
    { lat: 47.9119017, lng: 18.6159996 },
    { lat: 47.9118692, lng: 18.6161642 },
    { lat: 47.9119312, lng: 18.6163301 },
    { lat: 47.9120938, lng: 18.6164985 },
    { lat: 47.9120604, lng: 18.6167947 },
    { lat: 47.9121782, lng: 18.6169875 },
    { lat: 47.9120956, lng: 18.6174169 },
    { lat: 47.9121107, lng: 18.6177142 },
    { lat: 47.9120197, lng: 18.6182544 },
    { lat: 47.9118437, lng: 18.618732 },
    { lat: 47.9117776, lng: 18.6191696 },
    { lat: 47.9116279, lng: 18.6193638 },
    { lat: 47.911601, lng: 18.6195548 },
    { lat: 47.911527, lng: 18.6196837 },
    { lat: 47.9115675, lng: 18.6197617 },
    { lat: 47.9113995, lng: 18.6198503 },
    { lat: 47.9112757, lng: 18.6200793 },
    { lat: 47.9112438, lng: 18.6203001 },
    { lat: 47.9110742, lng: 18.6206057 },
    { lat: 47.9111069, lng: 18.6207498 },
    { lat: 47.9110175, lng: 18.6210731 },
    { lat: 47.9108997, lng: 18.621227 },
    { lat: 47.9109186, lng: 18.6213034 },
    { lat: 47.9107588, lng: 18.62152 },
    { lat: 47.9105447, lng: 18.6216454 },
    { lat: 47.9105594, lng: 18.6217283 },
    { lat: 47.9104114, lng: 18.6219536 },
    { lat: 47.9101401, lng: 18.6219404 },
    { lat: 47.9101215, lng: 18.6220544 },
    { lat: 47.9098575, lng: 18.6225089 },
    { lat: 47.9099281, lng: 18.6227675 },
    { lat: 47.9097213, lng: 18.6230689 },
    { lat: 47.9097443, lng: 18.6233871 },
    { lat: 47.9098062, lng: 18.6235293 },
    { lat: 47.9096427, lng: 18.6243788 },
    { lat: 47.9096846, lng: 18.6245256 },
    { lat: 47.9097894, lng: 18.624605 },
    { lat: 47.9097665, lng: 18.6246791 },
    { lat: 47.9094269, lng: 18.6250313 },
    { lat: 47.9093306, lng: 18.6250032 },
    { lat: 47.9088743, lng: 18.6253417 },
    { lat: 47.9086666, lng: 18.6258326 },
    { lat: 47.9085973, lng: 18.6258663 },
    { lat: 47.9085017, lng: 18.6262023 },
    { lat: 47.9083355, lng: 18.6265254 },
    { lat: 47.9081525, lng: 18.6265627 },
    { lat: 47.9080585, lng: 18.6266774 },
    { lat: 47.9078722, lng: 18.6266542 },
    { lat: 47.9076299, lng: 18.6263678 },
    { lat: 47.9073115, lng: 18.6256796 },
    { lat: 47.9070795, lng: 18.6257674 },
    { lat: 47.9069743, lng: 18.6259267 },
    { lat: 47.9065599, lng: 18.626973 },
    { lat: 47.9064387, lng: 18.6276282 },
    { lat: 47.9064389, lng: 18.6279382 },
    { lat: 47.9065072, lng: 18.6281974 },
    { lat: 47.9067237, lng: 18.6283196 },
    { lat: 47.9065492, lng: 18.629236 },
    { lat: 47.9063993, lng: 18.6296113 },
    { lat: 47.9059786, lng: 18.6302302 },
    { lat: 47.905123, lng: 18.6307764 },
    { lat: 47.9051214, lng: 18.6308508 },
    { lat: 47.904844, lng: 18.6310769 },
    { lat: 47.9044528, lng: 18.631515 },
    { lat: 47.9043665, lng: 18.6314825 },
    { lat: 47.9042901, lng: 18.6316549 },
    { lat: 47.9043596, lng: 18.6321843 },
    { lat: 47.9042734, lng: 18.6325493 },
    { lat: 47.904375, lng: 18.6328176 },
    { lat: 47.9043656, lng: 18.6330391 },
    { lat: 47.9041387, lng: 18.6334061 },
    { lat: 47.9040345, lng: 18.6337322 },
    { lat: 47.9038747, lng: 18.6339655 },
    { lat: 47.9037709, lng: 18.6340259 },
    { lat: 47.9037095, lng: 18.6342048 },
    { lat: 47.9037724, lng: 18.6342999 },
    { lat: 47.9038328, lng: 18.6342933 },
    { lat: 47.9039321, lng: 18.6345811 },
    { lat: 47.9036211, lng: 18.6352492 },
    { lat: 47.9032595, lng: 18.6356085 },
    { lat: 47.9031866, lng: 18.6355337 },
    { lat: 47.9028258, lng: 18.6359083 },
    { lat: 47.9027022, lng: 18.6361847 },
    { lat: 47.9027067, lng: 18.6362503 },
    { lat: 47.9029353, lng: 18.6364888 },
    { lat: 47.9029729, lng: 18.6365988 },
    { lat: 47.9028657, lng: 18.6369727 },
    { lat: 47.9026978, lng: 18.6372436 },
    { lat: 47.9029214, lng: 18.6376606 },
    { lat: 47.90284, lng: 18.6379709 },
    { lat: 47.9025622, lng: 18.638048 },
    { lat: 47.9023363, lng: 18.6382257 },
    { lat: 47.9022435, lng: 18.6384053 },
    { lat: 47.9023201, lng: 18.638653 },
    { lat: 47.9022146, lng: 18.6392404 },
    { lat: 47.9023493, lng: 18.6394136 },
    { lat: 47.9023587, lng: 18.6397109 },
    { lat: 47.9022918, lng: 18.6397674 },
    { lat: 47.9023552, lng: 18.6400818 },
    { lat: 47.9023192, lng: 18.6401272 },
    { lat: 47.9023454, lng: 18.6402351 },
    { lat: 47.9023123, lng: 18.6403091 },
    { lat: 47.9021892, lng: 18.6402656 },
    { lat: 47.9020487, lng: 18.6400045 },
    { lat: 47.9019491, lng: 18.6401244 },
    { lat: 47.9020081, lng: 18.6406691 },
    { lat: 47.9022195, lng: 18.64107 },
    { lat: 47.9022384, lng: 18.641602 },
    { lat: 47.9021922, lng: 18.641688 },
    { lat: 47.9019491, lng: 18.6417588 },
    { lat: 47.9016049, lng: 18.6416485 },
    { lat: 47.9011739, lng: 18.6410909 },
    { lat: 47.9010572, lng: 18.6411219 },
    { lat: 47.9008532, lng: 18.6413303 },
    { lat: 47.9007844, lng: 18.6414946 },
    { lat: 47.9006018, lng: 18.6415623 },
    { lat: 47.9005281, lng: 18.6414493 },
    { lat: 47.9004432, lng: 18.6411365 },
    { lat: 47.9001696, lng: 18.6408946 },
    { lat: 47.8999662, lng: 18.640846 },
    { lat: 47.8998618, lng: 18.641002 },
    { lat: 47.8999064, lng: 18.6412181 },
    { lat: 47.8998326, lng: 18.6413774 },
    { lat: 47.8996691, lng: 18.6413349 },
    { lat: 47.8992891, lng: 18.6414612 },
    { lat: 47.8991162, lng: 18.6417632 },
    { lat: 47.8990389, lng: 18.6420238 },
    { lat: 47.8989962, lng: 18.6426876 },
    { lat: 47.8984461, lng: 18.6429858 },
    { lat: 47.8981691, lng: 18.6432192 },
    { lat: 47.8977953, lng: 18.6437728 },
    { lat: 47.8975794, lng: 18.644734 },
    { lat: 47.8975288, lng: 18.6457748 },
    { lat: 47.8977661, lng: 18.6468382 },
    { lat: 47.8980257, lng: 18.6476069 },
    { lat: 47.8984336, lng: 18.6484484 },
    { lat: 47.8987622, lng: 18.6489764 },
    { lat: 47.8990039, lng: 18.6492092 },
    { lat: 47.8991895, lng: 18.6500697 },
    { lat: 47.8992563, lng: 18.6509664 },
    { lat: 47.8992949, lng: 18.6517982 },
    { lat: 47.8992172, lng: 18.6528584 },
    { lat: 47.8990459, lng: 18.6533159 },
    { lat: 47.8985602, lng: 18.6538847 },
    { lat: 47.8980941, lng: 18.6541281 },
    { lat: 47.898517, lng: 18.6562861 },
    { lat: 47.8982593, lng: 18.6566281 },
    { lat: 47.8982315, lng: 18.6568859 },
    { lat: 47.898259, lng: 18.6570359 },
    { lat: 47.8984486, lng: 18.657057 },
    { lat: 47.8985416, lng: 18.6569857 },
    { lat: 47.8986276, lng: 18.6567642 },
    { lat: 47.8987866, lng: 18.6566361 },
    { lat: 47.8989118, lng: 18.6567212 },
    { lat: 47.8988099, lng: 18.657087 },
    { lat: 47.898824, lng: 18.6571591 },
    { lat: 47.8989024, lng: 18.6571483 },
    { lat: 47.8989099, lng: 18.6573298 },
    { lat: 47.8988068, lng: 18.6574044 },
    { lat: 47.8986127, lng: 18.6573202 },
    { lat: 47.898537, lng: 18.657588 },
    { lat: 47.8983835, lng: 18.6578345 },
    { lat: 47.8983968, lng: 18.6582881 },
    { lat: 47.8986109, lng: 18.6583432 },
    { lat: 47.8986835, lng: 18.658287 },
    { lat: 47.8987387, lng: 18.6581644 },
    { lat: 47.8987157, lng: 18.6579489 },
    { lat: 47.8987893, lng: 18.6578072 },
    { lat: 47.8988445, lng: 18.6578065 },
    { lat: 47.8988991, lng: 18.6578963 },
    { lat: 47.8988643, lng: 18.6584013 },
    { lat: 47.8988968, lng: 18.6585418 },
    { lat: 47.8990101, lng: 18.6587574 },
    { lat: 47.8991496, lng: 18.6587698 },
    { lat: 47.8994171, lng: 18.6589397 },
    { lat: 47.8996062, lng: 18.659183 },
    { lat: 47.8997965, lng: 18.6591577 },
    { lat: 47.8998531, lng: 18.65883 },
    { lat: 47.8999816, lng: 18.6586803 },
    { lat: 47.9000786, lng: 18.6590117 },
    { lat: 47.9003839, lng: 18.6591121 },
    { lat: 47.9004566, lng: 18.6587491 },
    { lat: 47.9005888, lng: 18.6588548 },
    { lat: 47.9007646, lng: 18.6591851 },
    { lat: 47.9013171, lng: 18.6594705 },
    { lat: 47.901374, lng: 18.6595428 },
    { lat: 47.9014494, lng: 18.659874 },
    { lat: 47.9015497, lng: 18.6599751 },
    { lat: 47.9017671, lng: 18.6599261 },
    { lat: 47.9018009, lng: 18.6601361 },
    { lat: 47.9017143, lng: 18.6605549 },
    { lat: 47.9017561, lng: 18.6606202 },
    { lat: 47.9018601, lng: 18.6605817 },
    { lat: 47.9019286, lng: 18.6606807 },
    { lat: 47.9019907, lng: 18.661031 },
    { lat: 47.9019743, lng: 18.6611687 },
    { lat: 47.901927, lng: 18.6612731 },
    { lat: 47.9015933, lng: 18.661212 },
    { lat: 47.90154, lng: 18.6612708 },
    { lat: 47.9014427, lng: 18.6618859 },
    { lat: 47.9014835, lng: 18.6620142 },
    { lat: 47.9020657, lng: 18.6621858 },
    { lat: 47.9030397, lng: 18.6628002 },
    { lat: 47.9034778, lng: 18.6627458 },
    { lat: 47.9039021, lng: 18.6632263 },
    { lat: 47.9048843, lng: 18.6636377 },
    { lat: 47.9059544, lng: 18.6637591 },
    { lat: 47.9060983, lng: 18.6636676 },
    { lat: 47.9070599, lng: 18.6640137 },
    { lat: 47.9074239, lng: 18.6638389 },
    { lat: 47.9078571, lng: 18.6638434 },
    { lat: 47.9079579, lng: 18.6638942 },
    { lat: 47.9085608, lng: 18.6647551 },
    { lat: 47.9091869, lng: 18.6652554 },
    { lat: 47.9100526, lng: 18.6649982 },
    { lat: 47.910426, lng: 18.6647276 },
    { lat: 47.9109414, lng: 18.6649143 },
    { lat: 47.9111372, lng: 18.6650722 },
    { lat: 47.9115532, lng: 18.6651301 },
    { lat: 47.911621, lng: 18.6652387 },
    { lat: 47.9116278, lng: 18.6653634 },
    { lat: 47.9115666, lng: 18.6654586 },
    { lat: 47.9113739, lng: 18.6654016 },
    { lat: 47.9113391, lng: 18.6655098 },
    { lat: 47.9113699, lng: 18.6657234 },
    { lat: 47.911437, lng: 18.6658584 },
    { lat: 47.9116574, lng: 18.6658035 },
    { lat: 47.9117333, lng: 18.6660401 },
    { lat: 47.9118723, lng: 18.6661064 },
    { lat: 47.9118869, lng: 18.6662312 },
    { lat: 47.9118345, lng: 18.6663028 },
    { lat: 47.9112778, lng: 18.6662906 },
    { lat: 47.9112777, lng: 18.6664544 },
    { lat: 47.9115123, lng: 18.6667673 },
    { lat: 47.9115296, lng: 18.6668773 },
    { lat: 47.9114842, lng: 18.6669763 },
    { lat: 47.9112747, lng: 18.6671487 },
    { lat: 47.9112726, lng: 18.6674408 },
    { lat: 47.9110095, lng: 18.6678112 },
    { lat: 47.9113776, lng: 18.6685665 },
    { lat: 47.9114937, lng: 18.6686291 },
    { lat: 47.9115799, lng: 18.6685963 },
    { lat: 47.9116383, lng: 18.6681959 },
    { lat: 47.9118318, lng: 18.6681435 },
    { lat: 47.9119249, lng: 18.6685085 },
    { lat: 47.9120972, lng: 18.668774 },
    { lat: 47.912564, lng: 18.6687911 },
    { lat: 47.912698, lng: 18.6689356 },
    { lat: 47.9127008, lng: 18.66907 },
    { lat: 47.9125036, lng: 18.6694044 },
    { lat: 47.9123829, lng: 18.6694045 },
    { lat: 47.9122441, lng: 18.6695913 },
    { lat: 47.912122, lng: 18.669877 },
    { lat: 47.9121213, lng: 18.6699889 },
    { lat: 47.9124456, lng: 18.6703742 },
    { lat: 47.9128812, lng: 18.6705867 },
    { lat: 47.9130385, lng: 18.6704722 },
    { lat: 47.913135, lng: 18.6699206 },
    { lat: 47.9132402, lng: 18.6696549 },
    { lat: 47.9133829, lng: 18.6696058 },
    { lat: 47.9138585, lng: 18.6703481 },
    { lat: 47.9139032, lng: 18.6712343 },
    { lat: 47.9140125, lng: 18.6714613 },
    { lat: 47.9140935, lng: 18.6715008 },
    { lat: 47.9143305, lng: 18.6712731 },
    { lat: 47.9145632, lng: 18.6712191 },
    { lat: 47.9146506, lng: 18.671331 },
    { lat: 47.9149192, lng: 18.6721879 },
    { lat: 47.9150139, lng: 18.6722897 },
    { lat: 47.915127, lng: 18.6722315 },
    { lat: 47.9151822, lng: 18.6721296 },
    { lat: 47.9151824, lng: 18.6716107 },
    { lat: 47.9152405, lng: 18.6714564 },
    { lat: 47.9152939, lng: 18.6714433 },
    { lat: 47.9158426, lng: 18.6720121 },
    { lat: 47.9134149, lng: 18.6790405 },
    { lat: 47.9131058, lng: 18.679808 },
    { lat: 47.9128838, lng: 18.6799162 },
    { lat: 47.9126477, lng: 18.6802447 },
    { lat: 47.9115428, lng: 18.6827496 },
    { lat: 47.9112235, lng: 18.6837718 },
    { lat: 47.9110218, lng: 18.6846702 },
    { lat: 47.9108745, lng: 18.6848868 },
    { lat: 47.9107999, lng: 18.6851566 },
    { lat: 47.9106859, lng: 18.6855685 },
    { lat: 47.9105585, lng: 18.6858023 },
    { lat: 47.9098343, lng: 18.6871314 },
    { lat: 47.9095438, lng: 18.6880397 },
    { lat: 47.9094321, lng: 18.6885574 },
    { lat: 47.9093443, lng: 18.689207 },
    { lat: 47.9093553, lng: 18.6894532 },
    { lat: 47.9095837, lng: 18.6896079 },
    { lat: 47.909526, lng: 18.6908582 },
    { lat: 47.9096363, lng: 18.6923555 },
    { lat: 47.909558, lng: 18.6931417 },
    { lat: 47.9094982, lng: 18.6933295 },
    { lat: 47.9092574, lng: 18.6936712 },
    { lat: 47.9092201, lng: 18.6938391 },
    { lat: 47.9091718, lng: 18.6949887 },
    { lat: 47.9091822, lng: 18.6954332 },
    { lat: 47.9093273, lng: 18.6963552 },
    { lat: 47.9094366, lng: 18.69705 },
    { lat: 47.9095144, lng: 18.6973633 },
    { lat: 47.9095879, lng: 18.697659 },
    { lat: 47.9095852, lng: 18.6982573 },
    { lat: 47.9094498, lng: 18.6993178 },
    { lat: 47.9091337, lng: 18.7017927 },
    { lat: 47.9090833, lng: 18.7021874 },
    { lat: 47.9099204, lng: 18.7025062 },
    { lat: 47.9101057, lng: 18.7026373 },
    { lat: 47.9102008, lng: 18.7012213 },
    { lat: 47.9104414, lng: 18.6997053 },
    { lat: 47.9106112, lng: 18.6988361 },
    { lat: 47.910932, lng: 18.6976999 },
    { lat: 47.9111858, lng: 18.6969901 },
    { lat: 47.9118534, lng: 18.6951906 },
    { lat: 47.9127053, lng: 18.6930892 },
    { lat: 47.9136063, lng: 18.6913971 },
    { lat: 47.9145122, lng: 18.6895008 },
    { lat: 47.915234, lng: 18.6877093 },
    { lat: 47.9161923, lng: 18.6858853 },
    { lat: 47.9167515, lng: 18.6845267 },
    { lat: 47.9170764, lng: 18.6834468 },
    { lat: 47.9180974, lng: 18.676681 },
    { lat: 47.9193008, lng: 18.6722652 },
    { lat: 47.9196016, lng: 18.6717415 },
    { lat: 47.9197729, lng: 18.6715493 },
    { lat: 47.9199292, lng: 18.6714799 },
    { lat: 47.9208342, lng: 18.6714237 },
    { lat: 47.9210328, lng: 18.6713433 },
    { lat: 47.9214653, lng: 18.6709802 },
    { lat: 47.9219197, lng: 18.6709753 },
    { lat: 47.9220993, lng: 18.6710659 },
    { lat: 47.9221287, lng: 18.6711506 },
    { lat: 47.9220507, lng: 18.6714778 },
    { lat: 47.9220701, lng: 18.6726278 },
    { lat: 47.9220837, lng: 18.6728575 },
    { lat: 47.9222157, lng: 18.6732748 },
    { lat: 47.9223192, lng: 18.6734136 },
    { lat: 47.9225089, lng: 18.6734809 },
    { lat: 47.9228966, lng: 18.6733303 },
    { lat: 47.9248186, lng: 18.6716651 },
    { lat: 47.9248716, lng: 18.671606 },
    { lat: 47.9249338, lng: 18.6712145 },
    { lat: 47.9249264, lng: 18.6709116 },
    { lat: 47.9248975, lng: 18.6708359 },
    { lat: 47.9248207, lng: 18.67081 },
    { lat: 47.9247338, lng: 18.6708331 },
    { lat: 47.9244982, lng: 18.6713136 },
    { lat: 47.9243333, lng: 18.6713896 },
    { lat: 47.9241822, lng: 18.6713698 },
    { lat: 47.9239002, lng: 18.6711824 },
    { lat: 47.9236214, lng: 18.6711797 },
    { lat: 47.9233889, lng: 18.6710943 },
    { lat: 47.9233858, lng: 18.6710152 },
    { lat: 47.9238128, lng: 18.6703634 },
    { lat: 47.9241752, lng: 18.6701388 },
    { lat: 47.9242332, lng: 18.6700007 },
    { lat: 47.9242551, lng: 18.6697639 },
    { lat: 47.9242442, lng: 18.6696024 },
    { lat: 47.9241775, lng: 18.669557 },
    { lat: 47.9238054, lng: 18.669705 },
    { lat: 47.9235458, lng: 18.6697249 },
    { lat: 47.9231739, lng: 18.6695442 },
    { lat: 47.9230903, lng: 18.6692877 },
    { lat: 47.9231299, lng: 18.6691032 },
    { lat: 47.9236055, lng: 18.6688295 },
    { lat: 47.9234049, lng: 18.6681458 },
    { lat: 47.9231753, lng: 18.6679054 },
    { lat: 47.923196, lng: 18.6677637 },
    { lat: 47.9234359, lng: 18.6675499 },
    { lat: 47.9235466, lng: 18.6675335 },
    { lat: 47.923754, lng: 18.6676978 },
    { lat: 47.924185, lng: 18.6678883 },
    { lat: 47.925115, lng: 18.6680231 },
    { lat: 47.9254819, lng: 18.6679893 },
    { lat: 47.9261648, lng: 18.6677691 },
    { lat: 47.9273571, lng: 18.6676069 },
    { lat: 47.9277899, lng: 18.6673503 },
    { lat: 47.9280834, lng: 18.6668962 },
    { lat: 47.9282943, lng: 18.6667316 },
    { lat: 47.929033, lng: 18.6667906 },
    { lat: 47.9291645, lng: 18.6666577 },
    { lat: 47.9293296, lng: 18.6663326 },
    { lat: 47.9294627, lng: 18.666208 },
    { lat: 47.9295212, lng: 18.6657311 },
    { lat: 47.9297099, lng: 18.6654451 },
    { lat: 47.9304189, lng: 18.6651049 },
    { lat: 47.9306892, lng: 18.6651382 },
    { lat: 47.9308479, lng: 18.6653622 },
    { lat: 47.9310233, lng: 18.6654318 },
    { lat: 47.9310822, lng: 18.6655208 },
    { lat: 47.9311378, lng: 18.6666285 },
    { lat: 47.9311058, lng: 18.667763 },
    { lat: 47.9311551, lng: 18.6679439 },
    { lat: 47.9313619, lng: 18.6678712 },
    { lat: 47.9322027, lng: 18.6666308 },
    { lat: 47.9324242, lng: 18.6666836 },
    { lat: 47.9326368, lng: 18.6665255 },
    { lat: 47.9328077, lng: 18.6666826 },
    { lat: 47.9344212, lng: 18.6647612 },
    { lat: 47.935046, lng: 18.6651771 },
    { lat: 47.9354672, lng: 18.6652465 },
    { lat: 47.9378296, lng: 18.6671307 },
    { lat: 47.938825, lng: 18.667258 },
    { lat: 47.93911, lng: 18.666617 },
    { lat: 47.939525, lng: 18.665842 },
    { lat: 47.940052, lng: 18.664728 },
    { lat: 47.94017, lng: 18.663603 },
    { lat: 47.940352, lng: 18.662983 },
    { lat: 47.940377, lng: 18.66288 },
    { lat: 47.940468, lng: 18.662228 },
    { lat: 47.940414, lng: 18.661819 },
    { lat: 47.940345, lng: 18.661476 },
    { lat: 47.940311, lng: 18.661077 },
    { lat: 47.940498, lng: 18.659457 },
    { lat: 47.940155, lng: 18.658625 },
    { lat: 47.939965, lng: 18.658152 },
    { lat: 47.939868, lng: 18.658009 },
    { lat: 47.939712, lng: 18.657782 },
    { lat: 47.939756, lng: 18.657661 },
    { lat: 47.939912, lng: 18.657247 },
    { lat: 47.939957, lng: 18.657134 },
    { lat: 47.940306, lng: 18.656247 },
    { lat: 47.940587, lng: 18.655663 },
    { lat: 47.940677, lng: 18.654726 },
    { lat: 47.940726, lng: 18.654295 },
    { lat: 47.940767, lng: 18.653872 },
    { lat: 47.940964, lng: 18.652055 },
    { lat: 47.941056, lng: 18.651158 },
    { lat: 47.9411, lng: 18.65068 },
    { lat: 47.941482, lng: 18.650623 },
    { lat: 47.942933, lng: 18.650281 },
    { lat: 47.943489, lng: 18.64991 },
    { lat: 47.943738, lng: 18.64979 },
    { lat: 47.943894, lng: 18.64974 },
    { lat: 47.944015, lng: 18.649711 },
    { lat: 47.944275, lng: 18.649672 },
    { lat: 47.944396, lng: 18.649699 },
    { lat: 47.944544, lng: 18.649786 },
    { lat: 47.94484, lng: 18.650105 },
    { lat: 47.944871, lng: 18.649311 },
    { lat: 47.944961, lng: 18.647859 },
    { lat: 47.94497, lng: 18.64769 },
    { lat: 47.945103, lng: 18.645413 },
    { lat: 47.945227, lng: 18.644606 },
    { lat: 47.945437, lng: 18.643235 },
    { lat: 47.945491, lng: 18.642976 },
    { lat: 47.945717, lng: 18.642012 },
    { lat: 47.945916, lng: 18.641381 },
    { lat: 47.94624, lng: 18.640377 },
    { lat: 47.946611, lng: 18.639184 },
    { lat: 47.947081, lng: 18.63762 },
    { lat: 47.947396, lng: 18.63669 },
    { lat: 47.947432, lng: 18.636583 },
    { lat: 47.947864, lng: 18.635454 },
    { lat: 47.948819, lng: 18.632716 },
    { lat: 47.949657, lng: 18.630536 },
    { lat: 47.94978, lng: 18.630179 },
    { lat: 47.950091, lng: 18.629184 },
    { lat: 47.950661, lng: 18.627271 },
    { lat: 47.951332, lng: 18.62525 },
    { lat: 47.950804, lng: 18.62479 },
    { lat: 47.949695, lng: 18.62351 },
    { lat: 47.949169, lng: 18.622952 },
    { lat: 47.948812, lng: 18.622572 },
    { lat: 47.948475, lng: 18.622277 },
    { lat: 47.947447, lng: 18.62103 },
    { lat: 47.946913, lng: 18.62034 },
    { lat: 47.946459, lng: 18.619689 },
    { lat: 47.945769, lng: 18.618697 },
    { lat: 47.945368, lng: 18.618119 },
    { lat: 47.945674, lng: 18.617989 },
    { lat: 47.945902, lng: 18.617845 },
    { lat: 47.946012, lng: 18.617677 },
    { lat: 47.946097, lng: 18.617487 },
    { lat: 47.946276, lng: 18.616988 },
    { lat: 47.946532, lng: 18.616866 },
    { lat: 47.946719, lng: 18.616927 },
    { lat: 47.94682, lng: 18.617092 },
    { lat: 47.947267, lng: 18.617505 },
    { lat: 47.947442, lng: 18.617415 },
  ],
  Rúbaň: [
    { lat: 47.919695, lng: 18.358861 },
    { lat: 47.9197128, lng: 18.3589038 },
    { lat: 47.91833, lng: 18.3607716 },
    { lat: 47.917031, lng: 18.362704 },
    { lat: 47.9131135, lng: 18.3701308 },
    { lat: 47.911579, lng: 18.3732992 },
    { lat: 47.9101363, lng: 18.3770776 },
    { lat: 47.9095554, lng: 18.3783079 },
    { lat: 47.9093098, lng: 18.3802228 },
    { lat: 47.9103646, lng: 18.3813104 },
    { lat: 47.9111826, lng: 18.3823269 },
    { lat: 47.9121723, lng: 18.3837234 },
    { lat: 47.9119775, lng: 18.3841424 },
    { lat: 47.9133013, lng: 18.3857604 },
    { lat: 47.9132639, lng: 18.38586 },
    { lat: 47.9140021, lng: 18.3868202 },
    { lat: 47.9137252, lng: 18.3873476 },
    { lat: 47.9153548, lng: 18.3894236 },
    { lat: 47.9161205, lng: 18.3906093 },
    { lat: 47.9167942, lng: 18.3918263 },
    { lat: 47.9155677, lng: 18.3930971 },
    { lat: 47.9140622, lng: 18.3943434 },
    { lat: 47.9111398, lng: 18.3972037 },
    { lat: 47.9135, lng: 18.4019216 },
    { lat: 47.9143512, lng: 18.4037449 },
    { lat: 47.9154512, lng: 18.4055624 },
    { lat: 47.9163493, lng: 18.4068988 },
    { lat: 47.9164171, lng: 18.4069395 },
    { lat: 47.9172313, lng: 18.4061751 },
    { lat: 47.9184687, lng: 18.4046584 },
    { lat: 47.919156, lng: 18.4035852 },
    { lat: 47.9213616, lng: 18.4037633 },
    { lat: 47.9228384, lng: 18.4065967 },
    { lat: 47.923498, lng: 18.4076878 },
    { lat: 47.9239684, lng: 18.4083219 },
    { lat: 47.9242226, lng: 18.4089495 },
    { lat: 47.9244259, lng: 18.4112655 },
    { lat: 47.9249446, lng: 18.4123208 },
    { lat: 47.9253354, lng: 18.4128864 },
    { lat: 47.9267136, lng: 18.4151952 },
    { lat: 47.9276625, lng: 18.4166168 },
    { lat: 47.9279359, lng: 18.417302 },
    { lat: 47.9294027, lng: 18.4203245 },
    { lat: 47.9300257, lng: 18.4218669 },
    { lat: 47.9304736, lng: 18.4232908 },
    { lat: 47.9310688, lng: 18.4244589 },
    { lat: 47.9311297, lng: 18.4244981 },
    { lat: 47.9312145, lng: 18.4252642 },
    { lat: 47.9308915, lng: 18.4267193 },
    { lat: 47.9306506, lng: 18.4282282 },
    { lat: 47.9307743, lng: 18.4294425 },
    { lat: 47.9308701, lng: 18.4297999 },
    { lat: 47.9309956, lng: 18.4310876 },
    { lat: 47.9313995, lng: 18.4318276 },
    { lat: 47.9314365, lng: 18.4318047 },
    { lat: 47.9316463, lng: 18.4323435 },
    { lat: 47.9319431, lng: 18.4327659 },
    { lat: 47.93259, lng: 18.4333177 },
    { lat: 47.9326685, lng: 18.4335914 },
    { lat: 47.9328249, lng: 18.4346047 },
    { lat: 47.933014, lng: 18.4352562 },
    { lat: 47.9334891, lng: 18.4381657 },
    { lat: 47.933441, lng: 18.4387495 },
    { lat: 47.9333892, lng: 18.439378 },
    { lat: 47.9333752, lng: 18.4401924 },
    { lat: 47.9334372, lng: 18.4404738 },
    { lat: 47.9365422, lng: 18.4469411 },
    { lat: 47.9368485, lng: 18.447494 },
    { lat: 47.9373538, lng: 18.4486575 },
    { lat: 47.937967, lng: 18.4493735 },
    { lat: 47.9393981, lng: 18.4512494 },
    { lat: 47.9393727, lng: 18.4513415 },
    { lat: 47.9388868, lng: 18.4513474 },
    { lat: 47.9387487, lng: 18.4514188 },
    { lat: 47.9380632, lng: 18.451987 },
    { lat: 47.9378667, lng: 18.4521684 },
    { lat: 47.9379666, lng: 18.452499 },
    { lat: 47.9390553, lng: 18.4540456 },
    { lat: 47.9394533, lng: 18.4550477 },
    { lat: 47.9404273, lng: 18.4561225 },
    { lat: 47.9410799, lng: 18.4573171 },
    { lat: 47.9416525, lng: 18.4582336 },
    { lat: 47.9418204, lng: 18.4584104 },
    { lat: 47.9421104, lng: 18.4584525 },
    { lat: 47.9423931, lng: 18.4587893 },
    { lat: 47.9427667, lng: 18.4591538 },
    { lat: 47.942972, lng: 18.4591938 },
    { lat: 47.943248, lng: 18.4595071 },
    { lat: 47.9430891, lng: 18.4613835 },
    { lat: 47.9431993, lng: 18.4620207 },
    { lat: 47.9433699, lng: 18.462466 },
    { lat: 47.9436797, lng: 18.462803 },
    { lat: 47.9442839, lng: 18.4638609 },
    { lat: 47.9446095, lng: 18.4640355 },
    { lat: 47.9447847, lng: 18.4642843 },
    { lat: 47.9450427, lng: 18.4641767 },
    { lat: 47.9452081, lng: 18.4641885 },
    { lat: 47.9446533, lng: 18.4636275 },
    { lat: 47.9442085, lng: 18.4613307 },
    { lat: 47.9440688, lng: 18.459921 },
    { lat: 47.9441665, lng: 18.4583459 },
    { lat: 47.9441136, lng: 18.4570187 },
    { lat: 47.9441904, lng: 18.4561967 },
    { lat: 47.9441377, lng: 18.4554176 },
    { lat: 47.9438062, lng: 18.4536922 },
    { lat: 47.9438134, lng: 18.4535175 },
    { lat: 47.943857, lng: 18.45301 },
    { lat: 47.9439797, lng: 18.4526017 },
    { lat: 47.9440109, lng: 18.4521338 },
    { lat: 47.9442763, lng: 18.4507138 },
    { lat: 47.944562, lng: 18.4493729 },
    { lat: 47.9448226, lng: 18.4486294 },
    { lat: 47.945143, lng: 18.4473221 },
    { lat: 47.9453057, lng: 18.4464856 },
    { lat: 47.9456272, lng: 18.4457792 },
    { lat: 47.9460135, lng: 18.4447445 },
    { lat: 47.9467937, lng: 18.4435136 },
    { lat: 47.9468414, lng: 18.4430759 },
    { lat: 47.9468106, lng: 18.4427881 },
    { lat: 47.9466345, lng: 18.4421875 },
    { lat: 47.9463594, lng: 18.4415068 },
    { lat: 47.9461396, lng: 18.4411077 },
    { lat: 47.9459594, lng: 18.4406119 },
    { lat: 47.9455017, lng: 18.4396563 },
    { lat: 47.9451655, lng: 18.4391352 },
    { lat: 47.9450263, lng: 18.4388239 },
    { lat: 47.9449279, lng: 18.4372978 },
    { lat: 47.9447941, lng: 18.4361794 },
    { lat: 47.9445741, lng: 18.4350767 },
    { lat: 47.9441965, lng: 18.434102 },
    { lat: 47.9440658, lng: 18.4332612 },
    { lat: 47.9435711, lng: 18.4320706 },
    { lat: 47.943599, lng: 18.4320453 },
    { lat: 47.943519, lng: 18.4314857 },
    { lat: 47.9432085, lng: 18.4302212 },
    { lat: 47.9430993, lng: 18.4294818 },
    { lat: 47.9428029, lng: 18.4288281 },
    { lat: 47.9423851, lng: 18.4274395 },
    { lat: 47.9419147, lng: 18.4263195 },
    { lat: 47.9418366, lng: 18.425513 },
    { lat: 47.9414451, lng: 18.4231944 },
    { lat: 47.9410545, lng: 18.4213851 },
    { lat: 47.9409964, lng: 18.4213575 },
    { lat: 47.9407309, lng: 18.4202304 },
    { lat: 47.9400532, lng: 18.4156572 },
    { lat: 47.9398464, lng: 18.4145329 },
    { lat: 47.9403917, lng: 18.4134434 },
    { lat: 47.9410486, lng: 18.412311 },
    { lat: 47.941123, lng: 18.4120504 },
    { lat: 47.9418185, lng: 18.4123768 },
    { lat: 47.940686, lng: 18.4062347 },
    { lat: 47.943187, lng: 18.4044188 },
    { lat: 47.9444968, lng: 18.4029605 },
    { lat: 47.944379, lng: 18.4024538 },
    { lat: 47.9444746, lng: 18.4010527 },
    { lat: 47.9421942, lng: 18.3918653 },
    { lat: 47.9417566, lng: 18.3908735 },
    { lat: 47.9415696, lng: 18.3899396 },
    { lat: 47.9415422, lng: 18.3892176 },
    { lat: 47.940489, lng: 18.3893896 },
    { lat: 47.9396568, lng: 18.387046 },
    { lat: 47.9376332, lng: 18.3822231 },
    { lat: 47.9384901, lng: 18.3810015 },
    { lat: 47.938118, lng: 18.3802327 },
    { lat: 47.9394502, lng: 18.377665 },
    { lat: 47.9402423, lng: 18.376297 },
    { lat: 47.9402363, lng: 18.375854 },
    { lat: 47.9378643, lng: 18.3697911 },
    { lat: 47.9374165, lng: 18.3680698 },
    { lat: 47.9362049, lng: 18.3652555 },
    { lat: 47.9358561, lng: 18.3642018 },
    { lat: 47.9346003, lng: 18.3586548 },
    { lat: 47.929408, lng: 18.342866 },
    { lat: 47.928904, lng: 18.34337 },
    { lat: 47.928458, lng: 18.343956 },
    { lat: 47.928062, lng: 18.344477 },
    { lat: 47.927878, lng: 18.34474 },
    { lat: 47.927672, lng: 18.345033 },
    { lat: 47.927509, lng: 18.345336 },
    { lat: 47.927474, lng: 18.345401 },
    { lat: 47.927176, lng: 18.346022 },
    { lat: 47.92672, lng: 18.346972 },
    { lat: 47.926516, lng: 18.347287 },
    { lat: 47.926465, lng: 18.347366 },
    { lat: 47.926197, lng: 18.347744 },
    { lat: 47.926176, lng: 18.347774 },
    { lat: 47.926036, lng: 18.348062 },
    { lat: 47.92564, lng: 18.34872 },
    { lat: 47.925305, lng: 18.349276 },
    { lat: 47.925029, lng: 18.349701 },
    { lat: 47.924971, lng: 18.349792 },
    { lat: 47.924622, lng: 18.350342 },
    { lat: 47.924516, lng: 18.350541 },
    { lat: 47.924255, lng: 18.351028 },
    { lat: 47.924012, lng: 18.351379 },
    { lat: 47.923649, lng: 18.351905 },
    { lat: 47.923333, lng: 18.352396 },
    { lat: 47.9233, lng: 18.352453 },
    { lat: 47.923215, lng: 18.352598 },
    { lat: 47.923197, lng: 18.352629 },
    { lat: 47.922874, lng: 18.353111 },
    { lat: 47.922781, lng: 18.353262 },
    { lat: 47.922772, lng: 18.353276 },
    { lat: 47.922673, lng: 18.353457 },
    { lat: 47.922652, lng: 18.353494 },
    { lat: 47.922273, lng: 18.354104 },
    { lat: 47.922085, lng: 18.354416 },
    { lat: 47.921993, lng: 18.354566 },
    { lat: 47.921838, lng: 18.354799 },
    { lat: 47.921825, lng: 18.354866 },
    { lat: 47.92177, lng: 18.354955 },
    { lat: 47.921639, lng: 18.355168 },
    { lat: 47.92141, lng: 18.355541 },
    { lat: 47.920984, lng: 18.356232 },
    { lat: 47.92095, lng: 18.356287 },
    { lat: 47.92071, lng: 18.356592 },
    { lat: 47.919849, lng: 18.358095 },
    { lat: 47.919847, lng: 18.358097 },
    { lat: 47.919695, lng: 18.358861 },
  ],
  Bešeňov: [
    { lat: 48.0568651, lng: 18.2480105 },
    { lat: 48.0566205, lng: 18.2475881 },
    { lat: 48.0564698, lng: 18.246543 },
    { lat: 48.0563571, lng: 18.2461935 },
    { lat: 48.0563384, lng: 18.2451298 },
    { lat: 48.0565143, lng: 18.2444433 },
    { lat: 48.056448, lng: 18.2443524 },
    { lat: 48.0562593, lng: 18.244389 },
    { lat: 48.0559886, lng: 18.2446013 },
    { lat: 48.0556989, lng: 18.2446298 },
    { lat: 48.0553566, lng: 18.2448925 },
    { lat: 48.0551696, lng: 18.2448605 },
    { lat: 48.0549215, lng: 18.244435 },
    { lat: 48.0549593, lng: 18.2439547 },
    { lat: 48.0548906, lng: 18.2434137 },
    { lat: 48.0549156, lng: 18.2427926 },
    { lat: 48.0544936, lng: 18.2424787 },
    { lat: 48.0541924, lng: 18.2420707 },
    { lat: 48.0541191, lng: 18.2418787 },
    { lat: 48.0542037, lng: 18.2413515 },
    { lat: 48.0541835, lng: 18.2412762 },
    { lat: 48.0537677, lng: 18.2409918 },
    { lat: 48.0529296, lng: 18.2406667 },
    { lat: 48.0528495, lng: 18.2405501 },
    { lat: 48.0528077, lng: 18.2401961 },
    { lat: 48.0529071, lng: 18.2398655 },
    { lat: 48.0529036, lng: 18.2397111 },
    { lat: 48.0528169, lng: 18.2395587 },
    { lat: 48.0527067, lng: 18.239485 },
    { lat: 48.0525921, lng: 18.2394938 },
    { lat: 48.0522691, lng: 18.2397632 },
    { lat: 48.052132, lng: 18.2398906 },
    { lat: 48.052055, lng: 18.2400793 },
    { lat: 48.052022, lng: 18.2405628 },
    { lat: 48.0520382, lng: 18.2406478 },
    { lat: 48.0522205, lng: 18.2408511 },
    { lat: 48.052187, lng: 18.2410325 },
    { lat: 48.0519947, lng: 18.2412036 },
    { lat: 48.0518705, lng: 18.241239 },
    { lat: 48.0515971, lng: 18.2411755 },
    { lat: 48.0513974, lng: 18.2409004 },
    { lat: 48.05126, lng: 18.240594 },
    { lat: 48.0512783, lng: 18.2403835 },
    { lat: 48.0513774, lng: 18.2401877 },
    { lat: 48.0512337, lng: 18.2395961 },
    { lat: 48.0514004, lng: 18.239143 },
    { lat: 48.0513799, lng: 18.2388674 },
    { lat: 48.0510508, lng: 18.238598 },
    { lat: 48.0509053, lng: 18.2386207 },
    { lat: 48.0507247, lng: 18.2389888 },
    { lat: 48.0507579, lng: 18.23929 },
    { lat: 48.0506963, lng: 18.2394915 },
    { lat: 48.0505704, lng: 18.2395991 },
    { lat: 48.0504427, lng: 18.2395954 },
    { lat: 48.050381, lng: 18.2395829 },
    { lat: 48.0502922, lng: 18.2394303 },
    { lat: 48.0502481, lng: 18.2391786 },
    { lat: 48.05036, lng: 18.2386934 },
    { lat: 48.0505603, lng: 18.2382529 },
    { lat: 48.0505842, lng: 18.2380456 },
    { lat: 48.0505817, lng: 18.2378058 },
    { lat: 48.0504884, lng: 18.2376171 },
    { lat: 48.0503192, lng: 18.2374428 },
    { lat: 48.049741, lng: 18.2373756 },
    { lat: 48.0493409, lng: 18.2371305 },
    { lat: 48.0492423, lng: 18.2368696 },
    { lat: 48.0491237, lng: 18.2361492 },
    { lat: 48.048977, lng: 18.2359714 },
    { lat: 48.0487429, lng: 18.2360615 },
    { lat: 48.0486425, lng: 18.2361982 },
    { lat: 48.0485095, lng: 18.23624 },
    { lat: 48.0484447, lng: 18.2361923 },
    { lat: 48.0482675, lng: 18.2357655 },
    { lat: 48.0477195, lng: 18.2353788 },
    { lat: 48.0475483, lng: 18.235172 },
    { lat: 48.0472864, lng: 18.2349769 },
    { lat: 48.0471804, lng: 18.2348012 },
    { lat: 48.0470457, lng: 18.2347156 },
    { lat: 48.0463559, lng: 18.2345552 },
    { lat: 48.0460665, lng: 18.2336869 },
    { lat: 48.0459496, lng: 18.2334823 },
    { lat: 48.0456622, lng: 18.2331787 },
    { lat: 48.0451583, lng: 18.2330103 },
    { lat: 48.0449786, lng: 18.2328113 },
    { lat: 48.0448248, lng: 18.2325113 },
    { lat: 48.0448141, lng: 18.232348 },
    { lat: 48.0365095, lng: 18.2375711 },
    { lat: 48.0351125, lng: 18.238448 },
    { lat: 48.0336617, lng: 18.2418814 },
    { lat: 48.0321953, lng: 18.2452591 },
    { lat: 48.02981, lng: 18.2511702 },
    { lat: 48.0293352, lng: 18.2525588 },
    { lat: 48.0288101, lng: 18.2543309 },
    { lat: 48.0283718, lng: 18.2559033 },
    { lat: 48.0281167, lng: 18.2570902 },
    { lat: 48.0276961, lng: 18.2586387 },
    { lat: 48.026326, lng: 18.262915 },
    { lat: 48.0260444, lng: 18.2639962 },
    { lat: 48.0258606, lng: 18.2652313 },
    { lat: 48.0257162, lng: 18.2672649 },
    { lat: 48.0258502, lng: 18.2688628 },
    { lat: 48.0263918, lng: 18.2717602 },
    { lat: 48.0267851, lng: 18.2741412 },
    { lat: 48.0250664, lng: 18.2792088 },
    { lat: 48.0250809, lng: 18.2792224 },
    { lat: 48.0258868, lng: 18.2805218 },
    { lat: 48.026873, lng: 18.2815034 },
    { lat: 48.027453, lng: 18.2821899 },
    { lat: 48.0284032, lng: 18.2840349 },
    { lat: 48.0294387, lng: 18.2849889 },
    { lat: 48.0300089, lng: 18.2858229 },
    { lat: 48.031167, lng: 18.2877342 },
    { lat: 48.0320895, lng: 18.2894726 },
    { lat: 48.0326848, lng: 18.290759 },
    { lat: 48.0331487, lng: 18.2913308 },
    { lat: 48.0334529, lng: 18.291815 },
    { lat: 48.0344766, lng: 18.293706 },
    { lat: 48.0351741, lng: 18.2951797 },
    { lat: 48.0357309, lng: 18.2960161 },
    { lat: 48.036384, lng: 18.296819 },
    { lat: 48.0367312, lng: 18.2974219 },
    { lat: 48.0378847, lng: 18.3010455 },
    { lat: 48.0389458, lng: 18.3046726 },
    { lat: 48.0410337, lng: 18.3096829 },
    { lat: 48.041605, lng: 18.311244 },
    { lat: 48.0416723, lng: 18.3112185 },
    { lat: 48.0450064, lng: 18.3094059 },
    { lat: 48.0462233, lng: 18.3085721 },
    { lat: 48.0475589, lng: 18.3075602 },
    { lat: 48.049017, lng: 18.3067608 },
    { lat: 48.0527411, lng: 18.3043878 },
    { lat: 48.0528586, lng: 18.3042352 },
    { lat: 48.0553319, lng: 18.3038602 },
    { lat: 48.0554686, lng: 18.3039835 },
    { lat: 48.0562927, lng: 18.303636 },
    { lat: 48.0596554, lng: 18.3033651 },
    { lat: 48.0596705, lng: 18.3031381 },
    { lat: 48.0596723, lng: 18.3030709 },
    { lat: 48.059484, lng: 18.2999439 },
    { lat: 48.0594447, lng: 18.298237 },
    { lat: 48.0592681, lng: 18.2963585 },
    { lat: 48.0593351, lng: 18.2961707 },
    { lat: 48.060506, lng: 18.2892507 },
    { lat: 48.0608966, lng: 18.2880368 },
    { lat: 48.0629612, lng: 18.2711091 },
    { lat: 48.0627125, lng: 18.265777 },
    { lat: 48.0624903, lng: 18.2636088 },
    { lat: 48.0622179, lng: 18.2601753 },
    { lat: 48.0606299, lng: 18.2538194 },
    { lat: 48.0586112, lng: 18.2480624 },
    { lat: 48.0581342, lng: 18.2481054 },
    { lat: 48.0579151, lng: 18.2480214 },
    { lat: 48.0577631, lng: 18.2478336 },
    { lat: 48.0576474, lng: 18.2473332 },
    { lat: 48.0575327, lng: 18.2472435 },
    { lat: 48.0572707, lng: 18.2474349 },
    { lat: 48.0572366, lng: 18.2475486 },
    { lat: 48.057235, lng: 18.2481051 },
    { lat: 48.0571531, lng: 18.2482725 },
    { lat: 48.05697, lng: 18.2482464 },
    { lat: 48.0568651, lng: 18.2480105 },
  ],
};

export default UnitsNovéZámky;
