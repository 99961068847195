const UnitsKomárno = {
  OkoličnánaOstrove: [
    { lat: 47.7763016, lng: 17.9176307 },
    { lat: 47.7763481, lng: 17.9176515 },
    { lat: 47.7767463, lng: 17.9178513 },
    { lat: 47.778354, lng: 17.9183746 },
    { lat: 47.7786372, lng: 17.9172831 },
    { lat: 47.7788276, lng: 17.9173887 },
    { lat: 47.7790771, lng: 17.9165393 },
    { lat: 47.7795296, lng: 17.9167957 },
    { lat: 47.7785871, lng: 17.9201231 },
    { lat: 47.7788713, lng: 17.9202467 },
    { lat: 47.7790015, lng: 17.9196828 },
    { lat: 47.7792047, lng: 17.9197909 },
    { lat: 47.7789189, lng: 17.9212506 },
    { lat: 47.7800228, lng: 17.9218978 },
    { lat: 47.7798461, lng: 17.9226802 },
    { lat: 47.7809878, lng: 17.9233003 },
    { lat: 47.7822604, lng: 17.9238449 },
    { lat: 47.7816189, lng: 17.9254489 },
    { lat: 47.7814104, lng: 17.9265833 },
    { lat: 47.7810401, lng: 17.9296373 },
    { lat: 47.782324, lng: 17.9300693 },
    { lat: 47.7822761, lng: 17.9352868 },
    { lat: 47.7820015, lng: 17.9384143 },
    { lat: 47.7828355, lng: 17.9385976 },
    { lat: 47.7842422, lng: 17.9387112 },
    { lat: 47.785325, lng: 17.9385717 },
    { lat: 47.7855911, lng: 17.9382869 },
    { lat: 47.7886178, lng: 17.9368682 },
    { lat: 47.7896277, lng: 17.9416473 },
    { lat: 47.7937946, lng: 17.9462087 },
    { lat: 47.7956382, lng: 17.9477749 },
    { lat: 47.7976371, lng: 17.9494618 },
    { lat: 47.8002006, lng: 17.9507117 },
    { lat: 47.8042116, lng: 17.9520959 },
    { lat: 47.8082136, lng: 17.9535771 },
    { lat: 47.8085055, lng: 17.953886 },
    { lat: 47.8089243, lng: 17.9547414 },
    { lat: 47.8090756, lng: 17.9548381 },
    { lat: 47.8096187, lng: 17.9548195 },
    { lat: 47.8109889, lng: 17.9555084 },
    { lat: 47.8112556, lng: 17.9555499 },
    { lat: 47.8119298, lng: 17.9558523 },
    { lat: 47.812703, lng: 17.957007 },
    { lat: 47.8133268, lng: 17.9578035 },
    { lat: 47.8139112, lng: 17.9564037 },
    { lat: 47.8144881, lng: 17.9560687 },
    { lat: 47.8148563, lng: 17.9560557 },
    { lat: 47.8154143, lng: 17.9555224 },
    { lat: 47.8163278, lng: 17.9548759 },
    { lat: 47.8184804, lng: 17.9527765 },
    { lat: 47.8196156, lng: 17.9511942 },
    { lat: 47.8196977, lng: 17.9513005 },
    { lat: 47.8206294, lng: 17.9494894 },
    { lat: 47.8215473, lng: 17.9478577 },
    { lat: 47.8232127, lng: 17.9451182 },
    { lat: 47.8236388, lng: 17.9446617 },
    { lat: 47.8238457, lng: 17.943796 },
    { lat: 47.8242798, lng: 17.9441203 },
    { lat: 47.8245253, lng: 17.9447428 },
    { lat: 47.8251205, lng: 17.9456981 },
    { lat: 47.8255876, lng: 17.9463435 },
    { lat: 47.8273425, lng: 17.9482885 },
    { lat: 47.8281349, lng: 17.949052 },
    { lat: 47.8301505, lng: 17.9532504 },
    { lat: 47.8304866, lng: 17.9542128 },
    { lat: 47.8305011, lng: 17.9546352 },
    { lat: 47.8320242, lng: 17.9547204 },
    { lat: 47.8330012, lng: 17.954859 },
    { lat: 47.8336237, lng: 17.9550574 },
    { lat: 47.8347218, lng: 17.9556095 },
    { lat: 47.8351673, lng: 17.9545087 },
    { lat: 47.8357841, lng: 17.9547022 },
    { lat: 47.8362066, lng: 17.9551375 },
    { lat: 47.8367505, lng: 17.9559342 },
    { lat: 47.8386364, lng: 17.9574176 },
    { lat: 47.83912, lng: 17.9572064 },
    { lat: 47.8392966, lng: 17.956823 },
    { lat: 47.8393819, lng: 17.9565165 },
    { lat: 47.8394062, lng: 17.9560446 },
    { lat: 47.8395113, lng: 17.955429 },
    { lat: 47.8396355, lng: 17.9552473 },
    { lat: 47.8403555, lng: 17.9547792 },
    { lat: 47.8408418, lng: 17.9542739 },
    { lat: 47.8416734, lng: 17.9528925 },
    { lat: 47.8419582, lng: 17.9522659 },
    { lat: 47.8420983, lng: 17.9516175 },
    { lat: 47.8420343, lng: 17.9508426 },
    { lat: 47.8418414, lng: 17.9501935 },
    { lat: 47.8413019, lng: 17.94906 },
    { lat: 47.8411643, lng: 17.9486333 },
    { lat: 47.841123, lng: 17.9481041 },
    { lat: 47.8412444, lng: 17.947576 },
    { lat: 47.8414311, lng: 17.9471919 },
    { lat: 47.8427899, lng: 17.9454586 },
    { lat: 47.8430227, lng: 17.9449267 },
    { lat: 47.8431206, lng: 17.9445489 },
    { lat: 47.8431952, lng: 17.9438647 },
    { lat: 47.8432253, lng: 17.9423913 },
    { lat: 47.8433064, lng: 17.941943 },
    { lat: 47.8435272, lng: 17.9416024 },
    { lat: 47.8437395, lng: 17.9414387 },
    { lat: 47.8441021, lng: 17.9413479 },
    { lat: 47.8445412, lng: 17.9414987 },
    { lat: 47.8452417, lng: 17.9419712 },
    { lat: 47.8458206, lng: 17.9422294 },
    { lat: 47.8464329, lng: 17.9423697 },
    { lat: 47.8468456, lng: 17.9423421 },
    { lat: 47.847362, lng: 17.9421868 },
    { lat: 47.8481511, lng: 17.9414642 },
    { lat: 47.8504658, lng: 17.9381896 },
    { lat: 47.8509913, lng: 17.9375158 },
    { lat: 47.851475, lng: 17.9370411 },
    { lat: 47.853489, lng: 17.9362668 },
    { lat: 47.8552765, lng: 17.9349802 },
    { lat: 47.8556589, lng: 17.9349018 },
    { lat: 47.8558239, lng: 17.9349576 },
    { lat: 47.8563556, lng: 17.9353312 },
    { lat: 47.8568939, lng: 17.9358324 },
    { lat: 47.8572141, lng: 17.9360105 },
    { lat: 47.8574259, lng: 17.9360042 },
    { lat: 47.8582772, lng: 17.9347445 },
    { lat: 47.8584967, lng: 17.9347927 },
    { lat: 47.8590166, lng: 17.9337461 },
    { lat: 47.85944, lng: 17.9327625 },
    { lat: 47.8598748, lng: 17.9322313 },
    { lat: 47.8595847, lng: 17.9314725 },
    { lat: 47.8594405, lng: 17.9313063 },
    { lat: 47.8592679, lng: 17.9302688 },
    { lat: 47.8585886, lng: 17.9292354 },
    { lat: 47.857944, lng: 17.9285868 },
    { lat: 47.8576876, lng: 17.9279732 },
    { lat: 47.8576176, lng: 17.9274881 },
    { lat: 47.8584406, lng: 17.9254894 },
    { lat: 47.8591455, lng: 17.9241662 },
    { lat: 47.8594413, lng: 17.9237753 },
    { lat: 47.8598906, lng: 17.9233898 },
    { lat: 47.8602671, lng: 17.923195 },
    { lat: 47.8620658, lng: 17.9198712 },
    { lat: 47.8622708, lng: 17.9194925 },
    { lat: 47.8615872, lng: 17.9195001 },
    { lat: 47.8570338, lng: 17.9149485 },
    { lat: 47.8555005, lng: 17.9138494 },
    { lat: 47.8549953, lng: 17.9140798 },
    { lat: 47.8542674, lng: 17.9148772 },
    { lat: 47.8539437, lng: 17.9142699 },
    { lat: 47.8523329, lng: 17.9116812 },
    { lat: 47.8530547, lng: 17.9102105 },
    { lat: 47.8522663, lng: 17.9095008 },
    { lat: 47.8518952, lng: 17.9100944 },
    { lat: 47.851274, lng: 17.9107011 },
    { lat: 47.8502837, lng: 17.9120667 },
    { lat: 47.8465124, lng: 17.9090095 },
    { lat: 47.845879, lng: 17.9097109 },
    { lat: 47.8449666, lng: 17.9111283 },
    { lat: 47.8446061, lng: 17.9101396 },
    { lat: 47.844458, lng: 17.9100271 },
    { lat: 47.8424814, lng: 17.9113918 },
    { lat: 47.8394763, lng: 17.9126257 },
    { lat: 47.8371111, lng: 17.9143201 },
    { lat: 47.8356289, lng: 17.9148189 },
    { lat: 47.834444, lng: 17.9153596 },
    { lat: 47.8333025, lng: 17.9129689 },
    { lat: 47.8318147, lng: 17.9102498 },
    { lat: 47.8313858, lng: 17.9095671 },
    { lat: 47.8301208, lng: 17.9082773 },
    { lat: 47.823782, lng: 17.9031159 },
    { lat: 47.82034, lng: 17.9004335 },
    { lat: 47.8192431, lng: 17.8994429 },
    { lat: 47.8106865, lng: 17.8926317 },
    { lat: 47.8092036, lng: 17.890994 },
    { lat: 47.8088161, lng: 17.8904926 },
    { lat: 47.8082893, lng: 17.8896894 },
    { lat: 47.8084217, lng: 17.8892509 },
    { lat: 47.8079889, lng: 17.8887865 },
    { lat: 47.807292, lng: 17.8878937 },
    { lat: 47.8062499, lng: 17.8863632 },
    { lat: 47.8058665, lng: 17.8855382 },
    { lat: 47.8048376, lng: 17.8826588 },
    { lat: 47.8046172, lng: 17.882749 },
    { lat: 47.8039106, lng: 17.8828596 },
    { lat: 47.8022255, lng: 17.8834676 },
    { lat: 47.8011738, lng: 17.8832906 },
    { lat: 47.7979885, lng: 17.8821519 },
    { lat: 47.7976959, lng: 17.8843382 },
    { lat: 47.7973831, lng: 17.8861403 },
    { lat: 47.7948217, lng: 17.8842797 },
    { lat: 47.794821, lng: 17.8859763 },
    { lat: 47.7946563, lng: 17.8876716 },
    { lat: 47.7943599, lng: 17.8885292 },
    { lat: 47.7939241, lng: 17.8895186 },
    { lat: 47.792993, lng: 17.8923027 },
    { lat: 47.7928607, lng: 17.8927768 },
    { lat: 47.7927845, lng: 17.8933042 },
    { lat: 47.7928372, lng: 17.8936288 },
    { lat: 47.7945104, lng: 17.896067 },
    { lat: 47.7924845, lng: 17.9026715 },
    { lat: 47.7923303, lng: 17.9025136 },
    { lat: 47.7922264, lng: 17.9027909 },
    { lat: 47.7922883, lng: 17.9024726 },
    { lat: 47.7922372, lng: 17.9024142 },
    { lat: 47.7873869, lng: 17.8974352 },
    { lat: 47.7866622, lng: 17.8984859 },
    { lat: 47.7840488, lng: 17.9033126 },
    { lat: 47.7837251, lng: 17.9031133 },
    { lat: 47.7835473, lng: 17.903417 },
    { lat: 47.7828351, lng: 17.9030844 },
    { lat: 47.7801347, lng: 17.9070645 },
    { lat: 47.7795168, lng: 17.9081454 },
    { lat: 47.7792795, lng: 17.9088416 },
    { lat: 47.7789506, lng: 17.908855 },
    { lat: 47.7788492, lng: 17.9102397 },
    { lat: 47.7785352, lng: 17.9117892 },
    { lat: 47.7782543, lng: 17.9124021 },
    { lat: 47.7767878, lng: 17.9160912 },
    { lat: 47.7764713, lng: 17.9170819 },
    { lat: 47.7763016, lng: 17.9176307 },
  ],
  Šrobárová: [
    { lat: 47.8116507, lng: 18.3373631 },
    { lat: 47.8122545, lng: 18.3364954 },
    { lat: 47.814553, lng: 18.334395 },
    { lat: 47.8158179, lng: 18.3326837 },
    { lat: 47.8162437, lng: 18.3322009 },
    { lat: 47.8161708, lng: 18.3320729 },
    { lat: 47.8163007, lng: 18.3318771 },
    { lat: 47.8160729, lng: 18.331524 },
    { lat: 47.8205161, lng: 18.3230902 },
    { lat: 47.8225064, lng: 18.3171799 },
    { lat: 47.8234365, lng: 18.3178326 },
    { lat: 47.8244056, lng: 18.3150238 },
    { lat: 47.8236765, lng: 18.3145885 },
    { lat: 47.8244232, lng: 18.3124454 },
    { lat: 47.8244307, lng: 18.3124205 },
    { lat: 47.8241856, lng: 18.3119815 },
    { lat: 47.8236699, lng: 18.311508 },
    { lat: 47.8232133, lng: 18.3107711 },
    { lat: 47.8226604, lng: 18.3101412 },
    { lat: 47.8221043, lng: 18.3097705 },
    { lat: 47.8216816, lng: 18.3097219 },
    { lat: 47.8214742, lng: 18.3095481 },
    { lat: 47.8212322, lng: 18.3091642 },
    { lat: 47.8206799, lng: 18.3080172 },
    { lat: 47.8203153, lng: 18.3076029 },
    { lat: 47.819715, lng: 18.3072329 },
    { lat: 47.8185606, lng: 18.3067159 },
    { lat: 47.8185423, lng: 18.3067526 },
    { lat: 47.8176959, lng: 18.3060456 },
    { lat: 47.8171437, lng: 18.3056608 },
    { lat: 47.8164967, lng: 18.3053431 },
    { lat: 47.8162306, lng: 18.3053046 },
    { lat: 47.816044, lng: 18.3051798 },
    { lat: 47.8133607, lng: 18.3030822 },
    { lat: 47.8111772, lng: 18.301509 },
    { lat: 47.8097244, lng: 18.3002215 },
    { lat: 47.8070694, lng: 18.2980662 },
    { lat: 47.8057888, lng: 18.2969342 },
    { lat: 47.8048811, lng: 18.2962978 },
    { lat: 47.8041096, lng: 18.2956378 },
    { lat: 47.8034789, lng: 18.2949908 },
    { lat: 47.802504, lng: 18.2937983 },
    { lat: 47.8019655, lng: 18.2932656 },
    { lat: 47.8012723, lng: 18.292435 },
    { lat: 47.8005517, lng: 18.2917185 },
    { lat: 47.7996259, lng: 18.2905943 },
    { lat: 47.7987193, lng: 18.2896664 },
    { lat: 47.7978828, lng: 18.2886428 },
    { lat: 47.7951759, lng: 18.285652 },
    { lat: 47.7951543, lng: 18.2856302 },
    { lat: 47.7942788, lng: 18.2872921 },
    { lat: 47.8035456, lng: 18.2975385 },
    { lat: 47.8006261, lng: 18.3012652 },
    { lat: 47.8023649, lng: 18.3033227 },
    { lat: 47.8041646, lng: 18.3051462 },
    { lat: 47.8057777, lng: 18.3070984 },
    { lat: 47.8001487, lng: 18.3142386 },
    { lat: 47.799925, lng: 18.3145252 },
    { lat: 47.7999416, lng: 18.3145596 },
    { lat: 47.7998335, lng: 18.3146828 },
    { lat: 47.7998161, lng: 18.3146345 },
    { lat: 47.7971712, lng: 18.318084 },
    { lat: 47.7964214, lng: 18.3176021 },
    { lat: 47.7958701, lng: 18.3171389 },
    { lat: 47.795384, lng: 18.3164931 },
    { lat: 47.7946218, lng: 18.315108 },
    { lat: 47.7941236, lng: 18.3140799 },
    { lat: 47.792506, lng: 18.3116923 },
    { lat: 47.792357, lng: 18.3117284 },
    { lat: 47.7894377, lng: 18.3147794 },
    { lat: 47.7888228, lng: 18.3151548 },
    { lat: 47.7874194, lng: 18.3163717 },
    { lat: 47.7868529, lng: 18.3170184 },
    { lat: 47.7844076, lng: 18.3206259 },
    { lat: 47.7844324, lng: 18.3206627 },
    { lat: 47.7843983, lng: 18.3207193 },
    { lat: 47.7929011, lng: 18.3318042 },
    { lat: 47.7928985, lng: 18.3316592 },
    { lat: 47.7932831, lng: 18.3309493 },
    { lat: 47.7940967, lng: 18.3298493 },
    { lat: 47.7983431, lng: 18.3226917 },
    { lat: 47.8029708, lng: 18.3287968 },
    { lat: 47.7923558, lng: 18.3471183 },
    { lat: 47.792593, lng: 18.3474372 },
    { lat: 47.7925376, lng: 18.347514 },
    { lat: 47.7922721, lng: 18.3471545 },
    { lat: 47.792222, lng: 18.3472268 },
    { lat: 47.7918909, lng: 18.3470794 },
    { lat: 47.7915854, lng: 18.3471986 },
    { lat: 47.791056, lng: 18.3475688 },
    { lat: 47.7907991, lng: 18.3477531 },
    { lat: 47.796581, lng: 18.3557399 },
    { lat: 47.8094025, lng: 18.3372869 },
    { lat: 47.8097537, lng: 18.3376482 },
    { lat: 47.8109207, lng: 18.3362174 },
    { lat: 47.8116337, lng: 18.3373382 },
    { lat: 47.8116507, lng: 18.3373631 },
  ],
  Brestovec: [
    { lat: 47.857217, lng: 17.79806 },
    { lat: 47.8569369, lng: 17.7991545 },
    { lat: 47.8568945, lng: 17.7991259 },
    { lat: 47.8555263, lng: 17.8034763 },
    { lat: 47.8552851, lng: 17.8046484 },
    { lat: 47.8552876, lng: 17.8049447 },
    { lat: 47.8551867, lng: 17.8052833 },
    { lat: 47.8561349, lng: 17.8065926 },
    { lat: 47.8576928, lng: 17.809106 },
    { lat: 47.8572106, lng: 17.8097431 },
    { lat: 47.8550549, lng: 17.8118645 },
    { lat: 47.8540122, lng: 17.8132407 },
    { lat: 47.8548937, lng: 17.8147708 },
    { lat: 47.8550299, lng: 17.8146348 },
    { lat: 47.8560149, lng: 17.8165423 },
    { lat: 47.8556469, lng: 17.8168523 },
    { lat: 47.8558002, lng: 17.8188724 },
    { lat: 47.855781, lng: 17.8199309 },
    { lat: 47.8556611, lng: 17.8208942 },
    { lat: 47.8553652, lng: 17.8217865 },
    { lat: 47.8552653, lng: 17.8222174 },
    { lat: 47.8551798, lng: 17.8228975 },
    { lat: 47.8551426, lng: 17.8252526 },
    { lat: 47.8551439, lng: 17.8254918 },
    { lat: 47.8555941, lng: 17.8257646 },
    { lat: 47.8573414, lng: 17.8264158 },
    { lat: 47.8600146, lng: 17.8275516 },
    { lat: 47.8602841, lng: 17.8275838 },
    { lat: 47.8620296, lng: 17.8277499 },
    { lat: 47.862018, lng: 17.8271799 },
    { lat: 47.8633886, lng: 17.8271599 },
    { lat: 47.864664, lng: 17.8270326 },
    { lat: 47.8659103, lng: 17.8267729 },
    { lat: 47.8662531, lng: 17.8277734 },
    { lat: 47.8667765, lng: 17.8295561 },
    { lat: 47.8672722, lng: 17.8309675 },
    { lat: 47.8681052, lng: 17.8330494 },
    { lat: 47.868413, lng: 17.8337404 },
    { lat: 47.8699344, lng: 17.8366785 },
    { lat: 47.871133, lng: 17.8387076 },
    { lat: 47.872148, lng: 17.8402837 },
    { lat: 47.8724267, lng: 17.8406293 },
    { lat: 47.8849093, lng: 17.8587231 },
    { lat: 47.8860757, lng: 17.8601391 },
    { lat: 47.8869311, lng: 17.8613473 },
    { lat: 47.8898854, lng: 17.8638478 },
    { lat: 47.890968, lng: 17.8660175 },
    { lat: 47.8922131, lng: 17.8697507 },
    { lat: 47.8933213, lng: 17.8709217 },
    { lat: 47.8938355, lng: 17.8708938 },
    { lat: 47.894228, lng: 17.8709644 },
    { lat: 47.8944043, lng: 17.8711163 },
    { lat: 47.8945008, lng: 17.871621 },
    { lat: 47.8941477, lng: 17.8722665 },
    { lat: 47.8940651, lng: 17.872558 },
    { lat: 47.8942308, lng: 17.8734398 },
    { lat: 47.8943224, lng: 17.8736454 },
    { lat: 47.8944519, lng: 17.873732 },
    { lat: 47.8948856, lng: 17.8738655 },
    { lat: 47.8949932, lng: 17.8740335 },
    { lat: 47.8950312, lng: 17.8745268 },
    { lat: 47.8951796, lng: 17.8748478 },
    { lat: 47.8959461, lng: 17.8748839 },
    { lat: 47.8963411, lng: 17.8747526 },
    { lat: 47.8970942, lng: 17.8743701 },
    { lat: 47.8972994, lng: 17.874349 },
    { lat: 47.8979392, lng: 17.8746558 },
    { lat: 47.898498, lng: 17.8748045 },
    { lat: 47.8988924, lng: 17.8747939 },
    { lat: 47.8993523, lng: 17.8745979 },
    { lat: 47.8997072, lng: 17.8746606 },
    { lat: 47.9001441, lng: 17.8747733 },
    { lat: 47.9010218, lng: 17.8751686 },
    { lat: 47.9012678, lng: 17.875168 },
    { lat: 47.9023258, lng: 17.8748229 },
    { lat: 47.9025072, lng: 17.8748156 },
    { lat: 47.902551, lng: 17.874864 },
    { lat: 47.90336, lng: 17.874113 },
    { lat: 47.902334, lng: 17.871017 },
    { lat: 47.901642, lng: 17.869661 },
    { lat: 47.901336, lng: 17.868898 },
    { lat: 47.901227, lng: 17.868753 },
    { lat: 47.89992, lng: 17.86593 },
    { lat: 47.899416, lng: 17.864947 },
    { lat: 47.897894, lng: 17.862395 },
    { lat: 47.897413, lng: 17.861532 },
    { lat: 47.89591, lng: 17.857543 },
    { lat: 47.894455, lng: 17.853762 },
    { lat: 47.895002, lng: 17.852644 },
    { lat: 47.894187, lng: 17.850737 },
    { lat: 47.892794, lng: 17.848086 },
    { lat: 47.892758, lng: 17.848015 },
    { lat: 47.892638, lng: 17.847776 },
    { lat: 47.892594, lng: 17.847688 },
    { lat: 47.891861, lng: 17.846298 },
    { lat: 47.889214, lng: 17.844392 },
    { lat: 47.888655, lng: 17.843898 },
    { lat: 47.883971, lng: 17.839815 },
    { lat: 47.87944, lng: 17.836236 },
    { lat: 47.878684, lng: 17.835665 },
    { lat: 47.876941, lng: 17.834252 },
    { lat: 47.875061, lng: 17.832736 },
    { lat: 47.874265, lng: 17.830943 },
    { lat: 47.874251, lng: 17.830909 },
    { lat: 47.873356, lng: 17.828893 },
    { lat: 47.871619, lng: 17.825294 },
    { lat: 47.87162, lng: 17.825293 },
    { lat: 47.871725, lng: 17.825093 },
    { lat: 47.871817, lng: 17.82492 },
    { lat: 47.871925, lng: 17.824713 },
    { lat: 47.872624, lng: 17.823388 },
    { lat: 47.872825, lng: 17.821953 },
    { lat: 47.873235, lng: 17.821489 },
    { lat: 47.87392, lng: 17.820988 },
    { lat: 47.873609, lng: 17.818941 },
    { lat: 47.873562, lng: 17.817817 },
    { lat: 47.872848, lng: 17.816548 },
    { lat: 47.87239, lng: 17.815614 },
    { lat: 47.872629, lng: 17.815292 },
    { lat: 47.872419, lng: 17.814933 },
    { lat: 47.872174, lng: 17.814393 },
    { lat: 47.872038, lng: 17.814009 },
    { lat: 47.872069, lng: 17.813614 },
    { lat: 47.871963, lng: 17.813329 },
    { lat: 47.871833, lng: 17.812986 },
    { lat: 47.871726, lng: 17.812701 },
    { lat: 47.871363, lng: 17.811538 },
    { lat: 47.871356, lng: 17.811045 },
    { lat: 47.871265, lng: 17.810335 },
    { lat: 47.871041, lng: 17.810036 },
    { lat: 47.870843, lng: 17.809589 },
    { lat: 47.870611, lng: 17.808839 },
    { lat: 47.870324, lng: 17.808194 },
    { lat: 47.870602, lng: 17.807691 },
    { lat: 47.869835, lng: 17.80707 },
    { lat: 47.869372, lng: 17.806617 },
    { lat: 47.869241, lng: 17.806367 },
    { lat: 47.868892, lng: 17.806586 },
    { lat: 47.868397, lng: 17.806898 },
    { lat: 47.868326, lng: 17.806944 },
    { lat: 47.868241, lng: 17.807 },
    { lat: 47.867974, lng: 17.807138 },
    { lat: 47.867204, lng: 17.807534 },
    { lat: 47.867199, lng: 17.807531 },
    { lat: 47.866909, lng: 17.807397 },
    { lat: 47.866712, lng: 17.807306 },
    { lat: 47.866207, lng: 17.807074 },
    { lat: 47.866174, lng: 17.807058 },
    { lat: 47.865716, lng: 17.806848 },
    { lat: 47.865397, lng: 17.806701 },
    { lat: 47.865182, lng: 17.806606 },
    { lat: 47.865177, lng: 17.806599 },
    { lat: 47.864969, lng: 17.806326 },
    { lat: 47.864741, lng: 17.806025 },
    { lat: 47.864557, lng: 17.805783 },
    { lat: 47.864405, lng: 17.805419 },
    { lat: 47.864218, lng: 17.804964 },
    { lat: 47.86412, lng: 17.804726 },
    { lat: 47.863772, lng: 17.804062 },
    { lat: 47.863567, lng: 17.803762 },
    { lat: 47.863448, lng: 17.803608 },
    { lat: 47.863105, lng: 17.803268 },
    { lat: 47.862782, lng: 17.802965 },
    { lat: 47.862699, lng: 17.8029 },
    { lat: 47.862155, lng: 17.802498 },
    { lat: 47.86204, lng: 17.802413 },
    { lat: 47.861927, lng: 17.80233 },
    { lat: 47.861893, lng: 17.802305 },
    { lat: 47.861676, lng: 17.802071 },
    { lat: 47.861599, lng: 17.801986 },
    { lat: 47.861523, lng: 17.801904 },
    { lat: 47.861363, lng: 17.801732 },
    { lat: 47.861299, lng: 17.801662 },
    { lat: 47.861197, lng: 17.801553 },
    { lat: 47.861045, lng: 17.801387 },
    { lat: 47.860902, lng: 17.801232 },
    { lat: 47.860823, lng: 17.801147 },
    { lat: 47.86076, lng: 17.801078 },
    { lat: 47.860664, lng: 17.800973 },
    { lat: 47.860615, lng: 17.800919 },
    { lat: 47.860564, lng: 17.800863 },
    { lat: 47.860475, lng: 17.800767 },
    { lat: 47.860422, lng: 17.800707 },
    { lat: 47.860417, lng: 17.800705 },
    { lat: 47.86037, lng: 17.800665 },
    { lat: 47.860053, lng: 17.800403 },
    { lat: 47.859999, lng: 17.800359 },
    { lat: 47.859985, lng: 17.800348 },
    { lat: 47.859958, lng: 17.800325 },
    { lat: 47.859942, lng: 17.800312 },
    { lat: 47.859906, lng: 17.800282 },
    { lat: 47.859797, lng: 17.800193 },
    { lat: 47.859748, lng: 17.800152 },
    { lat: 47.859438, lng: 17.799898 },
    { lat: 47.859374, lng: 17.799844 },
    { lat: 47.859235, lng: 17.799729 },
    { lat: 47.859181, lng: 17.799686 },
    { lat: 47.859163, lng: 17.799671 },
    { lat: 47.859097, lng: 17.799616 },
    { lat: 47.858865, lng: 17.799425 },
    { lat: 47.858622, lng: 17.799224 },
    { lat: 47.858593, lng: 17.799201 },
    { lat: 47.858335, lng: 17.798987 },
    { lat: 47.858307, lng: 17.798964 },
    { lat: 47.858229, lng: 17.7989 },
    { lat: 47.858188, lng: 17.798866 },
    { lat: 47.858011, lng: 17.798719 },
    { lat: 47.858001, lng: 17.798712 },
    { lat: 47.85774, lng: 17.798495 },
    { lat: 47.857695, lng: 17.798458 },
    { lat: 47.857527, lng: 17.79832 },
    { lat: 47.857414, lng: 17.798224 },
    { lat: 47.857217, lng: 17.79806 },
  ],
  Iža: [
    { lat: 47.824619, lng: 18.2717696 },
    { lat: 47.824596, lng: 18.271782 },
    { lat: 47.8239251, lng: 18.2710489 },
    { lat: 47.8232537, lng: 18.2706413 },
    { lat: 47.8206382, lng: 18.2674809 },
    { lat: 47.8183255, lng: 18.2653652 },
    { lat: 47.8145232, lng: 18.2610266 },
    { lat: 47.8131602, lng: 18.2588296 },
    { lat: 47.811131, lng: 18.2563873 },
    { lat: 47.8086995, lng: 18.252678 },
    { lat: 47.8068374, lng: 18.2502817 },
    { lat: 47.8037689, lng: 18.2475523 },
    { lat: 47.8033645, lng: 18.2474854 },
    { lat: 47.8014678, lng: 18.2462662 },
    { lat: 47.7974414, lng: 18.2425907 },
    { lat: 47.7949927, lng: 18.2402004 },
    { lat: 47.793054, lng: 18.2379108 },
    { lat: 47.7912821, lng: 18.2362951 },
    { lat: 47.7907299, lng: 18.2358628 },
    { lat: 47.7890889, lng: 18.2338192 },
    { lat: 47.7883459, lng: 18.2334047 },
    { lat: 47.7877653, lng: 18.232521 },
    { lat: 47.7877151, lng: 18.2322608 },
    { lat: 47.7866765, lng: 18.2313204 },
    { lat: 47.7859154, lng: 18.2305381 },
    { lat: 47.7854445, lng: 18.229973 },
    { lat: 47.7842249, lng: 18.2288125 },
    { lat: 47.7819537, lng: 18.2269983 },
    { lat: 47.7824099, lng: 18.2267314 },
    { lat: 47.7826549, lng: 18.2260744 },
    { lat: 47.7825392, lng: 18.2250607 },
    { lat: 47.7821673, lng: 18.2245461 },
    { lat: 47.7820544, lng: 18.2235186 },
    { lat: 47.7816705, lng: 18.2191455 },
    { lat: 47.7817469, lng: 18.2185979 },
    { lat: 47.7817354, lng: 18.2175515 },
    { lat: 47.781647, lng: 18.2152243 },
    { lat: 47.7814736, lng: 18.2138984 },
    { lat: 47.7814179, lng: 18.2126546 },
    { lat: 47.7813979, lng: 18.2078555 },
    { lat: 47.7817702, lng: 18.2057707 },
    { lat: 47.7815946, lng: 18.2057193 },
    { lat: 47.7816355, lng: 18.2050485 },
    { lat: 47.7814285, lng: 18.2043686 },
    { lat: 47.7813786, lng: 18.2033211 },
    { lat: 47.7813076, lng: 18.2028791 },
    { lat: 47.7813781, lng: 18.2025406 },
    { lat: 47.7816322, lng: 18.2018897 },
    { lat: 47.7816633, lng: 18.2015324 },
    { lat: 47.7816001, lng: 18.2009537 },
    { lat: 47.7810028, lng: 18.1997537 },
    { lat: 47.7808695, lng: 18.1991524 },
    { lat: 47.7804086, lng: 18.1980656 },
    { lat: 47.7802772, lng: 18.1973248 },
    { lat: 47.7802773, lng: 18.197095 },
    { lat: 47.7804312, lng: 18.196256 },
    { lat: 47.781718, lng: 18.1952421 },
    { lat: 47.7823554, lng: 18.1950673 },
    { lat: 47.7825105, lng: 18.1948813 },
    { lat: 47.7827708, lng: 18.1941261 },
    { lat: 47.7828215, lng: 18.1936603 },
    { lat: 47.7827779, lng: 18.1932825 },
    { lat: 47.7826427, lng: 18.1929214 },
    { lat: 47.7819431, lng: 18.192096 },
    { lat: 47.7817596, lng: 18.1918327 },
    { lat: 47.7816152, lng: 18.1914679 },
    { lat: 47.7792236, lng: 18.1905353 },
    { lat: 47.775796, lng: 18.1898307 },
    { lat: 47.7740658, lng: 18.1892658 },
    { lat: 47.773018, lng: 18.1890646 },
    { lat: 47.7715689, lng: 18.1886754 },
    { lat: 47.7681842, lng: 18.1873218 },
    { lat: 47.7674831, lng: 18.1869101 },
    { lat: 47.7669137, lng: 18.1867644 },
    { lat: 47.7660407, lng: 18.1866368 },
    { lat: 47.7631029, lng: 18.1865507 },
    { lat: 47.7614212, lng: 18.1863547 },
    { lat: 47.7602546, lng: 18.1858363 },
    { lat: 47.7596746, lng: 18.1854128 },
    { lat: 47.758509, lng: 18.1843006 },
    { lat: 47.7562151, lng: 18.1823231 },
    { lat: 47.7554689, lng: 18.1818013 },
    { lat: 47.7528778, lng: 18.1898496 },
    { lat: 47.753496, lng: 18.190387 },
    { lat: 47.7526142, lng: 18.1945508 },
    { lat: 47.7519056, lng: 18.194455 },
    { lat: 47.7507968, lng: 18.1937529 },
    { lat: 47.7504552, lng: 18.1921014 },
    { lat: 47.7501015, lng: 18.1906913 },
    { lat: 47.7490654, lng: 18.1912039 },
    { lat: 47.7479456, lng: 18.1911601 },
    { lat: 47.7460802, lng: 18.1914073 },
    { lat: 47.7457513, lng: 18.1915718 },
    { lat: 47.7453633, lng: 18.1916327 },
    { lat: 47.7453631, lng: 18.1915889 },
    { lat: 47.7443403, lng: 18.1913534 },
    { lat: 47.7437368, lng: 18.1913155 },
    { lat: 47.7437624, lng: 18.1914461 },
    { lat: 47.7420548, lng: 18.1913631 },
    { lat: 47.7420546, lng: 18.1913032 },
    { lat: 47.7400102, lng: 18.1912739 },
    { lat: 47.7398961, lng: 18.1942659 },
    { lat: 47.7399167, lng: 18.1962475 },
    { lat: 47.7400166, lng: 18.1982238 },
    { lat: 47.7401957, lng: 18.2001877 },
    { lat: 47.7404531, lng: 18.2021324 },
    { lat: 47.7410825, lng: 18.2054432 },
    { lat: 47.7414558, lng: 18.207375 },
    { lat: 47.7417728, lng: 18.2093291 },
    { lat: 47.7418311, lng: 18.209921 },
    { lat: 47.7420834, lng: 18.2124797 },
    { lat: 47.7421712, lng: 18.214153 },
    { lat: 47.742191, lng: 18.2158315 },
    { lat: 47.7421425, lng: 18.2175085 },
    { lat: 47.7421109, lng: 18.2181878 },
    { lat: 47.7420956, lng: 18.219319 },
    { lat: 47.7420542, lng: 18.2203439 },
    { lat: 47.7419802, lng: 18.2213647 },
    { lat: 47.7419182, lng: 18.2224498 },
    { lat: 47.7419007, lng: 18.2235389 },
    { lat: 47.7418714, lng: 18.2249166 },
    { lat: 47.7417642, lng: 18.2262857 },
    { lat: 47.7415797, lng: 18.2276366 },
    { lat: 47.741324, lng: 18.2289644 },
    { lat: 47.7412073, lng: 18.2298302 },
    { lat: 47.7411434, lng: 18.2305654 },
    { lat: 47.7410304, lng: 18.2316712 },
    { lat: 47.7408354, lng: 18.2333156 },
    { lat: 47.7406603, lng: 18.2349648 },
    { lat: 47.7405053, lng: 18.2366187 },
    { lat: 47.7403703, lng: 18.2382762 },
    { lat: 47.7402553, lng: 18.2399372 },
    { lat: 47.7401606, lng: 18.2416009 },
    { lat: 47.740086, lng: 18.243267 },
    { lat: 47.739984, lng: 18.2452395 },
    { lat: 47.7398063, lng: 18.2472003 },
    { lat: 47.7395533, lng: 18.2491427 },
    { lat: 47.7394579, lng: 18.2501943 },
    { lat: 47.7394334, lng: 18.2512547 },
    { lat: 47.7394669, lng: 18.2520509 },
    { lat: 47.7394382, lng: 18.2530517 },
    { lat: 47.7393462, lng: 18.2540441 },
    { lat: 47.739192, lng: 18.2550191 },
    { lat: 47.7390419, lng: 18.2557777 },
    { lat: 47.7410048, lng: 18.2568585 },
    { lat: 47.743376, lng: 18.2585963 },
    { lat: 47.7506132, lng: 18.2635938 },
    { lat: 47.7526674, lng: 18.264947 },
    { lat: 47.754196, lng: 18.2662284 },
    { lat: 47.755314, lng: 18.267042 },
    { lat: 47.758732, lng: 18.2697335 },
    { lat: 47.7594133, lng: 18.270295 },
    { lat: 47.7610209, lng: 18.271458 },
    { lat: 47.7624476, lng: 18.2728514 },
    { lat: 47.7631221, lng: 18.2736824 },
    { lat: 47.7637475, lng: 18.2741779 },
    { lat: 47.7679506, lng: 18.2779585 },
    { lat: 47.7701383, lng: 18.2800255 },
    { lat: 47.7706382, lng: 18.2782896 },
    { lat: 47.7709305, lng: 18.2770235 },
    { lat: 47.7725437, lng: 18.2726534 },
    { lat: 47.7728714, lng: 18.2704577 },
    { lat: 47.7728788, lng: 18.2695575 },
    { lat: 47.7733946, lng: 18.2694977 },
    { lat: 47.7733688, lng: 18.269172 },
    { lat: 47.7735136, lng: 18.2681237 },
    { lat: 47.7738402, lng: 18.267437 },
    { lat: 47.7740915, lng: 18.2672528 },
    { lat: 47.7751065, lng: 18.266118 },
    { lat: 47.775513, lng: 18.2634751 },
    { lat: 47.7755227, lng: 18.2630961 },
    { lat: 47.7757377, lng: 18.2624085 },
    { lat: 47.7758482, lng: 18.2617469 },
    { lat: 47.7760196, lng: 18.2604584 },
    { lat: 47.7759466, lng: 18.2592408 },
    { lat: 47.7758785, lng: 18.2586086 },
    { lat: 47.7755072, lng: 18.2579675 },
    { lat: 47.7754696, lng: 18.2575924 },
    { lat: 47.775754, lng: 18.2561341 },
    { lat: 47.7759947, lng: 18.2551521 },
    { lat: 47.7771108, lng: 18.2523734 },
    { lat: 47.7771784, lng: 18.2520434 },
    { lat: 47.7774351, lng: 18.2518391 },
    { lat: 47.7776994, lng: 18.2512998 },
    { lat: 47.7780592, lng: 18.2509865 },
    { lat: 47.7785031, lng: 18.250239 },
    { lat: 47.77869, lng: 18.2498422 },
    { lat: 47.7788218, lng: 18.2493711 },
    { lat: 47.7788457, lng: 18.2490067 },
    { lat: 47.7786834, lng: 18.2485452 },
    { lat: 47.7782624, lng: 18.2478624 },
    { lat: 47.7782357, lng: 18.247501 },
    { lat: 47.7783886, lng: 18.2464631 },
    { lat: 47.778548, lng: 18.2457474 },
    { lat: 47.7785835, lng: 18.2453497 },
    { lat: 47.7786452, lng: 18.2452074 },
    { lat: 47.7789349, lng: 18.2449314 },
    { lat: 47.7792578, lng: 18.2448371 },
    { lat: 47.7797651, lng: 18.2448708 },
    { lat: 47.7798117, lng: 18.2437896 },
    { lat: 47.7797927, lng: 18.2432122 },
    { lat: 47.7792322, lng: 18.240987 },
    { lat: 47.779163, lng: 18.2404549 },
    { lat: 47.7792547, lng: 18.2396665 },
    { lat: 47.7793507, lng: 18.2392974 },
    { lat: 47.7799046, lng: 18.2380559 },
    { lat: 47.779967, lng: 18.2378083 },
    { lat: 47.7799451, lng: 18.2369485 },
    { lat: 47.7798192, lng: 18.2363942 },
    { lat: 47.7796528, lng: 18.2360177 },
    { lat: 47.7794982, lng: 18.2354535 },
    { lat: 47.7794441, lng: 18.2343136 },
    { lat: 47.7796439, lng: 18.233384 },
    { lat: 47.7799437, lng: 18.2326057 },
    { lat: 47.7801211, lng: 18.2318916 },
    { lat: 47.7834076, lng: 18.2342478 },
    { lat: 47.7844552, lng: 18.2352032 },
    { lat: 47.7855333, lng: 18.2358147 },
    { lat: 47.7876609, lng: 18.2379965 },
    { lat: 47.7887805, lng: 18.2390459 },
    { lat: 47.7894307, lng: 18.2397888 },
    { lat: 47.7903844, lng: 18.2406838 },
    { lat: 47.7919244, lng: 18.2419616 },
    { lat: 47.7932083, lng: 18.2432982 },
    { lat: 47.7940244, lng: 18.2444478 },
    { lat: 47.7953089, lng: 18.2457953 },
    { lat: 47.7959902, lng: 18.2463334 },
    { lat: 47.7967857, lng: 18.2468369 },
    { lat: 47.7977608, lng: 18.247077 },
    { lat: 47.7983697, lng: 18.2473205 },
    { lat: 47.8006863, lng: 18.2485249 },
    { lat: 47.8020413, lng: 18.250175 },
    { lat: 47.8035944, lng: 18.2518556 },
    { lat: 47.8051235, lng: 18.2537605 },
    { lat: 47.8069275, lng: 18.2561477 },
    { lat: 47.8083995, lng: 18.2582819 },
    { lat: 47.8087826, lng: 18.2589511 },
    { lat: 47.8093454, lng: 18.2596654 },
    { lat: 47.8112221, lng: 18.2618117 },
    { lat: 47.812129, lng: 18.26274 },
    { lat: 47.8137913, lng: 18.2642073 },
    { lat: 47.8151807, lng: 18.2658889 },
    { lat: 47.8181703, lng: 18.2702259 },
    { lat: 47.8198381, lng: 18.2722153 },
    { lat: 47.8205933, lng: 18.2730036 },
    { lat: 47.8218409, lng: 18.274518 },
    { lat: 47.8240214, lng: 18.2766161 },
    { lat: 47.8254078, lng: 18.2782295 },
    { lat: 47.8259561, lng: 18.2790486 },
    { lat: 47.8259107, lng: 18.2794164 },
    { lat: 47.8275742, lng: 18.2823485 },
    { lat: 47.8291743, lng: 18.2862612 },
    { lat: 47.8301475, lng: 18.2883992 },
    { lat: 47.8325136, lng: 18.291003 },
    { lat: 47.8322138, lng: 18.2899905 },
    { lat: 47.8318157, lng: 18.288177 },
    { lat: 47.8310398, lng: 18.2858561 },
    { lat: 47.8303769, lng: 18.2830322 },
    { lat: 47.8296314, lng: 18.2809109 },
    { lat: 47.8272127, lng: 18.2763682 },
    { lat: 47.8260734, lng: 18.2741095 },
    { lat: 47.8257854, lng: 18.273443 },
    { lat: 47.8247269, lng: 18.2718819 },
    { lat: 47.824619, lng: 18.2717696 },
  ],
  Nesvady: [
    { lat: 47.947548, lng: 18.070234 },
    { lat: 47.946963, lng: 18.0705104 },
    { lat: 47.9461526, lng: 18.0707173 },
    { lat: 47.9457586, lng: 18.0706182 },
    { lat: 47.9452003, lng: 18.0703022 },
    { lat: 47.9450189, lng: 18.0701747 },
    { lat: 47.9447583, lng: 18.0698564 },
    { lat: 47.944477, lng: 18.0693168 },
    { lat: 47.9437162, lng: 18.0678573 },
    { lat: 47.9434948, lng: 18.0677017 },
    { lat: 47.9433215, lng: 18.0677112 },
    { lat: 47.9431275, lng: 18.0675988 },
    { lat: 47.9420819, lng: 18.0683481 },
    { lat: 47.9414584, lng: 18.0690217 },
    { lat: 47.9409496, lng: 18.0699522 },
    { lat: 47.9401824, lng: 18.0706041 },
    { lat: 47.9400531, lng: 18.0710854 },
    { lat: 47.9401207, lng: 18.071879 },
    { lat: 47.940024, lng: 18.0721504 },
    { lat: 47.9399392, lng: 18.0722422 },
    { lat: 47.9395824, lng: 18.072316 },
    { lat: 47.9388803, lng: 18.0722371 },
    { lat: 47.938406, lng: 18.0723099 },
    { lat: 47.9381415, lng: 18.0726066 },
    { lat: 47.9380001, lng: 18.0730722 },
    { lat: 47.9373584, lng: 18.0760184 },
    { lat: 47.9372243, lng: 18.0763451 },
    { lat: 47.9364321, lng: 18.0776386 },
    { lat: 47.935883, lng: 18.0783863 },
    { lat: 47.9354313, lng: 18.0787988 },
    { lat: 47.9340516, lng: 18.0798131 },
    { lat: 47.933786, lng: 18.0792568 },
    { lat: 47.9332738, lng: 18.0789952 },
    { lat: 47.932817, lng: 18.0786018 },
    { lat: 47.9312804, lng: 18.0767117 },
    { lat: 47.9308263, lng: 18.0756061 },
    { lat: 47.9305159, lng: 18.0747134 },
    { lat: 47.9304548, lng: 18.0733814 },
    { lat: 47.9300679, lng: 18.0734053 },
    { lat: 47.9300352, lng: 18.0723986 },
    { lat: 47.9301966, lng: 18.0712052 },
    { lat: 47.9302423, lng: 18.0710282 },
    { lat: 47.9303233, lng: 18.0709467 },
    { lat: 47.9306074, lng: 18.0698043 },
    { lat: 47.9308369, lng: 18.0678598 },
    { lat: 47.9311425, lng: 18.067685 },
    { lat: 47.9311466, lng: 18.0668449 },
    { lat: 47.9309056, lng: 18.0657179 },
    { lat: 47.928714, lng: 18.0623261 },
    { lat: 47.9286703, lng: 18.0622585 },
    { lat: 47.9260627, lng: 18.0584647 },
    { lat: 47.9234155, lng: 18.0549154 },
    { lat: 47.9170354, lng: 18.048321 },
    { lat: 47.910989, lng: 18.0456154 },
    { lat: 47.9092201, lng: 18.0443822 },
    { lat: 47.9042541, lng: 18.0380429 },
    { lat: 47.9037553, lng: 18.0374945 },
    { lat: 47.9034091, lng: 18.0369933 },
    { lat: 47.9034019, lng: 18.0369829 },
    { lat: 47.9032736, lng: 18.0368254 },
    { lat: 47.9014035, lng: 18.0340901 },
    { lat: 47.9005735, lng: 18.03069 },
    { lat: 47.900422, lng: 18.0302335 },
    { lat: 47.8993569, lng: 18.0295253 },
    { lat: 47.8991798, lng: 18.0284519 },
    { lat: 47.8969356, lng: 18.029048 },
    { lat: 47.8962673, lng: 18.0290057 },
    { lat: 47.8956108, lng: 18.0290892 },
    { lat: 47.8924584, lng: 18.0292187 },
    { lat: 47.8910833, lng: 18.0293989 },
    { lat: 47.8905663, lng: 18.0296988 },
    { lat: 47.8901362, lng: 18.0298666 },
    { lat: 47.8879414, lng: 18.0301174 },
    { lat: 47.8867923, lng: 18.0301553 },
    { lat: 47.8846418, lng: 18.0305778 },
    { lat: 47.8841732, lng: 18.0307809 },
    { lat: 47.8833665, lng: 18.0308441 },
    { lat: 47.8829383, lng: 18.0308942 },
    { lat: 47.8814754, lng: 18.0352591 },
    { lat: 47.8795955, lng: 18.0414083 },
    { lat: 47.878952, lng: 18.043195 },
    { lat: 47.8817819, lng: 18.0439767 },
    { lat: 47.8918152, lng: 18.0465582 },
    { lat: 47.8948594, lng: 18.0471871 },
    { lat: 47.8957268, lng: 18.0475374 },
    { lat: 47.8980425, lng: 18.0513304 },
    { lat: 47.8979738, lng: 18.0513733 },
    { lat: 47.8979926, lng: 18.051477 },
    { lat: 47.897476, lng: 18.0518045 },
    { lat: 47.8966578, lng: 18.0531082 },
    { lat: 47.8966207, lng: 18.0535633 },
    { lat: 47.8965472, lng: 18.0539018 },
    { lat: 47.8964726, lng: 18.054034 },
    { lat: 47.8952044, lng: 18.0554946 },
    { lat: 47.8951618, lng: 18.0556114 },
    { lat: 47.8951317, lng: 18.0561657 },
    { lat: 47.8953198, lng: 18.0565948 },
    { lat: 47.8952461, lng: 18.0567589 },
    { lat: 47.8950992, lng: 18.0569837 },
    { lat: 47.8948207, lng: 18.0572153 },
    { lat: 47.8939242, lng: 18.0578101 },
    { lat: 47.8932881, lng: 18.0580094 },
    { lat: 47.8928941, lng: 18.0579865 },
    { lat: 47.8919668, lng: 18.0576335 },
    { lat: 47.8902987, lng: 18.0566142 },
    { lat: 47.8901625, lng: 18.0571287 },
    { lat: 47.889643, lng: 18.0571072 },
    { lat: 47.8883297, lng: 18.056488 },
    { lat: 47.8877485, lng: 18.0563949 },
    { lat: 47.8870057, lng: 18.056147 },
    { lat: 47.882639, lng: 18.0593936 },
    { lat: 47.8821114, lng: 18.0597631 },
    { lat: 47.8829236, lng: 18.0619665 },
    { lat: 47.8832212, lng: 18.0630231 },
    { lat: 47.8818076, lng: 18.063656 },
    { lat: 47.8824912, lng: 18.066183 },
    { lat: 47.8833672, lng: 18.0667186 },
    { lat: 47.8852167, lng: 18.0676056 },
    { lat: 47.8869142, lng: 18.0690801 },
    { lat: 47.8882445, lng: 18.0698545 },
    { lat: 47.889039, lng: 18.0699678 },
    { lat: 47.8899317, lng: 18.070327 },
    { lat: 47.890623, lng: 18.0707524 },
    { lat: 47.8911329, lng: 18.0712057 },
    { lat: 47.8915356, lng: 18.0725454 },
    { lat: 47.8915746, lng: 18.0738086 },
    { lat: 47.8914682, lng: 18.0747491 },
    { lat: 47.8910401, lng: 18.0762785 },
    { lat: 47.890956, lng: 18.0789611 },
    { lat: 47.8904801, lng: 18.0804063 },
    { lat: 47.8904398, lng: 18.0813982 },
    { lat: 47.8905852, lng: 18.0820975 },
    { lat: 47.8913109, lng: 18.0837102 },
    { lat: 47.8919984, lng: 18.0850987 },
    { lat: 47.8920681, lng: 18.0859489 },
    { lat: 47.8918366, lng: 18.0873528 },
    { lat: 47.8944402, lng: 18.0962822 },
    { lat: 47.8947722, lng: 18.0979782 },
    { lat: 47.8972327, lng: 18.1031752 },
    { lat: 47.8978337, lng: 18.104541 },
    { lat: 47.8993709, lng: 18.1077025 },
    { lat: 47.9003008, lng: 18.1094167 },
    { lat: 47.9020948, lng: 18.1146729 },
    { lat: 47.9044215, lng: 18.122297 },
    { lat: 47.9050244, lng: 18.1244247 },
    { lat: 47.9014437, lng: 18.126983 },
    { lat: 47.9028772, lng: 18.1323762 },
    { lat: 47.9032193, lng: 18.1320541 },
    { lat: 47.9034007, lng: 18.1320304 },
    { lat: 47.9035238, lng: 18.1321089 },
    { lat: 47.9038246, lng: 18.1325427 },
    { lat: 47.9040154, lng: 18.1322575 },
    { lat: 47.904104, lng: 18.132208 },
    { lat: 47.9042603, lng: 18.1322962 },
    { lat: 47.90468, lng: 18.1321029 },
    { lat: 47.9047826, lng: 18.1321373 },
    { lat: 47.9055702, lng: 18.1315435 },
    { lat: 47.9059715, lng: 18.1314236 },
    { lat: 47.9070054, lng: 18.130487 },
    { lat: 47.9073691, lng: 18.1300136 },
    { lat: 47.9076989, lng: 18.1309371 },
    { lat: 47.9084021, lng: 18.1300634 },
    { lat: 47.9101688, lng: 18.1317731 },
    { lat: 47.9107899, lng: 18.1310339 },
    { lat: 47.9118768, lng: 18.1331583 },
    { lat: 47.9129957, lng: 18.1350601 },
    { lat: 47.9120936, lng: 18.1361075 },
    { lat: 47.9123035, lng: 18.1361666 },
    { lat: 47.9116299, lng: 18.137315 },
    { lat: 47.9116888, lng: 18.1375845 },
    { lat: 47.9107483, lng: 18.1391883 },
    { lat: 47.9103947, lng: 18.1386604 },
    { lat: 47.9093554, lng: 18.138758 },
    { lat: 47.9095931, lng: 18.1392518 },
    { lat: 47.9101624, lng: 18.141252 },
    { lat: 47.9113117, lng: 18.1432871 },
    { lat: 47.9123899, lng: 18.14546 },
    { lat: 47.9138007, lng: 18.1490666 },
    { lat: 47.9143775, lng: 18.1508916 },
    { lat: 47.9153627, lng: 18.1535968 },
    { lat: 47.9158302, lng: 18.1561347 },
    { lat: 47.9161518, lng: 18.1573047 },
    { lat: 47.9171172, lng: 18.1590255 },
    { lat: 47.9188899, lng: 18.1619613 },
    { lat: 47.9193763, lng: 18.1629791 },
    { lat: 47.9225703, lng: 18.1672728 },
    { lat: 47.9237856, lng: 18.1691361 },
    { lat: 47.9246015, lng: 18.1699639 },
    { lat: 47.9245348, lng: 18.1714065 },
    { lat: 47.9245964, lng: 18.1730707 },
    { lat: 47.9246522, lng: 18.1747156 },
    { lat: 47.9272786, lng: 18.1761311 },
    { lat: 47.9290088, lng: 18.174654 },
    { lat: 47.9322646, lng: 18.1710946 },
    { lat: 47.9325556, lng: 18.1714778 },
    { lat: 47.9342726, lng: 18.173739 },
    { lat: 47.9361986, lng: 18.1757963 },
    { lat: 47.9388955, lng: 18.1729843 },
    { lat: 47.94393, lng: 18.167069 },
    { lat: 47.944001, lng: 18.166981 },
    { lat: 47.94509, lng: 18.1656 },
    { lat: 47.945378, lng: 18.165236 },
    { lat: 47.945489, lng: 18.165095 },
    { lat: 47.948602, lng: 18.160205 },
    { lat: 47.949221, lng: 18.159267 },
    { lat: 47.949846, lng: 18.1582 },
    { lat: 47.949953, lng: 18.158018 },
    { lat: 47.950419, lng: 18.157193 },
    { lat: 47.95255, lng: 18.153426 },
    { lat: 47.952557, lng: 18.153414 },
    { lat: 47.95313, lng: 18.152399 },
    { lat: 47.953178, lng: 18.152314 },
    { lat: 47.953236, lng: 18.152214 },
    { lat: 47.953272, lng: 18.152148 },
    { lat: 47.95417, lng: 18.153293 },
    { lat: 47.954342, lng: 18.153514 },
    { lat: 47.954412, lng: 18.153602 },
    { lat: 47.954471, lng: 18.153584 },
    { lat: 47.954613, lng: 18.153542 },
    { lat: 47.955035, lng: 18.153418 },
    { lat: 47.956004, lng: 18.153133 },
    { lat: 47.956419, lng: 18.15301 },
    { lat: 47.956784, lng: 18.152907 },
    { lat: 47.957087, lng: 18.152817 },
    { lat: 47.958163, lng: 18.152501 },
    { lat: 47.958235, lng: 18.15248 },
    { lat: 47.958239, lng: 18.152479 },
    { lat: 47.95888, lng: 18.152199 },
    { lat: 47.958959, lng: 18.152159 },
    { lat: 47.959916, lng: 18.151676 },
    { lat: 47.961808, lng: 18.150516 },
    { lat: 47.964297, lng: 18.149801 },
    { lat: 47.964505, lng: 18.149808 },
    { lat: 47.964573, lng: 18.149811 },
    { lat: 47.964609, lng: 18.149812 },
    { lat: 47.964738, lng: 18.149816 },
    { lat: 47.964765, lng: 18.149817 },
    { lat: 47.964838, lng: 18.14971 },
    { lat: 47.965238, lng: 18.149113 },
    { lat: 47.965836, lng: 18.14815 },
    { lat: 47.965908, lng: 18.148034 },
    { lat: 47.967135, lng: 18.146082 },
    { lat: 47.967193, lng: 18.145955 },
    { lat: 47.967266, lng: 18.145796 },
    { lat: 47.967439, lng: 18.145422 },
    { lat: 47.967503, lng: 18.145282 },
    { lat: 47.967527, lng: 18.14523 },
    { lat: 47.967547, lng: 18.145186 },
    { lat: 47.96757, lng: 18.145136 },
    { lat: 47.967622, lng: 18.145025 },
    { lat: 47.967859, lng: 18.14451 },
    { lat: 47.968044, lng: 18.144108 },
    { lat: 47.968279, lng: 18.143616 },
    { lat: 47.968282, lng: 18.141513 },
    { lat: 47.96898, lng: 18.140664 },
    { lat: 47.970415, lng: 18.13892 },
    { lat: 47.97144, lng: 18.136735 },
    { lat: 47.971954, lng: 18.135639 },
    { lat: 47.97226, lng: 18.135209 },
    { lat: 47.972616, lng: 18.134709 },
    { lat: 47.972669, lng: 18.134635 },
    { lat: 47.973081, lng: 18.134055 },
    { lat: 47.972633, lng: 18.133028 },
    { lat: 47.972262, lng: 18.13217 },
    { lat: 47.972209, lng: 18.132045 },
    { lat: 47.971625, lng: 18.130685 },
    { lat: 47.971501, lng: 18.130453 },
    { lat: 47.970721, lng: 18.128993 },
    { lat: 47.970098, lng: 18.12787 },
    { lat: 47.970153, lng: 18.127688 },
    { lat: 47.970349, lng: 18.127033 },
    { lat: 47.970386, lng: 18.12691 },
    { lat: 47.970496, lng: 18.12654 },
    { lat: 47.970736, lng: 18.12574 },
    { lat: 47.97109, lng: 18.125028 },
    { lat: 47.971236, lng: 18.124734 },
    { lat: 47.97186, lng: 18.123465 },
    { lat: 47.972021, lng: 18.123082 },
    { lat: 47.97202, lng: 18.122876 },
    { lat: 47.971751, lng: 18.122886 },
    { lat: 47.971664, lng: 18.122761 },
    { lat: 47.97163, lng: 18.122655 },
    { lat: 47.971646, lng: 18.12254 },
    { lat: 47.971688, lng: 18.122394 },
    { lat: 47.971881, lng: 18.122041 },
    { lat: 47.972029, lng: 18.121662 },
    { lat: 47.972031, lng: 18.121456 },
    { lat: 47.971997, lng: 18.121318 },
    { lat: 47.971911, lng: 18.121137 },
    { lat: 47.971708, lng: 18.120945 },
    { lat: 47.971677, lng: 18.120896 },
    { lat: 47.971205, lng: 18.120654 },
    { lat: 47.971144, lng: 18.120585 },
    { lat: 47.971151, lng: 18.120436 },
    { lat: 47.971217, lng: 18.12017 },
    { lat: 47.971625, lng: 18.119704 },
    { lat: 47.97174, lng: 18.119511 },
    { lat: 47.971864, lng: 18.119067 },
    { lat: 47.971822, lng: 18.118558 },
    { lat: 47.971733, lng: 18.11832 },
    { lat: 47.971724, lng: 18.117977 },
    { lat: 47.971806, lng: 18.117826 },
    { lat: 47.972012, lng: 18.117795 },
    { lat: 47.972072, lng: 18.117847 },
    { lat: 47.972213, lng: 18.118177 },
    { lat: 47.972293, lng: 18.118493 },
    { lat: 47.972393, lng: 18.118763 },
    { lat: 47.972655, lng: 18.11922 },
    { lat: 47.972731, lng: 18.119636 },
    { lat: 47.972622, lng: 18.120238 },
    { lat: 47.972704, lng: 18.120392 },
    { lat: 47.972777, lng: 18.120428 },
    { lat: 47.972885, lng: 18.120416 },
    { lat: 47.973038, lng: 18.120304 },
    { lat: 47.973105, lng: 18.120068 },
    { lat: 47.973163, lng: 18.119812 },
    { lat: 47.973169, lng: 18.119751 },
    { lat: 47.973201, lng: 18.119424 },
    { lat: 47.97326, lng: 18.118834 },
    { lat: 47.973264, lng: 18.118509 },
    { lat: 47.973221, lng: 18.118121 },
    { lat: 47.973188, lng: 18.117738 },
    { lat: 47.973201, lng: 18.117494 },
    { lat: 47.973212, lng: 18.117282 },
    { lat: 47.973245, lng: 18.117188 },
    { lat: 47.973414, lng: 18.117023 },
    { lat: 47.973484, lng: 18.117012 },
    { lat: 47.97354, lng: 18.117004 },
    { lat: 47.973588, lng: 18.116997 },
    { lat: 47.973674, lng: 18.116983 },
    { lat: 47.973763, lng: 18.117008 },
    { lat: 47.973868, lng: 18.117037 },
    { lat: 47.973875, lng: 18.11705 },
    { lat: 47.973935, lng: 18.117158 },
    { lat: 47.973995, lng: 18.117269 },
    { lat: 47.974043, lng: 18.117355 },
    { lat: 47.974048, lng: 18.117378 },
    { lat: 47.974074, lng: 18.11751 },
    { lat: 47.9741, lng: 18.117638 },
    { lat: 47.974125, lng: 18.117766 },
    { lat: 47.97415, lng: 18.117891 },
    { lat: 47.97415, lng: 18.117895 },
    { lat: 47.974153, lng: 18.117906 },
    { lat: 47.974152, lng: 18.118027 },
    { lat: 47.974152, lng: 18.118041 },
    { lat: 47.974151, lng: 18.118186 },
    { lat: 47.974151, lng: 18.11833 },
    { lat: 47.97415, lng: 18.118475 },
    { lat: 47.97415, lng: 18.118563 },
    { lat: 47.974185, lng: 18.118601 },
    { lat: 47.974259, lng: 18.118681 },
    { lat: 47.974276, lng: 18.118689 },
    { lat: 47.974315, lng: 18.118707 },
    { lat: 47.974339, lng: 18.118707 },
    { lat: 47.974395, lng: 18.118706 },
    { lat: 47.9744, lng: 18.118706 },
    { lat: 47.974409, lng: 18.118706 },
    { lat: 47.974459, lng: 18.118705 },
    { lat: 47.974496, lng: 18.11867 },
    { lat: 47.974505, lng: 18.118678 },
    { lat: 47.974519, lng: 18.118689 },
    { lat: 47.974597, lng: 18.118584 },
    { lat: 47.974634, lng: 18.118494 },
    { lat: 47.974642, lng: 18.118392 },
    { lat: 47.974641, lng: 18.118362 },
    { lat: 47.97464, lng: 18.118301 },
    { lat: 47.974635, lng: 18.118028 },
    { lat: 47.974633, lng: 18.117959 },
    { lat: 47.974632, lng: 18.117946 },
    { lat: 47.974632, lng: 18.117882 },
    { lat: 47.974632, lng: 18.117877 },
    { lat: 47.974639, lng: 18.117686 },
    { lat: 47.974641, lng: 18.117655 },
    { lat: 47.974683, lng: 18.117519 },
    { lat: 47.974732, lng: 18.117364 },
    { lat: 47.974745, lng: 18.117319 },
    { lat: 47.974804, lng: 18.117137 },
    { lat: 47.974816, lng: 18.117058 },
    { lat: 47.974859, lng: 18.116778 },
    { lat: 47.974818, lng: 18.116654 },
    { lat: 47.974801, lng: 18.116604 },
    { lat: 47.974745, lng: 18.116511 },
    { lat: 47.974732, lng: 18.11649 },
    { lat: 47.97467, lng: 18.116439 },
    { lat: 47.974608, lng: 18.116437 },
    { lat: 47.974586, lng: 18.11644 },
    { lat: 47.974471, lng: 18.116455 },
    { lat: 47.974319, lng: 18.1165 },
    { lat: 47.974308, lng: 18.116503 },
    { lat: 47.974274, lng: 18.11651 },
    { lat: 47.974264, lng: 18.116511 },
    { lat: 47.974227, lng: 18.116518 },
    { lat: 47.974131, lng: 18.116535 },
    { lat: 47.973984, lng: 18.116527 },
    { lat: 47.97398, lng: 18.116527 },
    { lat: 47.97395, lng: 18.116503 },
    { lat: 47.973907, lng: 18.116469 },
    { lat: 47.973897, lng: 18.11646 },
    { lat: 47.973894, lng: 18.116457 },
    { lat: 47.97381, lng: 18.116392 },
    { lat: 47.973784, lng: 18.116372 },
    { lat: 47.973749, lng: 18.116343 },
    { lat: 47.973707, lng: 18.11631 },
    { lat: 47.9737, lng: 18.116297 },
    { lat: 47.973687, lng: 18.116277 },
    { lat: 47.973661, lng: 18.116231 },
    { lat: 47.973648, lng: 18.116209 },
    { lat: 47.973641, lng: 18.116197 },
    { lat: 47.973605, lng: 18.116135 },
    { lat: 47.973583, lng: 18.116098 },
    { lat: 47.9735, lng: 18.115956 },
    { lat: 47.973517, lng: 18.115766 },
    { lat: 47.973533, lng: 18.115594 },
    { lat: 47.973549, lng: 18.115419 },
    { lat: 47.973554, lng: 18.115368 },
    { lat: 47.973604, lng: 18.115228 },
    { lat: 47.973674, lng: 18.115037 },
    { lat: 47.97369, lng: 18.114992 },
    { lat: 47.973703, lng: 18.114957 },
    { lat: 47.974052, lng: 18.114586 },
    { lat: 47.974295, lng: 18.114535 },
    { lat: 47.974703, lng: 18.114479 },
    { lat: 47.974804, lng: 18.114452 },
    { lat: 47.974865, lng: 18.114436 },
    { lat: 47.974884, lng: 18.114328 },
    { lat: 47.9749, lng: 18.114249 },
    { lat: 47.974873, lng: 18.114137 },
    { lat: 47.974715, lng: 18.113799 },
    { lat: 47.974636, lng: 18.113725 },
    { lat: 47.974607, lng: 18.113742 },
    { lat: 47.974483, lng: 18.113714 },
    { lat: 47.974357, lng: 18.113646 },
    { lat: 47.974308, lng: 18.113475 },
    { lat: 47.974277, lng: 18.113366 },
    { lat: 47.974246, lng: 18.113273 },
    { lat: 47.974237, lng: 18.113243 },
    { lat: 47.974226, lng: 18.113206 },
    { lat: 47.974222, lng: 18.11319 },
    { lat: 47.97421, lng: 18.113152 },
    { lat: 47.974205, lng: 18.113134 },
    { lat: 47.974188, lng: 18.113077 },
    { lat: 47.974169, lng: 18.113015 },
    { lat: 47.974138, lng: 18.112912 },
    { lat: 47.974111, lng: 18.112835 },
    { lat: 47.974053, lng: 18.112671 },
    { lat: 47.974019, lng: 18.112573 },
    { lat: 47.973991, lng: 18.112524 },
    { lat: 47.973935, lng: 18.112421 },
    { lat: 47.973907, lng: 18.112377 },
    { lat: 47.973862, lng: 18.112305 },
    { lat: 47.973839, lng: 18.112282 },
    { lat: 47.973798, lng: 18.112237 },
    { lat: 47.973768, lng: 18.112203 },
    { lat: 47.973716, lng: 18.112142 },
    { lat: 47.973609, lng: 18.112017 },
    { lat: 47.973452, lng: 18.111833 },
    { lat: 47.973417, lng: 18.111793 },
    { lat: 47.973281, lng: 18.111669 },
    { lat: 47.973166, lng: 18.111566 },
    { lat: 47.972924, lng: 18.111568 },
    { lat: 47.972919, lng: 18.111571 },
    { lat: 47.972911, lng: 18.111578 },
    { lat: 47.972742, lng: 18.111569 },
    { lat: 47.972655, lng: 18.111569 },
    { lat: 47.972576, lng: 18.111558 },
    { lat: 47.972531, lng: 18.111553 },
    { lat: 47.972479, lng: 18.111546 },
    { lat: 47.972448, lng: 18.111541 },
    { lat: 47.97239, lng: 18.111534 },
    { lat: 47.972017, lng: 18.11107 },
    { lat: 47.971983, lng: 18.111027 },
    { lat: 47.971514, lng: 18.110445 },
    { lat: 47.97143, lng: 18.110339 },
    { lat: 47.971399, lng: 18.110326 },
    { lat: 47.970649, lng: 18.11002 },
    { lat: 47.970554, lng: 18.10998 },
    { lat: 47.970534, lng: 18.109972 },
    { lat: 47.970423, lng: 18.109926 },
    { lat: 47.970366, lng: 18.109903 },
    { lat: 47.970248, lng: 18.109856 },
    { lat: 47.970198, lng: 18.109835 },
    { lat: 47.969168, lng: 18.109413 },
    { lat: 47.969246, lng: 18.109251 },
    { lat: 47.969329, lng: 18.109082 },
    { lat: 47.969181, lng: 18.109014 },
    { lat: 47.968821, lng: 18.108946 },
    { lat: 47.966714, lng: 18.108329 },
    { lat: 47.966767, lng: 18.108218 },
    { lat: 47.966792, lng: 18.108167 },
    { lat: 47.966827, lng: 18.108095 },
    { lat: 47.966929, lng: 18.107884 },
    { lat: 47.966987, lng: 18.107763 },
    { lat: 47.967055, lng: 18.10762 },
    { lat: 47.96719, lng: 18.107341 },
    { lat: 47.96719, lng: 18.10734 },
    { lat: 47.967228, lng: 18.107261 },
    { lat: 47.967229, lng: 18.107259 },
    { lat: 47.967417, lng: 18.106865 },
    { lat: 47.967419, lng: 18.106862 },
    { lat: 47.967449, lng: 18.1068 },
    { lat: 47.967487, lng: 18.106743 },
    { lat: 47.967602, lng: 18.106621 },
    { lat: 47.967604, lng: 18.10662 },
    { lat: 47.967664, lng: 18.106596 },
    { lat: 47.967665, lng: 18.106596 },
    { lat: 47.967828, lng: 18.106541 },
    { lat: 47.967824, lng: 18.106537 },
    { lat: 47.967605, lng: 18.10623 },
    { lat: 47.967585, lng: 18.106202 },
    { lat: 47.967574, lng: 18.106123 },
    { lat: 47.967639, lng: 18.105732 },
    { lat: 47.967617, lng: 18.105577 },
    { lat: 47.967523, lng: 18.105413 },
    { lat: 47.967451, lng: 18.105444 },
    { lat: 47.96708, lng: 18.10581 },
    { lat: 47.96683, lng: 18.105844 },
    { lat: 47.966584, lng: 18.105737 },
    { lat: 47.966377, lng: 18.105504 },
    { lat: 47.966321, lng: 18.105354 },
    { lat: 47.966309, lng: 18.105281 },
    { lat: 47.966299, lng: 18.105222 },
    { lat: 47.966289, lng: 18.105154 },
    { lat: 47.966275, lng: 18.105068 },
    { lat: 47.966266, lng: 18.105009 },
    { lat: 47.966323, lng: 18.104734 },
    { lat: 47.966844, lng: 18.103819 },
    { lat: 47.966926, lng: 18.103522 },
    { lat: 47.966941, lng: 18.103201 },
    { lat: 47.966873, lng: 18.102809 },
    { lat: 47.966783, lng: 18.102771 },
    { lat: 47.966566, lng: 18.10279 },
    { lat: 47.966372, lng: 18.103054 },
    { lat: 47.965779, lng: 18.103486 },
    { lat: 47.965472, lng: 18.10362 },
    { lat: 47.965268, lng: 18.103619 },
    { lat: 47.96502, lng: 18.103539 },
    { lat: 47.964552, lng: 18.10313 },
    { lat: 47.964396, lng: 18.103065 },
    { lat: 47.964206, lng: 18.102783 },
    { lat: 47.96412, lng: 18.102466 },
    { lat: 47.964189, lng: 18.101695 },
    { lat: 47.964013, lng: 18.100868 },
    { lat: 47.964004, lng: 18.100464 },
    { lat: 47.964144, lng: 18.099728 },
    { lat: 47.964099, lng: 18.099581 },
    { lat: 47.964189, lng: 18.098981 },
    { lat: 47.964212, lng: 18.098918 },
    { lat: 47.96456, lng: 18.098617 },
    { lat: 47.964978, lng: 18.098381 },
    { lat: 47.965038, lng: 18.098254 },
    { lat: 47.965077, lng: 18.098103 },
    { lat: 47.965083, lng: 18.097926 },
    { lat: 47.965033, lng: 18.097709 },
    { lat: 47.964593, lng: 18.096998 },
    { lat: 47.964161, lng: 18.096442 },
    { lat: 47.964044, lng: 18.096321 },
    { lat: 47.963723, lng: 18.096104 },
    { lat: 47.963368, lng: 18.096005 },
    { lat: 47.963155, lng: 18.095711 },
    { lat: 47.96323, lng: 18.095261 },
    { lat: 47.963528, lng: 18.094603 },
    { lat: 47.963613, lng: 18.094333 },
    { lat: 47.96364, lng: 18.094128 },
    { lat: 47.963647, lng: 18.093985 },
    { lat: 47.963617, lng: 18.093801 },
    { lat: 47.963581, lng: 18.093729 },
    { lat: 47.963497, lng: 18.093651 },
    { lat: 47.962827, lng: 18.093602 },
    { lat: 47.962671, lng: 18.093488 },
    { lat: 47.962502, lng: 18.093318 },
    { lat: 47.962314, lng: 18.092926 },
    { lat: 47.96215, lng: 18.092461 },
    { lat: 47.962096, lng: 18.092443 },
    { lat: 47.962031, lng: 18.092479 },
    { lat: 47.961993, lng: 18.092517 },
    { lat: 47.961911, lng: 18.092664 },
    { lat: 47.961841, lng: 18.093022 },
    { lat: 47.961804, lng: 18.093274 },
    { lat: 47.961744, lng: 18.093405 },
    { lat: 47.961548, lng: 18.09358 },
    { lat: 47.96122, lng: 18.093834 },
    { lat: 47.961033, lng: 18.093928 },
    { lat: 47.960918, lng: 18.093927 },
    { lat: 47.960789, lng: 18.093904 },
    { lat: 47.960669, lng: 18.093831 },
    { lat: 47.960567, lng: 18.093725 },
    { lat: 47.960473, lng: 18.093521 },
    { lat: 47.96044, lng: 18.093394 },
    { lat: 47.960413, lng: 18.093164 },
    { lat: 47.960425, lng: 18.093002 },
    { lat: 47.960467, lng: 18.092816 },
    { lat: 47.960674, lng: 18.092488 },
    { lat: 47.960754, lng: 18.092331 },
    { lat: 47.960754, lng: 18.092136 },
    { lat: 47.960686, lng: 18.091978 },
    { lat: 47.960569, lng: 18.091836 },
    { lat: 47.960459, lng: 18.09175 },
    { lat: 47.960334, lng: 18.091703 },
    { lat: 47.960073, lng: 18.091804 },
    { lat: 47.95996, lng: 18.09201 },
    { lat: 47.959836, lng: 18.092316 },
    { lat: 47.95971, lng: 18.0926 },
    { lat: 47.959243, lng: 18.093041 },
    { lat: 47.959153, lng: 18.093345 },
    { lat: 47.959235, lng: 18.093419 },
    { lat: 47.959268, lng: 18.093448 },
    { lat: 47.959397, lng: 18.093563 },
    { lat: 47.959817, lng: 18.093656 },
    { lat: 47.960035, lng: 18.09398 },
    { lat: 47.959985, lng: 18.094267 },
    { lat: 47.959904, lng: 18.094381 },
    { lat: 47.959754, lng: 18.094496 },
    { lat: 47.959649, lng: 18.094611 },
    { lat: 47.959482, lng: 18.094521 },
    { lat: 47.959395, lng: 18.094437 },
    { lat: 47.95922, lng: 18.094181 },
    { lat: 47.959056, lng: 18.094123 },
    { lat: 47.95902, lng: 18.093821 },
    { lat: 47.958764, lng: 18.093838 },
    { lat: 47.95865, lng: 18.093671 },
    { lat: 47.958481, lng: 18.09351 },
    { lat: 47.958397, lng: 18.093393 },
    { lat: 47.958246, lng: 18.093177 },
    { lat: 47.958112, lng: 18.093057 },
    { lat: 47.957971, lng: 18.092992 },
    { lat: 47.95786, lng: 18.09297 },
    { lat: 47.957748, lng: 18.09302 },
    { lat: 47.957598, lng: 18.093075 },
    { lat: 47.95748, lng: 18.093212 },
    { lat: 47.957413, lng: 18.093413 },
    { lat: 47.957368, lng: 18.093631 },
    { lat: 47.957366, lng: 18.093922 },
    { lat: 47.957309, lng: 18.094275 },
    { lat: 47.957226, lng: 18.094641 },
    { lat: 47.957149, lng: 18.094851 },
    { lat: 47.956674, lng: 18.095598 },
    { lat: 47.956617, lng: 18.095439 },
    { lat: 47.956552, lng: 18.095273 },
    { lat: 47.956472, lng: 18.095062 },
    { lat: 47.955942, lng: 18.095302 },
    { lat: 47.955425, lng: 18.093224 },
    { lat: 47.955102, lng: 18.091888 },
    { lat: 47.9551, lng: 18.091881 },
    { lat: 47.95555, lng: 18.090862 },
    { lat: 47.954899, lng: 18.089725 },
    { lat: 47.954337, lng: 18.088793 },
    { lat: 47.954028, lng: 18.088282 },
    { lat: 47.953968, lng: 18.088181 },
    { lat: 47.953459, lng: 18.087218 },
    { lat: 47.951822, lng: 18.08642 },
    { lat: 47.950921, lng: 18.085989 },
    { lat: 47.950012, lng: 18.085551 },
    { lat: 47.948989, lng: 18.085022 },
    { lat: 47.948527, lng: 18.084814 },
    { lat: 47.948476, lng: 18.084791 },
    { lat: 47.949126, lng: 18.084017 },
    { lat: 47.94941, lng: 18.083747 },
    { lat: 47.949981, lng: 18.083205 },
    { lat: 47.950153, lng: 18.083047 },
    { lat: 47.950206, lng: 18.082998 },
    { lat: 47.951098, lng: 18.082182 },
    { lat: 47.952458, lng: 18.080871 },
    { lat: 47.953069, lng: 18.07981 },
    { lat: 47.95319, lng: 18.079549 },
    { lat: 47.953267, lng: 18.078899 },
    { lat: 47.953304, lng: 18.078342 },
    { lat: 47.953263, lng: 18.077669 },
    { lat: 47.953571, lng: 18.07706 },
    { lat: 47.953596, lng: 18.076665 },
    { lat: 47.953294, lng: 18.075545 },
    { lat: 47.953036, lng: 18.075086 },
    { lat: 47.952673, lng: 18.07493 },
    { lat: 47.951845, lng: 18.074785 },
    { lat: 47.951402, lng: 18.074192 },
    { lat: 47.95122, lng: 18.074043 },
    { lat: 47.95104, lng: 18.073895 },
    { lat: 47.950609, lng: 18.073421 },
    { lat: 47.950361, lng: 18.072809 },
    { lat: 47.950313, lng: 18.072672 },
    { lat: 47.950298, lng: 18.072632 },
    { lat: 47.950285, lng: 18.072598 },
    { lat: 47.95026, lng: 18.072524 },
    { lat: 47.950225, lng: 18.072426 },
    { lat: 47.950206, lng: 18.072375 },
    { lat: 47.950069, lng: 18.072362 },
    { lat: 47.950035, lng: 18.072359 },
    { lat: 47.949982, lng: 18.072355 },
    { lat: 47.949844, lng: 18.072345 },
    { lat: 47.949351, lng: 18.072336 },
    { lat: 47.949038, lng: 18.07233 },
    { lat: 47.948489, lng: 18.072189 },
    { lat: 47.947767, lng: 18.070955 },
    { lat: 47.947633, lng: 18.070519 },
    { lat: 47.947548, lng: 18.070234 },
  ],
  Patince: [
    { lat: 47.7444642, lng: 18.327462 },
    { lat: 47.7444869, lng: 18.3274245 },
    { lat: 47.7448242, lng: 18.3269339 },
    { lat: 47.7448478, lng: 18.3267525 },
    { lat: 47.7451316, lng: 18.3260173 },
    { lat: 47.7454129, lng: 18.3254932 },
    { lat: 47.74567, lng: 18.324862 },
    { lat: 47.7459775, lng: 18.3238742 },
    { lat: 47.7463062, lng: 18.3233104 },
    { lat: 47.7468075, lng: 18.3226308 },
    { lat: 47.7482239, lng: 18.3210474 },
    { lat: 47.7477437, lng: 18.3202325 },
    { lat: 47.7471872, lng: 18.3209724 },
    { lat: 47.7468705, lng: 18.3205266 },
    { lat: 47.7468328, lng: 18.3204017 },
    { lat: 47.7468502, lng: 18.3202515 },
    { lat: 47.7469196, lng: 18.3201274 },
    { lat: 47.7469939, lng: 18.320144 },
    { lat: 47.7474264, lng: 18.3171096 },
    { lat: 47.7474382, lng: 18.3164709 },
    { lat: 47.7475217, lng: 18.315823 },
    { lat: 47.7476963, lng: 18.3151513 },
    { lat: 47.7487365, lng: 18.3136844 },
    { lat: 47.7504748, lng: 18.3119328 },
    { lat: 47.7511944, lng: 18.3099689 },
    { lat: 47.7516119, lng: 18.308405 },
    { lat: 47.7521378, lng: 18.3060193 },
    { lat: 47.7513826, lng: 18.3015284 },
    { lat: 47.7519326, lng: 18.3005411 },
    { lat: 47.7523282, lng: 18.3002796 },
    { lat: 47.7532139, lng: 18.3003166 },
    { lat: 47.7539235, lng: 18.2997683 },
    { lat: 47.7541181, lng: 18.2995445 },
    { lat: 47.7537814, lng: 18.2974833 },
    { lat: 47.754429, lng: 18.2954671 },
    { lat: 47.7558724, lng: 18.2937727 },
    { lat: 47.7569173, lng: 18.2931516 },
    { lat: 47.7582991, lng: 18.2933212 },
    { lat: 47.759106, lng: 18.2933035 },
    { lat: 47.7595269, lng: 18.2916428 },
    { lat: 47.7589941, lng: 18.2903303 },
    { lat: 47.7581828, lng: 18.2890039 },
    { lat: 47.7575166, lng: 18.2873154 },
    { lat: 47.7572798, lng: 18.2857947 },
    { lat: 47.7572795, lng: 18.2851233 },
    { lat: 47.7577311, lng: 18.2832556 },
    { lat: 47.7582855, lng: 18.2806104 },
    { lat: 47.7559009, lng: 18.2789437 },
    { lat: 47.758732, lng: 18.2697335 },
    { lat: 47.755314, lng: 18.267042 },
    { lat: 47.754196, lng: 18.2662284 },
    { lat: 47.7526674, lng: 18.264947 },
    { lat: 47.7506132, lng: 18.2635938 },
    { lat: 47.743376, lng: 18.2585963 },
    { lat: 47.7410048, lng: 18.2568585 },
    { lat: 47.7390419, lng: 18.2557777 },
    { lat: 47.7389367, lng: 18.2565636 },
    { lat: 47.7388604, lng: 18.2572626 },
    { lat: 47.7387111, lng: 18.258183 },
    { lat: 47.7385072, lng: 18.2590801 },
    { lat: 47.7383017, lng: 18.2601255 },
    { lat: 47.7381691, lng: 18.2611966 },
    { lat: 47.7380232, lng: 18.2624023 },
    { lat: 47.7378136, lng: 18.2636116 },
    { lat: 47.737591, lng: 18.2644726 },
    { lat: 47.7372805, lng: 18.2654987 },
    { lat: 47.736899, lng: 18.2664705 },
    { lat: 47.7365691, lng: 18.2671566 },
    { lat: 47.7359688, lng: 18.2684915 },
    { lat: 47.7354093, lng: 18.269865 },
    { lat: 47.7348919, lng: 18.271274 },
    { lat: 47.7336633, lng: 18.274462 },
    { lat: 47.7332828, lng: 18.2755583 },
    { lat: 47.7329902, lng: 18.2767124 },
    { lat: 47.7328558, lng: 18.2774556 },
    { lat: 47.732671, lng: 18.2782881 },
    { lat: 47.7324775, lng: 18.2790034 },
    { lat: 47.7322007, lng: 18.2803227 },
    { lat: 47.7320413, lng: 18.2816838 },
    { lat: 47.732002, lng: 18.2830641 },
    { lat: 47.7319418, lng: 18.284193 },
    { lat: 47.7318012, lng: 18.2853062 },
    { lat: 47.7315818, lng: 18.2863909 },
    { lat: 47.731354, lng: 18.2876032 },
    { lat: 47.7312195, lng: 18.2888456 },
    { lat: 47.7311798, lng: 18.2901028 },
    { lat: 47.7312354, lng: 18.2913584 },
    { lat: 47.7314485, lng: 18.293618 },
    { lat: 47.7314716, lng: 18.2938631 },
    { lat: 47.7316573, lng: 18.2958638 },
    { lat: 47.7318906, lng: 18.2978535 },
    { lat: 47.7321896, lng: 18.3001934 },
    { lat: 47.7324494, lng: 18.3025438 },
    { lat: 47.7326333, lng: 18.3038245 },
    { lat: 47.7328806, lng: 18.3050813 },
    { lat: 47.7336841, lng: 18.3090656 },
    { lat: 47.733892, lng: 18.3102022 },
    { lat: 47.73405, lng: 18.3113562 },
    { lat: 47.7343082, lng: 18.3138605 },
    { lat: 47.7345494, lng: 18.3155861 },
    { lat: 47.7348255, lng: 18.3173005 },
    { lat: 47.7350542, lng: 18.3186151 },
    { lat: 47.7351966, lng: 18.3199563 },
    { lat: 47.7352516, lng: 18.3213116 },
    { lat: 47.7353241, lng: 18.3224215 },
    { lat: 47.7354595, lng: 18.3235185 },
    { lat: 47.7356568, lng: 18.3245945 },
    { lat: 47.7358835, lng: 18.3255563 },
    { lat: 47.7361711, lng: 18.3264816 },
    { lat: 47.7365911, lng: 18.3278257 },
    { lat: 47.7368815, lng: 18.329243 },
    { lat: 47.7370366, lng: 18.3307065 },
    { lat: 47.737164, lng: 18.3324413 },
    { lat: 47.7373688, lng: 18.3341598 },
    { lat: 47.7375888, lng: 18.3354852 },
    { lat: 47.7414119, lng: 18.333643 },
    { lat: 47.741471, lng: 18.3336889 },
    { lat: 47.7419218, lng: 18.3320898 },
    { lat: 47.7422923, lng: 18.3311149 },
    { lat: 47.7430617, lng: 18.32958 },
    { lat: 47.7435223, lng: 18.3288986 },
    { lat: 47.7438648, lng: 18.3284978 },
    { lat: 47.7444003, lng: 18.3275679 },
    { lat: 47.7444642, lng: 18.327462 },
  ],
  Imeľ: [
    { lat: 47.9246522, lng: 18.1747156 },
    { lat: 47.9245964, lng: 18.1730707 },
    { lat: 47.9245348, lng: 18.1714065 },
    { lat: 47.9246015, lng: 18.1699639 },
    { lat: 47.9237856, lng: 18.1691361 },
    { lat: 47.9225703, lng: 18.1672728 },
    { lat: 47.9193763, lng: 18.1629791 },
    { lat: 47.9188899, lng: 18.1619613 },
    { lat: 47.9171172, lng: 18.1590255 },
    { lat: 47.9161518, lng: 18.1573047 },
    { lat: 47.9158302, lng: 18.1561347 },
    { lat: 47.9153627, lng: 18.1535968 },
    { lat: 47.9143775, lng: 18.1508916 },
    { lat: 47.9138007, lng: 18.1490666 },
    { lat: 47.9123899, lng: 18.14546 },
    { lat: 47.9113117, lng: 18.1432871 },
    { lat: 47.9101624, lng: 18.141252 },
    { lat: 47.9095931, lng: 18.1392518 },
    { lat: 47.9093554, lng: 18.138758 },
    { lat: 47.9103947, lng: 18.1386604 },
    { lat: 47.9107483, lng: 18.1391883 },
    { lat: 47.9116888, lng: 18.1375845 },
    { lat: 47.9116299, lng: 18.137315 },
    { lat: 47.9123035, lng: 18.1361666 },
    { lat: 47.9120936, lng: 18.1361075 },
    { lat: 47.9129957, lng: 18.1350601 },
    { lat: 47.9118768, lng: 18.1331583 },
    { lat: 47.9107899, lng: 18.1310339 },
    { lat: 47.9101688, lng: 18.1317731 },
    { lat: 47.9084021, lng: 18.1300634 },
    { lat: 47.9076989, lng: 18.1309371 },
    { lat: 47.9073691, lng: 18.1300136 },
    { lat: 47.9070054, lng: 18.130487 },
    { lat: 47.9059715, lng: 18.1314236 },
    { lat: 47.9055702, lng: 18.1315435 },
    { lat: 47.9047826, lng: 18.1321373 },
    { lat: 47.90468, lng: 18.1321029 },
    { lat: 47.9042603, lng: 18.1322962 },
    { lat: 47.904104, lng: 18.132208 },
    { lat: 47.9040154, lng: 18.1322575 },
    { lat: 47.9038246, lng: 18.1325427 },
    { lat: 47.9035238, lng: 18.1321089 },
    { lat: 47.9034007, lng: 18.1320304 },
    { lat: 47.9032193, lng: 18.1320541 },
    { lat: 47.9028772, lng: 18.1323762 },
    { lat: 47.9014437, lng: 18.126983 },
    { lat: 47.9050244, lng: 18.1244247 },
    { lat: 47.9044215, lng: 18.122297 },
    { lat: 47.9020948, lng: 18.1146729 },
    { lat: 47.9003008, lng: 18.1094167 },
    { lat: 47.8993709, lng: 18.1077025 },
    { lat: 47.8978337, lng: 18.104541 },
    { lat: 47.8972327, lng: 18.1031752 },
    { lat: 47.8947722, lng: 18.0979782 },
    { lat: 47.8944402, lng: 18.0962822 },
    { lat: 47.8918366, lng: 18.0873528 },
    { lat: 47.8920681, lng: 18.0859489 },
    { lat: 47.8919984, lng: 18.0850987 },
    { lat: 47.8913109, lng: 18.0837102 },
    { lat: 47.8905852, lng: 18.0820975 },
    { lat: 47.8904398, lng: 18.0813982 },
    { lat: 47.8904801, lng: 18.0804063 },
    { lat: 47.890956, lng: 18.0789611 },
    { lat: 47.8910401, lng: 18.0762785 },
    { lat: 47.8914682, lng: 18.0747491 },
    { lat: 47.8915746, lng: 18.0738086 },
    { lat: 47.8915356, lng: 18.0725454 },
    { lat: 47.8911329, lng: 18.0712057 },
    { lat: 47.890623, lng: 18.0707524 },
    { lat: 47.8899317, lng: 18.070327 },
    { lat: 47.889039, lng: 18.0699678 },
    { lat: 47.8882445, lng: 18.0698545 },
    { lat: 47.8869142, lng: 18.0690801 },
    { lat: 47.8852167, lng: 18.0676056 },
    { lat: 47.8833672, lng: 18.0667186 },
    { lat: 47.8824912, lng: 18.066183 },
    { lat: 47.8818076, lng: 18.063656 },
    { lat: 47.8832212, lng: 18.0630231 },
    { lat: 47.8829236, lng: 18.0619665 },
    { lat: 47.8821114, lng: 18.0597631 },
    { lat: 47.8819388, lng: 18.0592712 },
    { lat: 47.8811444, lng: 18.0577989 },
    { lat: 47.880946, lng: 18.0575769 },
    { lat: 47.8805726, lng: 18.0583542 },
    { lat: 47.8802188, lng: 18.059347 },
    { lat: 47.8799647, lng: 18.059666 },
    { lat: 47.8793439, lng: 18.060194 },
    { lat: 47.8792526, lng: 18.060367 },
    { lat: 47.8792493, lng: 18.0608589 },
    { lat: 47.8793135, lng: 18.0611426 },
    { lat: 47.8793262, lng: 18.061201 },
    { lat: 47.8794053, lng: 18.0614873 },
    { lat: 47.8797568, lng: 18.0625947 },
    { lat: 47.8797873, lng: 18.0631057 },
    { lat: 47.8796603, lng: 18.0634143 },
    { lat: 47.879078, lng: 18.0640201 },
    { lat: 47.8788903, lng: 18.0646006 },
    { lat: 47.878816, lng: 18.0651754 },
    { lat: 47.8790289, lng: 18.067155 },
    { lat: 47.8791267, lng: 18.0676012 },
    { lat: 47.8793998, lng: 18.0683773 },
    { lat: 47.8801183, lng: 18.0696393 },
    { lat: 47.8801782, lng: 18.0701682 },
    { lat: 47.8801035, lng: 18.0711135 },
    { lat: 47.8805529, lng: 18.071967 },
    { lat: 47.8807453, lng: 18.0722436 },
    { lat: 47.8813392, lng: 18.0728162 },
    { lat: 47.8815044, lng: 18.0730124 },
    { lat: 47.8816037, lng: 18.0732377 },
    { lat: 47.8812472, lng: 18.073999 },
    { lat: 47.8810545, lng: 18.0746107 },
    { lat: 47.8807661, lng: 18.0766863 },
    { lat: 47.8806035, lng: 18.0771556 },
    { lat: 47.8804084, lng: 18.0774069 },
    { lat: 47.8799991, lng: 18.077678 },
    { lat: 47.8797702, lng: 18.0776976 },
    { lat: 47.8795252, lng: 18.0775664 },
    { lat: 47.8790413, lng: 18.0774954 },
    { lat: 47.8786907, lng: 18.0777468 },
    { lat: 47.8784428, lng: 18.0779809 },
    { lat: 47.8778464, lng: 18.0792042 },
    { lat: 47.8770911, lng: 18.0802428 },
    { lat: 47.8770176, lng: 18.0804497 },
    { lat: 47.8770512, lng: 18.080652 },
    { lat: 47.8771689, lng: 18.080866 },
    { lat: 47.8775382, lng: 18.0811933 },
    { lat: 47.878265, lng: 18.0815326 },
    { lat: 47.8794496, lng: 18.0817361 },
    { lat: 47.8797409, lng: 18.0818711 },
    { lat: 47.8803801, lng: 18.0824863 },
    { lat: 47.8805758, lng: 18.0830241 },
    { lat: 47.8804759, lng: 18.0842117 },
    { lat: 47.8804409, lng: 18.085388 },
    { lat: 47.8802851, lng: 18.0858781 },
    { lat: 47.880074, lng: 18.0861263 },
    { lat: 47.8799119, lng: 18.0861676 },
    { lat: 47.879378, lng: 18.0859235 },
    { lat: 47.8792249, lng: 18.0859292 },
    { lat: 47.8786457, lng: 18.0861114 },
    { lat: 47.8785088, lng: 18.0862405 },
    { lat: 47.8778288, lng: 18.0865928 },
    { lat: 47.8771482, lng: 18.086588 },
    { lat: 47.8767641, lng: 18.0865087 },
    { lat: 47.8765822, lng: 18.0863727 },
    { lat: 47.8762412, lng: 18.0856445 },
    { lat: 47.8761423, lng: 18.085294 },
    { lat: 47.8759461, lng: 18.0839669 },
    { lat: 47.8758332, lng: 18.0837188 },
    { lat: 47.8756426, lng: 18.0835884 },
    { lat: 47.8754035, lng: 18.0835928 },
    { lat: 47.8749928, lng: 18.0838805 },
    { lat: 47.8744386, lng: 18.0846525 },
    { lat: 47.8739287, lng: 18.0860246 },
    { lat: 47.8736144, lng: 18.0874768 },
    { lat: 47.8735355, lng: 18.0879701 },
    { lat: 47.8735098, lng: 18.0886044 },
    { lat: 47.8740907, lng: 18.0910441 },
    { lat: 47.874375, lng: 18.0927175 },
    { lat: 47.8744484, lng: 18.0938018 },
    { lat: 47.8745553, lng: 18.0943076 },
    { lat: 47.8748018, lng: 18.0952092 },
    { lat: 47.875188, lng: 18.0958784 },
    { lat: 47.8753607, lng: 18.0965841 },
    { lat: 47.8753439, lng: 18.0974752 },
    { lat: 47.8752797, lng: 18.0979163 },
    { lat: 47.8751172, lng: 18.0983738 },
    { lat: 47.8748053, lng: 18.0988239 },
    { lat: 47.8746633, lng: 18.0992453 },
    { lat: 47.874628, lng: 18.0992197 },
    { lat: 47.8746056, lng: 18.0995278 },
    { lat: 47.8743793, lng: 18.1000696 },
    { lat: 47.8745504, lng: 18.1018296 },
    { lat: 47.8746706, lng: 18.1051115 },
    { lat: 47.8764922, lng: 18.1090365 },
    { lat: 47.8773584, lng: 18.1113116 },
    { lat: 47.8828552, lng: 18.1220848 },
    { lat: 47.8834202, lng: 18.1269456 },
    { lat: 47.8842821, lng: 18.1305307 },
    { lat: 47.8845995, lng: 18.1323145 },
    { lat: 47.8860569, lng: 18.1374663 },
    { lat: 47.8860297, lng: 18.137614 },
    { lat: 47.8863637, lng: 18.1392246 },
    { lat: 47.8869543, lng: 18.1427315 },
    { lat: 47.8869541, lng: 18.1428246 },
    { lat: 47.8873867, lng: 18.146123 },
    { lat: 47.8876829, lng: 18.1473381 },
    { lat: 47.8883818, lng: 18.149609 },
    { lat: 47.8886653, lng: 18.1516446 },
    { lat: 47.8884676, lng: 18.1594467 },
    { lat: 47.8882949, lng: 18.1629409 },
    { lat: 47.8882112, lng: 18.1701789 },
    { lat: 47.8877709, lng: 18.1737341 },
    { lat: 47.8875906, lng: 18.1746786 },
    { lat: 47.8874451, lng: 18.1766609 },
    { lat: 47.8874949, lng: 18.1766839 },
    { lat: 47.8880817, lng: 18.1770322 },
    { lat: 47.8880965, lng: 18.1772047 },
    { lat: 47.8880054, lng: 18.1777885 },
    { lat: 47.8880886, lng: 18.1785898 },
    { lat: 47.8880072, lng: 18.1790893 },
    { lat: 47.8876713, lng: 18.1797126 },
    { lat: 47.8877163, lng: 18.1799588 },
    { lat: 47.887935, lng: 18.1803587 },
    { lat: 47.8882074, lng: 18.180714 },
    { lat: 47.8884573, lng: 18.1808219 },
    { lat: 47.8886395, lng: 18.1806176 },
    { lat: 47.8888786, lng: 18.1800575 },
    { lat: 47.8890744, lng: 18.1797962 },
    { lat: 47.8896834, lng: 18.1794266 },
    { lat: 47.8897725, lng: 18.1792108 },
    { lat: 47.8898186, lng: 18.1787833 },
    { lat: 47.8898622, lng: 18.1787611 },
    { lat: 47.8900442, lng: 18.1788598 },
    { lat: 47.8904129, lng: 18.1792807 },
    { lat: 47.8906717, lng: 18.1793836 },
    { lat: 47.8910399, lng: 18.1793672 },
    { lat: 47.8920049, lng: 18.1789883 },
    { lat: 47.8922077, lng: 18.1791557 },
    { lat: 47.8922332, lng: 18.1792614 },
    { lat: 47.8921875, lng: 18.1795212 },
    { lat: 47.891816, lng: 18.1798639 },
    { lat: 47.8916846, lng: 18.1802206 },
    { lat: 47.891746, lng: 18.1805948 },
    { lat: 47.8921425, lng: 18.1811028 },
    { lat: 47.8921587, lng: 18.1814386 },
    { lat: 47.8920648, lng: 18.1816248 },
    { lat: 47.8917065, lng: 18.181962 },
    { lat: 47.8916535, lng: 18.1823696 },
    { lat: 47.8919774, lng: 18.1829254 },
    { lat: 47.8922249, lng: 18.1832231 },
    { lat: 47.8925561, lng: 18.1833106 },
    { lat: 47.8928198, lng: 18.1830532 },
    { lat: 47.8930389, lng: 18.1824577 },
    { lat: 47.8931153, lng: 18.1823768 },
    { lat: 47.8933339, lng: 18.1823707 },
    { lat: 47.893495, lng: 18.1820773 },
    { lat: 47.8936965, lng: 18.1820498 },
    { lat: 47.894006, lng: 18.1822871 },
    { lat: 47.8940528, lng: 18.1826214 },
    { lat: 47.8939544, lng: 18.182984 },
    { lat: 47.8940366, lng: 18.183152 },
    { lat: 47.8942281, lng: 18.1832084 },
    { lat: 47.8944354, lng: 18.1831432 },
    { lat: 47.8946531, lng: 18.1826611 },
    { lat: 47.8950147, lng: 18.1825724 },
    { lat: 47.8952925, lng: 18.1825768 },
    { lat: 47.8956118, lng: 18.1824399 },
    { lat: 47.8957806, lng: 18.1821448 },
    { lat: 47.8960537, lng: 18.18191 },
    { lat: 47.8961522, lng: 18.1819173 },
    { lat: 47.8964978, lng: 18.1821939 },
    { lat: 47.896639, lng: 18.1821581 },
    { lat: 47.8966898, lng: 18.1819468 },
    { lat: 47.8966021, lng: 18.1816808 },
    { lat: 47.8963724, lng: 18.1814577 },
    { lat: 47.8963981, lng: 18.1810898 },
    { lat: 47.8965225, lng: 18.1806893 },
    { lat: 47.8965794, lng: 18.1800789 },
    { lat: 47.8966645, lng: 18.1798967 },
    { lat: 47.8973205, lng: 18.179805 },
    { lat: 47.8976999, lng: 18.1794643 },
    { lat: 47.8980273, lng: 18.1785231 },
    { lat: 47.8981588, lng: 18.1775306 },
    { lat: 47.8981581, lng: 18.1772522 },
    { lat: 47.8982569, lng: 18.1769539 },
    { lat: 47.8988162, lng: 18.1767365 },
    { lat: 47.8989841, lng: 18.1767496 },
    { lat: 47.8993037, lng: 18.1771948 },
    { lat: 47.8995256, lng: 18.1772483 },
    { lat: 47.8999932, lng: 18.1770041 },
    { lat: 47.9003539, lng: 18.1769408 },
    { lat: 47.9009012, lng: 18.1766898 },
    { lat: 47.9011409, lng: 18.1767157 },
    { lat: 47.9012048, lng: 18.1767858 },
    { lat: 47.90129, lng: 18.1771215 },
    { lat: 47.9013219, lng: 18.177456 },
    { lat: 47.9013162, lng: 18.177762 },
    { lat: 47.9012311, lng: 18.1781785 },
    { lat: 47.9013148, lng: 18.1784127 },
    { lat: 47.9014519, lng: 18.1785968 },
    { lat: 47.9015973, lng: 18.1785558 },
    { lat: 47.9016808, lng: 18.1780165 },
    { lat: 47.9018044, lng: 18.177737 },
    { lat: 47.9020332, lng: 18.177598 },
    { lat: 47.9026579, lng: 18.1776095 },
    { lat: 47.9027776, lng: 18.1775328 },
    { lat: 47.9028486, lng: 18.1770105 },
    { lat: 47.9028123, lng: 18.1763137 },
    { lat: 47.9029422, lng: 18.1760862 },
    { lat: 47.9037904, lng: 18.1758876 },
    { lat: 47.9041128, lng: 18.1759017 },
    { lat: 47.9044108, lng: 18.1760199 },
    { lat: 47.9046919, lng: 18.1768095 },
    { lat: 47.9048723, lng: 18.177059 },
    { lat: 47.9052202, lng: 18.177253 },
    { lat: 47.9057145, lng: 18.1771988 },
    { lat: 47.9058356, lng: 18.1772995 },
    { lat: 47.9058948, lng: 18.1776924 },
    { lat: 47.9058036, lng: 18.1780816 },
    { lat: 47.905699, lng: 18.1782615 },
    { lat: 47.9051458, lng: 18.178642 },
    { lat: 47.9050672, lng: 18.1791125 },
    { lat: 47.9050991, lng: 18.179888 },
    { lat: 47.9052532, lng: 18.1802661 },
    { lat: 47.9054066, lng: 18.1804545 },
    { lat: 47.9056288, lng: 18.1804169 },
    { lat: 47.9060502, lng: 18.1799212 },
    { lat: 47.9062788, lng: 18.1797977 },
    { lat: 47.9065043, lng: 18.1799496 },
    { lat: 47.9064794, lng: 18.180199 },
    { lat: 47.9061388, lng: 18.1806824 },
    { lat: 47.9060893, lng: 18.1808984 },
    { lat: 47.9060981, lng: 18.1811811 },
    { lat: 47.9062553, lng: 18.1814389 },
    { lat: 47.9064559, lng: 18.18153 },
    { lat: 47.9066879, lng: 18.1814455 },
    { lat: 47.9068097, lng: 18.1811948 },
    { lat: 47.9068463, lng: 18.1806051 },
    { lat: 47.906994, lng: 18.1803732 },
    { lat: 47.9072516, lng: 18.1801855 },
    { lat: 47.9074375, lng: 18.1802743 },
    { lat: 47.9075889, lng: 18.180453 },
    { lat: 47.907623, lng: 18.1808059 },
    { lat: 47.9073463, lng: 18.1812441 },
    { lat: 47.9073838, lng: 18.1814977 },
    { lat: 47.9076686, lng: 18.1818501 },
    { lat: 47.9076741, lng: 18.1821786 },
    { lat: 47.9077638, lng: 18.1824385 },
    { lat: 47.9081157, lng: 18.1827052 },
    { lat: 47.9084887, lng: 18.1826379 },
    { lat: 47.9092181, lng: 18.1821649 },
    { lat: 47.9092301, lng: 18.181868 },
    { lat: 47.9091662, lng: 18.1812787 },
    { lat: 47.9092263, lng: 18.1810328 },
    { lat: 47.9097358, lng: 18.1809267 },
    { lat: 47.910082, lng: 18.1809595 },
    { lat: 47.9103595, lng: 18.1805339 },
    { lat: 47.9107634, lng: 18.1802061 },
    { lat: 47.9111497, lng: 18.1803098 },
    { lat: 47.9111672, lng: 18.1806254 },
    { lat: 47.9110979, lng: 18.1809633 },
    { lat: 47.9113581, lng: 18.1816312 },
    { lat: 47.9117844, lng: 18.1819165 },
    { lat: 47.9119037, lng: 18.1818342 },
    { lat: 47.9122675, lng: 18.1818046 },
    { lat: 47.91243, lng: 18.181862 },
    { lat: 47.9126352, lng: 18.1822248 },
    { lat: 47.9129562, lng: 18.1829848 },
    { lat: 47.9129942, lng: 18.1833843 },
    { lat: 47.9131012, lng: 18.1837444 },
    { lat: 47.9134613, lng: 18.1838043 },
    { lat: 47.9136219, lng: 18.1841781 },
    { lat: 47.9138306, lng: 18.1843902 },
    { lat: 47.9140518, lng: 18.1842902 },
    { lat: 47.9144514, lng: 18.1837931 },
    { lat: 47.9149174, lng: 18.1837162 },
    { lat: 47.9151523, lng: 18.1838342 },
    { lat: 47.9153262, lng: 18.1840495 },
    { lat: 47.9155068, lng: 18.1847633 },
    { lat: 47.9156305, lng: 18.1849506 },
    { lat: 47.9158253, lng: 18.1849606 },
    { lat: 47.9160767, lng: 18.1848204 },
    { lat: 47.9162423, lng: 18.1844306 },
    { lat: 47.9163335, lng: 18.184021 },
    { lat: 47.9166743, lng: 18.1838118 },
    { lat: 47.9169054, lng: 18.1839423 },
    { lat: 47.9172223, lng: 18.1846705 },
    { lat: 47.9175721, lng: 18.1849991 },
    { lat: 47.9176853, lng: 18.1852349 },
    { lat: 47.9175891, lng: 18.1856354 },
    { lat: 47.9177342, lng: 18.1856071 },
    { lat: 47.9181954, lng: 18.1855273 },
    { lat: 47.9197742, lng: 18.1830426 },
    { lat: 47.9199626, lng: 18.1829045 },
    { lat: 47.9211653, lng: 18.1811277 },
    { lat: 47.9222337, lng: 18.17922 },
    { lat: 47.9229479, lng: 18.1781553 },
    { lat: 47.9246522, lng: 18.1747156 },
  ],
  Tôň: [
    { lat: 47.8240199, lng: 17.8368101 },
    { lat: 47.8239256, lng: 17.8368177 },
    { lat: 47.82349, lng: 17.8330898 },
    { lat: 47.8232911, lng: 17.8328875 },
    { lat: 47.8223803, lng: 17.8298739 },
    { lat: 47.8217066, lng: 17.8281448 },
    { lat: 47.8189694, lng: 17.8270343 },
    { lat: 47.8138746, lng: 17.8243862 },
    { lat: 47.8103305, lng: 17.8199643 },
    { lat: 47.8090115, lng: 17.8183905 },
    { lat: 47.8070746, lng: 17.8164967 },
    { lat: 47.807269, lng: 17.8133316 },
    { lat: 47.8070304, lng: 17.8119957 },
    { lat: 47.8063985, lng: 17.8091612 },
    { lat: 47.8073144, lng: 17.8086171 },
    { lat: 47.8083068, lng: 17.80773 },
    { lat: 47.8090947, lng: 17.8069084 },
    { lat: 47.8099932, lng: 17.805487 },
    { lat: 47.8108987, lng: 17.8037969 },
    { lat: 47.8108194, lng: 17.8036663 },
    { lat: 47.8103331, lng: 17.8035417 },
    { lat: 47.8080285, lng: 17.8025495 },
    { lat: 47.8063394, lng: 17.8007663 },
    { lat: 47.8046615, lng: 17.8007782 },
    { lat: 47.8041918, lng: 17.8006841 },
    { lat: 47.8028677, lng: 17.8002679 },
    { lat: 47.8015581, lng: 17.7995405 },
    { lat: 47.8015762, lng: 17.7992153 },
    { lat: 47.8009821, lng: 17.7991 },
    { lat: 47.8008955, lng: 17.7970691 },
    { lat: 47.8007862, lng: 17.7962547 },
    { lat: 47.799865, lng: 17.7966002 },
    { lat: 47.7983916, lng: 17.7964174 },
    { lat: 47.7980806, lng: 17.7964642 },
    { lat: 47.7981236, lng: 17.796848 },
    { lat: 47.7976392, lng: 17.7995338 },
    { lat: 47.7961411, lng: 17.8004984 },
    { lat: 47.7957486, lng: 17.8006645 },
    { lat: 47.7945761, lng: 17.8010751 },
    { lat: 47.793208, lng: 17.8009867 },
    { lat: 47.7927494, lng: 17.8015974 },
    { lat: 47.7935203, lng: 17.8023429 },
    { lat: 47.7940651, lng: 17.8027694 },
    { lat: 47.7944361, lng: 17.8030378 },
    { lat: 47.7952754, lng: 17.8034447 },
    { lat: 47.795342, lng: 17.8044804 },
    { lat: 47.7957944, lng: 17.8068603 },
    { lat: 47.7952433, lng: 17.8073109 },
    { lat: 47.7956568, lng: 17.8092106 },
    { lat: 47.7949127, lng: 17.8098616 },
    { lat: 47.7930514, lng: 17.8105396 },
    { lat: 47.792036, lng: 17.8117883 },
    { lat: 47.7931585, lng: 17.8150718 },
    { lat: 47.793379, lng: 17.8159537 },
    { lat: 47.7941967, lng: 17.8200959 },
    { lat: 47.7906115, lng: 17.8220831 },
    { lat: 47.7902613, lng: 17.8229568 },
    { lat: 47.7884538, lng: 17.8237929 },
    { lat: 47.7875496, lng: 17.8240828 },
    { lat: 47.7871564, lng: 17.8244794 },
    { lat: 47.7858193, lng: 17.8265159 },
    { lat: 47.7835066, lng: 17.8278513 },
    { lat: 47.7825149, lng: 17.8286644 },
    { lat: 47.7807404, lng: 17.8308009 },
    { lat: 47.7799223, lng: 17.8319915 },
    { lat: 47.7796225, lng: 17.8322393 },
    { lat: 47.7792575, lng: 17.8325424 },
    { lat: 47.7795436, lng: 17.8329871 },
    { lat: 47.7802849, lng: 17.8341349 },
    { lat: 47.780736, lng: 17.8349491 },
    { lat: 47.7813978, lng: 17.8366136 },
    { lat: 47.7858321, lng: 17.8361788 },
    { lat: 47.7864522, lng: 17.836238 },
    { lat: 47.7879724, lng: 17.8363776 },
    { lat: 47.7890707, lng: 17.83707 },
    { lat: 47.7908512, lng: 17.8386288 },
    { lat: 47.7934174, lng: 17.841012 },
    { lat: 47.7951849, lng: 17.8413726 },
    { lat: 47.7995232, lng: 17.8438242 },
    { lat: 47.7998089, lng: 17.8438089 },
    { lat: 47.7999559, lng: 17.8439605 },
    { lat: 47.8003074, lng: 17.8440216 },
    { lat: 47.8004739, lng: 17.8443968 },
    { lat: 47.8006184, lng: 17.8445794 },
    { lat: 47.8022677, lng: 17.8456392 },
    { lat: 47.8018559, lng: 17.8492489 },
    { lat: 47.8031512, lng: 17.8496428 },
    { lat: 47.805379, lng: 17.8513346 },
    { lat: 47.8048203, lng: 17.8545256 },
    { lat: 47.8058063, lng: 17.8552099 },
    { lat: 47.8068034, lng: 17.8560412 },
    { lat: 47.8072076, lng: 17.8547071 },
    { lat: 47.8070329, lng: 17.8546009 },
    { lat: 47.8074414, lng: 17.8531497 },
    { lat: 47.8075588, lng: 17.8532244 },
    { lat: 47.8075834, lng: 17.8531666 },
    { lat: 47.8077538, lng: 17.8525427 },
    { lat: 47.8077813, lng: 17.8521099 },
    { lat: 47.8083232, lng: 17.8504783 },
    { lat: 47.8085996, lng: 17.8491748 },
    { lat: 47.8085965, lng: 17.8487599 },
    { lat: 47.808328, lng: 17.8487833 },
    { lat: 47.8086697, lng: 17.8482239 },
    { lat: 47.8087771, lng: 17.8478789 },
    { lat: 47.8095435, lng: 17.8475296 },
    { lat: 47.8107236, lng: 17.8462944 },
    { lat: 47.8116876, lng: 17.8451218 },
    { lat: 47.8117412, lng: 17.8451314 },
    { lat: 47.812194, lng: 17.8447864 },
    { lat: 47.8122184, lng: 17.8446293 },
    { lat: 47.8122939, lng: 17.8446657 },
    { lat: 47.8131622, lng: 17.8437361 },
    { lat: 47.8155404, lng: 17.8443708 },
    { lat: 47.8159975, lng: 17.8460376 },
    { lat: 47.8199418, lng: 17.8440238 },
    { lat: 47.8204578, lng: 17.8436038 },
    { lat: 47.8208804, lng: 17.8429531 },
    { lat: 47.8213686, lng: 17.8420476 },
    { lat: 47.8222327, lng: 17.8410312 },
    { lat: 47.8230623, lng: 17.8402622 },
    { lat: 47.8235545, lng: 17.8392184 },
    { lat: 47.8236644, lng: 17.8386566 },
    { lat: 47.8236903, lng: 17.8384563 },
    { lat: 47.8240199, lng: 17.8368101 },
  ],
  Bodza: [
    { lat: 47.8236644, lng: 17.8386566 },
    { lat: 47.8265727, lng: 17.840767 },
    { lat: 47.8289508, lng: 17.8422248 },
    { lat: 47.8292774, lng: 17.8411694 },
    { lat: 47.8440343, lng: 17.8544023 },
    { lat: 47.8745073, lng: 17.8883125 },
    { lat: 47.8735899, lng: 17.8895525 },
    { lat: 47.8696283, lng: 17.8942435 },
    { lat: 47.8730006, lng: 17.8987026 },
    { lat: 47.8743891, lng: 17.9010869 },
    { lat: 47.8744053, lng: 17.9011128 },
    { lat: 47.8747766, lng: 17.900817 },
    { lat: 47.875419, lng: 17.9011145 },
    { lat: 47.8785289, lng: 17.8952627 },
    { lat: 47.8739782, lng: 17.8900675 },
    { lat: 47.874813, lng: 17.888492 },
    { lat: 47.8745163, lng: 17.8880888 },
    { lat: 47.8722358, lng: 17.8855056 },
    { lat: 47.8759698, lng: 17.8787951 },
    { lat: 47.8759875, lng: 17.8787558 },
    { lat: 47.8670378, lng: 17.8708005 },
    { lat: 47.8577394, lng: 17.8591285 },
    { lat: 47.8442476, lng: 17.8427825 },
    { lat: 47.842282, lng: 17.8411862 },
    { lat: 47.8419629, lng: 17.8400237 },
    { lat: 47.8417737, lng: 17.838749 },
    { lat: 47.8417711, lng: 17.836859 },
    { lat: 47.8418546, lng: 17.8360004 },
    { lat: 47.841812, lng: 17.8348358 },
    { lat: 47.8416809, lng: 17.8336811 },
    { lat: 47.8417257, lng: 17.8331996 },
    { lat: 47.8418761, lng: 17.8324908 },
    { lat: 47.8420892, lng: 17.832103 },
    { lat: 47.8424743, lng: 17.8316134 },
    { lat: 47.8440167, lng: 17.8299361 },
    { lat: 47.8432089, lng: 17.827934 },
    { lat: 47.842666, lng: 17.8268741 },
    { lat: 47.8452793, lng: 17.8235697 },
    { lat: 47.8451313, lng: 17.8228941 },
    { lat: 47.8451376, lng: 17.8226477 },
    { lat: 47.8447642, lng: 17.8215562 },
    { lat: 47.8441311, lng: 17.8215049 },
    { lat: 47.8425526, lng: 17.8228332 },
    { lat: 47.841965, lng: 17.8232226 },
    { lat: 47.8413788, lng: 17.8235281 },
    { lat: 47.8401045, lng: 17.8240274 },
    { lat: 47.839234, lng: 17.8241542 },
    { lat: 47.8369594, lng: 17.8242432 },
    { lat: 47.8346274, lng: 17.8237392 },
    { lat: 47.8340561, lng: 17.8234826 },
    { lat: 47.833302, lng: 17.822879 },
    { lat: 47.8326193, lng: 17.8216055 },
    { lat: 47.832574, lng: 17.8216409 },
    { lat: 47.8322461, lng: 17.8206998 },
    { lat: 47.8318375, lng: 17.8208866 },
    { lat: 47.8312377, lng: 17.8222314 },
    { lat: 47.8307156, lng: 17.8236074 },
    { lat: 47.8304409, lng: 17.8242024 },
    { lat: 47.8295741, lng: 17.8258843 },
    { lat: 47.8291981, lng: 17.8264881 },
    { lat: 47.8285742, lng: 17.8273506 },
    { lat: 47.8279991, lng: 17.8279275 },
    { lat: 47.8276827, lng: 17.8284891 },
    { lat: 47.8270776, lng: 17.8279958 },
    { lat: 47.8254661, lng: 17.831986 },
    { lat: 47.8249606, lng: 17.8337975 },
    { lat: 47.824797, lng: 17.8344387 },
    { lat: 47.8246665, lng: 17.8349219 },
    { lat: 47.8245256, lng: 17.8354377 },
    { lat: 47.8244033, lng: 17.8362338 },
    { lat: 47.8241569, lng: 17.8367792 },
    { lat: 47.8240199, lng: 17.8368101 },
    { lat: 47.8236903, lng: 17.8384563 },
    { lat: 47.8236644, lng: 17.8386566 },
  ],
  Chotín: [
    { lat: 47.824619, lng: 18.2717696 },
    { lat: 47.8254444, lng: 18.2712138 },
    { lat: 47.8262973, lng: 18.270512 },
    { lat: 47.8265947, lng: 18.2701324 },
    { lat: 47.8268277, lng: 18.269635 },
    { lat: 47.8270894, lng: 18.2688744 },
    { lat: 47.8274742, lng: 18.2681139 },
    { lat: 47.8286599, lng: 18.2662796 },
    { lat: 47.8298222, lng: 18.2649552 },
    { lat: 47.8306334, lng: 18.2637666 },
    { lat: 47.8312481, lng: 18.263023 },
    { lat: 47.83316, lng: 18.2607704 },
    { lat: 47.8340967, lng: 18.2598233 },
    { lat: 47.8354559, lng: 18.2580426 },
    { lat: 47.835745, lng: 18.2577152 },
    { lat: 47.8369156, lng: 18.2566331 },
    { lat: 47.8381654, lng: 18.2550502 },
    { lat: 47.8394791, lng: 18.2535184 },
    { lat: 47.8384595, lng: 18.251876 },
    { lat: 47.8359934, lng: 18.2475099 },
    { lat: 47.8342234, lng: 18.2442337 },
    { lat: 47.832757, lng: 18.2423382 },
    { lat: 47.8284048, lng: 18.2343999 },
    { lat: 47.8270135, lng: 18.2280848 },
    { lat: 47.8262393, lng: 18.225041 },
    { lat: 47.8258365, lng: 18.2228354 },
    { lat: 47.8254793, lng: 18.2201167 },
    { lat: 47.8252422, lng: 18.2189344 },
    { lat: 47.8249708, lng: 18.2182657 },
    { lat: 47.8251771, lng: 18.2178171 },
    { lat: 47.8251329, lng: 18.217255 },
    { lat: 47.823667, lng: 18.2147984 },
    { lat: 47.8247454, lng: 18.2136301 },
    { lat: 47.824726, lng: 18.2134711 },
    { lat: 47.8240106, lng: 18.2122915 },
    { lat: 47.824352, lng: 18.2116057 },
    { lat: 47.8235698, lng: 18.2103673 },
    { lat: 47.8240522, lng: 18.2096226 },
    { lat: 47.8230301, lng: 18.2080068 },
    { lat: 47.8218382, lng: 18.2050659 },
    { lat: 47.8197616, lng: 18.2020688 },
    { lat: 47.8194442, lng: 18.2015431 },
    { lat: 47.8191658, lng: 18.2010488 },
    { lat: 47.8181241, lng: 18.1985175 },
    { lat: 47.8179305, lng: 18.1982388 },
    { lat: 47.8173331, lng: 18.1978058 },
    { lat: 47.815367, lng: 18.1969301 },
    { lat: 47.8148876, lng: 18.1965117 },
    { lat: 47.8142979, lng: 18.1964025 },
    { lat: 47.8115511, lng: 18.1922753 },
    { lat: 47.8107685, lng: 18.1917241 },
    { lat: 47.8087311, lng: 18.1890191 },
    { lat: 47.8080305, lng: 18.1892742 },
    { lat: 47.8051065, lng: 18.1866137 },
    { lat: 47.8037028, lng: 18.1854796 },
    { lat: 47.8016195, lng: 18.184399 },
    { lat: 47.8012026, lng: 18.1841399 },
    { lat: 47.7897943, lng: 18.2147466 },
    { lat: 47.7815403, lng: 18.2079733 },
    { lat: 47.7813979, lng: 18.2078555 },
    { lat: 47.7814179, lng: 18.2126546 },
    { lat: 47.7814736, lng: 18.2138984 },
    { lat: 47.781647, lng: 18.2152243 },
    { lat: 47.7817354, lng: 18.2175515 },
    { lat: 47.7817469, lng: 18.2185979 },
    { lat: 47.7816705, lng: 18.2191455 },
    { lat: 47.7820544, lng: 18.2235186 },
    { lat: 47.7821673, lng: 18.2245461 },
    { lat: 47.7825392, lng: 18.2250607 },
    { lat: 47.7826549, lng: 18.2260744 },
    { lat: 47.7824099, lng: 18.2267314 },
    { lat: 47.7819537, lng: 18.2269983 },
    { lat: 47.7842249, lng: 18.2288125 },
    { lat: 47.7854445, lng: 18.229973 },
    { lat: 47.7859154, lng: 18.2305381 },
    { lat: 47.7866765, lng: 18.2313204 },
    { lat: 47.7877151, lng: 18.2322608 },
    { lat: 47.7877653, lng: 18.232521 },
    { lat: 47.7883459, lng: 18.2334047 },
    { lat: 47.7890889, lng: 18.2338192 },
    { lat: 47.7907299, lng: 18.2358628 },
    { lat: 47.7912821, lng: 18.2362951 },
    { lat: 47.793054, lng: 18.2379108 },
    { lat: 47.7949927, lng: 18.2402004 },
    { lat: 47.7974414, lng: 18.2425907 },
    { lat: 47.8014678, lng: 18.2462662 },
    { lat: 47.8033645, lng: 18.2474854 },
    { lat: 47.8037689, lng: 18.2475523 },
    { lat: 47.8068374, lng: 18.2502817 },
    { lat: 47.8086995, lng: 18.252678 },
    { lat: 47.811131, lng: 18.2563873 },
    { lat: 47.8131602, lng: 18.2588296 },
    { lat: 47.8145232, lng: 18.2610266 },
    { lat: 47.8183255, lng: 18.2653652 },
    { lat: 47.8206382, lng: 18.2674809 },
    { lat: 47.8232537, lng: 18.2706413 },
    { lat: 47.8239251, lng: 18.2710489 },
    { lat: 47.824596, lng: 18.271782 },
    { lat: 47.824619, lng: 18.2717696 },
  ],
  BodzianskeLúky: [
    { lat: 47.8970715, lng: 17.9248814 },
    { lat: 47.8971079, lng: 17.9246237 },
    { lat: 47.89728, lng: 17.9241527 },
    { lat: 47.8976793, lng: 17.9235703 },
    { lat: 47.8980152, lng: 17.9232283 },
    { lat: 47.8992322, lng: 17.9222899 },
    { lat: 47.8997962, lng: 17.9220029 },
    { lat: 47.900246, lng: 17.9218818 },
    { lat: 47.9004974, lng: 17.9217491 },
    { lat: 47.900979, lng: 17.9208713 },
    { lat: 47.9017644, lng: 17.9199962 },
    { lat: 47.9023275, lng: 17.9194744 },
    { lat: 47.9026017, lng: 17.9185657 },
    { lat: 47.9029926, lng: 17.9181807 },
    { lat: 47.9033433, lng: 17.9179881 },
    { lat: 47.9039398, lng: 17.917907 },
    { lat: 47.9042202, lng: 17.9179505 },
    { lat: 47.9044687, lng: 17.91814 },
    { lat: 47.9045836, lng: 17.9184682 },
    { lat: 47.9046277, lng: 17.9189129 },
    { lat: 47.9047552, lng: 17.9190058 },
    { lat: 47.9049615, lng: 17.9190027 },
    { lat: 47.9053857, lng: 17.9187956 },
    { lat: 47.9055399, lng: 17.9185659 },
    { lat: 47.905662, lng: 17.9182515 },
    { lat: 47.9056177, lng: 17.9177854 },
    { lat: 47.9054447, lng: 17.9172634 },
    { lat: 47.9055691, lng: 17.9170412 },
    { lat: 47.9056657, lng: 17.9168688 },
    { lat: 47.9046844, lng: 17.9148795 },
    { lat: 47.9038294, lng: 17.9129827 },
    { lat: 47.9028088, lng: 17.910443 },
    { lat: 47.9020792, lng: 17.9097711 },
    { lat: 47.901576, lng: 17.908796 },
    { lat: 47.9012498, lng: 17.9086093 },
    { lat: 47.9007737, lng: 17.9077269 },
    { lat: 47.9000885, lng: 17.9068008 },
    { lat: 47.9000703, lng: 17.9062812 },
    { lat: 47.8999416, lng: 17.9059296 },
    { lat: 47.8996619, lng: 17.9054155 },
    { lat: 47.8993867, lng: 17.9052556 },
    { lat: 47.8989141, lng: 17.9052783 },
    { lat: 47.8980858, lng: 17.9042832 },
    { lat: 47.8980177, lng: 17.9042023 },
    { lat: 47.897673, lng: 17.9044926 },
    { lat: 47.8973665, lng: 17.9047401 },
    { lat: 47.8969478, lng: 17.9048526 },
    { lat: 47.8959217, lng: 17.9045581 },
    { lat: 47.8956747, lng: 17.9041894 },
    { lat: 47.8961246, lng: 17.9032751 },
    { lat: 47.8956982, lng: 17.9027266 },
    { lat: 47.8945336, lng: 17.8988874 },
    { lat: 47.8943647, lng: 17.8983802 },
    { lat: 47.8941539, lng: 17.8979764 },
    { lat: 47.8938835, lng: 17.8975233 },
    { lat: 47.8928869, lng: 17.8972666 },
    { lat: 47.8926898, lng: 17.8970878 },
    { lat: 47.8924965, lng: 17.8967155 },
    { lat: 47.8921168, lng: 17.895654 },
    { lat: 47.8920274, lng: 17.8952838 },
    { lat: 47.8918922, lng: 17.8944386 },
    { lat: 47.8917212, lng: 17.8928401 },
    { lat: 47.8915122, lng: 17.8926732 },
    { lat: 47.8910725, lng: 17.8926651 },
    { lat: 47.8906679, lng: 17.8924356 },
    { lat: 47.8906266, lng: 17.892034 },
    { lat: 47.8903532, lng: 17.8915909 },
    { lat: 47.8875393, lng: 17.8890466 },
    { lat: 47.8871813, lng: 17.8886589 },
    { lat: 47.8870135, lng: 17.8881848 },
    { lat: 47.8860804, lng: 17.8877308 },
    { lat: 47.8849287, lng: 17.8869125 },
    { lat: 47.8820458, lng: 17.8843517 },
    { lat: 47.8792984, lng: 17.881719 },
    { lat: 47.8759875, lng: 17.8787558 },
    { lat: 47.8759698, lng: 17.8787951 },
    { lat: 47.8722358, lng: 17.8855056 },
    { lat: 47.8745163, lng: 17.8880888 },
    { lat: 47.874813, lng: 17.888492 },
    { lat: 47.8739782, lng: 17.8900675 },
    { lat: 47.8785289, lng: 17.8952627 },
    { lat: 47.875419, lng: 17.9011145 },
    { lat: 47.8761562, lng: 17.9014457 },
    { lat: 47.8772573, lng: 17.9020659 },
    { lat: 47.8791709, lng: 17.9032692 },
    { lat: 47.8808679, lng: 17.9045332 },
    { lat: 47.881748, lng: 17.9054548 },
    { lat: 47.8818661, lng: 17.9061302 },
    { lat: 47.8822673, lng: 17.9068785 },
    { lat: 47.8830258, lng: 17.9079843 },
    { lat: 47.8836222, lng: 17.9090291 },
    { lat: 47.8837281, lng: 17.9095975 },
    { lat: 47.8837801, lng: 17.9106714 },
    { lat: 47.8836388, lng: 17.9110762 },
    { lat: 47.8834922, lng: 17.9111153 },
    { lat: 47.8833034, lng: 17.9110191 },
    { lat: 47.8831217, lng: 17.9110183 },
    { lat: 47.8830024, lng: 17.9112288 },
    { lat: 47.8836436, lng: 17.9119895 },
    { lat: 47.8840546, lng: 17.9122319 },
    { lat: 47.8843686, lng: 17.9121604 },
    { lat: 47.8848596, lng: 17.9118259 },
    { lat: 47.8855683, lng: 17.9111199 },
    { lat: 47.8861504, lng: 17.9108564 },
    { lat: 47.8867385, lng: 17.9108383 },
    { lat: 47.8869469, lng: 17.9110317 },
    { lat: 47.8870129, lng: 17.911506 },
    { lat: 47.8869595, lng: 17.9119795 },
    { lat: 47.88671, lng: 17.9123873 },
    { lat: 47.886631, lng: 17.9126089 },
    { lat: 47.8866919, lng: 17.9130326 },
    { lat: 47.8871028, lng: 17.9135532 },
    { lat: 47.8875329, lng: 17.9136823 },
    { lat: 47.8875628, lng: 17.9138203 },
    { lat: 47.8868495, lng: 17.9154318 },
    { lat: 47.8868055, lng: 17.9164318 },
    { lat: 47.8871896, lng: 17.9178292 },
    { lat: 47.890991, lng: 17.9199333 },
    { lat: 47.8943017, lng: 17.92262 },
    { lat: 47.8947485, lng: 17.9229889 },
    { lat: 47.8969782, lng: 17.9249056 },
    { lat: 47.8970715, lng: 17.9248814 },
  ],
  Trávnik: [
    { lat: 47.7792575, lng: 17.8325424 },
    { lat: 47.7796225, lng: 17.8322393 },
    { lat: 47.7799223, lng: 17.8319915 },
    { lat: 47.7807404, lng: 17.8308009 },
    { lat: 47.7825149, lng: 17.8286644 },
    { lat: 47.7835066, lng: 17.8278513 },
    { lat: 47.7858193, lng: 17.8265159 },
    { lat: 47.7871564, lng: 17.8244794 },
    { lat: 47.7875496, lng: 17.8240828 },
    { lat: 47.7884538, lng: 17.8237929 },
    { lat: 47.7902613, lng: 17.8229568 },
    { lat: 47.7906115, lng: 17.8220831 },
    { lat: 47.7941967, lng: 17.8200959 },
    { lat: 47.793379, lng: 17.8159537 },
    { lat: 47.7931585, lng: 17.8150718 },
    { lat: 47.792036, lng: 17.8117883 },
    { lat: 47.7930514, lng: 17.8105396 },
    { lat: 47.7949127, lng: 17.8098616 },
    { lat: 47.7956568, lng: 17.8092106 },
    { lat: 47.7952433, lng: 17.8073109 },
    { lat: 47.7957944, lng: 17.8068603 },
    { lat: 47.795342, lng: 17.8044804 },
    { lat: 47.7952754, lng: 17.8034447 },
    { lat: 47.7944361, lng: 17.8030378 },
    { lat: 47.7940651, lng: 17.8027694 },
    { lat: 47.7935203, lng: 17.8023429 },
    { lat: 47.7927494, lng: 17.8015974 },
    { lat: 47.793208, lng: 17.8009867 },
    { lat: 47.7945761, lng: 17.8010751 },
    { lat: 47.7957486, lng: 17.8006645 },
    { lat: 47.7957429, lng: 17.8001576 },
    { lat: 47.7957686, lng: 17.7995885 },
    { lat: 47.7960921, lng: 17.7971074 },
    { lat: 47.7961161, lng: 17.7961316 },
    { lat: 47.7953325, lng: 17.7960044 },
    { lat: 47.7945367, lng: 17.7957345 },
    { lat: 47.7939229, lng: 17.7954123 },
    { lat: 47.7929067, lng: 17.7948268 },
    { lat: 47.7897897, lng: 17.7928477 },
    { lat: 47.7888399, lng: 17.7921318 },
    { lat: 47.7892012, lng: 17.7910791 },
    { lat: 47.7893912, lng: 17.7898518 },
    { lat: 47.7894926, lng: 17.7896108 },
    { lat: 47.7898455, lng: 17.789077 },
    { lat: 47.7899209, lng: 17.7886577 },
    { lat: 47.7898833, lng: 17.7884332 },
    { lat: 47.7891812, lng: 17.7879432 },
    { lat: 47.7888348, lng: 17.7871138 },
    { lat: 47.7887918, lng: 17.786895 },
    { lat: 47.7888211, lng: 17.7846254 },
    { lat: 47.7872125, lng: 17.7841582 },
    { lat: 47.7848697, lng: 17.7814147 },
    { lat: 47.7845736, lng: 17.7811566 },
    { lat: 47.7840949, lng: 17.780939 },
    { lat: 47.7832951, lng: 17.7808938 },
    { lat: 47.7829439, lng: 17.7812709 },
    { lat: 47.7820144, lng: 17.7829529 },
    { lat: 47.7818737, lng: 17.7830501 },
    { lat: 47.7816953, lng: 17.7830679 },
    { lat: 47.7813225, lng: 17.7828108 },
    { lat: 47.7804262, lng: 17.7815617 },
    { lat: 47.7796232, lng: 17.7806676 },
    { lat: 47.7781242, lng: 17.7794539 },
    { lat: 47.7763512, lng: 17.7769999 },
    { lat: 47.7755094, lng: 17.7782925 },
    { lat: 47.7744742, lng: 17.7789654 },
    { lat: 47.772738, lng: 17.7797461 },
    { lat: 47.7715678, lng: 17.7804048 },
    { lat: 47.7703312, lng: 17.7813229 },
    { lat: 47.7691959, lng: 17.7822529 },
    { lat: 47.7671068, lng: 17.784254 },
    { lat: 47.7645121, lng: 17.7870944 },
    { lat: 47.76344, lng: 17.7865563 },
    { lat: 47.7613409, lng: 17.7849752 },
    { lat: 47.7605045, lng: 17.7855185 },
    { lat: 47.7596043, lng: 17.7844967 },
    { lat: 47.7584995, lng: 17.7837253 },
    { lat: 47.75718, lng: 17.7824958 },
    { lat: 47.7559104, lng: 17.7803102 },
    { lat: 47.7548601, lng: 17.7788184 },
    { lat: 47.7530833, lng: 17.7766681 },
    { lat: 47.7530281, lng: 17.7728145 },
    { lat: 47.7534355, lng: 17.7677095 },
    { lat: 47.7540081, lng: 17.7650419 },
    { lat: 47.7544417, lng: 17.762473 },
    { lat: 47.7550691, lng: 17.7601567 },
    { lat: 47.75576, lng: 17.758062 },
    { lat: 47.7566184, lng: 17.7560664 },
    { lat: 47.7574266, lng: 17.7535183 },
    { lat: 47.7584847, lng: 17.7511768 },
    { lat: 47.7590489, lng: 17.7487393 },
    { lat: 47.7590615, lng: 17.7460536 },
    { lat: 47.7619456, lng: 17.74659 },
    { lat: 47.7638298, lng: 17.7439743 },
    { lat: 47.7611491, lng: 17.736547 },
    { lat: 47.7606617, lng: 17.7335817 },
    { lat: 47.7603924, lng: 17.7329059 },
    { lat: 47.759311, lng: 17.7338628 },
    { lat: 47.7581873, lng: 17.7352066 },
    { lat: 47.7576652, lng: 17.7365111 },
    { lat: 47.7569855, lng: 17.7369262 },
    { lat: 47.7557285, lng: 17.7365404 },
    { lat: 47.7546126, lng: 17.738314 },
    { lat: 47.7532658, lng: 17.74062 },
    { lat: 47.7524779, lng: 17.7416677 },
    { lat: 47.750757, lng: 17.743213 },
    { lat: 47.750745, lng: 17.743248 },
    { lat: 47.75071, lng: 17.743402 },
    { lat: 47.750655, lng: 17.743643 },
    { lat: 47.75065, lng: 17.74367 },
    { lat: 47.750528, lng: 17.744208 },
    { lat: 47.750476, lng: 17.744439 },
    { lat: 47.750277, lng: 17.74566 },
    { lat: 47.750197, lng: 17.746448 },
    { lat: 47.750149, lng: 17.746902 },
    { lat: 47.750138, lng: 17.747139 },
    { lat: 47.750093, lng: 17.748155 },
    { lat: 47.750097, lng: 17.74845 },
    { lat: 47.75011, lng: 17.749411 },
    { lat: 47.7501, lng: 17.750148 },
    { lat: 47.750092, lng: 17.750625 },
    { lat: 47.750086, lng: 17.751105 },
    { lat: 47.750078, lng: 17.751688 },
    { lat: 47.750073, lng: 17.752013 },
    { lat: 47.750071, lng: 17.752139 },
    { lat: 47.750058, lng: 17.753067 },
    { lat: 47.750049, lng: 17.753614 },
    { lat: 47.750042, lng: 17.754139 },
    { lat: 47.750032, lng: 17.754866 },
    { lat: 47.750024, lng: 17.755398 },
    { lat: 47.75002, lng: 17.755675 },
    { lat: 47.750018, lng: 17.755774 },
    { lat: 47.750013, lng: 17.756159 },
    { lat: 47.750007, lng: 17.75658 },
    { lat: 47.750003, lng: 17.756836 },
    { lat: 47.749998, lng: 17.75715 },
    { lat: 47.749977, lng: 17.758628 },
    { lat: 47.749863, lng: 17.760097 },
    { lat: 47.749658, lng: 17.761544 },
    { lat: 47.749363, lng: 17.762956 },
    { lat: 47.748982, lng: 17.764322 },
    { lat: 47.748519, lng: 17.76563 },
    { lat: 47.747856, lng: 17.767046 },
    { lat: 47.746861, lng: 17.768709 },
    { lat: 47.745782, lng: 17.770254 },
    { lat: 47.744628, lng: 17.771674 },
    { lat: 47.743405, lng: 17.77296 },
    { lat: 47.74212, lng: 17.774106 },
    { lat: 47.741551, lng: 17.774783 },
    { lat: 47.741032, lng: 17.775542 },
    { lat: 47.740567, lng: 17.776375 },
    { lat: 47.740162, lng: 17.777274 },
    { lat: 47.739778, lng: 17.778548 },
    { lat: 47.739487, lng: 17.779874 },
    { lat: 47.739289, lng: 17.781238 },
    { lat: 47.73919, lng: 17.782625 },
    { lat: 47.739188, lng: 17.78402 },
    { lat: 47.739286, lng: 17.785409 },
    { lat: 47.739481, lng: 17.786773 },
    { lat: 47.739754, lng: 17.787952 },
    { lat: 47.73999, lng: 17.789149 },
    { lat: 47.740187, lng: 17.790361 },
    { lat: 47.74063, lng: 17.792008 },
    { lat: 47.741154, lng: 17.7936 },
    { lat: 47.7417578, lng: 17.7951338 },
    { lat: 47.742442, lng: 17.796584 },
    { lat: 47.743196, lng: 17.797959 },
    { lat: 47.743597, lng: 17.799114 },
    { lat: 47.743911, lng: 17.800326 },
    { lat: 47.744136, lng: 17.801581 },
    { lat: 47.744266, lng: 17.802865 },
    { lat: 47.744303, lng: 17.804163 },
    { lat: 47.744363, lng: 17.80564 },
    { lat: 47.744355, lng: 17.806783 },
    { lat: 47.745605, lng: 17.8071357 },
    { lat: 47.7467583, lng: 17.8067481 },
    { lat: 47.7472747, lng: 17.8079926 },
    { lat: 47.7476758, lng: 17.8084534 },
    { lat: 47.7479921, lng: 17.8087273 },
    { lat: 47.7482335, lng: 17.8087631 },
    { lat: 47.7480835, lng: 17.8077833 },
    { lat: 47.7487142, lng: 17.8077049 },
    { lat: 47.7518611, lng: 17.8075857 },
    { lat: 47.7519668, lng: 17.8084349 },
    { lat: 47.7527826, lng: 17.8082201 },
    { lat: 47.752872, lng: 17.8081966 },
    { lat: 47.7528574, lng: 17.8068145 },
    { lat: 47.7530014, lng: 17.8058607 },
    { lat: 47.7555944, lng: 17.805916 },
    { lat: 47.7598347, lng: 17.8073741 },
    { lat: 47.7651258, lng: 17.8109005 },
    { lat: 47.7669637, lng: 17.8123584 },
    { lat: 47.7663645, lng: 17.8147844 },
    { lat: 47.7658077, lng: 17.8194405 },
    { lat: 47.765723, lng: 17.820826 },
    { lat: 47.7659152, lng: 17.8225243 },
    { lat: 47.7665474, lng: 17.8255507 },
    { lat: 47.7678413, lng: 17.8250429 },
    { lat: 47.771567, lng: 17.8269482 },
    { lat: 47.7786789, lng: 17.8321288 },
    { lat: 47.7787109, lng: 17.8321563 },
    { lat: 47.7792575, lng: 17.8325424 },
  ],
  Modrany: [
    { lat: 47.8497745, lng: 18.3117953 },
    { lat: 47.8493974, lng: 18.3128116 },
    { lat: 47.8488682, lng: 18.313951 },
    { lat: 47.8481991, lng: 18.3150081 },
    { lat: 47.8475446, lng: 18.316168 },
    { lat: 47.8465521, lng: 18.3186871 },
    { lat: 47.8461052, lng: 18.3196499 },
    { lat: 47.8455324, lng: 18.3203423 },
    { lat: 47.8452269, lng: 18.3208576 },
    { lat: 47.8450625, lng: 18.3213544 },
    { lat: 47.8442977, lng: 18.3220058 },
    { lat: 47.8442322, lng: 18.3220257 },
    { lat: 47.8438471, lng: 18.3214869 },
    { lat: 47.8427218, lng: 18.322132 },
    { lat: 47.842032, lng: 18.3223388 },
    { lat: 47.841758, lng: 18.3225003 },
    { lat: 47.8415189, lng: 18.3227378 },
    { lat: 47.8410659, lng: 18.3228243 },
    { lat: 47.8404821, lng: 18.3233878 },
    { lat: 47.8397114, lng: 18.3244275 },
    { lat: 47.8351443, lng: 18.3177114 },
    { lat: 47.8350331, lng: 18.3179934 },
    { lat: 47.8280844, lng: 18.3285156 },
    { lat: 47.8169743, lng: 18.3456061 },
    { lat: 47.8116645, lng: 18.3373852 },
    { lat: 47.8116507, lng: 18.3373631 },
    { lat: 47.8116337, lng: 18.3373382 },
    { lat: 47.8109207, lng: 18.3362174 },
    { lat: 47.8097537, lng: 18.3376482 },
    { lat: 47.8094025, lng: 18.3372869 },
    { lat: 47.796581, lng: 18.3557399 },
    { lat: 47.7907991, lng: 18.3477531 },
    { lat: 47.7907726, lng: 18.3477438 },
    { lat: 47.790615, lng: 18.3475931 },
    { lat: 47.7895875, lng: 18.3461011 },
    { lat: 47.7878865, lng: 18.3438795 },
    { lat: 47.7859821, lng: 18.3411424 },
    { lat: 47.7848288, lng: 18.3426159 },
    { lat: 47.7829079, lng: 18.3452245 },
    { lat: 47.7820175, lng: 18.3466002 },
    { lat: 47.7812088, lng: 18.3477081 },
    { lat: 47.7805831, lng: 18.3482678 },
    { lat: 47.7801048, lng: 18.3483505 },
    { lat: 47.7795052, lng: 18.3502914 },
    { lat: 47.7789551, lng: 18.3516398 },
    { lat: 47.7782671, lng: 18.3531431 },
    { lat: 47.7763541, lng: 18.3569786 },
    { lat: 47.7760964, lng: 18.357623 },
    { lat: 47.7757197, lng: 18.358275 },
    { lat: 47.7752238, lng: 18.3587776 },
    { lat: 47.7743144, lng: 18.3592707 },
    { lat: 47.7736509, lng: 18.3598034 },
    { lat: 47.7723922, lng: 18.361329 },
    { lat: 47.774434, lng: 18.3638146 },
    { lat: 47.7747801, lng: 18.3640271 },
    { lat: 47.7751825, lng: 18.3643746 },
    { lat: 47.7768516, lng: 18.3660237 },
    { lat: 47.7790212, lng: 18.3695655 },
    { lat: 47.7790418, lng: 18.3695285 },
    { lat: 47.7790681, lng: 18.369575 },
    { lat: 47.7765028, lng: 18.3735355 },
    { lat: 47.7764306, lng: 18.3736365 },
    { lat: 47.7779503, lng: 18.3763874 },
    { lat: 47.7783455, lng: 18.3768096 },
    { lat: 47.7789473, lng: 18.3772521 },
    { lat: 47.7798943, lng: 18.3784869 },
    { lat: 47.7816489, lng: 18.3811266 },
    { lat: 47.7823315, lng: 18.3818215 },
    { lat: 47.7835564, lng: 18.3825698 },
    { lat: 47.7856508, lng: 18.3836887 },
    { lat: 47.7875313, lng: 18.3847948 },
    { lat: 47.7881841, lng: 18.385402 },
    { lat: 47.7891366, lng: 18.3861207 },
    { lat: 47.7895503, lng: 18.3866113 },
    { lat: 47.7907542, lng: 18.3883805 },
    { lat: 47.7911166, lng: 18.3886045 },
    { lat: 47.7933792, lng: 18.3888895 },
    { lat: 47.7937811, lng: 18.3890564 },
    { lat: 47.7941104, lng: 18.389072 },
    { lat: 47.7956028, lng: 18.3885514 },
    { lat: 47.7975816, lng: 18.3914715 },
    { lat: 47.79837, lng: 18.3929149 },
    { lat: 47.7991152, lng: 18.394156 },
    { lat: 47.7995243, lng: 18.3947448 },
    { lat: 47.800104, lng: 18.3953565 },
    { lat: 47.8008977, lng: 18.3958673 },
    { lat: 47.8022885, lng: 18.3970316 },
    { lat: 47.802317, lng: 18.3970603 },
    { lat: 47.8023591, lng: 18.3969859 },
    { lat: 47.8029457, lng: 18.3959915 },
    { lat: 47.8037803, lng: 18.3949526 },
    { lat: 47.8047391, lng: 18.393482 },
    { lat: 47.80665, lng: 18.3909562 },
    { lat: 47.8071747, lng: 18.3903628 },
    { lat: 47.8092028, lng: 18.3875388 },
    { lat: 47.8096142, lng: 18.3870521 },
    { lat: 47.8108515, lng: 18.3860787 },
    { lat: 47.8115446, lng: 18.3854286 },
    { lat: 47.8124093, lng: 18.3838317 },
    { lat: 47.8130243, lng: 18.3830018 },
    { lat: 47.8135117, lng: 18.3825634 },
    { lat: 47.8139691, lng: 18.3819561 },
    { lat: 47.814788, lng: 18.3813827 },
    { lat: 47.8157552, lng: 18.3810094 },
    { lat: 47.8171484, lng: 18.3807304 },
    { lat: 47.817701, lng: 18.380824 },
    { lat: 47.818315, lng: 18.3803556 },
    { lat: 47.821051, lng: 18.3769447 },
    { lat: 47.8216601, lng: 18.3764122 },
    { lat: 47.8233289, lng: 18.3746497 },
    { lat: 47.8270084, lng: 18.3710139 },
    { lat: 47.8288991, lng: 18.3686133 },
    { lat: 47.8297016, lng: 18.3679461 },
    { lat: 47.8302556, lng: 18.3672557 },
    { lat: 47.8314732, lng: 18.3662325 },
    { lat: 47.8322743, lng: 18.3654384 },
    { lat: 47.8326538, lng: 18.3651901 },
    { lat: 47.8341175, lng: 18.3648582 },
    { lat: 47.8348439, lng: 18.3643019 },
    { lat: 47.8356815, lng: 18.3640736 },
    { lat: 47.8362819, lng: 18.3639967 },
    { lat: 47.8372824, lng: 18.3640913 },
    { lat: 47.8377189, lng: 18.3640304 },
    { lat: 47.8382865, lng: 18.3640538 },
    { lat: 47.8397604, lng: 18.3636253 },
    { lat: 47.8402505, lng: 18.3633389 },
    { lat: 47.840716, lng: 18.3634289 },
    { lat: 47.8424804, lng: 18.3633849 },
    { lat: 47.8434279, lng: 18.3629067 },
    { lat: 47.8437514, lng: 18.3623687 },
    { lat: 47.8443205, lng: 18.3619217 },
    { lat: 47.8449665, lng: 18.3616953 },
    { lat: 47.8458592, lng: 18.3610484 },
    { lat: 47.846468, lng: 18.3601699 },
    { lat: 47.8471368, lng: 18.3589604 },
    { lat: 47.8472952, lng: 18.3585134 },
    { lat: 47.8474828, lng: 18.356937 },
    { lat: 47.847573, lng: 18.3554931 },
    { lat: 47.8478065, lng: 18.3544651 },
    { lat: 47.8480023, lng: 18.3540028 },
    { lat: 47.8482591, lng: 18.3531265 },
    { lat: 47.8486088, lng: 18.3524512 },
    { lat: 47.8487717, lng: 18.3520257 },
    { lat: 47.8491591, lng: 18.3515189 },
    { lat: 47.8495608, lng: 18.3511637 },
    { lat: 47.8499763, lng: 18.3505363 },
    { lat: 47.8514113, lng: 18.3478127 },
    { lat: 47.8518879, lng: 18.3462611 },
    { lat: 47.8524611, lng: 18.3447824 },
    { lat: 47.8527027, lng: 18.3444891 },
    { lat: 47.8528318, lng: 18.344241 },
    { lat: 47.8532023, lng: 18.3431215 },
    { lat: 47.853763, lng: 18.3418381 },
    { lat: 47.8543337, lng: 18.3412258 },
    { lat: 47.8550111, lng: 18.3406614 },
    { lat: 47.8558692, lng: 18.3397454 },
    { lat: 47.8565117, lng: 18.3387116 },
    { lat: 47.8567645, lng: 18.3381207 },
    { lat: 47.8569478, lng: 18.3378331 },
    { lat: 47.8572243, lng: 18.3375016 },
    { lat: 47.8579521, lng: 18.3370285 },
    { lat: 47.8587469, lng: 18.3359124 },
    { lat: 47.8592795, lng: 18.3349116 },
    { lat: 47.8595332, lng: 18.3345532 },
    { lat: 47.8598431, lng: 18.3334039 },
    { lat: 47.8602704, lng: 18.3320598 },
    { lat: 47.8602667, lng: 18.3318375 },
    { lat: 47.8600543, lng: 18.3313789 },
    { lat: 47.8598589, lng: 18.3305095 },
    { lat: 47.8593252, lng: 18.3290324 },
    { lat: 47.8587275, lng: 18.3263015 },
    { lat: 47.8579289, lng: 18.324592 },
    { lat: 47.8578099, lng: 18.3241697 },
    { lat: 47.8575949, lng: 18.323019 },
    { lat: 47.8575862, lng: 18.3221008 },
    { lat: 47.8574712, lng: 18.3214324 },
    { lat: 47.8574843, lng: 18.3208156 },
    { lat: 47.8576463, lng: 18.3206729 },
    { lat: 47.85846, lng: 18.319513 },
    { lat: 47.8588417, lng: 18.3192261 },
    { lat: 47.8590007, lng: 18.3190275 },
    { lat: 47.8592369, lng: 18.3185616 },
    { lat: 47.859205, lng: 18.3185003 },
    { lat: 47.8592303, lng: 18.3184679 },
    { lat: 47.859105, lng: 18.3180348 },
    { lat: 47.8589067, lng: 18.317019 },
    { lat: 47.8584246, lng: 18.3160097 },
    { lat: 47.8578658, lng: 18.3152382 },
    { lat: 47.8572234, lng: 18.3139169 },
    { lat: 47.8569603, lng: 18.3134824 },
    { lat: 47.856503, lng: 18.3127851 },
    { lat: 47.8559916, lng: 18.312172 },
    { lat: 47.8556584, lng: 18.3119494 },
    { lat: 47.855313, lng: 18.311851 },
    { lat: 47.8548413, lng: 18.3114766 },
    { lat: 47.8546982, lng: 18.3114593 },
    { lat: 47.8537912, lng: 18.3116594 },
    { lat: 47.8524655, lng: 18.3114827 },
    { lat: 47.8516413, lng: 18.3117288 },
    { lat: 47.8498635, lng: 18.3117735 },
    { lat: 47.8497745, lng: 18.3117953 },
  ],
  Kolárovo: [
    { lat: 47.97075, lng: 18.005925 },
    { lat: 47.9709259, lng: 18.0066386 },
    { lat: 47.9677423, lng: 18.0087454 },
    { lat: 47.9665588, lng: 18.0098453 },
    { lat: 47.9655809, lng: 18.0108711 },
    { lat: 47.9620549, lng: 18.0155544 },
    { lat: 47.9605589, lng: 18.01797 },
    { lat: 47.9600779, lng: 18.0183054 },
    { lat: 47.9591141, lng: 18.0189082 },
    { lat: 47.9588651, lng: 18.0189927 },
    { lat: 47.9584342, lng: 18.0190165 },
    { lat: 47.9577527, lng: 18.0189237 },
    { lat: 47.957489, lng: 18.0187432 },
    { lat: 47.957089, lng: 18.0182288 },
    { lat: 47.955956, lng: 18.015642 },
    { lat: 47.95628, lng: 18.017233 },
    { lat: 47.956315, lng: 18.01741 },
    { lat: 47.956325, lng: 18.017456 },
    { lat: 47.956369, lng: 18.017679 },
    { lat: 47.956457, lng: 18.018124 },
    { lat: 47.956544, lng: 18.018867 },
    { lat: 47.956548, lng: 18.018903 },
    { lat: 47.956615, lng: 18.019349 },
    { lat: 47.956628, lng: 18.019424 },
    { lat: 47.956655, lng: 18.019605 },
    { lat: 47.956662, lng: 18.019646 },
    { lat: 47.956713, lng: 18.01999 },
    { lat: 47.956721, lng: 18.020045 },
    { lat: 47.956731, lng: 18.020111 },
    { lat: 47.956739, lng: 18.020173 },
    { lat: 47.956747, lng: 18.020219 },
    { lat: 47.95681, lng: 18.020629 },
    { lat: 47.956821, lng: 18.020709 },
    { lat: 47.956848, lng: 18.020871 },
    { lat: 47.956857, lng: 18.020932 },
    { lat: 47.956967, lng: 18.021611 },
    { lat: 47.957103, lng: 18.022144 },
    { lat: 47.957293, lng: 18.022654 },
    { lat: 47.957457, lng: 18.02291 },
    { lat: 47.95753, lng: 18.023026 },
    { lat: 47.957539, lng: 18.023038 },
    { lat: 47.957633, lng: 18.023183 },
    { lat: 47.957771, lng: 18.023392 },
    { lat: 47.957791, lng: 18.023422 },
    { lat: 47.957832, lng: 18.023483 },
    { lat: 47.957848, lng: 18.023505 },
    { lat: 47.957977, lng: 18.023697 },
    { lat: 47.95802, lng: 18.023702 },
    { lat: 47.958032, lng: 18.023703 },
    { lat: 47.958146, lng: 18.023715 },
    { lat: 47.958778, lng: 18.023784 },
    { lat: 47.958879, lng: 18.023795 },
    { lat: 47.958884, lng: 18.023796 },
    { lat: 47.958991, lng: 18.023805 },
    { lat: 47.959002, lng: 18.023806 },
    { lat: 47.959134, lng: 18.023817 },
    { lat: 47.959145, lng: 18.023818 },
    { lat: 47.959179, lng: 18.02382 },
    { lat: 47.959184, lng: 18.02382 },
    { lat: 47.959282, lng: 18.023825 },
    { lat: 47.959359, lng: 18.023829 },
    { lat: 47.959363, lng: 18.023829 },
    { lat: 47.959414, lng: 18.02383 },
    { lat: 47.959426, lng: 18.023831 },
    { lat: 47.959553, lng: 18.023833 },
    { lat: 47.959617, lng: 18.023834 },
    { lat: 47.959869, lng: 18.023922 },
    { lat: 47.959879, lng: 18.023925 },
    { lat: 47.959934, lng: 18.023952 },
    { lat: 47.960012, lng: 18.024053 },
    { lat: 47.960054, lng: 18.02411 },
    { lat: 47.960119, lng: 18.024195 },
    { lat: 47.960149, lng: 18.024234 },
    { lat: 47.960226, lng: 18.024334 },
    { lat: 47.960254, lng: 18.024372 },
    { lat: 47.960308, lng: 18.024443 },
    { lat: 47.960326, lng: 18.024468 },
    { lat: 47.96045, lng: 18.02463 },
    { lat: 47.960467, lng: 18.024654 },
    { lat: 47.96066, lng: 18.024908 },
    { lat: 47.960689, lng: 18.024875 },
    { lat: 47.960891, lng: 18.024806 },
    { lat: 47.960908, lng: 18.0248 },
    { lat: 47.961207, lng: 18.024667 },
    { lat: 47.961282, lng: 18.024627 },
    { lat: 47.961348, lng: 18.024592 },
    { lat: 47.96173, lng: 18.024389 },
    { lat: 47.961852, lng: 18.024325 },
    { lat: 47.961907, lng: 18.024295 },
    { lat: 47.962309, lng: 18.024025 },
    { lat: 47.962401, lng: 18.023964 },
    { lat: 47.962474, lng: 18.023915 },
    { lat: 47.962498, lng: 18.023898 },
    { lat: 47.962529, lng: 18.023877 },
    { lat: 47.962551, lng: 18.023861 },
    { lat: 47.962656, lng: 18.023781 },
    { lat: 47.962722, lng: 18.02373 },
    { lat: 47.962818, lng: 18.023666 },
    { lat: 47.96309, lng: 18.023483 },
    { lat: 47.963116, lng: 18.023465 },
    { lat: 47.963135, lng: 18.023453 },
    { lat: 47.963203, lng: 18.02347 },
    { lat: 47.963218, lng: 18.023449 },
    { lat: 47.96399, lng: 18.022465 },
    { lat: 47.964848, lng: 18.021481 },
    { lat: 47.965629, lng: 18.02068 },
    { lat: 47.966395, lng: 18.020476 },
    { lat: 47.966524, lng: 18.020441 },
    { lat: 47.966575, lng: 18.020428 },
    { lat: 47.966589, lng: 18.020425 },
    { lat: 47.966604, lng: 18.020421 },
    { lat: 47.966736, lng: 18.020385 },
    { lat: 47.966835, lng: 18.020359 },
    { lat: 47.96699, lng: 18.020319 },
    { lat: 47.967005, lng: 18.020315 },
    { lat: 47.967141, lng: 18.020283 },
    { lat: 47.96715, lng: 18.020282 },
    { lat: 47.967204, lng: 18.020269 },
    { lat: 47.967272, lng: 18.020254 },
    { lat: 47.968251, lng: 18.020035 },
    { lat: 47.969064, lng: 18.019899 },
    { lat: 47.969362, lng: 18.019849 },
    { lat: 47.969569, lng: 18.019814 },
    { lat: 47.969806, lng: 18.019682 },
    { lat: 47.96982, lng: 18.019674 },
    { lat: 47.969846, lng: 18.019659 },
    { lat: 47.970202, lng: 18.01946 },
    { lat: 47.970472, lng: 18.019309 },
    { lat: 47.970985, lng: 18.019023 },
    { lat: 47.971362, lng: 18.018757 },
    { lat: 47.972081, lng: 18.018251 },
    { lat: 47.972387, lng: 18.018036 },
    { lat: 47.972402, lng: 18.018025 },
    { lat: 47.972408, lng: 18.01803 },
    { lat: 47.972483, lng: 18.017911 },
    { lat: 47.972504, lng: 18.01788 },
    { lat: 47.973074, lng: 18.016978 },
    { lat: 47.973398, lng: 18.016464 },
    { lat: 47.973368, lng: 18.016233 },
    { lat: 47.973457, lng: 18.016139 },
    { lat: 47.973511, lng: 18.016083 },
    { lat: 47.973568, lng: 18.016023 },
    { lat: 47.973785, lng: 18.015795 },
    { lat: 47.97382, lng: 18.015758 },
    { lat: 47.973928, lng: 18.015749 },
    { lat: 47.974103, lng: 18.015734 },
    { lat: 47.974413, lng: 18.015708 },
    { lat: 47.974543, lng: 18.015696 },
    { lat: 47.974607, lng: 18.015725 },
    { lat: 47.974651, lng: 18.015746 },
    { lat: 47.97487, lng: 18.015845 },
    { lat: 47.975419, lng: 18.016094 },
    { lat: 47.975711, lng: 18.016227 },
    { lat: 47.975849, lng: 18.016203 },
    { lat: 47.976824, lng: 18.016036 },
    { lat: 47.977574, lng: 18.015279 },
    { lat: 47.977824, lng: 18.015027 },
    { lat: 47.977875, lng: 18.014977 },
    { lat: 47.977944, lng: 18.014907 },
    { lat: 47.978162, lng: 18.014686 },
    { lat: 47.978192, lng: 18.014657 },
    { lat: 47.97929, lng: 18.013299 },
    { lat: 47.979342, lng: 18.013254 },
    { lat: 47.980554, lng: 18.012213 },
    { lat: 47.980565, lng: 18.012203 },
    { lat: 47.980625, lng: 18.012077 },
    { lat: 47.98088, lng: 18.01154 },
    { lat: 47.981057, lng: 18.011169 },
    { lat: 47.981381, lng: 18.010489 },
    { lat: 47.981401, lng: 18.010448 },
    { lat: 47.981387, lng: 18.009975 },
    { lat: 47.981386, lng: 18.009959 },
    { lat: 47.981386, lng: 18.009944 },
    { lat: 47.981446, lng: 18.009681 },
    { lat: 47.981449, lng: 18.009672 },
    { lat: 47.981451, lng: 18.009663 },
    { lat: 47.981522, lng: 18.009351 },
    { lat: 47.981535, lng: 18.009343 },
    { lat: 47.981541, lng: 18.00934 },
    { lat: 47.981595, lng: 18.009297 },
    { lat: 47.981526, lng: 18.009239 },
    { lat: 47.981373, lng: 18.009354 },
    { lat: 47.981361, lng: 18.009363 },
    { lat: 47.981303, lng: 18.009399 },
    { lat: 47.981253, lng: 18.009401 },
    { lat: 47.9812, lng: 18.009402 },
    { lat: 47.981147, lng: 18.009403 },
    { lat: 47.981126, lng: 18.009404 },
    { lat: 47.981055, lng: 18.009368 },
    { lat: 47.980884, lng: 18.009283 },
    { lat: 47.980689, lng: 18.009184 },
    { lat: 47.980588, lng: 18.009134 },
    { lat: 47.980507, lng: 18.00911 },
    { lat: 47.980455, lng: 18.009095 },
    { lat: 47.980442, lng: 18.009092 },
    { lat: 47.980375, lng: 18.009096 },
    { lat: 47.980325, lng: 18.009098 },
    { lat: 47.980262, lng: 18.009102 },
    { lat: 47.980108, lng: 18.009149 },
    { lat: 47.98006, lng: 18.009165 },
    { lat: 47.980054, lng: 18.00917 },
    { lat: 47.979842, lng: 18.009322 },
    { lat: 47.979806, lng: 18.009348 },
    { lat: 47.97973, lng: 18.009338 },
    { lat: 47.979677, lng: 18.00933 },
    { lat: 47.979667, lng: 18.009322 },
    { lat: 47.979623, lng: 18.009284 },
    { lat: 47.979609, lng: 18.009272 },
    { lat: 47.979575, lng: 18.009225 },
    { lat: 47.979555, lng: 18.0092 },
    { lat: 47.979552, lng: 18.009184 },
    { lat: 47.979519, lng: 18.009002 },
    { lat: 47.979516, lng: 18.008989 },
    { lat: 47.979515, lng: 18.008978 },
    { lat: 47.979489, lng: 18.008816 },
    { lat: 47.979472, lng: 18.008709 },
    { lat: 47.979456, lng: 18.008608 },
    { lat: 47.979435, lng: 18.008556 },
    { lat: 47.979367, lng: 18.008388 },
    { lat: 47.979354, lng: 18.008356 },
    { lat: 47.979348, lng: 18.008347 },
    { lat: 47.979304, lng: 18.008277 },
    { lat: 47.979173, lng: 18.00807 },
    { lat: 47.979152, lng: 18.008036 },
    { lat: 47.979074, lng: 18.007937 },
    { lat: 47.979052, lng: 18.00792 },
    { lat: 47.97893, lng: 18.007825 },
    { lat: 47.978923, lng: 18.007823 },
    { lat: 47.97884, lng: 18.007799 },
    { lat: 47.978814, lng: 18.007792 },
    { lat: 47.978657, lng: 18.007823 },
    { lat: 47.978433, lng: 18.007868 },
    { lat: 47.97842, lng: 18.007871 },
    { lat: 47.978281, lng: 18.007946 },
    { lat: 47.978148, lng: 18.008017 },
    { lat: 47.978139, lng: 18.008022 },
    { lat: 47.977958, lng: 18.008095 },
    { lat: 47.977806, lng: 18.008156 },
    { lat: 47.977602, lng: 18.008238 },
    { lat: 47.977581, lng: 18.008251 },
    { lat: 47.977487, lng: 18.008304 },
    { lat: 47.977432, lng: 18.008336 },
    { lat: 47.977286, lng: 18.008419 },
    { lat: 47.977215, lng: 18.00848 },
    { lat: 47.977088, lng: 18.00859 },
    { lat: 47.977079, lng: 18.008599 },
    { lat: 47.977021, lng: 18.008666 },
    { lat: 47.976979, lng: 18.008713 },
    { lat: 47.976875, lng: 18.008834 },
    { lat: 47.976837, lng: 18.008878 },
    { lat: 47.9768, lng: 18.008818 },
    { lat: 47.976741, lng: 18.008707 },
    { lat: 47.976369, lng: 18.00693 },
    { lat: 47.976146, lng: 18.005956 },
    { lat: 47.976006, lng: 18.005347 },
    { lat: 47.975891, lng: 18.005169 },
    { lat: 47.975729, lng: 18.004924 },
    { lat: 47.975689, lng: 18.00486 },
    { lat: 47.975658, lng: 18.004811 },
    { lat: 47.975647, lng: 18.004793 },
    { lat: 47.9756, lng: 18.004728 },
    { lat: 47.97557, lng: 18.004681 },
    { lat: 47.975396, lng: 18.004408 },
    { lat: 47.975372, lng: 18.004366 },
    { lat: 47.975271, lng: 18.00418 },
    { lat: 47.974987, lng: 18.00375 },
    { lat: 47.974948, lng: 18.003689 },
    { lat: 47.974838, lng: 18.003541 },
    { lat: 47.974812, lng: 18.003507 },
    { lat: 47.974659, lng: 18.003257 },
    { lat: 47.974616, lng: 18.003187 },
    { lat: 47.974599, lng: 18.003161 },
    { lat: 47.974584, lng: 18.003135 },
    { lat: 47.97457, lng: 18.003111 },
    { lat: 47.974418, lng: 18.002957 },
    { lat: 47.973774, lng: 18.002307 },
    { lat: 47.973418, lng: 18.003103 },
    { lat: 47.973345, lng: 18.003266 },
    { lat: 47.973333, lng: 18.003289 },
    { lat: 47.972973, lng: 18.003963 },
    { lat: 47.972931, lng: 18.004042 },
    { lat: 47.972702, lng: 18.004392 },
    { lat: 47.972555, lng: 18.00461 },
    { lat: 47.972541, lng: 18.004626 },
    { lat: 47.972391, lng: 18.004809 },
    { lat: 47.972362, lng: 18.004835 },
    { lat: 47.97204, lng: 18.005124 },
    { lat: 47.971937, lng: 18.005168 },
    { lat: 47.971912, lng: 18.005178 },
    { lat: 47.971323, lng: 18.005601 },
    { lat: 47.971298, lng: 18.005609 },
    { lat: 47.971108, lng: 18.005668 },
    { lat: 47.971069, lng: 18.005696 },
    { lat: 47.970759, lng: 18.005919 },
    { lat: 47.97075, lng: 18.005925 },
  ],
  KolárovoExt: [
    { lat: 47.965495, lng: 17.962154 },
    { lat: 47.965496, lng: 17.962148 },
    { lat: 47.96567, lng: 17.961609 },
    { lat: 47.965687, lng: 17.961555 },
    { lat: 47.965807, lng: 17.961183 },
    { lat: 47.965927, lng: 17.960812 },
    { lat: 47.965948, lng: 17.960746 },
    { lat: 47.965967, lng: 17.960686 },
    { lat: 47.966051, lng: 17.960422 },
    { lat: 47.966113, lng: 17.960232 },
    { lat: 47.966132, lng: 17.960171 },
    { lat: 47.966151, lng: 17.960114 },
    { lat: 47.966169, lng: 17.960057 },
    { lat: 47.966205, lng: 17.959945 },
    { lat: 47.966243, lng: 17.959828 },
    { lat: 47.966305, lng: 17.959636 },
    { lat: 47.966476, lng: 17.959034 },
    { lat: 47.966573, lng: 17.958693 },
    { lat: 47.966606, lng: 17.958579 },
    { lat: 47.966619, lng: 17.958534 },
    { lat: 47.966661, lng: 17.958382 },
    { lat: 47.966764, lng: 17.958021 },
    { lat: 47.966822, lng: 17.957813 },
    { lat: 47.966874, lng: 17.957627 },
    { lat: 47.966895, lng: 17.957555 },
    { lat: 47.966911, lng: 17.957499 },
    { lat: 47.966915, lng: 17.957482 },
    { lat: 47.966936, lng: 17.957409 },
    { lat: 47.966991, lng: 17.957208 },
    { lat: 47.967077, lng: 17.956904 },
    { lat: 47.967118, lng: 17.956755 },
    { lat: 47.967124, lng: 17.956736 },
    { lat: 47.967133, lng: 17.956703 },
    { lat: 47.967144, lng: 17.95666 },
    { lat: 47.967164, lng: 17.956592 },
    { lat: 47.967181, lng: 17.956527 },
    { lat: 47.967189, lng: 17.956502 },
    { lat: 47.967319, lng: 17.956037 },
    { lat: 47.967369, lng: 17.95586 },
    { lat: 47.967411, lng: 17.955705 },
    { lat: 47.967478, lng: 17.955466 },
    { lat: 47.967506, lng: 17.955371 },
    { lat: 47.967517, lng: 17.955334 },
    { lat: 47.967682, lng: 17.954763 },
    { lat: 47.967716, lng: 17.954649 },
    { lat: 47.96783, lng: 17.95425 },
    { lat: 47.967881, lng: 17.954076 },
    { lat: 47.96799, lng: 17.9537 },
    { lat: 47.968051, lng: 17.953489 },
    { lat: 47.968077, lng: 17.953401 },
    { lat: 47.968164, lng: 17.953102 },
    { lat: 47.968181, lng: 17.953038 },
    { lat: 47.968209, lng: 17.952943 },
    { lat: 47.968245, lng: 17.952818 },
    { lat: 47.968263, lng: 17.952755 },
    { lat: 47.968279, lng: 17.9527 },
    { lat: 47.96831, lng: 17.952592 },
    { lat: 47.96835, lng: 17.952454 },
    { lat: 47.968369, lng: 17.952385 },
    { lat: 47.96841, lng: 17.952221 },
    { lat: 47.968489, lng: 17.951909 },
    { lat: 47.968524, lng: 17.951769 },
    { lat: 47.968555, lng: 17.951649 },
    { lat: 47.968744, lng: 17.951111 },
    { lat: 47.968842, lng: 17.950832 },
    { lat: 47.968853, lng: 17.950802 },
    { lat: 47.968953, lng: 17.950517 },
    { lat: 47.968978, lng: 17.950444 },
    { lat: 47.969021, lng: 17.950322 },
    { lat: 47.969082, lng: 17.950151 },
    { lat: 47.969158, lng: 17.949934 },
    { lat: 47.969191, lng: 17.949841 },
    { lat: 47.9692, lng: 17.94981 },
    { lat: 47.969237, lng: 17.949691 },
    { lat: 47.969343, lng: 17.949353 },
    { lat: 47.96938, lng: 17.949235 },
    { lat: 47.969971, lng: 17.947351 },
    { lat: 47.970042, lng: 17.947124 },
    { lat: 47.970044, lng: 17.947117 },
    { lat: 47.970065, lng: 17.947052 },
    { lat: 47.970122, lng: 17.946842 },
    { lat: 47.970264, lng: 17.946323 },
    { lat: 47.970297, lng: 17.946205 },
    { lat: 47.970419, lng: 17.945758 },
    { lat: 47.97046, lng: 17.945606 },
    { lat: 47.970536, lng: 17.945331 },
    { lat: 47.970564, lng: 17.945229 },
    { lat: 47.970571, lng: 17.945202 },
    { lat: 47.97058, lng: 17.945168 },
    { lat: 47.970582, lng: 17.945161 },
    { lat: 47.970604, lng: 17.945077 },
    { lat: 47.970607, lng: 17.945066 },
    { lat: 47.970617, lng: 17.945038 },
    { lat: 47.970619, lng: 17.945032 },
    { lat: 47.970636, lng: 17.94498 },
    { lat: 47.97064, lng: 17.944968 },
    { lat: 47.970644, lng: 17.94495 },
    { lat: 47.970681, lng: 17.944816 },
    { lat: 47.970717, lng: 17.944687 },
    { lat: 47.970753, lng: 17.944553 },
    { lat: 47.970776, lng: 17.944473 },
    { lat: 47.970788, lng: 17.94443 },
    { lat: 47.970865, lng: 17.944149 },
    { lat: 47.970943, lng: 17.94387 },
    { lat: 47.971062, lng: 17.943439 },
    { lat: 47.971123, lng: 17.943219 },
    { lat: 47.971212, lng: 17.942899 },
    { lat: 47.971302, lng: 17.942569 },
    { lat: 47.97132, lng: 17.942506 },
    { lat: 47.971355, lng: 17.942378 },
    { lat: 47.971498, lng: 17.941863 },
    { lat: 47.971612, lng: 17.94155 },
    { lat: 47.971709, lng: 17.941285 },
    { lat: 47.971745, lng: 17.941186 },
    { lat: 47.971906, lng: 17.940745 },
    { lat: 47.971923, lng: 17.9407 },
    { lat: 47.972013, lng: 17.940454 },
    { lat: 47.9722, lng: 17.939943 },
    { lat: 47.972294, lng: 17.939683 },
    { lat: 47.972341, lng: 17.939555 },
    { lat: 47.972349, lng: 17.939535 },
    { lat: 47.972681, lng: 17.938626 },
    { lat: 47.972684, lng: 17.938607 },
    { lat: 47.972718, lng: 17.938432 },
    { lat: 47.972798, lng: 17.938006 },
    { lat: 47.972867, lng: 17.937638 },
    { lat: 47.973059, lng: 17.936618 },
    { lat: 47.973084, lng: 17.936478 },
    { lat: 47.973091, lng: 17.936442 },
    { lat: 47.973104, lng: 17.936381 },
    { lat: 47.973139, lng: 17.936211 },
    { lat: 47.973301, lng: 17.935418 },
    { lat: 47.97331, lng: 17.935376 },
    { lat: 47.973357, lng: 17.935148 },
    { lat: 47.973379, lng: 17.935043 },
    { lat: 47.973398, lng: 17.934948 },
    { lat: 47.973422, lng: 17.934829 },
    { lat: 47.973556, lng: 17.934173 },
    { lat: 47.973559, lng: 17.934155 },
    { lat: 47.973601, lng: 17.934079 },
    { lat: 47.973845, lng: 17.933631 },
    { lat: 47.973891, lng: 17.933547 },
    { lat: 47.973939, lng: 17.933457 },
    { lat: 47.974151, lng: 17.933069 },
    { lat: 47.974543, lng: 17.93235 },
    { lat: 47.974579, lng: 17.932282 },
    { lat: 47.974645, lng: 17.932162 },
    { lat: 47.974701, lng: 17.932062 },
    { lat: 47.974705, lng: 17.932053 },
    { lat: 47.974718, lng: 17.93203 },
    { lat: 47.975001, lng: 17.931509 },
    { lat: 47.975236, lng: 17.931074 },
    { lat: 47.975308, lng: 17.930941 },
    { lat: 47.975319, lng: 17.93092 },
    { lat: 47.975399, lng: 17.930772 },
    { lat: 47.975424, lng: 17.930728 },
    { lat: 47.975425, lng: 17.930726 },
    { lat: 47.975476, lng: 17.930619 },
    { lat: 47.975546, lng: 17.930477 },
    { lat: 47.975588, lng: 17.930388 },
    { lat: 47.975617, lng: 17.930332 },
    { lat: 47.975644, lng: 17.930275 },
    { lat: 47.97586, lng: 17.92983 },
    { lat: 47.975896, lng: 17.929756 },
    { lat: 47.976289, lng: 17.928948 },
    { lat: 47.976297, lng: 17.928932 },
    { lat: 47.976346, lng: 17.928832 },
    { lat: 47.976388, lng: 17.928745 },
    { lat: 47.976756, lng: 17.927987 },
    { lat: 47.977023, lng: 17.927586 },
    { lat: 47.977892, lng: 17.926277 },
    { lat: 47.97803, lng: 17.926052 },
    { lat: 47.978435, lng: 17.925391 },
    { lat: 47.978942, lng: 17.924563 },
    { lat: 47.97927, lng: 17.924029 },
    { lat: 47.979287, lng: 17.924001 },
    { lat: 47.979387, lng: 17.923742 },
    { lat: 47.979441, lng: 17.923605 },
    { lat: 47.979658, lng: 17.923044 },
    { lat: 47.979674, lng: 17.923004 },
    { lat: 47.97969, lng: 17.922962 },
    { lat: 47.980132, lng: 17.921821 },
    { lat: 47.980159, lng: 17.921752 },
    { lat: 47.980455, lng: 17.920984 },
    { lat: 47.980474, lng: 17.920937 },
    { lat: 47.980501, lng: 17.920866 },
    { lat: 47.9805, lng: 17.920769 },
    { lat: 47.980498, lng: 17.920703 },
    { lat: 47.980498, lng: 17.920687 },
    { lat: 47.980497, lng: 17.92059 },
    { lat: 47.980496, lng: 17.920496 },
    { lat: 47.980468, lng: 17.919507 },
    { lat: 47.980455, lng: 17.919057 },
    { lat: 47.980441, lng: 17.918564 },
    { lat: 47.980427, lng: 17.918052 },
    { lat: 47.980408, lng: 17.917538 },
    { lat: 47.980394, lng: 17.917176 },
    { lat: 47.980382, lng: 17.91685 },
    { lat: 47.980365, lng: 17.916381 },
    { lat: 47.980351, lng: 17.91602 },
    { lat: 47.980339, lng: 17.915705 },
    { lat: 47.980334, lng: 17.915582 },
    { lat: 47.980329, lng: 17.915421 },
    { lat: 47.980307, lng: 17.91483 },
    { lat: 47.980303, lng: 17.914767 },
    { lat: 47.980298, lng: 17.914672 },
    { lat: 47.980277, lng: 17.914307 },
    { lat: 47.980273, lng: 17.914235 },
    { lat: 47.980242, lng: 17.913688 },
    { lat: 47.980232, lng: 17.913518 },
    { lat: 47.980215, lng: 17.913212 },
    { lat: 47.9802, lng: 17.912945 },
    { lat: 47.9802, lng: 17.912738 },
    { lat: 47.9802, lng: 17.912293 },
    { lat: 47.9802, lng: 17.911663 },
    { lat: 47.980199, lng: 17.911582 },
    { lat: 47.979955, lng: 17.910872 },
    { lat: 47.979904, lng: 17.910723 },
    { lat: 47.979853, lng: 17.910576 },
    { lat: 47.979849, lng: 17.910565 },
    { lat: 47.979795, lng: 17.910415 },
    { lat: 47.979794, lng: 17.910412 },
    { lat: 47.979733, lng: 17.910279 },
    { lat: 47.97973, lng: 17.910276 },
    { lat: 47.979717, lng: 17.910262 },
    { lat: 47.979641, lng: 17.910184 },
    { lat: 47.979572, lng: 17.910113 },
    { lat: 47.979442, lng: 17.909981 },
    { lat: 47.979439, lng: 17.909976 },
    { lat: 47.979434, lng: 17.909974 },
    { lat: 47.979375, lng: 17.909934 },
    { lat: 47.979353, lng: 17.909918 },
    { lat: 47.979343, lng: 17.909913 },
    { lat: 47.979273, lng: 17.909883 },
    { lat: 47.979192, lng: 17.909906 },
    { lat: 47.979113, lng: 17.909855 },
    { lat: 47.97909, lng: 17.909696 },
    { lat: 47.979082, lng: 17.909646 },
    { lat: 47.979082, lng: 17.909628 },
    { lat: 47.979072, lng: 17.909188 },
    { lat: 47.97907, lng: 17.909077 },
    { lat: 47.979078, lng: 17.908843 },
    { lat: 47.97908, lng: 17.908836 },
    { lat: 47.979122, lng: 17.908699 },
    { lat: 47.979252, lng: 17.908418 },
    { lat: 47.979253, lng: 17.908316 },
    { lat: 47.979222, lng: 17.908238 },
    { lat: 47.979192, lng: 17.908165 },
    { lat: 47.979181, lng: 17.907987 },
    { lat: 47.979185, lng: 17.90792 },
    { lat: 47.979187, lng: 17.907889 },
    { lat: 47.979225, lng: 17.907446 },
    { lat: 47.979208, lng: 17.907356 },
    { lat: 47.979031, lng: 17.906988 },
    { lat: 47.979025, lng: 17.90685 },
    { lat: 47.979293, lng: 17.905872 },
    { lat: 47.979303, lng: 17.905775 },
    { lat: 47.979336, lng: 17.905506 },
    { lat: 47.979386, lng: 17.904856 },
    { lat: 47.979359, lng: 17.904757 },
    { lat: 47.979284, lng: 17.904502 },
    { lat: 47.979228, lng: 17.904279 },
    { lat: 47.979182, lng: 17.904099 },
    { lat: 47.979074, lng: 17.903675 },
    { lat: 47.979028, lng: 17.903469 },
    { lat: 47.97901, lng: 17.903394 },
    { lat: 47.979004, lng: 17.903367 },
    { lat: 47.978904, lng: 17.90315 },
    { lat: 47.978795, lng: 17.902913 },
    { lat: 47.97861, lng: 17.902566 },
    { lat: 47.978588, lng: 17.902524 },
    { lat: 47.978581, lng: 17.90251 },
    { lat: 47.978519, lng: 17.902411 },
    { lat: 47.978497, lng: 17.902373 },
    { lat: 47.978358, lng: 17.902147 },
    { lat: 47.978338, lng: 17.902115 },
    { lat: 47.978337, lng: 17.902051 },
    { lat: 47.978338, lng: 17.901984 },
    { lat: 47.978337, lng: 17.901918 },
    { lat: 47.978337, lng: 17.90188 },
    { lat: 47.978337, lng: 17.901859 },
    { lat: 47.978336, lng: 17.901832 },
    { lat: 47.97834, lng: 17.901768 },
    { lat: 47.978347, lng: 17.901671 },
    { lat: 47.978354, lng: 17.901553 },
    { lat: 47.978366, lng: 17.90136 },
    { lat: 47.978444, lng: 17.901077 },
    { lat: 47.978734, lng: 17.900985 },
    { lat: 47.978752, lng: 17.900977 },
    { lat: 47.978765, lng: 17.900971 },
    { lat: 47.978988, lng: 17.900866 },
    { lat: 47.97901, lng: 17.900851 },
    { lat: 47.979152, lng: 17.900748 },
    { lat: 47.979184, lng: 17.90073 },
    { lat: 47.979273, lng: 17.900684 },
    { lat: 47.979289, lng: 17.90068 },
    { lat: 47.979374, lng: 17.900662 },
    { lat: 47.979546, lng: 17.900626 },
    { lat: 47.979568, lng: 17.900609 },
    { lat: 47.979795, lng: 17.900439 },
    { lat: 47.97982, lng: 17.900435 },
    { lat: 47.980017, lng: 17.900402 },
    { lat: 47.980031, lng: 17.90039 },
    { lat: 47.98009, lng: 17.900334 },
    { lat: 47.980148, lng: 17.900193 },
    { lat: 47.980205, lng: 17.900133 },
    { lat: 47.980254, lng: 17.900105 },
    { lat: 47.980267, lng: 17.900098 },
    { lat: 47.980374, lng: 17.900058 },
    { lat: 47.980385, lng: 17.90005 },
    { lat: 47.980556, lng: 17.89993 },
    { lat: 47.980567, lng: 17.899862 },
    { lat: 47.980566, lng: 17.89968 },
    { lat: 47.980567, lng: 17.899655 },
    { lat: 47.98059, lng: 17.899307 },
    { lat: 47.980591, lng: 17.899285 },
    { lat: 47.980686, lng: 17.898786 },
    { lat: 47.980693, lng: 17.898752 },
    { lat: 47.98068, lng: 17.898541 },
    { lat: 47.980677, lng: 17.898511 },
    { lat: 47.980654, lng: 17.898305 },
    { lat: 47.980648, lng: 17.898243 },
    { lat: 47.980598, lng: 17.898147 },
    { lat: 47.980567, lng: 17.898087 },
    { lat: 47.980514, lng: 17.898043 },
    { lat: 47.980466, lng: 17.898004 },
    { lat: 47.980392, lng: 17.898021 },
    { lat: 47.980358, lng: 17.898028 },
    { lat: 47.980305, lng: 17.897983 },
    { lat: 47.98026, lng: 17.897946 },
    { lat: 47.98021, lng: 17.897848 },
    { lat: 47.980181, lng: 17.89779 },
    { lat: 47.980148, lng: 17.897683 },
    { lat: 47.980129, lng: 17.897625 },
    { lat: 47.980125, lng: 17.897513 },
    { lat: 47.980123, lng: 17.897451 },
    { lat: 47.980109, lng: 17.897391 },
    { lat: 47.980094, lng: 17.897319 },
    { lat: 47.980023, lng: 17.897277 },
    { lat: 47.979977, lng: 17.897249 },
    { lat: 47.979835, lng: 17.897059 },
    { lat: 47.979786, lng: 17.896993 },
    { lat: 47.97975, lng: 17.896927 },
    { lat: 47.97971, lng: 17.896853 },
    { lat: 47.979692, lng: 17.896843 },
    { lat: 47.97965, lng: 17.89682 },
    { lat: 47.979608, lng: 17.896798 },
    { lat: 47.979604, lng: 17.896796 },
    { lat: 47.979567, lng: 17.896748 },
    { lat: 47.979544, lng: 17.89668 },
    { lat: 47.979556, lng: 17.896549 },
    { lat: 47.97956, lng: 17.896512 },
    { lat: 47.979612, lng: 17.896375 },
    { lat: 47.97992, lng: 17.895895 },
    { lat: 47.979969, lng: 17.895817 },
    { lat: 47.980024, lng: 17.895708 },
    { lat: 47.980043, lng: 17.89567 },
    { lat: 47.980186, lng: 17.895325 },
    { lat: 47.980343, lng: 17.894765 },
    { lat: 47.980411, lng: 17.894526 },
    { lat: 47.980441, lng: 17.894448 },
    { lat: 47.980517, lng: 17.894248 },
    { lat: 47.980589, lng: 17.894057 },
    { lat: 47.980647, lng: 17.893902 },
    { lat: 47.980603, lng: 17.893847 },
    { lat: 47.980574, lng: 17.893811 },
    { lat: 47.980671, lng: 17.893651 },
    { lat: 47.980722, lng: 17.893565 },
    { lat: 47.980809, lng: 17.893458 },
    { lat: 47.980863, lng: 17.893392 },
    { lat: 47.980914, lng: 17.893337 },
    { lat: 47.980977, lng: 17.893268 },
    { lat: 47.981232, lng: 17.892989 },
    { lat: 47.981274, lng: 17.89284 },
    { lat: 47.981307, lng: 17.892723 },
    { lat: 47.981326, lng: 17.892654 },
    { lat: 47.981375, lng: 17.892417 },
    { lat: 47.981404, lng: 17.892269 },
    { lat: 47.981461, lng: 17.891989 },
    { lat: 47.98147, lng: 17.891956 },
    { lat: 47.981543, lng: 17.891689 },
    { lat: 47.981548, lng: 17.891669 },
    { lat: 47.981541, lng: 17.891654 },
    { lat: 47.981525, lng: 17.891624 },
    { lat: 47.98221, lng: 17.890561 },
    { lat: 47.982314, lng: 17.890345 },
    { lat: 47.982403, lng: 17.890039 },
    { lat: 47.982447, lng: 17.889887 },
    { lat: 47.982486, lng: 17.889736 },
    { lat: 47.982504, lng: 17.889666 },
    { lat: 47.982507, lng: 17.88965 },
    { lat: 47.98251, lng: 17.889641 },
    { lat: 47.982222, lng: 17.889174 },
    { lat: 47.982215, lng: 17.889161 },
    { lat: 47.982279, lng: 17.889043 },
    { lat: 47.982328, lng: 17.888951 },
    { lat: 47.982566, lng: 17.888717 },
    { lat: 47.98277, lng: 17.888516 },
    { lat: 47.982774, lng: 17.88851 },
    { lat: 47.982861, lng: 17.888386 },
    { lat: 47.982898, lng: 17.888335 },
    { lat: 47.982954, lng: 17.888221 },
    { lat: 47.983005, lng: 17.888119 },
    { lat: 47.983079, lng: 17.887809 },
    { lat: 47.983077, lng: 17.887542 },
    { lat: 47.983076, lng: 17.887355 },
    { lat: 47.983084, lng: 17.887241 },
    { lat: 47.983088, lng: 17.887182 },
    { lat: 47.983088, lng: 17.887168 },
    { lat: 47.98313, lng: 17.886941 },
    { lat: 47.983218, lng: 17.886702 },
    { lat: 47.983325, lng: 17.886546 },
    { lat: 47.983421, lng: 17.886371 },
    { lat: 47.983521, lng: 17.886258 },
    { lat: 47.98363, lng: 17.886136 },
    { lat: 47.983737, lng: 17.886015 },
    { lat: 47.983854, lng: 17.885884 },
    { lat: 47.983885, lng: 17.885849 },
    { lat: 47.983964, lng: 17.885703 },
    { lat: 47.984051, lng: 17.88554 },
    { lat: 47.984267, lng: 17.884996 },
    { lat: 47.984293, lng: 17.884932 },
    { lat: 47.984311, lng: 17.884874 },
    { lat: 47.984566, lng: 17.884046 },
    { lat: 47.984571, lng: 17.884032 },
    { lat: 47.984769, lng: 17.883598 },
    { lat: 47.985069, lng: 17.882943 },
    { lat: 47.985144, lng: 17.882779 },
    { lat: 47.985149, lng: 17.882768 },
    { lat: 47.985221, lng: 17.882632 },
    { lat: 47.98527, lng: 17.882536 },
    { lat: 47.985342, lng: 17.882399 },
    { lat: 47.985417, lng: 17.882256 },
    { lat: 47.985463, lng: 17.882169 },
    { lat: 47.985503, lng: 17.882077 },
    { lat: 47.985509, lng: 17.882069 },
    { lat: 47.985798, lng: 17.881635 },
    { lat: 47.985865, lng: 17.881533 },
    { lat: 47.985932, lng: 17.881433 },
    { lat: 47.985986, lng: 17.881351 },
    { lat: 47.986011, lng: 17.881315 },
    { lat: 47.986021, lng: 17.8813 },
    { lat: 47.986069, lng: 17.881179 },
    { lat: 47.986122, lng: 17.881046 },
    { lat: 47.986126, lng: 17.881035 },
    { lat: 47.986152, lng: 17.880939 },
    { lat: 47.986219, lng: 17.880678 },
    { lat: 47.986219, lng: 17.880676 },
    { lat: 47.986229, lng: 17.880605 },
    { lat: 47.986243, lng: 17.880502 },
    { lat: 47.98627, lng: 17.880315 },
    { lat: 47.986274, lng: 17.880294 },
    { lat: 47.986256, lng: 17.880241 },
    { lat: 47.986124, lng: 17.879838 },
    { lat: 47.985784, lng: 17.879655 },
    { lat: 47.984141, lng: 17.878763 },
    { lat: 47.984042, lng: 17.878709 },
    { lat: 47.983926, lng: 17.878656 },
    { lat: 47.983879, lng: 17.878635 },
    { lat: 47.983827, lng: 17.878612 },
    { lat: 47.983718, lng: 17.878563 },
    { lat: 47.98368, lng: 17.878545 },
    { lat: 47.983646, lng: 17.87853 },
    { lat: 47.983606, lng: 17.878512 },
    { lat: 47.983592, lng: 17.878505 },
    { lat: 47.983361, lng: 17.878401 },
    { lat: 47.983304, lng: 17.878375 },
    { lat: 47.983242, lng: 17.878347 },
    { lat: 47.982836, lng: 17.878163 },
    { lat: 47.982766, lng: 17.878132 },
    { lat: 47.98276, lng: 17.87813 },
    { lat: 47.982728, lng: 17.878115 },
    { lat: 47.9827, lng: 17.878103 },
    { lat: 47.982684, lng: 17.878095 },
    { lat: 47.982634, lng: 17.878073 },
    { lat: 47.982473, lng: 17.878002 },
    { lat: 47.982417, lng: 17.877978 },
    { lat: 47.98226, lng: 17.877909 },
    { lat: 47.981749, lng: 17.877683 },
    { lat: 47.981637, lng: 17.877633 },
    { lat: 47.981356, lng: 17.877526 },
    { lat: 47.980885, lng: 17.877346 },
    { lat: 47.98025, lng: 17.877104 },
    { lat: 47.980189, lng: 17.877081 },
    { lat: 47.980161, lng: 17.87707 },
    { lat: 47.979385, lng: 17.876779 },
    { lat: 47.978802, lng: 17.87656 },
    { lat: 47.978708, lng: 17.876525 },
    { lat: 47.977719, lng: 17.876151 },
    { lat: 47.977514, lng: 17.876073 },
    { lat: 47.97652, lng: 17.875698 },
    { lat: 47.976494, lng: 17.875687 },
    { lat: 47.975882, lng: 17.875456 },
    { lat: 47.975282, lng: 17.875229 },
    { lat: 47.975182, lng: 17.875191 },
    { lat: 47.974391, lng: 17.874887 },
    { lat: 47.974382, lng: 17.874887 },
    { lat: 47.974349, lng: 17.874887 },
    { lat: 47.972925, lng: 17.874907 },
    { lat: 47.972793, lng: 17.874909 },
    { lat: 47.972677, lng: 17.874911 },
    { lat: 47.972329, lng: 17.874916 },
    { lat: 47.972089, lng: 17.874919 },
    { lat: 47.971885, lng: 17.874922 },
    { lat: 47.971761, lng: 17.874924 },
    { lat: 47.971453, lng: 17.874928 },
    { lat: 47.971413, lng: 17.874929 },
    { lat: 47.971382, lng: 17.874928 },
    { lat: 47.971342, lng: 17.874929 },
    { lat: 47.971294, lng: 17.87493 },
    { lat: 47.971086, lng: 17.874933 },
    { lat: 47.970969, lng: 17.874935 },
    { lat: 47.970924, lng: 17.874935 },
    { lat: 47.970858, lng: 17.874936 },
    { lat: 47.970814, lng: 17.874937 },
    { lat: 47.970792, lng: 17.874937 },
    { lat: 47.970775, lng: 17.874938 },
    { lat: 47.970733, lng: 17.874941 },
    { lat: 47.970674, lng: 17.874944 },
    { lat: 47.970458, lng: 17.874958 },
    { lat: 47.969857, lng: 17.874995 },
    { lat: 47.969068, lng: 17.875044 },
    { lat: 47.969021, lng: 17.875047 },
    { lat: 47.9683, lng: 17.875091 },
    { lat: 47.968256, lng: 17.875094 },
    { lat: 47.968025, lng: 17.87511 },
    { lat: 47.967831, lng: 17.875124 },
    { lat: 47.967651, lng: 17.875136 },
    { lat: 47.967058, lng: 17.875178 },
    { lat: 47.966992, lng: 17.875182 },
    { lat: 47.966967, lng: 17.875184 },
    { lat: 47.966864, lng: 17.875192 },
    { lat: 47.966748, lng: 17.875199 },
    { lat: 47.966626, lng: 17.875208 },
    { lat: 47.966619, lng: 17.875208 },
    { lat: 47.966607, lng: 17.875209 },
    { lat: 47.966536, lng: 17.875188 },
    { lat: 47.966451, lng: 17.875168 },
    { lat: 47.966276, lng: 17.875121 },
    { lat: 47.966089, lng: 17.875072 },
    { lat: 47.965888, lng: 17.875018 },
    { lat: 47.965773, lng: 17.874987 },
    { lat: 47.965664, lng: 17.874958 },
    { lat: 47.96562, lng: 17.874947 },
    { lat: 47.965591, lng: 17.874939 },
    { lat: 47.965509, lng: 17.874917 },
    { lat: 47.965245, lng: 17.874847 },
    { lat: 47.964872, lng: 17.874748 },
    { lat: 47.964262, lng: 17.874585 },
    { lat: 47.963889, lng: 17.874392 },
    { lat: 47.963639, lng: 17.874219 },
    { lat: 47.963623, lng: 17.874206 },
    { lat: 47.963575, lng: 17.874169 },
    { lat: 47.963417, lng: 17.874046 },
    { lat: 47.963189, lng: 17.873869 },
    { lat: 47.963387, lng: 17.873543 },
    { lat: 47.963399, lng: 17.873525 },
    { lat: 47.963373, lng: 17.873407 },
    { lat: 47.963311, lng: 17.8731 },
    { lat: 47.962968, lng: 17.873267 },
    { lat: 47.962494, lng: 17.873497 },
    { lat: 47.962468, lng: 17.87351 },
    { lat: 47.962182, lng: 17.873669 },
    { lat: 47.961999, lng: 17.87377 },
    { lat: 47.961921, lng: 17.873814 },
    { lat: 47.96171, lng: 17.873948 },
    { lat: 47.961652, lng: 17.873984 },
    { lat: 47.961497, lng: 17.874103 },
    { lat: 47.961343, lng: 17.87422 },
    { lat: 47.961319, lng: 17.874245 },
    { lat: 47.961203, lng: 17.874368 },
    { lat: 47.961136, lng: 17.874459 },
    { lat: 47.961035, lng: 17.874596 },
    { lat: 47.960976, lng: 17.874701 },
    { lat: 47.960935, lng: 17.874773 },
    { lat: 47.96086, lng: 17.874904 },
    { lat: 47.960817, lng: 17.875006 },
    { lat: 47.960787, lng: 17.875078 },
    { lat: 47.960568, lng: 17.875601 },
    { lat: 47.96032, lng: 17.876217 },
    { lat: 47.960248, lng: 17.876397 },
    { lat: 47.960239, lng: 17.876417 },
    { lat: 47.960197, lng: 17.876502 },
    { lat: 47.959984, lng: 17.876919 },
    { lat: 47.959908, lng: 17.877071 },
    { lat: 47.959899, lng: 17.877088 },
    { lat: 47.959689, lng: 17.877653 },
    { lat: 47.959654, lng: 17.877747 },
    { lat: 47.959417, lng: 17.87782 },
    { lat: 47.9593226, lng: 17.8778488 },
    { lat: 47.959083, lng: 17.877922 },
    { lat: 47.959136, lng: 17.878217 },
    { lat: 47.959139, lng: 17.87824 },
    { lat: 47.959129, lng: 17.878698 },
    { lat: 47.959127, lng: 17.878825 },
    { lat: 47.959113, lng: 17.878969 },
    { lat: 47.959092, lng: 17.879213 },
    { lat: 47.959081, lng: 17.879328 },
    { lat: 47.959049, lng: 17.879528 },
    { lat: 47.959, lng: 17.879835 },
    { lat: 47.958997, lng: 17.879857 },
    { lat: 47.958972, lng: 17.87993 },
    { lat: 47.958698, lng: 17.880747 },
    { lat: 47.958688, lng: 17.880769 },
    { lat: 47.958519, lng: 17.881115 },
    { lat: 47.958465, lng: 17.881224 },
    { lat: 47.958279, lng: 17.881565 },
    { lat: 47.958243, lng: 17.881631 },
    { lat: 47.958226, lng: 17.881655 },
    { lat: 47.957791, lng: 17.882292 },
    { lat: 47.957778, lng: 17.882312 },
    { lat: 47.957631, lng: 17.882494 },
    { lat: 47.95748, lng: 17.882682 },
    { lat: 47.957239, lng: 17.882991 },
    { lat: 47.957177, lng: 17.88307 },
    { lat: 47.957114, lng: 17.883135 },
    { lat: 47.957014, lng: 17.883239 },
    { lat: 47.956878, lng: 17.883364 },
    { lat: 47.956866, lng: 17.883374 },
    { lat: 47.956696, lng: 17.883519 },
    { lat: 47.956645, lng: 17.883557 },
    { lat: 47.956484, lng: 17.883677 },
    { lat: 47.956298, lng: 17.883816 },
    { lat: 47.956235, lng: 17.883855 },
    { lat: 47.955844, lng: 17.884097 },
    { lat: 47.95578, lng: 17.884137 },
    { lat: 47.95556, lng: 17.884256 },
    { lat: 47.955267, lng: 17.884416 },
    { lat: 47.95522, lng: 17.884435 },
    { lat: 47.955003, lng: 17.884526 },
    { lat: 47.954713, lng: 17.884646 },
    { lat: 47.954645, lng: 17.884672 },
    { lat: 47.95447, lng: 17.884739 },
    { lat: 47.954361, lng: 17.884782 },
    { lat: 47.954264, lng: 17.88481 },
    { lat: 47.954067, lng: 17.884868 },
    { lat: 47.953921, lng: 17.884912 },
    { lat: 47.953794, lng: 17.884949 },
    { lat: 47.95363, lng: 17.884997 },
    { lat: 47.953246, lng: 17.885088 },
    { lat: 47.953196, lng: 17.885099 },
    { lat: 47.953049, lng: 17.885134 },
    { lat: 47.952945, lng: 17.885163 },
    { lat: 47.952761, lng: 17.885213 },
    { lat: 47.952484, lng: 17.88529 },
    { lat: 47.952232, lng: 17.88536 },
    { lat: 47.952164, lng: 17.885379 },
    { lat: 47.952084, lng: 17.885401 },
    { lat: 47.952038, lng: 17.885411 },
    { lat: 47.951605, lng: 17.88551 },
    { lat: 47.951534, lng: 17.885526 },
    { lat: 47.951169, lng: 17.88561 },
    { lat: 47.951157, lng: 17.885612 },
    { lat: 47.950985, lng: 17.885643 },
    { lat: 47.950869, lng: 17.885664 },
    { lat: 47.950713, lng: 17.8857 },
    { lat: 47.950353, lng: 17.885783 },
    { lat: 47.950276, lng: 17.8858 },
    { lat: 47.950243, lng: 17.885804 },
    { lat: 47.94989, lng: 17.885841 },
    { lat: 47.949463, lng: 17.885887 },
    { lat: 47.949262, lng: 17.885908 },
    { lat: 47.949165, lng: 17.885918 },
    { lat: 47.948898, lng: 17.885934 },
    { lat: 47.948654, lng: 17.885948 },
    { lat: 47.948621, lng: 17.88595 },
    { lat: 47.948538, lng: 17.885928 },
    { lat: 47.948418, lng: 17.885897 },
    { lat: 47.948293, lng: 17.885865 },
    { lat: 47.948108, lng: 17.885816 },
    { lat: 47.947931, lng: 17.885771 },
    { lat: 47.947679, lng: 17.885705 },
    { lat: 47.947538, lng: 17.885668 },
    { lat: 47.947492, lng: 17.885656 },
    { lat: 47.947089, lng: 17.885483 },
    { lat: 47.947039, lng: 17.885462 },
    { lat: 47.946861, lng: 17.885412 },
    { lat: 47.946389, lng: 17.88528 },
    { lat: 47.946251, lng: 17.885242 },
    { lat: 47.946154, lng: 17.885229 },
    { lat: 47.946124, lng: 17.885224 },
    { lat: 47.946107, lng: 17.885218 },
    { lat: 47.945939, lng: 17.885151 },
    { lat: 47.945788, lng: 17.885091 },
    { lat: 47.945625, lng: 17.885027 },
    { lat: 47.945604, lng: 17.885019 },
    { lat: 47.945522, lng: 17.885003 },
    { lat: 47.945435, lng: 17.884985 },
    { lat: 47.945207, lng: 17.884966 },
    { lat: 47.944901, lng: 17.88494 },
    { lat: 47.944879, lng: 17.884938 },
    { lat: 47.944671, lng: 17.884965 },
    { lat: 47.9446, lng: 17.884975 },
    { lat: 47.944368, lng: 17.885005 },
    { lat: 47.9441, lng: 17.88504 },
    { lat: 47.944068, lng: 17.885044 },
    { lat: 47.944058, lng: 17.885047 },
    { lat: 47.943922, lng: 17.885093 },
    { lat: 47.943621, lng: 17.885193 },
    { lat: 47.943603, lng: 17.885207 },
    { lat: 47.943509, lng: 17.88528 },
    { lat: 47.943396, lng: 17.885367 },
    { lat: 47.94332, lng: 17.885426 },
    { lat: 47.943205, lng: 17.885515 },
    { lat: 47.942689, lng: 17.885993 },
    { lat: 47.942683, lng: 17.885979 },
    { lat: 47.94242, lng: 17.885397 },
    { lat: 47.942412, lng: 17.885381 },
    { lat: 47.942394, lng: 17.885339 },
    { lat: 47.94239, lng: 17.88533 },
    { lat: 47.942323, lng: 17.885272 },
    { lat: 47.942205, lng: 17.885166 },
    { lat: 47.942195, lng: 17.885157 },
    { lat: 47.942002, lng: 17.884987 },
    { lat: 47.941983, lng: 17.884971 },
    { lat: 47.94197, lng: 17.884959 },
    { lat: 47.941874, lng: 17.884873 },
    { lat: 47.941865, lng: 17.884866 },
    { lat: 47.941833, lng: 17.884838 },
    { lat: 47.941773, lng: 17.884785 },
    { lat: 47.941769, lng: 17.884781 },
    { lat: 47.941729, lng: 17.884746 },
    { lat: 47.941646, lng: 17.884673 },
    { lat: 47.941584, lng: 17.884617 },
    { lat: 47.941547, lng: 17.884585 },
    { lat: 47.941392, lng: 17.884438 },
    { lat: 47.941239, lng: 17.884293 },
    { lat: 47.941225, lng: 17.884279 },
    { lat: 47.940898, lng: 17.88397 },
    { lat: 47.940644, lng: 17.883728 },
    { lat: 47.940578, lng: 17.883665 },
    { lat: 47.940318, lng: 17.883418 },
    { lat: 47.940041, lng: 17.883156 },
    { lat: 47.939794, lng: 17.882922 },
    { lat: 47.939527, lng: 17.882667 },
    { lat: 47.939419, lng: 17.882565 },
    { lat: 47.939356, lng: 17.882506 },
    { lat: 47.939224, lng: 17.882387 },
    { lat: 47.939079, lng: 17.882252 },
    { lat: 47.938936, lng: 17.882122 },
    { lat: 47.938689, lng: 17.881894 },
    { lat: 47.938507, lng: 17.881728 },
    { lat: 47.938331, lng: 17.881567 },
    { lat: 47.938246, lng: 17.881489 },
    { lat: 47.938167, lng: 17.881417 },
    { lat: 47.938146, lng: 17.881399 },
    { lat: 47.937951, lng: 17.881222 },
    { lat: 47.93771, lng: 17.881002 },
    { lat: 47.937445, lng: 17.880763 },
    { lat: 47.937254, lng: 17.880591 },
    { lat: 47.936963, lng: 17.880327 },
    { lat: 47.936862, lng: 17.880235 },
    { lat: 47.936775, lng: 17.880156 },
    { lat: 47.936766, lng: 17.880148 },
    { lat: 47.936718, lng: 17.880083 },
    { lat: 47.936531, lng: 17.879834 },
    { lat: 47.936474, lng: 17.87976 },
    { lat: 47.936293, lng: 17.879518 },
    { lat: 47.936194, lng: 17.879387 },
    { lat: 47.936189, lng: 17.879378 },
    { lat: 47.936169, lng: 17.879354 },
    { lat: 47.936161, lng: 17.879341 },
    { lat: 47.936159, lng: 17.879339 },
    { lat: 47.936018, lng: 17.879152 },
    { lat: 47.935995, lng: 17.87912 },
    { lat: 47.935991, lng: 17.879116 },
    { lat: 47.935979, lng: 17.879098 },
    { lat: 47.935975, lng: 17.879095 },
    { lat: 47.935678, lng: 17.878699 },
    { lat: 47.935433, lng: 17.878373 },
    { lat: 47.935177, lng: 17.878033 },
    { lat: 47.935007, lng: 17.877805 },
    { lat: 47.93484, lng: 17.877583 },
    { lat: 47.93474, lng: 17.877449 },
    { lat: 47.93473, lng: 17.877437 },
    { lat: 47.934525, lng: 17.87717 },
    { lat: 47.934264, lng: 17.876827 },
    { lat: 47.934038, lng: 17.876533 },
    { lat: 47.933796, lng: 17.876218 },
    { lat: 47.933564, lng: 17.875914 },
    { lat: 47.933534, lng: 17.875875 },
    { lat: 47.933458, lng: 17.875779 },
    { lat: 47.933365, lng: 17.87566 },
    { lat: 47.93314, lng: 17.875375 },
    { lat: 47.932949, lng: 17.875132 },
    { lat: 47.932826, lng: 17.874977 },
    { lat: 47.932633, lng: 17.874731 },
    { lat: 47.932442, lng: 17.874489 },
    { lat: 47.932337, lng: 17.874355 },
    { lat: 47.932326, lng: 17.874342 },
    { lat: 47.93218, lng: 17.874224 },
    { lat: 47.932012, lng: 17.87409 },
    { lat: 47.931803, lng: 17.873923 },
    { lat: 47.931607, lng: 17.873765 },
    { lat: 47.931452, lng: 17.873641 },
    { lat: 47.931365, lng: 17.873572 },
    { lat: 47.931132, lng: 17.873385 },
    { lat: 47.930789, lng: 17.87311 },
    { lat: 47.930241, lng: 17.87267 },
    { lat: 47.93019, lng: 17.872629 },
    { lat: 47.930166, lng: 17.872617 },
    { lat: 47.930143, lng: 17.872604 },
    { lat: 47.929729, lng: 17.872389 },
    { lat: 47.929363, lng: 17.872199 },
    { lat: 47.928941, lng: 17.871979 },
    { lat: 47.928621, lng: 17.871813 },
    { lat: 47.928279, lng: 17.871634 },
    { lat: 47.928078, lng: 17.871496 },
    { lat: 47.928048, lng: 17.871482 },
    { lat: 47.92793, lng: 17.871431 },
    { lat: 47.927904, lng: 17.871419 },
    { lat: 47.927863, lng: 17.871534 },
    { lat: 47.927787, lng: 17.871749 },
    { lat: 47.927754, lng: 17.871841 },
    { lat: 47.92772, lng: 17.871934 },
    { lat: 47.927717, lng: 17.871942 },
    { lat: 47.927711, lng: 17.871961 },
    { lat: 47.927698, lng: 17.871995 },
    { lat: 47.927684, lng: 17.872063 },
    { lat: 47.927671, lng: 17.872124 },
    { lat: 47.927612, lng: 17.872403 },
    { lat: 47.92759, lng: 17.872511 },
    { lat: 47.927587, lng: 17.872524 },
    { lat: 47.927568, lng: 17.872571 },
    { lat: 47.927439, lng: 17.872904 },
    { lat: 47.927411, lng: 17.872979 },
    { lat: 47.9274, lng: 17.87303 },
    { lat: 47.927375, lng: 17.873166 },
    { lat: 47.92737, lng: 17.873187 },
    { lat: 47.92737, lng: 17.873194 },
    { lat: 47.927367, lng: 17.873222 },
    { lat: 47.927361, lng: 17.873272 },
    { lat: 47.927341, lng: 17.873461 },
    { lat: 47.927338, lng: 17.873474 },
    { lat: 47.927317, lng: 17.873551 },
    { lat: 47.927246, lng: 17.87381 },
    { lat: 47.927243, lng: 17.873816 },
    { lat: 47.92721, lng: 17.873869 },
    { lat: 47.927133, lng: 17.873993 },
    { lat: 47.927104, lng: 17.874038 },
    { lat: 47.927084, lng: 17.874071 },
    { lat: 47.926969, lng: 17.874171 },
    { lat: 47.926736, lng: 17.874431 },
    { lat: 47.926565, lng: 17.87462 },
    { lat: 47.926535, lng: 17.874654 },
    { lat: 47.926486, lng: 17.874749 },
    { lat: 47.926406, lng: 17.874908 },
    { lat: 47.926377, lng: 17.875015 },
    { lat: 47.926356, lng: 17.875091 },
    { lat: 47.926346, lng: 17.875129 },
    { lat: 47.92634, lng: 17.875158 },
    { lat: 47.926333, lng: 17.875193 },
    { lat: 47.926221, lng: 17.87571 },
    { lat: 47.926212, lng: 17.875752 },
    { lat: 47.926185, lng: 17.875818 },
    { lat: 47.926123, lng: 17.875968 },
    { lat: 47.92606, lng: 17.87612 },
    { lat: 47.925987, lng: 17.876294 },
    { lat: 47.925985, lng: 17.876301 },
    { lat: 47.925835, lng: 17.876505 },
    { lat: 47.92583, lng: 17.876513 },
    { lat: 47.925769, lng: 17.876559 },
    { lat: 47.92566, lng: 17.87664 },
    { lat: 47.925618, lng: 17.876672 },
    { lat: 47.925412, lng: 17.876761 },
    { lat: 47.925189, lng: 17.876858 },
    { lat: 47.924984, lng: 17.876987 },
    { lat: 47.924966, lng: 17.87701 },
    { lat: 47.924852, lng: 17.877145 },
    { lat: 47.924674, lng: 17.877357 },
    { lat: 47.924571, lng: 17.877479 },
    { lat: 47.924553, lng: 17.8775 },
    { lat: 47.924527, lng: 17.877553 },
    { lat: 47.924399, lng: 17.877801 },
    { lat: 47.924171, lng: 17.87809 },
    { lat: 47.924155, lng: 17.878111 },
    { lat: 47.924045, lng: 17.878325 },
    { lat: 47.924025, lng: 17.878361 },
    { lat: 47.923863, lng: 17.878602 },
    { lat: 47.923813, lng: 17.878675 },
    { lat: 47.92366, lng: 17.878902 },
    { lat: 47.923596, lng: 17.878996 },
    { lat: 47.923533, lng: 17.87909 },
    { lat: 47.923465, lng: 17.879189 },
    { lat: 47.923425, lng: 17.879249 },
    { lat: 47.923368, lng: 17.87938 },
    { lat: 47.923302, lng: 17.879529 },
    { lat: 47.923295, lng: 17.879546 },
    { lat: 47.92329, lng: 17.879568 },
    { lat: 47.923285, lng: 17.87959 },
    { lat: 47.923254, lng: 17.879729 },
    { lat: 47.923236, lng: 17.879806 },
    { lat: 47.923226, lng: 17.879848 },
    { lat: 47.923206, lng: 17.879941 },
    { lat: 47.923195, lng: 17.879971 },
    { lat: 47.923144, lng: 17.880129 },
    { lat: 47.923127, lng: 17.88018 },
    { lat: 47.923076, lng: 17.880289 },
    { lat: 47.923035, lng: 17.880376 },
    { lat: 47.923004, lng: 17.880441 },
    { lat: 47.92299, lng: 17.880455 },
    { lat: 47.922958, lng: 17.880488 },
    { lat: 47.92283, lng: 17.880617 },
    { lat: 47.922807, lng: 17.880652 },
    { lat: 47.922749, lng: 17.880739 },
    { lat: 47.922731, lng: 17.880778 },
    { lat: 47.922712, lng: 17.880821 },
    { lat: 47.922684, lng: 17.880883 },
    { lat: 47.922677, lng: 17.880939 },
    { lat: 47.922671, lng: 17.881 },
    { lat: 47.922669, lng: 17.881019 },
    { lat: 47.922674, lng: 17.881081 },
    { lat: 47.92269, lng: 17.881275 },
    { lat: 47.922692, lng: 17.881291 },
    { lat: 47.922731, lng: 17.881553 },
    { lat: 47.922736, lng: 17.881598 },
    { lat: 47.92275, lng: 17.881724 },
    { lat: 47.92275, lng: 17.881735 },
    { lat: 47.922738, lng: 17.881913 },
    { lat: 47.922726, lng: 17.881935 },
    { lat: 47.922702, lng: 17.881978 },
    { lat: 47.922664, lng: 17.882045 },
    { lat: 47.922658, lng: 17.882054 },
    { lat: 47.922617, lng: 17.88211 },
    { lat: 47.922588, lng: 17.88215 },
    { lat: 47.92257, lng: 17.882158 },
    { lat: 47.92247, lng: 17.882206 },
    { lat: 47.922457, lng: 17.882212 },
    { lat: 47.922391, lng: 17.882213 },
    { lat: 47.922257, lng: 17.882216 },
    { lat: 47.922142, lng: 17.882218 },
    { lat: 47.921979, lng: 17.882222 },
    { lat: 47.921955, lng: 17.882229 },
    { lat: 47.921795, lng: 17.882274 },
    { lat: 47.921704, lng: 17.8823 },
    { lat: 47.921585, lng: 17.882389 },
    { lat: 47.92157, lng: 17.882405 },
    { lat: 47.921546, lng: 17.882428 },
    { lat: 47.92144, lng: 17.882535 },
    { lat: 47.921346, lng: 17.882628 },
    { lat: 47.921297, lng: 17.882691 },
    { lat: 47.921183, lng: 17.882836 },
    { lat: 47.921178, lng: 17.882844 },
    { lat: 47.921114, lng: 17.882987 },
    { lat: 47.921064, lng: 17.883103 },
    { lat: 47.921057, lng: 17.883117 },
    { lat: 47.921023, lng: 17.883218 },
    { lat: 47.92097, lng: 17.88338 },
    { lat: 47.920949, lng: 17.883441 },
    { lat: 47.920849, lng: 17.883744 },
    { lat: 47.920763, lng: 17.884003 },
    { lat: 47.920732, lng: 17.884095 },
    { lat: 47.920715, lng: 17.88415 },
    { lat: 47.920662, lng: 17.884319 },
    { lat: 47.920645, lng: 17.884398 },
    { lat: 47.920625, lng: 17.884499 },
    { lat: 47.920623, lng: 17.884511 },
    { lat: 47.920621, lng: 17.884602 },
    { lat: 47.920618, lng: 17.884727 },
    { lat: 47.920621, lng: 17.884815 },
    { lat: 47.920634, lng: 17.885088 },
    { lat: 47.920637, lng: 17.885104 },
    { lat: 47.92065, lng: 17.885179 },
    { lat: 47.920716, lng: 17.885539 },
    { lat: 47.920719, lng: 17.885557 },
    { lat: 47.920723, lng: 17.88574 },
    { lat: 47.920724, lng: 17.885813 },
    { lat: 47.920715, lng: 17.885853 },
    { lat: 47.920692, lng: 17.885959 },
    { lat: 47.920686, lng: 17.88599 },
    { lat: 47.920681, lng: 17.885998 },
    { lat: 47.920615, lng: 17.886122 },
    { lat: 47.920565, lng: 17.886207 },
    { lat: 47.920546, lng: 17.886224 },
    { lat: 47.920479, lng: 17.886281 },
    { lat: 47.920382, lng: 17.886365 },
    { lat: 47.92037, lng: 17.886374 },
    { lat: 47.920144, lng: 17.886525 },
    { lat: 47.920089, lng: 17.886563 },
    { lat: 47.920063, lng: 17.886581 },
    { lat: 47.920054, lng: 17.886587 },
    { lat: 47.92003, lng: 17.886603 },
    { lat: 47.919955, lng: 17.88672 },
    { lat: 47.919933, lng: 17.886755 },
    { lat: 47.919847, lng: 17.886904 },
    { lat: 47.919824, lng: 17.886943 },
    { lat: 47.919763, lng: 17.887038 },
    { lat: 47.91965, lng: 17.887211 },
    { lat: 47.919628, lng: 17.887243 },
    { lat: 47.919591, lng: 17.8873 },
    { lat: 47.919485, lng: 17.887463 },
    { lat: 47.919438, lng: 17.887534 },
    { lat: 47.919397, lng: 17.887598 },
    { lat: 47.919379, lng: 17.887625 },
    { lat: 47.919287, lng: 17.887765 },
    { lat: 47.9191253, lng: 17.8879889 },
    { lat: 47.9189076, lng: 17.888563 },
    { lat: 47.9188, lng: 17.8890164 },
    { lat: 47.9185426, lng: 17.8895713 },
    { lat: 47.9178404, lng: 17.8907139 },
    { lat: 47.9178307, lng: 17.8914028 },
    { lat: 47.9177228, lng: 17.891708 },
    { lat: 47.917427, lng: 17.8922045 },
    { lat: 47.917275, lng: 17.8926561 },
    { lat: 47.9172707, lng: 17.8930268 },
    { lat: 47.9173474, lng: 17.8938208 },
    { lat: 47.9173046, lng: 17.8940458 },
    { lat: 47.9171473, lng: 17.8944981 },
    { lat: 47.9168103, lng: 17.8950983 },
    { lat: 47.9166725, lng: 17.8955761 },
    { lat: 47.9166816, lng: 17.8959109 },
    { lat: 47.9169565, lng: 17.8963994 },
    { lat: 47.9171166, lng: 17.8970453 },
    { lat: 47.9168639, lng: 17.8981903 },
    { lat: 47.9169148, lng: 17.8991904 },
    { lat: 47.9168669, lng: 17.8998655 },
    { lat: 47.916717, lng: 17.900631 },
    { lat: 47.9165543, lng: 17.9009401 },
    { lat: 47.9161423, lng: 17.9013487 },
    { lat: 47.9159701, lng: 17.9021499 },
    { lat: 47.9157482, lng: 17.9028241 },
    { lat: 47.9155928, lng: 17.903052 },
    { lat: 47.9153076, lng: 17.9032633 },
    { lat: 47.914211, lng: 17.9046225 },
    { lat: 47.9138287, lng: 17.9046843 },
    { lat: 47.9133573, lng: 17.9038205 },
    { lat: 47.9130752, lng: 17.9036204 },
    { lat: 47.9125356, lng: 17.9034336 },
    { lat: 47.9123452, lng: 17.9034562 },
    { lat: 47.91194, lng: 17.9039947 },
    { lat: 47.9118275, lng: 17.9040584 },
    { lat: 47.911123, lng: 17.903832 },
    { lat: 47.9108532, lng: 17.9038685 },
    { lat: 47.9106714, lng: 17.9040524 },
    { lat: 47.910546, lng: 17.9042734 },
    { lat: 47.9102129, lng: 17.9056053 },
    { lat: 47.9100765, lng: 17.9057346 },
    { lat: 47.9098984, lng: 17.9057963 },
    { lat: 47.9093116, lng: 17.9057787 },
    { lat: 47.9089889, lng: 17.9058388 },
    { lat: 47.9088315, lng: 17.9061095 },
    { lat: 47.908768, lng: 17.9065638 },
    { lat: 47.90868, lng: 17.908118 },
    { lat: 47.9083378, lng: 17.9095581 },
    { lat: 47.9081922, lng: 17.9098201 },
    { lat: 47.9080975, lng: 17.9101717 },
    { lat: 47.9078874, lng: 17.9117603 },
    { lat: 47.9077609, lng: 17.9123163 },
    { lat: 47.9074945, lng: 17.9130956 },
    { lat: 47.9070687, lng: 17.9146474 },
    { lat: 47.9067998, lng: 17.915141 },
    { lat: 47.905933, lng: 17.9163642 },
    { lat: 47.9056657, lng: 17.9168688 },
    { lat: 47.9055691, lng: 17.9170412 },
    { lat: 47.9054447, lng: 17.9172634 },
    { lat: 47.9056177, lng: 17.9177854 },
    { lat: 47.905662, lng: 17.9182515 },
    { lat: 47.9055399, lng: 17.9185659 },
    { lat: 47.9053857, lng: 17.9187956 },
    { lat: 47.9049615, lng: 17.9190027 },
    { lat: 47.9047552, lng: 17.9190058 },
    { lat: 47.9046277, lng: 17.9189129 },
    { lat: 47.9045836, lng: 17.9184682 },
    { lat: 47.9044687, lng: 17.91814 },
    { lat: 47.9042202, lng: 17.9179505 },
    { lat: 47.9039398, lng: 17.917907 },
    { lat: 47.9033433, lng: 17.9179881 },
    { lat: 47.9029926, lng: 17.9181807 },
    { lat: 47.9026017, lng: 17.9185657 },
    { lat: 47.9023275, lng: 17.9194744 },
    { lat: 47.9017644, lng: 17.9199962 },
    { lat: 47.900979, lng: 17.9208713 },
    { lat: 47.9004974, lng: 17.9217491 },
    { lat: 47.900246, lng: 17.9218818 },
    { lat: 47.8997962, lng: 17.9220029 },
    { lat: 47.8992322, lng: 17.9222899 },
    { lat: 47.8980152, lng: 17.9232283 },
    { lat: 47.8976793, lng: 17.9235703 },
    { lat: 47.89728, lng: 17.9241527 },
    { lat: 47.8971079, lng: 17.9246237 },
    { lat: 47.8970715, lng: 17.9248814 },
    { lat: 47.8970811, lng: 17.9249069 },
    { lat: 47.8971854, lng: 17.9250455 },
    { lat: 47.8975309, lng: 17.9251031 },
    { lat: 47.8977812, lng: 17.9252291 },
    { lat: 47.8982857, lng: 17.9256909 },
    { lat: 47.8984151, lng: 17.9261107 },
    { lat: 47.8983423, lng: 17.9263062 },
    { lat: 47.8976803, lng: 17.9270365 },
    { lat: 47.8973288, lng: 17.9273279 },
    { lat: 47.8970679, lng: 17.9274717 },
    { lat: 47.8963107, lng: 17.9277163 },
    { lat: 47.8958618, lng: 17.9280191 },
    { lat: 47.8948218, lng: 17.9289021 },
    { lat: 47.8945378, lng: 17.9290596 },
    { lat: 47.893527, lng: 17.9294196 },
    { lat: 47.8932648, lng: 17.9295463 },
    { lat: 47.8930481, lng: 17.9297323 },
    { lat: 47.8929324, lng: 17.9300376 },
    { lat: 47.8928433, lng: 17.9307231 },
    { lat: 47.8926585, lng: 17.9311777 },
    { lat: 47.8920768, lng: 17.9321721 },
    { lat: 47.8911908, lng: 17.9332692 },
    { lat: 47.8906699, lng: 17.9337577 },
    { lat: 47.8899423, lng: 17.934287 },
    { lat: 47.889002, lng: 17.935122 },
    { lat: 47.8885032, lng: 17.9358301 },
    { lat: 47.8879508, lng: 17.9363418 },
    { lat: 47.8878993, lng: 17.936514 },
    { lat: 47.8880324, lng: 17.9369775 },
    { lat: 47.8880721, lng: 17.9372996 },
    { lat: 47.8880498, lng: 17.9375625 },
    { lat: 47.8872478, lng: 17.9399661 },
    { lat: 47.8871116, lng: 17.9402503 },
    { lat: 47.88694, lng: 17.9403809 },
    { lat: 47.8868378, lng: 17.9403138 },
    { lat: 47.8865756, lng: 17.9396974 },
    { lat: 47.8865009, lng: 17.9396198 },
    { lat: 47.8863377, lng: 17.9395883 },
    { lat: 47.8858381, lng: 17.9400629 },
    { lat: 47.8856502, lng: 17.9401659 },
    { lat: 47.8845679, lng: 17.9402799 },
    { lat: 47.8833035, lng: 17.9407405 },
    { lat: 47.8824051, lng: 17.9408924 },
    { lat: 47.8821403, lng: 17.9411199 },
    { lat: 47.8816932, lng: 17.9421778 },
    { lat: 47.881499, lng: 17.9428076 },
    { lat: 47.8811968, lng: 17.9435025 },
    { lat: 47.8809422, lng: 17.9442722 },
    { lat: 47.8809567, lng: 17.9443628 },
    { lat: 47.8812836, lng: 17.9447805 },
    { lat: 47.8812316, lng: 17.9450769 },
    { lat: 47.8809981, lng: 17.9454145 },
    { lat: 47.8806481, lng: 17.9456731 },
    { lat: 47.8802275, lng: 17.9462766 },
    { lat: 47.8797777, lng: 17.947178 },
    { lat: 47.8797501, lng: 17.9474862 },
    { lat: 47.8798592, lng: 17.9479233 },
    { lat: 47.8801009, lng: 17.9484489 },
    { lat: 47.8801547, lng: 17.9487402 },
    { lat: 47.8800621, lng: 17.9493535 },
    { lat: 47.8799349, lng: 17.9495811 },
    { lat: 47.8793789, lng: 17.9499451 },
    { lat: 47.8792395, lng: 17.9501923 },
    { lat: 47.8792001, lng: 17.9508487 },
    { lat: 47.8790377, lng: 17.9515571 },
    { lat: 47.8790025, lng: 17.951962 },
    { lat: 47.8790242, lng: 17.9523395 },
    { lat: 47.8793161, lng: 17.9531519 },
    { lat: 47.879326, lng: 17.9534821 },
    { lat: 47.87924, lng: 17.9537176 },
    { lat: 47.8791061, lng: 17.9539673 },
    { lat: 47.8787799, lng: 17.954134 },
    { lat: 47.8786275, lng: 17.9543791 },
    { lat: 47.8786634, lng: 17.9548971 },
    { lat: 47.8785862, lng: 17.9551687 },
    { lat: 47.8778297, lng: 17.9556659 },
    { lat: 47.8776832, lng: 17.9558757 },
    { lat: 47.8776069, lng: 17.9565916 },
    { lat: 47.877011, lng: 17.9578034 },
    { lat: 47.8765508, lng: 17.9582332 },
    { lat: 47.87554, lng: 17.9588006 },
    { lat: 47.8752828, lng: 17.9591971 },
    { lat: 47.8751236, lng: 17.9600126 },
    { lat: 47.8747433, lng: 17.9610792 },
    { lat: 47.8746561, lng: 17.9619855 },
    { lat: 47.8746586, lng: 17.9625308 },
    { lat: 47.8747174, lng: 17.9629585 },
    { lat: 47.874809, lng: 17.9632261 },
    { lat: 47.8752154, lng: 17.9638597 },
    { lat: 47.8752388, lng: 17.9640974 },
    { lat: 47.8745718, lng: 17.9662032 },
    { lat: 47.8743481, lng: 17.9672832 },
    { lat: 47.8741571, lng: 17.9677643 },
    { lat: 47.8735998, lng: 17.9686819 },
    { lat: 47.8733259, lng: 17.9690226 },
    { lat: 47.8728518, lng: 17.9694354 },
    { lat: 47.872502, lng: 17.9699581 },
    { lat: 47.8722752, lng: 17.9707056 },
    { lat: 47.8719902, lng: 17.9719455 },
    { lat: 47.8717947, lng: 17.9722944 },
    { lat: 47.8715509, lng: 17.9725772 },
    { lat: 47.8703723, lng: 17.9732265 },
    { lat: 47.8701014, lng: 17.9735411 },
    { lat: 47.8695096, lng: 17.974507 },
    { lat: 47.8690953, lng: 17.97542 },
    { lat: 47.8688535, lng: 17.9762014 },
    { lat: 47.8687233, lng: 17.9768683 },
    { lat: 47.8685109, lng: 17.9786173 },
    { lat: 47.8681102, lng: 17.9802252 },
    { lat: 47.8676976, lng: 17.9811481 },
    { lat: 47.867494, lng: 17.9817588 },
    { lat: 47.8674897, lng: 17.9830311 },
    { lat: 47.8674076, lng: 17.9832803 },
    { lat: 47.8671702, lng: 17.983686 },
    { lat: 47.8667425, lng: 17.9841539 },
    { lat: 47.86628, lng: 17.9849042 },
    { lat: 47.8659738, lng: 17.9855362 },
    { lat: 47.8654601, lng: 17.9868813 },
    { lat: 47.8650449, lng: 17.9875925 },
    { lat: 47.864388, lng: 17.9891271 },
    { lat: 47.8635779, lng: 17.9906666 },
    { lat: 47.8630943, lng: 17.9913295 },
    { lat: 47.8623783, lng: 17.9928388 },
    { lat: 47.8619691, lng: 17.9934296 },
    { lat: 47.8618997, lng: 17.9935299 },
    { lat: 47.8617372, lng: 17.9939477 },
    { lat: 47.8613212, lng: 17.9950174 },
    { lat: 47.8605256, lng: 17.9963122 },
    { lat: 47.860501, lng: 17.9963662 },
    { lat: 47.8604439, lng: 17.9964914 },
    { lat: 47.8604033, lng: 17.9970142 },
    { lat: 47.8605207, lng: 17.9978786 },
    { lat: 47.8604711, lng: 17.9983673 },
    { lat: 47.8603464, lng: 17.9986015 },
    { lat: 47.8597086, lng: 17.999423 },
    { lat: 47.8585902, lng: 18.0006412 },
    { lat: 47.8584611, lng: 18.0008812 },
    { lat: 47.8583101, lng: 18.001162 },
    { lat: 47.8579418, lng: 18.0022793 },
    { lat: 47.8573424, lng: 18.0045828 },
    { lat: 47.8573065, lng: 18.0050399 },
    { lat: 47.857074, lng: 18.0057898 },
    { lat: 47.8579781, lng: 18.0058432 },
    { lat: 47.8581791, lng: 18.00626 },
    { lat: 47.8582194, lng: 18.006571 },
    { lat: 47.85833, lng: 18.0067791 },
    { lat: 47.858559, lng: 18.0070386 },
    { lat: 47.8587904, lng: 18.0072289 },
    { lat: 47.859388, lng: 18.0075192 },
    { lat: 47.8602427, lng: 18.0081039 },
    { lat: 47.8606776, lng: 18.0078869 },
    { lat: 47.8608536, lng: 18.0079229 },
    { lat: 47.8615636, lng: 18.0078475 },
    { lat: 47.8618082, lng: 18.0078997 },
    { lat: 47.8621408, lng: 18.0075744 },
    { lat: 47.8624696, lng: 18.0075806 },
    { lat: 47.8628019, lng: 18.0080193 },
    { lat: 47.8635152, lng: 18.0073432 },
    { lat: 47.8642273, lng: 18.0064047 },
    { lat: 47.8648062, lng: 18.0059911 },
    { lat: 47.8658393, lng: 18.0056604 },
    { lat: 47.8663806, lng: 18.0053681 },
    { lat: 47.8664567, lng: 18.0053601 },
    { lat: 47.8669037, lng: 18.0057379 },
    { lat: 47.8671351, lng: 18.0057936 },
    { lat: 47.8676136, lng: 18.0061289 },
    { lat: 47.8689806, lng: 18.0097916 },
    { lat: 47.8689937, lng: 18.0133484 },
    { lat: 47.8695375, lng: 18.0128027 },
    { lat: 47.870978, lng: 18.0110989 },
    { lat: 47.8715904, lng: 18.0105848 },
    { lat: 47.8722358, lng: 18.0104309 },
    { lat: 47.8724892, lng: 18.0104598 },
    { lat: 47.8728357, lng: 18.0105902 },
    { lat: 47.8731009, lng: 18.0108348 },
    { lat: 47.8734183, lng: 18.0114011 },
    { lat: 47.8734383, lng: 18.0122013 },
    { lat: 47.8732957, lng: 18.0126657 },
    { lat: 47.8730917, lng: 18.0131199 },
    { lat: 47.8726428, lng: 18.0136709 },
    { lat: 47.8718929, lng: 18.0141122 },
    { lat: 47.8712753, lng: 18.0146153 },
    { lat: 47.8700271, lng: 18.0163276 },
    { lat: 47.8695451, lng: 18.0178669 },
    { lat: 47.8691872, lng: 18.0194205 },
    { lat: 47.8690494, lng: 18.0203984 },
    { lat: 47.8690196, lng: 18.021686 },
    { lat: 47.8693794, lng: 18.0242211 },
    { lat: 47.8695009, lng: 18.0247983 },
    { lat: 47.8696801, lng: 18.0253025 },
    { lat: 47.8699982, lng: 18.0259177 },
    { lat: 47.8705128, lng: 18.0266892 },
    { lat: 47.8717711, lng: 18.0280434 },
    { lat: 47.8724955, lng: 18.0286965 },
    { lat: 47.8746045, lng: 18.0304771 },
    { lat: 47.8754085, lng: 18.0307864 },
    { lat: 47.8767835, lng: 18.03094 },
    { lat: 47.8795909, lng: 18.0309499 },
    { lat: 47.8806195, lng: 18.0306948 },
    { lat: 47.8811018, lng: 18.0307585 },
    { lat: 47.8814605, lng: 18.0307141 },
    { lat: 47.882643, lng: 18.0309179 },
    { lat: 47.8829383, lng: 18.0308942 },
    { lat: 47.8833665, lng: 18.0308441 },
    { lat: 47.8841732, lng: 18.0307809 },
    { lat: 47.8846418, lng: 18.0305778 },
    { lat: 47.8867923, lng: 18.0301553 },
    { lat: 47.8879414, lng: 18.0301174 },
    { lat: 47.8901362, lng: 18.0298666 },
    { lat: 47.8905663, lng: 18.0296988 },
    { lat: 47.8910833, lng: 18.0293989 },
    { lat: 47.8924584, lng: 18.0292187 },
    { lat: 47.8956108, lng: 18.0290892 },
    { lat: 47.8962673, lng: 18.0290057 },
    { lat: 47.8969356, lng: 18.029048 },
    { lat: 47.8991798, lng: 18.0284519 },
    { lat: 47.8993569, lng: 18.0295253 },
    { lat: 47.900422, lng: 18.0302335 },
    { lat: 47.9005735, lng: 18.03069 },
    { lat: 47.9014035, lng: 18.0340901 },
    { lat: 47.9032736, lng: 18.0368254 },
    { lat: 47.9034019, lng: 18.0369829 },
    { lat: 47.9034091, lng: 18.0369933 },
    { lat: 47.9037553, lng: 18.0374945 },
    { lat: 47.9042541, lng: 18.0380429 },
    { lat: 47.9092201, lng: 18.0443822 },
    { lat: 47.910989, lng: 18.0456154 },
    { lat: 47.9170354, lng: 18.048321 },
    { lat: 47.9234155, lng: 18.0549154 },
    { lat: 47.9260627, lng: 18.0584647 },
    { lat: 47.9286703, lng: 18.0622585 },
    { lat: 47.928714, lng: 18.0623261 },
    { lat: 47.9309056, lng: 18.0657179 },
    { lat: 47.9311466, lng: 18.0668449 },
    { lat: 47.9311425, lng: 18.067685 },
    { lat: 47.9308369, lng: 18.0678598 },
    { lat: 47.9306074, lng: 18.0698043 },
    { lat: 47.9303233, lng: 18.0709467 },
    { lat: 47.9302423, lng: 18.0710282 },
    { lat: 47.9301966, lng: 18.0712052 },
    { lat: 47.9300352, lng: 18.0723986 },
    { lat: 47.9300679, lng: 18.0734053 },
    { lat: 47.9304548, lng: 18.0733814 },
    { lat: 47.9305159, lng: 18.0747134 },
    { lat: 47.9308263, lng: 18.0756061 },
    { lat: 47.9312804, lng: 18.0767117 },
    { lat: 47.932817, lng: 18.0786018 },
    { lat: 47.9332738, lng: 18.0789952 },
    { lat: 47.933786, lng: 18.0792568 },
    { lat: 47.9340516, lng: 18.0798131 },
    { lat: 47.9354313, lng: 18.0787988 },
    { lat: 47.935883, lng: 18.0783863 },
    { lat: 47.9364321, lng: 18.0776386 },
    { lat: 47.9372243, lng: 18.0763451 },
    { lat: 47.9373584, lng: 18.0760184 },
    { lat: 47.9380001, lng: 18.0730722 },
    { lat: 47.9381415, lng: 18.0726066 },
    { lat: 47.938406, lng: 18.0723099 },
    { lat: 47.9388803, lng: 18.0722371 },
    { lat: 47.9395824, lng: 18.072316 },
    { lat: 47.9399392, lng: 18.0722422 },
    { lat: 47.940024, lng: 18.0721504 },
    { lat: 47.9401207, lng: 18.071879 },
    { lat: 47.9400531, lng: 18.0710854 },
    { lat: 47.9401824, lng: 18.0706041 },
    { lat: 47.9409496, lng: 18.0699522 },
    { lat: 47.9414584, lng: 18.0690217 },
    { lat: 47.9420819, lng: 18.0683481 },
    { lat: 47.9431275, lng: 18.0675988 },
    { lat: 47.9433215, lng: 18.0677112 },
    { lat: 47.9434948, lng: 18.0677017 },
    { lat: 47.9437162, lng: 18.0678573 },
    { lat: 47.944477, lng: 18.0693168 },
    { lat: 47.9447583, lng: 18.0698564 },
    { lat: 47.9450189, lng: 18.0701747 },
    { lat: 47.9452003, lng: 18.0703022 },
    { lat: 47.9457586, lng: 18.0706182 },
    { lat: 47.9461526, lng: 18.0707173 },
    { lat: 47.946963, lng: 18.0705104 },
    { lat: 47.947548, lng: 18.070234 },
    { lat: 47.947567, lng: 18.070232 },
    { lat: 47.948817, lng: 18.070088 },
    { lat: 47.948977, lng: 18.069685 },
    { lat: 47.949467, lng: 18.068443 },
    { lat: 47.94976, lng: 18.067827 },
    { lat: 47.950005, lng: 18.06731 },
    { lat: 47.950303, lng: 18.066679 },
    { lat: 47.950503, lng: 18.066259 },
    { lat: 47.950522, lng: 18.066197 },
    { lat: 47.950698, lng: 18.06564 },
    { lat: 47.950782, lng: 18.065375 },
    { lat: 47.950952, lng: 18.064838 },
    { lat: 47.951104, lng: 18.063959 },
    { lat: 47.95112, lng: 18.063872 },
    { lat: 47.951126, lng: 18.06384 },
    { lat: 47.951143, lng: 18.063756 },
    { lat: 47.951148, lng: 18.063727 },
    { lat: 47.951156, lng: 18.063684 },
    { lat: 47.951175, lng: 18.063537 },
    { lat: 47.95122, lng: 18.063178 },
    { lat: 47.951301, lng: 18.062536 },
    { lat: 47.951416, lng: 18.061626 },
    { lat: 47.951458, lng: 18.061298 },
    { lat: 47.951485, lng: 18.061078 },
    { lat: 47.951485, lng: 18.061077 },
    { lat: 47.951532, lng: 18.060919 },
    { lat: 47.95161, lng: 18.060661 },
    { lat: 47.951797, lng: 18.060026 },
    { lat: 47.951949, lng: 18.059519 },
    { lat: 47.952118, lng: 18.058948 },
    { lat: 47.952217, lng: 18.058632 },
    { lat: 47.952385, lng: 18.058087 },
    { lat: 47.952452, lng: 18.057871 },
    { lat: 47.952472, lng: 18.057804 },
    { lat: 47.952529, lng: 18.057611 },
    { lat: 47.952533, lng: 18.057598 },
    { lat: 47.952569, lng: 18.057281 },
    { lat: 47.952578, lng: 18.057209 },
    { lat: 47.952605, lng: 18.056974 },
    { lat: 47.952612, lng: 18.056916 },
    { lat: 47.952616, lng: 18.056858 },
    { lat: 47.952665, lng: 18.056266 },
    { lat: 47.952652, lng: 18.05615 },
    { lat: 47.952641, lng: 18.056036 },
    { lat: 47.952591, lng: 18.055772 },
    { lat: 47.952513, lng: 18.055353 },
    { lat: 47.952451, lng: 18.055059 },
    { lat: 47.952445, lng: 18.055034 },
    { lat: 47.952379, lng: 18.05485 },
    { lat: 47.95237, lng: 18.054822 },
    { lat: 47.952316, lng: 18.054728 },
    { lat: 47.952309, lng: 18.054716 },
    { lat: 47.952203, lng: 18.054644 },
    { lat: 47.952164, lng: 18.054618 },
    { lat: 47.951827, lng: 18.05427 },
    { lat: 47.951784, lng: 18.054226 },
    { lat: 47.951611, lng: 18.054077 },
    { lat: 47.951578, lng: 18.054039 },
    { lat: 47.951486, lng: 18.053935 },
    { lat: 47.951354, lng: 18.053783 },
    { lat: 47.951348, lng: 18.053776 },
    { lat: 47.951319, lng: 18.053785 },
    { lat: 47.951148, lng: 18.053835 },
    { lat: 47.951133, lng: 18.05384 },
    { lat: 47.951106, lng: 18.053848 },
    { lat: 47.950447, lng: 18.054036 },
    { lat: 47.950424, lng: 18.054042 },
    { lat: 47.950351, lng: 18.054055 },
    { lat: 47.950326, lng: 18.05406 },
    { lat: 47.950279, lng: 18.054092 },
    { lat: 47.950121, lng: 18.054199 },
    { lat: 47.949991, lng: 18.054279 },
    { lat: 47.94998, lng: 18.054286 },
    { lat: 47.949804, lng: 18.054344 },
    { lat: 47.949536, lng: 18.054432 },
    { lat: 47.949469, lng: 18.054455 },
    { lat: 47.949292, lng: 18.0545 },
    { lat: 47.94893, lng: 18.054593 },
    { lat: 47.948903, lng: 18.0546 },
    { lat: 47.948582, lng: 18.05457 },
    { lat: 47.948553, lng: 18.054565 },
    { lat: 47.948266, lng: 18.054512 },
    { lat: 47.948259, lng: 18.054511 },
    { lat: 47.947873, lng: 18.054288 },
    { lat: 47.947862, lng: 18.054281 },
    { lat: 47.947671, lng: 18.054007 },
    { lat: 47.947263, lng: 18.053424 },
    { lat: 47.94724, lng: 18.053391 },
    { lat: 47.947072, lng: 18.053152 },
    { lat: 47.94699, lng: 18.053037 },
    { lat: 47.946853, lng: 18.05282 },
    { lat: 47.946841, lng: 18.052802 },
    { lat: 47.946773, lng: 18.052405 },
    { lat: 47.946712, lng: 18.052049 },
    { lat: 47.946669, lng: 18.051792 },
    { lat: 47.946623, lng: 18.051522 },
    { lat: 47.946623, lng: 18.051475 },
    { lat: 47.946621, lng: 18.051152 },
    { lat: 47.946604, lng: 18.050763 },
    { lat: 47.946577, lng: 18.050389 },
    { lat: 47.946551, lng: 18.050038 },
    { lat: 47.946535, lng: 18.04982 },
    { lat: 47.94649, lng: 18.049265 },
    { lat: 47.946473, lng: 18.049065 },
    { lat: 47.946467, lng: 18.048966 },
    { lat: 47.94644, lng: 18.048475 },
    { lat: 47.946412, lng: 18.048128 },
    { lat: 47.946401, lng: 18.047932 },
    { lat: 47.946389, lng: 18.047723 },
    { lat: 47.946371, lng: 18.04741 },
    { lat: 47.946354, lng: 18.047102 },
    { lat: 47.94633, lng: 18.046683 },
    { lat: 47.946318, lng: 18.046464 },
    { lat: 47.946291, lng: 18.045955 },
    { lat: 47.946281, lng: 18.045755 },
    { lat: 47.946269, lng: 18.045514 },
    { lat: 47.946265, lng: 18.045441 },
    { lat: 47.946253, lng: 18.045191 },
    { lat: 47.94625, lng: 18.045117 },
    { lat: 47.946249, lng: 18.04511 },
    { lat: 47.946246, lng: 18.045041 },
    { lat: 47.946247, lng: 18.04503 },
    { lat: 47.946249, lng: 18.04502 },
    { lat: 47.946255, lng: 18.044935 },
    { lat: 47.946263, lng: 18.044829 },
    { lat: 47.946264, lng: 18.044818 },
    { lat: 47.946259, lng: 18.044709 },
    { lat: 47.946256, lng: 18.044599 },
    { lat: 47.94625, lng: 18.044448 },
    { lat: 47.946248, lng: 18.044349 },
    { lat: 47.946243, lng: 18.044188 },
    { lat: 47.94624, lng: 18.043995 },
    { lat: 47.946237, lng: 18.043748 },
    { lat: 47.946227, lng: 18.043522 },
    { lat: 47.946216, lng: 18.043265 },
    { lat: 47.946227, lng: 18.043137 },
    { lat: 47.946237, lng: 18.043017 },
    { lat: 47.946248, lng: 18.042899 },
    { lat: 47.946252, lng: 18.042681 },
    { lat: 47.946255, lng: 18.042552 },
    { lat: 47.946252, lng: 18.042438 },
    { lat: 47.94625, lng: 18.042311 },
    { lat: 47.946246, lng: 18.042093 },
    { lat: 47.946242, lng: 18.041862 },
    { lat: 47.946238, lng: 18.041645 },
    { lat: 47.946234, lng: 18.041421 },
    { lat: 47.946232, lng: 18.04131 },
    { lat: 47.946228, lng: 18.041091 },
    { lat: 47.946229, lng: 18.040994 },
    { lat: 47.946221, lng: 18.040762 },
    { lat: 47.946204, lng: 18.040542 },
    { lat: 47.946184, lng: 18.040254 },
    { lat: 47.946148, lng: 18.039984 },
    { lat: 47.946125, lng: 18.039715 },
    { lat: 47.946103, lng: 18.039487 },
    { lat: 47.946098, lng: 18.039427 },
    { lat: 47.946096, lng: 18.039414 },
    { lat: 47.946086, lng: 18.039303 },
    { lat: 47.946079, lng: 18.039217 },
    { lat: 47.946075, lng: 18.039168 },
    { lat: 47.946059, lng: 18.039007 },
    { lat: 47.946061, lng: 18.038812 },
    { lat: 47.946053, lng: 18.038622 },
    { lat: 47.946028, lng: 18.038424 },
    { lat: 47.946004, lng: 18.038236 },
    { lat: 47.945962, lng: 18.037978 },
    { lat: 47.945947, lng: 18.037824 },
    { lat: 47.945922, lng: 18.037572 },
    { lat: 47.945919, lng: 18.037542 },
    { lat: 47.945891, lng: 18.037345 },
    { lat: 47.945857, lng: 18.037098 },
    { lat: 47.94584, lng: 18.036976 },
    { lat: 47.945825, lng: 18.036876 },
    { lat: 47.945805, lng: 18.03675 },
    { lat: 47.945787, lng: 18.036631 },
    { lat: 47.945748, lng: 18.03639 },
    { lat: 47.945691, lng: 18.036134 },
    { lat: 47.945668, lng: 18.03603 },
    { lat: 47.945645, lng: 18.035928 },
    { lat: 47.945622, lng: 18.035823 },
    { lat: 47.945614, lng: 18.035727 },
    { lat: 47.945606, lng: 18.035632 },
    { lat: 47.9456, lng: 18.035552 },
    { lat: 47.945585, lng: 18.035375 },
    { lat: 47.945572, lng: 18.03522 },
    { lat: 47.94556, lng: 18.035073 },
    { lat: 47.945549, lng: 18.034943 },
    { lat: 47.945543, lng: 18.034871 },
    { lat: 47.945532, lng: 18.0348 },
    { lat: 47.945496, lng: 18.034567 },
    { lat: 47.945485, lng: 18.034489 },
    { lat: 47.945473, lng: 18.034272 },
    { lat: 47.945483, lng: 18.034099 },
    { lat: 47.945486, lng: 18.034055 },
    { lat: 47.94549, lng: 18.033981 },
    { lat: 47.945497, lng: 18.03386 },
    { lat: 47.945499, lng: 18.033814 },
    { lat: 47.945498, lng: 18.033791 },
    { lat: 47.945492, lng: 18.033728 },
    { lat: 47.94548, lng: 18.0336 },
    { lat: 47.945454, lng: 18.033323 },
    { lat: 47.945444, lng: 18.033219 },
    { lat: 47.945437, lng: 18.033099 },
    { lat: 47.945431, lng: 18.032975 },
    { lat: 47.945437, lng: 18.032854 },
    { lat: 47.945439, lng: 18.032806 },
    { lat: 47.945444, lng: 18.032715 },
    { lat: 47.945447, lng: 18.032653 },
    { lat: 47.945448, lng: 18.032586 },
    { lat: 47.945452, lng: 18.032476 },
    { lat: 47.945453, lng: 18.032415 },
    { lat: 47.945455, lng: 18.032347 },
    { lat: 47.94546, lng: 18.032177 },
    { lat: 47.945461, lng: 18.032117 },
    { lat: 47.945463, lng: 18.032053 },
    { lat: 47.945465, lng: 18.031992 },
    { lat: 47.945465, lng: 18.031957 },
    { lat: 47.945466, lng: 18.031932 },
    { lat: 47.945462, lng: 18.031855 },
    { lat: 47.945455, lng: 18.031711 },
    { lat: 47.945459, lng: 18.03139 },
    { lat: 47.945437, lng: 18.0312 },
    { lat: 47.945461, lng: 18.03108 },
    { lat: 47.945439, lng: 18.030892 },
    { lat: 47.945398, lng: 18.03053 },
    { lat: 47.945305, lng: 18.029714 },
    { lat: 47.945302, lng: 18.029688 },
    { lat: 47.945288, lng: 18.029391 },
    { lat: 47.945263, lng: 18.028909 },
    { lat: 47.945244, lng: 18.02851 },
    { lat: 47.945243, lng: 18.028491 },
    { lat: 47.94524, lng: 18.028356 },
    { lat: 47.945237, lng: 18.028249 },
    { lat: 47.945212, lng: 18.027897 },
    { lat: 47.945169, lng: 18.027293 },
    { lat: 47.945157, lng: 18.027128 },
    { lat: 47.945152, lng: 18.02704 },
    { lat: 47.94514, lng: 18.026743 },
    { lat: 47.945139, lng: 18.026713 },
    { lat: 47.945107, lng: 18.026373 },
    { lat: 47.945102, lng: 18.026353 },
    { lat: 47.945045, lng: 18.026065 },
    { lat: 47.945021, lng: 18.025938 },
    { lat: 47.945014, lng: 18.025912 },
    { lat: 47.944873, lng: 18.025372 },
    { lat: 47.94475, lng: 18.0249 },
    { lat: 47.944725, lng: 18.024809 },
    { lat: 47.944719, lng: 18.024761 },
    { lat: 47.944705, lng: 18.024666 },
    { lat: 47.944703, lng: 18.024654 },
    { lat: 47.944688, lng: 18.0246 },
    { lat: 47.944684, lng: 18.024593 },
    { lat: 47.944662, lng: 18.024556 },
    { lat: 47.944604, lng: 18.024461 },
    { lat: 47.944576, lng: 18.024432 },
    { lat: 47.944342, lng: 18.024189 },
    { lat: 47.944339, lng: 18.024177 },
    { lat: 47.944318, lng: 18.024109 },
    { lat: 47.944334, lng: 18.023837 },
    { lat: 47.944343, lng: 18.023675 },
    { lat: 47.944358, lng: 18.02336 },
    { lat: 47.944374, lng: 18.022997 },
    { lat: 47.944391, lng: 18.022652 },
    { lat: 47.944392, lng: 18.022616 },
    { lat: 47.944369, lng: 18.022483 },
    { lat: 47.944277, lng: 18.022306 },
    { lat: 47.944271, lng: 18.022297 },
    { lat: 47.944244, lng: 18.022262 },
    { lat: 47.944186, lng: 18.022201 },
    { lat: 47.944185, lng: 18.022191 },
    { lat: 47.94416, lng: 18.02204 },
    { lat: 47.944191, lng: 18.021533 },
    { lat: 47.944191, lng: 18.021511 },
    { lat: 47.944202, lng: 18.021255 },
    { lat: 47.944219, lng: 18.020829 },
    { lat: 47.94423, lng: 18.020528 },
    { lat: 47.944231, lng: 18.020516 },
    { lat: 47.944229, lng: 18.020486 },
    { lat: 47.944221, lng: 18.020387 },
    { lat: 47.944215, lng: 18.020312 },
    { lat: 47.944213, lng: 18.020289 },
    { lat: 47.944214, lng: 18.020284 },
    { lat: 47.944229, lng: 18.020158 },
    { lat: 47.944236, lng: 18.020094 },
    { lat: 47.944239, lng: 18.020071 },
    { lat: 47.944233, lng: 18.020055 },
    { lat: 47.944218, lng: 18.020026 },
    { lat: 47.944168, lng: 18.019915 },
    { lat: 47.944151, lng: 18.019877 },
    { lat: 47.944138, lng: 18.01985 },
    { lat: 47.944096, lng: 18.01923 },
    { lat: 47.944093, lng: 18.019184 },
    { lat: 47.944094, lng: 18.019129 },
    { lat: 47.944095, lng: 18.018783 },
    { lat: 47.944096, lng: 18.018762 },
    { lat: 47.944083, lng: 18.018631 },
    { lat: 47.94408, lng: 18.018597 },
    { lat: 47.944073, lng: 18.018574 },
    { lat: 47.944064, lng: 18.018523 },
    { lat: 47.944031, lng: 18.018331 },
    { lat: 47.944001, lng: 18.018169 },
    { lat: 47.94387, lng: 18.017957 },
    { lat: 47.943794, lng: 18.017833 },
    { lat: 47.94378, lng: 18.017804 },
    { lat: 47.943677, lng: 18.017572 },
    { lat: 47.943634, lng: 18.017473 },
    { lat: 47.943564, lng: 18.017315 },
    { lat: 47.943545, lng: 18.017272 },
    { lat: 47.943535, lng: 18.017243 },
    { lat: 47.943371, lng: 18.016802 },
    { lat: 47.943369, lng: 18.016794 },
    { lat: 47.94325, lng: 18.016476 },
    { lat: 47.943136, lng: 18.016165 },
    { lat: 47.943126, lng: 18.016139 },
    { lat: 47.943121, lng: 18.016117 },
    { lat: 47.94309, lng: 18.015998 },
    { lat: 47.943088, lng: 18.01599 },
    { lat: 47.942967, lng: 18.015513 },
    { lat: 47.942903, lng: 18.015265 },
    { lat: 47.942859, lng: 18.015092 },
    { lat: 47.942846, lng: 18.015039 },
    { lat: 47.942781, lng: 18.014781 },
    { lat: 47.942779, lng: 18.014776 },
    { lat: 47.942775, lng: 18.014757 },
    { lat: 47.942712, lng: 18.014515 },
    { lat: 47.942709, lng: 18.014507 },
    { lat: 47.942707, lng: 18.014498 },
    { lat: 47.94266, lng: 18.014243 },
    { lat: 47.942644, lng: 18.014152 },
    { lat: 47.942641, lng: 18.014142 },
    { lat: 47.942631, lng: 18.014064 },
    { lat: 47.942628, lng: 18.01404 },
    { lat: 47.942623, lng: 18.014006 },
    { lat: 47.942623, lng: 18.013996 },
    { lat: 47.942613, lng: 18.013927 },
    { lat: 47.942611, lng: 18.013905 },
    { lat: 47.942597, lng: 18.013802 },
    { lat: 47.942596, lng: 18.013787 },
    { lat: 47.942588, lng: 18.013736 },
    { lat: 47.942588, lng: 18.013727 },
    { lat: 47.94258, lng: 18.013668 },
    { lat: 47.942569, lng: 18.013591 },
    { lat: 47.942568, lng: 18.013584 },
    { lat: 47.942564, lng: 18.013552 },
    { lat: 47.942561, lng: 18.013536 },
    { lat: 47.942533, lng: 18.013318 },
    { lat: 47.942521, lng: 18.013231 },
    { lat: 47.942519, lng: 18.013218 },
    { lat: 47.942517, lng: 18.013194 },
    { lat: 47.942514, lng: 18.013161 },
    { lat: 47.942511, lng: 18.013105 },
    { lat: 47.942508, lng: 18.013072 },
    { lat: 47.942509, lng: 18.013024 },
    { lat: 47.942515, lng: 18.012796 },
    { lat: 47.942515, lng: 18.012745 },
    { lat: 47.942516, lng: 18.012658 },
    { lat: 47.942515, lng: 18.012611 },
    { lat: 47.942516, lng: 18.012584 },
    { lat: 47.942514, lng: 18.012531 },
    { lat: 47.942499, lng: 18.012046 },
    { lat: 47.942483, lng: 18.011501 },
    { lat: 47.9424224, lng: 18.0092064 },
    { lat: 47.942938, lng: 18.0086204 },
    { lat: 47.9435233, lng: 18.0078237 },
    { lat: 47.9429023, lng: 18.0076332 },
    { lat: 47.9420636, lng: 18.0071636 },
    { lat: 47.9414236, lng: 18.0067314 },
    { lat: 47.9406902, lng: 18.0059241 },
    { lat: 47.9398321, lng: 18.0045311 },
    { lat: 47.937275, lng: 17.9984016 },
    { lat: 47.9370986, lng: 17.9980961 },
    { lat: 47.9366979, lng: 17.9976081 },
    { lat: 47.9363125, lng: 17.9972843 },
    { lat: 47.9357863, lng: 17.9969972 },
    { lat: 47.9334568, lng: 17.9968115 },
    { lat: 47.9330926, lng: 17.9966875 },
    { lat: 47.9301807, lng: 17.9923004 },
    { lat: 47.9300665, lng: 17.9917353 },
    { lat: 47.9300661, lng: 17.9911871 },
    { lat: 47.9301259, lng: 17.9908694 },
    { lat: 47.9311803, lng: 17.9878749 },
    { lat: 47.9323362, lng: 17.9854787 },
    { lat: 47.9316034, lng: 17.984406 },
    { lat: 47.9316325, lng: 17.9843131 },
    { lat: 47.9315074, lng: 17.9841278 },
    { lat: 47.931438, lng: 17.9841744 },
    { lat: 47.9304096, lng: 17.9827857 },
    { lat: 47.9304697, lng: 17.9826605 },
    { lat: 47.9302375, lng: 17.9823086 },
    { lat: 47.9306291, lng: 17.9818241 },
    { lat: 47.9316418, lng: 17.9821256 },
    { lat: 47.9321876, lng: 17.9820422 },
    { lat: 47.9326062, lng: 17.9814611 },
    { lat: 47.933651, lng: 17.9802904 },
    { lat: 47.9344224, lng: 17.9793872 },
    { lat: 47.9349741, lng: 17.9790427 },
    { lat: 47.9353156, lng: 17.9784415 },
    { lat: 47.936228, lng: 17.9776793 },
    { lat: 47.9372556, lng: 17.9766795 },
    { lat: 47.9378653, lng: 17.9759342 },
    { lat: 47.9385918, lng: 17.9747839 },
    { lat: 47.9398513, lng: 17.9723725 },
    { lat: 47.9408557, lng: 17.9707266 },
    { lat: 47.9413202, lng: 17.9698228 },
    { lat: 47.9419866, lng: 17.9679421 },
    { lat: 47.942015, lng: 17.9678908 },
    { lat: 47.9421493, lng: 17.9679644 },
    { lat: 47.9421948, lng: 17.9677471 },
    { lat: 47.9420597, lng: 17.9676567 },
    { lat: 47.9421241, lng: 17.9656617 },
    { lat: 47.9430069, lng: 17.9656244 },
    { lat: 47.9441517, lng: 17.9660048 },
    { lat: 47.9441679, lng: 17.966104 },
    { lat: 47.9442809, lng: 17.9661064 },
    { lat: 47.9443729, lng: 17.9658917 },
    { lat: 47.9444127, lng: 17.9656157 },
    { lat: 47.9446717, lng: 17.9657566 },
    { lat: 47.9462979, lng: 17.9670568 },
    { lat: 47.9469983, lng: 17.9672373 },
    { lat: 47.9472864, lng: 17.9672227 },
    { lat: 47.9635167, lng: 17.9634396 },
    { lat: 47.9638738, lng: 17.9633342 },
    { lat: 47.9641807, lng: 17.9632177 },
    { lat: 47.9646172, lng: 17.9629348 },
    { lat: 47.9650249, lng: 17.9625456 },
    { lat: 47.9653713, lng: 17.9620407 },
    { lat: 47.965495, lng: 17.962154 },
  ],
  Moča: [
    { lat: 47.802317, lng: 18.3970603 },
    { lat: 47.8022885, lng: 18.3970316 },
    { lat: 47.8008977, lng: 18.3958673 },
    { lat: 47.800104, lng: 18.3953565 },
    { lat: 47.7995243, lng: 18.3947448 },
    { lat: 47.7991152, lng: 18.394156 },
    { lat: 47.79837, lng: 18.3929149 },
    { lat: 47.7975816, lng: 18.3914715 },
    { lat: 47.7956028, lng: 18.3885514 },
    { lat: 47.7941104, lng: 18.389072 },
    { lat: 47.7937811, lng: 18.3890564 },
    { lat: 47.7933792, lng: 18.3888895 },
    { lat: 47.7911166, lng: 18.3886045 },
    { lat: 47.7907542, lng: 18.3883805 },
    { lat: 47.7895503, lng: 18.3866113 },
    { lat: 47.7891366, lng: 18.3861207 },
    { lat: 47.7881841, lng: 18.385402 },
    { lat: 47.7875313, lng: 18.3847948 },
    { lat: 47.7856508, lng: 18.3836887 },
    { lat: 47.7835564, lng: 18.3825698 },
    { lat: 47.7823315, lng: 18.3818215 },
    { lat: 47.7816489, lng: 18.3811266 },
    { lat: 47.7798943, lng: 18.3784869 },
    { lat: 47.7789473, lng: 18.3772521 },
    { lat: 47.7783455, lng: 18.3768096 },
    { lat: 47.7779503, lng: 18.3763874 },
    { lat: 47.7764306, lng: 18.3736365 },
    { lat: 47.7764188, lng: 18.3736246 },
    { lat: 47.775169, lng: 18.3751156 },
    { lat: 47.7733069, lng: 18.377541 },
    { lat: 47.7697505, lng: 18.3803648 },
    { lat: 47.7697683, lng: 18.3804405 },
    { lat: 47.7681134, lng: 18.3812177 },
    { lat: 47.7665798, lng: 18.382574 },
    { lat: 47.7633505, lng: 18.3847878 },
    { lat: 47.7602259, lng: 18.386343 },
    { lat: 47.757952, lng: 18.3875616 },
    { lat: 47.7563236, lng: 18.3886254 },
    { lat: 47.7542674, lng: 18.3881353 },
    { lat: 47.752946, lng: 18.3897965 },
    { lat: 47.7510595, lng: 18.3915704 },
    { lat: 47.7513588, lng: 18.3930894 },
    { lat: 47.7516706, lng: 18.3950255 },
    { lat: 47.7518628, lng: 18.3966712 },
    { lat: 47.7520231, lng: 18.3986791 },
    { lat: 47.752139, lng: 18.4006938 },
    { lat: 47.7522106, lng: 18.4027129 },
    { lat: 47.7523079, lng: 18.4049347 },
    { lat: 47.7523213, lng: 18.4050809 },
    { lat: 47.7524907, lng: 18.4069408 },
    { lat: 47.7527321, lng: 18.4089332 },
    { lat: 47.7530318, lng: 18.410908 },
    { lat: 47.7533894, lng: 18.4128617 },
    { lat: 47.7539773, lng: 18.4157377 },
    { lat: 47.7544043, lng: 18.417759 },
    { lat: 47.754861, lng: 18.4197659 },
    { lat: 47.7550289, lng: 18.4204496 },
    { lat: 47.7551756, lng: 18.4212244 },
    { lat: 47.7552836, lng: 18.4220131 },
    { lat: 47.7553522, lng: 18.4228115 },
    { lat: 47.755381, lng: 18.423615 },
    { lat: 47.7555078, lng: 18.4280757 },
    { lat: 47.7555765, lng: 18.4289168 },
    { lat: 47.75578, lng: 18.4299443 },
    { lat: 47.7560391, lng: 18.4309438 },
    { lat: 47.7563519, lng: 18.4319089 },
    { lat: 47.7567164, lng: 18.4328333 },
    { lat: 47.7583954, lng: 18.4360285 },
    { lat: 47.7590493, lng: 18.4372628 },
    { lat: 47.7597514, lng: 18.4384374 },
    { lat: 47.760664, lng: 18.4398346 },
    { lat: 47.7613626, lng: 18.4410701 },
    { lat: 47.7619883, lng: 18.4423884 },
    { lat: 47.7635415, lng: 18.4459618 },
    { lat: 47.7639427, lng: 18.4470525 },
    { lat: 47.7642918, lng: 18.4481822 },
    { lat: 47.7645871, lng: 18.4493452 },
    { lat: 47.7648273, lng: 18.4505357 },
    { lat: 47.7649865, lng: 18.4515865 },
    { lat: 47.7659163, lng: 18.4509932 },
    { lat: 47.7691375, lng: 18.4482976 },
    { lat: 47.7701983, lng: 18.4475437 },
    { lat: 47.7718309, lng: 18.4459848 },
    { lat: 47.7727688, lng: 18.4447912 },
    { lat: 47.7752556, lng: 18.4399616 },
    { lat: 47.7753181, lng: 18.4398665 },
    { lat: 47.7773098, lng: 18.4368188 },
    { lat: 47.7788019, lng: 18.4324981 },
    { lat: 47.7797622, lng: 18.4298665 },
    { lat: 47.7816406, lng: 18.425739 },
    { lat: 47.7831961, lng: 18.42372 },
    { lat: 47.7866295, lng: 18.4194968 },
    { lat: 47.7869531, lng: 18.4188781 },
    { lat: 47.7884823, lng: 18.4168939 },
    { lat: 47.7893285, lng: 18.415702 },
    { lat: 47.7918869, lng: 18.4126102 },
    { lat: 47.7935877, lng: 18.4107502 },
    { lat: 47.7949591, lng: 18.4093701 },
    { lat: 47.7957434, lng: 18.4083961 },
    { lat: 47.7957757, lng: 18.4083486 },
    { lat: 47.801191, lng: 18.3998426 },
    { lat: 47.802317, lng: 18.3970603 },
  ],
  Číčov: [
    { lat: 47.7588991, lng: 17.7055077 },
    { lat: 47.75736, lng: 17.707338 },
    { lat: 47.756678, lng: 17.708579 },
    { lat: 47.756082, lng: 17.709914 },
    { lat: 47.75558, lng: 17.711332 },
    { lat: 47.755175, lng: 17.712816 },
    { lat: 47.754182, lng: 17.718313 },
    { lat: 47.754028, lng: 17.720153 },
    { lat: 47.75396, lng: 17.722004 },
    { lat: 47.753971, lng: 17.723153 },
    { lat: 47.753978, lng: 17.723858 },
    { lat: 47.754031, lng: 17.7262 },
    { lat: 47.754049, lng: 17.72707 },
    { lat: 47.753944, lng: 17.728696 },
    { lat: 47.753923, lng: 17.728996 },
    { lat: 47.753832, lng: 17.729775 },
    { lat: 47.753698, lng: 17.730902 },
    { lat: 47.753657, lng: 17.731145 },
    { lat: 47.753511, lng: 17.731997 },
    { lat: 47.753414, lng: 17.732559 },
    { lat: 47.753377, lng: 17.732776 },
    { lat: 47.753051, lng: 17.734206 },
    { lat: 47.752959, lng: 17.734609 },
    { lat: 47.752878, lng: 17.734976 },
    { lat: 47.75269, lng: 17.735834 },
    { lat: 47.752585, lng: 17.736315 },
    { lat: 47.752436, lng: 17.736998 },
    { lat: 47.752143, lng: 17.738336 },
    { lat: 47.751812, lng: 17.739752 },
    { lat: 47.751751, lng: 17.740018 },
    { lat: 47.751561, lng: 17.740686 },
    { lat: 47.751433, lng: 17.741135 },
    { lat: 47.751314, lng: 17.741555 },
    { lat: 47.751284, lng: 17.741658 },
    { lat: 47.751141, lng: 17.742081 },
    { lat: 47.750967, lng: 17.742595 },
    { lat: 47.750757, lng: 17.743213 },
    { lat: 47.7524779, lng: 17.7416677 },
    { lat: 47.7532658, lng: 17.74062 },
    { lat: 47.7546126, lng: 17.738314 },
    { lat: 47.7557285, lng: 17.7365404 },
    { lat: 47.7569855, lng: 17.7369262 },
    { lat: 47.7576652, lng: 17.7365111 },
    { lat: 47.7581873, lng: 17.7352066 },
    { lat: 47.759311, lng: 17.7338628 },
    { lat: 47.7603924, lng: 17.7329059 },
    { lat: 47.7606617, lng: 17.7335817 },
    { lat: 47.7611491, lng: 17.736547 },
    { lat: 47.7638298, lng: 17.7439743 },
    { lat: 47.7619456, lng: 17.74659 },
    { lat: 47.7590615, lng: 17.7460536 },
    { lat: 47.7590489, lng: 17.7487393 },
    { lat: 47.7584847, lng: 17.7511768 },
    { lat: 47.7574266, lng: 17.7535183 },
    { lat: 47.7566184, lng: 17.7560664 },
    { lat: 47.75576, lng: 17.758062 },
    { lat: 47.7550691, lng: 17.7601567 },
    { lat: 47.7544417, lng: 17.762473 },
    { lat: 47.7540081, lng: 17.7650419 },
    { lat: 47.7534355, lng: 17.7677095 },
    { lat: 47.7530281, lng: 17.7728145 },
    { lat: 47.7530833, lng: 17.7766681 },
    { lat: 47.7548601, lng: 17.7788184 },
    { lat: 47.7559104, lng: 17.7803102 },
    { lat: 47.75718, lng: 17.7824958 },
    { lat: 47.7584995, lng: 17.7837253 },
    { lat: 47.7596043, lng: 17.7844967 },
    { lat: 47.7605045, lng: 17.7855185 },
    { lat: 47.7613409, lng: 17.7849752 },
    { lat: 47.76344, lng: 17.7865563 },
    { lat: 47.7645121, lng: 17.7870944 },
    { lat: 47.7671068, lng: 17.784254 },
    { lat: 47.7691959, lng: 17.7822529 },
    { lat: 47.7703312, lng: 17.7813229 },
    { lat: 47.7715678, lng: 17.7804048 },
    { lat: 47.772738, lng: 17.7797461 },
    { lat: 47.7744742, lng: 17.7789654 },
    { lat: 47.7755094, lng: 17.7782925 },
    { lat: 47.7763512, lng: 17.7769999 },
    { lat: 47.7781242, lng: 17.7794539 },
    { lat: 47.7796232, lng: 17.7806676 },
    { lat: 47.7804262, lng: 17.7815617 },
    { lat: 47.7813225, lng: 17.7828108 },
    { lat: 47.7816953, lng: 17.7830679 },
    { lat: 47.7818737, lng: 17.7830501 },
    { lat: 47.7820144, lng: 17.7829529 },
    { lat: 47.7829439, lng: 17.7812709 },
    { lat: 47.7832951, lng: 17.7808938 },
    { lat: 47.7840949, lng: 17.780939 },
    { lat: 47.7845736, lng: 17.7811566 },
    { lat: 47.7848697, lng: 17.7814147 },
    { lat: 47.7872125, lng: 17.7841582 },
    { lat: 47.7888211, lng: 17.7846254 },
    { lat: 47.7887918, lng: 17.786895 },
    { lat: 47.7888348, lng: 17.7871138 },
    { lat: 47.7891812, lng: 17.7879432 },
    { lat: 47.7898833, lng: 17.7884332 },
    { lat: 47.7899209, lng: 17.7886577 },
    { lat: 47.7898455, lng: 17.789077 },
    { lat: 47.7894926, lng: 17.7896108 },
    { lat: 47.7893912, lng: 17.7898518 },
    { lat: 47.7892012, lng: 17.7910791 },
    { lat: 47.7888399, lng: 17.7921318 },
    { lat: 47.7897897, lng: 17.7928477 },
    { lat: 47.7929067, lng: 17.7948268 },
    { lat: 47.7939229, lng: 17.7954123 },
    { lat: 47.7945367, lng: 17.7957345 },
    { lat: 47.7953325, lng: 17.7960044 },
    { lat: 47.7961161, lng: 17.7961316 },
    { lat: 47.7960921, lng: 17.7971074 },
    { lat: 47.7957686, lng: 17.7995885 },
    { lat: 47.7957429, lng: 17.8001576 },
    { lat: 47.7957486, lng: 17.8006645 },
    { lat: 47.7961411, lng: 17.8004984 },
    { lat: 47.7976392, lng: 17.7995338 },
    { lat: 47.7981236, lng: 17.796848 },
    { lat: 47.7980806, lng: 17.7964642 },
    { lat: 47.7983916, lng: 17.7964174 },
    { lat: 47.799865, lng: 17.7966002 },
    { lat: 47.8007862, lng: 17.7962547 },
    { lat: 47.8008955, lng: 17.7970691 },
    { lat: 47.8009821, lng: 17.7991 },
    { lat: 47.8015762, lng: 17.7992153 },
    { lat: 47.8015581, lng: 17.7995405 },
    { lat: 47.8028677, lng: 17.8002679 },
    { lat: 47.8041918, lng: 17.8006841 },
    { lat: 47.8046615, lng: 17.8007782 },
    { lat: 47.8063394, lng: 17.8007663 },
    { lat: 47.8080285, lng: 17.8025495 },
    { lat: 47.8103331, lng: 17.8035417 },
    { lat: 47.8108194, lng: 17.8036663 },
    { lat: 47.8108987, lng: 17.8037969 },
    { lat: 47.8116779, lng: 17.8025799 },
    { lat: 47.8112857, lng: 17.8018301 },
    { lat: 47.8109613, lng: 17.8000571 },
    { lat: 47.8108754, lng: 17.7990388 },
    { lat: 47.8109103, lng: 17.7989645 },
    { lat: 47.8119451, lng: 17.7987407 },
    { lat: 47.8122956, lng: 17.7985639 },
    { lat: 47.8146867, lng: 17.7931515 },
    { lat: 47.8153602, lng: 17.7920706 },
    { lat: 47.8162943, lng: 17.7911179 },
    { lat: 47.8170433, lng: 17.7910873 },
    { lat: 47.8178189, lng: 17.7915434 },
    { lat: 47.8181277, lng: 17.7918446 },
    { lat: 47.8185083, lng: 17.792229 },
    { lat: 47.8194903, lng: 17.7935152 },
    { lat: 47.8197647, lng: 17.7935539 },
    { lat: 47.820596, lng: 17.792816 },
    { lat: 47.820279, lng: 17.790698 },
    { lat: 47.819683, lng: 17.788423 },
    { lat: 47.819429, lng: 17.787617 },
    { lat: 47.819415, lng: 17.78757 },
    { lat: 47.819388, lng: 17.787503 },
    { lat: 47.818864, lng: 17.786153 },
    { lat: 47.818522, lng: 17.784849 },
    { lat: 47.81822, lng: 17.782477 },
    { lat: 47.818149, lng: 17.781939 },
    { lat: 47.818118, lng: 17.781707 },
    { lat: 47.818113, lng: 17.781565 },
    { lat: 47.817553, lng: 17.779054 },
    { lat: 47.81718, lng: 17.778095 },
    { lat: 47.817135, lng: 17.777983 },
    { lat: 47.816557, lng: 17.776497 },
    { lat: 47.8162854, lng: 17.7758044 },
    { lat: 47.816139, lng: 17.775431 },
    { lat: 47.8153478, lng: 17.7735111 },
    { lat: 47.814186, lng: 17.770692 },
    { lat: 47.813928, lng: 17.770349 },
    { lat: 47.813423, lng: 17.769683 },
    { lat: 47.813018, lng: 17.76938 },
    { lat: 47.8126187, lng: 17.7690805 },
    { lat: 47.81259, lng: 17.769059 },
    { lat: 47.811015, lng: 17.768357 },
    { lat: 47.810481, lng: 17.768144 },
    { lat: 47.808862, lng: 17.76754 },
    { lat: 47.808362, lng: 17.767354 },
    { lat: 47.807762, lng: 17.766964 },
    { lat: 47.807338, lng: 17.766665 },
    { lat: 47.807134, lng: 17.766421 },
    { lat: 47.806262, lng: 17.765308 },
    { lat: 47.805522, lng: 17.763921 },
    { lat: 47.805011, lng: 17.762976 },
    { lat: 47.804616, lng: 17.762692 },
    { lat: 47.804314, lng: 17.762678 },
    { lat: 47.803322, lng: 17.762641 },
    { lat: 47.802068, lng: 17.762601 },
    { lat: 47.802143, lng: 17.762336 },
    { lat: 47.802235, lng: 17.762007 },
    { lat: 47.802315, lng: 17.761722 },
    { lat: 47.802409, lng: 17.761391 },
    { lat: 47.80245, lng: 17.761242 },
    { lat: 47.802469, lng: 17.761109 },
    { lat: 47.802509, lng: 17.760813 },
    { lat: 47.802554, lng: 17.760493 },
    { lat: 47.8026, lng: 17.760168 },
    { lat: 47.802647, lng: 17.759838 },
    { lat: 47.802691, lng: 17.759533 },
    { lat: 47.802746, lng: 17.759157 },
    { lat: 47.802996, lng: 17.758668 },
    { lat: 47.803139, lng: 17.758389 },
    { lat: 47.803265, lng: 17.758143 },
    { lat: 47.803424, lng: 17.757832 },
    { lat: 47.803471, lng: 17.757739 },
    { lat: 47.803649, lng: 17.757707 },
    { lat: 47.803761, lng: 17.757686 },
    { lat: 47.803867, lng: 17.757668 },
    { lat: 47.803931, lng: 17.757655 },
    { lat: 47.804099, lng: 17.757566 },
    { lat: 47.804361, lng: 17.757428 },
    { lat: 47.804476, lng: 17.757367 },
    { lat: 47.804573, lng: 17.757315 },
    { lat: 47.804634, lng: 17.757244 },
    { lat: 47.804828, lng: 17.757016 },
    { lat: 47.80505, lng: 17.756678 },
    { lat: 47.805453, lng: 17.756063 },
    { lat: 47.805717, lng: 17.755662 },
    { lat: 47.805894, lng: 17.755392 },
    { lat: 47.80605, lng: 17.755102 },
    { lat: 47.80622, lng: 17.754785 },
    { lat: 47.806213, lng: 17.754741 },
    { lat: 47.806086, lng: 17.753912 },
    { lat: 47.80605, lng: 17.753684 },
    { lat: 47.805972, lng: 17.753602 },
    { lat: 47.805832, lng: 17.753457 },
    { lat: 47.805728, lng: 17.753351 },
    { lat: 47.805645, lng: 17.753264 },
    { lat: 47.805565, lng: 17.753185 },
    { lat: 47.805386, lng: 17.753006 },
    { lat: 47.805289, lng: 17.752911 },
    { lat: 47.805236, lng: 17.752858 },
    { lat: 47.805145, lng: 17.752767 },
    { lat: 47.805121, lng: 17.752707 },
    { lat: 47.804817, lng: 17.751942 },
    { lat: 47.80462, lng: 17.751304 },
    { lat: 47.804541, lng: 17.750647 },
    { lat: 47.804448, lng: 17.750312 },
    { lat: 47.804368, lng: 17.750016 },
    { lat: 47.805055, lng: 17.750047 },
    { lat: 47.805682, lng: 17.749928 },
    { lat: 47.805796, lng: 17.749833 },
    { lat: 47.806094, lng: 17.749583 },
    { lat: 47.8064, lng: 17.749326 },
    { lat: 47.806433, lng: 17.749298 },
    { lat: 47.806597, lng: 17.749119 },
    { lat: 47.806793, lng: 17.748905 },
    { lat: 47.806818, lng: 17.748878 },
    { lat: 47.806994, lng: 17.748632 },
    { lat: 47.80719, lng: 17.748357 },
    { lat: 47.807298, lng: 17.748147 },
    { lat: 47.807392, lng: 17.747963 },
    { lat: 47.807443, lng: 17.747862 },
    { lat: 47.807472, lng: 17.747661 },
    { lat: 47.807503, lng: 17.747446 },
    { lat: 47.807506, lng: 17.747156 },
    { lat: 47.807511, lng: 17.746818 },
    { lat: 47.807511, lng: 17.746736 },
    { lat: 47.807483, lng: 17.746318 },
    { lat: 47.807479, lng: 17.746258 },
    { lat: 47.807359, lng: 17.745792 },
    { lat: 47.807277, lng: 17.745476 },
    { lat: 47.807109, lng: 17.744829 },
    { lat: 47.806851, lng: 17.743893 },
    { lat: 47.806775, lng: 17.743618 },
    { lat: 47.806673, lng: 17.743245 },
    { lat: 47.806148, lng: 17.742205 },
    { lat: 47.80614, lng: 17.742135 },
    { lat: 47.806102, lng: 17.741829 },
    { lat: 47.806186, lng: 17.741434 },
    { lat: 47.806374, lng: 17.741017 },
    { lat: 47.806405, lng: 17.740949 },
    { lat: 47.806501, lng: 17.740749 },
    { lat: 47.80679, lng: 17.740213 },
    { lat: 47.806855, lng: 17.740094 },
    { lat: 47.807103, lng: 17.739696 },
    { lat: 47.807198, lng: 17.739544 },
    { lat: 47.808009, lng: 17.738907 },
    { lat: 47.808016, lng: 17.738898 },
    { lat: 47.808081, lng: 17.738819 },
    { lat: 47.808094, lng: 17.738803 },
    { lat: 47.808576, lng: 17.73821 },
    { lat: 47.808636, lng: 17.738063 },
    { lat: 47.808678, lng: 17.73796 },
    { lat: 47.808784, lng: 17.737704 },
    { lat: 47.808812, lng: 17.737634 },
    { lat: 47.808562, lng: 17.737276 },
    { lat: 47.808361, lng: 17.737064 },
    { lat: 47.80821, lng: 17.736949 },
    { lat: 47.808148, lng: 17.73691 },
    { lat: 47.807749, lng: 17.736724 },
    { lat: 47.807009, lng: 17.736287 },
    { lat: 47.806662, lng: 17.735814 },
    { lat: 47.806521, lng: 17.735547 },
    { lat: 47.806489, lng: 17.735463 },
    { lat: 47.806464, lng: 17.735349 },
    { lat: 47.806437, lng: 17.734955 },
    { lat: 47.806443, lng: 17.734706 },
    { lat: 47.80648, lng: 17.734182 },
    { lat: 47.806486, lng: 17.733518 },
    { lat: 47.806499, lng: 17.733121 },
    { lat: 47.806391, lng: 17.73288 },
    { lat: 47.80611, lng: 17.73255 },
    { lat: 47.805595, lng: 17.732038 },
    { lat: 47.805521, lng: 17.73195 },
    { lat: 47.805446, lng: 17.731838 },
    { lat: 47.805375, lng: 17.731702 },
    { lat: 47.805209, lng: 17.73101 },
    { lat: 47.805028, lng: 17.730187 },
    { lat: 47.804975, lng: 17.730024 },
    { lat: 47.804863, lng: 17.729818 },
    { lat: 47.804754, lng: 17.729702 },
    { lat: 47.8047, lng: 17.72968 },
    { lat: 47.804576, lng: 17.72969 },
    { lat: 47.8044, lng: 17.729772 },
    { lat: 47.804344, lng: 17.729846 },
    { lat: 47.804271, lng: 17.729999 },
    { lat: 47.804199, lng: 17.730197 },
    { lat: 47.804155, lng: 17.730353 },
    { lat: 47.803893, lng: 17.731415 },
    { lat: 47.803857, lng: 17.731387 },
    { lat: 47.803716, lng: 17.73128 },
    { lat: 47.803643, lng: 17.731223 },
    { lat: 47.803605, lng: 17.731194 },
    { lat: 47.802975, lng: 17.730715 },
    { lat: 47.802765, lng: 17.730572 },
    { lat: 47.802344, lng: 17.730282 },
    { lat: 47.802131, lng: 17.730163 },
    { lat: 47.801863, lng: 17.730011 },
    { lat: 47.801694, lng: 17.729916 },
    { lat: 47.8012, lng: 17.729613 },
    { lat: 47.800374, lng: 17.729107 },
    { lat: 47.800257, lng: 17.729036 },
    { lat: 47.800174, lng: 17.728985 },
    { lat: 47.800093, lng: 17.72893 },
    { lat: 47.80005, lng: 17.728899 },
    { lat: 47.799808, lng: 17.72872 },
    { lat: 47.799563, lng: 17.728538 },
    { lat: 47.798727, lng: 17.728251 },
    { lat: 47.797742, lng: 17.727977 },
    { lat: 47.797254, lng: 17.727848 },
    { lat: 47.797032, lng: 17.727793 },
    { lat: 47.79698, lng: 17.727781 },
    { lat: 47.796924, lng: 17.727769 },
    { lat: 47.796706, lng: 17.727723 },
    { lat: 47.796358, lng: 17.727654 },
    { lat: 47.796054, lng: 17.727594 },
    { lat: 47.795739, lng: 17.727525 },
    { lat: 47.795559, lng: 17.727485 },
    { lat: 47.7954, lng: 17.72745 },
    { lat: 47.795104, lng: 17.727385 },
    { lat: 47.794955, lng: 17.727354 },
    { lat: 47.794807, lng: 17.727322 },
    { lat: 47.794297, lng: 17.726694 },
    { lat: 47.794171, lng: 17.726614 },
    { lat: 47.794042, lng: 17.72654 },
    { lat: 47.793883, lng: 17.726447 },
    { lat: 47.79382, lng: 17.726412 },
    { lat: 47.79341, lng: 17.728169 },
    { lat: 47.793399, lng: 17.728216 },
    { lat: 47.793132, lng: 17.729378 },
    { lat: 47.793018, lng: 17.729811 },
    { lat: 47.792984, lng: 17.729954 },
    { lat: 47.79294, lng: 17.730168 },
    { lat: 47.792788, lng: 17.730184 },
    { lat: 47.792657, lng: 17.730355 },
    { lat: 47.792584, lng: 17.730393 },
    { lat: 47.792483, lng: 17.730426 },
    { lat: 47.792411, lng: 17.730514 },
    { lat: 47.792257, lng: 17.730969 },
    { lat: 47.792185, lng: 17.731203 },
    { lat: 47.792168, lng: 17.731577 },
    { lat: 47.792195, lng: 17.731916 },
    { lat: 47.792265, lng: 17.732322 },
    { lat: 47.792385, lng: 17.732687 },
    { lat: 47.792522, lng: 17.733172 },
    { lat: 47.792572, lng: 17.733391 },
    { lat: 47.792628, lng: 17.733711 },
    { lat: 47.792633, lng: 17.733881 },
    { lat: 47.792606, lng: 17.733992 },
    { lat: 47.792545, lng: 17.734158 },
    { lat: 47.792485, lng: 17.734278 },
    { lat: 47.792412, lng: 17.73436 },
    { lat: 47.792251, lng: 17.734503 },
    { lat: 47.791993, lng: 17.734588 },
    { lat: 47.79149, lng: 17.734858 },
    { lat: 47.791323, lng: 17.734848 },
    { lat: 47.791142, lng: 17.734747 },
    { lat: 47.790258, lng: 17.734239 },
    { lat: 47.790069, lng: 17.734123 },
    { lat: 47.789844, lng: 17.73396 },
    { lat: 47.789474, lng: 17.733622 },
    { lat: 47.788984, lng: 17.733157 },
    { lat: 47.788854, lng: 17.733004 },
    { lat: 47.78872, lng: 17.732806 },
    { lat: 47.788619, lng: 17.732622 },
    { lat: 47.788571, lng: 17.732453 },
    { lat: 47.788516, lng: 17.732276 },
    { lat: 47.788486, lng: 17.732052 },
    { lat: 47.788486, lng: 17.73194 },
    { lat: 47.788523, lng: 17.731481 },
    { lat: 47.788654, lng: 17.730633 },
    { lat: 47.788765, lng: 17.73002 },
    { lat: 47.788816, lng: 17.729593 },
    { lat: 47.788857, lng: 17.728898 },
    { lat: 47.788828, lng: 17.72844 },
    { lat: 47.788833, lng: 17.728229 },
    { lat: 47.788799, lng: 17.727696 },
    { lat: 47.78872, lng: 17.727453 },
    { lat: 47.788591, lng: 17.727214 },
    { lat: 47.788502, lng: 17.727103 },
    { lat: 47.788152, lng: 17.726839 },
    { lat: 47.78797, lng: 17.726795 },
    { lat: 47.787394, lng: 17.726848 },
    { lat: 47.787222, lng: 17.726904 },
    { lat: 47.787105, lng: 17.726958 },
    { lat: 47.786884, lng: 17.727138 },
    { lat: 47.786752, lng: 17.727256 },
    { lat: 47.786616, lng: 17.727427 },
    { lat: 47.786452, lng: 17.727697 },
    { lat: 47.786345, lng: 17.727949 },
    { lat: 47.786237, lng: 17.728293 },
    { lat: 47.786179, lng: 17.728573 },
    { lat: 47.786109, lng: 17.728944 },
    { lat: 47.785998, lng: 17.729463 },
    { lat: 47.785938, lng: 17.729681 },
    { lat: 47.785876, lng: 17.729879 },
    { lat: 47.785821, lng: 17.730085 },
    { lat: 47.785706, lng: 17.73041 },
    { lat: 47.785579, lng: 17.730722 },
    { lat: 47.785518, lng: 17.730845 },
    { lat: 47.78543, lng: 17.730975 },
    { lat: 47.785368, lng: 17.731049 },
    { lat: 47.785336, lng: 17.731084 },
    { lat: 47.785277, lng: 17.731099 },
    { lat: 47.785185, lng: 17.731097 },
    { lat: 47.785048, lng: 17.731002 },
    { lat: 47.784967, lng: 17.730868 },
    { lat: 47.784884, lng: 17.730691 },
    { lat: 47.784855, lng: 17.73052 },
    { lat: 47.784835, lng: 17.730356 },
    { lat: 47.78471, lng: 17.729513 },
    { lat: 47.784524, lng: 17.728433 },
    { lat: 47.784555, lng: 17.728108 },
    { lat: 47.784643, lng: 17.727793 },
    { lat: 47.785026, lng: 17.726924 },
    { lat: 47.785169, lng: 17.726334 },
    { lat: 47.785144, lng: 17.72581 },
    { lat: 47.785118, lng: 17.725736 },
    { lat: 47.784936, lng: 17.72543 },
    { lat: 47.784915, lng: 17.725367 },
    { lat: 47.784878, lng: 17.725184 },
    { lat: 47.784873, lng: 17.725122 },
    { lat: 47.784472, lng: 17.724697 },
    { lat: 47.7844, lng: 17.724767 },
    { lat: 47.784287, lng: 17.724546 },
    { lat: 47.783983, lng: 17.72506 },
    { lat: 47.78352, lng: 17.72567 },
    { lat: 47.783187, lng: 17.725978 },
    { lat: 47.782201, lng: 17.726833 },
    { lat: 47.781326, lng: 17.727484 },
    { lat: 47.780358, lng: 17.728223 },
    { lat: 47.779989, lng: 17.728451 },
    { lat: 47.779513, lng: 17.728694 },
    { lat: 47.778007, lng: 17.729269 },
    { lat: 47.777698, lng: 17.729348 },
    { lat: 47.777476, lng: 17.729487 },
    { lat: 47.776627, lng: 17.729768 },
    { lat: 47.775771, lng: 17.730052 },
    { lat: 47.775767, lng: 17.729944 },
    { lat: 47.775353, lng: 17.728751 },
    { lat: 47.774984, lng: 17.728346 },
    { lat: 47.774692, lng: 17.728095 },
    { lat: 47.774383, lng: 17.727826 },
    { lat: 47.773901, lng: 17.727201 },
    { lat: 47.773585, lng: 17.726742 },
    { lat: 47.77343, lng: 17.726594 },
    { lat: 47.772875, lng: 17.72617 },
    { lat: 47.772668, lng: 17.72594 },
    { lat: 47.772191, lng: 17.725534 },
    { lat: 47.771759, lng: 17.725193 },
    { lat: 47.771679, lng: 17.725128 },
    { lat: 47.771315, lng: 17.724811 },
    { lat: 47.771011, lng: 17.724632 },
    { lat: 47.770929, lng: 17.724535 },
    { lat: 47.770776, lng: 17.724208 },
    { lat: 47.770458, lng: 17.723531 },
    { lat: 47.770328, lng: 17.723261 },
    { lat: 47.770107, lng: 17.722789 },
    { lat: 47.769841, lng: 17.722221 },
    { lat: 47.769734, lng: 17.721981 },
    { lat: 47.769658, lng: 17.72181 },
    { lat: 47.769641, lng: 17.721773 },
    { lat: 47.769609, lng: 17.721701 },
    { lat: 47.769487, lng: 17.721425 },
    { lat: 47.769375, lng: 17.721173 },
    { lat: 47.769366, lng: 17.721154 },
    { lat: 47.769328, lng: 17.721066 },
    { lat: 47.769278, lng: 17.720962 },
    { lat: 47.769179, lng: 17.720744 },
    { lat: 47.769, lng: 17.720355 },
    { lat: 47.768987, lng: 17.720325 },
    { lat: 47.768797, lng: 17.719907 },
    { lat: 47.768734, lng: 17.719765 },
    { lat: 47.768584, lng: 17.719425 },
    { lat: 47.768519, lng: 17.719277 },
    { lat: 47.768445, lng: 17.71911 },
    { lat: 47.768407, lng: 17.719025 },
    { lat: 47.767922, lng: 17.717954 },
    { lat: 47.767913, lng: 17.717934 },
    { lat: 47.767832, lng: 17.717759 },
    { lat: 47.767819, lng: 17.717727 },
    { lat: 47.767724, lng: 17.717523 },
    { lat: 47.767701, lng: 17.717472 },
    { lat: 47.767679, lng: 17.717422 },
    { lat: 47.767503, lng: 17.717039 },
    { lat: 47.767439, lng: 17.716901 },
    { lat: 47.767413, lng: 17.716842 },
    { lat: 47.76733, lng: 17.716656 },
    { lat: 47.76725, lng: 17.716475 },
    { lat: 47.767168, lng: 17.716292 },
    { lat: 47.767017, lng: 17.715951 },
    { lat: 47.76697, lng: 17.715844 },
    { lat: 47.766759, lng: 17.715368 },
    { lat: 47.766656, lng: 17.715234 },
    { lat: 47.766551, lng: 17.715099 },
    { lat: 47.766447, lng: 17.714963 },
    { lat: 47.766372, lng: 17.714867 },
    { lat: 47.766335, lng: 17.714819 },
    { lat: 47.766296, lng: 17.714769 },
    { lat: 47.766263, lng: 17.714726 },
    { lat: 47.766223, lng: 17.714674 },
    { lat: 47.766118, lng: 17.714537 },
    { lat: 47.766008, lng: 17.714396 },
    { lat: 47.765976, lng: 17.714353 },
    { lat: 47.765935, lng: 17.714311 },
    { lat: 47.765855, lng: 17.714228 },
    { lat: 47.765773, lng: 17.714141 },
    { lat: 47.765658, lng: 17.71402 },
    { lat: 47.765551, lng: 17.713908 },
    { lat: 47.765473, lng: 17.713805 },
    { lat: 47.765403, lng: 17.713712 },
    { lat: 47.765336, lng: 17.713621 },
    { lat: 47.765279, lng: 17.713545 },
    { lat: 47.765223, lng: 17.71347 },
    { lat: 47.765167, lng: 17.713396 },
    { lat: 47.765112, lng: 17.713322 },
    { lat: 47.764903, lng: 17.713066 },
    { lat: 47.764884, lng: 17.713043 },
    { lat: 47.764767, lng: 17.712905 },
    { lat: 47.764705, lng: 17.712833 },
    { lat: 47.764645, lng: 17.712763 },
    { lat: 47.764537, lng: 17.712637 },
    { lat: 47.764504, lng: 17.712579 },
    { lat: 47.764469, lng: 17.71252 },
    { lat: 47.764361, lng: 17.712338 },
    { lat: 47.764314, lng: 17.712259 },
    { lat: 47.764232, lng: 17.712133 },
    { lat: 47.764078, lng: 17.711897 },
    { lat: 47.764023, lng: 17.71181 },
    { lat: 47.763843, lng: 17.711526 },
    { lat: 47.763805, lng: 17.711473 },
    { lat: 47.763613, lng: 17.711198 },
    { lat: 47.763603, lng: 17.711177 },
    { lat: 47.763517, lng: 17.711014 },
    { lat: 47.763463, lng: 17.710911 },
    { lat: 47.763368, lng: 17.710758 },
    { lat: 47.763272, lng: 17.710607 },
    { lat: 47.763243, lng: 17.710562 },
    { lat: 47.763211, lng: 17.71051 },
    { lat: 47.763167, lng: 17.710439 },
    { lat: 47.76315, lng: 17.710426 },
    { lat: 47.762997, lng: 17.710306 },
    { lat: 47.762876, lng: 17.71021 },
    { lat: 47.762741, lng: 17.70951 },
    { lat: 47.760627, lng: 17.708584 },
    { lat: 47.759689, lng: 17.708175 },
    { lat: 47.760536, lng: 17.707118 },
    { lat: 47.758985, lng: 17.705419 },
    { lat: 47.7588991, lng: 17.7055077 },
  ],
  KlížskaNemá: [
    { lat: 47.744355, lng: 17.806783 },
    { lat: 47.744352, lng: 17.807121 },
    { lat: 47.74427, lng: 17.808597 },
    { lat: 47.744116, lng: 17.81006 },
    { lat: 47.743891, lng: 17.811502 },
    { lat: 47.743252, lng: 17.814954 },
    { lat: 47.743012, lng: 17.815869 },
    { lat: 47.742728, lng: 17.816755 },
    { lat: 47.742401, lng: 17.817608 },
    { lat: 47.740685, lng: 17.822051 },
    { lat: 47.740371, lng: 17.82337 },
    { lat: 47.740147, lng: 17.824731 },
    { lat: 47.740018, lng: 17.826116 },
    { lat: 47.73998, lng: 17.827514 },
    { lat: 47.740034, lng: 17.828911 },
    { lat: 47.740187, lng: 17.830291 },
    { lat: 47.740429, lng: 17.831643 },
    { lat: 47.740741, lng: 17.833052 },
    { lat: 47.740961, lng: 17.834498 },
    { lat: 47.741087, lng: 17.835969 },
    { lat: 47.741156, lng: 17.837874 },
    { lat: 47.741316, lng: 17.83942 },
    { lat: 47.741512, lng: 17.840957 },
    { lat: 47.741742, lng: 17.842482 },
    { lat: 47.7430235, lng: 17.8490616 },
    { lat: 47.7438931, lng: 17.8535897 },
    { lat: 47.744548, lng: 17.856933 },
    { lat: 47.744981, lng: 17.858698 },
    { lat: 47.745319, lng: 17.860508 },
    { lat: 47.7454889, lng: 17.8618027 },
    { lat: 47.7473525, lng: 17.8617981 },
    { lat: 47.7522973, lng: 17.8612003 },
    { lat: 47.7527076, lng: 17.8611903 },
    { lat: 47.7531446, lng: 17.8613759 },
    { lat: 47.75334, lng: 17.8613775 },
    { lat: 47.7534554, lng: 17.861311 },
    { lat: 47.7537023, lng: 17.861011 },
    { lat: 47.7537682, lng: 17.861001 },
    { lat: 47.7539156, lng: 17.8610091 },
    { lat: 47.7542722, lng: 17.8612109 },
    { lat: 47.7544361, lng: 17.8611954 },
    { lat: 47.7544944, lng: 17.8612976 },
    { lat: 47.7546989, lng: 17.8610812 },
    { lat: 47.7550743, lng: 17.8608542 },
    { lat: 47.7553195, lng: 17.8605842 },
    { lat: 47.7559661, lng: 17.8593731 },
    { lat: 47.7562098, lng: 17.8584912 },
    { lat: 47.7564164, lng: 17.858561 },
    { lat: 47.7567053, lng: 17.8583588 },
    { lat: 47.7569473, lng: 17.8579106 },
    { lat: 47.7571351, lng: 17.857715 },
    { lat: 47.7576743, lng: 17.8573971 },
    { lat: 47.7580237, lng: 17.8573897 },
    { lat: 47.7586923, lng: 17.8570083 },
    { lat: 47.7590714, lng: 17.8569344 },
    { lat: 47.7592688, lng: 17.8568017 },
    { lat: 47.7594197, lng: 17.8568907 },
    { lat: 47.7596548, lng: 17.85717 },
    { lat: 47.759789, lng: 17.8571673 },
    { lat: 47.759902, lng: 17.8570776 },
    { lat: 47.7601794, lng: 17.8566706 },
    { lat: 47.7606551, lng: 17.8564012 },
    { lat: 47.7610636, lng: 17.8555179 },
    { lat: 47.7612657, lng: 17.8552291 },
    { lat: 47.761349, lng: 17.8544734 },
    { lat: 47.7612988, lng: 17.8543195 },
    { lat: 47.7675647, lng: 17.8585073 },
    { lat: 47.767727, lng: 17.8582309 },
    { lat: 47.7683675, lng: 17.8566975 },
    { lat: 47.7687, lng: 17.8557029 },
    { lat: 47.7689884, lng: 17.8546294 },
    { lat: 47.7692767, lng: 17.8532282 },
    { lat: 47.7693578, lng: 17.8521067 },
    { lat: 47.7695201, lng: 17.8521759 },
    { lat: 47.7719023, lng: 17.8524404 },
    { lat: 47.7721397, lng: 17.8512001 },
    { lat: 47.772249, lng: 17.8502449 },
    { lat: 47.7722249, lng: 17.8493063 },
    { lat: 47.7718464, lng: 17.8469018 },
    { lat: 47.7716075, lng: 17.8450429 },
    { lat: 47.7710839, lng: 17.844226 },
    { lat: 47.7708625, lng: 17.8440188 },
    { lat: 47.7700455, lng: 17.8436249 },
    { lat: 47.7700533, lng: 17.8402589 },
    { lat: 47.7710908, lng: 17.8401859 },
    { lat: 47.7721109, lng: 17.8389005 },
    { lat: 47.7752768, lng: 17.8365175 },
    { lat: 47.7766753, lng: 17.8350559 },
    { lat: 47.7776736, lng: 17.8338164 },
    { lat: 47.7786789, lng: 17.8321288 },
    { lat: 47.771567, lng: 17.8269482 },
    { lat: 47.7678413, lng: 17.8250429 },
    { lat: 47.7665474, lng: 17.8255507 },
    { lat: 47.7659152, lng: 17.8225243 },
    { lat: 47.765723, lng: 17.820826 },
    { lat: 47.7658077, lng: 17.8194405 },
    { lat: 47.7663645, lng: 17.8147844 },
    { lat: 47.7669637, lng: 17.8123584 },
    { lat: 47.7651258, lng: 17.8109005 },
    { lat: 47.7598347, lng: 17.8073741 },
    { lat: 47.7555944, lng: 17.805916 },
    { lat: 47.7530014, lng: 17.8058607 },
    { lat: 47.7528574, lng: 17.8068145 },
    { lat: 47.752872, lng: 17.8081966 },
    { lat: 47.7527826, lng: 17.8082201 },
    { lat: 47.7519668, lng: 17.8084349 },
    { lat: 47.7518611, lng: 17.8075857 },
    { lat: 47.7487142, lng: 17.8077049 },
    { lat: 47.7480835, lng: 17.8077833 },
    { lat: 47.7482335, lng: 17.8087631 },
    { lat: 47.7479921, lng: 17.8087273 },
    { lat: 47.7476758, lng: 17.8084534 },
    { lat: 47.7472747, lng: 17.8079926 },
    { lat: 47.7467583, lng: 17.8067481 },
    { lat: 47.745605, lng: 17.8071357 },
    { lat: 47.744355, lng: 17.806783 },
  ],
  Sokolce: [
    { lat: 47.8759875, lng: 17.8787558 },
    { lat: 47.8792984, lng: 17.881719 },
    { lat: 47.8820458, lng: 17.8843517 },
    { lat: 47.8849287, lng: 17.8869125 },
    { lat: 47.8860804, lng: 17.8877308 },
    { lat: 47.8870135, lng: 17.8881848 },
    { lat: 47.8871813, lng: 17.8886589 },
    { lat: 47.8875393, lng: 17.8890466 },
    { lat: 47.8903532, lng: 17.8915909 },
    { lat: 47.8906266, lng: 17.892034 },
    { lat: 47.8906679, lng: 17.8924356 },
    { lat: 47.8910725, lng: 17.8926651 },
    { lat: 47.8915122, lng: 17.8926732 },
    { lat: 47.8917212, lng: 17.8928401 },
    { lat: 47.8918922, lng: 17.8944386 },
    { lat: 47.8920274, lng: 17.8952838 },
    { lat: 47.8921168, lng: 17.895654 },
    { lat: 47.8924965, lng: 17.8967155 },
    { lat: 47.8926898, lng: 17.8970878 },
    { lat: 47.8928869, lng: 17.8972666 },
    { lat: 47.8938835, lng: 17.8975233 },
    { lat: 47.8941539, lng: 17.8979764 },
    { lat: 47.8943647, lng: 17.8983802 },
    { lat: 47.8945336, lng: 17.8988874 },
    { lat: 47.8956982, lng: 17.9027266 },
    { lat: 47.8961246, lng: 17.9032751 },
    { lat: 47.8956747, lng: 17.9041894 },
    { lat: 47.8959217, lng: 17.9045581 },
    { lat: 47.8969478, lng: 17.9048526 },
    { lat: 47.8973665, lng: 17.9047401 },
    { lat: 47.897673, lng: 17.9044926 },
    { lat: 47.8980177, lng: 17.9042023 },
    { lat: 47.8980858, lng: 17.9042832 },
    { lat: 47.8989141, lng: 17.9052783 },
    { lat: 47.8993867, lng: 17.9052556 },
    { lat: 47.8996619, lng: 17.9054155 },
    { lat: 47.8999416, lng: 17.9059296 },
    { lat: 47.9000703, lng: 17.9062812 },
    { lat: 47.9000885, lng: 17.9068008 },
    { lat: 47.9007737, lng: 17.9077269 },
    { lat: 47.9012498, lng: 17.9086093 },
    { lat: 47.901576, lng: 17.908796 },
    { lat: 47.9020792, lng: 17.9097711 },
    { lat: 47.9028088, lng: 17.910443 },
    { lat: 47.9038294, lng: 17.9129827 },
    { lat: 47.9046844, lng: 17.9148795 },
    { lat: 47.9056657, lng: 17.9168688 },
    { lat: 47.905933, lng: 17.9163642 },
    { lat: 47.9067998, lng: 17.915141 },
    { lat: 47.9070687, lng: 17.9146474 },
    { lat: 47.9074945, lng: 17.9130956 },
    { lat: 47.9077609, lng: 17.9123163 },
    { lat: 47.9078874, lng: 17.9117603 },
    { lat: 47.9080975, lng: 17.9101717 },
    { lat: 47.9081922, lng: 17.9098201 },
    { lat: 47.9083378, lng: 17.9095581 },
    { lat: 47.90868, lng: 17.908118 },
    { lat: 47.908768, lng: 17.9065638 },
    { lat: 47.9088315, lng: 17.9061095 },
    { lat: 47.9089889, lng: 17.9058388 },
    { lat: 47.9093116, lng: 17.9057787 },
    { lat: 47.9098984, lng: 17.9057963 },
    { lat: 47.9100765, lng: 17.9057346 },
    { lat: 47.9102129, lng: 17.9056053 },
    { lat: 47.910546, lng: 17.9042734 },
    { lat: 47.9106714, lng: 17.9040524 },
    { lat: 47.9108532, lng: 17.9038685 },
    { lat: 47.911123, lng: 17.903832 },
    { lat: 47.9118275, lng: 17.9040584 },
    { lat: 47.91194, lng: 17.9039947 },
    { lat: 47.9123452, lng: 17.9034562 },
    { lat: 47.9125356, lng: 17.9034336 },
    { lat: 47.9130752, lng: 17.9036204 },
    { lat: 47.9133573, lng: 17.9038205 },
    { lat: 47.9138287, lng: 17.9046843 },
    { lat: 47.914211, lng: 17.9046225 },
    { lat: 47.9153076, lng: 17.9032633 },
    { lat: 47.9155928, lng: 17.903052 },
    { lat: 47.9157482, lng: 17.9028241 },
    { lat: 47.9159701, lng: 17.9021499 },
    { lat: 47.9161423, lng: 17.9013487 },
    { lat: 47.9165543, lng: 17.9009401 },
    { lat: 47.916717, lng: 17.900631 },
    { lat: 47.9168669, lng: 17.8998655 },
    { lat: 47.9169148, lng: 17.8991904 },
    { lat: 47.9168639, lng: 17.8981903 },
    { lat: 47.9171166, lng: 17.8970453 },
    { lat: 47.9169565, lng: 17.8963994 },
    { lat: 47.9166816, lng: 17.8959109 },
    { lat: 47.9166725, lng: 17.8955761 },
    { lat: 47.9168103, lng: 17.8950983 },
    { lat: 47.9171473, lng: 17.8944981 },
    { lat: 47.9173046, lng: 17.8940458 },
    { lat: 47.9173474, lng: 17.8938208 },
    { lat: 47.9172707, lng: 17.8930268 },
    { lat: 47.917275, lng: 17.8926561 },
    { lat: 47.917427, lng: 17.8922045 },
    { lat: 47.9177228, lng: 17.891708 },
    { lat: 47.9178307, lng: 17.8914028 },
    { lat: 47.9178404, lng: 17.8907139 },
    { lat: 47.9185426, lng: 17.8895713 },
    { lat: 47.9188, lng: 17.8890164 },
    { lat: 47.9189076, lng: 17.888563 },
    { lat: 47.9191253, lng: 17.8879889 },
    { lat: 47.919287, lng: 17.887765 },
    { lat: 47.919117, lng: 17.887571 },
    { lat: 47.91886, lng: 17.887269 },
    { lat: 47.918411, lng: 17.886769 },
    { lat: 47.918369, lng: 17.886718 },
    { lat: 47.917989, lng: 17.886258 },
    { lat: 47.917879, lng: 17.886197 },
    { lat: 47.917865, lng: 17.886193 },
    { lat: 47.917812, lng: 17.886177 },
    { lat: 47.917705, lng: 17.886259 },
    { lat: 47.917549, lng: 17.886393 },
    { lat: 47.917518, lng: 17.886408 },
    { lat: 47.917447, lng: 17.886442 },
    { lat: 47.917439, lng: 17.886441 },
    { lat: 47.917338, lng: 17.886425 },
    { lat: 47.917318, lng: 17.886415 },
    { lat: 47.917228, lng: 17.886369 },
    { lat: 47.917189, lng: 17.886334 },
    { lat: 47.917091, lng: 17.886246 },
    { lat: 47.917085, lng: 17.886256 },
    { lat: 47.91707, lng: 17.886248 },
    { lat: 47.917062, lng: 17.886243 },
    { lat: 47.916993, lng: 17.886203 },
    { lat: 47.916979, lng: 17.886194 },
    { lat: 47.916736, lng: 17.886034 },
    { lat: 47.916722, lng: 17.886025 },
    { lat: 47.916686, lng: 17.886013 },
    { lat: 47.916644, lng: 17.885999 },
    { lat: 47.916634, lng: 17.886026 },
    { lat: 47.916609, lng: 17.886084 },
    { lat: 47.916601, lng: 17.886104 },
    { lat: 47.916573, lng: 17.886173 },
    { lat: 47.916571, lng: 17.886189 },
    { lat: 47.916554, lng: 17.886414 },
    { lat: 47.916531, lng: 17.886476 },
    { lat: 47.916518, lng: 17.886514 },
    { lat: 47.916463, lng: 17.886552 },
    { lat: 47.916413, lng: 17.886585 },
    { lat: 47.91629, lng: 17.886664 },
    { lat: 47.91627, lng: 17.886683 },
    { lat: 47.916167, lng: 17.886779 },
    { lat: 47.916161, lng: 17.886785 },
    { lat: 47.91602, lng: 17.887054 },
    { lat: 47.915904, lng: 17.887186 },
    { lat: 47.915899, lng: 17.887192 },
    { lat: 47.915802, lng: 17.887265 },
    { lat: 47.91574, lng: 17.887298 },
    { lat: 47.915733, lng: 17.887297 },
    { lat: 47.915689, lng: 17.887288 },
    { lat: 47.915582, lng: 17.88711 },
    { lat: 47.915553, lng: 17.887114 },
    { lat: 47.915548, lng: 17.887115 },
    { lat: 47.915428, lng: 17.887188 },
    { lat: 47.91529, lng: 17.887325 },
    { lat: 47.914923, lng: 17.88782 },
    { lat: 47.914909, lng: 17.887838 },
    { lat: 47.914775, lng: 17.887926 },
    { lat: 47.914755, lng: 17.887933 },
    { lat: 47.914548, lng: 17.888011 },
    { lat: 47.914443, lng: 17.888008 },
    { lat: 47.914441, lng: 17.888006 },
    { lat: 47.914422, lng: 17.887988 },
    { lat: 47.914402, lng: 17.88797 },
    { lat: 47.914344, lng: 17.887917 },
    { lat: 47.914181, lng: 17.887767 },
    { lat: 47.914107, lng: 17.88775 },
    { lat: 47.914089, lng: 17.88776 },
    { lat: 47.91404, lng: 17.887789 },
    { lat: 47.914025, lng: 17.887798 },
    { lat: 47.914007, lng: 17.887835 },
    { lat: 47.913945, lng: 17.887959 },
    { lat: 47.913923, lng: 17.888017 },
    { lat: 47.913752, lng: 17.888479 },
    { lat: 47.913707, lng: 17.888611 },
    { lat: 47.913626, lng: 17.888846 },
    { lat: 47.913491, lng: 17.888923 },
    { lat: 47.913372, lng: 17.888913 },
    { lat: 47.913246, lng: 17.888833 },
    { lat: 47.912693, lng: 17.88821 },
    { lat: 47.911922, lng: 17.887321 },
    { lat: 47.911218, lng: 17.887506 },
    { lat: 47.911206, lng: 17.887508 },
    { lat: 47.911103, lng: 17.887525 },
    { lat: 47.911082, lng: 17.887515 },
    { lat: 47.910992, lng: 17.887472 },
    { lat: 47.910787, lng: 17.88729 },
    { lat: 47.910607, lng: 17.887117 },
    { lat: 47.910439, lng: 17.886901 },
    { lat: 47.9101, lng: 17.886011 },
    { lat: 47.910065, lng: 17.885945 },
    { lat: 47.9091, lng: 17.883864 },
    { lat: 47.908856, lng: 17.883922 },
    { lat: 47.908759, lng: 17.883933 },
    { lat: 47.908724, lng: 17.883937 },
    { lat: 47.908654, lng: 17.883921 },
    { lat: 47.908623, lng: 17.883915 },
    { lat: 47.908536, lng: 17.883859 },
    { lat: 47.908515, lng: 17.883847 },
    { lat: 47.908432, lng: 17.883765 },
    { lat: 47.908414, lng: 17.883747 },
    { lat: 47.908339, lng: 17.88357 },
    { lat: 47.908303, lng: 17.883486 },
    { lat: 47.908237, lng: 17.883275 },
    { lat: 47.908098, lng: 17.882829 },
    { lat: 47.90807, lng: 17.882737 },
    { lat: 47.908067, lng: 17.882731 },
    { lat: 47.907959, lng: 17.882476 },
    { lat: 47.907953, lng: 17.882461 },
    { lat: 47.907884, lng: 17.882297 },
    { lat: 47.907819, lng: 17.882165 },
    { lat: 47.907714, lng: 17.88195 },
    { lat: 47.907626, lng: 17.881773 },
    { lat: 47.907571, lng: 17.881653 },
    { lat: 47.907432, lng: 17.881328 },
    { lat: 47.907355, lng: 17.881102 },
    { lat: 47.90732, lng: 17.881 },
    { lat: 47.907249, lng: 17.880783 },
    { lat: 47.907204, lng: 17.880688 },
    { lat: 47.906976, lng: 17.880358 },
    { lat: 47.906896, lng: 17.880189 },
    { lat: 47.906825, lng: 17.880031 },
    { lat: 47.906745, lng: 17.879846 },
    { lat: 47.906645, lng: 17.879622 },
    { lat: 47.906594, lng: 17.879507 },
    { lat: 47.90647, lng: 17.879165 },
    { lat: 47.906396, lng: 17.879015 },
    { lat: 47.906383, lng: 17.878986 },
    { lat: 47.906334, lng: 17.878947 },
    { lat: 47.905996, lng: 17.878665 },
    { lat: 47.905981, lng: 17.878652 },
    { lat: 47.905752, lng: 17.878429 },
    { lat: 47.905612, lng: 17.878292 },
    { lat: 47.905552, lng: 17.878234 },
    { lat: 47.905488, lng: 17.878164 },
    { lat: 47.905131, lng: 17.877773 },
    { lat: 47.90459, lng: 17.877158 },
    { lat: 47.904211, lng: 17.876721 },
    { lat: 47.903966, lng: 17.876438 },
    { lat: 47.903918, lng: 17.876383 },
    { lat: 47.90387, lng: 17.876327 },
    { lat: 47.903845, lng: 17.876299 },
    { lat: 47.903652, lng: 17.876075 },
    { lat: 47.903309, lng: 17.875679 },
    { lat: 47.903028, lng: 17.875355 },
    { lat: 47.902551, lng: 17.874864 },
    { lat: 47.9025072, lng: 17.8748156 },
    { lat: 47.9023258, lng: 17.8748229 },
    { lat: 47.9012678, lng: 17.875168 },
    { lat: 47.9010218, lng: 17.8751686 },
    { lat: 47.9001441, lng: 17.8747733 },
    { lat: 47.8997072, lng: 17.8746606 },
    { lat: 47.8993523, lng: 17.8745979 },
    { lat: 47.8988924, lng: 17.8747939 },
    { lat: 47.898498, lng: 17.8748045 },
    { lat: 47.8979392, lng: 17.8746558 },
    { lat: 47.8972994, lng: 17.874349 },
    { lat: 47.8970942, lng: 17.8743701 },
    { lat: 47.8963411, lng: 17.8747526 },
    { lat: 47.8959461, lng: 17.8748839 },
    { lat: 47.8951796, lng: 17.8748478 },
    { lat: 47.8950312, lng: 17.8745268 },
    { lat: 47.8949932, lng: 17.8740335 },
    { lat: 47.8948856, lng: 17.8738655 },
    { lat: 47.8944519, lng: 17.873732 },
    { lat: 47.8943224, lng: 17.8736454 },
    { lat: 47.8942308, lng: 17.8734398 },
    { lat: 47.8940651, lng: 17.872558 },
    { lat: 47.8941477, lng: 17.8722665 },
    { lat: 47.8945008, lng: 17.871621 },
    { lat: 47.8944043, lng: 17.8711163 },
    { lat: 47.894228, lng: 17.8709644 },
    { lat: 47.8938355, lng: 17.8708938 },
    { lat: 47.8933213, lng: 17.8709217 },
    { lat: 47.8922131, lng: 17.8697507 },
    { lat: 47.890968, lng: 17.8660175 },
    { lat: 47.8898854, lng: 17.8638478 },
    { lat: 47.8869311, lng: 17.8613473 },
    { lat: 47.8860757, lng: 17.8601391 },
    { lat: 47.8849093, lng: 17.8587231 },
    { lat: 47.8724267, lng: 17.8406293 },
    { lat: 47.872148, lng: 17.8402837 },
    { lat: 47.871133, lng: 17.8387076 },
    { lat: 47.8699344, lng: 17.8366785 },
    { lat: 47.868413, lng: 17.8337404 },
    { lat: 47.8681052, lng: 17.8330494 },
    { lat: 47.8672722, lng: 17.8309675 },
    { lat: 47.8667765, lng: 17.8295561 },
    { lat: 47.8662531, lng: 17.8277734 },
    { lat: 47.8659103, lng: 17.8267729 },
    { lat: 47.864664, lng: 17.8270326 },
    { lat: 47.8633886, lng: 17.8271599 },
    { lat: 47.862018, lng: 17.8271799 },
    { lat: 47.8620296, lng: 17.8277499 },
    { lat: 47.8602841, lng: 17.8275838 },
    { lat: 47.8600146, lng: 17.8275516 },
    { lat: 47.8573414, lng: 17.8264158 },
    { lat: 47.8555941, lng: 17.8257646 },
    { lat: 47.8551439, lng: 17.8254918 },
    { lat: 47.8551426, lng: 17.8252526 },
    { lat: 47.8551798, lng: 17.8228975 },
    { lat: 47.8552653, lng: 17.8222174 },
    { lat: 47.8553652, lng: 17.8217865 },
    { lat: 47.8556611, lng: 17.8208942 },
    { lat: 47.855781, lng: 17.8199309 },
    { lat: 47.8558002, lng: 17.8188724 },
    { lat: 47.8556469, lng: 17.8168523 },
    { lat: 47.8560149, lng: 17.8165423 },
    { lat: 47.8550299, lng: 17.8146348 },
    { lat: 47.8548937, lng: 17.8147708 },
    { lat: 47.8540122, lng: 17.8132407 },
    { lat: 47.8550549, lng: 17.8118645 },
    { lat: 47.8572106, lng: 17.8097431 },
    { lat: 47.8576928, lng: 17.809106 },
    { lat: 47.8561349, lng: 17.8065926 },
    { lat: 47.8551867, lng: 17.8052833 },
    { lat: 47.8552876, lng: 17.8049447 },
    { lat: 47.8552851, lng: 17.8046484 },
    { lat: 47.855278, lng: 17.8046603 },
    { lat: 47.854395, lng: 17.8061347 },
    { lat: 47.8527015, lng: 17.8100142 },
    { lat: 47.8515768, lng: 17.8088406 },
    { lat: 47.8504029, lng: 17.8096643 },
    { lat: 47.8482254, lng: 17.8117266 },
    { lat: 47.847521, lng: 17.8136766 },
    { lat: 47.8472052, lng: 17.8142262 },
    { lat: 47.8467086, lng: 17.8166022 },
    { lat: 47.8459526, lng: 17.8189243 },
    { lat: 47.8452549, lng: 17.8205475 },
    { lat: 47.8447642, lng: 17.8215562 },
    { lat: 47.8451376, lng: 17.8226477 },
    { lat: 47.8451313, lng: 17.8228941 },
    { lat: 47.8452793, lng: 17.8235697 },
    { lat: 47.842666, lng: 17.8268741 },
    { lat: 47.8432089, lng: 17.827934 },
    { lat: 47.8440167, lng: 17.8299361 },
    { lat: 47.8424743, lng: 17.8316134 },
    { lat: 47.8420892, lng: 17.832103 },
    { lat: 47.8418761, lng: 17.8324908 },
    { lat: 47.8417257, lng: 17.8331996 },
    { lat: 47.8416809, lng: 17.8336811 },
    { lat: 47.841812, lng: 17.8348358 },
    { lat: 47.8418546, lng: 17.8360004 },
    { lat: 47.8417711, lng: 17.836859 },
    { lat: 47.8417737, lng: 17.838749 },
    { lat: 47.8419629, lng: 17.8400237 },
    { lat: 47.842282, lng: 17.8411862 },
    { lat: 47.8442476, lng: 17.8427825 },
    { lat: 47.8577394, lng: 17.8591285 },
    { lat: 47.8670378, lng: 17.8708005 },
    { lat: 47.8759875, lng: 17.8787558 },
  ],
  Búč: [
    { lat: 47.833178, lng: 18.478771 },
    { lat: 47.8325969, lng: 18.4782409 },
    { lat: 47.8324844, lng: 18.4780445 },
    { lat: 47.8302106, lng: 18.4730808 },
    { lat: 47.827356, lng: 18.4655102 },
    { lat: 47.8269553, lng: 18.4646283 },
    { lat: 47.8243494, lng: 18.459139 },
    { lat: 47.8219454, lng: 18.4552488 },
    { lat: 47.8225258, lng: 18.4541354 },
    { lat: 47.8205719, lng: 18.4510668 },
    { lat: 47.8206059, lng: 18.4509692 },
    { lat: 47.8204327, lng: 18.4506987 },
    { lat: 47.8201474, lng: 18.4498594 },
    { lat: 47.8197426, lng: 18.4494993 },
    { lat: 47.8197163, lng: 18.4491962 },
    { lat: 47.8193567, lng: 18.4484988 },
    { lat: 47.8192526, lng: 18.4480937 },
    { lat: 47.8189285, lng: 18.4474653 },
    { lat: 47.8187098, lng: 18.4468899 },
    { lat: 47.8186818, lng: 18.4465718 },
    { lat: 47.8187806, lng: 18.4452409 },
    { lat: 47.8187205, lng: 18.4445152 },
    { lat: 47.818779, lng: 18.4440302 },
    { lat: 47.8183571, lng: 18.4426092 },
    { lat: 47.818148, lng: 18.4421617 },
    { lat: 47.8177287, lng: 18.4407453 },
    { lat: 47.817566, lng: 18.4404009 },
    { lat: 47.8172619, lng: 18.4401526 },
    { lat: 47.8168535, lng: 18.4400907 },
    { lat: 47.816112, lng: 18.4402861 },
    { lat: 47.8159412, lng: 18.4402571 },
    { lat: 47.8157713, lng: 18.4403639 },
    { lat: 47.8153804, lng: 18.4400766 },
    { lat: 47.8149359, lng: 18.4395127 },
    { lat: 47.8143679, lng: 18.4382872 },
    { lat: 47.8134617, lng: 18.4369185 },
    { lat: 47.8135962, lng: 18.4366631 },
    { lat: 47.8148975, lng: 18.4350736 },
    { lat: 47.8158169, lng: 18.4334951 },
    { lat: 47.8143074, lng: 18.4305758 },
    { lat: 47.8115044, lng: 18.4263898 },
    { lat: 47.8099828, lng: 18.4239789 },
    { lat: 47.8036632, lng: 18.4163686 },
    { lat: 47.8004382, lng: 18.4122474 },
    { lat: 47.7982712, lng: 18.4098057 },
    { lat: 47.798279, lng: 18.4097002 },
    { lat: 47.7957434, lng: 18.4083961 },
    { lat: 47.7949591, lng: 18.4093701 },
    { lat: 47.7935877, lng: 18.4107502 },
    { lat: 47.7918869, lng: 18.4126102 },
    { lat: 47.7893285, lng: 18.415702 },
    { lat: 47.7884823, lng: 18.4168939 },
    { lat: 47.7869531, lng: 18.4188781 },
    { lat: 47.7866295, lng: 18.4194968 },
    { lat: 47.7831961, lng: 18.42372 },
    { lat: 47.7816406, lng: 18.425739 },
    { lat: 47.7797622, lng: 18.4298665 },
    { lat: 47.7788019, lng: 18.4324981 },
    { lat: 47.7773098, lng: 18.4368188 },
    { lat: 47.7753181, lng: 18.4398665 },
    { lat: 47.7752556, lng: 18.4399616 },
    { lat: 47.7752648, lng: 18.4399822 },
    { lat: 47.7905439, lng: 18.4757827 },
    { lat: 47.7957034, lng: 18.4861396 },
    { lat: 47.7940306, lng: 18.4895929 },
    { lat: 47.7921749, lng: 18.4958029 },
    { lat: 47.7927271, lng: 18.4988994 },
    { lat: 47.7930257, lng: 18.5017826 },
    { lat: 47.7931226, lng: 18.5034152 },
    { lat: 47.7930811, lng: 18.5051578 },
    { lat: 47.7926381, lng: 18.5056711 },
    { lat: 47.792524, lng: 18.505709 },
    { lat: 47.792542, lng: 18.505978 },
    { lat: 47.792548, lng: 18.506061 },
    { lat: 47.792628, lng: 18.506521 },
    { lat: 47.792863, lng: 18.507141 },
    { lat: 47.792875, lng: 18.507792 },
    { lat: 47.792828, lng: 18.508262 },
    { lat: 47.792711, lng: 18.508729 },
    { lat: 47.792584, lng: 18.509236 },
    { lat: 47.792445, lng: 18.50963 },
    { lat: 47.792333, lng: 18.509947 },
    { lat: 47.79229, lng: 18.510235 },
    { lat: 47.792223, lng: 18.510699 },
    { lat: 47.792084, lng: 18.51164 },
    { lat: 47.791995, lng: 18.512248 },
    { lat: 47.791809, lng: 18.513031 },
    { lat: 47.791597, lng: 18.513782 },
    { lat: 47.791427, lng: 18.514383 },
    { lat: 47.791284, lng: 18.514978 },
    { lat: 47.791175, lng: 18.515427 },
    { lat: 47.791031, lng: 18.515897 },
    { lat: 47.791001, lng: 18.515998 },
    { lat: 47.790987, lng: 18.516041 },
    { lat: 47.790813, lng: 18.516447 },
    { lat: 47.790652, lng: 18.516819 },
    { lat: 47.790339, lng: 18.517693 },
    { lat: 47.790092, lng: 18.518364 },
    { lat: 47.789888, lng: 18.518916 },
    { lat: 47.789764, lng: 18.519252 },
    { lat: 47.78975, lng: 18.519284 },
    { lat: 47.78971, lng: 18.519376 },
    { lat: 47.789696, lng: 18.519406 },
    { lat: 47.78968, lng: 18.519443 },
    { lat: 47.78967, lng: 18.519466 },
    { lat: 47.789635, lng: 18.519546 },
    { lat: 47.789388, lng: 18.52011 },
    { lat: 47.789254, lng: 18.520504 },
    { lat: 47.789068, lng: 18.521055 },
    { lat: 47.788766, lng: 18.521869 },
    { lat: 47.788424, lng: 18.522826 },
    { lat: 47.788198, lng: 18.523459 },
    { lat: 47.788003, lng: 18.524025 },
    { lat: 47.787621, lng: 18.525132 },
    { lat: 47.787426, lng: 18.525695 },
    { lat: 47.787208, lng: 18.526247 },
    { lat: 47.786886, lng: 18.527065 },
    { lat: 47.78656, lng: 18.527845 },
    { lat: 47.786536, lng: 18.527916 },
    { lat: 47.786424, lng: 18.528246 },
    { lat: 47.786256, lng: 18.528742 },
    { lat: 47.785977, lng: 18.529542 },
    { lat: 47.785821, lng: 18.529989 },
    { lat: 47.785425, lng: 18.530898 },
    { lat: 47.785198, lng: 18.531419 },
    { lat: 47.784999, lng: 18.531985 },
    { lat: 47.784684, lng: 18.532617 },
    { lat: 47.78433, lng: 18.533324 },
    { lat: 47.783973, lng: 18.534136 },
    { lat: 47.783767, lng: 18.534604 },
    { lat: 47.783754, lng: 18.534632 },
    { lat: 47.78382, lng: 18.534611 },
    { lat: 47.784387, lng: 18.534422 },
    { lat: 47.785165, lng: 18.534157 },
    { lat: 47.785611, lng: 18.534005 },
    { lat: 47.785618, lng: 18.534002 },
    { lat: 47.785651, lng: 18.53399 },
    { lat: 47.785911, lng: 18.533896 },
    { lat: 47.786341, lng: 18.533739 },
    { lat: 47.787017, lng: 18.533493 },
    { lat: 47.78735, lng: 18.533402 },
    { lat: 47.787833, lng: 18.53327 },
    { lat: 47.788132, lng: 18.533189 },
    { lat: 47.788608, lng: 18.53306 },
    { lat: 47.789084, lng: 18.53293 },
    { lat: 47.78916, lng: 18.53291 },
    { lat: 47.790102, lng: 18.532381 },
    { lat: 47.790585, lng: 18.532109 },
    { lat: 47.790811, lng: 18.531982 },
    { lat: 47.790891, lng: 18.531937 },
    { lat: 47.791239, lng: 18.531742 },
    { lat: 47.791261, lng: 18.531729 },
    { lat: 47.791292, lng: 18.531712 },
    { lat: 47.791547, lng: 18.531569 },
    { lat: 47.791768, lng: 18.531474 },
    { lat: 47.792617, lng: 18.53111 },
    { lat: 47.792867, lng: 18.531004 },
    { lat: 47.793285, lng: 18.530824 },
    { lat: 47.793439, lng: 18.530759 },
    { lat: 47.794027, lng: 18.530507 },
    { lat: 47.794197, lng: 18.530434 },
    { lat: 47.794304, lng: 18.530388 },
    { lat: 47.794543, lng: 18.530249 },
    { lat: 47.794771, lng: 18.530116 },
    { lat: 47.795046, lng: 18.529957 },
    { lat: 47.79563, lng: 18.529616 },
    { lat: 47.796463, lng: 18.529133 },
    { lat: 47.797035, lng: 18.5288 },
    { lat: 47.797207, lng: 18.528666 },
    { lat: 47.797381, lng: 18.528531 },
    { lat: 47.79751, lng: 18.528432 },
    { lat: 47.79754, lng: 18.528409 },
    { lat: 47.797606, lng: 18.528357 },
    { lat: 47.797872, lng: 18.528151 },
    { lat: 47.797915, lng: 18.528118 },
    { lat: 47.798065, lng: 18.528002 },
    { lat: 47.798104, lng: 18.527972 },
    { lat: 47.798322, lng: 18.527803 },
    { lat: 47.798751, lng: 18.527471 },
    { lat: 47.798831, lng: 18.527409 },
    { lat: 47.799029, lng: 18.527227 },
    { lat: 47.799516, lng: 18.526785 },
    { lat: 47.799745, lng: 18.526575 },
    { lat: 47.799834, lng: 18.526494 },
    { lat: 47.800014, lng: 18.526477 },
    { lat: 47.800053, lng: 18.526473 },
    { lat: 47.80078, lng: 18.526403 },
    { lat: 47.800823, lng: 18.5264 },
    { lat: 47.801334, lng: 18.526348 },
    { lat: 47.801365, lng: 18.526345 },
    { lat: 47.802387, lng: 18.526232 },
    { lat: 47.803078, lng: 18.526155 },
    { lat: 47.803583, lng: 18.526099 },
    { lat: 47.804621, lng: 18.525983 },
    { lat: 47.80544, lng: 18.525892 },
    { lat: 47.80616, lng: 18.525812 },
    { lat: 47.807053, lng: 18.525713 },
    { lat: 47.807257, lng: 18.525691 },
    { lat: 47.807276, lng: 18.525689 },
    { lat: 47.807297, lng: 18.525692 },
    { lat: 47.807331, lng: 18.525697 },
    { lat: 47.807355, lng: 18.525701 },
    { lat: 47.807389, lng: 18.525706 },
    { lat: 47.80805, lng: 18.525816 },
    { lat: 47.808839, lng: 18.525946 },
    { lat: 47.809504, lng: 18.526056 },
    { lat: 47.810184, lng: 18.526169 },
    { lat: 47.811025, lng: 18.526468 },
    { lat: 47.811804, lng: 18.526746 },
    { lat: 47.812211, lng: 18.526891 },
    { lat: 47.812415, lng: 18.526964 },
    { lat: 47.812794, lng: 18.527099 },
    { lat: 47.813161, lng: 18.52723 },
    { lat: 47.813781, lng: 18.527451 },
    { lat: 47.814044, lng: 18.527544 },
    { lat: 47.814059, lng: 18.527555 },
    { lat: 47.814087, lng: 18.527577 },
    { lat: 47.814157, lng: 18.527629 },
    { lat: 47.814183, lng: 18.527649 },
    { lat: 47.815043, lng: 18.528293 },
    { lat: 47.815291, lng: 18.528475 },
    { lat: 47.815292, lng: 18.528437 },
    { lat: 47.815202, lng: 18.523866 },
    { lat: 47.815019, lng: 18.519501 },
    { lat: 47.814927, lng: 18.516545 },
    { lat: 47.814886, lng: 18.515234 },
    { lat: 47.814826, lng: 18.512403 },
    { lat: 47.81544, lng: 18.508706 },
    { lat: 47.816463, lng: 18.504924 },
    { lat: 47.816923, lng: 18.503268 },
    { lat: 47.817176, lng: 18.502348 },
    { lat: 47.818919, lng: 18.499965 },
    { lat: 47.820377, lng: 18.498011 },
    { lat: 47.821464, lng: 18.496559 },
    { lat: 47.822985, lng: 18.494578 },
    { lat: 47.825011, lng: 18.492104 },
    { lat: 47.826565, lng: 18.490121 },
    { lat: 47.828947, lng: 18.486698 },
    { lat: 47.830316, lng: 18.484365 },
    { lat: 47.831404, lng: 18.482433 },
    { lat: 47.832457, lng: 18.480365 },
    { lat: 47.8329817, lng: 18.4799484 },
    { lat: 47.833087, lng: 18.478897 },
    { lat: 47.833119, lng: 18.478837 },
    { lat: 47.833178, lng: 18.478771 },
  ],
  Mudroňovo: [
    { lat: 47.8497745, lng: 18.3117953 },
    { lat: 47.8493939, lng: 18.3118872 },
    { lat: 47.8483775, lng: 18.311929 },
    { lat: 47.8479964, lng: 18.3118759 },
    { lat: 47.8474636, lng: 18.3116555 },
    { lat: 47.8471223, lng: 18.3115871 },
    { lat: 47.8465354, lng: 18.3112699 },
    { lat: 47.8461515, lng: 18.3111683 },
    { lat: 47.8453552, lng: 18.3107973 },
    { lat: 47.844914, lng: 18.3104849 },
    { lat: 47.8409176, lng: 18.3086962 },
    { lat: 47.8401845, lng: 18.3080939 },
    { lat: 47.8398113, lng: 18.3078653 },
    { lat: 47.8388307, lng: 18.307038 },
    { lat: 47.8382547, lng: 18.3064 },
    { lat: 47.8378359, lng: 18.3061024 },
    { lat: 47.837385, lng: 18.3059345 },
    { lat: 47.8373734, lng: 18.3059307 },
    { lat: 47.8368745, lng: 18.306886 },
    { lat: 47.836666, lng: 18.3075362 },
    { lat: 47.8365918, lng: 18.308014 },
    { lat: 47.8365014, lng: 18.3082257 },
    { lat: 47.8361741, lng: 18.3087708 },
    { lat: 47.8358647, lng: 18.3090808 },
    { lat: 47.8351426, lng: 18.3094186 },
    { lat: 47.83422, lng: 18.3093221 },
    { lat: 47.8332048, lng: 18.3098576 },
    { lat: 47.8327638, lng: 18.3102148 },
    { lat: 47.8318921, lng: 18.3106337 },
    { lat: 47.8309086, lng: 18.3112886 },
    { lat: 47.8303173, lng: 18.3114823 },
    { lat: 47.8294373, lng: 18.3120117 },
    { lat: 47.8283091, lng: 18.3125066 },
    { lat: 47.8281827, lng: 18.3125244 },
    { lat: 47.8278391, lng: 18.3123759 },
    { lat: 47.8276359, lng: 18.3124154 },
    { lat: 47.827491, lng: 18.3125057 },
    { lat: 47.8273294, lng: 18.3127337 },
    { lat: 47.8270354, lng: 18.3137225 },
    { lat: 47.826324, lng: 18.3135184 },
    { lat: 47.8255267, lng: 18.3131129 },
    { lat: 47.8250529, lng: 18.3130157 },
    { lat: 47.8247167, lng: 18.3127599 },
    { lat: 47.8244307, lng: 18.3124205 },
    { lat: 47.8244232, lng: 18.3124454 },
    { lat: 47.8236765, lng: 18.3145885 },
    { lat: 47.8244056, lng: 18.3150238 },
    { lat: 47.8234365, lng: 18.3178326 },
    { lat: 47.8225064, lng: 18.3171799 },
    { lat: 47.8205161, lng: 18.3230902 },
    { lat: 47.8160729, lng: 18.331524 },
    { lat: 47.8163007, lng: 18.3318771 },
    { lat: 47.8161708, lng: 18.3320729 },
    { lat: 47.8162437, lng: 18.3322009 },
    { lat: 47.8158179, lng: 18.3326837 },
    { lat: 47.814553, lng: 18.334395 },
    { lat: 47.8122545, lng: 18.3364954 },
    { lat: 47.8116507, lng: 18.3373631 },
    { lat: 47.8116645, lng: 18.3373852 },
    { lat: 47.8169743, lng: 18.3456061 },
    { lat: 47.8280844, lng: 18.3285156 },
    { lat: 47.8350331, lng: 18.3179934 },
    { lat: 47.8351443, lng: 18.3177114 },
    { lat: 47.8397114, lng: 18.3244275 },
    { lat: 47.8404821, lng: 18.3233878 },
    { lat: 47.8410659, lng: 18.3228243 },
    { lat: 47.8415189, lng: 18.3227378 },
    { lat: 47.841758, lng: 18.3225003 },
    { lat: 47.842032, lng: 18.3223388 },
    { lat: 47.8427218, lng: 18.322132 },
    { lat: 47.8438471, lng: 18.3214869 },
    { lat: 47.8442322, lng: 18.3220257 },
    { lat: 47.8442977, lng: 18.3220058 },
    { lat: 47.8450625, lng: 18.3213544 },
    { lat: 47.8452269, lng: 18.3208576 },
    { lat: 47.8455324, lng: 18.3203423 },
    { lat: 47.8461052, lng: 18.3196499 },
    { lat: 47.8465521, lng: 18.3186871 },
    { lat: 47.8475446, lng: 18.316168 },
    { lat: 47.8481991, lng: 18.3150081 },
    { lat: 47.8488682, lng: 18.313951 },
    { lat: 47.8493974, lng: 18.3128116 },
    { lat: 47.8497745, lng: 18.3117953 },
  ],
  SvätýPeter: [
    { lat: 47.8604649, lng: 18.2466083 },
    { lat: 47.8604539, lng: 18.2465567 },
    { lat: 47.8601029, lng: 18.2450762 },
    { lat: 47.8580734, lng: 18.2470091 },
    { lat: 47.8572154, lng: 18.2478954 },
    { lat: 47.8562571, lng: 18.2487054 },
    { lat: 47.8538253, lng: 18.2509973 },
    { lat: 47.8522425, lng: 18.2512941 },
    { lat: 47.8506091, lng: 18.2520021 },
    { lat: 47.8501122, lng: 18.25213 },
    { lat: 47.8499189, lng: 18.2519697 },
    { lat: 47.8493954, lng: 18.249887 },
    { lat: 47.8487555, lng: 18.2470506 },
    { lat: 47.8485685, lng: 18.2456755 },
    { lat: 47.8484206, lng: 18.2443712 },
    { lat: 47.8485651, lng: 18.2428205 },
    { lat: 47.8486224, lng: 18.2411812 },
    { lat: 47.8479633, lng: 18.2385596 },
    { lat: 47.8469485, lng: 18.2341291 },
    { lat: 47.8466696, lng: 18.2328666 },
    { lat: 47.8464725, lng: 18.2317107 },
    { lat: 47.8468511, lng: 18.2298193 },
    { lat: 47.8484813, lng: 18.2279161 },
    { lat: 47.8484756, lng: 18.2277984 },
    { lat: 47.8477939, lng: 18.2263812 },
    { lat: 47.847625, lng: 18.2258079 },
    { lat: 47.8470739, lng: 18.2228536 },
    { lat: 47.8462887, lng: 18.2191393 },
    { lat: 47.8463819, lng: 18.2177574 },
    { lat: 47.8452023, lng: 18.2140595 },
    { lat: 47.8446899, lng: 18.2126653 },
    { lat: 47.8419807, lng: 18.2079698 },
    { lat: 47.8413143, lng: 18.206163 },
    { lat: 47.8412467, lng: 18.2058162 },
    { lat: 47.8410001, lng: 18.2054775 },
    { lat: 47.8408375, lng: 18.2050124 },
    { lat: 47.8406366, lng: 18.2047805 },
    { lat: 47.8405389, lng: 18.204556 },
    { lat: 47.8400781, lng: 18.202369 },
    { lat: 47.8399241, lng: 18.2018789 },
    { lat: 47.8396785, lng: 18.201383 },
    { lat: 47.8387848, lng: 18.1999365 },
    { lat: 47.8375759, lng: 18.1981359 },
    { lat: 47.8363278, lng: 18.1966478 },
    { lat: 47.8355475, lng: 18.1952714 },
    { lat: 47.8326864, lng: 18.1911567 },
    { lat: 47.8321169, lng: 18.1895253 },
    { lat: 47.8308188, lng: 18.1863029 },
    { lat: 47.830965, lng: 18.1849485 },
    { lat: 47.8313443, lng: 18.1832742 },
    { lat: 47.8313739, lng: 18.1827034 },
    { lat: 47.8314281, lng: 18.1821435 },
    { lat: 47.8311153, lng: 18.1785058 },
    { lat: 47.8284259, lng: 18.1738121 },
    { lat: 47.8273234, lng: 18.1721791 },
    { lat: 47.8276024, lng: 18.1698777 },
    { lat: 47.8278793, lng: 18.1688512 },
    { lat: 47.8270284, lng: 18.1671802 },
    { lat: 47.8256623, lng: 18.1620059 },
    { lat: 47.8258984, lng: 18.1594039 },
    { lat: 47.8259603, lng: 18.1562581 },
    { lat: 47.8258952, lng: 18.1539044 },
    { lat: 47.8259777, lng: 18.1517812 },
    { lat: 47.8259234, lng: 18.1503057 },
    { lat: 47.8240306, lng: 18.1475811 },
    { lat: 47.8218065, lng: 18.145443 },
    { lat: 47.8220663, lng: 18.1446434 },
    { lat: 47.8226154, lng: 18.1437749 },
    { lat: 47.8229112, lng: 18.1434485 },
    { lat: 47.8231165, lng: 18.1427613 },
    { lat: 47.8231016, lng: 18.1413623 },
    { lat: 47.823269, lng: 18.1384257 },
    { lat: 47.8229644, lng: 18.1332507 },
    { lat: 47.8240122, lng: 18.1298813 },
    { lat: 47.8248781, lng: 18.1273658 },
    { lat: 47.8268031, lng: 18.1221187 },
    { lat: 47.8269636, lng: 18.122011 },
    { lat: 47.8268627, lng: 18.1216755 },
    { lat: 47.8268552, lng: 18.1212441 },
    { lat: 47.8268434, lng: 18.1211583 },
    { lat: 47.8269978, lng: 18.1205836 },
    { lat: 47.8272403, lng: 18.1199736 },
    { lat: 47.8273221, lng: 18.119617 },
    { lat: 47.8273601, lng: 18.1190822 },
    { lat: 47.8272634, lng: 18.1188184 },
    { lat: 47.8270903, lng: 18.1186381 },
    { lat: 47.8268607, lng: 18.1185953 },
    { lat: 47.8264879, lng: 18.1187578 },
    { lat: 47.8262148, lng: 18.1191211 },
    { lat: 47.8260745, lng: 18.1193993 },
    { lat: 47.8258083, lng: 18.1203193 },
    { lat: 47.8254631, lng: 18.1225689 },
    { lat: 47.8250912, lng: 18.1238535 },
    { lat: 47.8247944, lng: 18.1245405 },
    { lat: 47.8244179, lng: 18.1249861 },
    { lat: 47.8236097, lng: 18.1256716 },
    { lat: 47.8226597, lng: 18.126094 },
    { lat: 47.8219396, lng: 18.1262051 },
    { lat: 47.821686, lng: 18.1261525 },
    { lat: 47.820863, lng: 18.1255394 },
    { lat: 47.8206097, lng: 18.1254706 },
    { lat: 47.820456, lng: 18.1255072 },
    { lat: 47.820314, lng: 18.1256612 },
    { lat: 47.8200975, lng: 18.1260669 },
    { lat: 47.8199971, lng: 18.1268279 },
    { lat: 47.8199591, lng: 18.1278079 },
    { lat: 47.8200779, lng: 18.1290174 },
    { lat: 47.8200997, lng: 18.1341746 },
    { lat: 47.8191961, lng: 18.1371032 },
    { lat: 47.8189244, lng: 18.1392491 },
    { lat: 47.81858, lng: 18.1410221 },
    { lat: 47.8172909, lng: 18.1455837 },
    { lat: 47.816431, lng: 18.1466368 },
    { lat: 47.8151212, lng: 18.1476767 },
    { lat: 47.8142535, lng: 18.1479837 },
    { lat: 47.8132097, lng: 18.1475461 },
    { lat: 47.8126039, lng: 18.147014 },
    { lat: 47.8115736, lng: 18.1483882 },
    { lat: 47.8099399, lng: 18.1515742 },
    { lat: 47.8072133, lng: 18.1509947 },
    { lat: 47.8069587, lng: 18.1512889 },
    { lat: 47.8068011, lng: 18.1517559 },
    { lat: 47.8062347, lng: 18.1527912 },
    { lat: 47.805426, lng: 18.154062 },
    { lat: 47.8051805, lng: 18.1543585 },
    { lat: 47.8049702, lng: 18.1547905 },
    { lat: 47.8047252, lng: 18.1551512 },
    { lat: 47.8043844, lng: 18.1563386 },
    { lat: 47.8041451, lng: 18.1568565 },
    { lat: 47.8041803, lng: 18.1572409 },
    { lat: 47.8041497, lng: 18.1574123 },
    { lat: 47.8040633, lng: 18.1573631 },
    { lat: 47.8039535, lng: 18.1577996 },
    { lat: 47.8039305, lng: 18.1584452 },
    { lat: 47.8038134, lng: 18.1594039 },
    { lat: 47.803909, lng: 18.1598015 },
    { lat: 47.8041538, lng: 18.1601642 },
    { lat: 47.8045019, lng: 18.160422 },
    { lat: 47.804644, lng: 18.1606148 },
    { lat: 47.8046893, lng: 18.1608662 },
    { lat: 47.8043786, lng: 18.1612435 },
    { lat: 47.8043817, lng: 18.1613326 },
    { lat: 47.804944, lng: 18.1649434 },
    { lat: 47.8045303, lng: 18.1657383 },
    { lat: 47.8043382, lng: 18.1664234 },
    { lat: 47.8041025, lng: 18.1670405 },
    { lat: 47.8041329, lng: 18.1676332 },
    { lat: 47.8043147, lng: 18.1681544 },
    { lat: 47.8044687, lng: 18.1684135 },
    { lat: 47.8045902, lng: 18.1682924 },
    { lat: 47.8045801, lng: 18.169326 },
    { lat: 47.8039155, lng: 18.1701527 },
    { lat: 47.8034054, lng: 18.1704778 },
    { lat: 47.8031796, lng: 18.1701891 },
    { lat: 47.8032624, lng: 18.1700808 },
    { lat: 47.8030816, lng: 18.1697887 },
    { lat: 47.7961928, lng: 18.1787987 },
    { lat: 47.7956041, lng: 18.1795392 },
    { lat: 47.7954046, lng: 18.1796206 },
    { lat: 47.7952775, lng: 18.1799527 },
    { lat: 47.795094, lng: 18.1801915 },
    { lat: 47.7974083, lng: 18.1817059 },
    { lat: 47.7998489, lng: 18.1831665 },
    { lat: 47.8005261, lng: 18.1837054 },
    { lat: 47.8012026, lng: 18.1841399 },
    { lat: 47.8016195, lng: 18.184399 },
    { lat: 47.8037028, lng: 18.1854796 },
    { lat: 47.8051065, lng: 18.1866137 },
    { lat: 47.8080305, lng: 18.1892742 },
    { lat: 47.8087311, lng: 18.1890191 },
    { lat: 47.8107685, lng: 18.1917241 },
    { lat: 47.8115511, lng: 18.1922753 },
    { lat: 47.8142979, lng: 18.1964025 },
    { lat: 47.8148876, lng: 18.1965117 },
    { lat: 47.815367, lng: 18.1969301 },
    { lat: 47.8173331, lng: 18.1978058 },
    { lat: 47.8179305, lng: 18.1982388 },
    { lat: 47.8181241, lng: 18.1985175 },
    { lat: 47.8191658, lng: 18.2010488 },
    { lat: 47.8194442, lng: 18.2015431 },
    { lat: 47.8197616, lng: 18.2020688 },
    { lat: 47.8218382, lng: 18.2050659 },
    { lat: 47.8230301, lng: 18.2080068 },
    { lat: 47.8240522, lng: 18.2096226 },
    { lat: 47.8235698, lng: 18.2103673 },
    { lat: 47.824352, lng: 18.2116057 },
    { lat: 47.8240106, lng: 18.2122915 },
    { lat: 47.824726, lng: 18.2134711 },
    { lat: 47.8247454, lng: 18.2136301 },
    { lat: 47.823667, lng: 18.2147984 },
    { lat: 47.8251329, lng: 18.217255 },
    { lat: 47.8251771, lng: 18.2178171 },
    { lat: 47.8249708, lng: 18.2182657 },
    { lat: 47.8252422, lng: 18.2189344 },
    { lat: 47.8254793, lng: 18.2201167 },
    { lat: 47.8258365, lng: 18.2228354 },
    { lat: 47.8262393, lng: 18.225041 },
    { lat: 47.8270135, lng: 18.2280848 },
    { lat: 47.8284048, lng: 18.2343999 },
    { lat: 47.832757, lng: 18.2423382 },
    { lat: 47.8342234, lng: 18.2442337 },
    { lat: 47.8359934, lng: 18.2475099 },
    { lat: 47.8384595, lng: 18.251876 },
    { lat: 47.8394791, lng: 18.2535184 },
    { lat: 47.8381654, lng: 18.2550502 },
    { lat: 47.8369156, lng: 18.2566331 },
    { lat: 47.835745, lng: 18.2577152 },
    { lat: 47.8354559, lng: 18.2580426 },
    { lat: 47.8340967, lng: 18.2598233 },
    { lat: 47.83316, lng: 18.2607704 },
    { lat: 47.8312481, lng: 18.263023 },
    { lat: 47.8306334, lng: 18.2637666 },
    { lat: 47.8298222, lng: 18.2649552 },
    { lat: 47.8286599, lng: 18.2662796 },
    { lat: 47.8274742, lng: 18.2681139 },
    { lat: 47.8270894, lng: 18.2688744 },
    { lat: 47.8268277, lng: 18.269635 },
    { lat: 47.8265947, lng: 18.2701324 },
    { lat: 47.8262973, lng: 18.270512 },
    { lat: 47.8254444, lng: 18.2712138 },
    { lat: 47.824619, lng: 18.2717696 },
    { lat: 47.8247269, lng: 18.2718819 },
    { lat: 47.8257854, lng: 18.273443 },
    { lat: 47.8260734, lng: 18.2741095 },
    { lat: 47.8272127, lng: 18.2763682 },
    { lat: 47.8296314, lng: 18.2809109 },
    { lat: 47.8303769, lng: 18.2830322 },
    { lat: 47.8310398, lng: 18.2858561 },
    { lat: 47.8318157, lng: 18.288177 },
    { lat: 47.8322138, lng: 18.2899905 },
    { lat: 47.8325136, lng: 18.291003 },
    { lat: 47.8328397, lng: 18.2919794 },
    { lat: 47.8332744, lng: 18.2943143 },
    { lat: 47.8335523, lng: 18.2987842 },
    { lat: 47.8342253, lng: 18.3025532 },
    { lat: 47.8350458, lng: 18.3040874 },
    { lat: 47.8355726, lng: 18.3047123 },
    { lat: 47.8365511, lng: 18.3054413 },
    { lat: 47.8374036, lng: 18.3058672 },
    { lat: 47.837385, lng: 18.3059345 },
    { lat: 47.8378359, lng: 18.3061024 },
    { lat: 47.8382547, lng: 18.3064 },
    { lat: 47.8388307, lng: 18.307038 },
    { lat: 47.8398113, lng: 18.3078653 },
    { lat: 47.8401845, lng: 18.3080939 },
    { lat: 47.8409176, lng: 18.3086962 },
    { lat: 47.844914, lng: 18.3104849 },
    { lat: 47.8453552, lng: 18.3107973 },
    { lat: 47.8461515, lng: 18.3111683 },
    { lat: 47.8465354, lng: 18.3112699 },
    { lat: 47.8471223, lng: 18.3115871 },
    { lat: 47.8474636, lng: 18.3116555 },
    { lat: 47.8479964, lng: 18.3118759 },
    { lat: 47.8483775, lng: 18.311929 },
    { lat: 47.8493939, lng: 18.3118872 },
    { lat: 47.8497745, lng: 18.3117953 },
    { lat: 47.8498635, lng: 18.3117735 },
    { lat: 47.8516413, lng: 18.3117288 },
    { lat: 47.8524655, lng: 18.3114827 },
    { lat: 47.8537912, lng: 18.3116594 },
    { lat: 47.8546982, lng: 18.3114593 },
    { lat: 47.8548413, lng: 18.3114766 },
    { lat: 47.855313, lng: 18.311851 },
    { lat: 47.8556584, lng: 18.3119494 },
    { lat: 47.8559916, lng: 18.312172 },
    { lat: 47.856503, lng: 18.3127851 },
    { lat: 47.8569603, lng: 18.3134824 },
    { lat: 47.8572234, lng: 18.3139169 },
    { lat: 47.8578658, lng: 18.3152382 },
    { lat: 47.8584246, lng: 18.3160097 },
    { lat: 47.8589067, lng: 18.317019 },
    { lat: 47.859105, lng: 18.3180348 },
    { lat: 47.8592303, lng: 18.3184679 },
    { lat: 47.8606264, lng: 18.3168038 },
    { lat: 47.862247, lng: 18.3145904 },
    { lat: 47.8633779, lng: 18.312673 },
    { lat: 47.8650033, lng: 18.310347 },
    { lat: 47.8661669, lng: 18.3088448 },
    { lat: 47.8670066, lng: 18.3073541 },
    { lat: 47.8670207, lng: 18.3073281 },
    { lat: 47.8662455, lng: 18.3063054 },
    { lat: 47.865995, lng: 18.3058927 },
    { lat: 47.865452, lng: 18.3054579 },
    { lat: 47.8649991, lng: 18.3052498 },
    { lat: 47.8647111, lng: 18.3046792 },
    { lat: 47.8643747, lng: 18.3037754 },
    { lat: 47.8642761, lng: 18.3033154 },
    { lat: 47.8642265, lng: 18.3027658 },
    { lat: 47.8642549, lng: 18.3020265 },
    { lat: 47.8642138, lng: 18.3010943 },
    { lat: 47.8638416, lng: 18.2991966 },
    { lat: 47.8636739, lng: 18.298693 },
    { lat: 47.8632362, lng: 18.2979708 },
    { lat: 47.862649, lng: 18.2967487 },
    { lat: 47.8611401, lng: 18.2951931 },
    { lat: 47.8608328, lng: 18.2946205 },
    { lat: 47.8607341, lng: 18.2942378 },
    { lat: 47.860706, lng: 18.2938268 },
    { lat: 47.8607463, lng: 18.2933938 },
    { lat: 47.8608513, lng: 18.2930214 },
    { lat: 47.861336, lng: 18.2919549 },
    { lat: 47.8615351, lng: 18.2910452 },
    { lat: 47.8615204, lng: 18.28983 },
    { lat: 47.8614449, lng: 18.2895386 },
    { lat: 47.8612096, lng: 18.2890984 },
    { lat: 47.8605109, lng: 18.2872379 },
    { lat: 47.8605456, lng: 18.287191 },
    { lat: 47.8605266, lng: 18.2860428 },
    { lat: 47.8609097, lng: 18.2841178 },
    { lat: 47.8609591, lng: 18.2815499 },
    { lat: 47.8607052, lng: 18.2794409 },
    { lat: 47.8607951, lng: 18.2696542 },
    { lat: 47.860779, lng: 18.2669766 },
    { lat: 47.8610114, lng: 18.2648729 },
    { lat: 47.8609901, lng: 18.2643093 },
    { lat: 47.8611239, lng: 18.2635986 },
    { lat: 47.8611911, lng: 18.2624591 },
    { lat: 47.8612587, lng: 18.2620516 },
    { lat: 47.861311, lng: 18.2574402 },
    { lat: 47.8610565, lng: 18.2562796 },
    { lat: 47.8603645, lng: 18.2542137 },
    { lat: 47.8607963, lng: 18.2536023 },
    { lat: 47.8606882, lng: 18.2532967 },
    { lat: 47.8604387, lng: 18.2520691 },
    { lat: 47.8603698, lng: 18.2512513 },
    { lat: 47.8603608, lng: 18.2501232 },
    { lat: 47.860239, lng: 18.2486477 },
    { lat: 47.8600073, lng: 18.2478412 },
    { lat: 47.8603538, lng: 18.2469093 },
    { lat: 47.8604649, lng: 18.2466083 },
  ],
  Čalovec: [
    { lat: 47.8060864, lng: 18.0034118 },
    { lat: 47.8074657, lng: 18.005249 },
    { lat: 47.8081074, lng: 18.0069645 },
    { lat: 47.8090707, lng: 18.0087908 },
    { lat: 47.8093343, lng: 18.0092884 },
    { lat: 47.8107125, lng: 18.0084644 },
    { lat: 47.8109129, lng: 18.0081601 },
    { lat: 47.811151, lng: 18.007632 },
    { lat: 47.8112575, lng: 18.0076028 },
    { lat: 47.8114751, lng: 18.0070567 },
    { lat: 47.8115512, lng: 18.0065761 },
    { lat: 47.8115167, lng: 18.0063033 },
    { lat: 47.8115787, lng: 18.0057375 },
    { lat: 47.8118149, lng: 18.0052778 },
    { lat: 47.8127392, lng: 18.0049019 },
    { lat: 47.812841, lng: 18.0050162 },
    { lat: 47.8133142, lng: 18.0066682 },
    { lat: 47.8140429, lng: 18.0080125 },
    { lat: 47.8152403, lng: 18.0098304 },
    { lat: 47.817368, lng: 18.0118003 },
    { lat: 47.8184792, lng: 18.0126533 },
    { lat: 47.8197728, lng: 18.0133174 },
    { lat: 47.8203275, lng: 18.0133067 },
    { lat: 47.8217254, lng: 18.0130534 },
    { lat: 47.8225267, lng: 18.0127368 },
    { lat: 47.8232068, lng: 18.0122195 },
    { lat: 47.8240009, lng: 18.0113303 },
    { lat: 47.8255663, lng: 18.0094199 },
    { lat: 47.8265933, lng: 18.0080311 },
    { lat: 47.8279291, lng: 18.0059983 },
    { lat: 47.8286312, lng: 18.0053999 },
    { lat: 47.8294197, lng: 18.0040251 },
    { lat: 47.829781, lng: 18.0032582 },
    { lat: 47.830359, lng: 18.0023974 },
    { lat: 47.8312733, lng: 18.0005098 },
    { lat: 47.8319045, lng: 17.9994663 },
    { lat: 47.8328805, lng: 17.998563 },
    { lat: 47.8332141, lng: 17.9981056 },
    { lat: 47.8339838, lng: 17.9977113 },
    { lat: 47.8346235, lng: 17.9975223 },
    { lat: 47.835661, lng: 17.9970924 },
    { lat: 47.8362689, lng: 17.9963212 },
    { lat: 47.8366432, lng: 17.9959591 },
    { lat: 47.8371826, lng: 17.9958248 },
    { lat: 47.8382483, lng: 17.9951189 },
    { lat: 47.838997, lng: 17.9948819 },
    { lat: 47.8394631, lng: 17.9945985 },
    { lat: 47.8400142, lng: 17.9940622 },
    { lat: 47.8409858, lng: 17.992471 },
    { lat: 47.8420034, lng: 17.9912461 },
    { lat: 47.8421332, lng: 17.9909829 },
    { lat: 47.8421974, lng: 17.9905686 },
    { lat: 47.8422051, lng: 17.9889023 },
    { lat: 47.8437999, lng: 17.986716 },
    { lat: 47.8441548, lng: 17.9859035 },
    { lat: 47.8443231, lng: 17.9848007 },
    { lat: 47.8448126, lng: 17.9828337 },
    { lat: 47.8458037, lng: 17.9813414 },
    { lat: 47.8465487, lng: 17.98037 },
    { lat: 47.8478387, lng: 17.9783167 },
    { lat: 47.8488404, lng: 17.9761775 },
    { lat: 47.8495975, lng: 17.9748125 },
    { lat: 47.8505485, lng: 17.9724707 },
    { lat: 47.8507967, lng: 17.9711266 },
    { lat: 47.8511555, lng: 17.9697827 },
    { lat: 47.8512304, lng: 17.9692857 },
    { lat: 47.8510792, lng: 17.9682612 },
    { lat: 47.850823, lng: 17.9674011 },
    { lat: 47.8507499, lng: 17.9670142 },
    { lat: 47.8507986, lng: 17.9664978 },
    { lat: 47.8513454, lng: 17.964978 },
    { lat: 47.851498, lng: 17.9647759 },
    { lat: 47.8520709, lng: 17.964284 },
    { lat: 47.8528233, lng: 17.9637153 },
    { lat: 47.8538207, lng: 17.9625902 },
    { lat: 47.8547648, lng: 17.9608871 },
    { lat: 47.8553328, lng: 17.9602953 },
    { lat: 47.8556741, lng: 17.9598001 },
    { lat: 47.8560655, lng: 17.9588639 },
    { lat: 47.8565475, lng: 17.9574509 },
    { lat: 47.8566912, lng: 17.9571952 },
    { lat: 47.8578818, lng: 17.9556258 },
    { lat: 47.8581564, lng: 17.9548499 },
    { lat: 47.8581408, lng: 17.9545769 },
    { lat: 47.8584962, lng: 17.9531217 },
    { lat: 47.8589577, lng: 17.9525422 },
    { lat: 47.8592835, lng: 17.952333 },
    { lat: 47.859236, lng: 17.9521078 },
    { lat: 47.8592996, lng: 17.9516315 },
    { lat: 47.8592113, lng: 17.9512911 },
    { lat: 47.8590181, lng: 17.951007 },
    { lat: 47.8586383, lng: 17.9499603 },
    { lat: 47.8585746, lng: 17.949616 },
    { lat: 47.8585199, lng: 17.9486955 },
    { lat: 47.8585727, lng: 17.9485791 },
    { lat: 47.8587001, lng: 17.9485231 },
    { lat: 47.8589759, lng: 17.9485859 },
    { lat: 47.8593507, lng: 17.948568 },
    { lat: 47.8596267, lng: 17.9484474 },
    { lat: 47.860079, lng: 17.9476968 },
    { lat: 47.8600032, lng: 17.9473784 },
    { lat: 47.8600516, lng: 17.9472643 },
    { lat: 47.8602058, lng: 17.9471828 },
    { lat: 47.8601955, lng: 17.9470514 },
    { lat: 47.860664, lng: 17.9468838 },
    { lat: 47.8608267, lng: 17.9465172 },
    { lat: 47.8609206, lng: 17.9461672 },
    { lat: 47.8608653, lng: 17.945942 },
    { lat: 47.8609434, lng: 17.9455916 },
    { lat: 47.8611365, lng: 17.9455928 },
    { lat: 47.8614917, lng: 17.9450865 },
    { lat: 47.8614997, lng: 17.9448281 },
    { lat: 47.8617002, lng: 17.9444136 },
    { lat: 47.8620326, lng: 17.9441823 },
    { lat: 47.8622314, lng: 17.9438095 },
    { lat: 47.8624264, lng: 17.9436761 },
    { lat: 47.8624453, lng: 17.9435776 },
    { lat: 47.8622639, lng: 17.9432322 },
    { lat: 47.8622793, lng: 17.9430765 },
    { lat: 47.8628601, lng: 17.9422496 },
    { lat: 47.8629994, lng: 17.9420889 },
    { lat: 47.8635145, lng: 17.9420613 },
    { lat: 47.8644791, lng: 17.9413846 },
    { lat: 47.8653316, lng: 17.9413342 },
    { lat: 47.8657163, lng: 17.9410681 },
    { lat: 47.8665643, lng: 17.9407416 },
    { lat: 47.8669007, lng: 17.9404814 },
    { lat: 47.8677615, lng: 17.9396389 },
    { lat: 47.8684126, lng: 17.9387433 },
    { lat: 47.8687042, lng: 17.9384353 },
    { lat: 47.8693511, lng: 17.9379719 },
    { lat: 47.86974, lng: 17.9375562 },
    { lat: 47.8707922, lng: 17.937056 },
    { lat: 47.8716274, lng: 17.936201 },
    { lat: 47.8719119, lng: 17.9359986 },
    { lat: 47.872428, lng: 17.9358003 },
    { lat: 47.8730219, lng: 17.9357645 },
    { lat: 47.8731626, lng: 17.9358528 },
    { lat: 47.8733974, lng: 17.9364073 },
    { lat: 47.8735346, lng: 17.9364402 },
    { lat: 47.8744513, lng: 17.9359012 },
    { lat: 47.8754999, lng: 17.9358095 },
    { lat: 47.876092, lng: 17.9354491 },
    { lat: 47.876711, lng: 17.9355653 },
    { lat: 47.8768455, lng: 17.9355324 },
    { lat: 47.8782685, lng: 17.9347833 },
    { lat: 47.878785, lng: 17.9347976 },
    { lat: 47.8787421, lng: 17.9341803 },
    { lat: 47.8788203, lng: 17.9340401 },
    { lat: 47.879047, lng: 17.9338887 },
    { lat: 47.8792938, lng: 17.9336037 },
    { lat: 47.8799283, lng: 17.9325273 },
    { lat: 47.8800194, lng: 17.9324672 },
    { lat: 47.880744, lng: 17.9323016 },
    { lat: 47.8818568, lng: 17.932836 },
    { lat: 47.8819818, lng: 17.9329781 },
    { lat: 47.8821767, lng: 17.9334371 },
    { lat: 47.8822976, lng: 17.9338112 },
    { lat: 47.8823438, lng: 17.9343454 },
    { lat: 47.8824084, lng: 17.9345357 },
    { lat: 47.8824724, lng: 17.934679 },
    { lat: 47.8825895, lng: 17.9347677 },
    { lat: 47.8826654, lng: 17.9347519 },
    { lat: 47.88286, lng: 17.9345376 },
    { lat: 47.8836832, lng: 17.9333753 },
    { lat: 47.8838626, lng: 17.9332608 },
    { lat: 47.8844413, lng: 17.9331275 },
    { lat: 47.885111, lng: 17.9331127 },
    { lat: 47.8857792, lng: 17.9327247 },
    { lat: 47.8860114, lng: 17.9326801 },
    { lat: 47.8863082, lng: 17.9327542 },
    { lat: 47.8871289, lng: 17.9325897 },
    { lat: 47.8874918, lng: 17.9324688 },
    { lat: 47.8878665, lng: 17.9321164 },
    { lat: 47.8893816, lng: 17.9318424 },
    { lat: 47.8895638, lng: 17.931738 },
    { lat: 47.8896629, lng: 17.9315861 },
    { lat: 47.8900363, lng: 17.9306564 },
    { lat: 47.8894515, lng: 17.9299656 },
    { lat: 47.8892461, lng: 17.9292804 },
    { lat: 47.8891538, lng: 17.928728 },
    { lat: 47.8889148, lng: 17.9285284 },
    { lat: 47.8874769, lng: 17.9285316 },
    { lat: 47.8864377, lng: 17.9283496 },
    { lat: 47.8855596, lng: 17.9277767 },
    { lat: 47.8852206, lng: 17.9282007 },
    { lat: 47.8849627, lng: 17.9284323 },
    { lat: 47.8844249, lng: 17.928755 },
    { lat: 47.8836322, lng: 17.9288827 },
    { lat: 47.8832084, lng: 17.9288177 },
    { lat: 47.8827134, lng: 17.9284732 },
    { lat: 47.8821975, lng: 17.9279508 },
    { lat: 47.8817543, lng: 17.9273111 },
    { lat: 47.8813454, lng: 17.9265271 },
    { lat: 47.880888, lng: 17.9252272 },
    { lat: 47.8798921, lng: 17.9232164 },
    { lat: 47.8797308, lng: 17.9226812 },
    { lat: 47.8796477, lng: 17.9221527 },
    { lat: 47.8792836, lng: 17.9206609 },
    { lat: 47.8779969, lng: 17.9233477 },
    { lat: 47.8771736, lng: 17.9245446 },
    { lat: 47.8752253, lng: 17.9270459 },
    { lat: 47.8749201, lng: 17.9264664 },
    { lat: 47.8748692, lng: 17.925871 },
    { lat: 47.8748602, lng: 17.9249072 },
    { lat: 47.8733743, lng: 17.9232885 },
    { lat: 47.8728661, lng: 17.9229487 },
    { lat: 47.8727369, lng: 17.9224236 },
    { lat: 47.8730881, lng: 17.9219257 },
    { lat: 47.8736128, lng: 17.9206805 },
    { lat: 47.8733938, lng: 17.9207356 },
    { lat: 47.8729606, lng: 17.9207225 },
    { lat: 47.8728406, lng: 17.9205858 },
    { lat: 47.8727614, lng: 17.9202125 },
    { lat: 47.872913, lng: 17.9196272 },
    { lat: 47.8729145, lng: 17.9193172 },
    { lat: 47.8728776, lng: 17.9189731 },
    { lat: 47.8727889, lng: 17.9187551 },
    { lat: 47.8725942, lng: 17.9186189 },
    { lat: 47.8723463, lng: 17.918627 },
    { lat: 47.8720871, lng: 17.9188162 },
    { lat: 47.8714331, lng: 17.9195863 },
    { lat: 47.8710436, lng: 17.9199355 },
    { lat: 47.870773, lng: 17.9200172 },
    { lat: 47.8703836, lng: 17.9199073 },
    { lat: 47.8700563, lng: 17.9197232 },
    { lat: 47.8697357, lng: 17.9193529 },
    { lat: 47.8693927, lng: 17.9187227 },
    { lat: 47.8692054, lng: 17.9180755 },
    { lat: 47.8691072, lng: 17.9172608 },
    { lat: 47.8687749, lng: 17.9154963 },
    { lat: 47.8686795, lng: 17.9131366 },
    { lat: 47.8686225, lng: 17.9127566 },
    { lat: 47.8684775, lng: 17.9126018 },
    { lat: 47.8684011, lng: 17.9126064 },
    { lat: 47.8681346, lng: 17.912848 },
    { lat: 47.8669052, lng: 17.9141747 },
    { lat: 47.8657981, lng: 17.915732 },
    { lat: 47.8650715, lng: 17.917109 },
    { lat: 47.8647688, lng: 17.9177899 },
    { lat: 47.8641714, lng: 17.9198236 },
    { lat: 47.8632704, lng: 17.9192064 },
    { lat: 47.8624336, lng: 17.9194045 },
    { lat: 47.8622708, lng: 17.9194925 },
    { lat: 47.8620658, lng: 17.9198712 },
    { lat: 47.8602671, lng: 17.923195 },
    { lat: 47.8598906, lng: 17.9233898 },
    { lat: 47.8594413, lng: 17.9237753 },
    { lat: 47.8591455, lng: 17.9241662 },
    { lat: 47.8584406, lng: 17.9254894 },
    { lat: 47.8576176, lng: 17.9274881 },
    { lat: 47.8576876, lng: 17.9279732 },
    { lat: 47.857944, lng: 17.9285868 },
    { lat: 47.8585886, lng: 17.9292354 },
    { lat: 47.8592679, lng: 17.9302688 },
    { lat: 47.8594405, lng: 17.9313063 },
    { lat: 47.8595847, lng: 17.9314725 },
    { lat: 47.8598748, lng: 17.9322313 },
    { lat: 47.85944, lng: 17.9327625 },
    { lat: 47.8590166, lng: 17.9337461 },
    { lat: 47.8584967, lng: 17.9347927 },
    { lat: 47.8582772, lng: 17.9347445 },
    { lat: 47.8574259, lng: 17.9360042 },
    { lat: 47.8572141, lng: 17.9360105 },
    { lat: 47.8568939, lng: 17.9358324 },
    { lat: 47.8563556, lng: 17.9353312 },
    { lat: 47.8558239, lng: 17.9349576 },
    { lat: 47.8556589, lng: 17.9349018 },
    { lat: 47.8552765, lng: 17.9349802 },
    { lat: 47.853489, lng: 17.9362668 },
    { lat: 47.851475, lng: 17.9370411 },
    { lat: 47.8509913, lng: 17.9375158 },
    { lat: 47.8504658, lng: 17.9381896 },
    { lat: 47.8481511, lng: 17.9414642 },
    { lat: 47.847362, lng: 17.9421868 },
    { lat: 47.8468456, lng: 17.9423421 },
    { lat: 47.8464329, lng: 17.9423697 },
    { lat: 47.8458206, lng: 17.9422294 },
    { lat: 47.8452417, lng: 17.9419712 },
    { lat: 47.8445412, lng: 17.9414987 },
    { lat: 47.8441021, lng: 17.9413479 },
    { lat: 47.8437395, lng: 17.9414387 },
    { lat: 47.8435272, lng: 17.9416024 },
    { lat: 47.8433064, lng: 17.941943 },
    { lat: 47.8432253, lng: 17.9423913 },
    { lat: 47.8431952, lng: 17.9438647 },
    { lat: 47.8431206, lng: 17.9445489 },
    { lat: 47.8430227, lng: 17.9449267 },
    { lat: 47.8427899, lng: 17.9454586 },
    { lat: 47.8414311, lng: 17.9471919 },
    { lat: 47.8412444, lng: 17.947576 },
    { lat: 47.841123, lng: 17.9481041 },
    { lat: 47.8411643, lng: 17.9486333 },
    { lat: 47.8413019, lng: 17.94906 },
    { lat: 47.8418414, lng: 17.9501935 },
    { lat: 47.8420343, lng: 17.9508426 },
    { lat: 47.8420983, lng: 17.9516175 },
    { lat: 47.8419582, lng: 17.9522659 },
    { lat: 47.8416734, lng: 17.9528925 },
    { lat: 47.8408418, lng: 17.9542739 },
    { lat: 47.8403555, lng: 17.9547792 },
    { lat: 47.8396355, lng: 17.9552473 },
    { lat: 47.8395113, lng: 17.955429 },
    { lat: 47.8394062, lng: 17.9560446 },
    { lat: 47.8393819, lng: 17.9565165 },
    { lat: 47.8392966, lng: 17.956823 },
    { lat: 47.83912, lng: 17.9572064 },
    { lat: 47.8386364, lng: 17.9574176 },
    { lat: 47.8367505, lng: 17.9559342 },
    { lat: 47.8362066, lng: 17.9551375 },
    { lat: 47.8357841, lng: 17.9547022 },
    { lat: 47.8351673, lng: 17.9545087 },
    { lat: 47.8347218, lng: 17.9556095 },
    { lat: 47.8336237, lng: 17.9550574 },
    { lat: 47.8330012, lng: 17.954859 },
    { lat: 47.8320242, lng: 17.9547204 },
    { lat: 47.8305011, lng: 17.9546352 },
    { lat: 47.8304866, lng: 17.9542128 },
    { lat: 47.8301505, lng: 17.9532504 },
    { lat: 47.8281349, lng: 17.949052 },
    { lat: 47.8273425, lng: 17.9482885 },
    { lat: 47.8255876, lng: 17.9463435 },
    { lat: 47.8251205, lng: 17.9456981 },
    { lat: 47.8245253, lng: 17.9447428 },
    { lat: 47.8242798, lng: 17.9441203 },
    { lat: 47.8238457, lng: 17.943796 },
    { lat: 47.8236388, lng: 17.9446617 },
    { lat: 47.8232127, lng: 17.9451182 },
    { lat: 47.8215473, lng: 17.9478577 },
    { lat: 47.8206294, lng: 17.9494894 },
    { lat: 47.8196977, lng: 17.9513005 },
    { lat: 47.8196156, lng: 17.9511942 },
    { lat: 47.8184804, lng: 17.9527765 },
    { lat: 47.8163278, lng: 17.9548759 },
    { lat: 47.8154143, lng: 17.9555224 },
    { lat: 47.8148563, lng: 17.9560557 },
    { lat: 47.8144881, lng: 17.9560687 },
    { lat: 47.8139112, lng: 17.9564037 },
    { lat: 47.8133268, lng: 17.9578035 },
    { lat: 47.8133113, lng: 17.9578235 },
    { lat: 47.8128958, lng: 17.9583629 },
    { lat: 47.8122683, lng: 17.9602028 },
    { lat: 47.8124228, lng: 17.9607219 },
    { lat: 47.8129516, lng: 17.9612372 },
    { lat: 47.8132488, lng: 17.9625062 },
    { lat: 47.8130734, lng: 17.9626293 },
    { lat: 47.8131298, lng: 17.9628535 },
    { lat: 47.8130738, lng: 17.9628572 },
    { lat: 47.8129589, lng: 17.9624719 },
    { lat: 47.812899, lng: 17.9625111 },
    { lat: 47.8129389, lng: 17.9626259 },
    { lat: 47.8123414, lng: 17.9630302 },
    { lat: 47.8119109, lng: 17.9631702 },
    { lat: 47.8111295, lng: 17.9632578 },
    { lat: 47.810884, lng: 17.9634528 },
    { lat: 47.8104462, lng: 17.9636051 },
    { lat: 47.8097756, lng: 17.9633615 },
    { lat: 47.8095387, lng: 17.9634563 },
    { lat: 47.8092082, lng: 17.9636458 },
    { lat: 47.8088711, lng: 17.9640047 },
    { lat: 47.8080613, lng: 17.9650581 },
    { lat: 47.8077593, lng: 17.9655658 },
    { lat: 47.8074924, lng: 17.9661923 },
    { lat: 47.8073754, lng: 17.9663656 },
    { lat: 47.8069271, lng: 17.9666993 },
    { lat: 47.8064269, lng: 17.9666955 },
    { lat: 47.8061307, lng: 17.9668162 },
    { lat: 47.8057955, lng: 17.9671895 },
    { lat: 47.8041448, lng: 17.9677772 },
    { lat: 47.8034553, lng: 17.9679286 },
    { lat: 47.8029022, lng: 17.9677981 },
    { lat: 47.8022613, lng: 17.9678377 },
    { lat: 47.8016336, lng: 17.9681152 },
    { lat: 47.8011706, lng: 17.9686878 },
    { lat: 47.8007642, lng: 17.9689675 },
    { lat: 47.8006714, lng: 17.9693909 },
    { lat: 47.8005721, lng: 17.9695783 },
    { lat: 47.8062794, lng: 17.9709676 },
    { lat: 47.8070917, lng: 17.9733631 },
    { lat: 47.8072811, lng: 17.9741099 },
    { lat: 47.8075263, lng: 17.9755304 },
    { lat: 47.8072513, lng: 17.9803468 },
    { lat: 47.807288, lng: 17.9823936 },
    { lat: 47.807524, lng: 17.9839814 },
    { lat: 47.8079597, lng: 17.9860559 },
    { lat: 47.808124, lng: 17.9861597 },
    { lat: 47.808093, lng: 17.9881092 },
    { lat: 47.8080379, lng: 17.9887317 },
    { lat: 47.807842, lng: 17.9896734 },
    { lat: 47.8075027, lng: 17.9905762 },
    { lat: 47.8072824, lng: 17.990872 },
    { lat: 47.8071494, lng: 17.9909677 },
    { lat: 47.8066366, lng: 17.9910183 },
    { lat: 47.8063218, lng: 17.990984 },
    { lat: 47.8055667, lng: 17.9903273 },
    { lat: 47.8045331, lng: 17.9896795 },
    { lat: 47.804274, lng: 17.9895921 },
    { lat: 47.803867, lng: 17.9896396 },
    { lat: 47.8038135, lng: 17.9895755 },
    { lat: 47.8035622, lng: 17.989676 },
    { lat: 47.8034313, lng: 17.9898219 },
    { lat: 47.8033254, lng: 17.9900694 },
    { lat: 47.8033611, lng: 17.991484 },
    { lat: 47.803325, lng: 17.9918023 },
    { lat: 47.8028491, lng: 17.9929686 },
    { lat: 47.8034938, lng: 17.9939792 },
    { lat: 47.8055279, lng: 17.9975388 },
    { lat: 47.8059842, lng: 17.9983671 },
    { lat: 47.8060713, lng: 17.9986862 },
    { lat: 47.8076423, lng: 17.9995272 },
    { lat: 47.807943, lng: 18.0002469 },
    { lat: 47.8080752, lng: 18.0013181 },
    { lat: 47.8078565, lng: 18.0018085 },
    { lat: 47.808005, lng: 18.001801 },
    { lat: 47.8081883, lng: 18.0020323 },
    { lat: 47.8076965, lng: 18.0032151 },
    { lat: 47.8073542, lng: 18.0034737 },
    { lat: 47.8071881, lng: 18.003488 },
    { lat: 47.8069212, lng: 18.0033479 },
    { lat: 47.806745, lng: 18.0031593 },
    { lat: 47.8063369, lng: 18.0034122 },
    { lat: 47.8060864, lng: 18.0034118 },
  ],
  Kameničná: [
    { lat: 47.8970715, lng: 17.9248814 },
    { lat: 47.8969782, lng: 17.9249056 },
    { lat: 47.8947485, lng: 17.9229889 },
    { lat: 47.8949263, lng: 17.9239825 },
    { lat: 47.8948402, lng: 17.9252725 },
    { lat: 47.8944639, lng: 17.9264335 },
    { lat: 47.8940164, lng: 17.9271876 },
    { lat: 47.8935674, lng: 17.9276342 },
    { lat: 47.8930775, lng: 17.928489 },
    { lat: 47.8925351, lng: 17.9286467 },
    { lat: 47.8920403, lng: 17.9289348 },
    { lat: 47.8912209, lng: 17.9291597 },
    { lat: 47.8905946, lng: 17.9292186 },
    { lat: 47.8899265, lng: 17.9290735 },
    { lat: 47.8893325, lng: 17.9288081 },
    { lat: 47.8891538, lng: 17.928728 },
    { lat: 47.8892461, lng: 17.9292804 },
    { lat: 47.8894515, lng: 17.9299656 },
    { lat: 47.8900363, lng: 17.9306564 },
    { lat: 47.8896629, lng: 17.9315861 },
    { lat: 47.8895638, lng: 17.931738 },
    { lat: 47.8893816, lng: 17.9318424 },
    { lat: 47.8878665, lng: 17.9321164 },
    { lat: 47.8874918, lng: 17.9324688 },
    { lat: 47.8871289, lng: 17.9325897 },
    { lat: 47.8863082, lng: 17.9327542 },
    { lat: 47.8860114, lng: 17.9326801 },
    { lat: 47.8857792, lng: 17.9327247 },
    { lat: 47.885111, lng: 17.9331127 },
    { lat: 47.8844413, lng: 17.9331275 },
    { lat: 47.8838626, lng: 17.9332608 },
    { lat: 47.8836832, lng: 17.9333753 },
    { lat: 47.88286, lng: 17.9345376 },
    { lat: 47.8826654, lng: 17.9347519 },
    { lat: 47.8825895, lng: 17.9347677 },
    { lat: 47.8824724, lng: 17.934679 },
    { lat: 47.8824084, lng: 17.9345357 },
    { lat: 47.8823438, lng: 17.9343454 },
    { lat: 47.8822976, lng: 17.9338112 },
    { lat: 47.8821767, lng: 17.9334371 },
    { lat: 47.8819818, lng: 17.9329781 },
    { lat: 47.8818568, lng: 17.932836 },
    { lat: 47.880744, lng: 17.9323016 },
    { lat: 47.8800194, lng: 17.9324672 },
    { lat: 47.8799283, lng: 17.9325273 },
    { lat: 47.8792938, lng: 17.9336037 },
    { lat: 47.879047, lng: 17.9338887 },
    { lat: 47.8788203, lng: 17.9340401 },
    { lat: 47.8787421, lng: 17.9341803 },
    { lat: 47.878785, lng: 17.9347976 },
    { lat: 47.8782685, lng: 17.9347833 },
    { lat: 47.8768455, lng: 17.9355324 },
    { lat: 47.876711, lng: 17.9355653 },
    { lat: 47.876092, lng: 17.9354491 },
    { lat: 47.8754999, lng: 17.9358095 },
    { lat: 47.8744513, lng: 17.9359012 },
    { lat: 47.8735346, lng: 17.9364402 },
    { lat: 47.8733974, lng: 17.9364073 },
    { lat: 47.8731626, lng: 17.9358528 },
    { lat: 47.8730219, lng: 17.9357645 },
    { lat: 47.872428, lng: 17.9358003 },
    { lat: 47.8719119, lng: 17.9359986 },
    { lat: 47.8716274, lng: 17.936201 },
    { lat: 47.8707922, lng: 17.937056 },
    { lat: 47.86974, lng: 17.9375562 },
    { lat: 47.8693511, lng: 17.9379719 },
    { lat: 47.8687042, lng: 17.9384353 },
    { lat: 47.8684126, lng: 17.9387433 },
    { lat: 47.8677615, lng: 17.9396389 },
    { lat: 47.8669007, lng: 17.9404814 },
    { lat: 47.8665643, lng: 17.9407416 },
    { lat: 47.8657163, lng: 17.9410681 },
    { lat: 47.8653316, lng: 17.9413342 },
    { lat: 47.8644791, lng: 17.9413846 },
    { lat: 47.8635145, lng: 17.9420613 },
    { lat: 47.8629994, lng: 17.9420889 },
    { lat: 47.8628601, lng: 17.9422496 },
    { lat: 47.8622793, lng: 17.9430765 },
    { lat: 47.8622639, lng: 17.9432322 },
    { lat: 47.8624453, lng: 17.9435776 },
    { lat: 47.8624264, lng: 17.9436761 },
    { lat: 47.8622314, lng: 17.9438095 },
    { lat: 47.8620326, lng: 17.9441823 },
    { lat: 47.8617002, lng: 17.9444136 },
    { lat: 47.8614997, lng: 17.9448281 },
    { lat: 47.8614917, lng: 17.9450865 },
    { lat: 47.8611365, lng: 17.9455928 },
    { lat: 47.8609434, lng: 17.9455916 },
    { lat: 47.8608653, lng: 17.945942 },
    { lat: 47.8609206, lng: 17.9461672 },
    { lat: 47.8608267, lng: 17.9465172 },
    { lat: 47.860664, lng: 17.9468838 },
    { lat: 47.8601955, lng: 17.9470514 },
    { lat: 47.8602058, lng: 17.9471828 },
    { lat: 47.8600516, lng: 17.9472643 },
    { lat: 47.8600032, lng: 17.9473784 },
    { lat: 47.860079, lng: 17.9476968 },
    { lat: 47.8596267, lng: 17.9484474 },
    { lat: 47.8593507, lng: 17.948568 },
    { lat: 47.8589759, lng: 17.9485859 },
    { lat: 47.8587001, lng: 17.9485231 },
    { lat: 47.8585727, lng: 17.9485791 },
    { lat: 47.8585199, lng: 17.9486955 },
    { lat: 47.8585746, lng: 17.949616 },
    { lat: 47.8586383, lng: 17.9499603 },
    { lat: 47.8590181, lng: 17.951007 },
    { lat: 47.8592113, lng: 17.9512911 },
    { lat: 47.8592996, lng: 17.9516315 },
    { lat: 47.859236, lng: 17.9521078 },
    { lat: 47.8592835, lng: 17.952333 },
    { lat: 47.8589577, lng: 17.9525422 },
    { lat: 47.8584962, lng: 17.9531217 },
    { lat: 47.8581408, lng: 17.9545769 },
    { lat: 47.8581564, lng: 17.9548499 },
    { lat: 47.8578818, lng: 17.9556258 },
    { lat: 47.8566912, lng: 17.9571952 },
    { lat: 47.8565475, lng: 17.9574509 },
    { lat: 47.8560655, lng: 17.9588639 },
    { lat: 47.8556741, lng: 17.9598001 },
    { lat: 47.8553328, lng: 17.9602953 },
    { lat: 47.8547648, lng: 17.9608871 },
    { lat: 47.8538207, lng: 17.9625902 },
    { lat: 47.8528233, lng: 17.9637153 },
    { lat: 47.8520709, lng: 17.964284 },
    { lat: 47.851498, lng: 17.9647759 },
    { lat: 47.8513454, lng: 17.964978 },
    { lat: 47.8507986, lng: 17.9664978 },
    { lat: 47.8507499, lng: 17.9670142 },
    { lat: 47.850823, lng: 17.9674011 },
    { lat: 47.8510792, lng: 17.9682612 },
    { lat: 47.8512304, lng: 17.9692857 },
    { lat: 47.8511555, lng: 17.9697827 },
    { lat: 47.8507967, lng: 17.9711266 },
    { lat: 47.8505485, lng: 17.9724707 },
    { lat: 47.8495975, lng: 17.9748125 },
    { lat: 47.8488404, lng: 17.9761775 },
    { lat: 47.8478387, lng: 17.9783167 },
    { lat: 47.8465487, lng: 17.98037 },
    { lat: 47.8458037, lng: 17.9813414 },
    { lat: 47.8448126, lng: 17.9828337 },
    { lat: 47.8443231, lng: 17.9848007 },
    { lat: 47.8441548, lng: 17.9859035 },
    { lat: 47.8437999, lng: 17.986716 },
    { lat: 47.8422051, lng: 17.9889023 },
    { lat: 47.8421974, lng: 17.9905686 },
    { lat: 47.8421332, lng: 17.9909829 },
    { lat: 47.8420034, lng: 17.9912461 },
    { lat: 47.8409858, lng: 17.992471 },
    { lat: 47.8400142, lng: 17.9940622 },
    { lat: 47.8394631, lng: 17.9945985 },
    { lat: 47.838997, lng: 17.9948819 },
    { lat: 47.8382483, lng: 17.9951189 },
    { lat: 47.8371826, lng: 17.9958248 },
    { lat: 47.8366432, lng: 17.9959591 },
    { lat: 47.8362689, lng: 17.9963212 },
    { lat: 47.835661, lng: 17.9970924 },
    { lat: 47.8346235, lng: 17.9975223 },
    { lat: 47.8339838, lng: 17.9977113 },
    { lat: 47.8332141, lng: 17.9981056 },
    { lat: 47.8328805, lng: 17.998563 },
    { lat: 47.8319045, lng: 17.9994663 },
    { lat: 47.8312733, lng: 18.0005098 },
    { lat: 47.830359, lng: 18.0023974 },
    { lat: 47.829781, lng: 18.0032582 },
    { lat: 47.8294197, lng: 18.0040251 },
    { lat: 47.8286312, lng: 18.0053999 },
    { lat: 47.8279291, lng: 18.0059983 },
    { lat: 47.8265933, lng: 18.0080311 },
    { lat: 47.8255663, lng: 18.0094199 },
    { lat: 47.8240009, lng: 18.0113303 },
    { lat: 47.8232068, lng: 18.0122195 },
    { lat: 47.8225267, lng: 18.0127368 },
    { lat: 47.8217254, lng: 18.0130534 },
    { lat: 47.8203275, lng: 18.0133067 },
    { lat: 47.8197728, lng: 18.0133174 },
    { lat: 47.8184792, lng: 18.0126533 },
    { lat: 47.817368, lng: 18.0118003 },
    { lat: 47.8152403, lng: 18.0098304 },
    { lat: 47.8140429, lng: 18.0080125 },
    { lat: 47.8133142, lng: 18.0066682 },
    { lat: 47.812841, lng: 18.0050162 },
    { lat: 47.8127392, lng: 18.0049019 },
    { lat: 47.8118149, lng: 18.0052778 },
    { lat: 47.8115787, lng: 18.0057375 },
    { lat: 47.8115167, lng: 18.0063033 },
    { lat: 47.8115512, lng: 18.0065761 },
    { lat: 47.8114751, lng: 18.0070567 },
    { lat: 47.8112575, lng: 18.0076028 },
    { lat: 47.811151, lng: 18.007632 },
    { lat: 47.8109129, lng: 18.0081601 },
    { lat: 47.8107125, lng: 18.0084644 },
    { lat: 47.8093343, lng: 18.0092884 },
    { lat: 47.8086199, lng: 18.0097965 },
    { lat: 47.8074273, lng: 18.0117294 },
    { lat: 47.8066722, lng: 18.0132507 },
    { lat: 47.8058035, lng: 18.0157107 },
    { lat: 47.804593, lng: 18.0184026 },
    { lat: 47.8039013, lng: 18.0193442 },
    { lat: 47.8022223, lng: 18.0214349 },
    { lat: 47.8021204, lng: 18.0213922 },
    { lat: 47.8019326, lng: 18.0222659 },
    { lat: 47.8019401, lng: 18.0226338 },
    { lat: 47.8020322, lng: 18.0227975 },
    { lat: 47.8024705, lng: 18.0232748 },
    { lat: 47.8027811, lng: 18.0239171 },
    { lat: 47.8028995, lng: 18.0243248 },
    { lat: 47.803437, lng: 18.0272452 },
    { lat: 47.8037824, lng: 18.0279199 },
    { lat: 47.8045567, lng: 18.0290651 },
    { lat: 47.8048354, lng: 18.0295748 },
    { lat: 47.805105, lng: 18.0302293 },
    { lat: 47.8051566, lng: 18.0305444 },
    { lat: 47.8049452, lng: 18.0317034 },
    { lat: 47.8042946, lng: 18.0342731 },
    { lat: 47.8040725, lng: 18.0349344 },
    { lat: 47.8040417, lng: 18.0352301 },
    { lat: 47.8040621, lng: 18.0356438 },
    { lat: 47.8041702, lng: 18.0359047 },
    { lat: 47.8048377, lng: 18.0369276 },
    { lat: 47.8016475, lng: 18.0405677 },
    { lat: 47.7996795, lng: 18.0430748 },
    { lat: 47.7997515, lng: 18.0435974 },
    { lat: 47.7997538, lng: 18.0442191 },
    { lat: 47.7996748, lng: 18.0448986 },
    { lat: 47.7995186, lng: 18.0451926 },
    { lat: 47.7988584, lng: 18.045834 },
    { lat: 47.7986024, lng: 18.0462353 },
    { lat: 47.7983432, lng: 18.0471828 },
    { lat: 47.7981577, lng: 18.0486453 },
    { lat: 47.7981728, lng: 18.0491982 },
    { lat: 47.7982989, lng: 18.0503799 },
    { lat: 47.7985532, lng: 18.0516135 },
    { lat: 47.7989405, lng: 18.052502 },
    { lat: 47.7998401, lng: 18.0539546 },
    { lat: 47.8009605, lng: 18.0551783 },
    { lat: 47.8009871, lng: 18.0552078 },
    { lat: 47.8013563, lng: 18.0557548 },
    { lat: 47.801567, lng: 18.0562051 },
    { lat: 47.8017123, lng: 18.0566693 },
    { lat: 47.8021584, lng: 18.0576001 },
    { lat: 47.803498, lng: 18.0601297 },
    { lat: 47.803099, lng: 18.0608372 },
    { lat: 47.8028848, lng: 18.0616882 },
    { lat: 47.8041883, lng: 18.065658 },
    { lat: 47.8052772, lng: 18.0672243 },
    { lat: 47.8056296, lng: 18.067398 },
    { lat: 47.8058351, lng: 18.0677038 },
    { lat: 47.8091457, lng: 18.0685415 },
    { lat: 47.8083904, lng: 18.0646058 },
    { lat: 47.80808, lng: 18.0620745 },
    { lat: 47.8079048, lng: 18.0598592 },
    { lat: 47.8080133, lng: 18.0599576 },
    { lat: 47.8080343, lng: 18.0596649 },
    { lat: 47.8082704, lng: 18.0587084 },
    { lat: 47.8085809, lng: 18.0578621 },
    { lat: 47.809204, lng: 18.0566348 },
    { lat: 47.8095467, lng: 18.0560813 },
    { lat: 47.8100409, lng: 18.05555 },
    { lat: 47.8106573, lng: 18.0550063 },
    { lat: 47.8111752, lng: 18.0547204 },
    { lat: 47.8121554, lng: 18.0544314 },
    { lat: 47.8128841, lng: 18.0549537 },
    { lat: 47.8140652, lng: 18.0554324 },
    { lat: 47.8196388, lng: 18.057084 },
    { lat: 47.8204395, lng: 18.0569647 },
    { lat: 47.8211774, lng: 18.0570357 },
    { lat: 47.8220047, lng: 18.0571475 },
    { lat: 47.8223792, lng: 18.0572959 },
    { lat: 47.8230329, lng: 18.0573769 },
    { lat: 47.8236964, lng: 18.0575437 },
    { lat: 47.8239442, lng: 18.0575356 },
    { lat: 47.8238748, lng: 18.0574359 },
    { lat: 47.8234553, lng: 18.055644 },
    { lat: 47.8245466, lng: 18.0557572 },
    { lat: 47.8259929, lng: 18.0557305 },
    { lat: 47.8283214, lng: 18.0554688 },
    { lat: 47.8299847, lng: 18.0554104 },
    { lat: 47.8305747, lng: 18.0551612 },
    { lat: 47.8313462, lng: 18.0545688 },
    { lat: 47.8334056, lng: 18.0518835 },
    { lat: 47.8368583, lng: 18.0462486 },
    { lat: 47.8376834, lng: 18.0450429 },
    { lat: 47.8387358, lng: 18.0432814 },
    { lat: 47.8398105, lng: 18.041664 },
    { lat: 47.8403475, lng: 18.0412091 },
    { lat: 47.8410191, lng: 18.0408175 },
    { lat: 47.8415021, lng: 18.0407189 },
    { lat: 47.8427201, lng: 18.0402737 },
    { lat: 47.8453942, lng: 18.0384896 },
    { lat: 47.8470286, lng: 18.0369861 },
    { lat: 47.8478008, lng: 18.0365007 },
    { lat: 47.8487536, lng: 18.0357148 },
    { lat: 47.8501973, lng: 18.0347188 },
    { lat: 47.8510104, lng: 18.0340666 },
    { lat: 47.8511824, lng: 18.033832 },
    { lat: 47.8546932, lng: 18.0320366 },
    { lat: 47.8564749, lng: 18.0312775 },
    { lat: 47.8581397, lng: 18.0306786 },
    { lat: 47.8608702, lng: 18.0299118 },
    { lat: 47.8629802, lng: 18.0295086 },
    { lat: 47.8654693, lng: 18.0292541 },
    { lat: 47.866623, lng: 18.0291832 },
    { lat: 47.8695829, lng: 18.0291541 },
    { lat: 47.8712087, lng: 18.0290523 },
    { lat: 47.8714061, lng: 18.0289917 },
    { lat: 47.8724955, lng: 18.0286965 },
    { lat: 47.8717711, lng: 18.0280434 },
    { lat: 47.8705128, lng: 18.0266892 },
    { lat: 47.8699982, lng: 18.0259177 },
    { lat: 47.8696801, lng: 18.0253025 },
    { lat: 47.8695009, lng: 18.0247983 },
    { lat: 47.8693794, lng: 18.0242211 },
    { lat: 47.8690196, lng: 18.021686 },
    { lat: 47.8690494, lng: 18.0203984 },
    { lat: 47.8691872, lng: 18.0194205 },
    { lat: 47.8695451, lng: 18.0178669 },
    { lat: 47.8700271, lng: 18.0163276 },
    { lat: 47.8712753, lng: 18.0146153 },
    { lat: 47.8718929, lng: 18.0141122 },
    { lat: 47.8726428, lng: 18.0136709 },
    { lat: 47.8730917, lng: 18.0131199 },
    { lat: 47.8732957, lng: 18.0126657 },
    { lat: 47.8734383, lng: 18.0122013 },
    { lat: 47.8734183, lng: 18.0114011 },
    { lat: 47.8731009, lng: 18.0108348 },
    { lat: 47.8728357, lng: 18.0105902 },
    { lat: 47.8724892, lng: 18.0104598 },
    { lat: 47.8722358, lng: 18.0104309 },
    { lat: 47.8715904, lng: 18.0105848 },
    { lat: 47.870978, lng: 18.0110989 },
    { lat: 47.8695375, lng: 18.0128027 },
    { lat: 47.8689937, lng: 18.0133484 },
    { lat: 47.8689806, lng: 18.0097916 },
    { lat: 47.8676136, lng: 18.0061289 },
    { lat: 47.8671351, lng: 18.0057936 },
    { lat: 47.8669037, lng: 18.0057379 },
    { lat: 47.8664567, lng: 18.0053601 },
    { lat: 47.8663806, lng: 18.0053681 },
    { lat: 47.8658393, lng: 18.0056604 },
    { lat: 47.8648062, lng: 18.0059911 },
    { lat: 47.8642273, lng: 18.0064047 },
    { lat: 47.8635152, lng: 18.0073432 },
    { lat: 47.8628019, lng: 18.0080193 },
    { lat: 47.8624696, lng: 18.0075806 },
    { lat: 47.8621408, lng: 18.0075744 },
    { lat: 47.8618082, lng: 18.0078997 },
    { lat: 47.8615636, lng: 18.0078475 },
    { lat: 47.8608536, lng: 18.0079229 },
    { lat: 47.8606776, lng: 18.0078869 },
    { lat: 47.8602427, lng: 18.0081039 },
    { lat: 47.859388, lng: 18.0075192 },
    { lat: 47.8587904, lng: 18.0072289 },
    { lat: 47.858559, lng: 18.0070386 },
    { lat: 47.85833, lng: 18.0067791 },
    { lat: 47.8582194, lng: 18.006571 },
    { lat: 47.8581791, lng: 18.00626 },
    { lat: 47.8579781, lng: 18.0058432 },
    { lat: 47.857074, lng: 18.0057898 },
    { lat: 47.8573065, lng: 18.0050399 },
    { lat: 47.8573424, lng: 18.0045828 },
    { lat: 47.8579418, lng: 18.0022793 },
    { lat: 47.8583101, lng: 18.001162 },
    { lat: 47.8584611, lng: 18.0008812 },
    { lat: 47.8585902, lng: 18.0006412 },
    { lat: 47.8597086, lng: 17.999423 },
    { lat: 47.8603464, lng: 17.9986015 },
    { lat: 47.8604711, lng: 17.9983673 },
    { lat: 47.8605207, lng: 17.9978786 },
    { lat: 47.8604033, lng: 17.9970142 },
    { lat: 47.8604439, lng: 17.9964914 },
    { lat: 47.860501, lng: 17.9963662 },
    { lat: 47.8605256, lng: 17.9963122 },
    { lat: 47.8613212, lng: 17.9950174 },
    { lat: 47.8617372, lng: 17.9939477 },
    { lat: 47.8618997, lng: 17.9935299 },
    { lat: 47.8619691, lng: 17.9934296 },
    { lat: 47.8623783, lng: 17.9928388 },
    { lat: 47.8630943, lng: 17.9913295 },
    { lat: 47.8635779, lng: 17.9906666 },
    { lat: 47.864388, lng: 17.9891271 },
    { lat: 47.8650449, lng: 17.9875925 },
    { lat: 47.8654601, lng: 17.9868813 },
    { lat: 47.8659738, lng: 17.9855362 },
    { lat: 47.86628, lng: 17.9849042 },
    { lat: 47.8667425, lng: 17.9841539 },
    { lat: 47.8671702, lng: 17.983686 },
    { lat: 47.8674076, lng: 17.9832803 },
    { lat: 47.8674897, lng: 17.9830311 },
    { lat: 47.867494, lng: 17.9817588 },
    { lat: 47.8676976, lng: 17.9811481 },
    { lat: 47.8681102, lng: 17.9802252 },
    { lat: 47.8685109, lng: 17.9786173 },
    { lat: 47.8687233, lng: 17.9768683 },
    { lat: 47.8688535, lng: 17.9762014 },
    { lat: 47.8690953, lng: 17.97542 },
    { lat: 47.8695096, lng: 17.974507 },
    { lat: 47.8701014, lng: 17.9735411 },
    { lat: 47.8703723, lng: 17.9732265 },
    { lat: 47.8715509, lng: 17.9725772 },
    { lat: 47.8717947, lng: 17.9722944 },
    { lat: 47.8719902, lng: 17.9719455 },
    { lat: 47.8722752, lng: 17.9707056 },
    { lat: 47.872502, lng: 17.9699581 },
    { lat: 47.8728518, lng: 17.9694354 },
    { lat: 47.8733259, lng: 17.9690226 },
    { lat: 47.8735998, lng: 17.9686819 },
    { lat: 47.8741571, lng: 17.9677643 },
    { lat: 47.8743481, lng: 17.9672832 },
    { lat: 47.8745718, lng: 17.9662032 },
    { lat: 47.8752388, lng: 17.9640974 },
    { lat: 47.8752154, lng: 17.9638597 },
    { lat: 47.874809, lng: 17.9632261 },
    { lat: 47.8747174, lng: 17.9629585 },
    { lat: 47.8746586, lng: 17.9625308 },
    { lat: 47.8746561, lng: 17.9619855 },
    { lat: 47.8747433, lng: 17.9610792 },
    { lat: 47.8751236, lng: 17.9600126 },
    { lat: 47.8752828, lng: 17.9591971 },
    { lat: 47.87554, lng: 17.9588006 },
    { lat: 47.8765508, lng: 17.9582332 },
    { lat: 47.877011, lng: 17.9578034 },
    { lat: 47.8776069, lng: 17.9565916 },
    { lat: 47.8776832, lng: 17.9558757 },
    { lat: 47.8778297, lng: 17.9556659 },
    { lat: 47.8785862, lng: 17.9551687 },
    { lat: 47.8786634, lng: 17.9548971 },
    { lat: 47.8786275, lng: 17.9543791 },
    { lat: 47.8787799, lng: 17.954134 },
    { lat: 47.8791061, lng: 17.9539673 },
    { lat: 47.87924, lng: 17.9537176 },
    { lat: 47.879326, lng: 17.9534821 },
    { lat: 47.8793161, lng: 17.9531519 },
    { lat: 47.8790242, lng: 17.9523395 },
    { lat: 47.8790025, lng: 17.951962 },
    { lat: 47.8790377, lng: 17.9515571 },
    { lat: 47.8792001, lng: 17.9508487 },
    { lat: 47.8792395, lng: 17.9501923 },
    { lat: 47.8793789, lng: 17.9499451 },
    { lat: 47.8799349, lng: 17.9495811 },
    { lat: 47.8800621, lng: 17.9493535 },
    { lat: 47.8801547, lng: 17.9487402 },
    { lat: 47.8801009, lng: 17.9484489 },
    { lat: 47.8798592, lng: 17.9479233 },
    { lat: 47.8797501, lng: 17.9474862 },
    { lat: 47.8797777, lng: 17.947178 },
    { lat: 47.8802275, lng: 17.9462766 },
    { lat: 47.8806481, lng: 17.9456731 },
    { lat: 47.8809981, lng: 17.9454145 },
    { lat: 47.8812316, lng: 17.9450769 },
    { lat: 47.8812836, lng: 17.9447805 },
    { lat: 47.8809567, lng: 17.9443628 },
    { lat: 47.8809422, lng: 17.9442722 },
    { lat: 47.8811968, lng: 17.9435025 },
    { lat: 47.881499, lng: 17.9428076 },
    { lat: 47.8816932, lng: 17.9421778 },
    { lat: 47.8821403, lng: 17.9411199 },
    { lat: 47.8824051, lng: 17.9408924 },
    { lat: 47.8833035, lng: 17.9407405 },
    { lat: 47.8845679, lng: 17.9402799 },
    { lat: 47.8856502, lng: 17.9401659 },
    { lat: 47.8858381, lng: 17.9400629 },
    { lat: 47.8863377, lng: 17.9395883 },
    { lat: 47.8865009, lng: 17.9396198 },
    { lat: 47.8865756, lng: 17.9396974 },
    { lat: 47.8868378, lng: 17.9403138 },
    { lat: 47.88694, lng: 17.9403809 },
    { lat: 47.8871116, lng: 17.9402503 },
    { lat: 47.8872478, lng: 17.9399661 },
    { lat: 47.8880498, lng: 17.9375625 },
    { lat: 47.8880721, lng: 17.9372996 },
    { lat: 47.8880324, lng: 17.9369775 },
    { lat: 47.8878993, lng: 17.936514 },
    { lat: 47.8879508, lng: 17.9363418 },
    { lat: 47.8885032, lng: 17.9358301 },
    { lat: 47.889002, lng: 17.935122 },
    { lat: 47.8899423, lng: 17.934287 },
    { lat: 47.8906699, lng: 17.9337577 },
    { lat: 47.8911908, lng: 17.9332692 },
    { lat: 47.8920768, lng: 17.9321721 },
    { lat: 47.8926585, lng: 17.9311777 },
    { lat: 47.8928433, lng: 17.9307231 },
    { lat: 47.8929324, lng: 17.9300376 },
    { lat: 47.8930481, lng: 17.9297323 },
    { lat: 47.8932648, lng: 17.9295463 },
    { lat: 47.893527, lng: 17.9294196 },
    { lat: 47.8945378, lng: 17.9290596 },
    { lat: 47.8948218, lng: 17.9289021 },
    { lat: 47.8958618, lng: 17.9280191 },
    { lat: 47.8963107, lng: 17.9277163 },
    { lat: 47.8970679, lng: 17.9274717 },
    { lat: 47.8973288, lng: 17.9273279 },
    { lat: 47.8976803, lng: 17.9270365 },
    { lat: 47.8983423, lng: 17.9263062 },
    { lat: 47.8984151, lng: 17.9261107 },
    { lat: 47.8982857, lng: 17.9256909 },
    { lat: 47.8977812, lng: 17.9252291 },
    { lat: 47.8975309, lng: 17.9251031 },
    { lat: 47.8971854, lng: 17.9250455 },
    { lat: 47.8970811, lng: 17.9249069 },
    { lat: 47.8970715, lng: 17.9248814 },
  ],
  Komárno: [
    { lat: 47.8472297, lng: 18.0977988 },
    { lat: 47.8471286, lng: 18.0978798 },
    { lat: 47.8469247, lng: 18.0980429 },
    { lat: 47.8464816, lng: 18.0982752 },
    { lat: 47.845848, lng: 18.0983628 },
    { lat: 47.8449779, lng: 18.0980033 },
    { lat: 47.8443309, lng: 18.0978832 },
    { lat: 47.843838, lng: 18.0978502 },
    { lat: 47.8435026, lng: 18.0979015 },
    { lat: 47.8425386, lng: 18.0982621 },
    { lat: 47.8420287, lng: 18.0983563 },
    { lat: 47.8408611, lng: 18.0978897 },
    { lat: 47.8405855, lng: 18.097904 },
    { lat: 47.8399459, lng: 18.0983482 },
    { lat: 47.8397658, lng: 18.0986033 },
    { lat: 47.8393717, lng: 18.0997017 },
    { lat: 47.8392001, lng: 18.1004032 },
    { lat: 47.8390386, lng: 18.100268 },
    { lat: 47.8387163, lng: 18.0994563 },
    { lat: 47.8384076, lng: 18.0992207 },
    { lat: 47.8380071, lng: 18.0990902 },
    { lat: 47.8378901, lng: 18.0991353 },
    { lat: 47.8377509, lng: 18.0990897 },
    { lat: 47.8375914, lng: 18.0989479 },
    { lat: 47.8372343, lng: 18.0992072 },
    { lat: 47.8370014, lng: 18.0992184 },
    { lat: 47.8368396, lng: 18.099155 },
    { lat: 47.836054, lng: 18.0994012 },
    { lat: 47.8356224, lng: 18.0992572 },
    { lat: 47.8353902, lng: 18.0993585 },
    { lat: 47.8344545, lng: 18.0981001 },
    { lat: 47.8341558, lng: 18.0972717 },
    { lat: 47.8337455, lng: 18.096635 },
    { lat: 47.8333491, lng: 18.0962125 },
    { lat: 47.8330353, lng: 18.0956808 },
    { lat: 47.8319203, lng: 18.0949405 },
    { lat: 47.8315417, lng: 18.0951548 },
    { lat: 47.8314829, lng: 18.0949465 },
    { lat: 47.8312285, lng: 18.0951105 },
    { lat: 47.8311368, lng: 18.0945901 },
    { lat: 47.8310819, lng: 18.0937611 },
    { lat: 47.8303864, lng: 18.0921275 },
    { lat: 47.8303627, lng: 18.0919747 },
    { lat: 47.8304945, lng: 18.0896746 },
    { lat: 47.8300227, lng: 18.0882671 },
    { lat: 47.829887, lng: 18.0871471 },
    { lat: 47.8298311, lng: 18.0862441 },
    { lat: 47.8295588, lng: 18.0859364 },
    { lat: 47.8289361, lng: 18.0857888 },
    { lat: 47.8286241, lng: 18.0854013 },
    { lat: 47.8285898, lng: 18.0851266 },
    { lat: 47.8286526, lng: 18.0846846 },
    { lat: 47.8277234, lng: 18.0829727 },
    { lat: 47.8268261, lng: 18.0809217 },
    { lat: 47.8245634, lng: 18.0763222 },
    { lat: 47.8223426, lng: 18.0740746 },
    { lat: 47.821754, lng: 18.07117 },
    { lat: 47.8219169, lng: 18.0704544 },
    { lat: 47.8219033, lng: 18.0690544 },
    { lat: 47.8214866, lng: 18.0679178 },
    { lat: 47.8210096, lng: 18.0637668 },
    { lat: 47.8224292, lng: 18.064081 },
    { lat: 47.8228188, lng: 18.0640876 },
    { lat: 47.8236989, lng: 18.0638959 },
    { lat: 47.8242386, lng: 18.0635386 },
    { lat: 47.8252981, lng: 18.0624787 },
    { lat: 47.8255563, lng: 18.062117 },
    { lat: 47.8257132, lng: 18.0616237 },
    { lat: 47.8257819, lng: 18.0611111 },
    { lat: 47.8258006, lng: 18.0602169 },
    { lat: 47.8253161, lng: 18.0598506 },
    { lat: 47.8250893, lng: 18.0593593 },
    { lat: 47.8248537, lng: 18.0589869 },
    { lat: 47.8245135, lng: 18.0585857 },
    { lat: 47.8243121, lng: 18.0580286 },
    { lat: 47.8240743, lng: 18.0577098 },
    { lat: 47.8239442, lng: 18.0575356 },
    { lat: 47.8236964, lng: 18.0575437 },
    { lat: 47.8230329, lng: 18.0573769 },
    { lat: 47.8223792, lng: 18.0572959 },
    { lat: 47.8220047, lng: 18.0571475 },
    { lat: 47.8211774, lng: 18.0570357 },
    { lat: 47.8204395, lng: 18.0569647 },
    { lat: 47.8196388, lng: 18.057084 },
    { lat: 47.8140652, lng: 18.0554324 },
    { lat: 47.8128841, lng: 18.0549537 },
    { lat: 47.8121554, lng: 18.0544314 },
    { lat: 47.8111752, lng: 18.0547204 },
    { lat: 47.8106573, lng: 18.0550063 },
    { lat: 47.8100409, lng: 18.05555 },
    { lat: 47.8095467, lng: 18.0560813 },
    { lat: 47.809204, lng: 18.0566348 },
    { lat: 47.8085809, lng: 18.0578621 },
    { lat: 47.8082704, lng: 18.0587084 },
    { lat: 47.8080343, lng: 18.0596649 },
    { lat: 47.8080133, lng: 18.0599576 },
    { lat: 47.8079048, lng: 18.0598592 },
    { lat: 47.80808, lng: 18.0620745 },
    { lat: 47.8083904, lng: 18.0646058 },
    { lat: 47.8091457, lng: 18.0685415 },
    { lat: 47.8058351, lng: 18.0677038 },
    { lat: 47.8056296, lng: 18.067398 },
    { lat: 47.8052772, lng: 18.0672243 },
    { lat: 47.8041883, lng: 18.065658 },
    { lat: 47.8028848, lng: 18.0616882 },
    { lat: 47.803099, lng: 18.0608372 },
    { lat: 47.803498, lng: 18.0601297 },
    { lat: 47.8021584, lng: 18.0576001 },
    { lat: 47.8017123, lng: 18.0566693 },
    { lat: 47.801567, lng: 18.0562051 },
    { lat: 47.8013563, lng: 18.0557548 },
    { lat: 47.8009871, lng: 18.0552078 },
    { lat: 47.8009605, lng: 18.0551783 },
    { lat: 47.7998401, lng: 18.0539546 },
    { lat: 47.7989405, lng: 18.052502 },
    { lat: 47.7985532, lng: 18.0516135 },
    { lat: 47.7982989, lng: 18.0503799 },
    { lat: 47.7981728, lng: 18.0491982 },
    { lat: 47.7981577, lng: 18.0486453 },
    { lat: 47.7983432, lng: 18.0471828 },
    { lat: 47.7986024, lng: 18.0462353 },
    { lat: 47.7988584, lng: 18.045834 },
    { lat: 47.7995186, lng: 18.0451926 },
    { lat: 47.7996748, lng: 18.0448986 },
    { lat: 47.7997538, lng: 18.0442191 },
    { lat: 47.7997515, lng: 18.0435974 },
    { lat: 47.7996795, lng: 18.0430748 },
    { lat: 47.8016475, lng: 18.0405677 },
    { lat: 47.8048377, lng: 18.0369276 },
    { lat: 47.8041702, lng: 18.0359047 },
    { lat: 47.8040621, lng: 18.0356438 },
    { lat: 47.8040417, lng: 18.0352301 },
    { lat: 47.8040725, lng: 18.0349344 },
    { lat: 47.8042946, lng: 18.0342731 },
    { lat: 47.8049452, lng: 18.0317034 },
    { lat: 47.8051566, lng: 18.0305444 },
    { lat: 47.805105, lng: 18.0302293 },
    { lat: 47.8048354, lng: 18.0295748 },
    { lat: 47.8045567, lng: 18.0290651 },
    { lat: 47.8037824, lng: 18.0279199 },
    { lat: 47.803437, lng: 18.0272452 },
    { lat: 47.8028995, lng: 18.0243248 },
    { lat: 47.8027811, lng: 18.0239171 },
    { lat: 47.8024705, lng: 18.0232748 },
    { lat: 47.8020322, lng: 18.0227975 },
    { lat: 47.8019401, lng: 18.0226338 },
    { lat: 47.8019326, lng: 18.0222659 },
    { lat: 47.8021204, lng: 18.0213922 },
    { lat: 47.8022223, lng: 18.0214349 },
    { lat: 47.8039013, lng: 18.0193442 },
    { lat: 47.804593, lng: 18.0184026 },
    { lat: 47.8058035, lng: 18.0157107 },
    { lat: 47.8066722, lng: 18.0132507 },
    { lat: 47.8074273, lng: 18.0117294 },
    { lat: 47.8086199, lng: 18.0097965 },
    { lat: 47.8093343, lng: 18.0092884 },
    { lat: 47.8090707, lng: 18.0087908 },
    { lat: 47.8081074, lng: 18.0069645 },
    { lat: 47.8074657, lng: 18.005249 },
    { lat: 47.8060864, lng: 18.0034118 },
    { lat: 47.8060702, lng: 18.0034069 },
    { lat: 47.8046829, lng: 18.0029708 },
    { lat: 47.801592, lng: 18.0010683 },
    { lat: 47.7893152, lng: 18.001232 },
    { lat: 47.7843508, lng: 18.0004735 },
    { lat: 47.7763244, lng: 17.9978276 },
    { lat: 47.7745359, lng: 17.9977213 },
    { lat: 47.7695365, lng: 17.9980841 },
    { lat: 47.7688525, lng: 17.9977273 },
    { lat: 47.7667089, lng: 17.9963969 },
    { lat: 47.7634199, lng: 17.9975357 },
    { lat: 47.7624261, lng: 17.9984054 },
    { lat: 47.7617613, lng: 17.9987775 },
    { lat: 47.7591722, lng: 18.0011153 },
    { lat: 47.758102, lng: 18.0059183 },
    { lat: 47.7560939, lng: 18.0106705 },
    { lat: 47.7544201, lng: 18.0169618 },
    { lat: 47.752248, lng: 18.0199666 },
    { lat: 47.7533219, lng: 18.022605 },
    { lat: 47.7541029, lng: 18.0245849 },
    { lat: 47.7547862, lng: 18.0266429 },
    { lat: 47.7553684, lng: 18.0287681 },
    { lat: 47.7558463, lng: 18.0309498 },
    { lat: 47.7562177, lng: 18.0331767 },
    { lat: 47.7564803, lng: 18.0354373 },
    { lat: 47.7566333, lng: 18.0377199 },
    { lat: 47.7566754, lng: 18.040013 },
    { lat: 47.7566067, lng: 18.0423047 },
    { lat: 47.7565631, lng: 18.0626532 },
    { lat: 47.7565393, lng: 18.0846265 },
    { lat: 47.7565134, lng: 18.0857906 },
    { lat: 47.756481, lng: 18.086895 },
    { lat: 47.7564699, lng: 18.0872758 },
    { lat: 47.7564564, lng: 18.0877335 },
    { lat: 47.7562936, lng: 18.0896632 },
    { lat: 47.7560258, lng: 18.0915671 },
    { lat: 47.7556551, lng: 18.0934324 },
    { lat: 47.7551837, lng: 18.0952473 },
    { lat: 47.754412, lng: 18.0980689 },
    { lat: 47.7537279, lng: 18.1009398 },
    { lat: 47.7531328, lng: 18.1038539 },
    { lat: 47.7526279, lng: 18.1068051 },
    { lat: 47.7522145, lng: 18.1097875 },
    { lat: 47.7518931, lng: 18.1127947 },
    { lat: 47.7512882, lng: 18.1208706 },
    { lat: 47.7509787, lng: 18.1250029 },
    { lat: 47.7508322, lng: 18.1269951 },
    { lat: 47.7506144, lng: 18.128973 },
    { lat: 47.7503261, lng: 18.1309309 },
    { lat: 47.7497841, lng: 18.1331858 },
    { lat: 47.7493258, lng: 18.1354811 },
    { lat: 47.7489525, lng: 18.1378099 },
    { lat: 47.7485008, lng: 18.140843 },
    { lat: 47.7479533, lng: 18.144176 },
    { lat: 47.7473833, lng: 18.1474997 },
    { lat: 47.7468176, lng: 18.1497653 },
    { lat: 47.7453091, lng: 18.155807 },
    { lat: 47.7443187, lng: 18.1599095 },
    { lat: 47.7432382, lng: 18.1639616 },
    { lat: 47.7420688, lng: 18.1679592 },
    { lat: 47.741554, lng: 18.1703303 },
    { lat: 47.7411252, lng: 18.1727387 },
    { lat: 47.7407837, lng: 18.1751776 },
    { lat: 47.7405305, lng: 18.17764 },
    { lat: 47.7400102, lng: 18.1912739 },
    { lat: 47.7420546, lng: 18.1913032 },
    { lat: 47.7420548, lng: 18.1913631 },
    { lat: 47.7437624, lng: 18.1914461 },
    { lat: 47.7437368, lng: 18.1913155 },
    { lat: 47.7443403, lng: 18.1913534 },
    { lat: 47.7453631, lng: 18.1915889 },
    { lat: 47.7453633, lng: 18.1916327 },
    { lat: 47.7457513, lng: 18.1915718 },
    { lat: 47.7460802, lng: 18.1914073 },
    { lat: 47.7479456, lng: 18.1911601 },
    { lat: 47.7490654, lng: 18.1912039 },
    { lat: 47.7501015, lng: 18.1906913 },
    { lat: 47.7504552, lng: 18.1921014 },
    { lat: 47.7507968, lng: 18.1937529 },
    { lat: 47.7519056, lng: 18.194455 },
    { lat: 47.7526142, lng: 18.1945508 },
    { lat: 47.753496, lng: 18.190387 },
    { lat: 47.7528778, lng: 18.1898496 },
    { lat: 47.7554689, lng: 18.1818013 },
    { lat: 47.7562151, lng: 18.1823231 },
    { lat: 47.758509, lng: 18.1843006 },
    { lat: 47.7596746, lng: 18.1854128 },
    { lat: 47.7602546, lng: 18.1858363 },
    { lat: 47.7614212, lng: 18.1863547 },
    { lat: 47.7631029, lng: 18.1865507 },
    { lat: 47.7660407, lng: 18.1866368 },
    { lat: 47.7669137, lng: 18.1867644 },
    { lat: 47.7674831, lng: 18.1869101 },
    { lat: 47.7681842, lng: 18.1873218 },
    { lat: 47.7715689, lng: 18.1886754 },
    { lat: 47.773018, lng: 18.1890646 },
    { lat: 47.7740658, lng: 18.1892658 },
    { lat: 47.775796, lng: 18.1898307 },
    { lat: 47.7792236, lng: 18.1905353 },
    { lat: 47.7816152, lng: 18.1914679 },
    { lat: 47.7817596, lng: 18.1918327 },
    { lat: 47.7819431, lng: 18.192096 },
    { lat: 47.7826427, lng: 18.1929214 },
    { lat: 47.7827779, lng: 18.1932825 },
    { lat: 47.7828215, lng: 18.1936603 },
    { lat: 47.7827708, lng: 18.1941261 },
    { lat: 47.7825105, lng: 18.1948813 },
    { lat: 47.7823554, lng: 18.1950673 },
    { lat: 47.781718, lng: 18.1952421 },
    { lat: 47.7804312, lng: 18.196256 },
    { lat: 47.7802773, lng: 18.197095 },
    { lat: 47.7802772, lng: 18.1973248 },
    { lat: 47.7804086, lng: 18.1980656 },
    { lat: 47.7808695, lng: 18.1991524 },
    { lat: 47.7810028, lng: 18.1997537 },
    { lat: 47.7816001, lng: 18.2009537 },
    { lat: 47.7816633, lng: 18.2015324 },
    { lat: 47.7816322, lng: 18.2018897 },
    { lat: 47.7813781, lng: 18.2025406 },
    { lat: 47.7813076, lng: 18.2028791 },
    { lat: 47.7813786, lng: 18.2033211 },
    { lat: 47.7814285, lng: 18.2043686 },
    { lat: 47.7816355, lng: 18.2050485 },
    { lat: 47.7815946, lng: 18.2057193 },
    { lat: 47.7817702, lng: 18.2057707 },
    { lat: 47.7813979, lng: 18.2078555 },
    { lat: 47.7815403, lng: 18.2079733 },
    { lat: 47.7897943, lng: 18.2147466 },
    { lat: 47.8012026, lng: 18.1841399 },
    { lat: 47.8005261, lng: 18.1837054 },
    { lat: 47.7998489, lng: 18.1831665 },
    { lat: 47.7974083, lng: 18.1817059 },
    { lat: 47.795094, lng: 18.1801915 },
    { lat: 47.7952775, lng: 18.1799527 },
    { lat: 47.7954046, lng: 18.1796206 },
    { lat: 47.7956041, lng: 18.1795392 },
    { lat: 47.7961928, lng: 18.1787987 },
    { lat: 47.8030816, lng: 18.1697887 },
    { lat: 47.8032624, lng: 18.1700808 },
    { lat: 47.8031796, lng: 18.1701891 },
    { lat: 47.8034054, lng: 18.1704778 },
    { lat: 47.8039155, lng: 18.1701527 },
    { lat: 47.8045801, lng: 18.169326 },
    { lat: 47.8045902, lng: 18.1682924 },
    { lat: 47.8044687, lng: 18.1684135 },
    { lat: 47.8043147, lng: 18.1681544 },
    { lat: 47.8041329, lng: 18.1676332 },
    { lat: 47.8041025, lng: 18.1670405 },
    { lat: 47.8043382, lng: 18.1664234 },
    { lat: 47.8045303, lng: 18.1657383 },
    { lat: 47.804944, lng: 18.1649434 },
    { lat: 47.8043817, lng: 18.1613326 },
    { lat: 47.8043786, lng: 18.1612435 },
    { lat: 47.8046893, lng: 18.1608662 },
    { lat: 47.804644, lng: 18.1606148 },
    { lat: 47.8045019, lng: 18.160422 },
    { lat: 47.8041538, lng: 18.1601642 },
    { lat: 47.803909, lng: 18.1598015 },
    { lat: 47.8038134, lng: 18.1594039 },
    { lat: 47.8039305, lng: 18.1584452 },
    { lat: 47.8039535, lng: 18.1577996 },
    { lat: 47.8040633, lng: 18.1573631 },
    { lat: 47.8041497, lng: 18.1574123 },
    { lat: 47.8041803, lng: 18.1572409 },
    { lat: 47.8041451, lng: 18.1568565 },
    { lat: 47.8043844, lng: 18.1563386 },
    { lat: 47.8047252, lng: 18.1551512 },
    { lat: 47.8049702, lng: 18.1547905 },
    { lat: 47.8051805, lng: 18.1543585 },
    { lat: 47.805426, lng: 18.154062 },
    { lat: 47.8062347, lng: 18.1527912 },
    { lat: 47.8068011, lng: 18.1517559 },
    { lat: 47.8069587, lng: 18.1512889 },
    { lat: 47.8072133, lng: 18.1509947 },
    { lat: 47.8099399, lng: 18.1515742 },
    { lat: 47.8115736, lng: 18.1483882 },
    { lat: 47.8126039, lng: 18.147014 },
    { lat: 47.8132097, lng: 18.1475461 },
    { lat: 47.8142535, lng: 18.1479837 },
    { lat: 47.8151212, lng: 18.1476767 },
    { lat: 47.816431, lng: 18.1466368 },
    { lat: 47.8172909, lng: 18.1455837 },
    { lat: 47.81858, lng: 18.1410221 },
    { lat: 47.8189244, lng: 18.1392491 },
    { lat: 47.8191961, lng: 18.1371032 },
    { lat: 47.8200997, lng: 18.1341746 },
    { lat: 47.8200779, lng: 18.1290174 },
    { lat: 47.8199591, lng: 18.1278079 },
    { lat: 47.8199971, lng: 18.1268279 },
    { lat: 47.8200975, lng: 18.1260669 },
    { lat: 47.820314, lng: 18.1256612 },
    { lat: 47.820456, lng: 18.1255072 },
    { lat: 47.8206097, lng: 18.1254706 },
    { lat: 47.820863, lng: 18.1255394 },
    { lat: 47.821686, lng: 18.1261525 },
    { lat: 47.8219396, lng: 18.1262051 },
    { lat: 47.8226597, lng: 18.126094 },
    { lat: 47.8236097, lng: 18.1256716 },
    { lat: 47.8244179, lng: 18.1249861 },
    { lat: 47.8247944, lng: 18.1245405 },
    { lat: 47.8250912, lng: 18.1238535 },
    { lat: 47.8254631, lng: 18.1225689 },
    { lat: 47.8258083, lng: 18.1203193 },
    { lat: 47.8260745, lng: 18.1193993 },
    { lat: 47.8262148, lng: 18.1191211 },
    { lat: 47.8264879, lng: 18.1187578 },
    { lat: 47.8268607, lng: 18.1185953 },
    { lat: 47.8270903, lng: 18.1186381 },
    { lat: 47.8272634, lng: 18.1188184 },
    { lat: 47.8273601, lng: 18.1190822 },
    { lat: 47.8273221, lng: 18.119617 },
    { lat: 47.8272403, lng: 18.1199736 },
    { lat: 47.8269978, lng: 18.1205836 },
    { lat: 47.8268434, lng: 18.1211583 },
    { lat: 47.8268552, lng: 18.1212441 },
    { lat: 47.8268627, lng: 18.1216755 },
    { lat: 47.8269636, lng: 18.122011 },
    { lat: 47.8270846, lng: 18.1223162 },
    { lat: 47.8273037, lng: 18.122683 },
    { lat: 47.8276831, lng: 18.1228389 },
    { lat: 47.8281367, lng: 18.1228201 },
    { lat: 47.8288846, lng: 18.1226786 },
    { lat: 47.8299855, lng: 18.122289 },
    { lat: 47.830423, lng: 18.122037 },
    { lat: 47.8309279, lng: 18.1215952 },
    { lat: 47.8313245, lng: 18.1211962 },
    { lat: 47.8315159, lng: 18.1208608 },
    { lat: 47.8316609, lng: 18.12095 },
    { lat: 47.8318434, lng: 18.1208647 },
    { lat: 47.832407, lng: 18.1207989 },
    { lat: 47.8337778, lng: 18.1209444 },
    { lat: 47.8344662, lng: 18.121232 },
    { lat: 47.8351543, lng: 18.1217031 },
    { lat: 47.8356996, lng: 18.1218223 },
    { lat: 47.8363539, lng: 18.1217582 },
    { lat: 47.836609, lng: 18.1216301 },
    { lat: 47.8369767, lng: 18.1213022 },
    { lat: 47.8370796, lng: 18.121321 },
    { lat: 47.838394, lng: 18.1222895 },
    { lat: 47.8389631, lng: 18.1222194 },
    { lat: 47.8393286, lng: 18.122158 },
    { lat: 47.8397935, lng: 18.1218901 },
    { lat: 47.8413005, lng: 18.1208499 },
    { lat: 47.8420668, lng: 18.1205651 },
    { lat: 47.8424363, lng: 18.120363 },
    { lat: 47.8429756, lng: 18.1199051 },
    { lat: 47.8430336, lng: 18.1196425 },
    { lat: 47.8428071, lng: 18.1189084 },
    { lat: 47.8417412, lng: 18.1173331 },
    { lat: 47.8416804, lng: 18.1171261 },
    { lat: 47.8417654, lng: 18.1167321 },
    { lat: 47.841958, lng: 18.116651 },
    { lat: 47.8421988, lng: 18.1166948 },
    { lat: 47.8425434, lng: 18.1169114 },
    { lat: 47.8428858, lng: 18.1169952 },
    { lat: 47.8433007, lng: 18.116933 },
    { lat: 47.8437268, lng: 18.1167125 },
    { lat: 47.8445696, lng: 18.1166523 },
    { lat: 47.8449679, lng: 18.1168061 },
    { lat: 47.8452925, lng: 18.1166439 },
    { lat: 47.8454539, lng: 18.1164548 },
    { lat: 47.8458468, lng: 18.1156229 },
    { lat: 47.8461547, lng: 18.1152749 },
    { lat: 47.8465969, lng: 18.1150794 },
    { lat: 47.8468273, lng: 18.1146253 },
    { lat: 47.8469352, lng: 18.1141239 },
    { lat: 47.8469081, lng: 18.1135348 },
    { lat: 47.8465836, lng: 18.1125922 },
    { lat: 47.8465456, lng: 18.1121215 },
    { lat: 47.846607, lng: 18.1106802 },
    { lat: 47.8467727, lng: 18.1092515 },
    { lat: 47.8470701, lng: 18.1081007 },
    { lat: 47.8473766, lng: 18.1071402 },
    { lat: 47.8477027, lng: 18.106625 },
    { lat: 47.8476985, lng: 18.1062521 },
    { lat: 47.8478018, lng: 18.1059565 },
    { lat: 47.8480568, lng: 18.1054429 },
    { lat: 47.8485825, lng: 18.1046584 },
    { lat: 47.8487551, lng: 18.1041225 },
    { lat: 47.8487317, lng: 18.1039208 },
    { lat: 47.848784, lng: 18.1036423 },
    { lat: 47.8485045, lng: 18.1030606 },
    { lat: 47.8479644, lng: 18.10263 },
    { lat: 47.847844, lng: 18.1023862 },
    { lat: 47.8477329, lng: 18.1017701 },
    { lat: 47.848052, lng: 18.0999784 },
    { lat: 47.847975, lng: 18.0996899 },
    { lat: 47.8477487, lng: 18.0993187 },
    { lat: 47.8477306, lng: 18.0991268 },
    { lat: 47.847834, lng: 18.0983787 },
    { lat: 47.8477913, lng: 18.0981369 },
    { lat: 47.8476898, lng: 18.0979654 },
    { lat: 47.8474517, lng: 18.0978705 },
    { lat: 47.8473222, lng: 18.0979152 },
    { lat: 47.8473283, lng: 18.097847 },
    { lat: 47.8472297, lng: 18.0977988 },
  ],
  DedinaMládeže: [
    { lat: 47.955956, lng: 18.015642 },
    { lat: 47.957089, lng: 18.0182288 },
    { lat: 47.957489, lng: 18.0187432 },
    { lat: 47.9577527, lng: 18.0189237 },
    { lat: 47.9584342, lng: 18.0190165 },
    { lat: 47.9588651, lng: 18.0189927 },
    { lat: 47.9591141, lng: 18.0189082 },
    { lat: 47.9600779, lng: 18.0183054 },
    { lat: 47.9605589, lng: 18.01797 },
    { lat: 47.9620549, lng: 18.0155544 },
    { lat: 47.9655809, lng: 18.0108711 },
    { lat: 47.9665588, lng: 18.0098453 },
    { lat: 47.9677423, lng: 18.0087454 },
    { lat: 47.9709259, lng: 18.0066386 },
    { lat: 47.97075, lng: 18.005925 },
    { lat: 47.970476, lng: 18.004932 },
    { lat: 47.970454, lng: 18.004853 },
    { lat: 47.970851, lng: 18.003289 },
    { lat: 47.970972, lng: 18.0028 },
    { lat: 47.971076, lng: 18.002383 },
    { lat: 47.971141, lng: 18.002135 },
    { lat: 47.97128, lng: 18.001658 },
    { lat: 47.971727, lng: 18.000682 },
    { lat: 47.971964, lng: 18.000315 },
    { lat: 47.972399, lng: 17.999735 },
    { lat: 47.972622, lng: 17.999082 },
    { lat: 47.972619, lng: 17.99904 },
    { lat: 47.972605, lng: 17.998719 },
    { lat: 47.972605, lng: 17.998653 },
    { lat: 47.972604, lng: 17.998592 },
    { lat: 47.972571, lng: 17.998427 },
    { lat: 47.972468, lng: 17.998014 },
    { lat: 47.97246, lng: 17.997982 },
    { lat: 47.972432, lng: 17.997853 },
    { lat: 47.972392, lng: 17.99767 },
    { lat: 47.972343, lng: 17.997449 },
    { lat: 47.972304, lng: 17.997274 },
    { lat: 47.972255, lng: 17.997048 },
    { lat: 47.972171, lng: 17.996669 },
    { lat: 47.97217, lng: 17.996664 },
    { lat: 47.972151, lng: 17.996575 },
    { lat: 47.971865, lng: 17.9961 },
    { lat: 47.971849, lng: 17.996074 },
    { lat: 47.971842, lng: 17.996061 },
    { lat: 47.971675, lng: 17.995734 },
    { lat: 47.971666, lng: 17.995715 },
    { lat: 47.971584, lng: 17.995559 },
    { lat: 47.971375, lng: 17.99516 },
    { lat: 47.971363, lng: 17.995137 },
    { lat: 47.971048, lng: 17.994516 },
    { lat: 47.971034, lng: 17.994471 },
    { lat: 47.971015, lng: 17.994418 },
    { lat: 47.970686, lng: 17.993426 },
    { lat: 47.970621, lng: 17.993234 },
    { lat: 47.970536, lng: 17.992976 },
    { lat: 47.970319, lng: 17.992328 },
    { lat: 47.970271, lng: 17.992186 },
    { lat: 47.970261, lng: 17.992133 },
    { lat: 47.970265, lng: 17.992093 },
    { lat: 47.970373, lng: 17.990664 },
    { lat: 47.970422, lng: 17.990194 },
    { lat: 47.97046, lng: 17.989839 },
    { lat: 47.970469, lng: 17.989748 },
    { lat: 47.970568, lng: 17.988783 },
    { lat: 47.970569, lng: 17.988779 },
    { lat: 47.970635, lng: 17.988464 },
    { lat: 47.970648, lng: 17.988407 },
    { lat: 47.970706, lng: 17.988208 },
    { lat: 47.970944, lng: 17.987383 },
    { lat: 47.970991, lng: 17.987253 },
    { lat: 47.970985, lng: 17.987229 },
    { lat: 47.970715, lng: 17.986642 },
    { lat: 47.970715, lng: 17.986641 },
    { lat: 47.970649, lng: 17.986503 },
    { lat: 47.970623, lng: 17.98645 },
    { lat: 47.970589, lng: 17.986376 },
    { lat: 47.970434, lng: 17.98605 },
    { lat: 47.970433, lng: 17.986049 },
    { lat: 47.9704, lng: 17.985981 },
    { lat: 47.970344, lng: 17.985866 },
    { lat: 47.970326, lng: 17.985817 },
    { lat: 47.970273, lng: 17.985665 },
    { lat: 47.970245, lng: 17.985588 },
    { lat: 47.970243, lng: 17.985582 },
    { lat: 47.970207, lng: 17.985478 },
    { lat: 47.970205, lng: 17.985473 },
    { lat: 47.970196, lng: 17.985449 },
    { lat: 47.970191, lng: 17.985434 },
    { lat: 47.970177, lng: 17.985395 },
    { lat: 47.970139, lng: 17.985282 },
    { lat: 47.969974, lng: 17.984808 },
    { lat: 47.969659, lng: 17.983903 },
    { lat: 47.969314, lng: 17.982851 },
    { lat: 47.969166, lng: 17.982328 },
    { lat: 47.969156, lng: 17.98229 },
    { lat: 47.969036, lng: 17.981864 },
    { lat: 47.969014, lng: 17.981785 },
    { lat: 47.969013, lng: 17.98178 },
    { lat: 47.968774, lng: 17.980909 },
    { lat: 47.96872, lng: 17.980704 },
    { lat: 47.968439, lng: 17.979717 },
    { lat: 47.968378, lng: 17.979508 },
    { lat: 47.968156, lng: 17.978749 },
    { lat: 47.96803, lng: 17.978306 },
    { lat: 47.967998, lng: 17.978195 },
    { lat: 47.96772, lng: 17.977214 },
    { lat: 47.967616, lng: 17.976867 },
    { lat: 47.967594, lng: 17.976794 },
    { lat: 47.967589, lng: 17.976774 },
    { lat: 47.967348, lng: 17.975969 },
    { lat: 47.967343, lng: 17.975951 },
    { lat: 47.967296, lng: 17.975915 },
    { lat: 47.967295, lng: 17.975914 },
    { lat: 47.967237, lng: 17.975854 },
    { lat: 47.967236, lng: 17.975853 },
    { lat: 47.967135, lng: 17.975753 },
    { lat: 47.966964, lng: 17.97558 },
    { lat: 47.966691, lng: 17.975305 },
    { lat: 47.966219, lng: 17.974832 },
    { lat: 47.966041, lng: 17.974653 },
    { lat: 47.965988, lng: 17.974582 },
    { lat: 47.965713, lng: 17.974209 },
    { lat: 47.965581, lng: 17.974033 },
    { lat: 47.965511, lng: 17.973937 },
    { lat: 47.965507, lng: 17.973933 },
    { lat: 47.965435, lng: 17.973836 },
    { lat: 47.965406, lng: 17.973798 },
    { lat: 47.965342, lng: 17.973712 },
    { lat: 47.965325, lng: 17.973689 },
    { lat: 47.965304, lng: 17.973661 },
    { lat: 47.965068, lng: 17.973345 },
    { lat: 47.964721, lng: 17.972912 },
    { lat: 47.96392, lng: 17.97188 },
    { lat: 47.963619, lng: 17.971429 },
    { lat: 47.962941, lng: 17.970447 },
    { lat: 47.962957, lng: 17.970395 },
    { lat: 47.962969, lng: 17.970356 },
    { lat: 47.962974, lng: 17.970338 },
    { lat: 47.962988, lng: 17.97029 },
    { lat: 47.963021, lng: 17.970177 },
    { lat: 47.963033, lng: 17.970136 },
    { lat: 47.963035, lng: 17.970127 },
    { lat: 47.963149, lng: 17.969723 },
    { lat: 47.963436, lng: 17.968883 },
    { lat: 47.963717, lng: 17.968062 },
    { lat: 47.963791, lng: 17.967823 },
    { lat: 47.963811, lng: 17.967761 },
    { lat: 47.963852, lng: 17.967627 },
    { lat: 47.963903, lng: 17.967464 },
    { lat: 47.963945, lng: 17.96733 },
    { lat: 47.96398, lng: 17.96722 },
    { lat: 47.964071, lng: 17.966928 },
    { lat: 47.964147, lng: 17.96668 },
    { lat: 47.964196, lng: 17.966524 },
    { lat: 47.964204, lng: 17.966501 },
    { lat: 47.964214, lng: 17.966469 },
    { lat: 47.964248, lng: 17.966359 },
    { lat: 47.96426, lng: 17.966322 },
    { lat: 47.964262, lng: 17.966315 },
    { lat: 47.964264, lng: 17.966308 },
    { lat: 47.964277, lng: 17.966266 },
    { lat: 47.964776, lng: 17.964673 },
    { lat: 47.965495, lng: 17.962154 },
    { lat: 47.9653713, lng: 17.9620407 },
    { lat: 47.9650249, lng: 17.9625456 },
    { lat: 47.9646172, lng: 17.9629348 },
    { lat: 47.9641807, lng: 17.9632177 },
    { lat: 47.9638738, lng: 17.9633342 },
    { lat: 47.9635167, lng: 17.9634396 },
    { lat: 47.9472864, lng: 17.9672227 },
    { lat: 47.9469983, lng: 17.9672373 },
    { lat: 47.9462979, lng: 17.9670568 },
    { lat: 47.9446717, lng: 17.9657566 },
    { lat: 47.9444127, lng: 17.9656157 },
    { lat: 47.9443729, lng: 17.9658917 },
    { lat: 47.9442809, lng: 17.9661064 },
    { lat: 47.9441679, lng: 17.966104 },
    { lat: 47.9441517, lng: 17.9660048 },
    { lat: 47.9430069, lng: 17.9656244 },
    { lat: 47.9421241, lng: 17.9656617 },
    { lat: 47.9420597, lng: 17.9676567 },
    { lat: 47.9421948, lng: 17.9677471 },
    { lat: 47.9421493, lng: 17.9679644 },
    { lat: 47.942015, lng: 17.9678908 },
    { lat: 47.9419866, lng: 17.9679421 },
    { lat: 47.9413202, lng: 17.9698228 },
    { lat: 47.9408557, lng: 17.9707266 },
    { lat: 47.9398513, lng: 17.9723725 },
    { lat: 47.9385918, lng: 17.9747839 },
    { lat: 47.9378653, lng: 17.9759342 },
    { lat: 47.9372556, lng: 17.9766795 },
    { lat: 47.936228, lng: 17.9776793 },
    { lat: 47.9353156, lng: 17.9784415 },
    { lat: 47.9349741, lng: 17.9790427 },
    { lat: 47.9344224, lng: 17.9793872 },
    { lat: 47.933651, lng: 17.9802904 },
    { lat: 47.9326062, lng: 17.9814611 },
    { lat: 47.9321876, lng: 17.9820422 },
    { lat: 47.9316418, lng: 17.9821256 },
    { lat: 47.9306291, lng: 17.9818241 },
    { lat: 47.9302375, lng: 17.9823086 },
    { lat: 47.9304697, lng: 17.9826605 },
    { lat: 47.9304096, lng: 17.9827857 },
    { lat: 47.931438, lng: 17.9841744 },
    { lat: 47.9315074, lng: 17.9841278 },
    { lat: 47.9316325, lng: 17.9843131 },
    { lat: 47.9316034, lng: 17.984406 },
    { lat: 47.9323362, lng: 17.9854787 },
    { lat: 47.9311803, lng: 17.9878749 },
    { lat: 47.9301259, lng: 17.9908694 },
    { lat: 47.9300661, lng: 17.9911871 },
    { lat: 47.9300665, lng: 17.9917353 },
    { lat: 47.9301807, lng: 17.9923004 },
    { lat: 47.9330926, lng: 17.9966875 },
    { lat: 47.9334568, lng: 17.9968115 },
    { lat: 47.9357863, lng: 17.9969972 },
    { lat: 47.9363125, lng: 17.9972843 },
    { lat: 47.9366979, lng: 17.9976081 },
    { lat: 47.9370986, lng: 17.9980961 },
    { lat: 47.937275, lng: 17.9984016 },
    { lat: 47.9398321, lng: 18.0045311 },
    { lat: 47.9406902, lng: 18.0059241 },
    { lat: 47.9414236, lng: 18.0067314 },
    { lat: 47.9420636, lng: 18.0071636 },
    { lat: 47.9429023, lng: 18.0076332 },
    { lat: 47.9435233, lng: 18.0078237 },
    { lat: 47.942938, lng: 18.0086204 },
    { lat: 47.9424224, lng: 18.0092064 },
    { lat: 47.942483, lng: 18.011501 },
    { lat: 47.943052, lng: 18.012139 },
    { lat: 47.943339, lng: 18.012392 },
    { lat: 47.944083, lng: 18.012947 },
    { lat: 47.944469, lng: 18.013149 },
    { lat: 47.945238, lng: 18.013312 },
    { lat: 47.945608, lng: 18.01331 },
    { lat: 47.946035, lng: 18.013352 },
    { lat: 47.946138, lng: 18.013331 },
    { lat: 47.946336, lng: 18.013198 },
    { lat: 47.946711, lng: 18.012992 },
    { lat: 47.946918, lng: 18.012947 },
    { lat: 47.947439, lng: 18.01275 },
    { lat: 47.947836, lng: 18.012608 },
    { lat: 47.947956, lng: 18.012605 },
    { lat: 47.948236, lng: 18.012487 },
    { lat: 47.948461, lng: 18.012337 },
    { lat: 47.948693, lng: 18.012168 },
    { lat: 47.948782, lng: 18.012145 },
    { lat: 47.94897, lng: 18.012036 },
    { lat: 47.949375, lng: 18.011861 },
    { lat: 47.949442, lng: 18.011849 },
    { lat: 47.949492, lng: 18.01181 },
    { lat: 47.949561, lng: 18.011775 },
    { lat: 47.949677, lng: 18.011766 },
    { lat: 47.949731, lng: 18.011732 },
    { lat: 47.949833, lng: 18.011692 },
    { lat: 47.949936, lng: 18.011674 },
    { lat: 47.950058, lng: 18.011644 },
    { lat: 47.950114, lng: 18.011613 },
    { lat: 47.950329, lng: 18.011579 },
    { lat: 47.950443, lng: 18.011568 },
    { lat: 47.950565, lng: 18.011545 },
    { lat: 47.95065, lng: 18.011518 },
    { lat: 47.950732, lng: 18.01151 },
    { lat: 47.950846, lng: 18.011485 },
    { lat: 47.950994, lng: 18.01147 },
    { lat: 47.95113, lng: 18.011444 },
    { lat: 47.951302, lng: 18.011435 },
    { lat: 47.951736, lng: 18.011371 },
    { lat: 47.952227, lng: 18.011344 },
    { lat: 47.952405, lng: 18.011321 },
    { lat: 47.952652, lng: 18.011333 },
    { lat: 47.952736, lng: 18.011363 },
    { lat: 47.95293, lng: 18.011387 },
    { lat: 47.953093, lng: 18.011454 },
    { lat: 47.95317, lng: 18.011495 },
    { lat: 47.953241, lng: 18.011518 },
    { lat: 47.953314, lng: 18.011571 },
    { lat: 47.953487, lng: 18.011661 },
    { lat: 47.953606, lng: 18.011744 },
    { lat: 47.953684, lng: 18.011775 },
    { lat: 47.953916, lng: 18.011961 },
    { lat: 47.95412, lng: 18.012151 },
    { lat: 47.954273, lng: 18.012323 },
    { lat: 47.954417, lng: 18.012515 },
    { lat: 47.954626, lng: 18.012818 },
    { lat: 47.954808, lng: 18.013123 },
    { lat: 47.955039, lng: 18.013632 },
    { lat: 47.955308, lng: 18.014251 },
    { lat: 47.955771, lng: 18.015235 },
    { lat: 47.955956, lng: 18.015642 },
  ],
  Martovce: [
    { lat: 47.8472297, lng: 18.0977988 },
    { lat: 47.8473283, lng: 18.097847 },
    { lat: 47.8473222, lng: 18.0979152 },
    { lat: 47.8474517, lng: 18.0978705 },
    { lat: 47.8476898, lng: 18.0979654 },
    { lat: 47.8477913, lng: 18.0981369 },
    { lat: 47.847834, lng: 18.0983787 },
    { lat: 47.8477306, lng: 18.0991268 },
    { lat: 47.8477487, lng: 18.0993187 },
    { lat: 47.847975, lng: 18.0996899 },
    { lat: 47.848052, lng: 18.0999784 },
    { lat: 47.8477329, lng: 18.1017701 },
    { lat: 47.847844, lng: 18.1023862 },
    { lat: 47.8479644, lng: 18.10263 },
    { lat: 47.8485045, lng: 18.1030606 },
    { lat: 47.848784, lng: 18.1036423 },
    { lat: 47.8487317, lng: 18.1039208 },
    { lat: 47.8487551, lng: 18.1041225 },
    { lat: 47.8485825, lng: 18.1046584 },
    { lat: 47.8480568, lng: 18.1054429 },
    { lat: 47.8478018, lng: 18.1059565 },
    { lat: 47.8476985, lng: 18.1062521 },
    { lat: 47.8477027, lng: 18.106625 },
    { lat: 47.8473766, lng: 18.1071402 },
    { lat: 47.8470701, lng: 18.1081007 },
    { lat: 47.8467727, lng: 18.1092515 },
    { lat: 47.846607, lng: 18.1106802 },
    { lat: 47.8465456, lng: 18.1121215 },
    { lat: 47.8465836, lng: 18.1125922 },
    { lat: 47.8469081, lng: 18.1135348 },
    { lat: 47.8469352, lng: 18.1141239 },
    { lat: 47.8468273, lng: 18.1146253 },
    { lat: 47.8465969, lng: 18.1150794 },
    { lat: 47.8461547, lng: 18.1152749 },
    { lat: 47.8458468, lng: 18.1156229 },
    { lat: 47.8454539, lng: 18.1164548 },
    { lat: 47.8452925, lng: 18.1166439 },
    { lat: 47.8449679, lng: 18.1168061 },
    { lat: 47.8445696, lng: 18.1166523 },
    { lat: 47.8437268, lng: 18.1167125 },
    { lat: 47.8433007, lng: 18.116933 },
    { lat: 47.8428858, lng: 18.1169952 },
    { lat: 47.8425434, lng: 18.1169114 },
    { lat: 47.8421988, lng: 18.1166948 },
    { lat: 47.841958, lng: 18.116651 },
    { lat: 47.8417654, lng: 18.1167321 },
    { lat: 47.8416804, lng: 18.1171261 },
    { lat: 47.8417412, lng: 18.1173331 },
    { lat: 47.8428071, lng: 18.1189084 },
    { lat: 47.8430336, lng: 18.1196425 },
    { lat: 47.8429756, lng: 18.1199051 },
    { lat: 47.8424363, lng: 18.120363 },
    { lat: 47.8420668, lng: 18.1205651 },
    { lat: 47.8413005, lng: 18.1208499 },
    { lat: 47.8397935, lng: 18.1218901 },
    { lat: 47.8393286, lng: 18.122158 },
    { lat: 47.8389631, lng: 18.1222194 },
    { lat: 47.838394, lng: 18.1222895 },
    { lat: 47.8385751, lng: 18.1245866 },
    { lat: 47.8401579, lng: 18.1251309 },
    { lat: 47.8409636, lng: 18.1247876 },
    { lat: 47.841458, lng: 18.1247502 },
    { lat: 47.8426784, lng: 18.125925 },
    { lat: 47.8436776, lng: 18.1272868 },
    { lat: 47.8440612, lng: 18.1286984 },
    { lat: 47.8441923, lng: 18.1295799 },
    { lat: 47.8442853, lng: 18.1298527 },
    { lat: 47.8444567, lng: 18.1300045 },
    { lat: 47.8448507, lng: 18.1298727 },
    { lat: 47.8451272, lng: 18.1298939 },
    { lat: 47.8453821, lng: 18.1300416 },
    { lat: 47.8456425, lng: 18.1302858 },
    { lat: 47.8459869, lng: 18.1308444 },
    { lat: 47.846232, lng: 18.1316306 },
    { lat: 47.8462796, lng: 18.1319395 },
    { lat: 47.846131, lng: 18.1335516 },
    { lat: 47.8458571, lng: 18.1344153 },
    { lat: 47.8458435, lng: 18.1348731 },
    { lat: 47.845892, lng: 18.1350814 },
    { lat: 47.8462431, lng: 18.1355619 },
    { lat: 47.8461895, lng: 18.1356862 },
    { lat: 47.8474524, lng: 18.1393423 },
    { lat: 47.848185, lng: 18.1408162 },
    { lat: 47.8488693, lng: 18.1416657 },
    { lat: 47.849816, lng: 18.1419517 },
    { lat: 47.8499798, lng: 18.1417056 },
    { lat: 47.8502695, lng: 18.1416411 },
    { lat: 47.8504808, lng: 18.1417043 },
    { lat: 47.8512867, lng: 18.142166 },
    { lat: 47.8516687, lng: 18.1421742 },
    { lat: 47.8520719, lng: 18.1420469 },
    { lat: 47.8528514, lng: 18.1416401 },
    { lat: 47.853596, lng: 18.1414458 },
    { lat: 47.853957, lng: 18.1414796 },
    { lat: 47.8542038, lng: 18.1415715 },
    { lat: 47.8546993, lng: 18.1420132 },
    { lat: 47.8557674, lng: 18.1437855 },
    { lat: 47.8561412, lng: 18.1441528 },
    { lat: 47.8563836, lng: 18.1442453 },
    { lat: 47.8567528, lng: 18.1441133 },
    { lat: 47.8587871, lng: 18.1427855 },
    { lat: 47.8590566, lng: 18.1425357 },
    { lat: 47.8591459, lng: 18.142249 },
    { lat: 47.8591469, lng: 18.1420377 },
    { lat: 47.8588018, lng: 18.1407379 },
    { lat: 47.8588073, lng: 18.1405257 },
    { lat: 47.8588808, lng: 18.1402413 },
    { lat: 47.8593035, lng: 18.1400218 },
    { lat: 47.8596195, lng: 18.1400242 },
    { lat: 47.8598213, lng: 18.1398841 },
    { lat: 47.8600259, lng: 18.1396459 },
    { lat: 47.8604257, lng: 18.1394965 },
    { lat: 47.8620064, lng: 18.1385561 },
    { lat: 47.8624181, lng: 18.1384557 },
    { lat: 47.8626269, lng: 18.1385121 },
    { lat: 47.8627677, lng: 18.1386045 },
    { lat: 47.8629994, lng: 18.1389735 },
    { lat: 47.8632055, lng: 18.1405902 },
    { lat: 47.8634901, lng: 18.1414383 },
    { lat: 47.8640295, lng: 18.1424683 },
    { lat: 47.8645215, lng: 18.1427394 },
    { lat: 47.8649405, lng: 18.1431076 },
    { lat: 47.8651484, lng: 18.1435701 },
    { lat: 47.8653691, lng: 18.1445981 },
    { lat: 47.8655157, lng: 18.145629 },
    { lat: 47.865267, lng: 18.1458301 },
    { lat: 47.8649606, lng: 18.1458397 },
    { lat: 47.8646394, lng: 18.1457508 },
    { lat: 47.8643771, lng: 18.1455534 },
    { lat: 47.8640063, lng: 18.1454642 },
    { lat: 47.8639236, lng: 18.1455186 },
    { lat: 47.8638382, lng: 18.1456372 },
    { lat: 47.8637355, lng: 18.1465393 },
    { lat: 47.863788, lng: 18.1469686 },
    { lat: 47.8638401, lng: 18.1471259 },
    { lat: 47.8641205, lng: 18.1471734 },
    { lat: 47.8644573, lng: 18.1468576 },
    { lat: 47.8645128, lng: 18.146552 },
    { lat: 47.8646657, lng: 18.146465 },
    { lat: 47.8650651, lng: 18.146581 },
    { lat: 47.8652047, lng: 18.1466868 },
    { lat: 47.8654252, lng: 18.1472148 },
    { lat: 47.8653193, lng: 18.1479061 },
    { lat: 47.8653715, lng: 18.1484862 },
    { lat: 47.8655131, lng: 18.148706 },
    { lat: 47.8656877, lng: 18.1487971 },
    { lat: 47.8657816, lng: 18.1487814 },
    { lat: 47.8657827, lng: 18.1483785 },
    { lat: 47.8658631, lng: 18.1483811 },
    { lat: 47.8678956, lng: 18.1471382 },
    { lat: 47.8682517, lng: 18.1469366 },
    { lat: 47.8704259, lng: 18.145706 },
    { lat: 47.8716593, lng: 18.1448073 },
    { lat: 47.8745681, lng: 18.1438785 },
    { lat: 47.8772982, lng: 18.1437915 },
    { lat: 47.8824844, lng: 18.1433641 },
    { lat: 47.8828106, lng: 18.1434221 },
    { lat: 47.8851796, lng: 18.1434088 },
    { lat: 47.8853852, lng: 18.1433753 },
    { lat: 47.8869411, lng: 18.1427363 },
    { lat: 47.8869543, lng: 18.1427315 },
    { lat: 47.8863637, lng: 18.1392246 },
    { lat: 47.8860297, lng: 18.137614 },
    { lat: 47.8860569, lng: 18.1374663 },
    { lat: 47.8845995, lng: 18.1323145 },
    { lat: 47.8842821, lng: 18.1305307 },
    { lat: 47.8834202, lng: 18.1269456 },
    { lat: 47.8828552, lng: 18.1220848 },
    { lat: 47.8773584, lng: 18.1113116 },
    { lat: 47.8764922, lng: 18.1090365 },
    { lat: 47.8746706, lng: 18.1051115 },
    { lat: 47.8745504, lng: 18.1018296 },
    { lat: 47.8743793, lng: 18.1000696 },
    { lat: 47.8746056, lng: 18.0995278 },
    { lat: 47.874628, lng: 18.0992197 },
    { lat: 47.8746633, lng: 18.0992453 },
    { lat: 47.8748053, lng: 18.0988239 },
    { lat: 47.8751172, lng: 18.0983738 },
    { lat: 47.8752797, lng: 18.0979163 },
    { lat: 47.8753439, lng: 18.0974752 },
    { lat: 47.8753607, lng: 18.0965841 },
    { lat: 47.875188, lng: 18.0958784 },
    { lat: 47.8748018, lng: 18.0952092 },
    { lat: 47.8745553, lng: 18.0943076 },
    { lat: 47.8744484, lng: 18.0938018 },
    { lat: 47.874375, lng: 18.0927175 },
    { lat: 47.8740907, lng: 18.0910441 },
    { lat: 47.8735098, lng: 18.0886044 },
    { lat: 47.8735355, lng: 18.0879701 },
    { lat: 47.8736144, lng: 18.0874768 },
    { lat: 47.8739287, lng: 18.0860246 },
    { lat: 47.8744386, lng: 18.0846525 },
    { lat: 47.8749928, lng: 18.0838805 },
    { lat: 47.8754035, lng: 18.0835928 },
    { lat: 47.8756426, lng: 18.0835884 },
    { lat: 47.8758332, lng: 18.0837188 },
    { lat: 47.8759461, lng: 18.0839669 },
    { lat: 47.8761423, lng: 18.085294 },
    { lat: 47.8762412, lng: 18.0856445 },
    { lat: 47.8765822, lng: 18.0863727 },
    { lat: 47.8767641, lng: 18.0865087 },
    { lat: 47.8771482, lng: 18.086588 },
    { lat: 47.8778288, lng: 18.0865928 },
    { lat: 47.8785088, lng: 18.0862405 },
    { lat: 47.8786457, lng: 18.0861114 },
    { lat: 47.8792249, lng: 18.0859292 },
    { lat: 47.879378, lng: 18.0859235 },
    { lat: 47.8799119, lng: 18.0861676 },
    { lat: 47.880074, lng: 18.0861263 },
    { lat: 47.8802851, lng: 18.0858781 },
    { lat: 47.8804409, lng: 18.085388 },
    { lat: 47.8804759, lng: 18.0842117 },
    { lat: 47.8805758, lng: 18.0830241 },
    { lat: 47.8803801, lng: 18.0824863 },
    { lat: 47.8797409, lng: 18.0818711 },
    { lat: 47.8794496, lng: 18.0817361 },
    { lat: 47.878265, lng: 18.0815326 },
    { lat: 47.8775382, lng: 18.0811933 },
    { lat: 47.8771689, lng: 18.080866 },
    { lat: 47.8770512, lng: 18.080652 },
    { lat: 47.8770176, lng: 18.0804497 },
    { lat: 47.8770911, lng: 18.0802428 },
    { lat: 47.8778464, lng: 18.0792042 },
    { lat: 47.8784428, lng: 18.0779809 },
    { lat: 47.8786907, lng: 18.0777468 },
    { lat: 47.8790413, lng: 18.0774954 },
    { lat: 47.8795252, lng: 18.0775664 },
    { lat: 47.8797702, lng: 18.0776976 },
    { lat: 47.8799991, lng: 18.077678 },
    { lat: 47.8804084, lng: 18.0774069 },
    { lat: 47.8806035, lng: 18.0771556 },
    { lat: 47.8807661, lng: 18.0766863 },
    { lat: 47.8810545, lng: 18.0746107 },
    { lat: 47.8812472, lng: 18.073999 },
    { lat: 47.8816037, lng: 18.0732377 },
    { lat: 47.8815044, lng: 18.0730124 },
    { lat: 47.8813392, lng: 18.0728162 },
    { lat: 47.8807453, lng: 18.0722436 },
    { lat: 47.8805529, lng: 18.071967 },
    { lat: 47.8801035, lng: 18.0711135 },
    { lat: 47.8801782, lng: 18.0701682 },
    { lat: 47.8801183, lng: 18.0696393 },
    { lat: 47.8793998, lng: 18.0683773 },
    { lat: 47.8791267, lng: 18.0676012 },
    { lat: 47.8790289, lng: 18.067155 },
    { lat: 47.878816, lng: 18.0651754 },
    { lat: 47.8788903, lng: 18.0646006 },
    { lat: 47.879078, lng: 18.0640201 },
    { lat: 47.8796603, lng: 18.0634143 },
    { lat: 47.8797873, lng: 18.0631057 },
    { lat: 47.8797568, lng: 18.0625947 },
    { lat: 47.8794053, lng: 18.0614873 },
    { lat: 47.8793262, lng: 18.061201 },
    { lat: 47.8680063, lng: 18.0684744 },
    { lat: 47.8537356, lng: 18.0776287 },
    { lat: 47.8537005, lng: 18.0776066 },
    { lat: 47.8539642, lng: 18.0765389 },
    { lat: 47.8537834, lng: 18.0764615 },
    { lat: 47.8534366, lng: 18.0767021 },
    { lat: 47.8529122, lng: 18.0768048 },
    { lat: 47.8525316, lng: 18.0767037 },
    { lat: 47.85175, lng: 18.0766558 },
    { lat: 47.8514501, lng: 18.0768495 },
    { lat: 47.8513269, lng: 18.0771675 },
    { lat: 47.8505153, lng: 18.0775257 },
    { lat: 47.8488397, lng: 18.0780443 },
    { lat: 47.848346, lng: 18.0783941 },
    { lat: 47.8473568, lng: 18.0802779 },
    { lat: 47.8469985, lng: 18.0812335 },
    { lat: 47.8464738, lng: 18.0835322 },
    { lat: 47.8463714, lng: 18.0841625 },
    { lat: 47.8463297, lng: 18.0847146 },
    { lat: 47.8463495, lng: 18.0850071 },
    { lat: 47.8467337, lng: 18.0866834 },
    { lat: 47.8469815, lng: 18.0872077 },
    { lat: 47.8473676, lng: 18.0876264 },
    { lat: 47.8479034, lng: 18.087868 },
    { lat: 47.8484266, lng: 18.0880136 },
    { lat: 47.8488554, lng: 18.0882795 },
    { lat: 47.8492068, lng: 18.0887966 },
    { lat: 47.8495253, lng: 18.0895997 },
    { lat: 47.8496147, lng: 18.0900409 },
    { lat: 47.8496809, lng: 18.0905451 },
    { lat: 47.8496656, lng: 18.0910871 },
    { lat: 47.8494911, lng: 18.0924043 },
    { lat: 47.8494074, lng: 18.0941835 },
    { lat: 47.8492668, lng: 18.0947072 },
    { lat: 47.8490169, lng: 18.0949075 },
    { lat: 47.8486767, lng: 18.0953343 },
    { lat: 47.8479747, lng: 18.0963953 },
    { lat: 47.8472377, lng: 18.097783 },
    { lat: 47.8472297, lng: 18.0977988 },
  ],
  ZlatnánaOstrove: [
    { lat: 47.8133268, lng: 17.9578035 },
    { lat: 47.812703, lng: 17.957007 },
    { lat: 47.8119298, lng: 17.9558523 },
    { lat: 47.8112556, lng: 17.9555499 },
    { lat: 47.8109889, lng: 17.9555084 },
    { lat: 47.8096187, lng: 17.9548195 },
    { lat: 47.8090756, lng: 17.9548381 },
    { lat: 47.8089243, lng: 17.9547414 },
    { lat: 47.8085055, lng: 17.953886 },
    { lat: 47.8082136, lng: 17.9535771 },
    { lat: 47.8042116, lng: 17.9520959 },
    { lat: 47.8002006, lng: 17.9507117 },
    { lat: 47.7976371, lng: 17.9494618 },
    { lat: 47.7956382, lng: 17.9477749 },
    { lat: 47.7937946, lng: 17.9462087 },
    { lat: 47.7896277, lng: 17.9416473 },
    { lat: 47.7886178, lng: 17.9368682 },
    { lat: 47.7855911, lng: 17.9382869 },
    { lat: 47.785325, lng: 17.9385717 },
    { lat: 47.7842422, lng: 17.9387112 },
    { lat: 47.7828355, lng: 17.9385976 },
    { lat: 47.7820015, lng: 17.9384143 },
    { lat: 47.7822761, lng: 17.9352868 },
    { lat: 47.782324, lng: 17.9300693 },
    { lat: 47.7810401, lng: 17.9296373 },
    { lat: 47.7814104, lng: 17.9265833 },
    { lat: 47.7816189, lng: 17.9254489 },
    { lat: 47.7822604, lng: 17.9238449 },
    { lat: 47.7809878, lng: 17.9233003 },
    { lat: 47.7798461, lng: 17.9226802 },
    { lat: 47.7800228, lng: 17.9218978 },
    { lat: 47.7789189, lng: 17.9212506 },
    { lat: 47.7792047, lng: 17.9197909 },
    { lat: 47.7790015, lng: 17.9196828 },
    { lat: 47.7788713, lng: 17.9202467 },
    { lat: 47.7785871, lng: 17.9201231 },
    { lat: 47.7795296, lng: 17.9167957 },
    { lat: 47.7790771, lng: 17.9165393 },
    { lat: 47.7788276, lng: 17.9173887 },
    { lat: 47.7786372, lng: 17.9172831 },
    { lat: 47.778354, lng: 17.9183746 },
    { lat: 47.7767463, lng: 17.9178513 },
    { lat: 47.7763481, lng: 17.9176515 },
    { lat: 47.7763016, lng: 17.9176307 },
    { lat: 47.7758906, lng: 17.9188295 },
    { lat: 47.7754077, lng: 17.9196829 },
    { lat: 47.7748405, lng: 17.9203412 },
    { lat: 47.7742438, lng: 17.9207916 },
    { lat: 47.7739906, lng: 17.9208741 },
    { lat: 47.7737077, lng: 17.9209015 },
    { lat: 47.7734682, lng: 17.9208253 },
    { lat: 47.7731306, lng: 17.9205998 },
    { lat: 47.7731688, lng: 17.9209308 },
    { lat: 47.7739009, lng: 17.9236795 },
    { lat: 47.7702517, lng: 17.9238843 },
    { lat: 47.7705862, lng: 17.9260512 },
    { lat: 47.7707588, lng: 17.9281254 },
    { lat: 47.7707479, lng: 17.9284269 },
    { lat: 47.7693122, lng: 17.9282259 },
    { lat: 47.7683087, lng: 17.9283363 },
    { lat: 47.7679108, lng: 17.9242909 },
    { lat: 47.7674154, lng: 17.9243855 },
    { lat: 47.7663686, lng: 17.9243064 },
    { lat: 47.7662787, lng: 17.9213143 },
    { lat: 47.7653553, lng: 17.9207756 },
    { lat: 47.7646935, lng: 17.9204937 },
    { lat: 47.7638769, lng: 17.920247 },
    { lat: 47.7617838, lng: 17.9198318 },
    { lat: 47.7613404, lng: 17.917747 },
    { lat: 47.7607314, lng: 17.915188 },
    { lat: 47.7592205, lng: 17.9154557 },
    { lat: 47.7588056, lng: 17.9156654 },
    { lat: 47.7592405, lng: 17.9149317 },
    { lat: 47.7594277, lng: 17.9143029 },
    { lat: 47.7576397, lng: 17.91301 },
    { lat: 47.7571683, lng: 17.9127009 },
    { lat: 47.7561334, lng: 17.9123153 },
    { lat: 47.7560317, lng: 17.9123703 },
    { lat: 47.7541033, lng: 17.9124567 },
    { lat: 47.754263, lng: 17.9104882 },
    { lat: 47.7542619, lng: 17.9093635 },
    { lat: 47.7540586, lng: 17.9083653 },
    { lat: 47.7536076, lng: 17.9071363 },
    { lat: 47.7525917, lng: 17.9050342 },
    { lat: 47.7463722, lng: 17.9102722 },
    { lat: 47.7468373, lng: 17.911751 },
    { lat: 47.7476339, lng: 17.9133325 },
    { lat: 47.7478076, lng: 17.9135347 },
    { lat: 47.7481057, lng: 17.9140518 },
    { lat: 47.7475071, lng: 17.9146563 },
    { lat: 47.7464254, lng: 17.9141865 },
    { lat: 47.7456083, lng: 17.9135613 },
    { lat: 47.7433661, lng: 17.9130529 },
    { lat: 47.743711, lng: 17.9146469 },
    { lat: 47.7439648, lng: 17.916278 },
    { lat: 47.7441257, lng: 17.9179347 },
    { lat: 47.7438398, lng: 17.9367741 },
    { lat: 47.7438347, lng: 17.9387156 },
    { lat: 47.7439565, lng: 17.9406486 },
    { lat: 47.744204, lng: 17.9425551 },
    { lat: 47.7445751, lng: 17.9444171 },
    { lat: 47.7450662, lng: 17.9462169 },
    { lat: 47.7456726, lng: 17.9479377 },
    { lat: 47.7463888, lng: 17.9495634 },
    { lat: 47.7472077, lng: 17.9510784 },
    { lat: 47.7481219, lng: 17.9524686 },
    { lat: 47.7485871, lng: 17.9530524 },
    { lat: 47.7491227, lng: 17.9537209 },
    { lat: 47.7512893, lng: 17.9565919 },
    { lat: 47.7524641, lng: 17.958516 },
    { lat: 47.7535874, lng: 17.9605065 },
    { lat: 47.7546575, lng: 17.9625605 },
    { lat: 47.7556728, lng: 17.964675 },
    { lat: 47.7561127, lng: 17.9660332 },
    { lat: 47.7564694, lng: 17.9675385 },
    { lat: 47.7567221, lng: 17.9690896 },
    { lat: 47.7568677, lng: 17.9706706 },
    { lat: 47.756905, lng: 17.9722654 },
    { lat: 47.7568336, lng: 17.9738576 },
    { lat: 47.756654, lng: 17.9754309 },
    { lat: 47.7563684, lng: 17.9769694 },
    { lat: 47.7559793, lng: 17.9784571 },
    { lat: 47.7554912, lng: 17.979879 },
    { lat: 47.7549086, lng: 17.9812207 },
    { lat: 47.7542377, lng: 17.9824682 },
    { lat: 47.7487518, lng: 17.9932556 },
    { lat: 47.7481912, lng: 17.9949443 },
    { lat: 47.7477481, lng: 17.9967083 },
    { lat: 47.7474269, lng: 17.9985294 },
    { lat: 47.7472312, lng: 18.0003891 },
    { lat: 47.7471625, lng: 18.0022686 },
    { lat: 47.747222, lng: 18.0041488 },
    { lat: 47.7474088, lng: 18.0060107 },
    { lat: 47.747721, lng: 18.0078351 },
    { lat: 47.7481555, lng: 18.0096038 },
    { lat: 47.748708, lng: 18.0112985 },
    { lat: 47.7493727, lng: 18.0129022 },
    { lat: 47.7511387, lng: 18.017241 },
    { lat: 47.752248, lng: 18.0199666 },
    { lat: 47.7544201, lng: 18.0169618 },
    { lat: 47.7560939, lng: 18.0106705 },
    { lat: 47.758102, lng: 18.0059183 },
    { lat: 47.7591722, lng: 18.0011153 },
    { lat: 47.7617613, lng: 17.9987775 },
    { lat: 47.7624261, lng: 17.9984054 },
    { lat: 47.7634199, lng: 17.9975357 },
    { lat: 47.7667089, lng: 17.9963969 },
    { lat: 47.7688525, lng: 17.9977273 },
    { lat: 47.7695365, lng: 17.9980841 },
    { lat: 47.7745359, lng: 17.9977213 },
    { lat: 47.7763244, lng: 17.9978276 },
    { lat: 47.7843508, lng: 18.0004735 },
    { lat: 47.7893152, lng: 18.001232 },
    { lat: 47.801592, lng: 18.0010683 },
    { lat: 47.8046829, lng: 18.0029708 },
    { lat: 47.8060702, lng: 18.0034069 },
    { lat: 47.8060864, lng: 18.0034118 },
    { lat: 47.8063369, lng: 18.0034122 },
    { lat: 47.806745, lng: 18.0031593 },
    { lat: 47.8069212, lng: 18.0033479 },
    { lat: 47.8071881, lng: 18.003488 },
    { lat: 47.8073542, lng: 18.0034737 },
    { lat: 47.8076965, lng: 18.0032151 },
    { lat: 47.8081883, lng: 18.0020323 },
    { lat: 47.808005, lng: 18.001801 },
    { lat: 47.8078565, lng: 18.0018085 },
    { lat: 47.8080752, lng: 18.0013181 },
    { lat: 47.807943, lng: 18.0002469 },
    { lat: 47.8076423, lng: 17.9995272 },
    { lat: 47.8060713, lng: 17.9986862 },
    { lat: 47.8059842, lng: 17.9983671 },
    { lat: 47.8055279, lng: 17.9975388 },
    { lat: 47.8034938, lng: 17.9939792 },
    { lat: 47.8028491, lng: 17.9929686 },
    { lat: 47.803325, lng: 17.9918023 },
    { lat: 47.8033611, lng: 17.991484 },
    { lat: 47.8033254, lng: 17.9900694 },
    { lat: 47.8034313, lng: 17.9898219 },
    { lat: 47.8035622, lng: 17.989676 },
    { lat: 47.8038135, lng: 17.9895755 },
    { lat: 47.803867, lng: 17.9896396 },
    { lat: 47.804274, lng: 17.9895921 },
    { lat: 47.8045331, lng: 17.9896795 },
    { lat: 47.8055667, lng: 17.9903273 },
    { lat: 47.8063218, lng: 17.990984 },
    { lat: 47.8066366, lng: 17.9910183 },
    { lat: 47.8071494, lng: 17.9909677 },
    { lat: 47.8072824, lng: 17.990872 },
    { lat: 47.8075027, lng: 17.9905762 },
    { lat: 47.807842, lng: 17.9896734 },
    { lat: 47.8080379, lng: 17.9887317 },
    { lat: 47.808093, lng: 17.9881092 },
    { lat: 47.808124, lng: 17.9861597 },
    { lat: 47.8079597, lng: 17.9860559 },
    { lat: 47.807524, lng: 17.9839814 },
    { lat: 47.807288, lng: 17.9823936 },
    { lat: 47.8072513, lng: 17.9803468 },
    { lat: 47.8075263, lng: 17.9755304 },
    { lat: 47.8072811, lng: 17.9741099 },
    { lat: 47.8070917, lng: 17.9733631 },
    { lat: 47.8062794, lng: 17.9709676 },
    { lat: 47.8005721, lng: 17.9695783 },
    { lat: 47.8006714, lng: 17.9693909 },
    { lat: 47.8007642, lng: 17.9689675 },
    { lat: 47.8011706, lng: 17.9686878 },
    { lat: 47.8016336, lng: 17.9681152 },
    { lat: 47.8022613, lng: 17.9678377 },
    { lat: 47.8029022, lng: 17.9677981 },
    { lat: 47.8034553, lng: 17.9679286 },
    { lat: 47.8041448, lng: 17.9677772 },
    { lat: 47.8057955, lng: 17.9671895 },
    { lat: 47.8061307, lng: 17.9668162 },
    { lat: 47.8064269, lng: 17.9666955 },
    { lat: 47.8069271, lng: 17.9666993 },
    { lat: 47.8073754, lng: 17.9663656 },
    { lat: 47.8074924, lng: 17.9661923 },
    { lat: 47.8077593, lng: 17.9655658 },
    { lat: 47.8080613, lng: 17.9650581 },
    { lat: 47.8088711, lng: 17.9640047 },
    { lat: 47.8092082, lng: 17.9636458 },
    { lat: 47.8095387, lng: 17.9634563 },
    { lat: 47.8097756, lng: 17.9633615 },
    { lat: 47.8104462, lng: 17.9636051 },
    { lat: 47.810884, lng: 17.9634528 },
    { lat: 47.8111295, lng: 17.9632578 },
    { lat: 47.8119109, lng: 17.9631702 },
    { lat: 47.8123414, lng: 17.9630302 },
    { lat: 47.8129389, lng: 17.9626259 },
    { lat: 47.812899, lng: 17.9625111 },
    { lat: 47.8129589, lng: 17.9624719 },
    { lat: 47.8130738, lng: 17.9628572 },
    { lat: 47.8131298, lng: 17.9628535 },
    { lat: 47.8130734, lng: 17.9626293 },
    { lat: 47.8132488, lng: 17.9625062 },
    { lat: 47.8129516, lng: 17.9612372 },
    { lat: 47.8124228, lng: 17.9607219 },
    { lat: 47.8122683, lng: 17.9602028 },
    { lat: 47.8128958, lng: 17.9583629 },
    { lat: 47.8133113, lng: 17.9578235 },
    { lat: 47.8133268, lng: 17.9578035 },
  ],
  Marcelová: [
    { lat: 47.7482239, lng: 18.3210474 },
    { lat: 47.7483357, lng: 18.3209152 },
    { lat: 47.7485501, lng: 18.3206595 },
    { lat: 47.7492491, lng: 18.3191973 },
    { lat: 47.7502552, lng: 18.31639 },
    { lat: 47.7506041, lng: 18.3159816 },
    { lat: 47.7508582, lng: 18.315906 },
    { lat: 47.7510741, lng: 18.3158948 },
    { lat: 47.7514039, lng: 18.3160392 },
    { lat: 47.7522574, lng: 18.3158116 },
    { lat: 47.7528215, lng: 18.315262 },
    { lat: 47.7530563, lng: 18.3148509 },
    { lat: 47.7536852, lng: 18.3130976 },
    { lat: 47.7542987, lng: 18.3120344 },
    { lat: 47.7545636, lng: 18.3116369 },
    { lat: 47.755104, lng: 18.3111674 },
    { lat: 47.7556151, lng: 18.3109333 },
    { lat: 47.7569986, lng: 18.308801 },
    { lat: 47.7576171, lng: 18.3073931 },
    { lat: 47.758121, lng: 18.3067381 },
    { lat: 47.7589755, lng: 18.3052009 },
    { lat: 47.7593618, lng: 18.3040731 },
    { lat: 47.7598417, lng: 18.3029238 },
    { lat: 47.7605321, lng: 18.3018457 },
    { lat: 47.7613196, lng: 18.3009267 },
    { lat: 47.7620957, lng: 18.2998096 },
    { lat: 47.7630311, lng: 18.2991839 },
    { lat: 47.7640921, lng: 18.3021249 },
    { lat: 47.7641536, lng: 18.302917 },
    { lat: 47.765186, lng: 18.3046593 },
    { lat: 47.7656988, lng: 18.3056888 },
    { lat: 47.7660588, lng: 18.3065528 },
    { lat: 47.7665315, lng: 18.3074116 },
    { lat: 47.7669497, lng: 18.3080509 },
    { lat: 47.765901, lng: 18.3094529 },
    { lat: 47.7664872, lng: 18.310324 },
    { lat: 47.7663411, lng: 18.3112357 },
    { lat: 47.767048, lng: 18.3121309 },
    { lat: 47.768851, lng: 18.3149529 },
    { lat: 47.7698211, lng: 18.3163422 },
    { lat: 47.7700033, lng: 18.3166832 },
    { lat: 47.7704249, lng: 18.3185419 },
    { lat: 47.7700823, lng: 18.3193133 },
    { lat: 47.7704491, lng: 18.3201012 },
    { lat: 47.7709939, lng: 18.3208431 },
    { lat: 47.7719447, lng: 18.321977 },
    { lat: 47.7732636, lng: 18.3234091 },
    { lat: 47.7740703, lng: 18.3241541 },
    { lat: 47.7741473, lng: 18.3242776 },
    { lat: 47.774196, lng: 18.324713 },
    { lat: 47.7742767, lng: 18.3249981 },
    { lat: 47.7750708, lng: 18.3268415 },
    { lat: 47.7768206, lng: 18.3299436 },
    { lat: 47.7775388, lng: 18.3316852 },
    { lat: 47.7778984, lng: 18.3322694 },
    { lat: 47.7789501, lng: 18.3332769 },
    { lat: 47.7804792, lng: 18.3346191 },
    { lat: 47.7811815, lng: 18.3358093 },
    { lat: 47.7822205, lng: 18.3377534 },
    { lat: 47.7838257, lng: 18.3398761 },
    { lat: 47.7840575, lng: 18.341243 },
    { lat: 47.784157, lng: 18.3415437 },
    { lat: 47.7842157, lng: 18.3417026 },
    { lat: 47.7848165, lng: 18.3425985 },
    { lat: 47.7848288, lng: 18.3426159 },
    { lat: 47.7859821, lng: 18.3411424 },
    { lat: 47.7878865, lng: 18.3438795 },
    { lat: 47.7895875, lng: 18.3461011 },
    { lat: 47.790615, lng: 18.3475931 },
    { lat: 47.7907726, lng: 18.3477438 },
    { lat: 47.7907991, lng: 18.3477531 },
    { lat: 47.791056, lng: 18.3475688 },
    { lat: 47.7915854, lng: 18.3471986 },
    { lat: 47.7918909, lng: 18.3470794 },
    { lat: 47.792222, lng: 18.3472268 },
    { lat: 47.7922721, lng: 18.3471545 },
    { lat: 47.7925376, lng: 18.347514 },
    { lat: 47.792593, lng: 18.3474372 },
    { lat: 47.7923558, lng: 18.3471183 },
    { lat: 47.8029708, lng: 18.3287968 },
    { lat: 47.7983431, lng: 18.3226917 },
    { lat: 47.7940967, lng: 18.3298493 },
    { lat: 47.7932831, lng: 18.3309493 },
    { lat: 47.7928985, lng: 18.3316592 },
    { lat: 47.7929011, lng: 18.3318042 },
    { lat: 47.7843983, lng: 18.3207193 },
    { lat: 47.7844324, lng: 18.3206627 },
    { lat: 47.7844076, lng: 18.3206259 },
    { lat: 47.7868529, lng: 18.3170184 },
    { lat: 47.7874194, lng: 18.3163717 },
    { lat: 47.7888228, lng: 18.3151548 },
    { lat: 47.7894377, lng: 18.3147794 },
    { lat: 47.792357, lng: 18.3117284 },
    { lat: 47.792506, lng: 18.3116923 },
    { lat: 47.7941236, lng: 18.3140799 },
    { lat: 47.7946218, lng: 18.315108 },
    { lat: 47.795384, lng: 18.3164931 },
    { lat: 47.7958701, lng: 18.3171389 },
    { lat: 47.7964214, lng: 18.3176021 },
    { lat: 47.7971712, lng: 18.318084 },
    { lat: 47.7998161, lng: 18.3146345 },
    { lat: 47.7998335, lng: 18.3146828 },
    { lat: 47.7999416, lng: 18.3145596 },
    { lat: 47.799925, lng: 18.3145252 },
    { lat: 47.8001487, lng: 18.3142386 },
    { lat: 47.8057777, lng: 18.3070984 },
    { lat: 47.8041646, lng: 18.3051462 },
    { lat: 47.8023649, lng: 18.3033227 },
    { lat: 47.8006261, lng: 18.3012652 },
    { lat: 47.8035456, lng: 18.2975385 },
    { lat: 47.7942788, lng: 18.2872921 },
    { lat: 47.7951543, lng: 18.2856302 },
    { lat: 47.7951759, lng: 18.285652 },
    { lat: 47.7978828, lng: 18.2886428 },
    { lat: 47.7987193, lng: 18.2896664 },
    { lat: 47.7996259, lng: 18.2905943 },
    { lat: 47.8005517, lng: 18.2917185 },
    { lat: 47.8012723, lng: 18.292435 },
    { lat: 47.8019655, lng: 18.2932656 },
    { lat: 47.802504, lng: 18.2937983 },
    { lat: 47.8034789, lng: 18.2949908 },
    { lat: 47.8041096, lng: 18.2956378 },
    { lat: 47.8048811, lng: 18.2962978 },
    { lat: 47.8057888, lng: 18.2969342 },
    { lat: 47.8070694, lng: 18.2980662 },
    { lat: 47.8097244, lng: 18.3002215 },
    { lat: 47.8111772, lng: 18.301509 },
    { lat: 47.8133607, lng: 18.3030822 },
    { lat: 47.816044, lng: 18.3051798 },
    { lat: 47.8162306, lng: 18.3053046 },
    { lat: 47.8164967, lng: 18.3053431 },
    { lat: 47.8171437, lng: 18.3056608 },
    { lat: 47.8176959, lng: 18.3060456 },
    { lat: 47.8185423, lng: 18.3067526 },
    { lat: 47.8185606, lng: 18.3067159 },
    { lat: 47.819715, lng: 18.3072329 },
    { lat: 47.8203153, lng: 18.3076029 },
    { lat: 47.8206799, lng: 18.3080172 },
    { lat: 47.8212322, lng: 18.3091642 },
    { lat: 47.8214742, lng: 18.3095481 },
    { lat: 47.8216816, lng: 18.3097219 },
    { lat: 47.8221043, lng: 18.3097705 },
    { lat: 47.8226604, lng: 18.3101412 },
    { lat: 47.8232133, lng: 18.3107711 },
    { lat: 47.8236699, lng: 18.311508 },
    { lat: 47.8241856, lng: 18.3119815 },
    { lat: 47.8244307, lng: 18.3124205 },
    { lat: 47.8247167, lng: 18.3127599 },
    { lat: 47.8250529, lng: 18.3130157 },
    { lat: 47.8255267, lng: 18.3131129 },
    { lat: 47.826324, lng: 18.3135184 },
    { lat: 47.8270354, lng: 18.3137225 },
    { lat: 47.8273294, lng: 18.3127337 },
    { lat: 47.827491, lng: 18.3125057 },
    { lat: 47.8276359, lng: 18.3124154 },
    { lat: 47.8278391, lng: 18.3123759 },
    { lat: 47.8281827, lng: 18.3125244 },
    { lat: 47.8283091, lng: 18.3125066 },
    { lat: 47.8294373, lng: 18.3120117 },
    { lat: 47.8303173, lng: 18.3114823 },
    { lat: 47.8309086, lng: 18.3112886 },
    { lat: 47.8318921, lng: 18.3106337 },
    { lat: 47.8327638, lng: 18.3102148 },
    { lat: 47.8332048, lng: 18.3098576 },
    { lat: 47.83422, lng: 18.3093221 },
    { lat: 47.8351426, lng: 18.3094186 },
    { lat: 47.8358647, lng: 18.3090808 },
    { lat: 47.8361741, lng: 18.3087708 },
    { lat: 47.8365014, lng: 18.3082257 },
    { lat: 47.8365918, lng: 18.308014 },
    { lat: 47.836666, lng: 18.3075362 },
    { lat: 47.8368745, lng: 18.306886 },
    { lat: 47.8373734, lng: 18.3059307 },
    { lat: 47.837385, lng: 18.3059345 },
    { lat: 47.8374036, lng: 18.3058672 },
    { lat: 47.8365511, lng: 18.3054413 },
    { lat: 47.8355726, lng: 18.3047123 },
    { lat: 47.8350458, lng: 18.3040874 },
    { lat: 47.8342253, lng: 18.3025532 },
    { lat: 47.8335523, lng: 18.2987842 },
    { lat: 47.8332744, lng: 18.2943143 },
    { lat: 47.8328397, lng: 18.2919794 },
    { lat: 47.8325136, lng: 18.291003 },
    { lat: 47.8301475, lng: 18.2883992 },
    { lat: 47.8291743, lng: 18.2862612 },
    { lat: 47.8275742, lng: 18.2823485 },
    { lat: 47.8259107, lng: 18.2794164 },
    { lat: 47.8259561, lng: 18.2790486 },
    { lat: 47.8254078, lng: 18.2782295 },
    { lat: 47.8240214, lng: 18.2766161 },
    { lat: 47.8218409, lng: 18.274518 },
    { lat: 47.8205933, lng: 18.2730036 },
    { lat: 47.8198381, lng: 18.2722153 },
    { lat: 47.8181703, lng: 18.2702259 },
    { lat: 47.8151807, lng: 18.2658889 },
    { lat: 47.8137913, lng: 18.2642073 },
    { lat: 47.812129, lng: 18.26274 },
    { lat: 47.8112221, lng: 18.2618117 },
    { lat: 47.8093454, lng: 18.2596654 },
    { lat: 47.8087826, lng: 18.2589511 },
    { lat: 47.8083995, lng: 18.2582819 },
    { lat: 47.8069275, lng: 18.2561477 },
    { lat: 47.8051235, lng: 18.2537605 },
    { lat: 47.8035944, lng: 18.2518556 },
    { lat: 47.8020413, lng: 18.250175 },
    { lat: 47.8006863, lng: 18.2485249 },
    { lat: 47.7983697, lng: 18.2473205 },
    { lat: 47.7977608, lng: 18.247077 },
    { lat: 47.7967857, lng: 18.2468369 },
    { lat: 47.7959902, lng: 18.2463334 },
    { lat: 47.7953089, lng: 18.2457953 },
    { lat: 47.7940244, lng: 18.2444478 },
    { lat: 47.7932083, lng: 18.2432982 },
    { lat: 47.7919244, lng: 18.2419616 },
    { lat: 47.7903844, lng: 18.2406838 },
    { lat: 47.7894307, lng: 18.2397888 },
    { lat: 47.7887805, lng: 18.2390459 },
    { lat: 47.7876609, lng: 18.2379965 },
    { lat: 47.7855333, lng: 18.2358147 },
    { lat: 47.7844552, lng: 18.2352032 },
    { lat: 47.7834076, lng: 18.2342478 },
    { lat: 47.7801211, lng: 18.2318916 },
    { lat: 47.7799437, lng: 18.2326057 },
    { lat: 47.7796439, lng: 18.233384 },
    { lat: 47.7794441, lng: 18.2343136 },
    { lat: 47.7794982, lng: 18.2354535 },
    { lat: 47.7796528, lng: 18.2360177 },
    { lat: 47.7798192, lng: 18.2363942 },
    { lat: 47.7799451, lng: 18.2369485 },
    { lat: 47.779967, lng: 18.2378083 },
    { lat: 47.7799046, lng: 18.2380559 },
    { lat: 47.7793507, lng: 18.2392974 },
    { lat: 47.7792547, lng: 18.2396665 },
    { lat: 47.779163, lng: 18.2404549 },
    { lat: 47.7792322, lng: 18.240987 },
    { lat: 47.7797927, lng: 18.2432122 },
    { lat: 47.7798117, lng: 18.2437896 },
    { lat: 47.7797651, lng: 18.2448708 },
    { lat: 47.7792578, lng: 18.2448371 },
    { lat: 47.7789349, lng: 18.2449314 },
    { lat: 47.7786452, lng: 18.2452074 },
    { lat: 47.7785835, lng: 18.2453497 },
    { lat: 47.778548, lng: 18.2457474 },
    { lat: 47.7783886, lng: 18.2464631 },
    { lat: 47.7782357, lng: 18.247501 },
    { lat: 47.7782624, lng: 18.2478624 },
    { lat: 47.7786834, lng: 18.2485452 },
    { lat: 47.7788457, lng: 18.2490067 },
    { lat: 47.7788218, lng: 18.2493711 },
    { lat: 47.77869, lng: 18.2498422 },
    { lat: 47.7785031, lng: 18.250239 },
    { lat: 47.7780592, lng: 18.2509865 },
    { lat: 47.7776994, lng: 18.2512998 },
    { lat: 47.7774351, lng: 18.2518391 },
    { lat: 47.7771784, lng: 18.2520434 },
    { lat: 47.7771108, lng: 18.2523734 },
    { lat: 47.7759947, lng: 18.2551521 },
    { lat: 47.775754, lng: 18.2561341 },
    { lat: 47.7754696, lng: 18.2575924 },
    { lat: 47.7755072, lng: 18.2579675 },
    { lat: 47.7758785, lng: 18.2586086 },
    { lat: 47.7759466, lng: 18.2592408 },
    { lat: 47.7760196, lng: 18.2604584 },
    { lat: 47.7758482, lng: 18.2617469 },
    { lat: 47.7757377, lng: 18.2624085 },
    { lat: 47.7755227, lng: 18.2630961 },
    { lat: 47.775513, lng: 18.2634751 },
    { lat: 47.7751065, lng: 18.266118 },
    { lat: 47.7740915, lng: 18.2672528 },
    { lat: 47.7738402, lng: 18.267437 },
    { lat: 47.7735136, lng: 18.2681237 },
    { lat: 47.7733688, lng: 18.269172 },
    { lat: 47.7733946, lng: 18.2694977 },
    { lat: 47.7728788, lng: 18.2695575 },
    { lat: 47.7728714, lng: 18.2704577 },
    { lat: 47.7725437, lng: 18.2726534 },
    { lat: 47.7709305, lng: 18.2770235 },
    { lat: 47.7706382, lng: 18.2782896 },
    { lat: 47.7701383, lng: 18.2800255 },
    { lat: 47.7679506, lng: 18.2779585 },
    { lat: 47.7637475, lng: 18.2741779 },
    { lat: 47.7631221, lng: 18.2736824 },
    { lat: 47.7624476, lng: 18.2728514 },
    { lat: 47.7610209, lng: 18.271458 },
    { lat: 47.7594133, lng: 18.270295 },
    { lat: 47.758732, lng: 18.2697335 },
    { lat: 47.7559009, lng: 18.2789437 },
    { lat: 47.7582855, lng: 18.2806104 },
    { lat: 47.7577311, lng: 18.2832556 },
    { lat: 47.7572795, lng: 18.2851233 },
    { lat: 47.7572798, lng: 18.2857947 },
    { lat: 47.7575166, lng: 18.2873154 },
    { lat: 47.7581828, lng: 18.2890039 },
    { lat: 47.7589941, lng: 18.2903303 },
    { lat: 47.7595269, lng: 18.2916428 },
    { lat: 47.759106, lng: 18.2933035 },
    { lat: 47.7582991, lng: 18.2933212 },
    { lat: 47.7569173, lng: 18.2931516 },
    { lat: 47.7558724, lng: 18.2937727 },
    { lat: 47.754429, lng: 18.2954671 },
    { lat: 47.7537814, lng: 18.2974833 },
    { lat: 47.7541181, lng: 18.2995445 },
    { lat: 47.7539235, lng: 18.2997683 },
    { lat: 47.7532139, lng: 18.3003166 },
    { lat: 47.7523282, lng: 18.3002796 },
    { lat: 47.7519326, lng: 18.3005411 },
    { lat: 47.7513826, lng: 18.3015284 },
    { lat: 47.7521378, lng: 18.3060193 },
    { lat: 47.7516119, lng: 18.308405 },
    { lat: 47.7511944, lng: 18.3099689 },
    { lat: 47.7504748, lng: 18.3119328 },
    { lat: 47.7487365, lng: 18.3136844 },
    { lat: 47.7476963, lng: 18.3151513 },
    { lat: 47.7475217, lng: 18.315823 },
    { lat: 47.7474382, lng: 18.3164709 },
    { lat: 47.7474264, lng: 18.3171096 },
    { lat: 47.7469939, lng: 18.320144 },
    { lat: 47.7469196, lng: 18.3201274 },
    { lat: 47.7468502, lng: 18.3202515 },
    { lat: 47.7468328, lng: 18.3204017 },
    { lat: 47.7468705, lng: 18.3205266 },
    { lat: 47.7471872, lng: 18.3209724 },
    { lat: 47.7477437, lng: 18.3202325 },
    { lat: 47.7482239, lng: 18.3210474 },
  ],
  Dulovce: [
    { lat: 47.8914234, lng: 18.2628614 },
    { lat: 47.8914148, lng: 18.2627897 },
    { lat: 47.8913802, lng: 18.2624823 },
    { lat: 47.8909793, lng: 18.2616013 },
    { lat: 47.8890109, lng: 18.2587069 },
    { lat: 47.8854111, lng: 18.2546674 },
    { lat: 47.8845976, lng: 18.2535191 },
    { lat: 47.8826388, lng: 18.2501381 },
    { lat: 47.8818114, lng: 18.2485556 },
    { lat: 47.8801824, lng: 18.2464429 },
    { lat: 47.87985, lng: 18.2459249 },
    { lat: 47.8792285, lng: 18.2446082 },
    { lat: 47.8784671, lng: 18.242804 },
    { lat: 47.8784161, lng: 18.2428373 },
    { lat: 47.8760853, lng: 18.2384764 },
    { lat: 47.8739655, lng: 18.2340167 },
    { lat: 47.8725754, lng: 18.231477 },
    { lat: 47.8721094, lng: 18.2304488 },
    { lat: 47.8717753, lng: 18.2293806 },
    { lat: 47.8717635, lng: 18.229393 },
    { lat: 47.8709161, lng: 18.2303611 },
    { lat: 47.8705753, lng: 18.2309444 },
    { lat: 47.8699053, lng: 18.2318405 },
    { lat: 47.8695471, lng: 18.2324512 },
    { lat: 47.8679294, lng: 18.2343384 },
    { lat: 47.8666375, lng: 18.236515 },
    { lat: 47.8659928, lng: 18.238217 },
    { lat: 47.8651212, lng: 18.2400362 },
    { lat: 47.864687, lng: 18.2406974 },
    { lat: 47.8632718, lng: 18.2425771 },
    { lat: 47.861522, lng: 18.2443717 },
    { lat: 47.8610155, lng: 18.245307 },
    { lat: 47.8604649, lng: 18.2466083 },
    { lat: 47.8603538, lng: 18.2469093 },
    { lat: 47.8600073, lng: 18.2478412 },
    { lat: 47.860239, lng: 18.2486477 },
    { lat: 47.8603608, lng: 18.2501232 },
    { lat: 47.8603698, lng: 18.2512513 },
    { lat: 47.8604387, lng: 18.2520691 },
    { lat: 47.8606882, lng: 18.2532967 },
    { lat: 47.8607963, lng: 18.2536023 },
    { lat: 47.8603645, lng: 18.2542137 },
    { lat: 47.8610565, lng: 18.2562796 },
    { lat: 47.861311, lng: 18.2574402 },
    { lat: 47.8612587, lng: 18.2620516 },
    { lat: 47.8611911, lng: 18.2624591 },
    { lat: 47.8611239, lng: 18.2635986 },
    { lat: 47.8609901, lng: 18.2643093 },
    { lat: 47.8610114, lng: 18.2648729 },
    { lat: 47.860779, lng: 18.2669766 },
    { lat: 47.8607951, lng: 18.2696542 },
    { lat: 47.8607052, lng: 18.2794409 },
    { lat: 47.8609591, lng: 18.2815499 },
    { lat: 47.8609097, lng: 18.2841178 },
    { lat: 47.8605266, lng: 18.2860428 },
    { lat: 47.8605456, lng: 18.287191 },
    { lat: 47.8605109, lng: 18.2872379 },
    { lat: 47.8612096, lng: 18.2890984 },
    { lat: 47.8614449, lng: 18.2895386 },
    { lat: 47.8615204, lng: 18.28983 },
    { lat: 47.8615351, lng: 18.2910452 },
    { lat: 47.861336, lng: 18.2919549 },
    { lat: 47.8608513, lng: 18.2930214 },
    { lat: 47.8607463, lng: 18.2933938 },
    { lat: 47.860706, lng: 18.2938268 },
    { lat: 47.8607341, lng: 18.2942378 },
    { lat: 47.8608328, lng: 18.2946205 },
    { lat: 47.8611401, lng: 18.2951931 },
    { lat: 47.862649, lng: 18.2967487 },
    { lat: 47.8632362, lng: 18.2979708 },
    { lat: 47.8636739, lng: 18.298693 },
    { lat: 47.8638416, lng: 18.2991966 },
    { lat: 47.8642138, lng: 18.3010943 },
    { lat: 47.8642549, lng: 18.3020265 },
    { lat: 47.8642265, lng: 18.3027658 },
    { lat: 47.8642761, lng: 18.3033154 },
    { lat: 47.8643747, lng: 18.3037754 },
    { lat: 47.8647111, lng: 18.3046792 },
    { lat: 47.8649991, lng: 18.3052498 },
    { lat: 47.865452, lng: 18.3054579 },
    { lat: 47.865995, lng: 18.3058927 },
    { lat: 47.8662455, lng: 18.3063054 },
    { lat: 47.8670207, lng: 18.3073281 },
    { lat: 47.8670444, lng: 18.3073451 },
    { lat: 47.8679497, lng: 18.3057259 },
    { lat: 47.8682404, lng: 18.3053039 },
    { lat: 47.8692544, lng: 18.303988 },
    { lat: 47.8701905, lng: 18.3026079 },
    { lat: 47.8729622, lng: 18.2989306 },
    { lat: 47.8800515, lng: 18.2891427 },
    { lat: 47.8825105, lng: 18.2856671 },
    { lat: 47.8879844, lng: 18.2777167 },
    { lat: 47.8891094, lng: 18.2759567 },
    { lat: 47.8898985, lng: 18.2748527 },
    { lat: 47.8910933, lng: 18.2727012 },
    { lat: 47.8936868, lng: 18.2677517 },
    { lat: 47.8938534, lng: 18.2674151 },
    { lat: 47.8922777, lng: 18.2648516 },
    { lat: 47.8917484, lng: 18.2637774 },
    { lat: 47.8914234, lng: 18.2628614 },
  ],
  ZemianskaOlča: [
    { lat: 47.7948217, lng: 17.8842797 },
    { lat: 47.7973831, lng: 17.8861403 },
    { lat: 47.7976959, lng: 17.8843382 },
    { lat: 47.7979885, lng: 17.8821519 },
    { lat: 47.8011738, lng: 17.8832906 },
    { lat: 47.8022255, lng: 17.8834676 },
    { lat: 47.8039106, lng: 17.8828596 },
    { lat: 47.8046172, lng: 17.882749 },
    { lat: 47.8048376, lng: 17.8826588 },
    { lat: 47.8058665, lng: 17.8855382 },
    { lat: 47.8062499, lng: 17.8863632 },
    { lat: 47.807292, lng: 17.8878937 },
    { lat: 47.8079889, lng: 17.8887865 },
    { lat: 47.8084217, lng: 17.8892509 },
    { lat: 47.8082893, lng: 17.8896894 },
    { lat: 47.8088161, lng: 17.8904926 },
    { lat: 47.8092036, lng: 17.890994 },
    { lat: 47.8106865, lng: 17.8926317 },
    { lat: 47.8192431, lng: 17.8994429 },
    { lat: 47.82034, lng: 17.9004335 },
    { lat: 47.823782, lng: 17.9031159 },
    { lat: 47.8301208, lng: 17.9082773 },
    { lat: 47.8313858, lng: 17.9095671 },
    { lat: 47.8318147, lng: 17.9102498 },
    { lat: 47.8333025, lng: 17.9129689 },
    { lat: 47.834444, lng: 17.9153596 },
    { lat: 47.8356289, lng: 17.9148189 },
    { lat: 47.8371111, lng: 17.9143201 },
    { lat: 47.8394763, lng: 17.9126257 },
    { lat: 47.8424814, lng: 17.9113918 },
    { lat: 47.844458, lng: 17.9100271 },
    { lat: 47.8446061, lng: 17.9101396 },
    { lat: 47.8449666, lng: 17.9111283 },
    { lat: 47.845879, lng: 17.9097109 },
    { lat: 47.8465124, lng: 17.9090095 },
    { lat: 47.8502837, lng: 17.9120667 },
    { lat: 47.851274, lng: 17.9107011 },
    { lat: 47.8518952, lng: 17.9100944 },
    { lat: 47.8522663, lng: 17.9095008 },
    { lat: 47.8530547, lng: 17.9102105 },
    { lat: 47.8523329, lng: 17.9116812 },
    { lat: 47.8539437, lng: 17.9142699 },
    { lat: 47.8542674, lng: 17.9148772 },
    { lat: 47.8549953, lng: 17.9140798 },
    { lat: 47.8555005, lng: 17.9138494 },
    { lat: 47.8570338, lng: 17.9149485 },
    { lat: 47.8615872, lng: 17.9195001 },
    { lat: 47.8622708, lng: 17.9194925 },
    { lat: 47.8624336, lng: 17.9194045 },
    { lat: 47.8632704, lng: 17.9192064 },
    { lat: 47.8641714, lng: 17.9198236 },
    { lat: 47.8647688, lng: 17.9177899 },
    { lat: 47.8650715, lng: 17.917109 },
    { lat: 47.8657981, lng: 17.915732 },
    { lat: 47.8669052, lng: 17.9141747 },
    { lat: 47.8681346, lng: 17.912848 },
    { lat: 47.8684011, lng: 17.9126064 },
    { lat: 47.8684775, lng: 17.9126018 },
    { lat: 47.8686225, lng: 17.9127566 },
    { lat: 47.8686795, lng: 17.9131366 },
    { lat: 47.8687749, lng: 17.9154963 },
    { lat: 47.8691072, lng: 17.9172608 },
    { lat: 47.8692054, lng: 17.9180755 },
    { lat: 47.8693927, lng: 17.9187227 },
    { lat: 47.8697357, lng: 17.9193529 },
    { lat: 47.8700563, lng: 17.9197232 },
    { lat: 47.8703836, lng: 17.9199073 },
    { lat: 47.870773, lng: 17.9200172 },
    { lat: 47.8710436, lng: 17.9199355 },
    { lat: 47.8714331, lng: 17.9195863 },
    { lat: 47.8720871, lng: 17.9188162 },
    { lat: 47.8723463, lng: 17.918627 },
    { lat: 47.8725942, lng: 17.9186189 },
    { lat: 47.8727889, lng: 17.9187551 },
    { lat: 47.8728776, lng: 17.9189731 },
    { lat: 47.8729145, lng: 17.9193172 },
    { lat: 47.872913, lng: 17.9196272 },
    { lat: 47.8727614, lng: 17.9202125 },
    { lat: 47.8728406, lng: 17.9205858 },
    { lat: 47.8729606, lng: 17.9207225 },
    { lat: 47.8733938, lng: 17.9207356 },
    { lat: 47.8736128, lng: 17.9206805 },
    { lat: 47.8730881, lng: 17.9219257 },
    { lat: 47.8727369, lng: 17.9224236 },
    { lat: 47.8728661, lng: 17.9229487 },
    { lat: 47.8733743, lng: 17.9232885 },
    { lat: 47.8748602, lng: 17.9249072 },
    { lat: 47.8748692, lng: 17.925871 },
    { lat: 47.8749201, lng: 17.9264664 },
    { lat: 47.8752253, lng: 17.9270459 },
    { lat: 47.8771736, lng: 17.9245446 },
    { lat: 47.8779969, lng: 17.9233477 },
    { lat: 47.8792836, lng: 17.9206609 },
    { lat: 47.8796477, lng: 17.9221527 },
    { lat: 47.8797308, lng: 17.9226812 },
    { lat: 47.8798921, lng: 17.9232164 },
    { lat: 47.880888, lng: 17.9252272 },
    { lat: 47.8813454, lng: 17.9265271 },
    { lat: 47.8817543, lng: 17.9273111 },
    { lat: 47.8821975, lng: 17.9279508 },
    { lat: 47.8827134, lng: 17.9284732 },
    { lat: 47.8832084, lng: 17.9288177 },
    { lat: 47.8836322, lng: 17.9288827 },
    { lat: 47.8844249, lng: 17.928755 },
    { lat: 47.8849627, lng: 17.9284323 },
    { lat: 47.8852206, lng: 17.9282007 },
    { lat: 47.8855596, lng: 17.9277767 },
    { lat: 47.8864377, lng: 17.9283496 },
    { lat: 47.8874769, lng: 17.9285316 },
    { lat: 47.8889148, lng: 17.9285284 },
    { lat: 47.8891538, lng: 17.928728 },
    { lat: 47.8893325, lng: 17.9288081 },
    { lat: 47.8899265, lng: 17.9290735 },
    { lat: 47.8905946, lng: 17.9292186 },
    { lat: 47.8912209, lng: 17.9291597 },
    { lat: 47.8920403, lng: 17.9289348 },
    { lat: 47.8925351, lng: 17.9286467 },
    { lat: 47.8930775, lng: 17.928489 },
    { lat: 47.8935674, lng: 17.9276342 },
    { lat: 47.8940164, lng: 17.9271876 },
    { lat: 47.8944639, lng: 17.9264335 },
    { lat: 47.8948402, lng: 17.9252725 },
    { lat: 47.8949263, lng: 17.9239825 },
    { lat: 47.8947485, lng: 17.9229889 },
    { lat: 47.8943017, lng: 17.92262 },
    { lat: 47.890991, lng: 17.9199333 },
    { lat: 47.8871896, lng: 17.9178292 },
    { lat: 47.8868055, lng: 17.9164318 },
    { lat: 47.8868495, lng: 17.9154318 },
    { lat: 47.8875628, lng: 17.9138203 },
    { lat: 47.8875329, lng: 17.9136823 },
    { lat: 47.8871028, lng: 17.9135532 },
    { lat: 47.8866919, lng: 17.9130326 },
    { lat: 47.886631, lng: 17.9126089 },
    { lat: 47.88671, lng: 17.9123873 },
    { lat: 47.8869595, lng: 17.9119795 },
    { lat: 47.8870129, lng: 17.911506 },
    { lat: 47.8869469, lng: 17.9110317 },
    { lat: 47.8867385, lng: 17.9108383 },
    { lat: 47.8861504, lng: 17.9108564 },
    { lat: 47.8855683, lng: 17.9111199 },
    { lat: 47.8848596, lng: 17.9118259 },
    { lat: 47.8843686, lng: 17.9121604 },
    { lat: 47.8840546, lng: 17.9122319 },
    { lat: 47.8836436, lng: 17.9119895 },
    { lat: 47.8830024, lng: 17.9112288 },
    { lat: 47.8831217, lng: 17.9110183 },
    { lat: 47.8833034, lng: 17.9110191 },
    { lat: 47.8834922, lng: 17.9111153 },
    { lat: 47.8836388, lng: 17.9110762 },
    { lat: 47.8837801, lng: 17.9106714 },
    { lat: 47.8837281, lng: 17.9095975 },
    { lat: 47.8836222, lng: 17.9090291 },
    { lat: 47.8830258, lng: 17.9079843 },
    { lat: 47.8822673, lng: 17.9068785 },
    { lat: 47.8818661, lng: 17.9061302 },
    { lat: 47.881748, lng: 17.9054548 },
    { lat: 47.8808679, lng: 17.9045332 },
    { lat: 47.8791709, lng: 17.9032692 },
    { lat: 47.8772573, lng: 17.9020659 },
    { lat: 47.8761562, lng: 17.9014457 },
    { lat: 47.875419, lng: 17.9011145 },
    { lat: 47.8747766, lng: 17.900817 },
    { lat: 47.8744053, lng: 17.9011128 },
    { lat: 47.8741525, lng: 17.901315 },
    { lat: 47.8720923, lng: 17.9029417 },
    { lat: 47.871857, lng: 17.9034298 },
    { lat: 47.8716135, lng: 17.9037847 },
    { lat: 47.8713455, lng: 17.9047215 },
    { lat: 47.8711299, lng: 17.9051264 },
    { lat: 47.8707303, lng: 17.9056444 },
    { lat: 47.8702644, lng: 17.9052421 },
    { lat: 47.8691949, lng: 17.9065462 },
    { lat: 47.8683055, lng: 17.9077847 },
    { lat: 47.8671321, lng: 17.9082586 },
    { lat: 47.8663846, lng: 17.9082529 },
    { lat: 47.8655618, lng: 17.9080686 },
    { lat: 47.8646142, lng: 17.9079489 },
    { lat: 47.8643491, lng: 17.9080275 },
    { lat: 47.863992, lng: 17.9082412 },
    { lat: 47.8635846, lng: 17.9085928 },
    { lat: 47.8632975, lng: 17.9079964 },
    { lat: 47.8631606, lng: 17.908064 },
    { lat: 47.8630757, lng: 17.9080056 },
    { lat: 47.8630841, lng: 17.9078487 },
    { lat: 47.8631585, lng: 17.9076605 },
    { lat: 47.8629633, lng: 17.9073659 },
    { lat: 47.8624136, lng: 17.9067381 },
    { lat: 47.8616127, lng: 17.9052872 },
    { lat: 47.8609551, lng: 17.9039078 },
    { lat: 47.8608042, lng: 17.9034705 },
    { lat: 47.8608682, lng: 17.9032257 },
    { lat: 47.8605483, lng: 17.9022946 },
    { lat: 47.8588494, lng: 17.8993747 },
    { lat: 47.858653, lng: 17.897919 },
    { lat: 47.8570338, lng: 17.8934636 },
    { lat: 47.8548765, lng: 17.8903057 },
    { lat: 47.8541818, lng: 17.8894528 },
    { lat: 47.8512228, lng: 17.886515 },
    { lat: 47.8489553, lng: 17.8833895 },
    { lat: 47.8449626, lng: 17.8784723 },
    { lat: 47.8423388, lng: 17.8748409 },
    { lat: 47.8393426, lng: 17.8710857 },
    { lat: 47.8384252, lng: 17.8700404 },
    { lat: 47.8358943, lng: 17.8664054 },
    { lat: 47.8346068, lng: 17.8647383 },
    { lat: 47.8286163, lng: 17.8575686 },
    { lat: 47.8273872, lng: 17.855996 },
    { lat: 47.8258156, lng: 17.8538109 },
    { lat: 47.822705, lng: 17.8509784 },
    { lat: 47.820786, lng: 17.8490186 },
    { lat: 47.8198593, lng: 17.8497908 },
    { lat: 47.8195503, lng: 17.8501633 },
    { lat: 47.819055, lng: 17.8510275 },
    { lat: 47.8178604, lng: 17.8533493 },
    { lat: 47.8173783, lng: 17.8544277 },
    { lat: 47.8168396, lng: 17.8552871 },
    { lat: 47.8162661, lng: 17.8566612 },
    { lat: 47.8152993, lng: 17.8587616 },
    { lat: 47.8151579, lng: 17.8592317 },
    { lat: 47.8143203, lng: 17.858756 },
    { lat: 47.8140399, lng: 17.8593335 },
    { lat: 47.8126727, lng: 17.8588352 },
    { lat: 47.8116939, lng: 17.8585779 },
    { lat: 47.8087831, lng: 17.8580739 },
    { lat: 47.807433, lng: 17.8566229 },
    { lat: 47.8068034, lng: 17.8560412 },
    { lat: 47.8058063, lng: 17.8552099 },
    { lat: 47.8048203, lng: 17.8545256 },
    { lat: 47.805379, lng: 17.8513346 },
    { lat: 47.8031512, lng: 17.8496428 },
    { lat: 47.8018559, lng: 17.8492489 },
    { lat: 47.8022677, lng: 17.8456392 },
    { lat: 47.8006184, lng: 17.8445794 },
    { lat: 47.8004739, lng: 17.8443968 },
    { lat: 47.8003074, lng: 17.8440216 },
    { lat: 47.7999559, lng: 17.8439605 },
    { lat: 47.7998089, lng: 17.8438089 },
    { lat: 47.7995232, lng: 17.8438242 },
    { lat: 47.7951849, lng: 17.8413726 },
    { lat: 47.7934174, lng: 17.841012 },
    { lat: 47.7908512, lng: 17.8386288 },
    { lat: 47.7890707, lng: 17.83707 },
    { lat: 47.7879724, lng: 17.8363776 },
    { lat: 47.7864522, lng: 17.836238 },
    { lat: 47.7858321, lng: 17.8361788 },
    { lat: 47.786426, lng: 17.8393054 },
    { lat: 47.7866277, lng: 17.8407603 },
    { lat: 47.7865663, lng: 17.8414854 },
    { lat: 47.7862446, lng: 17.844303 },
    { lat: 47.7859495, lng: 17.8455196 },
    { lat: 47.7854339, lng: 17.8485067 },
    { lat: 47.7851542, lng: 17.8498286 },
    { lat: 47.7851924, lng: 17.8508697 },
    { lat: 47.785256, lng: 17.8514251 },
    { lat: 47.78532, lng: 17.851494 },
    { lat: 47.785117, lng: 17.8521178 },
    { lat: 47.7846483, lng: 17.8539276 },
    { lat: 47.7841163, lng: 17.855516 },
    { lat: 47.7839084, lng: 17.8563191 },
    { lat: 47.7837701, lng: 17.8582675 },
    { lat: 47.783809, lng: 17.8590635 },
    { lat: 47.7837973, lng: 17.8607091 },
    { lat: 47.7833311, lng: 17.862413 },
    { lat: 47.7830893, lng: 17.8638033 },
    { lat: 47.7834603, lng: 17.8639268 },
    { lat: 47.7839378, lng: 17.8646598 },
    { lat: 47.78424, lng: 17.8662306 },
    { lat: 47.7851005, lng: 17.8661986 },
    { lat: 47.7857755, lng: 17.8680702 },
    { lat: 47.7858271, lng: 17.8686367 },
    { lat: 47.7857662, lng: 17.8692427 },
    { lat: 47.7857929, lng: 17.871584 },
    { lat: 47.7859891, lng: 17.8777181 },
    { lat: 47.7858971, lng: 17.8786459 },
    { lat: 47.7863508, lng: 17.8800798 },
    { lat: 47.7867176, lng: 17.8796167 },
    { lat: 47.7874201, lng: 17.8790925 },
    { lat: 47.7879048, lng: 17.8788423 },
    { lat: 47.7909173, lng: 17.8779172 },
    { lat: 47.7918614, lng: 17.8779784 },
    { lat: 47.7934622, lng: 17.8782386 },
    { lat: 47.7956189, lng: 17.879502 },
    { lat: 47.7954263, lng: 17.880425 },
    { lat: 47.7953612, lng: 17.8809635 },
    { lat: 47.7946757, lng: 17.8841243 },
    { lat: 47.7948039, lng: 17.8842642 },
    { lat: 47.7948217, lng: 17.8842797 },
  ],
  RadvaňnadDunajom: [
    { lat: 47.7750708, lng: 18.3268415 },
    { lat: 47.7749879, lng: 18.3269498 },
    { lat: 47.7740661, lng: 18.3281287 },
    { lat: 47.7736867, lng: 18.3285172 },
    { lat: 47.7732643, lng: 18.3288438 },
    { lat: 47.7727083, lng: 18.3280618 },
    { lat: 47.7714097, lng: 18.3294761 },
    { lat: 47.7713436, lng: 18.3293866 },
    { lat: 47.770982, lng: 18.3300653 },
    { lat: 47.7700833, lng: 18.3311352 },
    { lat: 47.7687302, lng: 18.3292598 },
    { lat: 47.7662659, lng: 18.3327962 },
    { lat: 47.7634049, lng: 18.3297522 },
    { lat: 47.7604756, lng: 18.3343287 },
    { lat: 47.7595711, lng: 18.3340565 },
    { lat: 47.7595284, lng: 18.3341208 },
    { lat: 47.7567262, lng: 18.3312769 },
    { lat: 47.7538718, lng: 18.3376273 },
    { lat: 47.7519436, lng: 18.3356377 },
    { lat: 47.7506995, lng: 18.3344681 },
    { lat: 47.7492778, lng: 18.3332464 },
    { lat: 47.7477449, lng: 18.33174 },
    { lat: 47.7477738, lng: 18.3316922 },
    { lat: 47.7456267, lng: 18.3291569 },
    { lat: 47.7447409, lng: 18.3278001 },
    { lat: 47.7444642, lng: 18.327462 },
    { lat: 47.7444003, lng: 18.3275679 },
    { lat: 47.7438648, lng: 18.3284978 },
    { lat: 47.7435223, lng: 18.3288986 },
    { lat: 47.7430617, lng: 18.32958 },
    { lat: 47.7422923, lng: 18.3311149 },
    { lat: 47.7419218, lng: 18.3320898 },
    { lat: 47.741471, lng: 18.3336889 },
    { lat: 47.7414119, lng: 18.333643 },
    { lat: 47.7375888, lng: 18.3354852 },
    { lat: 47.7376501, lng: 18.3358544 },
    { lat: 47.7382797, lng: 18.3391505 },
    { lat: 47.7383968, lng: 18.3397309 },
    { lat: 47.7384925, lng: 18.3402051 },
    { lat: 47.7387563, lng: 18.3412343 },
    { lat: 47.7389691, lng: 18.3419975 },
    { lat: 47.7392065, lng: 18.3430403 },
    { lat: 47.7393945, lng: 18.3441053 },
    { lat: 47.7395322, lng: 18.3451869 },
    { lat: 47.7397365, lng: 18.3464471 },
    { lat: 47.7400224, lng: 18.3476721 },
    { lat: 47.7401939, lng: 18.3484181 },
    { lat: 47.7403278, lng: 18.3491809 },
    { lat: 47.7404253, lng: 18.3499366 },
    { lat: 47.7406545, lng: 18.3512238 },
    { lat: 47.740927, lng: 18.3524921 },
    { lat: 47.7412027, lng: 18.3535714 },
    { lat: 47.7417387, lng: 18.3556609 },
    { lat: 47.7422027, lng: 18.3577878 },
    { lat: 47.7425937, lng: 18.3599467 },
    { lat: 47.7434894, lng: 18.3655158 },
    { lat: 47.7443849, lng: 18.3710849 },
    { lat: 47.7445704, lng: 18.3722725 },
    { lat: 47.744823, lng: 18.3734323 },
    { lat: 47.7451409, lng: 18.3745564 },
    { lat: 47.7455218, lng: 18.3756365 },
    { lat: 47.7470034, lng: 18.3791147 },
    { lat: 47.7478029, lng: 18.3811785 },
    { lat: 47.7486046, lng: 18.3832404 },
    { lat: 47.7494741, lng: 18.3856471 },
    { lat: 47.7500388, lng: 18.3874531 },
    { lat: 47.750542, lng: 18.3892985 },
    { lat: 47.7509823, lng: 18.3911788 },
    { lat: 47.7510595, lng: 18.3915704 },
    { lat: 47.752946, lng: 18.3897965 },
    { lat: 47.7542674, lng: 18.3881353 },
    { lat: 47.7563236, lng: 18.3886254 },
    { lat: 47.757952, lng: 18.3875616 },
    { lat: 47.7602259, lng: 18.386343 },
    { lat: 47.7633505, lng: 18.3847878 },
    { lat: 47.7665798, lng: 18.382574 },
    { lat: 47.7681134, lng: 18.3812177 },
    { lat: 47.7697683, lng: 18.3804405 },
    { lat: 47.7697505, lng: 18.3803648 },
    { lat: 47.7733069, lng: 18.377541 },
    { lat: 47.775169, lng: 18.3751156 },
    { lat: 47.7764188, lng: 18.3736246 },
    { lat: 47.7764306, lng: 18.3736365 },
    { lat: 47.7765028, lng: 18.3735355 },
    { lat: 47.7790681, lng: 18.369575 },
    { lat: 47.7790418, lng: 18.3695285 },
    { lat: 47.7790212, lng: 18.3695655 },
    { lat: 47.7768516, lng: 18.3660237 },
    { lat: 47.7751825, lng: 18.3643746 },
    { lat: 47.7747801, lng: 18.3640271 },
    { lat: 47.774434, lng: 18.3638146 },
    { lat: 47.7723922, lng: 18.361329 },
    { lat: 47.7736509, lng: 18.3598034 },
    { lat: 47.7743144, lng: 18.3592707 },
    { lat: 47.7752238, lng: 18.3587776 },
    { lat: 47.7757197, lng: 18.358275 },
    { lat: 47.7760964, lng: 18.357623 },
    { lat: 47.7763541, lng: 18.3569786 },
    { lat: 47.7782671, lng: 18.3531431 },
    { lat: 47.7789551, lng: 18.3516398 },
    { lat: 47.7795052, lng: 18.3502914 },
    { lat: 47.7801048, lng: 18.3483505 },
    { lat: 47.7805831, lng: 18.3482678 },
    { lat: 47.7812088, lng: 18.3477081 },
    { lat: 47.7820175, lng: 18.3466002 },
    { lat: 47.7829079, lng: 18.3452245 },
    { lat: 47.7848288, lng: 18.3426159 },
    { lat: 47.7848165, lng: 18.3425985 },
    { lat: 47.7842157, lng: 18.3417026 },
    { lat: 47.784157, lng: 18.3415437 },
    { lat: 47.7840575, lng: 18.341243 },
    { lat: 47.7838257, lng: 18.3398761 },
    { lat: 47.7822205, lng: 18.3377534 },
    { lat: 47.7811815, lng: 18.3358093 },
    { lat: 47.7804792, lng: 18.3346191 },
    { lat: 47.7789501, lng: 18.3332769 },
    { lat: 47.7778984, lng: 18.3322694 },
    { lat: 47.7775388, lng: 18.3316852 },
    { lat: 47.7768206, lng: 18.3299436 },
    { lat: 47.7750708, lng: 18.3268415 },
  ],
  VrbovánadVáhom: [
    { lat: 47.8724955, lng: 18.0286965 },
    { lat: 47.8714061, lng: 18.0289917 },
    { lat: 47.8712087, lng: 18.0290523 },
    { lat: 47.8695829, lng: 18.0291541 },
    { lat: 47.866623, lng: 18.0291832 },
    { lat: 47.8654693, lng: 18.0292541 },
    { lat: 47.8629802, lng: 18.0295086 },
    { lat: 47.8608702, lng: 18.0299118 },
    { lat: 47.8581397, lng: 18.0306786 },
    { lat: 47.8564749, lng: 18.0312775 },
    { lat: 47.8546932, lng: 18.0320366 },
    { lat: 47.8511824, lng: 18.033832 },
    { lat: 47.8510104, lng: 18.0340666 },
    { lat: 47.8501973, lng: 18.0347188 },
    { lat: 47.8487536, lng: 18.0357148 },
    { lat: 47.8478008, lng: 18.0365007 },
    { lat: 47.8470286, lng: 18.0369861 },
    { lat: 47.8453942, lng: 18.0384896 },
    { lat: 47.8427201, lng: 18.0402737 },
    { lat: 47.8415021, lng: 18.0407189 },
    { lat: 47.8410191, lng: 18.0408175 },
    { lat: 47.8403475, lng: 18.0412091 },
    { lat: 47.8398105, lng: 18.041664 },
    { lat: 47.8387358, lng: 18.0432814 },
    { lat: 47.8376834, lng: 18.0450429 },
    { lat: 47.8368583, lng: 18.0462486 },
    { lat: 47.8334056, lng: 18.0518835 },
    { lat: 47.8313462, lng: 18.0545688 },
    { lat: 47.8305747, lng: 18.0551612 },
    { lat: 47.8299847, lng: 18.0554104 },
    { lat: 47.8283214, lng: 18.0554688 },
    { lat: 47.8259929, lng: 18.0557305 },
    { lat: 47.8245466, lng: 18.0557572 },
    { lat: 47.8234553, lng: 18.055644 },
    { lat: 47.8238748, lng: 18.0574359 },
    { lat: 47.8239442, lng: 18.0575356 },
    { lat: 47.8240743, lng: 18.0577098 },
    { lat: 47.8243121, lng: 18.0580286 },
    { lat: 47.8245135, lng: 18.0585857 },
    { lat: 47.8248537, lng: 18.0589869 },
    { lat: 47.8250893, lng: 18.0593593 },
    { lat: 47.8253161, lng: 18.0598506 },
    { lat: 47.8258006, lng: 18.0602169 },
    { lat: 47.8257819, lng: 18.0611111 },
    { lat: 47.8257132, lng: 18.0616237 },
    { lat: 47.8255563, lng: 18.062117 },
    { lat: 47.8252981, lng: 18.0624787 },
    { lat: 47.8242386, lng: 18.0635386 },
    { lat: 47.8236989, lng: 18.0638959 },
    { lat: 47.8228188, lng: 18.0640876 },
    { lat: 47.8224292, lng: 18.064081 },
    { lat: 47.8210096, lng: 18.0637668 },
    { lat: 47.8214866, lng: 18.0679178 },
    { lat: 47.8219033, lng: 18.0690544 },
    { lat: 47.8219169, lng: 18.0704544 },
    { lat: 47.821754, lng: 18.07117 },
    { lat: 47.8223426, lng: 18.0740746 },
    { lat: 47.8245634, lng: 18.0763222 },
    { lat: 47.8268261, lng: 18.0809217 },
    { lat: 47.8277234, lng: 18.0829727 },
    { lat: 47.8286526, lng: 18.0846846 },
    { lat: 47.8285898, lng: 18.0851266 },
    { lat: 47.8286241, lng: 18.0854013 },
    { lat: 47.8289361, lng: 18.0857888 },
    { lat: 47.8295588, lng: 18.0859364 },
    { lat: 47.8298311, lng: 18.0862441 },
    { lat: 47.829887, lng: 18.0871471 },
    { lat: 47.8300227, lng: 18.0882671 },
    { lat: 47.8304945, lng: 18.0896746 },
    { lat: 47.8303627, lng: 18.0919747 },
    { lat: 47.8303864, lng: 18.0921275 },
    { lat: 47.8310819, lng: 18.0937611 },
    { lat: 47.8311368, lng: 18.0945901 },
    { lat: 47.8312285, lng: 18.0951105 },
    { lat: 47.8314829, lng: 18.0949465 },
    { lat: 47.8315417, lng: 18.0951548 },
    { lat: 47.8319203, lng: 18.0949405 },
    { lat: 47.8330353, lng: 18.0956808 },
    { lat: 47.8333491, lng: 18.0962125 },
    { lat: 47.8337455, lng: 18.096635 },
    { lat: 47.8341558, lng: 18.0972717 },
    { lat: 47.8344545, lng: 18.0981001 },
    { lat: 47.8353902, lng: 18.0993585 },
    { lat: 47.8356224, lng: 18.0992572 },
    { lat: 47.836054, lng: 18.0994012 },
    { lat: 47.8368396, lng: 18.099155 },
    { lat: 47.8370014, lng: 18.0992184 },
    { lat: 47.8372343, lng: 18.0992072 },
    { lat: 47.8375914, lng: 18.0989479 },
    { lat: 47.8377509, lng: 18.0990897 },
    { lat: 47.8378901, lng: 18.0991353 },
    { lat: 47.8380071, lng: 18.0990902 },
    { lat: 47.8384076, lng: 18.0992207 },
    { lat: 47.8387163, lng: 18.0994563 },
    { lat: 47.8390386, lng: 18.100268 },
    { lat: 47.8392001, lng: 18.1004032 },
    { lat: 47.8393717, lng: 18.0997017 },
    { lat: 47.8397658, lng: 18.0986033 },
    { lat: 47.8399459, lng: 18.0983482 },
    { lat: 47.8405855, lng: 18.097904 },
    { lat: 47.8408611, lng: 18.0978897 },
    { lat: 47.8420287, lng: 18.0983563 },
    { lat: 47.8425386, lng: 18.0982621 },
    { lat: 47.8435026, lng: 18.0979015 },
    { lat: 47.843838, lng: 18.0978502 },
    { lat: 47.8443309, lng: 18.0978832 },
    { lat: 47.8449779, lng: 18.0980033 },
    { lat: 47.845848, lng: 18.0983628 },
    { lat: 47.8464816, lng: 18.0982752 },
    { lat: 47.8469247, lng: 18.0980429 },
    { lat: 47.8471286, lng: 18.0978798 },
    { lat: 47.8472297, lng: 18.0977988 },
    { lat: 47.8472377, lng: 18.097783 },
    { lat: 47.8479747, lng: 18.0963953 },
    { lat: 47.8486767, lng: 18.0953343 },
    { lat: 47.8490169, lng: 18.0949075 },
    { lat: 47.8492668, lng: 18.0947072 },
    { lat: 47.8494074, lng: 18.0941835 },
    { lat: 47.8494911, lng: 18.0924043 },
    { lat: 47.8496656, lng: 18.0910871 },
    { lat: 47.8496809, lng: 18.0905451 },
    { lat: 47.8496147, lng: 18.0900409 },
    { lat: 47.8495253, lng: 18.0895997 },
    { lat: 47.8492068, lng: 18.0887966 },
    { lat: 47.8488554, lng: 18.0882795 },
    { lat: 47.8484266, lng: 18.0880136 },
    { lat: 47.8479034, lng: 18.087868 },
    { lat: 47.8473676, lng: 18.0876264 },
    { lat: 47.8469815, lng: 18.0872077 },
    { lat: 47.8467337, lng: 18.0866834 },
    { lat: 47.8463495, lng: 18.0850071 },
    { lat: 47.8463297, lng: 18.0847146 },
    { lat: 47.8463714, lng: 18.0841625 },
    { lat: 47.8464738, lng: 18.0835322 },
    { lat: 47.8469985, lng: 18.0812335 },
    { lat: 47.8473568, lng: 18.0802779 },
    { lat: 47.848346, lng: 18.0783941 },
    { lat: 47.8488397, lng: 18.0780443 },
    { lat: 47.8505153, lng: 18.0775257 },
    { lat: 47.8513269, lng: 18.0771675 },
    { lat: 47.8514501, lng: 18.0768495 },
    { lat: 47.85175, lng: 18.0766558 },
    { lat: 47.8525316, lng: 18.0767037 },
    { lat: 47.8529122, lng: 18.0768048 },
    { lat: 47.8534366, lng: 18.0767021 },
    { lat: 47.8537834, lng: 18.0764615 },
    { lat: 47.8539642, lng: 18.0765389 },
    { lat: 47.8537005, lng: 18.0776066 },
    { lat: 47.8537356, lng: 18.0776287 },
    { lat: 47.8680063, lng: 18.0684744 },
    { lat: 47.8793262, lng: 18.061201 },
    { lat: 47.8793135, lng: 18.0611426 },
    { lat: 47.8792493, lng: 18.0608589 },
    { lat: 47.8792526, lng: 18.060367 },
    { lat: 47.8793439, lng: 18.060194 },
    { lat: 47.8799647, lng: 18.059666 },
    { lat: 47.8802188, lng: 18.059347 },
    { lat: 47.8805726, lng: 18.0583542 },
    { lat: 47.880946, lng: 18.0575769 },
    { lat: 47.8811444, lng: 18.0577989 },
    { lat: 47.8819388, lng: 18.0592712 },
    { lat: 47.8821114, lng: 18.0597631 },
    { lat: 47.882639, lng: 18.0593936 },
    { lat: 47.8870057, lng: 18.056147 },
    { lat: 47.8877485, lng: 18.0563949 },
    { lat: 47.8883297, lng: 18.056488 },
    { lat: 47.889643, lng: 18.0571072 },
    { lat: 47.8901625, lng: 18.0571287 },
    { lat: 47.8902987, lng: 18.0566142 },
    { lat: 47.8919668, lng: 18.0576335 },
    { lat: 47.8928941, lng: 18.0579865 },
    { lat: 47.8932881, lng: 18.0580094 },
    { lat: 47.8939242, lng: 18.0578101 },
    { lat: 47.8948207, lng: 18.0572153 },
    { lat: 47.8950992, lng: 18.0569837 },
    { lat: 47.8952461, lng: 18.0567589 },
    { lat: 47.8953198, lng: 18.0565948 },
    { lat: 47.8951317, lng: 18.0561657 },
    { lat: 47.8951618, lng: 18.0556114 },
    { lat: 47.8952044, lng: 18.0554946 },
    { lat: 47.8964726, lng: 18.054034 },
    { lat: 47.8965472, lng: 18.0539018 },
    { lat: 47.8966207, lng: 18.0535633 },
    { lat: 47.8966578, lng: 18.0531082 },
    { lat: 47.897476, lng: 18.0518045 },
    { lat: 47.8979926, lng: 18.051477 },
    { lat: 47.8979738, lng: 18.0513733 },
    { lat: 47.8980425, lng: 18.0513304 },
    { lat: 47.8957268, lng: 18.0475374 },
    { lat: 47.8948594, lng: 18.0471871 },
    { lat: 47.8918152, lng: 18.0465582 },
    { lat: 47.8817819, lng: 18.0439767 },
    { lat: 47.878952, lng: 18.043195 },
    { lat: 47.8795955, lng: 18.0414083 },
    { lat: 47.8814754, lng: 18.0352591 },
    { lat: 47.8829383, lng: 18.0308942 },
    { lat: 47.882643, lng: 18.0309179 },
    { lat: 47.8814605, lng: 18.0307141 },
    { lat: 47.8811018, lng: 18.0307585 },
    { lat: 47.8806195, lng: 18.0306948 },
    { lat: 47.8795909, lng: 18.0309499 },
    { lat: 47.8767835, lng: 18.03094 },
    { lat: 47.8754085, lng: 18.0307864 },
    { lat: 47.8746045, lng: 18.0304771 },
    { lat: 47.8724955, lng: 18.0286965 },
  ],
  Hurbanovo: [
    { lat: 47.8269636, lng: 18.122011 },
    { lat: 47.8268031, lng: 18.1221187 },
    { lat: 47.8248781, lng: 18.1273658 },
    { lat: 47.8240122, lng: 18.1298813 },
    { lat: 47.8229644, lng: 18.1332507 },
    { lat: 47.823269, lng: 18.1384257 },
    { lat: 47.8231016, lng: 18.1413623 },
    { lat: 47.8231165, lng: 18.1427613 },
    { lat: 47.8229112, lng: 18.1434485 },
    { lat: 47.8226154, lng: 18.1437749 },
    { lat: 47.8220663, lng: 18.1446434 },
    { lat: 47.8218065, lng: 18.145443 },
    { lat: 47.8240306, lng: 18.1475811 },
    { lat: 47.8259234, lng: 18.1503057 },
    { lat: 47.8259777, lng: 18.1517812 },
    { lat: 47.8258952, lng: 18.1539044 },
    { lat: 47.8259603, lng: 18.1562581 },
    { lat: 47.8258984, lng: 18.1594039 },
    { lat: 47.8256623, lng: 18.1620059 },
    { lat: 47.8270284, lng: 18.1671802 },
    { lat: 47.8278793, lng: 18.1688512 },
    { lat: 47.8276024, lng: 18.1698777 },
    { lat: 47.8273234, lng: 18.1721791 },
    { lat: 47.8284259, lng: 18.1738121 },
    { lat: 47.8311153, lng: 18.1785058 },
    { lat: 47.8314281, lng: 18.1821435 },
    { lat: 47.8313739, lng: 18.1827034 },
    { lat: 47.8313443, lng: 18.1832742 },
    { lat: 47.830965, lng: 18.1849485 },
    { lat: 47.8308188, lng: 18.1863029 },
    { lat: 47.8321169, lng: 18.1895253 },
    { lat: 47.8326864, lng: 18.1911567 },
    { lat: 47.8355475, lng: 18.1952714 },
    { lat: 47.8363278, lng: 18.1966478 },
    { lat: 47.8375759, lng: 18.1981359 },
    { lat: 47.8387848, lng: 18.1999365 },
    { lat: 47.8396785, lng: 18.201383 },
    { lat: 47.8399241, lng: 18.2018789 },
    { lat: 47.8400781, lng: 18.202369 },
    { lat: 47.8405389, lng: 18.204556 },
    { lat: 47.8406366, lng: 18.2047805 },
    { lat: 47.8408375, lng: 18.2050124 },
    { lat: 47.8410001, lng: 18.2054775 },
    { lat: 47.8412467, lng: 18.2058162 },
    { lat: 47.8413143, lng: 18.206163 },
    { lat: 47.8419807, lng: 18.2079698 },
    { lat: 47.8446899, lng: 18.2126653 },
    { lat: 47.8452023, lng: 18.2140595 },
    { lat: 47.8463819, lng: 18.2177574 },
    { lat: 47.8462887, lng: 18.2191393 },
    { lat: 47.8470739, lng: 18.2228536 },
    { lat: 47.847625, lng: 18.2258079 },
    { lat: 47.8477939, lng: 18.2263812 },
    { lat: 47.8484756, lng: 18.2277984 },
    { lat: 47.8484813, lng: 18.2279161 },
    { lat: 47.8468511, lng: 18.2298193 },
    { lat: 47.8464725, lng: 18.2317107 },
    { lat: 47.8466696, lng: 18.2328666 },
    { lat: 47.8469485, lng: 18.2341291 },
    { lat: 47.8479633, lng: 18.2385596 },
    { lat: 47.8486224, lng: 18.2411812 },
    { lat: 47.8485651, lng: 18.2428205 },
    { lat: 47.8484206, lng: 18.2443712 },
    { lat: 47.8485685, lng: 18.2456755 },
    { lat: 47.8487555, lng: 18.2470506 },
    { lat: 47.8493954, lng: 18.249887 },
    { lat: 47.8499189, lng: 18.2519697 },
    { lat: 47.8501122, lng: 18.25213 },
    { lat: 47.8506091, lng: 18.2520021 },
    { lat: 47.8522425, lng: 18.2512941 },
    { lat: 47.8538253, lng: 18.2509973 },
    { lat: 47.8562571, lng: 18.2487054 },
    { lat: 47.8572154, lng: 18.2478954 },
    { lat: 47.8580734, lng: 18.2470091 },
    { lat: 47.8601029, lng: 18.2450762 },
    { lat: 47.8604539, lng: 18.2465567 },
    { lat: 47.8604649, lng: 18.2466083 },
    { lat: 47.8610155, lng: 18.245307 },
    { lat: 47.861522, lng: 18.2443717 },
    { lat: 47.8632718, lng: 18.2425771 },
    { lat: 47.864687, lng: 18.2406974 },
    { lat: 47.8651212, lng: 18.2400362 },
    { lat: 47.8659928, lng: 18.238217 },
    { lat: 47.8666375, lng: 18.236515 },
    { lat: 47.8679294, lng: 18.2343384 },
    { lat: 47.8695471, lng: 18.2324512 },
    { lat: 47.8699053, lng: 18.2318405 },
    { lat: 47.8705753, lng: 18.2309444 },
    { lat: 47.8709161, lng: 18.2303611 },
    { lat: 47.8717635, lng: 18.229393 },
    { lat: 47.8717753, lng: 18.2293806 },
    { lat: 47.8721094, lng: 18.2304488 },
    { lat: 47.8725754, lng: 18.231477 },
    { lat: 47.8739655, lng: 18.2340167 },
    { lat: 47.8760853, lng: 18.2384764 },
    { lat: 47.8784161, lng: 18.2428373 },
    { lat: 47.8784671, lng: 18.242804 },
    { lat: 47.8792285, lng: 18.2446082 },
    { lat: 47.87985, lng: 18.2459249 },
    { lat: 47.8801824, lng: 18.2464429 },
    { lat: 47.8818114, lng: 18.2485556 },
    { lat: 47.8826388, lng: 18.2501381 },
    { lat: 47.8845976, lng: 18.2535191 },
    { lat: 47.8854111, lng: 18.2546674 },
    { lat: 47.8890109, lng: 18.2587069 },
    { lat: 47.8909793, lng: 18.2616013 },
    { lat: 47.8913802, lng: 18.2624823 },
    { lat: 47.8914148, lng: 18.2627897 },
    { lat: 47.8914234, lng: 18.2628614 },
    { lat: 47.8914564, lng: 18.2627088 },
    { lat: 47.8930205, lng: 18.2603353 },
    { lat: 47.8938513, lng: 18.2589577 },
    { lat: 47.8952161, lng: 18.2565135 },
    { lat: 47.8942312, lng: 18.2540992 },
    { lat: 47.8958882, lng: 18.2518308 },
    { lat: 47.896019, lng: 18.2508975 },
    { lat: 47.8959885, lng: 18.2493565 },
    { lat: 47.8960529, lng: 18.2480586 },
    { lat: 47.8967904, lng: 18.2458893 },
    { lat: 47.898719, lng: 18.2424696 },
    { lat: 47.9017065, lng: 18.2378191 },
    { lat: 47.9030162, lng: 18.2355607 },
    { lat: 47.9039959, lng: 18.2337226 },
    { lat: 47.9048923, lng: 18.2323122 },
    { lat: 47.9062703, lng: 18.2292324 },
    { lat: 47.9075781, lng: 18.2270375 },
    { lat: 47.9087692, lng: 18.2248001 },
    { lat: 47.9092892, lng: 18.2222777 },
    { lat: 47.9092455, lng: 18.2201707 },
    { lat: 47.9103291, lng: 18.2177753 },
    { lat: 47.9108227, lng: 18.2165641 },
    { lat: 47.9113895, lng: 18.21494 },
    { lat: 47.9117229, lng: 18.2136133 },
    { lat: 47.9116038, lng: 18.2097704 },
    { lat: 47.9116839, lng: 18.2081686 },
    { lat: 47.9119118, lng: 18.2054419 },
    { lat: 47.9123966, lng: 18.2020524 },
    { lat: 47.9125656, lng: 18.201293 },
    { lat: 47.9129366, lng: 18.200148 },
    { lat: 47.9139205, lng: 18.1964202 },
    { lat: 47.9146019, lng: 18.1931907 },
    { lat: 47.9151285, lng: 18.1910639 },
    { lat: 47.9162112, lng: 18.188274 },
    { lat: 47.9174628, lng: 18.1856567 },
    { lat: 47.9175891, lng: 18.1856354 },
    { lat: 47.9176853, lng: 18.1852349 },
    { lat: 47.9175721, lng: 18.1849991 },
    { lat: 47.9172223, lng: 18.1846705 },
    { lat: 47.9169054, lng: 18.1839423 },
    { lat: 47.9166743, lng: 18.1838118 },
    { lat: 47.9163335, lng: 18.184021 },
    { lat: 47.9162423, lng: 18.1844306 },
    { lat: 47.9160767, lng: 18.1848204 },
    { lat: 47.9158253, lng: 18.1849606 },
    { lat: 47.9156305, lng: 18.1849506 },
    { lat: 47.9155068, lng: 18.1847633 },
    { lat: 47.9153262, lng: 18.1840495 },
    { lat: 47.9151523, lng: 18.1838342 },
    { lat: 47.9149174, lng: 18.1837162 },
    { lat: 47.9144514, lng: 18.1837931 },
    { lat: 47.9140518, lng: 18.1842902 },
    { lat: 47.9138306, lng: 18.1843902 },
    { lat: 47.9136219, lng: 18.1841781 },
    { lat: 47.9134613, lng: 18.1838043 },
    { lat: 47.9131012, lng: 18.1837444 },
    { lat: 47.9129942, lng: 18.1833843 },
    { lat: 47.9129562, lng: 18.1829848 },
    { lat: 47.9126352, lng: 18.1822248 },
    { lat: 47.91243, lng: 18.181862 },
    { lat: 47.9122675, lng: 18.1818046 },
    { lat: 47.9119037, lng: 18.1818342 },
    { lat: 47.9117844, lng: 18.1819165 },
    { lat: 47.9113581, lng: 18.1816312 },
    { lat: 47.9110979, lng: 18.1809633 },
    { lat: 47.9111672, lng: 18.1806254 },
    { lat: 47.9111497, lng: 18.1803098 },
    { lat: 47.9107634, lng: 18.1802061 },
    { lat: 47.9103595, lng: 18.1805339 },
    { lat: 47.910082, lng: 18.1809595 },
    { lat: 47.9097358, lng: 18.1809267 },
    { lat: 47.9092263, lng: 18.1810328 },
    { lat: 47.9091662, lng: 18.1812787 },
    { lat: 47.9092301, lng: 18.181868 },
    { lat: 47.9092181, lng: 18.1821649 },
    { lat: 47.9084887, lng: 18.1826379 },
    { lat: 47.9081157, lng: 18.1827052 },
    { lat: 47.9077638, lng: 18.1824385 },
    { lat: 47.9076741, lng: 18.1821786 },
    { lat: 47.9076686, lng: 18.1818501 },
    { lat: 47.9073838, lng: 18.1814977 },
    { lat: 47.9073463, lng: 18.1812441 },
    { lat: 47.907623, lng: 18.1808059 },
    { lat: 47.9075889, lng: 18.180453 },
    { lat: 47.9074375, lng: 18.1802743 },
    { lat: 47.9072516, lng: 18.1801855 },
    { lat: 47.906994, lng: 18.1803732 },
    { lat: 47.9068463, lng: 18.1806051 },
    { lat: 47.9068097, lng: 18.1811948 },
    { lat: 47.9066879, lng: 18.1814455 },
    { lat: 47.9064559, lng: 18.18153 },
    { lat: 47.9062553, lng: 18.1814389 },
    { lat: 47.9060981, lng: 18.1811811 },
    { lat: 47.9060893, lng: 18.1808984 },
    { lat: 47.9061388, lng: 18.1806824 },
    { lat: 47.9064794, lng: 18.180199 },
    { lat: 47.9065043, lng: 18.1799496 },
    { lat: 47.9062788, lng: 18.1797977 },
    { lat: 47.9060502, lng: 18.1799212 },
    { lat: 47.9056288, lng: 18.1804169 },
    { lat: 47.9054066, lng: 18.1804545 },
    { lat: 47.9052532, lng: 18.1802661 },
    { lat: 47.9050991, lng: 18.179888 },
    { lat: 47.9050672, lng: 18.1791125 },
    { lat: 47.9051458, lng: 18.178642 },
    { lat: 47.905699, lng: 18.1782615 },
    { lat: 47.9058036, lng: 18.1780816 },
    { lat: 47.9058948, lng: 18.1776924 },
    { lat: 47.9058356, lng: 18.1772995 },
    { lat: 47.9057145, lng: 18.1771988 },
    { lat: 47.9052202, lng: 18.177253 },
    { lat: 47.9048723, lng: 18.177059 },
    { lat: 47.9046919, lng: 18.1768095 },
    { lat: 47.9044108, lng: 18.1760199 },
    { lat: 47.9041128, lng: 18.1759017 },
    { lat: 47.9037904, lng: 18.1758876 },
    { lat: 47.9029422, lng: 18.1760862 },
    { lat: 47.9028123, lng: 18.1763137 },
    { lat: 47.9028486, lng: 18.1770105 },
    { lat: 47.9027776, lng: 18.1775328 },
    { lat: 47.9026579, lng: 18.1776095 },
    { lat: 47.9020332, lng: 18.177598 },
    { lat: 47.9018044, lng: 18.177737 },
    { lat: 47.9016808, lng: 18.1780165 },
    { lat: 47.9015973, lng: 18.1785558 },
    { lat: 47.9014519, lng: 18.1785968 },
    { lat: 47.9013148, lng: 18.1784127 },
    { lat: 47.9012311, lng: 18.1781785 },
    { lat: 47.9013162, lng: 18.177762 },
    { lat: 47.9013219, lng: 18.177456 },
    { lat: 47.90129, lng: 18.1771215 },
    { lat: 47.9012048, lng: 18.1767858 },
    { lat: 47.9011409, lng: 18.1767157 },
    { lat: 47.9009012, lng: 18.1766898 },
    { lat: 47.9003539, lng: 18.1769408 },
    { lat: 47.8999932, lng: 18.1770041 },
    { lat: 47.8995256, lng: 18.1772483 },
    { lat: 47.8993037, lng: 18.1771948 },
    { lat: 47.8989841, lng: 18.1767496 },
    { lat: 47.8988162, lng: 18.1767365 },
    { lat: 47.8982569, lng: 18.1769539 },
    { lat: 47.8981581, lng: 18.1772522 },
    { lat: 47.8981588, lng: 18.1775306 },
    { lat: 47.8980273, lng: 18.1785231 },
    { lat: 47.8976999, lng: 18.1794643 },
    { lat: 47.8973205, lng: 18.179805 },
    { lat: 47.8966645, lng: 18.1798967 },
    { lat: 47.8965794, lng: 18.1800789 },
    { lat: 47.8965225, lng: 18.1806893 },
    { lat: 47.8963981, lng: 18.1810898 },
    { lat: 47.8963724, lng: 18.1814577 },
    { lat: 47.8966021, lng: 18.1816808 },
    { lat: 47.8966898, lng: 18.1819468 },
    { lat: 47.896639, lng: 18.1821581 },
    { lat: 47.8964978, lng: 18.1821939 },
    { lat: 47.8961522, lng: 18.1819173 },
    { lat: 47.8960537, lng: 18.18191 },
    { lat: 47.8957806, lng: 18.1821448 },
    { lat: 47.8956118, lng: 18.1824399 },
    { lat: 47.8952925, lng: 18.1825768 },
    { lat: 47.8950147, lng: 18.1825724 },
    { lat: 47.8946531, lng: 18.1826611 },
    { lat: 47.8944354, lng: 18.1831432 },
    { lat: 47.8942281, lng: 18.1832084 },
    { lat: 47.8940366, lng: 18.183152 },
    { lat: 47.8939544, lng: 18.182984 },
    { lat: 47.8940528, lng: 18.1826214 },
    { lat: 47.894006, lng: 18.1822871 },
    { lat: 47.8936965, lng: 18.1820498 },
    { lat: 47.893495, lng: 18.1820773 },
    { lat: 47.8933339, lng: 18.1823707 },
    { lat: 47.8931153, lng: 18.1823768 },
    { lat: 47.8930389, lng: 18.1824577 },
    { lat: 47.8928198, lng: 18.1830532 },
    { lat: 47.8925561, lng: 18.1833106 },
    { lat: 47.8922249, lng: 18.1832231 },
    { lat: 47.8919774, lng: 18.1829254 },
    { lat: 47.8916535, lng: 18.1823696 },
    { lat: 47.8917065, lng: 18.181962 },
    { lat: 47.8920648, lng: 18.1816248 },
    { lat: 47.8921587, lng: 18.1814386 },
    { lat: 47.8921425, lng: 18.1811028 },
    { lat: 47.891746, lng: 18.1805948 },
    { lat: 47.8916846, lng: 18.1802206 },
    { lat: 47.891816, lng: 18.1798639 },
    { lat: 47.8921875, lng: 18.1795212 },
    { lat: 47.8922332, lng: 18.1792614 },
    { lat: 47.8922077, lng: 18.1791557 },
    { lat: 47.8920049, lng: 18.1789883 },
    { lat: 47.8910399, lng: 18.1793672 },
    { lat: 47.8906717, lng: 18.1793836 },
    { lat: 47.8904129, lng: 18.1792807 },
    { lat: 47.8900442, lng: 18.1788598 },
    { lat: 47.8898622, lng: 18.1787611 },
    { lat: 47.8898186, lng: 18.1787833 },
    { lat: 47.8897725, lng: 18.1792108 },
    { lat: 47.8896834, lng: 18.1794266 },
    { lat: 47.8890744, lng: 18.1797962 },
    { lat: 47.8888786, lng: 18.1800575 },
    { lat: 47.8886395, lng: 18.1806176 },
    { lat: 47.8884573, lng: 18.1808219 },
    { lat: 47.8882074, lng: 18.180714 },
    { lat: 47.887935, lng: 18.1803587 },
    { lat: 47.8877163, lng: 18.1799588 },
    { lat: 47.8876713, lng: 18.1797126 },
    { lat: 47.8880072, lng: 18.1790893 },
    { lat: 47.8880886, lng: 18.1785898 },
    { lat: 47.8880054, lng: 18.1777885 },
    { lat: 47.8880965, lng: 18.1772047 },
    { lat: 47.8880817, lng: 18.1770322 },
    { lat: 47.8874949, lng: 18.1766839 },
    { lat: 47.8874451, lng: 18.1766609 },
    { lat: 47.8875906, lng: 18.1746786 },
    { lat: 47.8877709, lng: 18.1737341 },
    { lat: 47.8882112, lng: 18.1701789 },
    { lat: 47.8882949, lng: 18.1629409 },
    { lat: 47.8884676, lng: 18.1594467 },
    { lat: 47.8886653, lng: 18.1516446 },
    { lat: 47.8883818, lng: 18.149609 },
    { lat: 47.8876829, lng: 18.1473381 },
    { lat: 47.8873867, lng: 18.146123 },
    { lat: 47.8869541, lng: 18.1428246 },
    { lat: 47.8869543, lng: 18.1427315 },
    { lat: 47.8869411, lng: 18.1427363 },
    { lat: 47.8853852, lng: 18.1433753 },
    { lat: 47.8851796, lng: 18.1434088 },
    { lat: 47.8828106, lng: 18.1434221 },
    { lat: 47.8824844, lng: 18.1433641 },
    { lat: 47.8772982, lng: 18.1437915 },
    { lat: 47.8745681, lng: 18.1438785 },
    { lat: 47.8716593, lng: 18.1448073 },
    { lat: 47.8704259, lng: 18.145706 },
    { lat: 47.8682517, lng: 18.1469366 },
    { lat: 47.8678956, lng: 18.1471382 },
    { lat: 47.8658631, lng: 18.1483811 },
    { lat: 47.8657827, lng: 18.1483785 },
    { lat: 47.8657816, lng: 18.1487814 },
    { lat: 47.8656877, lng: 18.1487971 },
    { lat: 47.8655131, lng: 18.148706 },
    { lat: 47.8653715, lng: 18.1484862 },
    { lat: 47.8653193, lng: 18.1479061 },
    { lat: 47.8654252, lng: 18.1472148 },
    { lat: 47.8652047, lng: 18.1466868 },
    { lat: 47.8650651, lng: 18.146581 },
    { lat: 47.8646657, lng: 18.146465 },
    { lat: 47.8645128, lng: 18.146552 },
    { lat: 47.8644573, lng: 18.1468576 },
    { lat: 47.8641205, lng: 18.1471734 },
    { lat: 47.8638401, lng: 18.1471259 },
    { lat: 47.863788, lng: 18.1469686 },
    { lat: 47.8637355, lng: 18.1465393 },
    { lat: 47.8638382, lng: 18.1456372 },
    { lat: 47.8639236, lng: 18.1455186 },
    { lat: 47.8640063, lng: 18.1454642 },
    { lat: 47.8643771, lng: 18.1455534 },
    { lat: 47.8646394, lng: 18.1457508 },
    { lat: 47.8649606, lng: 18.1458397 },
    { lat: 47.865267, lng: 18.1458301 },
    { lat: 47.8655157, lng: 18.145629 },
    { lat: 47.8653691, lng: 18.1445981 },
    { lat: 47.8651484, lng: 18.1435701 },
    { lat: 47.8649405, lng: 18.1431076 },
    { lat: 47.8645215, lng: 18.1427394 },
    { lat: 47.8640295, lng: 18.1424683 },
    { lat: 47.8634901, lng: 18.1414383 },
    { lat: 47.8632055, lng: 18.1405902 },
    { lat: 47.8629994, lng: 18.1389735 },
    { lat: 47.8627677, lng: 18.1386045 },
    { lat: 47.8626269, lng: 18.1385121 },
    { lat: 47.8624181, lng: 18.1384557 },
    { lat: 47.8620064, lng: 18.1385561 },
    { lat: 47.8604257, lng: 18.1394965 },
    { lat: 47.8600259, lng: 18.1396459 },
    { lat: 47.8598213, lng: 18.1398841 },
    { lat: 47.8596195, lng: 18.1400242 },
    { lat: 47.8593035, lng: 18.1400218 },
    { lat: 47.8588808, lng: 18.1402413 },
    { lat: 47.8588073, lng: 18.1405257 },
    { lat: 47.8588018, lng: 18.1407379 },
    { lat: 47.8591469, lng: 18.1420377 },
    { lat: 47.8591459, lng: 18.142249 },
    { lat: 47.8590566, lng: 18.1425357 },
    { lat: 47.8587871, lng: 18.1427855 },
    { lat: 47.8567528, lng: 18.1441133 },
    { lat: 47.8563836, lng: 18.1442453 },
    { lat: 47.8561412, lng: 18.1441528 },
    { lat: 47.8557674, lng: 18.1437855 },
    { lat: 47.8546993, lng: 18.1420132 },
    { lat: 47.8542038, lng: 18.1415715 },
    { lat: 47.853957, lng: 18.1414796 },
    { lat: 47.853596, lng: 18.1414458 },
    { lat: 47.8528514, lng: 18.1416401 },
    { lat: 47.8520719, lng: 18.1420469 },
    { lat: 47.8516687, lng: 18.1421742 },
    { lat: 47.8512867, lng: 18.142166 },
    { lat: 47.8504808, lng: 18.1417043 },
    { lat: 47.8502695, lng: 18.1416411 },
    { lat: 47.8499798, lng: 18.1417056 },
    { lat: 47.849816, lng: 18.1419517 },
    { lat: 47.8488693, lng: 18.1416657 },
    { lat: 47.848185, lng: 18.1408162 },
    { lat: 47.8474524, lng: 18.1393423 },
    { lat: 47.8461895, lng: 18.1356862 },
    { lat: 47.8462431, lng: 18.1355619 },
    { lat: 47.845892, lng: 18.1350814 },
    { lat: 47.8458435, lng: 18.1348731 },
    { lat: 47.8458571, lng: 18.1344153 },
    { lat: 47.846131, lng: 18.1335516 },
    { lat: 47.8462796, lng: 18.1319395 },
    { lat: 47.846232, lng: 18.1316306 },
    { lat: 47.8459869, lng: 18.1308444 },
    { lat: 47.8456425, lng: 18.1302858 },
    { lat: 47.8453821, lng: 18.1300416 },
    { lat: 47.8451272, lng: 18.1298939 },
    { lat: 47.8448507, lng: 18.1298727 },
    { lat: 47.8444567, lng: 18.1300045 },
    { lat: 47.8442853, lng: 18.1298527 },
    { lat: 47.8441923, lng: 18.1295799 },
    { lat: 47.8440612, lng: 18.1286984 },
    { lat: 47.8436776, lng: 18.1272868 },
    { lat: 47.8426784, lng: 18.125925 },
    { lat: 47.841458, lng: 18.1247502 },
    { lat: 47.8409636, lng: 18.1247876 },
    { lat: 47.8401579, lng: 18.1251309 },
    { lat: 47.8385751, lng: 18.1245866 },
    { lat: 47.838394, lng: 18.1222895 },
    { lat: 47.8370796, lng: 18.121321 },
    { lat: 47.8369767, lng: 18.1213022 },
    { lat: 47.836609, lng: 18.1216301 },
    { lat: 47.8363539, lng: 18.1217582 },
    { lat: 47.8356996, lng: 18.1218223 },
    { lat: 47.8351543, lng: 18.1217031 },
    { lat: 47.8344662, lng: 18.121232 },
    { lat: 47.8337778, lng: 18.1209444 },
    { lat: 47.832407, lng: 18.1207989 },
    { lat: 47.8318434, lng: 18.1208647 },
    { lat: 47.8316609, lng: 18.12095 },
    { lat: 47.8315159, lng: 18.1208608 },
    { lat: 47.8313245, lng: 18.1211962 },
    { lat: 47.8309279, lng: 18.1215952 },
    { lat: 47.830423, lng: 18.122037 },
    { lat: 47.8299855, lng: 18.122289 },
    { lat: 47.8288846, lng: 18.1226786 },
    { lat: 47.8281367, lng: 18.1228201 },
    { lat: 47.8276831, lng: 18.1228389 },
    { lat: 47.8273037, lng: 18.122683 },
    { lat: 47.8270846, lng: 18.1223162 },
    { lat: 47.8269636, lng: 18.122011 },
  ],
  Bajč: [
    { lat: 47.94393, lng: 18.167069 },
    { lat: 47.9388955, lng: 18.1729843 },
    { lat: 47.9361986, lng: 18.1757963 },
    { lat: 47.9342726, lng: 18.173739 },
    { lat: 47.9325556, lng: 18.1714778 },
    { lat: 47.9322646, lng: 18.1710946 },
    { lat: 47.9290088, lng: 18.174654 },
    { lat: 47.9272786, lng: 18.1761311 },
    { lat: 47.9246522, lng: 18.1747156 },
    { lat: 47.9229479, lng: 18.1781553 },
    { lat: 47.9222337, lng: 18.17922 },
    { lat: 47.9211653, lng: 18.1811277 },
    { lat: 47.9199626, lng: 18.1829045 },
    { lat: 47.9197742, lng: 18.1830426 },
    { lat: 47.9181954, lng: 18.1855273 },
    { lat: 47.9177342, lng: 18.1856071 },
    { lat: 47.9175891, lng: 18.1856354 },
    { lat: 47.9174628, lng: 18.1856567 },
    { lat: 47.9162112, lng: 18.188274 },
    { lat: 47.9151285, lng: 18.1910639 },
    { lat: 47.9146019, lng: 18.1931907 },
    { lat: 47.9139205, lng: 18.1964202 },
    { lat: 47.9129366, lng: 18.200148 },
    { lat: 47.9125656, lng: 18.201293 },
    { lat: 47.9123966, lng: 18.2020524 },
    { lat: 47.9119118, lng: 18.2054419 },
    { lat: 47.9116839, lng: 18.2081686 },
    { lat: 47.9116038, lng: 18.2097704 },
    { lat: 47.9117229, lng: 18.2136133 },
    { lat: 47.9113895, lng: 18.21494 },
    { lat: 47.9108227, lng: 18.2165641 },
    { lat: 47.9103291, lng: 18.2177753 },
    { lat: 47.9092455, lng: 18.2201707 },
    { lat: 47.9092892, lng: 18.2222777 },
    { lat: 47.9087692, lng: 18.2248001 },
    { lat: 47.9075781, lng: 18.2270375 },
    { lat: 47.9062703, lng: 18.2292324 },
    { lat: 47.9048923, lng: 18.2323122 },
    { lat: 47.9039959, lng: 18.2337226 },
    { lat: 47.9030162, lng: 18.2355607 },
    { lat: 47.9017065, lng: 18.2378191 },
    { lat: 47.898719, lng: 18.2424696 },
    { lat: 47.8967904, lng: 18.2458893 },
    { lat: 47.8960529, lng: 18.2480586 },
    { lat: 47.8959885, lng: 18.2493565 },
    { lat: 47.896019, lng: 18.2508975 },
    { lat: 47.8958882, lng: 18.2518308 },
    { lat: 47.8942312, lng: 18.2540992 },
    { lat: 47.8952161, lng: 18.2565135 },
    { lat: 47.8938513, lng: 18.2589577 },
    { lat: 47.8930205, lng: 18.2603353 },
    { lat: 47.8914564, lng: 18.2627088 },
    { lat: 47.8914234, lng: 18.2628614 },
    { lat: 47.8917484, lng: 18.2637774 },
    { lat: 47.8922777, lng: 18.2648516 },
    { lat: 47.8938534, lng: 18.2674151 },
    { lat: 47.8936868, lng: 18.2677517 },
    { lat: 47.8944263, lng: 18.2690097 },
    { lat: 47.8956046, lng: 18.2705364 },
    { lat: 47.8968844, lng: 18.2720854 },
    { lat: 47.8975925, lng: 18.2726995 },
    { lat: 47.8987705, lng: 18.2738907 },
    { lat: 47.8987963, lng: 18.2738498 },
    { lat: 47.8998187, lng: 18.2749677 },
    { lat: 47.9007798, lng: 18.2765643 },
    { lat: 47.9015497, lng: 18.2751643 },
    { lat: 47.9024965, lng: 18.2736432 },
    { lat: 47.9045163, lng: 18.271653 },
    { lat: 47.9052505, lng: 18.2704955 },
    { lat: 47.9062388, lng: 18.2693993 },
    { lat: 47.9070111, lng: 18.2688247 },
    { lat: 47.9083016, lng: 18.2675674 },
    { lat: 47.9088865, lng: 18.2667942 },
    { lat: 47.9096747, lng: 18.2674671 },
    { lat: 47.9171125, lng: 18.2696527 },
    { lat: 47.9180673, lng: 18.2697916 },
    { lat: 47.9287504, lng: 18.2719601 },
    { lat: 47.9334097, lng: 18.2648613 },
    { lat: 47.9342642, lng: 18.2661322 },
    { lat: 47.9347733, lng: 18.2667225 },
    { lat: 47.9369617, lng: 18.2687045 },
    { lat: 47.939878, lng: 18.271643 },
    { lat: 47.940223, lng: 18.270798 },
    { lat: 47.940506, lng: 18.270155 },
    { lat: 47.940761, lng: 18.269462 },
    { lat: 47.940851, lng: 18.269169 },
    { lat: 47.941014, lng: 18.268591 },
    { lat: 47.941241, lng: 18.26783 },
    { lat: 47.94142, lng: 18.267347 },
    { lat: 47.941632, lng: 18.266869 },
    { lat: 47.941945, lng: 18.266253 },
    { lat: 47.942293, lng: 18.265542 },
    { lat: 47.942797, lng: 18.264402 },
    { lat: 47.942919, lng: 18.26415 },
    { lat: 47.943248, lng: 18.263503 },
    { lat: 47.943892, lng: 18.262403 },
    { lat: 47.944131, lng: 18.261906 },
    { lat: 47.944987, lng: 18.260094 },
    { lat: 47.945296, lng: 18.259487 },
    { lat: 47.945864, lng: 18.258452 },
    { lat: 47.946314, lng: 18.257613 },
    { lat: 47.946584, lng: 18.257109 },
    { lat: 47.947032, lng: 18.256261 },
    { lat: 47.947704, lng: 18.255092 },
    { lat: 47.948225, lng: 18.254311 },
    { lat: 47.948312, lng: 18.254179 },
    { lat: 47.949068, lng: 18.253145 },
    { lat: 47.949125, lng: 18.253038 },
    { lat: 47.949156, lng: 18.252982 },
    { lat: 47.949192, lng: 18.252912 },
    { lat: 47.949593, lng: 18.251848 },
    { lat: 47.949777, lng: 18.251458 },
    { lat: 47.949999, lng: 18.251104 },
    { lat: 47.950561, lng: 18.250261 },
    { lat: 47.951373, lng: 18.249315 },
    { lat: 47.95222, lng: 18.248336 },
    { lat: 47.952677, lng: 18.247692 },
    { lat: 47.952833, lng: 18.247358 },
    { lat: 47.952865, lng: 18.247294 },
    { lat: 47.953415, lng: 18.247697 },
    { lat: 47.953447, lng: 18.247643 },
    { lat: 47.953469, lng: 18.247605 },
    { lat: 47.954414, lng: 18.246056 },
    { lat: 47.954681, lng: 18.24507 },
    { lat: 47.954906, lng: 18.244351 },
    { lat: 47.955012, lng: 18.244117 },
    { lat: 47.95514, lng: 18.24385 },
    { lat: 47.95521, lng: 18.243703 },
    { lat: 47.955273, lng: 18.243571 },
    { lat: 47.956407, lng: 18.241211 },
    { lat: 47.957523, lng: 18.238884 },
    { lat: 47.958658, lng: 18.236538 },
    { lat: 47.959296, lng: 18.230983 },
    { lat: 47.959287, lng: 18.229611 },
    { lat: 47.959286, lng: 18.229425 },
    { lat: 47.959281, lng: 18.228538 },
    { lat: 47.959375, lng: 18.226427 },
    { lat: 47.959607, lng: 18.225222 },
    { lat: 47.959613, lng: 18.225194 },
    { lat: 47.959647, lng: 18.225018 },
    { lat: 47.95967, lng: 18.2249 },
    { lat: 47.959712, lng: 18.224679 },
    { lat: 47.959719, lng: 18.224642 },
    { lat: 47.959749, lng: 18.224491 },
    { lat: 47.959786, lng: 18.224293 },
    { lat: 47.959805, lng: 18.224196 },
    { lat: 47.960172, lng: 18.222266 },
    { lat: 47.960767, lng: 18.219272 },
    { lat: 47.956132, lng: 18.217709 },
    { lat: 47.955201, lng: 18.216688 },
    { lat: 47.953788, lng: 18.215047 },
    { lat: 47.953037, lng: 18.214232 },
    { lat: 47.951998, lng: 18.213051 },
    { lat: 47.951218, lng: 18.212171 },
    { lat: 47.950744, lng: 18.211643 },
    { lat: 47.951528, lng: 18.210528 },
    { lat: 47.952563, lng: 18.209087 },
    { lat: 47.953522, lng: 18.207685 },
    { lat: 47.95477, lng: 18.205858 },
    { lat: 47.955822, lng: 18.204316 },
    { lat: 47.955483, lng: 18.203722 },
    { lat: 47.954805, lng: 18.201629 },
    { lat: 47.954231, lng: 18.199888 },
    { lat: 47.954148, lng: 18.199639 },
    { lat: 47.954098, lng: 18.199485 },
    { lat: 47.953828, lng: 18.198667 },
    { lat: 47.953119, lng: 18.196482 },
    { lat: 47.952239, lng: 18.194014 },
    { lat: 47.951994, lng: 18.19332 },
    { lat: 47.951889, lng: 18.193021 },
    { lat: 47.951656, lng: 18.192363 },
    { lat: 47.950012, lng: 18.187731 },
    { lat: 47.949283, lng: 18.18565 },
    { lat: 47.948661, lng: 18.182676 },
    { lat: 47.948001, lng: 18.17953 },
    { lat: 47.947724, lng: 18.178455 },
    { lat: 47.947397, lng: 18.177189 },
    { lat: 47.947108, lng: 18.176116 },
    { lat: 47.94698, lng: 18.17564 },
    { lat: 47.94693, lng: 18.175454 },
    { lat: 47.946463, lng: 18.173721 },
    { lat: 47.94579, lng: 18.171312 },
    { lat: 47.944945, lng: 18.169349 },
    { lat: 47.944198, lng: 18.16767 },
    { lat: 47.944167, lng: 18.167601 },
    { lat: 47.94393, lng: 18.167069 },
  ],
  Lipové: [
    { lat: 47.8744053, lng: 17.9011128 },
    { lat: 47.8743891, lng: 17.9010869 },
    { lat: 47.8730006, lng: 17.8987026 },
    { lat: 47.8696283, lng: 17.8942435 },
    { lat: 47.8735899, lng: 17.8895525 },
    { lat: 47.8745073, lng: 17.8883125 },
    { lat: 47.8440343, lng: 17.8544023 },
    { lat: 47.8292774, lng: 17.8411694 },
    { lat: 47.8289508, lng: 17.8422248 },
    { lat: 47.8265727, lng: 17.840767 },
    { lat: 47.8236644, lng: 17.8386566 },
    { lat: 47.8235545, lng: 17.8392184 },
    { lat: 47.8230623, lng: 17.8402622 },
    { lat: 47.8222327, lng: 17.8410312 },
    { lat: 47.8213686, lng: 17.8420476 },
    { lat: 47.8208804, lng: 17.8429531 },
    { lat: 47.8204578, lng: 17.8436038 },
    { lat: 47.8199418, lng: 17.8440238 },
    { lat: 47.8159975, lng: 17.8460376 },
    { lat: 47.8155404, lng: 17.8443708 },
    { lat: 47.8131622, lng: 17.8437361 },
    { lat: 47.8122939, lng: 17.8446657 },
    { lat: 47.8122184, lng: 17.8446293 },
    { lat: 47.812194, lng: 17.8447864 },
    { lat: 47.8117412, lng: 17.8451314 },
    { lat: 47.8116876, lng: 17.8451218 },
    { lat: 47.8107236, lng: 17.8462944 },
    { lat: 47.8095435, lng: 17.8475296 },
    { lat: 47.8087771, lng: 17.8478789 },
    { lat: 47.8086697, lng: 17.8482239 },
    { lat: 47.808328, lng: 17.8487833 },
    { lat: 47.8085965, lng: 17.8487599 },
    { lat: 47.8085996, lng: 17.8491748 },
    { lat: 47.8083232, lng: 17.8504783 },
    { lat: 47.8077813, lng: 17.8521099 },
    { lat: 47.8077538, lng: 17.8525427 },
    { lat: 47.8075834, lng: 17.8531666 },
    { lat: 47.8075588, lng: 17.8532244 },
    { lat: 47.8074414, lng: 17.8531497 },
    { lat: 47.8070329, lng: 17.8546009 },
    { lat: 47.8072076, lng: 17.8547071 },
    { lat: 47.8068034, lng: 17.8560412 },
    { lat: 47.807433, lng: 17.8566229 },
    { lat: 47.8087831, lng: 17.8580739 },
    { lat: 47.8116939, lng: 17.8585779 },
    { lat: 47.8126727, lng: 17.8588352 },
    { lat: 47.8140399, lng: 17.8593335 },
    { lat: 47.8143203, lng: 17.858756 },
    { lat: 47.8151579, lng: 17.8592317 },
    { lat: 47.8152993, lng: 17.8587616 },
    { lat: 47.8162661, lng: 17.8566612 },
    { lat: 47.8168396, lng: 17.8552871 },
    { lat: 47.8173783, lng: 17.8544277 },
    { lat: 47.8178604, lng: 17.8533493 },
    { lat: 47.819055, lng: 17.8510275 },
    { lat: 47.8195503, lng: 17.8501633 },
    { lat: 47.8198593, lng: 17.8497908 },
    { lat: 47.820786, lng: 17.8490186 },
    { lat: 47.822705, lng: 17.8509784 },
    { lat: 47.8258156, lng: 17.8538109 },
    { lat: 47.8273872, lng: 17.855996 },
    { lat: 47.8286163, lng: 17.8575686 },
    { lat: 47.8346068, lng: 17.8647383 },
    { lat: 47.8358943, lng: 17.8664054 },
    { lat: 47.8384252, lng: 17.8700404 },
    { lat: 47.8393426, lng: 17.8710857 },
    { lat: 47.8423388, lng: 17.8748409 },
    { lat: 47.8449626, lng: 17.8784723 },
    { lat: 47.8489553, lng: 17.8833895 },
    { lat: 47.8512228, lng: 17.886515 },
    { lat: 47.8541818, lng: 17.8894528 },
    { lat: 47.8548765, lng: 17.8903057 },
    { lat: 47.8570338, lng: 17.8934636 },
    { lat: 47.858653, lng: 17.897919 },
    { lat: 47.8588494, lng: 17.8993747 },
    { lat: 47.8605483, lng: 17.9022946 },
    { lat: 47.8608682, lng: 17.9032257 },
    { lat: 47.8608042, lng: 17.9034705 },
    { lat: 47.8609551, lng: 17.9039078 },
    { lat: 47.8616127, lng: 17.9052872 },
    { lat: 47.8624136, lng: 17.9067381 },
    { lat: 47.8629633, lng: 17.9073659 },
    { lat: 47.8631585, lng: 17.9076605 },
    { lat: 47.8630841, lng: 17.9078487 },
    { lat: 47.8630757, lng: 17.9080056 },
    { lat: 47.8631606, lng: 17.908064 },
    { lat: 47.8632975, lng: 17.9079964 },
    { lat: 47.8635846, lng: 17.9085928 },
    { lat: 47.863992, lng: 17.9082412 },
    { lat: 47.8643491, lng: 17.9080275 },
    { lat: 47.8646142, lng: 17.9079489 },
    { lat: 47.8655618, lng: 17.9080686 },
    { lat: 47.8663846, lng: 17.9082529 },
    { lat: 47.8671321, lng: 17.9082586 },
    { lat: 47.8683055, lng: 17.9077847 },
    { lat: 47.8691949, lng: 17.9065462 },
    { lat: 47.8702644, lng: 17.9052421 },
    { lat: 47.8707303, lng: 17.9056444 },
    { lat: 47.8711299, lng: 17.9051264 },
    { lat: 47.8713455, lng: 17.9047215 },
    { lat: 47.8716135, lng: 17.9037847 },
    { lat: 47.871857, lng: 17.9034298 },
    { lat: 47.8720923, lng: 17.9029417 },
    { lat: 47.8741525, lng: 17.901315 },
    { lat: 47.8744053, lng: 17.9011128 },
  ],
  BátoroveKosihy: [
    { lat: 47.867204, lng: 18.415431 },
    { lat: 47.867078, lng: 18.415293 },
    { lat: 47.866916, lng: 18.415109 },
    { lat: 47.866847, lng: 18.415028 },
    { lat: 47.866663, lng: 18.414871 },
    { lat: 47.866576, lng: 18.414797 },
    { lat: 47.866466, lng: 18.414702 },
    { lat: 47.866365, lng: 18.414616 },
    { lat: 47.86619, lng: 18.414467 },
    { lat: 47.866071, lng: 18.414364 },
    { lat: 47.865869, lng: 18.41419 },
    { lat: 47.865644, lng: 18.413996 },
    { lat: 47.865528, lng: 18.413893 },
    { lat: 47.865494, lng: 18.413802 },
    { lat: 47.865483, lng: 18.413782 },
    { lat: 47.865533, lng: 18.413476 },
    { lat: 47.865615, lng: 18.412942 },
    { lat: 47.865682, lng: 18.412506 },
    { lat: 47.865703, lng: 18.412377 },
    { lat: 47.865807, lng: 18.4117 },
    { lat: 47.865878, lng: 18.411217 },
    { lat: 47.865971, lng: 18.410589 },
    { lat: 47.866035, lng: 18.410185 },
    { lat: 47.866103, lng: 18.409741 },
    { lat: 47.866142, lng: 18.409465 },
    { lat: 47.866238, lng: 18.408753 },
    { lat: 47.866314, lng: 18.408165 },
    { lat: 47.866354, lng: 18.407862 },
    { lat: 47.86645, lng: 18.407142 },
    { lat: 47.866483, lng: 18.406889 },
    { lat: 47.866492, lng: 18.406795 },
    { lat: 47.866582, lng: 18.406302 },
    { lat: 47.866612, lng: 18.406127 },
    { lat: 47.866624, lng: 18.406063 },
    { lat: 47.866711, lng: 18.405565 },
    { lat: 47.866759, lng: 18.405277 },
    { lat: 47.866803, lng: 18.405013 },
    { lat: 47.866833, lng: 18.40484 },
    { lat: 47.866905, lng: 18.404423 },
    { lat: 47.866994, lng: 18.403918 },
    { lat: 47.867034, lng: 18.403696 },
    { lat: 47.867057, lng: 18.403568 },
    { lat: 47.86712, lng: 18.403208 },
    { lat: 47.867139, lng: 18.403088 },
    { lat: 47.867197, lng: 18.402741 },
    { lat: 47.867297, lng: 18.402147 },
    { lat: 47.867351, lng: 18.401827 },
    { lat: 47.867468, lng: 18.40113 },
    { lat: 47.867549, lng: 18.400718 },
    { lat: 47.86772, lng: 18.399858 },
    { lat: 47.867834, lng: 18.399283 },
    { lat: 47.867906, lng: 18.398859 },
    { lat: 47.868045, lng: 18.398014 },
    { lat: 47.868159, lng: 18.397317 },
    { lat: 47.868291, lng: 18.396492 },
    { lat: 47.868311, lng: 18.396366 },
    { lat: 47.868435, lng: 18.395457 },
    { lat: 47.868488, lng: 18.395049 },
    { lat: 47.868517, lng: 18.39482 },
    { lat: 47.868542, lng: 18.394662 },
    { lat: 47.868654, lng: 18.394012 },
    { lat: 47.868772, lng: 18.393319 },
    { lat: 47.868925, lng: 18.392395 },
    { lat: 47.869042, lng: 18.391685 },
    { lat: 47.869111, lng: 18.391268 },
    { lat: 47.869152, lng: 18.391041 },
    { lat: 47.869285, lng: 18.390211 },
    { lat: 47.869334, lng: 18.389888 },
    { lat: 47.869357, lng: 18.38974 },
    { lat: 47.869465, lng: 18.38904 },
    { lat: 47.869557, lng: 18.388468 },
    { lat: 47.869608, lng: 18.388143 },
    { lat: 47.86967, lng: 18.387717 },
    { lat: 47.869736, lng: 18.387261 },
    { lat: 47.869834, lng: 18.386578 },
    { lat: 47.869948, lng: 18.385802 },
    { lat: 47.870038, lng: 18.385198 },
    { lat: 47.870104, lng: 18.38478 },
    { lat: 47.870216, lng: 18.384074 },
    { lat: 47.870301, lng: 18.383532 },
    { lat: 47.870389, lng: 18.38297 },
    { lat: 47.870444, lng: 18.382628 },
    { lat: 47.870509, lng: 18.382248 },
    { lat: 47.870584, lng: 18.381735 },
    { lat: 47.870733, lng: 18.380712 },
    { lat: 47.870878, lng: 18.379701 },
    { lat: 47.870977, lng: 18.379038 },
    { lat: 47.870999, lng: 18.378934 },
    { lat: 47.871121, lng: 18.378334 },
    { lat: 47.871254, lng: 18.377681 },
    { lat: 47.871385, lng: 18.377029 },
    { lat: 47.871406, lng: 18.37694 },
    { lat: 47.871494, lng: 18.376328 },
    { lat: 47.871604, lng: 18.375596 },
    { lat: 47.871656, lng: 18.375243 },
    { lat: 47.871676, lng: 18.375083 },
    { lat: 47.871692, lng: 18.374991 },
    { lat: 47.871766, lng: 18.374479 },
    { lat: 47.871891, lng: 18.37361 },
    { lat: 47.871927, lng: 18.373361 },
    { lat: 47.871957, lng: 18.37318 },
    { lat: 47.872062, lng: 18.372591 },
    { lat: 47.872166, lng: 18.372 },
    { lat: 47.872323, lng: 18.371127 },
    { lat: 47.872343, lng: 18.371013 },
    { lat: 47.872406, lng: 18.370542 },
    { lat: 47.872534, lng: 18.369577 },
    { lat: 47.872676, lng: 18.368531 },
    { lat: 47.872738, lng: 18.368087 },
    { lat: 47.872832, lng: 18.367398 },
    { lat: 47.873211, lng: 18.366959 },
    { lat: 47.873778, lng: 18.366304 },
    { lat: 47.873912, lng: 18.366147 },
    { lat: 47.873946, lng: 18.366159 },
    { lat: 47.874144, lng: 18.366195 },
    { lat: 47.874784, lng: 18.36631 },
    { lat: 47.87501, lng: 18.365907 },
    { lat: 47.875232, lng: 18.365519 },
    { lat: 47.875334, lng: 18.365335 },
    { lat: 47.875371, lng: 18.365275 },
    { lat: 47.875463, lng: 18.365124 },
    { lat: 47.875667, lng: 18.364783 },
    { lat: 47.875929, lng: 18.364347 },
    { lat: 47.876092, lng: 18.364073 },
    { lat: 47.876276, lng: 18.363765 },
    { lat: 47.876504, lng: 18.363397 },
    { lat: 47.876607, lng: 18.363225 },
    { lat: 47.876791, lng: 18.362918 },
    { lat: 47.876903, lng: 18.362727 },
    { lat: 47.877042, lng: 18.362489 },
    { lat: 47.877195, lng: 18.362232 },
    { lat: 47.877322, lng: 18.362014 },
    { lat: 47.8775, lng: 18.361709 },
    { lat: 47.877633, lng: 18.361482 },
    { lat: 47.877803, lng: 18.36119 },
    { lat: 47.877952, lng: 18.360932 },
    { lat: 47.878018, lng: 18.360819 },
    { lat: 47.878158, lng: 18.360576 },
    { lat: 47.87828, lng: 18.360368 },
    { lat: 47.8783, lng: 18.360336 },
    { lat: 47.87836, lng: 18.360232 },
    { lat: 47.878468, lng: 18.360054 },
    { lat: 47.878482, lng: 18.36002 },
    { lat: 47.8712335, lng: 18.3544686 },
    { lat: 47.8703355, lng: 18.35354 },
    { lat: 47.8695037, lng: 18.3521147 },
    { lat: 47.8681193, lng: 18.350374 },
    { lat: 47.8667802, lng: 18.3477708 },
    { lat: 47.8657313, lng: 18.3454066 },
    { lat: 47.8651434, lng: 18.3435927 },
    { lat: 47.8643361, lng: 18.3407013 },
    { lat: 47.8633556, lng: 18.3386666 },
    { lat: 47.8622047, lng: 18.3366556 },
    { lat: 47.8611304, lng: 18.3341917 },
    { lat: 47.8603021, lng: 18.3321377 },
    { lat: 47.8602704, lng: 18.3320598 },
    { lat: 47.8598431, lng: 18.3334039 },
    { lat: 47.8595332, lng: 18.3345532 },
    { lat: 47.8592795, lng: 18.3349116 },
    { lat: 47.8587469, lng: 18.3359124 },
    { lat: 47.8579521, lng: 18.3370285 },
    { lat: 47.8572243, lng: 18.3375016 },
    { lat: 47.8569478, lng: 18.3378331 },
    { lat: 47.8567645, lng: 18.3381207 },
    { lat: 47.8565117, lng: 18.3387116 },
    { lat: 47.8558692, lng: 18.3397454 },
    { lat: 47.8550111, lng: 18.3406614 },
    { lat: 47.8543337, lng: 18.3412258 },
    { lat: 47.853763, lng: 18.3418381 },
    { lat: 47.8532023, lng: 18.3431215 },
    { lat: 47.8528318, lng: 18.344241 },
    { lat: 47.8527027, lng: 18.3444891 },
    { lat: 47.8524611, lng: 18.3447824 },
    { lat: 47.8518879, lng: 18.3462611 },
    { lat: 47.8514113, lng: 18.3478127 },
    { lat: 47.8499763, lng: 18.3505363 },
    { lat: 47.8495608, lng: 18.3511637 },
    { lat: 47.8491591, lng: 18.3515189 },
    { lat: 47.8487717, lng: 18.3520257 },
    { lat: 47.8486088, lng: 18.3524512 },
    { lat: 47.8482591, lng: 18.3531265 },
    { lat: 47.8480023, lng: 18.3540028 },
    { lat: 47.8478065, lng: 18.3544651 },
    { lat: 47.847573, lng: 18.3554931 },
    { lat: 47.8474828, lng: 18.356937 },
    { lat: 47.8472952, lng: 18.3585134 },
    { lat: 47.8471368, lng: 18.3589604 },
    { lat: 47.846468, lng: 18.3601699 },
    { lat: 47.8458592, lng: 18.3610484 },
    { lat: 47.8449665, lng: 18.3616953 },
    { lat: 47.8443205, lng: 18.3619217 },
    { lat: 47.8437514, lng: 18.3623687 },
    { lat: 47.8434279, lng: 18.3629067 },
    { lat: 47.8424804, lng: 18.3633849 },
    { lat: 47.840716, lng: 18.3634289 },
    { lat: 47.8402505, lng: 18.3633389 },
    { lat: 47.8397604, lng: 18.3636253 },
    { lat: 47.8382865, lng: 18.3640538 },
    { lat: 47.8377189, lng: 18.3640304 },
    { lat: 47.8372824, lng: 18.3640913 },
    { lat: 47.8362819, lng: 18.3639967 },
    { lat: 47.8356815, lng: 18.3640736 },
    { lat: 47.8348439, lng: 18.3643019 },
    { lat: 47.8341175, lng: 18.3648582 },
    { lat: 47.8326538, lng: 18.3651901 },
    { lat: 47.8322743, lng: 18.3654384 },
    { lat: 47.8314732, lng: 18.3662325 },
    { lat: 47.8302556, lng: 18.3672557 },
    { lat: 47.8297016, lng: 18.3679461 },
    { lat: 47.8288991, lng: 18.3686133 },
    { lat: 47.8270084, lng: 18.3710139 },
    { lat: 47.8233289, lng: 18.3746497 },
    { lat: 47.8216601, lng: 18.3764122 },
    { lat: 47.821051, lng: 18.3769447 },
    { lat: 47.818315, lng: 18.3803556 },
    { lat: 47.817701, lng: 18.380824 },
    { lat: 47.8171484, lng: 18.3807304 },
    { lat: 47.8157552, lng: 18.3810094 },
    { lat: 47.814788, lng: 18.3813827 },
    { lat: 47.8139691, lng: 18.3819561 },
    { lat: 47.8135117, lng: 18.3825634 },
    { lat: 47.8130243, lng: 18.3830018 },
    { lat: 47.8124093, lng: 18.3838317 },
    { lat: 47.8115446, lng: 18.3854286 },
    { lat: 47.8108515, lng: 18.3860787 },
    { lat: 47.8096142, lng: 18.3870521 },
    { lat: 47.8092028, lng: 18.3875388 },
    { lat: 47.8071747, lng: 18.3903628 },
    { lat: 47.80665, lng: 18.3909562 },
    { lat: 47.8047391, lng: 18.393482 },
    { lat: 47.8037803, lng: 18.3949526 },
    { lat: 47.8029457, lng: 18.3959915 },
    { lat: 47.8023591, lng: 18.3969859 },
    { lat: 47.802317, lng: 18.3970603 },
    { lat: 47.801191, lng: 18.3998426 },
    { lat: 47.7957757, lng: 18.4083486 },
    { lat: 47.7957434, lng: 18.4083961 },
    { lat: 47.798279, lng: 18.4097002 },
    { lat: 47.7982712, lng: 18.4098057 },
    { lat: 47.8004382, lng: 18.4122474 },
    { lat: 47.8036632, lng: 18.4163686 },
    { lat: 47.8099828, lng: 18.4239789 },
    { lat: 47.8115044, lng: 18.4263898 },
    { lat: 47.8143074, lng: 18.4305758 },
    { lat: 47.8158169, lng: 18.4334951 },
    { lat: 47.8148975, lng: 18.4350736 },
    { lat: 47.8135962, lng: 18.4366631 },
    { lat: 47.8134617, lng: 18.4369185 },
    { lat: 47.8143679, lng: 18.4382872 },
    { lat: 47.8149359, lng: 18.4395127 },
    { lat: 47.8153804, lng: 18.4400766 },
    { lat: 47.8157713, lng: 18.4403639 },
    { lat: 47.8159412, lng: 18.4402571 },
    { lat: 47.816112, lng: 18.4402861 },
    { lat: 47.8168535, lng: 18.4400907 },
    { lat: 47.8172619, lng: 18.4401526 },
    { lat: 47.817566, lng: 18.4404009 },
    { lat: 47.8177287, lng: 18.4407453 },
    { lat: 47.818148, lng: 18.4421617 },
    { lat: 47.8183571, lng: 18.4426092 },
    { lat: 47.818779, lng: 18.4440302 },
    { lat: 47.8187205, lng: 18.4445152 },
    { lat: 47.8187806, lng: 18.4452409 },
    { lat: 47.8186818, lng: 18.4465718 },
    { lat: 47.8187098, lng: 18.4468899 },
    { lat: 47.8189285, lng: 18.4474653 },
    { lat: 47.8192526, lng: 18.4480937 },
    { lat: 47.8193567, lng: 18.4484988 },
    { lat: 47.8197163, lng: 18.4491962 },
    { lat: 47.8197426, lng: 18.4494993 },
    { lat: 47.8201474, lng: 18.4498594 },
    { lat: 47.8204327, lng: 18.4506987 },
    { lat: 47.8206059, lng: 18.4509692 },
    { lat: 47.8205719, lng: 18.4510668 },
    { lat: 47.8225258, lng: 18.4541354 },
    { lat: 47.8219454, lng: 18.4552488 },
    { lat: 47.8243494, lng: 18.459139 },
    { lat: 47.8269553, lng: 18.4646283 },
    { lat: 47.827356, lng: 18.4655102 },
    { lat: 47.8302106, lng: 18.4730808 },
    { lat: 47.8324844, lng: 18.4780445 },
    { lat: 47.8325969, lng: 18.4782409 },
    { lat: 47.833178, lng: 18.478771 },
    { lat: 47.833229, lng: 18.478825 },
    { lat: 47.836853, lng: 18.476824 },
    { lat: 47.837559, lng: 18.476426 },
    { lat: 47.837586, lng: 18.476525 },
    { lat: 47.837744, lng: 18.477104 },
    { lat: 47.83785, lng: 18.4773 },
    { lat: 47.837915, lng: 18.477206 },
    { lat: 47.837966, lng: 18.477212 },
    { lat: 47.838065, lng: 18.476993 },
    { lat: 47.838316, lng: 18.476374 },
    { lat: 47.839475, lng: 18.473901 },
    { lat: 47.83958, lng: 18.473739 },
    { lat: 47.840567, lng: 18.472158 },
    { lat: 47.840616, lng: 18.472072 },
    { lat: 47.840762, lng: 18.471768 },
    { lat: 47.841109, lng: 18.470964 },
    { lat: 47.841126, lng: 18.47098 },
    { lat: 47.841156, lng: 18.470918 },
    { lat: 47.841223, lng: 18.470753 },
    { lat: 47.841329, lng: 18.470351 },
    { lat: 47.841425, lng: 18.469895 },
    { lat: 47.841636, lng: 18.468788 },
    { lat: 47.841649, lng: 18.468639 },
    { lat: 47.841757, lng: 18.468344 },
    { lat: 47.841857, lng: 18.46807 },
    { lat: 47.841716, lng: 18.467868 },
    { lat: 47.842095, lng: 18.467399 },
    { lat: 47.842208, lng: 18.467102 },
    { lat: 47.842402, lng: 18.466396 },
    { lat: 47.84245, lng: 18.46625 },
    { lat: 47.842519, lng: 18.46609 },
    { lat: 47.842899, lng: 18.465402 },
    { lat: 47.842912, lng: 18.465353 },
    { lat: 47.842946, lng: 18.464647 },
    { lat: 47.842977, lng: 18.463269 },
    { lat: 47.84316, lng: 18.462289 },
    { lat: 47.843212, lng: 18.462153 },
    { lat: 47.843364, lng: 18.461649 },
    { lat: 47.843378, lng: 18.461641 },
    { lat: 47.843355, lng: 18.461496 },
    { lat: 47.843342, lng: 18.461408 },
    { lat: 47.8433, lng: 18.461131 },
    { lat: 47.843236, lng: 18.460677 },
    { lat: 47.843218, lng: 18.460554 },
    { lat: 47.843161, lng: 18.460123 },
    { lat: 47.843083, lng: 18.459558 },
    { lat: 47.843091, lng: 18.459536 },
    { lat: 47.843211, lng: 18.459225 },
    { lat: 47.843271, lng: 18.459072 },
    { lat: 47.843455, lng: 18.458607 },
    { lat: 47.843645, lng: 18.458123 },
    { lat: 47.843671, lng: 18.458063 },
    { lat: 47.843693, lng: 18.457977 },
    { lat: 47.843803, lng: 18.457504 },
    { lat: 47.843911, lng: 18.457033 },
    { lat: 47.843971, lng: 18.456784 },
    { lat: 47.844071, lng: 18.45634 },
    { lat: 47.844161, lng: 18.455956 },
    { lat: 47.844232, lng: 18.455655 },
    { lat: 47.844276, lng: 18.45547 },
    { lat: 47.844326, lng: 18.455258 },
    { lat: 47.844396, lng: 18.454957 },
    { lat: 47.844445, lng: 18.454747 },
    { lat: 47.844504, lng: 18.454491 },
    { lat: 47.844628, lng: 18.454145 },
    { lat: 47.844738, lng: 18.453846 },
    { lat: 47.844784, lng: 18.453703 },
    { lat: 47.844823, lng: 18.453513 },
    { lat: 47.844873, lng: 18.453427 },
    { lat: 47.845072, lng: 18.453109 },
    { lat: 47.845124, lng: 18.453005 },
    { lat: 47.845274, lng: 18.452696 },
    { lat: 47.845402, lng: 18.452438 },
    { lat: 47.84551, lng: 18.452179 },
    { lat: 47.845614, lng: 18.451976 },
    { lat: 47.845687, lng: 18.451828 },
    { lat: 47.845785, lng: 18.451635 },
    { lat: 47.845869, lng: 18.451471 },
    { lat: 47.845891, lng: 18.451427 },
    { lat: 47.845996, lng: 18.451221 },
    { lat: 47.846075, lng: 18.451102 },
    { lat: 47.846138, lng: 18.451008 },
    { lat: 47.846333, lng: 18.450718 },
    { lat: 47.846459, lng: 18.450532 },
    { lat: 47.846526, lng: 18.45043 },
    { lat: 47.846553, lng: 18.450398 },
    { lat: 47.846656, lng: 18.450266 },
    { lat: 47.846777, lng: 18.450132 },
    { lat: 47.846962, lng: 18.449927 },
    { lat: 47.84702, lng: 18.449863 },
    { lat: 47.847054, lng: 18.449816 },
    { lat: 47.847183, lng: 18.449629 },
    { lat: 47.847324, lng: 18.449423 },
    { lat: 47.847499, lng: 18.449173 },
    { lat: 47.847698, lng: 18.44889 },
    { lat: 47.847914, lng: 18.44858 },
    { lat: 47.848135, lng: 18.44826 },
    { lat: 47.848333, lng: 18.447977 },
    { lat: 47.848363, lng: 18.44794 },
    { lat: 47.848594, lng: 18.447577 },
    { lat: 47.848716, lng: 18.447386 },
    { lat: 47.848899, lng: 18.447099 },
    { lat: 47.849023, lng: 18.446905 },
    { lat: 47.849248, lng: 18.446555 },
    { lat: 47.849279, lng: 18.446506 },
    { lat: 47.849377, lng: 18.44636 },
    { lat: 47.84952, lng: 18.446151 },
    { lat: 47.849795, lng: 18.445749 },
    { lat: 47.849884, lng: 18.445617 },
    { lat: 47.850061, lng: 18.445337 },
    { lat: 47.850277, lng: 18.444993 },
    { lat: 47.850446, lng: 18.444725 },
    { lat: 47.850668, lng: 18.444373 },
    { lat: 47.850842, lng: 18.444097 },
    { lat: 47.851047, lng: 18.44377 },
    { lat: 47.851207, lng: 18.443517 },
    { lat: 47.851376, lng: 18.443249 },
    { lat: 47.851388, lng: 18.443227 },
    { lat: 47.851365, lng: 18.443195 },
    { lat: 47.851091, lng: 18.44284 },
    { lat: 47.851096, lng: 18.442828 },
    { lat: 47.85114, lng: 18.442751 },
    { lat: 47.851149, lng: 18.442739 },
    { lat: 47.851165, lng: 18.442709 },
    { lat: 47.851212, lng: 18.442627 },
    { lat: 47.851414, lng: 18.442293 },
    { lat: 47.851529, lng: 18.442094 },
    { lat: 47.851715, lng: 18.441775 },
    { lat: 47.851861, lng: 18.441532 },
    { lat: 47.851972, lng: 18.441341 },
    { lat: 47.852133, lng: 18.441071 },
    { lat: 47.852316, lng: 18.440764 },
    { lat: 47.852386, lng: 18.440645 },
    { lat: 47.85249, lng: 18.440466 },
    { lat: 47.85259, lng: 18.440297 },
    { lat: 47.852692, lng: 18.440122 },
    { lat: 47.852792, lng: 18.439953 },
    { lat: 47.852933, lng: 18.439715 },
    { lat: 47.853141, lng: 18.439363 },
    { lat: 47.853219, lng: 18.439234 },
    { lat: 47.85346, lng: 18.438833 },
    { lat: 47.853666, lng: 18.438482 },
    { lat: 47.85386, lng: 18.438143 },
    { lat: 47.853932, lng: 18.438023 },
    { lat: 47.854153, lng: 18.437648 },
    { lat: 47.854281, lng: 18.437429 },
    { lat: 47.854413, lng: 18.437204 },
    { lat: 47.854543, lng: 18.436983 },
    { lat: 47.854629, lng: 18.436837 },
    { lat: 47.854723, lng: 18.436679 },
    { lat: 47.854801, lng: 18.436547 },
    { lat: 47.855076, lng: 18.436075 },
    { lat: 47.855251, lng: 18.43578 },
    { lat: 47.855565, lng: 18.435245 },
    { lat: 47.855781, lng: 18.43488 },
    { lat: 47.855922, lng: 18.434638 },
    { lat: 47.855987, lng: 18.434527 },
    { lat: 47.856092, lng: 18.434348 },
    { lat: 47.856191, lng: 18.43418 },
    { lat: 47.856288, lng: 18.434015 },
    { lat: 47.85632, lng: 18.433962 },
    { lat: 47.856452, lng: 18.433736 },
    { lat: 47.85652, lng: 18.43362 },
    { lat: 47.85673, lng: 18.433263 },
    { lat: 47.856867, lng: 18.433026 },
    { lat: 47.856971, lng: 18.432854 },
    { lat: 47.857154, lng: 18.43254 },
    { lat: 47.857365, lng: 18.432185 },
    { lat: 47.857529, lng: 18.431904 },
    { lat: 47.857688, lng: 18.431635 },
    { lat: 47.857808, lng: 18.43143 },
    { lat: 47.857989, lng: 18.431125 },
    { lat: 47.858399, lng: 18.430427 },
    { lat: 47.85873, lng: 18.42986 },
    { lat: 47.858938, lng: 18.429509 },
    { lat: 47.859223, lng: 18.429024 },
    { lat: 47.859333, lng: 18.428848 },
    { lat: 47.859437, lng: 18.428664 },
    { lat: 47.859618, lng: 18.428358 },
    { lat: 47.859826, lng: 18.428 },
    { lat: 47.859914, lng: 18.427855 },
    { lat: 47.860264, lng: 18.427255 },
    { lat: 47.860511, lng: 18.426838 },
    { lat: 47.860713, lng: 18.426493 },
    { lat: 47.860776, lng: 18.426387 },
    { lat: 47.860905, lng: 18.426168 },
    { lat: 47.861321, lng: 18.425457 },
    { lat: 47.86163, lng: 18.424934 },
    { lat: 47.861925, lng: 18.424428 },
    { lat: 47.86219, lng: 18.423979 },
    { lat: 47.862489, lng: 18.423468 },
    { lat: 47.862656, lng: 18.423185 },
    { lat: 47.862824, lng: 18.422898 },
    { lat: 47.863, lng: 18.4226 },
    { lat: 47.863262, lng: 18.422151 },
    { lat: 47.863284, lng: 18.422114 },
    { lat: 47.863425, lng: 18.42187 },
    { lat: 47.863671, lng: 18.421455 },
    { lat: 47.864095, lng: 18.420735 },
    { lat: 47.864505, lng: 18.420031 },
    { lat: 47.864809, lng: 18.419513 },
    { lat: 47.865017, lng: 18.41916 },
    { lat: 47.865186, lng: 18.418871 },
    { lat: 47.865354, lng: 18.418582 },
    { lat: 47.86551, lng: 18.418313 },
    { lat: 47.865757, lng: 18.417896 },
    { lat: 47.86599, lng: 18.417499 },
    { lat: 47.866024, lng: 18.417442 },
    { lat: 47.866183, lng: 18.417171 },
    { lat: 47.86625, lng: 18.417055 },
    { lat: 47.86649, lng: 18.416647 },
    { lat: 47.866715, lng: 18.416262 },
    { lat: 47.866835, lng: 18.416057 },
    { lat: 47.867015, lng: 18.415749 },
    { lat: 47.867148, lng: 18.415524 },
    { lat: 47.867204, lng: 18.415431 },
  ],
  Virt: [
    { lat: 47.7482239, lng: 18.3210474 },
    { lat: 47.7468075, lng: 18.3226308 },
    { lat: 47.7463062, lng: 18.3233104 },
    { lat: 47.7459775, lng: 18.3238742 },
    { lat: 47.74567, lng: 18.324862 },
    { lat: 47.7454129, lng: 18.3254932 },
    { lat: 47.7451316, lng: 18.3260173 },
    { lat: 47.7448478, lng: 18.3267525 },
    { lat: 47.7448242, lng: 18.3269339 },
    { lat: 47.7444869, lng: 18.3274245 },
    { lat: 47.7444642, lng: 18.327462 },
    { lat: 47.7447409, lng: 18.3278001 },
    { lat: 47.7456267, lng: 18.3291569 },
    { lat: 47.7477738, lng: 18.3316922 },
    { lat: 47.7477449, lng: 18.33174 },
    { lat: 47.7492778, lng: 18.3332464 },
    { lat: 47.7506995, lng: 18.3344681 },
    { lat: 47.7519436, lng: 18.3356377 },
    { lat: 47.7538718, lng: 18.3376273 },
    { lat: 47.7567262, lng: 18.3312769 },
    { lat: 47.7595284, lng: 18.3341208 },
    { lat: 47.7595711, lng: 18.3340565 },
    { lat: 47.7604756, lng: 18.3343287 },
    { lat: 47.7634049, lng: 18.3297522 },
    { lat: 47.7662659, lng: 18.3327962 },
    { lat: 47.7687302, lng: 18.3292598 },
    { lat: 47.7700833, lng: 18.3311352 },
    { lat: 47.770982, lng: 18.3300653 },
    { lat: 47.7713436, lng: 18.3293866 },
    { lat: 47.7714097, lng: 18.3294761 },
    { lat: 47.7727083, lng: 18.3280618 },
    { lat: 47.7732643, lng: 18.3288438 },
    { lat: 47.7736867, lng: 18.3285172 },
    { lat: 47.7740661, lng: 18.3281287 },
    { lat: 47.7749879, lng: 18.3269498 },
    { lat: 47.7750708, lng: 18.3268415 },
    { lat: 47.7742767, lng: 18.3249981 },
    { lat: 47.774196, lng: 18.324713 },
    { lat: 47.7741473, lng: 18.3242776 },
    { lat: 47.7740703, lng: 18.3241541 },
    { lat: 47.7732636, lng: 18.3234091 },
    { lat: 47.7719447, lng: 18.321977 },
    { lat: 47.7709939, lng: 18.3208431 },
    { lat: 47.7704491, lng: 18.3201012 },
    { lat: 47.7700823, lng: 18.3193133 },
    { lat: 47.7704249, lng: 18.3185419 },
    { lat: 47.7700033, lng: 18.3166832 },
    { lat: 47.7698211, lng: 18.3163422 },
    { lat: 47.768851, lng: 18.3149529 },
    { lat: 47.767048, lng: 18.3121309 },
    { lat: 47.7663411, lng: 18.3112357 },
    { lat: 47.7664872, lng: 18.310324 },
    { lat: 47.765901, lng: 18.3094529 },
    { lat: 47.7669497, lng: 18.3080509 },
    { lat: 47.7665315, lng: 18.3074116 },
    { lat: 47.7660588, lng: 18.3065528 },
    { lat: 47.7656988, lng: 18.3056888 },
    { lat: 47.765186, lng: 18.3046593 },
    { lat: 47.7641536, lng: 18.302917 },
    { lat: 47.7640921, lng: 18.3021249 },
    { lat: 47.7630311, lng: 18.2991839 },
    { lat: 47.7620957, lng: 18.2998096 },
    { lat: 47.7613196, lng: 18.3009267 },
    { lat: 47.7605321, lng: 18.3018457 },
    { lat: 47.7598417, lng: 18.3029238 },
    { lat: 47.7593618, lng: 18.3040731 },
    { lat: 47.7589755, lng: 18.3052009 },
    { lat: 47.758121, lng: 18.3067381 },
    { lat: 47.7576171, lng: 18.3073931 },
    { lat: 47.7569986, lng: 18.308801 },
    { lat: 47.7556151, lng: 18.3109333 },
    { lat: 47.755104, lng: 18.3111674 },
    { lat: 47.7545636, lng: 18.3116369 },
    { lat: 47.7542987, lng: 18.3120344 },
    { lat: 47.7536852, lng: 18.3130976 },
    { lat: 47.7530563, lng: 18.3148509 },
    { lat: 47.7528215, lng: 18.315262 },
    { lat: 47.7522574, lng: 18.3158116 },
    { lat: 47.7514039, lng: 18.3160392 },
    { lat: 47.7510741, lng: 18.3158948 },
    { lat: 47.7508582, lng: 18.315906 },
    { lat: 47.7506041, lng: 18.3159816 },
    { lat: 47.7502552, lng: 18.31639 },
    { lat: 47.7492491, lng: 18.3191973 },
    { lat: 47.7485501, lng: 18.3206595 },
    { lat: 47.7483357, lng: 18.3209152 },
    { lat: 47.7482239, lng: 18.3210474 },
  ],
  Holiare: [
    { lat: 47.857217, lng: 17.79806 },
    { lat: 47.85706, lng: 17.797933 },
    { lat: 47.856851, lng: 17.797764 },
    { lat: 47.856569, lng: 17.797537 },
    { lat: 47.856519, lng: 17.797496 },
    { lat: 47.856368, lng: 17.797376 },
    { lat: 47.856247, lng: 17.797281 },
    { lat: 47.85624, lng: 17.797275 },
    { lat: 47.856079, lng: 17.797146 },
    { lat: 47.855891, lng: 17.796994 },
    { lat: 47.85554, lng: 17.796729 },
    { lat: 47.855411, lng: 17.796632 },
    { lat: 47.855268, lng: 17.796525 },
    { lat: 47.854812, lng: 17.796188 },
    { lat: 47.854724, lng: 17.796174 },
    { lat: 47.854503, lng: 17.796138 },
    { lat: 47.854394, lng: 17.796121 },
    { lat: 47.854102, lng: 17.796075 },
    { lat: 47.854045, lng: 17.796065 },
    { lat: 47.853973, lng: 17.796018 },
    { lat: 47.853905, lng: 17.795973 },
    { lat: 47.853689, lng: 17.795829 },
    { lat: 47.853683, lng: 17.795825 },
    { lat: 47.853663, lng: 17.795811 },
    { lat: 47.853602, lng: 17.795771 },
    { lat: 47.853586, lng: 17.79576 },
    { lat: 47.853581, lng: 17.795755 },
    { lat: 47.853392, lng: 17.795604 },
    { lat: 47.853082, lng: 17.795521 },
    { lat: 47.852975, lng: 17.795492 },
    { lat: 47.852858, lng: 17.795461 },
    { lat: 47.852751, lng: 17.795432 },
    { lat: 47.852638, lng: 17.795402 },
    { lat: 47.852513, lng: 17.795369 },
    { lat: 47.852476, lng: 17.795359 },
    { lat: 47.852468, lng: 17.795358 },
    { lat: 47.852383, lng: 17.795357 },
    { lat: 47.852196, lng: 17.795353 },
    { lat: 47.852115, lng: 17.795352 },
    { lat: 47.851933, lng: 17.795348 },
    { lat: 47.851762, lng: 17.795344 },
    { lat: 47.851735, lng: 17.795344 },
    { lat: 47.851582, lng: 17.795342 },
    { lat: 47.851568, lng: 17.795343 },
    { lat: 47.851554, lng: 17.795343 },
    { lat: 47.851514, lng: 17.795343 },
    { lat: 47.851506, lng: 17.795342 },
    { lat: 47.851399, lng: 17.79534 },
    { lat: 47.85128, lng: 17.795338 },
    { lat: 47.851219, lng: 17.795342 },
    { lat: 47.851078, lng: 17.795353 },
    { lat: 47.850976, lng: 17.79536 },
    { lat: 47.850634, lng: 17.795387 },
    { lat: 47.850497, lng: 17.795397 },
    { lat: 47.850442, lng: 17.795401 },
    { lat: 47.850396, lng: 17.795404 },
    { lat: 47.850241, lng: 17.795417 },
    { lat: 47.850151, lng: 17.795424 },
    { lat: 47.849933, lng: 17.79544 },
    { lat: 47.849898, lng: 17.795442 },
    { lat: 47.849863, lng: 17.795445 },
    { lat: 47.849726, lng: 17.795492 },
    { lat: 47.849682, lng: 17.795507 },
    { lat: 47.849644, lng: 17.79552 },
    { lat: 47.849502, lng: 17.795568 },
    { lat: 47.849439, lng: 17.795589 },
    { lat: 47.849021, lng: 17.79573 },
    { lat: 47.848776, lng: 17.795812 },
    { lat: 47.848558, lng: 17.795885 },
    { lat: 47.848435, lng: 17.795925 },
    { lat: 47.848338, lng: 17.795958 },
    { lat: 47.848187, lng: 17.796 },
    { lat: 47.847782, lng: 17.796113 },
    { lat: 47.847507, lng: 17.796189 },
    { lat: 47.847436, lng: 17.796209 },
    { lat: 47.847385, lng: 17.796224 },
    { lat: 47.84727, lng: 17.796255 },
    { lat: 47.847207, lng: 17.796273 },
    { lat: 47.847166, lng: 17.796284 },
    { lat: 47.847125, lng: 17.796296 },
    { lat: 47.847061, lng: 17.796314 },
    { lat: 47.847042, lng: 17.796319 },
    { lat: 47.84702, lng: 17.796325 },
    { lat: 47.846984, lng: 17.796335 },
    { lat: 47.846975, lng: 17.796338 },
    { lat: 47.846935, lng: 17.796349 },
    { lat: 47.8467, lng: 17.796415 },
    { lat: 47.846539, lng: 17.796461 },
    { lat: 47.846432, lng: 17.796492 },
    { lat: 47.846367, lng: 17.79651 },
    { lat: 47.846144, lng: 17.796573 },
    { lat: 47.845869, lng: 17.796651 },
    { lat: 47.845755, lng: 17.796683 },
    { lat: 47.845547, lng: 17.79674 },
    { lat: 47.845394, lng: 17.796781 },
    { lat: 47.845352, lng: 17.796793 },
    { lat: 47.845272, lng: 17.796815 },
    { lat: 47.845075, lng: 17.796868 },
    { lat: 47.845015, lng: 17.796884 },
    { lat: 47.844956, lng: 17.796901 },
    { lat: 47.844826, lng: 17.796936 },
    { lat: 47.844775, lng: 17.79695 },
    { lat: 47.84472, lng: 17.796965 },
    { lat: 47.844669, lng: 17.796979 },
    { lat: 47.844643, lng: 17.796986 },
    { lat: 47.844634, lng: 17.796989 },
    { lat: 47.844562, lng: 17.79701 },
    { lat: 47.844534, lng: 17.797018 },
    { lat: 47.844449, lng: 17.797044 },
    { lat: 47.844412, lng: 17.797055 },
    { lat: 47.844156, lng: 17.797131 },
    { lat: 47.844084, lng: 17.797153 },
    { lat: 47.844042, lng: 17.797165 },
    { lat: 47.844013, lng: 17.797177 },
    { lat: 47.843917, lng: 17.797214 },
    { lat: 47.843861, lng: 17.797236 },
    { lat: 47.843733, lng: 17.797286 },
    { lat: 47.843604, lng: 17.797335 },
    { lat: 47.843564, lng: 17.797351 },
    { lat: 47.843526, lng: 17.797365 },
    { lat: 47.843452, lng: 17.797394 },
    { lat: 47.843231, lng: 17.797479 },
    { lat: 47.843156, lng: 17.797509 },
    { lat: 47.842913, lng: 17.797576 },
    { lat: 47.84238, lng: 17.797723 },
    { lat: 47.84221, lng: 17.79777 },
    { lat: 47.842088, lng: 17.797792 },
    { lat: 47.841866, lng: 17.797831 },
    { lat: 47.841598, lng: 17.79788 },
    { lat: 47.841474, lng: 17.797902 },
    { lat: 47.841342, lng: 17.797925 },
    { lat: 47.840989, lng: 17.797987 },
    { lat: 47.840604, lng: 17.798054 },
    { lat: 47.840587, lng: 17.79805 },
    { lat: 47.840226, lng: 17.797969 },
    { lat: 47.839906, lng: 17.797976 },
    { lat: 47.839599, lng: 17.797982 },
    { lat: 47.839222, lng: 17.797989 },
    { lat: 47.839048, lng: 17.797993 },
    { lat: 47.838387, lng: 17.798363 },
    { lat: 47.838255, lng: 17.798438 },
    { lat: 47.837872, lng: 17.798651 },
    { lat: 47.837543, lng: 17.798863 },
    { lat: 47.837257, lng: 17.799047 },
    { lat: 47.836912, lng: 17.79915 },
    { lat: 47.836686, lng: 17.799217 },
    { lat: 47.836614, lng: 17.799239 },
    { lat: 47.83624, lng: 17.799368 },
    { lat: 47.835975, lng: 17.799459 },
    { lat: 47.835852, lng: 17.799507 },
    { lat: 47.835582, lng: 17.79961 },
    { lat: 47.835336, lng: 17.799705 },
    { lat: 47.835031, lng: 17.799831 },
    { lat: 47.834837, lng: 17.799912 },
    { lat: 47.834626, lng: 17.8 },
    { lat: 47.834486, lng: 17.800058 },
    { lat: 47.834352, lng: 17.800114 },
    { lat: 47.834048, lng: 17.800228 },
    { lat: 47.83364, lng: 17.80038 },
    { lat: 47.833563, lng: 17.800004 },
    { lat: 47.833559, lng: 17.799986 },
    { lat: 47.833535, lng: 17.799883 },
    { lat: 47.83345, lng: 17.799477 },
    { lat: 47.833157, lng: 17.799575 },
    { lat: 47.83304, lng: 17.799613 },
    { lat: 47.833006, lng: 17.799625 },
    { lat: 47.832913, lng: 17.799656 },
    { lat: 47.832861, lng: 17.799673 },
    { lat: 47.832808, lng: 17.79969 },
    { lat: 47.832709, lng: 17.799723 },
    { lat: 47.832681, lng: 17.799733 },
    { lat: 47.832662, lng: 17.79974 },
    { lat: 47.832638, lng: 17.799747 },
    { lat: 47.832456, lng: 17.799807 },
    { lat: 47.832426, lng: 17.799817 },
    { lat: 47.832273, lng: 17.799872 },
    { lat: 47.832081, lng: 17.799939 },
    { lat: 47.831887, lng: 17.800008 },
    { lat: 47.831731, lng: 17.800063 },
    { lat: 47.831609, lng: 17.800106 },
    { lat: 47.831501, lng: 17.800143 },
    { lat: 47.83143, lng: 17.800169 },
    { lat: 47.831382, lng: 17.800172 },
    { lat: 47.831334, lng: 17.800176 },
    { lat: 47.831238, lng: 17.800183 },
    { lat: 47.831133, lng: 17.80019 },
    { lat: 47.831035, lng: 17.800197 },
    { lat: 47.83091, lng: 17.800206 },
    { lat: 47.830904, lng: 17.800206 },
    { lat: 47.830778, lng: 17.800166 },
    { lat: 47.830453, lng: 17.800063 },
    { lat: 47.830317, lng: 17.80002 },
    { lat: 47.830184, lng: 17.799978 },
    { lat: 47.830011, lng: 17.799922 },
    { lat: 47.829971, lng: 17.79991 },
    { lat: 47.829774, lng: 17.799847 },
    { lat: 47.829593, lng: 17.79979 },
    { lat: 47.829533, lng: 17.79976 },
    { lat: 47.829524, lng: 17.799755 },
    { lat: 47.829484, lng: 17.799735 },
    { lat: 47.82938, lng: 17.799684 },
    { lat: 47.829351, lng: 17.799668 },
    { lat: 47.82928, lng: 17.799634 },
    { lat: 47.829215, lng: 17.799601 },
    { lat: 47.829142, lng: 17.799565 },
    { lat: 47.829113, lng: 17.79955 },
    { lat: 47.829093, lng: 17.79954 },
    { lat: 47.829052, lng: 17.79952 },
    { lat: 47.828924, lng: 17.799456 },
    { lat: 47.828867, lng: 17.799427 },
    { lat: 47.828835, lng: 17.799411 },
    { lat: 47.828811, lng: 17.799399 },
    { lat: 47.82879, lng: 17.799389 },
    { lat: 47.828775, lng: 17.799382 },
    { lat: 47.828712, lng: 17.79935 },
    { lat: 47.828687, lng: 17.799338 },
    { lat: 47.828674, lng: 17.799331 },
    { lat: 47.828664, lng: 17.799326 },
    { lat: 47.828634, lng: 17.799311 },
    { lat: 47.828614, lng: 17.799301 },
    { lat: 47.828581, lng: 17.799284 },
    { lat: 47.828521, lng: 17.799254 },
    { lat: 47.828465, lng: 17.799221 },
    { lat: 47.828425, lng: 17.799197 },
    { lat: 47.828405, lng: 17.799185 },
    { lat: 47.828355, lng: 17.799155 },
    { lat: 47.828289, lng: 17.799116 },
    { lat: 47.82827, lng: 17.799104 },
    { lat: 47.828252, lng: 17.799094 },
    { lat: 47.828228, lng: 17.799079 },
    { lat: 47.828206, lng: 17.799065 },
    { lat: 47.828188, lng: 17.799055 },
    { lat: 47.828168, lng: 17.799043 },
    { lat: 47.828151, lng: 17.799033 },
    { lat: 47.828079, lng: 17.798991 },
    { lat: 47.828053, lng: 17.798975 },
    { lat: 47.828002, lng: 17.798944 },
    { lat: 47.827976, lng: 17.798929 },
    { lat: 47.827962, lng: 17.79892 },
    { lat: 47.827939, lng: 17.798906 },
    { lat: 47.827895, lng: 17.79888 },
    { lat: 47.827847, lng: 17.798851 },
    { lat: 47.827833, lng: 17.798843 },
    { lat: 47.827819, lng: 17.798834 },
    { lat: 47.827789, lng: 17.798817 },
    { lat: 47.827745, lng: 17.798791 },
    { lat: 47.827703, lng: 17.798765 },
    { lat: 47.827685, lng: 17.798756 },
    { lat: 47.827642, lng: 17.79873 },
    { lat: 47.827555, lng: 17.798678 },
    { lat: 47.827531, lng: 17.798663 },
    { lat: 47.827467, lng: 17.798624 },
    { lat: 47.827451, lng: 17.798615 },
    { lat: 47.82743, lng: 17.798602 },
    { lat: 47.827412, lng: 17.798592 },
    { lat: 47.827385, lng: 17.798576 },
    { lat: 47.827353, lng: 17.798557 },
    { lat: 47.827339, lng: 17.798547 },
    { lat: 47.82733, lng: 17.798541 },
    { lat: 47.827317, lng: 17.798532 },
    { lat: 47.827282, lng: 17.798508 },
    { lat: 47.827262, lng: 17.798495 },
    { lat: 47.827243, lng: 17.798482 },
    { lat: 47.827209, lng: 17.798459 },
    { lat: 47.827203, lng: 17.798455 },
    { lat: 47.827194, lng: 17.79845 },
    { lat: 47.82718, lng: 17.798441 },
    { lat: 47.827141, lng: 17.798414 },
    { lat: 47.82711, lng: 17.798392 },
    { lat: 47.827088, lng: 17.798377 },
    { lat: 47.827077, lng: 17.79837 },
    { lat: 47.827066, lng: 17.798363 },
    { lat: 47.827053, lng: 17.798354 },
    { lat: 47.827033, lng: 17.79834 },
    { lat: 47.82699, lng: 17.798311 },
    { lat: 47.826939, lng: 17.798277 },
    { lat: 47.826868, lng: 17.798228 },
    { lat: 47.826807, lng: 17.798188 },
    { lat: 47.826716, lng: 17.798126 },
    { lat: 47.826421, lng: 17.797928 },
    { lat: 47.826387, lng: 17.797904 },
    { lat: 47.826375, lng: 17.797896 },
    { lat: 47.826332, lng: 17.797866 },
    { lat: 47.826255, lng: 17.797815 },
    { lat: 47.826208, lng: 17.797782 },
    { lat: 47.826168, lng: 17.797756 },
    { lat: 47.826147, lng: 17.797742 },
    { lat: 47.826114, lng: 17.797719 },
    { lat: 47.825975, lng: 17.797626 },
    { lat: 47.825898, lng: 17.797574 },
    { lat: 47.825873, lng: 17.797557 },
    { lat: 47.825848, lng: 17.797539 },
    { lat: 47.825825, lng: 17.797524 },
    { lat: 47.825798, lng: 17.797506 },
    { lat: 47.825762, lng: 17.797482 },
    { lat: 47.825745, lng: 17.79747 },
    { lat: 47.825729, lng: 17.797459 },
    { lat: 47.825685, lng: 17.79743 },
    { lat: 47.825632, lng: 17.797395 },
    { lat: 47.825433, lng: 17.797264 },
    { lat: 47.825344, lng: 17.797204 },
    { lat: 47.825304, lng: 17.797178 },
    { lat: 47.825269, lng: 17.797156 },
    { lat: 47.825238, lng: 17.797134 },
    { lat: 47.824607, lng: 17.796716 },
    { lat: 47.82444, lng: 17.796593 },
    { lat: 47.824382, lng: 17.796549 },
    { lat: 47.824294, lng: 17.796485 },
    { lat: 47.82421, lng: 17.796423 },
    { lat: 47.824137, lng: 17.796367 },
    { lat: 47.824111, lng: 17.796348 },
    { lat: 47.824092, lng: 17.796335 },
    { lat: 47.824075, lng: 17.796322 },
    { lat: 47.824031, lng: 17.796289 },
    { lat: 47.824008, lng: 17.796272 },
    { lat: 47.823989, lng: 17.796258 },
    { lat: 47.823976, lng: 17.796248 },
    { lat: 47.823936, lng: 17.796219 },
    { lat: 47.823917, lng: 17.796205 },
    { lat: 47.823896, lng: 17.79619 },
    { lat: 47.823878, lng: 17.796175 },
    { lat: 47.823863, lng: 17.796165 },
    { lat: 47.82385, lng: 17.796155 },
    { lat: 47.82384, lng: 17.796147 },
    { lat: 47.823818, lng: 17.796132 },
    { lat: 47.823777, lng: 17.7961 },
    { lat: 47.823765, lng: 17.796092 },
    { lat: 47.823677, lng: 17.796026 },
    { lat: 47.823654, lng: 17.79601 },
    { lat: 47.823634, lng: 17.795995 },
    { lat: 47.823622, lng: 17.795987 },
    { lat: 47.823548, lng: 17.795931 },
    { lat: 47.823501, lng: 17.795896 },
    { lat: 47.823399, lng: 17.795815 },
    { lat: 47.823385, lng: 17.795802 },
    { lat: 47.82325, lng: 17.795694 },
    { lat: 47.823228, lng: 17.795676 },
    { lat: 47.823214, lng: 17.795664 },
    { lat: 47.823199, lng: 17.795652 },
    { lat: 47.823172, lng: 17.795631 },
    { lat: 47.82316, lng: 17.795621 },
    { lat: 47.823097, lng: 17.795571 },
    { lat: 47.823038, lng: 17.795522 },
    { lat: 47.822855, lng: 17.795374 },
    { lat: 47.82263, lng: 17.795193 },
    { lat: 47.822549, lng: 17.795128 },
    { lat: 47.822523, lng: 17.795107 },
    { lat: 47.822449, lng: 17.795047 },
    { lat: 47.82243, lng: 17.795032 },
    { lat: 47.822417, lng: 17.795022 },
    { lat: 47.822402, lng: 17.795009 },
    { lat: 47.822386, lng: 17.794997 },
    { lat: 47.822372, lng: 17.794985 },
    { lat: 47.822356, lng: 17.794972 },
    { lat: 47.82234, lng: 17.79496 },
    { lat: 47.82217, lng: 17.794822 },
    { lat: 47.822151, lng: 17.794807 },
    { lat: 47.822114, lng: 17.794778 },
    { lat: 47.822063, lng: 17.794735 },
    { lat: 47.821944, lng: 17.79464 },
    { lat: 47.82194, lng: 17.794636 },
    { lat: 47.821927, lng: 17.794621 },
    { lat: 47.821905, lng: 17.794596 },
    { lat: 47.821887, lng: 17.794576 },
    { lat: 47.821841, lng: 17.79452 },
    { lat: 47.821752, lng: 17.794417 },
    { lat: 47.821716, lng: 17.794374 },
    { lat: 47.821641, lng: 17.794287 },
    { lat: 47.821576, lng: 17.794211 },
    { lat: 47.821561, lng: 17.794194 },
    { lat: 47.821548, lng: 17.794177 },
    { lat: 47.82144, lng: 17.794024 },
    { lat: 47.821425, lng: 17.794004 },
    { lat: 47.821351, lng: 17.793898 },
    { lat: 47.821212, lng: 17.793698 },
    { lat: 47.821145, lng: 17.793603 },
    { lat: 47.821074, lng: 17.793502 },
    { lat: 47.821015, lng: 17.793418 },
    { lat: 47.820957, lng: 17.793335 },
    { lat: 47.820947, lng: 17.793321 },
    { lat: 47.820937, lng: 17.793306 },
    { lat: 47.820927, lng: 17.793293 },
    { lat: 47.820915, lng: 17.793273 },
    { lat: 47.820899, lng: 17.79325 },
    { lat: 47.820887, lng: 17.793235 },
    { lat: 47.820875, lng: 17.793217 },
    { lat: 47.820833, lng: 17.793158 },
    { lat: 47.820747, lng: 17.793035 },
    { lat: 47.82071, lng: 17.792982 },
    { lat: 47.820662, lng: 17.792913 },
    { lat: 47.820632, lng: 17.792869 },
    { lat: 47.820606, lng: 17.792833 },
    { lat: 47.820596, lng: 17.792816 },
    { lat: 47.8197647, lng: 17.7935539 },
    { lat: 47.8194903, lng: 17.7935152 },
    { lat: 47.8185083, lng: 17.792229 },
    { lat: 47.8181277, lng: 17.7918446 },
    { lat: 47.8178189, lng: 17.7915434 },
    { lat: 47.8170433, lng: 17.7910873 },
    { lat: 47.8162943, lng: 17.7911179 },
    { lat: 47.8153602, lng: 17.7920706 },
    { lat: 47.8146867, lng: 17.7931515 },
    { lat: 47.8122956, lng: 17.7985639 },
    { lat: 47.8119451, lng: 17.7987407 },
    { lat: 47.8109103, lng: 17.7989645 },
    { lat: 47.8108754, lng: 17.7990388 },
    { lat: 47.8109613, lng: 17.8000571 },
    { lat: 47.8112857, lng: 17.8018301 },
    { lat: 47.8116779, lng: 17.8025799 },
    { lat: 47.8108987, lng: 17.8037969 },
    { lat: 47.8099932, lng: 17.805487 },
    { lat: 47.8090947, lng: 17.8069084 },
    { lat: 47.8083068, lng: 17.80773 },
    { lat: 47.8073144, lng: 17.8086171 },
    { lat: 47.8063985, lng: 17.8091612 },
    { lat: 47.8070304, lng: 17.8119957 },
    { lat: 47.807269, lng: 17.8133316 },
    { lat: 47.8070746, lng: 17.8164967 },
    { lat: 47.8090115, lng: 17.8183905 },
    { lat: 47.8103305, lng: 17.8199643 },
    { lat: 47.8138746, lng: 17.8243862 },
    { lat: 47.8189694, lng: 17.8270343 },
    { lat: 47.8217066, lng: 17.8281448 },
    { lat: 47.8223803, lng: 17.8298739 },
    { lat: 47.8232911, lng: 17.8328875 },
    { lat: 47.82349, lng: 17.8330898 },
    { lat: 47.8239256, lng: 17.8368177 },
    { lat: 47.8240199, lng: 17.8368101 },
    { lat: 47.8241569, lng: 17.8367792 },
    { lat: 47.8244033, lng: 17.8362338 },
    { lat: 47.8245256, lng: 17.8354377 },
    { lat: 47.8246665, lng: 17.8349219 },
    { lat: 47.824797, lng: 17.8344387 },
    { lat: 47.8249606, lng: 17.8337975 },
    { lat: 47.8254661, lng: 17.831986 },
    { lat: 47.8270776, lng: 17.8279958 },
    { lat: 47.8276827, lng: 17.8284891 },
    { lat: 47.8279991, lng: 17.8279275 },
    { lat: 47.8285742, lng: 17.8273506 },
    { lat: 47.8291981, lng: 17.8264881 },
    { lat: 47.8295741, lng: 17.8258843 },
    { lat: 47.8304409, lng: 17.8242024 },
    { lat: 47.8307156, lng: 17.8236074 },
    { lat: 47.8312377, lng: 17.8222314 },
    { lat: 47.8318375, lng: 17.8208866 },
    { lat: 47.8322461, lng: 17.8206998 },
    { lat: 47.832574, lng: 17.8216409 },
    { lat: 47.8326193, lng: 17.8216055 },
    { lat: 47.833302, lng: 17.822879 },
    { lat: 47.8340561, lng: 17.8234826 },
    { lat: 47.8346274, lng: 17.8237392 },
    { lat: 47.8369594, lng: 17.8242432 },
    { lat: 47.839234, lng: 17.8241542 },
    { lat: 47.8401045, lng: 17.8240274 },
    { lat: 47.8413788, lng: 17.8235281 },
    { lat: 47.841965, lng: 17.8232226 },
    { lat: 47.8425526, lng: 17.8228332 },
    { lat: 47.8441311, lng: 17.8215049 },
    { lat: 47.8447642, lng: 17.8215562 },
    { lat: 47.8452549, lng: 17.8205475 },
    { lat: 47.8459526, lng: 17.8189243 },
    { lat: 47.8467086, lng: 17.8166022 },
    { lat: 47.8472052, lng: 17.8142262 },
    { lat: 47.847521, lng: 17.8136766 },
    { lat: 47.8482254, lng: 17.8117266 },
    { lat: 47.8504029, lng: 17.8096643 },
    { lat: 47.8515768, lng: 17.8088406 },
    { lat: 47.8527015, lng: 17.8100142 },
    { lat: 47.854395, lng: 17.8061347 },
    { lat: 47.855278, lng: 17.8046603 },
    { lat: 47.8552851, lng: 17.8046484 },
    { lat: 47.8555263, lng: 17.8034763 },
    { lat: 47.8568945, lng: 17.7991259 },
    { lat: 47.8569369, lng: 17.7991545 },
    { lat: 47.857217, lng: 17.79806 },
  ],
  Pribeta: [
    { lat: 47.929408, lng: 18.342866 },
    { lat: 47.929439, lng: 18.342854 },
    { lat: 47.929677, lng: 18.342617 },
    { lat: 47.929992, lng: 18.342308 },
    { lat: 47.930165, lng: 18.342139 },
    { lat: 47.930407, lng: 18.341851 },
    { lat: 47.930737, lng: 18.341443 },
    { lat: 47.931146, lng: 18.340952 },
    { lat: 47.931427, lng: 18.340522 },
    { lat: 47.931908, lng: 18.339798 },
    { lat: 47.932316, lng: 18.339177 },
    { lat: 47.932751, lng: 18.338522 },
    { lat: 47.932932, lng: 18.338182 },
    { lat: 47.933347, lng: 18.337397 },
    { lat: 47.933615, lng: 18.336983 },
    { lat: 47.933775, lng: 18.336761 },
    { lat: 47.933926, lng: 18.336516 },
    { lat: 47.934168, lng: 18.336105 },
    { lat: 47.934577, lng: 18.335416 },
    { lat: 47.934935, lng: 18.334789 },
    { lat: 47.935048, lng: 18.334601 },
    { lat: 47.935299, lng: 18.334124 },
    { lat: 47.935875, lng: 18.333 },
    { lat: 47.936538, lng: 18.331966 },
    { lat: 47.936971, lng: 18.331266 },
    { lat: 47.937328, lng: 18.330629 },
    { lat: 47.937677, lng: 18.329992 },
    { lat: 47.937968, lng: 18.329294 },
    { lat: 47.938062, lng: 18.329153 },
    { lat: 47.938047, lng: 18.329132 },
    { lat: 47.937761, lng: 18.328834 },
    { lat: 47.93719, lng: 18.328099 },
    { lat: 47.936588, lng: 18.327312 },
    { lat: 47.936161, lng: 18.326764 },
    { lat: 47.935483, lng: 18.325899 },
    { lat: 47.9349423, lng: 18.3253767 },
    { lat: 47.934875, lng: 18.325111 },
    { lat: 47.934643, lng: 18.324806 },
    { lat: 47.934686, lng: 18.324726 },
    { lat: 47.934614, lng: 18.324497 },
    { lat: 47.934867, lng: 18.323899 },
    { lat: 47.935281, lng: 18.322976 },
    { lat: 47.936073, lng: 18.321209 },
    { lat: 47.937213, lng: 18.319915 },
    { lat: 47.938458, lng: 18.317127 },
    { lat: 47.939114, lng: 18.31573 },
    { lat: 47.939215, lng: 18.315059 },
    { lat: 47.939236, lng: 18.314928 },
    { lat: 47.93931, lng: 18.314431 },
    { lat: 47.939391, lng: 18.313894 },
    { lat: 47.940223, lng: 18.312013 },
    { lat: 47.940389, lng: 18.312017 },
    { lat: 47.940415, lng: 18.311963 },
    { lat: 47.940472, lng: 18.311852 },
    { lat: 47.940503, lng: 18.311788 },
    { lat: 47.940451, lng: 18.311563 },
    { lat: 47.940588, lng: 18.311283 },
    { lat: 47.940738, lng: 18.310972 },
    { lat: 47.940872, lng: 18.310711 },
    { lat: 47.940953, lng: 18.310534 },
    { lat: 47.941148, lng: 18.310133 },
    { lat: 47.94145, lng: 18.309496 },
    { lat: 47.942006, lng: 18.308334 },
    { lat: 47.942235, lng: 18.307857 },
    { lat: 47.942334, lng: 18.307662 },
    { lat: 47.942746, lng: 18.306739 },
    { lat: 47.943254, lng: 18.305579 },
    { lat: 47.943766, lng: 18.304399 },
    { lat: 47.944178, lng: 18.303426 },
    { lat: 47.944661, lng: 18.302298 },
    { lat: 47.944713, lng: 18.302174 },
    { lat: 47.944736, lng: 18.30212 },
    { lat: 47.945287, lng: 18.300821 },
    { lat: 47.945557, lng: 18.300164 },
    { lat: 47.94587, lng: 18.299501 },
    { lat: 47.94625, lng: 18.298674 },
    { lat: 47.946856, lng: 18.297386 },
    { lat: 47.947211, lng: 18.296659 },
    { lat: 47.947728, lng: 18.295594 },
    { lat: 47.947852, lng: 18.295327 },
    { lat: 47.948023, lng: 18.29498 },
    { lat: 47.948321, lng: 18.29429 },
    { lat: 47.948711, lng: 18.293353 },
    { lat: 47.948858, lng: 18.293011 },
    { lat: 47.948991, lng: 18.292705 },
    { lat: 47.94923, lng: 18.292256 },
    { lat: 47.949596, lng: 18.291564 },
    { lat: 47.949882, lng: 18.291012 },
    { lat: 47.95003, lng: 18.290726 },
    { lat: 47.95041, lng: 18.289929 },
    { lat: 47.950697, lng: 18.289307 },
    { lat: 47.951019, lng: 18.288597 },
    { lat: 47.951326, lng: 18.287943 },
    { lat: 47.951806, lng: 18.286904 },
    { lat: 47.95205, lng: 18.286369 },
    { lat: 47.952154, lng: 18.286137 },
    { lat: 47.952273, lng: 18.285855 },
    { lat: 47.952415, lng: 18.285506 },
    { lat: 47.952738, lng: 18.284756 },
    { lat: 47.952938, lng: 18.28428 },
    { lat: 47.952898, lng: 18.283363 },
    { lat: 47.952717, lng: 18.283751 },
    { lat: 47.952413, lng: 18.284372 },
    { lat: 47.952071, lng: 18.285092 },
    { lat: 47.951767, lng: 18.285733 },
    { lat: 47.951737, lng: 18.285793 },
    { lat: 47.951525, lng: 18.286156 },
    { lat: 47.951287, lng: 18.286558 },
    { lat: 47.951217, lng: 18.286698 },
    { lat: 47.951078, lng: 18.287017 },
    { lat: 47.950881, lng: 18.287475 },
    { lat: 47.950536, lng: 18.288264 },
    { lat: 47.950153, lng: 18.289131 },
    { lat: 47.949839, lng: 18.288953 },
    { lat: 47.949733, lng: 18.28934 },
    { lat: 47.949906, lng: 18.289534 },
    { lat: 47.949914, lng: 18.28954 },
    { lat: 47.949733, lng: 18.29011 },
    { lat: 47.949501, lng: 18.290845 },
    { lat: 47.949324, lng: 18.291224 },
    { lat: 47.949108, lng: 18.291676 },
    { lat: 47.94891, lng: 18.292101 },
    { lat: 47.948649, lng: 18.292672 },
    { lat: 47.948678, lng: 18.29277 },
    { lat: 47.948317, lng: 18.29359 },
    { lat: 47.948042, lng: 18.294188 },
    { lat: 47.947995, lng: 18.294288 },
    { lat: 47.947886, lng: 18.294515 },
    { lat: 47.947462, lng: 18.295441 },
    { lat: 47.947144, lng: 18.296135 },
    { lat: 47.946511, lng: 18.297545 },
    { lat: 47.945916, lng: 18.298888 },
    { lat: 47.945742, lng: 18.299265 },
    { lat: 47.945679, lng: 18.299418 },
    { lat: 47.945191, lng: 18.300495 },
    { lat: 47.944934, lng: 18.301058 },
    { lat: 47.944612, lng: 18.301211 },
    { lat: 47.944362, lng: 18.301767 },
    { lat: 47.944515, lng: 18.301959 },
    { lat: 47.944468, lng: 18.30205 },
    { lat: 47.944303, lng: 18.30237 },
    { lat: 47.944091, lng: 18.302801 },
    { lat: 47.943626, lng: 18.303746 },
    { lat: 47.943326, lng: 18.30436 },
    { lat: 47.943145, lng: 18.304731 },
    { lat: 47.943069, lng: 18.304681 },
    { lat: 47.942694, lng: 18.305423 },
    { lat: 47.942749, lng: 18.305523 },
    { lat: 47.94235, lng: 18.306301 },
    { lat: 47.94217, lng: 18.306646 },
    { lat: 47.941871, lng: 18.30724 },
    { lat: 47.941759, lng: 18.307504 },
    { lat: 47.941473, lng: 18.30818 },
    { lat: 47.941011, lng: 18.309293 },
    { lat: 47.940854, lng: 18.30965 },
    { lat: 47.940341, lng: 18.310866 },
    { lat: 47.940174, lng: 18.311249 },
    { lat: 47.939993, lng: 18.311236 },
    { lat: 47.939956, lng: 18.311293 },
    { lat: 47.939952, lng: 18.311297 },
    { lat: 47.939884, lng: 18.311409 },
    { lat: 47.939782, lng: 18.311609 },
    { lat: 47.939921, lng: 18.311883 },
    { lat: 47.939739, lng: 18.312263 },
    { lat: 47.939092, lng: 18.31362 },
    { lat: 47.938483, lng: 18.314352 },
    { lat: 47.937629, lng: 18.316135 },
    { lat: 47.937439, lng: 18.316168 },
    { lat: 47.936166, lng: 18.318954 },
    { lat: 47.935738, lng: 18.320971 },
    { lat: 47.93486, lng: 18.322781 },
    { lat: 47.934337, lng: 18.323885 },
    { lat: 47.934012, lng: 18.323878 },
    { lat: 47.933986, lng: 18.323987 },
    { lat: 47.933403, lng: 18.323196 },
    { lat: 47.932961, lng: 18.322601 },
    { lat: 47.932612, lng: 18.322148 },
    { lat: 47.932037, lng: 18.321391 },
    { lat: 47.931427, lng: 18.320637 },
    { lat: 47.931124, lng: 18.320278 },
    { lat: 47.930976, lng: 18.320095 },
    { lat: 47.93028, lng: 18.319388 },
    { lat: 47.929686, lng: 18.318722 },
    { lat: 47.929192, lng: 18.318167 },
    { lat: 47.928817, lng: 18.317757 },
    { lat: 47.92835, lng: 18.317244 },
    { lat: 47.928051, lng: 18.316911 },
    { lat: 47.927701, lng: 18.316513 },
    { lat: 47.927419, lng: 18.316189 },
    { lat: 47.927134, lng: 18.315858 },
    { lat: 47.926804, lng: 18.315456 },
    { lat: 47.926368, lng: 18.314927 },
    { lat: 47.925991, lng: 18.314398 },
    { lat: 47.925595, lng: 18.31384 },
    { lat: 47.925266, lng: 18.313389 },
    { lat: 47.925057, lng: 18.313096 },
    { lat: 47.924817, lng: 18.31275 },
    { lat: 47.924549, lng: 18.312371 },
    { lat: 47.924491, lng: 18.312257 },
    { lat: 47.924118, lng: 18.311519 },
    { lat: 47.924044, lng: 18.311364 },
    { lat: 47.923955, lng: 18.311195 },
    { lat: 47.923716, lng: 18.310793 },
    { lat: 47.923394, lng: 18.310261 },
    { lat: 47.923165, lng: 18.309895 },
    { lat: 47.922972, lng: 18.30958 },
    { lat: 47.922974, lng: 18.309544 },
    { lat: 47.923228, lng: 18.309099 },
    { lat: 47.923526, lng: 18.308574 },
    { lat: 47.923929, lng: 18.307861 },
    { lat: 47.924101, lng: 18.307565 },
    { lat: 47.924473, lng: 18.306924 },
    { lat: 47.924866, lng: 18.30624 },
    { lat: 47.924981, lng: 18.306048 },
    { lat: 47.92533, lng: 18.305508 },
    { lat: 47.92539, lng: 18.305416 },
    { lat: 47.925626, lng: 18.30506 },
    { lat: 47.925752, lng: 18.304864 },
    { lat: 47.925864, lng: 18.304698 },
    { lat: 47.926161, lng: 18.304233 },
    { lat: 47.926426, lng: 18.30382 },
    { lat: 47.926755, lng: 18.303302 },
    { lat: 47.926956, lng: 18.302993 },
    { lat: 47.927037, lng: 18.302866 },
    { lat: 47.927202, lng: 18.302607 },
    { lat: 47.927309, lng: 18.302444 },
    { lat: 47.927479, lng: 18.302202 },
    { lat: 47.927841, lng: 18.301676 },
    { lat: 47.928206, lng: 18.301157 },
    { lat: 47.928291, lng: 18.301044 },
    { lat: 47.928505, lng: 18.300692 },
    { lat: 47.928864, lng: 18.300098 },
    { lat: 47.929099, lng: 18.29971 },
    { lat: 47.929384, lng: 18.299232 },
    { lat: 47.929561, lng: 18.298936 },
    { lat: 47.929609, lng: 18.298814 },
    { lat: 47.929882, lng: 18.298232 },
    { lat: 47.93027, lng: 18.297404 },
    { lat: 47.93042, lng: 18.29709 },
    { lat: 47.930632, lng: 18.296643 },
    { lat: 47.931173, lng: 18.295527 },
    { lat: 47.931576, lng: 18.294684 },
    { lat: 47.931686, lng: 18.294416 },
    { lat: 47.931964, lng: 18.293761 },
    { lat: 47.932242, lng: 18.293103 },
    { lat: 47.932444, lng: 18.292631 },
    { lat: 47.932571, lng: 18.292327 },
    { lat: 47.932721, lng: 18.291976 },
    { lat: 47.932849, lng: 18.291797 },
    { lat: 47.932953, lng: 18.291648 },
    { lat: 47.933374, lng: 18.291043 },
    { lat: 47.933617, lng: 18.290691 },
    { lat: 47.933727, lng: 18.290538 },
    { lat: 47.933807, lng: 18.290422 },
    { lat: 47.933995, lng: 18.290156 },
    { lat: 47.934043, lng: 18.29005 },
    { lat: 47.934139, lng: 18.289838 },
    { lat: 47.934199, lng: 18.289706 },
    { lat: 47.93432, lng: 18.289439 },
    { lat: 47.934441, lng: 18.289174 },
    { lat: 47.93464, lng: 18.288718 },
    { lat: 47.934684, lng: 18.288629 },
    { lat: 47.934729, lng: 18.288535 },
    { lat: 47.934774, lng: 18.288459 },
    { lat: 47.934782, lng: 18.288444 },
    { lat: 47.934798, lng: 18.288416 },
    { lat: 47.934828, lng: 18.288365 },
    { lat: 47.935249, lng: 18.287625 },
    { lat: 47.93579, lng: 18.286667 },
    { lat: 47.936198, lng: 18.285964 },
    { lat: 47.936506, lng: 18.285411 },
    { lat: 47.936715, lng: 18.285046 },
    { lat: 47.936825, lng: 18.2848 },
    { lat: 47.937154, lng: 18.284031 },
    { lat: 47.937396, lng: 18.283474 },
    { lat: 47.937631, lng: 18.282711 },
    { lat: 47.937964, lng: 18.281636 },
    { lat: 47.937975, lng: 18.280586 },
    { lat: 47.937982, lng: 18.279488 },
    { lat: 47.937988, lng: 18.278616 },
    { lat: 47.938029, lng: 18.27723 },
    { lat: 47.938061, lng: 18.276197 },
    { lat: 47.938405, lng: 18.275499 },
    { lat: 47.938627, lng: 18.275177 },
    { lat: 47.938889, lng: 18.274801 },
    { lat: 47.939093, lng: 18.274495 },
    { lat: 47.939426, lng: 18.273752 },
    { lat: 47.939761, lng: 18.272989 },
    { lat: 47.940105, lng: 18.272097 },
    { lat: 47.940153, lng: 18.27199 },
    { lat: 47.940171, lng: 18.27196 },
    { lat: 47.939878, lng: 18.271643 },
    { lat: 47.9369617, lng: 18.2687045 },
    { lat: 47.9347733, lng: 18.2667225 },
    { lat: 47.9342642, lng: 18.2661322 },
    { lat: 47.9334097, lng: 18.2648613 },
    { lat: 47.9287504, lng: 18.2719601 },
    { lat: 47.9180673, lng: 18.2697916 },
    { lat: 47.9171125, lng: 18.2696527 },
    { lat: 47.9096747, lng: 18.2674671 },
    { lat: 47.9088865, lng: 18.2667942 },
    { lat: 47.9083016, lng: 18.2675674 },
    { lat: 47.9070111, lng: 18.2688247 },
    { lat: 47.9062388, lng: 18.2693993 },
    { lat: 47.9052505, lng: 18.2704955 },
    { lat: 47.9045163, lng: 18.271653 },
    { lat: 47.9024965, lng: 18.2736432 },
    { lat: 47.9015497, lng: 18.2751643 },
    { lat: 47.9007798, lng: 18.2765643 },
    { lat: 47.8998187, lng: 18.2749677 },
    { lat: 47.8987963, lng: 18.2738498 },
    { lat: 47.8987705, lng: 18.2738907 },
    { lat: 47.8975925, lng: 18.2726995 },
    { lat: 47.8968844, lng: 18.2720854 },
    { lat: 47.8956046, lng: 18.2705364 },
    { lat: 47.8944263, lng: 18.2690097 },
    { lat: 47.8936868, lng: 18.2677517 },
    { lat: 47.8910933, lng: 18.2727012 },
    { lat: 47.8898985, lng: 18.2748527 },
    { lat: 47.8891094, lng: 18.2759567 },
    { lat: 47.8879844, lng: 18.2777167 },
    { lat: 47.8825105, lng: 18.2856671 },
    { lat: 47.8800515, lng: 18.2891427 },
    { lat: 47.8729622, lng: 18.2989306 },
    { lat: 47.8701905, lng: 18.3026079 },
    { lat: 47.8692544, lng: 18.303988 },
    { lat: 47.8682404, lng: 18.3053039 },
    { lat: 47.8679497, lng: 18.3057259 },
    { lat: 47.8670444, lng: 18.3073451 },
    { lat: 47.8670207, lng: 18.3073281 },
    { lat: 47.8670066, lng: 18.3073541 },
    { lat: 47.8661669, lng: 18.3088448 },
    { lat: 47.8650033, lng: 18.310347 },
    { lat: 47.8633779, lng: 18.312673 },
    { lat: 47.862247, lng: 18.3145904 },
    { lat: 47.8606264, lng: 18.3168038 },
    { lat: 47.8592303, lng: 18.3184679 },
    { lat: 47.859205, lng: 18.3185003 },
    { lat: 47.8592369, lng: 18.3185616 },
    { lat: 47.8590007, lng: 18.3190275 },
    { lat: 47.8588417, lng: 18.3192261 },
    { lat: 47.85846, lng: 18.319513 },
    { lat: 47.8576463, lng: 18.3206729 },
    { lat: 47.8574843, lng: 18.3208156 },
    { lat: 47.8574712, lng: 18.3214324 },
    { lat: 47.8575862, lng: 18.3221008 },
    { lat: 47.8575949, lng: 18.323019 },
    { lat: 47.8578099, lng: 18.3241697 },
    { lat: 47.8579289, lng: 18.324592 },
    { lat: 47.8587275, lng: 18.3263015 },
    { lat: 47.8593252, lng: 18.3290324 },
    { lat: 47.8598589, lng: 18.3305095 },
    { lat: 47.8600543, lng: 18.3313789 },
    { lat: 47.8602667, lng: 18.3318375 },
    { lat: 47.8602704, lng: 18.3320598 },
    { lat: 47.8603021, lng: 18.3321377 },
    { lat: 47.8611304, lng: 18.3341917 },
    { lat: 47.8622047, lng: 18.3366556 },
    { lat: 47.8633556, lng: 18.3386666 },
    { lat: 47.8643361, lng: 18.3407013 },
    { lat: 47.8651434, lng: 18.3435927 },
    { lat: 47.8657313, lng: 18.3454066 },
    { lat: 47.8667802, lng: 18.3477708 },
    { lat: 47.8681193, lng: 18.350374 },
    { lat: 47.8695037, lng: 18.3521147 },
    { lat: 47.8703355, lng: 18.35354 },
    { lat: 47.8712335, lng: 18.3544686 },
    { lat: 47.878482, lng: 18.36002 },
    { lat: 47.878508, lng: 18.35999 },
    { lat: 47.878742, lng: 18.359783 },
    { lat: 47.879078, lng: 18.359486 },
    { lat: 47.87971, lng: 18.358922 },
    { lat: 47.879992, lng: 18.358675 },
    { lat: 47.880173, lng: 18.358506 },
    { lat: 47.880499, lng: 18.358217 },
    { lat: 47.880588, lng: 18.358138 },
    { lat: 47.881092, lng: 18.357695 },
    { lat: 47.881436, lng: 18.357393 },
    { lat: 47.881735, lng: 18.357127 },
    { lat: 47.882045, lng: 18.356848 },
    { lat: 47.882345, lng: 18.356766 },
    { lat: 47.882652, lng: 18.356684 },
    { lat: 47.883003, lng: 18.356589 },
    { lat: 47.883761, lng: 18.356399 },
    { lat: 47.884812, lng: 18.356127 },
    { lat: 47.885646, lng: 18.355911 },
    { lat: 47.886897, lng: 18.355584 },
    { lat: 47.888036, lng: 18.355276 },
    { lat: 47.888706, lng: 18.356008 },
    { lat: 47.889746, lng: 18.357019 },
    { lat: 47.890629, lng: 18.357399 },
    { lat: 47.890867, lng: 18.35698 },
    { lat: 47.891297, lng: 18.356235 },
    { lat: 47.891589, lng: 18.355711 },
    { lat: 47.891896, lng: 18.355163 },
    { lat: 47.892503, lng: 18.35439 },
    { lat: 47.892759, lng: 18.354066 },
    { lat: 47.893172, lng: 18.353554 },
    { lat: 47.893523, lng: 18.353108 },
    { lat: 47.893784, lng: 18.352787 },
    { lat: 47.894006, lng: 18.352509 },
    { lat: 47.894108, lng: 18.352368 },
    { lat: 47.89439, lng: 18.352014 },
    { lat: 47.89456, lng: 18.351802 },
    { lat: 47.894625, lng: 18.351718 },
    { lat: 47.894693, lng: 18.351634 },
    { lat: 47.894914, lng: 18.35136 },
    { lat: 47.894995, lng: 18.351259 },
    { lat: 47.895306, lng: 18.350871 },
    { lat: 47.895619, lng: 18.350484 },
    { lat: 47.895912, lng: 18.350109 },
    { lat: 47.895988, lng: 18.350015 },
    { lat: 47.896062, lng: 18.349924 },
    { lat: 47.896221, lng: 18.349726 },
    { lat: 47.896713, lng: 18.349105 },
    { lat: 47.897183, lng: 18.348525 },
    { lat: 47.8973, lng: 18.34836 },
    { lat: 47.897398, lng: 18.348219 },
    { lat: 47.897441, lng: 18.348159 },
    { lat: 47.897489, lng: 18.348085 },
    { lat: 47.897641, lng: 18.347867 },
    { lat: 47.898085, lng: 18.347225 },
    { lat: 47.898384, lng: 18.346795 },
    { lat: 47.898781, lng: 18.346208 },
    { lat: 47.898975, lng: 18.345916 },
    { lat: 47.899417, lng: 18.345265 },
    { lat: 47.899449, lng: 18.345218 },
    { lat: 47.899812, lng: 18.345322 },
    { lat: 47.900267, lng: 18.345457 },
    { lat: 47.900579, lng: 18.345548 },
    { lat: 47.900711, lng: 18.345587 },
    { lat: 47.901364, lng: 18.345788 },
    { lat: 47.902253, lng: 18.346498 },
    { lat: 47.902598, lng: 18.346767 },
    { lat: 47.903195, lng: 18.347111 },
    { lat: 47.903327, lng: 18.347188 },
    { lat: 47.903687, lng: 18.347395 },
    { lat: 47.904337, lng: 18.347776 },
    { lat: 47.904969, lng: 18.348188 },
    { lat: 47.90534, lng: 18.348427 },
    { lat: 47.905918, lng: 18.348806 },
    { lat: 47.906305, lng: 18.349051 },
    { lat: 47.906458, lng: 18.349158 },
    { lat: 47.906853, lng: 18.349403 },
    { lat: 47.907397, lng: 18.349738 },
    { lat: 47.907739, lng: 18.349945 },
    { lat: 47.907974, lng: 18.350092 },
    { lat: 47.908676, lng: 18.350525 },
    { lat: 47.909499, lng: 18.351091 },
    { lat: 47.909613, lng: 18.351167 },
    { lat: 47.909655, lng: 18.351196 },
    { lat: 47.910359, lng: 18.351649 },
    { lat: 47.91079, lng: 18.351957 },
    { lat: 47.910862, lng: 18.352009 },
    { lat: 47.911311, lng: 18.35233 },
    { lat: 47.912363, lng: 18.35302 },
    { lat: 47.912991, lng: 18.353389 },
    { lat: 47.913669, lng: 18.353882 },
    { lat: 47.914202, lng: 18.354259 },
    { lat: 47.915122, lng: 18.354924 },
    { lat: 47.915289, lng: 18.355062 },
    { lat: 47.915314, lng: 18.355081 },
    { lat: 47.915983, lng: 18.35562 },
    { lat: 47.916574, lng: 18.356085 },
    { lat: 47.917528, lng: 18.356809 },
    { lat: 47.91795, lng: 18.357181 },
    { lat: 47.918691, lng: 18.357824 },
    { lat: 47.918782, lng: 18.357917 },
    { lat: 47.918811, lng: 18.357946 },
    { lat: 47.919055, lng: 18.358197 },
    { lat: 47.919683, lng: 18.35884 },
    { lat: 47.919695, lng: 18.358861 },
    { lat: 47.919847, lng: 18.358097 },
    { lat: 47.919849, lng: 18.358095 },
    { lat: 47.92071, lng: 18.356592 },
    { lat: 47.92095, lng: 18.356287 },
    { lat: 47.920984, lng: 18.356232 },
    { lat: 47.92141, lng: 18.355541 },
    { lat: 47.921639, lng: 18.355168 },
    { lat: 47.92177, lng: 18.354955 },
    { lat: 47.921825, lng: 18.354866 },
    { lat: 47.921838, lng: 18.354799 },
    { lat: 47.921993, lng: 18.354566 },
    { lat: 47.922085, lng: 18.354416 },
    { lat: 47.922273, lng: 18.354104 },
    { lat: 47.922652, lng: 18.353494 },
    { lat: 47.922673, lng: 18.353457 },
    { lat: 47.922772, lng: 18.353276 },
    { lat: 47.922781, lng: 18.353262 },
    { lat: 47.922874, lng: 18.353111 },
    { lat: 47.923197, lng: 18.352629 },
    { lat: 47.923215, lng: 18.352598 },
    { lat: 47.9233, lng: 18.352453 },
    { lat: 47.923333, lng: 18.352396 },
    { lat: 47.923649, lng: 18.351905 },
    { lat: 47.924012, lng: 18.351379 },
    { lat: 47.924255, lng: 18.351028 },
    { lat: 47.924516, lng: 18.350541 },
    { lat: 47.924622, lng: 18.350342 },
    { lat: 47.924971, lng: 18.349792 },
    { lat: 47.925029, lng: 18.349701 },
    { lat: 47.925305, lng: 18.349276 },
    { lat: 47.92564, lng: 18.34872 },
    { lat: 47.926036, lng: 18.348062 },
    { lat: 47.926176, lng: 18.347774 },
    { lat: 47.926197, lng: 18.347744 },
    { lat: 47.926465, lng: 18.347366 },
    { lat: 47.926516, lng: 18.347287 },
    { lat: 47.92672, lng: 18.346972 },
    { lat: 47.927176, lng: 18.346022 },
    { lat: 47.927474, lng: 18.345401 },
    { lat: 47.927509, lng: 18.345336 },
    { lat: 47.927672, lng: 18.345033 },
    { lat: 47.927878, lng: 18.34474 },
    { lat: 47.928062, lng: 18.344477 },
    { lat: 47.928458, lng: 18.343956 },
    { lat: 47.928904, lng: 18.34337 },
    { lat: 47.929408, lng: 18.342866 },
  ],
  KravanynadDunajom: [
    { lat: 47.7551703, lng: 18.5123163 },
    { lat: 47.75534, lng: 18.51227 },
    { lat: 47.755531, lng: 18.512217 },
    { lat: 47.757445, lng: 18.511688 },
    { lat: 47.757568, lng: 18.511667 },
    { lat: 47.75794, lng: 18.511654 },
    { lat: 47.758109, lng: 18.511633 },
    { lat: 47.758518, lng: 18.511624 },
    { lat: 47.759184, lng: 18.511579 },
    { lat: 47.759478, lng: 18.511562 },
    { lat: 47.760089, lng: 18.511527 },
    { lat: 47.760851, lng: 18.511489 },
    { lat: 47.761999, lng: 18.511415 },
    { lat: 47.762363, lng: 18.511395 },
    { lat: 47.762669, lng: 18.511377 },
    { lat: 47.763316, lng: 18.511332 },
    { lat: 47.763685, lng: 18.511307 },
    { lat: 47.764268, lng: 18.511263 },
    { lat: 47.765, lng: 18.511208 },
    { lat: 47.765386, lng: 18.511172 },
    { lat: 47.765621, lng: 18.511148 },
    { lat: 47.76626, lng: 18.511084 },
    { lat: 47.767128, lng: 18.511025 },
    { lat: 47.767481, lng: 18.510992 },
    { lat: 47.768273, lng: 18.510914 },
    { lat: 47.768622, lng: 18.510879 },
    { lat: 47.768793, lng: 18.510858 },
    { lat: 47.76897, lng: 18.510839 },
    { lat: 47.769735, lng: 18.510757 },
    { lat: 47.770366, lng: 18.510685 },
    { lat: 47.770749, lng: 18.510611 },
    { lat: 47.771307, lng: 18.510508 },
    { lat: 47.771906, lng: 18.510397 },
    { lat: 47.772727, lng: 18.510241 },
    { lat: 47.773414, lng: 18.510116 },
    { lat: 47.774613, lng: 18.50986 },
    { lat: 47.774645, lng: 18.509848 },
    { lat: 47.774678, lng: 18.509845 },
    { lat: 47.774739, lng: 18.509832 },
    { lat: 47.775883, lng: 18.50958 },
    { lat: 47.777196, lng: 18.509279 },
    { lat: 47.777718, lng: 18.509158 },
    { lat: 47.778459, lng: 18.508984 },
    { lat: 47.778703, lng: 18.508929 },
    { lat: 47.779246, lng: 18.508806 },
    { lat: 47.780424, lng: 18.508546 },
    { lat: 47.781143, lng: 18.508379 },
    { lat: 47.781545, lng: 18.508287 },
    { lat: 47.782018, lng: 18.508181 },
    { lat: 47.782279, lng: 18.508122 },
    { lat: 47.783013, lng: 18.507952 },
    { lat: 47.783611, lng: 18.507803 },
    { lat: 47.783907, lng: 18.50772 },
    { lat: 47.784677, lng: 18.507498 },
    { lat: 47.785331, lng: 18.507305 },
    { lat: 47.7858, lng: 18.507184 },
    { lat: 47.78662, lng: 18.506976 },
    { lat: 47.787345, lng: 18.506791 },
    { lat: 47.788512, lng: 18.506694 },
    { lat: 47.789561, lng: 18.506602 },
    { lat: 47.790084, lng: 18.506559 },
    { lat: 47.791194, lng: 18.506169 },
    { lat: 47.792002, lng: 18.505891 },
    { lat: 47.79207, lng: 18.505871 },
    { lat: 47.792524, lng: 18.505709 },
    { lat: 47.7926381, lng: 18.5056711 },
    { lat: 47.7930811, lng: 18.5051578 },
    { lat: 47.7931226, lng: 18.5034152 },
    { lat: 47.7930257, lng: 18.5017826 },
    { lat: 47.7927271, lng: 18.4988994 },
    { lat: 47.7921749, lng: 18.4958029 },
    { lat: 47.7940306, lng: 18.4895929 },
    { lat: 47.7957034, lng: 18.4861396 },
    { lat: 47.7905439, lng: 18.4757827 },
    { lat: 47.7752648, lng: 18.4399822 },
    { lat: 47.7752556, lng: 18.4399616 },
    { lat: 47.7727688, lng: 18.4447912 },
    { lat: 47.7718309, lng: 18.4459848 },
    { lat: 47.7701983, lng: 18.4475437 },
    { lat: 47.7691375, lng: 18.4482976 },
    { lat: 47.7659163, lng: 18.4509932 },
    { lat: 47.7649865, lng: 18.4515865 },
    { lat: 47.765011, lng: 18.4517481 },
    { lat: 47.7651375, lng: 18.4529767 },
    { lat: 47.7652062, lng: 18.4542151 },
    { lat: 47.7652176, lng: 18.4556098 },
    { lat: 47.7651315, lng: 18.4569988 },
    { lat: 47.7649492, lng: 18.4583672 },
    { lat: 47.7647645, lng: 18.4593791 },
    { lat: 47.7644541, lng: 18.4611427 },
    { lat: 47.7641812, lng: 18.4629197 },
    { lat: 47.7641653, lng: 18.4630302 },
    { lat: 47.7639919, lng: 18.4642314 },
    { lat: 47.7638168, lng: 18.4650584 },
    { lat: 47.763597, lng: 18.4658615 },
    { lat: 47.7631147, lng: 18.4675508 },
    { lat: 47.7626326, lng: 18.4692407 },
    { lat: 47.76215, lng: 18.4709298 },
    { lat: 47.7617023, lng: 18.472447 },
    { lat: 47.7611925, lng: 18.4739205 },
    { lat: 47.7606228, lng: 18.4753448 },
    { lat: 47.759995, lng: 18.4767143 },
    { lat: 47.7595208, lng: 18.4776668 },
    { lat: 47.7591356, lng: 18.4783667 },
    { lat: 47.758716, lng: 18.4790215 },
    { lat: 47.7582643, lng: 18.4796277 },
    { lat: 47.7572628, lng: 18.4808292 },
    { lat: 47.7566024, lng: 18.4817018 },
    { lat: 47.7559744, lng: 18.4826254 },
    { lat: 47.7553806, lng: 18.4835976 },
    { lat: 47.7548226, lng: 18.4846152 },
    { lat: 47.754302, lng: 18.4856757 },
    { lat: 47.7538205, lng: 18.4867761 },
    { lat: 47.7534703, lng: 18.4876391 },
    { lat: 47.7531766, lng: 18.4885473 },
    { lat: 47.7529419, lng: 18.4894926 },
    { lat: 47.7527684, lng: 18.4904663 },
    { lat: 47.7526575, lng: 18.4914602 },
    { lat: 47.7526103, lng: 18.492465 },
    { lat: 47.7526273, lng: 18.493472 },
    { lat: 47.7527083, lng: 18.4944721 },
    { lat: 47.7528632, lng: 18.4954712 },
    { lat: 47.7531104, lng: 18.4970645 },
    { lat: 47.7532043, lng: 18.4986935 },
    { lat: 47.7532826, lng: 18.5002652 },
    { lat: 47.7534631, lng: 18.5018185 },
    { lat: 47.7540955, lng: 18.5056977 },
    { lat: 47.7547276, lng: 18.5095771 },
    { lat: 47.7551703, lng: 18.5123163 },
  ],
  VeľkéKosihy: [
    { lat: 47.7858321, lng: 17.8361788 },
    { lat: 47.7813978, lng: 17.8366136 },
    { lat: 47.780736, lng: 17.8349491 },
    { lat: 47.7802849, lng: 17.8341349 },
    { lat: 47.7795436, lng: 17.8329871 },
    { lat: 47.7792575, lng: 17.8325424 },
    { lat: 47.7787109, lng: 17.8321563 },
    { lat: 47.7786789, lng: 17.8321288 },
    { lat: 47.7776736, lng: 17.8338164 },
    { lat: 47.7766753, lng: 17.8350559 },
    { lat: 47.7752768, lng: 17.8365175 },
    { lat: 47.7721109, lng: 17.8389005 },
    { lat: 47.7710908, lng: 17.8401859 },
    { lat: 47.7700533, lng: 17.8402589 },
    { lat: 47.7700455, lng: 17.8436249 },
    { lat: 47.7708625, lng: 17.8440188 },
    { lat: 47.7710839, lng: 17.844226 },
    { lat: 47.7716075, lng: 17.8450429 },
    { lat: 47.7718464, lng: 17.8469018 },
    { lat: 47.7722249, lng: 17.8493063 },
    { lat: 47.772249, lng: 17.8502449 },
    { lat: 47.7721397, lng: 17.8512001 },
    { lat: 47.7719023, lng: 17.8524404 },
    { lat: 47.7695201, lng: 17.8521759 },
    { lat: 47.7693578, lng: 17.8521067 },
    { lat: 47.7692767, lng: 17.8532282 },
    { lat: 47.7689884, lng: 17.8546294 },
    { lat: 47.7687, lng: 17.8557029 },
    { lat: 47.7683675, lng: 17.8566975 },
    { lat: 47.767727, lng: 17.8582309 },
    { lat: 47.7675647, lng: 17.8585073 },
    { lat: 47.7612988, lng: 17.8543195 },
    { lat: 47.761349, lng: 17.8544734 },
    { lat: 47.7612657, lng: 17.8552291 },
    { lat: 47.7610636, lng: 17.8555179 },
    { lat: 47.7606551, lng: 17.8564012 },
    { lat: 47.7601794, lng: 17.8566706 },
    { lat: 47.759902, lng: 17.8570776 },
    { lat: 47.759789, lng: 17.8571673 },
    { lat: 47.7596548, lng: 17.85717 },
    { lat: 47.7594197, lng: 17.8568907 },
    { lat: 47.7592688, lng: 17.8568017 },
    { lat: 47.7590714, lng: 17.8569344 },
    { lat: 47.7586923, lng: 17.8570083 },
    { lat: 47.7580237, lng: 17.8573897 },
    { lat: 47.7576743, lng: 17.8573971 },
    { lat: 47.7571351, lng: 17.857715 },
    { lat: 47.7569473, lng: 17.8579106 },
    { lat: 47.7567053, lng: 17.8583588 },
    { lat: 47.7564164, lng: 17.858561 },
    { lat: 47.7562098, lng: 17.8584912 },
    { lat: 47.7559661, lng: 17.8593731 },
    { lat: 47.7553195, lng: 17.8605842 },
    { lat: 47.7550743, lng: 17.8608542 },
    { lat: 47.7546989, lng: 17.8610812 },
    { lat: 47.7544944, lng: 17.8612976 },
    { lat: 47.7544361, lng: 17.8611954 },
    { lat: 47.7542722, lng: 17.8612109 },
    { lat: 47.7539156, lng: 17.8610091 },
    { lat: 47.7537682, lng: 17.861001 },
    { lat: 47.7537023, lng: 17.861011 },
    { lat: 47.7534554, lng: 17.861311 },
    { lat: 47.75334, lng: 17.8613775 },
    { lat: 47.7531446, lng: 17.8613759 },
    { lat: 47.7527076, lng: 17.8611903 },
    { lat: 47.7522973, lng: 17.8612003 },
    { lat: 47.7473525, lng: 17.8617981 },
    { lat: 47.7454889, lng: 17.8618027 },
    { lat: 47.745561, lng: 17.862352 },
    { lat: 47.745706, lng: 17.864218 },
    { lat: 47.74575, lng: 17.866096 },
    { lat: 47.745713, lng: 17.86712 },
    { lat: 47.745578, lng: 17.868474 },
    { lat: 47.745376, lng: 17.869809 },
    { lat: 47.745108, lng: 17.871117 },
    { lat: 47.7447706, lng: 17.8723903 },
    { lat: 47.7401611, lng: 17.8897058 },
    { lat: 47.7397963, lng: 17.8913595 },
    { lat: 47.7395299, lng: 17.8930536 },
    { lat: 47.7393637, lng: 17.8947759 },
    { lat: 47.7392991, lng: 17.8965129 },
    { lat: 47.7393366, lng: 17.8982518 },
    { lat: 47.7394759, lng: 17.8999793 },
    { lat: 47.7397159, lng: 17.9016822 },
    { lat: 47.7400548, lng: 17.903348 },
    { lat: 47.74049, lng: 17.9049636 },
    { lat: 47.7429325, lng: 17.9115074 },
    { lat: 47.7433661, lng: 17.9130529 },
    { lat: 47.7456083, lng: 17.9135613 },
    { lat: 47.7464254, lng: 17.9141865 },
    { lat: 47.7475071, lng: 17.9146563 },
    { lat: 47.7481057, lng: 17.9140518 },
    { lat: 47.7478076, lng: 17.9135347 },
    { lat: 47.7476339, lng: 17.9133325 },
    { lat: 47.7468373, lng: 17.911751 },
    { lat: 47.7463722, lng: 17.9102722 },
    { lat: 47.7525917, lng: 17.9050342 },
    { lat: 47.7536076, lng: 17.9071363 },
    { lat: 47.7540586, lng: 17.9083653 },
    { lat: 47.7542619, lng: 17.9093635 },
    { lat: 47.754263, lng: 17.9104882 },
    { lat: 47.7541033, lng: 17.9124567 },
    { lat: 47.7560317, lng: 17.9123703 },
    { lat: 47.7561334, lng: 17.9123153 },
    { lat: 47.7571683, lng: 17.9127009 },
    { lat: 47.7576397, lng: 17.91301 },
    { lat: 47.7594277, lng: 17.9143029 },
    { lat: 47.7592405, lng: 17.9149317 },
    { lat: 47.7588056, lng: 17.9156654 },
    { lat: 47.7592205, lng: 17.9154557 },
    { lat: 47.7607314, lng: 17.915188 },
    { lat: 47.7613404, lng: 17.917747 },
    { lat: 47.7617838, lng: 17.9198318 },
    { lat: 47.7638769, lng: 17.920247 },
    { lat: 47.7646935, lng: 17.9204937 },
    { lat: 47.7653553, lng: 17.9207756 },
    { lat: 47.7662787, lng: 17.9213143 },
    { lat: 47.7663686, lng: 17.9243064 },
    { lat: 47.7674154, lng: 17.9243855 },
    { lat: 47.7679108, lng: 17.9242909 },
    { lat: 47.7683087, lng: 17.9283363 },
    { lat: 47.7693122, lng: 17.9282259 },
    { lat: 47.7707479, lng: 17.9284269 },
    { lat: 47.7707588, lng: 17.9281254 },
    { lat: 47.7705862, lng: 17.9260512 },
    { lat: 47.7702517, lng: 17.9238843 },
    { lat: 47.7739009, lng: 17.9236795 },
    { lat: 47.7731688, lng: 17.9209308 },
    { lat: 47.7731306, lng: 17.9205998 },
    { lat: 47.7734682, lng: 17.9208253 },
    { lat: 47.7737077, lng: 17.9209015 },
    { lat: 47.7739906, lng: 17.9208741 },
    { lat: 47.7742438, lng: 17.9207916 },
    { lat: 47.7748405, lng: 17.9203412 },
    { lat: 47.7754077, lng: 17.9196829 },
    { lat: 47.7758906, lng: 17.9188295 },
    { lat: 47.7763016, lng: 17.9176307 },
    { lat: 47.7764713, lng: 17.9170819 },
    { lat: 47.7767878, lng: 17.9160912 },
    { lat: 47.7782543, lng: 17.9124021 },
    { lat: 47.7785352, lng: 17.9117892 },
    { lat: 47.7788492, lng: 17.9102397 },
    { lat: 47.7789506, lng: 17.908855 },
    { lat: 47.7792795, lng: 17.9088416 },
    { lat: 47.7795168, lng: 17.9081454 },
    { lat: 47.7801347, lng: 17.9070645 },
    { lat: 47.7828351, lng: 17.9030844 },
    { lat: 47.7835473, lng: 17.903417 },
    { lat: 47.7837251, lng: 17.9031133 },
    { lat: 47.7840488, lng: 17.9033126 },
    { lat: 47.7866622, lng: 17.8984859 },
    { lat: 47.7873869, lng: 17.8974352 },
    { lat: 47.7922372, lng: 17.9024142 },
    { lat: 47.7922883, lng: 17.9024726 },
    { lat: 47.7922264, lng: 17.9027909 },
    { lat: 47.7923303, lng: 17.9025136 },
    { lat: 47.7924845, lng: 17.9026715 },
    { lat: 47.7945104, lng: 17.896067 },
    { lat: 47.7928372, lng: 17.8936288 },
    { lat: 47.7927845, lng: 17.8933042 },
    { lat: 47.7928607, lng: 17.8927768 },
    { lat: 47.792993, lng: 17.8923027 },
    { lat: 47.7939241, lng: 17.8895186 },
    { lat: 47.7943599, lng: 17.8885292 },
    { lat: 47.7946563, lng: 17.8876716 },
    { lat: 47.794821, lng: 17.8859763 },
    { lat: 47.7948217, lng: 17.8842797 },
    { lat: 47.7948039, lng: 17.8842642 },
    { lat: 47.7946757, lng: 17.8841243 },
    { lat: 47.7953612, lng: 17.8809635 },
    { lat: 47.7954263, lng: 17.880425 },
    { lat: 47.7956189, lng: 17.879502 },
    { lat: 47.7934622, lng: 17.8782386 },
    { lat: 47.7918614, lng: 17.8779784 },
    { lat: 47.7909173, lng: 17.8779172 },
    { lat: 47.7879048, lng: 17.8788423 },
    { lat: 47.7874201, lng: 17.8790925 },
    { lat: 47.7867176, lng: 17.8796167 },
    { lat: 47.7863508, lng: 17.8800798 },
    { lat: 47.7858971, lng: 17.8786459 },
    { lat: 47.7859891, lng: 17.8777181 },
    { lat: 47.7857929, lng: 17.871584 },
    { lat: 47.7857662, lng: 17.8692427 },
    { lat: 47.7858271, lng: 17.8686367 },
    { lat: 47.7857755, lng: 17.8680702 },
    { lat: 47.7851005, lng: 17.8661986 },
    { lat: 47.78424, lng: 17.8662306 },
    { lat: 47.7839378, lng: 17.8646598 },
    { lat: 47.7834603, lng: 17.8639268 },
    { lat: 47.7830893, lng: 17.8638033 },
    { lat: 47.7833311, lng: 17.862413 },
    { lat: 47.7837973, lng: 17.8607091 },
    { lat: 47.783809, lng: 17.8590635 },
    { lat: 47.7837701, lng: 17.8582675 },
    { lat: 47.7839084, lng: 17.8563191 },
    { lat: 47.7841163, lng: 17.855516 },
    { lat: 47.7846483, lng: 17.8539276 },
    { lat: 47.785117, lng: 17.8521178 },
    { lat: 47.78532, lng: 17.851494 },
    { lat: 47.785256, lng: 17.8514251 },
    { lat: 47.7851924, lng: 17.8508697 },
    { lat: 47.7851542, lng: 17.8498286 },
    { lat: 47.7854339, lng: 17.8485067 },
    { lat: 47.7859495, lng: 17.8455196 },
    { lat: 47.7862446, lng: 17.844303 },
    { lat: 47.7865663, lng: 17.8414854 },
    { lat: 47.7866277, lng: 17.8407603 },
    { lat: 47.786426, lng: 17.8393054 },
    { lat: 47.7858321, lng: 17.8361788 },
  ],
};

export default UnitsKomárno;
