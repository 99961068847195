import { useState } from "react";
import { Polygon } from "@react-google-maps/api";
import RegionBratislava from "../constants/Districts/RegionBratislava";
import RegionKošice from "../constants/Districts/RegionKosice";
import RegionTrnava from "../constants/Districts/RegionTrnava";
import RegionPresov from "../constants/Districts/RegionPresov";
import RegionBanskaBystrica from "../constants/Districts/RegionBanskaBystrica";
import RegionNitra from "../constants/Districts/RegionNitra";
import RegionTrencin from "../constants/Districts/RegionTrencin";
import RegionZilina from "../constants/Districts/RegionZilina";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

export default function Polygons({
  priceMap,
  setPriceMap,
  map,
  setZoom,
  genAvgPrice,
  setGenAvgPrice,
  setPMResult,
  PMResult,
  setRegDisUni,
  regDisUni,
  PMHistory,
  setPMHistory,
}) {
  // const color  =()=> {
  const [showTooltip, setShowTooltip] = useState({ active: false, name: "", x: 0, y: 0 });
  // }

  const defaultColor = "#7551b0";
  const green3 = "#00FFFF";
  const green1 = "#00A300";
  const green2 = "#2EFF2E";
  const yellow1 = "#D1D100";
  const yellow2 = "#FFFF2E";
  const orange1 = "#FFA500";
  // const orange2 = "#FFB52E";
  const red1 = "#FF0000";
  const red2 = "#D10000";

  const setColor = (keyy) => {
    const key = keyy.replace("District", "").replace("Outer", "");
    if (Object.keys(genAvgPrice).length > 0) {
      if (genAvgPrice.hasOwnProperty(key)) {
        if (genAvgPrice[key]?.avgBytM2 >= 100 && genAvgPrice[key]?.avgBytM2 <= 1000) {
          return green1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1000 && genAvgPrice[key]?.avgBytM2 <= 1300) {
          return green2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1300 && genAvgPrice[key]?.avgBytM2 <= 1600) {
          return yellow1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1600 && genAvgPrice[key]?.avgBytM2 <= 1800) {
          return yellow2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1800 && genAvgPrice[key]?.avgBytM2 <= 2000) {
          return orange1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2000 && genAvgPrice[key]?.avgBytM2 <= 2100) {
          return red1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2100) {
          return red2;
        }
        return green3;
      } else return defaultColor;
    } else return defaultColor;
  };

  const convertLatLngToPoint = (latLng, map) => {
    const scale = Math.pow(2, map.getZoom());
    const nw = new window.google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng(),
    );
    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(latLng);
    const pixelOffset = new window.google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
    );
    return pixelOffset;
  };

  const addSpaceBeforeUppercase = (input) => {
    if (input?.includes("Štiavnica")) {
      return input?.replace("Štiavnica", " Štiavnica");
    }
    if (input?.includes("Ľubovňa")) {
      return input?.replace("Ľubovňa", " Ľubovňa");
    }
    if (!input?.includes("Košice") && !input?.includes("Bratislava")) {
      return input?.replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ").replace("nad", " nad");
    } else {
      return input?.replace("Bratislava", "Bratislava ").replace("Košice", "Košice ");
    }
  };

  return (
    <>
      {priceMap.district ? (
        <>
          {Object.keys(RegionBratislava).map((key) => {
            if (priceMap.districtName === "RegionBratislava") {
              return (
                <Polygon
                  key={key}
                  paths={RegionBratislava[key]}
                  options={{
                    // strokeColor: "#f00d0d",
                    strokeColor: setColor(key),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: setColor(key),
                    fillOpacity: 0.35,
                  }}
                  onClick={() => {
                    // setPriceMap({ ...priceMap, district: false, city: true, cityName: key });
                    // console.log(key);
                    setPMResult({
                      ...PMResult,
                      active: true,
                      data: {
                        ...genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                        obec: "",
                      },
                      parent: key.replace("District", "").replace("Outer", ""),
                    });
                    setPMHistory({
                      ...PMHistory,
                      district: PMResult?.region + " " + key.replace("District", "").replace("Outer", ""),
                    });
                    setRegDisUni({ ...regDisUni, region: false, district: true, unit: false });
                    // setCenterBratislava(key);
                    // setZoom(10);
                    // getAvgPrice(key);
                  }}
                  onMouseOver={(e) => {
                    const latLng = e.latLng;
                    const point = convertLatLngToPoint(latLng, map);
                    setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                  }}
                  onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
                />
              );
            } else return null;
          })}
          {Object.keys(RegionKošice).map((key) => {
            if (
              priceMap.districtName === "RegionKošice" &&
              key !== "DistrictKošiceokolieInner" &&
              key !== "DistrictKošiceokolieOuter"
            ) {
              return (
                <Polygon
                  key={key}
                  paths={RegionKošice[key]}
                  options={{
                    strokeColor: setColor(key),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: setColor(key),
                    fillOpacity: 0.35,
                  }}
                  onClick={() => {
                    // setPriceMap({ ...priceMap, district: false, city: true, cityName: key });
                    // console.log(key);
                    setPMResult({
                      ...PMResult,
                      active: true,
                      data: {
                        ...genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                        obec: "",
                      },
                      parent: key.replace("District", "").replace("Outer", ""),
                    });
                    setPMHistory({
                      ...PMHistory,
                      district: PMResult?.region + " " + key.replace("District", "").replace("Outer", ""),
                    });
                    setRegDisUni({ ...regDisUni, region: false, district: true, unit: false });
                    // setCenterKosice(key);
                    // setZoom(10);
                    // getAvgPrice(key);
                  }}
                  onMouseOver={(e) => {
                    const latLng = e.latLng;
                    const point = convertLatLngToPoint(latLng, map);
                    setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                  }}
                  onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
                />
              );
            }
            if (key === "DistrictKošiceokolieOuter" && priceMap.districtName === "RegionKošice") {
              return (
                <Polygon
                  key={key}
                  paths={[RegionKošice.DistrictKošiceokolieInner, RegionKošice.DistrictKošiceokolieOuter]}
                  options={{
                    strokeColor: setColor(key),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: setColor(key),
                    fillOpacity: 0.35,
                  }}
                  onClick={() => {
                    // setPriceMap({ ...priceMap, district: false, city: true, cityName: key });
                    // console.log(key);
                    setPMResult({
                      ...PMResult,
                      active: true,
                      data: {
                        ...genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                        obec: "",
                      },
                      parent: key.replace("District", "").replace("Outer", ""),
                    });
                    setPMHistory({ ...PMHistory, district: key.replace("District", "").replace("Outer", "") });
                    setRegDisUni({ ...regDisUni, region: false, district: true, unit: false });
                    // setCenterKosice(key);
                    // setZoom(10);
                    // getAvgPrice(key);
                  }}
                  onMouseOver={(e) => {
                    const latLng = e.latLng;
                    const point = convertLatLngToPoint(latLng, map);
                    setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                  }}
                  onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
                />
              );
            } else return null;
          })}
          {Object.keys(RegionTrnava).map((key) => {
            if (priceMap.districtName === "RegionTrnava") {
              return (
                <Polygon
                  key={key}
                  paths={RegionTrnava[key]}
                  options={{
                    strokeColor: setColor(key),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: setColor(key),
                    fillOpacity: 0.35,
                  }}
                  onClick={() => {
                    // setPriceMap({ ...priceMap, district: false, city: true, cityName: key });
                    // console.log(key);
                    setPMResult({
                      ...PMResult,
                      active: true,
                      data: {
                        ...genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                        obec: "",
                      },
                      parent: key.replace("District", "").replace("Outer", ""),
                    });
                    setPMHistory({
                      ...PMHistory,
                      district: PMResult?.region + " " + key.replace("District", "").replace("Outer", ""),
                    });
                    setRegDisUni({ ...regDisUni, region: false, district: true, unit: false });
                    // setCenterTrnava(key);
                    // setZoom(10);
                    // getAvgPrice(key);
                  }}
                  onMouseOver={(e) => {
                    const latLng = e.latLng;
                    const point = convertLatLngToPoint(latLng, map);
                    setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                  }}
                  onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
                />
              );
            } else return null;
          })}
          {Object.keys(RegionPresov).map((key) => {
            if (priceMap.districtName === "RegionPrešov") {
              return (
                <Polygon
                  key={key}
                  paths={RegionPresov[key]}
                  options={{
                    strokeColor: setColor(key),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: setColor(key),
                    fillOpacity: 0.35,
                  }}
                  onClick={() => {
                    // setPriceMap({ ...priceMap, district: false, city: true, cityName: key });
                    // console.log(key);
                    setPMResult({
                      ...PMResult,
                      active: true,
                      data: {
                        ...genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                        obec: "",
                      },
                      parent: key.replace("District", "").replace("Outer", ""),
                    });
                    setPMHistory({
                      ...PMHistory,
                      district: PMResult?.region + " " + key.replace("District", "").replace("Outer", ""),
                    });
                    setRegDisUni({ ...regDisUni, region: false, district: true, unit: false });
                    // setCenterPresov(key);
                    // setZoom(10);
                    // getAvgPrice(key);
                  }}
                  onMouseOver={(e) => {
                    const latLng = e.latLng;
                    const point = convertLatLngToPoint(latLng, map);
                    setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                  }}
                  onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
                />
              );
            } else return null;
          })}
          {Object.keys(RegionBanskaBystrica).map((key) => {
            if (priceMap.districtName === "RegionBanskáBystrica") {
              return (
                <Polygon
                  key={key}
                  paths={RegionBanskaBystrica[key]}
                  options={{
                    strokeColor: setColor(key),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: setColor(key),
                    fillOpacity: 0.35,
                  }}
                  onClick={() => {
                    // setPriceMap({ ...priceMap, district: false, city: true, cityName: key });
                    // console.log(key);
                    setPMResult({
                      ...PMResult,
                      active: true,
                      data: {
                        ...genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                        obec: "",
                      },
                      parent: key.replace("District", "").replace("Outer", ""),
                    });
                    setPMHistory({
                      ...PMHistory,
                      district: PMResult?.region + " " + key.replace("District", "").replace("Outer", ""),
                    });
                    setRegDisUni({ ...regDisUni, region: false, district: true, unit: false });
                    // setCenterBanskaBystrica(key);
                    // setZoom(10);
                    // getAvgPrice(key);
                  }}
                  onMouseOver={(e) => {
                    const latLng = e.latLng;
                    const point = convertLatLngToPoint(latLng, map);
                    setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                  }}
                  onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
                />
              );
            } else return null;
          })}
          {Object.keys(RegionNitra).map((key) => {
            if (priceMap.districtName === "RegionNitra") {
              return (
                <Polygon
                  key={key}
                  paths={RegionNitra[key]}
                  options={{
                    strokeColor: setColor(key),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: setColor(key),
                    fillOpacity: 0.35,
                  }}
                  onClick={() => {
                    // setPriceMap({ ...priceMap, district: false, city: true, cityName: key });
                    // console.log(key);
                    setPMResult({
                      ...PMResult,
                      active: true,
                      data: {
                        ...genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                        obec: "",
                      },
                      parent: key.replace("District", "").replace("Outer", ""),
                    });
                    setPMHistory({
                      ...PMHistory,
                      district: PMResult?.region + " " + key.replace("District", "").replace("Outer", ""),
                    });
                    setRegDisUni({ ...regDisUni, region: false, district: true, unit: false });
                    // setCenterNitra(key);
                    // setZoom(10);
                    // getAvgPrice(key);
                  }}
                  onMouseOver={(e) => {
                    const latLng = e.latLng;
                    const point = convertLatLngToPoint(latLng, map);
                    setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                  }}
                  onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
                />
              );
            } else return null;
          })}
          {Object.keys(RegionTrencin).map((key) => {
            if (priceMap.districtName === "RegionTrenčín") {
              return (
                <Polygon
                  key={key}
                  paths={RegionTrencin[key]}
                  options={{
                    strokeColor: setColor(key),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: setColor(key),
                    fillOpacity: 0.35,
                  }}
                  onClick={() => {
                    // setPriceMap({ ...priceMap, district: false, city: true, cityName: key });
                    // console.log(key);
                    setPMResult({
                      ...PMResult,
                      active: true,
                      data: {
                        ...genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                        obec: "",
                      },
                      parent: key.replace("District", "").replace("Outer", ""),
                    });
                    setPMHistory({
                      ...PMHistory,
                      district: PMResult?.region + " " + key.replace("District", "").replace("Outer", ""),
                    });
                    setRegDisUni({ ...regDisUni, region: false, district: true, unit: false });
                    // setCenterTrencin(key);
                    // setZoom(10);
                    // getAvgPrice(key);
                  }}
                  onMouseOver={(e) => {
                    const latLng = e.latLng;
                    const point = convertLatLngToPoint(latLng, map);
                    setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                  }}
                  onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
                />
              );
            } else return null;
          })}
          {Object.keys(RegionZilina).map((key) => {
            if (priceMap.districtName === "RegionŽilina") {
              return (
                <Polygon
                  key={key}
                  paths={RegionZilina[key]}
                  options={{
                    strokeColor: setColor(key),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: setColor(key),
                    fillOpacity: 0.35,
                  }}
                  onClick={() => {
                    // setPriceMap({ ...priceMap, district: false, city: true, cityName: key });
                    // console.log(key);
                    setPMResult({
                      ...PMResult,
                      active: true,
                      data: {
                        ...genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")],
                        obec: "",
                      },
                      parent: key.replace("District", "").replace("Outer", ""),
                    });
                    setPMHistory({
                      ...PMHistory,
                      district: PMResult?.region + " " + key.replace("District", "").replace("Outer", ""),
                    });
                    setRegDisUni({ ...regDisUni, region: false, district: true, unit: false });
                    // setCenterZilina(key);
                    // setZoom(10);
                    // getAvgPrice(key);
                  }}
                  onMouseOver={(e) => {
                    const latLng = e.latLng;
                    const point = convertLatLngToPoint(latLng, map);
                    setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                  }}
                  onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
                />
              );
            } else return null;
          })}
          {showTooltip.active ? (
            <div
              className="absolute mb-2 h-auto w-auto p-2 bg-sky-200 text-start text-sm rounded-lg opacity-100 transition-opacity duration-300"
              style={{ top: showTooltip.y + 10, left: showTooltip.x + 10 }}
            >
              <div className="flex flex-col">
                <div className="flex flex-row gap-1 justify-between items-center">
                  Trhová cena pre
                  <FontAwesomeIcon icon={faTags} />
                </div>
                {/* <div>
                  {addSpaceBeforeUppercase(showTooltip.name.replace("District", "okres ").replace("Outer", ""))}
                </div>
                */}
                <div>
                  okres{" "}
                  {addSpaceBeforeUppercase(
                    genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")]?.obec,
                  )}
                </div>
                <div>
                  za dom: {genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")]?.avgDom} €
                </div>
                <div>
                  za m² bytu: {genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")]?.avgBytM2} €
                </div>
                <div>
                  za m² pozemku:{" "}
                  {genAvgPrice[showTooltip.name.replace("District", "").replace("Outer", "")]?.avgPozemokM2} €
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
}
