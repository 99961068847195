const UnitsTurčianskeTeplice = {
  Blažovce: [
    { lat: 48.9335083, lng: 18.8400314 },
    { lat: 48.9337183, lng: 18.8403446 },
    { lat: 48.9337417, lng: 18.8404461 },
    { lat: 48.9334929, lng: 18.8405414 },
    { lat: 48.9320008, lng: 18.8415633 },
    { lat: 48.9313252, lng: 18.8420364 },
    { lat: 48.9312146, lng: 18.842169 },
    { lat: 48.9311567, lng: 18.8423096 },
    { lat: 48.9308645, lng: 18.8443835 },
    { lat: 48.9307952, lng: 18.8446345 },
    { lat: 48.9302003, lng: 18.8461491 },
    { lat: 48.9301372, lng: 18.8465884 },
    { lat: 48.930107, lng: 18.8468562 },
    { lat: 48.9302007, lng: 18.8472285 },
    { lat: 48.9307909, lng: 18.8497733 },
    { lat: 48.9309763, lng: 18.8505674 },
    { lat: 48.9316474, lng: 18.8534552 },
    { lat: 48.9317892, lng: 18.8542162 },
    { lat: 48.9318926, lng: 18.8556122 },
    { lat: 48.9319702, lng: 18.8565906 },
    { lat: 48.9321052, lng: 18.8581141 },
    { lat: 48.932171, lng: 18.859326 },
    { lat: 48.9323527, lng: 18.8621526 },
    { lat: 48.9342099, lng: 18.8627915 },
    { lat: 48.9363484, lng: 18.8633301 },
    { lat: 48.9375633, lng: 18.8635783 },
    { lat: 48.937866, lng: 18.864861 },
    { lat: 48.938685, lng: 18.864537 },
    { lat: 48.938954, lng: 18.863451 },
    { lat: 48.939184, lng: 18.862496 },
    { lat: 48.93951, lng: 18.861154 },
    { lat: 48.93984, lng: 18.860097 },
    { lat: 48.939897, lng: 18.860051 },
    { lat: 48.940333, lng: 18.85971 },
    { lat: 48.94131, lng: 18.858824 },
    { lat: 48.941369, lng: 18.858719 },
    { lat: 48.941952, lng: 18.858108 },
    { lat: 48.941982, lng: 18.858097 },
    { lat: 48.942051, lng: 18.858074 },
    { lat: 48.942074, lng: 18.858066 },
    { lat: 48.942659, lng: 18.857867 },
    { lat: 48.942783, lng: 18.856816 },
    { lat: 48.942845, lng: 18.856154 },
    { lat: 48.942853, lng: 18.855166 },
    { lat: 48.942939, lng: 18.853593 },
    { lat: 48.943259, lng: 18.852806 },
    { lat: 48.943739, lng: 18.851721 },
    { lat: 48.944411, lng: 18.85164 },
    { lat: 48.944627, lng: 18.851799 },
    { lat: 48.944755, lng: 18.851893 },
    { lat: 48.9447873, lng: 18.851865 },
    { lat: 48.945396, lng: 18.851337 },
    { lat: 48.946185, lng: 18.850723 },
    { lat: 48.947495, lng: 18.850581 },
    { lat: 48.947931, lng: 18.850817 },
    { lat: 48.948265, lng: 18.851003 },
    { lat: 48.948419, lng: 18.851383 },
    { lat: 48.94868, lng: 18.851328 },
    { lat: 48.949236, lng: 18.850849 },
    { lat: 48.949394, lng: 18.850481 },
    { lat: 48.949489, lng: 18.85027 },
    { lat: 48.949595, lng: 18.849756 },
    { lat: 48.949598, lng: 18.849034 },
    { lat: 48.949517, lng: 18.848563 },
    { lat: 48.949381, lng: 18.848035 },
    { lat: 48.948865, lng: 18.847744 },
    { lat: 48.948497, lng: 18.846847 },
    { lat: 48.948333, lng: 18.846694 },
    { lat: 48.948231, lng: 18.846661 },
    { lat: 48.947933, lng: 18.846729 },
    { lat: 48.947717, lng: 18.846792 },
    { lat: 48.947571, lng: 18.846641 },
    { lat: 48.947456, lng: 18.845938 },
    { lat: 48.947373, lng: 18.845714 },
    { lat: 48.947274, lng: 18.845595 },
    { lat: 48.94716, lng: 18.845538 },
    { lat: 48.947025, lng: 18.845564 },
    { lat: 48.946935, lng: 18.845593 },
    { lat: 48.94635, lng: 18.84662 },
    { lat: 48.946148, lng: 18.846624 },
    { lat: 48.945894, lng: 18.846549 },
    { lat: 48.945879, lng: 18.845822 },
    { lat: 48.946012, lng: 18.845089 },
    { lat: 48.946372, lng: 18.844603 },
    { lat: 48.94639, lng: 18.844529 },
    { lat: 48.946398, lng: 18.844497 },
    { lat: 48.946457, lng: 18.84443 },
    { lat: 48.946468, lng: 18.844417 },
    { lat: 48.94681, lng: 18.844374 },
    { lat: 48.947057, lng: 18.844203 },
    { lat: 48.947128, lng: 18.843946 },
    { lat: 48.947093, lng: 18.843104 },
    { lat: 48.94699, lng: 18.842826 },
    { lat: 48.94674, lng: 18.842638 },
    { lat: 48.946594, lng: 18.842566 },
    { lat: 48.946198, lng: 18.84297 },
    { lat: 48.945935, lng: 18.843483 },
    { lat: 48.94566, lng: 18.843207 },
    { lat: 48.945521, lng: 18.842415 },
    { lat: 48.945387, lng: 18.841766 },
    { lat: 48.945617, lng: 18.841426 },
    { lat: 48.945865, lng: 18.84137 },
    { lat: 48.946521, lng: 18.841681 },
    { lat: 48.946781, lng: 18.841258 },
    { lat: 48.947067, lng: 18.840745 },
    { lat: 48.947151, lng: 18.840468 },
    { lat: 48.947422, lng: 18.840168 },
    { lat: 48.947628, lng: 18.839729 },
    { lat: 48.94762, lng: 18.839538 },
    { lat: 48.947774, lng: 18.839198 },
    { lat: 48.947861, lng: 18.838877 },
    { lat: 48.94786, lng: 18.838526 },
    { lat: 48.94778, lng: 18.838161 },
    { lat: 48.947569, lng: 18.837716 },
    { lat: 48.947416, lng: 18.837191 },
    { lat: 48.9471656, lng: 18.8364569 },
    { lat: 48.9469725, lng: 18.8360993 },
    { lat: 48.9468422, lng: 18.8358677 },
    { lat: 48.9465867, lng: 18.8357941 },
    { lat: 48.9461873, lng: 18.8359389 },
    { lat: 48.9460981, lng: 18.836219 },
    { lat: 48.9460945, lng: 18.8368945 },
    { lat: 48.9459277, lng: 18.8376584 },
    { lat: 48.9457673, lng: 18.8377973 },
    { lat: 48.945624, lng: 18.8378026 },
    { lat: 48.9451246, lng: 18.8370876 },
    { lat: 48.9448659, lng: 18.8370098 },
    { lat: 48.94448, lng: 18.8370514 },
    { lat: 48.9441539, lng: 18.8366304 },
    { lat: 48.9440999, lng: 18.8362201 },
    { lat: 48.9443255, lng: 18.8356307 },
    { lat: 48.9443423, lng: 18.835238 },
    { lat: 48.9441835, lng: 18.8344979 },
    { lat: 48.9440072, lng: 18.8338812 },
    { lat: 48.9446454, lng: 18.8331404 },
    { lat: 48.9446409, lng: 18.8328392 },
    { lat: 48.9446389, lng: 18.8327078 },
    { lat: 48.9443288, lng: 18.8320536 },
    { lat: 48.9439393, lng: 18.8318059 },
    { lat: 48.9437944, lng: 18.831991 },
    { lat: 48.9435362, lng: 18.8321886 },
    { lat: 48.9433419, lng: 18.8320504 },
    { lat: 48.9430066, lng: 18.8314745 },
    { lat: 48.9427122, lng: 18.8311706 },
    { lat: 48.9419806, lng: 18.830585 },
    { lat: 48.9417567, lng: 18.8306594 },
    { lat: 48.9417453, lng: 18.8306306 },
    { lat: 48.9415996, lng: 18.8302624 },
    { lat: 48.9413309, lng: 18.8299512 },
    { lat: 48.9411042, lng: 18.8298638 },
    { lat: 48.9406045, lng: 18.829663 },
    { lat: 48.9405867, lng: 18.8296016 },
    { lat: 48.9404932, lng: 18.8295586 },
    { lat: 48.9404252, lng: 18.8295546 },
    { lat: 48.9401583, lng: 18.8294289 },
    { lat: 48.9398143, lng: 18.8296027 },
    { lat: 48.9392816, lng: 18.8296594 },
    { lat: 48.938752, lng: 18.8297309 },
    { lat: 48.9385038, lng: 18.8296432 },
    { lat: 48.9384172, lng: 18.8295348 },
    { lat: 48.9383563, lng: 18.8293818 },
    { lat: 48.9383093, lng: 18.8287156 },
    { lat: 48.9382426, lng: 18.8283623 },
    { lat: 48.937933, lng: 18.8280697 },
    { lat: 48.9378286, lng: 18.8279889 },
    { lat: 48.9376651, lng: 18.8282067 },
    { lat: 48.9375722, lng: 18.8285901 },
    { lat: 48.9372463, lng: 18.8290271 },
    { lat: 48.9369552, lng: 18.829068 },
    { lat: 48.9367736, lng: 18.8289146 },
    { lat: 48.9363704, lng: 18.8284211 },
    { lat: 48.9362133, lng: 18.8282849 },
    { lat: 48.9360776, lng: 18.8281003 },
    { lat: 48.9359729, lng: 18.8280826 },
    { lat: 48.9358247, lng: 18.8279841 },
    { lat: 48.9356874, lng: 18.827752 },
    { lat: 48.9358355, lng: 18.827542 },
    { lat: 48.9360599, lng: 18.8273901 },
    { lat: 48.9363542, lng: 18.8273467 },
    { lat: 48.9366224, lng: 18.8273649 },
    { lat: 48.936967, lng: 18.827161 },
    { lat: 48.937152, lng: 18.8271199 },
    { lat: 48.9373115, lng: 18.8267364 },
    { lat: 48.9373614, lng: 18.8263277 },
    { lat: 48.9373139, lng: 18.8260676 },
    { lat: 48.9371031, lng: 18.8258058 },
    { lat: 48.9365852, lng: 18.8254057 },
    { lat: 48.936454, lng: 18.8252686 },
    { lat: 48.936328, lng: 18.8253403 },
    { lat: 48.936164, lng: 18.825527 },
    { lat: 48.9360106, lng: 18.8256762 },
    { lat: 48.9357577, lng: 18.8258022 },
    { lat: 48.9351486, lng: 18.8256895 },
    { lat: 48.9346585, lng: 18.8255643 },
    { lat: 48.9342795, lng: 18.8255831 },
    { lat: 48.9341275, lng: 18.8255227 },
    { lat: 48.9340665, lng: 18.8252391 },
    { lat: 48.9339651, lng: 18.8248506 },
    { lat: 48.9337767, lng: 18.8244757 },
    { lat: 48.9337722, lng: 18.8244755 },
    { lat: 48.9333944, lng: 18.8243252 },
    { lat: 48.933229, lng: 18.8243768 },
    { lat: 48.9331046, lng: 18.8245423 },
    { lat: 48.9330506, lng: 18.8248045 },
    { lat: 48.9330138, lng: 18.8253798 },
    { lat: 48.9330711, lng: 18.8257477 },
    { lat: 48.9330272, lng: 18.8260306 },
    { lat: 48.9329242, lng: 18.8261551 },
    { lat: 48.9326916, lng: 18.8261667 },
    { lat: 48.9327163, lng: 18.8264288 },
    { lat: 48.9325963, lng: 18.8265475 },
    { lat: 48.9323875, lng: 18.8266546 },
    { lat: 48.9321854, lng: 18.8266489 },
    { lat: 48.9321675, lng: 18.8268913 },
    { lat: 48.9322907, lng: 18.8268869 },
    { lat: 48.9325885, lng: 18.8268762 },
    { lat: 48.9326796, lng: 18.8272204 },
    { lat: 48.9327084, lng: 18.8273432 },
    { lat: 48.9326785, lng: 18.8275702 },
    { lat: 48.9325581, lng: 18.8283607 },
    { lat: 48.9325954, lng: 18.8291864 },
    { lat: 48.9329333, lng: 18.8314686 },
    { lat: 48.9329994, lng: 18.8316544 },
    { lat: 48.9331442, lng: 18.8327529 },
    { lat: 48.9332324, lng: 18.833696 },
    { lat: 48.9332698, lng: 18.8340487 },
    { lat: 48.9332745, lng: 18.8343985 },
    { lat: 48.9332909, lng: 18.8347391 },
    { lat: 48.9332979, lng: 18.8347885 },
    { lat: 48.9333238, lng: 18.8355482 },
    { lat: 48.9335406, lng: 18.8398737 },
    { lat: 48.9335083, lng: 18.8400314 },
  ],
  Borcová: [
    { lat: 48.932171, lng: 18.859326 },
    { lat: 48.9321052, lng: 18.8581141 },
    { lat: 48.9319702, lng: 18.8565906 },
    { lat: 48.9318926, lng: 18.8556122 },
    { lat: 48.9317892, lng: 18.8542162 },
    { lat: 48.9316474, lng: 18.8534552 },
    { lat: 48.9309763, lng: 18.8505674 },
    { lat: 48.9307909, lng: 18.8497733 },
    { lat: 48.9302007, lng: 18.8472285 },
    { lat: 48.930107, lng: 18.8468562 },
    { lat: 48.9301372, lng: 18.8465884 },
    { lat: 48.9302003, lng: 18.8461491 },
    { lat: 48.9307952, lng: 18.8446345 },
    { lat: 48.9308645, lng: 18.8443835 },
    { lat: 48.9311567, lng: 18.8423096 },
    { lat: 48.9312146, lng: 18.842169 },
    { lat: 48.9313252, lng: 18.8420364 },
    { lat: 48.9320008, lng: 18.8415633 },
    { lat: 48.9334929, lng: 18.8405414 },
    { lat: 48.9337417, lng: 18.8404461 },
    { lat: 48.9337183, lng: 18.8403446 },
    { lat: 48.9335083, lng: 18.8400314 },
    { lat: 48.9331135, lng: 18.8398998 },
    { lat: 48.9329013, lng: 18.8397429 },
    { lat: 48.932638, lng: 18.8396864 },
    { lat: 48.9325772, lng: 18.8396434 },
    { lat: 48.9312942, lng: 18.8391052 },
    { lat: 48.9312396, lng: 18.8390722 },
    { lat: 48.930906, lng: 18.8386619 },
    { lat: 48.9302505, lng: 18.8378492 },
    { lat: 48.9297253, lng: 18.8371773 },
    { lat: 48.9296055, lng: 18.8370628 },
    { lat: 48.9295007, lng: 18.8369329 },
    { lat: 48.9292513, lng: 18.8366654 },
    { lat: 48.929091, lng: 18.8365772 },
    { lat: 48.9289735, lng: 18.8365532 },
    { lat: 48.9288173, lng: 18.8365392 },
    { lat: 48.9285855, lng: 18.8365489 },
    { lat: 48.9284599, lng: 18.8365413 },
    { lat: 48.928412, lng: 18.8365283 },
    { lat: 48.928385, lng: 18.8365312 },
    { lat: 48.9279704, lng: 18.8366702 },
    { lat: 48.9277204, lng: 18.8366516 },
    { lat: 48.9272349, lng: 18.8367722 },
    { lat: 48.9268643, lng: 18.8367156 },
    { lat: 48.9265824, lng: 18.8367793 },
    { lat: 48.9263355, lng: 18.8369303 },
    { lat: 48.9261194, lng: 18.8367452 },
    { lat: 48.9256836, lng: 18.8365784 },
    { lat: 48.9254879, lng: 18.8365235 },
    { lat: 48.925383, lng: 18.8363721 },
    { lat: 48.9252675, lng: 18.8366018 },
    { lat: 48.9251624, lng: 18.8365763 },
    { lat: 48.9250895, lng: 18.8361253 },
    { lat: 48.9249062, lng: 18.8360722 },
    { lat: 48.9248161, lng: 18.8360696 },
    { lat: 48.9248125, lng: 18.8360683 },
    { lat: 48.9246551, lng: 18.8360999 },
    { lat: 48.924544, lng: 18.8361551 },
    { lat: 48.9245013, lng: 18.8363302 },
    { lat: 48.9244787, lng: 18.8367879 },
    { lat: 48.9245267, lng: 18.8370167 },
    { lat: 48.9244879, lng: 18.8371805 },
    { lat: 48.9242974, lng: 18.8371197 },
    { lat: 48.9240222, lng: 18.8370531 },
    { lat: 48.9238014, lng: 18.8369044 },
    { lat: 48.9235955, lng: 18.8373453 },
    { lat: 48.9235846, lng: 18.8378988 },
    { lat: 48.9235219, lng: 18.8380202 },
    { lat: 48.9233898, lng: 18.8379929 },
    { lat: 48.9231208, lng: 18.8375018 },
    { lat: 48.9230772, lng: 18.8372471 },
    { lat: 48.9228694, lng: 18.8369979 },
    { lat: 48.9227865, lng: 18.8370544 },
    { lat: 48.9227098, lng: 18.8374042 },
    { lat: 48.9227999, lng: 18.8380535 },
    { lat: 48.9226513, lng: 18.838428 },
    { lat: 48.9222647, lng: 18.8387168 },
    { lat: 48.9221465, lng: 18.8386548 },
    { lat: 48.9219494, lng: 18.8387048 },
    { lat: 48.9218161, lng: 18.838914 },
    { lat: 48.9217187, lng: 18.8392329 },
    { lat: 48.9214521, lng: 18.8393141 },
    { lat: 48.9212628, lng: 18.8389896 },
    { lat: 48.9211732, lng: 18.8390207 },
    { lat: 48.9207141, lng: 18.8398932 },
    { lat: 48.9205545, lng: 18.8400742 },
    { lat: 48.920448, lng: 18.8400209 },
    { lat: 48.920344, lng: 18.8400201 },
    { lat: 48.9201641, lng: 18.8398529 },
    { lat: 48.9201397, lng: 18.8397063 },
    { lat: 48.9200741, lng: 18.8397266 },
    { lat: 48.920058, lng: 18.8400261 },
    { lat: 48.9199832, lng: 18.8401075 },
    { lat: 48.919655, lng: 18.8401014 },
    { lat: 48.9196215, lng: 18.8400017 },
    { lat: 48.9195592, lng: 18.8399536 },
    { lat: 48.9194024, lng: 18.8400612 },
    { lat: 48.9193893, lng: 18.8402635 },
    { lat: 48.9194224, lng: 18.8403603 },
    { lat: 48.9193604, lng: 18.8404681 },
    { lat: 48.9193095, lng: 18.8404899 },
    { lat: 48.9192217, lng: 18.8404452 },
    { lat: 48.9190814, lng: 18.8404604 },
    { lat: 48.9189657, lng: 18.8404477 },
    { lat: 48.918793, lng: 18.8407916 },
    { lat: 48.9186059, lng: 18.8412086 },
    { lat: 48.9186136, lng: 18.8412482 },
    { lat: 48.9186086, lng: 18.8413894 },
    { lat: 48.9184932, lng: 18.8443717 },
    { lat: 48.9183465, lng: 18.844415 },
    { lat: 48.9181785, lng: 18.8445002 },
    { lat: 48.9178748, lng: 18.8446919 },
    { lat: 48.9178669, lng: 18.8447997 },
    { lat: 48.9175389, lng: 18.8484902 },
    { lat: 48.9175787, lng: 18.8485622 },
    { lat: 48.918841, lng: 18.8490109 },
    { lat: 48.9192622, lng: 18.8491752 },
    { lat: 48.919271, lng: 18.8492362 },
    { lat: 48.9187982, lng: 18.8529221 },
    { lat: 48.9211763, lng: 18.8540598 },
    { lat: 48.921556, lng: 18.8543402 },
    { lat: 48.9222551, lng: 18.8549062 },
    { lat: 48.9235792, lng: 18.8544564 },
    { lat: 48.9243164, lng: 18.8559587 },
    { lat: 48.9251386, lng: 18.8575571 },
    { lat: 48.9251469, lng: 18.8575683 },
    { lat: 48.92496, lng: 18.8579031 },
    { lat: 48.9247787, lng: 18.8583639 },
    { lat: 48.9245842, lng: 18.8587402 },
    { lat: 48.9246553, lng: 18.8587781 },
    { lat: 48.927465, lng: 18.8613261 },
    { lat: 48.9291402, lng: 18.8623723 },
    { lat: 48.9293554, lng: 18.862151 },
    { lat: 48.929498, lng: 18.8621165 },
    { lat: 48.9296189, lng: 18.8621194 },
    { lat: 48.9299042, lng: 18.8618151 },
    { lat: 48.930113, lng: 18.8616892 },
    { lat: 48.9301414, lng: 18.8612954 },
    { lat: 48.9315376, lng: 18.860359 },
    { lat: 48.9320606, lng: 18.8594582 },
    { lat: 48.9320937, lng: 18.8593352 },
    { lat: 48.932171, lng: 18.859326 },
  ],
  Brieštie: [
    { lat: 48.937493, lng: 18.720277 },
    { lat: 48.936203, lng: 18.720128 },
    { lat: 48.93604, lng: 18.71947 },
    { lat: 48.93611, lng: 18.718789 },
    { lat: 48.937419, lng: 18.717192 },
    { lat: 48.937378, lng: 18.717098 },
    { lat: 48.935957, lng: 18.713803 },
    { lat: 48.934758, lng: 18.713731 },
    { lat: 48.9326826, lng: 18.7114505 },
    { lat: 48.931603, lng: 18.710233 },
    { lat: 48.931145, lng: 18.709695 },
    { lat: 48.931021, lng: 18.709757 },
    { lat: 48.930914, lng: 18.70981 },
    { lat: 48.930811, lng: 18.709862 },
    { lat: 48.930735, lng: 18.709917 },
    { lat: 48.930421, lng: 18.710173 },
    { lat: 48.930199, lng: 18.710444 },
    { lat: 48.929689, lng: 18.710704 },
    { lat: 48.929167, lng: 18.710664 },
    { lat: 48.929235, lng: 18.710382 },
    { lat: 48.928944, lng: 18.709637 },
    { lat: 48.928483, lng: 18.708729 },
    { lat: 48.929048, lng: 18.707948 },
    { lat: 48.92961, lng: 18.707165 },
    { lat: 48.92957, lng: 18.70668 },
    { lat: 48.930174, lng: 18.705729 },
    { lat: 48.930569, lng: 18.704861 },
    { lat: 48.930698, lng: 18.703967 },
    { lat: 48.93064, lng: 18.703606 },
    { lat: 48.930242, lng: 18.703353 },
    { lat: 48.928648, lng: 18.703268 },
    { lat: 48.926647, lng: 18.702905 },
    { lat: 48.926381, lng: 18.702999 },
    { lat: 48.926369, lng: 18.702768 },
    { lat: 48.926861, lng: 18.701846 },
    { lat: 48.927394, lng: 18.700809 },
    { lat: 48.927987, lng: 18.699698 },
    { lat: 48.928516, lng: 18.698688 },
    { lat: 48.92839, lng: 18.698353 },
    { lat: 48.928035, lng: 18.69804 },
    { lat: 48.927364, lng: 18.697822 },
    { lat: 48.92724, lng: 18.69674 },
    { lat: 48.927398, lng: 18.696017 },
    { lat: 48.927837, lng: 18.694983 },
    { lat: 48.927928, lng: 18.694597 },
    { lat: 48.92736, lng: 18.694202 },
    { lat: 48.927097, lng: 18.694304 },
    { lat: 48.926784, lng: 18.694264 },
    { lat: 48.926651, lng: 18.694091 },
    { lat: 48.926414, lng: 18.694038 },
    { lat: 48.925874, lng: 18.693843 },
    { lat: 48.925612, lng: 18.693749 },
    { lat: 48.925346, lng: 18.693749 },
    { lat: 48.924939, lng: 18.694408 },
    { lat: 48.924527, lng: 18.695136 },
    { lat: 48.924329, lng: 18.695685 },
    { lat: 48.924098, lng: 18.695591 },
    { lat: 48.923814, lng: 18.695735 },
    { lat: 48.923477, lng: 18.696357 },
    { lat: 48.922789, lng: 18.696483 },
    { lat: 48.922118, lng: 18.696241 },
    { lat: 48.92153, lng: 18.695337 },
    { lat: 48.921073, lng: 18.695323 },
    { lat: 48.920552, lng: 18.695313 },
    { lat: 48.919963, lng: 18.696354 },
    { lat: 48.919439, lng: 18.697241 },
    { lat: 48.918634, lng: 18.696666 },
    { lat: 48.918432, lng: 18.697041 },
    { lat: 48.918175, lng: 18.697545 },
    { lat: 48.917674, lng: 18.697564 },
    { lat: 48.917274, lng: 18.697237 },
    { lat: 48.916818, lng: 18.697114 },
    { lat: 48.916236, lng: 18.696986 },
    { lat: 48.915469, lng: 18.696443 },
    { lat: 48.914683, lng: 18.695965 },
    { lat: 48.914492, lng: 18.695715 },
    { lat: 48.914272, lng: 18.695424 },
    { lat: 48.914074, lng: 18.695149 },
    { lat: 48.913894, lng: 18.694913 },
    { lat: 48.913139, lng: 18.69494 },
    { lat: 48.912901, lng: 18.694425 },
    { lat: 48.912448, lng: 18.694165 },
    { lat: 48.911939, lng: 18.693616 },
    { lat: 48.911782, lng: 18.693191 },
    { lat: 48.911276, lng: 18.693364 },
    { lat: 48.910973, lng: 18.693165 },
    { lat: 48.910784, lng: 18.693572 },
    { lat: 48.909602, lng: 18.693812 },
    { lat: 48.908901, lng: 18.693642 },
    { lat: 48.908638, lng: 18.693977 },
    { lat: 48.908644, lng: 18.694059 },
    { lat: 48.908286, lng: 18.695254 },
    { lat: 48.908146, lng: 18.695244 },
    { lat: 48.908103, lng: 18.695617 },
    { lat: 48.908184, lng: 18.69594 },
    { lat: 48.907843, lng: 18.697038 },
    { lat: 48.907691, lng: 18.697866 },
    { lat: 48.906621, lng: 18.698475 },
    { lat: 48.905701, lng: 18.698916 },
    { lat: 48.904816, lng: 18.699669 },
    { lat: 48.903889, lng: 18.700292 },
    { lat: 48.903571, lng: 18.701008 },
    { lat: 48.90335, lng: 18.701744 },
    { lat: 48.902918, lng: 18.702434 },
    { lat: 48.902587, lng: 18.702725 },
    { lat: 48.901789, lng: 18.703299 },
    { lat: 48.901233, lng: 18.703709 },
    { lat: 48.90098, lng: 18.703892 },
    { lat: 48.9029848, lng: 18.7072367 },
    { lat: 48.9029281, lng: 18.7086584 },
    { lat: 48.9032689, lng: 18.7097709 },
    { lat: 48.903722, lng: 18.7100682 },
    { lat: 48.9041752, lng: 18.7110641 },
    { lat: 48.9038588, lng: 18.7116733 },
    { lat: 48.9033131, lng: 18.7124006 },
    { lat: 48.9030125, lng: 18.7131788 },
    { lat: 48.9024674, lng: 18.7151775 },
    { lat: 48.9019875, lng: 18.7157639 },
    { lat: 48.9019733, lng: 18.7157943 },
    { lat: 48.9017375, lng: 18.7164137 },
    { lat: 48.9014719, lng: 18.7169775 },
    { lat: 48.9012154, lng: 18.7176112 },
    { lat: 48.9012735, lng: 18.718135 },
    { lat: 48.9013134, lng: 18.7184607 },
    { lat: 48.9013533, lng: 18.718877 },
    { lat: 48.9013886, lng: 18.7191598 },
    { lat: 48.9014479, lng: 18.7201771 },
    { lat: 48.9015102, lng: 18.7209947 },
    { lat: 48.901684, lng: 18.722045 },
    { lat: 48.901804, lng: 18.7229086 },
    { lat: 48.9059524, lng: 18.721424 },
    { lat: 48.9070158, lng: 18.7230673 },
    { lat: 48.9052954, lng: 18.7263934 },
    { lat: 48.9041916, lng: 18.7285375 },
    { lat: 48.9035578, lng: 18.7304662 },
    { lat: 48.9066339, lng: 18.7328364 },
    { lat: 48.9068077, lng: 18.7330086 },
    { lat: 48.9087409, lng: 18.7351802 },
    { lat: 48.9090715, lng: 18.7368785 },
    { lat: 48.9095674, lng: 18.7402357 },
    { lat: 48.909652, lng: 18.7410543 },
    { lat: 48.9097333, lng: 18.7422132 },
    { lat: 48.9101701, lng: 18.7427325 },
    { lat: 48.9109094, lng: 18.7433927 },
    { lat: 48.9131126, lng: 18.7449729 },
    { lat: 48.9133959, lng: 18.7452147 },
    { lat: 48.9134868, lng: 18.7453113 },
    { lat: 48.9137131, lng: 18.7454542 },
    { lat: 48.9175722, lng: 18.7455024 },
    { lat: 48.9176143, lng: 18.7444381 },
    { lat: 48.920596, lng: 18.7445002 },
    { lat: 48.9212249, lng: 18.743571 },
    { lat: 48.9213582, lng: 18.7432114 },
    { lat: 48.9218537, lng: 18.7433902 },
    { lat: 48.9221201, lng: 18.7432651 },
    { lat: 48.9227424, lng: 18.7437582 },
    { lat: 48.9231557, lng: 18.744198 },
    { lat: 48.9249162, lng: 18.7432352 },
    { lat: 48.9255789, lng: 18.742734 },
    { lat: 48.9264834, lng: 18.7420037 },
    { lat: 48.9274571, lng: 18.7405991 },
    { lat: 48.9275501, lng: 18.7404569 },
    { lat: 48.9275759, lng: 18.7403911 },
    { lat: 48.9280971, lng: 18.7396415 },
    { lat: 48.9287196, lng: 18.7387438 },
    { lat: 48.9290504, lng: 18.7388146 },
    { lat: 48.9295011, lng: 18.7391946 },
    { lat: 48.9304451, lng: 18.7383612 },
    { lat: 48.9302702, lng: 18.7379707 },
    { lat: 48.931036, lng: 18.7360635 },
    { lat: 48.9318561, lng: 18.735212 },
    { lat: 48.9318214, lng: 18.7338239 },
    { lat: 48.9315834, lng: 18.7329665 },
    { lat: 48.9322674, lng: 18.7307685 },
    { lat: 48.9324806, lng: 18.7300863 },
    { lat: 48.9329158, lng: 18.7311343 },
    { lat: 48.9336641, lng: 18.7337999 },
    { lat: 48.9337601, lng: 18.7364848 },
    { lat: 48.9337917, lng: 18.7373461 },
    { lat: 48.9348288, lng: 18.7368001 },
    { lat: 48.9357464, lng: 18.737609 },
    { lat: 48.9364359, lng: 18.7386026 },
    { lat: 48.9370927, lng: 18.7397899 },
    { lat: 48.9372339, lng: 18.7396494 },
    { lat: 48.9375199, lng: 18.7391643 },
    { lat: 48.9380141, lng: 18.7380767 },
    { lat: 48.9380737, lng: 18.7379454 },
    { lat: 48.9382704, lng: 18.7371425 },
    { lat: 48.9384447, lng: 18.7371088 },
    { lat: 48.93856, lng: 18.7352694 },
    { lat: 48.9385746, lng: 18.7335307 },
    { lat: 48.9386747, lng: 18.7329902 },
    { lat: 48.9385866, lng: 18.7327749 },
    { lat: 48.938604, lng: 18.7326576 },
    { lat: 48.9385288, lng: 18.7325972 },
    { lat: 48.9387411, lng: 18.7314042 },
    { lat: 48.9388567, lng: 18.7305343 },
    { lat: 48.9389636, lng: 18.7293953 },
    { lat: 48.939099, lng: 18.7292888 },
    { lat: 48.939797, lng: 18.7289681 },
    { lat: 48.9398844, lng: 18.7288506 },
    { lat: 48.9400045, lng: 18.7286897 },
    { lat: 48.940142, lng: 18.727164 },
    { lat: 48.939544, lng: 18.726638 },
    { lat: 48.939003, lng: 18.726239 },
    { lat: 48.938512, lng: 18.725577 },
    { lat: 48.937889, lng: 18.724552 },
    { lat: 48.937342, lng: 18.723167 },
    { lat: 48.93653, lng: 18.721148 },
    { lat: 48.937493, lng: 18.720277 },
  ],
  Budiš: [
    { lat: 48.875054, lng: 18.7644811 },
    { lat: 48.874457, lng: 18.7627517 },
    { lat: 48.8743784, lng: 18.7624159 },
    { lat: 48.8743539, lng: 18.7620337 },
    { lat: 48.8743938, lng: 18.7617179 },
    { lat: 48.8750063, lng: 18.7589075 },
    { lat: 48.8752143, lng: 18.7579481 },
    { lat: 48.87538, lng: 18.7556253 },
    { lat: 48.8753665, lng: 18.7556258 },
    { lat: 48.8753454, lng: 18.7556094 },
    { lat: 48.8754561, lng: 18.7542834 },
    { lat: 48.8754862, lng: 18.7533956 },
    { lat: 48.8754631, lng: 18.7519686 },
    { lat: 48.87534, lng: 18.7503999 },
    { lat: 48.8752227, lng: 18.7485716 },
    { lat: 48.8751781, lng: 18.7478934 },
    { lat: 48.8749095, lng: 18.7477837 },
    { lat: 48.8742531, lng: 18.7482115 },
    { lat: 48.8739354, lng: 18.7480242 },
    { lat: 48.8738408, lng: 18.7478744 },
    { lat: 48.8731698, lng: 18.7462897 },
    { lat: 48.8724344, lng: 18.7438633 },
    { lat: 48.8720992, lng: 18.7432146 },
    { lat: 48.8717495, lng: 18.7428911 },
    { lat: 48.8717687, lng: 18.7424206 },
    { lat: 48.8718067, lng: 18.7424783 },
    { lat: 48.8717991, lng: 18.7423736 },
    { lat: 48.8718176, lng: 18.742237 },
    { lat: 48.8717241, lng: 18.7419782 },
    { lat: 48.8716055, lng: 18.741592 },
    { lat: 48.8715122, lng: 18.7412512 },
    { lat: 48.8714983, lng: 18.7409878 },
    { lat: 48.8714703, lng: 18.7405856 },
    { lat: 48.8712653, lng: 18.7399903 },
    { lat: 48.8710201, lng: 18.7395962 },
    { lat: 48.8709954, lng: 18.7392754 },
    { lat: 48.8709381, lng: 18.7389986 },
    { lat: 48.8706535, lng: 18.738822 },
    { lat: 48.8702766, lng: 18.7383876 },
    { lat: 48.8699341, lng: 18.7380941 },
    { lat: 48.8690501, lng: 18.7379463 },
    { lat: 48.8682836, lng: 18.7375275 },
    { lat: 48.8675779, lng: 18.7370817 },
    { lat: 48.8673852, lng: 18.7369134 },
    { lat: 48.8669515, lng: 18.7360643 },
    { lat: 48.8662868, lng: 18.7356923 },
    { lat: 48.8658826, lng: 18.7354475 },
    { lat: 48.86559, lng: 18.7350072 },
    { lat: 48.8651358, lng: 18.7347563 },
    { lat: 48.8648626, lng: 18.7346024 },
    { lat: 48.8647016, lng: 18.7342429 },
    { lat: 48.8644527, lng: 18.7332196 },
    { lat: 48.8643641, lng: 18.7322242 },
    { lat: 48.8645158, lng: 18.7316131 },
    { lat: 48.8644661, lng: 18.7311191 },
    { lat: 48.8639251, lng: 18.7299066 },
    { lat: 48.8638419, lng: 18.7295478 },
    { lat: 48.8638233, lng: 18.7283848 },
    { lat: 48.8637329, lng: 18.7277593 },
    { lat: 48.8635192, lng: 18.727085 },
    { lat: 48.8635125, lng: 18.726882 },
    { lat: 48.8634991, lng: 18.7264728 },
    { lat: 48.8638435, lng: 18.7246645 },
    { lat: 48.8636157, lng: 18.7241939 },
    { lat: 48.8633539, lng: 18.7231428 },
    { lat: 48.8633258, lng: 18.7223099 },
    { lat: 48.8634005, lng: 18.7201808 },
    { lat: 48.8622214, lng: 18.7181379 },
    { lat: 48.8622182, lng: 18.718144 },
    { lat: 48.862107, lng: 18.717896 },
    { lat: 48.8619256, lng: 18.7177282 },
    { lat: 48.8617615, lng: 18.7176088 },
    { lat: 48.861425, lng: 18.7173008 },
    { lat: 48.8612311, lng: 18.7171782 },
    { lat: 48.8610553, lng: 18.717115 },
    { lat: 48.8607933, lng: 18.7168932 },
    { lat: 48.8610307, lng: 18.7167752 },
    { lat: 48.8608375, lng: 18.7165351 },
    { lat: 48.8607044, lng: 18.7167672 },
    { lat: 48.8605985, lng: 18.7163023 },
    { lat: 48.8604172, lng: 18.7160109 },
    { lat: 48.8599746, lng: 18.7151023 },
    { lat: 48.8598502, lng: 18.7146675 },
    { lat: 48.8597146, lng: 18.714449 },
    { lat: 48.859652, lng: 18.714164 },
    { lat: 48.8594821, lng: 18.7141506 },
    { lat: 48.859348, lng: 18.7138714 },
    { lat: 48.8591685, lng: 18.7139534 },
    { lat: 48.8585219, lng: 18.713649 },
    { lat: 48.8581165, lng: 18.7132309 },
    { lat: 48.8579948, lng: 18.7129137 },
    { lat: 48.857804, lng: 18.7125909 },
    { lat: 48.857682, lng: 18.7124457 },
    { lat: 48.8575895, lng: 18.7123357 },
    { lat: 48.8575309, lng: 18.7122659 },
    { lat: 48.8572283, lng: 18.7119187 },
    { lat: 48.8571315, lng: 18.7116035 },
    { lat: 48.8569912, lng: 18.7112295 },
    { lat: 48.8568166, lng: 18.7109362 },
    { lat: 48.8566098, lng: 18.7106949 },
    { lat: 48.8563824, lng: 18.7106627 },
    { lat: 48.8562107, lng: 18.7105031 },
    { lat: 48.856067, lng: 18.7102623 },
    { lat: 48.8558636, lng: 18.7099544 },
    { lat: 48.85574, lng: 18.7096868 },
    { lat: 48.8556847, lng: 18.7094937 },
    { lat: 48.8555163, lng: 18.7093584 },
    { lat: 48.8553506, lng: 18.7092775 },
    { lat: 48.8551702, lng: 18.7092339 },
    { lat: 48.8549655, lng: 18.7093096 },
    { lat: 48.8547684, lng: 18.7094621 },
    { lat: 48.8547368, lng: 18.7094635 },
    { lat: 48.8543576, lng: 18.709415 },
    { lat: 48.8540521, lng: 18.709224 },
    { lat: 48.8537484, lng: 18.7086829 },
    { lat: 48.8535084, lng: 18.7084986 },
    { lat: 48.8533021, lng: 18.7082437 },
    { lat: 48.8532142, lng: 18.7081017 },
    { lat: 48.853154, lng: 18.7080045 },
    { lat: 48.8530663, lng: 18.7076868 },
    { lat: 48.8530525, lng: 18.7073193 },
    { lat: 48.8530236, lng: 18.7070718 },
    { lat: 48.8530314, lng: 18.7066876 },
    { lat: 48.8529764, lng: 18.7063717 },
    { lat: 48.852888, lng: 18.706237 },
    { lat: 48.8528021, lng: 18.7054234 },
    { lat: 48.8526114, lng: 18.705231 },
    { lat: 48.8524048, lng: 18.7051179 },
    { lat: 48.8521144, lng: 18.7044904 },
    { lat: 48.8519518, lng: 18.7042763 },
    { lat: 48.8517434, lng: 18.7041061 },
    { lat: 48.8515602, lng: 18.7037578 },
    { lat: 48.8513829, lng: 18.7033067 },
    { lat: 48.8510934, lng: 18.702842 },
    { lat: 48.8509323, lng: 18.7029951 },
    { lat: 48.8510129, lng: 18.7030716 },
    { lat: 48.8511278, lng: 18.7032603 },
    { lat: 48.8507866, lng: 18.7031214 },
    { lat: 48.8505674, lng: 18.7030481 },
    { lat: 48.8502949, lng: 18.7031869 },
    { lat: 48.8499055, lng: 18.7036249 },
    { lat: 48.8497085, lng: 18.7037377 },
    { lat: 48.8494306, lng: 18.7036659 },
    { lat: 48.849123, lng: 18.7034805 },
    { lat: 48.8487865, lng: 18.7033202 },
    { lat: 48.8485921, lng: 18.7031226 },
    { lat: 48.8486564, lng: 18.7028233 },
    { lat: 48.8486124, lng: 18.702506 },
    { lat: 48.8484174, lng: 18.7016982 },
    { lat: 48.8481995, lng: 18.7013316 },
    { lat: 48.8479951, lng: 18.701113 },
    { lat: 48.847735, lng: 18.7010164 },
    { lat: 48.8475146, lng: 18.7008133 },
    { lat: 48.8474153, lng: 18.7006833 },
    { lat: 48.8466053, lng: 18.7000442 },
    { lat: 48.8465356, lng: 18.7000043 },
    { lat: 48.845988, lng: 18.700124 },
    { lat: 48.845999, lng: 18.701754 },
    { lat: 48.845938, lng: 18.702152 },
    { lat: 48.845913, lng: 18.702309 },
    { lat: 48.845831, lng: 18.702843 },
    { lat: 48.845665, lng: 18.703085 },
    { lat: 48.845204, lng: 18.704342 },
    { lat: 48.84446, lng: 18.70624 },
    { lat: 48.844252, lng: 18.707056 },
    { lat: 48.843745, lng: 18.707807 },
    { lat: 48.843709, lng: 18.707848 },
    { lat: 48.843438, lng: 18.708153 },
    { lat: 48.843068, lng: 18.708483 },
    { lat: 48.842985, lng: 18.708922 },
    { lat: 48.842816, lng: 18.709634 },
    { lat: 48.842551, lng: 18.710183 },
    { lat: 48.841622, lng: 18.712265 },
    { lat: 48.841399, lng: 18.713218 },
    { lat: 48.840633, lng: 18.714687 },
    { lat: 48.84009, lng: 18.716274 },
    { lat: 48.840143, lng: 18.717473 },
    { lat: 48.839916, lng: 18.718654 },
    { lat: 48.839452, lng: 18.71973 },
    { lat: 48.839187, lng: 18.720451 },
    { lat: 48.83851, lng: 18.721235 },
    { lat: 48.838033, lng: 18.722927 },
    { lat: 48.837243, lng: 18.723634 },
    { lat: 48.836501, lng: 18.724758 },
    { lat: 48.836318, lng: 18.724926 },
    { lat: 48.836062, lng: 18.724922 },
    { lat: 48.83527, lng: 18.725492 },
    { lat: 48.834601, lng: 18.725366 },
    { lat: 48.834463, lng: 18.725315 },
    { lat: 48.834297, lng: 18.725314 },
    { lat: 48.834133, lng: 18.72538 },
    { lat: 48.8341207, lng: 18.7253842 },
    { lat: 48.83406, lng: 18.725405 },
    { lat: 48.8340485, lng: 18.7254252 },
    { lat: 48.83398, lng: 18.725545 },
    { lat: 48.833829, lng: 18.725997 },
    { lat: 48.833749, lng: 18.72615 },
    { lat: 48.833377, lng: 18.726794 },
    { lat: 48.832769, lng: 18.727684 },
    { lat: 48.832006, lng: 18.729141 },
    { lat: 48.8323396, lng: 18.7295859 },
    { lat: 48.8324016, lng: 18.7297141 },
    { lat: 48.8325064, lng: 18.7298632 },
    { lat: 48.8328266, lng: 18.7303069 },
    { lat: 48.8328882, lng: 18.7304319 },
    { lat: 48.8331968, lng: 18.7310228 },
    { lat: 48.8333656, lng: 18.7309909 },
    { lat: 48.8336594, lng: 18.7310244 },
    { lat: 48.8337853, lng: 18.7310192 },
    { lat: 48.8340967, lng: 18.7311484 },
    { lat: 48.8343798, lng: 18.7314259 },
    { lat: 48.8347894, lng: 18.731793 },
    { lat: 48.835077, lng: 18.7322021 },
    { lat: 48.8353721, lng: 18.7324291 },
    { lat: 48.8358176, lng: 18.7328155 },
    { lat: 48.8358753, lng: 18.7329682 },
    { lat: 48.8361935, lng: 18.7331638 },
    { lat: 48.836474, lng: 18.7331592 },
    { lat: 48.8366273, lng: 18.7331135 },
    { lat: 48.836798, lng: 18.7328687 },
    { lat: 48.8371682, lng: 18.7325522 },
    { lat: 48.8371951, lng: 18.732525 },
    { lat: 48.8373751, lng: 18.7324964 },
    { lat: 48.8375111, lng: 18.7325974 },
    { lat: 48.8376343, lng: 18.7327507 },
    { lat: 48.8377092, lng: 18.7329536 },
    { lat: 48.8377549, lng: 18.733412 },
    { lat: 48.8378634, lng: 18.733609 },
    { lat: 48.8381135, lng: 18.7336697 },
    { lat: 48.8384012, lng: 18.7336718 },
    { lat: 48.8389788, lng: 18.7338697 },
    { lat: 48.8391178, lng: 18.7339148 },
    { lat: 48.839281, lng: 18.7340784 },
    { lat: 48.8394018, lng: 18.7342324 },
    { lat: 48.8394994, lng: 18.7345238 },
    { lat: 48.8395871, lng: 18.73452 },
    { lat: 48.839725, lng: 18.7343125 },
    { lat: 48.839844, lng: 18.7340453 },
    { lat: 48.8399288, lng: 18.7337705 },
    { lat: 48.8399888, lng: 18.7337074 },
    { lat: 48.8400128, lng: 18.733567 },
    { lat: 48.8402519, lng: 18.7335653 },
    { lat: 48.8403193, lng: 18.7336875 },
    { lat: 48.8404466, lng: 18.733686 },
    { lat: 48.8406573, lng: 18.7337035 },
    { lat: 48.8408256, lng: 18.7337762 },
    { lat: 48.841073, lng: 18.7339951 },
    { lat: 48.8411949, lng: 18.7340842 },
    { lat: 48.8412484, lng: 18.7341794 },
    { lat: 48.8413468, lng: 18.7344632 },
    { lat: 48.8414023, lng: 18.7346605 },
    { lat: 48.8414914, lng: 18.7350228 },
    { lat: 48.841693, lng: 18.7352537 },
    { lat: 48.8418617, lng: 18.7352874 },
    { lat: 48.8420299, lng: 18.7350533 },
    { lat: 48.842158, lng: 18.7350446 },
    { lat: 48.8422895, lng: 18.7349309 },
    { lat: 48.842432, lng: 18.7348765 },
    { lat: 48.8426195, lng: 18.734862 },
    { lat: 48.8428877, lng: 18.7349198 },
    { lat: 48.8430673, lng: 18.7350989 },
    { lat: 48.8432387, lng: 18.7355429 },
    { lat: 48.8432795, lng: 18.7355924 },
    { lat: 48.8435603, lng: 18.7355298 },
    { lat: 48.8436785, lng: 18.7353522 },
    { lat: 48.8438538, lng: 18.735171 },
    { lat: 48.8440157, lng: 18.7349885 },
    { lat: 48.8441303, lng: 18.73483 },
    { lat: 48.8442538, lng: 18.7347803 },
    { lat: 48.8444106, lng: 18.734737 },
    { lat: 48.8445501, lng: 18.7346442 },
    { lat: 48.8446324, lng: 18.7345147 },
    { lat: 48.8446923, lng: 18.7344777 },
    { lat: 48.844778, lng: 18.7343051 },
    { lat: 48.8449032, lng: 18.7340884 },
    { lat: 48.8450539, lng: 18.7339753 },
    { lat: 48.8452423, lng: 18.7338071 },
    { lat: 48.8454572, lng: 18.7337314 },
    { lat: 48.845512, lng: 18.7338078 },
    { lat: 48.8456287, lng: 18.7337758 },
    { lat: 48.8457686, lng: 18.7335429 },
    { lat: 48.8458693, lng: 18.7335243 },
    { lat: 48.8459594, lng: 18.733266 },
    { lat: 48.8460614, lng: 18.7332787 },
    { lat: 48.8461087, lng: 18.7331713 },
    { lat: 48.8463146, lng: 18.7330748 },
    { lat: 48.8465262, lng: 18.7330223 },
    { lat: 48.8466643, lng: 18.7330142 },
    { lat: 48.8468224, lng: 18.7331218 },
    { lat: 48.8469636, lng: 18.733278 },
    { lat: 48.8470231, lng: 18.7333578 },
    { lat: 48.8472168, lng: 18.7338665 },
    { lat: 48.8474256, lng: 18.7343393 },
    { lat: 48.8476538, lng: 18.7347965 },
    { lat: 48.8477698, lng: 18.7351366 },
    { lat: 48.8477601, lng: 18.735422 },
    { lat: 48.8477333, lng: 18.7357314 },
    { lat: 48.8477561, lng: 18.7360112 },
    { lat: 48.8477961, lng: 18.7361922 },
    { lat: 48.8478559, lng: 18.736514 },
    { lat: 48.8479288, lng: 18.7366158 },
    { lat: 48.8480977, lng: 18.7368027 },
    { lat: 48.8483, lng: 18.7369594 },
    { lat: 48.8484386, lng: 18.7370884 },
    { lat: 48.8486127, lng: 18.7373513 },
    { lat: 48.8486802, lng: 18.7374985 },
    { lat: 48.8487708, lng: 18.7378055 },
    { lat: 48.8488713, lng: 18.7380662 },
    { lat: 48.8489491, lng: 18.7381929 },
    { lat: 48.8491371, lng: 18.7385272 },
    { lat: 48.8492472, lng: 18.738671 },
    { lat: 48.8494235, lng: 18.7389573 },
    { lat: 48.8495107, lng: 18.7391333 },
    { lat: 48.8495947, lng: 18.7394053 },
    { lat: 48.8496076, lng: 18.7396036 },
    { lat: 48.8496034, lng: 18.7397813 },
    { lat: 48.8495758, lng: 18.7401578 },
    { lat: 48.8495682, lng: 18.7403993 },
    { lat: 48.8495827, lng: 18.7407377 },
    { lat: 48.8496214, lng: 18.7409565 },
    { lat: 48.8496919, lng: 18.7412065 },
    { lat: 48.8498033, lng: 18.7414591 },
    { lat: 48.8499051, lng: 18.741764 },
    { lat: 48.8499631, lng: 18.7419905 },
    { lat: 48.8500218, lng: 18.7423103 },
    { lat: 48.8500727, lng: 18.7424417 },
    { lat: 48.8502005, lng: 18.7427288 },
    { lat: 48.8502986, lng: 18.7428996 },
    { lat: 48.8503598, lng: 18.7431375 },
    { lat: 48.8503891, lng: 18.7435683 },
    { lat: 48.8503695, lng: 18.7441626 },
    { lat: 48.850347, lng: 18.7441828 },
    { lat: 48.8502838, lng: 18.7442667 },
    { lat: 48.8502766, lng: 18.7447317 },
    { lat: 48.8503033, lng: 18.7448312 },
    { lat: 48.850483, lng: 18.7451149 },
    { lat: 48.850522, lng: 18.7452381 },
    { lat: 48.8505812, lng: 18.745357 },
    { lat: 48.8505937, lng: 18.7455263 },
    { lat: 48.8505383, lng: 18.7460411 },
    { lat: 48.8505395, lng: 18.7461723 },
    { lat: 48.8505714, lng: 18.7464327 },
    { lat: 48.8505721, lng: 18.7466837 },
    { lat: 48.8505326, lng: 18.7469627 },
    { lat: 48.8505329, lng: 18.7475027 },
    { lat: 48.8505003, lng: 18.7477813 },
    { lat: 48.8504721, lng: 18.7479329 },
    { lat: 48.8503815, lng: 18.7483528 },
    { lat: 48.8503759, lng: 18.7485257 },
    { lat: 48.8503865, lng: 18.7487944 },
    { lat: 48.8503905, lng: 18.7490642 },
    { lat: 48.8502254, lng: 18.749892 },
    { lat: 48.850185, lng: 18.7504104 },
    { lat: 48.8501774, lng: 18.750436 },
    { lat: 48.8500619, lng: 18.7509852 },
    { lat: 48.8499447, lng: 18.7513496 },
    { lat: 48.8498749, lng: 18.7516097 },
    { lat: 48.8498314, lng: 18.7517933 },
    { lat: 48.8498024, lng: 18.7519923 },
    { lat: 48.8498136, lng: 18.7521378 },
    { lat: 48.8499266, lng: 18.7525921 },
    { lat: 48.8499641, lng: 18.7528168 },
    { lat: 48.8499682, lng: 18.7529344 },
    { lat: 48.8497579, lng: 18.7534389 },
    { lat: 48.8497146, lng: 18.7535827 },
    { lat: 48.8497068, lng: 18.7537129 },
    { lat: 48.8497243, lng: 18.7539829 },
    { lat: 48.8496992, lng: 18.7540848 },
    { lat: 48.849691, lng: 18.7544195 },
    { lat: 48.8497452, lng: 18.7544892 },
    { lat: 48.8499096, lng: 18.7545904 },
    { lat: 48.8499896, lng: 18.7547824 },
    { lat: 48.8500755, lng: 18.7547659 },
    { lat: 48.8502253, lng: 18.7551657 },
    { lat: 48.8502021, lng: 18.7553508 },
    { lat: 48.8503672, lng: 18.755637 },
    { lat: 48.8504145, lng: 18.7556589 },
    { lat: 48.8504542, lng: 18.7557043 },
    { lat: 48.8504611, lng: 18.7556877 },
    { lat: 48.8505064, lng: 18.755732 },
    { lat: 48.8505097, lng: 18.7557756 },
    { lat: 48.8505525, lng: 18.75575 },
    { lat: 48.8505662, lng: 18.755776 },
    { lat: 48.850561, lng: 18.7558267 },
    { lat: 48.8505987, lng: 18.7557742 },
    { lat: 48.8506382, lng: 18.7557931 },
    { lat: 48.8507873, lng: 18.7559273 },
    { lat: 48.8507817, lng: 18.7559706 },
    { lat: 48.8508158, lng: 18.7561068 },
    { lat: 48.8508518, lng: 18.7561039 },
    { lat: 48.8508478, lng: 18.7561352 },
    { lat: 48.8508798, lng: 18.7562266 },
    { lat: 48.850949, lng: 18.7561942 },
    { lat: 48.8511126, lng: 18.7563897 },
    { lat: 48.8511778, lng: 18.7564925 },
    { lat: 48.8512338, lng: 18.7564829 },
    { lat: 48.8513288, lng: 18.7566186 },
    { lat: 48.8512771, lng: 18.7567097 },
    { lat: 48.8512759, lng: 18.7567492 },
    { lat: 48.8513377, lng: 18.7568109 },
    { lat: 48.8514304, lng: 18.7568803 },
    { lat: 48.8514641, lng: 18.7569632 },
    { lat: 48.8516309, lng: 18.7571741 },
    { lat: 48.8516771, lng: 18.7572799 },
    { lat: 48.8517466, lng: 18.7573618 },
    { lat: 48.8517231, lng: 18.757428 },
    { lat: 48.8517579, lng: 18.7575942 },
    { lat: 48.8519153, lng: 18.7577794 },
    { lat: 48.8520039, lng: 18.7580097 },
    { lat: 48.8520626, lng: 18.7580287 },
    { lat: 48.852114, lng: 18.7581941 },
    { lat: 48.8521886, lng: 18.7583455 },
    { lat: 48.8521866, lng: 18.7583966 },
    { lat: 48.8525269, lng: 18.7588436 },
    { lat: 48.8526008, lng: 18.7589583 },
    { lat: 48.852693, lng: 18.7591259 },
    { lat: 48.8527026, lng: 18.7592242 },
    { lat: 48.852875, lng: 18.7594158 },
    { lat: 48.8529558, lng: 18.7594917 },
    { lat: 48.8532046, lng: 18.7595276 },
    { lat: 48.8534221, lng: 18.7594394 },
    { lat: 48.8536625, lng: 18.7592923 },
    { lat: 48.8536916, lng: 18.7592028 },
    { lat: 48.8537615, lng: 18.7591786 },
    { lat: 48.8538654, lng: 18.7590963 },
    { lat: 48.8540137, lng: 18.759 },
    { lat: 48.8541038, lng: 18.7589104 },
    { lat: 48.8541743, lng: 18.7588592 },
    { lat: 48.854391, lng: 18.7587998 },
    { lat: 48.8545874, lng: 18.7587611 },
    { lat: 48.8547166, lng: 18.7587498 },
    { lat: 48.8550431, lng: 18.7586983 },
    { lat: 48.8551615, lng: 18.7587406 },
    { lat: 48.8552294, lng: 18.7587869 },
    { lat: 48.8553513, lng: 18.7587453 },
    { lat: 48.8554388, lng: 18.7587595 },
    { lat: 48.8555441, lng: 18.7587228 },
    { lat: 48.855751, lng: 18.7588418 },
    { lat: 48.8558852, lng: 18.7587555 },
    { lat: 48.8559654, lng: 18.7588024 },
    { lat: 48.8560033, lng: 18.7588587 },
    { lat: 48.8560791, lng: 18.7588614 },
    { lat: 48.8561286, lng: 18.7589231 },
    { lat: 48.8562194, lng: 18.7588695 },
    { lat: 48.8563019, lng: 18.7589187 },
    { lat: 48.8563794, lng: 18.7590189 },
    { lat: 48.8564303, lng: 18.7591055 },
    { lat: 48.8565424, lng: 18.7592027 },
    { lat: 48.8567432, lng: 18.7592046 },
    { lat: 48.8571865, lng: 18.7590788 },
    { lat: 48.8573635, lng: 18.7589669 },
    { lat: 48.8574658, lng: 18.7588547 },
    { lat: 48.8576399, lng: 18.7585833 },
    { lat: 48.8582986, lng: 18.7577064 },
    { lat: 48.8584757, lng: 18.7575321 },
    { lat: 48.8585151, lng: 18.7575145 },
    { lat: 48.8586368, lng: 18.7575084 },
    { lat: 48.8587634, lng: 18.7575572 },
    { lat: 48.8589075, lng: 18.7576606 },
    { lat: 48.8591241, lng: 18.7578576 },
    { lat: 48.8592175, lng: 18.758024 },
    { lat: 48.8592742, lng: 18.7579433 },
    { lat: 48.8595737, lng: 18.758193 },
    { lat: 48.8595956, lng: 18.7581377 },
    { lat: 48.8601732, lng: 18.7587009 },
    { lat: 48.860176, lng: 18.7586913 },
    { lat: 48.8608914, lng: 18.7577978 },
    { lat: 48.8625482, lng: 18.7578441 },
    { lat: 48.8627405, lng: 18.7579817 },
    { lat: 48.8630675, lng: 18.7581744 },
    { lat: 48.8634497, lng: 18.758388 },
    { lat: 48.8635549, lng: 18.7583986 },
    { lat: 48.8639518, lng: 18.7582888 },
    { lat: 48.8641105, lng: 18.7583033 },
    { lat: 48.8642316, lng: 18.7583736 },
    { lat: 48.8646336, lng: 18.7586851 },
    { lat: 48.864726, lng: 18.7588154 },
    { lat: 48.8647732, lng: 18.758963 },
    { lat: 48.864806, lng: 18.7590311 },
    { lat: 48.864998, lng: 18.759163 },
    { lat: 48.8651808, lng: 18.759381 },
    { lat: 48.8656115, lng: 18.7595923 },
    { lat: 48.8656967, lng: 18.7596268 },
    { lat: 48.8657107, lng: 18.759613 },
    { lat: 48.8663138, lng: 18.7622775 },
    { lat: 48.866452, lng: 18.7627172 },
    { lat: 48.8673942, lng: 18.7644279 },
    { lat: 48.8679975, lng: 18.764879 },
    { lat: 48.8696563, lng: 18.7653096 },
    { lat: 48.8699337, lng: 18.7652013 },
    { lat: 48.8722512, lng: 18.7629667 },
    { lat: 48.8726517, lng: 18.7627773 },
    { lat: 48.8732585, lng: 18.7628668 },
    { lat: 48.8734414, lng: 18.762997 },
    { lat: 48.8736837, lng: 18.7631885 },
    { lat: 48.8747891, lng: 18.7642289 },
    { lat: 48.875054, lng: 18.7644811 },
  ],
  Dubové: [
    { lat: 48.818129, lng: 18.753317 },
    { lat: 48.8178919, lng: 18.7536351 },
    { lat: 48.817567, lng: 18.754071 },
    { lat: 48.817567, lng: 18.754074 },
    { lat: 48.817522, lng: 18.754449 },
    { lat: 48.817525, lng: 18.754454 },
    { lat: 48.818056, lng: 18.755305 },
    { lat: 48.818054, lng: 18.755311 },
    { lat: 48.817773, lng: 18.75646 },
    { lat: 48.817534, lng: 18.758279 },
    { lat: 48.817126, lng: 18.759607 },
    { lat: 48.816227, lng: 18.760864 },
    { lat: 48.815875, lng: 18.761232 },
    { lat: 48.815605, lng: 18.761482 },
    { lat: 48.815281, lng: 18.761781 },
    { lat: 48.815281, lng: 18.761782 },
    { lat: 48.815209, lng: 18.761848 },
    { lat: 48.8147, lng: 18.76351 },
    { lat: 48.8147, lng: 18.763511 },
    { lat: 48.814695, lng: 18.763525 },
    { lat: 48.814191, lng: 18.764393 },
    { lat: 48.81384, lng: 18.764999 },
    { lat: 48.813823, lng: 18.765115 },
    { lat: 48.813736, lng: 18.765716 },
    { lat: 48.813806, lng: 18.766199 },
    { lat: 48.813901, lng: 18.766858 },
    { lat: 48.813846, lng: 18.767619 },
    { lat: 48.81383, lng: 18.767848 },
    { lat: 48.813815, lng: 18.767897 },
    { lat: 48.81348, lng: 18.768943 },
    { lat: 48.813269, lng: 18.769326 },
    { lat: 48.813224, lng: 18.769723 },
    { lat: 48.813224, lng: 18.769724 },
    { lat: 48.813098, lng: 18.770825 },
    { lat: 48.813101, lng: 18.770961 },
    { lat: 48.813137, lng: 18.772226 },
    { lat: 48.813142, lng: 18.772362 },
    { lat: 48.813191, lng: 18.772882 },
    { lat: 48.813318, lng: 18.774205 },
    { lat: 48.813328, lng: 18.774322 },
    { lat: 48.813311, lng: 18.77468 },
    { lat: 48.813268, lng: 18.775547 },
    { lat: 48.813258, lng: 18.775752 },
    { lat: 48.813186, lng: 18.776747 },
    { lat: 48.813134, lng: 18.777463 },
    { lat: 48.812747, lng: 18.777958 },
    { lat: 48.8121053, lng: 18.779068 },
    { lat: 48.8116894, lng: 18.7794392 },
    { lat: 48.8133766, lng: 18.7804543 },
    { lat: 48.814721, lng: 18.7812372 },
    { lat: 48.8155854, lng: 18.7818703 },
    { lat: 48.8163166, lng: 18.782441 },
    { lat: 48.8165601, lng: 18.7827173 },
    { lat: 48.8172499, lng: 18.7832959 },
    { lat: 48.8180498, lng: 18.7840555 },
    { lat: 48.8185952, lng: 18.7844395 },
    { lat: 48.8190557, lng: 18.7845514 },
    { lat: 48.819409, lng: 18.7848594 },
    { lat: 48.8198126, lng: 18.7853345 },
    { lat: 48.8202169, lng: 18.7857774 },
    { lat: 48.8205356, lng: 18.786029 },
    { lat: 48.8207187, lng: 18.7861871 },
    { lat: 48.8211711, lng: 18.7866005 },
    { lat: 48.8219075, lng: 18.7872706 },
    { lat: 48.8223854, lng: 18.7877386 },
    { lat: 48.8225979, lng: 18.7882682 },
    { lat: 48.822671, lng: 18.7887583 },
    { lat: 48.8228479, lng: 18.789363 },
    { lat: 48.8229602, lng: 18.7897843 },
    { lat: 48.8228341, lng: 18.7899401 },
    { lat: 48.8226539, lng: 18.7902902 },
    { lat: 48.8224404, lng: 18.7905183 },
    { lat: 48.8223009, lng: 18.7906338 },
    { lat: 48.8221492, lng: 18.7908606 },
    { lat: 48.8219628, lng: 18.7912641 },
    { lat: 48.8217726, lng: 18.7916175 },
    { lat: 48.8216937, lng: 18.791875 },
    { lat: 48.8216496, lng: 18.7921762 },
    { lat: 48.8215671, lng: 18.7925597 },
    { lat: 48.821564, lng: 18.793021 },
    { lat: 48.8216335, lng: 18.7932073 },
    { lat: 48.8216558, lng: 18.7935249 },
    { lat: 48.8216416, lng: 18.7943769 },
    { lat: 48.8216022, lng: 18.7943372 },
    { lat: 48.8215556, lng: 18.7944358 },
    { lat: 48.8214848, lng: 18.7944636 },
    { lat: 48.8213705, lng: 18.7946276 },
    { lat: 48.821218, lng: 18.794949 },
    { lat: 48.8211292, lng: 18.7951256 },
    { lat: 48.821036, lng: 18.7952413 },
    { lat: 48.8209715, lng: 18.7953857 },
    { lat: 48.8208436, lng: 18.7955703 },
    { lat: 48.820798, lng: 18.7956928 },
    { lat: 48.8207132, lng: 18.7958621 },
    { lat: 48.8206747, lng: 18.7959877 },
    { lat: 48.8206994, lng: 18.7961587 },
    { lat: 48.8206537, lng: 18.7961878 },
    { lat: 48.8204407, lng: 18.7965393 },
    { lat: 48.8203086, lng: 18.7969022 },
    { lat: 48.8202669, lng: 18.7971841 },
    { lat: 48.8202815, lng: 18.7973098 },
    { lat: 48.820147, lng: 18.7975223 },
    { lat: 48.8199447, lng: 18.7980103 },
    { lat: 48.8200411, lng: 18.7981913 },
    { lat: 48.8200826, lng: 18.7983223 },
    { lat: 48.8200669, lng: 18.7984734 },
    { lat: 48.8200337, lng: 18.7987028 },
    { lat: 48.820014, lng: 18.7988011 },
    { lat: 48.8199706, lng: 18.7989197 },
    { lat: 48.8199405, lng: 18.7991401 },
    { lat: 48.8198494, lng: 18.7992101 },
    { lat: 48.8197256, lng: 18.7994514 },
    { lat: 48.8197832, lng: 18.7995851 },
    { lat: 48.8198178, lng: 18.7997284 },
    { lat: 48.8198197, lng: 18.7997862 },
    { lat: 48.8198686, lng: 18.8000083 },
    { lat: 48.8198576, lng: 18.8001244 },
    { lat: 48.819908, lng: 18.8002469 },
    { lat: 48.8199217, lng: 18.8003615 },
    { lat: 48.8199141, lng: 18.8004501 },
    { lat: 48.8198882, lng: 18.8005155 },
    { lat: 48.8198958, lng: 18.8005525 },
    { lat: 48.8199278, lng: 18.8005816 },
    { lat: 48.8198702, lng: 18.8008866 },
    { lat: 48.819881, lng: 18.80094 },
    { lat: 48.8198188, lng: 18.8010874 },
    { lat: 48.8198319, lng: 18.8012297 },
    { lat: 48.8198195, lng: 18.801297 },
    { lat: 48.8198545, lng: 18.8013833 },
    { lat: 48.8198462, lng: 18.8014827 },
    { lat: 48.8198586, lng: 18.8015696 },
    { lat: 48.8198381, lng: 18.8016522 },
    { lat: 48.8198602, lng: 18.8017093 },
    { lat: 48.8198206, lng: 18.8017713 },
    { lat: 48.8198939, lng: 18.8019655 },
    { lat: 48.8199954, lng: 18.8020954 },
    { lat: 48.8199989, lng: 18.8021633 },
    { lat: 48.8200632, lng: 18.8022275 },
    { lat: 48.8202039, lng: 18.8022884 },
    { lat: 48.8202451, lng: 18.8023443 },
    { lat: 48.8203103, lng: 18.8023619 },
    { lat: 48.8203687, lng: 18.8025528 },
    { lat: 48.8204296, lng: 18.8026607 },
    { lat: 48.8205346, lng: 18.8026607 },
    { lat: 48.8205403, lng: 18.8027706 },
    { lat: 48.8205364, lng: 18.8029146 },
    { lat: 48.8206182, lng: 18.8029227 },
    { lat: 48.8206459, lng: 18.8029791 },
    { lat: 48.820744, lng: 18.8029803 },
    { lat: 48.8207468, lng: 18.8030326 },
    { lat: 48.8207223, lng: 18.803062 },
    { lat: 48.8207391, lng: 18.8031677 },
    { lat: 48.8207629, lng: 18.8031924 },
    { lat: 48.8207715, lng: 18.803289 },
    { lat: 48.8207479, lng: 18.8033355 },
    { lat: 48.820755, lng: 18.8033858 },
    { lat: 48.8207787, lng: 18.8034091 },
    { lat: 48.8207929, lng: 18.803465 },
    { lat: 48.8207641, lng: 18.8034735 },
    { lat: 48.820753, lng: 18.8035417 },
    { lat: 48.8207946, lng: 18.8035829 },
    { lat: 48.8207744, lng: 18.803736 },
    { lat: 48.8207873, lng: 18.8038339 },
    { lat: 48.8208772, lng: 18.8038722 },
    { lat: 48.8209175, lng: 18.8039508 },
    { lat: 48.8209681, lng: 18.8039465 },
    { lat: 48.8209979, lng: 18.8039787 },
    { lat: 48.8209544, lng: 18.8040333 },
    { lat: 48.8209526, lng: 18.804103 },
    { lat: 48.8209664, lng: 18.804131 },
    { lat: 48.8210154, lng: 18.804143 },
    { lat: 48.8210589, lng: 18.8041976 },
    { lat: 48.8211872, lng: 18.8042152 },
    { lat: 48.8212338, lng: 18.8041355 },
    { lat: 48.8213508, lng: 18.8041316 },
    { lat: 48.821378, lng: 18.8040907 },
    { lat: 48.8213905, lng: 18.8041136 },
    { lat: 48.8213699, lng: 18.8041715 },
    { lat: 48.8214046, lng: 18.8042522 },
    { lat: 48.8214894, lng: 18.8042778 },
    { lat: 48.8214956, lng: 18.8043104 },
    { lat: 48.8215635, lng: 18.8043173 },
    { lat: 48.8216212, lng: 18.8043855 },
    { lat: 48.8216809, lng: 18.8043838 },
    { lat: 48.8217328, lng: 18.8043597 },
    { lat: 48.8218069, lng: 18.8044396 },
    { lat: 48.8218253, lng: 18.8045122 },
    { lat: 48.8219015, lng: 18.8045689 },
    { lat: 48.8219437, lng: 18.8045356 },
    { lat: 48.8220237, lng: 18.804555 },
    { lat: 48.8220661, lng: 18.8045256 },
    { lat: 48.8220841, lng: 18.8045682 },
    { lat: 48.82209, lng: 18.8046813 },
    { lat: 48.8221113, lng: 18.8047456 },
    { lat: 48.8221481, lng: 18.8047563 },
    { lat: 48.8222825, lng: 18.8048687 },
    { lat: 48.822315, lng: 18.8048215 },
    { lat: 48.8223261, lng: 18.8049726 },
    { lat: 48.8223675, lng: 18.8050317 },
    { lat: 48.8223774, lng: 18.8050896 },
    { lat: 48.8224392, lng: 18.8051735 },
    { lat: 48.8225009, lng: 18.805165 },
    { lat: 48.8225309, lng: 18.805224 },
    { lat: 48.8225655, lng: 18.8052178 },
    { lat: 48.8225924, lng: 18.8051254 },
    { lat: 48.8226445, lng: 18.8051736 },
    { lat: 48.8226636, lng: 18.8053048 },
    { lat: 48.8226194, lng: 18.8053859 },
    { lat: 48.822685, lng: 18.8054515 },
    { lat: 48.8226891, lng: 18.8055075 },
    { lat: 48.8227364, lng: 18.8055305 },
    { lat: 48.8228717, lng: 18.8057251 },
    { lat: 48.8228691, lng: 18.8057823 },
    { lat: 48.8228789, lng: 18.805862 },
    { lat: 48.8228403, lng: 18.8059246 },
    { lat: 48.8228685, lng: 18.8059896 },
    { lat: 48.822918, lng: 18.8060277 },
    { lat: 48.8229526, lng: 18.806089 },
    { lat: 48.8229921, lng: 18.8060886 },
    { lat: 48.8230116, lng: 18.8060512 },
    { lat: 48.8230426, lng: 18.8060389 },
    { lat: 48.8230718, lng: 18.8060583 },
    { lat: 48.823101, lng: 18.806121 },
    { lat: 48.8231757, lng: 18.8061941 },
    { lat: 48.8232472, lng: 18.806114 },
    { lat: 48.8232716, lng: 18.8061472 },
    { lat: 48.8233342, lng: 18.806139 },
    { lat: 48.8233548, lng: 18.8062979 },
    { lat: 48.8234014, lng: 18.8063034 },
    { lat: 48.8234188, lng: 18.8063325 },
    { lat: 48.8234365, lng: 18.8063293 },
    { lat: 48.8234572, lng: 18.806358 },
    { lat: 48.8234515, lng: 18.8064628 },
    { lat: 48.8234874, lng: 18.806461 },
    { lat: 48.8235112, lng: 18.8065294 },
    { lat: 48.8235307, lng: 18.8066623 },
    { lat: 48.8235147, lng: 18.8068127 },
    { lat: 48.8235479, lng: 18.8068657 },
    { lat: 48.8235744, lng: 18.8068292 },
    { lat: 48.8236183, lng: 18.8068741 },
    { lat: 48.823653, lng: 18.8068463 },
    { lat: 48.823715, lng: 18.8068661 },
    { lat: 48.8237928, lng: 18.806913 },
    { lat: 48.8238243, lng: 18.8068455 },
    { lat: 48.8238654, lng: 18.8068997 },
    { lat: 48.823987, lng: 18.8068948 },
    { lat: 48.8240325, lng: 18.806792 },
    { lat: 48.8240573, lng: 18.806774 },
    { lat: 48.8240677, lng: 18.8067961 },
    { lat: 48.824094, lng: 18.8068244 },
    { lat: 48.8241265, lng: 18.8067822 },
    { lat: 48.8241401, lng: 18.8067176 },
    { lat: 48.8241583, lng: 18.8067219 },
    { lat: 48.8241656, lng: 18.8067525 },
    { lat: 48.8241378, lng: 18.8068034 },
    { lat: 48.8241993, lng: 18.8068999 },
    { lat: 48.8243018, lng: 18.8069409 },
    { lat: 48.8243431, lng: 18.8069342 },
    { lat: 48.8243811, lng: 18.8070156 },
    { lat: 48.8243712, lng: 18.8070396 },
    { lat: 48.8243069, lng: 18.8069983 },
    { lat: 48.8242786, lng: 18.8070811 },
    { lat: 48.8243408, lng: 18.8071492 },
    { lat: 48.8243459, lng: 18.8072696 },
    { lat: 48.8244619, lng: 18.8072654 },
    { lat: 48.824478, lng: 18.8073389 },
    { lat: 48.8245334, lng: 18.807362 },
    { lat: 48.8245382, lng: 18.807432 },
    { lat: 48.8245592, lng: 18.8074685 },
    { lat: 48.8245464, lng: 18.80763 },
    { lat: 48.8245787, lng: 18.8076887 },
    { lat: 48.824569, lng: 18.8078291 },
    { lat: 48.8246301, lng: 18.8078547 },
    { lat: 48.8246519, lng: 18.8078421 },
    { lat: 48.8247039, lng: 18.8078867 },
    { lat: 48.8247585, lng: 18.8078734 },
    { lat: 48.8247968, lng: 18.8079794 },
    { lat: 48.8248299, lng: 18.8079661 },
    { lat: 48.824854, lng: 18.8079282 },
    { lat: 48.8249249, lng: 18.8081008 },
    { lat: 48.8249789, lng: 18.807968 },
    { lat: 48.8250149, lng: 18.8079687 },
    { lat: 48.8250487, lng: 18.8079468 },
    { lat: 48.8250858, lng: 18.8080515 },
    { lat: 48.8250592, lng: 18.8080797 },
    { lat: 48.8250233, lng: 18.8080854 },
    { lat: 48.8250233, lng: 18.8081946 },
    { lat: 48.8250586, lng: 18.8082419 },
    { lat: 48.8251402, lng: 18.8082297 },
    { lat: 48.8251888, lng: 18.8082553 },
    { lat: 48.8252275, lng: 18.8083681 },
    { lat: 48.8252163, lng: 18.8084153 },
    { lat: 48.8252257, lng: 18.8085054 },
    { lat: 48.8252524, lng: 18.8085226 },
    { lat: 48.8252379, lng: 18.8085902 },
    { lat: 48.8252727, lng: 18.8086719 },
    { lat: 48.8252594, lng: 18.8087844 },
    { lat: 48.8253146, lng: 18.8088294 },
    { lat: 48.8253773, lng: 18.8087983 },
    { lat: 48.8254131, lng: 18.8088369 },
    { lat: 48.8254101, lng: 18.8088878 },
    { lat: 48.8253546, lng: 18.8089019 },
    { lat: 48.8253597, lng: 18.8089997 },
    { lat: 48.825396, lng: 18.8090712 },
    { lat: 48.8254345, lng: 18.8090749 },
    { lat: 48.8254598, lng: 18.8091023 },
    { lat: 48.8255036, lng: 18.8090366 },
    { lat: 48.8254936, lng: 18.8089755 },
    { lat: 48.8254956, lng: 18.8089284 },
    { lat: 48.8255232, lng: 18.8089355 },
    { lat: 48.8255928, lng: 18.8089067 },
    { lat: 48.8255845, lng: 18.8089879 },
    { lat: 48.825655, lng: 18.8091713 },
    { lat: 48.8256799, lng: 18.8091726 },
    { lat: 48.8256977, lng: 18.809125 },
    { lat: 48.8257317, lng: 18.8091517 },
    { lat: 48.8257444, lng: 18.8092458 },
    { lat: 48.8257973, lng: 18.8092634 },
    { lat: 48.8258699, lng: 18.80936 },
    { lat: 48.8259659, lng: 18.8094469 },
    { lat: 48.8259654, lng: 18.8095014 },
    { lat: 48.8259954, lng: 18.8095143 },
    { lat: 48.8260082, lng: 18.8095467 },
    { lat: 48.8259956, lng: 18.8095855 },
    { lat: 48.8259536, lng: 18.8096234 },
    { lat: 48.8259652, lng: 18.8097154 },
    { lat: 48.8260414, lng: 18.8097478 },
    { lat: 48.8260716, lng: 18.8096383 },
    { lat: 48.8261027, lng: 18.8096274 },
    { lat: 48.8261353, lng: 18.8096468 },
    { lat: 48.8261732, lng: 18.8097704 },
    { lat: 48.8261334, lng: 18.8098736 },
    { lat: 48.8261536, lng: 18.8099563 },
    { lat: 48.8262041, lng: 18.8099503 },
    { lat: 48.8262523, lng: 18.8099699 },
    { lat: 48.8262801, lng: 18.8099577 },
    { lat: 48.8262846, lng: 18.8098487 },
    { lat: 48.8263546, lng: 18.8099413 },
    { lat: 48.8263796, lng: 18.8100081 },
    { lat: 48.8264745, lng: 18.810035 },
    { lat: 48.8265195, lng: 18.8099223 },
    { lat: 48.8265378, lng: 18.8099695 },
    { lat: 48.8265926, lng: 18.8099826 },
    { lat: 48.8266682, lng: 18.8100513 },
    { lat: 48.8266951, lng: 18.8100456 },
    { lat: 48.8267736, lng: 18.8101911 },
    { lat: 48.8268182, lng: 18.8102001 },
    { lat: 48.8268627, lng: 18.8102342 },
    { lat: 48.8268866, lng: 18.8103025 },
    { lat: 48.8269234, lng: 18.8103146 },
    { lat: 48.8269747, lng: 18.8103912 },
    { lat: 48.8270087, lng: 18.8103957 },
    { lat: 48.8270303, lng: 18.8104214 },
    { lat: 48.8270654, lng: 18.8105539 },
    { lat: 48.8270752, lng: 18.8106744 },
    { lat: 48.8271066, lng: 18.8106975 },
    { lat: 48.8271488, lng: 18.8106581 },
    { lat: 48.8271857, lng: 18.810677 },
    { lat: 48.8271982, lng: 18.8108326 },
    { lat: 48.8272298, lng: 18.8108306 },
    { lat: 48.8272383, lng: 18.8109072 },
    { lat: 48.8272916, lng: 18.8109324 },
    { lat: 48.8273091, lng: 18.8110094 },
    { lat: 48.8273606, lng: 18.8110465 },
    { lat: 48.8273322, lng: 18.8111501 },
    { lat: 48.8274402, lng: 18.8113398 },
    { lat: 48.8274965, lng: 18.8113606 },
    { lat: 48.8275387, lng: 18.8113499 },
    { lat: 48.8276042, lng: 18.8113728 },
    { lat: 48.8276083, lng: 18.8114518 },
    { lat: 48.8276362, lng: 18.8114885 },
    { lat: 48.8276448, lng: 18.8116117 },
    { lat: 48.8276223, lng: 18.8117432 },
    { lat: 48.8277038, lng: 18.811857 },
    { lat: 48.8277541, lng: 18.8118467 },
    { lat: 48.8277986, lng: 18.81188 },
    { lat: 48.8277817, lng: 18.8119676 },
    { lat: 48.8278038, lng: 18.8120651 },
    { lat: 48.8278731, lng: 18.8122261 },
    { lat: 48.8279221, lng: 18.8123677 },
    { lat: 48.8279659, lng: 18.8123654 },
    { lat: 48.8280561, lng: 18.8124509 },
    { lat: 48.8280834, lng: 18.8126709 },
    { lat: 48.8281199, lng: 18.8127009 },
    { lat: 48.8281385, lng: 18.812776 },
    { lat: 48.8281138, lng: 18.8128012 },
    { lat: 48.8281384, lng: 18.8128612 },
    { lat: 48.8281486, lng: 18.8129298 },
    { lat: 48.8282439, lng: 18.8129399 },
    { lat: 48.8283064, lng: 18.8130788 },
    { lat: 48.8283537, lng: 18.8132514 },
    { lat: 48.8283914, lng: 18.8133228 },
    { lat: 48.8283576, lng: 18.8133687 },
    { lat: 48.828362, lng: 18.8134301 },
    { lat: 48.8283912, lng: 18.8134513 },
    { lat: 48.8284258, lng: 18.8135756 },
    { lat: 48.8284234, lng: 18.8136375 },
    { lat: 48.8284566, lng: 18.8136446 },
    { lat: 48.8284636, lng: 18.813671 },
    { lat: 48.8284487, lng: 18.8137103 },
    { lat: 48.8284738, lng: 18.8137793 },
    { lat: 48.8284582, lng: 18.8138713 },
    { lat: 48.8284853, lng: 18.8139375 },
    { lat: 48.8285117, lng: 18.8140546 },
    { lat: 48.8285352, lng: 18.8141152 },
    { lat: 48.8285636, lng: 18.8141425 },
    { lat: 48.8285998, lng: 18.8141436 },
    { lat: 48.8286396, lng: 18.814238 },
    { lat: 48.8286348, lng: 18.8142776 },
    { lat: 48.8286659, lng: 18.8143111 },
    { lat: 48.8287156, lng: 18.8143309 },
    { lat: 48.8287457, lng: 18.8143051 },
    { lat: 48.8288042, lng: 18.8143436 },
    { lat: 48.8288267, lng: 18.8144322 },
    { lat: 48.8287808, lng: 18.814457 },
    { lat: 48.8287829, lng: 18.8145659 },
    { lat: 48.8287511, lng: 18.8146685 },
    { lat: 48.8287868, lng: 18.8147462 },
    { lat: 48.8288062, lng: 18.8148365 },
    { lat: 48.8288323, lng: 18.8148853 },
    { lat: 48.8288475, lng: 18.8149587 },
    { lat: 48.8288147, lng: 18.8151551 },
    { lat: 48.8288121, lng: 18.815232 },
    { lat: 48.8287683, lng: 18.8153031 },
    { lat: 48.8288205, lng: 18.815503 },
    { lat: 48.8287988, lng: 18.8155851 },
    { lat: 48.828806, lng: 18.8157864 },
    { lat: 48.8288423, lng: 18.8158805 },
    { lat: 48.8288357, lng: 18.8159496 },
    { lat: 48.8288655, lng: 18.8160864 },
    { lat: 48.8289124, lng: 18.8161026 },
    { lat: 48.8289502, lng: 18.8160709 },
    { lat: 48.8289997, lng: 18.8162168 },
    { lat: 48.8290706, lng: 18.8161951 },
    { lat: 48.8290767, lng: 18.8162444 },
    { lat: 48.8290773, lng: 18.8163868 },
    { lat: 48.8290478, lng: 18.8164243 },
    { lat: 48.8290744, lng: 18.8165042 },
    { lat: 48.8291175, lng: 18.8165112 },
    { lat: 48.8291502, lng: 18.8165771 },
    { lat: 48.8291236, lng: 18.8167363 },
    { lat: 48.8290741, lng: 18.8167401 },
    { lat: 48.8290308, lng: 18.8167935 },
    { lat: 48.8290708, lng: 18.8168897 },
    { lat: 48.8290467, lng: 18.8170132 },
    { lat: 48.8290859, lng: 18.8171567 },
    { lat: 48.8291329, lng: 18.8171289 },
    { lat: 48.8291874, lng: 18.8172688 },
    { lat: 48.8292169, lng: 18.8172499 },
    { lat: 48.8292419, lng: 18.8172759 },
    { lat: 48.8292806, lng: 18.8173704 },
    { lat: 48.8292721, lng: 18.8174208 },
    { lat: 48.829289, lng: 18.8174861 },
    { lat: 48.8293947, lng: 18.8176109 },
    { lat: 48.8294876, lng: 18.8175984 },
    { lat: 48.8294991, lng: 18.8175605 },
    { lat: 48.829563, lng: 18.8176176 },
    { lat: 48.829627, lng: 18.8177435 },
    { lat: 48.8298314, lng: 18.8178118 },
    { lat: 48.8298092, lng: 18.8178392 },
    { lat: 48.8298195, lng: 18.8178877 },
    { lat: 48.8300501, lng: 18.8179189 },
    { lat: 48.8301048, lng: 18.818062 },
    { lat: 48.8300901, lng: 18.8181697 },
    { lat: 48.8301509, lng: 18.8182977 },
    { lat: 48.8302248, lng: 18.8183748 },
    { lat: 48.8303018, lng: 18.8184017 },
    { lat: 48.8303518, lng: 18.8184953 },
    { lat: 48.8303678, lng: 18.8186079 },
    { lat: 48.8304028, lng: 18.8186528 },
    { lat: 48.8304502, lng: 18.8186518 },
    { lat: 48.8305453, lng: 18.8185756 },
    { lat: 48.8305867, lng: 18.8184826 },
    { lat: 48.8306636, lng: 18.8185057 },
    { lat: 48.8307277, lng: 18.8184802 },
    { lat: 48.8307996, lng: 18.8184999 },
    { lat: 48.8308813, lng: 18.8185939 },
    { lat: 48.8310497, lng: 18.8186475 },
    { lat: 48.8311276, lng: 18.8186043 },
    { lat: 48.8311271, lng: 18.818551 },
    { lat: 48.8311915, lng: 18.8184034 },
    { lat: 48.831299, lng: 18.8183426 },
    { lat: 48.8313523, lng: 18.8183913 },
    { lat: 48.8313822, lng: 18.8184475 },
    { lat: 48.8314313, lng: 18.8184374 },
    { lat: 48.8314497, lng: 18.8185505 },
    { lat: 48.8314297, lng: 18.8186441 },
    { lat: 48.8314394, lng: 18.8186795 },
    { lat: 48.8314659, lng: 18.818691 },
    { lat: 48.8314995, lng: 18.8186236 },
    { lat: 48.8316208, lng: 18.8187025 },
    { lat: 48.8316749, lng: 18.8187648 },
    { lat: 48.8317837, lng: 18.8187769 },
    { lat: 48.8317876, lng: 18.8188698 },
    { lat: 48.8318634, lng: 18.8189875 },
    { lat: 48.8318742, lng: 18.8190857 },
    { lat: 48.8319587, lng: 18.8191247 },
    { lat: 48.8320446, lng: 18.8190851 },
    { lat: 48.8320529, lng: 18.8191145 },
    { lat: 48.8321025, lng: 18.8191816 },
    { lat: 48.8321837, lng: 18.8193337 },
    { lat: 48.8323183, lng: 18.8193195 },
    { lat: 48.832423, lng: 18.8193782 },
    { lat: 48.8324892, lng: 18.819506 },
    { lat: 48.8325441, lng: 18.8195402 },
    { lat: 48.8325844, lng: 18.819534 },
    { lat: 48.832761, lng: 18.8193998 },
    { lat: 48.8327916, lng: 18.8194423 },
    { lat: 48.8328093, lng: 18.8195687 },
    { lat: 48.8328034, lng: 18.8196288 },
    { lat: 48.8328214, lng: 18.8197104 },
    { lat: 48.8329581, lng: 18.8198047 },
    { lat: 48.8330589, lng: 18.8199896 },
    { lat: 48.8331538, lng: 18.819883 },
    { lat: 48.8332945, lng: 18.8198122 },
    { lat: 48.8334151, lng: 18.8196364 },
    { lat: 48.8335741, lng: 18.8196808 },
    { lat: 48.8336529, lng: 18.8197419 },
    { lat: 48.8337036, lng: 18.8198318 },
    { lat: 48.8337218, lng: 18.8199177 },
    { lat: 48.8336827, lng: 18.8199936 },
    { lat: 48.8336583, lng: 18.8201729 },
    { lat: 48.8335897, lng: 18.8203096 },
    { lat: 48.8335133, lng: 18.8203985 },
    { lat: 48.8334165, lng: 18.8204903 },
    { lat: 48.8333654, lng: 18.8205039 },
    { lat: 48.8332765, lng: 18.8205521 },
    { lat: 48.8332385, lng: 18.8206661 },
    { lat: 48.8332476, lng: 18.8209693 },
    { lat: 48.8332713, lng: 18.8211425 },
    { lat: 48.8333356, lng: 18.8212991 },
    { lat: 48.8334517, lng: 18.8214056 },
    { lat: 48.8335734, lng: 18.8212966 },
    { lat: 48.8337061, lng: 18.8212428 },
    { lat: 48.8338791, lng: 18.8211034 },
    { lat: 48.833954, lng: 18.821113 },
    { lat: 48.8341096, lng: 18.8210698 },
    { lat: 48.8341156, lng: 18.8210934 },
    { lat: 48.8341928, lng: 18.8210434 },
    { lat: 48.8343629, lng: 18.8210367 },
    { lat: 48.8344423, lng: 18.8210247 },
    { lat: 48.8344662, lng: 18.8210749 },
    { lat: 48.834412, lng: 18.8212675 },
    { lat: 48.8344304, lng: 18.8213186 },
    { lat: 48.8345464, lng: 18.8214218 },
    { lat: 48.8345931, lng: 18.8214524 },
    { lat: 48.8346794, lng: 18.8213995 },
    { lat: 48.8347221, lng: 18.8212874 },
    { lat: 48.834782, lng: 18.8213128 },
    { lat: 48.8348696, lng: 18.8212394 },
    { lat: 48.8349054, lng: 18.8211686 },
    { lat: 48.834985, lng: 18.8211823 },
    { lat: 48.8350862, lng: 18.8212873 },
    { lat: 48.8351406, lng: 18.8213789 },
    { lat: 48.83503, lng: 18.8214605 },
    { lat: 48.834978, lng: 18.8214377 },
    { lat: 48.834926, lng: 18.8215015 },
    { lat: 48.8349165, lng: 18.8216 },
    { lat: 48.8350206, lng: 18.8219355 },
    { lat: 48.835098, lng: 18.8220336 },
    { lat: 48.8351763, lng: 18.8220672 },
    { lat: 48.8352928, lng: 18.8220498 },
    { lat: 48.8354107, lng: 18.8219996 },
    { lat: 48.8354874, lng: 18.8219554 },
    { lat: 48.8356529, lng: 18.8219267 },
    { lat: 48.8357771, lng: 18.8218886 },
    { lat: 48.835832, lng: 18.8218388 },
    { lat: 48.8358073, lng: 18.8216638 },
    { lat: 48.8357557, lng: 18.8215844 },
    { lat: 48.8356726, lng: 18.8213946 },
    { lat: 48.8356062, lng: 18.8213323 },
    { lat: 48.8356964, lng: 18.8212028 },
    { lat: 48.8357755, lng: 18.8212066 },
    { lat: 48.8358137, lng: 18.8212231 },
    { lat: 48.8358419, lng: 18.8213107 },
    { lat: 48.8360131, lng: 18.8215448 },
    { lat: 48.8360545, lng: 18.8216279 },
    { lat: 48.8360398, lng: 18.8216676 },
    { lat: 48.8360653, lng: 18.8218138 },
    { lat: 48.8359632, lng: 18.82204 },
    { lat: 48.8359717, lng: 18.822441 },
    { lat: 48.836018, lng: 18.8225317 },
    { lat: 48.8362396, lng: 18.8226306 },
    { lat: 48.8362857, lng: 18.8227132 },
    { lat: 48.8363378, lng: 18.8227388 },
    { lat: 48.836389, lng: 18.8226812 },
    { lat: 48.8364338, lng: 18.822567 },
    { lat: 48.8363827, lng: 18.8223018 },
    { lat: 48.8365002, lng: 18.8221747 },
    { lat: 48.8365481, lng: 18.8220777 },
    { lat: 48.8364962, lng: 18.8216425 },
    { lat: 48.836572, lng: 18.821583 },
    { lat: 48.8366133, lng: 18.8214868 },
    { lat: 48.8366313, lng: 18.8213547 },
    { lat: 48.8366214, lng: 18.8212969 },
    { lat: 48.8366355, lng: 18.8212 },
    { lat: 48.8366725, lng: 18.8210613 },
    { lat: 48.8367136, lng: 18.8209655 },
    { lat: 48.8367407, lng: 18.8209873 },
    { lat: 48.8367498, lng: 18.8210296 },
    { lat: 48.8368012, lng: 18.8210639 },
    { lat: 48.8368859, lng: 18.8210259 },
    { lat: 48.8369203, lng: 18.8210604 },
    { lat: 48.8370181, lng: 18.8210505 },
    { lat: 48.8371833, lng: 18.8211064 },
    { lat: 48.8371663, lng: 18.8212569 },
    { lat: 48.8371958, lng: 18.8213243 },
    { lat: 48.8373358, lng: 18.8213481 },
    { lat: 48.8373985, lng: 18.8212146 },
    { lat: 48.8373864, lng: 18.8211083 },
    { lat: 48.8374402, lng: 18.8209519 },
    { lat: 48.837408, lng: 18.8207882 },
    { lat: 48.8373237, lng: 18.8206608 },
    { lat: 48.8372625, lng: 18.8206105 },
    { lat: 48.837234, lng: 18.8204349 },
    { lat: 48.8371965, lng: 18.8202543 },
    { lat: 48.8371516, lng: 18.8201279 },
    { lat: 48.8371609, lng: 18.8200474 },
    { lat: 48.8372074, lng: 18.8200286 },
    { lat: 48.8374102, lng: 18.820115 },
    { lat: 48.8374986, lng: 18.8202061 },
    { lat: 48.8375054, lng: 18.8202508 },
    { lat: 48.8374889, lng: 18.8203927 },
    { lat: 48.8375058, lng: 18.8204329 },
    { lat: 48.8376704, lng: 18.8203249 },
    { lat: 48.8376977, lng: 18.8202407 },
    { lat: 48.8379338, lng: 18.8202061 },
    { lat: 48.8380027, lng: 18.8202323 },
    { lat: 48.8381518, lng: 18.8201493 },
    { lat: 48.8382659, lng: 18.8200445 },
    { lat: 48.8384, lng: 18.8200195 },
    { lat: 48.8384651, lng: 18.8200819 },
    { lat: 48.8385729, lng: 18.8200737 },
    { lat: 48.8387873, lng: 18.8202468 },
    { lat: 48.8388213, lng: 18.8202517 },
    { lat: 48.8389074, lng: 18.8203252 },
    { lat: 48.8390054, lng: 18.8202566 },
    { lat: 48.8391819, lng: 18.8203336 },
    { lat: 48.8392842, lng: 18.8203701 },
    { lat: 48.8392229, lng: 18.8206688 },
    { lat: 48.8392751, lng: 18.820741 },
    { lat: 48.839346, lng: 18.8207809 },
    { lat: 48.8394142, lng: 18.8209206 },
    { lat: 48.8396283, lng: 18.8210887 },
    { lat: 48.8396945, lng: 18.8210862 },
    { lat: 48.8397388, lng: 18.8210472 },
    { lat: 48.8398056, lng: 18.8209219 },
    { lat: 48.8398221, lng: 18.8208494 },
    { lat: 48.8398989, lng: 18.820832 },
    { lat: 48.839963, lng: 18.8207416 },
    { lat: 48.83998, lng: 18.8206777 },
    { lat: 48.8399871, lng: 18.8206192 },
    { lat: 48.8400935, lng: 18.8204737 },
    { lat: 48.8401126, lng: 18.8203408 },
    { lat: 48.8401599, lng: 18.8202564 },
    { lat: 48.8401894, lng: 18.8202593 },
    { lat: 48.8402198, lng: 18.8202994 },
    { lat: 48.8402794, lng: 18.8203413 },
    { lat: 48.8403299, lng: 18.8203131 },
    { lat: 48.8404345, lng: 18.8202022 },
    { lat: 48.8404705, lng: 18.8201359 },
    { lat: 48.8405414, lng: 18.820067 },
    { lat: 48.8405922, lng: 18.8200667 },
    { lat: 48.8406068, lng: 18.820216 },
    { lat: 48.8406435, lng: 18.8203401 },
    { lat: 48.8406233, lng: 18.8204481 },
    { lat: 48.8405805, lng: 18.8205159 },
    { lat: 48.8405831, lng: 18.8206749 },
    { lat: 48.8406373, lng: 18.8207407 },
    { lat: 48.8408867, lng: 18.8206369 },
    { lat: 48.8409703, lng: 18.8206179 },
    { lat: 48.841002, lng: 18.820641 },
    { lat: 48.8410653, lng: 18.8205773 },
    { lat: 48.84122, lng: 18.8204525 },
    { lat: 48.8412452, lng: 18.8202648 },
    { lat: 48.8413103, lng: 18.8202359 },
    { lat: 48.8414127, lng: 18.8201009 },
    { lat: 48.8414131, lng: 18.8200214 },
    { lat: 48.8415129, lng: 18.8199475 },
    { lat: 48.8415493, lng: 18.8198652 },
    { lat: 48.8415538, lng: 18.8197794 },
    { lat: 48.8416508, lng: 18.819867 },
    { lat: 48.8417085, lng: 18.8198723 },
    { lat: 48.8418181, lng: 18.8200268 },
    { lat: 48.8419514, lng: 18.8200538 },
    { lat: 48.8419987, lng: 18.8201373 },
    { lat: 48.8420288, lng: 18.8201337 },
    { lat: 48.8420733, lng: 18.8201868 },
    { lat: 48.8421247, lng: 18.8204162 },
    { lat: 48.8421055, lng: 18.8205008 },
    { lat: 48.8421787, lng: 18.8206102 },
    { lat: 48.8423242, lng: 18.8205668 },
    { lat: 48.8425044, lng: 18.8206469 },
    { lat: 48.8426102, lng: 18.8206454 },
    { lat: 48.8426381, lng: 18.8207237 },
    { lat: 48.8426514, lng: 18.8208295 },
    { lat: 48.8427391, lng: 18.8210618 },
    { lat: 48.8427352, lng: 18.8213565 },
    { lat: 48.8430564, lng: 18.8215484 },
    { lat: 48.8432705, lng: 18.8214165 },
    { lat: 48.8434293, lng: 18.8210901 },
    { lat: 48.843433, lng: 18.8210299 },
    { lat: 48.8434025, lng: 18.8208108 },
    { lat: 48.8434322, lng: 18.8207729 },
    { lat: 48.8434957, lng: 18.8208488 },
    { lat: 48.8438897, lng: 18.8206364 },
    { lat: 48.844104, lng: 18.8205961 },
    { lat: 48.8441959, lng: 18.8205175 },
    { lat: 48.844267, lng: 18.8203898 },
    { lat: 48.8443214, lng: 18.8202459 },
    { lat: 48.8443476, lng: 18.8201182 },
    { lat: 48.8443428, lng: 18.8199852 },
    { lat: 48.8443515, lng: 18.8199333 },
    { lat: 48.8443425, lng: 18.8198245 },
    { lat: 48.8444151, lng: 18.8195935 },
    { lat: 48.8444908, lng: 18.8194033 },
    { lat: 48.8445472, lng: 18.8192931 },
    { lat: 48.8445903, lng: 18.8192547 },
    { lat: 48.8446397, lng: 18.8192953 },
    { lat: 48.8446891, lng: 18.8193138 },
    { lat: 48.844694, lng: 18.8193408 },
    { lat: 48.844668, lng: 18.819408 },
    { lat: 48.8445988, lng: 18.8195276 },
    { lat: 48.8446057, lng: 18.8196818 },
    { lat: 48.8446888, lng: 18.8198487 },
    { lat: 48.8448765, lng: 18.8197955 },
    { lat: 48.845006, lng: 18.8197915 },
    { lat: 48.8451655, lng: 18.8197145 },
    { lat: 48.8453918, lng: 18.8193596 },
    { lat: 48.8454609, lng: 18.8192146 },
    { lat: 48.845529, lng: 18.8189211 },
    { lat: 48.8456249, lng: 18.818769 },
    { lat: 48.8457443, lng: 18.8186119 },
    { lat: 48.8457643, lng: 18.8184334 },
    { lat: 48.8457714, lng: 18.8182005 },
    { lat: 48.8458047, lng: 18.8181289 },
    { lat: 48.8457765, lng: 18.8180624 },
    { lat: 48.8457284, lng: 18.8180474 },
    { lat: 48.8456528, lng: 18.8178291 },
    { lat: 48.8455426, lng: 18.8176826 },
    { lat: 48.8454595, lng: 18.8175378 },
    { lat: 48.8453792, lng: 18.817488 },
    { lat: 48.8453103, lng: 18.8175087 },
    { lat: 48.8452905, lng: 18.8174042 },
    { lat: 48.845279, lng: 18.8173431 },
    { lat: 48.8452173, lng: 18.8172144 },
    { lat: 48.8452028, lng: 18.8171539 },
    { lat: 48.8452695, lng: 18.8170274 },
    { lat: 48.8453388, lng: 18.8169937 },
    { lat: 48.8456089, lng: 18.8169432 },
    { lat: 48.845692, lng: 18.8168929 },
    { lat: 48.8457381, lng: 18.8168007 },
    { lat: 48.8457735, lng: 18.8167013 },
    { lat: 48.845801, lng: 18.816665 },
    { lat: 48.8458314, lng: 18.8165594 },
    { lat: 48.8459179, lng: 18.8163142 },
    { lat: 48.8459779, lng: 18.8162104 },
    { lat: 48.8460827, lng: 18.8160582 },
    { lat: 48.8462669, lng: 18.8158683 },
    { lat: 48.8463322, lng: 18.8157567 },
    { lat: 48.8463697, lng: 18.8156098 },
    { lat: 48.8463713, lng: 18.8155136 },
    { lat: 48.846357, lng: 18.815452 },
    { lat: 48.8462845, lng: 18.8154033 },
    { lat: 48.8462298, lng: 18.8153916 },
    { lat: 48.8461472, lng: 18.8154072 },
    { lat: 48.8461725, lng: 18.8153125 },
    { lat: 48.8462679, lng: 18.8153018 },
    { lat: 48.846318, lng: 18.8152486 },
    { lat: 48.8463556, lng: 18.8150343 },
    { lat: 48.8463545, lng: 18.8149088 },
    { lat: 48.8463022, lng: 18.8147489 },
    { lat: 48.8463266, lng: 18.8146726 },
    { lat: 48.8464076, lng: 18.8145394 },
    { lat: 48.8464468, lng: 18.8144484 },
    { lat: 48.8464517, lng: 18.8143473 },
    { lat: 48.846419, lng: 18.814305 },
    { lat: 48.846432, lng: 18.8142089 },
    { lat: 48.8465195, lng: 18.8140696 },
    { lat: 48.8465532, lng: 18.8138049 },
    { lat: 48.8466063, lng: 18.8135669 },
    { lat: 48.8467281, lng: 18.8132871 },
    { lat: 48.8467869, lng: 18.8132285 },
    { lat: 48.8468455, lng: 18.8132043 },
    { lat: 48.8469222, lng: 18.8132038 },
    { lat: 48.8470565, lng: 18.8132278 },
    { lat: 48.8471604, lng: 18.8130998 },
    { lat: 48.8472127, lng: 18.8130867 },
    { lat: 48.8473239, lng: 18.8131204 },
    { lat: 48.8474489, lng: 18.8131398 },
    { lat: 48.8476114, lng: 18.8130792 },
    { lat: 48.8476753, lng: 18.8130727 },
    { lat: 48.8477701, lng: 18.8131372 },
    { lat: 48.8480278, lng: 18.8131484 },
    { lat: 48.8481021, lng: 18.8130818 },
    { lat: 48.8481498, lng: 18.8129129 },
    { lat: 48.8481721, lng: 18.8127154 },
    { lat: 48.8481614, lng: 18.8126193 },
    { lat: 48.8481156, lng: 18.8124959 },
    { lat: 48.8479925, lng: 18.8124064 },
    { lat: 48.8478618, lng: 18.8123637 },
    { lat: 48.8478218, lng: 18.8122682 },
    { lat: 48.8478515, lng: 18.8121673 },
    { lat: 48.8479467, lng: 18.812152 },
    { lat: 48.8481178, lng: 18.81193 },
    { lat: 48.8482537, lng: 18.8117909 },
    { lat: 48.8483045, lng: 18.8117723 },
    { lat: 48.8483522, lng: 18.81184 },
    { lat: 48.8484857, lng: 18.8120897 },
    { lat: 48.8485, lng: 18.812233 },
    { lat: 48.8485562, lng: 18.8124268 },
    { lat: 48.8486908, lng: 18.812584 },
    { lat: 48.848772, lng: 18.8125826 },
    { lat: 48.8488465, lng: 18.8125196 },
    { lat: 48.8489215, lng: 18.8125311 },
    { lat: 48.8491061, lng: 18.8126325 },
    { lat: 48.8492616, lng: 18.8126734 },
    { lat: 48.8493211, lng: 18.812604 },
    { lat: 48.8493456, lng: 18.8124485 },
    { lat: 48.8493185, lng: 18.8123179 },
    { lat: 48.849403, lng: 18.8123382 },
    { lat: 48.8494898, lng: 18.812272 },
    { lat: 48.8495255, lng: 18.8120916 },
    { lat: 48.8494979, lng: 18.8118826 },
    { lat: 48.849525, lng: 18.8117139 },
    { lat: 48.8495591, lng: 18.8116339 },
    { lat: 48.8497261, lng: 18.8115459 },
    { lat: 48.8498162, lng: 18.8114797 },
    { lat: 48.8499421, lng: 18.8114507 },
    { lat: 48.8500242, lng: 18.8114026 },
    { lat: 48.8501383, lng: 18.8112572 },
    { lat: 48.8501906, lng: 18.8111141 },
    { lat: 48.8501763, lng: 18.8109877 },
    { lat: 48.8501028, lng: 18.8109195 },
    { lat: 48.8500407, lng: 18.8107668 },
    { lat: 48.8500647, lng: 18.8107282 },
    { lat: 48.8502316, lng: 18.8105761 },
    { lat: 48.8502812, lng: 18.8104707 },
    { lat: 48.8503415, lng: 18.8102841 },
    { lat: 48.8504103, lng: 18.8102866 },
    { lat: 48.8504842, lng: 18.8104249 },
    { lat: 48.8505257, lng: 18.8106194 },
    { lat: 48.8505348, lng: 18.8111801 },
    { lat: 48.8505816, lng: 18.8112794 },
    { lat: 48.8506189, lng: 18.8112789 },
    { lat: 48.8507058, lng: 18.8113698 },
    { lat: 48.8506595, lng: 18.8114337 },
    { lat: 48.8506365, lng: 18.8115536 },
    { lat: 48.8506635, lng: 18.8116825 },
    { lat: 48.850681, lng: 18.8117151 },
    { lat: 48.8507098, lng: 18.8116415 },
    { lat: 48.8509463, lng: 18.8114643 },
    { lat: 48.8512172, lng: 18.8119253 },
    { lat: 48.85127, lng: 18.8122445 },
    { lat: 48.8514344, lng: 18.8121973 },
    { lat: 48.851498, lng: 18.8119906 },
    { lat: 48.851578, lng: 18.8117705 },
    { lat: 48.8515785, lng: 18.8115835 },
    { lat: 48.8515038, lng: 18.81147 },
    { lat: 48.8513884, lng: 18.8115275 },
    { lat: 48.8513338, lng: 18.8114134 },
    { lat: 48.8512795, lng: 18.8112999 },
    { lat: 48.8514593, lng: 18.8112458 },
    { lat: 48.8515669, lng: 18.8107972 },
    { lat: 48.8517229, lng: 18.8108709 },
    { lat: 48.8517472, lng: 18.8112293 },
    { lat: 48.8518039, lng: 18.8113013 },
    { lat: 48.8519254, lng: 18.8111855 },
    { lat: 48.8519691, lng: 18.8107262 },
    { lat: 48.8522493, lng: 18.8108238 },
    { lat: 48.8521774, lng: 18.8111328 },
    { lat: 48.8522028, lng: 18.8112705 },
    { lat: 48.8522852, lng: 18.8113376 },
    { lat: 48.8523148, lng: 18.8112793 },
    { lat: 48.8524092, lng: 18.8109909 },
    { lat: 48.8526912, lng: 18.8111488 },
    { lat: 48.8530302, lng: 18.8110728 },
    { lat: 48.8530243, lng: 18.8111548 },
    { lat: 48.8529504, lng: 18.8117691 },
    { lat: 48.8530108, lng: 18.8118503 },
    { lat: 48.8537419, lng: 18.811314 },
    { lat: 48.8537773, lng: 18.8112808 },
    { lat: 48.8537916, lng: 18.8112515 },
    { lat: 48.853851, lng: 18.8110496 },
    { lat: 48.8538203, lng: 18.8108721 },
    { lat: 48.8537255, lng: 18.8105225 },
    { lat: 48.8536944, lng: 18.8099687 },
    { lat: 48.8538508, lng: 18.8100051 },
    { lat: 48.8540056, lng: 18.8099655 },
    { lat: 48.854153, lng: 18.8096782 },
    { lat: 48.8542172, lng: 18.8096122 },
    { lat: 48.8543851, lng: 18.8095878 },
    { lat: 48.8544328, lng: 18.8098793 },
    { lat: 48.8545173, lng: 18.8100039 },
    { lat: 48.8546787, lng: 18.8098987 },
    { lat: 48.8546847, lng: 18.8095825 },
    { lat: 48.8546451, lng: 18.8092426 },
    { lat: 48.854621, lng: 18.8089459 },
    { lat: 48.8546787, lng: 18.8089225 },
    { lat: 48.8551993, lng: 18.8098046 },
    { lat: 48.8556533, lng: 18.8104926 },
    { lat: 48.8558678, lng: 18.8111907 },
    { lat: 48.8557625, lng: 18.8116785 },
    { lat: 48.8557571, lng: 18.8122284 },
    { lat: 48.855912, lng: 18.8129497 },
    { lat: 48.8560603, lng: 18.8138519 },
    { lat: 48.8560959, lng: 18.8149907 },
    { lat: 48.856177, lng: 18.8157062 },
    { lat: 48.8563487, lng: 18.8165129 },
    { lat: 48.856711, lng: 18.8179736 },
    { lat: 48.8568691, lng: 18.8182373 },
    { lat: 48.8570935, lng: 18.8186499 },
    { lat: 48.8571679, lng: 18.8186721 },
    { lat: 48.8571664, lng: 18.8189484 },
    { lat: 48.8578373, lng: 18.8185131 },
    { lat: 48.8581105, lng: 18.8184956 },
    { lat: 48.8583809, lng: 18.8185786 },
    { lat: 48.8585663, lng: 18.8186786 },
    { lat: 48.8587177, lng: 18.8188539 },
    { lat: 48.8589462, lng: 18.8192596 },
    { lat: 48.8593815, lng: 18.8197373 },
    { lat: 48.860184, lng: 18.8199936 },
    { lat: 48.8608526, lng: 18.8201412 },
    { lat: 48.8612441, lng: 18.8199956 },
    { lat: 48.8616882, lng: 18.8200347 },
    { lat: 48.8619236, lng: 18.8199858 },
    { lat: 48.8633044, lng: 18.8196288 },
    { lat: 48.8636257, lng: 18.8194772 },
    { lat: 48.8639157, lng: 18.8193051 },
    { lat: 48.8641505, lng: 18.8192678 },
    { lat: 48.8649516, lng: 18.8192553 },
    { lat: 48.8657977, lng: 18.8194773 },
    { lat: 48.8665209, lng: 18.8197678 },
    { lat: 48.8671747, lng: 18.8201135 },
    { lat: 48.8671853, lng: 18.819989 },
    { lat: 48.8672489, lng: 18.8188063 },
    { lat: 48.8675044, lng: 18.8177522 },
    { lat: 48.8678742, lng: 18.8169068 },
    { lat: 48.8679209, lng: 18.8168958 },
    { lat: 48.8681047, lng: 18.8166142 },
    { lat: 48.8682156, lng: 18.8164015 },
    { lat: 48.868263, lng: 18.8163414 },
    { lat: 48.8684529, lng: 18.8159988 },
    { lat: 48.868869, lng: 18.8151343 },
    { lat: 48.8689551, lng: 18.8149675 },
    { lat: 48.8689842, lng: 18.81494 },
    { lat: 48.869178, lng: 18.8145683 },
    { lat: 48.8694435, lng: 18.8140803 },
    { lat: 48.8695207, lng: 18.814002 },
    { lat: 48.8697102, lng: 18.8139603 },
    { lat: 48.8697658, lng: 18.8139268 },
    { lat: 48.8698102, lng: 18.8138674 },
    { lat: 48.8699929, lng: 18.8135225 },
    { lat: 48.8700876, lng: 18.8133665 },
    { lat: 48.8706178, lng: 18.812633 },
    { lat: 48.8708165, lng: 18.8124015 },
    { lat: 48.8708939, lng: 18.8123045 },
    { lat: 48.8709992, lng: 18.8121217 },
    { lat: 48.8710915, lng: 18.8119184 },
    { lat: 48.871294, lng: 18.8113472 },
    { lat: 48.8713737, lng: 18.811103 },
    { lat: 48.8714092, lng: 18.8109401 },
    { lat: 48.8715126, lng: 18.8104105 },
    { lat: 48.8715569, lng: 18.8102218 },
    { lat: 48.8716265, lng: 18.8100211 },
    { lat: 48.8717522, lng: 18.8096841 },
    { lat: 48.8722954, lng: 18.8092466 },
    { lat: 48.8724016, lng: 18.8091003 },
    { lat: 48.8725783, lng: 18.8087435 },
    { lat: 48.872686, lng: 18.808538 },
    { lat: 48.8727542, lng: 18.8083783 },
    { lat: 48.8727764, lng: 18.8080865 },
    { lat: 48.8728061, lng: 18.8075321 },
    { lat: 48.8728418, lng: 18.8071789 },
    { lat: 48.8728883, lng: 18.8069688 },
    { lat: 48.872989, lng: 18.8066702 },
    { lat: 48.8731629, lng: 18.8061737 },
    { lat: 48.8732618, lng: 18.8059537 },
    { lat: 48.8735346, lng: 18.805584 },
    { lat: 48.8737652, lng: 18.8053121 },
    { lat: 48.8740769, lng: 18.8049535 },
    { lat: 48.874179, lng: 18.8048589 },
    { lat: 48.8739212, lng: 18.8043671 },
    { lat: 48.8729653, lng: 18.8026191 },
    { lat: 48.8739688, lng: 18.8027906 },
    { lat: 48.8740604, lng: 18.8028158 },
    { lat: 48.873757, lng: 18.8021855 },
    { lat: 48.873547, lng: 18.8018377 },
    { lat: 48.8731487, lng: 18.8010088 },
    { lat: 48.8729049, lng: 18.800616 },
    { lat: 48.8725698, lng: 18.8000476 },
    { lat: 48.872104, lng: 18.7995696 },
    { lat: 48.8718342, lng: 18.7993501 },
    { lat: 48.8716618, lng: 18.7991791 },
    { lat: 48.8715152, lng: 18.7988965 },
    { lat: 48.8713922, lng: 18.7986343 },
    { lat: 48.8709995, lng: 18.7982809 },
    { lat: 48.8708551, lng: 18.7980632 },
    { lat: 48.8706955, lng: 18.7977714 },
    { lat: 48.8705534, lng: 18.7974929 },
    { lat: 48.8704833, lng: 18.7972721 },
    { lat: 48.8704738, lng: 18.7970905 },
    { lat: 48.870532, lng: 18.7967565 },
    { lat: 48.8703953, lng: 18.7964695 },
    { lat: 48.8701656, lng: 18.7960654 },
    { lat: 48.8700043, lng: 18.7956083 },
    { lat: 48.8694951, lng: 18.7944948 },
    { lat: 48.8692127, lng: 18.7938599 },
    { lat: 48.8691339, lng: 18.7936451 },
    { lat: 48.8690649, lng: 18.7931645 },
    { lat: 48.8690433, lng: 18.7930764 },
    { lat: 48.8687865, lng: 18.7918055 },
    { lat: 48.8691853, lng: 18.7922295 },
    { lat: 48.8691906, lng: 18.7918144 },
    { lat: 48.8695168, lng: 18.7916893 },
    { lat: 48.8693832, lng: 18.7912712 },
    { lat: 48.8694979, lng: 18.7908287 },
    { lat: 48.8693432, lng: 18.7905687 },
    { lat: 48.8695959, lng: 18.79029 },
    { lat: 48.8696488, lng: 18.7903066 },
    { lat: 48.8697087, lng: 18.7903553 },
    { lat: 48.8697878, lng: 18.7905292 },
    { lat: 48.8699779, lng: 18.7908227 },
    { lat: 48.8700366, lng: 18.7909561 },
    { lat: 48.8700949, lng: 18.791058 },
    { lat: 48.8701871, lng: 18.7911171 },
    { lat: 48.8702623, lng: 18.7910881 },
    { lat: 48.8703289, lng: 18.7910231 },
    { lat: 48.8703523, lng: 18.79091 },
    { lat: 48.8702922, lng: 18.7907327 },
    { lat: 48.8703049, lng: 18.7906954 },
    { lat: 48.8704782, lng: 18.7907104 },
    { lat: 48.8704925, lng: 18.7907664 },
    { lat: 48.8704333, lng: 18.7908863 },
    { lat: 48.8703884, lng: 18.7910171 },
    { lat: 48.8704099, lng: 18.7910847 },
    { lat: 48.8704232, lng: 18.7912321 },
    { lat: 48.8705128, lng: 18.7912425 },
    { lat: 48.8706535, lng: 18.7912385 },
    { lat: 48.8707326, lng: 18.7911971 },
    { lat: 48.8711002, lng: 18.7912401 },
    { lat: 48.8711947, lng: 18.7912821 },
    { lat: 48.8713144, lng: 18.7913699 },
    { lat: 48.8713841, lng: 18.7913422 },
    { lat: 48.8714178, lng: 18.7912554 },
    { lat: 48.8714882, lng: 18.7910277 },
    { lat: 48.8715253, lng: 18.7908743 },
    { lat: 48.8715465, lng: 18.7906292 },
    { lat: 48.8715892, lng: 18.7905413 },
    { lat: 48.8716617, lng: 18.7905047 },
    { lat: 48.8718271, lng: 18.7905171 },
    { lat: 48.8719404, lng: 18.790573 },
    { lat: 48.8720478, lng: 18.7905759 },
    { lat: 48.8721839, lng: 18.7905488 },
    { lat: 48.8722428, lng: 18.7902505 },
    { lat: 48.8722841, lng: 18.7898741 },
    { lat: 48.8723, lng: 18.7897708 },
    { lat: 48.8722875, lng: 18.7896584 },
    { lat: 48.8723005, lng: 18.789587 },
    { lat: 48.8723734, lng: 18.7895335 },
    { lat: 48.8724354, lng: 18.7895131 },
    { lat: 48.8726259, lng: 18.7896178 },
    { lat: 48.8728935, lng: 18.7896695 },
    { lat: 48.8730433, lng: 18.7897959 },
    { lat: 48.8732569, lng: 18.7900236 },
    { lat: 48.8734192, lng: 18.7901266 },
    { lat: 48.8734951, lng: 18.7900452 },
    { lat: 48.8735354, lng: 18.789935 },
    { lat: 48.8735427, lng: 18.7898582 },
    { lat: 48.8735869, lng: 18.7896444 },
    { lat: 48.873648, lng: 18.7896711 },
    { lat: 48.8738736, lng: 18.7895652 },
    { lat: 48.8739535, lng: 18.7896473 },
    { lat: 48.8740311, lng: 18.7897523 },
    { lat: 48.8741695, lng: 18.789788 },
    { lat: 48.8742792, lng: 18.7898397 },
    { lat: 48.8745382, lng: 18.7899164 },
    { lat: 48.8746695, lng: 18.7899061 },
    { lat: 48.8748332, lng: 18.7898685 },
    { lat: 48.8749559, lng: 18.7897331 },
    { lat: 48.8749777, lng: 18.7898254 },
    { lat: 48.8749017, lng: 18.7901222 },
    { lat: 48.8748732, lng: 18.7902671 },
    { lat: 48.8748982, lng: 18.7904217 },
    { lat: 48.8749662, lng: 18.7906013 },
    { lat: 48.8750079, lng: 18.7908388 },
    { lat: 48.8750812, lng: 18.7910124 },
    { lat: 48.8752592, lng: 18.7910727 },
    { lat: 48.8754139, lng: 18.7910527 },
    { lat: 48.8755183, lng: 18.790961 },
    { lat: 48.8755856, lng: 18.7908426 },
    { lat: 48.8756147, lng: 18.7906897 },
    { lat: 48.8756267, lng: 18.7904834 },
    { lat: 48.8756706, lng: 18.7900077 },
    { lat: 48.8756215, lng: 18.7896278 },
    { lat: 48.875575, lng: 18.7895768 },
    { lat: 48.8754848, lng: 18.789431 },
    { lat: 48.8754814, lng: 18.789041 },
    { lat: 48.8754984, lng: 18.7889999 },
    { lat: 48.875541, lng: 18.7889518 },
    { lat: 48.8758134, lng: 18.7888374 },
    { lat: 48.875976, lng: 18.7887108 },
    { lat: 48.87618, lng: 18.7886845 },
    { lat: 48.8762933, lng: 18.7888046 },
    { lat: 48.8765151, lng: 18.7890563 },
    { lat: 48.8766706, lng: 18.7891845 },
    { lat: 48.8767853, lng: 18.7893083 },
    { lat: 48.8769058, lng: 18.7893487 },
    { lat: 48.8770084, lng: 18.7895628 },
    { lat: 48.8771507, lng: 18.7898474 },
    { lat: 48.8771388, lng: 18.7900724 },
    { lat: 48.8770747, lng: 18.790098 },
    { lat: 48.8769895, lng: 18.7900423 },
    { lat: 48.8768801, lng: 18.7900421 },
    { lat: 48.8768033, lng: 18.7901273 },
    { lat: 48.8767796, lng: 18.7902136 },
    { lat: 48.8768108, lng: 18.7903177 },
    { lat: 48.8768798, lng: 18.7904481 },
    { lat: 48.8770698, lng: 18.7906341 },
    { lat: 48.8772525, lng: 18.790739 },
    { lat: 48.8773513, lng: 18.7907315 },
    { lat: 48.8774457, lng: 18.7906206 },
    { lat: 48.8774184, lng: 18.7903548 },
    { lat: 48.8774247, lng: 18.7902577 },
    { lat: 48.8774486, lng: 18.7902175 },
    { lat: 48.8775229, lng: 18.7902154 },
    { lat: 48.8776864, lng: 18.7902836 },
    { lat: 48.8779055, lng: 18.7903526 },
    { lat: 48.8779325, lng: 18.790244 },
    { lat: 48.8778166, lng: 18.7895984 },
    { lat: 48.8780978, lng: 18.7894142 },
    { lat: 48.8783095, lng: 18.7891688 },
    { lat: 48.8783373, lng: 18.7889265 },
    { lat: 48.8781753, lng: 18.7888654 },
    { lat: 48.8780232, lng: 18.7889133 },
    { lat: 48.8779654, lng: 18.78906 },
    { lat: 48.8778704, lng: 18.7890316 },
    { lat: 48.8778252, lng: 18.7890944 },
    { lat: 48.8775926, lng: 18.7889611 },
    { lat: 48.8776187, lng: 18.7886362 },
    { lat: 48.8773693, lng: 18.7887432 },
    { lat: 48.8771905, lng: 18.7884923 },
    { lat: 48.8770107, lng: 18.7880941 },
    { lat: 48.8764776, lng: 18.788229 },
    { lat: 48.8763321, lng: 18.7881859 },
    { lat: 48.8760016, lng: 18.7877301 },
    { lat: 48.8758, lng: 18.7880602 },
    { lat: 48.8755298, lng: 18.7880027 },
    { lat: 48.8753333, lng: 18.7878017 },
    { lat: 48.8752388, lng: 18.7878922 },
    { lat: 48.8751959, lng: 18.7876989 },
    { lat: 48.8752877, lng: 18.7874486 },
    { lat: 48.8751275, lng: 18.787231 },
    { lat: 48.8750163, lng: 18.787129 },
    { lat: 48.8749205, lng: 18.7876076 },
    { lat: 48.87462, lng: 18.7872951 },
    { lat: 48.8745375, lng: 18.7868382 },
    { lat: 48.874211, lng: 18.7865457 },
    { lat: 48.8742442, lng: 18.786253 },
    { lat: 48.874189, lng: 18.7861869 },
    { lat: 48.8739502, lng: 18.7863664 },
    { lat: 48.873827, lng: 18.7862504 },
    { lat: 48.8739187, lng: 18.7861111 },
    { lat: 48.8735592, lng: 18.7858111 },
    { lat: 48.8733532, lng: 18.785603 },
    { lat: 48.8732935, lng: 18.7855826 },
    { lat: 48.8732195, lng: 18.785522 },
    { lat: 48.8731883, lng: 18.7854462 },
    { lat: 48.872991, lng: 18.7851657 },
    { lat: 48.8728801, lng: 18.7849867 },
    { lat: 48.8724858, lng: 18.7843882 },
    { lat: 48.8724327, lng: 18.7843659 },
    { lat: 48.8723635, lng: 18.7841858 },
    { lat: 48.8721554, lng: 18.7838701 },
    { lat: 48.8721256, lng: 18.783816 },
    { lat: 48.8720696, lng: 18.7837625 },
    { lat: 48.8719677, lng: 18.7835795 },
    { lat: 48.871858, lng: 18.7834423 },
    { lat: 48.8717807, lng: 18.7832792 },
    { lat: 48.8717381, lng: 18.7829852 },
    { lat: 48.8716356, lng: 18.7825107 },
    { lat: 48.8716377, lng: 18.7824639 },
    { lat: 48.8716231, lng: 18.7824245 },
    { lat: 48.8715592, lng: 18.7821284 },
    { lat: 48.8715053, lng: 18.7821109 },
    { lat: 48.8713998, lng: 18.7820129 },
    { lat: 48.8713444, lng: 18.7820306 },
    { lat: 48.8712958, lng: 18.7820321 },
    { lat: 48.8712271, lng: 18.7820107 },
    { lat: 48.8711536, lng: 18.7820277 },
    { lat: 48.871079, lng: 18.7820019 },
    { lat: 48.8708814, lng: 18.7819555 },
    { lat: 48.8708335, lng: 18.7819669 },
    { lat: 48.8707362, lng: 18.7819174 },
    { lat: 48.8706841, lng: 18.7819559 },
    { lat: 48.8706446, lng: 18.7819327 },
    { lat: 48.8704257, lng: 18.7818623 },
    { lat: 48.8702631, lng: 18.7818392 },
    { lat: 48.8702523, lng: 18.7818691 },
    { lat: 48.8701571, lng: 18.7818601 },
    { lat: 48.8700685, lng: 18.7818292 },
    { lat: 48.8700397, lng: 18.7818377 },
    { lat: 48.8699335, lng: 18.7818143 },
    { lat: 48.869885, lng: 18.7817889 },
    { lat: 48.8698471, lng: 18.7818203 },
    { lat: 48.8698025, lng: 18.7817852 },
    { lat: 48.8697381, lng: 18.7818041 },
    { lat: 48.8696888, lng: 18.7817487 },
    { lat: 48.8696393, lng: 18.7817726 },
    { lat: 48.8695348, lng: 18.7817762 },
    { lat: 48.8694247, lng: 18.7817626 },
    { lat: 48.8693734, lng: 18.7817307 },
    { lat: 48.869291, lng: 18.78175 },
    { lat: 48.8692693, lng: 18.7817461 },
    { lat: 48.8691797, lng: 18.7816064 },
    { lat: 48.8690856, lng: 18.7814906 },
    { lat: 48.8690063, lng: 18.781354 },
    { lat: 48.8689877, lng: 18.781301 },
    { lat: 48.8689318, lng: 18.7812655 },
    { lat: 48.8688475, lng: 18.7811409 },
    { lat: 48.8687732, lng: 18.7811033 },
    { lat: 48.8687399, lng: 18.7810478 },
    { lat: 48.8686952, lng: 18.7810109 },
    { lat: 48.8685748, lng: 18.7808423 },
    { lat: 48.8683889, lng: 18.7806938 },
    { lat: 48.8683095, lng: 18.7806661 },
    { lat: 48.8681124, lng: 18.7805168 },
    { lat: 48.8678683, lng: 18.7803584 },
    { lat: 48.8677387, lng: 18.7802941 },
    { lat: 48.8676414, lng: 18.78029 },
    { lat: 48.8676027, lng: 18.7803054 },
    { lat: 48.8674452, lng: 18.7802663 },
    { lat: 48.8674013, lng: 18.7802261 },
    { lat: 48.8673489, lng: 18.7802829 },
    { lat: 48.8672771, lng: 18.7803098 },
    { lat: 48.8672073, lng: 18.7802666 },
    { lat: 48.8670771, lng: 18.7802582 },
    { lat: 48.8670359, lng: 18.7802876 },
    { lat: 48.8669042, lng: 18.7802726 },
    { lat: 48.8667683, lng: 18.780242 },
    { lat: 48.8667479, lng: 18.7802172 },
    { lat: 48.8667081, lng: 18.7802739 },
    { lat: 48.8666777, lng: 18.7803621 },
    { lat: 48.8666048, lng: 18.7804543 },
    { lat: 48.8665873, lng: 18.7805542 },
    { lat: 48.8665545, lng: 18.7805503 },
    { lat: 48.8664879, lng: 18.7804651 },
    { lat: 48.8664735, lng: 18.7804232 },
    { lat: 48.8664419, lng: 18.7804023 },
    { lat: 48.8663411, lng: 18.7802872 },
    { lat: 48.8662783, lng: 18.7802292 },
    { lat: 48.8662211, lng: 18.7801469 },
    { lat: 48.8661435, lng: 18.7800638 },
    { lat: 48.8660988, lng: 18.7799864 },
    { lat: 48.8660504, lng: 18.7799657 },
    { lat: 48.8660221, lng: 18.7799215 },
    { lat: 48.8659894, lng: 18.7799011 },
    { lat: 48.8658362, lng: 18.7796648 },
    { lat: 48.8657206, lng: 18.779524 },
    { lat: 48.8656832, lng: 18.779459 },
    { lat: 48.8656468, lng: 18.7794497 },
    { lat: 48.8656088, lng: 18.7794156 },
    { lat: 48.8655391, lng: 18.7793108 },
    { lat: 48.8654964, lng: 18.7792952 },
    { lat: 48.8655015, lng: 18.7792602 },
    { lat: 48.8653794, lng: 18.7791273 },
    { lat: 48.8652964, lng: 18.7789847 },
    { lat: 48.8652283, lng: 18.778952 },
    { lat: 48.8651495, lng: 18.7788691 },
    { lat: 48.8651385, lng: 18.778831 },
    { lat: 48.8651094, lng: 18.778793 },
    { lat: 48.8650795, lng: 18.7787829 },
    { lat: 48.8649961, lng: 18.7786966 },
    { lat: 48.8649333, lng: 18.7786164 },
    { lat: 48.8648971, lng: 18.7786165 },
    { lat: 48.8648883, lng: 18.7785735 },
    { lat: 48.8648704, lng: 18.7785337 },
    { lat: 48.864799, lng: 18.7784427 },
    { lat: 48.8647527, lng: 18.7784214 },
    { lat: 48.8647513, lng: 18.778394 },
    { lat: 48.8646237, lng: 18.7782596 },
    { lat: 48.8646069, lng: 18.7782 },
    { lat: 48.8645531, lng: 18.7781381 },
    { lat: 48.8645282, lng: 18.7781357 },
    { lat: 48.8643857, lng: 18.7779379 },
    { lat: 48.8643245, lng: 18.7778876 },
    { lat: 48.8641385, lng: 18.7776517 },
    { lat: 48.8640847, lng: 18.777612 },
    { lat: 48.8640528, lng: 18.7775413 },
    { lat: 48.864011, lng: 18.7775177 },
    { lat: 48.8639949, lng: 18.7774734 },
    { lat: 48.8639466, lng: 18.7774305 },
    { lat: 48.8639172, lng: 18.7774273 },
    { lat: 48.8633297, lng: 18.7767185 },
    { lat: 48.8631682, lng: 18.7765857 },
    { lat: 48.8628041, lng: 18.7761341 },
    { lat: 48.8627625, lng: 18.7760518 },
    { lat: 48.8626034, lng: 18.7758968 },
    { lat: 48.8624294, lng: 18.7756968 },
    { lat: 48.8623998, lng: 18.7756469 },
    { lat: 48.862342, lng: 18.7755801 },
    { lat: 48.862323, lng: 18.7755387 },
    { lat: 48.8622897, lng: 18.7755073 },
    { lat: 48.8622235, lng: 18.7754934 },
    { lat: 48.8622242, lng: 18.7754385 },
    { lat: 48.8622161, lng: 18.7754147 },
    { lat: 48.8622007, lng: 18.7754413 },
    { lat: 48.8621709, lng: 18.7754123 },
    { lat: 48.8621656, lng: 18.7753743 },
    { lat: 48.8620435, lng: 18.7751962 },
    { lat: 48.8620021, lng: 18.7751819 },
    { lat: 48.8619942, lng: 18.7750765 },
    { lat: 48.8619741, lng: 18.7750348 },
    { lat: 48.8619254, lng: 18.7750081 },
    { lat: 48.861911, lng: 18.7749715 },
    { lat: 48.86193, lng: 18.7749227 },
    { lat: 48.8618362, lng: 18.7747481 },
    { lat: 48.8618063, lng: 18.7747133 },
    { lat: 48.861791, lng: 18.7746375 },
    { lat: 48.8617431, lng: 18.7745394 },
    { lat: 48.8617364, lng: 18.7745018 },
    { lat: 48.8617095, lng: 18.7744614 },
    { lat: 48.8616924, lng: 18.7743703 },
    { lat: 48.861657, lng: 18.7743173 },
    { lat: 48.8616052, lng: 18.7741484 },
    { lat: 48.8615725, lng: 18.7741075 },
    { lat: 48.8615829, lng: 18.7739809 },
    { lat: 48.8615598, lng: 18.773949 },
    { lat: 48.8615199, lng: 18.7739824 },
    { lat: 48.8614851, lng: 18.7739221 },
    { lat: 48.8615029, lng: 18.7738967 },
    { lat: 48.861483, lng: 18.7738182 },
    { lat: 48.8614459, lng: 18.7737954 },
    { lat: 48.8614135, lng: 18.7738462 },
    { lat: 48.8614199, lng: 18.7737548 },
    { lat: 48.8613952, lng: 18.7737116 },
    { lat: 48.8613635, lng: 18.7737115 },
    { lat: 48.861328, lng: 18.7736559 },
    { lat: 48.8613129, lng: 18.7736022 },
    { lat: 48.8612465, lng: 18.7734999 },
    { lat: 48.8612227, lng: 18.7734967 },
    { lat: 48.8612161, lng: 18.773414 },
    { lat: 48.8611764, lng: 18.7733887 },
    { lat: 48.8611732, lng: 18.7733501 },
    { lat: 48.8611265, lng: 18.7732751 },
    { lat: 48.8610916, lng: 18.7732757 },
    { lat: 48.8610687, lng: 18.7732484 },
    { lat: 48.8610725, lng: 18.7731899 },
    { lat: 48.8610093, lng: 18.7729085 },
    { lat: 48.8609362, lng: 18.7727629 },
    { lat: 48.8608821, lng: 18.7725287 },
    { lat: 48.8608838, lng: 18.7724519 },
    { lat: 48.8608451, lng: 18.7724043 },
    { lat: 48.8608294, lng: 18.7723388 },
    { lat: 48.8607972, lng: 18.7722829 },
    { lat: 48.8607537, lng: 18.7720983 },
    { lat: 48.8607632, lng: 18.771999 },
    { lat: 48.8607286, lng: 18.7718119 },
    { lat: 48.8607071, lng: 18.7717486 },
    { lat: 48.8607171, lng: 18.7716597 },
    { lat: 48.8606913, lng: 18.7714472 },
    { lat: 48.8607055, lng: 18.7713524 },
    { lat: 48.8606889, lng: 18.7712928 },
    { lat: 48.8607429, lng: 18.7710968 },
    { lat: 48.8609528, lng: 18.7702367 },
    { lat: 48.8610502, lng: 18.7699027 },
    { lat: 48.8613651, lng: 18.7687875 },
    { lat: 48.8614369, lng: 18.7686062 },
    { lat: 48.8614882, lng: 18.7685126 },
    { lat: 48.8616584, lng: 18.7682522 },
    { lat: 48.8618972, lng: 18.7679025 },
    { lat: 48.8620779, lng: 18.7674775 },
    { lat: 48.8619729, lng: 18.7666145 },
    { lat: 48.861918, lng: 18.7659098 },
    { lat: 48.8618528, lng: 18.7649667 },
    { lat: 48.8617501, lng: 18.7635444 },
    { lat: 48.8617588, lng: 18.763346 },
    { lat: 48.8619455, lng: 18.7620255 },
    { lat: 48.8619542, lng: 18.7615646 },
    { lat: 48.8615847, lng: 18.7608452 },
    { lat: 48.8613057, lng: 18.760554 },
    { lat: 48.8612001, lng: 18.7604567 },
    { lat: 48.8607413, lng: 18.7600532 },
    { lat: 48.8606891, lng: 18.7599353 },
    { lat: 48.8606657, lng: 18.7600083 },
    { lat: 48.8606342, lng: 18.7599891 },
    { lat: 48.8606575, lng: 18.7598932 },
    { lat: 48.8606322, lng: 18.7598433 },
    { lat: 48.8605817, lng: 18.7599106 },
    { lat: 48.8604886, lng: 18.7596822 },
    { lat: 48.8604019, lng: 18.759601 },
    { lat: 48.8603389, lng: 18.7596258 },
    { lat: 48.860271, lng: 18.7596189 },
    { lat: 48.8601447, lng: 18.7594912 },
    { lat: 48.860071, lng: 18.7594593 },
    { lat: 48.8600922, lng: 18.7594145 },
    { lat: 48.8601076, lng: 18.7593195 },
    { lat: 48.8600961, lng: 18.7592937 },
    { lat: 48.8600875, lng: 18.7592621 },
    { lat: 48.8600398, lng: 18.7592521 },
    { lat: 48.8599806, lng: 18.759309 },
    { lat: 48.859956, lng: 18.7592683 },
    { lat: 48.8598724, lng: 18.759071 },
    { lat: 48.8598191, lng: 18.7590015 },
    { lat: 48.8597681, lng: 18.7590198 },
    { lat: 48.8597066, lng: 18.759005 },
    { lat: 48.8596183, lng: 18.7588939 },
    { lat: 48.8595697, lng: 18.7588912 },
    { lat: 48.8594934, lng: 18.7588112 },
    { lat: 48.8594026, lng: 18.7588232 },
    { lat: 48.8593657, lng: 18.7587868 },
    { lat: 48.8593533, lng: 18.7587213 },
    { lat: 48.8592817, lng: 18.7586891 },
    { lat: 48.859221, lng: 18.7585858 },
    { lat: 48.8592194, lng: 18.7584932 },
    { lat: 48.8591471, lng: 18.7582946 },
    { lat: 48.8590848, lng: 18.7582473 },
    { lat: 48.859087, lng: 18.7581589 },
    { lat: 48.8590766, lng: 18.7581136 },
    { lat: 48.8590543, lng: 18.7580748 },
    { lat: 48.8590465, lng: 18.7580524 },
    { lat: 48.8592175, lng: 18.758024 },
    { lat: 48.8591241, lng: 18.7578576 },
    { lat: 48.8589075, lng: 18.7576606 },
    { lat: 48.8587634, lng: 18.7575572 },
    { lat: 48.8586368, lng: 18.7575084 },
    { lat: 48.8585151, lng: 18.7575145 },
    { lat: 48.8584757, lng: 18.7575321 },
    { lat: 48.8582986, lng: 18.7577064 },
    { lat: 48.8576399, lng: 18.7585833 },
    { lat: 48.8574658, lng: 18.7588547 },
    { lat: 48.8573635, lng: 18.7589669 },
    { lat: 48.8571865, lng: 18.7590788 },
    { lat: 48.8567432, lng: 18.7592046 },
    { lat: 48.8565424, lng: 18.7592027 },
    { lat: 48.8564303, lng: 18.7591055 },
    { lat: 48.8563794, lng: 18.7590189 },
    { lat: 48.8563019, lng: 18.7589187 },
    { lat: 48.8562194, lng: 18.7588695 },
    { lat: 48.8561286, lng: 18.7589231 },
    { lat: 48.8560791, lng: 18.7588614 },
    { lat: 48.8560033, lng: 18.7588587 },
    { lat: 48.8559654, lng: 18.7588024 },
    { lat: 48.8558852, lng: 18.7587555 },
    { lat: 48.855751, lng: 18.7588418 },
    { lat: 48.8555441, lng: 18.7587228 },
    { lat: 48.8554388, lng: 18.7587595 },
    { lat: 48.8553513, lng: 18.7587453 },
    { lat: 48.8552294, lng: 18.7587869 },
    { lat: 48.8551615, lng: 18.7587406 },
    { lat: 48.8550431, lng: 18.7586983 },
    { lat: 48.8547166, lng: 18.7587498 },
    { lat: 48.8545874, lng: 18.7587611 },
    { lat: 48.854391, lng: 18.7587998 },
    { lat: 48.8541743, lng: 18.7588592 },
    { lat: 48.8541038, lng: 18.7589104 },
    { lat: 48.8540137, lng: 18.759 },
    { lat: 48.8538654, lng: 18.7590963 },
    { lat: 48.8537615, lng: 18.7591786 },
    { lat: 48.8536916, lng: 18.7592028 },
    { lat: 48.8536625, lng: 18.7592923 },
    { lat: 48.8534221, lng: 18.7594394 },
    { lat: 48.8532046, lng: 18.7595276 },
    { lat: 48.8529558, lng: 18.7594917 },
    { lat: 48.852875, lng: 18.7594158 },
    { lat: 48.8527026, lng: 18.7592242 },
    { lat: 48.852693, lng: 18.7591259 },
    { lat: 48.8526008, lng: 18.7589583 },
    { lat: 48.8525269, lng: 18.7588436 },
    { lat: 48.8521866, lng: 18.7583966 },
    { lat: 48.8521886, lng: 18.7583455 },
    { lat: 48.852114, lng: 18.7581941 },
    { lat: 48.8520626, lng: 18.7580287 },
    { lat: 48.8520039, lng: 18.7580097 },
    { lat: 48.8519153, lng: 18.7577794 },
    { lat: 48.8517579, lng: 18.7575942 },
    { lat: 48.8517231, lng: 18.757428 },
    { lat: 48.8517466, lng: 18.7573618 },
    { lat: 48.8516771, lng: 18.7572799 },
    { lat: 48.8516309, lng: 18.7571741 },
    { lat: 48.8514641, lng: 18.7569632 },
    { lat: 48.8514304, lng: 18.7568803 },
    { lat: 48.8513377, lng: 18.7568109 },
    { lat: 48.8512759, lng: 18.7567492 },
    { lat: 48.8512771, lng: 18.7567097 },
    { lat: 48.8513288, lng: 18.7566186 },
    { lat: 48.8512338, lng: 18.7564829 },
    { lat: 48.8511778, lng: 18.7564925 },
    { lat: 48.8511126, lng: 18.7563897 },
    { lat: 48.850949, lng: 18.7561942 },
    { lat: 48.8508798, lng: 18.7562266 },
    { lat: 48.8508478, lng: 18.7561352 },
    { lat: 48.8508518, lng: 18.7561039 },
    { lat: 48.8508158, lng: 18.7561068 },
    { lat: 48.8507817, lng: 18.7559706 },
    { lat: 48.8507873, lng: 18.7559273 },
    { lat: 48.8506382, lng: 18.7557931 },
    { lat: 48.8505987, lng: 18.7557742 },
    { lat: 48.850561, lng: 18.7558267 },
    { lat: 48.8505662, lng: 18.755776 },
    { lat: 48.8505525, lng: 18.75575 },
    { lat: 48.8505097, lng: 18.7557756 },
    { lat: 48.8505064, lng: 18.755732 },
    { lat: 48.8504611, lng: 18.7556877 },
    { lat: 48.8504542, lng: 18.7557043 },
    { lat: 48.8504145, lng: 18.7556589 },
    { lat: 48.8503672, lng: 18.755637 },
    { lat: 48.8502021, lng: 18.7553508 },
    { lat: 48.8502253, lng: 18.7551657 },
    { lat: 48.8500755, lng: 18.7547659 },
    { lat: 48.8499896, lng: 18.7547824 },
    { lat: 48.8499096, lng: 18.7545904 },
    { lat: 48.8497452, lng: 18.7544892 },
    { lat: 48.849691, lng: 18.7544195 },
    { lat: 48.8496992, lng: 18.7540848 },
    { lat: 48.8497243, lng: 18.7539829 },
    { lat: 48.8497068, lng: 18.7537129 },
    { lat: 48.8497146, lng: 18.7535827 },
    { lat: 48.8497579, lng: 18.7534389 },
    { lat: 48.8499682, lng: 18.7529344 },
    { lat: 48.8499641, lng: 18.7528168 },
    { lat: 48.8499266, lng: 18.7525921 },
    { lat: 48.8498136, lng: 18.7521378 },
    { lat: 48.8498024, lng: 18.7519923 },
    { lat: 48.8498314, lng: 18.7517933 },
    { lat: 48.8498749, lng: 18.7516097 },
    { lat: 48.8499447, lng: 18.7513496 },
    { lat: 48.8500619, lng: 18.7509852 },
    { lat: 48.8501774, lng: 18.750436 },
    { lat: 48.850185, lng: 18.7504104 },
    { lat: 48.8502254, lng: 18.749892 },
    { lat: 48.8503905, lng: 18.7490642 },
    { lat: 48.8503865, lng: 18.7487944 },
    { lat: 48.8503759, lng: 18.7485257 },
    { lat: 48.8503815, lng: 18.7483528 },
    { lat: 48.8504721, lng: 18.7479329 },
    { lat: 48.8505003, lng: 18.7477813 },
    { lat: 48.8505329, lng: 18.7475027 },
    { lat: 48.8505326, lng: 18.7469627 },
    { lat: 48.8505721, lng: 18.7466837 },
    { lat: 48.8505714, lng: 18.7464327 },
    { lat: 48.8505395, lng: 18.7461723 },
    { lat: 48.8505383, lng: 18.7460411 },
    { lat: 48.8505937, lng: 18.7455263 },
    { lat: 48.8505812, lng: 18.745357 },
    { lat: 48.850522, lng: 18.7452381 },
    { lat: 48.850483, lng: 18.7451149 },
    { lat: 48.8503033, lng: 18.7448312 },
    { lat: 48.8502766, lng: 18.7447317 },
    { lat: 48.8502838, lng: 18.7442667 },
    { lat: 48.850347, lng: 18.7441828 },
    { lat: 48.8503695, lng: 18.7441626 },
    { lat: 48.8503891, lng: 18.7435683 },
    { lat: 48.8503598, lng: 18.7431375 },
    { lat: 48.8502986, lng: 18.7428996 },
    { lat: 48.8502005, lng: 18.7427288 },
    { lat: 48.8500727, lng: 18.7424417 },
    { lat: 48.8500218, lng: 18.7423103 },
    { lat: 48.8499631, lng: 18.7419905 },
    { lat: 48.8499051, lng: 18.741764 },
    { lat: 48.8498033, lng: 18.7414591 },
    { lat: 48.8496919, lng: 18.7412065 },
    { lat: 48.8496214, lng: 18.7409565 },
    { lat: 48.8495827, lng: 18.7407377 },
    { lat: 48.8495682, lng: 18.7403993 },
    { lat: 48.8495758, lng: 18.7401578 },
    { lat: 48.8496034, lng: 18.7397813 },
    { lat: 48.8496076, lng: 18.7396036 },
    { lat: 48.8495947, lng: 18.7394053 },
    { lat: 48.8495107, lng: 18.7391333 },
    { lat: 48.8494235, lng: 18.7389573 },
    { lat: 48.8492472, lng: 18.738671 },
    { lat: 48.8491371, lng: 18.7385272 },
    { lat: 48.8489491, lng: 18.7381929 },
    { lat: 48.8488713, lng: 18.7380662 },
    { lat: 48.8487708, lng: 18.7378055 },
    { lat: 48.8486802, lng: 18.7374985 },
    { lat: 48.8486127, lng: 18.7373513 },
    { lat: 48.8484386, lng: 18.7370884 },
    { lat: 48.8483, lng: 18.7369594 },
    { lat: 48.8480977, lng: 18.7368027 },
    { lat: 48.8479288, lng: 18.7366158 },
    { lat: 48.8478559, lng: 18.736514 },
    { lat: 48.8477961, lng: 18.7361922 },
    { lat: 48.8477561, lng: 18.7360112 },
    { lat: 48.8477333, lng: 18.7357314 },
    { lat: 48.8477601, lng: 18.735422 },
    { lat: 48.8477698, lng: 18.7351366 },
    { lat: 48.8476538, lng: 18.7347965 },
    { lat: 48.8474256, lng: 18.7343393 },
    { lat: 48.8472168, lng: 18.7338665 },
    { lat: 48.8470231, lng: 18.7333578 },
    { lat: 48.8469636, lng: 18.733278 },
    { lat: 48.8468224, lng: 18.7331218 },
    { lat: 48.8466643, lng: 18.7330142 },
    { lat: 48.8465262, lng: 18.7330223 },
    { lat: 48.8463146, lng: 18.7330748 },
    { lat: 48.8461087, lng: 18.7331713 },
    { lat: 48.8460614, lng: 18.7332787 },
    { lat: 48.8459594, lng: 18.733266 },
    { lat: 48.8458693, lng: 18.7335243 },
    { lat: 48.8457686, lng: 18.7335429 },
    { lat: 48.8456287, lng: 18.7337758 },
    { lat: 48.845512, lng: 18.7338078 },
    { lat: 48.8454572, lng: 18.7337314 },
    { lat: 48.8452423, lng: 18.7338071 },
    { lat: 48.8450539, lng: 18.7339753 },
    { lat: 48.8449032, lng: 18.7340884 },
    { lat: 48.844778, lng: 18.7343051 },
    { lat: 48.8446923, lng: 18.7344777 },
    { lat: 48.8446324, lng: 18.7345147 },
    { lat: 48.8445501, lng: 18.7346442 },
    { lat: 48.8444106, lng: 18.734737 },
    { lat: 48.8442538, lng: 18.7347803 },
    { lat: 48.8441303, lng: 18.73483 },
    { lat: 48.8440157, lng: 18.7349885 },
    { lat: 48.8438538, lng: 18.735171 },
    { lat: 48.8436785, lng: 18.7353522 },
    { lat: 48.8435603, lng: 18.7355298 },
    { lat: 48.8432795, lng: 18.7355924 },
    { lat: 48.8432387, lng: 18.7355429 },
    { lat: 48.8430673, lng: 18.7350989 },
    { lat: 48.8428877, lng: 18.7349198 },
    { lat: 48.8426195, lng: 18.734862 },
    { lat: 48.842432, lng: 18.7348765 },
    { lat: 48.8422895, lng: 18.7349309 },
    { lat: 48.842158, lng: 18.7350446 },
    { lat: 48.8420299, lng: 18.7350533 },
    { lat: 48.8418617, lng: 18.7352874 },
    { lat: 48.841693, lng: 18.7352537 },
    { lat: 48.8414914, lng: 18.7350228 },
    { lat: 48.8414023, lng: 18.7346605 },
    { lat: 48.8413468, lng: 18.7344632 },
    { lat: 48.8412484, lng: 18.7341794 },
    { lat: 48.8411949, lng: 18.7340842 },
    { lat: 48.841073, lng: 18.7339951 },
    { lat: 48.8408256, lng: 18.7337762 },
    { lat: 48.8406573, lng: 18.7337035 },
    { lat: 48.8404466, lng: 18.733686 },
    { lat: 48.8403193, lng: 18.7336875 },
    { lat: 48.8402519, lng: 18.7335653 },
    { lat: 48.8400128, lng: 18.733567 },
    { lat: 48.8399888, lng: 18.7337074 },
    { lat: 48.8399288, lng: 18.7337705 },
    { lat: 48.839844, lng: 18.7340453 },
    { lat: 48.839725, lng: 18.7343125 },
    { lat: 48.8395871, lng: 18.73452 },
    { lat: 48.8394994, lng: 18.7345238 },
    { lat: 48.8394018, lng: 18.7342324 },
    { lat: 48.839281, lng: 18.7340784 },
    { lat: 48.8391178, lng: 18.7339148 },
    { lat: 48.8389788, lng: 18.7338697 },
    { lat: 48.8384012, lng: 18.7336718 },
    { lat: 48.8381135, lng: 18.7336697 },
    { lat: 48.8378634, lng: 18.733609 },
    { lat: 48.8377549, lng: 18.733412 },
    { lat: 48.8377092, lng: 18.7329536 },
    { lat: 48.8376343, lng: 18.7327507 },
    { lat: 48.8375111, lng: 18.7325974 },
    { lat: 48.8373751, lng: 18.7324964 },
    { lat: 48.8371951, lng: 18.732525 },
    { lat: 48.8371682, lng: 18.7325522 },
    { lat: 48.836798, lng: 18.7328687 },
    { lat: 48.8366273, lng: 18.7331135 },
    { lat: 48.836474, lng: 18.7331592 },
    { lat: 48.8361935, lng: 18.7331638 },
    { lat: 48.8358753, lng: 18.7329682 },
    { lat: 48.8358176, lng: 18.7328155 },
    { lat: 48.8353721, lng: 18.7324291 },
    { lat: 48.835077, lng: 18.7322021 },
    { lat: 48.8347894, lng: 18.731793 },
    { lat: 48.8343798, lng: 18.7314259 },
    { lat: 48.8340967, lng: 18.7311484 },
    { lat: 48.8337853, lng: 18.7310192 },
    { lat: 48.8336594, lng: 18.7310244 },
    { lat: 48.8333656, lng: 18.7309909 },
    { lat: 48.8331968, lng: 18.7310228 },
    { lat: 48.8328882, lng: 18.7304319 },
    { lat: 48.8328266, lng: 18.7303069 },
    { lat: 48.8325064, lng: 18.7298632 },
    { lat: 48.8324016, lng: 18.7297141 },
    { lat: 48.8323396, lng: 18.7295859 },
    { lat: 48.832006, lng: 18.729141 },
    { lat: 48.831761, lng: 18.731283 },
    { lat: 48.831718, lng: 18.732472 },
    { lat: 48.831442, lng: 18.733185 },
    { lat: 48.83139, lng: 18.733626 },
    { lat: 48.831374, lng: 18.733767 },
    { lat: 48.831388, lng: 18.733991 },
    { lat: 48.831396, lng: 18.73412 },
    { lat: 48.831408, lng: 18.734334 },
    { lat: 48.8314435, lng: 18.7346855 },
    { lat: 48.8314945, lng: 18.7350577 },
    { lat: 48.8315303, lng: 18.7354097 },
    { lat: 48.831544, lng: 18.735831 },
    { lat: 48.831538, lng: 18.73602 },
    { lat: 48.831546, lng: 18.736081 },
    { lat: 48.831616, lng: 18.736561 },
    { lat: 48.831649, lng: 18.736781 },
    { lat: 48.831654, lng: 18.736825 },
    { lat: 48.831713, lng: 18.737031 },
    { lat: 48.831774, lng: 18.737248 },
    { lat: 48.831824, lng: 18.737426 },
    { lat: 48.831851, lng: 18.737526 },
    { lat: 48.831882, lng: 18.737695 },
    { lat: 48.831965, lng: 18.738168 },
    { lat: 48.832012, lng: 18.73843 },
    { lat: 48.832071, lng: 18.738654 },
    { lat: 48.832082, lng: 18.738784 },
    { lat: 48.832124, lng: 18.73928 },
    { lat: 48.832034, lng: 18.739429 },
    { lat: 48.831828, lng: 18.739772 },
    { lat: 48.831328, lng: 18.739965 },
    { lat: 48.83125, lng: 18.740027 },
    { lat: 48.831196, lng: 18.740069 },
    { lat: 48.831189, lng: 18.740075 },
    { lat: 48.831161, lng: 18.740097 },
    { lat: 48.831142, lng: 18.740247 },
    { lat: 48.831076, lng: 18.740757 },
    { lat: 48.831041, lng: 18.741019 },
    { lat: 48.830922, lng: 18.741187 },
    { lat: 48.830823, lng: 18.74133 },
    { lat: 48.830624, lng: 18.741689 },
    { lat: 48.830543, lng: 18.741836 },
    { lat: 48.829974, lng: 18.741932 },
    { lat: 48.829569, lng: 18.742347 },
    { lat: 48.82937, lng: 18.742668 },
    { lat: 48.829285, lng: 18.742805 },
    { lat: 48.829183, lng: 18.742971 },
    { lat: 48.829181, lng: 18.743798 },
    { lat: 48.829059, lng: 18.744273 },
    { lat: 48.828979, lng: 18.745108 },
    { lat: 48.8289433, lng: 18.74515 },
    { lat: 48.82873, lng: 18.745427 },
    { lat: 48.8284752, lng: 18.745753 },
    { lat: 48.828433, lng: 18.745807 },
    { lat: 48.828363, lng: 18.745986 },
    { lat: 48.828349, lng: 18.746023 },
    { lat: 48.828336, lng: 18.746058 },
    { lat: 48.828298, lng: 18.746154 },
    { lat: 48.828216, lng: 18.746365 },
    { lat: 48.828147, lng: 18.746545 },
    { lat: 48.8280777, lng: 18.7471947 },
    { lat: 48.828051, lng: 18.747398 },
    { lat: 48.8280995, lng: 18.7475333 },
    { lat: 48.828203, lng: 18.747928 },
    { lat: 48.828337, lng: 18.7484 },
    { lat: 48.828453, lng: 18.748806 },
    { lat: 48.828058, lng: 18.749847 },
    { lat: 48.828032, lng: 18.749914 },
    { lat: 48.827977, lng: 18.75006 },
    { lat: 48.827915, lng: 18.750223 },
    { lat: 48.827863, lng: 18.750362 },
    { lat: 48.827687, lng: 18.750636 },
    { lat: 48.827412, lng: 18.750939 },
    { lat: 48.827351, lng: 18.751065 },
    { lat: 48.827103, lng: 18.751571 },
    { lat: 48.82689, lng: 18.752429 },
    { lat: 48.826805, lng: 18.752552 },
    { lat: 48.826645, lng: 18.752783 },
    { lat: 48.826575, lng: 18.75288 },
    { lat: 48.826417, lng: 18.753103 },
    { lat: 48.826035, lng: 18.753191 },
    { lat: 48.826026, lng: 18.753205 },
    { lat: 48.825783, lng: 18.75359 },
    { lat: 48.825585, lng: 18.753632 },
    { lat: 48.825196, lng: 18.753715 },
    { lat: 48.824969, lng: 18.753764 },
    { lat: 48.824942, lng: 18.753756 },
    { lat: 48.824442, lng: 18.753607 },
    { lat: 48.823908, lng: 18.753447 },
    { lat: 48.823862, lng: 18.753416 },
    { lat: 48.82304, lng: 18.752791 },
    { lat: 48.822747, lng: 18.752604 },
    { lat: 48.821985, lng: 18.752116 },
    { lat: 48.821438, lng: 18.751834 },
    { lat: 48.821363, lng: 18.751795 },
    { lat: 48.82135, lng: 18.751771 },
    { lat: 48.821182, lng: 18.751453 },
    { lat: 48.821114, lng: 18.751358 },
    { lat: 48.82066, lng: 18.750719 },
    { lat: 48.82022, lng: 18.75054 },
    { lat: 48.820173, lng: 18.750522 },
    { lat: 48.8199224, lng: 18.7511085 },
    { lat: 48.819575, lng: 18.751473 },
    { lat: 48.819264, lng: 18.752156 },
    { lat: 48.818868, lng: 18.752411 },
    { lat: 48.818796, lng: 18.752457 },
    { lat: 48.818129, lng: 18.753317 },
  ],
  HornáŠtubňa: [
    { lat: 48.806092, lng: 18.991116 },
    { lat: 48.8072541, lng: 18.9863364 },
    { lat: 48.8069515, lng: 18.9847204 },
    { lat: 48.806894, lng: 18.9843164 },
    { lat: 48.807015, lng: 18.9836114 },
    { lat: 48.8073785, lng: 18.9821943 },
    { lat: 48.8075216, lng: 18.9813668 },
    { lat: 48.8076404, lng: 18.9809331 },
    { lat: 48.8077039, lng: 18.980361 },
    { lat: 48.8079433, lng: 18.9792748 },
    { lat: 48.8079281, lng: 18.9788874 },
    { lat: 48.8079922, lng: 18.97844 },
    { lat: 48.8081555, lng: 18.977965 },
    { lat: 48.8086485, lng: 18.9769041 },
    { lat: 48.8090676, lng: 18.9765225 },
    { lat: 48.8093994, lng: 18.9763033 },
    { lat: 48.8096398, lng: 18.976265 },
    { lat: 48.8099854, lng: 18.9758765 },
    { lat: 48.8101041, lng: 18.9750815 },
    { lat: 48.8103537, lng: 18.9738846 },
    { lat: 48.8104423, lng: 18.9737216 },
    { lat: 48.8106172, lng: 18.9732806 },
    { lat: 48.8108894, lng: 18.9729772 },
    { lat: 48.8114668, lng: 18.9722916 },
    { lat: 48.8137776, lng: 18.971145 },
    { lat: 48.8140053, lng: 18.9708552 },
    { lat: 48.814252, lng: 18.970384 },
    { lat: 48.8137338, lng: 18.9698146 },
    { lat: 48.814121, lng: 18.9694216 },
    { lat: 48.8142576, lng: 18.9689381 },
    { lat: 48.8146855, lng: 18.968795 },
    { lat: 48.8152482, lng: 18.9687515 },
    { lat: 48.8152549, lng: 18.9687718 },
    { lat: 48.8157132, lng: 18.9681844 },
    { lat: 48.8161317, lng: 18.9674084 },
    { lat: 48.81678, lng: 18.9662752 },
    { lat: 48.8167989, lng: 18.9662251 },
    { lat: 48.8168266, lng: 18.9634832 },
    { lat: 48.8169692, lng: 18.9632713 },
    { lat: 48.81846, lng: 18.9624989 },
    { lat: 48.8189754, lng: 18.961683 },
    { lat: 48.8194332, lng: 18.961615 },
    { lat: 48.8197488, lng: 18.9619042 },
    { lat: 48.8200634, lng: 18.9612165 },
    { lat: 48.8201313, lng: 18.9604411 },
    { lat: 48.8204252, lng: 18.9602133 },
    { lat: 48.8209074, lng: 18.9600094 },
    { lat: 48.821578, lng: 18.9599357 },
    { lat: 48.8219843, lng: 18.9599682 },
    { lat: 48.8223885, lng: 18.9601351 },
    { lat: 48.8227141, lng: 18.9601254 },
    { lat: 48.8229826, lng: 18.9590848 },
    { lat: 48.8231455, lng: 18.9583438 },
    { lat: 48.8231573, lng: 18.9575334 },
    { lat: 48.8230357, lng: 18.956647 },
    { lat: 48.8232569, lng: 18.9561421 },
    { lat: 48.8236789, lng: 18.9551287 },
    { lat: 48.8236333, lng: 18.9541199 },
    { lat: 48.8240476, lng: 18.9541958 },
    { lat: 48.8242652, lng: 18.9533743 },
    { lat: 48.8243949, lng: 18.9529549 },
    { lat: 48.8247092, lng: 18.9523472 },
    { lat: 48.8240756, lng: 18.9494215 },
    { lat: 48.8242303, lng: 18.9489837 },
    { lat: 48.8242925, lng: 18.94859 },
    { lat: 48.8242939, lng: 18.9484603 },
    { lat: 48.8243218, lng: 18.9482792 },
    { lat: 48.8244403, lng: 18.9481277 },
    { lat: 48.8250077, lng: 18.946691 },
    { lat: 48.8251715, lng: 18.9458568 },
    { lat: 48.8252015, lng: 18.9454242 },
    { lat: 48.8253492, lng: 18.944915 },
    { lat: 48.8253744, lng: 18.944546 },
    { lat: 48.8255881, lng: 18.9435839 },
    { lat: 48.8257853, lng: 18.943159 },
    { lat: 48.826, lng: 18.9429264 },
    { lat: 48.8262332, lng: 18.9424168 },
    { lat: 48.8267174, lng: 18.941694 },
    { lat: 48.8267411, lng: 18.941474 },
    { lat: 48.8270053, lng: 18.9409511 },
    { lat: 48.8274065, lng: 18.9406584 },
    { lat: 48.8279531, lng: 18.9398363 },
    { lat: 48.8282205, lng: 18.9393052 },
    { lat: 48.8285799, lng: 18.9389024 },
    { lat: 48.8291358, lng: 18.9381737 },
    { lat: 48.8296138, lng: 18.9377295 },
    { lat: 48.8299741, lng: 18.9375443 },
    { lat: 48.8301767, lng: 18.9373621 },
    { lat: 48.8305105, lng: 18.9368979 },
    { lat: 48.8307783, lng: 18.9365756 },
    { lat: 48.831204, lng: 18.9363469 },
    { lat: 48.8314121, lng: 18.9359794 },
    { lat: 48.8318611, lng: 18.9357455 },
    { lat: 48.8321374, lng: 18.9352147 },
    { lat: 48.8326107, lng: 18.9350126 },
    { lat: 48.8326335, lng: 18.9347726 },
    { lat: 48.8323904, lng: 18.9344253 },
    { lat: 48.8323286, lng: 18.9340335 },
    { lat: 48.8324687, lng: 18.9337286 },
    { lat: 48.832935, lng: 18.9327028 },
    { lat: 48.8329634, lng: 18.9318649 },
    { lat: 48.833016, lng: 18.9317059 },
    { lat: 48.8338502, lng: 18.9292906 },
    { lat: 48.8336611, lng: 18.9289005 },
    { lat: 48.8333836, lng: 18.9285887 },
    { lat: 48.8327828, lng: 18.9283251 },
    { lat: 48.8317506, lng: 18.9282051 },
    { lat: 48.8302007, lng: 18.9278805 },
    { lat: 48.8296054, lng: 18.9275959 },
    { lat: 48.8292063, lng: 18.9267799 },
    { lat: 48.8291799, lng: 18.9251579 },
    { lat: 48.8300505, lng: 18.9243889 },
    { lat: 48.8300874, lng: 18.9236076 },
    { lat: 48.8300069, lng: 18.9230259 },
    { lat: 48.8299086, lng: 18.9227568 },
    { lat: 48.829679, lng: 18.9222348 },
    { lat: 48.8296215, lng: 18.9217709 },
    { lat: 48.8295643, lng: 18.9211141 },
    { lat: 48.8294584, lng: 18.9206556 },
    { lat: 48.8293904, lng: 18.92012 },
    { lat: 48.8293363, lng: 18.9200501 },
    { lat: 48.8293615, lng: 18.9200178 },
    { lat: 48.8293599, lng: 18.9199625 },
    { lat: 48.8294741, lng: 18.9196596 },
    { lat: 48.8296668, lng: 18.9194581 },
    { lat: 48.829715, lng: 18.9193422 },
    { lat: 48.8299099, lng: 18.9192077 },
    { lat: 48.8299784, lng: 18.9189588 },
    { lat: 48.8300989, lng: 18.9188903 },
    { lat: 48.8301159, lng: 18.9188697 },
    { lat: 48.8301963, lng: 18.9186556 },
    { lat: 48.830231, lng: 18.9185849 },
    { lat: 48.8302432, lng: 18.918537 },
    { lat: 48.8303477, lng: 18.9183544 },
    { lat: 48.8304488, lng: 18.9182137 },
    { lat: 48.8305601, lng: 18.9182293 },
    { lat: 48.8306669, lng: 18.9181595 },
    { lat: 48.8307817, lng: 18.9180197 },
    { lat: 48.8308887, lng: 18.91782 },
    { lat: 48.8310788, lng: 18.9177444 },
    { lat: 48.831293, lng: 18.9175267 },
    { lat: 48.8313545, lng: 18.9174021 },
    { lat: 48.8314375, lng: 18.917353 },
    { lat: 48.8313982, lng: 18.9172864 },
    { lat: 48.8314082, lng: 18.9171968 },
    { lat: 48.8315141, lng: 18.9170223 },
    { lat: 48.8316427, lng: 18.916888 },
    { lat: 48.8316762, lng: 18.916819 },
    { lat: 48.8317593, lng: 18.9168085 },
    { lat: 48.8318648, lng: 18.9166673 },
    { lat: 48.8318834, lng: 18.9165925 },
    { lat: 48.831847, lng: 18.9163652 },
    { lat: 48.8319787, lng: 18.9162703 },
    { lat: 48.8321953, lng: 18.9161397 },
    { lat: 48.8323748, lng: 18.9161487 },
    { lat: 48.8326215, lng: 18.9160514 },
    { lat: 48.8327403, lng: 18.915953 },
    { lat: 48.8327929, lng: 18.9159418 },
    { lat: 48.832952, lng: 18.9158376 },
    { lat: 48.8330072, lng: 18.9158125 },
    { lat: 48.8333135, lng: 18.9157418 },
    { lat: 48.8335638, lng: 18.9155858 },
    { lat: 48.8336298, lng: 18.9155326 },
    { lat: 48.8338616, lng: 18.9154317 },
    { lat: 48.8340076, lng: 18.9153097 },
    { lat: 48.834059, lng: 18.9153036 },
    { lat: 48.8341904, lng: 18.915227 },
    { lat: 48.8342834, lng: 18.9151281 },
    { lat: 48.834326, lng: 18.9151002 },
    { lat: 48.8345476, lng: 18.9148469 },
    { lat: 48.83459, lng: 18.9147929 },
    { lat: 48.8347091, lng: 18.9146089 },
    { lat: 48.8347487, lng: 18.9145695 },
    { lat: 48.8348405, lng: 18.9144246 },
    { lat: 48.8350239, lng: 18.9141715 },
    { lat: 48.8352074, lng: 18.91417 },
    { lat: 48.835344, lng: 18.9140609 },
    { lat: 48.8355027, lng: 18.9139238 },
    { lat: 48.8355381, lng: 18.9138456 },
    { lat: 48.8355887, lng: 18.913796 },
    { lat: 48.8357095, lng: 18.9137327 },
    { lat: 48.8357959, lng: 18.9136614 },
    { lat: 48.8358485, lng: 18.9135622 },
    { lat: 48.8358839, lng: 18.9134417 },
    { lat: 48.8358924, lng: 18.9133391 },
    { lat: 48.8359672, lng: 18.9122243 },
    { lat: 48.8359917, lng: 18.9120203 },
    { lat: 48.8360966, lng: 18.9115348 },
    { lat: 48.8361388, lng: 18.9113899 },
    { lat: 48.8363024, lng: 18.9109072 },
    { lat: 48.8363722, lng: 18.910753 },
    { lat: 48.8364295, lng: 18.9105752 },
    { lat: 48.8368089, lng: 18.9097482 },
    { lat: 48.8369223, lng: 18.9094758 },
    { lat: 48.837031, lng: 18.9091759 },
    { lat: 48.8370954, lng: 18.9088459 },
    { lat: 48.8371182, lng: 18.9086728 },
    { lat: 48.8371301, lng: 18.9084445 },
    { lat: 48.8371241, lng: 18.9082401 },
    { lat: 48.8371325, lng: 18.9080291 },
    { lat: 48.8371298, lng: 18.9079116 },
    { lat: 48.8370794, lng: 18.9076112 },
    { lat: 48.8370783, lng: 18.9076081 },
    { lat: 48.8370364, lng: 18.9073846 },
    { lat: 48.8370803, lng: 18.9071629 },
    { lat: 48.8371433, lng: 18.9069215 },
    { lat: 48.8372187, lng: 18.9063442 },
    { lat: 48.8372237, lng: 18.9058483 },
    { lat: 48.8373005, lng: 18.9055425 },
    { lat: 48.8372952, lng: 18.9047339 },
    { lat: 48.8372789, lng: 18.9046603 },
    { lat: 48.837142, lng: 18.9043628 },
    { lat: 48.8369361, lng: 18.9040191 },
    { lat: 48.8365401, lng: 18.9035087 },
    { lat: 48.8364409, lng: 18.9034205 },
    { lat: 48.8363403, lng: 18.9033934 },
    { lat: 48.8359992, lng: 18.9033823 },
    { lat: 48.835825, lng: 18.9034178 },
    { lat: 48.8357121, lng: 18.9033913 },
    { lat: 48.8352907, lng: 18.9033969 },
    { lat: 48.8351947, lng: 18.9034375 },
    { lat: 48.8351242, lng: 18.9035145 },
    { lat: 48.8351046, lng: 18.9034624 },
    { lat: 48.8349975, lng: 18.9028676 },
    { lat: 48.8351148, lng: 18.9023388 },
    { lat: 48.8349022, lng: 18.901823 },
    { lat: 48.8349696, lng: 18.9006497 },
    { lat: 48.8349786, lng: 18.9001902 },
    { lat: 48.835009, lng: 18.8999676 },
    { lat: 48.8350954, lng: 18.8995419 },
    { lat: 48.8351829, lng: 18.8993813 },
    { lat: 48.8349106, lng: 18.8992827 },
    { lat: 48.8346318, lng: 18.8979554 },
    { lat: 48.8343697, lng: 18.8977941 },
    { lat: 48.8341781, lng: 18.8971992 },
    { lat: 48.8339774, lng: 18.8966069 },
    { lat: 48.8339516, lng: 18.8965187 },
    { lat: 48.8336666, lng: 18.8965265 },
    { lat: 48.8335562, lng: 18.8965491 },
    { lat: 48.8334198, lng: 18.8958011 },
    { lat: 48.8334021, lng: 18.8952581 },
    { lat: 48.833412, lng: 18.895015 },
    { lat: 48.8331629, lng: 18.8935866 },
    { lat: 48.8325294, lng: 18.8915042 },
    { lat: 48.8323966, lng: 18.8910037 },
    { lat: 48.8320345, lng: 18.8899706 },
    { lat: 48.8308068, lng: 18.8873843 },
    { lat: 48.830407, lng: 18.8861423 },
    { lat: 48.8300445, lng: 18.8837189 },
    { lat: 48.8299354, lng: 18.8831334 },
    { lat: 48.8299931, lng: 18.8831155 },
    { lat: 48.8296767, lng: 18.8817181 },
    { lat: 48.8296246, lng: 18.8814952 },
    { lat: 48.829341, lng: 18.8800389 },
    { lat: 48.8302424, lng: 18.8792808 },
    { lat: 48.8299871, lng: 18.8786592 },
    { lat: 48.8293894, lng: 18.8782835 },
    { lat: 48.829312, lng: 18.878337 },
    { lat: 48.8288919, lng: 18.8770965 },
    { lat: 48.8287886, lng: 18.8768621 },
    { lat: 48.8282349, lng: 18.8749789 },
    { lat: 48.8282108, lng: 18.8742034 },
    { lat: 48.8276407, lng: 18.8737085 },
    { lat: 48.8272372, lng: 18.8732499 },
    { lat: 48.8266685, lng: 18.872499 },
    { lat: 48.8263728, lng: 18.8719467 },
    { lat: 48.8260614, lng: 18.8712445 },
    { lat: 48.8260266, lng: 18.8708725 },
    { lat: 48.8259274, lng: 18.8701768 },
    { lat: 48.8258941, lng: 18.8692864 },
    { lat: 48.8260076, lng: 18.8681433 },
    { lat: 48.8261156, lng: 18.8677909 },
    { lat: 48.8262608, lng: 18.867106 },
    { lat: 48.8262098, lng: 18.867078 },
    { lat: 48.8263719, lng: 18.8670978 },
    { lat: 48.8262899, lng: 18.8670145 },
    { lat: 48.8261893, lng: 18.866529 },
    { lat: 48.825958, lng: 18.8665259 },
    { lat: 48.8259285, lng: 18.8664585 },
    { lat: 48.8262664, lng: 18.866055 },
    { lat: 48.8261308, lng: 18.8660929 },
    { lat: 48.8261087, lng: 18.8660164 },
    { lat: 48.825975, lng: 18.8654113 },
    { lat: 48.8256637, lng: 18.8649296 },
    { lat: 48.8253775, lng: 18.8644514 },
    { lat: 48.8251864, lng: 18.864113 },
    { lat: 48.8250865, lng: 18.8638621 },
    { lat: 48.8249125, lng: 18.8633531 },
    { lat: 48.8248388, lng: 18.8630823 },
    { lat: 48.8245498, lng: 18.8614801 },
    { lat: 48.8244403, lng: 18.860864 },
    { lat: 48.8242274, lng: 18.8596231 },
    { lat: 48.8239976, lng: 18.8579051 },
    { lat: 48.8239314, lng: 18.8571218 },
    { lat: 48.8237573, lng: 18.8554577 },
    { lat: 48.8236564, lng: 18.8544205 },
    { lat: 48.8236412, lng: 18.8537098 },
    { lat: 48.8235065, lng: 18.8526315 },
    { lat: 48.8233615, lng: 18.8517462 },
    { lat: 48.8232985, lng: 18.8514692 },
    { lat: 48.8230821, lng: 18.8511406 },
    { lat: 48.822921, lng: 18.8509267 },
    { lat: 48.8226432, lng: 18.8505704 },
    { lat: 48.8222062, lng: 18.8500583 },
    { lat: 48.8220357, lng: 18.8498759 },
    { lat: 48.8218818, lng: 18.8496469 },
    { lat: 48.8213955, lng: 18.8491217 },
    { lat: 48.8209018, lng: 18.8487534 },
    { lat: 48.8206566, lng: 18.8485037 },
    { lat: 48.8205894, lng: 18.8482276 },
    { lat: 48.8192614, lng: 18.8473815 },
    { lat: 48.819052, lng: 18.8475624 },
    { lat: 48.818883, lng: 18.8476758 },
    { lat: 48.8187095, lng: 18.8475236 },
    { lat: 48.8186422, lng: 18.8475759 },
    { lat: 48.81855, lng: 18.8476241 },
    { lat: 48.8184292, lng: 18.8476237 },
    { lat: 48.8183589, lng: 18.8477895 },
    { lat: 48.8184928, lng: 18.8480663 },
    { lat: 48.8184379, lng: 18.8482023 },
    { lat: 48.8184392, lng: 18.8484058 },
    { lat: 48.8183941, lng: 18.8484927 },
    { lat: 48.8185185, lng: 18.8486497 },
    { lat: 48.8183861, lng: 18.8492737 },
    { lat: 48.8183433, lng: 18.8492748 },
    { lat: 48.8182926, lng: 18.8492522 },
    { lat: 48.81822, lng: 18.8492476 },
    { lat: 48.8182387, lng: 18.8495395 },
    { lat: 48.8181721, lng: 18.849825 },
    { lat: 48.8180684, lng: 18.8501715 },
    { lat: 48.8179289, lng: 18.8506586 },
    { lat: 48.8178888, lng: 18.8509063 },
    { lat: 48.8178435, lng: 18.8510362 },
    { lat: 48.8177624, lng: 18.8510576 },
    { lat: 48.817731, lng: 18.8511729 },
    { lat: 48.8178206, lng: 18.8512471 },
    { lat: 48.8178758, lng: 18.851418 },
    { lat: 48.8180484, lng: 18.8514441 },
    { lat: 48.8181099, lng: 18.8518243 },
    { lat: 48.8179813, lng: 18.8520009 },
    { lat: 48.8177616, lng: 18.8521755 },
    { lat: 48.8176814, lng: 18.8522605 },
    { lat: 48.8175209, lng: 18.8523646 },
    { lat: 48.8174542, lng: 18.8523593 },
    { lat: 48.8173264, lng: 18.8522635 },
    { lat: 48.8171805, lng: 18.8521935 },
    { lat: 48.8168642, lng: 18.8520707 },
    { lat: 48.8167276, lng: 18.8517649 },
    { lat: 48.816579, lng: 18.851857 },
    { lat: 48.8166019, lng: 18.8517734 },
    { lat: 48.8161515, lng: 18.8521331 },
    { lat: 48.8158622, lng: 18.8522547 },
    { lat: 48.8156407, lng: 18.8521797 },
    { lat: 48.8154836, lng: 18.8520849 },
    { lat: 48.8152068, lng: 18.852074 },
    { lat: 48.8147211, lng: 18.8523398 },
    { lat: 48.8146528, lng: 18.8525465 },
    { lat: 48.8145779, lng: 18.8526699 },
    { lat: 48.8145327, lng: 18.8527092 },
    { lat: 48.8144602, lng: 18.8526831 },
    { lat: 48.8143747, lng: 18.8526013 },
    { lat: 48.814265, lng: 18.8526368 },
    { lat: 48.8141195, lng: 18.8527646 },
    { lat: 48.8139728, lng: 18.8530489 },
    { lat: 48.8139505, lng: 18.8532712 },
    { lat: 48.8135311, lng: 18.8536415 },
    { lat: 48.8134973, lng: 18.8536409 },
    { lat: 48.8134484, lng: 18.8536549 },
    { lat: 48.813247, lng: 18.8541889 },
    { lat: 48.8131546, lng: 18.8543208 },
    { lat: 48.8131614, lng: 18.8545611 },
    { lat: 48.8131049, lng: 18.8547131 },
    { lat: 48.813151, lng: 18.8551477 },
    { lat: 48.8131133, lng: 18.8557249 },
    { lat: 48.8130897, lng: 18.85579 },
    { lat: 48.8129813, lng: 18.8559187 },
    { lat: 48.8128597, lng: 18.8559428 },
    { lat: 48.8127226, lng: 18.8558868 },
    { lat: 48.812604, lng: 18.8559294 },
    { lat: 48.8125814, lng: 18.8559496 },
    { lat: 48.8125832, lng: 18.8559831 },
    { lat: 48.8125723, lng: 18.856101 },
    { lat: 48.8125571, lng: 18.8561325 },
    { lat: 48.8124364, lng: 18.8561965 },
    { lat: 48.8123518, lng: 18.8561743 },
    { lat: 48.8122542, lng: 18.8562059 },
    { lat: 48.8121859, lng: 18.856307 },
    { lat: 48.8120881, lng: 18.8566127 },
    { lat: 48.8120194, lng: 18.8567929 },
    { lat: 48.8119131, lng: 18.8568699 },
    { lat: 48.8117816, lng: 18.8568354 },
    { lat: 48.811638, lng: 18.8568739 },
    { lat: 48.8115136, lng: 18.8569766 },
    { lat: 48.8114322, lng: 18.8571898 },
    { lat: 48.8113692, lng: 18.8574756 },
    { lat: 48.8112401, lng: 18.8575961 },
    { lat: 48.8110056, lng: 18.8574021 },
    { lat: 48.8109263, lng: 18.8574368 },
    { lat: 48.8106723, lng: 18.8577785 },
    { lat: 48.8106522, lng: 18.8578932 },
    { lat: 48.8103869, lng: 18.8581282 },
    { lat: 48.8102089, lng: 18.8581536 },
    { lat: 48.8100779, lng: 18.8581916 },
    { lat: 48.8099412, lng: 18.8583584 },
    { lat: 48.8097977, lng: 18.8583994 },
    { lat: 48.8096813, lng: 18.8583499 },
    { lat: 48.809575, lng: 18.8583467 },
    { lat: 48.8095253, lng: 18.858408 },
    { lat: 48.8090486, lng: 18.8585203 },
    { lat: 48.8089318, lng: 18.8584866 },
    { lat: 48.8084733, lng: 18.858998 },
    { lat: 48.8083803, lng: 18.8592051 },
    { lat: 48.8082994, lng: 18.8592121 },
    { lat: 48.8081608, lng: 18.8589953 },
    { lat: 48.8081048, lng: 18.8588485 },
    { lat: 48.8080747, lng: 18.8587916 },
    { lat: 48.807318, lng: 18.8591824 },
    { lat: 48.806532, lng: 18.8600535 },
    { lat: 48.8063437, lng: 18.8600543 },
    { lat: 48.8062337, lng: 18.8598867 },
    { lat: 48.8060953, lng: 18.8599163 },
    { lat: 48.8060091, lng: 18.8600177 },
    { lat: 48.8058996, lng: 18.8602993 },
    { lat: 48.8057898, lng: 18.8603563 },
    { lat: 48.8056437, lng: 18.8602996 },
    { lat: 48.8050953, lng: 18.8605966 },
    { lat: 48.8049732, lng: 18.8606755 },
    { lat: 48.8048489, lng: 18.8607395 },
    { lat: 48.8042048, lng: 18.8607113 },
    { lat: 48.8040644, lng: 18.8608077 },
    { lat: 48.8040534, lng: 18.8607718 },
    { lat: 48.8040104, lng: 18.8607046 },
    { lat: 48.8038231, lng: 18.8602836 },
    { lat: 48.8034354, lng: 18.8602898 },
    { lat: 48.8034207, lng: 18.8602665 },
    { lat: 48.8025455, lng: 18.8614829 },
    { lat: 48.8015793, lng: 18.8622729 },
    { lat: 48.8011726, lng: 18.8624966 },
    { lat: 48.8006196, lng: 18.8627959 },
    { lat: 48.7998009, lng: 18.8636904 },
    { lat: 48.7996738, lng: 18.8637809 },
    { lat: 48.7992526, lng: 18.8645979 },
    { lat: 48.7985637, lng: 18.8653003 },
    { lat: 48.7981548, lng: 18.8655042 },
    { lat: 48.797021, lng: 18.8664991 },
    { lat: 48.7961182, lng: 18.8674027 },
    { lat: 48.7941321, lng: 18.868548 },
    { lat: 48.7934403, lng: 18.8689981 },
    { lat: 48.7924012, lng: 18.8697971 },
    { lat: 48.7921156, lng: 18.8698755 },
    { lat: 48.7920269, lng: 18.8699064 },
    { lat: 48.79133, lng: 18.8700785 },
    { lat: 48.7907913, lng: 18.8703896 },
    { lat: 48.7906895, lng: 18.8705143 },
    { lat: 48.7905803, lng: 18.8706048 },
    { lat: 48.7904446, lng: 18.8706852 },
    { lat: 48.7889384, lng: 18.8713884 },
    { lat: 48.7887757, lng: 18.8716007 },
    { lat: 48.7884089, lng: 18.8721625 },
    { lat: 48.7876098, lng: 18.87252 },
    { lat: 48.7865875, lng: 18.8724391 },
    { lat: 48.7857863, lng: 18.8727561 },
    { lat: 48.7848373, lng: 18.8732682 },
    { lat: 48.7845264, lng: 18.8732502 },
    { lat: 48.7830986, lng: 18.8737513 },
    { lat: 48.7823217, lng: 18.8739023 },
    { lat: 48.7811378, lng: 18.8748015 },
    { lat: 48.7800762, lng: 18.8766029 },
    { lat: 48.7800875, lng: 18.8766255 },
    { lat: 48.7800635, lng: 18.8766412 },
    { lat: 48.77979, lng: 18.8769781 },
    { lat: 48.7794473, lng: 18.8772012 },
    { lat: 48.779335, lng: 18.8773835 },
    { lat: 48.7791846, lng: 18.8777706 },
    { lat: 48.7793431, lng: 18.8778928 },
    { lat: 48.7792635, lng: 18.8781878 },
    { lat: 48.7793079, lng: 18.8784997 },
    { lat: 48.7794371, lng: 18.8786686 },
    { lat: 48.7796074, lng: 18.87869 },
    { lat: 48.7796717, lng: 18.8788651 },
    { lat: 48.779569, lng: 18.8790363 },
    { lat: 48.7793628, lng: 18.8792812 },
    { lat: 48.7792902, lng: 18.8796451 },
    { lat: 48.7792915, lng: 18.8798452 },
    { lat: 48.779377, lng: 18.8801933 },
    { lat: 48.779211, lng: 18.8803827 },
    { lat: 48.7792174, lng: 18.8805304 },
    { lat: 48.7793714, lng: 18.8806942 },
    { lat: 48.7794443, lng: 18.8807985 },
    { lat: 48.7796626, lng: 18.880854 },
    { lat: 48.7797168, lng: 18.8810517 },
    { lat: 48.779855, lng: 18.8811534 },
    { lat: 48.7798303, lng: 18.8812657 },
    { lat: 48.7798277, lng: 18.8814341 },
    { lat: 48.7798793, lng: 18.8816021 },
    { lat: 48.7798308, lng: 18.8816929 },
    { lat: 48.7801077, lng: 18.882095 },
    { lat: 48.7801301, lng: 18.8822286 },
    { lat: 48.7803984, lng: 18.8824004 },
    { lat: 48.7804229, lng: 18.8825037 },
    { lat: 48.7805301, lng: 18.8824396 },
    { lat: 48.7806224, lng: 18.8824773 },
    { lat: 48.7808074, lng: 18.8825935 },
    { lat: 48.7810908, lng: 18.8828204 },
    { lat: 48.781254, lng: 18.8827679 },
    { lat: 48.7812239, lng: 18.8829724 },
    { lat: 48.7812851, lng: 18.8830231 },
    { lat: 48.7814391, lng: 18.8828623 },
    { lat: 48.7816072, lng: 18.8826198 },
    { lat: 48.7817136, lng: 18.8828064 },
    { lat: 48.7816393, lng: 18.8829817 },
    { lat: 48.7817595, lng: 18.8833019 },
    { lat: 48.7820139, lng: 18.8834671 },
    { lat: 48.7821043, lng: 18.8836027 },
    { lat: 48.7821623, lng: 18.8837421 },
    { lat: 48.7821176, lng: 18.8838199 },
    { lat: 48.7820321, lng: 18.8838007 },
    { lat: 48.781996, lng: 18.8838432 },
    { lat: 48.7820145, lng: 18.8840291 },
    { lat: 48.7822086, lng: 18.8843391 },
    { lat: 48.7824231, lng: 18.8841686 },
    { lat: 48.7825227, lng: 18.8841515 },
    { lat: 48.7826157, lng: 18.8844023 },
    { lat: 48.7826114, lng: 18.8848008 },
    { lat: 48.7826597, lng: 18.8849506 },
    { lat: 48.7827845, lng: 18.8849237 },
    { lat: 48.782801, lng: 18.8850705 },
    { lat: 48.7828789, lng: 18.8851136 },
    { lat: 48.7828807, lng: 18.8852138 },
    { lat: 48.7830497, lng: 18.8853655 },
    { lat: 48.7830102, lng: 18.8854978 },
    { lat: 48.7831186, lng: 18.8856309 },
    { lat: 48.7831932, lng: 18.8859421 },
    { lat: 48.7832698, lng: 18.8861169 },
    { lat: 48.7836675, lng: 18.8867655 },
    { lat: 48.7838249, lng: 18.8868445 },
    { lat: 48.7839217, lng: 18.8870405 },
    { lat: 48.7839567, lng: 18.8872384 },
    { lat: 48.7841303, lng: 18.8873896 },
    { lat: 48.7843432, lng: 18.8877568 },
    { lat: 48.7845687, lng: 18.8885025 },
    { lat: 48.7846087, lng: 18.888712 },
    { lat: 48.7847274, lng: 18.8892451 },
    { lat: 48.7851141, lng: 18.8899651 },
    { lat: 48.7854057, lng: 18.8906827 },
    { lat: 48.785647, lng: 18.8910984 },
    { lat: 48.7859971, lng: 18.8919439 },
    { lat: 48.7861076, lng: 18.8922313 },
    { lat: 48.7862962, lng: 18.8930222 },
    { lat: 48.7865208, lng: 18.8936426 },
    { lat: 48.7866539, lng: 18.8943016 },
    { lat: 48.7868483, lng: 18.8946414 },
    { lat: 48.7869561, lng: 18.8948532 },
    { lat: 48.7871548, lng: 18.8954506 },
    { lat: 48.7872513, lng: 18.8959917 },
    { lat: 48.7872282, lng: 18.8962629 },
    { lat: 48.7872255, lng: 18.8965429 },
    { lat: 48.7871705, lng: 18.8971604 },
    { lat: 48.787222, lng: 18.8973067 },
    { lat: 48.7872568, lng: 18.8978969 },
    { lat: 48.7873403, lng: 18.898098 },
    { lat: 48.7876057, lng: 18.8981713 },
    { lat: 48.7880459, lng: 18.8983748 },
    { lat: 48.7883624, lng: 18.898368 },
    { lat: 48.7884465, lng: 18.8983824 },
    { lat: 48.7890155, lng: 18.8985311 },
    { lat: 48.789148, lng: 18.8985209 },
    { lat: 48.7892887, lng: 18.8985359 },
    { lat: 48.7897347, lng: 18.8989444 },
    { lat: 48.7902485, lng: 18.8990259 },
    { lat: 48.7906291, lng: 18.8991031 },
    { lat: 48.79128, lng: 18.8992401 },
    { lat: 48.7919345, lng: 18.8994242 },
    { lat: 48.792259, lng: 18.8993342 },
    { lat: 48.7926134, lng: 18.8994485 },
    { lat: 48.7927763, lng: 18.8994372 },
    { lat: 48.7933293, lng: 18.8993611 },
    { lat: 48.7933747, lng: 18.8994126 },
    { lat: 48.7938482, lng: 18.8997628 },
    { lat: 48.7946948, lng: 18.8998003 },
    { lat: 48.7952823, lng: 18.8997563 },
    { lat: 48.7961051, lng: 18.9006756 },
    { lat: 48.7963746, lng: 18.902147 },
    { lat: 48.7966032, lng: 18.9030024 },
    { lat: 48.7968163, lng: 18.903771 },
    { lat: 48.796916, lng: 18.904462 },
    { lat: 48.7969499, lng: 18.9072333 },
    { lat: 48.7968457, lng: 18.9076622 },
    { lat: 48.7970785, lng: 18.9084031 },
    { lat: 48.7970854, lng: 18.9091733 },
    { lat: 48.7972545, lng: 18.9102518 },
    { lat: 48.7974331, lng: 18.9133423 },
    { lat: 48.7974266, lng: 18.9135705 },
    { lat: 48.7974781, lng: 18.9144421 },
    { lat: 48.797493, lng: 18.9144239 },
    { lat: 48.7975074, lng: 18.9145949 },
    { lat: 48.7974596, lng: 18.9150079 },
    { lat: 48.797439, lng: 18.915266 },
    { lat: 48.7974403, lng: 18.9155788 },
    { lat: 48.7974241, lng: 18.9157871 },
    { lat: 48.7973852, lng: 18.9159325 },
    { lat: 48.7972888, lng: 18.9161634 },
    { lat: 48.797276, lng: 18.9163344 },
    { lat: 48.7972918, lng: 18.9165092 },
    { lat: 48.7973621, lng: 18.9166902 },
    { lat: 48.7973514, lng: 18.9170137 },
    { lat: 48.7972768, lng: 18.9173597 },
    { lat: 48.797229, lng: 18.9177047 },
    { lat: 48.7972562, lng: 18.9180609 },
    { lat: 48.7973018, lng: 18.9183574 },
    { lat: 48.7974347, lng: 18.9188168 },
    { lat: 48.7975026, lng: 18.9191318 },
    { lat: 48.7975703, lng: 18.9193531 },
    { lat: 48.7975984, lng: 18.9195919 },
    { lat: 48.7975972, lng: 18.9200534 },
    { lat: 48.7976288, lng: 18.9202088 },
    { lat: 48.7977052, lng: 18.9204005 },
    { lat: 48.7977359, lng: 18.9205629 },
    { lat: 48.7977566, lng: 18.9207686 },
    { lat: 48.7977652, lng: 18.9211113 },
    { lat: 48.7980252, lng: 18.9217006 },
    { lat: 48.7982034, lng: 18.9223392 },
    { lat: 48.798234, lng: 18.9225173 },
    { lat: 48.798302, lng: 18.9228352 },
    { lat: 48.7980031, lng: 18.9237832 },
    { lat: 48.7978673, lng: 18.9238588 },
    { lat: 48.7976628, lng: 18.9240963 },
    { lat: 48.797452, lng: 18.9244464 },
    { lat: 48.7973314, lng: 18.9247828 },
    { lat: 48.7972282, lng: 18.9250563 },
    { lat: 48.7972395, lng: 18.9251887 },
    { lat: 48.7972781, lng: 18.9254817 },
    { lat: 48.7971676, lng: 18.9259467 },
    { lat: 48.7971212, lng: 18.9260512 },
    { lat: 48.7969925, lng: 18.9262226 },
    { lat: 48.7969163, lng: 18.9264096 },
    { lat: 48.796879, lng: 18.9265627 },
    { lat: 48.7966374, lng: 18.926778 },
    { lat: 48.7964572, lng: 18.9270026 },
    { lat: 48.7964113, lng: 18.9271389 },
    { lat: 48.7963142, lng: 18.927579 },
    { lat: 48.7961238, lng: 18.9279121 },
    { lat: 48.796041, lng: 18.9281038 },
    { lat: 48.7960442, lng: 18.9283841 },
    { lat: 48.7959524, lng: 18.9287064 },
    { lat: 48.7959146, lng: 18.9290076 },
    { lat: 48.7959294, lng: 18.9293853 },
    { lat: 48.7958926, lng: 18.9296775 },
    { lat: 48.7959145, lng: 18.9298659 },
    { lat: 48.7960447, lng: 18.930273 },
    { lat: 48.7961876, lng: 18.9304201 },
    { lat: 48.7962492, lng: 18.9306547 },
    { lat: 48.7962634, lng: 18.9308697 },
    { lat: 48.7963448, lng: 18.9311578 },
    { lat: 48.7963588, lng: 18.9311727 },
    { lat: 48.7963698, lng: 18.9311845 },
    { lat: 48.7967232, lng: 18.931351 },
    { lat: 48.7970053, lng: 18.9317947 },
    { lat: 48.7971486, lng: 18.9319965 },
    { lat: 48.7972842, lng: 18.9325567 },
    { lat: 48.7973928, lng: 18.9331148 },
    { lat: 48.7974142, lng: 18.9333119 },
    { lat: 48.7977423, lng: 18.934001 },
    { lat: 48.7978873, lng: 18.9346285 },
    { lat: 48.7981917, lng: 18.9350524 },
    { lat: 48.7987954, lng: 18.9356786 },
    { lat: 48.7991152, lng: 18.9360246 },
    { lat: 48.7994162, lng: 18.9362645 },
    { lat: 48.7995554, lng: 18.9367611 },
    { lat: 48.799575, lng: 18.9368403 },
    { lat: 48.7995801, lng: 18.936893 },
    { lat: 48.7996022, lng: 18.9368865 },
    { lat: 48.7997482, lng: 18.9380926 },
    { lat: 48.7997834, lng: 18.9407469 },
    { lat: 48.7996564, lng: 18.9436114 },
    { lat: 48.7998269, lng: 18.9463199 },
    { lat: 48.7999699, lng: 18.9481541 },
    { lat: 48.7999599, lng: 18.9481796 },
    { lat: 48.8006872, lng: 18.9490876 },
    { lat: 48.8007726, lng: 18.9492844 },
    { lat: 48.8009289, lng: 18.9494725 },
    { lat: 48.8011942, lng: 18.9495467 },
    { lat: 48.8013241, lng: 18.9496625 },
    { lat: 48.8014988, lng: 18.9499704 },
    { lat: 48.8016159, lng: 18.950146 },
    { lat: 48.8017954, lng: 18.9507953 },
    { lat: 48.8019117, lng: 18.9513941 },
    { lat: 48.8019385, lng: 18.9519857 },
    { lat: 48.8023061, lng: 18.9541192 },
    { lat: 48.8024217, lng: 18.95453 },
    { lat: 48.80251, lng: 18.9547786 },
    { lat: 48.8025055, lng: 18.954914 },
    { lat: 48.8025186, lng: 18.9551242 },
    { lat: 48.802582, lng: 18.9556186 },
    { lat: 48.8026924, lng: 18.9562621 },
    { lat: 48.8027114, lng: 18.956277 },
    { lat: 48.8027166, lng: 18.9562743 },
    { lat: 48.8028237, lng: 18.9564288 },
    { lat: 48.8028864, lng: 18.9567967 },
    { lat: 48.8026325, lng: 18.9574554 },
    { lat: 48.8026815, lng: 18.9578231 },
    { lat: 48.8022237, lng: 18.958974 },
    { lat: 48.802207, lng: 18.9602689 },
    { lat: 48.8023028, lng: 18.9607538 },
    { lat: 48.8023857, lng: 18.9616329 },
    { lat: 48.8026585, lng: 18.9619857 },
    { lat: 48.802914, lng: 18.9622675 },
    { lat: 48.803201, lng: 18.9630982 },
    { lat: 48.8037868, lng: 18.9642697 },
    { lat: 48.8038755, lng: 18.964439 },
    { lat: 48.8038998, lng: 18.9646282 },
    { lat: 48.8038396, lng: 18.9652447 },
    { lat: 48.8038459, lng: 18.9652754 },
    { lat: 48.8042545, lng: 18.9656875 },
    { lat: 48.8047225, lng: 18.9658874 },
    { lat: 48.8051504, lng: 18.9666557 },
    { lat: 48.8055697, lng: 18.9672565 },
    { lat: 48.8058241, lng: 18.9681587 },
    { lat: 48.8059988, lng: 18.9685983 },
    { lat: 48.8062399, lng: 18.9692435 },
    { lat: 48.8065843, lng: 18.969677 },
    { lat: 48.80663, lng: 18.9698416 },
    { lat: 48.8068355, lng: 18.9708708 },
    { lat: 48.8073569, lng: 18.9718157 },
    { lat: 48.8073607, lng: 18.971826 },
    { lat: 48.806837, lng: 18.9726801 },
    { lat: 48.8066336, lng: 18.9735858 },
    { lat: 48.806565, lng: 18.9748338 },
    { lat: 48.8064539, lng: 18.9756014 },
    { lat: 48.8061594, lng: 18.9768227 },
    { lat: 48.8058315, lng: 18.9771344 },
    { lat: 48.8058436, lng: 18.9777514 },
    { lat: 48.8059477, lng: 18.9781607 },
    { lat: 48.8059202, lng: 18.9796668 },
    { lat: 48.805791, lng: 18.9798303 },
    { lat: 48.8056056, lng: 18.9801125 },
    { lat: 48.8055969, lng: 18.9809204 },
    { lat: 48.8054829, lng: 18.9821458 },
    { lat: 48.8053283, lng: 18.9828998 },
    { lat: 48.8056279, lng: 18.9837836 },
    { lat: 48.8058233, lng: 18.9842803 },
    { lat: 48.8056368, lng: 18.9852966 },
    { lat: 48.8056518, lng: 18.9853034 },
    { lat: 48.8061388, lng: 18.9891181 },
    { lat: 48.8061457, lng: 18.9902136 },
    { lat: 48.8060327, lng: 18.9907213 },
    { lat: 48.806092, lng: 18.991116 },
  ],
  Háj: [
    { lat: 48.8366548, lng: 18.8845437 },
    { lat: 48.8370579, lng: 18.8852327 },
    { lat: 48.8372667, lng: 18.8856308 },
    { lat: 48.8375157, lng: 18.8860671 },
    { lat: 48.8433598, lng: 18.8963267 },
    { lat: 48.8433922, lng: 18.8964758 },
    { lat: 48.8436001, lng: 18.8965902 },
    { lat: 48.8439602, lng: 18.8967339 },
    { lat: 48.8440701, lng: 18.8967425 },
    { lat: 48.8442811, lng: 18.896965 },
    { lat: 48.8444169, lng: 18.8971527 },
    { lat: 48.8443989, lng: 18.8975027 },
    { lat: 48.8445862, lng: 18.8974403 },
    { lat: 48.8445845, lng: 18.8978045 },
    { lat: 48.8446482, lng: 18.897903 },
    { lat: 48.8446097, lng: 18.8982497 },
    { lat: 48.8445454, lng: 18.8990402 },
    { lat: 48.8442994, lng: 18.8987312 },
    { lat: 48.8443536, lng: 18.8984245 },
    { lat: 48.8441991, lng: 18.8983797 },
    { lat: 48.84409, lng: 18.8988915 },
    { lat: 48.8440348, lng: 18.899464 },
    { lat: 48.8440345, lng: 18.8995653 },
    { lat: 48.8439879, lng: 18.8997147 },
    { lat: 48.8441278, lng: 18.8997799 },
    { lat: 48.8440843, lng: 18.8999643 },
    { lat: 48.8441063, lng: 18.9002184 },
    { lat: 48.8442561, lng: 18.9001951 },
    { lat: 48.8442247, lng: 18.9005689 },
    { lat: 48.8441978, lng: 18.9009945 },
    { lat: 48.8471867, lng: 18.9005308 },
    { lat: 48.8472376, lng: 18.9002466 },
    { lat: 48.847445, lng: 18.9000654 },
    { lat: 48.8476823, lng: 18.9001453 },
    { lat: 48.848326, lng: 18.8983597 },
    { lat: 48.8484474, lng: 18.8983962 },
    { lat: 48.8486292, lng: 18.89772 },
    { lat: 48.8485836, lng: 18.8974476 },
    { lat: 48.8488917, lng: 18.8968349 },
    { lat: 48.8489285, lng: 18.896609 },
    { lat: 48.8490045, lng: 18.8965113 },
    { lat: 48.8494437, lng: 18.8965648 },
    { lat: 48.8494877, lng: 18.8965847 },
    { lat: 48.8495162, lng: 18.8964825 },
    { lat: 48.8496468, lng: 18.8965677 },
    { lat: 48.8496343, lng: 18.8972684 },
    { lat: 48.8496142, lng: 18.8976005 },
    { lat: 48.8495025, lng: 18.8983934 },
    { lat: 48.8493772, lng: 18.8987203 },
    { lat: 48.8492331, lng: 18.899033 },
    { lat: 48.8491542, lng: 18.8992328 },
    { lat: 48.8491112, lng: 18.8994032 },
    { lat: 48.8490695, lng: 18.8996204 },
    { lat: 48.8490476, lng: 18.8998339 },
    { lat: 48.8490473, lng: 18.9000913 },
    { lat: 48.8490153, lng: 18.900413 },
    { lat: 48.8490094, lng: 18.9007803 },
    { lat: 48.8490128, lng: 18.9009818 },
    { lat: 48.8490366, lng: 18.9011823 },
    { lat: 48.8490794, lng: 18.9013126 },
    { lat: 48.8491722, lng: 18.9014506 },
    { lat: 48.8492499, lng: 18.9015375 },
    { lat: 48.8493234, lng: 18.9015832 },
    { lat: 48.8495705, lng: 18.9016974 },
    { lat: 48.8495988, lng: 18.9016364 },
    { lat: 48.8497015, lng: 18.9013257 },
    { lat: 48.8498377, lng: 18.9012603 },
    { lat: 48.8499482, lng: 18.9011463 },
    { lat: 48.8499931, lng: 18.9010777 },
    { lat: 48.8499931, lng: 18.9008339 },
    { lat: 48.8500081, lng: 18.9007999 },
    { lat: 48.8500364, lng: 18.9007804 },
    { lat: 48.8504278, lng: 18.9008239 },
    { lat: 48.8504883, lng: 18.9009306 },
    { lat: 48.8505074, lng: 18.9010827 },
    { lat: 48.8506457, lng: 18.9013367 },
    { lat: 48.8507436, lng: 18.9015744 },
    { lat: 48.8507704, lng: 18.9016819 },
    { lat: 48.8509563, lng: 18.9017642 },
    { lat: 48.8521013, lng: 18.9031432 },
    { lat: 48.8522449, lng: 18.9041617 },
    { lat: 48.8522832, lng: 18.9044179 },
    { lat: 48.852394, lng: 18.9051585 },
    { lat: 48.8524982, lng: 18.9061348 },
    { lat: 48.8525674, lng: 18.9067829 },
    { lat: 48.8530444, lng: 18.906739 },
    { lat: 48.8534607, lng: 18.9068555 },
    { lat: 48.8539678, lng: 18.9069577 },
    { lat: 48.8544558, lng: 18.9068911 },
    { lat: 48.8553676, lng: 18.9067481 },
    { lat: 48.8559121, lng: 18.9055379 },
    { lat: 48.856568, lng: 18.9052893 },
    { lat: 48.8574419, lng: 18.9053806 },
    { lat: 48.8578678, lng: 18.9052392 },
    { lat: 48.8582548, lng: 18.9045835 },
    { lat: 48.8584916, lng: 18.9042771 },
    { lat: 48.8587044, lng: 18.9038557 },
    { lat: 48.8587446, lng: 18.9037586 },
    { lat: 48.8587885, lng: 18.903689 },
    { lat: 48.8588527, lng: 18.9030931 },
    { lat: 48.858947, lng: 18.9028672 },
    { lat: 48.8591108, lng: 18.9026749 },
    { lat: 48.8596184, lng: 18.9023724 },
    { lat: 48.8600822, lng: 18.9019795 },
    { lat: 48.860637, lng: 18.9014311 },
    { lat: 48.8608639, lng: 18.9013511 },
    { lat: 48.8612534, lng: 18.9009651 },
    { lat: 48.8613588, lng: 18.9007994 },
    { lat: 48.8618773, lng: 18.9003147 },
    { lat: 48.8622575, lng: 18.9000283 },
    { lat: 48.8627012, lng: 18.8998439 },
    { lat: 48.8634848, lng: 18.898215 },
    { lat: 48.8636516, lng: 18.8985649 },
    { lat: 48.8642813, lng: 18.8983773 },
    { lat: 48.8660893, lng: 18.897336 },
    { lat: 48.8661341, lng: 18.8973479 },
    { lat: 48.8661632, lng: 18.8971468 },
    { lat: 48.8662029, lng: 18.8969534 },
    { lat: 48.8663821, lng: 18.8949959 },
    { lat: 48.8663404, lng: 18.8935901 },
    { lat: 48.8664041, lng: 18.89314 },
    { lat: 48.8664475, lng: 18.8929962 },
    { lat: 48.8664955, lng: 18.8928764 },
    { lat: 48.8666487, lng: 18.8926312 },
    { lat: 48.8667682, lng: 18.8924768 },
    { lat: 48.8669305, lng: 18.8923197 },
    { lat: 48.8671735, lng: 18.892179 },
    { lat: 48.8675114, lng: 18.8920654 },
    { lat: 48.8684067, lng: 18.8917776 },
    { lat: 48.8685273, lng: 18.8917322 },
    { lat: 48.8691934, lng: 18.8911114 },
    { lat: 48.8693731, lng: 18.8909036 },
    { lat: 48.8697892, lng: 18.8903094 },
    { lat: 48.8700703, lng: 18.8894382 },
    { lat: 48.8703637, lng: 18.8889344 },
    { lat: 48.8709659, lng: 18.888036 },
    { lat: 48.87147, lng: 18.8867871 },
    { lat: 48.8716932, lng: 18.8864359 },
    { lat: 48.8717259, lng: 18.8862812 },
    { lat: 48.8728383, lng: 18.8850227 },
    { lat: 48.8729478, lng: 18.88496 },
    { lat: 48.8735038, lng: 18.8846759 },
    { lat: 48.8736703, lng: 18.8845588 },
    { lat: 48.8739509, lng: 18.8841836 },
    { lat: 48.874123, lng: 18.8838452 },
    { lat: 48.8742598, lng: 18.8835246 },
    { lat: 48.8745258, lng: 18.8828846 },
    { lat: 48.8748199, lng: 18.8820927 },
    { lat: 48.874981, lng: 18.881848 },
    { lat: 48.8752586, lng: 18.8815254 },
    { lat: 48.8758147, lng: 18.8804983 },
    { lat: 48.8761374, lng: 18.8800429 },
    { lat: 48.8764852, lng: 18.879371 },
    { lat: 48.876776, lng: 18.8788645 },
    { lat: 48.8771507, lng: 18.8779727 },
    { lat: 48.87731, lng: 18.877519 },
    { lat: 48.8774071, lng: 18.8772797 },
    { lat: 48.877693, lng: 18.8766308 },
    { lat: 48.8778794, lng: 18.8761356 },
    { lat: 48.8780715, lng: 18.8755519 },
    { lat: 48.8781752, lng: 18.8752215 },
    { lat: 48.8784202, lng: 18.8741781 },
    { lat: 48.878745, lng: 18.8728626 },
    { lat: 48.8789637, lng: 18.8721163 },
    { lat: 48.8789982, lng: 18.8720441 },
    { lat: 48.879383, lng: 18.8713255 },
    { lat: 48.8802123, lng: 18.8702418 },
    { lat: 48.8786844, lng: 18.8648525 },
    { lat: 48.8786891, lng: 18.864771 },
    { lat: 48.8798268, lng: 18.8635457 },
    { lat: 48.8796684, lng: 18.8633148 },
    { lat: 48.8792858, lng: 18.86274 },
    { lat: 48.8787162, lng: 18.8635677 },
    { lat: 48.8784429, lng: 18.8627948 },
    { lat: 48.8784304, lng: 18.8627712 },
    { lat: 48.8784186, lng: 18.862735 },
    { lat: 48.8784059, lng: 18.8627096 },
    { lat: 48.8783429, lng: 18.8626236 },
    { lat: 48.87816, lng: 18.8623336 },
    { lat: 48.8784897, lng: 18.8617991 },
    { lat: 48.8787573, lng: 18.8612795 },
    { lat: 48.8786319, lng: 18.8611439 },
    { lat: 48.8782623, lng: 18.8609308 },
    { lat: 48.8775606, lng: 18.8607905 },
    { lat: 48.8766741, lng: 18.860873 },
    { lat: 48.8765135, lng: 18.8609117 },
    { lat: 48.876455, lng: 18.8607338 },
    { lat: 48.8766043, lng: 18.8590615 },
    { lat: 48.8765676, lng: 18.8590049 },
    { lat: 48.8760541, lng: 18.8591267 },
    { lat: 48.8759137, lng: 18.859095 },
    { lat: 48.8758161, lng: 18.8589755 },
    { lat: 48.8740728, lng: 18.8601751 },
    { lat: 48.8741416, lng: 18.8608769 },
    { lat: 48.8739625, lng: 18.8608576 },
    { lat: 48.8737682, lng: 18.8609161 },
    { lat: 48.8736526, lng: 18.8610566 },
    { lat: 48.8736019, lng: 18.8611651 },
    { lat: 48.8734559, lng: 18.8615044 },
    { lat: 48.8732509, lng: 18.8620155 },
    { lat: 48.8730717, lng: 18.8624751 },
    { lat: 48.8728225, lng: 18.8628256 },
    { lat: 48.8725239, lng: 18.8629129 },
    { lat: 48.8722668, lng: 18.8627844 },
    { lat: 48.8720844, lng: 18.8625287 },
    { lat: 48.8718541, lng: 18.8622354 },
    { lat: 48.8716141, lng: 18.8621084 },
    { lat: 48.8713772, lng: 18.8621519 },
    { lat: 48.8706851, lng: 18.862615 },
    { lat: 48.8700359, lng: 18.8630551 },
    { lat: 48.869019, lng: 18.8636713 },
    { lat: 48.8683464, lng: 18.864122 },
    { lat: 48.8675366, lng: 18.8647082 },
    { lat: 48.8666983, lng: 18.8652856 },
    { lat: 48.8660798, lng: 18.8656909 },
    { lat: 48.8652451, lng: 18.8662964 },
    { lat: 48.8648181, lng: 18.866612 },
    { lat: 48.8648013, lng: 18.8668325 },
    { lat: 48.8650483, lng: 18.8675356 },
    { lat: 48.8653067, lng: 18.8686521 },
    { lat: 48.8650098, lng: 18.8687553 },
    { lat: 48.8649199, lng: 18.868589 },
    { lat: 48.8647638, lng: 18.8684893 },
    { lat: 48.864512, lng: 18.8686377 },
    { lat: 48.8642643, lng: 18.8686856 },
    { lat: 48.8640528, lng: 18.868567 },
    { lat: 48.8638706, lng: 18.8684013 },
    { lat: 48.8635156, lng: 18.8679627 },
    { lat: 48.8632376, lng: 18.8678452 },
    { lat: 48.8629265, lng: 18.8679346 },
    { lat: 48.8617858, lng: 18.8687282 },
    { lat: 48.8611583, lng: 18.8690879 },
    { lat: 48.8606394, lng: 18.8693892 },
    { lat: 48.8600483, lng: 18.8696926 },
    { lat: 48.8594488, lng: 18.8699175 },
    { lat: 48.8588155, lng: 18.8700809 },
    { lat: 48.858155, lng: 18.870193 },
    { lat: 48.8575519, lng: 18.8702189 },
    { lat: 48.8570009, lng: 18.8701598 },
    { lat: 48.8564441, lng: 18.8700732 },
    { lat: 48.85619, lng: 18.8700262 },
    { lat: 48.8554963, lng: 18.8698014 },
    { lat: 48.8548223, lng: 18.8695016 },
    { lat: 48.8541483, lng: 18.8691778 },
    { lat: 48.8536145, lng: 18.8688192 },
    { lat: 48.8532326, lng: 18.8685185 },
    { lat: 48.8528027, lng: 18.8681168 },
    { lat: 48.8521981, lng: 18.867522 },
    { lat: 48.8515157, lng: 18.8677378 },
    { lat: 48.8512146, lng: 18.8675152 },
    { lat: 48.8500373, lng: 18.8666195 },
    { lat: 48.8490792, lng: 18.8666001 },
    { lat: 48.8489089, lng: 18.8666879 },
    { lat: 48.8487057, lng: 18.8668347 },
    { lat: 48.8483513, lng: 18.867138 },
    { lat: 48.8482408, lng: 18.8672689 },
    { lat: 48.8481182, lng: 18.8673604 },
    { lat: 48.848024, lng: 18.8671732 },
    { lat: 48.8479259, lng: 18.867241 },
    { lat: 48.8477296, lng: 18.8674758 },
    { lat: 48.8475125, lng: 18.8677292 },
    { lat: 48.847346, lng: 18.8677593 },
    { lat: 48.8472475, lng: 18.8677269 },
    { lat: 48.8470143, lng: 18.8677781 },
    { lat: 48.8468942, lng: 18.8677669 },
    { lat: 48.846824, lng: 18.8678912 },
    { lat: 48.8467329, lng: 18.8679206 },
    { lat: 48.8466423, lng: 18.8679095 },
    { lat: 48.8465048, lng: 18.8677349 },
    { lat: 48.8463996, lng: 18.8677062 },
    { lat: 48.8463674, lng: 18.8680906 },
    { lat: 48.8461961, lng: 18.8682236 },
    { lat: 48.8460923, lng: 18.8682452 },
    { lat: 48.8459619, lng: 18.8685799 },
    { lat: 48.8458714, lng: 18.8686798 },
    { lat: 48.8456785, lng: 18.868771 },
    { lat: 48.8455068, lng: 18.8688948 },
    { lat: 48.8450082, lng: 18.8695226 },
    { lat: 48.8448392, lng: 18.8696779 },
    { lat: 48.84463, lng: 18.8698856 },
    { lat: 48.8445715, lng: 18.8701323 },
    { lat: 48.8442239, lng: 18.8703181 },
    { lat: 48.8441529, lng: 18.8703673 },
    { lat: 48.8438859, lng: 18.870784 },
    { lat: 48.843533, lng: 18.8710481 },
    { lat: 48.8435734, lng: 18.8713096 },
    { lat: 48.8435299, lng: 18.8714275 },
    { lat: 48.8432458, lng: 18.8716688 },
    { lat: 48.8431973, lng: 18.8720858 },
    { lat: 48.8431236, lng: 18.8723147 },
    { lat: 48.8429633, lng: 18.8727077 },
    { lat: 48.8428635, lng: 18.8727381 },
    { lat: 48.8427883, lng: 18.8733377 },
    { lat: 48.8423246, lng: 18.874166 },
    { lat: 48.8421993, lng: 18.8742471 },
    { lat: 48.8417466, lng: 18.8742386 },
    { lat: 48.8415724, lng: 18.8744672 },
    { lat: 48.8414978, lng: 18.8748161 },
    { lat: 48.8415534, lng: 18.8752169 },
    { lat: 48.8414769, lng: 18.8755724 },
    { lat: 48.8412773, lng: 18.8760495 },
    { lat: 48.8412188, lng: 18.8760099 },
    { lat: 48.8411166, lng: 18.8756687 },
    { lat: 48.84109, lng: 18.8760051 },
    { lat: 48.8409836, lng: 18.8762159 },
    { lat: 48.8408304, lng: 18.8762645 },
    { lat: 48.8406431, lng: 18.8761495 },
    { lat: 48.8405899, lng: 18.8762148 },
    { lat: 48.8405312, lng: 18.8766939 },
    { lat: 48.8404634, lng: 18.8768229 },
    { lat: 48.8403988, lng: 18.8768395 },
    { lat: 48.8403074, lng: 18.8769459 },
    { lat: 48.8402854, lng: 18.8771332 },
    { lat: 48.8402484, lng: 18.8772449 },
    { lat: 48.8400576, lng: 18.8773487 },
    { lat: 48.8397084, lng: 18.8777068 },
    { lat: 48.8396071, lng: 18.8778179 },
    { lat: 48.8395426, lng: 18.8779694 },
    { lat: 48.8395147, lng: 18.8781073 },
    { lat: 48.8393926, lng: 18.8783662 },
    { lat: 48.8389248, lng: 18.8786963 },
    { lat: 48.8387376, lng: 18.878824 },
    { lat: 48.8386558, lng: 18.8787918 },
    { lat: 48.8385669, lng: 18.8788807 },
    { lat: 48.8383252, lng: 18.879226 },
    { lat: 48.8382165, lng: 18.8793687 },
    { lat: 48.8381261, lng: 18.8795222 },
    { lat: 48.8380811, lng: 18.8794785 },
    { lat: 48.8380734, lng: 18.8794798 },
    { lat: 48.8380068, lng: 18.8795216 },
    { lat: 48.8379988, lng: 18.8795229 },
    { lat: 48.8379632, lng: 18.8796213 },
    { lat: 48.8378813, lng: 18.879539 },
    { lat: 48.8372533, lng: 18.879999 },
    { lat: 48.8373983, lng: 18.8804747 },
    { lat: 48.8373948, lng: 18.880978 },
    { lat: 48.8372291, lng: 18.8811497 },
    { lat: 48.836949, lng: 18.8818463 },
    { lat: 48.8368807, lng: 18.8819421 },
    { lat: 48.83667, lng: 18.8821867 },
    { lat: 48.8367348, lng: 18.8824815 },
    { lat: 48.8367261, lng: 18.8827492 },
    { lat: 48.8364734, lng: 18.8835797 },
    { lat: 48.8366514, lng: 18.8838833 },
    { lat: 48.8366548, lng: 18.8845437 },
  ],
  Jasenovo: [
    { lat: 48.878425, lng: 18.69659 },
    { lat: 48.877366, lng: 18.697364 },
    { lat: 48.877042, lng: 18.697454 },
    { lat: 48.877021, lng: 18.69746 },
    { lat: 48.876757, lng: 18.697613 },
    { lat: 48.8764941, lng: 18.6979569 },
    { lat: 48.876348, lng: 18.698358 },
    { lat: 48.87585, lng: 18.699967 },
    { lat: 48.874803, lng: 18.699962 },
    { lat: 48.8742, lng: 18.699987 },
    { lat: 48.872883, lng: 18.700103 },
    { lat: 48.872036, lng: 18.699991 },
    { lat: 48.871192, lng: 18.69997 },
    { lat: 48.871182, lng: 18.699982 },
    { lat: 48.871093, lng: 18.699724 },
    { lat: 48.871067, lng: 18.699697 },
    { lat: 48.870907, lng: 18.699614 },
    { lat: 48.870722, lng: 18.699623 },
    { lat: 48.8702, lng: 18.699842 },
    { lat: 48.869956, lng: 18.700051 },
    { lat: 48.86978, lng: 18.700159 },
    { lat: 48.869652, lng: 18.700268 },
    { lat: 48.86945, lng: 18.700325 },
    { lat: 48.86936, lng: 18.700415 },
    { lat: 48.869085, lng: 18.700684 },
    { lat: 48.868991, lng: 18.700683 },
    { lat: 48.868751, lng: 18.700637 },
    { lat: 48.868669, lng: 18.70064 },
    { lat: 48.86852, lng: 18.700675 },
    { lat: 48.868356, lng: 18.700716 },
    { lat: 48.868153, lng: 18.700734 },
    { lat: 48.868012, lng: 18.700714 },
    { lat: 48.867882, lng: 18.700667 },
    { lat: 48.867766, lng: 18.700541 },
    { lat: 48.867567, lng: 18.700252 },
    { lat: 48.867398, lng: 18.699983 },
    { lat: 48.867092, lng: 18.699375 },
    { lat: 48.86678, lng: 18.699159 },
    { lat: 48.866308, lng: 18.699104 },
    { lat: 48.866157, lng: 18.699123 },
    { lat: 48.865922, lng: 18.699154 },
    { lat: 48.865527, lng: 18.699368 },
    { lat: 48.864985, lng: 18.699478 },
    { lat: 48.864801, lng: 18.699516 },
    { lat: 48.864531, lng: 18.699566 },
    { lat: 48.864402, lng: 18.699589 },
    { lat: 48.864124, lng: 18.699635 },
    { lat: 48.864049, lng: 18.699648 },
    { lat: 48.863976, lng: 18.69966 },
    { lat: 48.863824, lng: 18.699681 },
    { lat: 48.863552, lng: 18.699655 },
    { lat: 48.863457, lng: 18.699649 },
    { lat: 48.863067, lng: 18.699709 },
    { lat: 48.862955, lng: 18.699699 },
    { lat: 48.862768, lng: 18.699682 },
    { lat: 48.862436, lng: 18.699924 },
    { lat: 48.862109, lng: 18.69982 },
    { lat: 48.861957, lng: 18.69977 },
    { lat: 48.8617, lng: 18.699702 },
    { lat: 48.861132, lng: 18.699598 },
    { lat: 48.860838, lng: 18.699544 },
    { lat: 48.860695, lng: 18.699466 },
    { lat: 48.860576, lng: 18.699363 },
    { lat: 48.86047, lng: 18.699273 },
    { lat: 48.86023, lng: 18.699257 },
    { lat: 48.860095, lng: 18.699181 },
    { lat: 48.859826, lng: 18.699035 },
    { lat: 48.859464, lng: 18.698921 },
    { lat: 48.859368, lng: 18.69894 },
    { lat: 48.859308, lng: 18.698951 },
    { lat: 48.858786, lng: 18.699052 },
    { lat: 48.858122, lng: 18.698952 },
    { lat: 48.85759, lng: 18.699022 },
    { lat: 48.856965, lng: 18.698962 },
    { lat: 48.856411, lng: 18.698752 },
    { lat: 48.856314, lng: 18.698747 },
    { lat: 48.856253, lng: 18.698754 },
    { lat: 48.856017, lng: 18.698694 },
    { lat: 48.855953, lng: 18.698717 },
    { lat: 48.855859, lng: 18.698724 },
    { lat: 48.855682, lng: 18.698698 },
    { lat: 48.855078, lng: 18.698615 },
    { lat: 48.854952, lng: 18.698621 },
    { lat: 48.854686, lng: 18.698876 },
    { lat: 48.854495, lng: 18.699133 },
    { lat: 48.853895, lng: 18.699825 },
    { lat: 48.853343, lng: 18.700035 },
    { lat: 48.853282, lng: 18.70015 },
    { lat: 48.853252, lng: 18.700206 },
    { lat: 48.853212, lng: 18.70028 },
    { lat: 48.852984, lng: 18.700273 },
    { lat: 48.85283, lng: 18.700184 },
    { lat: 48.852622, lng: 18.700179 },
    { lat: 48.851811, lng: 18.700297 },
    { lat: 48.851495, lng: 18.700049 },
    { lat: 48.851387, lng: 18.699873 },
    { lat: 48.850887, lng: 18.699693 },
    { lat: 48.850781, lng: 18.699557 },
    { lat: 48.850454, lng: 18.698872 },
    { lat: 48.850023, lng: 18.698263 },
    { lat: 48.849628, lng: 18.697987 },
    { lat: 48.849442, lng: 18.697898 },
    { lat: 48.849172, lng: 18.697835 },
    { lat: 48.848992, lng: 18.697936 },
    { lat: 48.848444, lng: 18.698117 },
    { lat: 48.848013, lng: 18.698173 },
    { lat: 48.847707, lng: 18.698074 },
    { lat: 48.847484, lng: 18.698412 },
    { lat: 48.847439, lng: 18.698481 },
    { lat: 48.847071, lng: 18.69904 },
    { lat: 48.846994, lng: 18.699157 },
    { lat: 48.84695, lng: 18.699184 },
    { lat: 48.846402, lng: 18.699531 },
    { lat: 48.846342, lng: 18.699569 },
    { lat: 48.846187, lng: 18.699515 },
    { lat: 48.846154, lng: 18.699503 },
    { lat: 48.846027, lng: 18.699759 },
    { lat: 48.846, lng: 18.700065 },
    { lat: 48.845988, lng: 18.700124 },
    { lat: 48.8465356, lng: 18.7000043 },
    { lat: 48.8466053, lng: 18.7000442 },
    { lat: 48.8474153, lng: 18.7006833 },
    { lat: 48.8475146, lng: 18.7008133 },
    { lat: 48.847735, lng: 18.7010164 },
    { lat: 48.8479951, lng: 18.701113 },
    { lat: 48.8481995, lng: 18.7013316 },
    { lat: 48.8484174, lng: 18.7016982 },
    { lat: 48.8486124, lng: 18.702506 },
    { lat: 48.8486564, lng: 18.7028233 },
    { lat: 48.8485921, lng: 18.7031226 },
    { lat: 48.8487865, lng: 18.7033202 },
    { lat: 48.849123, lng: 18.7034805 },
    { lat: 48.8494306, lng: 18.7036659 },
    { lat: 48.8497085, lng: 18.7037377 },
    { lat: 48.8499055, lng: 18.7036249 },
    { lat: 48.8502949, lng: 18.7031869 },
    { lat: 48.8505674, lng: 18.7030481 },
    { lat: 48.8507866, lng: 18.7031214 },
    { lat: 48.8511278, lng: 18.7032603 },
    { lat: 48.8510129, lng: 18.7030716 },
    { lat: 48.8509323, lng: 18.7029951 },
    { lat: 48.8510934, lng: 18.702842 },
    { lat: 48.8513829, lng: 18.7033067 },
    { lat: 48.8515602, lng: 18.7037578 },
    { lat: 48.8517434, lng: 18.7041061 },
    { lat: 48.8519518, lng: 18.7042763 },
    { lat: 48.8521144, lng: 18.7044904 },
    { lat: 48.8524048, lng: 18.7051179 },
    { lat: 48.8526114, lng: 18.705231 },
    { lat: 48.8528021, lng: 18.7054234 },
    { lat: 48.852888, lng: 18.706237 },
    { lat: 48.8529764, lng: 18.7063717 },
    { lat: 48.8530314, lng: 18.7066876 },
    { lat: 48.8530236, lng: 18.7070718 },
    { lat: 48.8530525, lng: 18.7073193 },
    { lat: 48.8530663, lng: 18.7076868 },
    { lat: 48.853154, lng: 18.7080045 },
    { lat: 48.8532142, lng: 18.7081017 },
    { lat: 48.8533021, lng: 18.7082437 },
    { lat: 48.8535084, lng: 18.7084986 },
    { lat: 48.8537484, lng: 18.7086829 },
    { lat: 48.8540521, lng: 18.709224 },
    { lat: 48.8543576, lng: 18.709415 },
    { lat: 48.8547368, lng: 18.7094635 },
    { lat: 48.8547684, lng: 18.7094621 },
    { lat: 48.8549655, lng: 18.7093096 },
    { lat: 48.8551702, lng: 18.7092339 },
    { lat: 48.8553506, lng: 18.7092775 },
    { lat: 48.8555163, lng: 18.7093584 },
    { lat: 48.8556847, lng: 18.7094937 },
    { lat: 48.85574, lng: 18.7096868 },
    { lat: 48.8558636, lng: 18.7099544 },
    { lat: 48.856067, lng: 18.7102623 },
    { lat: 48.8562107, lng: 18.7105031 },
    { lat: 48.8563824, lng: 18.7106627 },
    { lat: 48.8566098, lng: 18.7106949 },
    { lat: 48.8568166, lng: 18.7109362 },
    { lat: 48.8569912, lng: 18.7112295 },
    { lat: 48.8571315, lng: 18.7116035 },
    { lat: 48.8572283, lng: 18.7119187 },
    { lat: 48.8575309, lng: 18.7122659 },
    { lat: 48.8575895, lng: 18.7123357 },
    { lat: 48.857682, lng: 18.7124457 },
    { lat: 48.857804, lng: 18.7125909 },
    { lat: 48.8579948, lng: 18.7129137 },
    { lat: 48.8581165, lng: 18.7132309 },
    { lat: 48.8585219, lng: 18.713649 },
    { lat: 48.8591685, lng: 18.7139534 },
    { lat: 48.859348, lng: 18.7138714 },
    { lat: 48.8594821, lng: 18.7141506 },
    { lat: 48.859652, lng: 18.714164 },
    { lat: 48.8597146, lng: 18.714449 },
    { lat: 48.8598502, lng: 18.7146675 },
    { lat: 48.8599746, lng: 18.7151023 },
    { lat: 48.8604172, lng: 18.7160109 },
    { lat: 48.8605985, lng: 18.7163023 },
    { lat: 48.8607044, lng: 18.7167672 },
    { lat: 48.8608375, lng: 18.7165351 },
    { lat: 48.8610307, lng: 18.7167752 },
    { lat: 48.8607933, lng: 18.7168932 },
    { lat: 48.8610553, lng: 18.717115 },
    { lat: 48.8612311, lng: 18.7171782 },
    { lat: 48.861425, lng: 18.7173008 },
    { lat: 48.8617615, lng: 18.7176088 },
    { lat: 48.8619256, lng: 18.7177282 },
    { lat: 48.862107, lng: 18.717896 },
    { lat: 48.8622182, lng: 18.718144 },
    { lat: 48.8622214, lng: 18.7181379 },
    { lat: 48.8634005, lng: 18.7201808 },
    { lat: 48.8633258, lng: 18.7223099 },
    { lat: 48.8633539, lng: 18.7231428 },
    { lat: 48.8636157, lng: 18.7241939 },
    { lat: 48.8638435, lng: 18.7246645 },
    { lat: 48.8634991, lng: 18.7264728 },
    { lat: 48.8635125, lng: 18.726882 },
    { lat: 48.8635192, lng: 18.727085 },
    { lat: 48.8637329, lng: 18.7277593 },
    { lat: 48.8668807, lng: 18.7265461 },
    { lat: 48.8674751, lng: 18.7283894 },
    { lat: 48.8681246, lng: 18.7292209 },
    { lat: 48.8683954, lng: 18.7301216 },
    { lat: 48.8684573, lng: 18.7305243 },
    { lat: 48.8686755, lng: 18.7308353 },
    { lat: 48.8690378, lng: 18.7311188 },
    { lat: 48.8696201, lng: 18.7323031 },
    { lat: 48.8704989, lng: 18.7330378 },
    { lat: 48.8711174, lng: 18.7332406 },
    { lat: 48.8713836, lng: 18.7346957 },
    { lat: 48.8716977, lng: 18.7361186 },
    { lat: 48.8725231, lng: 18.7357554 },
    { lat: 48.8725104, lng: 18.735298 },
    { lat: 48.8725755, lng: 18.7352518 },
    { lat: 48.8729769, lng: 18.7338809 },
    { lat: 48.8732534, lng: 18.7350623 },
    { lat: 48.8739463, lng: 18.735265 },
    { lat: 48.8742782, lng: 18.7356343 },
    { lat: 48.8746538, lng: 18.7356354 },
    { lat: 48.8749354, lng: 18.7356934 },
    { lat: 48.87511, lng: 18.7358184 },
    { lat: 48.8752634, lng: 18.736353 },
    { lat: 48.8752626, lng: 18.7368934 },
    { lat: 48.8754083, lng: 18.7370939 },
    { lat: 48.8758471, lng: 18.7374159 },
    { lat: 48.8759107, lng: 18.7375748 },
    { lat: 48.8759655, lng: 18.7376506 },
    { lat: 48.8757237, lng: 18.7385207 },
    { lat: 48.8757704, lng: 18.7388733 },
    { lat: 48.8763902, lng: 18.7401518 },
    { lat: 48.876426, lng: 18.7402303 },
    { lat: 48.876415, lng: 18.740237 },
    { lat: 48.8770014, lng: 18.7406841 },
    { lat: 48.8774354, lng: 18.7406814 },
    { lat: 48.8780622, lng: 18.73997 },
    { lat: 48.8783308, lng: 18.7391823 },
    { lat: 48.8794323, lng: 18.7388548 },
    { lat: 48.8800967, lng: 18.7380009 },
    { lat: 48.8806534, lng: 18.735769 },
    { lat: 48.8813885, lng: 18.7342852 },
    { lat: 48.8820016, lng: 18.7332514 },
    { lat: 48.8822558, lng: 18.7325327 },
    { lat: 48.8826129, lng: 18.731475 },
    { lat: 48.8827676, lng: 18.7310073 },
    { lat: 48.8827292, lng: 18.7302996 },
    { lat: 48.8828398, lng: 18.729681 },
    { lat: 48.8829081, lng: 18.7293725 },
    { lat: 48.8830804, lng: 18.7276786 },
    { lat: 48.8826599, lng: 18.7264218 },
    { lat: 48.8846002, lng: 18.7243992 },
    { lat: 48.8852549, lng: 18.7229807 },
    { lat: 48.8853448, lng: 18.7222481 },
    { lat: 48.885398, lng: 18.7220155 },
    { lat: 48.8862787, lng: 18.7198349 },
    { lat: 48.8877593, lng: 18.7180361 },
    { lat: 48.8884973, lng: 18.7176605 },
    { lat: 48.8888432, lng: 18.7176999 },
    { lat: 48.8888551, lng: 18.718055 },
    { lat: 48.8894861, lng: 18.7179565 },
    { lat: 48.8895825, lng: 18.718009 },
    { lat: 48.8901335, lng: 18.7182589 },
    { lat: 48.8909166, lng: 18.7176441 },
    { lat: 48.8930316, lng: 18.7160551 },
    { lat: 48.8930171, lng: 18.7153104 },
    { lat: 48.8925057, lng: 18.7137006 },
    { lat: 48.8922555, lng: 18.7119072 },
    { lat: 48.8923016, lng: 18.7110938 },
    { lat: 48.8923923, lng: 18.7100165 },
    { lat: 48.8940957, lng: 18.7095116 },
    { lat: 48.8948875, lng: 18.7090662 },
    { lat: 48.8952202, lng: 18.7086186 },
    { lat: 48.8951511, lng: 18.7082101 },
    { lat: 48.8952984, lng: 18.7081137 },
    { lat: 48.895808, lng: 18.707418 },
    { lat: 48.895694, lng: 18.707328 },
    { lat: 48.895623, lng: 18.707077 },
    { lat: 48.895251, lng: 18.706989 },
    { lat: 48.894445, lng: 18.70724 },
    { lat: 48.893728, lng: 18.70729 },
    { lat: 48.893051, lng: 18.706648 },
    { lat: 48.892731, lng: 18.706639 },
    { lat: 48.892575, lng: 18.70632 },
    { lat: 48.892093, lng: 18.705283 },
    { lat: 48.892043, lng: 18.705287 },
    { lat: 48.891787, lng: 18.705333 },
    { lat: 48.891401, lng: 18.705169 },
    { lat: 48.891235, lng: 18.705079 },
    { lat: 48.891057, lng: 18.705021 },
    { lat: 48.891004, lng: 18.704929 },
    { lat: 48.890959, lng: 18.704795 },
    { lat: 48.890881, lng: 18.70467 },
    { lat: 48.890757, lng: 18.704533 },
    { lat: 48.890561, lng: 18.704145 },
    { lat: 48.890396, lng: 18.703808 },
    { lat: 48.890237, lng: 18.703421 },
    { lat: 48.890134, lng: 18.70316 },
    { lat: 48.890046, lng: 18.703002 },
    { lat: 48.889847, lng: 18.702814 },
    { lat: 48.889801, lng: 18.702802 },
    { lat: 48.889801, lng: 18.702792 },
    { lat: 48.889257, lng: 18.702962 },
    { lat: 48.888981, lng: 18.703056 },
    { lat: 48.888751, lng: 18.703176 },
    { lat: 48.888395, lng: 18.703189 },
    { lat: 48.888245, lng: 18.703211 },
    { lat: 48.888048, lng: 18.703445 },
    { lat: 48.887863, lng: 18.703609 },
    { lat: 48.887649, lng: 18.703732 },
    { lat: 48.887491, lng: 18.703894 },
    { lat: 48.887221, lng: 18.704016 },
    { lat: 48.887086, lng: 18.704106 },
    { lat: 48.886873, lng: 18.703769 },
    { lat: 48.886845, lng: 18.703668 },
    { lat: 48.88682, lng: 18.703577 },
    { lat: 48.886806, lng: 18.703529 },
    { lat: 48.886793, lng: 18.70348 },
    { lat: 48.886453, lng: 18.702318 },
    { lat: 48.886216, lng: 18.70231 },
    { lat: 48.885665, lng: 18.7017 },
    { lat: 48.885649, lng: 18.701688 },
    { lat: 48.885572, lng: 18.701636 },
    { lat: 48.885402, lng: 18.70152 },
    { lat: 48.884489, lng: 18.701024 },
    { lat: 48.8842, lng: 18.700864 },
    { lat: 48.884101, lng: 18.700799 },
    { lat: 48.883448, lng: 18.700284 },
    { lat: 48.882714, lng: 18.699874 },
    { lat: 48.882768, lng: 18.699704 },
    { lat: 48.882831, lng: 18.699263 },
    { lat: 48.882819, lng: 18.698819 },
    { lat: 48.882739, lng: 18.698754 },
    { lat: 48.882549, lng: 18.698613 },
    { lat: 48.882485, lng: 18.698669 },
    { lat: 48.882143, lng: 18.698441 },
    { lat: 48.882187, lng: 18.697924 },
    { lat: 48.882142, lng: 18.697555 },
    { lat: 48.882091, lng: 18.697159 },
    { lat: 48.882022, lng: 18.696838 },
    { lat: 48.8818, lng: 18.69623 },
    { lat: 48.881721, lng: 18.695868 },
    { lat: 48.881597, lng: 18.695804 },
    { lat: 48.881361, lng: 18.695791 },
    { lat: 48.881197, lng: 18.695715 },
    { lat: 48.881156, lng: 18.695696 },
    { lat: 48.881114, lng: 18.695675 },
    { lat: 48.880702, lng: 18.695423 },
    { lat: 48.88054, lng: 18.69542 },
    { lat: 48.880484, lng: 18.695408 },
    { lat: 48.879331, lng: 18.696137 },
    { lat: 48.879084, lng: 18.696265 },
    { lat: 48.878932, lng: 18.696151 },
    { lat: 48.878425, lng: 18.69659 },
  ],
  Jazernica: [
    { lat: 48.9185643, lng: 18.8339487 },
    { lat: 48.9196676, lng: 18.8376862 },
    { lat: 48.9192204, lng: 18.8378703 },
    { lat: 48.9191472, lng: 18.8382182 },
    { lat: 48.9196311, lng: 18.8399683 },
    { lat: 48.919655, lng: 18.8401014 },
    { lat: 48.9199832, lng: 18.8401075 },
    { lat: 48.920058, lng: 18.8400261 },
    { lat: 48.9200741, lng: 18.8397266 },
    { lat: 48.9201397, lng: 18.8397063 },
    { lat: 48.9201641, lng: 18.8398529 },
    { lat: 48.920344, lng: 18.8400201 },
    { lat: 48.920448, lng: 18.8400209 },
    { lat: 48.9205545, lng: 18.8400742 },
    { lat: 48.9207141, lng: 18.8398932 },
    { lat: 48.9211732, lng: 18.8390207 },
    { lat: 48.9212628, lng: 18.8389896 },
    { lat: 48.9214521, lng: 18.8393141 },
    { lat: 48.9217187, lng: 18.8392329 },
    { lat: 48.9218161, lng: 18.838914 },
    { lat: 48.9219494, lng: 18.8387048 },
    { lat: 48.9221465, lng: 18.8386548 },
    { lat: 48.9222647, lng: 18.8387168 },
    { lat: 48.9226513, lng: 18.838428 },
    { lat: 48.9227999, lng: 18.8380535 },
    { lat: 48.9227098, lng: 18.8374042 },
    { lat: 48.9227865, lng: 18.8370544 },
    { lat: 48.9228694, lng: 18.8369979 },
    { lat: 48.9230772, lng: 18.8372471 },
    { lat: 48.9231208, lng: 18.8375018 },
    { lat: 48.9233898, lng: 18.8379929 },
    { lat: 48.9235219, lng: 18.8380202 },
    { lat: 48.9235846, lng: 18.8378988 },
    { lat: 48.9235955, lng: 18.8373453 },
    { lat: 48.9238014, lng: 18.8369044 },
    { lat: 48.9240222, lng: 18.8370531 },
    { lat: 48.9242974, lng: 18.8371197 },
    { lat: 48.9244879, lng: 18.8371805 },
    { lat: 48.9245267, lng: 18.8370167 },
    { lat: 48.9244787, lng: 18.8367879 },
    { lat: 48.9245013, lng: 18.8363302 },
    { lat: 48.924544, lng: 18.8361551 },
    { lat: 48.9246551, lng: 18.8360999 },
    { lat: 48.9248125, lng: 18.8360683 },
    { lat: 48.9248161, lng: 18.8360696 },
    { lat: 48.9249062, lng: 18.8360722 },
    { lat: 48.9250895, lng: 18.8361253 },
    { lat: 48.9251624, lng: 18.8365763 },
    { lat: 48.9252675, lng: 18.8366018 },
    { lat: 48.925383, lng: 18.8363721 },
    { lat: 48.9254879, lng: 18.8365235 },
    { lat: 48.9256836, lng: 18.8365784 },
    { lat: 48.9261194, lng: 18.8367452 },
    { lat: 48.9263355, lng: 18.8369303 },
    { lat: 48.9265824, lng: 18.8367793 },
    { lat: 48.9268643, lng: 18.8367156 },
    { lat: 48.9272349, lng: 18.8367722 },
    { lat: 48.9277204, lng: 18.8366516 },
    { lat: 48.9279704, lng: 18.8366702 },
    { lat: 48.928385, lng: 18.8365312 },
    { lat: 48.928412, lng: 18.8365283 },
    { lat: 48.9284599, lng: 18.8365413 },
    { lat: 48.9285855, lng: 18.8365489 },
    { lat: 48.9288173, lng: 18.8365392 },
    { lat: 48.9289735, lng: 18.8365532 },
    { lat: 48.929091, lng: 18.8365772 },
    { lat: 48.9292513, lng: 18.8366654 },
    { lat: 48.9295007, lng: 18.8369329 },
    { lat: 48.9296055, lng: 18.8370628 },
    { lat: 48.9297253, lng: 18.8371773 },
    { lat: 48.9302505, lng: 18.8378492 },
    { lat: 48.930906, lng: 18.8386619 },
    { lat: 48.9312396, lng: 18.8390722 },
    { lat: 48.9312942, lng: 18.8391052 },
    { lat: 48.9325772, lng: 18.8396434 },
    { lat: 48.932638, lng: 18.8396864 },
    { lat: 48.9329013, lng: 18.8397429 },
    { lat: 48.9331135, lng: 18.8398998 },
    { lat: 48.9335083, lng: 18.8400314 },
    { lat: 48.9335406, lng: 18.8398737 },
    { lat: 48.9333238, lng: 18.8355482 },
    { lat: 48.9332979, lng: 18.8347885 },
    { lat: 48.9332909, lng: 18.8347391 },
    { lat: 48.9332745, lng: 18.8343985 },
    { lat: 48.9332698, lng: 18.8340487 },
    { lat: 48.9332324, lng: 18.833696 },
    { lat: 48.9331442, lng: 18.8327529 },
    { lat: 48.9329994, lng: 18.8316544 },
    { lat: 48.9329333, lng: 18.8314686 },
    { lat: 48.9325954, lng: 18.8291864 },
    { lat: 48.9325581, lng: 18.8283607 },
    { lat: 48.9326785, lng: 18.8275702 },
    { lat: 48.9327084, lng: 18.8273432 },
    { lat: 48.9326796, lng: 18.8272204 },
    { lat: 48.9325885, lng: 18.8268762 },
    { lat: 48.9322907, lng: 18.8268869 },
    { lat: 48.9321675, lng: 18.8268913 },
    { lat: 48.9321854, lng: 18.8266489 },
    { lat: 48.9323875, lng: 18.8266546 },
    { lat: 48.9325963, lng: 18.8265475 },
    { lat: 48.9327163, lng: 18.8264288 },
    { lat: 48.9326916, lng: 18.8261667 },
    { lat: 48.9324284, lng: 18.8260067 },
    { lat: 48.9322879, lng: 18.8258183 },
    { lat: 48.9322568, lng: 18.8255233 },
    { lat: 48.9324985, lng: 18.824856 },
    { lat: 48.9325249, lng: 18.8245412 },
    { lat: 48.9324673, lng: 18.823909 },
    { lat: 48.932283, lng: 18.8234853 },
    { lat: 48.9318502, lng: 18.8228799 },
    { lat: 48.9316143, lng: 18.8228514 },
    { lat: 48.931328, lng: 18.8225518 },
    { lat: 48.9311309, lng: 18.8223999 },
    { lat: 48.9309102, lng: 18.8223226 },
    { lat: 48.9306517, lng: 18.8224909 },
    { lat: 48.9304681, lng: 18.8224741 },
    { lat: 48.9302028, lng: 18.8225334 },
    { lat: 48.9301098, lng: 18.8226313 },
    { lat: 48.9298819, lng: 18.8221277 },
    { lat: 48.9301029, lng: 18.821368 },
    { lat: 48.9300875, lng: 18.8211896 },
    { lat: 48.9300803, lng: 18.821106 },
    { lat: 48.9300366, lng: 18.8209775 },
    { lat: 48.929559, lng: 18.8208584 },
    { lat: 48.9292464, lng: 18.821286 },
    { lat: 48.9289663, lng: 18.8213904 },
    { lat: 48.9285872, lng: 18.8213199 },
    { lat: 48.9283514, lng: 18.8211426 },
    { lat: 48.9280927, lng: 18.8208066 },
    { lat: 48.927807, lng: 18.8206051 },
    { lat: 48.9275779, lng: 18.8205787 },
    { lat: 48.9272246, lng: 18.8205291 },
    { lat: 48.9270643, lng: 18.8205673 },
    { lat: 48.9265619, lng: 18.8201203 },
    { lat: 48.9264525, lng: 18.8196872 },
    { lat: 48.9265438, lng: 18.8193568 },
    { lat: 48.9269214, lng: 18.8189015 },
    { lat: 48.9274523, lng: 18.818417 },
    { lat: 48.9278093, lng: 18.8179939 },
    { lat: 48.9279038, lng: 18.8176419 },
    { lat: 48.9278745, lng: 18.817361 },
    { lat: 48.9277024, lng: 18.817131 },
    { lat: 48.927438, lng: 18.8171228 },
    { lat: 48.9271849, lng: 18.8170444 },
    { lat: 48.9270457, lng: 18.8171205 },
    { lat: 48.9268117, lng: 18.8170241 },
    { lat: 48.9267298, lng: 18.8168398 },
    { lat: 48.9266733, lng: 18.8165981 },
    { lat: 48.9265127, lng: 18.8161077 },
    { lat: 48.9264867, lng: 18.8157791 },
    { lat: 48.926449, lng: 18.8154674 },
    { lat: 48.9264653, lng: 18.8143936 },
    { lat: 48.9264086, lng: 18.8140604 },
    { lat: 48.926413, lng: 18.814014 },
    { lat: 48.9261579, lng: 18.8133553 },
    { lat: 48.9260113, lng: 18.8134001 },
    { lat: 48.9259045, lng: 18.8133393 },
    { lat: 48.9257996, lng: 18.8128621 },
    { lat: 48.9258916, lng: 18.8125502 },
    { lat: 48.9262165, lng: 18.8122904 },
    { lat: 48.9263138, lng: 18.812126 },
    { lat: 48.9264413, lng: 18.8118884 },
    { lat: 48.9264252, lng: 18.8114905 },
    { lat: 48.9262754, lng: 18.810972 },
    { lat: 48.9260913, lng: 18.8102543 },
    { lat: 48.9260284, lng: 18.809607 },
    { lat: 48.9259128, lng: 18.809334 },
    { lat: 48.92582, lng: 18.8091988 },
    { lat: 48.9257617, lng: 18.80903 },
    { lat: 48.9255582, lng: 18.8088661 },
    { lat: 48.9251349, lng: 18.8088594 },
    { lat: 48.9249775, lng: 18.8090192 },
    { lat: 48.9238506, lng: 18.8101179 },
    { lat: 48.923595, lng: 18.8101697 },
    { lat: 48.9234938, lng: 18.8101768 },
    { lat: 48.9229802, lng: 18.8100345 },
    { lat: 48.9225244, lng: 18.809747 },
    { lat: 48.9222252, lng: 18.8098506 },
    { lat: 48.9216148, lng: 18.8103421 },
    { lat: 48.9210321, lng: 18.8105183 },
    { lat: 48.9203938, lng: 18.8108044 },
    { lat: 48.920148, lng: 18.8114527 },
    { lat: 48.9199717, lng: 18.8115114 },
    { lat: 48.9195772, lng: 18.8113173 },
    { lat: 48.9193207, lng: 18.8113294 },
    { lat: 48.9192207, lng: 18.8115324 },
    { lat: 48.9190224, lng: 18.8115364 },
    { lat: 48.9188566, lng: 18.8112978 },
    { lat: 48.9186557, lng: 18.8112279 },
    { lat: 48.9182748, lng: 18.8108643 },
    { lat: 48.9178177, lng: 18.8106569 },
    { lat: 48.9172721, lng: 18.8109033 },
    { lat: 48.9172834, lng: 18.8110346 },
    { lat: 48.9172943, lng: 18.8111573 },
    { lat: 48.9170851, lng: 18.8129653 },
    { lat: 48.9169812, lng: 18.8145276 },
    { lat: 48.9168184, lng: 18.817127 },
    { lat: 48.9167283, lng: 18.8176043 },
    { lat: 48.9167235, lng: 18.8177518 },
    { lat: 48.9166313, lng: 18.8196469 },
    { lat: 48.9163579, lng: 18.8238136 },
    { lat: 48.9163725, lng: 18.8239187 },
    { lat: 48.9162022, lng: 18.8262891 },
    { lat: 48.9161294, lng: 18.827649 },
    { lat: 48.9160599, lng: 18.8293531 },
    { lat: 48.9160694, lng: 18.8293631 },
    { lat: 48.9161517, lng: 18.8291005 },
    { lat: 48.9161825, lng: 18.8288918 },
    { lat: 48.9163193, lng: 18.8285087 },
    { lat: 48.9163194, lng: 18.8282047 },
    { lat: 48.9164264, lng: 18.8279395 },
    { lat: 48.9165567, lng: 18.8278232 },
    { lat: 48.9166331, lng: 18.8276015 },
    { lat: 48.9166641, lng: 18.8274336 },
    { lat: 48.9167405, lng: 18.8272796 },
    { lat: 48.9168082, lng: 18.8272551 },
    { lat: 48.9169428, lng: 18.8272856 },
    { lat: 48.9170944, lng: 18.8273604 },
    { lat: 48.9170806, lng: 18.8274452 },
    { lat: 48.9176607, lng: 18.8308089 },
    { lat: 48.918561, lng: 18.8322966 },
    { lat: 48.9195472, lng: 18.8334422 },
    { lat: 48.9194813, lng: 18.8335185 },
    { lat: 48.919563, lng: 18.8336375 },
    { lat: 48.9185643, lng: 18.8339487 },
  ],
  Kaľamenová: [
    { lat: 48.8783373, lng: 18.7889265 },
    { lat: 48.878364, lng: 18.7889631 },
    { lat: 48.8785443, lng: 18.7887007 },
    { lat: 48.8787916, lng: 18.788599 },
    { lat: 48.8791696, lng: 18.7885802 },
    { lat: 48.8793491, lng: 18.7887356 },
    { lat: 48.8794455, lng: 18.7889 },
    { lat: 48.8795019, lng: 18.7890297 },
    { lat: 48.879436, lng: 18.7894292 },
    { lat: 48.8794609, lng: 18.7894971 },
    { lat: 48.8795506, lng: 18.7896139 },
    { lat: 48.8796603, lng: 18.7895785 },
    { lat: 48.8800453, lng: 18.7898702 },
    { lat: 48.8802621, lng: 18.789918 },
    { lat: 48.8804037, lng: 18.789972 },
    { lat: 48.8805423, lng: 18.7901019 },
    { lat: 48.8806323, lng: 18.7904002 },
    { lat: 48.8808137, lng: 18.790808 },
    { lat: 48.880844, lng: 18.7908714 },
    { lat: 48.8808632, lng: 18.7908636 },
    { lat: 48.881161, lng: 18.7907427 },
    { lat: 48.8810014, lng: 18.7901929 },
    { lat: 48.8810227, lng: 18.7900797 },
    { lat: 48.881304, lng: 18.7900037 },
    { lat: 48.8824878, lng: 18.7899756 },
    { lat: 48.8828221, lng: 18.790053 },
    { lat: 48.8828213, lng: 18.7902308 },
    { lat: 48.882698, lng: 18.7905494 },
    { lat: 48.8827184, lng: 18.7906785 },
    { lat: 48.8830348, lng: 18.7907365 },
    { lat: 48.883238, lng: 18.7908494 },
    { lat: 48.8834719, lng: 18.7909446 },
    { lat: 48.8835201, lng: 18.7910405 },
    { lat: 48.8835762, lng: 18.7911763 },
    { lat: 48.883633, lng: 18.7912649 },
    { lat: 48.8838942, lng: 18.7914933 },
    { lat: 48.883958, lng: 18.7915706 },
    { lat: 48.8840038, lng: 18.7916912 },
    { lat: 48.8839954, lng: 18.7918345 },
    { lat: 48.8839171, lng: 18.7920639 },
    { lat: 48.8839041, lng: 18.7922267 },
    { lat: 48.8839156, lng: 18.7923159 },
    { lat: 48.8838904, lng: 18.7924401 },
    { lat: 48.88365, lng: 18.7923697 },
    { lat: 48.8836021, lng: 18.7924657 },
    { lat: 48.8836155, lng: 18.7925515 },
    { lat: 48.8837297, lng: 18.7927922 },
    { lat: 48.8839184, lng: 18.7927807 },
    { lat: 48.8841706, lng: 18.7927511 },
    { lat: 48.8842781, lng: 18.792692 },
    { lat: 48.88434, lng: 18.7926054 },
    { lat: 48.8844805, lng: 18.7924445 },
    { lat: 48.8846328, lng: 18.7923607 },
    { lat: 48.8847365, lng: 18.7923569 },
    { lat: 48.8849983, lng: 18.792491 },
    { lat: 48.8850567, lng: 18.7924647 },
    { lat: 48.8854186, lng: 18.7920655 },
    { lat: 48.8854671, lng: 18.792012 },
    { lat: 48.8855683, lng: 18.7917673 },
    { lat: 48.8855682, lng: 18.7915484 },
    { lat: 48.885865, lng: 18.7912731 },
    { lat: 48.885986, lng: 18.7911274 },
    { lat: 48.8857779, lng: 18.7908342 },
    { lat: 48.8857212, lng: 18.7907822 },
    { lat: 48.8855635, lng: 18.7906378 },
    { lat: 48.885576, lng: 18.7905298 },
    { lat: 48.8859142, lng: 18.7903237 },
    { lat: 48.8860011, lng: 18.7902707 },
    { lat: 48.8859613, lng: 18.7902428 },
    { lat: 48.8857123, lng: 18.7901198 },
    { lat: 48.8856603, lng: 18.7900941 },
    { lat: 48.8855546, lng: 18.7897294 },
    { lat: 48.8858081, lng: 18.7894034 },
    { lat: 48.8860592, lng: 18.7892078 },
    { lat: 48.8861126, lng: 18.7891662 },
    { lat: 48.8864263, lng: 18.7893622 },
    { lat: 48.8864294, lng: 18.7890143 },
    { lat: 48.8867016, lng: 18.7891733 },
    { lat: 48.886695, lng: 18.7894812 },
    { lat: 48.8867621, lng: 18.7895351 },
    { lat: 48.8870962, lng: 18.7892994 },
    { lat: 48.887289, lng: 18.7894074 },
    { lat: 48.8872894, lng: 18.7893683 },
    { lat: 48.8872934, lng: 18.7889525 },
    { lat: 48.8871068, lng: 18.7886009 },
    { lat: 48.8870719, lng: 18.7885351 },
    { lat: 48.8870405, lng: 18.7880424 },
    { lat: 48.8870381, lng: 18.7879508 },
    { lat: 48.8870957, lng: 18.7879431 },
    { lat: 48.8874768, lng: 18.7880802 },
    { lat: 48.8877262, lng: 18.787956 },
    { lat: 48.8878308, lng: 18.7876915 },
    { lat: 48.8882719, lng: 18.7875454 },
    { lat: 48.8882962, lng: 18.7875328 },
    { lat: 48.8884204, lng: 18.7874688 },
    { lat: 48.8886133, lng: 18.7874937 },
    { lat: 48.8887837, lng: 18.7876485 },
    { lat: 48.8889188, lng: 18.7879694 },
    { lat: 48.8891699, lng: 18.7882997 },
    { lat: 48.8891759, lng: 18.7883353 },
    { lat: 48.8893265, lng: 18.7883955 },
    { lat: 48.8895424, lng: 18.788593 },
    { lat: 48.8893546, lng: 18.7891419 },
    { lat: 48.8894871, lng: 18.7894407 },
    { lat: 48.8895718, lng: 18.7896876 },
    { lat: 48.8897801, lng: 18.790211 },
    { lat: 48.8899825, lng: 18.7903706 },
    { lat: 48.890195, lng: 18.7902734 },
    { lat: 48.8901733, lng: 18.7899259 },
    { lat: 48.8902484, lng: 18.7897847 },
    { lat: 48.8905361, lng: 18.7900892 },
    { lat: 48.8905322, lng: 18.7901298 },
    { lat: 48.8904019, lng: 18.7905256 },
    { lat: 48.8903755, lng: 18.7906662 },
    { lat: 48.8903631, lng: 18.790732 },
    { lat: 48.8904287, lng: 18.7908787 },
    { lat: 48.890491, lng: 18.791018 },
    { lat: 48.8905138, lng: 18.7910532 },
    { lat: 48.8906339, lng: 18.7912387 },
    { lat: 48.8906662, lng: 18.7912887 },
    { lat: 48.8908285, lng: 18.7914137 },
    { lat: 48.8909948, lng: 18.7912959 },
    { lat: 48.8911683, lng: 18.7912733 },
    { lat: 48.8913429, lng: 18.7911595 },
    { lat: 48.8914698, lng: 18.7909346 },
    { lat: 48.891332, lng: 18.7906068 },
    { lat: 48.8912732, lng: 18.7902362 },
    { lat: 48.8913909, lng: 18.7900701 },
    { lat: 48.8917312, lng: 18.790174 },
    { lat: 48.8917704, lng: 18.7901761 },
    { lat: 48.8918151, lng: 18.7901457 },
    { lat: 48.8918373, lng: 18.7900804 },
    { lat: 48.8918374, lng: 18.7900078 },
    { lat: 48.8918616, lng: 18.7898932 },
    { lat: 48.891903, lng: 18.7898485 },
    { lat: 48.8920692, lng: 18.7897264 },
    { lat: 48.8921552, lng: 18.7897171 },
    { lat: 48.8922087, lng: 18.7897724 },
    { lat: 48.8922461, lng: 18.7898399 },
    { lat: 48.8922384, lng: 18.7899411 },
    { lat: 48.8921587, lng: 18.7903552 },
    { lat: 48.8921449, lng: 18.7904374 },
    { lat: 48.8921233, lng: 18.7904737 },
    { lat: 48.8921165, lng: 18.790509 },
    { lat: 48.8921215, lng: 18.7905518 },
    { lat: 48.8921376, lng: 18.7905683 },
    { lat: 48.8921706, lng: 18.7905836 },
    { lat: 48.8923258, lng: 18.7905826 },
    { lat: 48.8924451, lng: 18.7905915 },
    { lat: 48.8925046, lng: 18.7906163 },
    { lat: 48.8925442, lng: 18.7906524 },
    { lat: 48.8925555, lng: 18.7907111 },
    { lat: 48.8925449, lng: 18.7907639 },
    { lat: 48.8924815, lng: 18.7908228 },
    { lat: 48.892414, lng: 18.7909322 },
    { lat: 48.8923561, lng: 18.7910954 },
    { lat: 48.8923005, lng: 18.7913215 },
    { lat: 48.8922701, lng: 18.7913753 },
    { lat: 48.8921531, lng: 18.7914965 },
    { lat: 48.8921081, lng: 18.7915834 },
    { lat: 48.8920913, lng: 18.7917493 },
    { lat: 48.8921475, lng: 18.7918792 },
    { lat: 48.8921901, lng: 18.7919677 },
    { lat: 48.8922947, lng: 18.7922329 },
    { lat: 48.8923292, lng: 18.7922596 },
    { lat: 48.8923727, lng: 18.7922306 },
    { lat: 48.8925887, lng: 18.791935 },
    { lat: 48.8926613, lng: 18.7916607 },
    { lat: 48.8927397, lng: 18.7914977 },
    { lat: 48.8927684, lng: 18.7914545 },
    { lat: 48.8927963, lng: 18.7914354 },
    { lat: 48.8928626, lng: 18.7914158 },
    { lat: 48.8929191, lng: 18.7914212 },
    { lat: 48.8929693, lng: 18.7914583 },
    { lat: 48.8929808, lng: 18.7915045 },
    { lat: 48.8929826, lng: 18.7915975 },
    { lat: 48.8929963, lng: 18.7916661 },
    { lat: 48.8930179, lng: 18.7916909 },
    { lat: 48.8930512, lng: 18.7916912 },
    { lat: 48.8930723, lng: 18.7916711 },
    { lat: 48.8930922, lng: 18.7916125 },
    { lat: 48.893128, lng: 18.7914959 },
    { lat: 48.8931379, lng: 18.7914423 },
    { lat: 48.8931199, lng: 18.7913864 },
    { lat: 48.8930741, lng: 18.7913101 },
    { lat: 48.8930583, lng: 18.7911777 },
    { lat: 48.8930253, lng: 18.7910868 },
    { lat: 48.892986, lng: 18.7910538 },
    { lat: 48.89295, lng: 18.7909407 },
    { lat: 48.8929384, lng: 18.790848 },
    { lat: 48.8929582, lng: 18.7907098 },
    { lat: 48.8930091, lng: 18.7905888 },
    { lat: 48.8930383, lng: 18.7905454 },
    { lat: 48.8930812, lng: 18.7905084 },
    { lat: 48.8931164, lng: 18.7905154 },
    { lat: 48.8932783, lng: 18.7906463 },
    { lat: 48.8933363, lng: 18.7907468 },
    { lat: 48.8933804, lng: 18.7908001 },
    { lat: 48.8934182, lng: 18.7908552 },
    { lat: 48.893441, lng: 18.7909096 },
    { lat: 48.8934548, lng: 18.7910101 },
    { lat: 48.8934518, lng: 18.7911196 },
    { lat: 48.8934365, lng: 18.7911781 },
    { lat: 48.8934096, lng: 18.7911997 },
    { lat: 48.8933783, lng: 18.7912007 },
    { lat: 48.8933621, lng: 18.7911918 },
    { lat: 48.8933425, lng: 18.791194 },
    { lat: 48.8933211, lng: 18.7912198 },
    { lat: 48.8933109, lng: 18.7912682 },
    { lat: 48.8933228, lng: 18.7913133 },
    { lat: 48.8933362, lng: 18.7913653 },
    { lat: 48.8935865, lng: 18.7913827 },
    { lat: 48.8940156, lng: 18.791883 },
    { lat: 48.8940455, lng: 18.7919307 },
    { lat: 48.8940674, lng: 18.7920626 },
    { lat: 48.894068, lng: 18.792146 },
    { lat: 48.8940509, lng: 18.7921983 },
    { lat: 48.8940498, lng: 18.7922507 },
    { lat: 48.8940628, lng: 18.7923019 },
    { lat: 48.8940811, lng: 18.7923287 },
    { lat: 48.8941384, lng: 18.7924009 },
    { lat: 48.8942012, lng: 18.7924724 },
    { lat: 48.8942408, lng: 18.7924959 },
    { lat: 48.8942862, lng: 18.7925016 },
    { lat: 48.8943264, lng: 18.7925052 },
    { lat: 48.8943505, lng: 18.7924854 },
    { lat: 48.8943817, lng: 18.7924568 },
    { lat: 48.8944178, lng: 18.7923309 },
    { lat: 48.8944357, lng: 18.7922672 },
    { lat: 48.8944443, lng: 18.7922116 },
    { lat: 48.8944263, lng: 18.7920311 },
    { lat: 48.8944009, lng: 18.7919073 },
    { lat: 48.8943639, lng: 18.7918112 },
    { lat: 48.8943413, lng: 18.7917686 },
    { lat: 48.8943224, lng: 18.7917248 },
    { lat: 48.8943016, lng: 18.791706 },
    { lat: 48.894271, lng: 18.791716 },
    { lat: 48.8942354, lng: 18.7917056 },
    { lat: 48.8942184, lng: 18.791685 },
    { lat: 48.8942046, lng: 18.7916373 },
    { lat: 48.8942168, lng: 18.7915857 },
    { lat: 48.8942336, lng: 18.7915552 },
    { lat: 48.8942781, lng: 18.7915239 },
    { lat: 48.8943231, lng: 18.7915285 },
    { lat: 48.8943867, lng: 18.7915741 },
    { lat: 48.8945142, lng: 18.7916891 },
    { lat: 48.8945556, lng: 18.7917544 },
    { lat: 48.8946001, lng: 18.7918365 },
    { lat: 48.8946406, lng: 18.792023 },
    { lat: 48.8946721, lng: 18.7920803 },
    { lat: 48.894706, lng: 18.7921045 },
    { lat: 48.8948717, lng: 18.7921197 },
    { lat: 48.8949807, lng: 18.7920839 },
    { lat: 48.8950148, lng: 18.7921013 },
    { lat: 48.8950397, lng: 18.7921355 },
    { lat: 48.8950719, lng: 18.7921959 },
    { lat: 48.895074, lng: 18.7922568 },
    { lat: 48.8950785, lng: 18.7924004 },
    { lat: 48.8950409, lng: 18.7925532 },
    { lat: 48.8950169, lng: 18.7927391 },
    { lat: 48.8950262, lng: 18.7928155 },
    { lat: 48.895059, lng: 18.7928724 },
    { lat: 48.8951903, lng: 18.7929397 },
    { lat: 48.8952935, lng: 18.7929503 },
    { lat: 48.8953355, lng: 18.7929453 },
    { lat: 48.8953746, lng: 18.7929009 },
    { lat: 48.895387, lng: 18.7928504 },
    { lat: 48.8953881, lng: 18.7927533 },
    { lat: 48.8954059, lng: 18.7926587 },
    { lat: 48.8954237, lng: 18.7926112 },
    { lat: 48.8954522, lng: 18.7925902 },
    { lat: 48.8955351, lng: 18.7926073 },
    { lat: 48.8956297, lng: 18.7926523 },
    { lat: 48.8956656, lng: 18.7926922 },
    { lat: 48.8956881, lng: 18.7927667 },
    { lat: 48.8956968, lng: 18.7928466 },
    { lat: 48.8956774, lng: 18.7929264 },
    { lat: 48.8956568, lng: 18.7930123 },
    { lat: 48.8956131, lng: 18.7930648 },
    { lat: 48.8955636, lng: 18.7930847 },
    { lat: 48.8954517, lng: 18.7931835 },
    { lat: 48.8954256, lng: 18.793263 },
    { lat: 48.8954303, lng: 18.7933398 },
    { lat: 48.8954626, lng: 18.7933823 },
    { lat: 48.8955731, lng: 18.7933541 },
    { lat: 48.8956018, lng: 18.7933528 },
    { lat: 48.8956372, lng: 18.7933749 },
    { lat: 48.895653, lng: 18.793424 },
    { lat: 48.8956602, lng: 18.7935122 },
    { lat: 48.8956353, lng: 18.7935584 },
    { lat: 48.89553, lng: 18.7936538 },
    { lat: 48.89544, lng: 18.7937519 },
    { lat: 48.895383, lng: 18.793881 },
    { lat: 48.8953616, lng: 18.7939834 },
    { lat: 48.8953447, lng: 18.7943483 },
    { lat: 48.8953439, lng: 18.7945296 },
    { lat: 48.8953695, lng: 18.7946082 },
    { lat: 48.8954776, lng: 18.7947228 },
    { lat: 48.8955707, lng: 18.7948767 },
    { lat: 48.8956219, lng: 18.7949891 },
    { lat: 48.8956562, lng: 18.7951261 },
    { lat: 48.895697, lng: 18.795255 },
    { lat: 48.8957506, lng: 18.7953787 },
    { lat: 48.8960415, lng: 18.7959124 },
    { lat: 48.89612, lng: 18.7960152 },
    { lat: 48.8961725, lng: 18.7960904 },
    { lat: 48.8961998, lng: 18.7961121 },
    { lat: 48.8962245, lng: 18.7961281 },
    { lat: 48.8962514, lng: 18.7961174 },
    { lat: 48.8962808, lng: 18.7960533 },
    { lat: 48.8962944, lng: 18.7959722 },
    { lat: 48.8962878, lng: 18.7958378 },
    { lat: 48.8963111, lng: 18.7956571 },
    { lat: 48.8963123, lng: 18.7952907 },
    { lat: 48.8963214, lng: 18.7952159 },
    { lat: 48.8963596, lng: 18.7951405 },
    { lat: 48.8964138, lng: 18.795096 },
    { lat: 48.8964641, lng: 18.7950905 },
    { lat: 48.8965222, lng: 18.7950901 },
    { lat: 48.8965611, lng: 18.7950474 },
    { lat: 48.8965938, lng: 18.794951 },
    { lat: 48.89661, lng: 18.794838 },
    { lat: 48.8966451, lng: 18.7947072 },
    { lat: 48.8966763, lng: 18.7946444 },
    { lat: 48.8968016, lng: 18.7946267 },
    { lat: 48.8968259, lng: 18.7947356 },
    { lat: 48.8967806, lng: 18.7950988 },
    { lat: 48.8968207, lng: 18.7951122 },
    { lat: 48.8986265, lng: 18.7958784 },
    { lat: 48.899413, lng: 18.7965382 },
    { lat: 48.9004432, lng: 18.7976982 },
    { lat: 48.9004884, lng: 18.7977444 },
    { lat: 48.9005672, lng: 18.7976331 },
    { lat: 48.9005793, lng: 18.7974758 },
    { lat: 48.9004847, lng: 18.7973258 },
    { lat: 48.90035, lng: 18.7972294 },
    { lat: 48.9002347, lng: 18.7963992 },
    { lat: 48.9002345, lng: 18.7961824 },
    { lat: 48.9003032, lng: 18.7961365 },
    { lat: 48.9005079, lng: 18.7962772 },
    { lat: 48.9006527, lng: 18.7963312 },
    { lat: 48.900888, lng: 18.7962126 },
    { lat: 48.901124, lng: 18.796198 },
    { lat: 48.9013046, lng: 18.796462 },
    { lat: 48.9013376, lng: 18.7966652 },
    { lat: 48.9013853, lng: 18.7968 },
    { lat: 48.9014534, lng: 18.7968735 },
    { lat: 48.9015851, lng: 18.7968255 },
    { lat: 48.9019787, lng: 18.7962209 },
    { lat: 48.9020621, lng: 18.7962215 },
    { lat: 48.9021022, lng: 18.7961991 },
    { lat: 48.9021726, lng: 18.7961199 },
    { lat: 48.9022161, lng: 18.7960556 },
    { lat: 48.9022511, lng: 18.7959582 },
    { lat: 48.9022796, lng: 18.7958289 },
    { lat: 48.9022987, lng: 18.7957044 },
    { lat: 48.9022888, lng: 18.7955948 },
    { lat: 48.9022714, lng: 18.7955009 },
    { lat: 48.9022945, lng: 18.7954005 },
    { lat: 48.9023442, lng: 18.7953322 },
    { lat: 48.9030075, lng: 18.7949513 },
    { lat: 48.9032283, lng: 18.7948166 },
    { lat: 48.9032426, lng: 18.7945926 },
    { lat: 48.9032053, lng: 18.7943347 },
    { lat: 48.9032168, lng: 18.7940337 },
    { lat: 48.9030868, lng: 18.7937884 },
    { lat: 48.903102, lng: 18.7936485 },
    { lat: 48.9032871, lng: 18.7935016 },
    { lat: 48.9032598, lng: 18.7934571 },
    { lat: 48.9032221, lng: 18.7934237 },
    { lat: 48.9006438, lng: 18.790666 },
    { lat: 48.8981115, lng: 18.7879912 },
    { lat: 48.8978825, lng: 18.7876872 },
    { lat: 48.8977029, lng: 18.7874016 },
    { lat: 48.8975318, lng: 18.7869759 },
    { lat: 48.8974221, lng: 18.7865805 },
    { lat: 48.8974014, lng: 18.7864196 },
    { lat: 48.8972486, lng: 18.7851123 },
    { lat: 48.8971493, lng: 18.7846364 },
    { lat: 48.8970537, lng: 18.7842762 },
    { lat: 48.8969497, lng: 18.7839687 },
    { lat: 48.8967708, lng: 18.7835648 },
    { lat: 48.8954697, lng: 18.7810217 },
    { lat: 48.8950144, lng: 18.7803131 },
    { lat: 48.8946938, lng: 18.7798983 },
    { lat: 48.8933344, lng: 18.7784425 },
    { lat: 48.8927485, lng: 18.7778031 },
    { lat: 48.8923791, lng: 18.777358 },
    { lat: 48.8918995, lng: 18.7766606 },
    { lat: 48.8917374, lng: 18.7764511 },
    { lat: 48.8909838, lng: 18.7758185 },
    { lat: 48.8904747, lng: 18.7753999 },
    { lat: 48.889809, lng: 18.77494 },
    { lat: 48.8889408, lng: 18.7744693 },
    { lat: 48.8874812, lng: 18.773964 },
    { lat: 48.8873172, lng: 18.7738906 },
    { lat: 48.884626, lng: 18.7696566 },
    { lat: 48.8825981, lng: 18.7663995 },
    { lat: 48.8814968, lng: 18.7646428 },
    { lat: 48.8813135, lng: 18.7644138 },
    { lat: 48.8800437, lng: 18.7632941 },
    { lat: 48.8797761, lng: 18.7631372 },
    { lat: 48.8796157, lng: 18.763111 },
    { lat: 48.8794157, lng: 18.7631462 },
    { lat: 48.8779521, lng: 18.7640721 },
    { lat: 48.876936, lng: 18.7645682 },
    { lat: 48.8760066, lng: 18.7650147 },
    { lat: 48.8758257, lng: 18.7650713 },
    { lat: 48.8756236, lng: 18.7650469 },
    { lat: 48.8753398, lng: 18.7648805 },
    { lat: 48.8751675, lng: 18.7646902 },
    { lat: 48.875054, lng: 18.7644811 },
    { lat: 48.8747891, lng: 18.7642289 },
    { lat: 48.8736837, lng: 18.7631885 },
    { lat: 48.8734414, lng: 18.762997 },
    { lat: 48.8732585, lng: 18.7628668 },
    { lat: 48.8726517, lng: 18.7627773 },
    { lat: 48.8722512, lng: 18.7629667 },
    { lat: 48.8699337, lng: 18.7652013 },
    { lat: 48.8696563, lng: 18.7653096 },
    { lat: 48.8679975, lng: 18.764879 },
    { lat: 48.8673942, lng: 18.7644279 },
    { lat: 48.866452, lng: 18.7627172 },
    { lat: 48.8663138, lng: 18.7622775 },
    { lat: 48.8657107, lng: 18.759613 },
    { lat: 48.8656967, lng: 18.7596268 },
    { lat: 48.8656115, lng: 18.7595923 },
    { lat: 48.8651808, lng: 18.759381 },
    { lat: 48.864998, lng: 18.759163 },
    { lat: 48.864806, lng: 18.7590311 },
    { lat: 48.8647732, lng: 18.758963 },
    { lat: 48.864726, lng: 18.7588154 },
    { lat: 48.8646336, lng: 18.7586851 },
    { lat: 48.8642316, lng: 18.7583736 },
    { lat: 48.8641105, lng: 18.7583033 },
    { lat: 48.8639518, lng: 18.7582888 },
    { lat: 48.8635549, lng: 18.7583986 },
    { lat: 48.8634497, lng: 18.758388 },
    { lat: 48.8630675, lng: 18.7581744 },
    { lat: 48.8627405, lng: 18.7579817 },
    { lat: 48.8625482, lng: 18.7578441 },
    { lat: 48.8608914, lng: 18.7577978 },
    { lat: 48.860176, lng: 18.7586913 },
    { lat: 48.8601732, lng: 18.7587009 },
    { lat: 48.8595956, lng: 18.7581377 },
    { lat: 48.8595737, lng: 18.758193 },
    { lat: 48.8592742, lng: 18.7579433 },
    { lat: 48.8592175, lng: 18.758024 },
    { lat: 48.8590465, lng: 18.7580524 },
    { lat: 48.8590543, lng: 18.7580748 },
    { lat: 48.8590766, lng: 18.7581136 },
    { lat: 48.859087, lng: 18.7581589 },
    { lat: 48.8590848, lng: 18.7582473 },
    { lat: 48.8591471, lng: 18.7582946 },
    { lat: 48.8592194, lng: 18.7584932 },
    { lat: 48.859221, lng: 18.7585858 },
    { lat: 48.8592817, lng: 18.7586891 },
    { lat: 48.8593533, lng: 18.7587213 },
    { lat: 48.8593657, lng: 18.7587868 },
    { lat: 48.8594026, lng: 18.7588232 },
    { lat: 48.8594934, lng: 18.7588112 },
    { lat: 48.8595697, lng: 18.7588912 },
    { lat: 48.8596183, lng: 18.7588939 },
    { lat: 48.8597066, lng: 18.759005 },
    { lat: 48.8597681, lng: 18.7590198 },
    { lat: 48.8598191, lng: 18.7590015 },
    { lat: 48.8598724, lng: 18.759071 },
    { lat: 48.859956, lng: 18.7592683 },
    { lat: 48.8599806, lng: 18.759309 },
    { lat: 48.8600398, lng: 18.7592521 },
    { lat: 48.8600875, lng: 18.7592621 },
    { lat: 48.8600961, lng: 18.7592937 },
    { lat: 48.8601076, lng: 18.7593195 },
    { lat: 48.8600922, lng: 18.7594145 },
    { lat: 48.860071, lng: 18.7594593 },
    { lat: 48.8601447, lng: 18.7594912 },
    { lat: 48.860271, lng: 18.7596189 },
    { lat: 48.8603389, lng: 18.7596258 },
    { lat: 48.8604019, lng: 18.759601 },
    { lat: 48.8604886, lng: 18.7596822 },
    { lat: 48.8605817, lng: 18.7599106 },
    { lat: 48.8606322, lng: 18.7598433 },
    { lat: 48.8606575, lng: 18.7598932 },
    { lat: 48.8606342, lng: 18.7599891 },
    { lat: 48.8606657, lng: 18.7600083 },
    { lat: 48.8606891, lng: 18.7599353 },
    { lat: 48.8607413, lng: 18.7600532 },
    { lat: 48.8612001, lng: 18.7604567 },
    { lat: 48.8613057, lng: 18.760554 },
    { lat: 48.8615847, lng: 18.7608452 },
    { lat: 48.8619542, lng: 18.7615646 },
    { lat: 48.8619455, lng: 18.7620255 },
    { lat: 48.8617588, lng: 18.763346 },
    { lat: 48.8617501, lng: 18.7635444 },
    { lat: 48.8618528, lng: 18.7649667 },
    { lat: 48.861918, lng: 18.7659098 },
    { lat: 48.8619729, lng: 18.7666145 },
    { lat: 48.8620779, lng: 18.7674775 },
    { lat: 48.8618972, lng: 18.7679025 },
    { lat: 48.8616584, lng: 18.7682522 },
    { lat: 48.8614882, lng: 18.7685126 },
    { lat: 48.8614369, lng: 18.7686062 },
    { lat: 48.8613651, lng: 18.7687875 },
    { lat: 48.8610502, lng: 18.7699027 },
    { lat: 48.8609528, lng: 18.7702367 },
    { lat: 48.8607429, lng: 18.7710968 },
    { lat: 48.8606889, lng: 18.7712928 },
    { lat: 48.8607055, lng: 18.7713524 },
    { lat: 48.8606913, lng: 18.7714472 },
    { lat: 48.8607171, lng: 18.7716597 },
    { lat: 48.8607071, lng: 18.7717486 },
    { lat: 48.8607286, lng: 18.7718119 },
    { lat: 48.8607632, lng: 18.771999 },
    { lat: 48.8607537, lng: 18.7720983 },
    { lat: 48.8607972, lng: 18.7722829 },
    { lat: 48.8608294, lng: 18.7723388 },
    { lat: 48.8608451, lng: 18.7724043 },
    { lat: 48.8608838, lng: 18.7724519 },
    { lat: 48.8608821, lng: 18.7725287 },
    { lat: 48.8609362, lng: 18.7727629 },
    { lat: 48.8610093, lng: 18.7729085 },
    { lat: 48.8610725, lng: 18.7731899 },
    { lat: 48.8610687, lng: 18.7732484 },
    { lat: 48.8610916, lng: 18.7732757 },
    { lat: 48.8611265, lng: 18.7732751 },
    { lat: 48.8611732, lng: 18.7733501 },
    { lat: 48.8611764, lng: 18.7733887 },
    { lat: 48.8612161, lng: 18.773414 },
    { lat: 48.8612227, lng: 18.7734967 },
    { lat: 48.8612465, lng: 18.7734999 },
    { lat: 48.8613129, lng: 18.7736022 },
    { lat: 48.861328, lng: 18.7736559 },
    { lat: 48.8613635, lng: 18.7737115 },
    { lat: 48.8613952, lng: 18.7737116 },
    { lat: 48.8614199, lng: 18.7737548 },
    { lat: 48.8614135, lng: 18.7738462 },
    { lat: 48.8614459, lng: 18.7737954 },
    { lat: 48.861483, lng: 18.7738182 },
    { lat: 48.8615029, lng: 18.7738967 },
    { lat: 48.8614851, lng: 18.7739221 },
    { lat: 48.8615199, lng: 18.7739824 },
    { lat: 48.8615598, lng: 18.773949 },
    { lat: 48.8615829, lng: 18.7739809 },
    { lat: 48.8615725, lng: 18.7741075 },
    { lat: 48.8616052, lng: 18.7741484 },
    { lat: 48.861657, lng: 18.7743173 },
    { lat: 48.8616924, lng: 18.7743703 },
    { lat: 48.8617095, lng: 18.7744614 },
    { lat: 48.8617364, lng: 18.7745018 },
    { lat: 48.8617431, lng: 18.7745394 },
    { lat: 48.861791, lng: 18.7746375 },
    { lat: 48.8618063, lng: 18.7747133 },
    { lat: 48.8618362, lng: 18.7747481 },
    { lat: 48.86193, lng: 18.7749227 },
    { lat: 48.861911, lng: 18.7749715 },
    { lat: 48.8619254, lng: 18.7750081 },
    { lat: 48.8619741, lng: 18.7750348 },
    { lat: 48.8619942, lng: 18.7750765 },
    { lat: 48.8620021, lng: 18.7751819 },
    { lat: 48.8620435, lng: 18.7751962 },
    { lat: 48.8621656, lng: 18.7753743 },
    { lat: 48.8621709, lng: 18.7754123 },
    { lat: 48.8622007, lng: 18.7754413 },
    { lat: 48.8622161, lng: 18.7754147 },
    { lat: 48.8622242, lng: 18.7754385 },
    { lat: 48.8622235, lng: 18.7754934 },
    { lat: 48.8622897, lng: 18.7755073 },
    { lat: 48.862323, lng: 18.7755387 },
    { lat: 48.862342, lng: 18.7755801 },
    { lat: 48.8623998, lng: 18.7756469 },
    { lat: 48.8624294, lng: 18.7756968 },
    { lat: 48.8626034, lng: 18.7758968 },
    { lat: 48.8627625, lng: 18.7760518 },
    { lat: 48.8628041, lng: 18.7761341 },
    { lat: 48.8631682, lng: 18.7765857 },
    { lat: 48.8633297, lng: 18.7767185 },
    { lat: 48.8639172, lng: 18.7774273 },
    { lat: 48.8639466, lng: 18.7774305 },
    { lat: 48.8639949, lng: 18.7774734 },
    { lat: 48.864011, lng: 18.7775177 },
    { lat: 48.8640528, lng: 18.7775413 },
    { lat: 48.8640847, lng: 18.777612 },
    { lat: 48.8641385, lng: 18.7776517 },
    { lat: 48.8643245, lng: 18.7778876 },
    { lat: 48.8643857, lng: 18.7779379 },
    { lat: 48.8645282, lng: 18.7781357 },
    { lat: 48.8645531, lng: 18.7781381 },
    { lat: 48.8646069, lng: 18.7782 },
    { lat: 48.8646237, lng: 18.7782596 },
    { lat: 48.8647513, lng: 18.778394 },
    { lat: 48.8647527, lng: 18.7784214 },
    { lat: 48.864799, lng: 18.7784427 },
    { lat: 48.8648704, lng: 18.7785337 },
    { lat: 48.8648883, lng: 18.7785735 },
    { lat: 48.8648971, lng: 18.7786165 },
    { lat: 48.8649333, lng: 18.7786164 },
    { lat: 48.8649961, lng: 18.7786966 },
    { lat: 48.8650795, lng: 18.7787829 },
    { lat: 48.8651094, lng: 18.778793 },
    { lat: 48.8651385, lng: 18.778831 },
    { lat: 48.8651495, lng: 18.7788691 },
    { lat: 48.8652283, lng: 18.778952 },
    { lat: 48.8652964, lng: 18.7789847 },
    { lat: 48.8653794, lng: 18.7791273 },
    { lat: 48.8655015, lng: 18.7792602 },
    { lat: 48.8654964, lng: 18.7792952 },
    { lat: 48.8655391, lng: 18.7793108 },
    { lat: 48.8656088, lng: 18.7794156 },
    { lat: 48.8656468, lng: 18.7794497 },
    { lat: 48.8656832, lng: 18.779459 },
    { lat: 48.8657206, lng: 18.779524 },
    { lat: 48.8658362, lng: 18.7796648 },
    { lat: 48.8659894, lng: 18.7799011 },
    { lat: 48.8660221, lng: 18.7799215 },
    { lat: 48.8660504, lng: 18.7799657 },
    { lat: 48.8660988, lng: 18.7799864 },
    { lat: 48.8661435, lng: 18.7800638 },
    { lat: 48.8662211, lng: 18.7801469 },
    { lat: 48.8662783, lng: 18.7802292 },
    { lat: 48.8663411, lng: 18.7802872 },
    { lat: 48.8664419, lng: 18.7804023 },
    { lat: 48.8664735, lng: 18.7804232 },
    { lat: 48.8664879, lng: 18.7804651 },
    { lat: 48.8665545, lng: 18.7805503 },
    { lat: 48.8665873, lng: 18.7805542 },
    { lat: 48.8666048, lng: 18.7804543 },
    { lat: 48.8666777, lng: 18.7803621 },
    { lat: 48.8667081, lng: 18.7802739 },
    { lat: 48.8667479, lng: 18.7802172 },
    { lat: 48.8667683, lng: 18.780242 },
    { lat: 48.8669042, lng: 18.7802726 },
    { lat: 48.8670359, lng: 18.7802876 },
    { lat: 48.8670771, lng: 18.7802582 },
    { lat: 48.8672073, lng: 18.7802666 },
    { lat: 48.8672771, lng: 18.7803098 },
    { lat: 48.8673489, lng: 18.7802829 },
    { lat: 48.8674013, lng: 18.7802261 },
    { lat: 48.8674452, lng: 18.7802663 },
    { lat: 48.8676027, lng: 18.7803054 },
    { lat: 48.8676414, lng: 18.78029 },
    { lat: 48.8677387, lng: 18.7802941 },
    { lat: 48.8678683, lng: 18.7803584 },
    { lat: 48.8681124, lng: 18.7805168 },
    { lat: 48.8683095, lng: 18.7806661 },
    { lat: 48.8683889, lng: 18.7806938 },
    { lat: 48.8685748, lng: 18.7808423 },
    { lat: 48.8686952, lng: 18.7810109 },
    { lat: 48.8687399, lng: 18.7810478 },
    { lat: 48.8687732, lng: 18.7811033 },
    { lat: 48.8688475, lng: 18.7811409 },
    { lat: 48.8689318, lng: 18.7812655 },
    { lat: 48.8689877, lng: 18.781301 },
    { lat: 48.8690063, lng: 18.781354 },
    { lat: 48.8690856, lng: 18.7814906 },
    { lat: 48.8691797, lng: 18.7816064 },
    { lat: 48.8692693, lng: 18.7817461 },
    { lat: 48.869291, lng: 18.78175 },
    { lat: 48.8693734, lng: 18.7817307 },
    { lat: 48.8694247, lng: 18.7817626 },
    { lat: 48.8695348, lng: 18.7817762 },
    { lat: 48.8696393, lng: 18.7817726 },
    { lat: 48.8696888, lng: 18.7817487 },
    { lat: 48.8697381, lng: 18.7818041 },
    { lat: 48.8698025, lng: 18.7817852 },
    { lat: 48.8698471, lng: 18.7818203 },
    { lat: 48.869885, lng: 18.7817889 },
    { lat: 48.8699335, lng: 18.7818143 },
    { lat: 48.8700397, lng: 18.7818377 },
    { lat: 48.8700685, lng: 18.7818292 },
    { lat: 48.8701571, lng: 18.7818601 },
    { lat: 48.8702523, lng: 18.7818691 },
    { lat: 48.8702631, lng: 18.7818392 },
    { lat: 48.8704257, lng: 18.7818623 },
    { lat: 48.8706446, lng: 18.7819327 },
    { lat: 48.8706841, lng: 18.7819559 },
    { lat: 48.8707362, lng: 18.7819174 },
    { lat: 48.8708335, lng: 18.7819669 },
    { lat: 48.8708814, lng: 18.7819555 },
    { lat: 48.871079, lng: 18.7820019 },
    { lat: 48.8711536, lng: 18.7820277 },
    { lat: 48.8712271, lng: 18.7820107 },
    { lat: 48.8712958, lng: 18.7820321 },
    { lat: 48.8713444, lng: 18.7820306 },
    { lat: 48.8713998, lng: 18.7820129 },
    { lat: 48.8715053, lng: 18.7821109 },
    { lat: 48.8715592, lng: 18.7821284 },
    { lat: 48.8716231, lng: 18.7824245 },
    { lat: 48.8716377, lng: 18.7824639 },
    { lat: 48.8716356, lng: 18.7825107 },
    { lat: 48.8717381, lng: 18.7829852 },
    { lat: 48.8717807, lng: 18.7832792 },
    { lat: 48.871858, lng: 18.7834423 },
    { lat: 48.8719677, lng: 18.7835795 },
    { lat: 48.8720696, lng: 18.7837625 },
    { lat: 48.8721256, lng: 18.783816 },
    { lat: 48.8721554, lng: 18.7838701 },
    { lat: 48.8723635, lng: 18.7841858 },
    { lat: 48.8724327, lng: 18.7843659 },
    { lat: 48.8724858, lng: 18.7843882 },
    { lat: 48.8728801, lng: 18.7849867 },
    { lat: 48.872991, lng: 18.7851657 },
    { lat: 48.8731883, lng: 18.7854462 },
    { lat: 48.8732195, lng: 18.785522 },
    { lat: 48.8732935, lng: 18.7855826 },
    { lat: 48.8733532, lng: 18.785603 },
    { lat: 48.8735592, lng: 18.7858111 },
    { lat: 48.8739187, lng: 18.7861111 },
    { lat: 48.873827, lng: 18.7862504 },
    { lat: 48.8739502, lng: 18.7863664 },
    { lat: 48.874189, lng: 18.7861869 },
    { lat: 48.8742442, lng: 18.786253 },
    { lat: 48.874211, lng: 18.7865457 },
    { lat: 48.8745375, lng: 18.7868382 },
    { lat: 48.87462, lng: 18.7872951 },
    { lat: 48.8749205, lng: 18.7876076 },
    { lat: 48.8750163, lng: 18.787129 },
    { lat: 48.8751275, lng: 18.787231 },
    { lat: 48.8752877, lng: 18.7874486 },
    { lat: 48.8751959, lng: 18.7876989 },
    { lat: 48.8752388, lng: 18.7878922 },
    { lat: 48.8753333, lng: 18.7878017 },
    { lat: 48.8755298, lng: 18.7880027 },
    { lat: 48.8758, lng: 18.7880602 },
    { lat: 48.8760016, lng: 18.7877301 },
    { lat: 48.8763321, lng: 18.7881859 },
    { lat: 48.8764776, lng: 18.788229 },
    { lat: 48.8770107, lng: 18.7880941 },
    { lat: 48.8771905, lng: 18.7884923 },
    { lat: 48.8773693, lng: 18.7887432 },
    { lat: 48.8776187, lng: 18.7886362 },
    { lat: 48.8775926, lng: 18.7889611 },
    { lat: 48.8778252, lng: 18.7890944 },
    { lat: 48.8778704, lng: 18.7890316 },
    { lat: 48.8779654, lng: 18.78906 },
    { lat: 48.8780232, lng: 18.7889133 },
    { lat: 48.8781753, lng: 18.7888654 },
    { lat: 48.8783373, lng: 18.7889265 },
  ],
  Liešno: [
    { lat: 48.8933344, lng: 18.7784425 },
    { lat: 48.893163, lng: 18.7749479 },
    { lat: 48.8929062, lng: 18.775039 },
    { lat: 48.8927509, lng: 18.7721771 },
    { lat: 48.8926765, lng: 18.7706695 },
    { lat: 48.8928478, lng: 18.7703858 },
    { lat: 48.8931444, lng: 18.7700842 },
    { lat: 48.8931378, lng: 18.769957 },
    { lat: 48.8929731, lng: 18.7670504 },
    { lat: 48.8929073, lng: 18.7660749 },
    { lat: 48.8927785, lng: 18.7639409 },
    { lat: 48.8927777, lng: 18.7626534 },
    { lat: 48.8927721, lng: 18.7625695 },
    { lat: 48.8929957, lng: 18.762425 },
    { lat: 48.8933943, lng: 18.7620243 },
    { lat: 48.8933764, lng: 18.761962 },
    { lat: 48.8925802, lng: 18.759359 },
    { lat: 48.8922426, lng: 18.758062 },
    { lat: 48.8921199, lng: 18.7580212 },
    { lat: 48.8917009, lng: 18.7587179 },
    { lat: 48.8914359, lng: 18.7591093 },
    { lat: 48.8912037, lng: 18.7592592 },
    { lat: 48.8908442, lng: 18.7594337 },
    { lat: 48.8905698, lng: 18.7595764 },
    { lat: 48.8898902, lng: 18.759672 },
    { lat: 48.8896051, lng: 18.7595881 },
    { lat: 48.8893793, lng: 18.7596942 },
    { lat: 48.8893373, lng: 18.7597717 },
    { lat: 48.8892585, lng: 18.7598195 },
    { lat: 48.8891905, lng: 18.7598782 },
    { lat: 48.8891674, lng: 18.7598674 },
    { lat: 48.8891279, lng: 18.7597808 },
    { lat: 48.8882982, lng: 18.7589692 },
    { lat: 48.8882443, lng: 18.7592527 },
    { lat: 48.8865559, lng: 18.7582161 },
    { lat: 48.8863838, lng: 18.7572777 },
    { lat: 48.8852339, lng: 18.7568542 },
    { lat: 48.8835651, lng: 18.7546867 },
    { lat: 48.883748, lng: 18.7534034 },
    { lat: 48.883727, lng: 18.7533869 },
    { lat: 48.8837089, lng: 18.753388 },
    { lat: 48.8831784, lng: 18.7533349 },
    { lat: 48.8826468, lng: 18.7532418 },
    { lat: 48.8820771, lng: 18.7530436 },
    { lat: 48.8815106, lng: 18.7527143 },
    { lat: 48.8806739, lng: 18.7527565 },
    { lat: 48.8803232, lng: 18.7529092 },
    { lat: 48.880226, lng: 18.7530112 },
    { lat: 48.8798033, lng: 18.7535302 },
    { lat: 48.8794987, lng: 18.7539567 },
    { lat: 48.8782142, lng: 18.7533787 },
    { lat: 48.8781478, lng: 18.7533384 },
    { lat: 48.8776655, lng: 18.7543557 },
    { lat: 48.877383, lng: 18.754686 },
    { lat: 48.876811, lng: 18.754701 },
    { lat: 48.87634, lng: 18.7550974 },
    { lat: 48.8754149, lng: 18.755625 },
    { lat: 48.87538, lng: 18.7556253 },
    { lat: 48.8752143, lng: 18.7579481 },
    { lat: 48.8750063, lng: 18.7589075 },
    { lat: 48.8743938, lng: 18.7617179 },
    { lat: 48.8743539, lng: 18.7620337 },
    { lat: 48.8743784, lng: 18.7624159 },
    { lat: 48.874457, lng: 18.7627517 },
    { lat: 48.875054, lng: 18.7644811 },
    { lat: 48.8751675, lng: 18.7646902 },
    { lat: 48.8753398, lng: 18.7648805 },
    { lat: 48.8756236, lng: 18.7650469 },
    { lat: 48.8758257, lng: 18.7650713 },
    { lat: 48.8760066, lng: 18.7650147 },
    { lat: 48.876936, lng: 18.7645682 },
    { lat: 48.8779521, lng: 18.7640721 },
    { lat: 48.8794157, lng: 18.7631462 },
    { lat: 48.8796157, lng: 18.763111 },
    { lat: 48.8797761, lng: 18.7631372 },
    { lat: 48.8800437, lng: 18.7632941 },
    { lat: 48.8813135, lng: 18.7644138 },
    { lat: 48.8814968, lng: 18.7646428 },
    { lat: 48.8825981, lng: 18.7663995 },
    { lat: 48.884626, lng: 18.7696566 },
    { lat: 48.8873172, lng: 18.7738906 },
    { lat: 48.8874812, lng: 18.773964 },
    { lat: 48.8889408, lng: 18.7744693 },
    { lat: 48.889809, lng: 18.77494 },
    { lat: 48.8904747, lng: 18.7753999 },
    { lat: 48.8909838, lng: 18.7758185 },
    { lat: 48.8917374, lng: 18.7764511 },
    { lat: 48.8918995, lng: 18.7766606 },
    { lat: 48.8923791, lng: 18.777358 },
    { lat: 48.8927485, lng: 18.7778031 },
    { lat: 48.8933344, lng: 18.7784425 },
  ],
  MalýČepčín: [
    { lat: 48.9129957, lng: 18.8350945 },
    { lat: 48.912882, lng: 18.8349255 },
    { lat: 48.9129648, lng: 18.8346701 },
    { lat: 48.9127494, lng: 18.8336691 },
    { lat: 48.9124037, lng: 18.8317837 },
    { lat: 48.9123849, lng: 18.8317035 },
    { lat: 48.9121309, lng: 18.830435 },
    { lat: 48.9117673, lng: 18.8291635 },
    { lat: 48.9117371, lng: 18.8290592 },
    { lat: 48.9117263, lng: 18.8290239 },
    { lat: 48.9109598, lng: 18.8285263 },
    { lat: 48.9107526, lng: 18.8277706 },
    { lat: 48.9106018, lng: 18.826953 },
    { lat: 48.910564, lng: 18.8256537 },
    { lat: 48.910524, lng: 18.825689 },
    { lat: 48.9098247, lng: 18.8268181 },
    { lat: 48.9095847, lng: 18.8273833 },
    { lat: 48.9091155, lng: 18.8279928 },
    { lat: 48.9087441, lng: 18.828549 },
    { lat: 48.9087125, lng: 18.8285965 },
    { lat: 48.9085649, lng: 18.828922 },
    { lat: 48.9082942, lng: 18.829379 },
    { lat: 48.9078692, lng: 18.8299928 },
    { lat: 48.9075911, lng: 18.8303031 },
    { lat: 48.9071009, lng: 18.8307888 },
    { lat: 48.9069325, lng: 18.8310623 },
    { lat: 48.9063843, lng: 18.828991 },
    { lat: 48.905934, lng: 18.8274145 },
    { lat: 48.9059065, lng: 18.8272586 },
    { lat: 48.9056406, lng: 18.8263265 },
    { lat: 48.9058164, lng: 18.8257341 },
    { lat: 48.9058667, lng: 18.8252693 },
    { lat: 48.9058037, lng: 18.8251202 },
    { lat: 48.9056477, lng: 18.8252661 },
    { lat: 48.9053421, lng: 18.8247215 },
    { lat: 48.9051336, lng: 18.8242689 },
    { lat: 48.9049678, lng: 18.8237461 },
    { lat: 48.9047141, lng: 18.8227441 },
    { lat: 48.9049518, lng: 18.8218479 },
    { lat: 48.9049988, lng: 18.8216473 },
    { lat: 48.905068, lng: 18.8201573 },
    { lat: 48.9050608, lng: 18.820123 },
    { lat: 48.9046349, lng: 18.8203258 },
    { lat: 48.9045528, lng: 18.8203091 },
    { lat: 48.9035663, lng: 18.8197685 },
    { lat: 48.9032923, lng: 18.8195284 },
    { lat: 48.9032035, lng: 18.8195154 },
    { lat: 48.9023037, lng: 18.8202762 },
    { lat: 48.9014952, lng: 18.8205373 },
    { lat: 48.9014885, lng: 18.8204954 },
    { lat: 48.9010664, lng: 18.8176614 },
    { lat: 48.9009459, lng: 18.8168374 },
    { lat: 48.9006704, lng: 18.814945 },
    { lat: 48.900049, lng: 18.8152691 },
    { lat: 48.8991655, lng: 18.8157334 },
    { lat: 48.8993528, lng: 18.8176711 },
    { lat: 48.8985673, lng: 18.8179835 },
    { lat: 48.8976543, lng: 18.8183793 },
    { lat: 48.8981279, lng: 18.8220106 },
    { lat: 48.8970993, lng: 18.8223104 },
    { lat: 48.8970005, lng: 18.8223358 },
    { lat: 48.8960011, lng: 18.8226346 },
    { lat: 48.8935909, lng: 18.823563 },
    { lat: 48.8918339, lng: 18.824971 },
    { lat: 48.8918065, lng: 18.8249846 },
    { lat: 48.8918486, lng: 18.8251451 },
    { lat: 48.8920027, lng: 18.8259216 },
    { lat: 48.892217, lng: 18.8268359 },
    { lat: 48.8923074, lng: 18.8271226 },
    { lat: 48.8923522, lng: 18.8272477 },
    { lat: 48.8924512, lng: 18.827353 },
    { lat: 48.8926877, lng: 18.8275202 },
    { lat: 48.8928685, lng: 18.827504 },
    { lat: 48.8928228, lng: 18.8279713 },
    { lat: 48.892762, lng: 18.8283204 },
    { lat: 48.8926662, lng: 18.8285831 },
    { lat: 48.8924127, lng: 18.8290211 },
    { lat: 48.8921509, lng: 18.8294085 },
    { lat: 48.8921081, lng: 18.8296508 },
    { lat: 48.892226, lng: 18.8306211 },
    { lat: 48.8922593, lng: 18.8313736 },
    { lat: 48.8924417, lng: 18.8322395 },
    { lat: 48.8926861, lng: 18.8327755 },
    { lat: 48.8927132, lng: 18.833189 },
    { lat: 48.8928201, lng: 18.8352769 },
    { lat: 48.8929437, lng: 18.8362086 },
    { lat: 48.8932333, lng: 18.8378995 },
    { lat: 48.8934694, lng: 18.8406328 },
    { lat: 48.8938546, lng: 18.8408888 },
    { lat: 48.8943291, lng: 18.8413659 },
    { lat: 48.8945891, lng: 18.8417929 },
    { lat: 48.8951158, lng: 18.843364 },
    { lat: 48.8951535, lng: 18.8434172 },
    { lat: 48.8950631, lng: 18.8435667 },
    { lat: 48.8954001, lng: 18.8444135 },
    { lat: 48.8958016, lng: 18.8454434 },
    { lat: 48.8958622, lng: 18.8456329 },
    { lat: 48.8959466, lng: 18.8458243 },
    { lat: 48.8960093, lng: 18.8458168 },
    { lat: 48.8963499, lng: 18.8457287 },
    { lat: 48.8967399, lng: 18.8455974 },
    { lat: 48.8972363, lng: 18.8454921 },
    { lat: 48.897807, lng: 18.8453193 },
    { lat: 48.8983357, lng: 18.8451622 },
    { lat: 48.8983827, lng: 18.8451573 },
    { lat: 48.8987628, lng: 18.8450515 },
    { lat: 48.899582, lng: 18.8448448 },
    { lat: 48.9002395, lng: 18.844652 },
    { lat: 48.9007071, lng: 18.8445089 },
    { lat: 48.9017408, lng: 18.8442204 },
    { lat: 48.9021691, lng: 18.8441092 },
    { lat: 48.9024472, lng: 18.8440138 },
    { lat: 48.9028116, lng: 18.843955 },
    { lat: 48.9030606, lng: 18.8448003 },
    { lat: 48.9032874, lng: 18.8446307 },
    { lat: 48.9034925, lng: 18.8444282 },
    { lat: 48.9036205, lng: 18.8442452 },
    { lat: 48.9038639, lng: 18.8441539 },
    { lat: 48.9041153, lng: 18.8440444 },
    { lat: 48.9058403, lng: 18.8435378 },
    { lat: 48.9071478, lng: 18.8431805 },
    { lat: 48.9073269, lng: 18.8440951 },
    { lat: 48.9075185, lng: 18.8452243 },
    { lat: 48.9075253, lng: 18.8452916 },
    { lat: 48.9076285, lng: 18.8453256 },
    { lat: 48.9077831, lng: 18.8454563 },
    { lat: 48.9079306, lng: 18.8455201 },
    { lat: 48.9080002, lng: 18.8454918 },
    { lat: 48.9083094, lng: 18.8451916 },
    { lat: 48.908459, lng: 18.8450978 },
    { lat: 48.908676, lng: 18.845039 },
    { lat: 48.9087472, lng: 18.8450642 },
    { lat: 48.9088787, lng: 18.8454913 },
    { lat: 48.9089676, lng: 18.8456205 },
    { lat: 48.9090666, lng: 18.8456381 },
    { lat: 48.9091654, lng: 18.8456084 },
    { lat: 48.9092954, lng: 18.8455263 },
    { lat: 48.9093883, lng: 18.8454489 },
    { lat: 48.9093806, lng: 18.8454094 },
    { lat: 48.909419, lng: 18.8453439 },
    { lat: 48.9094491, lng: 18.8449893 },
    { lat: 48.9095125, lng: 18.8446011 },
    { lat: 48.9096909, lng: 18.8443641 },
    { lat: 48.9094399, lng: 18.8436301 },
    { lat: 48.9089016, lng: 18.8424467 },
    { lat: 48.9088261, lng: 18.8423377 },
    { lat: 48.9086553, lng: 18.8420638 },
    { lat: 48.9079821, lng: 18.8403412 },
    { lat: 48.9076619, lng: 18.8394911 },
    { lat: 48.9076994, lng: 18.8394533 },
    { lat: 48.9081175, lng: 18.8391433 },
    { lat: 48.9084171, lng: 18.8388979 },
    { lat: 48.9083078, lng: 18.8386159 },
    { lat: 48.9081274, lng: 18.8381166 },
    { lat: 48.9088596, lng: 18.8375114 },
    { lat: 48.9087542, lng: 18.8371963 },
    { lat: 48.9086568, lng: 18.8368853 },
    { lat: 48.9086318, lng: 18.8367897 },
    { lat: 48.9089868, lng: 18.8366772 },
    { lat: 48.9102589, lng: 18.8361149 },
    { lat: 48.9107669, lng: 18.8359088 },
    { lat: 48.9116078, lng: 18.835643 },
    { lat: 48.9123224, lng: 18.8353984 },
    { lat: 48.9129957, lng: 18.8350945 },
  ],
  Moškovec: [
    { lat: 48.947416, lng: 18.837191 },
    { lat: 48.947628, lng: 18.836994 },
    { lat: 48.947489, lng: 18.836359 },
    { lat: 48.947383, lng: 18.835976 },
    { lat: 48.947403, lng: 18.835929 },
    { lat: 48.947456, lng: 18.835893 },
    { lat: 48.947494, lng: 18.835934 },
    { lat: 48.94788, lng: 18.835266 },
    { lat: 48.948203, lng: 18.834433 },
    { lat: 48.948336, lng: 18.833929 },
    { lat: 48.94837, lng: 18.833948 },
    { lat: 48.950273, lng: 18.834984 },
    { lat: 48.950689, lng: 18.835318 },
    { lat: 48.951067, lng: 18.835584 },
    { lat: 48.951332, lng: 18.835663 },
    { lat: 48.951583, lng: 18.835713 },
    { lat: 48.951612, lng: 18.835589 },
    { lat: 48.951657, lng: 18.835188 },
    { lat: 48.951758, lng: 18.834559 },
    { lat: 48.951853, lng: 18.8339 },
    { lat: 48.951866, lng: 18.833723 },
    { lat: 48.951872, lng: 18.833484 },
    { lat: 48.951878, lng: 18.833254 },
    { lat: 48.951848, lng: 18.832667 },
    { lat: 48.951823, lng: 18.831859 },
    { lat: 48.951806, lng: 18.831269 },
    { lat: 48.951847, lng: 18.830548 },
    { lat: 48.95189, lng: 18.830145 },
    { lat: 48.951938, lng: 18.829484 },
    { lat: 48.951691, lng: 18.82938 },
    { lat: 48.951496, lng: 18.829209 },
    { lat: 48.951396, lng: 18.829144 },
    { lat: 48.950958, lng: 18.828821 },
    { lat: 48.950748, lng: 18.828656 },
    { lat: 48.950593, lng: 18.828416 },
    { lat: 48.950417, lng: 18.827821 },
    { lat: 48.950613, lng: 18.826833 },
    { lat: 48.950421, lng: 18.826368 },
    { lat: 48.950394, lng: 18.826309 },
    { lat: 48.950183, lng: 18.825674 },
    { lat: 48.950118, lng: 18.825395 },
    { lat: 48.950208, lng: 18.825265 },
    { lat: 48.95046, lng: 18.824471 },
    { lat: 48.950543, lng: 18.82426 },
    { lat: 48.950769, lng: 18.823553 },
    { lat: 48.950941, lng: 18.823099 },
    { lat: 48.951071, lng: 18.822681 },
    { lat: 48.95109, lng: 18.822621 },
    { lat: 48.951056, lng: 18.822617 },
    { lat: 48.950994, lng: 18.822608 },
    { lat: 48.950886, lng: 18.822635 },
    { lat: 48.950643, lng: 18.822658 },
    { lat: 48.949995, lng: 18.822754 },
    { lat: 48.949972, lng: 18.822758 },
    { lat: 48.949673, lng: 18.822812 },
    { lat: 48.949465, lng: 18.822887 },
    { lat: 48.949076, lng: 18.82295 },
    { lat: 48.948968, lng: 18.822958 },
    { lat: 48.948777, lng: 18.822974 },
    { lat: 48.948716, lng: 18.822986 },
    { lat: 48.948561, lng: 18.82304 },
    { lat: 48.948534, lng: 18.822992 },
    { lat: 48.948148, lng: 18.8226 },
    { lat: 48.947952, lng: 18.82239 },
    { lat: 48.947859, lng: 18.822289 },
    { lat: 48.947737, lng: 18.822159 },
    { lat: 48.947666, lng: 18.822065 },
    { lat: 48.947393, lng: 18.821743 },
    { lat: 48.947194, lng: 18.821474 },
    { lat: 48.947159, lng: 18.821429 },
    { lat: 48.947149, lng: 18.821415 },
    { lat: 48.947144, lng: 18.821409 },
    { lat: 48.947133, lng: 18.821393 },
    { lat: 48.947182, lng: 18.821384 },
    { lat: 48.947244, lng: 18.821292 },
    { lat: 48.947267, lng: 18.821207 },
    { lat: 48.947392, lng: 18.820918 },
    { lat: 48.947577, lng: 18.820904 },
    { lat: 48.947613, lng: 18.820881 },
    { lat: 48.947685, lng: 18.820727 },
    { lat: 48.947735, lng: 18.820698 },
    { lat: 48.947776, lng: 18.820609 },
    { lat: 48.947804, lng: 18.820611 },
    { lat: 48.947815, lng: 18.820553 },
    { lat: 48.947814, lng: 18.820475 },
    { lat: 48.94787, lng: 18.820391 },
    { lat: 48.947904, lng: 18.820326 },
    { lat: 48.947943, lng: 18.820349 },
    { lat: 48.947951, lng: 18.820414 },
    { lat: 48.947967, lng: 18.820424 },
    { lat: 48.947977, lng: 18.820468 },
    { lat: 48.948044, lng: 18.820419 },
    { lat: 48.948061, lng: 18.820373 },
    { lat: 48.948105, lng: 18.82036 },
    { lat: 48.948143, lng: 18.820259 },
    { lat: 48.948179, lng: 18.820227 },
    { lat: 48.948175, lng: 18.820185 },
    { lat: 48.948165, lng: 18.820155 },
    { lat: 48.948161, lng: 18.82009 },
    { lat: 48.948197, lng: 18.820036 },
    { lat: 48.948274, lng: 18.819922 },
    { lat: 48.948325, lng: 18.81986 },
    { lat: 48.948394, lng: 18.819841 },
    { lat: 48.948408, lng: 18.819747 },
    { lat: 48.948372, lng: 18.819619 },
    { lat: 48.948374, lng: 18.819564 },
    { lat: 48.948422, lng: 18.819578 },
    { lat: 48.948446, lng: 18.819539 },
    { lat: 48.948509, lng: 18.819523 },
    { lat: 48.948544, lng: 18.819451 },
    { lat: 48.948609, lng: 18.81941 },
    { lat: 48.94864, lng: 18.819423 },
    { lat: 48.948749, lng: 18.819322 },
    { lat: 48.948768, lng: 18.81926 },
    { lat: 48.948732, lng: 18.81919 },
    { lat: 48.948696, lng: 18.819146 },
    { lat: 48.948684, lng: 18.818975 },
    { lat: 48.948704, lng: 18.818932 },
    { lat: 48.948797, lng: 18.818893 },
    { lat: 48.948791, lng: 18.818817 },
    { lat: 48.948832, lng: 18.818793 },
    { lat: 48.948805, lng: 18.818728 },
    { lat: 48.94881, lng: 18.818604 },
    { lat: 48.948847, lng: 18.81857 },
    { lat: 48.948869, lng: 18.818611 },
    { lat: 48.948891, lng: 18.818549 },
    { lat: 48.948927, lng: 18.818539 },
    { lat: 48.949025, lng: 18.818433 },
    { lat: 48.949075, lng: 18.818427 },
    { lat: 48.949088, lng: 18.818308 },
    { lat: 48.949105, lng: 18.818188 },
    { lat: 48.949144, lng: 18.818097 },
    { lat: 48.949244, lng: 18.817884 },
    { lat: 48.94929, lng: 18.817795 },
    { lat: 48.949374, lng: 18.817812 },
    { lat: 48.949386, lng: 18.817764 },
    { lat: 48.949332, lng: 18.817684 },
    { lat: 48.949405, lng: 18.817656 },
    { lat: 48.949466, lng: 18.817686 },
    { lat: 48.949514, lng: 18.817659 },
    { lat: 48.949489, lng: 18.817583 },
    { lat: 48.949539, lng: 18.817504 },
    { lat: 48.949542, lng: 18.817469 },
    { lat: 48.949501, lng: 18.817456 },
    { lat: 48.94954, lng: 18.817355 },
    { lat: 48.94955, lng: 18.817159 },
    { lat: 48.949558, lng: 18.817056 },
    { lat: 48.949549, lng: 18.816867 },
    { lat: 48.949556, lng: 18.816728 },
    { lat: 48.949583, lng: 18.816728 },
    { lat: 48.949545, lng: 18.816552 },
    { lat: 48.949587, lng: 18.81646 },
    { lat: 48.949604, lng: 18.816378 },
    { lat: 48.94969, lng: 18.8163 },
    { lat: 48.949722, lng: 18.816131 },
    { lat: 48.949819, lng: 18.816088 },
    { lat: 48.949882, lng: 18.816035 },
    { lat: 48.949984, lng: 18.815677 },
    { lat: 48.950077, lng: 18.8154 },
    { lat: 48.950187, lng: 18.815271 },
    { lat: 48.950189, lng: 18.815188 },
    { lat: 48.95013, lng: 18.815036 },
    { lat: 48.950143, lng: 18.814962 },
    { lat: 48.950146, lng: 18.814822 },
    { lat: 48.950132, lng: 18.814702 },
    { lat: 48.950199, lng: 18.814631 },
    { lat: 48.950222, lng: 18.814508 },
    { lat: 48.950218, lng: 18.814199 },
    { lat: 48.950249, lng: 18.814071 },
    { lat: 48.9502, lng: 18.814018 },
    { lat: 48.950291, lng: 18.813815 },
    { lat: 48.950229, lng: 18.813806 },
    { lat: 48.950313, lng: 18.813637 },
    { lat: 48.950359, lng: 18.813582 },
    { lat: 48.9502127, lng: 18.8136084 },
    { lat: 48.9463201, lng: 18.8139314 },
    { lat: 48.9444861, lng: 18.8142017 },
    { lat: 48.9420474, lng: 18.8131429 },
    { lat: 48.9419369, lng: 18.8131676 },
    { lat: 48.9418853, lng: 18.8131935 },
    { lat: 48.9408541, lng: 18.8130113 },
    { lat: 48.9393419, lng: 18.8122482 },
    { lat: 48.9373435, lng: 18.8163463 },
    { lat: 48.937235, lng: 18.816474 },
    { lat: 48.9371914, lng: 18.8165495 },
    { lat: 48.9369788, lng: 18.8170559 },
    { lat: 48.9367602, lng: 18.8178431 },
    { lat: 48.9362728, lng: 18.8197066 },
    { lat: 48.9358297, lng: 18.8215564 },
    { lat: 48.9357779, lng: 18.8218829 },
    { lat: 48.9357997, lng: 18.8223261 },
    { lat: 48.9360824, lng: 18.8244945 },
    { lat: 48.9361842, lng: 18.8248482 },
    { lat: 48.936454, lng: 18.8252686 },
    { lat: 48.9365852, lng: 18.8254057 },
    { lat: 48.9371031, lng: 18.8258058 },
    { lat: 48.9373139, lng: 18.8260676 },
    { lat: 48.9373614, lng: 18.8263277 },
    { lat: 48.9373115, lng: 18.8267364 },
    { lat: 48.937152, lng: 18.8271199 },
    { lat: 48.936967, lng: 18.827161 },
    { lat: 48.9366224, lng: 18.8273649 },
    { lat: 48.9363542, lng: 18.8273467 },
    { lat: 48.9360599, lng: 18.8273901 },
    { lat: 48.9358355, lng: 18.827542 },
    { lat: 48.9356874, lng: 18.827752 },
    { lat: 48.9358247, lng: 18.8279841 },
    { lat: 48.9359729, lng: 18.8280826 },
    { lat: 48.9360776, lng: 18.8281003 },
    { lat: 48.9362133, lng: 18.8282849 },
    { lat: 48.9363704, lng: 18.8284211 },
    { lat: 48.9367736, lng: 18.8289146 },
    { lat: 48.9369552, lng: 18.829068 },
    { lat: 48.9372463, lng: 18.8290271 },
    { lat: 48.9375722, lng: 18.8285901 },
    { lat: 48.9376651, lng: 18.8282067 },
    { lat: 48.9378286, lng: 18.8279889 },
    { lat: 48.937933, lng: 18.8280697 },
    { lat: 48.9382426, lng: 18.8283623 },
    { lat: 48.9383093, lng: 18.8287156 },
    { lat: 48.9383563, lng: 18.8293818 },
    { lat: 48.9384172, lng: 18.8295348 },
    { lat: 48.9385038, lng: 18.8296432 },
    { lat: 48.938752, lng: 18.8297309 },
    { lat: 48.9392816, lng: 18.8296594 },
    { lat: 48.9398143, lng: 18.8296027 },
    { lat: 48.9401583, lng: 18.8294289 },
    { lat: 48.9404252, lng: 18.8295546 },
    { lat: 48.9404932, lng: 18.8295586 },
    { lat: 48.9405867, lng: 18.8296016 },
    { lat: 48.9406045, lng: 18.829663 },
    { lat: 48.9411042, lng: 18.8298638 },
    { lat: 48.9413309, lng: 18.8299512 },
    { lat: 48.9415996, lng: 18.8302624 },
    { lat: 48.9417453, lng: 18.8306306 },
    { lat: 48.9417567, lng: 18.8306594 },
    { lat: 48.9419806, lng: 18.830585 },
    { lat: 48.9427122, lng: 18.8311706 },
    { lat: 48.9430066, lng: 18.8314745 },
    { lat: 48.9433419, lng: 18.8320504 },
    { lat: 48.9435362, lng: 18.8321886 },
    { lat: 48.9437944, lng: 18.831991 },
    { lat: 48.9439393, lng: 18.8318059 },
    { lat: 48.9443288, lng: 18.8320536 },
    { lat: 48.9446389, lng: 18.8327078 },
    { lat: 48.9446409, lng: 18.8328392 },
    { lat: 48.9446454, lng: 18.8331404 },
    { lat: 48.9440072, lng: 18.8338812 },
    { lat: 48.9441835, lng: 18.8344979 },
    { lat: 48.9443423, lng: 18.835238 },
    { lat: 48.9443255, lng: 18.8356307 },
    { lat: 48.9440999, lng: 18.8362201 },
    { lat: 48.9441539, lng: 18.8366304 },
    { lat: 48.94448, lng: 18.8370514 },
    { lat: 48.9448659, lng: 18.8370098 },
    { lat: 48.9451246, lng: 18.8370876 },
    { lat: 48.945624, lng: 18.8378026 },
    { lat: 48.9457673, lng: 18.8377973 },
    { lat: 48.9459277, lng: 18.8376584 },
    { lat: 48.9460945, lng: 18.8368945 },
    { lat: 48.9460981, lng: 18.836219 },
    { lat: 48.9461873, lng: 18.8359389 },
    { lat: 48.9465867, lng: 18.8357941 },
    { lat: 48.9468422, lng: 18.8358677 },
    { lat: 48.9469725, lng: 18.8360993 },
    { lat: 48.9471656, lng: 18.8364569 },
    { lat: 48.947416, lng: 18.837191 },
  ],
  Mošovce: [
    { lat: 48.878002, lng: 19.029292 },
    { lat: 48.8787, lng: 19.029222 },
    { lat: 48.878963, lng: 19.028822 },
    { lat: 48.87941, lng: 19.028152 },
    { lat: 48.880363, lng: 19.026711 },
    { lat: 48.880496, lng: 19.026529 },
    { lat: 48.881974, lng: 19.024531 },
    { lat: 48.88304, lng: 19.023521 },
    { lat: 48.883556, lng: 19.023733 },
    { lat: 48.884814, lng: 19.024252 },
    { lat: 48.885019, lng: 19.023914 },
    { lat: 48.885169, lng: 19.023702 },
    { lat: 48.885364, lng: 19.023595 },
    { lat: 48.885525, lng: 19.02354 },
    { lat: 48.885685, lng: 19.023416 },
    { lat: 48.885933, lng: 19.023213 },
    { lat: 48.88595, lng: 19.022958 },
    { lat: 48.885984, lng: 19.022455 },
    { lat: 48.885998, lng: 19.021844 },
    { lat: 48.88604, lng: 19.021369 },
    { lat: 48.885795, lng: 19.021023 },
    { lat: 48.885695, lng: 19.020767 },
    { lat: 48.885647, lng: 19.020403 },
    { lat: 48.885624, lng: 19.020113 },
    { lat: 48.885672, lng: 19.019863 },
    { lat: 48.885678, lng: 19.019641 },
    { lat: 48.88563, lng: 19.019195 },
    { lat: 48.885527, lng: 19.018334 },
    { lat: 48.88537, lng: 19.017327 },
    { lat: 48.885267, lng: 19.01645 },
    { lat: 48.88522, lng: 19.015843 },
    { lat: 48.88517, lng: 19.015366 },
    { lat: 48.885163, lng: 19.014939 },
    { lat: 48.885084, lng: 19.014392 },
    { lat: 48.884782, lng: 19.013234 },
    { lat: 48.884445, lng: 19.012086 },
    { lat: 48.884381, lng: 19.011839 },
    { lat: 48.884366, lng: 19.010745 },
    { lat: 48.884362, lng: 19.010475 },
    { lat: 48.88389, lng: 19.010156 },
    { lat: 48.883336, lng: 19.009746 },
    { lat: 48.882909, lng: 19.009216 },
    { lat: 48.88231, lng: 19.008979 },
    { lat: 48.881926, lng: 19.009215 },
    { lat: 48.88156, lng: 19.008291 },
    { lat: 48.881475, lng: 19.00759 },
    { lat: 48.88123, lng: 19.007129 },
    { lat: 48.880794, lng: 19.006232 },
    { lat: 48.880396, lng: 19.00585 },
    { lat: 48.880058, lng: 19.005449 },
    { lat: 48.879729, lng: 19.005059 },
    { lat: 48.879382, lng: 19.004747 },
    { lat: 48.879202, lng: 19.004477 },
    { lat: 48.879184, lng: 19.004162 },
    { lat: 48.879075, lng: 19.003921 },
    { lat: 48.879031, lng: 19.003547 },
    { lat: 48.87899, lng: 19.003418 },
    { lat: 48.878878, lng: 19.003146 },
    { lat: 48.878829, lng: 19.00303 },
    { lat: 48.878312, lng: 19.002125 },
    { lat: 48.878085, lng: 19.001836 },
    { lat: 48.877855, lng: 19.001526 },
    { lat: 48.877728, lng: 19.00129 },
    { lat: 48.877475, lng: 19.00067 },
    { lat: 48.877471, lng: 19.000414 },
    { lat: 48.877484, lng: 18.999949 },
    { lat: 48.877536, lng: 18.999735 },
    { lat: 48.877759, lng: 18.999198 },
    { lat: 48.877901, lng: 18.998803 },
    { lat: 48.877931, lng: 18.9986 },
    { lat: 48.87785, lng: 18.998293 },
    { lat: 48.877837, lng: 18.998041 },
    { lat: 48.877822, lng: 18.997701 },
    { lat: 48.877846, lng: 18.997217 },
    { lat: 48.877921, lng: 18.996778 },
    { lat: 48.877964, lng: 18.996285 },
    { lat: 48.877937, lng: 18.995943 },
    { lat: 48.877833, lng: 18.99566 },
    { lat: 48.877704, lng: 18.995237 },
    { lat: 48.877467, lng: 18.994738 },
    { lat: 48.877291, lng: 18.994233 },
    { lat: 48.877124, lng: 18.993673 },
    { lat: 48.877103, lng: 18.9936 },
    { lat: 48.877013, lng: 18.993314 },
    { lat: 48.876995, lng: 18.993171 },
    { lat: 48.876962, lng: 18.992886 },
    { lat: 48.877067, lng: 18.992573 },
    { lat: 48.877134, lng: 18.992341 },
    { lat: 48.877247, lng: 18.991811 },
    { lat: 48.877297, lng: 18.991622 },
    { lat: 48.877248, lng: 18.991254 },
    { lat: 48.877274, lng: 18.990997 },
    { lat: 48.877349, lng: 18.991056 },
    { lat: 48.877454, lng: 18.990468 },
    { lat: 48.877337, lng: 18.990205 },
    { lat: 48.87725, lng: 18.990024 },
    { lat: 48.877216, lng: 18.989951 },
    { lat: 48.877154, lng: 18.98966 },
    { lat: 48.877165, lng: 18.989411 },
    { lat: 48.877273, lng: 18.989248 },
    { lat: 48.877451, lng: 18.988938 },
    { lat: 48.877638, lng: 18.988695 },
    { lat: 48.877869, lng: 18.988498 },
    { lat: 48.878057, lng: 18.988247 },
    { lat: 48.878192, lng: 18.987924 },
    { lat: 48.878346, lng: 18.98754 },
    { lat: 48.878209, lng: 18.987124 },
    { lat: 48.878105, lng: 18.986635 },
    { lat: 48.877973, lng: 18.985895 },
    { lat: 48.87785, lng: 18.985236 },
    { lat: 48.877701, lng: 18.985101 },
    { lat: 48.877487, lng: 18.984879 },
    { lat: 48.8773, lng: 18.984543 },
    { lat: 48.877245, lng: 18.984374 },
    { lat: 48.877236, lng: 18.984134 },
    { lat: 48.877284, lng: 18.983827 },
    { lat: 48.877336, lng: 18.983471 },
    { lat: 48.877433, lng: 18.983098 },
    { lat: 48.877444, lng: 18.982721 },
    { lat: 48.877393, lng: 18.982157 },
    { lat: 48.8773, lng: 18.981621 },
    { lat: 48.877358, lng: 18.981152 },
    { lat: 48.877369, lng: 18.980658 },
    { lat: 48.877444, lng: 18.980212 },
    { lat: 48.877428, lng: 18.979773 },
    { lat: 48.877378, lng: 18.979278 },
    { lat: 48.877169, lng: 18.978836 },
    { lat: 48.877168, lng: 18.978759 },
    { lat: 48.877164, lng: 18.978493 },
    { lat: 48.877195, lng: 18.978197 },
    { lat: 48.877225, lng: 18.977931 },
    { lat: 48.877253, lng: 18.977505 },
    { lat: 48.877155, lng: 18.977141 },
    { lat: 48.876943, lng: 18.976728 },
    { lat: 48.876769, lng: 18.976368 },
    { lat: 48.876774, lng: 18.976112 },
    { lat: 48.876783, lng: 18.975773 },
    { lat: 48.876652, lng: 18.975333 },
    { lat: 48.876656, lng: 18.974853 },
    { lat: 48.876998, lng: 18.974714 },
    { lat: 48.877268, lng: 18.974505 },
    { lat: 48.87753, lng: 18.974473 },
    { lat: 48.877616, lng: 18.974462 },
    { lat: 48.878088, lng: 18.974473 },
    { lat: 48.878639, lng: 18.974466 },
    { lat: 48.87906, lng: 18.974441 },
    { lat: 48.87912, lng: 18.974437 },
    { lat: 48.879408, lng: 18.974077 },
    { lat: 48.879596, lng: 18.973811 },
    { lat: 48.879789, lng: 18.973537 },
    { lat: 48.879945, lng: 18.973171 },
    { lat: 48.880167, lng: 18.972825 },
    { lat: 48.880261, lng: 18.972557 },
    { lat: 48.880376, lng: 18.97207 },
    { lat: 48.880512, lng: 18.971806 },
    { lat: 48.881064, lng: 18.971498 },
    { lat: 48.881212, lng: 18.971476 },
    { lat: 48.881741, lng: 18.971373 },
    { lat: 48.88211, lng: 18.971152 },
    { lat: 48.882307, lng: 18.970533 },
    { lat: 48.88241, lng: 18.969839 },
    { lat: 48.882541, lng: 18.969144 },
    { lat: 48.882699, lng: 18.96868 },
    { lat: 48.882772, lng: 18.968578 },
    { lat: 48.8833, lng: 18.96818 },
    { lat: 48.883784, lng: 18.967761 },
    { lat: 48.884013, lng: 18.966771 },
    { lat: 48.884447, lng: 18.966692 },
    { lat: 48.884798, lng: 18.9665 },
    { lat: 48.885117, lng: 18.966558 },
    { lat: 48.885563, lng: 18.966613 },
    { lat: 48.886146, lng: 18.96648 },
    { lat: 48.886516, lng: 18.966456 },
    { lat: 48.88688, lng: 18.966161 },
    { lat: 48.886983, lng: 18.965976 },
    { lat: 48.88704, lng: 18.965717 },
    { lat: 48.887194, lng: 18.965613 },
    { lat: 48.887329, lng: 18.965336 },
    { lat: 48.887622, lng: 18.964881 },
    { lat: 48.887914, lng: 18.964391 },
    { lat: 48.888033, lng: 18.964039 },
    { lat: 48.888112, lng: 18.963687 },
    { lat: 48.888139, lng: 18.963061 },
    { lat: 48.888202, lng: 18.962557 },
    { lat: 48.888305, lng: 18.961954 },
    { lat: 48.888439, lng: 18.961378 },
    { lat: 48.88872, lng: 18.961233 },
    { lat: 48.889039, lng: 18.961224 },
    { lat: 48.889135, lng: 18.961291 },
    { lat: 48.889325, lng: 18.961369 },
    { lat: 48.889708, lng: 18.961347 },
    { lat: 48.889922, lng: 18.96144 },
    { lat: 48.890199, lng: 18.961666 },
    { lat: 48.890329, lng: 18.9616 },
    { lat: 48.89043, lng: 18.961498 },
    { lat: 48.890825, lng: 18.961081 },
    { lat: 48.891111, lng: 18.960681 },
    { lat: 48.89133, lng: 18.959978 },
    { lat: 48.891824, lng: 18.959806 },
    { lat: 48.892881, lng: 18.959206 },
    { lat: 48.89334, lng: 18.958856 },
    { lat: 48.893474, lng: 18.958753 },
    { lat: 48.893629, lng: 18.958365 },
    { lat: 48.893853, lng: 18.958051 },
    { lat: 48.894042, lng: 18.957627 },
    { lat: 48.894245, lng: 18.95724 },
    { lat: 48.894427, lng: 18.956802 },
    { lat: 48.894465, lng: 18.9567 },
    { lat: 48.894567, lng: 18.955944 },
    { lat: 48.894741, lng: 18.955284 },
    { lat: 48.894922, lng: 18.954535 },
    { lat: 48.894987, lng: 18.954057 },
    { lat: 48.895321, lng: 18.953058 },
    { lat: 48.895354, lng: 18.952381 },
    { lat: 48.89537, lng: 18.951291 },
    { lat: 48.895475, lng: 18.950741 },
    { lat: 48.895618, lng: 18.95065 },
    { lat: 48.895959, lng: 18.950342 },
    { lat: 48.896145, lng: 18.950416 },
    { lat: 48.89665, lng: 18.950429 },
    { lat: 48.896805, lng: 18.950353 },
    { lat: 48.897026, lng: 18.950269 },
    { lat: 48.897821, lng: 18.950798 },
    { lat: 48.898098, lng: 18.950822 },
    { lat: 48.898495, lng: 18.950907 },
    { lat: 48.898646, lng: 18.950925 },
    { lat: 48.898831, lng: 18.95092 },
    { lat: 48.899015, lng: 18.950777 },
    { lat: 48.89945, lng: 18.950401 },
    { lat: 48.899515, lng: 18.950347 },
    { lat: 48.899599, lng: 18.950318 },
    { lat: 48.899844, lng: 18.950373 },
    { lat: 48.900227, lng: 18.950576 },
    { lat: 48.900535, lng: 18.950816 },
    { lat: 48.900659, lng: 18.9508 },
    { lat: 48.901076, lng: 18.950699 },
    { lat: 48.901262, lng: 18.950477 },
    { lat: 48.901515, lng: 18.950312 },
    { lat: 48.901737, lng: 18.950102 },
    { lat: 48.90202, lng: 18.949939 },
    { lat: 48.902332, lng: 18.950122 },
    { lat: 48.902566, lng: 18.950435 },
    { lat: 48.903086, lng: 18.950244 },
    { lat: 48.903295, lng: 18.95021 },
    { lat: 48.903617, lng: 18.949981 },
    { lat: 48.903667, lng: 18.949946 },
    { lat: 48.903955, lng: 18.949621 },
    { lat: 48.904238, lng: 18.949132 },
    { lat: 48.904412, lng: 18.94861 },
    { lat: 48.904693, lng: 18.947828 },
    { lat: 48.904893, lng: 18.947495 },
    { lat: 48.905293, lng: 18.948464 },
    { lat: 48.905535, lng: 18.948644 },
    { lat: 48.9057, lng: 18.948535 },
    { lat: 48.905994, lng: 18.948287 },
    { lat: 48.90628, lng: 18.947903 },
    { lat: 48.906624, lng: 18.947292 },
    { lat: 48.907083, lng: 18.946913 },
    { lat: 48.90768, lng: 18.946719 },
    { lat: 48.907903, lng: 18.946436 },
    { lat: 48.90811, lng: 18.94613 },
    { lat: 48.908417, lng: 18.945875 },
    { lat: 48.90868, lng: 18.945538 },
    { lat: 48.908925, lng: 18.944987 },
    { lat: 48.909191, lng: 18.944824 },
    { lat: 48.909591, lng: 18.944693 },
    { lat: 48.909993, lng: 18.944662 },
    { lat: 48.910538, lng: 18.944531 },
    { lat: 48.91102, lng: 18.944424 },
    { lat: 48.911514, lng: 18.944232 },
    { lat: 48.911641, lng: 18.944045 },
    { lat: 48.911693, lng: 18.943969 },
    { lat: 48.911796, lng: 18.943773 },
    { lat: 48.912048, lng: 18.94319 },
    { lat: 48.912255, lng: 18.9429 },
    { lat: 48.912952, lng: 18.941717 },
    { lat: 48.913588, lng: 18.940756 },
    { lat: 48.913842, lng: 18.940386 },
    { lat: 48.914175, lng: 18.940032 },
    { lat: 48.914353, lng: 18.939954 },
    { lat: 48.914498, lng: 18.939634 },
    { lat: 48.914655, lng: 18.939514 },
    { lat: 48.914976, lng: 18.939436 },
    { lat: 48.915273, lng: 18.939231 },
    { lat: 48.916002, lng: 18.939338 },
    { lat: 48.916019, lng: 18.938852 },
    { lat: 48.916196, lng: 18.938221 },
    { lat: 48.916345, lng: 18.9376 },
    { lat: 48.916487, lng: 18.937182 },
    { lat: 48.916537, lng: 18.937013 },
    { lat: 48.916874, lng: 18.936288 },
    { lat: 48.917119, lng: 18.935832 },
    { lat: 48.91744, lng: 18.935342 },
    { lat: 48.917738, lng: 18.934935 },
    { lat: 48.918025, lng: 18.934715 },
    { lat: 48.918408, lng: 18.934776 },
    { lat: 48.918737, lng: 18.934714 },
    { lat: 48.918776, lng: 18.934637 },
    { lat: 48.918883, lng: 18.934422 },
    { lat: 48.919043, lng: 18.934159 },
    { lat: 48.91931, lng: 18.933809 },
    { lat: 48.919397, lng: 18.933576 },
    { lat: 48.919522, lng: 18.933529 },
    { lat: 48.919699, lng: 18.933485 },
    { lat: 48.920515, lng: 18.93139 },
    { lat: 48.921377, lng: 18.928094 },
    { lat: 48.921435, lng: 18.92778 },
    { lat: 48.92144, lng: 18.927368 },
    { lat: 48.921419, lng: 18.926901 },
    { lat: 48.921356, lng: 18.92645 },
    { lat: 48.921297, lng: 18.92615 },
    { lat: 48.921213, lng: 18.925784 },
    { lat: 48.921171, lng: 18.925495 },
    { lat: 48.921131, lng: 18.924795 },
    { lat: 48.921112, lng: 18.924214 },
    { lat: 48.921148, lng: 18.923701 },
    { lat: 48.921118, lng: 18.923454 },
    { lat: 48.921133, lng: 18.923324 },
    { lat: 48.9212, lng: 18.922458 },
    { lat: 48.921277, lng: 18.920934 },
    { lat: 48.921531, lng: 18.919702 },
    { lat: 48.921777, lng: 18.9185 },
    { lat: 48.921881, lng: 18.917997 },
    { lat: 48.922199, lng: 18.917025 },
    { lat: 48.923046, lng: 18.914593 },
    { lat: 48.923083, lng: 18.914546 },
    { lat: 48.92312, lng: 18.914498 },
    { lat: 48.923138, lng: 18.914475 },
    { lat: 48.92386, lng: 18.912755 },
    { lat: 48.924221, lng: 18.911203 },
    { lat: 48.924226, lng: 18.911161 },
    { lat: 48.924251, lng: 18.910916 },
    { lat: 48.924302, lng: 18.910436 },
    { lat: 48.924361, lng: 18.91039 },
    { lat: 48.924903, lng: 18.909971 },
    { lat: 48.925028, lng: 18.909874 },
    { lat: 48.92531, lng: 18.909632 },
    { lat: 48.925488, lng: 18.909401 },
    { lat: 48.925593, lng: 18.909074 },
    { lat: 48.926407, lng: 18.907662 },
    { lat: 48.926854, lng: 18.906597 },
    { lat: 48.927474, lng: 18.905814 },
    { lat: 48.927563, lng: 18.905663 },
    { lat: 48.927585, lng: 18.905627 },
    { lat: 48.927874, lng: 18.905151 },
    { lat: 48.929077, lng: 18.902965 },
    { lat: 48.929546, lng: 18.90208 },
    { lat: 48.929838, lng: 18.901799 },
    { lat: 48.931006, lng: 18.900052 },
    { lat: 48.931506, lng: 18.899298 },
    { lat: 48.931575, lng: 18.899219 },
    { lat: 48.931603, lng: 18.899155 },
    { lat: 48.93181, lng: 18.898949 },
    { lat: 48.932065, lng: 18.898756 },
    { lat: 48.932339, lng: 18.898194 },
    { lat: 48.933179, lng: 18.896982 },
    { lat: 48.933477, lng: 18.896586 },
    { lat: 48.933671, lng: 18.89647 },
    { lat: 48.933767, lng: 18.89641 },
    { lat: 48.934358, lng: 18.895428 },
    { lat: 48.934999, lng: 18.894256 },
    { lat: 48.935023, lng: 18.894174 },
    { lat: 48.935065, lng: 18.894029 },
    { lat: 48.935086, lng: 18.893955 },
    { lat: 48.935454, lng: 18.8928 },
    { lat: 48.93569, lng: 18.892304 },
    { lat: 48.935737, lng: 18.892325 },
    { lat: 48.93605, lng: 18.892463 },
    { lat: 48.936219, lng: 18.891194 },
    { lat: 48.936485, lng: 18.889207 },
    { lat: 48.936451, lng: 18.888894 },
    { lat: 48.936294, lng: 18.88825 },
    { lat: 48.936339, lng: 18.88787 },
    { lat: 48.936508, lng: 18.886427 },
    { lat: 48.9361, lng: 18.885638 },
    { lat: 48.936008, lng: 18.883987 },
    { lat: 48.936267, lng: 18.882805 },
    { lat: 48.936865, lng: 18.88012 },
    { lat: 48.937224, lng: 18.878615 },
    { lat: 48.937685, lng: 18.876929 },
    { lat: 48.937907, lng: 18.87578 },
    { lat: 48.938181, lng: 18.874381 },
    { lat: 48.938322, lng: 18.873662 },
    { lat: 48.938546, lng: 18.872709 },
    { lat: 48.938513, lng: 18.872552 },
    { lat: 48.938662, lng: 18.869735 },
    { lat: 48.938558, lng: 18.868932 },
    { lat: 48.938091, lng: 18.866177 },
    { lat: 48.937866, lng: 18.864861 },
    { lat: 48.9375633, lng: 18.8635783 },
    { lat: 48.9363484, lng: 18.8633301 },
    { lat: 48.9342099, lng: 18.8627915 },
    { lat: 48.9323527, lng: 18.8621526 },
    { lat: 48.932171, lng: 18.859326 },
    { lat: 48.9320937, lng: 18.8593352 },
    { lat: 48.9320606, lng: 18.8594582 },
    { lat: 48.9315376, lng: 18.860359 },
    { lat: 48.9301414, lng: 18.8612954 },
    { lat: 48.930113, lng: 18.8616892 },
    { lat: 48.9299042, lng: 18.8618151 },
    { lat: 48.9296189, lng: 18.8621194 },
    { lat: 48.929498, lng: 18.8621165 },
    { lat: 48.9293554, lng: 18.862151 },
    { lat: 48.9291402, lng: 18.8623723 },
    { lat: 48.927465, lng: 18.8613261 },
    { lat: 48.9246553, lng: 18.8587781 },
    { lat: 48.9245842, lng: 18.8587402 },
    { lat: 48.924509, lng: 18.8588857 },
    { lat: 48.9244829, lng: 18.8589705 },
    { lat: 48.9245259, lng: 18.8593466 },
    { lat: 48.9243898, lng: 18.8598547 },
    { lat: 48.9242564, lng: 18.8601782 },
    { lat: 48.924024, lng: 18.8604141 },
    { lat: 48.9239776, lng: 18.860435 },
    { lat: 48.9238422, lng: 18.8605626 },
    { lat: 48.9228844, lng: 18.8615177 },
    { lat: 48.9215992, lng: 18.8627232 },
    { lat: 48.9213079, lng: 18.8631794 },
    { lat: 48.9197588, lng: 18.8614077 },
    { lat: 48.9184254, lng: 18.8599063 },
    { lat: 48.9168306, lng: 18.8581062 },
    { lat: 48.9163681, lng: 18.8576011 },
    { lat: 48.9163097, lng: 18.8577138 },
    { lat: 48.9161661, lng: 18.8579671 },
    { lat: 48.9159619, lng: 18.8583369 },
    { lat: 48.9147423, lng: 18.8602931 },
    { lat: 48.9137399, lng: 18.8618037 },
    { lat: 48.9151095, lng: 18.8633061 },
    { lat: 48.9126011, lng: 18.8689874 },
    { lat: 48.9125523, lng: 18.8690441 },
    { lat: 48.9117266, lng: 18.8708834 },
    { lat: 48.9109871, lng: 18.8727041 },
    { lat: 48.910648, lng: 18.8735662 },
    { lat: 48.9103332, lng: 18.8743057 },
    { lat: 48.9101483, lng: 18.8747265 },
    { lat: 48.9099086, lng: 18.8753678 },
    { lat: 48.9091866, lng: 18.8738467 },
    { lat: 48.9080957, lng: 18.8722395 },
    { lat: 48.9063418, lng: 18.8695754 },
    { lat: 48.9060989, lng: 18.8694794 },
    { lat: 48.9052958, lng: 18.8697584 },
    { lat: 48.9040999, lng: 18.8703351 },
    { lat: 48.90369, lng: 18.8704794 },
    { lat: 48.9033339, lng: 18.8705696 },
    { lat: 48.902925, lng: 18.8704252 },
    { lat: 48.9023994, lng: 18.8704874 },
    { lat: 48.9022112, lng: 18.870466 },
    { lat: 48.9005237, lng: 18.869182 },
    { lat: 48.9003564, lng: 18.8691531 },
    { lat: 48.9003307, lng: 18.8691601 },
    { lat: 48.9003355, lng: 18.8692061 },
    { lat: 48.9003162, lng: 18.8692492 },
    { lat: 48.8998442, lng: 18.8693252 },
    { lat: 48.899526, lng: 18.8694267 },
    { lat: 48.8990539, lng: 18.8696754 },
    { lat: 48.8986117, lng: 18.8700216 },
    { lat: 48.8984731, lng: 18.8700911 },
    { lat: 48.898288, lng: 18.8701954 },
    { lat: 48.8972702, lng: 18.8710373 },
    { lat: 48.8970911, lng: 18.8725066 },
    { lat: 48.8967878, lng: 18.875008 },
    { lat: 48.8966247, lng: 18.87633 },
    { lat: 48.8965924, lng: 18.8763596 },
    { lat: 48.8965529, lng: 18.8764266 },
    { lat: 48.8965319, lng: 18.8764778 },
    { lat: 48.894879, lng: 18.8788714 },
    { lat: 48.8947716, lng: 18.8799383 },
    { lat: 48.8946393, lng: 18.880633 },
    { lat: 48.8945735, lng: 18.883128 },
    { lat: 48.8945602, lng: 18.8831787 },
    { lat: 48.8941921, lng: 18.8846376 },
    { lat: 48.8936835, lng: 18.8862826 },
    { lat: 48.8922884, lng: 18.8892844 },
    { lat: 48.89227, lng: 18.8893235 },
    { lat: 48.8905519, lng: 18.8917696 },
    { lat: 48.8897251, lng: 18.8936296 },
    { lat: 48.8897068, lng: 18.8936694 },
    { lat: 48.8892967, lng: 18.8943806 },
    { lat: 48.8890069, lng: 18.8947741 },
    { lat: 48.8886801, lng: 18.8952193 },
    { lat: 48.8882062, lng: 18.8958946 },
    { lat: 48.8867777, lng: 18.8977881 },
    { lat: 48.8864305, lng: 18.8982937 },
    { lat: 48.8863454, lng: 18.8984183 },
    { lat: 48.8863193, lng: 18.8984565 },
    { lat: 48.8853546, lng: 18.9005131 },
    { lat: 48.8845416, lng: 18.902341 },
    { lat: 48.8840623, lng: 18.9028878 },
    { lat: 48.8840389, lng: 18.902915 },
    { lat: 48.884004, lng: 18.9029617 },
    { lat: 48.8839398, lng: 18.9030506 },
    { lat: 48.8837269, lng: 18.9032956 },
    { lat: 48.8831298, lng: 18.9040738 },
    { lat: 48.883091, lng: 18.9041339 },
    { lat: 48.8831064, lng: 18.9043689 },
    { lat: 48.8830572, lng: 18.9045763 },
    { lat: 48.8827235, lng: 18.9052153 },
    { lat: 48.8824348, lng: 18.9058952 },
    { lat: 48.8822068, lng: 18.9065497 },
    { lat: 48.8822037, lng: 18.9067119 },
    { lat: 48.8822226, lng: 18.9071461 },
    { lat: 48.8821641, lng: 18.907724 },
    { lat: 48.8816413, lng: 18.9099987 },
    { lat: 48.8814721, lng: 18.9104602 },
    { lat: 48.8813528, lng: 18.9111478 },
    { lat: 48.8814016, lng: 18.9117926 },
    { lat: 48.8813699, lng: 18.9126278 },
    { lat: 48.8814774, lng: 18.9139606 },
    { lat: 48.8814862, lng: 18.9143533 },
    { lat: 48.8811467, lng: 18.915278 },
    { lat: 48.8809841, lng: 18.9163328 },
    { lat: 48.8809568, lng: 18.9169034 },
    { lat: 48.880998, lng: 18.9174237 },
    { lat: 48.8809898, lng: 18.9181874 },
    { lat: 48.8809437, lng: 18.9187187 },
    { lat: 48.8809813, lng: 18.9197875 },
    { lat: 48.8808942, lng: 18.920535 },
    { lat: 48.8810812, lng: 18.9211252 },
    { lat: 48.8808412, lng: 18.9210846 },
    { lat: 48.8806386, lng: 18.9212485 },
    { lat: 48.8802383, lng: 18.9214645 },
    { lat: 48.8799717, lng: 18.9217222 },
    { lat: 48.8794742, lng: 18.9218715 },
    { lat: 48.8790672, lng: 18.922002 },
    { lat: 48.8787006, lng: 18.9220221 },
    { lat: 48.8783446, lng: 18.9219833 },
    { lat: 48.8780534, lng: 18.9218 },
    { lat: 48.8777053, lng: 18.9217609 },
    { lat: 48.877669, lng: 18.9217573 },
    { lat: 48.8776245, lng: 18.9217704 },
    { lat: 48.8775459, lng: 18.9220401 },
    { lat: 48.8772732, lng: 18.9225298 },
    { lat: 48.8771802, lng: 18.9227628 },
    { lat: 48.8770326, lng: 18.9232279 },
    { lat: 48.8767603, lng: 18.9236134 },
    { lat: 48.876512, lng: 18.9240075 },
    { lat: 48.8762748, lng: 18.9245085 },
    { lat: 48.876004, lng: 18.9250585 },
    { lat: 48.8758984, lng: 18.9251322 },
    { lat: 48.8758232, lng: 18.925117 },
    { lat: 48.8758081, lng: 18.9251965 },
    { lat: 48.8757323, lng: 18.9255854 },
    { lat: 48.8755474, lng: 18.9262726 },
    { lat: 48.8753889, lng: 18.9267464 },
    { lat: 48.8751967, lng: 18.9272213 },
    { lat: 48.8751555, lng: 18.9273454 },
    { lat: 48.8751216, lng: 18.9276703 },
    { lat: 48.8750642, lng: 18.928204 },
    { lat: 48.8750242, lng: 18.9287488 },
    { lat: 48.874975, lng: 18.9288642 },
    { lat: 48.8748585, lng: 18.9290816 },
    { lat: 48.874713, lng: 18.9292541 },
    { lat: 48.8735184, lng: 18.9286927 },
    { lat: 48.8730779, lng: 18.9285889 },
    { lat: 48.8724844, lng: 18.9284446 },
    { lat: 48.8721358, lng: 18.9284661 },
    { lat: 48.8718397, lng: 18.9284281 },
    { lat: 48.8713024, lng: 18.9283062 },
    { lat: 48.8709595, lng: 18.9282168 },
    { lat: 48.8704919, lng: 18.9282852 },
    { lat: 48.8701483, lng: 18.9282928 },
    { lat: 48.8697322, lng: 18.9284028 },
    { lat: 48.8695685, lng: 18.9283754 },
    { lat: 48.8694014, lng: 18.9282826 },
    { lat: 48.8692792, lng: 18.9281199 },
    { lat: 48.8691279, lng: 18.9279185 },
    { lat: 48.8687453, lng: 18.9277309 },
    { lat: 48.8685012, lng: 18.927675 },
    { lat: 48.868176, lng: 18.9278438 },
    { lat: 48.8675864, lng: 18.9281102 },
    { lat: 48.8670659, lng: 18.9290235 },
    { lat: 48.8668836, lng: 18.9292991 },
    { lat: 48.8668239, lng: 18.9294762 },
    { lat: 48.8668715, lng: 18.9297006 },
    { lat: 48.8670749, lng: 18.9301724 },
    { lat: 48.8671339, lng: 18.9315522 },
    { lat: 48.8673685, lng: 18.9318871 },
    { lat: 48.8677793, lng: 18.9324461 },
    { lat: 48.8679767, lng: 18.9328703 },
    { lat: 48.8680346, lng: 18.9331436 },
    { lat: 48.8682424, lng: 18.9339028 },
    { lat: 48.8682864, lng: 18.9343016 },
    { lat: 48.868439, lng: 18.934528 },
    { lat: 48.8687688, lng: 18.9348067 },
    { lat: 48.869089, lng: 18.935184 },
    { lat: 48.869382, lng: 18.9355544 },
    { lat: 48.8696279, lng: 18.9359339 },
    { lat: 48.8699257, lng: 18.9363349 },
    { lat: 48.8702372, lng: 18.9366298 },
    { lat: 48.8698716, lng: 18.9372682 },
    { lat: 48.8695831, lng: 18.9376246 },
    { lat: 48.8692964, lng: 18.9379482 },
    { lat: 48.8690707, lng: 18.9381469 },
    { lat: 48.8687267, lng: 18.9382992 },
    { lat: 48.8684533, lng: 18.938531 },
    { lat: 48.8684075, lng: 18.938517 },
    { lat: 48.8684223, lng: 18.9382976 },
    { lat: 48.8683206, lng: 18.937716 },
    { lat: 48.8682201, lng: 18.9375136 },
    { lat: 48.8680349, lng: 18.9372631 },
    { lat: 48.8676179, lng: 18.9368273 },
    { lat: 48.8674877, lng: 18.9367713 },
    { lat: 48.8674252, lng: 18.9367607 },
    { lat: 48.867281, lng: 18.9368094 },
    { lat: 48.8670931, lng: 18.9368826 },
    { lat: 48.8669348, lng: 18.9368969 },
    { lat: 48.8668027, lng: 18.9368668 },
    { lat: 48.8666754, lng: 18.9368273 },
    { lat: 48.8664947, lng: 18.9365773 },
    { lat: 48.8663882, lng: 18.9364104 },
    { lat: 48.8657856, lng: 18.935254 },
    { lat: 48.8656683, lng: 18.935075 },
    { lat: 48.8655795, lng: 18.9350109 },
    { lat: 48.8654282, lng: 18.9349857 },
    { lat: 48.8653264, lng: 18.9349349 },
    { lat: 48.8652736, lng: 18.9348505 },
    { lat: 48.8649411, lng: 18.934301 },
    { lat: 48.8645074, lng: 18.9338665 },
    { lat: 48.8641198, lng: 18.9335155 },
    { lat: 48.8640199, lng: 18.9334795 },
    { lat: 48.8639419, lng: 18.9334751 },
    { lat: 48.863666, lng: 18.9335915 },
    { lat: 48.863401, lng: 18.9336812 },
    { lat: 48.8631298, lng: 18.9338437 },
    { lat: 48.8630318, lng: 18.9338695 },
    { lat: 48.8625486, lng: 18.9339232 },
    { lat: 48.8623493, lng: 18.9339716 },
    { lat: 48.8618313, lng: 18.9341643 },
    { lat: 48.861575, lng: 18.9340443 },
    { lat: 48.861326, lng: 18.9339624 },
    { lat: 48.8594999, lng: 18.9335914 },
    { lat: 48.8593283, lng: 18.9335187 },
    { lat: 48.8589453, lng: 18.933218 },
    { lat: 48.8585804, lng: 18.93285 },
    { lat: 48.8585215, lng: 18.932733 },
    { lat: 48.8583765, lng: 18.9323898 },
    { lat: 48.8581929, lng: 18.9318613 },
    { lat: 48.8580364, lng: 18.9312895 },
    { lat: 48.8579523, lng: 18.9310759 },
    { lat: 48.8578216, lng: 18.93077 },
    { lat: 48.857672, lng: 18.9304445 },
    { lat: 48.8576047, lng: 18.9301208 },
    { lat: 48.8576052, lng: 18.9296685 },
    { lat: 48.8575971, lng: 18.9292455 },
    { lat: 48.8575406, lng: 18.928999 },
    { lat: 48.8573523, lng: 18.9285312 },
    { lat: 48.8573347, lng: 18.9283435 },
    { lat: 48.8570058, lng: 18.9275746 },
    { lat: 48.8568573, lng: 18.9271821 },
    { lat: 48.8567642, lng: 18.9266408 },
    { lat: 48.8567069, lng: 18.9264252 },
    { lat: 48.8559973, lng: 18.9245613 },
    { lat: 48.8558303, lng: 18.9241827 },
    { lat: 48.8557076, lng: 18.923834 },
    { lat: 48.8556657, lng: 18.9234766 },
    { lat: 48.855667, lng: 18.9231682 },
    { lat: 48.8555611, lng: 18.9233023 },
    { lat: 48.8554202, lng: 18.9235451 },
    { lat: 48.8552474, lng: 18.9238733 },
    { lat: 48.8551847, lng: 18.9242362 },
    { lat: 48.8551204, lng: 18.9250092 },
    { lat: 48.8550605, lng: 18.9256038 },
    { lat: 48.8548024, lng: 18.927083 },
    { lat: 48.8546528, lng: 18.9276884 },
    { lat: 48.8546296, lng: 18.9279371 },
    { lat: 48.8545779, lng: 18.9285171 },
    { lat: 48.8544836, lng: 18.9289657 },
    { lat: 48.8542888, lng: 18.9296584 },
    { lat: 48.8541604, lng: 18.9304113 },
    { lat: 48.8540834, lng: 18.9311994 },
    { lat: 48.8540071, lng: 18.9315395 },
    { lat: 48.85375, lng: 18.9316731 },
    { lat: 48.8533375, lng: 18.9322294 },
    { lat: 48.8530897, lng: 18.9319473 },
    { lat: 48.852763, lng: 18.9318402 },
    { lat: 48.852625, lng: 18.9321191 },
    { lat: 48.8501972, lng: 18.930058 },
    { lat: 48.8495721, lng: 18.9317275 },
    { lat: 48.8485813, lng: 18.9343201 },
    { lat: 48.8485662, lng: 18.9345789 },
    { lat: 48.8485619, lng: 18.9349814 },
    { lat: 48.8485449, lng: 18.9351119 },
    { lat: 48.8483678, lng: 18.9350213 },
    { lat: 48.8482823, lng: 18.9350675 },
    { lat: 48.8480912, lng: 18.9350365 },
    { lat: 48.8476654, lng: 18.9349971 },
    { lat: 48.8476243, lng: 18.9349017 },
    { lat: 48.8475061, lng: 18.9348804 },
    { lat: 48.8473384, lng: 18.9347987 },
    { lat: 48.8468618, lng: 18.9348069 },
    { lat: 48.846652, lng: 18.9348028 },
    { lat: 48.8463931, lng: 18.9347235 },
    { lat: 48.8463009, lng: 18.9346398 },
    { lat: 48.8460527, lng: 18.9344602 },
    { lat: 48.8457995, lng: 18.9343369 },
    { lat: 48.8457999, lng: 18.9345857 },
    { lat: 48.8457773, lng: 18.9349857 },
    { lat: 48.8457074, lng: 18.9354746 },
    { lat: 48.8456007, lng: 18.9360552 },
    { lat: 48.8455469, lng: 18.9367065 },
    { lat: 48.8455598, lng: 18.9372649 },
    { lat: 48.8455861, lng: 18.9376051 },
    { lat: 48.8455194, lng: 18.937803 },
    { lat: 48.8453792, lng: 18.9379261 },
    { lat: 48.845294, lng: 18.9381365 },
    { lat: 48.845281, lng: 18.9383685 },
    { lat: 48.8451845, lng: 18.9385516 },
    { lat: 48.8451083, lng: 18.9389815 },
    { lat: 48.8450143, lng: 18.9394414 },
    { lat: 48.8449302, lng: 18.9399137 },
    { lat: 48.8448137, lng: 18.9403261 },
    { lat: 48.8446265, lng: 18.9408588 },
    { lat: 48.8443284, lng: 18.9416901 },
    { lat: 48.8439145, lng: 18.9427497 },
    { lat: 48.8436362, lng: 18.9437762 },
    { lat: 48.8434186, lng: 18.944529 },
    { lat: 48.8431307, lng: 18.9454078 },
    { lat: 48.8429654, lng: 18.9459479 },
    { lat: 48.8428426, lng: 18.9461899 },
    { lat: 48.8427688, lng: 18.946226 },
    { lat: 48.8426153, lng: 18.9464 },
    { lat: 48.8424003, lng: 18.9465855 },
    { lat: 48.8421934, lng: 18.9467951 },
    { lat: 48.8419218, lng: 18.94715 },
    { lat: 48.8418017, lng: 18.9473158 },
    { lat: 48.8416176, lng: 18.9477099 },
    { lat: 48.8414676, lng: 18.9480871 },
    { lat: 48.8412565, lng: 18.9485033 },
    { lat: 48.8410946, lng: 18.9488447 },
    { lat: 48.8409171, lng: 18.9493218 },
    { lat: 48.8407808, lng: 18.9497225 },
    { lat: 48.8407179, lng: 18.9500194 },
    { lat: 48.8406175, lng: 18.9505265 },
    { lat: 48.8405143, lng: 18.9510697 },
    { lat: 48.8404883, lng: 18.9511776 },
    { lat: 48.8403288, lng: 18.9516318 },
    { lat: 48.8401739, lng: 18.9521348 },
    { lat: 48.8400735, lng: 18.9524458 },
    { lat: 48.8399141, lng: 18.952746 },
    { lat: 48.8396893, lng: 18.9529405 },
    { lat: 48.8395352, lng: 18.9531886 },
    { lat: 48.839412, lng: 18.9535831 },
    { lat: 48.8393875, lng: 18.9539005 },
    { lat: 48.8394962, lng: 18.9549742 },
    { lat: 48.8395004, lng: 18.9555224 },
    { lat: 48.839461, lng: 18.9561004 },
    { lat: 48.8393406, lng: 18.9565306 },
    { lat: 48.839082, lng: 18.9570962 },
    { lat: 48.8388931, lng: 18.9574182 },
    { lat: 48.8388125, lng: 18.9576509 },
    { lat: 48.8387681, lng: 18.9579295 },
    { lat: 48.8387731, lng: 18.9583402 },
    { lat: 48.8387922, lng: 18.9586057 },
    { lat: 48.8388333, lng: 18.9587716 },
    { lat: 48.8389089, lng: 18.9589489 },
    { lat: 48.8389792, lng: 18.959226 },
    { lat: 48.8390213, lng: 18.9593664 },
    { lat: 48.8390436, lng: 18.9595413 },
    { lat: 48.8389983, lng: 18.9598455 },
    { lat: 48.8389319, lng: 18.9605383 },
    { lat: 48.8387744, lng: 18.9611218 },
    { lat: 48.8385622, lng: 18.961382 },
    { lat: 48.8384739, lng: 18.9618855 },
    { lat: 48.8384116, lng: 18.962372 },
    { lat: 48.8383671, lng: 18.9625837 },
    { lat: 48.8382578, lng: 18.9628272 },
    { lat: 48.8378767, lng: 18.9637406 },
    { lat: 48.8378276, lng: 18.9640401 },
    { lat: 48.8377546, lng: 18.9646213 },
    { lat: 48.8376453, lng: 18.9652245 },
    { lat: 48.8375375, lng: 18.9655445 },
    { lat: 48.8373334, lng: 18.9662991 },
    { lat: 48.8372357, lng: 18.9665957 },
    { lat: 48.837149, lng: 18.9667969 },
    { lat: 48.8370682, lng: 18.9669599 },
    { lat: 48.8369419, lng: 18.9671807 },
    { lat: 48.836822, lng: 18.9672849 },
    { lat: 48.8366877, lng: 18.9677718 },
    { lat: 48.8365153, lng: 18.9682868 },
    { lat: 48.8363786, lng: 18.9685672 },
    { lat: 48.8363541, lng: 18.9689104 },
    { lat: 48.8363959, lng: 18.9698239 },
    { lat: 48.8364058, lng: 18.971021 },
    { lat: 48.8364633, lng: 18.9711761 },
    { lat: 48.8365951, lng: 18.9715727 },
    { lat: 48.8366272, lng: 18.9718278 },
    { lat: 48.8366039, lng: 18.9720581 },
    { lat: 48.8365348, lng: 18.9724763 },
    { lat: 48.8364948, lng: 18.9726012 },
    { lat: 48.8364076, lng: 18.9729466 },
    { lat: 48.8362608, lng: 18.9737239 },
    { lat: 48.8360296, lng: 18.9743699 },
    { lat: 48.8359061, lng: 18.9751587 },
    { lat: 48.8357713, lng: 18.975543 },
    { lat: 48.8356645, lng: 18.9761742 },
    { lat: 48.835479, lng: 18.9768535 },
    { lat: 48.8352558, lng: 18.9780544 },
    { lat: 48.8349751, lng: 18.9787268 },
    { lat: 48.8348724, lng: 18.9792133 },
    { lat: 48.8346122, lng: 18.9799793 },
    { lat: 48.8344196, lng: 18.980473 },
    { lat: 48.8343616, lng: 18.9805957 },
    { lat: 48.8340335, lng: 18.9811523 },
    { lat: 48.8339505, lng: 18.9813151 },
    { lat: 48.8339115, lng: 18.9815057 },
    { lat: 48.8338847, lng: 18.9826909 },
    { lat: 48.8339336, lng: 18.9835219 },
    { lat: 48.8339251, lng: 18.9840694 },
    { lat: 48.8340798, lng: 18.9844988 },
    { lat: 48.834355, lng: 18.9848824 },
    { lat: 48.8346479, lng: 18.9854783 },
    { lat: 48.8348632, lng: 18.9857661 },
    { lat: 48.8351151, lng: 18.9861538 },
    { lat: 48.8353148, lng: 18.9867409 },
    { lat: 48.835733, lng: 18.9877177 },
    { lat: 48.8359707, lng: 18.9885649 },
    { lat: 48.8360393, lng: 18.9890501 },
    { lat: 48.836051, lng: 18.9896417 },
    { lat: 48.8360054, lng: 18.9906147 },
    { lat: 48.8360813, lng: 18.9909989 },
    { lat: 48.8363282, lng: 18.9915565 },
    { lat: 48.8365729, lng: 18.992609 },
    { lat: 48.8367359, lng: 18.9930243 },
    { lat: 48.8372316, lng: 18.9940423 },
    { lat: 48.8374581, lng: 18.9945326 },
    { lat: 48.837517, lng: 18.9948758 },
    { lat: 48.8375239, lng: 18.9954798 },
    { lat: 48.8375229, lng: 18.9957931 },
    { lat: 48.8377352, lng: 18.9963164 },
    { lat: 48.8378796, lng: 18.9965646 },
    { lat: 48.8381038, lng: 18.996763 },
    { lat: 48.8383077, lng: 18.9968282 },
    { lat: 48.8383412, lng: 18.9966865 },
    { lat: 48.8384401, lng: 18.9961651 },
    { lat: 48.8385595, lng: 18.995871 },
    { lat: 48.8388682, lng: 18.9956689 },
    { lat: 48.8393338, lng: 18.995533 },
    { lat: 48.8397325, lng: 18.9954368 },
    { lat: 48.8402132, lng: 18.9953081 },
    { lat: 48.8404483, lng: 18.9952107 },
    { lat: 48.8411322, lng: 18.9948639 },
    { lat: 48.8413358, lng: 18.9947684 },
    { lat: 48.8415718, lng: 18.9946197 },
    { lat: 48.8416441, lng: 18.9945533 },
    { lat: 48.8417735, lng: 18.9944578 },
    { lat: 48.8419501, lng: 18.9943622 },
    { lat: 48.8420908, lng: 18.9942665 },
    { lat: 48.8423838, lng: 18.9942877 },
    { lat: 48.8425613, lng: 18.9942746 },
    { lat: 48.842787, lng: 18.994196 },
    { lat: 48.843001, lng: 18.993881 },
    { lat: 48.843235, lng: 18.99356 },
    { lat: 48.843489, lng: 18.993479 },
    { lat: 48.843783, lng: 18.993569 },
    { lat: 48.844181, lng: 18.993821 },
    { lat: 48.844266, lng: 18.993934 },
    { lat: 48.84456, lng: 18.994077 },
    { lat: 48.845663, lng: 18.993945 },
    { lat: 48.845918, lng: 18.993946 },
    { lat: 48.846189, lng: 18.994105 },
    { lat: 48.846269, lng: 18.994085 },
    { lat: 48.846354, lng: 18.994139 },
    { lat: 48.846553, lng: 18.994184 },
    { lat: 48.846636, lng: 18.994232 },
    { lat: 48.846686, lng: 18.994288 },
    { lat: 48.846864, lng: 18.99436 },
    { lat: 48.847187, lng: 18.994379 },
    { lat: 48.847296, lng: 18.994552 },
    { lat: 48.84737, lng: 18.994746 },
    { lat: 48.847498, lng: 18.995002 },
    { lat: 48.847554, lng: 18.995416 },
    { lat: 48.847569, lng: 18.996471 },
    { lat: 48.848053, lng: 18.996824 },
    { lat: 48.848254, lng: 18.996804 },
    { lat: 48.84873, lng: 18.997269 },
    { lat: 48.848796, lng: 18.997486 },
    { lat: 48.848916, lng: 18.998444 },
    { lat: 48.84925, lng: 18.999176 },
    { lat: 48.849295, lng: 18.999866 },
    { lat: 48.849424, lng: 19.00044 },
    { lat: 48.84951, lng: 19.000707 },
    { lat: 48.849534, lng: 19.000963 },
    { lat: 48.849416, lng: 19.001327 },
    { lat: 48.849167, lng: 19.001775 },
    { lat: 48.84907, lng: 19.002153 },
    { lat: 48.84906, lng: 19.002805 },
    { lat: 48.848999, lng: 19.003456 },
    { lat: 48.848875, lng: 19.00389 },
    { lat: 48.848849, lng: 19.004207 },
    { lat: 48.849064, lng: 19.004917 },
    { lat: 48.849144, lng: 19.005287 },
    { lat: 48.849235, lng: 19.005541 },
    { lat: 48.849438, lng: 19.005824 },
    { lat: 48.849692, lng: 19.006295 },
    { lat: 48.849848, lng: 19.00654 },
    { lat: 48.849829, lng: 19.006868 },
    { lat: 48.849744, lng: 19.007431 },
    { lat: 48.849804, lng: 19.008392 },
    { lat: 48.849951, lng: 19.008854 },
    { lat: 48.850247, lng: 19.00931 },
    { lat: 48.850302, lng: 19.009375 },
    { lat: 48.850567, lng: 19.009824 },
    { lat: 48.850802, lng: 19.010003 },
    { lat: 48.850914, lng: 19.010201 },
    { lat: 48.851064, lng: 19.010335 },
    { lat: 48.851137, lng: 19.010383 },
    { lat: 48.851414, lng: 19.010679 },
    { lat: 48.851559, lng: 19.010944 },
    { lat: 48.851671, lng: 19.010985 },
    { lat: 48.851921, lng: 19.011184 },
    { lat: 48.852019, lng: 19.011223 },
    { lat: 48.852103, lng: 19.011324 },
    { lat: 48.852398, lng: 19.011847 },
    { lat: 48.852626, lng: 19.012398 },
    { lat: 48.852742, lng: 19.012878 },
    { lat: 48.85274, lng: 19.013033 },
    { lat: 48.852841, lng: 19.013131 },
    { lat: 48.852951, lng: 19.013236 },
    { lat: 48.852972, lng: 19.013321 },
    { lat: 48.853267, lng: 19.013591 },
    { lat: 48.853743, lng: 19.014168 },
    { lat: 48.853856, lng: 19.014257 },
    { lat: 48.854302, lng: 19.0148 },
    { lat: 48.854657, lng: 19.014911 },
    { lat: 48.854837, lng: 19.014914 },
    { lat: 48.855247, lng: 19.015577 },
    { lat: 48.855365, lng: 19.015813 },
    { lat: 48.855476, lng: 19.016296 },
    { lat: 48.855567, lng: 19.016575 },
    { lat: 48.855641, lng: 19.016638 },
    { lat: 48.856117, lng: 19.016772 },
    { lat: 48.856395, lng: 19.016648 },
    { lat: 48.856635, lng: 19.016967 },
    { lat: 48.857246, lng: 19.017144 },
    { lat: 48.857456, lng: 19.017505 },
    { lat: 48.857639, lng: 19.017652 },
    { lat: 48.857863, lng: 19.017999 },
    { lat: 48.857983, lng: 19.018211 },
    { lat: 48.858044, lng: 19.018191 },
    { lat: 48.858523, lng: 19.019015 },
    { lat: 48.858842, lng: 19.019633 },
    { lat: 48.859105, lng: 19.019678 },
    { lat: 48.859409, lng: 19.019992 },
    { lat: 48.859648, lng: 19.020186 },
    { lat: 48.8599, lng: 19.020339 },
    { lat: 48.860142, lng: 19.020625 },
    { lat: 48.860363, lng: 19.020801 },
    { lat: 48.860549, lng: 19.021094 },
    { lat: 48.860751, lng: 19.021223 },
    { lat: 48.860928, lng: 19.021397 },
    { lat: 48.861174, lng: 19.02156 },
    { lat: 48.861369, lng: 19.021607 },
    { lat: 48.861705, lng: 19.021907 },
    { lat: 48.86216, lng: 19.022073 },
    { lat: 48.862643, lng: 19.022568 },
    { lat: 48.862939, lng: 19.023315 },
    { lat: 48.863268, lng: 19.023544 },
    { lat: 48.863428, lng: 19.023558 },
    { lat: 48.863683, lng: 19.023384 },
    { lat: 48.863977, lng: 19.023087 },
    { lat: 48.864307, lng: 19.023067 },
    { lat: 48.864342, lng: 19.023214 },
    { lat: 48.864464, lng: 19.023464 },
    { lat: 48.864618, lng: 19.023706 },
    { lat: 48.864902, lng: 19.02435 },
    { lat: 48.865157, lng: 19.024431 },
    { lat: 48.865339, lng: 19.024458 },
    { lat: 48.865529, lng: 19.024612 },
    { lat: 48.865648, lng: 19.024833 },
    { lat: 48.865895, lng: 19.025049 },
    { lat: 48.866263, lng: 19.025233 },
    { lat: 48.866474, lng: 19.025458 },
    { lat: 48.866782, lng: 19.025625 },
    { lat: 48.867177, lng: 19.025502 },
    { lat: 48.86738, lng: 19.02551 },
    { lat: 48.867683, lng: 19.025333 },
    { lat: 48.868026, lng: 19.024919 },
    { lat: 48.868437, lng: 19.024294 },
    { lat: 48.868567, lng: 19.02426 },
    { lat: 48.868869, lng: 19.02442 },
    { lat: 48.869104, lng: 19.024714 },
    { lat: 48.869178, lng: 19.024807 },
    { lat: 48.869395, lng: 19.025383 },
    { lat: 48.869782, lng: 19.025994 },
    { lat: 48.869935, lng: 19.026117 },
    { lat: 48.870137, lng: 19.026002 },
    { lat: 48.870408, lng: 19.025897 },
    { lat: 48.870987, lng: 19.024957 },
    { lat: 48.871088, lng: 19.024669 },
    { lat: 48.871328, lng: 19.024145 },
    { lat: 48.871635, lng: 19.024019 },
    { lat: 48.872541, lng: 19.024171 },
    { lat: 48.872838, lng: 19.024336 },
    { lat: 48.873421, lng: 19.024012 },
    { lat: 48.874444, lng: 19.023543 },
    { lat: 48.8747, lng: 19.023338 },
    { lat: 48.874871, lng: 19.023255 },
    { lat: 48.875317, lng: 19.022879 },
    { lat: 48.875562, lng: 19.02336 },
    { lat: 48.876675, lng: 19.025721 },
    { lat: 48.876755, lng: 19.025976 },
    { lat: 48.877188, lng: 19.026932 },
    { lat: 48.877517, lng: 19.027388 },
    { lat: 48.87773, lng: 19.027852 },
    { lat: 48.877934, lng: 19.029152 },
    { lat: 48.878002, lng: 19.029292 },
  ],
  Ondrašová: [
    { lat: 48.9393419, lng: 18.8122482 },
    { lat: 48.9408541, lng: 18.8130113 },
    { lat: 48.9418853, lng: 18.8131935 },
    { lat: 48.9419369, lng: 18.8131676 },
    { lat: 48.9420474, lng: 18.8131429 },
    { lat: 48.9444861, lng: 18.8142017 },
    { lat: 48.9463201, lng: 18.8139314 },
    { lat: 48.9502127, lng: 18.8136084 },
    { lat: 48.950359, lng: 18.813582 },
    { lat: 48.950351, lng: 18.813494 },
    { lat: 48.950366, lng: 18.81345 },
    { lat: 48.950329, lng: 18.81343 },
    { lat: 48.950419, lng: 18.813388 },
    { lat: 48.95044, lng: 18.813275 },
    { lat: 48.950416, lng: 18.813291 },
    { lat: 48.950437, lng: 18.813056 },
    { lat: 48.950444, lng: 18.81295 },
    { lat: 48.95049, lng: 18.812851 },
    { lat: 48.950574, lng: 18.812486 },
    { lat: 48.950616, lng: 18.812351 },
    { lat: 48.95066, lng: 18.812258 },
    { lat: 48.950668, lng: 18.812186 },
    { lat: 48.95067, lng: 18.812163 },
    { lat: 48.950697, lng: 18.811896 },
    { lat: 48.950762, lng: 18.811893 },
    { lat: 48.950763, lng: 18.81182 },
    { lat: 48.950707, lng: 18.811791 },
    { lat: 48.950759, lng: 18.811495 },
    { lat: 48.95082, lng: 18.811284 },
    { lat: 48.95122, lng: 18.809832 },
    { lat: 48.951422, lng: 18.809332 },
    { lat: 48.951657, lng: 18.808584 },
    { lat: 48.951717, lng: 18.808338 },
    { lat: 48.951949, lng: 18.807621 },
    { lat: 48.951972, lng: 18.807508 },
    { lat: 48.95219, lng: 18.806295 },
    { lat: 48.952334, lng: 18.805495 },
    { lat: 48.952376, lng: 18.80533 },
    { lat: 48.953167, lng: 18.802897 },
    { lat: 48.953289, lng: 18.802514 },
    { lat: 48.953347, lng: 18.802334 },
    { lat: 48.953569, lng: 18.801656 },
    { lat: 48.953706, lng: 18.801234 },
    { lat: 48.953837, lng: 18.800827 },
    { lat: 48.954009, lng: 18.800297 },
    { lat: 48.953993, lng: 18.800284 },
    { lat: 48.954022, lng: 18.800185 },
    { lat: 48.954044, lng: 18.800193 },
    { lat: 48.954079, lng: 18.800083 },
    { lat: 48.95413, lng: 18.799844 },
    { lat: 48.954219, lng: 18.799261 },
    { lat: 48.954336, lng: 18.798566 },
    { lat: 48.954387, lng: 18.79838 },
    { lat: 48.954435, lng: 18.798292 },
    { lat: 48.954488, lng: 18.798209 },
    { lat: 48.954632, lng: 18.798033 },
    { lat: 48.954781, lng: 18.797855 },
    { lat: 48.954974, lng: 18.797613 },
    { lat: 48.955155, lng: 18.797392 },
    { lat: 48.955225, lng: 18.797326 },
    { lat: 48.955204, lng: 18.797301 },
    { lat: 48.955778, lng: 18.797213 },
    { lat: 48.956026, lng: 18.797174 },
    { lat: 48.95618, lng: 18.797092 },
    { lat: 48.956104, lng: 18.796816 },
    { lat: 48.956135, lng: 18.796556 },
    { lat: 48.956126, lng: 18.796483 },
    { lat: 48.95613, lng: 18.796423 },
    { lat: 48.9561364, lng: 18.796394 },
    { lat: 48.956145, lng: 18.796355 },
    { lat: 48.956165, lng: 18.796299 },
    { lat: 48.956269, lng: 18.796258 },
    { lat: 48.956422, lng: 18.796224 },
    { lat: 48.956664, lng: 18.796009 },
    { lat: 48.956866, lng: 18.795721 },
    { lat: 48.957031, lng: 18.795536 },
    { lat: 48.957117, lng: 18.795491 },
    { lat: 48.9571036, lng: 18.7951642 },
    { lat: 48.957096, lng: 18.794979 },
    { lat: 48.95709, lng: 18.794572 },
    { lat: 48.957055, lng: 18.79376 },
    { lat: 48.957031, lng: 18.792971 },
    { lat: 48.957034, lng: 18.792664 },
    { lat: 48.957016, lng: 18.792168 },
    { lat: 48.957016, lng: 18.792146 },
    { lat: 48.957082, lng: 18.792098 },
    { lat: 48.957166, lng: 18.792074 },
    { lat: 48.957262, lng: 18.792052 },
    { lat: 48.957339, lng: 18.792002 },
    { lat: 48.957399, lng: 18.791967 },
    { lat: 48.957455, lng: 18.791857 },
    { lat: 48.957529, lng: 18.791822 },
    { lat: 48.957673, lng: 18.7917 },
    { lat: 48.957812, lng: 18.791566 },
    { lat: 48.957843, lng: 18.791512 },
    { lat: 48.957927, lng: 18.791497 },
    { lat: 48.957971, lng: 18.791471 },
    { lat: 48.958135, lng: 18.791361 },
    { lat: 48.958236, lng: 18.791318 },
    { lat: 48.95841, lng: 18.791195 },
    { lat: 48.958529, lng: 18.791226 },
    { lat: 48.958768, lng: 18.790831 },
    { lat: 48.958931, lng: 18.790675 },
    { lat: 48.9592, lng: 18.79076 },
    { lat: 48.95936, lng: 18.790365 },
    { lat: 48.959413, lng: 18.790059 },
    { lat: 48.959512, lng: 18.789684 },
    { lat: 48.959619, lng: 18.789471 },
    { lat: 48.95975, lng: 18.789313 },
    { lat: 48.959831, lng: 18.789248 },
    { lat: 48.96024, lng: 18.788784 },
    { lat: 48.960261, lng: 18.788671 },
    { lat: 48.960331, lng: 18.788481 },
    { lat: 48.960341, lng: 18.788352 },
    { lat: 48.960429, lng: 18.787223 },
    { lat: 48.960524, lng: 18.784854 },
    { lat: 48.96077, lng: 18.784044 },
    { lat: 48.960781, lng: 18.783943 },
    { lat: 48.960774, lng: 18.78363 },
    { lat: 48.960841, lng: 18.782912 },
    { lat: 48.960847, lng: 18.78287 },
    { lat: 48.96093, lng: 18.782189 },
    { lat: 48.960974, lng: 18.781781 },
    { lat: 48.961127, lng: 18.781311 },
    { lat: 48.961564, lng: 18.780525 },
    { lat: 48.9617984, lng: 18.7800649 },
    { lat: 48.961969, lng: 18.77973 },
    { lat: 48.96211, lng: 18.779501 },
    { lat: 48.962291, lng: 18.779342 },
    { lat: 48.962882, lng: 18.779084 },
    { lat: 48.963145, lng: 18.779006 },
    { lat: 48.963441, lng: 18.778731 },
    { lat: 48.963778, lng: 18.778444 },
    { lat: 48.964042, lng: 18.777973 },
    { lat: 48.964446, lng: 18.775514 },
    { lat: 48.964684, lng: 18.773594 },
    { lat: 48.964761, lng: 18.772311 },
    { lat: 48.964801, lng: 18.770134 },
    { lat: 48.964826, lng: 18.769151 },
    { lat: 48.965182, lng: 18.767499 },
    { lat: 48.965655, lng: 18.767147 },
    { lat: 48.966128, lng: 18.766268 },
    { lat: 48.96663, lng: 18.765179 },
    { lat: 48.966438, lng: 18.763661 },
    { lat: 48.965331, lng: 18.762075 },
    { lat: 48.964289, lng: 18.762249 },
    { lat: 48.9638007, lng: 18.7626169 },
    { lat: 48.96287, lng: 18.763318 },
    { lat: 48.961754, lng: 18.763179 },
    { lat: 48.96097, lng: 18.763643 },
    { lat: 48.960293, lng: 18.764238 },
    { lat: 48.959672, lng: 18.763403 },
    { lat: 48.959049, lng: 18.761998 },
    { lat: 48.958069, lng: 18.76113 },
    { lat: 48.957428, lng: 18.75931 },
    { lat: 48.957188, lng: 18.757134 },
    { lat: 48.956293, lng: 18.75718 },
    { lat: 48.955598, lng: 18.7571 },
    { lat: 48.95574, lng: 18.75637 },
    { lat: 48.955383, lng: 18.754536 },
    { lat: 48.955262, lng: 18.753606 },
    { lat: 48.954252, lng: 18.752331 },
    { lat: 48.953679, lng: 18.751989 },
    { lat: 48.953008, lng: 18.751317 },
    { lat: 48.9508238, lng: 18.7516778 },
    { lat: 48.9502778, lng: 18.7520599 },
    { lat: 48.9498646, lng: 18.7528267 },
    { lat: 48.9486081, lng: 18.7540919 },
    { lat: 48.9479102, lng: 18.75446 },
    { lat: 48.9471431, lng: 18.7557223 },
    { lat: 48.9464621, lng: 18.757359 },
    { lat: 48.9462524, lng: 18.7580692 },
    { lat: 48.9461722, lng: 18.7583297 },
    { lat: 48.9461444, lng: 18.7586792 },
    { lat: 48.9461556, lng: 18.7589369 },
    { lat: 48.9462186, lng: 18.7598158 },
    { lat: 48.9463864, lng: 18.7607182 },
    { lat: 48.9464934, lng: 18.7608851 },
    { lat: 48.9465106, lng: 18.761128 },
    { lat: 48.9465208, lng: 18.7611953 },
    { lat: 48.9464611, lng: 18.7613012 },
    { lat: 48.9464207, lng: 18.7615686 },
    { lat: 48.9464214, lng: 18.7616841 },
    { lat: 48.9464831, lng: 18.7625829 },
    { lat: 48.9464356, lng: 18.7633147 },
    { lat: 48.9468295, lng: 18.7649587 },
    { lat: 48.947248, lng: 18.7661054 },
    { lat: 48.9474684, lng: 18.7669539 },
    { lat: 48.9471616, lng: 18.7681403 },
    { lat: 48.9471054, lng: 18.7691986 },
    { lat: 48.946687, lng: 18.7690697 },
    { lat: 48.9463361, lng: 18.7691722 },
    { lat: 48.9460431, lng: 18.7694117 },
    { lat: 48.9455291, lng: 18.7705588 },
    { lat: 48.946442, lng: 18.7707868 },
    { lat: 48.9466573, lng: 18.7719946 },
    { lat: 48.9468885, lng: 18.7732887 },
    { lat: 48.9467331, lng: 18.7753003 },
    { lat: 48.9469382, lng: 18.7756028 },
    { lat: 48.947242, lng: 18.7758895 },
    { lat: 48.9470762, lng: 18.7763858 },
    { lat: 48.9463614, lng: 18.7770814 },
    { lat: 48.9460546, lng: 18.7776597 },
    { lat: 48.9457081, lng: 18.7786944 },
    { lat: 48.9456749, lng: 18.7791583 },
    { lat: 48.945646, lng: 18.7801796 },
    { lat: 48.9455004, lng: 18.7818229 },
    { lat: 48.9452999, lng: 18.7830793 },
    { lat: 48.9452624, lng: 18.7835702 },
    { lat: 48.9450707, lng: 18.7840453 },
    { lat: 48.9448918, lng: 18.7843965 },
    { lat: 48.9447357, lng: 18.7847155 },
    { lat: 48.9445465, lng: 18.7849804 },
    { lat: 48.944495, lng: 18.7850307 },
    { lat: 48.9443768, lng: 18.7853775 },
    { lat: 48.9441521, lng: 18.785718 },
    { lat: 48.9440667, lng: 18.7859844 },
    { lat: 48.9438625, lng: 18.7863279 },
    { lat: 48.9437515, lng: 18.7865999 },
    { lat: 48.9439298, lng: 18.7868396 },
    { lat: 48.9444458, lng: 18.7872626 },
    { lat: 48.9458524, lng: 18.7884173 },
    { lat: 48.947041, lng: 18.7890034 },
    { lat: 48.9469415, lng: 18.7899288 },
    { lat: 48.9469385, lng: 18.7903047 },
    { lat: 48.946968, lng: 18.7904809 },
    { lat: 48.9469725, lng: 18.7905037 },
    { lat: 48.9470021, lng: 18.790682 },
    { lat: 48.9464943, lng: 18.7922752 },
    { lat: 48.9461331, lng: 18.7932011 },
    { lat: 48.945854, lng: 18.7936647 },
    { lat: 48.945347, lng: 18.7949492 },
    { lat: 48.9452909, lng: 18.7951518 },
    { lat: 48.9451113, lng: 18.7957958 },
    { lat: 48.9449072, lng: 18.7965324 },
    { lat: 48.9443904, lng: 18.7961155 },
    { lat: 48.9438937, lng: 18.7957148 },
    { lat: 48.9431069, lng: 18.795245 },
    { lat: 48.9428977, lng: 18.7956428 },
    { lat: 48.9426454, lng: 18.7964511 },
    { lat: 48.9424627, lng: 18.796364 },
    { lat: 48.9420718, lng: 18.796177 },
    { lat: 48.9420708, lng: 18.7961768 },
    { lat: 48.9420174, lng: 18.7961724 },
    { lat: 48.9415272, lng: 18.796133 },
    { lat: 48.941492, lng: 18.7961286 },
    { lat: 48.9411403, lng: 18.7959993 },
    { lat: 48.9409197, lng: 18.7957711 },
    { lat: 48.9405767, lng: 18.7956139 },
    { lat: 48.9401935, lng: 18.796745 },
    { lat: 48.9402749, lng: 18.7969235 },
    { lat: 48.9402112, lng: 18.7975201 },
    { lat: 48.9399925, lng: 18.7990794 },
    { lat: 48.9400337, lng: 18.8003898 },
    { lat: 48.940076, lng: 18.8010301 },
    { lat: 48.9401596, lng: 18.801769 },
    { lat: 48.9404928, lng: 18.8047733 },
    { lat: 48.9407083, lng: 18.8059699 },
    { lat: 48.9407551, lng: 18.8061559 },
    { lat: 48.9409596, lng: 18.8071799 },
    { lat: 48.9410764, lng: 18.8079963 },
    { lat: 48.9410808, lng: 18.8080801 },
    { lat: 48.9410747, lng: 18.8081364 },
    { lat: 48.9410566, lng: 18.8082013 },
    { lat: 48.9410201, lng: 18.8082821 },
    { lat: 48.9409419, lng: 18.8084037 },
    { lat: 48.9409376, lng: 18.8084082 },
    { lat: 48.9409313, lng: 18.8084369 },
    { lat: 48.9407481, lng: 18.8087092 },
    { lat: 48.9406201, lng: 18.8089825 },
    { lat: 48.9393419, lng: 18.8122482 },
  ],
  Rakša: [
    { lat: 48.8501972, lng: 18.930058 },
    { lat: 48.852625, lng: 18.9321191 },
    { lat: 48.852763, lng: 18.9318402 },
    { lat: 48.8530897, lng: 18.9319473 },
    { lat: 48.8533375, lng: 18.9322294 },
    { lat: 48.85375, lng: 18.9316731 },
    { lat: 48.8540071, lng: 18.9315395 },
    { lat: 48.8540834, lng: 18.9311994 },
    { lat: 48.8541604, lng: 18.9304113 },
    { lat: 48.8542888, lng: 18.9296584 },
    { lat: 48.8544836, lng: 18.9289657 },
    { lat: 48.8545779, lng: 18.9285171 },
    { lat: 48.8546296, lng: 18.9279371 },
    { lat: 48.8546528, lng: 18.9276884 },
    { lat: 48.8548024, lng: 18.927083 },
    { lat: 48.8550605, lng: 18.9256038 },
    { lat: 48.8551204, lng: 18.9250092 },
    { lat: 48.8551847, lng: 18.9242362 },
    { lat: 48.8552474, lng: 18.9238733 },
    { lat: 48.8554202, lng: 18.9235451 },
    { lat: 48.8555611, lng: 18.9233023 },
    { lat: 48.855667, lng: 18.9231682 },
    { lat: 48.8556657, lng: 18.9234766 },
    { lat: 48.8557076, lng: 18.923834 },
    { lat: 48.8558303, lng: 18.9241827 },
    { lat: 48.8559973, lng: 18.9245613 },
    { lat: 48.8567069, lng: 18.9264252 },
    { lat: 48.8567642, lng: 18.9266408 },
    { lat: 48.8568573, lng: 18.9271821 },
    { lat: 48.8570058, lng: 18.9275746 },
    { lat: 48.8573347, lng: 18.9283435 },
    { lat: 48.8573523, lng: 18.9285312 },
    { lat: 48.8575406, lng: 18.928999 },
    { lat: 48.8575971, lng: 18.9292455 },
    { lat: 48.8576052, lng: 18.9296685 },
    { lat: 48.8576047, lng: 18.9301208 },
    { lat: 48.857672, lng: 18.9304445 },
    { lat: 48.8578216, lng: 18.93077 },
    { lat: 48.8579523, lng: 18.9310759 },
    { lat: 48.8580364, lng: 18.9312895 },
    { lat: 48.8581929, lng: 18.9318613 },
    { lat: 48.8583765, lng: 18.9323898 },
    { lat: 48.8585215, lng: 18.932733 },
    { lat: 48.8585804, lng: 18.93285 },
    { lat: 48.8589453, lng: 18.933218 },
    { lat: 48.8593283, lng: 18.9335187 },
    { lat: 48.8594999, lng: 18.9335914 },
    { lat: 48.861326, lng: 18.9339624 },
    { lat: 48.861575, lng: 18.9340443 },
    { lat: 48.8618313, lng: 18.9341643 },
    { lat: 48.8623493, lng: 18.9339716 },
    { lat: 48.8625486, lng: 18.9339232 },
    { lat: 48.8630318, lng: 18.9338695 },
    { lat: 48.8631298, lng: 18.9338437 },
    { lat: 48.863401, lng: 18.9336812 },
    { lat: 48.863666, lng: 18.9335915 },
    { lat: 48.8639419, lng: 18.9334751 },
    { lat: 48.8640199, lng: 18.9334795 },
    { lat: 48.8641198, lng: 18.9335155 },
    { lat: 48.8645074, lng: 18.9338665 },
    { lat: 48.8649411, lng: 18.934301 },
    { lat: 48.8652736, lng: 18.9348505 },
    { lat: 48.8653264, lng: 18.9349349 },
    { lat: 48.8654282, lng: 18.9349857 },
    { lat: 48.8655795, lng: 18.9350109 },
    { lat: 48.8656683, lng: 18.935075 },
    { lat: 48.8657856, lng: 18.935254 },
    { lat: 48.8663882, lng: 18.9364104 },
    { lat: 48.8664947, lng: 18.9365773 },
    { lat: 48.8666754, lng: 18.9368273 },
    { lat: 48.8668027, lng: 18.9368668 },
    { lat: 48.8669348, lng: 18.9368969 },
    { lat: 48.8670931, lng: 18.9368826 },
    { lat: 48.867281, lng: 18.9368094 },
    { lat: 48.8674252, lng: 18.9367607 },
    { lat: 48.8674877, lng: 18.9367713 },
    { lat: 48.8676179, lng: 18.9368273 },
    { lat: 48.8680349, lng: 18.9372631 },
    { lat: 48.8682201, lng: 18.9375136 },
    { lat: 48.8683206, lng: 18.937716 },
    { lat: 48.8684223, lng: 18.9382976 },
    { lat: 48.8684075, lng: 18.938517 },
    { lat: 48.8684533, lng: 18.938531 },
    { lat: 48.8687267, lng: 18.9382992 },
    { lat: 48.8690707, lng: 18.9381469 },
    { lat: 48.8692964, lng: 18.9379482 },
    { lat: 48.8695831, lng: 18.9376246 },
    { lat: 48.8698716, lng: 18.9372682 },
    { lat: 48.8702372, lng: 18.9366298 },
    { lat: 48.8699257, lng: 18.9363349 },
    { lat: 48.8696279, lng: 18.9359339 },
    { lat: 48.869382, lng: 18.9355544 },
    { lat: 48.869089, lng: 18.935184 },
    { lat: 48.8687688, lng: 18.9348067 },
    { lat: 48.868439, lng: 18.934528 },
    { lat: 48.8682864, lng: 18.9343016 },
    { lat: 48.8682424, lng: 18.9339028 },
    { lat: 48.8680346, lng: 18.9331436 },
    { lat: 48.8679767, lng: 18.9328703 },
    { lat: 48.8677793, lng: 18.9324461 },
    { lat: 48.8673685, lng: 18.9318871 },
    { lat: 48.8671339, lng: 18.9315522 },
    { lat: 48.8670749, lng: 18.9301724 },
    { lat: 48.8668715, lng: 18.9297006 },
    { lat: 48.8668239, lng: 18.9294762 },
    { lat: 48.8668836, lng: 18.9292991 },
    { lat: 48.8670659, lng: 18.9290235 },
    { lat: 48.8675864, lng: 18.9281102 },
    { lat: 48.868176, lng: 18.9278438 },
    { lat: 48.8685012, lng: 18.927675 },
    { lat: 48.8687453, lng: 18.9277309 },
    { lat: 48.8691279, lng: 18.9279185 },
    { lat: 48.8692792, lng: 18.9281199 },
    { lat: 48.8694014, lng: 18.9282826 },
    { lat: 48.8695685, lng: 18.9283754 },
    { lat: 48.8697322, lng: 18.9284028 },
    { lat: 48.8701483, lng: 18.9282928 },
    { lat: 48.8704919, lng: 18.9282852 },
    { lat: 48.8709595, lng: 18.9282168 },
    { lat: 48.8713024, lng: 18.9283062 },
    { lat: 48.8718397, lng: 18.9284281 },
    { lat: 48.8721358, lng: 18.9284661 },
    { lat: 48.8724844, lng: 18.9284446 },
    { lat: 48.8730779, lng: 18.9285889 },
    { lat: 48.8735184, lng: 18.9286927 },
    { lat: 48.874713, lng: 18.9292541 },
    { lat: 48.8748585, lng: 18.9290816 },
    { lat: 48.874975, lng: 18.9288642 },
    { lat: 48.8750242, lng: 18.9287488 },
    { lat: 48.8750642, lng: 18.928204 },
    { lat: 48.8751216, lng: 18.9276703 },
    { lat: 48.8751555, lng: 18.9273454 },
    { lat: 48.8751967, lng: 18.9272213 },
    { lat: 48.8753889, lng: 18.9267464 },
    { lat: 48.8755474, lng: 18.9262726 },
    { lat: 48.8757323, lng: 18.9255854 },
    { lat: 48.8758081, lng: 18.9251965 },
    { lat: 48.8758232, lng: 18.925117 },
    { lat: 48.8758984, lng: 18.9251322 },
    { lat: 48.876004, lng: 18.9250585 },
    { lat: 48.8762748, lng: 18.9245085 },
    { lat: 48.876512, lng: 18.9240075 },
    { lat: 48.8767603, lng: 18.9236134 },
    { lat: 48.8770326, lng: 18.9232279 },
    { lat: 48.8771802, lng: 18.9227628 },
    { lat: 48.8772732, lng: 18.9225298 },
    { lat: 48.8775459, lng: 18.9220401 },
    { lat: 48.8776245, lng: 18.9217704 },
    { lat: 48.877669, lng: 18.9217573 },
    { lat: 48.8777053, lng: 18.9217609 },
    { lat: 48.8780534, lng: 18.9218 },
    { lat: 48.8783446, lng: 18.9219833 },
    { lat: 48.8787006, lng: 18.9220221 },
    { lat: 48.8790672, lng: 18.922002 },
    { lat: 48.8794742, lng: 18.9218715 },
    { lat: 48.8799717, lng: 18.9217222 },
    { lat: 48.8802383, lng: 18.9214645 },
    { lat: 48.8806386, lng: 18.9212485 },
    { lat: 48.8808412, lng: 18.9210846 },
    { lat: 48.8810812, lng: 18.9211252 },
    { lat: 48.8808942, lng: 18.920535 },
    { lat: 48.8809813, lng: 18.9197875 },
    { lat: 48.8809437, lng: 18.9187187 },
    { lat: 48.8809898, lng: 18.9181874 },
    { lat: 48.880998, lng: 18.9174237 },
    { lat: 48.8809568, lng: 18.9169034 },
    { lat: 48.8809841, lng: 18.9163328 },
    { lat: 48.8811467, lng: 18.915278 },
    { lat: 48.8814862, lng: 18.9143533 },
    { lat: 48.8814774, lng: 18.9139606 },
    { lat: 48.8813699, lng: 18.9126278 },
    { lat: 48.8814016, lng: 18.9117926 },
    { lat: 48.8813528, lng: 18.9111478 },
    { lat: 48.8814721, lng: 18.9104602 },
    { lat: 48.8816413, lng: 18.9099987 },
    { lat: 48.8821641, lng: 18.907724 },
    { lat: 48.8822226, lng: 18.9071461 },
    { lat: 48.8822037, lng: 18.9067119 },
    { lat: 48.8822068, lng: 18.9065497 },
    { lat: 48.8824348, lng: 18.9058952 },
    { lat: 48.8827235, lng: 18.9052153 },
    { lat: 48.8830572, lng: 18.9045763 },
    { lat: 48.8831064, lng: 18.9043689 },
    { lat: 48.883091, lng: 18.9041339 },
    { lat: 48.8831298, lng: 18.9040738 },
    { lat: 48.8837269, lng: 18.9032956 },
    { lat: 48.8839398, lng: 18.9030506 },
    { lat: 48.884004, lng: 18.9029617 },
    { lat: 48.8840389, lng: 18.902915 },
    { lat: 48.8840623, lng: 18.9028878 },
    { lat: 48.8845416, lng: 18.902341 },
    { lat: 48.8853546, lng: 18.9005131 },
    { lat: 48.8863193, lng: 18.8984565 },
    { lat: 48.8863454, lng: 18.8984183 },
    { lat: 48.8864305, lng: 18.8982937 },
    { lat: 48.8867777, lng: 18.8977881 },
    { lat: 48.8882062, lng: 18.8958946 },
    { lat: 48.8886801, lng: 18.8952193 },
    { lat: 48.8890069, lng: 18.8947741 },
    { lat: 48.8892967, lng: 18.8943806 },
    { lat: 48.8897068, lng: 18.8936694 },
    { lat: 48.8897251, lng: 18.8936296 },
    { lat: 48.8905519, lng: 18.8917696 },
    { lat: 48.89227, lng: 18.8893235 },
    { lat: 48.8922884, lng: 18.8892844 },
    { lat: 48.8936835, lng: 18.8862826 },
    { lat: 48.8941921, lng: 18.8846376 },
    { lat: 48.8945602, lng: 18.8831787 },
    { lat: 48.8945735, lng: 18.883128 },
    { lat: 48.8946393, lng: 18.880633 },
    { lat: 48.8947716, lng: 18.8799383 },
    { lat: 48.894879, lng: 18.8788714 },
    { lat: 48.8965319, lng: 18.8764778 },
    { lat: 48.8965529, lng: 18.8764266 },
    { lat: 48.8965924, lng: 18.8763596 },
    { lat: 48.8966247, lng: 18.87633 },
    { lat: 48.8967878, lng: 18.875008 },
    { lat: 48.8970911, lng: 18.8725066 },
    { lat: 48.8972702, lng: 18.8710373 },
    { lat: 48.8970049, lng: 18.871249 },
    { lat: 48.8968112, lng: 18.8713604 },
    { lat: 48.8965825, lng: 18.8714753 },
    { lat: 48.8959978, lng: 18.8716606 },
    { lat: 48.8952902, lng: 18.8718045 },
    { lat: 48.8947765, lng: 18.871813 },
    { lat: 48.8947909, lng: 18.8718726 },
    { lat: 48.8948024, lng: 18.8723801 },
    { lat: 48.8947374, lng: 18.8727153 },
    { lat: 48.8946073, lng: 18.8729693 },
    { lat: 48.8945748, lng: 18.8729298 },
    { lat: 48.8939557, lng: 18.871989 },
    { lat: 48.893707, lng: 18.8716228 },
    { lat: 48.8936117, lng: 18.8715244 },
    { lat: 48.8935135, lng: 18.8714565 },
    { lat: 48.892141, lng: 18.8707973 },
    { lat: 48.8915682, lng: 18.8705329 },
    { lat: 48.8915447, lng: 18.8706651 },
    { lat: 48.8910571, lng: 18.8730046 },
    { lat: 48.891037, lng: 18.8729847 },
    { lat: 48.8910225, lng: 18.8729872 },
    { lat: 48.8874672, lng: 18.8715174 },
    { lat: 48.8874629, lng: 18.8719354 },
    { lat: 48.887442, lng: 18.8724753 },
    { lat: 48.8873554, lng: 18.8731785 },
    { lat: 48.8872298, lng: 18.8739572 },
    { lat: 48.8869941, lng: 18.8751853 },
    { lat: 48.8866223, lng: 18.8771415 },
    { lat: 48.88658, lng: 18.8771322 },
    { lat: 48.8855661, lng: 18.8768647 },
    { lat: 48.8853632, lng: 18.876778 },
    { lat: 48.884655, lng: 18.8763813 },
    { lat: 48.8834859, lng: 18.8757245 },
    { lat: 48.8836219, lng: 18.8748862 },
    { lat: 48.8838226, lng: 18.8738081 },
    { lat: 48.883997, lng: 18.8732992 },
    { lat: 48.8841863, lng: 18.872772 },
    { lat: 48.8843737, lng: 18.8723134 },
    { lat: 48.8826293, lng: 18.871042 },
    { lat: 48.8824912, lng: 18.8709437 },
    { lat: 48.8823261, lng: 18.8712201 },
    { lat: 48.8821039, lng: 18.8716129 },
    { lat: 48.8802123, lng: 18.8702418 },
    { lat: 48.879383, lng: 18.8713255 },
    { lat: 48.8789982, lng: 18.8720441 },
    { lat: 48.8789637, lng: 18.8721163 },
    { lat: 48.878745, lng: 18.8728626 },
    { lat: 48.8784202, lng: 18.8741781 },
    { lat: 48.8781752, lng: 18.8752215 },
    { lat: 48.8780715, lng: 18.8755519 },
    { lat: 48.8778794, lng: 18.8761356 },
    { lat: 48.877693, lng: 18.8766308 },
    { lat: 48.8774071, lng: 18.8772797 },
    { lat: 48.87731, lng: 18.877519 },
    { lat: 48.8771507, lng: 18.8779727 },
    { lat: 48.876776, lng: 18.8788645 },
    { lat: 48.8764852, lng: 18.879371 },
    { lat: 48.8761374, lng: 18.8800429 },
    { lat: 48.8758147, lng: 18.8804983 },
    { lat: 48.8752586, lng: 18.8815254 },
    { lat: 48.874981, lng: 18.881848 },
    { lat: 48.8748199, lng: 18.8820927 },
    { lat: 48.8745258, lng: 18.8828846 },
    { lat: 48.8742598, lng: 18.8835246 },
    { lat: 48.874123, lng: 18.8838452 },
    { lat: 48.8739509, lng: 18.8841836 },
    { lat: 48.8736703, lng: 18.8845588 },
    { lat: 48.8735038, lng: 18.8846759 },
    { lat: 48.8729478, lng: 18.88496 },
    { lat: 48.8728383, lng: 18.8850227 },
    { lat: 48.8717259, lng: 18.8862812 },
    { lat: 48.8716932, lng: 18.8864359 },
    { lat: 48.87147, lng: 18.8867871 },
    { lat: 48.8709659, lng: 18.888036 },
    { lat: 48.8703637, lng: 18.8889344 },
    { lat: 48.8700703, lng: 18.8894382 },
    { lat: 48.8697892, lng: 18.8903094 },
    { lat: 48.8693731, lng: 18.8909036 },
    { lat: 48.8691934, lng: 18.8911114 },
    { lat: 48.8685273, lng: 18.8917322 },
    { lat: 48.8684067, lng: 18.8917776 },
    { lat: 48.8675114, lng: 18.8920654 },
    { lat: 48.8671735, lng: 18.892179 },
    { lat: 48.8669305, lng: 18.8923197 },
    { lat: 48.8667682, lng: 18.8924768 },
    { lat: 48.8666487, lng: 18.8926312 },
    { lat: 48.8664955, lng: 18.8928764 },
    { lat: 48.8664475, lng: 18.8929962 },
    { lat: 48.8664041, lng: 18.89314 },
    { lat: 48.8663404, lng: 18.8935901 },
    { lat: 48.8663821, lng: 18.8949959 },
    { lat: 48.8662029, lng: 18.8969534 },
    { lat: 48.8661632, lng: 18.8971468 },
    { lat: 48.8661341, lng: 18.8973479 },
    { lat: 48.8660893, lng: 18.897336 },
    { lat: 48.8642813, lng: 18.8983773 },
    { lat: 48.8636516, lng: 18.8985649 },
    { lat: 48.8634848, lng: 18.898215 },
    { lat: 48.8627012, lng: 18.8998439 },
    { lat: 48.8622575, lng: 18.9000283 },
    { lat: 48.8618773, lng: 18.9003147 },
    { lat: 48.8613588, lng: 18.9007994 },
    { lat: 48.8612534, lng: 18.9009651 },
    { lat: 48.8608639, lng: 18.9013511 },
    { lat: 48.860637, lng: 18.9014311 },
    { lat: 48.8600822, lng: 18.9019795 },
    { lat: 48.8596184, lng: 18.9023724 },
    { lat: 48.8591108, lng: 18.9026749 },
    { lat: 48.858947, lng: 18.9028672 },
    { lat: 48.8588527, lng: 18.9030931 },
    { lat: 48.8587885, lng: 18.903689 },
    { lat: 48.8587446, lng: 18.9037586 },
    { lat: 48.8587044, lng: 18.9038557 },
    { lat: 48.8584916, lng: 18.9042771 },
    { lat: 48.8582548, lng: 18.9045835 },
    { lat: 48.8578678, lng: 18.9052392 },
    { lat: 48.8574419, lng: 18.9053806 },
    { lat: 48.856568, lng: 18.9052893 },
    { lat: 48.8559121, lng: 18.9055379 },
    { lat: 48.8553676, lng: 18.9067481 },
    { lat: 48.8544558, lng: 18.9068911 },
    { lat: 48.8539678, lng: 18.9069577 },
    { lat: 48.8534607, lng: 18.9068555 },
    { lat: 48.8530444, lng: 18.906739 },
    { lat: 48.8525674, lng: 18.9067829 },
    { lat: 48.8527473, lng: 18.9082924 },
    { lat: 48.8522356, lng: 18.910916 },
    { lat: 48.8521564, lng: 18.9113545 },
    { lat: 48.8522086, lng: 18.911913 },
    { lat: 48.8522066, lng: 18.9125992 },
    { lat: 48.8517281, lng: 18.9137811 },
    { lat: 48.8513443, lng: 18.9144351 },
    { lat: 48.8509404, lng: 18.9153564 },
    { lat: 48.8503462, lng: 18.9164795 },
    { lat: 48.850218, lng: 18.9167963 },
    { lat: 48.849723, lng: 18.9182051 },
    { lat: 48.8496215, lng: 18.9187813 },
    { lat: 48.8495841, lng: 18.9188433 },
    { lat: 48.8496008, lng: 18.9193468 },
    { lat: 48.8496448, lng: 18.9199618 },
    { lat: 48.8496845, lng: 18.9209617 },
    { lat: 48.8496568, lng: 18.921039 },
    { lat: 48.8495075, lng: 18.9215577 },
    { lat: 48.8492781, lng: 18.9222344 },
    { lat: 48.8492364, lng: 18.9224537 },
    { lat: 48.8492209, lng: 18.9234547 },
    { lat: 48.8492352, lng: 18.9237341 },
    { lat: 48.8492364, lng: 18.9246827 },
    { lat: 48.8492404, lng: 18.9248494 },
    { lat: 48.849224, lng: 18.9252788 },
    { lat: 48.8491663, lng: 18.9266019 },
    { lat: 48.84882, lng: 18.926621 },
    { lat: 48.8487636, lng: 18.9269279 },
    { lat: 48.84886, lng: 18.9273409 },
    { lat: 48.8489778, lng: 18.9283202 },
    { lat: 48.8492328, lng: 18.9286555 },
    { lat: 48.8494472, lng: 18.9291674 },
    { lat: 48.8501972, lng: 18.930058 },
  ],
  Rudno: [
    { lat: 48.895808, lng: 18.707418 },
    { lat: 48.8952984, lng: 18.7081137 },
    { lat: 48.8951511, lng: 18.7082101 },
    { lat: 48.8952202, lng: 18.7086186 },
    { lat: 48.8948875, lng: 18.7090662 },
    { lat: 48.8940957, lng: 18.7095116 },
    { lat: 48.8923923, lng: 18.7100165 },
    { lat: 48.8923016, lng: 18.7110938 },
    { lat: 48.8922555, lng: 18.7119072 },
    { lat: 48.8925057, lng: 18.7137006 },
    { lat: 48.8930171, lng: 18.7153104 },
    { lat: 48.8930316, lng: 18.7160551 },
    { lat: 48.8909166, lng: 18.7176441 },
    { lat: 48.8901335, lng: 18.7182589 },
    { lat: 48.8895825, lng: 18.718009 },
    { lat: 48.8894861, lng: 18.7179565 },
    { lat: 48.8888551, lng: 18.718055 },
    { lat: 48.8888432, lng: 18.7176999 },
    { lat: 48.8884973, lng: 18.7176605 },
    { lat: 48.8877593, lng: 18.7180361 },
    { lat: 48.8862787, lng: 18.7198349 },
    { lat: 48.885398, lng: 18.7220155 },
    { lat: 48.8853448, lng: 18.7222481 },
    { lat: 48.8852549, lng: 18.7229807 },
    { lat: 48.8846002, lng: 18.7243992 },
    { lat: 48.8826599, lng: 18.7264218 },
    { lat: 48.8830804, lng: 18.7276786 },
    { lat: 48.8829081, lng: 18.7293725 },
    { lat: 48.8828398, lng: 18.729681 },
    { lat: 48.8827292, lng: 18.7302996 },
    { lat: 48.8827676, lng: 18.7310073 },
    { lat: 48.8826129, lng: 18.731475 },
    { lat: 48.8822558, lng: 18.7325327 },
    { lat: 48.8820016, lng: 18.7332514 },
    { lat: 48.8813885, lng: 18.7342852 },
    { lat: 48.8806534, lng: 18.735769 },
    { lat: 48.8800967, lng: 18.7380009 },
    { lat: 48.8794323, lng: 18.7388548 },
    { lat: 48.8783308, lng: 18.7391823 },
    { lat: 48.8780622, lng: 18.73997 },
    { lat: 48.8774354, lng: 18.7406814 },
    { lat: 48.8770014, lng: 18.7406841 },
    { lat: 48.876415, lng: 18.740237 },
    { lat: 48.876426, lng: 18.7402303 },
    { lat: 48.8763902, lng: 18.7401518 },
    { lat: 48.8757704, lng: 18.7388733 },
    { lat: 48.8757237, lng: 18.7385207 },
    { lat: 48.8759655, lng: 18.7376506 },
    { lat: 48.8759107, lng: 18.7375748 },
    { lat: 48.8758471, lng: 18.7374159 },
    { lat: 48.8754083, lng: 18.7370939 },
    { lat: 48.8752626, lng: 18.7368934 },
    { lat: 48.8752634, lng: 18.736353 },
    { lat: 48.87511, lng: 18.7358184 },
    { lat: 48.8749354, lng: 18.7356934 },
    { lat: 48.8746538, lng: 18.7356354 },
    { lat: 48.8742782, lng: 18.7356343 },
    { lat: 48.8739463, lng: 18.735265 },
    { lat: 48.8732534, lng: 18.7350623 },
    { lat: 48.8729769, lng: 18.7338809 },
    { lat: 48.8725755, lng: 18.7352518 },
    { lat: 48.8725104, lng: 18.735298 },
    { lat: 48.8725231, lng: 18.7357554 },
    { lat: 48.8716977, lng: 18.7361186 },
    { lat: 48.8713836, lng: 18.7346957 },
    { lat: 48.8711174, lng: 18.7332406 },
    { lat: 48.8704989, lng: 18.7330378 },
    { lat: 48.8696201, lng: 18.7323031 },
    { lat: 48.8690378, lng: 18.7311188 },
    { lat: 48.8686755, lng: 18.7308353 },
    { lat: 48.8684573, lng: 18.7305243 },
    { lat: 48.8683954, lng: 18.7301216 },
    { lat: 48.8681246, lng: 18.7292209 },
    { lat: 48.8674751, lng: 18.7283894 },
    { lat: 48.8668807, lng: 18.7265461 },
    { lat: 48.8637329, lng: 18.7277593 },
    { lat: 48.8638233, lng: 18.7283848 },
    { lat: 48.8638419, lng: 18.7295478 },
    { lat: 48.8639251, lng: 18.7299066 },
    { lat: 48.8644661, lng: 18.7311191 },
    { lat: 48.8645158, lng: 18.7316131 },
    { lat: 48.8643641, lng: 18.7322242 },
    { lat: 48.8644527, lng: 18.7332196 },
    { lat: 48.8647016, lng: 18.7342429 },
    { lat: 48.8648626, lng: 18.7346024 },
    { lat: 48.8651358, lng: 18.7347563 },
    { lat: 48.86559, lng: 18.7350072 },
    { lat: 48.8658826, lng: 18.7354475 },
    { lat: 48.8662868, lng: 18.7356923 },
    { lat: 48.8669515, lng: 18.7360643 },
    { lat: 48.8673852, lng: 18.7369134 },
    { lat: 48.8675779, lng: 18.7370817 },
    { lat: 48.8682836, lng: 18.7375275 },
    { lat: 48.8690501, lng: 18.7379463 },
    { lat: 48.8699341, lng: 18.7380941 },
    { lat: 48.8702766, lng: 18.7383876 },
    { lat: 48.8706535, lng: 18.738822 },
    { lat: 48.8709381, lng: 18.7389986 },
    { lat: 48.8709954, lng: 18.7392754 },
    { lat: 48.8710201, lng: 18.7395962 },
    { lat: 48.8712653, lng: 18.7399903 },
    { lat: 48.8714703, lng: 18.7405856 },
    { lat: 48.8714983, lng: 18.7409878 },
    { lat: 48.8715122, lng: 18.7412512 },
    { lat: 48.8716055, lng: 18.741592 },
    { lat: 48.8717241, lng: 18.7419782 },
    { lat: 48.8718176, lng: 18.742237 },
    { lat: 48.8717991, lng: 18.7423736 },
    { lat: 48.8718067, lng: 18.7424783 },
    { lat: 48.8717687, lng: 18.7424206 },
    { lat: 48.8717495, lng: 18.7428911 },
    { lat: 48.8720992, lng: 18.7432146 },
    { lat: 48.8724344, lng: 18.7438633 },
    { lat: 48.8731698, lng: 18.7462897 },
    { lat: 48.8738408, lng: 18.7478744 },
    { lat: 48.8739354, lng: 18.7480242 },
    { lat: 48.8742531, lng: 18.7482115 },
    { lat: 48.8749095, lng: 18.7477837 },
    { lat: 48.8751781, lng: 18.7478934 },
    { lat: 48.8752227, lng: 18.7485716 },
    { lat: 48.87534, lng: 18.7503999 },
    { lat: 48.8754631, lng: 18.7519686 },
    { lat: 48.8754862, lng: 18.7533956 },
    { lat: 48.8754561, lng: 18.7542834 },
    { lat: 48.8753454, lng: 18.7556094 },
    { lat: 48.8753665, lng: 18.7556258 },
    { lat: 48.87538, lng: 18.7556253 },
    { lat: 48.8754149, lng: 18.755625 },
    { lat: 48.87634, lng: 18.7550974 },
    { lat: 48.876811, lng: 18.754701 },
    { lat: 48.877383, lng: 18.754686 },
    { lat: 48.8776655, lng: 18.7543557 },
    { lat: 48.8781478, lng: 18.7533384 },
    { lat: 48.8782142, lng: 18.7533787 },
    { lat: 48.8794987, lng: 18.7539567 },
    { lat: 48.8798033, lng: 18.7535302 },
    { lat: 48.880226, lng: 18.7530112 },
    { lat: 48.8803232, lng: 18.7529092 },
    { lat: 48.8806739, lng: 18.7527565 },
    { lat: 48.8815106, lng: 18.7527143 },
    { lat: 48.8820771, lng: 18.7530436 },
    { lat: 48.8826468, lng: 18.7532418 },
    { lat: 48.8831784, lng: 18.7533349 },
    { lat: 48.8837089, lng: 18.753388 },
    { lat: 48.883727, lng: 18.7533869 },
    { lat: 48.883748, lng: 18.7534034 },
    { lat: 48.8835651, lng: 18.7546867 },
    { lat: 48.8852339, lng: 18.7568542 },
    { lat: 48.8863838, lng: 18.7572777 },
    { lat: 48.8865559, lng: 18.7582161 },
    { lat: 48.8882443, lng: 18.7592527 },
    { lat: 48.8882982, lng: 18.7589692 },
    { lat: 48.8891279, lng: 18.7597808 },
    { lat: 48.8891674, lng: 18.7598674 },
    { lat: 48.8891905, lng: 18.7598782 },
    { lat: 48.8892585, lng: 18.7598195 },
    { lat: 48.8893373, lng: 18.7597717 },
    { lat: 48.8893793, lng: 18.7596942 },
    { lat: 48.8896051, lng: 18.7595881 },
    { lat: 48.8898902, lng: 18.759672 },
    { lat: 48.8905698, lng: 18.7595764 },
    { lat: 48.8908442, lng: 18.7594337 },
    { lat: 48.8912037, lng: 18.7592592 },
    { lat: 48.8914359, lng: 18.7591093 },
    { lat: 48.8917009, lng: 18.7587179 },
    { lat: 48.8921199, lng: 18.7580212 },
    { lat: 48.8920648, lng: 18.7580028 },
    { lat: 48.892202, lng: 18.7576705 },
    { lat: 48.8930031, lng: 18.7568198 },
    { lat: 48.893968, lng: 18.7561683 },
    { lat: 48.8941923, lng: 18.7559528 },
    { lat: 48.8944483, lng: 18.7555455 },
    { lat: 48.8953828, lng: 18.7533681 },
    { lat: 48.8954133, lng: 18.7533905 },
    { lat: 48.8954423, lng: 18.7534028 },
    { lat: 48.8959002, lng: 18.7536851 },
    { lat: 48.8959347, lng: 18.7535677 },
    { lat: 48.8959614, lng: 18.7535412 },
    { lat: 48.8959537, lng: 18.7534801 },
    { lat: 48.8972161, lng: 18.7513833 },
    { lat: 48.8968634, lng: 18.7489812 },
    { lat: 48.8965152, lng: 18.7466051 },
    { lat: 48.8960666, lng: 18.7451731 },
    { lat: 48.8951362, lng: 18.7457927 },
    { lat: 48.8950209, lng: 18.7445867 },
    { lat: 48.894808, lng: 18.7434093 },
    { lat: 48.8945685, lng: 18.7420495 },
    { lat: 48.8944902, lng: 18.7406691 },
    { lat: 48.8945817, lng: 18.74001 },
    { lat: 48.8949988, lng: 18.7389324 },
    { lat: 48.8964688, lng: 18.7357324 },
    { lat: 48.8974224, lng: 18.736856 },
    { lat: 48.8978634, lng: 18.7373115 },
    { lat: 48.8982784, lng: 18.7373064 },
    { lat: 48.8987064, lng: 18.7368507 },
    { lat: 48.8989227, lng: 18.7366324 },
    { lat: 48.8990925, lng: 18.7350985 },
    { lat: 48.8989357, lng: 18.7325512 },
    { lat: 48.8989623, lng: 18.7314268 },
    { lat: 48.8988622, lng: 18.7303393 },
    { lat: 48.8986697, lng: 18.7282733 },
    { lat: 48.8990607, lng: 18.7279886 },
    { lat: 48.8994223, lng: 18.7272096 },
    { lat: 48.8995895, lng: 18.726123 },
    { lat: 48.9000734, lng: 18.7253324 },
    { lat: 48.9002807, lng: 18.7249886 },
    { lat: 48.9006295, lng: 18.7245885 },
    { lat: 48.9010265, lng: 18.7242895 },
    { lat: 48.9017043, lng: 18.7232849 },
    { lat: 48.901804, lng: 18.7229086 },
    { lat: 48.901684, lng: 18.722045 },
    { lat: 48.9015102, lng: 18.7209947 },
    { lat: 48.9014479, lng: 18.7201771 },
    { lat: 48.9013886, lng: 18.7191598 },
    { lat: 48.9013533, lng: 18.718877 },
    { lat: 48.9013134, lng: 18.7184607 },
    { lat: 48.9012735, lng: 18.718135 },
    { lat: 48.9012154, lng: 18.7176112 },
    { lat: 48.9014719, lng: 18.7169775 },
    { lat: 48.9017375, lng: 18.7164137 },
    { lat: 48.9019733, lng: 18.7157943 },
    { lat: 48.9019875, lng: 18.7157639 },
    { lat: 48.9024674, lng: 18.7151775 },
    { lat: 48.9030125, lng: 18.7131788 },
    { lat: 48.9033131, lng: 18.7124006 },
    { lat: 48.9038588, lng: 18.7116733 },
    { lat: 48.9041752, lng: 18.7110641 },
    { lat: 48.903722, lng: 18.7100682 },
    { lat: 48.9032689, lng: 18.7097709 },
    { lat: 48.9029281, lng: 18.7086584 },
    { lat: 48.9029848, lng: 18.7072367 },
    { lat: 48.90098, lng: 18.703892 },
    { lat: 48.900532, lng: 18.704068 },
    { lat: 48.900221, lng: 18.704832 },
    { lat: 48.899921, lng: 18.705595 },
    { lat: 48.899703, lng: 18.706341 },
    { lat: 48.8993, lng: 18.706634 },
    { lat: 48.89888, lng: 18.707042 },
    { lat: 48.898479, lng: 18.707493 },
    { lat: 48.897931, lng: 18.707825 },
    { lat: 48.897488, lng: 18.707939 },
    { lat: 48.897036, lng: 18.707858 },
    { lat: 48.896547, lng: 18.707724 },
    { lat: 48.896279, lng: 18.707709 },
    { lat: 48.895808, lng: 18.707418 },
  ],
  Sklené: [
    { lat: 48.806639, lng: 18.769677 },
    { lat: 48.806451, lng: 18.769881 },
    { lat: 48.806357, lng: 18.769958 },
    { lat: 48.806265, lng: 18.770075 },
    { lat: 48.806013, lng: 18.770789 },
    { lat: 48.805964, lng: 18.771267 },
    { lat: 48.805981, lng: 18.771501 },
    { lat: 48.805849, lng: 18.771939 },
    { lat: 48.805874, lng: 18.7721 },
    { lat: 48.805994, lng: 18.772341 },
    { lat: 48.805883, lng: 18.77249 },
    { lat: 48.805831, lng: 18.772684 },
    { lat: 48.805708, lng: 18.772957 },
    { lat: 48.805656, lng: 18.772979 },
    { lat: 48.805603, lng: 18.773033 },
    { lat: 48.805617, lng: 18.77333 },
    { lat: 48.805351, lng: 18.774081 },
    { lat: 48.804445, lng: 18.776106 },
    { lat: 48.804439, lng: 18.776267 },
    { lat: 48.804717, lng: 18.777122 },
    { lat: 48.804852, lng: 18.777247 },
    { lat: 48.804977, lng: 18.777648 },
    { lat: 48.804951, lng: 18.777795 },
    { lat: 48.8048583, lng: 18.7779647 },
    { lat: 48.804691, lng: 18.778271 },
    { lat: 48.804273, lng: 18.778861 },
    { lat: 48.804139, lng: 18.779578 },
    { lat: 48.804205, lng: 18.780088 },
    { lat: 48.804177, lng: 18.780316 },
    { lat: 48.804147, lng: 18.780318 },
    { lat: 48.803816, lng: 18.78034 },
    { lat: 48.803714, lng: 18.780295 },
    { lat: 48.803592, lng: 18.780181 },
    { lat: 48.803407, lng: 18.780201 },
    { lat: 48.802485, lng: 18.779852 },
    { lat: 48.801992, lng: 18.779104 },
    { lat: 48.801859, lng: 18.779146 },
    { lat: 48.801432, lng: 18.779086 },
    { lat: 48.801009, lng: 18.778906 },
    { lat: 48.800386, lng: 18.778477 },
    { lat: 48.799879, lng: 18.778152 },
    { lat: 48.799149, lng: 18.777838 },
    { lat: 48.798542, lng: 18.777733 },
    { lat: 48.797965, lng: 18.77772 },
    { lat: 48.797851, lng: 18.777502 },
    { lat: 48.797418, lng: 18.776986 },
    { lat: 48.797282, lng: 18.776827 },
    { lat: 48.796321, lng: 18.776358 },
    { lat: 48.796277, lng: 18.776942 },
    { lat: 48.796239, lng: 18.776991 },
    { lat: 48.795286, lng: 18.776401 },
    { lat: 48.795168, lng: 18.776221 },
    { lat: 48.79435, lng: 18.776275 },
    { lat: 48.793997, lng: 18.776267 },
    { lat: 48.793597, lng: 18.776314 },
    { lat: 48.79329, lng: 18.776399 },
    { lat: 48.793149, lng: 18.776513 },
    { lat: 48.792849, lng: 18.77646 },
    { lat: 48.792129, lng: 18.776426 },
    { lat: 48.791866, lng: 18.776401 },
    { lat: 48.791859, lng: 18.776377 },
    { lat: 48.791668, lng: 18.776439 },
    { lat: 48.791144, lng: 18.776548 },
    { lat: 48.790778, lng: 18.776719 },
    { lat: 48.79052, lng: 18.776938 },
    { lat: 48.790338, lng: 18.777162 },
    { lat: 48.790119, lng: 18.777523 },
    { lat: 48.790079, lng: 18.777906 },
    { lat: 48.7900034, lng: 18.7781676 },
    { lat: 48.7899055, lng: 18.7784416 },
    { lat: 48.7898432, lng: 18.7786069 },
    { lat: 48.789775, lng: 18.778791 },
    { lat: 48.78968, lng: 18.77902 },
    { lat: 48.789416, lng: 18.77947 },
    { lat: 48.789019, lng: 18.77995 },
    { lat: 48.78889, lng: 18.780073 },
    { lat: 48.78869, lng: 18.780338 },
    { lat: 48.788472, lng: 18.780719 },
    { lat: 48.788241, lng: 18.781211 },
    { lat: 48.788159, lng: 18.781346 },
    { lat: 48.787966, lng: 18.781758 },
    { lat: 48.787644, lng: 18.782173 },
    { lat: 48.787545, lng: 18.78236 },
    { lat: 48.787367, lng: 18.782734 },
    { lat: 48.786888, lng: 18.783595 },
    { lat: 48.786658, lng: 18.783844 },
    { lat: 48.786614, lng: 18.783907 },
    { lat: 48.78655, lng: 18.784225 },
    { lat: 48.786198, lng: 18.78474 },
    { lat: 48.786213, lng: 18.784763 },
    { lat: 48.786157, lng: 18.784871 },
    { lat: 48.786128, lng: 18.784979 },
    { lat: 48.786125, lng: 18.785232 },
    { lat: 48.78607, lng: 18.785833 },
    { lat: 48.785976, lng: 18.786281 },
    { lat: 48.785821, lng: 18.786664 },
    { lat: 48.785617, lng: 18.787003 },
    { lat: 48.785432, lng: 18.787205 },
    { lat: 48.78525, lng: 18.787524 },
    { lat: 48.785134, lng: 18.787645 },
    { lat: 48.784945, lng: 18.787879 },
    { lat: 48.784451, lng: 18.788028 },
    { lat: 48.784212, lng: 18.788106 },
    { lat: 48.784028, lng: 18.789246 },
    { lat: 48.783215, lng: 18.79073 },
    { lat: 48.783035, lng: 18.7914 },
    { lat: 48.783065, lng: 18.792051 },
    { lat: 48.782824, lng: 18.792709 },
    { lat: 48.782595, lng: 18.793513 },
    { lat: 48.782421, lng: 18.793663 },
    { lat: 48.782039, lng: 18.793772 },
    { lat: 48.781436, lng: 18.793875 },
    { lat: 48.781028, lng: 18.794423 },
    { lat: 48.780694, lng: 18.794579 },
    { lat: 48.78018, lng: 18.794918 },
    { lat: 48.780133, lng: 18.795096 },
    { lat: 48.780111, lng: 18.795154 },
    { lat: 48.779837, lng: 18.795479 },
    { lat: 48.779689, lng: 18.795681 },
    { lat: 48.779501, lng: 18.795881 },
    { lat: 48.779115, lng: 18.796308 },
    { lat: 48.779048, lng: 18.796388 },
    { lat: 48.779057, lng: 18.796418 },
    { lat: 48.778851, lng: 18.796801 },
    { lat: 48.778772, lng: 18.796895 },
    { lat: 48.778297, lng: 18.797548 },
    { lat: 48.778045, lng: 18.798014 },
    { lat: 48.777648, lng: 18.798775 },
    { lat: 48.777436, lng: 18.799125 },
    { lat: 48.777338, lng: 18.799242 },
    { lat: 48.776969, lng: 18.799685 },
    { lat: 48.776549, lng: 18.800324 },
    { lat: 48.776214, lng: 18.800904 },
    { lat: 48.775564, lng: 18.801579 },
    { lat: 48.775223, lng: 18.802045 },
    { lat: 48.774882, lng: 18.802429 },
    { lat: 48.77474, lng: 18.802602 },
    { lat: 48.774556, lng: 18.803005 },
    { lat: 48.77451, lng: 18.803123 },
    { lat: 48.774471, lng: 18.80311 },
    { lat: 48.774386, lng: 18.8034 },
    { lat: 48.774175, lng: 18.803806 },
    { lat: 48.773809, lng: 18.804036 },
    { lat: 48.773769, lng: 18.804062 },
    { lat: 48.773728, lng: 18.804088 },
    { lat: 48.773583, lng: 18.804181 },
    { lat: 48.773516, lng: 18.804275 },
    { lat: 48.773413, lng: 18.804399 },
    { lat: 48.773205, lng: 18.804733 },
    { lat: 48.772575, lng: 18.80549 },
    { lat: 48.772013, lng: 18.806202 },
    { lat: 48.771992, lng: 18.806216 },
    { lat: 48.771801, lng: 18.806338 },
    { lat: 48.771671, lng: 18.806505 },
    { lat: 48.771553, lng: 18.806794 },
    { lat: 48.771305, lng: 18.807187 },
    { lat: 48.771034, lng: 18.807685 },
    { lat: 48.770429, lng: 18.808433 },
    { lat: 48.770384, lng: 18.808637 },
    { lat: 48.769581, lng: 18.809289 },
    { lat: 48.769369, lng: 18.809558 },
    { lat: 48.769173, lng: 18.810111 },
    { lat: 48.769167, lng: 18.810144 },
    { lat: 48.769018, lng: 18.810149 },
    { lat: 48.768755, lng: 18.810004 },
    { lat: 48.768537, lng: 18.80982 },
    { lat: 48.768403, lng: 18.809808 },
    { lat: 48.768403, lng: 18.80978 },
    { lat: 48.768275, lng: 18.809792 },
    { lat: 48.768123, lng: 18.8098 },
    { lat: 48.767954, lng: 18.809687 },
    { lat: 48.767681, lng: 18.809516 },
    { lat: 48.767284, lng: 18.809297 },
    { lat: 48.767241, lng: 18.809244 },
    { lat: 48.767148, lng: 18.809391 },
    { lat: 48.766873, lng: 18.80937 },
    { lat: 48.766721, lng: 18.809377 },
    { lat: 48.766504, lng: 18.809579 },
    { lat: 48.766373, lng: 18.809406 },
    { lat: 48.766326, lng: 18.809299 },
    { lat: 48.766067, lng: 18.809174 },
    { lat: 48.765905, lng: 18.809267 },
    { lat: 48.765866, lng: 18.809155 },
    { lat: 48.76562, lng: 18.809172 },
    { lat: 48.765339, lng: 18.809356 },
    { lat: 48.765242, lng: 18.809432 },
    { lat: 48.764942, lng: 18.809691 },
    { lat: 48.764879, lng: 18.809827 },
    { lat: 48.764922, lng: 18.809997 },
    { lat: 48.76472, lng: 18.810221 },
    { lat: 48.764496, lng: 18.810287 },
    { lat: 48.764248, lng: 18.810526 },
    { lat: 48.76383, lng: 18.810831 },
    { lat: 48.76366, lng: 18.810881 },
    { lat: 48.763157, lng: 18.811475 },
    { lat: 48.762955, lng: 18.811671 },
    { lat: 48.762703, lng: 18.811807 },
    { lat: 48.762379, lng: 18.811597 },
    { lat: 48.762152, lng: 18.811334 },
    { lat: 48.762096, lng: 18.811301 },
    { lat: 48.762007, lng: 18.811484 },
    { lat: 48.761932, lng: 18.811557 },
    { lat: 48.761938, lng: 18.811623 },
    { lat: 48.761942, lng: 18.811675 },
    { lat: 48.761998, lng: 18.81177 },
    { lat: 48.762018, lng: 18.812119 },
    { lat: 48.761964, lng: 18.812356 },
    { lat: 48.761741, lng: 18.813032 },
    { lat: 48.76147, lng: 18.813323 },
    { lat: 48.761335, lng: 18.813542 },
    { lat: 48.76101, lng: 18.813811 },
    { lat: 48.760888, lng: 18.813793 },
    { lat: 48.760709, lng: 18.813944 },
    { lat: 48.760696, lng: 18.813902 },
    { lat: 48.760191, lng: 18.814193 },
    { lat: 48.759977, lng: 18.814119 },
    { lat: 48.759638, lng: 18.814339 },
    { lat: 48.75921, lng: 18.814512 },
    { lat: 48.758779, lng: 18.814448 },
    { lat: 48.758719, lng: 18.814768 },
    { lat: 48.75861, lng: 18.815271 },
    { lat: 48.758501, lng: 18.815517 },
    { lat: 48.75822, lng: 18.816011 },
    { lat: 48.758146, lng: 18.816217 },
    { lat: 48.757881, lng: 18.816909 },
    { lat: 48.757723, lng: 18.816949 },
    { lat: 48.757698, lng: 18.817446 },
    { lat: 48.757748, lng: 18.817909 },
    { lat: 48.757646, lng: 18.818311 },
    { lat: 48.757661, lng: 18.818685 },
    { lat: 48.757327, lng: 18.819174 },
    { lat: 48.7572149, lng: 18.8197816 },
    { lat: 48.757208, lng: 18.819819 },
    { lat: 48.756972, lng: 18.820021 },
    { lat: 48.756632, lng: 18.820734 },
    { lat: 48.756143, lng: 18.821618 },
    { lat: 48.756109, lng: 18.821964 },
    { lat: 48.755968, lng: 18.822633 },
    { lat: 48.755994, lng: 18.822665 },
    { lat: 48.755705, lng: 18.823078 },
    { lat: 48.755234, lng: 18.823318 },
    { lat: 48.754869, lng: 18.823282 },
    { lat: 48.754099, lng: 18.823908 },
    { lat: 48.754048, lng: 18.823986 },
    { lat: 48.754017, lng: 18.824034 },
    { lat: 48.75389, lng: 18.824001 },
    { lat: 48.753698, lng: 18.824082 },
    { lat: 48.753635, lng: 18.824343 },
    { lat: 48.753138, lng: 18.824747 },
    { lat: 48.752992, lng: 18.824739 },
    { lat: 48.752941, lng: 18.824815 },
    { lat: 48.75258, lng: 18.824922 },
    { lat: 48.752362, lng: 18.824392 },
    { lat: 48.751948, lng: 18.824303 },
    { lat: 48.751639, lng: 18.824285 },
    { lat: 48.751508, lng: 18.824405 },
    { lat: 48.751287, lng: 18.824472 },
    { lat: 48.75124, lng: 18.824629 },
    { lat: 48.75105, lng: 18.824713 },
    { lat: 48.75094, lng: 18.824814 },
    { lat: 48.7505, lng: 18.824788 },
    { lat: 48.750373, lng: 18.824699 },
    { lat: 48.750286, lng: 18.824165 },
    { lat: 48.75006, lng: 18.824159 },
    { lat: 48.749943, lng: 18.824238 },
    { lat: 48.749856, lng: 18.824348 },
    { lat: 48.749509, lng: 18.824633 },
    { lat: 48.749295, lng: 18.824678 },
    { lat: 48.748955, lng: 18.824817 },
    { lat: 48.748734, lng: 18.824632 },
    { lat: 48.748484, lng: 18.8247 },
    { lat: 48.748278, lng: 18.824698 },
    { lat: 48.747921, lng: 18.824776 },
    { lat: 48.747465, lng: 18.824615 },
    { lat: 48.747316, lng: 18.824531 },
    { lat: 48.746967, lng: 18.824675 },
    { lat: 48.746901, lng: 18.824715 },
    { lat: 48.746777, lng: 18.82509 },
    { lat: 48.746463, lng: 18.825324 },
    { lat: 48.746267, lng: 18.825524 },
    { lat: 48.746088, lng: 18.82586 },
    { lat: 48.74609, lng: 18.825861 },
    { lat: 48.745464, lng: 18.826157 },
    { lat: 48.745318, lng: 18.826269 },
    { lat: 48.744938, lng: 18.826685 },
    { lat: 48.744644, lng: 18.826697 },
    { lat: 48.74453, lng: 18.826703 },
    { lat: 48.744057, lng: 18.826348 },
    { lat: 48.744147, lng: 18.826841 },
    { lat: 48.744619, lng: 18.827914 },
    { lat: 48.74603, lng: 18.829653 },
    { lat: 48.746627, lng: 18.830172 },
    { lat: 48.746906, lng: 18.830653 },
    { lat: 48.747037, lng: 18.830873 },
    { lat: 48.747306, lng: 18.831598 },
    { lat: 48.747918, lng: 18.832329 },
    { lat: 48.748033, lng: 18.832758 },
    { lat: 48.748418, lng: 18.833765 },
    { lat: 48.748339, lng: 18.83439 },
    { lat: 48.74841, lng: 18.834768 },
    { lat: 48.74868, lng: 18.835388 },
    { lat: 48.748688, lng: 18.835741 },
    { lat: 48.749075, lng: 18.83694 },
    { lat: 48.749241, lng: 18.838338 },
    { lat: 48.749315, lng: 18.838569 },
    { lat: 48.7494, lng: 18.838805 },
    { lat: 48.749495, lng: 18.839246 },
    { lat: 48.749644, lng: 18.839579 },
    { lat: 48.750339, lng: 18.840343 },
    { lat: 48.750524, lng: 18.841257 },
    { lat: 48.750262, lng: 18.84212 },
    { lat: 48.75024, lng: 18.842185 },
    { lat: 48.750185, lng: 18.84236 },
    { lat: 48.750177, lng: 18.842387 },
    { lat: 48.750721, lng: 18.842973 },
    { lat: 48.751079, lng: 18.843356 },
    { lat: 48.751168, lng: 18.843455 },
    { lat: 48.751876, lng: 18.84402 },
    { lat: 48.751921, lng: 18.844197 },
    { lat: 48.75225, lng: 18.844532 },
    { lat: 48.752419, lng: 18.844776 },
    { lat: 48.75251, lng: 18.844854 },
    { lat: 48.752691, lng: 18.84487 },
    { lat: 48.752844, lng: 18.844762 },
    { lat: 48.752982, lng: 18.844731 },
    { lat: 48.753123, lng: 18.844759 },
    { lat: 48.753289, lng: 18.844895 },
    { lat: 48.753459, lng: 18.845524 },
    { lat: 48.753596, lng: 18.845708 },
    { lat: 48.753659, lng: 18.845784 },
    { lat: 48.753709, lng: 18.846033 },
    { lat: 48.753817, lng: 18.846232 },
    { lat: 48.753985, lng: 18.846344 },
    { lat: 48.754015, lng: 18.846499 },
    { lat: 48.75404, lng: 18.846744 },
    { lat: 48.754133, lng: 18.846929 },
    { lat: 48.754345, lng: 18.847022 },
    { lat: 48.754662, lng: 18.846961 },
    { lat: 48.75505, lng: 18.846973 },
    { lat: 48.755364, lng: 18.847042 },
    { lat: 48.755542, lng: 18.847141 },
    { lat: 48.755826, lng: 18.847376 },
    { lat: 48.756002, lng: 18.847831 },
    { lat: 48.756243, lng: 18.848392 },
    { lat: 48.756587, lng: 18.848762 },
    { lat: 48.7567, lng: 18.848914 },
    { lat: 48.756741, lng: 18.849109 },
    { lat: 48.756786, lng: 18.8494 },
    { lat: 48.757005, lng: 18.849874 },
    { lat: 48.757108, lng: 18.8501 },
    { lat: 48.757495, lng: 18.85093 },
    { lat: 48.757561, lng: 18.851068 },
    { lat: 48.757785, lng: 18.851601 },
    { lat: 48.757904, lng: 18.851887 },
    { lat: 48.758093, lng: 18.852495 },
    { lat: 48.758246, lng: 18.853002 },
    { lat: 48.758373, lng: 18.853418 },
    { lat: 48.758465, lng: 18.853556 },
    { lat: 48.758752, lng: 18.853986 },
    { lat: 48.758758, lng: 18.853995 },
    { lat: 48.75883, lng: 18.854103 },
    { lat: 48.759028, lng: 18.854311 },
    { lat: 48.759111, lng: 18.854337 },
    { lat: 48.759202, lng: 18.854304 },
    { lat: 48.759289, lng: 18.854265 },
    { lat: 48.759366, lng: 18.854234 },
    { lat: 48.759523, lng: 18.854247 },
    { lat: 48.759607, lng: 18.854254 },
    { lat: 48.75963, lng: 18.854266 },
    { lat: 48.760096, lng: 18.854522 },
    { lat: 48.760275, lng: 18.854582 },
    { lat: 48.760641, lng: 18.854594 },
    { lat: 48.761017, lng: 18.854449 },
    { lat: 48.761455, lng: 18.854389 },
    { lat: 48.761746, lng: 18.85434 },
    { lat: 48.762207, lng: 18.854256 },
    { lat: 48.762861, lng: 18.854253 },
    { lat: 48.763245, lng: 18.854378 },
    { lat: 48.763444, lng: 18.854531 },
    { lat: 48.763446, lng: 18.854572 },
    { lat: 48.763452, lng: 18.854777 },
    { lat: 48.763452, lng: 18.85478 },
    { lat: 48.763456, lng: 18.854894 },
    { lat: 48.763456, lng: 18.854901 },
    { lat: 48.763462, lng: 18.855214 },
    { lat: 48.763459, lng: 18.855357 },
    { lat: 48.763456, lng: 18.855477 },
    { lat: 48.763444, lng: 18.856014 },
    { lat: 48.763489, lng: 18.856978 },
    { lat: 48.763625, lng: 18.858315 },
    { lat: 48.763647, lng: 18.858527 },
    { lat: 48.763658, lng: 18.858662 },
    { lat: 48.763688, lng: 18.858916 },
    { lat: 48.763741, lng: 18.859036 },
    { lat: 48.763786, lng: 18.859139 },
    { lat: 48.763865, lng: 18.860094 },
    { lat: 48.763996, lng: 18.860439 },
    { lat: 48.764048, lng: 18.860611 },
    { lat: 48.764509, lng: 18.862141 },
    { lat: 48.764638, lng: 18.863354 },
    { lat: 48.765185, lng: 18.863826 },
    { lat: 48.76552, lng: 18.864295 },
    { lat: 48.766296, lng: 18.864803 },
    { lat: 48.767169, lng: 18.866643 },
    { lat: 48.767341, lng: 18.867373 },
    { lat: 48.767404, lng: 18.867969 },
    { lat: 48.76712, lng: 18.868656 },
    { lat: 48.767146, lng: 18.86906 },
    { lat: 48.76676, lng: 18.87044 },
    { lat: 48.767182, lng: 18.871192 },
    { lat: 48.767339, lng: 18.87137 },
    { lat: 48.767707, lng: 18.871631 },
    { lat: 48.768424, lng: 18.872134 },
    { lat: 48.768859, lng: 18.872798 },
    { lat: 48.769047, lng: 18.872962 },
    { lat: 48.769386, lng: 18.873509 },
    { lat: 48.7720717, lng: 18.8732989 },
    { lat: 48.7743577, lng: 18.8732193 },
    { lat: 48.7748603, lng: 18.8739818 },
    { lat: 48.7750395, lng: 18.8742406 },
    { lat: 48.7755767, lng: 18.8741411 },
    { lat: 48.7759863, lng: 18.8742768 },
    { lat: 48.7766891, lng: 18.8738671 },
    { lat: 48.7771266, lng: 18.8739331 },
    { lat: 48.7776534, lng: 18.8735417 },
    { lat: 48.7778986, lng: 18.8735782 },
    { lat: 48.7780789, lng: 18.8737521 },
    { lat: 48.7782706, lng: 18.8735343 },
    { lat: 48.7783109, lng: 18.8733121 },
    { lat: 48.7783744, lng: 18.872659 },
    { lat: 48.778523, lng: 18.8718252 },
    { lat: 48.7786974, lng: 18.8705715 },
    { lat: 48.7787794, lng: 18.8698887 },
    { lat: 48.7791998, lng: 18.8696459 },
    { lat: 48.7797458, lng: 18.8691692 },
    { lat: 48.7799781, lng: 18.8689087 },
    { lat: 48.7800892, lng: 18.8689613 },
    { lat: 48.78016, lng: 18.8688496 },
    { lat: 48.7807324, lng: 18.8684037 },
    { lat: 48.7808552, lng: 18.8683792 },
    { lat: 48.7809348, lng: 18.8684145 },
    { lat: 48.7810215, lng: 18.8685423 },
    { lat: 48.7810686, lng: 18.8685389 },
    { lat: 48.7810796, lng: 18.8687303 },
    { lat: 48.781118, lng: 18.869104 },
    { lat: 48.7811392, lng: 18.8694033 },
    { lat: 48.7814432, lng: 18.8695719 },
    { lat: 48.7813847, lng: 18.8704018 },
    { lat: 48.7814839, lng: 18.870645 },
    { lat: 48.7810628, lng: 18.8720555 },
    { lat: 48.7808764, lng: 18.8722023 },
    { lat: 48.7807944, lng: 18.87234 },
    { lat: 48.7807008, lng: 18.8726235 },
    { lat: 48.7804641, lng: 18.8723854 },
    { lat: 48.7795187, lng: 18.8747591 },
    { lat: 48.779726, lng: 18.8749063 },
    { lat: 48.7798502, lng: 18.8750425 },
    { lat: 48.7796273, lng: 18.8756186 },
    { lat: 48.7795393, lng: 18.876033 },
    { lat: 48.7796266, lng: 18.8763481 },
    { lat: 48.7800646, lng: 18.8765798 },
    { lat: 48.7800762, lng: 18.8766029 },
    { lat: 48.7811378, lng: 18.8748015 },
    { lat: 48.7823217, lng: 18.8739023 },
    { lat: 48.7830986, lng: 18.8737513 },
    { lat: 48.7845264, lng: 18.8732502 },
    { lat: 48.7848373, lng: 18.8732682 },
    { lat: 48.7857863, lng: 18.8727561 },
    { lat: 48.7865875, lng: 18.8724391 },
    { lat: 48.7876098, lng: 18.87252 },
    { lat: 48.7884089, lng: 18.8721625 },
    { lat: 48.7887757, lng: 18.8716007 },
    { lat: 48.7889384, lng: 18.8713884 },
    { lat: 48.7904446, lng: 18.8706852 },
    { lat: 48.7905803, lng: 18.8706048 },
    { lat: 48.7906895, lng: 18.8705143 },
    { lat: 48.7907913, lng: 18.8703896 },
    { lat: 48.79133, lng: 18.8700785 },
    { lat: 48.7920269, lng: 18.8699064 },
    { lat: 48.7921156, lng: 18.8698755 },
    { lat: 48.7924012, lng: 18.8697971 },
    { lat: 48.7934403, lng: 18.8689981 },
    { lat: 48.7941321, lng: 18.868548 },
    { lat: 48.7961182, lng: 18.8674027 },
    { lat: 48.797021, lng: 18.8664991 },
    { lat: 48.7981548, lng: 18.8655042 },
    { lat: 48.7985637, lng: 18.8653003 },
    { lat: 48.7992526, lng: 18.8645979 },
    { lat: 48.7996738, lng: 18.8637809 },
    { lat: 48.7998009, lng: 18.8636904 },
    { lat: 48.8006196, lng: 18.8627959 },
    { lat: 48.8011726, lng: 18.8624966 },
    { lat: 48.8015793, lng: 18.8622729 },
    { lat: 48.8025455, lng: 18.8614829 },
    { lat: 48.8034207, lng: 18.8602665 },
    { lat: 48.8034354, lng: 18.8602898 },
    { lat: 48.8038231, lng: 18.8602836 },
    { lat: 48.8040104, lng: 18.8607046 },
    { lat: 48.8040534, lng: 18.8607718 },
    { lat: 48.8040644, lng: 18.8608077 },
    { lat: 48.8042048, lng: 18.8607113 },
    { lat: 48.8048489, lng: 18.8607395 },
    { lat: 48.8049732, lng: 18.8606755 },
    { lat: 48.8050953, lng: 18.8605966 },
    { lat: 48.8056437, lng: 18.8602996 },
    { lat: 48.8057898, lng: 18.8603563 },
    { lat: 48.8058996, lng: 18.8602993 },
    { lat: 48.8060091, lng: 18.8600177 },
    { lat: 48.8060953, lng: 18.8599163 },
    { lat: 48.8062337, lng: 18.8598867 },
    { lat: 48.8063437, lng: 18.8600543 },
    { lat: 48.806532, lng: 18.8600535 },
    { lat: 48.807318, lng: 18.8591824 },
    { lat: 48.8080747, lng: 18.8587916 },
    { lat: 48.8081048, lng: 18.8588485 },
    { lat: 48.8081608, lng: 18.8589953 },
    { lat: 48.8082994, lng: 18.8592121 },
    { lat: 48.8083803, lng: 18.8592051 },
    { lat: 48.8084733, lng: 18.858998 },
    { lat: 48.8089318, lng: 18.8584866 },
    { lat: 48.8090486, lng: 18.8585203 },
    { lat: 48.8095253, lng: 18.858408 },
    { lat: 48.809575, lng: 18.8583467 },
    { lat: 48.8096813, lng: 18.8583499 },
    { lat: 48.8097977, lng: 18.8583994 },
    { lat: 48.8099412, lng: 18.8583584 },
    { lat: 48.8100779, lng: 18.8581916 },
    { lat: 48.8102089, lng: 18.8581536 },
    { lat: 48.8103869, lng: 18.8581282 },
    { lat: 48.8106522, lng: 18.8578932 },
    { lat: 48.8106723, lng: 18.8577785 },
    { lat: 48.8109263, lng: 18.8574368 },
    { lat: 48.8110056, lng: 18.8574021 },
    { lat: 48.8112401, lng: 18.8575961 },
    { lat: 48.8113692, lng: 18.8574756 },
    { lat: 48.8114322, lng: 18.8571898 },
    { lat: 48.8115136, lng: 18.8569766 },
    { lat: 48.811638, lng: 18.8568739 },
    { lat: 48.8117816, lng: 18.8568354 },
    { lat: 48.8119131, lng: 18.8568699 },
    { lat: 48.8120194, lng: 18.8567929 },
    { lat: 48.8120881, lng: 18.8566127 },
    { lat: 48.8121859, lng: 18.856307 },
    { lat: 48.8122542, lng: 18.8562059 },
    { lat: 48.8123518, lng: 18.8561743 },
    { lat: 48.8124364, lng: 18.8561965 },
    { lat: 48.8125571, lng: 18.8561325 },
    { lat: 48.8125723, lng: 18.856101 },
    { lat: 48.8125832, lng: 18.8559831 },
    { lat: 48.8125814, lng: 18.8559496 },
    { lat: 48.812604, lng: 18.8559294 },
    { lat: 48.8127226, lng: 18.8558868 },
    { lat: 48.8128597, lng: 18.8559428 },
    { lat: 48.8129813, lng: 18.8559187 },
    { lat: 48.8130897, lng: 18.85579 },
    { lat: 48.8131133, lng: 18.8557249 },
    { lat: 48.813151, lng: 18.8551477 },
    { lat: 48.8131049, lng: 18.8547131 },
    { lat: 48.8131614, lng: 18.8545611 },
    { lat: 48.8131546, lng: 18.8543208 },
    { lat: 48.813247, lng: 18.8541889 },
    { lat: 48.8134484, lng: 18.8536549 },
    { lat: 48.8134973, lng: 18.8536409 },
    { lat: 48.8135311, lng: 18.8536415 },
    { lat: 48.8139505, lng: 18.8532712 },
    { lat: 48.8139728, lng: 18.8530489 },
    { lat: 48.8141195, lng: 18.8527646 },
    { lat: 48.814265, lng: 18.8526368 },
    { lat: 48.8143747, lng: 18.8526013 },
    { lat: 48.8144602, lng: 18.8526831 },
    { lat: 48.8145327, lng: 18.8527092 },
    { lat: 48.8145779, lng: 18.8526699 },
    { lat: 48.8146528, lng: 18.8525465 },
    { lat: 48.8147211, lng: 18.8523398 },
    { lat: 48.8152068, lng: 18.852074 },
    { lat: 48.8154836, lng: 18.8520849 },
    { lat: 48.8156407, lng: 18.8521797 },
    { lat: 48.8158622, lng: 18.8522547 },
    { lat: 48.8161515, lng: 18.8521331 },
    { lat: 48.8166019, lng: 18.8517734 },
    { lat: 48.8166212, lng: 18.8516899 },
    { lat: 48.8167939, lng: 18.8514336 },
    { lat: 48.8168113, lng: 18.8512899 },
    { lat: 48.8169112, lng: 18.8509743 },
    { lat: 48.817067, lng: 18.850282 },
    { lat: 48.8170688, lng: 18.8500561 },
    { lat: 48.8171127, lng: 18.849853 },
    { lat: 48.8171428, lng: 18.8495228 },
    { lat: 48.8171781, lng: 18.8493547 },
    { lat: 48.817327, lng: 18.8489209 },
    { lat: 48.8174298, lng: 18.8485109 },
    { lat: 48.8176589, lng: 18.8478142 },
    { lat: 48.817746, lng: 18.8476028 },
    { lat: 48.8178712, lng: 18.8471053 },
    { lat: 48.8179018, lng: 18.846888 },
    { lat: 48.8180259, lng: 18.8462353 },
    { lat: 48.8182404, lng: 18.845368 },
    { lat: 48.8182775, lng: 18.845126 },
    { lat: 48.8182914, lng: 18.8448284 },
    { lat: 48.8186475, lng: 18.8438419 },
    { lat: 48.8186462, lng: 18.8437301 },
    { lat: 48.8186913, lng: 18.8432978 },
    { lat: 48.8189758, lng: 18.8428222 },
    { lat: 48.8191029, lng: 18.842251 },
    { lat: 48.8193683, lng: 18.8415407 },
    { lat: 48.8194085, lng: 18.8413595 },
    { lat: 48.8195328, lng: 18.8401697 },
    { lat: 48.8189748, lng: 18.8397784 },
    { lat: 48.8182646, lng: 18.8394563 },
    { lat: 48.8182583, lng: 18.8393801 },
    { lat: 48.8182862, lng: 18.8391352 },
    { lat: 48.8194938, lng: 18.8388057 },
    { lat: 48.8196731, lng: 18.8386534 },
    { lat: 48.8198225, lng: 18.8382721 },
    { lat: 48.8202848, lng: 18.8369469 },
    { lat: 48.8203336, lng: 18.8365589 },
    { lat: 48.8203722, lng: 18.8362824 },
    { lat: 48.8204114, lng: 18.8358204 },
    { lat: 48.8199699, lng: 18.8353723 },
    { lat: 48.8201503, lng: 18.8348088 },
    { lat: 48.8201658, lng: 18.8343876 },
    { lat: 48.8201133, lng: 18.834119 },
    { lat: 48.8199582, lng: 18.8335155 },
    { lat: 48.8200972, lng: 18.8333287 },
    { lat: 48.8205668, lng: 18.8333591 },
    { lat: 48.8208151, lng: 18.8325158 },
    { lat: 48.821114, lng: 18.8317134 },
    { lat: 48.820949, lng: 18.8313395 },
    { lat: 48.8208891, lng: 18.8312264 },
    { lat: 48.8208002, lng: 18.8309661 },
    { lat: 48.8206634, lng: 18.8307887 },
    { lat: 48.8204611, lng: 18.8303877 },
    { lat: 48.8203919, lng: 18.8300507 },
    { lat: 48.820293, lng: 18.829711 },
    { lat: 48.8201164, lng: 18.8286124 },
    { lat: 48.8201258, lng: 18.8282531 },
    { lat: 48.8201095, lng: 18.8278056 },
    { lat: 48.8201481, lng: 18.8273763 },
    { lat: 48.8202336, lng: 18.8267646 },
    { lat: 48.8202266, lng: 18.8262845 },
    { lat: 48.8202568, lng: 18.8259521 },
    { lat: 48.8200862, lng: 18.8253999 },
    { lat: 48.8200548, lng: 18.8251882 },
    { lat: 48.8198886, lng: 18.8242902 },
    { lat: 48.819832, lng: 18.8239376 },
    { lat: 48.8197787, lng: 18.8237615 },
    { lat: 48.819722, lng: 18.8236019 },
    { lat: 48.8194952, lng: 18.8232539 },
    { lat: 48.8191838, lng: 18.8227496 },
    { lat: 48.8189576, lng: 18.8224742 },
    { lat: 48.8189192, lng: 18.8223628 },
    { lat: 48.8188673, lng: 18.8219962 },
    { lat: 48.8188431, lng: 18.8218756 },
    { lat: 48.8187745, lng: 18.8217521 },
    { lat: 48.818681, lng: 18.8217081 },
    { lat: 48.8184395, lng: 18.8216618 },
    { lat: 48.8183592, lng: 18.8216127 },
    { lat: 48.8182325, lng: 18.8214993 },
    { lat: 48.8180288, lng: 18.8210294 },
    { lat: 48.8180648, lng: 18.8210277 },
    { lat: 48.8182794, lng: 18.82086 },
    { lat: 48.8181429, lng: 18.8194064 },
    { lat: 48.8180663, lng: 18.8184115 },
    { lat: 48.8178917, lng: 18.8174803 },
    { lat: 48.8178577, lng: 18.8173024 },
    { lat: 48.8176923, lng: 18.8169851 },
    { lat: 48.8180115, lng: 18.8172022 },
    { lat: 48.8181562, lng: 18.8172304 },
    { lat: 48.8186364, lng: 18.8171008 },
    { lat: 48.8188611, lng: 18.8169724 },
    { lat: 48.818888, lng: 18.8168858 },
    { lat: 48.8191441, lng: 18.8167576 },
    { lat: 48.8191978, lng: 18.8167043 },
    { lat: 48.8193072, lng: 18.8167038 },
    { lat: 48.8194596, lng: 18.8167325 },
    { lat: 48.8197967, lng: 18.8165563 },
    { lat: 48.8199792, lng: 18.8164869 },
    { lat: 48.8206596, lng: 18.8165604 },
    { lat: 48.8207937, lng: 18.8165368 },
    { lat: 48.821058, lng: 18.8166109 },
    { lat: 48.8211744, lng: 18.8167448 },
    { lat: 48.8212864, lng: 18.8167765 },
    { lat: 48.8214386, lng: 18.8165136 },
    { lat: 48.8215467, lng: 18.8164656 },
    { lat: 48.8216916, lng: 18.8164984 },
    { lat: 48.8220416, lng: 18.8165315 },
    { lat: 48.8224986, lng: 18.8166001 },
    { lat: 48.8231804, lng: 18.8167653 },
    { lat: 48.8232755, lng: 18.8174274 },
    { lat: 48.8232637, lng: 18.817591 },
    { lat: 48.8231615, lng: 18.817942 },
    { lat: 48.8232132, lng: 18.817982 },
    { lat: 48.8232948, lng: 18.8179014 },
    { lat: 48.8233732, lng: 18.8178921 },
    { lat: 48.823449, lng: 18.8179403 },
    { lat: 48.8235362, lng: 18.8181239 },
    { lat: 48.8235696, lng: 18.8180694 },
    { lat: 48.8238264, lng: 18.8182371 },
    { lat: 48.8247118, lng: 18.816097 },
    { lat: 48.8247092, lng: 18.8160483 },
    { lat: 48.824896, lng: 18.8160185 },
    { lat: 48.8251139, lng: 18.8160458 },
    { lat: 48.825288, lng: 18.8162895 },
    { lat: 48.8256174, lng: 18.8165086 },
    { lat: 48.8257235, lng: 18.8165124 },
    { lat: 48.8258392, lng: 18.8164843 },
    { lat: 48.8259971, lng: 18.8164626 },
    { lat: 48.8260774, lng: 18.8165532 },
    { lat: 48.8262031, lng: 18.8165207 },
    { lat: 48.8262968, lng: 18.8166963 },
    { lat: 48.8264012, lng: 18.8167715 },
    { lat: 48.8264627, lng: 18.8168501 },
    { lat: 48.8266321, lng: 18.8169877 },
    { lat: 48.8268155, lng: 18.8169787 },
    { lat: 48.8270651, lng: 18.8170935 },
    { lat: 48.8272948, lng: 18.8172809 },
    { lat: 48.8275213, lng: 18.8174317 },
    { lat: 48.8276879, lng: 18.8175099 },
    { lat: 48.8278008, lng: 18.8175136 },
    { lat: 48.8278554, lng: 18.8176098 },
    { lat: 48.8278591, lng: 18.8177672 },
    { lat: 48.8278526, lng: 18.8178821 },
    { lat: 48.8278766, lng: 18.8179315 },
    { lat: 48.8279375, lng: 18.8179529 },
    { lat: 48.8280853, lng: 18.8180405 },
    { lat: 48.8281974, lng: 18.8180704 },
    { lat: 48.8283256, lng: 18.8179549 },
    { lat: 48.8284437, lng: 18.817909 },
    { lat: 48.8285285, lng: 18.8178033 },
    { lat: 48.8286413, lng: 18.8178464 },
    { lat: 48.828713, lng: 18.8179252 },
    { lat: 48.8288047, lng: 18.8179518 },
    { lat: 48.8288998, lng: 18.8180912 },
    { lat: 48.8289772, lng: 18.81802 },
    { lat: 48.8290254, lng: 18.8180114 },
    { lat: 48.8291032, lng: 18.8180572 },
    { lat: 48.8291637, lng: 18.8181993 },
    { lat: 48.8292885, lng: 18.8182588 },
    { lat: 48.8293467, lng: 18.8183607 },
    { lat: 48.8294316, lng: 18.8183201 },
    { lat: 48.8295489, lng: 18.8182993 },
    { lat: 48.8295954, lng: 18.8182401 },
    { lat: 48.8296065, lng: 18.8181726 },
    { lat: 48.8296724, lng: 18.8181615 },
    { lat: 48.8297322, lng: 18.8182041 },
    { lat: 48.8298154, lng: 18.8183084 },
    { lat: 48.8298852, lng: 18.818305 },
    { lat: 48.8299519, lng: 18.8182652 },
    { lat: 48.8300104, lng: 18.8183055 },
    { lat: 48.8300403, lng: 18.8184891 },
    { lat: 48.8300444, lng: 18.8186547 },
    { lat: 48.830092, lng: 18.8186834 },
    { lat: 48.830109, lng: 18.8187519 },
    { lat: 48.8302137, lng: 18.8187258 },
    { lat: 48.8303285, lng: 18.8187647 },
    { lat: 48.8304028, lng: 18.8186528 },
    { lat: 48.8303678, lng: 18.8186079 },
    { lat: 48.8303518, lng: 18.8184953 },
    { lat: 48.8303018, lng: 18.8184017 },
    { lat: 48.8302248, lng: 18.8183748 },
    { lat: 48.8301509, lng: 18.8182977 },
    { lat: 48.8300901, lng: 18.8181697 },
    { lat: 48.8301048, lng: 18.818062 },
    { lat: 48.8300501, lng: 18.8179189 },
    { lat: 48.8298195, lng: 18.8178877 },
    { lat: 48.8298092, lng: 18.8178392 },
    { lat: 48.8298314, lng: 18.8178118 },
    { lat: 48.829627, lng: 18.8177435 },
    { lat: 48.829563, lng: 18.8176176 },
    { lat: 48.8294991, lng: 18.8175605 },
    { lat: 48.8294876, lng: 18.8175984 },
    { lat: 48.8293947, lng: 18.8176109 },
    { lat: 48.829289, lng: 18.8174861 },
    { lat: 48.8292721, lng: 18.8174208 },
    { lat: 48.8292806, lng: 18.8173704 },
    { lat: 48.8292419, lng: 18.8172759 },
    { lat: 48.8292169, lng: 18.8172499 },
    { lat: 48.8291874, lng: 18.8172688 },
    { lat: 48.8291329, lng: 18.8171289 },
    { lat: 48.8290859, lng: 18.8171567 },
    { lat: 48.8290467, lng: 18.8170132 },
    { lat: 48.8290708, lng: 18.8168897 },
    { lat: 48.8290308, lng: 18.8167935 },
    { lat: 48.8290741, lng: 18.8167401 },
    { lat: 48.8291236, lng: 18.8167363 },
    { lat: 48.8291502, lng: 18.8165771 },
    { lat: 48.8291175, lng: 18.8165112 },
    { lat: 48.8290744, lng: 18.8165042 },
    { lat: 48.8290478, lng: 18.8164243 },
    { lat: 48.8290773, lng: 18.8163868 },
    { lat: 48.8290767, lng: 18.8162444 },
    { lat: 48.8290706, lng: 18.8161951 },
    { lat: 48.8289997, lng: 18.8162168 },
    { lat: 48.8289502, lng: 18.8160709 },
    { lat: 48.8289124, lng: 18.8161026 },
    { lat: 48.8288655, lng: 18.8160864 },
    { lat: 48.8288357, lng: 18.8159496 },
    { lat: 48.8288423, lng: 18.8158805 },
    { lat: 48.828806, lng: 18.8157864 },
    { lat: 48.8287988, lng: 18.8155851 },
    { lat: 48.8288205, lng: 18.815503 },
    { lat: 48.8287683, lng: 18.8153031 },
    { lat: 48.8288121, lng: 18.815232 },
    { lat: 48.8288147, lng: 18.8151551 },
    { lat: 48.8288475, lng: 18.8149587 },
    { lat: 48.8288323, lng: 18.8148853 },
    { lat: 48.8288062, lng: 18.8148365 },
    { lat: 48.8287868, lng: 18.8147462 },
    { lat: 48.8287511, lng: 18.8146685 },
    { lat: 48.8287829, lng: 18.8145659 },
    { lat: 48.8287808, lng: 18.814457 },
    { lat: 48.8288267, lng: 18.8144322 },
    { lat: 48.8288042, lng: 18.8143436 },
    { lat: 48.8287457, lng: 18.8143051 },
    { lat: 48.8287156, lng: 18.8143309 },
    { lat: 48.8286659, lng: 18.8143111 },
    { lat: 48.8286348, lng: 18.8142776 },
    { lat: 48.8286396, lng: 18.814238 },
    { lat: 48.8285998, lng: 18.8141436 },
    { lat: 48.8285636, lng: 18.8141425 },
    { lat: 48.8285352, lng: 18.8141152 },
    { lat: 48.8285117, lng: 18.8140546 },
    { lat: 48.8284853, lng: 18.8139375 },
    { lat: 48.8284582, lng: 18.8138713 },
    { lat: 48.8284738, lng: 18.8137793 },
    { lat: 48.8284487, lng: 18.8137103 },
    { lat: 48.8284636, lng: 18.813671 },
    { lat: 48.8284566, lng: 18.8136446 },
    { lat: 48.8284234, lng: 18.8136375 },
    { lat: 48.8284258, lng: 18.8135756 },
    { lat: 48.8283912, lng: 18.8134513 },
    { lat: 48.828362, lng: 18.8134301 },
    { lat: 48.8283576, lng: 18.8133687 },
    { lat: 48.8283914, lng: 18.8133228 },
    { lat: 48.8283537, lng: 18.8132514 },
    { lat: 48.8283064, lng: 18.8130788 },
    { lat: 48.8282439, lng: 18.8129399 },
    { lat: 48.8281486, lng: 18.8129298 },
    { lat: 48.8281384, lng: 18.8128612 },
    { lat: 48.8281138, lng: 18.8128012 },
    { lat: 48.8281385, lng: 18.812776 },
    { lat: 48.8281199, lng: 18.8127009 },
    { lat: 48.8280834, lng: 18.8126709 },
    { lat: 48.8280561, lng: 18.8124509 },
    { lat: 48.8279659, lng: 18.8123654 },
    { lat: 48.8279221, lng: 18.8123677 },
    { lat: 48.8278731, lng: 18.8122261 },
    { lat: 48.8278038, lng: 18.8120651 },
    { lat: 48.8277817, lng: 18.8119676 },
    { lat: 48.8277986, lng: 18.81188 },
    { lat: 48.8277541, lng: 18.8118467 },
    { lat: 48.8277038, lng: 18.811857 },
    { lat: 48.8276223, lng: 18.8117432 },
    { lat: 48.8276448, lng: 18.8116117 },
    { lat: 48.8276362, lng: 18.8114885 },
    { lat: 48.8276083, lng: 18.8114518 },
    { lat: 48.8276042, lng: 18.8113728 },
    { lat: 48.8275387, lng: 18.8113499 },
    { lat: 48.8274965, lng: 18.8113606 },
    { lat: 48.8274402, lng: 18.8113398 },
    { lat: 48.8273322, lng: 18.8111501 },
    { lat: 48.8273606, lng: 18.8110465 },
    { lat: 48.8273091, lng: 18.8110094 },
    { lat: 48.8272916, lng: 18.8109324 },
    { lat: 48.8272383, lng: 18.8109072 },
    { lat: 48.8272298, lng: 18.8108306 },
    { lat: 48.8271982, lng: 18.8108326 },
    { lat: 48.8271857, lng: 18.810677 },
    { lat: 48.8271488, lng: 18.8106581 },
    { lat: 48.8271066, lng: 18.8106975 },
    { lat: 48.8270752, lng: 18.8106744 },
    { lat: 48.8270654, lng: 18.8105539 },
    { lat: 48.8270303, lng: 18.8104214 },
    { lat: 48.8270087, lng: 18.8103957 },
    { lat: 48.8269747, lng: 18.8103912 },
    { lat: 48.8269234, lng: 18.8103146 },
    { lat: 48.8268866, lng: 18.8103025 },
    { lat: 48.8268627, lng: 18.8102342 },
    { lat: 48.8268182, lng: 18.8102001 },
    { lat: 48.8267736, lng: 18.8101911 },
    { lat: 48.8266951, lng: 18.8100456 },
    { lat: 48.8266682, lng: 18.8100513 },
    { lat: 48.8265926, lng: 18.8099826 },
    { lat: 48.8265378, lng: 18.8099695 },
    { lat: 48.8265195, lng: 18.8099223 },
    { lat: 48.8264745, lng: 18.810035 },
    { lat: 48.8263796, lng: 18.8100081 },
    { lat: 48.8263546, lng: 18.8099413 },
    { lat: 48.8262846, lng: 18.8098487 },
    { lat: 48.8262801, lng: 18.8099577 },
    { lat: 48.8262523, lng: 18.8099699 },
    { lat: 48.8262041, lng: 18.8099503 },
    { lat: 48.8261536, lng: 18.8099563 },
    { lat: 48.8261334, lng: 18.8098736 },
    { lat: 48.8261732, lng: 18.8097704 },
    { lat: 48.8261353, lng: 18.8096468 },
    { lat: 48.8261027, lng: 18.8096274 },
    { lat: 48.8260716, lng: 18.8096383 },
    { lat: 48.8260414, lng: 18.8097478 },
    { lat: 48.8259652, lng: 18.8097154 },
    { lat: 48.8259536, lng: 18.8096234 },
    { lat: 48.8259956, lng: 18.8095855 },
    { lat: 48.8260082, lng: 18.8095467 },
    { lat: 48.8259954, lng: 18.8095143 },
    { lat: 48.8259654, lng: 18.8095014 },
    { lat: 48.8259659, lng: 18.8094469 },
    { lat: 48.8258699, lng: 18.80936 },
    { lat: 48.8257973, lng: 18.8092634 },
    { lat: 48.8257444, lng: 18.8092458 },
    { lat: 48.8257317, lng: 18.8091517 },
    { lat: 48.8256977, lng: 18.809125 },
    { lat: 48.8256799, lng: 18.8091726 },
    { lat: 48.825655, lng: 18.8091713 },
    { lat: 48.8255845, lng: 18.8089879 },
    { lat: 48.8255928, lng: 18.8089067 },
    { lat: 48.8255232, lng: 18.8089355 },
    { lat: 48.8254956, lng: 18.8089284 },
    { lat: 48.8254936, lng: 18.8089755 },
    { lat: 48.8255036, lng: 18.8090366 },
    { lat: 48.8254598, lng: 18.8091023 },
    { lat: 48.8254345, lng: 18.8090749 },
    { lat: 48.825396, lng: 18.8090712 },
    { lat: 48.8253597, lng: 18.8089997 },
    { lat: 48.8253546, lng: 18.8089019 },
    { lat: 48.8254101, lng: 18.8088878 },
    { lat: 48.8254131, lng: 18.8088369 },
    { lat: 48.8253773, lng: 18.8087983 },
    { lat: 48.8253146, lng: 18.8088294 },
    { lat: 48.8252594, lng: 18.8087844 },
    { lat: 48.8252727, lng: 18.8086719 },
    { lat: 48.8252379, lng: 18.8085902 },
    { lat: 48.8252524, lng: 18.8085226 },
    { lat: 48.8252257, lng: 18.8085054 },
    { lat: 48.8252163, lng: 18.8084153 },
    { lat: 48.8252275, lng: 18.8083681 },
    { lat: 48.8251888, lng: 18.8082553 },
    { lat: 48.8251402, lng: 18.8082297 },
    { lat: 48.8250586, lng: 18.8082419 },
    { lat: 48.8250233, lng: 18.8081946 },
    { lat: 48.8250233, lng: 18.8080854 },
    { lat: 48.8250592, lng: 18.8080797 },
    { lat: 48.8250858, lng: 18.8080515 },
    { lat: 48.8250487, lng: 18.8079468 },
    { lat: 48.8250149, lng: 18.8079687 },
    { lat: 48.8249789, lng: 18.807968 },
    { lat: 48.8249249, lng: 18.8081008 },
    { lat: 48.824854, lng: 18.8079282 },
    { lat: 48.8248299, lng: 18.8079661 },
    { lat: 48.8247968, lng: 18.8079794 },
    { lat: 48.8247585, lng: 18.8078734 },
    { lat: 48.8247039, lng: 18.8078867 },
    { lat: 48.8246519, lng: 18.8078421 },
    { lat: 48.8246301, lng: 18.8078547 },
    { lat: 48.824569, lng: 18.8078291 },
    { lat: 48.8245787, lng: 18.8076887 },
    { lat: 48.8245464, lng: 18.80763 },
    { lat: 48.8245592, lng: 18.8074685 },
    { lat: 48.8245382, lng: 18.807432 },
    { lat: 48.8245334, lng: 18.807362 },
    { lat: 48.824478, lng: 18.8073389 },
    { lat: 48.8244619, lng: 18.8072654 },
    { lat: 48.8243459, lng: 18.8072696 },
    { lat: 48.8243408, lng: 18.8071492 },
    { lat: 48.8242786, lng: 18.8070811 },
    { lat: 48.8243069, lng: 18.8069983 },
    { lat: 48.8243712, lng: 18.8070396 },
    { lat: 48.8243811, lng: 18.8070156 },
    { lat: 48.8243431, lng: 18.8069342 },
    { lat: 48.8243018, lng: 18.8069409 },
    { lat: 48.8241993, lng: 18.8068999 },
    { lat: 48.8241378, lng: 18.8068034 },
    { lat: 48.8241656, lng: 18.8067525 },
    { lat: 48.8241583, lng: 18.8067219 },
    { lat: 48.8241401, lng: 18.8067176 },
    { lat: 48.8241265, lng: 18.8067822 },
    { lat: 48.824094, lng: 18.8068244 },
    { lat: 48.8240677, lng: 18.8067961 },
    { lat: 48.8240573, lng: 18.806774 },
    { lat: 48.8240325, lng: 18.806792 },
    { lat: 48.823987, lng: 18.8068948 },
    { lat: 48.8238654, lng: 18.8068997 },
    { lat: 48.8238243, lng: 18.8068455 },
    { lat: 48.8237928, lng: 18.806913 },
    { lat: 48.823715, lng: 18.8068661 },
    { lat: 48.823653, lng: 18.8068463 },
    { lat: 48.8236183, lng: 18.8068741 },
    { lat: 48.8235744, lng: 18.8068292 },
    { lat: 48.8235479, lng: 18.8068657 },
    { lat: 48.8235147, lng: 18.8068127 },
    { lat: 48.8235307, lng: 18.8066623 },
    { lat: 48.8235112, lng: 18.8065294 },
    { lat: 48.8234874, lng: 18.806461 },
    { lat: 48.8234515, lng: 18.8064628 },
    { lat: 48.8234572, lng: 18.806358 },
    { lat: 48.8234365, lng: 18.8063293 },
    { lat: 48.8234188, lng: 18.8063325 },
    { lat: 48.8234014, lng: 18.8063034 },
    { lat: 48.8233548, lng: 18.8062979 },
    { lat: 48.8233342, lng: 18.806139 },
    { lat: 48.8232716, lng: 18.8061472 },
    { lat: 48.8232472, lng: 18.806114 },
    { lat: 48.8231757, lng: 18.8061941 },
    { lat: 48.823101, lng: 18.806121 },
    { lat: 48.8230718, lng: 18.8060583 },
    { lat: 48.8230426, lng: 18.8060389 },
    { lat: 48.8230116, lng: 18.8060512 },
    { lat: 48.8229921, lng: 18.8060886 },
    { lat: 48.8229526, lng: 18.806089 },
    { lat: 48.822918, lng: 18.8060277 },
    { lat: 48.8228685, lng: 18.8059896 },
    { lat: 48.8228403, lng: 18.8059246 },
    { lat: 48.8228789, lng: 18.805862 },
    { lat: 48.8228691, lng: 18.8057823 },
    { lat: 48.8228717, lng: 18.8057251 },
    { lat: 48.8227364, lng: 18.8055305 },
    { lat: 48.8226891, lng: 18.8055075 },
    { lat: 48.822685, lng: 18.8054515 },
    { lat: 48.8226194, lng: 18.8053859 },
    { lat: 48.8226636, lng: 18.8053048 },
    { lat: 48.8226445, lng: 18.8051736 },
    { lat: 48.8225924, lng: 18.8051254 },
    { lat: 48.8225655, lng: 18.8052178 },
    { lat: 48.8225309, lng: 18.805224 },
    { lat: 48.8225009, lng: 18.805165 },
    { lat: 48.8224392, lng: 18.8051735 },
    { lat: 48.8223774, lng: 18.8050896 },
    { lat: 48.8223675, lng: 18.8050317 },
    { lat: 48.8223261, lng: 18.8049726 },
    { lat: 48.822315, lng: 18.8048215 },
    { lat: 48.8222825, lng: 18.8048687 },
    { lat: 48.8221481, lng: 18.8047563 },
    { lat: 48.8221113, lng: 18.8047456 },
    { lat: 48.82209, lng: 18.8046813 },
    { lat: 48.8220841, lng: 18.8045682 },
    { lat: 48.8220661, lng: 18.8045256 },
    { lat: 48.8220237, lng: 18.804555 },
    { lat: 48.8219437, lng: 18.8045356 },
    { lat: 48.8219015, lng: 18.8045689 },
    { lat: 48.8218253, lng: 18.8045122 },
    { lat: 48.8218069, lng: 18.8044396 },
    { lat: 48.8217328, lng: 18.8043597 },
    { lat: 48.8216809, lng: 18.8043838 },
    { lat: 48.8216212, lng: 18.8043855 },
    { lat: 48.8215635, lng: 18.8043173 },
    { lat: 48.8214956, lng: 18.8043104 },
    { lat: 48.8214894, lng: 18.8042778 },
    { lat: 48.8214046, lng: 18.8042522 },
    { lat: 48.8213699, lng: 18.8041715 },
    { lat: 48.8213905, lng: 18.8041136 },
    { lat: 48.821378, lng: 18.8040907 },
    { lat: 48.8213508, lng: 18.8041316 },
    { lat: 48.8212338, lng: 18.8041355 },
    { lat: 48.8211872, lng: 18.8042152 },
    { lat: 48.8210589, lng: 18.8041976 },
    { lat: 48.8210154, lng: 18.804143 },
    { lat: 48.8209664, lng: 18.804131 },
    { lat: 48.8209526, lng: 18.804103 },
    { lat: 48.8209544, lng: 18.8040333 },
    { lat: 48.8209979, lng: 18.8039787 },
    { lat: 48.8209681, lng: 18.8039465 },
    { lat: 48.8209175, lng: 18.8039508 },
    { lat: 48.8208772, lng: 18.8038722 },
    { lat: 48.8207873, lng: 18.8038339 },
    { lat: 48.8207744, lng: 18.803736 },
    { lat: 48.8207946, lng: 18.8035829 },
    { lat: 48.820753, lng: 18.8035417 },
    { lat: 48.8207641, lng: 18.8034735 },
    { lat: 48.8207929, lng: 18.803465 },
    { lat: 48.8207787, lng: 18.8034091 },
    { lat: 48.820755, lng: 18.8033858 },
    { lat: 48.8207479, lng: 18.8033355 },
    { lat: 48.8207715, lng: 18.803289 },
    { lat: 48.8207629, lng: 18.8031924 },
    { lat: 48.8207391, lng: 18.8031677 },
    { lat: 48.8207223, lng: 18.803062 },
    { lat: 48.8207468, lng: 18.8030326 },
    { lat: 48.820744, lng: 18.8029803 },
    { lat: 48.8206459, lng: 18.8029791 },
    { lat: 48.8206182, lng: 18.8029227 },
    { lat: 48.8205364, lng: 18.8029146 },
    { lat: 48.8205403, lng: 18.8027706 },
    { lat: 48.8205346, lng: 18.8026607 },
    { lat: 48.8204296, lng: 18.8026607 },
    { lat: 48.8203687, lng: 18.8025528 },
    { lat: 48.8203103, lng: 18.8023619 },
    { lat: 48.8202451, lng: 18.8023443 },
    { lat: 48.8202039, lng: 18.8022884 },
    { lat: 48.8200632, lng: 18.8022275 },
    { lat: 48.8199989, lng: 18.8021633 },
    { lat: 48.8199954, lng: 18.8020954 },
    { lat: 48.8198939, lng: 18.8019655 },
    { lat: 48.8198206, lng: 18.8017713 },
    { lat: 48.8198602, lng: 18.8017093 },
    { lat: 48.8198381, lng: 18.8016522 },
    { lat: 48.8198586, lng: 18.8015696 },
    { lat: 48.8198462, lng: 18.8014827 },
    { lat: 48.8198545, lng: 18.8013833 },
    { lat: 48.8198195, lng: 18.801297 },
    { lat: 48.8198319, lng: 18.8012297 },
    { lat: 48.8198188, lng: 18.8010874 },
    { lat: 48.819881, lng: 18.80094 },
    { lat: 48.8198702, lng: 18.8008866 },
    { lat: 48.8199278, lng: 18.8005816 },
    { lat: 48.8198958, lng: 18.8005525 },
    { lat: 48.8198882, lng: 18.8005155 },
    { lat: 48.8199141, lng: 18.8004501 },
    { lat: 48.8199217, lng: 18.8003615 },
    { lat: 48.819908, lng: 18.8002469 },
    { lat: 48.8198576, lng: 18.8001244 },
    { lat: 48.8198686, lng: 18.8000083 },
    { lat: 48.8198197, lng: 18.7997862 },
    { lat: 48.8198178, lng: 18.7997284 },
    { lat: 48.8197832, lng: 18.7995851 },
    { lat: 48.8197256, lng: 18.7994514 },
    { lat: 48.8198494, lng: 18.7992101 },
    { lat: 48.8199405, lng: 18.7991401 },
    { lat: 48.8199706, lng: 18.7989197 },
    { lat: 48.820014, lng: 18.7988011 },
    { lat: 48.8200337, lng: 18.7987028 },
    { lat: 48.8200669, lng: 18.7984734 },
    { lat: 48.8200826, lng: 18.7983223 },
    { lat: 48.8200411, lng: 18.7981913 },
    { lat: 48.8199447, lng: 18.7980103 },
    { lat: 48.820147, lng: 18.7975223 },
    { lat: 48.8202815, lng: 18.7973098 },
    { lat: 48.8202669, lng: 18.7971841 },
    { lat: 48.8203086, lng: 18.7969022 },
    { lat: 48.8204407, lng: 18.7965393 },
    { lat: 48.8206537, lng: 18.7961878 },
    { lat: 48.8206994, lng: 18.7961587 },
    { lat: 48.8206747, lng: 18.7959877 },
    { lat: 48.8207132, lng: 18.7958621 },
    { lat: 48.820798, lng: 18.7956928 },
    { lat: 48.8208436, lng: 18.7955703 },
    { lat: 48.8209715, lng: 18.7953857 },
    { lat: 48.821036, lng: 18.7952413 },
    { lat: 48.8211292, lng: 18.7951256 },
    { lat: 48.821218, lng: 18.794949 },
    { lat: 48.8213705, lng: 18.7946276 },
    { lat: 48.8214848, lng: 18.7944636 },
    { lat: 48.8215556, lng: 18.7944358 },
    { lat: 48.8216022, lng: 18.7943372 },
    { lat: 48.8216416, lng: 18.7943769 },
    { lat: 48.8216558, lng: 18.7935249 },
    { lat: 48.8216335, lng: 18.7932073 },
    { lat: 48.821564, lng: 18.793021 },
    { lat: 48.8215671, lng: 18.7925597 },
    { lat: 48.8216496, lng: 18.7921762 },
    { lat: 48.8216937, lng: 18.791875 },
    { lat: 48.8217726, lng: 18.7916175 },
    { lat: 48.8219628, lng: 18.7912641 },
    { lat: 48.8221492, lng: 18.7908606 },
    { lat: 48.8223009, lng: 18.7906338 },
    { lat: 48.8224404, lng: 18.7905183 },
    { lat: 48.8226539, lng: 18.7902902 },
    { lat: 48.8228341, lng: 18.7899401 },
    { lat: 48.8229602, lng: 18.7897843 },
    { lat: 48.8228479, lng: 18.789363 },
    { lat: 48.822671, lng: 18.7887583 },
    { lat: 48.8225979, lng: 18.7882682 },
    { lat: 48.8223854, lng: 18.7877386 },
    { lat: 48.8219075, lng: 18.7872706 },
    { lat: 48.8211711, lng: 18.7866005 },
    { lat: 48.8207187, lng: 18.7861871 },
    { lat: 48.8205356, lng: 18.786029 },
    { lat: 48.8202169, lng: 18.7857774 },
    { lat: 48.8198126, lng: 18.7853345 },
    { lat: 48.819409, lng: 18.7848594 },
    { lat: 48.8190557, lng: 18.7845514 },
    { lat: 48.8185952, lng: 18.7844395 },
    { lat: 48.8180498, lng: 18.7840555 },
    { lat: 48.8172499, lng: 18.7832959 },
    { lat: 48.8165601, lng: 18.7827173 },
    { lat: 48.8163166, lng: 18.782441 },
    { lat: 48.8155854, lng: 18.7818703 },
    { lat: 48.814721, lng: 18.7812372 },
    { lat: 48.8133766, lng: 18.7804543 },
    { lat: 48.8116894, lng: 18.7794392 },
    { lat: 48.8121053, lng: 18.779068 },
    { lat: 48.812747, lng: 18.777958 },
    { lat: 48.812717, lng: 18.777728 },
    { lat: 48.812717, lng: 18.777723 },
    { lat: 48.812606, lng: 18.776768 },
    { lat: 48.812447, lng: 18.775762 },
    { lat: 48.812205, lng: 18.7748 },
    { lat: 48.811893, lng: 18.7742 },
    { lat: 48.811712, lng: 18.773318 },
    { lat: 48.811466, lng: 18.773025 },
    { lat: 48.811055, lng: 18.772208 },
    { lat: 48.810503, lng: 18.772159 },
    { lat: 48.810218, lng: 18.77131 },
    { lat: 48.810052, lng: 18.77102 },
    { lat: 48.809938, lng: 18.770794 },
    { lat: 48.809602, lng: 18.770134 },
    { lat: 48.808794, lng: 18.769742 },
    { lat: 48.808584, lng: 18.769483 },
    { lat: 48.807434, lng: 18.769798 },
    { lat: 48.807105, lng: 18.769658 },
    { lat: 48.806873, lng: 18.769596 },
    { lat: 48.806738, lng: 18.76966 },
    { lat: 48.806676, lng: 18.769691 },
    { lat: 48.806647, lng: 18.769704 },
    { lat: 48.806639, lng: 18.769677 },
  ],
  SlovenskéPravno: [
    { lat: 48.8921199, lng: 18.7580212 },
    { lat: 48.8922426, lng: 18.758062 },
    { lat: 48.8925802, lng: 18.759359 },
    { lat: 48.8933764, lng: 18.761962 },
    { lat: 48.8933943, lng: 18.7620243 },
    { lat: 48.8929957, lng: 18.762425 },
    { lat: 48.8927721, lng: 18.7625695 },
    { lat: 48.8927777, lng: 18.7626534 },
    { lat: 48.8927785, lng: 18.7639409 },
    { lat: 48.8929073, lng: 18.7660749 },
    { lat: 48.8929731, lng: 18.7670504 },
    { lat: 48.8931378, lng: 18.769957 },
    { lat: 48.8931444, lng: 18.7700842 },
    { lat: 48.8928478, lng: 18.7703858 },
    { lat: 48.8926765, lng: 18.7706695 },
    { lat: 48.8927509, lng: 18.7721771 },
    { lat: 48.8929062, lng: 18.775039 },
    { lat: 48.893163, lng: 18.7749479 },
    { lat: 48.8933344, lng: 18.7784425 },
    { lat: 48.8946938, lng: 18.7798983 },
    { lat: 48.8950144, lng: 18.7803131 },
    { lat: 48.8954697, lng: 18.7810217 },
    { lat: 48.8967708, lng: 18.7835648 },
    { lat: 48.8969497, lng: 18.7839687 },
    { lat: 48.8970537, lng: 18.7842762 },
    { lat: 48.8971493, lng: 18.7846364 },
    { lat: 48.8972486, lng: 18.7851123 },
    { lat: 48.8974014, lng: 18.7864196 },
    { lat: 48.8974221, lng: 18.7865805 },
    { lat: 48.8975318, lng: 18.7869759 },
    { lat: 48.8977029, lng: 18.7874016 },
    { lat: 48.8978825, lng: 18.7876872 },
    { lat: 48.8981115, lng: 18.7879912 },
    { lat: 48.9006438, lng: 18.790666 },
    { lat: 48.9032221, lng: 18.7934237 },
    { lat: 48.9032598, lng: 18.7934571 },
    { lat: 48.9032871, lng: 18.7935016 },
    { lat: 48.9033865, lng: 18.7935273 },
    { lat: 48.9037082, lng: 18.7936943 },
    { lat: 48.9038152, lng: 18.7937161 },
    { lat: 48.9040309, lng: 18.7938375 },
    { lat: 48.9040931, lng: 18.7938626 },
    { lat: 48.9041811, lng: 18.7938704 },
    { lat: 48.904241, lng: 18.7938923 },
    { lat: 48.9042937, lng: 18.7939597 },
    { lat: 48.9043413, lng: 18.7939842 },
    { lat: 48.9045584, lng: 18.7940732 },
    { lat: 48.9046826, lng: 18.7941514 },
    { lat: 48.9047596, lng: 18.7942482 },
    { lat: 48.9048677, lng: 18.7944433 },
    { lat: 48.9049577, lng: 18.7946814 },
    { lat: 48.9050025, lng: 18.7947578 },
    { lat: 48.9050258, lng: 18.7948232 },
    { lat: 48.9050374, lng: 18.7948995 },
    { lat: 48.9050297, lng: 18.7949787 },
    { lat: 48.9050113, lng: 18.7950281 },
    { lat: 48.9049798, lng: 18.7950686 },
    { lat: 48.9049259, lng: 18.7950917 },
    { lat: 48.9047289, lng: 18.7951312 },
    { lat: 48.9046581, lng: 18.7951454 },
    { lat: 48.9046011, lng: 18.7951511 },
    { lat: 48.9045685, lng: 18.7951598 },
    { lat: 48.9044954, lng: 18.7952065 },
    { lat: 48.9044269, lng: 18.7952798 },
    { lat: 48.9043724, lng: 18.7953218 },
    { lat: 48.9043421, lng: 18.7953698 },
    { lat: 48.9042649, lng: 18.7956006 },
    { lat: 48.9042539, lng: 18.7958021 },
    { lat: 48.9042654, lng: 18.7960475 },
    { lat: 48.9043224, lng: 18.7962329 },
    { lat: 48.904332, lng: 18.7963175 },
    { lat: 48.9043204, lng: 18.7964173 },
    { lat: 48.9042783, lng: 18.7964921 },
    { lat: 48.9041965, lng: 18.7965498 },
    { lat: 48.9041555, lng: 18.7965841 },
    { lat: 48.9041427, lng: 18.7966343 },
    { lat: 48.9041423, lng: 18.7967051 },
    { lat: 48.9041695, lng: 18.7968105 },
    { lat: 48.9042344, lng: 18.7969097 },
    { lat: 48.9042903, lng: 18.7969545 },
    { lat: 48.9043383, lng: 18.796973 },
    { lat: 48.9043849, lng: 18.796985 },
    { lat: 48.9044366, lng: 18.7969701 },
    { lat: 48.9044795, lng: 18.796954 },
    { lat: 48.9050004, lng: 18.7967616 },
    { lat: 48.9050348, lng: 18.7967411 },
    { lat: 48.9050665, lng: 18.796741 },
    { lat: 48.9050929, lng: 18.796753 },
    { lat: 48.9051274, lng: 18.7968149 },
    { lat: 48.9051742, lng: 18.7971389 },
    { lat: 48.9051996, lng: 18.7971919 },
    { lat: 48.9052432, lng: 18.7972235 },
    { lat: 48.9052949, lng: 18.7972432 },
    { lat: 48.9056554, lng: 18.7972763 },
    { lat: 48.9057035, lng: 18.7973023 },
    { lat: 48.9057439, lng: 18.7973454 },
    { lat: 48.9057599, lng: 18.7974169 },
    { lat: 48.9057735, lng: 18.7975434 },
    { lat: 48.9057671, lng: 18.7976509 },
    { lat: 48.905748, lng: 18.7978051 },
    { lat: 48.9057513, lng: 18.7978511 },
    { lat: 48.9057629, lng: 18.7979026 },
    { lat: 48.9060229, lng: 18.798522 },
    { lat: 48.9060404, lng: 18.7985756 },
    { lat: 48.9060437, lng: 18.7986336 },
    { lat: 48.906035, lng: 18.7986889 },
    { lat: 48.9060168, lng: 18.7987362 },
    { lat: 48.9059713, lng: 18.7988274 },
    { lat: 48.9059928, lng: 18.7988779 },
    { lat: 48.9060015, lng: 18.7988496 },
    { lat: 48.9061289, lng: 18.7989135 },
    { lat: 48.9063033, lng: 18.7988192 },
    { lat: 48.9066209, lng: 18.7981212 },
    { lat: 48.9066451, lng: 18.7976953 },
    { lat: 48.9067799, lng: 18.7975988 },
    { lat: 48.9069025, lng: 18.7976616 },
    { lat: 48.9070048, lng: 18.7980006 },
    { lat: 48.9069687, lng: 18.7983458 },
    { lat: 48.9071629, lng: 18.7985233 },
    { lat: 48.9073685, lng: 18.7987908 },
    { lat: 48.9075613, lng: 18.7991817 },
    { lat: 48.9077609, lng: 18.7992664 },
    { lat: 48.9079914, lng: 18.7992954 },
    { lat: 48.9081039, lng: 18.7997443 },
    { lat: 48.9082514, lng: 18.7998948 },
    { lat: 48.9083669, lng: 18.7998584 },
    { lat: 48.9085783, lng: 18.7991767 },
    { lat: 48.9086595, lng: 18.7987655 },
    { lat: 48.9087753, lng: 18.7986942 },
    { lat: 48.9089639, lng: 18.7987634 },
    { lat: 48.9093407, lng: 18.7987247 },
    { lat: 48.9094564, lng: 18.7987825 },
    { lat: 48.9096958, lng: 18.7989821 },
    { lat: 48.9098506, lng: 18.7990106 },
    { lat: 48.9098981, lng: 18.7991181 },
    { lat: 48.9100619, lng: 18.799343 },
    { lat: 48.9096772, lng: 18.8015697 },
    { lat: 48.9097657, lng: 18.8016685 },
    { lat: 48.9098182, lng: 18.8016776 },
    { lat: 48.9100138, lng: 18.8014495 },
    { lat: 48.9102763, lng: 18.8012721 },
    { lat: 48.9105226, lng: 18.8014552 },
    { lat: 48.9107356, lng: 18.8016483 },
    { lat: 48.9110435, lng: 18.801648 },
    { lat: 48.911191, lng: 18.8020802 },
    { lat: 48.911315, lng: 18.8027695 },
    { lat: 48.9115966, lng: 18.8028103 },
    { lat: 48.9117216, lng: 18.8031556 },
    { lat: 48.9116999, lng: 18.8036687 },
    { lat: 48.9115952, lng: 18.8041265 },
    { lat: 48.9113396, lng: 18.8046744 },
    { lat: 48.9109888, lng: 18.8050204 },
    { lat: 48.9103, lng: 18.8050441 },
    { lat: 48.9099357, lng: 18.8054354 },
    { lat: 48.9095297, lng: 18.8055625 },
    { lat: 48.9094057, lng: 18.805671 },
    { lat: 48.9089954, lng: 18.8057832 },
    { lat: 48.9083529, lng: 18.8064189 },
    { lat: 48.9083312, lng: 18.8065464 },
    { lat: 48.9083227, lng: 18.8065964 },
    { lat: 48.9083407, lng: 18.8067029 },
    { lat: 48.9084187, lng: 18.8070534 },
    { lat: 48.9085858, lng: 18.8081214 },
    { lat: 48.9088287, lng: 18.8083034 },
    { lat: 48.9088514, lng: 18.808286 },
    { lat: 48.9089098, lng: 18.8081733 },
    { lat: 48.9089826, lng: 18.8080728 },
    { lat: 48.9095923, lng: 18.8078282 },
    { lat: 48.9096406, lng: 18.8078493 },
    { lat: 48.909743, lng: 18.8080177 },
    { lat: 48.9095308, lng: 18.808555 },
    { lat: 48.9096811, lng: 18.8089091 },
    { lat: 48.9098148, lng: 18.8096351 },
    { lat: 48.9099107, lng: 18.8096944 },
    { lat: 48.9100757, lng: 18.8097964 },
    { lat: 48.9104732, lng: 18.8098923 },
    { lat: 48.9110568, lng: 18.8100594 },
    { lat: 48.9111307, lng: 18.8100495 },
    { lat: 48.9113368, lng: 18.8098678 },
    { lat: 48.9114603, lng: 18.8094448 },
    { lat: 48.9114812, lng: 18.8093732 },
    { lat: 48.9115153, lng: 18.8092015 },
    { lat: 48.911627, lng: 18.8089017 },
    { lat: 48.9118537, lng: 18.8088696 },
    { lat: 48.9119695, lng: 18.8088532 },
    { lat: 48.9120845, lng: 18.8087908 },
    { lat: 48.912191, lng: 18.8087331 },
    { lat: 48.9121637, lng: 18.808379 },
    { lat: 48.9123394, lng: 18.8081823 },
    { lat: 48.9125845, lng: 18.8080389 },
    { lat: 48.9128896, lng: 18.808025 },
    { lat: 48.9130045, lng: 18.8080901 },
    { lat: 48.9130677, lng: 18.8082009 },
    { lat: 48.9131688, lng: 18.8086267 },
    { lat: 48.9131869, lng: 18.8086918 },
    { lat: 48.9131977, lng: 18.8087482 },
    { lat: 48.9134253, lng: 18.8088755 },
    { lat: 48.9135594, lng: 18.80922 },
    { lat: 48.9135799, lng: 18.8094417 },
    { lat: 48.9136384, lng: 18.8096118 },
    { lat: 48.9137214, lng: 18.8096438 },
    { lat: 48.913779, lng: 18.8096261 },
    { lat: 48.9138411, lng: 18.809561 },
    { lat: 48.913882, lng: 18.8094378 },
    { lat: 48.9140067, lng: 18.8089321 },
    { lat: 48.913985, lng: 18.8086884 },
    { lat: 48.9138158, lng: 18.8084702 },
    { lat: 48.9137601, lng: 18.8081571 },
    { lat: 48.9137491, lng: 18.8080238 },
    { lat: 48.913739, lng: 18.8079011 },
    { lat: 48.9137761, lng: 18.8076422 },
    { lat: 48.9139264, lng: 18.8075372 },
    { lat: 48.9139416, lng: 18.8075266 },
    { lat: 48.9141117, lng: 18.8075246 },
    { lat: 48.9142964, lng: 18.8076283 },
    { lat: 48.9144316, lng: 18.8078423 },
    { lat: 48.914523, lng: 18.8081687 },
    { lat: 48.9145713, lng: 18.8082748 },
    { lat: 48.9148254, lng: 18.8085182 },
    { lat: 48.915705, lng: 18.8084341 },
    { lat: 48.9158334, lng: 18.8085184 },
    { lat: 48.9158694, lng: 18.8085693 },
    { lat: 48.9159001, lng: 18.8086101 },
    { lat: 48.9159952, lng: 18.8087669 },
    { lat: 48.9160039, lng: 18.8089795 },
    { lat: 48.9160703, lng: 18.8090386 },
    { lat: 48.9161393, lng: 18.8091748 },
    { lat: 48.916365, lng: 18.8092841 },
    { lat: 48.9163773, lng: 18.8091991 },
    { lat: 48.9165052, lng: 18.8093372 },
    { lat: 48.9166083, lng: 18.8092614 },
    { lat: 48.9166047, lng: 18.8088001 },
    { lat: 48.9165888, lng: 18.8061083 },
    { lat: 48.9165556, lng: 18.8023274 },
    { lat: 48.916551, lng: 18.8021293 },
    { lat: 48.9171571, lng: 18.8021839 },
    { lat: 48.9183716, lng: 18.802032 },
    { lat: 48.9190258, lng: 18.8018851 },
    { lat: 48.9191989, lng: 18.8018751 },
    { lat: 48.919368, lng: 18.801919 },
    { lat: 48.9206037, lng: 18.8024978 },
    { lat: 48.9205764, lng: 18.8022762 },
    { lat: 48.9205742, lng: 18.8020162 },
    { lat: 48.9206011, lng: 18.8017989 },
    { lat: 48.9206315, lng: 18.8016468 },
    { lat: 48.9207579, lng: 18.8011057 },
    { lat: 48.9208398, lng: 18.8006003 },
    { lat: 48.9208643, lng: 18.8002668 },
    { lat: 48.9208374, lng: 18.7997497 },
    { lat: 48.9205842, lng: 18.7982899 },
    { lat: 48.9204042, lng: 18.7970849 },
    { lat: 48.9204058, lng: 18.7966806 },
    { lat: 48.9204435, lng: 18.7963462 },
    { lat: 48.9205027, lng: 18.7960949 },
    { lat: 48.9205984, lng: 18.7958314 },
    { lat: 48.9208445, lng: 18.7953616 },
    { lat: 48.9210638, lng: 18.7950265 },
    { lat: 48.921263, lng: 18.7946057 },
    { lat: 48.9219949, lng: 18.7914503 },
    { lat: 48.9220116, lng: 18.7913587 },
    { lat: 48.9224911, lng: 18.7881198 },
    { lat: 48.9226253, lng: 18.7873622 },
    { lat: 48.922704, lng: 18.7870996 },
    { lat: 48.9228758, lng: 18.786805 },
    { lat: 48.9230307, lng: 18.7866401 },
    { lat: 48.9238103, lng: 18.7860211 },
    { lat: 48.9242938, lng: 18.7856679 },
    { lat: 48.9246699, lng: 18.785353 },
    { lat: 48.9248543, lng: 18.7851523 },
    { lat: 48.9250733, lng: 18.7847892 },
    { lat: 48.9255199, lng: 18.7837057 },
    { lat: 48.9255432, lng: 18.7835731 },
    { lat: 48.9255354, lng: 18.7834241 },
    { lat: 48.9255152, lng: 18.7832092 },
    { lat: 48.9254856, lng: 18.7830937 },
    { lat: 48.9254832, lng: 18.7830714 },
    { lat: 48.9253275, lng: 18.7821833 },
    { lat: 48.9253188, lng: 18.7819494 },
    { lat: 48.9253394, lng: 18.7817196 },
    { lat: 48.9253992, lng: 18.7814617 },
    { lat: 48.9257374, lng: 18.7806383 },
    { lat: 48.9257794, lng: 18.7804471 },
    { lat: 48.9259143, lng: 18.7796861 },
    { lat: 48.9259725, lng: 18.7795259 },
    { lat: 48.9261002, lng: 18.7792291 },
    { lat: 48.926275, lng: 18.7781691 },
    { lat: 48.9263687, lng: 18.7778272 },
    { lat: 48.9268342, lng: 18.7776507 },
    { lat: 48.9271147, lng: 18.7773441 },
    { lat: 48.9274835, lng: 18.776803 },
    { lat: 48.9276847, lng: 18.7762531 },
    { lat: 48.9279392, lng: 18.7759018 },
    { lat: 48.9287737, lng: 18.7738286 },
    { lat: 48.9289099, lng: 18.7731782 },
    { lat: 48.9292145, lng: 18.7726234 },
    { lat: 48.9292713, lng: 18.77252 },
    { lat: 48.9295157, lng: 18.7722728 },
    { lat: 48.9297706, lng: 18.7718014 },
    { lat: 48.9300054, lng: 18.7715903 },
    { lat: 48.9306149, lng: 18.7710423 },
    { lat: 48.9312298, lng: 18.7698593 },
    { lat: 48.9319889, lng: 18.7685915 },
    { lat: 48.9321923, lng: 18.7677374 },
    { lat: 48.9322339, lng: 18.7664423 },
    { lat: 48.9321209, lng: 18.7656866 },
    { lat: 48.9315737, lng: 18.7643424 },
    { lat: 48.9313162, lng: 18.763947 },
    { lat: 48.9310445, lng: 18.7630233 },
    { lat: 48.9309645, lng: 18.7623153 },
    { lat: 48.9308358, lng: 18.7615597 },
    { lat: 48.9306309, lng: 18.7609192 },
    { lat: 48.9305708, lng: 18.7591447 },
    { lat: 48.9306669, lng: 18.7587404 },
    { lat: 48.9313331, lng: 18.7581109 },
    { lat: 48.931741, lng: 18.7574182 },
    { lat: 48.931879, lng: 18.7567581 },
    { lat: 48.9318798, lng: 18.7559582 },
    { lat: 48.9316328, lng: 18.7549648 },
    { lat: 48.9316123, lng: 18.7544487 },
    { lat: 48.931941, lng: 18.7536425 },
    { lat: 48.9319291, lng: 18.7534148 },
    { lat: 48.9321592, lng: 18.7527242 },
    { lat: 48.9326053, lng: 18.7522869 },
    { lat: 48.9329759, lng: 18.7516793 },
    { lat: 48.9331978, lng: 18.7510708 },
    { lat: 48.9337502, lng: 18.7497777 },
    { lat: 48.9341258, lng: 18.7490282 },
    { lat: 48.9343369, lng: 18.7484306 },
    { lat: 48.9343478, lng: 18.7479078 },
    { lat: 48.9344073, lng: 18.7474526 },
    { lat: 48.9345829, lng: 18.7469303 },
    { lat: 48.9348319, lng: 18.7464306 },
    { lat: 48.9344294, lng: 18.7439223 },
    { lat: 48.9345273, lng: 18.7430371 },
    { lat: 48.9346368, lng: 18.7427832 },
    { lat: 48.9347849, lng: 18.742228 },
    { lat: 48.9350615, lng: 18.7408638 },
    { lat: 48.9350906, lng: 18.7408764 },
    { lat: 48.9357127, lng: 18.7412175 },
    { lat: 48.9362551, lng: 18.7417716 },
    { lat: 48.9363511, lng: 18.7411108 },
    { lat: 48.9367052, lng: 18.740164 },
    { lat: 48.9370927, lng: 18.7397899 },
    { lat: 48.9364359, lng: 18.7386026 },
    { lat: 48.9357464, lng: 18.737609 },
    { lat: 48.9348288, lng: 18.7368001 },
    { lat: 48.9337917, lng: 18.7373461 },
    { lat: 48.9337601, lng: 18.7364848 },
    { lat: 48.9336641, lng: 18.7337999 },
    { lat: 48.9329158, lng: 18.7311343 },
    { lat: 48.9324806, lng: 18.7300863 },
    { lat: 48.9322674, lng: 18.7307685 },
    { lat: 48.9315834, lng: 18.7329665 },
    { lat: 48.9318214, lng: 18.7338239 },
    { lat: 48.9318561, lng: 18.735212 },
    { lat: 48.931036, lng: 18.7360635 },
    { lat: 48.9302702, lng: 18.7379707 },
    { lat: 48.9304451, lng: 18.7383612 },
    { lat: 48.9295011, lng: 18.7391946 },
    { lat: 48.9290504, lng: 18.7388146 },
    { lat: 48.9287196, lng: 18.7387438 },
    { lat: 48.9280971, lng: 18.7396415 },
    { lat: 48.9275759, lng: 18.7403911 },
    { lat: 48.9275501, lng: 18.7404569 },
    { lat: 48.9274571, lng: 18.7405991 },
    { lat: 48.9264834, lng: 18.7420037 },
    { lat: 48.9255789, lng: 18.742734 },
    { lat: 48.9249162, lng: 18.7432352 },
    { lat: 48.9231557, lng: 18.744198 },
    { lat: 48.9227424, lng: 18.7437582 },
    { lat: 48.9221201, lng: 18.7432651 },
    { lat: 48.9218537, lng: 18.7433902 },
    { lat: 48.9213582, lng: 18.7432114 },
    { lat: 48.9212249, lng: 18.743571 },
    { lat: 48.920596, lng: 18.7445002 },
    { lat: 48.9176143, lng: 18.7444381 },
    { lat: 48.9175722, lng: 18.7455024 },
    { lat: 48.9137131, lng: 18.7454542 },
    { lat: 48.9134868, lng: 18.7453113 },
    { lat: 48.9133959, lng: 18.7452147 },
    { lat: 48.9131126, lng: 18.7449729 },
    { lat: 48.9109094, lng: 18.7433927 },
    { lat: 48.9101701, lng: 18.7427325 },
    { lat: 48.9097333, lng: 18.7422132 },
    { lat: 48.909652, lng: 18.7410543 },
    { lat: 48.9095674, lng: 18.7402357 },
    { lat: 48.9090715, lng: 18.7368785 },
    { lat: 48.9087409, lng: 18.7351802 },
    { lat: 48.9068077, lng: 18.7330086 },
    { lat: 48.9066339, lng: 18.7328364 },
    { lat: 48.9035578, lng: 18.7304662 },
    { lat: 48.9041916, lng: 18.7285375 },
    { lat: 48.9052954, lng: 18.7263934 },
    { lat: 48.9070158, lng: 18.7230673 },
    { lat: 48.9059524, lng: 18.721424 },
    { lat: 48.901804, lng: 18.7229086 },
    { lat: 48.9017043, lng: 18.7232849 },
    { lat: 48.9010265, lng: 18.7242895 },
    { lat: 48.9006295, lng: 18.7245885 },
    { lat: 48.9002807, lng: 18.7249886 },
    { lat: 48.9000734, lng: 18.7253324 },
    { lat: 48.8995895, lng: 18.726123 },
    { lat: 48.8994223, lng: 18.7272096 },
    { lat: 48.8990607, lng: 18.7279886 },
    { lat: 48.8986697, lng: 18.7282733 },
    { lat: 48.8988622, lng: 18.7303393 },
    { lat: 48.8989623, lng: 18.7314268 },
    { lat: 48.8989357, lng: 18.7325512 },
    { lat: 48.8990925, lng: 18.7350985 },
    { lat: 48.8989227, lng: 18.7366324 },
    { lat: 48.8987064, lng: 18.7368507 },
    { lat: 48.8982784, lng: 18.7373064 },
    { lat: 48.8978634, lng: 18.7373115 },
    { lat: 48.8974224, lng: 18.736856 },
    { lat: 48.8964688, lng: 18.7357324 },
    { lat: 48.8949988, lng: 18.7389324 },
    { lat: 48.8945817, lng: 18.74001 },
    { lat: 48.8944902, lng: 18.7406691 },
    { lat: 48.8945685, lng: 18.7420495 },
    { lat: 48.894808, lng: 18.7434093 },
    { lat: 48.8950209, lng: 18.7445867 },
    { lat: 48.8951362, lng: 18.7457927 },
    { lat: 48.8960666, lng: 18.7451731 },
    { lat: 48.8965152, lng: 18.7466051 },
    { lat: 48.8968634, lng: 18.7489812 },
    { lat: 48.8972161, lng: 18.7513833 },
    { lat: 48.8959537, lng: 18.7534801 },
    { lat: 48.8959614, lng: 18.7535412 },
    { lat: 48.8959347, lng: 18.7535677 },
    { lat: 48.8959002, lng: 18.7536851 },
    { lat: 48.8954423, lng: 18.7534028 },
    { lat: 48.8954133, lng: 18.7533905 },
    { lat: 48.8953828, lng: 18.7533681 },
    { lat: 48.8944483, lng: 18.7555455 },
    { lat: 48.8941923, lng: 18.7559528 },
    { lat: 48.893968, lng: 18.7561683 },
    { lat: 48.8930031, lng: 18.7568198 },
    { lat: 48.892202, lng: 18.7576705 },
    { lat: 48.8920648, lng: 18.7580028 },
    { lat: 48.8921199, lng: 18.7580212 },
  ],
  VeľkýČepčín: [
    { lat: 48.9013376, lng: 18.7966652 },
    { lat: 48.9013046, lng: 18.796462 },
    { lat: 48.901124, lng: 18.796198 },
    { lat: 48.900888, lng: 18.7962126 },
    { lat: 48.9006527, lng: 18.7963312 },
    { lat: 48.9005079, lng: 18.7962772 },
    { lat: 48.9003032, lng: 18.7961365 },
    { lat: 48.9002345, lng: 18.7961824 },
    { lat: 48.9002347, lng: 18.7963992 },
    { lat: 48.90035, lng: 18.7972294 },
    { lat: 48.9004847, lng: 18.7973258 },
    { lat: 48.9005793, lng: 18.7974758 },
    { lat: 48.9005672, lng: 18.7976331 },
    { lat: 48.9004884, lng: 18.7977444 },
    { lat: 48.9004432, lng: 18.7976982 },
    { lat: 48.899413, lng: 18.7965382 },
    { lat: 48.8986265, lng: 18.7958784 },
    { lat: 48.8968207, lng: 18.7951122 },
    { lat: 48.8967806, lng: 18.7950988 },
    { lat: 48.8968259, lng: 18.7947356 },
    { lat: 48.8968016, lng: 18.7946267 },
    { lat: 48.8966763, lng: 18.7946444 },
    { lat: 48.8966451, lng: 18.7947072 },
    { lat: 48.89661, lng: 18.794838 },
    { lat: 48.8965938, lng: 18.794951 },
    { lat: 48.8965611, lng: 18.7950474 },
    { lat: 48.8965222, lng: 18.7950901 },
    { lat: 48.8964641, lng: 18.7950905 },
    { lat: 48.8964138, lng: 18.795096 },
    { lat: 48.8963596, lng: 18.7951405 },
    { lat: 48.8963214, lng: 18.7952159 },
    { lat: 48.8963123, lng: 18.7952907 },
    { lat: 48.8963111, lng: 18.7956571 },
    { lat: 48.8962878, lng: 18.7958378 },
    { lat: 48.8962944, lng: 18.7959722 },
    { lat: 48.8962808, lng: 18.7960533 },
    { lat: 48.8962514, lng: 18.7961174 },
    { lat: 48.8962245, lng: 18.7961281 },
    { lat: 48.8961998, lng: 18.7961121 },
    { lat: 48.8961725, lng: 18.7960904 },
    { lat: 48.89612, lng: 18.7960152 },
    { lat: 48.8960415, lng: 18.7959124 },
    { lat: 48.8957506, lng: 18.7953787 },
    { lat: 48.895697, lng: 18.795255 },
    { lat: 48.8956562, lng: 18.7951261 },
    { lat: 48.8956219, lng: 18.7949891 },
    { lat: 48.8955707, lng: 18.7948767 },
    { lat: 48.8954776, lng: 18.7947228 },
    { lat: 48.8953695, lng: 18.7946082 },
    { lat: 48.8953439, lng: 18.7945296 },
    { lat: 48.8953447, lng: 18.7943483 },
    { lat: 48.8953616, lng: 18.7939834 },
    { lat: 48.895383, lng: 18.793881 },
    { lat: 48.89544, lng: 18.7937519 },
    { lat: 48.89553, lng: 18.7936538 },
    { lat: 48.8956353, lng: 18.7935584 },
    { lat: 48.8956602, lng: 18.7935122 },
    { lat: 48.895653, lng: 18.793424 },
    { lat: 48.8956372, lng: 18.7933749 },
    { lat: 48.8956018, lng: 18.7933528 },
    { lat: 48.8955731, lng: 18.7933541 },
    { lat: 48.8954626, lng: 18.7933823 },
    { lat: 48.8954303, lng: 18.7933398 },
    { lat: 48.8954256, lng: 18.793263 },
    { lat: 48.8954517, lng: 18.7931835 },
    { lat: 48.8955636, lng: 18.7930847 },
    { lat: 48.8956131, lng: 18.7930648 },
    { lat: 48.8956568, lng: 18.7930123 },
    { lat: 48.8956774, lng: 18.7929264 },
    { lat: 48.8956968, lng: 18.7928466 },
    { lat: 48.8956881, lng: 18.7927667 },
    { lat: 48.8956656, lng: 18.7926922 },
    { lat: 48.8956297, lng: 18.7926523 },
    { lat: 48.8955351, lng: 18.7926073 },
    { lat: 48.8954522, lng: 18.7925902 },
    { lat: 48.8954237, lng: 18.7926112 },
    { lat: 48.8954059, lng: 18.7926587 },
    { lat: 48.8953881, lng: 18.7927533 },
    { lat: 48.895387, lng: 18.7928504 },
    { lat: 48.8953746, lng: 18.7929009 },
    { lat: 48.8953355, lng: 18.7929453 },
    { lat: 48.8952935, lng: 18.7929503 },
    { lat: 48.8951903, lng: 18.7929397 },
    { lat: 48.895059, lng: 18.7928724 },
    { lat: 48.8950262, lng: 18.7928155 },
    { lat: 48.8950169, lng: 18.7927391 },
    { lat: 48.8950409, lng: 18.7925532 },
    { lat: 48.8950785, lng: 18.7924004 },
    { lat: 48.895074, lng: 18.7922568 },
    { lat: 48.8950719, lng: 18.7921959 },
    { lat: 48.8950397, lng: 18.7921355 },
    { lat: 48.8950148, lng: 18.7921013 },
    { lat: 48.8949807, lng: 18.7920839 },
    { lat: 48.8948717, lng: 18.7921197 },
    { lat: 48.894706, lng: 18.7921045 },
    { lat: 48.8946721, lng: 18.7920803 },
    { lat: 48.8946406, lng: 18.792023 },
    { lat: 48.8946001, lng: 18.7918365 },
    { lat: 48.8945556, lng: 18.7917544 },
    { lat: 48.8945142, lng: 18.7916891 },
    { lat: 48.8943867, lng: 18.7915741 },
    { lat: 48.8943231, lng: 18.7915285 },
    { lat: 48.8942781, lng: 18.7915239 },
    { lat: 48.8942336, lng: 18.7915552 },
    { lat: 48.8942168, lng: 18.7915857 },
    { lat: 48.8942046, lng: 18.7916373 },
    { lat: 48.8942184, lng: 18.791685 },
    { lat: 48.8942354, lng: 18.7917056 },
    { lat: 48.894271, lng: 18.791716 },
    { lat: 48.8943016, lng: 18.791706 },
    { lat: 48.8943224, lng: 18.7917248 },
    { lat: 48.8943413, lng: 18.7917686 },
    { lat: 48.8943639, lng: 18.7918112 },
    { lat: 48.8944009, lng: 18.7919073 },
    { lat: 48.8944263, lng: 18.7920311 },
    { lat: 48.8944443, lng: 18.7922116 },
    { lat: 48.8944357, lng: 18.7922672 },
    { lat: 48.8944178, lng: 18.7923309 },
    { lat: 48.8943817, lng: 18.7924568 },
    { lat: 48.8943505, lng: 18.7924854 },
    { lat: 48.8943264, lng: 18.7925052 },
    { lat: 48.8942862, lng: 18.7925016 },
    { lat: 48.8942408, lng: 18.7924959 },
    { lat: 48.8942012, lng: 18.7924724 },
    { lat: 48.8941384, lng: 18.7924009 },
    { lat: 48.8940811, lng: 18.7923287 },
    { lat: 48.8940628, lng: 18.7923019 },
    { lat: 48.8940498, lng: 18.7922507 },
    { lat: 48.8940509, lng: 18.7921983 },
    { lat: 48.894068, lng: 18.792146 },
    { lat: 48.8940674, lng: 18.7920626 },
    { lat: 48.8940455, lng: 18.7919307 },
    { lat: 48.8940156, lng: 18.791883 },
    { lat: 48.8935865, lng: 18.7913827 },
    { lat: 48.8933362, lng: 18.7913653 },
    { lat: 48.8933228, lng: 18.7913133 },
    { lat: 48.8933109, lng: 18.7912682 },
    { lat: 48.8933211, lng: 18.7912198 },
    { lat: 48.8933425, lng: 18.791194 },
    { lat: 48.8933621, lng: 18.7911918 },
    { lat: 48.8933783, lng: 18.7912007 },
    { lat: 48.8934096, lng: 18.7911997 },
    { lat: 48.8934365, lng: 18.7911781 },
    { lat: 48.8934518, lng: 18.7911196 },
    { lat: 48.8934548, lng: 18.7910101 },
    { lat: 48.893441, lng: 18.7909096 },
    { lat: 48.8934182, lng: 18.7908552 },
    { lat: 48.8933804, lng: 18.7908001 },
    { lat: 48.8933363, lng: 18.7907468 },
    { lat: 48.8932783, lng: 18.7906463 },
    { lat: 48.8931164, lng: 18.7905154 },
    { lat: 48.8930812, lng: 18.7905084 },
    { lat: 48.8930383, lng: 18.7905454 },
    { lat: 48.8930091, lng: 18.7905888 },
    { lat: 48.8929582, lng: 18.7907098 },
    { lat: 48.8929384, lng: 18.790848 },
    { lat: 48.89295, lng: 18.7909407 },
    { lat: 48.892986, lng: 18.7910538 },
    { lat: 48.8930253, lng: 18.7910868 },
    { lat: 48.8930583, lng: 18.7911777 },
    { lat: 48.8930741, lng: 18.7913101 },
    { lat: 48.8931199, lng: 18.7913864 },
    { lat: 48.8931379, lng: 18.7914423 },
    { lat: 48.893128, lng: 18.7914959 },
    { lat: 48.8930922, lng: 18.7916125 },
    { lat: 48.8930723, lng: 18.7916711 },
    { lat: 48.8930512, lng: 18.7916912 },
    { lat: 48.8930179, lng: 18.7916909 },
    { lat: 48.8929963, lng: 18.7916661 },
    { lat: 48.8929826, lng: 18.7915975 },
    { lat: 48.8929808, lng: 18.7915045 },
    { lat: 48.8929693, lng: 18.7914583 },
    { lat: 48.8929191, lng: 18.7914212 },
    { lat: 48.8928626, lng: 18.7914158 },
    { lat: 48.8927963, lng: 18.7914354 },
    { lat: 48.8927684, lng: 18.7914545 },
    { lat: 48.8927397, lng: 18.7914977 },
    { lat: 48.8926613, lng: 18.7916607 },
    { lat: 48.8925887, lng: 18.791935 },
    { lat: 48.8923727, lng: 18.7922306 },
    { lat: 48.8923292, lng: 18.7922596 },
    { lat: 48.8922947, lng: 18.7922329 },
    { lat: 48.8921901, lng: 18.7919677 },
    { lat: 48.8921475, lng: 18.7918792 },
    { lat: 48.8920913, lng: 18.7917493 },
    { lat: 48.8921081, lng: 18.7915834 },
    { lat: 48.8921531, lng: 18.7914965 },
    { lat: 48.8922701, lng: 18.7913753 },
    { lat: 48.8923005, lng: 18.7913215 },
    { lat: 48.8923561, lng: 18.7910954 },
    { lat: 48.892414, lng: 18.7909322 },
    { lat: 48.8924815, lng: 18.7908228 },
    { lat: 48.8925449, lng: 18.7907639 },
    { lat: 48.8925555, lng: 18.7907111 },
    { lat: 48.8925442, lng: 18.7906524 },
    { lat: 48.8925046, lng: 18.7906163 },
    { lat: 48.8924451, lng: 18.7905915 },
    { lat: 48.8923258, lng: 18.7905826 },
    { lat: 48.8921706, lng: 18.7905836 },
    { lat: 48.8921376, lng: 18.7905683 },
    { lat: 48.8921215, lng: 18.7905518 },
    { lat: 48.8921165, lng: 18.790509 },
    { lat: 48.8921233, lng: 18.7904737 },
    { lat: 48.8921449, lng: 18.7904374 },
    { lat: 48.8921587, lng: 18.7903552 },
    { lat: 48.8922384, lng: 18.7899411 },
    { lat: 48.8922461, lng: 18.7898399 },
    { lat: 48.8922087, lng: 18.7897724 },
    { lat: 48.8921552, lng: 18.7897171 },
    { lat: 48.8920692, lng: 18.7897264 },
    { lat: 48.891903, lng: 18.7898485 },
    { lat: 48.8918616, lng: 18.7898932 },
    { lat: 48.8918374, lng: 18.7900078 },
    { lat: 48.8918373, lng: 18.7900804 },
    { lat: 48.8918151, lng: 18.7901457 },
    { lat: 48.8917704, lng: 18.7901761 },
    { lat: 48.8917312, lng: 18.790174 },
    { lat: 48.8913909, lng: 18.7900701 },
    { lat: 48.8912732, lng: 18.7902362 },
    { lat: 48.891332, lng: 18.7906068 },
    { lat: 48.8914698, lng: 18.7909346 },
    { lat: 48.8913429, lng: 18.7911595 },
    { lat: 48.8911683, lng: 18.7912733 },
    { lat: 48.8909948, lng: 18.7912959 },
    { lat: 48.8908285, lng: 18.7914137 },
    { lat: 48.8906662, lng: 18.7912887 },
    { lat: 48.8906339, lng: 18.7912387 },
    { lat: 48.8905138, lng: 18.7910532 },
    { lat: 48.890491, lng: 18.791018 },
    { lat: 48.8904287, lng: 18.7908787 },
    { lat: 48.8903631, lng: 18.790732 },
    { lat: 48.8903755, lng: 18.7906662 },
    { lat: 48.8904019, lng: 18.7905256 },
    { lat: 48.8905322, lng: 18.7901298 },
    { lat: 48.8905361, lng: 18.7900892 },
    { lat: 48.8902484, lng: 18.7897847 },
    { lat: 48.8901733, lng: 18.7899259 },
    { lat: 48.890195, lng: 18.7902734 },
    { lat: 48.8899825, lng: 18.7903706 },
    { lat: 48.8897801, lng: 18.790211 },
    { lat: 48.8895718, lng: 18.7896876 },
    { lat: 48.8894871, lng: 18.7894407 },
    { lat: 48.8893546, lng: 18.7891419 },
    { lat: 48.8895424, lng: 18.788593 },
    { lat: 48.8893265, lng: 18.7883955 },
    { lat: 48.8891759, lng: 18.7883353 },
    { lat: 48.8891699, lng: 18.7882997 },
    { lat: 48.8889188, lng: 18.7879694 },
    { lat: 48.8887837, lng: 18.7876485 },
    { lat: 48.8886133, lng: 18.7874937 },
    { lat: 48.8884204, lng: 18.7874688 },
    { lat: 48.8882962, lng: 18.7875328 },
    { lat: 48.8882719, lng: 18.7875454 },
    { lat: 48.8878308, lng: 18.7876915 },
    { lat: 48.8877262, lng: 18.787956 },
    { lat: 48.8874768, lng: 18.7880802 },
    { lat: 48.8870957, lng: 18.7879431 },
    { lat: 48.8870381, lng: 18.7879508 },
    { lat: 48.8870405, lng: 18.7880424 },
    { lat: 48.8870719, lng: 18.7885351 },
    { lat: 48.8871068, lng: 18.7886009 },
    { lat: 48.8872934, lng: 18.7889525 },
    { lat: 48.8872894, lng: 18.7893683 },
    { lat: 48.887289, lng: 18.7894074 },
    { lat: 48.8870962, lng: 18.7892994 },
    { lat: 48.8867621, lng: 18.7895351 },
    { lat: 48.886695, lng: 18.7894812 },
    { lat: 48.8867016, lng: 18.7891733 },
    { lat: 48.8864294, lng: 18.7890143 },
    { lat: 48.8864263, lng: 18.7893622 },
    { lat: 48.8861126, lng: 18.7891662 },
    { lat: 48.8860592, lng: 18.7892078 },
    { lat: 48.8858081, lng: 18.7894034 },
    { lat: 48.8855546, lng: 18.7897294 },
    { lat: 48.8856603, lng: 18.7900941 },
    { lat: 48.8857123, lng: 18.7901198 },
    { lat: 48.8859613, lng: 18.7902428 },
    { lat: 48.8860011, lng: 18.7902707 },
    { lat: 48.8859142, lng: 18.7903237 },
    { lat: 48.885576, lng: 18.7905298 },
    { lat: 48.8855635, lng: 18.7906378 },
    { lat: 48.8857212, lng: 18.7907822 },
    { lat: 48.8857779, lng: 18.7908342 },
    { lat: 48.885986, lng: 18.7911274 },
    { lat: 48.885865, lng: 18.7912731 },
    { lat: 48.8855682, lng: 18.7915484 },
    { lat: 48.8855683, lng: 18.7917673 },
    { lat: 48.8854671, lng: 18.792012 },
    { lat: 48.8854186, lng: 18.7920655 },
    { lat: 48.8850567, lng: 18.7924647 },
    { lat: 48.8849983, lng: 18.792491 },
    { lat: 48.8847365, lng: 18.7923569 },
    { lat: 48.8846328, lng: 18.7923607 },
    { lat: 48.8844805, lng: 18.7924445 },
    { lat: 48.88434, lng: 18.7926054 },
    { lat: 48.8842781, lng: 18.792692 },
    { lat: 48.8841706, lng: 18.7927511 },
    { lat: 48.8839184, lng: 18.7927807 },
    { lat: 48.8837297, lng: 18.7927922 },
    { lat: 48.8836155, lng: 18.7925515 },
    { lat: 48.8836021, lng: 18.7924657 },
    { lat: 48.88365, lng: 18.7923697 },
    { lat: 48.8838904, lng: 18.7924401 },
    { lat: 48.8839156, lng: 18.7923159 },
    { lat: 48.8839041, lng: 18.7922267 },
    { lat: 48.8839171, lng: 18.7920639 },
    { lat: 48.8839954, lng: 18.7918345 },
    { lat: 48.8840038, lng: 18.7916912 },
    { lat: 48.883958, lng: 18.7915706 },
    { lat: 48.8838942, lng: 18.7914933 },
    { lat: 48.883633, lng: 18.7912649 },
    { lat: 48.8835762, lng: 18.7911763 },
    { lat: 48.8835201, lng: 18.7910405 },
    { lat: 48.8834719, lng: 18.7909446 },
    { lat: 48.883238, lng: 18.7908494 },
    { lat: 48.8830348, lng: 18.7907365 },
    { lat: 48.8827184, lng: 18.7906785 },
    { lat: 48.882698, lng: 18.7905494 },
    { lat: 48.8828213, lng: 18.7902308 },
    { lat: 48.8828221, lng: 18.790053 },
    { lat: 48.8824878, lng: 18.7899756 },
    { lat: 48.881304, lng: 18.7900037 },
    { lat: 48.8810227, lng: 18.7900797 },
    { lat: 48.8810014, lng: 18.7901929 },
    { lat: 48.881161, lng: 18.7907427 },
    { lat: 48.8808632, lng: 18.7908636 },
    { lat: 48.880844, lng: 18.7908714 },
    { lat: 48.8808137, lng: 18.790808 },
    { lat: 48.8806323, lng: 18.7904002 },
    { lat: 48.8805423, lng: 18.7901019 },
    { lat: 48.8804037, lng: 18.789972 },
    { lat: 48.8802621, lng: 18.789918 },
    { lat: 48.8800453, lng: 18.7898702 },
    { lat: 48.8796603, lng: 18.7895785 },
    { lat: 48.8795506, lng: 18.7896139 },
    { lat: 48.8794609, lng: 18.7894971 },
    { lat: 48.879436, lng: 18.7894292 },
    { lat: 48.8795019, lng: 18.7890297 },
    { lat: 48.8794455, lng: 18.7889 },
    { lat: 48.8793491, lng: 18.7887356 },
    { lat: 48.8791696, lng: 18.7885802 },
    { lat: 48.8787916, lng: 18.788599 },
    { lat: 48.8785443, lng: 18.7887007 },
    { lat: 48.878364, lng: 18.7889631 },
    { lat: 48.8783373, lng: 18.7889265 },
    { lat: 48.8783095, lng: 18.7891688 },
    { lat: 48.8780978, lng: 18.7894142 },
    { lat: 48.8778166, lng: 18.7895984 },
    { lat: 48.8779325, lng: 18.790244 },
    { lat: 48.8779055, lng: 18.7903526 },
    { lat: 48.8776864, lng: 18.7902836 },
    { lat: 48.8775229, lng: 18.7902154 },
    { lat: 48.8774486, lng: 18.7902175 },
    { lat: 48.8774247, lng: 18.7902577 },
    { lat: 48.8774184, lng: 18.7903548 },
    { lat: 48.8774457, lng: 18.7906206 },
    { lat: 48.8773513, lng: 18.7907315 },
    { lat: 48.8772525, lng: 18.790739 },
    { lat: 48.8770698, lng: 18.7906341 },
    { lat: 48.8768798, lng: 18.7904481 },
    { lat: 48.8768108, lng: 18.7903177 },
    { lat: 48.8767796, lng: 18.7902136 },
    { lat: 48.8768033, lng: 18.7901273 },
    { lat: 48.8768801, lng: 18.7900421 },
    { lat: 48.8769895, lng: 18.7900423 },
    { lat: 48.8770747, lng: 18.790098 },
    { lat: 48.8771388, lng: 18.7900724 },
    { lat: 48.8771507, lng: 18.7898474 },
    { lat: 48.8770084, lng: 18.7895628 },
    { lat: 48.8769058, lng: 18.7893487 },
    { lat: 48.8767853, lng: 18.7893083 },
    { lat: 48.8766706, lng: 18.7891845 },
    { lat: 48.8765151, lng: 18.7890563 },
    { lat: 48.8762933, lng: 18.7888046 },
    { lat: 48.87618, lng: 18.7886845 },
    { lat: 48.875976, lng: 18.7887108 },
    { lat: 48.8758134, lng: 18.7888374 },
    { lat: 48.875541, lng: 18.7889518 },
    { lat: 48.8754984, lng: 18.7889999 },
    { lat: 48.8754814, lng: 18.789041 },
    { lat: 48.8754848, lng: 18.789431 },
    { lat: 48.875575, lng: 18.7895768 },
    { lat: 48.8756215, lng: 18.7896278 },
    { lat: 48.8756706, lng: 18.7900077 },
    { lat: 48.8756267, lng: 18.7904834 },
    { lat: 48.8756147, lng: 18.7906897 },
    { lat: 48.8755856, lng: 18.7908426 },
    { lat: 48.8755183, lng: 18.790961 },
    { lat: 48.8754139, lng: 18.7910527 },
    { lat: 48.8752592, lng: 18.7910727 },
    { lat: 48.8750812, lng: 18.7910124 },
    { lat: 48.8750079, lng: 18.7908388 },
    { lat: 48.8749662, lng: 18.7906013 },
    { lat: 48.8748982, lng: 18.7904217 },
    { lat: 48.8748732, lng: 18.7902671 },
    { lat: 48.8749017, lng: 18.7901222 },
    { lat: 48.8749777, lng: 18.7898254 },
    { lat: 48.8749559, lng: 18.7897331 },
    { lat: 48.8748332, lng: 18.7898685 },
    { lat: 48.8746695, lng: 18.7899061 },
    { lat: 48.8745382, lng: 18.7899164 },
    { lat: 48.8742792, lng: 18.7898397 },
    { lat: 48.8741695, lng: 18.789788 },
    { lat: 48.8740311, lng: 18.7897523 },
    { lat: 48.8739535, lng: 18.7896473 },
    { lat: 48.8738736, lng: 18.7895652 },
    { lat: 48.873648, lng: 18.7896711 },
    { lat: 48.8735869, lng: 18.7896444 },
    { lat: 48.8735427, lng: 18.7898582 },
    { lat: 48.8735354, lng: 18.789935 },
    { lat: 48.8734951, lng: 18.7900452 },
    { lat: 48.8734192, lng: 18.7901266 },
    { lat: 48.8732569, lng: 18.7900236 },
    { lat: 48.8730433, lng: 18.7897959 },
    { lat: 48.8728935, lng: 18.7896695 },
    { lat: 48.8726259, lng: 18.7896178 },
    { lat: 48.8724354, lng: 18.7895131 },
    { lat: 48.8723734, lng: 18.7895335 },
    { lat: 48.8723005, lng: 18.789587 },
    { lat: 48.8722875, lng: 18.7896584 },
    { lat: 48.8723, lng: 18.7897708 },
    { lat: 48.8722841, lng: 18.7898741 },
    { lat: 48.8722428, lng: 18.7902505 },
    { lat: 48.8721839, lng: 18.7905488 },
    { lat: 48.8720478, lng: 18.7905759 },
    { lat: 48.8719404, lng: 18.790573 },
    { lat: 48.8718271, lng: 18.7905171 },
    { lat: 48.8716617, lng: 18.7905047 },
    { lat: 48.8715892, lng: 18.7905413 },
    { lat: 48.8715465, lng: 18.7906292 },
    { lat: 48.8715253, lng: 18.7908743 },
    { lat: 48.8714882, lng: 18.7910277 },
    { lat: 48.8714178, lng: 18.7912554 },
    { lat: 48.8713841, lng: 18.7913422 },
    { lat: 48.8713144, lng: 18.7913699 },
    { lat: 48.8711947, lng: 18.7912821 },
    { lat: 48.8711002, lng: 18.7912401 },
    { lat: 48.8707326, lng: 18.7911971 },
    { lat: 48.8706535, lng: 18.7912385 },
    { lat: 48.8705128, lng: 18.7912425 },
    { lat: 48.8704232, lng: 18.7912321 },
    { lat: 48.8704099, lng: 18.7910847 },
    { lat: 48.8703884, lng: 18.7910171 },
    { lat: 48.8704333, lng: 18.7908863 },
    { lat: 48.8704925, lng: 18.7907664 },
    { lat: 48.8704782, lng: 18.7907104 },
    { lat: 48.8703049, lng: 18.7906954 },
    { lat: 48.8702922, lng: 18.7907327 },
    { lat: 48.8703523, lng: 18.79091 },
    { lat: 48.8703289, lng: 18.7910231 },
    { lat: 48.8702623, lng: 18.7910881 },
    { lat: 48.8701871, lng: 18.7911171 },
    { lat: 48.8700949, lng: 18.791058 },
    { lat: 48.8700366, lng: 18.7909561 },
    { lat: 48.8699779, lng: 18.7908227 },
    { lat: 48.8697878, lng: 18.7905292 },
    { lat: 48.8697087, lng: 18.7903553 },
    { lat: 48.8696488, lng: 18.7903066 },
    { lat: 48.8695959, lng: 18.79029 },
    { lat: 48.8693432, lng: 18.7905687 },
    { lat: 48.8694979, lng: 18.7908287 },
    { lat: 48.8693832, lng: 18.7912712 },
    { lat: 48.8695168, lng: 18.7916893 },
    { lat: 48.8691906, lng: 18.7918144 },
    { lat: 48.8691853, lng: 18.7922295 },
    { lat: 48.8687865, lng: 18.7918055 },
    { lat: 48.8690433, lng: 18.7930764 },
    { lat: 48.8690649, lng: 18.7931645 },
    { lat: 48.8691339, lng: 18.7936451 },
    { lat: 48.8692127, lng: 18.7938599 },
    { lat: 48.8694951, lng: 18.7944948 },
    { lat: 48.8700043, lng: 18.7956083 },
    { lat: 48.8701656, lng: 18.7960654 },
    { lat: 48.8703953, lng: 18.7964695 },
    { lat: 48.870532, lng: 18.7967565 },
    { lat: 48.8704738, lng: 18.7970905 },
    { lat: 48.8704833, lng: 18.7972721 },
    { lat: 48.8705534, lng: 18.7974929 },
    { lat: 48.8706955, lng: 18.7977714 },
    { lat: 48.8708551, lng: 18.7980632 },
    { lat: 48.8709995, lng: 18.7982809 },
    { lat: 48.8713922, lng: 18.7986343 },
    { lat: 48.8715152, lng: 18.7988965 },
    { lat: 48.8716618, lng: 18.7991791 },
    { lat: 48.8718342, lng: 18.7993501 },
    { lat: 48.872104, lng: 18.7995696 },
    { lat: 48.8725698, lng: 18.8000476 },
    { lat: 48.8729049, lng: 18.800616 },
    { lat: 48.8731487, lng: 18.8010088 },
    { lat: 48.873547, lng: 18.8018377 },
    { lat: 48.873757, lng: 18.8021855 },
    { lat: 48.8740604, lng: 18.8028158 },
    { lat: 48.8739688, lng: 18.8027906 },
    { lat: 48.8729653, lng: 18.8026191 },
    { lat: 48.8739212, lng: 18.8043671 },
    { lat: 48.874179, lng: 18.8048589 },
    { lat: 48.8740769, lng: 18.8049535 },
    { lat: 48.8737652, lng: 18.8053121 },
    { lat: 48.8735346, lng: 18.805584 },
    { lat: 48.8732618, lng: 18.8059537 },
    { lat: 48.8731629, lng: 18.8061737 },
    { lat: 48.872989, lng: 18.8066702 },
    { lat: 48.8728883, lng: 18.8069688 },
    { lat: 48.8728418, lng: 18.8071789 },
    { lat: 48.8728061, lng: 18.8075321 },
    { lat: 48.8727764, lng: 18.8080865 },
    { lat: 48.8727542, lng: 18.8083783 },
    { lat: 48.872686, lng: 18.808538 },
    { lat: 48.8725783, lng: 18.8087435 },
    { lat: 48.8724016, lng: 18.8091003 },
    { lat: 48.8722954, lng: 18.8092466 },
    { lat: 48.8717522, lng: 18.8096841 },
    { lat: 48.8716265, lng: 18.8100211 },
    { lat: 48.8715569, lng: 18.8102218 },
    { lat: 48.8715126, lng: 18.8104105 },
    { lat: 48.8714092, lng: 18.8109401 },
    { lat: 48.8713737, lng: 18.811103 },
    { lat: 48.871294, lng: 18.8113472 },
    { lat: 48.8710915, lng: 18.8119184 },
    { lat: 48.8709992, lng: 18.8121217 },
    { lat: 48.8708939, lng: 18.8123045 },
    { lat: 48.8708165, lng: 18.8124015 },
    { lat: 48.8706178, lng: 18.812633 },
    { lat: 48.8700876, lng: 18.8133665 },
    { lat: 48.8699929, lng: 18.8135225 },
    { lat: 48.8698102, lng: 18.8138674 },
    { lat: 48.8697658, lng: 18.8139268 },
    { lat: 48.8697102, lng: 18.8139603 },
    { lat: 48.8695207, lng: 18.814002 },
    { lat: 48.8694435, lng: 18.8140803 },
    { lat: 48.869178, lng: 18.8145683 },
    { lat: 48.8689842, lng: 18.81494 },
    { lat: 48.8689551, lng: 18.8149675 },
    { lat: 48.868869, lng: 18.8151343 },
    { lat: 48.8684529, lng: 18.8159988 },
    { lat: 48.868263, lng: 18.8163414 },
    { lat: 48.8682156, lng: 18.8164015 },
    { lat: 48.8681047, lng: 18.8166142 },
    { lat: 48.8679209, lng: 18.8168958 },
    { lat: 48.8678742, lng: 18.8169068 },
    { lat: 48.8675044, lng: 18.8177522 },
    { lat: 48.8672489, lng: 18.8188063 },
    { lat: 48.8671853, lng: 18.819989 },
    { lat: 48.867408, lng: 18.8201333 },
    { lat: 48.8685022, lng: 18.8207685 },
    { lat: 48.8689696, lng: 18.8209097 },
    { lat: 48.869653, lng: 18.8210424 },
    { lat: 48.8700288, lng: 18.8211996 },
    { lat: 48.8702283, lng: 18.8213065 },
    { lat: 48.8704244, lng: 18.8215238 },
    { lat: 48.8705291, lng: 18.8215421 },
    { lat: 48.8709492, lng: 18.821641 },
    { lat: 48.8713642, lng: 18.8217065 },
    { lat: 48.8717037, lng: 18.8216878 },
    { lat: 48.8717578, lng: 18.8216656 },
    { lat: 48.8722676, lng: 18.8214271 },
    { lat: 48.8726033, lng: 18.8210973 },
    { lat: 48.8730101, lng: 18.8206536 },
    { lat: 48.873229, lng: 18.8205276 },
    { lat: 48.8739602, lng: 18.820258 },
    { lat: 48.8747866, lng: 18.8198857 },
    { lat: 48.8760844, lng: 18.8191877 },
    { lat: 48.8762725, lng: 18.8191592 },
    { lat: 48.87679, lng: 18.8188778 },
    { lat: 48.8772063, lng: 18.8186866 },
    { lat: 48.8780217, lng: 18.8182772 },
    { lat: 48.8785757, lng: 18.8180008 },
    { lat: 48.8792805, lng: 18.8176096 },
    { lat: 48.8793619, lng: 18.8176561 },
    { lat: 48.8798388, lng: 18.8172036 },
    { lat: 48.8801943, lng: 18.8170548 },
    { lat: 48.8802684, lng: 18.816898 },
    { lat: 48.8804527, lng: 18.8168215 },
    { lat: 48.8805322, lng: 18.8168726 },
    { lat: 48.8805945, lng: 18.816772 },
    { lat: 48.8807161, lng: 18.8166793 },
    { lat: 48.8808035, lng: 18.8165371 },
    { lat: 48.8809931, lng: 18.8164747 },
    { lat: 48.8812672, lng: 18.8163069 },
    { lat: 48.8813341, lng: 18.8162258 },
    { lat: 48.8813664, lng: 18.8160424 },
    { lat: 48.8815181, lng: 18.8159247 },
    { lat: 48.8816798, lng: 18.8160218 },
    { lat: 48.8821164, lng: 18.8159611 },
    { lat: 48.8824313, lng: 18.81586 },
    { lat: 48.8828132, lng: 18.8157431 },
    { lat: 48.8830141, lng: 18.8159208 },
    { lat: 48.8831839, lng: 18.8159955 },
    { lat: 48.8834793, lng: 18.8159749 },
    { lat: 48.8841343, lng: 18.8159067 },
    { lat: 48.8846982, lng: 18.8158211 },
    { lat: 48.8852276, lng: 18.8157234 },
    { lat: 48.885774, lng: 18.8157807 },
    { lat: 48.8860841, lng: 18.8160593 },
    { lat: 48.8861308, lng: 18.8160706 },
    { lat: 48.886332, lng: 18.8164912 },
    { lat: 48.8866255, lng: 18.8173884 },
    { lat: 48.8869138, lng: 18.8180126 },
    { lat: 48.8870591, lng: 18.8180529 },
    { lat: 48.8872416, lng: 18.8181547 },
    { lat: 48.8879249, lng: 18.8186991 },
    { lat: 48.8879456, lng: 18.8187303 },
    { lat: 48.8883795, lng: 18.8191357 },
    { lat: 48.8889746, lng: 18.8197616 },
    { lat: 48.8891536, lng: 18.8200373 },
    { lat: 48.8893384, lng: 18.8203621 },
    { lat: 48.8900789, lng: 18.8220908 },
    { lat: 48.8901763, lng: 18.8222752 },
    { lat: 48.8903771, lng: 18.8225156 },
    { lat: 48.8907214, lng: 18.8228483 },
    { lat: 48.8912736, lng: 18.8236267 },
    { lat: 48.8916031, lng: 18.8242617 },
    { lat: 48.8917135, lng: 18.8246266 },
    { lat: 48.8918065, lng: 18.8249846 },
    { lat: 48.8918339, lng: 18.824971 },
    { lat: 48.8935909, lng: 18.823563 },
    { lat: 48.8960011, lng: 18.8226346 },
    { lat: 48.8970005, lng: 18.8223358 },
    { lat: 48.8970993, lng: 18.8223104 },
    { lat: 48.8981279, lng: 18.8220106 },
    { lat: 48.8976543, lng: 18.8183793 },
    { lat: 48.8985673, lng: 18.8179835 },
    { lat: 48.8993528, lng: 18.8176711 },
    { lat: 48.8991655, lng: 18.8157334 },
    { lat: 48.900049, lng: 18.8152691 },
    { lat: 48.9000406, lng: 18.8152346 },
    { lat: 48.9000319, lng: 18.8151998 },
    { lat: 48.8994233, lng: 18.8113215 },
    { lat: 48.898996, lng: 18.8087606 },
    { lat: 48.8989901, lng: 18.8086928 },
    { lat: 48.8989732, lng: 18.8086045 },
    { lat: 48.8986211, lng: 18.8067364 },
    { lat: 48.898378, lng: 18.8052061 },
    { lat: 48.8983201, lng: 18.8049156 },
    { lat: 48.8983157, lng: 18.804895 },
    { lat: 48.8983042, lng: 18.8048695 },
    { lat: 48.8984007, lng: 18.8048153 },
    { lat: 48.8989003, lng: 18.804777 },
    { lat: 48.8989194, lng: 18.8047758 },
    { lat: 48.8989542, lng: 18.8047727 },
    { lat: 48.899134, lng: 18.8047361 },
    { lat: 48.8992447, lng: 18.8046332 },
    { lat: 48.8993138, lng: 18.8045544 },
    { lat: 48.8993768, lng: 18.8044841 },
    { lat: 48.8993986, lng: 18.8044496 },
    { lat: 48.8995, lng: 18.8042955 },
    { lat: 48.899579, lng: 18.8042516 },
    { lat: 48.8996281, lng: 18.804264 },
    { lat: 48.8997282, lng: 18.8042804 },
    { lat: 48.8999452, lng: 18.8041408 },
    { lat: 48.9001435, lng: 18.8039196 },
    { lat: 48.9002037, lng: 18.8038647 },
    { lat: 48.9003343, lng: 18.8037301 },
    { lat: 48.9003709, lng: 18.8036934 },
    { lat: 48.9004556, lng: 18.8035865 },
    { lat: 48.9004999, lng: 18.8035299 },
    { lat: 48.9005019, lng: 18.8035276 },
    { lat: 48.9005055, lng: 18.8034017 },
    { lat: 48.9005325, lng: 18.8033121 },
    { lat: 48.9005356, lng: 18.8031984 },
    { lat: 48.9005368, lng: 18.8031366 },
    { lat: 48.9005436, lng: 18.8029179 },
    { lat: 48.9005444, lng: 18.8028694 },
    { lat: 48.9005437, lng: 18.8028548 },
    { lat: 48.9005253, lng: 18.8026527 },
    { lat: 48.9005501, lng: 18.8025476 },
    { lat: 48.9006249, lng: 18.8025731 },
    { lat: 48.9006461, lng: 18.8025716 },
    { lat: 48.9007292, lng: 18.8025642 },
    { lat: 48.9007658, lng: 18.8025096 },
    { lat: 48.9008225, lng: 18.802429 },
    { lat: 48.9008788, lng: 18.8024014 },
    { lat: 48.9008908, lng: 18.8023337 },
    { lat: 48.900849, lng: 18.8022649 },
    { lat: 48.9008367, lng: 18.8022463 },
    { lat: 48.9008787, lng: 18.8018825 },
    { lat: 48.9007868, lng: 18.801806 },
    { lat: 48.9008256, lng: 18.8015573 },
    { lat: 48.9005957, lng: 18.8012995 },
    { lat: 48.9004761, lng: 18.8010167 },
    { lat: 48.9004222, lng: 18.8007802 },
    { lat: 48.9004223, lng: 18.800654 },
    { lat: 48.9004389, lng: 18.8005384 },
    { lat: 48.900517, lng: 18.8002857 },
    { lat: 48.9005883, lng: 18.800118 },
    { lat: 48.9007546, lng: 18.799844 },
    { lat: 48.9008609, lng: 18.7994162 },
    { lat: 48.9009155, lng: 18.7991043 },
    { lat: 48.900907, lng: 18.7988311 },
    { lat: 48.9009849, lng: 18.7987443 },
    { lat: 48.9010991, lng: 18.79845 },
    { lat: 48.9011109, lng: 18.7982006 },
    { lat: 48.9010162, lng: 18.7978972 },
    { lat: 48.9010849, lng: 18.7976331 },
    { lat: 48.9011143, lng: 18.7975944 },
    { lat: 48.9012305, lng: 18.7974841 },
    { lat: 48.9012901, lng: 18.7973719 },
    { lat: 48.9013159, lng: 18.7968294 },
    { lat: 48.9013286, lng: 18.7967508 },
    { lat: 48.9013137, lng: 18.7966591 },
    { lat: 48.9013376, lng: 18.7966652 },
  ],
  Čremošné: [
    { lat: 48.842787, lng: 18.994196 },
    { lat: 48.8425613, lng: 18.9942746 },
    { lat: 48.8423838, lng: 18.9942877 },
    { lat: 48.8420908, lng: 18.9942665 },
    { lat: 48.8419501, lng: 18.9943622 },
    { lat: 48.8417735, lng: 18.9944578 },
    { lat: 48.8416441, lng: 18.9945533 },
    { lat: 48.8415718, lng: 18.9946197 },
    { lat: 48.8413358, lng: 18.9947684 },
    { lat: 48.8411322, lng: 18.9948639 },
    { lat: 48.8404483, lng: 18.9952107 },
    { lat: 48.8402132, lng: 18.9953081 },
    { lat: 48.8397325, lng: 18.9954368 },
    { lat: 48.8393338, lng: 18.995533 },
    { lat: 48.8388682, lng: 18.9956689 },
    { lat: 48.8385595, lng: 18.995871 },
    { lat: 48.8384401, lng: 18.9961651 },
    { lat: 48.8383412, lng: 18.9966865 },
    { lat: 48.8383077, lng: 18.9968282 },
    { lat: 48.8381038, lng: 18.996763 },
    { lat: 48.8378796, lng: 18.9965646 },
    { lat: 48.8377352, lng: 18.9963164 },
    { lat: 48.8375229, lng: 18.9957931 },
    { lat: 48.8375239, lng: 18.9954798 },
    { lat: 48.837517, lng: 18.9948758 },
    { lat: 48.8374581, lng: 18.9945326 },
    { lat: 48.8372316, lng: 18.9940423 },
    { lat: 48.8367359, lng: 18.9930243 },
    { lat: 48.8365729, lng: 18.992609 },
    { lat: 48.8363282, lng: 18.9915565 },
    { lat: 48.8360813, lng: 18.9909989 },
    { lat: 48.8360054, lng: 18.9906147 },
    { lat: 48.836051, lng: 18.9896417 },
    { lat: 48.8360393, lng: 18.9890501 },
    { lat: 48.8359707, lng: 18.9885649 },
    { lat: 48.835733, lng: 18.9877177 },
    { lat: 48.8353148, lng: 18.9867409 },
    { lat: 48.8351151, lng: 18.9861538 },
    { lat: 48.8348632, lng: 18.9857661 },
    { lat: 48.8346479, lng: 18.9854783 },
    { lat: 48.834355, lng: 18.9848824 },
    { lat: 48.8340798, lng: 18.9844988 },
    { lat: 48.8339251, lng: 18.9840694 },
    { lat: 48.8339336, lng: 18.9835219 },
    { lat: 48.8338847, lng: 18.9826909 },
    { lat: 48.8339115, lng: 18.9815057 },
    { lat: 48.8339505, lng: 18.9813151 },
    { lat: 48.8340335, lng: 18.9811523 },
    { lat: 48.8343616, lng: 18.9805957 },
    { lat: 48.8344196, lng: 18.980473 },
    { lat: 48.8346122, lng: 18.9799793 },
    { lat: 48.8348724, lng: 18.9792133 },
    { lat: 48.8349751, lng: 18.9787268 },
    { lat: 48.8352558, lng: 18.9780544 },
    { lat: 48.835479, lng: 18.9768535 },
    { lat: 48.8356645, lng: 18.9761742 },
    { lat: 48.8357713, lng: 18.975543 },
    { lat: 48.8359061, lng: 18.9751587 },
    { lat: 48.8360296, lng: 18.9743699 },
    { lat: 48.8362608, lng: 18.9737239 },
    { lat: 48.8364076, lng: 18.9729466 },
    { lat: 48.8364948, lng: 18.9726012 },
    { lat: 48.8365348, lng: 18.9724763 },
    { lat: 48.8366039, lng: 18.9720581 },
    { lat: 48.8366272, lng: 18.9718278 },
    { lat: 48.8365951, lng: 18.9715727 },
    { lat: 48.8364633, lng: 18.9711761 },
    { lat: 48.8364058, lng: 18.971021 },
    { lat: 48.8363959, lng: 18.9698239 },
    { lat: 48.8363541, lng: 18.9689104 },
    { lat: 48.8363786, lng: 18.9685672 },
    { lat: 48.8365153, lng: 18.9682868 },
    { lat: 48.8366877, lng: 18.9677718 },
    { lat: 48.836822, lng: 18.9672849 },
    { lat: 48.8369419, lng: 18.9671807 },
    { lat: 48.8370682, lng: 18.9669599 },
    { lat: 48.837149, lng: 18.9667969 },
    { lat: 48.8372357, lng: 18.9665957 },
    { lat: 48.8373334, lng: 18.9662991 },
    { lat: 48.8375375, lng: 18.9655445 },
    { lat: 48.8376453, lng: 18.9652245 },
    { lat: 48.8377546, lng: 18.9646213 },
    { lat: 48.8378276, lng: 18.9640401 },
    { lat: 48.8378767, lng: 18.9637406 },
    { lat: 48.8382578, lng: 18.9628272 },
    { lat: 48.8383671, lng: 18.9625837 },
    { lat: 48.8384116, lng: 18.962372 },
    { lat: 48.8384739, lng: 18.9618855 },
    { lat: 48.8385622, lng: 18.961382 },
    { lat: 48.8387744, lng: 18.9611218 },
    { lat: 48.8389319, lng: 18.9605383 },
    { lat: 48.8389983, lng: 18.9598455 },
    { lat: 48.8390436, lng: 18.9595413 },
    { lat: 48.8390213, lng: 18.9593664 },
    { lat: 48.8389792, lng: 18.959226 },
    { lat: 48.8389089, lng: 18.9589489 },
    { lat: 48.8388333, lng: 18.9587716 },
    { lat: 48.8387922, lng: 18.9586057 },
    { lat: 48.8387731, lng: 18.9583402 },
    { lat: 48.8387681, lng: 18.9579295 },
    { lat: 48.8388125, lng: 18.9576509 },
    { lat: 48.8388931, lng: 18.9574182 },
    { lat: 48.839082, lng: 18.9570962 },
    { lat: 48.8393406, lng: 18.9565306 },
    { lat: 48.839461, lng: 18.9561004 },
    { lat: 48.8395004, lng: 18.9555224 },
    { lat: 48.8394962, lng: 18.9549742 },
    { lat: 48.8393875, lng: 18.9539005 },
    { lat: 48.839412, lng: 18.9535831 },
    { lat: 48.8395352, lng: 18.9531886 },
    { lat: 48.8396893, lng: 18.9529405 },
    { lat: 48.8399141, lng: 18.952746 },
    { lat: 48.8400735, lng: 18.9524458 },
    { lat: 48.8401739, lng: 18.9521348 },
    { lat: 48.8403288, lng: 18.9516318 },
    { lat: 48.8404883, lng: 18.9511776 },
    { lat: 48.8405143, lng: 18.9510697 },
    { lat: 48.8406175, lng: 18.9505265 },
    { lat: 48.8407179, lng: 18.9500194 },
    { lat: 48.8407808, lng: 18.9497225 },
    { lat: 48.8409171, lng: 18.9493218 },
    { lat: 48.8410946, lng: 18.9488447 },
    { lat: 48.8412565, lng: 18.9485033 },
    { lat: 48.8414676, lng: 18.9480871 },
    { lat: 48.8416176, lng: 18.9477099 },
    { lat: 48.8418017, lng: 18.9473158 },
    { lat: 48.8419218, lng: 18.94715 },
    { lat: 48.8421934, lng: 18.9467951 },
    { lat: 48.8424003, lng: 18.9465855 },
    { lat: 48.8426153, lng: 18.9464 },
    { lat: 48.8427688, lng: 18.946226 },
    { lat: 48.8428426, lng: 18.9461899 },
    { lat: 48.8429654, lng: 18.9459479 },
    { lat: 48.8431307, lng: 18.9454078 },
    { lat: 48.8434186, lng: 18.944529 },
    { lat: 48.8436362, lng: 18.9437762 },
    { lat: 48.8439145, lng: 18.9427497 },
    { lat: 48.8443284, lng: 18.9416901 },
    { lat: 48.8446265, lng: 18.9408588 },
    { lat: 48.8448137, lng: 18.9403261 },
    { lat: 48.8449302, lng: 18.9399137 },
    { lat: 48.8450143, lng: 18.9394414 },
    { lat: 48.8451083, lng: 18.9389815 },
    { lat: 48.8451845, lng: 18.9385516 },
    { lat: 48.845281, lng: 18.9383685 },
    { lat: 48.845294, lng: 18.9381365 },
    { lat: 48.8453792, lng: 18.9379261 },
    { lat: 48.8455194, lng: 18.937803 },
    { lat: 48.8455861, lng: 18.9376051 },
    { lat: 48.8455598, lng: 18.9372649 },
    { lat: 48.8455469, lng: 18.9367065 },
    { lat: 48.8456007, lng: 18.9360552 },
    { lat: 48.8457074, lng: 18.9354746 },
    { lat: 48.8457773, lng: 18.9349857 },
    { lat: 48.8457999, lng: 18.9345857 },
    { lat: 48.8457995, lng: 18.9343369 },
    { lat: 48.8460527, lng: 18.9344602 },
    { lat: 48.8463009, lng: 18.9346398 },
    { lat: 48.8463931, lng: 18.9347235 },
    { lat: 48.846652, lng: 18.9348028 },
    { lat: 48.8468618, lng: 18.9348069 },
    { lat: 48.8473384, lng: 18.9347987 },
    { lat: 48.8475061, lng: 18.9348804 },
    { lat: 48.8476243, lng: 18.9349017 },
    { lat: 48.8476654, lng: 18.9349971 },
    { lat: 48.8480912, lng: 18.9350365 },
    { lat: 48.8482823, lng: 18.9350675 },
    { lat: 48.8483678, lng: 18.9350213 },
    { lat: 48.8485449, lng: 18.9351119 },
    { lat: 48.8485619, lng: 18.9349814 },
    { lat: 48.8485662, lng: 18.9345789 },
    { lat: 48.8485813, lng: 18.9343201 },
    { lat: 48.8495721, lng: 18.9317275 },
    { lat: 48.8501972, lng: 18.930058 },
    { lat: 48.8494472, lng: 18.9291674 },
    { lat: 48.8492328, lng: 18.9286555 },
    { lat: 48.8489778, lng: 18.9283202 },
    { lat: 48.84886, lng: 18.9273409 },
    { lat: 48.8487636, lng: 18.9269279 },
    { lat: 48.84882, lng: 18.926621 },
    { lat: 48.8491663, lng: 18.9266019 },
    { lat: 48.849224, lng: 18.9252788 },
    { lat: 48.8492404, lng: 18.9248494 },
    { lat: 48.8492364, lng: 18.9246827 },
    { lat: 48.8492352, lng: 18.9237341 },
    { lat: 48.8492209, lng: 18.9234547 },
    { lat: 48.8492364, lng: 18.9224537 },
    { lat: 48.8492781, lng: 18.9222344 },
    { lat: 48.8495075, lng: 18.9215577 },
    { lat: 48.8496568, lng: 18.921039 },
    { lat: 48.8496845, lng: 18.9209617 },
    { lat: 48.8496448, lng: 18.9199618 },
    { lat: 48.8496008, lng: 18.9193468 },
    { lat: 48.8495841, lng: 18.9188433 },
    { lat: 48.8496215, lng: 18.9187813 },
    { lat: 48.849723, lng: 18.9182051 },
    { lat: 48.850218, lng: 18.9167963 },
    { lat: 48.8503462, lng: 18.9164795 },
    { lat: 48.8509404, lng: 18.9153564 },
    { lat: 48.8513443, lng: 18.9144351 },
    { lat: 48.8517281, lng: 18.9137811 },
    { lat: 48.8522066, lng: 18.9125992 },
    { lat: 48.8522086, lng: 18.911913 },
    { lat: 48.8521564, lng: 18.9113545 },
    { lat: 48.8522356, lng: 18.910916 },
    { lat: 48.8527473, lng: 18.9082924 },
    { lat: 48.8525674, lng: 18.9067829 },
    { lat: 48.8524982, lng: 18.9061348 },
    { lat: 48.852394, lng: 18.9051585 },
    { lat: 48.8522832, lng: 18.9044179 },
    { lat: 48.8522449, lng: 18.9041617 },
    { lat: 48.8521013, lng: 18.9031432 },
    { lat: 48.8509563, lng: 18.9017642 },
    { lat: 48.8507704, lng: 18.9016819 },
    { lat: 48.8507436, lng: 18.9015744 },
    { lat: 48.8506457, lng: 18.9013367 },
    { lat: 48.8505074, lng: 18.9010827 },
    { lat: 48.8504883, lng: 18.9009306 },
    { lat: 48.8504278, lng: 18.9008239 },
    { lat: 48.8500364, lng: 18.9007804 },
    { lat: 48.8500081, lng: 18.9007999 },
    { lat: 48.8499931, lng: 18.9008339 },
    { lat: 48.8499931, lng: 18.9010777 },
    { lat: 48.8499482, lng: 18.9011463 },
    { lat: 48.8498377, lng: 18.9012603 },
    { lat: 48.8497015, lng: 18.9013257 },
    { lat: 48.8495988, lng: 18.9016364 },
    { lat: 48.8495705, lng: 18.9016974 },
    { lat: 48.8493234, lng: 18.9015832 },
    { lat: 48.8492499, lng: 18.9015375 },
    { lat: 48.8491722, lng: 18.9014506 },
    { lat: 48.8490794, lng: 18.9013126 },
    { lat: 48.8490366, lng: 18.9011823 },
    { lat: 48.8490128, lng: 18.9009818 },
    { lat: 48.8490094, lng: 18.9007803 },
    { lat: 48.8490153, lng: 18.900413 },
    { lat: 48.8490473, lng: 18.9000913 },
    { lat: 48.8490476, lng: 18.8998339 },
    { lat: 48.8490695, lng: 18.8996204 },
    { lat: 48.8491112, lng: 18.8994032 },
    { lat: 48.8491542, lng: 18.8992328 },
    { lat: 48.8492331, lng: 18.899033 },
    { lat: 48.8493772, lng: 18.8987203 },
    { lat: 48.8495025, lng: 18.8983934 },
    { lat: 48.8496142, lng: 18.8976005 },
    { lat: 48.8496343, lng: 18.8972684 },
    { lat: 48.8496468, lng: 18.8965677 },
    { lat: 48.8495162, lng: 18.8964825 },
    { lat: 48.8494877, lng: 18.8965847 },
    { lat: 48.8494437, lng: 18.8965648 },
    { lat: 48.8490045, lng: 18.8965113 },
    { lat: 48.8489285, lng: 18.896609 },
    { lat: 48.8488917, lng: 18.8968349 },
    { lat: 48.8485836, lng: 18.8974476 },
    { lat: 48.8486292, lng: 18.89772 },
    { lat: 48.8484474, lng: 18.8983962 },
    { lat: 48.848326, lng: 18.8983597 },
    { lat: 48.8476823, lng: 18.9001453 },
    { lat: 48.847445, lng: 18.9000654 },
    { lat: 48.8472376, lng: 18.9002466 },
    { lat: 48.8471867, lng: 18.9005308 },
    { lat: 48.8441978, lng: 18.9009945 },
    { lat: 48.8442247, lng: 18.9005689 },
    { lat: 48.8442561, lng: 18.9001951 },
    { lat: 48.8441063, lng: 18.9002184 },
    { lat: 48.8440843, lng: 18.8999643 },
    { lat: 48.8441278, lng: 18.8997799 },
    { lat: 48.8439879, lng: 18.8997147 },
    { lat: 48.8440345, lng: 18.8995653 },
    { lat: 48.8440348, lng: 18.899464 },
    { lat: 48.84409, lng: 18.8988915 },
    { lat: 48.8441991, lng: 18.8983797 },
    { lat: 48.8443536, lng: 18.8984245 },
    { lat: 48.8442994, lng: 18.8987312 },
    { lat: 48.8445454, lng: 18.8990402 },
    { lat: 48.8446097, lng: 18.8982497 },
    { lat: 48.8446482, lng: 18.897903 },
    { lat: 48.8445845, lng: 18.8978045 },
    { lat: 48.8445862, lng: 18.8974403 },
    { lat: 48.8443989, lng: 18.8975027 },
    { lat: 48.8444169, lng: 18.8971527 },
    { lat: 48.8442811, lng: 18.896965 },
    { lat: 48.8440701, lng: 18.8967425 },
    { lat: 48.8439602, lng: 18.8967339 },
    { lat: 48.8436001, lng: 18.8965902 },
    { lat: 48.8433922, lng: 18.8964758 },
    { lat: 48.8433598, lng: 18.8963267 },
    { lat: 48.8375157, lng: 18.8860671 },
    { lat: 48.8372667, lng: 18.8856308 },
    { lat: 48.8370579, lng: 18.8852327 },
    { lat: 48.8366548, lng: 18.8845437 },
    { lat: 48.8365354, lng: 18.884809 },
    { lat: 48.8364219, lng: 18.8850783 },
    { lat: 48.8363854, lng: 18.8851349 },
    { lat: 48.8363051, lng: 18.8853756 },
    { lat: 48.8362836, lng: 18.8854812 },
    { lat: 48.8364209, lng: 18.8858727 },
    { lat: 48.8364995, lng: 18.8858877 },
    { lat: 48.8366629, lng: 18.8861713 },
    { lat: 48.8370141, lng: 18.8863997 },
    { lat: 48.8371433, lng: 18.8865001 },
    { lat: 48.8372322, lng: 18.8865841 },
    { lat: 48.8372958, lng: 18.8866804 },
    { lat: 48.8373432, lng: 18.8868588 },
    { lat: 48.8372319, lng: 18.8872395 },
    { lat: 48.8373845, lng: 18.8874649 },
    { lat: 48.8374659, lng: 18.8876235 },
    { lat: 48.8374234, lng: 18.8879131 },
    { lat: 48.8373402, lng: 18.887987 },
    { lat: 48.8375596, lng: 18.8886784 },
    { lat: 48.8375903, lng: 18.8888158 },
    { lat: 48.8376072, lng: 18.889146 },
    { lat: 48.8377189, lng: 18.889388 },
    { lat: 48.837728, lng: 18.8896067 },
    { lat: 48.837834, lng: 18.8897854 },
    { lat: 48.8378132, lng: 18.8912253 },
    { lat: 48.8378964, lng: 18.891505 },
    { lat: 48.8379168, lng: 18.8916587 },
    { lat: 48.8379517, lng: 18.8918153 },
    { lat: 48.8378429, lng: 18.8922859 },
    { lat: 48.8378035, lng: 18.8925271 },
    { lat: 48.8377802, lng: 18.8929286 },
    { lat: 48.8377086, lng: 18.893053 },
    { lat: 48.837672, lng: 18.8932603 },
    { lat: 48.8377279, lng: 18.8934294 },
    { lat: 48.8378473, lng: 18.8939505 },
    { lat: 48.8379044, lng: 18.8941649 },
    { lat: 48.8379182, lng: 18.8941676 },
    { lat: 48.8379348, lng: 18.8944717 },
    { lat: 48.8379392, lng: 18.8946451 },
    { lat: 48.8379359, lng: 18.8948205 },
    { lat: 48.8379024, lng: 18.8951348 },
    { lat: 48.8378448, lng: 18.8954397 },
    { lat: 48.8378331, lng: 18.8954336 },
    { lat: 48.8376548, lng: 18.896024 },
    { lat: 48.837578, lng: 18.8964154 },
    { lat: 48.8376018, lng: 18.8966627 },
    { lat: 48.8378044, lng: 18.8970931 },
    { lat: 48.8378343, lng: 18.8971046 },
    { lat: 48.8379291, lng: 18.8974385 },
    { lat: 48.838102, lng: 18.8976447 },
    { lat: 48.8381502, lng: 18.8978148 },
    { lat: 48.8381756, lng: 18.897955 },
    { lat: 48.8381868, lng: 18.898085 },
    { lat: 48.8381753, lng: 18.8983681 },
    { lat: 48.8382258, lng: 18.8985372 },
    { lat: 48.8382626, lng: 18.8987719 },
    { lat: 48.8382921, lng: 18.8988869 },
    { lat: 48.8384131, lng: 18.8992879 },
    { lat: 48.8384588, lng: 18.8995205 },
    { lat: 48.8385895, lng: 18.900575 },
    { lat: 48.8386006, lng: 18.900922 },
    { lat: 48.8386389, lng: 18.9011849 },
    { lat: 48.8388321, lng: 18.9022059 },
    { lat: 48.8386818, lng: 18.9026398 },
    { lat: 48.8386803, lng: 18.9031583 },
    { lat: 48.8385713, lng: 18.9033884 },
    { lat: 48.8383921, lng: 18.9035426 },
    { lat: 48.8384603, lng: 18.903706 },
    { lat: 48.8387378, lng: 18.9037321 },
    { lat: 48.8388136, lng: 18.9041785 },
    { lat: 48.8389143, lng: 18.9043592 },
    { lat: 48.8389774, lng: 18.9045125 },
    { lat: 48.8389885, lng: 18.9050155 },
    { lat: 48.8388935, lng: 18.9051656 },
    { lat: 48.8389433, lng: 18.9055663 },
    { lat: 48.8390557, lng: 18.9055381 },
    { lat: 48.8391353, lng: 18.906013 },
    { lat: 48.8391059, lng: 18.9066464 },
    { lat: 48.8390699, lng: 18.9068475 },
    { lat: 48.8389265, lng: 18.9070659 },
    { lat: 48.8389631, lng: 18.907383 },
    { lat: 48.8388134, lng: 18.9076952 },
    { lat: 48.838665, lng: 18.9079481 },
    { lat: 48.8386474, lng: 18.9080197 },
    { lat: 48.8385501, lng: 18.9081685 },
    { lat: 48.8383367, lng: 18.9081392 },
    { lat: 48.8382856, lng: 18.9082214 },
    { lat: 48.8381567, lng: 18.9085229 },
    { lat: 48.8380561, lng: 18.9086479 },
    { lat: 48.8379722, lng: 18.908837 },
    { lat: 48.8378327, lng: 18.9089082 },
    { lat: 48.8376348, lng: 18.9092532 },
    { lat: 48.8376465, lng: 18.909657 },
    { lat: 48.8374909, lng: 18.9100328 },
    { lat: 48.8374471, lng: 18.9101468 },
    { lat: 48.8372773, lng: 18.9104835 },
    { lat: 48.8369333, lng: 18.9105901 },
    { lat: 48.8367825, lng: 18.9108151 },
    { lat: 48.8366347, lng: 18.9110167 },
    { lat: 48.8365581, lng: 18.9116528 },
    { lat: 48.8366537, lng: 18.9119143 },
    { lat: 48.8366309, lng: 18.9122863 },
    { lat: 48.8366319, lng: 18.9126094 },
    { lat: 48.8366173, lng: 18.9127905 },
    { lat: 48.8367426, lng: 18.9132131 },
    { lat: 48.8367495, lng: 18.9133014 },
    { lat: 48.8367516, lng: 18.9136297 },
    { lat: 48.8367675, lng: 18.9139869 },
    { lat: 48.8368526, lng: 18.914172 },
    { lat: 48.8368143, lng: 18.9143271 },
    { lat: 48.8368767, lng: 18.9147314 },
    { lat: 48.8368384, lng: 18.9153905 },
    { lat: 48.8368585, lng: 18.9154562 },
    { lat: 48.8369596, lng: 18.9154468 },
    { lat: 48.8371623, lng: 18.9157498 },
    { lat: 48.837173, lng: 18.9159368 },
    { lat: 48.8371533, lng: 18.9161288 },
    { lat: 48.8371584, lng: 18.9162048 },
    { lat: 48.8371311, lng: 18.9163967 },
    { lat: 48.8371319, lng: 18.9165702 },
    { lat: 48.8370956, lng: 18.9166958 },
    { lat: 48.837492, lng: 18.9175455 },
    { lat: 48.8375331, lng: 18.9178463 },
    { lat: 48.8376408, lng: 18.9179883 },
    { lat: 48.8376478, lng: 18.9185007 },
    { lat: 48.8377439, lng: 18.9187486 },
    { lat: 48.8378334, lng: 18.9189325 },
    { lat: 48.8379698, lng: 18.9189515 },
    { lat: 48.8382123, lng: 18.9192635 },
    { lat: 48.8382822, lng: 18.9194207 },
    { lat: 48.8383242, lng: 18.9194877 },
    { lat: 48.8384089, lng: 18.9195594 },
    { lat: 48.8384951, lng: 18.9200779 },
    { lat: 48.8384076, lng: 18.9201304 },
    { lat: 48.8383887, lng: 18.9202003 },
    { lat: 48.8382984, lng: 18.92031 },
    { lat: 48.8381787, lng: 18.9203731 },
    { lat: 48.8381088, lng: 18.920503 },
    { lat: 48.8380193, lng: 18.9205822 },
    { lat: 48.8377587, lng: 18.9209102 },
    { lat: 48.8375836, lng: 18.9210807 },
    { lat: 48.8374273, lng: 18.9212915 },
    { lat: 48.8374069, lng: 18.9213072 },
    { lat: 48.8372667, lng: 18.9212789 },
    { lat: 48.8371208, lng: 18.9212716 },
    { lat: 48.8370493, lng: 18.9214167 },
    { lat: 48.8369164, lng: 18.9216174 },
    { lat: 48.8368377, lng: 18.9216677 },
    { lat: 48.8367427, lng: 18.9217465 },
    { lat: 48.8366777, lng: 18.9218436 },
    { lat: 48.8366002, lng: 18.9221151 },
    { lat: 48.836539, lng: 18.9222869 },
    { lat: 48.8362437, lng: 18.9225738 },
    { lat: 48.8361625, lng: 18.9226642 },
    { lat: 48.8356504, lng: 18.9232761 },
    { lat: 48.8354312, lng: 18.9235785 },
    { lat: 48.8351829, lng: 18.9241244 },
    { lat: 48.8350183, lng: 18.9245889 },
    { lat: 48.83497, lng: 18.9248325 },
    { lat: 48.8349237, lng: 18.9249425 },
    { lat: 48.8348902, lng: 18.9251468 },
    { lat: 48.8349099, lng: 18.9254455 },
    { lat: 48.834953, lng: 18.925517 },
    { lat: 48.8349929, lng: 18.9257239 },
    { lat: 48.8349654, lng: 18.925847 },
    { lat: 48.835013, lng: 18.9260308 },
    { lat: 48.834996, lng: 18.9262253 },
    { lat: 48.8349975, lng: 18.9265 },
    { lat: 48.834976, lng: 18.9266299 },
    { lat: 48.8349182, lng: 18.9268203 },
    { lat: 48.8349107, lng: 18.9270717 },
    { lat: 48.8348896, lng: 18.9271901 },
    { lat: 48.8349622, lng: 18.9274644 },
    { lat: 48.8349546, lng: 18.9276038 },
    { lat: 48.8346488, lng: 18.9281073 },
    { lat: 48.8345977, lng: 18.9281656 },
    { lat: 48.8346005, lng: 18.9281971 },
    { lat: 48.8345381, lng: 18.9282982 },
    { lat: 48.8345161, lng: 18.9284225 },
    { lat: 48.8344551, lng: 18.928639 },
    { lat: 48.8343575, lng: 18.9287592 },
    { lat: 48.8342611, lng: 18.9288152 },
    { lat: 48.8341675, lng: 18.9287718 },
    { lat: 48.8341134, lng: 18.9288135 },
    { lat: 48.8340589, lng: 18.9287884 },
    { lat: 48.8339775, lng: 18.9289568 },
    { lat: 48.8340306, lng: 18.9290949 },
    { lat: 48.833893, lng: 18.9291353 },
    { lat: 48.8338607, lng: 18.92921 },
    { lat: 48.8338502, lng: 18.9292906 },
    { lat: 48.833016, lng: 18.9317059 },
    { lat: 48.8329634, lng: 18.9318649 },
    { lat: 48.832935, lng: 18.9327028 },
    { lat: 48.8324687, lng: 18.9337286 },
    { lat: 48.8323286, lng: 18.9340335 },
    { lat: 48.8323904, lng: 18.9344253 },
    { lat: 48.8326335, lng: 18.9347726 },
    { lat: 48.8326107, lng: 18.9350126 },
    { lat: 48.8321374, lng: 18.9352147 },
    { lat: 48.8318611, lng: 18.9357455 },
    { lat: 48.8314121, lng: 18.9359794 },
    { lat: 48.831204, lng: 18.9363469 },
    { lat: 48.8307783, lng: 18.9365756 },
    { lat: 48.8305105, lng: 18.9368979 },
    { lat: 48.8301767, lng: 18.9373621 },
    { lat: 48.8299741, lng: 18.9375443 },
    { lat: 48.8296138, lng: 18.9377295 },
    { lat: 48.8291358, lng: 18.9381737 },
    { lat: 48.8285799, lng: 18.9389024 },
    { lat: 48.8282205, lng: 18.9393052 },
    { lat: 48.8279531, lng: 18.9398363 },
    { lat: 48.8274065, lng: 18.9406584 },
    { lat: 48.8270053, lng: 18.9409511 },
    { lat: 48.8267411, lng: 18.941474 },
    { lat: 48.8267174, lng: 18.941694 },
    { lat: 48.8262332, lng: 18.9424168 },
    { lat: 48.826, lng: 18.9429264 },
    { lat: 48.8257853, lng: 18.943159 },
    { lat: 48.8255881, lng: 18.9435839 },
    { lat: 48.8253744, lng: 18.944546 },
    { lat: 48.8253492, lng: 18.944915 },
    { lat: 48.8252015, lng: 18.9454242 },
    { lat: 48.8251715, lng: 18.9458568 },
    { lat: 48.8250077, lng: 18.946691 },
    { lat: 48.8244403, lng: 18.9481277 },
    { lat: 48.8243218, lng: 18.9482792 },
    { lat: 48.8242939, lng: 18.9484603 },
    { lat: 48.8242925, lng: 18.94859 },
    { lat: 48.8242303, lng: 18.9489837 },
    { lat: 48.8240756, lng: 18.9494215 },
    { lat: 48.8247092, lng: 18.9523472 },
    { lat: 48.8243949, lng: 18.9529549 },
    { lat: 48.8242652, lng: 18.9533743 },
    { lat: 48.8240476, lng: 18.9541958 },
    { lat: 48.8236333, lng: 18.9541199 },
    { lat: 48.8236789, lng: 18.9551287 },
    { lat: 48.8232569, lng: 18.9561421 },
    { lat: 48.8230357, lng: 18.956647 },
    { lat: 48.8231573, lng: 18.9575334 },
    { lat: 48.8231455, lng: 18.9583438 },
    { lat: 48.8229826, lng: 18.9590848 },
    { lat: 48.8227141, lng: 18.9601254 },
    { lat: 48.8223885, lng: 18.9601351 },
    { lat: 48.8219843, lng: 18.9599682 },
    { lat: 48.821578, lng: 18.9599357 },
    { lat: 48.8209074, lng: 18.9600094 },
    { lat: 48.8204252, lng: 18.9602133 },
    { lat: 48.8201313, lng: 18.9604411 },
    { lat: 48.8200634, lng: 18.9612165 },
    { lat: 48.8197488, lng: 18.9619042 },
    { lat: 48.8194332, lng: 18.961615 },
    { lat: 48.8189754, lng: 18.961683 },
    { lat: 48.81846, lng: 18.9624989 },
    { lat: 48.8169692, lng: 18.9632713 },
    { lat: 48.8168266, lng: 18.9634832 },
    { lat: 48.8167989, lng: 18.9662251 },
    { lat: 48.81678, lng: 18.9662752 },
    { lat: 48.8161317, lng: 18.9674084 },
    { lat: 48.8157132, lng: 18.9681844 },
    { lat: 48.8152549, lng: 18.9687718 },
    { lat: 48.8152482, lng: 18.9687515 },
    { lat: 48.8146855, lng: 18.968795 },
    { lat: 48.8142576, lng: 18.9689381 },
    { lat: 48.814121, lng: 18.9694216 },
    { lat: 48.8137338, lng: 18.9698146 },
    { lat: 48.814252, lng: 18.970384 },
    { lat: 48.8140053, lng: 18.9708552 },
    { lat: 48.8137776, lng: 18.971145 },
    { lat: 48.8114668, lng: 18.9722916 },
    { lat: 48.8108894, lng: 18.9729772 },
    { lat: 48.8106172, lng: 18.9732806 },
    { lat: 48.8104423, lng: 18.9737216 },
    { lat: 48.8103537, lng: 18.9738846 },
    { lat: 48.8101041, lng: 18.9750815 },
    { lat: 48.8099854, lng: 18.9758765 },
    { lat: 48.8096398, lng: 18.976265 },
    { lat: 48.8093994, lng: 18.9763033 },
    { lat: 48.8090676, lng: 18.9765225 },
    { lat: 48.8086485, lng: 18.9769041 },
    { lat: 48.8081555, lng: 18.977965 },
    { lat: 48.8079922, lng: 18.97844 },
    { lat: 48.8079281, lng: 18.9788874 },
    { lat: 48.8079433, lng: 18.9792748 },
    { lat: 48.8077039, lng: 18.980361 },
    { lat: 48.8076404, lng: 18.9809331 },
    { lat: 48.8075216, lng: 18.9813668 },
    { lat: 48.8073785, lng: 18.9821943 },
    { lat: 48.807015, lng: 18.9836114 },
    { lat: 48.806894, lng: 18.9843164 },
    { lat: 48.8069515, lng: 18.9847204 },
    { lat: 48.8072541, lng: 18.9863364 },
    { lat: 48.806092, lng: 18.991116 },
    { lat: 48.807236, lng: 18.991533 },
    { lat: 48.807442, lng: 18.991557 },
    { lat: 48.807555, lng: 18.991629 },
    { lat: 48.808025, lng: 18.991792 },
    { lat: 48.808545, lng: 18.991826 },
    { lat: 48.809056, lng: 18.991965 },
    { lat: 48.809849, lng: 18.992015 },
    { lat: 48.810866, lng: 18.992212 },
    { lat: 48.811088, lng: 18.992402 },
    { lat: 48.811211, lng: 18.992652 },
    { lat: 48.811491, lng: 18.992805 },
    { lat: 48.811809, lng: 18.992914 },
    { lat: 48.81207, lng: 18.993562 },
    { lat: 48.812365, lng: 18.993659 },
    { lat: 48.812856, lng: 18.993904 },
    { lat: 48.813317, lng: 18.993976 },
    { lat: 48.813835, lng: 18.994279 },
    { lat: 48.814361, lng: 18.994615 },
    { lat: 48.814725, lng: 18.994723 },
    { lat: 48.815563, lng: 18.994943 },
    { lat: 48.816291, lng: 18.995502 },
    { lat: 48.816518, lng: 18.995675 },
    { lat: 48.816938, lng: 18.996728 },
    { lat: 48.816945, lng: 18.997014 },
    { lat: 48.816758, lng: 18.99747 },
    { lat: 48.816726, lng: 18.99768 },
    { lat: 48.816676, lng: 18.998298 },
    { lat: 48.816758, lng: 18.999287 },
    { lat: 48.816847, lng: 18.999786 },
    { lat: 48.816915, lng: 19.000271 },
    { lat: 48.817253, lng: 19.001206 },
    { lat: 48.817272, lng: 19.001488 },
    { lat: 48.817298, lng: 19.001678 },
    { lat: 48.817434, lng: 19.002005 },
    { lat: 48.81756, lng: 19.002174 },
    { lat: 48.817935, lng: 19.002185 },
    { lat: 48.818111, lng: 19.002212 },
    { lat: 48.818259, lng: 19.002241 },
    { lat: 48.818655, lng: 19.002096 },
    { lat: 48.819257, lng: 19.001993 },
    { lat: 48.819843, lng: 19.002399 },
    { lat: 48.820305, lng: 19.002528 },
    { lat: 48.820674, lng: 19.002665 },
    { lat: 48.820964, lng: 19.002956 },
    { lat: 48.821665, lng: 19.003108 },
    { lat: 48.822026, lng: 19.002999 },
    { lat: 48.822171, lng: 19.003109 },
    { lat: 48.822531, lng: 19.003246 },
    { lat: 48.822721, lng: 19.003236 },
    { lat: 48.822923, lng: 19.003049 },
    { lat: 48.823023, lng: 19.002704 },
    { lat: 48.823179, lng: 19.002605 },
    { lat: 48.823247, lng: 19.002577 },
    { lat: 48.823449, lng: 19.002421 },
    { lat: 48.823747, lng: 19.002287 },
    { lat: 48.823938, lng: 19.002146 },
    { lat: 48.824131, lng: 19.002332 },
    { lat: 48.82432, lng: 19.002398 },
    { lat: 48.824515, lng: 19.002336 },
    { lat: 48.824732, lng: 19.00216 },
    { lat: 48.824898, lng: 19.001814 },
    { lat: 48.825161, lng: 19.001386 },
    { lat: 48.825294, lng: 19.001426 },
    { lat: 48.825449, lng: 19.001594 },
    { lat: 48.825594, lng: 19.001758 },
    { lat: 48.82575, lng: 19.002132 },
    { lat: 48.82581, lng: 19.002211 },
    { lat: 48.826234, lng: 19.002626 },
    { lat: 48.826397, lng: 19.002624 },
    { lat: 48.826853, lng: 19.002483 },
    { lat: 48.827049, lng: 19.002485 },
    { lat: 48.827352, lng: 19.002311 },
    { lat: 48.827443, lng: 19.002286 },
    { lat: 48.827758, lng: 19.00232 },
    { lat: 48.828165, lng: 19.002414 },
    { lat: 48.828769, lng: 19.002384 },
    { lat: 48.828953, lng: 19.002397 },
    { lat: 48.829026, lng: 19.002428 },
    { lat: 48.829168, lng: 19.002673 },
    { lat: 48.829436, lng: 19.002668 },
    { lat: 48.829847, lng: 19.002529 },
    { lat: 48.830354, lng: 19.00278 },
    { lat: 48.830632, lng: 19.002684 },
    { lat: 48.830773, lng: 19.002646 },
    { lat: 48.831047, lng: 19.002586 },
    { lat: 48.83117, lng: 19.002435 },
    { lat: 48.831326, lng: 19.002162 },
    { lat: 48.831337, lng: 19.002144 },
    { lat: 48.831497, lng: 19.002121 },
    { lat: 48.831575, lng: 19.002015 },
    { lat: 48.831788, lng: 19.002083 },
    { lat: 48.831842, lng: 19.002102 },
    { lat: 48.832099, lng: 19.002196 },
    { lat: 48.832288, lng: 19.00236 },
    { lat: 48.832524, lng: 19.002576 },
    { lat: 48.832671, lng: 19.002643 },
    { lat: 48.832988, lng: 19.002723 },
    { lat: 48.83317, lng: 19.002847 },
    { lat: 48.833382, lng: 19.003072 },
    { lat: 48.833571, lng: 19.002513 },
    { lat: 48.83389, lng: 19.002051 },
    { lat: 48.834197, lng: 19.00183 },
    { lat: 48.834258, lng: 19.001786 },
    { lat: 48.8345, lng: 19.001744 },
    { lat: 48.834645, lng: 19.001671 },
    { lat: 48.834805, lng: 19.001398 },
    { lat: 48.834918, lng: 19.00094 },
    { lat: 48.835207, lng: 19.000489 },
    { lat: 48.835396, lng: 19.000104 },
    { lat: 48.835518, lng: 18.999592 },
    { lat: 48.835587, lng: 18.999441 },
    { lat: 48.835719, lng: 18.999377 },
    { lat: 48.835879, lng: 18.999334 },
    { lat: 48.8363176, lng: 18.9992748 },
    { lat: 48.836338, lng: 18.999272 },
    { lat: 48.836938, lng: 18.999172 },
    { lat: 48.837067, lng: 18.999281 },
    { lat: 48.837326, lng: 18.999613 },
    { lat: 48.837662, lng: 18.999606 },
    { lat: 48.837808, lng: 18.999534 },
    { lat: 48.837905, lng: 18.999443 },
    { lat: 48.838179, lng: 18.998977 },
    { lat: 48.838318, lng: 18.998834 },
    { lat: 48.838407, lng: 18.99879 },
    { lat: 48.838483, lng: 18.998753 },
    { lat: 48.838622, lng: 18.998684 },
    { lat: 48.8386975, lng: 18.9986059 },
    { lat: 48.838801, lng: 18.998535 },
    { lat: 48.839386, lng: 18.998214 },
    { lat: 48.839611, lng: 18.998033 },
    { lat: 48.839788, lng: 18.997995 },
    { lat: 48.839931, lng: 18.997927 },
    { lat: 48.840109, lng: 18.998019 },
    { lat: 48.840159, lng: 18.998077 },
    { lat: 48.840669, lng: 18.997632 },
    { lat: 48.841014, lng: 18.997172 },
    { lat: 48.841074, lng: 18.997095 },
    { lat: 48.841244, lng: 18.996951 },
    { lat: 48.841555, lng: 18.996877 },
    { lat: 48.841683, lng: 18.996752 },
    { lat: 48.841895, lng: 18.996106 },
    { lat: 48.841952, lng: 18.99581 },
    { lat: 48.842231, lng: 18.995375 },
    { lat: 48.842476, lng: 18.995041 },
    { lat: 48.842648, lng: 18.994686 },
    { lat: 48.842787, lng: 18.994196 },
  ],
  Bodorová: [
    { lat: 48.8965788, lng: 18.8474764 },
    { lat: 48.8965858, lng: 18.8474997 },
    { lat: 48.8973909, lng: 18.850362 },
    { lat: 48.8971772, lng: 18.8505691 },
    { lat: 48.8970735, lng: 18.8506154 },
    { lat: 48.8970678, lng: 18.8506561 },
    { lat: 48.8967561, lng: 18.8528528 },
    { lat: 48.8964438, lng: 18.8550619 },
    { lat: 48.896416, lng: 18.8555074 },
    { lat: 48.8964172, lng: 18.8555947 },
    { lat: 48.8964591, lng: 18.8559033 },
    { lat: 48.896535, lng: 18.8563889 },
    { lat: 48.896579, lng: 18.8564768 },
    { lat: 48.8966865, lng: 18.8574718 },
    { lat: 48.896865, lng: 18.8591593 },
    { lat: 48.8968482, lng: 18.8593147 },
    { lat: 48.896812, lng: 18.859448 },
    { lat: 48.8966871, lng: 18.8597147 },
    { lat: 48.8967735, lng: 18.8598786 },
    { lat: 48.8980549, lng: 18.8593654 },
    { lat: 48.8983388, lng: 18.8592948 },
    { lat: 48.8990166, lng: 18.8590564 },
    { lat: 48.8990955, lng: 18.8590775 },
    { lat: 48.899181, lng: 18.8592053 },
    { lat: 48.8996852, lng: 18.8637396 },
    { lat: 48.8996943, lng: 18.8638074 },
    { lat: 48.8997986, lng: 18.8647343 },
    { lat: 48.9000986, lng: 18.8675186 },
    { lat: 48.9003307, lng: 18.8691601 },
    { lat: 48.9003564, lng: 18.8691531 },
    { lat: 48.9005237, lng: 18.869182 },
    { lat: 48.9022112, lng: 18.870466 },
    { lat: 48.9023994, lng: 18.8704874 },
    { lat: 48.902925, lng: 18.8704252 },
    { lat: 48.9033339, lng: 18.8705696 },
    { lat: 48.90369, lng: 18.8704794 },
    { lat: 48.9040999, lng: 18.8703351 },
    { lat: 48.9052958, lng: 18.8697584 },
    { lat: 48.9060989, lng: 18.8694794 },
    { lat: 48.9063418, lng: 18.8695754 },
    { lat: 48.9080957, lng: 18.8722395 },
    { lat: 48.9091866, lng: 18.8738467 },
    { lat: 48.9099086, lng: 18.8753678 },
    { lat: 48.9101483, lng: 18.8747265 },
    { lat: 48.9103332, lng: 18.8743057 },
    { lat: 48.910648, lng: 18.8735662 },
    { lat: 48.9109871, lng: 18.8727041 },
    { lat: 48.9117266, lng: 18.8708834 },
    { lat: 48.9125523, lng: 18.8690441 },
    { lat: 48.9126011, lng: 18.8689874 },
    { lat: 48.9151095, lng: 18.8633061 },
    { lat: 48.9137399, lng: 18.8618037 },
    { lat: 48.9147423, lng: 18.8602931 },
    { lat: 48.9159619, lng: 18.8583369 },
    { lat: 48.9161661, lng: 18.8579671 },
    { lat: 48.9163097, lng: 18.8577138 },
    { lat: 48.9163681, lng: 18.8576011 },
    { lat: 48.9168306, lng: 18.8581062 },
    { lat: 48.9184254, lng: 18.8599063 },
    { lat: 48.9197588, lng: 18.8614077 },
    { lat: 48.9213079, lng: 18.8631794 },
    { lat: 48.9215992, lng: 18.8627232 },
    { lat: 48.9228844, lng: 18.8615177 },
    { lat: 48.9238422, lng: 18.8605626 },
    { lat: 48.9239776, lng: 18.860435 },
    { lat: 48.924024, lng: 18.8604141 },
    { lat: 48.9242564, lng: 18.8601782 },
    { lat: 48.9243898, lng: 18.8598547 },
    { lat: 48.9245259, lng: 18.8593466 },
    { lat: 48.9244829, lng: 18.8589705 },
    { lat: 48.924509, lng: 18.8588857 },
    { lat: 48.9245842, lng: 18.8587402 },
    { lat: 48.9247787, lng: 18.8583639 },
    { lat: 48.92496, lng: 18.8579031 },
    { lat: 48.9251469, lng: 18.8575683 },
    { lat: 48.9251386, lng: 18.8575571 },
    { lat: 48.9243164, lng: 18.8559587 },
    { lat: 48.9235792, lng: 18.8544564 },
    { lat: 48.9222551, lng: 18.8549062 },
    { lat: 48.921556, lng: 18.8543402 },
    { lat: 48.9211763, lng: 18.8540598 },
    { lat: 48.9187982, lng: 18.8529221 },
    { lat: 48.919271, lng: 18.8492362 },
    { lat: 48.9192622, lng: 18.8491752 },
    { lat: 48.918841, lng: 18.8490109 },
    { lat: 48.9175787, lng: 18.8485622 },
    { lat: 48.9175389, lng: 18.8484902 },
    { lat: 48.9178669, lng: 18.8447997 },
    { lat: 48.9178748, lng: 18.8446919 },
    { lat: 48.9181785, lng: 18.8445002 },
    { lat: 48.9183465, lng: 18.844415 },
    { lat: 48.9184932, lng: 18.8443717 },
    { lat: 48.9186086, lng: 18.8413894 },
    { lat: 48.9186136, lng: 18.8412482 },
    { lat: 48.9186059, lng: 18.8412086 },
    { lat: 48.918793, lng: 18.8407916 },
    { lat: 48.9189657, lng: 18.8404477 },
    { lat: 48.9190814, lng: 18.8404604 },
    { lat: 48.9192217, lng: 18.8404452 },
    { lat: 48.9193095, lng: 18.8404899 },
    { lat: 48.9193604, lng: 18.8404681 },
    { lat: 48.9194224, lng: 18.8403603 },
    { lat: 48.9193893, lng: 18.8402635 },
    { lat: 48.9194024, lng: 18.8400612 },
    { lat: 48.9195592, lng: 18.8399536 },
    { lat: 48.9196215, lng: 18.8400017 },
    { lat: 48.919655, lng: 18.8401014 },
    { lat: 48.9196311, lng: 18.8399683 },
    { lat: 48.9191472, lng: 18.8382182 },
    { lat: 48.9192204, lng: 18.8378703 },
    { lat: 48.9196676, lng: 18.8376862 },
    { lat: 48.9185643, lng: 18.8339487 },
    { lat: 48.9172249, lng: 18.8343891 },
    { lat: 48.9168694, lng: 18.8344701 },
    { lat: 48.9160691, lng: 18.8345193 },
    { lat: 48.9149491, lng: 18.8345781 },
    { lat: 48.9141207, lng: 18.8346521 },
    { lat: 48.913642, lng: 18.8347945 },
    { lat: 48.9130803, lng: 18.8350084 },
    { lat: 48.9130553, lng: 18.8350261 },
    { lat: 48.9129957, lng: 18.8350945 },
    { lat: 48.9123224, lng: 18.8353984 },
    { lat: 48.9116078, lng: 18.835643 },
    { lat: 48.9107669, lng: 18.8359088 },
    { lat: 48.9102589, lng: 18.8361149 },
    { lat: 48.9089868, lng: 18.8366772 },
    { lat: 48.9086318, lng: 18.8367897 },
    { lat: 48.9086568, lng: 18.8368853 },
    { lat: 48.9087542, lng: 18.8371963 },
    { lat: 48.9088596, lng: 18.8375114 },
    { lat: 48.9081274, lng: 18.8381166 },
    { lat: 48.9083078, lng: 18.8386159 },
    { lat: 48.9084171, lng: 18.8388979 },
    { lat: 48.9081175, lng: 18.8391433 },
    { lat: 48.9076994, lng: 18.8394533 },
    { lat: 48.9076619, lng: 18.8394911 },
    { lat: 48.9079821, lng: 18.8403412 },
    { lat: 48.9086553, lng: 18.8420638 },
    { lat: 48.9088261, lng: 18.8423377 },
    { lat: 48.9089016, lng: 18.8424467 },
    { lat: 48.9094399, lng: 18.8436301 },
    { lat: 48.9096909, lng: 18.8443641 },
    { lat: 48.9095125, lng: 18.8446011 },
    { lat: 48.9094491, lng: 18.8449893 },
    { lat: 48.909419, lng: 18.8453439 },
    { lat: 48.9093806, lng: 18.8454094 },
    { lat: 48.9093883, lng: 18.8454489 },
    { lat: 48.9092954, lng: 18.8455263 },
    { lat: 48.9091654, lng: 18.8456084 },
    { lat: 48.9090666, lng: 18.8456381 },
    { lat: 48.9089676, lng: 18.8456205 },
    { lat: 48.9088787, lng: 18.8454913 },
    { lat: 48.9087472, lng: 18.8450642 },
    { lat: 48.908676, lng: 18.845039 },
    { lat: 48.908459, lng: 18.8450978 },
    { lat: 48.9083094, lng: 18.8451916 },
    { lat: 48.9080002, lng: 18.8454918 },
    { lat: 48.9079306, lng: 18.8455201 },
    { lat: 48.9077831, lng: 18.8454563 },
    { lat: 48.9076285, lng: 18.8453256 },
    { lat: 48.9075253, lng: 18.8452916 },
    { lat: 48.9075185, lng: 18.8452243 },
    { lat: 48.9073269, lng: 18.8440951 },
    { lat: 48.9071478, lng: 18.8431805 },
    { lat: 48.9058403, lng: 18.8435378 },
    { lat: 48.9041153, lng: 18.8440444 },
    { lat: 48.9038639, lng: 18.8441539 },
    { lat: 48.9036205, lng: 18.8442452 },
    { lat: 48.9034925, lng: 18.8444282 },
    { lat: 48.9032874, lng: 18.8446307 },
    { lat: 48.9030606, lng: 18.8448003 },
    { lat: 48.9028116, lng: 18.843955 },
    { lat: 48.9024472, lng: 18.8440138 },
    { lat: 48.9021691, lng: 18.8441092 },
    { lat: 48.9017408, lng: 18.8442204 },
    { lat: 48.9007071, lng: 18.8445089 },
    { lat: 48.9002395, lng: 18.844652 },
    { lat: 48.899582, lng: 18.8448448 },
    { lat: 48.8987628, lng: 18.8450515 },
    { lat: 48.8983827, lng: 18.8451573 },
    { lat: 48.8983357, lng: 18.8451622 },
    { lat: 48.897807, lng: 18.8453193 },
    { lat: 48.8972363, lng: 18.8454921 },
    { lat: 48.8967399, lng: 18.8455974 },
    { lat: 48.8963499, lng: 18.8457287 },
    { lat: 48.8960093, lng: 18.8458168 },
    { lat: 48.8959466, lng: 18.8458243 },
    { lat: 48.8965422, lng: 18.8473554 },
    { lat: 48.8965788, lng: 18.8474764 },
  ],
  Abramová: [
    { lat: 48.940142, lng: 18.727164 },
    { lat: 48.9400045, lng: 18.7286897 },
    { lat: 48.9398844, lng: 18.7288506 },
    { lat: 48.939797, lng: 18.7289681 },
    { lat: 48.939099, lng: 18.7292888 },
    { lat: 48.9389636, lng: 18.7293953 },
    { lat: 48.9388567, lng: 18.7305343 },
    { lat: 48.9387411, lng: 18.7314042 },
    { lat: 48.9385288, lng: 18.7325972 },
    { lat: 48.938604, lng: 18.7326576 },
    { lat: 48.9385866, lng: 18.7327749 },
    { lat: 48.9386747, lng: 18.7329902 },
    { lat: 48.9385746, lng: 18.7335307 },
    { lat: 48.93856, lng: 18.7352694 },
    { lat: 48.9384447, lng: 18.7371088 },
    { lat: 48.9382704, lng: 18.7371425 },
    { lat: 48.9380737, lng: 18.7379454 },
    { lat: 48.9380141, lng: 18.7380767 },
    { lat: 48.9375199, lng: 18.7391643 },
    { lat: 48.9372339, lng: 18.7396494 },
    { lat: 48.9370927, lng: 18.7397899 },
    { lat: 48.9367052, lng: 18.740164 },
    { lat: 48.9363511, lng: 18.7411108 },
    { lat: 48.9362551, lng: 18.7417716 },
    { lat: 48.9357127, lng: 18.7412175 },
    { lat: 48.9350906, lng: 18.7408764 },
    { lat: 48.9350615, lng: 18.7408638 },
    { lat: 48.9347849, lng: 18.742228 },
    { lat: 48.9346368, lng: 18.7427832 },
    { lat: 48.9345273, lng: 18.7430371 },
    { lat: 48.9344294, lng: 18.7439223 },
    { lat: 48.9348319, lng: 18.7464306 },
    { lat: 48.9345829, lng: 18.7469303 },
    { lat: 48.9344073, lng: 18.7474526 },
    { lat: 48.9343478, lng: 18.7479078 },
    { lat: 48.9343369, lng: 18.7484306 },
    { lat: 48.9341258, lng: 18.7490282 },
    { lat: 48.9337502, lng: 18.7497777 },
    { lat: 48.9331978, lng: 18.7510708 },
    { lat: 48.9329759, lng: 18.7516793 },
    { lat: 48.9326053, lng: 18.7522869 },
    { lat: 48.9321592, lng: 18.7527242 },
    { lat: 48.9319291, lng: 18.7534148 },
    { lat: 48.931941, lng: 18.7536425 },
    { lat: 48.9316123, lng: 18.7544487 },
    { lat: 48.9316328, lng: 18.7549648 },
    { lat: 48.9318798, lng: 18.7559582 },
    { lat: 48.931879, lng: 18.7567581 },
    { lat: 48.931741, lng: 18.7574182 },
    { lat: 48.9313331, lng: 18.7581109 },
    { lat: 48.9306669, lng: 18.7587404 },
    { lat: 48.9305708, lng: 18.7591447 },
    { lat: 48.9306309, lng: 18.7609192 },
    { lat: 48.9308358, lng: 18.7615597 },
    { lat: 48.9309645, lng: 18.7623153 },
    { lat: 48.9310445, lng: 18.7630233 },
    { lat: 48.9313162, lng: 18.763947 },
    { lat: 48.9315737, lng: 18.7643424 },
    { lat: 48.9321209, lng: 18.7656866 },
    { lat: 48.9322339, lng: 18.7664423 },
    { lat: 48.9321923, lng: 18.7677374 },
    { lat: 48.9319889, lng: 18.7685915 },
    { lat: 48.9312298, lng: 18.7698593 },
    { lat: 48.9306149, lng: 18.7710423 },
    { lat: 48.9300054, lng: 18.7715903 },
    { lat: 48.9297706, lng: 18.7718014 },
    { lat: 48.9295157, lng: 18.7722728 },
    { lat: 48.9292713, lng: 18.77252 },
    { lat: 48.9292145, lng: 18.7726234 },
    { lat: 48.9289099, lng: 18.7731782 },
    { lat: 48.9287737, lng: 18.7738286 },
    { lat: 48.9279392, lng: 18.7759018 },
    { lat: 48.9276847, lng: 18.7762531 },
    { lat: 48.9274835, lng: 18.776803 },
    { lat: 48.9271147, lng: 18.7773441 },
    { lat: 48.9268342, lng: 18.7776507 },
    { lat: 48.9263687, lng: 18.7778272 },
    { lat: 48.926275, lng: 18.7781691 },
    { lat: 48.9261002, lng: 18.7792291 },
    { lat: 48.9259725, lng: 18.7795259 },
    { lat: 48.9259143, lng: 18.7796861 },
    { lat: 48.9257794, lng: 18.7804471 },
    { lat: 48.9257374, lng: 18.7806383 },
    { lat: 48.9253992, lng: 18.7814617 },
    { lat: 48.9253394, lng: 18.7817196 },
    { lat: 48.9253188, lng: 18.7819494 },
    { lat: 48.9253275, lng: 18.7821833 },
    { lat: 48.9254832, lng: 18.7830714 },
    { lat: 48.9254856, lng: 18.7830937 },
    { lat: 48.9255152, lng: 18.7832092 },
    { lat: 48.9255354, lng: 18.7834241 },
    { lat: 48.9255432, lng: 18.7835731 },
    { lat: 48.9255199, lng: 18.7837057 },
    { lat: 48.9250733, lng: 18.7847892 },
    { lat: 48.9248543, lng: 18.7851523 },
    { lat: 48.9246699, lng: 18.785353 },
    { lat: 48.9242938, lng: 18.7856679 },
    { lat: 48.9238103, lng: 18.7860211 },
    { lat: 48.9230307, lng: 18.7866401 },
    { lat: 48.9228758, lng: 18.786805 },
    { lat: 48.922704, lng: 18.7870996 },
    { lat: 48.9226253, lng: 18.7873622 },
    { lat: 48.9224911, lng: 18.7881198 },
    { lat: 48.9220116, lng: 18.7913587 },
    { lat: 48.9219949, lng: 18.7914503 },
    { lat: 48.921263, lng: 18.7946057 },
    { lat: 48.9210638, lng: 18.7950265 },
    { lat: 48.9208445, lng: 18.7953616 },
    { lat: 48.9205984, lng: 18.7958314 },
    { lat: 48.9205027, lng: 18.7960949 },
    { lat: 48.9204435, lng: 18.7963462 },
    { lat: 48.9204058, lng: 18.7966806 },
    { lat: 48.9204042, lng: 18.7970849 },
    { lat: 48.9205842, lng: 18.7982899 },
    { lat: 48.9208374, lng: 18.7997497 },
    { lat: 48.9208643, lng: 18.8002668 },
    { lat: 48.9208398, lng: 18.8006003 },
    { lat: 48.9207579, lng: 18.8011057 },
    { lat: 48.9206315, lng: 18.8016468 },
    { lat: 48.9206011, lng: 18.8017989 },
    { lat: 48.9205742, lng: 18.8020162 },
    { lat: 48.9205764, lng: 18.8022762 },
    { lat: 48.9206037, lng: 18.8024978 },
    { lat: 48.919368, lng: 18.801919 },
    { lat: 48.9191989, lng: 18.8018751 },
    { lat: 48.9190258, lng: 18.8018851 },
    { lat: 48.9183716, lng: 18.802032 },
    { lat: 48.9171571, lng: 18.8021839 },
    { lat: 48.916551, lng: 18.8021293 },
    { lat: 48.9165556, lng: 18.8023274 },
    { lat: 48.9165888, lng: 18.8061083 },
    { lat: 48.9166047, lng: 18.8088001 },
    { lat: 48.9166083, lng: 18.8092614 },
    { lat: 48.9165052, lng: 18.8093372 },
    { lat: 48.9166652, lng: 18.8096823 },
    { lat: 48.9167138, lng: 18.8098809 },
    { lat: 48.9167261, lng: 18.8103139 },
    { lat: 48.9168066, lng: 18.8106435 },
    { lat: 48.9168162, lng: 18.8108112 },
    { lat: 48.9168946, lng: 18.8109735 },
    { lat: 48.9169724, lng: 18.8110144 },
    { lat: 48.9171273, lng: 18.8110669 },
    { lat: 48.9172834, lng: 18.8110346 },
    { lat: 48.9172721, lng: 18.8109033 },
    { lat: 48.9178177, lng: 18.8106569 },
    { lat: 48.9182748, lng: 18.8108643 },
    { lat: 48.9186557, lng: 18.8112279 },
    { lat: 48.9188566, lng: 18.8112978 },
    { lat: 48.9190224, lng: 18.8115364 },
    { lat: 48.9192207, lng: 18.8115324 },
    { lat: 48.9193207, lng: 18.8113294 },
    { lat: 48.9195772, lng: 18.8113173 },
    { lat: 48.9199717, lng: 18.8115114 },
    { lat: 48.920148, lng: 18.8114527 },
    { lat: 48.9203938, lng: 18.8108044 },
    { lat: 48.9210321, lng: 18.8105183 },
    { lat: 48.9216148, lng: 18.8103421 },
    { lat: 48.9222252, lng: 18.8098506 },
    { lat: 48.9225244, lng: 18.809747 },
    { lat: 48.9229802, lng: 18.8100345 },
    { lat: 48.9234938, lng: 18.8101768 },
    { lat: 48.923595, lng: 18.8101697 },
    { lat: 48.9238506, lng: 18.8101179 },
    { lat: 48.9249775, lng: 18.8090192 },
    { lat: 48.9251349, lng: 18.8088594 },
    { lat: 48.9255582, lng: 18.8088661 },
    { lat: 48.9257617, lng: 18.80903 },
    { lat: 48.92582, lng: 18.8091988 },
    { lat: 48.9259128, lng: 18.809334 },
    { lat: 48.9260284, lng: 18.809607 },
    { lat: 48.9260913, lng: 18.8102543 },
    { lat: 48.9262754, lng: 18.810972 },
    { lat: 48.9264252, lng: 18.8114905 },
    { lat: 48.9264413, lng: 18.8118884 },
    { lat: 48.9263138, lng: 18.812126 },
    { lat: 48.9262165, lng: 18.8122904 },
    { lat: 48.9258916, lng: 18.8125502 },
    { lat: 48.9257996, lng: 18.8128621 },
    { lat: 48.9259045, lng: 18.8133393 },
    { lat: 48.9260113, lng: 18.8134001 },
    { lat: 48.9261579, lng: 18.8133553 },
    { lat: 48.926413, lng: 18.814014 },
    { lat: 48.9264086, lng: 18.8140604 },
    { lat: 48.9264653, lng: 18.8143936 },
    { lat: 48.926449, lng: 18.8154674 },
    { lat: 48.9264867, lng: 18.8157791 },
    { lat: 48.9265127, lng: 18.8161077 },
    { lat: 48.9266733, lng: 18.8165981 },
    { lat: 48.9267298, lng: 18.8168398 },
    { lat: 48.9268117, lng: 18.8170241 },
    { lat: 48.9270457, lng: 18.8171205 },
    { lat: 48.9271849, lng: 18.8170444 },
    { lat: 48.927438, lng: 18.8171228 },
    { lat: 48.9277024, lng: 18.817131 },
    { lat: 48.9278745, lng: 18.817361 },
    { lat: 48.9279038, lng: 18.8176419 },
    { lat: 48.9278093, lng: 18.8179939 },
    { lat: 48.9274523, lng: 18.818417 },
    { lat: 48.9269214, lng: 18.8189015 },
    { lat: 48.9265438, lng: 18.8193568 },
    { lat: 48.9264525, lng: 18.8196872 },
    { lat: 48.9265619, lng: 18.8201203 },
    { lat: 48.9270643, lng: 18.8205673 },
    { lat: 48.9272246, lng: 18.8205291 },
    { lat: 48.9275779, lng: 18.8205787 },
    { lat: 48.927807, lng: 18.8206051 },
    { lat: 48.9280927, lng: 18.8208066 },
    { lat: 48.9283514, lng: 18.8211426 },
    { lat: 48.9285872, lng: 18.8213199 },
    { lat: 48.9289663, lng: 18.8213904 },
    { lat: 48.9292464, lng: 18.821286 },
    { lat: 48.929559, lng: 18.8208584 },
    { lat: 48.9300366, lng: 18.8209775 },
    { lat: 48.9300803, lng: 18.821106 },
    { lat: 48.9300875, lng: 18.8211896 },
    { lat: 48.9301029, lng: 18.821368 },
    { lat: 48.9298819, lng: 18.8221277 },
    { lat: 48.9301098, lng: 18.8226313 },
    { lat: 48.9302028, lng: 18.8225334 },
    { lat: 48.9304681, lng: 18.8224741 },
    { lat: 48.9306517, lng: 18.8224909 },
    { lat: 48.9309102, lng: 18.8223226 },
    { lat: 48.9311309, lng: 18.8223999 },
    { lat: 48.931328, lng: 18.8225518 },
    { lat: 48.9316143, lng: 18.8228514 },
    { lat: 48.9318502, lng: 18.8228799 },
    { lat: 48.932283, lng: 18.8234853 },
    { lat: 48.9324673, lng: 18.823909 },
    { lat: 48.9325249, lng: 18.8245412 },
    { lat: 48.9324985, lng: 18.824856 },
    { lat: 48.9322568, lng: 18.8255233 },
    { lat: 48.9322879, lng: 18.8258183 },
    { lat: 48.9324284, lng: 18.8260067 },
    { lat: 48.9326916, lng: 18.8261667 },
    { lat: 48.9329242, lng: 18.8261551 },
    { lat: 48.9330272, lng: 18.8260306 },
    { lat: 48.9330711, lng: 18.8257477 },
    { lat: 48.9330138, lng: 18.8253798 },
    { lat: 48.9330506, lng: 18.8248045 },
    { lat: 48.9331046, lng: 18.8245423 },
    { lat: 48.933229, lng: 18.8243768 },
    { lat: 48.9333944, lng: 18.8243252 },
    { lat: 48.9337722, lng: 18.8244755 },
    { lat: 48.9337767, lng: 18.8244757 },
    { lat: 48.9339651, lng: 18.8248506 },
    { lat: 48.9340665, lng: 18.8252391 },
    { lat: 48.9341275, lng: 18.8255227 },
    { lat: 48.9342795, lng: 18.8255831 },
    { lat: 48.9346585, lng: 18.8255643 },
    { lat: 48.9351486, lng: 18.8256895 },
    { lat: 48.9357577, lng: 18.8258022 },
    { lat: 48.9360106, lng: 18.8256762 },
    { lat: 48.936164, lng: 18.825527 },
    { lat: 48.936328, lng: 18.8253403 },
    { lat: 48.936454, lng: 18.8252686 },
    { lat: 48.9361842, lng: 18.8248482 },
    { lat: 48.9360824, lng: 18.8244945 },
    { lat: 48.9357997, lng: 18.8223261 },
    { lat: 48.9357779, lng: 18.8218829 },
    { lat: 48.9358297, lng: 18.8215564 },
    { lat: 48.9362728, lng: 18.8197066 },
    { lat: 48.9367602, lng: 18.8178431 },
    { lat: 48.9369788, lng: 18.8170559 },
    { lat: 48.9371914, lng: 18.8165495 },
    { lat: 48.937235, lng: 18.816474 },
    { lat: 48.9373435, lng: 18.8163463 },
    { lat: 48.9393419, lng: 18.8122482 },
    { lat: 48.9406201, lng: 18.8089825 },
    { lat: 48.9407481, lng: 18.8087092 },
    { lat: 48.9409313, lng: 18.8084369 },
    { lat: 48.9409376, lng: 18.8084082 },
    { lat: 48.9409419, lng: 18.8084037 },
    { lat: 48.9410201, lng: 18.8082821 },
    { lat: 48.9410566, lng: 18.8082013 },
    { lat: 48.9410747, lng: 18.8081364 },
    { lat: 48.9410808, lng: 18.8080801 },
    { lat: 48.9410764, lng: 18.8079963 },
    { lat: 48.9409596, lng: 18.8071799 },
    { lat: 48.9407551, lng: 18.8061559 },
    { lat: 48.9407083, lng: 18.8059699 },
    { lat: 48.9404928, lng: 18.8047733 },
    { lat: 48.9401596, lng: 18.801769 },
    { lat: 48.940076, lng: 18.8010301 },
    { lat: 48.9400337, lng: 18.8003898 },
    { lat: 48.9399925, lng: 18.7990794 },
    { lat: 48.9402112, lng: 18.7975201 },
    { lat: 48.9402749, lng: 18.7969235 },
    { lat: 48.9401935, lng: 18.796745 },
    { lat: 48.9405767, lng: 18.7956139 },
    { lat: 48.9409197, lng: 18.7957711 },
    { lat: 48.9411403, lng: 18.7959993 },
    { lat: 48.941492, lng: 18.7961286 },
    { lat: 48.9415272, lng: 18.796133 },
    { lat: 48.9420174, lng: 18.7961724 },
    { lat: 48.9420708, lng: 18.7961768 },
    { lat: 48.9420718, lng: 18.796177 },
    { lat: 48.9424627, lng: 18.796364 },
    { lat: 48.9426454, lng: 18.7964511 },
    { lat: 48.9428977, lng: 18.7956428 },
    { lat: 48.9431069, lng: 18.795245 },
    { lat: 48.9438937, lng: 18.7957148 },
    { lat: 48.9443904, lng: 18.7961155 },
    { lat: 48.9449072, lng: 18.7965324 },
    { lat: 48.9451113, lng: 18.7957958 },
    { lat: 48.9452909, lng: 18.7951518 },
    { lat: 48.945347, lng: 18.7949492 },
    { lat: 48.945854, lng: 18.7936647 },
    { lat: 48.9461331, lng: 18.7932011 },
    { lat: 48.9464943, lng: 18.7922752 },
    { lat: 48.9470021, lng: 18.790682 },
    { lat: 48.9469725, lng: 18.7905037 },
    { lat: 48.946968, lng: 18.7904809 },
    { lat: 48.9469385, lng: 18.7903047 },
    { lat: 48.9469415, lng: 18.7899288 },
    { lat: 48.947041, lng: 18.7890034 },
    { lat: 48.9458524, lng: 18.7884173 },
    { lat: 48.9444458, lng: 18.7872626 },
    { lat: 48.9439298, lng: 18.7868396 },
    { lat: 48.9437515, lng: 18.7865999 },
    { lat: 48.9438625, lng: 18.7863279 },
    { lat: 48.9440667, lng: 18.7859844 },
    { lat: 48.9441521, lng: 18.785718 },
    { lat: 48.9443768, lng: 18.7853775 },
    { lat: 48.944495, lng: 18.7850307 },
    { lat: 48.9445465, lng: 18.7849804 },
    { lat: 48.9447357, lng: 18.7847155 },
    { lat: 48.9448918, lng: 18.7843965 },
    { lat: 48.9450707, lng: 18.7840453 },
    { lat: 48.9452624, lng: 18.7835702 },
    { lat: 48.9452999, lng: 18.7830793 },
    { lat: 48.9455004, lng: 18.7818229 },
    { lat: 48.945646, lng: 18.7801796 },
    { lat: 48.9456749, lng: 18.7791583 },
    { lat: 48.9457081, lng: 18.7786944 },
    { lat: 48.9460546, lng: 18.7776597 },
    { lat: 48.9463614, lng: 18.7770814 },
    { lat: 48.9470762, lng: 18.7763858 },
    { lat: 48.947242, lng: 18.7758895 },
    { lat: 48.9469382, lng: 18.7756028 },
    { lat: 48.9467331, lng: 18.7753003 },
    { lat: 48.9468885, lng: 18.7732887 },
    { lat: 48.9466573, lng: 18.7719946 },
    { lat: 48.946442, lng: 18.7707868 },
    { lat: 48.9455291, lng: 18.7705588 },
    { lat: 48.9460431, lng: 18.7694117 },
    { lat: 48.9463361, lng: 18.7691722 },
    { lat: 48.946687, lng: 18.7690697 },
    { lat: 48.9471054, lng: 18.7691986 },
    { lat: 48.9471616, lng: 18.7681403 },
    { lat: 48.9474684, lng: 18.7669539 },
    { lat: 48.947248, lng: 18.7661054 },
    { lat: 48.9468295, lng: 18.7649587 },
    { lat: 48.9464356, lng: 18.7633147 },
    { lat: 48.9464831, lng: 18.7625829 },
    { lat: 48.9464214, lng: 18.7616841 },
    { lat: 48.9464207, lng: 18.7615686 },
    { lat: 48.9464611, lng: 18.7613012 },
    { lat: 48.9465208, lng: 18.7611953 },
    { lat: 48.9465106, lng: 18.761128 },
    { lat: 48.9464934, lng: 18.7608851 },
    { lat: 48.9463864, lng: 18.7607182 },
    { lat: 48.9462186, lng: 18.7598158 },
    { lat: 48.9461556, lng: 18.7589369 },
    { lat: 48.9461444, lng: 18.7586792 },
    { lat: 48.9461722, lng: 18.7583297 },
    { lat: 48.9462524, lng: 18.7580692 },
    { lat: 48.9464621, lng: 18.757359 },
    { lat: 48.9471431, lng: 18.7557223 },
    { lat: 48.9479102, lng: 18.75446 },
    { lat: 48.9486081, lng: 18.7540919 },
    { lat: 48.9498646, lng: 18.7528267 },
    { lat: 48.9502778, lng: 18.7520599 },
    { lat: 48.9508238, lng: 18.7516778 },
    { lat: 48.953008, lng: 18.751317 },
    { lat: 48.952774, lng: 18.751071 },
    { lat: 48.952516, lng: 18.750267 },
    { lat: 48.952337, lng: 18.748754 },
    { lat: 48.95177, lng: 18.746289 },
    { lat: 48.951366, lng: 18.744944 },
    { lat: 48.950836, lng: 18.743182 },
    { lat: 48.950267, lng: 18.742313 },
    { lat: 48.950264, lng: 18.741827 },
    { lat: 48.950267, lng: 18.741539 },
    { lat: 48.949783, lng: 18.741087 },
    { lat: 48.949488, lng: 18.74091 },
    { lat: 48.949154, lng: 18.740551 },
    { lat: 48.947159, lng: 18.737921 },
    { lat: 48.946759, lng: 18.736756 },
    { lat: 48.94643, lng: 18.735999 },
    { lat: 48.945902, lng: 18.735182 },
    { lat: 48.945356, lng: 18.734544 },
    { lat: 48.944154, lng: 18.733341 },
    { lat: 48.94244, lng: 18.731652 },
    { lat: 48.942244, lng: 18.731474 },
    { lat: 48.941881, lng: 18.730996 },
    { lat: 48.941591, lng: 18.729845 },
    { lat: 48.94175, lng: 18.729142 },
    { lat: 48.941592, lng: 18.728504 },
    { lat: 48.941776, lng: 18.727152 },
    { lat: 48.940946, lng: 18.727101 },
    { lat: 48.940142, lng: 18.727164 },
  ],
  Turček: [
    { lat: 48.769386, lng: 18.873509 },
    { lat: 48.76907, lng: 18.875214 },
    { lat: 48.768915, lng: 18.875708 },
    { lat: 48.76913, lng: 18.87623 },
    { lat: 48.769315, lng: 18.877025 },
    { lat: 48.769463, lng: 18.878512 },
    { lat: 48.769508, lng: 18.880615 },
    { lat: 48.769768, lng: 18.881367 },
    { lat: 48.769532, lng: 18.882137 },
    { lat: 48.769399, lng: 18.882562 },
    { lat: 48.76921, lng: 18.883462 },
    { lat: 48.769162, lng: 18.883515 },
    { lat: 48.768725, lng: 18.88409 },
    { lat: 48.768455, lng: 18.884487 },
    { lat: 48.768183, lng: 18.884376 },
    { lat: 48.766245, lng: 18.884044 },
    { lat: 48.765706, lng: 18.883997 },
    { lat: 48.765218, lng: 18.883761 },
    { lat: 48.764865, lng: 18.884029 },
    { lat: 48.764131, lng: 18.884335 },
    { lat: 48.763333, lng: 18.884533 },
    { lat: 48.763002, lng: 18.884899 },
    { lat: 48.76276, lng: 18.88515 },
    { lat: 48.762408, lng: 18.885445 },
    { lat: 48.761955, lng: 18.885078 },
    { lat: 48.761596, lng: 18.884468 },
    { lat: 48.760985, lng: 18.883817 },
    { lat: 48.76089, lng: 18.884053 },
    { lat: 48.760486, lng: 18.885298 },
    { lat: 48.760218, lng: 18.886374 },
    { lat: 48.759922, lng: 18.887572 },
    { lat: 48.759986, lng: 18.888162 },
    { lat: 48.76006, lng: 18.889472 },
    { lat: 48.760254, lng: 18.889542 },
    { lat: 48.760487, lng: 18.889608 },
    { lat: 48.760181, lng: 18.890125 },
    { lat: 48.760139, lng: 18.890204 },
    { lat: 48.759695, lng: 18.890126 },
    { lat: 48.75915, lng: 18.890163 },
    { lat: 48.758718, lng: 18.889787 },
    { lat: 48.758389, lng: 18.890569 },
    { lat: 48.758105, lng: 18.891114 },
    { lat: 48.757683, lng: 18.891181 },
    { lat: 48.757512, lng: 18.891851 },
    { lat: 48.757331, lng: 18.891938 },
    { lat: 48.757176, lng: 18.89208 },
    { lat: 48.757062, lng: 18.892155 },
    { lat: 48.756734, lng: 18.892285 },
    { lat: 48.75666, lng: 18.892337 },
    { lat: 48.756528, lng: 18.892525 },
    { lat: 48.756211, lng: 18.892688 },
    { lat: 48.755849, lng: 18.892837 },
    { lat: 48.755691, lng: 18.892942 },
    { lat: 48.75558, lng: 18.893047 },
    { lat: 48.755452, lng: 18.893151 },
    { lat: 48.75535, lng: 18.893185 },
    { lat: 48.755174, lng: 18.893161 },
    { lat: 48.754897, lng: 18.893145 },
    { lat: 48.75458, lng: 18.893055 },
    { lat: 48.754392, lng: 18.892997 },
    { lat: 48.754147, lng: 18.892943 },
    { lat: 48.75394, lng: 18.892845 },
    { lat: 48.753502, lng: 18.892725 },
    { lat: 48.753379, lng: 18.892656 },
    { lat: 48.753151, lng: 18.892474 },
    { lat: 48.752847, lng: 18.892363 },
    { lat: 48.752715, lng: 18.892319 },
    { lat: 48.752537, lng: 18.892194 },
    { lat: 48.752176, lng: 18.891973 },
    { lat: 48.751972, lng: 18.891866 },
    { lat: 48.751821, lng: 18.891833 },
    { lat: 48.751386, lng: 18.891814 },
    { lat: 48.751139, lng: 18.891837 },
    { lat: 48.751094, lng: 18.891856 },
    { lat: 48.750741, lng: 18.892523 },
    { lat: 48.750473, lng: 18.892936 },
    { lat: 48.750448, lng: 18.893507 },
    { lat: 48.750303, lng: 18.89435 },
    { lat: 48.750253, lng: 18.894728 },
    { lat: 48.750522, lng: 18.894909 },
    { lat: 48.750895, lng: 18.895295 },
    { lat: 48.750993, lng: 18.89552 },
    { lat: 48.75104, lng: 18.895668 },
    { lat: 48.751085, lng: 18.89587 },
    { lat: 48.751144, lng: 18.896083 },
    { lat: 48.751198, lng: 18.896246 },
    { lat: 48.751271, lng: 18.896433 },
    { lat: 48.751379, lng: 18.896747 },
    { lat: 48.751457, lng: 18.896995 },
    { lat: 48.751552, lng: 18.897269 },
    { lat: 48.751811, lng: 18.897925 },
    { lat: 48.751871, lng: 18.898257 },
    { lat: 48.751872, lng: 18.89842 },
    { lat: 48.751865, lng: 18.898614 },
    { lat: 48.751837, lng: 18.898795 },
    { lat: 48.751804, lng: 18.898936 },
    { lat: 48.751749, lng: 18.899064 },
    { lat: 48.75161, lng: 18.899286 },
    { lat: 48.751331, lng: 18.899627 },
    { lat: 48.751179, lng: 18.899905 },
    { lat: 48.751091, lng: 18.900099 },
    { lat: 48.751059, lng: 18.900209 },
    { lat: 48.750991, lng: 18.900697 },
    { lat: 48.750987, lng: 18.900934 },
    { lat: 48.75096, lng: 18.901099 },
    { lat: 48.750858, lng: 18.901251 },
    { lat: 48.750822, lng: 18.901265 },
    { lat: 48.750807, lng: 18.901285 },
    { lat: 48.750789, lng: 18.902013 },
    { lat: 48.750807, lng: 18.90271 },
    { lat: 48.750788, lng: 18.903373 },
    { lat: 48.750697, lng: 18.904003 },
    { lat: 48.750623, lng: 18.904534 },
    { lat: 48.750555, lng: 18.905375 },
    { lat: 48.750199, lng: 18.905417 },
    { lat: 48.750112, lng: 18.905662 },
    { lat: 48.750415, lng: 18.906112 },
    { lat: 48.750497, lng: 18.907147 },
    { lat: 48.750219, lng: 18.907995 },
    { lat: 48.7503, lng: 18.90826 },
    { lat: 48.750619, lng: 18.90927 },
    { lat: 48.750637, lng: 18.909813 },
    { lat: 48.750638, lng: 18.909836 },
    { lat: 48.750324, lng: 18.910137 },
    { lat: 48.749991, lng: 18.910419 },
    { lat: 48.749753, lng: 18.910617 },
    { lat: 48.749609, lng: 18.91074 },
    { lat: 48.749059, lng: 18.911198 },
    { lat: 48.74882, lng: 18.911369 },
    { lat: 48.748567, lng: 18.911536 },
    { lat: 48.7483, lng: 18.911732 },
    { lat: 48.747964, lng: 18.911864 },
    { lat: 48.747896, lng: 18.911888 },
    { lat: 48.747609, lng: 18.911858 },
    { lat: 48.747295, lng: 18.911949 },
    { lat: 48.747143, lng: 18.911994 },
    { lat: 48.746907, lng: 18.91211 },
    { lat: 48.746543, lng: 18.912276 },
    { lat: 48.746291, lng: 18.912372 },
    { lat: 48.745918, lng: 18.912508 },
    { lat: 48.745718, lng: 18.912552 },
    { lat: 48.745582, lng: 18.912705 },
    { lat: 48.74527, lng: 18.913127 },
    { lat: 48.745123, lng: 18.913307 },
    { lat: 48.7449, lng: 18.91348 },
    { lat: 48.744631, lng: 18.913642 },
    { lat: 48.744411, lng: 18.913756 },
    { lat: 48.744264, lng: 18.913896 },
    { lat: 48.744021, lng: 18.91429 },
    { lat: 48.743653, lng: 18.914492 },
    { lat: 48.743361, lng: 18.914601 },
    { lat: 48.74334, lng: 18.91461 },
    { lat: 48.743513, lng: 18.91496 },
    { lat: 48.743497, lng: 18.915022 },
    { lat: 48.743309, lng: 18.915381 },
    { lat: 48.743272, lng: 18.916026 },
    { lat: 48.743303, lng: 18.91636 },
    { lat: 48.743284, lng: 18.916695 },
    { lat: 48.743271, lng: 18.91692 },
    { lat: 48.743202, lng: 18.917437 },
    { lat: 48.743181, lng: 18.917719 },
    { lat: 48.743181, lng: 18.917821 },
    { lat: 48.74318, lng: 18.91788 },
    { lat: 48.743179, lng: 18.91794 },
    { lat: 48.743168, lng: 18.918048 },
    { lat: 48.74315, lng: 18.918178 },
    { lat: 48.743131, lng: 18.918287 },
    { lat: 48.743086, lng: 18.918539 },
    { lat: 48.743043, lng: 18.918725 },
    { lat: 48.743006, lng: 18.918942 },
    { lat: 48.742973, lng: 18.919196 },
    { lat: 48.742935, lng: 18.919483 },
    { lat: 48.742911, lng: 18.919607 },
    { lat: 48.742871, lng: 18.919792 },
    { lat: 48.742846, lng: 18.919946 },
    { lat: 48.742833, lng: 18.920147 },
    { lat: 48.742819, lng: 18.920486 },
    { lat: 48.742811, lng: 18.920883 },
    { lat: 48.7428, lng: 18.921039 },
    { lat: 48.742771, lng: 18.92123 },
    { lat: 48.742677, lng: 18.921614 },
    { lat: 48.742662, lng: 18.921716 },
    { lat: 48.74267, lng: 18.922045 },
    { lat: 48.742633, lng: 18.922222 },
    { lat: 48.742496, lng: 18.922388 },
    { lat: 48.742331, lng: 18.922506 },
    { lat: 48.742262, lng: 18.922577 },
    { lat: 48.742143, lng: 18.922688 },
    { lat: 48.742004, lng: 18.922788 },
    { lat: 48.741819, lng: 18.922955 },
    { lat: 48.741624, lng: 18.92316 },
    { lat: 48.741525, lng: 18.923239 },
    { lat: 48.741438, lng: 18.923278 },
    { lat: 48.741438, lng: 18.9233 },
    { lat: 48.741156, lng: 18.923513 },
    { lat: 48.741154, lng: 18.923515 },
    { lat: 48.741193, lng: 18.923578 },
    { lat: 48.741317, lng: 18.92374 },
    { lat: 48.741467, lng: 18.923873 },
    { lat: 48.741515, lng: 18.924075 },
    { lat: 48.741479, lng: 18.924238 },
    { lat: 48.741544, lng: 18.924637 },
    { lat: 48.741594, lng: 18.924796 },
    { lat: 48.741724, lng: 18.924801 },
    { lat: 48.74186, lng: 18.92481 },
    { lat: 48.742187, lng: 18.924734 },
    { lat: 48.742194, lng: 18.924733 },
    { lat: 48.742284, lng: 18.92475 },
    { lat: 48.74242, lng: 18.92466 },
    { lat: 48.74249, lng: 18.924614 },
    { lat: 48.742529, lng: 18.924862 },
    { lat: 48.742459, lng: 18.924961 },
    { lat: 48.742452, lng: 18.92504 },
    { lat: 48.742432, lng: 18.925263 },
    { lat: 48.742395, lng: 18.925658 },
    { lat: 48.742194, lng: 18.925889 },
    { lat: 48.742139, lng: 18.925908 },
    { lat: 48.742123, lng: 18.925913 },
    { lat: 48.741927, lng: 18.925954 },
    { lat: 48.741922, lng: 18.926036 },
    { lat: 48.741922, lng: 18.926083 },
    { lat: 48.742127, lng: 18.926473 },
    { lat: 48.742198, lng: 18.926472 },
    { lat: 48.742242, lng: 18.926517 },
    { lat: 48.742246, lng: 18.926521 },
    { lat: 48.742263, lng: 18.926559 },
    { lat: 48.742288, lng: 18.926617 },
    { lat: 48.742288, lng: 18.927118 },
    { lat: 48.742288, lng: 18.92717 },
    { lat: 48.742288, lng: 18.927254 },
    { lat: 48.742246, lng: 18.927487 },
    { lat: 48.742288, lng: 18.927738 },
    { lat: 48.742341, lng: 18.927971 },
    { lat: 48.74231, lng: 18.928192 },
    { lat: 48.742391, lng: 18.928439 },
    { lat: 48.742391, lng: 18.928752 },
    { lat: 48.742357, lng: 18.929087 },
    { lat: 48.742351, lng: 18.929144 },
    { lat: 48.742416, lng: 18.929377 },
    { lat: 48.742602, lng: 18.929584 },
    { lat: 48.742706, lng: 18.929605 },
    { lat: 48.742748, lng: 18.929759 },
    { lat: 48.742673, lng: 18.929868 },
    { lat: 48.742557, lng: 18.930068 },
    { lat: 48.742519, lng: 18.930074 },
    { lat: 48.742432, lng: 18.930028 },
    { lat: 48.742428, lng: 18.930026 },
    { lat: 48.742325, lng: 18.930057 },
    { lat: 48.742176, lng: 18.930153 },
    { lat: 48.742401, lng: 18.930613 },
    { lat: 48.742547, lng: 18.930798 },
    { lat: 48.742686, lng: 18.930894 },
    { lat: 48.743049, lng: 18.931041 },
    { lat: 48.743105, lng: 18.931193 },
    { lat: 48.743191, lng: 18.931266 },
    { lat: 48.743283, lng: 18.931322 },
    { lat: 48.743554, lng: 18.93133 },
    { lat: 48.743878, lng: 18.931524 },
    { lat: 48.743898, lng: 18.931904 },
    { lat: 48.743864, lng: 18.932115 },
    { lat: 48.743712, lng: 18.932309 },
    { lat: 48.74359, lng: 18.93276 },
    { lat: 48.743651, lng: 18.932854 },
    { lat: 48.743675, lng: 18.932868 },
    { lat: 48.743781, lng: 18.932933 },
    { lat: 48.743881, lng: 18.932904 },
    { lat: 48.744049, lng: 18.932898 },
    { lat: 48.744158, lng: 18.932811 },
    { lat: 48.74454, lng: 18.932598 },
    { lat: 48.744727, lng: 18.932696 },
    { lat: 48.744909, lng: 18.932723 },
    { lat: 48.745212, lng: 18.932958 },
    { lat: 48.745228, lng: 18.933055 },
    { lat: 48.745231, lng: 18.933072 },
    { lat: 48.745094, lng: 18.933127 },
    { lat: 48.745185, lng: 18.933222 },
    { lat: 48.745159, lng: 18.933276 },
    { lat: 48.745071, lng: 18.933466 },
    { lat: 48.745028, lng: 18.933635 },
    { lat: 48.744969, lng: 18.933688 },
    { lat: 48.744769, lng: 18.933604 },
    { lat: 48.744266, lng: 18.933728 },
    { lat: 48.744085, lng: 18.933745 },
    { lat: 48.744011, lng: 18.93405 },
    { lat: 48.743987, lng: 18.93415 },
    { lat: 48.74412, lng: 18.934194 },
    { lat: 48.744256, lng: 18.93434 },
    { lat: 48.744408, lng: 18.934241 },
    { lat: 48.744511, lng: 18.934357 },
    { lat: 48.744591, lng: 18.934426 },
    { lat: 48.744837, lng: 18.934701 },
    { lat: 48.745009, lng: 18.934858 },
    { lat: 48.745122, lng: 18.934961 },
    { lat: 48.745375, lng: 18.935089 },
    { lat: 48.745419, lng: 18.935256 },
    { lat: 48.745455, lng: 18.935392 },
    { lat: 48.745471, lng: 18.935497 },
    { lat: 48.745487, lng: 18.935674 },
    { lat: 48.7455, lng: 18.93583 },
    { lat: 48.745536, lng: 18.935966 },
    { lat: 48.745492, lng: 18.936079 },
    { lat: 48.745464, lng: 18.936374 },
    { lat: 48.745428, lng: 18.936523 },
    { lat: 48.745301, lng: 18.936712 },
    { lat: 48.745412, lng: 18.937299 },
    { lat: 48.745597, lng: 18.937907 },
    { lat: 48.745734, lng: 18.938358 },
    { lat: 48.746863, lng: 18.940829 },
    { lat: 48.747353, lng: 18.94112 },
    { lat: 48.747547, lng: 18.941181 },
    { lat: 48.747623, lng: 18.941268 },
    { lat: 48.747831, lng: 18.941637 },
    { lat: 48.747876, lng: 18.941825 },
    { lat: 48.74821, lng: 18.942143 },
    { lat: 48.748463, lng: 18.942341 },
    { lat: 48.748673, lng: 18.942537 },
    { lat: 48.748889, lng: 18.942686 },
    { lat: 48.749222, lng: 18.942787 },
    { lat: 48.749348, lng: 18.942797 },
    { lat: 48.74936, lng: 18.942801 },
    { lat: 48.749277, lng: 18.943416 },
    { lat: 48.749155, lng: 18.943706 },
    { lat: 48.749039, lng: 18.943942 },
    { lat: 48.748761, lng: 18.944287 },
    { lat: 48.748443, lng: 18.944639 },
    { lat: 48.74809, lng: 18.944742 },
    { lat: 48.748092, lng: 18.944764 },
    { lat: 48.74807, lng: 18.944927 },
    { lat: 48.748034, lng: 18.945178 },
    { lat: 48.748043, lng: 18.945375 },
    { lat: 48.748044, lng: 18.945412 },
    { lat: 48.748065, lng: 18.9459 },
    { lat: 48.748069, lng: 18.945928 },
    { lat: 48.748201, lng: 18.94679 },
    { lat: 48.748226, lng: 18.94695 },
    { lat: 48.748231, lng: 18.946988 },
    { lat: 48.748232, lng: 18.946995 },
    { lat: 48.748232, lng: 18.947 },
    { lat: 48.748234, lng: 18.947008 },
    { lat: 48.748246, lng: 18.947023 },
    { lat: 48.748247, lng: 18.947024 },
    { lat: 48.748283, lng: 18.947068 },
    { lat: 48.748357, lng: 18.947159 },
    { lat: 48.748379, lng: 18.947187 },
    { lat: 48.748405, lng: 18.947219 },
    { lat: 48.74843, lng: 18.94725 },
    { lat: 48.748552, lng: 18.947406 },
    { lat: 48.748558, lng: 18.947413 },
    { lat: 48.748566, lng: 18.947422 },
    { lat: 48.748451, lng: 18.947692 },
    { lat: 48.748434, lng: 18.947958 },
    { lat: 48.748485, lng: 18.948023 },
    { lat: 48.74847, lng: 18.948246 },
    { lat: 48.748423, lng: 18.948294 },
    { lat: 48.74849, lng: 18.948615 },
    { lat: 48.748492, lng: 18.948913 },
    { lat: 48.74844, lng: 18.949279 },
    { lat: 48.748577, lng: 18.949437 },
    { lat: 48.748467, lng: 18.94956 },
    { lat: 48.748446, lng: 18.949789 },
    { lat: 48.748381, lng: 18.949862 },
    { lat: 48.748302, lng: 18.950126 },
    { lat: 48.748303, lng: 18.950134 },
    { lat: 48.748307, lng: 18.950189 },
    { lat: 48.748321, lng: 18.950401 },
    { lat: 48.748261, lng: 18.95054 },
    { lat: 48.748272, lng: 18.950688 },
    { lat: 48.748347, lng: 18.950934 },
    { lat: 48.748282, lng: 18.951136 },
    { lat: 48.748239, lng: 18.951161 },
    { lat: 48.748195, lng: 18.951378 },
    { lat: 48.748278, lng: 18.951578 },
    { lat: 48.748232, lng: 18.951644 },
    { lat: 48.748327, lng: 18.951774 },
    { lat: 48.748256, lng: 18.951864 },
    { lat: 48.748322, lng: 18.952236 },
    { lat: 48.748325, lng: 18.952488 },
    { lat: 48.748329, lng: 18.952698 },
    { lat: 48.748366, lng: 18.952792 },
    { lat: 48.748353, lng: 18.952918 },
    { lat: 48.74835, lng: 18.952948 },
    { lat: 48.748375, lng: 18.952994 },
    { lat: 48.748478, lng: 18.953189 },
    { lat: 48.748477, lng: 18.95358 },
    { lat: 48.748571, lng: 18.953844 },
    { lat: 48.74858, lng: 18.954109 },
    { lat: 48.748459, lng: 18.95422 },
    { lat: 48.748514, lng: 18.954356 },
    { lat: 48.748505, lng: 18.954748 },
    { lat: 48.748426, lng: 18.955046 },
    { lat: 48.748419, lng: 18.955108 },
    { lat: 48.748302, lng: 18.955632 },
    { lat: 48.748198, lng: 18.955976 },
    { lat: 48.748068, lng: 18.956187 },
    { lat: 48.747882, lng: 18.956359 },
    { lat: 48.74788, lng: 18.956363 },
    { lat: 48.747697, lng: 18.956865 },
    { lat: 48.74757, lng: 18.957086 },
    { lat: 48.747505, lng: 18.957332 },
    { lat: 48.747427, lng: 18.957361 },
    { lat: 48.747398, lng: 18.95758 },
    { lat: 48.747358, lng: 18.957755 },
    { lat: 48.747355, lng: 18.957769 },
    { lat: 48.747318, lng: 18.958035 },
    { lat: 48.747312, lng: 18.958236 },
    { lat: 48.747203, lng: 18.958639 },
    { lat: 48.747136, lng: 18.958892 },
    { lat: 48.747112, lng: 18.959161 },
    { lat: 48.747082, lng: 18.95934 },
    { lat: 48.747061, lng: 18.959465 },
    { lat: 48.747087, lng: 18.959696 },
    { lat: 48.747087, lng: 18.959933 },
    { lat: 48.747127, lng: 18.960151 },
    { lat: 48.747096, lng: 18.960883 },
    { lat: 48.747029, lng: 18.961276 },
    { lat: 48.7469962, lng: 18.9615848 },
    { lat: 48.746992, lng: 18.961624 },
    { lat: 48.746958, lng: 18.96168 },
    { lat: 48.746902, lng: 18.96177 },
    { lat: 48.746889, lng: 18.961779 },
    { lat: 48.746835, lng: 18.961816 },
    { lat: 48.746802, lng: 18.961816 },
    { lat: 48.746735, lng: 18.961853 },
    { lat: 48.746481, lng: 18.962207 },
    { lat: 48.746455, lng: 18.962265 },
    { lat: 48.746427, lng: 18.962328 },
    { lat: 48.745958, lng: 18.963309 },
    { lat: 48.746004, lng: 18.963537 },
    { lat: 48.746046, lng: 18.963713 },
    { lat: 48.746069, lng: 18.963864 },
    { lat: 48.746078, lng: 18.963921 },
    { lat: 48.746089, lng: 18.963996 },
    { lat: 48.746165, lng: 18.964171 },
    { lat: 48.746185, lng: 18.964224 },
    { lat: 48.746235, lng: 18.964339 },
    { lat: 48.746281, lng: 18.96436 },
    { lat: 48.746318, lng: 18.964362 },
    { lat: 48.746393, lng: 18.964511 },
    { lat: 48.74639, lng: 18.964897 },
    { lat: 48.746605, lng: 18.965614 },
    { lat: 48.746587, lng: 18.965818 },
    { lat: 48.746659, lng: 18.966002 },
    { lat: 48.746672, lng: 18.966039 },
    { lat: 48.746682, lng: 18.966066 },
    { lat: 48.746898, lng: 18.966324 },
    { lat: 48.746911, lng: 18.96634 },
    { lat: 48.746905, lng: 18.966419 },
    { lat: 48.746899, lng: 18.966518 },
    { lat: 48.747009, lng: 18.966873 },
    { lat: 48.747173, lng: 18.967088 },
    { lat: 48.747213, lng: 18.967276 },
    { lat: 48.747189, lng: 18.967503 },
    { lat: 48.747289, lng: 18.967673 },
    { lat: 48.74726, lng: 18.967856 },
    { lat: 48.747378, lng: 18.968319 },
    { lat: 48.747471, lng: 18.968706 },
    { lat: 48.747467, lng: 18.969722 },
    { lat: 48.747621, lng: 18.970087 },
    { lat: 48.747622, lng: 18.970243 },
    { lat: 48.74771, lng: 18.97041 },
    { lat: 48.747841, lng: 18.970551 },
    { lat: 48.748051, lng: 18.971246 },
    { lat: 48.748065, lng: 18.971391 },
    { lat: 48.748049, lng: 18.971496 },
    { lat: 48.747901, lng: 18.972197 },
    { lat: 48.747872, lng: 18.972234 },
    { lat: 48.747863, lng: 18.972251 },
    { lat: 48.747783, lng: 18.972916 },
    { lat: 48.747787, lng: 18.97349 },
    { lat: 48.747876, lng: 18.973733 },
    { lat: 48.7479, lng: 18.973805 },
    { lat: 48.74794, lng: 18.973904 },
    { lat: 48.747928, lng: 18.974021 },
    { lat: 48.748036, lng: 18.974414 },
    { lat: 48.748143, lng: 18.974807 },
    { lat: 48.74818, lng: 18.975485 },
    { lat: 48.748057, lng: 18.97641 },
    { lat: 48.748097, lng: 18.976853 },
    { lat: 48.74801, lng: 18.977055 },
    { lat: 48.747946, lng: 18.97778 },
    { lat: 48.747766, lng: 18.978296 },
    { lat: 48.747731, lng: 18.97878 },
    { lat: 48.7476, lng: 18.979024 },
    { lat: 48.747567, lng: 18.979061 },
    { lat: 48.747443, lng: 18.979272 },
    { lat: 48.74731, lng: 18.979749 },
    { lat: 48.747316, lng: 18.979778 },
    { lat: 48.747327, lng: 18.979834 },
    { lat: 48.747094, lng: 18.980306 },
    { lat: 48.746881, lng: 18.980734 },
    { lat: 48.746795, lng: 18.980747 },
    { lat: 48.74668, lng: 18.980924 },
    { lat: 48.746611, lng: 18.980993 },
    { lat: 48.746508, lng: 18.981191 },
    { lat: 48.746318, lng: 18.981276 },
    { lat: 48.746217, lng: 18.981402 },
    { lat: 48.74599, lng: 18.98201 },
    { lat: 48.74587, lng: 18.982115 },
    { lat: 48.74571, lng: 18.982758 },
    { lat: 48.745697, lng: 18.983189 },
    { lat: 48.74555, lng: 18.983724 },
    { lat: 48.745594, lng: 18.984153 },
    { lat: 48.745518, lng: 18.984494 },
    { lat: 48.745474, lng: 18.984889 },
    { lat: 48.74554, lng: 18.985018 },
    { lat: 48.745586, lng: 18.985234 },
    { lat: 48.745605, lng: 18.985378 },
    { lat: 48.745623, lng: 18.985609 },
    { lat: 48.745577, lng: 18.985744 },
    { lat: 48.745593, lng: 18.985925 },
    { lat: 48.745632, lng: 18.986054 },
    { lat: 48.745644, lng: 18.986121 },
    { lat: 48.745624, lng: 18.986189 },
    { lat: 48.745581, lng: 18.986466 },
    { lat: 48.745577, lng: 18.986628 },
    { lat: 48.745624, lng: 18.986795 },
    { lat: 48.745726, lng: 18.98737 },
    { lat: 48.745739, lng: 18.987434 },
    { lat: 48.745745, lng: 18.987505 },
    { lat: 48.745702, lng: 18.987669 },
    { lat: 48.745709, lng: 18.987987 },
    { lat: 48.74562, lng: 18.988219 },
    { lat: 48.745659, lng: 18.988663 },
    { lat: 48.745699, lng: 18.988715 },
    { lat: 48.745773, lng: 18.988813 },
    { lat: 48.745891, lng: 18.98916 },
    { lat: 48.746081, lng: 18.989583 },
    { lat: 48.746185, lng: 18.98976 },
    { lat: 48.74626, lng: 18.989858 },
    { lat: 48.746521, lng: 18.990544 },
    { lat: 48.746704, lng: 18.991172 },
    { lat: 48.746846, lng: 18.991397 },
    { lat: 48.747205, lng: 18.99153 },
    { lat: 48.74739, lng: 18.991783 },
    { lat: 48.747656, lng: 18.992099 },
    { lat: 48.747727, lng: 18.992096 },
    { lat: 48.747903, lng: 18.992132 },
    { lat: 48.748544, lng: 18.992262 },
    { lat: 48.748586, lng: 18.992278 },
    { lat: 48.748833, lng: 18.992398 },
    { lat: 48.748989, lng: 18.992677 },
    { lat: 48.74954, lng: 18.993012 },
    { lat: 48.749439, lng: 18.9933 },
    { lat: 48.749374, lng: 18.993357 },
    { lat: 48.749334, lng: 18.993393 },
    { lat: 48.749327, lng: 18.993404 },
    { lat: 48.749265, lng: 18.993526 },
    { lat: 48.74912, lng: 18.993775 },
    { lat: 48.749012, lng: 18.993903 },
    { lat: 48.748785, lng: 18.994438 },
    { lat: 48.748727, lng: 18.994681 },
    { lat: 48.748566, lng: 18.994936 },
    { lat: 48.74854, lng: 18.995082 },
    { lat: 48.748328, lng: 18.995435 },
    { lat: 48.748314, lng: 18.99547 },
    { lat: 48.748279, lng: 18.995528 },
    { lat: 48.748449, lng: 18.996005 },
    { lat: 48.748779, lng: 18.996583 },
    { lat: 48.74906, lng: 18.997114 },
    { lat: 48.749163, lng: 18.997279 },
    { lat: 48.748994, lng: 18.998313 },
    { lat: 48.749013, lng: 18.998333 },
    { lat: 48.749058, lng: 18.998383 },
    { lat: 48.749521, lng: 18.998731 },
    { lat: 48.7496925, lng: 18.9993731 },
    { lat: 48.749705, lng: 18.99942 },
    { lat: 48.7498604, lng: 18.9996803 },
    { lat: 48.750096, lng: 19.000075 },
    { lat: 48.751352, lng: 18.999936 },
    { lat: 48.752181, lng: 19.000425 },
    { lat: 48.75248, lng: 19.000968 },
    { lat: 48.752627, lng: 19.00126 },
    { lat: 48.752953, lng: 19.001855 },
    { lat: 48.753152, lng: 19.002112 },
    { lat: 48.753331, lng: 19.002516 },
    { lat: 48.753343, lng: 19.002542 },
    { lat: 48.75346, lng: 19.002574 },
    { lat: 48.753693, lng: 19.002633 },
    { lat: 48.754006, lng: 19.002615 },
    { lat: 48.7544, lng: 19.002742 },
    { lat: 48.755031, lng: 19.00271 },
    { lat: 48.755781, lng: 19.002998 },
    { lat: 48.756239, lng: 19.003754 },
    { lat: 48.756309, lng: 19.003777 },
    { lat: 48.756514, lng: 19.003793 },
    { lat: 48.756742, lng: 19.003819 },
    { lat: 48.756925, lng: 19.003839 },
    { lat: 48.757267, lng: 19.004095 },
    { lat: 48.757709, lng: 19.00435 },
    { lat: 48.758203, lng: 19.004342 },
    { lat: 48.758579, lng: 19.004576 },
    { lat: 48.759157, lng: 19.004726 },
    { lat: 48.759659, lng: 19.005049 },
    { lat: 48.760473, lng: 19.0054 },
    { lat: 48.760812, lng: 19.005732 },
    { lat: 48.761105, lng: 19.005879 },
    { lat: 48.761153, lng: 19.005902 },
    { lat: 48.762051, lng: 19.006354 },
    { lat: 48.762075, lng: 19.006358 },
    { lat: 48.762303, lng: 19.006399 },
    { lat: 48.762857, lng: 19.007021 },
    { lat: 48.763033, lng: 19.007556 },
    { lat: 48.76307, lng: 19.008008 },
    { lat: 48.763273, lng: 19.009485 },
    { lat: 48.763685, lng: 19.00999 },
    { lat: 48.764051, lng: 19.010592 },
    { lat: 48.764111, lng: 19.010691 },
    { lat: 48.764286, lng: 19.010767 },
    { lat: 48.765048, lng: 19.01088 },
    { lat: 48.765863, lng: 19.010583 },
    { lat: 48.76606, lng: 19.01069 },
    { lat: 48.766215, lng: 19.01078 },
    { lat: 48.766955, lng: 19.011214 },
    { lat: 48.767365, lng: 19.011746 },
    { lat: 48.767781, lng: 19.012173 },
    { lat: 48.767939, lng: 19.012064 },
    { lat: 48.768125, lng: 19.011937 },
    { lat: 48.768665, lng: 19.011782 },
    { lat: 48.768997, lng: 19.011503 },
    { lat: 48.76932, lng: 19.011412 },
    { lat: 48.769562, lng: 19.01135 },
    { lat: 48.76965, lng: 19.011327 },
    { lat: 48.770007, lng: 19.011235 },
    { lat: 48.770149, lng: 19.011243 },
    { lat: 48.770209, lng: 19.011247 },
    { lat: 48.770273, lng: 19.011251 },
    { lat: 48.770958, lng: 19.011293 },
    { lat: 48.771254, lng: 19.011308 },
    { lat: 48.771537, lng: 19.011176 },
    { lat: 48.77189, lng: 19.011012 },
    { lat: 48.772634, lng: 19.010743 },
    { lat: 48.773143, lng: 19.010646 },
    { lat: 48.773559, lng: 19.010628 },
    { lat: 48.774305, lng: 19.010746 },
    { lat: 48.774816, lng: 19.011198 },
    { lat: 48.774972, lng: 19.011336 },
    { lat: 48.775082, lng: 19.011433 },
    { lat: 48.775792, lng: 19.011616 },
    { lat: 48.776207, lng: 19.011957 },
    { lat: 48.776532, lng: 19.012057 },
    { lat: 48.776568, lng: 19.012087 },
    { lat: 48.777079, lng: 19.012529 },
    { lat: 48.777148, lng: 19.012588 },
    { lat: 48.777552, lng: 19.012779 },
    { lat: 48.778054, lng: 19.013016 },
    { lat: 48.778427, lng: 19.01319 },
    { lat: 48.779408, lng: 19.013552 },
    { lat: 48.780303, lng: 19.013911 },
    { lat: 48.780518, lng: 19.013994 },
    { lat: 48.780899, lng: 19.014141 },
    { lat: 48.781249, lng: 19.014309 },
    { lat: 48.781552, lng: 19.014449 },
    { lat: 48.782841, lng: 19.014423 },
    { lat: 48.782993, lng: 19.014382 },
    { lat: 48.783716, lng: 19.014186 },
    { lat: 48.78463, lng: 19.013904 },
    { lat: 48.784947, lng: 19.013873 },
    { lat: 48.785034, lng: 19.013865 },
    { lat: 48.785474, lng: 19.013824 },
    { lat: 48.786493, lng: 19.014221 },
    { lat: 48.786515, lng: 19.014253 },
    { lat: 48.786711, lng: 19.014542 },
    { lat: 48.78737, lng: 19.015316 },
    { lat: 48.787912, lng: 19.016337 },
    { lat: 48.788547, lng: 19.016188 },
    { lat: 48.78943, lng: 19.016166 },
    { lat: 48.789491, lng: 19.016164 },
    { lat: 48.789556, lng: 19.016163 },
    { lat: 48.790261, lng: 19.016576 },
    { lat: 48.790654, lng: 19.015972 },
    { lat: 48.790772, lng: 19.015783 },
    { lat: 48.791388, lng: 19.015269 },
    { lat: 48.791509, lng: 19.014873 },
    { lat: 48.791745, lng: 19.014696 },
    { lat: 48.792265, lng: 19.014244 },
    { lat: 48.792234, lng: 19.013998 },
    { lat: 48.792236, lng: 19.013457 },
    { lat: 48.792288, lng: 19.012663 },
    { lat: 48.792267, lng: 19.01228 },
    { lat: 48.792243, lng: 19.011842 },
    { lat: 48.7922886, lng: 19.0116755 },
    { lat: 48.792365, lng: 19.011508 },
    { lat: 48.792458, lng: 19.011259 },
    { lat: 48.79278, lng: 19.010974 },
    { lat: 48.793479, lng: 19.010491 },
    { lat: 48.794066, lng: 19.00947 },
    { lat: 48.794284, lng: 19.009049 },
    { lat: 48.794345, lng: 19.008998 },
    { lat: 48.794654, lng: 19.008746 },
    { lat: 48.794709, lng: 19.008317 },
    { lat: 48.794773, lng: 19.007734 },
    { lat: 48.795198, lng: 19.007202 },
    { lat: 48.795289, lng: 19.0071 },
    { lat: 48.795609, lng: 19.005976 },
    { lat: 48.795801, lng: 19.005626 },
    { lat: 48.795977, lng: 19.004961 },
    { lat: 48.796236, lng: 19.004302 },
    { lat: 48.796289, lng: 19.003734 },
    { lat: 48.796327, lng: 19.003078 },
    { lat: 48.796494, lng: 19.002295 },
    { lat: 48.79677, lng: 19.001983 },
    { lat: 48.796845, lng: 19.001893 },
    { lat: 48.797078, lng: 19.001528 },
    { lat: 48.797122, lng: 19.001185 },
    { lat: 48.797182, lng: 19.000863 },
    { lat: 48.797548, lng: 19.000774 },
    { lat: 48.797737, lng: 19.0005 },
    { lat: 48.79778, lng: 19.000438 },
    { lat: 48.797931, lng: 19.000219 },
    { lat: 48.798276, lng: 19.000055 },
    { lat: 48.798658, lng: 18.999563 },
    { lat: 48.798969, lng: 18.99947 },
    { lat: 48.799086, lng: 18.999119 },
    { lat: 48.799351, lng: 18.998502 },
    { lat: 48.79947, lng: 18.998157 },
    { lat: 48.799704, lng: 18.997771 },
    { lat: 48.800066, lng: 18.996955 },
    { lat: 48.800291, lng: 18.996482 },
    { lat: 48.800364, lng: 18.996329 },
    { lat: 48.800877, lng: 18.99553 },
    { lat: 48.801031, lng: 18.995297 },
    { lat: 48.801503, lng: 18.994784 },
    { lat: 48.801766, lng: 18.994441 },
    { lat: 48.801811, lng: 18.994346 },
    { lat: 48.801982, lng: 18.993495 },
    { lat: 48.802129, lng: 18.992766 },
    { lat: 48.802399, lng: 18.992492 },
    { lat: 48.802436, lng: 18.992455 },
    { lat: 48.802944, lng: 18.99229 },
    { lat: 48.803278, lng: 18.992268 },
    { lat: 48.803975, lng: 18.992201 },
    { lat: 48.804591, lng: 18.992135 },
    { lat: 48.804789, lng: 18.992056 },
    { lat: 48.805287, lng: 18.991717 },
    { lat: 48.805647, lng: 18.991463 },
    { lat: 48.806092, lng: 18.991116 },
    { lat: 48.8060327, lng: 18.9907213 },
    { lat: 48.8061457, lng: 18.9902136 },
    { lat: 48.8061388, lng: 18.9891181 },
    { lat: 48.8056518, lng: 18.9853034 },
    { lat: 48.8056368, lng: 18.9852966 },
    { lat: 48.8058233, lng: 18.9842803 },
    { lat: 48.8056279, lng: 18.9837836 },
    { lat: 48.8053283, lng: 18.9828998 },
    { lat: 48.8054829, lng: 18.9821458 },
    { lat: 48.8055969, lng: 18.9809204 },
    { lat: 48.8056056, lng: 18.9801125 },
    { lat: 48.805791, lng: 18.9798303 },
    { lat: 48.8059202, lng: 18.9796668 },
    { lat: 48.8059477, lng: 18.9781607 },
    { lat: 48.8058436, lng: 18.9777514 },
    { lat: 48.8058315, lng: 18.9771344 },
    { lat: 48.8061594, lng: 18.9768227 },
    { lat: 48.8064539, lng: 18.9756014 },
    { lat: 48.806565, lng: 18.9748338 },
    { lat: 48.8066336, lng: 18.9735858 },
    { lat: 48.806837, lng: 18.9726801 },
    { lat: 48.8073607, lng: 18.971826 },
    { lat: 48.8073569, lng: 18.9718157 },
    { lat: 48.8068355, lng: 18.9708708 },
    { lat: 48.80663, lng: 18.9698416 },
    { lat: 48.8065843, lng: 18.969677 },
    { lat: 48.8062399, lng: 18.9692435 },
    { lat: 48.8059988, lng: 18.9685983 },
    { lat: 48.8058241, lng: 18.9681587 },
    { lat: 48.8055697, lng: 18.9672565 },
    { lat: 48.8051504, lng: 18.9666557 },
    { lat: 48.8047225, lng: 18.9658874 },
    { lat: 48.8042545, lng: 18.9656875 },
    { lat: 48.8038459, lng: 18.9652754 },
    { lat: 48.8038396, lng: 18.9652447 },
    { lat: 48.8038998, lng: 18.9646282 },
    { lat: 48.8038755, lng: 18.964439 },
    { lat: 48.8037868, lng: 18.9642697 },
    { lat: 48.803201, lng: 18.9630982 },
    { lat: 48.802914, lng: 18.9622675 },
    { lat: 48.8026585, lng: 18.9619857 },
    { lat: 48.8023857, lng: 18.9616329 },
    { lat: 48.8023028, lng: 18.9607538 },
    { lat: 48.802207, lng: 18.9602689 },
    { lat: 48.8022237, lng: 18.958974 },
    { lat: 48.8026815, lng: 18.9578231 },
    { lat: 48.8026325, lng: 18.9574554 },
    { lat: 48.8028864, lng: 18.9567967 },
    { lat: 48.8028237, lng: 18.9564288 },
    { lat: 48.8027166, lng: 18.9562743 },
    { lat: 48.8027114, lng: 18.956277 },
    { lat: 48.8026924, lng: 18.9562621 },
    { lat: 48.802582, lng: 18.9556186 },
    { lat: 48.8025186, lng: 18.9551242 },
    { lat: 48.8025055, lng: 18.954914 },
    { lat: 48.80251, lng: 18.9547786 },
    { lat: 48.8024217, lng: 18.95453 },
    { lat: 48.8023061, lng: 18.9541192 },
    { lat: 48.8019385, lng: 18.9519857 },
    { lat: 48.8019117, lng: 18.9513941 },
    { lat: 48.8017954, lng: 18.9507953 },
    { lat: 48.8016159, lng: 18.950146 },
    { lat: 48.8014988, lng: 18.9499704 },
    { lat: 48.8013241, lng: 18.9496625 },
    { lat: 48.8011942, lng: 18.9495467 },
    { lat: 48.8009289, lng: 18.9494725 },
    { lat: 48.8007726, lng: 18.9492844 },
    { lat: 48.8006872, lng: 18.9490876 },
    { lat: 48.7999599, lng: 18.9481796 },
    { lat: 48.7999699, lng: 18.9481541 },
    { lat: 48.7998269, lng: 18.9463199 },
    { lat: 48.7996564, lng: 18.9436114 },
    { lat: 48.7997834, lng: 18.9407469 },
    { lat: 48.7997482, lng: 18.9380926 },
    { lat: 48.7996022, lng: 18.9368865 },
    { lat: 48.7995801, lng: 18.936893 },
    { lat: 48.799575, lng: 18.9368403 },
    { lat: 48.7995554, lng: 18.9367611 },
    { lat: 48.7994162, lng: 18.9362645 },
    { lat: 48.7991152, lng: 18.9360246 },
    { lat: 48.7987954, lng: 18.9356786 },
    { lat: 48.7981917, lng: 18.9350524 },
    { lat: 48.7978873, lng: 18.9346285 },
    { lat: 48.7977423, lng: 18.934001 },
    { lat: 48.7974142, lng: 18.9333119 },
    { lat: 48.7973928, lng: 18.9331148 },
    { lat: 48.7972842, lng: 18.9325567 },
    { lat: 48.7971486, lng: 18.9319965 },
    { lat: 48.7970053, lng: 18.9317947 },
    { lat: 48.7967232, lng: 18.931351 },
    { lat: 48.7963698, lng: 18.9311845 },
    { lat: 48.7963588, lng: 18.9311727 },
    { lat: 48.7963448, lng: 18.9311578 },
    { lat: 48.7962634, lng: 18.9308697 },
    { lat: 48.7962492, lng: 18.9306547 },
    { lat: 48.7961876, lng: 18.9304201 },
    { lat: 48.7960447, lng: 18.930273 },
    { lat: 48.7959145, lng: 18.9298659 },
    { lat: 48.7958926, lng: 18.9296775 },
    { lat: 48.7959294, lng: 18.9293853 },
    { lat: 48.7959146, lng: 18.9290076 },
    { lat: 48.7959524, lng: 18.9287064 },
    { lat: 48.7960442, lng: 18.9283841 },
    { lat: 48.796041, lng: 18.9281038 },
    { lat: 48.7961238, lng: 18.9279121 },
    { lat: 48.7963142, lng: 18.927579 },
    { lat: 48.7964113, lng: 18.9271389 },
    { lat: 48.7964572, lng: 18.9270026 },
    { lat: 48.7966374, lng: 18.926778 },
    { lat: 48.796879, lng: 18.9265627 },
    { lat: 48.7969163, lng: 18.9264096 },
    { lat: 48.7969925, lng: 18.9262226 },
    { lat: 48.7971212, lng: 18.9260512 },
    { lat: 48.7971676, lng: 18.9259467 },
    { lat: 48.7972781, lng: 18.9254817 },
    { lat: 48.7972395, lng: 18.9251887 },
    { lat: 48.7972282, lng: 18.9250563 },
    { lat: 48.7973314, lng: 18.9247828 },
    { lat: 48.797452, lng: 18.9244464 },
    { lat: 48.7976628, lng: 18.9240963 },
    { lat: 48.7978673, lng: 18.9238588 },
    { lat: 48.7980031, lng: 18.9237832 },
    { lat: 48.798302, lng: 18.9228352 },
    { lat: 48.798234, lng: 18.9225173 },
    { lat: 48.7982034, lng: 18.9223392 },
    { lat: 48.7980252, lng: 18.9217006 },
    { lat: 48.7977652, lng: 18.9211113 },
    { lat: 48.7977566, lng: 18.9207686 },
    { lat: 48.7977359, lng: 18.9205629 },
    { lat: 48.7977052, lng: 18.9204005 },
    { lat: 48.7976288, lng: 18.9202088 },
    { lat: 48.7975972, lng: 18.9200534 },
    { lat: 48.7975984, lng: 18.9195919 },
    { lat: 48.7975703, lng: 18.9193531 },
    { lat: 48.7975026, lng: 18.9191318 },
    { lat: 48.7974347, lng: 18.9188168 },
    { lat: 48.7973018, lng: 18.9183574 },
    { lat: 48.7972562, lng: 18.9180609 },
    { lat: 48.797229, lng: 18.9177047 },
    { lat: 48.7972768, lng: 18.9173597 },
    { lat: 48.7973514, lng: 18.9170137 },
    { lat: 48.7973621, lng: 18.9166902 },
    { lat: 48.7972918, lng: 18.9165092 },
    { lat: 48.797276, lng: 18.9163344 },
    { lat: 48.7972888, lng: 18.9161634 },
    { lat: 48.7973852, lng: 18.9159325 },
    { lat: 48.7974241, lng: 18.9157871 },
    { lat: 48.7974403, lng: 18.9155788 },
    { lat: 48.797439, lng: 18.915266 },
    { lat: 48.7974596, lng: 18.9150079 },
    { lat: 48.7975074, lng: 18.9145949 },
    { lat: 48.797493, lng: 18.9144239 },
    { lat: 48.7974781, lng: 18.9144421 },
    { lat: 48.7974266, lng: 18.9135705 },
    { lat: 48.7974331, lng: 18.9133423 },
    { lat: 48.7972545, lng: 18.9102518 },
    { lat: 48.7970854, lng: 18.9091733 },
    { lat: 48.7970785, lng: 18.9084031 },
    { lat: 48.7968457, lng: 18.9076622 },
    { lat: 48.7969499, lng: 18.9072333 },
    { lat: 48.796916, lng: 18.904462 },
    { lat: 48.7968163, lng: 18.903771 },
    { lat: 48.7966032, lng: 18.9030024 },
    { lat: 48.7963746, lng: 18.902147 },
    { lat: 48.7961051, lng: 18.9006756 },
    { lat: 48.7952823, lng: 18.8997563 },
    { lat: 48.7946948, lng: 18.8998003 },
    { lat: 48.7938482, lng: 18.8997628 },
    { lat: 48.7933747, lng: 18.8994126 },
    { lat: 48.7933293, lng: 18.8993611 },
    { lat: 48.7927763, lng: 18.8994372 },
    { lat: 48.7926134, lng: 18.8994485 },
    { lat: 48.792259, lng: 18.8993342 },
    { lat: 48.7919345, lng: 18.8994242 },
    { lat: 48.79128, lng: 18.8992401 },
    { lat: 48.7906291, lng: 18.8991031 },
    { lat: 48.7902485, lng: 18.8990259 },
    { lat: 48.7897347, lng: 18.8989444 },
    { lat: 48.7892887, lng: 18.8985359 },
    { lat: 48.789148, lng: 18.8985209 },
    { lat: 48.7890155, lng: 18.8985311 },
    { lat: 48.7884465, lng: 18.8983824 },
    { lat: 48.7883624, lng: 18.898368 },
    { lat: 48.7880459, lng: 18.8983748 },
    { lat: 48.7876057, lng: 18.8981713 },
    { lat: 48.7873403, lng: 18.898098 },
    { lat: 48.7872568, lng: 18.8978969 },
    { lat: 48.787222, lng: 18.8973067 },
    { lat: 48.7871705, lng: 18.8971604 },
    { lat: 48.7872255, lng: 18.8965429 },
    { lat: 48.7872282, lng: 18.8962629 },
    { lat: 48.7872513, lng: 18.8959917 },
    { lat: 48.7871548, lng: 18.8954506 },
    { lat: 48.7869561, lng: 18.8948532 },
    { lat: 48.7868483, lng: 18.8946414 },
    { lat: 48.7866539, lng: 18.8943016 },
    { lat: 48.7865208, lng: 18.8936426 },
    { lat: 48.7862962, lng: 18.8930222 },
    { lat: 48.7861076, lng: 18.8922313 },
    { lat: 48.7859971, lng: 18.8919439 },
    { lat: 48.785647, lng: 18.8910984 },
    { lat: 48.7854057, lng: 18.8906827 },
    { lat: 48.7851141, lng: 18.8899651 },
    { lat: 48.7847274, lng: 18.8892451 },
    { lat: 48.7846087, lng: 18.888712 },
    { lat: 48.7845687, lng: 18.8885025 },
    { lat: 48.7843432, lng: 18.8877568 },
    { lat: 48.7841303, lng: 18.8873896 },
    { lat: 48.7839567, lng: 18.8872384 },
    { lat: 48.7839217, lng: 18.8870405 },
    { lat: 48.7838249, lng: 18.8868445 },
    { lat: 48.7836675, lng: 18.8867655 },
    { lat: 48.7832698, lng: 18.8861169 },
    { lat: 48.7831932, lng: 18.8859421 },
    { lat: 48.7831186, lng: 18.8856309 },
    { lat: 48.7830102, lng: 18.8854978 },
    { lat: 48.7830497, lng: 18.8853655 },
    { lat: 48.7828807, lng: 18.8852138 },
    { lat: 48.7828789, lng: 18.8851136 },
    { lat: 48.782801, lng: 18.8850705 },
    { lat: 48.7827845, lng: 18.8849237 },
    { lat: 48.7826597, lng: 18.8849506 },
    { lat: 48.7826114, lng: 18.8848008 },
    { lat: 48.7826157, lng: 18.8844023 },
    { lat: 48.7825227, lng: 18.8841515 },
    { lat: 48.7824231, lng: 18.8841686 },
    { lat: 48.7822086, lng: 18.8843391 },
    { lat: 48.7820145, lng: 18.8840291 },
    { lat: 48.781996, lng: 18.8838432 },
    { lat: 48.7820321, lng: 18.8838007 },
    { lat: 48.7821176, lng: 18.8838199 },
    { lat: 48.7821623, lng: 18.8837421 },
    { lat: 48.7821043, lng: 18.8836027 },
    { lat: 48.7820139, lng: 18.8834671 },
    { lat: 48.7817595, lng: 18.8833019 },
    { lat: 48.7816393, lng: 18.8829817 },
    { lat: 48.7817136, lng: 18.8828064 },
    { lat: 48.7816072, lng: 18.8826198 },
    { lat: 48.7814391, lng: 18.8828623 },
    { lat: 48.7812851, lng: 18.8830231 },
    { lat: 48.7812239, lng: 18.8829724 },
    { lat: 48.781254, lng: 18.8827679 },
    { lat: 48.7810908, lng: 18.8828204 },
    { lat: 48.7808074, lng: 18.8825935 },
    { lat: 48.7806224, lng: 18.8824773 },
    { lat: 48.7805301, lng: 18.8824396 },
    { lat: 48.7804229, lng: 18.8825037 },
    { lat: 48.7803984, lng: 18.8824004 },
    { lat: 48.7801301, lng: 18.8822286 },
    { lat: 48.7801077, lng: 18.882095 },
    { lat: 48.7798308, lng: 18.8816929 },
    { lat: 48.7798793, lng: 18.8816021 },
    { lat: 48.7798277, lng: 18.8814341 },
    { lat: 48.7798303, lng: 18.8812657 },
    { lat: 48.779855, lng: 18.8811534 },
    { lat: 48.7797168, lng: 18.8810517 },
    { lat: 48.7796626, lng: 18.880854 },
    { lat: 48.7794443, lng: 18.8807985 },
    { lat: 48.7793714, lng: 18.8806942 },
    { lat: 48.7792174, lng: 18.8805304 },
    { lat: 48.779211, lng: 18.8803827 },
    { lat: 48.779377, lng: 18.8801933 },
    { lat: 48.7792915, lng: 18.8798452 },
    { lat: 48.7792902, lng: 18.8796451 },
    { lat: 48.7793628, lng: 18.8792812 },
    { lat: 48.779569, lng: 18.8790363 },
    { lat: 48.7796717, lng: 18.8788651 },
    { lat: 48.7796074, lng: 18.87869 },
    { lat: 48.7794371, lng: 18.8786686 },
    { lat: 48.7793079, lng: 18.8784997 },
    { lat: 48.7792635, lng: 18.8781878 },
    { lat: 48.7793431, lng: 18.8778928 },
    { lat: 48.7791846, lng: 18.8777706 },
    { lat: 48.779335, lng: 18.8773835 },
    { lat: 48.7794473, lng: 18.8772012 },
    { lat: 48.77979, lng: 18.8769781 },
    { lat: 48.7800635, lng: 18.8766412 },
    { lat: 48.7800875, lng: 18.8766255 },
    { lat: 48.7800762, lng: 18.8766029 },
    { lat: 48.7800646, lng: 18.8765798 },
    { lat: 48.7796266, lng: 18.8763481 },
    { lat: 48.7795393, lng: 18.876033 },
    { lat: 48.7796273, lng: 18.8756186 },
    { lat: 48.7798502, lng: 18.8750425 },
    { lat: 48.779726, lng: 18.8749063 },
    { lat: 48.7795187, lng: 18.8747591 },
    { lat: 48.7804641, lng: 18.8723854 },
    { lat: 48.7807008, lng: 18.8726235 },
    { lat: 48.7807944, lng: 18.87234 },
    { lat: 48.7808764, lng: 18.8722023 },
    { lat: 48.7810628, lng: 18.8720555 },
    { lat: 48.7814839, lng: 18.870645 },
    { lat: 48.7813847, lng: 18.8704018 },
    { lat: 48.7814432, lng: 18.8695719 },
    { lat: 48.7811392, lng: 18.8694033 },
    { lat: 48.781118, lng: 18.869104 },
    { lat: 48.7810796, lng: 18.8687303 },
    { lat: 48.7810686, lng: 18.8685389 },
    { lat: 48.7810215, lng: 18.8685423 },
    { lat: 48.7809348, lng: 18.8684145 },
    { lat: 48.7808552, lng: 18.8683792 },
    { lat: 48.7807324, lng: 18.8684037 },
    { lat: 48.78016, lng: 18.8688496 },
    { lat: 48.7800892, lng: 18.8689613 },
    { lat: 48.7799781, lng: 18.8689087 },
    { lat: 48.7797458, lng: 18.8691692 },
    { lat: 48.7791998, lng: 18.8696459 },
    { lat: 48.7787794, lng: 18.8698887 },
    { lat: 48.7786974, lng: 18.8705715 },
    { lat: 48.778523, lng: 18.8718252 },
    { lat: 48.7783744, lng: 18.872659 },
    { lat: 48.7783109, lng: 18.8733121 },
    { lat: 48.7782706, lng: 18.8735343 },
    { lat: 48.7780789, lng: 18.8737521 },
    { lat: 48.7778986, lng: 18.8735782 },
    { lat: 48.7776534, lng: 18.8735417 },
    { lat: 48.7771266, lng: 18.8739331 },
    { lat: 48.7766891, lng: 18.8738671 },
    { lat: 48.7759863, lng: 18.8742768 },
    { lat: 48.7755767, lng: 18.8741411 },
    { lat: 48.7750395, lng: 18.8742406 },
    { lat: 48.7748603, lng: 18.8739818 },
    { lat: 48.7743577, lng: 18.8732193 },
    { lat: 48.7720717, lng: 18.8732989 },
    { lat: 48.769386, lng: 18.873509 },
  ],
  Ivančiná: [
    { lat: 48.9129957, lng: 18.8350945 },
    { lat: 48.9130553, lng: 18.8350261 },
    { lat: 48.9130803, lng: 18.8350084 },
    { lat: 48.913642, lng: 18.8347945 },
    { lat: 48.9141207, lng: 18.8346521 },
    { lat: 48.9149491, lng: 18.8345781 },
    { lat: 48.9160691, lng: 18.8345193 },
    { lat: 48.9168694, lng: 18.8344701 },
    { lat: 48.9172249, lng: 18.8343891 },
    { lat: 48.9185643, lng: 18.8339487 },
    { lat: 48.919563, lng: 18.8336375 },
    { lat: 48.9194813, lng: 18.8335185 },
    { lat: 48.9195472, lng: 18.8334422 },
    { lat: 48.918561, lng: 18.8322966 },
    { lat: 48.9176607, lng: 18.8308089 },
    { lat: 48.9170806, lng: 18.8274452 },
    { lat: 48.9170944, lng: 18.8273604 },
    { lat: 48.9169428, lng: 18.8272856 },
    { lat: 48.9168082, lng: 18.8272551 },
    { lat: 48.9167405, lng: 18.8272796 },
    { lat: 48.9166641, lng: 18.8274336 },
    { lat: 48.9166331, lng: 18.8276015 },
    { lat: 48.9165567, lng: 18.8278232 },
    { lat: 48.9164264, lng: 18.8279395 },
    { lat: 48.9163194, lng: 18.8282047 },
    { lat: 48.9163193, lng: 18.8285087 },
    { lat: 48.9161825, lng: 18.8288918 },
    { lat: 48.9161517, lng: 18.8291005 },
    { lat: 48.9160694, lng: 18.8293631 },
    { lat: 48.9160599, lng: 18.8293531 },
    { lat: 48.9161294, lng: 18.827649 },
    { lat: 48.9162022, lng: 18.8262891 },
    { lat: 48.9163725, lng: 18.8239187 },
    { lat: 48.9163579, lng: 18.8238136 },
    { lat: 48.9166313, lng: 18.8196469 },
    { lat: 48.9167235, lng: 18.8177518 },
    { lat: 48.9167283, lng: 18.8176043 },
    { lat: 48.9168184, lng: 18.817127 },
    { lat: 48.9169812, lng: 18.8145276 },
    { lat: 48.9170851, lng: 18.8129653 },
    { lat: 48.9172943, lng: 18.8111573 },
    { lat: 48.9172834, lng: 18.8110346 },
    { lat: 48.9171273, lng: 18.8110669 },
    { lat: 48.9169724, lng: 18.8110144 },
    { lat: 48.9168946, lng: 18.8109735 },
    { lat: 48.9168162, lng: 18.8108112 },
    { lat: 48.9168066, lng: 18.8106435 },
    { lat: 48.9167261, lng: 18.8103139 },
    { lat: 48.9167138, lng: 18.8098809 },
    { lat: 48.9166652, lng: 18.8096823 },
    { lat: 48.9165052, lng: 18.8093372 },
    { lat: 48.9163773, lng: 18.8091991 },
    { lat: 48.916365, lng: 18.8092841 },
    { lat: 48.9161393, lng: 18.8091748 },
    { lat: 48.9160703, lng: 18.8090386 },
    { lat: 48.9160039, lng: 18.8089795 },
    { lat: 48.9159952, lng: 18.8087669 },
    { lat: 48.9159001, lng: 18.8086101 },
    { lat: 48.9158694, lng: 18.8085693 },
    { lat: 48.9158334, lng: 18.8085184 },
    { lat: 48.915705, lng: 18.8084341 },
    { lat: 48.9148254, lng: 18.8085182 },
    { lat: 48.9145713, lng: 18.8082748 },
    { lat: 48.914523, lng: 18.8081687 },
    { lat: 48.9144316, lng: 18.8078423 },
    { lat: 48.9142964, lng: 18.8076283 },
    { lat: 48.9141117, lng: 18.8075246 },
    { lat: 48.9139416, lng: 18.8075266 },
    { lat: 48.9139264, lng: 18.8075372 },
    { lat: 48.9137761, lng: 18.8076422 },
    { lat: 48.913739, lng: 18.8079011 },
    { lat: 48.9137491, lng: 18.8080238 },
    { lat: 48.9137601, lng: 18.8081571 },
    { lat: 48.9138158, lng: 18.8084702 },
    { lat: 48.913985, lng: 18.8086884 },
    { lat: 48.9140067, lng: 18.8089321 },
    { lat: 48.913882, lng: 18.8094378 },
    { lat: 48.9138411, lng: 18.809561 },
    { lat: 48.913779, lng: 18.8096261 },
    { lat: 48.9137214, lng: 18.8096438 },
    { lat: 48.9136384, lng: 18.8096118 },
    { lat: 48.9135799, lng: 18.8094417 },
    { lat: 48.9135594, lng: 18.80922 },
    { lat: 48.9134253, lng: 18.8088755 },
    { lat: 48.9131977, lng: 18.8087482 },
    { lat: 48.9131869, lng: 18.8086918 },
    { lat: 48.9131688, lng: 18.8086267 },
    { lat: 48.9130677, lng: 18.8082009 },
    { lat: 48.9130045, lng: 18.8080901 },
    { lat: 48.9128896, lng: 18.808025 },
    { lat: 48.9125845, lng: 18.8080389 },
    { lat: 48.9123394, lng: 18.8081823 },
    { lat: 48.9121637, lng: 18.808379 },
    { lat: 48.912191, lng: 18.8087331 },
    { lat: 48.9120845, lng: 18.8087908 },
    { lat: 48.9119695, lng: 18.8088532 },
    { lat: 48.9118537, lng: 18.8088696 },
    { lat: 48.911627, lng: 18.8089017 },
    { lat: 48.9115153, lng: 18.8092015 },
    { lat: 48.9114812, lng: 18.8093732 },
    { lat: 48.9114603, lng: 18.8094448 },
    { lat: 48.9113368, lng: 18.8098678 },
    { lat: 48.9111307, lng: 18.8100495 },
    { lat: 48.9110568, lng: 18.8100594 },
    { lat: 48.9104732, lng: 18.8098923 },
    { lat: 48.9100757, lng: 18.8097964 },
    { lat: 48.9099107, lng: 18.8096944 },
    { lat: 48.9098148, lng: 18.8096351 },
    { lat: 48.9096811, lng: 18.8089091 },
    { lat: 48.9095308, lng: 18.808555 },
    { lat: 48.909743, lng: 18.8080177 },
    { lat: 48.9096406, lng: 18.8078493 },
    { lat: 48.9095923, lng: 18.8078282 },
    { lat: 48.9089826, lng: 18.8080728 },
    { lat: 48.9089098, lng: 18.8081733 },
    { lat: 48.9088514, lng: 18.808286 },
    { lat: 48.9088287, lng: 18.8083034 },
    { lat: 48.9085858, lng: 18.8081214 },
    { lat: 48.9084187, lng: 18.8070534 },
    { lat: 48.9083407, lng: 18.8067029 },
    { lat: 48.9083227, lng: 18.8065964 },
    { lat: 48.9083312, lng: 18.8065464 },
    { lat: 48.9083529, lng: 18.8064189 },
    { lat: 48.9089954, lng: 18.8057832 },
    { lat: 48.9094057, lng: 18.805671 },
    { lat: 48.9095297, lng: 18.8055625 },
    { lat: 48.9099357, lng: 18.8054354 },
    { lat: 48.9103, lng: 18.8050441 },
    { lat: 48.9109888, lng: 18.8050204 },
    { lat: 48.9113396, lng: 18.8046744 },
    { lat: 48.9115952, lng: 18.8041265 },
    { lat: 48.9116999, lng: 18.8036687 },
    { lat: 48.9117216, lng: 18.8031556 },
    { lat: 48.9115966, lng: 18.8028103 },
    { lat: 48.911315, lng: 18.8027695 },
    { lat: 48.911191, lng: 18.8020802 },
    { lat: 48.9110435, lng: 18.801648 },
    { lat: 48.9107356, lng: 18.8016483 },
    { lat: 48.9105226, lng: 18.8014552 },
    { lat: 48.9102763, lng: 18.8012721 },
    { lat: 48.9100138, lng: 18.8014495 },
    { lat: 48.9098182, lng: 18.8016776 },
    { lat: 48.9097657, lng: 18.8016685 },
    { lat: 48.9096772, lng: 18.8015697 },
    { lat: 48.9100619, lng: 18.799343 },
    { lat: 48.9098981, lng: 18.7991181 },
    { lat: 48.9098506, lng: 18.7990106 },
    { lat: 48.9096958, lng: 18.7989821 },
    { lat: 48.9094564, lng: 18.7987825 },
    { lat: 48.9093407, lng: 18.7987247 },
    { lat: 48.9089639, lng: 18.7987634 },
    { lat: 48.9087753, lng: 18.7986942 },
    { lat: 48.9086595, lng: 18.7987655 },
    { lat: 48.9085783, lng: 18.7991767 },
    { lat: 48.9083669, lng: 18.7998584 },
    { lat: 48.9082514, lng: 18.7998948 },
    { lat: 48.9081039, lng: 18.7997443 },
    { lat: 48.9079914, lng: 18.7992954 },
    { lat: 48.9077609, lng: 18.7992664 },
    { lat: 48.9075613, lng: 18.7991817 },
    { lat: 48.9073685, lng: 18.7987908 },
    { lat: 48.9071629, lng: 18.7985233 },
    { lat: 48.9069687, lng: 18.7983458 },
    { lat: 48.9070048, lng: 18.7980006 },
    { lat: 48.9069025, lng: 18.7976616 },
    { lat: 48.9067799, lng: 18.7975988 },
    { lat: 48.9066451, lng: 18.7976953 },
    { lat: 48.9066209, lng: 18.7981212 },
    { lat: 48.9063033, lng: 18.7988192 },
    { lat: 48.9061289, lng: 18.7989135 },
    { lat: 48.9060015, lng: 18.7988496 },
    { lat: 48.9059928, lng: 18.7988779 },
    { lat: 48.9059713, lng: 18.7988274 },
    { lat: 48.9060168, lng: 18.7987362 },
    { lat: 48.906035, lng: 18.7986889 },
    { lat: 48.9060437, lng: 18.7986336 },
    { lat: 48.9060404, lng: 18.7985756 },
    { lat: 48.9060229, lng: 18.798522 },
    { lat: 48.9057629, lng: 18.7979026 },
    { lat: 48.9057513, lng: 18.7978511 },
    { lat: 48.905748, lng: 18.7978051 },
    { lat: 48.9057671, lng: 18.7976509 },
    { lat: 48.9057735, lng: 18.7975434 },
    { lat: 48.9057599, lng: 18.7974169 },
    { lat: 48.9057439, lng: 18.7973454 },
    { lat: 48.9057035, lng: 18.7973023 },
    { lat: 48.9056554, lng: 18.7972763 },
    { lat: 48.9052949, lng: 18.7972432 },
    { lat: 48.9052432, lng: 18.7972235 },
    { lat: 48.9051996, lng: 18.7971919 },
    { lat: 48.9051742, lng: 18.7971389 },
    { lat: 48.9051274, lng: 18.7968149 },
    { lat: 48.9050929, lng: 18.796753 },
    { lat: 48.9050665, lng: 18.796741 },
    { lat: 48.9050348, lng: 18.7967411 },
    { lat: 48.9050004, lng: 18.7967616 },
    { lat: 48.9044795, lng: 18.796954 },
    { lat: 48.9044366, lng: 18.7969701 },
    { lat: 48.9043849, lng: 18.796985 },
    { lat: 48.9043383, lng: 18.796973 },
    { lat: 48.9042903, lng: 18.7969545 },
    { lat: 48.9042344, lng: 18.7969097 },
    { lat: 48.9041695, lng: 18.7968105 },
    { lat: 48.9041423, lng: 18.7967051 },
    { lat: 48.9041427, lng: 18.7966343 },
    { lat: 48.9041555, lng: 18.7965841 },
    { lat: 48.9041965, lng: 18.7965498 },
    { lat: 48.9042783, lng: 18.7964921 },
    { lat: 48.9043204, lng: 18.7964173 },
    { lat: 48.904332, lng: 18.7963175 },
    { lat: 48.9043224, lng: 18.7962329 },
    { lat: 48.9042654, lng: 18.7960475 },
    { lat: 48.9042539, lng: 18.7958021 },
    { lat: 48.9042649, lng: 18.7956006 },
    { lat: 48.9043421, lng: 18.7953698 },
    { lat: 48.9043724, lng: 18.7953218 },
    { lat: 48.9044269, lng: 18.7952798 },
    { lat: 48.9044954, lng: 18.7952065 },
    { lat: 48.9045685, lng: 18.7951598 },
    { lat: 48.9046011, lng: 18.7951511 },
    { lat: 48.9046581, lng: 18.7951454 },
    { lat: 48.9047289, lng: 18.7951312 },
    { lat: 48.9049259, lng: 18.7950917 },
    { lat: 48.9049798, lng: 18.7950686 },
    { lat: 48.9050113, lng: 18.7950281 },
    { lat: 48.9050297, lng: 18.7949787 },
    { lat: 48.9050374, lng: 18.7948995 },
    { lat: 48.9050258, lng: 18.7948232 },
    { lat: 48.9050025, lng: 18.7947578 },
    { lat: 48.9049577, lng: 18.7946814 },
    { lat: 48.9048677, lng: 18.7944433 },
    { lat: 48.9047596, lng: 18.7942482 },
    { lat: 48.9046826, lng: 18.7941514 },
    { lat: 48.9045584, lng: 18.7940732 },
    { lat: 48.9043413, lng: 18.7939842 },
    { lat: 48.9042937, lng: 18.7939597 },
    { lat: 48.904241, lng: 18.7938923 },
    { lat: 48.9041811, lng: 18.7938704 },
    { lat: 48.9040931, lng: 18.7938626 },
    { lat: 48.9040309, lng: 18.7938375 },
    { lat: 48.9038152, lng: 18.7937161 },
    { lat: 48.9037082, lng: 18.7936943 },
    { lat: 48.9033865, lng: 18.7935273 },
    { lat: 48.9032871, lng: 18.7935016 },
    { lat: 48.903102, lng: 18.7936485 },
    { lat: 48.9030868, lng: 18.7937884 },
    { lat: 48.9032168, lng: 18.7940337 },
    { lat: 48.9032053, lng: 18.7943347 },
    { lat: 48.9032426, lng: 18.7945926 },
    { lat: 48.9032283, lng: 18.7948166 },
    { lat: 48.9030075, lng: 18.7949513 },
    { lat: 48.9023442, lng: 18.7953322 },
    { lat: 48.9022945, lng: 18.7954005 },
    { lat: 48.9022714, lng: 18.7955009 },
    { lat: 48.9022888, lng: 18.7955948 },
    { lat: 48.9022987, lng: 18.7957044 },
    { lat: 48.9022796, lng: 18.7958289 },
    { lat: 48.9022511, lng: 18.7959582 },
    { lat: 48.9022161, lng: 18.7960556 },
    { lat: 48.9021726, lng: 18.7961199 },
    { lat: 48.9021022, lng: 18.7961991 },
    { lat: 48.9020621, lng: 18.7962215 },
    { lat: 48.9019787, lng: 18.7962209 },
    { lat: 48.9015851, lng: 18.7968255 },
    { lat: 48.9014534, lng: 18.7968735 },
    { lat: 48.9013853, lng: 18.7968 },
    { lat: 48.9013376, lng: 18.7966652 },
    { lat: 48.9013137, lng: 18.7966591 },
    { lat: 48.9013286, lng: 18.7967508 },
    { lat: 48.9013159, lng: 18.7968294 },
    { lat: 48.9012901, lng: 18.7973719 },
    { lat: 48.9012305, lng: 18.7974841 },
    { lat: 48.9011143, lng: 18.7975944 },
    { lat: 48.9010849, lng: 18.7976331 },
    { lat: 48.9010162, lng: 18.7978972 },
    { lat: 48.9011109, lng: 18.7982006 },
    { lat: 48.9010991, lng: 18.79845 },
    { lat: 48.9009849, lng: 18.7987443 },
    { lat: 48.900907, lng: 18.7988311 },
    { lat: 48.9009155, lng: 18.7991043 },
    { lat: 48.9008609, lng: 18.7994162 },
    { lat: 48.9007546, lng: 18.799844 },
    { lat: 48.9005883, lng: 18.800118 },
    { lat: 48.900517, lng: 18.8002857 },
    { lat: 48.9004389, lng: 18.8005384 },
    { lat: 48.9004223, lng: 18.800654 },
    { lat: 48.9004222, lng: 18.8007802 },
    { lat: 48.9004761, lng: 18.8010167 },
    { lat: 48.9005957, lng: 18.8012995 },
    { lat: 48.9008256, lng: 18.8015573 },
    { lat: 48.9007868, lng: 18.801806 },
    { lat: 48.9008787, lng: 18.8018825 },
    { lat: 48.9008367, lng: 18.8022463 },
    { lat: 48.900849, lng: 18.8022649 },
    { lat: 48.9008908, lng: 18.8023337 },
    { lat: 48.9008788, lng: 18.8024014 },
    { lat: 48.9008225, lng: 18.802429 },
    { lat: 48.9007658, lng: 18.8025096 },
    { lat: 48.9007292, lng: 18.8025642 },
    { lat: 48.9006461, lng: 18.8025716 },
    { lat: 48.9006249, lng: 18.8025731 },
    { lat: 48.9005501, lng: 18.8025476 },
    { lat: 48.9005253, lng: 18.8026527 },
    { lat: 48.9005437, lng: 18.8028548 },
    { lat: 48.9005444, lng: 18.8028694 },
    { lat: 48.9005436, lng: 18.8029179 },
    { lat: 48.9005368, lng: 18.8031366 },
    { lat: 48.9005356, lng: 18.8031984 },
    { lat: 48.9005325, lng: 18.8033121 },
    { lat: 48.9005055, lng: 18.8034017 },
    { lat: 48.9005019, lng: 18.8035276 },
    { lat: 48.9004999, lng: 18.8035299 },
    { lat: 48.9004556, lng: 18.8035865 },
    { lat: 48.9003709, lng: 18.8036934 },
    { lat: 48.9003343, lng: 18.8037301 },
    { lat: 48.9002037, lng: 18.8038647 },
    { lat: 48.9001435, lng: 18.8039196 },
    { lat: 48.8999452, lng: 18.8041408 },
    { lat: 48.8997282, lng: 18.8042804 },
    { lat: 48.8996281, lng: 18.804264 },
    { lat: 48.899579, lng: 18.8042516 },
    { lat: 48.8995, lng: 18.8042955 },
    { lat: 48.8993986, lng: 18.8044496 },
    { lat: 48.8993768, lng: 18.8044841 },
    { lat: 48.8993138, lng: 18.8045544 },
    { lat: 48.8992447, lng: 18.8046332 },
    { lat: 48.899134, lng: 18.8047361 },
    { lat: 48.8989542, lng: 18.8047727 },
    { lat: 48.8989194, lng: 18.8047758 },
    { lat: 48.8989003, lng: 18.804777 },
    { lat: 48.8984007, lng: 18.8048153 },
    { lat: 48.8983042, lng: 18.8048695 },
    { lat: 48.8983157, lng: 18.804895 },
    { lat: 48.8983201, lng: 18.8049156 },
    { lat: 48.898378, lng: 18.8052061 },
    { lat: 48.8986211, lng: 18.8067364 },
    { lat: 48.8989732, lng: 18.8086045 },
    { lat: 48.8989901, lng: 18.8086928 },
    { lat: 48.898996, lng: 18.8087606 },
    { lat: 48.8994233, lng: 18.8113215 },
    { lat: 48.9000319, lng: 18.8151998 },
    { lat: 48.9000406, lng: 18.8152346 },
    { lat: 48.900049, lng: 18.8152691 },
    { lat: 48.9006704, lng: 18.814945 },
    { lat: 48.9009459, lng: 18.8168374 },
    { lat: 48.9010664, lng: 18.8176614 },
    { lat: 48.9014885, lng: 18.8204954 },
    { lat: 48.9014952, lng: 18.8205373 },
    { lat: 48.9023037, lng: 18.8202762 },
    { lat: 48.9032035, lng: 18.8195154 },
    { lat: 48.9032923, lng: 18.8195284 },
    { lat: 48.9035663, lng: 18.8197685 },
    { lat: 48.9045528, lng: 18.8203091 },
    { lat: 48.9046349, lng: 18.8203258 },
    { lat: 48.9050608, lng: 18.820123 },
    { lat: 48.905068, lng: 18.8201573 },
    { lat: 48.9049988, lng: 18.8216473 },
    { lat: 48.9049518, lng: 18.8218479 },
    { lat: 48.9047141, lng: 18.8227441 },
    { lat: 48.9049678, lng: 18.8237461 },
    { lat: 48.9051336, lng: 18.8242689 },
    { lat: 48.9053421, lng: 18.8247215 },
    { lat: 48.9056477, lng: 18.8252661 },
    { lat: 48.9058037, lng: 18.8251202 },
    { lat: 48.9058667, lng: 18.8252693 },
    { lat: 48.9058164, lng: 18.8257341 },
    { lat: 48.9056406, lng: 18.8263265 },
    { lat: 48.9059065, lng: 18.8272586 },
    { lat: 48.905934, lng: 18.8274145 },
    { lat: 48.9063843, lng: 18.828991 },
    { lat: 48.9069325, lng: 18.8310623 },
    { lat: 48.9071009, lng: 18.8307888 },
    { lat: 48.9075911, lng: 18.8303031 },
    { lat: 48.9078692, lng: 18.8299928 },
    { lat: 48.9082942, lng: 18.829379 },
    { lat: 48.9085649, lng: 18.828922 },
    { lat: 48.9087125, lng: 18.8285965 },
    { lat: 48.9087441, lng: 18.828549 },
    { lat: 48.9091155, lng: 18.8279928 },
    { lat: 48.9095847, lng: 18.8273833 },
    { lat: 48.9098247, lng: 18.8268181 },
    { lat: 48.910524, lng: 18.825689 },
    { lat: 48.910564, lng: 18.8256537 },
    { lat: 48.9106018, lng: 18.826953 },
    { lat: 48.9107526, lng: 18.8277706 },
    { lat: 48.9109598, lng: 18.8285263 },
    { lat: 48.9117263, lng: 18.8290239 },
    { lat: 48.9117371, lng: 18.8290592 },
    { lat: 48.9117673, lng: 18.8291635 },
    { lat: 48.9121309, lng: 18.830435 },
    { lat: 48.9123849, lng: 18.8317035 },
    { lat: 48.9124037, lng: 18.8317837 },
    { lat: 48.9127494, lng: 18.8336691 },
    { lat: 48.9129648, lng: 18.8346701 },
    { lat: 48.912882, lng: 18.8349255 },
    { lat: 48.9129957, lng: 18.8350945 },
  ],
  TurčianskeTeplice: [
    { lat: 48.8671853, lng: 18.819989 },
    { lat: 48.8671747, lng: 18.8201135 },
    { lat: 48.8665209, lng: 18.8197678 },
    { lat: 48.8657977, lng: 18.8194773 },
    { lat: 48.8649516, lng: 18.8192553 },
    { lat: 48.8641505, lng: 18.8192678 },
    { lat: 48.8639157, lng: 18.8193051 },
    { lat: 48.8636257, lng: 18.8194772 },
    { lat: 48.8633044, lng: 18.8196288 },
    { lat: 48.8619236, lng: 18.8199858 },
    { lat: 48.8616882, lng: 18.8200347 },
    { lat: 48.8612441, lng: 18.8199956 },
    { lat: 48.8608526, lng: 18.8201412 },
    { lat: 48.860184, lng: 18.8199936 },
    { lat: 48.8593815, lng: 18.8197373 },
    { lat: 48.8589462, lng: 18.8192596 },
    { lat: 48.8587177, lng: 18.8188539 },
    { lat: 48.8585663, lng: 18.8186786 },
    { lat: 48.8583809, lng: 18.8185786 },
    { lat: 48.8581105, lng: 18.8184956 },
    { lat: 48.8578373, lng: 18.8185131 },
    { lat: 48.8571664, lng: 18.8189484 },
    { lat: 48.8571679, lng: 18.8186721 },
    { lat: 48.8570935, lng: 18.8186499 },
    { lat: 48.8568691, lng: 18.8182373 },
    { lat: 48.856711, lng: 18.8179736 },
    { lat: 48.8563487, lng: 18.8165129 },
    { lat: 48.856177, lng: 18.8157062 },
    { lat: 48.8560959, lng: 18.8149907 },
    { lat: 48.8560603, lng: 18.8138519 },
    { lat: 48.855912, lng: 18.8129497 },
    { lat: 48.8557571, lng: 18.8122284 },
    { lat: 48.8557625, lng: 18.8116785 },
    { lat: 48.8558678, lng: 18.8111907 },
    { lat: 48.8556533, lng: 18.8104926 },
    { lat: 48.8551993, lng: 18.8098046 },
    { lat: 48.8546787, lng: 18.8089225 },
    { lat: 48.854621, lng: 18.8089459 },
    { lat: 48.8546451, lng: 18.8092426 },
    { lat: 48.8546847, lng: 18.8095825 },
    { lat: 48.8546787, lng: 18.8098987 },
    { lat: 48.8545173, lng: 18.8100039 },
    { lat: 48.8544328, lng: 18.8098793 },
    { lat: 48.8543851, lng: 18.8095878 },
    { lat: 48.8542172, lng: 18.8096122 },
    { lat: 48.854153, lng: 18.8096782 },
    { lat: 48.8540056, lng: 18.8099655 },
    { lat: 48.8538508, lng: 18.8100051 },
    { lat: 48.8536944, lng: 18.8099687 },
    { lat: 48.8537255, lng: 18.8105225 },
    { lat: 48.8538203, lng: 18.8108721 },
    { lat: 48.853851, lng: 18.8110496 },
    { lat: 48.8537916, lng: 18.8112515 },
    { lat: 48.8537773, lng: 18.8112808 },
    { lat: 48.8537419, lng: 18.811314 },
    { lat: 48.8530108, lng: 18.8118503 },
    { lat: 48.8529504, lng: 18.8117691 },
    { lat: 48.8530243, lng: 18.8111548 },
    { lat: 48.8530302, lng: 18.8110728 },
    { lat: 48.8526912, lng: 18.8111488 },
    { lat: 48.8524092, lng: 18.8109909 },
    { lat: 48.8523148, lng: 18.8112793 },
    { lat: 48.8522852, lng: 18.8113376 },
    { lat: 48.8522028, lng: 18.8112705 },
    { lat: 48.8521774, lng: 18.8111328 },
    { lat: 48.8522493, lng: 18.8108238 },
    { lat: 48.8519691, lng: 18.8107262 },
    { lat: 48.8519254, lng: 18.8111855 },
    { lat: 48.8518039, lng: 18.8113013 },
    { lat: 48.8517472, lng: 18.8112293 },
    { lat: 48.8517229, lng: 18.8108709 },
    { lat: 48.8515669, lng: 18.8107972 },
    { lat: 48.8514593, lng: 18.8112458 },
    { lat: 48.8512795, lng: 18.8112999 },
    { lat: 48.8513338, lng: 18.8114134 },
    { lat: 48.8513884, lng: 18.8115275 },
    { lat: 48.8515038, lng: 18.81147 },
    { lat: 48.8515785, lng: 18.8115835 },
    { lat: 48.851578, lng: 18.8117705 },
    { lat: 48.851498, lng: 18.8119906 },
    { lat: 48.8514344, lng: 18.8121973 },
    { lat: 48.85127, lng: 18.8122445 },
    { lat: 48.8512172, lng: 18.8119253 },
    { lat: 48.8509463, lng: 18.8114643 },
    { lat: 48.8507098, lng: 18.8116415 },
    { lat: 48.850681, lng: 18.8117151 },
    { lat: 48.8506635, lng: 18.8116825 },
    { lat: 48.8506365, lng: 18.8115536 },
    { lat: 48.8506595, lng: 18.8114337 },
    { lat: 48.8507058, lng: 18.8113698 },
    { lat: 48.8506189, lng: 18.8112789 },
    { lat: 48.8505816, lng: 18.8112794 },
    { lat: 48.8505348, lng: 18.8111801 },
    { lat: 48.8505257, lng: 18.8106194 },
    { lat: 48.8504842, lng: 18.8104249 },
    { lat: 48.8504103, lng: 18.8102866 },
    { lat: 48.8503415, lng: 18.8102841 },
    { lat: 48.8502812, lng: 18.8104707 },
    { lat: 48.8502316, lng: 18.8105761 },
    { lat: 48.8500647, lng: 18.8107282 },
    { lat: 48.8500407, lng: 18.8107668 },
    { lat: 48.8501028, lng: 18.8109195 },
    { lat: 48.8501763, lng: 18.8109877 },
    { lat: 48.8501906, lng: 18.8111141 },
    { lat: 48.8501383, lng: 18.8112572 },
    { lat: 48.8500242, lng: 18.8114026 },
    { lat: 48.8499421, lng: 18.8114507 },
    { lat: 48.8498162, lng: 18.8114797 },
    { lat: 48.8497261, lng: 18.8115459 },
    { lat: 48.8495591, lng: 18.8116339 },
    { lat: 48.849525, lng: 18.8117139 },
    { lat: 48.8494979, lng: 18.8118826 },
    { lat: 48.8495255, lng: 18.8120916 },
    { lat: 48.8494898, lng: 18.812272 },
    { lat: 48.849403, lng: 18.8123382 },
    { lat: 48.8493185, lng: 18.8123179 },
    { lat: 48.8493456, lng: 18.8124485 },
    { lat: 48.8493211, lng: 18.812604 },
    { lat: 48.8492616, lng: 18.8126734 },
    { lat: 48.8491061, lng: 18.8126325 },
    { lat: 48.8489215, lng: 18.8125311 },
    { lat: 48.8488465, lng: 18.8125196 },
    { lat: 48.848772, lng: 18.8125826 },
    { lat: 48.8486908, lng: 18.812584 },
    { lat: 48.8485562, lng: 18.8124268 },
    { lat: 48.8485, lng: 18.812233 },
    { lat: 48.8484857, lng: 18.8120897 },
    { lat: 48.8483522, lng: 18.81184 },
    { lat: 48.8483045, lng: 18.8117723 },
    { lat: 48.8482537, lng: 18.8117909 },
    { lat: 48.8481178, lng: 18.81193 },
    { lat: 48.8479467, lng: 18.812152 },
    { lat: 48.8478515, lng: 18.8121673 },
    { lat: 48.8478218, lng: 18.8122682 },
    { lat: 48.8478618, lng: 18.8123637 },
    { lat: 48.8479925, lng: 18.8124064 },
    { lat: 48.8481156, lng: 18.8124959 },
    { lat: 48.8481614, lng: 18.8126193 },
    { lat: 48.8481721, lng: 18.8127154 },
    { lat: 48.8481498, lng: 18.8129129 },
    { lat: 48.8481021, lng: 18.8130818 },
    { lat: 48.8480278, lng: 18.8131484 },
    { lat: 48.8477701, lng: 18.8131372 },
    { lat: 48.8476753, lng: 18.8130727 },
    { lat: 48.8476114, lng: 18.8130792 },
    { lat: 48.8474489, lng: 18.8131398 },
    { lat: 48.8473239, lng: 18.8131204 },
    { lat: 48.8472127, lng: 18.8130867 },
    { lat: 48.8471604, lng: 18.8130998 },
    { lat: 48.8470565, lng: 18.8132278 },
    { lat: 48.8469222, lng: 18.8132038 },
    { lat: 48.8468455, lng: 18.8132043 },
    { lat: 48.8467869, lng: 18.8132285 },
    { lat: 48.8467281, lng: 18.8132871 },
    { lat: 48.8466063, lng: 18.8135669 },
    { lat: 48.8465532, lng: 18.8138049 },
    { lat: 48.8465195, lng: 18.8140696 },
    { lat: 48.846432, lng: 18.8142089 },
    { lat: 48.846419, lng: 18.814305 },
    { lat: 48.8464517, lng: 18.8143473 },
    { lat: 48.8464468, lng: 18.8144484 },
    { lat: 48.8464076, lng: 18.8145394 },
    { lat: 48.8463266, lng: 18.8146726 },
    { lat: 48.8463022, lng: 18.8147489 },
    { lat: 48.8463545, lng: 18.8149088 },
    { lat: 48.8463556, lng: 18.8150343 },
    { lat: 48.846318, lng: 18.8152486 },
    { lat: 48.8462679, lng: 18.8153018 },
    { lat: 48.8461725, lng: 18.8153125 },
    { lat: 48.8461472, lng: 18.8154072 },
    { lat: 48.8462298, lng: 18.8153916 },
    { lat: 48.8462845, lng: 18.8154033 },
    { lat: 48.846357, lng: 18.815452 },
    { lat: 48.8463713, lng: 18.8155136 },
    { lat: 48.8463697, lng: 18.8156098 },
    { lat: 48.8463322, lng: 18.8157567 },
    { lat: 48.8462669, lng: 18.8158683 },
    { lat: 48.8460827, lng: 18.8160582 },
    { lat: 48.8459779, lng: 18.8162104 },
    { lat: 48.8459179, lng: 18.8163142 },
    { lat: 48.8458314, lng: 18.8165594 },
    { lat: 48.845801, lng: 18.816665 },
    { lat: 48.8457735, lng: 18.8167013 },
    { lat: 48.8457381, lng: 18.8168007 },
    { lat: 48.845692, lng: 18.8168929 },
    { lat: 48.8456089, lng: 18.8169432 },
    { lat: 48.8453388, lng: 18.8169937 },
    { lat: 48.8452695, lng: 18.8170274 },
    { lat: 48.8452028, lng: 18.8171539 },
    { lat: 48.8452173, lng: 18.8172144 },
    { lat: 48.845279, lng: 18.8173431 },
    { lat: 48.8452905, lng: 18.8174042 },
    { lat: 48.8453103, lng: 18.8175087 },
    { lat: 48.8453792, lng: 18.817488 },
    { lat: 48.8454595, lng: 18.8175378 },
    { lat: 48.8455426, lng: 18.8176826 },
    { lat: 48.8456528, lng: 18.8178291 },
    { lat: 48.8457284, lng: 18.8180474 },
    { lat: 48.8457765, lng: 18.8180624 },
    { lat: 48.8458047, lng: 18.8181289 },
    { lat: 48.8457714, lng: 18.8182005 },
    { lat: 48.8457643, lng: 18.8184334 },
    { lat: 48.8457443, lng: 18.8186119 },
    { lat: 48.8456249, lng: 18.818769 },
    { lat: 48.845529, lng: 18.8189211 },
    { lat: 48.8454609, lng: 18.8192146 },
    { lat: 48.8453918, lng: 18.8193596 },
    { lat: 48.8451655, lng: 18.8197145 },
    { lat: 48.845006, lng: 18.8197915 },
    { lat: 48.8448765, lng: 18.8197955 },
    { lat: 48.8446888, lng: 18.8198487 },
    { lat: 48.8446057, lng: 18.8196818 },
    { lat: 48.8445988, lng: 18.8195276 },
    { lat: 48.844668, lng: 18.819408 },
    { lat: 48.844694, lng: 18.8193408 },
    { lat: 48.8446891, lng: 18.8193138 },
    { lat: 48.8446397, lng: 18.8192953 },
    { lat: 48.8445903, lng: 18.8192547 },
    { lat: 48.8445472, lng: 18.8192931 },
    { lat: 48.8444908, lng: 18.8194033 },
    { lat: 48.8444151, lng: 18.8195935 },
    { lat: 48.8443425, lng: 18.8198245 },
    { lat: 48.8443515, lng: 18.8199333 },
    { lat: 48.8443428, lng: 18.8199852 },
    { lat: 48.8443476, lng: 18.8201182 },
    { lat: 48.8443214, lng: 18.8202459 },
    { lat: 48.844267, lng: 18.8203898 },
    { lat: 48.8441959, lng: 18.8205175 },
    { lat: 48.844104, lng: 18.8205961 },
    { lat: 48.8438897, lng: 18.8206364 },
    { lat: 48.8434957, lng: 18.8208488 },
    { lat: 48.8434322, lng: 18.8207729 },
    { lat: 48.8434025, lng: 18.8208108 },
    { lat: 48.843433, lng: 18.8210299 },
    { lat: 48.8434293, lng: 18.8210901 },
    { lat: 48.8432705, lng: 18.8214165 },
    { lat: 48.8430564, lng: 18.8215484 },
    { lat: 48.8427352, lng: 18.8213565 },
    { lat: 48.8427391, lng: 18.8210618 },
    { lat: 48.8426514, lng: 18.8208295 },
    { lat: 48.8426381, lng: 18.8207237 },
    { lat: 48.8426102, lng: 18.8206454 },
    { lat: 48.8425044, lng: 18.8206469 },
    { lat: 48.8423242, lng: 18.8205668 },
    { lat: 48.8421787, lng: 18.8206102 },
    { lat: 48.8421055, lng: 18.8205008 },
    { lat: 48.8421247, lng: 18.8204162 },
    { lat: 48.8420733, lng: 18.8201868 },
    { lat: 48.8420288, lng: 18.8201337 },
    { lat: 48.8419987, lng: 18.8201373 },
    { lat: 48.8419514, lng: 18.8200538 },
    { lat: 48.8418181, lng: 18.8200268 },
    { lat: 48.8417085, lng: 18.8198723 },
    { lat: 48.8416508, lng: 18.819867 },
    { lat: 48.8415538, lng: 18.8197794 },
    { lat: 48.8415493, lng: 18.8198652 },
    { lat: 48.8415129, lng: 18.8199475 },
    { lat: 48.8414131, lng: 18.8200214 },
    { lat: 48.8414127, lng: 18.8201009 },
    { lat: 48.8413103, lng: 18.8202359 },
    { lat: 48.8412452, lng: 18.8202648 },
    { lat: 48.84122, lng: 18.8204525 },
    { lat: 48.8410653, lng: 18.8205773 },
    { lat: 48.841002, lng: 18.820641 },
    { lat: 48.8409703, lng: 18.8206179 },
    { lat: 48.8408867, lng: 18.8206369 },
    { lat: 48.8406373, lng: 18.8207407 },
    { lat: 48.8405831, lng: 18.8206749 },
    { lat: 48.8405805, lng: 18.8205159 },
    { lat: 48.8406233, lng: 18.8204481 },
    { lat: 48.8406435, lng: 18.8203401 },
    { lat: 48.8406068, lng: 18.820216 },
    { lat: 48.8405922, lng: 18.8200667 },
    { lat: 48.8405414, lng: 18.820067 },
    { lat: 48.8404705, lng: 18.8201359 },
    { lat: 48.8404345, lng: 18.8202022 },
    { lat: 48.8403299, lng: 18.8203131 },
    { lat: 48.8402794, lng: 18.8203413 },
    { lat: 48.8402198, lng: 18.8202994 },
    { lat: 48.8401894, lng: 18.8202593 },
    { lat: 48.8401599, lng: 18.8202564 },
    { lat: 48.8401126, lng: 18.8203408 },
    { lat: 48.8400935, lng: 18.8204737 },
    { lat: 48.8399871, lng: 18.8206192 },
    { lat: 48.83998, lng: 18.8206777 },
    { lat: 48.839963, lng: 18.8207416 },
    { lat: 48.8398989, lng: 18.820832 },
    { lat: 48.8398221, lng: 18.8208494 },
    { lat: 48.8398056, lng: 18.8209219 },
    { lat: 48.8397388, lng: 18.8210472 },
    { lat: 48.8396945, lng: 18.8210862 },
    { lat: 48.8396283, lng: 18.8210887 },
    { lat: 48.8394142, lng: 18.8209206 },
    { lat: 48.839346, lng: 18.8207809 },
    { lat: 48.8392751, lng: 18.820741 },
    { lat: 48.8392229, lng: 18.8206688 },
    { lat: 48.8392842, lng: 18.8203701 },
    { lat: 48.8391819, lng: 18.8203336 },
    { lat: 48.8390054, lng: 18.8202566 },
    { lat: 48.8389074, lng: 18.8203252 },
    { lat: 48.8388213, lng: 18.8202517 },
    { lat: 48.8387873, lng: 18.8202468 },
    { lat: 48.8385729, lng: 18.8200737 },
    { lat: 48.8384651, lng: 18.8200819 },
    { lat: 48.8384, lng: 18.8200195 },
    { lat: 48.8382659, lng: 18.8200445 },
    { lat: 48.8381518, lng: 18.8201493 },
    { lat: 48.8380027, lng: 18.8202323 },
    { lat: 48.8379338, lng: 18.8202061 },
    { lat: 48.8376977, lng: 18.8202407 },
    { lat: 48.8376704, lng: 18.8203249 },
    { lat: 48.8375058, lng: 18.8204329 },
    { lat: 48.8374889, lng: 18.8203927 },
    { lat: 48.8375054, lng: 18.8202508 },
    { lat: 48.8374986, lng: 18.8202061 },
    { lat: 48.8374102, lng: 18.820115 },
    { lat: 48.8372074, lng: 18.8200286 },
    { lat: 48.8371609, lng: 18.8200474 },
    { lat: 48.8371516, lng: 18.8201279 },
    { lat: 48.8371965, lng: 18.8202543 },
    { lat: 48.837234, lng: 18.8204349 },
    { lat: 48.8372625, lng: 18.8206105 },
    { lat: 48.8373237, lng: 18.8206608 },
    { lat: 48.837408, lng: 18.8207882 },
    { lat: 48.8374402, lng: 18.8209519 },
    { lat: 48.8373864, lng: 18.8211083 },
    { lat: 48.8373985, lng: 18.8212146 },
    { lat: 48.8373358, lng: 18.8213481 },
    { lat: 48.8371958, lng: 18.8213243 },
    { lat: 48.8371663, lng: 18.8212569 },
    { lat: 48.8371833, lng: 18.8211064 },
    { lat: 48.8370181, lng: 18.8210505 },
    { lat: 48.8369203, lng: 18.8210604 },
    { lat: 48.8368859, lng: 18.8210259 },
    { lat: 48.8368012, lng: 18.8210639 },
    { lat: 48.8367498, lng: 18.8210296 },
    { lat: 48.8367407, lng: 18.8209873 },
    { lat: 48.8367136, lng: 18.8209655 },
    { lat: 48.8366725, lng: 18.8210613 },
    { lat: 48.8366355, lng: 18.8212 },
    { lat: 48.8366214, lng: 18.8212969 },
    { lat: 48.8366313, lng: 18.8213547 },
    { lat: 48.8366133, lng: 18.8214868 },
    { lat: 48.836572, lng: 18.821583 },
    { lat: 48.8364962, lng: 18.8216425 },
    { lat: 48.8365481, lng: 18.8220777 },
    { lat: 48.8365002, lng: 18.8221747 },
    { lat: 48.8363827, lng: 18.8223018 },
    { lat: 48.8364338, lng: 18.822567 },
    { lat: 48.836389, lng: 18.8226812 },
    { lat: 48.8363378, lng: 18.8227388 },
    { lat: 48.8362857, lng: 18.8227132 },
    { lat: 48.8362396, lng: 18.8226306 },
    { lat: 48.836018, lng: 18.8225317 },
    { lat: 48.8359717, lng: 18.822441 },
    { lat: 48.8359632, lng: 18.82204 },
    { lat: 48.8360653, lng: 18.8218138 },
    { lat: 48.8360398, lng: 18.8216676 },
    { lat: 48.8360545, lng: 18.8216279 },
    { lat: 48.8360131, lng: 18.8215448 },
    { lat: 48.8358419, lng: 18.8213107 },
    { lat: 48.8358137, lng: 18.8212231 },
    { lat: 48.8357755, lng: 18.8212066 },
    { lat: 48.8356964, lng: 18.8212028 },
    { lat: 48.8356062, lng: 18.8213323 },
    { lat: 48.8356726, lng: 18.8213946 },
    { lat: 48.8357557, lng: 18.8215844 },
    { lat: 48.8358073, lng: 18.8216638 },
    { lat: 48.835832, lng: 18.8218388 },
    { lat: 48.8357771, lng: 18.8218886 },
    { lat: 48.8356529, lng: 18.8219267 },
    { lat: 48.8354874, lng: 18.8219554 },
    { lat: 48.8354107, lng: 18.8219996 },
    { lat: 48.8352928, lng: 18.8220498 },
    { lat: 48.8351763, lng: 18.8220672 },
    { lat: 48.835098, lng: 18.8220336 },
    { lat: 48.8350206, lng: 18.8219355 },
    { lat: 48.8349165, lng: 18.8216 },
    { lat: 48.834926, lng: 18.8215015 },
    { lat: 48.834978, lng: 18.8214377 },
    { lat: 48.83503, lng: 18.8214605 },
    { lat: 48.8351406, lng: 18.8213789 },
    { lat: 48.8350862, lng: 18.8212873 },
    { lat: 48.834985, lng: 18.8211823 },
    { lat: 48.8349054, lng: 18.8211686 },
    { lat: 48.8348696, lng: 18.8212394 },
    { lat: 48.834782, lng: 18.8213128 },
    { lat: 48.8347221, lng: 18.8212874 },
    { lat: 48.8346794, lng: 18.8213995 },
    { lat: 48.8345931, lng: 18.8214524 },
    { lat: 48.8345464, lng: 18.8214218 },
    { lat: 48.8344304, lng: 18.8213186 },
    { lat: 48.834412, lng: 18.8212675 },
    { lat: 48.8344662, lng: 18.8210749 },
    { lat: 48.8344423, lng: 18.8210247 },
    { lat: 48.8343629, lng: 18.8210367 },
    { lat: 48.8341928, lng: 18.8210434 },
    { lat: 48.8341156, lng: 18.8210934 },
    { lat: 48.8341096, lng: 18.8210698 },
    { lat: 48.833954, lng: 18.821113 },
    { lat: 48.8338791, lng: 18.8211034 },
    { lat: 48.8337061, lng: 18.8212428 },
    { lat: 48.8335734, lng: 18.8212966 },
    { lat: 48.8334517, lng: 18.8214056 },
    { lat: 48.8333356, lng: 18.8212991 },
    { lat: 48.8332713, lng: 18.8211425 },
    { lat: 48.8332476, lng: 18.8209693 },
    { lat: 48.8332385, lng: 18.8206661 },
    { lat: 48.8332765, lng: 18.8205521 },
    { lat: 48.8333654, lng: 18.8205039 },
    { lat: 48.8334165, lng: 18.8204903 },
    { lat: 48.8335133, lng: 18.8203985 },
    { lat: 48.8335897, lng: 18.8203096 },
    { lat: 48.8336583, lng: 18.8201729 },
    { lat: 48.8336827, lng: 18.8199936 },
    { lat: 48.8337218, lng: 18.8199177 },
    { lat: 48.8337036, lng: 18.8198318 },
    { lat: 48.8336529, lng: 18.8197419 },
    { lat: 48.8335741, lng: 18.8196808 },
    { lat: 48.8334151, lng: 18.8196364 },
    { lat: 48.8332945, lng: 18.8198122 },
    { lat: 48.8331538, lng: 18.819883 },
    { lat: 48.8330589, lng: 18.8199896 },
    { lat: 48.8329581, lng: 18.8198047 },
    { lat: 48.8328214, lng: 18.8197104 },
    { lat: 48.8328034, lng: 18.8196288 },
    { lat: 48.8328093, lng: 18.8195687 },
    { lat: 48.8327916, lng: 18.8194423 },
    { lat: 48.832761, lng: 18.8193998 },
    { lat: 48.8325844, lng: 18.819534 },
    { lat: 48.8325441, lng: 18.8195402 },
    { lat: 48.8324892, lng: 18.819506 },
    { lat: 48.832423, lng: 18.8193782 },
    { lat: 48.8323183, lng: 18.8193195 },
    { lat: 48.8321837, lng: 18.8193337 },
    { lat: 48.8321025, lng: 18.8191816 },
    { lat: 48.8320529, lng: 18.8191145 },
    { lat: 48.8320446, lng: 18.8190851 },
    { lat: 48.8319587, lng: 18.8191247 },
    { lat: 48.8318742, lng: 18.8190857 },
    { lat: 48.8318634, lng: 18.8189875 },
    { lat: 48.8317876, lng: 18.8188698 },
    { lat: 48.8317837, lng: 18.8187769 },
    { lat: 48.8316749, lng: 18.8187648 },
    { lat: 48.8316208, lng: 18.8187025 },
    { lat: 48.8314995, lng: 18.8186236 },
    { lat: 48.8314659, lng: 18.818691 },
    { lat: 48.8314394, lng: 18.8186795 },
    { lat: 48.8314297, lng: 18.8186441 },
    { lat: 48.8314497, lng: 18.8185505 },
    { lat: 48.8314313, lng: 18.8184374 },
    { lat: 48.8313822, lng: 18.8184475 },
    { lat: 48.8313523, lng: 18.8183913 },
    { lat: 48.831299, lng: 18.8183426 },
    { lat: 48.8311915, lng: 18.8184034 },
    { lat: 48.8311271, lng: 18.818551 },
    { lat: 48.8311276, lng: 18.8186043 },
    { lat: 48.8310497, lng: 18.8186475 },
    { lat: 48.8308813, lng: 18.8185939 },
    { lat: 48.8307996, lng: 18.8184999 },
    { lat: 48.8307277, lng: 18.8184802 },
    { lat: 48.8306636, lng: 18.8185057 },
    { lat: 48.8305867, lng: 18.8184826 },
    { lat: 48.8305453, lng: 18.8185756 },
    { lat: 48.8304502, lng: 18.8186518 },
    { lat: 48.8304028, lng: 18.8186528 },
    { lat: 48.8303285, lng: 18.8187647 },
    { lat: 48.8302137, lng: 18.8187258 },
    { lat: 48.830109, lng: 18.8187519 },
    { lat: 48.830092, lng: 18.8186834 },
    { lat: 48.8300444, lng: 18.8186547 },
    { lat: 48.8300403, lng: 18.8184891 },
    { lat: 48.8300104, lng: 18.8183055 },
    { lat: 48.8299519, lng: 18.8182652 },
    { lat: 48.8298852, lng: 18.818305 },
    { lat: 48.8298154, lng: 18.8183084 },
    { lat: 48.8297322, lng: 18.8182041 },
    { lat: 48.8296724, lng: 18.8181615 },
    { lat: 48.8296065, lng: 18.8181726 },
    { lat: 48.8295954, lng: 18.8182401 },
    { lat: 48.8295489, lng: 18.8182993 },
    { lat: 48.8294316, lng: 18.8183201 },
    { lat: 48.8293467, lng: 18.8183607 },
    { lat: 48.8292885, lng: 18.8182588 },
    { lat: 48.8291637, lng: 18.8181993 },
    { lat: 48.8291032, lng: 18.8180572 },
    { lat: 48.8290254, lng: 18.8180114 },
    { lat: 48.8289772, lng: 18.81802 },
    { lat: 48.8288998, lng: 18.8180912 },
    { lat: 48.8288047, lng: 18.8179518 },
    { lat: 48.828713, lng: 18.8179252 },
    { lat: 48.8286413, lng: 18.8178464 },
    { lat: 48.8285285, lng: 18.8178033 },
    { lat: 48.8284437, lng: 18.817909 },
    { lat: 48.8283256, lng: 18.8179549 },
    { lat: 48.8281974, lng: 18.8180704 },
    { lat: 48.8280853, lng: 18.8180405 },
    { lat: 48.8279375, lng: 18.8179529 },
    { lat: 48.8278766, lng: 18.8179315 },
    { lat: 48.8278526, lng: 18.8178821 },
    { lat: 48.8278591, lng: 18.8177672 },
    { lat: 48.8278554, lng: 18.8176098 },
    { lat: 48.8278008, lng: 18.8175136 },
    { lat: 48.8276879, lng: 18.8175099 },
    { lat: 48.8275213, lng: 18.8174317 },
    { lat: 48.8272948, lng: 18.8172809 },
    { lat: 48.8270651, lng: 18.8170935 },
    { lat: 48.8268155, lng: 18.8169787 },
    { lat: 48.8266321, lng: 18.8169877 },
    { lat: 48.8264627, lng: 18.8168501 },
    { lat: 48.8264012, lng: 18.8167715 },
    { lat: 48.8262968, lng: 18.8166963 },
    { lat: 48.8262031, lng: 18.8165207 },
    { lat: 48.8260774, lng: 18.8165532 },
    { lat: 48.8259971, lng: 18.8164626 },
    { lat: 48.8258392, lng: 18.8164843 },
    { lat: 48.8257235, lng: 18.8165124 },
    { lat: 48.8256174, lng: 18.8165086 },
    { lat: 48.825288, lng: 18.8162895 },
    { lat: 48.8251139, lng: 18.8160458 },
    { lat: 48.824896, lng: 18.8160185 },
    { lat: 48.8247092, lng: 18.8160483 },
    { lat: 48.8247118, lng: 18.816097 },
    { lat: 48.8238264, lng: 18.8182371 },
    { lat: 48.8235696, lng: 18.8180694 },
    { lat: 48.8235362, lng: 18.8181239 },
    { lat: 48.823449, lng: 18.8179403 },
    { lat: 48.8233732, lng: 18.8178921 },
    { lat: 48.8232948, lng: 18.8179014 },
    { lat: 48.8232132, lng: 18.817982 },
    { lat: 48.8231615, lng: 18.817942 },
    { lat: 48.8232637, lng: 18.817591 },
    { lat: 48.8232755, lng: 18.8174274 },
    { lat: 48.8231804, lng: 18.8167653 },
    { lat: 48.8224986, lng: 18.8166001 },
    { lat: 48.8220416, lng: 18.8165315 },
    { lat: 48.8216916, lng: 18.8164984 },
    { lat: 48.8215467, lng: 18.8164656 },
    { lat: 48.8214386, lng: 18.8165136 },
    { lat: 48.8212864, lng: 18.8167765 },
    { lat: 48.8211744, lng: 18.8167448 },
    { lat: 48.821058, lng: 18.8166109 },
    { lat: 48.8207937, lng: 18.8165368 },
    { lat: 48.8206596, lng: 18.8165604 },
    { lat: 48.8199792, lng: 18.8164869 },
    { lat: 48.8197967, lng: 18.8165563 },
    { lat: 48.8194596, lng: 18.8167325 },
    { lat: 48.8193072, lng: 18.8167038 },
    { lat: 48.8191978, lng: 18.8167043 },
    { lat: 48.8191441, lng: 18.8167576 },
    { lat: 48.818888, lng: 18.8168858 },
    { lat: 48.8188611, lng: 18.8169724 },
    { lat: 48.8186364, lng: 18.8171008 },
    { lat: 48.8181562, lng: 18.8172304 },
    { lat: 48.8180115, lng: 18.8172022 },
    { lat: 48.8176923, lng: 18.8169851 },
    { lat: 48.8178577, lng: 18.8173024 },
    { lat: 48.8178917, lng: 18.8174803 },
    { lat: 48.8180663, lng: 18.8184115 },
    { lat: 48.8181429, lng: 18.8194064 },
    { lat: 48.8182794, lng: 18.82086 },
    { lat: 48.8180648, lng: 18.8210277 },
    { lat: 48.8180288, lng: 18.8210294 },
    { lat: 48.8182325, lng: 18.8214993 },
    { lat: 48.8183592, lng: 18.8216127 },
    { lat: 48.8184395, lng: 18.8216618 },
    { lat: 48.818681, lng: 18.8217081 },
    { lat: 48.8187745, lng: 18.8217521 },
    { lat: 48.8188431, lng: 18.8218756 },
    { lat: 48.8188673, lng: 18.8219962 },
    { lat: 48.8189192, lng: 18.8223628 },
    { lat: 48.8189576, lng: 18.8224742 },
    { lat: 48.8191838, lng: 18.8227496 },
    { lat: 48.8194952, lng: 18.8232539 },
    { lat: 48.819722, lng: 18.8236019 },
    { lat: 48.8197787, lng: 18.8237615 },
    { lat: 48.819832, lng: 18.8239376 },
    { lat: 48.8198886, lng: 18.8242902 },
    { lat: 48.8200548, lng: 18.8251882 },
    { lat: 48.8200862, lng: 18.8253999 },
    { lat: 48.8202568, lng: 18.8259521 },
    { lat: 48.8202266, lng: 18.8262845 },
    { lat: 48.8202336, lng: 18.8267646 },
    { lat: 48.8201481, lng: 18.8273763 },
    { lat: 48.8201095, lng: 18.8278056 },
    { lat: 48.8201258, lng: 18.8282531 },
    { lat: 48.8201164, lng: 18.8286124 },
    { lat: 48.820293, lng: 18.829711 },
    { lat: 48.8203919, lng: 18.8300507 },
    { lat: 48.8204611, lng: 18.8303877 },
    { lat: 48.8206634, lng: 18.8307887 },
    { lat: 48.8208002, lng: 18.8309661 },
    { lat: 48.8208891, lng: 18.8312264 },
    { lat: 48.820949, lng: 18.8313395 },
    { lat: 48.821114, lng: 18.8317134 },
    { lat: 48.8208151, lng: 18.8325158 },
    { lat: 48.8205668, lng: 18.8333591 },
    { lat: 48.8200972, lng: 18.8333287 },
    { lat: 48.8199582, lng: 18.8335155 },
    { lat: 48.8201133, lng: 18.834119 },
    { lat: 48.8201658, lng: 18.8343876 },
    { lat: 48.8201503, lng: 18.8348088 },
    { lat: 48.8199699, lng: 18.8353723 },
    { lat: 48.8204114, lng: 18.8358204 },
    { lat: 48.8203722, lng: 18.8362824 },
    { lat: 48.8203336, lng: 18.8365589 },
    { lat: 48.8202848, lng: 18.8369469 },
    { lat: 48.8198225, lng: 18.8382721 },
    { lat: 48.8196731, lng: 18.8386534 },
    { lat: 48.8194938, lng: 18.8388057 },
    { lat: 48.8182862, lng: 18.8391352 },
    { lat: 48.8182583, lng: 18.8393801 },
    { lat: 48.8182646, lng: 18.8394563 },
    { lat: 48.8189748, lng: 18.8397784 },
    { lat: 48.8195328, lng: 18.8401697 },
    { lat: 48.8194085, lng: 18.8413595 },
    { lat: 48.8193683, lng: 18.8415407 },
    { lat: 48.8191029, lng: 18.842251 },
    { lat: 48.8189758, lng: 18.8428222 },
    { lat: 48.8186913, lng: 18.8432978 },
    { lat: 48.8186462, lng: 18.8437301 },
    { lat: 48.8186475, lng: 18.8438419 },
    { lat: 48.8182914, lng: 18.8448284 },
    { lat: 48.8182775, lng: 18.845126 },
    { lat: 48.8182404, lng: 18.845368 },
    { lat: 48.8180259, lng: 18.8462353 },
    { lat: 48.8179018, lng: 18.846888 },
    { lat: 48.8178712, lng: 18.8471053 },
    { lat: 48.817746, lng: 18.8476028 },
    { lat: 48.8176589, lng: 18.8478142 },
    { lat: 48.8174298, lng: 18.8485109 },
    { lat: 48.817327, lng: 18.8489209 },
    { lat: 48.8171781, lng: 18.8493547 },
    { lat: 48.8171428, lng: 18.8495228 },
    { lat: 48.8171127, lng: 18.849853 },
    { lat: 48.8170688, lng: 18.8500561 },
    { lat: 48.817067, lng: 18.850282 },
    { lat: 48.8169112, lng: 18.8509743 },
    { lat: 48.8168113, lng: 18.8512899 },
    { lat: 48.8167939, lng: 18.8514336 },
    { lat: 48.8166212, lng: 18.8516899 },
    { lat: 48.8166019, lng: 18.8517734 },
    { lat: 48.816579, lng: 18.851857 },
    { lat: 48.8167276, lng: 18.8517649 },
    { lat: 48.8168642, lng: 18.8520707 },
    { lat: 48.8171805, lng: 18.8521935 },
    { lat: 48.8173264, lng: 18.8522635 },
    { lat: 48.8174542, lng: 18.8523593 },
    { lat: 48.8175209, lng: 18.8523646 },
    { lat: 48.8176814, lng: 18.8522605 },
    { lat: 48.8177616, lng: 18.8521755 },
    { lat: 48.8179813, lng: 18.8520009 },
    { lat: 48.8181099, lng: 18.8518243 },
    { lat: 48.8180484, lng: 18.8514441 },
    { lat: 48.8178758, lng: 18.851418 },
    { lat: 48.8178206, lng: 18.8512471 },
    { lat: 48.817731, lng: 18.8511729 },
    { lat: 48.8177624, lng: 18.8510576 },
    { lat: 48.8178435, lng: 18.8510362 },
    { lat: 48.8178888, lng: 18.8509063 },
    { lat: 48.8179289, lng: 18.8506586 },
    { lat: 48.8180684, lng: 18.8501715 },
    { lat: 48.8181721, lng: 18.849825 },
    { lat: 48.8182387, lng: 18.8495395 },
    { lat: 48.81822, lng: 18.8492476 },
    { lat: 48.8182926, lng: 18.8492522 },
    { lat: 48.8183433, lng: 18.8492748 },
    { lat: 48.8183861, lng: 18.8492737 },
    { lat: 48.8185185, lng: 18.8486497 },
    { lat: 48.8183941, lng: 18.8484927 },
    { lat: 48.8184392, lng: 18.8484058 },
    { lat: 48.8184379, lng: 18.8482023 },
    { lat: 48.8184928, lng: 18.8480663 },
    { lat: 48.8183589, lng: 18.8477895 },
    { lat: 48.8184292, lng: 18.8476237 },
    { lat: 48.81855, lng: 18.8476241 },
    { lat: 48.8186422, lng: 18.8475759 },
    { lat: 48.8187095, lng: 18.8475236 },
    { lat: 48.818883, lng: 18.8476758 },
    { lat: 48.819052, lng: 18.8475624 },
    { lat: 48.8192614, lng: 18.8473815 },
    { lat: 48.8205894, lng: 18.8482276 },
    { lat: 48.8206566, lng: 18.8485037 },
    { lat: 48.8209018, lng: 18.8487534 },
    { lat: 48.8213955, lng: 18.8491217 },
    { lat: 48.8218818, lng: 18.8496469 },
    { lat: 48.8220357, lng: 18.8498759 },
    { lat: 48.8222062, lng: 18.8500583 },
    { lat: 48.8226432, lng: 18.8505704 },
    { lat: 48.822921, lng: 18.8509267 },
    { lat: 48.8230821, lng: 18.8511406 },
    { lat: 48.8232985, lng: 18.8514692 },
    { lat: 48.8233615, lng: 18.8517462 },
    { lat: 48.8235065, lng: 18.8526315 },
    { lat: 48.8236412, lng: 18.8537098 },
    { lat: 48.8236564, lng: 18.8544205 },
    { lat: 48.8237573, lng: 18.8554577 },
    { lat: 48.8239314, lng: 18.8571218 },
    { lat: 48.8239976, lng: 18.8579051 },
    { lat: 48.8242274, lng: 18.8596231 },
    { lat: 48.8244403, lng: 18.860864 },
    { lat: 48.8245498, lng: 18.8614801 },
    { lat: 48.8248388, lng: 18.8630823 },
    { lat: 48.8249125, lng: 18.8633531 },
    { lat: 48.8250865, lng: 18.8638621 },
    { lat: 48.8251864, lng: 18.864113 },
    { lat: 48.8253775, lng: 18.8644514 },
    { lat: 48.8256637, lng: 18.8649296 },
    { lat: 48.825975, lng: 18.8654113 },
    { lat: 48.8261087, lng: 18.8660164 },
    { lat: 48.8261308, lng: 18.8660929 },
    { lat: 48.8262664, lng: 18.866055 },
    { lat: 48.8259285, lng: 18.8664585 },
    { lat: 48.825958, lng: 18.8665259 },
    { lat: 48.8261893, lng: 18.866529 },
    { lat: 48.8262899, lng: 18.8670145 },
    { lat: 48.8263719, lng: 18.8670978 },
    { lat: 48.8262098, lng: 18.867078 },
    { lat: 48.8262608, lng: 18.867106 },
    { lat: 48.8261156, lng: 18.8677909 },
    { lat: 48.8260076, lng: 18.8681433 },
    { lat: 48.8258941, lng: 18.8692864 },
    { lat: 48.8259274, lng: 18.8701768 },
    { lat: 48.8260266, lng: 18.8708725 },
    { lat: 48.8260614, lng: 18.8712445 },
    { lat: 48.8263728, lng: 18.8719467 },
    { lat: 48.8266685, lng: 18.872499 },
    { lat: 48.8272372, lng: 18.8732499 },
    { lat: 48.8276407, lng: 18.8737085 },
    { lat: 48.8282108, lng: 18.8742034 },
    { lat: 48.8282349, lng: 18.8749789 },
    { lat: 48.8287886, lng: 18.8768621 },
    { lat: 48.8288919, lng: 18.8770965 },
    { lat: 48.829312, lng: 18.878337 },
    { lat: 48.8293894, lng: 18.8782835 },
    { lat: 48.8299871, lng: 18.8786592 },
    { lat: 48.8302424, lng: 18.8792808 },
    { lat: 48.829341, lng: 18.8800389 },
    { lat: 48.8296246, lng: 18.8814952 },
    { lat: 48.8296767, lng: 18.8817181 },
    { lat: 48.8299931, lng: 18.8831155 },
    { lat: 48.8299354, lng: 18.8831334 },
    { lat: 48.8300445, lng: 18.8837189 },
    { lat: 48.830407, lng: 18.8861423 },
    { lat: 48.8308068, lng: 18.8873843 },
    { lat: 48.8320345, lng: 18.8899706 },
    { lat: 48.8323966, lng: 18.8910037 },
    { lat: 48.8325294, lng: 18.8915042 },
    { lat: 48.8331629, lng: 18.8935866 },
    { lat: 48.833412, lng: 18.895015 },
    { lat: 48.8334021, lng: 18.8952581 },
    { lat: 48.8334198, lng: 18.8958011 },
    { lat: 48.8335562, lng: 18.8965491 },
    { lat: 48.8336666, lng: 18.8965265 },
    { lat: 48.8339516, lng: 18.8965187 },
    { lat: 48.8339774, lng: 18.8966069 },
    { lat: 48.8341781, lng: 18.8971992 },
    { lat: 48.8343697, lng: 18.8977941 },
    { lat: 48.8346318, lng: 18.8979554 },
    { lat: 48.8349106, lng: 18.8992827 },
    { lat: 48.8351829, lng: 18.8993813 },
    { lat: 48.8350954, lng: 18.8995419 },
    { lat: 48.835009, lng: 18.8999676 },
    { lat: 48.8349786, lng: 18.9001902 },
    { lat: 48.8349696, lng: 18.9006497 },
    { lat: 48.8349022, lng: 18.901823 },
    { lat: 48.8351148, lng: 18.9023388 },
    { lat: 48.8349975, lng: 18.9028676 },
    { lat: 48.8351046, lng: 18.9034624 },
    { lat: 48.8351242, lng: 18.9035145 },
    { lat: 48.8351947, lng: 18.9034375 },
    { lat: 48.8352907, lng: 18.9033969 },
    { lat: 48.8357121, lng: 18.9033913 },
    { lat: 48.835825, lng: 18.9034178 },
    { lat: 48.8359992, lng: 18.9033823 },
    { lat: 48.8363403, lng: 18.9033934 },
    { lat: 48.8364409, lng: 18.9034205 },
    { lat: 48.8365401, lng: 18.9035087 },
    { lat: 48.8369361, lng: 18.9040191 },
    { lat: 48.837142, lng: 18.9043628 },
    { lat: 48.8372789, lng: 18.9046603 },
    { lat: 48.8372952, lng: 18.9047339 },
    { lat: 48.8373005, lng: 18.9055425 },
    { lat: 48.8372237, lng: 18.9058483 },
    { lat: 48.8372187, lng: 18.9063442 },
    { lat: 48.8371433, lng: 18.9069215 },
    { lat: 48.8370803, lng: 18.9071629 },
    { lat: 48.8370364, lng: 18.9073846 },
    { lat: 48.8370783, lng: 18.9076081 },
    { lat: 48.8370794, lng: 18.9076112 },
    { lat: 48.8371298, lng: 18.9079116 },
    { lat: 48.8371325, lng: 18.9080291 },
    { lat: 48.8371241, lng: 18.9082401 },
    { lat: 48.8371301, lng: 18.9084445 },
    { lat: 48.8371182, lng: 18.9086728 },
    { lat: 48.8370954, lng: 18.9088459 },
    { lat: 48.837031, lng: 18.9091759 },
    { lat: 48.8369223, lng: 18.9094758 },
    { lat: 48.8368089, lng: 18.9097482 },
    { lat: 48.8364295, lng: 18.9105752 },
    { lat: 48.8363722, lng: 18.910753 },
    { lat: 48.8363024, lng: 18.9109072 },
    { lat: 48.8361388, lng: 18.9113899 },
    { lat: 48.8360966, lng: 18.9115348 },
    { lat: 48.8359917, lng: 18.9120203 },
    { lat: 48.8359672, lng: 18.9122243 },
    { lat: 48.8358924, lng: 18.9133391 },
    { lat: 48.8358839, lng: 18.9134417 },
    { lat: 48.8358485, lng: 18.9135622 },
    { lat: 48.8357959, lng: 18.9136614 },
    { lat: 48.8357095, lng: 18.9137327 },
    { lat: 48.8355887, lng: 18.913796 },
    { lat: 48.8355381, lng: 18.9138456 },
    { lat: 48.8355027, lng: 18.9139238 },
    { lat: 48.835344, lng: 18.9140609 },
    { lat: 48.8352074, lng: 18.91417 },
    { lat: 48.8350239, lng: 18.9141715 },
    { lat: 48.8348405, lng: 18.9144246 },
    { lat: 48.8347487, lng: 18.9145695 },
    { lat: 48.8347091, lng: 18.9146089 },
    { lat: 48.83459, lng: 18.9147929 },
    { lat: 48.8345476, lng: 18.9148469 },
    { lat: 48.834326, lng: 18.9151002 },
    { lat: 48.8342834, lng: 18.9151281 },
    { lat: 48.8341904, lng: 18.915227 },
    { lat: 48.834059, lng: 18.9153036 },
    { lat: 48.8340076, lng: 18.9153097 },
    { lat: 48.8338616, lng: 18.9154317 },
    { lat: 48.8336298, lng: 18.9155326 },
    { lat: 48.8335638, lng: 18.9155858 },
    { lat: 48.8333135, lng: 18.9157418 },
    { lat: 48.8330072, lng: 18.9158125 },
    { lat: 48.832952, lng: 18.9158376 },
    { lat: 48.8327929, lng: 18.9159418 },
    { lat: 48.8327403, lng: 18.915953 },
    { lat: 48.8326215, lng: 18.9160514 },
    { lat: 48.8323748, lng: 18.9161487 },
    { lat: 48.8321953, lng: 18.9161397 },
    { lat: 48.8319787, lng: 18.9162703 },
    { lat: 48.831847, lng: 18.9163652 },
    { lat: 48.8318834, lng: 18.9165925 },
    { lat: 48.8318648, lng: 18.9166673 },
    { lat: 48.8317593, lng: 18.9168085 },
    { lat: 48.8316762, lng: 18.916819 },
    { lat: 48.8316427, lng: 18.916888 },
    { lat: 48.8315141, lng: 18.9170223 },
    { lat: 48.8314082, lng: 18.9171968 },
    { lat: 48.8313982, lng: 18.9172864 },
    { lat: 48.8314375, lng: 18.917353 },
    { lat: 48.8313545, lng: 18.9174021 },
    { lat: 48.831293, lng: 18.9175267 },
    { lat: 48.8310788, lng: 18.9177444 },
    { lat: 48.8308887, lng: 18.91782 },
    { lat: 48.8307817, lng: 18.9180197 },
    { lat: 48.8306669, lng: 18.9181595 },
    { lat: 48.8305601, lng: 18.9182293 },
    { lat: 48.8304488, lng: 18.9182137 },
    { lat: 48.8303477, lng: 18.9183544 },
    { lat: 48.8302432, lng: 18.918537 },
    { lat: 48.830231, lng: 18.9185849 },
    { lat: 48.8301963, lng: 18.9186556 },
    { lat: 48.8301159, lng: 18.9188697 },
    { lat: 48.8300989, lng: 18.9188903 },
    { lat: 48.8299784, lng: 18.9189588 },
    { lat: 48.8299099, lng: 18.9192077 },
    { lat: 48.829715, lng: 18.9193422 },
    { lat: 48.8296668, lng: 18.9194581 },
    { lat: 48.8294741, lng: 18.9196596 },
    { lat: 48.8293599, lng: 18.9199625 },
    { lat: 48.8293615, lng: 18.9200178 },
    { lat: 48.8293363, lng: 18.9200501 },
    { lat: 48.8293904, lng: 18.92012 },
    { lat: 48.8294584, lng: 18.9206556 },
    { lat: 48.8295643, lng: 18.9211141 },
    { lat: 48.8296215, lng: 18.9217709 },
    { lat: 48.829679, lng: 18.9222348 },
    { lat: 48.8299086, lng: 18.9227568 },
    { lat: 48.8300069, lng: 18.9230259 },
    { lat: 48.8300874, lng: 18.9236076 },
    { lat: 48.8300505, lng: 18.9243889 },
    { lat: 48.8291799, lng: 18.9251579 },
    { lat: 48.8292063, lng: 18.9267799 },
    { lat: 48.8296054, lng: 18.9275959 },
    { lat: 48.8302007, lng: 18.9278805 },
    { lat: 48.8317506, lng: 18.9282051 },
    { lat: 48.8327828, lng: 18.9283251 },
    { lat: 48.8333836, lng: 18.9285887 },
    { lat: 48.8336611, lng: 18.9289005 },
    { lat: 48.8338502, lng: 18.9292906 },
    { lat: 48.8338607, lng: 18.92921 },
    { lat: 48.833893, lng: 18.9291353 },
    { lat: 48.8340306, lng: 18.9290949 },
    { lat: 48.8339775, lng: 18.9289568 },
    { lat: 48.8340589, lng: 18.9287884 },
    { lat: 48.8341134, lng: 18.9288135 },
    { lat: 48.8341675, lng: 18.9287718 },
    { lat: 48.8342611, lng: 18.9288152 },
    { lat: 48.8343575, lng: 18.9287592 },
    { lat: 48.8344551, lng: 18.928639 },
    { lat: 48.8345161, lng: 18.9284225 },
    { lat: 48.8345381, lng: 18.9282982 },
    { lat: 48.8346005, lng: 18.9281971 },
    { lat: 48.8345977, lng: 18.9281656 },
    { lat: 48.8346488, lng: 18.9281073 },
    { lat: 48.8349546, lng: 18.9276038 },
    { lat: 48.8349622, lng: 18.9274644 },
    { lat: 48.8348896, lng: 18.9271901 },
    { lat: 48.8349107, lng: 18.9270717 },
    { lat: 48.8349182, lng: 18.9268203 },
    { lat: 48.834976, lng: 18.9266299 },
    { lat: 48.8349975, lng: 18.9265 },
    { lat: 48.834996, lng: 18.9262253 },
    { lat: 48.835013, lng: 18.9260308 },
    { lat: 48.8349654, lng: 18.925847 },
    { lat: 48.8349929, lng: 18.9257239 },
    { lat: 48.834953, lng: 18.925517 },
    { lat: 48.8349099, lng: 18.9254455 },
    { lat: 48.8348902, lng: 18.9251468 },
    { lat: 48.8349237, lng: 18.9249425 },
    { lat: 48.83497, lng: 18.9248325 },
    { lat: 48.8350183, lng: 18.9245889 },
    { lat: 48.8351829, lng: 18.9241244 },
    { lat: 48.8354312, lng: 18.9235785 },
    { lat: 48.8356504, lng: 18.9232761 },
    { lat: 48.8361625, lng: 18.9226642 },
    { lat: 48.8362437, lng: 18.9225738 },
    { lat: 48.836539, lng: 18.9222869 },
    { lat: 48.8366002, lng: 18.9221151 },
    { lat: 48.8366777, lng: 18.9218436 },
    { lat: 48.8367427, lng: 18.9217465 },
    { lat: 48.8368377, lng: 18.9216677 },
    { lat: 48.8369164, lng: 18.9216174 },
    { lat: 48.8370493, lng: 18.9214167 },
    { lat: 48.8371208, lng: 18.9212716 },
    { lat: 48.8372667, lng: 18.9212789 },
    { lat: 48.8374069, lng: 18.9213072 },
    { lat: 48.8374273, lng: 18.9212915 },
    { lat: 48.8375836, lng: 18.9210807 },
    { lat: 48.8377587, lng: 18.9209102 },
    { lat: 48.8380193, lng: 18.9205822 },
    { lat: 48.8381088, lng: 18.920503 },
    { lat: 48.8381787, lng: 18.9203731 },
    { lat: 48.8382984, lng: 18.92031 },
    { lat: 48.8383887, lng: 18.9202003 },
    { lat: 48.8384076, lng: 18.9201304 },
    { lat: 48.8384951, lng: 18.9200779 },
    { lat: 48.8384089, lng: 18.9195594 },
    { lat: 48.8383242, lng: 18.9194877 },
    { lat: 48.8382822, lng: 18.9194207 },
    { lat: 48.8382123, lng: 18.9192635 },
    { lat: 48.8379698, lng: 18.9189515 },
    { lat: 48.8378334, lng: 18.9189325 },
    { lat: 48.8377439, lng: 18.9187486 },
    { lat: 48.8376478, lng: 18.9185007 },
    { lat: 48.8376408, lng: 18.9179883 },
    { lat: 48.8375331, lng: 18.9178463 },
    { lat: 48.837492, lng: 18.9175455 },
    { lat: 48.8370956, lng: 18.9166958 },
    { lat: 48.8371319, lng: 18.9165702 },
    { lat: 48.8371311, lng: 18.9163967 },
    { lat: 48.8371584, lng: 18.9162048 },
    { lat: 48.8371533, lng: 18.9161288 },
    { lat: 48.837173, lng: 18.9159368 },
    { lat: 48.8371623, lng: 18.9157498 },
    { lat: 48.8369596, lng: 18.9154468 },
    { lat: 48.8368585, lng: 18.9154562 },
    { lat: 48.8368384, lng: 18.9153905 },
    { lat: 48.8368767, lng: 18.9147314 },
    { lat: 48.8368143, lng: 18.9143271 },
    { lat: 48.8368526, lng: 18.914172 },
    { lat: 48.8367675, lng: 18.9139869 },
    { lat: 48.8367516, lng: 18.9136297 },
    { lat: 48.8367495, lng: 18.9133014 },
    { lat: 48.8367426, lng: 18.9132131 },
    { lat: 48.8366173, lng: 18.9127905 },
    { lat: 48.8366319, lng: 18.9126094 },
    { lat: 48.8366309, lng: 18.9122863 },
    { lat: 48.8366537, lng: 18.9119143 },
    { lat: 48.8365581, lng: 18.9116528 },
    { lat: 48.8366347, lng: 18.9110167 },
    { lat: 48.8367825, lng: 18.9108151 },
    { lat: 48.8369333, lng: 18.9105901 },
    { lat: 48.8372773, lng: 18.9104835 },
    { lat: 48.8374471, lng: 18.9101468 },
    { lat: 48.8374909, lng: 18.9100328 },
    { lat: 48.8376465, lng: 18.909657 },
    { lat: 48.8376348, lng: 18.9092532 },
    { lat: 48.8378327, lng: 18.9089082 },
    { lat: 48.8379722, lng: 18.908837 },
    { lat: 48.8380561, lng: 18.9086479 },
    { lat: 48.8381567, lng: 18.9085229 },
    { lat: 48.8382856, lng: 18.9082214 },
    { lat: 48.8383367, lng: 18.9081392 },
    { lat: 48.8385501, lng: 18.9081685 },
    { lat: 48.8386474, lng: 18.9080197 },
    { lat: 48.838665, lng: 18.9079481 },
    { lat: 48.8388134, lng: 18.9076952 },
    { lat: 48.8389631, lng: 18.907383 },
    { lat: 48.8389265, lng: 18.9070659 },
    { lat: 48.8390699, lng: 18.9068475 },
    { lat: 48.8391059, lng: 18.9066464 },
    { lat: 48.8391353, lng: 18.906013 },
    { lat: 48.8390557, lng: 18.9055381 },
    { lat: 48.8389433, lng: 18.9055663 },
    { lat: 48.8388935, lng: 18.9051656 },
    { lat: 48.8389885, lng: 18.9050155 },
    { lat: 48.8389774, lng: 18.9045125 },
    { lat: 48.8389143, lng: 18.9043592 },
    { lat: 48.8388136, lng: 18.9041785 },
    { lat: 48.8387378, lng: 18.9037321 },
    { lat: 48.8384603, lng: 18.903706 },
    { lat: 48.8383921, lng: 18.9035426 },
    { lat: 48.8385713, lng: 18.9033884 },
    { lat: 48.8386803, lng: 18.9031583 },
    { lat: 48.8386818, lng: 18.9026398 },
    { lat: 48.8388321, lng: 18.9022059 },
    { lat: 48.8386389, lng: 18.9011849 },
    { lat: 48.8386006, lng: 18.900922 },
    { lat: 48.8385895, lng: 18.900575 },
    { lat: 48.8384588, lng: 18.8995205 },
    { lat: 48.8384131, lng: 18.8992879 },
    { lat: 48.8382921, lng: 18.8988869 },
    { lat: 48.8382626, lng: 18.8987719 },
    { lat: 48.8382258, lng: 18.8985372 },
    { lat: 48.8381753, lng: 18.8983681 },
    { lat: 48.8381868, lng: 18.898085 },
    { lat: 48.8381756, lng: 18.897955 },
    { lat: 48.8381502, lng: 18.8978148 },
    { lat: 48.838102, lng: 18.8976447 },
    { lat: 48.8379291, lng: 18.8974385 },
    { lat: 48.8378343, lng: 18.8971046 },
    { lat: 48.8378044, lng: 18.8970931 },
    { lat: 48.8376018, lng: 18.8966627 },
    { lat: 48.837578, lng: 18.8964154 },
    { lat: 48.8376548, lng: 18.896024 },
    { lat: 48.8378331, lng: 18.8954336 },
    { lat: 48.8378448, lng: 18.8954397 },
    { lat: 48.8379024, lng: 18.8951348 },
    { lat: 48.8379359, lng: 18.8948205 },
    { lat: 48.8379392, lng: 18.8946451 },
    { lat: 48.8379348, lng: 18.8944717 },
    { lat: 48.8379182, lng: 18.8941676 },
    { lat: 48.8379044, lng: 18.8941649 },
    { lat: 48.8378473, lng: 18.8939505 },
    { lat: 48.8377279, lng: 18.8934294 },
    { lat: 48.837672, lng: 18.8932603 },
    { lat: 48.8377086, lng: 18.893053 },
    { lat: 48.8377802, lng: 18.8929286 },
    { lat: 48.8378035, lng: 18.8925271 },
    { lat: 48.8378429, lng: 18.8922859 },
    { lat: 48.8379517, lng: 18.8918153 },
    { lat: 48.8379168, lng: 18.8916587 },
    { lat: 48.8378964, lng: 18.891505 },
    { lat: 48.8378132, lng: 18.8912253 },
    { lat: 48.837834, lng: 18.8897854 },
    { lat: 48.837728, lng: 18.8896067 },
    { lat: 48.8377189, lng: 18.889388 },
    { lat: 48.8376072, lng: 18.889146 },
    { lat: 48.8375903, lng: 18.8888158 },
    { lat: 48.8375596, lng: 18.8886784 },
    { lat: 48.8373402, lng: 18.887987 },
    { lat: 48.8374234, lng: 18.8879131 },
    { lat: 48.8374659, lng: 18.8876235 },
    { lat: 48.8373845, lng: 18.8874649 },
    { lat: 48.8372319, lng: 18.8872395 },
    { lat: 48.8373432, lng: 18.8868588 },
    { lat: 48.8372958, lng: 18.8866804 },
    { lat: 48.8372322, lng: 18.8865841 },
    { lat: 48.8371433, lng: 18.8865001 },
    { lat: 48.8370141, lng: 18.8863997 },
    { lat: 48.8366629, lng: 18.8861713 },
    { lat: 48.8364995, lng: 18.8858877 },
    { lat: 48.8364209, lng: 18.8858727 },
    { lat: 48.8362836, lng: 18.8854812 },
    { lat: 48.8363051, lng: 18.8853756 },
    { lat: 48.8363854, lng: 18.8851349 },
    { lat: 48.8364219, lng: 18.8850783 },
    { lat: 48.8365354, lng: 18.884809 },
    { lat: 48.8366548, lng: 18.8845437 },
    { lat: 48.8366514, lng: 18.8838833 },
    { lat: 48.8364734, lng: 18.8835797 },
    { lat: 48.8367261, lng: 18.8827492 },
    { lat: 48.8367348, lng: 18.8824815 },
    { lat: 48.83667, lng: 18.8821867 },
    { lat: 48.8368807, lng: 18.8819421 },
    { lat: 48.836949, lng: 18.8818463 },
    { lat: 48.8372291, lng: 18.8811497 },
    { lat: 48.8373948, lng: 18.880978 },
    { lat: 48.8373983, lng: 18.8804747 },
    { lat: 48.8372533, lng: 18.879999 },
    { lat: 48.8378813, lng: 18.879539 },
    { lat: 48.8379632, lng: 18.8796213 },
    { lat: 48.8379988, lng: 18.8795229 },
    { lat: 48.8380068, lng: 18.8795216 },
    { lat: 48.8380734, lng: 18.8794798 },
    { lat: 48.8380811, lng: 18.8794785 },
    { lat: 48.8381261, lng: 18.8795222 },
    { lat: 48.8382165, lng: 18.8793687 },
    { lat: 48.8383252, lng: 18.879226 },
    { lat: 48.8385669, lng: 18.8788807 },
    { lat: 48.8386558, lng: 18.8787918 },
    { lat: 48.8387376, lng: 18.878824 },
    { lat: 48.8389248, lng: 18.8786963 },
    { lat: 48.8393926, lng: 18.8783662 },
    { lat: 48.8395147, lng: 18.8781073 },
    { lat: 48.8395426, lng: 18.8779694 },
    { lat: 48.8396071, lng: 18.8778179 },
    { lat: 48.8397084, lng: 18.8777068 },
    { lat: 48.8400576, lng: 18.8773487 },
    { lat: 48.8402484, lng: 18.8772449 },
    { lat: 48.8402854, lng: 18.8771332 },
    { lat: 48.8403074, lng: 18.8769459 },
    { lat: 48.8403988, lng: 18.8768395 },
    { lat: 48.8404634, lng: 18.8768229 },
    { lat: 48.8405312, lng: 18.8766939 },
    { lat: 48.8405899, lng: 18.8762148 },
    { lat: 48.8406431, lng: 18.8761495 },
    { lat: 48.8408304, lng: 18.8762645 },
    { lat: 48.8409836, lng: 18.8762159 },
    { lat: 48.84109, lng: 18.8760051 },
    { lat: 48.8411166, lng: 18.8756687 },
    { lat: 48.8412188, lng: 18.8760099 },
    { lat: 48.8412773, lng: 18.8760495 },
    { lat: 48.8414769, lng: 18.8755724 },
    { lat: 48.8415534, lng: 18.8752169 },
    { lat: 48.8414978, lng: 18.8748161 },
    { lat: 48.8415724, lng: 18.8744672 },
    { lat: 48.8417466, lng: 18.8742386 },
    { lat: 48.8421993, lng: 18.8742471 },
    { lat: 48.8423246, lng: 18.874166 },
    { lat: 48.8427883, lng: 18.8733377 },
    { lat: 48.8428635, lng: 18.8727381 },
    { lat: 48.8429633, lng: 18.8727077 },
    { lat: 48.8431236, lng: 18.8723147 },
    { lat: 48.8431973, lng: 18.8720858 },
    { lat: 48.8432458, lng: 18.8716688 },
    { lat: 48.8435299, lng: 18.8714275 },
    { lat: 48.8435734, lng: 18.8713096 },
    { lat: 48.843533, lng: 18.8710481 },
    { lat: 48.8438859, lng: 18.870784 },
    { lat: 48.8441529, lng: 18.8703673 },
    { lat: 48.8442239, lng: 18.8703181 },
    { lat: 48.8445715, lng: 18.8701323 },
    { lat: 48.84463, lng: 18.8698856 },
    { lat: 48.8448392, lng: 18.8696779 },
    { lat: 48.8450082, lng: 18.8695226 },
    { lat: 48.8455068, lng: 18.8688948 },
    { lat: 48.8456785, lng: 18.868771 },
    { lat: 48.8458714, lng: 18.8686798 },
    { lat: 48.8459619, lng: 18.8685799 },
    { lat: 48.8460923, lng: 18.8682452 },
    { lat: 48.8461961, lng: 18.8682236 },
    { lat: 48.8463674, lng: 18.8680906 },
    { lat: 48.8463996, lng: 18.8677062 },
    { lat: 48.8465048, lng: 18.8677349 },
    { lat: 48.8466423, lng: 18.8679095 },
    { lat: 48.8467329, lng: 18.8679206 },
    { lat: 48.846824, lng: 18.8678912 },
    { lat: 48.8468942, lng: 18.8677669 },
    { lat: 48.8470143, lng: 18.8677781 },
    { lat: 48.8472475, lng: 18.8677269 },
    { lat: 48.847346, lng: 18.8677593 },
    { lat: 48.8475125, lng: 18.8677292 },
    { lat: 48.8477296, lng: 18.8674758 },
    { lat: 48.8479259, lng: 18.867241 },
    { lat: 48.848024, lng: 18.8671732 },
    { lat: 48.8481182, lng: 18.8673604 },
    { lat: 48.8482408, lng: 18.8672689 },
    { lat: 48.8483513, lng: 18.867138 },
    { lat: 48.8487057, lng: 18.8668347 },
    { lat: 48.8489089, lng: 18.8666879 },
    { lat: 48.8490792, lng: 18.8666001 },
    { lat: 48.8500373, lng: 18.8666195 },
    { lat: 48.8512146, lng: 18.8675152 },
    { lat: 48.8515157, lng: 18.8677378 },
    { lat: 48.8521981, lng: 18.867522 },
    { lat: 48.8528027, lng: 18.8681168 },
    { lat: 48.8532326, lng: 18.8685185 },
    { lat: 48.8536145, lng: 18.8688192 },
    { lat: 48.8541483, lng: 18.8691778 },
    { lat: 48.8548223, lng: 18.8695016 },
    { lat: 48.8554963, lng: 18.8698014 },
    { lat: 48.85619, lng: 18.8700262 },
    { lat: 48.8564441, lng: 18.8700732 },
    { lat: 48.8570009, lng: 18.8701598 },
    { lat: 48.8575519, lng: 18.8702189 },
    { lat: 48.858155, lng: 18.870193 },
    { lat: 48.8588155, lng: 18.8700809 },
    { lat: 48.8594488, lng: 18.8699175 },
    { lat: 48.8600483, lng: 18.8696926 },
    { lat: 48.8606394, lng: 18.8693892 },
    { lat: 48.8611583, lng: 18.8690879 },
    { lat: 48.8617858, lng: 18.8687282 },
    { lat: 48.8629265, lng: 18.8679346 },
    { lat: 48.8632376, lng: 18.8678452 },
    { lat: 48.8635156, lng: 18.8679627 },
    { lat: 48.8638706, lng: 18.8684013 },
    { lat: 48.8640528, lng: 18.868567 },
    { lat: 48.8642643, lng: 18.8686856 },
    { lat: 48.864512, lng: 18.8686377 },
    { lat: 48.8647638, lng: 18.8684893 },
    { lat: 48.8649199, lng: 18.868589 },
    { lat: 48.8650098, lng: 18.8687553 },
    { lat: 48.8653067, lng: 18.8686521 },
    { lat: 48.8650483, lng: 18.8675356 },
    { lat: 48.8648013, lng: 18.8668325 },
    { lat: 48.8648181, lng: 18.866612 },
    { lat: 48.8652451, lng: 18.8662964 },
    { lat: 48.8660798, lng: 18.8656909 },
    { lat: 48.8666983, lng: 18.8652856 },
    { lat: 48.8675366, lng: 18.8647082 },
    { lat: 48.8683464, lng: 18.864122 },
    { lat: 48.869019, lng: 18.8636713 },
    { lat: 48.8700359, lng: 18.8630551 },
    { lat: 48.8706851, lng: 18.862615 },
    { lat: 48.8713772, lng: 18.8621519 },
    { lat: 48.8716141, lng: 18.8621084 },
    { lat: 48.8718541, lng: 18.8622354 },
    { lat: 48.8720844, lng: 18.8625287 },
    { lat: 48.8722668, lng: 18.8627844 },
    { lat: 48.8725239, lng: 18.8629129 },
    { lat: 48.8728225, lng: 18.8628256 },
    { lat: 48.8730717, lng: 18.8624751 },
    { lat: 48.8732509, lng: 18.8620155 },
    { lat: 48.8734559, lng: 18.8615044 },
    { lat: 48.8736019, lng: 18.8611651 },
    { lat: 48.8736526, lng: 18.8610566 },
    { lat: 48.8737682, lng: 18.8609161 },
    { lat: 48.8739625, lng: 18.8608576 },
    { lat: 48.8741416, lng: 18.8608769 },
    { lat: 48.8740728, lng: 18.8601751 },
    { lat: 48.8758161, lng: 18.8589755 },
    { lat: 48.8759137, lng: 18.859095 },
    { lat: 48.8760541, lng: 18.8591267 },
    { lat: 48.8765676, lng: 18.8590049 },
    { lat: 48.8766043, lng: 18.8590615 },
    { lat: 48.876455, lng: 18.8607338 },
    { lat: 48.8765135, lng: 18.8609117 },
    { lat: 48.8766741, lng: 18.860873 },
    { lat: 48.8775606, lng: 18.8607905 },
    { lat: 48.8782623, lng: 18.8609308 },
    { lat: 48.8786319, lng: 18.8611439 },
    { lat: 48.8787573, lng: 18.8612795 },
    { lat: 48.8784897, lng: 18.8617991 },
    { lat: 48.87816, lng: 18.8623336 },
    { lat: 48.8783429, lng: 18.8626236 },
    { lat: 48.8784059, lng: 18.8627096 },
    { lat: 48.8784186, lng: 18.862735 },
    { lat: 48.8784304, lng: 18.8627712 },
    { lat: 48.8784429, lng: 18.8627948 },
    { lat: 48.8787162, lng: 18.8635677 },
    { lat: 48.8792858, lng: 18.86274 },
    { lat: 48.8796684, lng: 18.8633148 },
    { lat: 48.8798268, lng: 18.8635457 },
    { lat: 48.8786891, lng: 18.864771 },
    { lat: 48.8786844, lng: 18.8648525 },
    { lat: 48.8802123, lng: 18.8702418 },
    { lat: 48.8821039, lng: 18.8716129 },
    { lat: 48.8823261, lng: 18.8712201 },
    { lat: 48.8824912, lng: 18.8709437 },
    { lat: 48.8826293, lng: 18.871042 },
    { lat: 48.8843737, lng: 18.8723134 },
    { lat: 48.8841863, lng: 18.872772 },
    { lat: 48.883997, lng: 18.8732992 },
    { lat: 48.8838226, lng: 18.8738081 },
    { lat: 48.8836219, lng: 18.8748862 },
    { lat: 48.8834859, lng: 18.8757245 },
    { lat: 48.884655, lng: 18.8763813 },
    { lat: 48.8853632, lng: 18.876778 },
    { lat: 48.8855661, lng: 18.8768647 },
    { lat: 48.88658, lng: 18.8771322 },
    { lat: 48.8866223, lng: 18.8771415 },
    { lat: 48.8869941, lng: 18.8751853 },
    { lat: 48.8872298, lng: 18.8739572 },
    { lat: 48.8873554, lng: 18.8731785 },
    { lat: 48.887442, lng: 18.8724753 },
    { lat: 48.8874629, lng: 18.8719354 },
    { lat: 48.8874672, lng: 18.8715174 },
    { lat: 48.8910225, lng: 18.8729872 },
    { lat: 48.891037, lng: 18.8729847 },
    { lat: 48.8910571, lng: 18.8730046 },
    { lat: 48.8915447, lng: 18.8706651 },
    { lat: 48.8915682, lng: 18.8705329 },
    { lat: 48.892141, lng: 18.8707973 },
    { lat: 48.8935135, lng: 18.8714565 },
    { lat: 48.8936117, lng: 18.8715244 },
    { lat: 48.893707, lng: 18.8716228 },
    { lat: 48.8939557, lng: 18.871989 },
    { lat: 48.8945748, lng: 18.8729298 },
    { lat: 48.8946073, lng: 18.8729693 },
    { lat: 48.8947374, lng: 18.8727153 },
    { lat: 48.8948024, lng: 18.8723801 },
    { lat: 48.8947909, lng: 18.8718726 },
    { lat: 48.8947765, lng: 18.871813 },
    { lat: 48.8952902, lng: 18.8718045 },
    { lat: 48.8959978, lng: 18.8716606 },
    { lat: 48.8965825, lng: 18.8714753 },
    { lat: 48.8968112, lng: 18.8713604 },
    { lat: 48.8970049, lng: 18.871249 },
    { lat: 48.8972702, lng: 18.8710373 },
    { lat: 48.898288, lng: 18.8701954 },
    { lat: 48.8984731, lng: 18.8700911 },
    { lat: 48.8986117, lng: 18.8700216 },
    { lat: 48.8990539, lng: 18.8696754 },
    { lat: 48.899526, lng: 18.8694267 },
    { lat: 48.8998442, lng: 18.8693252 },
    { lat: 48.9003162, lng: 18.8692492 },
    { lat: 48.9003355, lng: 18.8692061 },
    { lat: 48.9003307, lng: 18.8691601 },
    { lat: 48.9000986, lng: 18.8675186 },
    { lat: 48.8997986, lng: 18.8647343 },
    { lat: 48.8996943, lng: 18.8638074 },
    { lat: 48.8996852, lng: 18.8637396 },
    { lat: 48.899181, lng: 18.8592053 },
    { lat: 48.8990955, lng: 18.8590775 },
    { lat: 48.8990166, lng: 18.8590564 },
    { lat: 48.8983388, lng: 18.8592948 },
    { lat: 48.8980549, lng: 18.8593654 },
    { lat: 48.8967735, lng: 18.8598786 },
    { lat: 48.8966871, lng: 18.8597147 },
    { lat: 48.896812, lng: 18.859448 },
    { lat: 48.8968482, lng: 18.8593147 },
    { lat: 48.896865, lng: 18.8591593 },
    { lat: 48.8966865, lng: 18.8574718 },
    { lat: 48.896579, lng: 18.8564768 },
    { lat: 48.896535, lng: 18.8563889 },
    { lat: 48.8964591, lng: 18.8559033 },
    { lat: 48.8964172, lng: 18.8555947 },
    { lat: 48.896416, lng: 18.8555074 },
    { lat: 48.8964438, lng: 18.8550619 },
    { lat: 48.8967561, lng: 18.8528528 },
    { lat: 48.8970678, lng: 18.8506561 },
    { lat: 48.8970735, lng: 18.8506154 },
    { lat: 48.8971772, lng: 18.8505691 },
    { lat: 48.8973909, lng: 18.850362 },
    { lat: 48.8965858, lng: 18.8474997 },
    { lat: 48.8965788, lng: 18.8474764 },
    { lat: 48.8965422, lng: 18.8473554 },
    { lat: 48.8959466, lng: 18.8458243 },
    { lat: 48.8958622, lng: 18.8456329 },
    { lat: 48.8958016, lng: 18.8454434 },
    { lat: 48.8954001, lng: 18.8444135 },
    { lat: 48.8950631, lng: 18.8435667 },
    { lat: 48.8951535, lng: 18.8434172 },
    { lat: 48.8951158, lng: 18.843364 },
    { lat: 48.8945891, lng: 18.8417929 },
    { lat: 48.8943291, lng: 18.8413659 },
    { lat: 48.8938546, lng: 18.8408888 },
    { lat: 48.8934694, lng: 18.8406328 },
    { lat: 48.8932333, lng: 18.8378995 },
    { lat: 48.8929437, lng: 18.8362086 },
    { lat: 48.8928201, lng: 18.8352769 },
    { lat: 48.8927132, lng: 18.833189 },
    { lat: 48.8926861, lng: 18.8327755 },
    { lat: 48.8924417, lng: 18.8322395 },
    { lat: 48.8922593, lng: 18.8313736 },
    { lat: 48.892226, lng: 18.8306211 },
    { lat: 48.8921081, lng: 18.8296508 },
    { lat: 48.8921509, lng: 18.8294085 },
    { lat: 48.8924127, lng: 18.8290211 },
    { lat: 48.8926662, lng: 18.8285831 },
    { lat: 48.892762, lng: 18.8283204 },
    { lat: 48.8928228, lng: 18.8279713 },
    { lat: 48.8928685, lng: 18.827504 },
    { lat: 48.8926877, lng: 18.8275202 },
    { lat: 48.8924512, lng: 18.827353 },
    { lat: 48.8923522, lng: 18.8272477 },
    { lat: 48.8923074, lng: 18.8271226 },
    { lat: 48.892217, lng: 18.8268359 },
    { lat: 48.8920027, lng: 18.8259216 },
    { lat: 48.8918486, lng: 18.8251451 },
    { lat: 48.8918065, lng: 18.8249846 },
    { lat: 48.8917135, lng: 18.8246266 },
    { lat: 48.8916031, lng: 18.8242617 },
    { lat: 48.8912736, lng: 18.8236267 },
    { lat: 48.8907214, lng: 18.8228483 },
    { lat: 48.8903771, lng: 18.8225156 },
    { lat: 48.8901763, lng: 18.8222752 },
    { lat: 48.8900789, lng: 18.8220908 },
    { lat: 48.8893384, lng: 18.8203621 },
    { lat: 48.8891536, lng: 18.8200373 },
    { lat: 48.8889746, lng: 18.8197616 },
    { lat: 48.8883795, lng: 18.8191357 },
    { lat: 48.8879456, lng: 18.8187303 },
    { lat: 48.8879249, lng: 18.8186991 },
    { lat: 48.8872416, lng: 18.8181547 },
    { lat: 48.8870591, lng: 18.8180529 },
    { lat: 48.8869138, lng: 18.8180126 },
    { lat: 48.8866255, lng: 18.8173884 },
    { lat: 48.886332, lng: 18.8164912 },
    { lat: 48.8861308, lng: 18.8160706 },
    { lat: 48.8860841, lng: 18.8160593 },
    { lat: 48.885774, lng: 18.8157807 },
    { lat: 48.8852276, lng: 18.8157234 },
    { lat: 48.8846982, lng: 18.8158211 },
    { lat: 48.8841343, lng: 18.8159067 },
    { lat: 48.8834793, lng: 18.8159749 },
    { lat: 48.8831839, lng: 18.8159955 },
    { lat: 48.8830141, lng: 18.8159208 },
    { lat: 48.8828132, lng: 18.8157431 },
    { lat: 48.8824313, lng: 18.81586 },
    { lat: 48.8821164, lng: 18.8159611 },
    { lat: 48.8816798, lng: 18.8160218 },
    { lat: 48.8815181, lng: 18.8159247 },
    { lat: 48.8813664, lng: 18.8160424 },
    { lat: 48.8813341, lng: 18.8162258 },
    { lat: 48.8812672, lng: 18.8163069 },
    { lat: 48.8809931, lng: 18.8164747 },
    { lat: 48.8808035, lng: 18.8165371 },
    { lat: 48.8807161, lng: 18.8166793 },
    { lat: 48.8805945, lng: 18.816772 },
    { lat: 48.8805322, lng: 18.8168726 },
    { lat: 48.8804527, lng: 18.8168215 },
    { lat: 48.8802684, lng: 18.816898 },
    { lat: 48.8801943, lng: 18.8170548 },
    { lat: 48.8798388, lng: 18.8172036 },
    { lat: 48.8793619, lng: 18.8176561 },
    { lat: 48.8792805, lng: 18.8176096 },
    { lat: 48.8785757, lng: 18.8180008 },
    { lat: 48.8780217, lng: 18.8182772 },
    { lat: 48.8772063, lng: 18.8186866 },
    { lat: 48.87679, lng: 18.8188778 },
    { lat: 48.8762725, lng: 18.8191592 },
    { lat: 48.8760844, lng: 18.8191877 },
    { lat: 48.8747866, lng: 18.8198857 },
    { lat: 48.8739602, lng: 18.820258 },
    { lat: 48.873229, lng: 18.8205276 },
    { lat: 48.8730101, lng: 18.8206536 },
    { lat: 48.8726033, lng: 18.8210973 },
    { lat: 48.8722676, lng: 18.8214271 },
    { lat: 48.8717578, lng: 18.8216656 },
    { lat: 48.8717037, lng: 18.8216878 },
    { lat: 48.8713642, lng: 18.8217065 },
    { lat: 48.8709492, lng: 18.821641 },
    { lat: 48.8705291, lng: 18.8215421 },
    { lat: 48.8704244, lng: 18.8215238 },
    { lat: 48.8702283, lng: 18.8213065 },
    { lat: 48.8700288, lng: 18.8211996 },
    { lat: 48.869653, lng: 18.8210424 },
    { lat: 48.8689696, lng: 18.8209097 },
    { lat: 48.8685022, lng: 18.8207685 },
    { lat: 48.867408, lng: 18.8201333 },
    { lat: 48.8671853, lng: 18.819989 },
  ],
};

export default UnitsTurčianskeTeplice;
