export const averagePriceByCodeService = {
  getAveragePrice: async (category, statusCode, usableArea, landArea, location) => {
    // console.log("locationCode = ", typeof location);
    // console.log("landArea = ", landArea);
    // console.log("usableArea = ", usableArea);
    // console.log("statusCode = ", statusCode);
    // console.log("category = ", category);
    return await fetch(process.env.REACT_APP_SERVICE_CALCULATOR + `${category}`, {
      // headers: {
      //   status: statusCode,
      //   landarea: landArea,
      //   usablearea: usableArea,
      //   municipalcode: location,
      // },
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        statusCode: statusCode,
        usableArea: usableArea,
        landArea: landArea,
        locationCode: typeof location === "string" ? location : location[0],
        categoryCode: category,
      }),
    });
  },
};
