import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { motion } from "framer-motion";

export default function Partners({
  office,
  size,
  subMenu,
  setSubMenu,
  previous,
  subjects,
  setSubjects,
  setSubjectsMenu,
  subjectsMenu,
  indx,
  indxPrev,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const navigate = () => {
    if (subjects.ROffice || subjects.FBroker) {
      setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: true, FBroker: false });
      if (subjects.ROffice) {
        setSubjectsMenu({ ...subjectsMenu, REO: false, FI: true, FIB: false, REB: false, menu: "ROffice" });
      }
      if (subjects.FBroker) {
        setSubjectsMenu({ ...subjectsMenu, REO: false, FI: true, FIB: false, REB: false, menu: "FBroker" });
      }
    }
    if (subjects.RBroker || subjects.FInstitution) {
      setSubjects({ ...subjects, ROffice: true, RBroker: false, FInstitution: false, FBroker: false });
      if (subjects.RBroker) {
        setSubjectsMenu({ ...subjectsMenu, REO: true, FI: false, FIB: false, REB: false, menu: "RBroker" });
      }
      if (subjects.FInstitution) {
        setSubjectsMenu({ ...subjectsMenu, REO: true, FI: false, FIB: false, REB: false, menu: "FInstitution" });
      }
    }
  };

  return subMenu.rating ? (
    <motion.div
      className={clsx("flex flex-col", {
        "grid sm:grid-cols-2": !isSmallDevice && size.full,
      })}
      initial={{ x: previous !== "sh" ? 500 : -500 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
    >
      {office?.partners?.length === undefined ? (
        <div className="w-full flex justify-center items-center">Realitná kacelária nemá parnterov</div>
      ) : (
        office?.partners?.map((office, index) => (
          <div
            key={index}
            className={clsx(
              "transition-all duration-500 ease-linear min-h-max w-full flex-col justify-center sm:flex overflow-ellipsis p-2",
              {},
            )}
          >
            <div
              onClick={() => {
                indxPrev.current = indx.current;
                indx.current = index;
                navigate();
                setSubMenu({
                  ...subMenu,
                  lv: false,
                  interest: false,
                  owner: true,
                  disp: false,
                  rating: false,
                  share: false,
                });
              }}
              className={clsx(
                "w-full border border-opacity-50 border-sky-500 h-auto flex flex-col gap-2 cursor-pointer hover:bg-sky-300 rounded-lg shadow-lg shadow-sky-200 text-xs",
                {},
              )}
            >
              <div className={clsx("flex flex-row w-full gap-x-2 items-center", {})}>
                <img src={office?.url} alt="" className="w-1/3 h-24 rounded-l-lg object-fit" />
                <div className={clsx("w-2/3 flex justify-between", {})}>
                  <div className="flex flex-row w-full h-24 p-[2px] items-start justify-between ">
                    <div className="flex flex-col w-2/3 gap-x-2 gap-y-[2px]">
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden">
                        {office?.brokerName}
                      </p>
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden">{office?.name}</p>
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden">{office?.tel}</p>
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden">{office?.email}</p>
                      <p className="h-auto flex flex-row justify-start items-center overflow-hidden gap-1">
                        {office?.street} {office?.number}, {office?.psc} {office?.city}
                      </p>
                    </div>
                    <div className="w-1/3 h-full flex justify-end items-end text-xl font-semibold">
                      <img src={office?.url} alt="" className="w-20 h-10 rounded-l-lg object-contain" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </motion.div>
  ) : null;
}
