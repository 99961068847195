import React, { useEffect, useMemo, useState } from "react";
import { forSaleService } from "../services/forSaleService";
import HistoryPanel from "./SwitchParts/HistoryPanel";
import RealEstatePanel from "./SwitchParts/RealEstatePanel";
import FinancialInstitutionPanel from "./SwitchParts/FinancialInstitutionPanel";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import LoginNav from "./SwitchParts/SmallScreen/LoginNav";
import HelperNav from "./SwitchParts/SmallScreen/HelperNav";
import OptionsNav from "./SwitchParts/SmallScreen/OptionsNav";
import PropertiesNav from "./SwitchParts/SmallScreen/PropertiesNav";
import SubjectsNav from "./SwitchParts/SmallScreen/SubjectsNav";
import HomeNav from "./SwitchParts/SmallScreen/HomeNav";

export default function Switch({
  historyItems,
  map,
  setCurrentIndex,
  setVisibilityOfMarkers,
  setResponseForSale,
  setViewSale,
  setCurrentItem,
  setGallery,
  openLeftBar,
  setLeftBar,
  setNavSmall,
  navSmall,
  isLogged,
  infoUser,
  setForSale,
  forSale,
  setHistory,
  setHistoryMenu,
  viewSale,
  setSubMenu,
  subMenu,
  subjects,
  setSubjects,
  setFilterSale,
  optSubMenu,
  setOptSubMenu,
  setMapActive,
  setPriceMap,
  priceMap,
  setGotResponse,
  PMResult,
  setPMResult,
  setZoom,
}) {
  const [openSaved, setOpenSaved] = useState(false);

  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const reversedHistoryItems = useMemo(() => {
    return [...historyItems].slice(0, 10);
  }, [historyItems]);

  useEffect(() => {
    const callSales = async () => {
      const response = await forSaleService.getAll();
      const resp = await response.json();
      // console.log("resp = ", resp);
      setResponseForSale(resp);
    };
    callSales();
  }, [setResponseForSale]);
  // const reversedAndSlicedHistoryItems = useMemo(() => {
  //   return [...historyItems].slice(0, 3);
  // }, [historyItems]);

  return (
    <>
      <div className={`w-full relative flex flex-row sm:flex-col sm:mt-4 z-40 sm:gap-3`}>
        <div
          className={clsx("flex flex-row justify-between sm:flex-col sm:gap-3", {
            "w-full": isSmallDevice,
          })}
        >
          <>
            <HomeNav
              setMapActive={setMapActive}
              setPriceMap={setPriceMap}
              priceMap={priceMap}
              setGotResponse={setGotResponse}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
              setNavSmall={setNavSmall}
              navSmall={navSmall}
              setForSale={setForSale}
              forSale={forSale}
              setGallery={setGallery}
              setHistory={setHistory}
              setHistoryMenu={setHistoryMenu}
              setSubMenu={setSubMenu}
              subMenu={subMenu}
              setViewSale={setViewSale}
              setCurrentItem={setCurrentItem}
              viewSale={viewSale}
              subjects={subjects}
              setSubjects={setSubjects}
              setFilterSale={setFilterSale}
              optSubMenu={optSubMenu}
              setOptSubMenu={setOptSubMenu}
              PMResult={PMResult}
              setPMResult={setPMResult}
              map={map}
              setZoom={setZoom}
            />
            <PropertiesNav
              setNavSmall={setNavSmall}
              navSmall={navSmall}
              setForSale={setForSale}
              forSale={forSale}
              setGallery={setGallery}
              setHistory={setHistory}
              setHistoryMenu={setHistoryMenu}
              viewSale={viewSale}
              setSubMenu={setSubMenu}
              subMenu={subMenu}
              setViewSale={setViewSale}
              setCurrentItem={setCurrentItem}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
              subjects={subjects}
              setSubjects={setSubjects}
              setFilterSale={setFilterSale}
              optSubMenu={optSubMenu}
              setOptSubMenu={setOptSubMenu}
            />
            <SubjectsNav
              setNavSmall={setNavSmall}
              navSmall={navSmall}
              setForSale={setForSale}
              forSale={forSale}
              setGallery={setGallery}
              setHistory={setHistory}
              setHistoryMenu={setHistoryMenu}
              viewSale={viewSale}
              setSubMenu={setSubMenu}
              subMenu={subMenu}
              setViewSale={setViewSale}
              setCurrentItem={setCurrentItem}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
              subjects={subjects}
              setSubjects={setSubjects}
              optSubMenu={optSubMenu}
              setOptSubMenu={setOptSubMenu}
            />
            {/* <HelperNav
              setNavSmall={setNavSmall}
              navSmall={navSmall}
              setForSale={setForSale}
              forSale={forSale}
              setGallery={setGallery}
              setHistory={setHistory}
              setHistoryMenu={setHistoryMenu}
              viewSale={viewSale}
              setSubMenu={setSubMenu}
              subMenu={subMenu}
              setViewSale={setViewSale}
              setCurrentItem={setCurrentItem}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
              subjects={subjects}
              setSubjects={setSubjects}
              optSubMenu={optSubMenu}
              setOptSubMenu={setOptSubMenu}
            /> */}
            <OptionsNav
              setNavSmall={setNavSmall}
              navSmall={navSmall}
              setForSale={setForSale}
              forSale={forSale}
              setGallery={setGallery}
              setHistory={setHistory}
              setHistoryMenu={setHistoryMenu}
              viewSale={viewSale}
              setSubMenu={setSubMenu}
              subMenu={subMenu}
              setViewSale={setViewSale}
              setCurrentItem={setCurrentItem}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
              subjects={subjects}
              setSubjects={setSubjects}
              optSubMenu={optSubMenu}
              setOptSubMenu={setOptSubMenu}
            />
            <LoginNav
              setNavSmall={setNavSmall}
              navSmall={navSmall}
              isLogged={isLogged}
              infoUser={infoUser}
              setForSale={setForSale}
              forSale={forSale}
              setGallery={setGallery}
              setHistory={setHistory}
              setHistoryMenu={setHistoryMenu}
              viewSale={viewSale}
              setSubMenu={setSubMenu}
              subMenu={subMenu}
              setViewSale={setViewSale}
              setCurrentItem={setCurrentItem}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
              subjects={subjects}
              setSubjects={setSubjects}
              optSubMenu={optSubMenu}
              setOptSubMenu={setOptSubMenu}
            />
          </>
          {/* )} */}
        </div>
        <hr className="border-1 border-black " />
        {/* <HistoryList
          historyItems={reversedAndSlicedHistoryItems}
          map={map}
          setCurrentIndex={setCurrentIndex}
          setVisibilityOfMarkers={setVisibilityOfMarkers}
        /> */}
      </div>
      {/*History div*/}
      <HistoryPanel
        openLeftBar={openLeftBar}
        setLeftBar={setLeftBar}
        setVisibilityOfMarkers={setVisibilityOfMarkers}
        map={map}
        reversedHistoryItems={reversedHistoryItems}
        setCurrentIndex={setCurrentIndex}
      />
      {/*History div*/}
      {/*Saved div*/}
      <div
        className={`transition-all duration-200 ease-linear absolute top-0 left-16 bg-red-500 w-0 h-screen flex justify-center ${
          openSaved ? "w-96" : ""
        }`}
      >
        {openSaved ? <h1>Uložené</h1> : null}
      </div>
      {/*ForSale div*/}
      {/* {!isSmallDevice ? (
        <ForSale
          forSaleItems={responseForSale}
          setVisibilityOfMarkers={setVisibilityOfMarkers}
          setCurrentItem={setCurrentItem}
          map={map}
          gallery={gallery}
          openLeftBar={openLeftBar}
          visibilityOfMarkers={visibilityOfMarkers}
          setForSaleInfo={setForSaleInfo}
          setViewSingleSale={setViewSingleSale}
        />
      ) : null} */}
      {/*ForSale div*/}
      {/*RealEstate*/}
      <RealEstatePanel openLeftBar={openLeftBar} />
      {/*RealEstate*/}
      {/*Financne Institucie*/}
      <FinancialInstitutionPanel openLeftBar={openLeftBar} />
      {/*Financne Institucie*/}
    </>
  );
}
