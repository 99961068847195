import { forSaleService } from "../../services/forSaleService";
import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import Header from "./EditParts/Header";
// import ScrollMenu from "./EditParts/ScrollMenu";
import Price from "./EditParts/Price";
import Parameters from "./EditParts/Parameters";
import Description from "./EditParts/Description";
import Photos from "./EditParts/Photos";
import Url from "./EditParts/Url";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faWindowMaximize, faWindowMinimize, faWindowRestore, faX } from "@fortawesome/free-solid-svg-icons";

export default function NewEditMenu({ gotResponse }) {
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [isLoading, setLoading] = useState({ return: false, save: false });
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const [size, setSize] = useState({ min: false, mid: true, full: false });
  const navigate = useNavigate();
  //   const [menuExpanded, setMenuExpanded] = useState(false);
  //   const [previous, setPrevious] = useState("");
  //   const [category, setCategory] = useState({ foto: false, desc: false, price: true, param: false });
  const [inputValues, setInputValues] = useState({
    price: "",
    area: "",
    condition: "",
    type: "",
    description: "",
    url: "",
    photos: [],
  });

  useEffect(() => {
    getProperty();
    // eslint-disable-next-line
  }, []);

  const getProperty = async () => {
    setLoading({ ...isLoading, return: true });
    const request = JSON.parse(JSON.stringify({ email: JSON.parse(localStorage.getItem("userData"))?.m }));
    const response = await forSaleService.getMyProperties(request);
    const myProperties = await response.json();

    const path = window.location.pathname.split("/");
    const id = path[2];
    const property = myProperties?.items?.map((a) => {
      if (a.id === id) {
        return a;
      } else {
        return null;
      }
    });
    for (let i = 0; i < property?.length; i++) {
      if (property[i] !== null) {
        const params = JSON.parse(property[i]?.parameters);
        setInputValues({
          ...inputValues,
          price: property[i]?.price,
          area: params?.area,
          condition: params?.condition,
          type: params?.type,
          description: property[i]?.description,
          url: property[i]?.link,
          photos: property[i]?.img,
        });
        setPropertyInfo(property[i]);
        setLoading({ ...isLoading, return: false });
        return;
      }
    }
  };

  const editData = async () => {
    setLoading({ ...isLoading, save: true });
    const area = propertyInfo?.parameters !== undefined ? Number(JSON.parse(propertyInfo?.parameters).area) : "";
    const condition = propertyInfo?.parameters !== undefined ? JSON.parse(propertyInfo?.parameters).condition : "";
    const type = propertyInfo?.parameters !== undefined ? JSON.parse(propertyInfo?.parameters).type : "";
    const parameters = JSON.stringify({
      area: inputValues.area !== "" ? inputValues.area : area,
      condition: inputValues.condition !== "" ? inputValues.condition : condition,
      type: inputValues.type !== "" ? inputValues.type : type,
    });
    const photos = inputValues?.photos.length > 0 ? inputValues?.photos : propertyInfo?.img;
    console.log("photos = ", JSON.stringify(photos));
    const price = inputValues.price !== "" ? inputValues.price : propertyInfo.price;
    const description = inputValues.description !== "" ? inputValues.description : propertyInfo.description;
    const path = window.location.pathname.split("/");
    const id = path[2];
    const request = JSON.parse(
      JSON.stringify({
        id: id,
        price: price,
        photos: photos,
        description: description,
        parameters: parameters,
      }),
    );
    const response = await forSaleService.editProperty(request);
    console.log("request sent ", response);
    setLoading({ ...isLoading, save: false });
  };

  const changeSize = () => {
    if (size.mid) {
      setSize({ ...size, mid: false, full: true, min: false });
    } else {
      setSize({ ...size, mid: true, full: false, min: false });
    }
  };

  const minimize = () => {
    if (size.mid) {
      setSize({ ...size, min: true, mid: false, full: true });
    }
    if (size.full) {
      setSize({ ...size, min: true, mid: true, full: false });
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className={clsx(
          "transition-all duration-200 ease-linear overflow-scroll absolute h-auto bg-sky-100 flex flex-col items-center no-scrollbar select-none z-50",
          {
            "!h-[calc(100%-64px)] !rounded-none": size.full && !size.min && isSmallDevice,
            "!h-[40%]": size.mid && !size.min && isSmallDevice,
            "!h-[10%]": size.min && isSmallDevice,
            "bottom-0 left-0 w-full pb-5 rounded-t-xl": isSmallDevice,
            "top-0 left-16 !h-full": !isSmallDevice,
            "w-96": !isSmallDevice && size.mid,
            "w-[calc(100%-64px)]": !isSmallDevice && size.full,
            "!overflow-hidden": gotResponse !== "true",
          },
        )}
        // initial={{ opacity: 0, scale: 1, y: 300, height: 60 }}
        initial={{ opacity: 0, scale: 1, y: isSmallDevice ? 300 : 0, x: !isSmallDevice ? -500 : 0 }}
        animate={{ opacity: 1, scale: 1, y: isSmallDevice ? 0 : 0, x: !isSmallDevice ? 0 : 0 }}
        exit={{ opacity: 1, scale: 1, y: isSmallDevice ? 350 : 0, x: !isSmallDevice ? -500 : 0 }}
        // animate={{
        //   opacity: 1,
        //   scale: 1,
        //   y: 0,
        //   height: window.innerHeight - 64,
        // }}
        transition={{
          duration: 0.6,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <div
          className={clsx(
            "sticky top-0 right-0 rounded-t-lg w-full h-8 bg-sky-100 border-b border-opacity-50 border-b-sky-500 z-10 grid ",
            {
              "grid-cols-[70%_10%_10%_10%]": isSmallDevice,
              "grid-cols-[80%_10%_10%]": !isSmallDevice,
            },
          )}
        >
          <div className="w-full h-full flex justify-start items-center px-1"></div>
          {isSmallDevice ? (
            <button onClick={() => minimize()} className="w-full h-full border-l border-opacity-50 border-l-sky-500">
              <FontAwesomeIcon icon={faWindowMinimize} />
            </button>
          ) : null}
          <button onClick={() => changeSize()} className="w-full h-full border-x border-opacity-50 border-x-sky-500">
            {size.full ? <FontAwesomeIcon icon={faWindowRestore} /> : null}
            {size.mid ? <FontAwesomeIcon icon={faWindowMaximize} /> : null}
          </button>
          <button onClick={() => navigate("/sprava-uctu/moje-nehnutelnosti")} className="w-full h-full">
            <FontAwesomeIcon icon={faX} />
          </button>
        </div>
        <Header
          propertyInfo={propertyInfo}
          inputValues={inputValues}
          editData={editData}
          getProperty={getProperty}
          isLoading={isLoading}
        />
        <div
          className={clsx("w-full h-auto overflow-y-scroll p-2", {
            "no-scrollbar": isSmallDevice,
          })}
        >
          {/* <ScrollMenu category={category} showMenu={showMenu} /> */}
          <Url setInputValues={setInputValues} inputValues={inputValues} propertyInfo={propertyInfo} />
          <Photos setInputValues={setInputValues} inputValues={inputValues} />
          <Price setInputValues={setInputValues} inputValues={inputValues} />
          <Parameters setInputValues={setInputValues} inputValues={inputValues} />
          <Description setInputValues={setInputValues} inputValues={inputValues} />
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
