export default function Legend({ priceMap }) {
  return priceMap.region || priceMap.district || priceMap.city ? (
    <div className="absolute bottom-16 right-2 sm:right-4 sm:bottom-6 w-48 h-16 flex flex-col justify-center gap-2 select-none rounded-xl bg-sky-100 p-2 ">
      <div className="w-full flex flex-row justify-between">
        <p>100€</p>
        <p>2500€</p>
        <p>5000€</p>
      </div>
      <div className="w-full flex flex-row h-2">
        <div className="w-1/2 rounded-l-xl bg-gradient-to-r from-green-500 via-green-400 to-yellow-300"></div>
        <div className="w-1/2 rounded-r-xl bg-gradient-to-r from-yellow-300 via-orange-400 to-red-500"></div>
      </div>
    </div>
  ) : null;
}
