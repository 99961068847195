export const saveAddressService = {
  setSaveAddressService: async (
    email,
    street,
    number,
    city,
    postalCode,
    country,
    latitude,
    longitude,
    // stav_ku_dnu,
    avgPrice,
    kataster,
    flat,
    entrance,
    activityId,
    cisloLV,
  ) => {
    return await fetch(process.env.REACT_APP_SAVE_ADDRESS_SERVICE, {
      headers: {
        email: email,
        street: street,
        number: number,
        city: city,
        postalCode: postalCode,
        country: country,
        latitude: latitude,
        longitude: longitude,
        // stavkudnu: stav_ku_dnu,
        avgprice: avgPrice,
        kataster: kataster,
        flat: flat,
        entrance: entrance,
        activityId: activityId,
        cisloLV: cisloLV,
      },
    });
  },
};
