const UnitsBanskáBystrica = {
  Králiky: [
    { lat: 48.7407721, lng: 19.0443 },
    { lat: 48.740778, lng: 19.0442575 },
    { lat: 48.7409691, lng: 19.0428283 },
    { lat: 48.7409522, lng: 19.0415246 },
    { lat: 48.739859, lng: 19.0403401 },
    { lat: 48.7383612, lng: 19.038913 },
    { lat: 48.7382471, lng: 19.0385268 },
    { lat: 48.7382539, lng: 19.0383935 },
    { lat: 48.7379274, lng: 19.0375469 },
    { lat: 48.7378067, lng: 19.0370286 },
    { lat: 48.7377653, lng: 19.0366329 },
    { lat: 48.7380576, lng: 19.0366376 },
    { lat: 48.7384996, lng: 19.0369302 },
    { lat: 48.738727, lng: 19.0369884 },
    { lat: 48.7389628, lng: 19.0368824 },
    { lat: 48.7387785, lng: 19.0361978 },
    { lat: 48.7389151, lng: 19.0349898 },
    { lat: 48.7390562, lng: 19.034949 },
    { lat: 48.739044, lng: 19.0346814 },
    { lat: 48.739218, lng: 19.0345346 },
    { lat: 48.7392783, lng: 19.0342948 },
    { lat: 48.7392087, lng: 19.0338303 },
    { lat: 48.7395675, lng: 19.0341066 },
    { lat: 48.7398973, lng: 19.0340513 },
    { lat: 48.740388, lng: 19.034504 },
    { lat: 48.7407051, lng: 19.0344898 },
    { lat: 48.7412046, lng: 19.035498 },
    { lat: 48.7416328, lng: 19.0353842 },
    { lat: 48.7417258, lng: 19.0349449 },
    { lat: 48.741512, lng: 19.0339653 },
    { lat: 48.7419501, lng: 19.0335964 },
    { lat: 48.7423718, lng: 19.0333523 },
    { lat: 48.7416382, lng: 19.0327756 },
    { lat: 48.7413192, lng: 19.0282876 },
    { lat: 48.7410525, lng: 19.0284307 },
    { lat: 48.7408016, lng: 19.0291168 },
    { lat: 48.7406321, lng: 19.0291934 },
    { lat: 48.7404949, lng: 19.0289313 },
    { lat: 48.7396187, lng: 19.0292184 },
    { lat: 48.7393617, lng: 19.0291121 },
    { lat: 48.7389671, lng: 19.0292882 },
    { lat: 48.7388043, lng: 19.0292555 },
    { lat: 48.7385561, lng: 19.0294543 },
    { lat: 48.7372989, lng: 19.0274117 },
    { lat: 48.7366579, lng: 19.0260771 },
    { lat: 48.7349687, lng: 19.0229521 },
    { lat: 48.7340149, lng: 19.0216785 },
    { lat: 48.7336082, lng: 19.0192177 },
    { lat: 48.7336689, lng: 19.0189672 },
    { lat: 48.7333912, lng: 19.0170376 },
    { lat: 48.7331702, lng: 19.0168995 },
    { lat: 48.7324654, lng: 19.0126681 },
    { lat: 48.732282, lng: 19.0120957 },
    { lat: 48.7319761, lng: 19.0114409 },
    { lat: 48.731883, lng: 19.0115388 },
    { lat: 48.7315646, lng: 19.012514 },
    { lat: 48.7309874, lng: 19.0146369 },
    { lat: 48.7308184, lng: 19.0156027 },
    { lat: 48.7304788, lng: 19.0166264 },
    { lat: 48.7300298, lng: 19.0169813 },
    { lat: 48.729771, lng: 19.0171014 },
    { lat: 48.7294612, lng: 19.0169296 },
    { lat: 48.7289974, lng: 19.0168069 },
    { lat: 48.7287291, lng: 19.0165849 },
    { lat: 48.7258251, lng: 19.0250801 },
    { lat: 48.7260942, lng: 19.0252488 },
    { lat: 48.7262636, lng: 19.0251868 },
    { lat: 48.7264238, lng: 19.0252116 },
    { lat: 48.7265197, lng: 19.0251035 },
    { lat: 48.7269663, lng: 19.0252407 },
    { lat: 48.728342, lng: 19.026525 },
    { lat: 48.7289194, lng: 19.0265955 },
    { lat: 48.7289676, lng: 19.027036 },
    { lat: 48.7308553, lng: 19.0288622 },
    { lat: 48.7320797, lng: 19.0298295 },
    { lat: 48.7322476, lng: 19.0302741 },
    { lat: 48.7322564, lng: 19.0306038 },
    { lat: 48.7323415, lng: 19.0308408 },
    { lat: 48.7325061, lng: 19.0317601 },
    { lat: 48.7327807, lng: 19.0313007 },
    { lat: 48.7327867, lng: 19.0310636 },
    { lat: 48.7331783, lng: 19.0300358 },
    { lat: 48.7325514, lng: 19.0337167 },
    { lat: 48.732607, lng: 19.0343296 },
    { lat: 48.7325982, lng: 19.0347845 },
    { lat: 48.732482, lng: 19.0358396 },
    { lat: 48.7322651, lng: 19.0365027 },
    { lat: 48.7322738, lng: 19.0377109 },
    { lat: 48.7325616, lng: 19.0387054 },
    { lat: 48.7325668, lng: 19.0390552 },
    { lat: 48.7327385, lng: 19.0394926 },
    { lat: 48.7329915, lng: 19.0396319 },
    { lat: 48.7336677, lng: 19.0401897 },
    { lat: 48.7339156, lng: 19.0405499 },
    { lat: 48.7351447, lng: 19.0410933 },
    { lat: 48.7356563, lng: 19.0419208 },
    { lat: 48.736263, lng: 19.0422849 },
    { lat: 48.7367371, lng: 19.0428113 },
    { lat: 48.7369635, lng: 19.0421574 },
    { lat: 48.73785, lng: 19.0428384 },
    { lat: 48.7383425, lng: 19.043441 },
    { lat: 48.7384616, lng: 19.0434559 },
    { lat: 48.7385065, lng: 19.0436105 },
    { lat: 48.7389362, lng: 19.0439502 },
    { lat: 48.7394187, lng: 19.0451215 },
    { lat: 48.7398172, lng: 19.045762 },
    { lat: 48.7402853, lng: 19.0449569 },
    { lat: 48.7405698, lng: 19.0444682 },
    { lat: 48.7407721, lng: 19.0443 },
  ],
  BanskáBystrica: [
    { lat: 48.7398704, lng: 19.0805292 },
    { lat: 48.7409045, lng: 19.0795814 },
    { lat: 48.740941, lng: 19.0792525 },
    { lat: 48.7410927, lng: 19.0792431 },
    { lat: 48.741222, lng: 19.078804 },
    { lat: 48.741308, lng: 19.0788078 },
    { lat: 48.7414526, lng: 19.0778223 },
    { lat: 48.7413728, lng: 19.0776432 },
    { lat: 48.7411263, lng: 19.0776977 },
    { lat: 48.7409504, lng: 19.0762957 },
    { lat: 48.7403422, lng: 19.0750377 },
    { lat: 48.740442, lng: 19.0748935 },
    { lat: 48.7405584, lng: 19.0751481 },
    { lat: 48.7410908, lng: 19.075629 },
    { lat: 48.7418854, lng: 19.0759638 },
    { lat: 48.7420673, lng: 19.0760156 },
    { lat: 48.7422723, lng: 19.0759687 },
    { lat: 48.7424819, lng: 19.0758084 },
    { lat: 48.7423498, lng: 19.0756216 },
    { lat: 48.7423161, lng: 19.0754193 },
    { lat: 48.7424443, lng: 19.0745789 },
    { lat: 48.7434743, lng: 19.0728498 },
    { lat: 48.743599, lng: 19.0729535 },
    { lat: 48.7437104, lng: 19.0727057 },
    { lat: 48.7437126, lng: 19.0719768 },
    { lat: 48.743431, lng: 19.070579 },
    { lat: 48.7432616, lng: 19.0699436 },
    { lat: 48.743105, lng: 19.0697403 },
    { lat: 48.7432202, lng: 19.0693843 },
    { lat: 48.7428217, lng: 19.0661181 },
    { lat: 48.74277, lng: 19.0660391 },
    { lat: 48.7426056, lng: 19.0657877 },
    { lat: 48.7423777, lng: 19.0652875 },
    { lat: 48.7421519, lng: 19.064561 },
    { lat: 48.7414513, lng: 19.063823 },
    { lat: 48.7410471, lng: 19.0635877 },
    { lat: 48.7406381, lng: 19.0637509 },
    { lat: 48.7404568, lng: 19.063776 },
    { lat: 48.7399126, lng: 19.0638515 },
    { lat: 48.7395483, lng: 19.0640531 },
    { lat: 48.7362005, lng: 19.0647385 },
    { lat: 48.7356583, lng: 19.06447 },
    { lat: 48.7352803, lng: 19.0645964 },
    { lat: 48.734967, lng: 19.064535 },
    { lat: 48.7347995, lng: 19.0646577 },
    { lat: 48.7345431, lng: 19.0646227 },
    { lat: 48.7342945, lng: 19.06409 },
    { lat: 48.7339837, lng: 19.0639886 },
    { lat: 48.7339459, lng: 19.0637051 },
    { lat: 48.7339721, lng: 19.0629421 },
    { lat: 48.7334484, lng: 19.0621876 },
    { lat: 48.7332509, lng: 19.0622016 },
    { lat: 48.7330803, lng: 19.0620825 },
    { lat: 48.7328968, lng: 19.0620892 },
    { lat: 48.7321735, lng: 19.0617589 },
    { lat: 48.7318893, lng: 19.0618478 },
    { lat: 48.7316449, lng: 19.0618086 },
    { lat: 48.7314543, lng: 19.0616714 },
    { lat: 48.731203, lng: 19.0616255 },
    { lat: 48.7307093, lng: 19.0612447 },
    { lat: 48.730655, lng: 19.0609467 },
    { lat: 48.7303253, lng: 19.0602629 },
    { lat: 48.7299567, lng: 19.0597651 },
    { lat: 48.7298364, lng: 19.0594806 },
    { lat: 48.7293908, lng: 19.0590687 },
    { lat: 48.7291869, lng: 19.0591163 },
    { lat: 48.7288658, lng: 19.0590776 },
    { lat: 48.7283129, lng: 19.0585933 },
    { lat: 48.728275, lng: 19.058397 },
    { lat: 48.7279323, lng: 19.058129 },
    { lat: 48.7276821, lng: 19.0581283 },
    { lat: 48.7274886, lng: 19.057373 },
    { lat: 48.727298, lng: 19.056897 },
    { lat: 48.7270615, lng: 19.0565837 },
    { lat: 48.7268528, lng: 19.0564448 },
    { lat: 48.726662, lng: 19.0561919 },
    { lat: 48.727868, lng: 19.0552192 },
    { lat: 48.7284255, lng: 19.054577 },
    { lat: 48.7285466, lng: 19.055287 },
    { lat: 48.7289133, lng: 19.0561943 },
    { lat: 48.7290583, lng: 19.0563405 },
    { lat: 48.7290457, lng: 19.0564523 },
    { lat: 48.7292248, lng: 19.0568315 },
    { lat: 48.7296526, lng: 19.0565937 },
    { lat: 48.7297747, lng: 19.056734 },
    { lat: 48.7297521, lng: 19.056893 },
    { lat: 48.7298646, lng: 19.0570015 },
    { lat: 48.7300184, lng: 19.0568076 },
    { lat: 48.7301295, lng: 19.0570216 },
    { lat: 48.7297352, lng: 19.0575463 },
    { lat: 48.7294737, lng: 19.0581572 },
    { lat: 48.7294746, lng: 19.0583771 },
    { lat: 48.7321683, lng: 19.0591486 },
    { lat: 48.7346922, lng: 19.0595959 },
    { lat: 48.7362798, lng: 19.060345 },
    { lat: 48.7388292, lng: 19.0611453 },
    { lat: 48.74183, lng: 19.0619935 },
    { lat: 48.7422016, lng: 19.0622579 },
    { lat: 48.7428878, lng: 19.0631565 },
    { lat: 48.7432688, lng: 19.0639435 },
    { lat: 48.7435649, lng: 19.0633453 },
    { lat: 48.7437775, lng: 19.0627309 },
    { lat: 48.7433971, lng: 19.0624496 },
    { lat: 48.7433856, lng: 19.0621498 },
    { lat: 48.7434242, lng: 19.0621129 },
    { lat: 48.7433741, lng: 19.0615821 },
    { lat: 48.7432687, lng: 19.0614112 },
    { lat: 48.7430212, lng: 19.0606565 },
    { lat: 48.7427117, lng: 19.0602593 },
    { lat: 48.7427183, lng: 19.0595366 },
    { lat: 48.7425551, lng: 19.059068 },
    { lat: 48.7423382, lng: 19.0586288 },
    { lat: 48.7418702, lng: 19.0582447 },
    { lat: 48.7389971, lng: 19.05697 },
    { lat: 48.7373954, lng: 19.0564562 },
    { lat: 48.736767, lng: 19.0563798 },
    { lat: 48.7367454, lng: 19.056288 },
    { lat: 48.7364788, lng: 19.0560974 },
    { lat: 48.7358936, lng: 19.0559118 },
    { lat: 48.7356638, lng: 19.0556262 },
    { lat: 48.7353471, lng: 19.0556696 },
    { lat: 48.7352011, lng: 19.0554345 },
    { lat: 48.7346587, lng: 19.0553879 },
    { lat: 48.7341394, lng: 19.0549244 },
    { lat: 48.7337997, lng: 19.0544658 },
    { lat: 48.733747, lng: 19.0544693 },
    { lat: 48.7335017, lng: 19.0540959 },
    { lat: 48.7333074, lng: 19.0539288 },
    { lat: 48.7332319, lng: 19.0536614 },
    { lat: 48.7330405, lng: 19.0533729 },
    { lat: 48.7330318, lng: 19.0532886 },
    { lat: 48.7326483, lng: 19.0528142 },
    { lat: 48.7325773, lng: 19.0525427 },
    { lat: 48.7320687, lng: 19.0518627 },
    { lat: 48.7316744, lng: 19.051461 },
    { lat: 48.7311649, lng: 19.0511293 },
    { lat: 48.7308664, lng: 19.051226 },
    { lat: 48.7306035, lng: 19.0511268 },
    { lat: 48.7303572, lng: 19.0507789 },
    { lat: 48.729838, lng: 19.0504072 },
    { lat: 48.7295579, lng: 19.0499236 },
    { lat: 48.7295188, lng: 19.0495056 },
    { lat: 48.7292023, lng: 19.0486769 },
    { lat: 48.7289443, lng: 19.0483654 },
    { lat: 48.7287461, lng: 19.0478756 },
    { lat: 48.7281914, lng: 19.0474459 },
    { lat: 48.7279623, lng: 19.0476214 },
    { lat: 48.7273854, lng: 19.0473579 },
    { lat: 48.7267056, lng: 19.0466383 },
    { lat: 48.7266435, lng: 19.0459176 },
    { lat: 48.7268538, lng: 19.0456825 },
    { lat: 48.7267596, lng: 19.0448363 },
    { lat: 48.7265351, lng: 19.0447452 },
    { lat: 48.7264044, lng: 19.0448337 },
    { lat: 48.726212, lng: 19.0446379 },
    { lat: 48.7261683, lng: 19.044398 },
    { lat: 48.7261991, lng: 19.0440224 },
    { lat: 48.7260516, lng: 19.0437561 },
    { lat: 48.7262489, lng: 19.0436913 },
    { lat: 48.7262647, lng: 19.0430168 },
    { lat: 48.7261537, lng: 19.042622 },
    { lat: 48.7262409, lng: 19.0422921 },
    { lat: 48.7260431, lng: 19.0416316 },
    { lat: 48.72617, lng: 19.0411939 },
    { lat: 48.7263544, lng: 19.0408904 },
    { lat: 48.7264192, lng: 19.0402519 },
    { lat: 48.7270104, lng: 19.0400135 },
    { lat: 48.7276265, lng: 19.0389908 },
    { lat: 48.7280878, lng: 19.0389237 },
    { lat: 48.7283066, lng: 19.0385476 },
    { lat: 48.7287683, lng: 19.0392078 },
    { lat: 48.7287633, lng: 19.0389045 },
    { lat: 48.7292995, lng: 19.0386259 },
    { lat: 48.729793, lng: 19.0387571 },
    { lat: 48.7299814, lng: 19.0390934 },
    { lat: 48.7303945, lng: 19.0391057 },
    { lat: 48.7306675, lng: 19.0393351 },
    { lat: 48.7309348, lng: 19.0407079 },
    { lat: 48.7312142, lng: 19.0409509 },
    { lat: 48.731438, lng: 19.0413202 },
    { lat: 48.7315246, lng: 19.0412714 },
    { lat: 48.7316157, lng: 19.0414242 },
    { lat: 48.731461, lng: 19.0417129 },
    { lat: 48.7315935, lng: 19.0421974 },
    { lat: 48.7317445, lng: 19.0423297 },
    { lat: 48.7321568, lng: 19.0424343 },
    { lat: 48.732463, lng: 19.0416385 },
    { lat: 48.7326082, lng: 19.0416554 },
    { lat: 48.7327808, lng: 19.0419513 },
    { lat: 48.7330958, lng: 19.0420749 },
    { lat: 48.7334848, lng: 19.0423475 },
    { lat: 48.7338988, lng: 19.0433847 },
    { lat: 48.7338397, lng: 19.0436679 },
    { lat: 48.7338527, lng: 19.0440403 },
    { lat: 48.7339497, lng: 19.0447589 },
    { lat: 48.7339417, lng: 19.0452744 },
    { lat: 48.7338689, lng: 19.045646 },
    { lat: 48.7345354, lng: 19.0469769 },
    { lat: 48.7357681, lng: 19.0490808 },
    { lat: 48.7366762, lng: 19.0486637 },
    { lat: 48.7369607, lng: 19.0482221 },
    { lat: 48.7374489, lng: 19.0472101 },
    { lat: 48.7384274, lng: 19.047277 },
    { lat: 48.7389542, lng: 19.0476632 },
    { lat: 48.7394364, lng: 19.0477001 },
    { lat: 48.7393593, lng: 19.0488641 },
    { lat: 48.7388783, lng: 19.0497042 },
    { lat: 48.7391371, lng: 19.0500311 },
    { lat: 48.7392408, lng: 19.0503721 },
    { lat: 48.7390488, lng: 19.0506559 },
    { lat: 48.739069, lng: 19.0507915 },
    { lat: 48.7391901, lng: 19.0508644 },
    { lat: 48.7391484, lng: 19.0510938 },
    { lat: 48.7393395, lng: 19.0515545 },
    { lat: 48.7387502, lng: 19.0515162 },
    { lat: 48.7389551, lng: 19.0522307 },
    { lat: 48.7391635, lng: 19.0522958 },
    { lat: 48.7394255, lng: 19.0521933 },
    { lat: 48.739721, lng: 19.0514748 },
    { lat: 48.7398575, lng: 19.0512938 },
    { lat: 48.7400938, lng: 19.0514883 },
    { lat: 48.7405981, lng: 19.0510902 },
    { lat: 48.7407044, lng: 19.0516806 },
    { lat: 48.740872, lng: 19.0518346 },
    { lat: 48.7410445, lng: 19.0516274 },
    { lat: 48.7409224, lng: 19.0511277 },
    { lat: 48.7408761, lng: 19.0507215 },
    { lat: 48.7407399, lng: 19.0504981 },
    { lat: 48.7407051, lng: 19.0502785 },
    { lat: 48.74081, lng: 19.0496032 },
    { lat: 48.7407315, lng: 19.0496772 },
    { lat: 48.7405544, lng: 19.0495614 },
    { lat: 48.7404731, lng: 19.0492921 },
    { lat: 48.7403751, lng: 19.0487075 },
    { lat: 48.7403792, lng: 19.0484966 },
    { lat: 48.7404452, lng: 19.0484187 },
    { lat: 48.7403144, lng: 19.0476982 },
    { lat: 48.739937, lng: 19.0469374 },
    { lat: 48.739649, lng: 19.0466578 },
    { lat: 48.7395729, lng: 19.046374 },
    { lat: 48.7392406, lng: 19.0460652 },
    { lat: 48.7392946, lng: 19.0459504 },
    { lat: 48.7398172, lng: 19.045762 },
    { lat: 48.7394187, lng: 19.0451215 },
    { lat: 48.7389362, lng: 19.0439502 },
    { lat: 48.7385065, lng: 19.0436105 },
    { lat: 48.7384616, lng: 19.0434559 },
    { lat: 48.7383425, lng: 19.043441 },
    { lat: 48.73785, lng: 19.0428384 },
    { lat: 48.7369635, lng: 19.0421574 },
    { lat: 48.7367371, lng: 19.0428113 },
    { lat: 48.736263, lng: 19.0422849 },
    { lat: 48.7356563, lng: 19.0419208 },
    { lat: 48.7351447, lng: 19.0410933 },
    { lat: 48.7339156, lng: 19.0405499 },
    { lat: 48.7336677, lng: 19.0401897 },
    { lat: 48.7329915, lng: 19.0396319 },
    { lat: 48.7327385, lng: 19.0394926 },
    { lat: 48.7325668, lng: 19.0390552 },
    { lat: 48.7325616, lng: 19.0387054 },
    { lat: 48.7322738, lng: 19.0377109 },
    { lat: 48.7322651, lng: 19.0365027 },
    { lat: 48.732482, lng: 19.0358396 },
    { lat: 48.7325982, lng: 19.0347845 },
    { lat: 48.732607, lng: 19.0343296 },
    { lat: 48.7325514, lng: 19.0337167 },
    { lat: 48.7331783, lng: 19.0300358 },
    { lat: 48.7327867, lng: 19.0310636 },
    { lat: 48.7327807, lng: 19.0313007 },
    { lat: 48.7325061, lng: 19.0317601 },
    { lat: 48.7323415, lng: 19.0308408 },
    { lat: 48.7322564, lng: 19.0306038 },
    { lat: 48.7322476, lng: 19.0302741 },
    { lat: 48.7320797, lng: 19.0298295 },
    { lat: 48.7308553, lng: 19.0288622 },
    { lat: 48.7289676, lng: 19.027036 },
    { lat: 48.7289194, lng: 19.0265955 },
    { lat: 48.728342, lng: 19.026525 },
    { lat: 48.7269663, lng: 19.0252407 },
    { lat: 48.7265197, lng: 19.0251035 },
    { lat: 48.7264238, lng: 19.0252116 },
    { lat: 48.7262636, lng: 19.0251868 },
    { lat: 48.7260942, lng: 19.0252488 },
    { lat: 48.7258251, lng: 19.0250801 },
    { lat: 48.7287291, lng: 19.0165849 },
    { lat: 48.7289974, lng: 19.0168069 },
    { lat: 48.7294612, lng: 19.0169296 },
    { lat: 48.729771, lng: 19.0171014 },
    { lat: 48.7300298, lng: 19.0169813 },
    { lat: 48.7304788, lng: 19.0166264 },
    { lat: 48.7308184, lng: 19.0156027 },
    { lat: 48.7309874, lng: 19.0146369 },
    { lat: 48.7315646, lng: 19.012514 },
    { lat: 48.731883, lng: 19.0115388 },
    { lat: 48.7319761, lng: 19.0114409 },
    { lat: 48.732282, lng: 19.0120957 },
    { lat: 48.7324654, lng: 19.0126681 },
    { lat: 48.7331702, lng: 19.0168995 },
    { lat: 48.7333912, lng: 19.0170376 },
    { lat: 48.7336689, lng: 19.0189672 },
    { lat: 48.7336082, lng: 19.0192177 },
    { lat: 48.7340149, lng: 19.0216785 },
    { lat: 48.7349687, lng: 19.0229521 },
    { lat: 48.7366579, lng: 19.0260771 },
    { lat: 48.7372989, lng: 19.0274117 },
    { lat: 48.7385561, lng: 19.0294543 },
    { lat: 48.7388043, lng: 19.0292555 },
    { lat: 48.7389671, lng: 19.0292882 },
    { lat: 48.7393617, lng: 19.0291121 },
    { lat: 48.7396187, lng: 19.0292184 },
    { lat: 48.7404949, lng: 19.0289313 },
    { lat: 48.7406321, lng: 19.0291934 },
    { lat: 48.7408016, lng: 19.0291168 },
    { lat: 48.7410525, lng: 19.0284307 },
    { lat: 48.7413192, lng: 19.0282876 },
    { lat: 48.7416382, lng: 19.0327756 },
    { lat: 48.7423718, lng: 19.0333523 },
    { lat: 48.7419501, lng: 19.0335964 },
    { lat: 48.741512, lng: 19.0339653 },
    { lat: 48.7417258, lng: 19.0349449 },
    { lat: 48.7416328, lng: 19.0353842 },
    { lat: 48.7412046, lng: 19.035498 },
    { lat: 48.7407051, lng: 19.0344898 },
    { lat: 48.740388, lng: 19.034504 },
    { lat: 48.7398973, lng: 19.0340513 },
    { lat: 48.7395675, lng: 19.0341066 },
    { lat: 48.7392087, lng: 19.0338303 },
    { lat: 48.7392783, lng: 19.0342948 },
    { lat: 48.739218, lng: 19.0345346 },
    { lat: 48.739044, lng: 19.0346814 },
    { lat: 48.7390562, lng: 19.034949 },
    { lat: 48.7389151, lng: 19.0349898 },
    { lat: 48.7387785, lng: 19.0361978 },
    { lat: 48.7389628, lng: 19.0368824 },
    { lat: 48.738727, lng: 19.0369884 },
    { lat: 48.7384996, lng: 19.0369302 },
    { lat: 48.7380576, lng: 19.0366376 },
    { lat: 48.7377653, lng: 19.0366329 },
    { lat: 48.7378067, lng: 19.0370286 },
    { lat: 48.7379274, lng: 19.0375469 },
    { lat: 48.7382539, lng: 19.0383935 },
    { lat: 48.7382471, lng: 19.0385268 },
    { lat: 48.7383612, lng: 19.038913 },
    { lat: 48.739859, lng: 19.0403401 },
    { lat: 48.7409522, lng: 19.0415246 },
    { lat: 48.7409691, lng: 19.0428283 },
    { lat: 48.740778, lng: 19.0442575 },
    { lat: 48.7407721, lng: 19.0443 },
    { lat: 48.7411445, lng: 19.0441934 },
    { lat: 48.7413146, lng: 19.044415 },
    { lat: 48.7414969, lng: 19.0442527 },
    { lat: 48.7417346, lng: 19.0442015 },
    { lat: 48.7418093, lng: 19.0445227 },
    { lat: 48.742817, lng: 19.0444308 },
    { lat: 48.7430862, lng: 19.0442464 },
    { lat: 48.7433066, lng: 19.0439196 },
    { lat: 48.7438273, lng: 19.0439632 },
    { lat: 48.7436534, lng: 19.0437306 },
    { lat: 48.7436323, lng: 19.0434229 },
    { lat: 48.7435579, lng: 19.0432861 },
    { lat: 48.7435653, lng: 19.0430629 },
    { lat: 48.7435705, lng: 19.0429067 },
    { lat: 48.7435095, lng: 19.0428924 },
    { lat: 48.7432659, lng: 19.0428351 },
    { lat: 48.7431967, lng: 19.04193 },
    { lat: 48.7431732, lng: 19.0417534 },
    { lat: 48.7431948, lng: 19.0413076 },
    { lat: 48.7429983, lng: 19.0406914 },
    { lat: 48.7428801, lng: 19.0405821 },
    { lat: 48.7427364, lng: 19.0402603 },
    { lat: 48.7426689, lng: 19.0399226 },
    { lat: 48.7429935, lng: 19.0394035 },
    { lat: 48.7431415, lng: 19.0392551 },
    { lat: 48.7431355, lng: 19.0386603 },
    { lat: 48.7430091, lng: 19.0381644 },
    { lat: 48.7431731, lng: 19.0380195 },
    { lat: 48.743315, lng: 19.0375902 },
    { lat: 48.743333, lng: 19.0373983 },
    { lat: 48.743351, lng: 19.0372064 },
    { lat: 48.7434457, lng: 19.0371387 },
    { lat: 48.7435172, lng: 19.0369208 },
    { lat: 48.7434594, lng: 19.0368464 },
    { lat: 48.7434512, lng: 19.0365941 },
    { lat: 48.7431677, lng: 19.036204 },
    { lat: 48.7432349, lng: 19.0354294 },
    { lat: 48.7431953, lng: 19.0350438 },
    { lat: 48.7432215, lng: 19.034535 },
    { lat: 48.743324, lng: 19.034126 },
    { lat: 48.743921, lng: 19.032973 },
    { lat: 48.7438734, lng: 19.0327213 },
    { lat: 48.7443204, lng: 19.0322617 },
    { lat: 48.7443123, lng: 19.0320104 },
    { lat: 48.7442134, lng: 19.0318574 },
    { lat: 48.7442485, lng: 19.0314122 },
    { lat: 48.7445749, lng: 19.0307278 },
    { lat: 48.7439546, lng: 19.0292444 },
    { lat: 48.7439483, lng: 19.0289822 },
    { lat: 48.7440158, lng: 19.0287973 },
    { lat: 48.7438694, lng: 19.0279721 },
    { lat: 48.74387, lng: 19.027627 },
    { lat: 48.743732, lng: 19.0273914 },
    { lat: 48.7436072, lng: 19.0266373 },
    { lat: 48.7435229, lng: 19.0265309 },
    { lat: 48.7436421, lng: 19.0262565 },
    { lat: 48.7438307, lng: 19.0252524 },
    { lat: 48.7438122, lng: 19.0249488 },
    { lat: 48.7439369, lng: 19.0249634 },
    { lat: 48.7439039, lng: 19.024377 },
    { lat: 48.7438204, lng: 19.0238158 },
    { lat: 48.7437516, lng: 19.0236122 },
    { lat: 48.7437755, lng: 19.0233216 },
    { lat: 48.7436839, lng: 19.0228252 },
    { lat: 48.7437436, lng: 19.0226219 },
    { lat: 48.7437424, lng: 19.0224195 },
    { lat: 48.7436922, lng: 19.022272 },
    { lat: 48.7437895, lng: 19.0218532 },
    { lat: 48.7437409, lng: 19.0217195 },
    { lat: 48.7438753, lng: 19.0216094 },
    { lat: 48.7439206, lng: 19.0215002 },
    { lat: 48.7438646, lng: 19.021422 },
    { lat: 48.7438863, lng: 19.0212941 },
    { lat: 48.7437975, lng: 19.0212282 },
    { lat: 48.7437599, lng: 19.0209746 },
    { lat: 48.7438122, lng: 19.0204449 },
    { lat: 48.7436641, lng: 19.0196993 },
    { lat: 48.7437133, lng: 19.0190628 },
    { lat: 48.7436435, lng: 19.0187315 },
    { lat: 48.7436904, lng: 19.0184521 },
    { lat: 48.7434426, lng: 19.0180783 },
    { lat: 48.7434265, lng: 19.0177762 },
    { lat: 48.7433415, lng: 19.0176124 },
    { lat: 48.7434129, lng: 19.0173045 },
    { lat: 48.7433095, lng: 19.0172692 },
    { lat: 48.7432705, lng: 19.0170758 },
    { lat: 48.7431667, lng: 19.016512 },
    { lat: 48.7432175, lng: 19.0167597 },
    { lat: 48.7432474, lng: 19.0164155 },
    { lat: 48.7434598, lng: 19.0160708 },
    { lat: 48.7435173, lng: 19.0159775 },
    { lat: 48.7436744, lng: 19.0158737 },
    { lat: 48.7438759, lng: 19.0154183 },
    { lat: 48.7440174, lng: 19.0154088 },
    { lat: 48.7442926, lng: 19.0150712 },
    { lat: 48.7445463, lng: 19.0147599 },
    { lat: 48.7447144, lng: 19.0146312 },
    { lat: 48.7447953, lng: 19.0144454 },
    { lat: 48.7448028, lng: 19.0143031 },
    { lat: 48.7449456, lng: 19.0141599 },
    { lat: 48.7448173, lng: 19.0136498 },
    { lat: 48.7447077, lng: 19.0134372 },
    { lat: 48.7446862, lng: 19.0133956 },
    { lat: 48.7447631, lng: 19.0122343 },
    { lat: 48.7447668, lng: 19.0121782 },
    { lat: 48.7448327, lng: 19.0118268 },
    { lat: 48.7449009, lng: 19.0114629 },
    { lat: 48.7450709, lng: 19.0109893 },
    { lat: 48.7450519, lng: 19.010701 },
    { lat: 48.7448671, lng: 19.0104316 },
    { lat: 48.7448489, lng: 19.0101847 },
    { lat: 48.7449192, lng: 19.0099901 },
    { lat: 48.7449579, lng: 19.0095945 },
    { lat: 48.7450922, lng: 19.0093536 },
    { lat: 48.745064, lng: 19.0089499 },
    { lat: 48.7451426, lng: 19.0085397 },
    { lat: 48.7451424, lng: 19.0083157 },
    { lat: 48.7450423, lng: 19.0075106 },
    { lat: 48.7447475, lng: 19.0066562 },
    { lat: 48.7447507, lng: 19.0064279 },
    { lat: 48.7449078, lng: 19.0057636 },
    { lat: 48.744917, lng: 19.0055017 },
    { lat: 48.7448066, lng: 19.0052551 },
    { lat: 48.7447984, lng: 19.0050932 },
    { lat: 48.7449794, lng: 19.004345 },
    { lat: 48.7453941, lng: 19.0039388 },
    { lat: 48.7456817, lng: 19.0031822 },
    { lat: 48.7456363, lng: 18.997725 },
    { lat: 48.7456346, lng: 18.9975155 },
    { lat: 48.745631, lng: 18.997085 },
    { lat: 48.7455236, lng: 18.9970786 },
    { lat: 48.745396, lng: 18.997071 },
    { lat: 48.744973, lng: 18.99735 },
    { lat: 48.744848, lng: 18.997324 },
    { lat: 48.74454, lng: 18.99752 },
    { lat: 48.744285, lng: 18.997391 },
    { lat: 48.744084, lng: 18.99778 },
    { lat: 48.743935, lng: 18.997814 },
    { lat: 48.74375, lng: 18.997666 },
    { lat: 48.743606, lng: 18.997692 },
    { lat: 48.743327, lng: 18.997282 },
    { lat: 48.742954, lng: 18.997213 },
    { lat: 48.742587, lng: 18.9971 },
    { lat: 48.742294, lng: 18.996995 },
    { lat: 48.741869, lng: 18.99697 },
    { lat: 48.741502, lng: 18.996636 },
    { lat: 48.741365, lng: 18.99654 },
    { lat: 48.740815, lng: 18.996387 },
    { lat: 48.740427, lng: 18.996467 },
    { lat: 48.740072, lng: 18.996474 },
    { lat: 48.739885, lng: 18.996478 },
    { lat: 48.739617, lng: 18.996719 },
    { lat: 48.739455, lng: 18.997001 },
    { lat: 48.738996, lng: 18.997774 },
    { lat: 48.738807, lng: 18.99838 },
    { lat: 48.738627, lng: 18.998584 },
    { lat: 48.738084, lng: 18.999301 },
    { lat: 48.737629, lng: 19.00032 },
    { lat: 48.737372, lng: 19.000569 },
    { lat: 48.73669, lng: 19.000762 },
    { lat: 48.736364, lng: 19.000934 },
    { lat: 48.735685, lng: 19.001094 },
    { lat: 48.735465, lng: 19.001068 },
    { lat: 48.735118, lng: 19.001429 },
    { lat: 48.734734, lng: 19.001591 },
    { lat: 48.734646, lng: 19.001639 },
    { lat: 48.73418, lng: 19.002117 },
    { lat: 48.733795, lng: 19.002426 },
    { lat: 48.733417, lng: 19.00261 },
    { lat: 48.733186, lng: 19.002689 },
    { lat: 48.733053, lng: 19.002593 },
    { lat: 48.732567, lng: 19.002249 },
    { lat: 48.731988, lng: 19.001877 },
    { lat: 48.731524, lng: 19.001504 },
    { lat: 48.73076, lng: 19.00145 },
    { lat: 48.730376, lng: 19.00154 },
    { lat: 48.730018, lng: 19.001332 },
    { lat: 48.729431, lng: 19.001087 },
    { lat: 48.728976, lng: 19.001617 },
    { lat: 48.728435, lng: 19.00222 },
    { lat: 48.728185, lng: 19.002918 },
    { lat: 48.727889, lng: 19.003354 },
    { lat: 48.727692, lng: 19.00356 },
    { lat: 48.727174, lng: 19.004128 },
    { lat: 48.7268121, lng: 19.0040557 },
    { lat: 48.726508, lng: 19.003995 },
    { lat: 48.726282, lng: 19.003967 },
    { lat: 48.726065, lng: 19.003866 },
    { lat: 48.725842, lng: 19.003932 },
    { lat: 48.725245, lng: 19.003813 },
    { lat: 48.725232, lng: 19.003811 },
    { lat: 48.724624, lng: 19.004019 },
    { lat: 48.724371, lng: 19.003706 },
    { lat: 48.724222, lng: 19.003719 },
    { lat: 48.723983, lng: 19.003655 },
    { lat: 48.72377, lng: 19.00351 },
    { lat: 48.723567, lng: 19.003299 },
    { lat: 48.723093, lng: 19.002954 },
    { lat: 48.723019, lng: 19.003044 },
    { lat: 48.722907, lng: 19.00328 },
    { lat: 48.722587, lng: 19.003499 },
    { lat: 48.722168, lng: 19.003971 },
    { lat: 48.72206, lng: 19.004092 },
    { lat: 48.721586, lng: 19.004694 },
    { lat: 48.721165, lng: 19.004961 },
    { lat: 48.720955, lng: 19.005089 },
    { lat: 48.720585, lng: 19.005068 },
    { lat: 48.720465, lng: 19.00515 },
    { lat: 48.719879, lng: 19.005403 },
    { lat: 48.719502, lng: 19.005223 },
    { lat: 48.719075, lng: 19.00507 },
    { lat: 48.719011, lng: 19.005104 },
    { lat: 48.718377, lng: 19.005442 },
    { lat: 48.71819, lng: 19.00545 },
    { lat: 48.717347, lng: 19.005535 },
    { lat: 48.716592, lng: 19.005534 },
    { lat: 48.716225, lng: 19.005494 },
    { lat: 48.715906, lng: 19.005202 },
    { lat: 48.715394, lng: 19.004918 },
    { lat: 48.71496, lng: 19.005485 },
    { lat: 48.714636, lng: 19.005852 },
    { lat: 48.714082, lng: 19.006073 },
    { lat: 48.713736, lng: 19.006334 },
    { lat: 48.713317, lng: 19.006522 },
    { lat: 48.712897, lng: 19.006628 },
    { lat: 48.712359, lng: 19.006621 },
    { lat: 48.712329, lng: 19.006621 },
    { lat: 48.711846, lng: 19.006446 },
    { lat: 48.711355, lng: 19.005915 },
    { lat: 48.710593, lng: 19.00524 },
    { lat: 48.710194, lng: 19.004825 },
    { lat: 48.709699, lng: 19.004492 },
    { lat: 48.70927, lng: 19.004027 },
    { lat: 48.709104, lng: 19.003872 },
    { lat: 48.708726, lng: 19.003726 },
    { lat: 48.70843, lng: 19.003696 },
    { lat: 48.707804, lng: 19.00418 },
    { lat: 48.707479, lng: 19.005365 },
    { lat: 48.707214, lng: 19.006437 },
    { lat: 48.707168, lng: 19.00653 },
    { lat: 48.706729, lng: 19.007418 },
    { lat: 48.7067974, lng: 19.007937 },
    { lat: 48.7068987, lng: 19.008202 },
    { lat: 48.7082047, lng: 19.0105578 },
    { lat: 48.7087737, lng: 19.0110181 },
    { lat: 48.7092926, lng: 19.0115349 },
    { lat: 48.7096261, lng: 19.0121384 },
    { lat: 48.7097108, lng: 19.0124967 },
    { lat: 48.7097339, lng: 19.0129549 },
    { lat: 48.7098415, lng: 19.013388 },
    { lat: 48.7111973, lng: 19.015642 },
    { lat: 48.7120394, lng: 19.0173361 },
    { lat: 48.7124609, lng: 19.0182323 },
    { lat: 48.7126429, lng: 19.0190685 },
    { lat: 48.7130244, lng: 19.0198839 },
    { lat: 48.7132182, lng: 19.0204147 },
    { lat: 48.7134338, lng: 19.0200618 },
    { lat: 48.7136991, lng: 19.020117 },
    { lat: 48.7143887, lng: 19.0204468 },
    { lat: 48.7145367, lng: 19.0206965 },
    { lat: 48.7146665, lng: 19.0210553 },
    { lat: 48.7149378, lng: 19.0212966 },
    { lat: 48.7151728, lng: 19.0216184 },
    { lat: 48.7152966, lng: 19.0220243 },
    { lat: 48.7154387, lng: 19.0224903 },
    { lat: 48.7154704, lng: 19.0227812 },
    { lat: 48.7157655, lng: 19.0230026 },
    { lat: 48.7166724, lng: 19.0233014 },
    { lat: 48.717357, lng: 19.0239997 },
    { lat: 48.7180824, lng: 19.0251113 },
    { lat: 48.7184043, lng: 19.025837 },
    { lat: 48.7184801, lng: 19.0261792 },
    { lat: 48.7182582, lng: 19.0262951 },
    { lat: 48.7183113, lng: 19.0264532 },
    { lat: 48.717975, lng: 19.0268967 },
    { lat: 48.7188474, lng: 19.0295365 },
    { lat: 48.7191655, lng: 19.0296331 },
    { lat: 48.7191748, lng: 19.0298845 },
    { lat: 48.7193721, lng: 19.0302174 },
    { lat: 48.7195346, lng: 19.0308511 },
    { lat: 48.719643, lng: 19.031034 },
    { lat: 48.7198794, lng: 19.0317249 },
    { lat: 48.7197282, lng: 19.0319211 },
    { lat: 48.7197215, lng: 19.0321461 },
    { lat: 48.7202745, lng: 19.0339543 },
    { lat: 48.7199945, lng: 19.0341467 },
    { lat: 48.719875, lng: 19.0346657 },
    { lat: 48.7197089, lng: 19.0347029 },
    { lat: 48.7196429, lng: 19.034804 },
    { lat: 48.7196936, lng: 19.0349821 },
    { lat: 48.719478, lng: 19.0354253 },
    { lat: 48.7193858, lng: 19.0354284 },
    { lat: 48.7193069, lng: 19.0355659 },
    { lat: 48.7191387, lng: 19.0355367 },
    { lat: 48.7189873, lng: 19.0360043 },
    { lat: 48.7184974, lng: 19.037027 },
    { lat: 48.7182631, lng: 19.0373202 },
    { lat: 48.7179086, lng: 19.037986 },
    { lat: 48.7176865, lng: 19.0382996 },
    { lat: 48.7176076, lng: 19.0387467 },
    { lat: 48.7179406, lng: 19.0392498 },
    { lat: 48.7178263, lng: 19.0394 },
    { lat: 48.7178089, lng: 19.0396615 },
    { lat: 48.7179222, lng: 19.0398713 },
    { lat: 48.7177274, lng: 19.0407305 },
    { lat: 48.7176962, lng: 19.0413886 },
    { lat: 48.7175366, lng: 19.0421648 },
    { lat: 48.7175289, lng: 19.0424824 },
    { lat: 48.7173806, lng: 19.0428982 },
    { lat: 48.7168353, lng: 19.0439378 },
    { lat: 48.7168911, lng: 19.0442635 },
    { lat: 48.7167884, lng: 19.0445566 },
    { lat: 48.716739, lng: 19.0450992 },
    { lat: 48.7162989, lng: 19.0449814 },
    { lat: 48.7154151, lng: 19.045722 },
    { lat: 48.7151924, lng: 19.0457089 },
    { lat: 48.714923, lng: 19.0455566 },
    { lat: 48.7145981, lng: 19.0455749 },
    { lat: 48.7142812, lng: 19.045704 },
    { lat: 48.7139236, lng: 19.0459516 },
    { lat: 48.7136175, lng: 19.0463869 },
    { lat: 48.7130618, lng: 19.0475825 },
    { lat: 48.7129089, lng: 19.0481294 },
    { lat: 48.7127982, lng: 19.0483527 },
    { lat: 48.712425, lng: 19.0488674 },
    { lat: 48.7120125, lng: 19.0495943 },
    { lat: 48.7117754, lng: 19.0498777 },
    { lat: 48.7114615, lng: 19.050115 },
    { lat: 48.7110646, lng: 19.0509461 },
    { lat: 48.7109053, lng: 19.0511178 },
    { lat: 48.7104967, lng: 19.051243 },
    { lat: 48.7103687, lng: 19.0515454 },
    { lat: 48.7103141, lng: 19.0522817 },
    { lat: 48.7096918, lng: 19.0531171 },
    { lat: 48.7095118, lng: 19.0535043 },
    { lat: 48.7094099, lng: 19.0541713 },
    { lat: 48.7094253, lng: 19.0554718 },
    { lat: 48.7091948, lng: 19.0555945 },
    { lat: 48.7092158, lng: 19.0563796 },
    { lat: 48.7093606, lng: 19.0571298 },
    { lat: 48.7094021, lng: 19.0575425 },
    { lat: 48.7094457, lng: 19.0579761 },
    { lat: 48.7096822, lng: 19.0594822 },
    { lat: 48.7096088, lng: 19.0609446 },
    { lat: 48.7092784, lng: 19.0622938 },
    { lat: 48.7090277, lng: 19.0628261 },
    { lat: 48.7090092, lng: 19.0633176 },
    { lat: 48.7093511, lng: 19.0637708 },
    { lat: 48.7094393, lng: 19.0640716 },
    { lat: 48.7115356, lng: 19.0635397 },
    { lat: 48.7138883, lng: 19.0624081 },
    { lat: 48.7148121, lng: 19.062349 },
    { lat: 48.715114, lng: 19.0616452 },
    { lat: 48.7158583, lng: 19.0611982 },
    { lat: 48.7178687, lng: 19.0619266 },
    { lat: 48.7199078, lng: 19.0629153 },
    { lat: 48.7210375, lng: 19.0640221 },
    { lat: 48.7222909, lng: 19.0648727 },
    { lat: 48.7223699, lng: 19.0648319 },
    { lat: 48.7229536, lng: 19.0654352 },
    { lat: 48.7228878, lng: 19.0659922 },
    { lat: 48.7230337, lng: 19.0660227 },
    { lat: 48.7227439, lng: 19.0680546 },
    { lat: 48.7226548, lng: 19.0690706 },
    { lat: 48.7227056, lng: 19.0698135 },
    { lat: 48.7226725, lng: 19.0701686 },
    { lat: 48.7228936, lng: 19.0720016 },
    { lat: 48.7225169, lng: 19.0732871 },
    { lat: 48.7225334, lng: 19.0733712 },
    { lat: 48.7222945, lng: 19.074526 },
    { lat: 48.7216109, lng: 19.0741776 },
    { lat: 48.7213537, lng: 19.0750784 },
    { lat: 48.7213357, lng: 19.0755982 },
    { lat: 48.721417, lng: 19.0756475 },
    { lat: 48.721655, lng: 19.0760321 },
    { lat: 48.7216042, lng: 19.0767116 },
    { lat: 48.7213737, lng: 19.0771763 },
    { lat: 48.7214043, lng: 19.077407 },
    { lat: 48.7216625, lng: 19.0786712 },
    { lat: 48.7220864, lng: 19.079963 },
    { lat: 48.7220642, lng: 19.0801726 },
    { lat: 48.7215335, lng: 19.0812423 },
    { lat: 48.7213318, lng: 19.0818548 },
    { lat: 48.7209767, lng: 19.082309 },
    { lat: 48.7207077, lng: 19.0825245 },
    { lat: 48.7200685, lng: 19.0825022 },
    { lat: 48.7191884, lng: 19.0826416 },
    { lat: 48.7188062, lng: 19.082528 },
    { lat: 48.7182188, lng: 19.0826412 },
    { lat: 48.7179326, lng: 19.0828284 },
    { lat: 48.7176937, lng: 19.0831212 },
    { lat: 48.7174902, lng: 19.0835417 },
    { lat: 48.7174505, lng: 19.0840813 },
    { lat: 48.7172826, lng: 19.0850322 },
    { lat: 48.7172405, lng: 19.0855244 },
    { lat: 48.7173369, lng: 19.0857626 },
    { lat: 48.7176917, lng: 19.08616 },
    { lat: 48.7177016, lng: 19.0869046 },
    { lat: 48.7180418, lng: 19.0876932 },
    { lat: 48.7181684, lng: 19.0877695 },
    { lat: 48.7184828, lng: 19.0888998 },
    { lat: 48.7184947, lng: 19.090474 },
    { lat: 48.7182387, lng: 19.0912214 },
    { lat: 48.7173448, lng: 19.0924228 },
    { lat: 48.7171836, lng: 19.0931473 },
    { lat: 48.7171261, lng: 19.0936926 },
    { lat: 48.7171163, lng: 19.0941332 },
    { lat: 48.7172398, lng: 19.0944826 },
    { lat: 48.7173514, lng: 19.0950754 },
    { lat: 48.71733, lng: 19.0963898 },
    { lat: 48.7170675, lng: 19.0994139 },
    { lat: 48.7171183, lng: 19.1002549 },
    { lat: 48.7172969, lng: 19.1012411 },
    { lat: 48.7173271, lng: 19.1017578 },
    { lat: 48.7171401, lng: 19.1031032 },
    { lat: 48.717213, lng: 19.1040239 },
    { lat: 48.7169487, lng: 19.1041066 },
    { lat: 48.7167469, lng: 19.1046714 },
    { lat: 48.7159928, lng: 19.1070195 },
    { lat: 48.7152642, lng: 19.1099434 },
    { lat: 48.7151186, lng: 19.1101469 },
    { lat: 48.7151307, lng: 19.1102284 },
    { lat: 48.715066, lng: 19.110382 },
    { lat: 48.7143082, lng: 19.1099198 },
    { lat: 48.713933, lng: 19.1093804 },
    { lat: 48.713458, lng: 19.1089383 },
    { lat: 48.7128894, lng: 19.1086336 },
    { lat: 48.7127073, lng: 19.1082812 },
    { lat: 48.7125116, lng: 19.1076304 },
    { lat: 48.7124265, lng: 19.1068451 },
    { lat: 48.7119934, lng: 19.1052271 },
    { lat: 48.7119085, lng: 19.1044738 },
    { lat: 48.7114217, lng: 19.1029751 },
    { lat: 48.7113221, lng: 19.101969 },
    { lat: 48.7109179, lng: 19.0992987 },
    { lat: 48.7106891, lng: 19.0985978 },
    { lat: 48.7104106, lng: 19.0979362 },
    { lat: 48.7102662, lng: 19.0980268 },
    { lat: 48.7096156, lng: 19.0975502 },
    { lat: 48.7090791, lng: 19.0974172 },
    { lat: 48.7083797, lng: 19.0975669 },
    { lat: 48.7082774, lng: 19.0980214 },
    { lat: 48.7079882, lng: 19.0982631 },
    { lat: 48.7078147, lng: 19.0991033 },
    { lat: 48.7066224, lng: 19.1012909 },
    { lat: 48.7064777, lng: 19.1015561 },
    { lat: 48.7062436, lng: 19.1013374 },
    { lat: 48.7052883, lng: 19.1004434 },
    { lat: 48.7049975, lng: 19.1000095 },
    { lat: 48.7048118, lng: 19.0996098 },
    { lat: 48.7047521, lng: 19.0993392 },
    { lat: 48.7043313, lng: 19.0981727 },
    { lat: 48.7042057, lng: 19.0977329 },
    { lat: 48.7041936, lng: 19.0974231 },
    { lat: 48.7043999, lng: 19.0961073 },
    { lat: 48.7043475, lng: 19.095323 },
    { lat: 48.7042857, lng: 19.0950552 },
    { lat: 48.7039738, lng: 19.0945402 },
    { lat: 48.7038348, lng: 19.0944435 },
    { lat: 48.7030293, lng: 19.0943201 },
    { lat: 48.702611, lng: 19.094364 },
    { lat: 48.7027165, lng: 19.0940158 },
    { lat: 48.7013517, lng: 19.0917345 },
    { lat: 48.7016654, lng: 19.0914904 },
    { lat: 48.7014891, lng: 19.0912085 },
    { lat: 48.7013971, lng: 19.091194 },
    { lat: 48.7011891, lng: 19.0911626 },
    { lat: 48.7009092, lng: 19.0912447 },
    { lat: 48.7008921, lng: 19.0910611 },
    { lat: 48.7005834, lng: 19.0913149 },
    { lat: 48.7004396, lng: 19.0912545 },
    { lat: 48.700234, lng: 19.0907482 },
    { lat: 48.6999528, lng: 19.0907387 },
    { lat: 48.6998536, lng: 19.0902636 },
    { lat: 48.6994936, lng: 19.0900086 },
    { lat: 48.6994243, lng: 19.0901763 },
    { lat: 48.6994638, lng: 19.0902439 },
    { lat: 48.6992472, lng: 19.0902396 },
    { lat: 48.6992645, lng: 19.0904553 },
    { lat: 48.6989917, lng: 19.0904749 },
    { lat: 48.698597, lng: 19.0903848 },
    { lat: 48.6987299, lng: 19.0909725 },
    { lat: 48.6980719, lng: 19.0913659 },
    { lat: 48.6979123, lng: 19.0913291 },
    { lat: 48.6978172, lng: 19.0914548 },
    { lat: 48.6972443, lng: 19.091406 },
    { lat: 48.6971033, lng: 19.0915636 },
    { lat: 48.6964867, lng: 19.0916115 },
    { lat: 48.6963112, lng: 19.0920479 },
    { lat: 48.6960404, lng: 19.0921553 },
    { lat: 48.6957062, lng: 19.0926229 },
    { lat: 48.6955015, lng: 19.0927671 },
    { lat: 48.6953558, lng: 19.0932162 },
    { lat: 48.6952232, lng: 19.0932894 },
    { lat: 48.6946599, lng: 19.0940945 },
    { lat: 48.6944403, lng: 19.0943492 },
    { lat: 48.6942431, lng: 19.0943923 },
    { lat: 48.6942285, lng: 19.0948689 },
    { lat: 48.6939299, lng: 19.0949203 },
    { lat: 48.6937385, lng: 19.095063 },
    { lat: 48.6937899, lng: 19.0954354 },
    { lat: 48.6936049, lng: 19.0955502 },
    { lat: 48.6931754, lng: 19.0963668 },
    { lat: 48.6931229, lng: 19.0965787 },
    { lat: 48.6928824, lng: 19.0972729 },
    { lat: 48.6927282, lng: 19.097573 },
    { lat: 48.6925629, lng: 19.0977648 },
    { lat: 48.6926353, lng: 19.0980199 },
    { lat: 48.69255, lng: 19.0990034 },
    { lat: 48.6925658, lng: 19.0995454 },
    { lat: 48.6926169, lng: 19.1001213 },
    { lat: 48.6928021, lng: 19.1009199 },
    { lat: 48.692871, lng: 19.1015848 },
    { lat: 48.6927298, lng: 19.1023469 },
    { lat: 48.6928455, lng: 19.1025697 },
    { lat: 48.6928638, lng: 19.1028249 },
    { lat: 48.6927264, lng: 19.1032779 },
    { lat: 48.6926154, lng: 19.1039492 },
    { lat: 48.6925598, lng: 19.1047862 },
    { lat: 48.6921968, lng: 19.1060584 },
    { lat: 48.6912009, lng: 19.1075013 },
    { lat: 48.6902767, lng: 19.1083481 },
    { lat: 48.6900434, lng: 19.1084362 },
    { lat: 48.689968, lng: 19.1085304 },
    { lat: 48.6899008, lng: 19.1089038 },
    { lat: 48.6895588, lng: 19.1092161 },
    { lat: 48.6894587, lng: 19.1094033 },
    { lat: 48.6889768, lng: 19.1098215 },
    { lat: 48.6884394, lng: 19.1099657 },
    { lat: 48.6882075, lng: 19.1089722 },
    { lat: 48.6883208, lng: 19.1080742 },
    { lat: 48.6882976, lng: 19.1079691 },
    { lat: 48.6874977, lng: 19.1083443 },
    { lat: 48.6874247, lng: 19.1077357 },
    { lat: 48.6868334, lng: 19.1078393 },
    { lat: 48.6864116, lng: 19.10815 },
    { lat: 48.6854422, lng: 19.1087007 },
    { lat: 48.68539, lng: 19.1088293 },
    { lat: 48.6847164, lng: 19.1085466 },
    { lat: 48.6842478, lng: 19.1080962 },
    { lat: 48.6832225, lng: 19.1068808 },
    { lat: 48.6825241, lng: 19.1079588 },
    { lat: 48.6824073, lng: 19.1084483 },
    { lat: 48.6817541, lng: 19.1095757 },
    { lat: 48.6811292, lng: 19.1103409 },
    { lat: 48.6810137, lng: 19.1107361 },
    { lat: 48.6804439, lng: 19.1116364 },
    { lat: 48.6797374, lng: 19.1123063 },
    { lat: 48.6791934, lng: 19.1131309 },
    { lat: 48.6789243, lng: 19.1133453 },
    { lat: 48.6785821, lng: 19.1138324 },
    { lat: 48.6781384, lng: 19.1142265 },
    { lat: 48.6771964, lng: 19.1159646 },
    { lat: 48.6756563, lng: 19.1175297 },
    { lat: 48.6754379, lng: 19.1176757 },
    { lat: 48.6753843, lng: 19.1176386 },
    { lat: 48.6753665, lng: 19.1195115 },
    { lat: 48.6743364, lng: 19.1197901 },
    { lat: 48.6738248, lng: 19.1198157 },
    { lat: 48.6738137, lng: 19.1198669 },
    { lat: 48.6737662, lng: 19.1198195 },
    { lat: 48.6737822, lng: 19.1200039 },
    { lat: 48.6735931, lng: 19.1203505 },
    { lat: 48.6736955, lng: 19.1204153 },
    { lat: 48.6737794, lng: 19.1206295 },
    { lat: 48.6737478, lng: 19.1213564 },
    { lat: 48.6736892, lng: 19.1215606 },
    { lat: 48.673753, lng: 19.1216911 },
    { lat: 48.6737474, lng: 19.1219211 },
    { lat: 48.6736534, lng: 19.1221832 },
    { lat: 48.6738761, lng: 19.1225397 },
    { lat: 48.673894, lng: 19.1226961 },
    { lat: 48.673763, lng: 19.1230326 },
    { lat: 48.6738108, lng: 19.1231292 },
    { lat: 48.6738665, lng: 19.123644 },
    { lat: 48.6738154, lng: 19.123954 },
    { lat: 48.6738359, lng: 19.1240955 },
    { lat: 48.673969, lng: 19.1243027 },
    { lat: 48.6739945, lng: 19.1248616 },
    { lat: 48.6741082, lng: 19.1251302 },
    { lat: 48.6743256, lng: 19.1271816 },
    { lat: 48.674772, lng: 19.1288224 },
    { lat: 48.6747838, lng: 19.1293101 },
    { lat: 48.6747368, lng: 19.1298651 },
    { lat: 48.6749162, lng: 19.131669 },
    { lat: 48.6751413, lng: 19.1321409 },
    { lat: 48.6751778, lng: 19.1326978 },
    { lat: 48.6757967, lng: 19.1337503 },
    { lat: 48.6761161, lng: 19.1341424 },
    { lat: 48.6761683, lng: 19.1345396 },
    { lat: 48.6760165, lng: 19.1353685 },
    { lat: 48.6762928, lng: 19.1363958 },
    { lat: 48.6763408, lng: 19.1373256 },
    { lat: 48.6762778, lng: 19.1389504 },
    { lat: 48.6765535, lng: 19.1393945 },
    { lat: 48.6764467, lng: 19.1397004 },
    { lat: 48.6775154, lng: 19.139562 },
    { lat: 48.678162, lng: 19.1393722 },
    { lat: 48.6781529, lng: 19.1393208 },
    { lat: 48.6785045, lng: 19.1391839 },
    { lat: 48.6786303, lng: 19.1396092 },
    { lat: 48.6787676, lng: 19.139831 },
    { lat: 48.6804039, lng: 19.1376752 },
    { lat: 48.6806295, lng: 19.1373784 },
    { lat: 48.680814, lng: 19.1377147 },
    { lat: 48.679948, lng: 19.1393086 },
    { lat: 48.6794232, lng: 19.1399809 },
    { lat: 48.6789458, lng: 19.1407042 },
    { lat: 48.6785578, lng: 19.141453 },
    { lat: 48.6782994, lng: 19.1422922 },
    { lat: 48.6783131, lng: 19.1423873 },
    { lat: 48.6789816, lng: 19.1419993 },
    { lat: 48.6790695, lng: 19.142024 },
    { lat: 48.6793965, lng: 19.1417279 },
    { lat: 48.6800913, lng: 19.1427631 },
    { lat: 48.680518, lng: 19.1427747 },
    { lat: 48.6816168, lng: 19.1426101 },
    { lat: 48.6816497, lng: 19.1440541 },
    { lat: 48.6815349, lng: 19.1443078 },
    { lat: 48.6814446, lng: 19.1447617 },
    { lat: 48.6817552, lng: 19.1452294 },
    { lat: 48.6819186, lng: 19.1453256 },
    { lat: 48.6818523, lng: 19.1454411 },
    { lat: 48.682747, lng: 19.1466241 },
    { lat: 48.6826224, lng: 19.1469317 },
    { lat: 48.6818768, lng: 19.1465807 },
    { lat: 48.6806843, lng: 19.1456694 },
    { lat: 48.6800161, lng: 19.1464765 },
    { lat: 48.6808018, lng: 19.1475947 },
    { lat: 48.6806767, lng: 19.147984 },
    { lat: 48.6802009, lng: 19.1486928 },
    { lat: 48.680528, lng: 19.1486486 },
    { lat: 48.6812324, lng: 19.1494491 },
    { lat: 48.6813382, lng: 19.1505424 },
    { lat: 48.6814069, lng: 19.1507965 },
    { lat: 48.6815928, lng: 19.1510871 },
    { lat: 48.6819094, lng: 19.1514297 },
    { lat: 48.6822677, lng: 19.1518175 },
    { lat: 48.6824709, lng: 19.1521143 },
    { lat: 48.682733, lng: 19.152497 },
    { lat: 48.682924, lng: 19.1529535 },
    { lat: 48.6830665, lng: 19.153294 },
    { lat: 48.6834146, lng: 19.1539658 },
    { lat: 48.6838686, lng: 19.1548421 },
    { lat: 48.6840555, lng: 19.1556824 },
    { lat: 48.6843973, lng: 19.1563763 },
    { lat: 48.6844562, lng: 19.1564958 },
    { lat: 48.6847787, lng: 19.1573236 },
    { lat: 48.6852308, lng: 19.1586869 },
    { lat: 48.6853145, lng: 19.1589392 },
    { lat: 48.6853941, lng: 19.1596569 },
    { lat: 48.685416, lng: 19.1598547 },
    { lat: 48.6853102, lng: 19.1609379 },
    { lat: 48.6854847, lng: 19.1615512 },
    { lat: 48.6855773, lng: 19.1622876 },
    { lat: 48.6856391, lng: 19.1624922 },
    { lat: 48.6859952, lng: 19.1631537 },
    { lat: 48.6860165, lng: 19.1632729 },
    { lat: 48.686065, lng: 19.1635447 },
    { lat: 48.6860898, lng: 19.1636152 },
    { lat: 48.6862295, lng: 19.1640128 },
    { lat: 48.6863863, lng: 19.1644591 },
    { lat: 48.6869731, lng: 19.1646089 },
    { lat: 48.6874736, lng: 19.1645881 },
    { lat: 48.6880731, lng: 19.1644007 },
    { lat: 48.6882266, lng: 19.1650257 },
    { lat: 48.6896913, lng: 19.1648931 },
    { lat: 48.6898871, lng: 19.164955 },
    { lat: 48.6901, lng: 19.1656204 },
    { lat: 48.6901619, lng: 19.1655917 },
    { lat: 48.6906991, lng: 19.1673062 },
    { lat: 48.6912464, lng: 19.1667419 },
    { lat: 48.6912188, lng: 19.1673736 },
    { lat: 48.6920531, lng: 19.1667336 },
    { lat: 48.6927061, lng: 19.1660264 },
    { lat: 48.6927236, lng: 19.1662011 },
    { lat: 48.6933109, lng: 19.1671883 },
    { lat: 48.6934416, lng: 19.1675076 },
    { lat: 48.6939264, lng: 19.1680628 },
    { lat: 48.6945816, lng: 19.1684561 },
    { lat: 48.6946214, lng: 19.1680734 },
    { lat: 48.6947155, lng: 19.1681514 },
    { lat: 48.6956886, lng: 19.1683182 },
    { lat: 48.6957355, lng: 19.1685621 },
    { lat: 48.6962104, lng: 19.1688903 },
    { lat: 48.6962626, lng: 19.1689814 },
    { lat: 48.6963633, lng: 19.1691573 },
    { lat: 48.6964374, lng: 19.169128 },
    { lat: 48.6967679, lng: 19.1689971 },
    { lat: 48.6970941, lng: 19.1690751 },
    { lat: 48.6971285, lng: 19.1691299 },
    { lat: 48.697311, lng: 19.1694203 },
    { lat: 48.6974941, lng: 19.1697119 },
    { lat: 48.6974803, lng: 19.1706483 },
    { lat: 48.6978167, lng: 19.1707987 },
    { lat: 48.6984626, lng: 19.172086 },
    { lat: 48.6985532, lng: 19.1726001 },
    { lat: 48.6990907, lng: 19.172895 },
    { lat: 48.699488, lng: 19.1723752 },
    { lat: 48.6997234, lng: 19.1723246 },
    { lat: 48.7000202, lng: 19.1725152 },
    { lat: 48.7007372, lng: 19.1734595 },
    { lat: 48.7015223, lng: 19.174828 },
    { lat: 48.7017704, lng: 19.1751525 },
    { lat: 48.7019722, lng: 19.1752884 },
    { lat: 48.7028369, lng: 19.1749922 },
    { lat: 48.7040004, lng: 19.1743728 },
    { lat: 48.7041218, lng: 19.1741843 },
    { lat: 48.7047758, lng: 19.1754745 },
    { lat: 48.7051464, lng: 19.1758406 },
    { lat: 48.7053897, lng: 19.1759704 },
    { lat: 48.7058883, lng: 19.1757484 },
    { lat: 48.7063823, lng: 19.1761014 },
    { lat: 48.706996, lng: 19.1765028 },
    { lat: 48.7082856, lng: 19.1757429 },
    { lat: 48.70832, lng: 19.1757798 },
    { lat: 48.7089128, lng: 19.17499 },
    { lat: 48.7088845, lng: 19.1749675 },
    { lat: 48.7092822, lng: 19.1739486 },
    { lat: 48.7095441, lng: 19.1726711 },
    { lat: 48.7096271, lng: 19.1724791 },
    { lat: 48.7097883, lng: 19.1722265 },
    { lat: 48.7104715, lng: 19.1715565 },
    { lat: 48.7110766, lng: 19.1704947 },
    { lat: 48.7113807, lng: 19.1701448 },
    { lat: 48.7115718, lng: 19.1700213 },
    { lat: 48.7124074, lng: 19.1697039 },
    { lat: 48.7131451, lng: 19.1698544 },
    { lat: 48.7134552, lng: 19.169837 },
    { lat: 48.7135421, lng: 19.1698321 },
    { lat: 48.7135365, lng: 19.1699495 },
    { lat: 48.7139245, lng: 19.16986 },
    { lat: 48.7145385, lng: 19.16928 },
    { lat: 48.7152263, lng: 19.168271 },
    { lat: 48.7154196, lng: 19.168123 },
    { lat: 48.715645, lng: 19.1680524 },
    { lat: 48.7168689, lng: 19.1684586 },
    { lat: 48.717003, lng: 19.1683208 },
    { lat: 48.7175642, lng: 19.1674447 },
    { lat: 48.7180459, lng: 19.1669917 },
    { lat: 48.7184038, lng: 19.1664732 },
    { lat: 48.7197039, lng: 19.1667077 },
    { lat: 48.7200134, lng: 19.1666752 },
    { lat: 48.7207471, lng: 19.1673166 },
    { lat: 48.7211515, lng: 19.1671956 },
    { lat: 48.721048, lng: 19.1688077 },
    { lat: 48.7209396, lng: 19.1690594 },
    { lat: 48.7209077, lng: 19.1694199 },
    { lat: 48.72048, lng: 19.1701005 },
    { lat: 48.7199052, lng: 19.1703123 },
    { lat: 48.7194616, lng: 19.1708467 },
    { lat: 48.7191902, lng: 19.1712669 },
    { lat: 48.7187819, lng: 19.1725503 },
    { lat: 48.7183284, lng: 19.1734679 },
    { lat: 48.7182335, lng: 19.1740125 },
    { lat: 48.7182466, lng: 19.1741881 },
    { lat: 48.718393, lng: 19.1744634 },
    { lat: 48.7183849, lng: 19.1745246 },
    { lat: 48.7185768, lng: 19.1747374 },
    { lat: 48.7188903, lng: 19.1748112 },
    { lat: 48.7190312, lng: 19.1749917 },
    { lat: 48.7193475, lng: 19.1756296 },
    { lat: 48.7195634, lng: 19.1762177 },
    { lat: 48.7203338, lng: 19.1776756 },
    { lat: 48.720438, lng: 19.1782169 },
    { lat: 48.7203942, lng: 19.1790019 },
    { lat: 48.7204373, lng: 19.1792152 },
    { lat: 48.7207748, lng: 19.1795237 },
    { lat: 48.7208978, lng: 19.179571 },
    { lat: 48.7209097, lng: 19.1794947 },
    { lat: 48.7214044, lng: 19.1796997 },
    { lat: 48.7215098, lng: 19.1814147 },
    { lat: 48.7215115, lng: 19.182694 },
    { lat: 48.7213184, lng: 19.1849233 },
    { lat: 48.7217414, lng: 19.1854833 },
    { lat: 48.7217989, lng: 19.1858721 },
    { lat: 48.7216484, lng: 19.187268 },
    { lat: 48.7216633, lng: 19.188795 },
    { lat: 48.7222513, lng: 19.1890378 },
    { lat: 48.7226459, lng: 19.1893401 },
    { lat: 48.723696, lng: 19.1904171 },
    { lat: 48.7236676, lng: 19.1904595 },
    { lat: 48.7239183, lng: 19.1909535 },
    { lat: 48.724218, lng: 19.1910882 },
    { lat: 48.7245134, lng: 19.191483 },
    { lat: 48.7248613, lng: 19.1916309 },
    { lat: 48.724904, lng: 19.1917704 },
    { lat: 48.725032, lng: 19.1918532 },
    { lat: 48.7245272, lng: 19.1937492 },
    { lat: 48.7240807, lng: 19.1945323 },
    { lat: 48.7240665, lng: 19.1945407 },
    { lat: 48.7238367, lng: 19.1955149 },
    { lat: 48.7236816, lng: 19.1958692 },
    { lat: 48.7234995, lng: 19.1965953 },
    { lat: 48.723117, lng: 19.1968886 },
    { lat: 48.7224813, lng: 19.1987859 },
    { lat: 48.7222429, lng: 19.1998606 },
    { lat: 48.7221884, lng: 19.2005018 },
    { lat: 48.7224258, lng: 19.2012279 },
    { lat: 48.722288, lng: 19.2024531 },
    { lat: 48.7227599, lng: 19.2030903 },
    { lat: 48.723955, lng: 19.2034863 },
    { lat: 48.7245217, lng: 19.2038988 },
    { lat: 48.7251804, lng: 19.2048765 },
    { lat: 48.7254931, lng: 19.2056279 },
    { lat: 48.7259249, lng: 19.2058851 },
    { lat: 48.7268802, lng: 19.2067727 },
    { lat: 48.7277659, lng: 19.2068595 },
    { lat: 48.7281144, lng: 19.2071421 },
    { lat: 48.7283442, lng: 19.2075774 },
    { lat: 48.728974, lng: 19.2081957 },
    { lat: 48.729366, lng: 19.2083771 },
    { lat: 48.7296491, lng: 19.208749 },
    { lat: 48.7291979, lng: 19.2093452 },
    { lat: 48.7289979, lng: 19.2093845 },
    { lat: 48.7286866, lng: 19.2104189 },
    { lat: 48.728662, lng: 19.210934 },
    { lat: 48.7282944, lng: 19.2115822 },
    { lat: 48.728635, lng: 19.212699 },
    { lat: 48.7285217, lng: 19.2128265 },
    { lat: 48.7282627, lng: 19.2133873 },
    { lat: 48.7281214, lng: 19.2140714 },
    { lat: 48.728079, lng: 19.2154725 },
    { lat: 48.7282755, lng: 19.2161283 },
    { lat: 48.7287763, lng: 19.2172263 },
    { lat: 48.7288644, lng: 19.2180191 },
    { lat: 48.7287321, lng: 19.2185865 },
    { lat: 48.7288469, lng: 19.2205311 },
    { lat: 48.7284358, lng: 19.2223537 },
    { lat: 48.728546, lng: 19.2230669 },
    { lat: 48.7283703, lng: 19.2236937 },
    { lat: 48.7288165, lng: 19.2251049 },
    { lat: 48.7288536, lng: 19.2255481 },
    { lat: 48.7287059, lng: 19.226963 },
    { lat: 48.7284482, lng: 19.2275036 },
    { lat: 48.728094, lng: 19.2286843 },
    { lat: 48.7277591, lng: 19.2298443 },
    { lat: 48.7277137, lng: 19.230307 },
    { lat: 48.7268909, lng: 19.2311169 },
    { lat: 48.7262635, lng: 19.2330111 },
    { lat: 48.7261825, lng: 19.2337127 },
    { lat: 48.7261763, lng: 19.234215 },
    { lat: 48.7262335, lng: 19.234883 },
    { lat: 48.7266063, lng: 19.2362684 },
    { lat: 48.7269709, lng: 19.237096 },
    { lat: 48.7271538, lng: 19.2376395 },
    { lat: 48.7273772, lng: 19.2380139 },
    { lat: 48.7272982, lng: 19.2381048 },
    { lat: 48.7270316, lng: 19.2390036 },
    { lat: 48.7270417, lng: 19.2399772 },
    { lat: 48.7267731, lng: 19.2404839 },
    { lat: 48.7263267, lng: 19.2407378 },
    { lat: 48.7261318, lng: 19.2406926 },
    { lat: 48.7256386, lng: 19.2413562 },
    { lat: 48.725196, lng: 19.2421338 },
    { lat: 48.724804, lng: 19.2424195 },
    { lat: 48.7249676, lng: 19.2425385 },
    { lat: 48.7251032, lng: 19.2430073 },
    { lat: 48.7253985, lng: 19.2432678 },
    { lat: 48.7255234, lng: 19.2435649 },
    { lat: 48.7257594, lng: 19.2438537 },
    { lat: 48.726046, lng: 19.2440891 },
    { lat: 48.7262774, lng: 19.2445669 },
    { lat: 48.7265891, lng: 19.245018 },
    { lat: 48.7268309, lng: 19.2454754 },
    { lat: 48.7278242, lng: 19.2467384 },
    { lat: 48.7286785, lng: 19.2469478 },
    { lat: 48.7291105, lng: 19.2469523 },
    { lat: 48.7294328, lng: 19.2468563 },
    { lat: 48.7300046, lng: 19.2468181 },
    { lat: 48.7303126, lng: 19.2474093 },
    { lat: 48.7309847, lng: 19.2475778 },
    { lat: 48.7311984, lng: 19.2477067 },
    { lat: 48.7317857, lng: 19.2474744 },
    { lat: 48.731867, lng: 19.2474761 },
    { lat: 48.7319185, lng: 19.2475836 },
    { lat: 48.7322034, lng: 19.2475796 },
    { lat: 48.7322091, lng: 19.2480246 },
    { lat: 48.7323248, lng: 19.2490223 },
    { lat: 48.7322954, lng: 19.2499046 },
    { lat: 48.7324437, lng: 19.2504306 },
    { lat: 48.7326417, lng: 19.2507019 },
    { lat: 48.7328898, lng: 19.2513329 },
    { lat: 48.7327705, lng: 19.2526193 },
    { lat: 48.7326407, lng: 19.2532714 },
    { lat: 48.7327564, lng: 19.2536824 },
    { lat: 48.7328126, lng: 19.2544246 },
    { lat: 48.7332349, lng: 19.2552741 },
    { lat: 48.7332656, lng: 19.2553358 },
    { lat: 48.7335233, lng: 19.2555609 },
    { lat: 48.7342482, lng: 19.2557031 },
    { lat: 48.7347023, lng: 19.2558645 },
    { lat: 48.7347666, lng: 19.2558873 },
    { lat: 48.7351571, lng: 19.2558205 },
    { lat: 48.7351904, lng: 19.2558148 },
    { lat: 48.7354243, lng: 19.2560847 },
    { lat: 48.7354501, lng: 19.2560383 },
    { lat: 48.73579, lng: 19.2567042 },
    { lat: 48.7359535, lng: 19.2567733 },
    { lat: 48.7361975, lng: 19.2573216 },
    { lat: 48.7361894, lng: 19.2577614 },
    { lat: 48.7364337, lng: 19.2578012 },
    { lat: 48.7369929, lng: 19.257666 },
    { lat: 48.7374867, lng: 19.2577137 },
    { lat: 48.7377497, lng: 19.258166 },
    { lat: 48.7379561, lng: 19.2583561 },
    { lat: 48.7383005, lng: 19.2589302 },
    { lat: 48.7386371, lng: 19.2590131 },
    { lat: 48.7391296, lng: 19.2595737 },
    { lat: 48.7392439, lng: 19.2595706 },
    { lat: 48.7412684, lng: 19.259515 },
    { lat: 48.7418664, lng: 19.2616276 },
    { lat: 48.7420271, lng: 19.2621956 },
    { lat: 48.7422358, lng: 19.2629328 },
    { lat: 48.7422373, lng: 19.2654004 },
    { lat: 48.7422386, lng: 19.2654277 },
    { lat: 48.7423814, lng: 19.2655584 },
    { lat: 48.7425596, lng: 19.2656095 },
    { lat: 48.7430884, lng: 19.2655888 },
    { lat: 48.7433039, lng: 19.2652168 },
    { lat: 48.7435803, lng: 19.2650821 },
    { lat: 48.7436244, lng: 19.2649433 },
    { lat: 48.7438729, lng: 19.2646306 },
    { lat: 48.7443196, lng: 19.2646121 },
    { lat: 48.7443912, lng: 19.2642042 },
    { lat: 48.7445168, lng: 19.2640279 },
    { lat: 48.744667, lng: 19.2635671 },
    { lat: 48.7446479, lng: 19.2626992 },
    { lat: 48.7448235, lng: 19.2624627 },
    { lat: 48.7448242, lng: 19.2621037 },
    { lat: 48.7447576, lng: 19.2618439 },
    { lat: 48.7448146, lng: 19.2615512 },
    { lat: 48.7449091, lng: 19.2613604 },
    { lat: 48.7451238, lng: 19.2611171 },
    { lat: 48.7450737, lng: 19.2606355 },
    { lat: 48.7452508, lng: 19.2600327 },
    { lat: 48.7454251, lng: 19.2598853 },
    { lat: 48.7453899, lng: 19.2596695 },
    { lat: 48.7454344, lng: 19.25928 },
    { lat: 48.7456172, lng: 19.259123 },
    { lat: 48.7455769, lng: 19.2585232 },
    { lat: 48.7457883, lng: 19.2579738 },
    { lat: 48.7457804, lng: 19.2578782 },
    { lat: 48.745676, lng: 19.2577462 },
    { lat: 48.7456816, lng: 19.2574902 },
    { lat: 48.7455217, lng: 19.2573525 },
    { lat: 48.745461, lng: 19.2570738 },
    { lat: 48.7456392, lng: 19.2567731 },
    { lat: 48.7456358, lng: 19.2566842 },
    { lat: 48.7454458, lng: 19.256623 },
    { lat: 48.7454085, lng: 19.2564571 },
    { lat: 48.7454462, lng: 19.2563472 },
    { lat: 48.7455936, lng: 19.2563228 },
    { lat: 48.7455637, lng: 19.2560739 },
    { lat: 48.7456409, lng: 19.2556868 },
    { lat: 48.7456496, lng: 19.2552362 },
    { lat: 48.7455247, lng: 19.2549382 },
    { lat: 48.7455444, lng: 19.2548828 },
    { lat: 48.7457066, lng: 19.254856 },
    { lat: 48.7456651, lng: 19.2545309 },
    { lat: 48.7459473, lng: 19.2539126 },
    { lat: 48.7459575, lng: 19.2537233 },
    { lat: 48.7460716, lng: 19.2534982 },
    { lat: 48.7459303, lng: 19.2534423 },
    { lat: 48.7458878, lng: 19.2532867 },
    { lat: 48.7459484, lng: 19.2522271 },
    { lat: 48.745027, lng: 19.248277 },
    { lat: 48.7446407, lng: 19.2469304 },
    { lat: 48.7441461, lng: 19.2457679 },
    { lat: 48.7435021, lng: 19.2436409 },
    { lat: 48.7430424, lng: 19.2416607 },
    { lat: 48.742853, lng: 19.2405206 },
    { lat: 48.7428431, lng: 19.2396853 },
    { lat: 48.7429716, lng: 19.2389879 },
    { lat: 48.7433602, lng: 19.2380042 },
    { lat: 48.7443326, lng: 19.2358562 },
    { lat: 48.7446424, lng: 19.2349911 },
    { lat: 48.7448365, lng: 19.2340153 },
    { lat: 48.7449519, lng: 19.2330483 },
    { lat: 48.7445917, lng: 19.2318046 },
    { lat: 48.7441997, lng: 19.2310184 },
    { lat: 48.7443851, lng: 19.2306557 },
    { lat: 48.7447385, lng: 19.229624 },
    { lat: 48.746951, lng: 19.2269217 },
    { lat: 48.7470213, lng: 19.2269775 },
    { lat: 48.7471929, lng: 19.2269617 },
    { lat: 48.7478192, lng: 19.2264869 },
    { lat: 48.7479982, lng: 19.2262284 },
    { lat: 48.7524352, lng: 19.223354 },
    { lat: 48.7552496, lng: 19.222395 },
    { lat: 48.7579077, lng: 19.2215411 },
    { lat: 48.7577422, lng: 19.2167856 },
    { lat: 48.7574275, lng: 19.2166434 },
    { lat: 48.7572624, lng: 19.2167503 },
    { lat: 48.7571017, lng: 19.2169672 },
    { lat: 48.7568982, lng: 19.2167962 },
    { lat: 48.7564821, lng: 19.2167881 },
    { lat: 48.7557979, lng: 19.2169731 },
    { lat: 48.7554736, lng: 19.216589 },
    { lat: 48.7552409, lng: 19.2167406 },
    { lat: 48.7549969, lng: 19.2165656 },
    { lat: 48.7546996, lng: 19.21648 },
    { lat: 48.7542422, lng: 19.2159279 },
    { lat: 48.7537513, lng: 19.2155666 },
    { lat: 48.7533761, lng: 19.2154079 },
    { lat: 48.7525093, lng: 19.2152496 },
    { lat: 48.7519976, lng: 19.2148546 },
    { lat: 48.7517658, lng: 19.2143774 },
    { lat: 48.75126, lng: 19.2141268 },
    { lat: 48.7511065, lng: 19.2134513 },
    { lat: 48.750907, lng: 19.2133574 },
    { lat: 48.7507712, lng: 19.2131135 },
    { lat: 48.7500172, lng: 19.2124922 },
    { lat: 48.7493217, lng: 19.2127471 },
    { lat: 48.7503957, lng: 19.2084295 },
    { lat: 48.7506096, lng: 19.2069423 },
    { lat: 48.7512196, lng: 19.2059257 },
    { lat: 48.7516736, lng: 19.2046742 },
    { lat: 48.7519043, lng: 19.2032764 },
    { lat: 48.7525151, lng: 19.2019078 },
    { lat: 48.7528358, lng: 19.2021732 },
    { lat: 48.7527728, lng: 19.201414 },
    { lat: 48.7525557, lng: 19.2015142 },
    { lat: 48.752838, lng: 19.1993966 },
    { lat: 48.7528874, lng: 19.1981423 },
    { lat: 48.7531345, lng: 19.1971535 },
    { lat: 48.753623, lng: 19.1955458 },
    { lat: 48.75358, lng: 19.1948247 },
    { lat: 48.7538384, lng: 19.1932612 },
    { lat: 48.7538188, lng: 19.1928594 },
    { lat: 48.7536967, lng: 19.192065 },
    { lat: 48.7539256, lng: 19.191006 },
    { lat: 48.7539471, lng: 19.1909807 },
    { lat: 48.7537958, lng: 19.1909058 },
    { lat: 48.7536567, lng: 19.1908967 },
    { lat: 48.7534138, lng: 19.1910031 },
    { lat: 48.7532773, lng: 19.1909794 },
    { lat: 48.7528161, lng: 19.1904186 },
    { lat: 48.752757, lng: 19.1902502 },
    { lat: 48.7528704, lng: 19.1900567 },
    { lat: 48.7528082, lng: 19.1898419 },
    { lat: 48.7522902, lng: 19.189435 },
    { lat: 48.7521084, lng: 19.1891759 },
    { lat: 48.752045, lng: 19.1889855 },
    { lat: 48.7521239, lng: 19.1885927 },
    { lat: 48.7519755, lng: 19.188486 },
    { lat: 48.7519372, lng: 19.1884656 },
    { lat: 48.7515268, lng: 19.1888562 },
    { lat: 48.7508422, lng: 19.1889412 },
    { lat: 48.7507592, lng: 19.1888097 },
    { lat: 48.7507037, lng: 19.1884559 },
    { lat: 48.7506003, lng: 19.1883949 },
    { lat: 48.7498996, lng: 19.1885185 },
    { lat: 48.7494455, lng: 19.1883131 },
    { lat: 48.7492483, lng: 19.1884095 },
    { lat: 48.7489728, lng: 19.1884231 },
    { lat: 48.7489331, lng: 19.1883514 },
    { lat: 48.7489207, lng: 19.1880481 },
    { lat: 48.7488422, lng: 19.1879412 },
    { lat: 48.7486618, lng: 19.1878495 },
    { lat: 48.748832, lng: 19.1873158 },
    { lat: 48.7487628, lng: 19.1870296 },
    { lat: 48.7485524, lng: 19.1869938 },
    { lat: 48.7483399, lng: 19.1868639 },
    { lat: 48.748002, lng: 19.1869388 },
    { lat: 48.7477742, lng: 19.1868932 },
    { lat: 48.7477075, lng: 19.1867285 },
    { lat: 48.7476282, lng: 19.1866713 },
    { lat: 48.7469775, lng: 19.1869183 },
    { lat: 48.7466575, lng: 19.1868769 },
    { lat: 48.7464745, lng: 19.1870084 },
    { lat: 48.746489, lng: 19.1866578 },
    { lat: 48.7459964, lng: 19.1775534 },
    { lat: 48.7449039, lng: 19.1743265 },
    { lat: 48.7451874, lng: 19.1743115 },
    { lat: 48.7454461, lng: 19.1742554 },
    { lat: 48.7457206, lng: 19.174429 },
    { lat: 48.7458766, lng: 19.1740697 },
    { lat: 48.746093, lng: 19.1739772 },
    { lat: 48.7460342, lng: 19.173767 },
    { lat: 48.7461669, lng: 19.1737125 },
    { lat: 48.7470064, lng: 19.1737078 },
    { lat: 48.7472977, lng: 19.1735287 },
    { lat: 48.747495, lng: 19.1743367 },
    { lat: 48.747989, lng: 19.1763457 },
    { lat: 48.7482951, lng: 19.1756024 },
    { lat: 48.7490413, lng: 19.1742161 },
    { lat: 48.7505212, lng: 19.1770921 },
    { lat: 48.7514362, lng: 19.1773233 },
    { lat: 48.755654, lng: 19.1783888 },
    { lat: 48.7557073, lng: 19.1780292 },
    { lat: 48.7558204, lng: 19.1777393 },
    { lat: 48.7566921, lng: 19.175785 },
    { lat: 48.756781, lng: 19.1756658 },
    { lat: 48.7574045, lng: 19.1752548 },
    { lat: 48.7581272, lng: 19.182447 },
    { lat: 48.7584712, lng: 19.1825886 },
    { lat: 48.7585768, lng: 19.1825357 },
    { lat: 48.7586071, lng: 19.1822147 },
    { lat: 48.7588239, lng: 19.1819911 },
    { lat: 48.7599941, lng: 19.1813915 },
    { lat: 48.7599357, lng: 19.1810522 },
    { lat: 48.7600659, lng: 19.1809951 },
    { lat: 48.7603534, lng: 19.1810648 },
    { lat: 48.7602145, lng: 19.1805762 },
    { lat: 48.7604045, lng: 19.1803802 },
    { lat: 48.7604706, lng: 19.1804839 },
    { lat: 48.7605233, lng: 19.1803394 },
    { lat: 48.7604868, lng: 19.1802177 },
    { lat: 48.7607058, lng: 19.1800203 },
    { lat: 48.7606107, lng: 19.1797791 },
    { lat: 48.7608517, lng: 19.1797528 },
    { lat: 48.7609513, lng: 19.1798288 },
    { lat: 48.7611646, lng: 19.1796496 },
    { lat: 48.7611959, lng: 19.1794592 },
    { lat: 48.7613584, lng: 19.1792799 },
    { lat: 48.7615782, lng: 19.1792781 },
    { lat: 48.7615626, lng: 19.1789826 },
    { lat: 48.7617776, lng: 19.1790697 },
    { lat: 48.7620372, lng: 19.1790506 },
    { lat: 48.7619792, lng: 19.1788356 },
    { lat: 48.7622795, lng: 19.1784518 },
    { lat: 48.7624378, lng: 19.1779793 },
    { lat: 48.7623587, lng: 19.1779985 },
    { lat: 48.7622513, lng: 19.1778994 },
    { lat: 48.7622219, lng: 19.1773421 },
    { lat: 48.762279, lng: 19.176943 },
    { lat: 48.7623589, lng: 19.1767333 },
    { lat: 48.7622968, lng: 19.1763865 },
    { lat: 48.7623491, lng: 19.1760487 },
    { lat: 48.7625203, lng: 19.1763233 },
    { lat: 48.7627846, lng: 19.1757595 },
    { lat: 48.7631088, lng: 19.17591 },
    { lat: 48.7636246, lng: 19.1744942 },
    { lat: 48.7642311, lng: 19.1720068 },
    { lat: 48.7643162, lng: 19.1718594 },
    { lat: 48.7644256, lng: 19.1706162 },
    { lat: 48.7645058, lng: 19.1677919 },
    { lat: 48.7645586, lng: 19.1673704 },
    { lat: 48.7651837, lng: 19.1663016 },
    { lat: 48.7659432, lng: 19.166549 },
    { lat: 48.7669687, lng: 19.1659687 },
    { lat: 48.7673919, lng: 19.1653592 },
    { lat: 48.7675359, lng: 19.1653519 },
    { lat: 48.7676391, lng: 19.1651106 },
    { lat: 48.7682219, lng: 19.1646303 },
    { lat: 48.768475, lng: 19.1650325 },
    { lat: 48.7686349, lng: 19.1653976 },
    { lat: 48.7703678, lng: 19.1634964 },
    { lat: 48.7706759, lng: 19.1636169 },
    { lat: 48.7715965, lng: 19.1642551 },
    { lat: 48.7717424, lng: 19.164287 },
    { lat: 48.7718473, lng: 19.1642285 },
    { lat: 48.77185, lng: 19.164227 },
    { lat: 48.771997, lng: 19.1639639 },
    { lat: 48.7722241, lng: 19.1639513 },
    { lat: 48.7725315, lng: 19.1637835 },
    { lat: 48.773104, lng: 19.1638014 },
    { lat: 48.7733084, lng: 19.1637406 },
    { lat: 48.7733991, lng: 19.1636139 },
    { lat: 48.7736303, lng: 19.1629485 },
    { lat: 48.7737268, lng: 19.162623 },
    { lat: 48.7738019, lng: 19.16237 },
    { lat: 48.7739618, lng: 19.1617883 },
    { lat: 48.77397, lng: 19.161759 },
    { lat: 48.774197, lng: 19.1612199 },
    { lat: 48.7743728, lng: 19.1610329 },
    { lat: 48.7745738, lng: 19.1609234 },
    { lat: 48.774793, lng: 19.1610011 },
    { lat: 48.7750776, lng: 19.1610628 },
    { lat: 48.775348, lng: 19.1611299 },
    { lat: 48.7754714, lng: 19.1612297 },
    { lat: 48.7758518, lng: 19.161028 },
    { lat: 48.775896, lng: 19.1609234 },
    { lat: 48.7767295, lng: 19.1621506 },
    { lat: 48.7770001, lng: 19.1620141 },
    { lat: 48.7774918, lng: 19.1613745 },
    { lat: 48.7775584, lng: 19.1605282 },
    { lat: 48.7772407, lng: 19.1596564 },
    { lat: 48.7772046, lng: 19.159292 },
    { lat: 48.7769267, lng: 19.1594136 },
    { lat: 48.7761514, lng: 19.159515 },
    { lat: 48.7759981, lng: 19.1592831 },
    { lat: 48.7766134, lng: 19.1589555 },
    { lat: 48.7766286, lng: 19.1589474 },
    { lat: 48.7771327, lng: 19.158594 },
    { lat: 48.7775244, lng: 19.1583193 },
    { lat: 48.7776584, lng: 19.1582254 },
    { lat: 48.7776891, lng: 19.1582039 },
    { lat: 48.7777754, lng: 19.1581301 },
    { lat: 48.7779469, lng: 19.1579834 },
    { lat: 48.7781408, lng: 19.1578174 },
    { lat: 48.7781985, lng: 19.1577681 },
    { lat: 48.7782379, lng: 19.1577565 },
    { lat: 48.7783449, lng: 19.1577248 },
    { lat: 48.7785295, lng: 19.1576702 },
    { lat: 48.778766, lng: 19.1576004 },
    { lat: 48.7789249, lng: 19.1575534 },
    { lat: 48.7791676, lng: 19.1574816 },
    { lat: 48.7794227, lng: 19.1572564 },
    { lat: 48.7805828, lng: 19.156232 },
    { lat: 48.780606, lng: 19.1562898 },
    { lat: 48.7812398, lng: 19.1556999 },
    { lat: 48.7823083, lng: 19.1539491 },
    { lat: 48.7832998, lng: 19.1526572 },
    { lat: 48.7834656, lng: 19.1525431 },
    { lat: 48.7847579, lng: 19.1522336 },
    { lat: 48.7858974, lng: 19.1517916 },
    { lat: 48.7870262, lng: 19.1516399 },
    { lat: 48.7878333, lng: 19.1512734 },
    { lat: 48.7881254, lng: 19.1510225 },
    { lat: 48.7885665, lng: 19.1503655 },
    { lat: 48.7888365, lng: 19.1498503 },
    { lat: 48.7890902, lng: 19.1494603 },
    { lat: 48.7890585, lng: 19.1494128 },
    { lat: 48.7846058, lng: 19.1478743 },
    { lat: 48.7848147, lng: 19.1474672 },
    { lat: 48.7850837, lng: 19.1473454 },
    { lat: 48.7852327, lng: 19.1469615 },
    { lat: 48.785362, lng: 19.1465158 },
    { lat: 48.7853872, lng: 19.1462263 },
    { lat: 48.7855206, lng: 19.1460286 },
    { lat: 48.78559, lng: 19.1449412 },
    { lat: 48.785766, lng: 19.1446007 },
    { lat: 48.7858215, lng: 19.1447214 },
    { lat: 48.7863243, lng: 19.1446991 },
    { lat: 48.7864528, lng: 19.1446077 },
    { lat: 48.7866646, lng: 19.1446496 },
    { lat: 48.7868134, lng: 19.1443505 },
    { lat: 48.786614, lng: 19.1440819 },
    { lat: 48.7862425, lng: 19.1438621 },
    { lat: 48.7853388, lng: 19.1435475 },
    { lat: 48.7854043, lng: 19.1434132 },
    { lat: 48.7855753, lng: 19.143222 },
    { lat: 48.7857951, lng: 19.1428344 },
    { lat: 48.7857722, lng: 19.1425528 },
    { lat: 48.7858293, lng: 19.1424055 },
    { lat: 48.7857786, lng: 19.1416501 },
    { lat: 48.7856459, lng: 19.1406949 },
    { lat: 48.7817051, lng: 19.1323012 },
    { lat: 48.7816136, lng: 19.131246 },
    { lat: 48.7813202, lng: 19.1305982 },
    { lat: 48.7803584, lng: 19.1293614 },
    { lat: 48.7798944, lng: 19.1290481 },
    { lat: 48.7795299, lng: 19.1286979 },
    { lat: 48.7805391, lng: 19.1265125 },
    { lat: 48.7809568, lng: 19.1248096 },
    { lat: 48.7807829, lng: 19.124459 },
    { lat: 48.7810945, lng: 19.1244498 },
    { lat: 48.7814295, lng: 19.1243365 },
    { lat: 48.7822261, lng: 19.1234872 },
    { lat: 48.7831847, lng: 19.1228069 },
    { lat: 48.7837614, lng: 19.1227989 },
    { lat: 48.7836408, lng: 19.1223671 },
    { lat: 48.7833248, lng: 19.1216504 },
    { lat: 48.7831984, lng: 19.1210992 },
    { lat: 48.7827822, lng: 19.1202065 },
    { lat: 48.7825526, lng: 19.1198053 },
    { lat: 48.7819556, lng: 19.1190811 },
    { lat: 48.7815515, lng: 19.1186861 },
    { lat: 48.7816142, lng: 19.1176952 },
    { lat: 48.7820514, lng: 19.1177612 },
    { lat: 48.7826352, lng: 19.1180973 },
    { lat: 48.7838562, lng: 19.11931 },
    { lat: 48.7839801, lng: 19.1192652 },
    { lat: 48.7843398, lng: 19.1196317 },
    { lat: 48.7849255, lng: 19.1199872 },
    { lat: 48.7857214, lng: 19.1202804 },
    { lat: 48.7862476, lng: 19.1205504 },
    { lat: 48.7867734, lng: 19.1209467 },
    { lat: 48.7868935, lng: 19.1210071 },
    { lat: 48.7870217, lng: 19.1209762 },
    { lat: 48.7872817, lng: 19.121266 },
    { lat: 48.7877195, lng: 19.1214356 },
    { lat: 48.7880024, lng: 19.121409 },
    { lat: 48.7883075, lng: 19.1212647 },
    { lat: 48.7886157, lng: 19.1210003 },
    { lat: 48.7887938, lng: 19.1209076 },
    { lat: 48.7889183, lng: 19.1209175 },
    { lat: 48.7891756, lng: 19.1206281 },
    { lat: 48.7893275, lng: 19.1206238 },
    { lat: 48.7895283, lng: 19.1203991 },
    { lat: 48.7898302, lng: 19.1203038 },
    { lat: 48.790021, lng: 19.1205114 },
    { lat: 48.7903396, lng: 19.1205736 },
    { lat: 48.7904352, lng: 19.1204756 },
    { lat: 48.7908088, lng: 19.120512 },
    { lat: 48.791385, lng: 19.1208372 },
    { lat: 48.7917904, lng: 19.1208288 },
    { lat: 48.7921668, lng: 19.1207355 },
    { lat: 48.7924957, lng: 19.1208656 },
    { lat: 48.7925966, lng: 19.1210144 },
    { lat: 48.7928074, lng: 19.1210831 },
    { lat: 48.7931351, lng: 19.1213295 },
    { lat: 48.7932892, lng: 19.1212967 },
    { lat: 48.7935698, lng: 19.121684 },
    { lat: 48.7937004, lng: 19.1217268 },
    { lat: 48.7938446, lng: 19.1216322 },
    { lat: 48.7941784, lng: 19.1215894 },
    { lat: 48.7943118, lng: 19.1216423 },
    { lat: 48.7944593, lng: 19.1215709 },
    { lat: 48.7947839, lng: 19.1217308 },
    { lat: 48.7952192, lng: 19.1216612 },
    { lat: 48.7954837, lng: 19.1217038 },
    { lat: 48.7962133, lng: 19.1214093 },
    { lat: 48.7964833, lng: 19.121698 },
    { lat: 48.7967663, lng: 19.1216485 },
    { lat: 48.7970115, lng: 19.1217795 },
    { lat: 48.7973082, lng: 19.121688 },
    { lat: 48.7976914, lng: 19.121665 },
    { lat: 48.7983588, lng: 19.1218477 },
    { lat: 48.7986487, lng: 19.1222418 },
    { lat: 48.7988094, lng: 19.1223313 },
    { lat: 48.7993871, lng: 19.1226528 },
    { lat: 48.799541, lng: 19.1228461 },
    { lat: 48.8002851, lng: 19.1239654 },
    { lat: 48.8003378, lng: 19.1243979 },
    { lat: 48.8010459, lng: 19.1267301 },
    { lat: 48.8012378, lng: 19.1272834 },
    { lat: 48.8014052, lng: 19.1275448 },
    { lat: 48.8023179, lng: 19.1282488 },
    { lat: 48.8029852, lng: 19.1286794 },
    { lat: 48.8029956, lng: 19.1282712 },
    { lat: 48.8032054, lng: 19.1281617 },
    { lat: 48.8033046, lng: 19.127981 },
    { lat: 48.80403, lng: 19.1279226 },
    { lat: 48.8036658, lng: 19.1273008 },
    { lat: 48.803013, lng: 19.1265699 },
    { lat: 48.8028272, lng: 19.1262527 },
    { lat: 48.8026773, lng: 19.1258662 },
    { lat: 48.8026387, lng: 19.1253135 },
    { lat: 48.8028166, lng: 19.1245524 },
    { lat: 48.8028329, lng: 19.1238114 },
    { lat: 48.8029325, lng: 19.1237046 },
    { lat: 48.8030851, lng: 19.1238519 },
    { lat: 48.8031243, lng: 19.1243449 },
    { lat: 48.8033338, lng: 19.1242768 },
    { lat: 48.8033184, lng: 19.1239853 },
    { lat: 48.8034136, lng: 19.1237943 },
    { lat: 48.8035555, lng: 19.1238791 },
    { lat: 48.8034956, lng: 19.1242206 },
    { lat: 48.8037175, lng: 19.1241455 },
    { lat: 48.8038399, lng: 19.1237976 },
    { lat: 48.8037525, lng: 19.1238064 },
    { lat: 48.8037414, lng: 19.123581 },
    { lat: 48.8039955, lng: 19.1235654 },
    { lat: 48.8040019, lng: 19.1230347 },
    { lat: 48.8039779, lng: 19.1229359 },
    { lat: 48.8036579, lng: 19.122484 },
    { lat: 48.8035507, lng: 19.1216797 },
    { lat: 48.8032069, lng: 19.1212476 },
    { lat: 48.8031315, lng: 19.1210466 },
    { lat: 48.8030686, lng: 19.1206868 },
    { lat: 48.8030869, lng: 19.1201862 },
    { lat: 48.8030676, lng: 19.1201151 },
    { lat: 48.8028227, lng: 19.1199311 },
    { lat: 48.802768, lng: 19.1198032 },
    { lat: 48.8027461, lng: 19.1192932 },
    { lat: 48.8026079, lng: 19.1184796 },
    { lat: 48.8023689, lng: 19.1182277 },
    { lat: 48.8019795, lng: 19.1180594 },
    { lat: 48.8015322, lng: 19.117746 },
    { lat: 48.8010962, lng: 19.1171538 },
    { lat: 48.8007539, lng: 19.1168007 },
    { lat: 48.8004495, lng: 19.1163424 },
    { lat: 48.7999812, lng: 19.1159438 },
    { lat: 48.7996506, lng: 19.1153447 },
    { lat: 48.7996266, lng: 19.1152259 },
    { lat: 48.7997155, lng: 19.1145843 },
    { lat: 48.7997784, lng: 19.1144649 },
    { lat: 48.8001461, lng: 19.114262 },
    { lat: 48.8005942, lng: 19.1142287 },
    { lat: 48.8008973, lng: 19.1137946 },
    { lat: 48.8010229, lng: 19.1125939 },
    { lat: 48.8012571, lng: 19.1114026 },
    { lat: 48.8012984, lng: 19.1107149 },
    { lat: 48.8014, lng: 19.1105089 },
    { lat: 48.8015678, lng: 19.1104834 },
    { lat: 48.8024134, lng: 19.1110116 },
    { lat: 48.8030976, lng: 19.1110996 },
    { lat: 48.8036728, lng: 19.1110598 },
    { lat: 48.8038188, lng: 19.1108641 },
    { lat: 48.804196, lng: 19.1107205 },
    { lat: 48.8038966, lng: 19.1094766 },
    { lat: 48.8040428, lng: 19.108742 },
    { lat: 48.8037715, lng: 19.1078855 },
    { lat: 48.8035303, lng: 19.105996 },
    { lat: 48.8035892, lng: 19.1052473 },
    { lat: 48.8038143, lng: 19.1044697 },
    { lat: 48.8040508, lng: 19.1029158 },
    { lat: 48.8042234, lng: 19.1024628 },
    { lat: 48.8042628, lng: 19.1012127 },
    { lat: 48.803639, lng: 19.0990885 },
    { lat: 48.8036507, lng: 19.0986659 },
    { lat: 48.8035784, lng: 19.0987084 },
    { lat: 48.8031367, lng: 19.0988237 },
    { lat: 48.8022268, lng: 19.0987776 },
    { lat: 48.8019248, lng: 19.0985987 },
    { lat: 48.8013914, lng: 19.0985224 },
    { lat: 48.8008265, lng: 19.0986595 },
    { lat: 48.8004993, lng: 19.0986298 },
    { lat: 48.8002801, lng: 19.0985742 },
    { lat: 48.8001146, lng: 19.0984171 },
    { lat: 48.7995191, lng: 19.0981425 },
    { lat: 48.7987603, lng: 19.0982756 },
    { lat: 48.7982741, lng: 19.0986083 },
    { lat: 48.7979773, lng: 19.0987146 },
    { lat: 48.7975821, lng: 19.0992247 },
    { lat: 48.7971458, lng: 19.0994531 },
    { lat: 48.7962303, lng: 19.099566 },
    { lat: 48.7957801, lng: 19.099444 },
    { lat: 48.7953229, lng: 19.0994316 },
    { lat: 48.7948544, lng: 19.0991684 },
    { lat: 48.7946367, lng: 19.0991888 },
    { lat: 48.7943569, lng: 19.0995449 },
    { lat: 48.7937538, lng: 19.0995015 },
    { lat: 48.7929446, lng: 19.1000518 },
    { lat: 48.7927109, lng: 19.1003837 },
    { lat: 48.7926201, lng: 19.1007157 },
    { lat: 48.7923799, lng: 19.1009175 },
    { lat: 48.7921136, lng: 19.1010017 },
    { lat: 48.7917513, lng: 19.1012649 },
    { lat: 48.7914191, lng: 19.1016151 },
    { lat: 48.7910806, lng: 19.1018362 },
    { lat: 48.7905777, lng: 19.102426 },
    { lat: 48.7905073, lng: 19.1024176 },
    { lat: 48.7905462, lng: 19.1023588 },
    { lat: 48.7901892, lng: 19.1018909 },
    { lat: 48.7901916, lng: 19.1016186 },
    { lat: 48.7899218, lng: 19.1015416 },
    { lat: 48.7898421, lng: 19.1011395 },
    { lat: 48.7897083, lng: 19.100804 },
    { lat: 48.7894868, lng: 19.1005628 },
    { lat: 48.7893296, lng: 19.1006653 },
    { lat: 48.7891777, lng: 19.1003315 },
    { lat: 48.7890023, lng: 19.0993861 },
    { lat: 48.7888856, lng: 19.0993286 },
    { lat: 48.7882285, lng: 19.0985123 },
    { lat: 48.7863999, lng: 19.0939899 },
    { lat: 48.7864821, lng: 19.0939176 },
    { lat: 48.7865242, lng: 19.0935396 },
    { lat: 48.7867368, lng: 19.0931912 },
    { lat: 48.7868018, lng: 19.0931827 },
    { lat: 48.7868796, lng: 19.0929801 },
    { lat: 48.7868073, lng: 19.0925222 },
    { lat: 48.7867274, lng: 19.0923654 },
    { lat: 48.7867068, lng: 19.0904994 },
    { lat: 48.7865802, lng: 19.0885629 },
    { lat: 48.7865627, lng: 19.0870988 },
    { lat: 48.7865122, lng: 19.0864925 },
    { lat: 48.7862851, lng: 19.085957 },
    { lat: 48.7856259, lng: 19.0838545 },
    { lat: 48.7854404, lng: 19.0831188 },
    { lat: 48.785249, lng: 19.0826237 },
    { lat: 48.7850622, lng: 19.0822242 },
    { lat: 48.7847119, lng: 19.0816853 },
    { lat: 48.7842384, lng: 19.081321 },
    { lat: 48.7835311, lng: 19.0768983 },
    { lat: 48.7831476, lng: 19.0763926 },
    { lat: 48.7832217, lng: 19.0753238 },
    { lat: 48.7832017, lng: 19.0744721 },
    { lat: 48.7834971, lng: 19.0743558 },
    { lat: 48.7836377, lng: 19.0739683 },
    { lat: 48.783567, lng: 19.0736761 },
    { lat: 48.78369, lng: 19.0734326 },
    { lat: 48.7836001, lng: 19.0732544 },
    { lat: 48.7834378, lng: 19.0733698 },
    { lat: 48.7831291, lng: 19.0734404 },
    { lat: 48.7828451, lng: 19.0733309 },
    { lat: 48.7828257, lng: 19.0737571 },
    { lat: 48.7824432, lng: 19.0737946 },
    { lat: 48.7822324, lng: 19.0734791 },
    { lat: 48.7822021, lng: 19.0735265 },
    { lat: 48.7821117, lng: 19.0733624 },
    { lat: 48.7818963, lng: 19.0732489 },
    { lat: 48.7818487, lng: 19.0729487 },
    { lat: 48.7815515, lng: 19.0726391 },
    { lat: 48.7809101, lng: 19.0724406 },
    { lat: 48.780778, lng: 19.0725479 },
    { lat: 48.780625, lng: 19.0724642 },
    { lat: 48.7805505, lng: 19.0721213 },
    { lat: 48.7802659, lng: 19.0719096 },
    { lat: 48.7799574, lng: 19.0718955 },
    { lat: 48.7794599, lng: 19.0715687 },
    { lat: 48.77931, lng: 19.0716608 },
    { lat: 48.7790487, lng: 19.0714817 },
    { lat: 48.7787484, lng: 19.0712759 },
    { lat: 48.7786521, lng: 19.0711846 },
    { lat: 48.7780457, lng: 19.0706098 },
    { lat: 48.7778142, lng: 19.0702873 },
    { lat: 48.7774899, lng: 19.0701134 },
    { lat: 48.7767991, lng: 19.0694856 },
    { lat: 48.7766216, lng: 19.0706032 },
    { lat: 48.7755287, lng: 19.0704831 },
    { lat: 48.7752423, lng: 19.0705293 },
    { lat: 48.7749368, lng: 19.0707318 },
    { lat: 48.7738759, lng: 19.0721778 },
    { lat: 48.7737093, lng: 19.0725646 },
    { lat: 48.7728609, lng: 19.0736157 },
    { lat: 48.7727054, lng: 19.0741363 },
    { lat: 48.7724531, lng: 19.0744835 },
    { lat: 48.7715947, lng: 19.0761253 },
    { lat: 48.7708584, lng: 19.0772747 },
    { lat: 48.7705497, lng: 19.0779814 },
    { lat: 48.7703219, lng: 19.0788401 },
    { lat: 48.7705407, lng: 19.079229 },
    { lat: 48.769789, lng: 19.0813371 },
    { lat: 48.7692085, lng: 19.080727 },
    { lat: 48.7684776, lng: 19.0829867 },
    { lat: 48.7673268, lng: 19.0842156 },
    { lat: 48.7656357, lng: 19.0845063 },
    { lat: 48.7650212, lng: 19.0843686 },
    { lat: 48.7648764, lng: 19.0842925 },
    { lat: 48.7645007, lng: 19.0836067 },
    { lat: 48.764278, lng: 19.083324 },
    { lat: 48.7640289, lng: 19.0832551 },
    { lat: 48.7631805, lng: 19.0832631 },
    { lat: 48.7624232, lng: 19.0837002 },
    { lat: 48.7620402, lng: 19.084045 },
    { lat: 48.7615992, lng: 19.0845651 },
    { lat: 48.7612318, lng: 19.0851104 },
    { lat: 48.7610074, lng: 19.0857644 },
    { lat: 48.760317, lng: 19.0868213 },
    { lat: 48.7598771, lng: 19.0875864 },
    { lat: 48.7596019, lng: 19.0882001 },
    { lat: 48.7589224, lng: 19.0890195 },
    { lat: 48.7584617, lng: 19.089005 },
    { lat: 48.7581151, lng: 19.0893364 },
    { lat: 48.7578527, lng: 19.0898832 },
    { lat: 48.7575074, lng: 19.0903998 },
    { lat: 48.757252, lng: 19.0906361 },
    { lat: 48.7558805, lng: 19.0913497 },
    { lat: 48.7555277, lng: 19.0914634 },
    { lat: 48.75419, lng: 19.0928337 },
    { lat: 48.7537789, lng: 19.0927529 },
    { lat: 48.753671, lng: 19.092602 },
    { lat: 48.7531282, lng: 19.0918434 },
    { lat: 48.7529491, lng: 19.0915931 },
    { lat: 48.7525819, lng: 19.0915349 },
    { lat: 48.7523628, lng: 19.0915001 },
    { lat: 48.7515044, lng: 19.0911968 },
    { lat: 48.7506223, lng: 19.0912302 },
    { lat: 48.7503138, lng: 19.0913303 },
    { lat: 48.7477856, lng: 19.0896803 },
    { lat: 48.7467021, lng: 19.0892501 },
    { lat: 48.7457501, lng: 19.0907336 },
    { lat: 48.7454054, lng: 19.0904662 },
    { lat: 48.7451161, lng: 19.0907956 },
    { lat: 48.7451268, lng: 19.0908269 },
    { lat: 48.7452215, lng: 19.0911244 },
    { lat: 48.7449518, lng: 19.0913872 },
    { lat: 48.7448568, lng: 19.0916116 },
    { lat: 48.7445655, lng: 19.0929185 },
    { lat: 48.7441695, lng: 19.0940476 },
    { lat: 48.7438756, lng: 19.0945514 },
    { lat: 48.7427918, lng: 19.0956986 },
    { lat: 48.7427231, lng: 19.0957714 },
    { lat: 48.7426412, lng: 19.0949627 },
    { lat: 48.7426797, lng: 19.0944208 },
    { lat: 48.7428018, lng: 19.0938593 },
    { lat: 48.7430859, lng: 19.0932259 },
    { lat: 48.7430689, lng: 19.0920423 },
    { lat: 48.7429983, lng: 19.0918478 },
    { lat: 48.7429899, lng: 19.0916541 },
    { lat: 48.7431158, lng: 19.0913554 },
    { lat: 48.7431336, lng: 19.0908712 },
    { lat: 48.7432063, lng: 19.0907226 },
    { lat: 48.7433793, lng: 19.0906193 },
    { lat: 48.7434285, lng: 19.0905226 },
    { lat: 48.7434409, lng: 19.0898872 },
    { lat: 48.7434022, lng: 19.0894276 },
    { lat: 48.7435204, lng: 19.0889923 },
    { lat: 48.7436221, lng: 19.0888857 },
    { lat: 48.7435688, lng: 19.0887196 },
    { lat: 48.7431717, lng: 19.0884211 },
    { lat: 48.7429196, lng: 19.0876786 },
    { lat: 48.7429602, lng: 19.0858651 },
    { lat: 48.7428137, lng: 19.0853453 },
    { lat: 48.7427144, lng: 19.0847322 },
    { lat: 48.7427138, lng: 19.0846301 },
    { lat: 48.7429958, lng: 19.0841583 },
    { lat: 48.7429764, lng: 19.0837697 },
    { lat: 48.7431016, lng: 19.0833186 },
    { lat: 48.7432414, lng: 19.0831134 },
    { lat: 48.7432918, lng: 19.0825819 },
    { lat: 48.743418, lng: 19.0822421 },
    { lat: 48.7433938, lng: 19.0819402 },
    { lat: 48.7432165, lng: 19.081705 },
    { lat: 48.7429651, lng: 19.0808888 },
    { lat: 48.7429207, lng: 19.0809281 },
    { lat: 48.7426525, lng: 19.0807258 },
    { lat: 48.742451, lng: 19.0807784 },
    { lat: 48.742325, lng: 19.0806677 },
    { lat: 48.7422129, lng: 19.0806847 },
    { lat: 48.7419781, lng: 19.080585 },
    { lat: 48.7417738, lng: 19.0806233 },
    { lat: 48.7416638, lng: 19.0807737 },
    { lat: 48.7415634, lng: 19.0807261 },
    { lat: 48.7414798, lng: 19.0808357 },
    { lat: 48.7412131, lng: 19.0807789 },
    { lat: 48.7411993, lng: 19.0807004 },
    { lat: 48.74112, lng: 19.0806977 },
    { lat: 48.741054, lng: 19.0808424 },
    { lat: 48.7406931, lng: 19.0808824 },
    { lat: 48.7405202, lng: 19.0806259 },
    { lat: 48.7398704, lng: 19.0805292 },
  ],
  Kynceľová: [
    { lat: 48.7472977, lng: 19.1735287 },
    { lat: 48.7470064, lng: 19.1737078 },
    { lat: 48.7461669, lng: 19.1737125 },
    { lat: 48.7460342, lng: 19.173767 },
    { lat: 48.746093, lng: 19.1739772 },
    { lat: 48.7458766, lng: 19.1740697 },
    { lat: 48.7457206, lng: 19.174429 },
    { lat: 48.7454461, lng: 19.1742554 },
    { lat: 48.7451874, lng: 19.1743115 },
    { lat: 48.7449039, lng: 19.1743265 },
    { lat: 48.7459964, lng: 19.1775534 },
    { lat: 48.746489, lng: 19.1866578 },
    { lat: 48.7464745, lng: 19.1870084 },
    { lat: 48.7466575, lng: 19.1868769 },
    { lat: 48.7469775, lng: 19.1869183 },
    { lat: 48.7476282, lng: 19.1866713 },
    { lat: 48.7477075, lng: 19.1867285 },
    { lat: 48.7477742, lng: 19.1868932 },
    { lat: 48.748002, lng: 19.1869388 },
    { lat: 48.7483399, lng: 19.1868639 },
    { lat: 48.7485524, lng: 19.1869938 },
    { lat: 48.7487628, lng: 19.1870296 },
    { lat: 48.748832, lng: 19.1873158 },
    { lat: 48.7486618, lng: 19.1878495 },
    { lat: 48.7488422, lng: 19.1879412 },
    { lat: 48.7489207, lng: 19.1880481 },
    { lat: 48.7489331, lng: 19.1883514 },
    { lat: 48.7489728, lng: 19.1884231 },
    { lat: 48.7492483, lng: 19.1884095 },
    { lat: 48.7494455, lng: 19.1883131 },
    { lat: 48.7498996, lng: 19.1885185 },
    { lat: 48.7506003, lng: 19.1883949 },
    { lat: 48.7507037, lng: 19.1884559 },
    { lat: 48.7507592, lng: 19.1888097 },
    { lat: 48.7508422, lng: 19.1889412 },
    { lat: 48.7515268, lng: 19.1888562 },
    { lat: 48.7519372, lng: 19.1884656 },
    { lat: 48.7519755, lng: 19.188486 },
    { lat: 48.7521239, lng: 19.1885927 },
    { lat: 48.752045, lng: 19.1889855 },
    { lat: 48.7521084, lng: 19.1891759 },
    { lat: 48.7522902, lng: 19.189435 },
    { lat: 48.7528082, lng: 19.1898419 },
    { lat: 48.7528704, lng: 19.1900567 },
    { lat: 48.752757, lng: 19.1902502 },
    { lat: 48.7528161, lng: 19.1904186 },
    { lat: 48.7532773, lng: 19.1909794 },
    { lat: 48.7534138, lng: 19.1910031 },
    { lat: 48.7536567, lng: 19.1908967 },
    { lat: 48.7537958, lng: 19.1909058 },
    { lat: 48.7539471, lng: 19.1909807 },
    { lat: 48.7541469, lng: 19.1912417 },
    { lat: 48.7542365, lng: 19.1915046 },
    { lat: 48.7543487, lng: 19.1915874 },
    { lat: 48.754613, lng: 19.1916015 },
    { lat: 48.7547329, lng: 19.1914931 },
    { lat: 48.7545347, lng: 19.1908244 },
    { lat: 48.7545908, lng: 19.1906384 },
    { lat: 48.7547534, lng: 19.1906217 },
    { lat: 48.7548743, lng: 19.1900016 },
    { lat: 48.7551372, lng: 19.1899626 },
    { lat: 48.7552541, lng: 19.1898413 },
    { lat: 48.7555289, lng: 19.189856 },
    { lat: 48.7556848, lng: 19.1900254 },
    { lat: 48.7557859, lng: 19.189997 },
    { lat: 48.7556752, lng: 19.1898539 },
    { lat: 48.7557433, lng: 19.1898349 },
    { lat: 48.7556197, lng: 19.189569 },
    { lat: 48.7556367, lng: 19.1894081 },
    { lat: 48.755699, lng: 19.1894358 },
    { lat: 48.7557502, lng: 19.1895848 },
    { lat: 48.755907, lng: 19.1896576 },
    { lat: 48.7560273, lng: 19.1894888 },
    { lat: 48.7560748, lng: 19.1888215 },
    { lat: 48.7560125, lng: 19.188677 },
    { lat: 48.7560784, lng: 19.1885275 },
    { lat: 48.7563213, lng: 19.1886068 },
    { lat: 48.7563506, lng: 19.1885628 },
    { lat: 48.7564772, lng: 19.1878088 },
    { lat: 48.7567998, lng: 19.187005 },
    { lat: 48.7569487, lng: 19.1868465 },
    { lat: 48.7573395, lng: 19.1858472 },
    { lat: 48.7573966, lng: 19.1850974 },
    { lat: 48.7576457, lng: 19.1851682 },
    { lat: 48.757754, lng: 19.1850103 },
    { lat: 48.7576945, lng: 19.1845521 },
    { lat: 48.7578642, lng: 19.1844953 },
    { lat: 48.7579393, lng: 19.1843247 },
    { lat: 48.7578169, lng: 19.1839422 },
    { lat: 48.7575944, lng: 19.1837506 },
    { lat: 48.7575549, lng: 19.183516 },
    { lat: 48.7576065, lng: 19.1834652 },
    { lat: 48.7577154, lng: 19.1835959 },
    { lat: 48.7579508, lng: 19.1836379 },
    { lat: 48.7580082, lng: 19.1834725 },
    { lat: 48.7579764, lng: 19.1834221 },
    { lat: 48.7581272, lng: 19.182447 },
    { lat: 48.7574045, lng: 19.1752548 },
    { lat: 48.756781, lng: 19.1756658 },
    { lat: 48.7566921, lng: 19.175785 },
    { lat: 48.7558204, lng: 19.1777393 },
    { lat: 48.7557073, lng: 19.1780292 },
    { lat: 48.755654, lng: 19.1783888 },
    { lat: 48.7514362, lng: 19.1773233 },
    { lat: 48.7505212, lng: 19.1770921 },
    { lat: 48.7490413, lng: 19.1742161 },
    { lat: 48.7482951, lng: 19.1756024 },
    { lat: 48.747989, lng: 19.1763457 },
    { lat: 48.747495, lng: 19.1743367 },
    { lat: 48.7472977, lng: 19.1735287 },
  ],
  Dúbravica: [
    { lat: 48.6888242, lng: 19.2773463 },
    { lat: 48.6888131, lng: 19.2773275 },
    { lat: 48.6881217, lng: 19.2746968 },
    { lat: 48.6877323, lng: 19.2740268 },
    { lat: 48.6871618, lng: 19.2734164 },
    { lat: 48.6866337, lng: 19.2732154 },
    { lat: 48.6861408, lng: 19.2729267 },
    { lat: 48.6854247, lng: 19.2727118 },
    { lat: 48.6852545, lng: 19.272591 },
    { lat: 48.6851078, lng: 19.2722376 },
    { lat: 48.6846955, lng: 19.272035 },
    { lat: 48.6840675, lng: 19.2715639 },
    { lat: 48.6836601, lng: 19.2708999 },
    { lat: 48.683511, lng: 19.2704469 },
    { lat: 48.6829509, lng: 19.2696327 },
    { lat: 48.6818641, lng: 19.268783 },
    { lat: 48.6816255, lng: 19.2684875 },
    { lat: 48.6810416, lng: 19.2675284 },
    { lat: 48.6804116, lng: 19.2659141 },
    { lat: 48.6797399, lng: 19.2644221 },
    { lat: 48.6796001, lng: 19.26391 },
    { lat: 48.679535, lng: 19.2638918 },
    { lat: 48.6792044, lng: 19.2630219 },
    { lat: 48.6773671, lng: 19.2622497 },
    { lat: 48.6768186, lng: 19.2618378 },
    { lat: 48.6759048, lng: 19.2592064 },
    { lat: 48.6745923, lng: 19.2567927 },
    { lat: 48.6738674, lng: 19.2559524 },
    { lat: 48.6734124, lng: 19.2553306 },
    { lat: 48.6733898, lng: 19.2555863 },
    { lat: 48.6737145, lng: 19.2562396 },
    { lat: 48.6737555, lng: 19.2568093 },
    { lat: 48.6740262, lng: 19.2572777 },
    { lat: 48.6740072, lng: 19.2576521 },
    { lat: 48.674088, lng: 19.2578822 },
    { lat: 48.6744639, lng: 19.2583597 },
    { lat: 48.6744927, lng: 19.2586771 },
    { lat: 48.6746702, lng: 19.2589868 },
    { lat: 48.6746202, lng: 19.2600804 },
    { lat: 48.6747101, lng: 19.260307 },
    { lat: 48.6738612, lng: 19.261143 },
    { lat: 48.6738278, lng: 19.2618894 },
    { lat: 48.6738113, lng: 19.262259 },
    { lat: 48.6738394, lng: 19.2624132 },
    { lat: 48.6739125, lng: 19.2628139 },
    { lat: 48.673961, lng: 19.2629552 },
    { lat: 48.6741093, lng: 19.2633868 },
    { lat: 48.6740628, lng: 19.2636854 },
    { lat: 48.6737515, lng: 19.2640092 },
    { lat: 48.6729643, lng: 19.2643232 },
    { lat: 48.6727861, lng: 19.2643943 },
    { lat: 48.6724546, lng: 19.2639476 },
    { lat: 48.6720107, lng: 19.2644433 },
    { lat: 48.6714826, lng: 19.2651789 },
    { lat: 48.6705876, lng: 19.2656425 },
    { lat: 48.6699747, lng: 19.2663058 },
    { lat: 48.6697841, lng: 19.2678909 },
    { lat: 48.6688152, lng: 19.2679011 },
    { lat: 48.6687014, lng: 19.2688123 },
    { lat: 48.6687123, lng: 19.2697878 },
    { lat: 48.6685399, lng: 19.2700674 },
    { lat: 48.668145, lng: 19.270461 },
    { lat: 48.6680715, lng: 19.2709198 },
    { lat: 48.6681712, lng: 19.2714937 },
    { lat: 48.668164, lng: 19.2722993 },
    { lat: 48.6683606, lng: 19.2733654 },
    { lat: 48.6683255, lng: 19.273903 },
    { lat: 48.6682274, lng: 19.2741545 },
    { lat: 48.66798, lng: 19.2743293 },
    { lat: 48.6676905, lng: 19.2746642 },
    { lat: 48.6673915, lng: 19.2755236 },
    { lat: 48.6671261, lng: 19.2771039 },
    { lat: 48.6666218, lng: 19.2777505 },
    { lat: 48.6657623, lng: 19.2791203 },
    { lat: 48.6652861, lng: 19.2797879 },
    { lat: 48.6650555, lng: 19.2806152 },
    { lat: 48.6646934, lng: 19.2814851 },
    { lat: 48.6646452, lng: 19.2816008 },
    { lat: 48.6646435, lng: 19.2817366 },
    { lat: 48.6646407, lng: 19.2819504 },
    { lat: 48.6646994, lng: 19.2823404 },
    { lat: 48.6647483, lng: 19.2826649 },
    { lat: 48.6647668, lng: 19.2832159 },
    { lat: 48.6648143, lng: 19.2853284 },
    { lat: 48.6646904, lng: 19.2867803 },
    { lat: 48.6646848, lng: 19.2876756 },
    { lat: 48.6646996, lng: 19.2882152 },
    { lat: 48.6647298, lng: 19.2884427 },
    { lat: 48.6648286, lng: 19.2891881 },
    { lat: 48.6653635, lng: 19.2901172 },
    { lat: 48.6657627, lng: 19.290496 },
    { lat: 48.6659685, lng: 19.2907677 },
    { lat: 48.6661049, lng: 19.2910516 },
    { lat: 48.6667753, lng: 19.2919128 },
    { lat: 48.6671735, lng: 19.2922465 },
    { lat: 48.6674537, lng: 19.2923074 },
    { lat: 48.6676872, lng: 19.2922427 },
    { lat: 48.6681126, lng: 19.29176 },
    { lat: 48.6681806, lng: 19.2917447 },
    { lat: 48.6684341, lng: 19.2918111 },
    { lat: 48.6687899, lng: 19.2920885 },
    { lat: 48.668938, lng: 19.2926353 },
    { lat: 48.6692371, lng: 19.2933008 },
    { lat: 48.6693064, lng: 19.2939707 },
    { lat: 48.6694183, lng: 19.2943739 },
    { lat: 48.6699701, lng: 19.2957977 },
    { lat: 48.6700515, lng: 19.2965085 },
    { lat: 48.6701586, lng: 19.2969799 },
    { lat: 48.6704398, lng: 19.2973165 },
    { lat: 48.6714975, lng: 19.2989276 },
    { lat: 48.6716565, lng: 19.2989986 },
    { lat: 48.6718096, lng: 19.2991563 },
    { lat: 48.6721378, lng: 19.2997256 },
    { lat: 48.6722984, lng: 19.3001848 },
    { lat: 48.672523, lng: 19.3004249 },
    { lat: 48.6726778, lng: 19.3009978 },
    { lat: 48.6727831, lng: 19.3011938 },
    { lat: 48.6728309, lng: 19.3015124 },
    { lat: 48.6731274, lng: 19.3012778 },
    { lat: 48.6734309, lng: 19.3011448 },
    { lat: 48.6735243, lng: 19.3012355 },
    { lat: 48.6747226, lng: 19.3014245 },
    { lat: 48.6747234, lng: 19.3017725 },
    { lat: 48.6744728, lng: 19.3024464 },
    { lat: 48.6743543, lng: 19.3025853 },
    { lat: 48.6742038, lng: 19.3026202 },
    { lat: 48.6744745, lng: 19.3037743 },
    { lat: 48.67435, lng: 19.3043553 },
    { lat: 48.674638, lng: 19.3044102 },
    { lat: 48.6746984, lng: 19.3046372 },
    { lat: 48.6747491, lng: 19.3054622 },
    { lat: 48.6750949, lng: 19.3063029 },
    { lat: 48.675142, lng: 19.3067924 },
    { lat: 48.675266, lng: 19.3071715 },
    { lat: 48.6751357, lng: 19.3081454 },
    { lat: 48.6753851, lng: 19.3085258 },
    { lat: 48.6754771, lng: 19.3093175 },
    { lat: 48.6753122, lng: 19.310042 },
    { lat: 48.6755315, lng: 19.3106903 },
    { lat: 48.6753992, lng: 19.3115543 },
    { lat: 48.6750931, lng: 19.3130017 },
    { lat: 48.675222, lng: 19.3142348 },
    { lat: 48.6754536, lng: 19.3147659 },
    { lat: 48.6758126, lng: 19.3151315 },
    { lat: 48.676883, lng: 19.3159956 },
    { lat: 48.6768276, lng: 19.3163448 },
    { lat: 48.6769203, lng: 19.3165194 },
    { lat: 48.6771955, lng: 19.3166638 },
    { lat: 48.6772737, lng: 19.3174998 },
    { lat: 48.6774009, lng: 19.3177791 },
    { lat: 48.6776303, lng: 19.3190848 },
    { lat: 48.6775756, lng: 19.3199221 },
    { lat: 48.6776436, lng: 19.3206148 },
    { lat: 48.6780454, lng: 19.3221828 },
    { lat: 48.6777093, lng: 19.3235047 },
    { lat: 48.6780133, lng: 19.323732 },
    { lat: 48.678083, lng: 19.3248614 },
    { lat: 48.6780643, lng: 19.3253652 },
    { lat: 48.6775204, lng: 19.3260899 },
    { lat: 48.6774711, lng: 19.3264027 },
    { lat: 48.6772916, lng: 19.3265117 },
    { lat: 48.6771216, lng: 19.3272492 },
    { lat: 48.6769585, lng: 19.3275152 },
    { lat: 48.6768803, lng: 19.328569 },
    { lat: 48.676965, lng: 19.3295174 },
    { lat: 48.6766847, lng: 19.3301884 },
    { lat: 48.6763839, lng: 19.3300256 },
    { lat: 48.6762884, lng: 19.3301034 },
    { lat: 48.6757273, lng: 19.3314087 },
    { lat: 48.6753665, lng: 19.3316883 },
    { lat: 48.6748119, lng: 19.3317807 },
    { lat: 48.6747909, lng: 19.3323799 },
    { lat: 48.6746494, lng: 19.3325326 },
    { lat: 48.6740632, lng: 19.33298 },
    { lat: 48.6735749, lng: 19.3329775 },
    { lat: 48.6733851, lng: 19.3335088 },
    { lat: 48.6729721, lng: 19.3335964 },
    { lat: 48.6728481, lng: 19.3342789 },
    { lat: 48.672679, lng: 19.3345868 },
    { lat: 48.6725203, lng: 19.3351325 },
    { lat: 48.6724427, lng: 19.3360125 },
    { lat: 48.6720114, lng: 19.3370878 },
    { lat: 48.6713999, lng: 19.3396775 },
    { lat: 48.6713351, lng: 19.3408263 },
    { lat: 48.671466, lng: 19.3419436 },
    { lat: 48.6714795, lng: 19.3427246 },
    { lat: 48.6713593, lng: 19.3435389 },
    { lat: 48.671416, lng: 19.3441147 },
    { lat: 48.671691, lng: 19.3463664 },
    { lat: 48.6738278, lng: 19.3477602 },
    { lat: 48.6745178, lng: 19.3488563 },
    { lat: 48.6752917, lng: 19.3487068 },
    { lat: 48.6759291, lng: 19.3482932 },
    { lat: 48.6764383, lng: 19.3466282 },
    { lat: 48.6765161, lng: 19.3408234 },
    { lat: 48.6769716, lng: 19.3389559 },
    { lat: 48.6771451, lng: 19.3377933 },
    { lat: 48.6784279, lng: 19.3379158 },
    { lat: 48.6803285, lng: 19.337519 },
    { lat: 48.6805673, lng: 19.3365672 },
    { lat: 48.6807523, lng: 19.3351543 },
    { lat: 48.6813987, lng: 19.3331317 },
    { lat: 48.6821693, lng: 19.3322479 },
    { lat: 48.6822914, lng: 19.3297953 },
    { lat: 48.6819386, lng: 19.3287569 },
    { lat: 48.6818944, lng: 19.3278536 },
    { lat: 48.6818124, lng: 19.3275265 },
    { lat: 48.6817217, lng: 19.3274479 },
    { lat: 48.681788, lng: 19.3267819 },
    { lat: 48.6817047, lng: 19.3265716 },
    { lat: 48.6816204, lng: 19.325605 },
    { lat: 48.6815839, lng: 19.324627 },
    { lat: 48.6816418, lng: 19.3242583 },
    { lat: 48.6815888, lng: 19.3239984 },
    { lat: 48.6816176, lng: 19.323656 },
    { lat: 48.6815016, lng: 19.3232353 },
    { lat: 48.6814694, lng: 19.3227238 },
    { lat: 48.6815582, lng: 19.3221794 },
    { lat: 48.6811895, lng: 19.3207098 },
    { lat: 48.6811349, lng: 19.3206121 },
    { lat: 48.6810255, lng: 19.3204163 },
    { lat: 48.6809888, lng: 19.3202965 },
    { lat: 48.6809359, lng: 19.3201236 },
    { lat: 48.680971, lng: 19.3195316 },
    { lat: 48.6811191, lng: 19.3191677 },
    { lat: 48.6811333, lng: 19.3186126 },
    { lat: 48.6811964, lng: 19.3182959 },
    { lat: 48.6813468, lng: 19.3175414 },
    { lat: 48.6813572, lng: 19.3174067 },
    { lat: 48.6813852, lng: 19.317045 },
    { lat: 48.6811738, lng: 19.315946 },
    { lat: 48.6812073, lng: 19.3147865 },
    { lat: 48.6812086, lng: 19.3147427 },
    { lat: 48.6811565, lng: 19.314453 },
    { lat: 48.6809335, lng: 19.3138888 },
    { lat: 48.680896, lng: 19.3131985 },
    { lat: 48.6810129, lng: 19.3124178 },
    { lat: 48.6809752, lng: 19.3121234 },
    { lat: 48.680936, lng: 19.3120508 },
    { lat: 48.6805918, lng: 19.3114126 },
    { lat: 48.6804389, lng: 19.3109436 },
    { lat: 48.6804189, lng: 19.3108822 },
    { lat: 48.6804646, lng: 19.3106828 },
    { lat: 48.6806639, lng: 19.3103768 },
    { lat: 48.6809505, lng: 19.3101704 },
    { lat: 48.6823027, lng: 19.3096184 },
    { lat: 48.6831993, lng: 19.3095844 },
    { lat: 48.6836863, lng: 19.3094913 },
    { lat: 48.6845681, lng: 19.3095485 },
    { lat: 48.684883, lng: 19.3093668 },
    { lat: 48.6850432, lng: 19.3092055 },
    { lat: 48.6852503, lng: 19.3087991 },
    { lat: 48.6855288, lng: 19.3078377 },
    { lat: 48.6856763, lng: 19.3075298 },
    { lat: 48.6859964, lng: 19.3070576 },
    { lat: 48.6861083, lng: 19.3067539 },
    { lat: 48.6859902, lng: 19.305798 },
    { lat: 48.6859916, lng: 19.3050038 },
    { lat: 48.6860806, lng: 19.304765 },
    { lat: 48.6858244, lng: 19.303933 },
    { lat: 48.6857415, lng: 19.3033132 },
    { lat: 48.6859273, lng: 19.3022705 },
    { lat: 48.6857238, lng: 19.3011278 },
    { lat: 48.685821, lng: 19.3008307 },
    { lat: 48.6864259, lng: 19.3000479 },
    { lat: 48.6866707, lng: 19.2995328 },
    { lat: 48.6867813, lng: 19.299016 },
    { lat: 48.6867349, lng: 19.2982152 },
    { lat: 48.6869365, lng: 19.2981161 },
    { lat: 48.6873055, lng: 19.2980579 },
    { lat: 48.687455, lng: 19.2979288 },
    { lat: 48.6879354, lng: 19.296855 },
    { lat: 48.6881615, lng: 19.296537 },
    { lat: 48.6884098, lng: 19.2958396 },
    { lat: 48.688981, lng: 19.2949883 },
    { lat: 48.6892188, lng: 19.2948949 },
    { lat: 48.6893113, lng: 19.2946161 },
    { lat: 48.6895728, lng: 19.2944256 },
    { lat: 48.689523, lng: 19.2932006 },
    { lat: 48.6892843, lng: 19.2928283 },
    { lat: 48.6894482, lng: 19.2918043 },
    { lat: 48.6899367, lng: 19.2917719 },
    { lat: 48.6901104, lng: 19.2918667 },
    { lat: 48.6903921, lng: 19.2918401 },
    { lat: 48.6907772, lng: 19.2912667 },
    { lat: 48.6905381, lng: 19.2896223 },
    { lat: 48.6902616, lng: 19.2886497 },
    { lat: 48.6899433, lng: 19.2871666 },
    { lat: 48.6899254, lng: 19.2866966 },
    { lat: 48.6900259, lng: 19.2861623 },
    { lat: 48.6900651, lng: 19.2852194 },
    { lat: 48.6901331, lng: 19.2846632 },
    { lat: 48.6900949, lng: 19.2841983 },
    { lat: 48.6896795, lng: 19.282519 },
    { lat: 48.6895445, lng: 19.2821724 },
    { lat: 48.6898642, lng: 19.2814381 },
    { lat: 48.6899463, lng: 19.2807345 },
    { lat: 48.6898384, lng: 19.2803168 },
    { lat: 48.6892864, lng: 19.2788937 },
    { lat: 48.6891473, lng: 19.2780646 },
    { lat: 48.6890125, lng: 19.2776766 },
    { lat: 48.6888242, lng: 19.2773463 },
  ],
  Brusno: [
    { lat: 48.885361, lng: 19.363558 },
    { lat: 48.886696, lng: 19.359815 },
    { lat: 48.887693, lng: 19.358452 },
    { lat: 48.887982, lng: 19.358367 },
    { lat: 48.889074, lng: 19.35805 },
    { lat: 48.889542, lng: 19.357817 },
    { lat: 48.890656, lng: 19.356318 },
    { lat: 48.891131, lng: 19.355554 },
    { lat: 48.891905, lng: 19.354823 },
    { lat: 48.892517, lng: 19.354061 },
    { lat: 48.894359, lng: 19.352623 },
    { lat: 48.895053, lng: 19.352035 },
    { lat: 48.897577, lng: 19.350434 },
    { lat: 48.897478, lng: 19.3488 },
    { lat: 48.898136, lng: 19.347012 },
    { lat: 48.897796, lng: 19.34596 },
    { lat: 48.897315, lng: 19.342561 },
    { lat: 48.897282, lng: 19.339822 },
    { lat: 48.89719, lng: 19.339113 },
    { lat: 48.896478, lng: 19.337178 },
    { lat: 48.896231, lng: 19.336419 },
    { lat: 48.895454, lng: 19.335764 },
    { lat: 48.894869, lng: 19.334423 },
    { lat: 48.894013, lng: 19.33226 },
    { lat: 48.893473, lng: 19.331241 },
    { lat: 48.89173, lng: 19.33021 },
    { lat: 48.89062, lng: 19.328894 },
    { lat: 48.890258, lng: 19.328042 },
    { lat: 48.889853, lng: 19.327695 },
    { lat: 48.889006, lng: 19.326662 },
    { lat: 48.888646, lng: 19.325841 },
    { lat: 48.887725, lng: 19.3256 },
    { lat: 48.886522, lng: 19.32467 },
    { lat: 48.885763, lng: 19.32425 },
    { lat: 48.884563, lng: 19.323079 },
    { lat: 48.884173, lng: 19.321826 },
    { lat: 48.884092, lng: 19.321739 },
    { lat: 48.8833, lng: 19.321372 },
    { lat: 48.882433, lng: 19.321259 },
    { lat: 48.881412, lng: 19.320531 },
    { lat: 48.880583, lng: 19.319467 },
    { lat: 48.880374, lng: 19.319141 },
    { lat: 48.880047, lng: 19.318932 },
    { lat: 48.879808, lng: 19.318625 },
    { lat: 48.87968, lng: 19.318459 },
    { lat: 48.879515, lng: 19.318373 },
    { lat: 48.879053, lng: 19.318135 },
    { lat: 48.878281, lng: 19.318067 },
    { lat: 48.877739, lng: 19.318169 },
    { lat: 48.877312, lng: 19.318256 },
    { lat: 48.876863, lng: 19.318404 },
    { lat: 48.8748275, lng: 19.3192624 },
    { lat: 48.8665248, lng: 19.323368 },
    { lat: 48.8640123, lng: 19.324095 },
    { lat: 48.8630313, lng: 19.3242014 },
    { lat: 48.8626049, lng: 19.3238861 },
    { lat: 48.8621632, lng: 19.3238653 },
    { lat: 48.8620178, lng: 19.32398 },
    { lat: 48.8617825, lng: 19.3240343 },
    { lat: 48.8610884, lng: 19.3248432 },
    { lat: 48.8608094, lng: 19.3249517 },
    { lat: 48.8601466, lng: 19.3254454 },
    { lat: 48.8591607, lng: 19.3255928 },
    { lat: 48.8582088, lng: 19.326031 },
    { lat: 48.8579997, lng: 19.3265864 },
    { lat: 48.8578453, lng: 19.3271784 },
    { lat: 48.8571764, lng: 19.3272618 },
    { lat: 48.855869, lng: 19.3269998 },
    { lat: 48.8552378, lng: 19.3264545 },
    { lat: 48.8541685, lng: 19.326412 },
    { lat: 48.8522227, lng: 19.3267357 },
    { lat: 48.8496629, lng: 19.3269664 },
    { lat: 48.8486916, lng: 19.3274442 },
    { lat: 48.8481618, lng: 19.3275883 },
    { lat: 48.8470474, lng: 19.3282051 },
    { lat: 48.8468029, lng: 19.3280898 },
    { lat: 48.8456819, lng: 19.329165 },
    { lat: 48.8448135, lng: 19.3303389 },
    { lat: 48.844391, lng: 19.3304634 },
    { lat: 48.8440737, lng: 19.3309724 },
    { lat: 48.8436513, lng: 19.3321138 },
    { lat: 48.8426967, lng: 19.333848 },
    { lat: 48.8416424, lng: 19.3353541 },
    { lat: 48.8411649, lng: 19.3358637 },
    { lat: 48.8408056, lng: 19.3359855 },
    { lat: 48.8394591, lng: 19.337631 },
    { lat: 48.8392801, lng: 19.3379389 },
    { lat: 48.8388983, lng: 19.3382552 },
    { lat: 48.8378267, lng: 19.3395415 },
    { lat: 48.8372707, lng: 19.3400581 },
    { lat: 48.8365639, lng: 19.3402453 },
    { lat: 48.8360386, lng: 19.3400664 },
    { lat: 48.8353347, lng: 19.3398267 },
    { lat: 48.8346518, lng: 19.3399829 },
    { lat: 48.8345109, lng: 19.3400151 },
    { lat: 48.8344245, lng: 19.3400124 },
    { lat: 48.8341028, lng: 19.3400024 },
    { lat: 48.8334914, lng: 19.3399833 },
    { lat: 48.8334564, lng: 19.3399893 },
    { lat: 48.8317932, lng: 19.3402733 },
    { lat: 48.8310522, lng: 19.3408523 },
    { lat: 48.8304256, lng: 19.3410904 },
    { lat: 48.8300706, lng: 19.3415179 },
    { lat: 48.8298995, lng: 19.3416198 },
    { lat: 48.8296225, lng: 19.3422885 },
    { lat: 48.8290466, lng: 19.3424102 },
    { lat: 48.828786, lng: 19.3425723 },
    { lat: 48.8283696, lng: 19.3431048 },
    { lat: 48.8283093, lng: 19.3431674 },
    { lat: 48.827972, lng: 19.3435174 },
    { lat: 48.8278818, lng: 19.343611 },
    { lat: 48.8264214, lng: 19.3435188 },
    { lat: 48.8263709, lng: 19.3433783 },
    { lat: 48.826136, lng: 19.343396 },
    { lat: 48.825976, lng: 19.3434942 },
    { lat: 48.8255775, lng: 19.3433373 },
    { lat: 48.8249675, lng: 19.3434029 },
    { lat: 48.823751, lng: 19.3436769 },
    { lat: 48.8235579, lng: 19.3438352 },
    { lat: 48.8228169, lng: 19.3444426 },
    { lat: 48.8216728, lng: 19.3451525 },
    { lat: 48.8205374, lng: 19.3459723 },
    { lat: 48.8202416, lng: 19.3460094 },
    { lat: 48.8198755, lng: 19.3459201 },
    { lat: 48.8195863, lng: 19.346111 },
    { lat: 48.8189281, lng: 19.3465456 },
    { lat: 48.8186956, lng: 19.3467772 },
    { lat: 48.8182022, lng: 19.346995 },
    { lat: 48.8178363, lng: 19.3474081 },
    { lat: 48.8176556, lng: 19.3474968 },
    { lat: 48.8171232, lng: 19.3480839 },
    { lat: 48.8169889, lng: 19.3486478 },
    { lat: 48.8134556, lng: 19.354933 },
    { lat: 48.8126247, lng: 19.3560691 },
    { lat: 48.811394, lng: 19.3571158 },
    { lat: 48.8098694, lng: 19.3587662 },
    { lat: 48.809911, lng: 19.3588371 },
    { lat: 48.8097033, lng: 19.3593277 },
    { lat: 48.8095254, lng: 19.3595726 },
    { lat: 48.8093988, lng: 19.3597057 },
    { lat: 48.8091693, lng: 19.359737 },
    { lat: 48.8087419, lng: 19.3596382 },
    { lat: 48.8085462, lng: 19.3597649 },
    { lat: 48.8082264, lng: 19.3596758 },
    { lat: 48.8077989, lng: 19.359789 },
    { lat: 48.807556, lng: 19.3599924 },
    { lat: 48.807321, lng: 19.359907 },
    { lat: 48.8062386, lng: 19.3609185 },
    { lat: 48.8048423, lng: 19.3620666 },
    { lat: 48.8040342, lng: 19.3628081 },
    { lat: 48.8033755, lng: 19.3635362 },
    { lat: 48.7997841, lng: 19.3669953 },
    { lat: 48.7974808, lng: 19.3676968 },
    { lat: 48.7959979, lng: 19.368396 },
    { lat: 48.7938032, lng: 19.3675768 },
    { lat: 48.7934382, lng: 19.3676249 },
    { lat: 48.7929031, lng: 19.3673893 },
    { lat: 48.7923785, lng: 19.3673339 },
    { lat: 48.7922378, lng: 19.3667897 },
    { lat: 48.791354, lng: 19.3647797 },
    { lat: 48.7905821, lng: 19.3650481 },
    { lat: 48.7897772, lng: 19.365756 },
    { lat: 48.7883466, lng: 19.3678227 },
    { lat: 48.7871492, lng: 19.3696717 },
    { lat: 48.786935, lng: 19.369078 },
    { lat: 48.7863234, lng: 19.3686542 },
    { lat: 48.7852217, lng: 19.3687858 },
    { lat: 48.784038, lng: 19.3687087 },
    { lat: 48.7826385, lng: 19.3690738 },
    { lat: 48.7824271, lng: 19.3691289 },
    { lat: 48.7811897, lng: 19.3693234 },
    { lat: 48.7808298, lng: 19.3695532 },
    { lat: 48.7796404, lng: 19.3697569 },
    { lat: 48.7792383, lng: 19.3704586 },
    { lat: 48.7788858, lng: 19.3713922 },
    { lat: 48.7784383, lng: 19.3716067 },
    { lat: 48.77796, lng: 19.3722152 },
    { lat: 48.7777457, lng: 19.3726886 },
    { lat: 48.7776371, lng: 19.3733264 },
    { lat: 48.7765484, lng: 19.374307 },
    { lat: 48.7763285, lng: 19.3757636 },
    { lat: 48.7759624, lng: 19.3769126 },
    { lat: 48.7757918, lng: 19.3778583 },
    { lat: 48.7749385, lng: 19.3788841 },
    { lat: 48.7742723, lng: 19.380312 },
    { lat: 48.7737901, lng: 19.382758 },
    { lat: 48.7726181, lng: 19.3834258 },
    { lat: 48.7721606, lng: 19.3840028 },
    { lat: 48.771531, lng: 19.3834365 },
    { lat: 48.7710523, lng: 19.3826285 },
    { lat: 48.7708455, lng: 19.3824307 },
    { lat: 48.7707603, lng: 19.3822456 },
    { lat: 48.7702541, lng: 19.3818393 },
    { lat: 48.7698821, lng: 19.3810712 },
    { lat: 48.7695119, lng: 19.3807739 },
    { lat: 48.769216, lng: 19.3804475 },
    { lat: 48.7689084, lng: 19.3799959 },
    { lat: 48.768675, lng: 19.3797774 },
    { lat: 48.7679728, lng: 19.3784398 },
    { lat: 48.7679298, lng: 19.3779336 },
    { lat: 48.7677912, lng: 19.3774791 },
    { lat: 48.767418, lng: 19.3767627 },
    { lat: 48.7669478, lng: 19.3763265 },
    { lat: 48.765477, lng: 19.3752011 },
    { lat: 48.7651629, lng: 19.3748016 },
    { lat: 48.764872, lng: 19.3742279 },
    { lat: 48.7645982, lng: 19.3733208 },
    { lat: 48.7642869, lng: 19.3725995 },
    { lat: 48.7636435, lng: 19.3722479 },
    { lat: 48.763405, lng: 19.3728031 },
    { lat: 48.7630036, lng: 19.3732766 },
    { lat: 48.7628109, lng: 19.3737571 },
    { lat: 48.7626089, lng: 19.3754458 },
    { lat: 48.7627573, lng: 19.3766526 },
    { lat: 48.7624442, lng: 19.3834254 },
    { lat: 48.7624355, lng: 19.3848896 },
    { lat: 48.763771, lng: 19.3880218 },
    { lat: 48.7636996, lng: 19.3883014 },
    { lat: 48.7635846, lng: 19.3883981 },
    { lat: 48.7628733, lng: 19.3903094 },
    { lat: 48.7627375, lng: 19.3910878 },
    { lat: 48.7627034, lng: 19.3915569 },
    { lat: 48.7626098, lng: 19.3919157 },
    { lat: 48.7623717, lng: 19.392151 },
    { lat: 48.7622844, lng: 19.392499 },
    { lat: 48.7614864, lng: 19.3939113 },
    { lat: 48.7610126, lng: 19.3944273 },
    { lat: 48.7596648, lng: 19.3976647 },
    { lat: 48.7559864, lng: 19.4056117 },
    { lat: 48.7562819, lng: 19.40619 },
    { lat: 48.756482, lng: 19.4078633 },
    { lat: 48.757157, lng: 19.4103601 },
    { lat: 48.7574766, lng: 19.4111691 },
    { lat: 48.7579026, lng: 19.4119835 },
    { lat: 48.7580763, lng: 19.4127557 },
    { lat: 48.7583177, lng: 19.4141535 },
    { lat: 48.7583068, lng: 19.4145958 },
    { lat: 48.7580581, lng: 19.4158308 },
    { lat: 48.7583192, lng: 19.4164737 },
    { lat: 48.7588629, lng: 19.4172762 },
    { lat: 48.7591062, lng: 19.4178513 },
    { lat: 48.7593487, lng: 19.4188648 },
    { lat: 48.7597113, lng: 19.4217214 },
    { lat: 48.7596187, lng: 19.423504 },
    { lat: 48.7596649, lng: 19.424082 },
    { lat: 48.7602968, lng: 19.4268237 },
    { lat: 48.7605021, lng: 19.4286335 },
    { lat: 48.7614513, lng: 19.4306406 },
    { lat: 48.761588, lng: 19.4313736 },
    { lat: 48.761463, lng: 19.4334691 },
    { lat: 48.7615627, lng: 19.4344969 },
    { lat: 48.7620805, lng: 19.4358868 },
    { lat: 48.762264, lng: 19.4365552 },
    { lat: 48.762282, lng: 19.4370877 },
    { lat: 48.7622373, lng: 19.4374111 },
    { lat: 48.7623249, lng: 19.4376932 },
    { lat: 48.7623326, lng: 19.4379038 },
    { lat: 48.762126, lng: 19.4391543 },
    { lat: 48.762236, lng: 19.4408858 },
    { lat: 48.7621332, lng: 19.4413861 },
    { lat: 48.7619024, lng: 19.4419254 },
    { lat: 48.7619509, lng: 19.4423321 },
    { lat: 48.7621526, lng: 19.4430115 },
    { lat: 48.7624497, lng: 19.4437024 },
    { lat: 48.7634716, lng: 19.4453195 },
    { lat: 48.7636941, lng: 19.445813 },
    { lat: 48.7639117, lng: 19.4473141 },
    { lat: 48.764159, lng: 19.4480522 },
    { lat: 48.7639563, lng: 19.4531119 },
    { lat: 48.7639821, lng: 19.4535971 },
    { lat: 48.7639344, lng: 19.4556226 },
    { lat: 48.764081, lng: 19.455962 },
    { lat: 48.76418, lng: 19.455899 },
    { lat: 48.764636, lng: 19.455777 },
    { lat: 48.764825, lng: 19.456057 },
    { lat: 48.765, lng: 19.456123 },
    { lat: 48.765163, lng: 19.456445 },
    { lat: 48.765177, lng: 19.456736 },
    { lat: 48.7652, lng: 19.457032 },
    { lat: 48.76681, lng: 19.459025 },
    { lat: 48.767466, lng: 19.459715 },
    { lat: 48.767632, lng: 19.45982 },
    { lat: 48.767988, lng: 19.459867 },
    { lat: 48.768375, lng: 19.459865 },
    { lat: 48.768805, lng: 19.459979 },
    { lat: 48.769217, lng: 19.460045 },
    { lat: 48.769592, lng: 19.459767 },
    { lat: 48.769881, lng: 19.459161 },
    { lat: 48.77009, lng: 19.458928 },
    { lat: 48.770278, lng: 19.458701 },
    { lat: 48.770767, lng: 19.458627 },
    { lat: 48.770941, lng: 19.458238 },
    { lat: 48.770751, lng: 19.457684 },
    { lat: 48.770324, lng: 19.457181 },
    { lat: 48.770604, lng: 19.456524 },
    { lat: 48.770954, lng: 19.456661 },
    { lat: 48.771276, lng: 19.456967 },
    { lat: 48.771328, lng: 19.456655 },
    { lat: 48.771187, lng: 19.455595 },
    { lat: 48.770758, lng: 19.455118 },
    { lat: 48.770366, lng: 19.454331 },
    { lat: 48.770163, lng: 19.454049 },
    { lat: 48.770119, lng: 19.453868 },
    { lat: 48.770122, lng: 19.453519 },
    { lat: 48.769984, lng: 19.453507 },
    { lat: 48.769824, lng: 19.453028 },
    { lat: 48.769976, lng: 19.45261 },
    { lat: 48.770044, lng: 19.452405 },
    { lat: 48.769774, lng: 19.451813 },
    { lat: 48.770084, lng: 19.451294 },
    { lat: 48.769989, lng: 19.450919 },
    { lat: 48.769849, lng: 19.450168 },
    { lat: 48.7698, lng: 19.449761 },
    { lat: 48.769531, lng: 19.449078 },
    { lat: 48.76913, lng: 19.449263 },
    { lat: 48.769125, lng: 19.448253 },
    { lat: 48.769054, lng: 19.447898 },
    { lat: 48.769573, lng: 19.446258 },
    { lat: 48.769554, lng: 19.445727 },
    { lat: 48.769109, lng: 19.443633 },
    { lat: 48.76914, lng: 19.443125 },
    { lat: 48.769268, lng: 19.442644 },
    { lat: 48.770132, lng: 19.439293 },
    { lat: 48.770621, lng: 19.437761 },
    { lat: 48.770659, lng: 19.437632 },
    { lat: 48.770682, lng: 19.437564 },
    { lat: 48.770851, lng: 19.436817 },
    { lat: 48.770972, lng: 19.436067 },
    { lat: 48.771178, lng: 19.435253 },
    { lat: 48.771321, lng: 19.434734 },
    { lat: 48.771734, lng: 19.434117 },
    { lat: 48.771802, lng: 19.43355 },
    { lat: 48.772022, lng: 19.433431 },
    { lat: 48.77262, lng: 19.432963 },
    { lat: 48.773257, lng: 19.432717 },
    { lat: 48.773323, lng: 19.432704 },
    { lat: 48.773792, lng: 19.4326 },
    { lat: 48.774019, lng: 19.432441 },
    { lat: 48.774674, lng: 19.432256 },
    { lat: 48.774753, lng: 19.432033 },
    { lat: 48.774885, lng: 19.431722 },
    { lat: 48.775726, lng: 19.430589 },
    { lat: 48.776024, lng: 19.430412 },
    { lat: 48.776748, lng: 19.429366 },
    { lat: 48.777303, lng: 19.429203 },
    { lat: 48.777638, lng: 19.428545 },
    { lat: 48.778058, lng: 19.428606 },
    { lat: 48.778638, lng: 19.428626 },
    { lat: 48.779182, lng: 19.42858 },
    { lat: 48.780272, lng: 19.428283 },
    { lat: 48.782156, lng: 19.424725 },
    { lat: 48.782376, lng: 19.424295 },
    { lat: 48.782572, lng: 19.424258 },
    { lat: 48.782914, lng: 19.422457 },
    { lat: 48.783335, lng: 19.422142 },
    { lat: 48.784009, lng: 19.422134 },
    { lat: 48.784543, lng: 19.422248 },
    { lat: 48.784872, lng: 19.422451 },
    { lat: 48.785349, lng: 19.42239 },
    { lat: 48.785606, lng: 19.422238 },
    { lat: 48.785962, lng: 19.422228 },
    { lat: 48.786312, lng: 19.422297 },
    { lat: 48.786735, lng: 19.421529 },
    { lat: 48.786946, lng: 19.420849 },
    { lat: 48.78708, lng: 19.420138 },
    { lat: 48.787276, lng: 19.419505 },
    { lat: 48.787744, lng: 19.418072 },
    { lat: 48.787726, lng: 19.41772 },
    { lat: 48.787664, lng: 19.4169 },
    { lat: 48.787768, lng: 19.416609 },
    { lat: 48.787854, lng: 19.415711 },
    { lat: 48.78767, lng: 19.413649 },
    { lat: 48.787824, lng: 19.413308 },
    { lat: 48.788145, lng: 19.412916 },
    { lat: 48.788318, lng: 19.412588 },
    { lat: 48.788524, lng: 19.411594 },
    { lat: 48.788977, lng: 19.410503 },
    { lat: 48.789141, lng: 19.409387 },
    { lat: 48.789061, lng: 19.409103 },
    { lat: 48.791001, lng: 19.40838 },
    { lat: 48.791545, lng: 19.408085 },
    { lat: 48.791713, lng: 19.407937 },
    { lat: 48.792164, lng: 19.407596 },
    { lat: 48.792602, lng: 19.407263 },
    { lat: 48.792654, lng: 19.40715 },
    { lat: 48.792843, lng: 19.406895 },
    { lat: 48.792974, lng: 19.406827 },
    { lat: 48.793007, lng: 19.40681 },
    { lat: 48.793027, lng: 19.406799 },
    { lat: 48.79331, lng: 19.406918 },
    { lat: 48.793517, lng: 19.407033 },
    { lat: 48.794241, lng: 19.407903 },
    { lat: 48.794291, lng: 19.408014 },
    { lat: 48.794483, lng: 19.408415 },
    { lat: 48.795681, lng: 19.407805 },
    { lat: 48.796104, lng: 19.40723 },
    { lat: 48.797079, lng: 19.405767 },
    { lat: 48.797252, lng: 19.405392 },
    { lat: 48.797339, lng: 19.405307 },
    { lat: 48.797317, lng: 19.404916 },
    { lat: 48.797296, lng: 19.404342 },
    { lat: 48.797245, lng: 19.40404 },
    { lat: 48.797121, lng: 19.403258 },
    { lat: 48.797042, lng: 19.401924 },
    { lat: 48.797242, lng: 19.401791 },
    { lat: 48.797581, lng: 19.401573 },
    { lat: 48.797727, lng: 19.401189 },
    { lat: 48.798231, lng: 19.400548 },
    { lat: 48.797866, lng: 19.399559 },
    { lat: 48.797321, lng: 19.39881 },
    { lat: 48.797113, lng: 19.398578 },
    { lat: 48.797247, lng: 19.398177 },
    { lat: 48.797188, lng: 19.397063 },
    { lat: 48.797054, lng: 19.396156 },
    { lat: 48.796864, lng: 19.39445 },
    { lat: 48.796792, lng: 19.393712 },
    { lat: 48.797242, lng: 19.393422 },
    { lat: 48.797107, lng: 19.392835 },
    { lat: 48.797015, lng: 19.392106 },
    { lat: 48.796744, lng: 19.391153 },
    { lat: 48.796721, lng: 19.391072 },
    { lat: 48.796691, lng: 19.390865 },
    { lat: 48.796474, lng: 19.390166 },
    { lat: 48.798192, lng: 19.386994 },
    { lat: 48.798288, lng: 19.386864 },
    { lat: 48.798303, lng: 19.386842 },
    { lat: 48.798317, lng: 19.386828 },
    { lat: 48.798434, lng: 19.386707 },
    { lat: 48.798437, lng: 19.386717 },
    { lat: 48.798539, lng: 19.386926 },
    { lat: 48.798695, lng: 19.387128 },
    { lat: 48.79904, lng: 19.387577 },
    { lat: 48.799367, lng: 19.387868 },
    { lat: 48.799461, lng: 19.387973 },
    { lat: 48.80007, lng: 19.389118 },
    { lat: 48.80009, lng: 19.389163 },
    { lat: 48.80024, lng: 19.38955 },
    { lat: 48.800414, lng: 19.389941 },
    { lat: 48.800599, lng: 19.390286 },
    { lat: 48.800756, lng: 19.390526 },
    { lat: 48.801725, lng: 19.391844 },
    { lat: 48.801903, lng: 19.39206 },
    { lat: 48.801957, lng: 19.392133 },
    { lat: 48.802186, lng: 19.392305 },
    { lat: 48.802632, lng: 19.392614 },
    { lat: 48.803519, lng: 19.393222 },
    { lat: 48.803642, lng: 19.393457 },
    { lat: 48.80375, lng: 19.39394 },
    { lat: 48.803895, lng: 19.3938912 },
    { lat: 48.803895, lng: 19.393891 },
    { lat: 48.803892, lng: 19.39383 },
    { lat: 48.803887, lng: 19.393655 },
    { lat: 48.803975, lng: 19.393777 },
    { lat: 48.804025, lng: 19.393831 },
    { lat: 48.804146, lng: 19.393917 },
    { lat: 48.80423, lng: 19.393914 },
    { lat: 48.804323, lng: 19.393878 },
    { lat: 48.804333, lng: 19.393864 },
    { lat: 48.804338, lng: 19.393857 },
    { lat: 48.804416, lng: 19.393767 },
    { lat: 48.804423, lng: 19.39376 },
    { lat: 48.804446, lng: 19.393732 },
    { lat: 48.804452, lng: 19.393725 },
    { lat: 48.804502, lng: 19.393667 },
    { lat: 48.804506, lng: 19.393662 },
    { lat: 48.8044665, lng: 19.3936128 },
    { lat: 48.8045254, lng: 19.3934531 },
    { lat: 48.8048998, lng: 19.3928829 },
    { lat: 48.8047498, lng: 19.3925458 },
    { lat: 48.8042355, lng: 19.3924157 },
    { lat: 48.8043019, lng: 19.3899956 },
    { lat: 48.8044465, lng: 19.3897369 },
    { lat: 48.8077653, lng: 19.385686 },
    { lat: 48.809079, lng: 19.3845049 },
    { lat: 48.8100895, lng: 19.3833207 },
    { lat: 48.810187, lng: 19.3834764 },
    { lat: 48.8108595, lng: 19.3825357 },
    { lat: 48.8106478, lng: 19.382113 },
    { lat: 48.8116726, lng: 19.3810909 },
    { lat: 48.813296, lng: 19.38012 },
    { lat: 48.8138069, lng: 19.3796496 },
    { lat: 48.8151723, lng: 19.3790601 },
    { lat: 48.8159283, lng: 19.3786255 },
    { lat: 48.8174339, lng: 19.3775735 },
    { lat: 48.8173363, lng: 19.3773433 },
    { lat: 48.8178219, lng: 19.3769833 },
    { lat: 48.8172068, lng: 19.3749803 },
    { lat: 48.8176453, lng: 19.3751496 },
    { lat: 48.8182703, lng: 19.3750666 },
    { lat: 48.8193843, lng: 19.3744062 },
    { lat: 48.8200102, lng: 19.3741321 },
    { lat: 48.8205261, lng: 19.373669 },
    { lat: 48.8209109, lng: 19.3729153 },
    { lat: 48.821435, lng: 19.3709828 },
    { lat: 48.822127, lng: 19.3701687 },
    { lat: 48.8224784, lng: 19.3698338 },
    { lat: 48.8233982, lng: 19.3695718 },
    { lat: 48.8236682, lng: 19.3693684 },
    { lat: 48.8237179, lng: 19.3690855 },
    { lat: 48.82441, lng: 19.3688983 },
    { lat: 48.8250096, lng: 19.3684925 },
    { lat: 48.825265, lng: 19.3684584 },
    { lat: 48.8259699, lng: 19.3685417 },
    { lat: 48.8272566, lng: 19.3685097 },
    { lat: 48.8282288, lng: 19.3681669 },
    { lat: 48.8297702, lng: 19.3674452 },
    { lat: 48.8311336, lng: 19.3671498 },
    { lat: 48.8318324, lng: 19.3673213 },
    { lat: 48.8321952, lng: 19.3673418 },
    { lat: 48.8329933, lng: 19.3671534 },
    { lat: 48.8335336, lng: 19.3668527 },
    { lat: 48.8347633, lng: 19.3669531 },
    { lat: 48.8367638, lng: 19.3663304 },
    { lat: 48.8372511, lng: 19.3662672 },
    { lat: 48.8381026, lng: 19.3669499 },
    { lat: 48.8390841, lng: 19.3669001 },
    { lat: 48.8403281, lng: 19.3674183 },
    { lat: 48.841569, lng: 19.3675401 },
    { lat: 48.8418748, lng: 19.3673789 },
    { lat: 48.8437489, lng: 19.3674968 },
    { lat: 48.8444658, lng: 19.3677863 },
    { lat: 48.8457818, lng: 19.3676126 },
    { lat: 48.846744, lng: 19.3679639 },
    { lat: 48.8471899, lng: 19.3676952 },
    { lat: 48.8498382, lng: 19.3678832 },
    { lat: 48.8504846, lng: 19.3678239 },
    { lat: 48.8520005, lng: 19.3683035 },
    { lat: 48.8523618, lng: 19.368147 },
    { lat: 48.853177, lng: 19.3681313 },
    { lat: 48.8537744, lng: 19.3681593 },
    { lat: 48.8545859, lng: 19.3684217 },
    { lat: 48.8551394, lng: 19.3681839 },
    { lat: 48.8564138, lng: 19.368567 },
    { lat: 48.8569788, lng: 19.368883 },
    { lat: 48.8577726, lng: 19.3687948 },
    { lat: 48.8578944, lng: 19.3686775 },
    { lat: 48.8579109, lng: 19.3680218 },
    { lat: 48.8581083, lng: 19.3676905 },
    { lat: 48.8588753, lng: 19.3677051 },
    { lat: 48.8591925, lng: 19.3676195 },
    { lat: 48.8595887, lng: 19.3677538 },
    { lat: 48.8598509, lng: 19.3676234 },
    { lat: 48.859935, lng: 19.3674249 },
    { lat: 48.8602861, lng: 19.3670816 },
    { lat: 48.8602446, lng: 19.3665828 },
    { lat: 48.8605002, lng: 19.3663844 },
    { lat: 48.8609027, lng: 19.3657706 },
    { lat: 48.861623, lng: 19.3656048 },
    { lat: 48.8618387, lng: 19.3654984 },
    { lat: 48.8622879, lng: 19.3648172 },
    { lat: 48.8626761, lng: 19.3645407 },
    { lat: 48.8631106, lng: 19.3639542 },
    { lat: 48.8635388, lng: 19.3630007 },
    { lat: 48.8636367, lng: 19.3624718 },
    { lat: 48.8637601, lng: 19.3623392 },
    { lat: 48.8640064, lng: 19.3623046 },
    { lat: 48.864271, lng: 19.3624663 },
    { lat: 48.8645243, lng: 19.3624145 },
    { lat: 48.8646794, lng: 19.3627137 },
    { lat: 48.8648745, lng: 19.3629335 },
    { lat: 48.8652178, lng: 19.3628515 },
    { lat: 48.8661087, lng: 19.3632245 },
    { lat: 48.8676229, lng: 19.3629021 },
    { lat: 48.8679171, lng: 19.3628999 },
    { lat: 48.8703109, lng: 19.3620907 },
    { lat: 48.8720385, lng: 19.3620418 },
    { lat: 48.8742112, lng: 19.3611767 },
    { lat: 48.8757171, lng: 19.3611597 },
    { lat: 48.8781405, lng: 19.3599719 },
    { lat: 48.8818644, lng: 19.3615274 },
    { lat: 48.885361, lng: 19.363558 },
  ],
  Nemce: [
    { lat: 48.7651837, lng: 19.1663016 },
    { lat: 48.7645586, lng: 19.1673704 },
    { lat: 48.7645058, lng: 19.1677919 },
    { lat: 48.7644256, lng: 19.1706162 },
    { lat: 48.7643162, lng: 19.1718594 },
    { lat: 48.7642311, lng: 19.1720068 },
    { lat: 48.7636246, lng: 19.1744942 },
    { lat: 48.7631088, lng: 19.17591 },
    { lat: 48.7627846, lng: 19.1757595 },
    { lat: 48.7625203, lng: 19.1763233 },
    { lat: 48.7623491, lng: 19.1760487 },
    { lat: 48.7622968, lng: 19.1763865 },
    { lat: 48.7623589, lng: 19.1767333 },
    { lat: 48.762279, lng: 19.176943 },
    { lat: 48.7622219, lng: 19.1773421 },
    { lat: 48.7622513, lng: 19.1778994 },
    { lat: 48.7623587, lng: 19.1779985 },
    { lat: 48.7624378, lng: 19.1779793 },
    { lat: 48.7622795, lng: 19.1784518 },
    { lat: 48.7619792, lng: 19.1788356 },
    { lat: 48.7620372, lng: 19.1790506 },
    { lat: 48.7617776, lng: 19.1790697 },
    { lat: 48.7615626, lng: 19.1789826 },
    { lat: 48.7615782, lng: 19.1792781 },
    { lat: 48.7613584, lng: 19.1792799 },
    { lat: 48.7611959, lng: 19.1794592 },
    { lat: 48.7611646, lng: 19.1796496 },
    { lat: 48.7609513, lng: 19.1798288 },
    { lat: 48.7608517, lng: 19.1797528 },
    { lat: 48.7606107, lng: 19.1797791 },
    { lat: 48.7607058, lng: 19.1800203 },
    { lat: 48.7604868, lng: 19.1802177 },
    { lat: 48.7605233, lng: 19.1803394 },
    { lat: 48.7604706, lng: 19.1804839 },
    { lat: 48.7604045, lng: 19.1803802 },
    { lat: 48.7602145, lng: 19.1805762 },
    { lat: 48.7603534, lng: 19.1810648 },
    { lat: 48.7600659, lng: 19.1809951 },
    { lat: 48.7599357, lng: 19.1810522 },
    { lat: 48.7599941, lng: 19.1813915 },
    { lat: 48.7588239, lng: 19.1819911 },
    { lat: 48.7586071, lng: 19.1822147 },
    { lat: 48.7585768, lng: 19.1825357 },
    { lat: 48.7584712, lng: 19.1825886 },
    { lat: 48.7581272, lng: 19.182447 },
    { lat: 48.7579764, lng: 19.1834221 },
    { lat: 48.7580082, lng: 19.1834725 },
    { lat: 48.7579508, lng: 19.1836379 },
    { lat: 48.7577154, lng: 19.1835959 },
    { lat: 48.7576065, lng: 19.1834652 },
    { lat: 48.7575549, lng: 19.183516 },
    { lat: 48.7575944, lng: 19.1837506 },
    { lat: 48.7578169, lng: 19.1839422 },
    { lat: 48.7579393, lng: 19.1843247 },
    { lat: 48.7578642, lng: 19.1844953 },
    { lat: 48.7576945, lng: 19.1845521 },
    { lat: 48.757754, lng: 19.1850103 },
    { lat: 48.7576457, lng: 19.1851682 },
    { lat: 48.7573966, lng: 19.1850974 },
    { lat: 48.7573395, lng: 19.1858472 },
    { lat: 48.7569487, lng: 19.1868465 },
    { lat: 48.7567998, lng: 19.187005 },
    { lat: 48.7564772, lng: 19.1878088 },
    { lat: 48.7563506, lng: 19.1885628 },
    { lat: 48.7563213, lng: 19.1886068 },
    { lat: 48.7560784, lng: 19.1885275 },
    { lat: 48.7560125, lng: 19.188677 },
    { lat: 48.7560748, lng: 19.1888215 },
    { lat: 48.7560273, lng: 19.1894888 },
    { lat: 48.755907, lng: 19.1896576 },
    { lat: 48.7557502, lng: 19.1895848 },
    { lat: 48.755699, lng: 19.1894358 },
    { lat: 48.7556367, lng: 19.1894081 },
    { lat: 48.7556197, lng: 19.189569 },
    { lat: 48.7557433, lng: 19.1898349 },
    { lat: 48.7556752, lng: 19.1898539 },
    { lat: 48.7557859, lng: 19.189997 },
    { lat: 48.7556848, lng: 19.1900254 },
    { lat: 48.7555289, lng: 19.189856 },
    { lat: 48.7552541, lng: 19.1898413 },
    { lat: 48.7551372, lng: 19.1899626 },
    { lat: 48.7548743, lng: 19.1900016 },
    { lat: 48.7547534, lng: 19.1906217 },
    { lat: 48.7547508, lng: 19.1906352 },
    { lat: 48.756197, lng: 19.1912139 },
    { lat: 48.7561224, lng: 19.1909765 },
    { lat: 48.7567696, lng: 19.1910971 },
    { lat: 48.7567601, lng: 19.190996 },
    { lat: 48.7572417, lng: 19.1910422 },
    { lat: 48.7571874, lng: 19.1906468 },
    { lat: 48.7572976, lng: 19.1906452 },
    { lat: 48.757416, lng: 19.1908288 },
    { lat: 48.7574722, lng: 19.1907811 },
    { lat: 48.7575398, lng: 19.1905495 },
    { lat: 48.7576527, lng: 19.1905984 },
    { lat: 48.7576759, lng: 19.1906812 },
    { lat: 48.7576943, lng: 19.1909167 },
    { lat: 48.7576579, lng: 19.1910283 },
    { lat: 48.7577223, lng: 19.191075 },
    { lat: 48.7578945, lng: 19.1910019 },
    { lat: 48.7580373, lng: 19.1911556 },
    { lat: 48.7581846, lng: 19.1910088 },
    { lat: 48.7582049, lng: 19.1909161 },
    { lat: 48.7581452, lng: 19.1907824 },
    { lat: 48.7582428, lng: 19.1906761 },
    { lat: 48.7587822, lng: 19.1907602 },
    { lat: 48.7588343, lng: 19.1906715 },
    { lat: 48.7591219, lng: 19.1906065 },
    { lat: 48.7592323, lng: 19.1905124 },
    { lat: 48.75927, lng: 19.1905432 },
    { lat: 48.7594691, lng: 19.1902151 },
    { lat: 48.7595616, lng: 19.190218 },
    { lat: 48.7597052, lng: 19.1900623 },
    { lat: 48.7598884, lng: 19.1901222 },
    { lat: 48.7599125, lng: 19.1899437 },
    { lat: 48.7602246, lng: 19.1902886 },
    { lat: 48.7603504, lng: 19.1902571 },
    { lat: 48.7603748, lng: 19.1901775 },
    { lat: 48.7605604, lng: 19.1902379 },
    { lat: 48.7608056, lng: 19.1901816 },
    { lat: 48.7607816, lng: 19.1902454 },
    { lat: 48.7609192, lng: 19.1903397 },
    { lat: 48.7612859, lng: 19.1901823 },
    { lat: 48.7614249, lng: 19.1902375 },
    { lat: 48.761515, lng: 19.190028 },
    { lat: 48.761697, lng: 19.189898 },
    { lat: 48.7618547, lng: 19.1899701 },
    { lat: 48.7619448, lng: 19.1899189 },
    { lat: 48.7620335, lng: 19.1896604 },
    { lat: 48.7621021, lng: 19.1897674 },
    { lat: 48.762197, lng: 19.1897968 },
    { lat: 48.7622693, lng: 19.1895929 },
    { lat: 48.7625852, lng: 19.1894844 },
    { lat: 48.7626768, lng: 19.1894874 },
    { lat: 48.762758, lng: 19.1896084 },
    { lat: 48.7628618, lng: 19.1895378 },
    { lat: 48.7629007, lng: 19.1892748 },
    { lat: 48.762857, lng: 19.1889799 },
    { lat: 48.7630429, lng: 19.1887901 },
    { lat: 48.7632808, lng: 19.189232 },
    { lat: 48.7635237, lng: 19.1892674 },
    { lat: 48.7637798, lng: 19.1891316 },
    { lat: 48.7639079, lng: 19.1888489 },
    { lat: 48.7640397, lng: 19.1887274 },
    { lat: 48.7645473, lng: 19.1885048 },
    { lat: 48.7646733, lng: 19.1883625 },
    { lat: 48.7646756, lng: 19.1880164 },
    { lat: 48.7648031, lng: 19.1879714 },
    { lat: 48.7648805, lng: 19.1876408 },
    { lat: 48.7650054, lng: 19.1875918 },
    { lat: 48.7652957, lng: 19.1876505 },
    { lat: 48.7653238, lng: 19.1874183 },
    { lat: 48.7653937, lng: 19.1872779 },
    { lat: 48.7654705, lng: 19.1873072 },
    { lat: 48.7656106, lng: 19.1871722 },
    { lat: 48.7657032, lng: 19.186945 },
    { lat: 48.7658761, lng: 19.1869303 },
    { lat: 48.7659749, lng: 19.1867856 },
    { lat: 48.7660541, lng: 19.1867929 },
    { lat: 48.7660862, lng: 19.1865688 },
    { lat: 48.7660344, lng: 19.1864574 },
    { lat: 48.7660849, lng: 19.1863582 },
    { lat: 48.7661939, lng: 19.1863313 },
    { lat: 48.7663079, lng: 19.1861953 },
    { lat: 48.7664398, lng: 19.1858965 },
    { lat: 48.766442, lng: 19.1857769 },
    { lat: 48.7667223, lng: 19.1856305 },
    { lat: 48.7668792, lng: 19.1856608 },
    { lat: 48.7669486, lng: 19.1855584 },
    { lat: 48.7669362, lng: 19.185305 },
    { lat: 48.7670562, lng: 19.1853573 },
    { lat: 48.7671666, lng: 19.1852685 },
    { lat: 48.7670821, lng: 19.1850361 },
    { lat: 48.7672337, lng: 19.184953 },
    { lat: 48.7672535, lng: 19.1846671 },
    { lat: 48.7673334, lng: 19.1845967 },
    { lat: 48.7673951, lng: 19.184429 },
    { lat: 48.767571, lng: 19.1844075 },
    { lat: 48.7677346, lng: 19.1841788 },
    { lat: 48.7677791, lng: 19.1839823 },
    { lat: 48.7678555, lng: 19.1839308 },
    { lat: 48.7678697, lng: 19.1838052 },
    { lat: 48.7677416, lng: 19.183489 },
    { lat: 48.7678065, lng: 19.1833446 },
    { lat: 48.7679445, lng: 19.1833056 },
    { lat: 48.7680629, lng: 19.1829825 },
    { lat: 48.7680647, lng: 19.1826778 },
    { lat: 48.7682157, lng: 19.1825806 },
    { lat: 48.7683542, lng: 19.1821623 },
    { lat: 48.7683155, lng: 19.182067 },
    { lat: 48.7684516, lng: 19.1818506 },
    { lat: 48.7685064, lng: 19.1818637 },
    { lat: 48.7686317, lng: 19.1817068 },
    { lat: 48.7685542, lng: 19.1814364 },
    { lat: 48.7687834, lng: 19.1805431 },
    { lat: 48.7693119, lng: 19.1797339 },
    { lat: 48.7693275, lng: 19.1796378 },
    { lat: 48.7697722, lng: 19.1792793 },
    { lat: 48.7701976, lng: 19.178364 },
    { lat: 48.7707609, lng: 19.1774176 },
    { lat: 48.771186, lng: 19.1768883 },
    { lat: 48.7718597, lng: 19.1763486 },
    { lat: 48.7720974, lng: 19.1762784 },
    { lat: 48.7725815, lng: 19.1759411 },
    { lat: 48.7730018, lng: 19.1755227 },
    { lat: 48.7733008, lng: 19.1751847 },
    { lat: 48.7736038, lng: 19.1745597 },
    { lat: 48.7753914, lng: 19.1723687 },
    { lat: 48.7756256, lng: 19.1722456 },
    { lat: 48.7765284, lng: 19.1712559 },
    { lat: 48.7772016, lng: 19.170773 },
    { lat: 48.7778924, lng: 19.1700797 },
    { lat: 48.7804935, lng: 19.1686653 },
    { lat: 48.7807148, lng: 19.1684428 },
    { lat: 48.7808993, lng: 19.1683853 },
    { lat: 48.7810449, lng: 19.1681166 },
    { lat: 48.7812857, lng: 19.1678533 },
    { lat: 48.7814247, lng: 19.1675375 },
    { lat: 48.7815435, lng: 19.1674548 },
    { lat: 48.7815745, lng: 19.1672834 },
    { lat: 48.7816347, lng: 19.1672684 },
    { lat: 48.7817145, lng: 19.1670546 },
    { lat: 48.7819442, lng: 19.1669606 },
    { lat: 48.7820127, lng: 19.1666323 },
    { lat: 48.7821257, lng: 19.1664505 },
    { lat: 48.7821849, lng: 19.1664449 },
    { lat: 48.7822051, lng: 19.1663268 },
    { lat: 48.7825237, lng: 19.1659733 },
    { lat: 48.7827217, lng: 19.1656426 },
    { lat: 48.7830569, lng: 19.1653047 },
    { lat: 48.7834817, lng: 19.1650279 },
    { lat: 48.7846274, lng: 19.1645022 },
    { lat: 48.785031, lng: 19.1642081 },
    { lat: 48.7853155, lng: 19.1641029 },
    { lat: 48.7858713, lng: 19.1636894 },
    { lat: 48.7860657, lng: 19.1636733 },
    { lat: 48.786933, lng: 19.1631076 },
    { lat: 48.7873159, lng: 19.1629871 },
    { lat: 48.7880297, lng: 19.1629491 },
    { lat: 48.7882998, lng: 19.1627536 },
    { lat: 48.788531, lng: 19.1628913 },
    { lat: 48.7889538, lng: 19.162896 },
    { lat: 48.7889862, lng: 19.1629607 },
    { lat: 48.7894399, lng: 19.1627185 },
    { lat: 48.7898585, lng: 19.1626114 },
    { lat: 48.7902962, lng: 19.162756 },
    { lat: 48.7904301, lng: 19.162678 },
    { lat: 48.7912261, lng: 19.1627059 },
    { lat: 48.7913903, lng: 19.1626546 },
    { lat: 48.7916356, lng: 19.1627393 },
    { lat: 48.791688, lng: 19.1626322 },
    { lat: 48.7919727, lng: 19.1626674 },
    { lat: 48.7930551, lng: 19.1623356 },
    { lat: 48.7935949, lng: 19.1622407 },
    { lat: 48.7951217, lng: 19.1625962 },
    { lat: 48.7966193, lng: 19.1626779 },
    { lat: 48.7968249, lng: 19.1625003 },
    { lat: 48.7972569, lng: 19.1623209 },
    { lat: 48.7979254, lng: 19.1621629 },
    { lat: 48.7979221, lng: 19.162096 },
    { lat: 48.7981856, lng: 19.1603627 },
    { lat: 48.7982751, lng: 19.1593182 },
    { lat: 48.7981845, lng: 19.1581784 },
    { lat: 48.7979154, lng: 19.1574989 },
    { lat: 48.797688, lng: 19.15762 },
    { lat: 48.7975872, lng: 19.1575387 },
    { lat: 48.7974852, lng: 19.1576649 },
    { lat: 48.7973649, lng: 19.1575093 },
    { lat: 48.797211, lng: 19.1575935 },
    { lat: 48.7971446, lng: 19.1573621 },
    { lat: 48.7968723, lng: 19.1573502 },
    { lat: 48.7968847, lng: 19.1574185 },
    { lat: 48.7967874, lng: 19.1575071 },
    { lat: 48.7966907, lng: 19.1573939 },
    { lat: 48.7965353, lng: 19.1574002 },
    { lat: 48.796508, lng: 19.1573243 },
    { lat: 48.7961545, lng: 19.1574706 },
    { lat: 48.7956827, lng: 19.1575265 },
    { lat: 48.7956547, lng: 19.1573709 },
    { lat: 48.7955027, lng: 19.1572135 },
    { lat: 48.7953261, lng: 19.1573124 },
    { lat: 48.7952998, lng: 19.1574219 },
    { lat: 48.7950444, lng: 19.1572951 },
    { lat: 48.7946738, lng: 19.157252 },
    { lat: 48.7946735, lng: 19.1568316 },
    { lat: 48.794841, lng: 19.1567992 },
    { lat: 48.794839, lng: 19.1566671 },
    { lat: 48.7945761, lng: 19.1565465 },
    { lat: 48.7946178, lng: 19.1562265 },
    { lat: 48.7947757, lng: 19.1559302 },
    { lat: 48.7949028, lng: 19.1559459 },
    { lat: 48.7950149, lng: 19.1551785 },
    { lat: 48.7952074, lng: 19.154686 },
    { lat: 48.7953993, lng: 19.1537498 },
    { lat: 48.7954852, lng: 19.1535764 },
    { lat: 48.7953072, lng: 19.1529575 },
    { lat: 48.7952666, lng: 19.1525198 },
    { lat: 48.7957074, lng: 19.1522231 },
    { lat: 48.7956303, lng: 19.1502868 },
    { lat: 48.7954456, lng: 19.1492782 },
    { lat: 48.7945965, lng: 19.1501686 },
    { lat: 48.7937068, lng: 19.1508501 },
    { lat: 48.7933718, lng: 19.1507105 },
    { lat: 48.793006, lng: 19.1509483 },
    { lat: 48.7931365, lng: 19.1514249 },
    { lat: 48.7931238, lng: 19.1515815 },
    { lat: 48.7927527, lng: 19.1516425 },
    { lat: 48.7924294, lng: 19.1518319 },
    { lat: 48.7916463, lng: 19.1517888 },
    { lat: 48.7903628, lng: 19.150948 },
    { lat: 48.7900631, lng: 19.1504687 },
    { lat: 48.789983, lng: 19.1500544 },
    { lat: 48.7898034, lng: 19.1497987 },
    { lat: 48.7894354, lng: 19.1496932 },
    { lat: 48.7892474, lng: 19.1497658 },
    { lat: 48.7890902, lng: 19.1494603 },
    { lat: 48.7888365, lng: 19.1498503 },
    { lat: 48.7885665, lng: 19.1503655 },
    { lat: 48.7881254, lng: 19.1510225 },
    { lat: 48.7878333, lng: 19.1512734 },
    { lat: 48.7870262, lng: 19.1516399 },
    { lat: 48.7858974, lng: 19.1517916 },
    { lat: 48.7847579, lng: 19.1522336 },
    { lat: 48.7834656, lng: 19.1525431 },
    { lat: 48.7832998, lng: 19.1526572 },
    { lat: 48.7823083, lng: 19.1539491 },
    { lat: 48.7812398, lng: 19.1556999 },
    { lat: 48.780606, lng: 19.1562898 },
    { lat: 48.7805828, lng: 19.156232 },
    { lat: 48.7794227, lng: 19.1572564 },
    { lat: 48.7791676, lng: 19.1574816 },
    { lat: 48.7789249, lng: 19.1575534 },
    { lat: 48.778766, lng: 19.1576004 },
    { lat: 48.7785295, lng: 19.1576702 },
    { lat: 48.7783449, lng: 19.1577248 },
    { lat: 48.7782379, lng: 19.1577565 },
    { lat: 48.7781985, lng: 19.1577681 },
    { lat: 48.7781408, lng: 19.1578174 },
    { lat: 48.7779469, lng: 19.1579834 },
    { lat: 48.7777754, lng: 19.1581301 },
    { lat: 48.7776891, lng: 19.1582039 },
    { lat: 48.7776584, lng: 19.1582254 },
    { lat: 48.7775244, lng: 19.1583193 },
    { lat: 48.7771327, lng: 19.158594 },
    { lat: 48.7766286, lng: 19.1589474 },
    { lat: 48.7766134, lng: 19.1589555 },
    { lat: 48.7759981, lng: 19.1592831 },
    { lat: 48.7761514, lng: 19.159515 },
    { lat: 48.7769267, lng: 19.1594136 },
    { lat: 48.7772046, lng: 19.159292 },
    { lat: 48.7772407, lng: 19.1596564 },
    { lat: 48.7775584, lng: 19.1605282 },
    { lat: 48.7774918, lng: 19.1613745 },
    { lat: 48.7770001, lng: 19.1620141 },
    { lat: 48.7767295, lng: 19.1621506 },
    { lat: 48.775896, lng: 19.1609234 },
    { lat: 48.7758518, lng: 19.161028 },
    { lat: 48.7754714, lng: 19.1612297 },
    { lat: 48.775348, lng: 19.1611299 },
    { lat: 48.7750776, lng: 19.1610628 },
    { lat: 48.774793, lng: 19.1610011 },
    { lat: 48.7745738, lng: 19.1609234 },
    { lat: 48.7743728, lng: 19.1610329 },
    { lat: 48.774197, lng: 19.1612199 },
    { lat: 48.77397, lng: 19.161759 },
    { lat: 48.7739618, lng: 19.1617883 },
    { lat: 48.7738019, lng: 19.16237 },
    { lat: 48.7737268, lng: 19.162623 },
    { lat: 48.7736303, lng: 19.1629485 },
    { lat: 48.7733991, lng: 19.1636139 },
    { lat: 48.7733084, lng: 19.1637406 },
    { lat: 48.773104, lng: 19.1638014 },
    { lat: 48.7725315, lng: 19.1637835 },
    { lat: 48.7722241, lng: 19.1639513 },
    { lat: 48.771997, lng: 19.1639639 },
    { lat: 48.77185, lng: 19.164227 },
    { lat: 48.7718473, lng: 19.1642285 },
    { lat: 48.7717424, lng: 19.164287 },
    { lat: 48.7715965, lng: 19.1642551 },
    { lat: 48.7706759, lng: 19.1636169 },
    { lat: 48.7703678, lng: 19.1634964 },
    { lat: 48.7686349, lng: 19.1653976 },
    { lat: 48.768475, lng: 19.1650325 },
    { lat: 48.7682219, lng: 19.1646303 },
    { lat: 48.7676391, lng: 19.1651106 },
    { lat: 48.7675359, lng: 19.1653519 },
    { lat: 48.7673919, lng: 19.1653592 },
    { lat: 48.7669687, lng: 19.1659687 },
    { lat: 48.7659432, lng: 19.166549 },
    { lat: 48.7651837, lng: 19.1663016 },
  ],
  Donovaly: [
    { lat: 48.8516252, lng: 19.2188485 },
    { lat: 48.851713, lng: 19.2200782 },
    { lat: 48.8519518, lng: 19.2234043 },
    { lat: 48.8525503, lng: 19.225729 },
    { lat: 48.8529504, lng: 19.2265629 },
    { lat: 48.8535943, lng: 19.2273389 },
    { lat: 48.8545535, lng: 19.2293408 },
    { lat: 48.8551426, lng: 19.230987 },
    { lat: 48.8557776, lng: 19.2324195 },
    { lat: 48.8569444, lng: 19.234521 },
    { lat: 48.8572417, lng: 19.2353303 },
    { lat: 48.8575011, lng: 19.2357762 },
    { lat: 48.8579958, lng: 19.2371527 },
    { lat: 48.8585488, lng: 19.2377944 },
    { lat: 48.8600216, lng: 19.2409852 },
    { lat: 48.8602999, lng: 19.2420336 },
    { lat: 48.8606589, lng: 19.2431436 },
    { lat: 48.8609083, lng: 19.2435706 },
    { lat: 48.8609322, lng: 19.2446032 },
    { lat: 48.8605958, lng: 19.245317 },
    { lat: 48.8594003, lng: 19.2462338 },
    { lat: 48.8582242, lng: 19.2465495 },
    { lat: 48.8565404, lng: 19.246716 },
    { lat: 48.8567673, lng: 19.2492033 },
    { lat: 48.85666, lng: 19.2515812 },
    { lat: 48.856112, lng: 19.252941 },
    { lat: 48.856405, lng: 19.253473 },
    { lat: 48.856654, lng: 19.253461 },
    { lat: 48.857006, lng: 19.254125 },
    { lat: 48.857838, lng: 19.255044 },
    { lat: 48.858325, lng: 19.255361 },
    { lat: 48.858886, lng: 19.256194 },
    { lat: 48.85901, lng: 19.256433 },
    { lat: 48.859036, lng: 19.256481 },
    { lat: 48.858903, lng: 19.256663 },
    { lat: 48.858739, lng: 19.257097 },
    { lat: 48.858921, lng: 19.258024 },
    { lat: 48.859, lng: 19.258379 },
    { lat: 48.85934, lng: 19.259904 },
    { lat: 48.859366, lng: 19.260024 },
    { lat: 48.859465, lng: 19.260157 },
    { lat: 48.860009, lng: 19.260463 },
    { lat: 48.860581, lng: 19.260796 },
    { lat: 48.861112, lng: 19.26089 },
    { lat: 48.863751, lng: 19.260782 },
    { lat: 48.864247, lng: 19.261136 },
    { lat: 48.865127, lng: 19.262001 },
    { lat: 48.86437, lng: 19.263252 },
    { lat: 48.863535, lng: 19.264331 },
    { lat: 48.863716, lng: 19.265011 },
    { lat: 48.86389, lng: 19.265171 },
    { lat: 48.864017, lng: 19.265622 },
    { lat: 48.864977, lng: 19.266482 },
    { lat: 48.865504, lng: 19.266441 },
    { lat: 48.865983, lng: 19.266911 },
    { lat: 48.866525, lng: 19.26686 },
    { lat: 48.866826, lng: 19.266748 },
    { lat: 48.867273, lng: 19.266966 },
    { lat: 48.867643, lng: 19.266938 },
    { lat: 48.868713, lng: 19.266632 },
    { lat: 48.868847, lng: 19.266602 },
    { lat: 48.869277, lng: 19.26669 },
    { lat: 48.869769, lng: 19.266534 },
    { lat: 48.870273, lng: 19.266283 },
    { lat: 48.871884, lng: 19.266083 },
    { lat: 48.872959, lng: 19.265563 },
    { lat: 48.874416, lng: 19.265145 },
    { lat: 48.875094, lng: 19.26482 },
    { lat: 48.876057, lng: 19.264543 },
    { lat: 48.877204, lng: 19.264645 },
    { lat: 48.877618, lng: 19.26476 },
    { lat: 48.877634, lng: 19.264715 },
    { lat: 48.877711, lng: 19.264814 },
    { lat: 48.87774, lng: 19.264831 },
    { lat: 48.877766, lng: 19.264855 },
    { lat: 48.877798, lng: 19.264871 },
    { lat: 48.878174, lng: 19.265118 },
    { lat: 48.878745, lng: 19.265296 },
    { lat: 48.878862, lng: 19.265258 },
    { lat: 48.878888, lng: 19.26525 },
    { lat: 48.878983, lng: 19.265243 },
    { lat: 48.8791, lng: 19.26533 },
    { lat: 48.879355, lng: 19.265399 },
    { lat: 48.879439, lng: 19.265344 },
    { lat: 48.879574, lng: 19.265361 },
    { lat: 48.879663, lng: 19.265414 },
    { lat: 48.879782, lng: 19.265458 },
    { lat: 48.879967, lng: 19.265471 },
    { lat: 48.88056, lng: 19.265579 },
    { lat: 48.880581, lng: 19.265585 },
    { lat: 48.880727, lng: 19.265415 },
    { lat: 48.880784, lng: 19.265341 },
    { lat: 48.880911, lng: 19.265329 },
    { lat: 48.881716, lng: 19.265406 },
    { lat: 48.881929, lng: 19.265567 },
    { lat: 48.882884, lng: 19.265413 },
    { lat: 48.883031, lng: 19.265297 },
    { lat: 48.883251, lng: 19.265274 },
    { lat: 48.883306, lng: 19.2653 },
    { lat: 48.883729, lng: 19.265391 },
    { lat: 48.883842, lng: 19.26537 },
    { lat: 48.883917, lng: 19.265323 },
    { lat: 48.883861, lng: 19.265287 },
    { lat: 48.883702, lng: 19.265146 },
    { lat: 48.8836638, lng: 19.2649951 },
    { lat: 48.883594, lng: 19.264719 },
    { lat: 48.883572, lng: 19.264319 },
    { lat: 48.883562, lng: 19.264183 },
    { lat: 48.883523, lng: 19.263599 },
    { lat: 48.883509, lng: 19.2634 },
    { lat: 48.88351, lng: 19.263373 },
    { lat: 48.883584, lng: 19.262886 },
    { lat: 48.883608, lng: 19.262685 },
    { lat: 48.883652, lng: 19.262464 },
    { lat: 48.883726, lng: 19.262275 },
    { lat: 48.883922, lng: 19.261867 },
    { lat: 48.884027, lng: 19.261683 },
    { lat: 48.884121, lng: 19.261532 },
    { lat: 48.884228, lng: 19.261034 },
    { lat: 48.884542, lng: 19.260037 },
    { lat: 48.88466, lng: 19.259512 },
    { lat: 48.8847161, lng: 19.2590475 },
    { lat: 48.884772, lng: 19.257052 },
    { lat: 48.884686, lng: 19.256576 },
    { lat: 48.884616, lng: 19.25619 },
    { lat: 48.884569, lng: 19.255658 },
    { lat: 48.884655, lng: 19.25519 },
    { lat: 48.884714, lng: 19.254913 },
    { lat: 48.884781, lng: 19.254764 },
    { lat: 48.884826, lng: 19.254569 },
    { lat: 48.884855, lng: 19.254465 },
    { lat: 48.884896, lng: 19.254475 },
    { lat: 48.884937, lng: 19.254486 },
    { lat: 48.885039, lng: 19.25452 },
    { lat: 48.885115, lng: 19.254545 },
    { lat: 48.885191, lng: 19.25458 },
    { lat: 48.885238, lng: 19.254588 },
    { lat: 48.885386, lng: 19.254623 },
    { lat: 48.885604, lng: 19.254623 },
    { lat: 48.88577, lng: 19.254618 },
    { lat: 48.885953, lng: 19.254601 },
    { lat: 48.88606, lng: 19.254603 },
    { lat: 48.886234, lng: 19.254645 },
    { lat: 48.886441, lng: 19.254682 },
    { lat: 48.886701, lng: 19.25475 },
    { lat: 48.886929, lng: 19.254815 },
    { lat: 48.88717, lng: 19.254866 },
    { lat: 48.887291, lng: 19.254887 },
    { lat: 48.887404, lng: 19.254897 },
    { lat: 48.887635, lng: 19.254876 },
    { lat: 48.887849, lng: 19.254846 },
    { lat: 48.888057, lng: 19.25481 },
    { lat: 48.888154, lng: 19.254806 },
    { lat: 48.888334, lng: 19.254772 },
    { lat: 48.888399, lng: 19.254733 },
    { lat: 48.888474, lng: 19.254687 },
    { lat: 48.88855, lng: 19.254596 },
    { lat: 48.888578, lng: 19.254505 },
    { lat: 48.888599, lng: 19.254449 },
    { lat: 48.888671, lng: 19.254403 },
    { lat: 48.888857, lng: 19.25428 },
    { lat: 48.889356, lng: 19.254052 },
    { lat: 48.889802, lng: 19.253666 },
    { lat: 48.890028, lng: 19.253524 },
    { lat: 48.890346, lng: 19.253344 },
    { lat: 48.890607, lng: 19.253274 },
    { lat: 48.890768, lng: 19.253184 },
    { lat: 48.890922, lng: 19.253068 },
    { lat: 48.891123, lng: 19.252991 },
    { lat: 48.891285, lng: 19.252887 },
    { lat: 48.891437, lng: 19.252819 },
    { lat: 48.891532, lng: 19.252783 },
    { lat: 48.891648, lng: 19.25264 },
    { lat: 48.891725, lng: 19.252457 },
    { lat: 48.891811, lng: 19.252256 },
    { lat: 48.891895, lng: 19.252149 },
    { lat: 48.892018, lng: 19.25207 },
    { lat: 48.892198, lng: 19.251951 },
    { lat: 48.892305, lng: 19.251862 },
    { lat: 48.892424, lng: 19.251712 },
    { lat: 48.892535, lng: 19.251553 },
    { lat: 48.892655, lng: 19.251258 },
    { lat: 48.892728, lng: 19.251107 },
    { lat: 48.892892, lng: 19.250681 },
    { lat: 48.893147, lng: 19.250059 },
    { lat: 48.893205, lng: 19.24994 },
    { lat: 48.893357, lng: 19.24968 },
    { lat: 48.893495, lng: 19.249425 },
    { lat: 48.893581, lng: 19.249231 },
    { lat: 48.893712, lng: 19.248957 },
    { lat: 48.893845, lng: 19.248587 },
    { lat: 48.893926, lng: 19.248342 },
    { lat: 48.89399, lng: 19.248153 },
    { lat: 48.894089, lng: 19.24792 },
    { lat: 48.894163, lng: 19.24775 },
    { lat: 48.894245, lng: 19.247549 },
    { lat: 48.894314, lng: 19.24729 },
    { lat: 48.894344, lng: 19.247063 },
    { lat: 48.894422, lng: 19.246728 },
    { lat: 48.894492, lng: 19.246538 },
    { lat: 48.894593, lng: 19.246209 },
    { lat: 48.8948, lng: 19.245699 },
    { lat: 48.894875, lng: 19.245479 },
    { lat: 48.894938, lng: 19.245308 },
    { lat: 48.895001, lng: 19.245053 },
    { lat: 48.89502, lng: 19.244948 },
    { lat: 48.895104, lng: 19.244543 },
    { lat: 48.895128, lng: 19.244366 },
    { lat: 48.89516, lng: 19.244051 },
    { lat: 48.895177, lng: 19.243839 },
    { lat: 48.895172, lng: 19.243578 },
    { lat: 48.895151, lng: 19.243018 },
    { lat: 48.895104, lng: 19.242527 },
    { lat: 48.895094, lng: 19.242275 },
    { lat: 48.895077, lng: 19.242059 },
    { lat: 48.895035, lng: 19.241858 },
    { lat: 48.895004, lng: 19.241791 },
    { lat: 48.894857, lng: 19.241442 },
    { lat: 48.894633, lng: 19.240605 },
    { lat: 48.894529, lng: 19.239692 },
    { lat: 48.894431, lng: 19.239067 },
    { lat: 48.894378, lng: 19.238059 },
    { lat: 48.894375, lng: 19.237052 },
    { lat: 48.894456, lng: 19.236512 },
    { lat: 48.894508, lng: 19.235929 },
    { lat: 48.89447, lng: 19.235423 },
    { lat: 48.894414, lng: 19.234754 },
    { lat: 48.894343, lng: 19.234357 },
    { lat: 48.894306, lng: 19.234007 },
    { lat: 48.894219, lng: 19.233574 },
    { lat: 48.894123, lng: 19.233115 },
    { lat: 48.894031, lng: 19.232886 },
    { lat: 48.893869, lng: 19.232432 },
    { lat: 48.893706, lng: 19.232041 },
    { lat: 48.893506, lng: 19.231545 },
    { lat: 48.893414, lng: 19.231522 },
    { lat: 48.893289, lng: 19.231515 },
    { lat: 48.893162, lng: 19.231516 },
    { lat: 48.89292, lng: 19.23147 },
    { lat: 48.892798, lng: 19.231406 },
    { lat: 48.892752, lng: 19.231359 },
    { lat: 48.892036, lng: 19.231357 },
    { lat: 48.891111, lng: 19.23245 },
    { lat: 48.890542, lng: 19.232153 },
    { lat: 48.888851, lng: 19.231055 },
    { lat: 48.88882, lng: 19.231034 },
    { lat: 48.888801, lng: 19.231022 },
    { lat: 48.888657, lng: 19.230929 },
    { lat: 48.888698, lng: 19.230497 },
    { lat: 48.888936, lng: 19.229578 },
    { lat: 48.889001, lng: 19.229328 },
    { lat: 48.889148, lng: 19.228784 },
    { lat: 48.889447, lng: 19.228221 },
    { lat: 48.890195, lng: 19.225311 },
    { lat: 48.890334, lng: 19.224154 },
    { lat: 48.8905939, lng: 19.2232653 },
    { lat: 48.890819, lng: 19.222733 },
    { lat: 48.8908, lng: 19.218276 },
    { lat: 48.890834, lng: 19.215723 },
    { lat: 48.890965, lng: 19.214798 },
    { lat: 48.891339, lng: 19.21329 },
    { lat: 48.89146, lng: 19.212799 },
    { lat: 48.891004, lng: 19.211912 },
    { lat: 48.889702, lng: 19.209643 },
    { lat: 48.888836, lng: 19.208317 },
    { lat: 48.888713, lng: 19.208002 },
    { lat: 48.888701, lng: 19.207714 },
    { lat: 48.888544, lng: 19.207132 },
    { lat: 48.887735, lng: 19.205811 },
    { lat: 48.887392, lng: 19.205376 },
    { lat: 48.886983, lng: 19.204857 },
    { lat: 48.88686, lng: 19.204574 },
    { lat: 48.88705, lng: 19.204069 },
    { lat: 48.887227, lng: 19.203784 },
    { lat: 48.88724, lng: 19.20225 },
    { lat: 48.887248, lng: 19.201228 },
    { lat: 48.887185, lng: 19.201127 },
    { lat: 48.887049, lng: 19.200907 },
    { lat: 48.887043, lng: 19.200847 },
    { lat: 48.886954, lng: 19.199911 },
    { lat: 48.886369, lng: 19.197443 },
    { lat: 48.886097, lng: 19.196645 },
    { lat: 48.885563, lng: 19.19563 },
    { lat: 48.88553, lng: 19.19522 },
    { lat: 48.885513, lng: 19.195017 },
    { lat: 48.8850957, lng: 19.1950387 },
    { lat: 48.8794523, lng: 19.1979651 },
    { lat: 48.877439, lng: 19.1985633 },
    { lat: 48.8759165, lng: 19.1991277 },
    { lat: 48.8750627, lng: 19.199212 },
    { lat: 48.8741223, lng: 19.1994829 },
    { lat: 48.8737281, lng: 19.1993716 },
    { lat: 48.8734593, lng: 19.199619 },
    { lat: 48.8731605, lng: 19.2006818 },
    { lat: 48.8727124, lng: 19.2012948 },
    { lat: 48.8726187, lng: 19.201664 },
    { lat: 48.872421, lng: 19.2020735 },
    { lat: 48.87229, lng: 19.2019292 },
    { lat: 48.8700288, lng: 19.205686 },
    { lat: 48.8692962, lng: 19.2066855 },
    { lat: 48.8690167, lng: 19.2067514 },
    { lat: 48.8688562, lng: 19.2071875 },
    { lat: 48.8689106, lng: 19.207378 },
    { lat: 48.8688417, lng: 19.2076513 },
    { lat: 48.8685211, lng: 19.2081991 },
    { lat: 48.8682627, lng: 19.2081033 },
    { lat: 48.8680516, lng: 19.2076754 },
    { lat: 48.867911, lng: 19.2076603 },
    { lat: 48.8675314, lng: 19.2080145 },
    { lat: 48.8672814, lng: 19.2088795 },
    { lat: 48.8671574, lng: 19.208922 },
    { lat: 48.8670598, lng: 19.2087946 },
    { lat: 48.8666398, lng: 19.2088472 },
    { lat: 48.8660783, lng: 19.209197 },
    { lat: 48.8659043, lng: 19.2095343 },
    { lat: 48.8656776, lng: 19.2096029 },
    { lat: 48.8655832, lng: 19.2095365 },
    { lat: 48.865252, lng: 19.2095895 },
    { lat: 48.8649666, lng: 19.2094427 },
    { lat: 48.863602, lng: 19.210404 },
    { lat: 48.8633069, lng: 19.2106896 },
    { lat: 48.8630582, lng: 19.2106501 },
    { lat: 48.862634, lng: 19.2103634 },
    { lat: 48.862329, lng: 19.209719 },
    { lat: 48.8620971, lng: 19.2094971 },
    { lat: 48.8620925, lng: 19.2091443 },
    { lat: 48.8601841, lng: 19.2084697 },
    { lat: 48.8560036, lng: 19.2058285 },
    { lat: 48.8531988, lng: 19.2058877 },
    { lat: 48.8515245, lng: 19.2058111 },
    { lat: 48.8508275, lng: 19.2058022 },
    { lat: 48.8507166, lng: 19.206718 },
    { lat: 48.8506584, lng: 19.2075198 },
    { lat: 48.8504619, lng: 19.2087894 },
    { lat: 48.8516491, lng: 19.2106295 },
    { lat: 48.8519109, lng: 19.2136283 },
    { lat: 48.8516433, lng: 19.2142 },
    { lat: 48.8515475, lng: 19.2155264 },
    { lat: 48.8515995, lng: 19.215531 },
    { lat: 48.8516252, lng: 19.2188485 },
  ],
  Môlča: [
    { lat: 48.6947064, lng: 19.2407044 },
    { lat: 48.6949791, lng: 19.2416333 },
    { lat: 48.6959819, lng: 19.2435138 },
    { lat: 48.6966198, lng: 19.2453969 },
    { lat: 48.6973117, lng: 19.2457208 },
    { lat: 48.6975634, lng: 19.2460745 },
    { lat: 48.698092, lng: 19.2473581 },
    { lat: 48.6989497, lng: 19.2481005 },
    { lat: 48.6989188, lng: 19.2484871 },
    { lat: 48.6998752, lng: 19.2494317 },
    { lat: 48.6999542, lng: 19.2510658 },
    { lat: 48.7000057, lng: 19.2510794 },
    { lat: 48.7005956, lng: 19.252093 },
    { lat: 48.7007769, lng: 19.2527846 },
    { lat: 48.7007767, lng: 19.2530404 },
    { lat: 48.7008394, lng: 19.2533361 },
    { lat: 48.701035, lng: 19.2536061 },
    { lat: 48.7012847, lng: 19.2538251 },
    { lat: 48.702389, lng: 19.2535381 },
    { lat: 48.7026092, lng: 19.253638 },
    { lat: 48.7027569, lng: 19.2538983 },
    { lat: 48.7032202, lng: 19.2543892 },
    { lat: 48.7042326, lng: 19.2550895 },
    { lat: 48.7048042, lng: 19.2547677 },
    { lat: 48.705101, lng: 19.2544274 },
    { lat: 48.7054056, lng: 19.2542703 },
    { lat: 48.7067029, lng: 19.254427 },
    { lat: 48.706978, lng: 19.2547785 },
    { lat: 48.7079981, lng: 19.25511 },
    { lat: 48.7094562, lng: 19.2550042 },
    { lat: 48.7097729, lng: 19.2548661 },
    { lat: 48.7100101, lng: 19.2545684 },
    { lat: 48.710059, lng: 19.2539288 },
    { lat: 48.7100231, lng: 19.2524127 },
    { lat: 48.7102695, lng: 19.2518663 },
    { lat: 48.7103884, lng: 19.2513214 },
    { lat: 48.7105145, lng: 19.2511301 },
    { lat: 48.7106583, lng: 19.251051 },
    { lat: 48.71112, lng: 19.25104 },
    { lat: 48.7114659, lng: 19.2505222 },
    { lat: 48.7118995, lng: 19.2504483 },
    { lat: 48.7121797, lng: 19.2502261 },
    { lat: 48.7126665, lng: 19.2493853 },
    { lat: 48.7132228, lng: 19.2487707 },
    { lat: 48.7138253, lng: 19.2483158 },
    { lat: 48.7143819, lng: 19.2481466 },
    { lat: 48.7150581, lng: 19.2488882 },
    { lat: 48.7157717, lng: 19.2501487 },
    { lat: 48.7161796, lng: 19.2502868 },
    { lat: 48.7163644, lng: 19.2502143 },
    { lat: 48.7167579, lng: 19.2497297 },
    { lat: 48.7169499, lng: 19.249853 },
    { lat: 48.7177406, lng: 19.2498657 },
    { lat: 48.7177546, lng: 19.2494993 },
    { lat: 48.7179826, lng: 19.2494613 },
    { lat: 48.7189363, lng: 19.2496744 },
    { lat: 48.7203703, lng: 19.2479751 },
    { lat: 48.7207209, lng: 19.2474405 },
    { lat: 48.7208904, lng: 19.2470338 },
    { lat: 48.7211698, lng: 19.2468169 },
    { lat: 48.7224897, lng: 19.2450451 },
    { lat: 48.7235751, lng: 19.2439284 },
    { lat: 48.7241379, lng: 19.2430919 },
    { lat: 48.7243985, lng: 19.2423762 },
    { lat: 48.724532, lng: 19.2423182 },
    { lat: 48.7248056, lng: 19.242404 },
    { lat: 48.724804, lng: 19.2424195 },
    { lat: 48.725196, lng: 19.2421338 },
    { lat: 48.7256386, lng: 19.2413562 },
    { lat: 48.7261318, lng: 19.2406926 },
    { lat: 48.7263267, lng: 19.2407378 },
    { lat: 48.7267731, lng: 19.2404839 },
    { lat: 48.7270417, lng: 19.2399772 },
    { lat: 48.7270316, lng: 19.2390036 },
    { lat: 48.7272982, lng: 19.2381048 },
    { lat: 48.7273772, lng: 19.2380139 },
    { lat: 48.7271538, lng: 19.2376395 },
    { lat: 48.7269709, lng: 19.237096 },
    { lat: 48.7266063, lng: 19.2362684 },
    { lat: 48.7262335, lng: 19.234883 },
    { lat: 48.7261763, lng: 19.234215 },
    { lat: 48.7261825, lng: 19.2337127 },
    { lat: 48.7262635, lng: 19.2330111 },
    { lat: 48.7268909, lng: 19.2311169 },
    { lat: 48.7277137, lng: 19.230307 },
    { lat: 48.7277591, lng: 19.2298443 },
    { lat: 48.728094, lng: 19.2286843 },
    { lat: 48.7284482, lng: 19.2275036 },
    { lat: 48.7287059, lng: 19.226963 },
    { lat: 48.7288536, lng: 19.2255481 },
    { lat: 48.7288165, lng: 19.2251049 },
    { lat: 48.7283703, lng: 19.2236937 },
    { lat: 48.728546, lng: 19.2230669 },
    { lat: 48.7284358, lng: 19.2223537 },
    { lat: 48.7288469, lng: 19.2205311 },
    { lat: 48.7287321, lng: 19.2185865 },
    { lat: 48.7288644, lng: 19.2180191 },
    { lat: 48.7287763, lng: 19.2172263 },
    { lat: 48.7282755, lng: 19.2161283 },
    { lat: 48.728079, lng: 19.2154725 },
    { lat: 48.7281214, lng: 19.2140714 },
    { lat: 48.7282627, lng: 19.2133873 },
    { lat: 48.7285217, lng: 19.2128265 },
    { lat: 48.728635, lng: 19.212699 },
    { lat: 48.7282944, lng: 19.2115822 },
    { lat: 48.728662, lng: 19.210934 },
    { lat: 48.7286866, lng: 19.2104189 },
    { lat: 48.7289979, lng: 19.2093845 },
    { lat: 48.7291979, lng: 19.2093452 },
    { lat: 48.7296491, lng: 19.208749 },
    { lat: 48.729366, lng: 19.2083771 },
    { lat: 48.728974, lng: 19.2081957 },
    { lat: 48.7283442, lng: 19.2075774 },
    { lat: 48.7281144, lng: 19.2071421 },
    { lat: 48.7277659, lng: 19.2068595 },
    { lat: 48.7268802, lng: 19.2067727 },
    { lat: 48.7259249, lng: 19.2058851 },
    { lat: 48.7254931, lng: 19.2056279 },
    { lat: 48.7251804, lng: 19.2048765 },
    { lat: 48.7245217, lng: 19.2038988 },
    { lat: 48.723955, lng: 19.2034863 },
    { lat: 48.7227599, lng: 19.2030903 },
    { lat: 48.722288, lng: 19.2024531 },
    { lat: 48.7224258, lng: 19.2012279 },
    { lat: 48.7221884, lng: 19.2005018 },
    { lat: 48.7222429, lng: 19.1998606 },
    { lat: 48.7224813, lng: 19.1987859 },
    { lat: 48.723117, lng: 19.1968886 },
    { lat: 48.7234995, lng: 19.1965953 },
    { lat: 48.7236816, lng: 19.1958692 },
    { lat: 48.7238367, lng: 19.1955149 },
    { lat: 48.7240665, lng: 19.1945407 },
    { lat: 48.7237049, lng: 19.1948456 },
    { lat: 48.7223032, lng: 19.1945795 },
    { lat: 48.7220742, lng: 19.1948774 },
    { lat: 48.7214015, lng: 19.1969834 },
    { lat: 48.7210646, lng: 19.1991804 },
    { lat: 48.720259, lng: 19.2021285 },
    { lat: 48.719996, lng: 19.2027418 },
    { lat: 48.7196558, lng: 19.203835 },
    { lat: 48.7193286, lng: 19.2046842 },
    { lat: 48.7188509, lng: 19.2054288 },
    { lat: 48.7180555, lng: 19.2056694 },
    { lat: 48.7180308, lng: 19.2055358 },
    { lat: 48.7176223, lng: 19.2054984 },
    { lat: 48.7173435, lng: 19.2057693 },
    { lat: 48.7167633, lng: 19.2066124 },
    { lat: 48.7162635, lng: 19.2079898 },
    { lat: 48.7159938, lng: 19.207801 },
    { lat: 48.7156054, lng: 19.2077168 },
    { lat: 48.7155068, lng: 19.2075882 },
    { lat: 48.7155639, lng: 19.2082086 },
    { lat: 48.7154276, lng: 19.2091097 },
    { lat: 48.7151296, lng: 19.2096604 },
    { lat: 48.7143266, lng: 19.2100224 },
    { lat: 48.7143659, lng: 19.2106448 },
    { lat: 48.7144424, lng: 19.2107316 },
    { lat: 48.7144543, lng: 19.2109755 },
    { lat: 48.7142846, lng: 19.2110083 },
    { lat: 48.7138992, lng: 19.2108737 },
    { lat: 48.7136912, lng: 19.2110468 },
    { lat: 48.7137021, lng: 19.2114546 },
    { lat: 48.7132368, lng: 19.2119015 },
    { lat: 48.7128793, lng: 19.2123363 },
    { lat: 48.7130417, lng: 19.2125224 },
    { lat: 48.7131328, lng: 19.2130984 },
    { lat: 48.7128004, lng: 19.2133123 },
    { lat: 48.7128374, lng: 19.2135128 },
    { lat: 48.7127426, lng: 19.213605 },
    { lat: 48.7129535, lng: 19.2136769 },
    { lat: 48.7131146, lng: 19.2138364 },
    { lat: 48.7136638, lng: 19.214007 },
    { lat: 48.7136865, lng: 19.2140998 },
    { lat: 48.7133515, lng: 19.2142595 },
    { lat: 48.7130317, lng: 19.2145405 },
    { lat: 48.7126053, lng: 19.2144923 },
    { lat: 48.7124938, lng: 19.2144229 },
    { lat: 48.7123915, lng: 19.2141755 },
    { lat: 48.7115568, lng: 19.2142546 },
    { lat: 48.7115931, lng: 19.2146322 },
    { lat: 48.7117037, lng: 19.2148651 },
    { lat: 48.7110443, lng: 19.215211 },
    { lat: 48.7102752, lng: 19.2160866 },
    { lat: 48.7102541, lng: 19.2160208 },
    { lat: 48.7100575, lng: 19.2162471 },
    { lat: 48.7096887, lng: 19.2168192 },
    { lat: 48.7095207, lng: 19.2172604 },
    { lat: 48.7098418, lng: 19.2173746 },
    { lat: 48.7099108, lng: 19.2171218 },
    { lat: 48.7100937, lng: 19.2169923 },
    { lat: 48.7102167, lng: 19.217292 },
    { lat: 48.7100107, lng: 19.2180644 },
    { lat: 48.7099415, lng: 19.2181266 },
    { lat: 48.7099055, lng: 19.2184986 },
    { lat: 48.7096984, lng: 19.2188756 },
    { lat: 48.7097466, lng: 19.2189385 },
    { lat: 48.7093119, lng: 19.2196409 },
    { lat: 48.7092, lng: 19.2199392 },
    { lat: 48.709199, lng: 19.2201028 },
    { lat: 48.7092574, lng: 19.2201919 },
    { lat: 48.7093445, lng: 19.2206862 },
    { lat: 48.7093065, lng: 19.2210177 },
    { lat: 48.7091669, lng: 19.2211149 },
    { lat: 48.7088353, lng: 19.2207838 },
    { lat: 48.7086347, lng: 19.2206672 },
    { lat: 48.7084112, lng: 19.2202244 },
    { lat: 48.7083562, lng: 19.2202723 },
    { lat: 48.7081938, lng: 19.2201097 },
    { lat: 48.7080278, lng: 19.2200579 },
    { lat: 48.7079221, lng: 19.2201335 },
    { lat: 48.7078357, lng: 19.2199792 },
    { lat: 48.7071942, lng: 19.2206946 },
    { lat: 48.7069783, lng: 19.2203085 },
    { lat: 48.7053497, lng: 19.2224242 },
    { lat: 48.7052513, lng: 19.2224904 },
    { lat: 48.7045223, lng: 19.2209632 },
    { lat: 48.7045056, lng: 19.2212205 },
    { lat: 48.7042876, lng: 19.2217718 },
    { lat: 48.7040875, lng: 19.2215945 },
    { lat: 48.7035826, lng: 19.2220838 },
    { lat: 48.7031039, lng: 19.2229262 },
    { lat: 48.7029154, lng: 19.2231545 },
    { lat: 48.7017669, lng: 19.2236929 },
    { lat: 48.7016908, lng: 19.2235183 },
    { lat: 48.7004482, lng: 19.2244143 },
    { lat: 48.7001063, lng: 19.2248248 },
    { lat: 48.6999976, lng: 19.2245117 },
    { lat: 48.7001604, lng: 19.2242228 },
    { lat: 48.6999888, lng: 19.2239571 },
    { lat: 48.6981128, lng: 19.2258768 },
    { lat: 48.6978923, lng: 19.2266519 },
    { lat: 48.6981831, lng: 19.2271635 },
    { lat: 48.6981213, lng: 19.2273563 },
    { lat: 48.6981509, lng: 19.2278243 },
    { lat: 48.6979392, lng: 19.2289657 },
    { lat: 48.6979496, lng: 19.2296925 },
    { lat: 48.697903, lng: 19.2304737 },
    { lat: 48.6982161, lng: 19.2310025 },
    { lat: 48.6991026, lng: 19.2333607 },
    { lat: 48.6982979, lng: 19.2340628 },
    { lat: 48.6977225, lng: 19.2341896 },
    { lat: 48.6977864, lng: 19.2348551 },
    { lat: 48.6962743, lng: 19.2349132 },
    { lat: 48.695396, lng: 19.2345369 },
    { lat: 48.6950313, lng: 19.2346444 },
    { lat: 48.6947465, lng: 19.2339287 },
    { lat: 48.6944975, lng: 19.2325359 },
    { lat: 48.693976, lng: 19.2325212 },
    { lat: 48.6937346, lng: 19.2322389 },
    { lat: 48.6936032, lng: 19.232667 },
    { lat: 48.6928804, lng: 19.2327538 },
    { lat: 48.6928993, lng: 19.2334453 },
    { lat: 48.6922422, lng: 19.2337046 },
    { lat: 48.6922504, lng: 19.2334107 },
    { lat: 48.6914976, lng: 19.2329471 },
    { lat: 48.691062, lng: 19.2326353 },
    { lat: 48.6908521, lng: 19.2336746 },
    { lat: 48.6913982, lng: 19.233941 },
    { lat: 48.6918038, lng: 19.233986 },
    { lat: 48.6916927, lng: 19.234968 },
    { lat: 48.6914973, lng: 19.2349625 },
    { lat: 48.6912571, lng: 19.2357027 },
    { lat: 48.6909241, lng: 19.2363471 },
    { lat: 48.6913437, lng: 19.2361886 },
    { lat: 48.6918116, lng: 19.2366806 },
    { lat: 48.6916453, lng: 19.237967 },
    { lat: 48.6914659, lng: 19.2391527 },
    { lat: 48.6915364, lng: 19.2391899 },
    { lat: 48.691886, lng: 19.239376 },
    { lat: 48.6923965, lng: 19.2393278 },
    { lat: 48.6931083, lng: 19.2395024 },
    { lat: 48.6933599, lng: 19.239156 },
    { lat: 48.6937272, lng: 19.2396684 },
    { lat: 48.6945073, lng: 19.2398762 },
    { lat: 48.6947064, lng: 19.2407044 },
  ],
  Malachov: [
    { lat: 48.715066, lng: 19.110382 },
    { lat: 48.7151307, lng: 19.1102284 },
    { lat: 48.7151186, lng: 19.1101469 },
    { lat: 48.7152642, lng: 19.1099434 },
    { lat: 48.7159928, lng: 19.1070195 },
    { lat: 48.7167469, lng: 19.1046714 },
    { lat: 48.7169487, lng: 19.1041066 },
    { lat: 48.717213, lng: 19.1040239 },
    { lat: 48.7171401, lng: 19.1031032 },
    { lat: 48.7173271, lng: 19.1017578 },
    { lat: 48.7172969, lng: 19.1012411 },
    { lat: 48.7171183, lng: 19.1002549 },
    { lat: 48.7170675, lng: 19.0994139 },
    { lat: 48.71733, lng: 19.0963898 },
    { lat: 48.7173514, lng: 19.0950754 },
    { lat: 48.7172398, lng: 19.0944826 },
    { lat: 48.7171163, lng: 19.0941332 },
    { lat: 48.7171261, lng: 19.0936926 },
    { lat: 48.7171836, lng: 19.0931473 },
    { lat: 48.7173448, lng: 19.0924228 },
    { lat: 48.7182387, lng: 19.0912214 },
    { lat: 48.7184947, lng: 19.090474 },
    { lat: 48.7184828, lng: 19.0888998 },
    { lat: 48.7181684, lng: 19.0877695 },
    { lat: 48.7180418, lng: 19.0876932 },
    { lat: 48.7177016, lng: 19.0869046 },
    { lat: 48.7176917, lng: 19.08616 },
    { lat: 48.7173369, lng: 19.0857626 },
    { lat: 48.7172405, lng: 19.0855244 },
    { lat: 48.7172826, lng: 19.0850322 },
    { lat: 48.7174505, lng: 19.0840813 },
    { lat: 48.7174902, lng: 19.0835417 },
    { lat: 48.7176937, lng: 19.0831212 },
    { lat: 48.7179326, lng: 19.0828284 },
    { lat: 48.7182188, lng: 19.0826412 },
    { lat: 48.7188062, lng: 19.082528 },
    { lat: 48.7191884, lng: 19.0826416 },
    { lat: 48.7200685, lng: 19.0825022 },
    { lat: 48.7207077, lng: 19.0825245 },
    { lat: 48.7209767, lng: 19.082309 },
    { lat: 48.7213318, lng: 19.0818548 },
    { lat: 48.7215335, lng: 19.0812423 },
    { lat: 48.7220642, lng: 19.0801726 },
    { lat: 48.7220864, lng: 19.079963 },
    { lat: 48.7216625, lng: 19.0786712 },
    { lat: 48.7214043, lng: 19.077407 },
    { lat: 48.7213737, lng: 19.0771763 },
    { lat: 48.7216042, lng: 19.0767116 },
    { lat: 48.721655, lng: 19.0760321 },
    { lat: 48.721417, lng: 19.0756475 },
    { lat: 48.7213357, lng: 19.0755982 },
    { lat: 48.7213537, lng: 19.0750784 },
    { lat: 48.7216109, lng: 19.0741776 },
    { lat: 48.7222945, lng: 19.074526 },
    { lat: 48.7225334, lng: 19.0733712 },
    { lat: 48.7225169, lng: 19.0732871 },
    { lat: 48.7228936, lng: 19.0720016 },
    { lat: 48.7226725, lng: 19.0701686 },
    { lat: 48.7227056, lng: 19.0698135 },
    { lat: 48.7226548, lng: 19.0690706 },
    { lat: 48.7227439, lng: 19.0680546 },
    { lat: 48.7230337, lng: 19.0660227 },
    { lat: 48.7228878, lng: 19.0659922 },
    { lat: 48.7229536, lng: 19.0654352 },
    { lat: 48.7223699, lng: 19.0648319 },
    { lat: 48.7222909, lng: 19.0648727 },
    { lat: 48.7210375, lng: 19.0640221 },
    { lat: 48.7199078, lng: 19.0629153 },
    { lat: 48.7178687, lng: 19.0619266 },
    { lat: 48.7158583, lng: 19.0611982 },
    { lat: 48.715114, lng: 19.0616452 },
    { lat: 48.7148121, lng: 19.062349 },
    { lat: 48.7138883, lng: 19.0624081 },
    { lat: 48.7115356, lng: 19.0635397 },
    { lat: 48.7094393, lng: 19.0640716 },
    { lat: 48.7093511, lng: 19.0637708 },
    { lat: 48.7090092, lng: 19.0633176 },
    { lat: 48.7090277, lng: 19.0628261 },
    { lat: 48.7092784, lng: 19.0622938 },
    { lat: 48.7096088, lng: 19.0609446 },
    { lat: 48.7096822, lng: 19.0594822 },
    { lat: 48.7094457, lng: 19.0579761 },
    { lat: 48.7094021, lng: 19.0575425 },
    { lat: 48.7093606, lng: 19.0571298 },
    { lat: 48.7092158, lng: 19.0563796 },
    { lat: 48.7091948, lng: 19.0555945 },
    { lat: 48.7094253, lng: 19.0554718 },
    { lat: 48.7094099, lng: 19.0541713 },
    { lat: 48.7095118, lng: 19.0535043 },
    { lat: 48.7096918, lng: 19.0531171 },
    { lat: 48.7103141, lng: 19.0522817 },
    { lat: 48.7103687, lng: 19.0515454 },
    { lat: 48.7104967, lng: 19.051243 },
    { lat: 48.7109053, lng: 19.0511178 },
    { lat: 48.7110646, lng: 19.0509461 },
    { lat: 48.7114615, lng: 19.050115 },
    { lat: 48.7117754, lng: 19.0498777 },
    { lat: 48.7120125, lng: 19.0495943 },
    { lat: 48.712425, lng: 19.0488674 },
    { lat: 48.7127982, lng: 19.0483527 },
    { lat: 48.7129089, lng: 19.0481294 },
    { lat: 48.7130618, lng: 19.0475825 },
    { lat: 48.7136175, lng: 19.0463869 },
    { lat: 48.7139236, lng: 19.0459516 },
    { lat: 48.7142812, lng: 19.045704 },
    { lat: 48.7145981, lng: 19.0455749 },
    { lat: 48.714923, lng: 19.0455566 },
    { lat: 48.7151924, lng: 19.0457089 },
    { lat: 48.7154151, lng: 19.045722 },
    { lat: 48.7162989, lng: 19.0449814 },
    { lat: 48.716739, lng: 19.0450992 },
    { lat: 48.7167884, lng: 19.0445566 },
    { lat: 48.7168911, lng: 19.0442635 },
    { lat: 48.7168353, lng: 19.0439378 },
    { lat: 48.7173806, lng: 19.0428982 },
    { lat: 48.7175289, lng: 19.0424824 },
    { lat: 48.7175366, lng: 19.0421648 },
    { lat: 48.7176962, lng: 19.0413886 },
    { lat: 48.7177274, lng: 19.0407305 },
    { lat: 48.7179222, lng: 19.0398713 },
    { lat: 48.7178089, lng: 19.0396615 },
    { lat: 48.7178263, lng: 19.0394 },
    { lat: 48.7179406, lng: 19.0392498 },
    { lat: 48.7176076, lng: 19.0387467 },
    { lat: 48.7176865, lng: 19.0382996 },
    { lat: 48.7179086, lng: 19.037986 },
    { lat: 48.7182631, lng: 19.0373202 },
    { lat: 48.7184974, lng: 19.037027 },
    { lat: 48.7189873, lng: 19.0360043 },
    { lat: 48.7191387, lng: 19.0355367 },
    { lat: 48.7193069, lng: 19.0355659 },
    { lat: 48.7193858, lng: 19.0354284 },
    { lat: 48.719478, lng: 19.0354253 },
    { lat: 48.7196936, lng: 19.0349821 },
    { lat: 48.7196429, lng: 19.034804 },
    { lat: 48.7197089, lng: 19.0347029 },
    { lat: 48.719875, lng: 19.0346657 },
    { lat: 48.7199945, lng: 19.0341467 },
    { lat: 48.7202745, lng: 19.0339543 },
    { lat: 48.7197215, lng: 19.0321461 },
    { lat: 48.7197282, lng: 19.0319211 },
    { lat: 48.7198794, lng: 19.0317249 },
    { lat: 48.719643, lng: 19.031034 },
    { lat: 48.7195346, lng: 19.0308511 },
    { lat: 48.7193721, lng: 19.0302174 },
    { lat: 48.7191748, lng: 19.0298845 },
    { lat: 48.7191655, lng: 19.0296331 },
    { lat: 48.7188474, lng: 19.0295365 },
    { lat: 48.717975, lng: 19.0268967 },
    { lat: 48.7183113, lng: 19.0264532 },
    { lat: 48.7182582, lng: 19.0262951 },
    { lat: 48.7184801, lng: 19.0261792 },
    { lat: 48.7184043, lng: 19.025837 },
    { lat: 48.7180824, lng: 19.0251113 },
    { lat: 48.717357, lng: 19.0239997 },
    { lat: 48.7166724, lng: 19.0233014 },
    { lat: 48.7157655, lng: 19.0230026 },
    { lat: 48.7154704, lng: 19.0227812 },
    { lat: 48.7154387, lng: 19.0224903 },
    { lat: 48.7152966, lng: 19.0220243 },
    { lat: 48.7151728, lng: 19.0216184 },
    { lat: 48.7149378, lng: 19.0212966 },
    { lat: 48.7146665, lng: 19.0210553 },
    { lat: 48.7145367, lng: 19.0206965 },
    { lat: 48.7143887, lng: 19.0204468 },
    { lat: 48.7136991, lng: 19.020117 },
    { lat: 48.7134338, lng: 19.0200618 },
    { lat: 48.7132182, lng: 19.0204147 },
    { lat: 48.713245, lng: 19.0206151 },
    { lat: 48.7133309, lng: 19.0212649 },
    { lat: 48.7135312, lng: 19.0218406 },
    { lat: 48.7141196, lng: 19.0230272 },
    { lat: 48.7144196, lng: 19.0240366 },
    { lat: 48.7147925, lng: 19.0263864 },
    { lat: 48.7151672, lng: 19.0277154 },
    { lat: 48.7153056, lng: 19.0288095 },
    { lat: 48.7161845, lng: 19.0328154 },
    { lat: 48.716211, lng: 19.033409 },
    { lat: 48.7165937, lng: 19.0348567 },
    { lat: 48.7166238, lng: 19.0354324 },
    { lat: 48.7165613, lng: 19.0358962 },
    { lat: 48.7163048, lng: 19.0367342 },
    { lat: 48.7162149, lng: 19.0373261 },
    { lat: 48.7162403, lng: 19.0379205 },
    { lat: 48.7165052, lng: 19.0388398 },
    { lat: 48.7169646, lng: 19.0395059 },
    { lat: 48.7171377, lng: 19.0402826 },
    { lat: 48.71717, lng: 19.0408794 },
    { lat: 48.7170341, lng: 19.0421731 },
    { lat: 48.7165554, lng: 19.0433504 },
    { lat: 48.7161114, lng: 19.0446337 },
    { lat: 48.715891, lng: 19.0450299 },
    { lat: 48.715602, lng: 19.0453122 },
    { lat: 48.7153571, lng: 19.045423 },
    { lat: 48.714715, lng: 19.0452713 },
    { lat: 48.713829, lng: 19.0455908 },
    { lat: 48.7135795, lng: 19.0458107 },
    { lat: 48.7132828, lng: 19.0462313 },
    { lat: 48.7126369, lng: 19.0467497 },
    { lat: 48.712447, lng: 19.0474858 },
    { lat: 48.7123321, lng: 19.0476871 },
    { lat: 48.7123333, lng: 19.0478247 },
    { lat: 48.7122085, lng: 19.0480357 },
    { lat: 48.7121626, lng: 19.0484451 },
    { lat: 48.7120013, lng: 19.048671 },
    { lat: 48.7118334, lng: 19.0486741 },
    { lat: 48.7115758, lng: 19.0491759 },
    { lat: 48.711562, lng: 19.0497122 },
    { lat: 48.7112949, lng: 19.0499402 },
    { lat: 48.7110457, lng: 19.050592 },
    { lat: 48.7108972, lng: 19.0507767 },
    { lat: 48.7103316, lng: 19.0511031 },
    { lat: 48.7102158, lng: 19.0512866 },
    { lat: 48.7099375, lng: 19.0520773 },
    { lat: 48.7092773, lng: 19.0531887 },
    { lat: 48.7088069, lng: 19.0547171 },
    { lat: 48.7076746, lng: 19.0541883 },
    { lat: 48.7072337, lng: 19.0537168 },
    { lat: 48.707022, lng: 19.0537442 },
    { lat: 48.7066675, lng: 19.0533957 },
    { lat: 48.7063455, lng: 19.0536081 },
    { lat: 48.706255, lng: 19.0537776 },
    { lat: 48.7066894, lng: 19.053744 },
    { lat: 48.7068475, lng: 19.0546944 },
    { lat: 48.7070499, lng: 19.0548397 },
    { lat: 48.7072389, lng: 19.0556227 },
    { lat: 48.7069232, lng: 19.055556 },
    { lat: 48.7054695, lng: 19.054577 },
    { lat: 48.7050894, lng: 19.0537834 },
    { lat: 48.7047598, lng: 19.0528724 },
    { lat: 48.7041887, lng: 19.0525254 },
    { lat: 48.7038252, lng: 19.0518623 },
    { lat: 48.7032635, lng: 19.0516988 },
    { lat: 48.7028881, lng: 19.0528261 },
    { lat: 48.7028949, lng: 19.0531837 },
    { lat: 48.7029939, lng: 19.0535846 },
    { lat: 48.7033529, lng: 19.0540932 },
    { lat: 48.7032948, lng: 19.0543718 },
    { lat: 48.7032132, lng: 19.0543408 },
    { lat: 48.7032443, lng: 19.0549872 },
    { lat: 48.7030987, lng: 19.0557677 },
    { lat: 48.7032529, lng: 19.0561741 },
    { lat: 48.7037238, lng: 19.0559383 },
    { lat: 48.7041546, lng: 19.0562992 },
    { lat: 48.7039885, lng: 19.0569928 },
    { lat: 48.7043828, lng: 19.0573007 },
    { lat: 48.7045939, lng: 19.0571292 },
    { lat: 48.7049018, lng: 19.0571772 },
    { lat: 48.705026, lng: 19.0573375 },
    { lat: 48.7049419, lng: 19.057597 },
    { lat: 48.7051343, lng: 19.0576782 },
    { lat: 48.7054978, lng: 19.0573711 },
    { lat: 48.7059721, lng: 19.0577692 },
    { lat: 48.7057116, lng: 19.0584903 },
    { lat: 48.7053009, lng: 19.0583038 },
    { lat: 48.7052629, lng: 19.0586479 },
    { lat: 48.7056227, lng: 19.0587425 },
    { lat: 48.7057324, lng: 19.0592647 },
    { lat: 48.7055621, lng: 19.0593127 },
    { lat: 48.7051845, lng: 19.0592451 },
    { lat: 48.7051051, lng: 19.0588937 },
    { lat: 48.7026985, lng: 19.0582712 },
    { lat: 48.7024094, lng: 19.0588014 },
    { lat: 48.7025699, lng: 19.0594216 },
    { lat: 48.702833, lng: 19.0599922 },
    { lat: 48.7022224, lng: 19.0604281 },
    { lat: 48.7017701, lng: 19.0610779 },
    { lat: 48.7016927, lng: 19.0613812 },
    { lat: 48.7014003, lng: 19.0617087 },
    { lat: 48.7014688, lng: 19.0621524 },
    { lat: 48.7021766, lng: 19.0620591 },
    { lat: 48.7021056, lng: 19.0613191 },
    { lat: 48.7023686, lng: 19.061368 },
    { lat: 48.7028342, lng: 19.0610985 },
    { lat: 48.7034966, lng: 19.0614941 },
    { lat: 48.7039166, lng: 19.0619151 },
    { lat: 48.7040152, lng: 19.0619014 },
    { lat: 48.7039732, lng: 19.0623895 },
    { lat: 48.7038067, lng: 19.0625805 },
    { lat: 48.7037987, lng: 19.0629348 },
    { lat: 48.703513, lng: 19.0632872 },
    { lat: 48.7034686, lng: 19.063733 },
    { lat: 48.703596, lng: 19.0637099 },
    { lat: 48.7036947, lng: 19.064172 },
    { lat: 48.7034573, lng: 19.0642062 },
    { lat: 48.7030014, lng: 19.0652797 },
    { lat: 48.7027163, lng: 19.0654378 },
    { lat: 48.7026427, lng: 19.0655483 },
    { lat: 48.7018129, lng: 19.0656115 },
    { lat: 48.7018042, lng: 19.0659345 },
    { lat: 48.7016134, lng: 19.0662235 },
    { lat: 48.7018272, lng: 19.0665079 },
    { lat: 48.7019878, lng: 19.0669464 },
    { lat: 48.7019051, lng: 19.0679106 },
    { lat: 48.7029314, lng: 19.0675355 },
    { lat: 48.7028909, lng: 19.0678489 },
    { lat: 48.7032016, lng: 19.0677264 },
    { lat: 48.7034314, lng: 19.0674992 },
    { lat: 48.7036494, lng: 19.0674408 },
    { lat: 48.7040108, lng: 19.0677908 },
    { lat: 48.7039797, lng: 19.0677787 },
    { lat: 48.7040103, lng: 19.0681207 },
    { lat: 48.7046982, lng: 19.0682143 },
    { lat: 48.7052531, lng: 19.0680811 },
    { lat: 48.7056393, lng: 19.0680767 },
    { lat: 48.7059152, lng: 19.0681138 },
    { lat: 48.7060387, lng: 19.0683299 },
    { lat: 48.7059701, lng: 19.0685133 },
    { lat: 48.7060153, lng: 19.0689451 },
    { lat: 48.7059404, lng: 19.0691392 },
    { lat: 48.7058485, lng: 19.0697583 },
    { lat: 48.7057817, lng: 19.0699772 },
    { lat: 48.7057141, lng: 19.0699999 },
    { lat: 48.7056169, lng: 19.0706984 },
    { lat: 48.7054786, lng: 19.0713113 },
    { lat: 48.7050055, lng: 19.0718435 },
    { lat: 48.7046601, lng: 19.0718997 },
    { lat: 48.7042126, lng: 19.0723293 },
    { lat: 48.7033864, lng: 19.0727886 },
    { lat: 48.7033795, lng: 19.0729668 },
    { lat: 48.7028922, lng: 19.0727792 },
    { lat: 48.7027402, lng: 19.0729842 },
    { lat: 48.7028565, lng: 19.0732837 },
    { lat: 48.7025606, lng: 19.0749017 },
    { lat: 48.7029854, lng: 19.0764334 },
    { lat: 48.702492, lng: 19.0766662 },
    { lat: 48.7020423, lng: 19.0770104 },
    { lat: 48.702192, lng: 19.0777718 },
    { lat: 48.7023868, lng: 19.0780387 },
    { lat: 48.7028411, lng: 19.0784428 },
    { lat: 48.7035885, lng: 19.0793043 },
    { lat: 48.7036132, lng: 19.0796199 },
    { lat: 48.7033097, lng: 19.0794781 },
    { lat: 48.7034284, lng: 19.0808883 },
    { lat: 48.7033299, lng: 19.0809042 },
    { lat: 48.7033485, lng: 19.0812533 },
    { lat: 48.7033007, lng: 19.0815118 },
    { lat: 48.7032264, lng: 19.0815141 },
    { lat: 48.7032607, lng: 19.080846 },
    { lat: 48.7031336, lng: 19.0808316 },
    { lat: 48.7030934, lng: 19.0815378 },
    { lat: 48.7030121, lng: 19.0818979 },
    { lat: 48.7028649, lng: 19.08193 },
    { lat: 48.7027492, lng: 19.0823015 },
    { lat: 48.7026112, lng: 19.0823632 },
    { lat: 48.7026498, lng: 19.0827304 },
    { lat: 48.7027915, lng: 19.0829035 },
    { lat: 48.7029863, lng: 19.0830105 },
    { lat: 48.7032058, lng: 19.0825291 },
    { lat: 48.7033261, lng: 19.0827956 },
    { lat: 48.7032011, lng: 19.0831592 },
    { lat: 48.7032147, lng: 19.0833179 },
    { lat: 48.7031604, lng: 19.083429 },
    { lat: 48.7032205, lng: 19.0835253 },
    { lat: 48.7031084, lng: 19.0837172 },
    { lat: 48.7032413, lng: 19.0839191 },
    { lat: 48.7035306, lng: 19.0836621 },
    { lat: 48.7036853, lng: 19.0842584 },
    { lat: 48.7042317, lng: 19.0855387 },
    { lat: 48.7047765, lng: 19.0849139 },
    { lat: 48.7051012, lng: 19.0863818 },
    { lat: 48.7047123, lng: 19.0864513 },
    { lat: 48.704472, lng: 19.0866075 },
    { lat: 48.7044892, lng: 19.0869289 },
    { lat: 48.70464, lng: 19.0876053 },
    { lat: 48.7047613, lng: 19.0876889 },
    { lat: 48.7050519, lng: 19.0881117 },
    { lat: 48.7050399, lng: 19.0883679 },
    { lat: 48.7046734, lng: 19.0886847 },
    { lat: 48.7044828, lng: 19.0891375 },
    { lat: 48.7041134, lng: 19.0895757 },
    { lat: 48.704548, lng: 19.0902434 },
    { lat: 48.7041241, lng: 19.0906024 },
    { lat: 48.7036923, lng: 19.0905358 },
    { lat: 48.7031642, lng: 19.090614 },
    { lat: 48.7027635, lng: 19.0904485 },
    { lat: 48.7020432, lng: 19.0907651 },
    { lat: 48.7014498, lng: 19.0908703 },
    { lat: 48.7014751, lng: 19.0910841 },
    { lat: 48.7014891, lng: 19.0912085 },
    { lat: 48.7016654, lng: 19.0914904 },
    { lat: 48.7013517, lng: 19.0917345 },
    { lat: 48.7027165, lng: 19.0940158 },
    { lat: 48.702611, lng: 19.094364 },
    { lat: 48.7030293, lng: 19.0943201 },
    { lat: 48.7038348, lng: 19.0944435 },
    { lat: 48.7039738, lng: 19.0945402 },
    { lat: 48.7042857, lng: 19.0950552 },
    { lat: 48.7043475, lng: 19.095323 },
    { lat: 48.7043999, lng: 19.0961073 },
    { lat: 48.7041936, lng: 19.0974231 },
    { lat: 48.7042057, lng: 19.0977329 },
    { lat: 48.7043313, lng: 19.0981727 },
    { lat: 48.7047521, lng: 19.0993392 },
    { lat: 48.7048118, lng: 19.0996098 },
    { lat: 48.7049975, lng: 19.1000095 },
    { lat: 48.7052883, lng: 19.1004434 },
    { lat: 48.7062436, lng: 19.1013374 },
    { lat: 48.7064777, lng: 19.1015561 },
    { lat: 48.7066224, lng: 19.1012909 },
    { lat: 48.7078147, lng: 19.0991033 },
    { lat: 48.7079882, lng: 19.0982631 },
    { lat: 48.7082774, lng: 19.0980214 },
    { lat: 48.7083797, lng: 19.0975669 },
    { lat: 48.7090791, lng: 19.0974172 },
    { lat: 48.7096156, lng: 19.0975502 },
    { lat: 48.7102662, lng: 19.0980268 },
    { lat: 48.7104106, lng: 19.0979362 },
    { lat: 48.7106891, lng: 19.0985978 },
    { lat: 48.7109179, lng: 19.0992987 },
    { lat: 48.7113221, lng: 19.101969 },
    { lat: 48.7114217, lng: 19.1029751 },
    { lat: 48.7119085, lng: 19.1044738 },
    { lat: 48.7119934, lng: 19.1052271 },
    { lat: 48.7124265, lng: 19.1068451 },
    { lat: 48.7125116, lng: 19.1076304 },
    { lat: 48.7127073, lng: 19.1082812 },
    { lat: 48.7128894, lng: 19.1086336 },
    { lat: 48.713458, lng: 19.1089383 },
    { lat: 48.713933, lng: 19.1093804 },
    { lat: 48.7143082, lng: 19.1099198 },
    { lat: 48.715066, lng: 19.110382 },
  ],
  Badín: [
    { lat: 48.6567707, lng: 19.1505123 },
    { lat: 48.6568538, lng: 19.1499785 },
    { lat: 48.6568537, lng: 19.1494486 },
    { lat: 48.6570824, lng: 19.1483465 },
    { lat: 48.6572803, lng: 19.1469219 },
    { lat: 48.6573897, lng: 19.1465548 },
    { lat: 48.6575469, lng: 19.1462926 },
    { lat: 48.6578776, lng: 19.146024 },
    { lat: 48.6582705, lng: 19.1459674 },
    { lat: 48.658563, lng: 19.1460693 },
    { lat: 48.6587426, lng: 19.1462381 },
    { lat: 48.6587385, lng: 19.1459252 },
    { lat: 48.6593001, lng: 19.1462297 },
    { lat: 48.6594717, lng: 19.1462129 },
    { lat: 48.6598817, lng: 19.1464785 },
    { lat: 48.6599715, lng: 19.1466335 },
    { lat: 48.660182, lng: 19.147588 },
    { lat: 48.6606134, lng: 19.1481254 },
    { lat: 48.6612917, lng: 19.1487171 },
    { lat: 48.6614042, lng: 19.1495332 },
    { lat: 48.6617694, lng: 19.1495564 },
    { lat: 48.6625625, lng: 19.1495016 },
    { lat: 48.6641482, lng: 19.1488728 },
    { lat: 48.6647323, lng: 19.1485112 },
    { lat: 48.6664953, lng: 19.1471845 },
    { lat: 48.6666471, lng: 19.147196 },
    { lat: 48.6672399, lng: 19.1468298 },
    { lat: 48.667597, lng: 19.1465019 },
    { lat: 48.6679178, lng: 19.1460582 },
    { lat: 48.6684608, lng: 19.1449537 },
    { lat: 48.6689597, lng: 19.1441683 },
    { lat: 48.6694655, lng: 19.1436553 },
    { lat: 48.6703776, lng: 19.1430195 },
    { lat: 48.6708573, lng: 19.1428026 },
    { lat: 48.6713555, lng: 19.1427791 },
    { lat: 48.6719537, lng: 19.1431116 },
    { lat: 48.6724364, lng: 19.1435535 },
    { lat: 48.67304, lng: 19.1442254 },
    { lat: 48.6735057, lng: 19.1448696 },
    { lat: 48.6740569, lng: 19.145485 },
    { lat: 48.6747032, lng: 19.1458094 },
    { lat: 48.6753482, lng: 19.14586 },
    { lat: 48.6755524, lng: 19.145771 },
    { lat: 48.6762895, lng: 19.1452455 },
    { lat: 48.6768885, lng: 19.1445872 },
    { lat: 48.6771978, lng: 19.1439806 },
    { lat: 48.678026, lng: 19.1411404 },
    { lat: 48.678556, lng: 19.1401134 },
    { lat: 48.6787676, lng: 19.139831 },
    { lat: 48.6786303, lng: 19.1396092 },
    { lat: 48.6785045, lng: 19.1391839 },
    { lat: 48.6781529, lng: 19.1393208 },
    { lat: 48.678162, lng: 19.1393722 },
    { lat: 48.6775154, lng: 19.139562 },
    { lat: 48.6764467, lng: 19.1397004 },
    { lat: 48.6765535, lng: 19.1393945 },
    { lat: 48.6762778, lng: 19.1389504 },
    { lat: 48.6763408, lng: 19.1373256 },
    { lat: 48.6762928, lng: 19.1363958 },
    { lat: 48.6760165, lng: 19.1353685 },
    { lat: 48.6761683, lng: 19.1345396 },
    { lat: 48.6761161, lng: 19.1341424 },
    { lat: 48.6757967, lng: 19.1337503 },
    { lat: 48.6751778, lng: 19.1326978 },
    { lat: 48.6751413, lng: 19.1321409 },
    { lat: 48.6749162, lng: 19.131669 },
    { lat: 48.6747368, lng: 19.1298651 },
    { lat: 48.6747838, lng: 19.1293101 },
    { lat: 48.674772, lng: 19.1288224 },
    { lat: 48.6743256, lng: 19.1271816 },
    { lat: 48.6741082, lng: 19.1251302 },
    { lat: 48.6739945, lng: 19.1248616 },
    { lat: 48.673969, lng: 19.1243027 },
    { lat: 48.6738359, lng: 19.1240955 },
    { lat: 48.6738154, lng: 19.123954 },
    { lat: 48.6738665, lng: 19.123644 },
    { lat: 48.6738108, lng: 19.1231292 },
    { lat: 48.673763, lng: 19.1230326 },
    { lat: 48.673894, lng: 19.1226961 },
    { lat: 48.6738761, lng: 19.1225397 },
    { lat: 48.6736534, lng: 19.1221832 },
    { lat: 48.6737474, lng: 19.1219211 },
    { lat: 48.673753, lng: 19.1216911 },
    { lat: 48.6736892, lng: 19.1215606 },
    { lat: 48.6737478, lng: 19.1213564 },
    { lat: 48.6737794, lng: 19.1206295 },
    { lat: 48.6736955, lng: 19.1204153 },
    { lat: 48.6735931, lng: 19.1203505 },
    { lat: 48.6737822, lng: 19.1200039 },
    { lat: 48.6737662, lng: 19.1198195 },
    { lat: 48.6738137, lng: 19.1198669 },
    { lat: 48.6738248, lng: 19.1198157 },
    { lat: 48.6743364, lng: 19.1197901 },
    { lat: 48.6753665, lng: 19.1195115 },
    { lat: 48.6753843, lng: 19.1176386 },
    { lat: 48.6754379, lng: 19.1176757 },
    { lat: 48.6756563, lng: 19.1175297 },
    { lat: 48.6771964, lng: 19.1159646 },
    { lat: 48.6781384, lng: 19.1142265 },
    { lat: 48.6785821, lng: 19.1138324 },
    { lat: 48.6789243, lng: 19.1133453 },
    { lat: 48.6791934, lng: 19.1131309 },
    { lat: 48.6797374, lng: 19.1123063 },
    { lat: 48.6804439, lng: 19.1116364 },
    { lat: 48.6810137, lng: 19.1107361 },
    { lat: 48.6811292, lng: 19.1103409 },
    { lat: 48.6817541, lng: 19.1095757 },
    { lat: 48.6824073, lng: 19.1084483 },
    { lat: 48.6825241, lng: 19.1079588 },
    { lat: 48.6832225, lng: 19.1068808 },
    { lat: 48.6842478, lng: 19.1080962 },
    { lat: 48.6847164, lng: 19.1085466 },
    { lat: 48.68539, lng: 19.1088293 },
    { lat: 48.6854422, lng: 19.1087007 },
    { lat: 48.6864116, lng: 19.10815 },
    { lat: 48.6868334, lng: 19.1078393 },
    { lat: 48.6874247, lng: 19.1077357 },
    { lat: 48.6874977, lng: 19.1083443 },
    { lat: 48.6882976, lng: 19.1079691 },
    { lat: 48.6883208, lng: 19.1080742 },
    { lat: 48.6882075, lng: 19.1089722 },
    { lat: 48.6884394, lng: 19.1099657 },
    { lat: 48.6889768, lng: 19.1098215 },
    { lat: 48.6894587, lng: 19.1094033 },
    { lat: 48.6895588, lng: 19.1092161 },
    { lat: 48.6899008, lng: 19.1089038 },
    { lat: 48.689968, lng: 19.1085304 },
    { lat: 48.6900434, lng: 19.1084362 },
    { lat: 48.6902767, lng: 19.1083481 },
    { lat: 48.6912009, lng: 19.1075013 },
    { lat: 48.6921968, lng: 19.1060584 },
    { lat: 48.6925598, lng: 19.1047862 },
    { lat: 48.6926154, lng: 19.1039492 },
    { lat: 48.6927264, lng: 19.1032779 },
    { lat: 48.6928638, lng: 19.1028249 },
    { lat: 48.6928455, lng: 19.1025697 },
    { lat: 48.6927298, lng: 19.1023469 },
    { lat: 48.692871, lng: 19.1015848 },
    { lat: 48.6928021, lng: 19.1009199 },
    { lat: 48.6926169, lng: 19.1001213 },
    { lat: 48.6925658, lng: 19.0995454 },
    { lat: 48.69255, lng: 19.0990034 },
    { lat: 48.6926353, lng: 19.0980199 },
    { lat: 48.6925629, lng: 19.0977648 },
    { lat: 48.6927282, lng: 19.097573 },
    { lat: 48.6928824, lng: 19.0972729 },
    { lat: 48.6931229, lng: 19.0965787 },
    { lat: 48.6931754, lng: 19.0963668 },
    { lat: 48.6930969, lng: 19.0962362 },
    { lat: 48.6931248, lng: 19.0961424 },
    { lat: 48.6930387, lng: 19.0960155 },
    { lat: 48.6930263, lng: 19.0957479 },
    { lat: 48.6931041, lng: 19.0946799 },
    { lat: 48.6928806, lng: 19.0944937 },
    { lat: 48.692901, lng: 19.093883 },
    { lat: 48.692642, lng: 19.0935484 },
    { lat: 48.6924275, lng: 19.0926599 },
    { lat: 48.6920028, lng: 19.0915788 },
    { lat: 48.6922333, lng: 19.0907508 },
    { lat: 48.6923636, lng: 19.0904691 },
    { lat: 48.6925154, lng: 19.0892804 },
    { lat: 48.6924961, lng: 19.0890535 },
    { lat: 48.6926247, lng: 19.0883298 },
    { lat: 48.6927501, lng: 19.0879295 },
    { lat: 48.6928422, lng: 19.0871943 },
    { lat: 48.6929574, lng: 19.0870402 },
    { lat: 48.6930282, lng: 19.0867442 },
    { lat: 48.693005, lng: 19.0863679 },
    { lat: 48.6931707, lng: 19.0854588 },
    { lat: 48.6931873, lng: 19.085161 },
    { lat: 48.6931344, lng: 19.0839395 },
    { lat: 48.693206, lng: 19.0834082 },
    { lat: 48.6931186, lng: 19.0825825 },
    { lat: 48.6931271, lng: 19.0823228 },
    { lat: 48.6933411, lng: 19.0814342 },
    { lat: 48.6934337, lng: 19.0803956 },
    { lat: 48.6934212, lng: 19.0795149 },
    { lat: 48.6932798, lng: 19.0790949 },
    { lat: 48.693341, lng: 19.0783567 },
    { lat: 48.693231, lng: 19.0778222 },
    { lat: 48.6932682, lng: 19.0776217 },
    { lat: 48.6931948, lng: 19.077098 },
    { lat: 48.6931877, lng: 19.0759837 },
    { lat: 48.6932065, lng: 19.0757506 },
    { lat: 48.6935253, lng: 19.0751366 },
    { lat: 48.6935861, lng: 19.0748874 },
    { lat: 48.6935272, lng: 19.0746529 },
    { lat: 48.6932123, lng: 19.0739904 },
    { lat: 48.6929734, lng: 19.0732457 },
    { lat: 48.6927606, lng: 19.0708809 },
    { lat: 48.6926547, lng: 19.0701607 },
    { lat: 48.6926681, lng: 19.0699315 },
    { lat: 48.6927162, lng: 19.0696973 },
    { lat: 48.6929236, lng: 19.0693379 },
    { lat: 48.6933896, lng: 19.0689632 },
    { lat: 48.6938002, lng: 19.0687644 },
    { lat: 48.6937777, lng: 19.0684937 },
    { lat: 48.6939563, lng: 19.067533 },
    { lat: 48.6943691, lng: 19.0669492 },
    { lat: 48.6943895, lng: 19.0664105 },
    { lat: 48.6945257, lng: 19.0656606 },
    { lat: 48.6945763, lng: 19.064934 },
    { lat: 48.6946844, lng: 19.0643699 },
    { lat: 48.6948157, lng: 19.0642879 },
    { lat: 48.6950869, lng: 19.0642552 },
    { lat: 48.695428, lng: 19.0631635 },
    { lat: 48.6954539, lng: 19.0623961 },
    { lat: 48.6955804, lng: 19.0611402 },
    { lat: 48.6957072, lng: 19.0607895 },
    { lat: 48.6957665, lng: 19.0598549 },
    { lat: 48.6962873, lng: 19.0579815 },
    { lat: 48.6967169, lng: 19.0568375 },
    { lat: 48.6970096, lng: 19.055747 },
    { lat: 48.6972737, lng: 19.0558646 },
    { lat: 48.697385, lng: 19.0555678 },
    { lat: 48.6974353, lng: 19.0552658 },
    { lat: 48.6979582, lng: 19.0546304 },
    { lat: 48.6983845, lng: 19.0539582 },
    { lat: 48.6985263, lng: 19.0536369 },
    { lat: 48.6987216, lng: 19.0526731 },
    { lat: 48.6983986, lng: 19.0518099 },
    { lat: 48.6979766, lng: 19.0510823 },
    { lat: 48.6979297, lng: 19.0507954 },
    { lat: 48.6980123, lng: 19.0505536 },
    { lat: 48.6979776, lng: 19.0501778 },
    { lat: 48.6978431, lng: 19.0498549 },
    { lat: 48.6979163, lng: 19.0494261 },
    { lat: 48.6980016, lng: 19.0495095 },
    { lat: 48.6981474, lng: 19.0491331 },
    { lat: 48.6986015, lng: 19.0487913 },
    { lat: 48.6990089, lng: 19.0486427 },
    { lat: 48.6994521, lng: 19.0483739 },
    { lat: 48.6996955, lng: 19.0483268 },
    { lat: 48.6995071, lng: 19.0486421 },
    { lat: 48.6994762, lng: 19.0488772 },
    { lat: 48.6998662, lng: 19.0492124 },
    { lat: 48.7002177, lng: 19.0489609 },
    { lat: 48.7002885, lng: 19.0466397 },
    { lat: 48.7006057, lng: 19.0463336 },
    { lat: 48.7020839, lng: 19.0465827 },
    { lat: 48.7024094, lng: 19.0464641 },
    { lat: 48.7035082, lng: 19.0457376 },
    { lat: 48.7039255, lng: 19.0455614 },
    { lat: 48.7042988, lng: 19.0456322 },
    { lat: 48.7051004, lng: 19.0464206 },
    { lat: 48.7056067, lng: 19.0465765 },
    { lat: 48.7060925, lng: 19.0461483 },
    { lat: 48.7062718, lng: 19.0461956 },
    { lat: 48.7066168, lng: 19.0464845 },
    { lat: 48.7069617, lng: 19.0465974 },
    { lat: 48.7078822, lng: 19.047307 },
    { lat: 48.7079353, lng: 19.0482283 },
    { lat: 48.7081471, lng: 19.0488352 },
    { lat: 48.7083263, lng: 19.0490828 },
    { lat: 48.7081443, lng: 19.0499482 },
    { lat: 48.7077801, lng: 19.0504885 },
    { lat: 48.7072322, lng: 19.0524919 },
    { lat: 48.7070835, lng: 19.0531468 },
    { lat: 48.7071267, lng: 19.0535613 },
    { lat: 48.7072337, lng: 19.0537168 },
    { lat: 48.7076746, lng: 19.0541883 },
    { lat: 48.7088069, lng: 19.0547171 },
    { lat: 48.7092773, lng: 19.0531887 },
    { lat: 48.7099375, lng: 19.0520773 },
    { lat: 48.7102158, lng: 19.0512866 },
    { lat: 48.7103316, lng: 19.0511031 },
    { lat: 48.7108972, lng: 19.0507767 },
    { lat: 48.7110457, lng: 19.050592 },
    { lat: 48.7112949, lng: 19.0499402 },
    { lat: 48.711562, lng: 19.0497122 },
    { lat: 48.7115758, lng: 19.0491759 },
    { lat: 48.7118334, lng: 19.0486741 },
    { lat: 48.7120013, lng: 19.048671 },
    { lat: 48.7121626, lng: 19.0484451 },
    { lat: 48.7122085, lng: 19.0480357 },
    { lat: 48.7123333, lng: 19.0478247 },
    { lat: 48.7123321, lng: 19.0476871 },
    { lat: 48.712447, lng: 19.0474858 },
    { lat: 48.7126369, lng: 19.0467497 },
    { lat: 48.7132828, lng: 19.0462313 },
    { lat: 48.7135795, lng: 19.0458107 },
    { lat: 48.713829, lng: 19.0455908 },
    { lat: 48.714715, lng: 19.0452713 },
    { lat: 48.7153571, lng: 19.045423 },
    { lat: 48.715602, lng: 19.0453122 },
    { lat: 48.715891, lng: 19.0450299 },
    { lat: 48.7161114, lng: 19.0446337 },
    { lat: 48.7165554, lng: 19.0433504 },
    { lat: 48.7170341, lng: 19.0421731 },
    { lat: 48.71717, lng: 19.0408794 },
    { lat: 48.7171377, lng: 19.0402826 },
    { lat: 48.7169646, lng: 19.0395059 },
    { lat: 48.7165052, lng: 19.0388398 },
    { lat: 48.7162403, lng: 19.0379205 },
    { lat: 48.7162149, lng: 19.0373261 },
    { lat: 48.7163048, lng: 19.0367342 },
    { lat: 48.7165613, lng: 19.0358962 },
    { lat: 48.7166238, lng: 19.0354324 },
    { lat: 48.7165937, lng: 19.0348567 },
    { lat: 48.716211, lng: 19.033409 },
    { lat: 48.7161845, lng: 19.0328154 },
    { lat: 48.7153056, lng: 19.0288095 },
    { lat: 48.7151672, lng: 19.0277154 },
    { lat: 48.7147925, lng: 19.0263864 },
    { lat: 48.7144196, lng: 19.0240366 },
    { lat: 48.7141196, lng: 19.0230272 },
    { lat: 48.7135312, lng: 19.0218406 },
    { lat: 48.7133309, lng: 19.0212649 },
    { lat: 48.713245, lng: 19.0206151 },
    { lat: 48.7132182, lng: 19.0204147 },
    { lat: 48.7130244, lng: 19.0198839 },
    { lat: 48.7126429, lng: 19.0190685 },
    { lat: 48.7124609, lng: 19.0182323 },
    { lat: 48.7120394, lng: 19.0173361 },
    { lat: 48.7111973, lng: 19.015642 },
    { lat: 48.7098415, lng: 19.013388 },
    { lat: 48.7097339, lng: 19.0129549 },
    { lat: 48.7097108, lng: 19.0124967 },
    { lat: 48.7096261, lng: 19.0121384 },
    { lat: 48.7092926, lng: 19.0115349 },
    { lat: 48.7087737, lng: 19.0110181 },
    { lat: 48.7082047, lng: 19.0105578 },
    { lat: 48.7068987, lng: 19.008202 },
    { lat: 48.7067974, lng: 19.007937 },
    { lat: 48.706729, lng: 19.007418 },
    { lat: 48.706572, lng: 19.007646 },
    { lat: 48.706377, lng: 19.007718 },
    { lat: 48.705624, lng: 19.008127 },
    { lat: 48.704209, lng: 19.009767 },
    { lat: 48.70444, lng: 19.011374 },
    { lat: 48.704802, lng: 19.013016 },
    { lat: 48.705014, lng: 19.013933 },
    { lat: 48.705041, lng: 19.014051 },
    { lat: 48.705196, lng: 19.01472 },
    { lat: 48.705402, lng: 19.015421 },
    { lat: 48.705557, lng: 19.015799 },
    { lat: 48.706166, lng: 19.016498 },
    { lat: 48.706188, lng: 19.016591 },
    { lat: 48.706078, lng: 19.016831 },
    { lat: 48.706004, lng: 19.016994 },
    { lat: 48.705687, lng: 19.017585 },
    { lat: 48.705256, lng: 19.018042 },
    { lat: 48.703812, lng: 19.018576 },
    { lat: 48.703753, lng: 19.019406 },
    { lat: 48.703655, lng: 19.020541 },
    { lat: 48.703609, lng: 19.022058 },
    { lat: 48.703625, lng: 19.022672 },
    { lat: 48.703742, lng: 19.023263 },
    { lat: 48.70367, lng: 19.023552 },
    { lat: 48.703751, lng: 19.023692 },
    { lat: 48.703877, lng: 19.023909 },
    { lat: 48.704003, lng: 19.024207 },
    { lat: 48.703969, lng: 19.024805 },
    { lat: 48.703866, lng: 19.025283 },
    { lat: 48.703553, lng: 19.026032 },
    { lat: 48.7034705, lng: 19.0263386 },
    { lat: 48.70345, lng: 19.0264068 },
    { lat: 48.70326, lng: 19.027038 },
    { lat: 48.702682, lng: 19.028661 },
    { lat: 48.702344, lng: 19.029535 },
    { lat: 48.70226, lng: 19.029848 },
    { lat: 48.702257, lng: 19.030066 },
    { lat: 48.702181, lng: 19.030445 },
    { lat: 48.702127, lng: 19.030534 },
    { lat: 48.702061, lng: 19.030011 },
    { lat: 48.701968, lng: 19.029683 },
    { lat: 48.701749, lng: 19.026944 },
    { lat: 48.700872, lng: 19.025541 },
    { lat: 48.700796, lng: 19.025295 },
    { lat: 48.70065, lng: 19.024576 },
    { lat: 48.700658, lng: 19.024094 },
    { lat: 48.700588, lng: 19.023404 },
    { lat: 48.700484, lng: 19.022856 },
    { lat: 48.700468, lng: 19.022463 },
    { lat: 48.70051, lng: 19.021596 },
    { lat: 48.700876, lng: 19.020636 },
    { lat: 48.700696, lng: 19.020079 },
    { lat: 48.7005, lng: 19.019985 },
    { lat: 48.700349, lng: 19.019817 },
    { lat: 48.699724, lng: 19.01889 },
    { lat: 48.699674, lng: 19.018841 },
    { lat: 48.699611, lng: 19.018779 },
    { lat: 48.699546, lng: 19.018714 },
    { lat: 48.699419, lng: 19.01875 },
    { lat: 48.698793, lng: 19.019722 },
    { lat: 48.698421, lng: 19.020173 },
    { lat: 48.698211, lng: 19.020585 },
    { lat: 48.697917, lng: 19.021227 },
    { lat: 48.697531, lng: 19.022073 },
    { lat: 48.697201, lng: 19.022703 },
    { lat: 48.696812, lng: 19.023293 },
    { lat: 48.696737, lng: 19.023386 },
    { lat: 48.696009, lng: 19.023843 },
    { lat: 48.695526, lng: 19.0242 },
    { lat: 48.695142, lng: 19.024642 },
    { lat: 48.694038, lng: 19.02547 },
    { lat: 48.693647, lng: 19.025726 },
    { lat: 48.693136, lng: 19.026015 },
    { lat: 48.692979, lng: 19.026142 },
    { lat: 48.692711, lng: 19.026389 },
    { lat: 48.692378, lng: 19.026839 },
    { lat: 48.692118, lng: 19.027031 },
    { lat: 48.692036, lng: 19.027072 },
    { lat: 48.691063, lng: 19.027435 },
    { lat: 48.690813, lng: 19.027538 },
    { lat: 48.689796, lng: 19.027998 },
    { lat: 48.688, lng: 19.029005 },
    { lat: 48.687519, lng: 19.029341 },
    { lat: 48.687268, lng: 19.029555 },
    { lat: 48.686778, lng: 19.030076 },
    { lat: 48.686291, lng: 19.030434 },
    { lat: 48.6859, lng: 19.030934 },
    { lat: 48.685544, lng: 19.031847 },
    { lat: 48.685338, lng: 19.032274 },
    { lat: 48.685136, lng: 19.032551 },
    { lat: 48.684807, lng: 19.032853 },
    { lat: 48.684787, lng: 19.033176 },
    { lat: 48.684787, lng: 19.033438 },
    { lat: 48.684497, lng: 19.03404 },
    { lat: 48.683816, lng: 19.035245 },
    { lat: 48.683685, lng: 19.035488 },
    { lat: 48.683306, lng: 19.035941 },
    { lat: 48.682881, lng: 19.036599 },
    { lat: 48.682633, lng: 19.036982 },
    { lat: 48.682412, lng: 19.037629 },
    { lat: 48.682278, lng: 19.039449 },
    { lat: 48.682254, lng: 19.039691 },
    { lat: 48.682122, lng: 19.040197 },
    { lat: 48.681918, lng: 19.041887 },
    { lat: 48.681924, lng: 19.04245 },
    { lat: 48.68167, lng: 19.043052 },
    { lat: 48.681476, lng: 19.04351 },
    { lat: 48.681382, lng: 19.043586 },
    { lat: 48.681185, lng: 19.043591 },
    { lat: 48.681002, lng: 19.043581 },
    { lat: 48.680849, lng: 19.043553 },
    { lat: 48.680188, lng: 19.044485 },
    { lat: 48.679761, lng: 19.045017 },
    { lat: 48.679302, lng: 19.045769 },
    { lat: 48.679158, lng: 19.046614 },
    { lat: 48.67854, lng: 19.048041 },
    { lat: 48.677115, lng: 19.049835 },
    { lat: 48.676491, lng: 19.050903 },
    { lat: 48.676227, lng: 19.051355 },
    { lat: 48.675546, lng: 19.052178 },
    { lat: 48.675161, lng: 19.052785 },
    { lat: 48.674569, lng: 19.053768 },
    { lat: 48.674456, lng: 19.053962 },
    { lat: 48.674294, lng: 19.054305 },
    { lat: 48.67393, lng: 19.055173 },
    { lat: 48.673733, lng: 19.055649 },
    { lat: 48.673352, lng: 19.056461 },
    { lat: 48.67311, lng: 19.057292 },
    { lat: 48.672827, lng: 19.058587 },
    { lat: 48.672711, lng: 19.059076 },
    { lat: 48.67226, lng: 19.060065 },
    { lat: 48.672108, lng: 19.0604 },
    { lat: 48.672002, lng: 19.060528 },
    { lat: 48.671528, lng: 19.061061 },
    { lat: 48.671033, lng: 19.061599 },
    { lat: 48.670937, lng: 19.061822 },
    { lat: 48.670915, lng: 19.062201 },
    { lat: 48.670761, lng: 19.062635 },
    { lat: 48.670613, lng: 19.063869 },
    { lat: 48.670468, lng: 19.064343 },
    { lat: 48.670229, lng: 19.065295 },
    { lat: 48.669953, lng: 19.06601 },
    { lat: 48.66953, lng: 19.067833 },
    { lat: 48.6692549, lng: 19.0682035 },
    { lat: 48.66906, lng: 19.068466 },
    { lat: 48.6687964, lng: 19.0686392 },
    { lat: 48.668311, lng: 19.068958 },
    { lat: 48.667867, lng: 19.069851 },
    { lat: 48.666622, lng: 19.070875 },
    { lat: 48.665912, lng: 19.071641 },
    { lat: 48.665486, lng: 19.072253 },
    { lat: 48.665207, lng: 19.073077 },
    { lat: 48.665041, lng: 19.073415 },
    { lat: 48.664923, lng: 19.073618 },
    { lat: 48.663544, lng: 19.073976 },
    { lat: 48.663001, lng: 19.074106 },
    { lat: 48.662769, lng: 19.074339 },
    { lat: 48.662156, lng: 19.075161 },
    { lat: 48.661566, lng: 19.075647 },
    { lat: 48.660768, lng: 19.076262 },
    { lat: 48.660087, lng: 19.076845 },
    { lat: 48.659461, lng: 19.077236 },
    { lat: 48.659182, lng: 19.077509 },
    { lat: 48.659079, lng: 19.077653 },
    { lat: 48.659037, lng: 19.077753 },
    { lat: 48.65899, lng: 19.077901 },
    { lat: 48.658995, lng: 19.078 },
    { lat: 48.659042, lng: 19.07817 },
    { lat: 48.6591847, lng: 19.0785226 },
    { lat: 48.659329, lng: 19.078879 },
    { lat: 48.659404, lng: 19.078999 },
    { lat: 48.659579, lng: 19.079143 },
    { lat: 48.659822, lng: 19.079098 },
    { lat: 48.660306, lng: 19.079695 },
    { lat: 48.660548, lng: 19.08008 },
    { lat: 48.660622, lng: 19.080455 },
    { lat: 48.660642, lng: 19.080751 },
    { lat: 48.66065, lng: 19.081015 },
    { lat: 48.660633, lng: 19.081286 },
    { lat: 48.66056, lng: 19.081871 },
    { lat: 48.660444, lng: 19.082341 },
    { lat: 48.660317, lng: 19.082805 },
    { lat: 48.659998, lng: 19.08337 },
    { lat: 48.659822, lng: 19.083633 },
    { lat: 48.659018, lng: 19.08468 },
    { lat: 48.658709, lng: 19.085037 },
    { lat: 48.65823, lng: 19.085408 },
    { lat: 48.657996, lng: 19.085647 },
    { lat: 48.657662, lng: 19.085912 },
    { lat: 48.657483, lng: 19.086043 },
    { lat: 48.656821, lng: 19.087063 },
    { lat: 48.65676, lng: 19.087166 },
    { lat: 48.656735, lng: 19.087251 },
    { lat: 48.65672, lng: 19.087403 },
    { lat: 48.656694, lng: 19.087551 },
    { lat: 48.656517, lng: 19.08815 },
    { lat: 48.656419, lng: 19.088509 },
    { lat: 48.656222, lng: 19.088929 },
    { lat: 48.65602, lng: 19.089376 },
    { lat: 48.655971, lng: 19.089527 },
    { lat: 48.655884, lng: 19.089811 },
    { lat: 48.655836, lng: 19.090019 },
    { lat: 48.655781, lng: 19.090201 },
    { lat: 48.655673, lng: 19.090464 },
    { lat: 48.655483, lng: 19.09092 },
    { lat: 48.655349, lng: 19.091021 },
    { lat: 48.655268, lng: 19.091116 },
    { lat: 48.655154, lng: 19.091144 },
    { lat: 48.655028, lng: 19.091211 },
    { lat: 48.654916, lng: 19.091229 },
    { lat: 48.654818, lng: 19.09126 },
    { lat: 48.654709, lng: 19.09119 },
    { lat: 48.654592, lng: 19.091202 },
    { lat: 48.654433, lng: 19.091265 },
    { lat: 48.654337, lng: 19.091256 },
    { lat: 48.65419, lng: 19.091284 },
    { lat: 48.654102, lng: 19.091351 },
    { lat: 48.654019, lng: 19.091375 },
    { lat: 48.653935, lng: 19.091357 },
    { lat: 48.653778, lng: 19.091466 },
    { lat: 48.653692, lng: 19.091458 },
    { lat: 48.653642, lng: 19.091618 },
    { lat: 48.653583, lng: 19.091639 },
    { lat: 48.653546, lng: 19.091703 },
    { lat: 48.653514, lng: 19.091735 },
    { lat: 48.653516, lng: 19.091799 },
    { lat: 48.653475, lng: 19.091854 },
    { lat: 48.653372, lng: 19.091948 },
    { lat: 48.653303, lng: 19.092074 },
    { lat: 48.653232, lng: 19.092196 },
    { lat: 48.653123, lng: 19.092341 },
    { lat: 48.653029, lng: 19.0925 },
    { lat: 48.652974, lng: 19.092515 },
    { lat: 48.652833, lng: 19.092614 },
    { lat: 48.652793, lng: 19.092703 },
    { lat: 48.652715, lng: 19.092723 },
    { lat: 48.652618, lng: 19.092833 },
    { lat: 48.652521, lng: 19.092851 },
    { lat: 48.65244, lng: 19.092896 },
    { lat: 48.65231, lng: 19.092855 },
    { lat: 48.652232, lng: 19.092898 },
    { lat: 48.652181, lng: 19.093028 },
    { lat: 48.65206, lng: 19.09318 },
    { lat: 48.652002, lng: 19.093289 },
    { lat: 48.651947, lng: 19.093405 },
    { lat: 48.651898, lng: 19.093483 },
    { lat: 48.651794, lng: 19.093693 },
    { lat: 48.651713, lng: 19.093782 },
    { lat: 48.651679, lng: 19.093845 },
    { lat: 48.651645, lng: 19.093962 },
    { lat: 48.651596, lng: 19.094092 },
    { lat: 48.651557, lng: 19.09424 },
    { lat: 48.651476, lng: 19.094468 },
    { lat: 48.651391, lng: 19.094563 },
    { lat: 48.651328, lng: 19.094611 },
    { lat: 48.651277, lng: 19.094656 },
    { lat: 48.651218, lng: 19.094719 },
    { lat: 48.65108, lng: 19.094869 },
    { lat: 48.651028, lng: 19.095003 },
    { lat: 48.650998, lng: 19.095144 },
    { lat: 48.650996, lng: 19.095247 },
    { lat: 48.650906, lng: 19.095357 },
    { lat: 48.650881, lng: 19.095453 },
    { lat: 48.65079, lng: 19.095612 },
    { lat: 48.650769, lng: 19.095707 },
    { lat: 48.650709, lng: 19.095775 },
    { lat: 48.650628, lng: 19.0958 },
    { lat: 48.65058, lng: 19.095721 },
    { lat: 48.650482, lng: 19.095738 },
    { lat: 48.650462, lng: 19.095696 },
    { lat: 48.650378, lng: 19.095707 },
    { lat: 48.650301, lng: 19.095772 },
    { lat: 48.650291, lng: 19.095883 },
    { lat: 48.650208, lng: 19.095965 },
    { lat: 48.650237, lng: 19.096075 },
    { lat: 48.650156, lng: 19.0961 },
    { lat: 48.650053, lng: 19.096098 },
    { lat: 48.649946, lng: 19.096162 },
    { lat: 48.649867, lng: 19.096203 },
    { lat: 48.649832, lng: 19.096181 },
    { lat: 48.649775, lng: 19.096138 },
    { lat: 48.649713, lng: 19.096168 },
    { lat: 48.649687, lng: 19.096139 },
    { lat: 48.64958, lng: 19.096213 },
    { lat: 48.649505, lng: 19.096276 },
    { lat: 48.649406, lng: 19.096376 },
    { lat: 48.649345, lng: 19.096369 },
    { lat: 48.649176, lng: 19.096338 },
    { lat: 48.649112, lng: 19.09645 },
    { lat: 48.649037, lng: 19.096462 },
    { lat: 48.648958, lng: 19.096495 },
    { lat: 48.648897, lng: 19.09649 },
    { lat: 48.648873, lng: 19.096527 },
    { lat: 48.648869, lng: 19.096588 },
    { lat: 48.648823, lng: 19.096752 },
    { lat: 48.648764, lng: 19.096809 },
    { lat: 48.648706, lng: 19.096963 },
    { lat: 48.648581, lng: 19.097054 },
    { lat: 48.648519, lng: 19.097007 },
    { lat: 48.648448, lng: 19.096896 },
    { lat: 48.648402, lng: 19.096901 },
    { lat: 48.648349, lng: 19.096898 },
    { lat: 48.648234, lng: 19.096836 },
    { lat: 48.64809, lng: 19.096847 },
    { lat: 48.647967, lng: 19.096932 },
    { lat: 48.647921, lng: 19.097035 },
    { lat: 48.647898, lng: 19.097072 },
    { lat: 48.647821, lng: 19.097202 },
    { lat: 48.647744, lng: 19.097292 },
    { lat: 48.647716, lng: 19.097381 },
    { lat: 48.647614, lng: 19.097399 },
    { lat: 48.647579, lng: 19.097406 },
    { lat: 48.647525, lng: 19.097435 },
    { lat: 48.647514, lng: 19.097484 },
    { lat: 48.647497, lng: 19.097552 },
    { lat: 48.647441, lng: 19.097631 },
    { lat: 48.647428, lng: 19.097668 },
    { lat: 48.647391, lng: 19.097703 },
    { lat: 48.647363, lng: 19.097788 },
    { lat: 48.64734, lng: 19.097946 },
    { lat: 48.6473, lng: 19.097992 },
    { lat: 48.647283, lng: 19.098014 },
    { lat: 48.647198, lng: 19.098006 },
    { lat: 48.647188, lng: 19.098251 },
    { lat: 48.647159, lng: 19.098301 },
    { lat: 48.647144, lng: 19.098353 },
    { lat: 48.647038, lng: 19.098481 },
    { lat: 48.647054, lng: 19.098602 },
    { lat: 48.647016, lng: 19.098635 },
    { lat: 48.646991, lng: 19.098677 },
    { lat: 48.646985, lng: 19.098778 },
    { lat: 48.646902, lng: 19.0988 },
    { lat: 48.646902, lng: 19.098897 },
    { lat: 48.646907, lng: 19.099033 },
    { lat: 48.646892, lng: 19.099094 },
    { lat: 48.646846, lng: 19.099261 },
    { lat: 48.646841, lng: 19.099262 },
    { lat: 48.646797, lng: 19.099232 },
    { lat: 48.646713, lng: 19.099246 },
    { lat: 48.646647, lng: 19.099395 },
    { lat: 48.646655, lng: 19.099544 },
    { lat: 48.646609, lng: 19.099552 },
    { lat: 48.646575, lng: 19.100035 },
    { lat: 48.646549, lng: 19.100093 },
    { lat: 48.646535, lng: 19.100227 },
    { lat: 48.646525, lng: 19.100242 },
    { lat: 48.646509, lng: 19.100177 },
    { lat: 48.646377, lng: 19.100125 },
    { lat: 48.64637, lng: 19.100191 },
    { lat: 48.646359, lng: 19.100228 },
    { lat: 48.646233, lng: 19.100365 },
    { lat: 48.646367, lng: 19.100483 },
    { lat: 48.646414, lng: 19.100763 },
    { lat: 48.646483, lng: 19.100793 },
    { lat: 48.64648, lng: 19.100815 },
    { lat: 48.646423, lng: 19.100895 },
    { lat: 48.646411, lng: 19.101065 },
    { lat: 48.646395, lng: 19.101122 },
    { lat: 48.646422, lng: 19.101174 },
    { lat: 48.646441, lng: 19.101256 },
    { lat: 48.646431, lng: 19.101336 },
    { lat: 48.646517, lng: 19.101457 },
    { lat: 48.646551, lng: 19.101542 },
    { lat: 48.646618, lng: 19.101604 },
    { lat: 48.646655, lng: 19.101734 },
    { lat: 48.646644, lng: 19.101852 },
    { lat: 48.646701, lng: 19.101921 },
    { lat: 48.646693, lng: 19.102022 },
    { lat: 48.646665, lng: 19.102095 },
    { lat: 48.646523, lng: 19.102297 },
    { lat: 48.646464, lng: 19.102507 },
    { lat: 48.646374, lng: 19.102626 },
    { lat: 48.646359, lng: 19.102934 },
    { lat: 48.646376, lng: 19.103008 },
    { lat: 48.646363, lng: 19.103089 },
    { lat: 48.646303, lng: 19.103188 },
    { lat: 48.646237, lng: 19.103144 },
    { lat: 48.646198, lng: 19.103304 },
    { lat: 48.646121, lng: 19.103405 },
    { lat: 48.64611, lng: 19.103463 },
    { lat: 48.646127, lng: 19.103538 },
    { lat: 48.646188, lng: 19.103594 },
    { lat: 48.646165, lng: 19.103661 },
    { lat: 48.646061, lng: 19.103665 },
    { lat: 48.645968, lng: 19.103781 },
    { lat: 48.645913, lng: 19.103761 },
    { lat: 48.64583, lng: 19.10378 },
    { lat: 48.645743, lng: 19.103975 },
    { lat: 48.645712, lng: 19.10407 },
    { lat: 48.645694, lng: 19.104176 },
    { lat: 48.645701, lng: 19.104273 },
    { lat: 48.645686, lng: 19.104405 },
    { lat: 48.645636, lng: 19.10452 },
    { lat: 48.645654, lng: 19.104639 },
    { lat: 48.64567, lng: 19.104668 },
    { lat: 48.645642, lng: 19.104738 },
    { lat: 48.645664, lng: 19.104854 },
    { lat: 48.645668, lng: 19.105018 },
    { lat: 48.645713, lng: 19.105187 },
    { lat: 48.645624, lng: 19.105371 },
    { lat: 48.645702, lng: 19.105554 },
    { lat: 48.645708, lng: 19.105656 },
    { lat: 48.645745, lng: 19.105702 },
    { lat: 48.645758, lng: 19.105776 },
    { lat: 48.64581, lng: 19.105838 },
    { lat: 48.645837, lng: 19.1059 },
    { lat: 48.645885, lng: 19.106002 },
    { lat: 48.64592, lng: 19.106169 },
    { lat: 48.64584, lng: 19.106297 },
    { lat: 48.64576, lng: 19.106404 },
    { lat: 48.645729, lng: 19.106779 },
    { lat: 48.64572, lng: 19.106899 },
    { lat: 48.64574, lng: 19.107214 },
    { lat: 48.645547, lng: 19.1072 },
    { lat: 48.645543, lng: 19.107354 },
    { lat: 48.645548, lng: 19.107483 },
    { lat: 48.645501, lng: 19.107661 },
    { lat: 48.645424, lng: 19.107743 },
    { lat: 48.645409, lng: 19.107833 },
    { lat: 48.645331, lng: 19.107985 },
    { lat: 48.645266, lng: 19.108065 },
    { lat: 48.645243, lng: 19.108298 },
    { lat: 48.645237, lng: 19.108415 },
    { lat: 48.64524, lng: 19.108635 },
    { lat: 48.645263, lng: 19.10881 },
    { lat: 48.64551, lng: 19.109289 },
    { lat: 48.645828, lng: 19.109863 },
    { lat: 48.646086, lng: 19.110313 },
    { lat: 48.646285, lng: 19.110675 },
    { lat: 48.646327, lng: 19.110792 },
    { lat: 48.646356, lng: 19.110929 },
    { lat: 48.646418, lng: 19.111476 },
    { lat: 48.646476, lng: 19.112054 },
    { lat: 48.646535, lng: 19.112637 },
    { lat: 48.646611, lng: 19.113418 },
    { lat: 48.646626, lng: 19.113625 },
    { lat: 48.646633, lng: 19.113819 },
    { lat: 48.646638, lng: 19.113988 },
    { lat: 48.646608, lng: 19.114249 },
    { lat: 48.646541, lng: 19.114447 },
    { lat: 48.646349, lng: 19.114921 },
    { lat: 48.646173, lng: 19.115385 },
    { lat: 48.646045, lng: 19.115724 },
    { lat: 48.645964, lng: 19.115957 },
    { lat: 48.645818, lng: 19.116366 },
    { lat: 48.645611, lng: 19.116771 },
    { lat: 48.645592, lng: 19.116826 },
    { lat: 48.645584, lng: 19.116837 },
    { lat: 48.645581, lng: 19.116844 },
    { lat: 48.645555, lng: 19.116901 },
    { lat: 48.645552, lng: 19.116908 },
    { lat: 48.645497, lng: 19.117007 },
    { lat: 48.644727, lng: 19.118649 },
    { lat: 48.64458, lng: 19.118916 },
    { lat: 48.644434, lng: 19.119149 },
    { lat: 48.644294, lng: 19.119401 },
    { lat: 48.643716, lng: 19.120333 },
    { lat: 48.643629, lng: 19.120417 },
    { lat: 48.643627, lng: 19.120435 },
    { lat: 48.6435879, lng: 19.1204799 },
    { lat: 48.643544, lng: 19.120554 },
    { lat: 48.643459, lng: 19.120675 },
    { lat: 48.643379, lng: 19.12079 },
    { lat: 48.643379, lng: 19.120808 },
    { lat: 48.643377, lng: 19.120901 },
    { lat: 48.643029, lng: 19.121465 },
    { lat: 48.642806, lng: 19.121797 },
    { lat: 48.642427, lng: 19.122434 },
    { lat: 48.642189, lng: 19.122812 },
    { lat: 48.642046, lng: 19.123016 },
    { lat: 48.641926, lng: 19.123161 },
    { lat: 48.641733, lng: 19.123299 },
    { lat: 48.640972, lng: 19.123727 },
    { lat: 48.639937, lng: 19.124315 },
    { lat: 48.639001, lng: 19.12483 },
    { lat: 48.638793, lng: 19.125006 },
    { lat: 48.638703, lng: 19.125103 },
    { lat: 48.638542, lng: 19.125269 },
    { lat: 48.638349, lng: 19.125516 },
    { lat: 48.638241, lng: 19.125673 },
    { lat: 48.637948, lng: 19.126134 },
    { lat: 48.637122, lng: 19.127402 },
    { lat: 48.636472, lng: 19.128411 },
    { lat: 48.635993, lng: 19.129108 },
    { lat: 48.635895, lng: 19.129243 },
    { lat: 48.635814, lng: 19.129331 },
    { lat: 48.635499, lng: 19.129629 },
    { lat: 48.635408, lng: 19.129715 },
    { lat: 48.635408, lng: 19.129742 },
    { lat: 48.635407, lng: 19.129783 },
    { lat: 48.635408, lng: 19.129848 },
    { lat: 48.635515, lng: 19.129854 },
    { lat: 48.638609, lng: 19.129948 },
    { lat: 48.639758, lng: 19.130028 },
    { lat: 48.643368, lng: 19.13025 },
    { lat: 48.644412, lng: 19.130317 },
    { lat: 48.645405, lng: 19.130361 },
    { lat: 48.646945, lng: 19.130435 },
    { lat: 48.647938, lng: 19.130528 },
    { lat: 48.648167, lng: 19.13053 },
    { lat: 48.648701, lng: 19.130891 },
    { lat: 48.648909, lng: 19.131142 },
    { lat: 48.648859, lng: 19.133098 },
    { lat: 48.648784, lng: 19.136082 },
    { lat: 48.648767, lng: 19.13678 },
    { lat: 48.648724, lng: 19.138153 },
    { lat: 48.646712, lng: 19.13799 },
    { lat: 48.646507, lng: 19.137975 },
    { lat: 48.645845, lng: 19.137929 },
    { lat: 48.6458, lng: 19.139999 },
    { lat: 48.64576, lng: 19.141569 },
    { lat: 48.645495, lng: 19.141501 },
    { lat: 48.645131, lng: 19.141459 },
    { lat: 48.644819, lng: 19.141453 },
    { lat: 48.644284, lng: 19.141428 },
    { lat: 48.6439, lng: 19.1414 },
    { lat: 48.643626, lng: 19.141373 },
    { lat: 48.643452, lng: 19.141342 },
    { lat: 48.643103, lng: 19.141266 },
    { lat: 48.643038, lng: 19.141249 },
    { lat: 48.642834, lng: 19.141171 },
    { lat: 48.642436, lng: 19.140986 },
    { lat: 48.642138, lng: 19.140852 },
    { lat: 48.641906, lng: 19.140845 },
    { lat: 48.641702, lng: 19.140855 },
    { lat: 48.641536, lng: 19.14091 },
    { lat: 48.641509, lng: 19.140969 },
    { lat: 48.641466, lng: 19.141064 },
    { lat: 48.641447, lng: 19.141105 },
    { lat: 48.641332, lng: 19.141357 },
    { lat: 48.64112, lng: 19.142385 },
    { lat: 48.64108, lng: 19.142537 },
    { lat: 48.641029, lng: 19.142538 },
    { lat: 48.640292, lng: 19.142561 },
    { lat: 48.640292, lng: 19.142528 },
    { lat: 48.63695, lng: 19.142706 },
    { lat: 48.636865, lng: 19.142656 },
    { lat: 48.635869, lng: 19.142736 },
    { lat: 48.63587, lng: 19.142915 },
    { lat: 48.635376, lng: 19.142958 },
    { lat: 48.635378, lng: 19.143149 },
    { lat: 48.635024, lng: 19.143156 },
    { lat: 48.633934, lng: 19.142968 },
    { lat: 48.633399, lng: 19.142581 },
    { lat: 48.632703, lng: 19.14214 },
    { lat: 48.63286, lng: 19.141261 },
    { lat: 48.63318, lng: 19.140368 },
    { lat: 48.632988, lng: 19.14022 },
    { lat: 48.63292, lng: 19.140168 },
    { lat: 48.632878, lng: 19.140137 },
    { lat: 48.632461, lng: 19.139816 },
    { lat: 48.631895, lng: 19.139523 },
    { lat: 48.631082, lng: 19.139317 },
    { lat: 48.630955, lng: 19.139337 },
    { lat: 48.630932, lng: 19.13934 },
    { lat: 48.63079, lng: 19.139433 },
    { lat: 48.630671, lng: 19.1395 },
    { lat: 48.630609, lng: 19.139485 },
    { lat: 48.630482, lng: 19.139497 },
    { lat: 48.630353, lng: 19.139442 },
    { lat: 48.630274, lng: 19.139423 },
    { lat: 48.630219, lng: 19.139502 },
    { lat: 48.630072, lng: 19.139592 },
    { lat: 48.629817, lng: 19.139933 },
    { lat: 48.629773, lng: 19.140088 },
    { lat: 48.629658, lng: 19.140291 },
    { lat: 48.629591, lng: 19.140469 },
    { lat: 48.629576, lng: 19.140554 },
    { lat: 48.62955, lng: 19.140657 },
    { lat: 48.629569, lng: 19.14078 },
    { lat: 48.629611, lng: 19.140827 },
    { lat: 48.629677, lng: 19.140855 },
    { lat: 48.629716, lng: 19.140966 },
    { lat: 48.629674, lng: 19.141016 },
    { lat: 48.629404, lng: 19.141338 },
    { lat: 48.629375, lng: 19.141372 },
    { lat: 48.629723, lng: 19.141457 },
    { lat: 48.6298944, lng: 19.1415511 },
    { lat: 48.63008, lng: 19.141653 },
    { lat: 48.630586, lng: 19.141973 },
    { lat: 48.630822, lng: 19.142277 },
    { lat: 48.631116, lng: 19.142606 },
    { lat: 48.631282, lng: 19.142893 },
    { lat: 48.631524, lng: 19.143476 },
    { lat: 48.631841, lng: 19.144512 },
    { lat: 48.631948, lng: 19.144553 },
    { lat: 48.632269, lng: 19.14468 },
    { lat: 48.632323, lng: 19.1447 },
    { lat: 48.632841, lng: 19.144903 },
    { lat: 48.633132, lng: 19.145016 },
    { lat: 48.633496, lng: 19.145623 },
    { lat: 48.633982, lng: 19.145732 },
    { lat: 48.634027, lng: 19.14641 },
    { lat: 48.63387, lng: 19.148599 },
    { lat: 48.633859, lng: 19.148745 },
    { lat: 48.633814, lng: 19.149381 },
    { lat: 48.633805, lng: 19.149504 },
    { lat: 48.634066, lng: 19.149804 },
    { lat: 48.634225, lng: 19.149932 },
    { lat: 48.634357, lng: 19.15003 },
    { lat: 48.634962, lng: 19.150376 },
    { lat: 48.635489, lng: 19.150491 },
    { lat: 48.635871, lng: 19.150479 },
    { lat: 48.636254, lng: 19.150364 },
    { lat: 48.636282, lng: 19.150275 },
    { lat: 48.636459, lng: 19.149698 },
    { lat: 48.636486, lng: 19.149612 },
    { lat: 48.636826, lng: 19.148504 },
    { lat: 48.637314, lng: 19.147864 },
    { lat: 48.638245, lng: 19.146951 },
    { lat: 48.638432, lng: 19.146883 },
    { lat: 48.639583, lng: 19.146754 },
    { lat: 48.64015, lng: 19.1466 },
    { lat: 48.640098, lng: 19.14713 },
    { lat: 48.640135, lng: 19.147609 },
    { lat: 48.640188, lng: 19.148056 },
    { lat: 48.640188, lng: 19.148573 },
    { lat: 48.640188, lng: 19.148628 },
    { lat: 48.64018, lng: 19.148654 },
    { lat: 48.640166, lng: 19.148706 },
    { lat: 48.640156, lng: 19.148737 },
    { lat: 48.640103, lng: 19.148922 },
    { lat: 48.6427489, lng: 19.1504103 },
    { lat: 48.642994, lng: 19.1495744 },
    { lat: 48.6433865, lng: 19.1493232 },
    { lat: 48.6437898, lng: 19.1494341 },
    { lat: 48.6440053, lng: 19.1499631 },
    { lat: 48.6441869, lng: 19.1502404 },
    { lat: 48.6449465, lng: 19.1510617 },
    { lat: 48.6452537, lng: 19.151276 },
    { lat: 48.6460895, lng: 19.1524553 },
    { lat: 48.6465115, lng: 19.1526938 },
    { lat: 48.647172, lng: 19.1527563 },
    { lat: 48.6472014, lng: 19.1530132 },
    { lat: 48.6483494, lng: 19.1534773 },
    { lat: 48.6484616, lng: 19.1537634 },
    { lat: 48.6489483, lng: 19.1536458 },
    { lat: 48.6498532, lng: 19.1531118 },
    { lat: 48.65067, lng: 19.1528041 },
    { lat: 48.6513462, lng: 19.1524324 },
    { lat: 48.6517361, lng: 19.1521098 },
    { lat: 48.6519883, lng: 19.1520355 },
    { lat: 48.6533505, lng: 19.1521583 },
    { lat: 48.6533848, lng: 19.1517123 },
    { lat: 48.6541941, lng: 19.1516643 },
    { lat: 48.65471, lng: 19.151104 },
    { lat: 48.6548172, lng: 19.1510642 },
    { lat: 48.6550954, lng: 19.151125 },
    { lat: 48.6554979, lng: 19.1510156 },
    { lat: 48.6556351, lng: 19.1506872 },
    { lat: 48.6558347, lng: 19.1505286 },
    { lat: 48.6558108, lng: 19.1504086 },
    { lat: 48.6560483, lng: 19.1502867 },
    { lat: 48.6564602, lng: 19.1489473 },
    { lat: 48.6566787, lng: 19.1502705 },
    { lat: 48.6567707, lng: 19.1505123 },
  ],
  Harmanec: [
    { lat: 48.7634651, lng: 19.0441406 },
    { lat: 48.7658705, lng: 19.0448055 },
    { lat: 48.7682441, lng: 19.0454681 },
    { lat: 48.7681685, lng: 19.0457827 },
    { lat: 48.7679467, lng: 19.0461715 },
    { lat: 48.767892, lng: 19.0467207 },
    { lat: 48.7677616, lng: 19.0472259 },
    { lat: 48.7677135, lng: 19.0476803 },
    { lat: 48.7677822, lng: 19.0477682 },
    { lat: 48.7679414, lng: 19.0477068 },
    { lat: 48.7680948, lng: 19.0474158 },
    { lat: 48.7682644, lng: 19.0472924 },
    { lat: 48.7682572, lng: 19.0474647 },
    { lat: 48.7681771, lng: 19.0476866 },
    { lat: 48.7682651, lng: 19.047892 },
    { lat: 48.7683576, lng: 19.0479132 },
    { lat: 48.7683732, lng: 19.0477999 },
    { lat: 48.768532, lng: 19.0478196 },
    { lat: 48.7686719, lng: 19.0480234 },
    { lat: 48.7690454, lng: 19.0479152 },
    { lat: 48.7692252, lng: 19.0479489 },
    { lat: 48.7689266, lng: 19.0489684 },
    { lat: 48.7685226, lng: 19.0498614 },
    { lat: 48.7685293, lng: 19.0500604 },
    { lat: 48.7687904, lng: 19.0502313 },
    { lat: 48.7685929, lng: 19.0503404 },
    { lat: 48.768629, lng: 19.0505475 },
    { lat: 48.7689458, lng: 19.0510188 },
    { lat: 48.7693446, lng: 19.0510335 },
    { lat: 48.7693491, lng: 19.0511012 },
    { lat: 48.7694992, lng: 19.0511302 },
    { lat: 48.7710749, lng: 19.0543462 },
    { lat: 48.7711854, lng: 19.0555838 },
    { lat: 48.7721601, lng: 19.0560702 },
    { lat: 48.7725071, lng: 19.0567886 },
    { lat: 48.7727955, lng: 19.0581385 },
    { lat: 48.7728632, lng: 19.0589986 },
    { lat: 48.775717, lng: 19.0654192 },
    { lat: 48.7762126, lng: 19.0657272 },
    { lat: 48.7763043, lng: 19.0668409 },
    { lat: 48.7760282, lng: 19.0670265 },
    { lat: 48.7760609, lng: 19.0674971 },
    { lat: 48.7763648, lng: 19.0679206 },
    { lat: 48.7762922, lng: 19.0680894 },
    { lat: 48.7767991, lng: 19.0694856 },
    { lat: 48.7774899, lng: 19.0701134 },
    { lat: 48.7778142, lng: 19.0702873 },
    { lat: 48.7780457, lng: 19.0706098 },
    { lat: 48.7786521, lng: 19.0711846 },
    { lat: 48.7787484, lng: 19.0712759 },
    { lat: 48.7790487, lng: 19.0714817 },
    { lat: 48.77931, lng: 19.0716608 },
    { lat: 48.7794599, lng: 19.0715687 },
    { lat: 48.7799574, lng: 19.0718955 },
    { lat: 48.7802659, lng: 19.0719096 },
    { lat: 48.7805505, lng: 19.0721213 },
    { lat: 48.780625, lng: 19.0724642 },
    { lat: 48.780778, lng: 19.0725479 },
    { lat: 48.7809101, lng: 19.0724406 },
    { lat: 48.7815515, lng: 19.0726391 },
    { lat: 48.7818487, lng: 19.0729487 },
    { lat: 48.7818963, lng: 19.0732489 },
    { lat: 48.7821117, lng: 19.0733624 },
    { lat: 48.7822021, lng: 19.0735265 },
    { lat: 48.7822324, lng: 19.0734791 },
    { lat: 48.7824432, lng: 19.0737946 },
    { lat: 48.7828257, lng: 19.0737571 },
    { lat: 48.7828451, lng: 19.0733309 },
    { lat: 48.7831291, lng: 19.0734404 },
    { lat: 48.7834378, lng: 19.0733698 },
    { lat: 48.7836001, lng: 19.0732544 },
    { lat: 48.78369, lng: 19.0734326 },
    { lat: 48.783567, lng: 19.0736761 },
    { lat: 48.7836377, lng: 19.0739683 },
    { lat: 48.7834971, lng: 19.0743558 },
    { lat: 48.7832017, lng: 19.0744721 },
    { lat: 48.7832217, lng: 19.0753238 },
    { lat: 48.7831476, lng: 19.0763926 },
    { lat: 48.7835311, lng: 19.0768983 },
    { lat: 48.7842384, lng: 19.081321 },
    { lat: 48.7847119, lng: 19.0816853 },
    { lat: 48.7850622, lng: 19.0822242 },
    { lat: 48.785249, lng: 19.0826237 },
    { lat: 48.7854404, lng: 19.0831188 },
    { lat: 48.7856259, lng: 19.0838545 },
    { lat: 48.7862851, lng: 19.085957 },
    { lat: 48.7865122, lng: 19.0864925 },
    { lat: 48.7865627, lng: 19.0870988 },
    { lat: 48.7865802, lng: 19.0885629 },
    { lat: 48.7867068, lng: 19.0904994 },
    { lat: 48.7867274, lng: 19.0923654 },
    { lat: 48.7868073, lng: 19.0925222 },
    { lat: 48.7868796, lng: 19.0929801 },
    { lat: 48.7868018, lng: 19.0931827 },
    { lat: 48.7867368, lng: 19.0931912 },
    { lat: 48.7865242, lng: 19.0935396 },
    { lat: 48.7864821, lng: 19.0939176 },
    { lat: 48.7863999, lng: 19.0939899 },
    { lat: 48.7882285, lng: 19.0985123 },
    { lat: 48.7888856, lng: 19.0993286 },
    { lat: 48.7890023, lng: 19.0993861 },
    { lat: 48.7891777, lng: 19.1003315 },
    { lat: 48.7893296, lng: 19.1006653 },
    { lat: 48.7894868, lng: 19.1005628 },
    { lat: 48.7897083, lng: 19.100804 },
    { lat: 48.7898421, lng: 19.1011395 },
    { lat: 48.7899218, lng: 19.1015416 },
    { lat: 48.7901916, lng: 19.1016186 },
    { lat: 48.7901892, lng: 19.1018909 },
    { lat: 48.7904034, lng: 19.1014308 },
    { lat: 48.790801, lng: 19.0999173 },
    { lat: 48.7908159, lng: 19.0998605 },
    { lat: 48.7912256, lng: 19.098666 },
    { lat: 48.7915293, lng: 19.096901 },
    { lat: 48.7921074, lng: 19.0961935 },
    { lat: 48.7922248, lng: 19.0961312 },
    { lat: 48.7922868, lng: 19.0959722 },
    { lat: 48.7922327, lng: 19.0958092 },
    { lat: 48.7923254, lng: 19.0956596 },
    { lat: 48.7927252, lng: 19.0944239 },
    { lat: 48.7934294, lng: 19.0931429 },
    { lat: 48.7935538, lng: 19.0927179 },
    { lat: 48.7937601, lng: 19.0926054 },
    { lat: 48.7943818, lng: 19.0918904 },
    { lat: 48.7949305, lng: 19.0914089 },
    { lat: 48.7953946, lng: 19.0901755 },
    { lat: 48.7955436, lng: 19.0893844 },
    { lat: 48.7957339, lng: 19.0887688 },
    { lat: 48.7964512, lng: 19.0869347 },
    { lat: 48.7967863, lng: 19.0862629 },
    { lat: 48.7969562, lng: 19.0854602 },
    { lat: 48.7972296, lng: 19.0844979 },
    { lat: 48.7974397, lng: 19.0841436 },
    { lat: 48.7979224, lng: 19.0836695 },
    { lat: 48.798449, lng: 19.0835126 },
    { lat: 48.7990907, lng: 19.0829972 },
    { lat: 48.7992839, lng: 19.0827595 },
    { lat: 48.7998127, lng: 19.0829189 },
    { lat: 48.8001276, lng: 19.0828805 },
    { lat: 48.800811, lng: 19.0825909 },
    { lat: 48.8013752, lng: 19.0815379 },
    { lat: 48.8021446, lng: 19.0805251 },
    { lat: 48.8022749, lng: 19.0798404 },
    { lat: 48.8023354, lng: 19.0796497 },
    { lat: 48.8024749, lng: 19.0794382 },
    { lat: 48.8024275, lng: 19.0789193 },
    { lat: 48.802171, lng: 19.077817 },
    { lat: 48.802248, lng: 19.0772328 },
    { lat: 48.80262, lng: 19.0765546 },
    { lat: 48.8033045, lng: 19.0746827 },
    { lat: 48.8044375, lng: 19.0724144 },
    { lat: 48.8045348, lng: 19.0715854 },
    { lat: 48.8048462, lng: 19.070258 },
    { lat: 48.8051824, lng: 19.0695383 },
    { lat: 48.8054741, lng: 19.0686506 },
    { lat: 48.8046595, lng: 19.0682508 },
    { lat: 48.7977478, lng: 19.0648588 },
    { lat: 48.7977214, lng: 19.0646248 },
    { lat: 48.7978626, lng: 19.0643616 },
    { lat: 48.7978972, lng: 19.0640136 },
    { lat: 48.7977827, lng: 19.0639587 },
    { lat: 48.7975788, lng: 19.0641379 },
    { lat: 48.7975602, lng: 19.0645141 },
    { lat: 48.7974083, lng: 19.0646792 },
    { lat: 48.7963788, lng: 19.0645147 },
    { lat: 48.7965917, lng: 19.0630178 },
    { lat: 48.796722, lng: 19.062468 },
    { lat: 48.7966435, lng: 19.0622302 },
    { lat: 48.7903309, lng: 19.0578836 },
    { lat: 48.790264, lng: 19.0576506 },
    { lat: 48.7902324, lng: 19.0574015 },
    { lat: 48.7901137, lng: 19.0568737 },
    { lat: 48.7899558, lng: 19.056422 },
    { lat: 48.789485, lng: 19.056045 },
    { lat: 48.7889183, lng: 19.0558517 },
    { lat: 48.7885095, lng: 19.0560158 },
    { lat: 48.7879872, lng: 19.0554488 },
    { lat: 48.7876992, lng: 19.0552354 },
    { lat: 48.7873035, lng: 19.0550737 },
    { lat: 48.7862422, lng: 19.0550393 },
    { lat: 48.7860182, lng: 19.0550478 },
    { lat: 48.7857721, lng: 19.0551753 },
    { lat: 48.7855139, lng: 19.0551294 },
    { lat: 48.7854603, lng: 19.0549822 },
    { lat: 48.7851684, lng: 19.0546213 },
    { lat: 48.7850918, lng: 19.0543744 },
    { lat: 48.7850822, lng: 19.0536616 },
    { lat: 48.7853139, lng: 19.0530891 },
    { lat: 48.7852229, lng: 19.0512684 },
    { lat: 48.785103, lng: 19.0510347 },
    { lat: 48.7848134, lng: 19.0499586 },
    { lat: 48.7847022, lng: 19.04972 },
    { lat: 48.7849587, lng: 19.0479235 },
    { lat: 48.7849574, lng: 19.0476523 },
    { lat: 48.7851061, lng: 19.0464801 },
    { lat: 48.7850751, lng: 19.0463806 },
    { lat: 48.7849221, lng: 19.0458899 },
    { lat: 48.7848615, lng: 19.0451507 },
    { lat: 48.7848001, lng: 19.0449393 },
    { lat: 48.784663, lng: 19.0447448 },
    { lat: 48.7846699, lng: 19.0445653 },
    { lat: 48.7844964, lng: 19.0440968 },
    { lat: 48.7839971, lng: 19.0434673 },
    { lat: 48.7837376, lng: 19.0434173 },
    { lat: 48.7835305, lng: 19.0431551 },
    { lat: 48.7834249, lng: 19.0431833 },
    { lat: 48.7832721, lng: 19.0430142 },
    { lat: 48.7831166, lng: 19.0429512 },
    { lat: 48.7829643, lng: 19.0429923 },
    { lat: 48.7827473, lng: 19.0431641 },
    { lat: 48.7825595, lng: 19.0431263 },
    { lat: 48.7824837, lng: 19.0428722 },
    { lat: 48.7821549, lng: 19.0423215 },
    { lat: 48.7812178, lng: 19.0422702 },
    { lat: 48.7809516, lng: 19.0424226 },
    { lat: 48.7805136, lng: 19.0424786 },
    { lat: 48.7803738, lng: 19.0425929 },
    { lat: 48.7800969, lng: 19.0426686 },
    { lat: 48.7798742, lng: 19.0431078 },
    { lat: 48.7798111, lng: 19.0430828 },
    { lat: 48.779495, lng: 19.043706 },
    { lat: 48.7794614, lng: 19.0439784 },
    { lat: 48.7793582, lng: 19.0440127 },
    { lat: 48.7791746, lng: 19.0438364 },
    { lat: 48.7790794, lng: 19.0435117 },
    { lat: 48.7789759, lng: 19.0433613 },
    { lat: 48.7788392, lng: 19.0437591 },
    { lat: 48.7785951, lng: 19.0437238 },
    { lat: 48.7780296, lng: 19.0422912 },
    { lat: 48.7779103, lng: 19.0424518 },
    { lat: 48.7778109, lng: 19.0422687 },
    { lat: 48.7767304, lng: 19.0419441 },
    { lat: 48.7766592, lng: 19.0418303 },
    { lat: 48.7758146, lng: 19.0418235 },
    { lat: 48.7754804, lng: 19.041745 },
    { lat: 48.7752434, lng: 19.042235 },
    { lat: 48.7747506, lng: 19.0424624 },
    { lat: 48.7746424, lng: 19.0424154 },
    { lat: 48.7745152, lng: 19.0419693 },
    { lat: 48.7745169, lng: 19.0418476 },
    { lat: 48.7744283, lng: 19.0417859 },
    { lat: 48.7742576, lng: 19.0417322 },
    { lat: 48.7741174, lng: 19.0417722 },
    { lat: 48.7738871, lng: 19.0419648 },
    { lat: 48.7737572, lng: 19.041623 },
    { lat: 48.7734418, lng: 19.0412925 },
    { lat: 48.773261, lng: 19.0412614 },
    { lat: 48.772964, lng: 19.0409126 },
    { lat: 48.772571, lng: 19.0407397 },
    { lat: 48.7725323, lng: 19.0403542 },
    { lat: 48.7729875, lng: 19.0396275 },
    { lat: 48.7730721, lng: 19.0394248 },
    { lat: 48.7730963, lng: 19.0390967 },
    { lat: 48.7727911, lng: 19.039239 },
    { lat: 48.7725528, lng: 19.0394332 },
    { lat: 48.7722435, lng: 19.0395366 },
    { lat: 48.7718928, lng: 19.0394493 },
    { lat: 48.7714214, lng: 19.039197 },
    { lat: 48.7706901, lng: 19.0389785 },
    { lat: 48.770534, lng: 19.0390604 },
    { lat: 48.7704284, lng: 19.0390447 },
    { lat: 48.7700029, lng: 19.0393279 },
    { lat: 48.7697642, lng: 19.0392638 },
    { lat: 48.769099, lng: 19.0392651 },
    { lat: 48.7687868, lng: 19.0393566 },
    { lat: 48.7685882, lng: 19.0393079 },
    { lat: 48.7683843, lng: 19.0393549 },
    { lat: 48.7681352, lng: 19.0396258 },
    { lat: 48.7674406, lng: 19.0399631 },
    { lat: 48.7669627, lng: 19.0406609 },
    { lat: 48.7668827, lng: 19.0406822 },
    { lat: 48.7662006, lng: 19.0413358 },
    { lat: 48.7660228, lng: 19.0416578 },
    { lat: 48.7656548, lng: 19.0416446 },
    { lat: 48.765401, lng: 19.0420071 },
    { lat: 48.7649091, lng: 19.0422894 },
    { lat: 48.7649473, lng: 19.042424 },
    { lat: 48.7648748, lng: 19.0426425 },
    { lat: 48.7647627, lng: 19.0427668 },
    { lat: 48.7643774, lng: 19.0429736 },
    { lat: 48.7643324, lng: 19.0432682 },
    { lat: 48.7641865, lng: 19.0433285 },
    { lat: 48.7640809, lng: 19.0432656 },
    { lat: 48.7639394, lng: 19.0434126 },
    { lat: 48.7636846, lng: 19.043886 },
    { lat: 48.7635517, lng: 19.0438894 },
    { lat: 48.7634651, lng: 19.0441406 },
  ],
  Vlkanová: [
    { lat: 48.6567707, lng: 19.1505123 },
    { lat: 48.6567789, lng: 19.1505392 },
    { lat: 48.6569969, lng: 19.1512335 },
    { lat: 48.6573129, lng: 19.1532804 },
    { lat: 48.6566593, lng: 19.1536275 },
    { lat: 48.6572677, lng: 19.1556598 },
    { lat: 48.6569852, lng: 19.1558512 },
    { lat: 48.6568955, lng: 19.1569627 },
    { lat: 48.6572653, lng: 19.1579246 },
    { lat: 48.6563938, lng: 19.1603776 },
    { lat: 48.6563075, lng: 19.1614185 },
    { lat: 48.6562881, lng: 19.1631082 },
    { lat: 48.6563298, lng: 19.1635435 },
    { lat: 48.6563844, lng: 19.1635197 },
    { lat: 48.6564324, lng: 19.1634967 },
    { lat: 48.6565959, lng: 19.1641188 },
    { lat: 48.66036, lng: 19.1675162 },
    { lat: 48.670219, lng: 19.1680682 },
    { lat: 48.6704873, lng: 19.1851304 },
    { lat: 48.6675554, lng: 19.1813582 },
    { lat: 48.6671617, lng: 19.1822227 },
    { lat: 48.6669022, lng: 19.1830936 },
    { lat: 48.6665663, lng: 19.1848921 },
    { lat: 48.6651809, lng: 19.1868036 },
    { lat: 48.6636466, lng: 19.1901634 },
    { lat: 48.6635601, lng: 19.1907935 },
    { lat: 48.6635702, lng: 19.1915509 },
    { lat: 48.6636985, lng: 19.1920796 },
    { lat: 48.6637377, lng: 19.1929971 },
    { lat: 48.6639341, lng: 19.1935785 },
    { lat: 48.6638144, lng: 19.1938943 },
    { lat: 48.6631908, lng: 19.1945121 },
    { lat: 48.6635473, lng: 19.1948668 },
    { lat: 48.6636412, lng: 19.1951263 },
    { lat: 48.6635524, lng: 19.1954546 },
    { lat: 48.6638403, lng: 19.1963516 },
    { lat: 48.664043, lng: 19.1969833 },
    { lat: 48.6659776, lng: 19.1957212 },
    { lat: 48.666077, lng: 19.1956695 },
    { lat: 48.6667659, lng: 19.1953111 },
    { lat: 48.6677141, lng: 19.1944181 },
    { lat: 48.6680942, lng: 19.1942338 },
    { lat: 48.6684352, lng: 19.1935992 },
    { lat: 48.6688179, lng: 19.1932905 },
    { lat: 48.6692595, lng: 19.1932392 },
    { lat: 48.6698472, lng: 19.1933855 },
    { lat: 48.6698669, lng: 19.1934407 },
    { lat: 48.6709716, lng: 19.1929357 },
    { lat: 48.6713216, lng: 19.1925083 },
    { lat: 48.6715727, lng: 19.1919917 },
    { lat: 48.672071, lng: 19.1914208 },
    { lat: 48.6723515, lng: 19.1912068 },
    { lat: 48.672483, lng: 19.1911064 },
    { lat: 48.6737348, lng: 19.1917169 },
    { lat: 48.6738207, lng: 19.1911695 },
    { lat: 48.6740195, lng: 19.1906034 },
    { lat: 48.6746368, lng: 19.1894639 },
    { lat: 48.6750763, lng: 19.1888638 },
    { lat: 48.6753857, lng: 19.1880903 },
    { lat: 48.6759699, lng: 19.1862267 },
    { lat: 48.6762656, lng: 19.1857532 },
    { lat: 48.6767486, lng: 19.1849799 },
    { lat: 48.677091, lng: 19.1843054 },
    { lat: 48.6782961, lng: 19.1824177 },
    { lat: 48.6804994, lng: 19.1786338 },
    { lat: 48.6805433, lng: 19.1785847 },
    { lat: 48.6814425, lng: 19.1775664 },
    { lat: 48.6821744, lng: 19.176469 },
    { lat: 48.6840846, lng: 19.1731907 },
    { lat: 48.6843834, lng: 19.1710575 },
    { lat: 48.6846836, lng: 19.1710845 },
    { lat: 48.6849007, lng: 19.1713549 },
    { lat: 48.6854359, lng: 19.1725205 },
    { lat: 48.6858961, lng: 19.1731458 },
    { lat: 48.6867512, lng: 19.1738739 },
    { lat: 48.6867519, lng: 19.174095 },
    { lat: 48.6868478, lng: 19.1740711 },
    { lat: 48.6868288, lng: 19.1736644 },
    { lat: 48.6873959, lng: 19.172906 },
    { lat: 48.6878136, lng: 19.1725278 },
    { lat: 48.6884831, lng: 19.1725243 },
    { lat: 48.6889063, lng: 19.1719143 },
    { lat: 48.6898808, lng: 19.1719462 },
    { lat: 48.6910804, lng: 19.1725266 },
    { lat: 48.6910141, lng: 19.1730806 },
    { lat: 48.6925901, lng: 19.1739337 },
    { lat: 48.6929808, lng: 19.1738312 },
    { lat: 48.6933663, lng: 19.1734412 },
    { lat: 48.6946167, lng: 19.1728025 },
    { lat: 48.693423, lng: 19.1718613 },
    { lat: 48.693613, lng: 19.1714404 },
    { lat: 48.6929539, lng: 19.170919 },
    { lat: 48.692466, lng: 19.1699767 },
    { lat: 48.6925989, lng: 19.1698152 },
    { lat: 48.6930276, lng: 19.1695696 },
    { lat: 48.692713, lng: 19.1690414 },
    { lat: 48.6924548, lng: 19.1687866 },
    { lat: 48.6919631, lng: 19.168436 },
    { lat: 48.6917363, lng: 19.168156 },
    { lat: 48.6922511, lng: 19.1671382 },
    { lat: 48.6920462, lng: 19.1668896 },
    { lat: 48.6927236, lng: 19.1662011 },
    { lat: 48.6927061, lng: 19.1660264 },
    { lat: 48.6920531, lng: 19.1667336 },
    { lat: 48.6912188, lng: 19.1673736 },
    { lat: 48.6912464, lng: 19.1667419 },
    { lat: 48.6906991, lng: 19.1673062 },
    { lat: 48.6901619, lng: 19.1655917 },
    { lat: 48.6901, lng: 19.1656204 },
    { lat: 48.6898871, lng: 19.164955 },
    { lat: 48.6896913, lng: 19.1648931 },
    { lat: 48.6882266, lng: 19.1650257 },
    { lat: 48.6880731, lng: 19.1644007 },
    { lat: 48.6874736, lng: 19.1645881 },
    { lat: 48.6869731, lng: 19.1646089 },
    { lat: 48.6863863, lng: 19.1644591 },
    { lat: 48.6862295, lng: 19.1640128 },
    { lat: 48.6860898, lng: 19.1636152 },
    { lat: 48.686065, lng: 19.1635447 },
    { lat: 48.6860165, lng: 19.1632729 },
    { lat: 48.6859952, lng: 19.1631537 },
    { lat: 48.6856391, lng: 19.1624922 },
    { lat: 48.6855773, lng: 19.1622876 },
    { lat: 48.6854847, lng: 19.1615512 },
    { lat: 48.6853102, lng: 19.1609379 },
    { lat: 48.685416, lng: 19.1598547 },
    { lat: 48.6853941, lng: 19.1596569 },
    { lat: 48.6853145, lng: 19.1589392 },
    { lat: 48.6852308, lng: 19.1586869 },
    { lat: 48.6847787, lng: 19.1573236 },
    { lat: 48.6844562, lng: 19.1564958 },
    { lat: 48.6843973, lng: 19.1563763 },
    { lat: 48.6840555, lng: 19.1556824 },
    { lat: 48.6838686, lng: 19.1548421 },
    { lat: 48.6834146, lng: 19.1539658 },
    { lat: 48.6830665, lng: 19.153294 },
    { lat: 48.682924, lng: 19.1529535 },
    { lat: 48.682733, lng: 19.152497 },
    { lat: 48.6824709, lng: 19.1521143 },
    { lat: 48.6822677, lng: 19.1518175 },
    { lat: 48.6819094, lng: 19.1514297 },
    { lat: 48.6815928, lng: 19.1510871 },
    { lat: 48.6814069, lng: 19.1507965 },
    { lat: 48.6813382, lng: 19.1505424 },
    { lat: 48.6812324, lng: 19.1494491 },
    { lat: 48.680528, lng: 19.1486486 },
    { lat: 48.6802009, lng: 19.1486928 },
    { lat: 48.6806767, lng: 19.147984 },
    { lat: 48.6808018, lng: 19.1475947 },
    { lat: 48.6800161, lng: 19.1464765 },
    { lat: 48.6806843, lng: 19.1456694 },
    { lat: 48.6818768, lng: 19.1465807 },
    { lat: 48.6826224, lng: 19.1469317 },
    { lat: 48.682747, lng: 19.1466241 },
    { lat: 48.6818523, lng: 19.1454411 },
    { lat: 48.6819186, lng: 19.1453256 },
    { lat: 48.6817552, lng: 19.1452294 },
    { lat: 48.6814446, lng: 19.1447617 },
    { lat: 48.6815349, lng: 19.1443078 },
    { lat: 48.6816497, lng: 19.1440541 },
    { lat: 48.6816168, lng: 19.1426101 },
    { lat: 48.680518, lng: 19.1427747 },
    { lat: 48.6800913, lng: 19.1427631 },
    { lat: 48.6793965, lng: 19.1417279 },
    { lat: 48.6790695, lng: 19.142024 },
    { lat: 48.6789816, lng: 19.1419993 },
    { lat: 48.6783131, lng: 19.1423873 },
    { lat: 48.6782994, lng: 19.1422922 },
    { lat: 48.6785578, lng: 19.141453 },
    { lat: 48.6789458, lng: 19.1407042 },
    { lat: 48.6794232, lng: 19.1399809 },
    { lat: 48.679948, lng: 19.1393086 },
    { lat: 48.680814, lng: 19.1377147 },
    { lat: 48.6806295, lng: 19.1373784 },
    { lat: 48.6804039, lng: 19.1376752 },
    { lat: 48.6787676, lng: 19.139831 },
    { lat: 48.678556, lng: 19.1401134 },
    { lat: 48.678026, lng: 19.1411404 },
    { lat: 48.6771978, lng: 19.1439806 },
    { lat: 48.6768885, lng: 19.1445872 },
    { lat: 48.6762895, lng: 19.1452455 },
    { lat: 48.6755524, lng: 19.145771 },
    { lat: 48.6753482, lng: 19.14586 },
    { lat: 48.6747032, lng: 19.1458094 },
    { lat: 48.6740569, lng: 19.145485 },
    { lat: 48.6735057, lng: 19.1448696 },
    { lat: 48.67304, lng: 19.1442254 },
    { lat: 48.6724364, lng: 19.1435535 },
    { lat: 48.6719537, lng: 19.1431116 },
    { lat: 48.6713555, lng: 19.1427791 },
    { lat: 48.6708573, lng: 19.1428026 },
    { lat: 48.6703776, lng: 19.1430195 },
    { lat: 48.6694655, lng: 19.1436553 },
    { lat: 48.6689597, lng: 19.1441683 },
    { lat: 48.6684608, lng: 19.1449537 },
    { lat: 48.6679178, lng: 19.1460582 },
    { lat: 48.667597, lng: 19.1465019 },
    { lat: 48.6672399, lng: 19.1468298 },
    { lat: 48.6666471, lng: 19.147196 },
    { lat: 48.6664953, lng: 19.1471845 },
    { lat: 48.6647323, lng: 19.1485112 },
    { lat: 48.6641482, lng: 19.1488728 },
    { lat: 48.6625625, lng: 19.1495016 },
    { lat: 48.6617694, lng: 19.1495564 },
    { lat: 48.6614042, lng: 19.1495332 },
    { lat: 48.6612917, lng: 19.1487171 },
    { lat: 48.6606134, lng: 19.1481254 },
    { lat: 48.660182, lng: 19.147588 },
    { lat: 48.6599715, lng: 19.1466335 },
    { lat: 48.6598817, lng: 19.1464785 },
    { lat: 48.6594717, lng: 19.1462129 },
    { lat: 48.6593001, lng: 19.1462297 },
    { lat: 48.6587385, lng: 19.1459252 },
    { lat: 48.6587426, lng: 19.1462381 },
    { lat: 48.658563, lng: 19.1460693 },
    { lat: 48.6582705, lng: 19.1459674 },
    { lat: 48.6578776, lng: 19.146024 },
    { lat: 48.6575469, lng: 19.1462926 },
    { lat: 48.6573897, lng: 19.1465548 },
    { lat: 48.6572803, lng: 19.1469219 },
    { lat: 48.6570824, lng: 19.1483465 },
    { lat: 48.6568537, lng: 19.1494486 },
    { lat: 48.6568538, lng: 19.1499785 },
    { lat: 48.6567707, lng: 19.1505123 },
  ],
  StaréHory: [
    { lat: 48.8218208, lng: 19.1580079 },
    { lat: 48.8220589, lng: 19.1579199 },
    { lat: 48.8223286, lng: 19.1579976 },
    { lat: 48.8226127, lng: 19.1577646 },
    { lat: 48.8231815, lng: 19.1575948 },
    { lat: 48.8235455, lng: 19.1570408 },
    { lat: 48.8240141, lng: 19.1568965 },
    { lat: 48.8256432, lng: 19.1571565 },
    { lat: 48.8260676, lng: 19.1570537 },
    { lat: 48.8265539, lng: 19.1572212 },
    { lat: 48.8270289, lng: 19.157625 },
    { lat: 48.827388, lng: 19.157776 },
    { lat: 48.8282574, lng: 19.1583318 },
    { lat: 48.8287799, lng: 19.1580444 },
    { lat: 48.8290706, lng: 19.1581094 },
    { lat: 48.8291125, lng: 19.1583902 },
    { lat: 48.8290885, lng: 19.1587972 },
    { lat: 48.828934, lng: 19.1593716 },
    { lat: 48.8289562, lng: 19.1612264 },
    { lat: 48.8287714, lng: 19.1623573 },
    { lat: 48.8288591, lng: 19.1632272 },
    { lat: 48.8288654, lng: 19.1638833 },
    { lat: 48.8288717, lng: 19.1645421 },
    { lat: 48.8291545, lng: 19.166106 },
    { lat: 48.8293907, lng: 19.166809 },
    { lat: 48.8308739, lng: 19.1680031 },
    { lat: 48.8312792, lng: 19.1679933 },
    { lat: 48.8319662, lng: 19.1682124 },
    { lat: 48.8323878, lng: 19.168236 },
    { lat: 48.8326367, lng: 19.1684864 },
    { lat: 48.833129, lng: 19.1684526 },
    { lat: 48.8335315, lng: 19.1685498 },
    { lat: 48.8343173, lng: 19.1684608 },
    { lat: 48.8347097, lng: 19.1685782 },
    { lat: 48.8352317, lng: 19.1688523 },
    { lat: 48.8357496, lng: 19.1692819 },
    { lat: 48.8364076, lng: 19.1700138 },
    { lat: 48.8364337, lng: 19.1707069 },
    { lat: 48.8366144, lng: 19.1711514 },
    { lat: 48.8373581, lng: 19.1724381 },
    { lat: 48.8377673, lng: 19.1727604 },
    { lat: 48.8381684, lng: 19.1731705 },
    { lat: 48.8384812, lng: 19.1735784 },
    { lat: 48.8387102, lng: 19.1741114 },
    { lat: 48.8393092, lng: 19.1746962 },
    { lat: 48.8400708, lng: 19.1752881 },
    { lat: 48.8405484, lng: 19.1758812 },
    { lat: 48.8410418, lng: 19.1761275 },
    { lat: 48.8418401, lng: 19.1766883 },
    { lat: 48.8421647, lng: 19.1763165 },
    { lat: 48.8423768, lng: 19.176228 },
    { lat: 48.8432063, lng: 19.1755115 },
    { lat: 48.8435233, lng: 19.175078 },
    { lat: 48.8435837, lng: 19.1748587 },
    { lat: 48.8441041, lng: 19.1743665 },
    { lat: 48.8448984, lng: 19.1739192 },
    { lat: 48.8452843, lng: 19.1738125 },
    { lat: 48.8456167, lng: 19.1734421 },
    { lat: 48.8461339, lng: 19.173025 },
    { lat: 48.846381, lng: 19.1727064 },
    { lat: 48.8465173, lng: 19.1724248 },
    { lat: 48.8469016, lng: 19.1720338 },
    { lat: 48.8474125, lng: 19.1719024 },
    { lat: 48.8474825, lng: 19.1716976 },
    { lat: 48.848091, lng: 19.1710932 },
    { lat: 48.8484413, lng: 19.1705828 },
    { lat: 48.8489599, lng: 19.1692469 },
    { lat: 48.849066, lng: 19.1693388 },
    { lat: 48.8500258, lng: 19.1681772 },
    { lat: 48.8502909, lng: 19.1677394 },
    { lat: 48.8505667, lng: 19.1670656 },
    { lat: 48.851005, lng: 19.1663492 },
    { lat: 48.8522311, lng: 19.1650749 },
    { lat: 48.8527351, lng: 19.1643389 },
    { lat: 48.8529937, lng: 19.1640729 },
    { lat: 48.8532569, lng: 19.1639485 },
    { lat: 48.853564, lng: 19.1634286 },
    { lat: 48.8535207, lng: 19.1631657 },
    { lat: 48.8536532, lng: 19.1629485 },
    { lat: 48.8538394, lng: 19.1629262 },
    { lat: 48.8539891, lng: 19.1622342 },
    { lat: 48.8540112, lng: 19.1618559 },
    { lat: 48.8541121, lng: 19.1614766 },
    { lat: 48.8540809, lng: 19.1611626 },
    { lat: 48.8544132, lng: 19.1602586 },
    { lat: 48.8543546, lng: 19.1601202 },
    { lat: 48.8543953, lng: 19.1598704 },
    { lat: 48.8550548, lng: 19.1592485 },
    { lat: 48.8551468, lng: 19.1579961 },
    { lat: 48.8585551, lng: 19.15383 },
    { lat: 48.8588444, lng: 19.1537999 },
    { lat: 48.8588717, lng: 19.1537102 },
    { lat: 48.8587603, lng: 19.1531639 },
    { lat: 48.8588712, lng: 19.1531274 },
    { lat: 48.8588602, lng: 19.1530614 },
    { lat: 48.8589986, lng: 19.1529642 },
    { lat: 48.859051, lng: 19.153114 },
    { lat: 48.85928, lng: 19.153439 },
    { lat: 48.8597263, lng: 19.1546793 },
    { lat: 48.8598467, lng: 19.1547692 },
    { lat: 48.8599094, lng: 19.1549401 },
    { lat: 48.8599287, lng: 19.1552662 },
    { lat: 48.8601292, lng: 19.1554484 },
    { lat: 48.8601484, lng: 19.1555644 },
    { lat: 48.8603542, lng: 19.1558072 },
    { lat: 48.860641, lng: 19.1559528 },
    { lat: 48.8607398, lng: 19.1570297 },
    { lat: 48.860654, lng: 19.1570712 },
    { lat: 48.8606773, lng: 19.1575712 },
    { lat: 48.8609817, lng: 19.1580514 },
    { lat: 48.861225, lng: 19.1581619 },
    { lat: 48.8617648, lng: 19.1591519 },
    { lat: 48.8625424, lng: 19.1611487 },
    { lat: 48.8629389, lng: 19.1613505 },
    { lat: 48.8629046, lng: 19.1614356 },
    { lat: 48.8629706, lng: 19.1615155 },
    { lat: 48.8630903, lng: 19.1615658 },
    { lat: 48.863206, lng: 19.161488 },
    { lat: 48.8633375, lng: 19.1616665 },
    { lat: 48.8633279, lng: 19.1618369 },
    { lat: 48.8632099, lng: 19.1621234 },
    { lat: 48.8630225, lng: 19.1620009 },
    { lat: 48.8638029, lng: 19.1629274 },
    { lat: 48.864247, lng: 19.1632015 },
    { lat: 48.8643609, lng: 19.1633924 },
    { lat: 48.8645989, lng: 19.1635768 },
    { lat: 48.8653898, lng: 19.1638438 },
    { lat: 48.8659033, lng: 19.1638838 },
    { lat: 48.866611, lng: 19.1638547 },
    { lat: 48.8668143, lng: 19.163815 },
    { lat: 48.8671057, lng: 19.163644 },
    { lat: 48.8671998, lng: 19.1636557 },
    { lat: 48.868072, lng: 19.1645025 },
    { lat: 48.8688637, lng: 19.1649255 },
    { lat: 48.8696538, lng: 19.1650841 },
    { lat: 48.8697924, lng: 19.1651763 },
    { lat: 48.8701345, lng: 19.1656448 },
    { lat: 48.8703786, lng: 19.1656622 },
    { lat: 48.8706291, lng: 19.1655044 },
    { lat: 48.8710982, lng: 19.1647219 },
    { lat: 48.8715663, lng: 19.1641568 },
    { lat: 48.8720487, lng: 19.1639207 },
    { lat: 48.8722707, lng: 19.1638775 },
    { lat: 48.8730146, lng: 19.1640133 },
    { lat: 48.8737658, lng: 19.1646662 },
    { lat: 48.8740414, lng: 19.1647967 },
    { lat: 48.8752588, lng: 19.1649069 },
    { lat: 48.8758825, lng: 19.1651948 },
    { lat: 48.8759757, lng: 19.1651396 },
    { lat: 48.8765626, lng: 19.1651753 },
    { lat: 48.8766598, lng: 19.1652761 },
    { lat: 48.8770418, lng: 19.1654108 },
    { lat: 48.8771181, lng: 19.1655186 },
    { lat: 48.8771834, lng: 19.1654959 },
    { lat: 48.8774154, lng: 19.1657917 },
    { lat: 48.8779664, lng: 19.1659683 },
    { lat: 48.8782947, lng: 19.1662057 },
    { lat: 48.8787056, lng: 19.1662895 },
    { lat: 48.8787415, lng: 19.1663771 },
    { lat: 48.8789142, lng: 19.1664748 },
    { lat: 48.8789729, lng: 19.1664521 },
    { lat: 48.8790436, lng: 19.1665362 },
    { lat: 48.8795452, lng: 19.1666976 },
    { lat: 48.8797348, lng: 19.1666333 },
    { lat: 48.8800077, lng: 19.1666603 },
    { lat: 48.8801007, lng: 19.1665563 },
    { lat: 48.8806807, lng: 19.1665248 },
    { lat: 48.881796, lng: 19.1656355 },
    { lat: 48.8820795, lng: 19.1655295 },
    { lat: 48.8823708, lng: 19.1659566 },
    { lat: 48.8829541, lng: 19.1664261 },
    { lat: 48.8833003, lng: 19.1666375 },
    { lat: 48.884096, lng: 19.16686 },
    { lat: 48.884346, lng: 19.165161 },
    { lat: 48.884301, lng: 19.164357 },
    { lat: 48.884313, lng: 19.163608 },
    { lat: 48.883639, lng: 19.162111 },
    { lat: 48.88341, lng: 19.161761 },
    { lat: 48.883135, lng: 19.161116 },
    { lat: 48.882972, lng: 19.160694 },
    { lat: 48.882727, lng: 19.160788 },
    { lat: 48.882056, lng: 19.160779 },
    { lat: 48.881769, lng: 19.160618 },
    { lat: 48.881199, lng: 19.160044 },
    { lat: 48.880751, lng: 19.15983 },
    { lat: 48.880349, lng: 19.159417 },
    { lat: 48.880045, lng: 19.159035 },
    { lat: 48.879736, lng: 19.158815 },
    { lat: 48.879668, lng: 19.158734 },
    { lat: 48.87928, lng: 19.15827 },
    { lat: 48.879157, lng: 19.158148 },
    { lat: 48.879016, lng: 19.157972 },
    { lat: 48.878716, lng: 19.157714 },
    { lat: 48.878653, lng: 19.156887 },
    { lat: 48.878652, lng: 19.156589 },
    { lat: 48.8786601, lng: 19.1565177 },
    { lat: 48.8786824, lng: 19.1564073 },
    { lat: 48.878687, lng: 19.156281 },
    { lat: 48.878682, lng: 19.155879 },
    { lat: 48.878436, lng: 19.154799 },
    { lat: 48.878222, lng: 19.154422 },
    { lat: 48.877889, lng: 19.153655 },
    { lat: 48.877999, lng: 19.153362 },
    { lat: 48.878147, lng: 19.152972 },
    { lat: 48.87846, lng: 19.152408 },
    { lat: 48.878639, lng: 19.151796 },
    { lat: 48.87873, lng: 19.151327 },
    { lat: 48.878929, lng: 19.150976 },
    { lat: 48.880103, lng: 19.150254 },
    { lat: 48.881195, lng: 19.14877 },
    { lat: 48.881303, lng: 19.14845 },
    { lat: 48.881709, lng: 19.147549 },
    { lat: 48.882099, lng: 19.145824 },
    { lat: 48.882066, lng: 19.144324 },
    { lat: 48.882057, lng: 19.143915 },
    { lat: 48.88199, lng: 19.143642 },
    { lat: 48.881893, lng: 19.142905 },
    { lat: 48.881867, lng: 19.14225 },
    { lat: 48.881795, lng: 19.141393 },
    { lat: 48.881747, lng: 19.13968 },
    { lat: 48.881693, lng: 19.13879 },
    { lat: 48.881662, lng: 19.138572 },
    { lat: 48.881668, lng: 19.138433 },
    { lat: 48.881687, lng: 19.138005 },
    { lat: 48.881681, lng: 19.13765 },
    { lat: 48.881633, lng: 19.137394 },
    { lat: 48.881264, lng: 19.136764 },
    { lat: 48.880991, lng: 19.136498 },
    { lat: 48.880402, lng: 19.136014 },
    { lat: 48.880453, lng: 19.135633 },
    { lat: 48.88049, lng: 19.135354 },
    { lat: 48.880628, lng: 19.134493 },
    { lat: 48.881072, lng: 19.133539 },
    { lat: 48.881358, lng: 19.13288 },
    { lat: 48.881394, lng: 19.131999 },
    { lat: 48.88129, lng: 19.131217 },
    { lat: 48.88127, lng: 19.131067 },
    { lat: 48.880811, lng: 19.130306 },
    { lat: 48.880495, lng: 19.129758 },
    { lat: 48.879893, lng: 19.128714 },
    { lat: 48.87978, lng: 19.128039 },
    { lat: 48.879778, lng: 19.127756 },
    { lat: 48.879778, lng: 19.127651 },
    { lat: 48.87977, lng: 19.126375 },
    { lat: 48.879769, lng: 19.126293 },
    { lat: 48.879765, lng: 19.125492 },
    { lat: 48.879982, lng: 19.124704 },
    { lat: 48.879994, lng: 19.124352 },
    { lat: 48.879949, lng: 19.123993 },
    { lat: 48.879737, lng: 19.123309 },
    { lat: 48.879582, lng: 19.122558 },
    { lat: 48.879479, lng: 19.122403 },
    { lat: 48.879181, lng: 19.121473 },
    { lat: 48.879424, lng: 19.120805 },
    { lat: 48.879428, lng: 19.120678 },
    { lat: 48.879432, lng: 19.120551 },
    { lat: 48.879593, lng: 19.120213 },
    { lat: 48.880009, lng: 19.119754 },
    { lat: 48.880329, lng: 19.119472 },
    { lat: 48.880383, lng: 19.118857 },
    { lat: 48.880428, lng: 19.118341 },
    { lat: 48.88039, lng: 19.118212 },
    { lat: 48.880279, lng: 19.1176 },
    { lat: 48.880262, lng: 19.117053 },
    { lat: 48.88026, lng: 19.116446 },
    { lat: 48.880229, lng: 19.116275 },
    { lat: 48.880252, lng: 19.116179 },
    { lat: 48.880195, lng: 19.115633 },
    { lat: 48.880122, lng: 19.11496 },
    { lat: 48.88, lng: 19.11407 },
    { lat: 48.879799, lng: 19.113539 },
    { lat: 48.879746, lng: 19.113287 },
    { lat: 48.879754, lng: 19.113176 },
    { lat: 48.879776, lng: 19.112828 },
    { lat: 48.879879, lng: 19.111944 },
    { lat: 48.87984, lng: 19.110412 },
    { lat: 48.879838, lng: 19.110317 },
    { lat: 48.879732, lng: 19.109795 },
    { lat: 48.879199, lng: 19.107736 },
    { lat: 48.879011, lng: 19.107243 },
    { lat: 48.879055, lng: 19.106765 },
    { lat: 48.879054, lng: 19.106476 },
    { lat: 48.879058, lng: 19.106333 },
    { lat: 48.879075, lng: 19.105746 },
    { lat: 48.879222, lng: 19.104108 },
    { lat: 48.878905, lng: 19.103853 },
    { lat: 48.878373, lng: 19.102964 },
    { lat: 48.878262, lng: 19.1028 },
    { lat: 48.878075, lng: 19.102345 },
    { lat: 48.878028, lng: 19.102248 },
    { lat: 48.877627, lng: 19.101416 },
    { lat: 48.877255, lng: 19.100506 },
    { lat: 48.877187, lng: 19.100219 },
    { lat: 48.877025, lng: 19.099885 },
    { lat: 48.876976, lng: 19.099431 },
    { lat: 48.876938, lng: 19.099072 },
    { lat: 48.876828, lng: 19.098284 },
    { lat: 48.876798, lng: 19.097828 },
    { lat: 48.87689, lng: 19.097008 },
    { lat: 48.876904, lng: 19.096901 },
    { lat: 48.876974, lng: 19.09636 },
    { lat: 48.876952, lng: 19.096164 },
    { lat: 48.87697, lng: 19.095932 },
    { lat: 48.876943, lng: 19.095679 },
    { lat: 48.876905, lng: 19.095276 },
    { lat: 48.876919, lng: 19.09482 },
    { lat: 48.876952, lng: 19.094521 },
    { lat: 48.876959, lng: 19.094458 },
    { lat: 48.877142, lng: 19.092763 },
    { lat: 48.877161, lng: 19.092712 },
    { lat: 48.877282, lng: 19.0924 },
    { lat: 48.877604, lng: 19.091562 },
    { lat: 48.87759, lng: 19.091493 },
    { lat: 48.877365, lng: 19.090464 },
    { lat: 48.877037, lng: 19.088955 },
    { lat: 48.877022, lng: 19.088891 },
    { lat: 48.875725, lng: 19.086395 },
    { lat: 48.87502, lng: 19.085143 },
    { lat: 48.874741, lng: 19.083532 },
    { lat: 48.874675, lng: 19.083113 },
    { lat: 48.874631, lng: 19.082223 },
    { lat: 48.874669, lng: 19.081708 },
    { lat: 48.874618, lng: 19.081041 },
    { lat: 48.8742714, lng: 19.0810929 },
    { lat: 48.8733686, lng: 19.0816654 },
    { lat: 48.8725971, lng: 19.0819039 },
    { lat: 48.872668, lng: 19.0820838 },
    { lat: 48.8720132, lng: 19.0823608 },
    { lat: 48.871672, lng: 19.082664 },
    { lat: 48.8706407, lng: 19.0832547 },
    { lat: 48.8704993, lng: 19.0835423 },
    { lat: 48.8700283, lng: 19.0837532 },
    { lat: 48.8696149, lng: 19.0843465 },
    { lat: 48.8690691, lng: 19.0848216 },
    { lat: 48.8682012, lng: 19.0859586 },
    { lat: 48.8678009, lng: 19.0873643 },
    { lat: 48.866713, lng: 19.0902497 },
    { lat: 48.8650419, lng: 19.0967923 },
    { lat: 48.8643733, lng: 19.097292 },
    { lat: 48.8638244, lng: 19.0973831 },
    { lat: 48.8600728, lng: 19.0969757 },
    { lat: 48.8594924, lng: 19.097868 },
    { lat: 48.8592818, lng: 19.0978934 },
    { lat: 48.8593217, lng: 19.0983113 },
    { lat: 48.8593756, lng: 19.0988769 },
    { lat: 48.8591509, lng: 19.0996432 },
    { lat: 48.8596797, lng: 19.1003055 },
    { lat: 48.8601375, lng: 19.1006247 },
    { lat: 48.8595756, lng: 19.1015042 },
    { lat: 48.8595614, lng: 19.1018334 },
    { lat: 48.8592568, lng: 19.1018977 },
    { lat: 48.8592698, lng: 19.1020129 },
    { lat: 48.859293, lng: 19.102219 },
    { lat: 48.8592173, lng: 19.1023257 },
    { lat: 48.8586789, lng: 19.1020386 },
    { lat: 48.8584167, lng: 19.1020723 },
    { lat: 48.8576486, lng: 19.1017927 },
    { lat: 48.8573178, lng: 19.1018253 },
    { lat: 48.8557067, lng: 19.1024158 },
    { lat: 48.855424, lng: 19.1028972 },
    { lat: 48.8547987, lng: 19.1031802 },
    { lat: 48.8542526, lng: 19.1032809 },
    { lat: 48.8526704, lng: 19.1039299 },
    { lat: 48.8522159, lng: 19.1040399 },
    { lat: 48.8518535, lng: 19.1040056 },
    { lat: 48.8507923, lng: 19.1041329 },
    { lat: 48.8497544, lng: 19.1040282 },
    { lat: 48.8491118, lng: 19.1038209 },
    { lat: 48.8462135, lng: 19.1019776 },
    { lat: 48.8455173, lng: 19.1014386 },
    { lat: 48.8447301, lng: 19.1019081 },
    { lat: 48.8442578, lng: 19.102708 },
    { lat: 48.8441202, lng: 19.1025666 },
    { lat: 48.8440123, lng: 19.1025785 },
    { lat: 48.8397257, lng: 19.0981021 },
    { lat: 48.8401559, lng: 19.0884992 },
    { lat: 48.8400663, lng: 19.0877604 },
    { lat: 48.8394083, lng: 19.0865194 },
    { lat: 48.8394569, lng: 19.0857963 },
    { lat: 48.8391904, lng: 19.082657 },
    { lat: 48.8392938, lng: 19.0818328 },
    { lat: 48.8395342, lng: 19.0809991 },
    { lat: 48.8398352, lng: 19.0804108 },
    { lat: 48.8397883, lng: 19.080147 },
    { lat: 48.8392692, lng: 19.0802259 },
    { lat: 48.8383458, lng: 19.0800868 },
    { lat: 48.837867, lng: 19.0797087 },
    { lat: 48.8373766, lng: 19.0796605 },
    { lat: 48.8369713, lng: 19.0797386 },
    { lat: 48.8368276, lng: 19.0797088 },
    { lat: 48.8364517, lng: 19.0795835 },
    { lat: 48.8354788, lng: 19.0791051 },
    { lat: 48.8346194, lng: 19.0789398 },
    { lat: 48.8340227, lng: 19.0787072 },
    { lat: 48.8321216, lng: 19.0776116 },
    { lat: 48.8316617, lng: 19.0771559 },
    { lat: 48.8306018, lng: 19.0768582 },
    { lat: 48.8300567, lng: 19.0769841 },
    { lat: 48.8289457, lng: 19.0769277 },
    { lat: 48.8287376, lng: 19.0770706 },
    { lat: 48.8289323, lng: 19.0776741 },
    { lat: 48.828383, lng: 19.0780331 },
    { lat: 48.827821, lng: 19.0785179 },
    { lat: 48.8273455, lng: 19.0782287 },
    { lat: 48.8264913, lng: 19.078119 },
    { lat: 48.8260608, lng: 19.0781931 },
    { lat: 48.8254285, lng: 19.078423 },
    { lat: 48.8243558, lng: 19.0791357 },
    { lat: 48.8238036, lng: 19.0785264 },
    { lat: 48.8232676, lng: 19.0781736 },
    { lat: 48.8227481, lng: 19.0779553 },
    { lat: 48.8220618, lng: 19.0768962 },
    { lat: 48.8216224, lng: 19.0767374 },
    { lat: 48.8212753, lng: 19.0762676 },
    { lat: 48.8207451, lng: 19.0757857 },
    { lat: 48.8205985, lng: 19.0757831 },
    { lat: 48.8199358, lng: 19.0753533 },
    { lat: 48.8191774, lng: 19.0752484 },
    { lat: 48.8186889, lng: 19.0754666 },
    { lat: 48.8186534, lng: 19.0751605 },
    { lat: 48.8182955, lng: 19.0743821 },
    { lat: 48.8172681, lng: 19.0730584 },
    { lat: 48.816811, lng: 19.0727311 },
    { lat: 48.8168172, lng: 19.0722002 },
    { lat: 48.8164034, lng: 19.0712913 },
    { lat: 48.8162136, lng: 19.0711477 },
    { lat: 48.8157798, lng: 19.0700214 },
    { lat: 48.8155994, lng: 19.0692976 },
    { lat: 48.8153072, lng: 19.0685254 },
    { lat: 48.8150488, lng: 19.0681793 },
    { lat: 48.814873, lng: 19.0680656 },
    { lat: 48.814624, lng: 19.0676623 },
    { lat: 48.8142444, lng: 19.0676488 },
    { lat: 48.8140278, lng: 19.0675544 },
    { lat: 48.8136391, lng: 19.066946 },
    { lat: 48.8134557, lng: 19.066816 },
    { lat: 48.8134615, lng: 19.0670693 },
    { lat: 48.8131437, lng: 19.0666421 },
    { lat: 48.8129407, lng: 19.0665279 },
    { lat: 48.8128078, lng: 19.066552 },
    { lat: 48.8124043, lng: 19.0661031 },
    { lat: 48.8121397, lng: 19.0662203 },
    { lat: 48.8114648, lng: 19.0670457 },
    { lat: 48.8109983, lng: 19.0672313 },
    { lat: 48.809743, lng: 19.0675113 },
    { lat: 48.8089465, lng: 19.0680863 },
    { lat: 48.8087324, lng: 19.0682044 },
    { lat: 48.808669, lng: 19.0681544 },
    { lat: 48.8080792, lng: 19.0688949 },
    { lat: 48.8071925, lng: 19.068837 },
    { lat: 48.8069547, lng: 19.0686589 },
    { lat: 48.806676, lng: 19.0686553 },
    { lat: 48.806243, lng: 19.0684219 },
    { lat: 48.8058124, lng: 19.0684468 },
    { lat: 48.8054741, lng: 19.0686506 },
    { lat: 48.8051824, lng: 19.0695383 },
    { lat: 48.8048462, lng: 19.070258 },
    { lat: 48.8045348, lng: 19.0715854 },
    { lat: 48.8044375, lng: 19.0724144 },
    { lat: 48.8033045, lng: 19.0746827 },
    { lat: 48.80262, lng: 19.0765546 },
    { lat: 48.802248, lng: 19.0772328 },
    { lat: 48.802171, lng: 19.077817 },
    { lat: 48.8024275, lng: 19.0789193 },
    { lat: 48.8024749, lng: 19.0794382 },
    { lat: 48.8023354, lng: 19.0796497 },
    { lat: 48.8022749, lng: 19.0798404 },
    { lat: 48.8021446, lng: 19.0805251 },
    { lat: 48.8013752, lng: 19.0815379 },
    { lat: 48.800811, lng: 19.0825909 },
    { lat: 48.8001276, lng: 19.0828805 },
    { lat: 48.7998127, lng: 19.0829189 },
    { lat: 48.7992839, lng: 19.0827595 },
    { lat: 48.7990907, lng: 19.0829972 },
    { lat: 48.798449, lng: 19.0835126 },
    { lat: 48.7979224, lng: 19.0836695 },
    { lat: 48.7974397, lng: 19.0841436 },
    { lat: 48.7972296, lng: 19.0844979 },
    { lat: 48.7969562, lng: 19.0854602 },
    { lat: 48.7967863, lng: 19.0862629 },
    { lat: 48.7964512, lng: 19.0869347 },
    { lat: 48.7957339, lng: 19.0887688 },
    { lat: 48.7955436, lng: 19.0893844 },
    { lat: 48.7953946, lng: 19.0901755 },
    { lat: 48.7949305, lng: 19.0914089 },
    { lat: 48.7943818, lng: 19.0918904 },
    { lat: 48.7937601, lng: 19.0926054 },
    { lat: 48.7935538, lng: 19.0927179 },
    { lat: 48.7934294, lng: 19.0931429 },
    { lat: 48.7927252, lng: 19.0944239 },
    { lat: 48.7923254, lng: 19.0956596 },
    { lat: 48.7922327, lng: 19.0958092 },
    { lat: 48.7922868, lng: 19.0959722 },
    { lat: 48.7922248, lng: 19.0961312 },
    { lat: 48.7921074, lng: 19.0961935 },
    { lat: 48.7915293, lng: 19.096901 },
    { lat: 48.7912256, lng: 19.098666 },
    { lat: 48.7908159, lng: 19.0998605 },
    { lat: 48.790801, lng: 19.0999173 },
    { lat: 48.7904034, lng: 19.1014308 },
    { lat: 48.7901892, lng: 19.1018909 },
    { lat: 48.7905462, lng: 19.1023588 },
    { lat: 48.7905073, lng: 19.1024176 },
    { lat: 48.7905777, lng: 19.102426 },
    { lat: 48.7910806, lng: 19.1018362 },
    { lat: 48.7914191, lng: 19.1016151 },
    { lat: 48.7917513, lng: 19.1012649 },
    { lat: 48.7921136, lng: 19.1010017 },
    { lat: 48.7923799, lng: 19.1009175 },
    { lat: 48.7926201, lng: 19.1007157 },
    { lat: 48.7927109, lng: 19.1003837 },
    { lat: 48.7929446, lng: 19.1000518 },
    { lat: 48.7937538, lng: 19.0995015 },
    { lat: 48.7943569, lng: 19.0995449 },
    { lat: 48.7946367, lng: 19.0991888 },
    { lat: 48.7948544, lng: 19.0991684 },
    { lat: 48.7953229, lng: 19.0994316 },
    { lat: 48.7957801, lng: 19.099444 },
    { lat: 48.7962303, lng: 19.099566 },
    { lat: 48.7971458, lng: 19.0994531 },
    { lat: 48.7975821, lng: 19.0992247 },
    { lat: 48.7979773, lng: 19.0987146 },
    { lat: 48.7982741, lng: 19.0986083 },
    { lat: 48.7987603, lng: 19.0982756 },
    { lat: 48.7995191, lng: 19.0981425 },
    { lat: 48.8001146, lng: 19.0984171 },
    { lat: 48.8002801, lng: 19.0985742 },
    { lat: 48.8004993, lng: 19.0986298 },
    { lat: 48.8008265, lng: 19.0986595 },
    { lat: 48.8013914, lng: 19.0985224 },
    { lat: 48.8019248, lng: 19.0985987 },
    { lat: 48.8022268, lng: 19.0987776 },
    { lat: 48.8031367, lng: 19.0988237 },
    { lat: 48.8035784, lng: 19.0987084 },
    { lat: 48.8036507, lng: 19.0986659 },
    { lat: 48.8036913, lng: 19.0986423 },
    { lat: 48.8046059, lng: 19.0982873 },
    { lat: 48.80479, lng: 19.0981334 },
    { lat: 48.8052133, lng: 19.097532 },
    { lat: 48.8057645, lng: 19.0971672 },
    { lat: 48.8067547, lng: 19.09679 },
    { lat: 48.8074974, lng: 19.0970804 },
    { lat: 48.8078087, lng: 19.0970164 },
    { lat: 48.8080142, lng: 19.0970882 },
    { lat: 48.8081877, lng: 19.0970416 },
    { lat: 48.8087979, lng: 19.0965249 },
    { lat: 48.8090247, lng: 19.096439 },
    { lat: 48.8097143, lng: 19.0965677 },
    { lat: 48.8100623, lng: 19.0967889 },
    { lat: 48.8104034, lng: 19.0968746 },
    { lat: 48.8110632, lng: 19.0972406 },
    { lat: 48.8115362, lng: 19.097596 },
    { lat: 48.8121852, lng: 19.0979297 },
    { lat: 48.8124256, lng: 19.0979574 },
    { lat: 48.8123508, lng: 19.0980396 },
    { lat: 48.8123533, lng: 19.098138 },
    { lat: 48.8117293, lng: 19.098264 },
    { lat: 48.8114742, lng: 19.0985742 },
    { lat: 48.8121011, lng: 19.0998932 },
    { lat: 48.8125734, lng: 19.1003063 },
    { lat: 48.8128765, lng: 19.100391 },
    { lat: 48.8135967, lng: 19.1012535 },
    { lat: 48.8144612, lng: 19.1013424 },
    { lat: 48.814439, lng: 19.101576 },
    { lat: 48.8148688, lng: 19.1015357 },
    { lat: 48.8149836, lng: 19.1018067 },
    { lat: 48.8158434, lng: 19.1016876 },
    { lat: 48.815855, lng: 19.1013525 },
    { lat: 48.8163205, lng: 19.101236 },
    { lat: 48.8163309, lng: 19.1011751 },
    { lat: 48.8167836, lng: 19.1010246 },
    { lat: 48.8169601, lng: 19.1008829 },
    { lat: 48.8172662, lng: 19.1008478 },
    { lat: 48.8175656, lng: 19.1007014 },
    { lat: 48.8178819, lng: 19.1004201 },
    { lat: 48.818169, lng: 19.1002977 },
    { lat: 48.8185377, lng: 19.1002966 },
    { lat: 48.8188435, lng: 19.1004436 },
    { lat: 48.819052, lng: 19.100417 },
    { lat: 48.8192132, lng: 19.1005107 },
    { lat: 48.8195027, lng: 19.1004123 },
    { lat: 48.8199986, lng: 19.1003889 },
    { lat: 48.8207876, lng: 19.1000649 },
    { lat: 48.8213144, lng: 19.0995268 },
    { lat: 48.8217073, lng: 19.0993383 },
    { lat: 48.8226716, lng: 19.0990949 },
    { lat: 48.82293, lng: 19.0990566 },
    { lat: 48.8232843, lng: 19.0991759 },
    { lat: 48.8235345, lng: 19.0991292 },
    { lat: 48.8237186, lng: 19.0991835 },
    { lat: 48.8238858, lng: 19.099372 },
    { lat: 48.8247316, lng: 19.1000382 },
    { lat: 48.8254882, lng: 19.1002228 },
    { lat: 48.8257923, lng: 19.1001721 },
    { lat: 48.8257184, lng: 19.1009125 },
    { lat: 48.8257267, lng: 19.1015559 },
    { lat: 48.8257719, lng: 19.1018298 },
    { lat: 48.8256755, lng: 19.1027313 },
    { lat: 48.8253984, lng: 19.1036665 },
    { lat: 48.824841, lng: 19.1050696 },
    { lat: 48.8246423, lng: 19.1060664 },
    { lat: 48.824402, lng: 19.1077454 },
    { lat: 48.8242703, lng: 19.1081579 },
    { lat: 48.8241257, lng: 19.1092229 },
    { lat: 48.8237863, lng: 19.1099969 },
    { lat: 48.8237822, lng: 19.1105308 },
    { lat: 48.824087, lng: 19.1117033 },
    { lat: 48.8240858, lng: 19.1122029 },
    { lat: 48.8237572, lng: 19.1143588 },
    { lat: 48.823554, lng: 19.1149453 },
    { lat: 48.8232515, lng: 19.1154593 },
    { lat: 48.8227907, lng: 19.1167232 },
    { lat: 48.8227323, lng: 19.1169992 },
    { lat: 48.8224447, lng: 19.1175884 },
    { lat: 48.8222813, lng: 19.1182981 },
    { lat: 48.822037, lng: 19.1191053 },
    { lat: 48.8219276, lng: 19.1193055 },
    { lat: 48.8219405, lng: 19.1197305 },
    { lat: 48.8217094, lng: 19.1211929 },
    { lat: 48.8215539, lng: 19.1217618 },
    { lat: 48.8213611, lng: 19.1221285 },
    { lat: 48.8214023, lng: 19.1228246 },
    { lat: 48.8213731, lng: 19.1234686 },
    { lat: 48.8212573, lng: 19.1240888 },
    { lat: 48.8224986, lng: 19.124802 },
    { lat: 48.8227447, lng: 19.1248117 },
    { lat: 48.8228817, lng: 19.1247046 },
    { lat: 48.8234603, lng: 19.1252638 },
    { lat: 48.8234989, lng: 19.1254032 },
    { lat: 48.8234714, lng: 19.125875 },
    { lat: 48.823419, lng: 19.1260258 },
    { lat: 48.8231856, lng: 19.1262071 },
    { lat: 48.82305, lng: 19.1267469 },
    { lat: 48.8229009, lng: 19.1268568 },
    { lat: 48.8227436, lng: 19.1270964 },
    { lat: 48.8227137, lng: 19.127128 },
    { lat: 48.8226474, lng: 19.1269513 },
    { lat: 48.8225613, lng: 19.126898 },
    { lat: 48.8225007, lng: 19.127087 },
    { lat: 48.8222245, lng: 19.1274248 },
    { lat: 48.8216925, lng: 19.1284104 },
    { lat: 48.8215545, lng: 19.1285627 },
    { lat: 48.8213397, lng: 19.1285709 },
    { lat: 48.8211208, lng: 19.1287272 },
    { lat: 48.8194936, lng: 19.1310046 },
    { lat: 48.8180284, lng: 19.13243 },
    { lat: 48.8181471, lng: 19.1357504 },
    { lat: 48.8183878, lng: 19.1364758 },
    { lat: 48.8183354, lng: 19.1366238 },
    { lat: 48.8183652, lng: 19.136933 },
    { lat: 48.8182931, lng: 19.1375511 },
    { lat: 48.8181466, lng: 19.1380777 },
    { lat: 48.8178332, lng: 19.138626 },
    { lat: 48.8177655, lng: 19.1389917 },
    { lat: 48.8178068, lng: 19.1390972 },
    { lat: 48.8178114, lng: 19.1393546 },
    { lat: 48.8179389, lng: 19.1394912 },
    { lat: 48.8179634, lng: 19.1398078 },
    { lat: 48.8177395, lng: 19.1399533 },
    { lat: 48.8173938, lng: 19.1398685 },
    { lat: 48.8164802, lng: 19.13915 },
    { lat: 48.8161424, lng: 19.1397733 },
    { lat: 48.8160987, lng: 19.1401477 },
    { lat: 48.8161219, lng: 19.1405292 },
    { lat: 48.8164028, lng: 19.1417194 },
    { lat: 48.8164459, lng: 19.142117 },
    { lat: 48.8164055, lng: 19.1424664 },
    { lat: 48.8162595, lng: 19.143029 },
    { lat: 48.8160875, lng: 19.1444355 },
    { lat: 48.8160943, lng: 19.14666 },
    { lat: 48.8161989, lng: 19.1468181 },
    { lat: 48.816667, lng: 19.1471903 },
    { lat: 48.8169948, lng: 19.1478233 },
    { lat: 48.8175793, lng: 19.1479327 },
    { lat: 48.8177559, lng: 19.1480842 },
    { lat: 48.8179056, lng: 19.1483531 },
    { lat: 48.8181872, lng: 19.1515865 },
    { lat: 48.8180343, lng: 19.1520065 },
    { lat: 48.8179671, lng: 19.1526351 },
    { lat: 48.8179993, lng: 19.154186 },
    { lat: 48.8179695, lng: 19.1543153 },
    { lat: 48.81817, lng: 19.1549795 },
    { lat: 48.8182315, lng: 19.1561845 },
    { lat: 48.8187563, lng: 19.1562227 },
    { lat: 48.8195803, lng: 19.1568898 },
    { lat: 48.820319, lng: 19.157011 },
    { lat: 48.8212751, lng: 19.1573846 },
    { lat: 48.8216145, lng: 19.1578218 },
    { lat: 48.8218208, lng: 19.1580079 },
  ],
  Riečka: [
    { lat: 48.7553186, lng: 19.0568644 },
    { lat: 48.7549612, lng: 19.0584916 },
    { lat: 48.7549592, lng: 19.0586966 },
    { lat: 48.7545523, lng: 19.0601462 },
    { lat: 48.754464, lng: 19.0606997 },
    { lat: 48.7546718, lng: 19.0609346 },
    { lat: 48.7550045, lng: 19.0617495 },
    { lat: 48.7549761, lng: 19.0632119 },
    { lat: 48.7551442, lng: 19.0637769 },
    { lat: 48.7545609, lng: 19.0661844 },
    { lat: 48.7538796, lng: 19.0663372 },
    { lat: 48.753436, lng: 19.0667544 },
    { lat: 48.7529199, lng: 19.0675932 },
    { lat: 48.7526389, lng: 19.0683827 },
    { lat: 48.7525255, lng: 19.0688662 },
    { lat: 48.7511913, lng: 19.0700509 },
    { lat: 48.7509235, lng: 19.0713504 },
    { lat: 48.7504357, lng: 19.0726018 },
    { lat: 48.7500543, lng: 19.073747 },
    { lat: 48.7500702, lng: 19.0739513 },
    { lat: 48.7498803, lng: 19.0744417 },
    { lat: 48.749667, lng: 19.0756781 },
    { lat: 48.7484351, lng: 19.0773625 },
    { lat: 48.7479239, lng: 19.0787539 },
    { lat: 48.7473763, lng: 19.0795747 },
    { lat: 48.7472646, lng: 19.0803669 },
    { lat: 48.7472412, lng: 19.0816863 },
    { lat: 48.7471756, lng: 19.0823143 },
    { lat: 48.746769, lng: 19.0833425 },
    { lat: 48.7461273, lng: 19.0843765 },
    { lat: 48.7453644, lng: 19.0859507 },
    { lat: 48.7453041, lng: 19.088273 },
    { lat: 48.7450892, lng: 19.0892569 },
    { lat: 48.7451887, lng: 19.0900086 },
    { lat: 48.7451961, lng: 19.0906322 },
    { lat: 48.7451161, lng: 19.0907956 },
    { lat: 48.7454054, lng: 19.0904662 },
    { lat: 48.7457501, lng: 19.0907336 },
    { lat: 48.7467021, lng: 19.0892501 },
    { lat: 48.7477856, lng: 19.0896803 },
    { lat: 48.7503138, lng: 19.0913303 },
    { lat: 48.7506223, lng: 19.0912302 },
    { lat: 48.7515044, lng: 19.0911968 },
    { lat: 48.7523628, lng: 19.0915001 },
    { lat: 48.7525819, lng: 19.0915349 },
    { lat: 48.7529491, lng: 19.0915931 },
    { lat: 48.7531282, lng: 19.0918434 },
    { lat: 48.753671, lng: 19.092602 },
    { lat: 48.7537789, lng: 19.0927529 },
    { lat: 48.75419, lng: 19.0928337 },
    { lat: 48.7555277, lng: 19.0914634 },
    { lat: 48.7558805, lng: 19.0913497 },
    { lat: 48.757252, lng: 19.0906361 },
    { lat: 48.7575074, lng: 19.0903998 },
    { lat: 48.7578527, lng: 19.0898832 },
    { lat: 48.7581151, lng: 19.0893364 },
    { lat: 48.7584617, lng: 19.089005 },
    { lat: 48.7589224, lng: 19.0890195 },
    { lat: 48.7596019, lng: 19.0882001 },
    { lat: 48.7598771, lng: 19.0875864 },
    { lat: 48.760317, lng: 19.0868213 },
    { lat: 48.7610074, lng: 19.0857644 },
    { lat: 48.7612318, lng: 19.0851104 },
    { lat: 48.7615992, lng: 19.0845651 },
    { lat: 48.7620402, lng: 19.084045 },
    { lat: 48.7624232, lng: 19.0837002 },
    { lat: 48.7631805, lng: 19.0832631 },
    { lat: 48.7640289, lng: 19.0832551 },
    { lat: 48.764278, lng: 19.083324 },
    { lat: 48.7645007, lng: 19.0836067 },
    { lat: 48.7648764, lng: 19.0842925 },
    { lat: 48.7650212, lng: 19.0843686 },
    { lat: 48.7656357, lng: 19.0845063 },
    { lat: 48.7673268, lng: 19.0842156 },
    { lat: 48.7684776, lng: 19.0829867 },
    { lat: 48.7692085, lng: 19.080727 },
    { lat: 48.769789, lng: 19.0813371 },
    { lat: 48.7705407, lng: 19.079229 },
    { lat: 48.7703219, lng: 19.0788401 },
    { lat: 48.7705497, lng: 19.0779814 },
    { lat: 48.7708584, lng: 19.0772747 },
    { lat: 48.7715947, lng: 19.0761253 },
    { lat: 48.7724531, lng: 19.0744835 },
    { lat: 48.7727054, lng: 19.0741363 },
    { lat: 48.7728609, lng: 19.0736157 },
    { lat: 48.7737093, lng: 19.0725646 },
    { lat: 48.7738759, lng: 19.0721778 },
    { lat: 48.7749368, lng: 19.0707318 },
    { lat: 48.7752423, lng: 19.0705293 },
    { lat: 48.7755287, lng: 19.0704831 },
    { lat: 48.7766216, lng: 19.0706032 },
    { lat: 48.7767991, lng: 19.0694856 },
    { lat: 48.7762922, lng: 19.0680894 },
    { lat: 48.7763648, lng: 19.0679206 },
    { lat: 48.7760609, lng: 19.0674971 },
    { lat: 48.7760282, lng: 19.0670265 },
    { lat: 48.7763043, lng: 19.0668409 },
    { lat: 48.7762126, lng: 19.0657272 },
    { lat: 48.775717, lng: 19.0654192 },
    { lat: 48.7728632, lng: 19.0589986 },
    { lat: 48.7727955, lng: 19.0581385 },
    { lat: 48.7725071, lng: 19.0567886 },
    { lat: 48.7721601, lng: 19.0560702 },
    { lat: 48.7711854, lng: 19.0555838 },
    { lat: 48.7710749, lng: 19.0543462 },
    { lat: 48.7694992, lng: 19.0511302 },
    { lat: 48.7693491, lng: 19.0511012 },
    { lat: 48.7693446, lng: 19.0510335 },
    { lat: 48.7689458, lng: 19.0510188 },
    { lat: 48.768629, lng: 19.0505475 },
    { lat: 48.7685929, lng: 19.0503404 },
    { lat: 48.7687904, lng: 19.0502313 },
    { lat: 48.7685293, lng: 19.0500604 },
    { lat: 48.7685226, lng: 19.0498614 },
    { lat: 48.7689266, lng: 19.0489684 },
    { lat: 48.7692252, lng: 19.0479489 },
    { lat: 48.7690454, lng: 19.0479152 },
    { lat: 48.7686719, lng: 19.0480234 },
    { lat: 48.768532, lng: 19.0478196 },
    { lat: 48.7683732, lng: 19.0477999 },
    { lat: 48.7683576, lng: 19.0479132 },
    { lat: 48.7682651, lng: 19.047892 },
    { lat: 48.7681771, lng: 19.0476866 },
    { lat: 48.7682572, lng: 19.0474647 },
    { lat: 48.7682644, lng: 19.0472924 },
    { lat: 48.7680948, lng: 19.0474158 },
    { lat: 48.7679414, lng: 19.0477068 },
    { lat: 48.7677822, lng: 19.0477682 },
    { lat: 48.7677135, lng: 19.0476803 },
    { lat: 48.7677616, lng: 19.0472259 },
    { lat: 48.767892, lng: 19.0467207 },
    { lat: 48.7679467, lng: 19.0461715 },
    { lat: 48.7681685, lng: 19.0457827 },
    { lat: 48.7682441, lng: 19.0454681 },
    { lat: 48.7658705, lng: 19.0448055 },
    { lat: 48.7634651, lng: 19.0441406 },
    { lat: 48.7633244, lng: 19.044256 },
    { lat: 48.7631244, lng: 19.0442911 },
    { lat: 48.7629747, lng: 19.0442725 },
    { lat: 48.7628461, lng: 19.0441576 },
    { lat: 48.7627343, lng: 19.0441993 },
    { lat: 48.7624908, lng: 19.0445395 },
    { lat: 48.7622647, lng: 19.0447059 },
    { lat: 48.7620809, lng: 19.044574 },
    { lat: 48.7619369, lng: 19.0446877 },
    { lat: 48.7618736, lng: 19.0446642 },
    { lat: 48.7610468, lng: 19.0452642 },
    { lat: 48.7604143, lng: 19.0454657 },
    { lat: 48.7601802, lng: 19.0456497 },
    { lat: 48.7600493, lng: 19.0458523 },
    { lat: 48.7599047, lng: 19.0458478 },
    { lat: 48.7595392, lng: 19.0461827 },
    { lat: 48.7593556, lng: 19.0465453 },
    { lat: 48.7592415, lng: 19.0469225 },
    { lat: 48.758891, lng: 19.0475747 },
    { lat: 48.7585727, lng: 19.0477298 },
    { lat: 48.758283, lng: 19.0484269 },
    { lat: 48.7580746, lng: 19.0484307 },
    { lat: 48.757688, lng: 19.049602 },
    { lat: 48.7565132, lng: 19.0514487 },
    { lat: 48.7559866, lng: 19.0521003 },
    { lat: 48.7559654, lng: 19.0523521 },
    { lat: 48.7559005, lng: 19.0525188 },
    { lat: 48.7558301, lng: 19.0525917 },
    { lat: 48.7558849, lng: 19.0531084 },
    { lat: 48.7558975, lng: 19.0532005 },
    { lat: 48.7559093, lng: 19.0533461 },
    { lat: 48.7559513, lng: 19.0535574 },
    { lat: 48.7556738, lng: 19.0539136 },
    { lat: 48.7557498, lng: 19.0548482 },
    { lat: 48.7556523, lng: 19.0549948 },
    { lat: 48.7556424, lng: 19.0553353 },
    { lat: 48.7552088, lng: 19.0561574 },
    { lat: 48.7553349, lng: 19.0565385 },
    { lat: 48.7553186, lng: 19.0568644 },
  ],
  ŠpaniaDolina: [
    { lat: 48.7853388, lng: 19.1435475 },
    { lat: 48.7862425, lng: 19.1438621 },
    { lat: 48.786614, lng: 19.1440819 },
    { lat: 48.7868134, lng: 19.1443505 },
    { lat: 48.7866646, lng: 19.1446496 },
    { lat: 48.7864528, lng: 19.1446077 },
    { lat: 48.7863243, lng: 19.1446991 },
    { lat: 48.7858215, lng: 19.1447214 },
    { lat: 48.785766, lng: 19.1446007 },
    { lat: 48.78559, lng: 19.1449412 },
    { lat: 48.7855206, lng: 19.1460286 },
    { lat: 48.7853872, lng: 19.1462263 },
    { lat: 48.785362, lng: 19.1465158 },
    { lat: 48.7852327, lng: 19.1469615 },
    { lat: 48.7850837, lng: 19.1473454 },
    { lat: 48.7848147, lng: 19.1474672 },
    { lat: 48.7846058, lng: 19.1478743 },
    { lat: 48.7890585, lng: 19.1494128 },
    { lat: 48.7890902, lng: 19.1494603 },
    { lat: 48.7892474, lng: 19.1497658 },
    { lat: 48.7894354, lng: 19.1496932 },
    { lat: 48.7898034, lng: 19.1497987 },
    { lat: 48.789983, lng: 19.1500544 },
    { lat: 48.7900631, lng: 19.1504687 },
    { lat: 48.7903628, lng: 19.150948 },
    { lat: 48.7916463, lng: 19.1517888 },
    { lat: 48.7924294, lng: 19.1518319 },
    { lat: 48.7927527, lng: 19.1516425 },
    { lat: 48.7931238, lng: 19.1515815 },
    { lat: 48.7931365, lng: 19.1514249 },
    { lat: 48.793006, lng: 19.1509483 },
    { lat: 48.7933718, lng: 19.1507105 },
    { lat: 48.7937068, lng: 19.1508501 },
    { lat: 48.7945965, lng: 19.1501686 },
    { lat: 48.7954456, lng: 19.1492782 },
    { lat: 48.7956303, lng: 19.1502868 },
    { lat: 48.7957074, lng: 19.1522231 },
    { lat: 48.7952666, lng: 19.1525198 },
    { lat: 48.7953072, lng: 19.1529575 },
    { lat: 48.7954852, lng: 19.1535764 },
    { lat: 48.7953993, lng: 19.1537498 },
    { lat: 48.7952074, lng: 19.154686 },
    { lat: 48.7950149, lng: 19.1551785 },
    { lat: 48.7949028, lng: 19.1559459 },
    { lat: 48.7947757, lng: 19.1559302 },
    { lat: 48.7946178, lng: 19.1562265 },
    { lat: 48.7945761, lng: 19.1565465 },
    { lat: 48.794839, lng: 19.1566671 },
    { lat: 48.794841, lng: 19.1567992 },
    { lat: 48.7946735, lng: 19.1568316 },
    { lat: 48.7946738, lng: 19.157252 },
    { lat: 48.7950444, lng: 19.1572951 },
    { lat: 48.7952998, lng: 19.1574219 },
    { lat: 48.7953261, lng: 19.1573124 },
    { lat: 48.7955027, lng: 19.1572135 },
    { lat: 48.7956547, lng: 19.1573709 },
    { lat: 48.7956827, lng: 19.1575265 },
    { lat: 48.7961545, lng: 19.1574706 },
    { lat: 48.796508, lng: 19.1573243 },
    { lat: 48.7965353, lng: 19.1574002 },
    { lat: 48.7966907, lng: 19.1573939 },
    { lat: 48.7967874, lng: 19.1575071 },
    { lat: 48.7968847, lng: 19.1574185 },
    { lat: 48.7968723, lng: 19.1573502 },
    { lat: 48.7971446, lng: 19.1573621 },
    { lat: 48.797211, lng: 19.1575935 },
    { lat: 48.7973649, lng: 19.1575093 },
    { lat: 48.7974852, lng: 19.1576649 },
    { lat: 48.7975872, lng: 19.1575387 },
    { lat: 48.797688, lng: 19.15762 },
    { lat: 48.7979154, lng: 19.1574989 },
    { lat: 48.7981845, lng: 19.1581784 },
    { lat: 48.7982751, lng: 19.1593182 },
    { lat: 48.7981856, lng: 19.1603627 },
    { lat: 48.7979221, lng: 19.162096 },
    { lat: 48.7979254, lng: 19.1621629 },
    { lat: 48.7984489, lng: 19.1619185 },
    { lat: 48.7989976, lng: 19.1617978 },
    { lat: 48.8004227, lng: 19.1616199 },
    { lat: 48.8009711, lng: 19.1612861 },
    { lat: 48.8011722, lng: 19.160951 },
    { lat: 48.8014236, lng: 19.1609038 },
    { lat: 48.801641, lng: 19.1607839 },
    { lat: 48.8020858, lng: 19.1602505 },
    { lat: 48.8030584, lng: 19.1593713 },
    { lat: 48.8031406, lng: 19.1590248 },
    { lat: 48.8033044, lng: 19.1588001 },
    { lat: 48.8033878, lng: 19.1585497 },
    { lat: 48.8035724, lng: 19.1573492 },
    { lat: 48.8036485, lng: 19.1572194 },
    { lat: 48.803669, lng: 19.1565581 },
    { lat: 48.8041289, lng: 19.1546289 },
    { lat: 48.8043408, lng: 19.1531811 },
    { lat: 48.8040936, lng: 19.1517739 },
    { lat: 48.8040074, lng: 19.1504315 },
    { lat: 48.804, lng: 19.1490877 },
    { lat: 48.8049856, lng: 19.1490529 },
    { lat: 48.8056635, lng: 19.1493356 },
    { lat: 48.8058112, lng: 19.1495428 },
    { lat: 48.8060439, lng: 19.1496683 },
    { lat: 48.8064916, lng: 19.1493961 },
    { lat: 48.8067305, lng: 19.1491193 },
    { lat: 48.8072813, lng: 19.1496859 },
    { lat: 48.8075159, lng: 19.149686 },
    { lat: 48.8077311, lng: 19.1495716 },
    { lat: 48.8087909, lng: 19.1504204 },
    { lat: 48.8096626, lng: 19.1509567 },
    { lat: 48.8099546, lng: 19.150568 },
    { lat: 48.8106762, lng: 19.1500665 },
    { lat: 48.8106961, lng: 19.149785 },
    { lat: 48.8110935, lng: 19.1497967 },
    { lat: 48.8113966, lng: 19.1496764 },
    { lat: 48.8119402, lng: 19.1499804 },
    { lat: 48.8121939, lng: 19.1500058 },
    { lat: 48.8131148, lng: 19.1494543 },
    { lat: 48.8135142, lng: 19.1494837 },
    { lat: 48.8138604, lng: 19.1496017 },
    { lat: 48.8142422, lng: 19.1495741 },
    { lat: 48.815103, lng: 19.1502534 },
    { lat: 48.8157401, lng: 19.1496619 },
    { lat: 48.816155, lng: 19.149525 },
    { lat: 48.8165406, lng: 19.1498504 },
    { lat: 48.8167504, lng: 19.1499409 },
    { lat: 48.8180147, lng: 19.1513337 },
    { lat: 48.8181872, lng: 19.1515865 },
    { lat: 48.8179056, lng: 19.1483531 },
    { lat: 48.8177559, lng: 19.1480842 },
    { lat: 48.8175793, lng: 19.1479327 },
    { lat: 48.8169948, lng: 19.1478233 },
    { lat: 48.816667, lng: 19.1471903 },
    { lat: 48.8161989, lng: 19.1468181 },
    { lat: 48.8160943, lng: 19.14666 },
    { lat: 48.8160875, lng: 19.1444355 },
    { lat: 48.8162595, lng: 19.143029 },
    { lat: 48.8164055, lng: 19.1424664 },
    { lat: 48.8164459, lng: 19.142117 },
    { lat: 48.8164028, lng: 19.1417194 },
    { lat: 48.8161219, lng: 19.1405292 },
    { lat: 48.8160987, lng: 19.1401477 },
    { lat: 48.8161424, lng: 19.1397733 },
    { lat: 48.8164802, lng: 19.13915 },
    { lat: 48.8173938, lng: 19.1398685 },
    { lat: 48.8177395, lng: 19.1399533 },
    { lat: 48.8179634, lng: 19.1398078 },
    { lat: 48.8179389, lng: 19.1394912 },
    { lat: 48.8178114, lng: 19.1393546 },
    { lat: 48.8178068, lng: 19.1390972 },
    { lat: 48.8177655, lng: 19.1389917 },
    { lat: 48.8178332, lng: 19.138626 },
    { lat: 48.8181466, lng: 19.1380777 },
    { lat: 48.8182931, lng: 19.1375511 },
    { lat: 48.8183652, lng: 19.136933 },
    { lat: 48.8183354, lng: 19.1366238 },
    { lat: 48.8183878, lng: 19.1364758 },
    { lat: 48.8181471, lng: 19.1357504 },
    { lat: 48.8180284, lng: 19.13243 },
    { lat: 48.8194936, lng: 19.1310046 },
    { lat: 48.8211208, lng: 19.1287272 },
    { lat: 48.8213397, lng: 19.1285709 },
    { lat: 48.8215545, lng: 19.1285627 },
    { lat: 48.8216925, lng: 19.1284104 },
    { lat: 48.8222245, lng: 19.1274248 },
    { lat: 48.8225007, lng: 19.127087 },
    { lat: 48.8225613, lng: 19.126898 },
    { lat: 48.8226474, lng: 19.1269513 },
    { lat: 48.8227137, lng: 19.127128 },
    { lat: 48.8227436, lng: 19.1270964 },
    { lat: 48.8229009, lng: 19.1268568 },
    { lat: 48.82305, lng: 19.1267469 },
    { lat: 48.8231856, lng: 19.1262071 },
    { lat: 48.823419, lng: 19.1260258 },
    { lat: 48.8234714, lng: 19.125875 },
    { lat: 48.8234989, lng: 19.1254032 },
    { lat: 48.8234603, lng: 19.1252638 },
    { lat: 48.8228817, lng: 19.1247046 },
    { lat: 48.8227447, lng: 19.1248117 },
    { lat: 48.8224986, lng: 19.124802 },
    { lat: 48.8212573, lng: 19.1240888 },
    { lat: 48.8213731, lng: 19.1234686 },
    { lat: 48.8214023, lng: 19.1228246 },
    { lat: 48.8213611, lng: 19.1221285 },
    { lat: 48.8215539, lng: 19.1217618 },
    { lat: 48.8217094, lng: 19.1211929 },
    { lat: 48.8219405, lng: 19.1197305 },
    { lat: 48.8219276, lng: 19.1193055 },
    { lat: 48.822037, lng: 19.1191053 },
    { lat: 48.8222813, lng: 19.1182981 },
    { lat: 48.8224447, lng: 19.1175884 },
    { lat: 48.8227323, lng: 19.1169992 },
    { lat: 48.8227907, lng: 19.1167232 },
    { lat: 48.8232515, lng: 19.1154593 },
    { lat: 48.823554, lng: 19.1149453 },
    { lat: 48.8237572, lng: 19.1143588 },
    { lat: 48.8240858, lng: 19.1122029 },
    { lat: 48.824087, lng: 19.1117033 },
    { lat: 48.8237822, lng: 19.1105308 },
    { lat: 48.8237863, lng: 19.1099969 },
    { lat: 48.8241257, lng: 19.1092229 },
    { lat: 48.8242703, lng: 19.1081579 },
    { lat: 48.824402, lng: 19.1077454 },
    { lat: 48.8246423, lng: 19.1060664 },
    { lat: 48.824841, lng: 19.1050696 },
    { lat: 48.8253984, lng: 19.1036665 },
    { lat: 48.8256755, lng: 19.1027313 },
    { lat: 48.8257719, lng: 19.1018298 },
    { lat: 48.8257267, lng: 19.1015559 },
    { lat: 48.8257184, lng: 19.1009125 },
    { lat: 48.8257923, lng: 19.1001721 },
    { lat: 48.8254882, lng: 19.1002228 },
    { lat: 48.8247316, lng: 19.1000382 },
    { lat: 48.8238858, lng: 19.099372 },
    { lat: 48.8237186, lng: 19.0991835 },
    { lat: 48.8235345, lng: 19.0991292 },
    { lat: 48.8232843, lng: 19.0991759 },
    { lat: 48.82293, lng: 19.0990566 },
    { lat: 48.8226716, lng: 19.0990949 },
    { lat: 48.8217073, lng: 19.0993383 },
    { lat: 48.8213144, lng: 19.0995268 },
    { lat: 48.8207876, lng: 19.1000649 },
    { lat: 48.8199986, lng: 19.1003889 },
    { lat: 48.8195027, lng: 19.1004123 },
    { lat: 48.8192132, lng: 19.1005107 },
    { lat: 48.819052, lng: 19.100417 },
    { lat: 48.8188435, lng: 19.1004436 },
    { lat: 48.8185377, lng: 19.1002966 },
    { lat: 48.818169, lng: 19.1002977 },
    { lat: 48.8178819, lng: 19.1004201 },
    { lat: 48.8175656, lng: 19.1007014 },
    { lat: 48.8172662, lng: 19.1008478 },
    { lat: 48.8169601, lng: 19.1008829 },
    { lat: 48.8167836, lng: 19.1010246 },
    { lat: 48.8163309, lng: 19.1011751 },
    { lat: 48.8163205, lng: 19.101236 },
    { lat: 48.815855, lng: 19.1013525 },
    { lat: 48.8158434, lng: 19.1016876 },
    { lat: 48.8149836, lng: 19.1018067 },
    { lat: 48.8148688, lng: 19.1015357 },
    { lat: 48.814439, lng: 19.101576 },
    { lat: 48.8144612, lng: 19.1013424 },
    { lat: 48.8135967, lng: 19.1012535 },
    { lat: 48.8128765, lng: 19.100391 },
    { lat: 48.8125734, lng: 19.1003063 },
    { lat: 48.8121011, lng: 19.0998932 },
    { lat: 48.8114742, lng: 19.0985742 },
    { lat: 48.8117293, lng: 19.098264 },
    { lat: 48.8123533, lng: 19.098138 },
    { lat: 48.8123508, lng: 19.0980396 },
    { lat: 48.8124256, lng: 19.0979574 },
    { lat: 48.8121852, lng: 19.0979297 },
    { lat: 48.8115362, lng: 19.097596 },
    { lat: 48.8110632, lng: 19.0972406 },
    { lat: 48.8104034, lng: 19.0968746 },
    { lat: 48.8100623, lng: 19.0967889 },
    { lat: 48.8097143, lng: 19.0965677 },
    { lat: 48.8090247, lng: 19.096439 },
    { lat: 48.8087979, lng: 19.0965249 },
    { lat: 48.8081877, lng: 19.0970416 },
    { lat: 48.8080142, lng: 19.0970882 },
    { lat: 48.8078087, lng: 19.0970164 },
    { lat: 48.8074974, lng: 19.0970804 },
    { lat: 48.8067547, lng: 19.09679 },
    { lat: 48.8057645, lng: 19.0971672 },
    { lat: 48.8052133, lng: 19.097532 },
    { lat: 48.80479, lng: 19.0981334 },
    { lat: 48.8046059, lng: 19.0982873 },
    { lat: 48.8036913, lng: 19.0986423 },
    { lat: 48.8036507, lng: 19.0986659 },
    { lat: 48.803639, lng: 19.0990885 },
    { lat: 48.8042628, lng: 19.1012127 },
    { lat: 48.8042234, lng: 19.1024628 },
    { lat: 48.8040508, lng: 19.1029158 },
    { lat: 48.8038143, lng: 19.1044697 },
    { lat: 48.8035892, lng: 19.1052473 },
    { lat: 48.8035303, lng: 19.105996 },
    { lat: 48.8037715, lng: 19.1078855 },
    { lat: 48.8040428, lng: 19.108742 },
    { lat: 48.8038966, lng: 19.1094766 },
    { lat: 48.804196, lng: 19.1107205 },
    { lat: 48.8038188, lng: 19.1108641 },
    { lat: 48.8036728, lng: 19.1110598 },
    { lat: 48.8030976, lng: 19.1110996 },
    { lat: 48.8024134, lng: 19.1110116 },
    { lat: 48.8015678, lng: 19.1104834 },
    { lat: 48.8014, lng: 19.1105089 },
    { lat: 48.8012984, lng: 19.1107149 },
    { lat: 48.8012571, lng: 19.1114026 },
    { lat: 48.8010229, lng: 19.1125939 },
    { lat: 48.8008973, lng: 19.1137946 },
    { lat: 48.8005942, lng: 19.1142287 },
    { lat: 48.8001461, lng: 19.114262 },
    { lat: 48.7997784, lng: 19.1144649 },
    { lat: 48.7997155, lng: 19.1145843 },
    { lat: 48.7996266, lng: 19.1152259 },
    { lat: 48.7996506, lng: 19.1153447 },
    { lat: 48.7999812, lng: 19.1159438 },
    { lat: 48.8004495, lng: 19.1163424 },
    { lat: 48.8007539, lng: 19.1168007 },
    { lat: 48.8010962, lng: 19.1171538 },
    { lat: 48.8015322, lng: 19.117746 },
    { lat: 48.8019795, lng: 19.1180594 },
    { lat: 48.8023689, lng: 19.1182277 },
    { lat: 48.8026079, lng: 19.1184796 },
    { lat: 48.8027461, lng: 19.1192932 },
    { lat: 48.802768, lng: 19.1198032 },
    { lat: 48.8028227, lng: 19.1199311 },
    { lat: 48.8030676, lng: 19.1201151 },
    { lat: 48.8030869, lng: 19.1201862 },
    { lat: 48.8030686, lng: 19.1206868 },
    { lat: 48.8031315, lng: 19.1210466 },
    { lat: 48.8032069, lng: 19.1212476 },
    { lat: 48.8035507, lng: 19.1216797 },
    { lat: 48.8036579, lng: 19.122484 },
    { lat: 48.8039779, lng: 19.1229359 },
    { lat: 48.8040019, lng: 19.1230347 },
    { lat: 48.8039955, lng: 19.1235654 },
    { lat: 48.8037414, lng: 19.123581 },
    { lat: 48.8037525, lng: 19.1238064 },
    { lat: 48.8038399, lng: 19.1237976 },
    { lat: 48.8037175, lng: 19.1241455 },
    { lat: 48.8034956, lng: 19.1242206 },
    { lat: 48.8035555, lng: 19.1238791 },
    { lat: 48.8034136, lng: 19.1237943 },
    { lat: 48.8033184, lng: 19.1239853 },
    { lat: 48.8033338, lng: 19.1242768 },
    { lat: 48.8031243, lng: 19.1243449 },
    { lat: 48.8030851, lng: 19.1238519 },
    { lat: 48.8029325, lng: 19.1237046 },
    { lat: 48.8028329, lng: 19.1238114 },
    { lat: 48.8028166, lng: 19.1245524 },
    { lat: 48.8026387, lng: 19.1253135 },
    { lat: 48.8026773, lng: 19.1258662 },
    { lat: 48.8028272, lng: 19.1262527 },
    { lat: 48.803013, lng: 19.1265699 },
    { lat: 48.8036658, lng: 19.1273008 },
    { lat: 48.80403, lng: 19.1279226 },
    { lat: 48.8033046, lng: 19.127981 },
    { lat: 48.8032054, lng: 19.1281617 },
    { lat: 48.8029956, lng: 19.1282712 },
    { lat: 48.8029852, lng: 19.1286794 },
    { lat: 48.8023179, lng: 19.1282488 },
    { lat: 48.8014052, lng: 19.1275448 },
    { lat: 48.8012378, lng: 19.1272834 },
    { lat: 48.8010459, lng: 19.1267301 },
    { lat: 48.8003378, lng: 19.1243979 },
    { lat: 48.8002851, lng: 19.1239654 },
    { lat: 48.799541, lng: 19.1228461 },
    { lat: 48.7993871, lng: 19.1226528 },
    { lat: 48.7988094, lng: 19.1223313 },
    { lat: 48.7986487, lng: 19.1222418 },
    { lat: 48.7983588, lng: 19.1218477 },
    { lat: 48.7976914, lng: 19.121665 },
    { lat: 48.7973082, lng: 19.121688 },
    { lat: 48.7970115, lng: 19.1217795 },
    { lat: 48.7967663, lng: 19.1216485 },
    { lat: 48.7964833, lng: 19.121698 },
    { lat: 48.7962133, lng: 19.1214093 },
    { lat: 48.7954837, lng: 19.1217038 },
    { lat: 48.7952192, lng: 19.1216612 },
    { lat: 48.7947839, lng: 19.1217308 },
    { lat: 48.7944593, lng: 19.1215709 },
    { lat: 48.7943118, lng: 19.1216423 },
    { lat: 48.7941784, lng: 19.1215894 },
    { lat: 48.7938446, lng: 19.1216322 },
    { lat: 48.7937004, lng: 19.1217268 },
    { lat: 48.7935698, lng: 19.121684 },
    { lat: 48.7932892, lng: 19.1212967 },
    { lat: 48.7931351, lng: 19.1213295 },
    { lat: 48.7928074, lng: 19.1210831 },
    { lat: 48.7925966, lng: 19.1210144 },
    { lat: 48.7924957, lng: 19.1208656 },
    { lat: 48.7921668, lng: 19.1207355 },
    { lat: 48.7917904, lng: 19.1208288 },
    { lat: 48.791385, lng: 19.1208372 },
    { lat: 48.7908088, lng: 19.120512 },
    { lat: 48.7904352, lng: 19.1204756 },
    { lat: 48.7903396, lng: 19.1205736 },
    { lat: 48.790021, lng: 19.1205114 },
    { lat: 48.7898302, lng: 19.1203038 },
    { lat: 48.7895283, lng: 19.1203991 },
    { lat: 48.7893275, lng: 19.1206238 },
    { lat: 48.7891756, lng: 19.1206281 },
    { lat: 48.7889183, lng: 19.1209175 },
    { lat: 48.7887938, lng: 19.1209076 },
    { lat: 48.7886157, lng: 19.1210003 },
    { lat: 48.7883075, lng: 19.1212647 },
    { lat: 48.7880024, lng: 19.121409 },
    { lat: 48.7877195, lng: 19.1214356 },
    { lat: 48.7872817, lng: 19.121266 },
    { lat: 48.7870217, lng: 19.1209762 },
    { lat: 48.7868935, lng: 19.1210071 },
    { lat: 48.7867734, lng: 19.1209467 },
    { lat: 48.7862476, lng: 19.1205504 },
    { lat: 48.7857214, lng: 19.1202804 },
    { lat: 48.7849255, lng: 19.1199872 },
    { lat: 48.7843398, lng: 19.1196317 },
    { lat: 48.7839801, lng: 19.1192652 },
    { lat: 48.7838562, lng: 19.11931 },
    { lat: 48.7826352, lng: 19.1180973 },
    { lat: 48.7820514, lng: 19.1177612 },
    { lat: 48.7816142, lng: 19.1176952 },
    { lat: 48.7815515, lng: 19.1186861 },
    { lat: 48.7819556, lng: 19.1190811 },
    { lat: 48.7825526, lng: 19.1198053 },
    { lat: 48.7827822, lng: 19.1202065 },
    { lat: 48.7831984, lng: 19.1210992 },
    { lat: 48.7833248, lng: 19.1216504 },
    { lat: 48.7836408, lng: 19.1223671 },
    { lat: 48.7837614, lng: 19.1227989 },
    { lat: 48.7831847, lng: 19.1228069 },
    { lat: 48.7822261, lng: 19.1234872 },
    { lat: 48.7814295, lng: 19.1243365 },
    { lat: 48.7810945, lng: 19.1244498 },
    { lat: 48.7807829, lng: 19.124459 },
    { lat: 48.7809568, lng: 19.1248096 },
    { lat: 48.7805391, lng: 19.1265125 },
    { lat: 48.7795299, lng: 19.1286979 },
    { lat: 48.7798944, lng: 19.1290481 },
    { lat: 48.7803584, lng: 19.1293614 },
    { lat: 48.7813202, lng: 19.1305982 },
    { lat: 48.7816136, lng: 19.131246 },
    { lat: 48.7817051, lng: 19.1323012 },
    { lat: 48.7856459, lng: 19.1406949 },
    { lat: 48.7857786, lng: 19.1416501 },
    { lat: 48.7858293, lng: 19.1424055 },
    { lat: 48.7857722, lng: 19.1425528 },
    { lat: 48.7857951, lng: 19.1428344 },
    { lat: 48.7855753, lng: 19.143222 },
    { lat: 48.7854043, lng: 19.1434132 },
    { lat: 48.7853388, lng: 19.1435475 },
  ],
  Podkonice: [
    { lat: 48.7967736, lng: 19.2911337 },
    { lat: 48.7976579, lng: 19.2910993 },
    { lat: 48.7978842, lng: 19.2903651 },
    { lat: 48.7983082, lng: 19.2902778 },
    { lat: 48.7985025, lng: 19.2900996 },
    { lat: 48.798837, lng: 19.2899331 },
    { lat: 48.798501, lng: 19.290823 },
    { lat: 48.7986657, lng: 19.2907298 },
    { lat: 48.7991011, lng: 19.290268 },
    { lat: 48.7993632, lng: 19.2901195 },
    { lat: 48.7998434, lng: 19.2900028 },
    { lat: 48.800698, lng: 19.2899158 },
    { lat: 48.8020239, lng: 19.2900387 },
    { lat: 48.802021, lng: 19.2899568 },
    { lat: 48.8026747, lng: 19.2898124 },
    { lat: 48.8031739, lng: 19.2898613 },
    { lat: 48.8033787, lng: 19.2898077 },
    { lat: 48.8033895, lng: 19.2897018 },
    { lat: 48.8037106, lng: 19.2896058 },
    { lat: 48.8043121, lng: 19.2897138 },
    { lat: 48.8043556, lng: 19.2895178 },
    { lat: 48.8048419, lng: 19.2896893 },
    { lat: 48.8055686, lng: 19.2895909 },
    { lat: 48.8055112, lng: 19.2897831 },
    { lat: 48.8058046, lng: 19.2897638 },
    { lat: 48.8058325, lng: 19.2897107 },
    { lat: 48.8064272, lng: 19.2905279 },
    { lat: 48.8066706, lng: 19.2907128 },
    { lat: 48.8070964, lng: 19.2908763 },
    { lat: 48.8073059, lng: 19.2908026 },
    { lat: 48.8066471, lng: 19.2904347 },
    { lat: 48.8067032, lng: 19.2903152 },
    { lat: 48.8070028, lng: 19.29045 },
    { lat: 48.8070411, lng: 19.2902388 },
    { lat: 48.8073607, lng: 19.2901568 },
    { lat: 48.8073459, lng: 19.2899647 },
    { lat: 48.8076736, lng: 19.2897452 },
    { lat: 48.8077631, lng: 19.2900175 },
    { lat: 48.8080065, lng: 19.2901328 },
    { lat: 48.80802, lng: 19.2902718 },
    { lat: 48.8081949, lng: 19.2902984 },
    { lat: 48.8084096, lng: 19.2901704 },
    { lat: 48.8085565, lng: 19.2899884 },
    { lat: 48.8087065, lng: 19.2899204 },
    { lat: 48.8093257, lng: 19.2893668 },
    { lat: 48.809662, lng: 19.289282 },
    { lat: 48.8099128, lng: 19.2890595 },
    { lat: 48.8103755, lng: 19.2883894 },
    { lat: 48.8105255, lng: 19.2884151 },
    { lat: 48.8107801, lng: 19.2881536 },
    { lat: 48.811685, lng: 19.2870407 },
    { lat: 48.8115431, lng: 19.2869036 },
    { lat: 48.8114884, lng: 19.286753 },
    { lat: 48.8116483, lng: 19.2865606 },
    { lat: 48.8118057, lng: 19.28648 },
    { lat: 48.8120459, lng: 19.2861103 },
    { lat: 48.8121833, lng: 19.2863416 },
    { lat: 48.8125573, lng: 19.2859593 },
    { lat: 48.8129729, lng: 19.2856715 },
    { lat: 48.8130208, lng: 19.2857542 },
    { lat: 48.8133301, lng: 19.2855064 },
    { lat: 48.8132541, lng: 19.2853542 },
    { lat: 48.8136498, lng: 19.2849069 },
    { lat: 48.8135468, lng: 19.2844541 },
    { lat: 48.8138486, lng: 19.2844481 },
    { lat: 48.8139779, lng: 19.2842293 },
    { lat: 48.8139577, lng: 19.2838081 },
    { lat: 48.8140646, lng: 19.2836873 },
    { lat: 48.8140964, lng: 19.2834105 },
    { lat: 48.8142898, lng: 19.2832119 },
    { lat: 48.8143256, lng: 19.283017 },
    { lat: 48.8142041, lng: 19.282839 },
    { lat: 48.8140589, lng: 19.2828142 },
    { lat: 48.8140092, lng: 19.2821573 },
    { lat: 48.8138745, lng: 19.2816057 },
    { lat: 48.8140434, lng: 19.2811773 },
    { lat: 48.814194, lng: 19.2812154 },
    { lat: 48.8147493, lng: 19.2817959 },
    { lat: 48.8147277, lng: 19.2819077 },
    { lat: 48.8149507, lng: 19.282047 },
    { lat: 48.8148965, lng: 19.2822304 },
    { lat: 48.8151607, lng: 19.2824745 },
    { lat: 48.8151243, lng: 19.2826559 },
    { lat: 48.8151813, lng: 19.282718 },
    { lat: 48.8152408, lng: 19.2826431 },
    { lat: 48.8153536, lng: 19.2827398 },
    { lat: 48.8153343, lng: 19.2825238 },
    { lat: 48.8155732, lng: 19.2824292 },
    { lat: 48.8155498, lng: 19.2822268 },
    { lat: 48.8156591, lng: 19.2823378 },
    { lat: 48.8160517, lng: 19.2820694 },
    { lat: 48.8161509, lng: 19.2822562 },
    { lat: 48.8166966, lng: 19.2819777 },
    { lat: 48.81717, lng: 19.2811338 },
    { lat: 48.8175289, lng: 19.2809988 },
    { lat: 48.8175282, lng: 19.2808895 },
    { lat: 48.8176484, lng: 19.2807671 },
    { lat: 48.8177691, lng: 19.2810271 },
    { lat: 48.8181473, lng: 19.280453 },
    { lat: 48.8184579, lng: 19.2803505 },
    { lat: 48.818588, lng: 19.2802407 },
    { lat: 48.8190334, lng: 19.2796591 },
    { lat: 48.8192031, lng: 19.2800936 },
    { lat: 48.8197092, lng: 19.2793201 },
    { lat: 48.8198373, lng: 19.2789344 },
    { lat: 48.8200027, lng: 19.2788575 },
    { lat: 48.8202753, lng: 19.2783854 },
    { lat: 48.8206028, lng: 19.2771776 },
    { lat: 48.8211888, lng: 19.2767304 },
    { lat: 48.821381, lng: 19.2760847 },
    { lat: 48.8215901, lng: 19.2750885 },
    { lat: 48.8215915, lng: 19.2743404 },
    { lat: 48.8213921, lng: 19.2738269 },
    { lat: 48.821502, lng: 19.2732049 },
    { lat: 48.821589, lng: 19.2722732 },
    { lat: 48.8216867, lng: 19.2717939 },
    { lat: 48.8216395, lng: 19.2715888 },
    { lat: 48.8217895, lng: 19.2710476 },
    { lat: 48.8220063, lng: 19.2707529 },
    { lat: 48.822059, lng: 19.2702581 },
    { lat: 48.8221879, lng: 19.2697693 },
    { lat: 48.8224356, lng: 19.2691096 },
    { lat: 48.8224023, lng: 19.2681582 },
    { lat: 48.8225566, lng: 19.2677817 },
    { lat: 48.8225817, lng: 19.2672967 },
    { lat: 48.8225504, lng: 19.2669257 },
    { lat: 48.8225978, lng: 19.2665302 },
    { lat: 48.822801, lng: 19.2662524 },
    { lat: 48.8229716, lng: 19.2653944 },
    { lat: 48.8229746, lng: 19.2646608 },
    { lat: 48.8231002, lng: 19.2634537 },
    { lat: 48.823045, lng: 19.2629629 },
    { lat: 48.8231931, lng: 19.2626697 },
    { lat: 48.8232074, lng: 19.262284 },
    { lat: 48.8233754, lng: 19.2613479 },
    { lat: 48.8233351, lng: 19.2611192 },
    { lat: 48.8235, lng: 19.2608019 },
    { lat: 48.8239237, lng: 19.2602125 },
    { lat: 48.8239754, lng: 19.2598144 },
    { lat: 48.8240848, lng: 19.2594181 },
    { lat: 48.8243405, lng: 19.2575394 },
    { lat: 48.8245245, lng: 19.2572211 },
    { lat: 48.8245967, lng: 19.2568959 },
    { lat: 48.8246157, lng: 19.2560925 },
    { lat: 48.8248195, lng: 19.2555052 },
    { lat: 48.8249663, lng: 19.2553695 },
    { lat: 48.8252917, lng: 19.2532813 },
    { lat: 48.8254701, lng: 19.2525879 },
    { lat: 48.8252505, lng: 19.2514718 },
    { lat: 48.825258, lng: 19.2507842 },
    { lat: 48.8252973, lng: 19.2501939 },
    { lat: 48.8254673, lng: 19.2493073 },
    { lat: 48.8255539, lng: 19.2490891 },
    { lat: 48.8255556, lng: 19.24887 },
    { lat: 48.8256059, lng: 19.2488609 },
    { lat: 48.8256534, lng: 19.249052 },
    { lat: 48.8257346, lng: 19.2491686 },
    { lat: 48.8255303, lng: 19.2500737 },
    { lat: 48.8257859, lng: 19.2501388 },
    { lat: 48.8258574, lng: 19.2501037 },
    { lat: 48.8259982, lng: 19.2496535 },
    { lat: 48.825982, lng: 19.2494145 },
    { lat: 48.826039, lng: 19.2492397 },
    { lat: 48.8262751, lng: 19.2489943 },
    { lat: 48.8266385, lng: 19.2493297 },
    { lat: 48.8267202, lng: 19.2495063 },
    { lat: 48.8269931, lng: 19.2493459 },
    { lat: 48.827104, lng: 19.249447 },
    { lat: 48.8270217, lng: 19.2504964 },
    { lat: 48.8267505, lng: 19.2526567 },
    { lat: 48.8269136, lng: 19.2527667 },
    { lat: 48.8268496, lng: 19.2530754 },
    { lat: 48.8269807, lng: 19.2537366 },
    { lat: 48.8272008, lng: 19.2539083 },
    { lat: 48.8275761, lng: 19.2539573 },
    { lat: 48.8274882, lng: 19.253467 },
    { lat: 48.8277829, lng: 19.253102 },
    { lat: 48.828056, lng: 19.2529656 },
    { lat: 48.8282712, lng: 19.2526134 },
    { lat: 48.8281893, lng: 19.2521069 },
    { lat: 48.828072, lng: 19.2521525 },
    { lat: 48.8279753, lng: 19.2517629 },
    { lat: 48.8281754, lng: 19.2512596 },
    { lat: 48.8280434, lng: 19.2512105 },
    { lat: 48.8283014, lng: 19.2509033 },
    { lat: 48.8281885, lng: 19.2505519 },
    { lat: 48.8282546, lng: 19.2504234 },
    { lat: 48.8285985, lng: 19.2499836 },
    { lat: 48.8287737, lng: 19.2499223 },
    { lat: 48.8291654, lng: 19.2501448 },
    { lat: 48.8301488, lng: 19.2502023 },
    { lat: 48.8304403, lng: 19.2497046 },
    { lat: 48.8306637, lng: 19.2498692 },
    { lat: 48.8309966, lng: 19.2491816 },
    { lat: 48.8311097, lng: 19.2488814 },
    { lat: 48.8311646, lng: 19.2485698 },
    { lat: 48.8314777, lng: 19.2481241 },
    { lat: 48.8315758, lng: 19.247519 },
    { lat: 48.8317738, lng: 19.2478373 },
    { lat: 48.8320099, lng: 19.2479625 },
    { lat: 48.8321367, lng: 19.2482565 },
    { lat: 48.8322527, lng: 19.2483013 },
    { lat: 48.832424, lng: 19.2485571 },
    { lat: 48.832863, lng: 19.2483357 },
    { lat: 48.8329584, lng: 19.2482114 },
    { lat: 48.83365, lng: 19.2480368 },
    { lat: 48.8340406, lng: 19.247839 },
    { lat: 48.8343047, lng: 19.2478001 },
    { lat: 48.8346773, lng: 19.248186 },
    { lat: 48.8350839, lng: 19.2483206 },
    { lat: 48.8357717, lng: 19.2479035 },
    { lat: 48.8367769, lng: 19.2482506 },
    { lat: 48.8369713, lng: 19.2491522 },
    { lat: 48.8373603, lng: 19.2499453 },
    { lat: 48.8376935, lng: 19.2504788 },
    { lat: 48.8384301, lng: 19.2521288 },
    { lat: 48.8385953, lng: 19.2531678 },
    { lat: 48.838768, lng: 19.2537821 },
    { lat: 48.8390695, lng: 19.2542597 },
    { lat: 48.8392957, lng: 19.2548855 },
    { lat: 48.8392475, lng: 19.2569945 },
    { lat: 48.8389736, lng: 19.2590306 },
    { lat: 48.8392177, lng: 19.2597254 },
    { lat: 48.8391431, lng: 19.2603287 },
    { lat: 48.8388228, lng: 19.2609315 },
    { lat: 48.8387728, lng: 19.2612967 },
    { lat: 48.8389198, lng: 19.2614253 },
    { lat: 48.8443297, lng: 19.2633407 },
    { lat: 48.8444477, lng: 19.2631916 },
    { lat: 48.8500316, lng: 19.26034 },
    { lat: 48.8503372, lng: 19.2605067 },
    { lat: 48.8507349, lng: 19.2608292 },
    { lat: 48.8511179, lng: 19.2620678 },
    { lat: 48.8513678, lng: 19.2632328 },
    { lat: 48.8528287, lng: 19.2637987 },
    { lat: 48.853302, lng: 19.2643087 },
    { lat: 48.8538697, lng: 19.2646813 },
    { lat: 48.8541485, lng: 19.2652042 },
    { lat: 48.8543652, lng: 19.2657705 },
    { lat: 48.8548883, lng: 19.2658201 },
    { lat: 48.8551608, lng: 19.2663781 },
    { lat: 48.8553444, lng: 19.2669151 },
    { lat: 48.8552309, lng: 19.2670157 },
    { lat: 48.8554491, lng: 19.2677071 },
    { lat: 48.854102, lng: 19.2684825 },
    { lat: 48.8542477, lng: 19.2690019 },
    { lat: 48.8553193, lng: 19.2684573 },
    { lat: 48.8554137, lng: 19.2685679 },
    { lat: 48.8556128, lng: 19.2685152 },
    { lat: 48.8557781, lng: 19.2686899 },
    { lat: 48.8558685, lng: 19.2686018 },
    { lat: 48.8558965, lng: 19.2693021 },
    { lat: 48.8569714, lng: 19.2722038 },
    { lat: 48.8578, lng: 19.2723029 },
    { lat: 48.8579975, lng: 19.2730621 },
    { lat: 48.8580917, lng: 19.2736867 },
    { lat: 48.8580203, lng: 19.2746628 },
    { lat: 48.8577975, lng: 19.2750457 },
    { lat: 48.8579031, lng: 19.2755344 },
    { lat: 48.8582594, lng: 19.2763099 },
    { lat: 48.8587701, lng: 19.2768267 },
    { lat: 48.8593953, lng: 19.2779011 },
    { lat: 48.8596807, lng: 19.2782129 },
    { lat: 48.8601378, lng: 19.2805992 },
    { lat: 48.8601222, lng: 19.2836152 },
    { lat: 48.860158, lng: 19.2840559 },
    { lat: 48.8594201, lng: 19.2840841 },
    { lat: 48.8596776, lng: 19.2859107 },
    { lat: 48.8592941, lng: 19.2869114 },
    { lat: 48.858296, lng: 19.2869457 },
    { lat: 48.8581195, lng: 19.2882515 },
    { lat: 48.8584426, lng: 19.2899387 },
    { lat: 48.8584101, lng: 19.2906274 },
    { lat: 48.85795, lng: 19.2912578 },
    { lat: 48.8577871, lng: 19.2922135 },
    { lat: 48.8579537, lng: 19.2946786 },
    { lat: 48.8582515, lng: 19.295982 },
    { lat: 48.8571892, lng: 19.2958295 },
    { lat: 48.8567892, lng: 19.2958766 },
    { lat: 48.8549107, lng: 19.2944944 },
    { lat: 48.854963, lng: 19.2938706 },
    { lat: 48.8549074, lng: 19.2937894 },
    { lat: 48.8547971, lng: 19.2938369 },
    { lat: 48.8546885, lng: 19.2935965 },
    { lat: 48.8549834, lng: 19.2934911 },
    { lat: 48.8552168, lng: 19.2927421 },
    { lat: 48.8552112, lng: 19.2924815 },
    { lat: 48.8550343, lng: 19.2924372 },
    { lat: 48.8547504, lng: 19.2918062 },
    { lat: 48.8544287, lng: 19.2918885 },
    { lat: 48.8546373, lng: 19.2924074 },
    { lat: 48.8545287, lng: 19.2926355 },
    { lat: 48.8542821, lng: 19.2926844 },
    { lat: 48.8541587, lng: 19.2921586 },
    { lat: 48.8539996, lng: 19.2920133 },
    { lat: 48.8535974, lng: 19.2923681 },
    { lat: 48.8536648, lng: 19.2917297 },
    { lat: 48.8535715, lng: 19.2917095 },
    { lat: 48.8531383, lng: 19.2923147 },
    { lat: 48.8530535, lng: 19.2928734 },
    { lat: 48.8535858, lng: 19.2941757 },
    { lat: 48.8541206, lng: 19.2944001 },
    { lat: 48.8550689, lng: 19.2955168 },
    { lat: 48.855576, lng: 19.2960113 },
    { lat: 48.8563353, lng: 19.2962869 },
    { lat: 48.8564577, lng: 19.296441 },
    { lat: 48.8578433, lng: 19.2963318 },
    { lat: 48.8584729, lng: 19.2962076 },
    { lat: 48.859679, lng: 19.295631 },
    { lat: 48.859589, lng: 19.292831 },
    { lat: 48.859701, lng: 19.292212 },
    { lat: 48.859774, lng: 19.291351 },
    { lat: 48.860107, lng: 19.290441 },
    { lat: 48.860304, lng: 19.29028 },
    { lat: 48.860651, lng: 19.28973 },
    { lat: 48.86067, lng: 19.2888 },
    { lat: 48.860712, lng: 19.288024 },
    { lat: 48.860531, lng: 19.286716 },
    { lat: 48.860792, lng: 19.286121 },
    { lat: 48.861056, lng: 19.285032 },
    { lat: 48.860744, lng: 19.283111 },
    { lat: 48.860466, lng: 19.281976 },
    { lat: 48.860425, lng: 19.281848 },
    { lat: 48.860352, lng: 19.281237 },
    { lat: 48.860083, lng: 19.279867 },
    { lat: 48.859826, lng: 19.278557 },
    { lat: 48.859594, lng: 19.277657 },
    { lat: 48.859601, lng: 19.277073 },
    { lat: 48.859517, lng: 19.276389 },
    { lat: 48.859171, lng: 19.274211 },
    { lat: 48.858321, lng: 19.270316 },
    { lat: 48.858298, lng: 19.269857 },
    { lat: 48.858344, lng: 19.269215 },
    { lat: 48.8584955, lng: 19.2687027 },
    { lat: 48.85864, lng: 19.268251 },
    { lat: 48.859198, lng: 19.266853 },
    { lat: 48.859591, lng: 19.265271 },
    { lat: 48.859532, lng: 19.264481 },
    { lat: 48.858846, lng: 19.26394 },
    { lat: 48.857948, lng: 19.262617 },
    { lat: 48.857326, lng: 19.261666 },
    { lat: 48.856887, lng: 19.260845 },
    { lat: 48.856413, lng: 19.25949 },
    { lat: 48.856373, lng: 19.258981 },
    { lat: 48.856266, lng: 19.255842 },
    { lat: 48.856184, lng: 19.25483 },
    { lat: 48.856132, lng: 19.254375 },
    { lat: 48.855886, lng: 19.25334 },
    { lat: 48.856112, lng: 19.252941 },
    { lat: 48.85666, lng: 19.2515812 },
    { lat: 48.8567673, lng: 19.2492033 },
    { lat: 48.8565404, lng: 19.246716 },
    { lat: 48.8582242, lng: 19.2465495 },
    { lat: 48.8594003, lng: 19.2462338 },
    { lat: 48.8605958, lng: 19.245317 },
    { lat: 48.8609322, lng: 19.2446032 },
    { lat: 48.8609083, lng: 19.2435706 },
    { lat: 48.8606589, lng: 19.2431436 },
    { lat: 48.8602999, lng: 19.2420336 },
    { lat: 48.8600216, lng: 19.2409852 },
    { lat: 48.8585488, lng: 19.2377944 },
    { lat: 48.8579958, lng: 19.2371527 },
    { lat: 48.8575011, lng: 19.2357762 },
    { lat: 48.8572417, lng: 19.2353303 },
    { lat: 48.8569444, lng: 19.234521 },
    { lat: 48.8557776, lng: 19.2324195 },
    { lat: 48.8551426, lng: 19.230987 },
    { lat: 48.8545535, lng: 19.2293408 },
    { lat: 48.8535943, lng: 19.2273389 },
    { lat: 48.8529504, lng: 19.2265629 },
    { lat: 48.8525503, lng: 19.225729 },
    { lat: 48.8519518, lng: 19.2234043 },
    { lat: 48.851713, lng: 19.2200782 },
    { lat: 48.8516252, lng: 19.2188485 },
    { lat: 48.8515026, lng: 19.2188765 },
    { lat: 48.8511572, lng: 19.2193573 },
    { lat: 48.8506527, lng: 19.2198534 },
    { lat: 48.8497987, lng: 19.2203052 },
    { lat: 48.8473452, lng: 19.2211434 },
    { lat: 48.8455398, lng: 19.2229886 },
    { lat: 48.8455317, lng: 19.2230543 },
    { lat: 48.8449458, lng: 19.2229452 },
    { lat: 48.8440506, lng: 19.2223791 },
    { lat: 48.8434263, lng: 19.2218266 },
    { lat: 48.8421043, lng: 19.220174 },
    { lat: 48.8418046, lng: 19.2202052 },
    { lat: 48.8414015, lng: 19.2203711 },
    { lat: 48.8409658, lng: 19.2209681 },
    { lat: 48.8406918, lng: 19.2212219 },
    { lat: 48.839883, lng: 19.2214636 },
    { lat: 48.8379692, lng: 19.2213959 },
    { lat: 48.8375015, lng: 19.2211424 },
    { lat: 48.8367195, lng: 19.2208618 },
    { lat: 48.8361412, lng: 19.2206543 },
    { lat: 48.8352748, lng: 19.2206644 },
    { lat: 48.83499, lng: 19.2206013 },
    { lat: 48.8340445, lng: 19.22007 },
    { lat: 48.8332248, lng: 19.2202295 },
    { lat: 48.8328136, lng: 19.2200674 },
    { lat: 48.8327675, lng: 19.2192142 },
    { lat: 48.8328075, lng: 19.2190366 },
    { lat: 48.8329953, lng: 19.2187734 },
    { lat: 48.8311768, lng: 19.2182087 },
    { lat: 48.8299094, lng: 19.2172929 },
    { lat: 48.8295743, lng: 19.2167986 },
    { lat: 48.8292738, lng: 19.216666 },
    { lat: 48.8292389, lng: 19.2164578 },
    { lat: 48.8292798, lng: 19.2162103 },
    { lat: 48.8287607, lng: 19.2146635 },
    { lat: 48.8276941, lng: 19.2127764 },
    { lat: 48.8265283, lng: 19.2116792 },
    { lat: 48.8261367, lng: 19.2112267 },
    { lat: 48.8256603, lng: 19.2109599 },
    { lat: 48.8242256, lng: 19.2110314 },
    { lat: 48.8240193, lng: 19.211122 },
    { lat: 48.8236803, lng: 19.2109659 },
    { lat: 48.8233433, lng: 19.2109666 },
    { lat: 48.8231668, lng: 19.2109061 },
    { lat: 48.8230102, lng: 19.2107032 },
    { lat: 48.8225483, lng: 19.2104714 },
    { lat: 48.8222244, lng: 19.2104406 },
    { lat: 48.821854, lng: 19.2105416 },
    { lat: 48.8209178, lng: 19.2100668 },
    { lat: 48.820569, lng: 19.2098248 },
    { lat: 48.8201739, lng: 19.2096703 },
    { lat: 48.8197296, lng: 19.2097831 },
    { lat: 48.8193096, lng: 19.2100489 },
    { lat: 48.8188213, lng: 19.2102274 },
    { lat: 48.8184226, lng: 19.2104703 },
    { lat: 48.8181504, lng: 19.2104338 },
    { lat: 48.8180116, lng: 19.2105233 },
    { lat: 48.8178287, lng: 19.210912 },
    { lat: 48.8173387, lng: 19.2111955 },
    { lat: 48.8171626, lng: 19.2115192 },
    { lat: 48.8166831, lng: 19.2119027 },
    { lat: 48.8160607, lng: 19.2125246 },
    { lat: 48.8155064, lng: 19.2128564 },
    { lat: 48.8149393, lng: 19.2137822 },
    { lat: 48.8148188, lng: 19.2142921 },
    { lat: 48.8144552, lng: 19.2147464 },
    { lat: 48.814088, lng: 19.2150508 },
    { lat: 48.8130781, lng: 19.2153542 },
    { lat: 48.8127445, lng: 19.2160963 },
    { lat: 48.8123358, lng: 19.2162708 },
    { lat: 48.8118158, lng: 19.2162379 },
    { lat: 48.8108371, lng: 19.2163965 },
    { lat: 48.8103767, lng: 19.2168039 },
    { lat: 48.8098349, lng: 19.2169758 },
    { lat: 48.8089365, lng: 19.2176443 },
    { lat: 48.8069308, lng: 19.2186345 },
    { lat: 48.8064223, lng: 19.2191209 },
    { lat: 48.8057723, lng: 19.2195951 },
    { lat: 48.8044759, lng: 19.2198923 },
    { lat: 48.80414, lng: 19.220638 },
    { lat: 48.8036124, lng: 19.2211705 },
    { lat: 48.8009075, lng: 19.2224755 },
    { lat: 48.8006498, lng: 19.2229046 },
    { lat: 48.8001384, lng: 19.2248182 },
    { lat: 48.7998309, lng: 19.2254273 },
    { lat: 48.7996694, lng: 19.2262093 },
    { lat: 48.7989403, lng: 19.2281884 },
    { lat: 48.7988919, lng: 19.2284723 },
    { lat: 48.798546, lng: 19.2292408 },
    { lat: 48.7985704, lng: 19.2293972 },
    { lat: 48.7984703, lng: 19.2295881 },
    { lat: 48.7983465, lng: 19.2302412 },
    { lat: 48.7983324, lng: 19.2314876 },
    { lat: 48.7984267, lng: 19.2315966 },
    { lat: 48.7980761, lng: 19.2321561 },
    { lat: 48.7976449, lng: 19.2326054 },
    { lat: 48.797293, lng: 19.2327878 },
    { lat: 48.7964025, lng: 19.2351379 },
    { lat: 48.7959762, lng: 19.2357586 },
    { lat: 48.795191, lng: 19.2364212 },
    { lat: 48.7946016, lng: 19.2367947 },
    { lat: 48.7943165, lng: 19.2367505 },
    { lat: 48.7942801, lng: 19.2368139 },
    { lat: 48.7943192, lng: 19.237018 },
    { lat: 48.7932453, lng: 19.238 },
    { lat: 48.7933371, lng: 19.2382422 },
    { lat: 48.792914, lng: 19.2387005 },
    { lat: 48.7927126, lng: 19.2387801 },
    { lat: 48.7922385, lng: 19.2393456 },
    { lat: 48.7921152, lng: 19.2391505 },
    { lat: 48.7912572, lng: 19.240408 },
    { lat: 48.7907581, lng: 19.2429031 },
    { lat: 48.7903808, lng: 19.2431228 },
    { lat: 48.7899859, lng: 19.2431133 },
    { lat: 48.7898296, lng: 19.2431914 },
    { lat: 48.7897022, lng: 19.2430979 },
    { lat: 48.7896924, lng: 19.2432223 },
    { lat: 48.7886666, lng: 19.2430795 },
    { lat: 48.7886688, lng: 19.2431494 },
    { lat: 48.7885041, lng: 19.2431433 },
    { lat: 48.7875882, lng: 19.2429698 },
    { lat: 48.7871428, lng: 19.2434305 },
    { lat: 48.7863236, lng: 19.2438332 },
    { lat: 48.7850283, lng: 19.2446006 },
    { lat: 48.7847316, lng: 19.2447121 },
    { lat: 48.7844648, lng: 19.2448807 },
    { lat: 48.7836353, lng: 19.2456297 },
    { lat: 48.7832407, lng: 19.2462124 },
    { lat: 48.7830537, lng: 19.246401 },
    { lat: 48.7826677, lng: 19.2463404 },
    { lat: 48.7825499, lng: 19.2470998 },
    { lat: 48.782496, lng: 19.2471758 },
    { lat: 48.7823131, lng: 19.2470777 },
    { lat: 48.7822617, lng: 19.2471084 },
    { lat: 48.7820453, lng: 19.2475283 },
    { lat: 48.781402, lng: 19.2483951 },
    { lat: 48.7811721, lng: 19.2483065 },
    { lat: 48.7809799, lng: 19.2483846 },
    { lat: 48.7807718, lng: 19.2487675 },
    { lat: 48.7806792, lng: 19.2491372 },
    { lat: 48.7806539, lng: 19.2491969 },
    { lat: 48.7811173, lng: 19.2494598 },
    { lat: 48.7811384, lng: 19.2496144 },
    { lat: 48.7812735, lng: 19.249707 },
    { lat: 48.7813875, lng: 19.250198 },
    { lat: 48.7814472, lng: 19.2502214 },
    { lat: 48.7814243, lng: 19.2509377 },
    { lat: 48.781449, lng: 19.2511694 },
    { lat: 48.781532, lng: 19.2512793 },
    { lat: 48.7815193, lng: 19.2514812 },
    { lat: 48.7813186, lng: 19.2521338 },
    { lat: 48.7822187, lng: 19.2523085 },
    { lat: 48.7826585, lng: 19.2522216 },
    { lat: 48.7825764, lng: 19.2527847 },
    { lat: 48.7831823, lng: 19.2525855 },
    { lat: 48.7833335, lng: 19.2533602 },
    { lat: 48.783496, lng: 19.2533465 },
    { lat: 48.7834654, lng: 19.2535489 },
    { lat: 48.7837649, lng: 19.2538472 },
    { lat: 48.7836717, lng: 19.2543693 },
    { lat: 48.7838614, lng: 19.2559174 },
    { lat: 48.7841852, lng: 19.257048 },
    { lat: 48.7843075, lng: 19.2579491 },
    { lat: 48.7843407, lng: 19.2592883 },
    { lat: 48.784429, lng: 19.260047 },
    { lat: 48.7850183, lng: 19.2602783 },
    { lat: 48.7863525, lng: 19.2600381 },
    { lat: 48.7865495, lng: 19.2605908 },
    { lat: 48.786551, lng: 19.2607651 },
    { lat: 48.7868151, lng: 19.260796 },
    { lat: 48.7868643, lng: 19.2608549 },
    { lat: 48.7868623, lng: 19.2610932 },
    { lat: 48.7874901, lng: 19.2619361 },
    { lat: 48.7876657, lng: 19.262262 },
    { lat: 48.7877835, lng: 19.262787 },
    { lat: 48.7879579, lng: 19.2627578 },
    { lat: 48.7880272, lng: 19.2631433 },
    { lat: 48.7883345, lng: 19.2638844 },
    { lat: 48.7890716, lng: 19.2641384 },
    { lat: 48.7892495, lng: 19.2646053 },
    { lat: 48.7893879, lng: 19.2646433 },
    { lat: 48.7895801, lng: 19.2654517 },
    { lat: 48.7894844, lng: 19.2656192 },
    { lat: 48.7895461, lng: 19.2657315 },
    { lat: 48.790589, lng: 19.2653623 },
    { lat: 48.7914798, lng: 19.266728 },
    { lat: 48.7915762, lng: 19.2671173 },
    { lat: 48.7917165, lng: 19.2670333 },
    { lat: 48.7920832, lng: 19.2674879 },
    { lat: 48.7921132, lng: 19.2677141 },
    { lat: 48.7920158, lng: 19.2683351 },
    { lat: 48.7926197, lng: 19.2695973 },
    { lat: 48.7931942, lng: 19.2710612 },
    { lat: 48.7937681, lng: 19.2719857 },
    { lat: 48.794009, lng: 19.2733598 },
    { lat: 48.7940549, lng: 19.2739864 },
    { lat: 48.7940217, lng: 19.2747244 },
    { lat: 48.7937651, lng: 19.2772693 },
    { lat: 48.7938168, lng: 19.2779682 },
    { lat: 48.7940959, lng: 19.2797904 },
    { lat: 48.7942727, lng: 19.280231 },
    { lat: 48.79432, lng: 19.2805619 },
    { lat: 48.7949478, lng: 19.2818517 },
    { lat: 48.7954326, lng: 19.282982 },
    { lat: 48.7960659, lng: 19.2853316 },
    { lat: 48.7961909, lng: 19.2861276 },
    { lat: 48.7963828, lng: 19.2867451 },
    { lat: 48.7964997, lng: 19.2869507 },
    { lat: 48.7964095, lng: 19.2872083 },
    { lat: 48.7967227, lng: 19.2874846 },
    { lat: 48.7967209, lng: 19.2885058 },
    { lat: 48.7965508, lng: 19.2891222 },
    { lat: 48.7966968, lng: 19.2899796 },
    { lat: 48.7967736, lng: 19.2911337 },
  ],
  HornéPršany: [
    { lat: 48.7014891, lng: 19.0912085 },
    { lat: 48.7014751, lng: 19.0910841 },
    { lat: 48.7014498, lng: 19.0908703 },
    { lat: 48.7020432, lng: 19.0907651 },
    { lat: 48.7027635, lng: 19.0904485 },
    { lat: 48.7031642, lng: 19.090614 },
    { lat: 48.7036923, lng: 19.0905358 },
    { lat: 48.7041241, lng: 19.0906024 },
    { lat: 48.704548, lng: 19.0902434 },
    { lat: 48.7041134, lng: 19.0895757 },
    { lat: 48.7044828, lng: 19.0891375 },
    { lat: 48.7046734, lng: 19.0886847 },
    { lat: 48.7050399, lng: 19.0883679 },
    { lat: 48.7050519, lng: 19.0881117 },
    { lat: 48.7047613, lng: 19.0876889 },
    { lat: 48.70464, lng: 19.0876053 },
    { lat: 48.7044892, lng: 19.0869289 },
    { lat: 48.704472, lng: 19.0866075 },
    { lat: 48.7047123, lng: 19.0864513 },
    { lat: 48.7051012, lng: 19.0863818 },
    { lat: 48.7047765, lng: 19.0849139 },
    { lat: 48.7042317, lng: 19.0855387 },
    { lat: 48.7036853, lng: 19.0842584 },
    { lat: 48.7035306, lng: 19.0836621 },
    { lat: 48.7032413, lng: 19.0839191 },
    { lat: 48.7031084, lng: 19.0837172 },
    { lat: 48.7032205, lng: 19.0835253 },
    { lat: 48.7031604, lng: 19.083429 },
    { lat: 48.7032147, lng: 19.0833179 },
    { lat: 48.7032011, lng: 19.0831592 },
    { lat: 48.7033261, lng: 19.0827956 },
    { lat: 48.7032058, lng: 19.0825291 },
    { lat: 48.7029863, lng: 19.0830105 },
    { lat: 48.7027915, lng: 19.0829035 },
    { lat: 48.7026498, lng: 19.0827304 },
    { lat: 48.7026112, lng: 19.0823632 },
    { lat: 48.7027492, lng: 19.0823015 },
    { lat: 48.7028649, lng: 19.08193 },
    { lat: 48.7030121, lng: 19.0818979 },
    { lat: 48.7030934, lng: 19.0815378 },
    { lat: 48.7031336, lng: 19.0808316 },
    { lat: 48.7032607, lng: 19.080846 },
    { lat: 48.7032264, lng: 19.0815141 },
    { lat: 48.7033007, lng: 19.0815118 },
    { lat: 48.7033485, lng: 19.0812533 },
    { lat: 48.7033299, lng: 19.0809042 },
    { lat: 48.7034284, lng: 19.0808883 },
    { lat: 48.7033097, lng: 19.0794781 },
    { lat: 48.7036132, lng: 19.0796199 },
    { lat: 48.7035885, lng: 19.0793043 },
    { lat: 48.7028411, lng: 19.0784428 },
    { lat: 48.7023868, lng: 19.0780387 },
    { lat: 48.702192, lng: 19.0777718 },
    { lat: 48.7020423, lng: 19.0770104 },
    { lat: 48.702492, lng: 19.0766662 },
    { lat: 48.7029854, lng: 19.0764334 },
    { lat: 48.7025606, lng: 19.0749017 },
    { lat: 48.7028565, lng: 19.0732837 },
    { lat: 48.7027402, lng: 19.0729842 },
    { lat: 48.7028922, lng: 19.0727792 },
    { lat: 48.7033795, lng: 19.0729668 },
    { lat: 48.7033864, lng: 19.0727886 },
    { lat: 48.7042126, lng: 19.0723293 },
    { lat: 48.7046601, lng: 19.0718997 },
    { lat: 48.7050055, lng: 19.0718435 },
    { lat: 48.7054786, lng: 19.0713113 },
    { lat: 48.7056169, lng: 19.0706984 },
    { lat: 48.7057141, lng: 19.0699999 },
    { lat: 48.7057817, lng: 19.0699772 },
    { lat: 48.7058485, lng: 19.0697583 },
    { lat: 48.7059404, lng: 19.0691392 },
    { lat: 48.7060153, lng: 19.0689451 },
    { lat: 48.7059701, lng: 19.0685133 },
    { lat: 48.7060387, lng: 19.0683299 },
    { lat: 48.7059152, lng: 19.0681138 },
    { lat: 48.7056393, lng: 19.0680767 },
    { lat: 48.7052531, lng: 19.0680811 },
    { lat: 48.7046982, lng: 19.0682143 },
    { lat: 48.7040103, lng: 19.0681207 },
    { lat: 48.7039797, lng: 19.0677787 },
    { lat: 48.7040108, lng: 19.0677908 },
    { lat: 48.7036494, lng: 19.0674408 },
    { lat: 48.7034314, lng: 19.0674992 },
    { lat: 48.7032016, lng: 19.0677264 },
    { lat: 48.7028909, lng: 19.0678489 },
    { lat: 48.7029314, lng: 19.0675355 },
    { lat: 48.7019051, lng: 19.0679106 },
    { lat: 48.7019878, lng: 19.0669464 },
    { lat: 48.7018272, lng: 19.0665079 },
    { lat: 48.7016134, lng: 19.0662235 },
    { lat: 48.7018042, lng: 19.0659345 },
    { lat: 48.7018129, lng: 19.0656115 },
    { lat: 48.7026427, lng: 19.0655483 },
    { lat: 48.7027163, lng: 19.0654378 },
    { lat: 48.7030014, lng: 19.0652797 },
    { lat: 48.7034573, lng: 19.0642062 },
    { lat: 48.7036947, lng: 19.064172 },
    { lat: 48.703596, lng: 19.0637099 },
    { lat: 48.7034686, lng: 19.063733 },
    { lat: 48.703513, lng: 19.0632872 },
    { lat: 48.7037987, lng: 19.0629348 },
    { lat: 48.7038067, lng: 19.0625805 },
    { lat: 48.7039732, lng: 19.0623895 },
    { lat: 48.7040152, lng: 19.0619014 },
    { lat: 48.7039166, lng: 19.0619151 },
    { lat: 48.7034966, lng: 19.0614941 },
    { lat: 48.7028342, lng: 19.0610985 },
    { lat: 48.7023686, lng: 19.061368 },
    { lat: 48.7021056, lng: 19.0613191 },
    { lat: 48.7021766, lng: 19.0620591 },
    { lat: 48.7014688, lng: 19.0621524 },
    { lat: 48.7014003, lng: 19.0617087 },
    { lat: 48.7016927, lng: 19.0613812 },
    { lat: 48.7017701, lng: 19.0610779 },
    { lat: 48.7022224, lng: 19.0604281 },
    { lat: 48.702833, lng: 19.0599922 },
    { lat: 48.7025699, lng: 19.0594216 },
    { lat: 48.7024094, lng: 19.0588014 },
    { lat: 48.7026985, lng: 19.0582712 },
    { lat: 48.7051051, lng: 19.0588937 },
    { lat: 48.7051845, lng: 19.0592451 },
    { lat: 48.7055621, lng: 19.0593127 },
    { lat: 48.7057324, lng: 19.0592647 },
    { lat: 48.7056227, lng: 19.0587425 },
    { lat: 48.7052629, lng: 19.0586479 },
    { lat: 48.7053009, lng: 19.0583038 },
    { lat: 48.7057116, lng: 19.0584903 },
    { lat: 48.7059721, lng: 19.0577692 },
    { lat: 48.7054978, lng: 19.0573711 },
    { lat: 48.7051343, lng: 19.0576782 },
    { lat: 48.7049419, lng: 19.057597 },
    { lat: 48.705026, lng: 19.0573375 },
    { lat: 48.7049018, lng: 19.0571772 },
    { lat: 48.7045939, lng: 19.0571292 },
    { lat: 48.7043828, lng: 19.0573007 },
    { lat: 48.7039885, lng: 19.0569928 },
    { lat: 48.7041546, lng: 19.0562992 },
    { lat: 48.7037238, lng: 19.0559383 },
    { lat: 48.7032529, lng: 19.0561741 },
    { lat: 48.7030987, lng: 19.0557677 },
    { lat: 48.7032443, lng: 19.0549872 },
    { lat: 48.7032132, lng: 19.0543408 },
    { lat: 48.7032948, lng: 19.0543718 },
    { lat: 48.7033529, lng: 19.0540932 },
    { lat: 48.7029939, lng: 19.0535846 },
    { lat: 48.7028949, lng: 19.0531837 },
    { lat: 48.7028881, lng: 19.0528261 },
    { lat: 48.7032635, lng: 19.0516988 },
    { lat: 48.7038252, lng: 19.0518623 },
    { lat: 48.7041887, lng: 19.0525254 },
    { lat: 48.7047598, lng: 19.0528724 },
    { lat: 48.7050894, lng: 19.0537834 },
    { lat: 48.7054695, lng: 19.054577 },
    { lat: 48.7069232, lng: 19.055556 },
    { lat: 48.7072389, lng: 19.0556227 },
    { lat: 48.7070499, lng: 19.0548397 },
    { lat: 48.7068475, lng: 19.0546944 },
    { lat: 48.7066894, lng: 19.053744 },
    { lat: 48.706255, lng: 19.0537776 },
    { lat: 48.7063455, lng: 19.0536081 },
    { lat: 48.7066675, lng: 19.0533957 },
    { lat: 48.707022, lng: 19.0537442 },
    { lat: 48.7072337, lng: 19.0537168 },
    { lat: 48.7071267, lng: 19.0535613 },
    { lat: 48.7070835, lng: 19.0531468 },
    { lat: 48.7072322, lng: 19.0524919 },
    { lat: 48.7077801, lng: 19.0504885 },
    { lat: 48.7081443, lng: 19.0499482 },
    { lat: 48.7083263, lng: 19.0490828 },
    { lat: 48.7081471, lng: 19.0488352 },
    { lat: 48.7079353, lng: 19.0482283 },
    { lat: 48.7078822, lng: 19.047307 },
    { lat: 48.7069617, lng: 19.0465974 },
    { lat: 48.7066168, lng: 19.0464845 },
    { lat: 48.7062718, lng: 19.0461956 },
    { lat: 48.7060925, lng: 19.0461483 },
    { lat: 48.7056067, lng: 19.0465765 },
    { lat: 48.7051004, lng: 19.0464206 },
    { lat: 48.7042988, lng: 19.0456322 },
    { lat: 48.7039255, lng: 19.0455614 },
    { lat: 48.7035082, lng: 19.0457376 },
    { lat: 48.7024094, lng: 19.0464641 },
    { lat: 48.7020839, lng: 19.0465827 },
    { lat: 48.7006057, lng: 19.0463336 },
    { lat: 48.7002885, lng: 19.0466397 },
    { lat: 48.7002177, lng: 19.0489609 },
    { lat: 48.6998662, lng: 19.0492124 },
    { lat: 48.6994762, lng: 19.0488772 },
    { lat: 48.6995071, lng: 19.0486421 },
    { lat: 48.6996955, lng: 19.0483268 },
    { lat: 48.6994521, lng: 19.0483739 },
    { lat: 48.6990089, lng: 19.0486427 },
    { lat: 48.6986015, lng: 19.0487913 },
    { lat: 48.6981474, lng: 19.0491331 },
    { lat: 48.6980016, lng: 19.0495095 },
    { lat: 48.6979163, lng: 19.0494261 },
    { lat: 48.6978431, lng: 19.0498549 },
    { lat: 48.6979776, lng: 19.0501778 },
    { lat: 48.6980123, lng: 19.0505536 },
    { lat: 48.6979297, lng: 19.0507954 },
    { lat: 48.6979766, lng: 19.0510823 },
    { lat: 48.6983986, lng: 19.0518099 },
    { lat: 48.6987216, lng: 19.0526731 },
    { lat: 48.6985263, lng: 19.0536369 },
    { lat: 48.6983845, lng: 19.0539582 },
    { lat: 48.6979582, lng: 19.0546304 },
    { lat: 48.6974353, lng: 19.0552658 },
    { lat: 48.697385, lng: 19.0555678 },
    { lat: 48.6972737, lng: 19.0558646 },
    { lat: 48.6970096, lng: 19.055747 },
    { lat: 48.6967169, lng: 19.0568375 },
    { lat: 48.6962873, lng: 19.0579815 },
    { lat: 48.6957665, lng: 19.0598549 },
    { lat: 48.6957072, lng: 19.0607895 },
    { lat: 48.6955804, lng: 19.0611402 },
    { lat: 48.6954539, lng: 19.0623961 },
    { lat: 48.695428, lng: 19.0631635 },
    { lat: 48.6950869, lng: 19.0642552 },
    { lat: 48.6948157, lng: 19.0642879 },
    { lat: 48.6946844, lng: 19.0643699 },
    { lat: 48.6945763, lng: 19.064934 },
    { lat: 48.6945257, lng: 19.0656606 },
    { lat: 48.6943895, lng: 19.0664105 },
    { lat: 48.6943691, lng: 19.0669492 },
    { lat: 48.6939563, lng: 19.067533 },
    { lat: 48.6937777, lng: 19.0684937 },
    { lat: 48.6938002, lng: 19.0687644 },
    { lat: 48.6933896, lng: 19.0689632 },
    { lat: 48.6929236, lng: 19.0693379 },
    { lat: 48.6927162, lng: 19.0696973 },
    { lat: 48.6926681, lng: 19.0699315 },
    { lat: 48.6926547, lng: 19.0701607 },
    { lat: 48.6927606, lng: 19.0708809 },
    { lat: 48.6929734, lng: 19.0732457 },
    { lat: 48.6932123, lng: 19.0739904 },
    { lat: 48.6935272, lng: 19.0746529 },
    { lat: 48.6935861, lng: 19.0748874 },
    { lat: 48.6935253, lng: 19.0751366 },
    { lat: 48.6932065, lng: 19.0757506 },
    { lat: 48.6931877, lng: 19.0759837 },
    { lat: 48.6931948, lng: 19.077098 },
    { lat: 48.6932682, lng: 19.0776217 },
    { lat: 48.693231, lng: 19.0778222 },
    { lat: 48.693341, lng: 19.0783567 },
    { lat: 48.6932798, lng: 19.0790949 },
    { lat: 48.6934212, lng: 19.0795149 },
    { lat: 48.6934337, lng: 19.0803956 },
    { lat: 48.6933411, lng: 19.0814342 },
    { lat: 48.6931271, lng: 19.0823228 },
    { lat: 48.6931186, lng: 19.0825825 },
    { lat: 48.693206, lng: 19.0834082 },
    { lat: 48.6931344, lng: 19.0839395 },
    { lat: 48.6931873, lng: 19.085161 },
    { lat: 48.6931707, lng: 19.0854588 },
    { lat: 48.693005, lng: 19.0863679 },
    { lat: 48.6930282, lng: 19.0867442 },
    { lat: 48.6929574, lng: 19.0870402 },
    { lat: 48.6928422, lng: 19.0871943 },
    { lat: 48.6927501, lng: 19.0879295 },
    { lat: 48.6926247, lng: 19.0883298 },
    { lat: 48.6924961, lng: 19.0890535 },
    { lat: 48.6925154, lng: 19.0892804 },
    { lat: 48.6923636, lng: 19.0904691 },
    { lat: 48.6922333, lng: 19.0907508 },
    { lat: 48.6920028, lng: 19.0915788 },
    { lat: 48.6924275, lng: 19.0926599 },
    { lat: 48.692642, lng: 19.0935484 },
    { lat: 48.692901, lng: 19.093883 },
    { lat: 48.6928806, lng: 19.0944937 },
    { lat: 48.6931041, lng: 19.0946799 },
    { lat: 48.6930263, lng: 19.0957479 },
    { lat: 48.6930387, lng: 19.0960155 },
    { lat: 48.6931248, lng: 19.0961424 },
    { lat: 48.6930969, lng: 19.0962362 },
    { lat: 48.6931754, lng: 19.0963668 },
    { lat: 48.6936049, lng: 19.0955502 },
    { lat: 48.6937899, lng: 19.0954354 },
    { lat: 48.6937385, lng: 19.095063 },
    { lat: 48.6939299, lng: 19.0949203 },
    { lat: 48.6942285, lng: 19.0948689 },
    { lat: 48.6942431, lng: 19.0943923 },
    { lat: 48.6944403, lng: 19.0943492 },
    { lat: 48.6946599, lng: 19.0940945 },
    { lat: 48.6952232, lng: 19.0932894 },
    { lat: 48.6953558, lng: 19.0932162 },
    { lat: 48.6955015, lng: 19.0927671 },
    { lat: 48.6957062, lng: 19.0926229 },
    { lat: 48.6960404, lng: 19.0921553 },
    { lat: 48.6963112, lng: 19.0920479 },
    { lat: 48.6964867, lng: 19.0916115 },
    { lat: 48.6971033, lng: 19.0915636 },
    { lat: 48.6972443, lng: 19.091406 },
    { lat: 48.6978172, lng: 19.0914548 },
    { lat: 48.6979123, lng: 19.0913291 },
    { lat: 48.6980719, lng: 19.0913659 },
    { lat: 48.6987299, lng: 19.0909725 },
    { lat: 48.698597, lng: 19.0903848 },
    { lat: 48.6989917, lng: 19.0904749 },
    { lat: 48.6992645, lng: 19.0904553 },
    { lat: 48.6992472, lng: 19.0902396 },
    { lat: 48.6994638, lng: 19.0902439 },
    { lat: 48.6994243, lng: 19.0901763 },
    { lat: 48.6994936, lng: 19.0900086 },
    { lat: 48.6998536, lng: 19.0902636 },
    { lat: 48.6999528, lng: 19.0907387 },
    { lat: 48.700234, lng: 19.0907482 },
    { lat: 48.7004396, lng: 19.0912545 },
    { lat: 48.7005834, lng: 19.0913149 },
    { lat: 48.7008921, lng: 19.0910611 },
    { lat: 48.7009092, lng: 19.0912447 },
    { lat: 48.7011891, lng: 19.0911626 },
    { lat: 48.7013971, lng: 19.091194 },
    { lat: 48.7014891, lng: 19.0912085 },
  ],
  Poniky: [
    { lat: 48.724804, lng: 19.2424195 },
    { lat: 48.7248056, lng: 19.242404 },
    { lat: 48.724532, lng: 19.2423182 },
    { lat: 48.7243985, lng: 19.2423762 },
    { lat: 48.7241379, lng: 19.2430919 },
    { lat: 48.7235751, lng: 19.2439284 },
    { lat: 48.7224897, lng: 19.2450451 },
    { lat: 48.7211698, lng: 19.2468169 },
    { lat: 48.7208904, lng: 19.2470338 },
    { lat: 48.7207209, lng: 19.2474405 },
    { lat: 48.7203703, lng: 19.2479751 },
    { lat: 48.7189363, lng: 19.2496744 },
    { lat: 48.7179826, lng: 19.2494613 },
    { lat: 48.7177546, lng: 19.2494993 },
    { lat: 48.7177406, lng: 19.2498657 },
    { lat: 48.7169499, lng: 19.249853 },
    { lat: 48.7167579, lng: 19.2497297 },
    { lat: 48.7163644, lng: 19.2502143 },
    { lat: 48.7161796, lng: 19.2502868 },
    { lat: 48.7157717, lng: 19.2501487 },
    { lat: 48.7150581, lng: 19.2488882 },
    { lat: 48.7143819, lng: 19.2481466 },
    { lat: 48.7138253, lng: 19.2483158 },
    { lat: 48.7132228, lng: 19.2487707 },
    { lat: 48.7126665, lng: 19.2493853 },
    { lat: 48.7121797, lng: 19.2502261 },
    { lat: 48.7118995, lng: 19.2504483 },
    { lat: 48.7114659, lng: 19.2505222 },
    { lat: 48.71112, lng: 19.25104 },
    { lat: 48.7106583, lng: 19.251051 },
    { lat: 48.7105145, lng: 19.2511301 },
    { lat: 48.7103884, lng: 19.2513214 },
    { lat: 48.7102695, lng: 19.2518663 },
    { lat: 48.7100231, lng: 19.2524127 },
    { lat: 48.710059, lng: 19.2539288 },
    { lat: 48.7100101, lng: 19.2545684 },
    { lat: 48.7097729, lng: 19.2548661 },
    { lat: 48.7094562, lng: 19.2550042 },
    { lat: 48.7079981, lng: 19.25511 },
    { lat: 48.706978, lng: 19.2547785 },
    { lat: 48.7065351, lng: 19.2564843 },
    { lat: 48.7048068, lng: 19.2597966 },
    { lat: 48.7036294, lng: 19.2616521 },
    { lat: 48.7022241, lng: 19.2633384 },
    { lat: 48.7015394, lng: 19.2642837 },
    { lat: 48.7010937, lng: 19.2653765 },
    { lat: 48.7009649, lng: 19.2660023 },
    { lat: 48.7012139, lng: 19.2696938 },
    { lat: 48.7010471, lng: 19.2706973 },
    { lat: 48.7013638, lng: 19.2722904 },
    { lat: 48.7014047, lng: 19.2732072 },
    { lat: 48.7004016, lng: 19.2741699 },
    { lat: 48.6999006, lng: 19.2730792 },
    { lat: 48.6990193, lng: 19.2718176 },
    { lat: 48.698421, lng: 19.2716165 },
    { lat: 48.6979133, lng: 19.2721451 },
    { lat: 48.6975114, lng: 19.2723188 },
    { lat: 48.6967992, lng: 19.2714298 },
    { lat: 48.6956148, lng: 19.2707357 },
    { lat: 48.6950596, lng: 19.2721501 },
    { lat: 48.6951044, lng: 19.2726832 },
    { lat: 48.6948065, lng: 19.2731419 },
    { lat: 48.6948079, lng: 19.2732652 },
    { lat: 48.6949177, lng: 19.2734604 },
    { lat: 48.694632, lng: 19.2739182 },
    { lat: 48.6946469, lng: 19.2740172 },
    { lat: 48.6948894, lng: 19.2741372 },
    { lat: 48.694868, lng: 19.2746062 },
    { lat: 48.6947612, lng: 19.2746597 },
    { lat: 48.6947399, lng: 19.2748011 },
    { lat: 48.6945078, lng: 19.2748745 },
    { lat: 48.6943968, lng: 19.2748417 },
    { lat: 48.6940646, lng: 19.2755953 },
    { lat: 48.6938165, lng: 19.2756864 },
    { lat: 48.6937351, lng: 19.2758192 },
    { lat: 48.6939057, lng: 19.2760862 },
    { lat: 48.6939024, lng: 19.2764651 },
    { lat: 48.6934309, lng: 19.2765961 },
    { lat: 48.6930871, lng: 19.2764305 },
    { lat: 48.692989, lng: 19.2768308 },
    { lat: 48.6928857, lng: 19.2766727 },
    { lat: 48.6921655, lng: 19.2759762 },
    { lat: 48.6916767, lng: 19.275865 },
    { lat: 48.6912374, lng: 19.2761994 },
    { lat: 48.6910362, lng: 19.2766321 },
    { lat: 48.6909252, lng: 19.2770885 },
    { lat: 48.6907919, lng: 19.2771745 },
    { lat: 48.6903718, lng: 19.2767846 },
    { lat: 48.6901198, lng: 19.2769129 },
    { lat: 48.6897609, lng: 19.276948 },
    { lat: 48.6896227, lng: 19.2768394 },
    { lat: 48.6894604, lng: 19.2768401 },
    { lat: 48.6888242, lng: 19.2773463 },
    { lat: 48.6890125, lng: 19.2776766 },
    { lat: 48.6891473, lng: 19.2780646 },
    { lat: 48.6892864, lng: 19.2788937 },
    { lat: 48.6898384, lng: 19.2803168 },
    { lat: 48.6899463, lng: 19.2807345 },
    { lat: 48.6898642, lng: 19.2814381 },
    { lat: 48.6895445, lng: 19.2821724 },
    { lat: 48.6896795, lng: 19.282519 },
    { lat: 48.6900949, lng: 19.2841983 },
    { lat: 48.6901331, lng: 19.2846632 },
    { lat: 48.6900651, lng: 19.2852194 },
    { lat: 48.6900259, lng: 19.2861623 },
    { lat: 48.6899254, lng: 19.2866966 },
    { lat: 48.6899433, lng: 19.2871666 },
    { lat: 48.6902616, lng: 19.2886497 },
    { lat: 48.6905381, lng: 19.2896223 },
    { lat: 48.6907772, lng: 19.2912667 },
    { lat: 48.6903921, lng: 19.2918401 },
    { lat: 48.6901104, lng: 19.2918667 },
    { lat: 48.6899367, lng: 19.2917719 },
    { lat: 48.6894482, lng: 19.2918043 },
    { lat: 48.6892843, lng: 19.2928283 },
    { lat: 48.689523, lng: 19.2932006 },
    { lat: 48.6895728, lng: 19.2944256 },
    { lat: 48.6893113, lng: 19.2946161 },
    { lat: 48.6892188, lng: 19.2948949 },
    { lat: 48.688981, lng: 19.2949883 },
    { lat: 48.6884098, lng: 19.2958396 },
    { lat: 48.6881615, lng: 19.296537 },
    { lat: 48.6879354, lng: 19.296855 },
    { lat: 48.687455, lng: 19.2979288 },
    { lat: 48.6873055, lng: 19.2980579 },
    { lat: 48.6869365, lng: 19.2981161 },
    { lat: 48.6867349, lng: 19.2982152 },
    { lat: 48.6867813, lng: 19.299016 },
    { lat: 48.6866707, lng: 19.2995328 },
    { lat: 48.6864259, lng: 19.3000479 },
    { lat: 48.685821, lng: 19.3008307 },
    { lat: 48.6857238, lng: 19.3011278 },
    { lat: 48.6859273, lng: 19.3022705 },
    { lat: 48.6857415, lng: 19.3033132 },
    { lat: 48.6858244, lng: 19.303933 },
    { lat: 48.6860806, lng: 19.304765 },
    { lat: 48.6859916, lng: 19.3050038 },
    { lat: 48.6859902, lng: 19.305798 },
    { lat: 48.6861083, lng: 19.3067539 },
    { lat: 48.6859964, lng: 19.3070576 },
    { lat: 48.6856763, lng: 19.3075298 },
    { lat: 48.6855288, lng: 19.3078377 },
    { lat: 48.6852503, lng: 19.3087991 },
    { lat: 48.6850432, lng: 19.3092055 },
    { lat: 48.684883, lng: 19.3093668 },
    { lat: 48.6845681, lng: 19.3095485 },
    { lat: 48.6836863, lng: 19.3094913 },
    { lat: 48.6831993, lng: 19.3095844 },
    { lat: 48.6823027, lng: 19.3096184 },
    { lat: 48.6809505, lng: 19.3101704 },
    { lat: 48.6806639, lng: 19.3103768 },
    { lat: 48.6804646, lng: 19.3106828 },
    { lat: 48.6804189, lng: 19.3108822 },
    { lat: 48.6804389, lng: 19.3109436 },
    { lat: 48.6805918, lng: 19.3114126 },
    { lat: 48.680936, lng: 19.3120508 },
    { lat: 48.6809752, lng: 19.3121234 },
    { lat: 48.6810129, lng: 19.3124178 },
    { lat: 48.680896, lng: 19.3131985 },
    { lat: 48.6809335, lng: 19.3138888 },
    { lat: 48.6811565, lng: 19.314453 },
    { lat: 48.6812086, lng: 19.3147427 },
    { lat: 48.6812073, lng: 19.3147865 },
    { lat: 48.6811738, lng: 19.315946 },
    { lat: 48.6813852, lng: 19.317045 },
    { lat: 48.6813572, lng: 19.3174067 },
    { lat: 48.6813468, lng: 19.3175414 },
    { lat: 48.6811964, lng: 19.3182959 },
    { lat: 48.6811333, lng: 19.3186126 },
    { lat: 48.6811191, lng: 19.3191677 },
    { lat: 48.680971, lng: 19.3195316 },
    { lat: 48.6809359, lng: 19.3201236 },
    { lat: 48.6809888, lng: 19.3202965 },
    { lat: 48.6810255, lng: 19.3204163 },
    { lat: 48.6811349, lng: 19.3206121 },
    { lat: 48.6811895, lng: 19.3207098 },
    { lat: 48.6815582, lng: 19.3221794 },
    { lat: 48.6814694, lng: 19.3227238 },
    { lat: 48.6815016, lng: 19.3232353 },
    { lat: 48.6816176, lng: 19.323656 },
    { lat: 48.6815888, lng: 19.3239984 },
    { lat: 48.6816418, lng: 19.3242583 },
    { lat: 48.6815839, lng: 19.324627 },
    { lat: 48.6816204, lng: 19.325605 },
    { lat: 48.6817047, lng: 19.3265716 },
    { lat: 48.681788, lng: 19.3267819 },
    { lat: 48.6817217, lng: 19.3274479 },
    { lat: 48.6818124, lng: 19.3275265 },
    { lat: 48.6818944, lng: 19.3278536 },
    { lat: 48.6819386, lng: 19.3287569 },
    { lat: 48.6822914, lng: 19.3297953 },
    { lat: 48.6821693, lng: 19.3322479 },
    { lat: 48.6813987, lng: 19.3331317 },
    { lat: 48.6807523, lng: 19.3351543 },
    { lat: 48.6805673, lng: 19.3365672 },
    { lat: 48.6803285, lng: 19.337519 },
    { lat: 48.6784279, lng: 19.3379158 },
    { lat: 48.6771451, lng: 19.3377933 },
    { lat: 48.6769716, lng: 19.3389559 },
    { lat: 48.6765161, lng: 19.3408234 },
    { lat: 48.6764383, lng: 19.3466282 },
    { lat: 48.6759291, lng: 19.3482932 },
    { lat: 48.6752917, lng: 19.3487068 },
    { lat: 48.6745178, lng: 19.3488563 },
    { lat: 48.6738278, lng: 19.3477602 },
    { lat: 48.671691, lng: 19.3463664 },
    { lat: 48.6716804, lng: 19.3463576 },
    { lat: 48.6716659, lng: 19.3464312 },
    { lat: 48.671564, lng: 19.3464928 },
    { lat: 48.671562, lng: 19.3470162 },
    { lat: 48.6714655, lng: 19.3471493 },
    { lat: 48.6714753, lng: 19.3475441 },
    { lat: 48.6712798, lng: 19.3485723 },
    { lat: 48.6710273, lng: 19.3490254 },
    { lat: 48.6710246, lng: 19.3492771 },
    { lat: 48.6709252, lng: 19.3495364 },
    { lat: 48.670971, lng: 19.3496898 },
    { lat: 48.6708051, lng: 19.3502537 },
    { lat: 48.6707194, lng: 19.3503995 },
    { lat: 48.6706526, lng: 19.3507227 },
    { lat: 48.6705986, lng: 19.3511262 },
    { lat: 48.6706324, lng: 19.3515297 },
    { lat: 48.6703414, lng: 19.3518829 },
    { lat: 48.6701525, lng: 19.3520139 },
    { lat: 48.6700028, lng: 19.352014 },
    { lat: 48.6699065, lng: 19.3522195 },
    { lat: 48.6696569, lng: 19.3523489 },
    { lat: 48.6696278, lng: 19.3526366 },
    { lat: 48.6695296, lng: 19.3526814 },
    { lat: 48.6690972, lng: 19.3534496 },
    { lat: 48.669146, lng: 19.3535517 },
    { lat: 48.668854, lng: 19.3540732 },
    { lat: 48.6687889, lng: 19.3545516 },
    { lat: 48.6687, lng: 19.3547481 },
    { lat: 48.6686577, lng: 19.3550176 },
    { lat: 48.6684094, lng: 19.3556082 },
    { lat: 48.668375, lng: 19.3555938 },
    { lat: 48.6682592, lng: 19.3558356 },
    { lat: 48.6682859, lng: 19.3559004 },
    { lat: 48.6681596, lng: 19.3560927 },
    { lat: 48.6681332, lng: 19.3560528 },
    { lat: 48.6680348, lng: 19.3561701 },
    { lat: 48.6677582, lng: 19.3566061 },
    { lat: 48.6676401, lng: 19.3568995 },
    { lat: 48.6675224, lng: 19.3570078 },
    { lat: 48.6673119, lng: 19.3573697 },
    { lat: 48.6670082, lng: 19.3582119 },
    { lat: 48.6668928, lng: 19.3589643 },
    { lat: 48.6669222, lng: 19.3597049 },
    { lat: 48.6668828, lng: 19.3607713 },
    { lat: 48.6667616, lng: 19.3615681 },
    { lat: 48.6666509, lng: 19.361968 },
    { lat: 48.6666376, lng: 19.3627083 },
    { lat: 48.66698, lng: 19.3641324 },
    { lat: 48.6672656, lng: 19.3648058 },
    { lat: 48.6674348, lng: 19.3650471 },
    { lat: 48.6677841, lng: 19.3659999 },
    { lat: 48.6677862, lng: 19.3665681 },
    { lat: 48.6679135, lng: 19.3682787 },
    { lat: 48.6680992, lng: 19.3693665 },
    { lat: 48.6681904, lng: 19.3702217 },
    { lat: 48.6682794, lng: 19.3705091 },
    { lat: 48.6682495, lng: 19.3708749 },
    { lat: 48.6682899, lng: 19.3725885 },
    { lat: 48.6683109, lng: 19.3729125 },
    { lat: 48.6684118, lng: 19.3732817 },
    { lat: 48.6682526, lng: 19.3742516 },
    { lat: 48.6682356, lng: 19.3757995 },
    { lat: 48.6680798, lng: 19.3781076 },
    { lat: 48.6681229, lng: 19.3788496 },
    { lat: 48.6681044, lng: 19.3795786 },
    { lat: 48.6682317, lng: 19.3799636 },
    { lat: 48.6682091, lng: 19.3806296 },
    { lat: 48.6682967, lng: 19.3814538 },
    { lat: 48.6683044, lng: 19.3822884 },
    { lat: 48.6686249, lng: 19.3854248 },
    { lat: 48.6686298, lng: 19.3863635 },
    { lat: 48.6685343, lng: 19.3870872 },
    { lat: 48.6686146, lng: 19.3877642 },
    { lat: 48.6687486, lng: 19.3883571 },
    { lat: 48.6689548, lng: 19.3888566 },
    { lat: 48.6690178, lng: 19.3893065 },
    { lat: 48.6691958, lng: 19.389691 },
    { lat: 48.669283, lng: 19.3904137 },
    { lat: 48.6693329, lng: 19.3905142 },
    { lat: 48.6694594, lng: 19.3905937 },
    { lat: 48.6696836, lng: 19.3910948 },
    { lat: 48.669777, lng: 19.3911383 },
    { lat: 48.6704016, lng: 19.3923387 },
    { lat: 48.6707123, lng: 19.3939609 },
    { lat: 48.6707457, lng: 19.3945931 },
    { lat: 48.6707287, lng: 19.3949751 },
    { lat: 48.6706476, lng: 19.3953846 },
    { lat: 48.6701735, lng: 19.3969969 },
    { lat: 48.6700349, lng: 19.3971872 },
    { lat: 48.6699267, lng: 19.3976881 },
    { lat: 48.6694362, lng: 19.3991673 },
    { lat: 48.6693934, lng: 19.3995535 },
    { lat: 48.6692307, lng: 19.4000205 },
    { lat: 48.6686242, lng: 19.40136 },
    { lat: 48.6685575, lng: 19.4023609 },
    { lat: 48.6685444, lng: 19.4040213 },
    { lat: 48.6687856, lng: 19.4046237 },
    { lat: 48.6698141, lng: 19.4062667 },
    { lat: 48.6700951, lng: 19.4069953 },
    { lat: 48.6702678, lng: 19.4076443 },
    { lat: 48.6705738, lng: 19.408141 },
    { lat: 48.6710896, lng: 19.4087283 },
    { lat: 48.6714289, lng: 19.4089469 },
    { lat: 48.6721851, lng: 19.4096165 },
    { lat: 48.6725527, lng: 19.410388 },
    { lat: 48.672958, lng: 19.4115295 },
    { lat: 48.6733566, lng: 19.4122685 },
    { lat: 48.6735513, lng: 19.4127686 },
    { lat: 48.6736559, lng: 19.4143731 },
    { lat: 48.6736481, lng: 19.414947 },
    { lat: 48.6737171, lng: 19.4153404 },
    { lat: 48.6738733, lng: 19.4158807 },
    { lat: 48.6743512, lng: 19.4165074 },
    { lat: 48.6755187, lng: 19.420492 },
    { lat: 48.675555, lng: 19.4209538 },
    { lat: 48.6757311, lng: 19.4213954 },
    { lat: 48.6757383, lng: 19.421907 },
    { lat: 48.675816, lng: 19.4221501 },
    { lat: 48.6768386, lng: 19.4239138 },
    { lat: 48.6772339, lng: 19.4247502 },
    { lat: 48.6774593, lng: 19.4251105 },
    { lat: 48.6775999, lng: 19.4256565 },
    { lat: 48.6778868, lng: 19.4263202 },
    { lat: 48.678097, lng: 19.4271276 },
    { lat: 48.6782282, lng: 19.4281249 },
    { lat: 48.6782228, lng: 19.4292863 },
    { lat: 48.6784618, lng: 19.4301288 },
    { lat: 48.678588, lng: 19.4303674 },
    { lat: 48.67878, lng: 19.4310987 },
    { lat: 48.6788308, lng: 19.4322102 },
    { lat: 48.67879, lng: 19.4326151 },
    { lat: 48.6786945, lng: 19.4326431 },
    { lat: 48.6789495, lng: 19.4330866 },
    { lat: 48.679481, lng: 19.4343009 },
    { lat: 48.6799793, lng: 19.4352713 },
    { lat: 48.6801589, lng: 19.4357122 },
    { lat: 48.6802864, lng: 19.436511 },
    { lat: 48.680891, lng: 19.4372697 },
    { lat: 48.6809831, lng: 19.4376522 },
    { lat: 48.6810005, lng: 19.4380012 },
    { lat: 48.6809385, lng: 19.4380738 },
    { lat: 48.6814186, lng: 19.4386749 },
    { lat: 48.6814749, lng: 19.4388633 },
    { lat: 48.6815163, lng: 19.4396531 },
    { lat: 48.681484, lng: 19.4400028 },
    { lat: 48.6815493, lng: 19.4406981 },
    { lat: 48.6815423, lng: 19.4413476 },
    { lat: 48.6814519, lng: 19.4417538 },
    { lat: 48.6814988, lng: 19.4418886 },
    { lat: 48.6840359, lng: 19.44066 },
    { lat: 48.6838369, lng: 19.4400186 },
    { lat: 48.6838434, lng: 19.439846 },
    { lat: 48.6837724, lng: 19.4397248 },
    { lat: 48.6837908, lng: 19.438502 },
    { lat: 48.6837334, lng: 19.4382895 },
    { lat: 48.6836961, lng: 19.4376069 },
    { lat: 48.6836739, lng: 19.4359309 },
    { lat: 48.683719, lng: 19.4344356 },
    { lat: 48.6836461, lng: 19.4338663 },
    { lat: 48.6834426, lng: 19.4332015 },
    { lat: 48.6834103, lng: 19.4323882 },
    { lat: 48.6832412, lng: 19.4318595 },
    { lat: 48.6830269, lng: 19.4314932 },
    { lat: 48.6829293, lng: 19.4311627 },
    { lat: 48.6828677, lng: 19.4311247 },
    { lat: 48.6828316, lng: 19.4307612 },
    { lat: 48.6827689, lng: 19.4306983 },
    { lat: 48.6825881, lng: 19.4301095 },
    { lat: 48.6826006, lng: 19.4298728 },
    { lat: 48.6824539, lng: 19.4290043 },
    { lat: 48.6825079, lng: 19.4289255 },
    { lat: 48.6825218, lng: 19.4287446 },
    { lat: 48.6827827, lng: 19.4283694 },
    { lat: 48.6831456, lng: 19.4274543 },
    { lat: 48.68324, lng: 19.4273783 },
    { lat: 48.6832787, lng: 19.4272397 },
    { lat: 48.6837569, lng: 19.4265024 },
    { lat: 48.6840071, lng: 19.4257788 },
    { lat: 48.6842619, lng: 19.4254216 },
    { lat: 48.6843723, lng: 19.4253474 },
    { lat: 48.6843995, lng: 19.4250157 },
    { lat: 48.6844516, lng: 19.4249487 },
    { lat: 48.6845796, lng: 19.4242168 },
    { lat: 48.6845591, lng: 19.4239001 },
    { lat: 48.6846256, lng: 19.423419 },
    { lat: 48.6845721, lng: 19.4229232 },
    { lat: 48.6846197, lng: 19.4225932 },
    { lat: 48.6847161, lng: 19.4224121 },
    { lat: 48.6848572, lng: 19.4223249 },
    { lat: 48.6849359, lng: 19.4219588 },
    { lat: 48.6849164, lng: 19.4218293 },
    { lat: 48.6851616, lng: 19.4213875 },
    { lat: 48.6854978, lng: 19.4210127 },
    { lat: 48.6857068, lng: 19.4206583 },
    { lat: 48.6862114, lng: 19.4204852 },
    { lat: 48.6865065, lng: 19.4206046 },
    { lat: 48.6873151, lng: 19.4204945 },
    { lat: 48.6878675, lng: 19.4201865 },
    { lat: 48.6880162, lng: 19.4200383 },
    { lat: 48.6881952, lng: 19.4196749 },
    { lat: 48.6882922, lng: 19.4191459 },
    { lat: 48.6881828, lng: 19.4183023 },
    { lat: 48.6880811, lng: 19.4180845 },
    { lat: 48.6880602, lng: 19.4175428 },
    { lat: 48.6881388, lng: 19.417294 },
    { lat: 48.6882783, lng: 19.4170975 },
    { lat: 48.6883259, lng: 19.416909 },
    { lat: 48.6885704, lng: 19.4164524 },
    { lat: 48.6888274, lng: 19.4163068 },
    { lat: 48.6890696, lng: 19.4160676 },
    { lat: 48.6892783, lng: 19.4157342 },
    { lat: 48.6895295, lng: 19.4156813 },
    { lat: 48.6899658, lng: 19.4149893 },
    { lat: 48.6903443, lng: 19.4145806 },
    { lat: 48.6905453, lng: 19.414466 },
    { lat: 48.690706, lng: 19.4140953 },
    { lat: 48.690979, lng: 19.4138631 },
    { lat: 48.6912963, lng: 19.4137296 },
    { lat: 48.6917763, lng: 19.4139686 },
    { lat: 48.6924308, lng: 19.4133113 },
    { lat: 48.6925399, lng: 19.412924 },
    { lat: 48.6929674, lng: 19.412286 },
    { lat: 48.693077, lng: 19.412001 },
    { lat: 48.6931837, lng: 19.4119236 },
    { lat: 48.6935342, lng: 19.4113449 },
    { lat: 48.6936222, lng: 19.4112791 },
    { lat: 48.6936647, lng: 19.4110776 },
    { lat: 48.6939827, lng: 19.410532 },
    { lat: 48.6940486, lng: 19.4102542 },
    { lat: 48.6943751, lng: 19.4097654 },
    { lat: 48.6945175, lng: 19.4094623 },
    { lat: 48.6945819, lng: 19.4091539 },
    { lat: 48.6951335, lng: 19.4081081 },
    { lat: 48.695256, lng: 19.4076943 },
    { lat: 48.6955864, lng: 19.4071455 },
    { lat: 48.6958732, lng: 19.4068922 },
    { lat: 48.6959475, lng: 19.4067476 },
    { lat: 48.6960917, lng: 19.4060539 },
    { lat: 48.6960382, lng: 19.4054617 },
    { lat: 48.6959691, lng: 19.4052191 },
    { lat: 48.6961149, lng: 19.4048428 },
    { lat: 48.6960967, lng: 19.4040235 },
    { lat: 48.6961921, lng: 19.4038473 },
    { lat: 48.6962752, lng: 19.4034802 },
    { lat: 48.696284, lng: 19.4022321 },
    { lat: 48.6963501, lng: 19.4019792 },
    { lat: 48.6965411, lng: 19.4016326 },
    { lat: 48.6966654, lng: 19.4011359 },
    { lat: 48.6968985, lng: 19.400941 },
    { lat: 48.6970063, lng: 19.4005509 },
    { lat: 48.6970547, lng: 19.3998782 },
    { lat: 48.6969969, lng: 19.3993679 },
    { lat: 48.6968444, lng: 19.3987621 },
    { lat: 48.6968482, lng: 19.3977638 },
    { lat: 48.6970363, lng: 19.3975249 },
    { lat: 48.6972443, lng: 19.3974879 },
    { lat: 48.6974803, lng: 19.3972346 },
    { lat: 48.6977342, lng: 19.3967118 },
    { lat: 48.6975388, lng: 19.3961284 },
    { lat: 48.6974861, lng: 19.3950352 },
    { lat: 48.6976237, lng: 19.3944614 },
    { lat: 48.6977399, lng: 19.3943205 },
    { lat: 48.6978065, lng: 19.3941353 },
    { lat: 48.6977909, lng: 19.3939001 },
    { lat: 48.6978341, lng: 19.393594 },
    { lat: 48.6978161, lng: 19.3935963 },
    { lat: 48.6978152, lng: 19.3933819 },
    { lat: 48.6976733, lng: 19.3930971 },
    { lat: 48.6974453, lng: 19.3923282 },
    { lat: 48.6973779, lng: 19.3918545 },
    { lat: 48.6974634, lng: 19.3909634 },
    { lat: 48.6972986, lng: 19.390482 },
    { lat: 48.6973074, lng: 19.3901175 },
    { lat: 48.6971926, lng: 19.3898816 },
    { lat: 48.6971979, lng: 19.3897305 },
    { lat: 48.6971127, lng: 19.3896465 },
    { lat: 48.6970584, lng: 19.3894501 },
    { lat: 48.6969011, lng: 19.3892201 },
    { lat: 48.6968942, lng: 19.3888837 },
    { lat: 48.6966983, lng: 19.3884342 },
    { lat: 48.696457, lng: 19.3880952 },
    { lat: 48.6964329, lng: 19.387659 },
    { lat: 48.6963453, lng: 19.3875197 },
    { lat: 48.6963125, lng: 19.3871104 },
    { lat: 48.6961481, lng: 19.3868998 },
    { lat: 48.6961769, lng: 19.3867682 },
    { lat: 48.6961408, lng: 19.3862174 },
    { lat: 48.6965502, lng: 19.3852447 },
    { lat: 48.6965036, lng: 19.3850934 },
    { lat: 48.6965551, lng: 19.3849926 },
    { lat: 48.6966276, lng: 19.3849987 },
    { lat: 48.6967251, lng: 19.3848213 },
    { lat: 48.6967093, lng: 19.3845561 },
    { lat: 48.6968153, lng: 19.384341 },
    { lat: 48.6970168, lng: 19.383361 },
    { lat: 48.6969517, lng: 19.3828361 },
    { lat: 48.6969963, lng: 19.382731 },
    { lat: 48.6969909, lng: 19.3824009 },
    { lat: 48.6968224, lng: 19.3818197 },
    { lat: 48.6968537, lng: 19.3817414 },
    { lat: 48.6968144, lng: 19.3814349 },
    { lat: 48.6968662, lng: 19.3812905 },
    { lat: 48.6968945, lng: 19.3807202 },
    { lat: 48.69704, lng: 19.3806046 },
    { lat: 48.6970979, lng: 19.3804214 },
    { lat: 48.6971118, lng: 19.3802608 },
    { lat: 48.697, lng: 19.3800185 },
    { lat: 48.697078, lng: 19.3796647 },
    { lat: 48.6970563, lng: 19.3794891 },
    { lat: 48.6969849, lng: 19.3793626 },
    { lat: 48.6970202, lng: 19.3789407 },
    { lat: 48.6971142, lng: 19.3788556 },
    { lat: 48.6971011, lng: 19.3781285 },
    { lat: 48.6971868, lng: 19.3779819 },
    { lat: 48.6973934, lng: 19.3778259 },
    { lat: 48.6974732, lng: 19.3775318 },
    { lat: 48.6975962, lng: 19.3773709 },
    { lat: 48.6979316, lng: 19.3772658 },
    { lat: 48.6980293, lng: 19.3770438 },
    { lat: 48.6979884, lng: 19.3767231 },
    { lat: 48.69809, lng: 19.3763005 },
    { lat: 48.6980782, lng: 19.3760745 },
    { lat: 48.6979995, lng: 19.3759345 },
    { lat: 48.6980978, lng: 19.37527 },
    { lat: 48.6982738, lng: 19.3749923 },
    { lat: 48.6982383, lng: 19.3748092 },
    { lat: 48.6984868, lng: 19.3745105 },
    { lat: 48.6986519, lng: 19.3740025 },
    { lat: 48.6988058, lng: 19.3738464 },
    { lat: 48.6989251, lng: 19.3736255 },
    { lat: 48.6989248, lng: 19.3732163 },
    { lat: 48.6991276, lng: 19.3728341 },
    { lat: 48.6995572, lng: 19.3725501 },
    { lat: 48.7004668, lng: 19.372103 },
    { lat: 48.7006087, lng: 19.371976 },
    { lat: 48.7008052, lng: 19.3712227 },
    { lat: 48.7009187, lng: 19.3710995 },
    { lat: 48.7013253, lng: 19.3710192 },
    { lat: 48.7018395, lng: 19.3707146 },
    { lat: 48.7023692, lng: 19.3704994 },
    { lat: 48.7030522, lng: 19.3699803 },
    { lat: 48.7032581, lng: 19.3696355 },
    { lat: 48.7033222, lng: 19.3689417 },
    { lat: 48.70347, lng: 19.3685879 },
    { lat: 48.7035878, lng: 19.3679332 },
    { lat: 48.7036626, lng: 19.3678267 },
    { lat: 48.7038166, lng: 19.3676723 },
    { lat: 48.704061, lng: 19.3675967 },
    { lat: 48.7047498, lng: 19.3676992 },
    { lat: 48.7053719, lng: 19.3667403 },
    { lat: 48.7056475, lng: 19.3659726 },
    { lat: 48.7057368, lng: 19.3658327 },
    { lat: 48.7058387, lng: 19.3657461 },
    { lat: 48.7062341, lng: 19.3656263 },
    { lat: 48.7066745, lng: 19.3653317 },
    { lat: 48.7068381, lng: 19.3646949 },
    { lat: 48.7069622, lng: 19.3639354 },
    { lat: 48.7069647, lng: 19.3626762 },
    { lat: 48.7070066, lng: 19.3625389 },
    { lat: 48.707366, lng: 19.361988 },
    { lat: 48.7075748, lng: 19.3614965 },
    { lat: 48.7079769, lng: 19.3613453 },
    { lat: 48.7080023, lng: 19.3613357 },
    { lat: 48.7082157, lng: 19.36099 },
    { lat: 48.708318, lng: 19.3606974 },
    { lat: 48.7083634, lng: 19.3595873 },
    { lat: 48.7084414, lng: 19.3593983 },
    { lat: 48.7086133, lng: 19.3586057 },
    { lat: 48.7088999, lng: 19.3583741 },
    { lat: 48.7093087, lng: 19.3582945 },
    { lat: 48.7096878, lng: 19.3580198 },
    { lat: 48.7122555, lng: 19.356885 },
    { lat: 48.7128463, lng: 19.3560812 },
    { lat: 48.7139306, lng: 19.3561262 },
    { lat: 48.7161366, lng: 19.3554905 },
    { lat: 48.7183892, lng: 19.3561724 },
    { lat: 48.7186235, lng: 19.3563865 },
    { lat: 48.7188792, lng: 19.3562653 },
    { lat: 48.7191341, lng: 19.3562406 },
    { lat: 48.7193013, lng: 19.3561311 },
    { lat: 48.7194411, lng: 19.3562505 },
    { lat: 48.7195755, lng: 19.3562359 },
    { lat: 48.7196693, lng: 19.3562618 },
    { lat: 48.7197191, lng: 19.3561462 },
    { lat: 48.7198206, lng: 19.3560809 },
    { lat: 48.7199106, lng: 19.3561468 },
    { lat: 48.7199575, lng: 19.3561139 },
    { lat: 48.7201152, lng: 19.3558045 },
    { lat: 48.7203325, lng: 19.35563 },
    { lat: 48.7206166, lng: 19.3558491 },
    { lat: 48.7207828, lng: 19.3556915 },
    { lat: 48.7210309, lng: 19.3558146 },
    { lat: 48.7212274, lng: 19.3554413 },
    { lat: 48.7214946, lng: 19.3555124 },
    { lat: 48.7216845, lng: 19.3553836 },
    { lat: 48.7220131, lng: 19.3552802 },
    { lat: 48.7221344, lng: 19.3549155 },
    { lat: 48.7223777, lng: 19.3546707 },
    { lat: 48.7226682, lng: 19.3547606 },
    { lat: 48.723115, lng: 19.3547697 },
    { lat: 48.7232214, lng: 19.3546132 },
    { lat: 48.7232885, lng: 19.354365 },
    { lat: 48.7234829, lng: 19.3542365 },
    { lat: 48.7237818, lng: 19.3542384 },
    { lat: 48.7240452, lng: 19.3540417 },
    { lat: 48.7247354, lng: 19.3542165 },
    { lat: 48.7248698, lng: 19.3540091 },
    { lat: 48.7250072, lng: 19.3539118 },
    { lat: 48.7251707, lng: 19.353983 },
    { lat: 48.7252845, lng: 19.3536297 },
    { lat: 48.7254761, lng: 19.3536311 },
    { lat: 48.7257108, lng: 19.3538495 },
    { lat: 48.7259004, lng: 19.3539025 },
    { lat: 48.7260117, lng: 19.3538487 },
    { lat: 48.7262908, lng: 19.3533408 },
    { lat: 48.7264894, lng: 19.3532004 },
    { lat: 48.7265444, lng: 19.3529849 },
    { lat: 48.7266933, lng: 19.3527231 },
    { lat: 48.7269852, lng: 19.3524891 },
    { lat: 48.7274716, lng: 19.3525967 },
    { lat: 48.7280159, lng: 19.3524995 },
    { lat: 48.7281334, lng: 19.352551 },
    { lat: 48.7283536, lng: 19.3522758 },
    { lat: 48.7285155, lng: 19.3522169 },
    { lat: 48.7289044, lng: 19.3523397 },
    { lat: 48.7289876, lng: 19.3521671 },
    { lat: 48.7290808, lng: 19.352092 },
    { lat: 48.7291243, lng: 19.352127 },
    { lat: 48.72947, lng: 19.3515526 },
    { lat: 48.7296175, lng: 19.3514555 },
    { lat: 48.7300007, lng: 19.351359 },
    { lat: 48.7300615, lng: 19.3510979 },
    { lat: 48.7302385, lng: 19.3509305 },
    { lat: 48.7303812, lng: 19.3504495 },
    { lat: 48.7306125, lng: 19.3502852 },
    { lat: 48.7307869, lng: 19.3502788 },
    { lat: 48.7309534, lng: 19.3501786 },
    { lat: 48.731285, lng: 19.349706 },
    { lat: 48.7314459, lng: 19.3497718 },
    { lat: 48.7316059, lng: 19.3494663 },
    { lat: 48.7316563, lng: 19.3491931 },
    { lat: 48.7318457, lng: 19.3489358 },
    { lat: 48.7320048, lng: 19.3489639 },
    { lat: 48.7322426, lng: 19.3487728 },
    { lat: 48.7321369, lng: 19.348423 },
    { lat: 48.7321904, lng: 19.3479566 },
    { lat: 48.7321231, lng: 19.3478929 },
    { lat: 48.7320331, lng: 19.3476388 },
    { lat: 48.7321301, lng: 19.3472598 },
    { lat: 48.7323054, lng: 19.3468417 },
    { lat: 48.7326706, lng: 19.3466046 },
    { lat: 48.7327538, lng: 19.3464817 },
    { lat: 48.7328133, lng: 19.3461236 },
    { lat: 48.7330021, lng: 19.3459671 },
    { lat: 48.7329872, lng: 19.3458466 },
    { lat: 48.7332108, lng: 19.3453589 },
    { lat: 48.7331464, lng: 19.3452555 },
    { lat: 48.7330964, lng: 19.3447983 },
    { lat: 48.7332692, lng: 19.3443539 },
    { lat: 48.7332203, lng: 19.3441804 },
    { lat: 48.7332237, lng: 19.343774 },
    { lat: 48.7332771, lng: 19.3435472 },
    { lat: 48.7332833, lng: 19.3432302 },
    { lat: 48.7333339, lng: 19.3431069 },
    { lat: 48.7334431, lng: 19.3431533 },
    { lat: 48.7335454, lng: 19.3428605 },
    { lat: 48.7337653, lng: 19.3426731 },
    { lat: 48.733836, lng: 19.3428332 },
    { lat: 48.7340104, lng: 19.3429242 },
    { lat: 48.7341948, lng: 19.3429128 },
    { lat: 48.7344959, lng: 19.3430379 },
    { lat: 48.7347029, lng: 19.3429804 },
    { lat: 48.7349269, lng: 19.3430461 },
    { lat: 48.7350618, lng: 19.3432257 },
    { lat: 48.7352927, lng: 19.3432002 },
    { lat: 48.735398, lng: 19.3431138 },
    { lat: 48.7354445, lng: 19.3422697 },
    { lat: 48.7355922, lng: 19.3418191 },
    { lat: 48.7356075, lng: 19.3416186 },
    { lat: 48.735489, lng: 19.3411873 },
    { lat: 48.7355659, lng: 19.3410272 },
    { lat: 48.7357638, lng: 19.3399535 },
    { lat: 48.7358639, lng: 19.3398277 },
    { lat: 48.7359407, lng: 19.3394755 },
    { lat: 48.7360648, lng: 19.3393149 },
    { lat: 48.7361107, lng: 19.3391621 },
    { lat: 48.7360855, lng: 19.3390348 },
    { lat: 48.7362635, lng: 19.3387491 },
    { lat: 48.7363964, lng: 19.3381316 },
    { lat: 48.7366134, lng: 19.3376939 },
    { lat: 48.7369932, lng: 19.3373619 },
    { lat: 48.7370351, lng: 19.3372503 },
    { lat: 48.7369953, lng: 19.3371239 },
    { lat: 48.7370085, lng: 19.3369496 },
    { lat: 48.7371821, lng: 19.3367098 },
    { lat: 48.7372154, lng: 19.3365376 },
    { lat: 48.7371714, lng: 19.3362767 },
    { lat: 48.7372961, lng: 19.3361239 },
    { lat: 48.7373066, lng: 19.335991 },
    { lat: 48.7371374, lng: 19.3358462 },
    { lat: 48.7371257, lng: 19.3356679 },
    { lat: 48.7372229, lng: 19.3353449 },
    { lat: 48.7370644, lng: 19.3349023 },
    { lat: 48.737175, lng: 19.3345946 },
    { lat: 48.7371614, lng: 19.3344529 },
    { lat: 48.7370563, lng: 19.3342104 },
    { lat: 48.7371148, lng: 19.3339706 },
    { lat: 48.7373179, lng: 19.3336418 },
    { lat: 48.7373164, lng: 19.333448 },
    { lat: 48.7373755, lng: 19.3332691 },
    { lat: 48.7373746, lng: 19.3331325 },
    { lat: 48.7372281, lng: 19.3329917 },
    { lat: 48.7369949, lng: 19.3329192 },
    { lat: 48.7369759, lng: 19.3327095 },
    { lat: 48.7370758, lng: 19.3325366 },
    { lat: 48.737201, lng: 19.3324676 },
    { lat: 48.7371978, lng: 19.3323055 },
    { lat: 48.7369979, lng: 19.3317034 },
    { lat: 48.7370208, lng: 19.3315242 },
    { lat: 48.7370789, lng: 19.331439 },
    { lat: 48.7372303, lng: 19.3316128 },
    { lat: 48.7374306, lng: 19.3316792 },
    { lat: 48.7374786, lng: 19.3313373 },
    { lat: 48.737569, lng: 19.3312021 },
    { lat: 48.7376933, lng: 19.3311582 },
    { lat: 48.7378547, lng: 19.3312107 },
    { lat: 48.7379827, lng: 19.3310825 },
    { lat: 48.7380524, lng: 19.3309142 },
    { lat: 48.7383025, lng: 19.330937 },
    { lat: 48.7384178, lng: 19.3308009 },
    { lat: 48.7386148, lng: 19.3307466 },
    { lat: 48.7387051, lng: 19.3306332 },
    { lat: 48.7386993, lng: 19.3303668 },
    { lat: 48.7387583, lng: 19.3302578 },
    { lat: 48.7388961, lng: 19.3302646 },
    { lat: 48.7389977, lng: 19.3301971 },
    { lat: 48.7392039, lng: 19.33034 },
    { lat: 48.7392885, lng: 19.3305986 },
    { lat: 48.7393811, lng: 19.3306489 },
    { lat: 48.7394693, lng: 19.3305365 },
    { lat: 48.7395098, lng: 19.3301828 },
    { lat: 48.7399491, lng: 19.3297272 },
    { lat: 48.7402394, lng: 19.3292407 },
    { lat: 48.7404546, lng: 19.3290541 },
    { lat: 48.740525, lng: 19.3290636 },
    { lat: 48.7404917, lng: 19.3288597 },
    { lat: 48.7406377, lng: 19.3281432 },
    { lat: 48.7409619, lng: 19.3279665 },
    { lat: 48.7414793, lng: 19.3269253 },
    { lat: 48.7421799, lng: 19.3260438 },
    { lat: 48.7425387, lng: 19.32598 },
    { lat: 48.7430203, lng: 19.325678 },
    { lat: 48.7428717, lng: 19.3254956 },
    { lat: 48.74244, lng: 19.3250441 },
    { lat: 48.7422962, lng: 19.3247677 },
    { lat: 48.7418813, lng: 19.3245754 },
    { lat: 48.7415203, lng: 19.3248551 },
    { lat: 48.7411523, lng: 19.3243699 },
    { lat: 48.7408006, lng: 19.3240618 },
    { lat: 48.7406378, lng: 19.3238131 },
    { lat: 48.7402228, lng: 19.3229357 },
    { lat: 48.7396053, lng: 19.3219228 },
    { lat: 48.7392791, lng: 19.3219562 },
    { lat: 48.7390385, lng: 19.3216403 },
    { lat: 48.7386917, lng: 19.320629 },
    { lat: 48.7384572, lng: 19.3202297 },
    { lat: 48.7382931, lng: 19.3197209 },
    { lat: 48.7380066, lng: 19.3192401 },
    { lat: 48.7375605, lng: 19.3187503 },
    { lat: 48.7373183, lng: 19.3185642 },
    { lat: 48.7373168, lng: 19.3186972 },
    { lat: 48.7370591, lng: 19.3185618 },
    { lat: 48.7369216, lng: 19.3183747 },
    { lat: 48.7364257, lng: 19.3182124 },
    { lat: 48.7361564, lng: 19.3178115 },
    { lat: 48.735862, lng: 19.3176394 },
    { lat: 48.7359035, lng: 19.3165737 },
    { lat: 48.7360017, lng: 19.315796 },
    { lat: 48.7362172, lng: 19.3146217 },
    { lat: 48.7363951, lng: 19.3142431 },
    { lat: 48.7365664, lng: 19.3134138 },
    { lat: 48.7363811, lng: 19.312535 },
    { lat: 48.7362543, lng: 19.3122646 },
    { lat: 48.7357598, lng: 19.3113467 },
    { lat: 48.7353985, lng: 19.3109569 },
    { lat: 48.7352042, lng: 19.3104294 },
    { lat: 48.734871, lng: 19.3098473 },
    { lat: 48.7347493, lng: 19.3072327 },
    { lat: 48.7348167, lng: 19.3058617 },
    { lat: 48.734718, lng: 19.3052113 },
    { lat: 48.734526, lng: 19.3042143 },
    { lat: 48.7341895, lng: 19.3031896 },
    { lat: 48.7341473, lng: 19.30292 },
    { lat: 48.7340262, lng: 19.3028604 },
    { lat: 48.7336488, lng: 19.3023229 },
    { lat: 48.7333557, lng: 19.3017326 },
    { lat: 48.7328654, lng: 19.3009822 },
    { lat: 48.7325412, lng: 19.2999047 },
    { lat: 48.7326087, lng: 19.298586 },
    { lat: 48.7323653, lng: 19.2967985 },
    { lat: 48.7323577, lng: 19.2962855 },
    { lat: 48.7327513, lng: 19.295844 },
    { lat: 48.7330548, lng: 19.2952387 },
    { lat: 48.7330303, lng: 19.2950575 },
    { lat: 48.7332026, lng: 19.2949858 },
    { lat: 48.7332893, lng: 19.2943269 },
    { lat: 48.7335541, lng: 19.2936859 },
    { lat: 48.733732, lng: 19.2929541 },
    { lat: 48.734356, lng: 19.291982 },
    { lat: 48.7346278, lng: 19.2898488 },
    { lat: 48.7345404, lng: 19.2896709 },
    { lat: 48.734597, lng: 19.2894183 },
    { lat: 48.734554, lng: 19.2884986 },
    { lat: 48.7352545, lng: 19.2868446 },
    { lat: 48.7357975, lng: 19.2858738 },
    { lat: 48.7363096, lng: 19.2858109 },
    { lat: 48.7367728, lng: 19.2854833 },
    { lat: 48.7373663, lng: 19.2853543 },
    { lat: 48.737833, lng: 19.2850235 },
    { lat: 48.7385239, lng: 19.2842524 },
    { lat: 48.7389906, lng: 19.2840641 },
    { lat: 48.739148, lng: 19.2838194 },
    { lat: 48.7393503, lng: 19.283505 },
    { lat: 48.7398759, lng: 19.2829256 },
    { lat: 48.7404609, lng: 19.2815633 },
    { lat: 48.7404491, lng: 19.2808 },
    { lat: 48.7403356, lng: 19.2801294 },
    { lat: 48.7403794, lng: 19.2788097 },
    { lat: 48.7409485, lng: 19.2787382 },
    { lat: 48.7414539, lng: 19.2782563 },
    { lat: 48.7416901, lng: 19.277544 },
    { lat: 48.7418263, lng: 19.2765291 },
    { lat: 48.7419491, lng: 19.2760821 },
    { lat: 48.7433609, lng: 19.2740116 },
    { lat: 48.7434686, lng: 19.272853 },
    { lat: 48.7436335, lng: 19.2719946 },
    { lat: 48.7422386, lng: 19.2654277 },
    { lat: 48.7422373, lng: 19.2654004 },
    { lat: 48.7422358, lng: 19.2629328 },
    { lat: 48.7420271, lng: 19.2621956 },
    { lat: 48.7418664, lng: 19.2616276 },
    { lat: 48.7412684, lng: 19.259515 },
    { lat: 48.7392439, lng: 19.2595706 },
    { lat: 48.7391296, lng: 19.2595737 },
    { lat: 48.7386371, lng: 19.2590131 },
    { lat: 48.7383005, lng: 19.2589302 },
    { lat: 48.7379561, lng: 19.2583561 },
    { lat: 48.7377497, lng: 19.258166 },
    { lat: 48.7374867, lng: 19.2577137 },
    { lat: 48.7369929, lng: 19.257666 },
    { lat: 48.7364337, lng: 19.2578012 },
    { lat: 48.7361894, lng: 19.2577614 },
    { lat: 48.7361975, lng: 19.2573216 },
    { lat: 48.7359535, lng: 19.2567733 },
    { lat: 48.73579, lng: 19.2567042 },
    { lat: 48.7354501, lng: 19.2560383 },
    { lat: 48.7354243, lng: 19.2560847 },
    { lat: 48.7351904, lng: 19.2558148 },
    { lat: 48.7351571, lng: 19.2558205 },
    { lat: 48.7347666, lng: 19.2558873 },
    { lat: 48.7347023, lng: 19.2558645 },
    { lat: 48.7342482, lng: 19.2557031 },
    { lat: 48.7335233, lng: 19.2555609 },
    { lat: 48.7332656, lng: 19.2553358 },
    { lat: 48.7332349, lng: 19.2552741 },
    { lat: 48.7328126, lng: 19.2544246 },
    { lat: 48.7327564, lng: 19.2536824 },
    { lat: 48.7326407, lng: 19.2532714 },
    { lat: 48.7327705, lng: 19.2526193 },
    { lat: 48.7328898, lng: 19.2513329 },
    { lat: 48.7326417, lng: 19.2507019 },
    { lat: 48.7324437, lng: 19.2504306 },
    { lat: 48.7322954, lng: 19.2499046 },
    { lat: 48.7323248, lng: 19.2490223 },
    { lat: 48.7322091, lng: 19.2480246 },
    { lat: 48.7322034, lng: 19.2475796 },
    { lat: 48.7319185, lng: 19.2475836 },
    { lat: 48.731867, lng: 19.2474761 },
    { lat: 48.7317857, lng: 19.2474744 },
    { lat: 48.7311984, lng: 19.2477067 },
    { lat: 48.7309847, lng: 19.2475778 },
    { lat: 48.7303126, lng: 19.2474093 },
    { lat: 48.7300046, lng: 19.2468181 },
    { lat: 48.7294328, lng: 19.2468563 },
    { lat: 48.7291105, lng: 19.2469523 },
    { lat: 48.7286785, lng: 19.2469478 },
    { lat: 48.7278242, lng: 19.2467384 },
    { lat: 48.7268309, lng: 19.2454754 },
    { lat: 48.7265891, lng: 19.245018 },
    { lat: 48.7262774, lng: 19.2445669 },
    { lat: 48.726046, lng: 19.2440891 },
    { lat: 48.7257594, lng: 19.2438537 },
    { lat: 48.7255234, lng: 19.2435649 },
    { lat: 48.7253985, lng: 19.2432678 },
    { lat: 48.7251032, lng: 19.2430073 },
    { lat: 48.7249676, lng: 19.2425385 },
    { lat: 48.724804, lng: 19.2424195 },
  ],
  SebedínBečov: [
    { lat: 48.644365, lng: 19.282955 },
    { lat: 48.6445943, lng: 19.2845264 },
    { lat: 48.644919, lng: 19.287389 },
    { lat: 48.6456867, lng: 19.2871541 },
    { lat: 48.6456651, lng: 19.287128 },
    { lat: 48.6457726, lng: 19.286809 },
    { lat: 48.6466033, lng: 19.2852131 },
    { lat: 48.646838, lng: 19.2850787 },
    { lat: 48.6471338, lng: 19.2847383 },
    { lat: 48.6480477, lng: 19.2840561 },
    { lat: 48.6482194, lng: 19.2836634 },
    { lat: 48.6490314, lng: 19.2829944 },
    { lat: 48.6494505, lng: 19.282335 },
    { lat: 48.6496651, lng: 19.2822734 },
    { lat: 48.6502431, lng: 19.2823626 },
    { lat: 48.6505713, lng: 19.2823209 },
    { lat: 48.6505612, lng: 19.2812918 },
    { lat: 48.6506244, lng: 19.280773 },
    { lat: 48.6504087, lng: 19.2802972 },
    { lat: 48.6503508, lng: 19.279894 },
    { lat: 48.6504368, lng: 19.2791983 },
    { lat: 48.650623, lng: 19.2789444 },
    { lat: 48.6509183, lng: 19.2789459 },
    { lat: 48.6511695, lng: 19.2778885 },
    { lat: 48.6509783, lng: 19.2773808 },
    { lat: 48.6508644, lng: 19.2774486 },
    { lat: 48.6506392, lng: 19.2772455 },
    { lat: 48.6505569, lng: 19.2770314 },
    { lat: 48.6505647, lng: 19.2769386 },
    { lat: 48.6506637, lng: 19.2768165 },
    { lat: 48.6505256, lng: 19.2765251 },
    { lat: 48.6504925, lng: 19.2761166 },
    { lat: 48.650334, lng: 19.2758463 },
    { lat: 48.6503398, lng: 19.2752172 },
    { lat: 48.6502454, lng: 19.2750134 },
    { lat: 48.6499702, lng: 19.2749138 },
    { lat: 48.6499486, lng: 19.2747265 },
    { lat: 48.6498962, lng: 19.2746881 },
    { lat: 48.6499073, lng: 19.2744513 },
    { lat: 48.6500786, lng: 19.2742866 },
    { lat: 48.650068, lng: 19.2740215 },
    { lat: 48.6501218, lng: 19.2738256 },
    { lat: 48.6500675, lng: 19.273777 },
    { lat: 48.6500559, lng: 19.2733249 },
    { lat: 48.6499649, lng: 19.2729813 },
    { lat: 48.6499634, lng: 19.2725287 },
    { lat: 48.6499009, lng: 19.2721182 },
    { lat: 48.6499452, lng: 19.2719393 },
    { lat: 48.6498323, lng: 19.2715628 },
    { lat: 48.6498873, lng: 19.2713967 },
    { lat: 48.6498222, lng: 19.2712606 },
    { lat: 48.6498266, lng: 19.2711157 },
    { lat: 48.6498934, lng: 19.2710268 },
    { lat: 48.6498832, lng: 19.2704197 },
    { lat: 48.6500055, lng: 19.2699875 },
    { lat: 48.6499401, lng: 19.2697519 },
    { lat: 48.6498194, lng: 19.2697045 },
    { lat: 48.6498174, lng: 19.2695697 },
    { lat: 48.6498645, lng: 19.2694729 },
    { lat: 48.6497596, lng: 19.2693062 },
    { lat: 48.6497454, lng: 19.2686393 },
    { lat: 48.649818, lng: 19.2684357 },
    { lat: 48.6497327, lng: 19.2682351 },
    { lat: 48.649779, lng: 19.2680735 },
    { lat: 48.6495564, lng: 19.2676399 },
    { lat: 48.649645, lng: 19.267469 },
    { lat: 48.6495975, lng: 19.2673263 },
    { lat: 48.6495883, lng: 19.2666668 },
    { lat: 48.6494549, lng: 19.2663097 },
    { lat: 48.6494456, lng: 19.2659999 },
    { lat: 48.6494899, lng: 19.2658656 },
    { lat: 48.6493469, lng: 19.2655213 },
    { lat: 48.6493967, lng: 19.2652898 },
    { lat: 48.6493098, lng: 19.2649636 },
    { lat: 48.6493959, lng: 19.2648276 },
    { lat: 48.6493397, lng: 19.264555 },
    { lat: 48.6494095, lng: 19.2637595 },
    { lat: 48.6493723, lng: 19.2634791 },
    { lat: 48.6494115, lng: 19.26319 },
    { lat: 48.6493553, lng: 19.2629598 },
    { lat: 48.6493908, lng: 19.2626005 },
    { lat: 48.6493314, lng: 19.2624898 },
    { lat: 48.6495194, lng: 19.2617615 },
    { lat: 48.6495328, lng: 19.261245 },
    { lat: 48.6496555, lng: 19.2611498 },
    { lat: 48.6496414, lng: 19.2608826 },
    { lat: 48.6497018, lng: 19.260779 },
    { lat: 48.6495653, lng: 19.2606368 },
    { lat: 48.6495694, lng: 19.2605119 },
    { lat: 48.6497057, lng: 19.2601351 },
    { lat: 48.6496722, lng: 19.2599994 },
    { lat: 48.6497001, lng: 19.2598777 },
    { lat: 48.6497774, lng: 19.2598692 },
    { lat: 48.6498316, lng: 19.2597552 },
    { lat: 48.6498213, lng: 19.2595652 },
    { lat: 48.6498961, lng: 19.2593146 },
    { lat: 48.6498255, lng: 19.259116 },
    { lat: 48.6499278, lng: 19.2588284 },
    { lat: 48.6499408, lng: 19.2585843 },
    { lat: 48.6498855, lng: 19.2583961 },
    { lat: 48.6499804, lng: 19.2581229 },
    { lat: 48.6499564, lng: 19.2578603 },
    { lat: 48.6498735, lng: 19.2577768 },
    { lat: 48.6499452, lng: 19.2574858 },
    { lat: 48.6498551, lng: 19.2573717 },
    { lat: 48.649875, lng: 19.2572472 },
    { lat: 48.6497685, lng: 19.2571207 },
    { lat: 48.6495864, lng: 19.2562884 },
    { lat: 48.6495441, lng: 19.2555309 },
    { lat: 48.6494054, lng: 19.2553213 },
    { lat: 48.6493912, lng: 19.2552134 },
    { lat: 48.6494265, lng: 19.2550594 },
    { lat: 48.649334, lng: 19.2546836 },
    { lat: 48.6494922, lng: 19.2542045 },
    { lat: 48.6494058, lng: 19.2540928 },
    { lat: 48.6494027, lng: 19.253936 },
    { lat: 48.6494688, lng: 19.2538324 },
    { lat: 48.6493927, lng: 19.2537307 },
    { lat: 48.6493877, lng: 19.2534414 },
    { lat: 48.6494392, lng: 19.2532026 },
    { lat: 48.6493251, lng: 19.253054 },
    { lat: 48.6493186, lng: 19.2528772 },
    { lat: 48.6493917, lng: 19.252825 },
    { lat: 48.6493374, lng: 19.252493 },
    { lat: 48.6492352, lng: 19.2522704 },
    { lat: 48.6494617, lng: 19.251334 },
    { lat: 48.6497879, lng: 19.2504354 },
    { lat: 48.6502058, lng: 19.2488942 },
    { lat: 48.6504343, lng: 19.2485165 },
    { lat: 48.6504425, lng: 19.2482892 },
    { lat: 48.6511173, lng: 19.2442321 },
    { lat: 48.6510875, lng: 19.2442211 },
    { lat: 48.6511418, lng: 19.2436644 },
    { lat: 48.651211, lng: 19.2436742 },
    { lat: 48.6512347, lng: 19.2435347 },
    { lat: 48.6512843, lng: 19.2428387 },
    { lat: 48.6516373, lng: 19.2406084 },
    { lat: 48.6517138, lng: 19.2398378 },
    { lat: 48.6518534, lng: 19.2396965 },
    { lat: 48.651887, lng: 19.2381096 },
    { lat: 48.6523159, lng: 19.2360113 },
    { lat: 48.6525048, lng: 19.2358828 },
    { lat: 48.6525749, lng: 19.2357263 },
    { lat: 48.652708, lng: 19.2357295 },
    { lat: 48.6527589, lng: 19.2353805 },
    { lat: 48.6528687, lng: 19.2353463 },
    { lat: 48.6528865, lng: 19.2349921 },
    { lat: 48.6530091, lng: 19.2350074 },
    { lat: 48.6530591, lng: 19.2345292 },
    { lat: 48.653285, lng: 19.2339366 },
    { lat: 48.6538721, lng: 19.2336756 },
    { lat: 48.6542075, lng: 19.233199 },
    { lat: 48.6543684, lng: 19.2328453 },
    { lat: 48.6544408, lng: 19.2329208 },
    { lat: 48.6547018, lng: 19.2321714 },
    { lat: 48.6549111, lng: 19.2322299 },
    { lat: 48.6555202, lng: 19.2308466 },
    { lat: 48.6555668, lng: 19.2305526 },
    { lat: 48.6557096, lng: 19.2301268 },
    { lat: 48.6556918, lng: 19.2299691 },
    { lat: 48.6557649, lng: 19.2297824 },
    { lat: 48.6558658, lng: 19.2288625 },
    { lat: 48.6560488, lng: 19.2283167 },
    { lat: 48.6561392, lng: 19.2282279 },
    { lat: 48.6563322, lng: 19.2276076 },
    { lat: 48.6564212, lng: 19.2275385 },
    { lat: 48.6564475, lng: 19.2273787 },
    { lat: 48.6569775, lng: 19.2269215 },
    { lat: 48.6571614, lng: 19.2265784 },
    { lat: 48.6575414, lng: 19.2262757 },
    { lat: 48.6576246, lng: 19.2262972 },
    { lat: 48.657643, lng: 19.2260734 },
    { lat: 48.6582462, lng: 19.2251919 },
    { lat: 48.6582634, lng: 19.2251768 },
    { lat: 48.6579641, lng: 19.2250933 },
    { lat: 48.6577703, lng: 19.2251875 },
    { lat: 48.6577194, lng: 19.2251172 },
    { lat: 48.6576746, lng: 19.2251443 },
    { lat: 48.6575987, lng: 19.2249769 },
    { lat: 48.6573831, lng: 19.2249448 },
    { lat: 48.6571683, lng: 19.225025 },
    { lat: 48.6571465, lng: 19.2248334 },
    { lat: 48.6568988, lng: 19.2240972 },
    { lat: 48.6568877, lng: 19.223426 },
    { lat: 48.6568055, lng: 19.2232408 },
    { lat: 48.6566127, lng: 19.2230568 },
    { lat: 48.6565629, lng: 19.2227069 },
    { lat: 48.6567111, lng: 19.222622 },
    { lat: 48.6567146, lng: 19.2225567 },
    { lat: 48.6566358, lng: 19.2222834 },
    { lat: 48.6563565, lng: 19.2221928 },
    { lat: 48.6563022, lng: 19.222048 },
    { lat: 48.6561459, lng: 19.2220101 },
    { lat: 48.6560332, lng: 19.22185 },
    { lat: 48.6560548, lng: 19.221643 },
    { lat: 48.6559904, lng: 19.2214305 },
    { lat: 48.6560226, lng: 19.2212829 },
    { lat: 48.6559494, lng: 19.221097 },
    { lat: 48.6560165, lng: 19.2209955 },
    { lat: 48.6560214, lng: 19.220889 },
    { lat: 48.655854, lng: 19.2205988 },
    { lat: 48.6556948, lng: 19.220685 },
    { lat: 48.6553509, lng: 19.2206379 },
    { lat: 48.6553002, lng: 19.2205665 },
    { lat: 48.6551861, lng: 19.2203099 },
    { lat: 48.6551644, lng: 19.2199077 },
    { lat: 48.6550955, lng: 19.2198344 },
    { lat: 48.6550263, lng: 19.2195052 },
    { lat: 48.6546277, lng: 19.2194442 },
    { lat: 48.6546295, lng: 19.2192497 },
    { lat: 48.6545158, lng: 19.2189029 },
    { lat: 48.6545359, lng: 19.2185962 },
    { lat: 48.6546573, lng: 19.2184313 },
    { lat: 48.6546698, lng: 19.2183386 },
    { lat: 48.6545279, lng: 19.2181565 },
    { lat: 48.6542778, lng: 19.2181379 },
    { lat: 48.654013, lng: 19.217877 },
    { lat: 48.654008, lng: 19.217917 },
    { lat: 48.653846, lng: 19.218393 },
    { lat: 48.653673, lng: 19.218905 },
    { lat: 48.653569, lng: 19.219057 },
    { lat: 48.653552, lng: 19.219081 },
    { lat: 48.653415, lng: 19.219279 },
    { lat: 48.653241, lng: 19.219142 },
    { lat: 48.65325, lng: 19.219325 },
    { lat: 48.653053, lng: 19.219721 },
    { lat: 48.65312, lng: 19.220052 },
    { lat: 48.652934, lng: 19.22044 },
    { lat: 48.652825, lng: 19.221003 },
    { lat: 48.652625, lng: 19.221242 },
    { lat: 48.652522, lng: 19.221367 },
    { lat: 48.652373, lng: 19.22201 },
    { lat: 48.652212, lng: 19.222461 },
    { lat: 48.652246, lng: 19.22273 },
    { lat: 48.652116, lng: 19.22287 },
    { lat: 48.651956, lng: 19.22321 },
    { lat: 48.651815, lng: 19.22359 },
    { lat: 48.651693, lng: 19.22369 },
    { lat: 48.651512, lng: 19.223629 },
    { lat: 48.651316, lng: 19.223799 },
    { lat: 48.651137, lng: 19.223921 },
    { lat: 48.651086, lng: 19.224043 },
    { lat: 48.650968, lng: 19.224321 },
    { lat: 48.650851, lng: 19.22453 },
    { lat: 48.650744, lng: 19.224815 },
    { lat: 48.650817, lng: 19.225026 },
    { lat: 48.650806, lng: 19.225271 },
    { lat: 48.650825, lng: 19.225596 },
    { lat: 48.650853, lng: 19.225794 },
    { lat: 48.650848, lng: 19.225874 },
    { lat: 48.65078, lng: 19.22601 },
    { lat: 48.650732, lng: 19.226253 },
    { lat: 48.650486, lng: 19.226544 },
    { lat: 48.650454, lng: 19.226644 },
    { lat: 48.650375, lng: 19.226904 },
    { lat: 48.650152, lng: 19.227197 },
    { lat: 48.649956, lng: 19.227456 },
    { lat: 48.649664, lng: 19.227731 },
    { lat: 48.649441, lng: 19.227942 },
    { lat: 48.649138, lng: 19.228277 },
    { lat: 48.64913, lng: 19.228286 },
    { lat: 48.649047, lng: 19.228372 },
    { lat: 48.648929, lng: 19.228494 },
    { lat: 48.648803, lng: 19.228624 },
    { lat: 48.648655, lng: 19.228859 },
    { lat: 48.648624, lng: 19.228903 },
    { lat: 48.648558, lng: 19.229002 },
    { lat: 48.648407, lng: 19.229184 },
    { lat: 48.648366, lng: 19.229233 },
    { lat: 48.648256, lng: 19.229398 },
    { lat: 48.648121, lng: 19.229649 },
    { lat: 48.64805, lng: 19.229761 },
    { lat: 48.64798, lng: 19.229863 },
    { lat: 48.647819, lng: 19.230079 },
    { lat: 48.647777, lng: 19.230121 },
    { lat: 48.647749, lng: 19.230149 },
    { lat: 48.647678, lng: 19.230128 },
    { lat: 48.647546, lng: 19.23026 },
    { lat: 48.647402, lng: 19.230403 },
    { lat: 48.647314, lng: 19.230489 },
    { lat: 48.647171, lng: 19.230504 },
    { lat: 48.647164, lng: 19.230508 },
    { lat: 48.647058, lng: 19.230566 },
    { lat: 48.646953, lng: 19.230624 },
    { lat: 48.646569, lng: 19.230162 },
    { lat: 48.64656, lng: 19.230153 },
    { lat: 48.645933, lng: 19.229575 },
    { lat: 48.644637, lng: 19.229537 },
    { lat: 48.644632, lng: 19.229544 },
    { lat: 48.643636, lng: 19.231247 },
    { lat: 48.643414, lng: 19.231267 },
    { lat: 48.643315, lng: 19.231277 },
    { lat: 48.642419, lng: 19.231165 },
    { lat: 48.641252, lng: 19.230682 },
    { lat: 48.641202, lng: 19.230483 },
    { lat: 48.640469, lng: 19.230341 },
    { lat: 48.639931, lng: 19.230163 },
    { lat: 48.639196, lng: 19.229612 },
    { lat: 48.639191, lng: 19.229609 },
    { lat: 48.638971, lng: 19.229444 },
    { lat: 48.638515, lng: 19.229102 },
    { lat: 48.63777, lng: 19.228389 },
    { lat: 48.637455, lng: 19.228086 },
    { lat: 48.636433, lng: 19.22711 },
    { lat: 48.635984, lng: 19.22682 },
    { lat: 48.635941, lng: 19.226792 },
    { lat: 48.634839, lng: 19.22608 },
    { lat: 48.633899, lng: 19.225324 },
    { lat: 48.633356, lng: 19.224949 },
    { lat: 48.631956, lng: 19.224446 },
    { lat: 48.631613, lng: 19.224351 },
    { lat: 48.630178, lng: 19.224236 },
    { lat: 48.629754, lng: 19.223809 },
    { lat: 48.629056, lng: 19.222786 },
    { lat: 48.628639, lng: 19.222249 },
    { lat: 48.62806, lng: 19.221982 },
    { lat: 48.627454, lng: 19.222371 },
    { lat: 48.626795, lng: 19.223084 },
    { lat: 48.626721, lng: 19.22312 },
    { lat: 48.626468, lng: 19.223244 },
    { lat: 48.6263, lng: 19.223325 },
    { lat: 48.625471, lng: 19.223432 },
    { lat: 48.625315, lng: 19.223561 },
    { lat: 48.625087, lng: 19.223594 },
    { lat: 48.624067, lng: 19.22379 },
    { lat: 48.6233, lng: 19.224475 },
    { lat: 48.622848, lng: 19.225225 },
    { lat: 48.622178, lng: 19.225781 },
    { lat: 48.621579, lng: 19.226406 },
    { lat: 48.621288, lng: 19.227009 },
    { lat: 48.620808, lng: 19.227469 },
    { lat: 48.620559, lng: 19.228419 },
    { lat: 48.620234, lng: 19.22905 },
    { lat: 48.620016, lng: 19.229473 },
    { lat: 48.619514, lng: 19.229268 },
    { lat: 48.61895, lng: 19.23112 },
    { lat: 48.618923, lng: 19.231305 },
    { lat: 48.619129, lng: 19.231439 },
    { lat: 48.619403, lng: 19.231703 },
    { lat: 48.61963, lng: 19.231847 },
    { lat: 48.619819, lng: 19.231858 },
    { lat: 48.619862, lng: 19.23173 },
    { lat: 48.619909, lng: 19.231556 },
    { lat: 48.620045, lng: 19.231431 },
    { lat: 48.620386, lng: 19.231902 },
    { lat: 48.620317, lng: 19.232281 },
    { lat: 48.620569, lng: 19.232406 },
    { lat: 48.620738, lng: 19.23215 },
    { lat: 48.620837, lng: 19.232265 },
    { lat: 48.621104, lng: 19.232389 },
    { lat: 48.621299, lng: 19.232299 },
    { lat: 48.621455, lng: 19.232073 },
    { lat: 48.622069, lng: 19.231962 },
    { lat: 48.622393, lng: 19.231962 },
    { lat: 48.622492, lng: 19.232155 },
    { lat: 48.622527, lng: 19.23245 },
    { lat: 48.622655, lng: 19.232583 },
    { lat: 48.622809, lng: 19.232679 },
    { lat: 48.622984, lng: 19.232553 },
    { lat: 48.623106, lng: 19.232536 },
    { lat: 48.623241, lng: 19.23267 },
    { lat: 48.623267, lng: 19.232985 },
    { lat: 48.623377, lng: 19.23313 },
    { lat: 48.623585, lng: 19.233156 },
    { lat: 48.623637, lng: 19.233046 },
    { lat: 48.623767, lng: 19.233262 },
    { lat: 48.623669, lng: 19.233991 },
    { lat: 48.623738, lng: 19.234158 },
    { lat: 48.624073, lng: 19.234359 },
    { lat: 48.624204, lng: 19.234219 },
    { lat: 48.62424, lng: 19.234181 },
    { lat: 48.624271, lng: 19.234095 },
    { lat: 48.624477, lng: 19.234043 },
    { lat: 48.624572, lng: 19.233712 },
    { lat: 48.624639, lng: 19.233693 },
    { lat: 48.624791, lng: 19.233823 },
    { lat: 48.625114, lng: 19.234028 },
    { lat: 48.625329, lng: 19.234188 },
    { lat: 48.625529, lng: 19.233906 },
    { lat: 48.625621, lng: 19.233776 },
    { lat: 48.6258, lng: 19.233921 },
    { lat: 48.625959, lng: 19.234109 },
    { lat: 48.626006, lng: 19.234174 },
    { lat: 48.626103, lng: 19.234278 },
    { lat: 48.6264047, lng: 19.2346131 },
    { lat: 48.626755, lng: 19.234729 },
    { lat: 48.626947, lng: 19.234769 },
    { lat: 48.626951, lng: 19.234803 },
    { lat: 48.627465, lng: 19.236781 },
    { lat: 48.627431, lng: 19.236877 },
    { lat: 48.627419, lng: 19.236959 },
    { lat: 48.627406, lng: 19.237054 },
    { lat: 48.62739, lng: 19.237164 },
    { lat: 48.627372, lng: 19.237238 },
    { lat: 48.627222, lng: 19.23786 },
    { lat: 48.626556, lng: 19.240745 },
    { lat: 48.627102, lng: 19.241362 },
    { lat: 48.627781, lng: 19.243118 },
    { lat: 48.627873, lng: 19.244136 },
    { lat: 48.62841, lng: 19.24468 },
    { lat: 48.628274, lng: 19.245283 },
    { lat: 48.62798, lng: 19.246208 },
    { lat: 48.628007, lng: 19.249543 },
    { lat: 48.627907, lng: 19.249622 },
    { lat: 48.628016, lng: 19.251754 },
    { lat: 48.628053, lng: 19.252796 },
    { lat: 48.628038, lng: 19.255444 },
    { lat: 48.628016, lng: 19.255825 },
    { lat: 48.628012, lng: 19.255886 },
    { lat: 48.628011, lng: 19.255904 },
    { lat: 48.628537, lng: 19.256314 },
    { lat: 48.629014, lng: 19.256896 },
    { lat: 48.629525, lng: 19.257325 },
    { lat: 48.629932, lng: 19.257371 },
    { lat: 48.629995, lng: 19.257747 },
    { lat: 48.630078, lng: 19.258236 },
    { lat: 48.630087, lng: 19.258246 },
    { lat: 48.630113, lng: 19.258272 },
    { lat: 48.630495, lng: 19.25866 },
    { lat: 48.630722, lng: 19.258874 },
    { lat: 48.631062, lng: 19.259472 },
    { lat: 48.631435, lng: 19.259268 },
    { lat: 48.631464, lng: 19.260229 },
    { lat: 48.631192, lng: 19.261959 },
    { lat: 48.631355, lng: 19.262082 },
    { lat: 48.631906, lng: 19.262136 },
    { lat: 48.631935, lng: 19.262251 },
    { lat: 48.63213, lng: 19.26332 },
    { lat: 48.632138, lng: 19.263362 },
    { lat: 48.63292, lng: 19.264476 },
    { lat: 48.633243, lng: 19.264759 },
    { lat: 48.633628, lng: 19.265096 },
    { lat: 48.633877, lng: 19.265574 },
    { lat: 48.634292, lng: 19.266402 },
    { lat: 48.634534, lng: 19.266889 },
    { lat: 48.634839, lng: 19.26755 },
    { lat: 48.634969, lng: 19.267713 },
    { lat: 48.635401, lng: 19.268074 },
    { lat: 48.636221, lng: 19.268761 },
    { lat: 48.636514, lng: 19.269529 },
    { lat: 48.636858, lng: 19.270086 },
    { lat: 48.6371, lng: 19.270518 },
    { lat: 48.637191, lng: 19.270682 },
    { lat: 48.637292, lng: 19.27104 },
    { lat: 48.637267, lng: 19.271171 },
    { lat: 48.638172, lng: 19.272883 },
    { lat: 48.638259, lng: 19.273036 },
    { lat: 48.638441, lng: 19.27369 },
    { lat: 48.638622, lng: 19.2738 },
    { lat: 48.638931, lng: 19.274506 },
    { lat: 48.639144, lng: 19.27445 },
    { lat: 48.639337, lng: 19.274594 },
    { lat: 48.639449, lng: 19.274823 },
    { lat: 48.639584, lng: 19.275177 },
    { lat: 48.639562, lng: 19.275617 },
    { lat: 48.639581, lng: 19.275967 },
    { lat: 48.639903, lng: 19.276315 },
    { lat: 48.640319, lng: 19.276587 },
    { lat: 48.640438, lng: 19.277001 },
    { lat: 48.640629, lng: 19.277582 },
    { lat: 48.640865, lng: 19.278282 },
    { lat: 48.641039, lng: 19.278861 },
    { lat: 48.641473, lng: 19.279406 },
    { lat: 48.641819, lng: 19.279796 },
    { lat: 48.642014, lng: 19.280036 },
    { lat: 48.642084, lng: 19.280121 },
    { lat: 48.642239, lng: 19.280301 },
    { lat: 48.642756, lng: 19.280706 },
    { lat: 48.643341, lng: 19.281025 },
    { lat: 48.643852, lng: 19.281474 },
    { lat: 48.64411, lng: 19.281869 },
    { lat: 48.644181, lng: 19.282395 },
    { lat: 48.644365, lng: 19.282955 },
  ],
  DolnáMičiná: [
    { lat: 48.6773798, lng: 19.2332328 },
    { lat: 48.6778306, lng: 19.2339316 },
    { lat: 48.6782944, lng: 19.2342891 },
    { lat: 48.678693, lng: 19.2343745 },
    { lat: 48.6797668, lng: 19.2341323 },
    { lat: 48.6798143, lng: 19.2342975 },
    { lat: 48.6802085, lng: 19.2341073 },
    { lat: 48.6808181, lng: 19.2339603 },
    { lat: 48.6812942, lng: 19.2337593 },
    { lat: 48.6817845, lng: 19.2338074 },
    { lat: 48.6821557, lng: 19.2339986 },
    { lat: 48.6826191, lng: 19.2341196 },
    { lat: 48.6830903, lng: 19.2340953 },
    { lat: 48.6839004, lng: 19.2342307 },
    { lat: 48.6846799, lng: 19.2337978 },
    { lat: 48.6850505, lng: 19.2338656 },
    { lat: 48.6853311, lng: 19.2341424 },
    { lat: 48.6856201, lng: 19.2347297 },
    { lat: 48.685922, lng: 19.2350523 },
    { lat: 48.68621, lng: 19.2356219 },
    { lat: 48.686274, lng: 19.2359876 },
    { lat: 48.6864882, lng: 19.2363874 },
    { lat: 48.6866852, lng: 19.2369121 },
    { lat: 48.686825, lng: 19.2371696 },
    { lat: 48.6871248, lng: 19.2374486 },
    { lat: 48.6876813, lng: 19.237742 },
    { lat: 48.6884146, lng: 19.237896 },
    { lat: 48.6914659, lng: 19.2391527 },
    { lat: 48.6916453, lng: 19.237967 },
    { lat: 48.6918116, lng: 19.2366806 },
    { lat: 48.6913437, lng: 19.2361886 },
    { lat: 48.6909241, lng: 19.2363471 },
    { lat: 48.6912571, lng: 19.2357027 },
    { lat: 48.6914973, lng: 19.2349625 },
    { lat: 48.6916927, lng: 19.234968 },
    { lat: 48.6918038, lng: 19.233986 },
    { lat: 48.6913982, lng: 19.233941 },
    { lat: 48.6908521, lng: 19.2336746 },
    { lat: 48.691062, lng: 19.2326353 },
    { lat: 48.6914976, lng: 19.2329471 },
    { lat: 48.6915815, lng: 19.2321215 },
    { lat: 48.6914669, lng: 19.2321046 },
    { lat: 48.6914644, lng: 19.2314498 },
    { lat: 48.6902709, lng: 19.2309205 },
    { lat: 48.6905653, lng: 19.2305279 },
    { lat: 48.6904982, lng: 19.2304694 },
    { lat: 48.6908453, lng: 19.2301655 },
    { lat: 48.6910543, lng: 19.2300617 },
    { lat: 48.6910562, lng: 19.229541 },
    { lat: 48.6912121, lng: 19.229411 },
    { lat: 48.6915506, lng: 19.2286503 },
    { lat: 48.691488, lng: 19.2285196 },
    { lat: 48.6914669, lng: 19.2283196 },
    { lat: 48.6913442, lng: 19.2281841 },
    { lat: 48.6916628, lng: 19.2276882 },
    { lat: 48.6914686, lng: 19.2272395 },
    { lat: 48.6907602, lng: 19.2274396 },
    { lat: 48.6906944, lng: 19.2279167 },
    { lat: 48.6903127, lng: 19.2282972 },
    { lat: 48.6902075, lng: 19.2287288 },
    { lat: 48.689629, lng: 19.2294436 },
    { lat: 48.6895027, lng: 19.2293959 },
    { lat: 48.6895456, lng: 19.227867 },
    { lat: 48.6892949, lng: 19.2277421 },
    { lat: 48.6889206, lng: 19.2277147 },
    { lat: 48.6884579, lng: 19.227822 },
    { lat: 48.688539, lng: 19.2268226 },
    { lat: 48.6887616, lng: 19.2262757 },
    { lat: 48.6887088, lng: 19.2256069 },
    { lat: 48.6888282, lng: 19.2255116 },
    { lat: 48.6886376, lng: 19.2249088 },
    { lat: 48.6886074, lng: 19.2244723 },
    { lat: 48.6882075, lng: 19.2229462 },
    { lat: 48.6878193, lng: 19.2236777 },
    { lat: 48.6874635, lng: 19.2240596 },
    { lat: 48.6875487, lng: 19.2249528 },
    { lat: 48.6874965, lng: 19.2253178 },
    { lat: 48.6872511, lng: 19.2257164 },
    { lat: 48.6871422, lng: 19.2254969 },
    { lat: 48.6865093, lng: 19.225694 },
    { lat: 48.6861944, lng: 19.2255919 },
    { lat: 48.6858019, lng: 19.2257481 },
    { lat: 48.6856952, lng: 19.2256875 },
    { lat: 48.6855676, lng: 19.2251951 },
    { lat: 48.6850921, lng: 19.2255007 },
    { lat: 48.6847452, lng: 19.22544 },
    { lat: 48.6845629, lng: 19.2250703 },
    { lat: 48.6843297, lng: 19.2249392 },
    { lat: 48.6842614, lng: 19.2246207 },
    { lat: 48.6846125, lng: 19.2244484 },
    { lat: 48.6849327, lng: 19.224053 },
    { lat: 48.6845946, lng: 19.2235707 },
    { lat: 48.684211, lng: 19.2228156 },
    { lat: 48.6840757, lng: 19.2228416 },
    { lat: 48.684007, lng: 19.2226762 },
    { lat: 48.6846656, lng: 19.2217799 },
    { lat: 48.6840871, lng: 19.2200583 },
    { lat: 48.6837906, lng: 19.219479 },
    { lat: 48.6839161, lng: 19.2193741 },
    { lat: 48.6839664, lng: 19.2191988 },
    { lat: 48.6842407, lng: 19.2191858 },
    { lat: 48.6845477, lng: 19.2193105 },
    { lat: 48.6849765, lng: 19.2197588 },
    { lat: 48.6849306, lng: 19.2204374 },
    { lat: 48.6848519, lng: 19.2207914 },
    { lat: 48.6851064, lng: 19.2207835 },
    { lat: 48.6853355, lng: 19.2206768 },
    { lat: 48.6855565, lng: 19.2204468 },
    { lat: 48.6856602, lng: 19.2200722 },
    { lat: 48.6858984, lng: 19.22008 },
    { lat: 48.6865035, lng: 19.2197043 },
    { lat: 48.686599, lng: 19.2188165 },
    { lat: 48.686828, lng: 19.2186152 },
    { lat: 48.6865174, lng: 19.2185526 },
    { lat: 48.6865453, lng: 19.2182948 },
    { lat: 48.6864144, lng: 19.2182654 },
    { lat: 48.6864334, lng: 19.2180343 },
    { lat: 48.6863429, lng: 19.2179116 },
    { lat: 48.6854627, lng: 19.2179587 },
    { lat: 48.6852329, lng: 19.217916 },
    { lat: 48.6851924, lng: 19.2181278 },
    { lat: 48.684898, lng: 19.2179849 },
    { lat: 48.6841608, lng: 19.217217 },
    { lat: 48.6843799, lng: 19.2167161 },
    { lat: 48.6846243, lng: 19.2164578 },
    { lat: 48.6851586, lng: 19.2155102 },
    { lat: 48.6853103, lng: 19.2148941 },
    { lat: 48.6854808, lng: 19.2145552 },
    { lat: 48.6858613, lng: 19.2142907 },
    { lat: 48.6857335, lng: 19.2140737 },
    { lat: 48.685208, lng: 19.2147884 },
    { lat: 48.6849932, lng: 19.2144509 },
    { lat: 48.68423, lng: 19.215974 },
    { lat: 48.6837606, lng: 19.2149495 },
    { lat: 48.6833283, lng: 19.2151673 },
    { lat: 48.6830243, lng: 19.2144776 },
    { lat: 48.6837904, lng: 19.2136837 },
    { lat: 48.6841889, lng: 19.2130238 },
    { lat: 48.6848826, lng: 19.2121791 },
    { lat: 48.6848629, lng: 19.212051 },
    { lat: 48.6850344, lng: 19.2112907 },
    { lat: 48.684958, lng: 19.2109977 },
    { lat: 48.6848376, lng: 19.2108823 },
    { lat: 48.684661, lng: 19.210913 },
    { lat: 48.6845647, lng: 19.2111624 },
    { lat: 48.6842777, lng: 19.2115824 },
    { lat: 48.6837935, lng: 19.2118263 },
    { lat: 48.6834858, lng: 19.2118726 },
    { lat: 48.6832836, lng: 19.2120941 },
    { lat: 48.6827776, lng: 19.2123308 },
    { lat: 48.6831364, lng: 19.210716 },
    { lat: 48.6833908, lng: 19.209969 },
    { lat: 48.682853, lng: 19.2096902 },
    { lat: 48.6824292, lng: 19.2093642 },
    { lat: 48.6824179, lng: 19.2092299 },
    { lat: 48.6824937, lng: 19.2091851 },
    { lat: 48.6821394, lng: 19.2078864 },
    { lat: 48.6822428, lng: 19.2071372 },
    { lat: 48.6822095, lng: 19.207055 },
    { lat: 48.6822386, lng: 19.2068676 },
    { lat: 48.6822942, lng: 19.2068511 },
    { lat: 48.6824355, lng: 19.2063275 },
    { lat: 48.682356, lng: 19.2058501 },
    { lat: 48.6823629, lng: 19.2056 },
    { lat: 48.6827256, lng: 19.2050884 },
    { lat: 48.6826717, lng: 19.2041912 },
    { lat: 48.6819337, lng: 19.2042858 },
    { lat: 48.6818085, lng: 19.2049799 },
    { lat: 48.6816315, lng: 19.2055586 },
    { lat: 48.6811228, lng: 19.2036761 },
    { lat: 48.6808679, lng: 19.2038168 },
    { lat: 48.6807717, lng: 19.20353 },
    { lat: 48.6800553, lng: 19.2039651 },
    { lat: 48.6797765, lng: 19.2041344 },
    { lat: 48.6793982, lng: 19.2033571 },
    { lat: 48.6791164, lng: 19.2022956 },
    { lat: 48.6783672, lng: 19.2002017 },
    { lat: 48.6787273, lng: 19.1998641 },
    { lat: 48.679319, lng: 19.1987749 },
    { lat: 48.6800273, lng: 19.1980661 },
    { lat: 48.6807747, lng: 19.197792 },
    { lat: 48.6810237, lng: 19.1969789 },
    { lat: 48.6814696, lng: 19.1951503 },
    { lat: 48.6820154, lng: 19.1941859 },
    { lat: 48.6818563, lng: 19.1936939 },
    { lat: 48.6821057, lng: 19.1932401 },
    { lat: 48.6823812, lng: 19.193019 },
    { lat: 48.6824984, lng: 19.1928368 },
    { lat: 48.6825554, lng: 19.1926385 },
    { lat: 48.6824272, lng: 19.1919544 },
    { lat: 48.6823248, lng: 19.1905735 },
    { lat: 48.6825694, lng: 19.189582 },
    { lat: 48.6825112, lng: 19.1891301 },
    { lat: 48.682367, lng: 19.1887602 },
    { lat: 48.6822853, lng: 19.1878297 },
    { lat: 48.6823555, lng: 19.1876039 },
    { lat: 48.6827581, lng: 19.1869872 },
    { lat: 48.6832726, lng: 19.185704 },
    { lat: 48.6838096, lng: 19.1854397 },
    { lat: 48.6838985, lng: 19.1848576 },
    { lat: 48.684076, lng: 19.1844293 },
    { lat: 48.6841268, lng: 19.1841786 },
    { lat: 48.6842898, lng: 19.1840105 },
    { lat: 48.6845231, lng: 19.1836167 },
    { lat: 48.684856, lng: 19.1834162 },
    { lat: 48.6849599, lng: 19.1831459 },
    { lat: 48.685533, lng: 19.1821423 },
    { lat: 48.685255, lng: 19.1818055 },
    { lat: 48.6844206, lng: 19.18134 },
    { lat: 48.6839925, lng: 19.1808841 },
    { lat: 48.6837864, lng: 19.1805393 },
    { lat: 48.6833206, lng: 19.1802375 },
    { lat: 48.6830266, lng: 19.180311 },
    { lat: 48.6823741, lng: 19.1802476 },
    { lat: 48.6819664, lng: 19.1796532 },
    { lat: 48.6817265, lng: 19.1795437 },
    { lat: 48.6814607, lng: 19.1792738 },
    { lat: 48.6806837, lng: 19.1786935 },
    { lat: 48.6804994, lng: 19.1786338 },
    { lat: 48.6782961, lng: 19.1824177 },
    { lat: 48.677091, lng: 19.1843054 },
    { lat: 48.6767486, lng: 19.1849799 },
    { lat: 48.6762656, lng: 19.1857532 },
    { lat: 48.6759699, lng: 19.1862267 },
    { lat: 48.6753857, lng: 19.1880903 },
    { lat: 48.6750763, lng: 19.1888638 },
    { lat: 48.6746368, lng: 19.1894639 },
    { lat: 48.6740195, lng: 19.1906034 },
    { lat: 48.6738207, lng: 19.1911695 },
    { lat: 48.6737348, lng: 19.1917169 },
    { lat: 48.672483, lng: 19.1911064 },
    { lat: 48.6723515, lng: 19.1912068 },
    { lat: 48.672071, lng: 19.1914208 },
    { lat: 48.6715727, lng: 19.1919917 },
    { lat: 48.6713216, lng: 19.1925083 },
    { lat: 48.6709716, lng: 19.1929357 },
    { lat: 48.6698669, lng: 19.1934407 },
    { lat: 48.6698472, lng: 19.1933855 },
    { lat: 48.6692595, lng: 19.1932392 },
    { lat: 48.6688179, lng: 19.1932905 },
    { lat: 48.6684352, lng: 19.1935992 },
    { lat: 48.6680942, lng: 19.1942338 },
    { lat: 48.6677141, lng: 19.1944181 },
    { lat: 48.6667659, lng: 19.1953111 },
    { lat: 48.666077, lng: 19.1956695 },
    { lat: 48.6659776, lng: 19.1957212 },
    { lat: 48.664043, lng: 19.1969833 },
    { lat: 48.6638403, lng: 19.1963516 },
    { lat: 48.6635524, lng: 19.1954546 },
    { lat: 48.6636412, lng: 19.1951263 },
    { lat: 48.6635473, lng: 19.1948668 },
    { lat: 48.6631908, lng: 19.1945121 },
    { lat: 48.6627636, lng: 19.1950907 },
    { lat: 48.6619125, lng: 19.1961286 },
    { lat: 48.6615767, lng: 19.195972 },
    { lat: 48.6609414, lng: 19.1975493 },
    { lat: 48.660781, lng: 19.198231 },
    { lat: 48.660303, lng: 19.1995258 },
    { lat: 48.6594242, lng: 19.2006893 },
    { lat: 48.658617, lng: 19.201157 },
    { lat: 48.658629, lng: 19.201203 },
    { lat: 48.658747, lng: 19.201731 },
    { lat: 48.658779, lng: 19.201791 },
    { lat: 48.658876, lng: 19.202087 },
    { lat: 48.65903, lng: 19.202488 },
    { lat: 48.659076, lng: 19.202609 },
    { lat: 48.659099, lng: 19.20267 },
    { lat: 48.659187, lng: 19.202901 },
    { lat: 48.65926, lng: 19.203095 },
    { lat: 48.659326, lng: 19.20341 },
    { lat: 48.659395, lng: 19.203775 },
    { lat: 48.659453, lng: 19.204113 },
    { lat: 48.659474, lng: 19.204253 },
    { lat: 48.65949, lng: 19.204314 },
    { lat: 48.65952, lng: 19.204431 },
    { lat: 48.659646, lng: 19.204733 },
    { lat: 48.659755, lng: 19.204972 },
    { lat: 48.659893, lng: 19.205283 },
    { lat: 48.659752, lng: 19.205475 },
    { lat: 48.659551, lng: 19.205698 },
    { lat: 48.659199, lng: 19.206046 },
    { lat: 48.659124, lng: 19.20612 },
    { lat: 48.659122, lng: 19.206125 },
    { lat: 48.659044, lng: 19.206314 },
    { lat: 48.658903, lng: 19.206523 },
    { lat: 48.658886, lng: 19.206538 },
    { lat: 48.658879, lng: 19.206563 },
    { lat: 48.658867, lng: 19.206604 },
    { lat: 48.658807, lng: 19.206702 },
    { lat: 48.658622, lng: 19.207 },
    { lat: 48.658607, lng: 19.207052 },
    { lat: 48.658589, lng: 19.207069 },
    { lat: 48.658549, lng: 19.20705 },
    { lat: 48.658528, lng: 19.207045 },
    { lat: 48.658414, lng: 19.207014 },
    { lat: 48.658356, lng: 19.207223 },
    { lat: 48.658334, lng: 19.20731 },
    { lat: 48.658286, lng: 19.207377 },
    { lat: 48.658254, lng: 19.207425 },
    { lat: 48.658146, lng: 19.207584 },
    { lat: 48.658104, lng: 19.207646 },
    { lat: 48.658092, lng: 19.207665 },
    { lat: 48.658055, lng: 19.207713 },
    { lat: 48.658048, lng: 19.207716 },
    { lat: 48.658018, lng: 19.207731 },
    { lat: 48.657956, lng: 19.20776 },
    { lat: 48.657884, lng: 19.207792 },
    { lat: 48.657873, lng: 19.207836 },
    { lat: 48.657869, lng: 19.207837 },
    { lat: 48.657834, lng: 19.207841 },
    { lat: 48.657815, lng: 19.207856 },
    { lat: 48.657774, lng: 19.207854 },
    { lat: 48.65767, lng: 19.207848 },
    { lat: 48.657572, lng: 19.207844 },
    { lat: 48.657522, lng: 19.207841 },
    { lat: 48.657472, lng: 19.20785 },
    { lat: 48.657404, lng: 19.207863 },
    { lat: 48.657376, lng: 19.207877 },
    { lat: 48.657286, lng: 19.207922 },
    { lat: 48.657185, lng: 19.207972 },
    { lat: 48.657141, lng: 19.207994 },
    { lat: 48.657043, lng: 19.207867 },
    { lat: 48.656961, lng: 19.207762 },
    { lat: 48.656927, lng: 19.207729 },
    { lat: 48.6569, lng: 19.20773 },
    { lat: 48.656861, lng: 19.207751 },
    { lat: 48.656811, lng: 19.207778 },
    { lat: 48.656696, lng: 19.207882 },
    { lat: 48.656677, lng: 19.207898 },
    { lat: 48.656585, lng: 19.208035 },
    { lat: 48.656572, lng: 19.208063 },
    { lat: 48.656471, lng: 19.208312 },
    { lat: 48.65645, lng: 19.208379 },
    { lat: 48.656417, lng: 19.208488 },
    { lat: 48.656369, lng: 19.208538 },
    { lat: 48.656261, lng: 19.208517 },
    { lat: 48.65624, lng: 19.20862 },
    { lat: 48.656206, lng: 19.208663 },
    { lat: 48.656266, lng: 19.208787 },
    { lat: 48.656198, lng: 19.208914 },
    { lat: 48.656226, lng: 19.20901 },
    { lat: 48.656162, lng: 19.209079 },
    { lat: 48.656066, lng: 19.20898 },
    { lat: 48.656008, lng: 19.209021 },
    { lat: 48.655951, lng: 19.209155 },
    { lat: 48.655919, lng: 19.209138 },
    { lat: 48.655925, lng: 19.209067 },
    { lat: 48.655888, lng: 19.209029 },
    { lat: 48.655797, lng: 19.208966 },
    { lat: 48.655748, lng: 19.208983 },
    { lat: 48.655752, lng: 19.209092 },
    { lat: 48.655716, lng: 19.209149 },
    { lat: 48.655645, lng: 19.209151 },
    { lat: 48.655576, lng: 19.208983 },
    { lat: 48.655592, lng: 19.208919 },
    { lat: 48.655545, lng: 19.208855 },
    { lat: 48.655445, lng: 19.209092 },
    { lat: 48.655425, lng: 19.209084 },
    { lat: 48.655267, lng: 19.209379 },
    { lat: 48.655394, lng: 19.209511 },
    { lat: 48.655411, lng: 19.209508 },
    { lat: 48.655425, lng: 19.209526 },
    { lat: 48.655473, lng: 19.209543 },
    { lat: 48.655483, lng: 19.209592 },
    { lat: 48.655334, lng: 19.209769 },
    { lat: 48.655329, lng: 19.209861 },
    { lat: 48.655329, lng: 19.209874 },
    { lat: 48.655315, lng: 19.209886 },
    { lat: 48.655273, lng: 19.209848 },
    { lat: 48.655261, lng: 19.20974 },
    { lat: 48.655189, lng: 19.209781 },
    { lat: 48.655201, lng: 19.209889 },
    { lat: 48.655164, lng: 19.209919 },
    { lat: 48.655099, lng: 19.209901 },
    { lat: 48.655037, lng: 19.209981 },
    { lat: 48.655046, lng: 19.209996 },
    { lat: 48.654972, lng: 19.210076 },
    { lat: 48.65493, lng: 19.210147 },
    { lat: 48.654941, lng: 19.210181 },
    { lat: 48.655029, lng: 19.210178 },
    { lat: 48.65505, lng: 19.210176 },
    { lat: 48.655115, lng: 19.210254 },
    { lat: 48.654996, lng: 19.210532 },
    { lat: 48.65499, lng: 19.210548 },
    { lat: 48.654932, lng: 19.210549 },
    { lat: 48.654878, lng: 19.21062 },
    { lat: 48.65478, lng: 19.210519 },
    { lat: 48.654708, lng: 19.210641 },
    { lat: 48.65463, lng: 19.210669 },
    { lat: 48.654633, lng: 19.210726 },
    { lat: 48.654535, lng: 19.210741 },
    { lat: 48.654531, lng: 19.210633 },
    { lat: 48.6545, lng: 19.210598 },
    { lat: 48.654499, lng: 19.210498 },
    { lat: 48.65436, lng: 19.210512 },
    { lat: 48.654311, lng: 19.210721 },
    { lat: 48.654376, lng: 19.210793 },
    { lat: 48.654389, lng: 19.210956 },
    { lat: 48.654297, lng: 19.211033 },
    { lat: 48.654255, lng: 19.210976 },
    { lat: 48.654215, lng: 19.210996 },
    { lat: 48.654158, lng: 19.210945 },
    { lat: 48.654076, lng: 19.211124 },
    { lat: 48.654044, lng: 19.211167 },
    { lat: 48.654037, lng: 19.211165 },
    { lat: 48.654023, lng: 19.211142 },
    { lat: 48.654028, lng: 19.211041 },
    { lat: 48.653957, lng: 19.210993 },
    { lat: 48.653859, lng: 19.211107 },
    { lat: 48.653823, lng: 19.211261 },
    { lat: 48.653853, lng: 19.211345 },
    { lat: 48.653938, lng: 19.211352 },
    { lat: 48.653951, lng: 19.211402 },
    { lat: 48.653932, lng: 19.211469 },
    { lat: 48.653927, lng: 19.211485 },
    { lat: 48.653807, lng: 19.211578 },
    { lat: 48.653769, lng: 19.211507 },
    { lat: 48.653703, lng: 19.211554 },
    { lat: 48.653616, lng: 19.211638 },
    { lat: 48.65358, lng: 19.211563 },
    { lat: 48.653416, lng: 19.211734 },
    { lat: 48.653331, lng: 19.211865 },
    { lat: 48.653278, lng: 19.21198 },
    { lat: 48.653265, lng: 19.212076 },
    { lat: 48.653259, lng: 19.212078 },
    { lat: 48.653183, lng: 19.21212 },
    { lat: 48.653045, lng: 19.212139 },
    { lat: 48.653007, lng: 19.212216 },
    { lat: 48.652968, lng: 19.212326 },
    { lat: 48.652837, lng: 19.212389 },
    { lat: 48.652735, lng: 19.212471 },
    { lat: 48.652619, lng: 19.212549 },
    { lat: 48.652531, lng: 19.212557 },
    { lat: 48.652428, lng: 19.212513 },
    { lat: 48.652367, lng: 19.212606 },
    { lat: 48.652347, lng: 19.212734 },
    { lat: 48.652257, lng: 19.212707 },
    { lat: 48.652198, lng: 19.212857 },
    { lat: 48.652139, lng: 19.212892 },
    { lat: 48.65218, lng: 19.212972 },
    { lat: 48.652223, lng: 19.212925 },
    { lat: 48.652241, lng: 19.212984 },
    { lat: 48.652194, lng: 19.213091 },
    { lat: 48.652111, lng: 19.213046 },
    { lat: 48.652066, lng: 19.213146 },
    { lat: 48.652145, lng: 19.213199 },
    { lat: 48.652032, lng: 19.21339 },
    { lat: 48.651952, lng: 19.21337 },
    { lat: 48.651917, lng: 19.213468 },
    { lat: 48.651925, lng: 19.213591 },
    { lat: 48.651872, lng: 19.21365 },
    { lat: 48.651832, lng: 19.213664 },
    { lat: 48.651804, lng: 19.213838 },
    { lat: 48.65183, lng: 19.213933 },
    { lat: 48.651797, lng: 19.214032 },
    { lat: 48.651736, lng: 19.214106 },
    { lat: 48.651653, lng: 19.214056 },
    { lat: 48.651619, lng: 19.213959 },
    { lat: 48.651563, lng: 19.214076 },
    { lat: 48.651514, lng: 19.214122 },
    { lat: 48.651499, lng: 19.214175 },
    { lat: 48.651417, lng: 19.214171 },
    { lat: 48.651399, lng: 19.214159 },
    { lat: 48.651313, lng: 19.214129 },
    { lat: 48.651263, lng: 19.214245 },
    { lat: 48.651371, lng: 19.214375 },
    { lat: 48.651382, lng: 19.214308 },
    { lat: 48.6514, lng: 19.214278 },
    { lat: 48.651427, lng: 19.21427 },
    { lat: 48.65145, lng: 19.214275 },
    { lat: 48.65145, lng: 19.214317 },
    { lat: 48.651443, lng: 19.214415 },
    { lat: 48.651438, lng: 19.214501 },
    { lat: 48.651465, lng: 19.214569 },
    { lat: 48.651403, lng: 19.214592 },
    { lat: 48.651404, lng: 19.214701 },
    { lat: 48.651445, lng: 19.214732 },
    { lat: 48.651462, lng: 19.214787 },
    { lat: 48.651415, lng: 19.214857 },
    { lat: 48.651354, lng: 19.214819 },
    { lat: 48.651314, lng: 19.214763 },
    { lat: 48.651223, lng: 19.214859 },
    { lat: 48.651079, lng: 19.21481 },
    { lat: 48.651053, lng: 19.214873 },
    { lat: 48.651059, lng: 19.214997 },
    { lat: 48.651086, lng: 19.21506 },
    { lat: 48.651139, lng: 19.215134 },
    { lat: 48.651116, lng: 19.215215 },
    { lat: 48.651114, lng: 19.215324 },
    { lat: 48.651118, lng: 19.215403 },
    { lat: 48.651097, lng: 19.215471 },
    { lat: 48.651107, lng: 19.215499 },
    { lat: 48.651122, lng: 19.21558 },
    { lat: 48.651197, lng: 19.215644 },
    { lat: 48.651207, lng: 19.215801 },
    { lat: 48.651239, lng: 19.215889 },
    { lat: 48.651268, lng: 19.215928 },
    { lat: 48.651273, lng: 19.215954 },
    { lat: 48.651358, lng: 19.215866 },
    { lat: 48.651453, lng: 19.21592 },
    { lat: 48.651487, lng: 19.215862 },
    { lat: 48.651544, lng: 19.215866 },
    { lat: 48.651666, lng: 19.215964 },
    { lat: 48.651679, lng: 19.216019 },
    { lat: 48.651647, lng: 19.216208 },
    { lat: 48.651574, lng: 19.216319 },
    { lat: 48.651618, lng: 19.216449 },
    { lat: 48.651753, lng: 19.216544 },
    { lat: 48.651924, lng: 19.216565 },
    { lat: 48.651995, lng: 19.216537 },
    { lat: 48.652042, lng: 19.216487 },
    { lat: 48.652095, lng: 19.216509 },
    { lat: 48.65216, lng: 19.216584 },
    { lat: 48.652233, lng: 19.21654 },
    { lat: 48.652327, lng: 19.216438 },
    { lat: 48.652436, lng: 19.216428 },
    { lat: 48.652524, lng: 19.216458 },
    { lat: 48.652578, lng: 19.216467 },
    { lat: 48.652691, lng: 19.216623 },
    { lat: 48.652768, lng: 19.216778 },
    { lat: 48.652717, lng: 19.217011 },
    { lat: 48.652737, lng: 19.217071 },
    { lat: 48.652839, lng: 19.217102 },
    { lat: 48.652826, lng: 19.217144 },
    { lat: 48.652743, lng: 19.217423 },
    { lat: 48.652851, lng: 19.217421 },
    { lat: 48.652942, lng: 19.217424 },
    { lat: 48.652968, lng: 19.217425 },
    { lat: 48.653003, lng: 19.217229 },
    { lat: 48.653005, lng: 19.217214 },
    { lat: 48.653014, lng: 19.217178 },
    { lat: 48.653128, lng: 19.217212 },
    { lat: 48.653236, lng: 19.21722 },
    { lat: 48.65337, lng: 19.217199 },
    { lat: 48.653512, lng: 19.21727 },
    { lat: 48.653523, lng: 19.217275 },
    { lat: 48.653636, lng: 19.217438 },
    { lat: 48.653763, lng: 19.217601 },
    { lat: 48.653907, lng: 19.21778 },
    { lat: 48.654013, lng: 19.217877 },
    { lat: 48.6542778, lng: 19.2181379 },
    { lat: 48.6545279, lng: 19.2181565 },
    { lat: 48.6546698, lng: 19.2183386 },
    { lat: 48.6546573, lng: 19.2184313 },
    { lat: 48.6545359, lng: 19.2185962 },
    { lat: 48.6545158, lng: 19.2189029 },
    { lat: 48.6546295, lng: 19.2192497 },
    { lat: 48.6546277, lng: 19.2194442 },
    { lat: 48.6550263, lng: 19.2195052 },
    { lat: 48.6550955, lng: 19.2198344 },
    { lat: 48.6551644, lng: 19.2199077 },
    { lat: 48.6551861, lng: 19.2203099 },
    { lat: 48.6553002, lng: 19.2205665 },
    { lat: 48.6553509, lng: 19.2206379 },
    { lat: 48.6556948, lng: 19.220685 },
    { lat: 48.655854, lng: 19.2205988 },
    { lat: 48.6560214, lng: 19.220889 },
    { lat: 48.6560165, lng: 19.2209955 },
    { lat: 48.6559494, lng: 19.221097 },
    { lat: 48.6560226, lng: 19.2212829 },
    { lat: 48.6559904, lng: 19.2214305 },
    { lat: 48.6560548, lng: 19.221643 },
    { lat: 48.6560332, lng: 19.22185 },
    { lat: 48.6561459, lng: 19.2220101 },
    { lat: 48.6563022, lng: 19.222048 },
    { lat: 48.6563565, lng: 19.2221928 },
    { lat: 48.6566358, lng: 19.2222834 },
    { lat: 48.6567146, lng: 19.2225567 },
    { lat: 48.6567111, lng: 19.222622 },
    { lat: 48.6565629, lng: 19.2227069 },
    { lat: 48.6566127, lng: 19.2230568 },
    { lat: 48.6568055, lng: 19.2232408 },
    { lat: 48.6568877, lng: 19.223426 },
    { lat: 48.6568988, lng: 19.2240972 },
    { lat: 48.6571465, lng: 19.2248334 },
    { lat: 48.6571683, lng: 19.225025 },
    { lat: 48.6573831, lng: 19.2249448 },
    { lat: 48.6575987, lng: 19.2249769 },
    { lat: 48.6576746, lng: 19.2251443 },
    { lat: 48.6577194, lng: 19.2251172 },
    { lat: 48.6577703, lng: 19.2251875 },
    { lat: 48.6579641, lng: 19.2250933 },
    { lat: 48.6582634, lng: 19.2251768 },
    { lat: 48.6582799, lng: 19.2251896 },
    { lat: 48.6585418, lng: 19.22571 },
    { lat: 48.6585555, lng: 19.226059 },
    { lat: 48.6586251, lng: 19.2261504 },
    { lat: 48.6585392, lng: 19.2263513 },
    { lat: 48.6586511, lng: 19.2264024 },
    { lat: 48.6588011, lng: 19.2267085 },
    { lat: 48.6588549, lng: 19.2266783 },
    { lat: 48.659023, lng: 19.2267984 },
    { lat: 48.6591322, lng: 19.2272387 },
    { lat: 48.6593139, lng: 19.2271034 },
    { lat: 48.6594662, lng: 19.2271507 },
    { lat: 48.6595042, lng: 19.2272571 },
    { lat: 48.6594772, lng: 19.2274016 },
    { lat: 48.6593748, lng: 19.227613 },
    { lat: 48.6595586, lng: 19.2280996 },
    { lat: 48.6598138, lng: 19.2279259 },
    { lat: 48.6598885, lng: 19.227723 },
    { lat: 48.6600993, lng: 19.2280957 },
    { lat: 48.6601756, lng: 19.2284542 },
    { lat: 48.660274, lng: 19.2285364 },
    { lat: 48.6604032, lng: 19.228527 },
    { lat: 48.6605557, lng: 19.2282759 },
    { lat: 48.6607034, lng: 19.2284382 },
    { lat: 48.6607266, lng: 19.2285223 },
    { lat: 48.6606735, lng: 19.2286829 },
    { lat: 48.6607726, lng: 19.2288666 },
    { lat: 48.6608007, lng: 19.22905 },
    { lat: 48.6609823, lng: 19.2290741 },
    { lat: 48.6610204, lng: 19.2291865 },
    { lat: 48.6611735, lng: 19.2293004 },
    { lat: 48.6611784, lng: 19.2293768 },
    { lat: 48.6611232, lng: 19.2294464 },
    { lat: 48.6612374, lng: 19.2296859 },
    { lat: 48.6614053, lng: 19.229827 },
    { lat: 48.6614788, lng: 19.2298082 },
    { lat: 48.6616226, lng: 19.2300059 },
    { lat: 48.6617381, lng: 19.2299004 },
    { lat: 48.6618032, lng: 19.2299171 },
    { lat: 48.6619231, lng: 19.2301341 },
    { lat: 48.6622348, lng: 19.230079 },
    { lat: 48.6625803, lng: 19.230414 },
    { lat: 48.6626278, lng: 19.2306038 },
    { lat: 48.6627476, lng: 19.2307254 },
    { lat: 48.6629811, lng: 19.2301729 },
    { lat: 48.6632007, lng: 19.2300077 },
    { lat: 48.6632546, lng: 19.2298188 },
    { lat: 48.6634138, lng: 19.229824 },
    { lat: 48.6635103, lng: 19.2295085 },
    { lat: 48.6636405, lng: 19.2295198 },
    { lat: 48.6637392, lng: 19.2294411 },
    { lat: 48.6638662, lng: 19.2291781 },
    { lat: 48.6640593, lng: 19.2292099 },
    { lat: 48.6641619, lng: 19.2293041 },
    { lat: 48.6643781, lng: 19.2293005 },
    { lat: 48.6646569, lng: 19.2289127 },
    { lat: 48.6648802, lng: 19.2287974 },
    { lat: 48.6649815, lng: 19.2288414 },
    { lat: 48.665168, lng: 19.2287605 },
    { lat: 48.6652412, lng: 19.2285945 },
    { lat: 48.6652158, lng: 19.2284668 },
    { lat: 48.6653695, lng: 19.2281053 },
    { lat: 48.6655744, lng: 19.2281889 },
    { lat: 48.6657856, lng: 19.2275493 },
    { lat: 48.6658359, lng: 19.2274706 },
    { lat: 48.6659268, lng: 19.2274652 },
    { lat: 48.6662902, lng: 19.2277302 },
    { lat: 48.666412, lng: 19.2279135 },
    { lat: 48.6663705, lng: 19.2282886 },
    { lat: 48.6664984, lng: 19.2283686 },
    { lat: 48.666487, lng: 19.228509 },
    { lat: 48.6665408, lng: 19.2286389 },
    { lat: 48.6668307, lng: 19.2289213 },
    { lat: 48.6668728, lng: 19.2290686 },
    { lat: 48.6668761, lng: 19.2295795 },
    { lat: 48.6669359, lng: 19.2297412 },
    { lat: 48.666909, lng: 19.2299564 },
    { lat: 48.6671984, lng: 19.2300642 },
    { lat: 48.6673601, lng: 19.2300088 },
    { lat: 48.6676222, lng: 19.2301824 },
    { lat: 48.6677548, lng: 19.2301941 },
    { lat: 48.668059, lng: 19.2305616 },
    { lat: 48.6681984, lng: 19.230559 },
    { lat: 48.6684403, lng: 19.2308706 },
    { lat: 48.6686828, lng: 19.2309219 },
    { lat: 48.6687441, lng: 19.2310713 },
    { lat: 48.6690575, lng: 19.2313283 },
    { lat: 48.6693226, lng: 19.2317038 },
    { lat: 48.6696564, lng: 19.2320063 },
    { lat: 48.6696846, lng: 19.2321694 },
    { lat: 48.6696452, lng: 19.232428 },
    { lat: 48.6696576, lng: 19.2327997 },
    { lat: 48.6698165, lng: 19.2327746 },
    { lat: 48.6699256, lng: 19.2329294 },
    { lat: 48.6700716, lng: 19.2329686 },
    { lat: 48.6701524, lng: 19.233169 },
    { lat: 48.6703077, lng: 19.2332548 },
    { lat: 48.6703272, lng: 19.233355 },
    { lat: 48.6705615, lng: 19.233286 },
    { lat: 48.6708667, lng: 19.2337433 },
    { lat: 48.6710121, lng: 19.233791 },
    { lat: 48.6711447, lng: 19.2341273 },
    { lat: 48.6711999, lng: 19.2341757 },
    { lat: 48.6712546, lng: 19.2341397 },
    { lat: 48.6715974, lng: 19.2345398 },
    { lat: 48.6716841, lng: 19.2345594 },
    { lat: 48.6718983, lng: 19.2350026 },
    { lat: 48.6722034, lng: 19.2351318 },
    { lat: 48.6722968, lng: 19.2352698 },
    { lat: 48.672432, lng: 19.2351985 },
    { lat: 48.6725158, lng: 19.2353694 },
    { lat: 48.6727716, lng: 19.2355782 },
    { lat: 48.6729421, lng: 19.2355376 },
    { lat: 48.6731238, lng: 19.2355889 },
    { lat: 48.6731404, lng: 19.2356716 },
    { lat: 48.6732563, lng: 19.235714 },
    { lat: 48.6733835, lng: 19.2356686 },
    { lat: 48.6737136, lng: 19.2359438 },
    { lat: 48.6738932, lng: 19.2359931 },
    { lat: 48.6739493, lng: 19.2359195 },
    { lat: 48.6740658, lng: 19.235973 },
    { lat: 48.67409, lng: 19.2361019 },
    { lat: 48.6742468, lng: 19.236291 },
    { lat: 48.6742846, lng: 19.2361656 },
    { lat: 48.6745069, lng: 19.2361453 },
    { lat: 48.6748667, lng: 19.2350562 },
    { lat: 48.676249, lng: 19.233089 },
    { lat: 48.6771313, lng: 19.2327803 },
    { lat: 48.6773798, lng: 19.2332328 },
  ],
  Oravce: [
    { lat: 48.6947064, lng: 19.2407044 },
    { lat: 48.6942238, lng: 19.2402604 },
    { lat: 48.693539, lng: 19.2405772 },
    { lat: 48.6915742, lng: 19.2418533 },
    { lat: 48.6905989, lng: 19.2429643 },
    { lat: 48.6900537, lng: 19.2432992 },
    { lat: 48.6899767, lng: 19.2432667 },
    { lat: 48.6890017, lng: 19.2446865 },
    { lat: 48.6886467, lng: 19.2454092 },
    { lat: 48.6884109, lng: 19.2461298 },
    { lat: 48.6881797, lng: 19.2464279 },
    { lat: 48.6881769, lng: 19.246556 },
    { lat: 48.688099, lng: 19.2466698 },
    { lat: 48.6879079, lng: 19.2467266 },
    { lat: 48.6878348, lng: 19.2470125 },
    { lat: 48.6878966, lng: 19.2472404 },
    { lat: 48.6875484, lng: 19.247616 },
    { lat: 48.6873817, lng: 19.2480604 },
    { lat: 48.6873884, lng: 19.2481987 },
    { lat: 48.6870863, lng: 19.2487565 },
    { lat: 48.6868659, lng: 19.2494433 },
    { lat: 48.6866242, lng: 19.2500632 },
    { lat: 48.6865566, lng: 19.2501352 },
    { lat: 48.6865145, lng: 19.2503824 },
    { lat: 48.6863399, lng: 19.2506889 },
    { lat: 48.6860762, lng: 19.2520903 },
    { lat: 48.6861255, lng: 19.2524783 },
    { lat: 48.6860886, lng: 19.2528092 },
    { lat: 48.6858874, lng: 19.2534067 },
    { lat: 48.6854849, lng: 19.2542662 },
    { lat: 48.6854306, lng: 19.2545661 },
    { lat: 48.6852364, lng: 19.255118 },
    { lat: 48.6850861, lng: 19.2550864 },
    { lat: 48.6848803, lng: 19.2546543 },
    { lat: 48.68433, lng: 19.254508 },
    { lat: 48.6839732, lng: 19.2544953 },
    { lat: 48.6832521, lng: 19.2545694 },
    { lat: 48.6832218, lng: 19.2551799 },
    { lat: 48.6824877, lng: 19.2552911 },
    { lat: 48.6822191, lng: 19.2552622 },
    { lat: 48.6821919, lng: 19.2555145 },
    { lat: 48.6820205, lng: 19.2560749 },
    { lat: 48.6819711, lng: 19.2564987 },
    { lat: 48.6813489, lng: 19.2563804 },
    { lat: 48.6810942, lng: 19.2570337 },
    { lat: 48.6809843, lng: 19.2571842 },
    { lat: 48.6808304, lng: 19.2577833 },
    { lat: 48.6803069, lng: 19.2584209 },
    { lat: 48.6801746, lng: 19.2593508 },
    { lat: 48.6800781, lng: 19.2595668 },
    { lat: 48.6796265, lng: 19.2597393 },
    { lat: 48.6782254, lng: 19.2589237 },
    { lat: 48.677628, lng: 19.2588972 },
    { lat: 48.6772733, lng: 19.2587911 },
    { lat: 48.6771994, lng: 19.2588445 },
    { lat: 48.6769684, lng: 19.2585236 },
    { lat: 48.6769451, lng: 19.2581338 },
    { lat: 48.6766202, lng: 19.2582181 },
    { lat: 48.6762873, lng: 19.257371 },
    { lat: 48.6759962, lng: 19.2572508 },
    { lat: 48.6757224, lng: 19.2569475 },
    { lat: 48.6753153, lng: 19.256641 },
    { lat: 48.6751917, lng: 19.2560201 },
    { lat: 48.675264, lng: 19.2558135 },
    { lat: 48.6752783, lng: 19.2556907 },
    { lat: 48.6752382, lng: 19.2556523 },
    { lat: 48.6747596, lng: 19.2554743 },
    { lat: 48.6746335, lng: 19.2556408 },
    { lat: 48.6744399, lng: 19.2557606 },
    { lat: 48.6743348, lng: 19.255713 },
    { lat: 48.6742012, lng: 19.2554641 },
    { lat: 48.674069, lng: 19.2554562 },
    { lat: 48.6739523, lng: 19.2553289 },
    { lat: 48.6737949, lng: 19.2549907 },
    { lat: 48.6737046, lng: 19.2546119 },
    { lat: 48.6734944, lng: 19.2544614 },
    { lat: 48.6733257, lng: 19.2545388 },
    { lat: 48.6734185, lng: 19.2547326 },
    { lat: 48.6734104, lng: 19.2547991 },
    { lat: 48.6733497, lng: 19.2548004 },
    { lat: 48.6734124, lng: 19.2553306 },
    { lat: 48.6738674, lng: 19.2559524 },
    { lat: 48.6745923, lng: 19.2567927 },
    { lat: 48.6759048, lng: 19.2592064 },
    { lat: 48.6768186, lng: 19.2618378 },
    { lat: 48.6773671, lng: 19.2622497 },
    { lat: 48.6792044, lng: 19.2630219 },
    { lat: 48.679535, lng: 19.2638918 },
    { lat: 48.6796001, lng: 19.26391 },
    { lat: 48.6797399, lng: 19.2644221 },
    { lat: 48.6804116, lng: 19.2659141 },
    { lat: 48.6810416, lng: 19.2675284 },
    { lat: 48.6816255, lng: 19.2684875 },
    { lat: 48.6818641, lng: 19.268783 },
    { lat: 48.6829509, lng: 19.2696327 },
    { lat: 48.683511, lng: 19.2704469 },
    { lat: 48.6836601, lng: 19.2708999 },
    { lat: 48.6840675, lng: 19.2715639 },
    { lat: 48.6846955, lng: 19.272035 },
    { lat: 48.6851078, lng: 19.2722376 },
    { lat: 48.6852545, lng: 19.272591 },
    { lat: 48.6854247, lng: 19.2727118 },
    { lat: 48.6861408, lng: 19.2729267 },
    { lat: 48.6866337, lng: 19.2732154 },
    { lat: 48.6871618, lng: 19.2734164 },
    { lat: 48.6877323, lng: 19.2740268 },
    { lat: 48.6881217, lng: 19.2746968 },
    { lat: 48.6888131, lng: 19.2773275 },
    { lat: 48.6888242, lng: 19.2773463 },
    { lat: 48.6894604, lng: 19.2768401 },
    { lat: 48.6896227, lng: 19.2768394 },
    { lat: 48.6897609, lng: 19.276948 },
    { lat: 48.6901198, lng: 19.2769129 },
    { lat: 48.6903718, lng: 19.2767846 },
    { lat: 48.6907919, lng: 19.2771745 },
    { lat: 48.6909252, lng: 19.2770885 },
    { lat: 48.6910362, lng: 19.2766321 },
    { lat: 48.6912374, lng: 19.2761994 },
    { lat: 48.6916767, lng: 19.275865 },
    { lat: 48.6921655, lng: 19.2759762 },
    { lat: 48.6928857, lng: 19.2766727 },
    { lat: 48.692989, lng: 19.2768308 },
    { lat: 48.6930871, lng: 19.2764305 },
    { lat: 48.6934309, lng: 19.2765961 },
    { lat: 48.6939024, lng: 19.2764651 },
    { lat: 48.6939057, lng: 19.2760862 },
    { lat: 48.6937351, lng: 19.2758192 },
    { lat: 48.6938165, lng: 19.2756864 },
    { lat: 48.6940646, lng: 19.2755953 },
    { lat: 48.6943968, lng: 19.2748417 },
    { lat: 48.6945078, lng: 19.2748745 },
    { lat: 48.6947399, lng: 19.2748011 },
    { lat: 48.6947612, lng: 19.2746597 },
    { lat: 48.694868, lng: 19.2746062 },
    { lat: 48.6948894, lng: 19.2741372 },
    { lat: 48.6946469, lng: 19.2740172 },
    { lat: 48.694632, lng: 19.2739182 },
    { lat: 48.6949177, lng: 19.2734604 },
    { lat: 48.6948079, lng: 19.2732652 },
    { lat: 48.6948065, lng: 19.2731419 },
    { lat: 48.6951044, lng: 19.2726832 },
    { lat: 48.6950596, lng: 19.2721501 },
    { lat: 48.6956148, lng: 19.2707357 },
    { lat: 48.6967992, lng: 19.2714298 },
    { lat: 48.6975114, lng: 19.2723188 },
    { lat: 48.6979133, lng: 19.2721451 },
    { lat: 48.698421, lng: 19.2716165 },
    { lat: 48.6990193, lng: 19.2718176 },
    { lat: 48.6999006, lng: 19.2730792 },
    { lat: 48.7004016, lng: 19.2741699 },
    { lat: 48.7014047, lng: 19.2732072 },
    { lat: 48.7013638, lng: 19.2722904 },
    { lat: 48.7010471, lng: 19.2706973 },
    { lat: 48.7012139, lng: 19.2696938 },
    { lat: 48.7009649, lng: 19.2660023 },
    { lat: 48.7010937, lng: 19.2653765 },
    { lat: 48.7015394, lng: 19.2642837 },
    { lat: 48.7022241, lng: 19.2633384 },
    { lat: 48.7036294, lng: 19.2616521 },
    { lat: 48.7048068, lng: 19.2597966 },
    { lat: 48.7065351, lng: 19.2564843 },
    { lat: 48.706978, lng: 19.2547785 },
    { lat: 48.7067029, lng: 19.254427 },
    { lat: 48.7054056, lng: 19.2542703 },
    { lat: 48.705101, lng: 19.2544274 },
    { lat: 48.7048042, lng: 19.2547677 },
    { lat: 48.7042326, lng: 19.2550895 },
    { lat: 48.7032202, lng: 19.2543892 },
    { lat: 48.7027569, lng: 19.2538983 },
    { lat: 48.7026092, lng: 19.253638 },
    { lat: 48.702389, lng: 19.2535381 },
    { lat: 48.7012847, lng: 19.2538251 },
    { lat: 48.701035, lng: 19.2536061 },
    { lat: 48.7008394, lng: 19.2533361 },
    { lat: 48.7007767, lng: 19.2530404 },
    { lat: 48.7007769, lng: 19.2527846 },
    { lat: 48.7005956, lng: 19.252093 },
    { lat: 48.7000057, lng: 19.2510794 },
    { lat: 48.6999542, lng: 19.2510658 },
    { lat: 48.6998752, lng: 19.2494317 },
    { lat: 48.6989188, lng: 19.2484871 },
    { lat: 48.6989497, lng: 19.2481005 },
    { lat: 48.698092, lng: 19.2473581 },
    { lat: 48.6975634, lng: 19.2460745 },
    { lat: 48.6973117, lng: 19.2457208 },
    { lat: 48.6966198, lng: 19.2453969 },
    { lat: 48.6959819, lng: 19.2435138 },
    { lat: 48.6949791, lng: 19.2416333 },
    { lat: 48.6947064, lng: 19.2407044 },
  ],
  Hiadeľ: [
    { lat: 48.8468029, lng: 19.3280898 },
    { lat: 48.8470474, lng: 19.3282051 },
    { lat: 48.8481618, lng: 19.3275883 },
    { lat: 48.8486916, lng: 19.3274442 },
    { lat: 48.8496629, lng: 19.3269664 },
    { lat: 48.8522227, lng: 19.3267357 },
    { lat: 48.8541685, lng: 19.326412 },
    { lat: 48.8552378, lng: 19.3264545 },
    { lat: 48.855869, lng: 19.3269998 },
    { lat: 48.8571764, lng: 19.3272618 },
    { lat: 48.8578453, lng: 19.3271784 },
    { lat: 48.8579997, lng: 19.3265864 },
    { lat: 48.8582088, lng: 19.326031 },
    { lat: 48.8591607, lng: 19.3255928 },
    { lat: 48.8601466, lng: 19.3254454 },
    { lat: 48.8608094, lng: 19.3249517 },
    { lat: 48.8610884, lng: 19.3248432 },
    { lat: 48.8617825, lng: 19.3240343 },
    { lat: 48.8620178, lng: 19.32398 },
    { lat: 48.8621632, lng: 19.3238653 },
    { lat: 48.8626049, lng: 19.3238861 },
    { lat: 48.8630313, lng: 19.3242014 },
    { lat: 48.8640123, lng: 19.324095 },
    { lat: 48.8665248, lng: 19.323368 },
    { lat: 48.8748275, lng: 19.3192624 },
    { lat: 48.876863, lng: 19.318404 },
    { lat: 48.876, lng: 19.318432 },
    { lat: 48.875565, lng: 19.318306 },
    { lat: 48.873992, lng: 19.318001 },
    { lat: 48.873496, lng: 19.317787 },
    { lat: 48.872954, lng: 19.317703 },
    { lat: 48.871947, lng: 19.317614 },
    { lat: 48.870928, lng: 19.317394 },
    { lat: 48.870308, lng: 19.317016 },
    { lat: 48.869216, lng: 19.315987 },
    { lat: 48.868772, lng: 19.315492 },
    { lat: 48.867385, lng: 19.314073 },
    { lat: 48.866699, lng: 19.313316 },
    { lat: 48.866225, lng: 19.312685 },
    { lat: 48.865802, lng: 19.312025 },
    { lat: 48.865301, lng: 19.311033 },
    { lat: 48.863889, lng: 19.30813 },
    { lat: 48.862565, lng: 19.305022 },
    { lat: 48.862441, lng: 19.30473 },
    { lat: 48.862282, lng: 19.304399 },
    { lat: 48.86225, lng: 19.304352 },
    { lat: 48.862042, lng: 19.303917 },
    { lat: 48.861493, lng: 19.303185 },
    { lat: 48.861483, lng: 19.303168 },
    { lat: 48.860871, lng: 19.302012 },
    { lat: 48.860722, lng: 19.301318 },
    { lat: 48.8601409, lng: 19.3000242 },
    { lat: 48.859975, lng: 19.299655 },
    { lat: 48.859631, lng: 19.297808 },
    { lat: 48.859687, lng: 19.296514 },
    { lat: 48.859679, lng: 19.295631 },
    { lat: 48.8584729, lng: 19.2962076 },
    { lat: 48.8578433, lng: 19.2963318 },
    { lat: 48.8564577, lng: 19.296441 },
    { lat: 48.8563353, lng: 19.2962869 },
    { lat: 48.855576, lng: 19.2960113 },
    { lat: 48.8550689, lng: 19.2955168 },
    { lat: 48.8541206, lng: 19.2944001 },
    { lat: 48.8535858, lng: 19.2941757 },
    { lat: 48.8530535, lng: 19.2928734 },
    { lat: 48.852559, lng: 19.2916551 },
    { lat: 48.8515075, lng: 19.2907363 },
    { lat: 48.8509479, lng: 19.2906807 },
    { lat: 48.8508099, lng: 19.2907618 },
    { lat: 48.8504001, lng: 19.2907277 },
    { lat: 48.8487423, lng: 19.2929429 },
    { lat: 48.8486155, lng: 19.2930466 },
    { lat: 48.8485856, lng: 19.2933201 },
    { lat: 48.8486542, lng: 19.2935051 },
    { lat: 48.8483488, lng: 19.2936482 },
    { lat: 48.8477877, lng: 19.2937924 },
    { lat: 48.8470328, lng: 19.2936774 },
    { lat: 48.8452418, lng: 19.2928084 },
    { lat: 48.8449333, lng: 19.2927869 },
    { lat: 48.8447807, lng: 19.2928505 },
    { lat: 48.8446564, lng: 19.2929625 },
    { lat: 48.8444511, lng: 19.2934063 },
    { lat: 48.8443312, lng: 19.2935168 },
    { lat: 48.8438248, lng: 19.2935985 },
    { lat: 48.8436408, lng: 19.2937121 },
    { lat: 48.8433916, lng: 19.2940422 },
    { lat: 48.8432472, lng: 19.2944151 },
    { lat: 48.8432349, lng: 19.2948402 },
    { lat: 48.8430545, lng: 19.2954035 },
    { lat: 48.8428272, lng: 19.2958367 },
    { lat: 48.8424815, lng: 19.2955619 },
    { lat: 48.8417837, lng: 19.2946152 },
    { lat: 48.8416976, lng: 19.2943949 },
    { lat: 48.8416605, lng: 19.2941131 },
    { lat: 48.8414446, lng: 19.2936995 },
    { lat: 48.8413189, lng: 19.2933126 },
    { lat: 48.8405357, lng: 19.2923241 },
    { lat: 48.8403716, lng: 19.2922165 },
    { lat: 48.8394816, lng: 19.2921768 },
    { lat: 48.8385972, lng: 19.2923012 },
    { lat: 48.8383966, lng: 19.2920931 },
    { lat: 48.8378434, lng: 19.2917156 },
    { lat: 48.8372053, lng: 19.2917366 },
    { lat: 48.836567, lng: 19.2914228 },
    { lat: 48.836388, lng: 19.2911237 },
    { lat: 48.8355506, lng: 19.2909581 },
    { lat: 48.8354338, lng: 19.2913208 },
    { lat: 48.8352201, lng: 19.2914942 },
    { lat: 48.8350072, lng: 19.2921079 },
    { lat: 48.8348144, lng: 19.292294 },
    { lat: 48.8347883, lng: 19.2925774 },
    { lat: 48.8346372, lng: 19.2928551 },
    { lat: 48.8344493, lng: 19.2933813 },
    { lat: 48.8342687, lng: 19.2936827 },
    { lat: 48.8342046, lng: 19.2939924 },
    { lat: 48.8340233, lng: 19.2944191 },
    { lat: 48.8335944, lng: 19.2951845 },
    { lat: 48.8329495, lng: 19.2961672 },
    { lat: 48.8329085, lng: 19.2962297 },
    { lat: 48.8318749, lng: 19.2967019 },
    { lat: 48.8313399, lng: 19.2967545 },
    { lat: 48.8302689, lng: 19.2964213 },
    { lat: 48.82956, lng: 19.2967988 },
    { lat: 48.8289495, lng: 19.2976049 },
    { lat: 48.828258, lng: 19.2980388 },
    { lat: 48.827852, lng: 19.29799 },
    { lat: 48.8263908, lng: 19.2982626 },
    { lat: 48.8251752, lng: 19.2986988 },
    { lat: 48.8245107, lng: 19.2990614 },
    { lat: 48.8235179, lng: 19.2999622 },
    { lat: 48.8230996, lng: 19.3006626 },
    { lat: 48.8228332, lng: 19.3009161 },
    { lat: 48.8225298, lng: 19.3014065 },
    { lat: 48.8224268, lng: 19.3013015 },
    { lat: 48.8221482, lng: 19.3017445 },
    { lat: 48.8214664, lng: 19.3022895 },
    { lat: 48.8211002, lng: 19.3022721 },
    { lat: 48.8206761, lng: 19.3024065 },
    { lat: 48.8203296, lng: 19.3023454 },
    { lat: 48.8199874, lng: 19.3024498 },
    { lat: 48.8197479, lng: 19.3024159 },
    { lat: 48.8190912, lng: 19.3028484 },
    { lat: 48.8189418, lng: 19.3030239 },
    { lat: 48.8183208, lng: 19.3030952 },
    { lat: 48.8182522, lng: 19.3031932 },
    { lat: 48.8180553, lng: 19.3032484 },
    { lat: 48.8177842, lng: 19.3034462 },
    { lat: 48.8171861, lng: 19.303737 },
    { lat: 48.8168425, lng: 19.3038362 },
    { lat: 48.8165206, lng: 19.3038661 },
    { lat: 48.8149686, lng: 19.3032514 },
    { lat: 48.8145115, lng: 19.3032647 },
    { lat: 48.8135465, lng: 19.303497 },
    { lat: 48.8130536, lng: 19.3035374 },
    { lat: 48.8127901, lng: 19.3034007 },
    { lat: 48.8126699, lng: 19.303549 },
    { lat: 48.8122897, lng: 19.3036103 },
    { lat: 48.8114675, lng: 19.3028675 },
    { lat: 48.8112143, lng: 19.3024511 },
    { lat: 48.8107978, lng: 19.3019214 },
    { lat: 48.8105612, lng: 19.3011717 },
    { lat: 48.8105219, lng: 19.3005616 },
    { lat: 48.8104118, lng: 19.300259 },
    { lat: 48.8098658, lng: 19.3001808 },
    { lat: 48.8084352, lng: 19.3001502 },
    { lat: 48.8082551, lng: 19.3001074 },
    { lat: 48.8075613, lng: 19.2996464 },
    { lat: 48.8074028, lng: 19.2998672 },
    { lat: 48.807525, lng: 19.3001813 },
    { lat: 48.8074661, lng: 19.3004617 },
    { lat: 48.807276, lng: 19.300559 },
    { lat: 48.8071213, lng: 19.3008163 },
    { lat: 48.807152, lng: 19.3008919 },
    { lat: 48.8070264, lng: 19.3013752 },
    { lat: 48.8069946, lng: 19.3017988 },
    { lat: 48.8070572, lng: 19.3019504 },
    { lat: 48.8069162, lng: 19.3024907 },
    { lat: 48.8068337, lng: 19.3025326 },
    { lat: 48.8069697, lng: 19.3028521 },
    { lat: 48.8068856, lng: 19.3028634 },
    { lat: 48.8068679, lng: 19.3029419 },
    { lat: 48.8065626, lng: 19.3028502 },
    { lat: 48.8063266, lng: 19.302581 },
    { lat: 48.8062713, lng: 19.3023676 },
    { lat: 48.8061296, lng: 19.3026552 },
    { lat: 48.8056034, lng: 19.3026367 },
    { lat: 48.8053479, lng: 19.3027594 },
    { lat: 48.8053514, lng: 19.3034949 },
    { lat: 48.8047063, lng: 19.3039091 },
    { lat: 48.8043036, lng: 19.3024912 },
    { lat: 48.8037615, lng: 19.3011948 },
    { lat: 48.8017613, lng: 19.2954978 },
    { lat: 48.795798, lng: 19.2955683 },
    { lat: 48.7941185, lng: 19.2956634 },
    { lat: 48.7929697, lng: 19.2990927 },
    { lat: 48.792474, lng: 19.3021598 },
    { lat: 48.7917859, lng: 19.305429 },
    { lat: 48.7920891, lng: 19.3101458 },
    { lat: 48.7926311, lng: 19.3104511 },
    { lat: 48.7937063, lng: 19.3102402 },
    { lat: 48.7965592, lng: 19.3093227 },
    { lat: 48.7973389, lng: 19.3095066 },
    { lat: 48.7982235, lng: 19.3107548 },
    { lat: 48.7989959, lng: 19.3107629 },
    { lat: 48.7971721, lng: 19.3130036 },
    { lat: 48.796933, lng: 19.3148873 },
    { lat: 48.7937576, lng: 19.3180693 },
    { lat: 48.7940495, lng: 19.3188753 },
    { lat: 48.7927754, lng: 19.3205669 },
    { lat: 48.7924951, lng: 19.3232708 },
    { lat: 48.7919202, lng: 19.3240045 },
    { lat: 48.7916945, lng: 19.3241381 },
    { lat: 48.7917375, lng: 19.3246603 },
    { lat: 48.7913804, lng: 19.3263228 },
    { lat: 48.7914191, lng: 19.3268272 },
    { lat: 48.7916561, lng: 19.3275949 },
    { lat: 48.7918927, lng: 19.327518 },
    { lat: 48.7923438, lng: 19.3275289 },
    { lat: 48.7927715, lng: 19.3271613 },
    { lat: 48.7930917, lng: 19.3267821 },
    { lat: 48.793225, lng: 19.3267143 },
    { lat: 48.7933308, lng: 19.3268333 },
    { lat: 48.7938327, lng: 19.3268647 },
    { lat: 48.7939029, lng: 19.3268699 },
    { lat: 48.7939446, lng: 19.3267045 },
    { lat: 48.7943001, lng: 19.3268352 },
    { lat: 48.7944287, lng: 19.3271651 },
    { lat: 48.7946554, lng: 19.3280219 },
    { lat: 48.7946714, lng: 19.3284291 },
    { lat: 48.7944868, lng: 19.3292149 },
    { lat: 48.794341, lng: 19.3302763 },
    { lat: 48.7942894, lng: 19.3310616 },
    { lat: 48.7949387, lng: 19.3311821 },
    { lat: 48.7959923, lng: 19.3311785 },
    { lat: 48.7963551, lng: 19.3321221 },
    { lat: 48.7971379, lng: 19.3320904 },
    { lat: 48.7986521, lng: 19.3317929 },
    { lat: 48.7993021, lng: 19.3315515 },
    { lat: 48.7995921, lng: 19.3312221 },
    { lat: 48.7997998, lng: 19.3297148 },
    { lat: 48.8015124, lng: 19.3289976 },
    { lat: 48.8019342, lng: 19.3303785 },
    { lat: 48.8027531, lng: 19.3318144 },
    { lat: 48.8029727, lng: 19.3320215 },
    { lat: 48.8033521, lng: 19.3316559 },
    { lat: 48.8041995, lng: 19.3303258 },
    { lat: 48.8044082, lng: 19.329574 },
    { lat: 48.8047697, lng: 19.3287133 },
    { lat: 48.8050338, lng: 19.3288644 },
    { lat: 48.8050171, lng: 19.3289406 },
    { lat: 48.8051438, lng: 19.3289275 },
    { lat: 48.8054234, lng: 19.3293282 },
    { lat: 48.8056303, lng: 19.3295119 },
    { lat: 48.8057713, lng: 19.3295613 },
    { lat: 48.8060505, lng: 19.3293889 },
    { lat: 48.806764, lng: 19.3296969 },
    { lat: 48.8071382, lng: 19.3295793 },
    { lat: 48.807466, lng: 19.329599 },
    { lat: 48.8081109, lng: 19.3294386 },
    { lat: 48.8081939, lng: 19.3292182 },
    { lat: 48.8083713, lng: 19.3292028 },
    { lat: 48.8087007, lng: 19.3293017 },
    { lat: 48.8099015, lng: 19.3283398 },
    { lat: 48.812006, lng: 19.3250529 },
    { lat: 48.8125866, lng: 19.3243688 },
    { lat: 48.8135841, lng: 19.3241598 },
    { lat: 48.8138657, lng: 19.3239177 },
    { lat: 48.8147321, lng: 19.3239841 },
    { lat: 48.8151256, lng: 19.3235589 },
    { lat: 48.8158925, lng: 19.323286 },
    { lat: 48.8163869, lng: 19.3230389 },
    { lat: 48.8169219, lng: 19.3226794 },
    { lat: 48.8182673, lng: 19.3219378 },
    { lat: 48.8188183, lng: 19.3213433 },
    { lat: 48.8190296, lng: 19.3213067 },
    { lat: 48.8199791, lng: 19.3217441 },
    { lat: 48.8209686, lng: 19.3215534 },
    { lat: 48.8216424, lng: 19.3215496 },
    { lat: 48.821971, lng: 19.3214476 },
    { lat: 48.8225526, lng: 19.3211129 },
    { lat: 48.8232165, lng: 19.3202156 },
    { lat: 48.8237112, lng: 19.3198104 },
    { lat: 48.8239901, lng: 19.3194433 },
    { lat: 48.824254, lng: 19.3194263 },
    { lat: 48.824564, lng: 19.3192848 },
    { lat: 48.8250387, lng: 19.3192898 },
    { lat: 48.8252126, lng: 19.3193913 },
    { lat: 48.825765, lng: 19.319439 },
    { lat: 48.8259885, lng: 19.3195659 },
    { lat: 48.8264529, lng: 19.3203253 },
    { lat: 48.8266677, lng: 19.3201939 },
    { lat: 48.8272153, lng: 19.3202421 },
    { lat: 48.8277688, lng: 19.3201265 },
    { lat: 48.8280393, lng: 19.3201481 },
    { lat: 48.8282371, lng: 19.3200156 },
    { lat: 48.8290291, lng: 19.3198951 },
    { lat: 48.8290966, lng: 19.319818 },
    { lat: 48.8292631, lng: 19.3197894 },
    { lat: 48.8296033, lng: 19.3200939 },
    { lat: 48.8298293, lng: 19.320008 },
    { lat: 48.8303295, lng: 19.320099 },
    { lat: 48.830393, lng: 19.3202258 },
    { lat: 48.8318632, lng: 19.3215853 },
    { lat: 48.8319901, lng: 19.3218147 },
    { lat: 48.8324784, lng: 19.3218676 },
    { lat: 48.8328845, lng: 19.3220636 },
    { lat: 48.8335408, lng: 19.3221415 },
    { lat: 48.8347617, lng: 19.3218618 },
    { lat: 48.8352254, lng: 19.3220423 },
    { lat: 48.8353883, lng: 19.3219805 },
    { lat: 48.835982, lng: 19.3219514 },
    { lat: 48.8366181, lng: 19.3223156 },
    { lat: 48.8368274, lng: 19.3221228 },
    { lat: 48.8371425, lng: 19.3220143 },
    { lat: 48.8377617, lng: 19.3223116 },
    { lat: 48.838392, lng: 19.3221011 },
    { lat: 48.8385465, lng: 19.3219139 },
    { lat: 48.8389511, lng: 19.3219388 },
    { lat: 48.8391189, lng: 19.322577 },
    { lat: 48.8398155, lng: 19.3232648 },
    { lat: 48.8399564, lng: 19.3235256 },
    { lat: 48.8402492, lng: 19.323518 },
    { lat: 48.84141, lng: 19.3237519 },
    { lat: 48.8421682, lng: 19.3241264 },
    { lat: 48.8430361, lng: 19.3250983 },
    { lat: 48.8430872, lng: 19.3255124 },
    { lat: 48.8432148, lng: 19.3258209 },
    { lat: 48.8434557, lng: 19.3258855 },
    { lat: 48.8438745, lng: 19.3266119 },
    { lat: 48.8442823, lng: 19.3271328 },
    { lat: 48.8446631, lng: 19.327268 },
    { lat: 48.8449334, lng: 19.3276697 },
    { lat: 48.8449028, lng: 19.3278104 },
    { lat: 48.8450872, lng: 19.3279435 },
    { lat: 48.8450706, lng: 19.32816 },
    { lat: 48.8457415, lng: 19.3281691 },
    { lat: 48.8468029, lng: 19.3280898 },
  ],
  DolnýHarmanec: [
    { lat: 48.842787, lng: 18.994196 },
    { lat: 48.842648, lng: 18.994686 },
    { lat: 48.842476, lng: 18.995041 },
    { lat: 48.842231, lng: 18.995375 },
    { lat: 48.841952, lng: 18.99581 },
    { lat: 48.841895, lng: 18.996106 },
    { lat: 48.841683, lng: 18.996752 },
    { lat: 48.841555, lng: 18.996877 },
    { lat: 48.841244, lng: 18.996951 },
    { lat: 48.841074, lng: 18.997095 },
    { lat: 48.841014, lng: 18.997172 },
    { lat: 48.840669, lng: 18.997632 },
    { lat: 48.840159, lng: 18.998077 },
    { lat: 48.840109, lng: 18.998019 },
    { lat: 48.839931, lng: 18.997927 },
    { lat: 48.839788, lng: 18.997995 },
    { lat: 48.839611, lng: 18.998033 },
    { lat: 48.839386, lng: 18.998214 },
    { lat: 48.838801, lng: 18.998535 },
    { lat: 48.8386975, lng: 18.9986059 },
    { lat: 48.838622, lng: 18.998684 },
    { lat: 48.838483, lng: 18.998753 },
    { lat: 48.838407, lng: 18.99879 },
    { lat: 48.838318, lng: 18.998834 },
    { lat: 48.838179, lng: 18.998977 },
    { lat: 48.837905, lng: 18.999443 },
    { lat: 48.837808, lng: 18.999534 },
    { lat: 48.837662, lng: 18.999606 },
    { lat: 48.837326, lng: 18.999613 },
    { lat: 48.837067, lng: 18.999281 },
    { lat: 48.836938, lng: 18.999172 },
    { lat: 48.836338, lng: 18.999272 },
    { lat: 48.8363176, lng: 18.9992748 },
    { lat: 48.835879, lng: 18.999334 },
    { lat: 48.835719, lng: 18.999377 },
    { lat: 48.835587, lng: 18.999441 },
    { lat: 48.835518, lng: 18.999592 },
    { lat: 48.835396, lng: 19.000104 },
    { lat: 48.835207, lng: 19.000489 },
    { lat: 48.834918, lng: 19.00094 },
    { lat: 48.834805, lng: 19.001398 },
    { lat: 48.834645, lng: 19.001671 },
    { lat: 48.8345, lng: 19.001744 },
    { lat: 48.834258, lng: 19.001786 },
    { lat: 48.834197, lng: 19.00183 },
    { lat: 48.83389, lng: 19.002051 },
    { lat: 48.833571, lng: 19.002513 },
    { lat: 48.833382, lng: 19.003072 },
    { lat: 48.83317, lng: 19.002847 },
    { lat: 48.832988, lng: 19.002723 },
    { lat: 48.832671, lng: 19.002643 },
    { lat: 48.832524, lng: 19.002576 },
    { lat: 48.832288, lng: 19.00236 },
    { lat: 48.832099, lng: 19.002196 },
    { lat: 48.831842, lng: 19.002102 },
    { lat: 48.831788, lng: 19.002083 },
    { lat: 48.831575, lng: 19.002015 },
    { lat: 48.831497, lng: 19.002121 },
    { lat: 48.831337, lng: 19.002144 },
    { lat: 48.831326, lng: 19.002162 },
    { lat: 48.83117, lng: 19.002435 },
    { lat: 48.831047, lng: 19.002586 },
    { lat: 48.830773, lng: 19.002646 },
    { lat: 48.830632, lng: 19.002684 },
    { lat: 48.830354, lng: 19.00278 },
    { lat: 48.829847, lng: 19.002529 },
    { lat: 48.829436, lng: 19.002668 },
    { lat: 48.829168, lng: 19.002673 },
    { lat: 48.829026, lng: 19.002428 },
    { lat: 48.828953, lng: 19.002397 },
    { lat: 48.828769, lng: 19.002384 },
    { lat: 48.828165, lng: 19.002414 },
    { lat: 48.827758, lng: 19.00232 },
    { lat: 48.827443, lng: 19.002286 },
    { lat: 48.827352, lng: 19.002311 },
    { lat: 48.827049, lng: 19.002485 },
    { lat: 48.826853, lng: 19.002483 },
    { lat: 48.826397, lng: 19.002624 },
    { lat: 48.826234, lng: 19.002626 },
    { lat: 48.82581, lng: 19.002211 },
    { lat: 48.82575, lng: 19.002132 },
    { lat: 48.825594, lng: 19.001758 },
    { lat: 48.825449, lng: 19.001594 },
    { lat: 48.825294, lng: 19.001426 },
    { lat: 48.825161, lng: 19.001386 },
    { lat: 48.824898, lng: 19.001814 },
    { lat: 48.824732, lng: 19.00216 },
    { lat: 48.824515, lng: 19.002336 },
    { lat: 48.82432, lng: 19.002398 },
    { lat: 48.824131, lng: 19.002332 },
    { lat: 48.823938, lng: 19.002146 },
    { lat: 48.823747, lng: 19.002287 },
    { lat: 48.823449, lng: 19.002421 },
    { lat: 48.823247, lng: 19.002577 },
    { lat: 48.823179, lng: 19.002605 },
    { lat: 48.823023, lng: 19.002704 },
    { lat: 48.822923, lng: 19.003049 },
    { lat: 48.822721, lng: 19.003236 },
    { lat: 48.822531, lng: 19.003246 },
    { lat: 48.822171, lng: 19.003109 },
    { lat: 48.822026, lng: 19.002999 },
    { lat: 48.821665, lng: 19.003108 },
    { lat: 48.820964, lng: 19.002956 },
    { lat: 48.820674, lng: 19.002665 },
    { lat: 48.820305, lng: 19.002528 },
    { lat: 48.819843, lng: 19.002399 },
    { lat: 48.819257, lng: 19.001993 },
    { lat: 48.818655, lng: 19.002096 },
    { lat: 48.818259, lng: 19.002241 },
    { lat: 48.818111, lng: 19.002212 },
    { lat: 48.817935, lng: 19.002185 },
    { lat: 48.81756, lng: 19.002174 },
    { lat: 48.817434, lng: 19.002005 },
    { lat: 48.817298, lng: 19.001678 },
    { lat: 48.817272, lng: 19.001488 },
    { lat: 48.817253, lng: 19.001206 },
    { lat: 48.816915, lng: 19.000271 },
    { lat: 48.816847, lng: 18.999786 },
    { lat: 48.816758, lng: 18.999287 },
    { lat: 48.816676, lng: 18.998298 },
    { lat: 48.816726, lng: 18.99768 },
    { lat: 48.816758, lng: 18.99747 },
    { lat: 48.816945, lng: 18.997014 },
    { lat: 48.816938, lng: 18.996728 },
    { lat: 48.816518, lng: 18.995675 },
    { lat: 48.816291, lng: 18.995502 },
    { lat: 48.815563, lng: 18.994943 },
    { lat: 48.814725, lng: 18.994723 },
    { lat: 48.814361, lng: 18.994615 },
    { lat: 48.813835, lng: 18.994279 },
    { lat: 48.813317, lng: 18.993976 },
    { lat: 48.812856, lng: 18.993904 },
    { lat: 48.812365, lng: 18.993659 },
    { lat: 48.81207, lng: 18.993562 },
    { lat: 48.811809, lng: 18.992914 },
    { lat: 48.811491, lng: 18.992805 },
    { lat: 48.811211, lng: 18.992652 },
    { lat: 48.811088, lng: 18.992402 },
    { lat: 48.810866, lng: 18.992212 },
    { lat: 48.809849, lng: 18.992015 },
    { lat: 48.809056, lng: 18.991965 },
    { lat: 48.808545, lng: 18.991826 },
    { lat: 48.808025, lng: 18.991792 },
    { lat: 48.807555, lng: 18.991629 },
    { lat: 48.807442, lng: 18.991557 },
    { lat: 48.807236, lng: 18.991533 },
    { lat: 48.806092, lng: 18.991116 },
    { lat: 48.805647, lng: 18.991463 },
    { lat: 48.805287, lng: 18.991717 },
    { lat: 48.804789, lng: 18.992056 },
    { lat: 48.804591, lng: 18.992135 },
    { lat: 48.803975, lng: 18.992201 },
    { lat: 48.803278, lng: 18.992268 },
    { lat: 48.802944, lng: 18.99229 },
    { lat: 48.802436, lng: 18.992455 },
    { lat: 48.802399, lng: 18.992492 },
    { lat: 48.802129, lng: 18.992766 },
    { lat: 48.801982, lng: 18.993495 },
    { lat: 48.801811, lng: 18.994346 },
    { lat: 48.801766, lng: 18.994441 },
    { lat: 48.801503, lng: 18.994784 },
    { lat: 48.801031, lng: 18.995297 },
    { lat: 48.800877, lng: 18.99553 },
    { lat: 48.800364, lng: 18.996329 },
    { lat: 48.800291, lng: 18.996482 },
    { lat: 48.800066, lng: 18.996955 },
    { lat: 48.799704, lng: 18.997771 },
    { lat: 48.79947, lng: 18.998157 },
    { lat: 48.799351, lng: 18.998502 },
    { lat: 48.799086, lng: 18.999119 },
    { lat: 48.798969, lng: 18.99947 },
    { lat: 48.798658, lng: 18.999563 },
    { lat: 48.798276, lng: 19.000055 },
    { lat: 48.797931, lng: 19.000219 },
    { lat: 48.79778, lng: 19.000438 },
    { lat: 48.797737, lng: 19.0005 },
    { lat: 48.797548, lng: 19.000774 },
    { lat: 48.797182, lng: 19.000863 },
    { lat: 48.797122, lng: 19.001185 },
    { lat: 48.797078, lng: 19.001528 },
    { lat: 48.796845, lng: 19.001893 },
    { lat: 48.79677, lng: 19.001983 },
    { lat: 48.796494, lng: 19.002295 },
    { lat: 48.796327, lng: 19.003078 },
    { lat: 48.796289, lng: 19.003734 },
    { lat: 48.796236, lng: 19.004302 },
    { lat: 48.795977, lng: 19.004961 },
    { lat: 48.795801, lng: 19.005626 },
    { lat: 48.795609, lng: 19.005976 },
    { lat: 48.795289, lng: 19.0071 },
    { lat: 48.795198, lng: 19.007202 },
    { lat: 48.794773, lng: 19.007734 },
    { lat: 48.794709, lng: 19.008317 },
    { lat: 48.794654, lng: 19.008746 },
    { lat: 48.794345, lng: 19.008998 },
    { lat: 48.794284, lng: 19.009049 },
    { lat: 48.794066, lng: 19.00947 },
    { lat: 48.793479, lng: 19.010491 },
    { lat: 48.79278, lng: 19.010974 },
    { lat: 48.792458, lng: 19.011259 },
    { lat: 48.792365, lng: 19.011508 },
    { lat: 48.7922886, lng: 19.0116755 },
    { lat: 48.792243, lng: 19.011842 },
    { lat: 48.792267, lng: 19.01228 },
    { lat: 48.792288, lng: 19.012663 },
    { lat: 48.792236, lng: 19.013457 },
    { lat: 48.792234, lng: 19.013998 },
    { lat: 48.792265, lng: 19.014244 },
    { lat: 48.791745, lng: 19.014696 },
    { lat: 48.791509, lng: 19.014873 },
    { lat: 48.791388, lng: 19.015269 },
    { lat: 48.790772, lng: 19.015783 },
    { lat: 48.790654, lng: 19.015972 },
    { lat: 48.7908318, lng: 19.0163934 },
    { lat: 48.791011, lng: 19.0172223 },
    { lat: 48.7915557, lng: 19.0189313 },
    { lat: 48.791713, lng: 19.0192546 },
    { lat: 48.7920502, lng: 19.0195941 },
    { lat: 48.7924448, lng: 19.0202205 },
    { lat: 48.7929339, lng: 19.0212276 },
    { lat: 48.7933104, lng: 19.021766 },
    { lat: 48.7938523, lng: 19.0237544 },
    { lat: 48.7941515, lng: 19.0251996 },
    { lat: 48.794155, lng: 19.0257173 },
    { lat: 48.7943115, lng: 19.0262314 },
    { lat: 48.7948542, lng: 19.0266006 },
    { lat: 48.7947504, lng: 19.0267759 },
    { lat: 48.794815, lng: 19.0271422 },
    { lat: 48.7947165, lng: 19.0273823 },
    { lat: 48.7947609, lng: 19.0277248 },
    { lat: 48.7948732, lng: 19.027807 },
    { lat: 48.7947627, lng: 19.028212 },
    { lat: 48.7946544, lng: 19.028252 },
    { lat: 48.7944101, lng: 19.028195 },
    { lat: 48.7940012, lng: 19.0283382 },
    { lat: 48.7938377, lng: 19.0279086 },
    { lat: 48.7934308, lng: 19.0274376 },
    { lat: 48.7929676, lng: 19.0271941 },
    { lat: 48.7928129, lng: 19.0268983 },
    { lat: 48.7924031, lng: 19.0268192 },
    { lat: 48.7922734, lng: 19.0268852 },
    { lat: 48.7921287, lng: 19.0271043 },
    { lat: 48.7916868, lng: 19.0263402 },
    { lat: 48.7908811, lng: 19.0270185 },
    { lat: 48.7905441, lng: 19.0276948 },
    { lat: 48.7904906, lng: 19.0285155 },
    { lat: 48.7900922, lng: 19.0293604 },
    { lat: 48.7896729, lng: 19.0308262 },
    { lat: 48.7894592, lng: 19.0335731 },
    { lat: 48.7895884, lng: 19.0338129 },
    { lat: 48.7896407, lng: 19.0348555 },
    { lat: 48.7903131, lng: 19.0362836 },
    { lat: 48.7903891, lng: 19.0366739 },
    { lat: 48.7906673, lng: 19.0370299 },
    { lat: 48.790746, lng: 19.0384167 },
    { lat: 48.7910201, lng: 19.039144 },
    { lat: 48.791136, lng: 19.0402823 },
    { lat: 48.7911251, lng: 19.0405181 },
    { lat: 48.7910382, lng: 19.0408771 },
    { lat: 48.7910508, lng: 19.0410144 },
    { lat: 48.7908084, lng: 19.0420287 },
    { lat: 48.7909517, lng: 19.0432197 },
    { lat: 48.7913026, lng: 19.0442653 },
    { lat: 48.7912976, lng: 19.0452885 },
    { lat: 48.790918, lng: 19.0480694 },
    { lat: 48.7909315, lng: 19.0494859 },
    { lat: 48.7908541, lng: 19.0498991 },
    { lat: 48.7909093, lng: 19.0508003 },
    { lat: 48.7909092, lng: 19.0518786 },
    { lat: 48.7911037, lng: 19.0535622 },
    { lat: 48.7910457, lng: 19.0559866 },
    { lat: 48.7905262, lng: 19.0575928 },
    { lat: 48.790264, lng: 19.0576506 },
    { lat: 48.7903309, lng: 19.0578836 },
    { lat: 48.7966435, lng: 19.0622302 },
    { lat: 48.796722, lng: 19.062468 },
    { lat: 48.7965917, lng: 19.0630178 },
    { lat: 48.7963788, lng: 19.0645147 },
    { lat: 48.7974083, lng: 19.0646792 },
    { lat: 48.7975602, lng: 19.0645141 },
    { lat: 48.7975788, lng: 19.0641379 },
    { lat: 48.7977827, lng: 19.0639587 },
    { lat: 48.7978972, lng: 19.0640136 },
    { lat: 48.7978626, lng: 19.0643616 },
    { lat: 48.7977214, lng: 19.0646248 },
    { lat: 48.7977478, lng: 19.0648588 },
    { lat: 48.8046595, lng: 19.0682508 },
    { lat: 48.8054741, lng: 19.0686506 },
    { lat: 48.8058124, lng: 19.0684468 },
    { lat: 48.806243, lng: 19.0684219 },
    { lat: 48.806676, lng: 19.0686553 },
    { lat: 48.8069547, lng: 19.0686589 },
    { lat: 48.8071925, lng: 19.068837 },
    { lat: 48.8080792, lng: 19.0688949 },
    { lat: 48.808669, lng: 19.0681544 },
    { lat: 48.8087324, lng: 19.0682044 },
    { lat: 48.8089465, lng: 19.0680863 },
    { lat: 48.809743, lng: 19.0675113 },
    { lat: 48.8109983, lng: 19.0672313 },
    { lat: 48.8114648, lng: 19.0670457 },
    { lat: 48.8121397, lng: 19.0662203 },
    { lat: 48.8124043, lng: 19.0661031 },
    { lat: 48.8128078, lng: 19.066552 },
    { lat: 48.8129407, lng: 19.0665279 },
    { lat: 48.8131437, lng: 19.0666421 },
    { lat: 48.8134615, lng: 19.0670693 },
    { lat: 48.8134557, lng: 19.066816 },
    { lat: 48.8136391, lng: 19.066946 },
    { lat: 48.8140278, lng: 19.0675544 },
    { lat: 48.8142444, lng: 19.0676488 },
    { lat: 48.814624, lng: 19.0676623 },
    { lat: 48.814873, lng: 19.0680656 },
    { lat: 48.8150488, lng: 19.0681793 },
    { lat: 48.8153072, lng: 19.0685254 },
    { lat: 48.8155994, lng: 19.0692976 },
    { lat: 48.8157798, lng: 19.0700214 },
    { lat: 48.8162136, lng: 19.0711477 },
    { lat: 48.8164034, lng: 19.0712913 },
    { lat: 48.8168172, lng: 19.0722002 },
    { lat: 48.816811, lng: 19.0727311 },
    { lat: 48.8172681, lng: 19.0730584 },
    { lat: 48.8182955, lng: 19.0743821 },
    { lat: 48.8186534, lng: 19.0751605 },
    { lat: 48.8186889, lng: 19.0754666 },
    { lat: 48.8191774, lng: 19.0752484 },
    { lat: 48.8199358, lng: 19.0753533 },
    { lat: 48.8205985, lng: 19.0757831 },
    { lat: 48.8207451, lng: 19.0757857 },
    { lat: 48.8212753, lng: 19.0762676 },
    { lat: 48.8216224, lng: 19.0767374 },
    { lat: 48.8220618, lng: 19.0768962 },
    { lat: 48.8227481, lng: 19.0779553 },
    { lat: 48.8232676, lng: 19.0781736 },
    { lat: 48.8238036, lng: 19.0785264 },
    { lat: 48.8243558, lng: 19.0791357 },
    { lat: 48.8254285, lng: 19.078423 },
    { lat: 48.8260608, lng: 19.0781931 },
    { lat: 48.8264913, lng: 19.078119 },
    { lat: 48.8273455, lng: 19.0782287 },
    { lat: 48.827821, lng: 19.0785179 },
    { lat: 48.828383, lng: 19.0780331 },
    { lat: 48.8289323, lng: 19.0776741 },
    { lat: 48.8287376, lng: 19.0770706 },
    { lat: 48.8289457, lng: 19.0769277 },
    { lat: 48.8300567, lng: 19.0769841 },
    { lat: 48.8306018, lng: 19.0768582 },
    { lat: 48.8316617, lng: 19.0771559 },
    { lat: 48.8321216, lng: 19.0776116 },
    { lat: 48.8340227, lng: 19.0787072 },
    { lat: 48.8346194, lng: 19.0789398 },
    { lat: 48.8354788, lng: 19.0791051 },
    { lat: 48.8364517, lng: 19.0795835 },
    { lat: 48.8368276, lng: 19.0797088 },
    { lat: 48.8369713, lng: 19.0797386 },
    { lat: 48.8373766, lng: 19.0796605 },
    { lat: 48.837867, lng: 19.0797087 },
    { lat: 48.8383458, lng: 19.0800868 },
    { lat: 48.8392692, lng: 19.0802259 },
    { lat: 48.8397883, lng: 19.080147 },
    { lat: 48.8398352, lng: 19.0804108 },
    { lat: 48.8405381, lng: 19.0794903 },
    { lat: 48.8415625, lng: 19.0783488 },
    { lat: 48.8419693, lng: 19.0780733 },
    { lat: 48.8425718, lng: 19.0774291 },
    { lat: 48.8433991, lng: 19.0759714 },
    { lat: 48.843879, lng: 19.0754456 },
    { lat: 48.8443521, lng: 19.0750512 },
    { lat: 48.8446834, lng: 19.0745735 },
    { lat: 48.8453414, lng: 19.0739339 },
    { lat: 48.8458255, lng: 19.0736023 },
    { lat: 48.8459957, lng: 19.0735761 },
    { lat: 48.8463612, lng: 19.0729946 },
    { lat: 48.8464986, lng: 19.0726081 },
    { lat: 48.8468246, lng: 19.0719774 },
    { lat: 48.8480945, lng: 19.070471 },
    { lat: 48.8485113, lng: 19.0702358 },
    { lat: 48.848948, lng: 19.0698813 },
    { lat: 48.8495528, lng: 19.0692846 },
    { lat: 48.8502251, lng: 19.0682931 },
    { lat: 48.8505624, lng: 19.0678938 },
    { lat: 48.8510148, lng: 19.0668369 },
    { lat: 48.8512927, lng: 19.0663032 },
    { lat: 48.8542405, lng: 19.0617067 },
    { lat: 48.8543826, lng: 19.0613905 },
    { lat: 48.854886, lng: 19.0606373 },
    { lat: 48.8549356, lng: 19.0604332 },
    { lat: 48.8551723, lng: 19.0603392 },
    { lat: 48.8553202, lng: 19.0600967 },
    { lat: 48.8557839, lng: 19.0596516 },
    { lat: 48.8562922, lng: 19.0592859 },
    { lat: 48.8566563, lng: 19.0588558 },
    { lat: 48.8570197, lng: 19.0582986 },
    { lat: 48.8572525, lng: 19.0580646 },
    { lat: 48.8573493, lng: 19.0580579 },
    { lat: 48.8576259, lng: 19.0577075 },
    { lat: 48.857991, lng: 19.0568659 },
    { lat: 48.8581773, lng: 19.0565807 },
    { lat: 48.8584655, lng: 19.0563672 },
    { lat: 48.8588422, lng: 19.0562561 },
    { lat: 48.8592757, lng: 19.0560016 },
    { lat: 48.8599393, lng: 19.0559456 },
    { lat: 48.8606077, lng: 19.055739 },
    { lat: 48.8609794, lng: 19.0554609 },
    { lat: 48.8617098, lng: 19.0546937 },
    { lat: 48.8623308, lng: 19.0547353 },
    { lat: 48.8624572, lng: 19.0546456 },
    { lat: 48.8643997, lng: 19.0542654 },
    { lat: 48.866606, lng: 19.0554183 },
    { lat: 48.8669219, lng: 19.0557842 },
    { lat: 48.8674, lng: 19.0560105 },
    { lat: 48.8686492, lng: 19.0560031 },
    { lat: 48.8702004, lng: 19.055994 },
    { lat: 48.8709562, lng: 19.0557537 },
    { lat: 48.8730359, lng: 19.0567183 },
    { lat: 48.877519, lng: 19.060201 },
    { lat: 48.878454, lng: 19.0588373 },
    { lat: 48.879331, lng: 19.056901 },
    { lat: 48.880002, lng: 19.055241 },
    { lat: 48.880264, lng: 19.054589 },
    { lat: 48.880328, lng: 19.054429 },
    { lat: 48.880399, lng: 19.054145 },
    { lat: 48.880544, lng: 19.053795 },
    { lat: 48.880708, lng: 19.053398 },
    { lat: 48.88121, lng: 19.052234 },
    { lat: 48.881814, lng: 19.051522 },
    { lat: 48.883404, lng: 19.049565 },
    { lat: 48.883635, lng: 19.047588 },
    { lat: 48.883685, lng: 19.04708 },
    { lat: 48.883052, lng: 19.044412 },
    { lat: 48.882268, lng: 19.043472 },
    { lat: 48.881233, lng: 19.041865 },
    { lat: 48.881701, lng: 19.04037 },
    { lat: 48.882157, lng: 19.039015 },
    { lat: 48.881897, lng: 19.038182 },
    { lat: 48.881592, lng: 19.037278 },
    { lat: 48.881253, lng: 19.036268 },
    { lat: 48.88108, lng: 19.035737 },
    { lat: 48.881042, lng: 19.035625 },
    { lat: 48.881002, lng: 19.035537 },
    { lat: 48.880663, lng: 19.034871 },
    { lat: 48.880296, lng: 19.034141 },
    { lat: 48.879983, lng: 19.033625 },
    { lat: 48.879904, lng: 19.033253 },
    { lat: 48.879068, lng: 19.032148 },
    { lat: 48.878921, lng: 19.031164 },
    { lat: 48.878785, lng: 19.030887 },
    { lat: 48.878002, lng: 19.029292 },
    { lat: 48.877934, lng: 19.029152 },
    { lat: 48.87773, lng: 19.027852 },
    { lat: 48.877517, lng: 19.027388 },
    { lat: 48.877188, lng: 19.026932 },
    { lat: 48.876755, lng: 19.025976 },
    { lat: 48.876675, lng: 19.025721 },
    { lat: 48.875562, lng: 19.02336 },
    { lat: 48.875317, lng: 19.022879 },
    { lat: 48.874871, lng: 19.023255 },
    { lat: 48.8747, lng: 19.023338 },
    { lat: 48.874444, lng: 19.023543 },
    { lat: 48.873421, lng: 19.024012 },
    { lat: 48.872838, lng: 19.024336 },
    { lat: 48.872541, lng: 19.024171 },
    { lat: 48.871635, lng: 19.024019 },
    { lat: 48.871328, lng: 19.024145 },
    { lat: 48.871088, lng: 19.024669 },
    { lat: 48.870987, lng: 19.024957 },
    { lat: 48.870408, lng: 19.025897 },
    { lat: 48.870137, lng: 19.026002 },
    { lat: 48.869935, lng: 19.026117 },
    { lat: 48.869782, lng: 19.025994 },
    { lat: 48.869395, lng: 19.025383 },
    { lat: 48.869178, lng: 19.024807 },
    { lat: 48.869104, lng: 19.024714 },
    { lat: 48.868869, lng: 19.02442 },
    { lat: 48.868567, lng: 19.02426 },
    { lat: 48.868437, lng: 19.024294 },
    { lat: 48.868026, lng: 19.024919 },
    { lat: 48.867683, lng: 19.025333 },
    { lat: 48.86738, lng: 19.02551 },
    { lat: 48.867177, lng: 19.025502 },
    { lat: 48.866782, lng: 19.025625 },
    { lat: 48.866474, lng: 19.025458 },
    { lat: 48.866263, lng: 19.025233 },
    { lat: 48.865895, lng: 19.025049 },
    { lat: 48.865648, lng: 19.024833 },
    { lat: 48.865529, lng: 19.024612 },
    { lat: 48.865339, lng: 19.024458 },
    { lat: 48.865157, lng: 19.024431 },
    { lat: 48.864902, lng: 19.02435 },
    { lat: 48.864618, lng: 19.023706 },
    { lat: 48.864464, lng: 19.023464 },
    { lat: 48.864342, lng: 19.023214 },
    { lat: 48.864307, lng: 19.023067 },
    { lat: 48.863977, lng: 19.023087 },
    { lat: 48.863683, lng: 19.023384 },
    { lat: 48.863428, lng: 19.023558 },
    { lat: 48.863268, lng: 19.023544 },
    { lat: 48.862939, lng: 19.023315 },
    { lat: 48.862643, lng: 19.022568 },
    { lat: 48.86216, lng: 19.022073 },
    { lat: 48.861705, lng: 19.021907 },
    { lat: 48.861369, lng: 19.021607 },
    { lat: 48.861174, lng: 19.02156 },
    { lat: 48.860928, lng: 19.021397 },
    { lat: 48.860751, lng: 19.021223 },
    { lat: 48.860549, lng: 19.021094 },
    { lat: 48.860363, lng: 19.020801 },
    { lat: 48.860142, lng: 19.020625 },
    { lat: 48.8599, lng: 19.020339 },
    { lat: 48.859648, lng: 19.020186 },
    { lat: 48.859409, lng: 19.019992 },
    { lat: 48.859105, lng: 19.019678 },
    { lat: 48.858842, lng: 19.019633 },
    { lat: 48.858523, lng: 19.019015 },
    { lat: 48.858044, lng: 19.018191 },
    { lat: 48.857983, lng: 19.018211 },
    { lat: 48.857863, lng: 19.017999 },
    { lat: 48.857639, lng: 19.017652 },
    { lat: 48.857456, lng: 19.017505 },
    { lat: 48.857246, lng: 19.017144 },
    { lat: 48.856635, lng: 19.016967 },
    { lat: 48.856395, lng: 19.016648 },
    { lat: 48.856117, lng: 19.016772 },
    { lat: 48.855641, lng: 19.016638 },
    { lat: 48.855567, lng: 19.016575 },
    { lat: 48.855476, lng: 19.016296 },
    { lat: 48.855365, lng: 19.015813 },
    { lat: 48.855247, lng: 19.015577 },
    { lat: 48.854837, lng: 19.014914 },
    { lat: 48.854657, lng: 19.014911 },
    { lat: 48.854302, lng: 19.0148 },
    { lat: 48.853856, lng: 19.014257 },
    { lat: 48.853743, lng: 19.014168 },
    { lat: 48.853267, lng: 19.013591 },
    { lat: 48.852972, lng: 19.013321 },
    { lat: 48.852951, lng: 19.013236 },
    { lat: 48.852841, lng: 19.013131 },
    { lat: 48.85274, lng: 19.013033 },
    { lat: 48.852742, lng: 19.012878 },
    { lat: 48.852626, lng: 19.012398 },
    { lat: 48.852398, lng: 19.011847 },
    { lat: 48.852103, lng: 19.011324 },
    { lat: 48.852019, lng: 19.011223 },
    { lat: 48.851921, lng: 19.011184 },
    { lat: 48.851671, lng: 19.010985 },
    { lat: 48.851559, lng: 19.010944 },
    { lat: 48.851414, lng: 19.010679 },
    { lat: 48.851137, lng: 19.010383 },
    { lat: 48.851064, lng: 19.010335 },
    { lat: 48.850914, lng: 19.010201 },
    { lat: 48.850802, lng: 19.010003 },
    { lat: 48.850567, lng: 19.009824 },
    { lat: 48.850302, lng: 19.009375 },
    { lat: 48.850247, lng: 19.00931 },
    { lat: 48.849951, lng: 19.008854 },
    { lat: 48.849804, lng: 19.008392 },
    { lat: 48.849744, lng: 19.007431 },
    { lat: 48.849829, lng: 19.006868 },
    { lat: 48.849848, lng: 19.00654 },
    { lat: 48.849692, lng: 19.006295 },
    { lat: 48.849438, lng: 19.005824 },
    { lat: 48.849235, lng: 19.005541 },
    { lat: 48.849144, lng: 19.005287 },
    { lat: 48.849064, lng: 19.004917 },
    { lat: 48.848849, lng: 19.004207 },
    { lat: 48.848875, lng: 19.00389 },
    { lat: 48.848999, lng: 19.003456 },
    { lat: 48.84906, lng: 19.002805 },
    { lat: 48.84907, lng: 19.002153 },
    { lat: 48.849167, lng: 19.001775 },
    { lat: 48.849416, lng: 19.001327 },
    { lat: 48.849534, lng: 19.000963 },
    { lat: 48.84951, lng: 19.000707 },
    { lat: 48.849424, lng: 19.00044 },
    { lat: 48.849295, lng: 18.999866 },
    { lat: 48.84925, lng: 18.999176 },
    { lat: 48.848916, lng: 18.998444 },
    { lat: 48.848796, lng: 18.997486 },
    { lat: 48.84873, lng: 18.997269 },
    { lat: 48.848254, lng: 18.996804 },
    { lat: 48.848053, lng: 18.996824 },
    { lat: 48.847569, lng: 18.996471 },
    { lat: 48.847554, lng: 18.995416 },
    { lat: 48.847498, lng: 18.995002 },
    { lat: 48.84737, lng: 18.994746 },
    { lat: 48.847296, lng: 18.994552 },
    { lat: 48.847187, lng: 18.994379 },
    { lat: 48.846864, lng: 18.99436 },
    { lat: 48.846686, lng: 18.994288 },
    { lat: 48.846636, lng: 18.994232 },
    { lat: 48.846553, lng: 18.994184 },
    { lat: 48.846354, lng: 18.994139 },
    { lat: 48.846269, lng: 18.994085 },
    { lat: 48.846189, lng: 18.994105 },
    { lat: 48.845918, lng: 18.993946 },
    { lat: 48.845663, lng: 18.993945 },
    { lat: 48.84456, lng: 18.994077 },
    { lat: 48.844266, lng: 18.993934 },
    { lat: 48.844181, lng: 18.993821 },
    { lat: 48.843783, lng: 18.993569 },
    { lat: 48.843489, lng: 18.993479 },
    { lat: 48.843235, lng: 18.99356 },
    { lat: 48.843001, lng: 18.993881 },
    { lat: 48.842787, lng: 18.994196 },
  ],
  Tajov: [
    { lat: 48.7451161, lng: 19.0907956 },
    { lat: 48.7451961, lng: 19.0906322 },
    { lat: 48.7451887, lng: 19.0900086 },
    { lat: 48.7450892, lng: 19.0892569 },
    { lat: 48.7453041, lng: 19.088273 },
    { lat: 48.7453644, lng: 19.0859507 },
    { lat: 48.7461273, lng: 19.0843765 },
    { lat: 48.746769, lng: 19.0833425 },
    { lat: 48.7471756, lng: 19.0823143 },
    { lat: 48.7472412, lng: 19.0816863 },
    { lat: 48.7472646, lng: 19.0803669 },
    { lat: 48.7473763, lng: 19.0795747 },
    { lat: 48.7479239, lng: 19.0787539 },
    { lat: 48.7484351, lng: 19.0773625 },
    { lat: 48.749667, lng: 19.0756781 },
    { lat: 48.7498803, lng: 19.0744417 },
    { lat: 48.7500702, lng: 19.0739513 },
    { lat: 48.7500543, lng: 19.073747 },
    { lat: 48.7504357, lng: 19.0726018 },
    { lat: 48.7509235, lng: 19.0713504 },
    { lat: 48.7511913, lng: 19.0700509 },
    { lat: 48.7525255, lng: 19.0688662 },
    { lat: 48.7526389, lng: 19.0683827 },
    { lat: 48.7529199, lng: 19.0675932 },
    { lat: 48.753436, lng: 19.0667544 },
    { lat: 48.7538796, lng: 19.0663372 },
    { lat: 48.7545609, lng: 19.0661844 },
    { lat: 48.7551442, lng: 19.0637769 },
    { lat: 48.7549761, lng: 19.0632119 },
    { lat: 48.7550045, lng: 19.0617495 },
    { lat: 48.7546718, lng: 19.0609346 },
    { lat: 48.754464, lng: 19.0606997 },
    { lat: 48.7545523, lng: 19.0601462 },
    { lat: 48.7549592, lng: 19.0586966 },
    { lat: 48.7549612, lng: 19.0584916 },
    { lat: 48.7553186, lng: 19.0568644 },
    { lat: 48.7551005, lng: 19.0569247 },
    { lat: 48.7546862, lng: 19.0565959 },
    { lat: 48.754391, lng: 19.0556331 },
    { lat: 48.7542351, lng: 19.0553305 },
    { lat: 48.7541975, lng: 19.0549621 },
    { lat: 48.7542329, lng: 19.0547015 },
    { lat: 48.7541645, lng: 19.0542595 },
    { lat: 48.754205, lng: 19.0529494 },
    { lat: 48.7544011, lng: 19.0525436 },
    { lat: 48.7542859, lng: 19.0522666 },
    { lat: 48.7541519, lng: 19.0513255 },
    { lat: 48.7538616, lng: 19.0503437 },
    { lat: 48.7537912, lng: 19.0490744 },
    { lat: 48.7540352, lng: 19.0481572 },
    { lat: 48.7539384, lng: 19.0479139 },
    { lat: 48.7540026, lng: 19.0467429 },
    { lat: 48.7539381, lng: 19.0465584 },
    { lat: 48.754299, lng: 19.0449621 },
    { lat: 48.7547088, lng: 19.044366 },
    { lat: 48.7551054, lng: 19.0433709 },
    { lat: 48.7551563, lng: 19.0431049 },
    { lat: 48.7551333, lng: 19.0428725 },
    { lat: 48.7553203, lng: 19.0425531 },
    { lat: 48.7554102, lng: 19.0425696 },
    { lat: 48.755487, lng: 19.0418726 },
    { lat: 48.7556634, lng: 19.0418867 },
    { lat: 48.7561116, lng: 19.0410192 },
    { lat: 48.756289, lng: 19.0398123 },
    { lat: 48.7561471, lng: 19.039413 },
    { lat: 48.7562177, lng: 19.038885 },
    { lat: 48.7564888, lng: 19.0382658 },
    { lat: 48.7565946, lng: 19.0372753 },
    { lat: 48.7565977, lng: 19.036754 },
    { lat: 48.7567913, lng: 19.0365446 },
    { lat: 48.7566437, lng: 19.0351984 },
    { lat: 48.7565429, lng: 19.0349845 },
    { lat: 48.756483, lng: 19.0346462 },
    { lat: 48.7565625, lng: 19.0329038 },
    { lat: 48.7568158, lng: 19.0319339 },
    { lat: 48.756755, lng: 19.0316658 },
    { lat: 48.7570131, lng: 19.0304495 },
    { lat: 48.7571248, lng: 19.0285908 },
    { lat: 48.7570014, lng: 19.0279311 },
    { lat: 48.7572045, lng: 19.0271006 },
    { lat: 48.7568326, lng: 19.0266577 },
    { lat: 48.756519, lng: 19.0260452 },
    { lat: 48.7563207, lng: 19.0255128 },
    { lat: 48.7561183, lng: 19.0238635 },
    { lat: 48.7561793, lng: 19.0233948 },
    { lat: 48.7561473, lng: 19.022467 },
    { lat: 48.7559875, lng: 19.0217322 },
    { lat: 48.7554198, lng: 19.0205773 },
    { lat: 48.7554099, lng: 19.0199528 },
    { lat: 48.7552639, lng: 19.0193153 },
    { lat: 48.7550901, lng: 19.0180355 },
    { lat: 48.7550911, lng: 19.017473 },
    { lat: 48.7549634, lng: 19.0161246 },
    { lat: 48.7549973, lng: 19.0148508 },
    { lat: 48.7553109, lng: 19.0116308 },
    { lat: 48.755616, lng: 19.0101221 },
    { lat: 48.7559636, lng: 19.0069392 },
    { lat: 48.7562288, lng: 19.0045111 },
    { lat: 48.756309, lng: 19.003777 },
    { lat: 48.756239, lng: 19.003754 },
    { lat: 48.755781, lng: 19.002998 },
    { lat: 48.755031, lng: 19.00271 },
    { lat: 48.7544, lng: 19.002742 },
    { lat: 48.754006, lng: 19.002615 },
    { lat: 48.753693, lng: 19.002633 },
    { lat: 48.75346, lng: 19.002574 },
    { lat: 48.753343, lng: 19.002542 },
    { lat: 48.753331, lng: 19.002516 },
    { lat: 48.753152, lng: 19.002112 },
    { lat: 48.752953, lng: 19.001855 },
    { lat: 48.752627, lng: 19.00126 },
    { lat: 48.75248, lng: 19.000968 },
    { lat: 48.752181, lng: 19.000425 },
    { lat: 48.751352, lng: 18.999936 },
    { lat: 48.750096, lng: 19.000075 },
    { lat: 48.7498604, lng: 18.9996803 },
    { lat: 48.749705, lng: 18.99942 },
    { lat: 48.7496925, lng: 18.9993731 },
    { lat: 48.749521, lng: 18.998731 },
    { lat: 48.749058, lng: 18.998383 },
    { lat: 48.749013, lng: 18.998333 },
    { lat: 48.748994, lng: 18.998313 },
    { lat: 48.748976, lng: 18.998293 },
    { lat: 48.748451, lng: 18.998114 },
    { lat: 48.748301, lng: 18.997913 },
    { lat: 48.74809, lng: 18.99789 },
    { lat: 48.74801, lng: 18.998007 },
    { lat: 48.7479689, lng: 18.9979921 },
    { lat: 48.747872, lng: 18.997957 },
    { lat: 48.747749, lng: 18.997747 },
    { lat: 48.747471, lng: 18.997671 },
    { lat: 48.747262, lng: 18.997747 },
    { lat: 48.746791, lng: 18.997444 },
    { lat: 48.74594, lng: 18.996898 },
    { lat: 48.745631, lng: 18.997085 },
    { lat: 48.7456346, lng: 18.9975155 },
    { lat: 48.7456363, lng: 18.997725 },
    { lat: 48.7456817, lng: 19.0031822 },
    { lat: 48.7453941, lng: 19.0039388 },
    { lat: 48.7449794, lng: 19.004345 },
    { lat: 48.7447984, lng: 19.0050932 },
    { lat: 48.7448066, lng: 19.0052551 },
    { lat: 48.744917, lng: 19.0055017 },
    { lat: 48.7449078, lng: 19.0057636 },
    { lat: 48.7447507, lng: 19.0064279 },
    { lat: 48.7447475, lng: 19.0066562 },
    { lat: 48.7450423, lng: 19.0075106 },
    { lat: 48.7451424, lng: 19.0083157 },
    { lat: 48.7451426, lng: 19.0085397 },
    { lat: 48.745064, lng: 19.0089499 },
    { lat: 48.7450922, lng: 19.0093536 },
    { lat: 48.7449579, lng: 19.0095945 },
    { lat: 48.7449192, lng: 19.0099901 },
    { lat: 48.7448489, lng: 19.0101847 },
    { lat: 48.7448671, lng: 19.0104316 },
    { lat: 48.7450519, lng: 19.010701 },
    { lat: 48.7450709, lng: 19.0109893 },
    { lat: 48.7449009, lng: 19.0114629 },
    { lat: 48.7448327, lng: 19.0118268 },
    { lat: 48.7447668, lng: 19.0121782 },
    { lat: 48.7447631, lng: 19.0122343 },
    { lat: 48.7446862, lng: 19.0133956 },
    { lat: 48.7447077, lng: 19.0134372 },
    { lat: 48.7448173, lng: 19.0136498 },
    { lat: 48.7449456, lng: 19.0141599 },
    { lat: 48.7448028, lng: 19.0143031 },
    { lat: 48.7447953, lng: 19.0144454 },
    { lat: 48.7447144, lng: 19.0146312 },
    { lat: 48.7445463, lng: 19.0147599 },
    { lat: 48.7442926, lng: 19.0150712 },
    { lat: 48.7440174, lng: 19.0154088 },
    { lat: 48.7438759, lng: 19.0154183 },
    { lat: 48.7436744, lng: 19.0158737 },
    { lat: 48.7435173, lng: 19.0159775 },
    { lat: 48.7434598, lng: 19.0160708 },
    { lat: 48.7432474, lng: 19.0164155 },
    { lat: 48.7432175, lng: 19.0167597 },
    { lat: 48.7431667, lng: 19.016512 },
    { lat: 48.7432705, lng: 19.0170758 },
    { lat: 48.7433095, lng: 19.0172692 },
    { lat: 48.7434129, lng: 19.0173045 },
    { lat: 48.7433415, lng: 19.0176124 },
    { lat: 48.7434265, lng: 19.0177762 },
    { lat: 48.7434426, lng: 19.0180783 },
    { lat: 48.7436904, lng: 19.0184521 },
    { lat: 48.7436435, lng: 19.0187315 },
    { lat: 48.7437133, lng: 19.0190628 },
    { lat: 48.7436641, lng: 19.0196993 },
    { lat: 48.7438122, lng: 19.0204449 },
    { lat: 48.7437599, lng: 19.0209746 },
    { lat: 48.7437975, lng: 19.0212282 },
    { lat: 48.7438863, lng: 19.0212941 },
    { lat: 48.7438646, lng: 19.021422 },
    { lat: 48.7439206, lng: 19.0215002 },
    { lat: 48.7438753, lng: 19.0216094 },
    { lat: 48.7437409, lng: 19.0217195 },
    { lat: 48.7437895, lng: 19.0218532 },
    { lat: 48.7436922, lng: 19.022272 },
    { lat: 48.7437424, lng: 19.0224195 },
    { lat: 48.7437436, lng: 19.0226219 },
    { lat: 48.7436839, lng: 19.0228252 },
    { lat: 48.7437755, lng: 19.0233216 },
    { lat: 48.7437516, lng: 19.0236122 },
    { lat: 48.7438204, lng: 19.0238158 },
    { lat: 48.7439039, lng: 19.024377 },
    { lat: 48.7439369, lng: 19.0249634 },
    { lat: 48.7438122, lng: 19.0249488 },
    { lat: 48.7438307, lng: 19.0252524 },
    { lat: 48.7436421, lng: 19.0262565 },
    { lat: 48.7435229, lng: 19.0265309 },
    { lat: 48.7436072, lng: 19.0266373 },
    { lat: 48.743732, lng: 19.0273914 },
    { lat: 48.74387, lng: 19.027627 },
    { lat: 48.7438694, lng: 19.0279721 },
    { lat: 48.7440158, lng: 19.0287973 },
    { lat: 48.7439483, lng: 19.0289822 },
    { lat: 48.7439546, lng: 19.0292444 },
    { lat: 48.7445749, lng: 19.0307278 },
    { lat: 48.7442485, lng: 19.0314122 },
    { lat: 48.7442134, lng: 19.0318574 },
    { lat: 48.7443123, lng: 19.0320104 },
    { lat: 48.7443204, lng: 19.0322617 },
    { lat: 48.7438734, lng: 19.0327213 },
    { lat: 48.743921, lng: 19.032973 },
    { lat: 48.743324, lng: 19.034126 },
    { lat: 48.7432215, lng: 19.034535 },
    { lat: 48.7431953, lng: 19.0350438 },
    { lat: 48.7432349, lng: 19.0354294 },
    { lat: 48.7431677, lng: 19.036204 },
    { lat: 48.7434512, lng: 19.0365941 },
    { lat: 48.7434594, lng: 19.0368464 },
    { lat: 48.7435172, lng: 19.0369208 },
    { lat: 48.7434457, lng: 19.0371387 },
    { lat: 48.743351, lng: 19.0372064 },
    { lat: 48.743333, lng: 19.0373983 },
    { lat: 48.743315, lng: 19.0375902 },
    { lat: 48.7431731, lng: 19.0380195 },
    { lat: 48.7430091, lng: 19.0381644 },
    { lat: 48.7431355, lng: 19.0386603 },
    { lat: 48.7431415, lng: 19.0392551 },
    { lat: 48.7429935, lng: 19.0394035 },
    { lat: 48.7426689, lng: 19.0399226 },
    { lat: 48.7427364, lng: 19.0402603 },
    { lat: 48.7428801, lng: 19.0405821 },
    { lat: 48.7429983, lng: 19.0406914 },
    { lat: 48.7431948, lng: 19.0413076 },
    { lat: 48.7431732, lng: 19.0417534 },
    { lat: 48.7431967, lng: 19.04193 },
    { lat: 48.7432659, lng: 19.0428351 },
    { lat: 48.7435095, lng: 19.0428924 },
    { lat: 48.7435705, lng: 19.0429067 },
    { lat: 48.7435653, lng: 19.0430629 },
    { lat: 48.7435579, lng: 19.0432861 },
    { lat: 48.7436323, lng: 19.0434229 },
    { lat: 48.7436534, lng: 19.0437306 },
    { lat: 48.7438273, lng: 19.0439632 },
    { lat: 48.7433066, lng: 19.0439196 },
    { lat: 48.7430862, lng: 19.0442464 },
    { lat: 48.742817, lng: 19.0444308 },
    { lat: 48.7418093, lng: 19.0445227 },
    { lat: 48.7417346, lng: 19.0442015 },
    { lat: 48.7414969, lng: 19.0442527 },
    { lat: 48.7413146, lng: 19.044415 },
    { lat: 48.7411445, lng: 19.0441934 },
    { lat: 48.7407721, lng: 19.0443 },
    { lat: 48.7405698, lng: 19.0444682 },
    { lat: 48.7402853, lng: 19.0449569 },
    { lat: 48.7398172, lng: 19.045762 },
    { lat: 48.7392946, lng: 19.0459504 },
    { lat: 48.7392406, lng: 19.0460652 },
    { lat: 48.7395729, lng: 19.046374 },
    { lat: 48.739649, lng: 19.0466578 },
    { lat: 48.739937, lng: 19.0469374 },
    { lat: 48.7403144, lng: 19.0476982 },
    { lat: 48.7404452, lng: 19.0484187 },
    { lat: 48.7403792, lng: 19.0484966 },
    { lat: 48.7403751, lng: 19.0487075 },
    { lat: 48.7404731, lng: 19.0492921 },
    { lat: 48.7405544, lng: 19.0495614 },
    { lat: 48.7407315, lng: 19.0496772 },
    { lat: 48.74081, lng: 19.0496032 },
    { lat: 48.7407051, lng: 19.0502785 },
    { lat: 48.7407399, lng: 19.0504981 },
    { lat: 48.7408761, lng: 19.0507215 },
    { lat: 48.7409224, lng: 19.0511277 },
    { lat: 48.7410445, lng: 19.0516274 },
    { lat: 48.740872, lng: 19.0518346 },
    { lat: 48.7407044, lng: 19.0516806 },
    { lat: 48.7405981, lng: 19.0510902 },
    { lat: 48.7400938, lng: 19.0514883 },
    { lat: 48.7398575, lng: 19.0512938 },
    { lat: 48.739721, lng: 19.0514748 },
    { lat: 48.7394255, lng: 19.0521933 },
    { lat: 48.7391635, lng: 19.0522958 },
    { lat: 48.7389551, lng: 19.0522307 },
    { lat: 48.7387502, lng: 19.0515162 },
    { lat: 48.7393395, lng: 19.0515545 },
    { lat: 48.7391484, lng: 19.0510938 },
    { lat: 48.7391901, lng: 19.0508644 },
    { lat: 48.739069, lng: 19.0507915 },
    { lat: 48.7390488, lng: 19.0506559 },
    { lat: 48.7392408, lng: 19.0503721 },
    { lat: 48.7391371, lng: 19.0500311 },
    { lat: 48.7388783, lng: 19.0497042 },
    { lat: 48.7393593, lng: 19.0488641 },
    { lat: 48.7394364, lng: 19.0477001 },
    { lat: 48.7389542, lng: 19.0476632 },
    { lat: 48.7384274, lng: 19.047277 },
    { lat: 48.7374489, lng: 19.0472101 },
    { lat: 48.7369607, lng: 19.0482221 },
    { lat: 48.7366762, lng: 19.0486637 },
    { lat: 48.7357681, lng: 19.0490808 },
    { lat: 48.7345354, lng: 19.0469769 },
    { lat: 48.7338689, lng: 19.045646 },
    { lat: 48.7339417, lng: 19.0452744 },
    { lat: 48.7339497, lng: 19.0447589 },
    { lat: 48.7338527, lng: 19.0440403 },
    { lat: 48.7338397, lng: 19.0436679 },
    { lat: 48.7338988, lng: 19.0433847 },
    { lat: 48.7334848, lng: 19.0423475 },
    { lat: 48.7330958, lng: 19.0420749 },
    { lat: 48.7327808, lng: 19.0419513 },
    { lat: 48.7326082, lng: 19.0416554 },
    { lat: 48.732463, lng: 19.0416385 },
    { lat: 48.7321568, lng: 19.0424343 },
    { lat: 48.7317445, lng: 19.0423297 },
    { lat: 48.7315935, lng: 19.0421974 },
    { lat: 48.731461, lng: 19.0417129 },
    { lat: 48.7316157, lng: 19.0414242 },
    { lat: 48.7315246, lng: 19.0412714 },
    { lat: 48.731438, lng: 19.0413202 },
    { lat: 48.7312142, lng: 19.0409509 },
    { lat: 48.7309348, lng: 19.0407079 },
    { lat: 48.7306675, lng: 19.0393351 },
    { lat: 48.7303945, lng: 19.0391057 },
    { lat: 48.7299814, lng: 19.0390934 },
    { lat: 48.729793, lng: 19.0387571 },
    { lat: 48.7292995, lng: 19.0386259 },
    { lat: 48.7287633, lng: 19.0389045 },
    { lat: 48.7287683, lng: 19.0392078 },
    { lat: 48.7283066, lng: 19.0385476 },
    { lat: 48.7280878, lng: 19.0389237 },
    { lat: 48.7276265, lng: 19.0389908 },
    { lat: 48.7270104, lng: 19.0400135 },
    { lat: 48.7264192, lng: 19.0402519 },
    { lat: 48.7263544, lng: 19.0408904 },
    { lat: 48.72617, lng: 19.0411939 },
    { lat: 48.7260431, lng: 19.0416316 },
    { lat: 48.7262409, lng: 19.0422921 },
    { lat: 48.7261537, lng: 19.042622 },
    { lat: 48.7262647, lng: 19.0430168 },
    { lat: 48.7262489, lng: 19.0436913 },
    { lat: 48.7260516, lng: 19.0437561 },
    { lat: 48.7261991, lng: 19.0440224 },
    { lat: 48.7261683, lng: 19.044398 },
    { lat: 48.726212, lng: 19.0446379 },
    { lat: 48.7264044, lng: 19.0448337 },
    { lat: 48.7265351, lng: 19.0447452 },
    { lat: 48.7267596, lng: 19.0448363 },
    { lat: 48.7268538, lng: 19.0456825 },
    { lat: 48.7266435, lng: 19.0459176 },
    { lat: 48.7267056, lng: 19.0466383 },
    { lat: 48.7273854, lng: 19.0473579 },
    { lat: 48.7279623, lng: 19.0476214 },
    { lat: 48.7281914, lng: 19.0474459 },
    { lat: 48.7287461, lng: 19.0478756 },
    { lat: 48.7289443, lng: 19.0483654 },
    { lat: 48.7292023, lng: 19.0486769 },
    { lat: 48.7295188, lng: 19.0495056 },
    { lat: 48.7295579, lng: 19.0499236 },
    { lat: 48.729838, lng: 19.0504072 },
    { lat: 48.7303572, lng: 19.0507789 },
    { lat: 48.7306035, lng: 19.0511268 },
    { lat: 48.7308664, lng: 19.051226 },
    { lat: 48.7311649, lng: 19.0511293 },
    { lat: 48.7316744, lng: 19.051461 },
    { lat: 48.7320687, lng: 19.0518627 },
    { lat: 48.7325773, lng: 19.0525427 },
    { lat: 48.7326483, lng: 19.0528142 },
    { lat: 48.7330318, lng: 19.0532886 },
    { lat: 48.7330405, lng: 19.0533729 },
    { lat: 48.7332319, lng: 19.0536614 },
    { lat: 48.7333074, lng: 19.0539288 },
    { lat: 48.7335017, lng: 19.0540959 },
    { lat: 48.733747, lng: 19.0544693 },
    { lat: 48.7337997, lng: 19.0544658 },
    { lat: 48.7341394, lng: 19.0549244 },
    { lat: 48.7346587, lng: 19.0553879 },
    { lat: 48.7352011, lng: 19.0554345 },
    { lat: 48.7353471, lng: 19.0556696 },
    { lat: 48.7356638, lng: 19.0556262 },
    { lat: 48.7358936, lng: 19.0559118 },
    { lat: 48.7364788, lng: 19.0560974 },
    { lat: 48.7367454, lng: 19.056288 },
    { lat: 48.736767, lng: 19.0563798 },
    { lat: 48.7373954, lng: 19.0564562 },
    { lat: 48.7389971, lng: 19.05697 },
    { lat: 48.7418702, lng: 19.0582447 },
    { lat: 48.7423382, lng: 19.0586288 },
    { lat: 48.7425551, lng: 19.059068 },
    { lat: 48.7427183, lng: 19.0595366 },
    { lat: 48.7427117, lng: 19.0602593 },
    { lat: 48.7430212, lng: 19.0606565 },
    { lat: 48.7432687, lng: 19.0614112 },
    { lat: 48.7433741, lng: 19.0615821 },
    { lat: 48.7434242, lng: 19.0621129 },
    { lat: 48.7433856, lng: 19.0621498 },
    { lat: 48.7433971, lng: 19.0624496 },
    { lat: 48.7437775, lng: 19.0627309 },
    { lat: 48.7435649, lng: 19.0633453 },
    { lat: 48.7432688, lng: 19.0639435 },
    { lat: 48.7428878, lng: 19.0631565 },
    { lat: 48.7422016, lng: 19.0622579 },
    { lat: 48.74183, lng: 19.0619935 },
    { lat: 48.7388292, lng: 19.0611453 },
    { lat: 48.7362798, lng: 19.060345 },
    { lat: 48.7346922, lng: 19.0595959 },
    { lat: 48.7321683, lng: 19.0591486 },
    { lat: 48.7294746, lng: 19.0583771 },
    { lat: 48.7294737, lng: 19.0581572 },
    { lat: 48.7297352, lng: 19.0575463 },
    { lat: 48.7301295, lng: 19.0570216 },
    { lat: 48.7300184, lng: 19.0568076 },
    { lat: 48.7298646, lng: 19.0570015 },
    { lat: 48.7297521, lng: 19.056893 },
    { lat: 48.7297747, lng: 19.056734 },
    { lat: 48.7296526, lng: 19.0565937 },
    { lat: 48.7292248, lng: 19.0568315 },
    { lat: 48.7290457, lng: 19.0564523 },
    { lat: 48.7290583, lng: 19.0563405 },
    { lat: 48.7289133, lng: 19.0561943 },
    { lat: 48.7285466, lng: 19.055287 },
    { lat: 48.7284255, lng: 19.054577 },
    { lat: 48.727868, lng: 19.0552192 },
    { lat: 48.726662, lng: 19.0561919 },
    { lat: 48.7268528, lng: 19.0564448 },
    { lat: 48.7270615, lng: 19.0565837 },
    { lat: 48.727298, lng: 19.056897 },
    { lat: 48.7274886, lng: 19.057373 },
    { lat: 48.7276821, lng: 19.0581283 },
    { lat: 48.7279323, lng: 19.058129 },
    { lat: 48.728275, lng: 19.058397 },
    { lat: 48.7283129, lng: 19.0585933 },
    { lat: 48.7288658, lng: 19.0590776 },
    { lat: 48.7291869, lng: 19.0591163 },
    { lat: 48.7293908, lng: 19.0590687 },
    { lat: 48.7298364, lng: 19.0594806 },
    { lat: 48.7299567, lng: 19.0597651 },
    { lat: 48.7303253, lng: 19.0602629 },
    { lat: 48.730655, lng: 19.0609467 },
    { lat: 48.7307093, lng: 19.0612447 },
    { lat: 48.731203, lng: 19.0616255 },
    { lat: 48.7314543, lng: 19.0616714 },
    { lat: 48.7316449, lng: 19.0618086 },
    { lat: 48.7318893, lng: 19.0618478 },
    { lat: 48.7321735, lng: 19.0617589 },
    { lat: 48.7328968, lng: 19.0620892 },
    { lat: 48.7330803, lng: 19.0620825 },
    { lat: 48.7332509, lng: 19.0622016 },
    { lat: 48.7334484, lng: 19.0621876 },
    { lat: 48.7339721, lng: 19.0629421 },
    { lat: 48.7339459, lng: 19.0637051 },
    { lat: 48.7339837, lng: 19.0639886 },
    { lat: 48.7342945, lng: 19.06409 },
    { lat: 48.7345431, lng: 19.0646227 },
    { lat: 48.7347995, lng: 19.0646577 },
    { lat: 48.734967, lng: 19.064535 },
    { lat: 48.7352803, lng: 19.0645964 },
    { lat: 48.7356583, lng: 19.06447 },
    { lat: 48.7362005, lng: 19.0647385 },
    { lat: 48.7395483, lng: 19.0640531 },
    { lat: 48.7399126, lng: 19.0638515 },
    { lat: 48.7404568, lng: 19.063776 },
    { lat: 48.7406381, lng: 19.0637509 },
    { lat: 48.7410471, lng: 19.0635877 },
    { lat: 48.7414513, lng: 19.063823 },
    { lat: 48.7421519, lng: 19.064561 },
    { lat: 48.7423777, lng: 19.0652875 },
    { lat: 48.7426056, lng: 19.0657877 },
    { lat: 48.74277, lng: 19.0660391 },
    { lat: 48.7428217, lng: 19.0661181 },
    { lat: 48.7432202, lng: 19.0693843 },
    { lat: 48.743105, lng: 19.0697403 },
    { lat: 48.7432616, lng: 19.0699436 },
    { lat: 48.743431, lng: 19.070579 },
    { lat: 48.7437126, lng: 19.0719768 },
    { lat: 48.7437104, lng: 19.0727057 },
    { lat: 48.743599, lng: 19.0729535 },
    { lat: 48.7434743, lng: 19.0728498 },
    { lat: 48.7424443, lng: 19.0745789 },
    { lat: 48.7423161, lng: 19.0754193 },
    { lat: 48.7423498, lng: 19.0756216 },
    { lat: 48.7424819, lng: 19.0758084 },
    { lat: 48.7422723, lng: 19.0759687 },
    { lat: 48.7420673, lng: 19.0760156 },
    { lat: 48.7418854, lng: 19.0759638 },
    { lat: 48.7410908, lng: 19.075629 },
    { lat: 48.7405584, lng: 19.0751481 },
    { lat: 48.740442, lng: 19.0748935 },
    { lat: 48.7403422, lng: 19.0750377 },
    { lat: 48.7409504, lng: 19.0762957 },
    { lat: 48.7411263, lng: 19.0776977 },
    { lat: 48.7413728, lng: 19.0776432 },
    { lat: 48.7414526, lng: 19.0778223 },
    { lat: 48.741308, lng: 19.0788078 },
    { lat: 48.741222, lng: 19.078804 },
    { lat: 48.7410927, lng: 19.0792431 },
    { lat: 48.740941, lng: 19.0792525 },
    { lat: 48.7409045, lng: 19.0795814 },
    { lat: 48.7398704, lng: 19.0805292 },
    { lat: 48.7405202, lng: 19.0806259 },
    { lat: 48.7406931, lng: 19.0808824 },
    { lat: 48.741054, lng: 19.0808424 },
    { lat: 48.74112, lng: 19.0806977 },
    { lat: 48.7411993, lng: 19.0807004 },
    { lat: 48.7412131, lng: 19.0807789 },
    { lat: 48.7414798, lng: 19.0808357 },
    { lat: 48.7415634, lng: 19.0807261 },
    { lat: 48.7416638, lng: 19.0807737 },
    { lat: 48.7417738, lng: 19.0806233 },
    { lat: 48.7419781, lng: 19.080585 },
    { lat: 48.7422129, lng: 19.0806847 },
    { lat: 48.742325, lng: 19.0806677 },
    { lat: 48.742451, lng: 19.0807784 },
    { lat: 48.7426525, lng: 19.0807258 },
    { lat: 48.7429207, lng: 19.0809281 },
    { lat: 48.7429651, lng: 19.0808888 },
    { lat: 48.7432165, lng: 19.081705 },
    { lat: 48.7433938, lng: 19.0819402 },
    { lat: 48.743418, lng: 19.0822421 },
    { lat: 48.7432918, lng: 19.0825819 },
    { lat: 48.7432414, lng: 19.0831134 },
    { lat: 48.7431016, lng: 19.0833186 },
    { lat: 48.7429764, lng: 19.0837697 },
    { lat: 48.7429958, lng: 19.0841583 },
    { lat: 48.7427138, lng: 19.0846301 },
    { lat: 48.7427144, lng: 19.0847322 },
    { lat: 48.7428137, lng: 19.0853453 },
    { lat: 48.7429602, lng: 19.0858651 },
    { lat: 48.7429196, lng: 19.0876786 },
    { lat: 48.7431717, lng: 19.0884211 },
    { lat: 48.7435688, lng: 19.0887196 },
    { lat: 48.7436221, lng: 19.0888857 },
    { lat: 48.7435204, lng: 19.0889923 },
    { lat: 48.7434022, lng: 19.0894276 },
    { lat: 48.7434409, lng: 19.0898872 },
    { lat: 48.7434285, lng: 19.0905226 },
    { lat: 48.7433793, lng: 19.0906193 },
    { lat: 48.7432063, lng: 19.0907226 },
    { lat: 48.7431336, lng: 19.0908712 },
    { lat: 48.7431158, lng: 19.0913554 },
    { lat: 48.7429899, lng: 19.0916541 },
    { lat: 48.7429983, lng: 19.0918478 },
    { lat: 48.7430689, lng: 19.0920423 },
    { lat: 48.7430859, lng: 19.0932259 },
    { lat: 48.7428018, lng: 19.0938593 },
    { lat: 48.7426797, lng: 19.0944208 },
    { lat: 48.7426412, lng: 19.0949627 },
    { lat: 48.7427231, lng: 19.0957714 },
    { lat: 48.7427918, lng: 19.0956986 },
    { lat: 48.7438756, lng: 19.0945514 },
    { lat: 48.7441695, lng: 19.0940476 },
    { lat: 48.7445655, lng: 19.0929185 },
    { lat: 48.7448568, lng: 19.0916116 },
    { lat: 48.7449518, lng: 19.0913872 },
    { lat: 48.7452215, lng: 19.0911244 },
    { lat: 48.7451268, lng: 19.0908269 },
    { lat: 48.7451161, lng: 19.0907956 },
  ],
  Lučatín: [
    { lat: 48.7570482, lng: 19.3274085 },
    { lat: 48.7570485, lng: 19.3274632 },
    { lat: 48.7570924, lng: 19.3299935 },
    { lat: 48.7578445, lng: 19.3313047 },
    { lat: 48.7601426, lng: 19.331799 },
    { lat: 48.760379, lng: 19.3319115 },
    { lat: 48.7610451, lng: 19.3325052 },
    { lat: 48.7619679, lng: 19.3328535 },
    { lat: 48.762431, lng: 19.3341527 },
    { lat: 48.7640056, lng: 19.337904 },
    { lat: 48.7642413, lng: 19.3389015 },
    { lat: 48.7643685, lng: 19.3390399 },
    { lat: 48.7646727, lng: 19.339751 },
    { lat: 48.7648242, lng: 19.3398774 },
    { lat: 48.7651518, lng: 19.3406274 },
    { lat: 48.7652728, lng: 19.3415153 },
    { lat: 48.7654482, lng: 19.3419346 },
    { lat: 48.7657211, lng: 19.3420082 },
    { lat: 48.7661394, lng: 19.3419874 },
    { lat: 48.7663432, lng: 19.3419134 },
    { lat: 48.766685, lng: 19.3415865 },
    { lat: 48.7670226, lng: 19.3414098 },
    { lat: 48.7673414, lng: 19.341428 },
    { lat: 48.767383, lng: 19.3415482 },
    { lat: 48.7678291, lng: 19.3416115 },
    { lat: 48.7680032, lng: 19.3415787 },
    { lat: 48.7687151, lng: 19.341143 },
    { lat: 48.7688651, lng: 19.3409287 },
    { lat: 48.7689848, lng: 19.3406245 },
    { lat: 48.7691194, lng: 19.3405138 },
    { lat: 48.769326, lng: 19.3404992 },
    { lat: 48.7697741, lng: 19.3406795 },
    { lat: 48.7699584, lng: 19.3406216 },
    { lat: 48.7701165, lng: 19.3404136 },
    { lat: 48.7703584, lng: 19.3399089 },
    { lat: 48.7705533, lng: 19.3398348 },
    { lat: 48.7707818, lng: 19.3398782 },
    { lat: 48.7711175, lng: 19.3397989 },
    { lat: 48.771279, lng: 19.3396191 },
    { lat: 48.7713802, lng: 19.3392094 },
    { lat: 48.7716969, lng: 19.3388769 },
    { lat: 48.7724922, lng: 19.3374916 },
    { lat: 48.7725606, lng: 19.3377225 },
    { lat: 48.7720759, lng: 19.3385266 },
    { lat: 48.7717807, lng: 19.3394567 },
    { lat: 48.771434, lng: 19.3401972 },
    { lat: 48.7711647, lng: 19.3405594 },
    { lat: 48.7705465, lng: 19.3411383 },
    { lat: 48.7699872, lng: 19.3418951 },
    { lat: 48.7696628, lng: 19.3422058 },
    { lat: 48.7693911, lng: 19.3422964 },
    { lat: 48.7694341, lng: 19.3424301 },
    { lat: 48.769498, lng: 19.3426285 },
    { lat: 48.7699573, lng: 19.3433028 },
    { lat: 48.7699748, lng: 19.3433432 },
    { lat: 48.7702179, lng: 19.3439037 },
    { lat: 48.7703281, lng: 19.344512 },
    { lat: 48.7703009, lng: 19.3450845 },
    { lat: 48.7702017, lng: 19.3453513 },
    { lat: 48.770108, lng: 19.3456031 },
    { lat: 48.7698771, lng: 19.345958 },
    { lat: 48.7694467, lng: 19.3465092 },
    { lat: 48.7694181, lng: 19.3465391 },
    { lat: 48.7690149, lng: 19.3469609 },
    { lat: 48.7689517, lng: 19.347027 },
    { lat: 48.768186, lng: 19.3473492 },
    { lat: 48.7679146, lng: 19.347404 },
    { lat: 48.7675766, lng: 19.3477172 },
    { lat: 48.7672557, lng: 19.3478868 },
    { lat: 48.7668424, lng: 19.3470156 },
    { lat: 48.7655241, lng: 19.3483505 },
    { lat: 48.7655081, lng: 19.3487317 },
    { lat: 48.7657387, lng: 19.3496945 },
    { lat: 48.7660464, lng: 19.3515267 },
    { lat: 48.7663624, lng: 19.3521987 },
    { lat: 48.7665635, lng: 19.3524547 },
    { lat: 48.7668063, lng: 19.352764 },
    { lat: 48.7663158, lng: 19.3537368 },
    { lat: 48.7654743, lng: 19.3545983 },
    { lat: 48.7646479, lng: 19.3556199 },
    { lat: 48.7645627, lng: 19.3558421 },
    { lat: 48.7640164, lng: 19.3567086 },
    { lat: 48.7641826, lng: 19.3576202 },
    { lat: 48.763958, lng: 19.3586369 },
    { lat: 48.7639975, lng: 19.3591621 },
    { lat: 48.7639465, lng: 19.3600161 },
    { lat: 48.7637623, lng: 19.3610284 },
    { lat: 48.7634296, lng: 19.3621419 },
    { lat: 48.7630021, lng: 19.3632049 },
    { lat: 48.7631711, lng: 19.3638267 },
    { lat: 48.763478, lng: 19.3642147 },
    { lat: 48.7636188, lng: 19.3637801 },
    { lat: 48.7639169, lng: 19.363437 },
    { lat: 48.7645416, lng: 19.3629178 },
    { lat: 48.7653565, lng: 19.3618022 },
    { lat: 48.7658054, lng: 19.3615924 },
    { lat: 48.7663391, lng: 19.3612274 },
    { lat: 48.7664937, lng: 19.3610406 },
    { lat: 48.7666853, lng: 19.3609473 },
    { lat: 48.766765, lng: 19.360579 },
    { lat: 48.7669709, lng: 19.3602651 },
    { lat: 48.7672289, lng: 19.3600971 },
    { lat: 48.7677421, lng: 19.3600069 },
    { lat: 48.7682435, lng: 19.3592688 },
    { lat: 48.7685502, lng: 19.3586035 },
    { lat: 48.7694553, lng: 19.3577016 },
    { lat: 48.769757, lng: 19.3577639 },
    { lat: 48.7700665, lng: 19.3576611 },
    { lat: 48.7710998, lng: 19.3569653 },
    { lat: 48.772804, lng: 19.358396 },
    { lat: 48.7730315, lng: 19.3584628 },
    { lat: 48.7735361, lng: 19.3589094 },
    { lat: 48.7744214, lng: 19.3593009 },
    { lat: 48.7758038, lng: 19.356792 },
    { lat: 48.7762657, lng: 19.3562188 },
    { lat: 48.7768686, lng: 19.3550944 },
    { lat: 48.7772322, lng: 19.3539704 },
    { lat: 48.7772796, lng: 19.3529483 },
    { lat: 48.7773613, lng: 19.3526039 },
    { lat: 48.7784057, lng: 19.352001 },
    { lat: 48.7788722, lng: 19.3511893 },
    { lat: 48.7791432, lng: 19.3509126 },
    { lat: 48.7803718, lng: 19.3501328 },
    { lat: 48.7805283, lng: 19.3496326 },
    { lat: 48.7805947, lng: 19.34889 },
    { lat: 48.7798234, lng: 19.3481443 },
    { lat: 48.7798158, lng: 19.3472031 },
    { lat: 48.7805184, lng: 19.3464 },
    { lat: 48.7815561, lng: 19.3441888 },
    { lat: 48.783418, lng: 19.3405313 },
    { lat: 48.7831632, lng: 19.3402423 },
    { lat: 48.7824296, lng: 19.3385166 },
    { lat: 48.781953, lng: 19.3376861 },
    { lat: 48.7820709, lng: 19.3375325 },
    { lat: 48.7821126, lng: 19.3376051 },
    { lat: 48.7823356, lng: 19.3374822 },
    { lat: 48.7826253, lng: 19.3370122 },
    { lat: 48.782786, lng: 19.3370494 },
    { lat: 48.78321, lng: 19.336623 },
    { lat: 48.7832896, lng: 19.336406 },
    { lat: 48.7834364, lng: 19.3363132 },
    { lat: 48.7835036, lng: 19.3360925 },
    { lat: 48.7837225, lng: 19.3357642 },
    { lat: 48.7842635, lng: 19.335126 },
    { lat: 48.7844189, lng: 19.3348135 },
    { lat: 48.7848417, lng: 19.3342035 },
    { lat: 48.7849153, lng: 19.3340168 },
    { lat: 48.7853742, lng: 19.3336452 },
    { lat: 48.7857487, lng: 19.3335087 },
    { lat: 48.7858891, lng: 19.3329065 },
    { lat: 48.7861771, lng: 19.332704 },
    { lat: 48.7863251, lng: 19.3323108 },
    { lat: 48.7864276, lng: 19.3322846 },
    { lat: 48.7865558, lng: 19.3316834 },
    { lat: 48.7867567, lng: 19.3313588 },
    { lat: 48.7868986, lng: 19.3315975 },
    { lat: 48.7870955, lng: 19.3314466 },
    { lat: 48.7871139, lng: 19.3308842 },
    { lat: 48.7874027, lng: 19.3307245 },
    { lat: 48.7875791, lng: 19.3304264 },
    { lat: 48.7878027, lng: 19.3302709 },
    { lat: 48.7880931, lng: 19.330215 },
    { lat: 48.7882671, lng: 19.3300105 },
    { lat: 48.7885799, lng: 19.3299071 },
    { lat: 48.7888106, lng: 19.3299703 },
    { lat: 48.7891256, lng: 19.3298162 },
    { lat: 48.7893521, lng: 19.3294834 },
    { lat: 48.7897778, lng: 19.3293566 },
    { lat: 48.7902991, lng: 19.3289452 },
    { lat: 48.7904714, lng: 19.3287249 },
    { lat: 48.7908945, lng: 19.3285926 },
    { lat: 48.7911002, lng: 19.3283 },
    { lat: 48.7915908, lng: 19.3280939 },
    { lat: 48.7919767, lng: 19.3282666 },
    { lat: 48.79249, lng: 19.3279948 },
    { lat: 48.7934093, lng: 19.3268959 },
    { lat: 48.7937976, lng: 19.3269561 },
    { lat: 48.7939029, lng: 19.3268699 },
    { lat: 48.7938327, lng: 19.3268647 },
    { lat: 48.7933308, lng: 19.3268333 },
    { lat: 48.793225, lng: 19.3267143 },
    { lat: 48.7930917, lng: 19.3267821 },
    { lat: 48.7927715, lng: 19.3271613 },
    { lat: 48.7923438, lng: 19.3275289 },
    { lat: 48.7918927, lng: 19.327518 },
    { lat: 48.7916561, lng: 19.3275949 },
    { lat: 48.7914191, lng: 19.3268272 },
    { lat: 48.7913804, lng: 19.3263228 },
    { lat: 48.7917375, lng: 19.3246603 },
    { lat: 48.7916945, lng: 19.3241381 },
    { lat: 48.7919202, lng: 19.3240045 },
    { lat: 48.7924951, lng: 19.3232708 },
    { lat: 48.7927754, lng: 19.3205669 },
    { lat: 48.7940495, lng: 19.3188753 },
    { lat: 48.7937576, lng: 19.3180693 },
    { lat: 48.796933, lng: 19.3148873 },
    { lat: 48.7971721, lng: 19.3130036 },
    { lat: 48.7989959, lng: 19.3107629 },
    { lat: 48.7982235, lng: 19.3107548 },
    { lat: 48.7973389, lng: 19.3095066 },
    { lat: 48.7965592, lng: 19.3093227 },
    { lat: 48.7937063, lng: 19.3102402 },
    { lat: 48.7926311, lng: 19.3104511 },
    { lat: 48.7920891, lng: 19.3101458 },
    { lat: 48.7917859, lng: 19.305429 },
    { lat: 48.792474, lng: 19.3021598 },
    { lat: 48.7929697, lng: 19.2990927 },
    { lat: 48.7941185, lng: 19.2956634 },
    { lat: 48.795798, lng: 19.2955683 },
    { lat: 48.8017613, lng: 19.2954978 },
    { lat: 48.8037615, lng: 19.3011948 },
    { lat: 48.8043036, lng: 19.3024912 },
    { lat: 48.8047063, lng: 19.3039091 },
    { lat: 48.8053514, lng: 19.3034949 },
    { lat: 48.8053479, lng: 19.3027594 },
    { lat: 48.8056034, lng: 19.3026367 },
    { lat: 48.8061296, lng: 19.3026552 },
    { lat: 48.8062713, lng: 19.3023676 },
    { lat: 48.8062291, lng: 19.3016728 },
    { lat: 48.8059036, lng: 19.3019602 },
    { lat: 48.8057136, lng: 19.3014025 },
    { lat: 48.8057816, lng: 19.3008254 },
    { lat: 48.8059645, lng: 19.3000763 },
    { lat: 48.805801, lng: 19.2986822 },
    { lat: 48.8057406, lng: 19.2974946 },
    { lat: 48.8059133, lng: 19.2974742 },
    { lat: 48.8059108, lng: 19.2974135 },
    { lat: 48.8058541, lng: 19.2960265 },
    { lat: 48.8057817, lng: 19.2954995 },
    { lat: 48.8057858, lng: 19.2940817 },
    { lat: 48.8055573, lng: 19.293334 },
    { lat: 48.8049096, lng: 19.2931287 },
    { lat: 48.8045025, lng: 19.2928198 },
    { lat: 48.8043021, lng: 19.292467 },
    { lat: 48.803942, lng: 19.2924343 },
    { lat: 48.8034453, lng: 19.2918284 },
    { lat: 48.8031816, lng: 19.2921112 },
    { lat: 48.7996458, lng: 19.2916651 },
    { lat: 48.7975269, lng: 19.2917775 },
    { lat: 48.7967521, lng: 19.2916752 },
    { lat: 48.7957802, lng: 19.2915473 },
    { lat: 48.7955463, lng: 19.2918661 },
    { lat: 48.7952449, lng: 19.2915263 },
    { lat: 48.795024, lng: 19.2919774 },
    { lat: 48.7946941, lng: 19.291797 },
    { lat: 48.7913956, lng: 19.2922071 },
    { lat: 48.7912938, lng: 19.2921726 },
    { lat: 48.7911843, lng: 19.2924759 },
    { lat: 48.7904384, lng: 19.2924848 },
    { lat: 48.7900776, lng: 19.2926684 },
    { lat: 48.7898062, lng: 19.292344 },
    { lat: 48.7893883, lng: 19.2925628 },
    { lat: 48.7891299, lng: 19.2925082 },
    { lat: 48.788818, lng: 19.2920678 },
    { lat: 48.7883867, lng: 19.2922134 },
    { lat: 48.7880467, lng: 19.2920155 },
    { lat: 48.787525, lng: 19.2922771 },
    { lat: 48.7873175, lng: 19.2925798 },
    { lat: 48.7868503, lng: 19.2926139 },
    { lat: 48.786519, lng: 19.2927328 },
    { lat: 48.7861836, lng: 19.2930001 },
    { lat: 48.7857877, lng: 19.2938652 },
    { lat: 48.7853965, lng: 19.2937712 },
    { lat: 48.7853084, lng: 19.2941894 },
    { lat: 48.784817, lng: 19.2943538 },
    { lat: 48.7846312, lng: 19.2946411 },
    { lat: 48.7841506, lng: 19.2949579 },
    { lat: 48.7839846, lng: 19.2955162 },
    { lat: 48.7840812, lng: 19.2961479 },
    { lat: 48.7830408, lng: 19.2967358 },
    { lat: 48.7828896, lng: 19.2971534 },
    { lat: 48.7820728, lng: 19.2972121 },
    { lat: 48.782233, lng: 19.2977997 },
    { lat: 48.7818119, lng: 19.2986592 },
    { lat: 48.7812682, lng: 19.2990737 },
    { lat: 48.7808094, lng: 19.2999691 },
    { lat: 48.7808025, lng: 19.3001518 },
    { lat: 48.780184, lng: 19.3006073 },
    { lat: 48.7801187, lng: 19.3009847 },
    { lat: 48.7800453, lng: 19.3011482 },
    { lat: 48.7796625, lng: 19.3014596 },
    { lat: 48.7792405, lng: 19.3013325 },
    { lat: 48.7791431, lng: 19.3016326 },
    { lat: 48.7788764, lng: 19.3017107 },
    { lat: 48.7782728, lng: 19.3020724 },
    { lat: 48.7781306, lng: 19.3019993 },
    { lat: 48.7778834, lng: 19.3020639 },
    { lat: 48.7776855, lng: 19.3021681 },
    { lat: 48.7776267, lng: 19.3023514 },
    { lat: 48.7775903, lng: 19.3020395 },
    { lat: 48.7771943, lng: 19.3021701 },
    { lat: 48.7765442, lng: 19.3027901 },
    { lat: 48.7763678, lng: 19.3027562 },
    { lat: 48.7762978, lng: 19.3029425 },
    { lat: 48.7760928, lng: 19.3029921 },
    { lat: 48.7759658, lng: 19.3028549 },
    { lat: 48.7757445, lng: 19.3028487 },
    { lat: 48.7754766, lng: 19.3031389 },
    { lat: 48.7746733, lng: 19.3030042 },
    { lat: 48.7746563, lng: 19.3037315 },
    { lat: 48.7747268, lng: 19.3042615 },
    { lat: 48.7751661, lng: 19.3048938 },
    { lat: 48.7767885, lng: 19.3053989 },
    { lat: 48.7772444, lng: 19.3054104 },
    { lat: 48.7776721, lng: 19.3053265 },
    { lat: 48.778445, lng: 19.3057247 },
    { lat: 48.7786438, lng: 19.3058959 },
    { lat: 48.7789641, lng: 19.3063509 },
    { lat: 48.7789579, lng: 19.3067843 },
    { lat: 48.7771504, lng: 19.3131453 },
    { lat: 48.777055, lng: 19.3130546 },
    { lat: 48.7764872, lng: 19.3132474 },
    { lat: 48.7754241, lng: 19.3138109 },
    { lat: 48.7728339, lng: 19.3150136 },
    { lat: 48.7726094, lng: 19.3152697 },
    { lat: 48.7717754, lng: 19.3155058 },
    { lat: 48.7713595, lng: 19.3155312 },
    { lat: 48.7689512, lng: 19.3149485 },
    { lat: 48.7672684, lng: 19.3153684 },
    { lat: 48.7669858, lng: 19.3159439 },
    { lat: 48.7661527, lng: 19.3176406 },
    { lat: 48.7643415, lng: 19.319203 },
    { lat: 48.7642223, lng: 19.3192087 },
    { lat: 48.7632239, lng: 19.3192567 },
    { lat: 48.7629198, lng: 19.3193835 },
    { lat: 48.7611088, lng: 19.3212758 },
    { lat: 48.7606913, lng: 19.3218122 },
    { lat: 48.7603729, lng: 19.3224356 },
    { lat: 48.7601352, lng: 19.3226276 },
    { lat: 48.7596604, lng: 19.323591 },
    { lat: 48.758885, lng: 19.324086 },
    { lat: 48.758202, lng: 19.3253221 },
    { lat: 48.7578296, lng: 19.3261857 },
    { lat: 48.7570482, lng: 19.3274085 },
  ],
  Moštenica: [
    { lat: 48.7967521, lng: 19.2916752 },
    { lat: 48.7975269, lng: 19.2917775 },
    { lat: 48.7996458, lng: 19.2916651 },
    { lat: 48.8031816, lng: 19.2921112 },
    { lat: 48.8034453, lng: 19.2918284 },
    { lat: 48.803942, lng: 19.2924343 },
    { lat: 48.8043021, lng: 19.292467 },
    { lat: 48.8045025, lng: 19.2928198 },
    { lat: 48.8049096, lng: 19.2931287 },
    { lat: 48.8055573, lng: 19.293334 },
    { lat: 48.8057858, lng: 19.2940817 },
    { lat: 48.8057817, lng: 19.2954995 },
    { lat: 48.8058541, lng: 19.2960265 },
    { lat: 48.8059108, lng: 19.2974135 },
    { lat: 48.8059133, lng: 19.2974742 },
    { lat: 48.8057406, lng: 19.2974946 },
    { lat: 48.805801, lng: 19.2986822 },
    { lat: 48.8059645, lng: 19.3000763 },
    { lat: 48.8057816, lng: 19.3008254 },
    { lat: 48.8057136, lng: 19.3014025 },
    { lat: 48.8059036, lng: 19.3019602 },
    { lat: 48.8062291, lng: 19.3016728 },
    { lat: 48.8062713, lng: 19.3023676 },
    { lat: 48.8063266, lng: 19.302581 },
    { lat: 48.8065626, lng: 19.3028502 },
    { lat: 48.8068679, lng: 19.3029419 },
    { lat: 48.8068856, lng: 19.3028634 },
    { lat: 48.8069697, lng: 19.3028521 },
    { lat: 48.8068337, lng: 19.3025326 },
    { lat: 48.8069162, lng: 19.3024907 },
    { lat: 48.8070572, lng: 19.3019504 },
    { lat: 48.8069946, lng: 19.3017988 },
    { lat: 48.8070264, lng: 19.3013752 },
    { lat: 48.807152, lng: 19.3008919 },
    { lat: 48.8071213, lng: 19.3008163 },
    { lat: 48.807276, lng: 19.300559 },
    { lat: 48.8074661, lng: 19.3004617 },
    { lat: 48.807525, lng: 19.3001813 },
    { lat: 48.8074028, lng: 19.2998672 },
    { lat: 48.8075613, lng: 19.2996464 },
    { lat: 48.8082551, lng: 19.3001074 },
    { lat: 48.8084352, lng: 19.3001502 },
    { lat: 48.8098658, lng: 19.3001808 },
    { lat: 48.8104118, lng: 19.300259 },
    { lat: 48.8105219, lng: 19.3005616 },
    { lat: 48.8105612, lng: 19.3011717 },
    { lat: 48.8107978, lng: 19.3019214 },
    { lat: 48.8112143, lng: 19.3024511 },
    { lat: 48.8114675, lng: 19.3028675 },
    { lat: 48.8122897, lng: 19.3036103 },
    { lat: 48.8126699, lng: 19.303549 },
    { lat: 48.8127901, lng: 19.3034007 },
    { lat: 48.8130536, lng: 19.3035374 },
    { lat: 48.8135465, lng: 19.303497 },
    { lat: 48.8145115, lng: 19.3032647 },
    { lat: 48.8149686, lng: 19.3032514 },
    { lat: 48.8165206, lng: 19.3038661 },
    { lat: 48.8168425, lng: 19.3038362 },
    { lat: 48.8171861, lng: 19.303737 },
    { lat: 48.8177842, lng: 19.3034462 },
    { lat: 48.8180553, lng: 19.3032484 },
    { lat: 48.8182522, lng: 19.3031932 },
    { lat: 48.8183208, lng: 19.3030952 },
    { lat: 48.8189418, lng: 19.3030239 },
    { lat: 48.8190912, lng: 19.3028484 },
    { lat: 48.8197479, lng: 19.3024159 },
    { lat: 48.8199874, lng: 19.3024498 },
    { lat: 48.8203296, lng: 19.3023454 },
    { lat: 48.8206761, lng: 19.3024065 },
    { lat: 48.8211002, lng: 19.3022721 },
    { lat: 48.8214664, lng: 19.3022895 },
    { lat: 48.8221482, lng: 19.3017445 },
    { lat: 48.8224268, lng: 19.3013015 },
    { lat: 48.8225298, lng: 19.3014065 },
    { lat: 48.8228332, lng: 19.3009161 },
    { lat: 48.8230996, lng: 19.3006626 },
    { lat: 48.8235179, lng: 19.2999622 },
    { lat: 48.8245107, lng: 19.2990614 },
    { lat: 48.8251752, lng: 19.2986988 },
    { lat: 48.8263908, lng: 19.2982626 },
    { lat: 48.827852, lng: 19.29799 },
    { lat: 48.828258, lng: 19.2980388 },
    { lat: 48.8289495, lng: 19.2976049 },
    { lat: 48.82956, lng: 19.2967988 },
    { lat: 48.8302689, lng: 19.2964213 },
    { lat: 48.8313399, lng: 19.2967545 },
    { lat: 48.8318749, lng: 19.2967019 },
    { lat: 48.8329085, lng: 19.2962297 },
    { lat: 48.8329495, lng: 19.2961672 },
    { lat: 48.8335944, lng: 19.2951845 },
    { lat: 48.8340233, lng: 19.2944191 },
    { lat: 48.8342046, lng: 19.2939924 },
    { lat: 48.8342687, lng: 19.2936827 },
    { lat: 48.8344493, lng: 19.2933813 },
    { lat: 48.8346372, lng: 19.2928551 },
    { lat: 48.8347883, lng: 19.2925774 },
    { lat: 48.8348144, lng: 19.292294 },
    { lat: 48.8350072, lng: 19.2921079 },
    { lat: 48.8352201, lng: 19.2914942 },
    { lat: 48.8354338, lng: 19.2913208 },
    { lat: 48.8355506, lng: 19.2909581 },
    { lat: 48.836388, lng: 19.2911237 },
    { lat: 48.836567, lng: 19.2914228 },
    { lat: 48.8372053, lng: 19.2917366 },
    { lat: 48.8378434, lng: 19.2917156 },
    { lat: 48.8383966, lng: 19.2920931 },
    { lat: 48.8385972, lng: 19.2923012 },
    { lat: 48.8394816, lng: 19.2921768 },
    { lat: 48.8403716, lng: 19.2922165 },
    { lat: 48.8405357, lng: 19.2923241 },
    { lat: 48.8413189, lng: 19.2933126 },
    { lat: 48.8414446, lng: 19.2936995 },
    { lat: 48.8416605, lng: 19.2941131 },
    { lat: 48.8416976, lng: 19.2943949 },
    { lat: 48.8417837, lng: 19.2946152 },
    { lat: 48.8424815, lng: 19.2955619 },
    { lat: 48.8428272, lng: 19.2958367 },
    { lat: 48.8430545, lng: 19.2954035 },
    { lat: 48.8432349, lng: 19.2948402 },
    { lat: 48.8432472, lng: 19.2944151 },
    { lat: 48.8433916, lng: 19.2940422 },
    { lat: 48.8436408, lng: 19.2937121 },
    { lat: 48.8438248, lng: 19.2935985 },
    { lat: 48.8443312, lng: 19.2935168 },
    { lat: 48.8444511, lng: 19.2934063 },
    { lat: 48.8446564, lng: 19.2929625 },
    { lat: 48.8447807, lng: 19.2928505 },
    { lat: 48.8449333, lng: 19.2927869 },
    { lat: 48.8452418, lng: 19.2928084 },
    { lat: 48.8470328, lng: 19.2936774 },
    { lat: 48.8477877, lng: 19.2937924 },
    { lat: 48.8483488, lng: 19.2936482 },
    { lat: 48.8486542, lng: 19.2935051 },
    { lat: 48.8485856, lng: 19.2933201 },
    { lat: 48.8486155, lng: 19.2930466 },
    { lat: 48.8487423, lng: 19.2929429 },
    { lat: 48.8504001, lng: 19.2907277 },
    { lat: 48.8508099, lng: 19.2907618 },
    { lat: 48.8509479, lng: 19.2906807 },
    { lat: 48.8515075, lng: 19.2907363 },
    { lat: 48.852559, lng: 19.2916551 },
    { lat: 48.8530535, lng: 19.2928734 },
    { lat: 48.8531383, lng: 19.2923147 },
    { lat: 48.8535715, lng: 19.2917095 },
    { lat: 48.8536648, lng: 19.2917297 },
    { lat: 48.8535974, lng: 19.2923681 },
    { lat: 48.8539996, lng: 19.2920133 },
    { lat: 48.8541587, lng: 19.2921586 },
    { lat: 48.8542821, lng: 19.2926844 },
    { lat: 48.8545287, lng: 19.2926355 },
    { lat: 48.8546373, lng: 19.2924074 },
    { lat: 48.8544287, lng: 19.2918885 },
    { lat: 48.8547504, lng: 19.2918062 },
    { lat: 48.8550343, lng: 19.2924372 },
    { lat: 48.8552112, lng: 19.2924815 },
    { lat: 48.8552168, lng: 19.2927421 },
    { lat: 48.8549834, lng: 19.2934911 },
    { lat: 48.8546885, lng: 19.2935965 },
    { lat: 48.8547971, lng: 19.2938369 },
    { lat: 48.8549074, lng: 19.2937894 },
    { lat: 48.854963, lng: 19.2938706 },
    { lat: 48.8549107, lng: 19.2944944 },
    { lat: 48.8567892, lng: 19.2958766 },
    { lat: 48.8571892, lng: 19.2958295 },
    { lat: 48.8582515, lng: 19.295982 },
    { lat: 48.8579537, lng: 19.2946786 },
    { lat: 48.8577871, lng: 19.2922135 },
    { lat: 48.85795, lng: 19.2912578 },
    { lat: 48.8584101, lng: 19.2906274 },
    { lat: 48.8584426, lng: 19.2899387 },
    { lat: 48.8581195, lng: 19.2882515 },
    { lat: 48.858296, lng: 19.2869457 },
    { lat: 48.8592941, lng: 19.2869114 },
    { lat: 48.8596776, lng: 19.2859107 },
    { lat: 48.8594201, lng: 19.2840841 },
    { lat: 48.860158, lng: 19.2840559 },
    { lat: 48.8601222, lng: 19.2836152 },
    { lat: 48.8601378, lng: 19.2805992 },
    { lat: 48.8596807, lng: 19.2782129 },
    { lat: 48.8593953, lng: 19.2779011 },
    { lat: 48.8587701, lng: 19.2768267 },
    { lat: 48.8582594, lng: 19.2763099 },
    { lat: 48.8579031, lng: 19.2755344 },
    { lat: 48.8577975, lng: 19.2750457 },
    { lat: 48.8580203, lng: 19.2746628 },
    { lat: 48.8580917, lng: 19.2736867 },
    { lat: 48.8579975, lng: 19.2730621 },
    { lat: 48.8578, lng: 19.2723029 },
    { lat: 48.8569714, lng: 19.2722038 },
    { lat: 48.8558965, lng: 19.2693021 },
    { lat: 48.8558685, lng: 19.2686018 },
    { lat: 48.8557781, lng: 19.2686899 },
    { lat: 48.8556128, lng: 19.2685152 },
    { lat: 48.8554137, lng: 19.2685679 },
    { lat: 48.8553193, lng: 19.2684573 },
    { lat: 48.8542477, lng: 19.2690019 },
    { lat: 48.854102, lng: 19.2684825 },
    { lat: 48.8554491, lng: 19.2677071 },
    { lat: 48.8552309, lng: 19.2670157 },
    { lat: 48.8553444, lng: 19.2669151 },
    { lat: 48.8551608, lng: 19.2663781 },
    { lat: 48.8548883, lng: 19.2658201 },
    { lat: 48.8543652, lng: 19.2657705 },
    { lat: 48.8541485, lng: 19.2652042 },
    { lat: 48.8538697, lng: 19.2646813 },
    { lat: 48.853302, lng: 19.2643087 },
    { lat: 48.8528287, lng: 19.2637987 },
    { lat: 48.8513678, lng: 19.2632328 },
    { lat: 48.8511179, lng: 19.2620678 },
    { lat: 48.8507349, lng: 19.2608292 },
    { lat: 48.8503372, lng: 19.2605067 },
    { lat: 48.8500316, lng: 19.26034 },
    { lat: 48.8444477, lng: 19.2631916 },
    { lat: 48.8443297, lng: 19.2633407 },
    { lat: 48.8389198, lng: 19.2614253 },
    { lat: 48.8387728, lng: 19.2612967 },
    { lat: 48.8388228, lng: 19.2609315 },
    { lat: 48.8391431, lng: 19.2603287 },
    { lat: 48.8392177, lng: 19.2597254 },
    { lat: 48.8389736, lng: 19.2590306 },
    { lat: 48.8392475, lng: 19.2569945 },
    { lat: 48.8392957, lng: 19.2548855 },
    { lat: 48.8390695, lng: 19.2542597 },
    { lat: 48.838768, lng: 19.2537821 },
    { lat: 48.8385953, lng: 19.2531678 },
    { lat: 48.8384301, lng: 19.2521288 },
    { lat: 48.8376935, lng: 19.2504788 },
    { lat: 48.8373603, lng: 19.2499453 },
    { lat: 48.8369713, lng: 19.2491522 },
    { lat: 48.8367769, lng: 19.2482506 },
    { lat: 48.8357717, lng: 19.2479035 },
    { lat: 48.8350839, lng: 19.2483206 },
    { lat: 48.8346773, lng: 19.248186 },
    { lat: 48.8343047, lng: 19.2478001 },
    { lat: 48.8340406, lng: 19.247839 },
    { lat: 48.83365, lng: 19.2480368 },
    { lat: 48.8329584, lng: 19.2482114 },
    { lat: 48.832863, lng: 19.2483357 },
    { lat: 48.832424, lng: 19.2485571 },
    { lat: 48.8322527, lng: 19.2483013 },
    { lat: 48.8321367, lng: 19.2482565 },
    { lat: 48.8320099, lng: 19.2479625 },
    { lat: 48.8317738, lng: 19.2478373 },
    { lat: 48.8315758, lng: 19.247519 },
    { lat: 48.8314777, lng: 19.2481241 },
    { lat: 48.8311646, lng: 19.2485698 },
    { lat: 48.8311097, lng: 19.2488814 },
    { lat: 48.8309966, lng: 19.2491816 },
    { lat: 48.8306637, lng: 19.2498692 },
    { lat: 48.8304403, lng: 19.2497046 },
    { lat: 48.8301488, lng: 19.2502023 },
    { lat: 48.8291654, lng: 19.2501448 },
    { lat: 48.8287737, lng: 19.2499223 },
    { lat: 48.8285985, lng: 19.2499836 },
    { lat: 48.8282546, lng: 19.2504234 },
    { lat: 48.8281885, lng: 19.2505519 },
    { lat: 48.8283014, lng: 19.2509033 },
    { lat: 48.8280434, lng: 19.2512105 },
    { lat: 48.8281754, lng: 19.2512596 },
    { lat: 48.8279753, lng: 19.2517629 },
    { lat: 48.828072, lng: 19.2521525 },
    { lat: 48.8281893, lng: 19.2521069 },
    { lat: 48.8282712, lng: 19.2526134 },
    { lat: 48.828056, lng: 19.2529656 },
    { lat: 48.8277829, lng: 19.253102 },
    { lat: 48.8274882, lng: 19.253467 },
    { lat: 48.8275761, lng: 19.2539573 },
    { lat: 48.8272008, lng: 19.2539083 },
    { lat: 48.8269807, lng: 19.2537366 },
    { lat: 48.8268496, lng: 19.2530754 },
    { lat: 48.8269136, lng: 19.2527667 },
    { lat: 48.8267505, lng: 19.2526567 },
    { lat: 48.8270217, lng: 19.2504964 },
    { lat: 48.827104, lng: 19.249447 },
    { lat: 48.8269931, lng: 19.2493459 },
    { lat: 48.8267202, lng: 19.2495063 },
    { lat: 48.8266385, lng: 19.2493297 },
    { lat: 48.8262751, lng: 19.2489943 },
    { lat: 48.826039, lng: 19.2492397 },
    { lat: 48.825982, lng: 19.2494145 },
    { lat: 48.8259982, lng: 19.2496535 },
    { lat: 48.8258574, lng: 19.2501037 },
    { lat: 48.8257859, lng: 19.2501388 },
    { lat: 48.8255303, lng: 19.2500737 },
    { lat: 48.8257346, lng: 19.2491686 },
    { lat: 48.8256534, lng: 19.249052 },
    { lat: 48.8256059, lng: 19.2488609 },
    { lat: 48.8255556, lng: 19.24887 },
    { lat: 48.8255539, lng: 19.2490891 },
    { lat: 48.8254673, lng: 19.2493073 },
    { lat: 48.8252973, lng: 19.2501939 },
    { lat: 48.825258, lng: 19.2507842 },
    { lat: 48.8252505, lng: 19.2514718 },
    { lat: 48.8254701, lng: 19.2525879 },
    { lat: 48.8252917, lng: 19.2532813 },
    { lat: 48.8249663, lng: 19.2553695 },
    { lat: 48.8248195, lng: 19.2555052 },
    { lat: 48.8246157, lng: 19.2560925 },
    { lat: 48.8245967, lng: 19.2568959 },
    { lat: 48.8245245, lng: 19.2572211 },
    { lat: 48.8243405, lng: 19.2575394 },
    { lat: 48.8240848, lng: 19.2594181 },
    { lat: 48.8239754, lng: 19.2598144 },
    { lat: 48.8239237, lng: 19.2602125 },
    { lat: 48.8235, lng: 19.2608019 },
    { lat: 48.8233351, lng: 19.2611192 },
    { lat: 48.8233754, lng: 19.2613479 },
    { lat: 48.8232074, lng: 19.262284 },
    { lat: 48.8231931, lng: 19.2626697 },
    { lat: 48.823045, lng: 19.2629629 },
    { lat: 48.8231002, lng: 19.2634537 },
    { lat: 48.8229746, lng: 19.2646608 },
    { lat: 48.8229716, lng: 19.2653944 },
    { lat: 48.822801, lng: 19.2662524 },
    { lat: 48.8225978, lng: 19.2665302 },
    { lat: 48.8225504, lng: 19.2669257 },
    { lat: 48.8225817, lng: 19.2672967 },
    { lat: 48.8225566, lng: 19.2677817 },
    { lat: 48.8224023, lng: 19.2681582 },
    { lat: 48.8224356, lng: 19.2691096 },
    { lat: 48.8221879, lng: 19.2697693 },
    { lat: 48.822059, lng: 19.2702581 },
    { lat: 48.8220063, lng: 19.2707529 },
    { lat: 48.8217895, lng: 19.2710476 },
    { lat: 48.8216395, lng: 19.2715888 },
    { lat: 48.8216867, lng: 19.2717939 },
    { lat: 48.821589, lng: 19.2722732 },
    { lat: 48.821502, lng: 19.2732049 },
    { lat: 48.8213921, lng: 19.2738269 },
    { lat: 48.8215915, lng: 19.2743404 },
    { lat: 48.8215901, lng: 19.2750885 },
    { lat: 48.821381, lng: 19.2760847 },
    { lat: 48.8211888, lng: 19.2767304 },
    { lat: 48.8206028, lng: 19.2771776 },
    { lat: 48.8202753, lng: 19.2783854 },
    { lat: 48.8200027, lng: 19.2788575 },
    { lat: 48.8198373, lng: 19.2789344 },
    { lat: 48.8197092, lng: 19.2793201 },
    { lat: 48.8192031, lng: 19.2800936 },
    { lat: 48.8190334, lng: 19.2796591 },
    { lat: 48.818588, lng: 19.2802407 },
    { lat: 48.8184579, lng: 19.2803505 },
    { lat: 48.8181473, lng: 19.280453 },
    { lat: 48.8177691, lng: 19.2810271 },
    { lat: 48.8176484, lng: 19.2807671 },
    { lat: 48.8175282, lng: 19.2808895 },
    { lat: 48.8175289, lng: 19.2809988 },
    { lat: 48.81717, lng: 19.2811338 },
    { lat: 48.8166966, lng: 19.2819777 },
    { lat: 48.8161509, lng: 19.2822562 },
    { lat: 48.8160517, lng: 19.2820694 },
    { lat: 48.8156591, lng: 19.2823378 },
    { lat: 48.8155498, lng: 19.2822268 },
    { lat: 48.8155732, lng: 19.2824292 },
    { lat: 48.8153343, lng: 19.2825238 },
    { lat: 48.8153536, lng: 19.2827398 },
    { lat: 48.8152408, lng: 19.2826431 },
    { lat: 48.8151813, lng: 19.282718 },
    { lat: 48.8151243, lng: 19.2826559 },
    { lat: 48.8151607, lng: 19.2824745 },
    { lat: 48.8148965, lng: 19.2822304 },
    { lat: 48.8149507, lng: 19.282047 },
    { lat: 48.8147277, lng: 19.2819077 },
    { lat: 48.8147493, lng: 19.2817959 },
    { lat: 48.814194, lng: 19.2812154 },
    { lat: 48.8140434, lng: 19.2811773 },
    { lat: 48.8138745, lng: 19.2816057 },
    { lat: 48.8140092, lng: 19.2821573 },
    { lat: 48.8140589, lng: 19.2828142 },
    { lat: 48.8142041, lng: 19.282839 },
    { lat: 48.8143256, lng: 19.283017 },
    { lat: 48.8142898, lng: 19.2832119 },
    { lat: 48.8140964, lng: 19.2834105 },
    { lat: 48.8140646, lng: 19.2836873 },
    { lat: 48.8139577, lng: 19.2838081 },
    { lat: 48.8139779, lng: 19.2842293 },
    { lat: 48.8138486, lng: 19.2844481 },
    { lat: 48.8135468, lng: 19.2844541 },
    { lat: 48.8136498, lng: 19.2849069 },
    { lat: 48.8132541, lng: 19.2853542 },
    { lat: 48.8133301, lng: 19.2855064 },
    { lat: 48.8130208, lng: 19.2857542 },
    { lat: 48.8129729, lng: 19.2856715 },
    { lat: 48.8125573, lng: 19.2859593 },
    { lat: 48.8121833, lng: 19.2863416 },
    { lat: 48.8120459, lng: 19.2861103 },
    { lat: 48.8118057, lng: 19.28648 },
    { lat: 48.8116483, lng: 19.2865606 },
    { lat: 48.8114884, lng: 19.286753 },
    { lat: 48.8115431, lng: 19.2869036 },
    { lat: 48.811685, lng: 19.2870407 },
    { lat: 48.8107801, lng: 19.2881536 },
    { lat: 48.8105255, lng: 19.2884151 },
    { lat: 48.8103755, lng: 19.2883894 },
    { lat: 48.8099128, lng: 19.2890595 },
    { lat: 48.809662, lng: 19.289282 },
    { lat: 48.8093257, lng: 19.2893668 },
    { lat: 48.8087065, lng: 19.2899204 },
    { lat: 48.8085565, lng: 19.2899884 },
    { lat: 48.8084096, lng: 19.2901704 },
    { lat: 48.8081949, lng: 19.2902984 },
    { lat: 48.80802, lng: 19.2902718 },
    { lat: 48.8080065, lng: 19.2901328 },
    { lat: 48.8077631, lng: 19.2900175 },
    { lat: 48.8076736, lng: 19.2897452 },
    { lat: 48.8073459, lng: 19.2899647 },
    { lat: 48.8073607, lng: 19.2901568 },
    { lat: 48.8070411, lng: 19.2902388 },
    { lat: 48.8070028, lng: 19.29045 },
    { lat: 48.8067032, lng: 19.2903152 },
    { lat: 48.8066471, lng: 19.2904347 },
    { lat: 48.8073059, lng: 19.2908026 },
    { lat: 48.8070964, lng: 19.2908763 },
    { lat: 48.8066706, lng: 19.2907128 },
    { lat: 48.8064272, lng: 19.2905279 },
    { lat: 48.8058325, lng: 19.2897107 },
    { lat: 48.8058046, lng: 19.2897638 },
    { lat: 48.8055112, lng: 19.2897831 },
    { lat: 48.8055686, lng: 19.2895909 },
    { lat: 48.8048419, lng: 19.2896893 },
    { lat: 48.8043556, lng: 19.2895178 },
    { lat: 48.8043121, lng: 19.2897138 },
    { lat: 48.8037106, lng: 19.2896058 },
    { lat: 48.8033895, lng: 19.2897018 },
    { lat: 48.8033787, lng: 19.2898077 },
    { lat: 48.8031739, lng: 19.2898613 },
    { lat: 48.8026747, lng: 19.2898124 },
    { lat: 48.802021, lng: 19.2899568 },
    { lat: 48.8020239, lng: 19.2900387 },
    { lat: 48.800698, lng: 19.2899158 },
    { lat: 48.7998434, lng: 19.2900028 },
    { lat: 48.7993632, lng: 19.2901195 },
    { lat: 48.7991011, lng: 19.290268 },
    { lat: 48.7986657, lng: 19.2907298 },
    { lat: 48.798501, lng: 19.290823 },
    { lat: 48.798837, lng: 19.2899331 },
    { lat: 48.7985025, lng: 19.2900996 },
    { lat: 48.7983082, lng: 19.2902778 },
    { lat: 48.7978842, lng: 19.2903651 },
    { lat: 48.7976579, lng: 19.2910993 },
    { lat: 48.7967736, lng: 19.2911337 },
    { lat: 48.7967557, lng: 19.2915636 },
    { lat: 48.7967521, lng: 19.2916752 },
  ],
  HornáMičiná: [
    { lat: 48.7063823, lng: 19.1761014 },
    { lat: 48.7058883, lng: 19.1757484 },
    { lat: 48.7053897, lng: 19.1759704 },
    { lat: 48.7051464, lng: 19.1758406 },
    { lat: 48.7047758, lng: 19.1754745 },
    { lat: 48.7041218, lng: 19.1741843 },
    { lat: 48.7040004, lng: 19.1743728 },
    { lat: 48.7028369, lng: 19.1749922 },
    { lat: 48.7019722, lng: 19.1752884 },
    { lat: 48.7017704, lng: 19.1751525 },
    { lat: 48.7015223, lng: 19.174828 },
    { lat: 48.7007372, lng: 19.1734595 },
    { lat: 48.7000202, lng: 19.1725152 },
    { lat: 48.6997234, lng: 19.1723246 },
    { lat: 48.699488, lng: 19.1723752 },
    { lat: 48.6990907, lng: 19.172895 },
    { lat: 48.6985532, lng: 19.1726001 },
    { lat: 48.6984626, lng: 19.172086 },
    { lat: 48.6978167, lng: 19.1707987 },
    { lat: 48.6974803, lng: 19.1706483 },
    { lat: 48.6974941, lng: 19.1697119 },
    { lat: 48.697311, lng: 19.1694203 },
    { lat: 48.6971285, lng: 19.1691299 },
    { lat: 48.6970941, lng: 19.1690751 },
    { lat: 48.6967679, lng: 19.1689971 },
    { lat: 48.6964374, lng: 19.169128 },
    { lat: 48.6963633, lng: 19.1691573 },
    { lat: 48.6962626, lng: 19.1689814 },
    { lat: 48.6962104, lng: 19.1688903 },
    { lat: 48.6957355, lng: 19.1685621 },
    { lat: 48.6956886, lng: 19.1683182 },
    { lat: 48.6947155, lng: 19.1681514 },
    { lat: 48.6946214, lng: 19.1680734 },
    { lat: 48.6945816, lng: 19.1684561 },
    { lat: 48.6939264, lng: 19.1680628 },
    { lat: 48.6934416, lng: 19.1675076 },
    { lat: 48.6933109, lng: 19.1671883 },
    { lat: 48.6927236, lng: 19.1662011 },
    { lat: 48.6920462, lng: 19.1668896 },
    { lat: 48.6922511, lng: 19.1671382 },
    { lat: 48.6917363, lng: 19.168156 },
    { lat: 48.6919631, lng: 19.168436 },
    { lat: 48.6924548, lng: 19.1687866 },
    { lat: 48.692713, lng: 19.1690414 },
    { lat: 48.6930276, lng: 19.1695696 },
    { lat: 48.6925989, lng: 19.1698152 },
    { lat: 48.692466, lng: 19.1699767 },
    { lat: 48.6929539, lng: 19.170919 },
    { lat: 48.693613, lng: 19.1714404 },
    { lat: 48.693423, lng: 19.1718613 },
    { lat: 48.6946167, lng: 19.1728025 },
    { lat: 48.6933663, lng: 19.1734412 },
    { lat: 48.6929808, lng: 19.1738312 },
    { lat: 48.6925901, lng: 19.1739337 },
    { lat: 48.6910141, lng: 19.1730806 },
    { lat: 48.6910804, lng: 19.1725266 },
    { lat: 48.6898808, lng: 19.1719462 },
    { lat: 48.6889063, lng: 19.1719143 },
    { lat: 48.6884831, lng: 19.1725243 },
    { lat: 48.6878136, lng: 19.1725278 },
    { lat: 48.6873959, lng: 19.172906 },
    { lat: 48.6868288, lng: 19.1736644 },
    { lat: 48.6868478, lng: 19.1740711 },
    { lat: 48.6867519, lng: 19.174095 },
    { lat: 48.6867512, lng: 19.1738739 },
    { lat: 48.6858961, lng: 19.1731458 },
    { lat: 48.6854359, lng: 19.1725205 },
    { lat: 48.6849007, lng: 19.1713549 },
    { lat: 48.6846836, lng: 19.1710845 },
    { lat: 48.6843834, lng: 19.1710575 },
    { lat: 48.6840846, lng: 19.1731907 },
    { lat: 48.6821744, lng: 19.176469 },
    { lat: 48.6814425, lng: 19.1775664 },
    { lat: 48.6805433, lng: 19.1785847 },
    { lat: 48.6804994, lng: 19.1786338 },
    { lat: 48.6806837, lng: 19.1786935 },
    { lat: 48.6814607, lng: 19.1792738 },
    { lat: 48.6817265, lng: 19.1795437 },
    { lat: 48.6819664, lng: 19.1796532 },
    { lat: 48.6823741, lng: 19.1802476 },
    { lat: 48.6830266, lng: 19.180311 },
    { lat: 48.6833206, lng: 19.1802375 },
    { lat: 48.6837864, lng: 19.1805393 },
    { lat: 48.6839925, lng: 19.1808841 },
    { lat: 48.6844206, lng: 19.18134 },
    { lat: 48.685255, lng: 19.1818055 },
    { lat: 48.685533, lng: 19.1821423 },
    { lat: 48.6849599, lng: 19.1831459 },
    { lat: 48.684856, lng: 19.1834162 },
    { lat: 48.6845231, lng: 19.1836167 },
    { lat: 48.6842898, lng: 19.1840105 },
    { lat: 48.6841268, lng: 19.1841786 },
    { lat: 48.684076, lng: 19.1844293 },
    { lat: 48.6838985, lng: 19.1848576 },
    { lat: 48.6838096, lng: 19.1854397 },
    { lat: 48.6832726, lng: 19.185704 },
    { lat: 48.6827581, lng: 19.1869872 },
    { lat: 48.6823555, lng: 19.1876039 },
    { lat: 48.6822853, lng: 19.1878297 },
    { lat: 48.682367, lng: 19.1887602 },
    { lat: 48.6825112, lng: 19.1891301 },
    { lat: 48.6825694, lng: 19.189582 },
    { lat: 48.6823248, lng: 19.1905735 },
    { lat: 48.6824272, lng: 19.1919544 },
    { lat: 48.6825554, lng: 19.1926385 },
    { lat: 48.6824984, lng: 19.1928368 },
    { lat: 48.6823812, lng: 19.193019 },
    { lat: 48.6821057, lng: 19.1932401 },
    { lat: 48.6818563, lng: 19.1936939 },
    { lat: 48.6820154, lng: 19.1941859 },
    { lat: 48.6814696, lng: 19.1951503 },
    { lat: 48.6810237, lng: 19.1969789 },
    { lat: 48.6807747, lng: 19.197792 },
    { lat: 48.6800273, lng: 19.1980661 },
    { lat: 48.679319, lng: 19.1987749 },
    { lat: 48.6787273, lng: 19.1998641 },
    { lat: 48.6783672, lng: 19.2002017 },
    { lat: 48.6791164, lng: 19.2022956 },
    { lat: 48.6793982, lng: 19.2033571 },
    { lat: 48.6797765, lng: 19.2041344 },
    { lat: 48.6800553, lng: 19.2039651 },
    { lat: 48.6807717, lng: 19.20353 },
    { lat: 48.6808679, lng: 19.2038168 },
    { lat: 48.6811228, lng: 19.2036761 },
    { lat: 48.6816315, lng: 19.2055586 },
    { lat: 48.6818085, lng: 19.2049799 },
    { lat: 48.6819337, lng: 19.2042858 },
    { lat: 48.6826717, lng: 19.2041912 },
    { lat: 48.6827256, lng: 19.2050884 },
    { lat: 48.6823629, lng: 19.2056 },
    { lat: 48.682356, lng: 19.2058501 },
    { lat: 48.6824355, lng: 19.2063275 },
    { lat: 48.6822942, lng: 19.2068511 },
    { lat: 48.6822386, lng: 19.2068676 },
    { lat: 48.6822095, lng: 19.207055 },
    { lat: 48.6822428, lng: 19.2071372 },
    { lat: 48.6821394, lng: 19.2078864 },
    { lat: 48.6824937, lng: 19.2091851 },
    { lat: 48.6824179, lng: 19.2092299 },
    { lat: 48.6824292, lng: 19.2093642 },
    { lat: 48.682853, lng: 19.2096902 },
    { lat: 48.6833908, lng: 19.209969 },
    { lat: 48.6831364, lng: 19.210716 },
    { lat: 48.6827776, lng: 19.2123308 },
    { lat: 48.6832836, lng: 19.2120941 },
    { lat: 48.6834858, lng: 19.2118726 },
    { lat: 48.6837935, lng: 19.2118263 },
    { lat: 48.6842777, lng: 19.2115824 },
    { lat: 48.6845647, lng: 19.2111624 },
    { lat: 48.684661, lng: 19.210913 },
    { lat: 48.6848376, lng: 19.2108823 },
    { lat: 48.684958, lng: 19.2109977 },
    { lat: 48.6850344, lng: 19.2112907 },
    { lat: 48.6848629, lng: 19.212051 },
    { lat: 48.6848826, lng: 19.2121791 },
    { lat: 48.6841889, lng: 19.2130238 },
    { lat: 48.6837904, lng: 19.2136837 },
    { lat: 48.6830243, lng: 19.2144776 },
    { lat: 48.6833283, lng: 19.2151673 },
    { lat: 48.6837606, lng: 19.2149495 },
    { lat: 48.68423, lng: 19.215974 },
    { lat: 48.6849932, lng: 19.2144509 },
    { lat: 48.685208, lng: 19.2147884 },
    { lat: 48.6857335, lng: 19.2140737 },
    { lat: 48.6858613, lng: 19.2142907 },
    { lat: 48.6854808, lng: 19.2145552 },
    { lat: 48.6853103, lng: 19.2148941 },
    { lat: 48.6851586, lng: 19.2155102 },
    { lat: 48.6846243, lng: 19.2164578 },
    { lat: 48.6843799, lng: 19.2167161 },
    { lat: 48.6841608, lng: 19.217217 },
    { lat: 48.684898, lng: 19.2179849 },
    { lat: 48.6851924, lng: 19.2181278 },
    { lat: 48.6852329, lng: 19.217916 },
    { lat: 48.6854627, lng: 19.2179587 },
    { lat: 48.6863429, lng: 19.2179116 },
    { lat: 48.6864334, lng: 19.2180343 },
    { lat: 48.6864144, lng: 19.2182654 },
    { lat: 48.6865453, lng: 19.2182948 },
    { lat: 48.6865174, lng: 19.2185526 },
    { lat: 48.686828, lng: 19.2186152 },
    { lat: 48.686599, lng: 19.2188165 },
    { lat: 48.6865035, lng: 19.2197043 },
    { lat: 48.6858984, lng: 19.22008 },
    { lat: 48.6856602, lng: 19.2200722 },
    { lat: 48.6855565, lng: 19.2204468 },
    { lat: 48.6853355, lng: 19.2206768 },
    { lat: 48.6851064, lng: 19.2207835 },
    { lat: 48.6848519, lng: 19.2207914 },
    { lat: 48.6849306, lng: 19.2204374 },
    { lat: 48.6849765, lng: 19.2197588 },
    { lat: 48.6845477, lng: 19.2193105 },
    { lat: 48.6842407, lng: 19.2191858 },
    { lat: 48.6839664, lng: 19.2191988 },
    { lat: 48.6839161, lng: 19.2193741 },
    { lat: 48.6837906, lng: 19.219479 },
    { lat: 48.6840871, lng: 19.2200583 },
    { lat: 48.6846656, lng: 19.2217799 },
    { lat: 48.684007, lng: 19.2226762 },
    { lat: 48.6840757, lng: 19.2228416 },
    { lat: 48.684211, lng: 19.2228156 },
    { lat: 48.6845946, lng: 19.2235707 },
    { lat: 48.6849327, lng: 19.224053 },
    { lat: 48.6846125, lng: 19.2244484 },
    { lat: 48.6842614, lng: 19.2246207 },
    { lat: 48.6843297, lng: 19.2249392 },
    { lat: 48.6845629, lng: 19.2250703 },
    { lat: 48.6847452, lng: 19.22544 },
    { lat: 48.6850921, lng: 19.2255007 },
    { lat: 48.6855676, lng: 19.2251951 },
    { lat: 48.6856952, lng: 19.2256875 },
    { lat: 48.6858019, lng: 19.2257481 },
    { lat: 48.6861944, lng: 19.2255919 },
    { lat: 48.6865093, lng: 19.225694 },
    { lat: 48.6871422, lng: 19.2254969 },
    { lat: 48.6872511, lng: 19.2257164 },
    { lat: 48.6874965, lng: 19.2253178 },
    { lat: 48.6875487, lng: 19.2249528 },
    { lat: 48.6874635, lng: 19.2240596 },
    { lat: 48.6878193, lng: 19.2236777 },
    { lat: 48.6882075, lng: 19.2229462 },
    { lat: 48.6886074, lng: 19.2244723 },
    { lat: 48.6886376, lng: 19.2249088 },
    { lat: 48.6888282, lng: 19.2255116 },
    { lat: 48.6887088, lng: 19.2256069 },
    { lat: 48.6887616, lng: 19.2262757 },
    { lat: 48.688539, lng: 19.2268226 },
    { lat: 48.6884579, lng: 19.227822 },
    { lat: 48.6889206, lng: 19.2277147 },
    { lat: 48.6892949, lng: 19.2277421 },
    { lat: 48.6895456, lng: 19.227867 },
    { lat: 48.6895027, lng: 19.2293959 },
    { lat: 48.689629, lng: 19.2294436 },
    { lat: 48.6902075, lng: 19.2287288 },
    { lat: 48.6903127, lng: 19.2282972 },
    { lat: 48.6906944, lng: 19.2279167 },
    { lat: 48.6907602, lng: 19.2274396 },
    { lat: 48.6914686, lng: 19.2272395 },
    { lat: 48.6916628, lng: 19.2276882 },
    { lat: 48.6913442, lng: 19.2281841 },
    { lat: 48.6914669, lng: 19.2283196 },
    { lat: 48.691488, lng: 19.2285196 },
    { lat: 48.6915506, lng: 19.2286503 },
    { lat: 48.6912121, lng: 19.229411 },
    { lat: 48.6910562, lng: 19.229541 },
    { lat: 48.6910543, lng: 19.2300617 },
    { lat: 48.6908453, lng: 19.2301655 },
    { lat: 48.6904982, lng: 19.2304694 },
    { lat: 48.6905653, lng: 19.2305279 },
    { lat: 48.6902709, lng: 19.2309205 },
    { lat: 48.6914644, lng: 19.2314498 },
    { lat: 48.6914669, lng: 19.2321046 },
    { lat: 48.6915815, lng: 19.2321215 },
    { lat: 48.6914976, lng: 19.2329471 },
    { lat: 48.6922504, lng: 19.2334107 },
    { lat: 48.6922422, lng: 19.2337046 },
    { lat: 48.6928993, lng: 19.2334453 },
    { lat: 48.6928804, lng: 19.2327538 },
    { lat: 48.6936032, lng: 19.232667 },
    { lat: 48.6937346, lng: 19.2322389 },
    { lat: 48.693976, lng: 19.2325212 },
    { lat: 48.6944975, lng: 19.2325359 },
    { lat: 48.6947465, lng: 19.2339287 },
    { lat: 48.6950313, lng: 19.2346444 },
    { lat: 48.695396, lng: 19.2345369 },
    { lat: 48.6962743, lng: 19.2349132 },
    { lat: 48.6977864, lng: 19.2348551 },
    { lat: 48.6977225, lng: 19.2341896 },
    { lat: 48.6982979, lng: 19.2340628 },
    { lat: 48.6991026, lng: 19.2333607 },
    { lat: 48.6982161, lng: 19.2310025 },
    { lat: 48.697903, lng: 19.2304737 },
    { lat: 48.6979496, lng: 19.2296925 },
    { lat: 48.6979392, lng: 19.2289657 },
    { lat: 48.6981509, lng: 19.2278243 },
    { lat: 48.6981213, lng: 19.2273563 },
    { lat: 48.6981831, lng: 19.2271635 },
    { lat: 48.6978923, lng: 19.2266519 },
    { lat: 48.6981128, lng: 19.2258768 },
    { lat: 48.6999888, lng: 19.2239571 },
    { lat: 48.7001604, lng: 19.2242228 },
    { lat: 48.6999976, lng: 19.2245117 },
    { lat: 48.7001063, lng: 19.2248248 },
    { lat: 48.7004482, lng: 19.2244143 },
    { lat: 48.7016908, lng: 19.2235183 },
    { lat: 48.7017669, lng: 19.2236929 },
    { lat: 48.7029154, lng: 19.2231545 },
    { lat: 48.7031039, lng: 19.2229262 },
    { lat: 48.7035826, lng: 19.2220838 },
    { lat: 48.7040875, lng: 19.2215945 },
    { lat: 48.7042876, lng: 19.2217718 },
    { lat: 48.7045056, lng: 19.2212205 },
    { lat: 48.7045223, lng: 19.2209632 },
    { lat: 48.7052513, lng: 19.2224904 },
    { lat: 48.7053497, lng: 19.2224242 },
    { lat: 48.7069783, lng: 19.2203085 },
    { lat: 48.7071942, lng: 19.2206946 },
    { lat: 48.7078357, lng: 19.2199792 },
    { lat: 48.7079221, lng: 19.2201335 },
    { lat: 48.7080278, lng: 19.2200579 },
    { lat: 48.7081938, lng: 19.2201097 },
    { lat: 48.7083562, lng: 19.2202723 },
    { lat: 48.7084112, lng: 19.2202244 },
    { lat: 48.7086347, lng: 19.2206672 },
    { lat: 48.7088353, lng: 19.2207838 },
    { lat: 48.7091669, lng: 19.2211149 },
    { lat: 48.7093065, lng: 19.2210177 },
    { lat: 48.7093445, lng: 19.2206862 },
    { lat: 48.7092574, lng: 19.2201919 },
    { lat: 48.709199, lng: 19.2201028 },
    { lat: 48.7092, lng: 19.2199392 },
    { lat: 48.7093119, lng: 19.2196409 },
    { lat: 48.7097466, lng: 19.2189385 },
    { lat: 48.7096984, lng: 19.2188756 },
    { lat: 48.7099055, lng: 19.2184986 },
    { lat: 48.7099415, lng: 19.2181266 },
    { lat: 48.7100107, lng: 19.2180644 },
    { lat: 48.7102167, lng: 19.217292 },
    { lat: 48.7100937, lng: 19.2169923 },
    { lat: 48.7099108, lng: 19.2171218 },
    { lat: 48.7098418, lng: 19.2173746 },
    { lat: 48.7095207, lng: 19.2172604 },
    { lat: 48.7096887, lng: 19.2168192 },
    { lat: 48.7100575, lng: 19.2162471 },
    { lat: 48.7102541, lng: 19.2160208 },
    { lat: 48.7102752, lng: 19.2160866 },
    { lat: 48.7110443, lng: 19.215211 },
    { lat: 48.7117037, lng: 19.2148651 },
    { lat: 48.7115931, lng: 19.2146322 },
    { lat: 48.7115568, lng: 19.2142546 },
    { lat: 48.7123915, lng: 19.2141755 },
    { lat: 48.7124938, lng: 19.2144229 },
    { lat: 48.7126053, lng: 19.2144923 },
    { lat: 48.7130317, lng: 19.2145405 },
    { lat: 48.7133515, lng: 19.2142595 },
    { lat: 48.7136865, lng: 19.2140998 },
    { lat: 48.7136638, lng: 19.214007 },
    { lat: 48.7131146, lng: 19.2138364 },
    { lat: 48.7129535, lng: 19.2136769 },
    { lat: 48.7127426, lng: 19.213605 },
    { lat: 48.7128374, lng: 19.2135128 },
    { lat: 48.7128004, lng: 19.2133123 },
    { lat: 48.7131328, lng: 19.2130984 },
    { lat: 48.7130417, lng: 19.2125224 },
    { lat: 48.7128793, lng: 19.2123363 },
    { lat: 48.7132368, lng: 19.2119015 },
    { lat: 48.7137021, lng: 19.2114546 },
    { lat: 48.7136912, lng: 19.2110468 },
    { lat: 48.7138992, lng: 19.2108737 },
    { lat: 48.7142846, lng: 19.2110083 },
    { lat: 48.7144543, lng: 19.2109755 },
    { lat: 48.7144424, lng: 19.2107316 },
    { lat: 48.7143659, lng: 19.2106448 },
    { lat: 48.7143266, lng: 19.2100224 },
    { lat: 48.7151296, lng: 19.2096604 },
    { lat: 48.7154276, lng: 19.2091097 },
    { lat: 48.7155639, lng: 19.2082086 },
    { lat: 48.7155068, lng: 19.2075882 },
    { lat: 48.7156054, lng: 19.2077168 },
    { lat: 48.7159938, lng: 19.207801 },
    { lat: 48.7162635, lng: 19.2079898 },
    { lat: 48.7167633, lng: 19.2066124 },
    { lat: 48.7173435, lng: 19.2057693 },
    { lat: 48.7176223, lng: 19.2054984 },
    { lat: 48.7180308, lng: 19.2055358 },
    { lat: 48.7180555, lng: 19.2056694 },
    { lat: 48.7188509, lng: 19.2054288 },
    { lat: 48.7193286, lng: 19.2046842 },
    { lat: 48.7196558, lng: 19.203835 },
    { lat: 48.719996, lng: 19.2027418 },
    { lat: 48.720259, lng: 19.2021285 },
    { lat: 48.7210646, lng: 19.1991804 },
    { lat: 48.7214015, lng: 19.1969834 },
    { lat: 48.7220742, lng: 19.1948774 },
    { lat: 48.7223032, lng: 19.1945795 },
    { lat: 48.7237049, lng: 19.1948456 },
    { lat: 48.7240665, lng: 19.1945407 },
    { lat: 48.7240807, lng: 19.1945323 },
    { lat: 48.7245272, lng: 19.1937492 },
    { lat: 48.725032, lng: 19.1918532 },
    { lat: 48.724904, lng: 19.1917704 },
    { lat: 48.7248613, lng: 19.1916309 },
    { lat: 48.7245134, lng: 19.191483 },
    { lat: 48.724218, lng: 19.1910882 },
    { lat: 48.7239183, lng: 19.1909535 },
    { lat: 48.7236676, lng: 19.1904595 },
    { lat: 48.723696, lng: 19.1904171 },
    { lat: 48.7226459, lng: 19.1893401 },
    { lat: 48.7222513, lng: 19.1890378 },
    { lat: 48.7216633, lng: 19.188795 },
    { lat: 48.7216484, lng: 19.187268 },
    { lat: 48.7217989, lng: 19.1858721 },
    { lat: 48.7217414, lng: 19.1854833 },
    { lat: 48.7213184, lng: 19.1849233 },
    { lat: 48.7215115, lng: 19.182694 },
    { lat: 48.7215098, lng: 19.1814147 },
    { lat: 48.7214044, lng: 19.1796997 },
    { lat: 48.7209097, lng: 19.1794947 },
    { lat: 48.7208978, lng: 19.179571 },
    { lat: 48.7207748, lng: 19.1795237 },
    { lat: 48.7204373, lng: 19.1792152 },
    { lat: 48.7203942, lng: 19.1790019 },
    { lat: 48.720438, lng: 19.1782169 },
    { lat: 48.7203338, lng: 19.1776756 },
    { lat: 48.7195634, lng: 19.1762177 },
    { lat: 48.7193475, lng: 19.1756296 },
    { lat: 48.7190312, lng: 19.1749917 },
    { lat: 48.7188903, lng: 19.1748112 },
    { lat: 48.7185768, lng: 19.1747374 },
    { lat: 48.7183849, lng: 19.1745246 },
    { lat: 48.718393, lng: 19.1744634 },
    { lat: 48.7182466, lng: 19.1741881 },
    { lat: 48.7182335, lng: 19.1740125 },
    { lat: 48.7183284, lng: 19.1734679 },
    { lat: 48.7187819, lng: 19.1725503 },
    { lat: 48.7191902, lng: 19.1712669 },
    { lat: 48.7194616, lng: 19.1708467 },
    { lat: 48.7199052, lng: 19.1703123 },
    { lat: 48.72048, lng: 19.1701005 },
    { lat: 48.7209077, lng: 19.1694199 },
    { lat: 48.7209396, lng: 19.1690594 },
    { lat: 48.721048, lng: 19.1688077 },
    { lat: 48.7211515, lng: 19.1671956 },
    { lat: 48.7207471, lng: 19.1673166 },
    { lat: 48.7200134, lng: 19.1666752 },
    { lat: 48.7197039, lng: 19.1667077 },
    { lat: 48.7184038, lng: 19.1664732 },
    { lat: 48.7180459, lng: 19.1669917 },
    { lat: 48.7175642, lng: 19.1674447 },
    { lat: 48.717003, lng: 19.1683208 },
    { lat: 48.7168689, lng: 19.1684586 },
    { lat: 48.715645, lng: 19.1680524 },
    { lat: 48.7154196, lng: 19.168123 },
    { lat: 48.7152263, lng: 19.168271 },
    { lat: 48.7145385, lng: 19.16928 },
    { lat: 48.7139245, lng: 19.16986 },
    { lat: 48.7135365, lng: 19.1699495 },
    { lat: 48.7135421, lng: 19.1698321 },
    { lat: 48.7134552, lng: 19.169837 },
    { lat: 48.7131451, lng: 19.1698544 },
    { lat: 48.7124074, lng: 19.1697039 },
    { lat: 48.7115718, lng: 19.1700213 },
    { lat: 48.7113807, lng: 19.1701448 },
    { lat: 48.7110766, lng: 19.1704947 },
    { lat: 48.7104715, lng: 19.1715565 },
    { lat: 48.7097883, lng: 19.1722265 },
    { lat: 48.7096271, lng: 19.1724791 },
    { lat: 48.7095441, lng: 19.1726711 },
    { lat: 48.7092822, lng: 19.1739486 },
    { lat: 48.7088845, lng: 19.1749675 },
    { lat: 48.7089128, lng: 19.17499 },
    { lat: 48.70832, lng: 19.1757798 },
    { lat: 48.7082856, lng: 19.1757429 },
    { lat: 48.706996, lng: 19.1765028 },
    { lat: 48.7063823, lng: 19.1761014 },
  ],
  Baláže: [
    { lat: 48.8508275, lng: 19.2058022 },
    { lat: 48.8480842, lng: 19.204322 },
    { lat: 48.8471643, lng: 19.2043685 },
    { lat: 48.8464334, lng: 19.2043313 },
    { lat: 48.8449008, lng: 19.2031084 },
    { lat: 48.8442588, lng: 19.2011262 },
    { lat: 48.8441419, lng: 19.2006021 },
    { lat: 48.8439562, lng: 19.1992855 },
    { lat: 48.844202, lng: 19.1988468 },
    { lat: 48.8443709, lng: 19.1978921 },
    { lat: 48.8444752, lng: 19.1964472 },
    { lat: 48.8443845, lng: 19.1953697 },
    { lat: 48.8440598, lng: 19.1948391 },
    { lat: 48.8439912, lng: 19.1924541 },
    { lat: 48.8435962, lng: 19.1911061 },
    { lat: 48.8435814, lng: 19.1902206 },
    { lat: 48.8434086, lng: 19.1894756 },
    { lat: 48.843321, lng: 19.1888315 },
    { lat: 48.8428454, lng: 19.1874232 },
    { lat: 48.8424487, lng: 19.1858322 },
    { lat: 48.8419663, lng: 19.1852066 },
    { lat: 48.8411427, lng: 19.1843845 },
    { lat: 48.8410973, lng: 19.1836857 },
    { lat: 48.8411469, lng: 19.1829447 },
    { lat: 48.8413731, lng: 19.1815288 },
    { lat: 48.8418455, lng: 19.1805615 },
    { lat: 48.8420421, lng: 19.1782839 },
    { lat: 48.8419428, lng: 19.1769 },
    { lat: 48.8418401, lng: 19.1766883 },
    { lat: 48.8410418, lng: 19.1761275 },
    { lat: 48.8405484, lng: 19.1758812 },
    { lat: 48.8400708, lng: 19.1752881 },
    { lat: 48.8393092, lng: 19.1746962 },
    { lat: 48.8387102, lng: 19.1741114 },
    { lat: 48.8384812, lng: 19.1735784 },
    { lat: 48.8381684, lng: 19.1731705 },
    { lat: 48.8377673, lng: 19.1727604 },
    { lat: 48.8373581, lng: 19.1724381 },
    { lat: 48.8366144, lng: 19.1711514 },
    { lat: 48.8364337, lng: 19.1707069 },
    { lat: 48.8364076, lng: 19.1700138 },
    { lat: 48.8357496, lng: 19.1692819 },
    { lat: 48.8352317, lng: 19.1688523 },
    { lat: 48.8347097, lng: 19.1685782 },
    { lat: 48.8343173, lng: 19.1684608 },
    { lat: 48.8335315, lng: 19.1685498 },
    { lat: 48.833129, lng: 19.1684526 },
    { lat: 48.8326367, lng: 19.1684864 },
    { lat: 48.8323878, lng: 19.168236 },
    { lat: 48.8319662, lng: 19.1682124 },
    { lat: 48.8312792, lng: 19.1679933 },
    { lat: 48.8308739, lng: 19.1680031 },
    { lat: 48.8293907, lng: 19.166809 },
    { lat: 48.8291545, lng: 19.166106 },
    { lat: 48.8288717, lng: 19.1645421 },
    { lat: 48.8288654, lng: 19.1638833 },
    { lat: 48.8288591, lng: 19.1632272 },
    { lat: 48.8287714, lng: 19.1623573 },
    { lat: 48.8289562, lng: 19.1612264 },
    { lat: 48.828934, lng: 19.1593716 },
    { lat: 48.8290885, lng: 19.1587972 },
    { lat: 48.8291125, lng: 19.1583902 },
    { lat: 48.8290706, lng: 19.1581094 },
    { lat: 48.8287799, lng: 19.1580444 },
    { lat: 48.8282574, lng: 19.1583318 },
    { lat: 48.827388, lng: 19.157776 },
    { lat: 48.8270289, lng: 19.157625 },
    { lat: 48.8265539, lng: 19.1572212 },
    { lat: 48.8260676, lng: 19.1570537 },
    { lat: 48.8256432, lng: 19.1571565 },
    { lat: 48.8240141, lng: 19.1568965 },
    { lat: 48.8235455, lng: 19.1570408 },
    { lat: 48.8231815, lng: 19.1575948 },
    { lat: 48.8226127, lng: 19.1577646 },
    { lat: 48.8223286, lng: 19.1579976 },
    { lat: 48.8220589, lng: 19.1579199 },
    { lat: 48.8218208, lng: 19.1580079 },
    { lat: 48.821579, lng: 19.1584078 },
    { lat: 48.8211947, lng: 19.1586167 },
    { lat: 48.820952, lng: 19.1589802 },
    { lat: 48.820467, lng: 19.1598217 },
    { lat: 48.820318, lng: 19.1603958 },
    { lat: 48.8196483, lng: 19.1610803 },
    { lat: 48.8191569, lng: 19.1617973 },
    { lat: 48.8188864, lng: 19.1620269 },
    { lat: 48.8185774, lng: 19.1624702 },
    { lat: 48.8184822, lng: 19.1626102 },
    { lat: 48.8182952, lng: 19.1629579 },
    { lat: 48.8179958, lng: 19.1639831 },
    { lat: 48.8176224, lng: 19.1649189 },
    { lat: 48.8175942, lng: 19.1652408 },
    { lat: 48.8174067, lng: 19.1658545 },
    { lat: 48.8168668, lng: 19.1667053 },
    { lat: 48.8164284, lng: 19.1670089 },
    { lat: 48.8161389, lng: 19.1673597 },
    { lat: 48.8149584, lng: 19.1684562 },
    { lat: 48.81433, lng: 19.1691834 },
    { lat: 48.8138635, lng: 19.1695115 },
    { lat: 48.8133283, lng: 19.1700004 },
    { lat: 48.8122957, lng: 19.1714936 },
    { lat: 48.8117726, lng: 19.1721134 },
    { lat: 48.8113189, lng: 19.1727713 },
    { lat: 48.8112347, lng: 19.1730779 },
    { lat: 48.8103106, lng: 19.1743035 },
    { lat: 48.8101407, lng: 19.1749108 },
    { lat: 48.8101266, lng: 19.1753391 },
    { lat: 48.8104582, lng: 19.1766323 },
    { lat: 48.8104549, lng: 19.1775558 },
    { lat: 48.8097093, lng: 19.1804982 },
    { lat: 48.8091895, lng: 19.1814863 },
    { lat: 48.8086994, lng: 19.1817722 },
    { lat: 48.8083073, lng: 19.1824451 },
    { lat: 48.8079921, lng: 19.1831917 },
    { lat: 48.8077411, lng: 19.1834316 },
    { lat: 48.8073529, lng: 19.1841602 },
    { lat: 48.8070273, lng: 19.1850188 },
    { lat: 48.8068974, lng: 19.1854986 },
    { lat: 48.8067929, lng: 19.1861246 },
    { lat: 48.8068515, lng: 19.1871423 },
    { lat: 48.806756, lng: 19.1873827 },
    { lat: 48.8066256, lng: 19.1877111 },
    { lat: 48.8059929, lng: 19.1883973 },
    { lat: 48.805805, lng: 19.1889794 },
    { lat: 48.8055932, lng: 19.1892624 },
    { lat: 48.8056268, lng: 19.1893945 },
    { lat: 48.8055478, lng: 19.1896729 },
    { lat: 48.8056039, lng: 19.1898748 },
    { lat: 48.8056862, lng: 19.1906849 },
    { lat: 48.8056, lng: 19.1907624 },
    { lat: 48.8054896, lng: 19.1912511 },
    { lat: 48.8055016, lng: 19.1914529 },
    { lat: 48.8052327, lng: 19.1914858 },
    { lat: 48.8051988, lng: 19.1917581 },
    { lat: 48.8054778, lng: 19.1919333 },
    { lat: 48.8058961, lng: 19.1931153 },
    { lat: 48.8062255, lng: 19.1933513 },
    { lat: 48.8062429, lng: 19.1937577 },
    { lat: 48.8063842, lng: 19.1938793 },
    { lat: 48.8067968, lng: 19.1939745 },
    { lat: 48.8070012, lng: 19.1935397 },
    { lat: 48.8070918, lng: 19.193596 },
    { lat: 48.8070275, lng: 19.1941536 },
    { lat: 48.8072344, lng: 19.1946722 },
    { lat: 48.8074248, lng: 19.1949242 },
    { lat: 48.8078945, lng: 19.1952172 },
    { lat: 48.8083162, lng: 19.1946909 },
    { lat: 48.8083522, lng: 19.1944791 },
    { lat: 48.808295, lng: 19.1942795 },
    { lat: 48.8082033, lng: 19.1941531 },
    { lat: 48.8083587, lng: 19.1936633 },
    { lat: 48.8086377, lng: 19.1947887 },
    { lat: 48.8088008, lng: 19.1953152 },
    { lat: 48.808896, lng: 19.1954411 },
    { lat: 48.8093056, lng: 19.1955374 },
    { lat: 48.8095176, lng: 19.1952408 },
    { lat: 48.8096749, lng: 19.1956953 },
    { lat: 48.8102376, lng: 19.1961138 },
    { lat: 48.8103336, lng: 19.1963059 },
    { lat: 48.8105175, lng: 19.1963519 },
    { lat: 48.8108786, lng: 19.1963155 },
    { lat: 48.8111073, lng: 19.1964284 },
    { lat: 48.8114856, lng: 19.1967909 },
    { lat: 48.8120604, lng: 19.1974825 },
    { lat: 48.8122453, lng: 19.1975301 },
    { lat: 48.8123731, lng: 19.1974468 },
    { lat: 48.8125709, lng: 19.1968099 },
    { lat: 48.8128884, lng: 19.1964308 },
    { lat: 48.8125381, lng: 19.1971988 },
    { lat: 48.812257, lng: 19.1983707 },
    { lat: 48.812158, lng: 19.1985599 },
    { lat: 48.8120893, lng: 19.1989771 },
    { lat: 48.8121291, lng: 19.2000974 },
    { lat: 48.8122475, lng: 19.2005148 },
    { lat: 48.8122514, lng: 19.2009457 },
    { lat: 48.8121547, lng: 19.201224 },
    { lat: 48.8120882, lng: 19.2020808 },
    { lat: 48.8118216, lng: 19.2033001 },
    { lat: 48.8118072, lng: 19.2040688 },
    { lat: 48.8118973, lng: 19.2042301 },
    { lat: 48.8119051, lng: 19.2043908 },
    { lat: 48.8117222, lng: 19.2043844 },
    { lat: 48.8115694, lng: 19.2049039 },
    { lat: 48.8115425, lng: 19.2052299 },
    { lat: 48.8114322, lng: 19.205352 },
    { lat: 48.8115496, lng: 19.2056536 },
    { lat: 48.8115821, lng: 19.2061364 },
    { lat: 48.8117724, lng: 19.2063877 },
    { lat: 48.8118139, lng: 19.2063147 },
    { lat: 48.8120589, lng: 19.2067674 },
    { lat: 48.8123871, lng: 19.2070037 },
    { lat: 48.8128487, lng: 19.2072229 },
    { lat: 48.8129395, lng: 19.2072093 },
    { lat: 48.8130797, lng: 19.2073338 },
    { lat: 48.8133028, lng: 19.2072396 },
    { lat: 48.8133473, lng: 19.2073008 },
    { lat: 48.8131026, lng: 19.2077379 },
    { lat: 48.8133695, lng: 19.2077082 },
    { lat: 48.8137931, lng: 19.2080751 },
    { lat: 48.813629, lng: 19.2083631 },
    { lat: 48.813814, lng: 19.2084805 },
    { lat: 48.8140534, lng: 19.2087947 },
    { lat: 48.8142022, lng: 19.20912 },
    { lat: 48.8143895, lng: 19.2091678 },
    { lat: 48.8144122, lng: 19.2087554 },
    { lat: 48.8148869, lng: 19.2092451 },
    { lat: 48.8149998, lng: 19.2087593 },
    { lat: 48.8150861, lng: 19.2087511 },
    { lat: 48.8151134, lng: 19.2092213 },
    { lat: 48.8153375, lng: 19.2092639 },
    { lat: 48.8155107, lng: 19.209045 },
    { lat: 48.8157383, lng: 19.2090869 },
    { lat: 48.8158398, lng: 19.2093438 },
    { lat: 48.8159721, lng: 19.2093291 },
    { lat: 48.8159755, lng: 19.2094006 },
    { lat: 48.8156169, lng: 19.209442 },
    { lat: 48.8153585, lng: 19.2096718 },
    { lat: 48.8151105, lng: 19.2101082 },
    { lat: 48.8155927, lng: 19.2098531 },
    { lat: 48.8159056, lng: 19.2098183 },
    { lat: 48.8159255, lng: 19.210227 },
    { lat: 48.8161573, lng: 19.2103364 },
    { lat: 48.8161214, lng: 19.210477 },
    { lat: 48.816465, lng: 19.2108215 },
    { lat: 48.8171626, lng: 19.2115192 },
    { lat: 48.8173387, lng: 19.2111955 },
    { lat: 48.8178287, lng: 19.210912 },
    { lat: 48.8180116, lng: 19.2105233 },
    { lat: 48.8181504, lng: 19.2104338 },
    { lat: 48.8184226, lng: 19.2104703 },
    { lat: 48.8188213, lng: 19.2102274 },
    { lat: 48.8193096, lng: 19.2100489 },
    { lat: 48.8197296, lng: 19.2097831 },
    { lat: 48.8201739, lng: 19.2096703 },
    { lat: 48.820569, lng: 19.2098248 },
    { lat: 48.8209178, lng: 19.2100668 },
    { lat: 48.821854, lng: 19.2105416 },
    { lat: 48.8222244, lng: 19.2104406 },
    { lat: 48.8225483, lng: 19.2104714 },
    { lat: 48.8230102, lng: 19.2107032 },
    { lat: 48.8231668, lng: 19.2109061 },
    { lat: 48.8233433, lng: 19.2109666 },
    { lat: 48.8236803, lng: 19.2109659 },
    { lat: 48.8240193, lng: 19.211122 },
    { lat: 48.8242256, lng: 19.2110314 },
    { lat: 48.8256603, lng: 19.2109599 },
    { lat: 48.8261367, lng: 19.2112267 },
    { lat: 48.8265283, lng: 19.2116792 },
    { lat: 48.8276941, lng: 19.2127764 },
    { lat: 48.8287607, lng: 19.2146635 },
    { lat: 48.8292798, lng: 19.2162103 },
    { lat: 48.8292389, lng: 19.2164578 },
    { lat: 48.8292738, lng: 19.216666 },
    { lat: 48.8295743, lng: 19.2167986 },
    { lat: 48.8299094, lng: 19.2172929 },
    { lat: 48.8311768, lng: 19.2182087 },
    { lat: 48.8329953, lng: 19.2187734 },
    { lat: 48.8328075, lng: 19.2190366 },
    { lat: 48.8327675, lng: 19.2192142 },
    { lat: 48.8328136, lng: 19.2200674 },
    { lat: 48.8332248, lng: 19.2202295 },
    { lat: 48.8340445, lng: 19.22007 },
    { lat: 48.83499, lng: 19.2206013 },
    { lat: 48.8352748, lng: 19.2206644 },
    { lat: 48.8361412, lng: 19.2206543 },
    { lat: 48.8367195, lng: 19.2208618 },
    { lat: 48.8375015, lng: 19.2211424 },
    { lat: 48.8379692, lng: 19.2213959 },
    { lat: 48.839883, lng: 19.2214636 },
    { lat: 48.8406918, lng: 19.2212219 },
    { lat: 48.8409658, lng: 19.2209681 },
    { lat: 48.8414015, lng: 19.2203711 },
    { lat: 48.8418046, lng: 19.2202052 },
    { lat: 48.8421043, lng: 19.220174 },
    { lat: 48.8434263, lng: 19.2218266 },
    { lat: 48.8440506, lng: 19.2223791 },
    { lat: 48.8449458, lng: 19.2229452 },
    { lat: 48.8455317, lng: 19.2230543 },
    { lat: 48.8455398, lng: 19.2229886 },
    { lat: 48.8473452, lng: 19.2211434 },
    { lat: 48.8497987, lng: 19.2203052 },
    { lat: 48.8506527, lng: 19.2198534 },
    { lat: 48.8511572, lng: 19.2193573 },
    { lat: 48.8515026, lng: 19.2188765 },
    { lat: 48.8516252, lng: 19.2188485 },
    { lat: 48.8515995, lng: 19.215531 },
    { lat: 48.8515475, lng: 19.2155264 },
    { lat: 48.8516433, lng: 19.2142 },
    { lat: 48.8519109, lng: 19.2136283 },
    { lat: 48.8516491, lng: 19.2106295 },
    { lat: 48.8504619, lng: 19.2087894 },
    { lat: 48.8506584, lng: 19.2075198 },
    { lat: 48.8507166, lng: 19.206718 },
    { lat: 48.8508275, lng: 19.2058022 },
  ],
  PohronskýBukovec: [
    { lat: 48.885361, lng: 19.363558 },
    { lat: 48.8818644, lng: 19.3615274 },
    { lat: 48.8781405, lng: 19.3599719 },
    { lat: 48.8757171, lng: 19.3611597 },
    { lat: 48.8742112, lng: 19.3611767 },
    { lat: 48.8720385, lng: 19.3620418 },
    { lat: 48.8703109, lng: 19.3620907 },
    { lat: 48.8679171, lng: 19.3628999 },
    { lat: 48.8676229, lng: 19.3629021 },
    { lat: 48.8661087, lng: 19.3632245 },
    { lat: 48.8652178, lng: 19.3628515 },
    { lat: 48.8648745, lng: 19.3629335 },
    { lat: 48.8646794, lng: 19.3627137 },
    { lat: 48.8645243, lng: 19.3624145 },
    { lat: 48.864271, lng: 19.3624663 },
    { lat: 48.8640064, lng: 19.3623046 },
    { lat: 48.8637601, lng: 19.3623392 },
    { lat: 48.8636367, lng: 19.3624718 },
    { lat: 48.8635388, lng: 19.3630007 },
    { lat: 48.8631106, lng: 19.3639542 },
    { lat: 48.8626761, lng: 19.3645407 },
    { lat: 48.8622879, lng: 19.3648172 },
    { lat: 48.8618387, lng: 19.3654984 },
    { lat: 48.861623, lng: 19.3656048 },
    { lat: 48.8609027, lng: 19.3657706 },
    { lat: 48.8605002, lng: 19.3663844 },
    { lat: 48.8602446, lng: 19.3665828 },
    { lat: 48.8602861, lng: 19.3670816 },
    { lat: 48.859935, lng: 19.3674249 },
    { lat: 48.8598509, lng: 19.3676234 },
    { lat: 48.8595887, lng: 19.3677538 },
    { lat: 48.8591925, lng: 19.3676195 },
    { lat: 48.8588753, lng: 19.3677051 },
    { lat: 48.8581083, lng: 19.3676905 },
    { lat: 48.8579109, lng: 19.3680218 },
    { lat: 48.8578944, lng: 19.3686775 },
    { lat: 48.8577726, lng: 19.3687948 },
    { lat: 48.8569788, lng: 19.368883 },
    { lat: 48.8564138, lng: 19.368567 },
    { lat: 48.8551394, lng: 19.3681839 },
    { lat: 48.8545859, lng: 19.3684217 },
    { lat: 48.8537744, lng: 19.3681593 },
    { lat: 48.853177, lng: 19.3681313 },
    { lat: 48.8523618, lng: 19.368147 },
    { lat: 48.8520005, lng: 19.3683035 },
    { lat: 48.8504846, lng: 19.3678239 },
    { lat: 48.8498382, lng: 19.3678832 },
    { lat: 48.8471899, lng: 19.3676952 },
    { lat: 48.846744, lng: 19.3679639 },
    { lat: 48.8457818, lng: 19.3676126 },
    { lat: 48.8444658, lng: 19.3677863 },
    { lat: 48.8437489, lng: 19.3674968 },
    { lat: 48.8418748, lng: 19.3673789 },
    { lat: 48.841569, lng: 19.3675401 },
    { lat: 48.8403281, lng: 19.3674183 },
    { lat: 48.8390841, lng: 19.3669001 },
    { lat: 48.8381026, lng: 19.3669499 },
    { lat: 48.8372511, lng: 19.3662672 },
    { lat: 48.8367638, lng: 19.3663304 },
    { lat: 48.8347633, lng: 19.3669531 },
    { lat: 48.8335336, lng: 19.3668527 },
    { lat: 48.8329933, lng: 19.3671534 },
    { lat: 48.8321952, lng: 19.3673418 },
    { lat: 48.8318324, lng: 19.3673213 },
    { lat: 48.8311336, lng: 19.3671498 },
    { lat: 48.8297702, lng: 19.3674452 },
    { lat: 48.8282288, lng: 19.3681669 },
    { lat: 48.8272566, lng: 19.3685097 },
    { lat: 48.8259699, lng: 19.3685417 },
    { lat: 48.825265, lng: 19.3684584 },
    { lat: 48.8250096, lng: 19.3684925 },
    { lat: 48.82441, lng: 19.3688983 },
    { lat: 48.8237179, lng: 19.3690855 },
    { lat: 48.8236682, lng: 19.3693684 },
    { lat: 48.8233982, lng: 19.3695718 },
    { lat: 48.8224784, lng: 19.3698338 },
    { lat: 48.822127, lng: 19.3701687 },
    { lat: 48.821435, lng: 19.3709828 },
    { lat: 48.8209109, lng: 19.3729153 },
    { lat: 48.8205261, lng: 19.373669 },
    { lat: 48.8200102, lng: 19.3741321 },
    { lat: 48.8193843, lng: 19.3744062 },
    { lat: 48.8182703, lng: 19.3750666 },
    { lat: 48.8176453, lng: 19.3751496 },
    { lat: 48.8172068, lng: 19.3749803 },
    { lat: 48.8178219, lng: 19.3769833 },
    { lat: 48.8173363, lng: 19.3773433 },
    { lat: 48.8174339, lng: 19.3775735 },
    { lat: 48.8159283, lng: 19.3786255 },
    { lat: 48.8151723, lng: 19.3790601 },
    { lat: 48.8138069, lng: 19.3796496 },
    { lat: 48.813296, lng: 19.38012 },
    { lat: 48.8116726, lng: 19.3810909 },
    { lat: 48.8106478, lng: 19.382113 },
    { lat: 48.8108595, lng: 19.3825357 },
    { lat: 48.810187, lng: 19.3834764 },
    { lat: 48.8100895, lng: 19.3833207 },
    { lat: 48.809079, lng: 19.3845049 },
    { lat: 48.8077653, lng: 19.385686 },
    { lat: 48.8044465, lng: 19.3897369 },
    { lat: 48.8043019, lng: 19.3899956 },
    { lat: 48.8042355, lng: 19.3924157 },
    { lat: 48.8047498, lng: 19.3925458 },
    { lat: 48.8048998, lng: 19.3928829 },
    { lat: 48.8045254, lng: 19.3934531 },
    { lat: 48.8044665, lng: 19.3936128 },
    { lat: 48.804506, lng: 19.393662 },
    { lat: 48.804521, lng: 19.393689 },
    { lat: 48.804536, lng: 19.39372 },
    { lat: 48.804635, lng: 19.393739 },
    { lat: 48.806932, lng: 19.394183 },
    { lat: 48.807914, lng: 19.393292 },
    { lat: 48.808064, lng: 19.393155 },
    { lat: 48.808241, lng: 19.392994 },
    { lat: 48.808312, lng: 19.39293 },
    { lat: 48.808443, lng: 19.392811 },
    { lat: 48.809373, lng: 19.392793 },
    { lat: 48.810179, lng: 19.392785 },
    { lat: 48.810881, lng: 19.392774 },
    { lat: 48.812667, lng: 19.389949 },
    { lat: 48.813171, lng: 19.38917 },
    { lat: 48.813274, lng: 19.389013 },
    { lat: 48.813674, lng: 19.388459 },
    { lat: 48.814681, lng: 19.387066 },
    { lat: 48.815207, lng: 19.386324 },
    { lat: 48.815694, lng: 19.385574 },
    { lat: 48.816213, lng: 19.384905 },
    { lat: 48.817512, lng: 19.383992 },
    { lat: 48.818636, lng: 19.38401 },
    { lat: 48.818735, lng: 19.384011 },
    { lat: 48.819251, lng: 19.384019 },
    { lat: 48.819367, lng: 19.384021 },
    { lat: 48.820958, lng: 19.384043 },
    { lat: 48.822215, lng: 19.384061 },
    { lat: 48.823126, lng: 19.384069 },
    { lat: 48.823862, lng: 19.38408 },
    { lat: 48.824022, lng: 19.383499 },
    { lat: 48.824967, lng: 19.383355 },
    { lat: 48.825867, lng: 19.383225 },
    { lat: 48.827555, lng: 19.382889 },
    { lat: 48.828451, lng: 19.382681 },
    { lat: 48.830894, lng: 19.382194 },
    { lat: 48.83198, lng: 19.381964 },
    { lat: 48.832238, lng: 19.382268 },
    { lat: 48.833124, lng: 19.383271 },
    { lat: 48.834673, lng: 19.38506 },
    { lat: 48.834715, lng: 19.385109 },
    { lat: 48.834751, lng: 19.38515 },
    { lat: 48.8353, lng: 19.385806 },
    { lat: 48.835651, lng: 19.386189 },
    { lat: 48.83572, lng: 19.38627 },
    { lat: 48.835823, lng: 19.386398 },
    { lat: 48.836247, lng: 19.386881 },
    { lat: 48.836453, lng: 19.387118 },
    { lat: 48.836657, lng: 19.387365 },
    { lat: 48.836718, lng: 19.387438 },
    { lat: 48.83673, lng: 19.387453 },
    { lat: 48.837223, lng: 19.388015 },
    { lat: 48.83726, lng: 19.38807 },
    { lat: 48.838326, lng: 19.389399 },
    { lat: 48.838888, lng: 19.390042 },
    { lat: 48.84004, lng: 19.39142 },
    { lat: 48.840414, lng: 19.39172 },
    { lat: 48.840552, lng: 19.391897 },
    { lat: 48.840612, lng: 19.391955 },
    { lat: 48.841024, lng: 19.392441 },
    { lat: 48.841399, lng: 19.392881 },
    { lat: 48.841515, lng: 19.392991 },
    { lat: 48.841709, lng: 19.393371 },
    { lat: 48.842639, lng: 19.394277 },
    { lat: 48.842909, lng: 19.394591 },
    { lat: 48.843461, lng: 19.395215 },
    { lat: 48.844582, lng: 19.396546 },
    { lat: 48.844883, lng: 19.396892 },
    { lat: 48.845403, lng: 19.397502 },
    { lat: 48.846263, lng: 19.398496 },
    { lat: 48.846717, lng: 19.399024 },
    { lat: 48.847148, lng: 19.399493 },
    { lat: 48.848461, lng: 19.400983 },
    { lat: 48.849669, lng: 19.402426 },
    { lat: 48.849834, lng: 19.402624 },
    { lat: 48.849899, lng: 19.402687 },
    { lat: 48.850463, lng: 19.402609 },
    { lat: 48.851154, lng: 19.402392 },
    { lat: 48.851849, lng: 19.402182 },
    { lat: 48.852724, lng: 19.402288 },
    { lat: 48.853045, lng: 19.402585 },
    { lat: 48.853513, lng: 19.403018 },
    { lat: 48.854458, lng: 19.402121 },
    { lat: 48.855297, lng: 19.401897 },
    { lat: 48.856123, lng: 19.40108 },
    { lat: 48.856406, lng: 19.400824 },
    { lat: 48.856854, lng: 19.400524 },
    { lat: 48.857693, lng: 19.399981 },
    { lat: 48.858449, lng: 19.399872 },
    { lat: 48.859044, lng: 19.400017 },
    { lat: 48.860259, lng: 19.400583 },
    { lat: 48.860863, lng: 19.40046 },
    { lat: 48.861525, lng: 19.399956 },
    { lat: 48.862548, lng: 19.39932 },
    { lat: 48.862908, lng: 19.399143 },
    { lat: 48.863427, lng: 19.398983 },
    { lat: 48.864277, lng: 19.398602 },
    { lat: 48.865107, lng: 19.397999 },
    { lat: 48.865574, lng: 19.397486 },
    { lat: 48.865901, lng: 19.39685 },
    { lat: 48.866215, lng: 19.39641 },
    { lat: 48.866413, lng: 19.396124 },
    { lat: 48.867352, lng: 19.394315 },
    { lat: 48.868114, lng: 19.393836 },
    { lat: 48.868614, lng: 19.393653 },
    { lat: 48.869009, lng: 19.39344 },
    { lat: 48.869832, lng: 19.392858 },
    { lat: 48.870558, lng: 19.392131 },
    { lat: 48.871007, lng: 19.391681 },
    { lat: 48.871663, lng: 19.390762 },
    { lat: 48.871888, lng: 19.390486 },
    { lat: 48.87242, lng: 19.389834 },
    { lat: 48.873214, lng: 19.388523 },
    { lat: 48.873602, lng: 19.387755 },
    { lat: 48.873983, lng: 19.387306 },
    { lat: 48.874608, lng: 19.386568 },
    { lat: 48.874742, lng: 19.386349 },
    { lat: 48.874899, lng: 19.386093 },
    { lat: 48.875556, lng: 19.385413 },
    { lat: 48.876067, lng: 19.384983 },
    { lat: 48.877352, lng: 19.384959 },
    { lat: 48.878904, lng: 19.385175 },
    { lat: 48.880721, lng: 19.385137 },
    { lat: 48.881992, lng: 19.384932 },
    { lat: 48.881904, lng: 19.383876 },
    { lat: 48.881847, lng: 19.383181 },
    { lat: 48.882, lng: 19.382246 },
    { lat: 48.882142, lng: 19.381378 },
    { lat: 48.882322, lng: 19.379233 },
    { lat: 48.882419, lng: 19.378089 },
    { lat: 48.882648, lng: 19.37785 },
    { lat: 48.883272, lng: 19.377201 },
    { lat: 48.883903, lng: 19.376177 },
    { lat: 48.884059, lng: 19.375791 },
    { lat: 48.885015, lng: 19.373435 },
    { lat: 48.885456, lng: 19.372348 },
    { lat: 48.885455, lng: 19.371639 },
    { lat: 48.885456, lng: 19.370789 },
    { lat: 48.885455, lng: 19.365524 },
    { lat: 48.885361, lng: 19.363558 },
  ],
  Hronsek: [
    { lat: 48.6631908, lng: 19.1945121 },
    { lat: 48.6638144, lng: 19.1938943 },
    { lat: 48.6639341, lng: 19.1935785 },
    { lat: 48.6637377, lng: 19.1929971 },
    { lat: 48.6636985, lng: 19.1920796 },
    { lat: 48.6635702, lng: 19.1915509 },
    { lat: 48.6635601, lng: 19.1907935 },
    { lat: 48.6636466, lng: 19.1901634 },
    { lat: 48.6651809, lng: 19.1868036 },
    { lat: 48.6665663, lng: 19.1848921 },
    { lat: 48.6669022, lng: 19.1830936 },
    { lat: 48.6671617, lng: 19.1822227 },
    { lat: 48.6675554, lng: 19.1813582 },
    { lat: 48.6704873, lng: 19.1851304 },
    { lat: 48.670219, lng: 19.1680682 },
    { lat: 48.66036, lng: 19.1675162 },
    { lat: 48.6565959, lng: 19.1641188 },
    { lat: 48.6564324, lng: 19.1634967 },
    { lat: 48.6563844, lng: 19.1635197 },
    { lat: 48.6563298, lng: 19.1635435 },
    { lat: 48.6562881, lng: 19.1631082 },
    { lat: 48.6563075, lng: 19.1614185 },
    { lat: 48.6563938, lng: 19.1603776 },
    { lat: 48.6572653, lng: 19.1579246 },
    { lat: 48.6568955, lng: 19.1569627 },
    { lat: 48.6569852, lng: 19.1558512 },
    { lat: 48.6572677, lng: 19.1556598 },
    { lat: 48.6566593, lng: 19.1536275 },
    { lat: 48.6573129, lng: 19.1532804 },
    { lat: 48.6569969, lng: 19.1512335 },
    { lat: 48.6567789, lng: 19.1505392 },
    { lat: 48.6567707, lng: 19.1505123 },
    { lat: 48.6566787, lng: 19.1502705 },
    { lat: 48.6564602, lng: 19.1489473 },
    { lat: 48.6560483, lng: 19.1502867 },
    { lat: 48.6558108, lng: 19.1504086 },
    { lat: 48.6558347, lng: 19.1505286 },
    { lat: 48.6556351, lng: 19.1506872 },
    { lat: 48.6554979, lng: 19.1510156 },
    { lat: 48.6550954, lng: 19.151125 },
    { lat: 48.6548172, lng: 19.1510642 },
    { lat: 48.65471, lng: 19.151104 },
    { lat: 48.6541941, lng: 19.1516643 },
    { lat: 48.6533848, lng: 19.1517123 },
    { lat: 48.6533505, lng: 19.1521583 },
    { lat: 48.6519883, lng: 19.1520355 },
    { lat: 48.6517361, lng: 19.1521098 },
    { lat: 48.6513462, lng: 19.1524324 },
    { lat: 48.65067, lng: 19.1528041 },
    { lat: 48.6498532, lng: 19.1531118 },
    { lat: 48.6489483, lng: 19.1536458 },
    { lat: 48.6484616, lng: 19.1537634 },
    { lat: 48.6483494, lng: 19.1534773 },
    { lat: 48.6472014, lng: 19.1530132 },
    { lat: 48.647172, lng: 19.1527563 },
    { lat: 48.6465115, lng: 19.1526938 },
    { lat: 48.6460895, lng: 19.1524553 },
    { lat: 48.6452537, lng: 19.151276 },
    { lat: 48.6449465, lng: 19.1510617 },
    { lat: 48.6441869, lng: 19.1502404 },
    { lat: 48.6440053, lng: 19.1499631 },
    { lat: 48.6437898, lng: 19.1494341 },
    { lat: 48.6433865, lng: 19.1493232 },
    { lat: 48.642994, lng: 19.1495744 },
    { lat: 48.6427489, lng: 19.1504103 },
    { lat: 48.640103, lng: 19.148922 },
    { lat: 48.640042, lng: 19.149128 },
    { lat: 48.640036, lng: 19.149148 },
    { lat: 48.640024, lng: 19.149191 },
    { lat: 48.639939, lng: 19.149485 },
    { lat: 48.639916, lng: 19.149564 },
    { lat: 48.639805, lng: 19.149946 },
    { lat: 48.639476, lng: 19.150593 },
    { lat: 48.639471, lng: 19.150733 },
    { lat: 48.639469, lng: 19.150802 },
    { lat: 48.639457, lng: 19.151137 },
    { lat: 48.639441, lng: 19.151641 },
    { lat: 48.639437, lng: 19.151771 },
    { lat: 48.639423, lng: 19.15203 },
    { lat: 48.639371, lng: 19.15257 },
    { lat: 48.639332, lng: 19.152772 },
    { lat: 48.63932, lng: 19.152957 },
    { lat: 48.6393, lng: 19.153075 },
    { lat: 48.639293, lng: 19.153108 },
    { lat: 48.639278, lng: 19.153167 },
    { lat: 48.639264, lng: 19.153234 },
    { lat: 48.639251, lng: 19.153291 },
    { lat: 48.639241, lng: 19.153337 },
    { lat: 48.639212, lng: 19.154611 },
    { lat: 48.63921, lng: 19.154818 },
    { lat: 48.639216, lng: 19.15502 },
    { lat: 48.639336, lng: 19.155965 },
    { lat: 48.639398, lng: 19.156447 },
    { lat: 48.639425, lng: 19.156697 },
    { lat: 48.639426, lng: 19.156702 },
    { lat: 48.639429, lng: 19.15673 },
    { lat: 48.639427, lng: 19.156781 },
    { lat: 48.639452, lng: 19.156865 },
    { lat: 48.639454, lng: 19.156967 },
    { lat: 48.639581, lng: 19.158101 },
    { lat: 48.639565, lng: 19.15839 },
    { lat: 48.639513, lng: 19.158376 },
    { lat: 48.639392, lng: 19.158499 },
    { lat: 48.639475, lng: 19.159074 },
    { lat: 48.63953, lng: 19.159667 },
    { lat: 48.639558, lng: 19.160316 },
    { lat: 48.639584, lng: 19.160968 },
    { lat: 48.639705, lng: 19.162226 },
    { lat: 48.639242, lng: 19.162375 },
    { lat: 48.639287, lng: 19.162421 },
    { lat: 48.639359, lng: 19.162573 },
    { lat: 48.639661, lng: 19.164178 },
    { lat: 48.639689, lng: 19.164482 },
    { lat: 48.639684, lng: 19.16523 },
    { lat: 48.639718, lng: 19.165609 },
    { lat: 48.63995, lng: 19.166727 },
    { lat: 48.639965, lng: 19.167541 },
    { lat: 48.640018, lng: 19.16785 },
    { lat: 48.640175, lng: 19.168458 },
    { lat: 48.640234, lng: 19.168781 },
    { lat: 48.640359, lng: 19.169732 },
    { lat: 48.640419, lng: 19.170161 },
    { lat: 48.640443, lng: 19.170336 },
    { lat: 48.640448, lng: 19.170372 },
    { lat: 48.640451, lng: 19.170394 },
    { lat: 48.640454, lng: 19.170427 },
    { lat: 48.640497, lng: 19.170922 },
    { lat: 48.640637, lng: 19.171523 },
    { lat: 48.640828, lng: 19.171414 },
    { lat: 48.641042, lng: 19.171738 },
    { lat: 48.640983, lng: 19.171895 },
    { lat: 48.64099, lng: 19.172394 },
    { lat: 48.640754, lng: 19.172951 },
    { lat: 48.640833, lng: 19.173682 },
    { lat: 48.640764, lng: 19.174123 },
    { lat: 48.640987, lng: 19.175289 },
    { lat: 48.641018, lng: 19.175844 },
    { lat: 48.641125, lng: 19.176075 },
    { lat: 48.641637, lng: 19.175988 },
    { lat: 48.641753, lng: 19.175971 },
    { lat: 48.641882, lng: 19.176086 },
    { lat: 48.642064, lng: 19.176238 },
    { lat: 48.642232, lng: 19.176345 },
    { lat: 48.642404, lng: 19.176488 },
    { lat: 48.642822, lng: 19.17685 },
    { lat: 48.64317, lng: 19.177114 },
    { lat: 48.643314, lng: 19.177235 },
    { lat: 48.643512, lng: 19.177492 },
    { lat: 48.643643, lng: 19.177735 },
    { lat: 48.643803, lng: 19.177975 },
    { lat: 48.643958, lng: 19.178159 },
    { lat: 48.644477, lng: 19.178648 },
    { lat: 48.644684, lng: 19.178814 },
    { lat: 48.644895, lng: 19.179013 },
    { lat: 48.645464, lng: 19.179677 },
    { lat: 48.64576, lng: 19.179965 },
    { lat: 48.645821, lng: 19.179997 },
    { lat: 48.646222, lng: 19.180245 },
    { lat: 48.646408, lng: 19.180282 },
    { lat: 48.646562, lng: 19.180434 },
    { lat: 48.646616, lng: 19.180587 },
    { lat: 48.646659, lng: 19.180726 },
    { lat: 48.646782, lng: 19.180959 },
    { lat: 48.646922, lng: 19.181179 },
    { lat: 48.647031, lng: 19.181301 },
    { lat: 48.647252, lng: 19.181463 },
    { lat: 48.647322, lng: 19.181516 },
    { lat: 48.647413, lng: 19.181609 },
    { lat: 48.647549, lng: 19.181762 },
    { lat: 48.647703, lng: 19.181989 },
    { lat: 48.647861, lng: 19.182199 },
    { lat: 48.648121, lng: 19.182375 },
    { lat: 48.648233, lng: 19.182487 },
    { lat: 48.648329, lng: 19.182622 },
    { lat: 48.648526, lng: 19.18272 },
    { lat: 48.648885, lng: 19.182973 },
    { lat: 48.649113, lng: 19.183158 },
    { lat: 48.649498, lng: 19.183583 },
    { lat: 48.649664, lng: 19.183722 },
    { lat: 48.649774, lng: 19.183904 },
    { lat: 48.649949, lng: 19.184461 },
    { lat: 48.650025, lng: 19.184767 },
    { lat: 48.650131, lng: 19.185126 },
    { lat: 48.650234, lng: 19.185342 },
    { lat: 48.65034, lng: 19.185562 },
    { lat: 48.650512, lng: 19.186114 },
    { lat: 48.650692, lng: 19.186323 },
    { lat: 48.65111, lng: 19.186957 },
    { lat: 48.65131, lng: 19.187238 },
    { lat: 48.651627, lng: 19.187472 },
    { lat: 48.651785, lng: 19.187771 },
    { lat: 48.651843, lng: 19.187909 },
    { lat: 48.651961, lng: 19.188283 },
    { lat: 48.652237, lng: 19.188738 },
    { lat: 48.652509, lng: 19.189118 },
    { lat: 48.652841, lng: 19.189507 },
    { lat: 48.652227, lng: 19.191613 },
    { lat: 48.652179, lng: 19.191863 },
    { lat: 48.652099, lng: 19.192189 },
    { lat: 48.65201, lng: 19.193105 },
    { lat: 48.65177, lng: 19.193363 },
    { lat: 48.651714, lng: 19.193525 },
    { lat: 48.651692, lng: 19.193784 },
    { lat: 48.651679, lng: 19.193934 },
    { lat: 48.651727, lng: 19.194309 },
    { lat: 48.651784, lng: 19.194566 },
    { lat: 48.651977, lng: 19.194469 },
    { lat: 48.652371, lng: 19.194274 },
    { lat: 48.652585, lng: 19.194188 },
    { lat: 48.652599, lng: 19.194183 },
    { lat: 48.652622, lng: 19.194103 },
    { lat: 48.652746, lng: 19.193866 },
    { lat: 48.652845, lng: 19.193726 },
    { lat: 48.653253, lng: 19.19277 },
    { lat: 48.653335, lng: 19.192668 },
    { lat: 48.653741, lng: 19.193275 },
    { lat: 48.653753, lng: 19.19329 },
    { lat: 48.654022, lng: 19.193603 },
    { lat: 48.654141, lng: 19.193683 },
    { lat: 48.654128, lng: 19.194142 },
    { lat: 48.654366, lng: 19.194189 },
    { lat: 48.654577, lng: 19.19418 },
    { lat: 48.654628, lng: 19.19456 },
    { lat: 48.65479, lng: 19.195588 },
    { lat: 48.654817, lng: 19.196111 },
    { lat: 48.654817, lng: 19.196121 },
    { lat: 48.654814, lng: 19.196164 },
    { lat: 48.654779, lng: 19.196723 },
    { lat: 48.654782, lng: 19.197024 },
    { lat: 48.654854, lng: 19.197299 },
    { lat: 48.654929, lng: 19.197348 },
    { lat: 48.654963, lng: 19.197371 },
    { lat: 48.655048, lng: 19.197429 },
    { lat: 48.65506, lng: 19.197436 },
    { lat: 48.655115, lng: 19.197526 },
    { lat: 48.655174, lng: 19.19754 },
    { lat: 48.655181, lng: 19.197542 },
    { lat: 48.655215, lng: 19.19755 },
    { lat: 48.655296, lng: 19.197569 },
    { lat: 48.655666, lng: 19.197718 },
    { lat: 48.65573, lng: 19.197743 },
    { lat: 48.656328, lng: 19.197917 },
    { lat: 48.656751, lng: 19.198021 },
    { lat: 48.65702, lng: 19.197996 },
    { lat: 48.65723, lng: 19.197946 },
    { lat: 48.65748, lng: 19.19786 },
    { lat: 48.657861, lng: 19.199258 },
    { lat: 48.657861, lng: 19.199328 },
    { lat: 48.657882, lng: 19.199389 },
    { lat: 48.657926, lng: 19.199496 },
    { lat: 48.65832, lng: 19.20044 },
    { lat: 48.658542, lng: 19.200973 },
    { lat: 48.658617, lng: 19.201157 },
    { lat: 48.6594242, lng: 19.2006893 },
    { lat: 48.660303, lng: 19.1995258 },
    { lat: 48.660781, lng: 19.198231 },
    { lat: 48.6609414, lng: 19.1975493 },
    { lat: 48.6615767, lng: 19.195972 },
    { lat: 48.6619125, lng: 19.1961286 },
    { lat: 48.6627636, lng: 19.1950907 },
    { lat: 48.6631908, lng: 19.1945121 },
  ],
  Kordíky: [
    { lat: 48.7634651, lng: 19.0441406 },
    { lat: 48.7635517, lng: 19.0438894 },
    { lat: 48.7636846, lng: 19.043886 },
    { lat: 48.7639394, lng: 19.0434126 },
    { lat: 48.7640809, lng: 19.0432656 },
    { lat: 48.7641865, lng: 19.0433285 },
    { lat: 48.7643324, lng: 19.0432682 },
    { lat: 48.7643774, lng: 19.0429736 },
    { lat: 48.7647627, lng: 19.0427668 },
    { lat: 48.7648748, lng: 19.0426425 },
    { lat: 48.7649473, lng: 19.042424 },
    { lat: 48.7649091, lng: 19.0422894 },
    { lat: 48.765401, lng: 19.0420071 },
    { lat: 48.7656548, lng: 19.0416446 },
    { lat: 48.7660228, lng: 19.0416578 },
    { lat: 48.7662006, lng: 19.0413358 },
    { lat: 48.7668827, lng: 19.0406822 },
    { lat: 48.7669627, lng: 19.0406609 },
    { lat: 48.7674406, lng: 19.0399631 },
    { lat: 48.7681352, lng: 19.0396258 },
    { lat: 48.7683843, lng: 19.0393549 },
    { lat: 48.7685882, lng: 19.0393079 },
    { lat: 48.7687868, lng: 19.0393566 },
    { lat: 48.769099, lng: 19.0392651 },
    { lat: 48.7697642, lng: 19.0392638 },
    { lat: 48.7700029, lng: 19.0393279 },
    { lat: 48.7704284, lng: 19.0390447 },
    { lat: 48.770534, lng: 19.0390604 },
    { lat: 48.7706901, lng: 19.0389785 },
    { lat: 48.7714214, lng: 19.039197 },
    { lat: 48.7718928, lng: 19.0394493 },
    { lat: 48.7722435, lng: 19.0395366 },
    { lat: 48.7725528, lng: 19.0394332 },
    { lat: 48.7727911, lng: 19.039239 },
    { lat: 48.7730963, lng: 19.0390967 },
    { lat: 48.7730721, lng: 19.0394248 },
    { lat: 48.7729875, lng: 19.0396275 },
    { lat: 48.7725323, lng: 19.0403542 },
    { lat: 48.772571, lng: 19.0407397 },
    { lat: 48.772964, lng: 19.0409126 },
    { lat: 48.773261, lng: 19.0412614 },
    { lat: 48.7734418, lng: 19.0412925 },
    { lat: 48.7737572, lng: 19.041623 },
    { lat: 48.7738871, lng: 19.0419648 },
    { lat: 48.7741174, lng: 19.0417722 },
    { lat: 48.7742576, lng: 19.0417322 },
    { lat: 48.7744283, lng: 19.0417859 },
    { lat: 48.7745169, lng: 19.0418476 },
    { lat: 48.7745152, lng: 19.0419693 },
    { lat: 48.7746424, lng: 19.0424154 },
    { lat: 48.7747506, lng: 19.0424624 },
    { lat: 48.7752434, lng: 19.042235 },
    { lat: 48.7754804, lng: 19.041745 },
    { lat: 48.7758146, lng: 19.0418235 },
    { lat: 48.7766592, lng: 19.0418303 },
    { lat: 48.7767304, lng: 19.0419441 },
    { lat: 48.7778109, lng: 19.0422687 },
    { lat: 48.7779103, lng: 19.0424518 },
    { lat: 48.7780296, lng: 19.0422912 },
    { lat: 48.7785951, lng: 19.0437238 },
    { lat: 48.7788392, lng: 19.0437591 },
    { lat: 48.7789759, lng: 19.0433613 },
    { lat: 48.7790794, lng: 19.0435117 },
    { lat: 48.7791746, lng: 19.0438364 },
    { lat: 48.7793582, lng: 19.0440127 },
    { lat: 48.7794614, lng: 19.0439784 },
    { lat: 48.779495, lng: 19.043706 },
    { lat: 48.7798111, lng: 19.0430828 },
    { lat: 48.7798742, lng: 19.0431078 },
    { lat: 48.7800969, lng: 19.0426686 },
    { lat: 48.7803738, lng: 19.0425929 },
    { lat: 48.7805136, lng: 19.0424786 },
    { lat: 48.7809516, lng: 19.0424226 },
    { lat: 48.7812178, lng: 19.0422702 },
    { lat: 48.7821549, lng: 19.0423215 },
    { lat: 48.7824837, lng: 19.0428722 },
    { lat: 48.7825595, lng: 19.0431263 },
    { lat: 48.7827473, lng: 19.0431641 },
    { lat: 48.7829643, lng: 19.0429923 },
    { lat: 48.7831166, lng: 19.0429512 },
    { lat: 48.7832721, lng: 19.0430142 },
    { lat: 48.7834249, lng: 19.0431833 },
    { lat: 48.7835305, lng: 19.0431551 },
    { lat: 48.7837376, lng: 19.0434173 },
    { lat: 48.7839971, lng: 19.0434673 },
    { lat: 48.7844964, lng: 19.0440968 },
    { lat: 48.7846699, lng: 19.0445653 },
    { lat: 48.784663, lng: 19.0447448 },
    { lat: 48.7848001, lng: 19.0449393 },
    { lat: 48.7848615, lng: 19.0451507 },
    { lat: 48.7849221, lng: 19.0458899 },
    { lat: 48.7850751, lng: 19.0463806 },
    { lat: 48.7851061, lng: 19.0464801 },
    { lat: 48.7849574, lng: 19.0476523 },
    { lat: 48.7849587, lng: 19.0479235 },
    { lat: 48.7847022, lng: 19.04972 },
    { lat: 48.7848134, lng: 19.0499586 },
    { lat: 48.785103, lng: 19.0510347 },
    { lat: 48.7852229, lng: 19.0512684 },
    { lat: 48.7853139, lng: 19.0530891 },
    { lat: 48.7850822, lng: 19.0536616 },
    { lat: 48.7850918, lng: 19.0543744 },
    { lat: 48.7851684, lng: 19.0546213 },
    { lat: 48.7854603, lng: 19.0549822 },
    { lat: 48.7855139, lng: 19.0551294 },
    { lat: 48.7857721, lng: 19.0551753 },
    { lat: 48.7860182, lng: 19.0550478 },
    { lat: 48.7862422, lng: 19.0550393 },
    { lat: 48.7873035, lng: 19.0550737 },
    { lat: 48.7876992, lng: 19.0552354 },
    { lat: 48.7879872, lng: 19.0554488 },
    { lat: 48.7885095, lng: 19.0560158 },
    { lat: 48.7889183, lng: 19.0558517 },
    { lat: 48.789485, lng: 19.056045 },
    { lat: 48.7899558, lng: 19.056422 },
    { lat: 48.7901137, lng: 19.0568737 },
    { lat: 48.7902324, lng: 19.0574015 },
    { lat: 48.790264, lng: 19.0576506 },
    { lat: 48.7905262, lng: 19.0575928 },
    { lat: 48.7910457, lng: 19.0559866 },
    { lat: 48.7911037, lng: 19.0535622 },
    { lat: 48.7909092, lng: 19.0518786 },
    { lat: 48.7909093, lng: 19.0508003 },
    { lat: 48.7908541, lng: 19.0498991 },
    { lat: 48.7909315, lng: 19.0494859 },
    { lat: 48.790918, lng: 19.0480694 },
    { lat: 48.7912976, lng: 19.0452885 },
    { lat: 48.7913026, lng: 19.0442653 },
    { lat: 48.7909517, lng: 19.0432197 },
    { lat: 48.7908084, lng: 19.0420287 },
    { lat: 48.7910508, lng: 19.0410144 },
    { lat: 48.7910382, lng: 19.0408771 },
    { lat: 48.7911251, lng: 19.0405181 },
    { lat: 48.791136, lng: 19.0402823 },
    { lat: 48.7910201, lng: 19.039144 },
    { lat: 48.790746, lng: 19.0384167 },
    { lat: 48.7906673, lng: 19.0370299 },
    { lat: 48.7903891, lng: 19.0366739 },
    { lat: 48.7903131, lng: 19.0362836 },
    { lat: 48.7896407, lng: 19.0348555 },
    { lat: 48.7895884, lng: 19.0338129 },
    { lat: 48.7894592, lng: 19.0335731 },
    { lat: 48.7896729, lng: 19.0308262 },
    { lat: 48.7900922, lng: 19.0293604 },
    { lat: 48.7904906, lng: 19.0285155 },
    { lat: 48.7905441, lng: 19.0276948 },
    { lat: 48.7908811, lng: 19.0270185 },
    { lat: 48.7916868, lng: 19.0263402 },
    { lat: 48.7921287, lng: 19.0271043 },
    { lat: 48.7922734, lng: 19.0268852 },
    { lat: 48.7924031, lng: 19.0268192 },
    { lat: 48.7928129, lng: 19.0268983 },
    { lat: 48.7929676, lng: 19.0271941 },
    { lat: 48.7934308, lng: 19.0274376 },
    { lat: 48.7938377, lng: 19.0279086 },
    { lat: 48.7940012, lng: 19.0283382 },
    { lat: 48.7944101, lng: 19.028195 },
    { lat: 48.7946544, lng: 19.028252 },
    { lat: 48.7947627, lng: 19.028212 },
    { lat: 48.7948732, lng: 19.027807 },
    { lat: 48.7947609, lng: 19.0277248 },
    { lat: 48.7947165, lng: 19.0273823 },
    { lat: 48.794815, lng: 19.0271422 },
    { lat: 48.7947504, lng: 19.0267759 },
    { lat: 48.7948542, lng: 19.0266006 },
    { lat: 48.7943115, lng: 19.0262314 },
    { lat: 48.794155, lng: 19.0257173 },
    { lat: 48.7941515, lng: 19.0251996 },
    { lat: 48.7938523, lng: 19.0237544 },
    { lat: 48.7933104, lng: 19.021766 },
    { lat: 48.7929339, lng: 19.0212276 },
    { lat: 48.7924448, lng: 19.0202205 },
    { lat: 48.7920502, lng: 19.0195941 },
    { lat: 48.791713, lng: 19.0192546 },
    { lat: 48.7915557, lng: 19.0189313 },
    { lat: 48.791011, lng: 19.0172223 },
    { lat: 48.7908318, lng: 19.0163934 },
    { lat: 48.790654, lng: 19.015972 },
    { lat: 48.790261, lng: 19.016576 },
    { lat: 48.789556, lng: 19.016163 },
    { lat: 48.789491, lng: 19.016164 },
    { lat: 48.78943, lng: 19.016166 },
    { lat: 48.788547, lng: 19.016188 },
    { lat: 48.787912, lng: 19.016337 },
    { lat: 48.78737, lng: 19.015316 },
    { lat: 48.786711, lng: 19.014542 },
    { lat: 48.786515, lng: 19.014253 },
    { lat: 48.786493, lng: 19.014221 },
    { lat: 48.785474, lng: 19.013824 },
    { lat: 48.785034, lng: 19.013865 },
    { lat: 48.784947, lng: 19.013873 },
    { lat: 48.78463, lng: 19.013904 },
    { lat: 48.783716, lng: 19.014186 },
    { lat: 48.782993, lng: 19.014382 },
    { lat: 48.782841, lng: 19.014423 },
    { lat: 48.781552, lng: 19.014449 },
    { lat: 48.781249, lng: 19.014309 },
    { lat: 48.780899, lng: 19.014141 },
    { lat: 48.780518, lng: 19.013994 },
    { lat: 48.780303, lng: 19.013911 },
    { lat: 48.779408, lng: 19.013552 },
    { lat: 48.778427, lng: 19.01319 },
    { lat: 48.778054, lng: 19.013016 },
    { lat: 48.777552, lng: 19.012779 },
    { lat: 48.777148, lng: 19.012588 },
    { lat: 48.777079, lng: 19.012529 },
    { lat: 48.776568, lng: 19.012087 },
    { lat: 48.776532, lng: 19.012057 },
    { lat: 48.776207, lng: 19.011957 },
    { lat: 48.775792, lng: 19.011616 },
    { lat: 48.775082, lng: 19.011433 },
    { lat: 48.774972, lng: 19.011336 },
    { lat: 48.774816, lng: 19.011198 },
    { lat: 48.774305, lng: 19.010746 },
    { lat: 48.773559, lng: 19.010628 },
    { lat: 48.773143, lng: 19.010646 },
    { lat: 48.772634, lng: 19.010743 },
    { lat: 48.77189, lng: 19.011012 },
    { lat: 48.771537, lng: 19.011176 },
    { lat: 48.771254, lng: 19.011308 },
    { lat: 48.770958, lng: 19.011293 },
    { lat: 48.770273, lng: 19.011251 },
    { lat: 48.770209, lng: 19.011247 },
    { lat: 48.770149, lng: 19.011243 },
    { lat: 48.770007, lng: 19.011235 },
    { lat: 48.76965, lng: 19.011327 },
    { lat: 48.769562, lng: 19.01135 },
    { lat: 48.76932, lng: 19.011412 },
    { lat: 48.768997, lng: 19.011503 },
    { lat: 48.768665, lng: 19.011782 },
    { lat: 48.768125, lng: 19.011937 },
    { lat: 48.767939, lng: 19.012064 },
    { lat: 48.767781, lng: 19.012173 },
    { lat: 48.767365, lng: 19.011746 },
    { lat: 48.766955, lng: 19.011214 },
    { lat: 48.766215, lng: 19.01078 },
    { lat: 48.76606, lng: 19.01069 },
    { lat: 48.765863, lng: 19.010583 },
    { lat: 48.765048, lng: 19.01088 },
    { lat: 48.764286, lng: 19.010767 },
    { lat: 48.764111, lng: 19.010691 },
    { lat: 48.764051, lng: 19.010592 },
    { lat: 48.763685, lng: 19.00999 },
    { lat: 48.763273, lng: 19.009485 },
    { lat: 48.76307, lng: 19.008008 },
    { lat: 48.763033, lng: 19.007556 },
    { lat: 48.762857, lng: 19.007021 },
    { lat: 48.762303, lng: 19.006399 },
    { lat: 48.762075, lng: 19.006358 },
    { lat: 48.762051, lng: 19.006354 },
    { lat: 48.761153, lng: 19.005902 },
    { lat: 48.761105, lng: 19.005879 },
    { lat: 48.760812, lng: 19.005732 },
    { lat: 48.760473, lng: 19.0054 },
    { lat: 48.759659, lng: 19.005049 },
    { lat: 48.759157, lng: 19.004726 },
    { lat: 48.758579, lng: 19.004576 },
    { lat: 48.758203, lng: 19.004342 },
    { lat: 48.757709, lng: 19.00435 },
    { lat: 48.757267, lng: 19.004095 },
    { lat: 48.756925, lng: 19.003839 },
    { lat: 48.756742, lng: 19.003819 },
    { lat: 48.756514, lng: 19.003793 },
    { lat: 48.756309, lng: 19.003777 },
    { lat: 48.7562288, lng: 19.0045111 },
    { lat: 48.7559636, lng: 19.0069392 },
    { lat: 48.755616, lng: 19.0101221 },
    { lat: 48.7553109, lng: 19.0116308 },
    { lat: 48.7549973, lng: 19.0148508 },
    { lat: 48.7549634, lng: 19.0161246 },
    { lat: 48.7550911, lng: 19.017473 },
    { lat: 48.7550901, lng: 19.0180355 },
    { lat: 48.7552639, lng: 19.0193153 },
    { lat: 48.7554099, lng: 19.0199528 },
    { lat: 48.7554198, lng: 19.0205773 },
    { lat: 48.7559875, lng: 19.0217322 },
    { lat: 48.7561473, lng: 19.022467 },
    { lat: 48.7561793, lng: 19.0233948 },
    { lat: 48.7561183, lng: 19.0238635 },
    { lat: 48.7563207, lng: 19.0255128 },
    { lat: 48.756519, lng: 19.0260452 },
    { lat: 48.7568326, lng: 19.0266577 },
    { lat: 48.7572045, lng: 19.0271006 },
    { lat: 48.7570014, lng: 19.0279311 },
    { lat: 48.7571248, lng: 19.0285908 },
    { lat: 48.7570131, lng: 19.0304495 },
    { lat: 48.756755, lng: 19.0316658 },
    { lat: 48.7568158, lng: 19.0319339 },
    { lat: 48.7565625, lng: 19.0329038 },
    { lat: 48.756483, lng: 19.0346462 },
    { lat: 48.7565429, lng: 19.0349845 },
    { lat: 48.7566437, lng: 19.0351984 },
    { lat: 48.7567913, lng: 19.0365446 },
    { lat: 48.7565977, lng: 19.036754 },
    { lat: 48.7565946, lng: 19.0372753 },
    { lat: 48.7564888, lng: 19.0382658 },
    { lat: 48.7562177, lng: 19.038885 },
    { lat: 48.7561471, lng: 19.039413 },
    { lat: 48.756289, lng: 19.0398123 },
    { lat: 48.7561116, lng: 19.0410192 },
    { lat: 48.7556634, lng: 19.0418867 },
    { lat: 48.755487, lng: 19.0418726 },
    { lat: 48.7554102, lng: 19.0425696 },
    { lat: 48.7553203, lng: 19.0425531 },
    { lat: 48.7551333, lng: 19.0428725 },
    { lat: 48.7551563, lng: 19.0431049 },
    { lat: 48.7551054, lng: 19.0433709 },
    { lat: 48.7547088, lng: 19.044366 },
    { lat: 48.754299, lng: 19.0449621 },
    { lat: 48.7539381, lng: 19.0465584 },
    { lat: 48.7540026, lng: 19.0467429 },
    { lat: 48.7539384, lng: 19.0479139 },
    { lat: 48.7540352, lng: 19.0481572 },
    { lat: 48.7537912, lng: 19.0490744 },
    { lat: 48.7538616, lng: 19.0503437 },
    { lat: 48.7541519, lng: 19.0513255 },
    { lat: 48.7542859, lng: 19.0522666 },
    { lat: 48.7544011, lng: 19.0525436 },
    { lat: 48.754205, lng: 19.0529494 },
    { lat: 48.7541645, lng: 19.0542595 },
    { lat: 48.7542329, lng: 19.0547015 },
    { lat: 48.7541975, lng: 19.0549621 },
    { lat: 48.7542351, lng: 19.0553305 },
    { lat: 48.754391, lng: 19.0556331 },
    { lat: 48.7546862, lng: 19.0565959 },
    { lat: 48.7551005, lng: 19.0569247 },
    { lat: 48.7553186, lng: 19.0568644 },
    { lat: 48.7553349, lng: 19.0565385 },
    { lat: 48.7552088, lng: 19.0561574 },
    { lat: 48.7556424, lng: 19.0553353 },
    { lat: 48.7556523, lng: 19.0549948 },
    { lat: 48.7557498, lng: 19.0548482 },
    { lat: 48.7556738, lng: 19.0539136 },
    { lat: 48.7559513, lng: 19.0535574 },
    { lat: 48.7559093, lng: 19.0533461 },
    { lat: 48.7558975, lng: 19.0532005 },
    { lat: 48.7558849, lng: 19.0531084 },
    { lat: 48.7558301, lng: 19.0525917 },
    { lat: 48.7559005, lng: 19.0525188 },
    { lat: 48.7559654, lng: 19.0523521 },
    { lat: 48.7559866, lng: 19.0521003 },
    { lat: 48.7565132, lng: 19.0514487 },
    { lat: 48.757688, lng: 19.049602 },
    { lat: 48.7580746, lng: 19.0484307 },
    { lat: 48.758283, lng: 19.0484269 },
    { lat: 48.7585727, lng: 19.0477298 },
    { lat: 48.758891, lng: 19.0475747 },
    { lat: 48.7592415, lng: 19.0469225 },
    { lat: 48.7593556, lng: 19.0465453 },
    { lat: 48.7595392, lng: 19.0461827 },
    { lat: 48.7599047, lng: 19.0458478 },
    { lat: 48.7600493, lng: 19.0458523 },
    { lat: 48.7601802, lng: 19.0456497 },
    { lat: 48.7604143, lng: 19.0454657 },
    { lat: 48.7610468, lng: 19.0452642 },
    { lat: 48.7618736, lng: 19.0446642 },
    { lat: 48.7619369, lng: 19.0446877 },
    { lat: 48.7620809, lng: 19.044574 },
    { lat: 48.7622647, lng: 19.0447059 },
    { lat: 48.7624908, lng: 19.0445395 },
    { lat: 48.7627343, lng: 19.0441993 },
    { lat: 48.7628461, lng: 19.0441576 },
    { lat: 48.7629747, lng: 19.0442725 },
    { lat: 48.7631244, lng: 19.0442911 },
    { lat: 48.7633244, lng: 19.044256 },
    { lat: 48.7634651, lng: 19.0441406 },
  ],
  Čerín: [
    { lat: 48.6606332, lng: 19.2828768 },
    { lat: 48.660856, lng: 19.2829418 },
    { lat: 48.6610672, lng: 19.2830916 },
    { lat: 48.6614579, lng: 19.2828452 },
    { lat: 48.6622918, lng: 19.2820681 },
    { lat: 48.6631851, lng: 19.2818468 },
    { lat: 48.663829, lng: 19.2819032 },
    { lat: 48.6641627, lng: 19.2823494 },
    { lat: 48.6643631, lng: 19.2827874 },
    { lat: 48.6647668, lng: 19.2832159 },
    { lat: 48.6647483, lng: 19.2826649 },
    { lat: 48.6646994, lng: 19.2823404 },
    { lat: 48.6646407, lng: 19.2819504 },
    { lat: 48.6646435, lng: 19.2817366 },
    { lat: 48.6646452, lng: 19.2816008 },
    { lat: 48.6646934, lng: 19.2814851 },
    { lat: 48.6650555, lng: 19.2806152 },
    { lat: 48.6652861, lng: 19.2797879 },
    { lat: 48.6657623, lng: 19.2791203 },
    { lat: 48.6666218, lng: 19.2777505 },
    { lat: 48.6671261, lng: 19.2771039 },
    { lat: 48.6673915, lng: 19.2755236 },
    { lat: 48.6676905, lng: 19.2746642 },
    { lat: 48.66798, lng: 19.2743293 },
    { lat: 48.6682274, lng: 19.2741545 },
    { lat: 48.6683255, lng: 19.273903 },
    { lat: 48.6683606, lng: 19.2733654 },
    { lat: 48.668164, lng: 19.2722993 },
    { lat: 48.6681712, lng: 19.2714937 },
    { lat: 48.6680715, lng: 19.2709198 },
    { lat: 48.668145, lng: 19.270461 },
    { lat: 48.6685399, lng: 19.2700674 },
    { lat: 48.6687123, lng: 19.2697878 },
    { lat: 48.6687014, lng: 19.2688123 },
    { lat: 48.6688152, lng: 19.2679011 },
    { lat: 48.6697841, lng: 19.2678909 },
    { lat: 48.6699747, lng: 19.2663058 },
    { lat: 48.6705876, lng: 19.2656425 },
    { lat: 48.6714826, lng: 19.2651789 },
    { lat: 48.6720107, lng: 19.2644433 },
    { lat: 48.6724546, lng: 19.2639476 },
    { lat: 48.6727861, lng: 19.2643943 },
    { lat: 48.6729643, lng: 19.2643232 },
    { lat: 48.6737515, lng: 19.2640092 },
    { lat: 48.6740628, lng: 19.2636854 },
    { lat: 48.6741093, lng: 19.2633868 },
    { lat: 48.673961, lng: 19.2629552 },
    { lat: 48.6739125, lng: 19.2628139 },
    { lat: 48.6738394, lng: 19.2624132 },
    { lat: 48.6738113, lng: 19.262259 },
    { lat: 48.6738278, lng: 19.2618894 },
    { lat: 48.6738612, lng: 19.261143 },
    { lat: 48.6747101, lng: 19.260307 },
    { lat: 48.6746202, lng: 19.2600804 },
    { lat: 48.6746702, lng: 19.2589868 },
    { lat: 48.6744927, lng: 19.2586771 },
    { lat: 48.6744639, lng: 19.2583597 },
    { lat: 48.674088, lng: 19.2578822 },
    { lat: 48.6740072, lng: 19.2576521 },
    { lat: 48.6740262, lng: 19.2572777 },
    { lat: 48.6737555, lng: 19.2568093 },
    { lat: 48.6737145, lng: 19.2562396 },
    { lat: 48.6733898, lng: 19.2555863 },
    { lat: 48.6734124, lng: 19.2553306 },
    { lat: 48.6733497, lng: 19.2548004 },
    { lat: 48.6734104, lng: 19.2547991 },
    { lat: 48.6734185, lng: 19.2547326 },
    { lat: 48.6733257, lng: 19.2545388 },
    { lat: 48.6734944, lng: 19.2544614 },
    { lat: 48.6737046, lng: 19.2546119 },
    { lat: 48.6737949, lng: 19.2549907 },
    { lat: 48.6739523, lng: 19.2553289 },
    { lat: 48.674069, lng: 19.2554562 },
    { lat: 48.6742012, lng: 19.2554641 },
    { lat: 48.6743348, lng: 19.255713 },
    { lat: 48.6744399, lng: 19.2557606 },
    { lat: 48.6746335, lng: 19.2556408 },
    { lat: 48.6747596, lng: 19.2554743 },
    { lat: 48.6752382, lng: 19.2556523 },
    { lat: 48.6752783, lng: 19.2556907 },
    { lat: 48.675264, lng: 19.2558135 },
    { lat: 48.6751917, lng: 19.2560201 },
    { lat: 48.6753153, lng: 19.256641 },
    { lat: 48.6757224, lng: 19.2569475 },
    { lat: 48.6759962, lng: 19.2572508 },
    { lat: 48.6762873, lng: 19.257371 },
    { lat: 48.6766202, lng: 19.2582181 },
    { lat: 48.6769451, lng: 19.2581338 },
    { lat: 48.6769684, lng: 19.2585236 },
    { lat: 48.6771994, lng: 19.2588445 },
    { lat: 48.6772733, lng: 19.2587911 },
    { lat: 48.677628, lng: 19.2588972 },
    { lat: 48.6782254, lng: 19.2589237 },
    { lat: 48.6796265, lng: 19.2597393 },
    { lat: 48.6800781, lng: 19.2595668 },
    { lat: 48.6801746, lng: 19.2593508 },
    { lat: 48.6803069, lng: 19.2584209 },
    { lat: 48.6808304, lng: 19.2577833 },
    { lat: 48.6809843, lng: 19.2571842 },
    { lat: 48.6810942, lng: 19.2570337 },
    { lat: 48.6813489, lng: 19.2563804 },
    { lat: 48.6819711, lng: 19.2564987 },
    { lat: 48.6820205, lng: 19.2560749 },
    { lat: 48.6821919, lng: 19.2555145 },
    { lat: 48.6822191, lng: 19.2552622 },
    { lat: 48.6824877, lng: 19.2552911 },
    { lat: 48.6832218, lng: 19.2551799 },
    { lat: 48.6832521, lng: 19.2545694 },
    { lat: 48.6839732, lng: 19.2544953 },
    { lat: 48.68433, lng: 19.254508 },
    { lat: 48.6848803, lng: 19.2546543 },
    { lat: 48.6850861, lng: 19.2550864 },
    { lat: 48.6852364, lng: 19.255118 },
    { lat: 48.6854306, lng: 19.2545661 },
    { lat: 48.6854849, lng: 19.2542662 },
    { lat: 48.6858874, lng: 19.2534067 },
    { lat: 48.6860886, lng: 19.2528092 },
    { lat: 48.6861255, lng: 19.2524783 },
    { lat: 48.6860762, lng: 19.2520903 },
    { lat: 48.6863399, lng: 19.2506889 },
    { lat: 48.6865145, lng: 19.2503824 },
    { lat: 48.6865566, lng: 19.2501352 },
    { lat: 48.6866242, lng: 19.2500632 },
    { lat: 48.6868659, lng: 19.2494433 },
    { lat: 48.6870863, lng: 19.2487565 },
    { lat: 48.6873884, lng: 19.2481987 },
    { lat: 48.6873817, lng: 19.2480604 },
    { lat: 48.6875484, lng: 19.247616 },
    { lat: 48.6878966, lng: 19.2472404 },
    { lat: 48.6878348, lng: 19.2470125 },
    { lat: 48.6879079, lng: 19.2467266 },
    { lat: 48.688099, lng: 19.2466698 },
    { lat: 48.6881769, lng: 19.246556 },
    { lat: 48.6881797, lng: 19.2464279 },
    { lat: 48.6884109, lng: 19.2461298 },
    { lat: 48.6886467, lng: 19.2454092 },
    { lat: 48.6890017, lng: 19.2446865 },
    { lat: 48.6899767, lng: 19.2432667 },
    { lat: 48.6900537, lng: 19.2432992 },
    { lat: 48.6905989, lng: 19.2429643 },
    { lat: 48.6915742, lng: 19.2418533 },
    { lat: 48.693539, lng: 19.2405772 },
    { lat: 48.6942238, lng: 19.2402604 },
    { lat: 48.6947064, lng: 19.2407044 },
    { lat: 48.6945073, lng: 19.2398762 },
    { lat: 48.6937272, lng: 19.2396684 },
    { lat: 48.6933599, lng: 19.239156 },
    { lat: 48.6931083, lng: 19.2395024 },
    { lat: 48.6923965, lng: 19.2393278 },
    { lat: 48.691886, lng: 19.239376 },
    { lat: 48.6915364, lng: 19.2391899 },
    { lat: 48.6914659, lng: 19.2391527 },
    { lat: 48.6884146, lng: 19.237896 },
    { lat: 48.6876813, lng: 19.237742 },
    { lat: 48.6871248, lng: 19.2374486 },
    { lat: 48.686825, lng: 19.2371696 },
    { lat: 48.6866852, lng: 19.2369121 },
    { lat: 48.6864882, lng: 19.2363874 },
    { lat: 48.686274, lng: 19.2359876 },
    { lat: 48.68621, lng: 19.2356219 },
    { lat: 48.685922, lng: 19.2350523 },
    { lat: 48.6856201, lng: 19.2347297 },
    { lat: 48.6853311, lng: 19.2341424 },
    { lat: 48.6850505, lng: 19.2338656 },
    { lat: 48.6846799, lng: 19.2337978 },
    { lat: 48.6839004, lng: 19.2342307 },
    { lat: 48.6830903, lng: 19.2340953 },
    { lat: 48.6826191, lng: 19.2341196 },
    { lat: 48.6821557, lng: 19.2339986 },
    { lat: 48.6817845, lng: 19.2338074 },
    { lat: 48.6812942, lng: 19.2337593 },
    { lat: 48.6808181, lng: 19.2339603 },
    { lat: 48.6802085, lng: 19.2341073 },
    { lat: 48.6798143, lng: 19.2342975 },
    { lat: 48.6797668, lng: 19.2341323 },
    { lat: 48.678693, lng: 19.2343745 },
    { lat: 48.6782944, lng: 19.2342891 },
    { lat: 48.6778306, lng: 19.2339316 },
    { lat: 48.6773798, lng: 19.2332328 },
    { lat: 48.6771313, lng: 19.2327803 },
    { lat: 48.676249, lng: 19.233089 },
    { lat: 48.6748667, lng: 19.2350562 },
    { lat: 48.6745069, lng: 19.2361453 },
    { lat: 48.6742846, lng: 19.2361656 },
    { lat: 48.6742468, lng: 19.236291 },
    { lat: 48.67409, lng: 19.2361019 },
    { lat: 48.6740658, lng: 19.235973 },
    { lat: 48.6739493, lng: 19.2359195 },
    { lat: 48.6738932, lng: 19.2359931 },
    { lat: 48.6737136, lng: 19.2359438 },
    { lat: 48.6733835, lng: 19.2356686 },
    { lat: 48.6732563, lng: 19.235714 },
    { lat: 48.6731404, lng: 19.2356716 },
    { lat: 48.6731238, lng: 19.2355889 },
    { lat: 48.6729421, lng: 19.2355376 },
    { lat: 48.6727716, lng: 19.2355782 },
    { lat: 48.6725158, lng: 19.2353694 },
    { lat: 48.672432, lng: 19.2351985 },
    { lat: 48.6722968, lng: 19.2352698 },
    { lat: 48.6722034, lng: 19.2351318 },
    { lat: 48.6718983, lng: 19.2350026 },
    { lat: 48.6716841, lng: 19.2345594 },
    { lat: 48.6715974, lng: 19.2345398 },
    { lat: 48.6712546, lng: 19.2341397 },
    { lat: 48.6711999, lng: 19.2341757 },
    { lat: 48.6711447, lng: 19.2341273 },
    { lat: 48.6710121, lng: 19.233791 },
    { lat: 48.6708667, lng: 19.2337433 },
    { lat: 48.6705615, lng: 19.233286 },
    { lat: 48.6703272, lng: 19.233355 },
    { lat: 48.6703077, lng: 19.2332548 },
    { lat: 48.6701524, lng: 19.233169 },
    { lat: 48.6700716, lng: 19.2329686 },
    { lat: 48.6699256, lng: 19.2329294 },
    { lat: 48.6698165, lng: 19.2327746 },
    { lat: 48.6696576, lng: 19.2327997 },
    { lat: 48.6696452, lng: 19.232428 },
    { lat: 48.6696846, lng: 19.2321694 },
    { lat: 48.6696564, lng: 19.2320063 },
    { lat: 48.6693226, lng: 19.2317038 },
    { lat: 48.6690575, lng: 19.2313283 },
    { lat: 48.6687441, lng: 19.2310713 },
    { lat: 48.6686828, lng: 19.2309219 },
    { lat: 48.6684403, lng: 19.2308706 },
    { lat: 48.6681984, lng: 19.230559 },
    { lat: 48.668059, lng: 19.2305616 },
    { lat: 48.6677548, lng: 19.2301941 },
    { lat: 48.6676222, lng: 19.2301824 },
    { lat: 48.6673601, lng: 19.2300088 },
    { lat: 48.6671984, lng: 19.2300642 },
    { lat: 48.666909, lng: 19.2299564 },
    { lat: 48.6669359, lng: 19.2297412 },
    { lat: 48.6668761, lng: 19.2295795 },
    { lat: 48.6668728, lng: 19.2290686 },
    { lat: 48.6668307, lng: 19.2289213 },
    { lat: 48.6665408, lng: 19.2286389 },
    { lat: 48.666487, lng: 19.228509 },
    { lat: 48.6664984, lng: 19.2283686 },
    { lat: 48.6663705, lng: 19.2282886 },
    { lat: 48.666412, lng: 19.2279135 },
    { lat: 48.6662902, lng: 19.2277302 },
    { lat: 48.6659268, lng: 19.2274652 },
    { lat: 48.6658359, lng: 19.2274706 },
    { lat: 48.6657856, lng: 19.2275493 },
    { lat: 48.6655744, lng: 19.2281889 },
    { lat: 48.6653695, lng: 19.2281053 },
    { lat: 48.6652158, lng: 19.2284668 },
    { lat: 48.6652412, lng: 19.2285945 },
    { lat: 48.665168, lng: 19.2287605 },
    { lat: 48.6649815, lng: 19.2288414 },
    { lat: 48.6648802, lng: 19.2287974 },
    { lat: 48.6646569, lng: 19.2289127 },
    { lat: 48.6643781, lng: 19.2293005 },
    { lat: 48.6641619, lng: 19.2293041 },
    { lat: 48.6640593, lng: 19.2292099 },
    { lat: 48.6638662, lng: 19.2291781 },
    { lat: 48.6637392, lng: 19.2294411 },
    { lat: 48.6636405, lng: 19.2295198 },
    { lat: 48.6635103, lng: 19.2295085 },
    { lat: 48.6634138, lng: 19.229824 },
    { lat: 48.6632546, lng: 19.2298188 },
    { lat: 48.6632007, lng: 19.2300077 },
    { lat: 48.6629811, lng: 19.2301729 },
    { lat: 48.6627476, lng: 19.2307254 },
    { lat: 48.6626278, lng: 19.2306038 },
    { lat: 48.6625803, lng: 19.230414 },
    { lat: 48.6622348, lng: 19.230079 },
    { lat: 48.6619231, lng: 19.2301341 },
    { lat: 48.6618032, lng: 19.2299171 },
    { lat: 48.6617381, lng: 19.2299004 },
    { lat: 48.6616226, lng: 19.2300059 },
    { lat: 48.6614788, lng: 19.2298082 },
    { lat: 48.6614053, lng: 19.229827 },
    { lat: 48.6612374, lng: 19.2296859 },
    { lat: 48.6611232, lng: 19.2294464 },
    { lat: 48.6611784, lng: 19.2293768 },
    { lat: 48.6611735, lng: 19.2293004 },
    { lat: 48.6610204, lng: 19.2291865 },
    { lat: 48.6609823, lng: 19.2290741 },
    { lat: 48.6608007, lng: 19.22905 },
    { lat: 48.6607726, lng: 19.2288666 },
    { lat: 48.6606735, lng: 19.2286829 },
    { lat: 48.6607266, lng: 19.2285223 },
    { lat: 48.6607034, lng: 19.2284382 },
    { lat: 48.6605557, lng: 19.2282759 },
    { lat: 48.6604032, lng: 19.228527 },
    { lat: 48.660274, lng: 19.2285364 },
    { lat: 48.6601756, lng: 19.2284542 },
    { lat: 48.6600993, lng: 19.2280957 },
    { lat: 48.6598885, lng: 19.227723 },
    { lat: 48.6598138, lng: 19.2279259 },
    { lat: 48.6595586, lng: 19.2280996 },
    { lat: 48.6593748, lng: 19.227613 },
    { lat: 48.6594772, lng: 19.2274016 },
    { lat: 48.6595042, lng: 19.2272571 },
    { lat: 48.6594662, lng: 19.2271507 },
    { lat: 48.6593139, lng: 19.2271034 },
    { lat: 48.6591322, lng: 19.2272387 },
    { lat: 48.659023, lng: 19.2267984 },
    { lat: 48.6588549, lng: 19.2266783 },
    { lat: 48.6588011, lng: 19.2267085 },
    { lat: 48.6586511, lng: 19.2264024 },
    { lat: 48.6585392, lng: 19.2263513 },
    { lat: 48.6586251, lng: 19.2261504 },
    { lat: 48.6585555, lng: 19.226059 },
    { lat: 48.6585418, lng: 19.22571 },
    { lat: 48.6582799, lng: 19.2251896 },
    { lat: 48.6582634, lng: 19.2251768 },
    { lat: 48.6582462, lng: 19.2251919 },
    { lat: 48.657643, lng: 19.2260734 },
    { lat: 48.6576246, lng: 19.2262972 },
    { lat: 48.6575414, lng: 19.2262757 },
    { lat: 48.6571614, lng: 19.2265784 },
    { lat: 48.6569775, lng: 19.2269215 },
    { lat: 48.6564475, lng: 19.2273787 },
    { lat: 48.6564212, lng: 19.2275385 },
    { lat: 48.6563322, lng: 19.2276076 },
    { lat: 48.6561392, lng: 19.2282279 },
    { lat: 48.6560488, lng: 19.2283167 },
    { lat: 48.6558658, lng: 19.2288625 },
    { lat: 48.6557649, lng: 19.2297824 },
    { lat: 48.6556918, lng: 19.2299691 },
    { lat: 48.6557096, lng: 19.2301268 },
    { lat: 48.6555668, lng: 19.2305526 },
    { lat: 48.6555202, lng: 19.2308466 },
    { lat: 48.6549111, lng: 19.2322299 },
    { lat: 48.6547018, lng: 19.2321714 },
    { lat: 48.6544408, lng: 19.2329208 },
    { lat: 48.6543684, lng: 19.2328453 },
    { lat: 48.6542075, lng: 19.233199 },
    { lat: 48.6538721, lng: 19.2336756 },
    { lat: 48.653285, lng: 19.2339366 },
    { lat: 48.6530591, lng: 19.2345292 },
    { lat: 48.6530091, lng: 19.2350074 },
    { lat: 48.6528865, lng: 19.2349921 },
    { lat: 48.6528687, lng: 19.2353463 },
    { lat: 48.6527589, lng: 19.2353805 },
    { lat: 48.652708, lng: 19.2357295 },
    { lat: 48.6525749, lng: 19.2357263 },
    { lat: 48.6525048, lng: 19.2358828 },
    { lat: 48.6523159, lng: 19.2360113 },
    { lat: 48.651887, lng: 19.2381096 },
    { lat: 48.6518534, lng: 19.2396965 },
    { lat: 48.6517138, lng: 19.2398378 },
    { lat: 48.6516373, lng: 19.2406084 },
    { lat: 48.6512843, lng: 19.2428387 },
    { lat: 48.6512347, lng: 19.2435347 },
    { lat: 48.651211, lng: 19.2436742 },
    { lat: 48.6511418, lng: 19.2436644 },
    { lat: 48.6510875, lng: 19.2442211 },
    { lat: 48.6511173, lng: 19.2442321 },
    { lat: 48.6504425, lng: 19.2482892 },
    { lat: 48.6504343, lng: 19.2485165 },
    { lat: 48.6502058, lng: 19.2488942 },
    { lat: 48.6497879, lng: 19.2504354 },
    { lat: 48.6494617, lng: 19.251334 },
    { lat: 48.6492352, lng: 19.2522704 },
    { lat: 48.6493374, lng: 19.252493 },
    { lat: 48.6493917, lng: 19.252825 },
    { lat: 48.6493186, lng: 19.2528772 },
    { lat: 48.6493251, lng: 19.253054 },
    { lat: 48.6494392, lng: 19.2532026 },
    { lat: 48.6493877, lng: 19.2534414 },
    { lat: 48.6493927, lng: 19.2537307 },
    { lat: 48.6494688, lng: 19.2538324 },
    { lat: 48.6494027, lng: 19.253936 },
    { lat: 48.6494058, lng: 19.2540928 },
    { lat: 48.6494922, lng: 19.2542045 },
    { lat: 48.649334, lng: 19.2546836 },
    { lat: 48.6494265, lng: 19.2550594 },
    { lat: 48.6493912, lng: 19.2552134 },
    { lat: 48.6494054, lng: 19.2553213 },
    { lat: 48.6495441, lng: 19.2555309 },
    { lat: 48.6495864, lng: 19.2562884 },
    { lat: 48.6497685, lng: 19.2571207 },
    { lat: 48.649875, lng: 19.2572472 },
    { lat: 48.6498551, lng: 19.2573717 },
    { lat: 48.6499452, lng: 19.2574858 },
    { lat: 48.6498735, lng: 19.2577768 },
    { lat: 48.6499564, lng: 19.2578603 },
    { lat: 48.6499804, lng: 19.2581229 },
    { lat: 48.6498855, lng: 19.2583961 },
    { lat: 48.6499408, lng: 19.2585843 },
    { lat: 48.6499278, lng: 19.2588284 },
    { lat: 48.6498255, lng: 19.259116 },
    { lat: 48.6498961, lng: 19.2593146 },
    { lat: 48.6498213, lng: 19.2595652 },
    { lat: 48.6498316, lng: 19.2597552 },
    { lat: 48.6497774, lng: 19.2598692 },
    { lat: 48.6497001, lng: 19.2598777 },
    { lat: 48.6496722, lng: 19.2599994 },
    { lat: 48.6497057, lng: 19.2601351 },
    { lat: 48.6495694, lng: 19.2605119 },
    { lat: 48.6495653, lng: 19.2606368 },
    { lat: 48.6497018, lng: 19.260779 },
    { lat: 48.6496414, lng: 19.2608826 },
    { lat: 48.6496555, lng: 19.2611498 },
    { lat: 48.6495328, lng: 19.261245 },
    { lat: 48.6495194, lng: 19.2617615 },
    { lat: 48.6493314, lng: 19.2624898 },
    { lat: 48.6493908, lng: 19.2626005 },
    { lat: 48.6493553, lng: 19.2629598 },
    { lat: 48.6494115, lng: 19.26319 },
    { lat: 48.6493723, lng: 19.2634791 },
    { lat: 48.6494095, lng: 19.2637595 },
    { lat: 48.6493397, lng: 19.264555 },
    { lat: 48.6493959, lng: 19.2648276 },
    { lat: 48.6493098, lng: 19.2649636 },
    { lat: 48.6493967, lng: 19.2652898 },
    { lat: 48.6493469, lng: 19.2655213 },
    { lat: 48.6494899, lng: 19.2658656 },
    { lat: 48.6494456, lng: 19.2659999 },
    { lat: 48.6494549, lng: 19.2663097 },
    { lat: 48.6495883, lng: 19.2666668 },
    { lat: 48.6495975, lng: 19.2673263 },
    { lat: 48.649645, lng: 19.267469 },
    { lat: 48.6495564, lng: 19.2676399 },
    { lat: 48.649779, lng: 19.2680735 },
    { lat: 48.6497327, lng: 19.2682351 },
    { lat: 48.649818, lng: 19.2684357 },
    { lat: 48.6497454, lng: 19.2686393 },
    { lat: 48.6497596, lng: 19.2693062 },
    { lat: 48.6498645, lng: 19.2694729 },
    { lat: 48.6498174, lng: 19.2695697 },
    { lat: 48.6498194, lng: 19.2697045 },
    { lat: 48.6499401, lng: 19.2697519 },
    { lat: 48.6500055, lng: 19.2699875 },
    { lat: 48.6498832, lng: 19.2704197 },
    { lat: 48.6498934, lng: 19.2710268 },
    { lat: 48.6498266, lng: 19.2711157 },
    { lat: 48.6498222, lng: 19.2712606 },
    { lat: 48.6498873, lng: 19.2713967 },
    { lat: 48.6498323, lng: 19.2715628 },
    { lat: 48.6499452, lng: 19.2719393 },
    { lat: 48.6499009, lng: 19.2721182 },
    { lat: 48.6499634, lng: 19.2725287 },
    { lat: 48.6499649, lng: 19.2729813 },
    { lat: 48.6500559, lng: 19.2733249 },
    { lat: 48.6500675, lng: 19.273777 },
    { lat: 48.6501218, lng: 19.2738256 },
    { lat: 48.650068, lng: 19.2740215 },
    { lat: 48.6500786, lng: 19.2742866 },
    { lat: 48.6499073, lng: 19.2744513 },
    { lat: 48.6498962, lng: 19.2746881 },
    { lat: 48.6499486, lng: 19.2747265 },
    { lat: 48.6499702, lng: 19.2749138 },
    { lat: 48.6502454, lng: 19.2750134 },
    { lat: 48.6503398, lng: 19.2752172 },
    { lat: 48.650334, lng: 19.2758463 },
    { lat: 48.6504925, lng: 19.2761166 },
    { lat: 48.6505256, lng: 19.2765251 },
    { lat: 48.6506637, lng: 19.2768165 },
    { lat: 48.6505647, lng: 19.2769386 },
    { lat: 48.6505569, lng: 19.2770314 },
    { lat: 48.6506392, lng: 19.2772455 },
    { lat: 48.6508644, lng: 19.2774486 },
    { lat: 48.6509783, lng: 19.2773808 },
    { lat: 48.6511695, lng: 19.2778885 },
    { lat: 48.6509183, lng: 19.2789459 },
    { lat: 48.650623, lng: 19.2789444 },
    { lat: 48.6504368, lng: 19.2791983 },
    { lat: 48.6503508, lng: 19.279894 },
    { lat: 48.6504087, lng: 19.2802972 },
    { lat: 48.6506244, lng: 19.280773 },
    { lat: 48.6505612, lng: 19.2812918 },
    { lat: 48.6505713, lng: 19.2823209 },
    { lat: 48.6509813, lng: 19.2821975 },
    { lat: 48.6516413, lng: 19.2817184 },
    { lat: 48.651981, lng: 19.2811151 },
    { lat: 48.6524725, lng: 19.2806491 },
    { lat: 48.6532864, lng: 19.2802319 },
    { lat: 48.6538569, lng: 19.2796938 },
    { lat: 48.6544772, lng: 19.2794693 },
    { lat: 48.654744, lng: 19.2792764 },
    { lat: 48.65567, lng: 19.2794743 },
    { lat: 48.6560738, lng: 19.2797138 },
    { lat: 48.6563992, lng: 19.2797747 },
    { lat: 48.6567393, lng: 19.2801238 },
    { lat: 48.6574166, lng: 19.2805423 },
    { lat: 48.6580974, lng: 19.2812662 },
    { lat: 48.6583163, lng: 19.2813627 },
    { lat: 48.6586615, lng: 19.281676 },
    { lat: 48.6589749, lng: 19.2817002 },
    { lat: 48.6593683, lng: 19.2818652 },
    { lat: 48.6595367, lng: 19.2818749 },
    { lat: 48.6598489, lng: 19.2822008 },
    { lat: 48.6600303, lng: 19.2824811 },
    { lat: 48.6606261, lng: 19.2828957 },
    { lat: 48.6606332, lng: 19.2828768 },
  ],
  SlovenskáĽupča: [
    { lat: 48.7682604, lng: 19.2300849 },
    { lat: 48.7678077, lng: 19.2303009 },
    { lat: 48.7663016, lng: 19.2311804 },
    { lat: 48.763949, lng: 19.2320175 },
    { lat: 48.7622519, lng: 19.2315358 },
    { lat: 48.7604162, lng: 19.2304538 },
    { lat: 48.76024, lng: 19.2304001 },
    { lat: 48.7590954, lng: 19.2287594 },
    { lat: 48.7585548, lng: 19.2273964 },
    { lat: 48.7581871, lng: 19.2255813 },
    { lat: 48.7580607, lng: 19.2236233 },
    { lat: 48.7580431, lng: 19.2226371 },
    { lat: 48.7579077, lng: 19.2215411 },
    { lat: 48.7552496, lng: 19.222395 },
    { lat: 48.7524352, lng: 19.223354 },
    { lat: 48.7479982, lng: 19.2262284 },
    { lat: 48.7478192, lng: 19.2264869 },
    { lat: 48.7471929, lng: 19.2269617 },
    { lat: 48.7470213, lng: 19.2269775 },
    { lat: 48.746951, lng: 19.2269217 },
    { lat: 48.7447385, lng: 19.229624 },
    { lat: 48.7443851, lng: 19.2306557 },
    { lat: 48.7441997, lng: 19.2310184 },
    { lat: 48.7445917, lng: 19.2318046 },
    { lat: 48.7449519, lng: 19.2330483 },
    { lat: 48.7448365, lng: 19.2340153 },
    { lat: 48.7446424, lng: 19.2349911 },
    { lat: 48.7443326, lng: 19.2358562 },
    { lat: 48.7433602, lng: 19.2380042 },
    { lat: 48.7429716, lng: 19.2389879 },
    { lat: 48.7428431, lng: 19.2396853 },
    { lat: 48.742853, lng: 19.2405206 },
    { lat: 48.7430424, lng: 19.2416607 },
    { lat: 48.7435021, lng: 19.2436409 },
    { lat: 48.7441461, lng: 19.2457679 },
    { lat: 48.7446407, lng: 19.2469304 },
    { lat: 48.745027, lng: 19.248277 },
    { lat: 48.7459484, lng: 19.2522271 },
    { lat: 48.7458878, lng: 19.2532867 },
    { lat: 48.7459303, lng: 19.2534423 },
    { lat: 48.7460716, lng: 19.2534982 },
    { lat: 48.7459575, lng: 19.2537233 },
    { lat: 48.7459473, lng: 19.2539126 },
    { lat: 48.7456651, lng: 19.2545309 },
    { lat: 48.7457066, lng: 19.254856 },
    { lat: 48.7455444, lng: 19.2548828 },
    { lat: 48.7455247, lng: 19.2549382 },
    { lat: 48.7456496, lng: 19.2552362 },
    { lat: 48.7456409, lng: 19.2556868 },
    { lat: 48.7455637, lng: 19.2560739 },
    { lat: 48.7455936, lng: 19.2563228 },
    { lat: 48.7454462, lng: 19.2563472 },
    { lat: 48.7454085, lng: 19.2564571 },
    { lat: 48.7454458, lng: 19.256623 },
    { lat: 48.7456358, lng: 19.2566842 },
    { lat: 48.7456392, lng: 19.2567731 },
    { lat: 48.745461, lng: 19.2570738 },
    { lat: 48.7455217, lng: 19.2573525 },
    { lat: 48.7456816, lng: 19.2574902 },
    { lat: 48.745676, lng: 19.2577462 },
    { lat: 48.7457804, lng: 19.2578782 },
    { lat: 48.7457883, lng: 19.2579738 },
    { lat: 48.7455769, lng: 19.2585232 },
    { lat: 48.7456172, lng: 19.259123 },
    { lat: 48.7454344, lng: 19.25928 },
    { lat: 48.7453899, lng: 19.2596695 },
    { lat: 48.7454251, lng: 19.2598853 },
    { lat: 48.7452508, lng: 19.2600327 },
    { lat: 48.7450737, lng: 19.2606355 },
    { lat: 48.7451238, lng: 19.2611171 },
    { lat: 48.7449091, lng: 19.2613604 },
    { lat: 48.7448146, lng: 19.2615512 },
    { lat: 48.7447576, lng: 19.2618439 },
    { lat: 48.7448242, lng: 19.2621037 },
    { lat: 48.7448235, lng: 19.2624627 },
    { lat: 48.7446479, lng: 19.2626992 },
    { lat: 48.744667, lng: 19.2635671 },
    { lat: 48.7445168, lng: 19.2640279 },
    { lat: 48.7443912, lng: 19.2642042 },
    { lat: 48.7443196, lng: 19.2646121 },
    { lat: 48.7438729, lng: 19.2646306 },
    { lat: 48.7436244, lng: 19.2649433 },
    { lat: 48.7435803, lng: 19.2650821 },
    { lat: 48.7433039, lng: 19.2652168 },
    { lat: 48.7430884, lng: 19.2655888 },
    { lat: 48.7425596, lng: 19.2656095 },
    { lat: 48.7423814, lng: 19.2655584 },
    { lat: 48.7422386, lng: 19.2654277 },
    { lat: 48.7436335, lng: 19.2719946 },
    { lat: 48.7434686, lng: 19.272853 },
    { lat: 48.7433609, lng: 19.2740116 },
    { lat: 48.7419491, lng: 19.2760821 },
    { lat: 48.7418263, lng: 19.2765291 },
    { lat: 48.7416901, lng: 19.277544 },
    { lat: 48.7414539, lng: 19.2782563 },
    { lat: 48.7409485, lng: 19.2787382 },
    { lat: 48.7403794, lng: 19.2788097 },
    { lat: 48.7403356, lng: 19.2801294 },
    { lat: 48.7404491, lng: 19.2808 },
    { lat: 48.7404609, lng: 19.2815633 },
    { lat: 48.7398759, lng: 19.2829256 },
    { lat: 48.7393503, lng: 19.283505 },
    { lat: 48.739148, lng: 19.2838194 },
    { lat: 48.7389906, lng: 19.2840641 },
    { lat: 48.7385239, lng: 19.2842524 },
    { lat: 48.737833, lng: 19.2850235 },
    { lat: 48.7373663, lng: 19.2853543 },
    { lat: 48.7367728, lng: 19.2854833 },
    { lat: 48.7363096, lng: 19.2858109 },
    { lat: 48.7357975, lng: 19.2858738 },
    { lat: 48.7352545, lng: 19.2868446 },
    { lat: 48.734554, lng: 19.2884986 },
    { lat: 48.734597, lng: 19.2894183 },
    { lat: 48.7345404, lng: 19.2896709 },
    { lat: 48.7346278, lng: 19.2898488 },
    { lat: 48.734356, lng: 19.291982 },
    { lat: 48.733732, lng: 19.2929541 },
    { lat: 48.7335541, lng: 19.2936859 },
    { lat: 48.7332893, lng: 19.2943269 },
    { lat: 48.7332026, lng: 19.2949858 },
    { lat: 48.7330303, lng: 19.2950575 },
    { lat: 48.7330548, lng: 19.2952387 },
    { lat: 48.7327513, lng: 19.295844 },
    { lat: 48.7323577, lng: 19.2962855 },
    { lat: 48.7323653, lng: 19.2967985 },
    { lat: 48.7326087, lng: 19.298586 },
    { lat: 48.7325412, lng: 19.2999047 },
    { lat: 48.7328654, lng: 19.3009822 },
    { lat: 48.7333557, lng: 19.3017326 },
    { lat: 48.7336488, lng: 19.3023229 },
    { lat: 48.7340262, lng: 19.3028604 },
    { lat: 48.7341473, lng: 19.30292 },
    { lat: 48.7341895, lng: 19.3031896 },
    { lat: 48.734526, lng: 19.3042143 },
    { lat: 48.734718, lng: 19.3052113 },
    { lat: 48.7348167, lng: 19.3058617 },
    { lat: 48.7347493, lng: 19.3072327 },
    { lat: 48.734871, lng: 19.3098473 },
    { lat: 48.7352042, lng: 19.3104294 },
    { lat: 48.7353985, lng: 19.3109569 },
    { lat: 48.7357598, lng: 19.3113467 },
    { lat: 48.7362543, lng: 19.3122646 },
    { lat: 48.7363811, lng: 19.312535 },
    { lat: 48.7365664, lng: 19.3134138 },
    { lat: 48.7363951, lng: 19.3142431 },
    { lat: 48.7362172, lng: 19.3146217 },
    { lat: 48.7360017, lng: 19.315796 },
    { lat: 48.7359035, lng: 19.3165737 },
    { lat: 48.735862, lng: 19.3176394 },
    { lat: 48.7361564, lng: 19.3178115 },
    { lat: 48.7364257, lng: 19.3182124 },
    { lat: 48.7369216, lng: 19.3183747 },
    { lat: 48.7370591, lng: 19.3185618 },
    { lat: 48.7373168, lng: 19.3186972 },
    { lat: 48.7373183, lng: 19.3185642 },
    { lat: 48.7375605, lng: 19.3187503 },
    { lat: 48.7380066, lng: 19.3192401 },
    { lat: 48.7382931, lng: 19.3197209 },
    { lat: 48.7384572, lng: 19.3202297 },
    { lat: 48.7386917, lng: 19.320629 },
    { lat: 48.7390385, lng: 19.3216403 },
    { lat: 48.7392791, lng: 19.3219562 },
    { lat: 48.7396053, lng: 19.3219228 },
    { lat: 48.7402228, lng: 19.3229357 },
    { lat: 48.7406378, lng: 19.3238131 },
    { lat: 48.7408006, lng: 19.3240618 },
    { lat: 48.7411523, lng: 19.3243699 },
    { lat: 48.7415203, lng: 19.3248551 },
    { lat: 48.7418813, lng: 19.3245754 },
    { lat: 48.7422962, lng: 19.3247677 },
    { lat: 48.74244, lng: 19.3250441 },
    { lat: 48.7428717, lng: 19.3254956 },
    { lat: 48.7430203, lng: 19.325678 },
    { lat: 48.7432365, lng: 19.3255576 },
    { lat: 48.7433189, lng: 19.3254187 },
    { lat: 48.743429, lng: 19.3250064 },
    { lat: 48.7435019, lng: 19.3242681 },
    { lat: 48.7434814, lng: 19.3237872 },
    { lat: 48.7437136, lng: 19.3239065 },
    { lat: 48.7441744, lng: 19.3239274 },
    { lat: 48.7444393, lng: 19.3240942 },
    { lat: 48.74463, lng: 19.3241209 },
    { lat: 48.7447858, lng: 19.323985 },
    { lat: 48.7449028, lng: 19.3241705 },
    { lat: 48.745025, lng: 19.3241079 },
    { lat: 48.7456653, lng: 19.3242317 },
    { lat: 48.7461012, lng: 19.3241302 },
    { lat: 48.746333, lng: 19.3242867 },
    { lat: 48.7465846, lng: 19.3242679 },
    { lat: 48.7468382, lng: 19.3243863 },
    { lat: 48.7470126, lng: 19.3245473 },
    { lat: 48.7472717, lng: 19.3245416 },
    { lat: 48.747487, lng: 19.3246399 },
    { lat: 48.7477232, lng: 19.3246049 },
    { lat: 48.7479016, lng: 19.3247758 },
    { lat: 48.7482983, lng: 19.3249662 },
    { lat: 48.7484247, lng: 19.324922 },
    { lat: 48.7484805, lng: 19.3250984 },
    { lat: 48.7487255, lng: 19.3252282 },
    { lat: 48.7488683, lng: 19.3255033 },
    { lat: 48.7491656, lng: 19.3254484 },
    { lat: 48.74954, lng: 19.325598 },
    { lat: 48.7496274, lng: 19.3254885 },
    { lat: 48.7499212, lng: 19.3255772 },
    { lat: 48.7500605, lng: 19.3254474 },
    { lat: 48.7503619, lng: 19.3255092 },
    { lat: 48.750807, lng: 19.3254114 },
    { lat: 48.7509611, lng: 19.325285 },
    { lat: 48.7513365, lng: 19.325218 },
    { lat: 48.7518218, lng: 19.3252333 },
    { lat: 48.752155, lng: 19.3250081 },
    { lat: 48.7524045, lng: 19.3250159 },
    { lat: 48.7527688, lng: 19.3251419 },
    { lat: 48.7532729, lng: 19.3246284 },
    { lat: 48.7535951, lng: 19.3244797 },
    { lat: 48.7540207, lng: 19.3249898 },
    { lat: 48.7543432, lng: 19.3252804 },
    { lat: 48.7547538, lng: 19.3253825 },
    { lat: 48.7549576, lng: 19.3257096 },
    { lat: 48.7561681, lng: 19.3264885 },
    { lat: 48.756452, lng: 19.3270105 },
    { lat: 48.7570482, lng: 19.3274085 },
    { lat: 48.7578296, lng: 19.3261857 },
    { lat: 48.758202, lng: 19.3253221 },
    { lat: 48.758885, lng: 19.324086 },
    { lat: 48.7596604, lng: 19.323591 },
    { lat: 48.7601352, lng: 19.3226276 },
    { lat: 48.7603729, lng: 19.3224356 },
    { lat: 48.7606913, lng: 19.3218122 },
    { lat: 48.7611088, lng: 19.3212758 },
    { lat: 48.7629198, lng: 19.3193835 },
    { lat: 48.7632239, lng: 19.3192567 },
    { lat: 48.7642223, lng: 19.3192087 },
    { lat: 48.7643415, lng: 19.319203 },
    { lat: 48.7661527, lng: 19.3176406 },
    { lat: 48.7669858, lng: 19.3159439 },
    { lat: 48.7672684, lng: 19.3153684 },
    { lat: 48.7689512, lng: 19.3149485 },
    { lat: 48.7713595, lng: 19.3155312 },
    { lat: 48.7717754, lng: 19.3155058 },
    { lat: 48.7726094, lng: 19.3152697 },
    { lat: 48.7728339, lng: 19.3150136 },
    { lat: 48.7754241, lng: 19.3138109 },
    { lat: 48.7764872, lng: 19.3132474 },
    { lat: 48.777055, lng: 19.3130546 },
    { lat: 48.7771504, lng: 19.3131453 },
    { lat: 48.7789579, lng: 19.3067843 },
    { lat: 48.7789641, lng: 19.3063509 },
    { lat: 48.7786438, lng: 19.3058959 },
    { lat: 48.778445, lng: 19.3057247 },
    { lat: 48.7776721, lng: 19.3053265 },
    { lat: 48.7772444, lng: 19.3054104 },
    { lat: 48.7767885, lng: 19.3053989 },
    { lat: 48.7751661, lng: 19.3048938 },
    { lat: 48.7747268, lng: 19.3042615 },
    { lat: 48.7746563, lng: 19.3037315 },
    { lat: 48.7746733, lng: 19.3030042 },
    { lat: 48.7754766, lng: 19.3031389 },
    { lat: 48.7757445, lng: 19.3028487 },
    { lat: 48.7759658, lng: 19.3028549 },
    { lat: 48.7760928, lng: 19.3029921 },
    { lat: 48.7762978, lng: 19.3029425 },
    { lat: 48.7763678, lng: 19.3027562 },
    { lat: 48.7765442, lng: 19.3027901 },
    { lat: 48.7771943, lng: 19.3021701 },
    { lat: 48.7775903, lng: 19.3020395 },
    { lat: 48.7776267, lng: 19.3023514 },
    { lat: 48.7776855, lng: 19.3021681 },
    { lat: 48.7778834, lng: 19.3020639 },
    { lat: 48.7781306, lng: 19.3019993 },
    { lat: 48.7782728, lng: 19.3020724 },
    { lat: 48.7788764, lng: 19.3017107 },
    { lat: 48.7791431, lng: 19.3016326 },
    { lat: 48.7792405, lng: 19.3013325 },
    { lat: 48.7796625, lng: 19.3014596 },
    { lat: 48.7800453, lng: 19.3011482 },
    { lat: 48.7801187, lng: 19.3009847 },
    { lat: 48.780184, lng: 19.3006073 },
    { lat: 48.7808025, lng: 19.3001518 },
    { lat: 48.7808094, lng: 19.2999691 },
    { lat: 48.7812682, lng: 19.2990737 },
    { lat: 48.7818119, lng: 19.2986592 },
    { lat: 48.782233, lng: 19.2977997 },
    { lat: 48.7820728, lng: 19.2972121 },
    { lat: 48.7828896, lng: 19.2971534 },
    { lat: 48.7830408, lng: 19.2967358 },
    { lat: 48.7840812, lng: 19.2961479 },
    { lat: 48.7839846, lng: 19.2955162 },
    { lat: 48.7841506, lng: 19.2949579 },
    { lat: 48.7846312, lng: 19.2946411 },
    { lat: 48.784817, lng: 19.2943538 },
    { lat: 48.7853084, lng: 19.2941894 },
    { lat: 48.7853965, lng: 19.2937712 },
    { lat: 48.7857877, lng: 19.2938652 },
    { lat: 48.7861836, lng: 19.2930001 },
    { lat: 48.786519, lng: 19.2927328 },
    { lat: 48.7868503, lng: 19.2926139 },
    { lat: 48.7873175, lng: 19.2925798 },
    { lat: 48.787525, lng: 19.2922771 },
    { lat: 48.7880467, lng: 19.2920155 },
    { lat: 48.7883867, lng: 19.2922134 },
    { lat: 48.788818, lng: 19.2920678 },
    { lat: 48.7891299, lng: 19.2925082 },
    { lat: 48.7893883, lng: 19.2925628 },
    { lat: 48.7898062, lng: 19.292344 },
    { lat: 48.7900776, lng: 19.2926684 },
    { lat: 48.7904384, lng: 19.2924848 },
    { lat: 48.7911843, lng: 19.2924759 },
    { lat: 48.7912938, lng: 19.2921726 },
    { lat: 48.7913956, lng: 19.2922071 },
    { lat: 48.7946941, lng: 19.291797 },
    { lat: 48.795024, lng: 19.2919774 },
    { lat: 48.7952449, lng: 19.2915263 },
    { lat: 48.7955463, lng: 19.2918661 },
    { lat: 48.7957802, lng: 19.2915473 },
    { lat: 48.7967521, lng: 19.2916752 },
    { lat: 48.7967557, lng: 19.2915636 },
    { lat: 48.7967736, lng: 19.2911337 },
    { lat: 48.7966968, lng: 19.2899796 },
    { lat: 48.7965508, lng: 19.2891222 },
    { lat: 48.7967209, lng: 19.2885058 },
    { lat: 48.7967227, lng: 19.2874846 },
    { lat: 48.7964095, lng: 19.2872083 },
    { lat: 48.7964997, lng: 19.2869507 },
    { lat: 48.7963828, lng: 19.2867451 },
    { lat: 48.7961909, lng: 19.2861276 },
    { lat: 48.7960659, lng: 19.2853316 },
    { lat: 48.7954326, lng: 19.282982 },
    { lat: 48.7949478, lng: 19.2818517 },
    { lat: 48.79432, lng: 19.2805619 },
    { lat: 48.7942727, lng: 19.280231 },
    { lat: 48.7940959, lng: 19.2797904 },
    { lat: 48.7938168, lng: 19.2779682 },
    { lat: 48.7937651, lng: 19.2772693 },
    { lat: 48.7940217, lng: 19.2747244 },
    { lat: 48.7940549, lng: 19.2739864 },
    { lat: 48.794009, lng: 19.2733598 },
    { lat: 48.7937681, lng: 19.2719857 },
    { lat: 48.7931942, lng: 19.2710612 },
    { lat: 48.7926197, lng: 19.2695973 },
    { lat: 48.7920158, lng: 19.2683351 },
    { lat: 48.7921132, lng: 19.2677141 },
    { lat: 48.7920832, lng: 19.2674879 },
    { lat: 48.7917165, lng: 19.2670333 },
    { lat: 48.7915762, lng: 19.2671173 },
    { lat: 48.7914798, lng: 19.266728 },
    { lat: 48.790589, lng: 19.2653623 },
    { lat: 48.7895461, lng: 19.2657315 },
    { lat: 48.7894844, lng: 19.2656192 },
    { lat: 48.7895801, lng: 19.2654517 },
    { lat: 48.7893879, lng: 19.2646433 },
    { lat: 48.7892495, lng: 19.2646053 },
    { lat: 48.7890716, lng: 19.2641384 },
    { lat: 48.7883345, lng: 19.2638844 },
    { lat: 48.7880272, lng: 19.2631433 },
    { lat: 48.7879579, lng: 19.2627578 },
    { lat: 48.7877835, lng: 19.262787 },
    { lat: 48.7876657, lng: 19.262262 },
    { lat: 48.7874901, lng: 19.2619361 },
    { lat: 48.7868623, lng: 19.2610932 },
    { lat: 48.7868643, lng: 19.2608549 },
    { lat: 48.7868151, lng: 19.260796 },
    { lat: 48.786551, lng: 19.2607651 },
    { lat: 48.7865495, lng: 19.2605908 },
    { lat: 48.7863525, lng: 19.2600381 },
    { lat: 48.7850183, lng: 19.2602783 },
    { lat: 48.784429, lng: 19.260047 },
    { lat: 48.7843407, lng: 19.2592883 },
    { lat: 48.7843075, lng: 19.2579491 },
    { lat: 48.7841852, lng: 19.257048 },
    { lat: 48.7838614, lng: 19.2559174 },
    { lat: 48.7836717, lng: 19.2543693 },
    { lat: 48.7837649, lng: 19.2538472 },
    { lat: 48.7834654, lng: 19.2535489 },
    { lat: 48.783496, lng: 19.2533465 },
    { lat: 48.7833335, lng: 19.2533602 },
    { lat: 48.7831823, lng: 19.2525855 },
    { lat: 48.7825764, lng: 19.2527847 },
    { lat: 48.7826585, lng: 19.2522216 },
    { lat: 48.7822187, lng: 19.2523085 },
    { lat: 48.7813186, lng: 19.2521338 },
    { lat: 48.7815193, lng: 19.2514812 },
    { lat: 48.781532, lng: 19.2512793 },
    { lat: 48.781449, lng: 19.2511694 },
    { lat: 48.7814243, lng: 19.2509377 },
    { lat: 48.7814472, lng: 19.2502214 },
    { lat: 48.7813875, lng: 19.250198 },
    { lat: 48.7812735, lng: 19.249707 },
    { lat: 48.7811384, lng: 19.2496144 },
    { lat: 48.7811173, lng: 19.2494598 },
    { lat: 48.7806539, lng: 19.2491969 },
    { lat: 48.780131, lng: 19.249457 },
    { lat: 48.780044, lng: 19.2495906 },
    { lat: 48.7793711, lng: 19.2495914 },
    { lat: 48.7791538, lng: 19.2497394 },
    { lat: 48.7788895, lng: 19.24968 },
    { lat: 48.7786284, lng: 19.2498043 },
    { lat: 48.7786193, lng: 19.2499889 },
    { lat: 48.7787338, lng: 19.2503054 },
    { lat: 48.7786166, lng: 19.250892 },
    { lat: 48.7787505, lng: 19.2509551 },
    { lat: 48.7787643, lng: 19.2510354 },
    { lat: 48.7784423, lng: 19.2513226 },
    { lat: 48.7781419, lng: 19.2508637 },
    { lat: 48.7777622, lng: 19.2507534 },
    { lat: 48.7776468, lng: 19.2510485 },
    { lat: 48.7775338, lng: 19.251042 },
    { lat: 48.7771093, lng: 19.2516082 },
    { lat: 48.7771787, lng: 19.2519061 },
    { lat: 48.7771499, lng: 19.2520083 },
    { lat: 48.7769932, lng: 19.2520302 },
    { lat: 48.7767852, lng: 19.2519256 },
    { lat: 48.7765376, lng: 19.2521638 },
    { lat: 48.7761118, lng: 19.2523113 },
    { lat: 48.7759151, lng: 19.2516665 },
    { lat: 48.7755529, lng: 19.2492086 },
    { lat: 48.7753541, lng: 19.248945 },
    { lat: 48.775081, lng: 19.248724 },
    { lat: 48.7748914, lng: 19.2484419 },
    { lat: 48.7741815, lng: 19.2470692 },
    { lat: 48.7741162, lng: 19.2470914 },
    { lat: 48.7740261, lng: 19.2469337 },
    { lat: 48.7740021, lng: 19.2471608 },
    { lat: 48.773826, lng: 19.2471278 },
    { lat: 48.7736225, lng: 19.2469048 },
    { lat: 48.7735874, lng: 19.2469512 },
    { lat: 48.7733504, lng: 19.2466654 },
    { lat: 48.7731597, lng: 19.2466343 },
    { lat: 48.7730639, lng: 19.2464748 },
    { lat: 48.7729989, lng: 19.2461768 },
    { lat: 48.7717302, lng: 19.2440673 },
    { lat: 48.771695, lng: 19.2435016 },
    { lat: 48.7716056, lng: 19.243166 },
    { lat: 48.771134, lng: 19.2422251 },
    { lat: 48.7712404, lng: 19.2421394 },
    { lat: 48.771555, lng: 19.2413288 },
    { lat: 48.771546, lng: 19.2411661 },
    { lat: 48.7711263, lng: 19.2407134 },
    { lat: 48.7712899, lng: 19.2404612 },
    { lat: 48.7712744, lng: 19.2403271 },
    { lat: 48.7711947, lng: 19.240168 },
    { lat: 48.7711365, lng: 19.2401569 },
    { lat: 48.7711012, lng: 19.2399833 },
    { lat: 48.7709087, lng: 19.2398516 },
    { lat: 48.7707698, lng: 19.2400089 },
    { lat: 48.7706315, lng: 19.2399713 },
    { lat: 48.770578, lng: 19.2403549 },
    { lat: 48.7701643, lng: 19.2399126 },
    { lat: 48.7703976, lng: 19.2392139 },
    { lat: 48.7700169, lng: 19.2381698 },
    { lat: 48.7699996, lng: 19.2379977 },
    { lat: 48.7697471, lng: 19.2379524 },
    { lat: 48.7697863, lng: 19.2376901 },
    { lat: 48.7692162, lng: 19.2379258 },
    { lat: 48.7691068, lng: 19.2373683 },
    { lat: 48.7688609, lng: 19.23769 },
    { lat: 48.7688517, lng: 19.2372658 },
    { lat: 48.7689043, lng: 19.2371421 },
    { lat: 48.7687338, lng: 19.2367625 },
    { lat: 48.7689212, lng: 19.2364427 },
    { lat: 48.7689887, lng: 19.2360207 },
    { lat: 48.7688735, lng: 19.2357158 },
    { lat: 48.7689642, lng: 19.2354471 },
    { lat: 48.7682955, lng: 19.2308571 },
    { lat: 48.7682604, lng: 19.2300849 },
  ],
  Strelníky: [
    { lat: 48.6978341, lng: 19.393594 },
    { lat: 48.6977909, lng: 19.3939001 },
    { lat: 48.6978065, lng: 19.3941353 },
    { lat: 48.6977399, lng: 19.3943205 },
    { lat: 48.6976237, lng: 19.3944614 },
    { lat: 48.6974861, lng: 19.3950352 },
    { lat: 48.6975388, lng: 19.3961284 },
    { lat: 48.6977342, lng: 19.3967118 },
    { lat: 48.6974803, lng: 19.3972346 },
    { lat: 48.6972443, lng: 19.3974879 },
    { lat: 48.6970363, lng: 19.3975249 },
    { lat: 48.6968482, lng: 19.3977638 },
    { lat: 48.6968444, lng: 19.3987621 },
    { lat: 48.6969969, lng: 19.3993679 },
    { lat: 48.6970547, lng: 19.3998782 },
    { lat: 48.6970063, lng: 19.4005509 },
    { lat: 48.6968985, lng: 19.400941 },
    { lat: 48.6966654, lng: 19.4011359 },
    { lat: 48.6965411, lng: 19.4016326 },
    { lat: 48.6963501, lng: 19.4019792 },
    { lat: 48.696284, lng: 19.4022321 },
    { lat: 48.6962752, lng: 19.4034802 },
    { lat: 48.6961921, lng: 19.4038473 },
    { lat: 48.6960967, lng: 19.4040235 },
    { lat: 48.6961149, lng: 19.4048428 },
    { lat: 48.6959691, lng: 19.4052191 },
    { lat: 48.6960382, lng: 19.4054617 },
    { lat: 48.6960917, lng: 19.4060539 },
    { lat: 48.6959475, lng: 19.4067476 },
    { lat: 48.6958732, lng: 19.4068922 },
    { lat: 48.6955864, lng: 19.4071455 },
    { lat: 48.695256, lng: 19.4076943 },
    { lat: 48.6951335, lng: 19.4081081 },
    { lat: 48.6945819, lng: 19.4091539 },
    { lat: 48.6945175, lng: 19.4094623 },
    { lat: 48.6943751, lng: 19.4097654 },
    { lat: 48.6940486, lng: 19.4102542 },
    { lat: 48.6939827, lng: 19.410532 },
    { lat: 48.6936647, lng: 19.4110776 },
    { lat: 48.6936222, lng: 19.4112791 },
    { lat: 48.6935342, lng: 19.4113449 },
    { lat: 48.6931837, lng: 19.4119236 },
    { lat: 48.693077, lng: 19.412001 },
    { lat: 48.6929674, lng: 19.412286 },
    { lat: 48.6925399, lng: 19.412924 },
    { lat: 48.6924308, lng: 19.4133113 },
    { lat: 48.6917763, lng: 19.4139686 },
    { lat: 48.6912963, lng: 19.4137296 },
    { lat: 48.690979, lng: 19.4138631 },
    { lat: 48.690706, lng: 19.4140953 },
    { lat: 48.6905453, lng: 19.414466 },
    { lat: 48.6903443, lng: 19.4145806 },
    { lat: 48.6899658, lng: 19.4149893 },
    { lat: 48.6895295, lng: 19.4156813 },
    { lat: 48.6892783, lng: 19.4157342 },
    { lat: 48.6890696, lng: 19.4160676 },
    { lat: 48.6888274, lng: 19.4163068 },
    { lat: 48.6885704, lng: 19.4164524 },
    { lat: 48.6883259, lng: 19.416909 },
    { lat: 48.6882783, lng: 19.4170975 },
    { lat: 48.6881388, lng: 19.417294 },
    { lat: 48.6880602, lng: 19.4175428 },
    { lat: 48.6880811, lng: 19.4180845 },
    { lat: 48.6881828, lng: 19.4183023 },
    { lat: 48.6882922, lng: 19.4191459 },
    { lat: 48.6881952, lng: 19.4196749 },
    { lat: 48.6880162, lng: 19.4200383 },
    { lat: 48.6878675, lng: 19.4201865 },
    { lat: 48.6873151, lng: 19.4204945 },
    { lat: 48.6865065, lng: 19.4206046 },
    { lat: 48.6862114, lng: 19.4204852 },
    { lat: 48.6857068, lng: 19.4206583 },
    { lat: 48.6854978, lng: 19.4210127 },
    { lat: 48.6851616, lng: 19.4213875 },
    { lat: 48.6849164, lng: 19.4218293 },
    { lat: 48.6849359, lng: 19.4219588 },
    { lat: 48.6848572, lng: 19.4223249 },
    { lat: 48.6847161, lng: 19.4224121 },
    { lat: 48.6846197, lng: 19.4225932 },
    { lat: 48.6845721, lng: 19.4229232 },
    { lat: 48.6846256, lng: 19.423419 },
    { lat: 48.6845591, lng: 19.4239001 },
    { lat: 48.6845796, lng: 19.4242168 },
    { lat: 48.6844516, lng: 19.4249487 },
    { lat: 48.6843995, lng: 19.4250157 },
    { lat: 48.6843723, lng: 19.4253474 },
    { lat: 48.6842619, lng: 19.4254216 },
    { lat: 48.6840071, lng: 19.4257788 },
    { lat: 48.6837569, lng: 19.4265024 },
    { lat: 48.6832787, lng: 19.4272397 },
    { lat: 48.68324, lng: 19.4273783 },
    { lat: 48.6831456, lng: 19.4274543 },
    { lat: 48.6827827, lng: 19.4283694 },
    { lat: 48.6825218, lng: 19.4287446 },
    { lat: 48.6825079, lng: 19.4289255 },
    { lat: 48.6824539, lng: 19.4290043 },
    { lat: 48.6826006, lng: 19.4298728 },
    { lat: 48.6825881, lng: 19.4301095 },
    { lat: 48.6827689, lng: 19.4306983 },
    { lat: 48.6828316, lng: 19.4307612 },
    { lat: 48.6828677, lng: 19.4311247 },
    { lat: 48.6829293, lng: 19.4311627 },
    { lat: 48.6830269, lng: 19.4314932 },
    { lat: 48.6832412, lng: 19.4318595 },
    { lat: 48.6834103, lng: 19.4323882 },
    { lat: 48.6834426, lng: 19.4332015 },
    { lat: 48.6836461, lng: 19.4338663 },
    { lat: 48.683719, lng: 19.4344356 },
    { lat: 48.6836739, lng: 19.4359309 },
    { lat: 48.6836961, lng: 19.4376069 },
    { lat: 48.6837334, lng: 19.4382895 },
    { lat: 48.6837908, lng: 19.438502 },
    { lat: 48.6837724, lng: 19.4397248 },
    { lat: 48.6838434, lng: 19.439846 },
    { lat: 48.6838369, lng: 19.4400186 },
    { lat: 48.6840359, lng: 19.44066 },
    { lat: 48.6814988, lng: 19.4418886 },
    { lat: 48.6815776, lng: 19.4426338 },
    { lat: 48.6818305, lng: 19.4438989 },
    { lat: 48.6818899, lng: 19.4448313 },
    { lat: 48.6818691, lng: 19.4457629 },
    { lat: 48.6819832, lng: 19.4463702 },
    { lat: 48.6822187, lng: 19.4469547 },
    { lat: 48.682283, lng: 19.4470002 },
    { lat: 48.6824423, lng: 19.4476117 },
    { lat: 48.6826223, lng: 19.4479207 },
    { lat: 48.6827805, lng: 19.4483411 },
    { lat: 48.6831275, lng: 19.4481462 },
    { lat: 48.6833007, lng: 19.4476769 },
    { lat: 48.683349, lng: 19.4471932 },
    { lat: 48.6834556, lng: 19.4467952 },
    { lat: 48.6837071, lng: 19.4477928 },
    { lat: 48.6840035, lng: 19.4482234 },
    { lat: 48.6843587, lng: 19.4485926 },
    { lat: 48.6845237, lng: 19.4486022 },
    { lat: 48.685196, lng: 19.4493399 },
    { lat: 48.6860139, lng: 19.4510027 },
    { lat: 48.6859961, lng: 19.451154 },
    { lat: 48.6865928, lng: 19.4519619 },
    { lat: 48.6873983, lng: 19.4523671 },
    { lat: 48.6879432, lng: 19.4530332 },
    { lat: 48.6879753, lng: 19.4540114 },
    { lat: 48.6880997, lng: 19.4541868 },
    { lat: 48.688184, lng: 19.4544514 },
    { lat: 48.6887467, lng: 19.4548699 },
    { lat: 48.6892395, lng: 19.4550473 },
    { lat: 48.6902806, lng: 19.4560581 },
    { lat: 48.6915207, lng: 19.4558711 },
    { lat: 48.6921895, lng: 19.4558813 },
    { lat: 48.6931014, lng: 19.4565895 },
    { lat: 48.6933751, lng: 19.4566093 },
    { lat: 48.6936424, lng: 19.4563501 },
    { lat: 48.6938489, lng: 19.4556262 },
    { lat: 48.694131, lng: 19.4550312 },
    { lat: 48.6947354, lng: 19.4543598 },
    { lat: 48.6949642, lng: 19.4543829 },
    { lat: 48.6953296, lng: 19.4545824 },
    { lat: 48.6957813, lng: 19.4546514 },
    { lat: 48.6957233, lng: 19.454761 },
    { lat: 48.6965283, lng: 19.4547956 },
    { lat: 48.6975877, lng: 19.4537562 },
    { lat: 48.6979026, lng: 19.4537424 },
    { lat: 48.698839, lng: 19.4534245 },
    { lat: 48.69961, lng: 19.4528195 },
    { lat: 48.7001073, lng: 19.4519568 },
    { lat: 48.7006689, lng: 19.4515075 },
    { lat: 48.7011563, lng: 19.4515659 },
    { lat: 48.7015008, lng: 19.4510034 },
    { lat: 48.70161, lng: 19.450057 },
    { lat: 48.7015552, lng: 19.4495566 },
    { lat: 48.7017146, lng: 19.4486487 },
    { lat: 48.7021002, lng: 19.4483394 },
    { lat: 48.7021622, lng: 19.4481694 },
    { lat: 48.7024976, lng: 19.4478465 },
    { lat: 48.7026437, lng: 19.4473782 },
    { lat: 48.7027545, lng: 19.4472426 },
    { lat: 48.7028573, lng: 19.4469569 },
    { lat: 48.7028047, lng: 19.4459253 },
    { lat: 48.7028942, lng: 19.4455947 },
    { lat: 48.70326, lng: 19.4451294 },
    { lat: 48.7035565, lng: 19.4444085 },
    { lat: 48.7035256, lng: 19.4438167 },
    { lat: 48.7032535, lng: 19.443344 },
    { lat: 48.7032954, lng: 19.4428468 },
    { lat: 48.703504, lng: 19.4424375 },
    { lat: 48.7035431, lng: 19.4420166 },
    { lat: 48.7041317, lng: 19.4409145 },
    { lat: 48.7043232, lng: 19.4406711 },
    { lat: 48.7042012, lng: 19.4402085 },
    { lat: 48.7041567, lng: 19.4394736 },
    { lat: 48.7043701, lng: 19.4390216 },
    { lat: 48.7046891, lng: 19.4388285 },
    { lat: 48.7047185, lng: 19.438663 },
    { lat: 48.7052628, lng: 19.4381065 },
    { lat: 48.7055003, lng: 19.4379777 },
    { lat: 48.7057993, lng: 19.437624 },
    { lat: 48.7054152, lng: 19.4384253 },
    { lat: 48.7055052, lng: 19.4384921 },
    { lat: 48.7056054, lng: 19.4388275 },
    { lat: 48.7060859, lng: 19.4387898 },
    { lat: 48.7065714, lng: 19.4388605 },
    { lat: 48.7066621, lng: 19.4385785 },
    { lat: 48.7072464, lng: 19.4375764 },
    { lat: 48.7078394, lng: 19.4367638 },
    { lat: 48.7082704, lng: 19.4358529 },
    { lat: 48.7084157, lng: 19.4353946 },
    { lat: 48.7084408, lng: 19.4341997 },
    { lat: 48.708576, lng: 19.4332318 },
    { lat: 48.7085819, lng: 19.4325843 },
    { lat: 48.7085346, lng: 19.4321533 },
    { lat: 48.7086816, lng: 19.4318732 },
    { lat: 48.7091021, lng: 19.4313708 },
    { lat: 48.7092196, lng: 19.4310857 },
    { lat: 48.7095621, lng: 19.4315669 },
    { lat: 48.7097369, lng: 19.4316435 },
    { lat: 48.7101031, lng: 19.4311822 },
    { lat: 48.7100758, lng: 19.4307898 },
    { lat: 48.7101198, lng: 19.430288 },
    { lat: 48.7102599, lng: 19.4301981 },
    { lat: 48.7101908, lng: 19.4288159 },
    { lat: 48.7100395, lng: 19.4288592 },
    { lat: 48.7098326, lng: 19.4287725 },
    { lat: 48.7097306, lng: 19.4277478 },
    { lat: 48.7099409, lng: 19.4267515 },
    { lat: 48.7101546, lng: 19.4266944 },
    { lat: 48.7100194, lng: 19.4262386 },
    { lat: 48.7099518, lng: 19.4262122 },
    { lat: 48.7099224, lng: 19.4260684 },
    { lat: 48.710086, lng: 19.4255934 },
    { lat: 48.7101344, lng: 19.4253003 },
    { lat: 48.7102707, lng: 19.425129 },
    { lat: 48.709805, lng: 19.425004 },
    { lat: 48.7098876, lng: 19.424771 },
    { lat: 48.7104107, lng: 19.423934 },
    { lat: 48.7112792, lng: 19.4227206 },
    { lat: 48.7113352, lng: 19.4228331 },
    { lat: 48.7119039, lng: 19.4222927 },
    { lat: 48.7126919, lng: 19.4217227 },
    { lat: 48.7128143, lng: 19.4218802 },
    { lat: 48.7131786, lng: 19.421671 },
    { lat: 48.7133349, lng: 19.4217096 },
    { lat: 48.7137707, lng: 19.4212943 },
    { lat: 48.7141366, lng: 19.4207552 },
    { lat: 48.7143662, lng: 19.4205343 },
    { lat: 48.7145129, lng: 19.4206571 },
    { lat: 48.7145859, lng: 19.4206268 },
    { lat: 48.7146561, lng: 19.4205172 },
    { lat: 48.7151051, lng: 19.4202622 },
    { lat: 48.7152881, lng: 19.4200299 },
    { lat: 48.7153988, lng: 19.4200334 },
    { lat: 48.7156077, lng: 19.4198498 },
    { lat: 48.7156324, lng: 19.4197069 },
    { lat: 48.7158205, lng: 19.4195318 },
    { lat: 48.7159209, lng: 19.4195839 },
    { lat: 48.7160356, lng: 19.419532 },
    { lat: 48.7162912, lng: 19.4186806 },
    { lat: 48.7164232, lng: 19.4185201 },
    { lat: 48.716574, lng: 19.4184672 },
    { lat: 48.7167267, lng: 19.4181629 },
    { lat: 48.7168136, lng: 19.4174462 },
    { lat: 48.7171042, lng: 19.4169158 },
    { lat: 48.7173036, lng: 19.4169633 },
    { lat: 48.7172966, lng: 19.4164762 },
    { lat: 48.7174214, lng: 19.4160092 },
    { lat: 48.7177543, lng: 19.4156602 },
    { lat: 48.7177713, lng: 19.4153472 },
    { lat: 48.7178218, lng: 19.4152518 },
    { lat: 48.7184514, lng: 19.4150698 },
    { lat: 48.7187628, lng: 19.4148156 },
    { lat: 48.7193344, lng: 19.4140726 },
    { lat: 48.7195887, lng: 19.4135889 },
    { lat: 48.7199205, lng: 19.413211 },
    { lat: 48.7199712, lng: 19.4127309 },
    { lat: 48.7201474, lng: 19.41192 },
    { lat: 48.7203965, lng: 19.4119451 },
    { lat: 48.7204653, lng: 19.4117803 },
    { lat: 48.7204935, lng: 19.4113947 },
    { lat: 48.7206988, lng: 19.4109172 },
    { lat: 48.7214135, lng: 19.4102499 },
    { lat: 48.721727, lng: 19.4100614 },
    { lat: 48.721942, lng: 19.4096703 },
    { lat: 48.7220276, lng: 19.4086418 },
    { lat: 48.7226234, lng: 19.4075492 },
    { lat: 48.7230939, lng: 19.4072495 },
    { lat: 48.7235277, lng: 19.4066947 },
    { lat: 48.7237498, lng: 19.4066013 },
    { lat: 48.7246052, lng: 19.4065527 },
    { lat: 48.7249467, lng: 19.4062198 },
    { lat: 48.7251656, lng: 19.4058872 },
    { lat: 48.7259502, lng: 19.4051704 },
    { lat: 48.7264301, lng: 19.4051211 },
    { lat: 48.7267097, lng: 19.4047876 },
    { lat: 48.7271432, lng: 19.4046344 },
    { lat: 48.7279241, lng: 19.4036984 },
    { lat: 48.7283565, lng: 19.4029431 },
    { lat: 48.7287465, lng: 19.4025392 },
    { lat: 48.7290486, lng: 19.4017 },
    { lat: 48.7292159, lng: 19.4015424 },
    { lat: 48.7293665, lng: 19.4009576 },
    { lat: 48.7293548, lng: 19.4006368 },
    { lat: 48.7296937, lng: 19.3997901 },
    { lat: 48.7301762, lng: 19.3988104 },
    { lat: 48.7303692, lng: 19.3981231 },
    { lat: 48.730402, lng: 19.3973603 },
    { lat: 48.7305464, lng: 19.3968589 },
    { lat: 48.7307066, lng: 19.3967903 },
    { lat: 48.7308882, lng: 19.3961931 },
    { lat: 48.7308497, lng: 19.3959713 },
    { lat: 48.7308896, lng: 19.3953151 },
    { lat: 48.7313318, lng: 19.3951551 },
    { lat: 48.731282, lng: 19.3948606 },
    { lat: 48.731162, lng: 19.3948053 },
    { lat: 48.731382, lng: 19.3946863 },
    { lat: 48.7314026, lng: 19.3941661 },
    { lat: 48.7313103, lng: 19.3941214 },
    { lat: 48.7311333, lng: 19.394195 },
    { lat: 48.7308938, lng: 19.3938938 },
    { lat: 48.7306445, lng: 19.3937707 },
    { lat: 48.7301304, lng: 19.3929271 },
    { lat: 48.7296543, lng: 19.3922707 },
    { lat: 48.7293124, lng: 19.3919002 },
    { lat: 48.7293035, lng: 19.391522 },
    { lat: 48.7293481, lng: 19.3913912 },
    { lat: 48.7297834, lng: 19.3908506 },
    { lat: 48.7298586, lng: 19.3906008 },
    { lat: 48.7298145, lng: 19.3904067 },
    { lat: 48.7298515, lng: 19.3896374 },
    { lat: 48.7299803, lng: 19.3895757 },
    { lat: 48.730298, lng: 19.3892098 },
    { lat: 48.7308514, lng: 19.3890717 },
    { lat: 48.7305716, lng: 19.3886566 },
    { lat: 48.7301652, lng: 19.3884012 },
    { lat: 48.7298303, lng: 19.3883006 },
    { lat: 48.7293549, lng: 19.3883057 },
    { lat: 48.7292585, lng: 19.3881997 },
    { lat: 48.729198, lng: 19.3879424 },
    { lat: 48.7292745, lng: 19.3874615 },
    { lat: 48.729249, lng: 19.3872822 },
    { lat: 48.7288894, lng: 19.3868718 },
    { lat: 48.729247, lng: 19.3864731 },
    { lat: 48.7293672, lng: 19.3861267 },
    { lat: 48.7296748, lng: 19.3859376 },
    { lat: 48.7300303, lng: 19.3854941 },
    { lat: 48.730411, lng: 19.3854128 },
    { lat: 48.7305652, lng: 19.3852673 },
    { lat: 48.7308924, lng: 19.3845087 },
    { lat: 48.7316824, lng: 19.3835521 },
    { lat: 48.7318697, lng: 19.3834627 },
    { lat: 48.7320645, lng: 19.3830061 },
    { lat: 48.7323174, lng: 19.381985 },
    { lat: 48.7325832, lng: 19.3816261 },
    { lat: 48.7334303, lng: 19.3811149 },
    { lat: 48.7336886, lng: 19.3805215 },
    { lat: 48.7343478, lng: 19.3795621 },
    { lat: 48.7343014, lng: 19.3795346 },
    { lat: 48.7330886, lng: 19.3764769 },
    { lat: 48.732955, lng: 19.3764392 },
    { lat: 48.7329002, lng: 19.376257 },
    { lat: 48.7328297, lng: 19.376316 },
    { lat: 48.7327333, lng: 19.37616 },
    { lat: 48.7326628, lng: 19.3763361 },
    { lat: 48.7322657, lng: 19.3761848 },
    { lat: 48.7319747, lng: 19.376375 },
    { lat: 48.7313153, lng: 19.3771345 },
    { lat: 48.7308623, lng: 19.3779234 },
    { lat: 48.7303085, lng: 19.3786101 },
    { lat: 48.7302236, lng: 19.3785771 },
    { lat: 48.7299338, lng: 19.3791671 },
    { lat: 48.7290272, lng: 19.3782032 },
    { lat: 48.7286938, lng: 19.3778304 },
    { lat: 48.7284683, lng: 19.3774456 },
    { lat: 48.728157, lng: 19.3763889 },
    { lat: 48.7281827, lng: 19.3759774 },
    { lat: 48.7280245, lng: 19.3751084 },
    { lat: 48.7282272, lng: 19.3742944 },
    { lat: 48.7280604, lng: 19.3739394 },
    { lat: 48.7282026, lng: 19.3734646 },
    { lat: 48.7281485, lng: 19.3729649 },
    { lat: 48.7279731, lng: 19.3727565 },
    { lat: 48.7278293, lng: 19.3729573 },
    { lat: 48.7276171, lng: 19.3728549 },
    { lat: 48.7273834, lng: 19.373517 },
    { lat: 48.7270962, lng: 19.3738753 },
    { lat: 48.7269128, lng: 19.3743628 },
    { lat: 48.7266955, lng: 19.3747218 },
    { lat: 48.7266876, lng: 19.3749854 },
    { lat: 48.725958, lng: 19.3760483 },
    { lat: 48.7257655, lng: 19.3756278 },
    { lat: 48.7256023, lng: 19.3759885 },
    { lat: 48.7253049, lng: 19.3756561 },
    { lat: 48.7250199, lng: 19.3750632 },
    { lat: 48.7250632, lng: 19.3746698 },
    { lat: 48.7250191, lng: 19.374187 },
    { lat: 48.724799, lng: 19.3735129 },
    { lat: 48.7246414, lng: 19.3726357 },
    { lat: 48.7242074, lng: 19.3730624 },
    { lat: 48.7239651, lng: 19.3734919 },
    { lat: 48.7236761, lng: 19.3738397 },
    { lat: 48.7235027, lng: 19.3739634 },
    { lat: 48.7231709, lng: 19.3737125 },
    { lat: 48.7230523, lng: 19.373897 },
    { lat: 48.7218289, lng: 19.3736522 },
    { lat: 48.721269, lng: 19.3737283 },
    { lat: 48.721319, lng: 19.3734473 },
    { lat: 48.7212458, lng: 19.3734281 },
    { lat: 48.7211869, lng: 19.3733256 },
    { lat: 48.7216829, lng: 19.3731357 },
    { lat: 48.7219739, lng: 19.373186 },
    { lat: 48.7223762, lng: 19.3729245 },
    { lat: 48.7224385, lng: 19.3727629 },
    { lat: 48.7222603, lng: 19.3727364 },
    { lat: 48.7216927, lng: 19.3724597 },
    { lat: 48.721452, lng: 19.3727077 },
    { lat: 48.7206913, lng: 19.3726635 },
    { lat: 48.7203651, lng: 19.3731077 },
    { lat: 48.7201553, lng: 19.3731507 },
    { lat: 48.7194775, lng: 19.374355 },
    { lat: 48.7186931, lng: 19.3745485 },
    { lat: 48.7183935, lng: 19.3745059 },
    { lat: 48.718831, lng: 19.3735524 },
    { lat: 48.7185631, lng: 19.3733226 },
    { lat: 48.7183279, lng: 19.3733069 },
    { lat: 48.7182849, lng: 19.373162 },
    { lat: 48.7175709, lng: 19.372551 },
    { lat: 48.7170758, lng: 19.3723796 },
    { lat: 48.7170009, lng: 19.3722242 },
    { lat: 48.7166221, lng: 19.3725103 },
    { lat: 48.715991, lng: 19.3723193 },
    { lat: 48.7152443, lng: 19.3731155 },
    { lat: 48.7156462, lng: 19.3741824 },
    { lat: 48.7156867, lng: 19.3746549 },
    { lat: 48.7153881, lng: 19.3748234 },
    { lat: 48.7149885, lng: 19.3746688 },
    { lat: 48.7150184, lng: 19.3744411 },
    { lat: 48.7148573, lng: 19.3744653 },
    { lat: 48.7145034, lng: 19.3742283 },
    { lat: 48.713906, lng: 19.3744655 },
    { lat: 48.7132763, lng: 19.3754988 },
    { lat: 48.7128942, lng: 19.3769047 },
    { lat: 48.712183, lng: 19.3770221 },
    { lat: 48.7120891, lng: 19.3773252 },
    { lat: 48.7117255, lng: 19.3770715 },
    { lat: 48.7114404, lng: 19.3765708 },
    { lat: 48.7109562, lng: 19.3764393 },
    { lat: 48.7108451, lng: 19.3765669 },
    { lat: 48.7106047, lng: 19.3764429 },
    { lat: 48.7101081, lng: 19.3756657 },
    { lat: 48.7099743, lng: 19.3753122 },
    { lat: 48.7096673, lng: 19.373874 },
    { lat: 48.7095787, lng: 19.3738087 },
    { lat: 48.7089362, lng: 19.3723318 },
    { lat: 48.7086054, lng: 19.3723878 },
    { lat: 48.7081816, lng: 19.3722905 },
    { lat: 48.7074049, lng: 19.3728375 },
    { lat: 48.7069798, lng: 19.3735713 },
    { lat: 48.7068064, lng: 19.374101 },
    { lat: 48.7063275, lng: 19.3750309 },
    { lat: 48.7060183, lng: 19.3745947 },
    { lat: 48.705736, lng: 19.3740599 },
    { lat: 48.7053205, lng: 19.3737572 },
    { lat: 48.7049083, lng: 19.3754363 },
    { lat: 48.7049459, lng: 19.375657 },
    { lat: 48.7048801, lng: 19.3758889 },
    { lat: 48.7049088, lng: 19.3763015 },
    { lat: 48.7048521, lng: 19.3771997 },
    { lat: 48.7047273, lng: 19.3772335 },
    { lat: 48.7045663, lng: 19.3787901 },
    { lat: 48.7047828, lng: 19.3787875 },
    { lat: 48.7030864, lng: 19.3809592 },
    { lat: 48.7028929, lng: 19.3813476 },
    { lat: 48.702467, lng: 19.3835935 },
    { lat: 48.7006953, lng: 19.3834982 },
    { lat: 48.7006922, lng: 19.3842 },
    { lat: 48.7005128, lng: 19.3847916 },
    { lat: 48.700464, lng: 19.3858594 },
    { lat: 48.7006357, lng: 19.3868213 },
    { lat: 48.7000691, lng: 19.3878085 },
    { lat: 48.6997656, lng: 19.3888288 },
    { lat: 48.6983276, lng: 19.3943843 },
    { lat: 48.697981, lng: 19.3949664 },
    { lat: 48.6979272, lng: 19.3949721 },
    { lat: 48.6979971, lng: 19.3943517 },
    { lat: 48.6979693, lng: 19.3935712 },
    { lat: 48.6978341, lng: 19.393594 },
  ],
  Povrazník: [
    { lat: 48.7216927, lng: 19.3724597 },
    { lat: 48.7216025, lng: 19.3722196 },
    { lat: 48.7214739, lng: 19.3719579 },
    { lat: 48.7214697, lng: 19.3715596 },
    { lat: 48.7217379, lng: 19.3712473 },
    { lat: 48.7226383, lng: 19.3712196 },
    { lat: 48.72256, lng: 19.3710924 },
    { lat: 48.7232119, lng: 19.3709339 },
    { lat: 48.723082, lng: 19.3705492 },
    { lat: 48.7228351, lng: 19.3701618 },
    { lat: 48.7226025, lng: 19.3701066 },
    { lat: 48.7223518, lng: 19.369741 },
    { lat: 48.7221939, lng: 19.3696168 },
    { lat: 48.7221045, lng: 19.3691813 },
    { lat: 48.7219793, lng: 19.3692494 },
    { lat: 48.7215481, lng: 19.3689009 },
    { lat: 48.721627, lng: 19.3686621 },
    { lat: 48.7218778, lng: 19.368841 },
    { lat: 48.7218831, lng: 19.3686694 },
    { lat: 48.7215773, lng: 19.3682559 },
    { lat: 48.7214241, lng: 19.3682825 },
    { lat: 48.7214922, lng: 19.3679596 },
    { lat: 48.7213183, lng: 19.3674526 },
    { lat: 48.7213575, lng: 19.3671589 },
    { lat: 48.7216226, lng: 19.3668326 },
    { lat: 48.7220138, lng: 19.3672125 },
    { lat: 48.7221366, lng: 19.3674027 },
    { lat: 48.7226142, lng: 19.3677081 },
    { lat: 48.7227131, lng: 19.3674868 },
    { lat: 48.7226562, lng: 19.3672141 },
    { lat: 48.7226114, lng: 19.3663166 },
    { lat: 48.7227892, lng: 19.3658816 },
    { lat: 48.7230839, lng: 19.3657755 },
    { lat: 48.7230668, lng: 19.3656738 },
    { lat: 48.7228259, lng: 19.3652751 },
    { lat: 48.7227187, lng: 19.3648215 },
    { lat: 48.7228029, lng: 19.3636955 },
    { lat: 48.722922, lng: 19.3634967 },
    { lat: 48.723017, lng: 19.3621714 },
    { lat: 48.7230973, lng: 19.3616722 },
    { lat: 48.7229874, lng: 19.3615478 },
    { lat: 48.7228486, lng: 19.3615628 },
    { lat: 48.7226276, lng: 19.3617298 },
    { lat: 48.7225214, lng: 19.3617003 },
    { lat: 48.7224174, lng: 19.3611961 },
    { lat: 48.7219888, lng: 19.3610222 },
    { lat: 48.7218154, lng: 19.3605027 },
    { lat: 48.7220012, lng: 19.3602374 },
    { lat: 48.7222137, lng: 19.3602008 },
    { lat: 48.7226616, lng: 19.3599 },
    { lat: 48.7236922, lng: 19.3597681 },
    { lat: 48.7236524, lng: 19.3604274 },
    { lat: 48.7240354, lng: 19.3605666 },
    { lat: 48.7244602, lng: 19.3600423 },
    { lat: 48.7246357, lng: 19.3601511 },
    { lat: 48.7247648, lng: 19.3600008 },
    { lat: 48.7251428, lng: 19.360032 },
    { lat: 48.7251291, lng: 19.3608349 },
    { lat: 48.7254541, lng: 19.3611542 },
    { lat: 48.7256307, lng: 19.3604097 },
    { lat: 48.7257493, lng: 19.3585567 },
    { lat: 48.7260015, lng: 19.3576987 },
    { lat: 48.7270899, lng: 19.3573081 },
    { lat: 48.7272179, lng: 19.3572029 },
    { lat: 48.7274424, lng: 19.3569219 },
    { lat: 48.7276226, lng: 19.3564686 },
    { lat: 48.7279541, lng: 19.3559222 },
    { lat: 48.7281016, lng: 19.3551634 },
    { lat: 48.7276393, lng: 19.3550606 },
    { lat: 48.7272155, lng: 19.3552736 },
    { lat: 48.726909, lng: 19.3552554 },
    { lat: 48.7267092, lng: 19.3554152 },
    { lat: 48.7263934, lng: 19.3555294 },
    { lat: 48.726022, lng: 19.3561786 },
    { lat: 48.7255652, lng: 19.3564815 },
    { lat: 48.7255541, lng: 19.3560583 },
    { lat: 48.7250597, lng: 19.3559473 },
    { lat: 48.7249404, lng: 19.3557856 },
    { lat: 48.724506, lng: 19.3560147 },
    { lat: 48.7240103, lng: 19.3561098 },
    { lat: 48.723937, lng: 19.3560631 },
    { lat: 48.7235974, lng: 19.3561734 },
    { lat: 48.7234737, lng: 19.3561337 },
    { lat: 48.7230584, lng: 19.3564056 },
    { lat: 48.7227698, lng: 19.3563309 },
    { lat: 48.7224375, lng: 19.3565257 },
    { lat: 48.7223774, lng: 19.3563987 },
    { lat: 48.7218503, lng: 19.356882 },
    { lat: 48.7217679, lng: 19.3573814 },
    { lat: 48.7214143, lng: 19.3576506 },
    { lat: 48.7203104, lng: 19.356927 },
    { lat: 48.7199216, lng: 19.3568759 },
    { lat: 48.7195755, lng: 19.3562359 },
    { lat: 48.7194411, lng: 19.3562505 },
    { lat: 48.7193013, lng: 19.3561311 },
    { lat: 48.7191341, lng: 19.3562406 },
    { lat: 48.7188792, lng: 19.3562653 },
    { lat: 48.7186235, lng: 19.3563865 },
    { lat: 48.7183892, lng: 19.3561724 },
    { lat: 48.7161366, lng: 19.3554905 },
    { lat: 48.7139306, lng: 19.3561262 },
    { lat: 48.7128463, lng: 19.3560812 },
    { lat: 48.7122555, lng: 19.356885 },
    { lat: 48.7096878, lng: 19.3580198 },
    { lat: 48.7093087, lng: 19.3582945 },
    { lat: 48.7088999, lng: 19.3583741 },
    { lat: 48.7086133, lng: 19.3586057 },
    { lat: 48.7084414, lng: 19.3593983 },
    { lat: 48.7083634, lng: 19.3595873 },
    { lat: 48.708318, lng: 19.3606974 },
    { lat: 48.7082157, lng: 19.36099 },
    { lat: 48.7080023, lng: 19.3613357 },
    { lat: 48.7079769, lng: 19.3613453 },
    { lat: 48.7075748, lng: 19.3614965 },
    { lat: 48.707366, lng: 19.361988 },
    { lat: 48.7070066, lng: 19.3625389 },
    { lat: 48.7069647, lng: 19.3626762 },
    { lat: 48.7069622, lng: 19.3639354 },
    { lat: 48.7068381, lng: 19.3646949 },
    { lat: 48.7066745, lng: 19.3653317 },
    { lat: 48.7062341, lng: 19.3656263 },
    { lat: 48.7058387, lng: 19.3657461 },
    { lat: 48.7057368, lng: 19.3658327 },
    { lat: 48.7056475, lng: 19.3659726 },
    { lat: 48.7053719, lng: 19.3667403 },
    { lat: 48.7047498, lng: 19.3676992 },
    { lat: 48.704061, lng: 19.3675967 },
    { lat: 48.7038166, lng: 19.3676723 },
    { lat: 48.7036626, lng: 19.3678267 },
    { lat: 48.7035878, lng: 19.3679332 },
    { lat: 48.70347, lng: 19.3685879 },
    { lat: 48.7033222, lng: 19.3689417 },
    { lat: 48.7032581, lng: 19.3696355 },
    { lat: 48.7030522, lng: 19.3699803 },
    { lat: 48.7023692, lng: 19.3704994 },
    { lat: 48.7018395, lng: 19.3707146 },
    { lat: 48.7013253, lng: 19.3710192 },
    { lat: 48.7009187, lng: 19.3710995 },
    { lat: 48.7008052, lng: 19.3712227 },
    { lat: 48.7006087, lng: 19.371976 },
    { lat: 48.7004668, lng: 19.372103 },
    { lat: 48.6995572, lng: 19.3725501 },
    { lat: 48.6991276, lng: 19.3728341 },
    { lat: 48.6989248, lng: 19.3732163 },
    { lat: 48.6989251, lng: 19.3736255 },
    { lat: 48.6988058, lng: 19.3738464 },
    { lat: 48.6986519, lng: 19.3740025 },
    { lat: 48.6984868, lng: 19.3745105 },
    { lat: 48.6982383, lng: 19.3748092 },
    { lat: 48.6982738, lng: 19.3749923 },
    { lat: 48.6980978, lng: 19.37527 },
    { lat: 48.6979995, lng: 19.3759345 },
    { lat: 48.6980782, lng: 19.3760745 },
    { lat: 48.69809, lng: 19.3763005 },
    { lat: 48.6979884, lng: 19.3767231 },
    { lat: 48.6980293, lng: 19.3770438 },
    { lat: 48.6979316, lng: 19.3772658 },
    { lat: 48.6975962, lng: 19.3773709 },
    { lat: 48.6974732, lng: 19.3775318 },
    { lat: 48.6973934, lng: 19.3778259 },
    { lat: 48.6971868, lng: 19.3779819 },
    { lat: 48.6971011, lng: 19.3781285 },
    { lat: 48.6971142, lng: 19.3788556 },
    { lat: 48.6970202, lng: 19.3789407 },
    { lat: 48.6969849, lng: 19.3793626 },
    { lat: 48.6970563, lng: 19.3794891 },
    { lat: 48.697078, lng: 19.3796647 },
    { lat: 48.697, lng: 19.3800185 },
    { lat: 48.6971118, lng: 19.3802608 },
    { lat: 48.6970979, lng: 19.3804214 },
    { lat: 48.69704, lng: 19.3806046 },
    { lat: 48.6968945, lng: 19.3807202 },
    { lat: 48.6968662, lng: 19.3812905 },
    { lat: 48.6968144, lng: 19.3814349 },
    { lat: 48.6968537, lng: 19.3817414 },
    { lat: 48.6968224, lng: 19.3818197 },
    { lat: 48.6969909, lng: 19.3824009 },
    { lat: 48.6969963, lng: 19.382731 },
    { lat: 48.6969517, lng: 19.3828361 },
    { lat: 48.6970168, lng: 19.383361 },
    { lat: 48.6968153, lng: 19.384341 },
    { lat: 48.6967093, lng: 19.3845561 },
    { lat: 48.6967251, lng: 19.3848213 },
    { lat: 48.6966276, lng: 19.3849987 },
    { lat: 48.6965551, lng: 19.3849926 },
    { lat: 48.6965036, lng: 19.3850934 },
    { lat: 48.6965502, lng: 19.3852447 },
    { lat: 48.6961408, lng: 19.3862174 },
    { lat: 48.6961769, lng: 19.3867682 },
    { lat: 48.6961481, lng: 19.3868998 },
    { lat: 48.6963125, lng: 19.3871104 },
    { lat: 48.6963453, lng: 19.3875197 },
    { lat: 48.6964329, lng: 19.387659 },
    { lat: 48.696457, lng: 19.3880952 },
    { lat: 48.6966983, lng: 19.3884342 },
    { lat: 48.6968942, lng: 19.3888837 },
    { lat: 48.6969011, lng: 19.3892201 },
    { lat: 48.6970584, lng: 19.3894501 },
    { lat: 48.6971127, lng: 19.3896465 },
    { lat: 48.6971979, lng: 19.3897305 },
    { lat: 48.6971926, lng: 19.3898816 },
    { lat: 48.6973074, lng: 19.3901175 },
    { lat: 48.6972986, lng: 19.390482 },
    { lat: 48.6974634, lng: 19.3909634 },
    { lat: 48.6973779, lng: 19.3918545 },
    { lat: 48.6974453, lng: 19.3923282 },
    { lat: 48.6976733, lng: 19.3930971 },
    { lat: 48.6978152, lng: 19.3933819 },
    { lat: 48.6978161, lng: 19.3935963 },
    { lat: 48.6978341, lng: 19.393594 },
    { lat: 48.6979693, lng: 19.3935712 },
    { lat: 48.6979971, lng: 19.3943517 },
    { lat: 48.6979272, lng: 19.3949721 },
    { lat: 48.697981, lng: 19.3949664 },
    { lat: 48.6983276, lng: 19.3943843 },
    { lat: 48.6997656, lng: 19.3888288 },
    { lat: 48.7000691, lng: 19.3878085 },
    { lat: 48.7006357, lng: 19.3868213 },
    { lat: 48.700464, lng: 19.3858594 },
    { lat: 48.7005128, lng: 19.3847916 },
    { lat: 48.7006922, lng: 19.3842 },
    { lat: 48.7006953, lng: 19.3834982 },
    { lat: 48.702467, lng: 19.3835935 },
    { lat: 48.7028929, lng: 19.3813476 },
    { lat: 48.7030864, lng: 19.3809592 },
    { lat: 48.7047828, lng: 19.3787875 },
    { lat: 48.7045663, lng: 19.3787901 },
    { lat: 48.7047273, lng: 19.3772335 },
    { lat: 48.7048521, lng: 19.3771997 },
    { lat: 48.7049088, lng: 19.3763015 },
    { lat: 48.7048801, lng: 19.3758889 },
    { lat: 48.7049459, lng: 19.375657 },
    { lat: 48.7049083, lng: 19.3754363 },
    { lat: 48.7053205, lng: 19.3737572 },
    { lat: 48.705736, lng: 19.3740599 },
    { lat: 48.7060183, lng: 19.3745947 },
    { lat: 48.7063275, lng: 19.3750309 },
    { lat: 48.7068064, lng: 19.374101 },
    { lat: 48.7069798, lng: 19.3735713 },
    { lat: 48.7074049, lng: 19.3728375 },
    { lat: 48.7081816, lng: 19.3722905 },
    { lat: 48.7086054, lng: 19.3723878 },
    { lat: 48.7089362, lng: 19.3723318 },
    { lat: 48.7095787, lng: 19.3738087 },
    { lat: 48.7096673, lng: 19.373874 },
    { lat: 48.7099743, lng: 19.3753122 },
    { lat: 48.7101081, lng: 19.3756657 },
    { lat: 48.7106047, lng: 19.3764429 },
    { lat: 48.7108451, lng: 19.3765669 },
    { lat: 48.7109562, lng: 19.3764393 },
    { lat: 48.7114404, lng: 19.3765708 },
    { lat: 48.7117255, lng: 19.3770715 },
    { lat: 48.7120891, lng: 19.3773252 },
    { lat: 48.712183, lng: 19.3770221 },
    { lat: 48.7128942, lng: 19.3769047 },
    { lat: 48.7132763, lng: 19.3754988 },
    { lat: 48.713906, lng: 19.3744655 },
    { lat: 48.7145034, lng: 19.3742283 },
    { lat: 48.7148573, lng: 19.3744653 },
    { lat: 48.7150184, lng: 19.3744411 },
    { lat: 48.7149885, lng: 19.3746688 },
    { lat: 48.7153881, lng: 19.3748234 },
    { lat: 48.7156867, lng: 19.3746549 },
    { lat: 48.7156462, lng: 19.3741824 },
    { lat: 48.7152443, lng: 19.3731155 },
    { lat: 48.715991, lng: 19.3723193 },
    { lat: 48.7166221, lng: 19.3725103 },
    { lat: 48.7170009, lng: 19.3722242 },
    { lat: 48.7170758, lng: 19.3723796 },
    { lat: 48.7175709, lng: 19.372551 },
    { lat: 48.7182849, lng: 19.373162 },
    { lat: 48.7183279, lng: 19.3733069 },
    { lat: 48.7185631, lng: 19.3733226 },
    { lat: 48.718831, lng: 19.3735524 },
    { lat: 48.7183935, lng: 19.3745059 },
    { lat: 48.7186931, lng: 19.3745485 },
    { lat: 48.7194775, lng: 19.374355 },
    { lat: 48.7201553, lng: 19.3731507 },
    { lat: 48.7203651, lng: 19.3731077 },
    { lat: 48.7206913, lng: 19.3726635 },
    { lat: 48.721452, lng: 19.3727077 },
    { lat: 48.7216927, lng: 19.3724597 },
  ],
  Ľubietová: [
    { lat: 48.763478, lng: 19.3642147 },
    { lat: 48.7631711, lng: 19.3638267 },
    { lat: 48.7630021, lng: 19.3632049 },
    { lat: 48.7634296, lng: 19.3621419 },
    { lat: 48.7637623, lng: 19.3610284 },
    { lat: 48.7639465, lng: 19.3600161 },
    { lat: 48.7639975, lng: 19.3591621 },
    { lat: 48.763958, lng: 19.3586369 },
    { lat: 48.7641826, lng: 19.3576202 },
    { lat: 48.7640164, lng: 19.3567086 },
    { lat: 48.7645627, lng: 19.3558421 },
    { lat: 48.7646479, lng: 19.3556199 },
    { lat: 48.7654743, lng: 19.3545983 },
    { lat: 48.7663158, lng: 19.3537368 },
    { lat: 48.7668063, lng: 19.352764 },
    { lat: 48.7665635, lng: 19.3524547 },
    { lat: 48.7663624, lng: 19.3521987 },
    { lat: 48.7660464, lng: 19.3515267 },
    { lat: 48.7657387, lng: 19.3496945 },
    { lat: 48.7655081, lng: 19.3487317 },
    { lat: 48.7655241, lng: 19.3483505 },
    { lat: 48.7668424, lng: 19.3470156 },
    { lat: 48.7672557, lng: 19.3478868 },
    { lat: 48.7675766, lng: 19.3477172 },
    { lat: 48.7679146, lng: 19.347404 },
    { lat: 48.768186, lng: 19.3473492 },
    { lat: 48.7689517, lng: 19.347027 },
    { lat: 48.7690149, lng: 19.3469609 },
    { lat: 48.7694181, lng: 19.3465391 },
    { lat: 48.7694467, lng: 19.3465092 },
    { lat: 48.7698771, lng: 19.345958 },
    { lat: 48.770108, lng: 19.3456031 },
    { lat: 48.7702017, lng: 19.3453513 },
    { lat: 48.7703009, lng: 19.3450845 },
    { lat: 48.7703281, lng: 19.344512 },
    { lat: 48.7702179, lng: 19.3439037 },
    { lat: 48.7699748, lng: 19.3433432 },
    { lat: 48.7699573, lng: 19.3433028 },
    { lat: 48.769498, lng: 19.3426285 },
    { lat: 48.7694341, lng: 19.3424301 },
    { lat: 48.7693911, lng: 19.3422964 },
    { lat: 48.7696628, lng: 19.3422058 },
    { lat: 48.7699872, lng: 19.3418951 },
    { lat: 48.7705465, lng: 19.3411383 },
    { lat: 48.7711647, lng: 19.3405594 },
    { lat: 48.771434, lng: 19.3401972 },
    { lat: 48.7717807, lng: 19.3394567 },
    { lat: 48.7720759, lng: 19.3385266 },
    { lat: 48.7725606, lng: 19.3377225 },
    { lat: 48.7724922, lng: 19.3374916 },
    { lat: 48.7716969, lng: 19.3388769 },
    { lat: 48.7713802, lng: 19.3392094 },
    { lat: 48.771279, lng: 19.3396191 },
    { lat: 48.7711175, lng: 19.3397989 },
    { lat: 48.7707818, lng: 19.3398782 },
    { lat: 48.7705533, lng: 19.3398348 },
    { lat: 48.7703584, lng: 19.3399089 },
    { lat: 48.7701165, lng: 19.3404136 },
    { lat: 48.7699584, lng: 19.3406216 },
    { lat: 48.7697741, lng: 19.3406795 },
    { lat: 48.769326, lng: 19.3404992 },
    { lat: 48.7691194, lng: 19.3405138 },
    { lat: 48.7689848, lng: 19.3406245 },
    { lat: 48.7688651, lng: 19.3409287 },
    { lat: 48.7687151, lng: 19.341143 },
    { lat: 48.7680032, lng: 19.3415787 },
    { lat: 48.7678291, lng: 19.3416115 },
    { lat: 48.767383, lng: 19.3415482 },
    { lat: 48.7673414, lng: 19.341428 },
    { lat: 48.7670226, lng: 19.3414098 },
    { lat: 48.766685, lng: 19.3415865 },
    { lat: 48.7663432, lng: 19.3419134 },
    { lat: 48.7661394, lng: 19.3419874 },
    { lat: 48.7657211, lng: 19.3420082 },
    { lat: 48.7654482, lng: 19.3419346 },
    { lat: 48.7652728, lng: 19.3415153 },
    { lat: 48.7651518, lng: 19.3406274 },
    { lat: 48.7648242, lng: 19.3398774 },
    { lat: 48.7646727, lng: 19.339751 },
    { lat: 48.7643685, lng: 19.3390399 },
    { lat: 48.7642413, lng: 19.3389015 },
    { lat: 48.7640056, lng: 19.337904 },
    { lat: 48.762431, lng: 19.3341527 },
    { lat: 48.7619679, lng: 19.3328535 },
    { lat: 48.7610451, lng: 19.3325052 },
    { lat: 48.760379, lng: 19.3319115 },
    { lat: 48.7601426, lng: 19.331799 },
    { lat: 48.7578445, lng: 19.3313047 },
    { lat: 48.7570924, lng: 19.3299935 },
    { lat: 48.7570485, lng: 19.3274632 },
    { lat: 48.7570482, lng: 19.3274085 },
    { lat: 48.756452, lng: 19.3270105 },
    { lat: 48.7561681, lng: 19.3264885 },
    { lat: 48.7549576, lng: 19.3257096 },
    { lat: 48.7547538, lng: 19.3253825 },
    { lat: 48.7543432, lng: 19.3252804 },
    { lat: 48.7540207, lng: 19.3249898 },
    { lat: 48.7535951, lng: 19.3244797 },
    { lat: 48.7532729, lng: 19.3246284 },
    { lat: 48.7527688, lng: 19.3251419 },
    { lat: 48.7524045, lng: 19.3250159 },
    { lat: 48.752155, lng: 19.3250081 },
    { lat: 48.7518218, lng: 19.3252333 },
    { lat: 48.7513365, lng: 19.325218 },
    { lat: 48.7509611, lng: 19.325285 },
    { lat: 48.750807, lng: 19.3254114 },
    { lat: 48.7503619, lng: 19.3255092 },
    { lat: 48.7500605, lng: 19.3254474 },
    { lat: 48.7499212, lng: 19.3255772 },
    { lat: 48.7496274, lng: 19.3254885 },
    { lat: 48.74954, lng: 19.325598 },
    { lat: 48.7491656, lng: 19.3254484 },
    { lat: 48.7488683, lng: 19.3255033 },
    { lat: 48.7487255, lng: 19.3252282 },
    { lat: 48.7484805, lng: 19.3250984 },
    { lat: 48.7484247, lng: 19.324922 },
    { lat: 48.7482983, lng: 19.3249662 },
    { lat: 48.7479016, lng: 19.3247758 },
    { lat: 48.7477232, lng: 19.3246049 },
    { lat: 48.747487, lng: 19.3246399 },
    { lat: 48.7472717, lng: 19.3245416 },
    { lat: 48.7470126, lng: 19.3245473 },
    { lat: 48.7468382, lng: 19.3243863 },
    { lat: 48.7465846, lng: 19.3242679 },
    { lat: 48.746333, lng: 19.3242867 },
    { lat: 48.7461012, lng: 19.3241302 },
    { lat: 48.7456653, lng: 19.3242317 },
    { lat: 48.745025, lng: 19.3241079 },
    { lat: 48.7449028, lng: 19.3241705 },
    { lat: 48.7447858, lng: 19.323985 },
    { lat: 48.74463, lng: 19.3241209 },
    { lat: 48.7444393, lng: 19.3240942 },
    { lat: 48.7441744, lng: 19.3239274 },
    { lat: 48.7437136, lng: 19.3239065 },
    { lat: 48.7434814, lng: 19.3237872 },
    { lat: 48.7435019, lng: 19.3242681 },
    { lat: 48.743429, lng: 19.3250064 },
    { lat: 48.7433189, lng: 19.3254187 },
    { lat: 48.7432365, lng: 19.3255576 },
    { lat: 48.7430203, lng: 19.325678 },
    { lat: 48.7425387, lng: 19.32598 },
    { lat: 48.7421799, lng: 19.3260438 },
    { lat: 48.7414793, lng: 19.3269253 },
    { lat: 48.7409619, lng: 19.3279665 },
    { lat: 48.7406377, lng: 19.3281432 },
    { lat: 48.7404917, lng: 19.3288597 },
    { lat: 48.740525, lng: 19.3290636 },
    { lat: 48.7404546, lng: 19.3290541 },
    { lat: 48.7402394, lng: 19.3292407 },
    { lat: 48.7399491, lng: 19.3297272 },
    { lat: 48.7395098, lng: 19.3301828 },
    { lat: 48.7394693, lng: 19.3305365 },
    { lat: 48.7393811, lng: 19.3306489 },
    { lat: 48.7392885, lng: 19.3305986 },
    { lat: 48.7392039, lng: 19.33034 },
    { lat: 48.7389977, lng: 19.3301971 },
    { lat: 48.7388961, lng: 19.3302646 },
    { lat: 48.7387583, lng: 19.3302578 },
    { lat: 48.7386993, lng: 19.3303668 },
    { lat: 48.7387051, lng: 19.3306332 },
    { lat: 48.7386148, lng: 19.3307466 },
    { lat: 48.7384178, lng: 19.3308009 },
    { lat: 48.7383025, lng: 19.330937 },
    { lat: 48.7380524, lng: 19.3309142 },
    { lat: 48.7379827, lng: 19.3310825 },
    { lat: 48.7378547, lng: 19.3312107 },
    { lat: 48.7376933, lng: 19.3311582 },
    { lat: 48.737569, lng: 19.3312021 },
    { lat: 48.7374786, lng: 19.3313373 },
    { lat: 48.7374306, lng: 19.3316792 },
    { lat: 48.7372303, lng: 19.3316128 },
    { lat: 48.7370789, lng: 19.331439 },
    { lat: 48.7370208, lng: 19.3315242 },
    { lat: 48.7369979, lng: 19.3317034 },
    { lat: 48.7371978, lng: 19.3323055 },
    { lat: 48.737201, lng: 19.3324676 },
    { lat: 48.7370758, lng: 19.3325366 },
    { lat: 48.7369759, lng: 19.3327095 },
    { lat: 48.7369949, lng: 19.3329192 },
    { lat: 48.7372281, lng: 19.3329917 },
    { lat: 48.7373746, lng: 19.3331325 },
    { lat: 48.7373755, lng: 19.3332691 },
    { lat: 48.7373164, lng: 19.333448 },
    { lat: 48.7373179, lng: 19.3336418 },
    { lat: 48.7371148, lng: 19.3339706 },
    { lat: 48.7370563, lng: 19.3342104 },
    { lat: 48.7371614, lng: 19.3344529 },
    { lat: 48.737175, lng: 19.3345946 },
    { lat: 48.7370644, lng: 19.3349023 },
    { lat: 48.7372229, lng: 19.3353449 },
    { lat: 48.7371257, lng: 19.3356679 },
    { lat: 48.7371374, lng: 19.3358462 },
    { lat: 48.7373066, lng: 19.335991 },
    { lat: 48.7372961, lng: 19.3361239 },
    { lat: 48.7371714, lng: 19.3362767 },
    { lat: 48.7372154, lng: 19.3365376 },
    { lat: 48.7371821, lng: 19.3367098 },
    { lat: 48.7370085, lng: 19.3369496 },
    { lat: 48.7369953, lng: 19.3371239 },
    { lat: 48.7370351, lng: 19.3372503 },
    { lat: 48.7369932, lng: 19.3373619 },
    { lat: 48.7366134, lng: 19.3376939 },
    { lat: 48.7363964, lng: 19.3381316 },
    { lat: 48.7362635, lng: 19.3387491 },
    { lat: 48.7360855, lng: 19.3390348 },
    { lat: 48.7361107, lng: 19.3391621 },
    { lat: 48.7360648, lng: 19.3393149 },
    { lat: 48.7359407, lng: 19.3394755 },
    { lat: 48.7358639, lng: 19.3398277 },
    { lat: 48.7357638, lng: 19.3399535 },
    { lat: 48.7355659, lng: 19.3410272 },
    { lat: 48.735489, lng: 19.3411873 },
    { lat: 48.7356075, lng: 19.3416186 },
    { lat: 48.7355922, lng: 19.3418191 },
    { lat: 48.7354445, lng: 19.3422697 },
    { lat: 48.735398, lng: 19.3431138 },
    { lat: 48.7352927, lng: 19.3432002 },
    { lat: 48.7350618, lng: 19.3432257 },
    { lat: 48.7349269, lng: 19.3430461 },
    { lat: 48.7347029, lng: 19.3429804 },
    { lat: 48.7344959, lng: 19.3430379 },
    { lat: 48.7341948, lng: 19.3429128 },
    { lat: 48.7340104, lng: 19.3429242 },
    { lat: 48.733836, lng: 19.3428332 },
    { lat: 48.7337653, lng: 19.3426731 },
    { lat: 48.7335454, lng: 19.3428605 },
    { lat: 48.7334431, lng: 19.3431533 },
    { lat: 48.7333339, lng: 19.3431069 },
    { lat: 48.7332833, lng: 19.3432302 },
    { lat: 48.7332771, lng: 19.3435472 },
    { lat: 48.7332237, lng: 19.343774 },
    { lat: 48.7332203, lng: 19.3441804 },
    { lat: 48.7332692, lng: 19.3443539 },
    { lat: 48.7330964, lng: 19.3447983 },
    { lat: 48.7331464, lng: 19.3452555 },
    { lat: 48.7332108, lng: 19.3453589 },
    { lat: 48.7329872, lng: 19.3458466 },
    { lat: 48.7330021, lng: 19.3459671 },
    { lat: 48.7328133, lng: 19.3461236 },
    { lat: 48.7327538, lng: 19.3464817 },
    { lat: 48.7326706, lng: 19.3466046 },
    { lat: 48.7323054, lng: 19.3468417 },
    { lat: 48.7321301, lng: 19.3472598 },
    { lat: 48.7320331, lng: 19.3476388 },
    { lat: 48.7321231, lng: 19.3478929 },
    { lat: 48.7321904, lng: 19.3479566 },
    { lat: 48.7321369, lng: 19.348423 },
    { lat: 48.7322426, lng: 19.3487728 },
    { lat: 48.7320048, lng: 19.3489639 },
    { lat: 48.7318457, lng: 19.3489358 },
    { lat: 48.7316563, lng: 19.3491931 },
    { lat: 48.7316059, lng: 19.3494663 },
    { lat: 48.7314459, lng: 19.3497718 },
    { lat: 48.731285, lng: 19.349706 },
    { lat: 48.7309534, lng: 19.3501786 },
    { lat: 48.7307869, lng: 19.3502788 },
    { lat: 48.7306125, lng: 19.3502852 },
    { lat: 48.7303812, lng: 19.3504495 },
    { lat: 48.7302385, lng: 19.3509305 },
    { lat: 48.7300615, lng: 19.3510979 },
    { lat: 48.7300007, lng: 19.351359 },
    { lat: 48.7296175, lng: 19.3514555 },
    { lat: 48.72947, lng: 19.3515526 },
    { lat: 48.7291243, lng: 19.352127 },
    { lat: 48.7290808, lng: 19.352092 },
    { lat: 48.7289876, lng: 19.3521671 },
    { lat: 48.7289044, lng: 19.3523397 },
    { lat: 48.7285155, lng: 19.3522169 },
    { lat: 48.7283536, lng: 19.3522758 },
    { lat: 48.7281334, lng: 19.352551 },
    { lat: 48.7280159, lng: 19.3524995 },
    { lat: 48.7274716, lng: 19.3525967 },
    { lat: 48.7269852, lng: 19.3524891 },
    { lat: 48.7266933, lng: 19.3527231 },
    { lat: 48.7265444, lng: 19.3529849 },
    { lat: 48.7264894, lng: 19.3532004 },
    { lat: 48.7262908, lng: 19.3533408 },
    { lat: 48.7260117, lng: 19.3538487 },
    { lat: 48.7259004, lng: 19.3539025 },
    { lat: 48.7257108, lng: 19.3538495 },
    { lat: 48.7254761, lng: 19.3536311 },
    { lat: 48.7252845, lng: 19.3536297 },
    { lat: 48.7251707, lng: 19.353983 },
    { lat: 48.7250072, lng: 19.3539118 },
    { lat: 48.7248698, lng: 19.3540091 },
    { lat: 48.7247354, lng: 19.3542165 },
    { lat: 48.7240452, lng: 19.3540417 },
    { lat: 48.7237818, lng: 19.3542384 },
    { lat: 48.7234829, lng: 19.3542365 },
    { lat: 48.7232885, lng: 19.354365 },
    { lat: 48.7232214, lng: 19.3546132 },
    { lat: 48.723115, lng: 19.3547697 },
    { lat: 48.7226682, lng: 19.3547606 },
    { lat: 48.7223777, lng: 19.3546707 },
    { lat: 48.7221344, lng: 19.3549155 },
    { lat: 48.7220131, lng: 19.3552802 },
    { lat: 48.7216845, lng: 19.3553836 },
    { lat: 48.7214946, lng: 19.3555124 },
    { lat: 48.7212274, lng: 19.3554413 },
    { lat: 48.7210309, lng: 19.3558146 },
    { lat: 48.7207828, lng: 19.3556915 },
    { lat: 48.7206166, lng: 19.3558491 },
    { lat: 48.7203325, lng: 19.35563 },
    { lat: 48.7201152, lng: 19.3558045 },
    { lat: 48.7199575, lng: 19.3561139 },
    { lat: 48.7199106, lng: 19.3561468 },
    { lat: 48.7198206, lng: 19.3560809 },
    { lat: 48.7197191, lng: 19.3561462 },
    { lat: 48.7196693, lng: 19.3562618 },
    { lat: 48.7195755, lng: 19.3562359 },
    { lat: 48.7199216, lng: 19.3568759 },
    { lat: 48.7203104, lng: 19.356927 },
    { lat: 48.7214143, lng: 19.3576506 },
    { lat: 48.7217679, lng: 19.3573814 },
    { lat: 48.7218503, lng: 19.356882 },
    { lat: 48.7223774, lng: 19.3563987 },
    { lat: 48.7224375, lng: 19.3565257 },
    { lat: 48.7227698, lng: 19.3563309 },
    { lat: 48.7230584, lng: 19.3564056 },
    { lat: 48.7234737, lng: 19.3561337 },
    { lat: 48.7235974, lng: 19.3561734 },
    { lat: 48.723937, lng: 19.3560631 },
    { lat: 48.7240103, lng: 19.3561098 },
    { lat: 48.724506, lng: 19.3560147 },
    { lat: 48.7249404, lng: 19.3557856 },
    { lat: 48.7250597, lng: 19.3559473 },
    { lat: 48.7255541, lng: 19.3560583 },
    { lat: 48.7255652, lng: 19.3564815 },
    { lat: 48.726022, lng: 19.3561786 },
    { lat: 48.7263934, lng: 19.3555294 },
    { lat: 48.7267092, lng: 19.3554152 },
    { lat: 48.726909, lng: 19.3552554 },
    { lat: 48.7272155, lng: 19.3552736 },
    { lat: 48.7276393, lng: 19.3550606 },
    { lat: 48.7281016, lng: 19.3551634 },
    { lat: 48.7279541, lng: 19.3559222 },
    { lat: 48.7276226, lng: 19.3564686 },
    { lat: 48.7274424, lng: 19.3569219 },
    { lat: 48.7272179, lng: 19.3572029 },
    { lat: 48.7270899, lng: 19.3573081 },
    { lat: 48.7260015, lng: 19.3576987 },
    { lat: 48.7257493, lng: 19.3585567 },
    { lat: 48.7256307, lng: 19.3604097 },
    { lat: 48.7254541, lng: 19.3611542 },
    { lat: 48.7251291, lng: 19.3608349 },
    { lat: 48.7251428, lng: 19.360032 },
    { lat: 48.7247648, lng: 19.3600008 },
    { lat: 48.7246357, lng: 19.3601511 },
    { lat: 48.7244602, lng: 19.3600423 },
    { lat: 48.7240354, lng: 19.3605666 },
    { lat: 48.7236524, lng: 19.3604274 },
    { lat: 48.7236922, lng: 19.3597681 },
    { lat: 48.7226616, lng: 19.3599 },
    { lat: 48.7222137, lng: 19.3602008 },
    { lat: 48.7220012, lng: 19.3602374 },
    { lat: 48.7218154, lng: 19.3605027 },
    { lat: 48.7219888, lng: 19.3610222 },
    { lat: 48.7224174, lng: 19.3611961 },
    { lat: 48.7225214, lng: 19.3617003 },
    { lat: 48.7226276, lng: 19.3617298 },
    { lat: 48.7228486, lng: 19.3615628 },
    { lat: 48.7229874, lng: 19.3615478 },
    { lat: 48.7230973, lng: 19.3616722 },
    { lat: 48.723017, lng: 19.3621714 },
    { lat: 48.722922, lng: 19.3634967 },
    { lat: 48.7228029, lng: 19.3636955 },
    { lat: 48.7227187, lng: 19.3648215 },
    { lat: 48.7228259, lng: 19.3652751 },
    { lat: 48.7230668, lng: 19.3656738 },
    { lat: 48.7230839, lng: 19.3657755 },
    { lat: 48.7227892, lng: 19.3658816 },
    { lat: 48.7226114, lng: 19.3663166 },
    { lat: 48.7226562, lng: 19.3672141 },
    { lat: 48.7227131, lng: 19.3674868 },
    { lat: 48.7226142, lng: 19.3677081 },
    { lat: 48.7221366, lng: 19.3674027 },
    { lat: 48.7220138, lng: 19.3672125 },
    { lat: 48.7216226, lng: 19.3668326 },
    { lat: 48.7213575, lng: 19.3671589 },
    { lat: 48.7213183, lng: 19.3674526 },
    { lat: 48.7214922, lng: 19.3679596 },
    { lat: 48.7214241, lng: 19.3682825 },
    { lat: 48.7215773, lng: 19.3682559 },
    { lat: 48.7218831, lng: 19.3686694 },
    { lat: 48.7218778, lng: 19.368841 },
    { lat: 48.721627, lng: 19.3686621 },
    { lat: 48.7215481, lng: 19.3689009 },
    { lat: 48.7219793, lng: 19.3692494 },
    { lat: 48.7221045, lng: 19.3691813 },
    { lat: 48.7221939, lng: 19.3696168 },
    { lat: 48.7223518, lng: 19.369741 },
    { lat: 48.7226025, lng: 19.3701066 },
    { lat: 48.7228351, lng: 19.3701618 },
    { lat: 48.723082, lng: 19.3705492 },
    { lat: 48.7232119, lng: 19.3709339 },
    { lat: 48.72256, lng: 19.3710924 },
    { lat: 48.7226383, lng: 19.3712196 },
    { lat: 48.7217379, lng: 19.3712473 },
    { lat: 48.7214697, lng: 19.3715596 },
    { lat: 48.7214739, lng: 19.3719579 },
    { lat: 48.7216025, lng: 19.3722196 },
    { lat: 48.7216927, lng: 19.3724597 },
    { lat: 48.7222603, lng: 19.3727364 },
    { lat: 48.7224385, lng: 19.3727629 },
    { lat: 48.7223762, lng: 19.3729245 },
    { lat: 48.7219739, lng: 19.373186 },
    { lat: 48.7216829, lng: 19.3731357 },
    { lat: 48.7211869, lng: 19.3733256 },
    { lat: 48.7212458, lng: 19.3734281 },
    { lat: 48.721319, lng: 19.3734473 },
    { lat: 48.721269, lng: 19.3737283 },
    { lat: 48.7218289, lng: 19.3736522 },
    { lat: 48.7230523, lng: 19.373897 },
    { lat: 48.7231709, lng: 19.3737125 },
    { lat: 48.7235027, lng: 19.3739634 },
    { lat: 48.7236761, lng: 19.3738397 },
    { lat: 48.7239651, lng: 19.3734919 },
    { lat: 48.7242074, lng: 19.3730624 },
    { lat: 48.7246414, lng: 19.3726357 },
    { lat: 48.724799, lng: 19.3735129 },
    { lat: 48.7250191, lng: 19.374187 },
    { lat: 48.7250632, lng: 19.3746698 },
    { lat: 48.7250199, lng: 19.3750632 },
    { lat: 48.7253049, lng: 19.3756561 },
    { lat: 48.7256023, lng: 19.3759885 },
    { lat: 48.7257655, lng: 19.3756278 },
    { lat: 48.725958, lng: 19.3760483 },
    { lat: 48.7266876, lng: 19.3749854 },
    { lat: 48.7266955, lng: 19.3747218 },
    { lat: 48.7269128, lng: 19.3743628 },
    { lat: 48.7270962, lng: 19.3738753 },
    { lat: 48.7273834, lng: 19.373517 },
    { lat: 48.7276171, lng: 19.3728549 },
    { lat: 48.7278293, lng: 19.3729573 },
    { lat: 48.7279731, lng: 19.3727565 },
    { lat: 48.7281485, lng: 19.3729649 },
    { lat: 48.7282026, lng: 19.3734646 },
    { lat: 48.7280604, lng: 19.3739394 },
    { lat: 48.7282272, lng: 19.3742944 },
    { lat: 48.7280245, lng: 19.3751084 },
    { lat: 48.7281827, lng: 19.3759774 },
    { lat: 48.728157, lng: 19.3763889 },
    { lat: 48.7284683, lng: 19.3774456 },
    { lat: 48.7286938, lng: 19.3778304 },
    { lat: 48.7290272, lng: 19.3782032 },
    { lat: 48.7299338, lng: 19.3791671 },
    { lat: 48.7302236, lng: 19.3785771 },
    { lat: 48.7303085, lng: 19.3786101 },
    { lat: 48.7308623, lng: 19.3779234 },
    { lat: 48.7313153, lng: 19.3771345 },
    { lat: 48.7319747, lng: 19.376375 },
    { lat: 48.7322657, lng: 19.3761848 },
    { lat: 48.7326628, lng: 19.3763361 },
    { lat: 48.7327333, lng: 19.37616 },
    { lat: 48.7328297, lng: 19.376316 },
    { lat: 48.7329002, lng: 19.376257 },
    { lat: 48.732955, lng: 19.3764392 },
    { lat: 48.7330886, lng: 19.3764769 },
    { lat: 48.7343014, lng: 19.3795346 },
    { lat: 48.7343478, lng: 19.3795621 },
    { lat: 48.7336886, lng: 19.3805215 },
    { lat: 48.7334303, lng: 19.3811149 },
    { lat: 48.7325832, lng: 19.3816261 },
    { lat: 48.7323174, lng: 19.381985 },
    { lat: 48.7320645, lng: 19.3830061 },
    { lat: 48.7318697, lng: 19.3834627 },
    { lat: 48.7316824, lng: 19.3835521 },
    { lat: 48.7308924, lng: 19.3845087 },
    { lat: 48.7305652, lng: 19.3852673 },
    { lat: 48.730411, lng: 19.3854128 },
    { lat: 48.7300303, lng: 19.3854941 },
    { lat: 48.7296748, lng: 19.3859376 },
    { lat: 48.7293672, lng: 19.3861267 },
    { lat: 48.729247, lng: 19.3864731 },
    { lat: 48.7288894, lng: 19.3868718 },
    { lat: 48.729249, lng: 19.3872822 },
    { lat: 48.7292745, lng: 19.3874615 },
    { lat: 48.729198, lng: 19.3879424 },
    { lat: 48.7292585, lng: 19.3881997 },
    { lat: 48.7293549, lng: 19.3883057 },
    { lat: 48.7298303, lng: 19.3883006 },
    { lat: 48.7301652, lng: 19.3884012 },
    { lat: 48.7305716, lng: 19.3886566 },
    { lat: 48.7308514, lng: 19.3890717 },
    { lat: 48.730298, lng: 19.3892098 },
    { lat: 48.7299803, lng: 19.3895757 },
    { lat: 48.7298515, lng: 19.3896374 },
    { lat: 48.7298145, lng: 19.3904067 },
    { lat: 48.7298586, lng: 19.3906008 },
    { lat: 48.7297834, lng: 19.3908506 },
    { lat: 48.7293481, lng: 19.3913912 },
    { lat: 48.7293035, lng: 19.391522 },
    { lat: 48.7293124, lng: 19.3919002 },
    { lat: 48.7296543, lng: 19.3922707 },
    { lat: 48.7301304, lng: 19.3929271 },
    { lat: 48.7306445, lng: 19.3937707 },
    { lat: 48.7308938, lng: 19.3938938 },
    { lat: 48.7311333, lng: 19.394195 },
    { lat: 48.7313103, lng: 19.3941214 },
    { lat: 48.7314026, lng: 19.3941661 },
    { lat: 48.731382, lng: 19.3946863 },
    { lat: 48.731162, lng: 19.3948053 },
    { lat: 48.731282, lng: 19.3948606 },
    { lat: 48.7313318, lng: 19.3951551 },
    { lat: 48.7308896, lng: 19.3953151 },
    { lat: 48.7308497, lng: 19.3959713 },
    { lat: 48.7308882, lng: 19.3961931 },
    { lat: 48.7307066, lng: 19.3967903 },
    { lat: 48.7305464, lng: 19.3968589 },
    { lat: 48.730402, lng: 19.3973603 },
    { lat: 48.7303692, lng: 19.3981231 },
    { lat: 48.7301762, lng: 19.3988104 },
    { lat: 48.7296937, lng: 19.3997901 },
    { lat: 48.7293548, lng: 19.4006368 },
    { lat: 48.7293665, lng: 19.4009576 },
    { lat: 48.7292159, lng: 19.4015424 },
    { lat: 48.7290486, lng: 19.4017 },
    { lat: 48.7287465, lng: 19.4025392 },
    { lat: 48.7283565, lng: 19.4029431 },
    { lat: 48.7279241, lng: 19.4036984 },
    { lat: 48.7271432, lng: 19.4046344 },
    { lat: 48.7267097, lng: 19.4047876 },
    { lat: 48.7264301, lng: 19.4051211 },
    { lat: 48.7259502, lng: 19.4051704 },
    { lat: 48.7251656, lng: 19.4058872 },
    { lat: 48.7249467, lng: 19.4062198 },
    { lat: 48.7246052, lng: 19.4065527 },
    { lat: 48.7237498, lng: 19.4066013 },
    { lat: 48.7235277, lng: 19.4066947 },
    { lat: 48.7230939, lng: 19.4072495 },
    { lat: 48.7226234, lng: 19.4075492 },
    { lat: 48.7220276, lng: 19.4086418 },
    { lat: 48.721942, lng: 19.4096703 },
    { lat: 48.721727, lng: 19.4100614 },
    { lat: 48.7214135, lng: 19.4102499 },
    { lat: 48.7206988, lng: 19.4109172 },
    { lat: 48.7204935, lng: 19.4113947 },
    { lat: 48.7204653, lng: 19.4117803 },
    { lat: 48.7203965, lng: 19.4119451 },
    { lat: 48.7201474, lng: 19.41192 },
    { lat: 48.7199712, lng: 19.4127309 },
    { lat: 48.7199205, lng: 19.413211 },
    { lat: 48.7195887, lng: 19.4135889 },
    { lat: 48.7193344, lng: 19.4140726 },
    { lat: 48.7187628, lng: 19.4148156 },
    { lat: 48.7184514, lng: 19.4150698 },
    { lat: 48.7178218, lng: 19.4152518 },
    { lat: 48.7177713, lng: 19.4153472 },
    { lat: 48.7177543, lng: 19.4156602 },
    { lat: 48.7174214, lng: 19.4160092 },
    { lat: 48.7172966, lng: 19.4164762 },
    { lat: 48.7173036, lng: 19.4169633 },
    { lat: 48.7171042, lng: 19.4169158 },
    { lat: 48.7168136, lng: 19.4174462 },
    { lat: 48.7167267, lng: 19.4181629 },
    { lat: 48.716574, lng: 19.4184672 },
    { lat: 48.7164232, lng: 19.4185201 },
    { lat: 48.7162912, lng: 19.4186806 },
    { lat: 48.7160356, lng: 19.419532 },
    { lat: 48.7159209, lng: 19.4195839 },
    { lat: 48.7158205, lng: 19.4195318 },
    { lat: 48.7156324, lng: 19.4197069 },
    { lat: 48.7156077, lng: 19.4198498 },
    { lat: 48.7153988, lng: 19.4200334 },
    { lat: 48.7152881, lng: 19.4200299 },
    { lat: 48.7151051, lng: 19.4202622 },
    { lat: 48.7146561, lng: 19.4205172 },
    { lat: 48.7145859, lng: 19.4206268 },
    { lat: 48.7145129, lng: 19.4206571 },
    { lat: 48.7143662, lng: 19.4205343 },
    { lat: 48.7141366, lng: 19.4207552 },
    { lat: 48.7137707, lng: 19.4212943 },
    { lat: 48.7133349, lng: 19.4217096 },
    { lat: 48.7131786, lng: 19.421671 },
    { lat: 48.7128143, lng: 19.4218802 },
    { lat: 48.7126919, lng: 19.4217227 },
    { lat: 48.7119039, lng: 19.4222927 },
    { lat: 48.7113352, lng: 19.4228331 },
    { lat: 48.7112792, lng: 19.4227206 },
    { lat: 48.7104107, lng: 19.423934 },
    { lat: 48.7098876, lng: 19.424771 },
    { lat: 48.709805, lng: 19.425004 },
    { lat: 48.7102707, lng: 19.425129 },
    { lat: 48.7101344, lng: 19.4253003 },
    { lat: 48.710086, lng: 19.4255934 },
    { lat: 48.7099224, lng: 19.4260684 },
    { lat: 48.7099518, lng: 19.4262122 },
    { lat: 48.7100194, lng: 19.4262386 },
    { lat: 48.7101546, lng: 19.4266944 },
    { lat: 48.7099409, lng: 19.4267515 },
    { lat: 48.7097306, lng: 19.4277478 },
    { lat: 48.7098326, lng: 19.4287725 },
    { lat: 48.7100395, lng: 19.4288592 },
    { lat: 48.7101908, lng: 19.4288159 },
    { lat: 48.7102599, lng: 19.4301981 },
    { lat: 48.7101198, lng: 19.430288 },
    { lat: 48.7100758, lng: 19.4307898 },
    { lat: 48.7101031, lng: 19.4311822 },
    { lat: 48.7097369, lng: 19.4316435 },
    { lat: 48.7095621, lng: 19.4315669 },
    { lat: 48.7092196, lng: 19.4310857 },
    { lat: 48.7091021, lng: 19.4313708 },
    { lat: 48.7086816, lng: 19.4318732 },
    { lat: 48.7085346, lng: 19.4321533 },
    { lat: 48.7085819, lng: 19.4325843 },
    { lat: 48.708576, lng: 19.4332318 },
    { lat: 48.7084408, lng: 19.4341997 },
    { lat: 48.7084157, lng: 19.4353946 },
    { lat: 48.7082704, lng: 19.4358529 },
    { lat: 48.7078394, lng: 19.4367638 },
    { lat: 48.7072464, lng: 19.4375764 },
    { lat: 48.7066621, lng: 19.4385785 },
    { lat: 48.7065714, lng: 19.4388605 },
    { lat: 48.7060859, lng: 19.4387898 },
    { lat: 48.7056054, lng: 19.4388275 },
    { lat: 48.7055052, lng: 19.4384921 },
    { lat: 48.7054152, lng: 19.4384253 },
    { lat: 48.7057993, lng: 19.437624 },
    { lat: 48.7055003, lng: 19.4379777 },
    { lat: 48.7052628, lng: 19.4381065 },
    { lat: 48.7047185, lng: 19.438663 },
    { lat: 48.7046891, lng: 19.4388285 },
    { lat: 48.7043701, lng: 19.4390216 },
    { lat: 48.7041567, lng: 19.4394736 },
    { lat: 48.7042012, lng: 19.4402085 },
    { lat: 48.7043232, lng: 19.4406711 },
    { lat: 48.7041317, lng: 19.4409145 },
    { lat: 48.7035431, lng: 19.4420166 },
    { lat: 48.703504, lng: 19.4424375 },
    { lat: 48.7032954, lng: 19.4428468 },
    { lat: 48.7032535, lng: 19.443344 },
    { lat: 48.7035256, lng: 19.4438167 },
    { lat: 48.7035565, lng: 19.4444085 },
    { lat: 48.70326, lng: 19.4451294 },
    { lat: 48.7028942, lng: 19.4455947 },
    { lat: 48.7028047, lng: 19.4459253 },
    { lat: 48.7028573, lng: 19.4469569 },
    { lat: 48.7027545, lng: 19.4472426 },
    { lat: 48.7026437, lng: 19.4473782 },
    { lat: 48.7024976, lng: 19.4478465 },
    { lat: 48.7021622, lng: 19.4481694 },
    { lat: 48.7021002, lng: 19.4483394 },
    { lat: 48.7017146, lng: 19.4486487 },
    { lat: 48.7015552, lng: 19.4495566 },
    { lat: 48.70161, lng: 19.450057 },
    { lat: 48.7015008, lng: 19.4510034 },
    { lat: 48.7011563, lng: 19.4515659 },
    { lat: 48.7006689, lng: 19.4515075 },
    { lat: 48.7001073, lng: 19.4519568 },
    { lat: 48.69961, lng: 19.4528195 },
    { lat: 48.698839, lng: 19.4534245 },
    { lat: 48.6979026, lng: 19.4537424 },
    { lat: 48.6975877, lng: 19.4537562 },
    { lat: 48.6965283, lng: 19.4547956 },
    { lat: 48.6957233, lng: 19.454761 },
    { lat: 48.6957813, lng: 19.4546514 },
    { lat: 48.6953296, lng: 19.4545824 },
    { lat: 48.6949642, lng: 19.4543829 },
    { lat: 48.6947354, lng: 19.4543598 },
    { lat: 48.694131, lng: 19.4550312 },
    { lat: 48.6938489, lng: 19.4556262 },
    { lat: 48.6936424, lng: 19.4563501 },
    { lat: 48.6933751, lng: 19.4566093 },
    { lat: 48.6931014, lng: 19.4565895 },
    { lat: 48.6921895, lng: 19.4558813 },
    { lat: 48.6915207, lng: 19.4558711 },
    { lat: 48.6902806, lng: 19.4560581 },
    { lat: 48.6892395, lng: 19.4550473 },
    { lat: 48.6887467, lng: 19.4548699 },
    { lat: 48.688184, lng: 19.4544514 },
    { lat: 48.6880997, lng: 19.4541868 },
    { lat: 48.6879753, lng: 19.4540114 },
    { lat: 48.6879432, lng: 19.4530332 },
    { lat: 48.6873983, lng: 19.4523671 },
    { lat: 48.6865928, lng: 19.4519619 },
    { lat: 48.6859961, lng: 19.451154 },
    { lat: 48.6860139, lng: 19.4510027 },
    { lat: 48.685196, lng: 19.4493399 },
    { lat: 48.6845237, lng: 19.4486022 },
    { lat: 48.6843587, lng: 19.4485926 },
    { lat: 48.6840035, lng: 19.4482234 },
    { lat: 48.6837071, lng: 19.4477928 },
    { lat: 48.6834556, lng: 19.4467952 },
    { lat: 48.683349, lng: 19.4471932 },
    { lat: 48.6833007, lng: 19.4476769 },
    { lat: 48.6831275, lng: 19.4481462 },
    { lat: 48.6827805, lng: 19.4483411 },
    { lat: 48.682924, lng: 19.4485672 },
    { lat: 48.6830496, lng: 19.4489876 },
    { lat: 48.6831239, lng: 19.4495428 },
    { lat: 48.6831271, lng: 19.4503599 },
    { lat: 48.6832465, lng: 19.4509396 },
    { lat: 48.6837895, lng: 19.4516358 },
    { lat: 48.6841036, lng: 19.4518688 },
    { lat: 48.6842183, lng: 19.4520357 },
    { lat: 48.6843128, lng: 19.4522742 },
    { lat: 48.6844073, lng: 19.4527317 },
    { lat: 48.6851431, lng: 19.4545131 },
    { lat: 48.685503, lng: 19.4571152 },
    { lat: 48.686206, lng: 19.4599192 },
    { lat: 48.6866604, lng: 19.4608443 },
    { lat: 48.6867063, lng: 19.4612018 },
    { lat: 48.6865298, lng: 19.4625184 },
    { lat: 48.68615, lng: 19.463314 },
    { lat: 48.686336, lng: 19.463449 },
    { lat: 48.687507, lng: 19.464704 },
    { lat: 48.688145, lng: 19.465861 },
    { lat: 48.688812, lng: 19.466255 },
    { lat: 48.68899, lng: 19.466298 },
    { lat: 48.689291, lng: 19.466371 },
    { lat: 48.690118, lng: 19.466462 },
    { lat: 48.690659, lng: 19.467241 },
    { lat: 48.691128, lng: 19.470211 },
    { lat: 48.691118, lng: 19.471191 },
    { lat: 48.690971, lng: 19.474395 },
    { lat: 48.690943, lng: 19.475004 },
    { lat: 48.69128, lng: 19.476274 },
    { lat: 48.691592, lng: 19.477221 },
    { lat: 48.691667, lng: 19.477517 },
    { lat: 48.691836, lng: 19.478181 },
    { lat: 48.692176, lng: 19.478955 },
    { lat: 48.693248, lng: 19.481625 },
    { lat: 48.693421, lng: 19.482146 },
    { lat: 48.693556, lng: 19.482533 },
    { lat: 48.69408, lng: 19.483285 },
    { lat: 48.694255, lng: 19.483596 },
    { lat: 48.694843, lng: 19.484517 },
    { lat: 48.694993, lng: 19.484801 },
    { lat: 48.695299, lng: 19.485471 },
    { lat: 48.696263, lng: 19.486891 },
    { lat: 48.696373, lng: 19.487053 },
    { lat: 48.697065, lng: 19.488071 },
    { lat: 48.69714, lng: 19.488178 },
    { lat: 48.69756, lng: 19.489115 },
    { lat: 48.698133, lng: 19.4899 },
    { lat: 48.698536, lng: 19.490039 },
    { lat: 48.699136, lng: 19.490327 },
    { lat: 48.699189, lng: 19.490353 },
    { lat: 48.699248, lng: 19.490381 },
    { lat: 48.699271, lng: 19.490384 },
    { lat: 48.699639, lng: 19.490486 },
    { lat: 48.699872, lng: 19.490613 },
    { lat: 48.700222, lng: 19.490732 },
    { lat: 48.700464, lng: 19.49081 },
    { lat: 48.700955, lng: 19.491025 },
    { lat: 48.701266, lng: 19.491221 },
    { lat: 48.701394, lng: 19.491402 },
    { lat: 48.702599, lng: 19.492366 },
    { lat: 48.703929, lng: 19.493195 },
    { lat: 48.704429, lng: 19.493631 },
    { lat: 48.704776, lng: 19.49399 },
    { lat: 48.704888, lng: 19.494169 },
    { lat: 48.706351, lng: 19.495962 },
    { lat: 48.706637, lng: 19.496274 },
    { lat: 48.706882, lng: 19.496353 },
    { lat: 48.707098, lng: 19.496827 },
    { lat: 48.70718, lng: 19.497233 },
    { lat: 48.70727, lng: 19.497243 },
    { lat: 48.707387, lng: 19.497583 },
    { lat: 48.708086, lng: 19.49831 },
    { lat: 48.708488, lng: 19.498833 },
    { lat: 48.708659, lng: 19.498938 },
    { lat: 48.708831, lng: 19.499332 },
    { lat: 48.709699, lng: 19.498901 },
    { lat: 48.711107, lng: 19.498026 },
    { lat: 48.711478, lng: 19.497476 },
    { lat: 48.712531, lng: 19.49637 },
    { lat: 48.71292, lng: 19.496124 },
    { lat: 48.713826, lng: 19.49691 },
    { lat: 48.714886, lng: 19.497626 },
    { lat: 48.715478, lng: 19.498119 },
    { lat: 48.716714, lng: 19.49922 },
    { lat: 48.71801, lng: 19.499638 },
    { lat: 48.719256, lng: 19.4999 },
    { lat: 48.719875, lng: 19.500152 },
    { lat: 48.719948, lng: 19.500225 },
    { lat: 48.719983, lng: 19.500263 },
    { lat: 48.720955, lng: 19.500581 },
    { lat: 48.721485, lng: 19.500823 },
    { lat: 48.722986, lng: 19.502176 },
    { lat: 48.723974, lng: 19.502945 },
    { lat: 48.72523, lng: 19.503569 },
    { lat: 48.725389, lng: 19.503712 },
    { lat: 48.725477, lng: 19.503791 },
    { lat: 48.725598, lng: 19.503899 },
    { lat: 48.725666, lng: 19.503994 },
    { lat: 48.72581, lng: 19.504183 },
    { lat: 48.725846, lng: 19.504212 },
    { lat: 48.72596, lng: 19.504303 },
    { lat: 48.726009, lng: 19.504379 },
    { lat: 48.726072, lng: 19.504476 },
    { lat: 48.726534, lng: 19.504243 },
    { lat: 48.727498, lng: 19.503812 },
    { lat: 48.72736, lng: 19.502018 },
    { lat: 48.728133, lng: 19.500489 },
    { lat: 48.728883, lng: 19.49909 },
    { lat: 48.728907, lng: 19.499019 },
    { lat: 48.729004, lng: 19.4985 },
    { lat: 48.730013, lng: 19.496328 },
    { lat: 48.730455, lng: 19.495448 },
    { lat: 48.731473, lng: 19.493481 },
    { lat: 48.731495, lng: 19.493443 },
    { lat: 48.731532, lng: 19.493379 },
    { lat: 48.732213, lng: 19.49232 },
    { lat: 48.732325, lng: 19.491836 },
    { lat: 48.73237, lng: 19.491751 },
    { lat: 48.732423, lng: 19.491651 },
    { lat: 48.7326751, lng: 19.4910265 },
    { lat: 48.7329415, lng: 19.4903666 },
    { lat: 48.733125, lng: 19.489912 },
    { lat: 48.734494, lng: 19.488824 },
    { lat: 48.735007, lng: 19.488224 },
    { lat: 48.736643, lng: 19.489892 },
    { lat: 48.738735, lng: 19.489042 },
    { lat: 48.738654, lng: 19.488199 },
    { lat: 48.738672, lng: 19.487217 },
    { lat: 48.741769, lng: 19.484235 },
    { lat: 48.7430388, lng: 19.4830117 },
    { lat: 48.743517, lng: 19.482551 },
    { lat: 48.744612, lng: 19.481263 },
    { lat: 48.744654, lng: 19.481204 },
    { lat: 48.745717, lng: 19.480425 },
    { lat: 48.746284, lng: 19.480009 },
    { lat: 48.74775, lng: 19.478559 },
    { lat: 48.747808, lng: 19.478492 },
    { lat: 48.747814, lng: 19.478484 },
    { lat: 48.747833, lng: 19.478467 },
    { lat: 48.749241, lng: 19.477174 },
    { lat: 48.751662, lng: 19.475086 },
    { lat: 48.753163, lng: 19.473772 },
    { lat: 48.75358, lng: 19.473393 },
    { lat: 48.753786, lng: 19.473204 },
    { lat: 48.75387, lng: 19.473126 },
    { lat: 48.754609, lng: 19.472444 },
    { lat: 48.75483, lng: 19.472283 },
    { lat: 48.755008, lng: 19.471946 },
    { lat: 48.755299, lng: 19.469341 },
    { lat: 48.754881, lng: 19.466629 },
    { lat: 48.754806, lng: 19.46417 },
    { lat: 48.753922, lng: 19.461951 },
    { lat: 48.753863, lng: 19.45945 },
    { lat: 48.753658, lng: 19.458073 },
    { lat: 48.753458, lng: 19.456679 },
    { lat: 48.753778, lng: 19.456098 },
    { lat: 48.754241, lng: 19.456101 },
    { lat: 48.754412, lng: 19.455847 },
    { lat: 48.754995, lng: 19.455691 },
    { lat: 48.756017, lng: 19.455425 },
    { lat: 48.756169, lng: 19.455104 },
    { lat: 48.756724, lng: 19.455275 },
    { lat: 48.757655, lng: 19.455056 },
    { lat: 48.758875, lng: 19.454654 },
    { lat: 48.759831, lng: 19.454232 },
    { lat: 48.761243, lng: 19.455852 },
    { lat: 48.762353, lng: 19.456598 },
    { lat: 48.762808, lng: 19.45661 },
    { lat: 48.763799, lng: 19.456248 },
    { lat: 48.764081, lng: 19.455962 },
    { lat: 48.7639344, lng: 19.4556226 },
    { lat: 48.7639821, lng: 19.4535971 },
    { lat: 48.7639563, lng: 19.4531119 },
    { lat: 48.764159, lng: 19.4480522 },
    { lat: 48.7639117, lng: 19.4473141 },
    { lat: 48.7636941, lng: 19.445813 },
    { lat: 48.7634716, lng: 19.4453195 },
    { lat: 48.7624497, lng: 19.4437024 },
    { lat: 48.7621526, lng: 19.4430115 },
    { lat: 48.7619509, lng: 19.4423321 },
    { lat: 48.7619024, lng: 19.4419254 },
    { lat: 48.7621332, lng: 19.4413861 },
    { lat: 48.762236, lng: 19.4408858 },
    { lat: 48.762126, lng: 19.4391543 },
    { lat: 48.7623326, lng: 19.4379038 },
    { lat: 48.7623249, lng: 19.4376932 },
    { lat: 48.7622373, lng: 19.4374111 },
    { lat: 48.762282, lng: 19.4370877 },
    { lat: 48.762264, lng: 19.4365552 },
    { lat: 48.7620805, lng: 19.4358868 },
    { lat: 48.7615627, lng: 19.4344969 },
    { lat: 48.761463, lng: 19.4334691 },
    { lat: 48.761588, lng: 19.4313736 },
    { lat: 48.7614513, lng: 19.4306406 },
    { lat: 48.7605021, lng: 19.4286335 },
    { lat: 48.7602968, lng: 19.4268237 },
    { lat: 48.7596649, lng: 19.424082 },
    { lat: 48.7596187, lng: 19.423504 },
    { lat: 48.7597113, lng: 19.4217214 },
    { lat: 48.7593487, lng: 19.4188648 },
    { lat: 48.7591062, lng: 19.4178513 },
    { lat: 48.7588629, lng: 19.4172762 },
    { lat: 48.7583192, lng: 19.4164737 },
    { lat: 48.7580581, lng: 19.4158308 },
    { lat: 48.7583068, lng: 19.4145958 },
    { lat: 48.7583177, lng: 19.4141535 },
    { lat: 48.7580763, lng: 19.4127557 },
    { lat: 48.7579026, lng: 19.4119835 },
    { lat: 48.7574766, lng: 19.4111691 },
    { lat: 48.757157, lng: 19.4103601 },
    { lat: 48.756482, lng: 19.4078633 },
    { lat: 48.7562819, lng: 19.40619 },
    { lat: 48.7559864, lng: 19.4056117 },
    { lat: 48.7596648, lng: 19.3976647 },
    { lat: 48.7610126, lng: 19.3944273 },
    { lat: 48.7614864, lng: 19.3939113 },
    { lat: 48.7622844, lng: 19.392499 },
    { lat: 48.7623717, lng: 19.392151 },
    { lat: 48.7626098, lng: 19.3919157 },
    { lat: 48.7627034, lng: 19.3915569 },
    { lat: 48.7627375, lng: 19.3910878 },
    { lat: 48.7628733, lng: 19.3903094 },
    { lat: 48.7635846, lng: 19.3883981 },
    { lat: 48.7636996, lng: 19.3883014 },
    { lat: 48.763771, lng: 19.3880218 },
    { lat: 48.7624355, lng: 19.3848896 },
    { lat: 48.7624442, lng: 19.3834254 },
    { lat: 48.7627573, lng: 19.3766526 },
    { lat: 48.7626089, lng: 19.3754458 },
    { lat: 48.7628109, lng: 19.3737571 },
    { lat: 48.7630036, lng: 19.3732766 },
    { lat: 48.763405, lng: 19.3728031 },
    { lat: 48.7636435, lng: 19.3722479 },
    { lat: 48.7635259, lng: 19.3716633 },
    { lat: 48.7634071, lng: 19.3697496 },
    { lat: 48.7635333, lng: 19.3691765 },
    { lat: 48.7638526, lng: 19.3686554 },
    { lat: 48.7640489, lng: 19.3677779 },
    { lat: 48.7639288, lng: 19.3667663 },
    { lat: 48.7639624, lng: 19.3656884 },
    { lat: 48.7637912, lng: 19.3646649 },
    { lat: 48.763478, lng: 19.3642147 },
  ],
  Turecká: [
    { lat: 48.8398352, lng: 19.0804108 },
    { lat: 48.8395342, lng: 19.0809991 },
    { lat: 48.8392938, lng: 19.0818328 },
    { lat: 48.8391904, lng: 19.082657 },
    { lat: 48.8394569, lng: 19.0857963 },
    { lat: 48.8394083, lng: 19.0865194 },
    { lat: 48.8400663, lng: 19.0877604 },
    { lat: 48.8401559, lng: 19.0884992 },
    { lat: 48.8397257, lng: 19.0981021 },
    { lat: 48.8440123, lng: 19.1025785 },
    { lat: 48.8441202, lng: 19.1025666 },
    { lat: 48.8442578, lng: 19.102708 },
    { lat: 48.8447301, lng: 19.1019081 },
    { lat: 48.8455173, lng: 19.1014386 },
    { lat: 48.8462135, lng: 19.1019776 },
    { lat: 48.8491118, lng: 19.1038209 },
    { lat: 48.8497544, lng: 19.1040282 },
    { lat: 48.8507923, lng: 19.1041329 },
    { lat: 48.8518535, lng: 19.1040056 },
    { lat: 48.8522159, lng: 19.1040399 },
    { lat: 48.8526704, lng: 19.1039299 },
    { lat: 48.8542526, lng: 19.1032809 },
    { lat: 48.8547987, lng: 19.1031802 },
    { lat: 48.855424, lng: 19.1028972 },
    { lat: 48.8557067, lng: 19.1024158 },
    { lat: 48.8573178, lng: 19.1018253 },
    { lat: 48.8576486, lng: 19.1017927 },
    { lat: 48.8584167, lng: 19.1020723 },
    { lat: 48.8586789, lng: 19.1020386 },
    { lat: 48.8592173, lng: 19.1023257 },
    { lat: 48.859293, lng: 19.102219 },
    { lat: 48.8592698, lng: 19.1020129 },
    { lat: 48.8592568, lng: 19.1018977 },
    { lat: 48.8595614, lng: 19.1018334 },
    { lat: 48.8595756, lng: 19.1015042 },
    { lat: 48.8601375, lng: 19.1006247 },
    { lat: 48.8596797, lng: 19.1003055 },
    { lat: 48.8591509, lng: 19.0996432 },
    { lat: 48.8593756, lng: 19.0988769 },
    { lat: 48.8593217, lng: 19.0983113 },
    { lat: 48.8592818, lng: 19.0978934 },
    { lat: 48.8594924, lng: 19.097868 },
    { lat: 48.8600728, lng: 19.0969757 },
    { lat: 48.8638244, lng: 19.0973831 },
    { lat: 48.8643733, lng: 19.097292 },
    { lat: 48.8650419, lng: 19.0967923 },
    { lat: 48.866713, lng: 19.0902497 },
    { lat: 48.8678009, lng: 19.0873643 },
    { lat: 48.8682012, lng: 19.0859586 },
    { lat: 48.8690691, lng: 19.0848216 },
    { lat: 48.8696149, lng: 19.0843465 },
    { lat: 48.8700283, lng: 19.0837532 },
    { lat: 48.8704993, lng: 19.0835423 },
    { lat: 48.8706407, lng: 19.0832547 },
    { lat: 48.871672, lng: 19.082664 },
    { lat: 48.8720132, lng: 19.0823608 },
    { lat: 48.872668, lng: 19.0820838 },
    { lat: 48.8725971, lng: 19.0819039 },
    { lat: 48.8733686, lng: 19.0816654 },
    { lat: 48.8742714, lng: 19.0810929 },
    { lat: 48.874618, lng: 19.081041 },
    { lat: 48.874813, lng: 19.080875 },
    { lat: 48.875065, lng: 19.080608 },
    { lat: 48.875611, lng: 19.080212 },
    { lat: 48.876523, lng: 19.079196 },
    { lat: 48.876764, lng: 19.078754 },
    { lat: 48.876804, lng: 19.078612 },
    { lat: 48.876988, lng: 19.077974 },
    { lat: 48.876989, lng: 19.07797 },
    { lat: 48.877285, lng: 19.077171 },
    { lat: 48.877264, lng: 19.075049 },
    { lat: 48.876987, lng: 19.07226 },
    { lat: 48.87697, lng: 19.072091 },
    { lat: 48.87686, lng: 19.071551 },
    { lat: 48.876754, lng: 19.07103 },
    { lat: 48.876802, lng: 19.070278 },
    { lat: 48.876839, lng: 19.069587 },
    { lat: 48.876949, lng: 19.068958 },
    { lat: 48.877326, lng: 19.067547 },
    { lat: 48.877338, lng: 19.065933 },
    { lat: 48.877327, lng: 19.065208 },
    { lat: 48.877323, lng: 19.06495 },
    { lat: 48.877421, lng: 19.06396 },
    { lat: 48.877445, lng: 19.063076 },
    { lat: 48.877476, lng: 19.061721 },
    { lat: 48.877519, lng: 19.060201 },
    { lat: 48.8730359, lng: 19.0567183 },
    { lat: 48.8709562, lng: 19.0557537 },
    { lat: 48.8702004, lng: 19.055994 },
    { lat: 48.8686492, lng: 19.0560031 },
    { lat: 48.8674, lng: 19.0560105 },
    { lat: 48.8669219, lng: 19.0557842 },
    { lat: 48.866606, lng: 19.0554183 },
    { lat: 48.8643997, lng: 19.0542654 },
    { lat: 48.8624572, lng: 19.0546456 },
    { lat: 48.8623308, lng: 19.0547353 },
    { lat: 48.8617098, lng: 19.0546937 },
    { lat: 48.8609794, lng: 19.0554609 },
    { lat: 48.8606077, lng: 19.055739 },
    { lat: 48.8599393, lng: 19.0559456 },
    { lat: 48.8592757, lng: 19.0560016 },
    { lat: 48.8588422, lng: 19.0562561 },
    { lat: 48.8584655, lng: 19.0563672 },
    { lat: 48.8581773, lng: 19.0565807 },
    { lat: 48.857991, lng: 19.0568659 },
    { lat: 48.8576259, lng: 19.0577075 },
    { lat: 48.8573493, lng: 19.0580579 },
    { lat: 48.8572525, lng: 19.0580646 },
    { lat: 48.8570197, lng: 19.0582986 },
    { lat: 48.8566563, lng: 19.0588558 },
    { lat: 48.8562922, lng: 19.0592859 },
    { lat: 48.8557839, lng: 19.0596516 },
    { lat: 48.8553202, lng: 19.0600967 },
    { lat: 48.8551723, lng: 19.0603392 },
    { lat: 48.8549356, lng: 19.0604332 },
    { lat: 48.854886, lng: 19.0606373 },
    { lat: 48.8543826, lng: 19.0613905 },
    { lat: 48.8542405, lng: 19.0617067 },
    { lat: 48.8512927, lng: 19.0663032 },
    { lat: 48.8510148, lng: 19.0668369 },
    { lat: 48.8505624, lng: 19.0678938 },
    { lat: 48.8502251, lng: 19.0682931 },
    { lat: 48.8495528, lng: 19.0692846 },
    { lat: 48.848948, lng: 19.0698813 },
    { lat: 48.8485113, lng: 19.0702358 },
    { lat: 48.8480945, lng: 19.070471 },
    { lat: 48.8468246, lng: 19.0719774 },
    { lat: 48.8464986, lng: 19.0726081 },
    { lat: 48.8463612, lng: 19.0729946 },
    { lat: 48.8459957, lng: 19.0735761 },
    { lat: 48.8458255, lng: 19.0736023 },
    { lat: 48.8453414, lng: 19.0739339 },
    { lat: 48.8446834, lng: 19.0745735 },
    { lat: 48.8443521, lng: 19.0750512 },
    { lat: 48.843879, lng: 19.0754456 },
    { lat: 48.8433991, lng: 19.0759714 },
    { lat: 48.8425718, lng: 19.0774291 },
    { lat: 48.8419693, lng: 19.0780733 },
    { lat: 48.8415625, lng: 19.0783488 },
    { lat: 48.8405381, lng: 19.0794903 },
    { lat: 48.8398352, lng: 19.0804108 },
  ],
  Priechod: [
    { lat: 48.8055932, lng: 19.1892624 },
    { lat: 48.8053784, lng: 19.1895024 },
    { lat: 48.8051122, lng: 19.1895893 },
    { lat: 48.8049066, lng: 19.1898827 },
    { lat: 48.8043097, lng: 19.1903331 },
    { lat: 48.804063, lng: 19.1908067 },
    { lat: 48.8035161, lng: 19.1918564 },
    { lat: 48.8027313, lng: 19.1919702 },
    { lat: 48.802479, lng: 19.1913008 },
    { lat: 48.8017224, lng: 19.1916188 },
    { lat: 48.8014884, lng: 19.1919986 },
    { lat: 48.8011337, lng: 19.191985 },
    { lat: 48.8008743, lng: 19.1917966 },
    { lat: 48.8007363, lng: 19.1914162 },
    { lat: 48.8006423, lng: 19.1912901 },
    { lat: 48.8006529, lng: 19.191 },
    { lat: 48.800402, lng: 19.1904559 },
    { lat: 48.7995629, lng: 19.19137 },
    { lat: 48.7992498, lng: 19.1918133 },
    { lat: 48.7988797, lng: 19.1921289 },
    { lat: 48.7987224, lng: 19.1924193 },
    { lat: 48.7984779, lng: 19.1925832 },
    { lat: 48.797632, lng: 19.193426 },
    { lat: 48.7972504, lng: 19.194222 },
    { lat: 48.7967838, lng: 19.1942741 },
    { lat: 48.7966469, lng: 19.1945625 },
    { lat: 48.7965466, lng: 19.194607 },
    { lat: 48.7961685, lng: 19.1952252 },
    { lat: 48.7956949, lng: 19.1964697 },
    { lat: 48.7955965, lng: 19.1970392 },
    { lat: 48.7956327, lng: 19.1978757 },
    { lat: 48.7953874, lng: 19.1977701 },
    { lat: 48.79506, lng: 19.1979936 },
    { lat: 48.7950305, lng: 19.1978971 },
    { lat: 48.7944855, lng: 19.1985053 },
    { lat: 48.7944062, lng: 19.1981059 },
    { lat: 48.7943341, lng: 19.1981272 },
    { lat: 48.7939814, lng: 19.1988687 },
    { lat: 48.7936948, lng: 19.1992117 },
    { lat: 48.7931376, lng: 19.1994344 },
    { lat: 48.7929546, lng: 19.1994509 },
    { lat: 48.7922926, lng: 19.1997682 },
    { lat: 48.7920682, lng: 19.1999716 },
    { lat: 48.7919053, lng: 19.2002594 },
    { lat: 48.791547, lng: 19.2004055 },
    { lat: 48.7911752, lng: 19.2014487 },
    { lat: 48.790938, lng: 19.2017179 },
    { lat: 48.7904163, lng: 19.2020931 },
    { lat: 48.7900014, lng: 19.2021097 },
    { lat: 48.7896205, lng: 19.2025308 },
    { lat: 48.789439, lng: 19.2036435 },
    { lat: 48.7898284, lng: 19.2060646 },
    { lat: 48.7899719, lng: 19.2065337 },
    { lat: 48.7896663, lng: 19.2066031 },
    { lat: 48.7894399, lng: 19.2065558 },
    { lat: 48.7892919, lng: 19.2063885 },
    { lat: 48.7889508, lng: 19.2062378 },
    { lat: 48.7883617, lng: 19.2061737 },
    { lat: 48.7881987, lng: 19.206091 },
    { lat: 48.7879187, lng: 19.2056861 },
    { lat: 48.787737, lng: 19.2057278 },
    { lat: 48.7875817, lng: 19.2056432 },
    { lat: 48.7870888, lng: 19.205869 },
    { lat: 48.7862029, lng: 19.2068421 },
    { lat: 48.7859282, lng: 19.207263 },
    { lat: 48.785522, lng: 19.2076053 },
    { lat: 48.7848446, lng: 19.2073723 },
    { lat: 48.7844467, lng: 19.2074674 },
    { lat: 48.7831471, lng: 19.2082098 },
    { lat: 48.7824902, lng: 19.2087247 },
    { lat: 48.7818751, lng: 19.209497 },
    { lat: 48.7817217, lng: 19.2098433 },
    { lat: 48.7812471, lng: 19.2105863 },
    { lat: 48.7806526, lng: 19.2124045 },
    { lat: 48.780979, lng: 19.2143672 },
    { lat: 48.7808227, lng: 19.2150258 },
    { lat: 48.7804143, lng: 19.2158514 },
    { lat: 48.7800139, lng: 19.2162448 },
    { lat: 48.779872, lng: 19.2164782 },
    { lat: 48.7784173, lng: 19.2176471 },
    { lat: 48.7780659, lng: 19.2180053 },
    { lat: 48.7776631, lng: 19.2191806 },
    { lat: 48.7775755, lng: 19.2195806 },
    { lat: 48.7774213, lng: 19.2199115 },
    { lat: 48.7771969, lng: 19.2201841 },
    { lat: 48.7769803, lng: 19.2199501 },
    { lat: 48.7767429, lng: 19.2201438 },
    { lat: 48.7761838, lng: 19.2194736 },
    { lat: 48.7761286, lng: 19.2203314 },
    { lat: 48.7756607, lng: 19.2211432 },
    { lat: 48.7744969, lng: 19.2225497 },
    { lat: 48.7738253, lng: 19.2235949 },
    { lat: 48.7736624, lng: 19.2234221 },
    { lat: 48.7734711, lng: 19.223359 },
    { lat: 48.7729808, lng: 19.2238476 },
    { lat: 48.7728867, lng: 19.2237677 },
    { lat: 48.7723804, lng: 19.2240183 },
    { lat: 48.7719187, lng: 19.224399 },
    { lat: 48.771499, lng: 19.2248812 },
    { lat: 48.7700666, lng: 19.2271167 },
    { lat: 48.7695846, lng: 19.2279846 },
    { lat: 48.7691559, lng: 19.2284427 },
    { lat: 48.7688519, lng: 19.2291006 },
    { lat: 48.7687135, lng: 19.2299189 },
    { lat: 48.7682604, lng: 19.2300849 },
    { lat: 48.7682955, lng: 19.2308571 },
    { lat: 48.7689642, lng: 19.2354471 },
    { lat: 48.7688735, lng: 19.2357158 },
    { lat: 48.7689887, lng: 19.2360207 },
    { lat: 48.7689212, lng: 19.2364427 },
    { lat: 48.7687338, lng: 19.2367625 },
    { lat: 48.7689043, lng: 19.2371421 },
    { lat: 48.7688517, lng: 19.2372658 },
    { lat: 48.7688609, lng: 19.23769 },
    { lat: 48.7691068, lng: 19.2373683 },
    { lat: 48.7692162, lng: 19.2379258 },
    { lat: 48.7697863, lng: 19.2376901 },
    { lat: 48.7697471, lng: 19.2379524 },
    { lat: 48.7699996, lng: 19.2379977 },
    { lat: 48.7700169, lng: 19.2381698 },
    { lat: 48.7703976, lng: 19.2392139 },
    { lat: 48.7701643, lng: 19.2399126 },
    { lat: 48.770578, lng: 19.2403549 },
    { lat: 48.7706315, lng: 19.2399713 },
    { lat: 48.7707698, lng: 19.2400089 },
    { lat: 48.7709087, lng: 19.2398516 },
    { lat: 48.7711012, lng: 19.2399833 },
    { lat: 48.7711365, lng: 19.2401569 },
    { lat: 48.7711947, lng: 19.240168 },
    { lat: 48.7712744, lng: 19.2403271 },
    { lat: 48.7712899, lng: 19.2404612 },
    { lat: 48.7711263, lng: 19.2407134 },
    { lat: 48.771546, lng: 19.2411661 },
    { lat: 48.771555, lng: 19.2413288 },
    { lat: 48.7712404, lng: 19.2421394 },
    { lat: 48.771134, lng: 19.2422251 },
    { lat: 48.7716056, lng: 19.243166 },
    { lat: 48.771695, lng: 19.2435016 },
    { lat: 48.7717302, lng: 19.2440673 },
    { lat: 48.7729989, lng: 19.2461768 },
    { lat: 48.7730639, lng: 19.2464748 },
    { lat: 48.7731597, lng: 19.2466343 },
    { lat: 48.7733504, lng: 19.2466654 },
    { lat: 48.7735874, lng: 19.2469512 },
    { lat: 48.7736225, lng: 19.2469048 },
    { lat: 48.773826, lng: 19.2471278 },
    { lat: 48.7740021, lng: 19.2471608 },
    { lat: 48.7740261, lng: 19.2469337 },
    { lat: 48.7741162, lng: 19.2470914 },
    { lat: 48.7741815, lng: 19.2470692 },
    { lat: 48.7748914, lng: 19.2484419 },
    { lat: 48.775081, lng: 19.248724 },
    { lat: 48.7753541, lng: 19.248945 },
    { lat: 48.7755529, lng: 19.2492086 },
    { lat: 48.7759151, lng: 19.2516665 },
    { lat: 48.7761118, lng: 19.2523113 },
    { lat: 48.7765376, lng: 19.2521638 },
    { lat: 48.7767852, lng: 19.2519256 },
    { lat: 48.7769932, lng: 19.2520302 },
    { lat: 48.7771499, lng: 19.2520083 },
    { lat: 48.7771787, lng: 19.2519061 },
    { lat: 48.7771093, lng: 19.2516082 },
    { lat: 48.7775338, lng: 19.251042 },
    { lat: 48.7776468, lng: 19.2510485 },
    { lat: 48.7777622, lng: 19.2507534 },
    { lat: 48.7781419, lng: 19.2508637 },
    { lat: 48.7784423, lng: 19.2513226 },
    { lat: 48.7787643, lng: 19.2510354 },
    { lat: 48.7787505, lng: 19.2509551 },
    { lat: 48.7786166, lng: 19.250892 },
    { lat: 48.7787338, lng: 19.2503054 },
    { lat: 48.7786193, lng: 19.2499889 },
    { lat: 48.7786284, lng: 19.2498043 },
    { lat: 48.7788895, lng: 19.24968 },
    { lat: 48.7791538, lng: 19.2497394 },
    { lat: 48.7793711, lng: 19.2495914 },
    { lat: 48.780044, lng: 19.2495906 },
    { lat: 48.780131, lng: 19.249457 },
    { lat: 48.7806539, lng: 19.2491969 },
    { lat: 48.7806792, lng: 19.2491372 },
    { lat: 48.7807718, lng: 19.2487675 },
    { lat: 48.7809799, lng: 19.2483846 },
    { lat: 48.7811721, lng: 19.2483065 },
    { lat: 48.781402, lng: 19.2483951 },
    { lat: 48.7820453, lng: 19.2475283 },
    { lat: 48.7822617, lng: 19.2471084 },
    { lat: 48.7823131, lng: 19.2470777 },
    { lat: 48.782496, lng: 19.2471758 },
    { lat: 48.7825499, lng: 19.2470998 },
    { lat: 48.7826677, lng: 19.2463404 },
    { lat: 48.7830537, lng: 19.246401 },
    { lat: 48.7832407, lng: 19.2462124 },
    { lat: 48.7836353, lng: 19.2456297 },
    { lat: 48.7844648, lng: 19.2448807 },
    { lat: 48.7847316, lng: 19.2447121 },
    { lat: 48.7850283, lng: 19.2446006 },
    { lat: 48.7863236, lng: 19.2438332 },
    { lat: 48.7871428, lng: 19.2434305 },
    { lat: 48.7875882, lng: 19.2429698 },
    { lat: 48.7885041, lng: 19.2431433 },
    { lat: 48.7886688, lng: 19.2431494 },
    { lat: 48.7886666, lng: 19.2430795 },
    { lat: 48.7896924, lng: 19.2432223 },
    { lat: 48.7897022, lng: 19.2430979 },
    { lat: 48.7898296, lng: 19.2431914 },
    { lat: 48.7899859, lng: 19.2431133 },
    { lat: 48.7903808, lng: 19.2431228 },
    { lat: 48.7907581, lng: 19.2429031 },
    { lat: 48.7912572, lng: 19.240408 },
    { lat: 48.7921152, lng: 19.2391505 },
    { lat: 48.7922385, lng: 19.2393456 },
    { lat: 48.7927126, lng: 19.2387801 },
    { lat: 48.792914, lng: 19.2387005 },
    { lat: 48.7933371, lng: 19.2382422 },
    { lat: 48.7932453, lng: 19.238 },
    { lat: 48.7943192, lng: 19.237018 },
    { lat: 48.7942801, lng: 19.2368139 },
    { lat: 48.7943165, lng: 19.2367505 },
    { lat: 48.7946016, lng: 19.2367947 },
    { lat: 48.795191, lng: 19.2364212 },
    { lat: 48.7959762, lng: 19.2357586 },
    { lat: 48.7964025, lng: 19.2351379 },
    { lat: 48.797293, lng: 19.2327878 },
    { lat: 48.7976449, lng: 19.2326054 },
    { lat: 48.7980761, lng: 19.2321561 },
    { lat: 48.7984267, lng: 19.2315966 },
    { lat: 48.7983324, lng: 19.2314876 },
    { lat: 48.7983465, lng: 19.2302412 },
    { lat: 48.7984703, lng: 19.2295881 },
    { lat: 48.7985704, lng: 19.2293972 },
    { lat: 48.798546, lng: 19.2292408 },
    { lat: 48.7988919, lng: 19.2284723 },
    { lat: 48.7989403, lng: 19.2281884 },
    { lat: 48.7996694, lng: 19.2262093 },
    { lat: 48.7998309, lng: 19.2254273 },
    { lat: 48.8001384, lng: 19.2248182 },
    { lat: 48.8006498, lng: 19.2229046 },
    { lat: 48.8009075, lng: 19.2224755 },
    { lat: 48.8036124, lng: 19.2211705 },
    { lat: 48.80414, lng: 19.220638 },
    { lat: 48.8044759, lng: 19.2198923 },
    { lat: 48.8057723, lng: 19.2195951 },
    { lat: 48.8064223, lng: 19.2191209 },
    { lat: 48.8069308, lng: 19.2186345 },
    { lat: 48.8089365, lng: 19.2176443 },
    { lat: 48.8098349, lng: 19.2169758 },
    { lat: 48.8103767, lng: 19.2168039 },
    { lat: 48.8108371, lng: 19.2163965 },
    { lat: 48.8118158, lng: 19.2162379 },
    { lat: 48.8123358, lng: 19.2162708 },
    { lat: 48.8127445, lng: 19.2160963 },
    { lat: 48.8130781, lng: 19.2153542 },
    { lat: 48.814088, lng: 19.2150508 },
    { lat: 48.8144552, lng: 19.2147464 },
    { lat: 48.8148188, lng: 19.2142921 },
    { lat: 48.8149393, lng: 19.2137822 },
    { lat: 48.8155064, lng: 19.2128564 },
    { lat: 48.8160607, lng: 19.2125246 },
    { lat: 48.8166831, lng: 19.2119027 },
    { lat: 48.8171626, lng: 19.2115192 },
    { lat: 48.816465, lng: 19.2108215 },
    { lat: 48.8161214, lng: 19.210477 },
    { lat: 48.8161573, lng: 19.2103364 },
    { lat: 48.8159255, lng: 19.210227 },
    { lat: 48.8159056, lng: 19.2098183 },
    { lat: 48.8155927, lng: 19.2098531 },
    { lat: 48.8151105, lng: 19.2101082 },
    { lat: 48.8153585, lng: 19.2096718 },
    { lat: 48.8156169, lng: 19.209442 },
    { lat: 48.8159755, lng: 19.2094006 },
    { lat: 48.8159721, lng: 19.2093291 },
    { lat: 48.8158398, lng: 19.2093438 },
    { lat: 48.8157383, lng: 19.2090869 },
    { lat: 48.8155107, lng: 19.209045 },
    { lat: 48.8153375, lng: 19.2092639 },
    { lat: 48.8151134, lng: 19.2092213 },
    { lat: 48.8150861, lng: 19.2087511 },
    { lat: 48.8149998, lng: 19.2087593 },
    { lat: 48.8148869, lng: 19.2092451 },
    { lat: 48.8144122, lng: 19.2087554 },
    { lat: 48.8143895, lng: 19.2091678 },
    { lat: 48.8142022, lng: 19.20912 },
    { lat: 48.8140534, lng: 19.2087947 },
    { lat: 48.813814, lng: 19.2084805 },
    { lat: 48.813629, lng: 19.2083631 },
    { lat: 48.8137931, lng: 19.2080751 },
    { lat: 48.8133695, lng: 19.2077082 },
    { lat: 48.8131026, lng: 19.2077379 },
    { lat: 48.8133473, lng: 19.2073008 },
    { lat: 48.8133028, lng: 19.2072396 },
    { lat: 48.8130797, lng: 19.2073338 },
    { lat: 48.8129395, lng: 19.2072093 },
    { lat: 48.8128487, lng: 19.2072229 },
    { lat: 48.8123871, lng: 19.2070037 },
    { lat: 48.8120589, lng: 19.2067674 },
    { lat: 48.8118139, lng: 19.2063147 },
    { lat: 48.8117724, lng: 19.2063877 },
    { lat: 48.8115821, lng: 19.2061364 },
    { lat: 48.8115496, lng: 19.2056536 },
    { lat: 48.8114322, lng: 19.205352 },
    { lat: 48.8115425, lng: 19.2052299 },
    { lat: 48.8115694, lng: 19.2049039 },
    { lat: 48.8117222, lng: 19.2043844 },
    { lat: 48.8119051, lng: 19.2043908 },
    { lat: 48.8118973, lng: 19.2042301 },
    { lat: 48.8118072, lng: 19.2040688 },
    { lat: 48.8118216, lng: 19.2033001 },
    { lat: 48.8120882, lng: 19.2020808 },
    { lat: 48.8121547, lng: 19.201224 },
    { lat: 48.8122514, lng: 19.2009457 },
    { lat: 48.8122475, lng: 19.2005148 },
    { lat: 48.8121291, lng: 19.2000974 },
    { lat: 48.8120893, lng: 19.1989771 },
    { lat: 48.812158, lng: 19.1985599 },
    { lat: 48.812257, lng: 19.1983707 },
    { lat: 48.8125381, lng: 19.1971988 },
    { lat: 48.8128884, lng: 19.1964308 },
    { lat: 48.8125709, lng: 19.1968099 },
    { lat: 48.8123731, lng: 19.1974468 },
    { lat: 48.8122453, lng: 19.1975301 },
    { lat: 48.8120604, lng: 19.1974825 },
    { lat: 48.8114856, lng: 19.1967909 },
    { lat: 48.8111073, lng: 19.1964284 },
    { lat: 48.8108786, lng: 19.1963155 },
    { lat: 48.8105175, lng: 19.1963519 },
    { lat: 48.8103336, lng: 19.1963059 },
    { lat: 48.8102376, lng: 19.1961138 },
    { lat: 48.8096749, lng: 19.1956953 },
    { lat: 48.8095176, lng: 19.1952408 },
    { lat: 48.8093056, lng: 19.1955374 },
    { lat: 48.808896, lng: 19.1954411 },
    { lat: 48.8088008, lng: 19.1953152 },
    { lat: 48.8086377, lng: 19.1947887 },
    { lat: 48.8083587, lng: 19.1936633 },
    { lat: 48.8082033, lng: 19.1941531 },
    { lat: 48.808295, lng: 19.1942795 },
    { lat: 48.8083522, lng: 19.1944791 },
    { lat: 48.8083162, lng: 19.1946909 },
    { lat: 48.8078945, lng: 19.1952172 },
    { lat: 48.8074248, lng: 19.1949242 },
    { lat: 48.8072344, lng: 19.1946722 },
    { lat: 48.8070275, lng: 19.1941536 },
    { lat: 48.8070918, lng: 19.193596 },
    { lat: 48.8070012, lng: 19.1935397 },
    { lat: 48.8067968, lng: 19.1939745 },
    { lat: 48.8063842, lng: 19.1938793 },
    { lat: 48.8062429, lng: 19.1937577 },
    { lat: 48.8062255, lng: 19.1933513 },
    { lat: 48.8058961, lng: 19.1931153 },
    { lat: 48.8054778, lng: 19.1919333 },
    { lat: 48.8051988, lng: 19.1917581 },
    { lat: 48.8052327, lng: 19.1914858 },
    { lat: 48.8055016, lng: 19.1914529 },
    { lat: 48.8054896, lng: 19.1912511 },
    { lat: 48.8056, lng: 19.1907624 },
    { lat: 48.8056862, lng: 19.1906849 },
    { lat: 48.8056039, lng: 19.1898748 },
    { lat: 48.8055478, lng: 19.1896729 },
    { lat: 48.8056268, lng: 19.1893945 },
    { lat: 48.8055932, lng: 19.1892624 },
  ],
  Medzibrod: [
    { lat: 48.7939029, lng: 19.3268699 },
    { lat: 48.7937976, lng: 19.3269561 },
    { lat: 48.7934093, lng: 19.3268959 },
    { lat: 48.79249, lng: 19.3279948 },
    { lat: 48.7919767, lng: 19.3282666 },
    { lat: 48.7915908, lng: 19.3280939 },
    { lat: 48.7911002, lng: 19.3283 },
    { lat: 48.7908945, lng: 19.3285926 },
    { lat: 48.7904714, lng: 19.3287249 },
    { lat: 48.7902991, lng: 19.3289452 },
    { lat: 48.7897778, lng: 19.3293566 },
    { lat: 48.7893521, lng: 19.3294834 },
    { lat: 48.7891256, lng: 19.3298162 },
    { lat: 48.7888106, lng: 19.3299703 },
    { lat: 48.7885799, lng: 19.3299071 },
    { lat: 48.7882671, lng: 19.3300105 },
    { lat: 48.7880931, lng: 19.330215 },
    { lat: 48.7878027, lng: 19.3302709 },
    { lat: 48.7875791, lng: 19.3304264 },
    { lat: 48.7874027, lng: 19.3307245 },
    { lat: 48.7871139, lng: 19.3308842 },
    { lat: 48.7870955, lng: 19.3314466 },
    { lat: 48.7868986, lng: 19.3315975 },
    { lat: 48.7867567, lng: 19.3313588 },
    { lat: 48.7865558, lng: 19.3316834 },
    { lat: 48.7864276, lng: 19.3322846 },
    { lat: 48.7863251, lng: 19.3323108 },
    { lat: 48.7861771, lng: 19.332704 },
    { lat: 48.7858891, lng: 19.3329065 },
    { lat: 48.7857487, lng: 19.3335087 },
    { lat: 48.7853742, lng: 19.3336452 },
    { lat: 48.7849153, lng: 19.3340168 },
    { lat: 48.7848417, lng: 19.3342035 },
    { lat: 48.7844189, lng: 19.3348135 },
    { lat: 48.7842635, lng: 19.335126 },
    { lat: 48.7837225, lng: 19.3357642 },
    { lat: 48.7835036, lng: 19.3360925 },
    { lat: 48.7834364, lng: 19.3363132 },
    { lat: 48.7832896, lng: 19.336406 },
    { lat: 48.78321, lng: 19.336623 },
    { lat: 48.782786, lng: 19.3370494 },
    { lat: 48.7826253, lng: 19.3370122 },
    { lat: 48.7823356, lng: 19.3374822 },
    { lat: 48.7821126, lng: 19.3376051 },
    { lat: 48.7820709, lng: 19.3375325 },
    { lat: 48.781953, lng: 19.3376861 },
    { lat: 48.7824296, lng: 19.3385166 },
    { lat: 48.7831632, lng: 19.3402423 },
    { lat: 48.783418, lng: 19.3405313 },
    { lat: 48.7815561, lng: 19.3441888 },
    { lat: 48.7805184, lng: 19.3464 },
    { lat: 48.7798158, lng: 19.3472031 },
    { lat: 48.7798234, lng: 19.3481443 },
    { lat: 48.7805947, lng: 19.34889 },
    { lat: 48.7805283, lng: 19.3496326 },
    { lat: 48.7803718, lng: 19.3501328 },
    { lat: 48.7791432, lng: 19.3509126 },
    { lat: 48.7788722, lng: 19.3511893 },
    { lat: 48.7784057, lng: 19.352001 },
    { lat: 48.7773613, lng: 19.3526039 },
    { lat: 48.7772796, lng: 19.3529483 },
    { lat: 48.7772322, lng: 19.3539704 },
    { lat: 48.7768686, lng: 19.3550944 },
    { lat: 48.7762657, lng: 19.3562188 },
    { lat: 48.7758038, lng: 19.356792 },
    { lat: 48.7744214, lng: 19.3593009 },
    { lat: 48.7735361, lng: 19.3589094 },
    { lat: 48.7730315, lng: 19.3584628 },
    { lat: 48.772804, lng: 19.358396 },
    { lat: 48.7710998, lng: 19.3569653 },
    { lat: 48.7700665, lng: 19.3576611 },
    { lat: 48.769757, lng: 19.3577639 },
    { lat: 48.7694553, lng: 19.3577016 },
    { lat: 48.7685502, lng: 19.3586035 },
    { lat: 48.7682435, lng: 19.3592688 },
    { lat: 48.7677421, lng: 19.3600069 },
    { lat: 48.7672289, lng: 19.3600971 },
    { lat: 48.7669709, lng: 19.3602651 },
    { lat: 48.766765, lng: 19.360579 },
    { lat: 48.7666853, lng: 19.3609473 },
    { lat: 48.7664937, lng: 19.3610406 },
    { lat: 48.7663391, lng: 19.3612274 },
    { lat: 48.7658054, lng: 19.3615924 },
    { lat: 48.7653565, lng: 19.3618022 },
    { lat: 48.7645416, lng: 19.3629178 },
    { lat: 48.7639169, lng: 19.363437 },
    { lat: 48.7636188, lng: 19.3637801 },
    { lat: 48.763478, lng: 19.3642147 },
    { lat: 48.7637912, lng: 19.3646649 },
    { lat: 48.7639624, lng: 19.3656884 },
    { lat: 48.7639288, lng: 19.3667663 },
    { lat: 48.7640489, lng: 19.3677779 },
    { lat: 48.7638526, lng: 19.3686554 },
    { lat: 48.7635333, lng: 19.3691765 },
    { lat: 48.7634071, lng: 19.3697496 },
    { lat: 48.7635259, lng: 19.3716633 },
    { lat: 48.7636435, lng: 19.3722479 },
    { lat: 48.7642869, lng: 19.3725995 },
    { lat: 48.7645982, lng: 19.3733208 },
    { lat: 48.764872, lng: 19.3742279 },
    { lat: 48.7651629, lng: 19.3748016 },
    { lat: 48.765477, lng: 19.3752011 },
    { lat: 48.7669478, lng: 19.3763265 },
    { lat: 48.767418, lng: 19.3767627 },
    { lat: 48.7677912, lng: 19.3774791 },
    { lat: 48.7679298, lng: 19.3779336 },
    { lat: 48.7679728, lng: 19.3784398 },
    { lat: 48.768675, lng: 19.3797774 },
    { lat: 48.7689084, lng: 19.3799959 },
    { lat: 48.769216, lng: 19.3804475 },
    { lat: 48.7695119, lng: 19.3807739 },
    { lat: 48.7698821, lng: 19.3810712 },
    { lat: 48.7702541, lng: 19.3818393 },
    { lat: 48.7707603, lng: 19.3822456 },
    { lat: 48.7708455, lng: 19.3824307 },
    { lat: 48.7710523, lng: 19.3826285 },
    { lat: 48.771531, lng: 19.3834365 },
    { lat: 48.7721606, lng: 19.3840028 },
    { lat: 48.7726181, lng: 19.3834258 },
    { lat: 48.7737901, lng: 19.382758 },
    { lat: 48.7742723, lng: 19.380312 },
    { lat: 48.7749385, lng: 19.3788841 },
    { lat: 48.7757918, lng: 19.3778583 },
    { lat: 48.7759624, lng: 19.3769126 },
    { lat: 48.7763285, lng: 19.3757636 },
    { lat: 48.7765484, lng: 19.374307 },
    { lat: 48.7776371, lng: 19.3733264 },
    { lat: 48.7777457, lng: 19.3726886 },
    { lat: 48.77796, lng: 19.3722152 },
    { lat: 48.7784383, lng: 19.3716067 },
    { lat: 48.7788858, lng: 19.3713922 },
    { lat: 48.7792383, lng: 19.3704586 },
    { lat: 48.7796404, lng: 19.3697569 },
    { lat: 48.7808298, lng: 19.3695532 },
    { lat: 48.7811897, lng: 19.3693234 },
    { lat: 48.7824271, lng: 19.3691289 },
    { lat: 48.7826385, lng: 19.3690738 },
    { lat: 48.784038, lng: 19.3687087 },
    { lat: 48.7852217, lng: 19.3687858 },
    { lat: 48.7863234, lng: 19.3686542 },
    { lat: 48.786935, lng: 19.369078 },
    { lat: 48.7871492, lng: 19.3696717 },
    { lat: 48.7883466, lng: 19.3678227 },
    { lat: 48.7897772, lng: 19.365756 },
    { lat: 48.7905821, lng: 19.3650481 },
    { lat: 48.791354, lng: 19.3647797 },
    { lat: 48.7922378, lng: 19.3667897 },
    { lat: 48.7923785, lng: 19.3673339 },
    { lat: 48.7929031, lng: 19.3673893 },
    { lat: 48.7934382, lng: 19.3676249 },
    { lat: 48.7938032, lng: 19.3675768 },
    { lat: 48.7959979, lng: 19.368396 },
    { lat: 48.7974808, lng: 19.3676968 },
    { lat: 48.7997841, lng: 19.3669953 },
    { lat: 48.8033755, lng: 19.3635362 },
    { lat: 48.8040342, lng: 19.3628081 },
    { lat: 48.8048423, lng: 19.3620666 },
    { lat: 48.8062386, lng: 19.3609185 },
    { lat: 48.807321, lng: 19.359907 },
    { lat: 48.807556, lng: 19.3599924 },
    { lat: 48.8077989, lng: 19.359789 },
    { lat: 48.8082264, lng: 19.3596758 },
    { lat: 48.8085462, lng: 19.3597649 },
    { lat: 48.8087419, lng: 19.3596382 },
    { lat: 48.8091693, lng: 19.359737 },
    { lat: 48.8093988, lng: 19.3597057 },
    { lat: 48.8095254, lng: 19.3595726 },
    { lat: 48.8097033, lng: 19.3593277 },
    { lat: 48.809911, lng: 19.3588371 },
    { lat: 48.8098694, lng: 19.3587662 },
    { lat: 48.811394, lng: 19.3571158 },
    { lat: 48.8126247, lng: 19.3560691 },
    { lat: 48.8134556, lng: 19.354933 },
    { lat: 48.8169889, lng: 19.3486478 },
    { lat: 48.8171232, lng: 19.3480839 },
    { lat: 48.8176556, lng: 19.3474968 },
    { lat: 48.8178363, lng: 19.3474081 },
    { lat: 48.8182022, lng: 19.346995 },
    { lat: 48.8186956, lng: 19.3467772 },
    { lat: 48.8189281, lng: 19.3465456 },
    { lat: 48.8195863, lng: 19.346111 },
    { lat: 48.8198755, lng: 19.3459201 },
    { lat: 48.8202416, lng: 19.3460094 },
    { lat: 48.8205374, lng: 19.3459723 },
    { lat: 48.8216728, lng: 19.3451525 },
    { lat: 48.8228169, lng: 19.3444426 },
    { lat: 48.8235579, lng: 19.3438352 },
    { lat: 48.823751, lng: 19.3436769 },
    { lat: 48.8249675, lng: 19.3434029 },
    { lat: 48.8255775, lng: 19.3433373 },
    { lat: 48.825976, lng: 19.3434942 },
    { lat: 48.826136, lng: 19.343396 },
    { lat: 48.8263709, lng: 19.3433783 },
    { lat: 48.8264214, lng: 19.3435188 },
    { lat: 48.8278818, lng: 19.343611 },
    { lat: 48.827972, lng: 19.3435174 },
    { lat: 48.8283093, lng: 19.3431674 },
    { lat: 48.8283696, lng: 19.3431048 },
    { lat: 48.828786, lng: 19.3425723 },
    { lat: 48.8290466, lng: 19.3424102 },
    { lat: 48.8296225, lng: 19.3422885 },
    { lat: 48.8298995, lng: 19.3416198 },
    { lat: 48.8300706, lng: 19.3415179 },
    { lat: 48.8304256, lng: 19.3410904 },
    { lat: 48.8310522, lng: 19.3408523 },
    { lat: 48.8317932, lng: 19.3402733 },
    { lat: 48.8334564, lng: 19.3399893 },
    { lat: 48.8334914, lng: 19.3399833 },
    { lat: 48.8341028, lng: 19.3400024 },
    { lat: 48.8344245, lng: 19.3400124 },
    { lat: 48.8345109, lng: 19.3400151 },
    { lat: 48.8346518, lng: 19.3399829 },
    { lat: 48.8353347, lng: 19.3398267 },
    { lat: 48.8360386, lng: 19.3400664 },
    { lat: 48.8365639, lng: 19.3402453 },
    { lat: 48.8372707, lng: 19.3400581 },
    { lat: 48.8378267, lng: 19.3395415 },
    { lat: 48.8388983, lng: 19.3382552 },
    { lat: 48.8392801, lng: 19.3379389 },
    { lat: 48.8394591, lng: 19.337631 },
    { lat: 48.8408056, lng: 19.3359855 },
    { lat: 48.8411649, lng: 19.3358637 },
    { lat: 48.8416424, lng: 19.3353541 },
    { lat: 48.8426967, lng: 19.333848 },
    { lat: 48.8436513, lng: 19.3321138 },
    { lat: 48.8440737, lng: 19.3309724 },
    { lat: 48.844391, lng: 19.3304634 },
    { lat: 48.8448135, lng: 19.3303389 },
    { lat: 48.8456819, lng: 19.329165 },
    { lat: 48.8468029, lng: 19.3280898 },
    { lat: 48.8457415, lng: 19.3281691 },
    { lat: 48.8450706, lng: 19.32816 },
    { lat: 48.8450872, lng: 19.3279435 },
    { lat: 48.8449028, lng: 19.3278104 },
    { lat: 48.8449334, lng: 19.3276697 },
    { lat: 48.8446631, lng: 19.327268 },
    { lat: 48.8442823, lng: 19.3271328 },
    { lat: 48.8438745, lng: 19.3266119 },
    { lat: 48.8434557, lng: 19.3258855 },
    { lat: 48.8432148, lng: 19.3258209 },
    { lat: 48.8430872, lng: 19.3255124 },
    { lat: 48.8430361, lng: 19.3250983 },
    { lat: 48.8421682, lng: 19.3241264 },
    { lat: 48.84141, lng: 19.3237519 },
    { lat: 48.8402492, lng: 19.323518 },
    { lat: 48.8399564, lng: 19.3235256 },
    { lat: 48.8398155, lng: 19.3232648 },
    { lat: 48.8391189, lng: 19.322577 },
    { lat: 48.8389511, lng: 19.3219388 },
    { lat: 48.8385465, lng: 19.3219139 },
    { lat: 48.838392, lng: 19.3221011 },
    { lat: 48.8377617, lng: 19.3223116 },
    { lat: 48.8371425, lng: 19.3220143 },
    { lat: 48.8368274, lng: 19.3221228 },
    { lat: 48.8366181, lng: 19.3223156 },
    { lat: 48.835982, lng: 19.3219514 },
    { lat: 48.8353883, lng: 19.3219805 },
    { lat: 48.8352254, lng: 19.3220423 },
    { lat: 48.8347617, lng: 19.3218618 },
    { lat: 48.8335408, lng: 19.3221415 },
    { lat: 48.8328845, lng: 19.3220636 },
    { lat: 48.8324784, lng: 19.3218676 },
    { lat: 48.8319901, lng: 19.3218147 },
    { lat: 48.8318632, lng: 19.3215853 },
    { lat: 48.830393, lng: 19.3202258 },
    { lat: 48.8303295, lng: 19.320099 },
    { lat: 48.8298293, lng: 19.320008 },
    { lat: 48.8296033, lng: 19.3200939 },
    { lat: 48.8292631, lng: 19.3197894 },
    { lat: 48.8290966, lng: 19.319818 },
    { lat: 48.8290291, lng: 19.3198951 },
    { lat: 48.8282371, lng: 19.3200156 },
    { lat: 48.8280393, lng: 19.3201481 },
    { lat: 48.8277688, lng: 19.3201265 },
    { lat: 48.8272153, lng: 19.3202421 },
    { lat: 48.8266677, lng: 19.3201939 },
    { lat: 48.8264529, lng: 19.3203253 },
    { lat: 48.8259885, lng: 19.3195659 },
    { lat: 48.825765, lng: 19.319439 },
    { lat: 48.8252126, lng: 19.3193913 },
    { lat: 48.8250387, lng: 19.3192898 },
    { lat: 48.824564, lng: 19.3192848 },
    { lat: 48.824254, lng: 19.3194263 },
    { lat: 48.8239901, lng: 19.3194433 },
    { lat: 48.8237112, lng: 19.3198104 },
    { lat: 48.8232165, lng: 19.3202156 },
    { lat: 48.8225526, lng: 19.3211129 },
    { lat: 48.821971, lng: 19.3214476 },
    { lat: 48.8216424, lng: 19.3215496 },
    { lat: 48.8209686, lng: 19.3215534 },
    { lat: 48.8199791, lng: 19.3217441 },
    { lat: 48.8190296, lng: 19.3213067 },
    { lat: 48.8188183, lng: 19.3213433 },
    { lat: 48.8182673, lng: 19.3219378 },
    { lat: 48.8169219, lng: 19.3226794 },
    { lat: 48.8163869, lng: 19.3230389 },
    { lat: 48.8158925, lng: 19.323286 },
    { lat: 48.8151256, lng: 19.3235589 },
    { lat: 48.8147321, lng: 19.3239841 },
    { lat: 48.8138657, lng: 19.3239177 },
    { lat: 48.8135841, lng: 19.3241598 },
    { lat: 48.8125866, lng: 19.3243688 },
    { lat: 48.812006, lng: 19.3250529 },
    { lat: 48.8099015, lng: 19.3283398 },
    { lat: 48.8087007, lng: 19.3293017 },
    { lat: 48.8083713, lng: 19.3292028 },
    { lat: 48.8081939, lng: 19.3292182 },
    { lat: 48.8081109, lng: 19.3294386 },
    { lat: 48.807466, lng: 19.329599 },
    { lat: 48.8071382, lng: 19.3295793 },
    { lat: 48.806764, lng: 19.3296969 },
    { lat: 48.8060505, lng: 19.3293889 },
    { lat: 48.8057713, lng: 19.3295613 },
    { lat: 48.8056303, lng: 19.3295119 },
    { lat: 48.8054234, lng: 19.3293282 },
    { lat: 48.8051438, lng: 19.3289275 },
    { lat: 48.8050171, lng: 19.3289406 },
    { lat: 48.8050338, lng: 19.3288644 },
    { lat: 48.8047697, lng: 19.3287133 },
    { lat: 48.8044082, lng: 19.329574 },
    { lat: 48.8041995, lng: 19.3303258 },
    { lat: 48.8033521, lng: 19.3316559 },
    { lat: 48.8029727, lng: 19.3320215 },
    { lat: 48.8027531, lng: 19.3318144 },
    { lat: 48.8019342, lng: 19.3303785 },
    { lat: 48.8015124, lng: 19.3289976 },
    { lat: 48.7997998, lng: 19.3297148 },
    { lat: 48.7995921, lng: 19.3312221 },
    { lat: 48.7993021, lng: 19.3315515 },
    { lat: 48.7986521, lng: 19.3317929 },
    { lat: 48.7971379, lng: 19.3320904 },
    { lat: 48.7963551, lng: 19.3321221 },
    { lat: 48.7959923, lng: 19.3311785 },
    { lat: 48.7949387, lng: 19.3311821 },
    { lat: 48.7942894, lng: 19.3310616 },
    { lat: 48.794341, lng: 19.3302763 },
    { lat: 48.7944868, lng: 19.3292149 },
    { lat: 48.7946714, lng: 19.3284291 },
    { lat: 48.7946554, lng: 19.3280219 },
    { lat: 48.7944287, lng: 19.3271651 },
    { lat: 48.7943001, lng: 19.3268352 },
    { lat: 48.7939446, lng: 19.3267045 },
    { lat: 48.7939029, lng: 19.3268699 },
  ],
  Selce: [
    { lat: 48.7493217, lng: 19.2127471 },
    { lat: 48.7500172, lng: 19.2124922 },
    { lat: 48.7507712, lng: 19.2131135 },
    { lat: 48.750907, lng: 19.2133574 },
    { lat: 48.7511065, lng: 19.2134513 },
    { lat: 48.75126, lng: 19.2141268 },
    { lat: 48.7517658, lng: 19.2143774 },
    { lat: 48.7519976, lng: 19.2148546 },
    { lat: 48.7525093, lng: 19.2152496 },
    { lat: 48.7533761, lng: 19.2154079 },
    { lat: 48.7537513, lng: 19.2155666 },
    { lat: 48.7542422, lng: 19.2159279 },
    { lat: 48.7546996, lng: 19.21648 },
    { lat: 48.7549969, lng: 19.2165656 },
    { lat: 48.7552409, lng: 19.2167406 },
    { lat: 48.7554736, lng: 19.216589 },
    { lat: 48.7557979, lng: 19.2169731 },
    { lat: 48.7564821, lng: 19.2167881 },
    { lat: 48.7568982, lng: 19.2167962 },
    { lat: 48.7571017, lng: 19.2169672 },
    { lat: 48.7572624, lng: 19.2167503 },
    { lat: 48.7574275, lng: 19.2166434 },
    { lat: 48.7577422, lng: 19.2167856 },
    { lat: 48.7579077, lng: 19.2215411 },
    { lat: 48.7580431, lng: 19.2226371 },
    { lat: 48.7580607, lng: 19.2236233 },
    { lat: 48.7581871, lng: 19.2255813 },
    { lat: 48.7585548, lng: 19.2273964 },
    { lat: 48.7590954, lng: 19.2287594 },
    { lat: 48.76024, lng: 19.2304001 },
    { lat: 48.7604162, lng: 19.2304538 },
    { lat: 48.7622519, lng: 19.2315358 },
    { lat: 48.763949, lng: 19.2320175 },
    { lat: 48.7663016, lng: 19.2311804 },
    { lat: 48.7678077, lng: 19.2303009 },
    { lat: 48.7682604, lng: 19.2300849 },
    { lat: 48.7687135, lng: 19.2299189 },
    { lat: 48.7688519, lng: 19.2291006 },
    { lat: 48.7691559, lng: 19.2284427 },
    { lat: 48.7695846, lng: 19.2279846 },
    { lat: 48.7700666, lng: 19.2271167 },
    { lat: 48.771499, lng: 19.2248812 },
    { lat: 48.7719187, lng: 19.224399 },
    { lat: 48.7723804, lng: 19.2240183 },
    { lat: 48.7728867, lng: 19.2237677 },
    { lat: 48.7729808, lng: 19.2238476 },
    { lat: 48.7734711, lng: 19.223359 },
    { lat: 48.7736624, lng: 19.2234221 },
    { lat: 48.7738253, lng: 19.2235949 },
    { lat: 48.7744969, lng: 19.2225497 },
    { lat: 48.7756607, lng: 19.2211432 },
    { lat: 48.7761286, lng: 19.2203314 },
    { lat: 48.7761838, lng: 19.2194736 },
    { lat: 48.7767429, lng: 19.2201438 },
    { lat: 48.7769803, lng: 19.2199501 },
    { lat: 48.7771969, lng: 19.2201841 },
    { lat: 48.7774213, lng: 19.2199115 },
    { lat: 48.7775755, lng: 19.2195806 },
    { lat: 48.7776631, lng: 19.2191806 },
    { lat: 48.7780659, lng: 19.2180053 },
    { lat: 48.7784173, lng: 19.2176471 },
    { lat: 48.779872, lng: 19.2164782 },
    { lat: 48.7800139, lng: 19.2162448 },
    { lat: 48.7804143, lng: 19.2158514 },
    { lat: 48.7808227, lng: 19.2150258 },
    { lat: 48.780979, lng: 19.2143672 },
    { lat: 48.7806526, lng: 19.2124045 },
    { lat: 48.7812471, lng: 19.2105863 },
    { lat: 48.7817217, lng: 19.2098433 },
    { lat: 48.7818751, lng: 19.209497 },
    { lat: 48.7824902, lng: 19.2087247 },
    { lat: 48.7831471, lng: 19.2082098 },
    { lat: 48.7844467, lng: 19.2074674 },
    { lat: 48.7848446, lng: 19.2073723 },
    { lat: 48.785522, lng: 19.2076053 },
    { lat: 48.7859282, lng: 19.207263 },
    { lat: 48.7862029, lng: 19.2068421 },
    { lat: 48.7870888, lng: 19.205869 },
    { lat: 48.7875817, lng: 19.2056432 },
    { lat: 48.787737, lng: 19.2057278 },
    { lat: 48.7879187, lng: 19.2056861 },
    { lat: 48.7881987, lng: 19.206091 },
    { lat: 48.7883617, lng: 19.2061737 },
    { lat: 48.7889508, lng: 19.2062378 },
    { lat: 48.7892919, lng: 19.2063885 },
    { lat: 48.7894399, lng: 19.2065558 },
    { lat: 48.7896663, lng: 19.2066031 },
    { lat: 48.7899719, lng: 19.2065337 },
    { lat: 48.7898284, lng: 19.2060646 },
    { lat: 48.789439, lng: 19.2036435 },
    { lat: 48.7896205, lng: 19.2025308 },
    { lat: 48.7900014, lng: 19.2021097 },
    { lat: 48.7904163, lng: 19.2020931 },
    { lat: 48.790938, lng: 19.2017179 },
    { lat: 48.7911752, lng: 19.2014487 },
    { lat: 48.791547, lng: 19.2004055 },
    { lat: 48.7919053, lng: 19.2002594 },
    { lat: 48.7920682, lng: 19.1999716 },
    { lat: 48.7922926, lng: 19.1997682 },
    { lat: 48.7929546, lng: 19.1994509 },
    { lat: 48.7931376, lng: 19.1994344 },
    { lat: 48.7936948, lng: 19.1992117 },
    { lat: 48.7939814, lng: 19.1988687 },
    { lat: 48.7943341, lng: 19.1981272 },
    { lat: 48.7944062, lng: 19.1981059 },
    { lat: 48.7944855, lng: 19.1985053 },
    { lat: 48.7950305, lng: 19.1978971 },
    { lat: 48.79506, lng: 19.1979936 },
    { lat: 48.7953874, lng: 19.1977701 },
    { lat: 48.7956327, lng: 19.1978757 },
    { lat: 48.7955965, lng: 19.1970392 },
    { lat: 48.7956949, lng: 19.1964697 },
    { lat: 48.7961685, lng: 19.1952252 },
    { lat: 48.7965466, lng: 19.194607 },
    { lat: 48.7966469, lng: 19.1945625 },
    { lat: 48.7967838, lng: 19.1942741 },
    { lat: 48.7972504, lng: 19.194222 },
    { lat: 48.797632, lng: 19.193426 },
    { lat: 48.7984779, lng: 19.1925832 },
    { lat: 48.7987224, lng: 19.1924193 },
    { lat: 48.7988797, lng: 19.1921289 },
    { lat: 48.7992498, lng: 19.1918133 },
    { lat: 48.7995629, lng: 19.19137 },
    { lat: 48.800402, lng: 19.1904559 },
    { lat: 48.8006529, lng: 19.191 },
    { lat: 48.8006423, lng: 19.1912901 },
    { lat: 48.8007363, lng: 19.1914162 },
    { lat: 48.8008743, lng: 19.1917966 },
    { lat: 48.8011337, lng: 19.191985 },
    { lat: 48.8014884, lng: 19.1919986 },
    { lat: 48.8017224, lng: 19.1916188 },
    { lat: 48.802479, lng: 19.1913008 },
    { lat: 48.8027313, lng: 19.1919702 },
    { lat: 48.8035161, lng: 19.1918564 },
    { lat: 48.804063, lng: 19.1908067 },
    { lat: 48.8043097, lng: 19.1903331 },
    { lat: 48.8049066, lng: 19.1898827 },
    { lat: 48.8051122, lng: 19.1895893 },
    { lat: 48.8053784, lng: 19.1895024 },
    { lat: 48.8055932, lng: 19.1892624 },
    { lat: 48.805805, lng: 19.1889794 },
    { lat: 48.8059929, lng: 19.1883973 },
    { lat: 48.8066256, lng: 19.1877111 },
    { lat: 48.806756, lng: 19.1873827 },
    { lat: 48.8068515, lng: 19.1871423 },
    { lat: 48.8067929, lng: 19.1861246 },
    { lat: 48.8068974, lng: 19.1854986 },
    { lat: 48.8070273, lng: 19.1850188 },
    { lat: 48.8073529, lng: 19.1841602 },
    { lat: 48.8077411, lng: 19.1834316 },
    { lat: 48.8079921, lng: 19.1831917 },
    { lat: 48.8083073, lng: 19.1824451 },
    { lat: 48.8086994, lng: 19.1817722 },
    { lat: 48.8091895, lng: 19.1814863 },
    { lat: 48.8097093, lng: 19.1804982 },
    { lat: 48.8104549, lng: 19.1775558 },
    { lat: 48.8104582, lng: 19.1766323 },
    { lat: 48.8101266, lng: 19.1753391 },
    { lat: 48.8101407, lng: 19.1749108 },
    { lat: 48.8103106, lng: 19.1743035 },
    { lat: 48.8112347, lng: 19.1730779 },
    { lat: 48.8113189, lng: 19.1727713 },
    { lat: 48.8117726, lng: 19.1721134 },
    { lat: 48.8122957, lng: 19.1714936 },
    { lat: 48.8133283, lng: 19.1700004 },
    { lat: 48.8138635, lng: 19.1695115 },
    { lat: 48.81433, lng: 19.1691834 },
    { lat: 48.8149584, lng: 19.1684562 },
    { lat: 48.8161389, lng: 19.1673597 },
    { lat: 48.8164284, lng: 19.1670089 },
    { lat: 48.8168668, lng: 19.1667053 },
    { lat: 48.8174067, lng: 19.1658545 },
    { lat: 48.8175942, lng: 19.1652408 },
    { lat: 48.8176224, lng: 19.1649189 },
    { lat: 48.8179958, lng: 19.1639831 },
    { lat: 48.8182952, lng: 19.1629579 },
    { lat: 48.8184822, lng: 19.1626102 },
    { lat: 48.8185774, lng: 19.1624702 },
    { lat: 48.8188864, lng: 19.1620269 },
    { lat: 48.8191569, lng: 19.1617973 },
    { lat: 48.8196483, lng: 19.1610803 },
    { lat: 48.820318, lng: 19.1603958 },
    { lat: 48.820467, lng: 19.1598217 },
    { lat: 48.820952, lng: 19.1589802 },
    { lat: 48.8211947, lng: 19.1586167 },
    { lat: 48.821579, lng: 19.1584078 },
    { lat: 48.8218208, lng: 19.1580079 },
    { lat: 48.8216145, lng: 19.1578218 },
    { lat: 48.8212751, lng: 19.1573846 },
    { lat: 48.820319, lng: 19.157011 },
    { lat: 48.8195803, lng: 19.1568898 },
    { lat: 48.8187563, lng: 19.1562227 },
    { lat: 48.8182315, lng: 19.1561845 },
    { lat: 48.81817, lng: 19.1549795 },
    { lat: 48.8179695, lng: 19.1543153 },
    { lat: 48.8179993, lng: 19.154186 },
    { lat: 48.8179671, lng: 19.1526351 },
    { lat: 48.8180343, lng: 19.1520065 },
    { lat: 48.8181872, lng: 19.1515865 },
    { lat: 48.8180147, lng: 19.1513337 },
    { lat: 48.8167504, lng: 19.1499409 },
    { lat: 48.8165406, lng: 19.1498504 },
    { lat: 48.816155, lng: 19.149525 },
    { lat: 48.8157401, lng: 19.1496619 },
    { lat: 48.815103, lng: 19.1502534 },
    { lat: 48.8142422, lng: 19.1495741 },
    { lat: 48.8138604, lng: 19.1496017 },
    { lat: 48.8135142, lng: 19.1494837 },
    { lat: 48.8131148, lng: 19.1494543 },
    { lat: 48.8121939, lng: 19.1500058 },
    { lat: 48.8119402, lng: 19.1499804 },
    { lat: 48.8113966, lng: 19.1496764 },
    { lat: 48.8110935, lng: 19.1497967 },
    { lat: 48.8106961, lng: 19.149785 },
    { lat: 48.8106762, lng: 19.1500665 },
    { lat: 48.8099546, lng: 19.150568 },
    { lat: 48.8096626, lng: 19.1509567 },
    { lat: 48.8087909, lng: 19.1504204 },
    { lat: 48.8077311, lng: 19.1495716 },
    { lat: 48.8075159, lng: 19.149686 },
    { lat: 48.8072813, lng: 19.1496859 },
    { lat: 48.8067305, lng: 19.1491193 },
    { lat: 48.8064916, lng: 19.1493961 },
    { lat: 48.8060439, lng: 19.1496683 },
    { lat: 48.8058112, lng: 19.1495428 },
    { lat: 48.8056635, lng: 19.1493356 },
    { lat: 48.8049856, lng: 19.1490529 },
    { lat: 48.804, lng: 19.1490877 },
    { lat: 48.8040074, lng: 19.1504315 },
    { lat: 48.8040936, lng: 19.1517739 },
    { lat: 48.8043408, lng: 19.1531811 },
    { lat: 48.8041289, lng: 19.1546289 },
    { lat: 48.803669, lng: 19.1565581 },
    { lat: 48.8036485, lng: 19.1572194 },
    { lat: 48.8035724, lng: 19.1573492 },
    { lat: 48.8033878, lng: 19.1585497 },
    { lat: 48.8033044, lng: 19.1588001 },
    { lat: 48.8031406, lng: 19.1590248 },
    { lat: 48.8030584, lng: 19.1593713 },
    { lat: 48.8020858, lng: 19.1602505 },
    { lat: 48.801641, lng: 19.1607839 },
    { lat: 48.8014236, lng: 19.1609038 },
    { lat: 48.8011722, lng: 19.160951 },
    { lat: 48.8009711, lng: 19.1612861 },
    { lat: 48.8004227, lng: 19.1616199 },
    { lat: 48.7989976, lng: 19.1617978 },
    { lat: 48.7984489, lng: 19.1619185 },
    { lat: 48.7979254, lng: 19.1621629 },
    { lat: 48.7972569, lng: 19.1623209 },
    { lat: 48.7968249, lng: 19.1625003 },
    { lat: 48.7966193, lng: 19.1626779 },
    { lat: 48.7951217, lng: 19.1625962 },
    { lat: 48.7935949, lng: 19.1622407 },
    { lat: 48.7930551, lng: 19.1623356 },
    { lat: 48.7919727, lng: 19.1626674 },
    { lat: 48.791688, lng: 19.1626322 },
    { lat: 48.7916356, lng: 19.1627393 },
    { lat: 48.7913903, lng: 19.1626546 },
    { lat: 48.7912261, lng: 19.1627059 },
    { lat: 48.7904301, lng: 19.162678 },
    { lat: 48.7902962, lng: 19.162756 },
    { lat: 48.7898585, lng: 19.1626114 },
    { lat: 48.7894399, lng: 19.1627185 },
    { lat: 48.7889862, lng: 19.1629607 },
    { lat: 48.7889538, lng: 19.162896 },
    { lat: 48.788531, lng: 19.1628913 },
    { lat: 48.7882998, lng: 19.1627536 },
    { lat: 48.7880297, lng: 19.1629491 },
    { lat: 48.7873159, lng: 19.1629871 },
    { lat: 48.786933, lng: 19.1631076 },
    { lat: 48.7860657, lng: 19.1636733 },
    { lat: 48.7858713, lng: 19.1636894 },
    { lat: 48.7853155, lng: 19.1641029 },
    { lat: 48.785031, lng: 19.1642081 },
    { lat: 48.7846274, lng: 19.1645022 },
    { lat: 48.7834817, lng: 19.1650279 },
    { lat: 48.7830569, lng: 19.1653047 },
    { lat: 48.7827217, lng: 19.1656426 },
    { lat: 48.7825237, lng: 19.1659733 },
    { lat: 48.7822051, lng: 19.1663268 },
    { lat: 48.7821849, lng: 19.1664449 },
    { lat: 48.7821257, lng: 19.1664505 },
    { lat: 48.7820127, lng: 19.1666323 },
    { lat: 48.7819442, lng: 19.1669606 },
    { lat: 48.7817145, lng: 19.1670546 },
    { lat: 48.7816347, lng: 19.1672684 },
    { lat: 48.7815745, lng: 19.1672834 },
    { lat: 48.7815435, lng: 19.1674548 },
    { lat: 48.7814247, lng: 19.1675375 },
    { lat: 48.7812857, lng: 19.1678533 },
    { lat: 48.7810449, lng: 19.1681166 },
    { lat: 48.7808993, lng: 19.1683853 },
    { lat: 48.7807148, lng: 19.1684428 },
    { lat: 48.7804935, lng: 19.1686653 },
    { lat: 48.7778924, lng: 19.1700797 },
    { lat: 48.7772016, lng: 19.170773 },
    { lat: 48.7765284, lng: 19.1712559 },
    { lat: 48.7756256, lng: 19.1722456 },
    { lat: 48.7753914, lng: 19.1723687 },
    { lat: 48.7736038, lng: 19.1745597 },
    { lat: 48.7733008, lng: 19.1751847 },
    { lat: 48.7730018, lng: 19.1755227 },
    { lat: 48.7725815, lng: 19.1759411 },
    { lat: 48.7720974, lng: 19.1762784 },
    { lat: 48.7718597, lng: 19.1763486 },
    { lat: 48.771186, lng: 19.1768883 },
    { lat: 48.7707609, lng: 19.1774176 },
    { lat: 48.7701976, lng: 19.178364 },
    { lat: 48.7697722, lng: 19.1792793 },
    { lat: 48.7693275, lng: 19.1796378 },
    { lat: 48.7693119, lng: 19.1797339 },
    { lat: 48.7687834, lng: 19.1805431 },
    { lat: 48.7685542, lng: 19.1814364 },
    { lat: 48.7686317, lng: 19.1817068 },
    { lat: 48.7685064, lng: 19.1818637 },
    { lat: 48.7684516, lng: 19.1818506 },
    { lat: 48.7683155, lng: 19.182067 },
    { lat: 48.7683542, lng: 19.1821623 },
    { lat: 48.7682157, lng: 19.1825806 },
    { lat: 48.7680647, lng: 19.1826778 },
    { lat: 48.7680629, lng: 19.1829825 },
    { lat: 48.7679445, lng: 19.1833056 },
    { lat: 48.7678065, lng: 19.1833446 },
    { lat: 48.7677416, lng: 19.183489 },
    { lat: 48.7678697, lng: 19.1838052 },
    { lat: 48.7678555, lng: 19.1839308 },
    { lat: 48.7677791, lng: 19.1839823 },
    { lat: 48.7677346, lng: 19.1841788 },
    { lat: 48.767571, lng: 19.1844075 },
    { lat: 48.7673951, lng: 19.184429 },
    { lat: 48.7673334, lng: 19.1845967 },
    { lat: 48.7672535, lng: 19.1846671 },
    { lat: 48.7672337, lng: 19.184953 },
    { lat: 48.7670821, lng: 19.1850361 },
    { lat: 48.7671666, lng: 19.1852685 },
    { lat: 48.7670562, lng: 19.1853573 },
    { lat: 48.7669362, lng: 19.185305 },
    { lat: 48.7669486, lng: 19.1855584 },
    { lat: 48.7668792, lng: 19.1856608 },
    { lat: 48.7667223, lng: 19.1856305 },
    { lat: 48.766442, lng: 19.1857769 },
    { lat: 48.7664398, lng: 19.1858965 },
    { lat: 48.7663079, lng: 19.1861953 },
    { lat: 48.7661939, lng: 19.1863313 },
    { lat: 48.7660849, lng: 19.1863582 },
    { lat: 48.7660344, lng: 19.1864574 },
    { lat: 48.7660862, lng: 19.1865688 },
    { lat: 48.7660541, lng: 19.1867929 },
    { lat: 48.7659749, lng: 19.1867856 },
    { lat: 48.7658761, lng: 19.1869303 },
    { lat: 48.7657032, lng: 19.186945 },
    { lat: 48.7656106, lng: 19.1871722 },
    { lat: 48.7654705, lng: 19.1873072 },
    { lat: 48.7653937, lng: 19.1872779 },
    { lat: 48.7653238, lng: 19.1874183 },
    { lat: 48.7652957, lng: 19.1876505 },
    { lat: 48.7650054, lng: 19.1875918 },
    { lat: 48.7648805, lng: 19.1876408 },
    { lat: 48.7648031, lng: 19.1879714 },
    { lat: 48.7646756, lng: 19.1880164 },
    { lat: 48.7646733, lng: 19.1883625 },
    { lat: 48.7645473, lng: 19.1885048 },
    { lat: 48.7640397, lng: 19.1887274 },
    { lat: 48.7639079, lng: 19.1888489 },
    { lat: 48.7637798, lng: 19.1891316 },
    { lat: 48.7635237, lng: 19.1892674 },
    { lat: 48.7632808, lng: 19.189232 },
    { lat: 48.7630429, lng: 19.1887901 },
    { lat: 48.762857, lng: 19.1889799 },
    { lat: 48.7629007, lng: 19.1892748 },
    { lat: 48.7628618, lng: 19.1895378 },
    { lat: 48.762758, lng: 19.1896084 },
    { lat: 48.7626768, lng: 19.1894874 },
    { lat: 48.7625852, lng: 19.1894844 },
    { lat: 48.7622693, lng: 19.1895929 },
    { lat: 48.762197, lng: 19.1897968 },
    { lat: 48.7621021, lng: 19.1897674 },
    { lat: 48.7620335, lng: 19.1896604 },
    { lat: 48.7619448, lng: 19.1899189 },
    { lat: 48.7618547, lng: 19.1899701 },
    { lat: 48.761697, lng: 19.189898 },
    { lat: 48.761515, lng: 19.190028 },
    { lat: 48.7614249, lng: 19.1902375 },
    { lat: 48.7612859, lng: 19.1901823 },
    { lat: 48.7609192, lng: 19.1903397 },
    { lat: 48.7607816, lng: 19.1902454 },
    { lat: 48.7608056, lng: 19.1901816 },
    { lat: 48.7605604, lng: 19.1902379 },
    { lat: 48.7603748, lng: 19.1901775 },
    { lat: 48.7603504, lng: 19.1902571 },
    { lat: 48.7602246, lng: 19.1902886 },
    { lat: 48.7599125, lng: 19.1899437 },
    { lat: 48.7598884, lng: 19.1901222 },
    { lat: 48.7597052, lng: 19.1900623 },
    { lat: 48.7595616, lng: 19.190218 },
    { lat: 48.7594691, lng: 19.1902151 },
    { lat: 48.75927, lng: 19.1905432 },
    { lat: 48.7592323, lng: 19.1905124 },
    { lat: 48.7591219, lng: 19.1906065 },
    { lat: 48.7588343, lng: 19.1906715 },
    { lat: 48.7587822, lng: 19.1907602 },
    { lat: 48.7582428, lng: 19.1906761 },
    { lat: 48.7581452, lng: 19.1907824 },
    { lat: 48.7582049, lng: 19.1909161 },
    { lat: 48.7581846, lng: 19.1910088 },
    { lat: 48.7580373, lng: 19.1911556 },
    { lat: 48.7578945, lng: 19.1910019 },
    { lat: 48.7577223, lng: 19.191075 },
    { lat: 48.7576579, lng: 19.1910283 },
    { lat: 48.7576943, lng: 19.1909167 },
    { lat: 48.7576759, lng: 19.1906812 },
    { lat: 48.7576527, lng: 19.1905984 },
    { lat: 48.7575398, lng: 19.1905495 },
    { lat: 48.7574722, lng: 19.1907811 },
    { lat: 48.757416, lng: 19.1908288 },
    { lat: 48.7572976, lng: 19.1906452 },
    { lat: 48.7571874, lng: 19.1906468 },
    { lat: 48.7572417, lng: 19.1910422 },
    { lat: 48.7567601, lng: 19.190996 },
    { lat: 48.7567696, lng: 19.1910971 },
    { lat: 48.7561224, lng: 19.1909765 },
    { lat: 48.756197, lng: 19.1912139 },
    { lat: 48.7547508, lng: 19.1906352 },
    { lat: 48.7547534, lng: 19.1906217 },
    { lat: 48.7545908, lng: 19.1906384 },
    { lat: 48.7545347, lng: 19.1908244 },
    { lat: 48.7547329, lng: 19.1914931 },
    { lat: 48.754613, lng: 19.1916015 },
    { lat: 48.7543487, lng: 19.1915874 },
    { lat: 48.7542365, lng: 19.1915046 },
    { lat: 48.7541469, lng: 19.1912417 },
    { lat: 48.7539471, lng: 19.1909807 },
    { lat: 48.7539256, lng: 19.191006 },
    { lat: 48.7536967, lng: 19.192065 },
    { lat: 48.7538188, lng: 19.1928594 },
    { lat: 48.7538384, lng: 19.1932612 },
    { lat: 48.75358, lng: 19.1948247 },
    { lat: 48.753623, lng: 19.1955458 },
    { lat: 48.7531345, lng: 19.1971535 },
    { lat: 48.7528874, lng: 19.1981423 },
    { lat: 48.752838, lng: 19.1993966 },
    { lat: 48.7525557, lng: 19.2015142 },
    { lat: 48.7527728, lng: 19.201414 },
    { lat: 48.7528358, lng: 19.2021732 },
    { lat: 48.7525151, lng: 19.2019078 },
    { lat: 48.7519043, lng: 19.2032764 },
    { lat: 48.7516736, lng: 19.2046742 },
    { lat: 48.7512196, lng: 19.2059257 },
    { lat: 48.7506096, lng: 19.2069423 },
    { lat: 48.7503957, lng: 19.2084295 },
    { lat: 48.7493217, lng: 19.2127471 },
  ],
  Motyčky: [
    { lat: 48.8508275, lng: 19.2058022 },
    { lat: 48.8515245, lng: 19.2058111 },
    { lat: 48.8531988, lng: 19.2058877 },
    { lat: 48.8560036, lng: 19.2058285 },
    { lat: 48.8601841, lng: 19.2084697 },
    { lat: 48.8620925, lng: 19.2091443 },
    { lat: 48.8620971, lng: 19.2094971 },
    { lat: 48.862329, lng: 19.209719 },
    { lat: 48.862634, lng: 19.2103634 },
    { lat: 48.8630582, lng: 19.2106501 },
    { lat: 48.8633069, lng: 19.2106896 },
    { lat: 48.863602, lng: 19.210404 },
    { lat: 48.8649666, lng: 19.2094427 },
    { lat: 48.865252, lng: 19.2095895 },
    { lat: 48.8655832, lng: 19.2095365 },
    { lat: 48.8656776, lng: 19.2096029 },
    { lat: 48.8659043, lng: 19.2095343 },
    { lat: 48.8660783, lng: 19.209197 },
    { lat: 48.8666398, lng: 19.2088472 },
    { lat: 48.8670598, lng: 19.2087946 },
    { lat: 48.8671574, lng: 19.208922 },
    { lat: 48.8672814, lng: 19.2088795 },
    { lat: 48.8675314, lng: 19.2080145 },
    { lat: 48.867911, lng: 19.2076603 },
    { lat: 48.8680516, lng: 19.2076754 },
    { lat: 48.8682627, lng: 19.2081033 },
    { lat: 48.8685211, lng: 19.2081991 },
    { lat: 48.8688417, lng: 19.2076513 },
    { lat: 48.8689106, lng: 19.207378 },
    { lat: 48.8688562, lng: 19.2071875 },
    { lat: 48.8690167, lng: 19.2067514 },
    { lat: 48.8692962, lng: 19.2066855 },
    { lat: 48.8700288, lng: 19.205686 },
    { lat: 48.87229, lng: 19.2019292 },
    { lat: 48.872421, lng: 19.2020735 },
    { lat: 48.8726187, lng: 19.201664 },
    { lat: 48.8727124, lng: 19.2012948 },
    { lat: 48.8731605, lng: 19.2006818 },
    { lat: 48.8734593, lng: 19.199619 },
    { lat: 48.8737281, lng: 19.1993716 },
    { lat: 48.8741223, lng: 19.1994829 },
    { lat: 48.8750627, lng: 19.199212 },
    { lat: 48.8759165, lng: 19.1991277 },
    { lat: 48.877439, lng: 19.1985633 },
    { lat: 48.8794523, lng: 19.1979651 },
    { lat: 48.8850957, lng: 19.1950387 },
    { lat: 48.885513, lng: 19.195017 },
    { lat: 48.885669, lng: 19.194338 },
    { lat: 48.88575, lng: 19.193747 },
    { lat: 48.885775, lng: 19.193458 },
    { lat: 48.885778, lng: 19.193359 },
    { lat: 48.885786, lng: 19.192995 },
    { lat: 48.885524, lng: 19.192066 },
    { lat: 48.885479, lng: 19.191716 },
    { lat: 48.885388, lng: 19.191308 },
    { lat: 48.885165, lng: 19.190672 },
    { lat: 48.885041, lng: 19.19002 },
    { lat: 48.88509, lng: 19.189561 },
    { lat: 48.885089, lng: 19.189139 },
    { lat: 48.885188, lng: 19.188636 },
    { lat: 48.885124, lng: 19.188338 },
    { lat: 48.885105, lng: 19.187979 },
    { lat: 48.885036, lng: 19.187578 },
    { lat: 48.88492, lng: 19.18658 },
    { lat: 48.884745, lng: 19.186732 },
    { lat: 48.884567, lng: 19.186744 },
    { lat: 48.884377, lng: 19.186705 },
    { lat: 48.884077, lng: 19.186455 },
    { lat: 48.883714, lng: 19.186477 },
    { lat: 48.883557, lng: 19.186373 },
    { lat: 48.883364, lng: 19.186189 },
    { lat: 48.883219, lng: 19.186006 },
    { lat: 48.883099, lng: 19.185598 },
    { lat: 48.88276, lng: 19.184819 },
    { lat: 48.882397, lng: 19.184137 },
    { lat: 48.882196, lng: 19.183872 },
    { lat: 48.881957, lng: 19.183648 },
    { lat: 48.881577, lng: 19.183602 },
    { lat: 48.881506, lng: 19.183516 },
    { lat: 48.881399, lng: 19.183671 },
    { lat: 48.88033, lng: 19.18328 },
    { lat: 48.880267, lng: 19.183106 },
    { lat: 48.880386, lng: 19.182262 },
    { lat: 48.880255, lng: 19.181763 },
    { lat: 48.880634, lng: 19.181221 },
    { lat: 48.880991, lng: 19.180138 },
    { lat: 48.881262, lng: 19.179597 },
    { lat: 48.881496, lng: 19.179181 },
    { lat: 48.881672, lng: 19.178587 },
    { lat: 48.881758, lng: 19.178133 },
    { lat: 48.881774, lng: 19.178055 },
    { lat: 48.881962, lng: 19.177309 },
    { lat: 48.882468, lng: 19.175841 },
    { lat: 48.882466, lng: 19.174841 },
    { lat: 48.882702, lng: 19.174169 },
    { lat: 48.883466, lng: 19.173367 },
    { lat: 48.883638, lng: 19.172874 },
    { lat: 48.883834, lng: 19.172111 },
    { lat: 48.883797, lng: 19.171359 },
    { lat: 48.883952, lng: 19.170494 },
    { lat: 48.883972, lng: 19.17024 },
    { lat: 48.883989, lng: 19.169775 },
    { lat: 48.883878, lng: 19.169151 },
    { lat: 48.883865, lng: 19.168916 },
    { lat: 48.883871, lng: 19.168473 },
    { lat: 48.883847, lng: 19.168342 },
    { lat: 48.883887, lng: 19.168088 },
    { lat: 48.88396, lng: 19.167135 },
    { lat: 48.883965, lng: 19.167083 },
    { lat: 48.883989, lng: 19.167043 },
    { lat: 48.884051, lng: 19.166937 },
    { lat: 48.884096, lng: 19.16686 },
    { lat: 48.8833003, lng: 19.1666375 },
    { lat: 48.8829541, lng: 19.1664261 },
    { lat: 48.8823708, lng: 19.1659566 },
    { lat: 48.8820795, lng: 19.1655295 },
    { lat: 48.881796, lng: 19.1656355 },
    { lat: 48.8806807, lng: 19.1665248 },
    { lat: 48.8801007, lng: 19.1665563 },
    { lat: 48.8800077, lng: 19.1666603 },
    { lat: 48.8797348, lng: 19.1666333 },
    { lat: 48.8795452, lng: 19.1666976 },
    { lat: 48.8790436, lng: 19.1665362 },
    { lat: 48.8789729, lng: 19.1664521 },
    { lat: 48.8789142, lng: 19.1664748 },
    { lat: 48.8787415, lng: 19.1663771 },
    { lat: 48.8787056, lng: 19.1662895 },
    { lat: 48.8782947, lng: 19.1662057 },
    { lat: 48.8779664, lng: 19.1659683 },
    { lat: 48.8774154, lng: 19.1657917 },
    { lat: 48.8771834, lng: 19.1654959 },
    { lat: 48.8771181, lng: 19.1655186 },
    { lat: 48.8770418, lng: 19.1654108 },
    { lat: 48.8766598, lng: 19.1652761 },
    { lat: 48.8765626, lng: 19.1651753 },
    { lat: 48.8759757, lng: 19.1651396 },
    { lat: 48.8758825, lng: 19.1651948 },
    { lat: 48.8752588, lng: 19.1649069 },
    { lat: 48.8740414, lng: 19.1647967 },
    { lat: 48.8737658, lng: 19.1646662 },
    { lat: 48.8730146, lng: 19.1640133 },
    { lat: 48.8722707, lng: 19.1638775 },
    { lat: 48.8720487, lng: 19.1639207 },
    { lat: 48.8715663, lng: 19.1641568 },
    { lat: 48.8710982, lng: 19.1647219 },
    { lat: 48.8706291, lng: 19.1655044 },
    { lat: 48.8703786, lng: 19.1656622 },
    { lat: 48.8701345, lng: 19.1656448 },
    { lat: 48.8697924, lng: 19.1651763 },
    { lat: 48.8696538, lng: 19.1650841 },
    { lat: 48.8688637, lng: 19.1649255 },
    { lat: 48.868072, lng: 19.1645025 },
    { lat: 48.8671998, lng: 19.1636557 },
    { lat: 48.8671057, lng: 19.163644 },
    { lat: 48.8668143, lng: 19.163815 },
    { lat: 48.866611, lng: 19.1638547 },
    { lat: 48.8659033, lng: 19.1638838 },
    { lat: 48.8653898, lng: 19.1638438 },
    { lat: 48.8645989, lng: 19.1635768 },
    { lat: 48.8643609, lng: 19.1633924 },
    { lat: 48.864247, lng: 19.1632015 },
    { lat: 48.8638029, lng: 19.1629274 },
    { lat: 48.8630225, lng: 19.1620009 },
    { lat: 48.8632099, lng: 19.1621234 },
    { lat: 48.8633279, lng: 19.1618369 },
    { lat: 48.8633375, lng: 19.1616665 },
    { lat: 48.863206, lng: 19.161488 },
    { lat: 48.8630903, lng: 19.1615658 },
    { lat: 48.8629706, lng: 19.1615155 },
    { lat: 48.8629046, lng: 19.1614356 },
    { lat: 48.8629389, lng: 19.1613505 },
    { lat: 48.8625424, lng: 19.1611487 },
    { lat: 48.8617648, lng: 19.1591519 },
    { lat: 48.861225, lng: 19.1581619 },
    { lat: 48.8609817, lng: 19.1580514 },
    { lat: 48.8606773, lng: 19.1575712 },
    { lat: 48.860654, lng: 19.1570712 },
    { lat: 48.8607398, lng: 19.1570297 },
    { lat: 48.860641, lng: 19.1559528 },
    { lat: 48.8603542, lng: 19.1558072 },
    { lat: 48.8601484, lng: 19.1555644 },
    { lat: 48.8601292, lng: 19.1554484 },
    { lat: 48.8599287, lng: 19.1552662 },
    { lat: 48.8599094, lng: 19.1549401 },
    { lat: 48.8598467, lng: 19.1547692 },
    { lat: 48.8597263, lng: 19.1546793 },
    { lat: 48.85928, lng: 19.153439 },
    { lat: 48.859051, lng: 19.153114 },
    { lat: 48.8589986, lng: 19.1529642 },
    { lat: 48.8588602, lng: 19.1530614 },
    { lat: 48.8588712, lng: 19.1531274 },
    { lat: 48.8587603, lng: 19.1531639 },
    { lat: 48.8588717, lng: 19.1537102 },
    { lat: 48.8588444, lng: 19.1537999 },
    { lat: 48.8585551, lng: 19.15383 },
    { lat: 48.8551468, lng: 19.1579961 },
    { lat: 48.8550548, lng: 19.1592485 },
    { lat: 48.8543953, lng: 19.1598704 },
    { lat: 48.8543546, lng: 19.1601202 },
    { lat: 48.8544132, lng: 19.1602586 },
    { lat: 48.8540809, lng: 19.1611626 },
    { lat: 48.8541121, lng: 19.1614766 },
    { lat: 48.8540112, lng: 19.1618559 },
    { lat: 48.8539891, lng: 19.1622342 },
    { lat: 48.8538394, lng: 19.1629262 },
    { lat: 48.8536532, lng: 19.1629485 },
    { lat: 48.8535207, lng: 19.1631657 },
    { lat: 48.853564, lng: 19.1634286 },
    { lat: 48.8532569, lng: 19.1639485 },
    { lat: 48.8529937, lng: 19.1640729 },
    { lat: 48.8527351, lng: 19.1643389 },
    { lat: 48.8522311, lng: 19.1650749 },
    { lat: 48.851005, lng: 19.1663492 },
    { lat: 48.8505667, lng: 19.1670656 },
    { lat: 48.8502909, lng: 19.1677394 },
    { lat: 48.8500258, lng: 19.1681772 },
    { lat: 48.849066, lng: 19.1693388 },
    { lat: 48.8489599, lng: 19.1692469 },
    { lat: 48.8484413, lng: 19.1705828 },
    { lat: 48.848091, lng: 19.1710932 },
    { lat: 48.8474825, lng: 19.1716976 },
    { lat: 48.8474125, lng: 19.1719024 },
    { lat: 48.8469016, lng: 19.1720338 },
    { lat: 48.8465173, lng: 19.1724248 },
    { lat: 48.846381, lng: 19.1727064 },
    { lat: 48.8461339, lng: 19.173025 },
    { lat: 48.8456167, lng: 19.1734421 },
    { lat: 48.8452843, lng: 19.1738125 },
    { lat: 48.8448984, lng: 19.1739192 },
    { lat: 48.8441041, lng: 19.1743665 },
    { lat: 48.8435837, lng: 19.1748587 },
    { lat: 48.8435233, lng: 19.175078 },
    { lat: 48.8432063, lng: 19.1755115 },
    { lat: 48.8423768, lng: 19.176228 },
    { lat: 48.8421647, lng: 19.1763165 },
    { lat: 48.8418401, lng: 19.1766883 },
    { lat: 48.8419428, lng: 19.1769 },
    { lat: 48.8420421, lng: 19.1782839 },
    { lat: 48.8418455, lng: 19.1805615 },
    { lat: 48.8413731, lng: 19.1815288 },
    { lat: 48.8411469, lng: 19.1829447 },
    { lat: 48.8410973, lng: 19.1836857 },
    { lat: 48.8411427, lng: 19.1843845 },
    { lat: 48.8419663, lng: 19.1852066 },
    { lat: 48.8424487, lng: 19.1858322 },
    { lat: 48.8428454, lng: 19.1874232 },
    { lat: 48.843321, lng: 19.1888315 },
    { lat: 48.8434086, lng: 19.1894756 },
    { lat: 48.8435814, lng: 19.1902206 },
    { lat: 48.8435962, lng: 19.1911061 },
    { lat: 48.8439912, lng: 19.1924541 },
    { lat: 48.8440598, lng: 19.1948391 },
    { lat: 48.8443845, lng: 19.1953697 },
    { lat: 48.8444752, lng: 19.1964472 },
    { lat: 48.8443709, lng: 19.1978921 },
    { lat: 48.844202, lng: 19.1988468 },
    { lat: 48.8439562, lng: 19.1992855 },
    { lat: 48.8441419, lng: 19.2006021 },
    { lat: 48.8442588, lng: 19.2011262 },
    { lat: 48.8449008, lng: 19.2031084 },
    { lat: 48.8464334, lng: 19.2043313 },
    { lat: 48.8471643, lng: 19.2043685 },
    { lat: 48.8480842, lng: 19.204322 },
    { lat: 48.8508275, lng: 19.2058022 },
  ],
  Hrochoť: [
    { lat: 48.673762, lng: 19.480685 },
    { lat: 48.674083, lng: 19.48027 },
    { lat: 48.674457, lng: 19.479583 },
    { lat: 48.674441, lng: 19.478969 },
    { lat: 48.674422, lng: 19.47842 },
    { lat: 48.674543, lng: 19.477573 },
    { lat: 48.674689, lng: 19.477057 },
    { lat: 48.674828, lng: 19.476461 },
    { lat: 48.67513, lng: 19.475427 },
    { lat: 48.675388, lng: 19.474167 },
    { lat: 48.675719, lng: 19.473259 },
    { lat: 48.675778, lng: 19.472975 },
    { lat: 48.675829, lng: 19.472832 },
    { lat: 48.676291, lng: 19.47231 },
    { lat: 48.6769, lng: 19.472159 },
    { lat: 48.677241, lng: 19.471785 },
    { lat: 48.677568, lng: 19.471637 },
    { lat: 48.677862, lng: 19.471434 },
    { lat: 48.678281, lng: 19.471019 },
    { lat: 48.678665, lng: 19.47061 },
    { lat: 48.678891, lng: 19.4702 },
    { lat: 48.679221, lng: 19.469888 },
    { lat: 48.680196, lng: 19.468442 },
    { lat: 48.68028, lng: 19.468318 },
    { lat: 48.680571, lng: 19.467887 },
    { lat: 48.6806, lng: 19.467845 },
    { lat: 48.681207, lng: 19.467249 },
    { lat: 48.682218, lng: 19.466503 },
    { lat: 48.682474, lng: 19.466339 },
    { lat: 48.682823, lng: 19.466117 },
    { lat: 48.683268, lng: 19.465699 },
    { lat: 48.683419, lng: 19.465484 },
    { lat: 48.683842, lng: 19.464883 },
    { lat: 48.684647, lng: 19.46387 },
    { lat: 48.684809, lng: 19.463745 },
    { lat: 48.685283, lng: 19.463381 },
    { lat: 48.685777, lng: 19.463311 },
    { lat: 48.68615, lng: 19.463314 },
    { lat: 48.6865298, lng: 19.4625184 },
    { lat: 48.6867063, lng: 19.4612018 },
    { lat: 48.6866604, lng: 19.4608443 },
    { lat: 48.686206, lng: 19.4599192 },
    { lat: 48.685503, lng: 19.4571152 },
    { lat: 48.6851431, lng: 19.4545131 },
    { lat: 48.6844073, lng: 19.4527317 },
    { lat: 48.6843128, lng: 19.4522742 },
    { lat: 48.6842183, lng: 19.4520357 },
    { lat: 48.6841036, lng: 19.4518688 },
    { lat: 48.6837895, lng: 19.4516358 },
    { lat: 48.6832465, lng: 19.4509396 },
    { lat: 48.6831271, lng: 19.4503599 },
    { lat: 48.6831239, lng: 19.4495428 },
    { lat: 48.6830496, lng: 19.4489876 },
    { lat: 48.682924, lng: 19.4485672 },
    { lat: 48.6827805, lng: 19.4483411 },
    { lat: 48.6826223, lng: 19.4479207 },
    { lat: 48.6824423, lng: 19.4476117 },
    { lat: 48.682283, lng: 19.4470002 },
    { lat: 48.6822187, lng: 19.4469547 },
    { lat: 48.6819832, lng: 19.4463702 },
    { lat: 48.6818691, lng: 19.4457629 },
    { lat: 48.6818899, lng: 19.4448313 },
    { lat: 48.6818305, lng: 19.4438989 },
    { lat: 48.6815776, lng: 19.4426338 },
    { lat: 48.6814988, lng: 19.4418886 },
    { lat: 48.6814519, lng: 19.4417538 },
    { lat: 48.6815423, lng: 19.4413476 },
    { lat: 48.6815493, lng: 19.4406981 },
    { lat: 48.681484, lng: 19.4400028 },
    { lat: 48.6815163, lng: 19.4396531 },
    { lat: 48.6814749, lng: 19.4388633 },
    { lat: 48.6814186, lng: 19.4386749 },
    { lat: 48.6809385, lng: 19.4380738 },
    { lat: 48.6810005, lng: 19.4380012 },
    { lat: 48.6809831, lng: 19.4376522 },
    { lat: 48.680891, lng: 19.4372697 },
    { lat: 48.6802864, lng: 19.436511 },
    { lat: 48.6801589, lng: 19.4357122 },
    { lat: 48.6799793, lng: 19.4352713 },
    { lat: 48.679481, lng: 19.4343009 },
    { lat: 48.6789495, lng: 19.4330866 },
    { lat: 48.6786945, lng: 19.4326431 },
    { lat: 48.67879, lng: 19.4326151 },
    { lat: 48.6788308, lng: 19.4322102 },
    { lat: 48.67878, lng: 19.4310987 },
    { lat: 48.678588, lng: 19.4303674 },
    { lat: 48.6784618, lng: 19.4301288 },
    { lat: 48.6782228, lng: 19.4292863 },
    { lat: 48.6782282, lng: 19.4281249 },
    { lat: 48.678097, lng: 19.4271276 },
    { lat: 48.6778868, lng: 19.4263202 },
    { lat: 48.6775999, lng: 19.4256565 },
    { lat: 48.6774593, lng: 19.4251105 },
    { lat: 48.6772339, lng: 19.4247502 },
    { lat: 48.6768386, lng: 19.4239138 },
    { lat: 48.675816, lng: 19.4221501 },
    { lat: 48.6757383, lng: 19.421907 },
    { lat: 48.6757311, lng: 19.4213954 },
    { lat: 48.675555, lng: 19.4209538 },
    { lat: 48.6755187, lng: 19.420492 },
    { lat: 48.6743512, lng: 19.4165074 },
    { lat: 48.6738733, lng: 19.4158807 },
    { lat: 48.6737171, lng: 19.4153404 },
    { lat: 48.6736481, lng: 19.414947 },
    { lat: 48.6736559, lng: 19.4143731 },
    { lat: 48.6735513, lng: 19.4127686 },
    { lat: 48.6733566, lng: 19.4122685 },
    { lat: 48.672958, lng: 19.4115295 },
    { lat: 48.6725527, lng: 19.410388 },
    { lat: 48.6721851, lng: 19.4096165 },
    { lat: 48.6714289, lng: 19.4089469 },
    { lat: 48.6710896, lng: 19.4087283 },
    { lat: 48.6705738, lng: 19.408141 },
    { lat: 48.6702678, lng: 19.4076443 },
    { lat: 48.6700951, lng: 19.4069953 },
    { lat: 48.6698141, lng: 19.4062667 },
    { lat: 48.6687856, lng: 19.4046237 },
    { lat: 48.6685444, lng: 19.4040213 },
    { lat: 48.6685575, lng: 19.4023609 },
    { lat: 48.6686242, lng: 19.40136 },
    { lat: 48.6692307, lng: 19.4000205 },
    { lat: 48.6693934, lng: 19.3995535 },
    { lat: 48.6694362, lng: 19.3991673 },
    { lat: 48.6699267, lng: 19.3976881 },
    { lat: 48.6700349, lng: 19.3971872 },
    { lat: 48.6701735, lng: 19.3969969 },
    { lat: 48.6706476, lng: 19.3953846 },
    { lat: 48.6707287, lng: 19.3949751 },
    { lat: 48.6707457, lng: 19.3945931 },
    { lat: 48.6707123, lng: 19.3939609 },
    { lat: 48.6704016, lng: 19.3923387 },
    { lat: 48.669777, lng: 19.3911383 },
    { lat: 48.6696836, lng: 19.3910948 },
    { lat: 48.6694594, lng: 19.3905937 },
    { lat: 48.6693329, lng: 19.3905142 },
    { lat: 48.669283, lng: 19.3904137 },
    { lat: 48.6691958, lng: 19.389691 },
    { lat: 48.6690178, lng: 19.3893065 },
    { lat: 48.6689548, lng: 19.3888566 },
    { lat: 48.6687486, lng: 19.3883571 },
    { lat: 48.6686146, lng: 19.3877642 },
    { lat: 48.6685343, lng: 19.3870872 },
    { lat: 48.6686298, lng: 19.3863635 },
    { lat: 48.6686249, lng: 19.3854248 },
    { lat: 48.6683044, lng: 19.3822884 },
    { lat: 48.6682967, lng: 19.3814538 },
    { lat: 48.6682091, lng: 19.3806296 },
    { lat: 48.6682317, lng: 19.3799636 },
    { lat: 48.6681044, lng: 19.3795786 },
    { lat: 48.6681229, lng: 19.3788496 },
    { lat: 48.6680798, lng: 19.3781076 },
    { lat: 48.6682356, lng: 19.3757995 },
    { lat: 48.6682526, lng: 19.3742516 },
    { lat: 48.6684118, lng: 19.3732817 },
    { lat: 48.6683109, lng: 19.3729125 },
    { lat: 48.6682899, lng: 19.3725885 },
    { lat: 48.6682495, lng: 19.3708749 },
    { lat: 48.6682794, lng: 19.3705091 },
    { lat: 48.6681904, lng: 19.3702217 },
    { lat: 48.6680992, lng: 19.3693665 },
    { lat: 48.6679135, lng: 19.3682787 },
    { lat: 48.6677862, lng: 19.3665681 },
    { lat: 48.6677841, lng: 19.3659999 },
    { lat: 48.6674348, lng: 19.3650471 },
    { lat: 48.6672656, lng: 19.3648058 },
    { lat: 48.66698, lng: 19.3641324 },
    { lat: 48.6666376, lng: 19.3627083 },
    { lat: 48.6666509, lng: 19.361968 },
    { lat: 48.6667616, lng: 19.3615681 },
    { lat: 48.6668828, lng: 19.3607713 },
    { lat: 48.6669222, lng: 19.3597049 },
    { lat: 48.6668928, lng: 19.3589643 },
    { lat: 48.6670082, lng: 19.3582119 },
    { lat: 48.6673119, lng: 19.3573697 },
    { lat: 48.6675224, lng: 19.3570078 },
    { lat: 48.6676401, lng: 19.3568995 },
    { lat: 48.6677582, lng: 19.3566061 },
    { lat: 48.6680348, lng: 19.3561701 },
    { lat: 48.6681332, lng: 19.3560528 },
    { lat: 48.6681596, lng: 19.3560927 },
    { lat: 48.6682859, lng: 19.3559004 },
    { lat: 48.6682592, lng: 19.3558356 },
    { lat: 48.668375, lng: 19.3555938 },
    { lat: 48.6684094, lng: 19.3556082 },
    { lat: 48.6686577, lng: 19.3550176 },
    { lat: 48.6687, lng: 19.3547481 },
    { lat: 48.6687889, lng: 19.3545516 },
    { lat: 48.668854, lng: 19.3540732 },
    { lat: 48.669146, lng: 19.3535517 },
    { lat: 48.6690972, lng: 19.3534496 },
    { lat: 48.6695296, lng: 19.3526814 },
    { lat: 48.6696278, lng: 19.3526366 },
    { lat: 48.6696569, lng: 19.3523489 },
    { lat: 48.6699065, lng: 19.3522195 },
    { lat: 48.6700028, lng: 19.352014 },
    { lat: 48.6701525, lng: 19.3520139 },
    { lat: 48.6703414, lng: 19.3518829 },
    { lat: 48.6706324, lng: 19.3515297 },
    { lat: 48.6705986, lng: 19.3511262 },
    { lat: 48.6706526, lng: 19.3507227 },
    { lat: 48.6707194, lng: 19.3503995 },
    { lat: 48.6708051, lng: 19.3502537 },
    { lat: 48.670971, lng: 19.3496898 },
    { lat: 48.6709252, lng: 19.3495364 },
    { lat: 48.6710246, lng: 19.3492771 },
    { lat: 48.6710273, lng: 19.3490254 },
    { lat: 48.6712798, lng: 19.3485723 },
    { lat: 48.6714753, lng: 19.3475441 },
    { lat: 48.6714655, lng: 19.3471493 },
    { lat: 48.671562, lng: 19.3470162 },
    { lat: 48.671564, lng: 19.3464928 },
    { lat: 48.6716659, lng: 19.3464312 },
    { lat: 48.6716804, lng: 19.3463576 },
    { lat: 48.671691, lng: 19.3463664 },
    { lat: 48.671416, lng: 19.3441147 },
    { lat: 48.6713593, lng: 19.3435389 },
    { lat: 48.6714795, lng: 19.3427246 },
    { lat: 48.671466, lng: 19.3419436 },
    { lat: 48.6713351, lng: 19.3408263 },
    { lat: 48.6713999, lng: 19.3396775 },
    { lat: 48.6720114, lng: 19.3370878 },
    { lat: 48.6724427, lng: 19.3360125 },
    { lat: 48.6725203, lng: 19.3351325 },
    { lat: 48.672679, lng: 19.3345868 },
    { lat: 48.6728481, lng: 19.3342789 },
    { lat: 48.6729721, lng: 19.3335964 },
    { lat: 48.6733851, lng: 19.3335088 },
    { lat: 48.6735749, lng: 19.3329775 },
    { lat: 48.6740632, lng: 19.33298 },
    { lat: 48.6746494, lng: 19.3325326 },
    { lat: 48.6747909, lng: 19.3323799 },
    { lat: 48.6748119, lng: 19.3317807 },
    { lat: 48.6753665, lng: 19.3316883 },
    { lat: 48.6757273, lng: 19.3314087 },
    { lat: 48.6762884, lng: 19.3301034 },
    { lat: 48.6763839, lng: 19.3300256 },
    { lat: 48.6766847, lng: 19.3301884 },
    { lat: 48.676965, lng: 19.3295174 },
    { lat: 48.6768803, lng: 19.328569 },
    { lat: 48.6769585, lng: 19.3275152 },
    { lat: 48.6771216, lng: 19.3272492 },
    { lat: 48.6772916, lng: 19.3265117 },
    { lat: 48.6774711, lng: 19.3264027 },
    { lat: 48.6775204, lng: 19.3260899 },
    { lat: 48.6780643, lng: 19.3253652 },
    { lat: 48.678083, lng: 19.3248614 },
    { lat: 48.6780133, lng: 19.323732 },
    { lat: 48.6777093, lng: 19.3235047 },
    { lat: 48.6780454, lng: 19.3221828 },
    { lat: 48.6776436, lng: 19.3206148 },
    { lat: 48.6775756, lng: 19.3199221 },
    { lat: 48.6776303, lng: 19.3190848 },
    { lat: 48.6774009, lng: 19.3177791 },
    { lat: 48.6772737, lng: 19.3174998 },
    { lat: 48.6771955, lng: 19.3166638 },
    { lat: 48.6769203, lng: 19.3165194 },
    { lat: 48.6768276, lng: 19.3163448 },
    { lat: 48.676883, lng: 19.3159956 },
    { lat: 48.6758126, lng: 19.3151315 },
    { lat: 48.6754536, lng: 19.3147659 },
    { lat: 48.675222, lng: 19.3142348 },
    { lat: 48.6750931, lng: 19.3130017 },
    { lat: 48.6753992, lng: 19.3115543 },
    { lat: 48.6755315, lng: 19.3106903 },
    { lat: 48.6753122, lng: 19.310042 },
    { lat: 48.6754771, lng: 19.3093175 },
    { lat: 48.6753851, lng: 19.3085258 },
    { lat: 48.6751357, lng: 19.3081454 },
    { lat: 48.675266, lng: 19.3071715 },
    { lat: 48.675142, lng: 19.3067924 },
    { lat: 48.6750949, lng: 19.3063029 },
    { lat: 48.6747491, lng: 19.3054622 },
    { lat: 48.6746984, lng: 19.3046372 },
    { lat: 48.674638, lng: 19.3044102 },
    { lat: 48.67435, lng: 19.3043553 },
    { lat: 48.6744745, lng: 19.3037743 },
    { lat: 48.6742038, lng: 19.3026202 },
    { lat: 48.6743543, lng: 19.3025853 },
    { lat: 48.6744728, lng: 19.3024464 },
    { lat: 48.6747234, lng: 19.3017725 },
    { lat: 48.6747226, lng: 19.3014245 },
    { lat: 48.6735243, lng: 19.3012355 },
    { lat: 48.6734309, lng: 19.3011448 },
    { lat: 48.6731274, lng: 19.3012778 },
    { lat: 48.6728309, lng: 19.3015124 },
    { lat: 48.6727831, lng: 19.3011938 },
    { lat: 48.6726778, lng: 19.3009978 },
    { lat: 48.672523, lng: 19.3004249 },
    { lat: 48.6722984, lng: 19.3001848 },
    { lat: 48.6721378, lng: 19.2997256 },
    { lat: 48.6718096, lng: 19.2991563 },
    { lat: 48.6716565, lng: 19.2989986 },
    { lat: 48.6714975, lng: 19.2989276 },
    { lat: 48.6704398, lng: 19.2973165 },
    { lat: 48.6701586, lng: 19.2969799 },
    { lat: 48.6700515, lng: 19.2965085 },
    { lat: 48.6699701, lng: 19.2957977 },
    { lat: 48.6694183, lng: 19.2943739 },
    { lat: 48.6693064, lng: 19.2939707 },
    { lat: 48.6692371, lng: 19.2933008 },
    { lat: 48.668938, lng: 19.2926353 },
    { lat: 48.6687899, lng: 19.2920885 },
    { lat: 48.6684341, lng: 19.2918111 },
    { lat: 48.6681806, lng: 19.2917447 },
    { lat: 48.6681126, lng: 19.29176 },
    { lat: 48.6676872, lng: 19.2922427 },
    { lat: 48.6674537, lng: 19.2923074 },
    { lat: 48.6671735, lng: 19.2922465 },
    { lat: 48.6667753, lng: 19.2919128 },
    { lat: 48.6661049, lng: 19.2910516 },
    { lat: 48.6659685, lng: 19.2907677 },
    { lat: 48.6657627, lng: 19.290496 },
    { lat: 48.6653635, lng: 19.2901172 },
    { lat: 48.6648286, lng: 19.2891881 },
    { lat: 48.6647298, lng: 19.2884427 },
    { lat: 48.6646996, lng: 19.2882152 },
    { lat: 48.6646848, lng: 19.2876756 },
    { lat: 48.6646904, lng: 19.2867803 },
    { lat: 48.6648143, lng: 19.2853284 },
    { lat: 48.6647668, lng: 19.2832159 },
    { lat: 48.6643631, lng: 19.2827874 },
    { lat: 48.6641627, lng: 19.2823494 },
    { lat: 48.663829, lng: 19.2819032 },
    { lat: 48.6631851, lng: 19.2818468 },
    { lat: 48.6622918, lng: 19.2820681 },
    { lat: 48.6614579, lng: 19.2828452 },
    { lat: 48.6610672, lng: 19.2830916 },
    { lat: 48.660856, lng: 19.2829418 },
    { lat: 48.6606332, lng: 19.2828768 },
    { lat: 48.6606261, lng: 19.2828957 },
    { lat: 48.6600303, lng: 19.2824811 },
    { lat: 48.6598489, lng: 19.2822008 },
    { lat: 48.6595367, lng: 19.2818749 },
    { lat: 48.6593683, lng: 19.2818652 },
    { lat: 48.6589749, lng: 19.2817002 },
    { lat: 48.6586615, lng: 19.281676 },
    { lat: 48.6583163, lng: 19.2813627 },
    { lat: 48.6580974, lng: 19.2812662 },
    { lat: 48.6574166, lng: 19.2805423 },
    { lat: 48.6567393, lng: 19.2801238 },
    { lat: 48.6563992, lng: 19.2797747 },
    { lat: 48.6560738, lng: 19.2797138 },
    { lat: 48.65567, lng: 19.2794743 },
    { lat: 48.654744, lng: 19.2792764 },
    { lat: 48.6544772, lng: 19.2794693 },
    { lat: 48.6538569, lng: 19.2796938 },
    { lat: 48.6532864, lng: 19.2802319 },
    { lat: 48.6524725, lng: 19.2806491 },
    { lat: 48.651981, lng: 19.2811151 },
    { lat: 48.6516413, lng: 19.2817184 },
    { lat: 48.6509813, lng: 19.2821975 },
    { lat: 48.6505713, lng: 19.2823209 },
    { lat: 48.6502431, lng: 19.2823626 },
    { lat: 48.6496651, lng: 19.2822734 },
    { lat: 48.6494505, lng: 19.282335 },
    { lat: 48.6490314, lng: 19.2829944 },
    { lat: 48.6482194, lng: 19.2836634 },
    { lat: 48.6480477, lng: 19.2840561 },
    { lat: 48.6471338, lng: 19.2847383 },
    { lat: 48.646838, lng: 19.2850787 },
    { lat: 48.6466033, lng: 19.2852131 },
    { lat: 48.6457726, lng: 19.286809 },
    { lat: 48.6456651, lng: 19.287128 },
    { lat: 48.6456867, lng: 19.2871541 },
    { lat: 48.644919, lng: 19.287389 },
    { lat: 48.6445943, lng: 19.2845264 },
    { lat: 48.644365, lng: 19.282955 },
    { lat: 48.643522, lng: 19.283324 },
    { lat: 48.643418, lng: 19.282846 },
    { lat: 48.643273, lng: 19.282369 },
    { lat: 48.643148, lng: 19.282107 },
    { lat: 48.642866, lng: 19.282117 },
    { lat: 48.642638, lng: 19.282217 },
    { lat: 48.642496, lng: 19.28238 },
    { lat: 48.642527, lng: 19.282839 },
    { lat: 48.642662, lng: 19.283487 },
    { lat: 48.642612, lng: 19.283812 },
    { lat: 48.642053, lng: 19.285573 },
    { lat: 48.641928, lng: 19.286023 },
    { lat: 48.641783, lng: 19.286814 },
    { lat: 48.641648, lng: 19.287244 },
    { lat: 48.641369, lng: 19.287502 },
    { lat: 48.641356, lng: 19.287521 },
    { lat: 48.641039, lng: 19.287531 },
    { lat: 48.640303, lng: 19.287394 },
    { lat: 48.640078, lng: 19.287451 },
    { lat: 48.640044, lng: 19.28749 },
    { lat: 48.639245, lng: 19.288401 },
    { lat: 48.63893, lng: 19.288617 },
    { lat: 48.638823, lng: 19.288689 },
    { lat: 48.638454, lng: 19.289146 },
    { lat: 48.63768, lng: 19.291273 },
    { lat: 48.636702, lng: 19.293477 },
    { lat: 48.636628, lng: 19.293753 },
    { lat: 48.636586, lng: 19.294258 },
    { lat: 48.636531, lng: 19.294901 },
    { lat: 48.636445, lng: 19.294931 },
    { lat: 48.636416, lng: 19.294941 },
    { lat: 48.636399, lng: 19.295029 },
    { lat: 48.63639, lng: 19.295079 },
    { lat: 48.636377, lng: 19.295144 },
    { lat: 48.636358, lng: 19.295243 },
    { lat: 48.636296, lng: 19.295567 },
    { lat: 48.636162, lng: 19.296273 },
    { lat: 48.636065, lng: 19.296781 },
    { lat: 48.635958, lng: 19.297654 },
    { lat: 48.635921, lng: 19.298118 },
    { lat: 48.635859, lng: 19.298486 },
    { lat: 48.635654, lng: 19.299692 },
    { lat: 48.635823, lng: 19.301711 },
    { lat: 48.635888, lng: 19.302108 },
    { lat: 48.63614, lng: 19.302856 },
    { lat: 48.638631, lng: 19.306152 },
    { lat: 48.638405, lng: 19.30687 },
    { lat: 48.638165, lng: 19.307526 },
    { lat: 48.638399, lng: 19.307797 },
    { lat: 48.639126, lng: 19.308781 },
    { lat: 48.639337, lng: 19.309007 },
    { lat: 48.639948, lng: 19.309839 },
    { lat: 48.640202, lng: 19.310224 },
    { lat: 48.640489, lng: 19.310644 },
    { lat: 48.641045, lng: 19.311413 },
    { lat: 48.641394, lng: 19.312021 },
    { lat: 48.641624, lng: 19.312396 },
    { lat: 48.641823, lng: 19.312692 },
    { lat: 48.641977, lng: 19.312862 },
    { lat: 48.642119, lng: 19.31308 },
    { lat: 48.642195, lng: 19.313272 },
    { lat: 48.642236, lng: 19.313413 },
    { lat: 48.642267, lng: 19.313557 },
    { lat: 48.64228, lng: 19.313671 },
    { lat: 48.642282, lng: 19.313701 },
    { lat: 48.642804, lng: 19.314045 },
    { lat: 48.643049, lng: 19.314385 },
    { lat: 48.643108, lng: 19.314491 },
    { lat: 48.643231, lng: 19.314999 },
    { lat: 48.643407, lng: 19.315565 },
    { lat: 48.643695, lng: 19.315931 },
    { lat: 48.644046, lng: 19.316094 },
    { lat: 48.644092, lng: 19.316186 },
    { lat: 48.644747, lng: 19.317494 },
    { lat: 48.644991, lng: 19.318515 },
    { lat: 48.645052, lng: 19.318664 },
    { lat: 48.645803, lng: 19.319746 },
    { lat: 48.646171, lng: 19.319905 },
    { lat: 48.646439, lng: 19.319843 },
    { lat: 48.646642, lng: 19.320132 },
    { lat: 48.646912, lng: 19.321064 },
    { lat: 48.647213, lng: 19.321462 },
    { lat: 48.647484, lng: 19.321913 },
    { lat: 48.647491, lng: 19.322754 },
    { lat: 48.64777, lng: 19.323118 },
    { lat: 48.648285, lng: 19.324701 },
    { lat: 48.648734, lng: 19.325372 },
    { lat: 48.648876, lng: 19.325812 },
    { lat: 48.648842, lng: 19.326647 },
    { lat: 48.649008, lng: 19.326876 },
    { lat: 48.649137, lng: 19.327154 },
    { lat: 48.649093, lng: 19.328583 },
    { lat: 48.649125, lng: 19.329233 },
    { lat: 48.649172, lng: 19.329368 },
    { lat: 48.649304, lng: 19.329948 },
    { lat: 48.649512, lng: 19.330345 },
    { lat: 48.649555, lng: 19.330823 },
    { lat: 48.649564, lng: 19.331156 },
    { lat: 48.649596, lng: 19.331343 },
    { lat: 48.649632, lng: 19.331586 },
    { lat: 48.649743, lng: 19.331969 },
    { lat: 48.649812, lng: 19.332419 },
    { lat: 48.649984, lng: 19.332675 },
    { lat: 48.650083, lng: 19.333128 },
    { lat: 48.650087, lng: 19.333939 },
    { lat: 48.650099, lng: 19.334716 },
    { lat: 48.650159, lng: 19.335385 },
    { lat: 48.650157, lng: 19.335936 },
    { lat: 48.650194, lng: 19.336219 },
    { lat: 48.650283, lng: 19.336755 },
    { lat: 48.650317, lng: 19.337345 },
    { lat: 48.650319, lng: 19.33743 },
    { lat: 48.650278, lng: 19.337968 },
    { lat: 48.650433, lng: 19.339088 },
    { lat: 48.650422, lng: 19.339651 },
    { lat: 48.650709, lng: 19.340895 },
    { lat: 48.650754, lng: 19.341573 },
    { lat: 48.650772, lng: 19.341696 },
    { lat: 48.650906, lng: 19.34223 },
    { lat: 48.650973, lng: 19.342597 },
    { lat: 48.651018, lng: 19.342961 },
    { lat: 48.650952, lng: 19.34362 },
    { lat: 48.650929, lng: 19.344121 },
    { lat: 48.651043, lng: 19.344579 },
    { lat: 48.650991, lng: 19.345261 },
    { lat: 48.651013, lng: 19.345861 },
    { lat: 48.65108, lng: 19.346094 },
    { lat: 48.651182, lng: 19.346739 },
    { lat: 48.651339, lng: 19.347382 },
    { lat: 48.651433, lng: 19.347819 },
    { lat: 48.651588, lng: 19.348795 },
    { lat: 48.651637, lng: 19.349173 },
    { lat: 48.651776, lng: 19.349675 },
    { lat: 48.651817, lng: 19.350306 },
    { lat: 48.651818, lng: 19.350748 },
    { lat: 48.651744, lng: 19.351468 },
    { lat: 48.651769, lng: 19.35172 },
    { lat: 48.65186, lng: 19.351914 },
    { lat: 48.652692, lng: 19.352912 },
    { lat: 48.652812, lng: 19.353444 },
    { lat: 48.652864, lng: 19.353837 },
    { lat: 48.65296, lng: 19.354167 },
    { lat: 48.653129, lng: 19.354582 },
    { lat: 48.653374, lng: 19.354748 },
    { lat: 48.653703, lng: 19.355214 },
    { lat: 48.653934, lng: 19.355668 },
    { lat: 48.654084, lng: 19.355868 },
    { lat: 48.654275, lng: 19.356175 },
    { lat: 48.654413, lng: 19.3565 },
    { lat: 48.654545, lng: 19.356912 },
    { lat: 48.654653, lng: 19.357333 },
    { lat: 48.654767, lng: 19.357634 },
    { lat: 48.654861, lng: 19.357967 },
    { lat: 48.654999, lng: 19.358744 },
    { lat: 48.655065, lng: 19.359227 },
    { lat: 48.655141, lng: 19.359543 },
    { lat: 48.655351, lng: 19.359797 },
    { lat: 48.655481, lng: 19.360111 },
    { lat: 48.655528, lng: 19.360436 },
    { lat: 48.655802, lng: 19.361221 },
    { lat: 48.655824, lng: 19.361393 },
    { lat: 48.655857, lng: 19.361694 },
    { lat: 48.655958, lng: 19.361975 },
    { lat: 48.656023, lng: 19.362648 },
    { lat: 48.656045, lng: 19.36283 },
    { lat: 48.656182, lng: 19.363175 },
    { lat: 48.656227, lng: 19.363592 },
    { lat: 48.656267, lng: 19.36385 },
    { lat: 48.656452, lng: 19.364363 },
    { lat: 48.656488, lng: 19.364798 },
    { lat: 48.656548, lng: 19.365186 },
    { lat: 48.656654, lng: 19.365502 },
    { lat: 48.656856, lng: 19.365815 },
    { lat: 48.656947, lng: 19.366105 },
    { lat: 48.657014, lng: 19.366517 },
    { lat: 48.657247, lng: 19.36702 },
    { lat: 48.657357, lng: 19.367299 },
    { lat: 48.657399, lng: 19.367655 },
    { lat: 48.657292, lng: 19.367978 },
    { lat: 48.657225, lng: 19.36831 },
    { lat: 48.65726, lng: 19.368636 },
    { lat: 48.657337, lng: 19.368826 },
    { lat: 48.657476, lng: 19.369157 },
    { lat: 48.657538, lng: 19.369405 },
    { lat: 48.65753, lng: 19.369664 },
    { lat: 48.657491, lng: 19.369889 },
    { lat: 48.65743, lng: 19.370305 },
    { lat: 48.657495, lng: 19.370758 },
    { lat: 48.657433, lng: 19.371308 },
    { lat: 48.657459, lng: 19.371536 },
    { lat: 48.657527, lng: 19.371692 },
    { lat: 48.657571, lng: 19.371911 },
    { lat: 48.657718, lng: 19.372246 },
    { lat: 48.657885, lng: 19.372658 },
    { lat: 48.657977, lng: 19.373102 },
    { lat: 48.657978, lng: 19.373506 },
    { lat: 48.658043, lng: 19.373767 },
    { lat: 48.658155, lng: 19.374103 },
    { lat: 48.658434, lng: 19.374996 },
    { lat: 48.658631, lng: 19.375371 },
    { lat: 48.658883, lng: 19.376344 },
    { lat: 48.658876, lng: 19.376871 },
    { lat: 48.658668, lng: 19.377237 },
    { lat: 48.658337, lng: 19.377709 },
    { lat: 48.658217, lng: 19.377951 },
    { lat: 48.658088, lng: 19.378463 },
    { lat: 48.657953, lng: 19.378976 },
    { lat: 48.657876, lng: 19.379332 },
    { lat: 48.657706, lng: 19.379608 },
    { lat: 48.657564, lng: 19.380011 },
    { lat: 48.657534, lng: 19.380237 },
    { lat: 48.657502, lng: 19.38063 },
    { lat: 48.657412, lng: 19.381041 },
    { lat: 48.657288, lng: 19.381504 },
    { lat: 48.657296, lng: 19.382403 },
    { lat: 48.657249, lng: 19.382828 },
    { lat: 48.656946, lng: 19.383196 },
    { lat: 48.65674, lng: 19.383557 },
    { lat: 48.656463, lng: 19.38409 },
    { lat: 48.656451, lng: 19.384528 },
    { lat: 48.656387, lng: 19.385005 },
    { lat: 48.656283, lng: 19.385388 },
    { lat: 48.656344, lng: 19.386194 },
    { lat: 48.656432, lng: 19.386686 },
    { lat: 48.656369, lng: 19.387105 },
    { lat: 48.656164, lng: 19.387393 },
    { lat: 48.656023, lng: 19.387382 },
    { lat: 48.655934, lng: 19.387399 },
    { lat: 48.655829, lng: 19.387479 },
    { lat: 48.655571, lng: 19.388071 },
    { lat: 48.655499, lng: 19.388603 },
    { lat: 48.655517, lng: 19.388881 },
    { lat: 48.655519, lng: 19.389308 },
    { lat: 48.655483, lng: 19.390301 },
    { lat: 48.65566, lng: 19.391004 },
    { lat: 48.655639, lng: 19.391609 },
    { lat: 48.655635, lng: 19.392147 },
    { lat: 48.655571, lng: 19.392432 },
    { lat: 48.655412, lng: 19.392766 },
    { lat: 48.654994, lng: 19.393377 },
    { lat: 48.655005, lng: 19.393746 },
    { lat: 48.65489, lng: 19.394323 },
    { lat: 48.654508, lng: 19.395413 },
    { lat: 48.654486, lng: 19.395502 },
    { lat: 48.654462, lng: 19.3961 },
    { lat: 48.65431, lng: 19.396644 },
    { lat: 48.654179, lng: 19.396828 },
    { lat: 48.654192, lng: 19.396868 },
    { lat: 48.654182, lng: 19.397089 },
    { lat: 48.654064, lng: 19.397717 },
    { lat: 48.653811, lng: 19.399308 },
    { lat: 48.653687, lng: 19.399671 },
    { lat: 48.653106, lng: 19.400529 },
    { lat: 48.652971, lng: 19.400864 },
    { lat: 48.652998, lng: 19.401341 },
    { lat: 48.653031, lng: 19.401653 },
    { lat: 48.653081, lng: 19.402934 },
    { lat: 48.653089, lng: 19.403085 },
    { lat: 48.653091, lng: 19.403209 },
    { lat: 48.652927, lng: 19.403617 },
    { lat: 48.652802, lng: 19.403722 },
    { lat: 48.652653, lng: 19.40388 },
    { lat: 48.652383, lng: 19.404077 },
    { lat: 48.651987, lng: 19.404616 },
    { lat: 48.651759, lng: 19.405375 },
    { lat: 48.651608, lng: 19.405806 },
    { lat: 48.651499, lng: 19.406317 },
    { lat: 48.651748, lng: 19.407449 },
    { lat: 48.651918, lng: 19.407842 },
    { lat: 48.65206, lng: 19.408078 },
    { lat: 48.652225, lng: 19.408188 },
    { lat: 48.652328, lng: 19.408465 },
    { lat: 48.652299, lng: 19.408902 },
    { lat: 48.652313, lng: 19.409428 },
    { lat: 48.652194, lng: 19.409949 },
    { lat: 48.652002, lng: 19.410444 },
    { lat: 48.65191, lng: 19.410852 },
    { lat: 48.651863, lng: 19.411562 },
    { lat: 48.651749, lng: 19.412014 },
    { lat: 48.651663, lng: 19.412241 },
    { lat: 48.651552, lng: 19.412427 },
    { lat: 48.651481, lng: 19.412493 },
    { lat: 48.651416, lng: 19.41256 },
    { lat: 48.651184, lng: 19.412958 },
    { lat: 48.650983, lng: 19.413283 },
    { lat: 48.650971, lng: 19.413406 },
    { lat: 48.651039, lng: 19.413662 },
    { lat: 48.651297, lng: 19.413951 },
    { lat: 48.651401, lng: 19.414163 },
    { lat: 48.65143, lng: 19.414734 },
    { lat: 48.65154, lng: 19.41511 },
    { lat: 48.651458, lng: 19.415804 },
    { lat: 48.651451, lng: 19.416135 },
    { lat: 48.651576, lng: 19.416485 },
    { lat: 48.651681, lng: 19.417147 },
    { lat: 48.651847, lng: 19.417801 },
    { lat: 48.652366, lng: 19.419008 },
    { lat: 48.652563, lng: 19.419709 },
    { lat: 48.652593, lng: 19.420124 },
    { lat: 48.65257, lng: 19.420538 },
    { lat: 48.652575, lng: 19.420882 },
    { lat: 48.652697, lng: 19.421418 },
    { lat: 48.652683, lng: 19.421775 },
    { lat: 48.652618, lng: 19.422025 },
    { lat: 48.652533, lng: 19.422483 },
    { lat: 48.65261, lng: 19.422869 },
    { lat: 48.652803, lng: 19.42314 },
    { lat: 48.652682, lng: 19.423495 },
    { lat: 48.652609, lng: 19.423727 },
    { lat: 48.652476, lng: 19.424148 },
    { lat: 48.652492, lng: 19.424391 },
    { lat: 48.652444, lng: 19.424865 },
    { lat: 48.65248, lng: 19.425488 },
    { lat: 48.652403, lng: 19.425949 },
    { lat: 48.652411, lng: 19.426286 },
    { lat: 48.652545, lng: 19.426592 },
    { lat: 48.652537, lng: 19.426785 },
    { lat: 48.652467, lng: 19.426983 },
    { lat: 48.652409, lng: 19.4271 },
    { lat: 48.652257, lng: 19.427456 },
    { lat: 48.65215, lng: 19.428008 },
    { lat: 48.65198, lng: 19.428384 },
    { lat: 48.651959, lng: 19.429034 },
    { lat: 48.652007, lng: 19.429713 },
    { lat: 48.652099, lng: 19.430189 },
    { lat: 48.652103, lng: 19.430192 },
    { lat: 48.652153, lng: 19.430224 },
    { lat: 48.652213, lng: 19.430264 },
    { lat: 48.652427, lng: 19.430324 },
    { lat: 48.652639, lng: 19.430174 },
    { lat: 48.652937, lng: 19.430469 },
    { lat: 48.653111, lng: 19.431004 },
    { lat: 48.653278, lng: 19.431455 },
    { lat: 48.653458, lng: 19.431976 },
    { lat: 48.653702, lng: 19.432438 },
    { lat: 48.654033, lng: 19.432886 },
    { lat: 48.654381, lng: 19.433072 },
    { lat: 48.654416, lng: 19.433091 },
    { lat: 48.654442, lng: 19.433105 },
    { lat: 48.654493, lng: 19.43314 },
    { lat: 48.65469, lng: 19.433455 },
    { lat: 48.655294, lng: 19.433747 },
    { lat: 48.655542, lng: 19.433923 },
    { lat: 48.655697, lng: 19.43396 },
    { lat: 48.656432, lng: 19.433584 },
    { lat: 48.656954, lng: 19.433656 },
    { lat: 48.657064, lng: 19.433706 },
    { lat: 48.657291, lng: 19.433929 },
    { lat: 48.657438, lng: 19.43418 },
    { lat: 48.657647, lng: 19.434446 },
    { lat: 48.657824, lng: 19.43472 },
    { lat: 48.658216, lng: 19.435381 },
    { lat: 48.658403, lng: 19.43543 },
    { lat: 48.658521, lng: 19.435428 },
    { lat: 48.65877, lng: 19.435388 },
    { lat: 48.659126, lng: 19.435542 },
    { lat: 48.659387, lng: 19.435655 },
    { lat: 48.659588, lng: 19.436485 },
    { lat: 48.659658, lng: 19.436641 },
    { lat: 48.659987, lng: 19.437164 },
    { lat: 48.660004, lng: 19.437373 },
    { lat: 48.659784, lng: 19.437981 },
    { lat: 48.659631, lng: 19.438438 },
    { lat: 48.659595, lng: 19.438523 },
    { lat: 48.659559, lng: 19.43861 },
    { lat: 48.659347, lng: 19.438856 },
    { lat: 48.659293, lng: 19.439375 },
    { lat: 48.659268, lng: 19.439842 },
    { lat: 48.659297, lng: 19.440299 },
    { lat: 48.659401, lng: 19.440676 },
    { lat: 48.659676, lng: 19.44135 },
    { lat: 48.659978, lng: 19.442368 },
    { lat: 48.660159, lng: 19.443499 },
    { lat: 48.660311, lng: 19.444574 },
    { lat: 48.66032, lng: 19.444924 },
    { lat: 48.660219, lng: 19.445256 },
    { lat: 48.659972, lng: 19.445617 },
    { lat: 48.65977, lng: 19.446071 },
    { lat: 48.659768, lng: 19.446704 },
    { lat: 48.659857, lng: 19.44799 },
    { lat: 48.659849, lng: 19.448338 },
    { lat: 48.659769, lng: 19.448666 },
    { lat: 48.659753, lng: 19.449056 },
    { lat: 48.659776, lng: 19.449476 },
    { lat: 48.660096, lng: 19.45024 },
    { lat: 48.660283, lng: 19.450735 },
    { lat: 48.660464, lng: 19.45124 },
    { lat: 48.660602, lng: 19.451882 },
    { lat: 48.660758, lng: 19.452134 },
    { lat: 48.660903, lng: 19.452425 },
    { lat: 48.660985, lng: 19.452652 },
    { lat: 48.661085, lng: 19.453097 },
    { lat: 48.661255, lng: 19.453788 },
    { lat: 48.661261, lng: 19.454344 },
    { lat: 48.661445, lng: 19.454912 },
    { lat: 48.661425, lng: 19.455163 },
    { lat: 48.661236, lng: 19.45571 },
    { lat: 48.661049, lng: 19.45625 },
    { lat: 48.660947, lng: 19.456771 },
    { lat: 48.660904, lng: 19.457242 },
    { lat: 48.660797, lng: 19.457455 },
    { lat: 48.660454, lng: 19.457937 },
    { lat: 48.660417, lng: 19.458067 },
    { lat: 48.660362, lng: 19.458674 },
    { lat: 48.66011, lng: 19.459361 },
    { lat: 48.659967, lng: 19.459621 },
    { lat: 48.659853, lng: 19.45994 },
    { lat: 48.659786, lng: 19.460455 },
    { lat: 48.659624, lng: 19.461132 },
    { lat: 48.659599, lng: 19.461486 },
    { lat: 48.659638, lng: 19.461904 },
    { lat: 48.659523, lng: 19.462193 },
    { lat: 48.659487, lng: 19.462575 },
    { lat: 48.659487, lng: 19.463111 },
    { lat: 48.659531, lng: 19.463456 },
    { lat: 48.659719, lng: 19.463649 },
    { lat: 48.660093, lng: 19.463847 },
    { lat: 48.66047, lng: 19.464007 },
    { lat: 48.660598, lng: 19.464229 },
    { lat: 48.660685, lng: 19.464447 },
    { lat: 48.660792, lng: 19.464706 },
    { lat: 48.660889, lng: 19.464967 },
    { lat: 48.660914, lng: 19.465484 },
    { lat: 48.660981, lng: 19.465781 },
    { lat: 48.660992, lng: 19.465916 },
    { lat: 48.660954, lng: 19.466029 },
    { lat: 48.660892, lng: 19.466273 },
    { lat: 48.660836, lng: 19.466481 },
    { lat: 48.660785, lng: 19.466784 },
    { lat: 48.660724, lng: 19.467179 },
    { lat: 48.66087, lng: 19.467644 },
    { lat: 48.660891, lng: 19.468002 },
    { lat: 48.661001, lng: 19.468331 },
    { lat: 48.661251, lng: 19.468574 },
    { lat: 48.661725, lng: 19.468873 },
    { lat: 48.662021, lng: 19.469229 },
    { lat: 48.662122, lng: 19.469414 },
    { lat: 48.662152, lng: 19.469519 },
    { lat: 48.662156, lng: 19.469535 },
    { lat: 48.663159, lng: 19.470824 },
    { lat: 48.663973, lng: 19.472031 },
    { lat: 48.664006, lng: 19.47208 },
    { lat: 48.664292, lng: 19.472503 },
    { lat: 48.665096, lng: 19.473579 },
    { lat: 48.665737, lng: 19.474318 },
    { lat: 48.665815, lng: 19.474388 },
    { lat: 48.666162, lng: 19.474979 },
    { lat: 48.666181, lng: 19.475011 },
    { lat: 48.666192, lng: 19.47503 },
    { lat: 48.66624, lng: 19.475112 },
    { lat: 48.667941, lng: 19.477811 },
    { lat: 48.668294, lng: 19.478139 },
    { lat: 48.669519, lng: 19.479277 },
    { lat: 48.670406, lng: 19.479929 },
    { lat: 48.67098, lng: 19.480207 },
    { lat: 48.671402, lng: 19.480367 },
    { lat: 48.6716404, lng: 19.4806329 },
    { lat: 48.6717066, lng: 19.4807067 },
    { lat: 48.672165, lng: 19.481218 },
    { lat: 48.672587, lng: 19.481341 },
    { lat: 48.672687, lng: 19.481136 },
    { lat: 48.673162, lng: 19.481068 },
    { lat: 48.673762, lng: 19.480685 },
  ],
  Iliaš: [
    { lat: 48.699468, lng: 19.1388923 },
    { lat: 48.6988261, lng: 19.1385838 },
    { lat: 48.6982611, lng: 19.1381685 },
    { lat: 48.6975498, lng: 19.1378282 },
    { lat: 48.6969715, lng: 19.1376456 },
    { lat: 48.6959637, lng: 19.1376677 },
    { lat: 48.6948733, lng: 19.1373423 },
    { lat: 48.6944493, lng: 19.1370186 },
    { lat: 48.6941387, lng: 19.1366889 },
    { lat: 48.6940302, lng: 19.1363125 },
    { lat: 48.6935624, lng: 19.1352201 },
    { lat: 48.6932644, lng: 19.134753 },
    { lat: 48.6927671, lng: 19.1343423 },
    { lat: 48.6921485, lng: 19.134027 },
    { lat: 48.6918906, lng: 19.1339858 },
    { lat: 48.6911405, lng: 19.1340471 },
    { lat: 48.6910446, lng: 19.1340767 },
    { lat: 48.6901235, lng: 19.1343611 },
    { lat: 48.6895392, lng: 19.1346303 },
    { lat: 48.6886626, lng: 19.1352117 },
    { lat: 48.6879057, lng: 19.1353351 },
    { lat: 48.6869994, lng: 19.1355657 },
    { lat: 48.6868482, lng: 19.135653 },
    { lat: 48.6865867, lng: 19.1357002 },
    { lat: 48.6854448, lng: 19.1356344 },
    { lat: 48.6849149, lng: 19.1356762 },
    { lat: 48.6845133, lng: 19.1357607 },
    { lat: 48.6834134, lng: 19.1358364 },
    { lat: 48.6820722, lng: 19.1361411 },
    { lat: 48.6812235, lng: 19.1364857 },
    { lat: 48.6805166, lng: 19.1371081 },
    { lat: 48.6806295, lng: 19.1373784 },
    { lat: 48.680814, lng: 19.1377147 },
    { lat: 48.679948, lng: 19.1393086 },
    { lat: 48.6794232, lng: 19.1399809 },
    { lat: 48.6789458, lng: 19.1407042 },
    { lat: 48.6785578, lng: 19.141453 },
    { lat: 48.6782994, lng: 19.1422922 },
    { lat: 48.6783131, lng: 19.1423873 },
    { lat: 48.6789816, lng: 19.1419993 },
    { lat: 48.6790695, lng: 19.142024 },
    { lat: 48.6793965, lng: 19.1417279 },
    { lat: 48.6800913, lng: 19.1427631 },
    { lat: 48.680518, lng: 19.1427747 },
    { lat: 48.6816168, lng: 19.1426101 },
    { lat: 48.6816497, lng: 19.1440541 },
    { lat: 48.6815349, lng: 19.1443078 },
    { lat: 48.6814446, lng: 19.1447617 },
    { lat: 48.6817552, lng: 19.1452294 },
    { lat: 48.6819186, lng: 19.1453256 },
    { lat: 48.6818523, lng: 19.1454411 },
    { lat: 48.682747, lng: 19.1466241 },
    { lat: 48.6826224, lng: 19.1469317 },
    { lat: 48.6818768, lng: 19.1465807 },
    { lat: 48.6806843, lng: 19.1456694 },
    { lat: 48.6800161, lng: 19.1464765 },
    { lat: 48.6808018, lng: 19.1475947 },
    { lat: 48.6806767, lng: 19.147984 },
    { lat: 48.6802009, lng: 19.1486928 },
    { lat: 48.680528, lng: 19.1486486 },
    { lat: 48.6812324, lng: 19.1494491 },
    { lat: 48.6813382, lng: 19.1505424 },
    { lat: 48.6814069, lng: 19.1507965 },
    { lat: 48.6815928, lng: 19.1510871 },
    { lat: 48.6819094, lng: 19.1514297 },
    { lat: 48.6822677, lng: 19.1518175 },
    { lat: 48.6824709, lng: 19.1521143 },
    { lat: 48.682733, lng: 19.152497 },
    { lat: 48.682924, lng: 19.1529535 },
    { lat: 48.6830665, lng: 19.153294 },
    { lat: 48.6834146, lng: 19.1539658 },
    { lat: 48.6838686, lng: 19.1548421 },
    { lat: 48.6840555, lng: 19.1556824 },
    { lat: 48.6843973, lng: 19.1563763 },
    { lat: 48.6844562, lng: 19.1564958 },
    { lat: 48.6847787, lng: 19.1573236 },
    { lat: 48.6852308, lng: 19.1586869 },
    { lat: 48.6853145, lng: 19.1589392 },
    { lat: 48.6853941, lng: 19.1596569 },
    { lat: 48.685416, lng: 19.1598547 },
    { lat: 48.6853102, lng: 19.1609379 },
    { lat: 48.6854847, lng: 19.1615512 },
    { lat: 48.6855773, lng: 19.1622876 },
    { lat: 48.6856391, lng: 19.1624922 },
    { lat: 48.6859952, lng: 19.1631537 },
    { lat: 48.6860165, lng: 19.1632729 },
    { lat: 48.686065, lng: 19.1635447 },
    { lat: 48.6860898, lng: 19.1636152 },
    { lat: 48.6862295, lng: 19.1640128 },
    { lat: 48.6863863, lng: 19.1644591 },
    { lat: 48.6869731, lng: 19.1646089 },
    { lat: 48.6874736, lng: 19.1645881 },
    { lat: 48.6880731, lng: 19.1644007 },
    { lat: 48.6882266, lng: 19.1650257 },
    { lat: 48.6896913, lng: 19.1648931 },
    { lat: 48.6898871, lng: 19.164955 },
    { lat: 48.6901, lng: 19.1656204 },
    { lat: 48.6901619, lng: 19.1655917 },
    { lat: 48.6906991, lng: 19.1673062 },
    { lat: 48.6912464, lng: 19.1667419 },
    { lat: 48.6912188, lng: 19.1673736 },
    { lat: 48.6920531, lng: 19.1667336 },
    { lat: 48.6927061, lng: 19.1660264 },
    { lat: 48.6927236, lng: 19.1662011 },
    { lat: 48.6933109, lng: 19.1671883 },
    { lat: 48.6934416, lng: 19.1675076 },
    { lat: 48.6939264, lng: 19.1680628 },
    { lat: 48.6945816, lng: 19.1684561 },
    { lat: 48.6946214, lng: 19.1680734 },
    { lat: 48.6947155, lng: 19.1681514 },
    { lat: 48.6956886, lng: 19.1683182 },
    { lat: 48.6957355, lng: 19.1685621 },
    { lat: 48.6962104, lng: 19.1688903 },
    { lat: 48.6962626, lng: 19.1689814 },
    { lat: 48.6963633, lng: 19.1691573 },
    { lat: 48.6964374, lng: 19.169128 },
    { lat: 48.6967679, lng: 19.1689971 },
    { lat: 48.6970941, lng: 19.1690751 },
    { lat: 48.6971285, lng: 19.1691299 },
    { lat: 48.697311, lng: 19.1694203 },
    { lat: 48.6974941, lng: 19.1697119 },
    { lat: 48.6974803, lng: 19.1706483 },
    { lat: 48.6978167, lng: 19.1707987 },
    { lat: 48.6984626, lng: 19.172086 },
    { lat: 48.6985532, lng: 19.1726001 },
    { lat: 48.6990907, lng: 19.172895 },
    { lat: 48.699488, lng: 19.1723752 },
    { lat: 48.6997234, lng: 19.1723246 },
    { lat: 48.7000202, lng: 19.1725152 },
    { lat: 48.7007372, lng: 19.1734595 },
    { lat: 48.7015223, lng: 19.174828 },
    { lat: 48.7017704, lng: 19.1751525 },
    { lat: 48.7019722, lng: 19.1752884 },
    { lat: 48.7028369, lng: 19.1749922 },
    { lat: 48.7040004, lng: 19.1743728 },
    { lat: 48.7041218, lng: 19.1741843 },
    { lat: 48.7047758, lng: 19.1754745 },
    { lat: 48.7051464, lng: 19.1758406 },
    { lat: 48.7053897, lng: 19.1759704 },
    { lat: 48.7058883, lng: 19.1757484 },
    { lat: 48.7063823, lng: 19.1761014 },
    { lat: 48.7068215, lng: 19.1753071 },
    { lat: 48.7069381, lng: 19.1748376 },
    { lat: 48.7073084, lng: 19.174022 },
    { lat: 48.7073582, lng: 19.1738369 },
    { lat: 48.7073772, lng: 19.173445 },
    { lat: 48.7075392, lng: 19.1731649 },
    { lat: 48.7075853, lng: 19.172885 },
    { lat: 48.7076064, lng: 19.1721641 },
    { lat: 48.7077399, lng: 19.1715539 },
    { lat: 48.7076465, lng: 19.1713487 },
    { lat: 48.707844, lng: 19.1709879 },
    { lat: 48.7079908, lng: 19.1710136 },
    { lat: 48.7082515, lng: 19.1705404 },
    { lat: 48.7086639, lng: 19.1693168 },
    { lat: 48.708983, lng: 19.1686989 },
    { lat: 48.7090692, lng: 19.1683855 },
    { lat: 48.7093006, lng: 19.1680284 },
    { lat: 48.7093425, lng: 19.1677542 },
    { lat: 48.7095336, lng: 19.1674412 },
    { lat: 48.7098513, lng: 19.1669802 },
    { lat: 48.7102947, lng: 19.1667142 },
    { lat: 48.7104972, lng: 19.1662932 },
    { lat: 48.7107071, lng: 19.1659973 },
    { lat: 48.7113483, lng: 19.1653969 },
    { lat: 48.7118886, lng: 19.1645282 },
    { lat: 48.7125082, lng: 19.1638548 },
    { lat: 48.7127608, lng: 19.1634424 },
    { lat: 48.7131365, lng: 19.1630348 },
    { lat: 48.7134287, lng: 19.1628374 },
    { lat: 48.7136166, lng: 19.1624837 },
    { lat: 48.7140001, lng: 19.1623279 },
    { lat: 48.714409, lng: 19.1618173 },
    { lat: 48.715416, lng: 19.1614331 },
    { lat: 48.7159713, lng: 19.1613544 },
    { lat: 48.7163683, lng: 19.1609911 },
    { lat: 48.7165585, lng: 19.1606656 },
    { lat: 48.7168175, lng: 19.1597644 },
    { lat: 48.7169836, lng: 19.1593792 },
    { lat: 48.7171084, lng: 19.157978 },
    { lat: 48.7170306, lng: 19.1574944 },
    { lat: 48.7170422, lng: 19.1570636 },
    { lat: 48.716752, lng: 19.1558835 },
    { lat: 48.7176639, lng: 19.1545985 },
    { lat: 48.7178546, lng: 19.1539605 },
    { lat: 48.7178035, lng: 19.1538624 },
    { lat: 48.7178397, lng: 19.1532671 },
    { lat: 48.7179468, lng: 19.1532705 },
    { lat: 48.7179715, lng: 19.1530613 },
    { lat: 48.7186513, lng: 19.1519358 },
    { lat: 48.7185203, lng: 19.1504204 },
    { lat: 48.7185463, lng: 19.1501476 },
    { lat: 48.7186844, lng: 19.1495851 },
    { lat: 48.7189508, lng: 19.1490211 },
    { lat: 48.7190778, lng: 19.148602 },
    { lat: 48.7195559, lng: 19.1477811 },
    { lat: 48.7201591, lng: 19.1473466 },
    { lat: 48.7207116, lng: 19.1463627 },
    { lat: 48.7209013, lng: 19.1461185 },
    { lat: 48.7210457, lng: 19.145525 },
    { lat: 48.722035, lng: 19.1436654 },
    { lat: 48.7226592, lng: 19.1426258 },
    { lat: 48.7229692, lng: 19.1422814 },
    { lat: 48.7232281, lng: 19.1421144 },
    { lat: 48.7233172, lng: 19.1419334 },
    { lat: 48.7235619, lng: 19.1419565 },
    { lat: 48.7237083, lng: 19.1418411 },
    { lat: 48.7236923, lng: 19.1417705 },
    { lat: 48.7241663, lng: 19.1415286 },
    { lat: 48.7242076, lng: 19.1415886 },
    { lat: 48.7249012, lng: 19.1412263 },
    { lat: 48.7250557, lng: 19.1409104 },
    { lat: 48.7256496, lng: 19.1408146 },
    { lat: 48.7256682, lng: 19.1394877 },
    { lat: 48.725673, lng: 19.1391454 },
    { lat: 48.7256968, lng: 19.1374494 },
    { lat: 48.7252953, lng: 19.1375054 },
    { lat: 48.724852, lng: 19.1375382 },
    { lat: 48.7243293, lng: 19.1375928 },
    { lat: 48.7236049, lng: 19.1377841 },
    { lat: 48.7229777, lng: 19.1378605 },
    { lat: 48.7225668, lng: 19.1378933 },
    { lat: 48.7220874, lng: 19.1379097 },
    { lat: 48.7216945, lng: 19.1379644 },
    { lat: 48.7213628, lng: 19.1380245 },
    { lat: 48.7208366, lng: 19.1381556 },
    { lat: 48.7201949, lng: 19.1382649 },
    { lat: 48.7200103, lng: 19.1383056 },
    { lat: 48.7196002, lng: 19.138396 },
    { lat: 48.7190378, lng: 19.1384506 },
    { lat: 48.7185007, lng: 19.1384506 },
    { lat: 48.7179564, lng: 19.1384342 },
    { lat: 48.7175346, lng: 19.1384124 },
    { lat: 48.71712, lng: 19.138396 },
    { lat: 48.7166874, lng: 19.1383632 },
    { lat: 48.7159701, lng: 19.1385545 },
    { lat: 48.7155915, lng: 19.1386747 },
    { lat: 48.7150472, lng: 19.1391063 },
    { lat: 48.7145316, lng: 19.1397128 },
    { lat: 48.714337, lng: 19.1399914 },
    { lat: 48.7141909, lng: 19.1402025 },
    { lat: 48.7140558, lng: 19.1404112 },
    { lat: 48.7138111, lng: 19.1408787 },
    { lat: 48.713602, lng: 19.1413732 },
    { lat: 48.7134083, lng: 19.1417943 },
    { lat: 48.713212, lng: 19.142227 },
    { lat: 48.7130387, lng: 19.1424047 },
    { lat: 48.7128041, lng: 19.14254 },
    { lat: 48.7125466, lng: 19.1427679 },
    { lat: 48.7120087, lng: 19.1432779 },
    { lat: 48.7116136, lng: 19.1435097 },
    { lat: 48.7112235, lng: 19.1436952 },
    { lat: 48.7108106, lng: 19.143842 },
    { lat: 48.7104205, lng: 19.1438883 },
    { lat: 48.7098928, lng: 19.1438497 },
    { lat: 48.7094135, lng: 19.1438574 },
    { lat: 48.7092427, lng: 19.1437802 },
    { lat: 48.7089053, lng: 19.1436139 },
    { lat: 48.7084907, lng: 19.1434172 },
    { lat: 48.7079391, lng: 19.1432642 },
    { lat: 48.7074974, lng: 19.1432369 },
    { lat: 48.7069569, lng: 19.1432871 },
    { lat: 48.706679, lng: 19.1433567 },
    { lat: 48.7062073, lng: 19.1435769 },
    { lat: 48.7060798, lng: 19.1436155 },
    { lat: 48.705654, lng: 19.1436117 },
    { lat: 48.7052614, lng: 19.1434301 },
    { lat: 48.7049631, lng: 19.1431828 },
    { lat: 48.704601, lng: 19.1428428 },
    { lat: 48.7041235, lng: 19.1424278 },
    { lat: 48.7031877, lng: 19.1416875 },
    { lat: 48.7028282, lng: 19.1414557 },
    { lat: 48.7026064, lng: 19.1413436 },
    { lat: 48.7021678, lng: 19.1411427 },
    { lat: 48.701691, lng: 19.1409805 },
    { lat: 48.7012856, lng: 19.1407911 },
    { lat: 48.7007042, lng: 19.1404164 },
    { lat: 48.7004008, lng: 19.1401382 },
    { lat: 48.7000286, lng: 19.1396384 },
    { lat: 48.6997602, lng: 19.139295 },
    { lat: 48.699468, lng: 19.1388923 },
  ],
  Rakytovce: [
    { lat: 48.6806295, lng: 19.1373784 },
    { lat: 48.6805166, lng: 19.1371081 },
    { lat: 48.6804309, lng: 19.1367271 },
    { lat: 48.6803744, lng: 19.136214 },
    { lat: 48.6803603, lng: 19.1358078 },
    { lat: 48.6804215, lng: 19.1354586 },
    { lat: 48.6805344, lng: 19.1350381 },
    { lat: 48.6808308, lng: 19.1342043 },
    { lat: 48.681099, lng: 19.1333847 },
    { lat: 48.6813908, lng: 19.1325936 },
    { lat: 48.6817907, lng: 19.1315033 },
    { lat: 48.6818801, lng: 19.1312253 },
    { lat: 48.6821625, lng: 19.1304841 },
    { lat: 48.6823224, lng: 19.1301634 },
    { lat: 48.6825577, lng: 19.1297572 },
    { lat: 48.6828165, lng: 19.1291158 },
    { lat: 48.68308, lng: 19.12851 },
    { lat: 48.6833576, lng: 19.1277118 },
    { lat: 48.6835082, lng: 19.1269635 },
    { lat: 48.6835928, lng: 19.1264789 },
    { lat: 48.6836775, lng: 19.1259515 },
    { lat: 48.6837152, lng: 19.1252603 },
    { lat: 48.6839175, lng: 19.1246545 },
    { lat: 48.683974, lng: 19.1242554 },
    { lat: 48.6840163, lng: 19.1238135 },
    { lat: 48.684054, lng: 19.1234857 },
    { lat: 48.6840822, lng: 19.1231864 },
    { lat: 48.6842327, lng: 19.1226733 },
    { lat: 48.6841763, lng: 19.1222029 },
    { lat: 48.6840869, lng: 19.1216541 },
    { lat: 48.6841245, lng: 19.1211838 },
    { lat: 48.6842092, lng: 19.1206279 },
    { lat: 48.6843268, lng: 19.1200435 },
    { lat: 48.6844021, lng: 19.1195375 },
    { lat: 48.6844445, lng: 19.1190458 },
    { lat: 48.6844727, lng: 19.1184542 },
    { lat: 48.6844962, lng: 19.1177558 },
    { lat: 48.6844868, lng: 19.1172071 },
    { lat: 48.6844539, lng: 19.1166939 },
    { lat: 48.6844162, lng: 19.116302 },
    { lat: 48.6843551, lng: 19.1159528 },
    { lat: 48.6843033, lng: 19.1157176 },
    { lat: 48.6842139, lng: 19.1153113 },
    { lat: 48.6841669, lng: 19.1150192 },
    { lat: 48.6843551, lng: 19.1145702 },
    { lat: 48.684388, lng: 19.1143635 },
    { lat: 48.6843457, lng: 19.1140357 },
    { lat: 48.684308, lng: 19.1135653 },
    { lat: 48.6846139, lng: 19.1132018 },
    { lat: 48.6851455, lng: 19.1129667 },
    { lat: 48.685282, lng: 19.11276 },
    { lat: 48.6854184, lng: 19.1125462 },
    { lat: 48.6856301, lng: 19.1120616 },
    { lat: 48.6858748, lng: 19.1116055 },
    { lat: 48.6860207, lng: 19.111299 },
    { lat: 48.6862512, lng: 19.1109569 },
    { lat: 48.6864441, lng: 19.1106433 },
    { lat: 48.6865947, lng: 19.1104723 },
    { lat: 48.6867782, lng: 19.1103725 },
    { lat: 48.6871075, lng: 19.110344 },
    { lat: 48.6873992, lng: 19.1103725 },
    { lat: 48.6876862, lng: 19.1102656 },
    { lat: 48.6879544, lng: 19.1101587 },
    { lat: 48.6881849, lng: 19.1100732 },
    { lat: 48.6884394, lng: 19.1099657 },
    { lat: 48.6882075, lng: 19.1089722 },
    { lat: 48.6883208, lng: 19.1080742 },
    { lat: 48.6882976, lng: 19.1079691 },
    { lat: 48.6874977, lng: 19.1083443 },
    { lat: 48.6874247, lng: 19.1077357 },
    { lat: 48.6868334, lng: 19.1078393 },
    { lat: 48.6864116, lng: 19.10815 },
    { lat: 48.6854422, lng: 19.1087007 },
    { lat: 48.68539, lng: 19.1088293 },
    { lat: 48.6847164, lng: 19.1085466 },
    { lat: 48.6842478, lng: 19.1080962 },
    { lat: 48.6832225, lng: 19.1068808 },
    { lat: 48.6825241, lng: 19.1079588 },
    { lat: 48.6824073, lng: 19.1084483 },
    { lat: 48.6817541, lng: 19.1095757 },
    { lat: 48.6811292, lng: 19.1103409 },
    { lat: 48.6810137, lng: 19.1107361 },
    { lat: 48.6804439, lng: 19.1116364 },
    { lat: 48.6797374, lng: 19.1123063 },
    { lat: 48.6791934, lng: 19.1131309 },
    { lat: 48.6789243, lng: 19.1133453 },
    { lat: 48.6785821, lng: 19.1138324 },
    { lat: 48.6781384, lng: 19.1142265 },
    { lat: 48.6771964, lng: 19.1159646 },
    { lat: 48.6756563, lng: 19.1175297 },
    { lat: 48.6754379, lng: 19.1176757 },
    { lat: 48.6753843, lng: 19.1176386 },
    { lat: 48.6753665, lng: 19.1195115 },
    { lat: 48.6743364, lng: 19.1197901 },
    { lat: 48.6738248, lng: 19.1198157 },
    { lat: 48.6738137, lng: 19.1198669 },
    { lat: 48.6737662, lng: 19.1198195 },
    { lat: 48.6737822, lng: 19.1200039 },
    { lat: 48.6735931, lng: 19.1203505 },
    { lat: 48.6736955, lng: 19.1204153 },
    { lat: 48.6737794, lng: 19.1206295 },
    { lat: 48.6737478, lng: 19.1213564 },
    { lat: 48.6736892, lng: 19.1215606 },
    { lat: 48.673753, lng: 19.1216911 },
    { lat: 48.6737474, lng: 19.1219211 },
    { lat: 48.6736534, lng: 19.1221832 },
    { lat: 48.6738761, lng: 19.1225397 },
    { lat: 48.673894, lng: 19.1226961 },
    { lat: 48.673763, lng: 19.1230326 },
    { lat: 48.6738108, lng: 19.1231292 },
    { lat: 48.6738665, lng: 19.123644 },
    { lat: 48.6738154, lng: 19.123954 },
    { lat: 48.6738359, lng: 19.1240955 },
    { lat: 48.673969, lng: 19.1243027 },
    { lat: 48.6739945, lng: 19.1248616 },
    { lat: 48.6741082, lng: 19.1251302 },
    { lat: 48.6743256, lng: 19.1271816 },
    { lat: 48.674772, lng: 19.1288224 },
    { lat: 48.6747838, lng: 19.1293101 },
    { lat: 48.6747368, lng: 19.1298651 },
    { lat: 48.6749162, lng: 19.131669 },
    { lat: 48.6751413, lng: 19.1321409 },
    { lat: 48.6751778, lng: 19.1326978 },
    { lat: 48.6757967, lng: 19.1337503 },
    { lat: 48.6761161, lng: 19.1341424 },
    { lat: 48.6761683, lng: 19.1345396 },
    { lat: 48.6760165, lng: 19.1353685 },
    { lat: 48.6762928, lng: 19.1363958 },
    { lat: 48.6763408, lng: 19.1373256 },
    { lat: 48.6762778, lng: 19.1389504 },
    { lat: 48.6765535, lng: 19.1393945 },
    { lat: 48.6764467, lng: 19.1397004 },
    { lat: 48.6775154, lng: 19.139562 },
    { lat: 48.678162, lng: 19.1393722 },
    { lat: 48.6781529, lng: 19.1393208 },
    { lat: 48.6785045, lng: 19.1391839 },
    { lat: 48.6786303, lng: 19.1396092 },
    { lat: 48.6787676, lng: 19.139831 },
    { lat: 48.6804039, lng: 19.1376752 },
    { lat: 48.6806295, lng: 19.1373784 },
  ],
  Kremnička: [
    { lat: 48.699468, lng: 19.1388923 },
    { lat: 48.6998003, lng: 19.1377643 },
    { lat: 48.7013439, lng: 19.1355787 },
    { lat: 48.7015406, lng: 19.1353856 },
    { lat: 48.7020503, lng: 19.1339724 },
    { lat: 48.7027432, lng: 19.133304 },
    { lat: 48.7029338, lng: 19.1332123 },
    { lat: 48.7029764, lng: 19.133094 },
    { lat: 48.7040817, lng: 19.1277193 },
    { lat: 48.7041248, lng: 19.1263122 },
    { lat: 48.704045, lng: 19.1254239 },
    { lat: 48.7040745, lng: 19.1249748 },
    { lat: 48.7041534, lng: 19.1247682 },
    { lat: 48.7042933, lng: 19.1240397 },
    { lat: 48.7042714, lng: 19.1238258 },
    { lat: 48.7045788, lng: 19.1228382 },
    { lat: 48.7047182, lng: 19.1226472 },
    { lat: 48.704815, lng: 19.1221455 },
    { lat: 48.7048057, lng: 19.1216847 },
    { lat: 48.7048938, lng: 19.1212972 },
    { lat: 48.7051042, lng: 19.12102 },
    { lat: 48.7051896, lng: 19.1203166 },
    { lat: 48.7052212, lng: 19.120056 },
    { lat: 48.7057876, lng: 19.1185843 },
    { lat: 48.7058019, lng: 19.1181669 },
    { lat: 48.705926, lng: 19.1182167 },
    { lat: 48.7060468, lng: 19.1178326 },
    { lat: 48.7061765, lng: 19.1176748 },
    { lat: 48.7062576, lng: 19.1173523 },
    { lat: 48.7061781, lng: 19.115951 },
    { lat: 48.7063323, lng: 19.115854 },
    { lat: 48.7063182, lng: 19.1156172 },
    { lat: 48.7066411, lng: 19.1152166 },
    { lat: 48.7069148, lng: 19.1145271 },
    { lat: 48.7068393, lng: 19.1137326 },
    { lat: 48.7071484, lng: 19.1133978 },
    { lat: 48.7071769, lng: 19.1125847 },
    { lat: 48.7072761, lng: 19.1120181 },
    { lat: 48.7072223, lng: 19.111704 },
    { lat: 48.7068653, lng: 19.1107535 },
    { lat: 48.706859, lng: 19.1094457 },
    { lat: 48.7080889, lng: 19.1100379 },
    { lat: 48.7080996, lng: 19.1099343 },
    { lat: 48.7072804, lng: 19.1079624 },
    { lat: 48.7071543, lng: 19.107558 },
    { lat: 48.7070205, lng: 19.1066102 },
    { lat: 48.7069697, lng: 19.10579 },
    { lat: 48.707021, lng: 19.1057331 },
    { lat: 48.7071347, lng: 19.1051152 },
    { lat: 48.7071509, lng: 19.1044188 },
    { lat: 48.70689, lng: 19.1023903 },
    { lat: 48.7067287, lng: 19.1020898 },
    { lat: 48.7063553, lng: 19.1016792 },
    { lat: 48.7064777, lng: 19.1015561 },
    { lat: 48.7062436, lng: 19.1013374 },
    { lat: 48.7052883, lng: 19.1004434 },
    { lat: 48.7049975, lng: 19.1000095 },
    { lat: 48.7048118, lng: 19.0996098 },
    { lat: 48.7047521, lng: 19.0993392 },
    { lat: 48.7043313, lng: 19.0981727 },
    { lat: 48.7042057, lng: 19.0977329 },
    { lat: 48.7041936, lng: 19.0974231 },
    { lat: 48.7043999, lng: 19.0961073 },
    { lat: 48.7043475, lng: 19.095323 },
    { lat: 48.7042857, lng: 19.0950552 },
    { lat: 48.7039738, lng: 19.0945402 },
    { lat: 48.7038348, lng: 19.0944435 },
    { lat: 48.7030293, lng: 19.0943201 },
    { lat: 48.702611, lng: 19.094364 },
    { lat: 48.7027165, lng: 19.0940158 },
    { lat: 48.7013517, lng: 19.0917345 },
    { lat: 48.7016654, lng: 19.0914904 },
    { lat: 48.7014891, lng: 19.0912085 },
    { lat: 48.7013971, lng: 19.091194 },
    { lat: 48.7011891, lng: 19.0911626 },
    { lat: 48.7009092, lng: 19.0912447 },
    { lat: 48.7008921, lng: 19.0910611 },
    { lat: 48.7005834, lng: 19.0913149 },
    { lat: 48.7004396, lng: 19.0912545 },
    { lat: 48.700234, lng: 19.0907482 },
    { lat: 48.6999528, lng: 19.0907387 },
    { lat: 48.6998536, lng: 19.0902636 },
    { lat: 48.6994936, lng: 19.0900086 },
    { lat: 48.6994243, lng: 19.0901763 },
    { lat: 48.6994638, lng: 19.0902439 },
    { lat: 48.6992472, lng: 19.0902396 },
    { lat: 48.6992645, lng: 19.0904553 },
    { lat: 48.6989917, lng: 19.0904749 },
    { lat: 48.698597, lng: 19.0903848 },
    { lat: 48.6987299, lng: 19.0909725 },
    { lat: 48.6980719, lng: 19.0913659 },
    { lat: 48.6979123, lng: 19.0913291 },
    { lat: 48.6978172, lng: 19.0914548 },
    { lat: 48.6972443, lng: 19.091406 },
    { lat: 48.6971033, lng: 19.0915636 },
    { lat: 48.6964867, lng: 19.0916115 },
    { lat: 48.6963112, lng: 19.0920479 },
    { lat: 48.6960404, lng: 19.0921553 },
    { lat: 48.6957062, lng: 19.0926229 },
    { lat: 48.6955015, lng: 19.0927671 },
    { lat: 48.6953558, lng: 19.0932162 },
    { lat: 48.6952232, lng: 19.0932894 },
    { lat: 48.6946599, lng: 19.0940945 },
    { lat: 48.6944403, lng: 19.0943492 },
    { lat: 48.6942431, lng: 19.0943923 },
    { lat: 48.6942285, lng: 19.0948689 },
    { lat: 48.6939299, lng: 19.0949203 },
    { lat: 48.6937385, lng: 19.095063 },
    { lat: 48.6937899, lng: 19.0954354 },
    { lat: 48.6936049, lng: 19.0955502 },
    { lat: 48.6931754, lng: 19.0963668 },
    { lat: 48.6931229, lng: 19.0965787 },
    { lat: 48.6928824, lng: 19.0972729 },
    { lat: 48.6927282, lng: 19.097573 },
    { lat: 48.6925629, lng: 19.0977648 },
    { lat: 48.6926353, lng: 19.0980199 },
    { lat: 48.69255, lng: 19.0990034 },
    { lat: 48.6925658, lng: 19.0995454 },
    { lat: 48.6926169, lng: 19.1001213 },
    { lat: 48.6928021, lng: 19.1009199 },
    { lat: 48.692871, lng: 19.1015848 },
    { lat: 48.6927298, lng: 19.1023469 },
    { lat: 48.6928455, lng: 19.1025697 },
    { lat: 48.6928638, lng: 19.1028249 },
    { lat: 48.6927264, lng: 19.1032779 },
    { lat: 48.6926154, lng: 19.1039492 },
    { lat: 48.6925598, lng: 19.1047862 },
    { lat: 48.6921968, lng: 19.1060584 },
    { lat: 48.6912009, lng: 19.1075013 },
    { lat: 48.6902767, lng: 19.1083481 },
    { lat: 48.6900434, lng: 19.1084362 },
    { lat: 48.689968, lng: 19.1085304 },
    { lat: 48.6899008, lng: 19.1089038 },
    { lat: 48.6895588, lng: 19.1092161 },
    { lat: 48.6894587, lng: 19.1094033 },
    { lat: 48.6889768, lng: 19.1098215 },
    { lat: 48.6884394, lng: 19.1099657 },
    { lat: 48.6881849, lng: 19.1100732 },
    { lat: 48.6879544, lng: 19.1101587 },
    { lat: 48.6876862, lng: 19.1102656 },
    { lat: 48.6873992, lng: 19.1103725 },
    { lat: 48.6871075, lng: 19.110344 },
    { lat: 48.6867782, lng: 19.1103725 },
    { lat: 48.6865947, lng: 19.1104723 },
    { lat: 48.6864441, lng: 19.1106433 },
    { lat: 48.6862512, lng: 19.1109569 },
    { lat: 48.6860207, lng: 19.111299 },
    { lat: 48.6858748, lng: 19.1116055 },
    { lat: 48.6856301, lng: 19.1120616 },
    { lat: 48.6854184, lng: 19.1125462 },
    { lat: 48.685282, lng: 19.11276 },
    { lat: 48.6851455, lng: 19.1129667 },
    { lat: 48.6846139, lng: 19.1132018 },
    { lat: 48.684308, lng: 19.1135653 },
    { lat: 48.6843457, lng: 19.1140357 },
    { lat: 48.684388, lng: 19.1143635 },
    { lat: 48.6843551, lng: 19.1145702 },
    { lat: 48.6841669, lng: 19.1150192 },
    { lat: 48.6842139, lng: 19.1153113 },
    { lat: 48.6843033, lng: 19.1157176 },
    { lat: 48.6843551, lng: 19.1159528 },
    { lat: 48.6844162, lng: 19.116302 },
    { lat: 48.6844539, lng: 19.1166939 },
    { lat: 48.6844868, lng: 19.1172071 },
    { lat: 48.6844962, lng: 19.1177558 },
    { lat: 48.6844727, lng: 19.1184542 },
    { lat: 48.6844445, lng: 19.1190458 },
    { lat: 48.6844021, lng: 19.1195375 },
    { lat: 48.6843268, lng: 19.1200435 },
    { lat: 48.6842092, lng: 19.1206279 },
    { lat: 48.6841245, lng: 19.1211838 },
    { lat: 48.6840869, lng: 19.1216541 },
    { lat: 48.6841763, lng: 19.1222029 },
    { lat: 48.6842327, lng: 19.1226733 },
    { lat: 48.6840822, lng: 19.1231864 },
    { lat: 48.684054, lng: 19.1234857 },
    { lat: 48.6840163, lng: 19.1238135 },
    { lat: 48.683974, lng: 19.1242554 },
    { lat: 48.6839175, lng: 19.1246545 },
    { lat: 48.6837152, lng: 19.1252603 },
    { lat: 48.6836775, lng: 19.1259515 },
    { lat: 48.6835928, lng: 19.1264789 },
    { lat: 48.6835082, lng: 19.1269635 },
    { lat: 48.6833576, lng: 19.1277118 },
    { lat: 48.68308, lng: 19.12851 },
    { lat: 48.6828165, lng: 19.1291158 },
    { lat: 48.6825577, lng: 19.1297572 },
    { lat: 48.6823224, lng: 19.1301634 },
    { lat: 48.6821625, lng: 19.1304841 },
    { lat: 48.6818801, lng: 19.1312253 },
    { lat: 48.6817907, lng: 19.1315033 },
    { lat: 48.6813908, lng: 19.1325936 },
    { lat: 48.681099, lng: 19.1333847 },
    { lat: 48.6808308, lng: 19.1342043 },
    { lat: 48.6805344, lng: 19.1350381 },
    { lat: 48.6804215, lng: 19.1354586 },
    { lat: 48.6803603, lng: 19.1358078 },
    { lat: 48.6803744, lng: 19.136214 },
    { lat: 48.6804309, lng: 19.1367271 },
    { lat: 48.6805166, lng: 19.1371081 },
    { lat: 48.6812235, lng: 19.1364857 },
    { lat: 48.6820722, lng: 19.1361411 },
    { lat: 48.6834134, lng: 19.1358364 },
    { lat: 48.6845133, lng: 19.1357607 },
    { lat: 48.6849149, lng: 19.1356762 },
    { lat: 48.6854448, lng: 19.1356344 },
    { lat: 48.6865867, lng: 19.1357002 },
    { lat: 48.6868482, lng: 19.135653 },
    { lat: 48.6869994, lng: 19.1355657 },
    { lat: 48.6879057, lng: 19.1353351 },
    { lat: 48.6886626, lng: 19.1352117 },
    { lat: 48.6895392, lng: 19.1346303 },
    { lat: 48.6901235, lng: 19.1343611 },
    { lat: 48.6910446, lng: 19.1340767 },
    { lat: 48.6911405, lng: 19.1340471 },
    { lat: 48.6918906, lng: 19.1339858 },
    { lat: 48.6921485, lng: 19.134027 },
    { lat: 48.6927671, lng: 19.1343423 },
    { lat: 48.6932644, lng: 19.134753 },
    { lat: 48.6935624, lng: 19.1352201 },
    { lat: 48.6940302, lng: 19.1363125 },
    { lat: 48.6941387, lng: 19.1366889 },
    { lat: 48.6944493, lng: 19.1370186 },
    { lat: 48.6948733, lng: 19.1373423 },
    { lat: 48.6959637, lng: 19.1376677 },
    { lat: 48.6969715, lng: 19.1376456 },
    { lat: 48.6975498, lng: 19.1378282 },
    { lat: 48.6982611, lng: 19.1381685 },
    { lat: 48.6988261, lng: 19.1385838 },
    { lat: 48.699468, lng: 19.1388923 },
  ],
  Kráľová: [
    { lat: 48.7200103, lng: 19.1383056 },
    { lat: 48.7199813, lng: 19.1374029 },
    { lat: 48.7199236, lng: 19.1362661 },
    { lat: 48.7198861, lng: 19.1355971 },
    { lat: 48.7198659, lng: 19.1351423 },
    { lat: 48.7198428, lng: 19.134622 },
    { lat: 48.7200303, lng: 19.1345914 },
    { lat: 48.7201313, lng: 19.1345783 },
    { lat: 48.7202611, lng: 19.1345652 },
    { lat: 48.720238, lng: 19.1341192 },
    { lat: 48.7202207, lng: 19.1337956 },
    { lat: 48.7201976, lng: 19.1334283 },
    { lat: 48.7201774, lng: 19.1331048 },
    { lat: 48.7201601, lng: 19.1328293 },
    { lat: 48.7201515, lng: 19.1325145 },
    { lat: 48.7201255, lng: 19.1321909 },
    { lat: 48.7201226, lng: 19.1317143 },
    { lat: 48.7201247, lng: 19.1314793 },
    { lat: 48.7203021, lng: 19.1314669 },
    { lat: 48.7204531, lng: 19.1314391 },
    { lat: 48.720704, lng: 19.1313958 },
    { lat: 48.7208427, lng: 19.1312134 },
    { lat: 48.7210446, lng: 19.1308702 },
    { lat: 48.7211705, lng: 19.1304996 },
    { lat: 48.7212008, lng: 19.1303357 },
    { lat: 48.7212167, lng: 19.1301105 },
    { lat: 48.721202, lng: 19.1297914 },
    { lat: 48.721197, lng: 19.1296833 },
    { lat: 48.721145, lng: 19.129469 },
    { lat: 48.7210787, lng: 19.1291279 },
    { lat: 48.720972, lng: 19.1288656 },
    { lat: 48.7208393, lng: 19.1287607 },
    { lat: 48.7207383, lng: 19.1286076 },
    { lat: 48.7205739, lng: 19.128389 },
    { lat: 48.7203426, lng: 19.1278286 },
    { lat: 48.7202618, lng: 19.1273126 },
    { lat: 48.7201003, lng: 19.1267442 },
    { lat: 48.7200138, lng: 19.1265168 },
    { lat: 48.7199157, lng: 19.1260952 },
    { lat: 48.719708, lng: 19.1257848 },
    { lat: 48.7194858, lng: 19.1254831 },
    { lat: 48.7193878, lng: 19.1249365 },
    { lat: 48.7194051, lng: 19.1245255 },
    { lat: 48.7193156, lng: 19.1235636 },
    { lat: 48.7191397, lng: 19.1230826 },
    { lat: 48.7191945, lng: 19.1228246 },
    { lat: 48.7193214, lng: 19.1226278 },
    { lat: 48.7191858, lng: 19.1224136 },
    { lat: 48.7190618, lng: 19.1222868 },
    { lat: 48.7189752, lng: 19.1221993 },
    { lat: 48.7188772, lng: 19.1219851 },
    { lat: 48.7188137, lng: 19.121832 },
    { lat: 48.7186233, lng: 19.1215828 },
    { lat: 48.7185166, lng: 19.1213336 },
    { lat: 48.7183954, lng: 19.121045 },
    { lat: 48.7183002, lng: 19.120752 },
    { lat: 48.7182598, lng: 19.1205028 },
    { lat: 48.718156, lng: 19.120306 },
    { lat: 48.7179252, lng: 19.1202142 },
    { lat: 48.7176223, lng: 19.1201355 },
    { lat: 48.7173482, lng: 19.1198907 },
    { lat: 48.7172588, lng: 19.1196152 },
    { lat: 48.7172559, lng: 19.1192435 },
    { lat: 48.7171116, lng: 19.1189812 },
    { lat: 48.7170741, lng: 19.1185221 },
    { lat: 48.7171549, lng: 19.1182335 },
    { lat: 48.7171751, lng: 19.1180236 },
    { lat: 48.7171174, lng: 19.1178574 },
    { lat: 48.717002, lng: 19.1176432 },
    { lat: 48.7167597, lng: 19.1174945 },
    { lat: 48.7164799, lng: 19.1170966 },
    { lat: 48.7163789, lng: 19.116913 },
    { lat: 48.716125, lng: 19.1165544 },
    { lat: 48.7160558, lng: 19.116432 },
    { lat: 48.7159548, lng: 19.1159947 },
    { lat: 48.7159865, lng: 19.1155619 },
    { lat: 48.7160269, lng: 19.1153083 },
    { lat: 48.7160584, lng: 19.1150529 },
    { lat: 48.7160339, lng: 19.1149664 },
    { lat: 48.715887, lng: 19.1146695 },
    { lat: 48.7157932, lng: 19.1143356 },
    { lat: 48.7157687, lng: 19.113507 },
    { lat: 48.7156749, lng: 19.1130989 },
    { lat: 48.7155361, lng: 19.1125362 },
    { lat: 48.7154545, lng: 19.112227 },
    { lat: 48.7153933, lng: 19.1119611 },
    { lat: 48.7152913, lng: 19.1116519 },
    { lat: 48.7152587, lng: 19.1112067 },
    { lat: 48.7152424, lng: 19.1108295 },
    { lat: 48.7151689, lng: 19.1105698 },
    { lat: 48.715066, lng: 19.110382 },
    { lat: 48.7143082, lng: 19.1099198 },
    { lat: 48.713933, lng: 19.1093804 },
    { lat: 48.713458, lng: 19.1089383 },
    { lat: 48.7128894, lng: 19.1086336 },
    { lat: 48.7127073, lng: 19.1082812 },
    { lat: 48.7125116, lng: 19.1076304 },
    { lat: 48.7124265, lng: 19.1068451 },
    { lat: 48.7119934, lng: 19.1052271 },
    { lat: 48.7119085, lng: 19.1044738 },
    { lat: 48.7114217, lng: 19.1029751 },
    { lat: 48.7113221, lng: 19.101969 },
    { lat: 48.7109179, lng: 19.0992987 },
    { lat: 48.7106891, lng: 19.0985978 },
    { lat: 48.7104106, lng: 19.0979362 },
    { lat: 48.7102662, lng: 19.0980268 },
    { lat: 48.7096156, lng: 19.0975502 },
    { lat: 48.7090791, lng: 19.0974172 },
    { lat: 48.7083797, lng: 19.0975669 },
    { lat: 48.7082774, lng: 19.0980214 },
    { lat: 48.7079882, lng: 19.0982631 },
    { lat: 48.7078147, lng: 19.0991033 },
    { lat: 48.7066224, lng: 19.1012909 },
    { lat: 48.7064777, lng: 19.1015561 },
    { lat: 48.7063553, lng: 19.1016792 },
    { lat: 48.7067287, lng: 19.1020898 },
    { lat: 48.70689, lng: 19.1023903 },
    { lat: 48.7071509, lng: 19.1044188 },
    { lat: 48.7071347, lng: 19.1051152 },
    { lat: 48.707021, lng: 19.1057331 },
    { lat: 48.7069697, lng: 19.10579 },
    { lat: 48.7070205, lng: 19.1066102 },
    { lat: 48.7071543, lng: 19.107558 },
    { lat: 48.7072804, lng: 19.1079624 },
    { lat: 48.7080996, lng: 19.1099343 },
    { lat: 48.7080889, lng: 19.1100379 },
    { lat: 48.706859, lng: 19.1094457 },
    { lat: 48.7068653, lng: 19.1107535 },
    { lat: 48.7072223, lng: 19.111704 },
    { lat: 48.7072761, lng: 19.1120181 },
    { lat: 48.7071769, lng: 19.1125847 },
    { lat: 48.7071484, lng: 19.1133978 },
    { lat: 48.7068393, lng: 19.1137326 },
    { lat: 48.7069148, lng: 19.1145271 },
    { lat: 48.7066411, lng: 19.1152166 },
    { lat: 48.7063182, lng: 19.1156172 },
    { lat: 48.7063323, lng: 19.115854 },
    { lat: 48.7061781, lng: 19.115951 },
    { lat: 48.7062576, lng: 19.1173523 },
    { lat: 48.7061765, lng: 19.1176748 },
    { lat: 48.7060468, lng: 19.1178326 },
    { lat: 48.705926, lng: 19.1182167 },
    { lat: 48.7058019, lng: 19.1181669 },
    { lat: 48.7057876, lng: 19.1185843 },
    { lat: 48.7052212, lng: 19.120056 },
    { lat: 48.7051896, lng: 19.1203166 },
    { lat: 48.7051042, lng: 19.12102 },
    { lat: 48.7048938, lng: 19.1212972 },
    { lat: 48.7048057, lng: 19.1216847 },
    { lat: 48.704815, lng: 19.1221455 },
    { lat: 48.7047182, lng: 19.1226472 },
    { lat: 48.7045788, lng: 19.1228382 },
    { lat: 48.7042714, lng: 19.1238258 },
    { lat: 48.7042933, lng: 19.1240397 },
    { lat: 48.7041534, lng: 19.1247682 },
    { lat: 48.7040745, lng: 19.1249748 },
    { lat: 48.704045, lng: 19.1254239 },
    { lat: 48.7041248, lng: 19.1263122 },
    { lat: 48.7040817, lng: 19.1277193 },
    { lat: 48.7029764, lng: 19.133094 },
    { lat: 48.7029338, lng: 19.1332123 },
    { lat: 48.7027432, lng: 19.133304 },
    { lat: 48.7020503, lng: 19.1339724 },
    { lat: 48.7015406, lng: 19.1353856 },
    { lat: 48.7013439, lng: 19.1355787 },
    { lat: 48.6998003, lng: 19.1377643 },
    { lat: 48.699468, lng: 19.1388923 },
    { lat: 48.6997602, lng: 19.139295 },
    { lat: 48.7000286, lng: 19.1396384 },
    { lat: 48.7004008, lng: 19.1401382 },
    { lat: 48.7007042, lng: 19.1404164 },
    { lat: 48.7012856, lng: 19.1407911 },
    { lat: 48.701691, lng: 19.1409805 },
    { lat: 48.7021678, lng: 19.1411427 },
    { lat: 48.7026064, lng: 19.1413436 },
    { lat: 48.7028282, lng: 19.1414557 },
    { lat: 48.7031877, lng: 19.1416875 },
    { lat: 48.7041235, lng: 19.1424278 },
    { lat: 48.704601, lng: 19.1428428 },
    { lat: 48.7049631, lng: 19.1431828 },
    { lat: 48.7052614, lng: 19.1434301 },
    { lat: 48.705654, lng: 19.1436117 },
    { lat: 48.7060798, lng: 19.1436155 },
    { lat: 48.7062073, lng: 19.1435769 },
    { lat: 48.706679, lng: 19.1433567 },
    { lat: 48.7069569, lng: 19.1432871 },
    { lat: 48.7074974, lng: 19.1432369 },
    { lat: 48.7079391, lng: 19.1432642 },
    { lat: 48.7084907, lng: 19.1434172 },
    { lat: 48.7089053, lng: 19.1436139 },
    { lat: 48.7092427, lng: 19.1437802 },
    { lat: 48.7094135, lng: 19.1438574 },
    { lat: 48.7098928, lng: 19.1438497 },
    { lat: 48.7104205, lng: 19.1438883 },
    { lat: 48.7108106, lng: 19.143842 },
    { lat: 48.7112235, lng: 19.1436952 },
    { lat: 48.7116136, lng: 19.1435097 },
    { lat: 48.7120087, lng: 19.1432779 },
    { lat: 48.7125466, lng: 19.1427679 },
    { lat: 48.7128041, lng: 19.14254 },
    { lat: 48.7130387, lng: 19.1424047 },
    { lat: 48.713212, lng: 19.142227 },
    { lat: 48.7134083, lng: 19.1417943 },
    { lat: 48.713602, lng: 19.1413732 },
    { lat: 48.7138111, lng: 19.1408787 },
    { lat: 48.7140558, lng: 19.1404112 },
    { lat: 48.7141909, lng: 19.1402025 },
    { lat: 48.714337, lng: 19.1399914 },
    { lat: 48.7145316, lng: 19.1397128 },
    { lat: 48.7150472, lng: 19.1391063 },
    { lat: 48.7155915, lng: 19.1386747 },
    { lat: 48.7159701, lng: 19.1385545 },
    { lat: 48.7166874, lng: 19.1383632 },
    { lat: 48.71712, lng: 19.138396 },
    { lat: 48.7175346, lng: 19.1384124 },
    { lat: 48.7179564, lng: 19.1384342 },
    { lat: 48.7185007, lng: 19.1384506 },
    { lat: 48.7190378, lng: 19.1384506 },
    { lat: 48.7196002, lng: 19.138396 },
    { lat: 48.7200103, lng: 19.1383056 },
  ],
  Radvaň: [
    { lat: 48.7256968, lng: 19.1374494 },
    { lat: 48.7268631, lng: 19.1374331 },
    { lat: 48.7275104, lng: 19.137424 },
    { lat: 48.727661, lng: 19.1371624 },
    { lat: 48.7277905, lng: 19.1367901 },
    { lat: 48.7283683, lng: 19.1358658 },
    { lat: 48.7286607, lng: 19.1352794 },
    { lat: 48.7284829, lng: 19.1348803 },
    { lat: 48.7284832, lng: 19.134656 },
    { lat: 48.7285945, lng: 19.1342821 },
    { lat: 48.7285087, lng: 19.1340529 },
    { lat: 48.7285169, lng: 19.1337159 },
    { lat: 48.7289768, lng: 19.1317726 },
    { lat: 48.7291724, lng: 19.1313506 },
    { lat: 48.7309377, lng: 19.1294314 },
    { lat: 48.7323535, lng: 19.1275111 },
    { lat: 48.7324552, lng: 19.1275182 },
    { lat: 48.7329038, lng: 19.1269002 },
    { lat: 48.7329068, lng: 19.1267555 },
    { lat: 48.7334165, lng: 19.1260752 },
    { lat: 48.7356437, lng: 19.1238785 },
    { lat: 48.7359946, lng: 19.1236796 },
    { lat: 48.7362227, lng: 19.1236792 },
    { lat: 48.7363849, lng: 19.123679 },
    { lat: 48.7364185, lng: 19.123451 },
    { lat: 48.7364788, lng: 19.1234634 },
    { lat: 48.7365448, lng: 19.1233838 },
    { lat: 48.7367023, lng: 19.1230096 },
    { lat: 48.736705, lng: 19.122519 },
    { lat: 48.7367961, lng: 19.1220587 },
    { lat: 48.7370881, lng: 19.1216236 },
    { lat: 48.737165, lng: 19.1211495 },
    { lat: 48.7372704, lng: 19.1210015 },
    { lat: 48.7374557, lng: 19.1208988 },
    { lat: 48.7376709, lng: 19.1200295 },
    { lat: 48.7380333, lng: 19.119586 },
    { lat: 48.7382889, lng: 19.119031 },
    { lat: 48.7384841, lng: 19.119009 },
    { lat: 48.7385919, lng: 19.1188854 },
    { lat: 48.7387621, lng: 19.1183875 },
    { lat: 48.7389831, lng: 19.1183172 },
    { lat: 48.739056, lng: 19.1182207 },
    { lat: 48.7390408, lng: 19.117956 },
    { lat: 48.738913, lng: 19.1178615 },
    { lat: 48.7388833, lng: 19.1177134 },
    { lat: 48.7390588, lng: 19.1175225 },
    { lat: 48.7393144, lng: 19.1170639 },
    { lat: 48.7393434, lng: 19.1164671 },
    { lat: 48.7394808, lng: 19.1160315 },
    { lat: 48.7395091, lng: 19.1157569 },
    { lat: 48.7393719, lng: 19.1154445 },
    { lat: 48.7393841, lng: 19.1153753 },
    { lat: 48.7394211, lng: 19.1152786 },
    { lat: 48.7395532, lng: 19.1153065 },
    { lat: 48.7397406, lng: 19.1151278 },
    { lat: 48.7398226, lng: 19.1147567 },
    { lat: 48.7398543, lng: 19.114352 },
    { lat: 48.7398693, lng: 19.1141292 },
    { lat: 48.739733, lng: 19.1141771 },
    { lat: 48.7389769, lng: 19.1135916 },
    { lat: 48.7384812, lng: 19.113757 },
    { lat: 48.7380282, lng: 19.1135821 },
    { lat: 48.7379514, lng: 19.1134986 },
    { lat: 48.7378224, lng: 19.1133584 },
    { lat: 48.7355894, lng: 19.1109313 },
    { lat: 48.7360866, lng: 19.1097283 },
    { lat: 48.7352295, lng: 19.1090178 },
    { lat: 48.7325616, lng: 19.1072159 },
    { lat: 48.732015, lng: 19.1068467 },
    { lat: 48.7317907, lng: 19.106596 },
    { lat: 48.7316682, lng: 19.1063324 },
    { lat: 48.7317082, lng: 19.1051271 },
    { lat: 48.731736, lng: 19.1042878 },
    { lat: 48.731729, lng: 19.1032383 },
    { lat: 48.7318235, lng: 19.1022566 },
    { lat: 48.7316571, lng: 19.1020751 },
    { lat: 48.7314421, lng: 19.1018406 },
    { lat: 48.7312158, lng: 19.1014177 },
    { lat: 48.7309071, lng: 19.1012724 },
    { lat: 48.7303063, lng: 19.1009895 },
    { lat: 48.7290042, lng: 19.1014885 },
    { lat: 48.7290427, lng: 19.1010845 },
    { lat: 48.7289593, lng: 19.1005369 },
    { lat: 48.7289376, lng: 19.0996227 },
    { lat: 48.7293671, lng: 19.0994198 },
    { lat: 48.7290258, lng: 19.097834 },
    { lat: 48.7287533, lng: 19.096568 },
    { lat: 48.7282733, lng: 19.0937829 },
    { lat: 48.727589, lng: 19.0879769 },
    { lat: 48.7275007, lng: 19.0851873 },
    { lat: 48.7273297, lng: 19.0846508 },
    { lat: 48.7284687, lng: 19.0798994 },
    { lat: 48.72817, lng: 19.0796309 },
    { lat: 48.7272932, lng: 19.0793451 },
    { lat: 48.727201, lng: 19.0786896 },
    { lat: 48.7274497, lng: 19.0783881 },
    { lat: 48.7276424, lng: 19.0787047 },
    { lat: 48.7279245, lng: 19.0788015 },
    { lat: 48.7281713, lng: 19.0786138 },
    { lat: 48.728034, lng: 19.0781912 },
    { lat: 48.7284456, lng: 19.0779223 },
    { lat: 48.7286333, lng: 19.0775038 },
    { lat: 48.7288286, lng: 19.0775312 },
    { lat: 48.7288807, lng: 19.0767893 },
    { lat: 48.7286893, lng: 19.0761144 },
    { lat: 48.7283553, lng: 19.0759735 },
    { lat: 48.7280851, lng: 19.0760921 },
    { lat: 48.7279096, lng: 19.0760793 },
    { lat: 48.7277644, lng: 19.0756302 },
    { lat: 48.7279382, lng: 19.0750694 },
    { lat: 48.7278932, lng: 19.074641 },
    { lat: 48.7275899, lng: 19.0745045 },
    { lat: 48.7273296, lng: 19.0741917 },
    { lat: 48.7274627, lng: 19.0740566 },
    { lat: 48.7270494, lng: 19.073363 },
    { lat: 48.7272682, lng: 19.0733456 },
    { lat: 48.7272059, lng: 19.0729774 },
    { lat: 48.7269435, lng: 19.0726649 },
    { lat: 48.7266469, lng: 19.0715356 },
    { lat: 48.726416, lng: 19.0715141 },
    { lat: 48.7260166, lng: 19.0691589 },
    { lat: 48.7262348, lng: 19.068291 },
    { lat: 48.7262815, lng: 19.0677347 },
    { lat: 48.7283392, lng: 19.0673332 },
    { lat: 48.7284817, lng: 19.0661714 },
    { lat: 48.7287568, lng: 19.0654247 },
    { lat: 48.7296257, lng: 19.0655777 },
    { lat: 48.7302991, lng: 19.0667347 },
    { lat: 48.7304391, lng: 19.0667173 },
    { lat: 48.7307494, lng: 19.0665166 },
    { lat: 48.7315318, lng: 19.0664962 },
    { lat: 48.7316026, lng: 19.0672145 },
    { lat: 48.7314141, lng: 19.0676599 },
    { lat: 48.7310884, lng: 19.068997 },
    { lat: 48.7311454, lng: 19.0694148 },
    { lat: 48.7315416, lng: 19.0695385 },
    { lat: 48.7317704, lng: 19.069016 },
    { lat: 48.7320488, lng: 19.0685436 },
    { lat: 48.7326817, lng: 19.0683696 },
    { lat: 48.7326208, lng: 19.0685508 },
    { lat: 48.7327447, lng: 19.0691106 },
    { lat: 48.7331022, lng: 19.0691163 },
    { lat: 48.7330748, lng: 19.0690176 },
    { lat: 48.7331299, lng: 19.0689235 },
    { lat: 48.7331984, lng: 19.0689189 },
    { lat: 48.7334001, lng: 19.0687141 },
    { lat: 48.7338851, lng: 19.0684488 },
    { lat: 48.7339274, lng: 19.0685965 },
    { lat: 48.7342554, lng: 19.0684555 },
    { lat: 48.734285, lng: 19.0685757 },
    { lat: 48.7341645, lng: 19.0688512 },
    { lat: 48.734964, lng: 19.0692597 },
    { lat: 48.7349335, lng: 19.0693949 },
    { lat: 48.7347717, lng: 19.0693627 },
    { lat: 48.7346475, lng: 19.0697433 },
    { lat: 48.7348967, lng: 19.0701259 },
    { lat: 48.7355015, lng: 19.0699323 },
    { lat: 48.7354588, lng: 19.0704536 },
    { lat: 48.7354112, lng: 19.0705901 },
    { lat: 48.735313, lng: 19.0706957 },
    { lat: 48.7352124, lng: 19.0708039 },
    { lat: 48.7351339, lng: 19.0712439 },
    { lat: 48.7351769, lng: 19.0714468 },
    { lat: 48.7350731, lng: 19.0721473 },
    { lat: 48.7347589, lng: 19.0723602 },
    { lat: 48.7342602, lng: 19.0720142 },
    { lat: 48.7340683, lng: 19.0722296 },
    { lat: 48.7340339, lng: 19.0724921 },
    { lat: 48.7338625, lng: 19.0727642 },
    { lat: 48.7337492, lng: 19.0732928 },
    { lat: 48.7339489, lng: 19.0734089 },
    { lat: 48.7342056, lng: 19.0739697 },
    { lat: 48.734513, lng: 19.0744118 },
    { lat: 48.734818, lng: 19.0745399 },
    { lat: 48.7357536, lng: 19.0753958 },
    { lat: 48.736573, lng: 19.0765457 },
    { lat: 48.7368872, lng: 19.0763371 },
    { lat: 48.7375663, lng: 19.0777241 },
    { lat: 48.7375756, lng: 19.0783849 },
    { lat: 48.7377163, lng: 19.0787439 },
    { lat: 48.7381369, lng: 19.0788596 },
    { lat: 48.7384596, lng: 19.0790911 },
    { lat: 48.7387735, lng: 19.0795789 },
    { lat: 48.7389098, lng: 19.0803428 },
    { lat: 48.7389964, lng: 19.080454 },
    { lat: 48.7391822, lng: 19.0804946 },
    { lat: 48.7393313, lng: 19.0806127 },
    { lat: 48.7394546, lng: 19.0805318 },
    { lat: 48.7397144, lng: 19.0805162 },
    { lat: 48.7398704, lng: 19.0805292 },
    { lat: 48.7409045, lng: 19.0795814 },
    { lat: 48.740941, lng: 19.0792525 },
    { lat: 48.7410927, lng: 19.0792431 },
    { lat: 48.741222, lng: 19.078804 },
    { lat: 48.741308, lng: 19.0788078 },
    { lat: 48.7414526, lng: 19.0778223 },
    { lat: 48.7413728, lng: 19.0776432 },
    { lat: 48.7411263, lng: 19.0776977 },
    { lat: 48.7409504, lng: 19.0762957 },
    { lat: 48.7403422, lng: 19.0750377 },
    { lat: 48.740442, lng: 19.0748935 },
    { lat: 48.7405584, lng: 19.0751481 },
    { lat: 48.7410908, lng: 19.075629 },
    { lat: 48.7418854, lng: 19.0759638 },
    { lat: 48.7420673, lng: 19.0760156 },
    { lat: 48.7422723, lng: 19.0759687 },
    { lat: 48.7424819, lng: 19.0758084 },
    { lat: 48.7423498, lng: 19.0756216 },
    { lat: 48.7423161, lng: 19.0754193 },
    { lat: 48.7424443, lng: 19.0745789 },
    { lat: 48.7434743, lng: 19.0728498 },
    { lat: 48.743599, lng: 19.0729535 },
    { lat: 48.7437104, lng: 19.0727057 },
    { lat: 48.7437126, lng: 19.0719768 },
    { lat: 48.743431, lng: 19.070579 },
    { lat: 48.7432616, lng: 19.0699436 },
    { lat: 48.743105, lng: 19.0697403 },
    { lat: 48.7432202, lng: 19.0693843 },
    { lat: 48.7428217, lng: 19.0661181 },
    { lat: 48.74277, lng: 19.0660391 },
    { lat: 48.7426056, lng: 19.0657877 },
    { lat: 48.7423777, lng: 19.0652875 },
    { lat: 48.7421519, lng: 19.064561 },
    { lat: 48.7414513, lng: 19.063823 },
    { lat: 48.7410471, lng: 19.0635877 },
    { lat: 48.7406381, lng: 19.0637509 },
    { lat: 48.7404568, lng: 19.063776 },
    { lat: 48.7399126, lng: 19.0638515 },
    { lat: 48.7395483, lng: 19.0640531 },
    { lat: 48.7362005, lng: 19.0647385 },
    { lat: 48.7356583, lng: 19.06447 },
    { lat: 48.7352803, lng: 19.0645964 },
    { lat: 48.734967, lng: 19.064535 },
    { lat: 48.7347995, lng: 19.0646577 },
    { lat: 48.7345431, lng: 19.0646227 },
    { lat: 48.7342945, lng: 19.06409 },
    { lat: 48.7339837, lng: 19.0639886 },
    { lat: 48.7339459, lng: 19.0637051 },
    { lat: 48.7339721, lng: 19.0629421 },
    { lat: 48.7334484, lng: 19.0621876 },
    { lat: 48.7332509, lng: 19.0622016 },
    { lat: 48.7330803, lng: 19.0620825 },
    { lat: 48.7328968, lng: 19.0620892 },
    { lat: 48.7321735, lng: 19.0617589 },
    { lat: 48.7318893, lng: 19.0618478 },
    { lat: 48.7316449, lng: 19.0618086 },
    { lat: 48.7314543, lng: 19.0616714 },
    { lat: 48.731203, lng: 19.0616255 },
    { lat: 48.7307093, lng: 19.0612447 },
    { lat: 48.730655, lng: 19.0609467 },
    { lat: 48.7303253, lng: 19.0602629 },
    { lat: 48.7299567, lng: 19.0597651 },
    { lat: 48.7298364, lng: 19.0594806 },
    { lat: 48.7293908, lng: 19.0590687 },
    { lat: 48.7291869, lng: 19.0591163 },
    { lat: 48.7288658, lng: 19.0590776 },
    { lat: 48.7283129, lng: 19.0585933 },
    { lat: 48.728275, lng: 19.058397 },
    { lat: 48.7279323, lng: 19.058129 },
    { lat: 48.7276821, lng: 19.0581283 },
    { lat: 48.7274886, lng: 19.057373 },
    { lat: 48.727298, lng: 19.056897 },
    { lat: 48.7270615, lng: 19.0565837 },
    { lat: 48.7268528, lng: 19.0564448 },
    { lat: 48.726662, lng: 19.0561919 },
    { lat: 48.727868, lng: 19.0552192 },
    { lat: 48.7284255, lng: 19.054577 },
    { lat: 48.7285466, lng: 19.055287 },
    { lat: 48.7289133, lng: 19.0561943 },
    { lat: 48.7290583, lng: 19.0563405 },
    { lat: 48.7290457, lng: 19.0564523 },
    { lat: 48.7292248, lng: 19.0568315 },
    { lat: 48.7296526, lng: 19.0565937 },
    { lat: 48.7297747, lng: 19.056734 },
    { lat: 48.7297521, lng: 19.056893 },
    { lat: 48.7298646, lng: 19.0570015 },
    { lat: 48.7300184, lng: 19.0568076 },
    { lat: 48.7301295, lng: 19.0570216 },
    { lat: 48.7297352, lng: 19.0575463 },
    { lat: 48.7294737, lng: 19.0581572 },
    { lat: 48.7294746, lng: 19.0583771 },
    { lat: 48.7321683, lng: 19.0591486 },
    { lat: 48.7346922, lng: 19.0595959 },
    { lat: 48.7362798, lng: 19.060345 },
    { lat: 48.7388292, lng: 19.0611453 },
    { lat: 48.74183, lng: 19.0619935 },
    { lat: 48.7422016, lng: 19.0622579 },
    { lat: 48.7428878, lng: 19.0631565 },
    { lat: 48.7432688, lng: 19.0639435 },
    { lat: 48.7435649, lng: 19.0633453 },
    { lat: 48.7437775, lng: 19.0627309 },
    { lat: 48.7433971, lng: 19.0624496 },
    { lat: 48.7433856, lng: 19.0621498 },
    { lat: 48.7434242, lng: 19.0621129 },
    { lat: 48.7433741, lng: 19.0615821 },
    { lat: 48.7432687, lng: 19.0614112 },
    { lat: 48.7430212, lng: 19.0606565 },
    { lat: 48.7427117, lng: 19.0602593 },
    { lat: 48.7427183, lng: 19.0595366 },
    { lat: 48.7425551, lng: 19.059068 },
    { lat: 48.7423382, lng: 19.0586288 },
    { lat: 48.7418702, lng: 19.0582447 },
    { lat: 48.7389971, lng: 19.05697 },
    { lat: 48.7373954, lng: 19.0564562 },
    { lat: 48.736767, lng: 19.0563798 },
    { lat: 48.7367454, lng: 19.056288 },
    { lat: 48.7364788, lng: 19.0560974 },
    { lat: 48.7358936, lng: 19.0559118 },
    { lat: 48.7356638, lng: 19.0556262 },
    { lat: 48.7353471, lng: 19.0556696 },
    { lat: 48.7352011, lng: 19.0554345 },
    { lat: 48.7346587, lng: 19.0553879 },
    { lat: 48.7341394, lng: 19.0549244 },
    { lat: 48.7337997, lng: 19.0544658 },
    { lat: 48.733747, lng: 19.0544693 },
    { lat: 48.7335017, lng: 19.0540959 },
    { lat: 48.7333074, lng: 19.0539288 },
    { lat: 48.7332319, lng: 19.0536614 },
    { lat: 48.7330405, lng: 19.0533729 },
    { lat: 48.7330318, lng: 19.0532886 },
    { lat: 48.7326483, lng: 19.0528142 },
    { lat: 48.7325773, lng: 19.0525427 },
    { lat: 48.7320687, lng: 19.0518627 },
    { lat: 48.7316744, lng: 19.051461 },
    { lat: 48.7311649, lng: 19.0511293 },
    { lat: 48.7308664, lng: 19.051226 },
    { lat: 48.7306035, lng: 19.0511268 },
    { lat: 48.7303572, lng: 19.0507789 },
    { lat: 48.729838, lng: 19.0504072 },
    { lat: 48.7295579, lng: 19.0499236 },
    { lat: 48.7295188, lng: 19.0495056 },
    { lat: 48.7292023, lng: 19.0486769 },
    { lat: 48.7289443, lng: 19.0483654 },
    { lat: 48.7287461, lng: 19.0478756 },
    { lat: 48.7281914, lng: 19.0474459 },
    { lat: 48.7279623, lng: 19.0476214 },
    { lat: 48.7273854, lng: 19.0473579 },
    { lat: 48.7267056, lng: 19.0466383 },
    { lat: 48.7266435, lng: 19.0459176 },
    { lat: 48.7268538, lng: 19.0456825 },
    { lat: 48.7267596, lng: 19.0448363 },
    { lat: 48.7265351, lng: 19.0447452 },
    { lat: 48.7264044, lng: 19.0448337 },
    { lat: 48.726212, lng: 19.0446379 },
    { lat: 48.7261683, lng: 19.044398 },
    { lat: 48.7261991, lng: 19.0440224 },
    { lat: 48.7260516, lng: 19.0437561 },
    { lat: 48.7262489, lng: 19.0436913 },
    { lat: 48.7262647, lng: 19.0430168 },
    { lat: 48.7261537, lng: 19.042622 },
    { lat: 48.7262409, lng: 19.0422921 },
    { lat: 48.7260431, lng: 19.0416316 },
    { lat: 48.72617, lng: 19.0411939 },
    { lat: 48.7263544, lng: 19.0408904 },
    { lat: 48.7264192, lng: 19.0402519 },
    { lat: 48.7270104, lng: 19.0400135 },
    { lat: 48.7276265, lng: 19.0389908 },
    { lat: 48.7280878, lng: 19.0389237 },
    { lat: 48.7283066, lng: 19.0385476 },
    { lat: 48.7287683, lng: 19.0392078 },
    { lat: 48.7287633, lng: 19.0389045 },
    { lat: 48.7292995, lng: 19.0386259 },
    { lat: 48.729793, lng: 19.0387571 },
    { lat: 48.7299814, lng: 19.0390934 },
    { lat: 48.7303945, lng: 19.0391057 },
    { lat: 48.7306675, lng: 19.0393351 },
    { lat: 48.7309348, lng: 19.0407079 },
    { lat: 48.7312142, lng: 19.0409509 },
    { lat: 48.731438, lng: 19.0413202 },
    { lat: 48.7315246, lng: 19.0412714 },
    { lat: 48.7316157, lng: 19.0414242 },
    { lat: 48.731461, lng: 19.0417129 },
    { lat: 48.7315935, lng: 19.0421974 },
    { lat: 48.7317445, lng: 19.0423297 },
    { lat: 48.7321568, lng: 19.0424343 },
    { lat: 48.732463, lng: 19.0416385 },
    { lat: 48.7326082, lng: 19.0416554 },
    { lat: 48.7327808, lng: 19.0419513 },
    { lat: 48.7330958, lng: 19.0420749 },
    { lat: 48.7334848, lng: 19.0423475 },
    { lat: 48.7338988, lng: 19.0433847 },
    { lat: 48.7338397, lng: 19.0436679 },
    { lat: 48.7338527, lng: 19.0440403 },
    { lat: 48.7339497, lng: 19.0447589 },
    { lat: 48.7339417, lng: 19.0452744 },
    { lat: 48.7338689, lng: 19.045646 },
    { lat: 48.7345354, lng: 19.0469769 },
    { lat: 48.7357681, lng: 19.0490808 },
    { lat: 48.7366762, lng: 19.0486637 },
    { lat: 48.7369607, lng: 19.0482221 },
    { lat: 48.7374489, lng: 19.0472101 },
    { lat: 48.7384274, lng: 19.047277 },
    { lat: 48.7389542, lng: 19.0476632 },
    { lat: 48.7394364, lng: 19.0477001 },
    { lat: 48.7393593, lng: 19.0488641 },
    { lat: 48.7388783, lng: 19.0497042 },
    { lat: 48.7391371, lng: 19.0500311 },
    { lat: 48.7392408, lng: 19.0503721 },
    { lat: 48.7390488, lng: 19.0506559 },
    { lat: 48.739069, lng: 19.0507915 },
    { lat: 48.7391901, lng: 19.0508644 },
    { lat: 48.7391484, lng: 19.0510938 },
    { lat: 48.7393395, lng: 19.0515545 },
    { lat: 48.7387502, lng: 19.0515162 },
    { lat: 48.7389551, lng: 19.0522307 },
    { lat: 48.7391635, lng: 19.0522958 },
    { lat: 48.7394255, lng: 19.0521933 },
    { lat: 48.739721, lng: 19.0514748 },
    { lat: 48.7398575, lng: 19.0512938 },
    { lat: 48.7400938, lng: 19.0514883 },
    { lat: 48.7405981, lng: 19.0510902 },
    { lat: 48.7407044, lng: 19.0516806 },
    { lat: 48.740872, lng: 19.0518346 },
    { lat: 48.7410445, lng: 19.0516274 },
    { lat: 48.7409224, lng: 19.0511277 },
    { lat: 48.7408761, lng: 19.0507215 },
    { lat: 48.7407399, lng: 19.0504981 },
    { lat: 48.7407051, lng: 19.0502785 },
    { lat: 48.74081, lng: 19.0496032 },
    { lat: 48.7407315, lng: 19.0496772 },
    { lat: 48.7405544, lng: 19.0495614 },
    { lat: 48.7404731, lng: 19.0492921 },
    { lat: 48.7403751, lng: 19.0487075 },
    { lat: 48.7403792, lng: 19.0484966 },
    { lat: 48.7404452, lng: 19.0484187 },
    { lat: 48.7403144, lng: 19.0476982 },
    { lat: 48.739937, lng: 19.0469374 },
    { lat: 48.739649, lng: 19.0466578 },
    { lat: 48.7395729, lng: 19.046374 },
    { lat: 48.7392406, lng: 19.0460652 },
    { lat: 48.7392946, lng: 19.0459504 },
    { lat: 48.7398172, lng: 19.045762 },
    { lat: 48.7394187, lng: 19.0451215 },
    { lat: 48.7389362, lng: 19.0439502 },
    { lat: 48.7385065, lng: 19.0436105 },
    { lat: 48.7384616, lng: 19.0434559 },
    { lat: 48.7383425, lng: 19.043441 },
    { lat: 48.73785, lng: 19.0428384 },
    { lat: 48.7369635, lng: 19.0421574 },
    { lat: 48.7367371, lng: 19.0428113 },
    { lat: 48.736263, lng: 19.0422849 },
    { lat: 48.7356563, lng: 19.0419208 },
    { lat: 48.7351447, lng: 19.0410933 },
    { lat: 48.7339156, lng: 19.0405499 },
    { lat: 48.7336677, lng: 19.0401897 },
    { lat: 48.7329915, lng: 19.0396319 },
    { lat: 48.7327385, lng: 19.0394926 },
    { lat: 48.7325668, lng: 19.0390552 },
    { lat: 48.7325616, lng: 19.0387054 },
    { lat: 48.7322738, lng: 19.0377109 },
    { lat: 48.7322651, lng: 19.0365027 },
    { lat: 48.732482, lng: 19.0358396 },
    { lat: 48.7325982, lng: 19.0347845 },
    { lat: 48.732607, lng: 19.0343296 },
    { lat: 48.7325514, lng: 19.0337167 },
    { lat: 48.7331783, lng: 19.0300358 },
    { lat: 48.7327867, lng: 19.0310636 },
    { lat: 48.7327807, lng: 19.0313007 },
    { lat: 48.7325061, lng: 19.0317601 },
    { lat: 48.7323415, lng: 19.0308408 },
    { lat: 48.7322564, lng: 19.0306038 },
    { lat: 48.7322476, lng: 19.0302741 },
    { lat: 48.7320797, lng: 19.0298295 },
    { lat: 48.7308553, lng: 19.0288622 },
    { lat: 48.7289676, lng: 19.027036 },
    { lat: 48.7289194, lng: 19.0265955 },
    { lat: 48.728342, lng: 19.026525 },
    { lat: 48.7269663, lng: 19.0252407 },
    { lat: 48.7265197, lng: 19.0251035 },
    { lat: 48.7264238, lng: 19.0252116 },
    { lat: 48.7262636, lng: 19.0251868 },
    { lat: 48.7260942, lng: 19.0252488 },
    { lat: 48.7258251, lng: 19.0250801 },
    { lat: 48.7287291, lng: 19.0165849 },
    { lat: 48.7289974, lng: 19.0168069 },
    { lat: 48.7294612, lng: 19.0169296 },
    { lat: 48.729771, lng: 19.0171014 },
    { lat: 48.7300298, lng: 19.0169813 },
    { lat: 48.7304788, lng: 19.0166264 },
    { lat: 48.7308184, lng: 19.0156027 },
    { lat: 48.7309874, lng: 19.0146369 },
    { lat: 48.7315646, lng: 19.012514 },
    { lat: 48.731883, lng: 19.0115388 },
    { lat: 48.7319761, lng: 19.0114409 },
    { lat: 48.732282, lng: 19.0120957 },
    { lat: 48.7324654, lng: 19.0126681 },
    { lat: 48.7331702, lng: 19.0168995 },
    { lat: 48.7333912, lng: 19.0170376 },
    { lat: 48.7336689, lng: 19.0189672 },
    { lat: 48.7336082, lng: 19.0192177 },
    { lat: 48.7340149, lng: 19.0216785 },
    { lat: 48.7349687, lng: 19.0229521 },
    { lat: 48.7366579, lng: 19.0260771 },
    { lat: 48.7372989, lng: 19.0274117 },
    { lat: 48.7385561, lng: 19.0294543 },
    { lat: 48.7388043, lng: 19.0292555 },
    { lat: 48.7389671, lng: 19.0292882 },
    { lat: 48.7393617, lng: 19.0291121 },
    { lat: 48.7396187, lng: 19.0292184 },
    { lat: 48.7404949, lng: 19.0289313 },
    { lat: 48.7406321, lng: 19.0291934 },
    { lat: 48.7408016, lng: 19.0291168 },
    { lat: 48.7410525, lng: 19.0284307 },
    { lat: 48.7413192, lng: 19.0282876 },
    { lat: 48.7416382, lng: 19.0327756 },
    { lat: 48.7423718, lng: 19.0333523 },
    { lat: 48.7419501, lng: 19.0335964 },
    { lat: 48.741512, lng: 19.0339653 },
    { lat: 48.7417258, lng: 19.0349449 },
    { lat: 48.7416328, lng: 19.0353842 },
    { lat: 48.7412046, lng: 19.035498 },
    { lat: 48.7407051, lng: 19.0344898 },
    { lat: 48.740388, lng: 19.034504 },
    { lat: 48.7398973, lng: 19.0340513 },
    { lat: 48.7395675, lng: 19.0341066 },
    { lat: 48.7392087, lng: 19.0338303 },
    { lat: 48.7392783, lng: 19.0342948 },
    { lat: 48.739218, lng: 19.0345346 },
    { lat: 48.739044, lng: 19.0346814 },
    { lat: 48.7390562, lng: 19.034949 },
    { lat: 48.7389151, lng: 19.0349898 },
    { lat: 48.7387785, lng: 19.0361978 },
    { lat: 48.7389628, lng: 19.0368824 },
    { lat: 48.738727, lng: 19.0369884 },
    { lat: 48.7384996, lng: 19.0369302 },
    { lat: 48.7380576, lng: 19.0366376 },
    { lat: 48.7377653, lng: 19.0366329 },
    { lat: 48.7378067, lng: 19.0370286 },
    { lat: 48.7379274, lng: 19.0375469 },
    { lat: 48.7382539, lng: 19.0383935 },
    { lat: 48.7382471, lng: 19.0385268 },
    { lat: 48.7383612, lng: 19.038913 },
    { lat: 48.739859, lng: 19.0403401 },
    { lat: 48.7409522, lng: 19.0415246 },
    { lat: 48.7409691, lng: 19.0428283 },
    { lat: 48.740778, lng: 19.0442575 },
    { lat: 48.7407721, lng: 19.0443 },
    { lat: 48.7411445, lng: 19.0441934 },
    { lat: 48.7413146, lng: 19.044415 },
    { lat: 48.7414969, lng: 19.0442527 },
    { lat: 48.7417346, lng: 19.0442015 },
    { lat: 48.7418093, lng: 19.0445227 },
    { lat: 48.742817, lng: 19.0444308 },
    { lat: 48.7430862, lng: 19.0442464 },
    { lat: 48.7433066, lng: 19.0439196 },
    { lat: 48.7438273, lng: 19.0439632 },
    { lat: 48.7436534, lng: 19.0437306 },
    { lat: 48.7436323, lng: 19.0434229 },
    { lat: 48.7435579, lng: 19.0432861 },
    { lat: 48.7435653, lng: 19.0430629 },
    { lat: 48.7435705, lng: 19.0429067 },
    { lat: 48.7435095, lng: 19.0428924 },
    { lat: 48.7432659, lng: 19.0428351 },
    { lat: 48.7431967, lng: 19.04193 },
    { lat: 48.7431732, lng: 19.0417534 },
    { lat: 48.7431948, lng: 19.0413076 },
    { lat: 48.7429983, lng: 19.0406914 },
    { lat: 48.7428801, lng: 19.0405821 },
    { lat: 48.7427364, lng: 19.0402603 },
    { lat: 48.7426689, lng: 19.0399226 },
    { lat: 48.7429935, lng: 19.0394035 },
    { lat: 48.7431415, lng: 19.0392551 },
    { lat: 48.7431355, lng: 19.0386603 },
    { lat: 48.7430091, lng: 19.0381644 },
    { lat: 48.7431731, lng: 19.0380195 },
    { lat: 48.743315, lng: 19.0375902 },
    { lat: 48.743333, lng: 19.0373983 },
    { lat: 48.743351, lng: 19.0372064 },
    { lat: 48.7434457, lng: 19.0371387 },
    { lat: 48.7435172, lng: 19.0369208 },
    { lat: 48.7434594, lng: 19.0368464 },
    { lat: 48.7434512, lng: 19.0365941 },
    { lat: 48.7431677, lng: 19.036204 },
    { lat: 48.7432349, lng: 19.0354294 },
    { lat: 48.7431953, lng: 19.0350438 },
    { lat: 48.7432215, lng: 19.034535 },
    { lat: 48.743324, lng: 19.034126 },
    { lat: 48.743921, lng: 19.032973 },
    { lat: 48.7438734, lng: 19.0327213 },
    { lat: 48.7443204, lng: 19.0322617 },
    { lat: 48.7443123, lng: 19.0320104 },
    { lat: 48.7442134, lng: 19.0318574 },
    { lat: 48.7442485, lng: 19.0314122 },
    { lat: 48.7445749, lng: 19.0307278 },
    { lat: 48.7439546, lng: 19.0292444 },
    { lat: 48.7439483, lng: 19.0289822 },
    { lat: 48.7440158, lng: 19.0287973 },
    { lat: 48.7438694, lng: 19.0279721 },
    { lat: 48.74387, lng: 19.027627 },
    { lat: 48.743732, lng: 19.0273914 },
    { lat: 48.7436072, lng: 19.0266373 },
    { lat: 48.7435229, lng: 19.0265309 },
    { lat: 48.7436421, lng: 19.0262565 },
    { lat: 48.7438307, lng: 19.0252524 },
    { lat: 48.7438122, lng: 19.0249488 },
    { lat: 48.7439369, lng: 19.0249634 },
    { lat: 48.7439039, lng: 19.024377 },
    { lat: 48.7438204, lng: 19.0238158 },
    { lat: 48.7437516, lng: 19.0236122 },
    { lat: 48.7437755, lng: 19.0233216 },
    { lat: 48.7436839, lng: 19.0228252 },
    { lat: 48.7437436, lng: 19.0226219 },
    { lat: 48.7437424, lng: 19.0224195 },
    { lat: 48.7436922, lng: 19.022272 },
    { lat: 48.7437895, lng: 19.0218532 },
    { lat: 48.7437409, lng: 19.0217195 },
    { lat: 48.7438753, lng: 19.0216094 },
    { lat: 48.7439206, lng: 19.0215002 },
    { lat: 48.7438646, lng: 19.021422 },
    { lat: 48.7438863, lng: 19.0212941 },
    { lat: 48.7437975, lng: 19.0212282 },
    { lat: 48.7437599, lng: 19.0209746 },
    { lat: 48.7438122, lng: 19.0204449 },
    { lat: 48.7436641, lng: 19.0196993 },
    { lat: 48.7437133, lng: 19.0190628 },
    { lat: 48.7436435, lng: 19.0187315 },
    { lat: 48.7436904, lng: 19.0184521 },
    { lat: 48.7434426, lng: 19.0180783 },
    { lat: 48.7434265, lng: 19.0177762 },
    { lat: 48.7433415, lng: 19.0176124 },
    { lat: 48.7434129, lng: 19.0173045 },
    { lat: 48.7433095, lng: 19.0172692 },
    { lat: 48.7432705, lng: 19.0170758 },
    { lat: 48.7431667, lng: 19.016512 },
    { lat: 48.7432175, lng: 19.0167597 },
    { lat: 48.7432474, lng: 19.0164155 },
    { lat: 48.7434598, lng: 19.0160708 },
    { lat: 48.7435173, lng: 19.0159775 },
    { lat: 48.7436744, lng: 19.0158737 },
    { lat: 48.7438759, lng: 19.0154183 },
    { lat: 48.7440174, lng: 19.0154088 },
    { lat: 48.7442926, lng: 19.0150712 },
    { lat: 48.7445463, lng: 19.0147599 },
    { lat: 48.7447144, lng: 19.0146312 },
    { lat: 48.7447953, lng: 19.0144454 },
    { lat: 48.7448028, lng: 19.0143031 },
    { lat: 48.7449456, lng: 19.0141599 },
    { lat: 48.7448173, lng: 19.0136498 },
    { lat: 48.7447077, lng: 19.0134372 },
    { lat: 48.7446862, lng: 19.0133956 },
    { lat: 48.7447631, lng: 19.0122343 },
    { lat: 48.7447668, lng: 19.0121782 },
    { lat: 48.7448327, lng: 19.0118268 },
    { lat: 48.7449009, lng: 19.0114629 },
    { lat: 48.7450709, lng: 19.0109893 },
    { lat: 48.7450519, lng: 19.010701 },
    { lat: 48.7448671, lng: 19.0104316 },
    { lat: 48.7448489, lng: 19.0101847 },
    { lat: 48.7449192, lng: 19.0099901 },
    { lat: 48.7449579, lng: 19.0095945 },
    { lat: 48.7450922, lng: 19.0093536 },
    { lat: 48.745064, lng: 19.0089499 },
    { lat: 48.7451426, lng: 19.0085397 },
    { lat: 48.7451424, lng: 19.0083157 },
    { lat: 48.7450423, lng: 19.0075106 },
    { lat: 48.7447475, lng: 19.0066562 },
    { lat: 48.7447507, lng: 19.0064279 },
    { lat: 48.7449078, lng: 19.0057636 },
    { lat: 48.744917, lng: 19.0055017 },
    { lat: 48.7448066, lng: 19.0052551 },
    { lat: 48.7447984, lng: 19.0050932 },
    { lat: 48.7449794, lng: 19.004345 },
    { lat: 48.7453941, lng: 19.0039388 },
    { lat: 48.7456817, lng: 19.0031822 },
    { lat: 48.7456363, lng: 18.997725 },
    { lat: 48.7456346, lng: 18.9975155 },
    { lat: 48.745631, lng: 18.997085 },
    { lat: 48.7455236, lng: 18.9970786 },
    { lat: 48.745396, lng: 18.997071 },
    { lat: 48.744973, lng: 18.99735 },
    { lat: 48.744848, lng: 18.997324 },
    { lat: 48.74454, lng: 18.99752 },
    { lat: 48.744285, lng: 18.997391 },
    { lat: 48.744084, lng: 18.99778 },
    { lat: 48.743935, lng: 18.997814 },
    { lat: 48.74375, lng: 18.997666 },
    { lat: 48.743606, lng: 18.997692 },
    { lat: 48.743327, lng: 18.997282 },
    { lat: 48.742954, lng: 18.997213 },
    { lat: 48.742587, lng: 18.9971 },
    { lat: 48.742294, lng: 18.996995 },
    { lat: 48.741869, lng: 18.99697 },
    { lat: 48.741502, lng: 18.996636 },
    { lat: 48.741365, lng: 18.99654 },
    { lat: 48.740815, lng: 18.996387 },
    { lat: 48.740427, lng: 18.996467 },
    { lat: 48.740072, lng: 18.996474 },
    { lat: 48.739885, lng: 18.996478 },
    { lat: 48.739617, lng: 18.996719 },
    { lat: 48.739455, lng: 18.997001 },
    { lat: 48.738996, lng: 18.997774 },
    { lat: 48.738807, lng: 18.99838 },
    { lat: 48.738627, lng: 18.998584 },
    { lat: 48.738084, lng: 18.999301 },
    { lat: 48.737629, lng: 19.00032 },
    { lat: 48.737372, lng: 19.000569 },
    { lat: 48.73669, lng: 19.000762 },
    { lat: 48.736364, lng: 19.000934 },
    { lat: 48.735685, lng: 19.001094 },
    { lat: 48.735465, lng: 19.001068 },
    { lat: 48.735118, lng: 19.001429 },
    { lat: 48.734734, lng: 19.001591 },
    { lat: 48.734646, lng: 19.001639 },
    { lat: 48.73418, lng: 19.002117 },
    { lat: 48.733795, lng: 19.002426 },
    { lat: 48.733417, lng: 19.00261 },
    { lat: 48.733186, lng: 19.002689 },
    { lat: 48.733053, lng: 19.002593 },
    { lat: 48.732567, lng: 19.002249 },
    { lat: 48.731988, lng: 19.001877 },
    { lat: 48.731524, lng: 19.001504 },
    { lat: 48.73076, lng: 19.00145 },
    { lat: 48.730376, lng: 19.00154 },
    { lat: 48.730018, lng: 19.001332 },
    { lat: 48.729431, lng: 19.001087 },
    { lat: 48.728976, lng: 19.001617 },
    { lat: 48.728435, lng: 19.00222 },
    { lat: 48.728185, lng: 19.002918 },
    { lat: 48.727889, lng: 19.003354 },
    { lat: 48.727692, lng: 19.00356 },
    { lat: 48.727174, lng: 19.004128 },
    { lat: 48.7268121, lng: 19.0040557 },
    { lat: 48.726508, lng: 19.003995 },
    { lat: 48.726282, lng: 19.003967 },
    { lat: 48.726065, lng: 19.003866 },
    { lat: 48.725842, lng: 19.003932 },
    { lat: 48.725245, lng: 19.003813 },
    { lat: 48.725232, lng: 19.003811 },
    { lat: 48.724624, lng: 19.004019 },
    { lat: 48.724371, lng: 19.003706 },
    { lat: 48.724222, lng: 19.003719 },
    { lat: 48.723983, lng: 19.003655 },
    { lat: 48.72377, lng: 19.00351 },
    { lat: 48.723567, lng: 19.003299 },
    { lat: 48.723093, lng: 19.002954 },
    { lat: 48.723019, lng: 19.003044 },
    { lat: 48.722907, lng: 19.00328 },
    { lat: 48.722587, lng: 19.003499 },
    { lat: 48.722168, lng: 19.003971 },
    { lat: 48.72206, lng: 19.004092 },
    { lat: 48.721586, lng: 19.004694 },
    { lat: 48.721165, lng: 19.004961 },
    { lat: 48.720955, lng: 19.005089 },
    { lat: 48.720585, lng: 19.005068 },
    { lat: 48.720465, lng: 19.00515 },
    { lat: 48.719879, lng: 19.005403 },
    { lat: 48.719502, lng: 19.005223 },
    { lat: 48.719075, lng: 19.00507 },
    { lat: 48.719011, lng: 19.005104 },
    { lat: 48.718377, lng: 19.005442 },
    { lat: 48.71819, lng: 19.00545 },
    { lat: 48.717347, lng: 19.005535 },
    { lat: 48.716592, lng: 19.005534 },
    { lat: 48.716225, lng: 19.005494 },
    { lat: 48.715906, lng: 19.005202 },
    { lat: 48.715394, lng: 19.004918 },
    { lat: 48.71496, lng: 19.005485 },
    { lat: 48.714636, lng: 19.005852 },
    { lat: 48.714082, lng: 19.006073 },
    { lat: 48.713736, lng: 19.006334 },
    { lat: 48.713317, lng: 19.006522 },
    { lat: 48.712897, lng: 19.006628 },
    { lat: 48.712359, lng: 19.006621 },
    { lat: 48.712329, lng: 19.006621 },
    { lat: 48.711846, lng: 19.006446 },
    { lat: 48.711355, lng: 19.005915 },
    { lat: 48.710593, lng: 19.00524 },
    { lat: 48.710194, lng: 19.004825 },
    { lat: 48.709699, lng: 19.004492 },
    { lat: 48.70927, lng: 19.004027 },
    { lat: 48.709104, lng: 19.003872 },
    { lat: 48.708726, lng: 19.003726 },
    { lat: 48.70843, lng: 19.003696 },
    { lat: 48.707804, lng: 19.00418 },
    { lat: 48.707479, lng: 19.005365 },
    { lat: 48.707214, lng: 19.006437 },
    { lat: 48.707168, lng: 19.00653 },
    { lat: 48.706729, lng: 19.007418 },
    { lat: 48.7067974, lng: 19.007937 },
    { lat: 48.7068987, lng: 19.008202 },
    { lat: 48.7082047, lng: 19.0105578 },
    { lat: 48.7087737, lng: 19.0110181 },
    { lat: 48.7092926, lng: 19.0115349 },
    { lat: 48.7096261, lng: 19.0121384 },
    { lat: 48.7097108, lng: 19.0124967 },
    { lat: 48.7097339, lng: 19.0129549 },
    { lat: 48.7098415, lng: 19.013388 },
    { lat: 48.7111973, lng: 19.015642 },
    { lat: 48.7120394, lng: 19.0173361 },
    { lat: 48.7124609, lng: 19.0182323 },
    { lat: 48.7126429, lng: 19.0190685 },
    { lat: 48.7130244, lng: 19.0198839 },
    { lat: 48.7132182, lng: 19.0204147 },
    { lat: 48.7134338, lng: 19.0200618 },
    { lat: 48.7136991, lng: 19.020117 },
    { lat: 48.7143887, lng: 19.0204468 },
    { lat: 48.7145367, lng: 19.0206965 },
    { lat: 48.7146665, lng: 19.0210553 },
    { lat: 48.7149378, lng: 19.0212966 },
    { lat: 48.7151728, lng: 19.0216184 },
    { lat: 48.7152966, lng: 19.0220243 },
    { lat: 48.7154387, lng: 19.0224903 },
    { lat: 48.7154704, lng: 19.0227812 },
    { lat: 48.7157655, lng: 19.0230026 },
    { lat: 48.7166724, lng: 19.0233014 },
    { lat: 48.717357, lng: 19.0239997 },
    { lat: 48.7180824, lng: 19.0251113 },
    { lat: 48.7184043, lng: 19.025837 },
    { lat: 48.7184801, lng: 19.0261792 },
    { lat: 48.7182582, lng: 19.0262951 },
    { lat: 48.7183113, lng: 19.0264532 },
    { lat: 48.717975, lng: 19.0268967 },
    { lat: 48.7188474, lng: 19.0295365 },
    { lat: 48.7191655, lng: 19.0296331 },
    { lat: 48.7191748, lng: 19.0298845 },
    { lat: 48.7193721, lng: 19.0302174 },
    { lat: 48.7195346, lng: 19.0308511 },
    { lat: 48.719643, lng: 19.031034 },
    { lat: 48.7198794, lng: 19.0317249 },
    { lat: 48.7197282, lng: 19.0319211 },
    { lat: 48.7197215, lng: 19.0321461 },
    { lat: 48.7202745, lng: 19.0339543 },
    { lat: 48.7199945, lng: 19.0341467 },
    { lat: 48.719875, lng: 19.0346657 },
    { lat: 48.7197089, lng: 19.0347029 },
    { lat: 48.7196429, lng: 19.034804 },
    { lat: 48.7196936, lng: 19.0349821 },
    { lat: 48.719478, lng: 19.0354253 },
    { lat: 48.7193858, lng: 19.0354284 },
    { lat: 48.7193069, lng: 19.0355659 },
    { lat: 48.7191387, lng: 19.0355367 },
    { lat: 48.7189873, lng: 19.0360043 },
    { lat: 48.7184974, lng: 19.037027 },
    { lat: 48.7182631, lng: 19.0373202 },
    { lat: 48.7179086, lng: 19.037986 },
    { lat: 48.7176865, lng: 19.0382996 },
    { lat: 48.7176076, lng: 19.0387467 },
    { lat: 48.7179406, lng: 19.0392498 },
    { lat: 48.7178263, lng: 19.0394 },
    { lat: 48.7178089, lng: 19.0396615 },
    { lat: 48.7179222, lng: 19.0398713 },
    { lat: 48.7177274, lng: 19.0407305 },
    { lat: 48.7176962, lng: 19.0413886 },
    { lat: 48.7175366, lng: 19.0421648 },
    { lat: 48.7175289, lng: 19.0424824 },
    { lat: 48.7173806, lng: 19.0428982 },
    { lat: 48.7168353, lng: 19.0439378 },
    { lat: 48.7168911, lng: 19.0442635 },
    { lat: 48.7167884, lng: 19.0445566 },
    { lat: 48.716739, lng: 19.0450992 },
    { lat: 48.7162989, lng: 19.0449814 },
    { lat: 48.7154151, lng: 19.045722 },
    { lat: 48.7151924, lng: 19.0457089 },
    { lat: 48.714923, lng: 19.0455566 },
    { lat: 48.7145981, lng: 19.0455749 },
    { lat: 48.7142812, lng: 19.045704 },
    { lat: 48.7139236, lng: 19.0459516 },
    { lat: 48.7136175, lng: 19.0463869 },
    { lat: 48.7130618, lng: 19.0475825 },
    { lat: 48.7129089, lng: 19.0481294 },
    { lat: 48.7127982, lng: 19.0483527 },
    { lat: 48.712425, lng: 19.0488674 },
    { lat: 48.7120125, lng: 19.0495943 },
    { lat: 48.7117754, lng: 19.0498777 },
    { lat: 48.7114615, lng: 19.050115 },
    { lat: 48.7110646, lng: 19.0509461 },
    { lat: 48.7109053, lng: 19.0511178 },
    { lat: 48.7104967, lng: 19.051243 },
    { lat: 48.7103687, lng: 19.0515454 },
    { lat: 48.7103141, lng: 19.0522817 },
    { lat: 48.7096918, lng: 19.0531171 },
    { lat: 48.7095118, lng: 19.0535043 },
    { lat: 48.7094099, lng: 19.0541713 },
    { lat: 48.7094253, lng: 19.0554718 },
    { lat: 48.7091948, lng: 19.0555945 },
    { lat: 48.7092158, lng: 19.0563796 },
    { lat: 48.7093606, lng: 19.0571298 },
    { lat: 48.7094021, lng: 19.0575425 },
    { lat: 48.7094457, lng: 19.0579761 },
    { lat: 48.7096822, lng: 19.0594822 },
    { lat: 48.7096088, lng: 19.0609446 },
    { lat: 48.7092784, lng: 19.0622938 },
    { lat: 48.7090277, lng: 19.0628261 },
    { lat: 48.7090092, lng: 19.0633176 },
    { lat: 48.7093511, lng: 19.0637708 },
    { lat: 48.7094393, lng: 19.0640716 },
    { lat: 48.7115356, lng: 19.0635397 },
    { lat: 48.7138883, lng: 19.0624081 },
    { lat: 48.7148121, lng: 19.062349 },
    { lat: 48.715114, lng: 19.0616452 },
    { lat: 48.7158583, lng: 19.0611982 },
    { lat: 48.7178687, lng: 19.0619266 },
    { lat: 48.7199078, lng: 19.0629153 },
    { lat: 48.7210375, lng: 19.0640221 },
    { lat: 48.7222909, lng: 19.0648727 },
    { lat: 48.7223699, lng: 19.0648319 },
    { lat: 48.7229536, lng: 19.0654352 },
    { lat: 48.7228878, lng: 19.0659922 },
    { lat: 48.7230337, lng: 19.0660227 },
    { lat: 48.7227439, lng: 19.0680546 },
    { lat: 48.7226548, lng: 19.0690706 },
    { lat: 48.7227056, lng: 19.0698135 },
    { lat: 48.7226725, lng: 19.0701686 },
    { lat: 48.7228936, lng: 19.0720016 },
    { lat: 48.7225169, lng: 19.0732871 },
    { lat: 48.7225334, lng: 19.0733712 },
    { lat: 48.7222945, lng: 19.074526 },
    { lat: 48.7216109, lng: 19.0741776 },
    { lat: 48.7213537, lng: 19.0750784 },
    { lat: 48.7213357, lng: 19.0755982 },
    { lat: 48.721417, lng: 19.0756475 },
    { lat: 48.721655, lng: 19.0760321 },
    { lat: 48.7216042, lng: 19.0767116 },
    { lat: 48.7213737, lng: 19.0771763 },
    { lat: 48.7214043, lng: 19.077407 },
    { lat: 48.7216625, lng: 19.0786712 },
    { lat: 48.7220864, lng: 19.079963 },
    { lat: 48.7220642, lng: 19.0801726 },
    { lat: 48.7215335, lng: 19.0812423 },
    { lat: 48.7213318, lng: 19.0818548 },
    { lat: 48.7209767, lng: 19.082309 },
    { lat: 48.7207077, lng: 19.0825245 },
    { lat: 48.7200685, lng: 19.0825022 },
    { lat: 48.7191884, lng: 19.0826416 },
    { lat: 48.7188062, lng: 19.082528 },
    { lat: 48.7182188, lng: 19.0826412 },
    { lat: 48.7179326, lng: 19.0828284 },
    { lat: 48.7176937, lng: 19.0831212 },
    { lat: 48.7174902, lng: 19.0835417 },
    { lat: 48.7174505, lng: 19.0840813 },
    { lat: 48.7172826, lng: 19.0850322 },
    { lat: 48.7172405, lng: 19.0855244 },
    { lat: 48.7173369, lng: 19.0857626 },
    { lat: 48.7176917, lng: 19.08616 },
    { lat: 48.7177016, lng: 19.0869046 },
    { lat: 48.7180418, lng: 19.0876932 },
    { lat: 48.7181684, lng: 19.0877695 },
    { lat: 48.7184828, lng: 19.0888998 },
    { lat: 48.7184947, lng: 19.090474 },
    { lat: 48.7182387, lng: 19.0912214 },
    { lat: 48.7173448, lng: 19.0924228 },
    { lat: 48.7171836, lng: 19.0931473 },
    { lat: 48.7171261, lng: 19.0936926 },
    { lat: 48.7171163, lng: 19.0941332 },
    { lat: 48.7172398, lng: 19.0944826 },
    { lat: 48.7173514, lng: 19.0950754 },
    { lat: 48.71733, lng: 19.0963898 },
    { lat: 48.7170675, lng: 19.0994139 },
    { lat: 48.7171183, lng: 19.1002549 },
    { lat: 48.7172969, lng: 19.1012411 },
    { lat: 48.7173271, lng: 19.1017578 },
    { lat: 48.7171401, lng: 19.1031032 },
    { lat: 48.717213, lng: 19.1040239 },
    { lat: 48.7169487, lng: 19.1041066 },
    { lat: 48.7167469, lng: 19.1046714 },
    { lat: 48.7159928, lng: 19.1070195 },
    { lat: 48.7152642, lng: 19.1099434 },
    { lat: 48.7151186, lng: 19.1101469 },
    { lat: 48.7151307, lng: 19.1102284 },
    { lat: 48.715066, lng: 19.110382 },
    { lat: 48.7151689, lng: 19.1105698 },
    { lat: 48.7152424, lng: 19.1108295 },
    { lat: 48.7152587, lng: 19.1112067 },
    { lat: 48.7152913, lng: 19.1116519 },
    { lat: 48.7153933, lng: 19.1119611 },
    { lat: 48.7154545, lng: 19.112227 },
    { lat: 48.7155361, lng: 19.1125362 },
    { lat: 48.7156749, lng: 19.1130989 },
    { lat: 48.7157687, lng: 19.113507 },
    { lat: 48.7157932, lng: 19.1143356 },
    { lat: 48.715887, lng: 19.1146695 },
    { lat: 48.7160339, lng: 19.1149664 },
    { lat: 48.7160584, lng: 19.1150529 },
    { lat: 48.7160269, lng: 19.1153083 },
    { lat: 48.7159865, lng: 19.1155619 },
    { lat: 48.7159548, lng: 19.1159947 },
    { lat: 48.7160558, lng: 19.116432 },
    { lat: 48.716125, lng: 19.1165544 },
    { lat: 48.7163789, lng: 19.116913 },
    { lat: 48.7164799, lng: 19.1170966 },
    { lat: 48.7167597, lng: 19.1174945 },
    { lat: 48.717002, lng: 19.1176432 },
    { lat: 48.7171174, lng: 19.1178574 },
    { lat: 48.7171751, lng: 19.1180236 },
    { lat: 48.7171549, lng: 19.1182335 },
    { lat: 48.7170741, lng: 19.1185221 },
    { lat: 48.7171116, lng: 19.1189812 },
    { lat: 48.7172559, lng: 19.1192435 },
    { lat: 48.7172588, lng: 19.1196152 },
    { lat: 48.7173482, lng: 19.1198907 },
    { lat: 48.7176223, lng: 19.1201355 },
    { lat: 48.7179252, lng: 19.1202142 },
    { lat: 48.718156, lng: 19.120306 },
    { lat: 48.7182598, lng: 19.1205028 },
    { lat: 48.7183002, lng: 19.120752 },
    { lat: 48.7183954, lng: 19.121045 },
    { lat: 48.7185166, lng: 19.1213336 },
    { lat: 48.7186233, lng: 19.1215828 },
    { lat: 48.7188137, lng: 19.121832 },
    { lat: 48.7188772, lng: 19.1219851 },
    { lat: 48.7189752, lng: 19.1221993 },
    { lat: 48.7190618, lng: 19.1222868 },
    { lat: 48.7191858, lng: 19.1224136 },
    { lat: 48.7193214, lng: 19.1226278 },
    { lat: 48.7191945, lng: 19.1228246 },
    { lat: 48.7191397, lng: 19.1230826 },
    { lat: 48.7193156, lng: 19.1235636 },
    { lat: 48.7194051, lng: 19.1245255 },
    { lat: 48.7193878, lng: 19.1249365 },
    { lat: 48.7194858, lng: 19.1254831 },
    { lat: 48.719708, lng: 19.1257848 },
    { lat: 48.7199157, lng: 19.1260952 },
    { lat: 48.7200138, lng: 19.1265168 },
    { lat: 48.7201003, lng: 19.1267442 },
    { lat: 48.7202618, lng: 19.1273126 },
    { lat: 48.7203426, lng: 19.1278286 },
    { lat: 48.7205739, lng: 19.128389 },
    { lat: 48.7207383, lng: 19.1286076 },
    { lat: 48.7208393, lng: 19.1287607 },
    { lat: 48.720972, lng: 19.1288656 },
    { lat: 48.7210787, lng: 19.1291279 },
    { lat: 48.721145, lng: 19.129469 },
    { lat: 48.721197, lng: 19.1296833 },
    { lat: 48.721202, lng: 19.1297914 },
    { lat: 48.7212167, lng: 19.1301105 },
    { lat: 48.7212008, lng: 19.1303357 },
    { lat: 48.7211705, lng: 19.1304996 },
    { lat: 48.7210446, lng: 19.1308702 },
    { lat: 48.7208427, lng: 19.1312134 },
    { lat: 48.720704, lng: 19.1313958 },
    { lat: 48.7204531, lng: 19.1314391 },
    { lat: 48.7203021, lng: 19.1314669 },
    { lat: 48.7201247, lng: 19.1314793 },
    { lat: 48.7201226, lng: 19.1317143 },
    { lat: 48.7201255, lng: 19.1321909 },
    { lat: 48.7201515, lng: 19.1325145 },
    { lat: 48.7201601, lng: 19.1328293 },
    { lat: 48.7201774, lng: 19.1331048 },
    { lat: 48.7201976, lng: 19.1334283 },
    { lat: 48.7202207, lng: 19.1337956 },
    { lat: 48.720238, lng: 19.1341192 },
    { lat: 48.7202611, lng: 19.1345652 },
    { lat: 48.7201313, lng: 19.1345783 },
    { lat: 48.7200303, lng: 19.1345914 },
    { lat: 48.7198428, lng: 19.134622 },
    { lat: 48.7198659, lng: 19.1351423 },
    { lat: 48.7198861, lng: 19.1355971 },
    { lat: 48.7199236, lng: 19.1362661 },
    { lat: 48.7199813, lng: 19.1374029 },
    { lat: 48.7200103, lng: 19.1383056 },
    { lat: 48.7201949, lng: 19.1382649 },
    { lat: 48.7208366, lng: 19.1381556 },
    { lat: 48.7213628, lng: 19.1380245 },
    { lat: 48.7216945, lng: 19.1379644 },
    { lat: 48.7220874, lng: 19.1379097 },
    { lat: 48.7225668, lng: 19.1378933 },
    { lat: 48.7229777, lng: 19.1378605 },
    { lat: 48.7236049, lng: 19.1377841 },
    { lat: 48.7243293, lng: 19.1375928 },
    { lat: 48.724852, lng: 19.1375382 },
    { lat: 48.7252953, lng: 19.1375054 },
    { lat: 48.7256968, lng: 19.1374494 },
  ],
  Podlavice: [
    { lat: 48.7493765, lng: 19.1210477 },
    { lat: 48.749404, lng: 19.1207581 },
    { lat: 48.7497074, lng: 19.1196886 },
    { lat: 48.7499446, lng: 19.119336 },
    { lat: 48.7499617, lng: 19.1191075 },
    { lat: 48.750628, lng: 19.1178551 },
    { lat: 48.7518978, lng: 19.1166583 },
    { lat: 48.7522171, lng: 19.1167994 },
    { lat: 48.7523655, lng: 19.1166575 },
    { lat: 48.7526701, lng: 19.1155888 },
    { lat: 48.7532038, lng: 19.115009 },
    { lat: 48.7533758, lng: 19.1150181 },
    { lat: 48.7537942, lng: 19.1148428 },
    { lat: 48.7539316, lng: 19.1149991 },
    { lat: 48.7548569, lng: 19.1148548 },
    { lat: 48.7553854, lng: 19.1148753 },
    { lat: 48.7557896, lng: 19.1150711 },
    { lat: 48.7560513, lng: 19.1150276 },
    { lat: 48.7560646, lng: 19.1153383 },
    { lat: 48.7561871, lng: 19.1154949 },
    { lat: 48.7565013, lng: 19.1156001 },
    { lat: 48.7569234, lng: 19.115995 },
    { lat: 48.7570961, lng: 19.1160251 },
    { lat: 48.7572281, lng: 19.1163003 },
    { lat: 48.7574454, lng: 19.1164529 },
    { lat: 48.7585579, lng: 19.1165221 },
    { lat: 48.7586557, lng: 19.116331 },
    { lat: 48.7588351, lng: 19.1163379 },
    { lat: 48.759174, lng: 19.1165067 },
    { lat: 48.7600748, lng: 19.1165331 },
    { lat: 48.7602234, lng: 19.116642 },
    { lat: 48.7607539, lng: 19.1166324 },
    { lat: 48.7603397, lng: 19.1168976 },
    { lat: 48.7608464, lng: 19.1171361 },
    { lat: 48.7611484, lng: 19.1165892 },
    { lat: 48.7613944, lng: 19.1163184 },
    { lat: 48.7614937, lng: 19.115819 },
    { lat: 48.7618096, lng: 19.115323 },
    { lat: 48.7620425, lng: 19.1151552 },
    { lat: 48.7623141, lng: 19.1150837 },
    { lat: 48.762808, lng: 19.1144039 },
    { lat: 48.7626584, lng: 19.1140446 },
    { lat: 48.7628483, lng: 19.1139877 },
    { lat: 48.7629101, lng: 19.1137077 },
    { lat: 48.7630487, lng: 19.1137317 },
    { lat: 48.7633088, lng: 19.1136107 },
    { lat: 48.7637369, lng: 19.1131981 },
    { lat: 48.763995, lng: 19.113195 },
    { lat: 48.7646583, lng: 19.1125221 },
    { lat: 48.7649711, lng: 19.1124832 },
    { lat: 48.7652734, lng: 19.1124456 },
    { lat: 48.7660932, lng: 19.1116977 },
    { lat: 48.7663851, lng: 19.1116046 },
    { lat: 48.7667605, lng: 19.1111293 },
    { lat: 48.767046, lng: 19.1108829 },
    { lat: 48.7672344, lng: 19.1105192 },
    { lat: 48.7678715, lng: 19.1096366 },
    { lat: 48.7681027, lng: 19.1095242 },
    { lat: 48.7685771, lng: 19.1090641 },
    { lat: 48.7693988, lng: 19.1085414 },
    { lat: 48.769954, lng: 19.1087569 },
    { lat: 48.7702707, lng: 19.1087074 },
    { lat: 48.7705671, lng: 19.1083433 },
    { lat: 48.7710493, lng: 19.1074234 },
    { lat: 48.7715398, lng: 19.1070404 },
    { lat: 48.7720975, lng: 19.1069189 },
    { lat: 48.7735311, lng: 19.1050737 },
    { lat: 48.7742484, lng: 19.1032829 },
    { lat: 48.7745296, lng: 19.1026984 },
    { lat: 48.7746921, lng: 19.10225 },
    { lat: 48.7748537, lng: 19.1015086 },
    { lat: 48.7755443, lng: 19.0996806 },
    { lat: 48.7753287, lng: 19.099491 },
    { lat: 48.7748445, lng: 19.0987733 },
    { lat: 48.7743449, lng: 19.0989504 },
    { lat: 48.7743079, lng: 19.0993667 },
    { lat: 48.7740026, lng: 19.0994832 },
    { lat: 48.7737859, lng: 19.0992308 },
    { lat: 48.7731658, lng: 19.0988634 },
    { lat: 48.7734813, lng: 19.0978383 },
    { lat: 48.7731326, lng: 19.0978997 },
    { lat: 48.7729407, lng: 19.097736 },
    { lat: 48.772805, lng: 19.097182 },
    { lat: 48.7726486, lng: 19.0969382 },
    { lat: 48.7724484, lng: 19.0958133 },
    { lat: 48.7718506, lng: 19.0957393 },
    { lat: 48.7702868, lng: 19.0935089 },
    { lat: 48.7680381, lng: 19.0900885 },
    { lat: 48.7673037, lng: 19.0865386 },
    { lat: 48.7672223, lng: 19.0851495 },
    { lat: 48.7673268, lng: 19.0842156 },
    { lat: 48.7656357, lng: 19.0845063 },
    { lat: 48.7650212, lng: 19.0843686 },
    { lat: 48.7648764, lng: 19.0842925 },
    { lat: 48.7645007, lng: 19.0836067 },
    { lat: 48.764278, lng: 19.083324 },
    { lat: 48.7640289, lng: 19.0832551 },
    { lat: 48.7631805, lng: 19.0832631 },
    { lat: 48.7624232, lng: 19.0837002 },
    { lat: 48.7620402, lng: 19.084045 },
    { lat: 48.7615992, lng: 19.0845651 },
    { lat: 48.7612318, lng: 19.0851104 },
    { lat: 48.7610074, lng: 19.0857644 },
    { lat: 48.760317, lng: 19.0868213 },
    { lat: 48.7598771, lng: 19.0875864 },
    { lat: 48.7596019, lng: 19.0882001 },
    { lat: 48.7589224, lng: 19.0890195 },
    { lat: 48.7584617, lng: 19.089005 },
    { lat: 48.7581151, lng: 19.0893364 },
    { lat: 48.7578527, lng: 19.0898832 },
    { lat: 48.7575074, lng: 19.0903998 },
    { lat: 48.757252, lng: 19.0906361 },
    { lat: 48.7558805, lng: 19.0913497 },
    { lat: 48.7555277, lng: 19.0914634 },
    { lat: 48.75419, lng: 19.0928337 },
    { lat: 48.7537789, lng: 19.0927529 },
    { lat: 48.753671, lng: 19.092602 },
    { lat: 48.7531282, lng: 19.0918434 },
    { lat: 48.7529491, lng: 19.0915931 },
    { lat: 48.7525819, lng: 19.0915349 },
    { lat: 48.7523628, lng: 19.0915001 },
    { lat: 48.7515044, lng: 19.0911968 },
    { lat: 48.7506223, lng: 19.0912302 },
    { lat: 48.7503138, lng: 19.0913303 },
    { lat: 48.7477856, lng: 19.0896803 },
    { lat: 48.7467021, lng: 19.0892501 },
    { lat: 48.7457501, lng: 19.0907336 },
    { lat: 48.7454054, lng: 19.0904662 },
    { lat: 48.7451161, lng: 19.0907956 },
    { lat: 48.7451268, lng: 19.0908269 },
    { lat: 48.7452215, lng: 19.0911244 },
    { lat: 48.7449518, lng: 19.0913872 },
    { lat: 48.7448568, lng: 19.0916116 },
    { lat: 48.7445655, lng: 19.0929185 },
    { lat: 48.7441695, lng: 19.0940476 },
    { lat: 48.7438756, lng: 19.0945514 },
    { lat: 48.7427918, lng: 19.0956986 },
    { lat: 48.7427231, lng: 19.0957714 },
    { lat: 48.7426997, lng: 19.095952 },
    { lat: 48.7426458, lng: 19.0963092 },
    { lat: 48.7426256, lng: 19.0967123 },
    { lat: 48.7425449, lng: 19.0972328 },
    { lat: 48.7424473, lng: 19.0977838 },
    { lat: 48.7423598, lng: 19.0982992 },
    { lat: 48.7422589, lng: 19.099136 },
    { lat: 48.7422084, lng: 19.0994727 },
    { lat: 48.7421108, lng: 19.100085 },
    { lat: 48.7420233, lng: 19.10058 },
    { lat: 48.7418551, lng: 19.1011412 },
    { lat: 48.7415825, lng: 19.1017586 },
    { lat: 48.7413605, lng: 19.1022485 },
    { lat: 48.7411081, lng: 19.1027332 },
    { lat: 48.7409634, lng: 19.1030853 },
    { lat: 48.7408389, lng: 19.1033608 },
    { lat: 48.7406865, lng: 19.1036166 },
    { lat: 48.7405402, lng: 19.1040135 },
    { lat: 48.740449, lng: 19.1044595 },
    { lat: 48.7404395, lng: 19.1046038 },
    { lat: 48.7404205, lng: 19.1050259 },
    { lat: 48.74038, lng: 19.1053687 },
    { lat: 48.740361, lng: 19.1056429 },
    { lat: 48.7403824, lng: 19.1062959 },
    { lat: 48.7403491, lng: 19.1068047 },
    { lat: 48.7405751, lng: 19.1080855 },
    { lat: 48.7406061, lng: 19.1083453 },
    { lat: 48.7406727, lng: 19.1085437 },
    { lat: 48.7407964, lng: 19.1087241 },
    { lat: 48.7409059, lng: 19.1087746 },
    { lat: 48.7410153, lng: 19.1087602 },
    { lat: 48.7409213, lng: 19.1091488 },
    { lat: 48.7408615, lng: 19.1093937 },
    { lat: 48.7408321, lng: 19.1095363 },
    { lat: 48.7407988, lng: 19.1097491 },
    { lat: 48.7407768, lng: 19.1099295 },
    { lat: 48.7407667, lng: 19.1102076 },
    { lat: 48.740792, lng: 19.1104908 },
    { lat: 48.7408105, lng: 19.1107893 },
    { lat: 48.7408071, lng: 19.110899 },
    { lat: 48.7407566, lng: 19.111223 },
    { lat: 48.740691, lng: 19.1116006 },
    { lat: 48.7406574, lng: 19.1117128 },
    { lat: 48.740516, lng: 19.1120521 },
    { lat: 48.7403629, lng: 19.1124195 },
    { lat: 48.7401796, lng: 19.1127563 },
    { lat: 48.7401022, lng: 19.1128711 },
    { lat: 48.7400349, lng: 19.1130038 },
    { lat: 48.7399373, lng: 19.1133966 },
    { lat: 48.7398582, lng: 19.1138252 },
    { lat: 48.7398693, lng: 19.1141292 },
    { lat: 48.7398543, lng: 19.114352 },
    { lat: 48.7398226, lng: 19.1147567 },
    { lat: 48.7397406, lng: 19.1151278 },
    { lat: 48.7395532, lng: 19.1153065 },
    { lat: 48.7394211, lng: 19.1152786 },
    { lat: 48.7393841, lng: 19.1153753 },
    { lat: 48.7393719, lng: 19.1154445 },
    { lat: 48.7395091, lng: 19.1157569 },
    { lat: 48.7394808, lng: 19.1160315 },
    { lat: 48.7393434, lng: 19.1164671 },
    { lat: 48.7393144, lng: 19.1170639 },
    { lat: 48.7390588, lng: 19.1175225 },
    { lat: 48.7388833, lng: 19.1177134 },
    { lat: 48.738913, lng: 19.1178615 },
    { lat: 48.7390408, lng: 19.117956 },
    { lat: 48.739056, lng: 19.1182207 },
    { lat: 48.7389831, lng: 19.1183172 },
    { lat: 48.7387621, lng: 19.1183875 },
    { lat: 48.7385919, lng: 19.1188854 },
    { lat: 48.7384841, lng: 19.119009 },
    { lat: 48.7382889, lng: 19.119031 },
    { lat: 48.7380333, lng: 19.119586 },
    { lat: 48.7376709, lng: 19.1200295 },
    { lat: 48.7374557, lng: 19.1208988 },
    { lat: 48.7372704, lng: 19.1210015 },
    { lat: 48.737165, lng: 19.1211495 },
    { lat: 48.7370881, lng: 19.1216236 },
    { lat: 48.7367961, lng: 19.1220587 },
    { lat: 48.736705, lng: 19.122519 },
    { lat: 48.7367023, lng: 19.1230096 },
    { lat: 48.7365448, lng: 19.1233838 },
    { lat: 48.7364788, lng: 19.1234634 },
    { lat: 48.7364185, lng: 19.123451 },
    { lat: 48.7363849, lng: 19.123679 },
    { lat: 48.7363547, lng: 19.1238339 },
    { lat: 48.7363277, lng: 19.1240456 },
    { lat: 48.736284, lng: 19.12426 },
    { lat: 48.7362571, lng: 19.1244232 },
    { lat: 48.7361948, lng: 19.1246452 },
    { lat: 48.7361393, lng: 19.1250534 },
    { lat: 48.7361174, lng: 19.1253697 },
    { lat: 48.736072, lng: 19.1255866 },
    { lat: 48.7361225, lng: 19.1257065 },
    { lat: 48.7362554, lng: 19.1257932 },
    { lat: 48.7363614, lng: 19.1258774 },
    { lat: 48.7365801, lng: 19.1260382 },
    { lat: 48.736883, lng: 19.126403 },
    { lat: 48.7370731, lng: 19.1261325 },
    { lat: 48.7372481, lng: 19.1257499 },
    { lat: 48.7374256, lng: 19.1253472 },
    { lat: 48.7375088, lng: 19.1251163 },
    { lat: 48.7375826, lng: 19.1249395 },
    { lat: 48.7376849, lng: 19.1246905 },
    { lat: 48.7378015, lng: 19.1243802 },
    { lat: 48.7379609, lng: 19.1240267 },
    { lat: 48.7381108, lng: 19.1236586 },
    { lat: 48.7382988, lng: 19.1233087 },
    { lat: 48.7385487, lng: 19.1228288 },
    { lat: 48.7390736, lng: 19.1226247 },
    { lat: 48.739363, lng: 19.1230482 },
    { lat: 48.7395582, lng: 19.1229819 },
    { lat: 48.7393024, lng: 19.1236248 },
    { lat: 48.7391847, lng: 19.123936 },
    { lat: 48.7390534, lng: 19.1242779 },
    { lat: 48.7389255, lng: 19.124431 },
    { lat: 48.7390198, lng: 19.124681 },
    { lat: 48.7392721, lng: 19.1249718 },
    { lat: 48.7395212, lng: 19.1251963 },
    { lat: 48.7398072, lng: 19.1254719 },
    { lat: 48.7400764, lng: 19.1257372 },
    { lat: 48.7404802, lng: 19.1261709 },
    { lat: 48.7402177, lng: 19.1267577 },
    { lat: 48.7400663, lng: 19.126972 },
    { lat: 48.7398879, lng: 19.1273088 },
    { lat: 48.739713, lng: 19.1276455 },
    { lat: 48.7401201, lng: 19.1280945 },
    { lat: 48.7398913, lng: 19.1286966 },
    { lat: 48.7398375, lng: 19.131304 },
    { lat: 48.7404398, lng: 19.1302529 },
    { lat: 48.7416882, lng: 19.1318193 },
    { lat: 48.7413752, lng: 19.1325898 },
    { lat: 48.7410219, lng: 19.1333092 },
    { lat: 48.7411834, lng: 19.133549 },
    { lat: 48.7413833, lng: 19.1331454 },
    { lat: 48.7414878, lng: 19.1328845 },
    { lat: 48.7416013, lng: 19.132764 },
    { lat: 48.7416622, lng: 19.1327154 },
    { lat: 48.7417219, lng: 19.1326753 },
    { lat: 48.7417653, lng: 19.1326617 },
    { lat: 48.7419003, lng: 19.1325547 },
    { lat: 48.7419533, lng: 19.1325662 },
    { lat: 48.7419966, lng: 19.1325438 },
    { lat: 48.7420398, lng: 19.1324752 },
    { lat: 48.7421621, lng: 19.1323146 },
    { lat: 48.7423228, lng: 19.1321726 },
    { lat: 48.7424289, lng: 19.1320527 },
    { lat: 48.7425976, lng: 19.1317401 },
    { lat: 48.7427393, lng: 19.1314574 },
    { lat: 48.7428423, lng: 19.131274 },
    { lat: 48.7429484, lng: 19.131123 },
    { lat: 48.7439917, lng: 19.1317806 },
    { lat: 48.7441535, lng: 19.1310446 },
    { lat: 48.7443533, lng: 19.1299261 },
    { lat: 48.744558, lng: 19.129587 },
    { lat: 48.7447007, lng: 19.1293056 },
    { lat: 48.7448672, lng: 19.128685 },
    { lat: 48.7449243, lng: 19.1284108 },
    { lat: 48.7450956, lng: 19.1280283 },
    { lat: 48.7453193, lng: 19.1280933 },
    { lat: 48.745581, lng: 19.1257625 },
    { lat: 48.7468133, lng: 19.1256832 },
    { lat: 48.7468256, lng: 19.1251859 },
    { lat: 48.7469113, lng: 19.1248034 },
    { lat: 48.7472467, lng: 19.124973 },
    { lat: 48.7472174, lng: 19.1246877 },
    { lat: 48.7471956, lng: 19.1243407 },
    { lat: 48.7472006, lng: 19.1239555 },
    { lat: 48.7472427, lng: 19.1234937 },
    { lat: 48.7473083, lng: 19.1231085 },
    { lat: 48.7474311, lng: 19.1224758 },
    { lat: 48.7475034, lng: 19.1220395 },
    { lat: 48.7476582, lng: 19.1213762 },
    { lat: 48.7477262, lng: 19.1210474 },
    { lat: 48.7489611, lng: 19.1217666 },
    { lat: 48.7491654, lng: 19.1210877 },
    { lat: 48.7492509, lng: 19.1210141 },
    { lat: 48.7493627, lng: 19.1210157 },
    { lat: 48.7493765, lng: 19.1210477 },
  ],
  Skubín: [
    { lat: 48.7290258, lng: 19.097834 },
    { lat: 48.7293671, lng: 19.0994198 },
    { lat: 48.7289376, lng: 19.0996227 },
    { lat: 48.7289593, lng: 19.1005369 },
    { lat: 48.7290427, lng: 19.1010845 },
    { lat: 48.7290042, lng: 19.1014885 },
    { lat: 48.7303063, lng: 19.1009895 },
    { lat: 48.7309071, lng: 19.1012724 },
    { lat: 48.7312158, lng: 19.1014177 },
    { lat: 48.7314421, lng: 19.1018406 },
    { lat: 48.7316571, lng: 19.1020751 },
    { lat: 48.7318235, lng: 19.1022566 },
    { lat: 48.731729, lng: 19.1032383 },
    { lat: 48.731736, lng: 19.1042878 },
    { lat: 48.7317082, lng: 19.1051271 },
    { lat: 48.7316682, lng: 19.1063324 },
    { lat: 48.7317907, lng: 19.106596 },
    { lat: 48.732015, lng: 19.1068467 },
    { lat: 48.7325616, lng: 19.1072159 },
    { lat: 48.7352295, lng: 19.1090178 },
    { lat: 48.7360866, lng: 19.1097283 },
    { lat: 48.7355894, lng: 19.1109313 },
    { lat: 48.7378224, lng: 19.1133584 },
    { lat: 48.7379514, lng: 19.1134986 },
    { lat: 48.7380282, lng: 19.1135821 },
    { lat: 48.7384812, lng: 19.113757 },
    { lat: 48.7389769, lng: 19.1135916 },
    { lat: 48.739733, lng: 19.1141771 },
    { lat: 48.7398693, lng: 19.1141292 },
    { lat: 48.7398582, lng: 19.1138252 },
    { lat: 48.7399373, lng: 19.1133966 },
    { lat: 48.7400349, lng: 19.1130038 },
    { lat: 48.7401022, lng: 19.1128711 },
    { lat: 48.7401796, lng: 19.1127563 },
    { lat: 48.7403629, lng: 19.1124195 },
    { lat: 48.740516, lng: 19.1120521 },
    { lat: 48.7406574, lng: 19.1117128 },
    { lat: 48.740691, lng: 19.1116006 },
    { lat: 48.7407566, lng: 19.111223 },
    { lat: 48.7408071, lng: 19.110899 },
    { lat: 48.7408105, lng: 19.1107893 },
    { lat: 48.740792, lng: 19.1104908 },
    { lat: 48.7407667, lng: 19.1102076 },
    { lat: 48.7407768, lng: 19.1099295 },
    { lat: 48.7407988, lng: 19.1097491 },
    { lat: 48.7408321, lng: 19.1095363 },
    { lat: 48.7408615, lng: 19.1093937 },
    { lat: 48.7409213, lng: 19.1091488 },
    { lat: 48.7410153, lng: 19.1087602 },
    { lat: 48.7409059, lng: 19.1087746 },
    { lat: 48.7407964, lng: 19.1087241 },
    { lat: 48.7406727, lng: 19.1085437 },
    { lat: 48.7406061, lng: 19.1083453 },
    { lat: 48.7405751, lng: 19.1080855 },
    { lat: 48.7403491, lng: 19.1068047 },
    { lat: 48.7403824, lng: 19.1062959 },
    { lat: 48.740361, lng: 19.1056429 },
    { lat: 48.74038, lng: 19.1053687 },
    { lat: 48.7404205, lng: 19.1050259 },
    { lat: 48.7404395, lng: 19.1046038 },
    { lat: 48.740449, lng: 19.1044595 },
    { lat: 48.7405402, lng: 19.1040135 },
    { lat: 48.7406865, lng: 19.1036166 },
    { lat: 48.7408389, lng: 19.1033608 },
    { lat: 48.7409634, lng: 19.1030853 },
    { lat: 48.7411081, lng: 19.1027332 },
    { lat: 48.7413605, lng: 19.1022485 },
    { lat: 48.7415825, lng: 19.1017586 },
    { lat: 48.7418551, lng: 19.1011412 },
    { lat: 48.7420233, lng: 19.10058 },
    { lat: 48.7421108, lng: 19.100085 },
    { lat: 48.7422084, lng: 19.0994727 },
    { lat: 48.7422589, lng: 19.099136 },
    { lat: 48.7423598, lng: 19.0982992 },
    { lat: 48.7424473, lng: 19.0977838 },
    { lat: 48.7425449, lng: 19.0972328 },
    { lat: 48.7426256, lng: 19.0967123 },
    { lat: 48.7426458, lng: 19.0963092 },
    { lat: 48.7426997, lng: 19.095952 },
    { lat: 48.7427231, lng: 19.0957714 },
    { lat: 48.7426412, lng: 19.0949627 },
    { lat: 48.7426797, lng: 19.0944208 },
    { lat: 48.7428018, lng: 19.0938593 },
    { lat: 48.7430859, lng: 19.0932259 },
    { lat: 48.7430689, lng: 19.0920423 },
    { lat: 48.7429983, lng: 19.0918478 },
    { lat: 48.7429899, lng: 19.0916541 },
    { lat: 48.7431158, lng: 19.0913554 },
    { lat: 48.7431336, lng: 19.0908712 },
    { lat: 48.7432063, lng: 19.0907226 },
    { lat: 48.7433793, lng: 19.0906193 },
    { lat: 48.7434285, lng: 19.0905226 },
    { lat: 48.7434409, lng: 19.0898872 },
    { lat: 48.7434022, lng: 19.0894276 },
    { lat: 48.7435204, lng: 19.0889923 },
    { lat: 48.7436221, lng: 19.0888857 },
    { lat: 48.7435688, lng: 19.0887196 },
    { lat: 48.7431717, lng: 19.0884211 },
    { lat: 48.7429196, lng: 19.0876786 },
    { lat: 48.7429602, lng: 19.0858651 },
    { lat: 48.7428137, lng: 19.0853453 },
    { lat: 48.7427144, lng: 19.0847322 },
    { lat: 48.7427138, lng: 19.0846301 },
    { lat: 48.7429958, lng: 19.0841583 },
    { lat: 48.7429764, lng: 19.0837697 },
    { lat: 48.7431016, lng: 19.0833186 },
    { lat: 48.7432414, lng: 19.0831134 },
    { lat: 48.7432918, lng: 19.0825819 },
    { lat: 48.743418, lng: 19.0822421 },
    { lat: 48.7433938, lng: 19.0819402 },
    { lat: 48.7432165, lng: 19.081705 },
    { lat: 48.7429651, lng: 19.0808888 },
    { lat: 48.7429207, lng: 19.0809281 },
    { lat: 48.7426525, lng: 19.0807258 },
    { lat: 48.742451, lng: 19.0807784 },
    { lat: 48.742325, lng: 19.0806677 },
    { lat: 48.7422129, lng: 19.0806847 },
    { lat: 48.7419781, lng: 19.080585 },
    { lat: 48.7417738, lng: 19.0806233 },
    { lat: 48.7416638, lng: 19.0807737 },
    { lat: 48.7415634, lng: 19.0807261 },
    { lat: 48.7414798, lng: 19.0808357 },
    { lat: 48.7412131, lng: 19.0807789 },
    { lat: 48.7411993, lng: 19.0807004 },
    { lat: 48.74112, lng: 19.0806977 },
    { lat: 48.741054, lng: 19.0808424 },
    { lat: 48.7406931, lng: 19.0808824 },
    { lat: 48.7405202, lng: 19.0806259 },
    { lat: 48.7398704, lng: 19.0805292 },
    { lat: 48.7397144, lng: 19.0805162 },
    { lat: 48.7394546, lng: 19.0805318 },
    { lat: 48.7393313, lng: 19.0806127 },
    { lat: 48.7391822, lng: 19.0804946 },
    { lat: 48.7389964, lng: 19.080454 },
    { lat: 48.7389098, lng: 19.0803428 },
    { lat: 48.7387735, lng: 19.0795789 },
    { lat: 48.7384596, lng: 19.0790911 },
    { lat: 48.7381369, lng: 19.0788596 },
    { lat: 48.7377163, lng: 19.0787439 },
    { lat: 48.7375756, lng: 19.0783849 },
    { lat: 48.7375663, lng: 19.0777241 },
    { lat: 48.7368872, lng: 19.0763371 },
    { lat: 48.736573, lng: 19.0765457 },
    { lat: 48.7357536, lng: 19.0753958 },
    { lat: 48.734818, lng: 19.0745399 },
    { lat: 48.734513, lng: 19.0744118 },
    { lat: 48.7342056, lng: 19.0739697 },
    { lat: 48.7339489, lng: 19.0734089 },
    { lat: 48.7337492, lng: 19.0732928 },
    { lat: 48.7338625, lng: 19.0727642 },
    { lat: 48.7340339, lng: 19.0724921 },
    { lat: 48.7340683, lng: 19.0722296 },
    { lat: 48.7342602, lng: 19.0720142 },
    { lat: 48.7347589, lng: 19.0723602 },
    { lat: 48.7350731, lng: 19.0721473 },
    { lat: 48.7351769, lng: 19.0714468 },
    { lat: 48.7351339, lng: 19.0712439 },
    { lat: 48.7352124, lng: 19.0708039 },
    { lat: 48.735313, lng: 19.0706957 },
    { lat: 48.7354112, lng: 19.0705901 },
    { lat: 48.7354588, lng: 19.0704536 },
    { lat: 48.7355015, lng: 19.0699323 },
    { lat: 48.7348967, lng: 19.0701259 },
    { lat: 48.7346475, lng: 19.0697433 },
    { lat: 48.7347717, lng: 19.0693627 },
    { lat: 48.7349335, lng: 19.0693949 },
    { lat: 48.734964, lng: 19.0692597 },
    { lat: 48.7341645, lng: 19.0688512 },
    { lat: 48.734285, lng: 19.0685757 },
    { lat: 48.7342554, lng: 19.0684555 },
    { lat: 48.7339274, lng: 19.0685965 },
    { lat: 48.7338851, lng: 19.0684488 },
    { lat: 48.7334001, lng: 19.0687141 },
    { lat: 48.7331984, lng: 19.0689189 },
    { lat: 48.7331299, lng: 19.0689235 },
    { lat: 48.7330748, lng: 19.0690176 },
    { lat: 48.7331022, lng: 19.0691163 },
    { lat: 48.7327447, lng: 19.0691106 },
    { lat: 48.7326208, lng: 19.0685508 },
    { lat: 48.7326817, lng: 19.0683696 },
    { lat: 48.7320488, lng: 19.0685436 },
    { lat: 48.7317704, lng: 19.069016 },
    { lat: 48.7315416, lng: 19.0695385 },
    { lat: 48.7311454, lng: 19.0694148 },
    { lat: 48.7310884, lng: 19.068997 },
    { lat: 48.7314141, lng: 19.0676599 },
    { lat: 48.7316026, lng: 19.0672145 },
    { lat: 48.7315318, lng: 19.0664962 },
    { lat: 48.7307494, lng: 19.0665166 },
    { lat: 48.7304391, lng: 19.0667173 },
    { lat: 48.7302991, lng: 19.0667347 },
    { lat: 48.7296257, lng: 19.0655777 },
    { lat: 48.7287568, lng: 19.0654247 },
    { lat: 48.7284817, lng: 19.0661714 },
    { lat: 48.7283392, lng: 19.0673332 },
    { lat: 48.7262815, lng: 19.0677347 },
    { lat: 48.7262348, lng: 19.068291 },
    { lat: 48.7260166, lng: 19.0691589 },
    { lat: 48.726416, lng: 19.0715141 },
    { lat: 48.7266469, lng: 19.0715356 },
    { lat: 48.7269435, lng: 19.0726649 },
    { lat: 48.7272059, lng: 19.0729774 },
    { lat: 48.7272682, lng: 19.0733456 },
    { lat: 48.7270494, lng: 19.073363 },
    { lat: 48.7274627, lng: 19.0740566 },
    { lat: 48.7273296, lng: 19.0741917 },
    { lat: 48.7275899, lng: 19.0745045 },
    { lat: 48.7278932, lng: 19.074641 },
    { lat: 48.7279382, lng: 19.0750694 },
    { lat: 48.7277644, lng: 19.0756302 },
    { lat: 48.7279096, lng: 19.0760793 },
    { lat: 48.7280851, lng: 19.0760921 },
    { lat: 48.7283553, lng: 19.0759735 },
    { lat: 48.7286893, lng: 19.0761144 },
    { lat: 48.7288807, lng: 19.0767893 },
    { lat: 48.7288286, lng: 19.0775312 },
    { lat: 48.7286333, lng: 19.0775038 },
    { lat: 48.7284456, lng: 19.0779223 },
    { lat: 48.728034, lng: 19.0781912 },
    { lat: 48.7281713, lng: 19.0786138 },
    { lat: 48.7279245, lng: 19.0788015 },
    { lat: 48.7276424, lng: 19.0787047 },
    { lat: 48.7274497, lng: 19.0783881 },
    { lat: 48.727201, lng: 19.0786896 },
    { lat: 48.7272932, lng: 19.0793451 },
    { lat: 48.72817, lng: 19.0796309 },
    { lat: 48.7284687, lng: 19.0798994 },
    { lat: 48.7273297, lng: 19.0846508 },
    { lat: 48.7275007, lng: 19.0851873 },
    { lat: 48.727589, lng: 19.0879769 },
    { lat: 48.7282733, lng: 19.0937829 },
    { lat: 48.7287533, lng: 19.096568 },
    { lat: 48.7290258, lng: 19.097834 },
  ],
  Jakub: [
    { lat: 48.7715398, lng: 19.1070404 },
    { lat: 48.7710493, lng: 19.1074234 },
    { lat: 48.7705671, lng: 19.1083433 },
    { lat: 48.7702707, lng: 19.1087074 },
    { lat: 48.769954, lng: 19.1087569 },
    { lat: 48.7693988, lng: 19.1085414 },
    { lat: 48.7685771, lng: 19.1090641 },
    { lat: 48.7681027, lng: 19.1095242 },
    { lat: 48.7678715, lng: 19.1096366 },
    { lat: 48.7672344, lng: 19.1105192 },
    { lat: 48.767046, lng: 19.1108829 },
    { lat: 48.7667605, lng: 19.1111293 },
    { lat: 48.7663851, lng: 19.1116046 },
    { lat: 48.7660932, lng: 19.1116977 },
    { lat: 48.7652734, lng: 19.1124456 },
    { lat: 48.7649711, lng: 19.1124832 },
    { lat: 48.7656441, lng: 19.1140525 },
    { lat: 48.7652889, lng: 19.1144544 },
    { lat: 48.7646033, lng: 19.1143362 },
    { lat: 48.7638866, lng: 19.1150737 },
    { lat: 48.7632446, lng: 19.1160712 },
    { lat: 48.7634534, lng: 19.1162745 },
    { lat: 48.7630327, lng: 19.117239 },
    { lat: 48.7626712, lng: 19.1169364 },
    { lat: 48.7625341, lng: 19.11722 },
    { lat: 48.7628208, lng: 19.1177637 },
    { lat: 48.7621165, lng: 19.1186667 },
    { lat: 48.7623097, lng: 19.1192624 },
    { lat: 48.7624313, lng: 19.1196548 },
    { lat: 48.7622143, lng: 19.119933 },
    { lat: 48.7622018, lng: 19.120167 },
    { lat: 48.7621021, lng: 19.1203585 },
    { lat: 48.7619042, lng: 19.1202923 },
    { lat: 48.7617672, lng: 19.1209178 },
    { lat: 48.7613529, lng: 19.1214393 },
    { lat: 48.7617187, lng: 19.1219508 },
    { lat: 48.7618686, lng: 19.1220243 },
    { lat: 48.7620228, lng: 19.122392 },
    { lat: 48.7624349, lng: 19.1224589 },
    { lat: 48.762371, lng: 19.1234952 },
    { lat: 48.7626883, lng: 19.1239599 },
    { lat: 48.7625737, lng: 19.1247756 },
    { lat: 48.762393, lng: 19.1249828 },
    { lat: 48.7617606, lng: 19.1252971 },
    { lat: 48.7616879, lng: 19.1252503 },
    { lat: 48.7611921, lng: 19.1263367 },
    { lat: 48.760932, lng: 19.1266777 },
    { lat: 48.7605867, lng: 19.1264505 },
    { lat: 48.7605772, lng: 19.1266175 },
    { lat: 48.7602577, lng: 19.1264002 },
    { lat: 48.760423, lng: 19.1273396 },
    { lat: 48.7601541, lng: 19.1288539 },
    { lat: 48.7599558, lng: 19.1290879 },
    { lat: 48.7597442, lng: 19.1301677 },
    { lat: 48.7605111, lng: 19.1301009 },
    { lat: 48.7611326, lng: 19.130268 },
    { lat: 48.7615028, lng: 19.131184 },
    { lat: 48.7614896, lng: 19.1317857 },
    { lat: 48.7614499, lng: 19.1320598 },
    { lat: 48.7616104, lng: 19.1337278 },
    { lat: 48.7616187, lng: 19.1344548 },
    { lat: 48.76146, lng: 19.1348711 },
    { lat: 48.7606732, lng: 19.1349293 },
    { lat: 48.7605189, lng: 19.13519 },
    { lat: 48.7603779, lng: 19.1357049 },
    { lat: 48.7602721, lng: 19.1358051 },
    { lat: 48.7603338, lng: 19.1359589 },
    { lat: 48.7605806, lng: 19.1358453 },
    { lat: 48.7608142, lng: 19.1365205 },
    { lat: 48.7606512, lng: 19.1365339 },
    { lat: 48.7605542, lng: 19.1372493 },
    { lat: 48.7606203, lng: 19.1379446 },
    { lat: 48.7592628, lng: 19.1382388 },
    { lat: 48.7586854, lng: 19.1390578 },
    { lat: 48.7587449, lng: 19.1398367 },
    { lat: 48.7589984, lng: 19.1401643 },
    { lat: 48.7592782, lng: 19.1400908 },
    { lat: 48.7595669, lng: 19.140151 },
    { lat: 48.75973, lng: 19.140151 },
    { lat: 48.7599702, lng: 19.1400273 },
    { lat: 48.760228, lng: 19.1398936 },
    { lat: 48.7612946, lng: 19.1400607 },
    { lat: 48.7616737, lng: 19.1400908 },
    { lat: 48.7619756, lng: 19.1403983 },
    { lat: 48.7621232, lng: 19.1407895 },
    { lat: 48.7620196, lng: 19.1411572 },
    { lat: 48.7620196, lng: 19.1413645 },
    { lat: 48.7626366, lng: 19.1434571 },
    { lat: 48.7629363, lng: 19.1443564 },
    { lat: 48.7630509, lng: 19.1452155 },
    { lat: 48.7631038, lng: 19.1464892 },
    { lat: 48.763006, lng: 19.1473094 },
    { lat: 48.7632647, lng: 19.1476754 },
    { lat: 48.7640962, lng: 19.1495732 },
    { lat: 48.7640145, lng: 19.1498128 },
    { lat: 48.7641878, lng: 19.149922 },
    { lat: 48.7652626, lng: 19.1502291 },
    { lat: 48.7653987, lng: 19.1501295 },
    { lat: 48.7654927, lng: 19.1498645 },
    { lat: 48.7655233, lng: 19.1499153 },
    { lat: 48.7667961, lng: 19.1498247 },
    { lat: 48.7670634, lng: 19.152807 },
    { lat: 48.7677522, lng: 19.1525087 },
    { lat: 48.7682313, lng: 19.1531069 },
    { lat: 48.7684909, lng: 19.153275 },
    { lat: 48.7687172, lng: 19.1536837 },
    { lat: 48.7690017, lng: 19.1538061 },
    { lat: 48.7690787, lng: 19.1541127 },
    { lat: 48.7693284, lng: 19.1543976 },
    { lat: 48.7696736, lng: 19.154471 },
    { lat: 48.7699054, lng: 19.1549442 },
    { lat: 48.7700396, lng: 19.1551081 },
    { lat: 48.7708026, lng: 19.1555645 },
    { lat: 48.7718452, lng: 19.1554494 },
    { lat: 48.7727273, lng: 19.1556426 },
    { lat: 48.7730284, lng: 19.1525681 },
    { lat: 48.7731041, lng: 19.1523194 },
    { lat: 48.772767, lng: 19.1496806 },
    { lat: 48.7732526, lng: 19.1494258 },
    { lat: 48.7742239, lng: 19.1481985 },
    { lat: 48.7743043, lng: 19.1485269 },
    { lat: 48.7746397, lng: 19.1479223 },
    { lat: 48.7747798, lng: 19.1480423 },
    { lat: 48.77504, lng: 19.1477857 },
    { lat: 48.7749472, lng: 19.1474352 },
    { lat: 48.7759059, lng: 19.1467354 },
    { lat: 48.7767048, lng: 19.1459967 },
    { lat: 48.7766092, lng: 19.1440957 },
    { lat: 48.775234, lng: 19.1436856 },
    { lat: 48.7752271, lng: 19.1437746 },
    { lat: 48.7748783, lng: 19.144018 },
    { lat: 48.7746087, lng: 19.1440355 },
    { lat: 48.774584, lng: 19.1447956 },
    { lat: 48.7743981, lng: 19.145051 },
    { lat: 48.7740385, lng: 19.1451151 },
    { lat: 48.7740545, lng: 19.1452395 },
    { lat: 48.7738816, lng: 19.1452736 },
    { lat: 48.7737691, lng: 19.1450715 },
    { lat: 48.7736839, lng: 19.1450389 },
    { lat: 48.7736285, lng: 19.1440709 },
    { lat: 48.7738475, lng: 19.1438936 },
    { lat: 48.7738604, lng: 19.1436513 },
    { lat: 48.7737727, lng: 19.1434978 },
    { lat: 48.7735758, lng: 19.1438023 },
    { lat: 48.773515, lng: 19.1436662 },
    { lat: 48.7734795, lng: 19.1433804 },
    { lat: 48.7731268, lng: 19.1429001 },
    { lat: 48.7729867, lng: 19.1419792 },
    { lat: 48.7734076, lng: 19.1409554 },
    { lat: 48.7732012, lng: 19.1406791 },
    { lat: 48.7728353, lng: 19.1409666 },
    { lat: 48.7724345, lng: 19.1409539 },
    { lat: 48.7724157, lng: 19.1407973 },
    { lat: 48.7722063, lng: 19.14046 },
    { lat: 48.772048, lng: 19.1399936 },
    { lat: 48.7722516, lng: 19.1397773 },
    { lat: 48.7723495, lng: 19.139525 },
    { lat: 48.7753855, lng: 19.1409944 },
    { lat: 48.7800463, lng: 19.1435937 },
    { lat: 48.7802322, lng: 19.1440982 },
    { lat: 48.7805349, lng: 19.1446578 },
    { lat: 48.7807962, lng: 19.1447442 },
    { lat: 48.7811582, lng: 19.1450448 },
    { lat: 48.7810932, lng: 19.1452841 },
    { lat: 48.7815474, lng: 19.1455332 },
    { lat: 48.7816101, lng: 19.1457988 },
    { lat: 48.7819116, lng: 19.1459697 },
    { lat: 48.7820397, lng: 19.1458255 },
    { lat: 48.7824107, lng: 19.1457566 },
    { lat: 48.7825205, lng: 19.1456076 },
    { lat: 48.7828947, lng: 19.1460638 },
    { lat: 48.783034, lng: 19.1458704 },
    { lat: 48.7831545, lng: 19.1454815 },
    { lat: 48.7853388, lng: 19.1435475 },
    { lat: 48.7854043, lng: 19.1434132 },
    { lat: 48.7855753, lng: 19.143222 },
    { lat: 48.7857951, lng: 19.1428344 },
    { lat: 48.7857722, lng: 19.1425528 },
    { lat: 48.7858293, lng: 19.1424055 },
    { lat: 48.7857786, lng: 19.1416501 },
    { lat: 48.7856459, lng: 19.1406949 },
    { lat: 48.7817051, lng: 19.1323012 },
    { lat: 48.7816136, lng: 19.131246 },
    { lat: 48.7813202, lng: 19.1305982 },
    { lat: 48.7803584, lng: 19.1293614 },
    { lat: 48.7798944, lng: 19.1290481 },
    { lat: 48.7795299, lng: 19.1286979 },
    { lat: 48.7792943, lng: 19.1284725 },
    { lat: 48.7792038, lng: 19.1284431 },
    { lat: 48.7789305, lng: 19.1279968 },
    { lat: 48.7790305, lng: 19.1273046 },
    { lat: 48.778687, lng: 19.1269463 },
    { lat: 48.7788213, lng: 19.1266073 },
    { lat: 48.7788864, lng: 19.1260323 },
    { lat: 48.7785181, lng: 19.1253766 },
    { lat: 48.7786058, lng: 19.1252763 },
    { lat: 48.7784876, lng: 19.1248482 },
    { lat: 48.7782359, lng: 19.1244302 },
    { lat: 48.7778178, lng: 19.1254826 },
    { lat: 48.7774995, lng: 19.1260704 },
    { lat: 48.7770647, lng: 19.1266035 },
    { lat: 48.7768125, lng: 19.1270391 },
    { lat: 48.7764779, lng: 19.1272052 },
    { lat: 48.7760672, lng: 19.1276087 },
    { lat: 48.7755441, lng: 19.1279029 },
    { lat: 48.7750724, lng: 19.1280524 },
    { lat: 48.7749818, lng: 19.1282524 },
    { lat: 48.7743026, lng: 19.1291315 },
    { lat: 48.7740724, lng: 19.1297665 },
    { lat: 48.7730538, lng: 19.1311495 },
    { lat: 48.7729982, lng: 19.1312537 },
    { lat: 48.7730375, lng: 19.1313219 },
    { lat: 48.7729467, lng: 19.1316023 },
    { lat: 48.772871, lng: 19.1314618 },
    { lat: 48.7724648, lng: 19.131914 },
    { lat: 48.7721826, lng: 19.1324098 },
    { lat: 48.7722114, lng: 19.1324905 },
    { lat: 48.7718974, lng: 19.1329112 },
    { lat: 48.77178, lng: 19.1329112 },
    { lat: 48.7717463, lng: 19.1330469 },
    { lat: 48.77107, lng: 19.1336218 },
    { lat: 48.7707365, lng: 19.1326638 },
    { lat: 48.7705837, lng: 19.1312143 },
    { lat: 48.770722, lng: 19.1297414 },
    { lat: 48.7697992, lng: 19.1295225 },
    { lat: 48.7695898, lng: 19.1293918 },
    { lat: 48.7693305, lng: 19.1294364 },
    { lat: 48.7690011, lng: 19.1297066 },
    { lat: 48.7681785, lng: 19.1285048 },
    { lat: 48.7671576, lng: 19.1287155 },
    { lat: 48.7671282, lng: 19.1286699 },
    { lat: 48.7672579, lng: 19.128096 },
    { lat: 48.7672412, lng: 19.1278059 },
    { lat: 48.7673493, lng: 19.1272777 },
    { lat: 48.7675008, lng: 19.1269443 },
    { lat: 48.7679032, lng: 19.1264636 },
    { lat: 48.7680928, lng: 19.1257832 },
    { lat: 48.7668485, lng: 19.1257423 },
    { lat: 48.7673291, lng: 19.1249743 },
    { lat: 48.7673184, lng: 19.124923 },
    { lat: 48.7672168, lng: 19.1244363 },
    { lat: 48.7672253, lng: 19.1235566 },
    { lat: 48.7670798, lng: 19.1223463 },
    { lat: 48.7679104, lng: 19.1196512 },
    { lat: 48.7679859, lng: 19.1188754 },
    { lat: 48.7681602, lng: 19.1182063 },
    { lat: 48.7683962, lng: 19.1169357 },
    { lat: 48.7683979, lng: 19.1162625 },
    { lat: 48.7682952, lng: 19.1153479 },
    { lat: 48.7686906, lng: 19.1128405 },
    { lat: 48.7689405, lng: 19.1115803 },
    { lat: 48.7690324, lng: 19.1113405 },
    { lat: 48.7691127, lng: 19.1113886 },
    { lat: 48.7697663, lng: 19.1109779 },
    { lat: 48.7702769, lng: 19.1104493 },
    { lat: 48.7703146, lng: 19.1102088 },
    { lat: 48.7707471, lng: 19.1100901 },
    { lat: 48.7714687, lng: 19.1094762 },
    { lat: 48.7714718, lng: 19.1079234 },
    { lat: 48.7715398, lng: 19.1070404 },
  ],
  Kostiviarska: [
    { lat: 48.7512147, lng: 19.1465192 },
    { lat: 48.7516477, lng: 19.1465444 },
    { lat: 48.7519927, lng: 19.1466188 },
    { lat: 48.752221, lng: 19.1468553 },
    { lat: 48.7536285, lng: 19.1467573 },
    { lat: 48.7540013, lng: 19.146705 },
    { lat: 48.7540323, lng: 19.146644 },
    { lat: 48.7540417, lng: 19.1467012 },
    { lat: 48.754559, lng: 19.1466508 },
    { lat: 48.755018, lng: 19.1467481 },
    { lat: 48.7549427, lng: 19.1465905 },
    { lat: 48.755197, lng: 19.146469 },
    { lat: 48.7554889, lng: 19.1464861 },
    { lat: 48.7561198, lng: 19.1463423 },
    { lat: 48.7566235, lng: 19.1476678 },
    { lat: 48.756664, lng: 19.148127 },
    { lat: 48.7567632, lng: 19.1482849 },
    { lat: 48.7570235, lng: 19.148237 },
    { lat: 48.7580902, lng: 19.1477404 },
    { lat: 48.7591856, lng: 19.1474824 },
    { lat: 48.7603389, lng: 19.1470939 },
    { lat: 48.761715, lng: 19.1464674 },
    { lat: 48.7627666, lng: 19.1469707 },
    { lat: 48.763006, lng: 19.1473094 },
    { lat: 48.7631038, lng: 19.1464892 },
    { lat: 48.7630509, lng: 19.1452155 },
    { lat: 48.7629363, lng: 19.1443564 },
    { lat: 48.7626366, lng: 19.1434571 },
    { lat: 48.7620196, lng: 19.1413645 },
    { lat: 48.7620196, lng: 19.1411572 },
    { lat: 48.7621232, lng: 19.1407895 },
    { lat: 48.7619756, lng: 19.1403983 },
    { lat: 48.7616737, lng: 19.1400908 },
    { lat: 48.7612946, lng: 19.1400607 },
    { lat: 48.760228, lng: 19.1398936 },
    { lat: 48.7599702, lng: 19.1400273 },
    { lat: 48.75973, lng: 19.140151 },
    { lat: 48.7595669, lng: 19.140151 },
    { lat: 48.7592782, lng: 19.1400908 },
    { lat: 48.7589984, lng: 19.1401643 },
    { lat: 48.7587449, lng: 19.1398367 },
    { lat: 48.7586854, lng: 19.1390578 },
    { lat: 48.7592628, lng: 19.1382388 },
    { lat: 48.7606203, lng: 19.1379446 },
    { lat: 48.7605542, lng: 19.1372493 },
    { lat: 48.7606512, lng: 19.1365339 },
    { lat: 48.7608142, lng: 19.1365205 },
    { lat: 48.7605806, lng: 19.1358453 },
    { lat: 48.7603338, lng: 19.1359589 },
    { lat: 48.7602721, lng: 19.1358051 },
    { lat: 48.7603779, lng: 19.1357049 },
    { lat: 48.7605189, lng: 19.13519 },
    { lat: 48.7606732, lng: 19.1349293 },
    { lat: 48.76146, lng: 19.1348711 },
    { lat: 48.7616187, lng: 19.1344548 },
    { lat: 48.7616104, lng: 19.1337278 },
    { lat: 48.7614499, lng: 19.1320598 },
    { lat: 48.7614896, lng: 19.1317857 },
    { lat: 48.7615028, lng: 19.131184 },
    { lat: 48.7611326, lng: 19.130268 },
    { lat: 48.7605111, lng: 19.1301009 },
    { lat: 48.7597442, lng: 19.1301677 },
    { lat: 48.7599558, lng: 19.1290879 },
    { lat: 48.7601541, lng: 19.1288539 },
    { lat: 48.760423, lng: 19.1273396 },
    { lat: 48.7602577, lng: 19.1264002 },
    { lat: 48.7605772, lng: 19.1266175 },
    { lat: 48.7605867, lng: 19.1264505 },
    { lat: 48.760932, lng: 19.1266777 },
    { lat: 48.7611921, lng: 19.1263367 },
    { lat: 48.7616879, lng: 19.1252503 },
    { lat: 48.7617606, lng: 19.1252971 },
    { lat: 48.762393, lng: 19.1249828 },
    { lat: 48.7625737, lng: 19.1247756 },
    { lat: 48.7626883, lng: 19.1239599 },
    { lat: 48.762371, lng: 19.1234952 },
    { lat: 48.7624349, lng: 19.1224589 },
    { lat: 48.7620228, lng: 19.122392 },
    { lat: 48.7618686, lng: 19.1220243 },
    { lat: 48.7617187, lng: 19.1219508 },
    { lat: 48.7613529, lng: 19.1214393 },
    { lat: 48.7617672, lng: 19.1209178 },
    { lat: 48.7619042, lng: 19.1202923 },
    { lat: 48.7621021, lng: 19.1203585 },
    { lat: 48.7622018, lng: 19.120167 },
    { lat: 48.7622143, lng: 19.119933 },
    { lat: 48.7624313, lng: 19.1196548 },
    { lat: 48.7623097, lng: 19.1192624 },
    { lat: 48.7621165, lng: 19.1186667 },
    { lat: 48.7628208, lng: 19.1177637 },
    { lat: 48.7625341, lng: 19.11722 },
    { lat: 48.7626712, lng: 19.1169364 },
    { lat: 48.7630327, lng: 19.117239 },
    { lat: 48.7634534, lng: 19.1162745 },
    { lat: 48.7632446, lng: 19.1160712 },
    { lat: 48.7638866, lng: 19.1150737 },
    { lat: 48.7646033, lng: 19.1143362 },
    { lat: 48.7652889, lng: 19.1144544 },
    { lat: 48.7656441, lng: 19.1140525 },
    { lat: 48.7649711, lng: 19.1124832 },
    { lat: 48.7646583, lng: 19.1125221 },
    { lat: 48.763995, lng: 19.113195 },
    { lat: 48.7637369, lng: 19.1131981 },
    { lat: 48.7633088, lng: 19.1136107 },
    { lat: 48.7630487, lng: 19.1137317 },
    { lat: 48.7629101, lng: 19.1137077 },
    { lat: 48.7628483, lng: 19.1139877 },
    { lat: 48.7626584, lng: 19.1140446 },
    { lat: 48.762808, lng: 19.1144039 },
    { lat: 48.7623141, lng: 19.1150837 },
    { lat: 48.7620425, lng: 19.1151552 },
    { lat: 48.7618096, lng: 19.115323 },
    { lat: 48.7614937, lng: 19.115819 },
    { lat: 48.7613944, lng: 19.1163184 },
    { lat: 48.7611484, lng: 19.1165892 },
    { lat: 48.7608464, lng: 19.1171361 },
    { lat: 48.7603397, lng: 19.1168976 },
    { lat: 48.7607539, lng: 19.1166324 },
    { lat: 48.7602234, lng: 19.116642 },
    { lat: 48.7600748, lng: 19.1165331 },
    { lat: 48.759174, lng: 19.1165067 },
    { lat: 48.7588351, lng: 19.1163379 },
    { lat: 48.7586557, lng: 19.116331 },
    { lat: 48.7585579, lng: 19.1165221 },
    { lat: 48.7574454, lng: 19.1164529 },
    { lat: 48.7572281, lng: 19.1163003 },
    { lat: 48.7570961, lng: 19.1160251 },
    { lat: 48.7569234, lng: 19.115995 },
    { lat: 48.7565013, lng: 19.1156001 },
    { lat: 48.7561871, lng: 19.1154949 },
    { lat: 48.7560646, lng: 19.1153383 },
    { lat: 48.7560513, lng: 19.1150276 },
    { lat: 48.7557896, lng: 19.1150711 },
    { lat: 48.7553854, lng: 19.1148753 },
    { lat: 48.7548569, lng: 19.1148548 },
    { lat: 48.7539316, lng: 19.1149991 },
    { lat: 48.7537942, lng: 19.1148428 },
    { lat: 48.7533758, lng: 19.1150181 },
    { lat: 48.7532038, lng: 19.115009 },
    { lat: 48.7526701, lng: 19.1155888 },
    { lat: 48.7523655, lng: 19.1166575 },
    { lat: 48.7522171, lng: 19.1167994 },
    { lat: 48.7518978, lng: 19.1166583 },
    { lat: 48.750628, lng: 19.1178551 },
    { lat: 48.7499617, lng: 19.1191075 },
    { lat: 48.7499446, lng: 19.119336 },
    { lat: 48.7497074, lng: 19.1196886 },
    { lat: 48.749404, lng: 19.1207581 },
    { lat: 48.7493765, lng: 19.1210477 },
    { lat: 48.7493355, lng: 19.1213299 },
    { lat: 48.7493973, lng: 19.1221742 },
    { lat: 48.7494984, lng: 19.1226661 },
    { lat: 48.7495565, lng: 19.1227471 },
    { lat: 48.7494869, lng: 19.1228457 },
    { lat: 48.749666, lng: 19.1232277 },
    { lat: 48.7503249, lng: 19.1242914 },
    { lat: 48.7507072, lng: 19.1251163 },
    { lat: 48.7510659, lng: 19.1254376 },
    { lat: 48.7512456, lng: 19.1260625 },
    { lat: 48.7513573, lng: 19.1262964 },
    { lat: 48.751805, lng: 19.1268471 },
    { lat: 48.7519068, lng: 19.127081 },
    { lat: 48.7526323, lng: 19.1279822 },
    { lat: 48.7528777, lng: 19.1286851 },
    { lat: 48.752883, lng: 19.1288406 },
    { lat: 48.7527242, lng: 19.1294798 },
    { lat: 48.7526356, lng: 19.13013 },
    { lat: 48.7525028, lng: 19.1304997 },
    { lat: 48.7523859, lng: 19.1303943 },
    { lat: 48.752439, lng: 19.1302111 },
    { lat: 48.7523728, lng: 19.1301961 },
    { lat: 48.7506567, lng: 19.1323378 },
    { lat: 48.7508856, lng: 19.1334097 },
    { lat: 48.7489578, lng: 19.1358298 },
    { lat: 48.7486502, lng: 19.1364996 },
    { lat: 48.7488371, lng: 19.1369275 },
    { lat: 48.7489072, lng: 19.1378223 },
    { lat: 48.7489154, lng: 19.1388366 },
    { lat: 48.7500608, lng: 19.1385618 },
    { lat: 48.7508435, lng: 19.1385015 },
    { lat: 48.7519902, lng: 19.1386596 },
    { lat: 48.752008, lng: 19.1386621 },
    { lat: 48.7544525, lng: 19.1392919 },
    { lat: 48.7543967, lng: 19.140883 },
    { lat: 48.7544646, lng: 19.1410668 },
    { lat: 48.754492, lng: 19.1416043 },
    { lat: 48.7549609, lng: 19.1414896 },
    { lat: 48.7553165, lng: 19.1417936 },
    { lat: 48.7553929, lng: 19.1419733 },
    { lat: 48.7549719, lng: 19.1425573 },
    { lat: 48.75474, lng: 19.1430425 },
    { lat: 48.7548773, lng: 19.1432221 },
    { lat: 48.7547018, lng: 19.1436216 },
    { lat: 48.7543309, lng: 19.1438945 },
    { lat: 48.7541669, lng: 19.1440884 },
    { lat: 48.75397, lng: 19.1448301 },
    { lat: 48.7539926, lng: 19.1449004 },
    { lat: 48.7539554, lng: 19.1450379 },
    { lat: 48.7535752, lng: 19.1450067 },
    { lat: 48.7530966, lng: 19.1465092 },
    { lat: 48.7516146, lng: 19.1463074 },
    { lat: 48.7512147, lng: 19.1465192 },
  ],
  Senica: [
    { lat: 48.7428538, lng: 19.1742874 },
    { lat: 48.7429763, lng: 19.1748777 },
    { lat: 48.7429438, lng: 19.1750874 },
    { lat: 48.7430641, lng: 19.1759572 },
    { lat: 48.7430387, lng: 19.1762682 },
    { lat: 48.7431775, lng: 19.1762481 },
    { lat: 48.7434498, lng: 19.1765798 },
    { lat: 48.7434529, lng: 19.1783281 },
    { lat: 48.7432643, lng: 19.1790778 },
    { lat: 48.7424822, lng: 19.1796821 },
    { lat: 48.7421217, lng: 19.1803085 },
    { lat: 48.7417417, lng: 19.180746 },
    { lat: 48.7407, lng: 19.1811553 },
    { lat: 48.7405256, lng: 19.1811391 },
    { lat: 48.7405173, lng: 19.1810607 },
    { lat: 48.7403601, lng: 19.1810783 },
    { lat: 48.7402706, lng: 19.1813215 },
    { lat: 48.7399598, lng: 19.1813263 },
    { lat: 48.7394649, lng: 19.1811663 },
    { lat: 48.7386353, lng: 19.1805005 },
    { lat: 48.7381781, lng: 19.1799077 },
    { lat: 48.7378451, lng: 19.1792525 },
    { lat: 48.7378117, lng: 19.1790534 },
    { lat: 48.7376358, lng: 19.1790981 },
    { lat: 48.7386239, lng: 19.1834023 },
    { lat: 48.7387977, lng: 19.1840475 },
    { lat: 48.7388987, lng: 19.1840405 },
    { lat: 48.7390467, lng: 19.1852478 },
    { lat: 48.7397329, lng: 19.1883082 },
    { lat: 48.7406755, lng: 19.1922621 },
    { lat: 48.74089, lng: 19.1935406 },
    { lat: 48.7414431, lng: 19.1956524 },
    { lat: 48.7416837, lng: 19.196842 },
    { lat: 48.7418147, lng: 19.1981917 },
    { lat: 48.7418072, lng: 19.1986575 },
    { lat: 48.7416937, lng: 19.199708 },
    { lat: 48.7414672, lng: 19.2008186 },
    { lat: 48.7411229, lng: 19.2014359 },
    { lat: 48.7411411, lng: 19.2020174 },
    { lat: 48.7410079, lng: 19.2020376 },
    { lat: 48.7408251, lng: 19.2030258 },
    { lat: 48.7406263, lng: 19.2037797 },
    { lat: 48.7406663, lng: 19.2046248 },
    { lat: 48.7405866, lng: 19.2047737 },
    { lat: 48.7404726, lng: 19.205856 },
    { lat: 48.7404307, lng: 19.2075677 },
    { lat: 48.7407106, lng: 19.2107225 },
    { lat: 48.7425549, lng: 19.2100524 },
    { lat: 48.7426567, lng: 19.2110582 },
    { lat: 48.7428674, lng: 19.2110812 },
    { lat: 48.7430008, lng: 19.2125238 },
    { lat: 48.743333, lng: 19.2123123 },
    { lat: 48.7434647, lng: 19.2123291 },
    { lat: 48.7435255, lng: 19.2121166 },
    { lat: 48.7436453, lng: 19.2120118 },
    { lat: 48.7437512, lng: 19.2120079 },
    { lat: 48.7439817, lng: 19.2116048 },
    { lat: 48.7441256, lng: 19.2114784 },
    { lat: 48.7444882, lng: 19.2115609 },
    { lat: 48.7445262, lng: 19.2117217 },
    { lat: 48.7446105, lng: 19.2117163 },
    { lat: 48.7450225, lng: 19.2113336 },
    { lat: 48.7451239, lng: 19.211587 },
    { lat: 48.745332, lng: 19.211621 },
    { lat: 48.7455019, lng: 19.2114521 },
    { lat: 48.7458293, lng: 19.2115793 },
    { lat: 48.7460173, lng: 19.2115545 },
    { lat: 48.746217, lng: 19.2116973 },
    { lat: 48.7463122, lng: 19.2116824 },
    { lat: 48.7465019, lng: 19.2119902 },
    { lat: 48.7467028, lng: 19.2119047 },
    { lat: 48.7469466, lng: 19.211934 },
    { lat: 48.7471853, lng: 19.2120692 },
    { lat: 48.7472996, lng: 19.2122626 },
    { lat: 48.7474163, lng: 19.2123204 },
    { lat: 48.7475737, lng: 19.2122646 },
    { lat: 48.7475859, lng: 19.2121901 },
    { lat: 48.747888, lng: 19.2121715 },
    { lat: 48.7480179, lng: 19.2123845 },
    { lat: 48.7484159, lng: 19.2125242 },
    { lat: 48.7486986, lng: 19.2123596 },
    { lat: 48.748815, lng: 19.2123873 },
    { lat: 48.7493217, lng: 19.2127471 },
    { lat: 48.7503957, lng: 19.2084295 },
    { lat: 48.7506096, lng: 19.2069423 },
    { lat: 48.7512196, lng: 19.2059257 },
    { lat: 48.7516736, lng: 19.2046742 },
    { lat: 48.7519043, lng: 19.2032764 },
    { lat: 48.7525151, lng: 19.2019078 },
    { lat: 48.7528358, lng: 19.2021732 },
    { lat: 48.7527728, lng: 19.201414 },
    { lat: 48.7525557, lng: 19.2015142 },
    { lat: 48.752838, lng: 19.1993966 },
    { lat: 48.7528874, lng: 19.1981423 },
    { lat: 48.7531345, lng: 19.1971535 },
    { lat: 48.753623, lng: 19.1955458 },
    { lat: 48.75358, lng: 19.1948247 },
    { lat: 48.7538384, lng: 19.1932612 },
    { lat: 48.7538188, lng: 19.1928594 },
    { lat: 48.7536967, lng: 19.192065 },
    { lat: 48.7539256, lng: 19.191006 },
    { lat: 48.7539471, lng: 19.1909807 },
    { lat: 48.7537958, lng: 19.1909058 },
    { lat: 48.7536567, lng: 19.1908967 },
    { lat: 48.7534138, lng: 19.1910031 },
    { lat: 48.7532773, lng: 19.1909794 },
    { lat: 48.7528161, lng: 19.1904186 },
    { lat: 48.752757, lng: 19.1902502 },
    { lat: 48.7528704, lng: 19.1900567 },
    { lat: 48.7528082, lng: 19.1898419 },
    { lat: 48.7522902, lng: 19.189435 },
    { lat: 48.7521084, lng: 19.1891759 },
    { lat: 48.752045, lng: 19.1889855 },
    { lat: 48.7521239, lng: 19.1885927 },
    { lat: 48.7519755, lng: 19.188486 },
    { lat: 48.7519372, lng: 19.1884656 },
    { lat: 48.7515268, lng: 19.1888562 },
    { lat: 48.7508422, lng: 19.1889412 },
    { lat: 48.7507592, lng: 19.1888097 },
    { lat: 48.7507037, lng: 19.1884559 },
    { lat: 48.7506003, lng: 19.1883949 },
    { lat: 48.7498996, lng: 19.1885185 },
    { lat: 48.7494455, lng: 19.1883131 },
    { lat: 48.7492483, lng: 19.1884095 },
    { lat: 48.7489728, lng: 19.1884231 },
    { lat: 48.7489331, lng: 19.1883514 },
    { lat: 48.7489207, lng: 19.1880481 },
    { lat: 48.7488422, lng: 19.1879412 },
    { lat: 48.7486618, lng: 19.1878495 },
    { lat: 48.748832, lng: 19.1873158 },
    { lat: 48.7487628, lng: 19.1870296 },
    { lat: 48.7485524, lng: 19.1869938 },
    { lat: 48.7483399, lng: 19.1868639 },
    { lat: 48.748002, lng: 19.1869388 },
    { lat: 48.7477742, lng: 19.1868932 },
    { lat: 48.7477075, lng: 19.1867285 },
    { lat: 48.7476282, lng: 19.1866713 },
    { lat: 48.7469775, lng: 19.1869183 },
    { lat: 48.7466575, lng: 19.1868769 },
    { lat: 48.7464745, lng: 19.1870084 },
    { lat: 48.746489, lng: 19.1866578 },
    { lat: 48.7459964, lng: 19.1775534 },
    { lat: 48.7449039, lng: 19.1743265 },
    { lat: 48.7443837, lng: 19.1740161 },
    { lat: 48.7439701, lng: 19.1738999 },
    { lat: 48.7433003, lng: 19.1740138 },
    { lat: 48.7428538, lng: 19.1742874 },
  ],
  Uľanka: [
    { lat: 48.7767991, lng: 19.0694856 },
    { lat: 48.7766216, lng: 19.0706032 },
    { lat: 48.7755287, lng: 19.0704831 },
    { lat: 48.7752423, lng: 19.0705293 },
    { lat: 48.7749368, lng: 19.0707318 },
    { lat: 48.7738759, lng: 19.0721778 },
    { lat: 48.7737093, lng: 19.0725646 },
    { lat: 48.7728609, lng: 19.0736157 },
    { lat: 48.7727054, lng: 19.0741363 },
    { lat: 48.7724531, lng: 19.0744835 },
    { lat: 48.7715947, lng: 19.0761253 },
    { lat: 48.7708584, lng: 19.0772747 },
    { lat: 48.7705497, lng: 19.0779814 },
    { lat: 48.7703219, lng: 19.0788401 },
    { lat: 48.7705407, lng: 19.079229 },
    { lat: 48.769789, lng: 19.0813371 },
    { lat: 48.7692085, lng: 19.080727 },
    { lat: 48.7684776, lng: 19.0829867 },
    { lat: 48.7673268, lng: 19.0842156 },
    { lat: 48.7672223, lng: 19.0851495 },
    { lat: 48.7673037, lng: 19.0865386 },
    { lat: 48.7680381, lng: 19.0900885 },
    { lat: 48.7702868, lng: 19.0935089 },
    { lat: 48.7718506, lng: 19.0957393 },
    { lat: 48.7724484, lng: 19.0958133 },
    { lat: 48.7726486, lng: 19.0969382 },
    { lat: 48.772805, lng: 19.097182 },
    { lat: 48.7729407, lng: 19.097736 },
    { lat: 48.7731326, lng: 19.0978997 },
    { lat: 48.7734813, lng: 19.0978383 },
    { lat: 48.7731658, lng: 19.0988634 },
    { lat: 48.7737859, lng: 19.0992308 },
    { lat: 48.7740026, lng: 19.0994832 },
    { lat: 48.7743079, lng: 19.0993667 },
    { lat: 48.7743449, lng: 19.0989504 },
    { lat: 48.7748445, lng: 19.0987733 },
    { lat: 48.7753287, lng: 19.099491 },
    { lat: 48.7755443, lng: 19.0996806 },
    { lat: 48.7748537, lng: 19.1015086 },
    { lat: 48.7746921, lng: 19.10225 },
    { lat: 48.7745296, lng: 19.1026984 },
    { lat: 48.7742484, lng: 19.1032829 },
    { lat: 48.7735311, lng: 19.1050737 },
    { lat: 48.7720975, lng: 19.1069189 },
    { lat: 48.7715398, lng: 19.1070404 },
    { lat: 48.7714718, lng: 19.1079234 },
    { lat: 48.7714687, lng: 19.1094762 },
    { lat: 48.7707471, lng: 19.1100901 },
    { lat: 48.7703146, lng: 19.1102088 },
    { lat: 48.7702769, lng: 19.1104493 },
    { lat: 48.7697663, lng: 19.1109779 },
    { lat: 48.7691127, lng: 19.1113886 },
    { lat: 48.7690324, lng: 19.1113405 },
    { lat: 48.7689405, lng: 19.1115803 },
    { lat: 48.7686906, lng: 19.1128405 },
    { lat: 48.7682952, lng: 19.1153479 },
    { lat: 48.7683979, lng: 19.1162625 },
    { lat: 48.7683962, lng: 19.1169357 },
    { lat: 48.7681602, lng: 19.1182063 },
    { lat: 48.7679859, lng: 19.1188754 },
    { lat: 48.7679104, lng: 19.1196512 },
    { lat: 48.7670798, lng: 19.1223463 },
    { lat: 48.7672253, lng: 19.1235566 },
    { lat: 48.7672168, lng: 19.1244363 },
    { lat: 48.7673184, lng: 19.124923 },
    { lat: 48.7673291, lng: 19.1249743 },
    { lat: 48.7668485, lng: 19.1257423 },
    { lat: 48.7680928, lng: 19.1257832 },
    { lat: 48.7679032, lng: 19.1264636 },
    { lat: 48.7675008, lng: 19.1269443 },
    { lat: 48.7673493, lng: 19.1272777 },
    { lat: 48.7672412, lng: 19.1278059 },
    { lat: 48.7672579, lng: 19.128096 },
    { lat: 48.7671282, lng: 19.1286699 },
    { lat: 48.7671576, lng: 19.1287155 },
    { lat: 48.7681785, lng: 19.1285048 },
    { lat: 48.7690011, lng: 19.1297066 },
    { lat: 48.7693305, lng: 19.1294364 },
    { lat: 48.7695898, lng: 19.1293918 },
    { lat: 48.7697992, lng: 19.1295225 },
    { lat: 48.770722, lng: 19.1297414 },
    { lat: 48.7705837, lng: 19.1312143 },
    { lat: 48.7707365, lng: 19.1326638 },
    { lat: 48.77107, lng: 19.1336218 },
    { lat: 48.7717463, lng: 19.1330469 },
    { lat: 48.77178, lng: 19.1329112 },
    { lat: 48.7718974, lng: 19.1329112 },
    { lat: 48.7722114, lng: 19.1324905 },
    { lat: 48.7721826, lng: 19.1324098 },
    { lat: 48.7724648, lng: 19.131914 },
    { lat: 48.772871, lng: 19.1314618 },
    { lat: 48.7729467, lng: 19.1316023 },
    { lat: 48.7730375, lng: 19.1313219 },
    { lat: 48.7729982, lng: 19.1312537 },
    { lat: 48.7730538, lng: 19.1311495 },
    { lat: 48.7740724, lng: 19.1297665 },
    { lat: 48.7743026, lng: 19.1291315 },
    { lat: 48.7749818, lng: 19.1282524 },
    { lat: 48.7750724, lng: 19.1280524 },
    { lat: 48.7755441, lng: 19.1279029 },
    { lat: 48.7760672, lng: 19.1276087 },
    { lat: 48.7764779, lng: 19.1272052 },
    { lat: 48.7768125, lng: 19.1270391 },
    { lat: 48.7770647, lng: 19.1266035 },
    { lat: 48.7774995, lng: 19.1260704 },
    { lat: 48.7778178, lng: 19.1254826 },
    { lat: 48.7782359, lng: 19.1244302 },
    { lat: 48.7784876, lng: 19.1248482 },
    { lat: 48.7786058, lng: 19.1252763 },
    { lat: 48.7785181, lng: 19.1253766 },
    { lat: 48.7788864, lng: 19.1260323 },
    { lat: 48.7788213, lng: 19.1266073 },
    { lat: 48.778687, lng: 19.1269463 },
    { lat: 48.7790305, lng: 19.1273046 },
    { lat: 48.7789305, lng: 19.1279968 },
    { lat: 48.7792038, lng: 19.1284431 },
    { lat: 48.7792943, lng: 19.1284725 },
    { lat: 48.7795299, lng: 19.1286979 },
    { lat: 48.7805391, lng: 19.1265125 },
    { lat: 48.7809568, lng: 19.1248096 },
    { lat: 48.7807829, lng: 19.124459 },
    { lat: 48.7810945, lng: 19.1244498 },
    { lat: 48.7814295, lng: 19.1243365 },
    { lat: 48.7822261, lng: 19.1234872 },
    { lat: 48.7831847, lng: 19.1228069 },
    { lat: 48.7837614, lng: 19.1227989 },
    { lat: 48.7836408, lng: 19.1223671 },
    { lat: 48.7833248, lng: 19.1216504 },
    { lat: 48.7831984, lng: 19.1210992 },
    { lat: 48.7827822, lng: 19.1202065 },
    { lat: 48.7825526, lng: 19.1198053 },
    { lat: 48.7819556, lng: 19.1190811 },
    { lat: 48.7815515, lng: 19.1186861 },
    { lat: 48.7816142, lng: 19.1176952 },
    { lat: 48.7820514, lng: 19.1177612 },
    { lat: 48.7826352, lng: 19.1180973 },
    { lat: 48.7838562, lng: 19.11931 },
    { lat: 48.7839801, lng: 19.1192652 },
    { lat: 48.7843398, lng: 19.1196317 },
    { lat: 48.7849255, lng: 19.1199872 },
    { lat: 48.7857214, lng: 19.1202804 },
    { lat: 48.7862476, lng: 19.1205504 },
    { lat: 48.7867734, lng: 19.1209467 },
    { lat: 48.7868935, lng: 19.1210071 },
    { lat: 48.7870217, lng: 19.1209762 },
    { lat: 48.7872817, lng: 19.121266 },
    { lat: 48.7877195, lng: 19.1214356 },
    { lat: 48.7880024, lng: 19.121409 },
    { lat: 48.7883075, lng: 19.1212647 },
    { lat: 48.7886157, lng: 19.1210003 },
    { lat: 48.7887938, lng: 19.1209076 },
    { lat: 48.7889183, lng: 19.1209175 },
    { lat: 48.7891756, lng: 19.1206281 },
    { lat: 48.7893275, lng: 19.1206238 },
    { lat: 48.7895283, lng: 19.1203991 },
    { lat: 48.7898302, lng: 19.1203038 },
    { lat: 48.790021, lng: 19.1205114 },
    { lat: 48.7903396, lng: 19.1205736 },
    { lat: 48.7904352, lng: 19.1204756 },
    { lat: 48.7908088, lng: 19.120512 },
    { lat: 48.791385, lng: 19.1208372 },
    { lat: 48.7917904, lng: 19.1208288 },
    { lat: 48.7921668, lng: 19.1207355 },
    { lat: 48.7924957, lng: 19.1208656 },
    { lat: 48.7925966, lng: 19.1210144 },
    { lat: 48.7928074, lng: 19.1210831 },
    { lat: 48.7931351, lng: 19.1213295 },
    { lat: 48.7932892, lng: 19.1212967 },
    { lat: 48.7935698, lng: 19.121684 },
    { lat: 48.7937004, lng: 19.1217268 },
    { lat: 48.7938446, lng: 19.1216322 },
    { lat: 48.7941784, lng: 19.1215894 },
    { lat: 48.7943118, lng: 19.1216423 },
    { lat: 48.7944593, lng: 19.1215709 },
    { lat: 48.7947839, lng: 19.1217308 },
    { lat: 48.7952192, lng: 19.1216612 },
    { lat: 48.7954837, lng: 19.1217038 },
    { lat: 48.7962133, lng: 19.1214093 },
    { lat: 48.7964833, lng: 19.121698 },
    { lat: 48.7967663, lng: 19.1216485 },
    { lat: 48.7970115, lng: 19.1217795 },
    { lat: 48.7973082, lng: 19.121688 },
    { lat: 48.7976914, lng: 19.121665 },
    { lat: 48.7983588, lng: 19.1218477 },
    { lat: 48.7986487, lng: 19.1222418 },
    { lat: 48.7988094, lng: 19.1223313 },
    { lat: 48.7993871, lng: 19.1226528 },
    { lat: 48.799541, lng: 19.1228461 },
    { lat: 48.8002851, lng: 19.1239654 },
    { lat: 48.8003378, lng: 19.1243979 },
    { lat: 48.8010459, lng: 19.1267301 },
    { lat: 48.8012378, lng: 19.1272834 },
    { lat: 48.8014052, lng: 19.1275448 },
    { lat: 48.8023179, lng: 19.1282488 },
    { lat: 48.8029852, lng: 19.1286794 },
    { lat: 48.8029956, lng: 19.1282712 },
    { lat: 48.8032054, lng: 19.1281617 },
    { lat: 48.8033046, lng: 19.127981 },
    { lat: 48.80403, lng: 19.1279226 },
    { lat: 48.8036658, lng: 19.1273008 },
    { lat: 48.803013, lng: 19.1265699 },
    { lat: 48.8028272, lng: 19.1262527 },
    { lat: 48.8026773, lng: 19.1258662 },
    { lat: 48.8026387, lng: 19.1253135 },
    { lat: 48.8028166, lng: 19.1245524 },
    { lat: 48.8028329, lng: 19.1238114 },
    { lat: 48.8029325, lng: 19.1237046 },
    { lat: 48.8030851, lng: 19.1238519 },
    { lat: 48.8031243, lng: 19.1243449 },
    { lat: 48.8033338, lng: 19.1242768 },
    { lat: 48.8033184, lng: 19.1239853 },
    { lat: 48.8034136, lng: 19.1237943 },
    { lat: 48.8035555, lng: 19.1238791 },
    { lat: 48.8034956, lng: 19.1242206 },
    { lat: 48.8037175, lng: 19.1241455 },
    { lat: 48.8038399, lng: 19.1237976 },
    { lat: 48.8037525, lng: 19.1238064 },
    { lat: 48.8037414, lng: 19.123581 },
    { lat: 48.8039955, lng: 19.1235654 },
    { lat: 48.8040019, lng: 19.1230347 },
    { lat: 48.8039779, lng: 19.1229359 },
    { lat: 48.8036579, lng: 19.122484 },
    { lat: 48.8035507, lng: 19.1216797 },
    { lat: 48.8032069, lng: 19.1212476 },
    { lat: 48.8031315, lng: 19.1210466 },
    { lat: 48.8030686, lng: 19.1206868 },
    { lat: 48.8030869, lng: 19.1201862 },
    { lat: 48.8030676, lng: 19.1201151 },
    { lat: 48.8028227, lng: 19.1199311 },
    { lat: 48.802768, lng: 19.1198032 },
    { lat: 48.8027461, lng: 19.1192932 },
    { lat: 48.8026079, lng: 19.1184796 },
    { lat: 48.8023689, lng: 19.1182277 },
    { lat: 48.8019795, lng: 19.1180594 },
    { lat: 48.8015322, lng: 19.117746 },
    { lat: 48.8010962, lng: 19.1171538 },
    { lat: 48.8007539, lng: 19.1168007 },
    { lat: 48.8004495, lng: 19.1163424 },
    { lat: 48.7999812, lng: 19.1159438 },
    { lat: 48.7996506, lng: 19.1153447 },
    { lat: 48.7996266, lng: 19.1152259 },
    { lat: 48.7997155, lng: 19.1145843 },
    { lat: 48.7997784, lng: 19.1144649 },
    { lat: 48.8001461, lng: 19.114262 },
    { lat: 48.8005942, lng: 19.1142287 },
    { lat: 48.8008973, lng: 19.1137946 },
    { lat: 48.8010229, lng: 19.1125939 },
    { lat: 48.8012571, lng: 19.1114026 },
    { lat: 48.8012984, lng: 19.1107149 },
    { lat: 48.8014, lng: 19.1105089 },
    { lat: 48.8015678, lng: 19.1104834 },
    { lat: 48.8024134, lng: 19.1110116 },
    { lat: 48.8030976, lng: 19.1110996 },
    { lat: 48.8036728, lng: 19.1110598 },
    { lat: 48.8038188, lng: 19.1108641 },
    { lat: 48.804196, lng: 19.1107205 },
    { lat: 48.8038966, lng: 19.1094766 },
    { lat: 48.8040428, lng: 19.108742 },
    { lat: 48.8037715, lng: 19.1078855 },
    { lat: 48.8035303, lng: 19.105996 },
    { lat: 48.8035892, lng: 19.1052473 },
    { lat: 48.8038143, lng: 19.1044697 },
    { lat: 48.8040508, lng: 19.1029158 },
    { lat: 48.8042234, lng: 19.1024628 },
    { lat: 48.8042628, lng: 19.1012127 },
    { lat: 48.803639, lng: 19.0990885 },
    { lat: 48.8036507, lng: 19.0986659 },
    { lat: 48.8035784, lng: 19.0987084 },
    { lat: 48.8031367, lng: 19.0988237 },
    { lat: 48.8022268, lng: 19.0987776 },
    { lat: 48.8019248, lng: 19.0985987 },
    { lat: 48.8013914, lng: 19.0985224 },
    { lat: 48.8008265, lng: 19.0986595 },
    { lat: 48.8004993, lng: 19.0986298 },
    { lat: 48.8002801, lng: 19.0985742 },
    { lat: 48.8001146, lng: 19.0984171 },
    { lat: 48.7995191, lng: 19.0981425 },
    { lat: 48.7987603, lng: 19.0982756 },
    { lat: 48.7982741, lng: 19.0986083 },
    { lat: 48.7979773, lng: 19.0987146 },
    { lat: 48.7975821, lng: 19.0992247 },
    { lat: 48.7971458, lng: 19.0994531 },
    { lat: 48.7962303, lng: 19.099566 },
    { lat: 48.7957801, lng: 19.099444 },
    { lat: 48.7953229, lng: 19.0994316 },
    { lat: 48.7948544, lng: 19.0991684 },
    { lat: 48.7946367, lng: 19.0991888 },
    { lat: 48.7943569, lng: 19.0995449 },
    { lat: 48.7937538, lng: 19.0995015 },
    { lat: 48.7929446, lng: 19.1000518 },
    { lat: 48.7927109, lng: 19.1003837 },
    { lat: 48.7926201, lng: 19.1007157 },
    { lat: 48.7923799, lng: 19.1009175 },
    { lat: 48.7921136, lng: 19.1010017 },
    { lat: 48.7917513, lng: 19.1012649 },
    { lat: 48.7914191, lng: 19.1016151 },
    { lat: 48.7910806, lng: 19.1018362 },
    { lat: 48.7905777, lng: 19.102426 },
    { lat: 48.7905073, lng: 19.1024176 },
    { lat: 48.7905462, lng: 19.1023588 },
    { lat: 48.7901892, lng: 19.1018909 },
    { lat: 48.7901916, lng: 19.1016186 },
    { lat: 48.7899218, lng: 19.1015416 },
    { lat: 48.7898421, lng: 19.1011395 },
    { lat: 48.7897083, lng: 19.100804 },
    { lat: 48.7894868, lng: 19.1005628 },
    { lat: 48.7893296, lng: 19.1006653 },
    { lat: 48.7891777, lng: 19.1003315 },
    { lat: 48.7890023, lng: 19.0993861 },
    { lat: 48.7888856, lng: 19.0993286 },
    { lat: 48.7882285, lng: 19.0985123 },
    { lat: 48.7863999, lng: 19.0939899 },
    { lat: 48.7864821, lng: 19.0939176 },
    { lat: 48.7865242, lng: 19.0935396 },
    { lat: 48.7867368, lng: 19.0931912 },
    { lat: 48.7868018, lng: 19.0931827 },
    { lat: 48.7868796, lng: 19.0929801 },
    { lat: 48.7868073, lng: 19.0925222 },
    { lat: 48.7867274, lng: 19.0923654 },
    { lat: 48.7867068, lng: 19.0904994 },
    { lat: 48.7865802, lng: 19.0885629 },
    { lat: 48.7865627, lng: 19.0870988 },
    { lat: 48.7865122, lng: 19.0864925 },
    { lat: 48.7862851, lng: 19.085957 },
    { lat: 48.7856259, lng: 19.0838545 },
    { lat: 48.7854404, lng: 19.0831188 },
    { lat: 48.785249, lng: 19.0826237 },
    { lat: 48.7850622, lng: 19.0822242 },
    { lat: 48.7847119, lng: 19.0816853 },
    { lat: 48.7842384, lng: 19.081321 },
    { lat: 48.7835311, lng: 19.0768983 },
    { lat: 48.7831476, lng: 19.0763926 },
    { lat: 48.7832217, lng: 19.0753238 },
    { lat: 48.7832017, lng: 19.0744721 },
    { lat: 48.7834971, lng: 19.0743558 },
    { lat: 48.7836377, lng: 19.0739683 },
    { lat: 48.783567, lng: 19.0736761 },
    { lat: 48.78369, lng: 19.0734326 },
    { lat: 48.7836001, lng: 19.0732544 },
    { lat: 48.7834378, lng: 19.0733698 },
    { lat: 48.7831291, lng: 19.0734404 },
    { lat: 48.7828451, lng: 19.0733309 },
    { lat: 48.7828257, lng: 19.0737571 },
    { lat: 48.7824432, lng: 19.0737946 },
    { lat: 48.7822324, lng: 19.0734791 },
    { lat: 48.7822021, lng: 19.0735265 },
    { lat: 48.7821117, lng: 19.0733624 },
    { lat: 48.7818963, lng: 19.0732489 },
    { lat: 48.7818487, lng: 19.0729487 },
    { lat: 48.7815515, lng: 19.0726391 },
    { lat: 48.7809101, lng: 19.0724406 },
    { lat: 48.780778, lng: 19.0725479 },
    { lat: 48.780625, lng: 19.0724642 },
    { lat: 48.7805505, lng: 19.0721213 },
    { lat: 48.7802659, lng: 19.0719096 },
    { lat: 48.7799574, lng: 19.0718955 },
    { lat: 48.7794599, lng: 19.0715687 },
    { lat: 48.77931, lng: 19.0716608 },
    { lat: 48.7790487, lng: 19.0714817 },
    { lat: 48.7787484, lng: 19.0712759 },
    { lat: 48.7786521, lng: 19.0711846 },
    { lat: 48.7780457, lng: 19.0706098 },
    { lat: 48.7778142, lng: 19.0702873 },
    { lat: 48.7774899, lng: 19.0701134 },
    { lat: 48.7767991, lng: 19.0694856 },
  ],
  Šalková: [
    { lat: 48.7397329, lng: 19.1883082 },
    { lat: 48.7390467, lng: 19.1852478 },
    { lat: 48.7385992, lng: 19.1859839 },
    { lat: 48.7381145, lng: 19.1859847 },
    { lat: 48.7376456, lng: 19.1858013 },
    { lat: 48.737212, lng: 19.1854465 },
    { lat: 48.7368463, lng: 19.1852735 },
    { lat: 48.7363788, lng: 19.1852878 },
    { lat: 48.736178, lng: 19.1853614 },
    { lat: 48.7359873, lng: 19.1854877 },
    { lat: 48.7358316, lng: 19.1856195 },
    { lat: 48.7351868, lng: 19.1863145 },
    { lat: 48.7351192, lng: 19.186542 },
    { lat: 48.7350426, lng: 19.1864955 },
    { lat: 48.7334458, lng: 19.1862909 },
    { lat: 48.7342888, lng: 19.1877637 },
    { lat: 48.734715, lng: 19.1887117 },
    { lat: 48.7350309, lng: 19.1902183 },
    { lat: 48.7337354, lng: 19.1905361 },
    { lat: 48.7335584, lng: 19.1907901 },
    { lat: 48.7328947, lng: 19.1910955 },
    { lat: 48.7322096, lng: 19.1916319 },
    { lat: 48.7316571, lng: 19.191811 },
    { lat: 48.7313604, lng: 19.1920612 },
    { lat: 48.7310638, lng: 19.1921989 },
    { lat: 48.7306163, lng: 19.1925195 },
    { lat: 48.7302927, lng: 19.1928206 },
    { lat: 48.7296684, lng: 19.1929616 },
    { lat: 48.7289147, lng: 19.1932717 },
    { lat: 48.7276669, lng: 19.1943763 },
    { lat: 48.7272733, lng: 19.1943509 },
    { lat: 48.7269432, lng: 19.1938272 },
    { lat: 48.7257637, lng: 19.1937913 },
    { lat: 48.7254241, lng: 19.1944354 },
    { lat: 48.7245357, lng: 19.194522 },
    { lat: 48.7240665, lng: 19.1945407 },
    { lat: 48.7238367, lng: 19.1955149 },
    { lat: 48.7236816, lng: 19.1958692 },
    { lat: 48.7234995, lng: 19.1965953 },
    { lat: 48.723117, lng: 19.1968886 },
    { lat: 48.7224813, lng: 19.1987859 },
    { lat: 48.7222429, lng: 19.1998606 },
    { lat: 48.7221884, lng: 19.2005018 },
    { lat: 48.7224258, lng: 19.2012279 },
    { lat: 48.722288, lng: 19.2024531 },
    { lat: 48.7227599, lng: 19.2030903 },
    { lat: 48.723955, lng: 19.2034863 },
    { lat: 48.7245217, lng: 19.2038988 },
    { lat: 48.7251804, lng: 19.2048765 },
    { lat: 48.7254931, lng: 19.2056279 },
    { lat: 48.7259249, lng: 19.2058851 },
    { lat: 48.7268802, lng: 19.2067727 },
    { lat: 48.7277659, lng: 19.2068595 },
    { lat: 48.7281144, lng: 19.2071421 },
    { lat: 48.7283442, lng: 19.2075774 },
    { lat: 48.728974, lng: 19.2081957 },
    { lat: 48.729366, lng: 19.2083771 },
    { lat: 48.7296491, lng: 19.208749 },
    { lat: 48.7291979, lng: 19.2093452 },
    { lat: 48.7289979, lng: 19.2093845 },
    { lat: 48.7286866, lng: 19.2104189 },
    { lat: 48.728662, lng: 19.210934 },
    { lat: 48.7282944, lng: 19.2115822 },
    { lat: 48.728635, lng: 19.212699 },
    { lat: 48.7285217, lng: 19.2128265 },
    { lat: 48.7282627, lng: 19.2133873 },
    { lat: 48.7281214, lng: 19.2140714 },
    { lat: 48.728079, lng: 19.2154725 },
    { lat: 48.7282755, lng: 19.2161283 },
    { lat: 48.7287763, lng: 19.2172263 },
    { lat: 48.7288644, lng: 19.2180191 },
    { lat: 48.7287321, lng: 19.2185865 },
    { lat: 48.7288469, lng: 19.2205311 },
    { lat: 48.7284358, lng: 19.2223537 },
    { lat: 48.728546, lng: 19.2230669 },
    { lat: 48.7283703, lng: 19.2236937 },
    { lat: 48.7288165, lng: 19.2251049 },
    { lat: 48.7288536, lng: 19.2255481 },
    { lat: 48.7287059, lng: 19.226963 },
    { lat: 48.7284482, lng: 19.2275036 },
    { lat: 48.728094, lng: 19.2286843 },
    { lat: 48.7277591, lng: 19.2298443 },
    { lat: 48.7277137, lng: 19.230307 },
    { lat: 48.7268909, lng: 19.2311169 },
    { lat: 48.7262635, lng: 19.2330111 },
    { lat: 48.7261825, lng: 19.2337127 },
    { lat: 48.7261763, lng: 19.234215 },
    { lat: 48.7262335, lng: 19.234883 },
    { lat: 48.7266063, lng: 19.2362684 },
    { lat: 48.7269709, lng: 19.237096 },
    { lat: 48.7271538, lng: 19.2376395 },
    { lat: 48.7273772, lng: 19.2380139 },
    { lat: 48.7272982, lng: 19.2381048 },
    { lat: 48.7270316, lng: 19.2390036 },
    { lat: 48.7270417, lng: 19.2399772 },
    { lat: 48.7267731, lng: 19.2404839 },
    { lat: 48.7263267, lng: 19.2407378 },
    { lat: 48.7261318, lng: 19.2406926 },
    { lat: 48.7256386, lng: 19.2413562 },
    { lat: 48.725196, lng: 19.2421338 },
    { lat: 48.724804, lng: 19.2424195 },
    { lat: 48.7249676, lng: 19.2425385 },
    { lat: 48.7251032, lng: 19.2430073 },
    { lat: 48.7253985, lng: 19.2432678 },
    { lat: 48.7255234, lng: 19.2435649 },
    { lat: 48.7257594, lng: 19.2438537 },
    { lat: 48.726046, lng: 19.2440891 },
    { lat: 48.7262774, lng: 19.2445669 },
    { lat: 48.7265891, lng: 19.245018 },
    { lat: 48.7268309, lng: 19.2454754 },
    { lat: 48.7278242, lng: 19.2467384 },
    { lat: 48.7286785, lng: 19.2469478 },
    { lat: 48.7291105, lng: 19.2469523 },
    { lat: 48.7294328, lng: 19.2468563 },
    { lat: 48.7300046, lng: 19.2468181 },
    { lat: 48.7303126, lng: 19.2474093 },
    { lat: 48.7309847, lng: 19.2475778 },
    { lat: 48.7311984, lng: 19.2477067 },
    { lat: 48.7317857, lng: 19.2474744 },
    { lat: 48.731867, lng: 19.2474761 },
    { lat: 48.7319185, lng: 19.2475836 },
    { lat: 48.7322034, lng: 19.2475796 },
    { lat: 48.7322091, lng: 19.2480246 },
    { lat: 48.7323248, lng: 19.2490223 },
    { lat: 48.7322954, lng: 19.2499046 },
    { lat: 48.7324437, lng: 19.2504306 },
    { lat: 48.7326417, lng: 19.2507019 },
    { lat: 48.7328898, lng: 19.2513329 },
    { lat: 48.7327705, lng: 19.2526193 },
    { lat: 48.7326407, lng: 19.2532714 },
    { lat: 48.7327564, lng: 19.2536824 },
    { lat: 48.7328126, lng: 19.2544246 },
    { lat: 48.7332349, lng: 19.2552741 },
    { lat: 48.7332656, lng: 19.2553358 },
    { lat: 48.7335233, lng: 19.2555609 },
    { lat: 48.7342482, lng: 19.2557031 },
    { lat: 48.7347023, lng: 19.2558645 },
    { lat: 48.7347666, lng: 19.2558873 },
    { lat: 48.7351571, lng: 19.2558205 },
    { lat: 48.7351904, lng: 19.2558148 },
    { lat: 48.7354243, lng: 19.2560847 },
    { lat: 48.7354501, lng: 19.2560383 },
    { lat: 48.73579, lng: 19.2567042 },
    { lat: 48.7359535, lng: 19.2567733 },
    { lat: 48.7361975, lng: 19.2573216 },
    { lat: 48.7361894, lng: 19.2577614 },
    { lat: 48.7364337, lng: 19.2578012 },
    { lat: 48.7369929, lng: 19.257666 },
    { lat: 48.7374867, lng: 19.2577137 },
    { lat: 48.7377497, lng: 19.258166 },
    { lat: 48.7379561, lng: 19.2583561 },
    { lat: 48.7383005, lng: 19.2589302 },
    { lat: 48.7386371, lng: 19.2590131 },
    { lat: 48.7391296, lng: 19.2595737 },
    { lat: 48.7392439, lng: 19.2595706 },
    { lat: 48.7412684, lng: 19.259515 },
    { lat: 48.7418664, lng: 19.2616276 },
    { lat: 48.7420271, lng: 19.2621956 },
    { lat: 48.7422358, lng: 19.2629328 },
    { lat: 48.7422373, lng: 19.2654004 },
    { lat: 48.7422386, lng: 19.2654277 },
    { lat: 48.7423814, lng: 19.2655584 },
    { lat: 48.7425596, lng: 19.2656095 },
    { lat: 48.7430884, lng: 19.2655888 },
    { lat: 48.7433039, lng: 19.2652168 },
    { lat: 48.7435803, lng: 19.2650821 },
    { lat: 48.7436244, lng: 19.2649433 },
    { lat: 48.7438729, lng: 19.2646306 },
    { lat: 48.7443196, lng: 19.2646121 },
    { lat: 48.7443912, lng: 19.2642042 },
    { lat: 48.7445168, lng: 19.2640279 },
    { lat: 48.744667, lng: 19.2635671 },
    { lat: 48.7446479, lng: 19.2626992 },
    { lat: 48.7448235, lng: 19.2624627 },
    { lat: 48.7448242, lng: 19.2621037 },
    { lat: 48.7447576, lng: 19.2618439 },
    { lat: 48.7448146, lng: 19.2615512 },
    { lat: 48.7449091, lng: 19.2613604 },
    { lat: 48.7451238, lng: 19.2611171 },
    { lat: 48.7450737, lng: 19.2606355 },
    { lat: 48.7452508, lng: 19.2600327 },
    { lat: 48.7454251, lng: 19.2598853 },
    { lat: 48.7453899, lng: 19.2596695 },
    { lat: 48.7454344, lng: 19.25928 },
    { lat: 48.7456172, lng: 19.259123 },
    { lat: 48.7455769, lng: 19.2585232 },
    { lat: 48.7457883, lng: 19.2579738 },
    { lat: 48.7457804, lng: 19.2578782 },
    { lat: 48.745676, lng: 19.2577462 },
    { lat: 48.7456816, lng: 19.2574902 },
    { lat: 48.7455217, lng: 19.2573525 },
    { lat: 48.745461, lng: 19.2570738 },
    { lat: 48.7456392, lng: 19.2567731 },
    { lat: 48.7456358, lng: 19.2566842 },
    { lat: 48.7454458, lng: 19.256623 },
    { lat: 48.7454085, lng: 19.2564571 },
    { lat: 48.7454462, lng: 19.2563472 },
    { lat: 48.7455936, lng: 19.2563228 },
    { lat: 48.7455637, lng: 19.2560739 },
    { lat: 48.7456409, lng: 19.2556868 },
    { lat: 48.7456496, lng: 19.2552362 },
    { lat: 48.7455247, lng: 19.2549382 },
    { lat: 48.7455444, lng: 19.2548828 },
    { lat: 48.7457066, lng: 19.254856 },
    { lat: 48.7456651, lng: 19.2545309 },
    { lat: 48.7459473, lng: 19.2539126 },
    { lat: 48.7459575, lng: 19.2537233 },
    { lat: 48.7460716, lng: 19.2534982 },
    { lat: 48.7459303, lng: 19.2534423 },
    { lat: 48.7458878, lng: 19.2532867 },
    { lat: 48.7459484, lng: 19.2522271 },
    { lat: 48.745027, lng: 19.248277 },
    { lat: 48.7446407, lng: 19.2469304 },
    { lat: 48.7441461, lng: 19.2457679 },
    { lat: 48.7435021, lng: 19.2436409 },
    { lat: 48.7430424, lng: 19.2416607 },
    { lat: 48.742853, lng: 19.2405206 },
    { lat: 48.7428431, lng: 19.2396853 },
    { lat: 48.7429716, lng: 19.2389879 },
    { lat: 48.7433602, lng: 19.2380042 },
    { lat: 48.7443326, lng: 19.2358562 },
    { lat: 48.7446424, lng: 19.2349911 },
    { lat: 48.7448365, lng: 19.2340153 },
    { lat: 48.7449519, lng: 19.2330483 },
    { lat: 48.7445917, lng: 19.2318046 },
    { lat: 48.7441997, lng: 19.2310184 },
    { lat: 48.7443851, lng: 19.2306557 },
    { lat: 48.7447385, lng: 19.229624 },
    { lat: 48.746951, lng: 19.2269217 },
    { lat: 48.7470213, lng: 19.2269775 },
    { lat: 48.7471929, lng: 19.2269617 },
    { lat: 48.7478192, lng: 19.2264869 },
    { lat: 48.7479982, lng: 19.2262284 },
    { lat: 48.7524352, lng: 19.223354 },
    { lat: 48.7552496, lng: 19.222395 },
    { lat: 48.7579077, lng: 19.2215411 },
    { lat: 48.7577422, lng: 19.2167856 },
    { lat: 48.7574275, lng: 19.2166434 },
    { lat: 48.7572624, lng: 19.2167503 },
    { lat: 48.7571017, lng: 19.2169672 },
    { lat: 48.7568982, lng: 19.2167962 },
    { lat: 48.7564821, lng: 19.2167881 },
    { lat: 48.7557979, lng: 19.2169731 },
    { lat: 48.7554736, lng: 19.216589 },
    { lat: 48.7552409, lng: 19.2167406 },
    { lat: 48.7549969, lng: 19.2165656 },
    { lat: 48.7546996, lng: 19.21648 },
    { lat: 48.7542422, lng: 19.2159279 },
    { lat: 48.7537513, lng: 19.2155666 },
    { lat: 48.7533761, lng: 19.2154079 },
    { lat: 48.7525093, lng: 19.2152496 },
    { lat: 48.7519976, lng: 19.2148546 },
    { lat: 48.7517658, lng: 19.2143774 },
    { lat: 48.75126, lng: 19.2141268 },
    { lat: 48.7511065, lng: 19.2134513 },
    { lat: 48.750907, lng: 19.2133574 },
    { lat: 48.7507712, lng: 19.2131135 },
    { lat: 48.7500172, lng: 19.2124922 },
    { lat: 48.7493217, lng: 19.2127471 },
    { lat: 48.748815, lng: 19.2123873 },
    { lat: 48.7486986, lng: 19.2123596 },
    { lat: 48.7484159, lng: 19.2125242 },
    { lat: 48.7480179, lng: 19.2123845 },
    { lat: 48.747888, lng: 19.2121715 },
    { lat: 48.7475859, lng: 19.2121901 },
    { lat: 48.7475737, lng: 19.2122646 },
    { lat: 48.7474163, lng: 19.2123204 },
    { lat: 48.7472996, lng: 19.2122626 },
    { lat: 48.7471853, lng: 19.2120692 },
    { lat: 48.7469466, lng: 19.211934 },
    { lat: 48.7467028, lng: 19.2119047 },
    { lat: 48.7465019, lng: 19.2119902 },
    { lat: 48.7463122, lng: 19.2116824 },
    { lat: 48.746217, lng: 19.2116973 },
    { lat: 48.7460173, lng: 19.2115545 },
    { lat: 48.7458293, lng: 19.2115793 },
    { lat: 48.7455019, lng: 19.2114521 },
    { lat: 48.745332, lng: 19.211621 },
    { lat: 48.7451239, lng: 19.211587 },
    { lat: 48.7450225, lng: 19.2113336 },
    { lat: 48.7446105, lng: 19.2117163 },
    { lat: 48.7445262, lng: 19.2117217 },
    { lat: 48.7444882, lng: 19.2115609 },
    { lat: 48.7441256, lng: 19.2114784 },
    { lat: 48.7439817, lng: 19.2116048 },
    { lat: 48.7437512, lng: 19.2120079 },
    { lat: 48.7436453, lng: 19.2120118 },
    { lat: 48.7435255, lng: 19.2121166 },
    { lat: 48.7434647, lng: 19.2123291 },
    { lat: 48.743333, lng: 19.2123123 },
    { lat: 48.7430008, lng: 19.2125238 },
    { lat: 48.7428674, lng: 19.2110812 },
    { lat: 48.7426567, lng: 19.2110582 },
    { lat: 48.7425549, lng: 19.2100524 },
    { lat: 48.7407106, lng: 19.2107225 },
    { lat: 48.7404307, lng: 19.2075677 },
    { lat: 48.7404726, lng: 19.205856 },
    { lat: 48.7405866, lng: 19.2047737 },
    { lat: 48.7406663, lng: 19.2046248 },
    { lat: 48.7406263, lng: 19.2037797 },
    { lat: 48.7408251, lng: 19.2030258 },
    { lat: 48.7410079, lng: 19.2020376 },
    { lat: 48.7411411, lng: 19.2020174 },
    { lat: 48.7411229, lng: 19.2014359 },
    { lat: 48.7414672, lng: 19.2008186 },
    { lat: 48.7416937, lng: 19.199708 },
    { lat: 48.7418072, lng: 19.1986575 },
    { lat: 48.7418147, lng: 19.1981917 },
    { lat: 48.7416837, lng: 19.196842 },
    { lat: 48.7414431, lng: 19.1956524 },
    { lat: 48.74089, lng: 19.1935406 },
    { lat: 48.7406755, lng: 19.1922621 },
    { lat: 48.7397329, lng: 19.1883082 },
  ],
};

export default UnitsBanskáBystrica;
