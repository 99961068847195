import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { FinInstitution } from "../../data/FinInstitution";
import FIPreview from "./FIPreview";

export default function FinInstitutions({
  size,
  setHighlight,
  highlight,
  map,
  setSubjectsMenu,
  subjectsMenu,
  indx,
  subMenu,
  setSubMenu,
  setForSale,
  forSale,
  navSmall,
  setNavSmall,
  viewSale,
  setViewSale,
  indxPrev,
  subjects,
  setSubjects,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const highlightPin = (index) => {
    if (index !== highlight.index) setHighlight({ ...highlight, finIn: true, index: index });
    map.panTo({
      lat: Number(FinInstitution[index]?.lat),
      lng: Number(FinInstitution[index]?.lng),
    });
  };

  return (
    <>
      {subjectsMenu.FI ? (
        <FIPreview
          subMenu={subMenu}
          setSubMenu={setSubMenu}
          size={size}
          indx={indx}
          subjectsMenu={subjectsMenu}
          setForSale={setForSale}
          forSale={forSale}
          navSmall={navSmall}
          setNavSmall={setNavSmall}
          viewSale={viewSale}
          setViewSale={setViewSale}
          indxPrev={indxPrev}
          setSubjectsMenu={setSubjectsMenu}
          subjects={subjects}
          setSubjects={setSubjects}
        />
      ) : (
        <div
          className={clsx("flex flex-col", {
            "grid sm:grid-cols-2": !isSmallDevice && size.full,
          })}
        >
          {FinInstitution?.map((office, index) => (
            <div
              key={index}
              className={clsx(
                "transition-all duration-500 ease-linear min-h-max w-full flex-col justify-center sm:flex overflow-ellipsis p-2",
                {
                  //   hidden: !extended,
                  //   "w-full px-2": isSmallDevice,
                },
              )}
            >
              <div
                // ref={isSmallDevice ? (indexCur.current === index ? indx : null) : null}
                onClick={() => {
                  setSubjectsMenu({ ...subjectsMenu, REO: false, FI: true, FIB: false, REB: false });
                  indx.current = index;
                }}
                onMouseOver={() => highlightPin(index)}
                onMouseLeave={() => setHighlight({ ...highlight, finIn: false, index: null })}
                className={clsx(
                  "w-full border border-opacity-50 border-sky-500 h-auto flex flex-col gap-2 cursor-pointer hover:bg-sky-300 rounded-lg shadow-lg shadow-sky-200 text-xs",
                  {
                    // "bg-sky-100 rounded-3xl": extended,
                    // "text-xs": isSmallDevice,
                    // "items-center justify-center": !extended,
                  },
                )}
              >
                <div
                  className={clsx("flex flex-row w-full gap-x-2 items-center", {
                    // "flex flex-row w-full gap-x-2 items-center": extended,
                  })}
                >
                  {/* {icon(index)} */}
                  <img src={office?.img} alt="" className="w-1/3 h-24 rounded-l-lg object-cover" />
                  <div className={clsx("w-2/3 flex justify-between", {})}>
                    <div className="flex flex-row w-full h-24 p-[2px] items-start justify-between ">
                      <div className="flex flex-col w-2/3 gap-x-2 gap-y-[2px]">
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden">
                          {office?.brokerName}
                        </p>
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden">
                          {office?.name}
                        </p>
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden">{office?.tel}</p>
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden">
                          {office?.email}
                        </p>
                        <p className="h-auto flex flex-row justify-start items-center overflow-hidden gap-1 truncate">
                          {office?.street} {office?.number}, {office?.psc} {office?.city}
                        </p>
                      </div>
                      <div className="w-1/3 h-full flex justify-end items-end text-xl font-semibold">
                        {/* <p>Trhová cena: {office?.name} €</p> */}
                        <img src={office?.url} alt="" className="w-20 h-10 rounded-l-lg object-contain" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
