export const forSaleService = {
  create: async (request) => {
    return await fetch(process.env.REACT_APP_FOR_SALE_SERVICE + `create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  },

  getAll: async () => {
    return await fetch(process.env.REACT_APP_FOR_SALE_SERVICE + `getAll`);
  },

  getMyProperties: async (request) => {
    return await fetch(process.env.REACT_APP_FOR_SALE_SERVICE + `getMyProperties`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  },

  editProperty: async (request) => {
    return await fetch(process.env.REACT_APP_FOR_SALE_SERVICE + `editProperty`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  },
};
