import { motion } from "framer-motion";
import description from "../../../Images/description.png";

export default function Description({ inputValues, setInputValues }) {
  return (
    <motion.div onClick={(e) => e.stopPropagation()} className="w-full h-auto flex flex-col items-start pt-1">
      <p className="font-semibold flex flex-row items-center gap-1">
        <img src={description} alt="" className="w-5 h-5" />
        Popis
      </p>
      <div className="flex flex-row items-center w-full h-80 py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="flex flex-row gap-1 w-full h-full items-center">
          <textarea
            value={inputValues?.description || ""}
            onChange={(e) => setInputValues({ ...inputValues, description: e.target.value })}
            type="text"
            // cols={20}
            rows={6}
            placeholder="Popis..."
            className="w-full h-full rounded-lg bg-sky-100 placeholder:text-gray-500 placeholder:font-normal border border-opacity-50 border-sky-500"
          />
        </div>
      </div>
    </motion.div>
  );
}
