import { useState } from "react";
import { FinBroker } from "../../data/FinBroker";
import SubjectsHeader from "./SubjectsHeader";
import SubjectsScrollMenu from "./SubjectsScrollMenu";
import AboutUs from "./AboutUs";
import Properties from "./Properties";
import Partners from "./Partners";

export default function FIBPreview({
  subMenu,
  setSubMenu,
  size,
  indx,
  subjectsMenu,
  setForSale,
  forSale,
  navSmall,
  setNavSmall,
  viewSale,
  setViewSale,
  indxPrev,
  setSubjectsMenu,
  subjects,
  setSubjects,
}) {
  const [previous, setPrevious] = useState("");

  const showMenu = (menu) => {
    if (subMenu.lv) setPrevious("lv");
    if (subMenu.interest) setPrevious("in");
    if (subMenu.owner) setPrevious("ow");
    if (subMenu.rating) setPrevious("ra");
    if (subMenu.share) setPrevious("sh");

    if (menu === "lv") {
      setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, rating: false, share: false });
    }
    if (menu === "in") {
      setSubMenu({ ...subMenu, lv: false, interest: true, owner: false, rating: false, share: false });
    }
    if (menu === "ow") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
    }
    if (menu === "ra") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: true, share: false });
    }
    if (menu === "sh") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: false, share: true });
    }
  };
  return (
    <div className="w-full h-full">
      <SubjectsHeader office={FinBroker[indx.current]} />
      <SubjectsScrollMenu size={size} subMenu={subMenu} showMenu={showMenu} subjectsMenu={subjectsMenu} />
      <AboutUs subMenu={subMenu} previous={previous} office={FinBroker[indx.current]} />
      <Partners
        office={FinBroker[indx.current]}
        size={size}
        subMenu={subMenu}
        previous={previous}
        setSubMenu={setSubMenu}
        subjects={subjects}
        setSubjects={setSubjects}
        setSubjectsMenu={setSubjectsMenu}
        subjectsMenu={subjectsMenu}
        indx={indx}
        indxPrev={indxPrev}
      />
      <Properties
        office={FinBroker[indx.current]}
        size={size}
        subMenu={subMenu}
        previous={previous}
        setSubMenu={setSubMenu}
        setForSale={setForSale}
        forSale={forSale}
        navSmall={navSmall}
        setNavSmall={setNavSmall}
        viewSale={viewSale}
        setViewSale={setViewSale}
        indx={indx}
        indxPrev={indxPrev}
      />
    </div>
  );
}
