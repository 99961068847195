import { React } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuro, faGlobe, faSatellite, faCalculator } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import { averagePriceService } from "../../services/averagePriceService";

const MapControl = ({
  view,
  setView,
  setPriceMap,
  priceMap,
  setHideMarkers,
  hideMarkers,
  map,
  setZoom,
  setGenAvgPrice,
  setPMResult,
  PMResult,
  setClickedReg,
  setMapActive,
  mapActive,
}) => {
  // const [pick, setPick] = useState(false);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  function chngeViewToRoad() {
    setView("roadmap");
  }

  function chngeViewToSatellite() {
    setView("hybrid");
  }

  const getAvgPriceRegion = async (key) => {
    // console.log("key = ", key);
    const parent = key;
    const request = await averagePriceService.getGeneralAvgPrice(decodeURIComponent(parent));
    const response = await request.json();
    // console.log("mapControl response = ", response);
    setGenAvgPrice(response);
  };

  return (
    <div className="absolute bottom-6 left-2 sm:left-20 sm:bottom-2 w-min h-min flex flex-row gap-2 select-none">
      <button
        className={`flex flex-row justify-center items-center gap-1 font-sans h-8 ${
          view === "hybrid" ? " bg-white" : "bg-sky-100"
        } hover:bg-sky-200  p-1 rounded-lg`}
        onClick={chngeViewToRoad}
      >
        <FontAwesomeIcon icon={faGlobe} />
        Mapa
      </button>
      <button
        className={`flex flex-row justify-center items-center gap-1 font-sans h-8 ${
          view === "hybrid" ? " bg-sky-100" : "bg-white"
        } hover:bg-sky-200 p-1 rounded-lg`}
        onClick={chngeViewToSatellite}
      >
        <FontAwesomeIcon icon={faSatellite} />
        Satelit
      </button>
      <button
        className={clsx(
          "flex flex-row w-32 justify-center items-center gap-1 font-sans h-8 hover:bg-sky-200 p-1 rounded-lg",
          {
            "bg-sky-100": priceMap,
            "bg-white": !priceMap,
          },
        )}
        // onMouseOver={() => setPick(true)}
        onClick={() => {
          setClickedReg(false);
          setHideMarkers({ ...hideMarkers, REO: true, history: true, house: true, flat: true, FI: true });
          setPriceMap({
            ...priceMap,
            region: !priceMap.region,
            district: false,
            city: false,
            districtName: "",
            cityName: "",
          });
          map.panTo({ lat: 48.6667, lng: 18.5 });
          setZoom(8);
          setPMResult({ ...PMResult, region: "" });
          getAvgPriceRegion("");
        }}
      >
        <FontAwesomeIcon icon={faEuro} />
        Cenová mapa
      </button>
      <button
        className={clsx(
          "flex flex-row w-32 justify-center items-center gap-1 font-sans h-8 hover:bg-sky-200 p-1 rounded-lg bg-white",
          {
            "w-8": isSmallDevice,
            "w-32": !isSmallDevice,
          },
        )}
        onClick={() => {
          if (mapActive) {
            setMapActive(false);
          } else setMapActive(true);
        }}
      >
        <FontAwesomeIcon icon={faCalculator} /> {isSmallDevice ? "" : "Kalkulačka"}
      </button>
      {/* {pick ? (
        <div
          className="absolute -top-20 right-0 w-32 h-28  rounded-lg flex flex-col gap-1"
          onMouseLeave={() => setPick(false)}
        >
          <button
            className="w-full h-[30%] bg-blue-100 hover:bg-blue-200 rounded-lg"
            onClick={() => {
              setHideMarkers({ ...hideMarkers, REO: true, history: true, house: true, flat: true, FI: true });
              setPriceMap({ ...priceMap, region: !priceMap.region, district: false, city: false });
              setPick(false);
            }}
          >
            Kraj
          </button>
          <button
            className="w-full h-[30%] bg-yellow-100 hover:bg-yellow-200 rounded-lg"
            onClick={() => {
              if (priceMap.region) {
                setPriceMap({ ...priceMap, region: false, district: true, city: false });
              } else {
                setPriceMap({ ...priceMap, region: false, district: !priceMap.district, city: false });
              }
              setPick(false);
            }}
          >
            Okres
          </button>
          <button
            className="w-full h-[23%] bg-green-100 hover:bg-green-200 rounded-lg"
            onClick={() => {
              setPriceMap({ ...priceMap, region: false, district: false, city: !priceMap.city });
              setPick(false);
            }}
          >
            Mesto
          </button>
        </div>
      ) : null} */}
    </div>
  );
};

export default MapControl;
