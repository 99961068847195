const UnitsSobrance = {
  VyšnáRybnica: [
    { lat: 48.93013, lng: 22.199606 },
    { lat: 48.9293868, lng: 22.1993782 },
    { lat: 48.928427, lng: 22.1996547 },
    { lat: 48.927402, lng: 22.1992392 },
    { lat: 48.9268101, lng: 22.1990809 },
    { lat: 48.9261828, lng: 22.199345 },
    { lat: 48.9257072, lng: 22.1993466 },
    { lat: 48.9243995, lng: 22.1992653 },
    { lat: 48.9228518, lng: 22.1990599 },
    { lat: 48.9225368, lng: 22.1991589 },
    { lat: 48.9222789, lng: 22.1990425 },
    { lat: 48.9219223, lng: 22.1993444 },
    { lat: 48.9214634, lng: 22.1999366 },
    { lat: 48.9211657, lng: 22.200102 },
    { lat: 48.9209595, lng: 22.2003305 },
    { lat: 48.9206348, lng: 22.2005452 },
    { lat: 48.9204143, lng: 22.2004444 },
    { lat: 48.919913, lng: 22.200561 },
    { lat: 48.9193159, lng: 22.2005668 },
    { lat: 48.9191631, lng: 22.200466 },
    { lat: 48.9189832, lng: 22.2004748 },
    { lat: 48.9187056, lng: 22.2003297 },
    { lat: 48.9184958, lng: 22.2002559 },
    { lat: 48.9183979, lng: 22.2001831 },
    { lat: 48.9183565, lng: 22.2003189 },
    { lat: 48.9179297, lng: 22.2003415 },
    { lat: 48.9176914, lng: 22.2001526 },
    { lat: 48.9174159, lng: 22.1998115 },
    { lat: 48.9169499, lng: 22.1988924 },
    { lat: 48.916863, lng: 22.19849 },
    { lat: 48.9169988, lng: 22.1977741 },
    { lat: 48.9167535, lng: 22.1977225 },
    { lat: 48.9160632, lng: 22.197789 },
    { lat: 48.9155239, lng: 22.1976594 },
    { lat: 48.914811, lng: 22.1972689 },
    { lat: 48.9147206, lng: 22.1973033 },
    { lat: 48.9145546, lng: 22.1979242 },
    { lat: 48.9141682, lng: 22.198337 },
    { lat: 48.9140125, lng: 22.1987148 },
    { lat: 48.9138276, lng: 22.1988976 },
    { lat: 48.9136899, lng: 22.1992677 },
    { lat: 48.9135073, lng: 22.1992511 },
    { lat: 48.9134065, lng: 22.1991716 },
    { lat: 48.9130769, lng: 22.1988205 },
    { lat: 48.9130626, lng: 22.1986889 },
    { lat: 48.9127319, lng: 22.1986887 },
    { lat: 48.9126001, lng: 22.198874 },
    { lat: 48.9123539, lng: 22.1989283 },
    { lat: 48.9120274, lng: 22.1991846 },
    { lat: 48.9117231, lng: 22.1992372 },
    { lat: 48.9116054, lng: 22.1993471 },
    { lat: 48.9109728, lng: 22.1989846 },
    { lat: 48.9098814, lng: 22.1986407 },
    { lat: 48.9081832, lng: 22.1981307 },
    { lat: 48.9074969, lng: 22.1980714 },
    { lat: 48.9066811, lng: 22.1984515 },
    { lat: 48.9063833, lng: 22.1985088 },
    { lat: 48.9061167, lng: 22.198714 },
    { lat: 48.9057768, lng: 22.1985991 },
    { lat: 48.9049239, lng: 22.1978946 },
    { lat: 48.9046113, lng: 22.1981375 },
    { lat: 48.9039377, lng: 22.1977447 },
    { lat: 48.9032017, lng: 22.1976837 },
    { lat: 48.9025483, lng: 22.1979298 },
    { lat: 48.9020614, lng: 22.1986809 },
    { lat: 48.9016556, lng: 22.1990357 },
    { lat: 48.9013999, lng: 22.1990708 },
    { lat: 48.9011399, lng: 22.1992549 },
    { lat: 48.9008785, lng: 22.1995359 },
    { lat: 48.9005713, lng: 22.2002179 },
    { lat: 48.9004261, lng: 22.200268 },
    { lat: 48.9001681, lng: 22.2001467 },
    { lat: 48.8999319, lng: 22.2003928 },
    { lat: 48.8995747, lng: 22.2005133 },
    { lat: 48.8991152, lng: 22.2009348 },
    { lat: 48.8990032, lng: 22.2012563 },
    { lat: 48.8987241, lng: 22.2014917 },
    { lat: 48.8985473, lng: 22.2017301 },
    { lat: 48.8982079, lng: 22.2024877 },
    { lat: 48.8977941, lng: 22.2028879 },
    { lat: 48.8974025, lng: 22.2029336 },
    { lat: 48.8965854, lng: 22.2032064 },
    { lat: 48.896417, lng: 22.203169 },
    { lat: 48.8962637, lng: 22.2032592 },
    { lat: 48.8957454, lng: 22.2040111 },
    { lat: 48.8948592, lng: 22.2041666 },
    { lat: 48.894665, lng: 22.2039344 },
    { lat: 48.894371, lng: 22.2043068 },
    { lat: 48.8938112, lng: 22.2046694 },
    { lat: 48.8934626, lng: 22.2051257 },
    { lat: 48.8930497, lng: 22.2053159 },
    { lat: 48.8919849, lng: 22.2055775 },
    { lat: 48.8916419, lng: 22.2054309 },
    { lat: 48.8912566, lng: 22.2054538 },
    { lat: 48.8905367, lng: 22.2049559 },
    { lat: 48.8900838, lng: 22.2050241 },
    { lat: 48.8894733, lng: 22.205479 },
    { lat: 48.8890225, lng: 22.2060375 },
    { lat: 48.8885028, lng: 22.207328 },
    { lat: 48.8882641, lng: 22.2074665 },
    { lat: 48.8879582, lng: 22.2074685 },
    { lat: 48.887707, lng: 22.2078958 },
    { lat: 48.8873408, lng: 22.2082136 },
    { lat: 48.8869732, lng: 22.2086769 },
    { lat: 48.8866916, lng: 22.2086998 },
    { lat: 48.886563, lng: 22.2087879 },
    { lat: 48.8862216, lng: 22.2095122 },
    { lat: 48.8858027, lng: 22.209881 },
    { lat: 48.8854814, lng: 22.2103487 },
    { lat: 48.8847923, lng: 22.2105596 },
    { lat: 48.8843429, lng: 22.2104789 },
    { lat: 48.8841459, lng: 22.210522 },
    { lat: 48.8838228, lng: 22.2107657 },
    { lat: 48.883511, lng: 22.2109037 },
    { lat: 48.8829968, lng: 22.2108968 },
    { lat: 48.8824038, lng: 22.2105273 },
    { lat: 48.8819549, lng: 22.2107726 },
    { lat: 48.8817601, lng: 22.2109642 },
    { lat: 48.8814619, lng: 22.2114528 },
    { lat: 48.881144, lng: 22.2115701 },
    { lat: 48.8809701, lng: 22.211543 },
    { lat: 48.8806187, lng: 22.2117217 },
    { lat: 48.8802064, lng: 22.2116437 },
    { lat: 48.8800637, lng: 22.2114514 },
    { lat: 48.8798057, lng: 22.2112847 },
    { lat: 48.8795332, lng: 22.2112234 },
    { lat: 48.8791462, lng: 22.2109697 },
    { lat: 48.878815, lng: 22.2108451 },
    { lat: 48.8781455, lng: 22.2107322 },
    { lat: 48.8779604, lng: 22.2106025 },
    { lat: 48.8776045, lng: 22.2106338 },
    { lat: 48.8765504, lng: 22.2103673 },
    { lat: 48.87609, lng: 22.2101028 },
    { lat: 48.8757874, lng: 22.2102089 },
    { lat: 48.8753193, lng: 22.2096969 },
    { lat: 48.8751306, lng: 22.2092114 },
    { lat: 48.8748571, lng: 22.2089026 },
    { lat: 48.8746467, lng: 22.2087499 },
    { lat: 48.8743203, lng: 22.2087947 },
    { lat: 48.8742013, lng: 22.2086991 },
    { lat: 48.8741539, lng: 22.2078025 },
    { lat: 48.8740791, lng: 22.207577 },
    { lat: 48.8737227, lng: 22.2070339 },
    { lat: 48.873451, lng: 22.2063588 },
    { lat: 48.8729935, lng: 22.2055257 },
    { lat: 48.872779, lng: 22.2043985 },
    { lat: 48.8724535, lng: 22.2041296 },
    { lat: 48.8720542, lng: 22.2036273 },
    { lat: 48.8718867, lng: 22.2030372 },
    { lat: 48.8716102, lng: 22.2025989 },
    { lat: 48.8714848, lng: 22.2024697 },
    { lat: 48.8710531, lng: 22.2024328 },
    { lat: 48.870839, lng: 22.2023164 },
    { lat: 48.8705004, lng: 22.2020182 },
    { lat: 48.8703759, lng: 22.2018364 },
    { lat: 48.8702058, lng: 22.2017277 },
    { lat: 48.8696863, lng: 22.1998937 },
    { lat: 48.8695809, lng: 22.1998592 },
    { lat: 48.8691582, lng: 22.1992735 },
    { lat: 48.8691078, lng: 22.1991325 },
    { lat: 48.8691559, lng: 22.1987774 },
    { lat: 48.8691272, lng: 22.1986045 },
    { lat: 48.8687094, lng: 22.1978792 },
    { lat: 48.8683964, lng: 22.1975147 },
    { lat: 48.8677624, lng: 22.1971944 },
    { lat: 48.8673667, lng: 22.1966552 },
    { lat: 48.8671636, lng: 22.1965243 },
    { lat: 48.8666323, lng: 22.196512 },
    { lat: 48.8665356, lng: 22.1964564 },
    { lat: 48.8662527, lng: 22.1959874 },
    { lat: 48.8660319, lng: 22.1957263 },
    { lat: 48.8656037, lng: 22.1955958 },
    { lat: 48.8652532, lng: 22.1952618 },
    { lat: 48.8639741, lng: 22.1945576 },
    { lat: 48.863506, lng: 22.1942052 },
    { lat: 48.8628952, lng: 22.1935555 },
    { lat: 48.8627285, lng: 22.1929512 },
    { lat: 48.8625743, lng: 22.1928024 },
    { lat: 48.862328, lng: 22.1921097 },
    { lat: 48.8622197, lng: 22.191895 },
    { lat: 48.8620873, lng: 22.1917651 },
    { lat: 48.8616798, lng: 22.1917007 },
    { lat: 48.861289, lng: 22.1915232 },
    { lat: 48.8608171, lng: 22.191545 },
    { lat: 48.8605769, lng: 22.1912714 },
    { lat: 48.860243, lng: 22.1910367 },
    { lat: 48.8591731, lng: 22.190475 },
    { lat: 48.8585974, lng: 22.1902932 },
    { lat: 48.8577933, lng: 22.1904399 },
    { lat: 48.8574479, lng: 22.1906844 },
    { lat: 48.856939, lng: 22.1906642 },
    { lat: 48.8563746, lng: 22.1905226 },
    { lat: 48.8561359, lng: 22.1902115 },
    { lat: 48.8559658, lng: 22.1901543 },
    { lat: 48.8557108, lng: 22.190267 },
    { lat: 48.8556298, lng: 22.1902201 },
    { lat: 48.8549272, lng: 22.1892982 },
    { lat: 48.8545983, lng: 22.1893257 },
    { lat: 48.8542138, lng: 22.1886901 },
    { lat: 48.8539105, lng: 22.1883077 },
    { lat: 48.8537552, lng: 22.1882055 },
    { lat: 48.8533828, lng: 22.188206 },
    { lat: 48.8527606, lng: 22.1877525 },
    { lat: 48.8523138, lng: 22.187169 },
    { lat: 48.8519615, lng: 22.1866428 },
    { lat: 48.8515012, lng: 22.186684 },
    { lat: 48.851189, lng: 22.1868922 },
    { lat: 48.8509201, lng: 22.1868973 },
    { lat: 48.8501073, lng: 22.1868177 },
    { lat: 48.8499501, lng: 22.1866299 },
    { lat: 48.849726, lng: 22.1865709 },
    { lat: 48.8491442, lng: 22.1866485 },
    { lat: 48.8488941, lng: 22.1865851 },
    { lat: 48.8485817, lng: 22.1867483 },
    { lat: 48.8483604, lng: 22.1871105 },
    { lat: 48.848088, lng: 22.1870937 },
    { lat: 48.8478107, lng: 22.1868707 },
    { lat: 48.8473238, lng: 22.1869762 },
    { lat: 48.8463896, lng: 22.1862016 },
    { lat: 48.8456634, lng: 22.1858796 },
    { lat: 48.8454918, lng: 22.1859522 },
    { lat: 48.8451002, lng: 22.1859384 },
    { lat: 48.8444505, lng: 22.1857164 },
    { lat: 48.844114, lng: 22.1858508 },
    { lat: 48.843833, lng: 22.1856134 },
    { lat: 48.843252, lng: 22.1852902 },
    { lat: 48.8431516, lng: 22.1851787 },
    { lat: 48.8429941, lng: 22.1848352 },
    { lat: 48.8424171, lng: 22.1847319 },
    { lat: 48.8421212, lng: 22.1846353 },
    { lat: 48.8420199, lng: 22.1845338 },
    { lat: 48.8415877, lng: 22.1844715 },
    { lat: 48.8409818, lng: 22.183793 },
    { lat: 48.840489, lng: 22.1833425 },
    { lat: 48.8401256, lng: 22.1834358 },
    { lat: 48.8393972, lng: 22.1830176 },
    { lat: 48.8385068, lng: 22.1827825 },
    { lat: 48.8382714, lng: 22.1823821 },
    { lat: 48.8376159, lng: 22.1819509 },
    { lat: 48.8372449, lng: 22.1817479 },
    { lat: 48.8365657, lng: 22.1813914 },
    { lat: 48.8361755, lng: 22.1812922 },
    { lat: 48.8357946, lng: 22.181349 },
    { lat: 48.8356517, lng: 22.1812364 },
    { lat: 48.8354623, lng: 22.1810669 },
    { lat: 48.8353934, lng: 22.1809006 },
    { lat: 48.8353281, lng: 22.1808738 },
    { lat: 48.8346103, lng: 22.1805574 },
    { lat: 48.8338766, lng: 22.1804956 },
    { lat: 48.8336432, lng: 22.1805646 },
    { lat: 48.8332306, lng: 22.1801375 },
    { lat: 48.8331465, lng: 22.180098 },
    { lat: 48.833049, lng: 22.1801598 },
    { lat: 48.8317443, lng: 22.179475 },
    { lat: 48.8311643, lng: 22.179489 },
    { lat: 48.8306555, lng: 22.1792743 },
    { lat: 48.831154, lng: 22.175948 },
    { lat: 48.8310939, lng: 22.1759187 },
    { lat: 48.821392, lng: 22.1711305 },
    { lat: 48.8155241, lng: 22.1667256 },
    { lat: 48.8138641, lng: 22.165757 },
    { lat: 48.8107911, lng: 22.1624785 },
    { lat: 48.810838, lng: 22.1627147 },
    { lat: 48.8092525, lng: 22.1667445 },
    { lat: 48.8092293, lng: 22.1669049 },
    { lat: 48.8092981, lng: 22.1669512 },
    { lat: 48.8104699, lng: 22.1677406 },
    { lat: 48.8099862, lng: 22.1703212 },
    { lat: 48.8115066, lng: 22.1713478 },
    { lat: 48.8113941, lng: 22.1736962 },
    { lat: 48.8114475, lng: 22.1737209 },
    { lat: 48.8114529, lng: 22.1738041 },
    { lat: 48.8107863, lng: 22.1742142 },
    { lat: 48.8094371, lng: 22.174243 },
    { lat: 48.8091608, lng: 22.1745073 },
    { lat: 48.8088476, lng: 22.1746811 },
    { lat: 48.8086185, lng: 22.1749423 },
    { lat: 48.8085326, lng: 22.1751213 },
    { lat: 48.8085207, lng: 22.1771017 },
    { lat: 48.8088662, lng: 22.1798192 },
    { lat: 48.8088956, lng: 22.181548 },
    { lat: 48.8077176, lng: 22.1819191 },
    { lat: 48.8078378, lng: 22.185068 },
    { lat: 48.8043597, lng: 22.1852978 },
    { lat: 48.8035262, lng: 22.1888481 },
    { lat: 48.8032586, lng: 22.1908306 },
    { lat: 48.8033271, lng: 22.1914675 },
    { lat: 48.8032581, lng: 22.1922379 },
    { lat: 48.803657, lng: 22.1922214 },
    { lat: 48.8038089, lng: 22.1924229 },
    { lat: 48.804286, lng: 22.1923338 },
    { lat: 48.8045735, lng: 22.1924147 },
    { lat: 48.8050601, lng: 22.1921892 },
    { lat: 48.8059238, lng: 22.1922868 },
    { lat: 48.806257, lng: 22.1924489 },
    { lat: 48.8066021, lng: 22.1927307 },
    { lat: 48.8067142, lng: 22.1927166 },
    { lat: 48.8073403, lng: 22.1934365 },
    { lat: 48.808014, lng: 22.193739 },
    { lat: 48.8081769, lng: 22.1936252 },
    { lat: 48.8085288, lng: 22.1938224 },
    { lat: 48.8085324, lng: 22.1942219 },
    { lat: 48.8087497, lng: 22.1943322 },
    { lat: 48.8088113, lng: 22.194121 },
    { lat: 48.8090067, lng: 22.1943577 },
    { lat: 48.8092444, lng: 22.1943742 },
    { lat: 48.8092311, lng: 22.194088 },
    { lat: 48.8094067, lng: 22.1941952 },
    { lat: 48.8094054, lng: 22.1939408 },
    { lat: 48.8095898, lng: 22.1939833 },
    { lat: 48.8096476, lng: 22.1938004 },
    { lat: 48.8097422, lng: 22.1938579 },
    { lat: 48.8098154, lng: 22.1941617 },
    { lat: 48.8098982, lng: 22.194187 },
    { lat: 48.8099037, lng: 22.1939851 },
    { lat: 48.8100607, lng: 22.1940654 },
    { lat: 48.8100693, lng: 22.1942498 },
    { lat: 48.8101747, lng: 22.1942276 },
    { lat: 48.8103003, lng: 22.1946125 },
    { lat: 48.8106824, lng: 22.1943014 },
    { lat: 48.810718, lng: 22.1944291 },
    { lat: 48.8107004, lng: 22.1945949 },
    { lat: 48.8106299, lng: 22.1946563 },
    { lat: 48.8106367, lng: 22.1948626 },
    { lat: 48.8107891, lng: 22.1951273 },
    { lat: 48.8109826, lng: 22.1950872 },
    { lat: 48.8110115, lng: 22.1952163 },
    { lat: 48.8112527, lng: 22.1953865 },
    { lat: 48.8113063, lng: 22.1949772 },
    { lat: 48.8113649, lng: 22.1948782 },
    { lat: 48.8114282, lng: 22.1948945 },
    { lat: 48.8113975, lng: 22.1950781 },
    { lat: 48.8115755, lng: 22.1952904 },
    { lat: 48.8119332, lng: 22.1951387 },
    { lat: 48.811965, lng: 22.195304 },
    { lat: 48.8121117, lng: 22.1953214 },
    { lat: 48.8122065, lng: 22.195188 },
    { lat: 48.8121286, lng: 22.1948798 },
    { lat: 48.8122735, lng: 22.1947755 },
    { lat: 48.8123205, lng: 22.1949158 },
    { lat: 48.8124515, lng: 22.194632 },
    { lat: 48.8126291, lng: 22.1949275 },
    { lat: 48.8129085, lng: 22.194747 },
    { lat: 48.8130476, lng: 22.1948297 },
    { lat: 48.8131002, lng: 22.1953209 },
    { lat: 48.8129999, lng: 22.1954596 },
    { lat: 48.8130579, lng: 22.1955825 },
    { lat: 48.8129991, lng: 22.1956705 },
    { lat: 48.81308, lng: 22.1958087 },
    { lat: 48.8130174, lng: 22.1958762 },
    { lat: 48.8130383, lng: 22.1960611 },
    { lat: 48.8131145, lng: 22.1961467 },
    { lat: 48.8130905, lng: 22.1963256 },
    { lat: 48.8131848, lng: 22.1964164 },
    { lat: 48.8131682, lng: 22.1965264 },
    { lat: 48.8132427, lng: 22.1965911 },
    { lat: 48.813222, lng: 22.1967164 },
    { lat: 48.8133311, lng: 22.1967142 },
    { lat: 48.8133742, lng: 22.1968305 },
    { lat: 48.8134695, lng: 22.1967702 },
    { lat: 48.8135316, lng: 22.1969293 },
    { lat: 48.8136338, lng: 22.1969147 },
    { lat: 48.8136969, lng: 22.1971192 },
    { lat: 48.8137448, lng: 22.1970481 },
    { lat: 48.8139019, lng: 22.1973362 },
    { lat: 48.8140353, lng: 22.1974059 },
    { lat: 48.8139907, lng: 22.1976228 },
    { lat: 48.8142666, lng: 22.197795 },
    { lat: 48.8145955, lng: 22.1971145 },
    { lat: 48.8148121, lng: 22.1964508 },
    { lat: 48.8149734, lng: 22.1962793 },
    { lat: 48.8152853, lng: 22.1955988 },
    { lat: 48.815865, lng: 22.1967004 },
    { lat: 48.8164138, lng: 22.1968531 },
    { lat: 48.816446, lng: 22.1967843 },
    { lat: 48.8169071, lng: 22.1966853 },
    { lat: 48.8173407, lng: 22.1967552 },
    { lat: 48.8179063, lng: 22.1969998 },
    { lat: 48.8179113, lng: 22.1970659 },
    { lat: 48.8185794, lng: 22.1971129 },
    { lat: 48.8185833, lng: 22.1969956 },
    { lat: 48.8186824, lng: 22.1970004 },
    { lat: 48.8186732, lng: 22.1970819 },
    { lat: 48.8193022, lng: 22.1971523 },
    { lat: 48.8193083, lng: 22.1969749 },
    { lat: 48.8194682, lng: 22.196872 },
    { lat: 48.8196806, lng: 22.1971133 },
    { lat: 48.8198051, lng: 22.1968588 },
    { lat: 48.8203235, lng: 22.1967489 },
    { lat: 48.8203924, lng: 22.1965141 },
    { lat: 48.8208216, lng: 22.1960955 },
    { lat: 48.8209055, lng: 22.195539 },
    { lat: 48.8210118, lng: 22.1957126 },
    { lat: 48.8211237, lng: 22.1956386 },
    { lat: 48.821214, lng: 22.1952962 },
    { lat: 48.8212878, lng: 22.1952795 },
    { lat: 48.8214939, lng: 22.1954908 },
    { lat: 48.8214817, lng: 22.1956977 },
    { lat: 48.8215926, lng: 22.1957273 },
    { lat: 48.8216623, lng: 22.1953806 },
    { lat: 48.8218925, lng: 22.1953155 },
    { lat: 48.8221648, lng: 22.1953723 },
    { lat: 48.8223651, lng: 22.1959715 },
    { lat: 48.8225343, lng: 22.1956875 },
    { lat: 48.8226832, lng: 22.1956544 },
    { lat: 48.8228502, lng: 22.1956318 },
    { lat: 48.8230967, lng: 22.195837 },
    { lat: 48.8231447, lng: 22.1960176 },
    { lat: 48.8231436, lng: 22.1962711 },
    { lat: 48.8234611, lng: 22.1963848 },
    { lat: 48.8237057, lng: 22.1967041 },
    { lat: 48.8237891, lng: 22.1969524 },
    { lat: 48.8241514, lng: 22.197257 },
    { lat: 48.824275, lng: 22.197258 },
    { lat: 48.8243462, lng: 22.1973632 },
    { lat: 48.8243678, lng: 22.1975255 },
    { lat: 48.824595, lng: 22.1977222 },
    { lat: 48.8248066, lng: 22.1975848 },
    { lat: 48.8251636, lng: 22.1988894 },
    { lat: 48.8252792, lng: 22.1979932 },
    { lat: 48.8253873, lng: 22.1982881 },
    { lat: 48.8256723, lng: 22.1982611 },
    { lat: 48.8255596, lng: 22.1984954 },
    { lat: 48.8258619, lng: 22.1991786 },
    { lat: 48.8261045, lng: 22.1994598 },
    { lat: 48.8263665, lng: 22.2001033 },
    { lat: 48.8266588, lng: 22.2002895 },
    { lat: 48.8266959, lng: 22.2006384 },
    { lat: 48.8269306, lng: 22.200728 },
    { lat: 48.8269475, lng: 22.2010691 },
    { lat: 48.8271699, lng: 22.2012081 },
    { lat: 48.8271286, lng: 22.2014173 },
    { lat: 48.8271504, lng: 22.201534 },
    { lat: 48.8273698, lng: 22.2018339 },
    { lat: 48.8275569, lng: 22.2024463 },
    { lat: 48.8282603, lng: 22.2031687 },
    { lat: 48.8286773, lng: 22.2032527 },
    { lat: 48.828816, lng: 22.2036657 },
    { lat: 48.8287962, lng: 22.2037791 },
    { lat: 48.8291119, lng: 22.2041683 },
    { lat: 48.8295934, lng: 22.2045234 },
    { lat: 48.8297353, lng: 22.204968 },
    { lat: 48.8301507, lng: 22.2053374 },
    { lat: 48.8303833, lng: 22.2060675 },
    { lat: 48.8306171, lng: 22.2064073 },
    { lat: 48.8310593, lng: 22.208003 },
    { lat: 48.8312677, lng: 22.2083713 },
    { lat: 48.8313629, lng: 22.2087573 },
    { lat: 48.83221, lng: 22.2097594 },
    { lat: 48.8325556, lng: 22.2104612 },
    { lat: 48.832967, lng: 22.2109461 },
    { lat: 48.8331275, lng: 22.2112791 },
    { lat: 48.8334262, lng: 22.2117081 },
    { lat: 48.8336071, lng: 22.2118498 },
    { lat: 48.8336435, lng: 22.2120214 },
    { lat: 48.8338471, lng: 22.2123232 },
    { lat: 48.8341272, lng: 22.2125172 },
    { lat: 48.8345254, lng: 22.2129161 },
    { lat: 48.8349858, lng: 22.2136342 },
    { lat: 48.8349945, lng: 22.2138172 },
    { lat: 48.8352065, lng: 22.2143875 },
    { lat: 48.8355405, lng: 22.214744 },
    { lat: 48.8357356, lng: 22.215213 },
    { lat: 48.8359071, lng: 22.215332 },
    { lat: 48.8359414, lng: 22.2154559 },
    { lat: 48.8363056, lng: 22.2157491 },
    { lat: 48.8365286, lng: 22.2158125 },
    { lat: 48.8371463, lng: 22.2164704 },
    { lat: 48.8375793, lng: 22.2173185 },
    { lat: 48.8378225, lng: 22.2179257 },
    { lat: 48.8379373, lng: 22.2184818 },
    { lat: 48.8379337, lng: 22.218664 },
    { lat: 48.838095, lng: 22.2191377 },
    { lat: 48.8383046, lng: 22.21951 },
    { lat: 48.8383129, lng: 22.2198259 },
    { lat: 48.8383667, lng: 22.2199624 },
    { lat: 48.8392223, lng: 22.2207509 },
    { lat: 48.838386, lng: 22.2214721 },
    { lat: 48.8385209, lng: 22.2218725 },
    { lat: 48.839088, lng: 22.2229837 },
    { lat: 48.8393605, lng: 22.2238987 },
    { lat: 48.8403779, lng: 22.2248174 },
    { lat: 48.8409441, lng: 22.2250953 },
    { lat: 48.841185, lng: 22.2251478 },
    { lat: 48.8413554, lng: 22.2252751 },
    { lat: 48.8419063, lng: 22.2267139 },
    { lat: 48.8425113, lng: 22.2271659 },
    { lat: 48.8427103, lng: 22.2280122 },
    { lat: 48.8429002, lng: 22.2282581 },
    { lat: 48.8431536, lng: 22.2290696 },
    { lat: 48.8434078, lng: 22.2294732 },
    { lat: 48.8441854, lng: 22.2310022 },
    { lat: 48.8447547, lng: 22.232457 },
    { lat: 48.8450453, lng: 22.2336384 },
    { lat: 48.8452197, lng: 22.2346982 },
    { lat: 48.8454896, lng: 22.2352489 },
    { lat: 48.8464379, lng: 22.2363583 },
    { lat: 48.8467776, lng: 22.2368586 },
    { lat: 48.8472042, lng: 22.2372817 },
    { lat: 48.8477921, lng: 22.2377183 },
    { lat: 48.8487999, lng: 22.2387711 },
    { lat: 48.8493737, lng: 22.2390845 },
    { lat: 48.8501063, lng: 22.2399633 },
    { lat: 48.8505917, lng: 22.2400863 },
    { lat: 48.8509476, lng: 22.2403598 },
    { lat: 48.8515482, lng: 22.240964 },
    { lat: 48.8521123, lng: 22.2413483 },
    { lat: 48.8533923, lng: 22.2412402 },
    { lat: 48.8538414, lng: 22.2415136 },
    { lat: 48.8543452, lng: 22.2414639 },
    { lat: 48.8547141, lng: 22.2415632 },
    { lat: 48.8552159, lng: 22.2415632 },
    { lat: 48.8565069, lng: 22.2418135 },
    { lat: 48.8568781, lng: 22.2422156 },
    { lat: 48.8568914, lng: 22.243065 },
    { lat: 48.8572108, lng: 22.2443241 },
    { lat: 48.8573191, lng: 22.2453012 },
    { lat: 48.8572481, lng: 22.2461027 },
    { lat: 48.8574138, lng: 22.2479337 },
    { lat: 48.8577723, lng: 22.2486762 },
    { lat: 48.8580107, lng: 22.2493373 },
    { lat: 48.8579007, lng: 22.2497419 },
    { lat: 48.8577624, lng: 22.2509659 },
    { lat: 48.8574798, lng: 22.2517047 },
    { lat: 48.8573718, lng: 22.2527717 },
    { lat: 48.8571025, lng: 22.2535081 },
    { lat: 48.8568907, lng: 22.2545015 },
    { lat: 48.8566415, lng: 22.2546238 },
    { lat: 48.8563244, lng: 22.255194 },
    { lat: 48.8561413, lng: 22.2559041 },
    { lat: 48.856855, lng: 22.2568931 },
    { lat: 48.8571274, lng: 22.2576668 },
    { lat: 48.8573615, lng: 22.2580314 },
    { lat: 48.8578391, lng: 22.2584746 },
    { lat: 48.8581463, lng: 22.2589897 },
    { lat: 48.8585177, lng: 22.2589448 },
    { lat: 48.8590098, lng: 22.2588172 },
    { lat: 48.8598893, lng: 22.258407 },
    { lat: 48.860444, lng: 22.2587238 },
    { lat: 48.862351, lng: 22.2585978 },
    { lat: 48.8633995, lng: 22.2586147 },
    { lat: 48.8637022, lng: 22.2588747 },
    { lat: 48.8645035, lng: 22.2585431 },
    { lat: 48.8652973, lng: 22.2579708 },
    { lat: 48.8655504, lng: 22.2577157 },
    { lat: 48.8657738, lng: 22.2575989 },
    { lat: 48.8664262, lng: 22.2573989 },
    { lat: 48.8666498, lng: 22.2573928 },
    { lat: 48.8673633, lng: 22.2572586 },
    { lat: 48.8679534, lng: 22.2568337 },
    { lat: 48.8686691, lng: 22.2560438 },
    { lat: 48.8689007, lng: 22.2554215 },
    { lat: 48.869087, lng: 22.2555538 },
    { lat: 48.8696449, lng: 22.2544007 },
    { lat: 48.8702905, lng: 22.253437 },
    { lat: 48.8703651, lng: 22.2531438 },
    { lat: 48.8703162, lng: 22.2530777 },
    { lat: 48.8707225, lng: 22.2521217 },
    { lat: 48.8707958, lng: 22.2520772 },
    { lat: 48.8710519, lng: 22.252221 },
    { lat: 48.8711541, lng: 22.2524623 },
    { lat: 48.8722308, lng: 22.2523276 },
    { lat: 48.8730321, lng: 22.2524986 },
    { lat: 48.8736991, lng: 22.2531162 },
    { lat: 48.8749452, lng: 22.253818 },
    { lat: 48.8752304, lng: 22.2540528 },
    { lat: 48.8758583, lng: 22.2547197 },
    { lat: 48.8765591, lng: 22.2552352 },
    { lat: 48.8773728, lng: 22.2553042 },
    { lat: 48.8778492, lng: 22.2552373 },
    { lat: 48.8786269, lng: 22.2553081 },
    { lat: 48.8790323, lng: 22.2553817 },
    { lat: 48.8808964, lng: 22.2543325 },
    { lat: 48.8812761, lng: 22.2534527 },
    { lat: 48.8816192, lng: 22.2531532 },
    { lat: 48.8821626, lng: 22.2526495 },
    { lat: 48.8826733, lng: 22.2520981 },
    { lat: 48.882771, lng: 22.2518404 },
    { lat: 48.8829442, lng: 22.2516372 },
    { lat: 48.8832441, lng: 22.2514167 },
    { lat: 48.8834211, lng: 22.2512926 },
    { lat: 48.8838716, lng: 22.2515734 },
    { lat: 48.8843213, lng: 22.251361 },
    { lat: 48.8845617, lng: 22.2511382 },
    { lat: 48.8848173, lng: 22.2513557 },
    { lat: 48.8853369, lng: 22.2513534 },
    { lat: 48.8859523, lng: 22.2511068 },
    { lat: 48.8867047, lng: 22.2510069 },
    { lat: 48.8868369, lng: 22.2509301 },
    { lat: 48.8873882, lng: 22.2506386 },
    { lat: 48.8880552, lng: 22.2500859 },
    { lat: 48.8887395, lng: 22.2501027 },
    { lat: 48.8891476, lng: 22.2502445 },
    { lat: 48.8893556, lng: 22.2499454 },
    { lat: 48.8893326, lng: 22.2495649 },
    { lat: 48.8899911, lng: 22.2488775 },
    { lat: 48.8903497, lng: 22.2482141 },
    { lat: 48.8907497, lng: 22.2477933 },
    { lat: 48.8907577, lng: 22.247043 },
    { lat: 48.8906646, lng: 22.2456835 },
    { lat: 48.8906761, lng: 22.2451983 },
    { lat: 48.8907277, lng: 22.2445266 },
    { lat: 48.8911272, lng: 22.2442441 },
    { lat: 48.8914012, lng: 22.2441632 },
    { lat: 48.8920155, lng: 22.2437779 },
    { lat: 48.8928303, lng: 22.2433873 },
    { lat: 48.8931874, lng: 22.242969 },
    { lat: 48.8938045, lng: 22.2429485 },
    { lat: 48.8942446, lng: 22.2428183 },
    { lat: 48.894421, lng: 22.2426502 },
    { lat: 48.8948073, lng: 22.2422183 },
    { lat: 48.8951554, lng: 22.2420485 },
    { lat: 48.8953536, lng: 22.2419522 },
    { lat: 48.895487, lng: 22.2417852 },
    { lat: 48.8957124, lng: 22.2417043 },
    { lat: 48.8959381, lng: 22.2416885 },
    { lat: 48.8960875, lng: 22.241531 },
    { lat: 48.8964912, lng: 22.241422 },
    { lat: 48.8965838, lng: 22.2410823 },
    { lat: 48.8967968, lng: 22.2408466 },
    { lat: 48.8976425, lng: 22.240747 },
    { lat: 48.8992865, lng: 22.240687 },
    { lat: 48.9007313, lng: 22.2401599 },
    { lat: 48.900783, lng: 22.2400607 },
    { lat: 48.9010164, lng: 22.2398815 },
    { lat: 48.9012404, lng: 22.2395822 },
    { lat: 48.9018969, lng: 22.2392711 },
    { lat: 48.9021283, lng: 22.2389003 },
    { lat: 48.902714, lng: 22.2384448 },
    { lat: 48.9031392, lng: 22.2379499 },
    { lat: 48.9034919, lng: 22.2377752 },
    { lat: 48.9038306, lng: 22.2373224 },
    { lat: 48.9043271, lng: 22.2367896 },
    { lat: 48.9045309, lng: 22.2366525 },
    { lat: 48.9049164, lng: 22.2360357 },
    { lat: 48.9054587, lng: 22.2356996 },
    { lat: 48.9059457, lng: 22.2353417 },
    { lat: 48.9060605, lng: 22.2351417 },
    { lat: 48.9063324, lng: 22.2349825 },
    { lat: 48.9066289, lng: 22.2348539 },
    { lat: 48.9068277, lng: 22.2345444 },
    { lat: 48.9074545, lng: 22.2346667 },
    { lat: 48.907823, lng: 22.2350503 },
    { lat: 48.9083999, lng: 22.2357979 },
    { lat: 48.9090173, lng: 22.2360969 },
    { lat: 48.9092662, lng: 22.2361649 },
    { lat: 48.9096548, lng: 22.2363394 },
    { lat: 48.9101048, lng: 22.2362362 },
    { lat: 48.910604, lng: 22.2360296 },
    { lat: 48.9110201, lng: 22.235832 },
    { lat: 48.9116846, lng: 22.2355614 },
    { lat: 48.9120364, lng: 22.2357089 },
    { lat: 48.9123088, lng: 22.2357156 },
    { lat: 48.9125335, lng: 22.2357627 },
    { lat: 48.9127455, lng: 22.2357346 },
    { lat: 48.913046, lng: 22.2357381 },
    { lat: 48.9133645, lng: 22.235646 },
    { lat: 48.9138451, lng: 22.2357636 },
    { lat: 48.9155685, lng: 22.2351779 },
    { lat: 48.9175026, lng: 22.2348513 },
    { lat: 48.9176886, lng: 22.2342202 },
    { lat: 48.9178286, lng: 22.2340428 },
    { lat: 48.9180112, lng: 22.234062 },
    { lat: 48.9185196, lng: 22.2341667 },
    { lat: 48.9188025, lng: 22.234037 },
    { lat: 48.9194167, lng: 22.2331382 },
    { lat: 48.9197627, lng: 22.2329716 },
    { lat: 48.9208021, lng: 22.2326763 },
    { lat: 48.9212251, lng: 22.2321292 },
    { lat: 48.921342, lng: 22.2321686 },
    { lat: 48.921464, lng: 22.23209 },
    { lat: 48.9217644, lng: 22.2319526 },
    { lat: 48.9218908, lng: 22.2319236 },
    { lat: 48.9221794, lng: 22.231947 },
    { lat: 48.9224651, lng: 22.2317534 },
    { lat: 48.9231255, lng: 22.2316565 },
    { lat: 48.9238808, lng: 22.2313822 },
    { lat: 48.9243694, lng: 22.2310023 },
    { lat: 48.9248092, lng: 22.2308559 },
    { lat: 48.9250827, lng: 22.2307119 },
    { lat: 48.925555, lng: 22.231007 },
    { lat: 48.9268758, lng: 22.2299221 },
    { lat: 48.9276222, lng: 22.229145 },
    { lat: 48.9276877, lng: 22.2288594 },
    { lat: 48.9277273, lng: 22.2274613 },
    { lat: 48.9277105, lng: 22.2272143 },
    { lat: 48.927449, lng: 22.2267364 },
    { lat: 48.9271116, lng: 22.2259823 },
    { lat: 48.9270859, lng: 22.2256896 },
    { lat: 48.9271064, lng: 22.2255526 },
    { lat: 48.92734, lng: 22.2250369 },
    { lat: 48.927414, lng: 22.224385 },
    { lat: 48.927287, lng: 22.22374 },
    { lat: 48.927197, lng: 22.223589 },
    { lat: 48.926988, lng: 22.222878 },
    { lat: 48.92698, lng: 22.222261 },
    { lat: 48.926945, lng: 22.22187 },
    { lat: 48.926963, lng: 22.221218 },
    { lat: 48.926921, lng: 22.220428 },
    { lat: 48.926665, lng: 22.219139 },
    { lat: 48.926902, lng: 22.218219 },
    { lat: 48.927188, lng: 22.21725 },
    { lat: 48.927994, lng: 22.216831 },
    { lat: 48.928447, lng: 22.216535 },
    { lat: 48.928749, lng: 22.216359 },
    { lat: 48.928976, lng: 22.216216 },
    { lat: 48.929667, lng: 22.215253 },
    { lat: 48.929817, lng: 22.214325 },
    { lat: 48.929827, lng: 22.214041 },
    { lat: 48.929705, lng: 22.213641 },
    { lat: 48.929705, lng: 22.213554 },
    { lat: 48.92965, lng: 22.211791 },
    { lat: 48.929312, lng: 22.210864 },
    { lat: 48.9292, lng: 22.209569 },
    { lat: 48.929021, lng: 22.207714 },
    { lat: 48.929154, lng: 22.207751 },
    { lat: 48.929266, lng: 22.206725 },
    { lat: 48.929541, lng: 22.205856 },
    { lat: 48.929935, lng: 22.202769 },
    { lat: 48.929966, lng: 22.202197 },
    { lat: 48.93007, lng: 22.2015 },
    { lat: 48.9302, lng: 22.200446 },
    { lat: 48.93013, lng: 22.199606 },
  ],
  Bežovce: [
    { lat: 48.681846, lng: 22.1151373 },
    { lat: 48.6813614, lng: 22.1142893 },
    { lat: 48.673243, lng: 22.1001526 },
    { lat: 48.6725459, lng: 22.0987419 },
    { lat: 48.6718017, lng: 22.0980421 },
    { lat: 48.6713655, lng: 22.0973392 },
    { lat: 48.6711155, lng: 22.0966142 },
    { lat: 48.6709942, lng: 22.0959627 },
    { lat: 48.6707027, lng: 22.0933854 },
    { lat: 48.6704545, lng: 22.0906939 },
    { lat: 48.6703569, lng: 22.0901334 },
    { lat: 48.6702341, lng: 22.0895182 },
    { lat: 48.6693592, lng: 22.086306 },
    { lat: 48.6684225, lng: 22.0817273 },
    { lat: 48.6684191, lng: 22.0812951 },
    { lat: 48.668568, lng: 22.080581 },
    { lat: 48.668311, lng: 22.080884 },
    { lat: 48.668212, lng: 22.080914 },
    { lat: 48.668046, lng: 22.080863 },
    { lat: 48.667928, lng: 22.080714 },
    { lat: 48.667877, lng: 22.080641 },
    { lat: 48.667358, lng: 22.079995 },
    { lat: 48.667307, lng: 22.079933 },
    { lat: 48.666834, lng: 22.079345 },
    { lat: 48.665485, lng: 22.07746 },
    { lat: 48.665372, lng: 22.07737 },
    { lat: 48.665223, lng: 22.077286 },
    { lat: 48.665109, lng: 22.077223 },
    { lat: 48.664947, lng: 22.077132 },
    { lat: 48.664326, lng: 22.077054 },
    { lat: 48.664012, lng: 22.076861 },
    { lat: 48.663805, lng: 22.076873 },
    { lat: 48.663592, lng: 22.076606 },
    { lat: 48.663417, lng: 22.076121 },
    { lat: 48.663259, lng: 22.07568 },
    { lat: 48.663039, lng: 22.075297 },
    { lat: 48.662879, lng: 22.074689 },
    { lat: 48.662451, lng: 22.073481 },
    { lat: 48.662327, lng: 22.073005 },
    { lat: 48.662291, lng: 22.073038 },
    { lat: 48.66186, lng: 22.073435 },
    { lat: 48.661779, lng: 22.073794 },
    { lat: 48.661168, lng: 22.073742 },
    { lat: 48.660524, lng: 22.074142 },
    { lat: 48.660463, lng: 22.074403 },
    { lat: 48.660123, lng: 22.074748 },
    { lat: 48.659656, lng: 22.074971 },
    { lat: 48.658647, lng: 22.075624 },
    { lat: 48.658015, lng: 22.076171 },
    { lat: 48.65786, lng: 22.076124 },
    { lat: 48.657633, lng: 22.077683 },
    { lat: 48.657693, lng: 22.078123 },
    { lat: 48.657521, lng: 22.078176 },
    { lat: 48.657514, lng: 22.078179 },
    { lat: 48.6576, lng: 22.07846 },
    { lat: 48.657658, lng: 22.078648 },
    { lat: 48.657666, lng: 22.078978 },
    { lat: 48.657648, lng: 22.079342 },
    { lat: 48.65765, lng: 22.07954 },
    { lat: 48.657669, lng: 22.079794 },
    { lat: 48.657776, lng: 22.080323 },
    { lat: 48.657807, lng: 22.080498 },
    { lat: 48.657803, lng: 22.080903 },
    { lat: 48.657684, lng: 22.081152 },
    { lat: 48.657633, lng: 22.08126 },
    { lat: 48.657691, lng: 22.081777 },
    { lat: 48.657799, lng: 22.08217 },
    { lat: 48.657944, lng: 22.082424 },
    { lat: 48.658051, lng: 22.082696 },
    { lat: 48.658226, lng: 22.083031 },
    { lat: 48.658342, lng: 22.083669 },
    { lat: 48.658096, lng: 22.084321 },
    { lat: 48.657725, lng: 22.084674 },
    { lat: 48.657665, lng: 22.084731 },
    { lat: 48.657604, lng: 22.084788 },
    { lat: 48.657528, lng: 22.085101 },
    { lat: 48.657384, lng: 22.085479 },
    { lat: 48.65736, lng: 22.085655 },
    { lat: 48.657304, lng: 22.086065 },
    { lat: 48.657224, lng: 22.086388 },
    { lat: 48.657161, lng: 22.086725 },
    { lat: 48.657086, lng: 22.086961 },
    { lat: 48.657064, lng: 22.087031 },
    { lat: 48.657048, lng: 22.087085 },
    { lat: 48.657043, lng: 22.087102 },
    { lat: 48.657017, lng: 22.08718 },
    { lat: 48.656827, lng: 22.087597 },
    { lat: 48.656757, lng: 22.088059 },
    { lat: 48.656731, lng: 22.088318 },
    { lat: 48.65665, lng: 22.0887 },
    { lat: 48.656557, lng: 22.088765 },
    { lat: 48.656479, lng: 22.08878 },
    { lat: 48.656457, lng: 22.088837 },
    { lat: 48.65646, lng: 22.088891 },
    { lat: 48.65628, lng: 22.089078 },
    { lat: 48.656039, lng: 22.089242 },
    { lat: 48.655749, lng: 22.089571 },
    { lat: 48.655656, lng: 22.089447 },
    { lat: 48.65555, lng: 22.089699 },
    { lat: 48.655141, lng: 22.089478 },
    { lat: 48.655023, lng: 22.089446 },
    { lat: 48.654763, lng: 22.089879 },
    { lat: 48.654556, lng: 22.089987 },
    { lat: 48.654115, lng: 22.090371 },
    { lat: 48.654251, lng: 22.090912 },
    { lat: 48.654167, lng: 22.0911 },
    { lat: 48.653475, lng: 22.091668 },
    { lat: 48.653311, lng: 22.091366 },
    { lat: 48.653141, lng: 22.091511 },
    { lat: 48.653099, lng: 22.091444 },
    { lat: 48.652962, lng: 22.091489 },
    { lat: 48.652644, lng: 22.091695 },
    { lat: 48.652386, lng: 22.092015 },
    { lat: 48.652282, lng: 22.092217 },
    { lat: 48.652287, lng: 22.092357 },
    { lat: 48.652348, lng: 22.092678 },
    { lat: 48.652464, lng: 22.092953 },
    { lat: 48.652939, lng: 22.093661 },
    { lat: 48.652631, lng: 22.094127 },
    { lat: 48.652002, lng: 22.094632 },
    { lat: 48.651868, lng: 22.094783 },
    { lat: 48.651419, lng: 22.095216 },
    { lat: 48.651738, lng: 22.095579 },
    { lat: 48.651884, lng: 22.095742 },
    { lat: 48.651895, lng: 22.095754 },
    { lat: 48.651995, lng: 22.095857 },
    { lat: 48.652096, lng: 22.095963 },
    { lat: 48.652271, lng: 22.09609 },
    { lat: 48.652801, lng: 22.095734 },
    { lat: 48.652884, lng: 22.095772 },
    { lat: 48.652913, lng: 22.095795 },
    { lat: 48.65308, lng: 22.095587 },
    { lat: 48.653207, lng: 22.095429 },
    { lat: 48.653264, lng: 22.095358 },
    { lat: 48.65338, lng: 22.095281 },
    { lat: 48.653611, lng: 22.095141 },
    { lat: 48.653878, lng: 22.095156 },
    { lat: 48.654135, lng: 22.095027 },
    { lat: 48.654429, lng: 22.094965 },
    { lat: 48.654966, lng: 22.095128 },
    { lat: 48.655258, lng: 22.095703 },
    { lat: 48.655675, lng: 22.095553 },
    { lat: 48.655832, lng: 22.095615 },
    { lat: 48.655988, lng: 22.095762 },
    { lat: 48.656343, lng: 22.095438 },
    { lat: 48.6568, lng: 22.095787 },
    { lat: 48.65697, lng: 22.096013 },
    { lat: 48.657187, lng: 22.096641 },
    { lat: 48.657617, lng: 22.097111 },
    { lat: 48.657638, lng: 22.097133 },
    { lat: 48.657743, lng: 22.09726 },
    { lat: 48.657754, lng: 22.097273 },
    { lat: 48.657943, lng: 22.097492 },
    { lat: 48.658087, lng: 22.098096 },
    { lat: 48.657981, lng: 22.098301 },
    { lat: 48.6577, lng: 22.0985 },
    { lat: 48.657058, lng: 22.098419 },
    { lat: 48.657047, lng: 22.09841 },
    { lat: 48.657028, lng: 22.098394 },
    { lat: 48.656782, lng: 22.098376 },
    { lat: 48.656332, lng: 22.098703 },
    { lat: 48.655795, lng: 22.098648 },
    { lat: 48.655559, lng: 22.098707 },
    { lat: 48.655363, lng: 22.098832 },
    { lat: 48.655259, lng: 22.098895 },
    { lat: 48.654692, lng: 22.098801 },
    { lat: 48.65459, lng: 22.098729 },
    { lat: 48.654332, lng: 22.098553 },
    { lat: 48.653939, lng: 22.098511 },
    { lat: 48.653601, lng: 22.09882 },
    { lat: 48.653506, lng: 22.09891 },
    { lat: 48.653432, lng: 22.09898 },
    { lat: 48.653359, lng: 22.099049 },
    { lat: 48.653315, lng: 22.09909 },
    { lat: 48.653142, lng: 22.099503 },
    { lat: 48.6531, lng: 22.099602 },
    { lat: 48.65286, lng: 22.099787 },
    { lat: 48.652627, lng: 22.099837 },
    { lat: 48.652301, lng: 22.100216 },
    { lat: 48.652125, lng: 22.100418 },
    { lat: 48.651701, lng: 22.10056 },
    { lat: 48.651594, lng: 22.100546 },
    { lat: 48.651255, lng: 22.100742 },
    { lat: 48.65117, lng: 22.10074 },
    { lat: 48.651139, lng: 22.10074 },
    { lat: 48.651108, lng: 22.10074 },
    { lat: 48.650991, lng: 22.10074 },
    { lat: 48.650559, lng: 22.100855 },
    { lat: 48.650258, lng: 22.101009 },
    { lat: 48.649807, lng: 22.101534 },
    { lat: 48.649716, lng: 22.102135 },
    { lat: 48.649496, lng: 22.102429 },
    { lat: 48.64935, lng: 22.102789 },
    { lat: 48.649115, lng: 22.103004 },
    { lat: 48.649035, lng: 22.103195 },
    { lat: 48.648994, lng: 22.103302 },
    { lat: 48.64877, lng: 22.10352 },
    { lat: 48.648751, lng: 22.103507 },
    { lat: 48.648589, lng: 22.103386 },
    { lat: 48.648489, lng: 22.10344 },
    { lat: 48.64843, lng: 22.103478 },
    { lat: 48.648287, lng: 22.103616 },
    { lat: 48.647975, lng: 22.103678 },
    { lat: 48.647769, lng: 22.103804 },
    { lat: 48.647404, lng: 22.10422 },
    { lat: 48.647441, lng: 22.104438 },
    { lat: 48.647448, lng: 22.104487 },
    { lat: 48.64747, lng: 22.104628 },
    { lat: 48.647479, lng: 22.10469 },
    { lat: 48.647504, lng: 22.104973 },
    { lat: 48.647506, lng: 22.104999 },
    { lat: 48.647508, lng: 22.105075 },
    { lat: 48.64753, lng: 22.10531 },
    { lat: 48.647213, lng: 22.106019 },
    { lat: 48.647054, lng: 22.106484 },
    { lat: 48.646855, lng: 22.106585 },
    { lat: 48.646741, lng: 22.106751 },
    { lat: 48.646571, lng: 22.107495 },
    { lat: 48.646618, lng: 22.107619 },
    { lat: 48.646646, lng: 22.107694 },
    { lat: 48.64675, lng: 22.107964 },
    { lat: 48.646632, lng: 22.108142 },
    { lat: 48.646466, lng: 22.108391 },
    { lat: 48.646444, lng: 22.108494 },
    { lat: 48.646366, lng: 22.108853 },
    { lat: 48.645877, lng: 22.109267 },
    { lat: 48.645912, lng: 22.109665 },
    { lat: 48.645504, lng: 22.11054 },
    { lat: 48.645375, lng: 22.110699 },
    { lat: 48.645232, lng: 22.110687 },
    { lat: 48.644876, lng: 22.110289 },
    { lat: 48.644731, lng: 22.110131 },
    { lat: 48.644693, lng: 22.110109 },
    { lat: 48.644536, lng: 22.110015 },
    { lat: 48.644334, lng: 22.110197 },
    { lat: 48.644341, lng: 22.110217 },
    { lat: 48.644341, lng: 22.110231 },
    { lat: 48.644337, lng: 22.110325 },
    { lat: 48.644334, lng: 22.11038 },
    { lat: 48.644324, lng: 22.110543 },
    { lat: 48.644321, lng: 22.110604 },
    { lat: 48.64431, lng: 22.110869 },
    { lat: 48.644292, lng: 22.111042 },
    { lat: 48.643942, lng: 22.111781 },
    { lat: 48.643678, lng: 22.112126 },
    { lat: 48.643389, lng: 22.112034 },
    { lat: 48.643235, lng: 22.112085 },
    { lat: 48.642854, lng: 22.112416 },
    { lat: 48.64278, lng: 22.112463 },
    { lat: 48.642625, lng: 22.112385 },
    { lat: 48.642599, lng: 22.112368 },
    { lat: 48.642566, lng: 22.112346 },
    { lat: 48.642317, lng: 22.112337 },
    { lat: 48.642164, lng: 22.112491 },
    { lat: 48.642069, lng: 22.112982 },
    { lat: 48.642059, lng: 22.113034 },
    { lat: 48.641997, lng: 22.113333 },
    { lat: 48.641956, lng: 22.113537 },
    { lat: 48.641799, lng: 22.114339 },
    { lat: 48.641606, lng: 22.11467 },
    { lat: 48.641481, lng: 22.114779 },
    { lat: 48.641304, lng: 22.114933 },
    { lat: 48.641139, lng: 22.115067 },
    { lat: 48.640938, lng: 22.114652 },
    { lat: 48.640858, lng: 22.114497 },
    { lat: 48.640837, lng: 22.114455 },
    { lat: 48.640792, lng: 22.114369 },
    { lat: 48.640732, lng: 22.114246 },
    { lat: 48.640674, lng: 22.114127 },
    { lat: 48.640469, lng: 22.113812 },
    { lat: 48.640187, lng: 22.11356 },
    { lat: 48.639352, lng: 22.113592 },
    { lat: 48.638457, lng: 22.11369 },
    { lat: 48.638055, lng: 22.113809 },
    { lat: 48.637002, lng: 22.114349 },
    { lat: 48.636682, lng: 22.114577 },
    { lat: 48.636275, lng: 22.114726 },
    { lat: 48.635272, lng: 22.114892 },
    { lat: 48.634361, lng: 22.11512 },
    { lat: 48.634248, lng: 22.115148 },
    { lat: 48.634193, lng: 22.115161 },
    { lat: 48.634094, lng: 22.115189 },
    { lat: 48.631025, lng: 22.116341 },
    { lat: 48.631157, lng: 22.11724 },
    { lat: 48.631214, lng: 22.118264 },
    { lat: 48.631318, lng: 22.119837 },
    { lat: 48.631359, lng: 22.120176 },
    { lat: 48.631509, lng: 22.121182 },
    { lat: 48.631551, lng: 22.122103 },
    { lat: 48.631676, lng: 22.122787 },
    { lat: 48.631993, lng: 22.124052 },
    { lat: 48.632147, lng: 22.124993 },
    { lat: 48.63219, lng: 22.12542 },
    { lat: 48.632158, lng: 22.125796 },
    { lat: 48.632151, lng: 22.126191 },
    { lat: 48.632925, lng: 22.125692 },
    { lat: 48.633426, lng: 22.126014 },
    { lat: 48.633495, lng: 22.12726 },
    { lat: 48.632679, lng: 22.126977 },
    { lat: 48.632151, lng: 22.126787 },
    { lat: 48.630798, lng: 22.12764 },
    { lat: 48.630322, lng: 22.127812 },
    { lat: 48.629501, lng: 22.127913 },
    { lat: 48.629192, lng: 22.128062 },
    { lat: 48.628703, lng: 22.128439 },
    { lat: 48.628264, lng: 22.12869 },
    { lat: 48.628062, lng: 22.128757 },
    { lat: 48.627858, lng: 22.128751 },
    { lat: 48.6274673, lng: 22.1288639 },
    { lat: 48.6266803, lng: 22.1287821 },
    { lat: 48.6252163, lng: 22.1288579 },
    { lat: 48.6253335, lng: 22.1301691 },
    { lat: 48.6249442, lng: 22.1301621 },
    { lat: 48.6244666, lng: 22.1308982 },
    { lat: 48.6240914, lng: 22.1327031 },
    { lat: 48.6237719, lng: 22.1328026 },
    { lat: 48.6233779, lng: 22.1335521 },
    { lat: 48.6233291, lng: 22.1341165 },
    { lat: 48.6230013, lng: 22.1346765 },
    { lat: 48.6229511, lng: 22.1348381 },
    { lat: 48.6228356, lng: 22.1356254 },
    { lat: 48.622864, lng: 22.1357337 },
    { lat: 48.6227518, lng: 22.1366606 },
    { lat: 48.622438, lng: 22.1370946 },
    { lat: 48.62229, lng: 22.1375007 },
    { lat: 48.6217567, lng: 22.1374021 },
    { lat: 48.6216623, lng: 22.1382281 },
    { lat: 48.621497, lng: 22.1384662 },
    { lat: 48.6212615, lng: 22.1385999 },
    { lat: 48.6210769, lng: 22.1387896 },
    { lat: 48.6204171, lng: 22.1402538 },
    { lat: 48.6203574, lng: 22.1403399 },
    { lat: 48.6202913, lng: 22.1403053 },
    { lat: 48.6202809, lng: 22.1403958 },
    { lat: 48.620222, lng: 22.1403783 },
    { lat: 48.6195602, lng: 22.1422426 },
    { lat: 48.6190546, lng: 22.1432862 },
    { lat: 48.6186862, lng: 22.1442308 },
    { lat: 48.6183639, lng: 22.1445891 },
    { lat: 48.6181106, lng: 22.1447664 },
    { lat: 48.6176757, lng: 22.1449384 },
    { lat: 48.6174905, lng: 22.1476174 },
    { lat: 48.6172283, lng: 22.1477585 },
    { lat: 48.6161858, lng: 22.1480525 },
    { lat: 48.6156199, lng: 22.1481489 },
    { lat: 48.615283, lng: 22.1481251 },
    { lat: 48.6152598, lng: 22.1484339 },
    { lat: 48.6149146, lng: 22.148824 },
    { lat: 48.6147661, lng: 22.1492605 },
    { lat: 48.614566, lng: 22.1494556 },
    { lat: 48.6142686, lng: 22.1494376 },
    { lat: 48.6139103, lng: 22.1497519 },
    { lat: 48.6138463, lng: 22.1525683 },
    { lat: 48.6141745, lng: 22.154697 },
    { lat: 48.6143274, lng: 22.1568699 },
    { lat: 48.6141803, lng: 22.1587336 },
    { lat: 48.6139612, lng: 22.1586975 },
    { lat: 48.6138988, lng: 22.159158 },
    { lat: 48.6136317, lng: 22.159188 },
    { lat: 48.6134932, lng: 22.1599045 },
    { lat: 48.6137314, lng: 22.1618862 },
    { lat: 48.6135128, lng: 22.1628924 },
    { lat: 48.6137397, lng: 22.1653148 },
    { lat: 48.6141479, lng: 22.1652621 },
    { lat: 48.6145109, lng: 22.1660706 },
    { lat: 48.6147497, lng: 22.1662866 },
    { lat: 48.6151543, lng: 22.16636 },
    { lat: 48.615558, lng: 22.1663071 },
    { lat: 48.6159946, lng: 22.1664641 },
    { lat: 48.6162593, lng: 22.1667694 },
    { lat: 48.6163911, lng: 22.1674159 },
    { lat: 48.6177352, lng: 22.166436 },
    { lat: 48.6177743, lng: 22.1664748 },
    { lat: 48.6188388, lng: 22.1662433 },
    { lat: 48.6190124, lng: 22.1662587 },
    { lat: 48.6190827, lng: 22.1663304 },
    { lat: 48.6195678, lng: 22.167412 },
    { lat: 48.6205059, lng: 22.1678502 },
    { lat: 48.6142172, lng: 22.1831734 },
    { lat: 48.6212642, lng: 22.1858164 },
    { lat: 48.6217134, lng: 22.1859857 },
    { lat: 48.6222328, lng: 22.1859203 },
    { lat: 48.6224827, lng: 22.185882 },
    { lat: 48.6243074, lng: 22.1847124 },
    { lat: 48.6241973, lng: 22.1837347 },
    { lat: 48.6239259, lng: 22.1821585 },
    { lat: 48.6247642, lng: 22.1814733 },
    { lat: 48.6252384, lng: 22.1815527 },
    { lat: 48.6256535, lng: 22.1815005 },
    { lat: 48.626158, lng: 22.1807953 },
    { lat: 48.6306444, lng: 22.1790568 },
    { lat: 48.6329527, lng: 22.1817029 },
    { lat: 48.6332236, lng: 22.1812552 },
    { lat: 48.6333668, lng: 22.1811251 },
    { lat: 48.6337249, lng: 22.180995 },
    { lat: 48.6339811, lng: 22.1801915 },
    { lat: 48.6340841, lng: 22.1796363 },
    { lat: 48.6341905, lng: 22.1793775 },
    { lat: 48.6341884, lng: 22.1791308 },
    { lat: 48.6344765, lng: 22.1785496 },
    { lat: 48.6340562, lng: 22.177785 },
    { lat: 48.6340061, lng: 22.1767744 },
    { lat: 48.6341978, lng: 22.176216 },
    { lat: 48.6342106, lng: 22.1759838 },
    { lat: 48.6340792, lng: 22.175262 },
    { lat: 48.6345069, lng: 22.1739526 },
    { lat: 48.634584, lng: 22.1736381 },
    { lat: 48.6345915, lng: 22.1731836 },
    { lat: 48.6427018, lng: 22.1838158 },
    { lat: 48.6443126, lng: 22.1811171 },
    { lat: 48.644422, lng: 22.181132 },
    { lat: 48.6445936, lng: 22.180083 },
    { lat: 48.6448653, lng: 22.1785908 },
    { lat: 48.645182, lng: 22.1776317 },
    { lat: 48.64563, lng: 22.177011 },
    { lat: 48.6464813, lng: 22.1760733 },
    { lat: 48.6474142, lng: 22.1746674 },
    { lat: 48.653797, lng: 22.1747885 },
    { lat: 48.6538529, lng: 22.1737442 },
    { lat: 48.6537114, lng: 22.173118 },
    { lat: 48.6537615, lng: 22.1723128 },
    { lat: 48.6536048, lng: 22.1717109 },
    { lat: 48.6536518, lng: 22.1715533 },
    { lat: 48.6536101, lng: 22.1712061 },
    { lat: 48.6533753, lng: 22.1703443 },
    { lat: 48.6532967, lng: 22.1698575 },
    { lat: 48.6532959, lng: 22.1682687 },
    { lat: 48.6535334, lng: 22.167396 },
    { lat: 48.6534961, lng: 22.1665545 },
    { lat: 48.6536368, lng: 22.1660206 },
    { lat: 48.6536927, lng: 22.1655644 },
    { lat: 48.6540028, lng: 22.1652624 },
    { lat: 48.6546536, lng: 22.1652424 },
    { lat: 48.6548638, lng: 22.1651378 },
    { lat: 48.6550586, lng: 22.1648486 },
    { lat: 48.6553679, lng: 22.1642148 },
    { lat: 48.6558072, lng: 22.163753 },
    { lat: 48.6559958, lng: 22.1633478 },
    { lat: 48.6559944, lng: 22.1630398 },
    { lat: 48.6558465, lng: 22.1620474 },
    { lat: 48.6559444, lng: 22.1618036 },
    { lat: 48.6561058, lng: 22.1616805 },
    { lat: 48.6566708, lng: 22.1614526 },
    { lat: 48.6570284, lng: 22.1611086 },
    { lat: 48.657677, lng: 22.1603084 },
    { lat: 48.6578475, lng: 22.1599049 },
    { lat: 48.6579795, lng: 22.1589977 },
    { lat: 48.657903, lng: 22.1582582 },
    { lat: 48.6579257, lng: 22.15754 },
    { lat: 48.6577604, lng: 22.1570058 },
    { lat: 48.6574086, lng: 22.1566337 },
    { lat: 48.6568557, lng: 22.1563099 },
    { lat: 48.6561628, lng: 22.1561753 },
    { lat: 48.6558163, lng: 22.1560218 },
    { lat: 48.6554818, lng: 22.1556637 },
    { lat: 48.6552642, lng: 22.1552554 },
    { lat: 48.6552533, lng: 22.1550809 },
    { lat: 48.6553904, lng: 22.1546393 },
    { lat: 48.6557652, lng: 22.1539656 },
    { lat: 48.6559101, lng: 22.1535658 },
    { lat: 48.6558951, lng: 22.1530607 },
    { lat: 48.6557183, lng: 22.1528192 },
    { lat: 48.6557185, lng: 22.1520952 },
    { lat: 48.6556077, lng: 22.1515343 },
    { lat: 48.6556848, lng: 22.1505415 },
    { lat: 48.6557905, lng: 22.1502997 },
    { lat: 48.6558906, lng: 22.1502076 },
    { lat: 48.6559612, lng: 22.1499993 },
    { lat: 48.6559074, lng: 22.1493229 },
    { lat: 48.6560503, lng: 22.148892 },
    { lat: 48.6561634, lng: 22.1487248 },
    { lat: 48.6564615, lng: 22.1484803 },
    { lat: 48.6566617, lng: 22.147946 },
    { lat: 48.6568528, lng: 22.1476598 },
    { lat: 48.6570264, lng: 22.1471347 },
    { lat: 48.657222, lng: 22.1462594 },
    { lat: 48.6573483, lng: 22.1461279 },
    { lat: 48.6577452, lng: 22.1459218 },
    { lat: 48.6580442, lng: 22.1434517 },
    { lat: 48.6584175, lng: 22.1427217 },
    { lat: 48.6587068, lng: 22.1423908 },
    { lat: 48.6591798, lng: 22.1419793 },
    { lat: 48.6596381, lng: 22.1412253 },
    { lat: 48.6598007, lng: 22.1410601 },
    { lat: 48.66048, lng: 22.1407152 },
    { lat: 48.6608354, lng: 22.1403288 },
    { lat: 48.6616493, lng: 22.1399629 },
    { lat: 48.6626081, lng: 22.1390669 },
    { lat: 48.6628946, lng: 22.1385685 },
    { lat: 48.6641133, lng: 22.1375294 },
    { lat: 48.6641777, lng: 22.1376417 },
    { lat: 48.6683593, lng: 22.1360149 },
    { lat: 48.6685355, lng: 22.1365764 },
    { lat: 48.6686946, lng: 22.1367893 },
    { lat: 48.6691286, lng: 22.1369737 },
    { lat: 48.6701021, lng: 22.1376302 },
    { lat: 48.6703452, lng: 22.1377251 },
    { lat: 48.6713866, lng: 22.1390709 },
    { lat: 48.6715852, lng: 22.1395298 },
    { lat: 48.6726303, lng: 22.139921 },
    { lat: 48.6727633, lng: 22.1398324 },
    { lat: 48.6731196, lng: 22.1392419 },
    { lat: 48.6743216, lng: 22.1368095 },
    { lat: 48.6744046, lng: 22.136851 },
    { lat: 48.6744435, lng: 22.1365866 },
    { lat: 48.6748438, lng: 22.1358521 },
    { lat: 48.6751738, lng: 22.1351042 },
    { lat: 48.6755455, lng: 22.1331012 },
    { lat: 48.6760826, lng: 22.1308563 },
    { lat: 48.6815553, lng: 22.1214449 },
    { lat: 48.6817678, lng: 22.1208679 },
    { lat: 48.6818268, lng: 22.120507 },
    { lat: 48.6819058, lng: 22.1157695 },
    { lat: 48.6818786, lng: 22.1154733 },
    { lat: 48.681846, lng: 22.1151373 },
  ],
  Fekišovce: [
    { lat: 48.7638558, lng: 22.1073238 },
    { lat: 48.7638958, lng: 22.1073501 },
    { lat: 48.7641901, lng: 22.1073874 },
    { lat: 48.7667328, lng: 22.1064522 },
    { lat: 48.769759, lng: 22.1063282 },
    { lat: 48.7715483, lng: 22.1064084 },
    { lat: 48.7735784, lng: 22.1063551 },
    { lat: 48.7738025, lng: 22.1064122 },
    { lat: 48.7758225, lng: 22.1061605 },
    { lat: 48.7779609, lng: 22.1057828 },
    { lat: 48.7792417, lng: 22.1054303 },
    { lat: 48.7816511, lng: 22.1040465 },
    { lat: 48.7843956, lng: 22.1040444 },
    { lat: 48.7866026, lng: 22.1039472 },
    { lat: 48.7862503, lng: 22.1016491 },
    { lat: 48.7857284, lng: 22.0993034 },
    { lat: 48.7853688, lng: 22.0966012 },
    { lat: 48.7844974, lng: 22.094145 },
    { lat: 48.7843107, lng: 22.0927198 },
    { lat: 48.7848387, lng: 22.0914549 },
    { lat: 48.7847864, lng: 22.0900998 },
    { lat: 48.7856499, lng: 22.0900352 },
    { lat: 48.7856615, lng: 22.0892394 },
    { lat: 48.7855264, lng: 22.0884724 },
    { lat: 48.7852476, lng: 22.0880534 },
    { lat: 48.785091, lng: 22.087544 },
    { lat: 48.784993, lng: 22.087217 },
    { lat: 48.784816, lng: 22.087107 },
    { lat: 48.784593, lng: 22.087146 },
    { lat: 48.784371, lng: 22.087476 },
    { lat: 48.784157, lng: 22.087646 },
    { lat: 48.783846, lng: 22.087618 },
    { lat: 48.783745, lng: 22.08755 },
    { lat: 48.783343, lng: 22.087278 },
    { lat: 48.783058, lng: 22.086631 },
    { lat: 48.783007, lng: 22.086517 },
    { lat: 48.782702, lng: 22.085827 },
    { lat: 48.782581, lng: 22.08569 },
    { lat: 48.782486, lng: 22.085583 },
    { lat: 48.781898, lng: 22.084416 },
    { lat: 48.781841, lng: 22.083876 },
    { lat: 48.781287, lng: 22.083314 },
    { lat: 48.78118, lng: 22.083316 },
    { lat: 48.781068, lng: 22.083319 },
    { lat: 48.781025, lng: 22.08332 },
    { lat: 48.780762, lng: 22.083327 },
    { lat: 48.780123, lng: 22.083129 },
    { lat: 48.779724, lng: 22.083098 },
    { lat: 48.779689, lng: 22.083095 },
    { lat: 48.779426, lng: 22.083097 },
    { lat: 48.779375, lng: 22.08258 },
    { lat: 48.779479, lng: 22.081774 },
    { lat: 48.779315, lng: 22.081197 },
    { lat: 48.779114, lng: 22.080745 },
    { lat: 48.779064, lng: 22.080313 },
    { lat: 48.778787, lng: 22.07998 },
    { lat: 48.778962, lng: 22.07987 },
    { lat: 48.778953, lng: 22.079305 },
    { lat: 48.778773, lng: 22.079484 },
    { lat: 48.778718, lng: 22.079429 },
    { lat: 48.777843, lng: 22.079633 },
    { lat: 48.777683, lng: 22.079015 },
    { lat: 48.777596, lng: 22.078454 },
    { lat: 48.777461, lng: 22.078368 },
    { lat: 48.777339, lng: 22.078345 },
    { lat: 48.777259, lng: 22.078282 },
    { lat: 48.777134, lng: 22.078123 },
    { lat: 48.776659, lng: 22.077078 },
    { lat: 48.776506, lng: 22.076887 },
    { lat: 48.776354, lng: 22.076764 },
    { lat: 48.776284, lng: 22.076714 },
    { lat: 48.776225, lng: 22.076682 },
    { lat: 48.776129, lng: 22.07663 },
    { lat: 48.775951, lng: 22.076547 },
    { lat: 48.775232, lng: 22.076461 },
    { lat: 48.775167, lng: 22.076454 },
    { lat: 48.775161, lng: 22.076453 },
    { lat: 48.775005, lng: 22.077014 },
    { lat: 48.770953, lng: 22.07762 },
    { lat: 48.767434, lng: 22.078145 },
    { lat: 48.767088, lng: 22.07729 },
    { lat: 48.766979, lng: 22.077176 },
    { lat: 48.766284, lng: 22.077363 },
    { lat: 48.766094, lng: 22.077413 },
    { lat: 48.76611, lng: 22.077431 },
    { lat: 48.765872, lng: 22.077546 },
    { lat: 48.765107, lng: 22.077714 },
    { lat: 48.764889, lng: 22.077737 },
    { lat: 48.764622, lng: 22.077709 },
    { lat: 48.764336, lng: 22.077824 },
    { lat: 48.764086, lng: 22.078025 },
    { lat: 48.763413, lng: 22.078329 },
    { lat: 48.762751, lng: 22.078724 },
    { lat: 48.762123, lng: 22.078995 },
    { lat: 48.761941, lng: 22.079122 },
    { lat: 48.761988, lng: 22.079265 },
    { lat: 48.761856, lng: 22.079313 },
    { lat: 48.761358, lng: 22.079294 },
    { lat: 48.761453, lng: 22.07984 },
    { lat: 48.761605, lng: 22.080935 },
    { lat: 48.761802, lng: 22.081969 },
    { lat: 48.761973, lng: 22.083163 },
    { lat: 48.762088, lng: 22.084225 },
    { lat: 48.762245, lng: 22.085985 },
    { lat: 48.762303, lng: 22.086342 },
    { lat: 48.762371, lng: 22.086621 },
    { lat: 48.762255, lng: 22.086691 },
    { lat: 48.762349, lng: 22.087182 },
    { lat: 48.7624, lng: 22.087721 },
    { lat: 48.762492, lng: 22.088827 },
    { lat: 48.762579, lng: 22.089797 },
    { lat: 48.762694, lng: 22.090814 },
    { lat: 48.762926, lng: 22.092483 },
    { lat: 48.762605, lng: 22.092582 },
    { lat: 48.762784, lng: 22.093821 },
    { lat: 48.762839, lng: 22.094573 },
    { lat: 48.762919, lng: 22.095476 },
    { lat: 48.76321, lng: 22.095456 },
    { lat: 48.763639, lng: 22.095477 },
    { lat: 48.763527, lng: 22.095803 },
    { lat: 48.763459, lng: 22.096179 },
    { lat: 48.763388, lng: 22.096192 },
    { lat: 48.763275, lng: 22.09688 },
    { lat: 48.762382, lng: 22.098577 },
    { lat: 48.7647446, lng: 22.0985086 },
    { lat: 48.7642775, lng: 22.1049835 },
    { lat: 48.7641338, lng: 22.1059461 },
    { lat: 48.7638558, lng: 22.1073238 },
  ],
  Choňkovce: [
    { lat: 48.7638399, lng: 22.2208337 },
    { lat: 48.7633532, lng: 22.2219954 },
    { lat: 48.7631843, lng: 22.2225835 },
    { lat: 48.7630306, lng: 22.222446 },
    { lat: 48.7626465, lng: 22.224525 },
    { lat: 48.7628716, lng: 22.2247755 },
    { lat: 48.7632577, lng: 22.2247445 },
    { lat: 48.7633513, lng: 22.224959 },
    { lat: 48.7635149, lng: 22.2250293 },
    { lat: 48.7635127, lng: 22.2251343 },
    { lat: 48.7636256, lng: 22.2253506 },
    { lat: 48.7638383, lng: 22.2254533 },
    { lat: 48.763967, lng: 22.2256713 },
    { lat: 48.7639787, lng: 22.2258483 },
    { lat: 48.7641026, lng: 22.2259075 },
    { lat: 48.764198, lng: 22.2258384 },
    { lat: 48.7643623, lng: 22.2258503 },
    { lat: 48.7644826, lng: 22.226243 },
    { lat: 48.7647979, lng: 22.2264621 },
    { lat: 48.7648255, lng: 22.2265334 },
    { lat: 48.7650171, lng: 22.2264505 },
    { lat: 48.7650754, lng: 22.2265477 },
    { lat: 48.7651923, lng: 22.2265463 },
    { lat: 48.7652304, lng: 22.2266402 },
    { lat: 48.765428, lng: 22.2267681 },
    { lat: 48.7657651, lng: 22.2269055 },
    { lat: 48.7662704, lng: 22.2276175 },
    { lat: 48.7664578, lng: 22.2277437 },
    { lat: 48.7664205, lng: 22.2278911 },
    { lat: 48.766652, lng: 22.2283862 },
    { lat: 48.7663231, lng: 22.2295633 },
    { lat: 48.7658628, lng: 22.2309525 },
    { lat: 48.7669571, lng: 22.2315056 },
    { lat: 48.766985, lng: 22.2320476 },
    { lat: 48.7663168, lng: 22.2343459 },
    { lat: 48.7658493, lng: 22.2343107 },
    { lat: 48.7657576, lng: 22.2346862 },
    { lat: 48.7659316, lng: 22.2349208 },
    { lat: 48.7661761, lng: 22.2350944 },
    { lat: 48.7664089, lng: 22.2351413 },
    { lat: 48.766813, lng: 22.2356536 },
    { lat: 48.7642888, lng: 22.2422811 },
    { lat: 48.7642675, lng: 22.242341 },
    { lat: 48.7647949, lng: 22.2420834 },
    { lat: 48.7651226, lng: 22.2419942 },
    { lat: 48.7711322, lng: 22.2422484 },
    { lat: 48.7712522, lng: 22.2424312 },
    { lat: 48.7713524, lng: 22.2422835 },
    { lat: 48.7717674, lng: 22.2428805 },
    { lat: 48.7717978, lng: 22.2430419 },
    { lat: 48.7721465, lng: 22.2435408 },
    { lat: 48.7726608, lng: 22.2440611 },
    { lat: 48.7730768, lng: 22.2443565 },
    { lat: 48.7738504, lng: 22.2431327 },
    { lat: 48.7741098, lng: 22.2436233 },
    { lat: 48.7740894, lng: 22.2442616 },
    { lat: 48.7742485, lng: 22.2445912 },
    { lat: 48.77441, lng: 22.2458267 },
    { lat: 48.7741774, lng: 22.2466517 },
    { lat: 48.7742424, lng: 22.2472406 },
    { lat: 48.7742246, lng: 22.2475511 },
    { lat: 48.7744326, lng: 22.2480001 },
    { lat: 48.7745748, lng: 22.248174 },
    { lat: 48.7746501, lng: 22.2484747 },
    { lat: 48.7761987, lng: 22.2461961 },
    { lat: 48.7765208, lng: 22.2459193 },
    { lat: 48.7770829, lng: 22.2459484 },
    { lat: 48.7774196, lng: 22.2461241 },
    { lat: 48.7772002, lng: 22.2466217 },
    { lat: 48.7773096, lng: 22.2468358 },
    { lat: 48.7775279, lng: 22.2469933 },
    { lat: 48.7775154, lng: 22.2476409 },
    { lat: 48.7776596, lng: 22.2478593 },
    { lat: 48.7778689, lng: 22.2484634 },
    { lat: 48.7779957, lng: 22.2491588 },
    { lat: 48.7781612, lng: 22.2494673 },
    { lat: 48.7781522, lng: 22.2497724 },
    { lat: 48.7784074, lng: 22.2505735 },
    { lat: 48.7784126, lng: 22.251105 },
    { lat: 48.7783537, lng: 22.2512449 },
    { lat: 48.7784671, lng: 22.252038 },
    { lat: 48.7785141, lng: 22.2522849 },
    { lat: 48.7786173, lng: 22.2523167 },
    { lat: 48.778692, lng: 22.2526991 },
    { lat: 48.7785469, lng: 22.2529002 },
    { lat: 48.7784815, lng: 22.2533946 },
    { lat: 48.7784068, lng: 22.2534822 },
    { lat: 48.778389, lng: 22.2536846 },
    { lat: 48.7781778, lng: 22.2541005 },
    { lat: 48.7780609, lng: 22.254513 },
    { lat: 48.7779366, lng: 22.2554946 },
    { lat: 48.7778445, lng: 22.2557072 },
    { lat: 48.7779184, lng: 22.2562994 },
    { lat: 48.7777889, lng: 22.257046 },
    { lat: 48.7778752, lng: 22.2571318 },
    { lat: 48.7776499, lng: 22.2583847 },
    { lat: 48.7780744, lng: 22.2582058 },
    { lat: 48.7789416, lng: 22.2576471 },
    { lat: 48.7788798, lng: 22.2586096 },
    { lat: 48.7801171, lng: 22.2591272 },
    { lat: 48.7810089, lng: 22.2573936 },
    { lat: 48.781268, lng: 22.2574624 },
    { lat: 48.7813283, lng: 22.257694 },
    { lat: 48.7813215, lng: 22.2579476 },
    { lat: 48.7867504, lng: 22.2655359 },
    { lat: 48.7876072, lng: 22.266392 },
    { lat: 48.788314, lng: 22.2673257 },
    { lat: 48.7894308, lng: 22.2685446 },
    { lat: 48.7916306, lng: 22.2729659 },
    { lat: 48.7923855, lng: 22.2740953 },
    { lat: 48.7928711, lng: 22.2745919 },
    { lat: 48.7932612, lng: 22.2753952 },
    { lat: 48.7933148, lng: 22.2758901 },
    { lat: 48.7934621, lng: 22.2766082 },
    { lat: 48.7935395, lng: 22.2763857 },
    { lat: 48.7939621, lng: 22.2773496 },
    { lat: 48.7940136, lng: 22.2776442 },
    { lat: 48.7940045, lng: 22.2779489 },
    { lat: 48.794064, lng: 22.278196 },
    { lat: 48.7943491, lng: 22.2790813 },
    { lat: 48.7945892, lng: 22.28018 },
    { lat: 48.7947284, lng: 22.2804241 },
    { lat: 48.7946929, lng: 22.2807473 },
    { lat: 48.794864, lng: 22.2817882 },
    { lat: 48.7951066, lng: 22.2828398 },
    { lat: 48.7954111, lng: 22.2836446 },
    { lat: 48.7954229, lng: 22.2835687 },
    { lat: 48.7963708, lng: 22.2828938 },
    { lat: 48.7967276, lng: 22.2825483 },
    { lat: 48.7972923, lng: 22.2826019 },
    { lat: 48.7976472, lng: 22.2830935 },
    { lat: 48.7980127, lng: 22.2830359 },
    { lat: 48.8002481, lng: 22.2831055 },
    { lat: 48.8009359, lng: 22.2832368 },
    { lat: 48.8012894, lng: 22.2827958 },
    { lat: 48.8018546, lng: 22.2829286 },
    { lat: 48.8023903, lng: 22.2829355 },
    { lat: 48.8027976, lng: 22.2825311 },
    { lat: 48.803548, lng: 22.2820798 },
    { lat: 48.8035557, lng: 22.282074 },
    { lat: 48.8038537, lng: 22.2804823 },
    { lat: 48.8036776, lng: 22.2801069 },
    { lat: 48.8038563, lng: 22.2795327 },
    { lat: 48.8043537, lng: 22.27929 },
    { lat: 48.8053882, lng: 22.2784157 },
    { lat: 48.8058737, lng: 22.2782427 },
    { lat: 48.8062778, lng: 22.2779501 },
    { lat: 48.8066065, lng: 22.2780609 },
    { lat: 48.8071052, lng: 22.2775715 },
    { lat: 48.8077052, lng: 22.277234 },
    { lat: 48.8080893, lng: 22.277576 },
    { lat: 48.808734, lng: 22.2782932 },
    { lat: 48.808996, lng: 22.2784391 },
    { lat: 48.8094182, lng: 22.2783075 },
    { lat: 48.8101701, lng: 22.2777193 },
    { lat: 48.8105183, lng: 22.2769317 },
    { lat: 48.8114862, lng: 22.2757947 },
    { lat: 48.8127215, lng: 22.2758611 },
    { lat: 48.8134727, lng: 22.2761525 },
    { lat: 48.8140141, lng: 22.2759735 },
    { lat: 48.8145135, lng: 22.2759711 },
    { lat: 48.8153096, lng: 22.2755101 },
    { lat: 48.8162784, lng: 22.2751624 },
    { lat: 48.8167508, lng: 22.2746535 },
    { lat: 48.816981, lng: 22.2738264 },
    { lat: 48.8173047, lng: 22.2730509 },
    { lat: 48.8177206, lng: 22.2729831 },
    { lat: 48.818529, lng: 22.2733794 },
    { lat: 48.8190005, lng: 22.2734905 },
    { lat: 48.819479, lng: 22.2731618 },
    { lat: 48.8200418, lng: 22.2730241 },
    { lat: 48.8219334, lng: 22.2713894 },
    { lat: 48.8224496, lng: 22.2713789 },
    { lat: 48.8229203, lng: 22.2712105 },
    { lat: 48.8238242, lng: 22.2705294 },
    { lat: 48.8242571, lng: 22.2697058 },
    { lat: 48.8250919, lng: 22.2694147 },
    { lat: 48.825386, lng: 22.2685717 },
    { lat: 48.8255372, lng: 22.2677787 },
    { lat: 48.8257528, lng: 22.2670517 },
    { lat: 48.8263494, lng: 22.2658496 },
    { lat: 48.8266938, lng: 22.2654569 },
    { lat: 48.8270138, lng: 22.2648692 },
    { lat: 48.8272988, lng: 22.2641808 },
    { lat: 48.8282544, lng: 22.2638017 },
    { lat: 48.8286898, lng: 22.264018 },
    { lat: 48.8291399, lng: 22.2635182 },
    { lat: 48.8294683, lng: 22.2630621 },
    { lat: 48.8297291, lng: 22.2624413 },
    { lat: 48.8310505, lng: 22.261723 },
    { lat: 48.8315226, lng: 22.2616611 },
    { lat: 48.8319292, lng: 22.2611842 },
    { lat: 48.8325018, lng: 22.2607749 },
    { lat: 48.833329, lng: 22.2596871 },
    { lat: 48.8335897, lng: 22.2594226 },
    { lat: 48.8338033, lng: 22.2586605 },
    { lat: 48.8343299, lng: 22.2582606 },
    { lat: 48.8347904, lng: 22.2582268 },
    { lat: 48.8350746, lng: 22.2580664 },
    { lat: 48.8354949, lng: 22.2576447 },
    { lat: 48.8360515, lng: 22.2573829 },
    { lat: 48.836364, lng: 22.2568621 },
    { lat: 48.8364243, lng: 22.2561946 },
    { lat: 48.8365696, lng: 22.2556885 },
    { lat: 48.8369699, lng: 22.2552253 },
    { lat: 48.8370826, lng: 22.2547404 },
    { lat: 48.83715, lng: 22.2541251 },
    { lat: 48.8368423, lng: 22.254044 },
    { lat: 48.8366856, lng: 22.2537721 },
    { lat: 48.8363102, lng: 22.2535291 },
    { lat: 48.8361342, lng: 22.2529069 },
    { lat: 48.8358067, lng: 22.2521885 },
    { lat: 48.8355037, lng: 22.2520665 },
    { lat: 48.8353378, lng: 22.2517822 },
    { lat: 48.8348731, lng: 22.251626 },
    { lat: 48.834334, lng: 22.2515596 },
    { lat: 48.8334911, lng: 22.2513331 },
    { lat: 48.8330979, lng: 22.2513631 },
    { lat: 48.8321187, lng: 22.2512271 },
    { lat: 48.831235, lng: 22.2511919 },
    { lat: 48.8310479, lng: 22.2509712 },
    { lat: 48.8303276, lng: 22.2506941 },
    { lat: 48.8301481, lng: 22.2504638 },
    { lat: 48.8285167, lng: 22.2496832 },
    { lat: 48.8279508, lng: 22.249017 },
    { lat: 48.8274033, lng: 22.2489322 },
    { lat: 48.8272403, lng: 22.2486832 },
    { lat: 48.8264877, lng: 22.2479706 },
    { lat: 48.8261649, lng: 22.2477625 },
    { lat: 48.8259231, lng: 22.2478705 },
    { lat: 48.8255304, lng: 22.247911 },
    { lat: 48.8252369, lng: 22.2476654 },
    { lat: 48.8247868, lng: 22.2476013 },
    { lat: 48.8241954, lng: 22.2477075 },
    { lat: 48.8233834, lng: 22.2476612 },
    { lat: 48.8223388, lng: 22.2478299 },
    { lat: 48.8215083, lng: 22.2480569 },
    { lat: 48.8211186, lng: 22.2484437 },
    { lat: 48.8208164, lng: 22.2485012 },
    { lat: 48.8204924, lng: 22.248707 },
    { lat: 48.8200365, lng: 22.2486913 },
    { lat: 48.8198691, lng: 22.2488509 },
    { lat: 48.8195775, lng: 22.2487819 },
    { lat: 48.8191484, lng: 22.24921 },
    { lat: 48.8189281, lng: 22.2491206 },
    { lat: 48.8188608, lng: 22.2490291 },
    { lat: 48.8183046, lng: 22.2489026 },
    { lat: 48.8181625, lng: 22.2487367 },
    { lat: 48.8179338, lng: 22.2487102 },
    { lat: 48.8177342, lng: 22.2488396 },
    { lat: 48.817256, lng: 22.2487845 },
    { lat: 48.8169925, lng: 22.2490178 },
    { lat: 48.8169003, lng: 22.2490268 },
    { lat: 48.8168052, lng: 22.2492026 },
    { lat: 48.8165003, lng: 22.249049 },
    { lat: 48.8163169, lng: 22.2490366 },
    { lat: 48.8162146, lng: 22.2489408 },
    { lat: 48.8160816, lng: 22.2489783 },
    { lat: 48.8159601, lng: 22.2491301 },
    { lat: 48.8159497, lng: 22.2492145 },
    { lat: 48.8157916, lng: 22.2493864 },
    { lat: 48.8157604, lng: 22.2496556 },
    { lat: 48.8155787, lng: 22.2497873 },
    { lat: 48.8154704, lng: 22.2499724 },
    { lat: 48.81516, lng: 22.2501742 },
    { lat: 48.8151259, lng: 22.2502605 },
    { lat: 48.8148865, lng: 22.2502638 },
    { lat: 48.814773, lng: 22.2501739 },
    { lat: 48.8146331, lng: 22.2498485 },
    { lat: 48.8140502, lng: 22.2496366 },
    { lat: 48.8137639, lng: 22.2491944 },
    { lat: 48.8134973, lng: 22.2478819 },
    { lat: 48.8132692, lng: 22.2476898 },
    { lat: 48.8131137, lng: 22.2473765 },
    { lat: 48.8130018, lng: 22.2473048 },
    { lat: 48.8127722, lng: 22.2469968 },
    { lat: 48.8127911, lng: 22.2466311 },
    { lat: 48.8126511, lng: 22.2462486 },
    { lat: 48.8125439, lng: 22.2461349 },
    { lat: 48.8121379, lng: 22.2452292 },
    { lat: 48.8120756, lng: 22.2446571 },
    { lat: 48.8118133, lng: 22.2444432 },
    { lat: 48.8117045, lng: 22.2442572 },
    { lat: 48.8116194, lng: 22.2439742 },
    { lat: 48.8113545, lng: 22.2437954 },
    { lat: 48.8111942, lng: 22.2433604 },
    { lat: 48.81092, lng: 22.2431236 },
    { lat: 48.8107978, lng: 22.2429411 },
    { lat: 48.8104622, lng: 22.2426125 },
    { lat: 48.8101976, lng: 22.2425529 },
    { lat: 48.8100687, lng: 22.2423735 },
    { lat: 48.8099009, lng: 22.2424171 },
    { lat: 48.8097007, lng: 22.2423588 },
    { lat: 48.8093043, lng: 22.2419395 },
    { lat: 48.8091005, lng: 22.2413719 },
    { lat: 48.8090229, lng: 22.2412756 },
    { lat: 48.8087906, lng: 22.2412475 },
    { lat: 48.8085534, lng: 22.2408022 },
    { lat: 48.8083864, lng: 22.2406248 },
    { lat: 48.8082136, lng: 22.2402468 },
    { lat: 48.808212, lng: 22.2397185 },
    { lat: 48.8081109, lng: 22.2394699 },
    { lat: 48.8079951, lng: 22.2392728 },
    { lat: 48.8074966, lng: 22.2389048 },
    { lat: 48.8073504, lng: 22.2386054 },
    { lat: 48.8074012, lng: 22.2382638 },
    { lat: 48.806925, lng: 22.2377972 },
    { lat: 48.8067309, lng: 22.2378125 },
    { lat: 48.8064971, lng: 22.2373168 },
    { lat: 48.806316, lng: 22.2371651 },
    { lat: 48.8059723, lng: 22.2370882 },
    { lat: 48.8058911, lng: 22.2369789 },
    { lat: 48.8057475, lng: 22.2370409 },
    { lat: 48.8054951, lng: 22.2368208 },
    { lat: 48.8051755, lng: 22.2368629 },
    { lat: 48.8050542, lng: 22.2366768 },
    { lat: 48.8048856, lng: 22.2367243 },
    { lat: 48.8046849, lng: 22.236908 },
    { lat: 48.804465, lng: 22.2367812 },
    { lat: 48.8043235, lng: 22.2365369 },
    { lat: 48.8040008, lng: 22.2366923 },
    { lat: 48.8037926, lng: 22.2365952 },
    { lat: 48.8036942, lng: 22.2367155 },
    { lat: 48.8032518, lng: 22.2365987 },
    { lat: 48.8030557, lng: 22.2366305 },
    { lat: 48.8029591, lng: 22.2367393 },
    { lat: 48.8026781, lng: 22.2364383 },
    { lat: 48.802344, lng: 22.2363413 },
    { lat: 48.8021795, lng: 22.2366758 },
    { lat: 48.8020964, lng: 22.2366822 },
    { lat: 48.802038, lng: 22.2364437 },
    { lat: 48.801656, lng: 22.2365072 },
    { lat: 48.8013374, lng: 22.2363961 },
    { lat: 48.8011229, lng: 22.2365099 },
    { lat: 48.8009205, lng: 22.2362602 },
    { lat: 48.8006536, lng: 22.2363481 },
    { lat: 48.8005472, lng: 22.236218 },
    { lat: 48.800442, lng: 22.2363425 },
    { lat: 48.800305, lng: 22.2362543 },
    { lat: 48.8000652, lng: 22.2363847 },
    { lat: 48.8000255, lng: 22.2361202 },
    { lat: 48.7997507, lng: 22.2362057 },
    { lat: 48.7993025, lng: 22.2359322 },
    { lat: 48.7991615, lng: 22.2360704 },
    { lat: 48.7989953, lng: 22.236025 },
    { lat: 48.7987167, lng: 22.2357924 },
    { lat: 48.798185, lng: 22.2360045 },
    { lat: 48.7981074, lng: 22.2356025 },
    { lat: 48.7979849, lng: 22.2354029 },
    { lat: 48.7978101, lng: 22.235281 },
    { lat: 48.7975517, lng: 22.235403 },
    { lat: 48.7973431, lng: 22.235133 },
    { lat: 48.7972512, lng: 22.2348384 },
    { lat: 48.797095, lng: 22.2347454 },
    { lat: 48.7970169, lng: 22.2348251 },
    { lat: 48.796933, lng: 22.2347409 },
    { lat: 48.7969073, lng: 22.2343002 },
    { lat: 48.7964144, lng: 22.2334857 },
    { lat: 48.7963978, lng: 22.2333446 },
    { lat: 48.7961814, lng: 22.2330813 },
    { lat: 48.7959906, lng: 22.2326966 },
    { lat: 48.7958078, lng: 22.2325133 },
    { lat: 48.795698, lng: 22.2321904 },
    { lat: 48.7957461, lng: 22.2320199 },
    { lat: 48.795455, lng: 22.2318301 },
    { lat: 48.7953553, lng: 22.2316015 },
    { lat: 48.7953339, lng: 22.2313493 },
    { lat: 48.795282, lng: 22.2312846 },
    { lat: 48.7953167, lng: 22.2308758 },
    { lat: 48.7954466, lng: 22.2304995 },
    { lat: 48.7952916, lng: 22.2303141 },
    { lat: 48.7953371, lng: 22.2302301 },
    { lat: 48.7952867, lng: 22.2297407 },
    { lat: 48.7950363, lng: 22.2296109 },
    { lat: 48.7950346, lng: 22.2295336 },
    { lat: 48.7951641, lng: 22.2294962 },
    { lat: 48.7952043, lng: 22.2293329 },
    { lat: 48.7952864, lng: 22.2293262 },
    { lat: 48.7952902, lng: 22.2292022 },
    { lat: 48.7954002, lng: 22.2289903 },
    { lat: 48.7955219, lng: 22.228952 },
    { lat: 48.7956221, lng: 22.2286047 },
    { lat: 48.7953781, lng: 22.2283112 },
    { lat: 48.7953797, lng: 22.228076 },
    { lat: 48.7954628, lng: 22.22802 },
    { lat: 48.7955382, lng: 22.2276201 },
    { lat: 48.7956774, lng: 22.2275655 },
    { lat: 48.7956507, lng: 22.2273218 },
    { lat: 48.7953345, lng: 22.2273652 },
    { lat: 48.7952631, lng: 22.2270435 },
    { lat: 48.7954042, lng: 22.2269117 },
    { lat: 48.7952478, lng: 22.2268131 },
    { lat: 48.7952865, lng: 22.2266766 },
    { lat: 48.7953408, lng: 22.2266902 },
    { lat: 48.7952946, lng: 22.2265863 },
    { lat: 48.7951732, lng: 22.2266496 },
    { lat: 48.7951127, lng: 22.2268118 },
    { lat: 48.7949617, lng: 22.2269391 },
    { lat: 48.7948199, lng: 22.2267863 },
    { lat: 48.7947648, lng: 22.226986 },
    { lat: 48.794613, lng: 22.2269446 },
    { lat: 48.7945911, lng: 22.226767 },
    { lat: 48.7947017, lng: 22.2265794 },
    { lat: 48.7945849, lng: 22.2263446 },
    { lat: 48.7943494, lng: 22.2264709 },
    { lat: 48.7942364, lng: 22.226853 },
    { lat: 48.7939365, lng: 22.227268 },
    { lat: 48.7938658, lng: 22.2275292 },
    { lat: 48.7937282, lng: 22.2276583 },
    { lat: 48.7934659, lng: 22.2277546 },
    { lat: 48.7931943, lng: 22.2277297 },
    { lat: 48.7926932, lng: 22.2274044 },
    { lat: 48.7925893, lng: 22.2274365 },
    { lat: 48.7924825, lng: 22.2272373 },
    { lat: 48.7924306, lng: 22.2269713 },
    { lat: 48.7922591, lng: 22.2268517 },
    { lat: 48.7919774, lng: 22.226136 },
    { lat: 48.7915707, lng: 22.2258463 },
    { lat: 48.7914993, lng: 22.2255203 },
    { lat: 48.7910382, lng: 22.2250265 },
    { lat: 48.7908717, lng: 22.225074 },
    { lat: 48.790599, lng: 22.2249585 },
    { lat: 48.790388, lng: 22.2247768 },
    { lat: 48.7903583, lng: 22.2245553 },
    { lat: 48.7900429, lng: 22.2242841 },
    { lat: 48.7899921, lng: 22.2242799 },
    { lat: 48.7898768, lng: 22.2246515 },
    { lat: 48.7894767, lng: 22.2246693 },
    { lat: 48.7894463, lng: 22.2248174 },
    { lat: 48.7892828, lng: 22.2247962 },
    { lat: 48.7891616, lng: 22.2245564 },
    { lat: 48.7890464, lng: 22.2244307 },
    { lat: 48.7889138, lng: 22.2243922 },
    { lat: 48.7889156, lng: 22.2243268 },
    { lat: 48.7886311, lng: 22.2240725 },
    { lat: 48.788061, lng: 22.2238776 },
    { lat: 48.7879648, lng: 22.2237484 },
    { lat: 48.7877901, lng: 22.2232869 },
    { lat: 48.7877317, lng: 22.2235917 },
    { lat: 48.7875703, lng: 22.2233635 },
    { lat: 48.7876219, lng: 22.2232581 },
    { lat: 48.7872097, lng: 22.2227879 },
    { lat: 48.7871443, lng: 22.2227834 },
    { lat: 48.7867036, lng: 22.2220977 },
    { lat: 48.7865973, lng: 22.2220647 },
    { lat: 48.7864472, lng: 22.2221409 },
    { lat: 48.786447, lng: 22.2219312 },
    { lat: 48.7858934, lng: 22.221078 },
    { lat: 48.7857339, lng: 22.2205855 },
    { lat: 48.7853286, lng: 22.2207187 },
    { lat: 48.7852143, lng: 22.2203933 },
    { lat: 48.785079, lng: 22.2195781 },
    { lat: 48.784852, lng: 22.2187413 },
    { lat: 48.7846585, lng: 22.2173396 },
    { lat: 48.7847955, lng: 22.2166837 },
    { lat: 48.7846215, lng: 22.2155014 },
    { lat: 48.7840899, lng: 22.2155193 },
    { lat: 48.7838579, lng: 22.2153062 },
    { lat: 48.7835014, lng: 22.2152045 },
    { lat: 48.7834497, lng: 22.2150163 },
    { lat: 48.7837325, lng: 22.2146889 },
    { lat: 48.7833258, lng: 22.2144043 },
    { lat: 48.7832338, lng: 22.2140245 },
    { lat: 48.7835146, lng: 22.2135581 },
    { lat: 48.7834863, lng: 22.2131483 },
    { lat: 48.783344, lng: 22.2130705 },
    { lat: 48.7828942, lng: 22.2131804 },
    { lat: 48.7822713, lng: 22.2147252 },
    { lat: 48.7816745, lng: 22.2142054 },
    { lat: 48.780395, lng: 22.214772 },
    { lat: 48.7783734, lng: 22.2164004 },
    { lat: 48.7772197, lng: 22.2151676 },
    { lat: 48.7748584, lng: 22.2188956 },
    { lat: 48.7746641, lng: 22.2198004 },
    { lat: 48.7738982, lng: 22.2211554 },
    { lat: 48.7740097, lng: 22.2217099 },
    { lat: 48.7722456, lng: 22.2243836 },
    { lat: 48.7721772, lng: 22.2242445 },
    { lat: 48.7716934, lng: 22.2246335 },
    { lat: 48.771759, lng: 22.2237076 },
    { lat: 48.7715051, lng: 22.2237153 },
    { lat: 48.7709106, lng: 22.22324 },
    { lat: 48.7706933, lng: 22.2236812 },
    { lat: 48.7706951, lng: 22.2238662 },
    { lat: 48.7700923, lng: 22.2256633 },
    { lat: 48.7698512, lng: 22.2252033 },
    { lat: 48.7693954, lng: 22.2246356 },
    { lat: 48.7686202, lng: 22.2241236 },
    { lat: 48.7680698, lng: 22.223504 },
    { lat: 48.7679318, lng: 22.2235226 },
    { lat: 48.7673915, lng: 22.2225542 },
    { lat: 48.7665567, lng: 22.2218018 },
    { lat: 48.7658212, lng: 22.2212535 },
    { lat: 48.7654942, lng: 22.2208732 },
    { lat: 48.7651078, lng: 22.2219339 },
    { lat: 48.7638399, lng: 22.2208337 },
  ],
  Petrovce: [
    { lat: 48.679183, lng: 22.313604 },
    { lat: 48.679548, lng: 22.314857 },
    { lat: 48.679868, lng: 22.316396 },
    { lat: 48.680852, lng: 22.317297 },
    { lat: 48.681577, lng: 22.31849 },
    { lat: 48.681838, lng: 22.32063 },
    { lat: 48.682276, lng: 22.321521 },
    { lat: 48.682982, lng: 22.325232 },
    { lat: 48.683009, lng: 22.326482 },
    { lat: 48.682475, lng: 22.328542 },
    { lat: 48.68271, lng: 22.332093 },
    { lat: 48.68307, lng: 22.333748 },
    { lat: 48.683721, lng: 22.335752 },
    { lat: 48.683652, lng: 22.337714 },
    { lat: 48.683893, lng: 22.339611 },
    { lat: 48.698584, lng: 22.345695 },
    { lat: 48.702965, lng: 22.34575 },
    { lat: 48.704223, lng: 22.345642 },
    { lat: 48.704828, lng: 22.345605 },
    { lat: 48.70589, lng: 22.34557 },
    { lat: 48.708864, lng: 22.347576 },
    { lat: 48.710665, lng: 22.348787 },
    { lat: 48.7108085, lng: 22.3488842 },
    { lat: 48.713137, lng: 22.350461 },
    { lat: 48.714263, lng: 22.351228 },
    { lat: 48.717838, lng: 22.353701 },
    { lat: 48.72032, lng: 22.350692 },
    { lat: 48.721938, lng: 22.352629 },
    { lat: 48.723207, lng: 22.354192 },
    { lat: 48.723829, lng: 22.354938 },
    { lat: 48.724809, lng: 22.356039 },
    { lat: 48.725646, lng: 22.356974 },
    { lat: 48.726144, lng: 22.36013 },
    { lat: 48.7302, lng: 22.361795 },
    { lat: 48.733149, lng: 22.361477 },
    { lat: 48.735315, lng: 22.360477 },
    { lat: 48.738547, lng: 22.360456 },
    { lat: 48.739491, lng: 22.361604 },
    { lat: 48.74, lng: 22.362227 },
    { lat: 48.741828, lng: 22.360124 },
    { lat: 48.742377, lng: 22.357532 },
    { lat: 48.742602, lng: 22.356486 },
    { lat: 48.742875, lng: 22.355843 },
    { lat: 48.743228, lng: 22.353908 },
    { lat: 48.744285, lng: 22.353117 },
    { lat: 48.745465, lng: 22.352504 },
    { lat: 48.746167, lng: 22.352687 },
    { lat: 48.746917, lng: 22.351081 },
    { lat: 48.748486, lng: 22.350522 },
    { lat: 48.749196, lng: 22.35006 },
    { lat: 48.749389, lng: 22.345178 },
    { lat: 48.7489829, lng: 22.3455011 },
    { lat: 48.748632, lng: 22.3458768 },
    { lat: 48.7478729, lng: 22.3462979 },
    { lat: 48.7464841, lng: 22.3478854 },
    { lat: 48.7441545, lng: 22.3489693 },
    { lat: 48.7435737, lng: 22.3496243 },
    { lat: 48.7429403, lng: 22.3496721 },
    { lat: 48.7420832, lng: 22.3484715 },
    { lat: 48.7413138, lng: 22.3464659 },
    { lat: 48.7406679, lng: 22.3453053 },
    { lat: 48.7397213, lng: 22.3444367 },
    { lat: 48.739435, lng: 22.3445576 },
    { lat: 48.7384795, lng: 22.3443708 },
    { lat: 48.7374943, lng: 22.3434931 },
    { lat: 48.7370199, lng: 22.3426078 },
    { lat: 48.7367423, lng: 22.3423556 },
    { lat: 48.7367317, lng: 22.3421245 },
    { lat: 48.7364989, lng: 22.3415488 },
    { lat: 48.7362661, lng: 22.3413956 },
    { lat: 48.7361738, lng: 22.3411279 },
    { lat: 48.7361119, lng: 22.341131 },
    { lat: 48.7358925, lng: 22.3408147 },
    { lat: 48.7356312, lng: 22.3401181 },
    { lat: 48.735513, lng: 22.3399593 },
    { lat: 48.7355062, lng: 22.3395894 },
    { lat: 48.7352292, lng: 22.3391994 },
    { lat: 48.7352013, lng: 22.3389574 },
    { lat: 48.7350674, lng: 22.3389547 },
    { lat: 48.7349299, lng: 22.3385674 },
    { lat: 48.7342647, lng: 22.3372936 },
    { lat: 48.7340626, lng: 22.3370459 },
    { lat: 48.7338283, lng: 22.3365106 },
    { lat: 48.7337894, lng: 22.3361736 },
    { lat: 48.7336785, lng: 22.3360355 },
    { lat: 48.7335472, lng: 22.3355286 },
    { lat: 48.733465, lng: 22.3353705 },
    { lat: 48.7334545, lng: 22.3348287 },
    { lat: 48.7333522, lng: 22.3346214 },
    { lat: 48.7333098, lng: 22.3343798 },
    { lat: 48.7333515, lng: 22.3340628 },
    { lat: 48.7331227, lng: 22.3336301 },
    { lat: 48.7332007, lng: 22.3330149 },
    { lat: 48.7330158, lng: 22.3324294 },
    { lat: 48.7329977, lng: 22.3320529 },
    { lat: 48.7327149, lng: 22.3315631 },
    { lat: 48.73272, lng: 22.3312857 },
    { lat: 48.7324869, lng: 22.3309035 },
    { lat: 48.7324193, lng: 22.3303268 },
    { lat: 48.7322696, lng: 22.3301596 },
    { lat: 48.7321379, lng: 22.3297938 },
    { lat: 48.7319314, lng: 22.329608 },
    { lat: 48.7319108, lng: 22.3292321 },
    { lat: 48.7317956, lng: 22.3291587 },
    { lat: 48.7317818, lng: 22.328782 },
    { lat: 48.7313751, lng: 22.3281195 },
    { lat: 48.7308837, lng: 22.3276065 },
    { lat: 48.7308654, lng: 22.3274378 },
    { lat: 48.730645, lng: 22.3268135 },
    { lat: 48.7306213, lng: 22.326027 },
    { lat: 48.7303015, lng: 22.3254934 },
    { lat: 48.7302711, lng: 22.325232 },
    { lat: 48.7300762, lng: 22.3250592 },
    { lat: 48.7299665, lng: 22.3248234 },
    { lat: 48.7299653, lng: 22.3243564 },
    { lat: 48.729845, lng: 22.3238408 },
    { lat: 48.7292505, lng: 22.3229211 },
    { lat: 48.7289206, lng: 22.3227497 },
    { lat: 48.7284597, lng: 22.3223971 },
    { lat: 48.7281392, lng: 22.3223018 },
    { lat: 48.728098, lng: 22.3221627 },
    { lat: 48.7277912, lng: 22.3217625 },
    { lat: 48.7272314, lng: 22.3214144 },
    { lat: 48.7271624, lng: 22.3212415 },
    { lat: 48.7270625, lng: 22.3211449 },
    { lat: 48.7265225, lng: 22.3208304 },
    { lat: 48.7263628, lng: 22.320465 },
    { lat: 48.7260267, lng: 22.3204204 },
    { lat: 48.7258894, lng: 22.3200581 },
    { lat: 48.7256749, lng: 22.3200213 },
    { lat: 48.7255723, lng: 22.3198583 },
    { lat: 48.7253757, lng: 22.3198128 },
    { lat: 48.7253165, lng: 22.3195699 },
    { lat: 48.7250538, lng: 22.3192289 },
    { lat: 48.7249196, lng: 22.3189071 },
    { lat: 48.724702, lng: 22.3185673 },
    { lat: 48.7245123, lng: 22.318073 },
    { lat: 48.7241921, lng: 22.317826 },
    { lat: 48.7238398, lng: 22.3177633 },
    { lat: 48.7236385, lng: 22.317244 },
    { lat: 48.7231447, lng: 22.3168268 },
    { lat: 48.7229779, lng: 22.3178531 },
    { lat: 48.7225516, lng: 22.3179505 },
    { lat: 48.7223421, lng: 22.31778 },
    { lat: 48.7219582, lng: 22.3169352 },
    { lat: 48.7210982, lng: 22.3161336 },
    { lat: 48.7206781, lng: 22.315067 },
    { lat: 48.7205607, lng: 22.3143846 },
    { lat: 48.7198761, lng: 22.3128492 },
    { lat: 48.7186134, lng: 22.3120326 },
    { lat: 48.7179629, lng: 22.3119149 },
    { lat: 48.7172838, lng: 22.3119341 },
    { lat: 48.7169732, lng: 22.311556 },
    { lat: 48.7164747, lng: 22.3111875 },
    { lat: 48.7154113, lng: 22.3101833 },
    { lat: 48.714337, lng: 22.3096619 },
    { lat: 48.7137047, lng: 22.3098056 },
    { lat: 48.7134101, lng: 22.3097102 },
    { lat: 48.7120628, lng: 22.309555 },
    { lat: 48.7097324, lng: 22.3069897 },
    { lat: 48.7074981, lng: 22.3086106 },
    { lat: 48.7066779, lng: 22.3086047 },
    { lat: 48.7058649, lng: 22.3081456 },
    { lat: 48.7045921, lng: 22.3081616 },
    { lat: 48.7036988, lng: 22.307689 },
    { lat: 48.7027822, lng: 22.3075364 },
    { lat: 48.6993677, lng: 22.3081205 },
    { lat: 48.6986925, lng: 22.3089762 },
    { lat: 48.6983019, lng: 22.3097405 },
    { lat: 48.6976479, lng: 22.3102306 },
    { lat: 48.6970806, lng: 22.3108377 },
    { lat: 48.6968922, lng: 22.3111134 },
    { lat: 48.6964306, lng: 22.3113998 },
    { lat: 48.6961944, lng: 22.311821 },
    { lat: 48.6944617, lng: 22.3133967 },
    { lat: 48.6932311, lng: 22.3135462 },
    { lat: 48.6879367, lng: 22.3120388 },
    { lat: 48.6840381, lng: 22.3122902 },
    { lat: 48.6835011, lng: 22.3123714 },
    { lat: 48.6813125, lng: 22.3132632 },
    { lat: 48.680419, lng: 22.3133937 },
    { lat: 48.6795387, lng: 22.3134153 },
    { lat: 48.679183, lng: 22.313604 },
  ],
  Úbrež: [
    { lat: 48.810346, lng: 22.141457 },
    { lat: 48.810376, lng: 22.141108 },
    { lat: 48.810187, lng: 22.140755 },
    { lat: 48.81034, lng: 22.140564 },
    { lat: 48.810551, lng: 22.140565 },
    { lat: 48.810859, lng: 22.140202 },
    { lat: 48.810966, lng: 22.139692 },
    { lat: 48.810846, lng: 22.13921 },
    { lat: 48.81101, lng: 22.138974 },
    { lat: 48.811027, lng: 22.138712 },
    { lat: 48.811008, lng: 22.138461 },
    { lat: 48.810907, lng: 22.138383 },
    { lat: 48.811058, lng: 22.137605 },
    { lat: 48.811173, lng: 22.137666 },
    { lat: 48.81113, lng: 22.138071 },
    { lat: 48.811489, lng: 22.13799 },
    { lat: 48.811834, lng: 22.138062 },
    { lat: 48.811977, lng: 22.137553 },
    { lat: 48.81212, lng: 22.137681 },
    { lat: 48.812641, lng: 22.137767 },
    { lat: 48.812813, lng: 22.137317 },
    { lat: 48.812739, lng: 22.136994 },
    { lat: 48.812908, lng: 22.136706 },
    { lat: 48.812887, lng: 22.136456 },
    { lat: 48.812998, lng: 22.136471 },
    { lat: 48.813013, lng: 22.136001 },
    { lat: 48.812938, lng: 22.135809 },
    { lat: 48.813022, lng: 22.135654 },
    { lat: 48.813061, lng: 22.135007 },
    { lat: 48.812831, lng: 22.134705 },
    { lat: 48.812913, lng: 22.134377 },
    { lat: 48.812727, lng: 22.134256 },
    { lat: 48.812617, lng: 22.134058 },
    { lat: 48.812745, lng: 22.133906 },
    { lat: 48.812796, lng: 22.133703 },
    { lat: 48.812615, lng: 22.1334 },
    { lat: 48.81282, lng: 22.133268 },
    { lat: 48.812848, lng: 22.132809 },
    { lat: 48.813064, lng: 22.132416 },
    { lat: 48.813079, lng: 22.131287 },
    { lat: 48.812964, lng: 22.130651 },
    { lat: 48.812879, lng: 22.130248 },
    { lat: 48.812838, lng: 22.12978 },
    { lat: 48.812735, lng: 22.12939 },
    { lat: 48.812858, lng: 22.129027 },
    { lat: 48.812701, lng: 22.128597 },
    { lat: 48.812787, lng: 22.128364 },
    { lat: 48.812659, lng: 22.128001 },
    { lat: 48.812695, lng: 22.127806 },
    { lat: 48.812813, lng: 22.12766 },
    { lat: 48.81291, lng: 22.127415 },
    { lat: 48.812749, lng: 22.126996 },
    { lat: 48.812825, lng: 22.126389 },
    { lat: 48.812922, lng: 22.125852 },
    { lat: 48.812493, lng: 22.125401 },
    { lat: 48.812699, lng: 22.124891 },
    { lat: 48.812831, lng: 22.123992 },
    { lat: 48.812358, lng: 22.124055 },
    { lat: 48.812353, lng: 22.123657 },
    { lat: 48.812023, lng: 22.123302 },
    { lat: 48.811889, lng: 22.123307 },
    { lat: 48.81179, lng: 22.123194 },
    { lat: 48.811978, lng: 22.123052 },
    { lat: 48.812056, lng: 22.122689 },
    { lat: 48.811883, lng: 22.122075 },
    { lat: 48.811576, lng: 22.121369 },
    { lat: 48.811154, lng: 22.120568 },
    { lat: 48.81076, lng: 22.120488 },
    { lat: 48.81094, lng: 22.120116 },
    { lat: 48.810795, lng: 22.119812 },
    { lat: 48.810332, lng: 22.119822 },
    { lat: 48.80994, lng: 22.119497 },
    { lat: 48.809729, lng: 22.119247 },
    { lat: 48.809663, lng: 22.118893 },
    { lat: 48.809893, lng: 22.118753 },
    { lat: 48.809908, lng: 22.118595 },
    { lat: 48.809848, lng: 22.118205 },
    { lat: 48.809658, lng: 22.118195 },
    { lat: 48.809379, lng: 22.118381 },
    { lat: 48.808531, lng: 22.117747 },
    { lat: 48.808485, lng: 22.117549 },
    { lat: 48.808483, lng: 22.11729 },
    { lat: 48.808712, lng: 22.117454 },
    { lat: 48.80896, lng: 22.11746 },
    { lat: 48.809071, lng: 22.117022 },
    { lat: 48.809037, lng: 22.116529 },
    { lat: 48.808735, lng: 22.116041 },
    { lat: 48.808333, lng: 22.116191 },
    { lat: 48.80816, lng: 22.116219 },
    { lat: 48.808226, lng: 22.116146 },
    { lat: 48.808226, lng: 22.11593 },
    { lat: 48.808075, lng: 22.115891 },
    { lat: 48.807776, lng: 22.115525 },
    { lat: 48.807132, lng: 22.114551 },
    { lat: 48.807104, lng: 22.113816 },
    { lat: 48.80714, lng: 22.113622 },
    { lat: 48.807435, lng: 22.112037 },
    { lat: 48.807794, lng: 22.110287 },
    { lat: 48.807868, lng: 22.110117 },
    { lat: 48.810138, lng: 22.111618 },
    { lat: 48.810853, lng: 22.111856 },
    { lat: 48.811429, lng: 22.111706 },
    { lat: 48.810719, lng: 22.110819 },
    { lat: 48.81005, lng: 22.110354 },
    { lat: 48.809919, lng: 22.11003 },
    { lat: 48.809629, lng: 22.109727 },
    { lat: 48.809511, lng: 22.109307 },
    { lat: 48.808709, lng: 22.108452 },
    { lat: 48.808643, lng: 22.108382 },
    { lat: 48.807811, lng: 22.107444 },
    { lat: 48.807544, lng: 22.10721 },
    { lat: 48.807124, lng: 22.104772 },
    { lat: 48.805797, lng: 22.103698 },
    { lat: 48.805061, lng: 22.10305 },
    { lat: 48.805002, lng: 22.102998 },
    { lat: 48.804161, lng: 22.102257 },
    { lat: 48.802817, lng: 22.100715 },
    { lat: 48.802312, lng: 22.099742 },
    { lat: 48.802274, lng: 22.09967 },
    { lat: 48.802134, lng: 22.099384 },
    { lat: 48.802116, lng: 22.099353 },
    { lat: 48.802086, lng: 22.099303 },
    { lat: 48.801342, lng: 22.098047 },
    { lat: 48.802318, lng: 22.096237 },
    { lat: 48.802349, lng: 22.096177 },
    { lat: 48.800752, lng: 22.094174 },
    { lat: 48.798693, lng: 22.091584 },
    { lat: 48.798773, lng: 22.091417 },
    { lat: 48.798398, lng: 22.090859 },
    { lat: 48.798605, lng: 22.090095 },
    { lat: 48.798572, lng: 22.089324 },
    { lat: 48.796636, lng: 22.087359 },
    { lat: 48.79657, lng: 22.08733 },
    { lat: 48.796232, lng: 22.088431 },
    { lat: 48.79528, lng: 22.087489 },
    { lat: 48.794717, lng: 22.08835 },
    { lat: 48.793417, lng: 22.085754 },
    { lat: 48.792686, lng: 22.0836 },
    { lat: 48.792616, lng: 22.083615 },
    { lat: 48.791656, lng: 22.08673 },
    { lat: 48.791215, lng: 22.087726 },
    { lat: 48.790402, lng: 22.088195 },
    { lat: 48.790333, lng: 22.088773 },
    { lat: 48.790099, lng: 22.089531 },
    { lat: 48.789723, lng: 22.090752 },
    { lat: 48.789423, lng: 22.091514 },
    { lat: 48.789024, lng: 22.091743 },
    { lat: 48.78883, lng: 22.091531 },
    { lat: 48.787902, lng: 22.089515 },
    { lat: 48.787112, lng: 22.088499 },
    { lat: 48.786521, lng: 22.088394 },
    { lat: 48.785805, lng: 22.087843 },
    { lat: 48.785091, lng: 22.087544 },
    { lat: 48.7852476, lng: 22.0880534 },
    { lat: 48.7855264, lng: 22.0884724 },
    { lat: 48.7856615, lng: 22.0892394 },
    { lat: 48.7856499, lng: 22.0900352 },
    { lat: 48.7847864, lng: 22.0900998 },
    { lat: 48.7848387, lng: 22.0914549 },
    { lat: 48.7843107, lng: 22.0927198 },
    { lat: 48.7844974, lng: 22.094145 },
    { lat: 48.7853688, lng: 22.0966012 },
    { lat: 48.7857284, lng: 22.0993034 },
    { lat: 48.7862503, lng: 22.1016491 },
    { lat: 48.7866026, lng: 22.1039472 },
    { lat: 48.7843956, lng: 22.1040444 },
    { lat: 48.7816511, lng: 22.1040465 },
    { lat: 48.7792417, lng: 22.1054303 },
    { lat: 48.7779609, lng: 22.1057828 },
    { lat: 48.7758225, lng: 22.1061605 },
    { lat: 48.7738025, lng: 22.1064122 },
    { lat: 48.7735784, lng: 22.1063551 },
    { lat: 48.7715483, lng: 22.1064084 },
    { lat: 48.769759, lng: 22.1063282 },
    { lat: 48.7667328, lng: 22.1064522 },
    { lat: 48.7641901, lng: 22.1073874 },
    { lat: 48.7638958, lng: 22.1073501 },
    { lat: 48.7638558, lng: 22.1073238 },
    { lat: 48.7635079, lng: 22.1089904 },
    { lat: 48.7604051, lng: 22.1241358 },
    { lat: 48.7615343, lng: 22.1264324 },
    { lat: 48.7639721, lng: 22.1277985 },
    { lat: 48.764225, lng: 22.1277872 },
    { lat: 48.7646, lng: 22.1291634 },
    { lat: 48.7672434, lng: 22.1305544 },
    { lat: 48.7677436, lng: 22.1315579 },
    { lat: 48.7678963, lng: 22.1329418 },
    { lat: 48.7686923, lng: 22.1333141 },
    { lat: 48.7695546, lng: 22.1339652 },
    { lat: 48.7696714, lng: 22.1349731 },
    { lat: 48.7693974, lng: 22.1363477 },
    { lat: 48.7699595, lng: 22.1378857 },
    { lat: 48.7699359, lng: 22.1379329 },
    { lat: 48.7702067, lng: 22.1382177 },
    { lat: 48.7703949, lng: 22.1381795 },
    { lat: 48.7706798, lng: 22.1385313 },
    { lat: 48.770979, lng: 22.1390645 },
    { lat: 48.7713835, lng: 22.1390975 },
    { lat: 48.771468, lng: 22.1391933 },
    { lat: 48.771554, lng: 22.1397974 },
    { lat: 48.7718153, lng: 22.1403933 },
    { lat: 48.7722182, lng: 22.1405983 },
    { lat: 48.7725786, lng: 22.1405706 },
    { lat: 48.7727697, lng: 22.1406563 },
    { lat: 48.7730945, lng: 22.1406487 },
    { lat: 48.773405, lng: 22.1408929 },
    { lat: 48.7738855, lng: 22.1409009 },
    { lat: 48.7741099, lng: 22.1410241 },
    { lat: 48.7745912, lng: 22.1414981 },
    { lat: 48.7748213, lng: 22.1418183 },
    { lat: 48.7751591, lng: 22.1418374 },
    { lat: 48.7756907, lng: 22.1416686 },
    { lat: 48.7764344, lng: 22.1419233 },
    { lat: 48.776735, lng: 22.1428036 },
    { lat: 48.7770007, lng: 22.1428668 },
    { lat: 48.7773291, lng: 22.1427667 },
    { lat: 48.7777369, lng: 22.1432233 },
    { lat: 48.7778767, lng: 22.1436317 },
    { lat: 48.7780719, lng: 22.1437065 },
    { lat: 48.7782603, lng: 22.1439194 },
    { lat: 48.7784564, lng: 22.1438898 },
    { lat: 48.7785977, lng: 22.1439682 },
    { lat: 48.7790308, lng: 22.1446011 },
    { lat: 48.7791342, lng: 22.1448406 },
    { lat: 48.7794953, lng: 22.1448411 },
    { lat: 48.7797119, lng: 22.1452565 },
    { lat: 48.7800894, lng: 22.1453345 },
    { lat: 48.7801043, lng: 22.1462225 },
    { lat: 48.7803401, lng: 22.1463473 },
    { lat: 48.7803094, lng: 22.1469142 },
    { lat: 48.7804038, lng: 22.1472056 },
    { lat: 48.7804728, lng: 22.1477116 },
    { lat: 48.7806181, lng: 22.1480099 },
    { lat: 48.7809678, lng: 22.1472379 },
    { lat: 48.7814388, lng: 22.1470116 },
    { lat: 48.7829176, lng: 22.1474414 },
    { lat: 48.785245, lng: 22.148561 },
    { lat: 48.7862166, lng: 22.1488915 },
    { lat: 48.7875402, lng: 22.1490272 },
    { lat: 48.7881179, lng: 22.1493009 },
    { lat: 48.7884593, lng: 22.1486446 },
    { lat: 48.7890638, lng: 22.1479394 },
    { lat: 48.7894756, lng: 22.147065 },
    { lat: 48.7906425, lng: 22.1467477 },
    { lat: 48.7907687, lng: 22.1468541 },
    { lat: 48.7923646, lng: 22.1445616 },
    { lat: 48.7932584, lng: 22.143771 },
    { lat: 48.796703, lng: 22.1474311 },
    { lat: 48.798043, lng: 22.1489488 },
    { lat: 48.7980519, lng: 22.1487043 },
    { lat: 48.7981765, lng: 22.1484458 },
    { lat: 48.7985154, lng: 22.145991 },
    { lat: 48.7991397, lng: 22.1474927 },
    { lat: 48.799973, lng: 22.1485546 },
    { lat: 48.8028383, lng: 22.150374 },
    { lat: 48.8036773, lng: 22.152358 },
    { lat: 48.8053667, lng: 22.1540174 },
    { lat: 48.8058811, lng: 22.153396 },
    { lat: 48.8063561, lng: 22.1520459 },
    { lat: 48.8066726, lng: 22.1503617 },
    { lat: 48.80685, lng: 22.1484496 },
    { lat: 48.8077083, lng: 22.1453534 },
    { lat: 48.8087705, lng: 22.1434756 },
    { lat: 48.80894, lng: 22.1422982 },
    { lat: 48.8093686, lng: 22.1429745 },
    { lat: 48.8094657, lng: 22.1430414 },
    { lat: 48.8094698, lng: 22.1427322 },
    { lat: 48.8096912, lng: 22.1427768 },
    { lat: 48.8097163, lng: 22.1422541 },
    { lat: 48.8098525, lng: 22.1422097 },
    { lat: 48.8099476, lng: 22.1417989 },
    { lat: 48.8101242, lng: 22.1417528 },
    { lat: 48.8102062, lng: 22.1414558 },
    { lat: 48.810346, lng: 22.141457 },
  ],
  Jenkovce: [
    { lat: 48.6828568, lng: 22.1995985 },
    { lat: 48.6822542, lng: 22.1997131 },
    { lat: 48.6822768, lng: 22.1993197 },
    { lat: 48.682406, lng: 22.1987199 },
    { lat: 48.6822909, lng: 22.198408 },
    { lat: 48.6812724, lng: 22.198478 },
    { lat: 48.6807924, lng: 22.1988888 },
    { lat: 48.6799451, lng: 22.1971018 },
    { lat: 48.6790486, lng: 22.1964547 },
    { lat: 48.6783417, lng: 22.1961358 },
    { lat: 48.6769198, lng: 22.1959837 },
    { lat: 48.6768869, lng: 22.1960737 },
    { lat: 48.6712593, lng: 22.1921212 },
    { lat: 48.6669272, lng: 22.1876772 },
    { lat: 48.6669931, lng: 22.1875175 },
    { lat: 48.6667966, lng: 22.1869367 },
    { lat: 48.6655637, lng: 22.1850243 },
    { lat: 48.6661788, lng: 22.182561 },
    { lat: 48.6647453, lng: 22.1811136 },
    { lat: 48.6640571, lng: 22.1828846 },
    { lat: 48.6635869, lng: 22.1824957 },
    { lat: 48.6632693, lng: 22.1824799 },
    { lat: 48.6631965, lng: 22.182546 },
    { lat: 48.6629856, lng: 22.1825702 },
    { lat: 48.6627336, lng: 22.1823709 },
    { lat: 48.6623488, lng: 22.1826547 },
    { lat: 48.6622822, lng: 22.1829866 },
    { lat: 48.662026, lng: 22.1835973 },
    { lat: 48.6619039, lng: 22.1836169 },
    { lat: 48.6617007, lng: 22.183429 },
    { lat: 48.6613807, lng: 22.183414 },
    { lat: 48.6610845, lng: 22.1839856 },
    { lat: 48.660929, lng: 22.1845107 },
    { lat: 48.6607583, lng: 22.1847669 },
    { lat: 48.6606399, lng: 22.1852481 },
    { lat: 48.6604872, lng: 22.1853119 },
    { lat: 48.6585202, lng: 22.1845368 },
    { lat: 48.6579832, lng: 22.1846712 },
    { lat: 48.6561367, lng: 22.1845921 },
    { lat: 48.6563289, lng: 22.1841925 },
    { lat: 48.6533746, lng: 22.1836298 },
    { lat: 48.6524768, lng: 22.1836735 },
    { lat: 48.652449, lng: 22.1831506 },
    { lat: 48.6525069, lng: 22.1825296 },
    { lat: 48.6527991, lng: 22.1817317 },
    { lat: 48.653183, lng: 22.1812566 },
    { lat: 48.653205, lng: 22.1810438 },
    { lat: 48.6531364, lng: 22.1807457 },
    { lat: 48.6531565, lng: 22.1799588 },
    { lat: 48.6534949, lng: 22.1792161 },
    { lat: 48.653866, lng: 22.1780308 },
    { lat: 48.6539621, lng: 22.1765384 },
    { lat: 48.6538843, lng: 22.176239 },
    { lat: 48.6537643, lng: 22.1750812 },
    { lat: 48.653797, lng: 22.1747885 },
    { lat: 48.6474142, lng: 22.1746674 },
    { lat: 48.6464813, lng: 22.1760733 },
    { lat: 48.64563, lng: 22.177011 },
    { lat: 48.645182, lng: 22.1776317 },
    { lat: 48.6448653, lng: 22.1785908 },
    { lat: 48.6445936, lng: 22.180083 },
    { lat: 48.644422, lng: 22.181132 },
    { lat: 48.6438808, lng: 22.186077 },
    { lat: 48.643563, lng: 22.1898457 },
    { lat: 48.642951, lng: 22.196187 },
    { lat: 48.6420272, lng: 22.1958342 },
    { lat: 48.6416816, lng: 22.1954701 },
    { lat: 48.640873, lng: 22.1952913 },
    { lat: 48.6403565, lng: 22.1948845 },
    { lat: 48.6399939, lng: 22.1947138 },
    { lat: 48.6399131, lng: 22.1955143 },
    { lat: 48.6399124, lng: 22.195976 },
    { lat: 48.6383388, lng: 22.1967648 },
    { lat: 48.638208, lng: 22.1978912 },
    { lat: 48.6382799, lng: 22.1985735 },
    { lat: 48.6383108, lng: 22.1997864 },
    { lat: 48.6382034, lng: 22.2008451 },
    { lat: 48.6382052, lng: 22.2014713 },
    { lat: 48.6379497, lng: 22.2017093 },
    { lat: 48.6377926, lng: 22.2026585 },
    { lat: 48.6386761, lng: 22.2029812 },
    { lat: 48.63866, lng: 22.2032171 },
    { lat: 48.6383171, lng: 22.2043089 },
    { lat: 48.6384118, lng: 22.2052066 },
    { lat: 48.6384885, lng: 22.2055644 },
    { lat: 48.6371971, lng: 22.2066949 },
    { lat: 48.6372257, lng: 22.2070196 },
    { lat: 48.6370383, lng: 22.2071864 },
    { lat: 48.6371247, lng: 22.2076191 },
    { lat: 48.6364962, lng: 22.2081317 },
    { lat: 48.6363624, lng: 22.2083331 },
    { lat: 48.6357357, lng: 22.2086689 },
    { lat: 48.6355468, lng: 22.2088717 },
    { lat: 48.6353824, lng: 22.2089069 },
    { lat: 48.6352393, lng: 22.2091056 },
    { lat: 48.6351206, lng: 22.2096701 },
    { lat: 48.6348995, lng: 22.2099393 },
    { lat: 48.6348784, lng: 22.2102971 },
    { lat: 48.6347303, lng: 22.2104072 },
    { lat: 48.6347069, lng: 22.209822 },
    { lat: 48.6344737, lng: 22.2097149 },
    { lat: 48.6347041, lng: 22.2086098 },
    { lat: 48.6342143, lng: 22.2086924 },
    { lat: 48.6343115, lng: 22.2083648 },
    { lat: 48.6339316, lng: 22.208075 },
    { lat: 48.6337671, lng: 22.2080135 },
    { lat: 48.6329619, lng: 22.2082219 },
    { lat: 48.6327055, lng: 22.2091711 },
    { lat: 48.6325344, lng: 22.2101568 },
    { lat: 48.6323572, lng: 22.2104686 },
    { lat: 48.6321567, lng: 22.2110063 },
    { lat: 48.6321697, lng: 22.2114839 },
    { lat: 48.632086, lng: 22.2120124 },
    { lat: 48.631788, lng: 22.2130484 },
    { lat: 48.6317696, lng: 22.2138793 },
    { lat: 48.6315303, lng: 22.2146384 },
    { lat: 48.6315302, lng: 22.2155302 },
    { lat: 48.6314448, lng: 22.2162753 },
    { lat: 48.6316169, lng: 22.2164738 },
    { lat: 48.6316175, lng: 22.2170954 },
    { lat: 48.6315193, lng: 22.2177764 },
    { lat: 48.6316536, lng: 22.2190454 },
    { lat: 48.6316148, lng: 22.2192152 },
    { lat: 48.631261, lng: 22.2203831 },
    { lat: 48.6313118, lng: 22.2213882 },
    { lat: 48.6312762, lng: 22.2220451 },
    { lat: 48.6318025, lng: 22.2221496 },
    { lat: 48.6328375, lng: 22.2225039 },
    { lat: 48.6330197, lng: 22.2225973 },
    { lat: 48.6333701, lng: 22.2229274 },
    { lat: 48.63379, lng: 22.2230893 },
    { lat: 48.6341955, lng: 22.2234583 },
    { lat: 48.6342247, lng: 22.224157 },
    { lat: 48.6361375, lng: 22.2234915 },
    { lat: 48.6381618, lng: 22.2229681 },
    { lat: 48.6394119, lng: 22.2229857 },
    { lat: 48.6404869, lng: 22.2231726 },
    { lat: 48.6433577, lng: 22.2232296 },
    { lat: 48.6442839, lng: 22.2235042 },
    { lat: 48.6443914, lng: 22.2235805 },
    { lat: 48.6444676, lng: 22.2239162 },
    { lat: 48.6444991, lng: 22.2260143 },
    { lat: 48.6446489, lng: 22.2260709 },
    { lat: 48.6459307, lng: 22.2261446 },
    { lat: 48.6473132, lng: 22.2260031 },
    { lat: 48.6514369, lng: 22.2266533 },
    { lat: 48.6515187, lng: 22.2266467 },
    { lat: 48.6519358, lng: 22.2267473 },
    { lat: 48.6529988, lng: 22.2274442 },
    { lat: 48.6536707, lng: 22.227744 },
    { lat: 48.6583936, lng: 22.22978 },
    { lat: 48.6611636, lng: 22.2308241 },
    { lat: 48.661045, lng: 22.2310946 },
    { lat: 48.6711762, lng: 22.2361961 },
    { lat: 48.6711548, lng: 22.2356524 },
    { lat: 48.6711687, lng: 22.2333136 },
    { lat: 48.6712933, lng: 22.2332993 },
    { lat: 48.6712994, lng: 22.23312 },
    { lat: 48.6714218, lng: 22.2331123 },
    { lat: 48.6714278, lng: 22.2328754 },
    { lat: 48.671529, lng: 22.2328798 },
    { lat: 48.6716245, lng: 22.2309756 },
    { lat: 48.6715932, lng: 22.2295291 },
    { lat: 48.6720669, lng: 22.2295365 },
    { lat: 48.6721506, lng: 22.2279577 },
    { lat: 48.6726758, lng: 22.2280005 },
    { lat: 48.6726783, lng: 22.2278207 },
    { lat: 48.6736574, lng: 22.2275359 },
    { lat: 48.6735031, lng: 22.2256369 },
    { lat: 48.673427, lng: 22.2252504 },
    { lat: 48.6739346, lng: 22.2251222 },
    { lat: 48.6738196, lng: 22.2238586 },
    { lat: 48.6738317, lng: 22.2235985 },
    { lat: 48.6739388, lng: 22.2231133 },
    { lat: 48.6738904, lng: 22.2230581 },
    { lat: 48.6737662, lng: 22.2224937 },
    { lat: 48.6743218, lng: 22.2219807 },
    { lat: 48.6751965, lng: 22.2206231 },
    { lat: 48.6752814, lng: 22.220702 },
    { lat: 48.6753144, lng: 22.2205665 },
    { lat: 48.6756126, lng: 22.2208188 },
    { lat: 48.6756659, lng: 22.2206943 },
    { lat: 48.6759192, lng: 22.2208984 },
    { lat: 48.6772407, lng: 22.2180954 },
    { lat: 48.6772847, lng: 22.2175639 },
    { lat: 48.6772162, lng: 22.2172208 },
    { lat: 48.6792188, lng: 22.2176301 },
    { lat: 48.6796493, lng: 22.2178222 },
    { lat: 48.680385, lng: 22.2154792 },
    { lat: 48.6784607, lng: 22.213907 },
    { lat: 48.6786096, lng: 22.2134761 },
    { lat: 48.6797967, lng: 22.2090816 },
    { lat: 48.6804166, lng: 22.2097778 },
    { lat: 48.6826092, lng: 22.2017689 },
    { lat: 48.6833397, lng: 22.2018833 },
    { lat: 48.6828568, lng: 22.1995985 },
  ],
  RemetskéHámre: [
    { lat: 48.929273, lng: 22.1918562 },
    { lat: 48.9291452, lng: 22.1886284 },
    { lat: 48.9269307, lng: 22.1885867 },
    { lat: 48.9261338, lng: 22.1886563 },
    { lat: 48.9256863, lng: 22.1882666 },
    { lat: 48.9246712, lng: 22.1858651 },
    { lat: 48.9238083, lng: 22.1847247 },
    { lat: 48.9224404, lng: 22.1836308 },
    { lat: 48.9212256, lng: 22.1838741 },
    { lat: 48.9209007, lng: 22.1840778 },
    { lat: 48.9202265, lng: 22.1839576 },
    { lat: 48.9198332, lng: 22.1842197 },
    { lat: 48.9187067, lng: 22.1834966 },
    { lat: 48.9175092, lng: 22.1819398 },
    { lat: 48.9178651, lng: 22.180481 },
    { lat: 48.9177872, lng: 22.1801142 },
    { lat: 48.9176472, lng: 22.1798058 },
    { lat: 48.9172629, lng: 22.1794677 },
    { lat: 48.9166572, lng: 22.1785104 },
    { lat: 48.9151728, lng: 22.1754665 },
    { lat: 48.9143011, lng: 22.1741407 },
    { lat: 48.9139023, lng: 22.1736642 },
    { lat: 48.9136999, lng: 22.1729383 },
    { lat: 48.9124848, lng: 22.1726243 },
    { lat: 48.9120169, lng: 22.1732604 },
    { lat: 48.9112596, lng: 22.1725572 },
    { lat: 48.9108975, lng: 22.1721218 },
    { lat: 48.9103937, lng: 22.1699136 },
    { lat: 48.9093432, lng: 22.1688348 },
    { lat: 48.9092305, lng: 22.1682851 },
    { lat: 48.9085581, lng: 22.1676083 },
    { lat: 48.9079012, lng: 22.1665267 },
    { lat: 48.9072275, lng: 22.1648092 },
    { lat: 48.9070459, lng: 22.1638581 },
    { lat: 48.9069503, lng: 22.1636561 },
    { lat: 48.9068102, lng: 22.1626073 },
    { lat: 48.9061614, lng: 22.1621296 },
    { lat: 48.9048174, lng: 22.1602167 },
    { lat: 48.9038429, lng: 22.1603942 },
    { lat: 48.9028072, lng: 22.1600653 },
    { lat: 48.9023662, lng: 22.1597648 },
    { lat: 48.9018965, lng: 22.1597385 },
    { lat: 48.9011211, lng: 22.1598596 },
    { lat: 48.8999623, lng: 22.1595974 },
    { lat: 48.8982936, lng: 22.1590276 },
    { lat: 48.8976469, lng: 22.1584948 },
    { lat: 48.8963051, lng: 22.1577569 },
    { lat: 48.895722, lng: 22.1576863 },
    { lat: 48.8949634, lng: 22.157423 },
    { lat: 48.894338, lng: 22.1573299 },
    { lat: 48.8938991, lng: 22.1570196 },
    { lat: 48.8935089, lng: 22.1565838 },
    { lat: 48.89329, lng: 22.156661 },
    { lat: 48.892691, lng: 22.157475 },
    { lat: 48.892408, lng: 22.15741 },
    { lat: 48.892182, lng: 22.157542 },
    { lat: 48.891876, lng: 22.158225 },
    { lat: 48.891255, lng: 22.158408 },
    { lat: 48.890986, lng: 22.158074 },
    { lat: 48.889463, lng: 22.158958 },
    { lat: 48.887482, lng: 22.161495 },
    { lat: 48.887172, lng: 22.161395 },
    { lat: 48.887045, lng: 22.16136 },
    { lat: 48.886453, lng: 22.161163 },
    { lat: 48.886075, lng: 22.160534 },
    { lat: 48.886044, lng: 22.160471 },
    { lat: 48.885962, lng: 22.160339 },
    { lat: 48.885641, lng: 22.160578 },
    { lat: 48.885584, lng: 22.16062 },
    { lat: 48.884908, lng: 22.161122 },
    { lat: 48.884574, lng: 22.161469 },
    { lat: 48.884618, lng: 22.162127 },
    { lat: 48.884623, lng: 22.16271 },
    { lat: 48.884591, lng: 22.163077 },
    { lat: 48.884388, lng: 22.163441 },
    { lat: 48.883773, lng: 22.164176 },
    { lat: 48.88359, lng: 22.164836 },
    { lat: 48.883146, lng: 22.165154 },
    { lat: 48.882839, lng: 22.165656 },
    { lat: 48.882298, lng: 22.165694 },
    { lat: 48.881982, lng: 22.165963 },
    { lat: 48.881892, lng: 22.166233 },
    { lat: 48.881752, lng: 22.166301 },
    { lat: 48.881629, lng: 22.166361 },
    { lat: 48.881439, lng: 22.166191 },
    { lat: 48.880988, lng: 22.165949 },
    { lat: 48.880724, lng: 22.16587 },
    { lat: 48.88041, lng: 22.165869 },
    { lat: 48.879891, lng: 22.165718 },
    { lat: 48.879444, lng: 22.16567 },
    { lat: 48.879184, lng: 22.165602 },
    { lat: 48.878662, lng: 22.165634 },
    { lat: 48.878482, lng: 22.16554 },
    { lat: 48.878213, lng: 22.165724 },
    { lat: 48.877539, lng: 22.166185 },
    { lat: 48.877379, lng: 22.165911 },
    { lat: 48.877106, lng: 22.165991 },
    { lat: 48.876769, lng: 22.165969 },
    { lat: 48.876206, lng: 22.165661 },
    { lat: 48.875739, lng: 22.165321 },
    { lat: 48.875589, lng: 22.165212 },
    { lat: 48.875065, lng: 22.165255 },
    { lat: 48.874331, lng: 22.164964 },
    { lat: 48.874045, lng: 22.165169 },
    { lat: 48.873826, lng: 22.165432 },
    { lat: 48.873682, lng: 22.165556 },
    { lat: 48.873579, lng: 22.165642 },
    { lat: 48.873218, lng: 22.165199 },
    { lat: 48.873131, lng: 22.164891 },
    { lat: 48.873088, lng: 22.164739 },
    { lat: 48.872425, lng: 22.164443 },
    { lat: 48.872195, lng: 22.16442 },
    { lat: 48.87218, lng: 22.164402 },
    { lat: 48.872004, lng: 22.164997 },
    { lat: 48.871891, lng: 22.165087 },
    { lat: 48.871805, lng: 22.165344 },
    { lat: 48.87152, lng: 22.165685 },
    { lat: 48.871225, lng: 22.165841 },
    { lat: 48.870946, lng: 22.165919 },
    { lat: 48.870798, lng: 22.1659 },
    { lat: 48.870635, lng: 22.165766 },
    { lat: 48.870406, lng: 22.165732 },
    { lat: 48.870342, lng: 22.165759 },
    { lat: 48.870191, lng: 22.165978 },
    { lat: 48.870093, lng: 22.166048 },
    { lat: 48.869927, lng: 22.166028 },
    { lat: 48.869783, lng: 22.165978 },
    { lat: 48.869595, lng: 22.165769 },
    { lat: 48.869323, lng: 22.165719 },
    { lat: 48.86922, lng: 22.165647 },
    { lat: 48.86888, lng: 22.165693 },
    { lat: 48.868432, lng: 22.165755 },
    { lat: 48.868302, lng: 22.165705 },
    { lat: 48.868152, lng: 22.165546 },
    { lat: 48.867865, lng: 22.16549 },
    { lat: 48.867715, lng: 22.165625 },
    { lat: 48.867489, lng: 22.165759 },
    { lat: 48.867183, lng: 22.166064 },
    { lat: 48.866874, lng: 22.165861 },
    { lat: 48.866822, lng: 22.165828 },
    { lat: 48.86629, lng: 22.165681 },
    { lat: 48.865943, lng: 22.165692 },
    { lat: 48.86584, lng: 22.165783 },
    { lat: 48.865399, lng: 22.165878 },
    { lat: 48.86497, lng: 22.165883 },
    { lat: 48.864751, lng: 22.165886 },
    { lat: 48.864321, lng: 22.165809 },
    { lat: 48.86372, lng: 22.165531 },
    { lat: 48.86353, lng: 22.165107 },
    { lat: 48.863433, lng: 22.164506 },
    { lat: 48.863351, lng: 22.164244 },
    { lat: 48.86315, lng: 22.164014 },
    { lat: 48.862219, lng: 22.163733 },
    { lat: 48.862127, lng: 22.163753 },
    { lat: 48.861763, lng: 22.164234 },
    { lat: 48.86162, lng: 22.16421 },
    { lat: 48.861293, lng: 22.163976 },
    { lat: 48.861033, lng: 22.163946 },
    { lat: 48.860773, lng: 22.163862 },
    { lat: 48.860578, lng: 22.163898 },
    { lat: 48.860453, lng: 22.164038 },
    { lat: 48.860236, lng: 22.164078 },
    { lat: 48.8599, lng: 22.16402 },
    { lat: 48.859686, lng: 22.164035 },
    { lat: 48.859369, lng: 22.163887 },
    { lat: 48.858929, lng: 22.163477 },
    { lat: 48.858781, lng: 22.163393 },
    { lat: 48.858669, lng: 22.163203 },
    { lat: 48.858596, lng: 22.162889 },
    { lat: 48.85851, lng: 22.162718 },
    { lat: 48.858222, lng: 22.162479 },
    { lat: 48.858144, lng: 22.162446 },
    { lat: 48.857788, lng: 22.16197 },
    { lat: 48.857564, lng: 22.161925 },
    { lat: 48.857355, lng: 22.16185 },
    { lat: 48.857087, lng: 22.161829 },
    { lat: 48.856767, lng: 22.162048 },
    { lat: 48.855741, lng: 22.161988 },
    { lat: 48.855624, lng: 22.161901 },
    { lat: 48.85556, lng: 22.161784 },
    { lat: 48.855221, lng: 22.16154 },
    { lat: 48.855005, lng: 22.161464 },
    { lat: 48.854913, lng: 22.16145 },
    { lat: 48.854839, lng: 22.161343 },
    { lat: 48.854633, lng: 22.161196 },
    { lat: 48.854024, lng: 22.161016 },
    { lat: 48.853809, lng: 22.161018 },
    { lat: 48.853519, lng: 22.161201 },
    { lat: 48.853277, lng: 22.160959 },
    { lat: 48.853086, lng: 22.160417 },
    { lat: 48.852619, lng: 22.159716 },
    { lat: 48.852514, lng: 22.159321 },
    { lat: 48.852092, lng: 22.158827 },
    { lat: 48.851853, lng: 22.158659 },
    { lat: 48.851811, lng: 22.158569 },
    { lat: 48.851529, lng: 22.158238 },
    { lat: 48.851398, lng: 22.158087 },
    { lat: 48.851174, lng: 22.157838 },
    { lat: 48.850421, lng: 22.157002 },
    { lat: 48.850286, lng: 22.157076 },
    { lat: 48.849935, lng: 22.156983 },
    { lat: 48.848353, lng: 22.156193 },
    { lat: 48.847375, lng: 22.155747 },
    { lat: 48.847215, lng: 22.155618 },
    { lat: 48.846886, lng: 22.155701 },
    { lat: 48.846379, lng: 22.155631 },
    { lat: 48.84566, lng: 22.155824 },
    { lat: 48.844865, lng: 22.156449 },
    { lat: 48.844687, lng: 22.156418 },
    { lat: 48.844665, lng: 22.156414 },
    { lat: 48.844575, lng: 22.156463 },
    { lat: 48.844301, lng: 22.156448 },
    { lat: 48.84405, lng: 22.15659 },
    { lat: 48.84385, lng: 22.156704 },
    { lat: 48.843661, lng: 22.156632 },
    { lat: 48.843284, lng: 22.156491 },
    { lat: 48.842127, lng: 22.156412 },
    { lat: 48.841554, lng: 22.156475 },
    { lat: 48.84099, lng: 22.156412 },
    { lat: 48.84099, lng: 22.156361 },
    { lat: 48.840634, lng: 22.156247 },
    { lat: 48.840453, lng: 22.156089 },
    { lat: 48.840189, lng: 22.156142 },
    { lat: 48.840069, lng: 22.156073 },
    { lat: 48.840026, lng: 22.156063 },
    { lat: 48.839812, lng: 22.156009 },
    { lat: 48.839593, lng: 22.155619 },
    { lat: 48.839736, lng: 22.155272 },
    { lat: 48.839804, lng: 22.155103 },
    { lat: 48.839582, lng: 22.154578 },
    { lat: 48.839437, lng: 22.154433 },
    { lat: 48.839366, lng: 22.154361 },
    { lat: 48.839252, lng: 22.15413 },
    { lat: 48.838893, lng: 22.153535 },
    { lat: 48.838403, lng: 22.152979 },
    { lat: 48.837812, lng: 22.152838 },
    { lat: 48.837239, lng: 22.152511 },
    { lat: 48.836685, lng: 22.152344 },
    { lat: 48.835264, lng: 22.151675 },
    { lat: 48.834841, lng: 22.151539 },
    { lat: 48.834545, lng: 22.151377 },
    { lat: 48.834197, lng: 22.151239 },
    { lat: 48.834001, lng: 22.151686 },
    { lat: 48.8338833, lng: 22.1548738 },
    { lat: 48.8367357, lng: 22.1574046 },
    { lat: 48.8374839, lng: 22.1584915 },
    { lat: 48.8375722, lng: 22.1585183 },
    { lat: 48.8380012, lng: 22.1593715 },
    { lat: 48.8379906, lng: 22.1598808 },
    { lat: 48.8385246, lng: 22.1590899 },
    { lat: 48.8409981, lng: 22.1626567 },
    { lat: 48.8416668, lng: 22.1667504 },
    { lat: 48.8433728, lng: 22.1675199 },
    { lat: 48.8436555, lng: 22.1679775 },
    { lat: 48.8436903, lng: 22.1683124 },
    { lat: 48.8447195, lng: 22.1694977 },
    { lat: 48.8447974, lng: 22.1708816 },
    { lat: 48.8453071, lng: 22.1708891 },
    { lat: 48.8453262, lng: 22.1715255 },
    { lat: 48.8454311, lng: 22.1715346 },
    { lat: 48.8452206, lng: 22.1719775 },
    { lat: 48.8428963, lng: 22.1717073 },
    { lat: 48.839808, lng: 22.1712228 },
    { lat: 48.8393825, lng: 22.1716123 },
    { lat: 48.8391749, lng: 22.1711794 },
    { lat: 48.8387512, lng: 22.1707625 },
    { lat: 48.838376, lng: 22.1709286 },
    { lat: 48.8379363, lng: 22.1712724 },
    { lat: 48.8376904, lng: 22.1708497 },
    { lat: 48.8371556, lng: 22.1704359 },
    { lat: 48.8366833, lng: 22.1713219 },
    { lat: 48.8365048, lng: 22.1709493 },
    { lat: 48.8357096, lng: 22.1706551 },
    { lat: 48.8346767, lng: 22.1709277 },
    { lat: 48.834248, lng: 22.170776 },
    { lat: 48.834105, lng: 22.1708199 },
    { lat: 48.8335845, lng: 22.1737271 },
    { lat: 48.8360664, lng: 22.1762562 },
    { lat: 48.8359078, lng: 22.1765073 },
    { lat: 48.8352019, lng: 22.1762788 },
    { lat: 48.8341537, lng: 22.174961 },
    { lat: 48.8335709, lng: 22.1743576 },
    { lat: 48.8331049, lng: 22.1745892 },
    { lat: 48.8327127, lng: 22.1742624 },
    { lat: 48.832575, lng: 22.1742407 },
    { lat: 48.8323213, lng: 22.1749031 },
    { lat: 48.8320023, lng: 22.1747256 },
    { lat: 48.831704, lng: 22.1759934 },
    { lat: 48.8316947, lng: 22.176228 },
    { lat: 48.831154, lng: 22.175948 },
    { lat: 48.8306555, lng: 22.1792743 },
    { lat: 48.8311643, lng: 22.179489 },
    { lat: 48.8317443, lng: 22.179475 },
    { lat: 48.833049, lng: 22.1801598 },
    { lat: 48.8331465, lng: 22.180098 },
    { lat: 48.8332306, lng: 22.1801375 },
    { lat: 48.8336432, lng: 22.1805646 },
    { lat: 48.8338766, lng: 22.1804956 },
    { lat: 48.8346103, lng: 22.1805574 },
    { lat: 48.8353281, lng: 22.1808738 },
    { lat: 48.8353934, lng: 22.1809006 },
    { lat: 48.8354623, lng: 22.1810669 },
    { lat: 48.8356517, lng: 22.1812364 },
    { lat: 48.8357946, lng: 22.181349 },
    { lat: 48.8361755, lng: 22.1812922 },
    { lat: 48.8365657, lng: 22.1813914 },
    { lat: 48.8372449, lng: 22.1817479 },
    { lat: 48.8376159, lng: 22.1819509 },
    { lat: 48.8382714, lng: 22.1823821 },
    { lat: 48.8385068, lng: 22.1827825 },
    { lat: 48.8393972, lng: 22.1830176 },
    { lat: 48.8401256, lng: 22.1834358 },
    { lat: 48.840489, lng: 22.1833425 },
    { lat: 48.8409818, lng: 22.183793 },
    { lat: 48.8415877, lng: 22.1844715 },
    { lat: 48.8420199, lng: 22.1845338 },
    { lat: 48.8421212, lng: 22.1846353 },
    { lat: 48.8424171, lng: 22.1847319 },
    { lat: 48.8429941, lng: 22.1848352 },
    { lat: 48.8431516, lng: 22.1851787 },
    { lat: 48.843252, lng: 22.1852902 },
    { lat: 48.843833, lng: 22.1856134 },
    { lat: 48.844114, lng: 22.1858508 },
    { lat: 48.8444505, lng: 22.1857164 },
    { lat: 48.8451002, lng: 22.1859384 },
    { lat: 48.8454918, lng: 22.1859522 },
    { lat: 48.8456634, lng: 22.1858796 },
    { lat: 48.8463896, lng: 22.1862016 },
    { lat: 48.8473238, lng: 22.1869762 },
    { lat: 48.8478107, lng: 22.1868707 },
    { lat: 48.848088, lng: 22.1870937 },
    { lat: 48.8483604, lng: 22.1871105 },
    { lat: 48.8485817, lng: 22.1867483 },
    { lat: 48.8488941, lng: 22.1865851 },
    { lat: 48.8491442, lng: 22.1866485 },
    { lat: 48.849726, lng: 22.1865709 },
    { lat: 48.8499501, lng: 22.1866299 },
    { lat: 48.8501073, lng: 22.1868177 },
    { lat: 48.8509201, lng: 22.1868973 },
    { lat: 48.851189, lng: 22.1868922 },
    { lat: 48.8515012, lng: 22.186684 },
    { lat: 48.8519615, lng: 22.1866428 },
    { lat: 48.8523138, lng: 22.187169 },
    { lat: 48.8527606, lng: 22.1877525 },
    { lat: 48.8533828, lng: 22.188206 },
    { lat: 48.8537552, lng: 22.1882055 },
    { lat: 48.8539105, lng: 22.1883077 },
    { lat: 48.8542138, lng: 22.1886901 },
    { lat: 48.8545983, lng: 22.1893257 },
    { lat: 48.8549272, lng: 22.1892982 },
    { lat: 48.8556298, lng: 22.1902201 },
    { lat: 48.8557108, lng: 22.190267 },
    { lat: 48.8559658, lng: 22.1901543 },
    { lat: 48.8561359, lng: 22.1902115 },
    { lat: 48.8563746, lng: 22.1905226 },
    { lat: 48.856939, lng: 22.1906642 },
    { lat: 48.8574479, lng: 22.1906844 },
    { lat: 48.8577933, lng: 22.1904399 },
    { lat: 48.8585974, lng: 22.1902932 },
    { lat: 48.8591731, lng: 22.190475 },
    { lat: 48.860243, lng: 22.1910367 },
    { lat: 48.8605769, lng: 22.1912714 },
    { lat: 48.8608171, lng: 22.191545 },
    { lat: 48.861289, lng: 22.1915232 },
    { lat: 48.8616798, lng: 22.1917007 },
    { lat: 48.8620873, lng: 22.1917651 },
    { lat: 48.8622197, lng: 22.191895 },
    { lat: 48.862328, lng: 22.1921097 },
    { lat: 48.8625743, lng: 22.1928024 },
    { lat: 48.8627285, lng: 22.1929512 },
    { lat: 48.8628952, lng: 22.1935555 },
    { lat: 48.863506, lng: 22.1942052 },
    { lat: 48.8639741, lng: 22.1945576 },
    { lat: 48.8652532, lng: 22.1952618 },
    { lat: 48.8656037, lng: 22.1955958 },
    { lat: 48.8660319, lng: 22.1957263 },
    { lat: 48.8662527, lng: 22.1959874 },
    { lat: 48.8665356, lng: 22.1964564 },
    { lat: 48.8666323, lng: 22.196512 },
    { lat: 48.8671636, lng: 22.1965243 },
    { lat: 48.8673667, lng: 22.1966552 },
    { lat: 48.8677624, lng: 22.1971944 },
    { lat: 48.8683964, lng: 22.1975147 },
    { lat: 48.8687094, lng: 22.1978792 },
    { lat: 48.8691272, lng: 22.1986045 },
    { lat: 48.8691559, lng: 22.1987774 },
    { lat: 48.8691078, lng: 22.1991325 },
    { lat: 48.8691582, lng: 22.1992735 },
    { lat: 48.8695809, lng: 22.1998592 },
    { lat: 48.8696863, lng: 22.1998937 },
    { lat: 48.8702058, lng: 22.2017277 },
    { lat: 48.8703759, lng: 22.2018364 },
    { lat: 48.8705004, lng: 22.2020182 },
    { lat: 48.870839, lng: 22.2023164 },
    { lat: 48.8710531, lng: 22.2024328 },
    { lat: 48.8714848, lng: 22.2024697 },
    { lat: 48.8716102, lng: 22.2025989 },
    { lat: 48.8718867, lng: 22.2030372 },
    { lat: 48.8720542, lng: 22.2036273 },
    { lat: 48.8724535, lng: 22.2041296 },
    { lat: 48.872779, lng: 22.2043985 },
    { lat: 48.8729935, lng: 22.2055257 },
    { lat: 48.873451, lng: 22.2063588 },
    { lat: 48.8737227, lng: 22.2070339 },
    { lat: 48.8740791, lng: 22.207577 },
    { lat: 48.8741539, lng: 22.2078025 },
    { lat: 48.8742013, lng: 22.2086991 },
    { lat: 48.8743203, lng: 22.2087947 },
    { lat: 48.8746467, lng: 22.2087499 },
    { lat: 48.8748571, lng: 22.2089026 },
    { lat: 48.8751306, lng: 22.2092114 },
    { lat: 48.8753193, lng: 22.2096969 },
    { lat: 48.8757874, lng: 22.2102089 },
    { lat: 48.87609, lng: 22.2101028 },
    { lat: 48.8765504, lng: 22.2103673 },
    { lat: 48.8776045, lng: 22.2106338 },
    { lat: 48.8779604, lng: 22.2106025 },
    { lat: 48.8781455, lng: 22.2107322 },
    { lat: 48.878815, lng: 22.2108451 },
    { lat: 48.8791462, lng: 22.2109697 },
    { lat: 48.8795332, lng: 22.2112234 },
    { lat: 48.8798057, lng: 22.2112847 },
    { lat: 48.8800637, lng: 22.2114514 },
    { lat: 48.8802064, lng: 22.2116437 },
    { lat: 48.8806187, lng: 22.2117217 },
    { lat: 48.8809701, lng: 22.211543 },
    { lat: 48.881144, lng: 22.2115701 },
    { lat: 48.8814619, lng: 22.2114528 },
    { lat: 48.8817601, lng: 22.2109642 },
    { lat: 48.8819549, lng: 22.2107726 },
    { lat: 48.8824038, lng: 22.2105273 },
    { lat: 48.8829968, lng: 22.2108968 },
    { lat: 48.883511, lng: 22.2109037 },
    { lat: 48.8838228, lng: 22.2107657 },
    { lat: 48.8841459, lng: 22.210522 },
    { lat: 48.8843429, lng: 22.2104789 },
    { lat: 48.8847923, lng: 22.2105596 },
    { lat: 48.8854814, lng: 22.2103487 },
    { lat: 48.8858027, lng: 22.209881 },
    { lat: 48.8862216, lng: 22.2095122 },
    { lat: 48.886563, lng: 22.2087879 },
    { lat: 48.8866916, lng: 22.2086998 },
    { lat: 48.8869732, lng: 22.2086769 },
    { lat: 48.8873408, lng: 22.2082136 },
    { lat: 48.887707, lng: 22.2078958 },
    { lat: 48.8879582, lng: 22.2074685 },
    { lat: 48.8882641, lng: 22.2074665 },
    { lat: 48.8885028, lng: 22.207328 },
    { lat: 48.8890225, lng: 22.2060375 },
    { lat: 48.8894733, lng: 22.205479 },
    { lat: 48.8900838, lng: 22.2050241 },
    { lat: 48.8905367, lng: 22.2049559 },
    { lat: 48.8912566, lng: 22.2054538 },
    { lat: 48.8916419, lng: 22.2054309 },
    { lat: 48.8919849, lng: 22.2055775 },
    { lat: 48.8930497, lng: 22.2053159 },
    { lat: 48.8934626, lng: 22.2051257 },
    { lat: 48.8938112, lng: 22.2046694 },
    { lat: 48.894371, lng: 22.2043068 },
    { lat: 48.894665, lng: 22.2039344 },
    { lat: 48.8948592, lng: 22.2041666 },
    { lat: 48.8957454, lng: 22.2040111 },
    { lat: 48.8962637, lng: 22.2032592 },
    { lat: 48.896417, lng: 22.203169 },
    { lat: 48.8965854, lng: 22.2032064 },
    { lat: 48.8974025, lng: 22.2029336 },
    { lat: 48.8977941, lng: 22.2028879 },
    { lat: 48.8982079, lng: 22.2024877 },
    { lat: 48.8985473, lng: 22.2017301 },
    { lat: 48.8987241, lng: 22.2014917 },
    { lat: 48.8990032, lng: 22.2012563 },
    { lat: 48.8991152, lng: 22.2009348 },
    { lat: 48.8995747, lng: 22.2005133 },
    { lat: 48.8999319, lng: 22.2003928 },
    { lat: 48.9001681, lng: 22.2001467 },
    { lat: 48.9004261, lng: 22.200268 },
    { lat: 48.9005713, lng: 22.2002179 },
    { lat: 48.9008785, lng: 22.1995359 },
    { lat: 48.9011399, lng: 22.1992549 },
    { lat: 48.9013999, lng: 22.1990708 },
    { lat: 48.9016556, lng: 22.1990357 },
    { lat: 48.9020614, lng: 22.1986809 },
    { lat: 48.9025483, lng: 22.1979298 },
    { lat: 48.9032017, lng: 22.1976837 },
    { lat: 48.9039377, lng: 22.1977447 },
    { lat: 48.9046113, lng: 22.1981375 },
    { lat: 48.9049239, lng: 22.1978946 },
    { lat: 48.9057768, lng: 22.1985991 },
    { lat: 48.9061167, lng: 22.198714 },
    { lat: 48.9063833, lng: 22.1985088 },
    { lat: 48.9066811, lng: 22.1984515 },
    { lat: 48.9074969, lng: 22.1980714 },
    { lat: 48.9081832, lng: 22.1981307 },
    { lat: 48.9098814, lng: 22.1986407 },
    { lat: 48.9109728, lng: 22.1989846 },
    { lat: 48.9116054, lng: 22.1993471 },
    { lat: 48.9117231, lng: 22.1992372 },
    { lat: 48.9120274, lng: 22.1991846 },
    { lat: 48.9123539, lng: 22.1989283 },
    { lat: 48.9126001, lng: 22.198874 },
    { lat: 48.9127319, lng: 22.1986887 },
    { lat: 48.9130626, lng: 22.1986889 },
    { lat: 48.9130769, lng: 22.1988205 },
    { lat: 48.9134065, lng: 22.1991716 },
    { lat: 48.9135073, lng: 22.1992511 },
    { lat: 48.9136899, lng: 22.1992677 },
    { lat: 48.9138276, lng: 22.1988976 },
    { lat: 48.9140125, lng: 22.1987148 },
    { lat: 48.9141682, lng: 22.198337 },
    { lat: 48.9145546, lng: 22.1979242 },
    { lat: 48.9147206, lng: 22.1973033 },
    { lat: 48.914811, lng: 22.1972689 },
    { lat: 48.9155239, lng: 22.1976594 },
    { lat: 48.9160632, lng: 22.197789 },
    { lat: 48.9167535, lng: 22.1977225 },
    { lat: 48.9169988, lng: 22.1977741 },
    { lat: 48.916863, lng: 22.19849 },
    { lat: 48.9169499, lng: 22.1988924 },
    { lat: 48.9174159, lng: 22.1998115 },
    { lat: 48.9176914, lng: 22.2001526 },
    { lat: 48.9179297, lng: 22.2003415 },
    { lat: 48.9183565, lng: 22.2003189 },
    { lat: 48.9183979, lng: 22.2001831 },
    { lat: 48.9184958, lng: 22.2002559 },
    { lat: 48.9187056, lng: 22.2003297 },
    { lat: 48.9189832, lng: 22.2004748 },
    { lat: 48.9191631, lng: 22.200466 },
    { lat: 48.9193159, lng: 22.2005668 },
    { lat: 48.919913, lng: 22.200561 },
    { lat: 48.9204143, lng: 22.2004444 },
    { lat: 48.9206348, lng: 22.2005452 },
    { lat: 48.9209595, lng: 22.2003305 },
    { lat: 48.9211657, lng: 22.200102 },
    { lat: 48.9214634, lng: 22.1999366 },
    { lat: 48.9219223, lng: 22.1993444 },
    { lat: 48.9222789, lng: 22.1990425 },
    { lat: 48.9225368, lng: 22.1991589 },
    { lat: 48.9228518, lng: 22.1990599 },
    { lat: 48.9243995, lng: 22.1992653 },
    { lat: 48.9257072, lng: 22.1993466 },
    { lat: 48.9261828, lng: 22.199345 },
    { lat: 48.9268101, lng: 22.1990809 },
    { lat: 48.927402, lng: 22.1992392 },
    { lat: 48.928427, lng: 22.1996547 },
    { lat: 48.9293868, lng: 22.1993782 },
    { lat: 48.93013, lng: 22.199606 },
    { lat: 48.929873, lng: 22.198215 },
    { lat: 48.92973, lng: 22.197152 },
    { lat: 48.929788, lng: 22.196145 },
    { lat: 48.929778, lng: 22.195293 },
    { lat: 48.929653, lng: 22.193968 },
    { lat: 48.929273, lng: 22.1918562 },
  ],
  Kristy: [
    { lat: 48.7062798, lng: 22.2008718 },
    { lat: 48.7055529, lng: 22.2004686 },
    { lat: 48.7046983, lng: 22.1999304 },
    { lat: 48.7035768, lng: 22.1992358 },
    { lat: 48.7039273, lng: 22.1982746 },
    { lat: 48.7032014, lng: 22.1974662 },
    { lat: 48.7017309, lng: 22.1960738 },
    { lat: 48.7012585, lng: 22.195535 },
    { lat: 48.7004671, lng: 22.1938758 },
    { lat: 48.7000848, lng: 22.1933388 },
    { lat: 48.6993007, lng: 22.1925863 },
    { lat: 48.6988682, lng: 22.1918742 },
    { lat: 48.698715, lng: 22.1917704 },
    { lat: 48.6930585, lng: 22.1905967 },
    { lat: 48.692099, lng: 22.1884297 },
    { lat: 48.6911776, lng: 22.1894087 },
    { lat: 48.6910202, lng: 22.1893621 },
    { lat: 48.6897754, lng: 22.1882471 },
    { lat: 48.6881767, lng: 22.1870556 },
    { lat: 48.6880828, lng: 22.1867316 },
    { lat: 48.6882843, lng: 22.1859044 },
    { lat: 48.6881422, lng: 22.1859905 },
    { lat: 48.6880997, lng: 22.1857496 },
    { lat: 48.6883087, lng: 22.185592 },
    { lat: 48.6868158, lng: 22.1838134 },
    { lat: 48.6866463, lng: 22.1834837 },
    { lat: 48.6866069, lng: 22.1830899 },
    { lat: 48.6866982, lng: 22.1809456 },
    { lat: 48.687424, lng: 22.1809985 },
    { lat: 48.6874767, lng: 22.1803033 },
    { lat: 48.6874802, lng: 22.1800681 },
    { lat: 48.6867235, lng: 22.1800315 },
    { lat: 48.6868003, lng: 22.178673 },
    { lat: 48.6871344, lng: 22.1754426 },
    { lat: 48.6872393, lng: 22.1750124 },
    { lat: 48.6881665, lng: 22.1725281 },
    { lat: 48.6885094, lng: 22.1712954 },
    { lat: 48.6887542, lng: 22.1701017 },
    { lat: 48.6891234, lng: 22.1691937 },
    { lat: 48.6894046, lng: 22.1680681 },
    { lat: 48.6896325, lng: 22.166923 },
    { lat: 48.6900724, lng: 22.1655153 },
    { lat: 48.690547, lng: 22.1632214 },
    { lat: 48.6915353, lng: 22.1598907 },
    { lat: 48.6916093, lng: 22.1588504 },
    { lat: 48.6913408, lng: 22.1574593 },
    { lat: 48.6912421, lng: 22.1553358 },
    { lat: 48.6912805, lng: 22.1546523 },
    { lat: 48.6913627, lng: 22.1542735 },
    { lat: 48.6910894, lng: 22.1542765 },
    { lat: 48.6892725, lng: 22.1539203 },
    { lat: 48.6885477, lng: 22.1538571 },
    { lat: 48.687043, lng: 22.1538743 },
    { lat: 48.6864115, lng: 22.1537468 },
    { lat: 48.686238, lng: 22.1536445 },
    { lat: 48.6862643, lng: 22.1539571 },
    { lat: 48.6835293, lng: 22.1535494 },
    { lat: 48.6824227, lng: 22.153511 },
    { lat: 48.6777902, lng: 22.1546569 },
    { lat: 48.6777952, lng: 22.1547211 },
    { lat: 48.6777664, lng: 22.1550372 },
    { lat: 48.6778277, lng: 22.1552102 },
    { lat: 48.6780556, lng: 22.1555242 },
    { lat: 48.6781295, lng: 22.1563859 },
    { lat: 48.6780633, lng: 22.1567849 },
    { lat: 48.6779418, lng: 22.1570757 },
    { lat: 48.6779132, lng: 22.1581222 },
    { lat: 48.678028, lng: 22.1586084 },
    { lat: 48.6780341, lng: 22.1590267 },
    { lat: 48.6781178, lng: 22.1593839 },
    { lat: 48.6781804, lng: 22.1595646 },
    { lat: 48.6783457, lng: 22.1597994 },
    { lat: 48.6753933, lng: 22.1619068 },
    { lat: 48.6759351, lng: 22.1639867 },
    { lat: 48.6690757, lng: 22.1687746 },
    { lat: 48.6689851, lng: 22.1687879 },
    { lat: 48.668894, lng: 22.1686912 },
    { lat: 48.6684033, lng: 22.1673149 },
    { lat: 48.6677043, lng: 22.1683224 },
    { lat: 48.6650027, lng: 22.1682874 },
    { lat: 48.664369, lng: 22.1695796 },
    { lat: 48.663749, lng: 22.1706515 },
    { lat: 48.6636227, lng: 22.1710745 },
    { lat: 48.6636429, lng: 22.1722986 },
    { lat: 48.6637812, lng: 22.1728795 },
    { lat: 48.6637387, lng: 22.1731842 },
    { lat: 48.6634183, lng: 22.173776 },
    { lat: 48.663177, lng: 22.1743847 },
    { lat: 48.6625033, lng: 22.174885 },
    { lat: 48.6622439, lng: 22.1753409 },
    { lat: 48.6622352, lng: 22.1760423 },
    { lat: 48.6621204, lng: 22.1773617 },
    { lat: 48.6618558, lng: 22.177932 },
    { lat: 48.66184, lng: 22.1786715 },
    { lat: 48.6619604, lng: 22.1791641 },
    { lat: 48.6619949, lng: 22.1797825 },
    { lat: 48.6619296, lng: 22.1806647 },
    { lat: 48.6619298, lng: 22.1815586 },
    { lat: 48.6621952, lng: 22.1819884 },
    { lat: 48.6623488, lng: 22.1826547 },
    { lat: 48.6627336, lng: 22.1823709 },
    { lat: 48.6629856, lng: 22.1825702 },
    { lat: 48.6631965, lng: 22.182546 },
    { lat: 48.6632693, lng: 22.1824799 },
    { lat: 48.6635869, lng: 22.1824957 },
    { lat: 48.6640571, lng: 22.1828846 },
    { lat: 48.6647453, lng: 22.1811136 },
    { lat: 48.6661788, lng: 22.182561 },
    { lat: 48.6655637, lng: 22.1850243 },
    { lat: 48.6667966, lng: 22.1869367 },
    { lat: 48.6669931, lng: 22.1875175 },
    { lat: 48.6669272, lng: 22.1876772 },
    { lat: 48.6712593, lng: 22.1921212 },
    { lat: 48.6768869, lng: 22.1960737 },
    { lat: 48.6769198, lng: 22.1959837 },
    { lat: 48.6783417, lng: 22.1961358 },
    { lat: 48.6790486, lng: 22.1964547 },
    { lat: 48.6799451, lng: 22.1971018 },
    { lat: 48.6807924, lng: 22.1988888 },
    { lat: 48.6812724, lng: 22.198478 },
    { lat: 48.6822909, lng: 22.198408 },
    { lat: 48.682406, lng: 22.1987199 },
    { lat: 48.6822768, lng: 22.1993197 },
    { lat: 48.6822542, lng: 22.1997131 },
    { lat: 48.6828568, lng: 22.1995985 },
    { lat: 48.6828994, lng: 22.1995935 },
    { lat: 48.6870395, lng: 22.1991358 },
    { lat: 48.6875574, lng: 22.1991459 },
    { lat: 48.6905778, lng: 22.2002988 },
    { lat: 48.6945584, lng: 22.1994001 },
    { lat: 48.6945384, lng: 22.1992135 },
    { lat: 48.6956441, lng: 22.1989235 },
    { lat: 48.6956727, lng: 22.1992389 },
    { lat: 48.6960807, lng: 22.1991732 },
    { lat: 48.6962983, lng: 22.1986492 },
    { lat: 48.6976603, lng: 22.1983947 },
    { lat: 48.6989731, lng: 22.198454 },
    { lat: 48.6991533, lng: 22.1984155 },
    { lat: 48.700833, lng: 22.1991233 },
    { lat: 48.7059801, lng: 22.2020366 },
    { lat: 48.7059982, lng: 22.2019409 },
    { lat: 48.7062798, lng: 22.2008718 },
  ],
  RuskýHrabovec: [
    { lat: 48.835714, lng: 22.375866 },
    { lat: 48.835737, lng: 22.375874 },
    { lat: 48.836441, lng: 22.376133 },
    { lat: 48.838875, lng: 22.379947 },
    { lat: 48.841093, lng: 22.379174 },
    { lat: 48.845397, lng: 22.380193 },
    { lat: 48.850297, lng: 22.381044 },
    { lat: 48.855068, lng: 22.381895 },
    { lat: 48.855961, lng: 22.382043 },
    { lat: 48.8571528, lng: 22.381479 },
    { lat: 48.8573516, lng: 22.3813849 },
    { lat: 48.85825, lng: 22.381249 },
    { lat: 48.860039, lng: 22.381282 },
    { lat: 48.860846, lng: 22.381897 },
    { lat: 48.862108, lng: 22.382852 },
    { lat: 48.8625098, lng: 22.3825911 },
    { lat: 48.8628159, lng: 22.381904 },
    { lat: 48.8628944, lng: 22.3817287 },
    { lat: 48.8629022, lng: 22.3814065 },
    { lat: 48.8638185, lng: 22.3817179 },
    { lat: 48.864098, lng: 22.3817422 },
    { lat: 48.8643785, lng: 22.3816521 },
    { lat: 48.8645175, lng: 22.381685 },
    { lat: 48.8651508, lng: 22.3811607 },
    { lat: 48.8651692, lng: 22.3809183 },
    { lat: 48.8655779, lng: 22.3804059 },
    { lat: 48.8656715, lng: 22.3804628 },
    { lat: 48.8661189, lng: 22.3800327 },
    { lat: 48.8664858, lng: 22.3797306 },
    { lat: 48.8665113, lng: 22.3795504 },
    { lat: 48.8667692, lng: 22.3794532 },
    { lat: 48.8671774, lng: 22.3788215 },
    { lat: 48.8676183, lng: 22.3784493 },
    { lat: 48.8677663, lng: 22.3782142 },
    { lat: 48.8683666, lng: 22.3775609 },
    { lat: 48.8684322, lng: 22.3773648 },
    { lat: 48.8686836, lng: 22.3771811 },
    { lat: 48.8687991, lng: 22.3768936 },
    { lat: 48.8688289, lng: 22.3768175 },
    { lat: 48.8691075, lng: 22.3768547 },
    { lat: 48.8695253, lng: 22.3764057 },
    { lat: 48.8695867, lng: 22.376432 },
    { lat: 48.8699819, lng: 22.375818 },
    { lat: 48.8695246, lng: 22.3749346 },
    { lat: 48.8697169, lng: 22.3735992 },
    { lat: 48.8698522, lng: 22.3726637 },
    { lat: 48.8697919, lng: 22.3722076 },
    { lat: 48.8696616, lng: 22.371846 },
    { lat: 48.8695936, lng: 22.3716582 },
    { lat: 48.8694815, lng: 22.3716769 },
    { lat: 48.8693781, lng: 22.3709863 },
    { lat: 48.8694979, lng: 22.3709123 },
    { lat: 48.8694709, lng: 22.3708075 },
    { lat: 48.8692782, lng: 22.3700452 },
    { lat: 48.8690033, lng: 22.3690158 },
    { lat: 48.8689211, lng: 22.368853 },
    { lat: 48.8689429, lng: 22.3685447 },
    { lat: 48.8687679, lng: 22.3682517 },
    { lat: 48.8687223, lng: 22.367802 },
    { lat: 48.8686263, lng: 22.3673084 },
    { lat: 48.8684056, lng: 22.3668729 },
    { lat: 48.8682804, lng: 22.3666911 },
    { lat: 48.8682839, lng: 22.3659568 },
    { lat: 48.8684658, lng: 22.365675 },
    { lat: 48.8684276, lng: 22.3651564 },
    { lat: 48.8684733, lng: 22.3649227 },
    { lat: 48.8684083, lng: 22.3646111 },
    { lat: 48.8683831, lng: 22.3640008 },
    { lat: 48.8683051, lng: 22.363727 },
    { lat: 48.8684563, lng: 22.3631691 },
    { lat: 48.8684322, lng: 22.3628763 },
    { lat: 48.8685369, lng: 22.3626661 },
    { lat: 48.8686106, lng: 22.3623328 },
    { lat: 48.8686092, lng: 22.3618877 },
    { lat: 48.8685726, lng: 22.3613343 },
    { lat: 48.8685219, lng: 22.3609124 },
    { lat: 48.8685635, lng: 22.3605967 },
    { lat: 48.8684225, lng: 22.3599395 },
    { lat: 48.8685772, lng: 22.3592807 },
    { lat: 48.8685733, lng: 22.359094 },
    { lat: 48.8686535, lng: 22.3589554 },
    { lat: 48.8687744, lng: 22.3589317 },
    { lat: 48.8689286, lng: 22.3584579 },
    { lat: 48.8689549, lng: 22.3583759 },
    { lat: 48.8689664, lng: 22.3583317 },
    { lat: 48.8691302, lng: 22.3584223 },
    { lat: 48.8692606, lng: 22.3584145 },
    { lat: 48.8693535, lng: 22.3583482 },
    { lat: 48.8695725, lng: 22.3580631 },
    { lat: 48.8697701, lng: 22.357948 },
    { lat: 48.8700182, lng: 22.3577094 },
    { lat: 48.8701537, lng: 22.3576323 },
    { lat: 48.8702683, lng: 22.3575681 },
    { lat: 48.8704799, lng: 22.3575778 },
    { lat: 48.8704779, lng: 22.35754 },
    { lat: 48.8705162, lng: 22.3575442 },
    { lat: 48.8706438, lng: 22.3575058 },
    { lat: 48.8708521, lng: 22.3569944 },
    { lat: 48.8709152, lng: 22.3565301 },
    { lat: 48.8709541, lng: 22.3559787 },
    { lat: 48.8708201, lng: 22.3549085 },
    { lat: 48.8702305, lng: 22.3552639 },
    { lat: 48.8702241, lng: 22.3554975 },
    { lat: 48.8700144, lng: 22.3556303 },
    { lat: 48.8696903, lng: 22.3556734 },
    { lat: 48.8699919, lng: 22.3546858 },
    { lat: 48.8701362, lng: 22.3539063 },
    { lat: 48.8700551, lng: 22.3534859 },
    { lat: 48.8700752, lng: 22.3529462 },
    { lat: 48.8699211, lng: 22.352784 },
    { lat: 48.8698386, lng: 22.3520843 },
    { lat: 48.86987, lng: 22.3517684 },
    { lat: 48.8697068, lng: 22.3514999 },
    { lat: 48.8696192, lng: 22.3512369 },
    { lat: 48.8696991, lng: 22.3509715 },
    { lat: 48.8696071, lng: 22.3504609 },
    { lat: 48.8694583, lng: 22.3501263 },
    { lat: 48.8694552, lng: 22.3497178 },
    { lat: 48.8693544, lng: 22.3493222 },
    { lat: 48.8693781, lng: 22.3489563 },
    { lat: 48.8693681, lng: 22.3480658 },
    { lat: 48.8693301, lng: 22.3475032 },
    { lat: 48.8695528, lng: 22.3467096 },
    { lat: 48.8694857, lng: 22.3461505 },
    { lat: 48.8694543, lng: 22.3453654 },
    { lat: 48.8695646, lng: 22.345002 },
    { lat: 48.8694871, lng: 22.344491 },
    { lat: 48.8694501, lng: 22.3440706 },
    { lat: 48.8694534, lng: 22.343431 },
    { lat: 48.8693414, lng: 22.3432457 },
    { lat: 48.8692694, lng: 22.3427741 },
    { lat: 48.8696056, lng: 22.3427718 },
    { lat: 48.8694914, lng: 22.3420072 },
    { lat: 48.869282, lng: 22.3419986 },
    { lat: 48.8692049, lng: 22.3418649 },
    { lat: 48.869013, lng: 22.3418865 },
    { lat: 48.8690061, lng: 22.3416657 },
    { lat: 48.8687379, lng: 22.3408072 },
    { lat: 48.8689208, lng: 22.3405217 },
    { lat: 48.8697458, lng: 22.3394514 },
    { lat: 48.8699395, lng: 22.3391996 },
    { lat: 48.8701336, lng: 22.3387556 },
    { lat: 48.8704408, lng: 22.3385611 },
    { lat: 48.870631, lng: 22.3386178 },
    { lat: 48.8708496, lng: 22.3384806 },
    { lat: 48.8711061, lng: 22.3383186 },
    { lat: 48.8708113, lng: 22.3378905 },
    { lat: 48.870523, lng: 22.3370984 },
    { lat: 48.8706565, lng: 22.3367674 },
    { lat: 48.8702009, lng: 22.3363898 },
    { lat: 48.870186, lng: 22.3363784 },
    { lat: 48.8703969, lng: 22.3358263 },
    { lat: 48.870564, lng: 22.3352897 },
    { lat: 48.8709023, lng: 22.3340251 },
    { lat: 48.8711139, lng: 22.3333533 },
    { lat: 48.8711298, lng: 22.3333625 },
    { lat: 48.8716421, lng: 22.3323965 },
    { lat: 48.8716213, lng: 22.3323714 },
    { lat: 48.8716099, lng: 22.3323566 },
    { lat: 48.87234, lng: 22.331214 },
    { lat: 48.8723239, lng: 22.3312034 },
    { lat: 48.8721616, lng: 22.3310314 },
    { lat: 48.8724334, lng: 22.3305388 },
    { lat: 48.8729811, lng: 22.3298643 },
    { lat: 48.8733259, lng: 22.3295214 },
    { lat: 48.8739769, lng: 22.3287381 },
    { lat: 48.8740616, lng: 22.3288543 },
    { lat: 48.8744188, lng: 22.3283665 },
    { lat: 48.8747033, lng: 22.3276392 },
    { lat: 48.8747543, lng: 22.3272394 },
    { lat: 48.8751187, lng: 22.3267704 },
    { lat: 48.8750242, lng: 22.3263996 },
    { lat: 48.8748068, lng: 22.3256504 },
    { lat: 48.8746923, lng: 22.3246274 },
    { lat: 48.8748812, lng: 22.323738 },
    { lat: 48.8751063, lng: 22.3232295 },
    { lat: 48.8747329, lng: 22.3231155 },
    { lat: 48.8742042, lng: 22.3229599 },
    { lat: 48.8736131, lng: 22.3224112 },
    { lat: 48.873379, lng: 22.3220362 },
    { lat: 48.8732084, lng: 22.3216388 },
    { lat: 48.8727441, lng: 22.3210717 },
    { lat: 48.872419, lng: 22.3206087 },
    { lat: 48.8720155, lng: 22.3202043 },
    { lat: 48.8717916, lng: 22.3199868 },
    { lat: 48.8717572, lng: 22.3199539 },
    { lat: 48.8717055, lng: 22.3199036 },
    { lat: 48.87166, lng: 22.3198837 },
    { lat: 48.8716169, lng: 22.3198647 },
    { lat: 48.8708145, lng: 22.3191144 },
    { lat: 48.8703802, lng: 22.3185836 },
    { lat: 48.8692721, lng: 22.3165513 },
    { lat: 48.8688316, lng: 22.3165172 },
    { lat: 48.8668874, lng: 22.3166911 },
    { lat: 48.8657432, lng: 22.3164619 },
    { lat: 48.8652618, lng: 22.3165634 },
    { lat: 48.8646316, lng: 22.3173785 },
    { lat: 48.8640595, lng: 22.3191581 },
    { lat: 48.8635348, lng: 22.3197086 },
    { lat: 48.862844, lng: 22.3203173 },
    { lat: 48.8618771, lng: 22.3208008 },
    { lat: 48.86102, lng: 22.3202265 },
    { lat: 48.86026, lng: 22.3200917 },
    { lat: 48.8584633, lng: 22.3196474 },
    { lat: 48.8573447, lng: 22.3192962 },
    { lat: 48.8568594, lng: 22.3192207 },
    { lat: 48.8555245, lng: 22.3179795 },
    { lat: 48.8539497, lng: 22.3171425 },
    { lat: 48.8533109, lng: 22.3173539 },
    { lat: 48.8525372, lng: 22.3184981 },
    { lat: 48.8521843, lng: 22.3186174 },
    { lat: 48.8515245, lng: 22.3185774 },
    { lat: 48.8501201, lng: 22.3189176 },
    { lat: 48.8498059, lng: 22.3191535 },
    { lat: 48.8492248, lng: 22.3199977 },
    { lat: 48.8486028, lng: 22.3203646 },
    { lat: 48.8480734, lng: 22.3203295 },
    { lat: 48.8475197, lng: 22.3200967 },
    { lat: 48.8467977, lng: 22.3202094 },
    { lat: 48.8460954, lng: 22.3204414 },
    { lat: 48.8452112, lng: 22.3206389 },
    { lat: 48.8446095, lng: 22.3207484 },
    { lat: 48.8441253, lng: 22.3208296 },
    { lat: 48.8437859, lng: 22.3213638 },
    { lat: 48.8434342, lng: 22.3216873 },
    { lat: 48.8427585, lng: 22.3225821 },
    { lat: 48.8424215, lng: 22.3232247 },
    { lat: 48.8418903, lng: 22.3239909 },
    { lat: 48.8415362, lng: 22.3243127 },
    { lat: 48.8409556, lng: 22.3251777 },
    { lat: 48.840438, lng: 22.3256454 },
    { lat: 48.8396913, lng: 22.3260595 },
    { lat: 48.8385292, lng: 22.3258429 },
    { lat: 48.838058, lng: 22.3256877 },
    { lat: 48.8374231, lng: 22.3256649 },
    { lat: 48.8369273, lng: 22.3253082 },
    { lat: 48.8355526, lng: 22.324928 },
    { lat: 48.834824, lng: 22.3250423 },
    { lat: 48.8344318, lng: 22.3261563 },
    { lat: 48.8341062, lng: 22.3273163 },
    { lat: 48.8325817, lng: 22.3299532 },
    { lat: 48.8319925, lng: 22.3305321 },
    { lat: 48.8306054, lng: 22.3315118 },
    { lat: 48.8303307, lng: 22.3315511 },
    { lat: 48.8290267, lng: 22.3339815 },
    { lat: 48.8317267, lng: 22.3372545 },
    { lat: 48.8348757, lng: 22.3382444 },
    { lat: 48.8356673, lng: 22.3383432 },
    { lat: 48.8364713, lng: 22.3400568 },
    { lat: 48.8368727, lng: 22.3427304 },
    { lat: 48.8362439, lng: 22.3441359 },
    { lat: 48.8363106, lng: 22.3446299 },
    { lat: 48.8372014, lng: 22.348146 },
    { lat: 48.8380035, lng: 22.3479409 },
    { lat: 48.8387014, lng: 22.3476574 },
    { lat: 48.8396814, lng: 22.3486743 },
    { lat: 48.8390542, lng: 22.3500015 },
    { lat: 48.8389179, lng: 22.3501549 },
    { lat: 48.8388155, lng: 22.3504666 },
    { lat: 48.8385145, lng: 22.3509063 },
    { lat: 48.8381863, lng: 22.3511202 },
    { lat: 48.8378386, lng: 22.351591 },
    { lat: 48.8393089, lng: 22.3534519 },
    { lat: 48.83911, lng: 22.3555592 },
    { lat: 48.840694, lng: 22.3602275 },
    { lat: 48.8400866, lng: 22.3604336 },
    { lat: 48.8398935, lng: 22.3608729 },
    { lat: 48.8396966, lng: 22.3608222 },
    { lat: 48.8395212, lng: 22.3609307 },
    { lat: 48.8394454, lng: 22.3610665 },
    { lat: 48.8395599, lng: 22.3612119 },
    { lat: 48.8394247, lng: 22.3613032 },
    { lat: 48.839239, lng: 22.3612494 },
    { lat: 48.8391275, lng: 22.3614016 },
    { lat: 48.8391397, lng: 22.3615984 },
    { lat: 48.8390469, lng: 22.3618361 },
    { lat: 48.8389541, lng: 22.3618688 },
    { lat: 48.8387543, lng: 22.3613516 },
    { lat: 48.8385746, lng: 22.3612569 },
    { lat: 48.8382338, lng: 22.3613681 },
    { lat: 48.8381923, lng: 22.3617982 },
    { lat: 48.8382589, lng: 22.3653967 },
    { lat: 48.8382343, lng: 22.3656707 },
    { lat: 48.8378582, lng: 22.3665484 },
    { lat: 48.8377909, lng: 22.3676716 },
    { lat: 48.8375938, lng: 22.3684031 },
    { lat: 48.8359712, lng: 22.3712808 },
    { lat: 48.8351351, lng: 22.3722629 },
    { lat: 48.835714, lng: 22.375866 },
  ],
  RuskáBystrá: [
    { lat: 48.8446095, lng: 22.3207484 },
    { lat: 48.8452112, lng: 22.3206389 },
    { lat: 48.8460954, lng: 22.3204414 },
    { lat: 48.8467977, lng: 22.3202094 },
    { lat: 48.8475197, lng: 22.3200967 },
    { lat: 48.8480734, lng: 22.3203295 },
    { lat: 48.8486028, lng: 22.3203646 },
    { lat: 48.8492248, lng: 22.3199977 },
    { lat: 48.8498059, lng: 22.3191535 },
    { lat: 48.8501201, lng: 22.3189176 },
    { lat: 48.8515245, lng: 22.3185774 },
    { lat: 48.8521843, lng: 22.3186174 },
    { lat: 48.8525372, lng: 22.3184981 },
    { lat: 48.8533109, lng: 22.3173539 },
    { lat: 48.8539497, lng: 22.3171425 },
    { lat: 48.8555245, lng: 22.3179795 },
    { lat: 48.8568594, lng: 22.3192207 },
    { lat: 48.8573447, lng: 22.3192962 },
    { lat: 48.8584633, lng: 22.3196474 },
    { lat: 48.86026, lng: 22.3200917 },
    { lat: 48.86102, lng: 22.3202265 },
    { lat: 48.8618771, lng: 22.3208008 },
    { lat: 48.862844, lng: 22.3203173 },
    { lat: 48.8635348, lng: 22.3197086 },
    { lat: 48.8640595, lng: 22.3191581 },
    { lat: 48.8646316, lng: 22.3173785 },
    { lat: 48.8652618, lng: 22.3165634 },
    { lat: 48.8657432, lng: 22.3164619 },
    { lat: 48.8668874, lng: 22.3166911 },
    { lat: 48.8688316, lng: 22.3165172 },
    { lat: 48.8692721, lng: 22.3165513 },
    { lat: 48.8703802, lng: 22.3185836 },
    { lat: 48.8708145, lng: 22.3191144 },
    { lat: 48.8716169, lng: 22.3198647 },
    { lat: 48.8716124, lng: 22.3197078 },
    { lat: 48.8716432, lng: 22.3194247 },
    { lat: 48.8718118, lng: 22.319004 },
    { lat: 48.8720024, lng: 22.3184536 },
    { lat: 48.872067, lng: 22.318012 },
    { lat: 48.8720409, lng: 22.3175796 },
    { lat: 48.8721187, lng: 22.3173274 },
    { lat: 48.8721156, lng: 22.317294 },
    { lat: 48.8720648, lng: 22.3166577 },
    { lat: 48.8720653, lng: 22.3166241 },
    { lat: 48.8720657, lng: 22.316285 },
    { lat: 48.8719395, lng: 22.3161156 },
    { lat: 48.871841, lng: 22.3158346 },
    { lat: 48.8718456, lng: 22.3155272 },
    { lat: 48.8718101, lng: 22.315445 },
    { lat: 48.8715127, lng: 22.3147484 },
    { lat: 48.8714842, lng: 22.3147266 },
    { lat: 48.8714314, lng: 22.3146864 },
    { lat: 48.8710564, lng: 22.3143978 },
    { lat: 48.8709136, lng: 22.314346 },
    { lat: 48.8707671, lng: 22.3141761 },
    { lat: 48.8705129, lng: 22.3136064 },
    { lat: 48.8702263, lng: 22.3128883 },
    { lat: 48.8701024, lng: 22.3127841 },
    { lat: 48.8700268, lng: 22.3126103 },
    { lat: 48.8701381, lng: 22.3125072 },
    { lat: 48.8698608, lng: 22.3115997 },
    { lat: 48.8697917, lng: 22.3112191 },
    { lat: 48.8698046, lng: 22.311137 },
    { lat: 48.8698299, lng: 22.3110018 },
    { lat: 48.8698436, lng: 22.3108067 },
    { lat: 48.8698217, lng: 22.3098007 },
    { lat: 48.8698204, lng: 22.3096293 },
    { lat: 48.8696772, lng: 22.3091577 },
    { lat: 48.8695921, lng: 22.309167 },
    { lat: 48.8695749, lng: 22.3089949 },
    { lat: 48.8695418, lng: 22.3087751 },
    { lat: 48.8694876, lng: 22.3085016 },
    { lat: 48.8695288, lng: 22.3079704 },
    { lat: 48.8697582, lng: 22.3074449 },
    { lat: 48.8696902, lng: 22.3073186 },
    { lat: 48.8696703, lng: 22.3072828 },
    { lat: 48.8697108, lng: 22.3069232 },
    { lat: 48.869707, lng: 22.3068994 },
    { lat: 48.8696486, lng: 22.3068562 },
    { lat: 48.8696499, lng: 22.3067676 },
    { lat: 48.8696953, lng: 22.306621 },
    { lat: 48.8697433, lng: 22.30651 },
    { lat: 48.8697517, lng: 22.3064274 },
    { lat: 48.8697455, lng: 22.3063505 },
    { lat: 48.8696307, lng: 22.3061873 },
    { lat: 48.8695592, lng: 22.3059543 },
    { lat: 48.869432, lng: 22.3057971 },
    { lat: 48.8694301, lng: 22.305655 },
    { lat: 48.8694064, lng: 22.3054415 },
    { lat: 48.8693738, lng: 22.3052334 },
    { lat: 48.8693486, lng: 22.3050078 },
    { lat: 48.8693563, lng: 22.304742 },
    { lat: 48.8692784, lng: 22.3045762 },
    { lat: 48.869267, lng: 22.3045163 },
    { lat: 48.869259, lng: 22.3043563 },
    { lat: 48.869175, lng: 22.3042641 },
    { lat: 48.8692673, lng: 22.3041201 },
    { lat: 48.8692404, lng: 22.3040657 },
    { lat: 48.8691627, lng: 22.3040686 },
    { lat: 48.8691512, lng: 22.3040502 },
    { lat: 48.869159, lng: 22.3038437 },
    { lat: 48.8691404, lng: 22.3038132 },
    { lat: 48.8690821, lng: 22.3037754 },
    { lat: 48.8690594, lng: 22.3037093 },
    { lat: 48.8689622, lng: 22.3034863 },
    { lat: 48.8688304, lng: 22.303475 },
    { lat: 48.8687378, lng: 22.3029863 },
    { lat: 48.8684909, lng: 22.3028104 },
    { lat: 48.868485, lng: 22.3027449 },
    { lat: 48.8684515, lng: 22.3026548 },
    { lat: 48.8684103, lng: 22.30257 },
    { lat: 48.8682784, lng: 22.3022926 },
    { lat: 48.868258, lng: 22.3021379 },
    { lat: 48.8683134, lng: 22.3020515 },
    { lat: 48.8683227, lng: 22.3020107 },
    { lat: 48.8682523, lng: 22.3019842 },
    { lat: 48.8684024, lng: 22.3014819 },
    { lat: 48.8684955, lng: 22.3015272 },
    { lat: 48.8685848, lng: 22.3013416 },
    { lat: 48.8686557, lng: 22.3012856 },
    { lat: 48.8686966, lng: 22.3012046 },
    { lat: 48.868744, lng: 22.301165 },
    { lat: 48.8688162, lng: 22.3010146 },
    { lat: 48.8690421, lng: 22.3006457 },
    { lat: 48.8690368, lng: 22.3002494 },
    { lat: 48.8692911, lng: 22.2997986 },
    { lat: 48.8692916, lng: 22.2997636 },
    { lat: 48.8692028, lng: 22.2997123 },
    { lat: 48.8692837, lng: 22.2995561 },
    { lat: 48.8693382, lng: 22.2993218 },
    { lat: 48.8692822, lng: 22.2991835 },
    { lat: 48.8692399, lng: 22.2989924 },
    { lat: 48.8692407, lng: 22.2989216 },
    { lat: 48.8693249, lng: 22.2988188 },
    { lat: 48.8692878, lng: 22.2986634 },
    { lat: 48.8693076, lng: 22.2982327 },
    { lat: 48.8692484, lng: 22.2980881 },
    { lat: 48.8692738, lng: 22.2977638 },
    { lat: 48.869212, lng: 22.2975129 },
    { lat: 48.869296, lng: 22.2973865 },
    { lat: 48.8692339, lng: 22.2971771 },
    { lat: 48.8691942, lng: 22.296998 },
    { lat: 48.8693644, lng: 22.2966146 },
    { lat: 48.8692861, lng: 22.2964282 },
    { lat: 48.8693493, lng: 22.2961706 },
    { lat: 48.8692936, lng: 22.2955832 },
    { lat: 48.869383, lng: 22.2951965 },
    { lat: 48.869497, lng: 22.2946988 },
    { lat: 48.8694437, lng: 22.2944185 },
    { lat: 48.8694742, lng: 22.2942187 },
    { lat: 48.8694731, lng: 22.2940709 },
    { lat: 48.8694808, lng: 22.2938522 },
    { lat: 48.8695349, lng: 22.2936537 },
    { lat: 48.8695322, lng: 22.2933759 },
    { lat: 48.8696161, lng: 22.2928411 },
    { lat: 48.8696948, lng: 22.2923774 },
    { lat: 48.8696435, lng: 22.2919909 },
    { lat: 48.8697295, lng: 22.2917519 },
    { lat: 48.869761, lng: 22.2915463 },
    { lat: 48.8699764, lng: 22.2910585 },
    { lat: 48.870007, lng: 22.2908648 },
    { lat: 48.8700501, lng: 22.2906243 },
    { lat: 48.870099, lng: 22.2903425 },
    { lat: 48.8701256, lng: 22.2901661 },
    { lat: 48.8701123, lng: 22.2900238 },
    { lat: 48.8701462, lng: 22.2898835 },
    { lat: 48.8701438, lng: 22.2897711 },
    { lat: 48.8701647, lng: 22.2894878 },
    { lat: 48.8701161, lng: 22.2893795 },
    { lat: 48.8701113, lng: 22.289214 },
    { lat: 48.8700826, lng: 22.289035 },
    { lat: 48.8700587, lng: 22.2888686 },
    { lat: 48.8700764, lng: 22.2887509 },
    { lat: 48.8699754, lng: 22.2885513 },
    { lat: 48.869925, lng: 22.288307 },
    { lat: 48.8697376, lng: 22.2881217 },
    { lat: 48.8697039, lng: 22.2880137 },
    { lat: 48.8695248, lng: 22.2877994 },
    { lat: 48.8694642, lng: 22.2877021 },
    { lat: 48.8693804, lng: 22.2875745 },
    { lat: 48.8693717, lng: 22.2874381 },
    { lat: 48.8693692, lng: 22.2873257 },
    { lat: 48.8693271, lng: 22.2870931 },
    { lat: 48.8693041, lng: 22.2870746 },
    { lat: 48.8692617, lng: 22.2870314 },
    { lat: 48.8692077, lng: 22.2869758 },
    { lat: 48.8691677, lng: 22.2868503 },
    { lat: 48.8691048, lng: 22.2866463 },
    { lat: 48.8691188, lng: 22.286523 },
    { lat: 48.8691122, lng: 22.2864694 },
    { lat: 48.8690604, lng: 22.2863195 },
    { lat: 48.869134, lng: 22.2860861 },
    { lat: 48.8691295, lng: 22.2859262 },
    { lat: 48.869128, lng: 22.2858138 },
    { lat: 48.8691205, lng: 22.285772 },
    { lat: 48.8690833, lng: 22.2856699 },
    { lat: 48.8690506, lng: 22.2855619 },
    { lat: 48.8690106, lng: 22.2854246 },
    { lat: 48.8690071, lng: 22.28517 },
    { lat: 48.8690262, lng: 22.2851708 },
    { lat: 48.8691326, lng: 22.2848972 },
    { lat: 48.8691729, lng: 22.2843728 },
    { lat: 48.8692551, lng: 22.2839153 },
    { lat: 48.8691659, lng: 22.2836983 },
    { lat: 48.8691464, lng: 22.2832717 },
    { lat: 48.8691203, lng: 22.2831011 },
    { lat: 48.8691121, lng: 22.2830397 },
    { lat: 48.8689481, lng: 22.2823764 },
    { lat: 48.8687194, lng: 22.2818467 },
    { lat: 48.868363, lng: 22.2813406 },
    { lat: 48.868223, lng: 22.2810629 },
    { lat: 48.8682573, lng: 22.28068 },
    { lat: 48.8682742, lng: 22.2806334 },
    { lat: 48.86819, lng: 22.2805355 },
    { lat: 48.8681066, lng: 22.2803782 },
    { lat: 48.8680287, lng: 22.280156 },
    { lat: 48.8678926, lng: 22.2799496 },
    { lat: 48.867911, lng: 22.2793588 },
    { lat: 48.8679298, lng: 22.279141 },
    { lat: 48.8678679, lng: 22.2789313 },
    { lat: 48.8679142, lng: 22.2786381 },
    { lat: 48.867939, lng: 22.2784797 },
    { lat: 48.8679215, lng: 22.278253 },
    { lat: 48.8679025, lng: 22.2779967 },
    { lat: 48.8678115, lng: 22.2778059 },
    { lat: 48.8677818, lng: 22.2776199 },
    { lat: 48.8677395, lng: 22.2772312 },
    { lat: 48.8678046, lng: 22.2770285 },
    { lat: 48.8678263, lng: 22.2769353 },
    { lat: 48.8678463, lng: 22.2768239 },
    { lat: 48.8678808, lng: 22.2767021 },
    { lat: 48.8679039, lng: 22.2764783 },
    { lat: 48.867935, lng: 22.2762078 },
    { lat: 48.8680113, lng: 22.2757863 },
    { lat: 48.8680804, lng: 22.275447 },
    { lat: 48.8680825, lng: 22.275394 },
    { lat: 48.8681097, lng: 22.2749925 },
    { lat: 48.8679312, lng: 22.2747068 },
    { lat: 48.8678929, lng: 22.2745558 },
    { lat: 48.8678611, lng: 22.2743281 },
    { lat: 48.867804, lng: 22.2740391 },
    { lat: 48.8677317, lng: 22.2738677 },
    { lat: 48.8676668, lng: 22.2736852 },
    { lat: 48.8675873, lng: 22.2734895 },
    { lat: 48.8675717, lng: 22.2732395 },
    { lat: 48.8675468, lng: 22.2728818 },
    { lat: 48.8675301, lng: 22.272679 },
    { lat: 48.8675126, lng: 22.2726006 },
    { lat: 48.8674729, lng: 22.2724907 },
    { lat: 48.8673927, lng: 22.272313 },
    { lat: 48.8674031, lng: 22.2722306 },
    { lat: 48.867444, lng: 22.2720439 },
    { lat: 48.8674759, lng: 22.2719097 },
    { lat: 48.8675408, lng: 22.2718375 },
    { lat: 48.8675421, lng: 22.2718017 },
    { lat: 48.8674362, lng: 22.2714323 },
    { lat: 48.8673858, lng: 22.2712866 },
    { lat: 48.8674131, lng: 22.2709444 },
    { lat: 48.8674266, lng: 22.2708505 },
    { lat: 48.8674693, lng: 22.2707468 },
    { lat: 48.8674999, lng: 22.2706481 },
    { lat: 48.8675151, lng: 22.2705248 },
    { lat: 48.8675262, lng: 22.2704189 },
    { lat: 48.8676401, lng: 22.2700237 },
    { lat: 48.867586, lng: 22.2697646 },
    { lat: 48.8675669, lng: 22.2696151 },
    { lat: 48.8675627, lng: 22.2693717 },
    { lat: 48.8675674, lng: 22.2691225 },
    { lat: 48.8675107, lng: 22.2687985 },
    { lat: 48.867475, lng: 22.2685588 },
    { lat: 48.8674273, lng: 22.2683297 },
    { lat: 48.8674639, lng: 22.2680535 },
    { lat: 48.8672688, lng: 22.2676718 },
    { lat: 48.8672563, lng: 22.2675642 },
    { lat: 48.8672453, lng: 22.2671661 },
    { lat: 48.8672363, lng: 22.2669576 },
    { lat: 48.867185, lng: 22.2668355 },
    { lat: 48.8671212, lng: 22.2666469 },
    { lat: 48.8670549, lng: 22.2664941 },
    { lat: 48.8669674, lng: 22.2662978 },
    { lat: 48.8668943, lng: 22.26615 },
    { lat: 48.8666648, lng: 22.2657362 },
    { lat: 48.8666858, lng: 22.2657137 },
    { lat: 48.8665465, lng: 22.2652649 },
    { lat: 48.8663079, lng: 22.2643997 },
    { lat: 48.8662233, lng: 22.2634744 },
    { lat: 48.8662355, lng: 22.2623421 },
    { lat: 48.8662862, lng: 22.261735 },
    { lat: 48.8662958, lng: 22.2610537 },
    { lat: 48.866211, lng: 22.2598612 },
    { lat: 48.8660868, lng: 22.2589805 },
    { lat: 48.865886, lng: 22.2582484 },
    { lat: 48.8657738, lng: 22.2575989 },
    { lat: 48.8655504, lng: 22.2577157 },
    { lat: 48.8652973, lng: 22.2579708 },
    { lat: 48.8645035, lng: 22.2585431 },
    { lat: 48.8637022, lng: 22.2588747 },
    { lat: 48.8633995, lng: 22.2586147 },
    { lat: 48.862351, lng: 22.2585978 },
    { lat: 48.860444, lng: 22.2587238 },
    { lat: 48.8598893, lng: 22.258407 },
    { lat: 48.8590098, lng: 22.2588172 },
    { lat: 48.8585177, lng: 22.2589448 },
    { lat: 48.8581463, lng: 22.2589897 },
    { lat: 48.8577442, lng: 22.2592095 },
    { lat: 48.8568095, lng: 22.259211 },
    { lat: 48.8561864, lng: 22.2588524 },
    { lat: 48.8558515, lng: 22.2589687 },
    { lat: 48.8547935, lng: 22.2599483 },
    { lat: 48.8543619, lng: 22.2600096 },
    { lat: 48.853764, lng: 22.2602307 },
    { lat: 48.8530258, lng: 22.260879 },
    { lat: 48.8526214, lng: 22.2610499 },
    { lat: 48.8519774, lng: 22.2618348 },
    { lat: 48.8503266, lng: 22.2620988 },
    { lat: 48.8502849, lng: 22.2634733 },
    { lat: 48.8501804, lng: 22.2648511 },
    { lat: 48.8502113, lng: 22.2654295 },
    { lat: 48.8501707, lng: 22.2664376 },
    { lat: 48.8499791, lng: 22.2670866 },
    { lat: 48.8499547, lng: 22.267401 },
    { lat: 48.8498623, lng: 22.2675514 },
    { lat: 48.8495669, lng: 22.2684324 },
    { lat: 48.8488847, lng: 22.269633 },
    { lat: 48.8490492, lng: 22.2707131 },
    { lat: 48.8483226, lng: 22.2728051 },
    { lat: 48.8479439, lng: 22.2732254 },
    { lat: 48.8473665, lng: 22.2740684 },
    { lat: 48.8469364, lng: 22.2741566 },
    { lat: 48.8466809, lng: 22.2748088 },
    { lat: 48.8465628, lng: 22.2760389 },
    { lat: 48.8464324, lng: 22.2767507 },
    { lat: 48.846327, lng: 22.279059 },
    { lat: 48.8459284, lng: 22.2803761 },
    { lat: 48.8453792, lng: 22.2815865 },
    { lat: 48.845436, lng: 22.2827856 },
    { lat: 48.8451474, lng: 22.2839371 },
    { lat: 48.8446485, lng: 22.2850364 },
    { lat: 48.8446473, lng: 22.2861513 },
    { lat: 48.8444635, lng: 22.2869022 },
    { lat: 48.844337, lng: 22.2872421 },
    { lat: 48.8441954, lng: 22.2884236 },
    { lat: 48.8442052, lng: 22.2890238 },
    { lat: 48.844336, lng: 22.2901731 },
    { lat: 48.8445357, lng: 22.2930179 },
    { lat: 48.8444411, lng: 22.2935301 },
    { lat: 48.8441825, lng: 22.2940005 },
    { lat: 48.8437373, lng: 22.2946266 },
    { lat: 48.8432349, lng: 22.2951464 },
    { lat: 48.8429672, lng: 22.2957194 },
    { lat: 48.8425805, lng: 22.2961851 },
    { lat: 48.8420122, lng: 22.2972091 },
    { lat: 48.8416177, lng: 22.2980951 },
    { lat: 48.8415173, lng: 22.2988482 },
    { lat: 48.8414318, lng: 22.3007538 },
    { lat: 48.8414539, lng: 22.3019257 },
    { lat: 48.8413581, lng: 22.302841 },
    { lat: 48.8412576, lng: 22.303494 },
    { lat: 48.8407914, lng: 22.3047056 },
    { lat: 48.8397902, lng: 22.3057872 },
    { lat: 48.8393554, lng: 22.3061121 },
    { lat: 48.838524, lng: 22.3068741 },
    { lat: 48.8379403, lng: 22.3072906 },
    { lat: 48.8374063, lng: 22.3078664 },
    { lat: 48.8370139, lng: 22.3087572 },
    { lat: 48.8367964, lng: 22.3090522 },
    { lat: 48.8369946, lng: 22.3097871 },
    { lat: 48.8371051, lng: 22.3100001 },
    { lat: 48.8371751, lng: 22.3105857 },
    { lat: 48.8374188, lng: 22.3110873 },
    { lat: 48.8377823, lng: 22.3122849 },
    { lat: 48.837941, lng: 22.3134252 },
    { lat: 48.8379507, lng: 22.3138189 },
    { lat: 48.8380553, lng: 22.3141332 },
    { lat: 48.8381478, lng: 22.3150269 },
    { lat: 48.838291, lng: 22.3153607 },
    { lat: 48.8386928, lng: 22.3158909 },
    { lat: 48.8391583, lng: 22.3159924 },
    { lat: 48.8394674, lng: 22.3159309 },
    { lat: 48.8399154, lng: 22.3161036 },
    { lat: 48.8401219, lng: 22.3160712 },
    { lat: 48.8404141, lng: 22.316223 },
    { lat: 48.8408592, lng: 22.3167268 },
    { lat: 48.8412865, lng: 22.3170315 },
    { lat: 48.8417736, lng: 22.3172479 },
    { lat: 48.841892, lng: 22.3173599 },
    { lat: 48.8423126, lng: 22.3180863 },
    { lat: 48.8429297, lng: 22.3190018 },
    { lat: 48.8435025, lng: 22.3195947 },
    { lat: 48.8440417, lng: 22.3200281 },
    { lat: 48.8446248, lng: 22.3203673 },
    { lat: 48.8446095, lng: 22.3207484 },
  ],
  Ruskovce: [
    { lat: 48.7646, lng: 22.1291634 },
    { lat: 48.7647523, lng: 22.1296074 },
    { lat: 48.7640003, lng: 22.136097 },
    { lat: 48.7649117, lng: 22.1374222 },
    { lat: 48.7650402, lng: 22.1378172 },
    { lat: 48.7653658, lng: 22.1382288 },
    { lat: 48.7643926, lng: 22.1462597 },
    { lat: 48.763322, lng: 22.1539959 },
    { lat: 48.763293, lng: 22.1543408 },
    { lat: 48.7634265, lng: 22.1560793 },
    { lat: 48.7633814, lng: 22.1562204 },
    { lat: 48.7633397, lng: 22.1577807 },
    { lat: 48.7628679, lng: 22.1621478 },
    { lat: 48.7643648, lng: 22.1639874 },
    { lat: 48.7654588, lng: 22.1647501 },
    { lat: 48.7653764, lng: 22.1648875 },
    { lat: 48.7647968, lng: 22.1712834 },
    { lat: 48.7647923, lng: 22.1713346 },
    { lat: 48.7649711, lng: 22.1716257 },
    { lat: 48.76533, lng: 22.1716739 },
    { lat: 48.7656861, lng: 22.1721455 },
    { lat: 48.765822, lng: 22.172092 },
    { lat: 48.7659426, lng: 22.1719017 },
    { lat: 48.7662508, lng: 22.1716598 },
    { lat: 48.7663025, lng: 22.1717073 },
    { lat: 48.7663265, lng: 22.1719175 },
    { lat: 48.766419, lng: 22.1721708 },
    { lat: 48.7665585, lng: 22.1722729 },
    { lat: 48.7666875, lng: 22.1720608 },
    { lat: 48.766798, lng: 22.1720257 },
    { lat: 48.7668574, lng: 22.1720668 },
    { lat: 48.7669627, lng: 22.1723401 },
    { lat: 48.7665762, lng: 22.1726029 },
    { lat: 48.7659279, lng: 22.1733268 },
    { lat: 48.7647627, lng: 22.1748003 },
    { lat: 48.7627143, lng: 22.1776812 },
    { lat: 48.7622682, lng: 22.1781506 },
    { lat: 48.7621065, lng: 22.1784047 },
    { lat: 48.7621662, lng: 22.1784101 },
    { lat: 48.7626787, lng: 22.1784838 },
    { lat: 48.7630016, lng: 22.1782473 },
    { lat: 48.763962, lng: 22.1786751 },
    { lat: 48.7645846, lng: 22.1786581 },
    { lat: 48.7652447, lng: 22.178943 },
    { lat: 48.7654424, lng: 22.1792785 },
    { lat: 48.7659224, lng: 22.1793585 },
    { lat: 48.7659414, lng: 22.1795479 },
    { lat: 48.766266, lng: 22.1796849 },
    { lat: 48.7663131, lng: 22.1793755 },
    { lat: 48.7665124, lng: 22.1793896 },
    { lat: 48.7665782, lng: 22.17966 },
    { lat: 48.7667243, lng: 22.1797002 },
    { lat: 48.7668035, lng: 22.1795748 },
    { lat: 48.7670097, lng: 22.1794905 },
    { lat: 48.7669131, lng: 22.1792995 },
    { lat: 48.7672465, lng: 22.1793255 },
    { lat: 48.7675182, lng: 22.179547 },
    { lat: 48.7676855, lng: 22.1793813 },
    { lat: 48.7678146, lng: 22.1794722 },
    { lat: 48.7676863, lng: 22.1797132 },
    { lat: 48.7676554, lng: 22.1800336 },
    { lat: 48.7677376, lng: 22.1801841 },
    { lat: 48.7681266, lng: 22.1798784 },
    { lat: 48.7684388, lng: 22.1794723 },
    { lat: 48.7691084, lng: 22.1800288 },
    { lat: 48.7693942, lng: 22.1798801 },
    { lat: 48.7697357, lng: 22.1803529 },
    { lat: 48.7697843, lng: 22.1808581 },
    { lat: 48.7699104, lng: 22.1811061 },
    { lat: 48.7704573, lng: 22.181612 },
    { lat: 48.7706246, lng: 22.1815989 },
    { lat: 48.7706256, lng: 22.1813468 },
    { lat: 48.7709507, lng: 22.1813979 },
    { lat: 48.7711183, lng: 22.1818316 },
    { lat: 48.7713044, lng: 22.1820059 },
    { lat: 48.7716811, lng: 22.1815615 },
    { lat: 48.771894, lng: 22.1809373 },
    { lat: 48.7721075, lng: 22.1811729 },
    { lat: 48.7723277, lng: 22.1810605 },
    { lat: 48.7726298, lng: 22.1813346 },
    { lat: 48.7727539, lng: 22.1813505 },
    { lat: 48.7728209, lng: 22.1819728 },
    { lat: 48.7731535, lng: 22.1820057 },
    { lat: 48.7734019, lng: 22.1816536 },
    { lat: 48.773471, lng: 22.1812324 },
    { lat: 48.7736526, lng: 22.1812999 },
    { lat: 48.7738023, lng: 22.1812097 },
    { lat: 48.7738569, lng: 22.1806463 },
    { lat: 48.774041, lng: 22.1807728 },
    { lat: 48.7743802, lng: 22.1807533 },
    { lat: 48.7744421, lng: 22.1803695 },
    { lat: 48.774488, lng: 22.1803044 },
    { lat: 48.7751868, lng: 22.1807642 },
    { lat: 48.7758651, lng: 22.1804651 },
    { lat: 48.7760674, lng: 22.1799709 },
    { lat: 48.7763961, lng: 22.1799273 },
    { lat: 48.776617, lng: 22.1793099 },
    { lat: 48.7767491, lng: 22.1792844 },
    { lat: 48.7768648, lng: 22.1798707 },
    { lat: 48.7771111, lng: 22.1800657 },
    { lat: 48.7771056, lng: 22.1804133 },
    { lat: 48.7775352, lng: 22.1806062 },
    { lat: 48.7777959, lng: 22.1812324 },
    { lat: 48.7786551, lng: 22.1815248 },
    { lat: 48.7786579, lng: 22.1814019 },
    { lat: 48.7788856, lng: 22.181711 },
    { lat: 48.7789973, lng: 22.1816277 },
    { lat: 48.779122, lng: 22.181679 },
    { lat: 48.7791509, lng: 22.1823972 },
    { lat: 48.7800361, lng: 22.1817538 },
    { lat: 48.7815508, lng: 22.1820354 },
    { lat: 48.781557, lng: 22.1817198 },
    { lat: 48.7809108, lng: 22.1793858 },
    { lat: 48.7808438, lng: 22.1779724 },
    { lat: 48.7805712, lng: 22.1763903 },
    { lat: 48.7815356, lng: 22.1761607 },
    { lat: 48.7816038, lng: 22.1758486 },
    { lat: 48.782052, lng: 22.1757298 },
    { lat: 48.7822329, lng: 22.1759094 },
    { lat: 48.7828077, lng: 22.1761125 },
    { lat: 48.7830603, lng: 22.1758941 },
    { lat: 48.7834995, lng: 22.1756171 },
    { lat: 48.7835002, lng: 22.175061 },
    { lat: 48.783473, lng: 22.16986 },
    { lat: 48.7833523, lng: 22.1670515 },
    { lat: 48.7833711, lng: 22.1667523 },
    { lat: 48.7836158, lng: 22.1667227 },
    { lat: 48.7844668, lng: 22.1669532 },
    { lat: 48.7843968, lng: 22.1666473 },
    { lat: 48.7844341, lng: 22.1661678 },
    { lat: 48.7843236, lng: 22.1648774 },
    { lat: 48.7845283, lng: 22.1631717 },
    { lat: 48.7852881, lng: 22.1585057 },
    { lat: 48.7862156, lng: 22.1535229 },
    { lat: 48.7867451, lng: 22.151613 },
    { lat: 48.7875402, lng: 22.1490272 },
    { lat: 48.7862166, lng: 22.1488915 },
    { lat: 48.785245, lng: 22.148561 },
    { lat: 48.7829176, lng: 22.1474414 },
    { lat: 48.7814388, lng: 22.1470116 },
    { lat: 48.7809678, lng: 22.1472379 },
    { lat: 48.7806181, lng: 22.1480099 },
    { lat: 48.7804728, lng: 22.1477116 },
    { lat: 48.7804038, lng: 22.1472056 },
    { lat: 48.7803094, lng: 22.1469142 },
    { lat: 48.7803401, lng: 22.1463473 },
    { lat: 48.7801043, lng: 22.1462225 },
    { lat: 48.7800894, lng: 22.1453345 },
    { lat: 48.7797119, lng: 22.1452565 },
    { lat: 48.7794953, lng: 22.1448411 },
    { lat: 48.7791342, lng: 22.1448406 },
    { lat: 48.7790308, lng: 22.1446011 },
    { lat: 48.7785977, lng: 22.1439682 },
    { lat: 48.7784564, lng: 22.1438898 },
    { lat: 48.7782603, lng: 22.1439194 },
    { lat: 48.7780719, lng: 22.1437065 },
    { lat: 48.7778767, lng: 22.1436317 },
    { lat: 48.7777369, lng: 22.1432233 },
    { lat: 48.7773291, lng: 22.1427667 },
    { lat: 48.7770007, lng: 22.1428668 },
    { lat: 48.776735, lng: 22.1428036 },
    { lat: 48.7764344, lng: 22.1419233 },
    { lat: 48.7756907, lng: 22.1416686 },
    { lat: 48.7751591, lng: 22.1418374 },
    { lat: 48.7748213, lng: 22.1418183 },
    { lat: 48.7745912, lng: 22.1414981 },
    { lat: 48.7741099, lng: 22.1410241 },
    { lat: 48.7738855, lng: 22.1409009 },
    { lat: 48.773405, lng: 22.1408929 },
    { lat: 48.7730945, lng: 22.1406487 },
    { lat: 48.7727697, lng: 22.1406563 },
    { lat: 48.7725786, lng: 22.1405706 },
    { lat: 48.7722182, lng: 22.1405983 },
    { lat: 48.7718153, lng: 22.1403933 },
    { lat: 48.771554, lng: 22.1397974 },
    { lat: 48.771468, lng: 22.1391933 },
    { lat: 48.7713835, lng: 22.1390975 },
    { lat: 48.770979, lng: 22.1390645 },
    { lat: 48.7706798, lng: 22.1385313 },
    { lat: 48.7703949, lng: 22.1381795 },
    { lat: 48.7702067, lng: 22.1382177 },
    { lat: 48.7699359, lng: 22.1379329 },
    { lat: 48.7699595, lng: 22.1378857 },
    { lat: 48.7693974, lng: 22.1363477 },
    { lat: 48.7696714, lng: 22.1349731 },
    { lat: 48.7695546, lng: 22.1339652 },
    { lat: 48.7686923, lng: 22.1333141 },
    { lat: 48.7678963, lng: 22.1329418 },
    { lat: 48.7677436, lng: 22.1315579 },
    { lat: 48.7672434, lng: 22.1305544 },
    { lat: 48.7646, lng: 22.1291634 },
  ],
  VyšnéNemecké: [
    { lat: 48.6745565, lng: 22.272496 },
    { lat: 48.6744601, lng: 22.2719026 },
    { lat: 48.6740246, lng: 22.2710861 },
    { lat: 48.6737903, lng: 22.2709126 },
    { lat: 48.67371, lng: 22.2705856 },
    { lat: 48.673871, lng: 22.2697913 },
    { lat: 48.6737382, lng: 22.2691667 },
    { lat: 48.6738306, lng: 22.2685716 },
    { lat: 48.6729284, lng: 22.267046 },
    { lat: 48.6727245, lng: 22.2669787 },
    { lat: 48.6726484, lng: 22.2667918 },
    { lat: 48.6723145, lng: 22.2664422 },
    { lat: 48.672102, lng: 22.2664479 },
    { lat: 48.6717835, lng: 22.2662847 },
    { lat: 48.6713535, lng: 22.2659217 },
    { lat: 48.6706104, lng: 22.2654753 },
    { lat: 48.6704348, lng: 22.264965 },
    { lat: 48.6702543, lng: 22.2647446 },
    { lat: 48.6697662, lng: 22.2645466 },
    { lat: 48.6695727, lng: 22.264387 },
    { lat: 48.6692208, lng: 22.2643425 },
    { lat: 48.6692624, lng: 22.2637344 },
    { lat: 48.6690541, lng: 22.2634168 },
    { lat: 48.6677738, lng: 22.2622412 },
    { lat: 48.6667934, lng: 22.2616532 },
    { lat: 48.6655836, lng: 22.2612746 },
    { lat: 48.6647992, lng: 22.2611045 },
    { lat: 48.664724, lng: 22.2609147 },
    { lat: 48.6682835, lng: 22.2578769 },
    { lat: 48.6686019, lng: 22.2576052 },
    { lat: 48.6711563, lng: 22.2552303 },
    { lat: 48.6705822, lng: 22.2528428 },
    { lat: 48.6664855, lng: 22.2545442 },
    { lat: 48.6663561, lng: 22.2545977 },
    { lat: 48.6583545, lng: 22.2532463 },
    { lat: 48.6518342, lng: 22.2493771 },
    { lat: 48.6511909, lng: 22.2508826 },
    { lat: 48.6502508, lng: 22.2522089 },
    { lat: 48.6502046, lng: 22.2521578 },
    { lat: 48.65016, lng: 22.2522177 },
    { lat: 48.6500883, lng: 22.2519848 },
    { lat: 48.6502161, lng: 22.2516141 },
    { lat: 48.6502274, lng: 22.2514146 },
    { lat: 48.6500545, lng: 22.2512292 },
    { lat: 48.6498391, lng: 22.2511466 },
    { lat: 48.6498157, lng: 22.2510041 },
    { lat: 48.6499134, lng: 22.2507992 },
    { lat: 48.6499367, lng: 22.2505871 },
    { lat: 48.6498869, lng: 22.2505188 },
    { lat: 48.6497438, lng: 22.2504574 },
    { lat: 48.6497382, lng: 22.2505648 },
    { lat: 48.6496562, lng: 22.2506615 },
    { lat: 48.6495134, lng: 22.2507135 },
    { lat: 48.6492439, lng: 22.2509453 },
    { lat: 48.6490943, lng: 22.2508956 },
    { lat: 48.6492338, lng: 22.2503326 },
    { lat: 48.6491943, lng: 22.2500326 },
    { lat: 48.6494035, lng: 22.2497718 },
    { lat: 48.6494661, lng: 22.2494507 },
    { lat: 48.6493945, lng: 22.2493693 },
    { lat: 48.6491389, lng: 22.2495143 },
    { lat: 48.6490943, lng: 22.2493753 },
    { lat: 48.6490293, lng: 22.2493327 },
    { lat: 48.6489423, lng: 22.2493219 },
    { lat: 48.6489051, lng: 22.2494173 },
    { lat: 48.6487511, lng: 22.2493718 },
    { lat: 48.6487589, lng: 22.2491615 },
    { lat: 48.6486717, lng: 22.2487928 },
    { lat: 48.6484645, lng: 22.2488265 },
    { lat: 48.6484862, lng: 22.2484906 },
    { lat: 48.6486904, lng: 22.2482714 },
    { lat: 48.6486914, lng: 22.2481586 },
    { lat: 48.648451, lng: 22.2480218 },
    { lat: 48.6483278, lng: 22.2482981 },
    { lat: 48.6480581, lng: 22.2482642 },
    { lat: 48.647968, lng: 22.2483545 },
    { lat: 48.6479583, lng: 22.2485759 },
    { lat: 48.6478784, lng: 22.2487261 },
    { lat: 48.6477994, lng: 22.2487141 },
    { lat: 48.6476337, lng: 22.2485027 },
    { lat: 48.6476596, lng: 22.2481432 },
    { lat: 48.6475711, lng: 22.2479557 },
    { lat: 48.6472995, lng: 22.2477863 },
    { lat: 48.6471437, lng: 22.2479653 },
    { lat: 48.6470631, lng: 22.2479342 },
    { lat: 48.6470415, lng: 22.2478197 },
    { lat: 48.6471469, lng: 22.2475483 },
    { lat: 48.6471664, lng: 22.2470183 },
    { lat: 48.6470481, lng: 22.2470098 },
    { lat: 48.646542, lng: 22.2460609 },
    { lat: 48.6467359, lng: 22.2455182 },
    { lat: 48.6466356, lng: 22.2452085 },
    { lat: 48.64582, lng: 22.246658 },
    { lat: 48.6453409, lng: 22.2456619 },
    { lat: 48.6448153, lng: 22.2447436 },
    { lat: 48.6443589, lng: 22.2441519 },
    { lat: 48.6440673, lng: 22.24469 },
    { lat: 48.6429206, lng: 22.2462241 },
    { lat: 48.6428049, lng: 22.2461852 },
    { lat: 48.6419186, lng: 22.246793 },
    { lat: 48.6409888, lng: 22.2475659 },
    { lat: 48.640503, lng: 22.2470686 },
    { lat: 48.6387708, lng: 22.246215 },
    { lat: 48.6388503, lng: 22.2458376 },
    { lat: 48.6372406, lng: 22.2438252 },
    { lat: 48.6363202, lng: 22.2430183 },
    { lat: 48.635587, lng: 22.2424746 },
    { lat: 48.6349149, lng: 22.2420878 },
    { lat: 48.6339589, lng: 22.2418479 },
    { lat: 48.633563, lng: 22.2405918 },
    { lat: 48.6331238, lng: 22.2411213 },
    { lat: 48.632614, lng: 22.2418077 },
    { lat: 48.6325081, lng: 22.2426057 },
    { lat: 48.6326133, lng: 22.2433845 },
    { lat: 48.6329063, lng: 22.2439125 },
    { lat: 48.6341422, lng: 22.2450031 },
    { lat: 48.634239, lng: 22.245592 },
    { lat: 48.639469, lng: 22.249669 },
    { lat: 48.645234, lng: 22.249653 },
    { lat: 48.647399, lng: 22.250294 },
    { lat: 48.648337, lng: 22.255422 },
    { lat: 48.6515028, lng: 22.2616354 },
    { lat: 48.6523567, lng: 22.2634406 },
    { lat: 48.6527846, lng: 22.2643458 },
    { lat: 48.6532247, lng: 22.2652277 },
    { lat: 48.6548739, lng: 22.2649829 },
    { lat: 48.6549002, lng: 22.2651912 },
    { lat: 48.6549218, lng: 22.2653001 },
    { lat: 48.6549208, lng: 22.2654011 },
    { lat: 48.6550274, lng: 22.265542 },
    { lat: 48.6550294, lng: 22.2659748 },
    { lat: 48.6550302, lng: 22.2661413 },
    { lat: 48.655035, lng: 22.2671505 },
    { lat: 48.6550257, lng: 22.2677254 },
    { lat: 48.6550031, lng: 22.2688516 },
    { lat: 48.6565981, lng: 22.2721834 },
    { lat: 48.657686, lng: 22.274456 },
    { lat: 48.659591, lng: 22.278309 },
    { lat: 48.661601, lng: 22.2823126 },
    { lat: 48.664284, lng: 22.286316 },
    { lat: 48.6664617, lng: 22.2846338 },
    { lat: 48.6684877, lng: 22.2839382 },
    { lat: 48.6689325, lng: 22.2832422 },
    { lat: 48.6696318, lng: 22.281905 },
    { lat: 48.6745565, lng: 22.272496 },
  ],
  Beňatina: [
    { lat: 48.8035557, lng: 22.282074 },
    { lat: 48.803548, lng: 22.2820798 },
    { lat: 48.8027976, lng: 22.2825311 },
    { lat: 48.8023903, lng: 22.2829355 },
    { lat: 48.8018546, lng: 22.2829286 },
    { lat: 48.8012894, lng: 22.2827958 },
    { lat: 48.8009359, lng: 22.2832368 },
    { lat: 48.8002481, lng: 22.2831055 },
    { lat: 48.7980127, lng: 22.2830359 },
    { lat: 48.7976472, lng: 22.2830935 },
    { lat: 48.7972923, lng: 22.2826019 },
    { lat: 48.7967276, lng: 22.2825483 },
    { lat: 48.7963708, lng: 22.2828938 },
    { lat: 48.7954229, lng: 22.2835687 },
    { lat: 48.7954111, lng: 22.2836446 },
    { lat: 48.7951066, lng: 22.2828398 },
    { lat: 48.794864, lng: 22.2817882 },
    { lat: 48.7946929, lng: 22.2807473 },
    { lat: 48.7947284, lng: 22.2804241 },
    { lat: 48.7945892, lng: 22.28018 },
    { lat: 48.7943491, lng: 22.2790813 },
    { lat: 48.794064, lng: 22.278196 },
    { lat: 48.7940045, lng: 22.2779489 },
    { lat: 48.7940136, lng: 22.2776442 },
    { lat: 48.7939621, lng: 22.2773496 },
    { lat: 48.7935395, lng: 22.2763857 },
    { lat: 48.7934621, lng: 22.2766082 },
    { lat: 48.7933065, lng: 22.2772475 },
    { lat: 48.7930033, lng: 22.2783947 },
    { lat: 48.7929773, lng: 22.2787488 },
    { lat: 48.793219, lng: 22.2803262 },
    { lat: 48.7930034, lng: 22.2812581 },
    { lat: 48.792966, lng: 22.2818072 },
    { lat: 48.793068, lng: 22.2820967 },
    { lat: 48.793179, lng: 22.2830079 },
    { lat: 48.7930694, lng: 22.2838514 },
    { lat: 48.7932135, lng: 22.2852924 },
    { lat: 48.7932404, lng: 22.2867226 },
    { lat: 48.7931936, lng: 22.2877698 },
    { lat: 48.7929257, lng: 22.2882659 },
    { lat: 48.7928374, lng: 22.2886042 },
    { lat: 48.7929027, lng: 22.2893229 },
    { lat: 48.7927287, lng: 22.2904179 },
    { lat: 48.792798, lng: 22.2914713 },
    { lat: 48.7927832, lng: 22.2923346 },
    { lat: 48.7924639, lng: 22.2943914 },
    { lat: 48.7925666, lng: 22.2957464 },
    { lat: 48.7925047, lng: 22.2963103 },
    { lat: 48.7922567, lng: 22.2975687 },
    { lat: 48.7921987, lng: 22.2981595 },
    { lat: 48.7922957, lng: 22.3006456 },
    { lat: 48.7924226, lng: 22.3014636 },
    { lat: 48.7932004, lng: 22.3026721 },
    { lat: 48.7940895, lng: 22.3050634 },
    { lat: 48.7943009, lng: 22.3069271 },
    { lat: 48.7943091, lng: 22.3082302 },
    { lat: 48.7938285, lng: 22.3087835 },
    { lat: 48.7936641, lng: 22.3093967 },
    { lat: 48.7934812, lng: 22.3108131 },
    { lat: 48.7931719, lng: 22.3115908 },
    { lat: 48.7925735, lng: 22.3125114 },
    { lat: 48.7914541, lng: 22.315608 },
    { lat: 48.7907015, lng: 22.3163774 },
    { lat: 48.7902116, lng: 22.3167602 },
    { lat: 48.7900393, lng: 22.3168073 },
    { lat: 48.7893208, lng: 22.3176459 },
    { lat: 48.7893711, lng: 22.3185658 },
    { lat: 48.7893511, lng: 22.3196626 },
    { lat: 48.7894973, lng: 22.3204386 },
    { lat: 48.7892951, lng: 22.3209202 },
    { lat: 48.7893441, lng: 22.3215726 },
    { lat: 48.7901478, lng: 22.3231604 },
    { lat: 48.7904153, lng: 22.3232566 },
    { lat: 48.7911541, lng: 22.3241913 },
    { lat: 48.7917111, lng: 22.3245667 },
    { lat: 48.7923078, lng: 22.3256861 },
    { lat: 48.7920333, lng: 22.3260911 },
    { lat: 48.7923969, lng: 22.3263745 },
    { lat: 48.7925309, lng: 22.3266404 },
    { lat: 48.7923589, lng: 22.3278428 },
    { lat: 48.7923784, lng: 22.3283733 },
    { lat: 48.7926286, lng: 22.3291339 },
    { lat: 48.7930396, lng: 22.3300386 },
    { lat: 48.7927974, lng: 22.3301208 },
    { lat: 48.7929733, lng: 22.3308755 },
    { lat: 48.7929662, lng: 22.3319574 },
    { lat: 48.7926, lng: 22.332757 },
    { lat: 48.7932971, lng: 22.3332134 },
    { lat: 48.7940616, lng: 22.333354 },
    { lat: 48.794452, lng: 22.3326363 },
    { lat: 48.7946497, lng: 22.3325127 },
    { lat: 48.7950061, lng: 22.3324609 },
    { lat: 48.7951657, lng: 22.33287 },
    { lat: 48.7951986, lng: 22.333355 },
    { lat: 48.7947474, lng: 22.3352768 },
    { lat: 48.7938104, lng: 22.3351697 },
    { lat: 48.7936855, lng: 22.3355325 },
    { lat: 48.7932348, lng: 22.3359664 },
    { lat: 48.7927129, lng: 22.3367395 },
    { lat: 48.792219, lng: 22.3368976 },
    { lat: 48.7917725, lng: 22.3378877 },
    { lat: 48.7907455, lng: 22.3391882 },
    { lat: 48.7902015, lng: 22.3404117 },
    { lat: 48.7899277, lng: 22.3408539 },
    { lat: 48.7896006, lng: 22.3417015 },
    { lat: 48.7894756, lng: 22.3422805 },
    { lat: 48.7893788, lng: 22.34306 },
    { lat: 48.7891595, lng: 22.3435844 },
    { lat: 48.7890463, lng: 22.3443365 },
    { lat: 48.789052, lng: 22.3448559 },
    { lat: 48.7891802, lng: 22.3454937 },
    { lat: 48.7891978, lng: 22.3459362 },
    { lat: 48.7890672, lng: 22.3466204 },
    { lat: 48.7888781, lng: 22.3483942 },
    { lat: 48.7890753, lng: 22.3495687 },
    { lat: 48.7888353, lng: 22.3502842 },
    { lat: 48.788466, lng: 22.3509011 },
    { lat: 48.7881596, lng: 22.35124 },
    { lat: 48.7879389, lng: 22.351599 },
    { lat: 48.7875721, lng: 22.3527397 },
    { lat: 48.7868166, lng: 22.3536078 },
    { lat: 48.7861612, lng: 22.3547755 },
    { lat: 48.7859034, lng: 22.3553948 },
    { lat: 48.7852502, lng: 22.3561401 },
    { lat: 48.7847562, lng: 22.3568693 },
    { lat: 48.7843884, lng: 22.3576017 },
    { lat: 48.783112, lng: 22.3593054 },
    { lat: 48.7823129, lng: 22.3612756 },
    { lat: 48.7807106, lng: 22.3633077 },
    { lat: 48.7799688, lng: 22.3644858 },
    { lat: 48.7796272, lng: 22.3645021 },
    { lat: 48.77951, lng: 22.364356 },
    { lat: 48.780507, lng: 22.366333 },
    { lat: 48.78099, lng: 22.368589 },
    { lat: 48.781747, lng: 22.370904 },
    { lat: 48.7823445, lng: 22.3730274 },
    { lat: 48.782445, lng: 22.373369 },
    { lat: 48.7832357, lng: 22.3736498 },
    { lat: 48.784512, lng: 22.374103 },
    { lat: 48.785509, lng: 22.374599 },
    { lat: 48.786346, lng: 22.375592 },
    { lat: 48.786554, lng: 22.376103 },
    { lat: 48.788453, lng: 22.377188 },
    { lat: 48.789173, lng: 22.376059 },
    { lat: 48.790208, lng: 22.376176 },
    { lat: 48.790699, lng: 22.376826 },
    { lat: 48.79195, lng: 22.378077 },
    { lat: 48.793436, lng: 22.378789 },
    { lat: 48.793926, lng: 22.378903 },
    { lat: 48.794627, lng: 22.379056 },
    { lat: 48.795724, lng: 22.380396 },
    { lat: 48.796487, lng: 22.380839 },
    { lat: 48.798147, lng: 22.384855 },
    { lat: 48.798946, lng: 22.387019 },
    { lat: 48.80004, lng: 22.387648 },
    { lat: 48.801438, lng: 22.386813 },
    { lat: 48.802911, lng: 22.386515 },
    { lat: 48.804932, lng: 22.385837 },
    { lat: 48.805568, lng: 22.385401 },
    { lat: 48.8055263, lng: 22.3845384 },
    { lat: 48.8058412, lng: 22.3821402 },
    { lat: 48.8049958, lng: 22.3773687 },
    { lat: 48.8046004, lng: 22.3761659 },
    { lat: 48.8039732, lng: 22.3748081 },
    { lat: 48.8038268, lng: 22.3741077 },
    { lat: 48.803832, lng: 22.3737153 },
    { lat: 48.8040849, lng: 22.3722277 },
    { lat: 48.8059322, lng: 22.36923 },
    { lat: 48.8070717, lng: 22.36791 },
    { lat: 48.8075544, lng: 22.3677127 },
    { lat: 48.807842, lng: 22.3678033 },
    { lat: 48.8081709, lng: 22.3678219 },
    { lat: 48.8083419, lng: 22.367729 },
    { lat: 48.808748, lng: 22.3677866 },
    { lat: 48.8092378, lng: 22.366957 },
    { lat: 48.8093365, lng: 22.3669961 },
    { lat: 48.8099935, lng: 22.3669042 },
    { lat: 48.81104, lng: 22.364705 },
    { lat: 48.8118114, lng: 22.3622134 },
    { lat: 48.8123513, lng: 22.360947 },
    { lat: 48.8129978, lng: 22.3596689 },
    { lat: 48.8137655, lng: 22.3578571 },
    { lat: 48.8146028, lng: 22.3548324 },
    { lat: 48.8150253, lng: 22.3536444 },
    { lat: 48.8153612, lng: 22.3542122 },
    { lat: 48.8157547, lng: 22.3535669 },
    { lat: 48.8157621, lng: 22.3529221 },
    { lat: 48.8162854, lng: 22.3507216 },
    { lat: 48.8163296, lng: 22.3501117 },
    { lat: 48.8161509, lng: 22.3473765 },
    { lat: 48.8166227, lng: 22.3469372 },
    { lat: 48.816418, lng: 22.3463569 },
    { lat: 48.8164993, lng: 22.3461167 },
    { lat: 48.8165722, lng: 22.3460532 },
    { lat: 48.8167203, lng: 22.3450356 },
    { lat: 48.8167064, lng: 22.3448571 },
    { lat: 48.816589, lng: 22.3447372 },
    { lat: 48.8165729, lng: 22.3445596 },
    { lat: 48.8166592, lng: 22.343448 },
    { lat: 48.8166319, lng: 22.3419129 },
    { lat: 48.8168331, lng: 22.3405933 },
    { lat: 48.8170859, lng: 22.3398981 },
    { lat: 48.8172011, lng: 22.3390423 },
    { lat: 48.8172759, lng: 22.3380155 },
    { lat: 48.8176241, lng: 22.3367927 },
    { lat: 48.8183635, lng: 22.3329953 },
    { lat: 48.8186447, lng: 22.3319611 },
    { lat: 48.8188033, lng: 22.3310182 },
    { lat: 48.818846, lng: 22.3294575 },
    { lat: 48.8194723, lng: 22.3291888 },
    { lat: 48.819694, lng: 22.3280918 },
    { lat: 48.8194447, lng: 22.3248335 },
    { lat: 48.8194272, lng: 22.3239696 },
    { lat: 48.8194702, lng: 22.323516 },
    { lat: 48.8185761, lng: 22.3223747 },
    { lat: 48.8179634, lng: 22.3215097 },
    { lat: 48.8178874, lng: 22.320961 },
    { lat: 48.817808, lng: 22.3208182 },
    { lat: 48.8176354, lng: 22.3207408 },
    { lat: 48.8177699, lng: 22.3198426 },
    { lat: 48.8180269, lng: 22.3186756 },
    { lat: 48.8179688, lng: 22.3181264 },
    { lat: 48.8168747, lng: 22.3167839 },
    { lat: 48.8164327, lng: 22.3149655 },
    { lat: 48.8164556, lng: 22.3147309 },
    { lat: 48.8163727, lng: 22.3144282 },
    { lat: 48.8162436, lng: 22.3144977 },
    { lat: 48.8157793, lng: 22.3126419 },
    { lat: 48.8160119, lng: 22.3117503 },
    { lat: 48.8151096, lng: 22.3099784 },
    { lat: 48.814844, lng: 22.3081566 },
    { lat: 48.8144269, lng: 22.3083772 },
    { lat: 48.8139199, lng: 22.3081253 },
    { lat: 48.8136393, lng: 22.3074805 },
    { lat: 48.8132448, lng: 22.3070816 },
    { lat: 48.8129309, lng: 22.3051684 },
    { lat: 48.8112374, lng: 22.3034342 },
    { lat: 48.8114104, lng: 22.3030558 },
    { lat: 48.8108196, lng: 22.3025291 },
    { lat: 48.8094955, lng: 22.3015886 },
    { lat: 48.8089794, lng: 22.3013322 },
    { lat: 48.8076808, lng: 22.299552 },
    { lat: 48.8069984, lng: 22.2988483 },
    { lat: 48.8064941, lng: 22.2977928 },
    { lat: 48.8061065, lng: 22.2961195 },
    { lat: 48.8056882, lng: 22.2954625 },
    { lat: 48.8051715, lng: 22.2951773 },
    { lat: 48.805315, lng: 22.2946488 },
    { lat: 48.8056528, lng: 22.2925095 },
    { lat: 48.8058511, lng: 22.2915448 },
    { lat: 48.8062188, lng: 22.2903645 },
    { lat: 48.8062536, lng: 22.2892338 },
    { lat: 48.8058033, lng: 22.2883455 },
    { lat: 48.805672, lng: 22.287895 },
    { lat: 48.8059198, lng: 22.286674 },
    { lat: 48.8051426, lng: 22.2856024 },
    { lat: 48.8049332, lng: 22.2849789 },
    { lat: 48.8048186, lng: 22.2848341 },
    { lat: 48.8038207, lng: 22.282775 },
    { lat: 48.8035557, lng: 22.282074 },
  ],
  Krčava: [
    { lat: 48.6705822, lng: 22.2528428 },
    { lat: 48.6711563, lng: 22.2552303 },
    { lat: 48.6686019, lng: 22.2576052 },
    { lat: 48.6682835, lng: 22.2578769 },
    { lat: 48.664724, lng: 22.2609147 },
    { lat: 48.6647992, lng: 22.2611045 },
    { lat: 48.6655836, lng: 22.2612746 },
    { lat: 48.6667934, lng: 22.2616532 },
    { lat: 48.6677738, lng: 22.2622412 },
    { lat: 48.6690541, lng: 22.2634168 },
    { lat: 48.6692624, lng: 22.2637344 },
    { lat: 48.6692208, lng: 22.2643425 },
    { lat: 48.6695727, lng: 22.264387 },
    { lat: 48.6697662, lng: 22.2645466 },
    { lat: 48.6702543, lng: 22.2647446 },
    { lat: 48.6704348, lng: 22.264965 },
    { lat: 48.6706104, lng: 22.2654753 },
    { lat: 48.6713535, lng: 22.2659217 },
    { lat: 48.6717835, lng: 22.2662847 },
    { lat: 48.672102, lng: 22.2664479 },
    { lat: 48.6723145, lng: 22.2664422 },
    { lat: 48.6726484, lng: 22.2667918 },
    { lat: 48.6727245, lng: 22.2669787 },
    { lat: 48.6729284, lng: 22.267046 },
    { lat: 48.6738306, lng: 22.2685716 },
    { lat: 48.6737382, lng: 22.2691667 },
    { lat: 48.673871, lng: 22.2697913 },
    { lat: 48.67371, lng: 22.2705856 },
    { lat: 48.6737903, lng: 22.2709126 },
    { lat: 48.6740246, lng: 22.2710861 },
    { lat: 48.6744601, lng: 22.2719026 },
    { lat: 48.6745565, lng: 22.272496 },
    { lat: 48.6748788, lng: 22.2729801 },
    { lat: 48.6750451, lng: 22.2729778 },
    { lat: 48.6751197, lng: 22.2730979 },
    { lat: 48.6751321, lng: 22.2732425 },
    { lat: 48.6752433, lng: 22.2733917 },
    { lat: 48.6753967, lng: 22.2734647 },
    { lat: 48.6754492, lng: 22.2736009 },
    { lat: 48.6754345, lng: 22.2741052 },
    { lat: 48.6756067, lng: 22.2742072 },
    { lat: 48.6756794, lng: 22.2745032 },
    { lat: 48.676082, lng: 22.2749509 },
    { lat: 48.6761813, lng: 22.2751673 },
    { lat: 48.6762116, lng: 22.2758772 },
    { lat: 48.676285, lng: 22.2759436 },
    { lat: 48.6764434, lng: 22.2757855 },
    { lat: 48.6769662, lng: 22.2764902 },
    { lat: 48.6770542, lng: 22.276496 },
    { lat: 48.6770473, lng: 22.2763832 },
    { lat: 48.6771443, lng: 22.2761965 },
    { lat: 48.6772039, lng: 22.2763472 },
    { lat: 48.6773106, lng: 22.2763956 },
    { lat: 48.6775666, lng: 22.2763236 },
    { lat: 48.6778541, lng: 22.2767052 },
    { lat: 48.6778599, lng: 22.2772776 },
    { lat: 48.6781752, lng: 22.2774468 },
    { lat: 48.6783218, lng: 22.2776167 },
    { lat: 48.6785133, lng: 22.2776302 },
    { lat: 48.6791423, lng: 22.2782459 },
    { lat: 48.6791612, lng: 22.2783959 },
    { lat: 48.6793234, lng: 22.2783041 },
    { lat: 48.6799172, lng: 22.279064 },
    { lat: 48.6801745, lng: 22.2789973 },
    { lat: 48.6803745, lng: 22.27879 },
    { lat: 48.6806619, lng: 22.278101 },
    { lat: 48.6811864, lng: 22.277303 },
    { lat: 48.6815445, lng: 22.2764499 },
    { lat: 48.6818457, lng: 22.276087 },
    { lat: 48.6822952, lng: 22.2757582 },
    { lat: 48.683965, lng: 22.2738035 },
    { lat: 48.6841267, lng: 22.2733358 },
    { lat: 48.6847236, lng: 22.2723036 },
    { lat: 48.6849879, lng: 22.2720012 },
    { lat: 48.6855703, lng: 22.2715325 },
    { lat: 48.6857814, lng: 22.2712613 },
    { lat: 48.6864635, lng: 22.2699624 },
    { lat: 48.6867581, lng: 22.2691402 },
    { lat: 48.6873304, lng: 22.2685246 },
    { lat: 48.6875339, lng: 22.268169 },
    { lat: 48.6883575, lng: 22.2671627 },
    { lat: 48.6890926, lng: 22.2666823 },
    { lat: 48.6893926, lng: 22.2663068 },
    { lat: 48.6893745, lng: 22.2660546 },
    { lat: 48.6896685, lng: 22.265772 },
    { lat: 48.6897467, lng: 22.2654922 },
    { lat: 48.6901552, lng: 22.2651967 },
    { lat: 48.6904578, lng: 22.2648965 },
    { lat: 48.6914955, lng: 22.2637564 },
    { lat: 48.6922257, lng: 22.2634054 },
    { lat: 48.6926903, lng: 22.2633142 },
    { lat: 48.693193, lng: 22.2628533 },
    { lat: 48.6937771, lng: 22.2619027 },
    { lat: 48.694155, lng: 22.2615538 },
    { lat: 48.694416, lng: 22.2614523 },
    { lat: 48.6953888, lng: 22.2603861 },
    { lat: 48.6959696, lng: 22.2599939 },
    { lat: 48.697974, lng: 22.2590409 },
    { lat: 48.6982896, lng: 22.2581511 },
    { lat: 48.698785, lng: 22.2572144 },
    { lat: 48.6997863, lng: 22.2564144 },
    { lat: 48.6999674, lng: 22.2562709 },
    { lat: 48.699996, lng: 22.2562406 },
    { lat: 48.6991957, lng: 22.2553993 },
    { lat: 48.6992116, lng: 22.2553618 },
    { lat: 48.6984788, lng: 22.2549276 },
    { lat: 48.6980707, lng: 22.2544908 },
    { lat: 48.6975245, lng: 22.2529427 },
    { lat: 48.6971904, lng: 22.2525324 },
    { lat: 48.6968947, lng: 22.2519885 },
    { lat: 48.6964009, lng: 22.2501714 },
    { lat: 48.6960207, lng: 22.2496104 },
    { lat: 48.6957654, lng: 22.2480554 },
    { lat: 48.6957627, lng: 22.2473747 },
    { lat: 48.6959018, lng: 22.2466591 },
    { lat: 48.6959032, lng: 22.2464689 },
    { lat: 48.6956378, lng: 22.2460171 },
    { lat: 48.6954743, lng: 22.2449463 },
    { lat: 48.6955625, lng: 22.2445583 },
    { lat: 48.6954352, lng: 22.2441568 },
    { lat: 48.6955615, lng: 22.2421522 },
    { lat: 48.6967862, lng: 22.2414349 },
    { lat: 48.6982504, lng: 22.2414786 },
    { lat: 48.6987328, lng: 22.2414248 },
    { lat: 48.6994958, lng: 22.2406341 },
    { lat: 48.6999539, lng: 22.2415981 },
    { lat: 48.7000664, lng: 22.2415083 },
    { lat: 48.7002489, lng: 22.24187 },
    { lat: 48.7024895, lng: 22.2401634 },
    { lat: 48.7026947, lng: 22.2403285 },
    { lat: 48.7031035, lng: 22.2404055 },
    { lat: 48.703102, lng: 22.2402881 },
    { lat: 48.7030422, lng: 22.2390263 },
    { lat: 48.702941, lng: 22.2386248 },
    { lat: 48.7028408, lng: 22.238423 },
    { lat: 48.7027816, lng: 22.2381376 },
    { lat: 48.7026838, lng: 22.2379913 },
    { lat: 48.7026476, lng: 22.2380277 },
    { lat: 48.7025267, lng: 22.2379 },
    { lat: 48.7023913, lng: 22.2374871 },
    { lat: 48.7021364, lng: 22.2373025 },
    { lat: 48.7018452, lng: 22.2364065 },
    { lat: 48.7018783, lng: 22.2361235 },
    { lat: 48.7018742, lng: 22.2357943 },
    { lat: 48.7018166, lng: 22.2356848 },
    { lat: 48.7018352, lng: 22.2355065 },
    { lat: 48.7015709, lng: 22.2351579 },
    { lat: 48.701367, lng: 22.2331414 },
    { lat: 48.7012795, lng: 22.2330028 },
    { lat: 48.7010981, lng: 22.2329854 },
    { lat: 48.701011, lng: 22.2328624 },
    { lat: 48.7009143, lng: 22.232914 },
    { lat: 48.700562, lng: 22.2326532 },
    { lat: 48.7004482, lng: 22.2324461 },
    { lat: 48.7000426, lng: 22.2319631 },
    { lat: 48.699828, lng: 22.231373 },
    { lat: 48.6995979, lng: 22.2311491 },
    { lat: 48.6996112, lng: 22.2309857 },
    { lat: 48.699509, lng: 22.2308973 },
    { lat: 48.6994589, lng: 22.2302739 },
    { lat: 48.6994895, lng: 22.2301327 },
    { lat: 48.699425, lng: 22.2298113 },
    { lat: 48.6987524, lng: 22.2293121 },
    { lat: 48.698632, lng: 22.2291064 },
    { lat: 48.6985387, lng: 22.2280611 },
    { lat: 48.6982927, lng: 22.2275799 },
    { lat: 48.6977354, lng: 22.2257014 },
    { lat: 48.6974928, lng: 22.2257221 },
    { lat: 48.6884526, lng: 22.2332199 },
    { lat: 48.688141, lng: 22.2336192 },
    { lat: 48.6870239, lng: 22.2345091 },
    { lat: 48.685681, lng: 22.2350677 },
    { lat: 48.6849577, lng: 22.2309584 },
    { lat: 48.6841753, lng: 22.2306326 },
    { lat: 48.6827123, lng: 22.2302025 },
    { lat: 48.6823069, lng: 22.2304209 },
    { lat: 48.6813412, lng: 22.2307043 },
    { lat: 48.6812922, lng: 22.2304758 },
    { lat: 48.681006, lng: 22.2306514 },
    { lat: 48.6806506, lng: 22.2309992 },
    { lat: 48.6798317, lng: 22.2322019 },
    { lat: 48.6795594, lng: 22.2321991 },
    { lat: 48.6788452, lng: 22.2329505 },
    { lat: 48.6785597, lng: 22.2334613 },
    { lat: 48.6764105, lng: 22.2358766 },
    { lat: 48.6742445, lng: 22.2391592 },
    { lat: 48.673894, lng: 22.2395758 },
    { lat: 48.6738354, lng: 22.2389627 },
    { lat: 48.6731984, lng: 22.2388508 },
    { lat: 48.6727586, lng: 22.2387441 },
    { lat: 48.6709536, lng: 22.2386613 },
    { lat: 48.6694214, lng: 22.2384574 },
    { lat: 48.6690221, lng: 22.2385518 },
    { lat: 48.6690674, lng: 22.2401203 },
    { lat: 48.6692149, lng: 22.2408312 },
    { lat: 48.6685088, lng: 22.2434568 },
    { lat: 48.6684003, lng: 22.2435873 },
    { lat: 48.6686616, lng: 22.2442494 },
    { lat: 48.6700347, lng: 22.2501824 },
    { lat: 48.6705822, lng: 22.2528428 },
  ],
  Lekárovce: [
    { lat: 48.6137397, lng: 22.1653148 },
    { lat: 48.6135128, lng: 22.1628924 },
    { lat: 48.6137314, lng: 22.1618862 },
    { lat: 48.6134932, lng: 22.1599045 },
    { lat: 48.6136317, lng: 22.159188 },
    { lat: 48.6138988, lng: 22.159158 },
    { lat: 48.6139612, lng: 22.1586975 },
    { lat: 48.6141803, lng: 22.1587336 },
    { lat: 48.6143274, lng: 22.1568699 },
    { lat: 48.6141745, lng: 22.154697 },
    { lat: 48.6138463, lng: 22.1525683 },
    { lat: 48.6139103, lng: 22.1497519 },
    { lat: 48.6142686, lng: 22.1494376 },
    { lat: 48.614566, lng: 22.1494556 },
    { lat: 48.6147661, lng: 22.1492605 },
    { lat: 48.6149146, lng: 22.148824 },
    { lat: 48.6152598, lng: 22.1484339 },
    { lat: 48.615283, lng: 22.1481251 },
    { lat: 48.6156199, lng: 22.1481489 },
    { lat: 48.6161858, lng: 22.1480525 },
    { lat: 48.6172283, lng: 22.1477585 },
    { lat: 48.6174905, lng: 22.1476174 },
    { lat: 48.6176757, lng: 22.1449384 },
    { lat: 48.6181106, lng: 22.1447664 },
    { lat: 48.6183639, lng: 22.1445891 },
    { lat: 48.6186862, lng: 22.1442308 },
    { lat: 48.6190546, lng: 22.1432862 },
    { lat: 48.6195602, lng: 22.1422426 },
    { lat: 48.620222, lng: 22.1403783 },
    { lat: 48.6202809, lng: 22.1403958 },
    { lat: 48.6202913, lng: 22.1403053 },
    { lat: 48.6203574, lng: 22.1403399 },
    { lat: 48.6204171, lng: 22.1402538 },
    { lat: 48.6210769, lng: 22.1387896 },
    { lat: 48.6212615, lng: 22.1385999 },
    { lat: 48.621497, lng: 22.1384662 },
    { lat: 48.6216623, lng: 22.1382281 },
    { lat: 48.6217567, lng: 22.1374021 },
    { lat: 48.62229, lng: 22.1375007 },
    { lat: 48.622438, lng: 22.1370946 },
    { lat: 48.6227518, lng: 22.1366606 },
    { lat: 48.622864, lng: 22.1357337 },
    { lat: 48.6228356, lng: 22.1356254 },
    { lat: 48.6229511, lng: 22.1348381 },
    { lat: 48.6230013, lng: 22.1346765 },
    { lat: 48.6233291, lng: 22.1341165 },
    { lat: 48.6233779, lng: 22.1335521 },
    { lat: 48.6237719, lng: 22.1328026 },
    { lat: 48.6240914, lng: 22.1327031 },
    { lat: 48.6244666, lng: 22.1308982 },
    { lat: 48.6249442, lng: 22.1301621 },
    { lat: 48.6253335, lng: 22.1301691 },
    { lat: 48.6252163, lng: 22.1288579 },
    { lat: 48.6266803, lng: 22.1287821 },
    { lat: 48.6274673, lng: 22.1288639 },
    { lat: 48.627858, lng: 22.128751 },
    { lat: 48.62786, lng: 22.128628 },
    { lat: 48.627865, lng: 22.128298 },
    { lat: 48.626418, lng: 22.128061 },
    { lat: 48.62636, lng: 22.127515 },
    { lat: 48.626202, lng: 22.126668 },
    { lat: 48.626147, lng: 22.126447 },
    { lat: 48.62602, lng: 22.126229 },
    { lat: 48.625868, lng: 22.12604 },
    { lat: 48.625537, lng: 22.125756 },
    { lat: 48.625318, lng: 22.125661 },
    { lat: 48.625234, lng: 22.125573 },
    { lat: 48.623189, lng: 22.125439 },
    { lat: 48.622657, lng: 22.12539 },
    { lat: 48.621847, lng: 22.125286 },
    { lat: 48.620655, lng: 22.12533 },
    { lat: 48.618335, lng: 22.12521 },
    { lat: 48.61826, lng: 22.125206 },
    { lat: 48.618138, lng: 22.1252 },
    { lat: 48.61791, lng: 22.125188 },
    { lat: 48.617721, lng: 22.12518 },
    { lat: 48.617553, lng: 22.125172 },
    { lat: 48.617257, lng: 22.125159 },
    { lat: 48.615295, lng: 22.125071 },
    { lat: 48.614484, lng: 22.125032 },
    { lat: 48.61186, lng: 22.125009 },
    { lat: 48.610221, lng: 22.125002 },
    { lat: 48.610182, lng: 22.125007 },
    { lat: 48.609752, lng: 22.125062 },
    { lat: 48.608842, lng: 22.125231 },
    { lat: 48.608814, lng: 22.125228 },
    { lat: 48.607509, lng: 22.12539 },
    { lat: 48.607322, lng: 22.125382 },
    { lat: 48.607176, lng: 22.125421 },
    { lat: 48.607021, lng: 22.125266 },
    { lat: 48.606757, lng: 22.124962 },
    { lat: 48.60661, lng: 22.124794 },
    { lat: 48.606561, lng: 22.124738 },
    { lat: 48.606508, lng: 22.124701 },
    { lat: 48.60627, lng: 22.125155 },
    { lat: 48.606236, lng: 22.125588 },
    { lat: 48.606247, lng: 22.126915 },
    { lat: 48.606298, lng: 22.127347 },
    { lat: 48.606427, lng: 22.127969 },
    { lat: 48.606378, lng: 22.127977 },
    { lat: 48.60632, lng: 22.12798 },
    { lat: 48.606201, lng: 22.127994 },
    { lat: 48.606077, lng: 22.128006 },
    { lat: 48.605985, lng: 22.12807 },
    { lat: 48.605763, lng: 22.128221 },
    { lat: 48.605538, lng: 22.128212 },
    { lat: 48.605176, lng: 22.128287 },
    { lat: 48.605107, lng: 22.128317 },
    { lat: 48.605035, lng: 22.128348 },
    { lat: 48.604984, lng: 22.12837 },
    { lat: 48.60463, lng: 22.128524 },
    { lat: 48.604578, lng: 22.128551 },
    { lat: 48.604444, lng: 22.12863 },
    { lat: 48.60424, lng: 22.128733 },
    { lat: 48.603901, lng: 22.129027 },
    { lat: 48.603643, lng: 22.129134 },
    { lat: 48.60285, lng: 22.129214 },
    { lat: 48.602572, lng: 22.129176 },
    { lat: 48.602448, lng: 22.129159 },
    { lat: 48.602164, lng: 22.129118 },
    { lat: 48.601854, lng: 22.129081 },
    { lat: 48.601293, lng: 22.129112 },
    { lat: 48.600329, lng: 22.129222 },
    { lat: 48.600034, lng: 22.129355 },
    { lat: 48.599672, lng: 22.129587 },
    { lat: 48.599381, lng: 22.129739 },
    { lat: 48.59913, lng: 22.129863 },
    { lat: 48.599024, lng: 22.129915 },
    { lat: 48.598862, lng: 22.129932 },
    { lat: 48.598642, lng: 22.129901 },
    { lat: 48.598454, lng: 22.129827 },
    { lat: 48.598277, lng: 22.129761 },
    { lat: 48.598064, lng: 22.129666 },
    { lat: 48.5979, lng: 22.129577 },
    { lat: 48.59781, lng: 22.129556 },
    { lat: 48.59777, lng: 22.129547 },
    { lat: 48.597707, lng: 22.12954 },
    { lat: 48.595977, lng: 22.129756 },
    { lat: 48.595387, lng: 22.129985 },
    { lat: 48.594688, lng: 22.130318 },
    { lat: 48.594241, lng: 22.13059 },
    { lat: 48.594138, lng: 22.130641 },
    { lat: 48.593936, lng: 22.130767 },
    { lat: 48.593712, lng: 22.130887 },
    { lat: 48.593886, lng: 22.131355 },
    { lat: 48.594407, lng: 22.132789 },
    { lat: 48.594574, lng: 22.133148 },
    { lat: 48.595037, lng: 22.13393 },
    { lat: 48.595351, lng: 22.134411 },
    { lat: 48.595567, lng: 22.134772 },
    { lat: 48.595674, lng: 22.134983 },
    { lat: 48.595769, lng: 22.135195 },
    { lat: 48.595855, lng: 22.135421 },
    { lat: 48.595943, lng: 22.135714 },
    { lat: 48.596048, lng: 22.136076 },
    { lat: 48.596282, lng: 22.136165 },
    { lat: 48.596744, lng: 22.136519 },
    { lat: 48.596694, lng: 22.136647 },
    { lat: 48.596641, lng: 22.136784 },
    { lat: 48.596511, lng: 22.137115 },
    { lat: 48.596181, lng: 22.138313 },
    { lat: 48.596026, lng: 22.138804 },
    { lat: 48.595946, lng: 22.139058 },
    { lat: 48.595786, lng: 22.139481 },
    { lat: 48.595712, lng: 22.139674 },
    { lat: 48.595447, lng: 22.140377 },
    { lat: 48.595154, lng: 22.141056 },
    { lat: 48.595016, lng: 22.141371 },
    { lat: 48.594963, lng: 22.141494 },
    { lat: 48.594952, lng: 22.14152 },
    { lat: 48.594913, lng: 22.14161 },
    { lat: 48.594907, lng: 22.141625 },
    { lat: 48.594871, lng: 22.141745 },
    { lat: 48.594873, lng: 22.141861 },
    { lat: 48.594922, lng: 22.142095 },
    { lat: 48.594976, lng: 22.142252 },
    { lat: 48.595014, lng: 22.142359 },
    { lat: 48.595042, lng: 22.142423 },
    { lat: 48.59506, lng: 22.142464 },
    { lat: 48.595143, lng: 22.142655 },
    { lat: 48.595236, lng: 22.142871 },
    { lat: 48.595433, lng: 22.143387 },
    { lat: 48.595472, lng: 22.143617 },
    { lat: 48.595491, lng: 22.143857 },
    { lat: 48.595492, lng: 22.144218 },
    { lat: 48.595446, lng: 22.144486 },
    { lat: 48.595388, lng: 22.144762 },
    { lat: 48.595359, lng: 22.14485 },
    { lat: 48.595311, lng: 22.144987 },
    { lat: 48.5953, lng: 22.145018 },
    { lat: 48.595288, lng: 22.145053 },
    { lat: 48.595248, lng: 22.145162 },
    { lat: 48.59513, lng: 22.145487 },
    { lat: 48.594852, lng: 22.14625 },
    { lat: 48.594686, lng: 22.14665 },
    { lat: 48.594624, lng: 22.146773 },
    { lat: 48.594554, lng: 22.146811 },
    { lat: 48.594477, lng: 22.146801 },
    { lat: 48.5938, lng: 22.146455 },
    { lat: 48.593618, lng: 22.146405 },
    { lat: 48.59358, lng: 22.146409 },
    { lat: 48.593419, lng: 22.146427 },
    { lat: 48.592504, lng: 22.14608 },
    { lat: 48.592343, lng: 22.145988 },
    { lat: 48.592261, lng: 22.145998 },
    { lat: 48.592185, lng: 22.146052 },
    { lat: 48.592093, lng: 22.146124 },
    { lat: 48.592028, lng: 22.146223 },
    { lat: 48.591966, lng: 22.146286 },
    { lat: 48.591902, lng: 22.146262 },
    { lat: 48.591876, lng: 22.146252 },
    { lat: 48.591819, lng: 22.146256 },
    { lat: 48.591734, lng: 22.146263 },
    { lat: 48.5909, lng: 22.147107 },
    { lat: 48.590759, lng: 22.147251 },
    { lat: 48.590644, lng: 22.147366 },
    { lat: 48.590452, lng: 22.147559 },
    { lat: 48.590008, lng: 22.148004 },
    { lat: 48.589795, lng: 22.148217 },
    { lat: 48.5896, lng: 22.148415 },
    { lat: 48.589351, lng: 22.148667 },
    { lat: 48.589095, lng: 22.148927 },
    { lat: 48.58893, lng: 22.149096 },
    { lat: 48.588844, lng: 22.149183 },
    { lat: 48.588578, lng: 22.149455 },
    { lat: 48.588352, lng: 22.149687 },
    { lat: 48.588287, lng: 22.149753 },
    { lat: 48.587936, lng: 22.150103 },
    { lat: 48.587856, lng: 22.150183 },
    { lat: 48.587781, lng: 22.150258 },
    { lat: 48.587537, lng: 22.150501 },
    { lat: 48.587428, lng: 22.150582 },
    { lat: 48.587342, lng: 22.150606 },
    { lat: 48.587254, lng: 22.150603 },
    { lat: 48.586914, lng: 22.150536 },
    { lat: 48.585775, lng: 22.15028 },
    { lat: 48.585145, lng: 22.150163 },
    { lat: 48.5844, lng: 22.150006 },
    { lat: 48.583485, lng: 22.149875 },
    { lat: 48.583453, lng: 22.149869 },
    { lat: 48.583437, lng: 22.149866 },
    { lat: 48.583367, lng: 22.149854 },
    { lat: 48.583353, lng: 22.149852 },
    { lat: 48.583263, lng: 22.149836 },
    { lat: 48.581632, lng: 22.150437 },
    { lat: 48.5809, lng: 22.150702 },
    { lat: 48.579435, lng: 22.151092 },
    { lat: 48.579318, lng: 22.151123 },
    { lat: 48.579227, lng: 22.151148 },
    { lat: 48.578786, lng: 22.151279 },
    { lat: 48.578211, lng: 22.151449 },
    { lat: 48.578125, lng: 22.151452 },
    { lat: 48.577647, lng: 22.151651 },
    { lat: 48.576694, lng: 22.152361 },
    { lat: 48.575701, lng: 22.152931 },
    { lat: 48.573958, lng: 22.15344 },
    { lat: 48.573906, lng: 22.153466 },
    { lat: 48.575068, lng: 22.158005 },
    { lat: 48.575096, lng: 22.158146 },
    { lat: 48.575144, lng: 22.158334 },
    { lat: 48.57713, lng: 22.166018 },
    { lat: 48.587537, lng: 22.171379 },
    { lat: 48.5912928, lng: 22.174516 },
    { lat: 48.5949292, lng: 22.1775534 },
    { lat: 48.5949472, lng: 22.1775685 },
    { lat: 48.602387, lng: 22.183819 },
    { lat: 48.6026615, lng: 22.1835209 },
    { lat: 48.6035011, lng: 22.1831491 },
    { lat: 48.6040329, lng: 22.1828434 },
    { lat: 48.6045162, lng: 22.1821866 },
    { lat: 48.6052128, lng: 22.1800441 },
    { lat: 48.6062664, lng: 22.1772835 },
    { lat: 48.6063882, lng: 22.1766658 },
    { lat: 48.6063902, lng: 22.1760675 },
    { lat: 48.6063148, lng: 22.1755774 },
    { lat: 48.6058178, lng: 22.1744135 },
    { lat: 48.6050395, lng: 22.1731956 },
    { lat: 48.6073102, lng: 22.1710725 },
    { lat: 48.6065989, lng: 22.1685726 },
    { lat: 48.6073568, lng: 22.167972 },
    { lat: 48.6079503, lng: 22.1676135 },
    { lat: 48.6092189, lng: 22.1664285 },
    { lat: 48.6092715, lng: 22.1663794 },
    { lat: 48.609964, lng: 22.1659154 },
    { lat: 48.6099975, lng: 22.1659259 },
    { lat: 48.6117464, lng: 22.1664745 },
    { lat: 48.6127021, lng: 22.1660738 },
    { lat: 48.6130062, lng: 22.1656572 },
    { lat: 48.6132104, lng: 22.1655267 },
    { lat: 48.6137397, lng: 22.1653148 },
  ],
  NižnáRybnica: [
    { lat: 48.736795, lng: 22.1219279 },
    { lat: 48.7361496, lng: 22.1218459 },
    { lat: 48.7359794, lng: 22.121928 },
    { lat: 48.7275758, lng: 22.1303035 },
    { lat: 48.7273593, lng: 22.1303289 },
    { lat: 48.7273079, lng: 22.1304898 },
    { lat: 48.7277995, lng: 22.1309731 },
    { lat: 48.7282813, lng: 22.1315692 },
    { lat: 48.7288094, lng: 22.1324107 },
    { lat: 48.7298381, lng: 22.1350435 },
    { lat: 48.7310089, lng: 22.138416 },
    { lat: 48.7311035, lng: 22.1385222 },
    { lat: 48.7322178, lng: 22.1418911 },
    { lat: 48.7345982, lng: 22.148158 },
    { lat: 48.7380822, lng: 22.153806 },
    { lat: 48.7385247, lng: 22.1546938 },
    { lat: 48.7395458, lng: 22.1568258 },
    { lat: 48.7396737, lng: 22.1567235 },
    { lat: 48.7399666, lng: 22.1562993 },
    { lat: 48.7403349, lng: 22.1558875 },
    { lat: 48.7405567, lng: 22.1560775 },
    { lat: 48.7415077, lng: 22.15674 },
    { lat: 48.7418718, lng: 22.1573078 },
    { lat: 48.7421224, lng: 22.1579852 },
    { lat: 48.7455555, lng: 22.1584266 },
    { lat: 48.7474222, lng: 22.1588406 },
    { lat: 48.7482682, lng: 22.1595769 },
    { lat: 48.7483483, lng: 22.1598321 },
    { lat: 48.7488791, lng: 22.1601695 },
    { lat: 48.748993, lng: 22.1603331 },
    { lat: 48.7488984, lng: 22.1609555 },
    { lat: 48.748983, lng: 22.1613128 },
    { lat: 48.7491527, lng: 22.1613017 },
    { lat: 48.74949, lng: 22.1618845 },
    { lat: 48.7499143, lng: 22.1620892 },
    { lat: 48.7501136, lng: 22.1622498 },
    { lat: 48.7503561, lng: 22.1621739 },
    { lat: 48.75081, lng: 22.1619069 },
    { lat: 48.7509969, lng: 22.1619145 },
    { lat: 48.7511891, lng: 22.1620116 },
    { lat: 48.7512988, lng: 22.1620992 },
    { lat: 48.7518373, lng: 22.1625553 },
    { lat: 48.7519122, lng: 22.162541 },
    { lat: 48.7520629, lng: 22.1622892 },
    { lat: 48.7522339, lng: 22.162344 },
    { lat: 48.7523001, lng: 22.1625277 },
    { lat: 48.7526711, lng: 22.1630592 },
    { lat: 48.7528301, lng: 22.1636201 },
    { lat: 48.7532242, lng: 22.1641693 },
    { lat: 48.7538336, lng: 22.1640627 },
    { lat: 48.7541774, lng: 22.1638051 },
    { lat: 48.7544059, lng: 22.1638575 },
    { lat: 48.7547558, lng: 22.1637635 },
    { lat: 48.75506, lng: 22.1638288 },
    { lat: 48.7553633, lng: 22.1642049 },
    { lat: 48.7557518, lng: 22.1644233 },
    { lat: 48.755806, lng: 22.1645253 },
    { lat: 48.755779, lng: 22.1650086 },
    { lat: 48.7559973, lng: 22.1658534 },
    { lat: 48.7563372, lng: 22.1660587 },
    { lat: 48.7568086, lng: 22.1661094 },
    { lat: 48.7569481, lng: 22.166209 },
    { lat: 48.7577658, lng: 22.1657596 },
    { lat: 48.7581607, lng: 22.165722 },
    { lat: 48.7588411, lng: 22.1660056 },
    { lat: 48.7590751, lng: 22.166199 },
    { lat: 48.7596841, lng: 22.1671502 },
    { lat: 48.760569, lng: 22.1677746 },
    { lat: 48.7610978, lng: 22.1683133 },
    { lat: 48.7612939, lng: 22.1684384 },
    { lat: 48.7614309, lng: 22.1686655 },
    { lat: 48.7618597, lng: 22.168823 },
    { lat: 48.7621465, lng: 22.1690653 },
    { lat: 48.7624041, lng: 22.1697113 },
    { lat: 48.7628834, lng: 22.1698095 },
    { lat: 48.7630626, lng: 22.1700165 },
    { lat: 48.7633341, lng: 22.170138 },
    { lat: 48.7633449, lng: 22.170555 },
    { lat: 48.7636189, lng: 22.170931 },
    { lat: 48.7642799, lng: 22.1714467 },
    { lat: 48.7643592, lng: 22.1712276 },
    { lat: 48.7644323, lng: 22.1712262 },
    { lat: 48.7645372, lng: 22.1715362 },
    { lat: 48.764648, lng: 22.1713204 },
    { lat: 48.7647923, lng: 22.1713346 },
    { lat: 48.7647968, lng: 22.1712834 },
    { lat: 48.7653764, lng: 22.1648875 },
    { lat: 48.7654588, lng: 22.1647501 },
    { lat: 48.7643648, lng: 22.1639874 },
    { lat: 48.7628679, lng: 22.1621478 },
    { lat: 48.7633397, lng: 22.1577807 },
    { lat: 48.7633814, lng: 22.1562204 },
    { lat: 48.7634265, lng: 22.1560793 },
    { lat: 48.763293, lng: 22.1543408 },
    { lat: 48.763322, lng: 22.1539959 },
    { lat: 48.7643926, lng: 22.1462597 },
    { lat: 48.7653658, lng: 22.1382288 },
    { lat: 48.7650402, lng: 22.1378172 },
    { lat: 48.7649117, lng: 22.1374222 },
    { lat: 48.7640003, lng: 22.136097 },
    { lat: 48.760087, lng: 22.1332489 },
    { lat: 48.759077, lng: 22.1320468 },
    { lat: 48.758854, lng: 22.1318957 },
    { lat: 48.7552713, lng: 22.1301981 },
    { lat: 48.755343, lng: 22.1300378 },
    { lat: 48.7551282, lng: 22.129943 },
    { lat: 48.7535187, lng: 22.1278808 },
    { lat: 48.7530362, lng: 22.1269227 },
    { lat: 48.7528909, lng: 22.1241258 },
    { lat: 48.7527322, lng: 22.1235375 },
    { lat: 48.7524548, lng: 22.1230729 },
    { lat: 48.7521347, lng: 22.1229043 },
    { lat: 48.7517279, lng: 22.1229233 },
    { lat: 48.7505002, lng: 22.124198 },
    { lat: 48.7499619, lng: 22.1246534 },
    { lat: 48.749529, lng: 22.1247603 },
    { lat: 48.7468095, lng: 22.1239095 },
    { lat: 48.7463183, lng: 22.123867 },
    { lat: 48.7459749, lng: 22.1241419 },
    { lat: 48.7454867, lng: 22.1249664 },
    { lat: 48.7453524, lng: 22.1250876 },
    { lat: 48.7451164, lng: 22.1251983 },
    { lat: 48.7435683, lng: 22.12523 },
    { lat: 48.7431158, lng: 22.1250204 },
    { lat: 48.7414186, lng: 22.1221452 },
    { lat: 48.7385842, lng: 22.1214815 },
    { lat: 48.7382708, lng: 22.1214975 },
    { lat: 48.7368679, lng: 22.1219692 },
    { lat: 48.736795, lng: 22.1219279 },
  ],
  Podhoroď: [
    { lat: 48.8503266, lng: 22.2620988 },
    { lat: 48.8499249, lng: 22.2618921 },
    { lat: 48.8496359, lng: 22.2616875 },
    { lat: 48.8495424, lng: 22.2614355 },
    { lat: 48.8493337, lng: 22.2601865 },
    { lat: 48.8489872, lng: 22.2592671 },
    { lat: 48.8487072, lng: 22.2588646 },
    { lat: 48.8477962, lng: 22.2584567 },
    { lat: 48.8474546, lng: 22.2581234 },
    { lat: 48.8470323, lng: 22.2574875 },
    { lat: 48.8464003, lng: 22.2572915 },
    { lat: 48.8458297, lng: 22.2568659 },
    { lat: 48.8452577, lng: 22.2566848 },
    { lat: 48.8447777, lng: 22.2567435 },
    { lat: 48.8441296, lng: 22.2564791 },
    { lat: 48.8433587, lng: 22.256058 },
    { lat: 48.8430674, lng: 22.2561107 },
    { lat: 48.8425014, lng: 22.2559978 },
    { lat: 48.8420079, lng: 22.2561594 },
    { lat: 48.8417596, lng: 22.2558114 },
    { lat: 48.8414366, lng: 22.2558068 },
    { lat: 48.8403664, lng: 22.2553779 },
    { lat: 48.8398979, lng: 22.2547954 },
    { lat: 48.839479, lng: 22.2545687 },
    { lat: 48.8393619, lng: 22.254154 },
    { lat: 48.8392242, lng: 22.2539836 },
    { lat: 48.838505, lng: 22.2544137 },
    { lat: 48.8380095, lng: 22.2545372 },
    { lat: 48.837329, lng: 22.2544894 },
    { lat: 48.83715, lng: 22.2541251 },
    { lat: 48.8370826, lng: 22.2547404 },
    { lat: 48.8369699, lng: 22.2552253 },
    { lat: 48.8365696, lng: 22.2556885 },
    { lat: 48.8364243, lng: 22.2561946 },
    { lat: 48.836364, lng: 22.2568621 },
    { lat: 48.8360515, lng: 22.2573829 },
    { lat: 48.8354949, lng: 22.2576447 },
    { lat: 48.8350746, lng: 22.2580664 },
    { lat: 48.8347904, lng: 22.2582268 },
    { lat: 48.8343299, lng: 22.2582606 },
    { lat: 48.8338033, lng: 22.2586605 },
    { lat: 48.8335897, lng: 22.2594226 },
    { lat: 48.833329, lng: 22.2596871 },
    { lat: 48.8325018, lng: 22.2607749 },
    { lat: 48.8319292, lng: 22.2611842 },
    { lat: 48.8315226, lng: 22.2616611 },
    { lat: 48.8310505, lng: 22.261723 },
    { lat: 48.8297291, lng: 22.2624413 },
    { lat: 48.8294683, lng: 22.2630621 },
    { lat: 48.8291399, lng: 22.2635182 },
    { lat: 48.8286898, lng: 22.264018 },
    { lat: 48.8282544, lng: 22.2638017 },
    { lat: 48.8272988, lng: 22.2641808 },
    { lat: 48.8270138, lng: 22.2648692 },
    { lat: 48.8266938, lng: 22.2654569 },
    { lat: 48.8263494, lng: 22.2658496 },
    { lat: 48.8257528, lng: 22.2670517 },
    { lat: 48.8255372, lng: 22.2677787 },
    { lat: 48.825386, lng: 22.2685717 },
    { lat: 48.8250919, lng: 22.2694147 },
    { lat: 48.8242571, lng: 22.2697058 },
    { lat: 48.8238242, lng: 22.2705294 },
    { lat: 48.8229203, lng: 22.2712105 },
    { lat: 48.8224496, lng: 22.2713789 },
    { lat: 48.8219334, lng: 22.2713894 },
    { lat: 48.8200418, lng: 22.2730241 },
    { lat: 48.819479, lng: 22.2731618 },
    { lat: 48.8190005, lng: 22.2734905 },
    { lat: 48.818529, lng: 22.2733794 },
    { lat: 48.8177206, lng: 22.2729831 },
    { lat: 48.8173047, lng: 22.2730509 },
    { lat: 48.816981, lng: 22.2738264 },
    { lat: 48.8167508, lng: 22.2746535 },
    { lat: 48.8162784, lng: 22.2751624 },
    { lat: 48.8153096, lng: 22.2755101 },
    { lat: 48.8145135, lng: 22.2759711 },
    { lat: 48.8140141, lng: 22.2759735 },
    { lat: 48.8134727, lng: 22.2761525 },
    { lat: 48.8127215, lng: 22.2758611 },
    { lat: 48.8114862, lng: 22.2757947 },
    { lat: 48.8105183, lng: 22.2769317 },
    { lat: 48.8101701, lng: 22.2777193 },
    { lat: 48.8094182, lng: 22.2783075 },
    { lat: 48.808996, lng: 22.2784391 },
    { lat: 48.808734, lng: 22.2782932 },
    { lat: 48.8080893, lng: 22.277576 },
    { lat: 48.8077052, lng: 22.277234 },
    { lat: 48.8071052, lng: 22.2775715 },
    { lat: 48.8066065, lng: 22.2780609 },
    { lat: 48.8062778, lng: 22.2779501 },
    { lat: 48.8058737, lng: 22.2782427 },
    { lat: 48.8053882, lng: 22.2784157 },
    { lat: 48.8043537, lng: 22.27929 },
    { lat: 48.8038563, lng: 22.2795327 },
    { lat: 48.8036776, lng: 22.2801069 },
    { lat: 48.8038537, lng: 22.2804823 },
    { lat: 48.8035557, lng: 22.282074 },
    { lat: 48.8038207, lng: 22.282775 },
    { lat: 48.8048186, lng: 22.2848341 },
    { lat: 48.8049332, lng: 22.2849789 },
    { lat: 48.8051426, lng: 22.2856024 },
    { lat: 48.8059198, lng: 22.286674 },
    { lat: 48.805672, lng: 22.287895 },
    { lat: 48.8058033, lng: 22.2883455 },
    { lat: 48.8062536, lng: 22.2892338 },
    { lat: 48.8062188, lng: 22.2903645 },
    { lat: 48.8058511, lng: 22.2915448 },
    { lat: 48.8056528, lng: 22.2925095 },
    { lat: 48.805315, lng: 22.2946488 },
    { lat: 48.8051715, lng: 22.2951773 },
    { lat: 48.8056882, lng: 22.2954625 },
    { lat: 48.8061065, lng: 22.2961195 },
    { lat: 48.8064941, lng: 22.2977928 },
    { lat: 48.8069984, lng: 22.2988483 },
    { lat: 48.8076808, lng: 22.299552 },
    { lat: 48.8089794, lng: 22.3013322 },
    { lat: 48.8094955, lng: 22.3015886 },
    { lat: 48.8108196, lng: 22.3025291 },
    { lat: 48.8114104, lng: 22.3030558 },
    { lat: 48.8112374, lng: 22.3034342 },
    { lat: 48.8129309, lng: 22.3051684 },
    { lat: 48.8132448, lng: 22.3070816 },
    { lat: 48.8136393, lng: 22.3074805 },
    { lat: 48.8139199, lng: 22.3081253 },
    { lat: 48.8144269, lng: 22.3083772 },
    { lat: 48.814844, lng: 22.3081566 },
    { lat: 48.8151096, lng: 22.3099784 },
    { lat: 48.8160119, lng: 22.3117503 },
    { lat: 48.8157793, lng: 22.3126419 },
    { lat: 48.8162436, lng: 22.3144977 },
    { lat: 48.8163727, lng: 22.3144282 },
    { lat: 48.8164556, lng: 22.3147309 },
    { lat: 48.8164327, lng: 22.3149655 },
    { lat: 48.8168747, lng: 22.3167839 },
    { lat: 48.8179688, lng: 22.3181264 },
    { lat: 48.8180269, lng: 22.3186756 },
    { lat: 48.8177699, lng: 22.3198426 },
    { lat: 48.8176354, lng: 22.3207408 },
    { lat: 48.817808, lng: 22.3208182 },
    { lat: 48.8178874, lng: 22.320961 },
    { lat: 48.8179634, lng: 22.3215097 },
    { lat: 48.8185761, lng: 22.3223747 },
    { lat: 48.8194702, lng: 22.323516 },
    { lat: 48.8196079, lng: 22.3238029 },
    { lat: 48.8225905, lng: 22.3215605 },
    { lat: 48.824133, lng: 22.3221039 },
    { lat: 48.8250421, lng: 22.3225856 },
    { lat: 48.8246804, lng: 22.3230683 },
    { lat: 48.8245824, lng: 22.3236368 },
    { lat: 48.8244782, lng: 22.3247474 },
    { lat: 48.8247728, lng: 22.3249548 },
    { lat: 48.824833, lng: 22.3258081 },
    { lat: 48.8247862, lng: 22.3262513 },
    { lat: 48.8248122, lng: 22.3265138 },
    { lat: 48.8251453, lng: 22.327884 },
    { lat: 48.825441, lng: 22.3281302 },
    { lat: 48.8257401, lng: 22.3285452 },
    { lat: 48.8266781, lng: 22.3294293 },
    { lat: 48.8271376, lng: 22.3294173 },
    { lat: 48.8285083, lng: 22.3311753 },
    { lat: 48.8293861, lng: 22.3313008 },
    { lat: 48.8303307, lng: 22.3315511 },
    { lat: 48.8306054, lng: 22.3315118 },
    { lat: 48.8319925, lng: 22.3305321 },
    { lat: 48.8325817, lng: 22.3299532 },
    { lat: 48.8341062, lng: 22.3273163 },
    { lat: 48.8344318, lng: 22.3261563 },
    { lat: 48.834824, lng: 22.3250423 },
    { lat: 48.8355526, lng: 22.324928 },
    { lat: 48.8369273, lng: 22.3253082 },
    { lat: 48.8374231, lng: 22.3256649 },
    { lat: 48.838058, lng: 22.3256877 },
    { lat: 48.8385292, lng: 22.3258429 },
    { lat: 48.8396913, lng: 22.3260595 },
    { lat: 48.840438, lng: 22.3256454 },
    { lat: 48.8409556, lng: 22.3251777 },
    { lat: 48.8415362, lng: 22.3243127 },
    { lat: 48.8418903, lng: 22.3239909 },
    { lat: 48.8424215, lng: 22.3232247 },
    { lat: 48.8427585, lng: 22.3225821 },
    { lat: 48.8434342, lng: 22.3216873 },
    { lat: 48.8437859, lng: 22.3213638 },
    { lat: 48.8441253, lng: 22.3208296 },
    { lat: 48.8446095, lng: 22.3207484 },
    { lat: 48.8446248, lng: 22.3203673 },
    { lat: 48.8440417, lng: 22.3200281 },
    { lat: 48.8435025, lng: 22.3195947 },
    { lat: 48.8429297, lng: 22.3190018 },
    { lat: 48.8423126, lng: 22.3180863 },
    { lat: 48.841892, lng: 22.3173599 },
    { lat: 48.8417736, lng: 22.3172479 },
    { lat: 48.8412865, lng: 22.3170315 },
    { lat: 48.8408592, lng: 22.3167268 },
    { lat: 48.8404141, lng: 22.316223 },
    { lat: 48.8401219, lng: 22.3160712 },
    { lat: 48.8399154, lng: 22.3161036 },
    { lat: 48.8394674, lng: 22.3159309 },
    { lat: 48.8391583, lng: 22.3159924 },
    { lat: 48.8386928, lng: 22.3158909 },
    { lat: 48.838291, lng: 22.3153607 },
    { lat: 48.8381478, lng: 22.3150269 },
    { lat: 48.8380553, lng: 22.3141332 },
    { lat: 48.8379507, lng: 22.3138189 },
    { lat: 48.837941, lng: 22.3134252 },
    { lat: 48.8377823, lng: 22.3122849 },
    { lat: 48.8374188, lng: 22.3110873 },
    { lat: 48.8371751, lng: 22.3105857 },
    { lat: 48.8371051, lng: 22.3100001 },
    { lat: 48.8369946, lng: 22.3097871 },
    { lat: 48.8367964, lng: 22.3090522 },
    { lat: 48.8370139, lng: 22.3087572 },
    { lat: 48.8374063, lng: 22.3078664 },
    { lat: 48.8379403, lng: 22.3072906 },
    { lat: 48.838524, lng: 22.3068741 },
    { lat: 48.8393554, lng: 22.3061121 },
    { lat: 48.8397902, lng: 22.3057872 },
    { lat: 48.8407914, lng: 22.3047056 },
    { lat: 48.8412576, lng: 22.303494 },
    { lat: 48.8413581, lng: 22.302841 },
    { lat: 48.8414539, lng: 22.3019257 },
    { lat: 48.8414318, lng: 22.3007538 },
    { lat: 48.8415173, lng: 22.2988482 },
    { lat: 48.8416177, lng: 22.2980951 },
    { lat: 48.8420122, lng: 22.2972091 },
    { lat: 48.8425805, lng: 22.2961851 },
    { lat: 48.8429672, lng: 22.2957194 },
    { lat: 48.8432349, lng: 22.2951464 },
    { lat: 48.8437373, lng: 22.2946266 },
    { lat: 48.8441825, lng: 22.2940005 },
    { lat: 48.8444411, lng: 22.2935301 },
    { lat: 48.8445357, lng: 22.2930179 },
    { lat: 48.844336, lng: 22.2901731 },
    { lat: 48.8442052, lng: 22.2890238 },
    { lat: 48.8441954, lng: 22.2884236 },
    { lat: 48.844337, lng: 22.2872421 },
    { lat: 48.8444635, lng: 22.2869022 },
    { lat: 48.8446473, lng: 22.2861513 },
    { lat: 48.8446485, lng: 22.2850364 },
    { lat: 48.8451474, lng: 22.2839371 },
    { lat: 48.845436, lng: 22.2827856 },
    { lat: 48.8453792, lng: 22.2815865 },
    { lat: 48.8459284, lng: 22.2803761 },
    { lat: 48.846327, lng: 22.279059 },
    { lat: 48.8464324, lng: 22.2767507 },
    { lat: 48.8465628, lng: 22.2760389 },
    { lat: 48.8466809, lng: 22.2748088 },
    { lat: 48.8469364, lng: 22.2741566 },
    { lat: 48.8473665, lng: 22.2740684 },
    { lat: 48.8479439, lng: 22.2732254 },
    { lat: 48.8483226, lng: 22.2728051 },
    { lat: 48.8490492, lng: 22.2707131 },
    { lat: 48.8488847, lng: 22.269633 },
    { lat: 48.8495669, lng: 22.2684324 },
    { lat: 48.8498623, lng: 22.2675514 },
    { lat: 48.8499547, lng: 22.267401 },
    { lat: 48.8499791, lng: 22.2670866 },
    { lat: 48.8501707, lng: 22.2664376 },
    { lat: 48.8502113, lng: 22.2654295 },
    { lat: 48.8501804, lng: 22.2648511 },
    { lat: 48.8502849, lng: 22.2634733 },
    { lat: 48.8503266, lng: 22.2620988 },
  ],
  Tibava: [
    { lat: 48.7093479, lng: 22.2023412 },
    { lat: 48.710771, lng: 22.2030413 },
    { lat: 48.7108576, lng: 22.2033894 },
    { lat: 48.7110342, lng: 22.2036324 },
    { lat: 48.7111051, lng: 22.204086 },
    { lat: 48.711333, lng: 22.2045618 },
    { lat: 48.711502, lng: 22.2058588 },
    { lat: 48.7118313, lng: 22.2061409 },
    { lat: 48.7118516, lng: 22.2064938 },
    { lat: 48.7117652, lng: 22.2067501 },
    { lat: 48.7119939, lng: 22.2071176 },
    { lat: 48.7120349, lng: 22.2072807 },
    { lat: 48.7119609, lng: 22.2073441 },
    { lat: 48.7119797, lng: 22.2075308 },
    { lat: 48.7118714, lng: 22.2076603 },
    { lat: 48.7117441, lng: 22.2085929 },
    { lat: 48.7115399, lng: 22.2113068 },
    { lat: 48.7111566, lng: 22.2134999 },
    { lat: 48.7109123, lng: 22.214487 },
    { lat: 48.7107291, lng: 22.2154855 },
    { lat: 48.7105509, lng: 22.2167576 },
    { lat: 48.7104954, lng: 22.2186292 },
    { lat: 48.7127017, lng: 22.2187616 },
    { lat: 48.7140307, lng: 22.2186831 },
    { lat: 48.7149252, lng: 22.2184026 },
    { lat: 48.7149961, lng: 22.2190475 },
    { lat: 48.7149167, lng: 22.2193722 },
    { lat: 48.7149445, lng: 22.2199538 },
    { lat: 48.7148476, lng: 22.2200888 },
    { lat: 48.7147909, lng: 22.220719 },
    { lat: 48.7147368, lng: 22.2214163 },
    { lat: 48.7158759, lng: 22.2210496 },
    { lat: 48.7158878, lng: 22.2212317 },
    { lat: 48.7159379, lng: 22.2212569 },
    { lat: 48.7183472, lng: 22.2207541 },
    { lat: 48.7214368, lng: 22.2195946 },
    { lat: 48.7217027, lng: 22.219473 },
    { lat: 48.7220111, lng: 22.2191853 },
    { lat: 48.7220847, lng: 22.2193525 },
    { lat: 48.7221729, lng: 22.2189692 },
    { lat: 48.7230759, lng: 22.2178653 },
    { lat: 48.7228779, lng: 22.2173715 },
    { lat: 48.7229676, lng: 22.2172528 },
    { lat: 48.7231716, lng: 22.217227 },
    { lat: 48.7233334, lng: 22.2170124 },
    { lat: 48.7233636, lng: 22.2166068 },
    { lat: 48.7233071, lng: 22.2165082 },
    { lat: 48.7233809, lng: 22.2164339 },
    { lat: 48.723417, lng: 22.2162885 },
    { lat: 48.723386, lng: 22.21616 },
    { lat: 48.7233324, lng: 22.2161232 },
    { lat: 48.7233412, lng: 22.2159717 },
    { lat: 48.723457, lng: 22.2157677 },
    { lat: 48.7234103, lng: 22.2156917 },
    { lat: 48.7248864, lng: 22.2141128 },
    { lat: 48.7253875, lng: 22.2146349 },
    { lat: 48.7255825, lng: 22.2147096 },
    { lat: 48.7256748, lng: 22.2150084 },
    { lat: 48.7256586, lng: 22.2152422 },
    { lat: 48.7257601, lng: 22.2153524 },
    { lat: 48.7258989, lng: 22.215265 },
    { lat: 48.7261435, lng: 22.2154957 },
    { lat: 48.7263804, lng: 22.2152747 },
    { lat: 48.7266398, lng: 22.2153558 },
    { lat: 48.7266841, lng: 22.215523 },
    { lat: 48.7270108, lng: 22.2157923 },
    { lat: 48.7271608, lng: 22.2157126 },
    { lat: 48.727241, lng: 22.21578 },
    { lat: 48.727328, lng: 22.2157474 },
    { lat: 48.7275263, lng: 22.2154596 },
    { lat: 48.7275606, lng: 22.2152893 },
    { lat: 48.7276617, lng: 22.2152784 },
    { lat: 48.7294941, lng: 22.2157798 },
    { lat: 48.7301021, lng: 22.2161119 },
    { lat: 48.7302607, lng: 22.2161094 },
    { lat: 48.7307974, lng: 22.21661 },
    { lat: 48.7308355, lng: 22.2165108 },
    { lat: 48.7309246, lng: 22.216524 },
    { lat: 48.7309945, lng: 22.2167249 },
    { lat: 48.7311431, lng: 22.2167857 },
    { lat: 48.7313869, lng: 22.2164207 },
    { lat: 48.7314842, lng: 22.2164941 },
    { lat: 48.7315723, lng: 22.2163552 },
    { lat: 48.7316476, lng: 22.2163624 },
    { lat: 48.7316134, lng: 22.2167878 },
    { lat: 48.7318179, lng: 22.2169311 },
    { lat: 48.7320362, lng: 22.2168276 },
    { lat: 48.732161, lng: 22.2169259 },
    { lat: 48.7321498, lng: 22.217328 },
    { lat: 48.7324037, lng: 22.2174667 },
    { lat: 48.7325051, lng: 22.2178242 },
    { lat: 48.7324931, lng: 22.2180343 },
    { lat: 48.7325865, lng: 22.2182315 },
    { lat: 48.7330407, lng: 22.2190262 },
    { lat: 48.7334369, lng: 22.2195377 },
    { lat: 48.7337172, lng: 22.2196473 },
    { lat: 48.7339252, lng: 22.2196415 },
    { lat: 48.7339885, lng: 22.2195531 },
    { lat: 48.7340775, lng: 22.2195596 },
    { lat: 48.7346456, lng: 22.2191658 },
    { lat: 48.734785, lng: 22.2192616 },
    { lat: 48.734834, lng: 22.2193884 },
    { lat: 48.7347528, lng: 22.2198312 },
    { lat: 48.7348294, lng: 22.2202395 },
    { lat: 48.7349775, lng: 22.2201154 },
    { lat: 48.7350084, lng: 22.2199392 },
    { lat: 48.7351709, lng: 22.2198182 },
    { lat: 48.7352401, lng: 22.2197918 },
    { lat: 48.7353528, lng: 22.2198983 },
    { lat: 48.7354291, lng: 22.2201911 },
    { lat: 48.7353728, lng: 22.2203362 },
    { lat: 48.7359017, lng: 22.2208506 },
    { lat: 48.736053, lng: 22.2210776 },
    { lat: 48.7357966, lng: 22.2214753 },
    { lat: 48.7360565, lng: 22.2217766 },
    { lat: 48.7363278, lng: 22.2222386 },
    { lat: 48.7369249, lng: 22.2228265 },
    { lat: 48.7371402, lng: 22.2229063 },
    { lat: 48.7372843, lng: 22.223056 },
    { lat: 48.7378107, lng: 22.2233503 },
    { lat: 48.7381538, lng: 22.2240058 },
    { lat: 48.7385244, lng: 22.2242319 },
    { lat: 48.7390538, lng: 22.2249109 },
    { lat: 48.7391847, lng: 22.224927 },
    { lat: 48.7392436, lng: 22.2246014 },
    { lat: 48.7393986, lng: 22.2244912 },
    { lat: 48.7395984, lng: 22.2245711 },
    { lat: 48.7398154, lng: 22.2248222 },
    { lat: 48.7398884, lng: 22.2250572 },
    { lat: 48.7401391, lng: 22.2252542 },
    { lat: 48.7402494, lng: 22.2252631 },
    { lat: 48.7403845, lng: 22.2251197 },
    { lat: 48.7404774, lng: 22.2250985 },
    { lat: 48.7406345, lng: 22.2253228 },
    { lat: 48.7408487, lng: 22.2253531 },
    { lat: 48.7408481, lng: 22.2254732 },
    { lat: 48.7409421, lng: 22.2255535 },
    { lat: 48.7410618, lng: 22.2254198 },
    { lat: 48.7411425, lng: 22.2254659 },
    { lat: 48.7412385, lng: 22.2256744 },
    { lat: 48.7413411, lng: 22.2256898 },
    { lat: 48.7413983, lng: 22.2257788 },
    { lat: 48.741317, lng: 22.2258653 },
    { lat: 48.7413319, lng: 22.2260249 },
    { lat: 48.741617, lng: 22.2263566 },
    { lat: 48.741707, lng: 22.2265534 },
    { lat: 48.7420222, lng: 22.2267674 },
    { lat: 48.7422074, lng: 22.2270472 },
    { lat: 48.742178, lng: 22.2273424 },
    { lat: 48.7423483, lng: 22.2274619 },
    { lat: 48.7424536, lng: 22.227438 },
    { lat: 48.7426682, lng: 22.2277284 },
    { lat: 48.7426884, lng: 22.2282784 },
    { lat: 48.7428219, lng: 22.2284087 },
    { lat: 48.7429737, lng: 22.2279583 },
    { lat: 48.7431068, lng: 22.2280291 },
    { lat: 48.7430951, lng: 22.2284531 },
    { lat: 48.7431477, lng: 22.2286001 },
    { lat: 48.7432699, lng: 22.2285749 },
    { lat: 48.743437, lng: 22.2282089 },
    { lat: 48.7435694, lng: 22.2281436 },
    { lat: 48.7437056, lng: 22.2282471 },
    { lat: 48.7437728, lng: 22.2283848 },
    { lat: 48.743927, lng: 22.2280379 },
    { lat: 48.7439894, lng: 22.2280575 },
    { lat: 48.744341, lng: 22.2285911 },
    { lat: 48.7446052, lng: 22.22859 },
    { lat: 48.7448232, lng: 22.228881 },
    { lat: 48.7453296, lng: 22.2286886 },
    { lat: 48.745511, lng: 22.2287559 },
    { lat: 48.7459027, lng: 22.2298684 },
    { lat: 48.746011, lng: 22.2299762 },
    { lat: 48.7461594, lng: 22.2298787 },
    { lat: 48.7461699, lng: 22.2296437 },
    { lat: 48.7464744, lng: 22.2297278 },
    { lat: 48.7466329, lng: 22.230131 },
    { lat: 48.7467383, lng: 22.2301637 },
    { lat: 48.7468223, lng: 22.2300403 },
    { lat: 48.747238, lng: 22.2305226 },
    { lat: 48.7472481, lng: 22.2309694 },
    { lat: 48.74734, lng: 22.2313046 },
    { lat: 48.7474191, lng: 22.2312824 },
    { lat: 48.7475482, lng: 22.2314123 },
    { lat: 48.747364, lng: 22.2316798 },
    { lat: 48.7475167, lng: 22.2319617 },
    { lat: 48.7478276, lng: 22.2323439 },
    { lat: 48.7479744, lng: 22.2324661 },
    { lat: 48.7482463, lng: 22.2323487 },
    { lat: 48.7483458, lng: 22.2321683 },
    { lat: 48.7485426, lng: 22.2321703 },
    { lat: 48.7486774, lng: 22.2324114 },
    { lat: 48.7489756, lng: 22.2324236 },
    { lat: 48.7492268, lng: 22.2326823 },
    { lat: 48.7492687, lng: 22.2325971 },
    { lat: 48.749388, lng: 22.2325992 },
    { lat: 48.7496708, lng: 22.2327736 },
    { lat: 48.7497169, lng: 22.2331287 },
    { lat: 48.7498735, lng: 22.2334357 },
    { lat: 48.7501343, lng: 22.2336825 },
    { lat: 48.7502788, lng: 22.2337051 },
    { lat: 48.7503193, lng: 22.2338092 },
    { lat: 48.7508181, lng: 22.233929 },
    { lat: 48.7509821, lng: 22.233566 },
    { lat: 48.7509682, lng: 22.2333992 },
    { lat: 48.7511195, lng: 22.2334203 },
    { lat: 48.7511948, lng: 22.2332268 },
    { lat: 48.7513831, lng: 22.2334474 },
    { lat: 48.7514537, lng: 22.2333375 },
    { lat: 48.7516519, lng: 22.2332917 },
    { lat: 48.7517973, lng: 22.2333531 },
    { lat: 48.7518685, lng: 22.2334727 },
    { lat: 48.7520324, lng: 22.2333082 },
    { lat: 48.7521602, lng: 22.2334895 },
    { lat: 48.7524907, lng: 22.2335866 },
    { lat: 48.7531385, lng: 22.234123 },
    { lat: 48.7537163, lng: 22.2344141 },
    { lat: 48.7538318, lng: 22.2346032 },
    { lat: 48.7543829, lng: 22.2351919 },
    { lat: 48.7553202, lng: 22.2357149 },
    { lat: 48.755557, lng: 22.2357466 },
    { lat: 48.7558367, lng: 22.2359312 },
    { lat: 48.7563401, lng: 22.2358569 },
    { lat: 48.7565523, lng: 22.2360353 },
    { lat: 48.7569242, lng: 22.2359706 },
    { lat: 48.7570006, lng: 22.2362159 },
    { lat: 48.7572623, lng: 22.2363572 },
    { lat: 48.757415, lng: 22.2361875 },
    { lat: 48.7576138, lng: 22.2362739 },
    { lat: 48.7576895, lng: 22.2365916 },
    { lat: 48.7579073, lng: 22.2366767 },
    { lat: 48.7580847, lng: 22.2370095 },
    { lat: 48.7583408, lng: 22.2371015 },
    { lat: 48.7584591, lng: 22.2374559 },
    { lat: 48.7586555, lng: 22.237587 },
    { lat: 48.7587022, lng: 22.237766 },
    { lat: 48.7588802, lng: 22.2379815 },
    { lat: 48.7592775, lng: 22.2381451 },
    { lat: 48.7594075, lng: 22.2388785 },
    { lat: 48.7595855, lng: 22.2394373 },
    { lat: 48.7596717, lng: 22.2401719 },
    { lat: 48.7598218, lng: 22.2401489 },
    { lat: 48.7600269, lng: 22.2401173 },
    { lat: 48.760128, lng: 22.2398017 },
    { lat: 48.7616831, lng: 22.241044 },
    { lat: 48.7620843, lng: 22.2414284 },
    { lat: 48.7623252, lng: 22.2417448 },
    { lat: 48.7639119, lng: 22.2423325 },
    { lat: 48.7642675, lng: 22.242341 },
    { lat: 48.7642888, lng: 22.2422811 },
    { lat: 48.766813, lng: 22.2356536 },
    { lat: 48.7664089, lng: 22.2351413 },
    { lat: 48.7661761, lng: 22.2350944 },
    { lat: 48.7659316, lng: 22.2349208 },
    { lat: 48.7657576, lng: 22.2346862 },
    { lat: 48.7658493, lng: 22.2343107 },
    { lat: 48.7663168, lng: 22.2343459 },
    { lat: 48.766985, lng: 22.2320476 },
    { lat: 48.7669571, lng: 22.2315056 },
    { lat: 48.7658628, lng: 22.2309525 },
    { lat: 48.7663231, lng: 22.2295633 },
    { lat: 48.766652, lng: 22.2283862 },
    { lat: 48.7664205, lng: 22.2278911 },
    { lat: 48.7664578, lng: 22.2277437 },
    { lat: 48.7662704, lng: 22.2276175 },
    { lat: 48.7657651, lng: 22.2269055 },
    { lat: 48.765428, lng: 22.2267681 },
    { lat: 48.7652304, lng: 22.2266402 },
    { lat: 48.7651923, lng: 22.2265463 },
    { lat: 48.7650754, lng: 22.2265477 },
    { lat: 48.7650171, lng: 22.2264505 },
    { lat: 48.7648255, lng: 22.2265334 },
    { lat: 48.7647979, lng: 22.2264621 },
    { lat: 48.7644826, lng: 22.226243 },
    { lat: 48.7643623, lng: 22.2258503 },
    { lat: 48.764198, lng: 22.2258384 },
    { lat: 48.7641026, lng: 22.2259075 },
    { lat: 48.7639787, lng: 22.2258483 },
    { lat: 48.763967, lng: 22.2256713 },
    { lat: 48.7638383, lng: 22.2254533 },
    { lat: 48.7636256, lng: 22.2253506 },
    { lat: 48.7635127, lng: 22.2251343 },
    { lat: 48.7635149, lng: 22.2250293 },
    { lat: 48.7633513, lng: 22.224959 },
    { lat: 48.7632577, lng: 22.2247445 },
    { lat: 48.7628716, lng: 22.2247755 },
    { lat: 48.7626465, lng: 22.224525 },
    { lat: 48.7630306, lng: 22.222446 },
    { lat: 48.7631843, lng: 22.2225835 },
    { lat: 48.7633532, lng: 22.2219954 },
    { lat: 48.7638399, lng: 22.2208337 },
    { lat: 48.7630538, lng: 22.2201427 },
    { lat: 48.7617683, lng: 22.2194906 },
    { lat: 48.7613716, lng: 22.2205058 },
    { lat: 48.7523376, lng: 22.2154321 },
    { lat: 48.7530516, lng: 22.2105984 },
    { lat: 48.7525481, lng: 22.2092243 },
    { lat: 48.7515749, lng: 22.207778 },
    { lat: 48.7503986, lng: 22.2067426 },
    { lat: 48.7486585, lng: 22.205349 },
    { lat: 48.7476447, lng: 22.2041965 },
    { lat: 48.745306, lng: 22.2012635 },
    { lat: 48.7446036, lng: 22.2046048 },
    { lat: 48.7432551, lng: 22.2043636 },
    { lat: 48.7421807, lng: 22.2043082 },
    { lat: 48.7407553, lng: 22.203545 },
    { lat: 48.7405068, lng: 22.2035034 },
    { lat: 48.7405695, lng: 22.202951 },
    { lat: 48.7394558, lng: 22.2024418 },
    { lat: 48.7357054, lng: 22.2016883 },
    { lat: 48.7340706, lng: 22.2012233 },
    { lat: 48.7340001, lng: 22.20114 },
    { lat: 48.7335583, lng: 22.2009068 },
    { lat: 48.733558, lng: 22.20074 },
    { lat: 48.7334028, lng: 22.2004944 },
    { lat: 48.7334172, lng: 22.2002371 },
    { lat: 48.7331762, lng: 22.2000259 },
    { lat: 48.732847, lng: 22.1998912 },
    { lat: 48.7320562, lng: 22.1993935 },
    { lat: 48.7320485, lng: 22.1993034 },
    { lat: 48.7318679, lng: 22.1990851 },
    { lat: 48.7319379, lng: 22.1985449 },
    { lat: 48.7317237, lng: 22.1984345 },
    { lat: 48.7316528, lng: 22.1987803 },
    { lat: 48.7315594, lng: 22.1987224 },
    { lat: 48.731593, lng: 22.1983259 },
    { lat: 48.7315146, lng: 22.1982879 },
    { lat: 48.731525, lng: 22.1981562 },
    { lat: 48.730818, lng: 22.197878 },
    { lat: 48.730874, lng: 22.1969213 },
    { lat: 48.7305899, lng: 22.1968459 },
    { lat: 48.730136, lng: 22.1964227 },
    { lat: 48.7298368, lng: 22.1960286 },
    { lat: 48.7292657, lng: 22.1954996 },
    { lat: 48.7293109, lng: 22.1951135 },
    { lat: 48.7286937, lng: 22.194489 },
    { lat: 48.7282342, lng: 22.1941631 },
    { lat: 48.7283209, lng: 22.1935825 },
    { lat: 48.7273391, lng: 22.1923987 },
    { lat: 48.7271097, lng: 22.1922108 },
    { lat: 48.7271348, lng: 22.1917798 },
    { lat: 48.7262395, lng: 22.1906932 },
    { lat: 48.7251289, lng: 22.1897432 },
    { lat: 48.7247777, lng: 22.1892866 },
    { lat: 48.7242364, lng: 22.1887356 },
    { lat: 48.723831, lng: 22.1888608 },
    { lat: 48.7236733, lng: 22.1889933 },
    { lat: 48.7231404, lng: 22.1846756 },
    { lat: 48.7216497, lng: 22.1882564 },
    { lat: 48.7181319, lng: 22.1845924 },
    { lat: 48.716946, lng: 22.1904352 },
    { lat: 48.7146123, lng: 22.1880322 },
    { lat: 48.7135215, lng: 22.1896759 },
    { lat: 48.7126614, lng: 22.1915646 },
    { lat: 48.7129696, lng: 22.1912697 },
    { lat: 48.7131125, lng: 22.191264 },
    { lat: 48.7132689, lng: 22.1915672 },
    { lat: 48.7132388, lng: 22.1929543 },
    { lat: 48.7130708, lng: 22.1933819 },
    { lat: 48.7125518, lng: 22.1940044 },
    { lat: 48.7122233, lng: 22.1949906 },
    { lat: 48.7135456, lng: 22.1966023 },
    { lat: 48.7125583, lng: 22.1994335 },
    { lat: 48.712124, lng: 22.1985774 },
    { lat: 48.7119398, lng: 22.1983455 },
    { lat: 48.7109571, lng: 22.1976682 },
    { lat: 48.7098877, lng: 22.1970471 },
    { lat: 48.7095636, lng: 22.2003527 },
    { lat: 48.7093479, lng: 22.2023412 },
  ],
  Svätuš: [
    { lat: 48.6744046, lng: 22.136851 },
    { lat: 48.6744293, lng: 22.1370931 },
    { lat: 48.6759722, lng: 22.1408416 },
    { lat: 48.676395, lng: 22.1421358 },
    { lat: 48.6765036, lng: 22.1425984 },
    { lat: 48.6769614, lng: 22.1436651 },
    { lat: 48.6767302, lng: 22.1442657 },
    { lat: 48.6763765, lng: 22.1468061 },
    { lat: 48.6766161, lng: 22.1509254 },
    { lat: 48.6765898, lng: 22.1510614 },
    { lat: 48.6771484, lng: 22.1529297 },
    { lat: 48.6776137, lng: 22.1535393 },
    { lat: 48.6777902, lng: 22.1546569 },
    { lat: 48.6824227, lng: 22.153511 },
    { lat: 48.6835293, lng: 22.1535494 },
    { lat: 48.6862643, lng: 22.1539571 },
    { lat: 48.686238, lng: 22.1536445 },
    { lat: 48.6864115, lng: 22.1537468 },
    { lat: 48.687043, lng: 22.1538743 },
    { lat: 48.6885477, lng: 22.1538571 },
    { lat: 48.6892725, lng: 22.1539203 },
    { lat: 48.6910894, lng: 22.1542765 },
    { lat: 48.6913627, lng: 22.1542735 },
    { lat: 48.6914722, lng: 22.1526908 },
    { lat: 48.6913376, lng: 22.1484804 },
    { lat: 48.691526, lng: 22.1463175 },
    { lat: 48.6913256, lng: 22.1460739 },
    { lat: 48.690958, lng: 22.1447287 },
    { lat: 48.6909724, lng: 22.1439386 },
    { lat: 48.6912658, lng: 22.1429643 },
    { lat: 48.6919458, lng: 22.1418783 },
    { lat: 48.6920309, lng: 22.1415722 },
    { lat: 48.6917799, lng: 22.1412309 },
    { lat: 48.6920281, lng: 22.140338 },
    { lat: 48.6920593, lng: 22.1395506 },
    { lat: 48.6923554, lng: 22.138644 },
    { lat: 48.6923619, lng: 22.1381545 },
    { lat: 48.6924456, lng: 22.1379809 },
    { lat: 48.6927707, lng: 22.1385109 },
    { lat: 48.6930742, lng: 22.1385571 },
    { lat: 48.6933851, lng: 22.1387695 },
    { lat: 48.6940217, lng: 22.1397273 },
    { lat: 48.6961064, lng: 22.1400342 },
    { lat: 48.6961482, lng: 22.139896 },
    { lat: 48.6955677, lng: 22.1388771 },
    { lat: 48.6895351, lng: 22.128649 },
    { lat: 48.681846, lng: 22.1151373 },
    { lat: 48.6818786, lng: 22.1154733 },
    { lat: 48.6819058, lng: 22.1157695 },
    { lat: 48.6818268, lng: 22.120507 },
    { lat: 48.6817678, lng: 22.1208679 },
    { lat: 48.6815553, lng: 22.1214449 },
    { lat: 48.6760826, lng: 22.1308563 },
    { lat: 48.6755455, lng: 22.1331012 },
    { lat: 48.6751738, lng: 22.1351042 },
    { lat: 48.6748438, lng: 22.1358521 },
    { lat: 48.6744435, lng: 22.1365866 },
    { lat: 48.6744046, lng: 22.136851 },
  ],
  Pinkovce: [
    { lat: 48.6137397, lng: 22.1653148 },
    { lat: 48.6132104, lng: 22.1655267 },
    { lat: 48.6130062, lng: 22.1656572 },
    { lat: 48.6127021, lng: 22.1660738 },
    { lat: 48.6117464, lng: 22.1664745 },
    { lat: 48.6099975, lng: 22.1659259 },
    { lat: 48.609964, lng: 22.1659154 },
    { lat: 48.6092715, lng: 22.1663794 },
    { lat: 48.6092189, lng: 22.1664285 },
    { lat: 48.6079503, lng: 22.1676135 },
    { lat: 48.6073568, lng: 22.167972 },
    { lat: 48.6065989, lng: 22.1685726 },
    { lat: 48.6073102, lng: 22.1710725 },
    { lat: 48.6050395, lng: 22.1731956 },
    { lat: 48.6058178, lng: 22.1744135 },
    { lat: 48.6063148, lng: 22.1755774 },
    { lat: 48.6063902, lng: 22.1760675 },
    { lat: 48.6063882, lng: 22.1766658 },
    { lat: 48.6062664, lng: 22.1772835 },
    { lat: 48.6052128, lng: 22.1800441 },
    { lat: 48.6045162, lng: 22.1821866 },
    { lat: 48.6040329, lng: 22.1828434 },
    { lat: 48.6035011, lng: 22.1831491 },
    { lat: 48.6026615, lng: 22.1835209 },
    { lat: 48.602387, lng: 22.183819 },
    { lat: 48.602247, lng: 22.184006 },
    { lat: 48.602191, lng: 22.184113 },
    { lat: 48.602144, lng: 22.184227 },
    { lat: 48.602114, lng: 22.184354 },
    { lat: 48.602098, lng: 22.184483 },
    { lat: 48.602088, lng: 22.184744 },
    { lat: 48.602124, lng: 22.185002 },
    { lat: 48.602262, lng: 22.185509 },
    { lat: 48.602352, lng: 22.185738 },
    { lat: 48.602617, lng: 22.186275 },
    { lat: 48.602718, lng: 22.186493 },
    { lat: 48.602837, lng: 22.186686 },
    { lat: 48.603111, lng: 22.187023 },
    { lat: 48.603396, lng: 22.187334 },
    { lat: 48.603548, lng: 22.187467 },
    { lat: 48.603716, lng: 22.187561 },
    { lat: 48.603885, lng: 22.187628 },
    { lat: 48.603966, lng: 22.187684 },
    { lat: 48.604043, lng: 22.187747 },
    { lat: 48.604121, lng: 22.187813 },
    { lat: 48.604288, lng: 22.187909 },
    { lat: 48.604372, lng: 22.187954 },
    { lat: 48.604456, lng: 22.188009 },
    { lat: 48.604609, lng: 22.188141 },
    { lat: 48.604757, lng: 22.18827 },
    { lat: 48.605079, lng: 22.188521 },
    { lat: 48.605242, lng: 22.188614 },
    { lat: 48.605582, lng: 22.188772 },
    { lat: 48.605752, lng: 22.188846 },
    { lat: 48.605817, lng: 22.188871 },
    { lat: 48.605923, lng: 22.188913 },
    { lat: 48.606096, lng: 22.188948 },
    { lat: 48.606361, lng: 22.188954 },
    { lat: 48.606538, lng: 22.188933 },
    { lat: 48.606713, lng: 22.188885 },
    { lat: 48.607055, lng: 22.188746 },
    { lat: 48.607392, lng: 22.188584 },
    { lat: 48.607726, lng: 22.188396 },
    { lat: 48.607968, lng: 22.188283 },
    { lat: 48.608443, lng: 22.188136 },
    { lat: 48.608971, lng: 22.188179 },
    { lat: 48.609314, lng: 22.188353 },
    { lat: 48.609624, lng: 22.188638 },
    { lat: 48.609864, lng: 22.189027 },
    { lat: 48.610004, lng: 22.189524 },
    { lat: 48.6100317, lng: 22.1898383 },
    { lat: 48.609962, lng: 22.1901802 },
    { lat: 48.60987, lng: 22.190707 },
    { lat: 48.609151, lng: 22.195121 },
    { lat: 48.60922, lng: 22.197329 },
    { lat: 48.609397, lng: 22.198381 },
    { lat: 48.612108, lng: 22.1989624 },
    { lat: 48.612066, lng: 22.2001814 },
    { lat: 48.6170981, lng: 22.2011392 },
    { lat: 48.6170316, lng: 22.2008394 },
    { lat: 48.617085, lng: 22.2002767 },
    { lat: 48.6176025, lng: 22.1967254 },
    { lat: 48.6178766, lng: 22.1942987 },
    { lat: 48.6180699, lng: 22.1931532 },
    { lat: 48.6180796, lng: 22.1926877 },
    { lat: 48.6178513, lng: 22.191558 },
    { lat: 48.6178685, lng: 22.1909396 },
    { lat: 48.6181313, lng: 22.190868 },
    { lat: 48.6184695, lng: 22.1906076 },
    { lat: 48.6189644, lng: 22.1906064 },
    { lat: 48.6190222, lng: 22.1908252 },
    { lat: 48.6189223, lng: 22.1910832 },
    { lat: 48.6189279, lng: 22.1911732 },
    { lat: 48.6190976, lng: 22.1913674 },
    { lat: 48.6192008, lng: 22.1913965 },
    { lat: 48.6193, lng: 22.1913654 },
    { lat: 48.6194208, lng: 22.1908464 },
    { lat: 48.6196254, lng: 22.1903398 },
    { lat: 48.6202221, lng: 22.1904176 },
    { lat: 48.6205134, lng: 22.1905596 },
    { lat: 48.6217134, lng: 22.1859857 },
    { lat: 48.6212642, lng: 22.1858164 },
    { lat: 48.6142172, lng: 22.1831734 },
    { lat: 48.6205059, lng: 22.1678502 },
    { lat: 48.6195678, lng: 22.167412 },
    { lat: 48.6190827, lng: 22.1663304 },
    { lat: 48.6190124, lng: 22.1662587 },
    { lat: 48.6188388, lng: 22.1662433 },
    { lat: 48.6177743, lng: 22.1664748 },
    { lat: 48.6177352, lng: 22.166436 },
    { lat: 48.6163911, lng: 22.1674159 },
    { lat: 48.6162593, lng: 22.1667694 },
    { lat: 48.6159946, lng: 22.1664641 },
    { lat: 48.615558, lng: 22.1663071 },
    { lat: 48.6151543, lng: 22.16636 },
    { lat: 48.6147497, lng: 22.1662866 },
    { lat: 48.6145109, lng: 22.1660706 },
    { lat: 48.6141479, lng: 22.1652621 },
    { lat: 48.6137397, lng: 22.1653148 },
  ],
  Tašuľa: [
    { lat: 48.6744046, lng: 22.136851 },
    { lat: 48.6743216, lng: 22.1368095 },
    { lat: 48.6731196, lng: 22.1392419 },
    { lat: 48.6727633, lng: 22.1398324 },
    { lat: 48.6726303, lng: 22.139921 },
    { lat: 48.6715852, lng: 22.1395298 },
    { lat: 48.6713866, lng: 22.1390709 },
    { lat: 48.6703452, lng: 22.1377251 },
    { lat: 48.6701021, lng: 22.1376302 },
    { lat: 48.6691286, lng: 22.1369737 },
    { lat: 48.6686946, lng: 22.1367893 },
    { lat: 48.6685355, lng: 22.1365764 },
    { lat: 48.6683593, lng: 22.1360149 },
    { lat: 48.6641777, lng: 22.1376417 },
    { lat: 48.6641133, lng: 22.1375294 },
    { lat: 48.6628946, lng: 22.1385685 },
    { lat: 48.6626081, lng: 22.1390669 },
    { lat: 48.6616493, lng: 22.1399629 },
    { lat: 48.6608354, lng: 22.1403288 },
    { lat: 48.66048, lng: 22.1407152 },
    { lat: 48.6598007, lng: 22.1410601 },
    { lat: 48.6596381, lng: 22.1412253 },
    { lat: 48.6591798, lng: 22.1419793 },
    { lat: 48.6587068, lng: 22.1423908 },
    { lat: 48.6584175, lng: 22.1427217 },
    { lat: 48.6580442, lng: 22.1434517 },
    { lat: 48.6577452, lng: 22.1459218 },
    { lat: 48.6573483, lng: 22.1461279 },
    { lat: 48.657222, lng: 22.1462594 },
    { lat: 48.6570264, lng: 22.1471347 },
    { lat: 48.6568528, lng: 22.1476598 },
    { lat: 48.6566617, lng: 22.147946 },
    { lat: 48.6564615, lng: 22.1484803 },
    { lat: 48.6561634, lng: 22.1487248 },
    { lat: 48.6560503, lng: 22.148892 },
    { lat: 48.6559074, lng: 22.1493229 },
    { lat: 48.6559612, lng: 22.1499993 },
    { lat: 48.6558906, lng: 22.1502076 },
    { lat: 48.6557905, lng: 22.1502997 },
    { lat: 48.6556848, lng: 22.1505415 },
    { lat: 48.6556077, lng: 22.1515343 },
    { lat: 48.6557185, lng: 22.1520952 },
    { lat: 48.6557183, lng: 22.1528192 },
    { lat: 48.6558951, lng: 22.1530607 },
    { lat: 48.6559101, lng: 22.1535658 },
    { lat: 48.6557652, lng: 22.1539656 },
    { lat: 48.6553904, lng: 22.1546393 },
    { lat: 48.6552533, lng: 22.1550809 },
    { lat: 48.6552642, lng: 22.1552554 },
    { lat: 48.6554818, lng: 22.1556637 },
    { lat: 48.6558163, lng: 22.1560218 },
    { lat: 48.6561628, lng: 22.1561753 },
    { lat: 48.6568557, lng: 22.1563099 },
    { lat: 48.6574086, lng: 22.1566337 },
    { lat: 48.6577604, lng: 22.1570058 },
    { lat: 48.6579257, lng: 22.15754 },
    { lat: 48.657903, lng: 22.1582582 },
    { lat: 48.6579795, lng: 22.1589977 },
    { lat: 48.6578475, lng: 22.1599049 },
    { lat: 48.657677, lng: 22.1603084 },
    { lat: 48.6570284, lng: 22.1611086 },
    { lat: 48.6566708, lng: 22.1614526 },
    { lat: 48.6561058, lng: 22.1616805 },
    { lat: 48.6559444, lng: 22.1618036 },
    { lat: 48.6558465, lng: 22.1620474 },
    { lat: 48.6559944, lng: 22.1630398 },
    { lat: 48.6559958, lng: 22.1633478 },
    { lat: 48.6558072, lng: 22.163753 },
    { lat: 48.6553679, lng: 22.1642148 },
    { lat: 48.6550586, lng: 22.1648486 },
    { lat: 48.6548638, lng: 22.1651378 },
    { lat: 48.6546536, lng: 22.1652424 },
    { lat: 48.6540028, lng: 22.1652624 },
    { lat: 48.6536927, lng: 22.1655644 },
    { lat: 48.6536368, lng: 22.1660206 },
    { lat: 48.6534961, lng: 22.1665545 },
    { lat: 48.6535334, lng: 22.167396 },
    { lat: 48.6532959, lng: 22.1682687 },
    { lat: 48.6532967, lng: 22.1698575 },
    { lat: 48.6533753, lng: 22.1703443 },
    { lat: 48.6536101, lng: 22.1712061 },
    { lat: 48.6536518, lng: 22.1715533 },
    { lat: 48.6536048, lng: 22.1717109 },
    { lat: 48.6537615, lng: 22.1723128 },
    { lat: 48.6537114, lng: 22.173118 },
    { lat: 48.6538529, lng: 22.1737442 },
    { lat: 48.653797, lng: 22.1747885 },
    { lat: 48.6537643, lng: 22.1750812 },
    { lat: 48.6538843, lng: 22.176239 },
    { lat: 48.6539621, lng: 22.1765384 },
    { lat: 48.653866, lng: 22.1780308 },
    { lat: 48.6534949, lng: 22.1792161 },
    { lat: 48.6531565, lng: 22.1799588 },
    { lat: 48.6531364, lng: 22.1807457 },
    { lat: 48.653205, lng: 22.1810438 },
    { lat: 48.653183, lng: 22.1812566 },
    { lat: 48.6527991, lng: 22.1817317 },
    { lat: 48.6525069, lng: 22.1825296 },
    { lat: 48.652449, lng: 22.1831506 },
    { lat: 48.6524768, lng: 22.1836735 },
    { lat: 48.6533746, lng: 22.1836298 },
    { lat: 48.6563289, lng: 22.1841925 },
    { lat: 48.6561367, lng: 22.1845921 },
    { lat: 48.6579832, lng: 22.1846712 },
    { lat: 48.6585202, lng: 22.1845368 },
    { lat: 48.6604872, lng: 22.1853119 },
    { lat: 48.6606399, lng: 22.1852481 },
    { lat: 48.6607583, lng: 22.1847669 },
    { lat: 48.660929, lng: 22.1845107 },
    { lat: 48.6610845, lng: 22.1839856 },
    { lat: 48.6613807, lng: 22.183414 },
    { lat: 48.6617007, lng: 22.183429 },
    { lat: 48.6619039, lng: 22.1836169 },
    { lat: 48.662026, lng: 22.1835973 },
    { lat: 48.6622822, lng: 22.1829866 },
    { lat: 48.6623488, lng: 22.1826547 },
    { lat: 48.6621952, lng: 22.1819884 },
    { lat: 48.6619298, lng: 22.1815586 },
    { lat: 48.6619296, lng: 22.1806647 },
    { lat: 48.6619949, lng: 22.1797825 },
    { lat: 48.6619604, lng: 22.1791641 },
    { lat: 48.66184, lng: 22.1786715 },
    { lat: 48.6618558, lng: 22.177932 },
    { lat: 48.6621204, lng: 22.1773617 },
    { lat: 48.6622352, lng: 22.1760423 },
    { lat: 48.6622439, lng: 22.1753409 },
    { lat: 48.6625033, lng: 22.174885 },
    { lat: 48.663177, lng: 22.1743847 },
    { lat: 48.6634183, lng: 22.173776 },
    { lat: 48.6637387, lng: 22.1731842 },
    { lat: 48.6637812, lng: 22.1728795 },
    { lat: 48.6636429, lng: 22.1722986 },
    { lat: 48.6636227, lng: 22.1710745 },
    { lat: 48.663749, lng: 22.1706515 },
    { lat: 48.664369, lng: 22.1695796 },
    { lat: 48.6650027, lng: 22.1682874 },
    { lat: 48.6677043, lng: 22.1683224 },
    { lat: 48.6684033, lng: 22.1673149 },
    { lat: 48.668894, lng: 22.1686912 },
    { lat: 48.6689851, lng: 22.1687879 },
    { lat: 48.6690757, lng: 22.1687746 },
    { lat: 48.6759351, lng: 22.1639867 },
    { lat: 48.6753933, lng: 22.1619068 },
    { lat: 48.6783457, lng: 22.1597994 },
    { lat: 48.6781804, lng: 22.1595646 },
    { lat: 48.6781178, lng: 22.1593839 },
    { lat: 48.6780341, lng: 22.1590267 },
    { lat: 48.678028, lng: 22.1586084 },
    { lat: 48.6779132, lng: 22.1581222 },
    { lat: 48.6779418, lng: 22.1570757 },
    { lat: 48.6780633, lng: 22.1567849 },
    { lat: 48.6781295, lng: 22.1563859 },
    { lat: 48.6780556, lng: 22.1555242 },
    { lat: 48.6778277, lng: 22.1552102 },
    { lat: 48.6777664, lng: 22.1550372 },
    { lat: 48.6777952, lng: 22.1547211 },
    { lat: 48.6777902, lng: 22.1546569 },
    { lat: 48.6776137, lng: 22.1535393 },
    { lat: 48.6771484, lng: 22.1529297 },
    { lat: 48.6765898, lng: 22.1510614 },
    { lat: 48.6766161, lng: 22.1509254 },
    { lat: 48.6763765, lng: 22.1468061 },
    { lat: 48.6767302, lng: 22.1442657 },
    { lat: 48.6769614, lng: 22.1436651 },
    { lat: 48.6765036, lng: 22.1425984 },
    { lat: 48.676395, lng: 22.1421358 },
    { lat: 48.6759722, lng: 22.1408416 },
    { lat: 48.6744293, lng: 22.1370931 },
    { lat: 48.6744046, lng: 22.136851 },
  ],
  Husák: [
    { lat: 48.6999674, lng: 22.2562709 },
    { lat: 48.6997863, lng: 22.2564144 },
    { lat: 48.698785, lng: 22.2572144 },
    { lat: 48.6982896, lng: 22.2581511 },
    { lat: 48.697974, lng: 22.2590409 },
    { lat: 48.6959696, lng: 22.2599939 },
    { lat: 48.6953888, lng: 22.2603861 },
    { lat: 48.694416, lng: 22.2614523 },
    { lat: 48.694155, lng: 22.2615538 },
    { lat: 48.6937771, lng: 22.2619027 },
    { lat: 48.693193, lng: 22.2628533 },
    { lat: 48.6926903, lng: 22.2633142 },
    { lat: 48.6922257, lng: 22.2634054 },
    { lat: 48.6914955, lng: 22.2637564 },
    { lat: 48.6904578, lng: 22.2648965 },
    { lat: 48.6901552, lng: 22.2651967 },
    { lat: 48.6897467, lng: 22.2654922 },
    { lat: 48.6896685, lng: 22.265772 },
    { lat: 48.6893745, lng: 22.2660546 },
    { lat: 48.6893926, lng: 22.2663068 },
    { lat: 48.6890926, lng: 22.2666823 },
    { lat: 48.6883575, lng: 22.2671627 },
    { lat: 48.6875339, lng: 22.268169 },
    { lat: 48.6873304, lng: 22.2685246 },
    { lat: 48.6867581, lng: 22.2691402 },
    { lat: 48.6864635, lng: 22.2699624 },
    { lat: 48.6857814, lng: 22.2712613 },
    { lat: 48.6855703, lng: 22.2715325 },
    { lat: 48.6849879, lng: 22.2720012 },
    { lat: 48.6847236, lng: 22.2723036 },
    { lat: 48.6841267, lng: 22.2733358 },
    { lat: 48.683965, lng: 22.2738035 },
    { lat: 48.6822952, lng: 22.2757582 },
    { lat: 48.6818457, lng: 22.276087 },
    { lat: 48.6815445, lng: 22.2764499 },
    { lat: 48.6811864, lng: 22.277303 },
    { lat: 48.6806619, lng: 22.278101 },
    { lat: 48.6803745, lng: 22.27879 },
    { lat: 48.6801745, lng: 22.2789973 },
    { lat: 48.6799172, lng: 22.279064 },
    { lat: 48.6793234, lng: 22.2783041 },
    { lat: 48.6791612, lng: 22.2783959 },
    { lat: 48.6791423, lng: 22.2782459 },
    { lat: 48.6785133, lng: 22.2776302 },
    { lat: 48.6783218, lng: 22.2776167 },
    { lat: 48.6781752, lng: 22.2774468 },
    { lat: 48.6778599, lng: 22.2772776 },
    { lat: 48.6778541, lng: 22.2767052 },
    { lat: 48.6775666, lng: 22.2763236 },
    { lat: 48.6773106, lng: 22.2763956 },
    { lat: 48.6772039, lng: 22.2763472 },
    { lat: 48.6771443, lng: 22.2761965 },
    { lat: 48.6770473, lng: 22.2763832 },
    { lat: 48.6770542, lng: 22.276496 },
    { lat: 48.6769662, lng: 22.2764902 },
    { lat: 48.6764434, lng: 22.2757855 },
    { lat: 48.676285, lng: 22.2759436 },
    { lat: 48.6762116, lng: 22.2758772 },
    { lat: 48.6761813, lng: 22.2751673 },
    { lat: 48.676082, lng: 22.2749509 },
    { lat: 48.6756794, lng: 22.2745032 },
    { lat: 48.6756067, lng: 22.2742072 },
    { lat: 48.6754345, lng: 22.2741052 },
    { lat: 48.6754492, lng: 22.2736009 },
    { lat: 48.6753967, lng: 22.2734647 },
    { lat: 48.6752433, lng: 22.2733917 },
    { lat: 48.6751321, lng: 22.2732425 },
    { lat: 48.6751197, lng: 22.2730979 },
    { lat: 48.6750451, lng: 22.2729778 },
    { lat: 48.6748788, lng: 22.2729801 },
    { lat: 48.6745565, lng: 22.272496 },
    { lat: 48.6696318, lng: 22.281905 },
    { lat: 48.6689325, lng: 22.2832422 },
    { lat: 48.6684877, lng: 22.2839382 },
    { lat: 48.6664617, lng: 22.2846338 },
    { lat: 48.664284, lng: 22.286316 },
    { lat: 48.666295, lng: 22.289302 },
    { lat: 48.667077, lng: 22.289072 },
    { lat: 48.667998, lng: 22.289101 },
    { lat: 48.668611, lng: 22.2898 },
    { lat: 48.669323, lng: 22.290805 },
    { lat: 48.669807, lng: 22.293013 },
    { lat: 48.670663, lng: 22.295704 },
    { lat: 48.671785, lng: 22.297544 },
    { lat: 48.672254, lng: 22.299108 },
    { lat: 48.673194, lng: 22.301381 },
    { lat: 48.674253, lng: 22.302507 },
    { lat: 48.676376, lng: 22.302546 },
    { lat: 48.676494, lng: 22.304734 },
    { lat: 48.676818, lng: 22.30708 },
    { lat: 48.677632, lng: 22.308293 },
    { lat: 48.678853, lng: 22.309752 },
    { lat: 48.679151, lng: 22.311419 },
    { lat: 48.679154, lng: 22.312493 },
    { lat: 48.679183, lng: 22.313604 },
    { lat: 48.6795387, lng: 22.3134153 },
    { lat: 48.680419, lng: 22.3133937 },
    { lat: 48.6813125, lng: 22.3132632 },
    { lat: 48.6835011, lng: 22.3123714 },
    { lat: 48.6840381, lng: 22.3122902 },
    { lat: 48.6879367, lng: 22.3120388 },
    { lat: 48.6932311, lng: 22.3135462 },
    { lat: 48.6944617, lng: 22.3133967 },
    { lat: 48.6961944, lng: 22.311821 },
    { lat: 48.6964306, lng: 22.3113998 },
    { lat: 48.6968922, lng: 22.3111134 },
    { lat: 48.6970806, lng: 22.3108377 },
    { lat: 48.6976479, lng: 22.3102306 },
    { lat: 48.6983019, lng: 22.3097405 },
    { lat: 48.6986925, lng: 22.3089762 },
    { lat: 48.6993677, lng: 22.3081205 },
    { lat: 48.7027822, lng: 22.3075364 },
    { lat: 48.7036988, lng: 22.307689 },
    { lat: 48.7045921, lng: 22.3081616 },
    { lat: 48.7058649, lng: 22.3081456 },
    { lat: 48.7066779, lng: 22.3086047 },
    { lat: 48.7074981, lng: 22.3086106 },
    { lat: 48.7097324, lng: 22.3069897 },
    { lat: 48.7120628, lng: 22.309555 },
    { lat: 48.7134101, lng: 22.3097102 },
    { lat: 48.7137047, lng: 22.3098056 },
    { lat: 48.714337, lng: 22.3096619 },
    { lat: 48.7154113, lng: 22.3101833 },
    { lat: 48.7164747, lng: 22.3111875 },
    { lat: 48.7169732, lng: 22.311556 },
    { lat: 48.7172838, lng: 22.3119341 },
    { lat: 48.7179629, lng: 22.3119149 },
    { lat: 48.7186134, lng: 22.3120326 },
    { lat: 48.7198761, lng: 22.3128492 },
    { lat: 48.7205607, lng: 22.3143846 },
    { lat: 48.7206781, lng: 22.315067 },
    { lat: 48.7210982, lng: 22.3161336 },
    { lat: 48.7219582, lng: 22.3169352 },
    { lat: 48.7223421, lng: 22.31778 },
    { lat: 48.7225516, lng: 22.3179505 },
    { lat: 48.7229779, lng: 22.3178531 },
    { lat: 48.7231447, lng: 22.3168268 },
    { lat: 48.7230955, lng: 22.3167875 },
    { lat: 48.7229486, lng: 22.3167638 },
    { lat: 48.7226905, lng: 22.3163168 },
    { lat: 48.7224056, lng: 22.3160498 },
    { lat: 48.7223484, lng: 22.3159031 },
    { lat: 48.7223968, lng: 22.3157507 },
    { lat: 48.7223793, lng: 22.3155656 },
    { lat: 48.7222152, lng: 22.3150555 },
    { lat: 48.7220393, lng: 22.3149766 },
    { lat: 48.7218244, lng: 22.3145128 },
    { lat: 48.7217837, lng: 22.3141899 },
    { lat: 48.7218197, lng: 22.3139801 },
    { lat: 48.7216293, lng: 22.3132451 },
    { lat: 48.7217262, lng: 22.3122728 },
    { lat: 48.7216485, lng: 22.3120066 },
    { lat: 48.7215436, lng: 22.311895 },
    { lat: 48.7214087, lng: 22.3114273 },
    { lat: 48.721445, lng: 22.3107786 },
    { lat: 48.7206458, lng: 22.3098722 },
    { lat: 48.7203674, lng: 22.3096827 },
    { lat: 48.7202089, lng: 22.3092785 },
    { lat: 48.7196603, lng: 22.3090347 },
    { lat: 48.7196163, lng: 22.3089172 },
    { lat: 48.7195757, lng: 22.3079336 },
    { lat: 48.7193187, lng: 22.3077014 },
    { lat: 48.7190058, lng: 22.3072842 },
    { lat: 48.7186153, lng: 22.3064958 },
    { lat: 48.7184874, lng: 22.3060484 },
    { lat: 48.7182924, lng: 22.3057713 },
    { lat: 48.7183909, lng: 22.305438 },
    { lat: 48.7183536, lng: 22.3047674 },
    { lat: 48.7181216, lng: 22.3043929 },
    { lat: 48.7181486, lng: 22.3041308 },
    { lat: 48.7177868, lng: 22.3033762 },
    { lat: 48.7176734, lng: 22.3034378 },
    { lat: 48.7173658, lng: 22.302951 },
    { lat: 48.7170023, lng: 22.3017075 },
    { lat: 48.7168869, lng: 22.3014664 },
    { lat: 48.716504, lng: 22.3016536 },
    { lat: 48.7164092, lng: 22.3015327 },
    { lat: 48.7161346, lng: 22.3016276 },
    { lat: 48.7159052, lng: 22.3013783 },
    { lat: 48.7157223, lng: 22.3008275 },
    { lat: 48.7155849, lng: 22.3005694 },
    { lat: 48.7148067, lng: 22.3004223 },
    { lat: 48.7146367, lng: 22.3001032 },
    { lat: 48.7142978, lng: 22.3004424 },
    { lat: 48.7141072, lng: 22.3002162 },
    { lat: 48.7139529, lng: 22.2996946 },
    { lat: 48.7140884, lng: 22.2995105 },
    { lat: 48.7137014, lng: 22.2985294 },
    { lat: 48.7135969, lng: 22.2978689 },
    { lat: 48.7134424, lng: 22.2973339 },
    { lat: 48.7134036, lng: 22.2969504 },
    { lat: 48.713326, lng: 22.2968425 },
    { lat: 48.7129147, lng: 22.2955275 },
    { lat: 48.712711, lng: 22.2952702 },
    { lat: 48.7126499, lng: 22.2949951 },
    { lat: 48.7122932, lng: 22.2945325 },
    { lat: 48.7117498, lng: 22.2940084 },
    { lat: 48.7116033, lng: 22.2939919 },
    { lat: 48.7111842, lng: 22.2934006 },
    { lat: 48.7108277, lng: 22.2928351 },
    { lat: 48.7109319, lng: 22.2927188 },
    { lat: 48.7109472, lng: 22.2924395 },
    { lat: 48.7107339, lng: 22.2919434 },
    { lat: 48.7099206, lng: 22.2911726 },
    { lat: 48.7097827, lng: 22.2908305 },
    { lat: 48.7094295, lng: 22.2902235 },
    { lat: 48.7096298, lng: 22.2899649 },
    { lat: 48.7102676, lng: 22.289461 },
    { lat: 48.7097396, lng: 22.2879554 },
    { lat: 48.7096862, lng: 22.2873156 },
    { lat: 48.7090458, lng: 22.28392 },
    { lat: 48.7089375, lng: 22.2835512 },
    { lat: 48.7085921, lng: 22.2826259 },
    { lat: 48.7085049, lng: 22.2822442 },
    { lat: 48.7082521, lng: 22.282149 },
    { lat: 48.7074924, lng: 22.2820573 },
    { lat: 48.707383, lng: 22.2814524 },
    { lat: 48.706535, lng: 22.2807878 },
    { lat: 48.7075049, lng: 22.2781624 },
    { lat: 48.7076218, lng: 22.2776922 },
    { lat: 48.7076141, lng: 22.2775013 },
    { lat: 48.7073587, lng: 22.2775911 },
    { lat: 48.7071087, lng: 22.2774762 },
    { lat: 48.7069064, lng: 22.2770783 },
    { lat: 48.7066607, lng: 22.2764814 },
    { lat: 48.7069818, lng: 22.2732597 },
    { lat: 48.7063672, lng: 22.2716127 },
    { lat: 48.7059554, lng: 22.2699808 },
    { lat: 48.705213, lng: 22.2676933 },
    { lat: 48.703994, lng: 22.2651644 },
    { lat: 48.7028502, lng: 22.2633517 },
    { lat: 48.7022451, lng: 22.2621423 },
    { lat: 48.7020282, lng: 22.261498 },
    { lat: 48.7014361, lng: 22.2594933 },
    { lat: 48.700502, lng: 22.2573412 },
    { lat: 48.6999674, lng: 22.2562709 },
  ],
  Sejkov: [
    { lat: 48.7059801, lng: 22.2020366 },
    { lat: 48.700833, lng: 22.1991233 },
    { lat: 48.6991533, lng: 22.1984155 },
    { lat: 48.6989731, lng: 22.198454 },
    { lat: 48.6976603, lng: 22.1983947 },
    { lat: 48.6962983, lng: 22.1986492 },
    { lat: 48.6960807, lng: 22.1991732 },
    { lat: 48.6956727, lng: 22.1992389 },
    { lat: 48.6956441, lng: 22.1989235 },
    { lat: 48.6945384, lng: 22.1992135 },
    { lat: 48.6945584, lng: 22.1994001 },
    { lat: 48.6905778, lng: 22.2002988 },
    { lat: 48.6875574, lng: 22.1991459 },
    { lat: 48.6870395, lng: 22.1991358 },
    { lat: 48.6828994, lng: 22.1995935 },
    { lat: 48.6828568, lng: 22.1995985 },
    { lat: 48.6833397, lng: 22.2018833 },
    { lat: 48.6826092, lng: 22.2017689 },
    { lat: 48.6804166, lng: 22.2097778 },
    { lat: 48.6797967, lng: 22.2090816 },
    { lat: 48.6786096, lng: 22.2134761 },
    { lat: 48.6784607, lng: 22.213907 },
    { lat: 48.680385, lng: 22.2154792 },
    { lat: 48.6796493, lng: 22.2178222 },
    { lat: 48.6792188, lng: 22.2176301 },
    { lat: 48.6772162, lng: 22.2172208 },
    { lat: 48.6772847, lng: 22.2175639 },
    { lat: 48.6772407, lng: 22.2180954 },
    { lat: 48.6759192, lng: 22.2208984 },
    { lat: 48.6756659, lng: 22.2206943 },
    { lat: 48.6756126, lng: 22.2208188 },
    { lat: 48.6753144, lng: 22.2205665 },
    { lat: 48.6752814, lng: 22.220702 },
    { lat: 48.6751965, lng: 22.2206231 },
    { lat: 48.6743218, lng: 22.2219807 },
    { lat: 48.6737662, lng: 22.2224937 },
    { lat: 48.6738904, lng: 22.2230581 },
    { lat: 48.6739388, lng: 22.2231133 },
    { lat: 48.6738317, lng: 22.2235985 },
    { lat: 48.6738196, lng: 22.2238586 },
    { lat: 48.6739346, lng: 22.2251222 },
    { lat: 48.673427, lng: 22.2252504 },
    { lat: 48.6735031, lng: 22.2256369 },
    { lat: 48.6736574, lng: 22.2275359 },
    { lat: 48.6726783, lng: 22.2278207 },
    { lat: 48.6726758, lng: 22.2280005 },
    { lat: 48.6721506, lng: 22.2279577 },
    { lat: 48.6720669, lng: 22.2295365 },
    { lat: 48.6715932, lng: 22.2295291 },
    { lat: 48.6716245, lng: 22.2309756 },
    { lat: 48.671529, lng: 22.2328798 },
    { lat: 48.6714278, lng: 22.2328754 },
    { lat: 48.6714218, lng: 22.2331123 },
    { lat: 48.6712994, lng: 22.23312 },
    { lat: 48.6712933, lng: 22.2332993 },
    { lat: 48.6711687, lng: 22.2333136 },
    { lat: 48.6711548, lng: 22.2356524 },
    { lat: 48.6718075, lng: 22.2354688 },
    { lat: 48.6717523, lng: 22.2350143 },
    { lat: 48.6728628, lng: 22.2348186 },
    { lat: 48.6732005, lng: 22.2379898 },
    { lat: 48.6731984, lng: 22.2388508 },
    { lat: 48.6738354, lng: 22.2389627 },
    { lat: 48.673894, lng: 22.2395758 },
    { lat: 48.6742445, lng: 22.2391592 },
    { lat: 48.6764105, lng: 22.2358766 },
    { lat: 48.6785597, lng: 22.2334613 },
    { lat: 48.6788452, lng: 22.2329505 },
    { lat: 48.6795594, lng: 22.2321991 },
    { lat: 48.6798317, lng: 22.2322019 },
    { lat: 48.6806506, lng: 22.2309992 },
    { lat: 48.681006, lng: 22.2306514 },
    { lat: 48.6812922, lng: 22.2304758 },
    { lat: 48.6813412, lng: 22.2307043 },
    { lat: 48.6823069, lng: 22.2304209 },
    { lat: 48.6827123, lng: 22.2302025 },
    { lat: 48.6841753, lng: 22.2306326 },
    { lat: 48.6849577, lng: 22.2309584 },
    { lat: 48.685681, lng: 22.2350677 },
    { lat: 48.6870239, lng: 22.2345091 },
    { lat: 48.688141, lng: 22.2336192 },
    { lat: 48.6884526, lng: 22.2332199 },
    { lat: 48.6974928, lng: 22.2257221 },
    { lat: 48.6977354, lng: 22.2257014 },
    { lat: 48.6982927, lng: 22.2275799 },
    { lat: 48.6985387, lng: 22.2280611 },
    { lat: 48.698632, lng: 22.2291064 },
    { lat: 48.6987524, lng: 22.2293121 },
    { lat: 48.699425, lng: 22.2298113 },
    { lat: 48.6994895, lng: 22.2301327 },
    { lat: 48.7017863, lng: 22.2284712 },
    { lat: 48.7015869, lng: 22.2265664 },
    { lat: 48.702408, lng: 22.2264583 },
    { lat: 48.7031917, lng: 22.2261979 },
    { lat: 48.7037747, lng: 22.2236692 },
    { lat: 48.7033214, lng: 22.2231227 },
    { lat: 48.7028346, lng: 22.2226791 },
    { lat: 48.7027277, lng: 22.2223285 },
    { lat: 48.7022809, lng: 22.2224167 },
    { lat: 48.7020128, lng: 22.2220983 },
    { lat: 48.7018753, lng: 22.2218874 },
    { lat: 48.7019004, lng: 22.2212548 },
    { lat: 48.701864, lng: 22.220939 },
    { lat: 48.7031053, lng: 22.2207205 },
    { lat: 48.7035537, lng: 22.2207038 },
    { lat: 48.7035539, lng: 22.2207676 },
    { lat: 48.704283, lng: 22.2214155 },
    { lat: 48.7048961, lng: 22.21396 },
    { lat: 48.7055895, lng: 22.2082672 },
    { lat: 48.7059363, lng: 22.2083828 },
    { lat: 48.7064252, lng: 22.2058411 },
    { lat: 48.7061178, lng: 22.2056188 },
    { lat: 48.7055453, lng: 22.2050281 },
    { lat: 48.7057743, lng: 22.2034269 },
    { lat: 48.7059801, lng: 22.2020366 },
  ],
  VyšnéRemety: [
    { lat: 48.831154, lng: 22.175948 },
    { lat: 48.8316947, lng: 22.176228 },
    { lat: 48.831704, lng: 22.1759934 },
    { lat: 48.8320023, lng: 22.1747256 },
    { lat: 48.8323213, lng: 22.1749031 },
    { lat: 48.832575, lng: 22.1742407 },
    { lat: 48.8327127, lng: 22.1742624 },
    { lat: 48.8331049, lng: 22.1745892 },
    { lat: 48.8335709, lng: 22.1743576 },
    { lat: 48.8341537, lng: 22.174961 },
    { lat: 48.8352019, lng: 22.1762788 },
    { lat: 48.8359078, lng: 22.1765073 },
    { lat: 48.8360664, lng: 22.1762562 },
    { lat: 48.8335845, lng: 22.1737271 },
    { lat: 48.834105, lng: 22.1708199 },
    { lat: 48.834248, lng: 22.170776 },
    { lat: 48.8346767, lng: 22.1709277 },
    { lat: 48.8357096, lng: 22.1706551 },
    { lat: 48.8365048, lng: 22.1709493 },
    { lat: 48.8366833, lng: 22.1713219 },
    { lat: 48.8371556, lng: 22.1704359 },
    { lat: 48.8376904, lng: 22.1708497 },
    { lat: 48.8379363, lng: 22.1712724 },
    { lat: 48.838376, lng: 22.1709286 },
    { lat: 48.8387512, lng: 22.1707625 },
    { lat: 48.8391749, lng: 22.1711794 },
    { lat: 48.8393825, lng: 22.1716123 },
    { lat: 48.839808, lng: 22.1712228 },
    { lat: 48.8428963, lng: 22.1717073 },
    { lat: 48.8452206, lng: 22.1719775 },
    { lat: 48.8454311, lng: 22.1715346 },
    { lat: 48.8453262, lng: 22.1715255 },
    { lat: 48.8453071, lng: 22.1708891 },
    { lat: 48.8447974, lng: 22.1708816 },
    { lat: 48.8447195, lng: 22.1694977 },
    { lat: 48.8436903, lng: 22.1683124 },
    { lat: 48.8436555, lng: 22.1679775 },
    { lat: 48.8433728, lng: 22.1675199 },
    { lat: 48.8416668, lng: 22.1667504 },
    { lat: 48.8409981, lng: 22.1626567 },
    { lat: 48.8385246, lng: 22.1590899 },
    { lat: 48.8379906, lng: 22.1598808 },
    { lat: 48.8380012, lng: 22.1593715 },
    { lat: 48.8375722, lng: 22.1585183 },
    { lat: 48.8374839, lng: 22.1584915 },
    { lat: 48.8367357, lng: 22.1574046 },
    { lat: 48.8338833, lng: 22.1548738 },
    { lat: 48.834001, lng: 22.151686 },
    { lat: 48.834003, lng: 22.151551 },
    { lat: 48.833673, lng: 22.150775 },
    { lat: 48.833259, lng: 22.150435 },
    { lat: 48.832999, lng: 22.150255 },
    { lat: 48.833024, lng: 22.150154 },
    { lat: 48.832745, lng: 22.150001 },
    { lat: 48.832626, lng: 22.149936 },
    { lat: 48.832363, lng: 22.149792 },
    { lat: 48.83224, lng: 22.150451 },
    { lat: 48.832164, lng: 22.150414 },
    { lat: 48.831052, lng: 22.149872 },
    { lat: 48.831041, lng: 22.14934 },
    { lat: 48.830992, lng: 22.149198 },
    { lat: 48.830994, lng: 22.148858 },
    { lat: 48.830996, lng: 22.14874 },
    { lat: 48.830951, lng: 22.148647 },
    { lat: 48.830695, lng: 22.14863 },
    { lat: 48.830291, lng: 22.148405 },
    { lat: 48.830016, lng: 22.148422 },
    { lat: 48.830023, lng: 22.148603 },
    { lat: 48.830018, lng: 22.148646 },
    { lat: 48.829991, lng: 22.148709 },
    { lat: 48.82997, lng: 22.149045 },
    { lat: 48.829902, lng: 22.149372 },
    { lat: 48.829689, lng: 22.150151 },
    { lat: 48.829372, lng: 22.150079 },
    { lat: 48.829235, lng: 22.150215 },
    { lat: 48.82831, lng: 22.149904 },
    { lat: 48.828164, lng: 22.150245 },
    { lat: 48.827552, lng: 22.149901 },
    { lat: 48.827477, lng: 22.150836 },
    { lat: 48.827521, lng: 22.150857 },
    { lat: 48.827583, lng: 22.150888 },
    { lat: 48.827641, lng: 22.151109 },
    { lat: 48.827382, lng: 22.151752 },
    { lat: 48.827224, lng: 22.151788 },
    { lat: 48.826968, lng: 22.151705 },
    { lat: 48.826888, lng: 22.151869 },
    { lat: 48.826762, lng: 22.151837 },
    { lat: 48.826517, lng: 22.152476 },
    { lat: 48.826276, lng: 22.152605 },
    { lat: 48.825583, lng: 22.152643 },
    { lat: 48.825218, lng: 22.152636 },
    { lat: 48.825021, lng: 22.152915 },
    { lat: 48.824932, lng: 22.153039 },
    { lat: 48.824905, lng: 22.153079 },
    { lat: 48.824886, lng: 22.153105 },
    { lat: 48.824634, lng: 22.153079 },
    { lat: 48.823777, lng: 22.15299 },
    { lat: 48.822537, lng: 22.152835 },
    { lat: 48.822494, lng: 22.153025 },
    { lat: 48.822143, lng: 22.152927 },
    { lat: 48.822123, lng: 22.152984 },
    { lat: 48.822042, lng: 22.153504 },
    { lat: 48.821894, lng: 22.153653 },
    { lat: 48.821648, lng: 22.153702 },
    { lat: 48.821402, lng: 22.153674 },
    { lat: 48.820822, lng: 22.153785 },
    { lat: 48.820771, lng: 22.152362 },
    { lat: 48.820941, lng: 22.152317 },
    { lat: 48.821007, lng: 22.152056 },
    { lat: 48.82085, lng: 22.152087 },
    { lat: 48.820918, lng: 22.151149 },
    { lat: 48.82066, lng: 22.151084 },
    { lat: 48.820443, lng: 22.151101 },
    { lat: 48.820314, lng: 22.151005 },
    { lat: 48.819766, lng: 22.150701 },
    { lat: 48.819827, lng: 22.150312 },
    { lat: 48.819615, lng: 22.150159 },
    { lat: 48.819383, lng: 22.150057 },
    { lat: 48.81927, lng: 22.150091 },
    { lat: 48.819096, lng: 22.149975 },
    { lat: 48.819104, lng: 22.149788 },
    { lat: 48.8188, lng: 22.14985 },
    { lat: 48.8179, lng: 22.149929 },
    { lat: 48.817613, lng: 22.14994 },
    { lat: 48.817312, lng: 22.149864 },
    { lat: 48.817177, lng: 22.149788 },
    { lat: 48.817194, lng: 22.149648 },
    { lat: 48.817237, lng: 22.149148 },
    { lat: 48.817236, lng: 22.14914 },
    { lat: 48.817264, lng: 22.148815 },
    { lat: 48.816912, lng: 22.148731 },
    { lat: 48.816545, lng: 22.148516 },
    { lat: 48.816278, lng: 22.150072 },
    { lat: 48.815543, lng: 22.150123 },
    { lat: 48.815724, lng: 22.149103 },
    { lat: 48.815898, lng: 22.148276 },
    { lat: 48.81598, lng: 22.147668 },
    { lat: 48.815996, lng: 22.147545 },
    { lat: 48.81601, lng: 22.147438 },
    { lat: 48.816025, lng: 22.147332 },
    { lat: 48.815256, lng: 22.146926 },
    { lat: 48.81513, lng: 22.146973 },
    { lat: 48.81454, lng: 22.146872 },
    { lat: 48.81455, lng: 22.146823 },
    { lat: 48.814685, lng: 22.146263 },
    { lat: 48.814433, lng: 22.146064 },
    { lat: 48.814269, lng: 22.146022 },
    { lat: 48.814284, lng: 22.145847 },
    { lat: 48.813968, lng: 22.145604 },
    { lat: 48.813821, lng: 22.146787 },
    { lat: 48.813813, lng: 22.146781 },
    { lat: 48.813805, lng: 22.147036 },
    { lat: 48.813529, lng: 22.147003 },
    { lat: 48.813195, lng: 22.146964 },
    { lat: 48.811285, lng: 22.145674 },
    { lat: 48.81129, lng: 22.145696 },
    { lat: 48.810648, lng: 22.145222 },
    { lat: 48.810359, lng: 22.144782 },
    { lat: 48.810291, lng: 22.143821 },
    { lat: 48.810337, lng: 22.143692 },
    { lat: 48.810292, lng: 22.143322 },
    { lat: 48.81032, lng: 22.142695 },
    { lat: 48.810416, lng: 22.141666 },
    { lat: 48.810346, lng: 22.141457 },
    { lat: 48.8102062, lng: 22.1414558 },
    { lat: 48.8101242, lng: 22.1417528 },
    { lat: 48.8099476, lng: 22.1417989 },
    { lat: 48.8098525, lng: 22.1422097 },
    { lat: 48.8097163, lng: 22.1422541 },
    { lat: 48.8096912, lng: 22.1427768 },
    { lat: 48.8094698, lng: 22.1427322 },
    { lat: 48.8094657, lng: 22.1430414 },
    { lat: 48.8093686, lng: 22.1429745 },
    { lat: 48.80894, lng: 22.1422982 },
    { lat: 48.8087705, lng: 22.1434756 },
    { lat: 48.8077083, lng: 22.1453534 },
    { lat: 48.80685, lng: 22.1484496 },
    { lat: 48.8066726, lng: 22.1503617 },
    { lat: 48.8063561, lng: 22.1520459 },
    { lat: 48.8058811, lng: 22.153396 },
    { lat: 48.8053667, lng: 22.1540174 },
    { lat: 48.8062746, lng: 22.1560672 },
    { lat: 48.8065706, lng: 22.1577829 },
    { lat: 48.806487, lng: 22.1577627 },
    { lat: 48.8064696, lng: 22.1578871 },
    { lat: 48.8079791, lng: 22.1594973 },
    { lat: 48.810838, lng: 22.1627147 },
    { lat: 48.8107911, lng: 22.1624785 },
    { lat: 48.8138641, lng: 22.165757 },
    { lat: 48.8155241, lng: 22.1667256 },
    { lat: 48.821392, lng: 22.1711305 },
    { lat: 48.8310939, lng: 22.1759187 },
    { lat: 48.831154, lng: 22.175948 },
  ],
  Baškovce: [
    { lat: 48.7834995, lng: 22.1756171 },
    { lat: 48.7830603, lng: 22.1758941 },
    { lat: 48.7828077, lng: 22.1761125 },
    { lat: 48.7822329, lng: 22.1759094 },
    { lat: 48.782052, lng: 22.1757298 },
    { lat: 48.7816038, lng: 22.1758486 },
    { lat: 48.7815356, lng: 22.1761607 },
    { lat: 48.7805712, lng: 22.1763903 },
    { lat: 48.7808438, lng: 22.1779724 },
    { lat: 48.7809108, lng: 22.1793858 },
    { lat: 48.781557, lng: 22.1817198 },
    { lat: 48.7815508, lng: 22.1820354 },
    { lat: 48.7800361, lng: 22.1817538 },
    { lat: 48.7791509, lng: 22.1823972 },
    { lat: 48.779122, lng: 22.181679 },
    { lat: 48.7789973, lng: 22.1816277 },
    { lat: 48.7788856, lng: 22.181711 },
    { lat: 48.7786579, lng: 22.1814019 },
    { lat: 48.7786551, lng: 22.1815248 },
    { lat: 48.7777959, lng: 22.1812324 },
    { lat: 48.7775352, lng: 22.1806062 },
    { lat: 48.7771056, lng: 22.1804133 },
    { lat: 48.7771111, lng: 22.1800657 },
    { lat: 48.7768648, lng: 22.1798707 },
    { lat: 48.7767491, lng: 22.1792844 },
    { lat: 48.776617, lng: 22.1793099 },
    { lat: 48.7763961, lng: 22.1799273 },
    { lat: 48.7760674, lng: 22.1799709 },
    { lat: 48.7758651, lng: 22.1804651 },
    { lat: 48.7751868, lng: 22.1807642 },
    { lat: 48.7751374, lng: 22.181643 },
    { lat: 48.7754501, lng: 22.1825809 },
    { lat: 48.7754762, lng: 22.1833819 },
    { lat: 48.7754016, lng: 22.1847484 },
    { lat: 48.7757493, lng: 22.1868642 },
    { lat: 48.7759706, lng: 22.1876476 },
    { lat: 48.7759623, lng: 22.1896936 },
    { lat: 48.7755432, lng: 22.1937684 },
    { lat: 48.7752316, lng: 22.1952467 },
    { lat: 48.7749223, lng: 22.1946031 },
    { lat: 48.774444, lng: 22.1942479 },
    { lat: 48.7737889, lng: 22.195901 },
    { lat: 48.7737002, lng: 22.1970471 },
    { lat: 48.7732793, lng: 22.2007016 },
    { lat: 48.7734184, lng: 22.2010346 },
    { lat: 48.7734901, lng: 22.2010697 },
    { lat: 48.7736549, lng: 22.2009474 },
    { lat: 48.7738854, lng: 22.2010895 },
    { lat: 48.774088, lng: 22.2010979 },
    { lat: 48.7743062, lng: 22.2011981 },
    { lat: 48.7743842, lng: 22.2017065 },
    { lat: 48.7742911, lng: 22.2017142 },
    { lat: 48.774178, lng: 22.2026854 },
    { lat: 48.7743367, lng: 22.2034339 },
    { lat: 48.7743247, lng: 22.2037469 },
    { lat: 48.7743832, lng: 22.2043957 },
    { lat: 48.7746877, lng: 22.2051734 },
    { lat: 48.7747475, lng: 22.2055233 },
    { lat: 48.7750304, lng: 22.2059533 },
    { lat: 48.7747494, lng: 22.2071048 },
    { lat: 48.7753114, lng: 22.2085755 },
    { lat: 48.7754139, lng: 22.2087321 },
    { lat: 48.7753528, lng: 22.2095095 },
    { lat: 48.7765307, lng: 22.2119009 },
    { lat: 48.7760889, lng: 22.2131563 },
    { lat: 48.7772197, lng: 22.2151676 },
    { lat: 48.7783734, lng: 22.2164004 },
    { lat: 48.780395, lng: 22.214772 },
    { lat: 48.7816745, lng: 22.2142054 },
    { lat: 48.7822713, lng: 22.2147252 },
    { lat: 48.7828942, lng: 22.2131804 },
    { lat: 48.783344, lng: 22.2130705 },
    { lat: 48.7834863, lng: 22.2131483 },
    { lat: 48.7835146, lng: 22.2135581 },
    { lat: 48.7832338, lng: 22.2140245 },
    { lat: 48.7833258, lng: 22.2144043 },
    { lat: 48.7837325, lng: 22.2146889 },
    { lat: 48.7834497, lng: 22.2150163 },
    { lat: 48.7835014, lng: 22.2152045 },
    { lat: 48.7838579, lng: 22.2153062 },
    { lat: 48.7840899, lng: 22.2155193 },
    { lat: 48.7846215, lng: 22.2155014 },
    { lat: 48.7847955, lng: 22.2166837 },
    { lat: 48.7846585, lng: 22.2173396 },
    { lat: 48.784852, lng: 22.2187413 },
    { lat: 48.785079, lng: 22.2195781 },
    { lat: 48.7852143, lng: 22.2203933 },
    { lat: 48.7853286, lng: 22.2207187 },
    { lat: 48.7857339, lng: 22.2205855 },
    { lat: 48.7858934, lng: 22.221078 },
    { lat: 48.786447, lng: 22.2219312 },
    { lat: 48.7864472, lng: 22.2221409 },
    { lat: 48.7865973, lng: 22.2220647 },
    { lat: 48.7867036, lng: 22.2220977 },
    { lat: 48.7876265, lng: 22.2210164 },
    { lat: 48.7922783, lng: 22.2161682 },
    { lat: 48.7944591, lng: 22.2137734 },
    { lat: 48.7950718, lng: 22.2142051 },
    { lat: 48.7961412, lng: 22.2144919 },
    { lat: 48.7977527, lng: 22.2154239 },
    { lat: 48.7989262, lng: 22.2164853 },
    { lat: 48.7996138, lng: 22.2170013 },
    { lat: 48.8003031, lng: 22.2147529 },
    { lat: 48.799917, lng: 22.2142891 },
    { lat: 48.8002413, lng: 22.2127677 },
    { lat: 48.7994347, lng: 22.2122115 },
    { lat: 48.7993641, lng: 22.211923 },
    { lat: 48.7993996, lng: 22.2116471 },
    { lat: 48.7989463, lng: 22.2111044 },
    { lat: 48.7985326, lng: 22.2110102 },
    { lat: 48.7984927, lng: 22.2107861 },
    { lat: 48.798289, lng: 22.2106379 },
    { lat: 48.7981663, lng: 22.210777 },
    { lat: 48.7981092, lng: 22.2103461 },
    { lat: 48.7983855, lng: 22.2096853 },
    { lat: 48.7983868, lng: 22.2093852 },
    { lat: 48.7981388, lng: 22.209311 },
    { lat: 48.7979956, lng: 22.2087515 },
    { lat: 48.7971441, lng: 22.2081517 },
    { lat: 48.7973675, lng: 22.2071915 },
    { lat: 48.7976964, lng: 22.2065612 },
    { lat: 48.7981023, lng: 22.2061155 },
    { lat: 48.7986676, lng: 22.2059959 },
    { lat: 48.7992503, lng: 22.2048022 },
    { lat: 48.7989265, lng: 22.2040149 },
    { lat: 48.7985653, lng: 22.2033122 },
    { lat: 48.7972207, lng: 22.2027039 },
    { lat: 48.7968361, lng: 22.2022576 },
    { lat: 48.7965311, lng: 22.2015517 },
    { lat: 48.7957398, lng: 22.2006874 },
    { lat: 48.7951233, lng: 22.1979586 },
    { lat: 48.7950598, lng: 22.1970936 },
    { lat: 48.7951124, lng: 22.196535 },
    { lat: 48.7943672, lng: 22.1938882 },
    { lat: 48.7943655, lng: 22.193274 },
    { lat: 48.7930219, lng: 22.1926994 },
    { lat: 48.7932233, lng: 22.192207 },
    { lat: 48.7934404, lng: 22.1921668 },
    { lat: 48.7939058, lng: 22.1905189 },
    { lat: 48.793584, lng: 22.1904219 },
    { lat: 48.7936372, lng: 22.1898926 },
    { lat: 48.7932326, lng: 22.1896073 },
    { lat: 48.7930663, lng: 22.1900633 },
    { lat: 48.7927371, lng: 22.1900256 },
    { lat: 48.7931644, lng: 22.1887336 },
    { lat: 48.7937003, lng: 22.1865916 },
    { lat: 48.7941272, lng: 22.1850863 },
    { lat: 48.7942898, lng: 22.1838363 },
    { lat: 48.7924033, lng: 22.1831887 },
    { lat: 48.79014, lng: 22.1828841 },
    { lat: 48.7884137, lng: 22.1812209 },
    { lat: 48.7868758, lng: 22.1788442 },
    { lat: 48.7862343, lng: 22.1782397 },
    { lat: 48.785705, lng: 22.1780171 },
    { lat: 48.7856285, lng: 22.177475 },
    { lat: 48.7834995, lng: 22.1756171 },
  ],
  Porúbka: [
    { lat: 48.7404707, lng: 22.2574314 },
    { lat: 48.7399784, lng: 22.2570399 },
    { lat: 48.7391457, lng: 22.2556529 },
    { lat: 48.7388695, lng: 22.2554218 },
    { lat: 48.7387533, lng: 22.255411 },
    { lat: 48.7386341, lng: 22.2552139 },
    { lat: 48.7381623, lng: 22.2547789 },
    { lat: 48.7380993, lng: 22.2544828 },
    { lat: 48.7381313, lng: 22.2543533 },
    { lat: 48.7380195, lng: 22.2543269 },
    { lat: 48.7378675, lng: 22.2540228 },
    { lat: 48.7376872, lng: 22.2540541 },
    { lat: 48.7375505, lng: 22.2537236 },
    { lat: 48.737479, lng: 22.2533406 },
    { lat: 48.7371947, lng: 22.2530525 },
    { lat: 48.7370133, lng: 22.2527286 },
    { lat: 48.7367512, lng: 22.2525798 },
    { lat: 48.7367719, lng: 22.2523974 },
    { lat: 48.7367082, lng: 22.2523226 },
    { lat: 48.7365923, lng: 22.2523142 },
    { lat: 48.7365038, lng: 22.2522243 },
    { lat: 48.7362721, lng: 22.2516738 },
    { lat: 48.7362215, lng: 22.2512727 },
    { lat: 48.7359745, lng: 22.2507893 },
    { lat: 48.7359543, lng: 22.2505391 },
    { lat: 48.7358618, lng: 22.2504675 },
    { lat: 48.7356434, lng: 22.2500094 },
    { lat: 48.7355806, lng: 22.2500126 },
    { lat: 48.7354817, lng: 22.2498701 },
    { lat: 48.7354151, lng: 22.2499063 },
    { lat: 48.735293, lng: 22.2495245 },
    { lat: 48.735208, lng: 22.2494999 },
    { lat: 48.7351353, lng: 22.2493675 },
    { lat: 48.735132, lng: 22.2491251 },
    { lat: 48.7350648, lng: 22.249028 },
    { lat: 48.7350935, lng: 22.2488049 },
    { lat: 48.7350057, lng: 22.2487011 },
    { lat: 48.7351112, lng: 22.2482349 },
    { lat: 48.735056, lng: 22.2479766 },
    { lat: 48.7349706, lng: 22.2480889 },
    { lat: 48.7347155, lng: 22.2481924 },
    { lat: 48.7338551, lng: 22.2507259 },
    { lat: 48.7333157, lng: 22.2513552 },
    { lat: 48.7331624, lng: 22.2516348 },
    { lat: 48.7325866, lng: 22.2510859 },
    { lat: 48.7312554, lng: 22.2501564 },
    { lat: 48.7307612, lng: 22.2498855 },
    { lat: 48.7310515, lng: 22.2484758 },
    { lat: 48.7305298, lng: 22.2481853 },
    { lat: 48.7299639, lng: 22.2477218 },
    { lat: 48.7300548, lng: 22.2471589 },
    { lat: 48.7299033, lng: 22.2470765 },
    { lat: 48.7299279, lng: 22.2469637 },
    { lat: 48.7298266, lng: 22.2469111 },
    { lat: 48.7298875, lng: 22.2465664 },
    { lat: 48.7295572, lng: 22.2464268 },
    { lat: 48.7294717, lng: 22.2462284 },
    { lat: 48.7284707, lng: 22.2474421 },
    { lat: 48.7281939, lng: 22.247386 },
    { lat: 48.7279586, lng: 22.2474774 },
    { lat: 48.7276739, lng: 22.2477211 },
    { lat: 48.7273787, lng: 22.2480037 },
    { lat: 48.7261034, lng: 22.2492201 },
    { lat: 48.7260458, lng: 22.2502217 },
    { lat: 48.7256033, lng: 22.2534313 },
    { lat: 48.7256594, lng: 22.2540341 },
    { lat: 48.7256425, lng: 22.2546815 },
    { lat: 48.7253294, lng: 22.2547195 },
    { lat: 48.725198, lng: 22.2550322 },
    { lat: 48.7251842, lng: 22.255422 },
    { lat: 48.7250128, lng: 22.2554485 },
    { lat: 48.7249731, lng: 22.2558915 },
    { lat: 48.7249549, lng: 22.2575505 },
    { lat: 48.724859, lng: 22.2590031 },
    { lat: 48.7249972, lng: 22.2593477 },
    { lat: 48.7249224, lng: 22.2597783 },
    { lat: 48.7243341, lng: 22.2610971 },
    { lat: 48.7238395, lng: 22.2615675 },
    { lat: 48.7229652, lng: 22.2629658 },
    { lat: 48.7225603, lng: 22.2637298 },
    { lat: 48.7221701, lng: 22.2649031 },
    { lat: 48.722364, lng: 22.2652751 },
    { lat: 48.7223185, lng: 22.2653063 },
    { lat: 48.7224205, lng: 22.26544 },
    { lat: 48.7224152, lng: 22.2655536 },
    { lat: 48.7224068, lng: 22.2657378 },
    { lat: 48.7226075, lng: 22.2655061 },
    { lat: 48.7235745, lng: 22.2671108 },
    { lat: 48.7238624, lng: 22.2677755 },
    { lat: 48.7240011, lng: 22.2687507 },
    { lat: 48.7240657, lng: 22.2690256 },
    { lat: 48.7241402, lng: 22.2690938 },
    { lat: 48.7241102, lng: 22.2693148 },
    { lat: 48.7242306, lng: 22.2702787 },
    { lat: 48.7242666, lng: 22.2703289 },
    { lat: 48.7241833, lng: 22.2707325 },
    { lat: 48.7242928, lng: 22.2707583 },
    { lat: 48.724263, lng: 22.2711909 },
    { lat: 48.7244564, lng: 22.2712277 },
    { lat: 48.7244513, lng: 22.2719665 },
    { lat: 48.7245523, lng: 22.2720045 },
    { lat: 48.7245638, lng: 22.2723748 },
    { lat: 48.7247471, lng: 22.2724799 },
    { lat: 48.7247234, lng: 22.2726749 },
    { lat: 48.7248138, lng: 22.2726938 },
    { lat: 48.7247189, lng: 22.2730363 },
    { lat: 48.7251922, lng: 22.2733277 },
    { lat: 48.7248412, lng: 22.2738377 },
    { lat: 48.7248699, lng: 22.2743137 },
    { lat: 48.7251025, lng: 22.2750707 },
    { lat: 48.7253699, lng: 22.2754719 },
    { lat: 48.7255928, lng: 22.2759779 },
    { lat: 48.7253642, lng: 22.2769941 },
    { lat: 48.725329, lng: 22.2776862 },
    { lat: 48.7252308, lng: 22.2778832 },
    { lat: 48.7252388, lng: 22.2785433 },
    { lat: 48.7253607, lng: 22.2794869 },
    { lat: 48.7250412, lng: 22.2808606 },
    { lat: 48.7255411, lng: 22.2809883 },
    { lat: 48.725767, lng: 22.2816317 },
    { lat: 48.7260573, lng: 22.282709 },
    { lat: 48.7264234, lng: 22.2830465 },
    { lat: 48.7266652, lng: 22.2835566 },
    { lat: 48.728172, lng: 22.2856369 },
    { lat: 48.7284324, lng: 22.2865334 },
    { lat: 48.7286771, lng: 22.2870184 },
    { lat: 48.7287304, lng: 22.2870417 },
    { lat: 48.7289431, lng: 22.2879171 },
    { lat: 48.7291316, lng: 22.2881499 },
    { lat: 48.7291496, lng: 22.2883057 },
    { lat: 48.7293021, lng: 22.2884866 },
    { lat: 48.729357, lng: 22.2887841 },
    { lat: 48.7293292, lng: 22.2889469 },
    { lat: 48.7292561, lng: 22.2889983 },
    { lat: 48.7293322, lng: 22.2894509 },
    { lat: 48.7292748, lng: 22.2895514 },
    { lat: 48.7293418, lng: 22.289781 },
    { lat: 48.7298032, lng: 22.2911455 },
    { lat: 48.7303989, lng: 22.2923345 },
    { lat: 48.7306283, lng: 22.2925443 },
    { lat: 48.7307756, lng: 22.2930496 },
    { lat: 48.7310589, lng: 22.2931451 },
    { lat: 48.7310237, lng: 22.2933322 },
    { lat: 48.7311103, lng: 22.2934333 },
    { lat: 48.7310046, lng: 22.2936477 },
    { lat: 48.7310786, lng: 22.2939458 },
    { lat: 48.731061, lng: 22.2942167 },
    { lat: 48.7311537, lng: 22.2944921 },
    { lat: 48.7313979, lng: 22.2947606 },
    { lat: 48.7316274, lng: 22.2952191 },
    { lat: 48.7319381, lng: 22.2954932 },
    { lat: 48.7319654, lng: 22.2957174 },
    { lat: 48.7321901, lng: 22.2964707 },
    { lat: 48.7323611, lng: 22.296672 },
    { lat: 48.7325338, lng: 22.2967535 },
    { lat: 48.7329802, lng: 22.2969578 },
    { lat: 48.7343862, lng: 22.2973724 },
    { lat: 48.7345412, lng: 22.2975652 },
    { lat: 48.7345432, lng: 22.2974493 },
    { lat: 48.7348482, lng: 22.2972032 },
    { lat: 48.7352915, lng: 22.2974699 },
    { lat: 48.7354185, lng: 22.2976189 },
    { lat: 48.7355774, lng: 22.2975779 },
    { lat: 48.7356402, lng: 22.2977233 },
    { lat: 48.7359503, lng: 22.2976568 },
    { lat: 48.7361713, lng: 22.2977797 },
    { lat: 48.7363476, lng: 22.2980271 },
    { lat: 48.7367114, lng: 22.2982626 },
    { lat: 48.7369533, lng: 22.2985242 },
    { lat: 48.7370137, lng: 22.2986555 },
    { lat: 48.7370352, lng: 22.2988707 },
    { lat: 48.7372438, lng: 22.2991012 },
    { lat: 48.7374956, lng: 22.2991887 },
    { lat: 48.7377336, lng: 22.2995324 },
    { lat: 48.7377651, lng: 22.2994845 },
    { lat: 48.7385696, lng: 22.3018068 },
    { lat: 48.7391636, lng: 22.3031052 },
    { lat: 48.739599, lng: 22.3045003 },
    { lat: 48.739433, lng: 22.3045713 },
    { lat: 48.7393345, lng: 22.3047479 },
    { lat: 48.7392758, lng: 22.3049953 },
    { lat: 48.739436, lng: 22.3058992 },
    { lat: 48.7394939, lng: 22.306025 },
    { lat: 48.7396026, lng: 22.3068256 },
    { lat: 48.7394052, lng: 22.3083021 },
    { lat: 48.7391901, lng: 22.309116 },
    { lat: 48.7391612, lng: 22.3096965 },
    { lat: 48.7389914, lng: 22.3105245 },
    { lat: 48.7391148, lng: 22.3112336 },
    { lat: 48.7393272, lng: 22.3120059 },
    { lat: 48.7394011, lng: 22.3128649 },
    { lat: 48.7397464, lng: 22.3135928 },
    { lat: 48.7405042, lng: 22.3138352 },
    { lat: 48.7406281, lng: 22.3139481 },
    { lat: 48.7408455, lng: 22.31432 },
    { lat: 48.7413215, lng: 22.3147931 },
    { lat: 48.7417302, lng: 22.3155996 },
    { lat: 48.7421227, lng: 22.3161659 },
    { lat: 48.7422071, lng: 22.3166877 },
    { lat: 48.7423317, lng: 22.3169876 },
    { lat: 48.742558, lng: 22.3179749 },
    { lat: 48.7425876, lng: 22.3193412 },
    { lat: 48.742519, lng: 22.3200138 },
    { lat: 48.7425711, lng: 22.3206566 },
    { lat: 48.7424422, lng: 22.3210389 },
    { lat: 48.7424987, lng: 22.3210461 },
    { lat: 48.7428442, lng: 22.3221978 },
    { lat: 48.7429445, lng: 22.3223675 },
    { lat: 48.7430212, lng: 22.3229456 },
    { lat: 48.7433388, lng: 22.3232767 },
    { lat: 48.7434613, lng: 22.3236341 },
    { lat: 48.7435126, lng: 22.324036 },
    { lat: 48.7441009, lng: 22.32486 },
    { lat: 48.7442671, lng: 22.3252677 },
    { lat: 48.744597, lng: 22.325602 },
    { lat: 48.7446501, lng: 22.3258125 },
    { lat: 48.7446665, lng: 22.3265711 },
    { lat: 48.7452869, lng: 22.3277496 },
    { lat: 48.7456452, lng: 22.3279372 },
    { lat: 48.7457323, lng: 22.3280655 },
    { lat: 48.7460557, lng: 22.3288699 },
    { lat: 48.7460814, lng: 22.3296908 },
    { lat: 48.7461612, lng: 22.3300001 },
    { lat: 48.7461545, lng: 22.3303664 },
    { lat: 48.7458802, lng: 22.3312096 },
    { lat: 48.7458596, lng: 22.3314587 },
    { lat: 48.7460196, lng: 22.3326155 },
    { lat: 48.7460825, lng: 22.3328757 },
    { lat: 48.7463032, lng: 22.3334037 },
    { lat: 48.7463786, lng: 22.3345065 },
    { lat: 48.7464595, lng: 22.3349666 },
    { lat: 48.746701, lng: 22.3357423 },
    { lat: 48.7469351, lng: 22.3362147 },
    { lat: 48.7470968, lng: 22.336355 },
    { lat: 48.747602, lng: 22.3370953 },
    { lat: 48.7481544, lng: 22.3375727 },
    { lat: 48.7491341, lng: 22.3389312 },
    { lat: 48.7494001, lng: 22.3396901 },
    { lat: 48.7500426, lng: 22.3408514 },
    { lat: 48.7505221, lng: 22.3419655 },
    { lat: 48.750679, lng: 22.342667 },
    { lat: 48.751175, lng: 22.343005 },
    { lat: 48.751945, lng: 22.343257 },
    { lat: 48.7519118, lng: 22.3427825 },
    { lat: 48.7516415, lng: 22.3413562 },
    { lat: 48.7516145, lng: 22.3408346 },
    { lat: 48.7517413, lng: 22.3392502 },
    { lat: 48.7519679, lng: 22.3384406 },
    { lat: 48.7521952, lng: 22.3372411 },
    { lat: 48.7523307, lng: 22.3368484 },
    { lat: 48.7527927, lng: 22.3359524 },
    { lat: 48.7532859, lng: 22.3353489 },
    { lat: 48.7540459, lng: 22.3333546 },
    { lat: 48.7542159, lng: 22.3327905 },
    { lat: 48.7549001, lng: 22.330247 },
    { lat: 48.7550243, lng: 22.3293822 },
    { lat: 48.7550377, lng: 22.3289663 },
    { lat: 48.7553536, lng: 22.3269848 },
    { lat: 48.7555281, lng: 22.325488 },
    { lat: 48.7556552, lng: 22.3251192 },
    { lat: 48.7556578, lng: 22.3245623 },
    { lat: 48.7555503, lng: 22.3238536 },
    { lat: 48.7548024, lng: 22.3208498 },
    { lat: 48.7546, lng: 22.3196562 },
    { lat: 48.7545198, lng: 22.3186518 },
    { lat: 48.7545185, lng: 22.3176178 },
    { lat: 48.7547582, lng: 22.3144606 },
    { lat: 48.7547732, lng: 22.3128705 },
    { lat: 48.7548969, lng: 22.3124038 },
    { lat: 48.7549206, lng: 22.3120953 },
    { lat: 48.7543426, lng: 22.3113798 },
    { lat: 48.754184, lng: 22.3110751 },
    { lat: 48.7539399, lng: 22.3107665 },
    { lat: 48.7537286, lng: 22.3101942 },
    { lat: 48.7536689, lng: 22.3093171 },
    { lat: 48.7537337, lng: 22.3073909 },
    { lat: 48.7536712, lng: 22.305918 },
    { lat: 48.7536993, lng: 22.3054429 },
    { lat: 48.7536692, lng: 22.3044742 },
    { lat: 48.7534405, lng: 22.3033275 },
    { lat: 48.7532345, lng: 22.3028103 },
    { lat: 48.7530874, lng: 22.30271 },
    { lat: 48.7528847, lng: 22.3020835 },
    { lat: 48.75283, lng: 22.3016386 },
    { lat: 48.7527038, lng: 22.3012164 },
    { lat: 48.7523281, lng: 22.3006524 },
    { lat: 48.7515533, lng: 22.2999377 },
    { lat: 48.7509962, lng: 22.2997695 },
    { lat: 48.7503894, lng: 22.2993875 },
    { lat: 48.7500378, lng: 22.2986331 },
    { lat: 48.7497145, lng: 22.2970621 },
    { lat: 48.7493723, lng: 22.2966466 },
    { lat: 48.7491658, lng: 22.2960941 },
    { lat: 48.748924, lng: 22.2957384 },
    { lat: 48.7481852, lng: 22.293797 },
    { lat: 48.748052, lng: 22.2936647 },
    { lat: 48.748005, lng: 22.2928145 },
    { lat: 48.7479581, lng: 22.292674 },
    { lat: 48.7480019, lng: 22.2925652 },
    { lat: 48.7480253, lng: 22.2921492 },
    { lat: 48.7480759, lng: 22.2920903 },
    { lat: 48.7479729, lng: 22.2907244 },
    { lat: 48.7478602, lng: 22.2904646 },
    { lat: 48.7478804, lng: 22.288875 },
    { lat: 48.7478366, lng: 22.2886705 },
    { lat: 48.7477348, lng: 22.2885445 },
    { lat: 48.7476946, lng: 22.2884 },
    { lat: 48.7476327, lng: 22.2878896 },
    { lat: 48.7476931, lng: 22.2875672 },
    { lat: 48.7475576, lng: 22.2872921 },
    { lat: 48.747515, lng: 22.2859638 },
    { lat: 48.7473192, lng: 22.2854957 },
    { lat: 48.7471836, lng: 22.285361 },
    { lat: 48.7471235, lng: 22.2851356 },
    { lat: 48.7469167, lng: 22.2851421 },
    { lat: 48.7466943, lng: 22.2848592 },
    { lat: 48.7465971, lng: 22.2848884 },
    { lat: 48.7465195, lng: 22.2847761 },
    { lat: 48.7465371, lng: 22.284612 },
    { lat: 48.7463319, lng: 22.284639 },
    { lat: 48.7462393, lng: 22.2845197 },
    { lat: 48.7459418, lng: 22.2846487 },
    { lat: 48.7457876, lng: 22.284536 },
    { lat: 48.7457361, lng: 22.2843507 },
    { lat: 48.7455135, lng: 22.2841976 },
    { lat: 48.745388, lng: 22.2843169 },
    { lat: 48.7451319, lng: 22.2839282 },
    { lat: 48.745015, lng: 22.2839793 },
    { lat: 48.7448351, lng: 22.2836783 },
    { lat: 48.7448847, lng: 22.2835304 },
    { lat: 48.744563, lng: 22.2832241 },
    { lat: 48.7445201, lng: 22.2830081 },
    { lat: 48.7445943, lng: 22.2829107 },
    { lat: 48.7446124, lng: 22.2821995 },
    { lat: 48.7445438, lng: 22.282096 },
    { lat: 48.7445881, lng: 22.2818579 },
    { lat: 48.7445568, lng: 22.2817636 },
    { lat: 48.7444705, lng: 22.2817316 },
    { lat: 48.7444901, lng: 22.2816479 },
    { lat: 48.7444363, lng: 22.2815562 },
    { lat: 48.7443395, lng: 22.2815514 },
    { lat: 48.7443135, lng: 22.2814911 },
    { lat: 48.7444202, lng: 22.2812876 },
    { lat: 48.7444501, lng: 22.2811154 },
    { lat: 48.7443997, lng: 22.2808695 },
    { lat: 48.7442201, lng: 22.2806779 },
    { lat: 48.7441181, lng: 22.2806909 },
    { lat: 48.7439957, lng: 22.2804932 },
    { lat: 48.7440008, lng: 22.2803194 },
    { lat: 48.7435757, lng: 22.280272 },
    { lat: 48.7434268, lng: 22.2797909 },
    { lat: 48.7432539, lng: 22.2796582 },
    { lat: 48.7429635, lng: 22.2791318 },
    { lat: 48.7428549, lng: 22.279061 },
    { lat: 48.7427998, lng: 22.2788999 },
    { lat: 48.7433354, lng: 22.2760526 },
    { lat: 48.7440598, lng: 22.2739223 },
    { lat: 48.7441495, lng: 22.2734512 },
    { lat: 48.7442904, lng: 22.2730086 },
    { lat: 48.7446004, lng: 22.2723732 },
    { lat: 48.7442419, lng: 22.2713717 },
    { lat: 48.7439632, lng: 22.2702162 },
    { lat: 48.7437513, lng: 22.2697333 },
    { lat: 48.7436103, lng: 22.2692109 },
    { lat: 48.7434942, lng: 22.2690997 },
    { lat: 48.7434435, lng: 22.2688405 },
    { lat: 48.7434107, lng: 22.268881 },
    { lat: 48.7433083, lng: 22.2687283 },
    { lat: 48.7432448, lng: 22.2679332 },
    { lat: 48.7431357, lng: 22.2677317 },
    { lat: 48.7422896, lng: 22.2654375 },
    { lat: 48.7415392, lng: 22.2645155 },
    { lat: 48.7414067, lng: 22.2641719 },
    { lat: 48.7410966, lng: 22.2636759 },
    { lat: 48.7409631, lng: 22.2635458 },
    { lat: 48.7408564, lng: 22.2632526 },
    { lat: 48.7406684, lng: 22.2631352 },
    { lat: 48.7398732, lng: 22.2623324 },
    { lat: 48.7394096, lng: 22.2620684 },
    { lat: 48.7392372, lng: 22.2618505 },
    { lat: 48.7394661, lng: 22.2614155 },
    { lat: 48.740264, lng: 22.2602746 },
    { lat: 48.7405443, lng: 22.2597216 },
    { lat: 48.7409643, lng: 22.2584808 },
    { lat: 48.7409903, lng: 22.2583918 },
    { lat: 48.7406896, lng: 22.2580119 },
    { lat: 48.7406133, lng: 22.2578817 },
    { lat: 48.7405251, lng: 22.2576924 },
    { lat: 48.7405063, lng: 22.2575583 },
    { lat: 48.7404707, lng: 22.2574314 },
  ],
  Orechová: [
    { lat: 48.718268, lng: 22.2364972 },
    { lat: 48.7181001, lng: 22.2361233 },
    { lat: 48.7178625, lng: 22.235568 },
    { lat: 48.7176612, lng: 22.2348627 },
    { lat: 48.7175576, lng: 22.2349072 },
    { lat: 48.717255, lng: 22.234709 },
    { lat: 48.7171179, lng: 22.2344606 },
    { lat: 48.7169215, lng: 22.2343766 },
    { lat: 48.7167917, lng: 22.2341592 },
    { lat: 48.7168552, lng: 22.2340794 },
    { lat: 48.7165653, lng: 22.2334919 },
    { lat: 48.7164224, lng: 22.2333425 },
    { lat: 48.7164148, lng: 22.2329087 },
    { lat: 48.716632, lng: 22.2325033 },
    { lat: 48.7165651, lng: 22.2324337 },
    { lat: 48.7163991, lng: 22.2324094 },
    { lat: 48.7163242, lng: 22.2322199 },
    { lat: 48.7162081, lng: 22.2323644 },
    { lat: 48.715745, lng: 22.2322275 },
    { lat: 48.7156896, lng: 22.2323653 },
    { lat: 48.7153778, lng: 22.2324077 },
    { lat: 48.7153422, lng: 22.2322688 },
    { lat: 48.7154417, lng: 22.2320005 },
    { lat: 48.7152234, lng: 22.2317353 },
    { lat: 48.7150261, lng: 22.2316212 },
    { lat: 48.7145619, lng: 22.2314871 },
    { lat: 48.7146006, lng: 22.2311557 },
    { lat: 48.7145716, lng: 22.2311116 },
    { lat: 48.7142816, lng: 22.2311721 },
    { lat: 48.7142304, lng: 22.2305902 },
    { lat: 48.7141075, lng: 22.2305887 },
    { lat: 48.7141238, lng: 22.230757 },
    { lat: 48.7138752, lng: 22.230706 },
    { lat: 48.7138108, lng: 22.2308425 },
    { lat: 48.7136501, lng: 22.2308533 },
    { lat: 48.7135294, lng: 22.2305895 },
    { lat: 48.713428, lng: 22.2308294 },
    { lat: 48.7132266, lng: 22.2305227 },
    { lat: 48.7131744, lng: 22.2298544 },
    { lat: 48.7131091, lng: 22.2298047 },
    { lat: 48.7132445, lng: 22.2283723 },
    { lat: 48.7135744, lng: 22.2268894 },
    { lat: 48.7137169, lng: 22.2260176 },
    { lat: 48.7139258, lng: 22.2252578 },
    { lat: 48.7147243, lng: 22.22176 },
    { lat: 48.7147368, lng: 22.2214163 },
    { lat: 48.7147909, lng: 22.220719 },
    { lat: 48.7148476, lng: 22.2200888 },
    { lat: 48.7149445, lng: 22.2199538 },
    { lat: 48.7149167, lng: 22.2193722 },
    { lat: 48.7149961, lng: 22.2190475 },
    { lat: 48.7149252, lng: 22.2184026 },
    { lat: 48.7140307, lng: 22.2186831 },
    { lat: 48.7127017, lng: 22.2187616 },
    { lat: 48.7104954, lng: 22.2186292 },
    { lat: 48.7105509, lng: 22.2167576 },
    { lat: 48.7107291, lng: 22.2154855 },
    { lat: 48.7109123, lng: 22.214487 },
    { lat: 48.7111566, lng: 22.2134999 },
    { lat: 48.7115399, lng: 22.2113068 },
    { lat: 48.7117441, lng: 22.2085929 },
    { lat: 48.7118714, lng: 22.2076603 },
    { lat: 48.7119797, lng: 22.2075308 },
    { lat: 48.7119609, lng: 22.2073441 },
    { lat: 48.7120349, lng: 22.2072807 },
    { lat: 48.7119939, lng: 22.2071176 },
    { lat: 48.7117652, lng: 22.2067501 },
    { lat: 48.7118516, lng: 22.2064938 },
    { lat: 48.7118313, lng: 22.2061409 },
    { lat: 48.711502, lng: 22.2058588 },
    { lat: 48.711333, lng: 22.2045618 },
    { lat: 48.7111051, lng: 22.204086 },
    { lat: 48.7110342, lng: 22.2036324 },
    { lat: 48.7108576, lng: 22.2033894 },
    { lat: 48.710771, lng: 22.2030413 },
    { lat: 48.7093479, lng: 22.2023412 },
    { lat: 48.7069195, lng: 22.2011523 },
    { lat: 48.7062798, lng: 22.2008718 },
    { lat: 48.7059982, lng: 22.2019409 },
    { lat: 48.7059801, lng: 22.2020366 },
    { lat: 48.7057743, lng: 22.2034269 },
    { lat: 48.7055453, lng: 22.2050281 },
    { lat: 48.7061178, lng: 22.2056188 },
    { lat: 48.7064252, lng: 22.2058411 },
    { lat: 48.7059363, lng: 22.2083828 },
    { lat: 48.7055895, lng: 22.2082672 },
    { lat: 48.7048961, lng: 22.21396 },
    { lat: 48.704283, lng: 22.2214155 },
    { lat: 48.7035539, lng: 22.2207676 },
    { lat: 48.7035537, lng: 22.2207038 },
    { lat: 48.7031053, lng: 22.2207205 },
    { lat: 48.701864, lng: 22.220939 },
    { lat: 48.7019004, lng: 22.2212548 },
    { lat: 48.7018753, lng: 22.2218874 },
    { lat: 48.7020128, lng: 22.2220983 },
    { lat: 48.7022809, lng: 22.2224167 },
    { lat: 48.7027277, lng: 22.2223285 },
    { lat: 48.7028346, lng: 22.2226791 },
    { lat: 48.7033214, lng: 22.2231227 },
    { lat: 48.7037747, lng: 22.2236692 },
    { lat: 48.7031917, lng: 22.2261979 },
    { lat: 48.702408, lng: 22.2264583 },
    { lat: 48.7015869, lng: 22.2265664 },
    { lat: 48.7017863, lng: 22.2284712 },
    { lat: 48.6994895, lng: 22.2301327 },
    { lat: 48.6994589, lng: 22.2302739 },
    { lat: 48.699509, lng: 22.2308973 },
    { lat: 48.6996112, lng: 22.2309857 },
    { lat: 48.6995979, lng: 22.2311491 },
    { lat: 48.699828, lng: 22.231373 },
    { lat: 48.7000426, lng: 22.2319631 },
    { lat: 48.7004482, lng: 22.2324461 },
    { lat: 48.700562, lng: 22.2326532 },
    { lat: 48.7009143, lng: 22.232914 },
    { lat: 48.701011, lng: 22.2328624 },
    { lat: 48.7010981, lng: 22.2329854 },
    { lat: 48.7012795, lng: 22.2330028 },
    { lat: 48.701367, lng: 22.2331414 },
    { lat: 48.7015709, lng: 22.2351579 },
    { lat: 48.7018352, lng: 22.2355065 },
    { lat: 48.7018166, lng: 22.2356848 },
    { lat: 48.7018742, lng: 22.2357943 },
    { lat: 48.7018783, lng: 22.2361235 },
    { lat: 48.7018452, lng: 22.2364065 },
    { lat: 48.7021364, lng: 22.2373025 },
    { lat: 48.7023913, lng: 22.2374871 },
    { lat: 48.7025267, lng: 22.2379 },
    { lat: 48.7026476, lng: 22.2380277 },
    { lat: 48.7026838, lng: 22.2379913 },
    { lat: 48.7027816, lng: 22.2381376 },
    { lat: 48.7028408, lng: 22.238423 },
    { lat: 48.702941, lng: 22.2386248 },
    { lat: 48.7030422, lng: 22.2390263 },
    { lat: 48.703102, lng: 22.2402881 },
    { lat: 48.7031035, lng: 22.2404055 },
    { lat: 48.7031879, lng: 22.2409122 },
    { lat: 48.7033268, lng: 22.2428943 },
    { lat: 48.7036849, lng: 22.2442189 },
    { lat: 48.7037547, lng: 22.2448275 },
    { lat: 48.703728, lng: 22.2452394 },
    { lat: 48.7040549, lng: 22.2460792 },
    { lat: 48.704119, lng: 22.246386 },
    { lat: 48.7041588, lng: 22.2469545 },
    { lat: 48.7042466, lng: 22.2472128 },
    { lat: 48.7041567, lng: 22.2474603 },
    { lat: 48.704122, lng: 22.2477747 },
    { lat: 48.7040321, lng: 22.2493857 },
    { lat: 48.7040697, lng: 22.249669 },
    { lat: 48.7039327, lng: 22.250427 },
    { lat: 48.7039411, lng: 22.250907 },
    { lat: 48.7042297, lng: 22.2524021 },
    { lat: 48.7042225, lng: 22.2526831 },
    { lat: 48.7042626, lng: 22.2527153 },
    { lat: 48.7043643, lng: 22.2535993 },
    { lat: 48.7042052, lng: 22.2558448 },
    { lat: 48.7042944, lng: 22.2562181 },
    { lat: 48.7045063, lng: 22.2563875 },
    { lat: 48.7054637, lng: 22.2565454 },
    { lat: 48.7058145, lng: 22.2566882 },
    { lat: 48.7059571, lng: 22.2565827 },
    { lat: 48.706063, lng: 22.2560346 },
    { lat: 48.7062877, lng: 22.2557625 },
    { lat: 48.7065247, lng: 22.2553006 },
    { lat: 48.7066273, lng: 22.25521 },
    { lat: 48.7067112, lng: 22.2549895 },
    { lat: 48.706797, lng: 22.2544492 },
    { lat: 48.7068881, lng: 22.2543459 },
    { lat: 48.706999, lng: 22.2544293 },
    { lat: 48.7070955, lng: 22.2543582 },
    { lat: 48.7072439, lng: 22.2540066 },
    { lat: 48.7073553, lng: 22.2540134 },
    { lat: 48.7076584, lng: 22.2538299 },
    { lat: 48.7078164, lng: 22.2538033 },
    { lat: 48.7078806, lng: 22.253456 },
    { lat: 48.7079918, lng: 22.2534443 },
    { lat: 48.7082694, lng: 22.2531378 },
    { lat: 48.7083695, lng: 22.2531327 },
    { lat: 48.7087101, lng: 22.2529206 },
    { lat: 48.7091278, lng: 22.2522341 },
    { lat: 48.7091551, lng: 22.2518452 },
    { lat: 48.7090525, lng: 22.2513322 },
    { lat: 48.7091849, lng: 22.250708 },
    { lat: 48.7091, lng: 22.2504801 },
    { lat: 48.7091344, lng: 22.2500079 },
    { lat: 48.709072, lng: 22.2498305 },
    { lat: 48.7089278, lng: 22.2497695 },
    { lat: 48.7088854, lng: 22.2496847 },
    { lat: 48.7090684, lng: 22.2492131 },
    { lat: 48.7092277, lng: 22.2490433 },
    { lat: 48.7093347, lng: 22.2486005 },
    { lat: 48.7093999, lng: 22.2485018 },
    { lat: 48.7094386, lng: 22.2481664 },
    { lat: 48.7096752, lng: 22.2476775 },
    { lat: 48.7099155, lng: 22.2475556 },
    { lat: 48.70991, lng: 22.2469628 },
    { lat: 48.7099647, lng: 22.2467944 },
    { lat: 48.710293, lng: 22.2466865 },
    { lat: 48.7103388, lng: 22.2464684 },
    { lat: 48.7102678, lng: 22.2451062 },
    { lat: 48.7104404, lng: 22.2450247 },
    { lat: 48.710642, lng: 22.2450892 },
    { lat: 48.7106774, lng: 22.2449652 },
    { lat: 48.7105746, lng: 22.2447443 },
    { lat: 48.7105412, lng: 22.2444518 },
    { lat: 48.7105765, lng: 22.2438677 },
    { lat: 48.7107021, lng: 22.2434963 },
    { lat: 48.7105644, lng: 22.2433249 },
    { lat: 48.7105156, lng: 22.2428508 },
    { lat: 48.7105783, lng: 22.2426924 },
    { lat: 48.7105197, lng: 22.2425401 },
    { lat: 48.7104595, lng: 22.2420082 },
    { lat: 48.7103596, lng: 22.2419179 },
    { lat: 48.7104582, lng: 22.2417533 },
    { lat: 48.7104797, lng: 22.2414081 },
    { lat: 48.7104147, lng: 22.2414093 },
    { lat: 48.7103369, lng: 22.2411544 },
    { lat: 48.7102595, lng: 22.2410668 },
    { lat: 48.7102272, lng: 22.2406229 },
    { lat: 48.710255, lng: 22.2406147 },
    { lat: 48.7097099, lng: 22.2392686 },
    { lat: 48.7097202, lng: 22.2386747 },
    { lat: 48.7099492, lng: 22.2369496 },
    { lat: 48.7099386, lng: 22.2366727 },
    { lat: 48.7101264, lng: 22.2361274 },
    { lat: 48.7101537, lng: 22.2351341 },
    { lat: 48.710295, lng: 22.2348665 },
    { lat: 48.7104485, lng: 22.2343896 },
    { lat: 48.7104482, lng: 22.2334697 },
    { lat: 48.7107106, lng: 22.2325801 },
    { lat: 48.7108783, lng: 22.231191 },
    { lat: 48.7110681, lng: 22.2312248 },
    { lat: 48.7110475, lng: 22.2314082 },
    { lat: 48.7116203, lng: 22.2319276 },
    { lat: 48.7117347, lng: 22.2316568 },
    { lat: 48.7120108, lng: 22.2318307 },
    { lat: 48.7120606, lng: 22.231748 },
    { lat: 48.7121406, lng: 22.2318065 },
    { lat: 48.7121374, lng: 22.2320079 },
    { lat: 48.7123995, lng: 22.2322107 },
    { lat: 48.7130584, lng: 22.2322556 },
    { lat: 48.7131745, lng: 22.2320121 },
    { lat: 48.7133393, lng: 22.23214 },
    { lat: 48.7133673, lng: 22.2318298 },
    { lat: 48.7134227, lng: 22.2316948 },
    { lat: 48.7137365, lng: 22.2317952 },
    { lat: 48.7138881, lng: 22.2317333 },
    { lat: 48.7141667, lng: 22.2323715 },
    { lat: 48.7141551, lng: 22.2324562 },
    { lat: 48.7154117, lng: 22.2334118 },
    { lat: 48.7160203, lng: 22.2342227 },
    { lat: 48.7171285, lng: 22.2362364 },
    { lat: 48.7174766, lng: 22.2363639 },
    { lat: 48.7177475, lng: 22.2370073 },
    { lat: 48.718268, lng: 22.2364972 },
  ],
  BlatnéRevištia: [
    { lat: 48.7232601, lng: 22.0969392 },
    { lat: 48.7255065, lng: 22.0960338 },
    { lat: 48.726192, lng: 22.0955294 },
    { lat: 48.7271581, lng: 22.0950475 },
    { lat: 48.7281194, lng: 22.0943215 },
    { lat: 48.7291795, lng: 22.0932494 },
    { lat: 48.7293392, lng: 22.093006 },
    { lat: 48.7304442, lng: 22.0917868 },
    { lat: 48.7316508, lng: 22.0900162 },
    { lat: 48.7318182, lng: 22.0897203 },
    { lat: 48.7331372, lng: 22.0862342 },
    { lat: 48.7378525, lng: 22.0838151 },
    { lat: 48.7392645, lng: 22.0832489 },
    { lat: 48.7429394, lng: 22.0822611 },
    { lat: 48.7432044, lng: 22.0820963 },
    { lat: 48.7433316, lng: 22.0821005 },
    { lat: 48.7448058, lng: 22.0814177 },
    { lat: 48.7449593, lng: 22.0811054 },
    { lat: 48.7450438, lng: 22.0811603 },
    { lat: 48.745517, lng: 22.0800023 },
    { lat: 48.7458842, lng: 22.0776142 },
    { lat: 48.7466341, lng: 22.0763281 },
    { lat: 48.7476573, lng: 22.0752662 },
    { lat: 48.7481272, lng: 22.0746389 },
    { lat: 48.7486033, lng: 22.0737551 },
    { lat: 48.7491845, lng: 22.0693541 },
    { lat: 48.752694, lng: 22.067473 },
    { lat: 48.752893, lng: 22.066351 },
    { lat: 48.752462, lng: 22.064849 },
    { lat: 48.751829, lng: 22.063451 },
    { lat: 48.751442, lng: 22.062535 },
    { lat: 48.751333, lng: 22.06144 },
    { lat: 48.751305, lng: 22.061184 },
    { lat: 48.751161, lng: 22.06073 },
    { lat: 48.750556, lng: 22.059221 },
    { lat: 48.74964, lng: 22.057395 },
    { lat: 48.749701, lng: 22.057227 },
    { lat: 48.749675, lng: 22.057136 },
    { lat: 48.749683, lng: 22.057037 },
    { lat: 48.748615, lng: 22.057704 },
    { lat: 48.74856, lng: 22.057738 },
    { lat: 48.747678, lng: 22.058288 },
    { lat: 48.746963, lng: 22.058733 },
    { lat: 48.74622, lng: 22.059175 },
    { lat: 48.745261, lng: 22.059746 },
    { lat: 48.745203, lng: 22.05977 },
    { lat: 48.745176, lng: 22.059785 },
    { lat: 48.744556, lng: 22.060173 },
    { lat: 48.743587, lng: 22.060599 },
    { lat: 48.740949, lng: 22.061677 },
    { lat: 48.740514, lng: 22.061876 },
    { lat: 48.738802, lng: 22.063346 },
    { lat: 48.738199, lng: 22.063903 },
    { lat: 48.73743, lng: 22.064586 },
    { lat: 48.736658, lng: 22.065269 },
    { lat: 48.736654, lng: 22.065273 },
    { lat: 48.735576, lng: 22.06624 },
    { lat: 48.735106, lng: 22.066656 },
    { lat: 48.734334, lng: 22.067341 },
    { lat: 48.733543, lng: 22.067987 },
    { lat: 48.733012, lng: 22.068381 },
    { lat: 48.732984, lng: 22.068397 },
    { lat: 48.732954, lng: 22.068413 },
    { lat: 48.732927, lng: 22.068427 },
    { lat: 48.732726, lng: 22.068532 },
    { lat: 48.732642, lng: 22.068546 },
    { lat: 48.731842, lng: 22.068875 },
    { lat: 48.730974, lng: 22.069244 },
    { lat: 48.730085, lng: 22.06959 },
    { lat: 48.729234, lng: 22.069976 },
    { lat: 48.728358, lng: 22.070346 },
    { lat: 48.728353, lng: 22.070348 },
    { lat: 48.727486, lng: 22.070672 },
    { lat: 48.727032, lng: 22.070904 },
    { lat: 48.726615, lng: 22.071071 },
    { lat: 48.725731, lng: 22.07131 },
    { lat: 48.724837, lng: 22.071552 },
    { lat: 48.724074, lng: 22.071719 },
    { lat: 48.723935, lng: 22.07174 },
    { lat: 48.723522, lng: 22.071874 },
    { lat: 48.723081, lng: 22.072009 },
    { lat: 48.722194, lng: 22.072241 },
    { lat: 48.721944, lng: 22.072322 },
    { lat: 48.721341, lng: 22.072692 },
    { lat: 48.720808, lng: 22.073083 },
    { lat: 48.720557, lng: 22.073363 },
    { lat: 48.719816, lng: 22.074157 },
    { lat: 48.71944, lng: 22.074537 },
    { lat: 48.719081, lng: 22.074954 },
    { lat: 48.718258, lng: 22.07582 },
    { lat: 48.7182893, lng: 22.0761244 },
    { lat: 48.7226792, lng: 22.0943539 },
    { lat: 48.7232497, lng: 22.096881 },
    { lat: 48.7232601, lng: 22.0969392 },
  ],
  Ostrov: [
    { lat: 48.7062798, lng: 22.2008718 },
    { lat: 48.7069195, lng: 22.2011523 },
    { lat: 48.7093479, lng: 22.2023412 },
    { lat: 48.7095636, lng: 22.2003527 },
    { lat: 48.7098877, lng: 22.1970471 },
    { lat: 48.7109571, lng: 22.1976682 },
    { lat: 48.7119398, lng: 22.1983455 },
    { lat: 48.712124, lng: 22.1985774 },
    { lat: 48.7125583, lng: 22.1994335 },
    { lat: 48.7135456, lng: 22.1966023 },
    { lat: 48.7122233, lng: 22.1949906 },
    { lat: 48.7125518, lng: 22.1940044 },
    { lat: 48.7130708, lng: 22.1933819 },
    { lat: 48.7132388, lng: 22.1929543 },
    { lat: 48.7132689, lng: 22.1915672 },
    { lat: 48.7131125, lng: 22.191264 },
    { lat: 48.7129696, lng: 22.1912697 },
    { lat: 48.7126614, lng: 22.1915646 },
    { lat: 48.7135215, lng: 22.1896759 },
    { lat: 48.7146123, lng: 22.1880322 },
    { lat: 48.716946, lng: 22.1904352 },
    { lat: 48.7181319, lng: 22.1845924 },
    { lat: 48.7216497, lng: 22.1882564 },
    { lat: 48.7231404, lng: 22.1846756 },
    { lat: 48.7241121, lng: 22.1822579 },
    { lat: 48.7242177, lng: 22.1819585 },
    { lat: 48.7241521, lng: 22.1817001 },
    { lat: 48.7241952, lng: 22.1815247 },
    { lat: 48.7244383, lng: 22.1813833 },
    { lat: 48.7247736, lng: 22.1805425 },
    { lat: 48.7251987, lng: 22.179667 },
    { lat: 48.7275325, lng: 22.1740595 },
    { lat: 48.7264804, lng: 22.1738458 },
    { lat: 48.7264786, lng: 22.1736264 },
    { lat: 48.7276379, lng: 22.1737002 },
    { lat: 48.7283887, lng: 22.1720222 },
    { lat: 48.7286038, lng: 22.171461 },
    { lat: 48.729338, lng: 22.1700075 },
    { lat: 48.7312791, lng: 22.167204 },
    { lat: 48.7313901, lng: 22.166751 },
    { lat: 48.7314844, lng: 22.1665996 },
    { lat: 48.7316336, lng: 22.1668725 },
    { lat: 48.7325066, lng: 22.1657576 },
    { lat: 48.73391, lng: 22.1636395 },
    { lat: 48.7355542, lng: 22.1618678 },
    { lat: 48.7373444, lng: 22.1594554 },
    { lat: 48.7378321, lng: 22.1589963 },
    { lat: 48.7395458, lng: 22.1568258 },
    { lat: 48.7385247, lng: 22.1546938 },
    { lat: 48.7380822, lng: 22.153806 },
    { lat: 48.7345982, lng: 22.148158 },
    { lat: 48.7322178, lng: 22.1418911 },
    { lat: 48.7311035, lng: 22.1385222 },
    { lat: 48.7310089, lng: 22.138416 },
    { lat: 48.7298381, lng: 22.1350435 },
    { lat: 48.7288094, lng: 22.1324107 },
    { lat: 48.7282813, lng: 22.1315692 },
    { lat: 48.7273995, lng: 22.1343264 },
    { lat: 48.7264325, lng: 22.1373788 },
    { lat: 48.7252512, lng: 22.1413336 },
    { lat: 48.7236788, lng: 22.1460865 },
    { lat: 48.7183064, lng: 22.1388707 },
    { lat: 48.7137711, lng: 22.135974 },
    { lat: 48.7128089, lng: 22.1359112 },
    { lat: 48.7108887, lng: 22.1333011 },
    { lat: 48.7091318, lng: 22.1308007 },
    { lat: 48.7090633, lng: 22.1308037 },
    { lat: 48.7090671, lng: 22.1310139 },
    { lat: 48.7091053, lng: 22.1367991 },
    { lat: 48.7074544, lng: 22.1358989 },
    { lat: 48.7077802, lng: 22.13569 },
    { lat: 48.7076603, lng: 22.1347416 },
    { lat: 48.7069055, lng: 22.135083 },
    { lat: 48.7065029, lng: 22.1347511 },
    { lat: 48.7062186, lng: 22.1351947 },
    { lat: 48.7059188, lng: 22.1348321 },
    { lat: 48.7050892, lng: 22.1350963 },
    { lat: 48.7029951, lng: 22.1345286 },
    { lat: 48.7020486, lng: 22.1339276 },
    { lat: 48.7019653, lng: 22.1339257 },
    { lat: 48.7013475, lng: 22.1334817 },
    { lat: 48.6992436, lng: 22.1386822 },
    { lat: 48.7015481, lng: 22.1408381 },
    { lat: 48.7032427, lng: 22.1393187 },
    { lat: 48.7089569, lng: 22.1448821 },
    { lat: 48.708094, lng: 22.1468112 },
    { lat: 48.7072734, lng: 22.1487587 },
    { lat: 48.7062506, lng: 22.1512348 },
    { lat: 48.705663, lng: 22.1527723 },
    { lat: 48.7044128, lng: 22.1572247 },
    { lat: 48.7044825, lng: 22.1573168 },
    { lat: 48.7029341, lng: 22.1628438 },
    { lat: 48.7053732, lng: 22.1675469 },
    { lat: 48.7077311, lng: 22.1698594 },
    { lat: 48.7054578, lng: 22.1725884 },
    { lat: 48.7093509, lng: 22.1763731 },
    { lat: 48.706833, lng: 22.1777155 },
    { lat: 48.707931, lng: 22.1810082 },
    { lat: 48.7079927, lng: 22.181098 },
    { lat: 48.7081593, lng: 22.181158 },
    { lat: 48.7094179, lng: 22.1807107 },
    { lat: 48.7094474, lng: 22.1808694 },
    { lat: 48.7109434, lng: 22.1825848 },
    { lat: 48.7096645, lng: 22.1869654 },
    { lat: 48.709379, lng: 22.1870245 },
    { lat: 48.7091146, lng: 22.1868716 },
    { lat: 48.7089781, lng: 22.1866141 },
    { lat: 48.7087717, lng: 22.1843195 },
    { lat: 48.7086005, lng: 22.1839132 },
    { lat: 48.7066411, lng: 22.1885926 },
    { lat: 48.7083646, lng: 22.190183 },
    { lat: 48.7073424, lng: 22.1920165 },
    { lat: 48.7052141, lng: 22.1956053 },
    { lat: 48.7064072, lng: 22.1968281 },
    { lat: 48.7054321, lng: 22.1985138 },
    { lat: 48.7046983, lng: 22.1999304 },
    { lat: 48.7055529, lng: 22.2004686 },
    { lat: 48.7062798, lng: 22.2008718 },
  ],
  Inovce: [
    { lat: 48.8303307, lng: 22.3315511 },
    { lat: 48.8293861, lng: 22.3313008 },
    { lat: 48.8285083, lng: 22.3311753 },
    { lat: 48.8271376, lng: 22.3294173 },
    { lat: 48.8266781, lng: 22.3294293 },
    { lat: 48.8257401, lng: 22.3285452 },
    { lat: 48.825441, lng: 22.3281302 },
    { lat: 48.8251453, lng: 22.327884 },
    { lat: 48.8248122, lng: 22.3265138 },
    { lat: 48.8247862, lng: 22.3262513 },
    { lat: 48.824833, lng: 22.3258081 },
    { lat: 48.8247728, lng: 22.3249548 },
    { lat: 48.8244782, lng: 22.3247474 },
    { lat: 48.8245824, lng: 22.3236368 },
    { lat: 48.8246804, lng: 22.3230683 },
    { lat: 48.8250421, lng: 22.3225856 },
    { lat: 48.824133, lng: 22.3221039 },
    { lat: 48.8225905, lng: 22.3215605 },
    { lat: 48.8196079, lng: 22.3238029 },
    { lat: 48.8194702, lng: 22.323516 },
    { lat: 48.8194272, lng: 22.3239696 },
    { lat: 48.8194447, lng: 22.3248335 },
    { lat: 48.819694, lng: 22.3280918 },
    { lat: 48.8194723, lng: 22.3291888 },
    { lat: 48.818846, lng: 22.3294575 },
    { lat: 48.8188033, lng: 22.3310182 },
    { lat: 48.8186447, lng: 22.3319611 },
    { lat: 48.8183635, lng: 22.3329953 },
    { lat: 48.8176241, lng: 22.3367927 },
    { lat: 48.8172759, lng: 22.3380155 },
    { lat: 48.8172011, lng: 22.3390423 },
    { lat: 48.8170859, lng: 22.3398981 },
    { lat: 48.8168331, lng: 22.3405933 },
    { lat: 48.8166319, lng: 22.3419129 },
    { lat: 48.8166592, lng: 22.343448 },
    { lat: 48.8165729, lng: 22.3445596 },
    { lat: 48.816589, lng: 22.3447372 },
    { lat: 48.8167064, lng: 22.3448571 },
    { lat: 48.8167203, lng: 22.3450356 },
    { lat: 48.8165722, lng: 22.3460532 },
    { lat: 48.8164993, lng: 22.3461167 },
    { lat: 48.816418, lng: 22.3463569 },
    { lat: 48.8166227, lng: 22.3469372 },
    { lat: 48.8161509, lng: 22.3473765 },
    { lat: 48.8163296, lng: 22.3501117 },
    { lat: 48.8162854, lng: 22.3507216 },
    { lat: 48.8157621, lng: 22.3529221 },
    { lat: 48.8157547, lng: 22.3535669 },
    { lat: 48.8153612, lng: 22.3542122 },
    { lat: 48.8150253, lng: 22.3536444 },
    { lat: 48.8146028, lng: 22.3548324 },
    { lat: 48.8137655, lng: 22.3578571 },
    { lat: 48.8129978, lng: 22.3596689 },
    { lat: 48.8123513, lng: 22.360947 },
    { lat: 48.8118114, lng: 22.3622134 },
    { lat: 48.81104, lng: 22.364705 },
    { lat: 48.8099935, lng: 22.3669042 },
    { lat: 48.8093365, lng: 22.3669961 },
    { lat: 48.8092378, lng: 22.366957 },
    { lat: 48.808748, lng: 22.3677866 },
    { lat: 48.8083419, lng: 22.367729 },
    { lat: 48.8081709, lng: 22.3678219 },
    { lat: 48.807842, lng: 22.3678033 },
    { lat: 48.8075544, lng: 22.3677127 },
    { lat: 48.8070717, lng: 22.36791 },
    { lat: 48.8059322, lng: 22.36923 },
    { lat: 48.8040849, lng: 22.3722277 },
    { lat: 48.803832, lng: 22.3737153 },
    { lat: 48.8038268, lng: 22.3741077 },
    { lat: 48.8039732, lng: 22.3748081 },
    { lat: 48.8046004, lng: 22.3761659 },
    { lat: 48.8049958, lng: 22.3773687 },
    { lat: 48.8058412, lng: 22.3821402 },
    { lat: 48.8055263, lng: 22.3845384 },
    { lat: 48.805568, lng: 22.385401 },
    { lat: 48.806615, lng: 22.384999 },
    { lat: 48.8071332, lng: 22.3849635 },
    { lat: 48.807534, lng: 22.384936 },
    { lat: 48.808102, lng: 22.3850673 },
    { lat: 48.810844, lng: 22.385701 },
    { lat: 48.811345, lng: 22.385623 },
    { lat: 48.815136, lng: 22.385061 },
    { lat: 48.818766, lng: 22.384517 },
    { lat: 48.8195837, lng: 22.3844003 },
    { lat: 48.824503, lng: 22.383698 },
    { lat: 48.825696, lng: 22.381726 },
    { lat: 48.826225, lng: 22.381336 },
    { lat: 48.827056, lng: 22.380737 },
    { lat: 48.829263, lng: 22.379117 },
    { lat: 48.833246, lng: 22.376599 },
    { lat: 48.834026, lng: 22.375913 },
    { lat: 48.835714, lng: 22.375866 },
    { lat: 48.8351351, lng: 22.3722629 },
    { lat: 48.8359712, lng: 22.3712808 },
    { lat: 48.8375938, lng: 22.3684031 },
    { lat: 48.8377909, lng: 22.3676716 },
    { lat: 48.8378582, lng: 22.3665484 },
    { lat: 48.8382343, lng: 22.3656707 },
    { lat: 48.8382589, lng: 22.3653967 },
    { lat: 48.8381923, lng: 22.3617982 },
    { lat: 48.8382338, lng: 22.3613681 },
    { lat: 48.8385746, lng: 22.3612569 },
    { lat: 48.8387543, lng: 22.3613516 },
    { lat: 48.8389541, lng: 22.3618688 },
    { lat: 48.8390469, lng: 22.3618361 },
    { lat: 48.8391397, lng: 22.3615984 },
    { lat: 48.8391275, lng: 22.3614016 },
    { lat: 48.839239, lng: 22.3612494 },
    { lat: 48.8394247, lng: 22.3613032 },
    { lat: 48.8395599, lng: 22.3612119 },
    { lat: 48.8394454, lng: 22.3610665 },
    { lat: 48.8395212, lng: 22.3609307 },
    { lat: 48.8396966, lng: 22.3608222 },
    { lat: 48.8398935, lng: 22.3608729 },
    { lat: 48.8400866, lng: 22.3604336 },
    { lat: 48.840694, lng: 22.3602275 },
    { lat: 48.83911, lng: 22.3555592 },
    { lat: 48.8393089, lng: 22.3534519 },
    { lat: 48.8378386, lng: 22.351591 },
    { lat: 48.8381863, lng: 22.3511202 },
    { lat: 48.8385145, lng: 22.3509063 },
    { lat: 48.8388155, lng: 22.3504666 },
    { lat: 48.8389179, lng: 22.3501549 },
    { lat: 48.8390542, lng: 22.3500015 },
    { lat: 48.8396814, lng: 22.3486743 },
    { lat: 48.8387014, lng: 22.3476574 },
    { lat: 48.8380035, lng: 22.3479409 },
    { lat: 48.8372014, lng: 22.348146 },
    { lat: 48.8363106, lng: 22.3446299 },
    { lat: 48.8362439, lng: 22.3441359 },
    { lat: 48.8368727, lng: 22.3427304 },
    { lat: 48.8364713, lng: 22.3400568 },
    { lat: 48.8356673, lng: 22.3383432 },
    { lat: 48.8348757, lng: 22.3382444 },
    { lat: 48.8317267, lng: 22.3372545 },
    { lat: 48.8290267, lng: 22.3339815 },
    { lat: 48.8303307, lng: 22.3315511 },
  ],
  Priekopa: [
    { lat: 48.7404707, lng: 22.2574314 },
    { lat: 48.7405063, lng: 22.2575583 },
    { lat: 48.7405251, lng: 22.2576924 },
    { lat: 48.7406133, lng: 22.2578817 },
    { lat: 48.7406896, lng: 22.2580119 },
    { lat: 48.7409903, lng: 22.2583918 },
    { lat: 48.7409643, lng: 22.2584808 },
    { lat: 48.7405443, lng: 22.2597216 },
    { lat: 48.740264, lng: 22.2602746 },
    { lat: 48.7394661, lng: 22.2614155 },
    { lat: 48.7392372, lng: 22.2618505 },
    { lat: 48.7394096, lng: 22.2620684 },
    { lat: 48.7398732, lng: 22.2623324 },
    { lat: 48.7406684, lng: 22.2631352 },
    { lat: 48.7408564, lng: 22.2632526 },
    { lat: 48.7409631, lng: 22.2635458 },
    { lat: 48.7410966, lng: 22.2636759 },
    { lat: 48.7414067, lng: 22.2641719 },
    { lat: 48.7415392, lng: 22.2645155 },
    { lat: 48.7422896, lng: 22.2654375 },
    { lat: 48.7431357, lng: 22.2677317 },
    { lat: 48.7432448, lng: 22.2679332 },
    { lat: 48.7433083, lng: 22.2687283 },
    { lat: 48.7434107, lng: 22.268881 },
    { lat: 48.7434435, lng: 22.2688405 },
    { lat: 48.7434942, lng: 22.2690997 },
    { lat: 48.7436103, lng: 22.2692109 },
    { lat: 48.7437513, lng: 22.2697333 },
    { lat: 48.7439632, lng: 22.2702162 },
    { lat: 48.7442419, lng: 22.2713717 },
    { lat: 48.7446004, lng: 22.2723732 },
    { lat: 48.7442904, lng: 22.2730086 },
    { lat: 48.7441495, lng: 22.2734512 },
    { lat: 48.7440598, lng: 22.2739223 },
    { lat: 48.7433354, lng: 22.2760526 },
    { lat: 48.7427998, lng: 22.2788999 },
    { lat: 48.7428549, lng: 22.279061 },
    { lat: 48.7429635, lng: 22.2791318 },
    { lat: 48.7432539, lng: 22.2796582 },
    { lat: 48.7434268, lng: 22.2797909 },
    { lat: 48.7435757, lng: 22.280272 },
    { lat: 48.7440008, lng: 22.2803194 },
    { lat: 48.7439957, lng: 22.2804932 },
    { lat: 48.7441181, lng: 22.2806909 },
    { lat: 48.7442201, lng: 22.2806779 },
    { lat: 48.7443997, lng: 22.2808695 },
    { lat: 48.7444501, lng: 22.2811154 },
    { lat: 48.7444202, lng: 22.2812876 },
    { lat: 48.7443135, lng: 22.2814911 },
    { lat: 48.7443395, lng: 22.2815514 },
    { lat: 48.7444363, lng: 22.2815562 },
    { lat: 48.7444901, lng: 22.2816479 },
    { lat: 48.7444705, lng: 22.2817316 },
    { lat: 48.7445568, lng: 22.2817636 },
    { lat: 48.7445881, lng: 22.2818579 },
    { lat: 48.7445438, lng: 22.282096 },
    { lat: 48.7446124, lng: 22.2821995 },
    { lat: 48.7445943, lng: 22.2829107 },
    { lat: 48.7445201, lng: 22.2830081 },
    { lat: 48.744563, lng: 22.2832241 },
    { lat: 48.7448847, lng: 22.2835304 },
    { lat: 48.7448351, lng: 22.2836783 },
    { lat: 48.745015, lng: 22.2839793 },
    { lat: 48.7451319, lng: 22.2839282 },
    { lat: 48.745388, lng: 22.2843169 },
    { lat: 48.7455135, lng: 22.2841976 },
    { lat: 48.7457361, lng: 22.2843507 },
    { lat: 48.7457876, lng: 22.284536 },
    { lat: 48.7459418, lng: 22.2846487 },
    { lat: 48.7462393, lng: 22.2845197 },
    { lat: 48.7463319, lng: 22.284639 },
    { lat: 48.7465371, lng: 22.284612 },
    { lat: 48.7465195, lng: 22.2847761 },
    { lat: 48.7465971, lng: 22.2848884 },
    { lat: 48.7466943, lng: 22.2848592 },
    { lat: 48.7469167, lng: 22.2851421 },
    { lat: 48.7471235, lng: 22.2851356 },
    { lat: 48.7471836, lng: 22.285361 },
    { lat: 48.7473192, lng: 22.2854957 },
    { lat: 48.747515, lng: 22.2859638 },
    { lat: 48.7475576, lng: 22.2872921 },
    { lat: 48.7476931, lng: 22.2875672 },
    { lat: 48.7476327, lng: 22.2878896 },
    { lat: 48.7476946, lng: 22.2884 },
    { lat: 48.7477348, lng: 22.2885445 },
    { lat: 48.7478366, lng: 22.2886705 },
    { lat: 48.7478804, lng: 22.288875 },
    { lat: 48.7478602, lng: 22.2904646 },
    { lat: 48.7479729, lng: 22.2907244 },
    { lat: 48.7480759, lng: 22.2920903 },
    { lat: 48.7480253, lng: 22.2921492 },
    { lat: 48.7480019, lng: 22.2925652 },
    { lat: 48.7479581, lng: 22.292674 },
    { lat: 48.748005, lng: 22.2928145 },
    { lat: 48.748052, lng: 22.2936647 },
    { lat: 48.7481852, lng: 22.293797 },
    { lat: 48.748924, lng: 22.2957384 },
    { lat: 48.7491658, lng: 22.2960941 },
    { lat: 48.7493723, lng: 22.2966466 },
    { lat: 48.7497145, lng: 22.2970621 },
    { lat: 48.7500378, lng: 22.2986331 },
    { lat: 48.7503894, lng: 22.2993875 },
    { lat: 48.7509962, lng: 22.2997695 },
    { lat: 48.7515533, lng: 22.2999377 },
    { lat: 48.7523281, lng: 22.3006524 },
    { lat: 48.7527038, lng: 22.3012164 },
    { lat: 48.75283, lng: 22.3016386 },
    { lat: 48.7528847, lng: 22.3020835 },
    { lat: 48.7530874, lng: 22.30271 },
    { lat: 48.7532345, lng: 22.3028103 },
    { lat: 48.7534405, lng: 22.3033275 },
    { lat: 48.7536692, lng: 22.3044742 },
    { lat: 48.7536993, lng: 22.3054429 },
    { lat: 48.7536712, lng: 22.305918 },
    { lat: 48.7537337, lng: 22.3073909 },
    { lat: 48.7536689, lng: 22.3093171 },
    { lat: 48.7537286, lng: 22.3101942 },
    { lat: 48.7539399, lng: 22.3107665 },
    { lat: 48.754184, lng: 22.3110751 },
    { lat: 48.7543426, lng: 22.3113798 },
    { lat: 48.7549206, lng: 22.3120953 },
    { lat: 48.7548969, lng: 22.3124038 },
    { lat: 48.7547732, lng: 22.3128705 },
    { lat: 48.7547582, lng: 22.3144606 },
    { lat: 48.7545185, lng: 22.3176178 },
    { lat: 48.7545198, lng: 22.3186518 },
    { lat: 48.7546, lng: 22.3196562 },
    { lat: 48.7548024, lng: 22.3208498 },
    { lat: 48.7555503, lng: 22.3238536 },
    { lat: 48.7556578, lng: 22.3245623 },
    { lat: 48.7556552, lng: 22.3251192 },
    { lat: 48.7555281, lng: 22.325488 },
    { lat: 48.7553536, lng: 22.3269848 },
    { lat: 48.7550377, lng: 22.3289663 },
    { lat: 48.7550243, lng: 22.3293822 },
    { lat: 48.7549001, lng: 22.330247 },
    { lat: 48.7542159, lng: 22.3327905 },
    { lat: 48.7540459, lng: 22.3333546 },
    { lat: 48.7532859, lng: 22.3353489 },
    { lat: 48.7527927, lng: 22.3359524 },
    { lat: 48.7523307, lng: 22.3368484 },
    { lat: 48.7521952, lng: 22.3372411 },
    { lat: 48.7519679, lng: 22.3384406 },
    { lat: 48.7517413, lng: 22.3392502 },
    { lat: 48.7516145, lng: 22.3408346 },
    { lat: 48.7516415, lng: 22.3413562 },
    { lat: 48.7519118, lng: 22.3427825 },
    { lat: 48.751945, lng: 22.343257 },
    { lat: 48.75424, lng: 22.344011 },
    { lat: 48.755888, lng: 22.344901 },
    { lat: 48.757125, lng: 22.345619 },
    { lat: 48.757968, lng: 22.345744 },
    { lat: 48.763787, lng: 22.346215 },
    { lat: 48.765264, lng: 22.346013 },
    { lat: 48.765983, lng: 22.345755 },
    { lat: 48.7659808, lng: 22.3453939 },
    { lat: 48.7658244, lng: 22.3448672 },
    { lat: 48.7657985, lng: 22.3446046 },
    { lat: 48.7658997, lng: 22.344185 },
    { lat: 48.7659078, lng: 22.3437718 },
    { lat: 48.7656059, lng: 22.3428154 },
    { lat: 48.7655916, lng: 22.3418885 },
    { lat: 48.7654354, lng: 22.3411546 },
    { lat: 48.7648063, lng: 22.3411392 },
    { lat: 48.7644985, lng: 22.3404278 },
    { lat: 48.7650362, lng: 22.3400619 },
    { lat: 48.7651679, lng: 22.339019 },
    { lat: 48.7648566, lng: 22.3386689 },
    { lat: 48.7644588, lng: 22.3387912 },
    { lat: 48.7644529, lng: 22.3385601 },
    { lat: 48.7649153, lng: 22.3382101 },
    { lat: 48.7652273, lng: 22.338122 },
    { lat: 48.76529, lng: 22.3379021 },
    { lat: 48.7652898, lng: 22.3376312 },
    { lat: 48.7650488, lng: 22.3372498 },
    { lat: 48.7651198, lng: 22.3367857 },
    { lat: 48.7654559, lng: 22.3362062 },
    { lat: 48.7657238, lng: 22.3355865 },
    { lat: 48.7657261, lng: 22.3349618 },
    { lat: 48.7654563, lng: 22.3344952 },
    { lat: 48.7655828, lng: 22.3341107 },
    { lat: 48.7657656, lng: 22.3338767 },
    { lat: 48.7658727, lng: 22.3335185 },
    { lat: 48.7659111, lng: 22.3329062 },
    { lat: 48.7656032, lng: 22.3327029 },
    { lat: 48.7663877, lng: 22.3311696 },
    { lat: 48.766507, lng: 22.3304956 },
    { lat: 48.7664522, lng: 22.3298362 },
    { lat: 48.7665122, lng: 22.3295373 },
    { lat: 48.7668633, lng: 22.3286073 },
    { lat: 48.7669697, lng: 22.3281207 },
    { lat: 48.7671076, lng: 22.3278358 },
    { lat: 48.7674587, lng: 22.3275403 },
    { lat: 48.7678042, lng: 22.3270807 },
    { lat: 48.7681457, lng: 22.3264788 },
    { lat: 48.7682199, lng: 22.3258597 },
    { lat: 48.7686296, lng: 22.3253046 },
    { lat: 48.7691334, lng: 22.3236338 },
    { lat: 48.7697135, lng: 22.3190605 },
    { lat: 48.7696896, lng: 22.3179571 },
    { lat: 48.7694944, lng: 22.3174569 },
    { lat: 48.7694029, lng: 22.3170275 },
    { lat: 48.769391, lng: 22.3159061 },
    { lat: 48.76932, lng: 22.314656 },
    { lat: 48.769147, lng: 22.3136766 },
    { lat: 48.7689595, lng: 22.3130762 },
    { lat: 48.7687549, lng: 22.3121012 },
    { lat: 48.7685853, lng: 22.3107167 },
    { lat: 48.7683737, lng: 22.3100929 },
    { lat: 48.7679464, lng: 22.3093113 },
    { lat: 48.7676241, lng: 22.3066544 },
    { lat: 48.7677444, lng: 22.3053641 },
    { lat: 48.7677511, lng: 22.3047972 },
    { lat: 48.7675131, lng: 22.3032621 },
    { lat: 48.7668817, lng: 22.3021616 },
    { lat: 48.7666389, lng: 22.3012443 },
    { lat: 48.7660664, lng: 22.2994963 },
    { lat: 48.7655985, lng: 22.2986195 },
    { lat: 48.7650598, lng: 22.2967704 },
    { lat: 48.7643351, lng: 22.293823 },
    { lat: 48.7639891, lng: 22.2928781 },
    { lat: 48.7637668, lng: 22.2921203 },
    { lat: 48.7638018, lng: 22.2915124 },
    { lat: 48.7639785, lng: 22.2903852 },
    { lat: 48.7638993, lng: 22.2898572 },
    { lat: 48.7636245, lng: 22.2892785 },
    { lat: 48.7635077, lng: 22.2887115 },
    { lat: 48.7634079, lng: 22.2877628 },
    { lat: 48.7635604, lng: 22.286563 },
    { lat: 48.7632129, lng: 22.284723 },
    { lat: 48.7632365, lng: 22.2841033 },
    { lat: 48.7634444, lng: 22.2826707 },
    { lat: 48.7634347, lng: 22.2807916 },
    { lat: 48.7635889, lng: 22.2790612 },
    { lat: 48.7634305, lng: 22.2784117 },
    { lat: 48.7630089, lng: 22.2778009 },
    { lat: 48.7626481, lng: 22.2769517 },
    { lat: 48.7623447, lng: 22.275279 },
    { lat: 48.7623129, lng: 22.2743401 },
    { lat: 48.7623942, lng: 22.2737542 },
    { lat: 48.7623528, lng: 22.2726393 },
    { lat: 48.7623766, lng: 22.272041 },
    { lat: 48.7630455, lng: 22.2689718 },
    { lat: 48.7630468, lng: 22.268571 },
    { lat: 48.7631501, lng: 22.2676463 },
    { lat: 48.7627543, lng: 22.2665352 },
    { lat: 48.7622424, lng: 22.2656107 },
    { lat: 48.7615654, lng: 22.2648632 },
    { lat: 48.7612578, lng: 22.2642763 },
    { lat: 48.7604072, lng: 22.2636517 },
    { lat: 48.7603212, lng: 22.2629712 },
    { lat: 48.7603713, lng: 22.2627972 },
    { lat: 48.7603179, lng: 22.2624712 },
    { lat: 48.7602989, lng: 22.261766 },
    { lat: 48.7603992, lng: 22.261219 },
    { lat: 48.7605081, lng: 22.2612081 },
    { lat: 48.7604493, lng: 22.2609381 },
    { lat: 48.7601876, lng: 22.2606023 },
    { lat: 48.7602088, lng: 22.2608497 },
    { lat: 48.7596373, lng: 22.2602342 },
    { lat: 48.7585226, lng: 22.2593184 },
    { lat: 48.7571145, lng: 22.2571491 },
    { lat: 48.7566991, lng: 22.2548571 },
    { lat: 48.7568385, lng: 22.2542451 },
    { lat: 48.7570748, lng: 22.2524318 },
    { lat: 48.7572359, lng: 22.2499735 },
    { lat: 48.7569799, lng: 22.2485229 },
    { lat: 48.7555821, lng: 22.2465741 },
    { lat: 48.7553799, lng: 22.24684 },
    { lat: 48.7543163, lng: 22.2457946 },
    { lat: 48.7539824, lng: 22.2436387 },
    { lat: 48.7537981, lng: 22.2445015 },
    { lat: 48.7536546, lng: 22.2450752 },
    { lat: 48.7533308, lng: 22.245183 },
    { lat: 48.7533596, lng: 22.2453641 },
    { lat: 48.7529139, lng: 22.2455023 },
    { lat: 48.7519203, lng: 22.2459918 },
    { lat: 48.7518791, lng: 22.2460014 },
    { lat: 48.7518777, lng: 22.2458988 },
    { lat: 48.7517846, lng: 22.2458551 },
    { lat: 48.7517894, lng: 22.2455109 },
    { lat: 48.7516857, lng: 22.2453027 },
    { lat: 48.7516605, lng: 22.2449344 },
    { lat: 48.7516073, lng: 22.2449132 },
    { lat: 48.7513998, lng: 22.2444886 },
    { lat: 48.7514023, lng: 22.2443976 },
    { lat: 48.7512858, lng: 22.2442681 },
    { lat: 48.7512511, lng: 22.2439206 },
    { lat: 48.7513157, lng: 22.2437925 },
    { lat: 48.7512882, lng: 22.24362 },
    { lat: 48.7513522, lng: 22.2434673 },
    { lat: 48.7512778, lng: 22.2432074 },
    { lat: 48.7513494, lng: 22.2430872 },
    { lat: 48.7511861, lng: 22.2421203 },
    { lat: 48.7513943, lng: 22.2417211 },
    { lat: 48.7512753, lng: 22.2412396 },
    { lat: 48.7514011, lng: 22.2409181 },
    { lat: 48.7513306, lng: 22.2408444 },
    { lat: 48.7512781, lng: 22.240605 },
    { lat: 48.7511815, lng: 22.2405622 },
    { lat: 48.751293, lng: 22.2402172 },
    { lat: 48.7511775, lng: 22.2403332 },
    { lat: 48.7510614, lng: 22.2400219 },
    { lat: 48.7509475, lng: 22.2400623 },
    { lat: 48.7509077, lng: 22.2399902 },
    { lat: 48.7509505, lng: 22.2398373 },
    { lat: 48.7509275, lng: 22.2397682 },
    { lat: 48.7510372, lng: 22.2385821 },
    { lat: 48.7504618, lng: 22.2398127 },
    { lat: 48.7502753, lng: 22.2403773 },
    { lat: 48.7501238, lng: 22.2411913 },
    { lat: 48.7501237, lng: 22.2417983 },
    { lat: 48.7497017, lng: 22.2417407 },
    { lat: 48.7492671, lng: 22.241363 },
    { lat: 48.7491143, lng: 22.2417273 },
    { lat: 48.7492364, lng: 22.2433092 },
    { lat: 48.7495364, lng: 22.2457126 },
    { lat: 48.7453574, lng: 22.249321 },
    { lat: 48.7446925, lng: 22.2494704 },
    { lat: 48.7440148, lng: 22.2490851 },
    { lat: 48.7439483, lng: 22.2494869 },
    { lat: 48.744036, lng: 22.2497843 },
    { lat: 48.7439116, lng: 22.2503648 },
    { lat: 48.7435448, lng: 22.2496971 },
    { lat: 48.7427503, lng: 22.2496343 },
    { lat: 48.7426796, lng: 22.2506511 },
    { lat: 48.7426149, lng: 22.2506237 },
    { lat: 48.7424898, lng: 22.2508165 },
    { lat: 48.7422162, lng: 22.2507046 },
    { lat: 48.7421278, lng: 22.2514732 },
    { lat: 48.7417686, lng: 22.2526693 },
    { lat: 48.7413592, lng: 22.2520683 },
    { lat: 48.7412456, lng: 22.2518143 },
    { lat: 48.7409208, lng: 22.2514221 },
    { lat: 48.7408751, lng: 22.2515391 },
    { lat: 48.7403236, lng: 22.2511189 },
    { lat: 48.7402685, lng: 22.2513726 },
    { lat: 48.7402907, lng: 22.2518629 },
    { lat: 48.74043, lng: 22.2521035 },
    { lat: 48.7402045, lng: 22.2527414 },
    { lat: 48.7407185, lng: 22.2542526 },
    { lat: 48.7418244, lng: 22.256141 },
    { lat: 48.7411213, lng: 22.2578043 },
    { lat: 48.7405745, lng: 22.2572843 },
    { lat: 48.7404707, lng: 22.2574314 },
  ],
  Koňuš: [
    { lat: 48.7934621, lng: 22.2766082 },
    { lat: 48.7933148, lng: 22.2758901 },
    { lat: 48.7932612, lng: 22.2753952 },
    { lat: 48.7928711, lng: 22.2745919 },
    { lat: 48.7923855, lng: 22.2740953 },
    { lat: 48.7916306, lng: 22.2729659 },
    { lat: 48.7894308, lng: 22.2685446 },
    { lat: 48.788314, lng: 22.2673257 },
    { lat: 48.7876072, lng: 22.266392 },
    { lat: 48.7867504, lng: 22.2655359 },
    { lat: 48.7813215, lng: 22.2579476 },
    { lat: 48.7813283, lng: 22.257694 },
    { lat: 48.781268, lng: 22.2574624 },
    { lat: 48.7810089, lng: 22.2573936 },
    { lat: 48.7801171, lng: 22.2591272 },
    { lat: 48.7788798, lng: 22.2586096 },
    { lat: 48.7789416, lng: 22.2576471 },
    { lat: 48.7780744, lng: 22.2582058 },
    { lat: 48.7776499, lng: 22.2583847 },
    { lat: 48.7778752, lng: 22.2571318 },
    { lat: 48.7777889, lng: 22.257046 },
    { lat: 48.7779184, lng: 22.2562994 },
    { lat: 48.7778445, lng: 22.2557072 },
    { lat: 48.7779366, lng: 22.2554946 },
    { lat: 48.7780609, lng: 22.254513 },
    { lat: 48.7781778, lng: 22.2541005 },
    { lat: 48.778389, lng: 22.2536846 },
    { lat: 48.7784068, lng: 22.2534822 },
    { lat: 48.7784815, lng: 22.2533946 },
    { lat: 48.7785469, lng: 22.2529002 },
    { lat: 48.778692, lng: 22.2526991 },
    { lat: 48.7786173, lng: 22.2523167 },
    { lat: 48.7785141, lng: 22.2522849 },
    { lat: 48.7784671, lng: 22.252038 },
    { lat: 48.7783537, lng: 22.2512449 },
    { lat: 48.7784126, lng: 22.251105 },
    { lat: 48.7784074, lng: 22.2505735 },
    { lat: 48.7781522, lng: 22.2497724 },
    { lat: 48.7781612, lng: 22.2494673 },
    { lat: 48.7779957, lng: 22.2491588 },
    { lat: 48.7778689, lng: 22.2484634 },
    { lat: 48.7776596, lng: 22.2478593 },
    { lat: 48.7775154, lng: 22.2476409 },
    { lat: 48.7775279, lng: 22.2469933 },
    { lat: 48.7773096, lng: 22.2468358 },
    { lat: 48.7772002, lng: 22.2466217 },
    { lat: 48.7774196, lng: 22.2461241 },
    { lat: 48.7770829, lng: 22.2459484 },
    { lat: 48.7765208, lng: 22.2459193 },
    { lat: 48.7761987, lng: 22.2461961 },
    { lat: 48.7746501, lng: 22.2484747 },
    { lat: 48.7745748, lng: 22.248174 },
    { lat: 48.7744326, lng: 22.2480001 },
    { lat: 48.7742246, lng: 22.2475511 },
    { lat: 48.7742424, lng: 22.2472406 },
    { lat: 48.7741774, lng: 22.2466517 },
    { lat: 48.77441, lng: 22.2458267 },
    { lat: 48.7742485, lng: 22.2445912 },
    { lat: 48.7740894, lng: 22.2442616 },
    { lat: 48.7741098, lng: 22.2436233 },
    { lat: 48.7738504, lng: 22.2431327 },
    { lat: 48.7730768, lng: 22.2443565 },
    { lat: 48.7726608, lng: 22.2440611 },
    { lat: 48.7721465, lng: 22.2435408 },
    { lat: 48.7717978, lng: 22.2430419 },
    { lat: 48.7717674, lng: 22.2428805 },
    { lat: 48.7713524, lng: 22.2422835 },
    { lat: 48.7712522, lng: 22.2424312 },
    { lat: 48.7711322, lng: 22.2422484 },
    { lat: 48.7651226, lng: 22.2419942 },
    { lat: 48.7647949, lng: 22.2420834 },
    { lat: 48.7642675, lng: 22.242341 },
    { lat: 48.7639119, lng: 22.2423325 },
    { lat: 48.7623252, lng: 22.2417448 },
    { lat: 48.7620843, lng: 22.2414284 },
    { lat: 48.7616831, lng: 22.241044 },
    { lat: 48.760128, lng: 22.2398017 },
    { lat: 48.7600269, lng: 22.2401173 },
    { lat: 48.7597548, lng: 22.2406186 },
    { lat: 48.7597235, lng: 22.2416875 },
    { lat: 48.760268, lng: 22.2436076 },
    { lat: 48.7597131, lng: 22.2444926 },
    { lat: 48.7588401, lng: 22.2450916 },
    { lat: 48.7584769, lng: 22.2445889 },
    { lat: 48.757922, lng: 22.2440676 },
    { lat: 48.7566131, lng: 22.2431243 },
    { lat: 48.7558406, lng: 22.2447415 },
    { lat: 48.7552232, lng: 22.2438419 },
    { lat: 48.7548814, lng: 22.2429961 },
    { lat: 48.7544121, lng: 22.2433858 },
    { lat: 48.7539824, lng: 22.2436387 },
    { lat: 48.7543163, lng: 22.2457946 },
    { lat: 48.7553799, lng: 22.24684 },
    { lat: 48.7555821, lng: 22.2465741 },
    { lat: 48.7569799, lng: 22.2485229 },
    { lat: 48.7572359, lng: 22.2499735 },
    { lat: 48.7570748, lng: 22.2524318 },
    { lat: 48.7568385, lng: 22.2542451 },
    { lat: 48.7566991, lng: 22.2548571 },
    { lat: 48.7571145, lng: 22.2571491 },
    { lat: 48.7585226, lng: 22.2593184 },
    { lat: 48.7596373, lng: 22.2602342 },
    { lat: 48.7602088, lng: 22.2608497 },
    { lat: 48.7601876, lng: 22.2606023 },
    { lat: 48.7604493, lng: 22.2609381 },
    { lat: 48.7605081, lng: 22.2612081 },
    { lat: 48.7603992, lng: 22.261219 },
    { lat: 48.7602989, lng: 22.261766 },
    { lat: 48.7603179, lng: 22.2624712 },
    { lat: 48.7603713, lng: 22.2627972 },
    { lat: 48.7603212, lng: 22.2629712 },
    { lat: 48.7604072, lng: 22.2636517 },
    { lat: 48.7612578, lng: 22.2642763 },
    { lat: 48.7615654, lng: 22.2648632 },
    { lat: 48.7622424, lng: 22.2656107 },
    { lat: 48.7627543, lng: 22.2665352 },
    { lat: 48.7631501, lng: 22.2676463 },
    { lat: 48.7630468, lng: 22.268571 },
    { lat: 48.7630455, lng: 22.2689718 },
    { lat: 48.7623766, lng: 22.272041 },
    { lat: 48.7623528, lng: 22.2726393 },
    { lat: 48.7623942, lng: 22.2737542 },
    { lat: 48.7623129, lng: 22.2743401 },
    { lat: 48.7623447, lng: 22.275279 },
    { lat: 48.7626481, lng: 22.2769517 },
    { lat: 48.7630089, lng: 22.2778009 },
    { lat: 48.7634305, lng: 22.2784117 },
    { lat: 48.7635889, lng: 22.2790612 },
    { lat: 48.7634347, lng: 22.2807916 },
    { lat: 48.7634444, lng: 22.2826707 },
    { lat: 48.7632365, lng: 22.2841033 },
    { lat: 48.7632129, lng: 22.284723 },
    { lat: 48.7635604, lng: 22.286563 },
    { lat: 48.7634079, lng: 22.2877628 },
    { lat: 48.7635077, lng: 22.2887115 },
    { lat: 48.7636245, lng: 22.2892785 },
    { lat: 48.7638993, lng: 22.2898572 },
    { lat: 48.7639785, lng: 22.2903852 },
    { lat: 48.7638018, lng: 22.2915124 },
    { lat: 48.7637668, lng: 22.2921203 },
    { lat: 48.7639891, lng: 22.2928781 },
    { lat: 48.7643351, lng: 22.293823 },
    { lat: 48.7650598, lng: 22.2967704 },
    { lat: 48.7655985, lng: 22.2986195 },
    { lat: 48.7660664, lng: 22.2994963 },
    { lat: 48.7666389, lng: 22.3012443 },
    { lat: 48.7668817, lng: 22.3021616 },
    { lat: 48.7675131, lng: 22.3032621 },
    { lat: 48.7677511, lng: 22.3047972 },
    { lat: 48.7677444, lng: 22.3053641 },
    { lat: 48.7676241, lng: 22.3066544 },
    { lat: 48.7679464, lng: 22.3093113 },
    { lat: 48.7683737, lng: 22.3100929 },
    { lat: 48.7685853, lng: 22.3107167 },
    { lat: 48.7687549, lng: 22.3121012 },
    { lat: 48.7689595, lng: 22.3130762 },
    { lat: 48.769147, lng: 22.3136766 },
    { lat: 48.76932, lng: 22.314656 },
    { lat: 48.769391, lng: 22.3159061 },
    { lat: 48.7694029, lng: 22.3170275 },
    { lat: 48.7694944, lng: 22.3174569 },
    { lat: 48.7696896, lng: 22.3179571 },
    { lat: 48.7697135, lng: 22.3190605 },
    { lat: 48.7691334, lng: 22.3236338 },
    { lat: 48.7686296, lng: 22.3253046 },
    { lat: 48.7682199, lng: 22.3258597 },
    { lat: 48.7681457, lng: 22.3264788 },
    { lat: 48.7678042, lng: 22.3270807 },
    { lat: 48.7674587, lng: 22.3275403 },
    { lat: 48.7671076, lng: 22.3278358 },
    { lat: 48.7669697, lng: 22.3281207 },
    { lat: 48.7668633, lng: 22.3286073 },
    { lat: 48.7665122, lng: 22.3295373 },
    { lat: 48.7664522, lng: 22.3298362 },
    { lat: 48.766507, lng: 22.3304956 },
    { lat: 48.7663877, lng: 22.3311696 },
    { lat: 48.7656032, lng: 22.3327029 },
    { lat: 48.7659111, lng: 22.3329062 },
    { lat: 48.7658727, lng: 22.3335185 },
    { lat: 48.7657656, lng: 22.3338767 },
    { lat: 48.7655828, lng: 22.3341107 },
    { lat: 48.7654563, lng: 22.3344952 },
    { lat: 48.7657261, lng: 22.3349618 },
    { lat: 48.7657238, lng: 22.3355865 },
    { lat: 48.7654559, lng: 22.3362062 },
    { lat: 48.7651198, lng: 22.3367857 },
    { lat: 48.7650488, lng: 22.3372498 },
    { lat: 48.7652898, lng: 22.3376312 },
    { lat: 48.76529, lng: 22.3379021 },
    { lat: 48.7652273, lng: 22.338122 },
    { lat: 48.7649153, lng: 22.3382101 },
    { lat: 48.7644529, lng: 22.3385601 },
    { lat: 48.7644588, lng: 22.3387912 },
    { lat: 48.7648566, lng: 22.3386689 },
    { lat: 48.7651679, lng: 22.339019 },
    { lat: 48.7650362, lng: 22.3400619 },
    { lat: 48.7644985, lng: 22.3404278 },
    { lat: 48.7648063, lng: 22.3411392 },
    { lat: 48.7654354, lng: 22.3411546 },
    { lat: 48.7655916, lng: 22.3418885 },
    { lat: 48.7656059, lng: 22.3428154 },
    { lat: 48.7659078, lng: 22.3437718 },
    { lat: 48.7658997, lng: 22.344185 },
    { lat: 48.7657985, lng: 22.3446046 },
    { lat: 48.7658244, lng: 22.3448672 },
    { lat: 48.7659808, lng: 22.3453939 },
    { lat: 48.765983, lng: 22.345755 },
    { lat: 48.766147, lng: 22.345697 },
    { lat: 48.767585, lng: 22.34732 },
    { lat: 48.770483, lng: 22.349589 },
    { lat: 48.7716, lng: 22.350285 },
    { lat: 48.7716614, lng: 22.3501946 },
    { lat: 48.771879, lng: 22.351124 },
    { lat: 48.772343, lng: 22.351973 },
    { lat: 48.772525, lng: 22.354478 },
    { lat: 48.772441, lng: 22.356219 },
    { lat: 48.773012, lng: 22.357935 },
    { lat: 48.77824, lng: 22.362285 },
    { lat: 48.779073, lng: 22.363744 },
    { lat: 48.7793136, lng: 22.3638697 },
    { lat: 48.779507, lng: 22.364349 },
    { lat: 48.77951, lng: 22.364356 },
    { lat: 48.7796272, lng: 22.3645021 },
    { lat: 48.7799688, lng: 22.3644858 },
    { lat: 48.7807106, lng: 22.3633077 },
    { lat: 48.7823129, lng: 22.3612756 },
    { lat: 48.783112, lng: 22.3593054 },
    { lat: 48.7843884, lng: 22.3576017 },
    { lat: 48.7847562, lng: 22.3568693 },
    { lat: 48.7852502, lng: 22.3561401 },
    { lat: 48.7859034, lng: 22.3553948 },
    { lat: 48.7861612, lng: 22.3547755 },
    { lat: 48.7868166, lng: 22.3536078 },
    { lat: 48.7875721, lng: 22.3527397 },
    { lat: 48.7879389, lng: 22.351599 },
    { lat: 48.7881596, lng: 22.35124 },
    { lat: 48.788466, lng: 22.3509011 },
    { lat: 48.7888353, lng: 22.3502842 },
    { lat: 48.7890753, lng: 22.3495687 },
    { lat: 48.7888781, lng: 22.3483942 },
    { lat: 48.7890672, lng: 22.3466204 },
    { lat: 48.7891978, lng: 22.3459362 },
    { lat: 48.7891802, lng: 22.3454937 },
    { lat: 48.789052, lng: 22.3448559 },
    { lat: 48.7890463, lng: 22.3443365 },
    { lat: 48.7891595, lng: 22.3435844 },
    { lat: 48.7893788, lng: 22.34306 },
    { lat: 48.7894756, lng: 22.3422805 },
    { lat: 48.7896006, lng: 22.3417015 },
    { lat: 48.7899277, lng: 22.3408539 },
    { lat: 48.7902015, lng: 22.3404117 },
    { lat: 48.7907455, lng: 22.3391882 },
    { lat: 48.7917725, lng: 22.3378877 },
    { lat: 48.792219, lng: 22.3368976 },
    { lat: 48.7927129, lng: 22.3367395 },
    { lat: 48.7932348, lng: 22.3359664 },
    { lat: 48.7936855, lng: 22.3355325 },
    { lat: 48.7938104, lng: 22.3351697 },
    { lat: 48.7947474, lng: 22.3352768 },
    { lat: 48.7951986, lng: 22.333355 },
    { lat: 48.7951657, lng: 22.33287 },
    { lat: 48.7950061, lng: 22.3324609 },
    { lat: 48.7946497, lng: 22.3325127 },
    { lat: 48.794452, lng: 22.3326363 },
    { lat: 48.7940616, lng: 22.333354 },
    { lat: 48.7932971, lng: 22.3332134 },
    { lat: 48.7926, lng: 22.332757 },
    { lat: 48.7929662, lng: 22.3319574 },
    { lat: 48.7929733, lng: 22.3308755 },
    { lat: 48.7927974, lng: 22.3301208 },
    { lat: 48.7930396, lng: 22.3300386 },
    { lat: 48.7926286, lng: 22.3291339 },
    { lat: 48.7923784, lng: 22.3283733 },
    { lat: 48.7923589, lng: 22.3278428 },
    { lat: 48.7925309, lng: 22.3266404 },
    { lat: 48.7923969, lng: 22.3263745 },
    { lat: 48.7920333, lng: 22.3260911 },
    { lat: 48.7923078, lng: 22.3256861 },
    { lat: 48.7917111, lng: 22.3245667 },
    { lat: 48.7911541, lng: 22.3241913 },
    { lat: 48.7904153, lng: 22.3232566 },
    { lat: 48.7901478, lng: 22.3231604 },
    { lat: 48.7893441, lng: 22.3215726 },
    { lat: 48.7892951, lng: 22.3209202 },
    { lat: 48.7894973, lng: 22.3204386 },
    { lat: 48.7893511, lng: 22.3196626 },
    { lat: 48.7893711, lng: 22.3185658 },
    { lat: 48.7893208, lng: 22.3176459 },
    { lat: 48.7900393, lng: 22.3168073 },
    { lat: 48.7902116, lng: 22.3167602 },
    { lat: 48.7907015, lng: 22.3163774 },
    { lat: 48.7914541, lng: 22.315608 },
    { lat: 48.7925735, lng: 22.3125114 },
    { lat: 48.7931719, lng: 22.3115908 },
    { lat: 48.7934812, lng: 22.3108131 },
    { lat: 48.7936641, lng: 22.3093967 },
    { lat: 48.7938285, lng: 22.3087835 },
    { lat: 48.7943091, lng: 22.3082302 },
    { lat: 48.7943009, lng: 22.3069271 },
    { lat: 48.7940895, lng: 22.3050634 },
    { lat: 48.7932004, lng: 22.3026721 },
    { lat: 48.7924226, lng: 22.3014636 },
    { lat: 48.7922957, lng: 22.3006456 },
    { lat: 48.7921987, lng: 22.2981595 },
    { lat: 48.7922567, lng: 22.2975687 },
    { lat: 48.7925047, lng: 22.2963103 },
    { lat: 48.7925666, lng: 22.2957464 },
    { lat: 48.7924639, lng: 22.2943914 },
    { lat: 48.7927832, lng: 22.2923346 },
    { lat: 48.792798, lng: 22.2914713 },
    { lat: 48.7927287, lng: 22.2904179 },
    { lat: 48.7929027, lng: 22.2893229 },
    { lat: 48.7928374, lng: 22.2886042 },
    { lat: 48.7929257, lng: 22.2882659 },
    { lat: 48.7931936, lng: 22.2877698 },
    { lat: 48.7932404, lng: 22.2867226 },
    { lat: 48.7932135, lng: 22.2852924 },
    { lat: 48.7930694, lng: 22.2838514 },
    { lat: 48.793179, lng: 22.2830079 },
    { lat: 48.793068, lng: 22.2820967 },
    { lat: 48.792966, lng: 22.2818072 },
    { lat: 48.7930034, lng: 22.2812581 },
    { lat: 48.793219, lng: 22.2803262 },
    { lat: 48.7929773, lng: 22.2787488 },
    { lat: 48.7930033, lng: 22.2783947 },
    { lat: 48.7933065, lng: 22.2772475 },
    { lat: 48.7934621, lng: 22.2766082 },
  ],
  NižnéNemecké: [
    { lat: 48.6731984, lng: 22.2388508 },
    { lat: 48.6732005, lng: 22.2379898 },
    { lat: 48.6728628, lng: 22.2348186 },
    { lat: 48.6717523, lng: 22.2350143 },
    { lat: 48.6718075, lng: 22.2354688 },
    { lat: 48.6711548, lng: 22.2356524 },
    { lat: 48.6711762, lng: 22.2361961 },
    { lat: 48.661045, lng: 22.2310946 },
    { lat: 48.6611636, lng: 22.2308241 },
    { lat: 48.6583936, lng: 22.22978 },
    { lat: 48.6536707, lng: 22.227744 },
    { lat: 48.6529988, lng: 22.2274442 },
    { lat: 48.6519358, lng: 22.2267473 },
    { lat: 48.6515187, lng: 22.2266467 },
    { lat: 48.6514369, lng: 22.2266533 },
    { lat: 48.6473132, lng: 22.2260031 },
    { lat: 48.6459307, lng: 22.2261446 },
    { lat: 48.6446489, lng: 22.2260709 },
    { lat: 48.6444991, lng: 22.2260143 },
    { lat: 48.6444676, lng: 22.2239162 },
    { lat: 48.6443914, lng: 22.2235805 },
    { lat: 48.6442839, lng: 22.2235042 },
    { lat: 48.6433577, lng: 22.2232296 },
    { lat: 48.6404869, lng: 22.2231726 },
    { lat: 48.6394119, lng: 22.2229857 },
    { lat: 48.6381618, lng: 22.2229681 },
    { lat: 48.6361375, lng: 22.2234915 },
    { lat: 48.6342247, lng: 22.224157 },
    { lat: 48.6341955, lng: 22.2234583 },
    { lat: 48.63379, lng: 22.2230893 },
    { lat: 48.6333701, lng: 22.2229274 },
    { lat: 48.6330197, lng: 22.2225973 },
    { lat: 48.6328375, lng: 22.2225039 },
    { lat: 48.6318025, lng: 22.2221496 },
    { lat: 48.6312762, lng: 22.2220451 },
    { lat: 48.6313118, lng: 22.2213882 },
    { lat: 48.631261, lng: 22.2203831 },
    { lat: 48.6316148, lng: 22.2192152 },
    { lat: 48.6315669, lng: 22.2193386 },
    { lat: 48.6312329, lng: 22.2197839 },
    { lat: 48.630854, lng: 22.2198425 },
    { lat: 48.6304367, lng: 22.219746 },
    { lat: 48.6303468, lng: 22.2197004 },
    { lat: 48.6304108, lng: 22.2189013 },
    { lat: 48.629481, lng: 22.2187156 },
    { lat: 48.6294301, lng: 22.218113 },
    { lat: 48.6283378, lng: 22.2182073 },
    { lat: 48.628445, lng: 22.2193083 },
    { lat: 48.6283659, lng: 22.2206355 },
    { lat: 48.6283057, lng: 22.2209668 },
    { lat: 48.6283694, lng: 22.2214382 },
    { lat: 48.6279534, lng: 22.2225455 },
    { lat: 48.6279009, lng: 22.2228608 },
    { lat: 48.6278414, lng: 22.2229605 },
    { lat: 48.6277605, lng: 22.2236182 },
    { lat: 48.6277506, lng: 22.2243765 },
    { lat: 48.6277971, lng: 22.2249857 },
    { lat: 48.6279706, lng: 22.2258444 },
    { lat: 48.6276137, lng: 22.2259875 },
    { lat: 48.6277746, lng: 22.2262342 },
    { lat: 48.6281107, lng: 22.2285271 },
    { lat: 48.6283039, lng: 22.2289588 },
    { lat: 48.6281546, lng: 22.2296239 },
    { lat: 48.6281464, lng: 22.2299113 },
    { lat: 48.6282307, lng: 22.2301498 },
    { lat: 48.6285859, lng: 22.2304925 },
    { lat: 48.6291076, lng: 22.2313359 },
    { lat: 48.6292447, lng: 22.2318743 },
    { lat: 48.6293439, lng: 22.2328916 },
    { lat: 48.6298245, lng: 22.2346499 },
    { lat: 48.6303266, lng: 22.2356707 },
    { lat: 48.6307379, lng: 22.2368039 },
    { lat: 48.6310882, lng: 22.2373279 },
    { lat: 48.6315767, lng: 22.2378388 },
    { lat: 48.6318061, lng: 22.2382936 },
    { lat: 48.6319689, lng: 22.2387732 },
    { lat: 48.6319501, lng: 22.2395412 },
    { lat: 48.6333915, lng: 22.2399654 },
    { lat: 48.6335369, lng: 22.2402766 },
    { lat: 48.633563, lng: 22.2405918 },
    { lat: 48.6339589, lng: 22.2418479 },
    { lat: 48.6349149, lng: 22.2420878 },
    { lat: 48.635587, lng: 22.2424746 },
    { lat: 48.6363202, lng: 22.2430183 },
    { lat: 48.6372406, lng: 22.2438252 },
    { lat: 48.6388503, lng: 22.2458376 },
    { lat: 48.6387708, lng: 22.246215 },
    { lat: 48.640503, lng: 22.2470686 },
    { lat: 48.6409888, lng: 22.2475659 },
    { lat: 48.6419186, lng: 22.246793 },
    { lat: 48.6428049, lng: 22.2461852 },
    { lat: 48.6429206, lng: 22.2462241 },
    { lat: 48.6440673, lng: 22.24469 },
    { lat: 48.6443589, lng: 22.2441519 },
    { lat: 48.6448153, lng: 22.2447436 },
    { lat: 48.6453409, lng: 22.2456619 },
    { lat: 48.64582, lng: 22.246658 },
    { lat: 48.6466356, lng: 22.2452085 },
    { lat: 48.6467359, lng: 22.2455182 },
    { lat: 48.646542, lng: 22.2460609 },
    { lat: 48.6470481, lng: 22.2470098 },
    { lat: 48.6471664, lng: 22.2470183 },
    { lat: 48.6471469, lng: 22.2475483 },
    { lat: 48.6470415, lng: 22.2478197 },
    { lat: 48.6470631, lng: 22.2479342 },
    { lat: 48.6471437, lng: 22.2479653 },
    { lat: 48.6472995, lng: 22.2477863 },
    { lat: 48.6475711, lng: 22.2479557 },
    { lat: 48.6476596, lng: 22.2481432 },
    { lat: 48.6476337, lng: 22.2485027 },
    { lat: 48.6477994, lng: 22.2487141 },
    { lat: 48.6478784, lng: 22.2487261 },
    { lat: 48.6479583, lng: 22.2485759 },
    { lat: 48.647968, lng: 22.2483545 },
    { lat: 48.6480581, lng: 22.2482642 },
    { lat: 48.6483278, lng: 22.2482981 },
    { lat: 48.648451, lng: 22.2480218 },
    { lat: 48.6486914, lng: 22.2481586 },
    { lat: 48.6486904, lng: 22.2482714 },
    { lat: 48.6484862, lng: 22.2484906 },
    { lat: 48.6484645, lng: 22.2488265 },
    { lat: 48.6486717, lng: 22.2487928 },
    { lat: 48.6487589, lng: 22.2491615 },
    { lat: 48.6487511, lng: 22.2493718 },
    { lat: 48.6489051, lng: 22.2494173 },
    { lat: 48.6489423, lng: 22.2493219 },
    { lat: 48.6490293, lng: 22.2493327 },
    { lat: 48.6490943, lng: 22.2493753 },
    { lat: 48.6491389, lng: 22.2495143 },
    { lat: 48.6493945, lng: 22.2493693 },
    { lat: 48.6494661, lng: 22.2494507 },
    { lat: 48.6494035, lng: 22.2497718 },
    { lat: 48.6491943, lng: 22.2500326 },
    { lat: 48.6492338, lng: 22.2503326 },
    { lat: 48.6490943, lng: 22.2508956 },
    { lat: 48.6492439, lng: 22.2509453 },
    { lat: 48.6495134, lng: 22.2507135 },
    { lat: 48.6496562, lng: 22.2506615 },
    { lat: 48.6497382, lng: 22.2505648 },
    { lat: 48.6497438, lng: 22.2504574 },
    { lat: 48.6498869, lng: 22.2505188 },
    { lat: 48.6499367, lng: 22.2505871 },
    { lat: 48.6499134, lng: 22.2507992 },
    { lat: 48.6498157, lng: 22.2510041 },
    { lat: 48.6498391, lng: 22.2511466 },
    { lat: 48.6500545, lng: 22.2512292 },
    { lat: 48.6502274, lng: 22.2514146 },
    { lat: 48.6502161, lng: 22.2516141 },
    { lat: 48.6500883, lng: 22.2519848 },
    { lat: 48.65016, lng: 22.2522177 },
    { lat: 48.6502046, lng: 22.2521578 },
    { lat: 48.6502508, lng: 22.2522089 },
    { lat: 48.6511909, lng: 22.2508826 },
    { lat: 48.6518342, lng: 22.2493771 },
    { lat: 48.6583545, lng: 22.2532463 },
    { lat: 48.6663561, lng: 22.2545977 },
    { lat: 48.6664855, lng: 22.2545442 },
    { lat: 48.6705822, lng: 22.2528428 },
    { lat: 48.6700347, lng: 22.2501824 },
    { lat: 48.6686616, lng: 22.2442494 },
    { lat: 48.6684003, lng: 22.2435873 },
    { lat: 48.6685088, lng: 22.2434568 },
    { lat: 48.6692149, lng: 22.2408312 },
    { lat: 48.6690674, lng: 22.2401203 },
    { lat: 48.6690221, lng: 22.2385518 },
    { lat: 48.6694214, lng: 22.2384574 },
    { lat: 48.6709536, lng: 22.2386613 },
    { lat: 48.6727586, lng: 22.2387441 },
    { lat: 48.6731984, lng: 22.2388508 },
  ],
  BlatnáPolianka: [
    { lat: 48.7090633, lng: 22.1308037 },
    { lat: 48.7071856, lng: 22.1288623 },
    { lat: 48.704686, lng: 22.1264493 },
    { lat: 48.7033784, lng: 22.1259001 },
    { lat: 48.7015513, lng: 22.1250212 },
    { lat: 48.7004879, lng: 22.1234641 },
    { lat: 48.6999488, lng: 22.1213813 },
    { lat: 48.6996869, lng: 22.1195201 },
    { lat: 48.6991779, lng: 22.1165238 },
    { lat: 48.6990092, lng: 22.1161454 },
    { lat: 48.6964717, lng: 22.1120291 },
    { lat: 48.6955895, lng: 22.1067646 },
    { lat: 48.6955718, lng: 22.1066334 },
    { lat: 48.6971596, lng: 22.1061849 },
    { lat: 48.6987623, lng: 22.1062661 },
    { lat: 48.6987927, lng: 22.1055092 },
    { lat: 48.6990068, lng: 22.1050906 },
    { lat: 48.6991331, lng: 22.1042477 },
    { lat: 48.700004, lng: 22.1031144 },
    { lat: 48.6996166, lng: 22.1009428 },
    { lat: 48.6991784, lng: 22.100924 },
    { lat: 48.6984614, lng: 22.1006646 },
    { lat: 48.6979915, lng: 22.1002024 },
    { lat: 48.6975833, lng: 22.0988257 },
    { lat: 48.6976299, lng: 22.0981256 },
    { lat: 48.697767, lng: 22.0978818 },
    { lat: 48.698335, lng: 22.0978807 },
    { lat: 48.6985754, lng: 22.0973885 },
    { lat: 48.6985206, lng: 22.0968886 },
    { lat: 48.697761, lng: 22.0965806 },
    { lat: 48.6974991, lng: 22.0950778 },
    { lat: 48.6972582, lng: 22.094259 },
    { lat: 48.6972738, lng: 22.0941542 },
    { lat: 48.7018604, lng: 22.0960777 },
    { lat: 48.702647, lng: 22.0951906 },
    { lat: 48.7021003, lng: 22.0929545 },
    { lat: 48.7032442, lng: 22.0922887 },
    { lat: 48.7027341, lng: 22.0902166 },
    { lat: 48.701604, lng: 22.081992 },
    { lat: 48.69082, lng: 22.088696 },
    { lat: 48.689655, lng: 22.086761 },
    { lat: 48.687756, lng: 22.083564 },
    { lat: 48.687637, lng: 22.083257 },
    { lat: 48.687592, lng: 22.082835 },
    { lat: 48.687655, lng: 22.082357 },
    { lat: 48.68782, lng: 22.082045 },
    { lat: 48.687758, lng: 22.081962 },
    { lat: 48.687715, lng: 22.081909 },
    { lat: 48.687663, lng: 22.081843 },
    { lat: 48.68647, lng: 22.080357 },
    { lat: 48.686258, lng: 22.079921 },
    { lat: 48.68381, lng: 22.074876 },
    { lat: 48.683782, lng: 22.074856 },
    { lat: 48.683456, lng: 22.074765 },
    { lat: 48.683402, lng: 22.074735 },
    { lat: 48.682641, lng: 22.074494 },
    { lat: 48.681609, lng: 22.074167 },
    { lat: 48.681468, lng: 22.074122 },
    { lat: 48.681413, lng: 22.074105 },
    { lat: 48.680352, lng: 22.074045 },
    { lat: 48.680284, lng: 22.074066 },
    { lat: 48.680216, lng: 22.074201 },
    { lat: 48.680202, lng: 22.074228 },
    { lat: 48.679563, lng: 22.075482 },
    { lat: 48.678791, lng: 22.076743 },
    { lat: 48.67911, lng: 22.07714 },
    { lat: 48.679241, lng: 22.077868 },
    { lat: 48.679155, lng: 22.07949 },
    { lat: 48.678879, lng: 22.079329 },
    { lat: 48.6784, lng: 22.080564 },
    { lat: 48.678379, lng: 22.080565 },
    { lat: 48.678288, lng: 22.08057 },
    { lat: 48.677059, lng: 22.080555 },
    { lat: 48.676848, lng: 22.080742 },
    { lat: 48.676398, lng: 22.08067 },
    { lat: 48.676245, lng: 22.080179 },
    { lat: 48.675334, lng: 22.081101 },
    { lat: 48.674769, lng: 22.081064 },
    { lat: 48.674492, lng: 22.081497 },
    { lat: 48.673835, lng: 22.083168 },
    { lat: 48.673828, lng: 22.083904 },
    { lat: 48.673581, lng: 22.084178 },
    { lat: 48.673528, lng: 22.084227 },
    { lat: 48.673381, lng: 22.084431 },
    { lat: 48.672885, lng: 22.084597 },
    { lat: 48.672538, lng: 22.083693 },
    { lat: 48.672541, lng: 22.082829 },
    { lat: 48.672283, lng: 22.082615 },
    { lat: 48.671658, lng: 22.082569 },
    { lat: 48.67087, lng: 22.081668 },
    { lat: 48.670437, lng: 22.080795 },
    { lat: 48.670631, lng: 22.079803 },
    { lat: 48.670644, lng: 22.079739 },
    { lat: 48.670659, lng: 22.07966 },
    { lat: 48.670684, lng: 22.079536 },
    { lat: 48.670716, lng: 22.07937 },
    { lat: 48.669719, lng: 22.078657 },
    { lat: 48.669645, lng: 22.078605 },
    { lat: 48.669584, lng: 22.078561 },
    { lat: 48.668568, lng: 22.080581 },
    { lat: 48.6684191, lng: 22.0812951 },
    { lat: 48.6684225, lng: 22.0817273 },
    { lat: 48.6693592, lng: 22.086306 },
    { lat: 48.6702341, lng: 22.0895182 },
    { lat: 48.6703569, lng: 22.0901334 },
    { lat: 48.6704545, lng: 22.0906939 },
    { lat: 48.6707027, lng: 22.0933854 },
    { lat: 48.6709942, lng: 22.0959627 },
    { lat: 48.6711155, lng: 22.0966142 },
    { lat: 48.6713655, lng: 22.0973392 },
    { lat: 48.6718017, lng: 22.0980421 },
    { lat: 48.6725459, lng: 22.0987419 },
    { lat: 48.673243, lng: 22.1001526 },
    { lat: 48.6813614, lng: 22.1142893 },
    { lat: 48.681846, lng: 22.1151373 },
    { lat: 48.6895351, lng: 22.128649 },
    { lat: 48.6955677, lng: 22.1388771 },
    { lat: 48.6961482, lng: 22.139896 },
    { lat: 48.6979542, lng: 22.1429151 },
    { lat: 48.6987104, lng: 22.1422073 },
    { lat: 48.6991445, lng: 22.1420603 },
    { lat: 48.6994729, lng: 22.141528 },
    { lat: 48.6995622, lng: 22.1414966 },
    { lat: 48.6997532, lng: 22.1417702 },
    { lat: 48.7000931, lng: 22.1416397 },
    { lat: 48.7002542, lng: 22.1417476 },
    { lat: 48.7003905, lng: 22.1420412 },
    { lat: 48.7007389, lng: 22.1423162 },
    { lat: 48.7010461, lng: 22.1419359 },
    { lat: 48.7011636, lng: 22.1418674 },
    { lat: 48.7014173, lng: 22.1411114 },
    { lat: 48.7015481, lng: 22.1408381 },
    { lat: 48.6992436, lng: 22.1386822 },
    { lat: 48.7013475, lng: 22.1334817 },
    { lat: 48.7019653, lng: 22.1339257 },
    { lat: 48.7020486, lng: 22.1339276 },
    { lat: 48.7029951, lng: 22.1345286 },
    { lat: 48.7050892, lng: 22.1350963 },
    { lat: 48.7059188, lng: 22.1348321 },
    { lat: 48.7062186, lng: 22.1351947 },
    { lat: 48.7065029, lng: 22.1347511 },
    { lat: 48.7069055, lng: 22.135083 },
    { lat: 48.7076603, lng: 22.1347416 },
    { lat: 48.7077802, lng: 22.13569 },
    { lat: 48.7074544, lng: 22.1358989 },
    { lat: 48.7091053, lng: 22.1367991 },
    { lat: 48.7090671, lng: 22.1310139 },
    { lat: 48.7090633, lng: 22.1308037 },
  ],
  Záhor: [
    { lat: 48.633563, lng: 22.2405918 },
    { lat: 48.6335369, lng: 22.2402766 },
    { lat: 48.6333915, lng: 22.2399654 },
    { lat: 48.6319501, lng: 22.2395412 },
    { lat: 48.6319689, lng: 22.2387732 },
    { lat: 48.6318061, lng: 22.2382936 },
    { lat: 48.6315767, lng: 22.2378388 },
    { lat: 48.6310882, lng: 22.2373279 },
    { lat: 48.6307379, lng: 22.2368039 },
    { lat: 48.6303266, lng: 22.2356707 },
    { lat: 48.6298245, lng: 22.2346499 },
    { lat: 48.6293439, lng: 22.2328916 },
    { lat: 48.6292447, lng: 22.2318743 },
    { lat: 48.6291076, lng: 22.2313359 },
    { lat: 48.6285859, lng: 22.2304925 },
    { lat: 48.6282307, lng: 22.2301498 },
    { lat: 48.6281464, lng: 22.2299113 },
    { lat: 48.6281546, lng: 22.2296239 },
    { lat: 48.6283039, lng: 22.2289588 },
    { lat: 48.6281107, lng: 22.2285271 },
    { lat: 48.6277746, lng: 22.2262342 },
    { lat: 48.6276137, lng: 22.2259875 },
    { lat: 48.6279706, lng: 22.2258444 },
    { lat: 48.6277971, lng: 22.2249857 },
    { lat: 48.6277506, lng: 22.2243765 },
    { lat: 48.6277605, lng: 22.2236182 },
    { lat: 48.6278414, lng: 22.2229605 },
    { lat: 48.6279009, lng: 22.2228608 },
    { lat: 48.6279534, lng: 22.2225455 },
    { lat: 48.6283694, lng: 22.2214382 },
    { lat: 48.6283057, lng: 22.2209668 },
    { lat: 48.6283659, lng: 22.2206355 },
    { lat: 48.628445, lng: 22.2193083 },
    { lat: 48.6283378, lng: 22.2182073 },
    { lat: 48.6294301, lng: 22.218113 },
    { lat: 48.629481, lng: 22.2187156 },
    { lat: 48.6304108, lng: 22.2189013 },
    { lat: 48.6303468, lng: 22.2197004 },
    { lat: 48.6304367, lng: 22.219746 },
    { lat: 48.630854, lng: 22.2198425 },
    { lat: 48.6312329, lng: 22.2197839 },
    { lat: 48.6315669, lng: 22.2193386 },
    { lat: 48.6316148, lng: 22.2192152 },
    { lat: 48.6316536, lng: 22.2190454 },
    { lat: 48.6315193, lng: 22.2177764 },
    { lat: 48.6316175, lng: 22.2170954 },
    { lat: 48.6316169, lng: 22.2164738 },
    { lat: 48.6314448, lng: 22.2162753 },
    { lat: 48.6315302, lng: 22.2155302 },
    { lat: 48.6315303, lng: 22.2146384 },
    { lat: 48.6317696, lng: 22.2138793 },
    { lat: 48.631788, lng: 22.2130484 },
    { lat: 48.632086, lng: 22.2120124 },
    { lat: 48.6321697, lng: 22.2114839 },
    { lat: 48.6321567, lng: 22.2110063 },
    { lat: 48.6323572, lng: 22.2104686 },
    { lat: 48.6325344, lng: 22.2101568 },
    { lat: 48.6327055, lng: 22.2091711 },
    { lat: 48.6329619, lng: 22.2082219 },
    { lat: 48.6337671, lng: 22.2080135 },
    { lat: 48.6339316, lng: 22.208075 },
    { lat: 48.6343115, lng: 22.2083648 },
    { lat: 48.6342143, lng: 22.2086924 },
    { lat: 48.6347041, lng: 22.2086098 },
    { lat: 48.6344737, lng: 22.2097149 },
    { lat: 48.6347069, lng: 22.209822 },
    { lat: 48.6347303, lng: 22.2104072 },
    { lat: 48.6348784, lng: 22.2102971 },
    { lat: 48.6348995, lng: 22.2099393 },
    { lat: 48.6351206, lng: 22.2096701 },
    { lat: 48.6352393, lng: 22.2091056 },
    { lat: 48.6353824, lng: 22.2089069 },
    { lat: 48.6355468, lng: 22.2088717 },
    { lat: 48.6357357, lng: 22.2086689 },
    { lat: 48.6363624, lng: 22.2083331 },
    { lat: 48.6364962, lng: 22.2081317 },
    { lat: 48.6371247, lng: 22.2076191 },
    { lat: 48.6370383, lng: 22.2071864 },
    { lat: 48.6372257, lng: 22.2070196 },
    { lat: 48.6371971, lng: 22.2066949 },
    { lat: 48.6384885, lng: 22.2055644 },
    { lat: 48.6384118, lng: 22.2052066 },
    { lat: 48.6383171, lng: 22.2043089 },
    { lat: 48.63866, lng: 22.2032171 },
    { lat: 48.6386761, lng: 22.2029812 },
    { lat: 48.6377926, lng: 22.2026585 },
    { lat: 48.6379497, lng: 22.2017093 },
    { lat: 48.6382052, lng: 22.2014713 },
    { lat: 48.6382034, lng: 22.2008451 },
    { lat: 48.6383108, lng: 22.1997864 },
    { lat: 48.6382799, lng: 22.1985735 },
    { lat: 48.638208, lng: 22.1978912 },
    { lat: 48.6383388, lng: 22.1967648 },
    { lat: 48.6399124, lng: 22.195976 },
    { lat: 48.6399131, lng: 22.1955143 },
    { lat: 48.6399939, lng: 22.1947138 },
    { lat: 48.6403565, lng: 22.1948845 },
    { lat: 48.640873, lng: 22.1952913 },
    { lat: 48.6416816, lng: 22.1954701 },
    { lat: 48.6420272, lng: 22.1958342 },
    { lat: 48.642951, lng: 22.196187 },
    { lat: 48.643563, lng: 22.1898457 },
    { lat: 48.6438808, lng: 22.186077 },
    { lat: 48.644422, lng: 22.181132 },
    { lat: 48.6443126, lng: 22.1811171 },
    { lat: 48.6427018, lng: 22.1838158 },
    { lat: 48.6345915, lng: 22.1731836 },
    { lat: 48.634584, lng: 22.1736381 },
    { lat: 48.6345069, lng: 22.1739526 },
    { lat: 48.6340792, lng: 22.175262 },
    { lat: 48.6342106, lng: 22.1759838 },
    { lat: 48.6341978, lng: 22.176216 },
    { lat: 48.6340061, lng: 22.1767744 },
    { lat: 48.6340562, lng: 22.177785 },
    { lat: 48.6344765, lng: 22.1785496 },
    { lat: 48.6341884, lng: 22.1791308 },
    { lat: 48.6341905, lng: 22.1793775 },
    { lat: 48.6340841, lng: 22.1796363 },
    { lat: 48.6339811, lng: 22.1801915 },
    { lat: 48.6337249, lng: 22.180995 },
    { lat: 48.6333668, lng: 22.1811251 },
    { lat: 48.6332236, lng: 22.1812552 },
    { lat: 48.6329527, lng: 22.1817029 },
    { lat: 48.6306444, lng: 22.1790568 },
    { lat: 48.626158, lng: 22.1807953 },
    { lat: 48.6256535, lng: 22.1815005 },
    { lat: 48.6252384, lng: 22.1815527 },
    { lat: 48.6247642, lng: 22.1814733 },
    { lat: 48.6239259, lng: 22.1821585 },
    { lat: 48.6241973, lng: 22.1837347 },
    { lat: 48.6243074, lng: 22.1847124 },
    { lat: 48.6224827, lng: 22.185882 },
    { lat: 48.6222328, lng: 22.1859203 },
    { lat: 48.6217134, lng: 22.1859857 },
    { lat: 48.6205134, lng: 22.1905596 },
    { lat: 48.6202221, lng: 22.1904176 },
    { lat: 48.6196254, lng: 22.1903398 },
    { lat: 48.6194208, lng: 22.1908464 },
    { lat: 48.6193, lng: 22.1913654 },
    { lat: 48.6192008, lng: 22.1913965 },
    { lat: 48.6190976, lng: 22.1913674 },
    { lat: 48.6189279, lng: 22.1911732 },
    { lat: 48.6189223, lng: 22.1910832 },
    { lat: 48.6190222, lng: 22.1908252 },
    { lat: 48.6189644, lng: 22.1906064 },
    { lat: 48.6184695, lng: 22.1906076 },
    { lat: 48.6181313, lng: 22.190868 },
    { lat: 48.6178685, lng: 22.1909396 },
    { lat: 48.6178513, lng: 22.191558 },
    { lat: 48.6180796, lng: 22.1926877 },
    { lat: 48.6180699, lng: 22.1931532 },
    { lat: 48.6178766, lng: 22.1942987 },
    { lat: 48.6176025, lng: 22.1967254 },
    { lat: 48.617085, lng: 22.2002767 },
    { lat: 48.6170316, lng: 22.2008394 },
    { lat: 48.6170981, lng: 22.2011392 },
    { lat: 48.612066, lng: 22.2001814 },
    { lat: 48.612108, lng: 22.1989624 },
    { lat: 48.609397, lng: 22.198381 },
    { lat: 48.609656, lng: 22.199083 },
    { lat: 48.609939, lng: 22.199637 },
    { lat: 48.610718, lng: 22.200969 },
    { lat: 48.611287, lng: 22.202687 },
    { lat: 48.61143, lng: 22.203471 },
    { lat: 48.617421, lng: 22.208484 },
    { lat: 48.6180868, lng: 22.2097741 },
    { lat: 48.621273, lng: 22.215702 },
    { lat: 48.620548, lng: 22.229986 },
    { lat: 48.622228, lng: 22.230949 },
    { lat: 48.627708, lng: 22.240504 },
    { lat: 48.634239, lng: 22.245592 },
    { lat: 48.6341422, lng: 22.2450031 },
    { lat: 48.6329063, lng: 22.2439125 },
    { lat: 48.6326133, lng: 22.2433845 },
    { lat: 48.6325081, lng: 22.2426057 },
    { lat: 48.632614, lng: 22.2418077 },
    { lat: 48.6331238, lng: 22.2411213 },
    { lat: 48.633563, lng: 22.2405918 },
  ],
  Jasenov: [
    { lat: 48.7875402, lng: 22.1490272 },
    { lat: 48.7867451, lng: 22.151613 },
    { lat: 48.7862156, lng: 22.1535229 },
    { lat: 48.7852881, lng: 22.1585057 },
    { lat: 48.7845283, lng: 22.1631717 },
    { lat: 48.7843236, lng: 22.1648774 },
    { lat: 48.7844341, lng: 22.1661678 },
    { lat: 48.7843968, lng: 22.1666473 },
    { lat: 48.7844668, lng: 22.1669532 },
    { lat: 48.7836158, lng: 22.1667227 },
    { lat: 48.7833711, lng: 22.1667523 },
    { lat: 48.7833523, lng: 22.1670515 },
    { lat: 48.783473, lng: 22.16986 },
    { lat: 48.7835002, lng: 22.175061 },
    { lat: 48.7834995, lng: 22.1756171 },
    { lat: 48.7856285, lng: 22.177475 },
    { lat: 48.785705, lng: 22.1780171 },
    { lat: 48.7862343, lng: 22.1782397 },
    { lat: 48.7868758, lng: 22.1788442 },
    { lat: 48.7884137, lng: 22.1812209 },
    { lat: 48.79014, lng: 22.1828841 },
    { lat: 48.7924033, lng: 22.1831887 },
    { lat: 48.7942898, lng: 22.1838363 },
    { lat: 48.7941272, lng: 22.1850863 },
    { lat: 48.7937003, lng: 22.1865916 },
    { lat: 48.7931644, lng: 22.1887336 },
    { lat: 48.7942294, lng: 22.1889748 },
    { lat: 48.7960103, lng: 22.1892436 },
    { lat: 48.7970294, lng: 22.1892927 },
    { lat: 48.8000112, lng: 22.1898809 },
    { lat: 48.8014266, lng: 22.1904007 },
    { lat: 48.8032586, lng: 22.1908306 },
    { lat: 48.8035262, lng: 22.1888481 },
    { lat: 48.8043597, lng: 22.1852978 },
    { lat: 48.8078378, lng: 22.185068 },
    { lat: 48.8077176, lng: 22.1819191 },
    { lat: 48.8088956, lng: 22.181548 },
    { lat: 48.8088662, lng: 22.1798192 },
    { lat: 48.8085207, lng: 22.1771017 },
    { lat: 48.8085326, lng: 22.1751213 },
    { lat: 48.8086185, lng: 22.1749423 },
    { lat: 48.8088476, lng: 22.1746811 },
    { lat: 48.8091608, lng: 22.1745073 },
    { lat: 48.8094371, lng: 22.174243 },
    { lat: 48.8107863, lng: 22.1742142 },
    { lat: 48.8114529, lng: 22.1738041 },
    { lat: 48.8114475, lng: 22.1737209 },
    { lat: 48.8113941, lng: 22.1736962 },
    { lat: 48.8115066, lng: 22.1713478 },
    { lat: 48.8099862, lng: 22.1703212 },
    { lat: 48.8104699, lng: 22.1677406 },
    { lat: 48.8092981, lng: 22.1669512 },
    { lat: 48.8092293, lng: 22.1669049 },
    { lat: 48.8092525, lng: 22.1667445 },
    { lat: 48.810838, lng: 22.1627147 },
    { lat: 48.8079791, lng: 22.1594973 },
    { lat: 48.8064696, lng: 22.1578871 },
    { lat: 48.806487, lng: 22.1577627 },
    { lat: 48.8065706, lng: 22.1577829 },
    { lat: 48.8062746, lng: 22.1560672 },
    { lat: 48.8053667, lng: 22.1540174 },
    { lat: 48.8036773, lng: 22.152358 },
    { lat: 48.8028383, lng: 22.150374 },
    { lat: 48.799973, lng: 22.1485546 },
    { lat: 48.7991397, lng: 22.1474927 },
    { lat: 48.7985154, lng: 22.145991 },
    { lat: 48.7981765, lng: 22.1484458 },
    { lat: 48.7980519, lng: 22.1487043 },
    { lat: 48.798043, lng: 22.1489488 },
    { lat: 48.796703, lng: 22.1474311 },
    { lat: 48.7932584, lng: 22.143771 },
    { lat: 48.7923646, lng: 22.1445616 },
    { lat: 48.7907687, lng: 22.1468541 },
    { lat: 48.7906425, lng: 22.1467477 },
    { lat: 48.7894756, lng: 22.147065 },
    { lat: 48.7890638, lng: 22.1479394 },
    { lat: 48.7884593, lng: 22.1486446 },
    { lat: 48.7881179, lng: 22.1493009 },
    { lat: 48.7875402, lng: 22.1490272 },
  ],
  Kolibabovce: [
    { lat: 48.718268, lng: 22.2364972 },
    { lat: 48.7177475, lng: 22.2370073 },
    { lat: 48.7174766, lng: 22.2363639 },
    { lat: 48.7171285, lng: 22.2362364 },
    { lat: 48.7160203, lng: 22.2342227 },
    { lat: 48.7154117, lng: 22.2334118 },
    { lat: 48.7141551, lng: 22.2324562 },
    { lat: 48.7141667, lng: 22.2323715 },
    { lat: 48.7138881, lng: 22.2317333 },
    { lat: 48.7137365, lng: 22.2317952 },
    { lat: 48.7134227, lng: 22.2316948 },
    { lat: 48.7133673, lng: 22.2318298 },
    { lat: 48.7133393, lng: 22.23214 },
    { lat: 48.7131745, lng: 22.2320121 },
    { lat: 48.7130584, lng: 22.2322556 },
    { lat: 48.7123995, lng: 22.2322107 },
    { lat: 48.7121374, lng: 22.2320079 },
    { lat: 48.7121406, lng: 22.2318065 },
    { lat: 48.7120606, lng: 22.231748 },
    { lat: 48.7120108, lng: 22.2318307 },
    { lat: 48.7117347, lng: 22.2316568 },
    { lat: 48.7116203, lng: 22.2319276 },
    { lat: 48.7110475, lng: 22.2314082 },
    { lat: 48.7110681, lng: 22.2312248 },
    { lat: 48.7108783, lng: 22.231191 },
    { lat: 48.7107106, lng: 22.2325801 },
    { lat: 48.7104482, lng: 22.2334697 },
    { lat: 48.7104485, lng: 22.2343896 },
    { lat: 48.710295, lng: 22.2348665 },
    { lat: 48.7101537, lng: 22.2351341 },
    { lat: 48.7101264, lng: 22.2361274 },
    { lat: 48.7099386, lng: 22.2366727 },
    { lat: 48.7099492, lng: 22.2369496 },
    { lat: 48.7097202, lng: 22.2386747 },
    { lat: 48.7097099, lng: 22.2392686 },
    { lat: 48.710255, lng: 22.2406147 },
    { lat: 48.7102272, lng: 22.2406229 },
    { lat: 48.7102595, lng: 22.2410668 },
    { lat: 48.7103369, lng: 22.2411544 },
    { lat: 48.7104147, lng: 22.2414093 },
    { lat: 48.7104797, lng: 22.2414081 },
    { lat: 48.7104582, lng: 22.2417533 },
    { lat: 48.7103596, lng: 22.2419179 },
    { lat: 48.7104595, lng: 22.2420082 },
    { lat: 48.7105197, lng: 22.2425401 },
    { lat: 48.7105783, lng: 22.2426924 },
    { lat: 48.7105156, lng: 22.2428508 },
    { lat: 48.7105644, lng: 22.2433249 },
    { lat: 48.7107021, lng: 22.2434963 },
    { lat: 48.7105765, lng: 22.2438677 },
    { lat: 48.7105412, lng: 22.2444518 },
    { lat: 48.7105746, lng: 22.2447443 },
    { lat: 48.7106774, lng: 22.2449652 },
    { lat: 48.710642, lng: 22.2450892 },
    { lat: 48.7104404, lng: 22.2450247 },
    { lat: 48.7102678, lng: 22.2451062 },
    { lat: 48.7103388, lng: 22.2464684 },
    { lat: 48.710293, lng: 22.2466865 },
    { lat: 48.7099647, lng: 22.2467944 },
    { lat: 48.70991, lng: 22.2469628 },
    { lat: 48.7099155, lng: 22.2475556 },
    { lat: 48.7096752, lng: 22.2476775 },
    { lat: 48.7094386, lng: 22.2481664 },
    { lat: 48.7093999, lng: 22.2485018 },
    { lat: 48.7093347, lng: 22.2486005 },
    { lat: 48.7092277, lng: 22.2490433 },
    { lat: 48.7090684, lng: 22.2492131 },
    { lat: 48.7088854, lng: 22.2496847 },
    { lat: 48.7089278, lng: 22.2497695 },
    { lat: 48.709072, lng: 22.2498305 },
    { lat: 48.7091344, lng: 22.2500079 },
    { lat: 48.7091, lng: 22.2504801 },
    { lat: 48.7091849, lng: 22.250708 },
    { lat: 48.7090525, lng: 22.2513322 },
    { lat: 48.7091551, lng: 22.2518452 },
    { lat: 48.7091278, lng: 22.2522341 },
    { lat: 48.7087101, lng: 22.2529206 },
    { lat: 48.7083695, lng: 22.2531327 },
    { lat: 48.7082694, lng: 22.2531378 },
    { lat: 48.7079918, lng: 22.2534443 },
    { lat: 48.7078806, lng: 22.253456 },
    { lat: 48.7078164, lng: 22.2538033 },
    { lat: 48.7076584, lng: 22.2538299 },
    { lat: 48.7073553, lng: 22.2540134 },
    { lat: 48.7072439, lng: 22.2540066 },
    { lat: 48.7070955, lng: 22.2543582 },
    { lat: 48.706999, lng: 22.2544293 },
    { lat: 48.7068881, lng: 22.2543459 },
    { lat: 48.706797, lng: 22.2544492 },
    { lat: 48.7067112, lng: 22.2549895 },
    { lat: 48.7066273, lng: 22.25521 },
    { lat: 48.7065247, lng: 22.2553006 },
    { lat: 48.7062877, lng: 22.2557625 },
    { lat: 48.706063, lng: 22.2560346 },
    { lat: 48.7059571, lng: 22.2565827 },
    { lat: 48.7060677, lng: 22.2567433 },
    { lat: 48.7059424, lng: 22.2572871 },
    { lat: 48.7066828, lng: 22.2582573 },
    { lat: 48.70717, lng: 22.2584694 },
    { lat: 48.7076826, lng: 22.2594644 },
    { lat: 48.7076674, lng: 22.2597447 },
    { lat: 48.7077612, lng: 22.260116 },
    { lat: 48.7079876, lng: 22.2606405 },
    { lat: 48.7082206, lng: 22.2606989 },
    { lat: 48.7082951, lng: 22.260808 },
    { lat: 48.708222, lng: 22.2611657 },
    { lat: 48.7084146, lng: 22.261689 },
    { lat: 48.7083337, lng: 22.2618416 },
    { lat: 48.7083383, lng: 22.2620497 },
    { lat: 48.7087029, lng: 22.2629629 },
    { lat: 48.7089168, lng: 22.2632765 },
    { lat: 48.7090549, lng: 22.2633186 },
    { lat: 48.709302, lng: 22.2639076 },
    { lat: 48.709166, lng: 22.264265 },
    { lat: 48.7092429, lng: 22.2646966 },
    { lat: 48.7098514, lng: 22.2643923 },
    { lat: 48.7103752, lng: 22.2644281 },
    { lat: 48.7107185, lng: 22.2642323 },
    { lat: 48.7117377, lng: 22.2639395 },
    { lat: 48.7123394, lng: 22.2641405 },
    { lat: 48.7124864, lng: 22.2643251 },
    { lat: 48.712537, lng: 22.2645227 },
    { lat: 48.7126874, lng: 22.2646643 },
    { lat: 48.7144926, lng: 22.26547 },
    { lat: 48.715352, lng: 22.2656206 },
    { lat: 48.7165602, lng: 22.2644122 },
    { lat: 48.7171246, lng: 22.2640049 },
    { lat: 48.7174168, lng: 22.2636845 },
    { lat: 48.7175696, lng: 22.2636768 },
    { lat: 48.7177028, lng: 22.2637527 },
    { lat: 48.7180514, lng: 22.2634953 },
    { lat: 48.7181355, lng: 22.2632798 },
    { lat: 48.7181698, lng: 22.2633529 },
    { lat: 48.7185028, lng: 22.2634147 },
    { lat: 48.7185666, lng: 22.2632918 },
    { lat: 48.7191078, lng: 22.2634531 },
    { lat: 48.7193775, lng: 22.2633917 },
    { lat: 48.7193596, lng: 22.2635009 },
    { lat: 48.7194872, lng: 22.2632718 },
    { lat: 48.7195397, lng: 22.2629935 },
    { lat: 48.7195581, lng: 22.2635226 },
    { lat: 48.7196233, lng: 22.2637711 },
    { lat: 48.7197891, lng: 22.2639394 },
    { lat: 48.7200141, lng: 22.2642161 },
    { lat: 48.7204162, lng: 22.2645801 },
    { lat: 48.7207626, lng: 22.265085 },
    { lat: 48.7209567, lng: 22.2653692 },
    { lat: 48.7217071, lng: 22.2666421 },
    { lat: 48.7218341, lng: 22.2664415 },
    { lat: 48.7219659, lng: 22.2663458 },
    { lat: 48.7219574, lng: 22.2662721 },
    { lat: 48.7221772, lng: 22.266235 },
    { lat: 48.7221886, lng: 22.2662904 },
    { lat: 48.7222649, lng: 22.2661846 },
    { lat: 48.7222438, lng: 22.2661458 },
    { lat: 48.7223566, lng: 22.2657578 },
    { lat: 48.7224068, lng: 22.2657378 },
    { lat: 48.7224152, lng: 22.2655536 },
    { lat: 48.7224205, lng: 22.26544 },
    { lat: 48.7223185, lng: 22.2653063 },
    { lat: 48.722364, lng: 22.2652751 },
    { lat: 48.7221701, lng: 22.2649031 },
    { lat: 48.7225603, lng: 22.2637298 },
    { lat: 48.7229652, lng: 22.2629658 },
    { lat: 48.7238395, lng: 22.2615675 },
    { lat: 48.7243341, lng: 22.2610971 },
    { lat: 48.7249224, lng: 22.2597783 },
    { lat: 48.7249972, lng: 22.2593477 },
    { lat: 48.724859, lng: 22.2590031 },
    { lat: 48.7249549, lng: 22.2575505 },
    { lat: 48.7249731, lng: 22.2558915 },
    { lat: 48.7250128, lng: 22.2554485 },
    { lat: 48.7251842, lng: 22.255422 },
    { lat: 48.725198, lng: 22.2550322 },
    { lat: 48.7253294, lng: 22.2547195 },
    { lat: 48.7256425, lng: 22.2546815 },
    { lat: 48.7256594, lng: 22.2540341 },
    { lat: 48.7256033, lng: 22.2534313 },
    { lat: 48.7260458, lng: 22.2502217 },
    { lat: 48.7261034, lng: 22.2492201 },
    { lat: 48.7273787, lng: 22.2480037 },
    { lat: 48.7271745, lng: 22.2475748 },
    { lat: 48.7267064, lng: 22.2461508 },
    { lat: 48.7265685, lng: 22.2460749 },
    { lat: 48.7263277, lng: 22.2454683 },
    { lat: 48.7260012, lng: 22.2442399 },
    { lat: 48.7253, lng: 22.2444214 },
    { lat: 48.7250643, lng: 22.2442945 },
    { lat: 48.7245757, lng: 22.2444737 },
    { lat: 48.724376, lng: 22.244796 },
    { lat: 48.7242479, lng: 22.2447605 },
    { lat: 48.7236483, lng: 22.2428834 },
    { lat: 48.7234344, lng: 22.2418776 },
    { lat: 48.7234704, lng: 22.2408206 },
    { lat: 48.7228099, lng: 22.2382912 },
    { lat: 48.7221025, lng: 22.2367981 },
    { lat: 48.7218136, lng: 22.236347 },
    { lat: 48.7216391, lng: 22.2358903 },
    { lat: 48.7214782, lng: 22.2360959 },
    { lat: 48.7196027, lng: 22.2369771 },
    { lat: 48.7188008, lng: 22.2371557 },
    { lat: 48.7186484, lng: 22.2372518 },
    { lat: 48.7185136, lng: 22.236708 },
    { lat: 48.7183512, lng: 22.2364841 },
    { lat: 48.718268, lng: 22.2364972 },
  ],
  Horňa: [
    { lat: 48.7751868, lng: 22.1807642 },
    { lat: 48.774488, lng: 22.1803044 },
    { lat: 48.7744421, lng: 22.1803695 },
    { lat: 48.7743802, lng: 22.1807533 },
    { lat: 48.774041, lng: 22.1807728 },
    { lat: 48.7738569, lng: 22.1806463 },
    { lat: 48.7738023, lng: 22.1812097 },
    { lat: 48.7736526, lng: 22.1812999 },
    { lat: 48.773471, lng: 22.1812324 },
    { lat: 48.7734019, lng: 22.1816536 },
    { lat: 48.7731535, lng: 22.1820057 },
    { lat: 48.7728209, lng: 22.1819728 },
    { lat: 48.7727539, lng: 22.1813505 },
    { lat: 48.7726298, lng: 22.1813346 },
    { lat: 48.7723277, lng: 22.1810605 },
    { lat: 48.7721075, lng: 22.1811729 },
    { lat: 48.771894, lng: 22.1809373 },
    { lat: 48.7716811, lng: 22.1815615 },
    { lat: 48.7713044, lng: 22.1820059 },
    { lat: 48.7711183, lng: 22.1818316 },
    { lat: 48.7709507, lng: 22.1813979 },
    { lat: 48.7706256, lng: 22.1813468 },
    { lat: 48.7706246, lng: 22.1815989 },
    { lat: 48.7704573, lng: 22.181612 },
    { lat: 48.7699104, lng: 22.1811061 },
    { lat: 48.7697843, lng: 22.1808581 },
    { lat: 48.7697357, lng: 22.1803529 },
    { lat: 48.7693942, lng: 22.1798801 },
    { lat: 48.7691084, lng: 22.1800288 },
    { lat: 48.7684388, lng: 22.1794723 },
    { lat: 48.7681266, lng: 22.1798784 },
    { lat: 48.7677376, lng: 22.1801841 },
    { lat: 48.7676554, lng: 22.1800336 },
    { lat: 48.7676863, lng: 22.1797132 },
    { lat: 48.7678146, lng: 22.1794722 },
    { lat: 48.7676855, lng: 22.1793813 },
    { lat: 48.7675182, lng: 22.179547 },
    { lat: 48.7672465, lng: 22.1793255 },
    { lat: 48.7669131, lng: 22.1792995 },
    { lat: 48.7670097, lng: 22.1794905 },
    { lat: 48.7668035, lng: 22.1795748 },
    { lat: 48.7667243, lng: 22.1797002 },
    { lat: 48.7665782, lng: 22.17966 },
    { lat: 48.7665124, lng: 22.1793896 },
    { lat: 48.7663131, lng: 22.1793755 },
    { lat: 48.766266, lng: 22.1796849 },
    { lat: 48.7659414, lng: 22.1795479 },
    { lat: 48.7659224, lng: 22.1793585 },
    { lat: 48.7654424, lng: 22.1792785 },
    { lat: 48.7652447, lng: 22.178943 },
    { lat: 48.7645846, lng: 22.1786581 },
    { lat: 48.763962, lng: 22.1786751 },
    { lat: 48.7630016, lng: 22.1782473 },
    { lat: 48.7626787, lng: 22.1784838 },
    { lat: 48.7621662, lng: 22.1784101 },
    { lat: 48.7621065, lng: 22.1784047 },
    { lat: 48.7612685, lng: 22.1795328 },
    { lat: 48.7632908, lng: 22.1852304 },
    { lat: 48.7634786, lng: 22.1859205 },
    { lat: 48.7646344, lng: 22.1890968 },
    { lat: 48.7654231, lng: 22.1881696 },
    { lat: 48.7662195, lng: 22.1889637 },
    { lat: 48.7665632, lng: 22.1893886 },
    { lat: 48.76668, lng: 22.1900258 },
    { lat: 48.7671056, lng: 22.1904892 },
    { lat: 48.7680208, lng: 22.1913025 },
    { lat: 48.7689355, lng: 22.1918595 },
    { lat: 48.7692028, lng: 22.1920895 },
    { lat: 48.7694481, lng: 22.1924886 },
    { lat: 48.7695704, lng: 22.1929229 },
    { lat: 48.7702439, lng: 22.1942902 },
    { lat: 48.7702308, lng: 22.1948019 },
    { lat: 48.769686, lng: 22.1955776 },
    { lat: 48.7697919, lng: 22.1958798 },
    { lat: 48.7694905, lng: 22.196379 },
    { lat: 48.7690176, lng: 22.196645 },
    { lat: 48.7683534, lng: 22.1951425 },
    { lat: 48.7673402, lng: 22.1935715 },
    { lat: 48.766817, lng: 22.1955024 },
    { lat: 48.7667746, lng: 22.195659 },
    { lat: 48.7666734, lng: 22.1956008 },
    { lat: 48.7664896, lng: 22.1953369 },
    { lat: 48.7656378, lng: 22.1963596 },
    { lat: 48.7644405, lng: 22.197233 },
    { lat: 48.7641846, lng: 22.1975487 },
    { lat: 48.7638417, lng: 22.1975626 },
    { lat: 48.762932, lng: 22.1978652 },
    { lat: 48.7629633, lng: 22.1966714 },
    { lat: 48.7628986, lng: 22.1964473 },
    { lat: 48.762887, lng: 22.1961381 },
    { lat: 48.7627987, lng: 22.1956624 },
    { lat: 48.7624142, lng: 22.1941383 },
    { lat: 48.7621306, lng: 22.1933919 },
    { lat: 48.7618002, lng: 22.1928662 },
    { lat: 48.7604709, lng: 22.1911501 },
    { lat: 48.760311, lng: 22.1908468 },
    { lat: 48.7603612, lng: 22.1901326 },
    { lat: 48.761175, lng: 22.1888267 },
    { lat: 48.7616718, lng: 22.187928 },
    { lat: 48.7587197, lng: 22.1861734 },
    { lat: 48.7563092, lng: 22.1846482 },
    { lat: 48.7557651, lng: 22.1844093 },
    { lat: 48.7544145, lng: 22.1863973 },
    { lat: 48.7538204, lng: 22.187374 },
    { lat: 48.7527327, lng: 22.1888003 },
    { lat: 48.7518496, lng: 22.1901246 },
    { lat: 48.7507134, lng: 22.1921871 },
    { lat: 48.7507801, lng: 22.1922991 },
    { lat: 48.7515046, lng: 22.1951222 },
    { lat: 48.7525424, lng: 22.199455 },
    { lat: 48.7506705, lng: 22.2024141 },
    { lat: 48.7497976, lng: 22.2039996 },
    { lat: 48.7487186, lng: 22.2052771 },
    { lat: 48.7486585, lng: 22.205349 },
    { lat: 48.7503986, lng: 22.2067426 },
    { lat: 48.7515749, lng: 22.207778 },
    { lat: 48.7525481, lng: 22.2092243 },
    { lat: 48.7530516, lng: 22.2105984 },
    { lat: 48.7523376, lng: 22.2154321 },
    { lat: 48.7613716, lng: 22.2205058 },
    { lat: 48.7617683, lng: 22.2194906 },
    { lat: 48.7630538, lng: 22.2201427 },
    { lat: 48.7638399, lng: 22.2208337 },
    { lat: 48.7651078, lng: 22.2219339 },
    { lat: 48.7654942, lng: 22.2208732 },
    { lat: 48.7658212, lng: 22.2212535 },
    { lat: 48.7665567, lng: 22.2218018 },
    { lat: 48.7673915, lng: 22.2225542 },
    { lat: 48.7679318, lng: 22.2235226 },
    { lat: 48.7680698, lng: 22.223504 },
    { lat: 48.7686202, lng: 22.2241236 },
    { lat: 48.7693954, lng: 22.2246356 },
    { lat: 48.7698512, lng: 22.2252033 },
    { lat: 48.7700923, lng: 22.2256633 },
    { lat: 48.7706951, lng: 22.2238662 },
    { lat: 48.7706933, lng: 22.2236812 },
    { lat: 48.7709106, lng: 22.22324 },
    { lat: 48.7715051, lng: 22.2237153 },
    { lat: 48.771759, lng: 22.2237076 },
    { lat: 48.7716934, lng: 22.2246335 },
    { lat: 48.7721772, lng: 22.2242445 },
    { lat: 48.7722456, lng: 22.2243836 },
    { lat: 48.7740097, lng: 22.2217099 },
    { lat: 48.7738982, lng: 22.2211554 },
    { lat: 48.7746641, lng: 22.2198004 },
    { lat: 48.7748584, lng: 22.2188956 },
    { lat: 48.7772197, lng: 22.2151676 },
    { lat: 48.7760889, lng: 22.2131563 },
    { lat: 48.7765307, lng: 22.2119009 },
    { lat: 48.7753528, lng: 22.2095095 },
    { lat: 48.7754139, lng: 22.2087321 },
    { lat: 48.7753114, lng: 22.2085755 },
    { lat: 48.7747494, lng: 22.2071048 },
    { lat: 48.7750304, lng: 22.2059533 },
    { lat: 48.7747475, lng: 22.2055233 },
    { lat: 48.7746877, lng: 22.2051734 },
    { lat: 48.7743832, lng: 22.2043957 },
    { lat: 48.7743247, lng: 22.2037469 },
    { lat: 48.7743367, lng: 22.2034339 },
    { lat: 48.774178, lng: 22.2026854 },
    { lat: 48.7742911, lng: 22.2017142 },
    { lat: 48.7743842, lng: 22.2017065 },
    { lat: 48.7743062, lng: 22.2011981 },
    { lat: 48.774088, lng: 22.2010979 },
    { lat: 48.7738854, lng: 22.2010895 },
    { lat: 48.7736549, lng: 22.2009474 },
    { lat: 48.7734901, lng: 22.2010697 },
    { lat: 48.7734184, lng: 22.2010346 },
    { lat: 48.7732793, lng: 22.2007016 },
    { lat: 48.7737002, lng: 22.1970471 },
    { lat: 48.7737889, lng: 22.195901 },
    { lat: 48.774444, lng: 22.1942479 },
    { lat: 48.7749223, lng: 22.1946031 },
    { lat: 48.7752316, lng: 22.1952467 },
    { lat: 48.7755432, lng: 22.1937684 },
    { lat: 48.7759623, lng: 22.1896936 },
    { lat: 48.7759706, lng: 22.1876476 },
    { lat: 48.7757493, lng: 22.1868642 },
    { lat: 48.7754016, lng: 22.1847484 },
    { lat: 48.7754762, lng: 22.1833819 },
    { lat: 48.7754501, lng: 22.1825809 },
    { lat: 48.7751374, lng: 22.181643 },
    { lat: 48.7751868, lng: 22.1807642 },
  ],
  Sobrance: [
    { lat: 48.7621065, lng: 22.1784047 },
    { lat: 48.7622682, lng: 22.1781506 },
    { lat: 48.7627143, lng: 22.1776812 },
    { lat: 48.7647627, lng: 22.1748003 },
    { lat: 48.7659279, lng: 22.1733268 },
    { lat: 48.7665762, lng: 22.1726029 },
    { lat: 48.7669627, lng: 22.1723401 },
    { lat: 48.7668574, lng: 22.1720668 },
    { lat: 48.766798, lng: 22.1720257 },
    { lat: 48.7666875, lng: 22.1720608 },
    { lat: 48.7665585, lng: 22.1722729 },
    { lat: 48.766419, lng: 22.1721708 },
    { lat: 48.7663265, lng: 22.1719175 },
    { lat: 48.7663025, lng: 22.1717073 },
    { lat: 48.7662508, lng: 22.1716598 },
    { lat: 48.7659426, lng: 22.1719017 },
    { lat: 48.765822, lng: 22.172092 },
    { lat: 48.7656861, lng: 22.1721455 },
    { lat: 48.76533, lng: 22.1716739 },
    { lat: 48.7649711, lng: 22.1716257 },
    { lat: 48.7647923, lng: 22.1713346 },
    { lat: 48.764648, lng: 22.1713204 },
    { lat: 48.7645372, lng: 22.1715362 },
    { lat: 48.7644323, lng: 22.1712262 },
    { lat: 48.7643592, lng: 22.1712276 },
    { lat: 48.7642799, lng: 22.1714467 },
    { lat: 48.7636189, lng: 22.170931 },
    { lat: 48.7633449, lng: 22.170555 },
    { lat: 48.7633341, lng: 22.170138 },
    { lat: 48.7630626, lng: 22.1700165 },
    { lat: 48.7628834, lng: 22.1698095 },
    { lat: 48.7624041, lng: 22.1697113 },
    { lat: 48.7621465, lng: 22.1690653 },
    { lat: 48.7618597, lng: 22.168823 },
    { lat: 48.7614309, lng: 22.1686655 },
    { lat: 48.7612939, lng: 22.1684384 },
    { lat: 48.7610978, lng: 22.1683133 },
    { lat: 48.760569, lng: 22.1677746 },
    { lat: 48.7596841, lng: 22.1671502 },
    { lat: 48.7590751, lng: 22.166199 },
    { lat: 48.7588411, lng: 22.1660056 },
    { lat: 48.7581607, lng: 22.165722 },
    { lat: 48.7577658, lng: 22.1657596 },
    { lat: 48.7569481, lng: 22.166209 },
    { lat: 48.7568086, lng: 22.1661094 },
    { lat: 48.7563372, lng: 22.1660587 },
    { lat: 48.7559973, lng: 22.1658534 },
    { lat: 48.755779, lng: 22.1650086 },
    { lat: 48.755806, lng: 22.1645253 },
    { lat: 48.7557518, lng: 22.1644233 },
    { lat: 48.7553633, lng: 22.1642049 },
    { lat: 48.75506, lng: 22.1638288 },
    { lat: 48.7547558, lng: 22.1637635 },
    { lat: 48.7544059, lng: 22.1638575 },
    { lat: 48.7541774, lng: 22.1638051 },
    { lat: 48.7538336, lng: 22.1640627 },
    { lat: 48.7532242, lng: 22.1641693 },
    { lat: 48.7528301, lng: 22.1636201 },
    { lat: 48.7526711, lng: 22.1630592 },
    { lat: 48.7523001, lng: 22.1625277 },
    { lat: 48.7522339, lng: 22.162344 },
    { lat: 48.7520629, lng: 22.1622892 },
    { lat: 48.7519122, lng: 22.162541 },
    { lat: 48.7518373, lng: 22.1625553 },
    { lat: 48.7512988, lng: 22.1620992 },
    { lat: 48.7511891, lng: 22.1620116 },
    { lat: 48.7509969, lng: 22.1619145 },
    { lat: 48.75081, lng: 22.1619069 },
    { lat: 48.7503561, lng: 22.1621739 },
    { lat: 48.7501136, lng: 22.1622498 },
    { lat: 48.7499143, lng: 22.1620892 },
    { lat: 48.74949, lng: 22.1618845 },
    { lat: 48.7491527, lng: 22.1613017 },
    { lat: 48.748983, lng: 22.1613128 },
    { lat: 48.7488984, lng: 22.1609555 },
    { lat: 48.748993, lng: 22.1603331 },
    { lat: 48.7488791, lng: 22.1601695 },
    { lat: 48.7483483, lng: 22.1598321 },
    { lat: 48.7482682, lng: 22.1595769 },
    { lat: 48.7474222, lng: 22.1588406 },
    { lat: 48.7455555, lng: 22.1584266 },
    { lat: 48.7421224, lng: 22.1579852 },
    { lat: 48.7418718, lng: 22.1573078 },
    { lat: 48.7415077, lng: 22.15674 },
    { lat: 48.7405567, lng: 22.1560775 },
    { lat: 48.7403349, lng: 22.1558875 },
    { lat: 48.7399666, lng: 22.1562993 },
    { lat: 48.7396737, lng: 22.1567235 },
    { lat: 48.7395458, lng: 22.1568258 },
    { lat: 48.7378321, lng: 22.1589963 },
    { lat: 48.7373444, lng: 22.1594554 },
    { lat: 48.7355542, lng: 22.1618678 },
    { lat: 48.73391, lng: 22.1636395 },
    { lat: 48.7325066, lng: 22.1657576 },
    { lat: 48.7316336, lng: 22.1668725 },
    { lat: 48.7314844, lng: 22.1665996 },
    { lat: 48.7313901, lng: 22.166751 },
    { lat: 48.7312791, lng: 22.167204 },
    { lat: 48.729338, lng: 22.1700075 },
    { lat: 48.7286038, lng: 22.171461 },
    { lat: 48.7283887, lng: 22.1720222 },
    { lat: 48.7276379, lng: 22.1737002 },
    { lat: 48.7264786, lng: 22.1736264 },
    { lat: 48.7264804, lng: 22.1738458 },
    { lat: 48.7275325, lng: 22.1740595 },
    { lat: 48.7251987, lng: 22.179667 },
    { lat: 48.7247736, lng: 22.1805425 },
    { lat: 48.7244383, lng: 22.1813833 },
    { lat: 48.7241952, lng: 22.1815247 },
    { lat: 48.7241521, lng: 22.1817001 },
    { lat: 48.7242177, lng: 22.1819585 },
    { lat: 48.7241121, lng: 22.1822579 },
    { lat: 48.7231404, lng: 22.1846756 },
    { lat: 48.7236733, lng: 22.1889933 },
    { lat: 48.723831, lng: 22.1888608 },
    { lat: 48.7242364, lng: 22.1887356 },
    { lat: 48.7247777, lng: 22.1892866 },
    { lat: 48.7251289, lng: 22.1897432 },
    { lat: 48.7262395, lng: 22.1906932 },
    { lat: 48.7271348, lng: 22.1917798 },
    { lat: 48.7271097, lng: 22.1922108 },
    { lat: 48.7273391, lng: 22.1923987 },
    { lat: 48.7283209, lng: 22.1935825 },
    { lat: 48.7282342, lng: 22.1941631 },
    { lat: 48.7286937, lng: 22.194489 },
    { lat: 48.7293109, lng: 22.1951135 },
    { lat: 48.7292657, lng: 22.1954996 },
    { lat: 48.7298368, lng: 22.1960286 },
    { lat: 48.730136, lng: 22.1964227 },
    { lat: 48.7305899, lng: 22.1968459 },
    { lat: 48.730874, lng: 22.1969213 },
    { lat: 48.730818, lng: 22.197878 },
    { lat: 48.731525, lng: 22.1981562 },
    { lat: 48.7315146, lng: 22.1982879 },
    { lat: 48.731593, lng: 22.1983259 },
    { lat: 48.7315594, lng: 22.1987224 },
    { lat: 48.7316528, lng: 22.1987803 },
    { lat: 48.7317237, lng: 22.1984345 },
    { lat: 48.7319379, lng: 22.1985449 },
    { lat: 48.7318679, lng: 22.1990851 },
    { lat: 48.7320485, lng: 22.1993034 },
    { lat: 48.7320562, lng: 22.1993935 },
    { lat: 48.732847, lng: 22.1998912 },
    { lat: 48.7331762, lng: 22.2000259 },
    { lat: 48.7334172, lng: 22.2002371 },
    { lat: 48.7334028, lng: 22.2004944 },
    { lat: 48.733558, lng: 22.20074 },
    { lat: 48.7335583, lng: 22.2009068 },
    { lat: 48.7340001, lng: 22.20114 },
    { lat: 48.7340706, lng: 22.2012233 },
    { lat: 48.7357054, lng: 22.2016883 },
    { lat: 48.7394558, lng: 22.2024418 },
    { lat: 48.7405695, lng: 22.202951 },
    { lat: 48.7405068, lng: 22.2035034 },
    { lat: 48.7407553, lng: 22.203545 },
    { lat: 48.7421807, lng: 22.2043082 },
    { lat: 48.7432551, lng: 22.2043636 },
    { lat: 48.7446036, lng: 22.2046048 },
    { lat: 48.745306, lng: 22.2012635 },
    { lat: 48.7476447, lng: 22.2041965 },
    { lat: 48.7486585, lng: 22.205349 },
    { lat: 48.7487186, lng: 22.2052771 },
    { lat: 48.7497976, lng: 22.2039996 },
    { lat: 48.7506705, lng: 22.2024141 },
    { lat: 48.7525424, lng: 22.199455 },
    { lat: 48.7515046, lng: 22.1951222 },
    { lat: 48.7507801, lng: 22.1922991 },
    { lat: 48.7507134, lng: 22.1921871 },
    { lat: 48.7518496, lng: 22.1901246 },
    { lat: 48.7527327, lng: 22.1888003 },
    { lat: 48.7538204, lng: 22.187374 },
    { lat: 48.7544145, lng: 22.1863973 },
    { lat: 48.7557651, lng: 22.1844093 },
    { lat: 48.7563092, lng: 22.1846482 },
    { lat: 48.7587197, lng: 22.1861734 },
    { lat: 48.7616718, lng: 22.187928 },
    { lat: 48.761175, lng: 22.1888267 },
    { lat: 48.7603612, lng: 22.1901326 },
    { lat: 48.760311, lng: 22.1908468 },
    { lat: 48.7604709, lng: 22.1911501 },
    { lat: 48.7618002, lng: 22.1928662 },
    { lat: 48.7621306, lng: 22.1933919 },
    { lat: 48.7624142, lng: 22.1941383 },
    { lat: 48.7627987, lng: 22.1956624 },
    { lat: 48.762887, lng: 22.1961381 },
    { lat: 48.7628986, lng: 22.1964473 },
    { lat: 48.7629633, lng: 22.1966714 },
    { lat: 48.762932, lng: 22.1978652 },
    { lat: 48.7638417, lng: 22.1975626 },
    { lat: 48.7641846, lng: 22.1975487 },
    { lat: 48.7644405, lng: 22.197233 },
    { lat: 48.7656378, lng: 22.1963596 },
    { lat: 48.7664896, lng: 22.1953369 },
    { lat: 48.7666734, lng: 22.1956008 },
    { lat: 48.7667746, lng: 22.195659 },
    { lat: 48.766817, lng: 22.1955024 },
    { lat: 48.7673402, lng: 22.1935715 },
    { lat: 48.7683534, lng: 22.1951425 },
    { lat: 48.7690176, lng: 22.196645 },
    { lat: 48.7694905, lng: 22.196379 },
    { lat: 48.7697919, lng: 22.1958798 },
    { lat: 48.769686, lng: 22.1955776 },
    { lat: 48.7702308, lng: 22.1948019 },
    { lat: 48.7702439, lng: 22.1942902 },
    { lat: 48.7695704, lng: 22.1929229 },
    { lat: 48.7694481, lng: 22.1924886 },
    { lat: 48.7692028, lng: 22.1920895 },
    { lat: 48.7689355, lng: 22.1918595 },
    { lat: 48.7680208, lng: 22.1913025 },
    { lat: 48.7671056, lng: 22.1904892 },
    { lat: 48.76668, lng: 22.1900258 },
    { lat: 48.7665632, lng: 22.1893886 },
    { lat: 48.7662195, lng: 22.1889637 },
    { lat: 48.7654231, lng: 22.1881696 },
    { lat: 48.7646344, lng: 22.1890968 },
    { lat: 48.7634786, lng: 22.1859205 },
    { lat: 48.7632908, lng: 22.1852304 },
    { lat: 48.7612685, lng: 22.1795328 },
    { lat: 48.7621065, lng: 22.1784047 },
  ],
  Bunkovce: [
    { lat: 48.736795, lng: 22.1219279 },
    { lat: 48.7371214, lng: 22.1199729 },
    { lat: 48.7374343, lng: 22.1187303 },
    { lat: 48.7374429, lng: 22.1176076 },
    { lat: 48.7380604, lng: 22.1141609 },
    { lat: 48.7384146, lng: 22.1142471 },
    { lat: 48.739259, lng: 22.1103576 },
    { lat: 48.7393401, lng: 22.109112 },
    { lat: 48.7389771, lng: 22.1074013 },
    { lat: 48.7388402, lng: 22.1069404 },
    { lat: 48.7378448, lng: 22.1067502 },
    { lat: 48.7353443, lng: 22.1066113 },
    { lat: 48.7352701, lng: 22.106522 },
    { lat: 48.7341652, lng: 22.1061278 },
    { lat: 48.7330821, lng: 22.1058461 },
    { lat: 48.7317553, lng: 22.0989479 },
    { lat: 48.7310847, lng: 22.0991811 },
    { lat: 48.7309196, lng: 22.098286 },
    { lat: 48.7284717, lng: 22.098567 },
    { lat: 48.7281194, lng: 22.0943215 },
    { lat: 48.7271581, lng: 22.0950475 },
    { lat: 48.726192, lng: 22.0955294 },
    { lat: 48.7255065, lng: 22.0960338 },
    { lat: 48.7232601, lng: 22.0969392 },
    { lat: 48.7234759, lng: 22.0984036 },
    { lat: 48.7201709, lng: 22.1000189 },
    { lat: 48.7157071, lng: 22.1040111 },
    { lat: 48.71551, lng: 22.1042907 },
    { lat: 48.714399, lng: 22.1070288 },
    { lat: 48.7116143, lng: 22.1120204 },
    { lat: 48.7111774, lng: 22.1145338 },
    { lat: 48.7110413, lng: 22.1147288 },
    { lat: 48.710769, lng: 22.1149208 },
    { lat: 48.7099119, lng: 22.1144371 },
    { lat: 48.7085765, lng: 22.1134773 },
    { lat: 48.7023261, lng: 22.1110066 },
    { lat: 48.7022428, lng: 22.1111895 },
    { lat: 48.7049256, lng: 22.1175172 },
    { lat: 48.7059193, lng: 22.1229075 },
    { lat: 48.7074237, lng: 22.1262013 },
    { lat: 48.7090138, lng: 22.1288253 },
    { lat: 48.7090633, lng: 22.1308037 },
    { lat: 48.7091318, lng: 22.1308007 },
    { lat: 48.7108887, lng: 22.1333011 },
    { lat: 48.7128089, lng: 22.1359112 },
    { lat: 48.7137711, lng: 22.135974 },
    { lat: 48.7183064, lng: 22.1388707 },
    { lat: 48.7236788, lng: 22.1460865 },
    { lat: 48.7252512, lng: 22.1413336 },
    { lat: 48.7264325, lng: 22.1373788 },
    { lat: 48.7273995, lng: 22.1343264 },
    { lat: 48.7282813, lng: 22.1315692 },
    { lat: 48.7277995, lng: 22.1309731 },
    { lat: 48.7273079, lng: 22.1304898 },
    { lat: 48.7273593, lng: 22.1303289 },
    { lat: 48.7275758, lng: 22.1303035 },
    { lat: 48.7359794, lng: 22.121928 },
    { lat: 48.7361496, lng: 22.1218459 },
    { lat: 48.736795, lng: 22.1219279 },
  ],
  BlatnéRemety: [
    { lat: 48.7232601, lng: 22.0969392 },
    { lat: 48.7232497, lng: 22.096881 },
    { lat: 48.7226792, lng: 22.0943539 },
    { lat: 48.7182893, lng: 22.0761244 },
    { lat: 48.718258, lng: 22.07582 },
    { lat: 48.717614, lng: 22.076536 },
    { lat: 48.717032, lng: 22.077171 },
    { lat: 48.715996, lng: 22.077667 },
    { lat: 48.715111, lng: 22.078024 },
    { lat: 48.714074, lng: 22.07814 },
    { lat: 48.713057, lng: 22.078249 },
    { lat: 48.711139, lng: 22.078405 },
    { lat: 48.710586, lng: 22.078418 },
    { lat: 48.709685, lng: 22.078448 },
    { lat: 48.708467, lng: 22.078376 },
    { lat: 48.707935, lng: 22.07801 },
    { lat: 48.707447, lng: 22.077614 },
    { lat: 48.707123, lng: 22.077361 },
    { lat: 48.705847, lng: 22.076346 },
    { lat: 48.705528, lng: 22.076086 },
    { lat: 48.704739, lng: 22.075457 },
    { lat: 48.703701, lng: 22.074589 },
    { lat: 48.703196, lng: 22.074026 },
    { lat: 48.70278, lng: 22.073547 },
    { lat: 48.70247, lng: 22.073189 },
    { lat: 48.702438, lng: 22.07326 },
    { lat: 48.7024, lng: 22.073342 },
    { lat: 48.702363, lng: 22.073423 },
    { lat: 48.701586, lng: 22.073206 },
    { lat: 48.701602, lng: 22.075585 },
    { lat: 48.701605, lng: 22.077219 },
    { lat: 48.701614, lng: 22.07933 },
    { lat: 48.701604, lng: 22.081992 },
    { lat: 48.7027341, lng: 22.0902166 },
    { lat: 48.7032442, lng: 22.0922887 },
    { lat: 48.7021003, lng: 22.0929545 },
    { lat: 48.702647, lng: 22.0951906 },
    { lat: 48.7018604, lng: 22.0960777 },
    { lat: 48.6972738, lng: 22.0941542 },
    { lat: 48.6972582, lng: 22.094259 },
    { lat: 48.6974991, lng: 22.0950778 },
    { lat: 48.697761, lng: 22.0965806 },
    { lat: 48.6985206, lng: 22.0968886 },
    { lat: 48.6985754, lng: 22.0973885 },
    { lat: 48.698335, lng: 22.0978807 },
    { lat: 48.697767, lng: 22.0978818 },
    { lat: 48.6976299, lng: 22.0981256 },
    { lat: 48.6975833, lng: 22.0988257 },
    { lat: 48.6979915, lng: 22.1002024 },
    { lat: 48.6984614, lng: 22.1006646 },
    { lat: 48.6991784, lng: 22.100924 },
    { lat: 48.6996166, lng: 22.1009428 },
    { lat: 48.700004, lng: 22.1031144 },
    { lat: 48.6991331, lng: 22.1042477 },
    { lat: 48.6990068, lng: 22.1050906 },
    { lat: 48.6987927, lng: 22.1055092 },
    { lat: 48.6987623, lng: 22.1062661 },
    { lat: 48.6971596, lng: 22.1061849 },
    { lat: 48.6955718, lng: 22.1066334 },
    { lat: 48.6955895, lng: 22.1067646 },
    { lat: 48.6964717, lng: 22.1120291 },
    { lat: 48.6990092, lng: 22.1161454 },
    { lat: 48.6991779, lng: 22.1165238 },
    { lat: 48.6996869, lng: 22.1195201 },
    { lat: 48.6999488, lng: 22.1213813 },
    { lat: 48.7004879, lng: 22.1234641 },
    { lat: 48.7015513, lng: 22.1250212 },
    { lat: 48.7033784, lng: 22.1259001 },
    { lat: 48.704686, lng: 22.1264493 },
    { lat: 48.7071856, lng: 22.1288623 },
    { lat: 48.7090633, lng: 22.1308037 },
    { lat: 48.7090138, lng: 22.1288253 },
    { lat: 48.7074237, lng: 22.1262013 },
    { lat: 48.7059193, lng: 22.1229075 },
    { lat: 48.7049256, lng: 22.1175172 },
    { lat: 48.7022428, lng: 22.1111895 },
    { lat: 48.7023261, lng: 22.1110066 },
    { lat: 48.7085765, lng: 22.1134773 },
    { lat: 48.7099119, lng: 22.1144371 },
    { lat: 48.710769, lng: 22.1149208 },
    { lat: 48.7110413, lng: 22.1147288 },
    { lat: 48.7111774, lng: 22.1145338 },
    { lat: 48.7116143, lng: 22.1120204 },
    { lat: 48.714399, lng: 22.1070288 },
    { lat: 48.71551, lng: 22.1042907 },
    { lat: 48.7157071, lng: 22.1040111 },
    { lat: 48.7201709, lng: 22.1000189 },
    { lat: 48.7234759, lng: 22.0984036 },
    { lat: 48.7232601, lng: 22.0969392 },
  ],
  VeľkéRevištia: [
    { lat: 48.7646, lng: 22.1291634 },
    { lat: 48.764225, lng: 22.1277872 },
    { lat: 48.7639721, lng: 22.1277985 },
    { lat: 48.7615343, lng: 22.1264324 },
    { lat: 48.7604051, lng: 22.1241358 },
    { lat: 48.7635079, lng: 22.1089904 },
    { lat: 48.7638558, lng: 22.1073238 },
    { lat: 48.7641338, lng: 22.1059461 },
    { lat: 48.7642775, lng: 22.1049835 },
    { lat: 48.7647446, lng: 22.0985086 },
    { lat: 48.762382, lng: 22.098577 },
    { lat: 48.761699, lng: 22.098728 },
    { lat: 48.760437, lng: 22.09889 },
    { lat: 48.759297, lng: 22.098998 },
    { lat: 48.759316, lng: 22.097418 },
    { lat: 48.75873, lng: 22.095838 },
    { lat: 48.757975, lng: 22.096143 },
    { lat: 48.757972, lng: 22.093783 },
    { lat: 48.757875, lng: 22.092042 },
    { lat: 48.757871, lng: 22.091966 },
    { lat: 48.757866, lng: 22.0919 },
    { lat: 48.756342, lng: 22.092516 },
    { lat: 48.755836, lng: 22.088846 },
    { lat: 48.755256, lng: 22.085661 },
    { lat: 48.75499, lng: 22.08578 },
    { lat: 48.754753, lng: 22.084464 },
    { lat: 48.754381, lng: 22.082338 },
    { lat: 48.753947, lng: 22.080224 },
    { lat: 48.755227, lng: 22.078405 },
    { lat: 48.755217, lng: 22.078335 },
    { lat: 48.755198, lng: 22.078268 },
    { lat: 48.75517, lng: 22.078204 },
    { lat: 48.754575, lng: 22.076019 },
    { lat: 48.754179, lng: 22.073127 },
    { lat: 48.753599, lng: 22.0728 },
    { lat: 48.753587, lng: 22.072734 },
    { lat: 48.753187, lng: 22.0705 },
    { lat: 48.752946, lng: 22.069209 },
    { lat: 48.75276, lng: 22.067821 },
    { lat: 48.752694, lng: 22.067473 },
    { lat: 48.7491845, lng: 22.0693541 },
    { lat: 48.7486033, lng: 22.0737551 },
    { lat: 48.7481272, lng: 22.0746389 },
    { lat: 48.7476573, lng: 22.0752662 },
    { lat: 48.7466341, lng: 22.0763281 },
    { lat: 48.7458842, lng: 22.0776142 },
    { lat: 48.745517, lng: 22.0800023 },
    { lat: 48.7450438, lng: 22.0811603 },
    { lat: 48.7449593, lng: 22.0811054 },
    { lat: 48.7448058, lng: 22.0814177 },
    { lat: 48.7433316, lng: 22.0821005 },
    { lat: 48.7432044, lng: 22.0820963 },
    { lat: 48.7429394, lng: 22.0822611 },
    { lat: 48.7392645, lng: 22.0832489 },
    { lat: 48.7378525, lng: 22.0838151 },
    { lat: 48.7331372, lng: 22.0862342 },
    { lat: 48.7318182, lng: 22.0897203 },
    { lat: 48.7316508, lng: 22.0900162 },
    { lat: 48.7304442, lng: 22.0917868 },
    { lat: 48.7293392, lng: 22.093006 },
    { lat: 48.7291795, lng: 22.0932494 },
    { lat: 48.7281194, lng: 22.0943215 },
    { lat: 48.7284717, lng: 22.098567 },
    { lat: 48.7309196, lng: 22.098286 },
    { lat: 48.7310847, lng: 22.0991811 },
    { lat: 48.7317553, lng: 22.0989479 },
    { lat: 48.7330821, lng: 22.1058461 },
    { lat: 48.7341652, lng: 22.1061278 },
    { lat: 48.7352701, lng: 22.106522 },
    { lat: 48.7353443, lng: 22.1066113 },
    { lat: 48.7378448, lng: 22.1067502 },
    { lat: 48.7388402, lng: 22.1069404 },
    { lat: 48.7389771, lng: 22.1074013 },
    { lat: 48.7393401, lng: 22.109112 },
    { lat: 48.739259, lng: 22.1103576 },
    { lat: 48.7384146, lng: 22.1142471 },
    { lat: 48.7380604, lng: 22.1141609 },
    { lat: 48.7374429, lng: 22.1176076 },
    { lat: 48.7374343, lng: 22.1187303 },
    { lat: 48.7371214, lng: 22.1199729 },
    { lat: 48.736795, lng: 22.1219279 },
    { lat: 48.7368679, lng: 22.1219692 },
    { lat: 48.7382708, lng: 22.1214975 },
    { lat: 48.7385842, lng: 22.1214815 },
    { lat: 48.7414186, lng: 22.1221452 },
    { lat: 48.7431158, lng: 22.1250204 },
    { lat: 48.7435683, lng: 22.12523 },
    { lat: 48.7451164, lng: 22.1251983 },
    { lat: 48.7453524, lng: 22.1250876 },
    { lat: 48.7454867, lng: 22.1249664 },
    { lat: 48.7459749, lng: 22.1241419 },
    { lat: 48.7463183, lng: 22.123867 },
    { lat: 48.7468095, lng: 22.1239095 },
    { lat: 48.749529, lng: 22.1247603 },
    { lat: 48.7499619, lng: 22.1246534 },
    { lat: 48.7505002, lng: 22.124198 },
    { lat: 48.7517279, lng: 22.1229233 },
    { lat: 48.7521347, lng: 22.1229043 },
    { lat: 48.7524548, lng: 22.1230729 },
    { lat: 48.7527322, lng: 22.1235375 },
    { lat: 48.7528909, lng: 22.1241258 },
    { lat: 48.7530362, lng: 22.1269227 },
    { lat: 48.7535187, lng: 22.1278808 },
    { lat: 48.7551282, lng: 22.129943 },
    { lat: 48.755343, lng: 22.1300378 },
    { lat: 48.7552713, lng: 22.1301981 },
    { lat: 48.758854, lng: 22.1318957 },
    { lat: 48.759077, lng: 22.1320468 },
    { lat: 48.760087, lng: 22.1332489 },
    { lat: 48.7640003, lng: 22.136097 },
    { lat: 48.7647523, lng: 22.1296074 },
    { lat: 48.7646, lng: 22.1291634 },
  ],
  Porostov: [
    { lat: 48.7015481, lng: 22.1408381 },
    { lat: 48.7014173, lng: 22.1411114 },
    { lat: 48.7011636, lng: 22.1418674 },
    { lat: 48.7010461, lng: 22.1419359 },
    { lat: 48.7007389, lng: 22.1423162 },
    { lat: 48.7003905, lng: 22.1420412 },
    { lat: 48.7002542, lng: 22.1417476 },
    { lat: 48.7000931, lng: 22.1416397 },
    { lat: 48.6997532, lng: 22.1417702 },
    { lat: 48.6995622, lng: 22.1414966 },
    { lat: 48.6994729, lng: 22.141528 },
    { lat: 48.6991445, lng: 22.1420603 },
    { lat: 48.6987104, lng: 22.1422073 },
    { lat: 48.6979542, lng: 22.1429151 },
    { lat: 48.6961482, lng: 22.139896 },
    { lat: 48.6961064, lng: 22.1400342 },
    { lat: 48.6940217, lng: 22.1397273 },
    { lat: 48.6933851, lng: 22.1387695 },
    { lat: 48.6930742, lng: 22.1385571 },
    { lat: 48.6927707, lng: 22.1385109 },
    { lat: 48.6924456, lng: 22.1379809 },
    { lat: 48.6923619, lng: 22.1381545 },
    { lat: 48.6923554, lng: 22.138644 },
    { lat: 48.6920593, lng: 22.1395506 },
    { lat: 48.6920281, lng: 22.140338 },
    { lat: 48.6917799, lng: 22.1412309 },
    { lat: 48.6920309, lng: 22.1415722 },
    { lat: 48.6919458, lng: 22.1418783 },
    { lat: 48.6912658, lng: 22.1429643 },
    { lat: 48.6909724, lng: 22.1439386 },
    { lat: 48.690958, lng: 22.1447287 },
    { lat: 48.6913256, lng: 22.1460739 },
    { lat: 48.691526, lng: 22.1463175 },
    { lat: 48.6913376, lng: 22.1484804 },
    { lat: 48.6914722, lng: 22.1526908 },
    { lat: 48.6913627, lng: 22.1542735 },
    { lat: 48.6912805, lng: 22.1546523 },
    { lat: 48.6912421, lng: 22.1553358 },
    { lat: 48.6913408, lng: 22.1574593 },
    { lat: 48.6916093, lng: 22.1588504 },
    { lat: 48.6915353, lng: 22.1598907 },
    { lat: 48.690547, lng: 22.1632214 },
    { lat: 48.6900724, lng: 22.1655153 },
    { lat: 48.6896325, lng: 22.166923 },
    { lat: 48.6894046, lng: 22.1680681 },
    { lat: 48.6891234, lng: 22.1691937 },
    { lat: 48.6887542, lng: 22.1701017 },
    { lat: 48.6885094, lng: 22.1712954 },
    { lat: 48.6881665, lng: 22.1725281 },
    { lat: 48.6872393, lng: 22.1750124 },
    { lat: 48.6871344, lng: 22.1754426 },
    { lat: 48.6868003, lng: 22.178673 },
    { lat: 48.6867235, lng: 22.1800315 },
    { lat: 48.6874802, lng: 22.1800681 },
    { lat: 48.6874767, lng: 22.1803033 },
    { lat: 48.687424, lng: 22.1809985 },
    { lat: 48.6866982, lng: 22.1809456 },
    { lat: 48.6866069, lng: 22.1830899 },
    { lat: 48.6866463, lng: 22.1834837 },
    { lat: 48.6868158, lng: 22.1838134 },
    { lat: 48.6883087, lng: 22.185592 },
    { lat: 48.6880997, lng: 22.1857496 },
    { lat: 48.6881422, lng: 22.1859905 },
    { lat: 48.6882843, lng: 22.1859044 },
    { lat: 48.6880828, lng: 22.1867316 },
    { lat: 48.6881767, lng: 22.1870556 },
    { lat: 48.6897754, lng: 22.1882471 },
    { lat: 48.6910202, lng: 22.1893621 },
    { lat: 48.6911776, lng: 22.1894087 },
    { lat: 48.692099, lng: 22.1884297 },
    { lat: 48.6930585, lng: 22.1905967 },
    { lat: 48.698715, lng: 22.1917704 },
    { lat: 48.6988682, lng: 22.1918742 },
    { lat: 48.6993007, lng: 22.1925863 },
    { lat: 48.7000848, lng: 22.1933388 },
    { lat: 48.7004671, lng: 22.1938758 },
    { lat: 48.7012585, lng: 22.195535 },
    { lat: 48.7017309, lng: 22.1960738 },
    { lat: 48.7032014, lng: 22.1974662 },
    { lat: 48.7039273, lng: 22.1982746 },
    { lat: 48.7035768, lng: 22.1992358 },
    { lat: 48.7046983, lng: 22.1999304 },
    { lat: 48.7054321, lng: 22.1985138 },
    { lat: 48.7064072, lng: 22.1968281 },
    { lat: 48.7052141, lng: 22.1956053 },
    { lat: 48.7073424, lng: 22.1920165 },
    { lat: 48.7083646, lng: 22.190183 },
    { lat: 48.7066411, lng: 22.1885926 },
    { lat: 48.7086005, lng: 22.1839132 },
    { lat: 48.7087717, lng: 22.1843195 },
    { lat: 48.7089781, lng: 22.1866141 },
    { lat: 48.7091146, lng: 22.1868716 },
    { lat: 48.709379, lng: 22.1870245 },
    { lat: 48.7096645, lng: 22.1869654 },
    { lat: 48.7109434, lng: 22.1825848 },
    { lat: 48.7094474, lng: 22.1808694 },
    { lat: 48.7094179, lng: 22.1807107 },
    { lat: 48.7081593, lng: 22.181158 },
    { lat: 48.7079927, lng: 22.181098 },
    { lat: 48.707931, lng: 22.1810082 },
    { lat: 48.706833, lng: 22.1777155 },
    { lat: 48.7093509, lng: 22.1763731 },
    { lat: 48.7054578, lng: 22.1725884 },
    { lat: 48.7077311, lng: 22.1698594 },
    { lat: 48.7053732, lng: 22.1675469 },
    { lat: 48.7029341, lng: 22.1628438 },
    { lat: 48.7044825, lng: 22.1573168 },
    { lat: 48.7044128, lng: 22.1572247 },
    { lat: 48.705663, lng: 22.1527723 },
    { lat: 48.7062506, lng: 22.1512348 },
    { lat: 48.7072734, lng: 22.1487587 },
    { lat: 48.708094, lng: 22.1468112 },
    { lat: 48.7089569, lng: 22.1448821 },
    { lat: 48.7032427, lng: 22.1393187 },
    { lat: 48.7015481, lng: 22.1408381 },
  ],
  Hlivištia: [
    { lat: 48.8032586, lng: 22.1908306 },
    { lat: 48.8014266, lng: 22.1904007 },
    { lat: 48.8000112, lng: 22.1898809 },
    { lat: 48.7970294, lng: 22.1892927 },
    { lat: 48.7960103, lng: 22.1892436 },
    { lat: 48.7942294, lng: 22.1889748 },
    { lat: 48.7931644, lng: 22.1887336 },
    { lat: 48.7927371, lng: 22.1900256 },
    { lat: 48.7930663, lng: 22.1900633 },
    { lat: 48.7932326, lng: 22.1896073 },
    { lat: 48.7936372, lng: 22.1898926 },
    { lat: 48.793584, lng: 22.1904219 },
    { lat: 48.7939058, lng: 22.1905189 },
    { lat: 48.7934404, lng: 22.1921668 },
    { lat: 48.7932233, lng: 22.192207 },
    { lat: 48.7930219, lng: 22.1926994 },
    { lat: 48.7943655, lng: 22.193274 },
    { lat: 48.7943672, lng: 22.1938882 },
    { lat: 48.7951124, lng: 22.196535 },
    { lat: 48.7950598, lng: 22.1970936 },
    { lat: 48.7951233, lng: 22.1979586 },
    { lat: 48.7957398, lng: 22.2006874 },
    { lat: 48.7965311, lng: 22.2015517 },
    { lat: 48.7968361, lng: 22.2022576 },
    { lat: 48.7972207, lng: 22.2027039 },
    { lat: 48.7985653, lng: 22.2033122 },
    { lat: 48.7989265, lng: 22.2040149 },
    { lat: 48.7992503, lng: 22.2048022 },
    { lat: 48.7986676, lng: 22.2059959 },
    { lat: 48.7981023, lng: 22.2061155 },
    { lat: 48.7976964, lng: 22.2065612 },
    { lat: 48.7973675, lng: 22.2071915 },
    { lat: 48.7971441, lng: 22.2081517 },
    { lat: 48.7979956, lng: 22.2087515 },
    { lat: 48.7981388, lng: 22.209311 },
    { lat: 48.7983868, lng: 22.2093852 },
    { lat: 48.7983855, lng: 22.2096853 },
    { lat: 48.7981092, lng: 22.2103461 },
    { lat: 48.7981663, lng: 22.210777 },
    { lat: 48.798289, lng: 22.2106379 },
    { lat: 48.7984927, lng: 22.2107861 },
    { lat: 48.7985326, lng: 22.2110102 },
    { lat: 48.7989463, lng: 22.2111044 },
    { lat: 48.7993996, lng: 22.2116471 },
    { lat: 48.7993641, lng: 22.211923 },
    { lat: 48.7994347, lng: 22.2122115 },
    { lat: 48.8002413, lng: 22.2127677 },
    { lat: 48.799917, lng: 22.2142891 },
    { lat: 48.8003031, lng: 22.2147529 },
    { lat: 48.7996138, lng: 22.2170013 },
    { lat: 48.7989262, lng: 22.2164853 },
    { lat: 48.7977527, lng: 22.2154239 },
    { lat: 48.7961412, lng: 22.2144919 },
    { lat: 48.7950718, lng: 22.2142051 },
    { lat: 48.7944591, lng: 22.2137734 },
    { lat: 48.7922783, lng: 22.2161682 },
    { lat: 48.7876265, lng: 22.2210164 },
    { lat: 48.7867036, lng: 22.2220977 },
    { lat: 48.7871443, lng: 22.2227834 },
    { lat: 48.7872097, lng: 22.2227879 },
    { lat: 48.7876219, lng: 22.2232581 },
    { lat: 48.7875703, lng: 22.2233635 },
    { lat: 48.7877317, lng: 22.2235917 },
    { lat: 48.7877901, lng: 22.2232869 },
    { lat: 48.7879648, lng: 22.2237484 },
    { lat: 48.788061, lng: 22.2238776 },
    { lat: 48.7886311, lng: 22.2240725 },
    { lat: 48.7889156, lng: 22.2243268 },
    { lat: 48.7889138, lng: 22.2243922 },
    { lat: 48.7890464, lng: 22.2244307 },
    { lat: 48.7891616, lng: 22.2245564 },
    { lat: 48.7892828, lng: 22.2247962 },
    { lat: 48.7894463, lng: 22.2248174 },
    { lat: 48.7894767, lng: 22.2246693 },
    { lat: 48.7898768, lng: 22.2246515 },
    { lat: 48.7899921, lng: 22.2242799 },
    { lat: 48.7900429, lng: 22.2242841 },
    { lat: 48.7903583, lng: 22.2245553 },
    { lat: 48.790388, lng: 22.2247768 },
    { lat: 48.790599, lng: 22.2249585 },
    { lat: 48.7908717, lng: 22.225074 },
    { lat: 48.7910382, lng: 22.2250265 },
    { lat: 48.7914993, lng: 22.2255203 },
    { lat: 48.7915707, lng: 22.2258463 },
    { lat: 48.7919774, lng: 22.226136 },
    { lat: 48.7922591, lng: 22.2268517 },
    { lat: 48.7924306, lng: 22.2269713 },
    { lat: 48.7924825, lng: 22.2272373 },
    { lat: 48.7925893, lng: 22.2274365 },
    { lat: 48.7926932, lng: 22.2274044 },
    { lat: 48.7931943, lng: 22.2277297 },
    { lat: 48.7934659, lng: 22.2277546 },
    { lat: 48.7937282, lng: 22.2276583 },
    { lat: 48.7938658, lng: 22.2275292 },
    { lat: 48.7939365, lng: 22.227268 },
    { lat: 48.7942364, lng: 22.226853 },
    { lat: 48.7943494, lng: 22.2264709 },
    { lat: 48.7945849, lng: 22.2263446 },
    { lat: 48.7947017, lng: 22.2265794 },
    { lat: 48.7945911, lng: 22.226767 },
    { lat: 48.794613, lng: 22.2269446 },
    { lat: 48.7947648, lng: 22.226986 },
    { lat: 48.7948199, lng: 22.2267863 },
    { lat: 48.7949617, lng: 22.2269391 },
    { lat: 48.7951127, lng: 22.2268118 },
    { lat: 48.7951732, lng: 22.2266496 },
    { lat: 48.7952946, lng: 22.2265863 },
    { lat: 48.7953408, lng: 22.2266902 },
    { lat: 48.7952865, lng: 22.2266766 },
    { lat: 48.7952478, lng: 22.2268131 },
    { lat: 48.7954042, lng: 22.2269117 },
    { lat: 48.7952631, lng: 22.2270435 },
    { lat: 48.7953345, lng: 22.2273652 },
    { lat: 48.7956507, lng: 22.2273218 },
    { lat: 48.7956774, lng: 22.2275655 },
    { lat: 48.7955382, lng: 22.2276201 },
    { lat: 48.7954628, lng: 22.22802 },
    { lat: 48.7953797, lng: 22.228076 },
    { lat: 48.7953781, lng: 22.2283112 },
    { lat: 48.7956221, lng: 22.2286047 },
    { lat: 48.7955219, lng: 22.228952 },
    { lat: 48.7954002, lng: 22.2289903 },
    { lat: 48.7952902, lng: 22.2292022 },
    { lat: 48.7952864, lng: 22.2293262 },
    { lat: 48.7952043, lng: 22.2293329 },
    { lat: 48.7951641, lng: 22.2294962 },
    { lat: 48.7950346, lng: 22.2295336 },
    { lat: 48.7950363, lng: 22.2296109 },
    { lat: 48.7952867, lng: 22.2297407 },
    { lat: 48.7953371, lng: 22.2302301 },
    { lat: 48.7952916, lng: 22.2303141 },
    { lat: 48.7954466, lng: 22.2304995 },
    { lat: 48.7953167, lng: 22.2308758 },
    { lat: 48.795282, lng: 22.2312846 },
    { lat: 48.7953339, lng: 22.2313493 },
    { lat: 48.7953553, lng: 22.2316015 },
    { lat: 48.795455, lng: 22.2318301 },
    { lat: 48.7957461, lng: 22.2320199 },
    { lat: 48.795698, lng: 22.2321904 },
    { lat: 48.7958078, lng: 22.2325133 },
    { lat: 48.7959906, lng: 22.2326966 },
    { lat: 48.7961814, lng: 22.2330813 },
    { lat: 48.7963978, lng: 22.2333446 },
    { lat: 48.7964144, lng: 22.2334857 },
    { lat: 48.7969073, lng: 22.2343002 },
    { lat: 48.796933, lng: 22.2347409 },
    { lat: 48.7970169, lng: 22.2348251 },
    { lat: 48.797095, lng: 22.2347454 },
    { lat: 48.7972512, lng: 22.2348384 },
    { lat: 48.7973431, lng: 22.235133 },
    { lat: 48.7975517, lng: 22.235403 },
    { lat: 48.7978101, lng: 22.235281 },
    { lat: 48.7979849, lng: 22.2354029 },
    { lat: 48.7981074, lng: 22.2356025 },
    { lat: 48.798185, lng: 22.2360045 },
    { lat: 48.7987167, lng: 22.2357924 },
    { lat: 48.7989953, lng: 22.236025 },
    { lat: 48.7991615, lng: 22.2360704 },
    { lat: 48.7993025, lng: 22.2359322 },
    { lat: 48.7997507, lng: 22.2362057 },
    { lat: 48.8000255, lng: 22.2361202 },
    { lat: 48.8000652, lng: 22.2363847 },
    { lat: 48.800305, lng: 22.2362543 },
    { lat: 48.800442, lng: 22.2363425 },
    { lat: 48.8005472, lng: 22.236218 },
    { lat: 48.8006536, lng: 22.2363481 },
    { lat: 48.8009205, lng: 22.2362602 },
    { lat: 48.8011229, lng: 22.2365099 },
    { lat: 48.8013374, lng: 22.2363961 },
    { lat: 48.801656, lng: 22.2365072 },
    { lat: 48.802038, lng: 22.2364437 },
    { lat: 48.8020964, lng: 22.2366822 },
    { lat: 48.8021795, lng: 22.2366758 },
    { lat: 48.802344, lng: 22.2363413 },
    { lat: 48.8026781, lng: 22.2364383 },
    { lat: 48.8029591, lng: 22.2367393 },
    { lat: 48.8030557, lng: 22.2366305 },
    { lat: 48.8032518, lng: 22.2365987 },
    { lat: 48.8036942, lng: 22.2367155 },
    { lat: 48.8037926, lng: 22.2365952 },
    { lat: 48.8040008, lng: 22.2366923 },
    { lat: 48.8043235, lng: 22.2365369 },
    { lat: 48.804465, lng: 22.2367812 },
    { lat: 48.8046849, lng: 22.236908 },
    { lat: 48.8048856, lng: 22.2367243 },
    { lat: 48.8050542, lng: 22.2366768 },
    { lat: 48.8051755, lng: 22.2368629 },
    { lat: 48.8054951, lng: 22.2368208 },
    { lat: 48.8057475, lng: 22.2370409 },
    { lat: 48.8058911, lng: 22.2369789 },
    { lat: 48.8059723, lng: 22.2370882 },
    { lat: 48.806316, lng: 22.2371651 },
    { lat: 48.8064971, lng: 22.2373168 },
    { lat: 48.8067309, lng: 22.2378125 },
    { lat: 48.806925, lng: 22.2377972 },
    { lat: 48.8074012, lng: 22.2382638 },
    { lat: 48.8073504, lng: 22.2386054 },
    { lat: 48.8074966, lng: 22.2389048 },
    { lat: 48.8079951, lng: 22.2392728 },
    { lat: 48.8081109, lng: 22.2394699 },
    { lat: 48.808212, lng: 22.2397185 },
    { lat: 48.8082136, lng: 22.2402468 },
    { lat: 48.8083864, lng: 22.2406248 },
    { lat: 48.8085534, lng: 22.2408022 },
    { lat: 48.8087906, lng: 22.2412475 },
    { lat: 48.8090229, lng: 22.2412756 },
    { lat: 48.8091005, lng: 22.2413719 },
    { lat: 48.8093043, lng: 22.2419395 },
    { lat: 48.8097007, lng: 22.2423588 },
    { lat: 48.8099009, lng: 22.2424171 },
    { lat: 48.8100687, lng: 22.2423735 },
    { lat: 48.8101976, lng: 22.2425529 },
    { lat: 48.8104622, lng: 22.2426125 },
    { lat: 48.8107978, lng: 22.2429411 },
    { lat: 48.81092, lng: 22.2431236 },
    { lat: 48.8111942, lng: 22.2433604 },
    { lat: 48.8113545, lng: 22.2437954 },
    { lat: 48.8116194, lng: 22.2439742 },
    { lat: 48.8117045, lng: 22.2442572 },
    { lat: 48.8118133, lng: 22.2444432 },
    { lat: 48.8120756, lng: 22.2446571 },
    { lat: 48.8121379, lng: 22.2452292 },
    { lat: 48.8125439, lng: 22.2461349 },
    { lat: 48.8126511, lng: 22.2462486 },
    { lat: 48.8127911, lng: 22.2466311 },
    { lat: 48.8127722, lng: 22.2469968 },
    { lat: 48.8130018, lng: 22.2473048 },
    { lat: 48.8131137, lng: 22.2473765 },
    { lat: 48.8132692, lng: 22.2476898 },
    { lat: 48.8134973, lng: 22.2478819 },
    { lat: 48.8137639, lng: 22.2491944 },
    { lat: 48.8140502, lng: 22.2496366 },
    { lat: 48.8146331, lng: 22.2498485 },
    { lat: 48.814773, lng: 22.2501739 },
    { lat: 48.8148865, lng: 22.2502638 },
    { lat: 48.8151259, lng: 22.2502605 },
    { lat: 48.81516, lng: 22.2501742 },
    { lat: 48.8154704, lng: 22.2499724 },
    { lat: 48.8155787, lng: 22.2497873 },
    { lat: 48.8157604, lng: 22.2496556 },
    { lat: 48.8157916, lng: 22.2493864 },
    { lat: 48.8159497, lng: 22.2492145 },
    { lat: 48.8159601, lng: 22.2491301 },
    { lat: 48.8160816, lng: 22.2489783 },
    { lat: 48.8162146, lng: 22.2489408 },
    { lat: 48.8163169, lng: 22.2490366 },
    { lat: 48.8165003, lng: 22.249049 },
    { lat: 48.8168052, lng: 22.2492026 },
    { lat: 48.8169003, lng: 22.2490268 },
    { lat: 48.8169925, lng: 22.2490178 },
    { lat: 48.817256, lng: 22.2487845 },
    { lat: 48.8177342, lng: 22.2488396 },
    { lat: 48.8179338, lng: 22.2487102 },
    { lat: 48.8181625, lng: 22.2487367 },
    { lat: 48.8183046, lng: 22.2489026 },
    { lat: 48.8188608, lng: 22.2490291 },
    { lat: 48.8189281, lng: 22.2491206 },
    { lat: 48.8191484, lng: 22.24921 },
    { lat: 48.8195775, lng: 22.2487819 },
    { lat: 48.8198691, lng: 22.2488509 },
    { lat: 48.8200365, lng: 22.2486913 },
    { lat: 48.8204924, lng: 22.248707 },
    { lat: 48.8208164, lng: 22.2485012 },
    { lat: 48.8211186, lng: 22.2484437 },
    { lat: 48.8215083, lng: 22.2480569 },
    { lat: 48.8223388, lng: 22.2478299 },
    { lat: 48.8233834, lng: 22.2476612 },
    { lat: 48.8241954, lng: 22.2477075 },
    { lat: 48.8247868, lng: 22.2476013 },
    { lat: 48.8252369, lng: 22.2476654 },
    { lat: 48.8255304, lng: 22.247911 },
    { lat: 48.8259231, lng: 22.2478705 },
    { lat: 48.8261649, lng: 22.2477625 },
    { lat: 48.8264877, lng: 22.2479706 },
    { lat: 48.8272403, lng: 22.2486832 },
    { lat: 48.8274033, lng: 22.2489322 },
    { lat: 48.8279508, lng: 22.249017 },
    { lat: 48.8285167, lng: 22.2496832 },
    { lat: 48.8301481, lng: 22.2504638 },
    { lat: 48.8303276, lng: 22.2506941 },
    { lat: 48.8310479, lng: 22.2509712 },
    { lat: 48.831235, lng: 22.2511919 },
    { lat: 48.8321187, lng: 22.2512271 },
    { lat: 48.8330979, lng: 22.2513631 },
    { lat: 48.8334911, lng: 22.2513331 },
    { lat: 48.834334, lng: 22.2515596 },
    { lat: 48.8348731, lng: 22.251626 },
    { lat: 48.8353378, lng: 22.2517822 },
    { lat: 48.8355037, lng: 22.2520665 },
    { lat: 48.8358067, lng: 22.2521885 },
    { lat: 48.8361342, lng: 22.2529069 },
    { lat: 48.8363102, lng: 22.2535291 },
    { lat: 48.8366856, lng: 22.2537721 },
    { lat: 48.8368423, lng: 22.254044 },
    { lat: 48.83715, lng: 22.2541251 },
    { lat: 48.837329, lng: 22.2544894 },
    { lat: 48.8380095, lng: 22.2545372 },
    { lat: 48.838505, lng: 22.2544137 },
    { lat: 48.8392242, lng: 22.2539836 },
    { lat: 48.8393619, lng: 22.254154 },
    { lat: 48.839479, lng: 22.2545687 },
    { lat: 48.8398979, lng: 22.2547954 },
    { lat: 48.8403664, lng: 22.2553779 },
    { lat: 48.8414366, lng: 22.2558068 },
    { lat: 48.8417596, lng: 22.2558114 },
    { lat: 48.8420079, lng: 22.2561594 },
    { lat: 48.8425014, lng: 22.2559978 },
    { lat: 48.8430674, lng: 22.2561107 },
    { lat: 48.8433587, lng: 22.256058 },
    { lat: 48.8441296, lng: 22.2564791 },
    { lat: 48.8447777, lng: 22.2567435 },
    { lat: 48.8452577, lng: 22.2566848 },
    { lat: 48.8458297, lng: 22.2568659 },
    { lat: 48.8464003, lng: 22.2572915 },
    { lat: 48.8470323, lng: 22.2574875 },
    { lat: 48.8474546, lng: 22.2581234 },
    { lat: 48.8477962, lng: 22.2584567 },
    { lat: 48.8487072, lng: 22.2588646 },
    { lat: 48.8489872, lng: 22.2592671 },
    { lat: 48.8493337, lng: 22.2601865 },
    { lat: 48.8495424, lng: 22.2614355 },
    { lat: 48.8496359, lng: 22.2616875 },
    { lat: 48.8499249, lng: 22.2618921 },
    { lat: 48.8503266, lng: 22.2620988 },
    { lat: 48.8519774, lng: 22.2618348 },
    { lat: 48.8526214, lng: 22.2610499 },
    { lat: 48.8530258, lng: 22.260879 },
    { lat: 48.853764, lng: 22.2602307 },
    { lat: 48.8543619, lng: 22.2600096 },
    { lat: 48.8547935, lng: 22.2599483 },
    { lat: 48.8558515, lng: 22.2589687 },
    { lat: 48.8561864, lng: 22.2588524 },
    { lat: 48.8568095, lng: 22.259211 },
    { lat: 48.8577442, lng: 22.2592095 },
    { lat: 48.8581463, lng: 22.2589897 },
    { lat: 48.8578391, lng: 22.2584746 },
    { lat: 48.8573615, lng: 22.2580314 },
    { lat: 48.8571274, lng: 22.2576668 },
    { lat: 48.856855, lng: 22.2568931 },
    { lat: 48.8561413, lng: 22.2559041 },
    { lat: 48.8563244, lng: 22.255194 },
    { lat: 48.8566415, lng: 22.2546238 },
    { lat: 48.8568907, lng: 22.2545015 },
    { lat: 48.8571025, lng: 22.2535081 },
    { lat: 48.8573718, lng: 22.2527717 },
    { lat: 48.8574798, lng: 22.2517047 },
    { lat: 48.8577624, lng: 22.2509659 },
    { lat: 48.8579007, lng: 22.2497419 },
    { lat: 48.8580107, lng: 22.2493373 },
    { lat: 48.8577723, lng: 22.2486762 },
    { lat: 48.8574138, lng: 22.2479337 },
    { lat: 48.8572481, lng: 22.2461027 },
    { lat: 48.8573191, lng: 22.2453012 },
    { lat: 48.8572108, lng: 22.2443241 },
    { lat: 48.8568914, lng: 22.243065 },
    { lat: 48.8568781, lng: 22.2422156 },
    { lat: 48.8565069, lng: 22.2418135 },
    { lat: 48.8552159, lng: 22.2415632 },
    { lat: 48.8547141, lng: 22.2415632 },
    { lat: 48.8543452, lng: 22.2414639 },
    { lat: 48.8538414, lng: 22.2415136 },
    { lat: 48.8533923, lng: 22.2412402 },
    { lat: 48.8521123, lng: 22.2413483 },
    { lat: 48.8515482, lng: 22.240964 },
    { lat: 48.8509476, lng: 22.2403598 },
    { lat: 48.8505917, lng: 22.2400863 },
    { lat: 48.8501063, lng: 22.2399633 },
    { lat: 48.8493737, lng: 22.2390845 },
    { lat: 48.8487999, lng: 22.2387711 },
    { lat: 48.8477921, lng: 22.2377183 },
    { lat: 48.8472042, lng: 22.2372817 },
    { lat: 48.8467776, lng: 22.2368586 },
    { lat: 48.8464379, lng: 22.2363583 },
    { lat: 48.8454896, lng: 22.2352489 },
    { lat: 48.8452197, lng: 22.2346982 },
    { lat: 48.8450453, lng: 22.2336384 },
    { lat: 48.8447547, lng: 22.232457 },
    { lat: 48.8441854, lng: 22.2310022 },
    { lat: 48.8434078, lng: 22.2294732 },
    { lat: 48.8431536, lng: 22.2290696 },
    { lat: 48.8429002, lng: 22.2282581 },
    { lat: 48.8427103, lng: 22.2280122 },
    { lat: 48.8425113, lng: 22.2271659 },
    { lat: 48.8419063, lng: 22.2267139 },
    { lat: 48.8413554, lng: 22.2252751 },
    { lat: 48.841185, lng: 22.2251478 },
    { lat: 48.8409441, lng: 22.2250953 },
    { lat: 48.8403779, lng: 22.2248174 },
    { lat: 48.8393605, lng: 22.2238987 },
    { lat: 48.839088, lng: 22.2229837 },
    { lat: 48.8385209, lng: 22.2218725 },
    { lat: 48.838386, lng: 22.2214721 },
    { lat: 48.8392223, lng: 22.2207509 },
    { lat: 48.8383667, lng: 22.2199624 },
    { lat: 48.8383129, lng: 22.2198259 },
    { lat: 48.8383046, lng: 22.21951 },
    { lat: 48.838095, lng: 22.2191377 },
    { lat: 48.8379337, lng: 22.218664 },
    { lat: 48.8379373, lng: 22.2184818 },
    { lat: 48.8378225, lng: 22.2179257 },
    { lat: 48.8375793, lng: 22.2173185 },
    { lat: 48.8371463, lng: 22.2164704 },
    { lat: 48.8365286, lng: 22.2158125 },
    { lat: 48.8363056, lng: 22.2157491 },
    { lat: 48.8359414, lng: 22.2154559 },
    { lat: 48.8359071, lng: 22.215332 },
    { lat: 48.8357356, lng: 22.215213 },
    { lat: 48.8355405, lng: 22.214744 },
    { lat: 48.8352065, lng: 22.2143875 },
    { lat: 48.8349945, lng: 22.2138172 },
    { lat: 48.8349858, lng: 22.2136342 },
    { lat: 48.8345254, lng: 22.2129161 },
    { lat: 48.8341272, lng: 22.2125172 },
    { lat: 48.8338471, lng: 22.2123232 },
    { lat: 48.8336435, lng: 22.2120214 },
    { lat: 48.8336071, lng: 22.2118498 },
    { lat: 48.8334262, lng: 22.2117081 },
    { lat: 48.8331275, lng: 22.2112791 },
    { lat: 48.832967, lng: 22.2109461 },
    { lat: 48.8325556, lng: 22.2104612 },
    { lat: 48.83221, lng: 22.2097594 },
    { lat: 48.8313629, lng: 22.2087573 },
    { lat: 48.8312677, lng: 22.2083713 },
    { lat: 48.8310593, lng: 22.208003 },
    { lat: 48.8306171, lng: 22.2064073 },
    { lat: 48.8303833, lng: 22.2060675 },
    { lat: 48.8301507, lng: 22.2053374 },
    { lat: 48.8297353, lng: 22.204968 },
    { lat: 48.8295934, lng: 22.2045234 },
    { lat: 48.8291119, lng: 22.2041683 },
    { lat: 48.8287962, lng: 22.2037791 },
    { lat: 48.828816, lng: 22.2036657 },
    { lat: 48.8286773, lng: 22.2032527 },
    { lat: 48.8282603, lng: 22.2031687 },
    { lat: 48.8275569, lng: 22.2024463 },
    { lat: 48.8273698, lng: 22.2018339 },
    { lat: 48.8271504, lng: 22.201534 },
    { lat: 48.8271286, lng: 22.2014173 },
    { lat: 48.8271699, lng: 22.2012081 },
    { lat: 48.8269475, lng: 22.2010691 },
    { lat: 48.8269306, lng: 22.200728 },
    { lat: 48.8266959, lng: 22.2006384 },
    { lat: 48.8266588, lng: 22.2002895 },
    { lat: 48.8263665, lng: 22.2001033 },
    { lat: 48.8261045, lng: 22.1994598 },
    { lat: 48.8258619, lng: 22.1991786 },
    { lat: 48.8255596, lng: 22.1984954 },
    { lat: 48.8256723, lng: 22.1982611 },
    { lat: 48.8253873, lng: 22.1982881 },
    { lat: 48.8252792, lng: 22.1979932 },
    { lat: 48.8251636, lng: 22.1988894 },
    { lat: 48.8248066, lng: 22.1975848 },
    { lat: 48.824595, lng: 22.1977222 },
    { lat: 48.8243678, lng: 22.1975255 },
    { lat: 48.8243462, lng: 22.1973632 },
    { lat: 48.824275, lng: 22.197258 },
    { lat: 48.8241514, lng: 22.197257 },
    { lat: 48.8237891, lng: 22.1969524 },
    { lat: 48.8237057, lng: 22.1967041 },
    { lat: 48.8234611, lng: 22.1963848 },
    { lat: 48.8231436, lng: 22.1962711 },
    { lat: 48.8231447, lng: 22.1960176 },
    { lat: 48.8230967, lng: 22.195837 },
    { lat: 48.8228502, lng: 22.1956318 },
    { lat: 48.8226832, lng: 22.1956544 },
    { lat: 48.8225343, lng: 22.1956875 },
    { lat: 48.8223651, lng: 22.1959715 },
    { lat: 48.8221648, lng: 22.1953723 },
    { lat: 48.8218925, lng: 22.1953155 },
    { lat: 48.8216623, lng: 22.1953806 },
    { lat: 48.8215926, lng: 22.1957273 },
    { lat: 48.8214817, lng: 22.1956977 },
    { lat: 48.8214939, lng: 22.1954908 },
    { lat: 48.8212878, lng: 22.1952795 },
    { lat: 48.821214, lng: 22.1952962 },
    { lat: 48.8211237, lng: 22.1956386 },
    { lat: 48.8210118, lng: 22.1957126 },
    { lat: 48.8209055, lng: 22.195539 },
    { lat: 48.8208216, lng: 22.1960955 },
    { lat: 48.8203924, lng: 22.1965141 },
    { lat: 48.8203235, lng: 22.1967489 },
    { lat: 48.8198051, lng: 22.1968588 },
    { lat: 48.8196806, lng: 22.1971133 },
    { lat: 48.8194682, lng: 22.196872 },
    { lat: 48.8193083, lng: 22.1969749 },
    { lat: 48.8193022, lng: 22.1971523 },
    { lat: 48.8186732, lng: 22.1970819 },
    { lat: 48.8186824, lng: 22.1970004 },
    { lat: 48.8185833, lng: 22.1969956 },
    { lat: 48.8185794, lng: 22.1971129 },
    { lat: 48.8179113, lng: 22.1970659 },
    { lat: 48.8179063, lng: 22.1969998 },
    { lat: 48.8173407, lng: 22.1967552 },
    { lat: 48.8169071, lng: 22.1966853 },
    { lat: 48.816446, lng: 22.1967843 },
    { lat: 48.8164138, lng: 22.1968531 },
    { lat: 48.815865, lng: 22.1967004 },
    { lat: 48.8152853, lng: 22.1955988 },
    { lat: 48.8149734, lng: 22.1962793 },
    { lat: 48.8148121, lng: 22.1964508 },
    { lat: 48.8145955, lng: 22.1971145 },
    { lat: 48.8142666, lng: 22.197795 },
    { lat: 48.8139907, lng: 22.1976228 },
    { lat: 48.8140353, lng: 22.1974059 },
    { lat: 48.8139019, lng: 22.1973362 },
    { lat: 48.8137448, lng: 22.1970481 },
    { lat: 48.8136969, lng: 22.1971192 },
    { lat: 48.8136338, lng: 22.1969147 },
    { lat: 48.8135316, lng: 22.1969293 },
    { lat: 48.8134695, lng: 22.1967702 },
    { lat: 48.8133742, lng: 22.1968305 },
    { lat: 48.8133311, lng: 22.1967142 },
    { lat: 48.813222, lng: 22.1967164 },
    { lat: 48.8132427, lng: 22.1965911 },
    { lat: 48.8131682, lng: 22.1965264 },
    { lat: 48.8131848, lng: 22.1964164 },
    { lat: 48.8130905, lng: 22.1963256 },
    { lat: 48.8131145, lng: 22.1961467 },
    { lat: 48.8130383, lng: 22.1960611 },
    { lat: 48.8130174, lng: 22.1958762 },
    { lat: 48.81308, lng: 22.1958087 },
    { lat: 48.8129991, lng: 22.1956705 },
    { lat: 48.8130579, lng: 22.1955825 },
    { lat: 48.8129999, lng: 22.1954596 },
    { lat: 48.8131002, lng: 22.1953209 },
    { lat: 48.8130476, lng: 22.1948297 },
    { lat: 48.8129085, lng: 22.194747 },
    { lat: 48.8126291, lng: 22.1949275 },
    { lat: 48.8124515, lng: 22.194632 },
    { lat: 48.8123205, lng: 22.1949158 },
    { lat: 48.8122735, lng: 22.1947755 },
    { lat: 48.8121286, lng: 22.1948798 },
    { lat: 48.8122065, lng: 22.195188 },
    { lat: 48.8121117, lng: 22.1953214 },
    { lat: 48.811965, lng: 22.195304 },
    { lat: 48.8119332, lng: 22.1951387 },
    { lat: 48.8115755, lng: 22.1952904 },
    { lat: 48.8113975, lng: 22.1950781 },
    { lat: 48.8114282, lng: 22.1948945 },
    { lat: 48.8113649, lng: 22.1948782 },
    { lat: 48.8113063, lng: 22.1949772 },
    { lat: 48.8112527, lng: 22.1953865 },
    { lat: 48.8110115, lng: 22.1952163 },
    { lat: 48.8109826, lng: 22.1950872 },
    { lat: 48.8107891, lng: 22.1951273 },
    { lat: 48.8106367, lng: 22.1948626 },
    { lat: 48.8106299, lng: 22.1946563 },
    { lat: 48.8107004, lng: 22.1945949 },
    { lat: 48.810718, lng: 22.1944291 },
    { lat: 48.8106824, lng: 22.1943014 },
    { lat: 48.8103003, lng: 22.1946125 },
    { lat: 48.8101747, lng: 22.1942276 },
    { lat: 48.8100693, lng: 22.1942498 },
    { lat: 48.8100607, lng: 22.1940654 },
    { lat: 48.8099037, lng: 22.1939851 },
    { lat: 48.8098982, lng: 22.194187 },
    { lat: 48.8098154, lng: 22.1941617 },
    { lat: 48.8097422, lng: 22.1938579 },
    { lat: 48.8096476, lng: 22.1938004 },
    { lat: 48.8095898, lng: 22.1939833 },
    { lat: 48.8094054, lng: 22.1939408 },
    { lat: 48.8094067, lng: 22.1941952 },
    { lat: 48.8092311, lng: 22.194088 },
    { lat: 48.8092444, lng: 22.1943742 },
    { lat: 48.8090067, lng: 22.1943577 },
    { lat: 48.8088113, lng: 22.194121 },
    { lat: 48.8087497, lng: 22.1943322 },
    { lat: 48.8085324, lng: 22.1942219 },
    { lat: 48.8085288, lng: 22.1938224 },
    { lat: 48.8081769, lng: 22.1936252 },
    { lat: 48.808014, lng: 22.193739 },
    { lat: 48.8073403, lng: 22.1934365 },
    { lat: 48.8067142, lng: 22.1927166 },
    { lat: 48.8066021, lng: 22.1927307 },
    { lat: 48.806257, lng: 22.1924489 },
    { lat: 48.8059238, lng: 22.1922868 },
    { lat: 48.8050601, lng: 22.1921892 },
    { lat: 48.8045735, lng: 22.1924147 },
    { lat: 48.804286, lng: 22.1923338 },
    { lat: 48.8038089, lng: 22.1924229 },
    { lat: 48.803657, lng: 22.1922214 },
    { lat: 48.8032581, lng: 22.1922379 },
    { lat: 48.8033271, lng: 22.1914675 },
    { lat: 48.8032586, lng: 22.1908306 },
  ],
  Vojnatina: [
    { lat: 48.7273787, lng: 22.2480037 },
    { lat: 48.7276739, lng: 22.2477211 },
    { lat: 48.7279586, lng: 22.2474774 },
    { lat: 48.7281939, lng: 22.247386 },
    { lat: 48.7284707, lng: 22.2474421 },
    { lat: 48.7294717, lng: 22.2462284 },
    { lat: 48.7295572, lng: 22.2464268 },
    { lat: 48.7298875, lng: 22.2465664 },
    { lat: 48.7298266, lng: 22.2469111 },
    { lat: 48.7299279, lng: 22.2469637 },
    { lat: 48.7299033, lng: 22.2470765 },
    { lat: 48.7300548, lng: 22.2471589 },
    { lat: 48.7299639, lng: 22.2477218 },
    { lat: 48.7305298, lng: 22.2481853 },
    { lat: 48.7310515, lng: 22.2484758 },
    { lat: 48.7307612, lng: 22.2498855 },
    { lat: 48.7312554, lng: 22.2501564 },
    { lat: 48.7325866, lng: 22.2510859 },
    { lat: 48.7331624, lng: 22.2516348 },
    { lat: 48.7333157, lng: 22.2513552 },
    { lat: 48.7338551, lng: 22.2507259 },
    { lat: 48.7347155, lng: 22.2481924 },
    { lat: 48.7349706, lng: 22.2480889 },
    { lat: 48.735056, lng: 22.2479766 },
    { lat: 48.7351112, lng: 22.2482349 },
    { lat: 48.7350057, lng: 22.2487011 },
    { lat: 48.7350935, lng: 22.2488049 },
    { lat: 48.7350648, lng: 22.249028 },
    { lat: 48.735132, lng: 22.2491251 },
    { lat: 48.7351353, lng: 22.2493675 },
    { lat: 48.735208, lng: 22.2494999 },
    { lat: 48.735293, lng: 22.2495245 },
    { lat: 48.7354151, lng: 22.2499063 },
    { lat: 48.7354817, lng: 22.2498701 },
    { lat: 48.7355806, lng: 22.2500126 },
    { lat: 48.7356434, lng: 22.2500094 },
    { lat: 48.7358618, lng: 22.2504675 },
    { lat: 48.7359543, lng: 22.2505391 },
    { lat: 48.7359745, lng: 22.2507893 },
    { lat: 48.7362215, lng: 22.2512727 },
    { lat: 48.7362721, lng: 22.2516738 },
    { lat: 48.7365038, lng: 22.2522243 },
    { lat: 48.7365923, lng: 22.2523142 },
    { lat: 48.7367082, lng: 22.2523226 },
    { lat: 48.7367719, lng: 22.2523974 },
    { lat: 48.7367512, lng: 22.2525798 },
    { lat: 48.7370133, lng: 22.2527286 },
    { lat: 48.7371947, lng: 22.2530525 },
    { lat: 48.737479, lng: 22.2533406 },
    { lat: 48.7375505, lng: 22.2537236 },
    { lat: 48.7376872, lng: 22.2540541 },
    { lat: 48.7378675, lng: 22.2540228 },
    { lat: 48.7380195, lng: 22.2543269 },
    { lat: 48.7381313, lng: 22.2543533 },
    { lat: 48.7380993, lng: 22.2544828 },
    { lat: 48.7381623, lng: 22.2547789 },
    { lat: 48.7386341, lng: 22.2552139 },
    { lat: 48.7387533, lng: 22.255411 },
    { lat: 48.7388695, lng: 22.2554218 },
    { lat: 48.7391457, lng: 22.2556529 },
    { lat: 48.7399784, lng: 22.2570399 },
    { lat: 48.7404707, lng: 22.2574314 },
    { lat: 48.7405745, lng: 22.2572843 },
    { lat: 48.7411213, lng: 22.2578043 },
    { lat: 48.7418244, lng: 22.256141 },
    { lat: 48.7407185, lng: 22.2542526 },
    { lat: 48.7402045, lng: 22.2527414 },
    { lat: 48.74043, lng: 22.2521035 },
    { lat: 48.7402907, lng: 22.2518629 },
    { lat: 48.7402685, lng: 22.2513726 },
    { lat: 48.7403236, lng: 22.2511189 },
    { lat: 48.7408751, lng: 22.2515391 },
    { lat: 48.7409208, lng: 22.2514221 },
    { lat: 48.7412456, lng: 22.2518143 },
    { lat: 48.7413592, lng: 22.2520683 },
    { lat: 48.7417686, lng: 22.2526693 },
    { lat: 48.7421278, lng: 22.2514732 },
    { lat: 48.7422162, lng: 22.2507046 },
    { lat: 48.7424898, lng: 22.2508165 },
    { lat: 48.7426149, lng: 22.2506237 },
    { lat: 48.7426796, lng: 22.2506511 },
    { lat: 48.7427503, lng: 22.2496343 },
    { lat: 48.7435448, lng: 22.2496971 },
    { lat: 48.7439116, lng: 22.2503648 },
    { lat: 48.744036, lng: 22.2497843 },
    { lat: 48.7439483, lng: 22.2494869 },
    { lat: 48.7440148, lng: 22.2490851 },
    { lat: 48.7446925, lng: 22.2494704 },
    { lat: 48.7453574, lng: 22.249321 },
    { lat: 48.7495364, lng: 22.2457126 },
    { lat: 48.7492364, lng: 22.2433092 },
    { lat: 48.7491143, lng: 22.2417273 },
    { lat: 48.7492671, lng: 22.241363 },
    { lat: 48.7497017, lng: 22.2417407 },
    { lat: 48.7501237, lng: 22.2417983 },
    { lat: 48.7501238, lng: 22.2411913 },
    { lat: 48.7502753, lng: 22.2403773 },
    { lat: 48.7504618, lng: 22.2398127 },
    { lat: 48.7510372, lng: 22.2385821 },
    { lat: 48.7509275, lng: 22.2397682 },
    { lat: 48.7509505, lng: 22.2398373 },
    { lat: 48.7509077, lng: 22.2399902 },
    { lat: 48.7509475, lng: 22.2400623 },
    { lat: 48.7510614, lng: 22.2400219 },
    { lat: 48.7511775, lng: 22.2403332 },
    { lat: 48.751293, lng: 22.2402172 },
    { lat: 48.7511815, lng: 22.2405622 },
    { lat: 48.7512781, lng: 22.240605 },
    { lat: 48.7513306, lng: 22.2408444 },
    { lat: 48.7514011, lng: 22.2409181 },
    { lat: 48.7512753, lng: 22.2412396 },
    { lat: 48.7513943, lng: 22.2417211 },
    { lat: 48.7511861, lng: 22.2421203 },
    { lat: 48.7513494, lng: 22.2430872 },
    { lat: 48.7512778, lng: 22.2432074 },
    { lat: 48.7513522, lng: 22.2434673 },
    { lat: 48.7512882, lng: 22.24362 },
    { lat: 48.7513157, lng: 22.2437925 },
    { lat: 48.7512511, lng: 22.2439206 },
    { lat: 48.7512858, lng: 22.2442681 },
    { lat: 48.7514023, lng: 22.2443976 },
    { lat: 48.7513998, lng: 22.2444886 },
    { lat: 48.7516073, lng: 22.2449132 },
    { lat: 48.7516605, lng: 22.2449344 },
    { lat: 48.7516857, lng: 22.2453027 },
    { lat: 48.7517894, lng: 22.2455109 },
    { lat: 48.7517846, lng: 22.2458551 },
    { lat: 48.7518777, lng: 22.2458988 },
    { lat: 48.7518791, lng: 22.2460014 },
    { lat: 48.7519203, lng: 22.2459918 },
    { lat: 48.7529139, lng: 22.2455023 },
    { lat: 48.7533596, lng: 22.2453641 },
    { lat: 48.7533308, lng: 22.245183 },
    { lat: 48.7536546, lng: 22.2450752 },
    { lat: 48.7537981, lng: 22.2445015 },
    { lat: 48.7539824, lng: 22.2436387 },
    { lat: 48.7544121, lng: 22.2433858 },
    { lat: 48.7548814, lng: 22.2429961 },
    { lat: 48.7552232, lng: 22.2438419 },
    { lat: 48.7558406, lng: 22.2447415 },
    { lat: 48.7566131, lng: 22.2431243 },
    { lat: 48.757922, lng: 22.2440676 },
    { lat: 48.7584769, lng: 22.2445889 },
    { lat: 48.7588401, lng: 22.2450916 },
    { lat: 48.7597131, lng: 22.2444926 },
    { lat: 48.760268, lng: 22.2436076 },
    { lat: 48.7597235, lng: 22.2416875 },
    { lat: 48.7597548, lng: 22.2406186 },
    { lat: 48.7600269, lng: 22.2401173 },
    { lat: 48.7598218, lng: 22.2401489 },
    { lat: 48.7596717, lng: 22.2401719 },
    { lat: 48.7595855, lng: 22.2394373 },
    { lat: 48.7594075, lng: 22.2388785 },
    { lat: 48.7592775, lng: 22.2381451 },
    { lat: 48.7588802, lng: 22.2379815 },
    { lat: 48.7587022, lng: 22.237766 },
    { lat: 48.7586555, lng: 22.237587 },
    { lat: 48.7584591, lng: 22.2374559 },
    { lat: 48.7583408, lng: 22.2371015 },
    { lat: 48.7580847, lng: 22.2370095 },
    { lat: 48.7579073, lng: 22.2366767 },
    { lat: 48.7576895, lng: 22.2365916 },
    { lat: 48.7576138, lng: 22.2362739 },
    { lat: 48.757415, lng: 22.2361875 },
    { lat: 48.7572623, lng: 22.2363572 },
    { lat: 48.7570006, lng: 22.2362159 },
    { lat: 48.7569242, lng: 22.2359706 },
    { lat: 48.7565523, lng: 22.2360353 },
    { lat: 48.7563401, lng: 22.2358569 },
    { lat: 48.7558367, lng: 22.2359312 },
    { lat: 48.755557, lng: 22.2357466 },
    { lat: 48.7553202, lng: 22.2357149 },
    { lat: 48.7543829, lng: 22.2351919 },
    { lat: 48.7538318, lng: 22.2346032 },
    { lat: 48.7537163, lng: 22.2344141 },
    { lat: 48.7531385, lng: 22.234123 },
    { lat: 48.7524907, lng: 22.2335866 },
    { lat: 48.7521602, lng: 22.2334895 },
    { lat: 48.7520324, lng: 22.2333082 },
    { lat: 48.7518685, lng: 22.2334727 },
    { lat: 48.7517973, lng: 22.2333531 },
    { lat: 48.7516519, lng: 22.2332917 },
    { lat: 48.7514537, lng: 22.2333375 },
    { lat: 48.7513831, lng: 22.2334474 },
    { lat: 48.7511948, lng: 22.2332268 },
    { lat: 48.7511195, lng: 22.2334203 },
    { lat: 48.7509682, lng: 22.2333992 },
    { lat: 48.7509821, lng: 22.233566 },
    { lat: 48.7508181, lng: 22.233929 },
    { lat: 48.7503193, lng: 22.2338092 },
    { lat: 48.7502788, lng: 22.2337051 },
    { lat: 48.7501343, lng: 22.2336825 },
    { lat: 48.7498735, lng: 22.2334357 },
    { lat: 48.7497169, lng: 22.2331287 },
    { lat: 48.7496708, lng: 22.2327736 },
    { lat: 48.749388, lng: 22.2325992 },
    { lat: 48.7492687, lng: 22.2325971 },
    { lat: 48.7492268, lng: 22.2326823 },
    { lat: 48.7489756, lng: 22.2324236 },
    { lat: 48.7486774, lng: 22.2324114 },
    { lat: 48.7485426, lng: 22.2321703 },
    { lat: 48.7483458, lng: 22.2321683 },
    { lat: 48.7482463, lng: 22.2323487 },
    { lat: 48.7479744, lng: 22.2324661 },
    { lat: 48.7478276, lng: 22.2323439 },
    { lat: 48.7475167, lng: 22.2319617 },
    { lat: 48.747364, lng: 22.2316798 },
    { lat: 48.7475482, lng: 22.2314123 },
    { lat: 48.7474191, lng: 22.2312824 },
    { lat: 48.74734, lng: 22.2313046 },
    { lat: 48.7472481, lng: 22.2309694 },
    { lat: 48.747238, lng: 22.2305226 },
    { lat: 48.7468223, lng: 22.2300403 },
    { lat: 48.7467383, lng: 22.2301637 },
    { lat: 48.7466329, lng: 22.230131 },
    { lat: 48.7464744, lng: 22.2297278 },
    { lat: 48.7461699, lng: 22.2296437 },
    { lat: 48.7461594, lng: 22.2298787 },
    { lat: 48.746011, lng: 22.2299762 },
    { lat: 48.7459027, lng: 22.2298684 },
    { lat: 48.745511, lng: 22.2287559 },
    { lat: 48.7453296, lng: 22.2286886 },
    { lat: 48.7448232, lng: 22.228881 },
    { lat: 48.7446052, lng: 22.22859 },
    { lat: 48.744341, lng: 22.2285911 },
    { lat: 48.7439894, lng: 22.2280575 },
    { lat: 48.743927, lng: 22.2280379 },
    { lat: 48.7437728, lng: 22.2283848 },
    { lat: 48.7437056, lng: 22.2282471 },
    { lat: 48.7435694, lng: 22.2281436 },
    { lat: 48.743437, lng: 22.2282089 },
    { lat: 48.7432699, lng: 22.2285749 },
    { lat: 48.7431477, lng: 22.2286001 },
    { lat: 48.7430951, lng: 22.2284531 },
    { lat: 48.7431068, lng: 22.2280291 },
    { lat: 48.7429737, lng: 22.2279583 },
    { lat: 48.7428219, lng: 22.2284087 },
    { lat: 48.7426884, lng: 22.2282784 },
    { lat: 48.7426682, lng: 22.2277284 },
    { lat: 48.7424536, lng: 22.227438 },
    { lat: 48.7423483, lng: 22.2274619 },
    { lat: 48.742178, lng: 22.2273424 },
    { lat: 48.7422074, lng: 22.2270472 },
    { lat: 48.7420222, lng: 22.2267674 },
    { lat: 48.741707, lng: 22.2265534 },
    { lat: 48.741617, lng: 22.2263566 },
    { lat: 48.7413319, lng: 22.2260249 },
    { lat: 48.741317, lng: 22.2258653 },
    { lat: 48.7413983, lng: 22.2257788 },
    { lat: 48.7413411, lng: 22.2256898 },
    { lat: 48.7412385, lng: 22.2256744 },
    { lat: 48.7411425, lng: 22.2254659 },
    { lat: 48.7410618, lng: 22.2254198 },
    { lat: 48.7409421, lng: 22.2255535 },
    { lat: 48.7408481, lng: 22.2254732 },
    { lat: 48.7408487, lng: 22.2253531 },
    { lat: 48.7406345, lng: 22.2253228 },
    { lat: 48.7404774, lng: 22.2250985 },
    { lat: 48.7403845, lng: 22.2251197 },
    { lat: 48.7402494, lng: 22.2252631 },
    { lat: 48.7401391, lng: 22.2252542 },
    { lat: 48.7398884, lng: 22.2250572 },
    { lat: 48.7398154, lng: 22.2248222 },
    { lat: 48.7395984, lng: 22.2245711 },
    { lat: 48.7393986, lng: 22.2244912 },
    { lat: 48.7392436, lng: 22.2246014 },
    { lat: 48.7391847, lng: 22.224927 },
    { lat: 48.7390538, lng: 22.2249109 },
    { lat: 48.7385244, lng: 22.2242319 },
    { lat: 48.7381538, lng: 22.2240058 },
    { lat: 48.7378107, lng: 22.2233503 },
    { lat: 48.7372843, lng: 22.223056 },
    { lat: 48.7371402, lng: 22.2229063 },
    { lat: 48.7369249, lng: 22.2228265 },
    { lat: 48.7363278, lng: 22.2222386 },
    { lat: 48.7360565, lng: 22.2217766 },
    { lat: 48.7357966, lng: 22.2214753 },
    { lat: 48.736053, lng: 22.2210776 },
    { lat: 48.7359017, lng: 22.2208506 },
    { lat: 48.7353728, lng: 22.2203362 },
    { lat: 48.7354291, lng: 22.2201911 },
    { lat: 48.7353528, lng: 22.2198983 },
    { lat: 48.7352401, lng: 22.2197918 },
    { lat: 48.7351709, lng: 22.2198182 },
    { lat: 48.7350084, lng: 22.2199392 },
    { lat: 48.7349775, lng: 22.2201154 },
    { lat: 48.7348294, lng: 22.2202395 },
    { lat: 48.7347528, lng: 22.2198312 },
    { lat: 48.734834, lng: 22.2193884 },
    { lat: 48.734785, lng: 22.2192616 },
    { lat: 48.7346456, lng: 22.2191658 },
    { lat: 48.7340775, lng: 22.2195596 },
    { lat: 48.7339885, lng: 22.2195531 },
    { lat: 48.7339252, lng: 22.2196415 },
    { lat: 48.7337172, lng: 22.2196473 },
    { lat: 48.7334369, lng: 22.2195377 },
    { lat: 48.7330407, lng: 22.2190262 },
    { lat: 48.7325865, lng: 22.2182315 },
    { lat: 48.7324931, lng: 22.2180343 },
    { lat: 48.7325051, lng: 22.2178242 },
    { lat: 48.7324037, lng: 22.2174667 },
    { lat: 48.7321498, lng: 22.217328 },
    { lat: 48.732161, lng: 22.2169259 },
    { lat: 48.7320362, lng: 22.2168276 },
    { lat: 48.7318179, lng: 22.2169311 },
    { lat: 48.7316134, lng: 22.2167878 },
    { lat: 48.7316476, lng: 22.2163624 },
    { lat: 48.7315723, lng: 22.2163552 },
    { lat: 48.7314842, lng: 22.2164941 },
    { lat: 48.7313869, lng: 22.2164207 },
    { lat: 48.7311431, lng: 22.2167857 },
    { lat: 48.7309945, lng: 22.2167249 },
    { lat: 48.7309246, lng: 22.216524 },
    { lat: 48.7308355, lng: 22.2165108 },
    { lat: 48.7307974, lng: 22.21661 },
    { lat: 48.7302607, lng: 22.2161094 },
    { lat: 48.7301021, lng: 22.2161119 },
    { lat: 48.7294941, lng: 22.2157798 },
    { lat: 48.7276617, lng: 22.2152784 },
    { lat: 48.7275606, lng: 22.2152893 },
    { lat: 48.7275263, lng: 22.2154596 },
    { lat: 48.727328, lng: 22.2157474 },
    { lat: 48.727241, lng: 22.21578 },
    { lat: 48.7271608, lng: 22.2157126 },
    { lat: 48.7270108, lng: 22.2157923 },
    { lat: 48.7266841, lng: 22.215523 },
    { lat: 48.7266398, lng: 22.2153558 },
    { lat: 48.7263804, lng: 22.2152747 },
    { lat: 48.7261435, lng: 22.2154957 },
    { lat: 48.7258989, lng: 22.215265 },
    { lat: 48.7257601, lng: 22.2153524 },
    { lat: 48.7256586, lng: 22.2152422 },
    { lat: 48.7256748, lng: 22.2150084 },
    { lat: 48.7255825, lng: 22.2147096 },
    { lat: 48.7253875, lng: 22.2146349 },
    { lat: 48.7248864, lng: 22.2141128 },
    { lat: 48.7234103, lng: 22.2156917 },
    { lat: 48.723457, lng: 22.2157677 },
    { lat: 48.7233412, lng: 22.2159717 },
    { lat: 48.7233324, lng: 22.2161232 },
    { lat: 48.723386, lng: 22.21616 },
    { lat: 48.723417, lng: 22.2162885 },
    { lat: 48.7233809, lng: 22.2164339 },
    { lat: 48.7233071, lng: 22.2165082 },
    { lat: 48.7233636, lng: 22.2166068 },
    { lat: 48.7233334, lng: 22.2170124 },
    { lat: 48.7231716, lng: 22.217227 },
    { lat: 48.7229676, lng: 22.2172528 },
    { lat: 48.7228779, lng: 22.2173715 },
    { lat: 48.7230759, lng: 22.2178653 },
    { lat: 48.7221729, lng: 22.2189692 },
    { lat: 48.7220847, lng: 22.2193525 },
    { lat: 48.7220111, lng: 22.2191853 },
    { lat: 48.7217027, lng: 22.219473 },
    { lat: 48.7214368, lng: 22.2195946 },
    { lat: 48.7183472, lng: 22.2207541 },
    { lat: 48.7159379, lng: 22.2212569 },
    { lat: 48.7158878, lng: 22.2212317 },
    { lat: 48.7158759, lng: 22.2210496 },
    { lat: 48.7147368, lng: 22.2214163 },
    { lat: 48.7147243, lng: 22.22176 },
    { lat: 48.7139258, lng: 22.2252578 },
    { lat: 48.7137169, lng: 22.2260176 },
    { lat: 48.7135744, lng: 22.2268894 },
    { lat: 48.7132445, lng: 22.2283723 },
    { lat: 48.7131091, lng: 22.2298047 },
    { lat: 48.7131744, lng: 22.2298544 },
    { lat: 48.7132266, lng: 22.2305227 },
    { lat: 48.713428, lng: 22.2308294 },
    { lat: 48.7135294, lng: 22.2305895 },
    { lat: 48.7136501, lng: 22.2308533 },
    { lat: 48.7138108, lng: 22.2308425 },
    { lat: 48.7138752, lng: 22.230706 },
    { lat: 48.7141238, lng: 22.230757 },
    { lat: 48.7141075, lng: 22.2305887 },
    { lat: 48.7142304, lng: 22.2305902 },
    { lat: 48.7142816, lng: 22.2311721 },
    { lat: 48.7145716, lng: 22.2311116 },
    { lat: 48.7146006, lng: 22.2311557 },
    { lat: 48.7145619, lng: 22.2314871 },
    { lat: 48.7150261, lng: 22.2316212 },
    { lat: 48.7152234, lng: 22.2317353 },
    { lat: 48.7154417, lng: 22.2320005 },
    { lat: 48.7153422, lng: 22.2322688 },
    { lat: 48.7153778, lng: 22.2324077 },
    { lat: 48.7156896, lng: 22.2323653 },
    { lat: 48.715745, lng: 22.2322275 },
    { lat: 48.7162081, lng: 22.2323644 },
    { lat: 48.7163242, lng: 22.2322199 },
    { lat: 48.7163991, lng: 22.2324094 },
    { lat: 48.7165651, lng: 22.2324337 },
    { lat: 48.716632, lng: 22.2325033 },
    { lat: 48.7164148, lng: 22.2329087 },
    { lat: 48.7164224, lng: 22.2333425 },
    { lat: 48.7165653, lng: 22.2334919 },
    { lat: 48.7168552, lng: 22.2340794 },
    { lat: 48.7167917, lng: 22.2341592 },
    { lat: 48.7169215, lng: 22.2343766 },
    { lat: 48.7171179, lng: 22.2344606 },
    { lat: 48.717255, lng: 22.234709 },
    { lat: 48.7175576, lng: 22.2349072 },
    { lat: 48.7176612, lng: 22.2348627 },
    { lat: 48.7178625, lng: 22.235568 },
    { lat: 48.7181001, lng: 22.2361233 },
    { lat: 48.718268, lng: 22.2364972 },
    { lat: 48.7183512, lng: 22.2364841 },
    { lat: 48.7185136, lng: 22.236708 },
    { lat: 48.7186484, lng: 22.2372518 },
    { lat: 48.7188008, lng: 22.2371557 },
    { lat: 48.7196027, lng: 22.2369771 },
    { lat: 48.7214782, lng: 22.2360959 },
    { lat: 48.7216391, lng: 22.2358903 },
    { lat: 48.7218136, lng: 22.236347 },
    { lat: 48.7221025, lng: 22.2367981 },
    { lat: 48.7228099, lng: 22.2382912 },
    { lat: 48.7234704, lng: 22.2408206 },
    { lat: 48.7234344, lng: 22.2418776 },
    { lat: 48.7236483, lng: 22.2428834 },
    { lat: 48.7242479, lng: 22.2447605 },
    { lat: 48.724376, lng: 22.244796 },
    { lat: 48.7245757, lng: 22.2444737 },
    { lat: 48.7250643, lng: 22.2442945 },
    { lat: 48.7253, lng: 22.2444214 },
    { lat: 48.7260012, lng: 22.2442399 },
    { lat: 48.7263277, lng: 22.2454683 },
    { lat: 48.7265685, lng: 22.2460749 },
    { lat: 48.7267064, lng: 22.2461508 },
    { lat: 48.7271745, lng: 22.2475748 },
    { lat: 48.7273787, lng: 22.2480037 },
  ],
  Koromľa: [
    { lat: 48.6999674, lng: 22.2562709 },
    { lat: 48.700502, lng: 22.2573412 },
    { lat: 48.7014361, lng: 22.2594933 },
    { lat: 48.7020282, lng: 22.261498 },
    { lat: 48.7022451, lng: 22.2621423 },
    { lat: 48.7028502, lng: 22.2633517 },
    { lat: 48.703994, lng: 22.2651644 },
    { lat: 48.705213, lng: 22.2676933 },
    { lat: 48.7059554, lng: 22.2699808 },
    { lat: 48.7063672, lng: 22.2716127 },
    { lat: 48.7069818, lng: 22.2732597 },
    { lat: 48.7066607, lng: 22.2764814 },
    { lat: 48.7069064, lng: 22.2770783 },
    { lat: 48.7071087, lng: 22.2774762 },
    { lat: 48.7073587, lng: 22.2775911 },
    { lat: 48.7076141, lng: 22.2775013 },
    { lat: 48.7076218, lng: 22.2776922 },
    { lat: 48.7075049, lng: 22.2781624 },
    { lat: 48.706535, lng: 22.2807878 },
    { lat: 48.707383, lng: 22.2814524 },
    { lat: 48.7074924, lng: 22.2820573 },
    { lat: 48.7082521, lng: 22.282149 },
    { lat: 48.7085049, lng: 22.2822442 },
    { lat: 48.7085921, lng: 22.2826259 },
    { lat: 48.7089375, lng: 22.2835512 },
    { lat: 48.7090458, lng: 22.28392 },
    { lat: 48.7096862, lng: 22.2873156 },
    { lat: 48.7097396, lng: 22.2879554 },
    { lat: 48.7102676, lng: 22.289461 },
    { lat: 48.7096298, lng: 22.2899649 },
    { lat: 48.7094295, lng: 22.2902235 },
    { lat: 48.7097827, lng: 22.2908305 },
    { lat: 48.7099206, lng: 22.2911726 },
    { lat: 48.7107339, lng: 22.2919434 },
    { lat: 48.7109472, lng: 22.2924395 },
    { lat: 48.7109319, lng: 22.2927188 },
    { lat: 48.7108277, lng: 22.2928351 },
    { lat: 48.7111842, lng: 22.2934006 },
    { lat: 48.7116033, lng: 22.2939919 },
    { lat: 48.7117498, lng: 22.2940084 },
    { lat: 48.7122932, lng: 22.2945325 },
    { lat: 48.7126499, lng: 22.2949951 },
    { lat: 48.712711, lng: 22.2952702 },
    { lat: 48.7129147, lng: 22.2955275 },
    { lat: 48.713326, lng: 22.2968425 },
    { lat: 48.7134036, lng: 22.2969504 },
    { lat: 48.7134424, lng: 22.2973339 },
    { lat: 48.7135969, lng: 22.2978689 },
    { lat: 48.7137014, lng: 22.2985294 },
    { lat: 48.7140884, lng: 22.2995105 },
    { lat: 48.7139529, lng: 22.2996946 },
    { lat: 48.7141072, lng: 22.3002162 },
    { lat: 48.7142978, lng: 22.3004424 },
    { lat: 48.7146367, lng: 22.3001032 },
    { lat: 48.7148067, lng: 22.3004223 },
    { lat: 48.7155849, lng: 22.3005694 },
    { lat: 48.7157223, lng: 22.3008275 },
    { lat: 48.7159052, lng: 22.3013783 },
    { lat: 48.7161346, lng: 22.3016276 },
    { lat: 48.7164092, lng: 22.3015327 },
    { lat: 48.716504, lng: 22.3016536 },
    { lat: 48.7168869, lng: 22.3014664 },
    { lat: 48.7170023, lng: 22.3017075 },
    { lat: 48.7173658, lng: 22.302951 },
    { lat: 48.7176734, lng: 22.3034378 },
    { lat: 48.7177868, lng: 22.3033762 },
    { lat: 48.7181486, lng: 22.3041308 },
    { lat: 48.7181216, lng: 22.3043929 },
    { lat: 48.7183536, lng: 22.3047674 },
    { lat: 48.7183909, lng: 22.305438 },
    { lat: 48.7182924, lng: 22.3057713 },
    { lat: 48.7184874, lng: 22.3060484 },
    { lat: 48.7186153, lng: 22.3064958 },
    { lat: 48.7190058, lng: 22.3072842 },
    { lat: 48.7193187, lng: 22.3077014 },
    { lat: 48.7195757, lng: 22.3079336 },
    { lat: 48.7196163, lng: 22.3089172 },
    { lat: 48.7196603, lng: 22.3090347 },
    { lat: 48.7202089, lng: 22.3092785 },
    { lat: 48.7203674, lng: 22.3096827 },
    { lat: 48.7206458, lng: 22.3098722 },
    { lat: 48.721445, lng: 22.3107786 },
    { lat: 48.7214087, lng: 22.3114273 },
    { lat: 48.7215436, lng: 22.311895 },
    { lat: 48.7216485, lng: 22.3120066 },
    { lat: 48.7217262, lng: 22.3122728 },
    { lat: 48.7216293, lng: 22.3132451 },
    { lat: 48.7218197, lng: 22.3139801 },
    { lat: 48.7217837, lng: 22.3141899 },
    { lat: 48.7218244, lng: 22.3145128 },
    { lat: 48.7220393, lng: 22.3149766 },
    { lat: 48.7222152, lng: 22.3150555 },
    { lat: 48.7223793, lng: 22.3155656 },
    { lat: 48.7223968, lng: 22.3157507 },
    { lat: 48.7223484, lng: 22.3159031 },
    { lat: 48.7224056, lng: 22.3160498 },
    { lat: 48.7226905, lng: 22.3163168 },
    { lat: 48.7229486, lng: 22.3167638 },
    { lat: 48.7230955, lng: 22.3167875 },
    { lat: 48.7231447, lng: 22.3168268 },
    { lat: 48.7236385, lng: 22.317244 },
    { lat: 48.7238398, lng: 22.3177633 },
    { lat: 48.7241921, lng: 22.317826 },
    { lat: 48.7245123, lng: 22.318073 },
    { lat: 48.724702, lng: 22.3185673 },
    { lat: 48.7249196, lng: 22.3189071 },
    { lat: 48.7250538, lng: 22.3192289 },
    { lat: 48.7253165, lng: 22.3195699 },
    { lat: 48.7253757, lng: 22.3198128 },
    { lat: 48.7255723, lng: 22.3198583 },
    { lat: 48.7256749, lng: 22.3200213 },
    { lat: 48.7258894, lng: 22.3200581 },
    { lat: 48.7260267, lng: 22.3204204 },
    { lat: 48.7263628, lng: 22.320465 },
    { lat: 48.7265225, lng: 22.3208304 },
    { lat: 48.7270625, lng: 22.3211449 },
    { lat: 48.7271624, lng: 22.3212415 },
    { lat: 48.7272314, lng: 22.3214144 },
    { lat: 48.7277912, lng: 22.3217625 },
    { lat: 48.728098, lng: 22.3221627 },
    { lat: 48.7281392, lng: 22.3223018 },
    { lat: 48.7284597, lng: 22.3223971 },
    { lat: 48.7289206, lng: 22.3227497 },
    { lat: 48.7292505, lng: 22.3229211 },
    { lat: 48.729845, lng: 22.3238408 },
    { lat: 48.7299653, lng: 22.3243564 },
    { lat: 48.7299665, lng: 22.3248234 },
    { lat: 48.7300762, lng: 22.3250592 },
    { lat: 48.7302711, lng: 22.325232 },
    { lat: 48.7303015, lng: 22.3254934 },
    { lat: 48.7306213, lng: 22.326027 },
    { lat: 48.730645, lng: 22.3268135 },
    { lat: 48.7308654, lng: 22.3274378 },
    { lat: 48.7308837, lng: 22.3276065 },
    { lat: 48.7313751, lng: 22.3281195 },
    { lat: 48.7317818, lng: 22.328782 },
    { lat: 48.7317956, lng: 22.3291587 },
    { lat: 48.7319108, lng: 22.3292321 },
    { lat: 48.7319314, lng: 22.329608 },
    { lat: 48.7321379, lng: 22.3297938 },
    { lat: 48.7322696, lng: 22.3301596 },
    { lat: 48.7324193, lng: 22.3303268 },
    { lat: 48.7324869, lng: 22.3309035 },
    { lat: 48.73272, lng: 22.3312857 },
    { lat: 48.7327149, lng: 22.3315631 },
    { lat: 48.7329977, lng: 22.3320529 },
    { lat: 48.7330158, lng: 22.3324294 },
    { lat: 48.7332007, lng: 22.3330149 },
    { lat: 48.7331227, lng: 22.3336301 },
    { lat: 48.7333515, lng: 22.3340628 },
    { lat: 48.7333098, lng: 22.3343798 },
    { lat: 48.7333522, lng: 22.3346214 },
    { lat: 48.7334545, lng: 22.3348287 },
    { lat: 48.733465, lng: 22.3353705 },
    { lat: 48.7335472, lng: 22.3355286 },
    { lat: 48.7336785, lng: 22.3360355 },
    { lat: 48.7337894, lng: 22.3361736 },
    { lat: 48.7338283, lng: 22.3365106 },
    { lat: 48.7340626, lng: 22.3370459 },
    { lat: 48.7342647, lng: 22.3372936 },
    { lat: 48.7349299, lng: 22.3385674 },
    { lat: 48.7350674, lng: 22.3389547 },
    { lat: 48.7352013, lng: 22.3389574 },
    { lat: 48.7352292, lng: 22.3391994 },
    { lat: 48.7355062, lng: 22.3395894 },
    { lat: 48.735513, lng: 22.3399593 },
    { lat: 48.7356312, lng: 22.3401181 },
    { lat: 48.7358925, lng: 22.3408147 },
    { lat: 48.7361119, lng: 22.341131 },
    { lat: 48.7361738, lng: 22.3411279 },
    { lat: 48.7362661, lng: 22.3413956 },
    { lat: 48.7364989, lng: 22.3415488 },
    { lat: 48.7367317, lng: 22.3421245 },
    { lat: 48.7367423, lng: 22.3423556 },
    { lat: 48.7370199, lng: 22.3426078 },
    { lat: 48.7374943, lng: 22.3434931 },
    { lat: 48.7384795, lng: 22.3443708 },
    { lat: 48.739435, lng: 22.3445576 },
    { lat: 48.7397213, lng: 22.3444367 },
    { lat: 48.7406679, lng: 22.3453053 },
    { lat: 48.7413138, lng: 22.3464659 },
    { lat: 48.7420832, lng: 22.3484715 },
    { lat: 48.7429403, lng: 22.3496721 },
    { lat: 48.7435737, lng: 22.3496243 },
    { lat: 48.7441545, lng: 22.3489693 },
    { lat: 48.7464841, lng: 22.3478854 },
    { lat: 48.7478729, lng: 22.3462979 },
    { lat: 48.748632, lng: 22.3458768 },
    { lat: 48.7489829, lng: 22.3455011 },
    { lat: 48.749389, lng: 22.345178 },
    { lat: 48.749859, lng: 22.343348 },
    { lat: 48.750679, lng: 22.342667 },
    { lat: 48.7505221, lng: 22.3419655 },
    { lat: 48.7500426, lng: 22.3408514 },
    { lat: 48.7494001, lng: 22.3396901 },
    { lat: 48.7491341, lng: 22.3389312 },
    { lat: 48.7481544, lng: 22.3375727 },
    { lat: 48.747602, lng: 22.3370953 },
    { lat: 48.7470968, lng: 22.336355 },
    { lat: 48.7469351, lng: 22.3362147 },
    { lat: 48.746701, lng: 22.3357423 },
    { lat: 48.7464595, lng: 22.3349666 },
    { lat: 48.7463786, lng: 22.3345065 },
    { lat: 48.7463032, lng: 22.3334037 },
    { lat: 48.7460825, lng: 22.3328757 },
    { lat: 48.7460196, lng: 22.3326155 },
    { lat: 48.7458596, lng: 22.3314587 },
    { lat: 48.7458802, lng: 22.3312096 },
    { lat: 48.7461545, lng: 22.3303664 },
    { lat: 48.7461612, lng: 22.3300001 },
    { lat: 48.7460814, lng: 22.3296908 },
    { lat: 48.7460557, lng: 22.3288699 },
    { lat: 48.7457323, lng: 22.3280655 },
    { lat: 48.7456452, lng: 22.3279372 },
    { lat: 48.7452869, lng: 22.3277496 },
    { lat: 48.7446665, lng: 22.3265711 },
    { lat: 48.7446501, lng: 22.3258125 },
    { lat: 48.744597, lng: 22.325602 },
    { lat: 48.7442671, lng: 22.3252677 },
    { lat: 48.7441009, lng: 22.32486 },
    { lat: 48.7435126, lng: 22.324036 },
    { lat: 48.7434613, lng: 22.3236341 },
    { lat: 48.7433388, lng: 22.3232767 },
    { lat: 48.7430212, lng: 22.3229456 },
    { lat: 48.7429445, lng: 22.3223675 },
    { lat: 48.7428442, lng: 22.3221978 },
    { lat: 48.7424987, lng: 22.3210461 },
    { lat: 48.7424422, lng: 22.3210389 },
    { lat: 48.7425711, lng: 22.3206566 },
    { lat: 48.742519, lng: 22.3200138 },
    { lat: 48.7425876, lng: 22.3193412 },
    { lat: 48.742558, lng: 22.3179749 },
    { lat: 48.7423317, lng: 22.3169876 },
    { lat: 48.7422071, lng: 22.3166877 },
    { lat: 48.7421227, lng: 22.3161659 },
    { lat: 48.7417302, lng: 22.3155996 },
    { lat: 48.7413215, lng: 22.3147931 },
    { lat: 48.7408455, lng: 22.31432 },
    { lat: 48.7406281, lng: 22.3139481 },
    { lat: 48.7405042, lng: 22.3138352 },
    { lat: 48.7397464, lng: 22.3135928 },
    { lat: 48.7394011, lng: 22.3128649 },
    { lat: 48.7393272, lng: 22.3120059 },
    { lat: 48.7391148, lng: 22.3112336 },
    { lat: 48.7389914, lng: 22.3105245 },
    { lat: 48.7391612, lng: 22.3096965 },
    { lat: 48.7391901, lng: 22.309116 },
    { lat: 48.7394052, lng: 22.3083021 },
    { lat: 48.7396026, lng: 22.3068256 },
    { lat: 48.7394939, lng: 22.306025 },
    { lat: 48.739436, lng: 22.3058992 },
    { lat: 48.7392758, lng: 22.3049953 },
    { lat: 48.7393345, lng: 22.3047479 },
    { lat: 48.739433, lng: 22.3045713 },
    { lat: 48.739599, lng: 22.3045003 },
    { lat: 48.7391636, lng: 22.3031052 },
    { lat: 48.7385696, lng: 22.3018068 },
    { lat: 48.7377651, lng: 22.2994845 },
    { lat: 48.7377336, lng: 22.2995324 },
    { lat: 48.7374956, lng: 22.2991887 },
    { lat: 48.7372438, lng: 22.2991012 },
    { lat: 48.7370352, lng: 22.2988707 },
    { lat: 48.7370137, lng: 22.2986555 },
    { lat: 48.7369533, lng: 22.2985242 },
    { lat: 48.7367114, lng: 22.2982626 },
    { lat: 48.7363476, lng: 22.2980271 },
    { lat: 48.7361713, lng: 22.2977797 },
    { lat: 48.7359503, lng: 22.2976568 },
    { lat: 48.7356402, lng: 22.2977233 },
    { lat: 48.7355774, lng: 22.2975779 },
    { lat: 48.7354185, lng: 22.2976189 },
    { lat: 48.7352915, lng: 22.2974699 },
    { lat: 48.7348482, lng: 22.2972032 },
    { lat: 48.7345432, lng: 22.2974493 },
    { lat: 48.7345412, lng: 22.2975652 },
    { lat: 48.7343862, lng: 22.2973724 },
    { lat: 48.7329802, lng: 22.2969578 },
    { lat: 48.7325338, lng: 22.2967535 },
    { lat: 48.7323611, lng: 22.296672 },
    { lat: 48.7321901, lng: 22.2964707 },
    { lat: 48.7319654, lng: 22.2957174 },
    { lat: 48.7319381, lng: 22.2954932 },
    { lat: 48.7316274, lng: 22.2952191 },
    { lat: 48.7313979, lng: 22.2947606 },
    { lat: 48.7311537, lng: 22.2944921 },
    { lat: 48.731061, lng: 22.2942167 },
    { lat: 48.7310786, lng: 22.2939458 },
    { lat: 48.7310046, lng: 22.2936477 },
    { lat: 48.7311103, lng: 22.2934333 },
    { lat: 48.7310237, lng: 22.2933322 },
    { lat: 48.7310589, lng: 22.2931451 },
    { lat: 48.7307756, lng: 22.2930496 },
    { lat: 48.7306283, lng: 22.2925443 },
    { lat: 48.7303989, lng: 22.2923345 },
    { lat: 48.7298032, lng: 22.2911455 },
    { lat: 48.7293418, lng: 22.289781 },
    { lat: 48.7292748, lng: 22.2895514 },
    { lat: 48.7293322, lng: 22.2894509 },
    { lat: 48.7292561, lng: 22.2889983 },
    { lat: 48.7293292, lng: 22.2889469 },
    { lat: 48.729357, lng: 22.2887841 },
    { lat: 48.7293021, lng: 22.2884866 },
    { lat: 48.7291496, lng: 22.2883057 },
    { lat: 48.7291316, lng: 22.2881499 },
    { lat: 48.7289431, lng: 22.2879171 },
    { lat: 48.7287304, lng: 22.2870417 },
    { lat: 48.7286771, lng: 22.2870184 },
    { lat: 48.7284324, lng: 22.2865334 },
    { lat: 48.728172, lng: 22.2856369 },
    { lat: 48.7266652, lng: 22.2835566 },
    { lat: 48.7264234, lng: 22.2830465 },
    { lat: 48.7260573, lng: 22.282709 },
    { lat: 48.725767, lng: 22.2816317 },
    { lat: 48.7255411, lng: 22.2809883 },
    { lat: 48.7250412, lng: 22.2808606 },
    { lat: 48.7253607, lng: 22.2794869 },
    { lat: 48.7252388, lng: 22.2785433 },
    { lat: 48.7252308, lng: 22.2778832 },
    { lat: 48.725329, lng: 22.2776862 },
    { lat: 48.7253642, lng: 22.2769941 },
    { lat: 48.7255928, lng: 22.2759779 },
    { lat: 48.7253699, lng: 22.2754719 },
    { lat: 48.7251025, lng: 22.2750707 },
    { lat: 48.7248699, lng: 22.2743137 },
    { lat: 48.7248412, lng: 22.2738377 },
    { lat: 48.7251922, lng: 22.2733277 },
    { lat: 48.7247189, lng: 22.2730363 },
    { lat: 48.7248138, lng: 22.2726938 },
    { lat: 48.7247234, lng: 22.2726749 },
    { lat: 48.7247471, lng: 22.2724799 },
    { lat: 48.7245638, lng: 22.2723748 },
    { lat: 48.7245523, lng: 22.2720045 },
    { lat: 48.7244513, lng: 22.2719665 },
    { lat: 48.7244564, lng: 22.2712277 },
    { lat: 48.724263, lng: 22.2711909 },
    { lat: 48.7242928, lng: 22.2707583 },
    { lat: 48.7241833, lng: 22.2707325 },
    { lat: 48.7242666, lng: 22.2703289 },
    { lat: 48.7242306, lng: 22.2702787 },
    { lat: 48.7241102, lng: 22.2693148 },
    { lat: 48.7241402, lng: 22.2690938 },
    { lat: 48.7240657, lng: 22.2690256 },
    { lat: 48.7240011, lng: 22.2687507 },
    { lat: 48.7238624, lng: 22.2677755 },
    { lat: 48.7235745, lng: 22.2671108 },
    { lat: 48.7226075, lng: 22.2655061 },
    { lat: 48.7224068, lng: 22.2657378 },
    { lat: 48.7223566, lng: 22.2657578 },
    { lat: 48.7222438, lng: 22.2661458 },
    { lat: 48.7222649, lng: 22.2661846 },
    { lat: 48.7221886, lng: 22.2662904 },
    { lat: 48.7221772, lng: 22.266235 },
    { lat: 48.7219574, lng: 22.2662721 },
    { lat: 48.7219659, lng: 22.2663458 },
    { lat: 48.7218341, lng: 22.2664415 },
    { lat: 48.7217071, lng: 22.2666421 },
    { lat: 48.7209567, lng: 22.2653692 },
    { lat: 48.7207626, lng: 22.265085 },
    { lat: 48.7204162, lng: 22.2645801 },
    { lat: 48.7200141, lng: 22.2642161 },
    { lat: 48.7197891, lng: 22.2639394 },
    { lat: 48.7196233, lng: 22.2637711 },
    { lat: 48.7195581, lng: 22.2635226 },
    { lat: 48.7195397, lng: 22.2629935 },
    { lat: 48.7194872, lng: 22.2632718 },
    { lat: 48.7193596, lng: 22.2635009 },
    { lat: 48.7193775, lng: 22.2633917 },
    { lat: 48.7191078, lng: 22.2634531 },
    { lat: 48.7185666, lng: 22.2632918 },
    { lat: 48.7185028, lng: 22.2634147 },
    { lat: 48.7181698, lng: 22.2633529 },
    { lat: 48.7181355, lng: 22.2632798 },
    { lat: 48.7180514, lng: 22.2634953 },
    { lat: 48.7177028, lng: 22.2637527 },
    { lat: 48.7175696, lng: 22.2636768 },
    { lat: 48.7174168, lng: 22.2636845 },
    { lat: 48.7171246, lng: 22.2640049 },
    { lat: 48.7165602, lng: 22.2644122 },
    { lat: 48.715352, lng: 22.2656206 },
    { lat: 48.7144926, lng: 22.26547 },
    { lat: 48.7126874, lng: 22.2646643 },
    { lat: 48.712537, lng: 22.2645227 },
    { lat: 48.7124864, lng: 22.2643251 },
    { lat: 48.7123394, lng: 22.2641405 },
    { lat: 48.7117377, lng: 22.2639395 },
    { lat: 48.7107185, lng: 22.2642323 },
    { lat: 48.7103752, lng: 22.2644281 },
    { lat: 48.7098514, lng: 22.2643923 },
    { lat: 48.7092429, lng: 22.2646966 },
    { lat: 48.709166, lng: 22.264265 },
    { lat: 48.709302, lng: 22.2639076 },
    { lat: 48.7090549, lng: 22.2633186 },
    { lat: 48.7089168, lng: 22.2632765 },
    { lat: 48.7087029, lng: 22.2629629 },
    { lat: 48.7083383, lng: 22.2620497 },
    { lat: 48.7083337, lng: 22.2618416 },
    { lat: 48.7084146, lng: 22.261689 },
    { lat: 48.708222, lng: 22.2611657 },
    { lat: 48.7082951, lng: 22.260808 },
    { lat: 48.7082206, lng: 22.2606989 },
    { lat: 48.7079876, lng: 22.2606405 },
    { lat: 48.7077612, lng: 22.260116 },
    { lat: 48.7076674, lng: 22.2597447 },
    { lat: 48.7076826, lng: 22.2594644 },
    { lat: 48.70717, lng: 22.2584694 },
    { lat: 48.7066828, lng: 22.2582573 },
    { lat: 48.7059424, lng: 22.2572871 },
    { lat: 48.7060677, lng: 22.2567433 },
    { lat: 48.7059571, lng: 22.2565827 },
    { lat: 48.7058145, lng: 22.2566882 },
    { lat: 48.7054637, lng: 22.2565454 },
    { lat: 48.7045063, lng: 22.2563875 },
    { lat: 48.7042944, lng: 22.2562181 },
    { lat: 48.7042052, lng: 22.2558448 },
    { lat: 48.7043643, lng: 22.2535993 },
    { lat: 48.7042626, lng: 22.2527153 },
    { lat: 48.7042225, lng: 22.2526831 },
    { lat: 48.7042297, lng: 22.2524021 },
    { lat: 48.7039411, lng: 22.250907 },
    { lat: 48.7039327, lng: 22.250427 },
    { lat: 48.7040697, lng: 22.249669 },
    { lat: 48.7040321, lng: 22.2493857 },
    { lat: 48.704122, lng: 22.2477747 },
    { lat: 48.7041567, lng: 22.2474603 },
    { lat: 48.7042466, lng: 22.2472128 },
    { lat: 48.7041588, lng: 22.2469545 },
    { lat: 48.704119, lng: 22.246386 },
    { lat: 48.7040549, lng: 22.2460792 },
    { lat: 48.703728, lng: 22.2452394 },
    { lat: 48.7037547, lng: 22.2448275 },
    { lat: 48.7036849, lng: 22.2442189 },
    { lat: 48.7033268, lng: 22.2428943 },
    { lat: 48.7031879, lng: 22.2409122 },
    { lat: 48.7031035, lng: 22.2404055 },
    { lat: 48.7026947, lng: 22.2403285 },
    { lat: 48.7024895, lng: 22.2401634 },
    { lat: 48.7002489, lng: 22.24187 },
    { lat: 48.7000664, lng: 22.2415083 },
    { lat: 48.6999539, lng: 22.2415981 },
    { lat: 48.6994958, lng: 22.2406341 },
    { lat: 48.6987328, lng: 22.2414248 },
    { lat: 48.6982504, lng: 22.2414786 },
    { lat: 48.6967862, lng: 22.2414349 },
    { lat: 48.6955615, lng: 22.2421522 },
    { lat: 48.6954352, lng: 22.2441568 },
    { lat: 48.6955625, lng: 22.2445583 },
    { lat: 48.6954743, lng: 22.2449463 },
    { lat: 48.6956378, lng: 22.2460171 },
    { lat: 48.6959032, lng: 22.2464689 },
    { lat: 48.6959018, lng: 22.2466591 },
    { lat: 48.6957627, lng: 22.2473747 },
    { lat: 48.6957654, lng: 22.2480554 },
    { lat: 48.6960207, lng: 22.2496104 },
    { lat: 48.6964009, lng: 22.2501714 },
    { lat: 48.6968947, lng: 22.2519885 },
    { lat: 48.6971904, lng: 22.2525324 },
    { lat: 48.6975245, lng: 22.2529427 },
    { lat: 48.6980707, lng: 22.2544908 },
    { lat: 48.6984788, lng: 22.2549276 },
    { lat: 48.6992116, lng: 22.2553618 },
    { lat: 48.6991957, lng: 22.2553993 },
    { lat: 48.699996, lng: 22.2562406 },
    { lat: 48.6999674, lng: 22.2562709 },
  ],
};

export default UnitsSobrance;
