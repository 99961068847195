import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { categoryService } from "../../../services/categoryService";
import { useState } from "react";
import {
  faChevronLeft,
  faChevronRight,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import HouseCalc from "./Parts/HouseCalc";
import FlatCalc from "./Parts/FlatCalc";
import LandCalc from "./Parts/LandCalc";
import { averagePriceService } from "../../../services/averagePriceService";

export default function AvgPriceMenu({
  PMResult,
  setPMResult,
  setRegDisUni,
  regDisUni,
  setClickedReg,
  setZoom,
  setPriceMap,
  priceMap,
  setGenAvgPrice,
  map,
  genAvgPrice,
  setPick,
  pick,
  PMHistory,
  setPMHistory,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const [statuses, setStatuses] = useState([]);
  const [size, setSize] = useState({ min: false, mid: true, full: false });

  // console.log("PMHistory = ", PMHistory);
  // console.log("district/city = ", PMHistory?.district?.split(" ")[1], " || ", PMHistory?.city?.split(" ")[0]);
  // console.log("region/district = ", PMHistory?.region, " || ", PMHistory?.district?.split(" ")[0]);

  const close = () => {
    setPMResult({ ...PMResult, active: false });
  };

  const getAllStatuses = async () => {
    const response = await categoryService.getAllStatuses();
    const data = await response.json();
    setStatuses(data);
  };

  const minimize = () => {
    if (size.mid) {
      setSize({ ...size, min: true, mid: false, full: true });
    }
    if (size.full) {
      setSize({ ...size, min: true, mid: true, full: false });
    }
  };

  const changeSize = () => {
    if (size.mid) {
      setSize({ ...size, mid: false, full: true, min: false });
    } else {
      setSize({ ...size, mid: true, full: false, min: false });
    }
  };

  const getAvgPrice = async (key) => {
    const parent = key.replace("District", "").replace("Outer", "");
    const request = await averagePriceService.getGeneralAvgPrice(decodeURIComponent(parent));
    const response = await request.json();
    setGenAvgPrice(response);
  };

  const setCenterOfRegion = (key) => {
    if (key === "RegionKošice") {
      map.panTo({ lat: 48.705, lng: 20.758 });
    }
    if (key === "RegionBanskáBystrica") {
      map.panTo({ lat: 48.55, lng: 19.1462 });
    }
    if (key === "RegionNitra") {
      map.panTo({ lat: 48.3069, lng: 18.0843 });
    }
    if (key === "RegionPrešov") {
      map.panTo({ lat: 49.0803, lng: 20.558 });
    }
    if (key === "RegionTrenčín") {
      map.panTo({ lat: 48.8945, lng: 18.044 });
    }
    if (key === "RegionTrnava") {
      map.panTo({ lat: 48.375, lng: 17.5872 });
    }
    if (key === "RegionŽilina") {
      map.panTo({ lat: 49.2211, lng: 18.7495 });
    }
    if (key === "RegionBratislava") {
      map.panTo({ lat: 48.1486, lng: 17.1077 });
    }
  };

  const defaultColor = "#7551b0";
  const green1 = "#00A300";
  const green2 = "#2EFF2E";
  const yellow1 = "#D1D100";
  const yellow2 = "#FFFF2E";
  const orange1 = "#FFA500";
  // const orange2 = "#FFB52E"; scroll
  const red1 = "#FF0000";
  const red2 = "#D10000";

  const setColor = (key) => {
    // console.log("genAvgPrice = ", genAvgPrice);
    // console.log("color key = ", key);
    if (Object.keys(genAvgPrice).length > 0) {
      // console.log("key = ", key);
      // console.log("Object.keys length = ", Object.keys(genAvgPrice).length);
      if (genAvgPrice.hasOwnProperty(key)) {
        // console.log("has own property");
        if (genAvgPrice[key] >= 700 && genAvgPrice[key] <= 1000) {
          return green1;
        } else if (genAvgPrice[key] > 1000 && genAvgPrice[key] <= 1300) {
          return green2;
        } else if (genAvgPrice[key] > 1300 && genAvgPrice[key] <= 1600) {
          return yellow1;
        } else if (genAvgPrice[key] > 1600 && genAvgPrice[key] <= 1800) {
          return yellow2;
        } else if (genAvgPrice[key] > 1800 && genAvgPrice[key] <= 2000) {
          return orange1;
        } else if (genAvgPrice[key] > 2000 && genAvgPrice[key] <= 2100) {
          return red1;
        } else if (genAvgPrice[key] > 2100) {
          return red2;
        }
        // console.log("no property");
        return red2;
      } else return defaultColor;
    } else return defaultColor;
  };

  const setRegion = () => {
    setClickedReg(true);
    setPMResult({ ...PMResult, region: PMResult.region });
    setPriceMap({
      ...priceMap,
      district: true,
      districtName: "Region" + PMResult.region,
      region: false,
      city: false,
      cityName: "",
    });
    setZoom(9);
    getAvgPrice("Region" + PMResult.region);
    setCenterOfRegion("Region" + PMResult.region);
  };

  const setDistrict = () => {
    setPriceMap({
      ...priceMap,
      district: false,
      cityName: ("District" + PMResult?.parent).replace(/\s+/g, ""),
      city: true,
    });
    setPick({ ...pick, district: false });
    setPMResult({
      ...PMResult,
      parent: PMResult?.parent,
    });
    setZoom(10);
    getAvgPrice(("District" + PMResult?.parent).replace(/\s+/g, ""));
    setColor(
      ("District" + PMResult?.parent)
        .replace(/\s+/g, "")
        .replace("Ext", "")
        .replace("1", "")
        .replace("Košicemestskáčasť", ""),
    );
    setCenterOfDistrict(("District" + PMResult?.parent).replace(/\s+/g, ""));
  };

  const setCenterOfDistrict = (key) => {
    // console.log("key = ", key);
    if (key === "DistrictMichalovce") {
      map.panTo({ lat: 48.7543, lng: 21.9195 });
    }
    if (key === "DistrictSobrance") {
      map.panTo({ lat: 48.7464, lng: 21.9666 });
    }
    if (key === "DistrictTrebišov") {
      map.panTo({ lat: 48.6284, lng: 21.721 });
    }
    if (key === "DistrictKošiceokolieOuter") {
      map.panTo({ lat: 48.748, lng: 21.0065 });
    }
    if (key === "DistrictKošiceI") {
      map.panTo({ lat: 48.7144, lng: 21.2618 });
    }
    if (key === "DistrictKošiceII") {
      map.panTo({ lat: 48.7282, lng: 21.2734 });
    }
    if (key === "DistrictKošiceIII") {
      map.panTo({ lat: 48.7174, lng: 21.2245 });
    }
    if (key === "DistrictKošiceIV") {
      map.panTo({ lat: 48.6936, lng: 21.2072 });
    }
    if (key === "DistrictGelnica") {
      map.panTo({ lat: 48.7728, lng: 20.4858 });
    }
    if (key === "DistrictRožňava") {
      map.panTo({ lat: 48.7355, lng: 20.5301 });
    }
    if (key === "DistrictSpišskáNováVes") {
      map.panTo({ lat: 49.004, lng: 20.5734 });
    }
    if (key === "DistrictBardejov") {
      map.panTo({ lat: 49.2916, lng: 21.2763 });
    }
    if (key === "DistrictHumenné") {
      map.panTo({ lat: 48.9374, lng: 21.9065 });
    }
    if (key === "DistrictKežmarok") {
      map.panTo({ lat: 49.2352, lng: 20.4295 });
    }
    if (key === "DistrictLevoča") {
      map.panTo({ lat: 49.0208, lng: 20.5881 });
    }
    if (key === "DistrictMedzilaborce") {
      map.panTo({ lat: 49.2718, lng: 21.905 });
    }
    if (key === "DistrictPoprad") {
      map.panTo({ lat: 49.1594, lng: 20.0975 });
    }
    if (key === "DistrictPrešov") {
      map.panTo({ lat: 49.0011, lng: 21.2396 });
    }
    if (key === "DistrictSabinov") {
      map.panTo({ lat: 49.1045, lng: 20.8986 });
    }
    if (key === "DistrictSnina") {
      map.panTo({ lat: 48.9884, lng: 22.1498 });
    }
    if (key === "DistrictStaráĽubovňa") {
      map.panTo({ lat: 49.2985, lng: 20.6881 });
    }
    if (key === "DistrictStropkov") {
      map.panTo({ lat: 49.2619, lng: 21.4052 });
    }
    if (key === "DistrictSvidník") {
      map.panTo({ lat: 49.232, lng: 21.453 });
    }
    if (key === "DistrictVranovnadTopľou") {
      map.panTo({ lat: 48.9302, lng: 21.3484 });
    }
    if (key === "DistrictBanskáBystrica") {
      map.panTo({ lat: 48.738, lng: 19.1457 });
    }
    if (key === "DistrictBanskáŠtiavnica") {
      map.panTo({ lat: 48.4558, lng: 18.8962 });
    }
    if (key === "DistrictBrezno") {
      map.panTo({ lat: 48.7665, lng: 19.6334 });
    }
    if (key === "DistrictDetva") {
      map.panTo({ lat: 48.5602, lng: 19.4186 });
    }
    if (key === "DistrictKrupina") {
      map.panTo({ lat: 48.3192, lng: 19.0874 });
    }
    if (key === "DistrictLučenec") {
      map.panTo({ lat: 48.3264, lng: 19.6312 });
    }
    if (key === "DistrictPoltár") {
      map.panTo({ lat: 48.4171, lng: 19.4815 });
    }
    if (key === "DistrictRevúca") {
      map.panTo({ lat: 48.6843, lng: 20.1189 });
    }
    if (key === "DistrictRimavskáSobota") {
      map.panTo({ lat: 48.455, lng: 19.8807 });
    }
    if (key === "DistrictVeľkýKrtíš") {
      map.panTo({ lat: 48.3071, lng: 19.2623 });
    }
    if (key === "DistrictŽarnovica") {
      map.panTo({ lat: 48.3831, lng: 18.6637 });
    }
    if (key === "DistrictŽiarnadHronom") {
      map.panTo({ lat: 48.5908, lng: 18.853 });
    }
    if (key === "DistrictZvolen") {
      map.panTo({ lat: 48.582, lng: 19.1262 });
    }
    if (key === "DistrictBytča") {
      map.panTo({ lat: 49.232, lng: 18.4104 });
    }
    if (key === "DistrictČadca") {
      map.panTo({ lat: 49.34, lng: 18.5741 });
    }
    if (key === "DistrictDolnýKubín") {
      map.panTo({ lat: 49.2184, lng: 19.3031 });
    }
    if (key === "DistrictKysuckéNovéMesto") {
      map.panTo({ lat: 49.2987, lng: 18.8055 });
    }
    if (key === "DistrictLiptovskýMikuláš") {
      map.panTo({ lat: 49.0853, lng: 19.6015 });
    }
    if (key === "DistrictMartin") {
      map.panTo({ lat: 49.0654, lng: 18.913 });
    }
    if (key === "DistrictNámestovo") {
      map.panTo({ lat: 49.4172, lng: 19.393 });
    }
    if (key === "DistrictRužomberok") {
      map.panTo({ lat: 49.0894, lng: 19.3052 });
    }
    if (key === "DistrictTurčianskeTeplice") {
      map.panTo({ lat: 48.9033, lng: 18.8614 });
    }
    if (key === "DistrictTvrdošín") {
      map.panTo({ lat: 49.2616, lng: 19.5665 });
    }
    if (key === "DistrictŽilina") {
      map.panTo({ lat: 49.2215, lng: 18.7407 });
    }
    if (key === "DistrictBánovcenadBebravou") {
      map.panTo({ lat: 48.7633, lng: 18.2708 });
    }
    if (key === "DistrictIlava") {
      map.panTo({ lat: 48.8939, lng: 18.1856 });
    }
    if (key === "DistrictMyjava") {
      map.panTo({ lat: 48.7031, lng: 17.5736 });
    }
    if (key === "DistrictNovéMestonadVáhom") {
      map.panTo({ lat: 48.763, lng: 17.8266 });
    }
    if (key === "DistrictPartizánske") {
      map.panTo({ lat: 48.5953, lng: 18.3206 });
    }
    if (key === "DistrictPovažskáBystrica") {
      map.panTo({ lat: 49.1295, lng: 18.4256 });
    }
    if (key === "DistrictPrievidza") {
      map.panTo({ lat: 48.7642, lng: 18.6301 });
    }
    if (key === "DistrictPúchov") {
      map.panTo({ lat: 49.1431, lng: 18.2345 });
    }
    if (key === "DistrictTrenčín") {
      map.panTo({ lat: 48.8971, lng: 18.0443 });
    }
    if (key === "DistrictKomárno") {
      map.panTo({ lat: 47.7666, lng: 18.0216 });
    }
    if (key === "DistrictLevice") {
      map.panTo({ lat: 48.1673, lng: 18.6211 });
    }
    if (key === "DistrictNitra") {
      map.panTo({ lat: 48.3084, lng: 18.084 });
    }
    if (key === "DistrictNovéZámky") {
      map.panTo({ lat: 47.9816, lng: 18.1691 });
    }
    if (key === "DistrictŠaľa") {
      map.panTo({ lat: 48.1347, lng: 17.9745 });
    }
    if (key === "DistrictTopoľčany") {
      map.panTo({ lat: 48.5706, lng: 18.1882 });
    }
    if (key === "DistrictZlatéMoravce") {
      map.panTo({ lat: 48.3534, lng: 18.3706 });
    }
    if (key === "DistrictDunajskáStreda") {
      map.panTo({ lat: 47.9893, lng: 17.463 });
    }
    if (key === "DistrictGalanta") {
      map.panTo({ lat: 48.2081, lng: 17.7671 });
    }
    if (key === "DistrictHlohovec") {
      map.panTo({ lat: 48.4391, lng: 17.8304 });
    }
    if (key === "DistrictPiešťany") {
      map.panTo({ lat: 48.5863, lng: 17.821 });
    }
    if (key === "DistrictSenica") {
      map.panTo({ lat: 48.6628, lng: 17.166 });
    }
    if (key === "DistrictSkalica") {
      map.panTo({ lat: 48.8314, lng: 17.1892 });
    }
    if (key === "DistrictTrnava") {
      map.panTo({ lat: 48.4765, lng: 17.5875 });
    }
    if (key === "DistrictBratislavaI") {
      map.panTo({ lat: 48.1482, lng: 17.1067 });
    }
    if (key === "DistrictBratislavaII") {
      map.panTo({ lat: 48.1652, lng: 17.1315 });
    }
    if (key === "DistrictBratislavaIII") {
      map.panTo({ lat: 48.1883, lng: 17.1764 });
    }
    if (key === "DistrictBratislavaIV") {
      map.panTo({ lat: 48.2074, lng: 17.1502 });
    }
    if (key === "DistrictBratislavaV") {
      map.panTo({ lat: 48.1603, lng: 17.2248 });
    }
    if (key === "DistrictMalacky") {
      map.panTo({ lat: 48.4417, lng: 17.0145 });
    }
    if (key === "DistrictPezinok") {
      map.panTo({ lat: 48.2541, lng: 17.27 });
    }
    if (key === "DistrictSenec") {
      map.panTo({ lat: 48.2137, lng: 17.4008 });
    }
  };

  const goForward = () => {
    if (priceMap.district) {
      setPriceMap({
        ...priceMap,
        district: false,
        cityName: "District" + PMHistory?.district?.split(" ")[1],
        city: true,
      });
      setPMResult({
        ...PMResult,
        parent: PMHistory?.district?.split(" ")[1].replace("Ľubovňa", " Ľubovňa").replace("Štiavnica", " Štiavnica"),
        data: { ...PMResult.data, obec: PMHistory?.city?.split(" ")[1] },
      });
      setZoom(10);
      getAvgPrice("District" + PMHistory?.district?.split(" ")[1]);
      // console.log("district = ", ("District" + PMResult?.parent).replace(/\s+/g, ""));
      // console.log("district = ", ("District" + PMHistory?.district?.split(" ")[1]).replace(/\s+/g, ""));
      setCenterOfDistrict(("District" + PMHistory?.district?.split(" ")[1]).replace(/\s+/g, ""));
    }
    if (priceMap.region) {
      setPriceMap({
        ...priceMap,
        district: true,
        districtName: "Region" + PMHistory?.region,
        region: false,
        city: false,
        cityName: "",
      });
      setZoom(9);
      setPMResult({ ...PMResult, region: PMHistory?.region });
      getAvgPrice("Region" + PMHistory?.region);
      setCenterOfRegion("Region" + PMHistory?.region);
    }
  };

  const goBack = () => {
    // console.log("parent = ", PMResult?.parent);
    if (PMResult?.region !== "") {
      if (PMResult?.parent) {
        setZoom(9);
        setPriceMap({ ...priceMap, district: true, cityName: "", city: false });
        getAvgPrice(priceMap.districtName);
        setPMResult({ ...PMResult, parent: null, data: { ...PMResult.data, obec: "" } });
      } else {
        setPriceMap({
          ...priceMap,
          region: true,
          district: false,
          city: false,
          districtName: "",
          cityName: "",
        });
        getAvgPrice("");
        setPMResult({ ...PMResult, region: "" });
        setZoom(8);
        map.panTo({ lat: 48.6667, lng: 18.5 });
      }
    }
  };

  const titleOfBtn = () => {
    if (PMResult?.parent) return "Prejsť na obce";
    if (PMResult?.region !== "") return "Prejsť na okresy";
  };

  return (
    <AnimatePresence>
      {PMResult.active ? (
        <motion.div
          className={clsx(
            "transition-all duration-200 ease-linear overflow-auto absolute h-auto bg-sky-100 flex flex-col items-center no-scrollbar select-none",
            {
              "!h-[calc(100%-64px)] !rounded-none": size.full && !size.min && isSmallDevice,
              "!h-[45%]": size.mid && !size.min && isSmallDevice,
              "!h-[10%]": size.min && isSmallDevice,
              "bottom-0 left-0 w-full pb-5 rounded-t-xl  no-scrollbar": isSmallDevice,
              "top-0 left-16 h-full": !isSmallDevice,
              "w-96": !isSmallDevice,
              "w-[calc(100%-64px)]": !isSmallDevice && size.full,
            },
          )}
          initial={{ opacity: 0, scale: 1, y: isSmallDevice ? 300 : 0, x: !isSmallDevice ? -500 : 0 }}
          animate={{ opacity: 1, scale: 1, y: isSmallDevice ? 0 : 0, x: !isSmallDevice ? 0 : 0 }}
          exit={{ opacity: 1, scale: 1, y: isSmallDevice ? 350 : 0, x: !isSmallDevice ? -500 : 0 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div
            onClick={() => {
              // indexCur.current = null;
            }}
            className={clsx(
              "sticky top-0 right-0 rounded-t-lg w-full h-8 bg-sky-100 border-b border-opacity-50 border-b-sky-500 z-10 grid ",
              {
                "grid-cols-[10%_10%_50%_10%_10%_10%]": isSmallDevice,
                "grid-cols-[10%_10%_60%_10%_10%]": !isSmallDevice,
              },
            )}
          >
            <button
              className="w-full h-full disabled:text-gray-300"
              onClick={() => goBack()}
              disabled={PMResult?.region === "" && PMResult?.parent === null}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="w-5 h-4" />
            </button>
            <button
              className="w-full h-full border-x border-opacity-50 border-x-sky-500 disabled:text-gray-300"
              onClick={() => goForward()}
              disabled={
                PMHistory?.district.split(" ")[1] !== PMHistory?.city.split(" ")[0] ||
                PMHistory.region !== PMHistory?.district.split(" ")[0] ||
                priceMap.city
              }
            >
              <FontAwesomeIcon icon={faChevronRight} className="w-5 h-4" />
            </button>
            <div className={clsx("w-full h-full text-start pt-[2px] pl-1 overflow-hidden truncate ", {})}></div>
            {isSmallDevice ? (
              <button onClick={() => minimize()} className="w-full h-full border-l border-opacity-50 border-l-sky-500">
                <FontAwesomeIcon icon={faWindowMinimize} />
              </button>
            ) : null}
            <button onClick={() => changeSize()} className="w-full h-full border-x border-opacity-50 border-x-sky-500">
              {size.full ? <FontAwesomeIcon icon={faWindowRestore} /> : null}
              {size.mid ? <FontAwesomeIcon icon={faWindowMaximize} /> : null}
            </button>
            <button onClick={() => close()} className="w-full h-full flex justify-center items-center">
              <FontAwesomeIcon icon={faX} />
            </button>
          </div>
          <div className={clsx("w-full h-auto flex flex-col gap-4 p-2", {})}>
            <div className="flex flex-col justify-center items-center font-semibold">
              <div className="w-full flex justify-center">
                {/* <div className="w-[43%]">Aktuálna trhová cena</div>
              <div className="w-[57%] !line-clamp-1">{PMResult?.data?.obec}</div> */}
                Aktuálna trhová cena {PMResult?.data?.obec?.includes("Region") ? "" : PMResult?.data?.obec}
              </div>
              <div className="w-full flex justify-center">
                {PMResult?.parent
                  ? "okres " +
                    PMResult?.parent
                      .replace("nad", " nad")
                      .replace("pod", " pod")
                      .replace("pri", " pri")
                      .replace("okolie", " okolie")
                      .replace("Štiavnica", " Štiavnica")
                      .replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ")
                      .replace("Ext", "")
                      .replace("1", "") +
                    ","
                  : // "okres " + PMResult?.data?.parent + ", "}
                    null}
                kraj {PMResult.region.replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ")}
              </div>
              <div className="w-full flex felx-row pt-1">
                <button
                  onClick={() => setPMResult({ ...PMResult, dom: true, byt: false, pozemok: false })}
                  className={clsx("w-1/3 border-b-2 border-gray-300", { "border-sky-500": PMResult.dom })}
                >
                  Dom
                </button>
                <button
                  onClick={() => setPMResult({ ...PMResult, dom: false, byt: true, pozemok: false })}
                  className={clsx("w-1/3 border-b-2 border-gray-300", { "border-sky-500": PMResult.byt })}
                >
                  Byt
                </button>
                <button
                  onClick={() => setPMResult({ ...PMResult, dom: false, byt: false, pozemok: true })}
                  className={clsx("w-1/3 border-b-2 border-gray-300", { "border-sky-500": PMResult.pozemok })}
                >
                  Pozemok
                </button>
              </div>
            </div>
            <HouseCalc
              PMResult={PMResult}
              statuses={statuses}
              getAllStatuses={getAllStatuses}
              setPMResult={setPMResult}
              regDisUni={regDisUni}
            />
            <FlatCalc
              PMResult={PMResult}
              statuses={statuses}
              getAllStatuses={getAllStatuses}
              setPMResult={setPMResult}
              regDisUni={regDisUni}
            />
            <LandCalc PMResult={PMResult} setPMResult={setPMResult} regDisUni={regDisUni} />
            {PMResult.region !== "" ? (
              <div className="flex flex-row justify-between">
                <div className="flex justify-center -mt-2">
                  <button className="bg-sky-200 w-36 p-2 rounded-xl" onClick={() => goBack()}>
                    Späť
                  </button>
                </div>
                <div className="flex justify-center -mt-2">
                  <button
                    className="bg-sky-200 w-36 p-2 rounded-xl disabled:bg-gray-200"
                    disabled={(PMResult?.parent === null && priceMap?.district) || priceMap?.city}
                    onClick={() => {
                      if (PMResult?.parent) {
                        setDistrict();
                      } else {
                        setRegion();
                      }
                    }}
                  >
                    {/* {PMResult?.parent ? "Prejsť na okres" : "Prejsť na kraj"} */}
                    {titleOfBtn()}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
