import clsx from "clsx";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import NaPredaj from "../../Images/naPredaj.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useMediaQuery } from "usehooks-ts";
// import { motion, AnimatePresence } from "framer-motion";
import GallerySmall from "../NewMenu.jsx/GallerySmall";
import Share from "../NewMenu.jsx/Share";
import Rating from "../NewMenu.jsx/Rating";
import Header from "./ForSaleMenu/Header";
import ScrollMenu from "./ForSaleMenu/ScrollMenu";
import Overview from "./ForSaleMenu/Overview";
import Lv from "./LvParts/Lv";
import Gallery from "./Gallery";
import Interest from "../NewMenu.jsx/Interest";
import Filter from "./Filters/Filter";
import Load from "../constants/Load";

export default function ForSale({
  forSaleItems,
  setVisibilityOfMarkers,
  setCurrentItem,
  map,
  gallery,
  openLeftBar,
  visibilityOfMarkers,
  setExpanded,
  isExpanded,
  subMenu,
  setSubMenu,
  inputValues,
  setInputValues,
  setRating,
  rating,
  setForSale,
  forSale,
  lvSubMenu,
  setLvSubMenu,
  setGallery,
  idx,
  indx,
  size,
  clicked,
  setHighlight,
  highlight,
  filterSale,
  setFilterSale,
  filters,
  setFilters,
  shareLoad,
  setShareLoad,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const [previous, setPrevious] = useState("");
  const [isMoreOpt, setMoreOpt] = useState(false);
  if (visibilityOfMarkers !== "singleForSalePin") {
    clicked.current = -1;
  }

  const filLoc = (item) => {
    return item.city === filters.location;
  };

  const filPrice = (item) => {
    return item.price >= filters.minPrice && item.price <= filters.maxPrice;
  };

  const filArea = (item) => {
    return (
      Number(JSON.parse(item?.parameters).area) >= filters.minArea &&
      Number(JSON.parse(item?.parameters).area) <= filters.maxArea
    );
  };

  const filType = (item) => {
    return JSON.parse(item?.parameters).type.toLowerCase().includes(filters.type.toLowerCase());
  };

  const filterForSale = (prop) => {
    if (prop === 1) {
      setFilterSale([]);
      setFilters({ ...filters, location: "" });
      return;
    }
    const filteredArray = {
      ...forSaleItems,
      items: forSaleItems.items.filter((item) => {
        const locFilter = filters.location !== "" ? filLoc(item) : item.city;
        const priceFilter = filters.maxPrice !== "" && filters.minPrice !== "" ? filPrice(item) : item.price;
        const areaFilter =
          filters.maxArea !== "" && filters.minArea !== "" ? filArea(item) : JSON.parse(item.parameters).area;
        const typeFilter = filters.type !== "" ? filType(item) : JSON.parse(item?.parameters).type;
        return [locFilter, priceFilter, areaFilter, typeFilter].every((filter) => filter);
      }),
    };
    setFilterSale(filteredArray);
    return filteredArray;
  };

  const obj = () => {
    if (filterSale.length === 0) {
      return forSaleItems?.items;
    } else {
      return filterSale?.items;
    }
  };

  const showMenu = (menu) => {
    if (subMenu.lv) setPrevious("lv");
    if (subMenu.interest) setPrevious("in");
    if (subMenu.owner) setPrevious("ow");
    if (subMenu.rating) setPrevious("ra");
    if (subMenu.share) setPrevious("sh");

    if (menu === "lv") {
      setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, rating: false, share: false });
    }
    if (menu === "in") {
      setSubMenu({ ...subMenu, lv: false, interest: true, owner: false, rating: false, share: false });
    }
    if (menu === "ow") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
    }
    if (menu === "ra") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: true, share: false });
    }
    if (menu === "sh") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: false, share: true });
    }
  };

  const highlightPin = (index) => {
    if (index !== highlight.indexFS)
      setHighlight({
        ...highlight,
        forSale: true,
        history: false,
        reo: false,
        finIn: false,
        indexFS: index,
      });
    map.panTo({
      // lat: Number(forSaleItems?.items[index]?.lat),
      // lng: Number(forSaleItems?.items[index]?.lng),
      lat: Number(obj()[index]?.lat),
      lng: Number(obj()[index]?.lng),
    });
  };

  return (
    <div
      className={clsx(
        "transition-all duration-500 ease-linear min-h-max flex flex-col justify-center overflow-ellipsis w-full px-2 gap-4 select-none",
        {
          "h-44 p-3 sm:w-96 ": openLeftBar.forSale && !isSmallDevice,
          "p-2 no-scrollbar": isSmallDevice,
          "!overflow-hidden !top-0": gallery,
        },
      )}
    >
      {forSale?.menu ? ( //&& isSmallDevice
        <div className="w-full h-full">
          <Header forSaleItems={obj} indx={indx} />
          <GallerySmall
            // imgs={forSaleItems?.items[indx.current]?.img}
            imgs={obj()[indx.current]?.img}
            previous={previous}
            setGallery={setGallery}
            gallery={gallery}
            size={size}
          />
          <ScrollMenu showMenu={showMenu} subMenu={subMenu} size={size} />
          <Share subMenu={subMenu} size={size} items={obj()[indx.current]} active={"forSale"} />
          <Overview previous={previous} forSaleItems={obj()[indx.current]} subMenu={subMenu} />
          <Rating
            subMenu={subMenu}
            rating={rating}
            setRating={setRating}
            inputValues={inputValues}
            setInputValues={setInputValues}
            previous={previous}
            size={size}
          />
          <Lv
            previous={previous}
            subMenu={subMenu}
            forSale={obj()[indx.current]}
            lvSubMenu={lvSubMenu}
            setLvSubMenu={setLvSubMenu}
          />
          <Interest
            subMenu={subMenu}
            inputValues={inputValues}
            setInputValues={setInputValues}
            previous={previous}
            size={size}
          />
          {gallery ? <Gallery images={obj()[indx.current].img} setGallery={setGallery} /> : null}
        </div>
      ) : !window.location.pathname.includes("share") && !shareLoad ? (
        <>
          <Filter
            setFilters={setFilters}
            filters={filters}
            setMoreOpt={setMoreOpt}
            isMoreOpt={isMoreOpt}
            filterForSale={filterForSale}
          />
          <div
            className={clsx("flex flex-col gap-4", {
              "grid grid-cols-1 sm:grid-cols-2 gap-4": !isSmallDevice && size.full,
            })}
          >
            {obj()?.map((item, index) => (
              <div
                key={index}
                className={clsx(
                  "w-full border border-opacity-50 border-sky-500 h-auto flex flex-col gap-2 cursor-pointer hover:bg-sky-300 rounded-lg shadow-lg shadow-sky-200",
                  {
                    "bg-sky-300": Number(index) === Number(clicked.current),
                  },
                )}
                onClick={() => {
                  // console.log("lat = ", obj()[index]?.lat, "  lng = ", obj()[index]?.lng);
                  setVisibilityOfMarkers("singleForSalePin");
                  setCurrentItem(index);
                  isExpanded === index ? setExpanded(-1) : setExpanded(index);
                  setForSale({ ...forSale, menu: true });
                  indx.current = index;
                  idx.current = index;
                  clicked.current = index;
                  map.panTo({
                    // lat: Number(forSaleItems?.items[index]?.lat),
                    // lng: Number(forSaleItems?.items[index]?.lng),
                    lat: Number(obj()[index]?.lat),
                    lng: Number(obj()[index]?.lng),
                  });
                }}
                onMouseOver={() => highlightPin(index)}
                onMouseLeave={() =>
                  setHighlight({
                    ...highlight,
                    forSale: false,
                    history: false,
                    reo: false,
                    finIn: false,
                    indexFS: null,
                  })
                }
              >
                <div className="flex flex-row">
                  <div className="flex flex-col w-1/3">
                    <img src={item?.img[1]?.img} alt="photo1" className="w-full h-24 rounded-l-lg object-cover" />
                  </div>
                  <div className="flex flex-col justify-between w-2/3 p-1">
                    <div className="w-full h-2/5 flex flex-col items-start justify-start text-xs">
                      <div className="w-full flex flex-row items-center gap-1">
                        {JSON.parse(item?.parameters).type.includes("byt") ? (
                          <FontAwesomeIcon icon={faBuilding} className="w- h-3" />
                        ) : (
                          <img src={NaPredaj} alt="Na predaj" className="w-3 h-3" />
                        )}
                        {JSON.parse(item?.parameters).type} • {JSON.parse(item?.parameters).area} m²
                      </div>
                      <div className="w-full flex flex-row items-center gap-1">
                        • {JSON.parse(item?.parameters).condition}
                      </div>
                      <div className="w-full h-6 gap-1 ">
                        • {item?.street ? item?.street + ", " : null}
                        {item.city}
                        {Number(JSON.parse(item?.lv).List.length) > 1
                          ? ", okres " + JSON.parse(item?.lv).List[0]?.nazov_okresu
                          : ", okres " + JSON.parse(item?.lv).List?.nazov_okresu}
                      </div>
                    </div>
                    <div className="w-full h-1/4 flex items-center justify-end text-xl font-semibold">
                      {item.price} €
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <Load />
      )}
    </div>
  );
}
