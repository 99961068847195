import { useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { motion } from "framer-motion";

export default function AreaFilter({ filters, setFilters, isFilter }) {
  const [value, setValue] = useState([10, 10000]);

  const handleChange = (price) => {
    setValue(price);
    setFilters({ ...filters, minArea: price[0], maxArea: price[1] });
  };

  return (
    <motion.div
      className="w-full flex flex-col gap-2"
      initial={{ height: 30 }}
      animate={{ height: isFilter.area ? 100 : 30 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col">
        <p className="">Výmera (m²): </p>
        <div className="w-full flex flex-row gap-1">
          <input
            name="minArea"
            value={filters.minArea ? filters.minArea : ""}
            onChange={(e) => setFilters({ ...filters, minArea: e.target.value })}
            min={10}
            className="w-full rounded-lg text-center h-8 border border-opacity-50 border-sky-500"
            type="number"
            placeholder="Od"
          />
          <input
            name="maxArea"
            value={filters.maxArea ? filters.maxArea : ""}
            onChange={(e) => setFilters({ ...filters, maxArea: e.target.value })}
            min={10}
            className="w-full rounded-lg text-center h-8 border border-opacity-50 border-sky-500"
            type="number"
            placeholder="Do"
          />
        </div>
      </div>
      {/* <input
        className="w-full"
        onChange={(e) => setFilters({ ...filters, area: e.target.value })}
        type={"range"}
        min={10}
        max={2000}
        step={1}
        value={filters.area ? filters.area : 100}
        list={"tick-list"}
      /> */}
      <div className="w-full p-2 h-8">
        <RangeSlider value={value} onInput={handleChange} min={10} max={10000} step={10} />
      </div>
      <div className="mb-2 flex justify-between">
        <span className="-mt-4">{10} m²</span>
        <span className="-mt-4">{10000} m²</span>
      </div>
    </motion.div>
  );
}
