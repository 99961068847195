const UnitsPartizánske = {
  Brodzany: [
    { lat: 48.6117999, lng: 18.3127174 },
    { lat: 48.6109607, lng: 18.3137393 },
    { lat: 48.6082712, lng: 18.3159746 },
    { lat: 48.6028005, lng: 18.320725 },
    { lat: 48.6017334, lng: 18.3217678 },
    { lat: 48.6007191, lng: 18.3226451 },
    { lat: 48.6004423, lng: 18.3233989 },
    { lat: 48.5998683, lng: 18.32472 },
    { lat: 48.599189, lng: 18.326832 },
    { lat: 48.5987617, lng: 18.3294317 },
    { lat: 48.598656, lng: 18.3296577 },
    { lat: 48.5980582, lng: 18.3301205 },
    { lat: 48.5976064, lng: 18.3301319 },
    { lat: 48.5971612, lng: 18.3302422 },
    { lat: 48.5965271, lng: 18.3300028 },
    { lat: 48.5959843, lng: 18.3298779 },
    { lat: 48.5951625, lng: 18.3309508 },
    { lat: 48.5942768, lng: 18.3305724 },
    { lat: 48.5930537, lng: 18.3331143 },
    { lat: 48.5930066, lng: 18.3332122 },
    { lat: 48.593588, lng: 18.3337567 },
    { lat: 48.5939823, lng: 18.3343244 },
    { lat: 48.5941158, lng: 18.3349517 },
    { lat: 48.5942041, lng: 18.3358361 },
    { lat: 48.5941009, lng: 18.3364659 },
    { lat: 48.593555, lng: 18.3374466 },
    { lat: 48.5930229, lng: 18.3385959 },
    { lat: 48.5927208, lng: 18.339062 },
    { lat: 48.5925287, lng: 18.3399573 },
    { lat: 48.5924303, lng: 18.3402322 },
    { lat: 48.5921298, lng: 18.3407854 },
    { lat: 48.5917942, lng: 18.3412151 },
    { lat: 48.5915738, lng: 18.3413699 },
    { lat: 48.5912665, lng: 18.3414204 },
    { lat: 48.5907862, lng: 18.3413479 },
    { lat: 48.5897451, lng: 18.3414133 },
    { lat: 48.5892213, lng: 18.341247 },
    { lat: 48.5885289, lng: 18.3411523 },
    { lat: 48.5878519, lng: 18.3411924 },
    { lat: 48.5873995, lng: 18.3413541 },
    { lat: 48.5871136, lng: 18.3415465 },
    { lat: 48.5865863, lng: 18.3416786 },
    { lat: 48.5863344, lng: 18.3418324 },
    { lat: 48.5858594, lng: 18.3418316 },
    { lat: 48.5844521, lng: 18.3415081 },
    { lat: 48.5841842, lng: 18.3412763 },
    { lat: 48.5833195, lng: 18.3410707 },
    { lat: 48.582034, lng: 18.3404836 },
    { lat: 48.5817667, lng: 18.3404263 },
    { lat: 48.5811991, lng: 18.340545 },
    { lat: 48.5805787, lng: 18.3408879 },
    { lat: 48.5794629, lng: 18.3423275 },
    { lat: 48.5793006, lng: 18.3425108 },
    { lat: 48.5791413, lng: 18.3426876 },
    { lat: 48.578902, lng: 18.3430846 },
    { lat: 48.5785952, lng: 18.344284 },
    { lat: 48.5782993, lng: 18.3447606 },
    { lat: 48.5780442, lng: 18.3453591 },
    { lat: 48.5777579, lng: 18.346584 },
    { lat: 48.5778984, lng: 18.3476338 },
    { lat: 48.577963, lng: 18.3478336 },
    { lat: 48.5780227, lng: 18.348018 },
    { lat: 48.5780827, lng: 18.3481182 },
    { lat: 48.5781571, lng: 18.3482424 },
    { lat: 48.5783658, lng: 18.3487358 },
    { lat: 48.5788783, lng: 18.3499469 },
    { lat: 48.5789968, lng: 18.350447 },
    { lat: 48.5791268, lng: 18.3513654 },
    { lat: 48.579145, lng: 18.3514937 },
    { lat: 48.57924, lng: 18.3526643 },
    { lat: 48.5792555, lng: 18.3528557 },
    { lat: 48.5794934, lng: 18.3541528 },
    { lat: 48.5795104, lng: 18.3543934 },
    { lat: 48.5795711, lng: 18.3552553 },
    { lat: 48.5795541, lng: 18.3554819 },
    { lat: 48.579484, lng: 18.3564134 },
    { lat: 48.579544, lng: 18.3569573 },
    { lat: 48.579547, lng: 18.3575194 },
    { lat: 48.5795501, lng: 18.358107 },
    { lat: 48.5795359, lng: 18.3581913 },
    { lat: 48.5793701, lng: 18.3591769 },
    { lat: 48.579252, lng: 18.3597358 },
    { lat: 48.578872, lng: 18.3615344 },
    { lat: 48.5786645, lng: 18.3628409 },
    { lat: 48.5785663, lng: 18.363459 },
    { lat: 48.5784586, lng: 18.3638943 },
    { lat: 48.5783038, lng: 18.3641089 },
    { lat: 48.5781936, lng: 18.3644576 },
    { lat: 48.5780581, lng: 18.3651974 },
    { lat: 48.5780983, lng: 18.3657517 },
    { lat: 48.5780602, lng: 18.3661588 },
    { lat: 48.5781653, lng: 18.366522 },
    { lat: 48.5783186, lng: 18.366862 },
    { lat: 48.5785903, lng: 18.3674644 },
    { lat: 48.5786539, lng: 18.3676055 },
    { lat: 48.5789407, lng: 18.3679537 },
    { lat: 48.5789662, lng: 18.3679846 },
    { lat: 48.579096, lng: 18.3682663 },
    { lat: 48.5792797, lng: 18.3684641 },
    { lat: 48.5793867, lng: 18.3685794 },
    { lat: 48.5794782, lng: 18.3688591 },
    { lat: 48.5795149, lng: 18.3689714 },
    { lat: 48.579717, lng: 18.3691852 },
    { lat: 48.5797944, lng: 18.369267 },
    { lat: 48.5801443, lng: 18.3694725 },
    { lat: 48.5802399, lng: 18.3695706 },
    { lat: 48.5803119, lng: 18.3696446 },
    { lat: 48.5806197, lng: 18.3701264 },
    { lat: 48.5808142, lng: 18.3704307 },
    { lat: 48.5808684, lng: 18.3705804 },
    { lat: 48.5809091, lng: 18.3706929 },
    { lat: 48.5809118, lng: 18.3708832 },
    { lat: 48.580657, lng: 18.3716878 },
    { lat: 48.580596, lng: 18.3721884 },
    { lat: 48.5805364, lng: 18.3723325 },
    { lat: 48.5803088, lng: 18.3725998 },
    { lat: 48.5801658, lng: 18.3727678 },
    { lat: 48.5797496, lng: 18.3730471 },
    { lat: 48.5796221, lng: 18.3733484 },
    { lat: 48.5795942, lng: 18.3733802 },
    { lat: 48.579253, lng: 18.3737699 },
    { lat: 48.579075, lng: 18.3739117 },
    { lat: 48.5789473, lng: 18.3740134 },
    { lat: 48.5787851, lng: 18.3743103 },
    { lat: 48.5787121, lng: 18.3744439 },
    { lat: 48.5786107, lng: 18.3748263 },
    { lat: 48.5786034, lng: 18.3749435 },
    { lat: 48.5785299, lng: 18.3756614 },
    { lat: 48.5783699, lng: 18.3761044 },
    { lat: 48.5786917, lng: 18.3758899 },
    { lat: 48.579086, lng: 18.3757506 },
    { lat: 48.5791067, lng: 18.3757864 },
    { lat: 48.5791609, lng: 18.3758391 },
    { lat: 48.579268, lng: 18.3759432 },
    { lat: 48.5794525, lng: 18.3762771 },
    { lat: 48.5798446, lng: 18.3759674 },
    { lat: 48.5799826, lng: 18.375923 },
    { lat: 48.5801495, lng: 18.3758693 },
    { lat: 48.5806074, lng: 18.3758694 },
    { lat: 48.5808667, lng: 18.3759643 },
    { lat: 48.5811074, lng: 18.3759115 },
    { lat: 48.5818563, lng: 18.3764695 },
    { lat: 48.5825796, lng: 18.377153 },
    { lat: 48.5839441, lng: 18.3787094 },
    { lat: 48.5840033, lng: 18.3789014 },
    { lat: 48.5840153, lng: 18.379217 },
    { lat: 48.5841972, lng: 18.3797443 },
    { lat: 48.5844628, lng: 18.3801773 },
    { lat: 48.584772, lng: 18.3805181 },
    { lat: 48.5854672, lng: 18.3819696 },
    { lat: 48.5859844, lng: 18.3836886 },
    { lat: 48.5865831, lng: 18.3847488 },
    { lat: 48.5871401, lng: 18.3855288 },
    { lat: 48.5873286, lng: 18.3856117 },
    { lat: 48.5879955, lng: 18.3854124 },
    { lat: 48.5881157, lng: 18.3854442 },
    { lat: 48.5881834, lng: 18.3855207 },
    { lat: 48.5882934, lng: 18.3860751 },
    { lat: 48.5884425, lng: 18.3862796 },
    { lat: 48.5885549, lng: 18.3863116 },
    { lat: 48.5892757, lng: 18.3863527 },
    { lat: 48.5896049, lng: 18.386144 },
    { lat: 48.5898299, lng: 18.3861136 },
    { lat: 48.5903515, lng: 18.386322 },
    { lat: 48.5907245, lng: 18.3862945 },
    { lat: 48.5909547, lng: 18.3863949 },
    { lat: 48.5913775, lng: 18.386773 },
    { lat: 48.5917176, lng: 18.3869859 },
    { lat: 48.5922983, lng: 18.3872823 },
    { lat: 48.5929787, lng: 18.3875225 },
    { lat: 48.5937086, lng: 18.387946 },
    { lat: 48.5939283, lng: 18.3881204 },
    { lat: 48.5941274, lng: 18.3885552 },
    { lat: 48.5941791, lng: 18.3887749 },
    { lat: 48.5942356, lng: 18.38956 },
    { lat: 48.5943556, lng: 18.3898277 },
    { lat: 48.5948554, lng: 18.3902132 },
    { lat: 48.5953622, lng: 18.3910807 },
    { lat: 48.595703, lng: 18.39181 },
    { lat: 48.5962498, lng: 18.3917034 },
    { lat: 48.5968719, lng: 18.3918531 },
    { lat: 48.5971131, lng: 18.3920281 },
    { lat: 48.5975306, lng: 18.392331 },
    { lat: 48.5977607, lng: 18.3927932 },
    { lat: 48.5987285, lng: 18.3931923 },
    { lat: 48.5992899, lng: 18.3931284 },
    { lat: 48.5995254, lng: 18.3930025 },
    { lat: 48.6002225, lng: 18.3922113 },
    { lat: 48.6003323, lng: 18.3921242 },
    { lat: 48.6007939, lng: 18.391758 },
    { lat: 48.6011371, lng: 18.3913771 },
    { lat: 48.6015363, lng: 18.3912539 },
    { lat: 48.6019408, lng: 18.3910417 },
    { lat: 48.6025738, lng: 18.3906001 },
    { lat: 48.6027694, lng: 18.3902861 },
    { lat: 48.6030576, lng: 18.3891676 },
    { lat: 48.6037359, lng: 18.3874191 },
    { lat: 48.6039358, lng: 18.3864351 },
    { lat: 48.6041193, lng: 18.3861472 },
    { lat: 48.6045609, lng: 18.385835 },
    { lat: 48.6051593, lng: 18.3857381 },
    { lat: 48.6056145, lng: 18.3855087 },
    { lat: 48.6060847, lng: 18.3850399 },
    { lat: 48.60673, lng: 18.3840754 },
    { lat: 48.6074728, lng: 18.3840004 },
    { lat: 48.6078737, lng: 18.3840274 },
    { lat: 48.6081926, lng: 18.3842003 },
    { lat: 48.6082221, lng: 18.3832001 },
    { lat: 48.6081455, lng: 18.3823778 },
    { lat: 48.6082663, lng: 18.3801842 },
    { lat: 48.6083885, lng: 18.3789826 },
    { lat: 48.6085016, lng: 18.3759696 },
    { lat: 48.6087952, lng: 18.3750085 },
    { lat: 48.6090062, lng: 18.3740468 },
    { lat: 48.6093336, lng: 18.3738096 },
    { lat: 48.6099218, lng: 18.3735349 },
    { lat: 48.6109021, lng: 18.3728683 },
    { lat: 48.6112233, lng: 18.3727813 },
    { lat: 48.6114501, lng: 18.3723584 },
    { lat: 48.6132376, lng: 18.3716246 },
    { lat: 48.614793, lng: 18.3713979 },
    { lat: 48.616035, lng: 18.3715132 },
    { lat: 48.6172399, lng: 18.3712698 },
    { lat: 48.6176176, lng: 18.3707638 },
    { lat: 48.6179642, lng: 18.3705494 },
    { lat: 48.6185471, lng: 18.369836 },
    { lat: 48.6202142, lng: 18.3633807 },
    { lat: 48.6205196, lng: 18.3619702 },
    { lat: 48.6206037, lng: 18.3610569 },
    { lat: 48.6205496, lng: 18.3599779 },
    { lat: 48.6201802, lng: 18.3584237 },
    { lat: 48.6200115, lng: 18.3569883 },
    { lat: 48.619997, lng: 18.3561083 },
    { lat: 48.6204292, lng: 18.3532815 },
    { lat: 48.6202266, lng: 18.3517273 },
    { lat: 48.6202781, lng: 18.3516812 },
    { lat: 48.6207551, lng: 18.3518978 },
    { lat: 48.62082, lng: 18.3517066 },
    { lat: 48.6206768, lng: 18.3514693 },
    { lat: 48.6206403, lng: 18.3513402 },
    { lat: 48.6205264, lng: 18.3510823 },
    { lat: 48.6204485, lng: 18.3508017 },
    { lat: 48.6202955, lng: 18.3502482 },
    { lat: 48.6201748, lng: 18.3501286 },
    { lat: 48.6195501, lng: 18.3488343 },
    { lat: 48.6180689, lng: 18.3452136 },
    { lat: 48.6179348, lng: 18.3447127 },
    { lat: 48.6179161, lng: 18.3439035 },
    { lat: 48.6180423, lng: 18.3433984 },
    { lat: 48.6182715, lng: 18.3429811 },
    { lat: 48.620061, lng: 18.3409226 },
    { lat: 48.6199561, lng: 18.3395239 },
    { lat: 48.6198616, lng: 18.339107 },
    { lat: 48.6199334, lng: 18.3375205 },
    { lat: 48.6199337, lng: 18.3361444 },
    { lat: 48.6196841, lng: 18.3335016 },
    { lat: 48.6204164, lng: 18.3331846 },
    { lat: 48.6205587, lng: 18.3342864 },
    { lat: 48.6218921, lng: 18.3325432 },
    { lat: 48.6212994, lng: 18.3309775 },
    { lat: 48.6215905, lng: 18.3307989 },
    { lat: 48.6219732, lng: 18.3212821 },
    { lat: 48.6214253, lng: 18.3189162 },
    { lat: 48.6160729, lng: 18.3185215 },
    { lat: 48.6154863, lng: 18.3171094 },
    { lat: 48.6149333, lng: 18.3165298 },
    { lat: 48.6152947, lng: 18.3154168 },
    { lat: 48.6154339, lng: 18.3148059 },
    { lat: 48.6154855, lng: 18.3140643 },
    { lat: 48.6154758, lng: 18.3133545 },
    { lat: 48.6153779, lng: 18.3131404 },
    { lat: 48.6153364, lng: 18.3128259 },
    { lat: 48.6153607, lng: 18.3124416 },
    { lat: 48.6135004, lng: 18.3144149 },
    { lat: 48.6128584, lng: 18.3139153 },
    { lat: 48.6125501, lng: 18.3141119 },
    { lat: 48.6121764, lng: 18.3136302 },
    { lat: 48.6120842, lng: 18.313734 },
    { lat: 48.6118747, lng: 18.3131242 },
    { lat: 48.6117999, lng: 18.3127174 },
  ],
  Chynorany: [
    { lat: 48.6082712, lng: 18.3159746 },
    { lat: 48.6109607, lng: 18.3137393 },
    { lat: 48.6117999, lng: 18.3127174 },
    { lat: 48.6127789, lng: 18.3068606 },
    { lat: 48.6115373, lng: 18.3037686 },
    { lat: 48.61131, lng: 18.3032221 },
    { lat: 48.6101957, lng: 18.3004675 },
    { lat: 48.6094212, lng: 18.2985116 },
    { lat: 48.6105964, lng: 18.2967141 },
    { lat: 48.6107445, lng: 18.2965006 },
    { lat: 48.6108265, lng: 18.2963738 },
    { lat: 48.6107581, lng: 18.2958653 },
    { lat: 48.6106743, lng: 18.295659 },
    { lat: 48.6103121, lng: 18.2951221 },
    { lat: 48.6103741, lng: 18.2949864 },
    { lat: 48.6103903, lng: 18.2949932 },
    { lat: 48.6113655, lng: 18.2938254 },
    { lat: 48.6118695, lng: 18.2932247 },
    { lat: 48.6124492, lng: 18.2926624 },
    { lat: 48.6132836, lng: 18.2920604 },
    { lat: 48.6145463, lng: 18.2913669 },
    { lat: 48.6147808, lng: 18.291562 },
    { lat: 48.6183105, lng: 18.2887867 },
    { lat: 48.6184721, lng: 18.2886549 },
    { lat: 48.6186183, lng: 18.289238 },
    { lat: 48.6187355, lng: 18.2896953 },
    { lat: 48.619591, lng: 18.2891425 },
    { lat: 48.6205216, lng: 18.2885225 },
    { lat: 48.6217738, lng: 18.2876833 },
    { lat: 48.6220412, lng: 18.2885977 },
    { lat: 48.6224393, lng: 18.2885772 },
    { lat: 48.6227982, lng: 18.288635 },
    { lat: 48.6236349, lng: 18.2887871 },
    { lat: 48.6245236, lng: 18.2887784 },
    { lat: 48.6253625, lng: 18.2888483 },
    { lat: 48.6258634, lng: 18.2889475 },
    { lat: 48.6263694, lng: 18.2889762 },
    { lat: 48.62706, lng: 18.2889968 },
    { lat: 48.6274417, lng: 18.2890401 },
    { lat: 48.6281881, lng: 18.2891312 },
    { lat: 48.6282673, lng: 18.2891359 },
    { lat: 48.6286006, lng: 18.2891838 },
    { lat: 48.6289538, lng: 18.2892248 },
    { lat: 48.6295521, lng: 18.2892883 },
    { lat: 48.6303253, lng: 18.2894148 },
    { lat: 48.6307251, lng: 18.2895193 },
    { lat: 48.6311616, lng: 18.2896144 },
    { lat: 48.6312257, lng: 18.2896325 },
    { lat: 48.6320027, lng: 18.2896829 },
    { lat: 48.6320899, lng: 18.2896913 },
    { lat: 48.6322721, lng: 18.2897761 },
    { lat: 48.632652, lng: 18.2898914 },
    { lat: 48.6327147, lng: 18.2899019 },
    { lat: 48.6333116, lng: 18.2884927 },
    { lat: 48.6333895, lng: 18.2881159 },
    { lat: 48.6335271, lng: 18.287837 },
    { lat: 48.6335568, lng: 18.287586 },
    { lat: 48.633462, lng: 18.2870717 },
    { lat: 48.633331, lng: 18.2868504 },
    { lat: 48.633268, lng: 18.2865739 },
    { lat: 48.6332901, lng: 18.2864679 },
    { lat: 48.6332127, lng: 18.2862161 },
    { lat: 48.6333277, lng: 18.2859194 },
    { lat: 48.6333544, lng: 18.2855357 },
    { lat: 48.6333989, lng: 18.285225 },
    { lat: 48.6334211, lng: 18.2848429 },
    { lat: 48.6333555, lng: 18.2841248 },
    { lat: 48.6333489, lng: 18.2838517 },
    { lat: 48.6334133, lng: 18.2830001 },
    { lat: 48.6334318, lng: 18.2827105 },
    { lat: 48.6334306, lng: 18.2818164 },
    { lat: 48.633205, lng: 18.2816376 },
    { lat: 48.6331247, lng: 18.2815357 },
    { lat: 48.6329844, lng: 18.2810352 },
    { lat: 48.632834, lng: 18.280811 },
    { lat: 48.6327424, lng: 18.2806096 },
    { lat: 48.6327281, lng: 18.2802793 },
    { lat: 48.6327824, lng: 18.2801403 },
    { lat: 48.6328782, lng: 18.2797837 },
    { lat: 48.6328749, lng: 18.2794053 },
    { lat: 48.6329911, lng: 18.2792661 },
    { lat: 48.6332072, lng: 18.279237 },
    { lat: 48.6335085, lng: 18.279122 },
    { lat: 48.6336739, lng: 18.2791537 },
    { lat: 48.6337551, lng: 18.279151 },
    { lat: 48.633814, lng: 18.2789979 },
    { lat: 48.6338286, lng: 18.2787983 },
    { lat: 48.6337912, lng: 18.2784575 },
    { lat: 48.6338331, lng: 18.278262 },
    { lat: 48.633996, lng: 18.2781128 },
    { lat: 48.6342368, lng: 18.2780185 },
    { lat: 48.6344081, lng: 18.277738 },
    { lat: 48.6344186, lng: 18.2775661 },
    { lat: 48.634317, lng: 18.2765159 },
    { lat: 48.6343155, lng: 18.2765111 },
    { lat: 48.6343213, lng: 18.2760559 },
    { lat: 48.6343926, lng: 18.2757634 },
    { lat: 48.6343947, lng: 18.2756218 },
    { lat: 48.634532, lng: 18.2753379 },
    { lat: 48.6344756, lng: 18.2749218 },
    { lat: 48.6345267, lng: 18.274669 },
    { lat: 48.6348026, lng: 18.2739662 },
    { lat: 48.6347133, lng: 18.2737858 },
    { lat: 48.6345404, lng: 18.2735225 },
    { lat: 48.6343004, lng: 18.2733899 },
    { lat: 48.6341391, lng: 18.2732924 },
    { lat: 48.6338305, lng: 18.2730056 },
    { lat: 48.6334289, lng: 18.2728287 },
    { lat: 48.633351, lng: 18.272649 },
    { lat: 48.6332885, lng: 18.2725651 },
    { lat: 48.6330758, lng: 18.2723958 },
    { lat: 48.6330095, lng: 18.2722984 },
    { lat: 48.6327641, lng: 18.2722293 },
    { lat: 48.6324766, lng: 18.2719762 },
    { lat: 48.6323417, lng: 18.271866 },
    { lat: 48.6322496, lng: 18.2718138 },
    { lat: 48.6321053, lng: 18.2716798 },
    { lat: 48.6319494, lng: 18.271674 },
    { lat: 48.631718, lng: 18.2717707 },
    { lat: 48.631611, lng: 18.2717568 },
    { lat: 48.6314672, lng: 18.2716484 },
    { lat: 48.6313205, lng: 18.2717288 },
    { lat: 48.6310897, lng: 18.271758 },
    { lat: 48.6310212, lng: 18.2716224 },
    { lat: 48.6306839, lng: 18.2712292 },
    { lat: 48.6305734, lng: 18.2711319 },
    { lat: 48.6302892, lng: 18.2711527 },
    { lat: 48.630174, lng: 18.2713507 },
    { lat: 48.6300135, lng: 18.2714225 },
    { lat: 48.6299115, lng: 18.2713166 },
    { lat: 48.6297802, lng: 18.2711942 },
    { lat: 48.6296647, lng: 18.2710864 },
    { lat: 48.629585, lng: 18.2710942 },
    { lat: 48.6294671, lng: 18.271183 },
    { lat: 48.6293726, lng: 18.2712256 },
    { lat: 48.6291891, lng: 18.271057 },
    { lat: 48.629051, lng: 18.2709302 },
    { lat: 48.6290011, lng: 18.2709421 },
    { lat: 48.6289877, lng: 18.2709648 },
    { lat: 48.6288833, lng: 18.2714307 },
    { lat: 48.6286456, lng: 18.271459 },
    { lat: 48.6285216, lng: 18.2715557 },
    { lat: 48.6284087, lng: 18.2714954 },
    { lat: 48.6282659, lng: 18.271011 },
    { lat: 48.6282666, lng: 18.2708247 },
    { lat: 48.6282233, lng: 18.2705414 },
    { lat: 48.6280098, lng: 18.2704382 },
    { lat: 48.6279458, lng: 18.2705008 },
    { lat: 48.6279164, lng: 18.2705837 },
    { lat: 48.6276623, lng: 18.2705971 },
    { lat: 48.6275523, lng: 18.270845 },
    { lat: 48.6275195, lng: 18.2711403 },
    { lat: 48.6273047, lng: 18.2712134 },
    { lat: 48.6272831, lng: 18.2711121 },
    { lat: 48.6271527, lng: 18.2709978 },
    { lat: 48.627121, lng: 18.2708774 },
    { lat: 48.6271712, lng: 18.2707286 },
    { lat: 48.6270856, lng: 18.2705346 },
    { lat: 48.6271936, lng: 18.2703504 },
    { lat: 48.6271891, lng: 18.2702344 },
    { lat: 48.6271661, lng: 18.2701468 },
    { lat: 48.6269934, lng: 18.2700286 },
    { lat: 48.6268754, lng: 18.2697797 },
    { lat: 48.6268725, lng: 18.2695671 },
    { lat: 48.6268998, lng: 18.2694723 },
    { lat: 48.626881, lng: 18.2693591 },
    { lat: 48.6267777, lng: 18.269309 },
    { lat: 48.626712, lng: 18.2691481 },
    { lat: 48.6265908, lng: 18.2690793 },
    { lat: 48.62667, lng: 18.2689638 },
    { lat: 48.6266843, lng: 18.2688785 },
    { lat: 48.626561, lng: 18.2686563 },
    { lat: 48.6263608, lng: 18.2687079 },
    { lat: 48.6263136, lng: 18.2686916 },
    { lat: 48.6262879, lng: 18.268599 },
    { lat: 48.6262412, lng: 18.2681731 },
    { lat: 48.6263748, lng: 18.2680409 },
    { lat: 48.6263853, lng: 18.2679682 },
    { lat: 48.6262387, lng: 18.2677361 },
    { lat: 48.6262524, lng: 18.2676555 },
    { lat: 48.6263179, lng: 18.2675404 },
    { lat: 48.6264092, lng: 18.2674365 },
    { lat: 48.6264293, lng: 18.2673747 },
    { lat: 48.6264514, lng: 18.2671289 },
    { lat: 48.626495, lng: 18.2670063 },
    { lat: 48.62655, lng: 18.266921 },
    { lat: 48.6266018, lng: 18.2667606 },
    { lat: 48.6264881, lng: 18.2667436 },
    { lat: 48.6261463, lng: 18.266509 },
    { lat: 48.626004, lng: 18.2663691 },
    { lat: 48.6257808, lng: 18.2660543 },
    { lat: 48.6257846, lng: 18.2659832 },
    { lat: 48.6258391, lng: 18.2658488 },
    { lat: 48.6259641, lng: 18.2656906 },
    { lat: 48.6259753, lng: 18.2656289 },
    { lat: 48.6259521, lng: 18.2654967 },
    { lat: 48.6258907, lng: 18.2654305 },
    { lat: 48.6257732, lng: 18.2654069 },
    { lat: 48.6256007, lng: 18.2653137 },
    { lat: 48.6254719, lng: 18.2651692 },
    { lat: 48.6253696, lng: 18.2651739 },
    { lat: 48.6253073, lng: 18.2651524 },
    { lat: 48.6252986, lng: 18.2651193 },
    { lat: 48.6254357, lng: 18.2647908 },
    { lat: 48.6254322, lng: 18.2647506 },
    { lat: 48.625126, lng: 18.2645849 },
    { lat: 48.6249881, lng: 18.2645419 },
    { lat: 48.624838, lng: 18.2642036 },
    { lat: 48.6247636, lng: 18.2641469 },
    { lat: 48.6246948, lng: 18.2641469 },
    { lat: 48.624483, lng: 18.2643093 },
    { lat: 48.624423, lng: 18.2642689 },
    { lat: 48.6243028, lng: 18.2640819 },
    { lat: 48.6239973, lng: 18.263949 },
    { lat: 48.6239334, lng: 18.2638567 },
    { lat: 48.623862, lng: 18.2635917 },
    { lat: 48.6238447, lng: 18.2634873 },
    { lat: 48.6237897, lng: 18.2634142 },
    { lat: 48.6236814, lng: 18.2633929 },
    { lat: 48.6235492, lng: 18.2634871 },
    { lat: 48.6234865, lng: 18.2634161 },
    { lat: 48.623371, lng: 18.2630541 },
    { lat: 48.6234463, lng: 18.2625746 },
    { lat: 48.6233162, lng: 18.2623708 },
    { lat: 48.6227337, lng: 18.2621283 },
    { lat: 48.6226296, lng: 18.2621443 },
    { lat: 48.6224812, lng: 18.2622909 },
    { lat: 48.6224123, lng: 18.2624678 },
    { lat: 48.6224404, lng: 18.2627655 },
    { lat: 48.6222633, lng: 18.2630795 },
    { lat: 48.62214, lng: 18.2632143 },
    { lat: 48.6219582, lng: 18.2631908 },
    { lat: 48.6217887, lng: 18.2631283 },
    { lat: 48.6216255, lng: 18.2630361 },
    { lat: 48.6214934, lng: 18.2627758 },
    { lat: 48.6208502, lng: 18.2623229 },
    { lat: 48.6208016, lng: 18.2623417 },
    { lat: 48.6207371, lng: 18.262476 },
    { lat: 48.6206887, lng: 18.2629537 },
    { lat: 48.6207159, lng: 18.263131 },
    { lat: 48.6206149, lng: 18.2633788 },
    { lat: 48.620549, lng: 18.2633927 },
    { lat: 48.6204984, lng: 18.2636339 },
    { lat: 48.6205238, lng: 18.2637804 },
    { lat: 48.6204262, lng: 18.2639882 },
    { lat: 48.6202478, lng: 18.2642432 },
    { lat: 48.6201801, lng: 18.264283 },
    { lat: 48.6200231, lng: 18.2641011 },
    { lat: 48.61994, lng: 18.2638716 },
    { lat: 48.6199375, lng: 18.2636704 },
    { lat: 48.6199577, lng: 18.2633892 },
    { lat: 48.619943, lng: 18.2632899 },
    { lat: 48.6198822, lng: 18.2631572 },
    { lat: 48.6199052, lng: 18.2630038 },
    { lat: 48.6198994, lng: 18.2628027 },
    { lat: 48.6198652, lng: 18.2627179 },
    { lat: 48.6198139, lng: 18.2626679 },
    { lat: 48.619549, lng: 18.2625964 },
    { lat: 48.6194014, lng: 18.2623791 },
    { lat: 48.6193667, lng: 18.2624261 },
    { lat: 48.6193212, lng: 18.2626363 },
    { lat: 48.6193488, lng: 18.2628046 },
    { lat: 48.6192942, lng: 18.2628962 },
    { lat: 48.6191604, lng: 18.2629621 },
    { lat: 48.6189119, lng: 18.2629377 },
    { lat: 48.6187354, lng: 18.26289 },
    { lat: 48.6186083, lng: 18.2626608 },
    { lat: 48.6185701, lng: 18.2627032 },
    { lat: 48.618534, lng: 18.2628423 },
    { lat: 48.6184657, lng: 18.2633437 },
    { lat: 48.6183609, lng: 18.2635463 },
    { lat: 48.6183149, lng: 18.2637876 },
    { lat: 48.6182876, lng: 18.2638229 },
    { lat: 48.6179967, lng: 18.2638838 },
    { lat: 48.6179233, lng: 18.2638456 },
    { lat: 48.6178759, lng: 18.2637847 },
    { lat: 48.6178216, lng: 18.2633681 },
    { lat: 48.6177249, lng: 18.2632145 },
    { lat: 48.6175393, lng: 18.2630702 },
    { lat: 48.6174753, lng: 18.2629352 },
    { lat: 48.617351, lng: 18.2625969 },
    { lat: 48.6172593, lng: 18.2624148 },
    { lat: 48.6171491, lng: 18.2623813 },
    { lat: 48.6169072, lng: 18.2623735 },
    { lat: 48.6168338, lng: 18.2623331 },
    { lat: 48.6165855, lng: 18.2617159 },
    { lat: 48.6164971, lng: 18.2615916 },
    { lat: 48.6164962, lng: 18.2615182 },
    { lat: 48.6167316, lng: 18.2613924 },
    { lat: 48.6167661, lng: 18.2612435 },
    { lat: 48.6167526, lng: 18.2611679 },
    { lat: 48.6167058, lng: 18.261078 },
    { lat: 48.6163668, lng: 18.260656 },
    { lat: 48.6161712, lng: 18.260589 },
    { lat: 48.6160724, lng: 18.2606347 },
    { lat: 48.615972, lng: 18.2607558 },
    { lat: 48.6158684, lng: 18.260976 },
    { lat: 48.6158006, lng: 18.2610708 },
    { lat: 48.6157169, lng: 18.2610884 },
    { lat: 48.6155483, lng: 18.2609224 },
    { lat: 48.6154836, lng: 18.2608944 },
    { lat: 48.6154109, lng: 18.2609256 },
    { lat: 48.6153217, lng: 18.2611275 },
    { lat: 48.615257, lng: 18.261274 },
    { lat: 48.6151231, lng: 18.2613599 },
    { lat: 48.6145791, lng: 18.2613767 },
    { lat: 48.6145137, lng: 18.2612803 },
    { lat: 48.6144819, lng: 18.2611789 },
    { lat: 48.6145035, lng: 18.2610799 },
    { lat: 48.614575, lng: 18.2609704 },
    { lat: 48.6146975, lng: 18.2608635 },
    { lat: 48.6147258, lng: 18.2607875 },
    { lat: 48.6147268, lng: 18.260705 },
    { lat: 48.6146017, lng: 18.2603896 },
    { lat: 48.6144496, lng: 18.2600584 },
    { lat: 48.6143178, lng: 18.2598238 },
    { lat: 48.6142444, lng: 18.2597064 },
    { lat: 48.614177, lng: 18.2596524 },
    { lat: 48.6139005, lng: 18.2595721 },
    { lat: 48.6138043, lng: 18.2596063 },
    { lat: 48.6132155, lng: 18.2599652 },
    { lat: 48.6130752, lng: 18.259997 },
    { lat: 48.6126383, lng: 18.2596372 },
    { lat: 48.6126106, lng: 18.2596872 },
    { lat: 48.6120298, lng: 18.2591586 },
    { lat: 48.6116403, lng: 18.258761 },
    { lat: 48.6112609, lng: 18.2586177 },
    { lat: 48.6106126, lng: 18.2580926 },
    { lat: 48.6105208, lng: 18.2582232 },
    { lat: 48.6104333, lng: 18.2580157 },
    { lat: 48.6103284, lng: 18.2578774 },
    { lat: 48.6102765, lng: 18.2577387 },
    { lat: 48.6102466, lng: 18.2573143 },
    { lat: 48.6102698, lng: 18.257307 },
    { lat: 48.6102705, lng: 18.2571989 },
    { lat: 48.6103053, lng: 18.2570378 },
    { lat: 48.61008, lng: 18.2567284 },
    { lat: 48.6101848, lng: 18.2565105 },
    { lat: 48.6099283, lng: 18.2561466 },
    { lat: 48.6097654, lng: 18.2564002 },
    { lat: 48.6095471, lng: 18.2565274 },
    { lat: 48.6094612, lng: 18.2563474 },
    { lat: 48.609355, lng: 18.256094 },
    { lat: 48.609156, lng: 18.255464 },
    { lat: 48.609273, lng: 18.255322 },
    { lat: 48.609453, lng: 18.254588 },
    { lat: 48.609608, lng: 18.254382 },
    { lat: 48.609442, lng: 18.253949 },
    { lat: 48.608943, lng: 18.25363 },
    { lat: 48.608626, lng: 18.252573 },
    { lat: 48.608622, lng: 18.252571 },
    { lat: 48.608437, lng: 18.252476 },
    { lat: 48.60839, lng: 18.252526 },
    { lat: 48.60792, lng: 18.253013 },
    { lat: 48.606855, lng: 18.25361 },
    { lat: 48.606638, lng: 18.253275 },
    { lat: 48.604732, lng: 18.254176 },
    { lat: 48.60448, lng: 18.25372 },
    { lat: 48.604339, lng: 18.253538 },
    { lat: 48.604263, lng: 18.253444 },
    { lat: 48.604226, lng: 18.253395 },
    { lat: 48.603933, lng: 18.253071 },
    { lat: 48.60366, lng: 18.252737 },
    { lat: 48.602171, lng: 18.250735 },
    { lat: 48.601635, lng: 18.249621 },
    { lat: 48.600352, lng: 18.24702 },
    { lat: 48.599054, lng: 18.244125 },
    { lat: 48.59897, lng: 18.24403 },
    { lat: 48.598936, lng: 18.243997 },
    { lat: 48.5974522, lng: 18.2474544 },
    { lat: 48.5973286, lng: 18.2475421 },
    { lat: 48.5971731, lng: 18.248019 },
    { lat: 48.5969603, lng: 18.2486762 },
    { lat: 48.5969105, lng: 18.2488299 },
    { lat: 48.5969094, lng: 18.2488311 },
    { lat: 48.596877, lng: 18.2489341 },
    { lat: 48.5966759, lng: 18.2495438 },
    { lat: 48.5966589, lng: 18.2495984 },
    { lat: 48.5965749, lng: 18.249949 },
    { lat: 48.5965332, lng: 18.2501248 },
    { lat: 48.5965213, lng: 18.250172 },
    { lat: 48.596504, lng: 18.2502049 },
    { lat: 48.5964876, lng: 18.2502366 },
    { lat: 48.5964807, lng: 18.2503099 },
    { lat: 48.5960117, lng: 18.2510688 },
    { lat: 48.5954883, lng: 18.2519168 },
    { lat: 48.5953468, lng: 18.2520903 },
    { lat: 48.5947571, lng: 18.2532014 },
    { lat: 48.5944861, lng: 18.2537306 },
    { lat: 48.59438, lng: 18.2539872 },
    { lat: 48.5942063, lng: 18.2544403 },
    { lat: 48.5941332, lng: 18.2549777 },
    { lat: 48.5941445, lng: 18.2551174 },
    { lat: 48.5948607, lng: 18.2573007 },
    { lat: 48.5956626, lng: 18.2597862 },
    { lat: 48.5959055, lng: 18.2605025 },
    { lat: 48.5964327, lng: 18.2621034 },
    { lat: 48.5972415, lng: 18.2645706 },
    { lat: 48.5974364, lng: 18.2649817 },
    { lat: 48.5961894, lng: 18.2666591 },
    { lat: 48.5948189, lng: 18.2685265 },
    { lat: 48.5935494, lng: 18.2702248 },
    { lat: 48.5935032, lng: 18.2702864 },
    { lat: 48.5937522, lng: 18.2705771 },
    { lat: 48.5937333, lng: 18.2706173 },
    { lat: 48.5935697, lng: 18.2710335 },
    { lat: 48.5935102, lng: 18.2709809 },
    { lat: 48.5933877, lng: 18.2708724 },
    { lat: 48.5934616, lng: 18.2716702 },
    { lat: 48.5935822, lng: 18.2723421 },
    { lat: 48.593545, lng: 18.2724817 },
    { lat: 48.5931195, lng: 18.2731303 },
    { lat: 48.5929502, lng: 18.2734295 },
    { lat: 48.5928276, lng: 18.2738136 },
    { lat: 48.5928204, lng: 18.2741998 },
    { lat: 48.5928575, lng: 18.2744955 },
    { lat: 48.5931737, lng: 18.2755456 },
    { lat: 48.5933453, lng: 18.2758053 },
    { lat: 48.5934049, lng: 18.2758432 },
    { lat: 48.5934873, lng: 18.2757837 },
    { lat: 48.5936111, lng: 18.2755413 },
    { lat: 48.5936827, lng: 18.2747978 },
    { lat: 48.5940103, lng: 18.2744103 },
    { lat: 48.5943794, lng: 18.2741743 },
    { lat: 48.5945847, lng: 18.2739933 },
    { lat: 48.5949588, lng: 18.2740058 },
    { lat: 48.5953554, lng: 18.2743312 },
    { lat: 48.5953773, lng: 18.2744164 },
    { lat: 48.5953849, lng: 18.2746129 },
    { lat: 48.5957653, lng: 18.2758811 },
    { lat: 48.5960691, lng: 18.2763001 },
    { lat: 48.5964067, lng: 18.2765651 },
    { lat: 48.5966037, lng: 18.2767937 },
    { lat: 48.5967752, lng: 18.2771094 },
    { lat: 48.597022, lng: 18.2781554 },
    { lat: 48.5971264, lng: 18.2784805 },
    { lat: 48.5973786, lng: 18.2794791 },
    { lat: 48.597548, lng: 18.2797595 },
    { lat: 48.5978462, lng: 18.2800267 },
    { lat: 48.5981328, lng: 18.2801233 },
    { lat: 48.5986892, lng: 18.2800648 },
    { lat: 48.5993419, lng: 18.2798556 },
    { lat: 48.5997555, lng: 18.2798697 },
    { lat: 48.6004079, lng: 18.2801111 },
    { lat: 48.6005526, lng: 18.2801943 },
    { lat: 48.6006855, lng: 18.2802905 },
    { lat: 48.6012098, lng: 18.2809353 },
    { lat: 48.6014387, lng: 18.2814503 },
    { lat: 48.6015113, lng: 18.2817921 },
    { lat: 48.6015283, lng: 18.282725 },
    { lat: 48.6015034, lng: 18.2829617 },
    { lat: 48.6014091, lng: 18.2833475 },
    { lat: 48.6013999, lng: 18.2845525 },
    { lat: 48.6014931, lng: 18.285498 },
    { lat: 48.6015761, lng: 18.2861047 },
    { lat: 48.6015341, lng: 18.2867133 },
    { lat: 48.6014462, lng: 18.2875489 },
    { lat: 48.6012739, lng: 18.287969 },
    { lat: 48.6011899, lng: 18.2883806 },
    { lat: 48.6010907, lng: 18.2890554 },
    { lat: 48.6010756, lng: 18.2895047 },
    { lat: 48.6011358, lng: 18.2900855 },
    { lat: 48.601286, lng: 18.290901 },
    { lat: 48.6016669, lng: 18.291472 },
    { lat: 48.6017999, lng: 18.2915272 },
    { lat: 48.6024408, lng: 18.291526 },
    { lat: 48.6027752, lng: 18.2915948 },
    { lat: 48.6032869, lng: 18.2917614 },
    { lat: 48.6034448, lng: 18.2918601 },
    { lat: 48.6034379, lng: 18.2923348 },
    { lat: 48.6033708, lng: 18.2927848 },
    { lat: 48.6032508, lng: 18.2929732 },
    { lat: 48.6031958, lng: 18.2932363 },
    { lat: 48.603337, lng: 18.2937742 },
    { lat: 48.6034232, lng: 18.2940229 },
    { lat: 48.6034944, lng: 18.294402 },
    { lat: 48.6035433, lng: 18.2947457 },
    { lat: 48.6035819, lng: 18.2952669 },
    { lat: 48.6036359, lng: 18.2956813 },
    { lat: 48.6036318, lng: 18.2961842 },
    { lat: 48.6037018, lng: 18.2965042 },
    { lat: 48.6038474, lng: 18.2967025 },
    { lat: 48.6039274, lng: 18.2969581 },
    { lat: 48.6040355, lng: 18.2977092 },
    { lat: 48.6042865, lng: 18.2983353 },
    { lat: 48.6045, lng: 18.2986364 },
    { lat: 48.6047965, lng: 18.2991318 },
    { lat: 48.6050598, lng: 18.3001495 },
    { lat: 48.6050876, lng: 18.3006023 },
    { lat: 48.6052153, lng: 18.3009402 },
    { lat: 48.605317, lng: 18.3011035 },
    { lat: 48.6055011, lng: 18.3013054 },
    { lat: 48.6055887, lng: 18.301538 },
    { lat: 48.6056274, lng: 18.3018393 },
    { lat: 48.6056799, lng: 18.3021102 },
    { lat: 48.605667, lng: 18.3022423 },
    { lat: 48.6055909, lng: 18.3026297 },
    { lat: 48.6055311, lng: 18.3029893 },
    { lat: 48.6054259, lng: 18.303344 },
    { lat: 48.6053124, lng: 18.3039469 },
    { lat: 48.6051739, lng: 18.3040308 },
    { lat: 48.6050953, lng: 18.3041543 },
    { lat: 48.6050469, lng: 18.3045184 },
    { lat: 48.6049123, lng: 18.3058431 },
    { lat: 48.6082712, lng: 18.3159746 },
  ],
  Hradište: [
    { lat: 48.692343, lng: 18.339358 },
    { lat: 48.692178, lng: 18.339285 },
    { lat: 48.692061, lng: 18.339302 },
    { lat: 48.692019, lng: 18.339326 },
    { lat: 48.69196, lng: 18.339369 },
    { lat: 48.691908, lng: 18.339385 },
    { lat: 48.691613, lng: 18.33967 },
    { lat: 48.691536, lng: 18.339814 },
    { lat: 48.6912, lng: 18.339641 },
    { lat: 48.690961, lng: 18.339537 },
    { lat: 48.690826, lng: 18.339347 },
    { lat: 48.690353, lng: 18.339375 },
    { lat: 48.689744, lng: 18.339522 },
    { lat: 48.68936, lng: 18.339736 },
    { lat: 48.688953, lng: 18.339889 },
    { lat: 48.688665, lng: 18.339765 },
    { lat: 48.688448, lng: 18.339622 },
    { lat: 48.688208, lng: 18.339717 },
    { lat: 48.688041, lng: 18.33961 },
    { lat: 48.687818, lng: 18.339394 },
    { lat: 48.687546, lng: 18.339086 },
    { lat: 48.68677, lng: 18.338858 },
    { lat: 48.685566, lng: 18.340183 },
    { lat: 48.685238, lng: 18.340524 },
    { lat: 48.685154, lng: 18.340743 },
    { lat: 48.684738, lng: 18.341334 },
    { lat: 48.684314, lng: 18.341667 },
    { lat: 48.68401, lng: 18.341804 },
    { lat: 48.683846, lng: 18.342105 },
    { lat: 48.683705, lng: 18.34221 },
    { lat: 48.683665, lng: 18.342411 },
    { lat: 48.683342, lng: 18.342601 },
    { lat: 48.682964, lng: 18.342876 },
    { lat: 48.682664, lng: 18.343059 },
    { lat: 48.682565, lng: 18.343027 },
    { lat: 48.682357, lng: 18.343045 },
    { lat: 48.681917, lng: 18.343324 },
    { lat: 48.681421, lng: 18.34344 },
    { lat: 48.681377, lng: 18.343455 },
    { lat: 48.681097, lng: 18.343543 },
    { lat: 48.681115, lng: 18.343888 },
    { lat: 48.681228, lng: 18.344556 },
    { lat: 48.6813764, lng: 18.3444497 },
    { lat: 48.6814003, lng: 18.345135 },
    { lat: 48.681451, lng: 18.3454931 },
    { lat: 48.6812881, lng: 18.3460634 },
    { lat: 48.6809968, lng: 18.3464629 },
    { lat: 48.6809069, lng: 18.3470289 },
    { lat: 48.6807817, lng: 18.3472913 },
    { lat: 48.6803556, lng: 18.3476802 },
    { lat: 48.6801846, lng: 18.3479695 },
    { lat: 48.6800164, lng: 18.3485891 },
    { lat: 48.6797386, lng: 18.3490632 },
    { lat: 48.6793151, lng: 18.3495795 },
    { lat: 48.679232, lng: 18.3501064 },
    { lat: 48.6790245, lng: 18.3509327 },
    { lat: 48.6786081, lng: 18.3519315 },
    { lat: 48.6784088, lng: 18.3525401 },
    { lat: 48.6783271, lng: 18.3526937 },
    { lat: 48.6781179, lng: 18.3529672 },
    { lat: 48.6780061, lng: 18.3531062 },
    { lat: 48.6778686, lng: 18.3533262 },
    { lat: 48.6777475, lng: 18.353722 },
    { lat: 48.6776282, lng: 18.35403 },
    { lat: 48.6774753, lng: 18.3544605 },
    { lat: 48.6773975, lng: 18.3546401 },
    { lat: 48.6771956, lng: 18.3549437 },
    { lat: 48.6772092, lng: 18.3549662 },
    { lat: 48.6771319, lng: 18.3550932 },
    { lat: 48.6767197, lng: 18.3558304 },
    { lat: 48.676474, lng: 18.3561739 },
    { lat: 48.6763456, lng: 18.3563218 },
    { lat: 48.6762328, lng: 18.3565008 },
    { lat: 48.6761449, lng: 18.3566831 },
    { lat: 48.6758195, lng: 18.3572956 },
    { lat: 48.6758096, lng: 18.35738 },
    { lat: 48.6755425, lng: 18.3578277 },
    { lat: 48.6754111, lng: 18.3581019 },
    { lat: 48.6752841, lng: 18.3583327 },
    { lat: 48.6750705, lng: 18.3586667 },
    { lat: 48.6747736, lng: 18.3594048 },
    { lat: 48.6746725, lng: 18.3597344 },
    { lat: 48.6743106, lng: 18.3611037 },
    { lat: 48.6741303, lng: 18.3618329 },
    { lat: 48.6738331, lng: 18.3630065 },
    { lat: 48.6737973, lng: 18.3632963 },
    { lat: 48.6737793, lng: 18.3637383 },
    { lat: 48.6737463, lng: 18.3642156 },
    { lat: 48.6737358, lng: 18.3644062 },
    { lat: 48.6737111, lng: 18.3648565 },
    { lat: 48.6737067, lng: 18.3656417 },
    { lat: 48.6739549, lng: 18.366104 },
    { lat: 48.674013, lng: 18.3663558 },
    { lat: 48.6739559, lng: 18.3672676 },
    { lat: 48.6736768, lng: 18.3681047 },
    { lat: 48.6732922, lng: 18.3690324 },
    { lat: 48.6732143, lng: 18.3692541 },
    { lat: 48.6738027, lng: 18.3692017 },
    { lat: 48.6741965, lng: 18.3691634 },
    { lat: 48.6740956, lng: 18.3696993 },
    { lat: 48.6739156, lng: 18.3706926 },
    { lat: 48.6737138, lng: 18.3718203 },
    { lat: 48.6735736, lng: 18.3725836 },
    { lat: 48.6732759, lng: 18.3742366 },
    { lat: 48.6731194, lng: 18.3751272 },
    { lat: 48.6729991, lng: 18.3757983 },
    { lat: 48.6727364, lng: 18.3773118 },
    { lat: 48.6726658, lng: 18.3777017 },
    { lat: 48.6726053, lng: 18.3780743 },
    { lat: 48.6722623, lng: 18.3800249 },
    { lat: 48.6725197, lng: 18.3800913 },
    { lat: 48.6727492, lng: 18.3801994 },
    { lat: 48.6729623, lng: 18.3803589 },
    { lat: 48.6730776, lng: 18.3804627 },
    { lat: 48.6731903, lng: 18.3806014 },
    { lat: 48.6734547, lng: 18.3809536 },
    { lat: 48.6735901, lng: 18.3812133 },
    { lat: 48.6737781, lng: 18.3815481 },
    { lat: 48.6738171, lng: 18.3816412 },
    { lat: 48.6738945, lng: 18.3818745 },
    { lat: 48.6739364, lng: 18.3821204 },
    { lat: 48.6739528, lng: 18.3823329 },
    { lat: 48.6747052, lng: 18.3822897 },
    { lat: 48.675926, lng: 18.3823945 },
    { lat: 48.6769554, lng: 18.3824851 },
    { lat: 48.6774885, lng: 18.3825349 },
    { lat: 48.6780961, lng: 18.3825859 },
    { lat: 48.6789601, lng: 18.3826606 },
    { lat: 48.6797152, lng: 18.3827295 },
    { lat: 48.680402, lng: 18.382785 },
    { lat: 48.680852, lng: 18.382824 },
    { lat: 48.6807627, lng: 18.3834037 },
    { lat: 48.680626, lng: 18.3842063 },
    { lat: 48.6805685, lng: 18.3843921 },
    { lat: 48.6804482, lng: 18.3846195 },
    { lat: 48.6810435, lng: 18.3845111 },
    { lat: 48.6811509, lng: 18.3844949 },
    { lat: 48.6813139, lng: 18.3845058 },
    { lat: 48.6816481, lng: 18.3843272 },
    { lat: 48.6826926, lng: 18.3837601 },
    { lat: 48.6827482, lng: 18.3838073 },
    { lat: 48.6829983, lng: 18.3836768 },
    { lat: 48.6830781, lng: 18.3835517 },
    { lat: 48.6833109, lng: 18.3834195 },
    { lat: 48.6833523, lng: 18.3833521 },
    { lat: 48.6834814, lng: 18.3829179 },
    { lat: 48.6835639, lng: 18.3828167 },
    { lat: 48.6836444, lng: 18.3827461 },
    { lat: 48.6838154, lng: 18.3826546 },
    { lat: 48.6838288, lng: 18.3826329 },
    { lat: 48.6838704, lng: 18.3826491 },
    { lat: 48.683945, lng: 18.3826345 },
    { lat: 48.6840107, lng: 18.3825376 },
    { lat: 48.6841007, lng: 18.3823501 },
    { lat: 48.6843493, lng: 18.382057 },
    { lat: 48.684439, lng: 18.3818046 },
    { lat: 48.6846213, lng: 18.3819966 },
    { lat: 48.6849981, lng: 18.3823205 },
    { lat: 48.6852114, lng: 18.3824829 },
    { lat: 48.6854396, lng: 18.382648 },
    { lat: 48.6857287, lng: 18.3828753 },
    { lat: 48.6859648, lng: 18.3830223 },
    { lat: 48.6861088, lng: 18.383136 },
    { lat: 48.6862187, lng: 18.383203 },
    { lat: 48.6872202, lng: 18.3839236 },
    { lat: 48.687574, lng: 18.383249 },
    { lat: 48.6876235, lng: 18.3832087 },
    { lat: 48.6876526, lng: 18.3833443 },
    { lat: 48.6878119, lng: 18.3835135 },
    { lat: 48.6878919, lng: 18.3835697 },
    { lat: 48.6880028, lng: 18.383617 },
    { lat: 48.6880812, lng: 18.3836648 },
    { lat: 48.6882847, lng: 18.3837542 },
    { lat: 48.6884294, lng: 18.3838803 },
    { lat: 48.6885428, lng: 18.3839933 },
    { lat: 48.6887979, lng: 18.3842982 },
    { lat: 48.6889805, lng: 18.3845956 },
    { lat: 48.6891134, lng: 18.3847939 },
    { lat: 48.6892124, lng: 18.3849295 },
    { lat: 48.6892916, lng: 18.3849972 },
    { lat: 48.6893649, lng: 18.3850346 },
    { lat: 48.6895323, lng: 18.3852043 },
    { lat: 48.6896276, lng: 18.3853957 },
    { lat: 48.6896857, lng: 18.3855465 },
    { lat: 48.6898251, lng: 18.3857976 },
    { lat: 48.6898908, lng: 18.3860056 },
    { lat: 48.689922, lng: 18.3861401 },
    { lat: 48.6900629, lng: 18.3864025 },
    { lat: 48.6901723, lng: 18.3865613 },
    { lat: 48.6904186, lng: 18.3868709 },
    { lat: 48.6906592, lng: 18.3870166 },
    { lat: 48.6909048, lng: 18.3870917 },
    { lat: 48.6910798, lng: 18.3871526 },
    { lat: 48.6913095, lng: 18.3872479 },
    { lat: 48.6916211, lng: 18.3874135 },
    { lat: 48.6917333, lng: 18.3875013 },
    { lat: 48.6918204, lng: 18.3876291 },
    { lat: 48.692133, lng: 18.387849 },
    { lat: 48.692202, lng: 18.387737 },
    { lat: 48.692257, lng: 18.387631 },
    { lat: 48.692317, lng: 18.387513 },
    { lat: 48.692402, lng: 18.387407 },
    { lat: 48.692433, lng: 18.387326 },
    { lat: 48.692618, lng: 18.387276 },
    { lat: 48.692676, lng: 18.387212 },
    { lat: 48.69391, lng: 18.384802 },
    { lat: 48.693992, lng: 18.38468 },
    { lat: 48.694081, lng: 18.384601 },
    { lat: 48.694983, lng: 18.383829 },
    { lat: 48.69502, lng: 18.383798 },
    { lat: 48.695098, lng: 18.383731 },
    { lat: 48.695126, lng: 18.383708 },
    { lat: 48.695348, lng: 18.383525 },
    { lat: 48.695403, lng: 18.383486 },
    { lat: 48.695427, lng: 18.38343 },
    { lat: 48.695464, lng: 18.383361 },
    { lat: 48.695514, lng: 18.38337 },
    { lat: 48.695632, lng: 18.38347 },
    { lat: 48.695872, lng: 18.383346 },
    { lat: 48.695947, lng: 18.383276 },
    { lat: 48.696014, lng: 18.383239 },
    { lat: 48.696091, lng: 18.383226 },
    { lat: 48.696161, lng: 18.383148 },
    { lat: 48.696282, lng: 18.383107 },
    { lat: 48.696313, lng: 18.383016 },
    { lat: 48.69635, lng: 18.382994 },
    { lat: 48.696518, lng: 18.383042 },
    { lat: 48.696563, lng: 18.383094 },
    { lat: 48.696646, lng: 18.383073 },
    { lat: 48.696752, lng: 18.382836 },
    { lat: 48.696734, lng: 18.382709 },
    { lat: 48.696697, lng: 18.382615 },
    { lat: 48.696722, lng: 18.382515 },
    { lat: 48.696764, lng: 18.382505 },
    { lat: 48.696896, lng: 18.382633 },
    { lat: 48.696928, lng: 18.382598 },
    { lat: 48.696975, lng: 18.382539 },
    { lat: 48.696976, lng: 18.382448 },
    { lat: 48.697089, lng: 18.382301 },
    { lat: 48.697198, lng: 18.382352 },
    { lat: 48.697261, lng: 18.382211 },
    { lat: 48.697342, lng: 18.382252 },
    { lat: 48.697401, lng: 18.38222 },
    { lat: 48.697428, lng: 18.382148 },
    { lat: 48.697527, lng: 18.382135 },
    { lat: 48.697569, lng: 18.38205 },
    { lat: 48.697584, lng: 18.381909 },
    { lat: 48.69766, lng: 18.381928 },
    { lat: 48.697707, lng: 18.381923 },
    { lat: 48.697726, lng: 18.381872 },
    { lat: 48.69777, lng: 18.381834 },
    { lat: 48.69781, lng: 18.381856 },
    { lat: 48.697864, lng: 18.381824 },
    { lat: 48.697887, lng: 18.381791 },
    { lat: 48.697936, lng: 18.381773 },
    { lat: 48.698007, lng: 18.381788 },
    { lat: 48.698265, lng: 18.381651 },
    { lat: 48.698294, lng: 18.381574 },
    { lat: 48.698296, lng: 18.38148 },
    { lat: 48.698354, lng: 18.381506 },
    { lat: 48.698399, lng: 18.381484 },
    { lat: 48.698376, lng: 18.381397 },
    { lat: 48.698457, lng: 18.38139 },
    { lat: 48.698463, lng: 18.381312 },
    { lat: 48.698493, lng: 18.381239 },
    { lat: 48.69863, lng: 18.381226 },
    { lat: 48.698631, lng: 18.381109 },
    { lat: 48.698683, lng: 18.380867 },
    { lat: 48.698831, lng: 18.380749 },
    { lat: 48.698941, lng: 18.380783 },
    { lat: 48.698987, lng: 18.380742 },
    { lat: 48.699008, lng: 18.380622 },
    { lat: 48.699079, lng: 18.380704 },
    { lat: 48.699109, lng: 18.380578 },
    { lat: 48.699208, lng: 18.38051 },
    { lat: 48.699273, lng: 18.380465 },
    { lat: 48.699317, lng: 18.380526 },
    { lat: 48.699367, lng: 18.380588 },
    { lat: 48.699404, lng: 18.380551 },
    { lat: 48.699468, lng: 18.380481 },
    { lat: 48.699504, lng: 18.380379 },
    { lat: 48.699639, lng: 18.380318 },
    { lat: 48.699644, lng: 18.380238 },
    { lat: 48.699689, lng: 18.380205 },
    { lat: 48.699756, lng: 18.380099 },
    { lat: 48.699898, lng: 18.380059 },
    { lat: 48.700051, lng: 18.379893 },
    { lat: 48.70005, lng: 18.379815 },
    { lat: 48.700094, lng: 18.37975 },
    { lat: 48.700158, lng: 18.379738 },
    { lat: 48.700204, lng: 18.379672 },
    { lat: 48.700235, lng: 18.379673 },
    { lat: 48.700336, lng: 18.379527 },
    { lat: 48.700426, lng: 18.379485 },
    { lat: 48.700586, lng: 18.379292 },
    { lat: 48.700688, lng: 18.379254 },
    { lat: 48.700748, lng: 18.379102 },
    { lat: 48.700784, lng: 18.379101 },
    { lat: 48.700826, lng: 18.379026 },
    { lat: 48.700873, lng: 18.379037 },
    { lat: 48.700932, lng: 18.378911 },
    { lat: 48.701063, lng: 18.378813 },
    { lat: 48.701108, lng: 18.378848 },
    { lat: 48.701193, lng: 18.378759 },
    { lat: 48.701283, lng: 18.378736 },
    { lat: 48.701374, lng: 18.378546 },
    { lat: 48.70148, lng: 18.378595 },
    { lat: 48.701565, lng: 18.378474 },
    { lat: 48.701596, lng: 18.378497 },
    { lat: 48.701677, lng: 18.378489 },
    { lat: 48.701712, lng: 18.378345 },
    { lat: 48.701751, lng: 18.378415 },
    { lat: 48.701802, lng: 18.378369 },
    { lat: 48.701867, lng: 18.378321 },
    { lat: 48.702081, lng: 18.378264 },
    { lat: 48.702154, lng: 18.378294 },
    { lat: 48.702292, lng: 18.378174 },
    { lat: 48.702341, lng: 18.378137 },
    { lat: 48.702434, lng: 18.377996 },
    { lat: 48.702522, lng: 18.377959 },
    { lat: 48.702659, lng: 18.377967 },
    { lat: 48.702703, lng: 18.377925 },
    { lat: 48.702892, lng: 18.377908 },
    { lat: 48.70297, lng: 18.377873 },
    { lat: 48.703063, lng: 18.377814 },
    { lat: 48.703081, lng: 18.377728 },
    { lat: 48.70312, lng: 18.377602 },
    { lat: 48.703182, lng: 18.377617 },
    { lat: 48.703279, lng: 18.377594 },
    { lat: 48.703279, lng: 18.377507 },
    { lat: 48.703317, lng: 18.377469 },
    { lat: 48.703401, lng: 18.377426 },
    { lat: 48.703524, lng: 18.377424 },
    { lat: 48.703631, lng: 18.377291 },
    { lat: 48.703827, lng: 18.376917 },
    { lat: 48.703674, lng: 18.376425 },
    { lat: 48.703654, lng: 18.376363 },
    { lat: 48.703615, lng: 18.376157 },
    { lat: 48.703582, lng: 18.375876 },
    { lat: 48.703539, lng: 18.375644 },
    { lat: 48.703497, lng: 18.375361 },
    { lat: 48.70341, lng: 18.374845 },
    { lat: 48.703303, lng: 18.374387 },
    { lat: 48.703255, lng: 18.374065 },
    { lat: 48.703267, lng: 18.373654 },
    { lat: 48.703317, lng: 18.372479 },
    { lat: 48.703328, lng: 18.371758 },
    { lat: 48.703436, lng: 18.371316 },
    { lat: 48.70359, lng: 18.370326 },
    { lat: 48.703912, lng: 18.369207 },
    { lat: 48.704146, lng: 18.36832 },
    { lat: 48.704241, lng: 18.367887 },
    { lat: 48.704295, lng: 18.367206 },
    { lat: 48.704513, lng: 18.366658 },
    { lat: 48.704463, lng: 18.366545 },
    { lat: 48.704383, lng: 18.366209 },
    { lat: 48.70412, lng: 18.365516 },
    { lat: 48.703978, lng: 18.365183 },
    { lat: 48.703872, lng: 18.364968 },
    { lat: 48.7038, lng: 18.364836 },
    { lat: 48.703943, lng: 18.364422 },
    { lat: 48.70398, lng: 18.364042 },
    { lat: 48.703972, lng: 18.363831 },
    { lat: 48.704002, lng: 18.363563 },
    { lat: 48.703886, lng: 18.363248 },
    { lat: 48.703781, lng: 18.362866 },
    { lat: 48.703572, lng: 18.36247 },
    { lat: 48.703352, lng: 18.362182 },
    { lat: 48.702686, lng: 18.361963 },
    { lat: 48.702321, lng: 18.361955 },
    { lat: 48.701811, lng: 18.361993 },
    { lat: 48.70242, lng: 18.360304 },
    { lat: 48.702267, lng: 18.360111 },
    { lat: 48.701989, lng: 18.359943 },
    { lat: 48.701841, lng: 18.359894 },
    { lat: 48.701355, lng: 18.359439 },
    { lat: 48.701187, lng: 18.359296 },
    { lat: 48.701064, lng: 18.359265 },
    { lat: 48.700944, lng: 18.359319 },
    { lat: 48.700879, lng: 18.358978 },
    { lat: 48.700916, lng: 18.35849 },
    { lat: 48.700892, lng: 18.358386 },
    { lat: 48.700863, lng: 18.358329 },
    { lat: 48.700859, lng: 18.358125 },
    { lat: 48.700986, lng: 18.35803 },
    { lat: 48.701089, lng: 18.357884 },
    { lat: 48.701129, lng: 18.357701 },
    { lat: 48.701148, lng: 18.357526 },
    { lat: 48.701286, lng: 18.35708 },
    { lat: 48.701425, lng: 18.356727 },
    { lat: 48.701423, lng: 18.356689 },
    { lat: 48.701415, lng: 18.356683 },
    { lat: 48.700978, lng: 18.356361 },
    { lat: 48.700832, lng: 18.356301 },
    { lat: 48.700739, lng: 18.356179 },
    { lat: 48.700647, lng: 18.356121 },
    { lat: 48.700572, lng: 18.356004 },
    { lat: 48.700545, lng: 18.35593 },
    { lat: 48.700492, lng: 18.355776 },
    { lat: 48.700425, lng: 18.355718 },
    { lat: 48.700289, lng: 18.355684 },
    { lat: 48.700226, lng: 18.355722 },
    { lat: 48.700129, lng: 18.355661 },
    { lat: 48.700053, lng: 18.355577 },
    { lat: 48.699961, lng: 18.355427 },
    { lat: 48.699859, lng: 18.355245 },
    { lat: 48.699761, lng: 18.355209 },
    { lat: 48.699695, lng: 18.355122 },
    { lat: 48.699553, lng: 18.35504 },
    { lat: 48.699276, lng: 18.354973 },
    { lat: 48.699232, lng: 18.354939 },
    { lat: 48.699196, lng: 18.354892 },
    { lat: 48.699171, lng: 18.354857 },
    { lat: 48.699036, lng: 18.354804 },
    { lat: 48.698871, lng: 18.354861 },
    { lat: 48.698612, lng: 18.354896 },
    { lat: 48.698532, lng: 18.354907 },
    { lat: 48.698346, lng: 18.354958 },
    { lat: 48.698314, lng: 18.354966 },
    { lat: 48.698136, lng: 18.355043 },
    { lat: 48.69803, lng: 18.355103 },
    { lat: 48.697972, lng: 18.355135 },
    { lat: 48.697619, lng: 18.355116 },
    { lat: 48.697492, lng: 18.355266 },
    { lat: 48.697218, lng: 18.355342 },
    { lat: 48.697051, lng: 18.355344 },
    { lat: 48.696907, lng: 18.355178 },
    { lat: 48.696519, lng: 18.355201 },
    { lat: 48.69635, lng: 18.355072 },
    { lat: 48.696282, lng: 18.355073 },
    { lat: 48.696103, lng: 18.355226 },
    { lat: 48.695959, lng: 18.355302 },
    { lat: 48.695797, lng: 18.355353 },
    { lat: 48.695352, lng: 18.354858 },
    { lat: 48.695242, lng: 18.354776 },
    { lat: 48.695216, lng: 18.354761 },
    { lat: 48.694664, lng: 18.354461 },
    { lat: 48.694491, lng: 18.354429 },
    { lat: 48.694416, lng: 18.354396 },
    { lat: 48.694309, lng: 18.354311 },
    { lat: 48.694149, lng: 18.354185 },
    { lat: 48.69395, lng: 18.35416 },
    { lat: 48.693845, lng: 18.354096 },
    { lat: 48.693703, lng: 18.353936 },
    { lat: 48.693597, lng: 18.353774 },
    { lat: 48.693595, lng: 18.353516 },
    { lat: 48.693662, lng: 18.353402 },
    { lat: 48.693635, lng: 18.353262 },
    { lat: 48.69356, lng: 18.352861 },
    { lat: 48.693367, lng: 18.352582 },
    { lat: 48.693239, lng: 18.352442 },
    { lat: 48.693164, lng: 18.352408 },
    { lat: 48.693127, lng: 18.352348 },
    { lat: 48.693076, lng: 18.352267 },
    { lat: 48.693026, lng: 18.352188 },
    { lat: 48.693046, lng: 18.351746 },
    { lat: 48.693076, lng: 18.351469 },
    { lat: 48.693034, lng: 18.351383 },
    { lat: 48.692979, lng: 18.350995 },
    { lat: 48.692873, lng: 18.350513 },
    { lat: 48.692801, lng: 18.350198 },
    { lat: 48.692828, lng: 18.350018 },
    { lat: 48.692994, lng: 18.349599 },
    { lat: 48.692945, lng: 18.349279 },
    { lat: 48.692926, lng: 18.34899 },
    { lat: 48.692774, lng: 18.34888 },
    { lat: 48.692657, lng: 18.348738 },
    { lat: 48.692518, lng: 18.348359 },
    { lat: 48.692483, lng: 18.348149 },
    { lat: 48.692475, lng: 18.348093 },
    { lat: 48.692432, lng: 18.347843 },
    { lat: 48.692418, lng: 18.347672 },
    { lat: 48.692466, lng: 18.347474 },
    { lat: 48.6925, lng: 18.347289 },
    { lat: 48.692433, lng: 18.346193 },
    { lat: 48.692428, lng: 18.345807 },
    { lat: 48.692412, lng: 18.345565 },
    { lat: 48.692353, lng: 18.345219 },
    { lat: 48.692279, lng: 18.345194 },
    { lat: 48.692225, lng: 18.345146 },
    { lat: 48.69219, lng: 18.345076 },
    { lat: 48.692132, lng: 18.344739 },
    { lat: 48.692053, lng: 18.344496 },
    { lat: 48.691972, lng: 18.344076 },
    { lat: 48.691995, lng: 18.343979 },
    { lat: 48.692032, lng: 18.343749 },
    { lat: 48.691998, lng: 18.343651 },
    { lat: 48.691929, lng: 18.343547 },
    { lat: 48.691919, lng: 18.343332 },
    { lat: 48.691905, lng: 18.343161 },
    { lat: 48.691907, lng: 18.342938 },
    { lat: 48.691885, lng: 18.342779 },
    { lat: 48.691941, lng: 18.342363 },
    { lat: 48.691891, lng: 18.342147 },
    { lat: 48.691893, lng: 18.341916 },
    { lat: 48.692037, lng: 18.34152 },
    { lat: 48.692076, lng: 18.341439 },
    { lat: 48.692124, lng: 18.341233 },
    { lat: 48.692156, lng: 18.340995 },
    { lat: 48.692197, lng: 18.340896 },
    { lat: 48.692187, lng: 18.340692 },
    { lat: 48.6922, lng: 18.340492 },
    { lat: 48.692251, lng: 18.34038 },
    { lat: 48.692348, lng: 18.340117 },
    { lat: 48.692315, lng: 18.339913 },
    { lat: 48.692403, lng: 18.339876 },
    { lat: 48.692505, lng: 18.339681 },
    { lat: 48.692608, lng: 18.339598 },
    { lat: 48.692648, lng: 18.339539 },
    { lat: 48.692676, lng: 18.339541 },
    { lat: 48.692678, lng: 18.339537 },
    { lat: 48.692671, lng: 18.339462 },
    { lat: 48.692632, lng: 18.339469 },
    { lat: 48.692597, lng: 18.33941 },
    { lat: 48.692343, lng: 18.339358 },
  ],
  JeškovaVes: [
    { lat: 48.5510115, lng: 18.3559609 },
    { lat: 48.5504098, lng: 18.3524704 },
    { lat: 48.5510283, lng: 18.3521139 },
    { lat: 48.5522659, lng: 18.3514332 },
    { lat: 48.5523712, lng: 18.3514183 },
    { lat: 48.5571057, lng: 18.3487438 },
    { lat: 48.5574213, lng: 18.3485748 },
    { lat: 48.5578109, lng: 18.3490015 },
    { lat: 48.559678, lng: 18.3471199 },
    { lat: 48.5601349, lng: 18.346058 },
    { lat: 48.5602077, lng: 18.3457303 },
    { lat: 48.5606758, lng: 18.3435472 },
    { lat: 48.5609015, lng: 18.3416896 },
    { lat: 48.5616545, lng: 18.3424979 },
    { lat: 48.5619879, lng: 18.3416477 },
    { lat: 48.5625803, lng: 18.340147 },
    { lat: 48.5627423, lng: 18.3397579 },
    { lat: 48.5625781, lng: 18.3397469 },
    { lat: 48.5620018, lng: 18.3396954 },
    { lat: 48.5612996, lng: 18.3394858 },
    { lat: 48.561132, lng: 18.3394161 },
    { lat: 48.560956, lng: 18.3392752 },
    { lat: 48.5606861, lng: 18.3392115 },
    { lat: 48.5601863, lng: 18.3388125 },
    { lat: 48.5594904, lng: 18.3382795 },
    { lat: 48.5591858, lng: 18.3380393 },
    { lat: 48.5590616, lng: 18.3383572 },
    { lat: 48.5588911, lng: 18.3387171 },
    { lat: 48.5588202, lng: 18.3386585 },
    { lat: 48.5586766, lng: 18.3390121 },
    { lat: 48.5585711, lng: 18.3389241 },
    { lat: 48.5583604, lng: 18.3386893 },
    { lat: 48.5580788, lng: 18.3383372 },
    { lat: 48.5578757, lng: 18.3386382 },
    { lat: 48.5577937, lng: 18.3383032 },
    { lat: 48.5575086, lng: 18.3380274 },
    { lat: 48.5571173, lng: 18.3375341 },
    { lat: 48.5568476, lng: 18.3370112 },
    { lat: 48.5567924, lng: 18.3368167 },
    { lat: 48.5567532, lng: 18.3365579 },
    { lat: 48.5566745, lng: 18.3362876 },
    { lat: 48.556561, lng: 18.3358759 },
    { lat: 48.556427, lng: 18.3352406 },
    { lat: 48.5556007, lng: 18.3358733 },
    { lat: 48.5553122, lng: 18.3354801 },
    { lat: 48.5551674, lng: 18.3353095 },
    { lat: 48.5551124, lng: 18.335198 },
    { lat: 48.5546985, lng: 18.3347226 },
    { lat: 48.5541895, lng: 18.3342006 },
    { lat: 48.553252, lng: 18.3333211 },
    { lat: 48.5528974, lng: 18.3330158 },
    { lat: 48.5528299, lng: 18.3329223 },
    { lat: 48.5526933, lng: 18.3325812 },
    { lat: 48.5526229, lng: 18.3323342 },
    { lat: 48.55249, lng: 18.3320168 },
    { lat: 48.5523914, lng: 18.3318699 },
    { lat: 48.5520906, lng: 18.3316179 },
    { lat: 48.5520166, lng: 18.3313297 },
    { lat: 48.551922, lng: 18.3311534 },
    { lat: 48.5518323, lng: 18.3310595 },
    { lat: 48.5518171, lng: 18.3310714 },
    { lat: 48.5516093, lng: 18.3305483 },
    { lat: 48.5515268, lng: 18.330566 },
    { lat: 48.5513666, lng: 18.330308 },
    { lat: 48.5513478, lng: 18.3303137 },
    { lat: 48.5512262, lng: 18.3302203 },
    { lat: 48.5510466, lng: 18.3301557 },
    { lat: 48.5510395, lng: 18.3301086 },
    { lat: 48.5508944, lng: 18.3300976 },
    { lat: 48.5507428, lng: 18.3301303 },
    { lat: 48.5504964, lng: 18.3302224 },
    { lat: 48.5503334, lng: 18.3303114 },
    { lat: 48.5503099, lng: 18.3302763 },
    { lat: 48.5501736, lng: 18.3304416 },
    { lat: 48.5498763, lng: 18.3304487 },
    { lat: 48.5495644, lng: 18.3303438 },
    { lat: 48.5494633, lng: 18.3302909 },
    { lat: 48.5492951, lng: 18.3303096 },
    { lat: 48.5488746, lng: 18.3305342 },
    { lat: 48.5486869, lng: 18.330705 },
    { lat: 48.5485264, lng: 18.3307013 },
    { lat: 48.5483396, lng: 18.3306512 },
    { lat: 48.5477887, lng: 18.330483 },
    { lat: 48.5469993, lng: 18.330371 },
    { lat: 48.5459142, lng: 18.3302987 },
    { lat: 48.5457632, lng: 18.3301831 },
    { lat: 48.5457418, lng: 18.3301011 },
    { lat: 48.5456945, lng: 18.329984 },
    { lat: 48.545541, lng: 18.3298039 },
    { lat: 48.5446255, lng: 18.3293168 },
    { lat: 48.5443438, lng: 18.3293038 },
    { lat: 48.5442125, lng: 18.3294763 },
    { lat: 48.5438703, lng: 18.3297107 },
    { lat: 48.5437036, lng: 18.3298133 },
    { lat: 48.5432167, lng: 18.3301211 },
    { lat: 48.5430808, lng: 18.3301524 },
    { lat: 48.5420288, lng: 18.330527 },
    { lat: 48.5416969, lng: 18.3305436 },
    { lat: 48.5416265, lng: 18.3303739 },
    { lat: 48.541034, lng: 18.3306122 },
    { lat: 48.5397943, lng: 18.3313545 },
    { lat: 48.538661, lng: 18.3318242 },
    { lat: 48.5352603, lng: 18.3352344 },
    { lat: 48.5341708, lng: 18.3363377 },
    { lat: 48.5317454, lng: 18.3374638 },
    { lat: 48.5304435, lng: 18.3386041 },
    { lat: 48.5290396, lng: 18.3390969 },
    { lat: 48.5262539, lng: 18.34003 },
    { lat: 48.5257429, lng: 18.3402139 },
    { lat: 48.5239115, lng: 18.3429438 },
    { lat: 48.5216729, lng: 18.3439628 },
    { lat: 48.52155, lng: 18.3440227 },
    { lat: 48.5209574, lng: 18.3446188 },
    { lat: 48.5195222, lng: 18.3460527 },
    { lat: 48.5168708, lng: 18.34653 },
    { lat: 48.5134663, lng: 18.3500755 },
    { lat: 48.5129035, lng: 18.3495795 },
    { lat: 48.5117658, lng: 18.3500723 },
    { lat: 48.5109259, lng: 18.3491018 },
    { lat: 48.5093688, lng: 18.3485814 },
    { lat: 48.508391, lng: 18.3493494 },
    { lat: 48.5080574, lng: 18.3495916 },
    { lat: 48.507624, lng: 18.34991 },
    { lat: 48.5070843, lng: 18.349705 },
    { lat: 48.5059982, lng: 18.3495293 },
    { lat: 48.5056616, lng: 18.3493034 },
    { lat: 48.5053392, lng: 18.3487899 },
    { lat: 48.5048472, lng: 18.3486898 },
    { lat: 48.5046127, lng: 18.3487102 },
    { lat: 48.5037982, lng: 18.3487311 },
    { lat: 48.5036061, lng: 18.3486454 },
    { lat: 48.5031879, lng: 18.3485323 },
    { lat: 48.5026936, lng: 18.3487083 },
    { lat: 48.5022353, lng: 18.3490839 },
    { lat: 48.5018191, lng: 18.3494882 },
    { lat: 48.50154, lng: 18.349721 },
    { lat: 48.501088, lng: 18.349817 },
    { lat: 48.5012055, lng: 18.3512074 },
    { lat: 48.5013934, lng: 18.3528401 },
    { lat: 48.5018404, lng: 18.3544832 },
    { lat: 48.5019327, lng: 18.3547784 },
    { lat: 48.5019675, lng: 18.3551771 },
    { lat: 48.5020582, lng: 18.355263 },
    { lat: 48.5021248, lng: 18.3553672 },
    { lat: 48.5021002, lng: 18.3555091 },
    { lat: 48.5020423, lng: 18.3555806 },
    { lat: 48.501906, lng: 18.3560651 },
    { lat: 48.5018256, lng: 18.3565425 },
    { lat: 48.5017758, lng: 18.3566787 },
    { lat: 48.5017791, lng: 18.3568156 },
    { lat: 48.5017406, lng: 18.3569553 },
    { lat: 48.5016409, lng: 18.3571278 },
    { lat: 48.5016999, lng: 18.3572142 },
    { lat: 48.5014451, lng: 18.3578785 },
    { lat: 48.5013534, lng: 18.3583908 },
    { lat: 48.5014074, lng: 18.3587482 },
    { lat: 48.5015111, lng: 18.3589865 },
    { lat: 48.5016461, lng: 18.3592005 },
    { lat: 48.5018467, lng: 18.3593784 },
    { lat: 48.5022589, lng: 18.3595652 },
    { lat: 48.5025555, lng: 18.3598607 },
    { lat: 48.5026838, lng: 18.360078 },
    { lat: 48.5029309, lng: 18.3603977 },
    { lat: 48.5028878, lng: 18.3605547 },
    { lat: 48.5035149, lng: 18.3609738 },
    { lat: 48.5037574, lng: 18.3612702 },
    { lat: 48.5040797, lng: 18.3614639 },
    { lat: 48.5043362, lng: 18.3619509 },
    { lat: 48.5046327, lng: 18.3624077 },
    { lat: 48.5047468, lng: 18.362592 },
    { lat: 48.5051365, lng: 18.3630005 },
    { lat: 48.5052267, lng: 18.3633209 },
    { lat: 48.5054981, lng: 18.3634101 },
    { lat: 48.5056566, lng: 18.3635205 },
    { lat: 48.5058516, lng: 18.3634978 },
    { lat: 48.5061296, lng: 18.3637245 },
    { lat: 48.5061514, lng: 18.3637506 },
    { lat: 48.5065513, lng: 18.3640002 },
    { lat: 48.5067838, lng: 18.3644057 },
    { lat: 48.5071703, lng: 18.3649374 },
    { lat: 48.507521, lng: 18.3651115 },
    { lat: 48.5076291, lng: 18.3652094 },
    { lat: 48.5078923, lng: 18.3652783 },
    { lat: 48.507933, lng: 18.3653962 },
    { lat: 48.5080367, lng: 18.3652763 },
    { lat: 48.5083966, lng: 18.3653161 },
    { lat: 48.5085801, lng: 18.3653511 },
    { lat: 48.5085731, lng: 18.3654662 },
    { lat: 48.5086665, lng: 18.3654191 },
    { lat: 48.5087193, lng: 18.3654959 },
    { lat: 48.5088469, lng: 18.3655576 },
    { lat: 48.5090407, lng: 18.3657335 },
    { lat: 48.5091958, lng: 18.3660062 },
    { lat: 48.5092383, lng: 18.3661563 },
    { lat: 48.509396, lng: 18.3665141 },
    { lat: 48.5097773, lng: 18.3673568 },
    { lat: 48.509804, lng: 18.3674295 },
    { lat: 48.5098346, lng: 18.3676313 },
    { lat: 48.5098828, lng: 18.3678671 },
    { lat: 48.5099172, lng: 18.3679986 },
    { lat: 48.5100857, lng: 18.3682034 },
    { lat: 48.510313, lng: 18.3682349 },
    { lat: 48.5104631, lng: 18.3683137 },
    { lat: 48.5106053, lng: 18.3685176 },
    { lat: 48.5106683, lng: 18.3687141 },
    { lat: 48.5111472, lng: 18.3689634 },
    { lat: 48.5113914, lng: 18.3690938 },
    { lat: 48.511719, lng: 18.3694997 },
    { lat: 48.5117591, lng: 18.3694949 },
    { lat: 48.5118123, lng: 18.3698347 },
    { lat: 48.511856, lng: 18.3698718 },
    { lat: 48.5118606, lng: 18.3701338 },
    { lat: 48.5119013, lng: 18.3704375 },
    { lat: 48.5119537, lng: 18.3707258 },
    { lat: 48.5121483, lng: 18.371096 },
    { lat: 48.5121992, lng: 18.3712788 },
    { lat: 48.5122583, lng: 18.3713659 },
    { lat: 48.512321, lng: 18.371518 },
    { lat: 48.5124058, lng: 18.3717446 },
    { lat: 48.5124496, lng: 18.3720012 },
    { lat: 48.5124472, lng: 18.3723374 },
    { lat: 48.5124794, lng: 18.3725486 },
    { lat: 48.5125624, lng: 18.3729437 },
    { lat: 48.5126292, lng: 18.3730266 },
    { lat: 48.5127612, lng: 18.3733265 },
    { lat: 48.5127761, lng: 18.3733719 },
    { lat: 48.5128768, lng: 18.3734776 },
    { lat: 48.5129793, lng: 18.3734752 },
    { lat: 48.5134033, lng: 18.3736126 },
    { lat: 48.5135888, lng: 18.3737193 },
    { lat: 48.5136084, lng: 18.3737495 },
    { lat: 48.5139269, lng: 18.3736317 },
    { lat: 48.5140171, lng: 18.3735928 },
    { lat: 48.5142216, lng: 18.3735963 },
    { lat: 48.5142588, lng: 18.3736186 },
    { lat: 48.5143154, lng: 18.3735993 },
    { lat: 48.5143471, lng: 18.3735415 },
    { lat: 48.5144227, lng: 18.3731401 },
    { lat: 48.5144844, lng: 18.3731743 },
    { lat: 48.5144904, lng: 18.3734237 },
    { lat: 48.5145183, lng: 18.3734575 },
    { lat: 48.5145347, lng: 18.3734895 },
    { lat: 48.5146372, lng: 18.3735832 },
    { lat: 48.5147534, lng: 18.3736072 },
    { lat: 48.5149294, lng: 18.3736051 },
    { lat: 48.5155327, lng: 18.3735617 },
    { lat: 48.5156377, lng: 18.3735206 },
    { lat: 48.5158908, lng: 18.3735061 },
    { lat: 48.5161888, lng: 18.3733699 },
    { lat: 48.516575, lng: 18.3732168 },
    { lat: 48.5167965, lng: 18.3731612 },
    { lat: 48.5170069, lng: 18.3729312 },
    { lat: 48.5170558, lng: 18.3729179 },
    { lat: 48.517236, lng: 18.3730739 },
    { lat: 48.5174551, lng: 18.3730186 },
    { lat: 48.5176032, lng: 18.3731634 },
    { lat: 48.5176768, lng: 18.3731099 },
    { lat: 48.5179146, lng: 18.373185 },
    { lat: 48.517988, lng: 18.3731237 },
    { lat: 48.5180552, lng: 18.3731582 },
    { lat: 48.5181143, lng: 18.3733044 },
    { lat: 48.5185828, lng: 18.3735308 },
    { lat: 48.5186015, lng: 18.3735441 },
    { lat: 48.5187294, lng: 18.373447 },
    { lat: 48.518983, lng: 18.3735051 },
    { lat: 48.5192672, lng: 18.373523 },
    { lat: 48.5193028, lng: 18.3735754 },
    { lat: 48.5196251, lng: 18.3734294 },
    { lat: 48.5196939, lng: 18.3734679 },
    { lat: 48.5197545, lng: 18.3733207 },
    { lat: 48.5198331, lng: 18.3732726 },
    { lat: 48.5199596, lng: 18.3732569 },
    { lat: 48.5200895, lng: 18.3731585 },
    { lat: 48.5201817, lng: 18.3730517 },
    { lat: 48.5202387, lng: 18.3730238 },
    { lat: 48.5203125, lng: 18.3728891 },
    { lat: 48.5203798, lng: 18.3727037 },
    { lat: 48.5204755, lng: 18.3726192 },
    { lat: 48.5204686, lng: 18.3723984 },
    { lat: 48.5205087, lng: 18.3723086 },
    { lat: 48.5206124, lng: 18.3722658 },
    { lat: 48.520664, lng: 18.3721194 },
    { lat: 48.5207531, lng: 18.3720597 },
    { lat: 48.5209207, lng: 18.3717693 },
    { lat: 48.5209842, lng: 18.371554 },
    { lat: 48.5210139, lng: 18.371162 },
    { lat: 48.5210968, lng: 18.3710091 },
    { lat: 48.521124, lng: 18.3708711 },
    { lat: 48.5211665, lng: 18.370545 },
    { lat: 48.5211837, lng: 18.3704474 },
    { lat: 48.5212372, lng: 18.3702762 },
    { lat: 48.5212058, lng: 18.3701183 },
    { lat: 48.52137, lng: 18.3701123 },
    { lat: 48.5217777, lng: 18.3700589 },
    { lat: 48.5218381, lng: 18.3698088 },
    { lat: 48.5220358, lng: 18.3696509 },
    { lat: 48.5222152, lng: 18.3695533 },
    { lat: 48.5223803, lng: 18.3694995 },
    { lat: 48.5225183, lng: 18.3694449 },
    { lat: 48.5227415, lng: 18.3694403 },
    { lat: 48.5229004, lng: 18.3695175 },
    { lat: 48.5231625, lng: 18.3696044 },
    { lat: 48.5233786, lng: 18.3697178 },
    { lat: 48.5234989, lng: 18.3697879 },
    { lat: 48.5237437, lng: 18.3699507 },
    { lat: 48.5238414, lng: 18.3700428 },
    { lat: 48.5238495, lng: 18.370024 },
    { lat: 48.5240825, lng: 18.3700342 },
    { lat: 48.5242276, lng: 18.3700466 },
    { lat: 48.524667, lng: 18.3702156 },
    { lat: 48.5247701, lng: 18.3702049 },
    { lat: 48.5247705, lng: 18.370312 },
    { lat: 48.5244241, lng: 18.3705345 },
    { lat: 48.5242757, lng: 18.3706616 },
    { lat: 48.5244464, lng: 18.3711103 },
    { lat: 48.5250071, lng: 18.3712692 },
    { lat: 48.5249079, lng: 18.3717118 },
    { lat: 48.5253178, lng: 18.3725393 },
    { lat: 48.525449, lng: 18.3723468 },
    { lat: 48.5254702, lng: 18.3722608 },
    { lat: 48.5255312, lng: 18.3721438 },
    { lat: 48.5255881, lng: 18.3721337 },
    { lat: 48.5256443, lng: 18.3721496 },
    { lat: 48.5257215, lng: 18.3722185 },
    { lat: 48.5257383, lng: 18.3722142 },
    { lat: 48.5258778, lng: 18.3722694 },
    { lat: 48.5259875, lng: 18.3720134 },
    { lat: 48.5259915, lng: 18.3718623 },
    { lat: 48.5259567, lng: 18.3717646 },
    { lat: 48.5259475, lng: 18.3715415 },
    { lat: 48.5258778, lng: 18.3714461 },
    { lat: 48.5258568, lng: 18.3712737 },
    { lat: 48.5259076, lng: 18.3710711 },
    { lat: 48.525822, lng: 18.3707136 },
    { lat: 48.525767, lng: 18.3705989 },
    { lat: 48.5259372, lng: 18.3704164 },
    { lat: 48.5260721, lng: 18.3704063 },
    { lat: 48.5262956, lng: 18.3705102 },
    { lat: 48.5264647, lng: 18.3705271 },
    { lat: 48.5266668, lng: 18.3704679 },
    { lat: 48.526939, lng: 18.3704535 },
    { lat: 48.5271199, lng: 18.3704204 },
    { lat: 48.5272483, lng: 18.3704575 },
    { lat: 48.5272943, lng: 18.3705157 },
    { lat: 48.5275111, lng: 18.3701955 },
    { lat: 48.5284378, lng: 18.3688589 },
    { lat: 48.5286113, lng: 18.3686151 },
    { lat: 48.5287748, lng: 18.3682525 },
    { lat: 48.5290707, lng: 18.3676584 },
    { lat: 48.5295821, lng: 18.3666204 },
    { lat: 48.5299308, lng: 18.3659406 },
    { lat: 48.5302147, lng: 18.3653729 },
    { lat: 48.5304894, lng: 18.3651634 },
    { lat: 48.5312759, lng: 18.3645238 },
    { lat: 48.5314552, lng: 18.3643408 },
    { lat: 48.531538, lng: 18.3642074 },
    { lat: 48.5317118, lng: 18.3638463 },
    { lat: 48.5318571, lng: 18.3635263 },
    { lat: 48.5320203, lng: 18.3632381 },
    { lat: 48.5321959, lng: 18.3627284 },
    { lat: 48.5323942, lng: 18.362246 },
    { lat: 48.5327864, lng: 18.3613345 },
    { lat: 48.5328946, lng: 18.3611107 },
    { lat: 48.5332548, lng: 18.3605763 },
    { lat: 48.5336256, lng: 18.3599483 },
    { lat: 48.5338161, lng: 18.3596086 },
    { lat: 48.5338771, lng: 18.3594491 },
    { lat: 48.5339981, lng: 18.3591713 },
    { lat: 48.5341456, lng: 18.3588054 },
    { lat: 48.5344473, lng: 18.3579132 },
    { lat: 48.5346743, lng: 18.357179 },
    { lat: 48.5348584, lng: 18.35654 },
    { lat: 48.5350403, lng: 18.3559464 },
    { lat: 48.5351204, lng: 18.3555635 },
    { lat: 48.5351186, lng: 18.3544121 },
    { lat: 48.5351795, lng: 18.3544089 },
    { lat: 48.5353806, lng: 18.3545754 },
    { lat: 48.5359991, lng: 18.3550596 },
    { lat: 48.5360814, lng: 18.3552769 },
    { lat: 48.5362641, lng: 18.3554408 },
    { lat: 48.5364363, lng: 18.3556516 },
    { lat: 48.5364834, lng: 18.3557866 },
    { lat: 48.5367372, lng: 18.3560443 },
    { lat: 48.5367558, lng: 18.3559971 },
    { lat: 48.5370625, lng: 18.3553737 },
    { lat: 48.5378122, lng: 18.3540019 },
    { lat: 48.5381345, lng: 18.3533954 },
    { lat: 48.5390048, lng: 18.3539841 },
    { lat: 48.5403214, lng: 18.3548629 },
    { lat: 48.5404069, lng: 18.3546572 },
    { lat: 48.5404343, lng: 18.3544045 },
    { lat: 48.5402974, lng: 18.3543166 },
    { lat: 48.5402827, lng: 18.3540931 },
    { lat: 48.5402904, lng: 18.3539696 },
    { lat: 48.5399848, lng: 18.3537958 },
    { lat: 48.5403401, lng: 18.3537322 },
    { lat: 48.5401266, lng: 18.3529883 },
    { lat: 48.5405357, lng: 18.3526181 },
    { lat: 48.5411125, lng: 18.3521046 },
    { lat: 48.5415518, lng: 18.3517095 },
    { lat: 48.5412889, lng: 18.3513711 },
    { lat: 48.5411306, lng: 18.3505044 },
    { lat: 48.5415065, lng: 18.3502449 },
    { lat: 48.5415843, lng: 18.3502068 },
    { lat: 48.5420066, lng: 18.3503504 },
    { lat: 48.5421393, lng: 18.3504865 },
    { lat: 48.5428053, lng: 18.3512491 },
    { lat: 48.5432041, lng: 18.3516207 },
    { lat: 48.543607, lng: 18.3519815 },
    { lat: 48.5438737, lng: 18.352208 },
    { lat: 48.5447405, lng: 18.3526964 },
    { lat: 48.5467766, lng: 18.3538864 },
    { lat: 48.5469551, lng: 18.3539704 },
    { lat: 48.5475404, lng: 18.3540864 },
    { lat: 48.5483266, lng: 18.353924 },
    { lat: 48.5484916, lng: 18.3538277 },
    { lat: 48.5486295, lng: 18.3537745 },
    { lat: 48.5487803, lng: 18.3537275 },
    { lat: 48.5490244, lng: 18.3535958 },
    { lat: 48.5499195, lng: 18.3530793 },
    { lat: 48.549997, lng: 18.3534943 },
    { lat: 48.5500233, lng: 18.3538385 },
    { lat: 48.5501026, lng: 18.3550284 },
    { lat: 48.5501946, lng: 18.3557381 },
    { lat: 48.5502305, lng: 18.3559543 },
    { lat: 48.5502474, lng: 18.3561376 },
    { lat: 48.550335, lng: 18.3561279 },
    { lat: 48.5506839, lng: 18.3560712 },
    { lat: 48.5510115, lng: 18.3559609 },
  ],
  Kolačno: [
    { lat: 48.5567213, lng: 18.4406277 },
    { lat: 48.5582252, lng: 18.4441058 },
    { lat: 48.5590487, lng: 18.4460104 },
    { lat: 48.5597798, lng: 18.4463969 },
    { lat: 48.5603667, lng: 18.446646 },
    { lat: 48.5612035, lng: 18.4470234 },
    { lat: 48.5620147, lng: 18.4473539 },
    { lat: 48.5629291, lng: 18.4477494 },
    { lat: 48.5638409, lng: 18.4486873 },
    { lat: 48.5644858, lng: 18.4489045 },
    { lat: 48.5651785, lng: 18.4493323 },
    { lat: 48.5657806, lng: 18.4492468 },
    { lat: 48.5663819, lng: 18.4498367 },
    { lat: 48.567215, lng: 18.4506379 },
    { lat: 48.5679309, lng: 18.4513651 },
    { lat: 48.5687716, lng: 18.4511652 },
    { lat: 48.5693762, lng: 18.4512665 },
    { lat: 48.5699267, lng: 18.4509203 },
    { lat: 48.5704956, lng: 18.4509217 },
    { lat: 48.5709147, lng: 18.4506274 },
    { lat: 48.571218, lng: 18.4504011 },
    { lat: 48.5718323, lng: 18.4504046 },
    { lat: 48.5720168, lng: 18.4504056 },
    { lat: 48.5723433, lng: 18.4503933 },
    { lat: 48.5727382, lng: 18.450252 },
    { lat: 48.5734564, lng: 18.4499196 },
    { lat: 48.5740806, lng: 18.4499853 },
    { lat: 48.5743956, lng: 18.4499702 },
    { lat: 48.5748387, lng: 18.4496618 },
    { lat: 48.5757148, lng: 18.4490441 },
    { lat: 48.5761389, lng: 18.4485325 },
    { lat: 48.5763402, lng: 18.4482734 },
    { lat: 48.576559, lng: 18.4479919 },
    { lat: 48.5768079, lng: 18.4475351 },
    { lat: 48.5769796, lng: 18.4470735 },
    { lat: 48.5770915, lng: 18.4466098 },
    { lat: 48.5770986, lng: 18.4464381 },
    { lat: 48.5771083, lng: 18.4462016 },
    { lat: 48.5774457, lng: 18.4453297 },
    { lat: 48.5782668, lng: 18.4450209 },
    { lat: 48.5787682, lng: 18.4449905 },
    { lat: 48.5794516, lng: 18.4452933 },
    { lat: 48.5813581, lng: 18.4460851 },
    { lat: 48.5819931, lng: 18.4463445 },
    { lat: 48.5828113, lng: 18.4466942 },
    { lat: 48.5830292, lng: 18.4453392 },
    { lat: 48.5831111, lng: 18.4448215 },
    { lat: 48.5832372, lng: 18.4440068 },
    { lat: 48.583281, lng: 18.4434928 },
    { lat: 48.5833679, lng: 18.4431288 },
    { lat: 48.5836312, lng: 18.4428123 },
    { lat: 48.5843447, lng: 18.4409914 },
    { lat: 48.584733, lng: 18.4400416 },
    { lat: 48.5851001, lng: 18.439117 },
    { lat: 48.5851738, lng: 18.4391209 },
    { lat: 48.5852636, lng: 18.4391551 },
    { lat: 48.5853596, lng: 18.4392182 },
    { lat: 48.5855533, lng: 18.4394756 },
    { lat: 48.5855798, lng: 18.4395275 },
    { lat: 48.5858084, lng: 18.4389905 },
    { lat: 48.58627, lng: 18.4379599 },
    { lat: 48.5863917, lng: 18.4377339 },
    { lat: 48.5866441, lng: 18.4375235 },
    { lat: 48.5868118, lng: 18.4371945 },
    { lat: 48.5869382, lng: 18.4370142 },
    { lat: 48.5870714, lng: 18.4368706 },
    { lat: 48.587214, lng: 18.4367571 },
    { lat: 48.5873839, lng: 18.4366668 },
    { lat: 48.5880514, lng: 18.43646 },
    { lat: 48.5881092, lng: 18.4367694 },
    { lat: 48.5883991, lng: 18.4367492 },
    { lat: 48.5886634, lng: 18.4366934 },
    { lat: 48.5888179, lng: 18.4366504 },
    { lat: 48.5889785, lng: 18.4366172 },
    { lat: 48.5891324, lng: 18.4365444 },
    { lat: 48.58929, lng: 18.4364585 },
    { lat: 48.5894561, lng: 18.4364233 },
    { lat: 48.5896096, lng: 18.4363013 },
    { lat: 48.5899061, lng: 18.4361352 },
    { lat: 48.5900311, lng: 18.4360492 },
    { lat: 48.5903162, lng: 18.4357987 },
    { lat: 48.5904838, lng: 18.4356285 },
    { lat: 48.5906792, lng: 18.4354107 },
    { lat: 48.5913487, lng: 18.4348907 },
    { lat: 48.5914348, lng: 18.4346328 },
    { lat: 48.5915948, lng: 18.4342075 },
    { lat: 48.5918729, lng: 18.4337711 },
    { lat: 48.5920932, lng: 18.4335116 },
    { lat: 48.5925006, lng: 18.4330873 },
    { lat: 48.5927076, lng: 18.4328527 },
    { lat: 48.5929083, lng: 18.4329332 },
    { lat: 48.5936685, lng: 18.4332551 },
    { lat: 48.5941128, lng: 18.4334734 },
    { lat: 48.5946143, lng: 18.4337304 },
    { lat: 48.5946971, lng: 18.4337784 },
    { lat: 48.5950565, lng: 18.4336681 },
    { lat: 48.5951717, lng: 18.4338524 },
    { lat: 48.595172, lng: 18.4339172 },
    { lat: 48.5952179, lng: 18.4340964 },
    { lat: 48.5951713, lng: 18.4341896 },
    { lat: 48.5952264, lng: 18.4342286 },
    { lat: 48.595305, lng: 18.4342222 },
    { lat: 48.5953145, lng: 18.4342478 },
    { lat: 48.5953516, lng: 18.4342869 },
    { lat: 48.5954375, lng: 18.4342279 },
    { lat: 48.5955509, lng: 18.4342026 },
    { lat: 48.595802, lng: 18.4335432 },
    { lat: 48.5958575, lng: 18.4333844 },
    { lat: 48.5961527, lng: 18.4326268 },
    { lat: 48.5962345, lng: 18.4323945 },
    { lat: 48.596607, lng: 18.431424 },
    { lat: 48.5971071, lng: 18.4300916 },
    { lat: 48.5976224, lng: 18.428727 },
    { lat: 48.5976501, lng: 18.4286593 },
    { lat: 48.5978934, lng: 18.4283071 },
    { lat: 48.5988431, lng: 18.4259929 },
    { lat: 48.5991302, lng: 18.426144 },
    { lat: 48.5993267, lng: 18.4262293 },
    { lat: 48.5995104, lng: 18.4262863 },
    { lat: 48.5998118, lng: 18.425763 },
    { lat: 48.6000324, lng: 18.4254282 },
    { lat: 48.6000655, lng: 18.4253346 },
    { lat: 48.6004253, lng: 18.424465 },
    { lat: 48.6007728, lng: 18.4235941 },
    { lat: 48.6009201, lng: 18.4236022 },
    { lat: 48.6010561, lng: 18.4235529 },
    { lat: 48.6013517, lng: 18.4234317 },
    { lat: 48.6016638, lng: 18.4232842 },
    { lat: 48.6017964, lng: 18.4232154 },
    { lat: 48.60233, lng: 18.4228883 },
    { lat: 48.6025213, lng: 18.4227586 },
    { lat: 48.602706, lng: 18.4225905 },
    { lat: 48.6028544, lng: 18.4223817 },
    { lat: 48.602772, lng: 18.4219755 },
    { lat: 48.6028573, lng: 18.4217829 },
    { lat: 48.6028847, lng: 18.4216468 },
    { lat: 48.603022, lng: 18.421396 },
    { lat: 48.6030175, lng: 18.4212978 },
    { lat: 48.6031919, lng: 18.4211649 },
    { lat: 48.6033333, lng: 18.4208689 },
    { lat: 48.6034605, lng: 18.4206817 },
    { lat: 48.6036691, lng: 18.420599 },
    { lat: 48.6038236, lng: 18.4203773 },
    { lat: 48.6038989, lng: 18.4202309 },
    { lat: 48.6039781, lng: 18.4200102 },
    { lat: 48.6044498, lng: 18.4193662 },
    { lat: 48.6050643, lng: 18.4184464 },
    { lat: 48.6054962, lng: 18.417374 },
    { lat: 48.6058325, lng: 18.4167069 },
    { lat: 48.606116, lng: 18.416164 },
    { lat: 48.6064777, lng: 18.4157528 },
    { lat: 48.6068076, lng: 18.4151741 },
    { lat: 48.6069032, lng: 18.4148448 },
    { lat: 48.6069734, lng: 18.4146491 },
    { lat: 48.6072479, lng: 18.4148584 },
    { lat: 48.6074671, lng: 18.4142364 },
    { lat: 48.6074979, lng: 18.4141598 },
    { lat: 48.6075563, lng: 18.4136522 },
    { lat: 48.6080996, lng: 18.4133392 },
    { lat: 48.6088177, lng: 18.4130445 },
    { lat: 48.6093811, lng: 18.4125289 },
    { lat: 48.6100718, lng: 18.4120049 },
    { lat: 48.610593, lng: 18.4113399 },
    { lat: 48.6097583, lng: 18.4098661 },
    { lat: 48.6095222, lng: 18.4094551 },
    { lat: 48.6092866, lng: 18.4090765 },
    { lat: 48.6090411, lng: 18.4086588 },
    { lat: 48.6080672, lng: 18.4069155 },
    { lat: 48.6073367, lng: 18.4056326 },
    { lat: 48.6073183, lng: 18.4056845 },
    { lat: 48.6070695, lng: 18.4053895 },
    { lat: 48.6067619, lng: 18.4051362 },
    { lat: 48.6064164, lng: 18.4049684 },
    { lat: 48.6062774, lng: 18.404863 },
    { lat: 48.6059928, lng: 18.4044522 },
    { lat: 48.6058913, lng: 18.404295 },
    { lat: 48.6056744, lng: 18.4034004 },
    { lat: 48.6052931, lng: 18.4022069 },
    { lat: 48.6052059, lng: 18.4018978 },
    { lat: 48.6050635, lng: 18.4014308 },
    { lat: 48.6049389, lng: 18.4011187 },
    { lat: 48.6048499, lng: 18.4008797 },
    { lat: 48.604761, lng: 18.4007395 },
    { lat: 48.6046366, lng: 18.4005953 },
    { lat: 48.6045086, lng: 18.4003645 },
    { lat: 48.6041218, lng: 18.4001235 },
    { lat: 48.6040216, lng: 18.4000689 },
    { lat: 48.6038309, lng: 18.3998838 },
    { lat: 48.6036551, lng: 18.3996267 },
    { lat: 48.603292, lng: 18.3990865 },
    { lat: 48.6032858, lng: 18.3990727 },
    { lat: 48.6029658, lng: 18.3991219 },
    { lat: 48.6027452, lng: 18.3991121 },
    { lat: 48.6024977, lng: 18.3991258 },
    { lat: 48.6023172, lng: 18.3990856 },
    { lat: 48.6020328, lng: 18.3990594 },
    { lat: 48.6016691, lng: 18.3990147 },
    { lat: 48.6013172, lng: 18.3989546 },
    { lat: 48.6010448, lng: 18.3988634 },
    { lat: 48.6007327, lng: 18.398829 },
    { lat: 48.6003858, lng: 18.3988239 },
    { lat: 48.6000442, lng: 18.3986256 },
    { lat: 48.599893, lng: 18.3984402 },
    { lat: 48.5997164, lng: 18.3980481 },
    { lat: 48.5994514, lng: 18.3976256 },
    { lat: 48.5991021, lng: 18.3972088 },
    { lat: 48.5989499, lng: 18.3970706 },
    { lat: 48.5985624, lng: 18.3968201 },
    { lat: 48.5984466, lng: 18.3967061 },
    { lat: 48.5982516, lng: 18.3963863 },
    { lat: 48.5979842, lng: 18.396103 },
    { lat: 48.5978934, lng: 18.395993 },
    { lat: 48.5977944, lng: 18.3958167 },
    { lat: 48.597655, lng: 18.3955012 },
    { lat: 48.5975769, lng: 18.3951141 },
    { lat: 48.5975549, lng: 18.3945997 },
    { lat: 48.5975545, lng: 18.3943506 },
    { lat: 48.5975835, lng: 18.3940615 },
    { lat: 48.5975772, lng: 18.3938698 },
    { lat: 48.5975434, lng: 18.3934471 },
    { lat: 48.5975258, lng: 18.393315 },
    { lat: 48.5974515, lng: 18.3931573 },
    { lat: 48.5973272, lng: 18.3929934 },
    { lat: 48.5971794, lng: 18.3926077 },
    { lat: 48.5971549, lng: 18.3923132 },
    { lat: 48.5971131, lng: 18.3920281 },
    { lat: 48.5968719, lng: 18.3918531 },
    { lat: 48.5962498, lng: 18.3917034 },
    { lat: 48.595703, lng: 18.39181 },
    { lat: 48.5953622, lng: 18.3910807 },
    { lat: 48.5948554, lng: 18.3902132 },
    { lat: 48.5943556, lng: 18.3898277 },
    { lat: 48.5942356, lng: 18.38956 },
    { lat: 48.5941791, lng: 18.3887749 },
    { lat: 48.5941274, lng: 18.3885552 },
    { lat: 48.5939283, lng: 18.3881204 },
    { lat: 48.5937086, lng: 18.387946 },
    { lat: 48.5929787, lng: 18.3875225 },
    { lat: 48.5922983, lng: 18.3872823 },
    { lat: 48.5917176, lng: 18.3869859 },
    { lat: 48.5913775, lng: 18.386773 },
    { lat: 48.5909547, lng: 18.3863949 },
    { lat: 48.5907245, lng: 18.3862945 },
    { lat: 48.5903515, lng: 18.386322 },
    { lat: 48.5898299, lng: 18.3861136 },
    { lat: 48.5896049, lng: 18.386144 },
    { lat: 48.5892757, lng: 18.3863527 },
    { lat: 48.5885549, lng: 18.3863116 },
    { lat: 48.5884425, lng: 18.3862796 },
    { lat: 48.5882934, lng: 18.3860751 },
    { lat: 48.5881834, lng: 18.3855207 },
    { lat: 48.5881157, lng: 18.3854442 },
    { lat: 48.5879955, lng: 18.3854124 },
    { lat: 48.5873286, lng: 18.3856117 },
    { lat: 48.5871401, lng: 18.3855288 },
    { lat: 48.5865831, lng: 18.3847488 },
    { lat: 48.5859844, lng: 18.3836886 },
    { lat: 48.5854672, lng: 18.3819696 },
    { lat: 48.584772, lng: 18.3805181 },
    { lat: 48.5844628, lng: 18.3801773 },
    { lat: 48.5841972, lng: 18.3797443 },
    { lat: 48.5840153, lng: 18.379217 },
    { lat: 48.5840033, lng: 18.3789014 },
    { lat: 48.5839441, lng: 18.3787094 },
    { lat: 48.5825796, lng: 18.377153 },
    { lat: 48.5818563, lng: 18.3764695 },
    { lat: 48.5811074, lng: 18.3759115 },
    { lat: 48.5808667, lng: 18.3759643 },
    { lat: 48.5806074, lng: 18.3758694 },
    { lat: 48.5801495, lng: 18.3758693 },
    { lat: 48.5799826, lng: 18.375923 },
    { lat: 48.5798446, lng: 18.3759674 },
    { lat: 48.5794525, lng: 18.3762771 },
    { lat: 48.579268, lng: 18.3759432 },
    { lat: 48.5791609, lng: 18.3758391 },
    { lat: 48.5791067, lng: 18.3757864 },
    { lat: 48.579086, lng: 18.3757506 },
    { lat: 48.5786917, lng: 18.3758899 },
    { lat: 48.5783699, lng: 18.3761044 },
    { lat: 48.5782562, lng: 18.3764342 },
    { lat: 48.5781535, lng: 18.3770544 },
    { lat: 48.5781622, lng: 18.3778336 },
    { lat: 48.5781269, lng: 18.3785482 },
    { lat: 48.5780205, lng: 18.3793281 },
    { lat: 48.5779838, lng: 18.380102 },
    { lat: 48.5777656, lng: 18.3802527 },
    { lat: 48.577492, lng: 18.3804461 },
    { lat: 48.5771405, lng: 18.3807175 },
    { lat: 48.5766769, lng: 18.381038 },
    { lat: 48.5762614, lng: 18.3813136 },
    { lat: 48.5756635, lng: 18.381698 },
    { lat: 48.5751267, lng: 18.3820663 },
    { lat: 48.5748499, lng: 18.3823196 },
    { lat: 48.5745642, lng: 18.3825754 },
    { lat: 48.5741074, lng: 18.3829795 },
    { lat: 48.5737925, lng: 18.3832401 },
    { lat: 48.5732309, lng: 18.3835674 },
    { lat: 48.5727683, lng: 18.3838682 },
    { lat: 48.5722984, lng: 18.3842194 },
    { lat: 48.5720482, lng: 18.3844038 },
    { lat: 48.5714399, lng: 18.3849065 },
    { lat: 48.5708559, lng: 18.3853139 },
    { lat: 48.5701778, lng: 18.3857551 },
    { lat: 48.5700492, lng: 18.3858362 },
    { lat: 48.5696069, lng: 18.3862409 },
    { lat: 48.5693062, lng: 18.3865503 },
    { lat: 48.5691129, lng: 18.3867462 },
    { lat: 48.5687016, lng: 18.3871772 },
    { lat: 48.5684947, lng: 18.3873706 },
    { lat: 48.5682121, lng: 18.3876434 },
    { lat: 48.5677952, lng: 18.3880552 },
    { lat: 48.5672756, lng: 18.3885658 },
    { lat: 48.5668009, lng: 18.3890137 },
    { lat: 48.5660467, lng: 18.3897091 },
    { lat: 48.5656098, lng: 18.3901092 },
    { lat: 48.5650302, lng: 18.3906178 },
    { lat: 48.5646429, lng: 18.3910129 },
    { lat: 48.5640222, lng: 18.3915943 },
    { lat: 48.5635991, lng: 18.3919954 },
    { lat: 48.563095, lng: 18.3924851 },
    { lat: 48.5621188, lng: 18.3934669 },
    { lat: 48.561164, lng: 18.3943839 },
    { lat: 48.5606263, lng: 18.3954006 },
    { lat: 48.5601362, lng: 18.3963586 },
    { lat: 48.5599087, lng: 18.3967711 },
    { lat: 48.5596908, lng: 18.3971307 },
    { lat: 48.5594641, lng: 18.3975744 },
    { lat: 48.5593008, lng: 18.3977836 },
    { lat: 48.5590013, lng: 18.3982572 },
    { lat: 48.5587574, lng: 18.3987351 },
    { lat: 48.5586134, lng: 18.398903 },
    { lat: 48.5581363, lng: 18.3992705 },
    { lat: 48.5575653, lng: 18.3996293 },
    { lat: 48.5574657, lng: 18.3997673 },
    { lat: 48.55734, lng: 18.4000985 },
    { lat: 48.5573009, lng: 18.4004083 },
    { lat: 48.5571554, lng: 18.4009528 },
    { lat: 48.5569284, lng: 18.4011717 },
    { lat: 48.5566759, lng: 18.4023242 },
    { lat: 48.5566374, lng: 18.4036941 },
    { lat: 48.5566101, lng: 18.4039126 },
    { lat: 48.5565821, lng: 18.4040754 },
    { lat: 48.5565426, lng: 18.4042196 },
    { lat: 48.5565121, lng: 18.4044179 },
    { lat: 48.5565313, lng: 18.405428 },
    { lat: 48.5565669, lng: 18.4060862 },
    { lat: 48.5564447, lng: 18.4067053 },
    { lat: 48.5562505, lng: 18.4076299 },
    { lat: 48.5561294, lng: 18.4081691 },
    { lat: 48.5558263, lng: 18.4095857 },
    { lat: 48.555774, lng: 18.4099823 },
    { lat: 48.555847, lng: 18.4104441 },
    { lat: 48.5558647, lng: 18.410439 },
    { lat: 48.5559599, lng: 18.410928 },
    { lat: 48.5560023, lng: 18.411141 },
    { lat: 48.5562135, lng: 18.4124425 },
    { lat: 48.5562468, lng: 18.4129967 },
    { lat: 48.5562955, lng: 18.4139919 },
    { lat: 48.5563591, lng: 18.4147834 },
    { lat: 48.556416, lng: 18.4151159 },
    { lat: 48.5564953, lng: 18.4154064 },
    { lat: 48.5566362, lng: 18.4158091 },
    { lat: 48.5566962, lng: 18.4165191 },
    { lat: 48.5568523, lng: 18.4177401 },
    { lat: 48.5568055, lng: 18.4183974 },
    { lat: 48.556861, lng: 18.4200413 },
    { lat: 48.5566249, lng: 18.4209661 },
    { lat: 48.5564881, lng: 18.4212644 },
    { lat: 48.5561725, lng: 18.4216497 },
    { lat: 48.5559212, lng: 18.422002 },
    { lat: 48.5553504, lng: 18.422326 },
    { lat: 48.5549463, lng: 18.4226471 },
    { lat: 48.5547464, lng: 18.4228009 },
    { lat: 48.554357, lng: 18.4233056 },
    { lat: 48.5542429, lng: 18.4235661 },
    { lat: 48.5537458, lng: 18.4252919 },
    { lat: 48.55368, lng: 18.4256951 },
    { lat: 48.5536729, lng: 18.4267212 },
    { lat: 48.5537373, lng: 18.427024 },
    { lat: 48.5539435, lng: 18.4277076 },
    { lat: 48.5539608, lng: 18.4280191 },
    { lat: 48.5539284, lng: 18.4281878 },
    { lat: 48.5534934, lng: 18.4290233 },
    { lat: 48.5535149, lng: 18.42933 },
    { lat: 48.5537904, lng: 18.4299629 },
    { lat: 48.553918, lng: 18.4303694 },
    { lat: 48.5541659, lng: 18.4309916 },
    { lat: 48.5542395, lng: 18.4311589 },
    { lat: 48.5545158, lng: 18.431401 },
    { lat: 48.554775, lng: 18.4316193 },
    { lat: 48.5549504, lng: 18.4322349 },
    { lat: 48.5550427, lng: 18.4329202 },
    { lat: 48.5550947, lng: 18.4335314 },
    { lat: 48.5551611, lng: 18.433751 },
    { lat: 48.55552, lng: 18.4342412 },
    { lat: 48.5557327, lng: 18.4351837 },
    { lat: 48.5561665, lng: 18.4363896 },
    { lat: 48.5562865, lng: 18.4365414 },
    { lat: 48.556479, lng: 18.4371026 },
    { lat: 48.5566211, lng: 18.4373635 },
    { lat: 48.5568232, lng: 18.4376898 },
    { lat: 48.5569134, lng: 18.4379197 },
    { lat: 48.5570031, lng: 18.4381482 },
    { lat: 48.5569579, lng: 18.4389983 },
    { lat: 48.5568666, lng: 18.4393787 },
    { lat: 48.5567786, lng: 18.4397455 },
    { lat: 48.5567683, lng: 18.4397885 },
    { lat: 48.5567213, lng: 18.4406277 },
  ],
  Krásno: [
    { lat: 48.5889861, lng: 18.3121689 },
    { lat: 48.5889915, lng: 18.312263 },
    { lat: 48.5887172, lng: 18.3125977 },
    { lat: 48.5885321, lng: 18.3130139 },
    { lat: 48.5881805, lng: 18.3144366 },
    { lat: 48.5881631, lng: 18.314669 },
    { lat: 48.5881521, lng: 18.3152083 },
    { lat: 48.5881164, lng: 18.3156558 },
    { lat: 48.588082, lng: 18.3158452 },
    { lat: 48.5879576, lng: 18.3161994 },
    { lat: 48.587775, lng: 18.3165775 },
    { lat: 48.5877521, lng: 18.3165748 },
    { lat: 48.5875243, lng: 18.3169936 },
    { lat: 48.5873719, lng: 18.3173126 },
    { lat: 48.5872787, lng: 18.3176375 },
    { lat: 48.5872233, lng: 18.3178745 },
    { lat: 48.5871571, lng: 18.3182208 },
    { lat: 48.5871161, lng: 18.3186327 },
    { lat: 48.5871347, lng: 18.3190202 },
    { lat: 48.5871772, lng: 18.3193537 },
    { lat: 48.5872894, lng: 18.319861 },
    { lat: 48.5874828, lng: 18.3202873 },
    { lat: 48.5875988, lng: 18.3205817 },
    { lat: 48.5881182, lng: 18.3221425 },
    { lat: 48.5881966, lng: 18.3225722 },
    { lat: 48.5881954, lng: 18.3229109 },
    { lat: 48.5881753, lng: 18.3232731 },
    { lat: 48.5881669, lng: 18.3237435 },
    { lat: 48.588201, lng: 18.324025 },
    { lat: 48.5882449, lng: 18.3242823 },
    { lat: 48.5883515, lng: 18.3245912 },
    { lat: 48.5886861, lng: 18.3253401 },
    { lat: 48.5887732, lng: 18.3255637 },
    { lat: 48.5889679, lng: 18.3259756 },
    { lat: 48.5890874, lng: 18.3261142 },
    { lat: 48.589322, lng: 18.3265877 },
    { lat: 48.5895612, lng: 18.3271478 },
    { lat: 48.5898074, lng: 18.3278304 },
    { lat: 48.589856, lng: 18.3279913 },
    { lat: 48.5899543, lng: 18.3282324 },
    { lat: 48.5900703, lng: 18.3284709 },
    { lat: 48.5901424, lng: 18.3286082 },
    { lat: 48.5910165, lng: 18.3300594 },
    { lat: 48.5920657, lng: 18.3317366 },
    { lat: 48.5929933, lng: 18.3330767 },
    { lat: 48.5930537, lng: 18.3331143 },
    { lat: 48.5942768, lng: 18.3305724 },
    { lat: 48.5951625, lng: 18.3309508 },
    { lat: 48.5959843, lng: 18.3298779 },
    { lat: 48.5965271, lng: 18.3300028 },
    { lat: 48.5971612, lng: 18.3302422 },
    { lat: 48.5976064, lng: 18.3301319 },
    { lat: 48.5980582, lng: 18.3301205 },
    { lat: 48.598656, lng: 18.3296577 },
    { lat: 48.5987617, lng: 18.3294317 },
    { lat: 48.599189, lng: 18.326832 },
    { lat: 48.5998683, lng: 18.32472 },
    { lat: 48.6004423, lng: 18.3233989 },
    { lat: 48.6007191, lng: 18.3226451 },
    { lat: 48.6017334, lng: 18.3217678 },
    { lat: 48.6028005, lng: 18.320725 },
    { lat: 48.6082712, lng: 18.3159746 },
    { lat: 48.6049123, lng: 18.3058431 },
    { lat: 48.6050469, lng: 18.3045184 },
    { lat: 48.6050953, lng: 18.3041543 },
    { lat: 48.6051739, lng: 18.3040308 },
    { lat: 48.6053124, lng: 18.3039469 },
    { lat: 48.6054259, lng: 18.303344 },
    { lat: 48.6055311, lng: 18.3029893 },
    { lat: 48.6055909, lng: 18.3026297 },
    { lat: 48.605667, lng: 18.3022423 },
    { lat: 48.6056799, lng: 18.3021102 },
    { lat: 48.6056274, lng: 18.3018393 },
    { lat: 48.6055887, lng: 18.301538 },
    { lat: 48.6055011, lng: 18.3013054 },
    { lat: 48.605317, lng: 18.3011035 },
    { lat: 48.6052153, lng: 18.3009402 },
    { lat: 48.6050876, lng: 18.3006023 },
    { lat: 48.6050598, lng: 18.3001495 },
    { lat: 48.6047965, lng: 18.2991318 },
    { lat: 48.6045, lng: 18.2986364 },
    { lat: 48.6042865, lng: 18.2983353 },
    { lat: 48.6040355, lng: 18.2977092 },
    { lat: 48.6039274, lng: 18.2969581 },
    { lat: 48.6038474, lng: 18.2967025 },
    { lat: 48.6037018, lng: 18.2965042 },
    { lat: 48.6036318, lng: 18.2961842 },
    { lat: 48.6036359, lng: 18.2956813 },
    { lat: 48.6029779, lng: 18.2958979 },
    { lat: 48.6023186, lng: 18.2961655 },
    { lat: 48.6022522, lng: 18.2961923 },
    { lat: 48.6020195, lng: 18.2962869 },
    { lat: 48.6012607, lng: 18.2965937 },
    { lat: 48.600592, lng: 18.2968587 },
    { lat: 48.6000199, lng: 18.2971757 },
    { lat: 48.599887, lng: 18.2973169 },
    { lat: 48.5996588, lng: 18.2976508 },
    { lat: 48.5992347, lng: 18.2983304 },
    { lat: 48.5981121, lng: 18.2997999 },
    { lat: 48.5979231, lng: 18.3000471 },
    { lat: 48.5968287, lng: 18.3014983 },
    { lat: 48.5963164, lng: 18.3021151 },
    { lat: 48.5957215, lng: 18.3028651 },
    { lat: 48.5955119, lng: 18.3030508 },
    { lat: 48.5954869, lng: 18.3030679 },
    { lat: 48.5954077, lng: 18.303122 },
    { lat: 48.5954255, lng: 18.3031575 },
    { lat: 48.5939357, lng: 18.3047186 },
    { lat: 48.5916808, lng: 18.3071781 },
    { lat: 48.5907825, lng: 18.3082266 },
    { lat: 48.5905793, lng: 18.3084827 },
    { lat: 48.5903741, lng: 18.3087668 },
    { lat: 48.5903326, lng: 18.3088901 },
    { lat: 48.5902323, lng: 18.3095282 },
    { lat: 48.590191, lng: 18.3099747 },
    { lat: 48.590139, lng: 18.3103132 },
    { lat: 48.5900773, lng: 18.3105764 },
    { lat: 48.5899205, lng: 18.3109348 },
    { lat: 48.5892906, lng: 18.3119265 },
    { lat: 48.5889861, lng: 18.3121689 },
  ],
  Livina: [
    { lat: 48.648998, lng: 18.257611 },
    { lat: 48.648928, lng: 18.257206 },
    { lat: 48.648884, lng: 18.256897 },
    { lat: 48.648889, lng: 18.256057 },
    { lat: 48.648898, lng: 18.255576 },
    { lat: 48.648912, lng: 18.255207 },
    { lat: 48.648924, lng: 18.254887 },
    { lat: 48.648943, lng: 18.254858 },
    { lat: 48.649179, lng: 18.25449 },
    { lat: 48.649458, lng: 18.253859 },
    { lat: 48.649561, lng: 18.253497 },
    { lat: 48.649665, lng: 18.252896 },
    { lat: 48.649695, lng: 18.25283 },
    { lat: 48.649692, lng: 18.252809 },
    { lat: 48.649695, lng: 18.252473 },
    { lat: 48.649703, lng: 18.25236 },
    { lat: 48.649713, lng: 18.252286 },
    { lat: 48.649738, lng: 18.252156 },
    { lat: 48.649757, lng: 18.252069 },
    { lat: 48.649773, lng: 18.252003 },
    { lat: 48.649782, lng: 18.251969 },
    { lat: 48.649826, lng: 18.251802 },
    { lat: 48.649872, lng: 18.25163 },
    { lat: 48.649914, lng: 18.251499 },
    { lat: 48.649959, lng: 18.251354 },
    { lat: 48.650113, lng: 18.250851 },
    { lat: 48.650258, lng: 18.250372 },
    { lat: 48.650308, lng: 18.250172 },
    { lat: 48.650321, lng: 18.250107 },
    { lat: 48.65035, lng: 18.249985 },
    { lat: 48.650363, lng: 18.249877 },
    { lat: 48.650365, lng: 18.249835 },
    { lat: 48.650367, lng: 18.249774 },
    { lat: 48.650354, lng: 18.249618 },
    { lat: 48.650331, lng: 18.249332 },
    { lat: 48.650327, lng: 18.249161 },
    { lat: 48.650332, lng: 18.248999 },
    { lat: 48.650341, lng: 18.248898 },
    { lat: 48.650354, lng: 18.248764 },
    { lat: 48.650381, lng: 18.248606 },
    { lat: 48.650384, lng: 18.248592 },
    { lat: 48.650433, lng: 18.248423 },
    { lat: 48.6504828, lng: 18.2482681 },
    { lat: 48.6504044, lng: 18.2482231 },
    { lat: 48.6500378, lng: 18.2480692 },
    { lat: 48.6498142, lng: 18.2479877 },
    { lat: 48.649745, lng: 18.2479589 },
    { lat: 48.6495463, lng: 18.2478798 },
    { lat: 48.6491719, lng: 18.2477038 },
    { lat: 48.6489457, lng: 18.2475987 },
    { lat: 48.6487405, lng: 18.2475034 },
    { lat: 48.6485205, lng: 18.2474225 },
    { lat: 48.6483677, lng: 18.2473564 },
    { lat: 48.6481895, lng: 18.2472797 },
    { lat: 48.6479462, lng: 18.2471675 },
    { lat: 48.6478094, lng: 18.2471026 },
    { lat: 48.6476457, lng: 18.2470226 },
    { lat: 48.6474708, lng: 18.2469258 },
    { lat: 48.6474354, lng: 18.2471541 },
    { lat: 48.6473816, lng: 18.2473171 },
    { lat: 48.6472172, lng: 18.2477585 },
    { lat: 48.6470081, lng: 18.2475748 },
    { lat: 48.6467711, lng: 18.2470394 },
    { lat: 48.6464144, lng: 18.2461324 },
    { lat: 48.6462028, lng: 18.2457268 },
    { lat: 48.6458688, lng: 18.2450959 },
    { lat: 48.6458105, lng: 18.2449619 },
    { lat: 48.6455874, lng: 18.244613 },
    { lat: 48.6455096, lng: 18.2444962 },
    { lat: 48.6451468, lng: 18.2439529 },
    { lat: 48.6449642, lng: 18.2437216 },
    { lat: 48.6448273, lng: 18.2436131 },
    { lat: 48.6446266, lng: 18.2434408 },
    { lat: 48.6440241, lng: 18.2429488 },
    { lat: 48.6437916, lng: 18.2427126 },
    { lat: 48.6436851, lng: 18.2425855 },
    { lat: 48.64345, lng: 18.2422622 },
    { lat: 48.643297, lng: 18.2420523 },
    { lat: 48.6432859, lng: 18.2420349 },
    { lat: 48.6432387, lng: 18.2419636 },
    { lat: 48.6430902, lng: 18.2416932 },
    { lat: 48.6430384, lng: 18.241558 },
    { lat: 48.6429958, lng: 18.2414458 },
    { lat: 48.64293, lng: 18.2412414 },
    { lat: 48.6428975, lng: 18.2410861 },
    { lat: 48.642883, lng: 18.2408921 },
    { lat: 48.642851, lng: 18.240588 },
    { lat: 48.6428404, lng: 18.240502 },
    { lat: 48.6427899, lng: 18.2401946 },
    { lat: 48.642727, lng: 18.2399006 },
    { lat: 48.642648, lng: 18.2395665 },
    { lat: 48.642455, lng: 18.2388374 },
    { lat: 48.6423617, lng: 18.2384896 },
    { lat: 48.6422794, lng: 18.2382538 },
    { lat: 48.6421979, lng: 18.2380735 },
    { lat: 48.6420788, lng: 18.2378429 },
    { lat: 48.6420314, lng: 18.2377627 },
    { lat: 48.6419769, lng: 18.2376781 },
    { lat: 48.6415382, lng: 18.2370335 },
    { lat: 48.6412619, lng: 18.2371177 },
    { lat: 48.6410097, lng: 18.2372212 },
    { lat: 48.6408635, lng: 18.237311 },
    { lat: 48.6404959, lng: 18.2375323 },
    { lat: 48.6403278, lng: 18.2376513 },
    { lat: 48.6400731, lng: 18.2378946 },
    { lat: 48.6399526, lng: 18.2380363 },
    { lat: 48.6395839, lng: 18.2384515 },
    { lat: 48.6393365, lng: 18.2387228 },
    { lat: 48.6391813, lng: 18.2388911 },
    { lat: 48.6389034, lng: 18.2391417 },
    { lat: 48.6386072, lng: 18.2394279 },
    { lat: 48.6381603, lng: 18.2398779 },
    { lat: 48.6379418, lng: 18.240104 },
    { lat: 48.6378595, lng: 18.2401833 },
    { lat: 48.6372379, lng: 18.2410142 },
    { lat: 48.6369918, lng: 18.2413281 },
    { lat: 48.6366999, lng: 18.2417679 },
    { lat: 48.6366139, lng: 18.2418804 },
    { lat: 48.6364838, lng: 18.2420126 },
    { lat: 48.6363141, lng: 18.2421242 },
    { lat: 48.636163, lng: 18.2425378 },
    { lat: 48.6358867, lng: 18.2431713 },
    { lat: 48.6357024, lng: 18.2435785 },
    { lat: 48.6354642, lng: 18.2441097 },
    { lat: 48.6353546, lng: 18.2442859 },
    { lat: 48.6353142, lng: 18.2443697 },
    { lat: 48.6350346, lng: 18.2449465 },
    { lat: 48.6348647, lng: 18.245365 },
    { lat: 48.6346408, lng: 18.2460895 },
    { lat: 48.6344516, lng: 18.2460338 },
    { lat: 48.634274, lng: 18.2460463 },
    { lat: 48.6337845, lng: 18.2460904 },
    { lat: 48.633627, lng: 18.2461194 },
    { lat: 48.6335588, lng: 18.2462079 },
    { lat: 48.6334538, lng: 18.2466207 },
    { lat: 48.6336201, lng: 18.246689 },
    { lat: 48.6335689, lng: 18.2467606 },
    { lat: 48.6334419, lng: 18.2468881 },
    { lat: 48.6334008, lng: 18.2469762 },
    { lat: 48.6333594, lng: 18.2471992 },
    { lat: 48.6333213, lng: 18.2474624 },
    { lat: 48.6335967, lng: 18.2476199 },
    { lat: 48.6335386, lng: 18.2478854 },
    { lat: 48.633433, lng: 18.2482298 },
    { lat: 48.6332811, lng: 18.2487522 },
    { lat: 48.6331349, lng: 18.249194 },
    { lat: 48.6329804, lng: 18.2495893 },
    { lat: 48.6328735, lng: 18.2498911 },
    { lat: 48.6327686, lng: 18.2502851 },
    { lat: 48.6326957, lng: 18.2503755 },
    { lat: 48.6326552, lng: 18.2504154 },
    { lat: 48.6325843, lng: 18.2504778 },
    { lat: 48.6324834, lng: 18.2505662 },
    { lat: 48.6324299, lng: 18.2506598 },
    { lat: 48.6323261, lng: 18.2509733 },
    { lat: 48.6322534, lng: 18.2511836 },
    { lat: 48.6321244, lng: 18.2515322 },
    { lat: 48.6319813, lng: 18.25187 },
    { lat: 48.6319183, lng: 18.2520512 },
    { lat: 48.6317775, lng: 18.2524712 },
    { lat: 48.6315437, lng: 18.252939 },
    { lat: 48.6313892, lng: 18.2532566 },
    { lat: 48.6313527, lng: 18.2533287 },
    { lat: 48.6312484, lng: 18.2536391 },
    { lat: 48.6311607, lng: 18.2539576 },
    { lat: 48.6310294, lng: 18.2540307 },
    { lat: 48.6309172, lng: 18.254534 },
    { lat: 48.6308329, lng: 18.2549366 },
    { lat: 48.6307172, lng: 18.2554778 },
    { lat: 48.630537, lng: 18.2562928 },
    { lat: 48.6304356, lng: 18.256769 },
    { lat: 48.6302566, lng: 18.2576269 },
    { lat: 48.6300996, lng: 18.2583967 },
    { lat: 48.629992, lng: 18.2587846 },
    { lat: 48.6298425, lng: 18.2594468 },
    { lat: 48.6298765, lng: 18.2596064 },
    { lat: 48.6298137, lng: 18.259733 },
    { lat: 48.6298432, lng: 18.2598323 },
    { lat: 48.6298612, lng: 18.2598528 },
    { lat: 48.6301472, lng: 18.2598224 },
    { lat: 48.6301609, lng: 18.2598431 },
    { lat: 48.6301934, lng: 18.259882 },
    { lat: 48.630205, lng: 18.2599443 },
    { lat: 48.6302145, lng: 18.2601108 },
    { lat: 48.6302645, lng: 18.2601949 },
    { lat: 48.6302733, lng: 18.2601916 },
    { lat: 48.6303252, lng: 18.2601938 },
    { lat: 48.6303786, lng: 18.2601608 },
    { lat: 48.6304598, lng: 18.2600999 },
    { lat: 48.6305013, lng: 18.2601349 },
    { lat: 48.6305392, lng: 18.2601652 },
    { lat: 48.6305688, lng: 18.260211 },
    { lat: 48.6306221, lng: 18.2602925 },
    { lat: 48.630677, lng: 18.2604045 },
    { lat: 48.6307567, lng: 18.2605953 },
    { lat: 48.6308332, lng: 18.2608508 },
    { lat: 48.6308969, lng: 18.2616312 },
    { lat: 48.6309168, lng: 18.2618976 },
    { lat: 48.6309503, lng: 18.2622681 },
    { lat: 48.630956, lng: 18.2624051 },
    { lat: 48.6309576, lng: 18.2625754 },
    { lat: 48.6309892, lng: 18.2633653 },
    { lat: 48.6310396, lng: 18.2636348 },
    { lat: 48.6310835, lng: 18.2638318 },
    { lat: 48.6311459, lng: 18.2640769 },
    { lat: 48.6311688, lng: 18.2641999 },
    { lat: 48.6312207, lng: 18.2645334 },
    { lat: 48.6312265, lng: 18.2646568 },
    { lat: 48.631399, lng: 18.2650682 },
    { lat: 48.6315808, lng: 18.2654073 },
    { lat: 48.6316549, lng: 18.2655208 },
    { lat: 48.6318595, lng: 18.2657264 },
    { lat: 48.632068, lng: 18.2660365 },
    { lat: 48.6324414, lng: 18.2663939 },
    { lat: 48.6326346, lng: 18.2669507 },
    { lat: 48.6329165, lng: 18.2676396 },
    { lat: 48.632965, lng: 18.2676597 },
    { lat: 48.6339568, lng: 18.2679584 },
    { lat: 48.6347902, lng: 18.2682052 },
    { lat: 48.6349823, lng: 18.2681324 },
    { lat: 48.6353095, lng: 18.2679544 },
    { lat: 48.6359566, lng: 18.2675668 },
    { lat: 48.6362387, lng: 18.267389 },
    { lat: 48.6367699, lng: 18.2669115 },
    { lat: 48.6372692, lng: 18.266561 },
    { lat: 48.6377642, lng: 18.2660822 },
    { lat: 48.6379412, lng: 18.2662754 },
    { lat: 48.6380239, lng: 18.2663417 },
    { lat: 48.6383885, lng: 18.2665234 },
    { lat: 48.6394432, lng: 18.2663401 },
    { lat: 48.6396477, lng: 18.2662854 },
    { lat: 48.6398737, lng: 18.266073 },
    { lat: 48.6400425, lng: 18.2658702 },
    { lat: 48.6407539, lng: 18.2651071 },
    { lat: 48.6413655, lng: 18.2645913 },
    { lat: 48.6417861, lng: 18.2643298 },
    { lat: 48.6418587, lng: 18.2642583 },
    { lat: 48.6420654, lng: 18.2640241 },
    { lat: 48.642171, lng: 18.2639177 },
    { lat: 48.6424945, lng: 18.2636954 },
    { lat: 48.6426241, lng: 18.2635716 },
    { lat: 48.6427188, lng: 18.2634126 },
    { lat: 48.6428165, lng: 18.2631887 },
    { lat: 48.6429385, lng: 18.2630109 },
    { lat: 48.6433083, lng: 18.2646473 },
    { lat: 48.6441486, lng: 18.264307 },
    { lat: 48.6443267, lng: 18.2642267 },
    { lat: 48.6445576, lng: 18.2640993 },
    { lat: 48.6450822, lng: 18.2638007 },
    { lat: 48.6452199, lng: 18.2644736 },
    { lat: 48.6461033, lng: 18.2642754 },
    { lat: 48.6461832, lng: 18.26427 },
    { lat: 48.6462244, lng: 18.2642218 },
    { lat: 48.6463392, lng: 18.2638804 },
    { lat: 48.6463471, lng: 18.2638194 },
    { lat: 48.6463488, lng: 18.2636137 },
    { lat: 48.6463277, lng: 18.2634787 },
    { lat: 48.6460514, lng: 18.2630457 },
    { lat: 48.6459112, lng: 18.2627478 },
    { lat: 48.6458766, lng: 18.2626335 },
    { lat: 48.6458446, lng: 18.2624303 },
    { lat: 48.6458346, lng: 18.2621344 },
    { lat: 48.6458829, lng: 18.261995 },
    { lat: 48.6459333, lng: 18.2619082 },
    { lat: 48.6460164, lng: 18.2618189 },
    { lat: 48.6460877, lng: 18.2618054 },
    { lat: 48.6461525, lng: 18.2618123 },
    { lat: 48.6463104, lng: 18.2620276 },
    { lat: 48.6463824, lng: 18.2621053 },
    { lat: 48.6466479, lng: 18.2622703 },
    { lat: 48.646734, lng: 18.2622955 },
    { lat: 48.6468121, lng: 18.2623 },
    { lat: 48.646925, lng: 18.2622821 },
    { lat: 48.6471456, lng: 18.2623691 },
    { lat: 48.6472499, lng: 18.2623577 },
    { lat: 48.6473634, lng: 18.2622908 },
    { lat: 48.6476035, lng: 18.2619899 },
    { lat: 48.6477186, lng: 18.2616955 },
    { lat: 48.6477361, lng: 18.2615666 },
    { lat: 48.6477746, lng: 18.2609164 },
    { lat: 48.6478621, lng: 18.2606092 },
    { lat: 48.6479625, lng: 18.2602715 },
    { lat: 48.6479913, lng: 18.260105 },
    { lat: 48.648003, lng: 18.2598965 },
    { lat: 48.648, lng: 18.2597649 },
    { lat: 48.647944, lng: 18.2593561 },
    { lat: 48.6479688, lng: 18.2592394 },
    { lat: 48.6480461, lng: 18.2589692 },
    { lat: 48.6481433, lng: 18.258775 },
    { lat: 48.6482442, lng: 18.2585085 },
    { lat: 48.6482552, lng: 18.2584454 },
    { lat: 48.6482548, lng: 18.2582759 },
    { lat: 48.6482366, lng: 18.2581559 },
    { lat: 48.6481188, lng: 18.2579114 },
    { lat: 48.648998, lng: 18.257611 },
  ],
  LivinskéOpatovce: [
    { lat: 48.652991, lng: 18.240533 },
    { lat: 48.652991, lng: 18.240333 },
    { lat: 48.653118, lng: 18.239966 },
    { lat: 48.653162, lng: 18.239761 },
    { lat: 48.653161, lng: 18.239721 },
    { lat: 48.653077, lng: 18.239456 },
    { lat: 48.653073, lng: 18.239274 },
    { lat: 48.653053, lng: 18.239076 },
    { lat: 48.653058, lng: 18.239032 },
    { lat: 48.653103, lng: 18.238938 },
    { lat: 48.653118, lng: 18.238896 },
    { lat: 48.653152, lng: 18.238729 },
    { lat: 48.653155, lng: 18.238659 },
    { lat: 48.653139, lng: 18.238546 },
    { lat: 48.653102, lng: 18.238424 },
    { lat: 48.653063, lng: 18.238375 },
    { lat: 48.652971, lng: 18.238339 },
    { lat: 48.652798, lng: 18.238176 },
    { lat: 48.652691, lng: 18.238124 },
    { lat: 48.652625, lng: 18.238056 },
    { lat: 48.652626, lng: 18.237966 },
    { lat: 48.65262, lng: 18.237871 },
    { lat: 48.65258, lng: 18.237754 },
    { lat: 48.652576, lng: 18.237647 },
    { lat: 48.652595, lng: 18.237552 },
    { lat: 48.652656, lng: 18.237417 },
    { lat: 48.652691, lng: 18.237248 },
    { lat: 48.652727, lng: 18.237145 },
    { lat: 48.652818, lng: 18.236886 },
    { lat: 48.652839, lng: 18.236732 },
    { lat: 48.652793, lng: 18.236574 },
    { lat: 48.652738, lng: 18.236519 },
    { lat: 48.652665, lng: 18.23649 },
    { lat: 48.652599, lng: 18.236539 },
    { lat: 48.652529, lng: 18.236555 },
    { lat: 48.652432, lng: 18.236518 },
    { lat: 48.652368, lng: 18.236428 },
    { lat: 48.65233, lng: 18.236302 },
    { lat: 48.652339, lng: 18.236269 },
    { lat: 48.652361, lng: 18.236243 },
    { lat: 48.652399, lng: 18.236088 },
    { lat: 48.652412, lng: 18.235905 },
    { lat: 48.652486, lng: 18.235804 },
    { lat: 48.652545, lng: 18.235694 },
    { lat: 48.652585, lng: 18.235584 },
    { lat: 48.652599, lng: 18.235545 },
    { lat: 48.652623, lng: 18.23553 },
    { lat: 48.652661, lng: 18.235536 },
    { lat: 48.652761, lng: 18.235656 },
    { lat: 48.652885, lng: 18.235709 },
    { lat: 48.652934, lng: 18.23577 },
    { lat: 48.65298, lng: 18.235857 },
    { lat: 48.653038, lng: 18.235959 },
    { lat: 48.653152, lng: 18.23603 },
    { lat: 48.653215, lng: 18.236134 },
    { lat: 48.65329, lng: 18.236169 },
    { lat: 48.65335, lng: 18.236149 },
    { lat: 48.65347, lng: 18.236015 },
    { lat: 48.653565, lng: 18.235807 },
    { lat: 48.65371, lng: 18.235638 },
    { lat: 48.653799, lng: 18.235376 },
    { lat: 48.65386, lng: 18.235169 },
    { lat: 48.653914, lng: 18.235035 },
    { lat: 48.653935, lng: 18.234948 },
    { lat: 48.653943, lng: 18.234899 },
    { lat: 48.653929, lng: 18.234848 },
    { lat: 48.653878, lng: 18.234752 },
    { lat: 48.653893, lng: 18.234718 },
    { lat: 48.653893, lng: 18.234713 },
    { lat: 48.653908, lng: 18.23455 },
    { lat: 48.653952, lng: 18.234185 },
    { lat: 48.653959, lng: 18.234037 },
    { lat: 48.653967, lng: 18.233616 },
    { lat: 48.653986, lng: 18.233251 },
    { lat: 48.654036, lng: 18.233002 },
    { lat: 48.654061, lng: 18.232876 },
    { lat: 48.654075, lng: 18.2328 },
    { lat: 48.654121, lng: 18.232637 },
    { lat: 48.654139, lng: 18.232558 },
    { lat: 48.654198, lng: 18.23238 },
    { lat: 48.654258, lng: 18.232168 },
    { lat: 48.654318, lng: 18.23191 },
    { lat: 48.654349, lng: 18.23175 },
    { lat: 48.654364, lng: 18.231706 },
    { lat: 48.654405, lng: 18.231602 },
    { lat: 48.654417, lng: 18.231582 },
    { lat: 48.654499, lng: 18.231505 },
    { lat: 48.65458, lng: 18.231434 },
    { lat: 48.654788, lng: 18.231153 },
    { lat: 48.654908, lng: 18.230902 },
    { lat: 48.654981, lng: 18.230644 },
    { lat: 48.655002, lng: 18.230601 },
    { lat: 48.655026, lng: 18.230583 },
    { lat: 48.655123, lng: 18.23054 },
    { lat: 48.655261, lng: 18.230431 },
    { lat: 48.655352, lng: 18.230346 },
    { lat: 48.655377, lng: 18.230224 },
    { lat: 48.655376, lng: 18.230102 },
    { lat: 48.655378, lng: 18.230005 },
    { lat: 48.655401, lng: 18.229901 },
    { lat: 48.655518, lng: 18.229659 },
    { lat: 48.655579, lng: 18.229523 },
    { lat: 48.655658, lng: 18.229368 },
    { lat: 48.65569, lng: 18.229303 },
    { lat: 48.656065, lng: 18.229013 },
    { lat: 48.656099, lng: 18.228971 },
    { lat: 48.656106, lng: 18.228894 },
    { lat: 48.656067, lng: 18.2287 },
    { lat: 48.655935, lng: 18.228678 },
    { lat: 48.655918, lng: 18.228667 },
    { lat: 48.655918, lng: 18.228642 },
    { lat: 48.655938, lng: 18.22858 },
    { lat: 48.656103, lng: 18.228394 },
    { lat: 48.656181, lng: 18.228253 },
    { lat: 48.656201, lng: 18.22817 },
    { lat: 48.656203, lng: 18.228119 },
    { lat: 48.656195, lng: 18.228024 },
    { lat: 48.656185, lng: 18.227994 },
    { lat: 48.656158, lng: 18.227951 },
    { lat: 48.656151, lng: 18.227938 },
    { lat: 48.656117, lng: 18.227921 },
    { lat: 48.656073, lng: 18.227913 },
    { lat: 48.655879, lng: 18.22788 },
    { lat: 48.65584, lng: 18.227856 },
    { lat: 48.655836, lng: 18.227698 },
    { lat: 48.655875, lng: 18.227597 },
    { lat: 48.655929, lng: 18.227516 },
    { lat: 48.655966, lng: 18.227471 },
    { lat: 48.656025, lng: 18.227386 },
    { lat: 48.656041, lng: 18.227359 },
    { lat: 48.656056, lng: 18.227306 },
    { lat: 48.656097, lng: 18.22714 },
    { lat: 48.656152, lng: 18.227025 },
    { lat: 48.656211, lng: 18.226935 },
    { lat: 48.656308, lng: 18.226809 },
    { lat: 48.656358, lng: 18.226731 },
    { lat: 48.656406, lng: 18.226614 },
    { lat: 48.656449, lng: 18.226452 },
    { lat: 48.656455, lng: 18.226382 },
    { lat: 48.656442, lng: 18.226183 },
    { lat: 48.656477, lng: 18.22601 },
    { lat: 48.656513, lng: 18.225867 },
    { lat: 48.656579, lng: 18.225618 },
    { lat: 48.6566, lng: 18.225479 },
    { lat: 48.656604, lng: 18.225296 },
    { lat: 48.656447, lng: 18.225047 },
    { lat: 48.656129, lng: 18.225306 },
    { lat: 48.655764, lng: 18.224797 },
    { lat: 48.654772, lng: 18.223406 },
    { lat: 48.654738, lng: 18.223358 },
    { lat: 48.654468, lng: 18.223561 },
    { lat: 48.654431, lng: 18.223604 },
    { lat: 48.654373, lng: 18.223422 },
    { lat: 48.654363, lng: 18.22334 },
    { lat: 48.654343, lng: 18.223295 },
    { lat: 48.654219, lng: 18.2232 },
    { lat: 48.654076, lng: 18.223195 },
    { lat: 48.654048, lng: 18.223139 },
    { lat: 48.654053, lng: 18.223039 },
    { lat: 48.654078, lng: 18.222958 },
    { lat: 48.654111, lng: 18.222656 },
    { lat: 48.654148, lng: 18.222574 },
    { lat: 48.654209, lng: 18.22242 },
    { lat: 48.654211, lng: 18.222369 },
    { lat: 48.654186, lng: 18.222207 },
    { lat: 48.654116, lng: 18.222015 },
    { lat: 48.654044, lng: 18.221899 },
    { lat: 48.653889, lng: 18.221884 },
    { lat: 48.65383, lng: 18.221907 },
    { lat: 48.653745, lng: 18.221889 },
    { lat: 48.653721, lng: 18.22183 },
    { lat: 48.653777, lng: 18.221453 },
    { lat: 48.653776, lng: 18.221341 },
    { lat: 48.653759, lng: 18.221207 },
    { lat: 48.653733, lng: 18.220845 },
    { lat: 48.653717, lng: 18.220745 },
    { lat: 48.653682, lng: 18.220504 },
    { lat: 48.653655, lng: 18.220445 },
    { lat: 48.653548, lng: 18.220265 },
    { lat: 48.653424, lng: 18.220028 },
    { lat: 48.653412, lng: 18.21998 },
    { lat: 48.653401, lng: 18.219851 },
    { lat: 48.653421, lng: 18.219599 },
    { lat: 48.653409, lng: 18.219437 },
    { lat: 48.653386, lng: 18.21888 },
    { lat: 48.6534, lng: 18.218776 },
    { lat: 48.653489, lng: 18.218679 },
    { lat: 48.65367, lng: 18.218496 },
    { lat: 48.653807, lng: 18.218425 },
    { lat: 48.653831, lng: 18.218357 },
    { lat: 48.653849, lng: 18.218281 },
    { lat: 48.653824, lng: 18.217993 },
    { lat: 48.65383, lng: 18.217639 },
    { lat: 48.653807, lng: 18.217571 },
    { lat: 48.653696, lng: 18.217503 },
    { lat: 48.653657, lng: 18.217391 },
    { lat: 48.65367, lng: 18.217328 },
    { lat: 48.653605, lng: 18.21735 },
    { lat: 48.653525, lng: 18.217359 },
    { lat: 48.65341, lng: 18.216955 },
    { lat: 48.653374, lng: 18.216751 },
    { lat: 48.653272, lng: 18.216762 },
    { lat: 48.653185, lng: 18.216765 },
    { lat: 48.653166, lng: 18.216576 },
    { lat: 48.653186, lng: 18.216425 },
    { lat: 48.653267, lng: 18.216212 },
    { lat: 48.653327, lng: 18.216083 },
    { lat: 48.653458, lng: 18.215665 },
    { lat: 48.65348, lng: 18.215484 },
    { lat: 48.653138, lng: 18.215538 },
    { lat: 48.653031, lng: 18.215346 },
    { lat: 48.652971, lng: 18.215206 },
    { lat: 48.652962, lng: 18.215168 },
    { lat: 48.652953, lng: 18.215105 },
    { lat: 48.652949, lng: 18.215044 },
    { lat: 48.652925, lng: 18.214712 },
    { lat: 48.652929, lng: 18.21454 },
    { lat: 48.652971, lng: 18.214449 },
    { lat: 48.653058, lng: 18.214335 },
    { lat: 48.653223, lng: 18.214193 },
    { lat: 48.653085, lng: 18.213832 },
    { lat: 48.653071, lng: 18.213726 },
    { lat: 48.653098, lng: 18.213602 },
    { lat: 48.653157, lng: 18.213411 },
    { lat: 48.653133, lng: 18.213217 },
    { lat: 48.653036, lng: 18.213033 },
    { lat: 48.653016, lng: 18.212878 },
    { lat: 48.652972, lng: 18.212705 },
    { lat: 48.652841, lng: 18.212574 },
    { lat: 48.652808, lng: 18.212568 },
    { lat: 48.652766, lng: 18.212528 },
    { lat: 48.652719, lng: 18.212305 },
    { lat: 48.652783, lng: 18.212137 },
    { lat: 48.652755, lng: 18.212021 },
    { lat: 48.652698, lng: 18.211947 },
    { lat: 48.652572, lng: 18.211961 },
    { lat: 48.652542, lng: 18.211948 },
    { lat: 48.652452, lng: 18.211822 },
    { lat: 48.652443, lng: 18.211687 },
    { lat: 48.652517, lng: 18.21147 },
    { lat: 48.652529, lng: 18.21139 },
    { lat: 48.652479, lng: 18.211354 },
    { lat: 48.652372, lng: 18.211336 },
    { lat: 48.65234, lng: 18.211312 },
    { lat: 48.652265, lng: 18.211186 },
    { lat: 48.652239, lng: 18.211066 },
    { lat: 48.652192, lng: 18.210985 },
    { lat: 48.652112, lng: 18.210968 },
    { lat: 48.651984, lng: 18.210852 },
    { lat: 48.651932, lng: 18.210836 },
    { lat: 48.651802, lng: 18.210586 },
    { lat: 48.65168, lng: 18.210518 },
    { lat: 48.651618, lng: 18.210323 },
    { lat: 48.651716, lng: 18.210113 },
    { lat: 48.651753, lng: 18.210073 },
    { lat: 48.651794, lng: 18.210016 },
    { lat: 48.651807, lng: 18.209935 },
    { lat: 48.651881, lng: 18.209811 },
    { lat: 48.651889, lng: 18.209764 },
    { lat: 48.651877, lng: 18.209681 },
    { lat: 48.651818, lng: 18.209639 },
    { lat: 48.651714, lng: 18.209625 },
    { lat: 48.651564, lng: 18.209637 },
    { lat: 48.651495, lng: 18.20966 },
    { lat: 48.651422, lng: 18.20971 },
    { lat: 48.651288, lng: 18.209778 },
    { lat: 48.651225, lng: 18.209778 },
    { lat: 48.651136, lng: 18.209717 },
    { lat: 48.651065, lng: 18.209551 },
    { lat: 48.651034, lng: 18.209532 },
    { lat: 48.651045, lng: 18.209371 },
    { lat: 48.651044, lng: 18.209169 },
    { lat: 48.651064, lng: 18.208925 },
    { lat: 48.651065, lng: 18.208918 },
    { lat: 48.651033, lng: 18.20842 },
    { lat: 48.651021, lng: 18.208294 },
    { lat: 48.650978, lng: 18.20827 },
    { lat: 48.65095, lng: 18.208256 },
    { lat: 48.650768, lng: 18.208156 },
    { lat: 48.650686, lng: 18.208159 },
    { lat: 48.650629, lng: 18.208173 },
    { lat: 48.650569, lng: 18.208227 },
    { lat: 48.650467, lng: 18.20837 },
    { lat: 48.650393, lng: 18.208472 },
    { lat: 48.650347, lng: 18.208536 },
    { lat: 48.650284, lng: 18.208267 },
    { lat: 48.650201, lng: 18.208174 },
    { lat: 48.650157, lng: 18.208144 },
    { lat: 48.650031, lng: 18.208293 },
    { lat: 48.649998, lng: 18.208324 },
    { lat: 48.649971, lng: 18.208345 },
    { lat: 48.649865, lng: 18.208414 },
    { lat: 48.649777, lng: 18.20838 },
    { lat: 48.649705, lng: 18.208309 },
    { lat: 48.649658, lng: 18.208389 },
    { lat: 48.649511, lng: 18.208645 },
    { lat: 48.648934, lng: 18.208978 },
    { lat: 48.648422, lng: 18.209418 },
    { lat: 48.648385, lng: 18.209467 },
    { lat: 48.648298, lng: 18.209582 },
    { lat: 48.647888, lng: 18.21012 },
    { lat: 48.647641, lng: 18.210386 },
    { lat: 48.647355, lng: 18.210783 },
    { lat: 48.646528, lng: 18.211912 },
    { lat: 48.646131, lng: 18.212456 },
    { lat: 48.646091, lng: 18.212509 },
    { lat: 48.645958, lng: 18.212717 },
    { lat: 48.645899, lng: 18.212805 },
    { lat: 48.645578, lng: 18.213302 },
    { lat: 48.645406, lng: 18.213613 },
    { lat: 48.645364, lng: 18.213702 },
    { lat: 48.645153, lng: 18.213618 },
    { lat: 48.644875, lng: 18.213462 },
    { lat: 48.644719, lng: 18.213845 },
    { lat: 48.644052, lng: 18.213296 },
    { lat: 48.643314, lng: 18.212657 },
    { lat: 48.643137, lng: 18.212481 },
    { lat: 48.643118, lng: 18.212473 },
    { lat: 48.643047, lng: 18.212445 },
    { lat: 48.643029, lng: 18.212438 },
    { lat: 48.642651, lng: 18.213068 },
    { lat: 48.642378, lng: 18.213525 },
    { lat: 48.642316, lng: 18.213628 },
    { lat: 48.642289, lng: 18.213672 },
    { lat: 48.64226, lng: 18.213722 },
    { lat: 48.641021, lng: 18.212915 },
    { lat: 48.640005, lng: 18.212255 },
    { lat: 48.638924, lng: 18.211576 },
    { lat: 48.637465, lng: 18.210649 },
    { lat: 48.637374, lng: 18.210897 },
    { lat: 48.637304, lng: 18.211124 },
    { lat: 48.63723, lng: 18.211377 },
    { lat: 48.637158, lng: 18.211626 },
    { lat: 48.637103, lng: 18.2118 },
    { lat: 48.63703, lng: 18.212046 },
    { lat: 48.636958, lng: 18.212286 },
    { lat: 48.636885, lng: 18.212541 },
    { lat: 48.63677, lng: 18.212939 },
    { lat: 48.636736, lng: 18.21307 },
    { lat: 48.636672, lng: 18.213198 },
    { lat: 48.636623, lng: 18.213338 },
    { lat: 48.6365, lng: 18.213502 },
    { lat: 48.636418, lng: 18.213671 },
    { lat: 48.636363, lng: 18.213796 },
    { lat: 48.636316, lng: 18.213924 },
    { lat: 48.636254, lng: 18.214063 },
    { lat: 48.636217, lng: 18.214214 },
    { lat: 48.636172, lng: 18.214367 },
    { lat: 48.636153, lng: 18.214643 },
    { lat: 48.636088, lng: 18.214855 },
    { lat: 48.635996, lng: 18.215 },
    { lat: 48.635915, lng: 18.215041 },
    { lat: 48.635726, lng: 18.215189 },
    { lat: 48.635644, lng: 18.215178 },
    { lat: 48.635574, lng: 18.215214 },
    { lat: 48.635577, lng: 18.215307 },
    { lat: 48.635549, lng: 18.215453 },
    { lat: 48.635426, lng: 18.215121 },
    { lat: 48.634934, lng: 18.21416 },
    { lat: 48.63509, lng: 18.213988 },
    { lat: 48.635147, lng: 18.213926 },
    { lat: 48.634411, lng: 18.212571 },
    { lat: 48.63439, lng: 18.212601 },
    { lat: 48.634191, lng: 18.21288 },
    { lat: 48.633259, lng: 18.212022 },
    { lat: 48.63324, lng: 18.212004 },
    { lat: 48.632651, lng: 18.211462 },
    { lat: 48.631972, lng: 18.210823 },
    { lat: 48.630649, lng: 18.209588 },
    { lat: 48.630633, lng: 18.209574 },
    { lat: 48.630537, lng: 18.210225 },
    { lat: 48.630406, lng: 18.211304 },
    { lat: 48.630384, lng: 18.211505 },
    { lat: 48.630312, lng: 18.211831 },
    { lat: 48.630185, lng: 18.2123 },
    { lat: 48.630038, lng: 18.212621 },
    { lat: 48.629957, lng: 18.2128 },
    { lat: 48.629852, lng: 18.212984 },
    { lat: 48.629858, lng: 18.213012 },
    { lat: 48.629854, lng: 18.213017 },
    { lat: 48.629625, lng: 18.213658 },
    { lat: 48.629163, lng: 18.214497 },
    { lat: 48.62892, lng: 18.214944 },
    { lat: 48.628738, lng: 18.215283 },
    { lat: 48.628619, lng: 18.215562 },
    { lat: 48.6299709, lng: 18.2177567 },
    { lat: 48.6305099, lng: 18.218553 },
    { lat: 48.6307973, lng: 18.2191393 },
    { lat: 48.6309284, lng: 18.2193391 },
    { lat: 48.6311935, lng: 18.2198285 },
    { lat: 48.6313687, lng: 18.2201873 },
    { lat: 48.6315214, lng: 18.2205483 },
    { lat: 48.6315704, lng: 18.2206721 },
    { lat: 48.6318902, lng: 18.2216252 },
    { lat: 48.6319439, lng: 18.2218108 },
    { lat: 48.6319705, lng: 18.2219404 },
    { lat: 48.6320713, lng: 18.222341 },
    { lat: 48.6321393, lng: 18.2225836 },
    { lat: 48.6324858, lng: 18.2235279 },
    { lat: 48.6325898, lng: 18.2238069 },
    { lat: 48.6326865, lng: 18.2240739 },
    { lat: 48.6328147, lng: 18.2244011 },
    { lat: 48.6329533, lng: 18.22483 },
    { lat: 48.6330333, lng: 18.2251445 },
    { lat: 48.6332271, lng: 18.2257078 },
    { lat: 48.6333534, lng: 18.2260213 },
    { lat: 48.6336092, lng: 18.226584 },
    { lat: 48.6338896, lng: 18.2270668 },
    { lat: 48.6341355, lng: 18.2274785 },
    { lat: 48.6343349, lng: 18.2278085 },
    { lat: 48.6344405, lng: 18.2279349 },
    { lat: 48.6345366, lng: 18.2280568 },
    { lat: 48.6348482, lng: 18.2284934 },
    { lat: 48.6351148, lng: 18.2288735 },
    { lat: 48.6355105, lng: 18.2294772 },
    { lat: 48.6356955, lng: 18.2297731 },
    { lat: 48.636038, lng: 18.2303523 },
    { lat: 48.6363253, lng: 18.2308343 },
    { lat: 48.6365869, lng: 18.2312279 },
    { lat: 48.6368362, lng: 18.2315799 },
    { lat: 48.6370355, lng: 18.231768 },
    { lat: 48.6373841, lng: 18.2320828 },
    { lat: 48.6375217, lng: 18.2322183 },
    { lat: 48.6377117, lng: 18.2324029 },
    { lat: 48.6378488, lng: 18.2324357 },
    { lat: 48.6380878, lng: 18.2327056 },
    { lat: 48.6382305, lng: 18.2328743 },
    { lat: 48.6384155, lng: 18.2331067 },
    { lat: 48.6386069, lng: 18.2334138 },
    { lat: 48.6387438, lng: 18.2335779 },
    { lat: 48.6390319, lng: 18.2339389 },
    { lat: 48.6391946, lng: 18.234141 },
    { lat: 48.6395116, lng: 18.2345113 },
    { lat: 48.6396743, lng: 18.2347142 },
    { lat: 48.6401251, lng: 18.2351584 },
    { lat: 48.6405367, lng: 18.2355646 },
    { lat: 48.6407377, lng: 18.2358042 },
    { lat: 48.6409066, lng: 18.236017 },
    { lat: 48.6409226, lng: 18.2360398 },
    { lat: 48.6410504, lng: 18.2362811 },
    { lat: 48.6411765, lng: 18.2364923 },
    { lat: 48.6413266, lng: 18.2368056 },
    { lat: 48.641436, lng: 18.2369623 },
    { lat: 48.6415382, lng: 18.2370335 },
    { lat: 48.6419769, lng: 18.2376781 },
    { lat: 48.6420314, lng: 18.2377627 },
    { lat: 48.6420788, lng: 18.2378429 },
    { lat: 48.6421979, lng: 18.2380735 },
    { lat: 48.6422794, lng: 18.2382538 },
    { lat: 48.6423617, lng: 18.2384896 },
    { lat: 48.642455, lng: 18.2388374 },
    { lat: 48.642648, lng: 18.2395665 },
    { lat: 48.642727, lng: 18.2399006 },
    { lat: 48.6427899, lng: 18.2401946 },
    { lat: 48.6428404, lng: 18.240502 },
    { lat: 48.642851, lng: 18.240588 },
    { lat: 48.642883, lng: 18.2408921 },
    { lat: 48.6428975, lng: 18.2410861 },
    { lat: 48.64293, lng: 18.2412414 },
    { lat: 48.6429958, lng: 18.2414458 },
    { lat: 48.6430384, lng: 18.241558 },
    { lat: 48.6430902, lng: 18.2416932 },
    { lat: 48.6432387, lng: 18.2419636 },
    { lat: 48.6432859, lng: 18.2420349 },
    { lat: 48.643297, lng: 18.2420523 },
    { lat: 48.64345, lng: 18.2422622 },
    { lat: 48.6436851, lng: 18.2425855 },
    { lat: 48.6437916, lng: 18.2427126 },
    { lat: 48.6440241, lng: 18.2429488 },
    { lat: 48.6446266, lng: 18.2434408 },
    { lat: 48.6448273, lng: 18.2436131 },
    { lat: 48.6449642, lng: 18.2437216 },
    { lat: 48.6451468, lng: 18.2439529 },
    { lat: 48.6455096, lng: 18.2444962 },
    { lat: 48.6455874, lng: 18.244613 },
    { lat: 48.6458105, lng: 18.2449619 },
    { lat: 48.6458688, lng: 18.2450959 },
    { lat: 48.6462028, lng: 18.2457268 },
    { lat: 48.6464144, lng: 18.2461324 },
    { lat: 48.6467711, lng: 18.2470394 },
    { lat: 48.6470081, lng: 18.2475748 },
    { lat: 48.6472172, lng: 18.2477585 },
    { lat: 48.6473816, lng: 18.2473171 },
    { lat: 48.6474354, lng: 18.2471541 },
    { lat: 48.6474708, lng: 18.2469258 },
    { lat: 48.6476457, lng: 18.2470226 },
    { lat: 48.6478094, lng: 18.2471026 },
    { lat: 48.6479462, lng: 18.2471675 },
    { lat: 48.6481895, lng: 18.2472797 },
    { lat: 48.6483677, lng: 18.2473564 },
    { lat: 48.6485205, lng: 18.2474225 },
    { lat: 48.6487405, lng: 18.2475034 },
    { lat: 48.6489457, lng: 18.2475987 },
    { lat: 48.6491719, lng: 18.2477038 },
    { lat: 48.6495463, lng: 18.2478798 },
    { lat: 48.649745, lng: 18.2479589 },
    { lat: 48.6498142, lng: 18.2479877 },
    { lat: 48.6500378, lng: 18.2480692 },
    { lat: 48.6504044, lng: 18.2482231 },
    { lat: 48.6504828, lng: 18.2482681 },
    { lat: 48.650488, lng: 18.248252 },
    { lat: 48.650531, lng: 18.248095 },
    { lat: 48.650558, lng: 18.247888 },
    { lat: 48.650556, lng: 18.247666 },
    { lat: 48.650534, lng: 18.247499 },
    { lat: 48.650495, lng: 18.247314 },
    { lat: 48.650417, lng: 18.247029 },
    { lat: 48.650411, lng: 18.246986 },
    { lat: 48.650412, lng: 18.246937 },
    { lat: 48.65111, lng: 18.247095 },
    { lat: 48.651893, lng: 18.247284 },
    { lat: 48.651943, lng: 18.247297 },
    { lat: 48.651975, lng: 18.247302 },
    { lat: 48.651995, lng: 18.247198 },
    { lat: 48.652001, lng: 18.247151 },
    { lat: 48.652002, lng: 18.247023 },
    { lat: 48.65202, lng: 18.246849 },
    { lat: 48.652033, lng: 18.24679 },
    { lat: 48.652085, lng: 18.246618 },
    { lat: 48.652113, lng: 18.246544 },
    { lat: 48.652194, lng: 18.24635 },
    { lat: 48.652264, lng: 18.246116 },
    { lat: 48.652284, lng: 18.24605 },
    { lat: 48.652302, lng: 18.245973 },
    { lat: 48.652329, lng: 18.24587 },
    { lat: 48.652343, lng: 18.245807 },
    { lat: 48.652357, lng: 18.245736 },
    { lat: 48.652346, lng: 18.245634 },
    { lat: 48.652339, lng: 18.245596 },
    { lat: 48.652364, lng: 18.245455 },
    { lat: 48.652383, lng: 18.245392 },
    { lat: 48.652392, lng: 18.245347 },
    { lat: 48.652428, lng: 18.245244 },
    { lat: 48.652481, lng: 18.245135 },
    { lat: 48.652487, lng: 18.244945 },
    { lat: 48.652501, lng: 18.244759 },
    { lat: 48.6525, lng: 18.244685 },
    { lat: 48.652445, lng: 18.244509 },
    { lat: 48.652479, lng: 18.244316 },
    { lat: 48.652488, lng: 18.244182 },
    { lat: 48.652493, lng: 18.2441 },
    { lat: 48.652496, lng: 18.244052 },
    { lat: 48.6525, lng: 18.244014 },
    { lat: 48.652532, lng: 18.243782 },
    { lat: 48.65256, lng: 18.2436 },
    { lat: 48.652578, lng: 18.243545 },
    { lat: 48.652558, lng: 18.243448 },
    { lat: 48.65253, lng: 18.243373 },
    { lat: 48.652548, lng: 18.243259 },
    { lat: 48.652542, lng: 18.24322 },
    { lat: 48.652565, lng: 18.243155 },
    { lat: 48.652658, lng: 18.243044 },
    { lat: 48.65269, lng: 18.242972 },
    { lat: 48.652781, lng: 18.242806 },
    { lat: 48.652798, lng: 18.242777 },
    { lat: 48.652794, lng: 18.242703 },
    { lat: 48.652861, lng: 18.242645 },
    { lat: 48.652855, lng: 18.242473 },
    { lat: 48.65281, lng: 18.242396 },
    { lat: 48.652782, lng: 18.242349 },
    { lat: 48.652712, lng: 18.242321 },
    { lat: 48.65271, lng: 18.242118 },
    { lat: 48.652669, lng: 18.241979 },
    { lat: 48.652647, lng: 18.241793 },
    { lat: 48.65266, lng: 18.241636 },
    { lat: 48.65267, lng: 18.241486 },
    { lat: 48.652696, lng: 18.241432 },
    { lat: 48.652812, lng: 18.241353 },
    { lat: 48.652905, lng: 18.241235 },
    { lat: 48.652924, lng: 18.241174 },
    { lat: 48.652955, lng: 18.240998 },
    { lat: 48.652986, lng: 18.240825 },
    { lat: 48.652985, lng: 18.240749 },
    { lat: 48.652991, lng: 18.240533 },
  ],
  MaléKršteňany: [
    { lat: 48.653043, lng: 18.465828 },
    { lat: 48.653336, lng: 18.465321 },
    { lat: 48.653517, lng: 18.464585 },
    { lat: 48.653511, lng: 18.46365 },
    { lat: 48.65393, lng: 18.46196 },
    { lat: 48.654048, lng: 18.461722 },
    { lat: 48.654351, lng: 18.461329 },
    { lat: 48.654851, lng: 18.461274 },
    { lat: 48.654874, lng: 18.461272 },
    { lat: 48.655226, lng: 18.461092 },
    { lat: 48.655993, lng: 18.460629 },
    { lat: 48.656271, lng: 18.460569 },
    { lat: 48.656735, lng: 18.460357 },
    { lat: 48.657018, lng: 18.460029 },
    { lat: 48.657487, lng: 18.459558 },
    { lat: 48.657782, lng: 18.459273 },
    { lat: 48.658592, lng: 18.45816 },
    { lat: 48.6584159, lng: 18.4573416 },
    { lat: 48.658045, lng: 18.4554705 },
    { lat: 48.6576737, lng: 18.4533664 },
    { lat: 48.6574509, lng: 18.4521128 },
    { lat: 48.6572706, lng: 18.4509963 },
    { lat: 48.6571418, lng: 18.4502425 },
    { lat: 48.6570779, lng: 18.4498013 },
    { lat: 48.6568768, lng: 18.4485515 },
    { lat: 48.6567467, lng: 18.4477904 },
    { lat: 48.6566217, lng: 18.4470462 },
    { lat: 48.656538, lng: 18.4465119 },
    { lat: 48.6564231, lng: 18.4457396 },
    { lat: 48.6561864, lng: 18.4442588 },
    { lat: 48.6560097, lng: 18.4431061 },
    { lat: 48.6558459, lng: 18.4421477 },
    { lat: 48.6557176, lng: 18.4412958 },
    { lat: 48.6555528, lng: 18.4404435 },
    { lat: 48.6561432, lng: 18.440304 },
    { lat: 48.6568021, lng: 18.4401654 },
    { lat: 48.6571227, lng: 18.4401481 },
    { lat: 48.657608, lng: 18.4401306 },
    { lat: 48.6575893, lng: 18.4398964 },
    { lat: 48.6577292, lng: 18.4397549 },
    { lat: 48.6582074, lng: 18.4392213 },
    { lat: 48.6590895, lng: 18.4382066 },
    { lat: 48.6596377, lng: 18.4376135 },
    { lat: 48.6596708, lng: 18.43758 },
    { lat: 48.6591491, lng: 18.4361708 },
    { lat: 48.6584556, lng: 18.4344694 },
    { lat: 48.6584122, lng: 18.4345181 },
    { lat: 48.6579145, lng: 18.4351673 },
    { lat: 48.6576583, lng: 18.4355301 },
    { lat: 48.6571999, lng: 18.4345669 },
    { lat: 48.6567538, lng: 18.4337452 },
    { lat: 48.6564623, lng: 18.4332508 },
    { lat: 48.6560175, lng: 18.4327376 },
    { lat: 48.6558443, lng: 18.4327053 },
    { lat: 48.6557226, lng: 18.4325876 },
    { lat: 48.6557588, lng: 18.4321034 },
    { lat: 48.6550829, lng: 18.4316131 },
    { lat: 48.6549978, lng: 18.4311957 },
    { lat: 48.654902, lng: 18.4307729 },
    { lat: 48.6548949, lng: 18.4305597 },
    { lat: 48.6549289, lng: 18.43016 },
    { lat: 48.6549958, lng: 18.4298415 },
    { lat: 48.6538489, lng: 18.4289327 },
    { lat: 48.6534437, lng: 18.4311451 },
    { lat: 48.6529356, lng: 18.4309939 },
    { lat: 48.6524488, lng: 18.4309631 },
    { lat: 48.6518729, lng: 18.4308512 },
    { lat: 48.652032, lng: 18.431342 },
    { lat: 48.651497, lng: 18.4316605 },
    { lat: 48.6510587, lng: 18.431974 },
    { lat: 48.6508568, lng: 18.4321351 },
    { lat: 48.6506493, lng: 18.4323415 },
    { lat: 48.6504053, lng: 18.4320306 },
    { lat: 48.6510584, lng: 18.4302625 },
    { lat: 48.650821, lng: 18.43005 },
    { lat: 48.6502422, lng: 18.4295196 },
    { lat: 48.6496468, lng: 18.4290205 },
    { lat: 48.6490531, lng: 18.428507 },
    { lat: 48.6484296, lng: 18.4279873 },
    { lat: 48.648065, lng: 18.4276361 },
    { lat: 48.647761, lng: 18.4272632 },
    { lat: 48.6472119, lng: 18.4271062 },
    { lat: 48.6469667, lng: 18.4268925 },
    { lat: 48.6468427, lng: 18.4267117 },
    { lat: 48.6467123, lng: 18.4267432 },
    { lat: 48.6467056, lng: 18.4264782 },
    { lat: 48.646479, lng: 18.4261199 },
    { lat: 48.6461754, lng: 18.4257488 },
    { lat: 48.6460117, lng: 18.4255044 },
    { lat: 48.6459525, lng: 18.4253532 },
    { lat: 48.6456623, lng: 18.4248413 },
    { lat: 48.6449916, lng: 18.4237354 },
    { lat: 48.6451114, lng: 18.4235993 },
    { lat: 48.6449312, lng: 18.4234395 },
    { lat: 48.6445432, lng: 18.4229981 },
    { lat: 48.644416, lng: 18.4230218 },
    { lat: 48.6442168, lng: 18.4226036 },
    { lat: 48.6446863, lng: 18.4221199 },
    { lat: 48.6441259, lng: 18.420851 },
    { lat: 48.6438686, lng: 18.4217584 },
    { lat: 48.6438146, lng: 18.4220456 },
    { lat: 48.6433959, lng: 18.4211312 },
    { lat: 48.6433137, lng: 18.4212768 },
    { lat: 48.6431997, lng: 18.4215574 },
    { lat: 48.6427722, lng: 18.4204033 },
    { lat: 48.6422747, lng: 18.4190688 },
    { lat: 48.6415263, lng: 18.417053 },
    { lat: 48.6412598, lng: 18.4163398 },
    { lat: 48.6406219, lng: 18.4146107 },
    { lat: 48.6406101, lng: 18.4146197 },
    { lat: 48.6400436, lng: 18.4116834 },
    { lat: 48.6397562, lng: 18.4101707 },
    { lat: 48.6395567, lng: 18.4090596 },
    { lat: 48.6392855, lng: 18.4075966 },
    { lat: 48.6390042, lng: 18.4063171 },
    { lat: 48.6387844, lng: 18.4054073 },
    { lat: 48.6386528, lng: 18.4046471 },
    { lat: 48.6385456, lng: 18.4032931 },
    { lat: 48.6382994, lng: 18.4000948 },
    { lat: 48.6381987, lng: 18.3988007 },
    { lat: 48.6382351, lng: 18.3987829 },
    { lat: 48.6381624, lng: 18.39845 },
    { lat: 48.6379894, lng: 18.3980305 },
    { lat: 48.637917, lng: 18.3980361 },
    { lat: 48.6372882, lng: 18.3981503 },
    { lat: 48.6371485, lng: 18.3977104 },
    { lat: 48.6362655, lng: 18.3982023 },
    { lat: 48.6361624, lng: 18.3982557 },
    { lat: 48.6352939, lng: 18.3986008 },
    { lat: 48.6349673, lng: 18.3986745 },
    { lat: 48.6350165, lng: 18.4000967 },
    { lat: 48.6350813, lng: 18.400875 },
    { lat: 48.6351501, lng: 18.4014387 },
    { lat: 48.6344883, lng: 18.4015478 },
    { lat: 48.6341717, lng: 18.4015752 },
    { lat: 48.6331636, lng: 18.4016477 },
    { lat: 48.6328383, lng: 18.4017179 },
    { lat: 48.6324748, lng: 18.4018569 },
    { lat: 48.6321417, lng: 18.4020558 },
    { lat: 48.6321418, lng: 18.4021578 },
    { lat: 48.6316353, lng: 18.4025203 },
    { lat: 48.6317539, lng: 18.4029885 },
    { lat: 48.6314437, lng: 18.4031331 },
    { lat: 48.6310314, lng: 18.4034844 },
    { lat: 48.6313161, lng: 18.4041576 },
    { lat: 48.631396, lng: 18.4045005 },
    { lat: 48.6314497, lng: 18.4048539 },
    { lat: 48.6314796, lng: 18.4051262 },
    { lat: 48.6315795, lng: 18.4056449 },
    { lat: 48.6316301, lng: 18.4059423 },
    { lat: 48.6313144, lng: 18.4063516 },
    { lat: 48.6311121, lng: 18.406709 },
    { lat: 48.6314988, lng: 18.4073935 },
    { lat: 48.6329671, lng: 18.4086979 },
    { lat: 48.6334441, lng: 18.408913 },
    { lat: 48.6343262, lng: 18.4091948 },
    { lat: 48.6344173, lng: 18.4090901 },
    { lat: 48.6344958, lng: 18.4092046 },
    { lat: 48.6346233, lng: 18.4093625 },
    { lat: 48.6346688, lng: 18.4095143 },
    { lat: 48.6347021, lng: 18.409687 },
    { lat: 48.6347329, lng: 18.4099132 },
    { lat: 48.6347425, lng: 18.4102073 },
    { lat: 48.6347676, lng: 18.410416 },
    { lat: 48.6348288, lng: 18.4107841 },
    { lat: 48.6348785, lng: 18.4109897 },
    { lat: 48.6348951, lng: 18.4111253 },
    { lat: 48.6348191, lng: 18.4113607 },
    { lat: 48.63475, lng: 18.4114957 },
    { lat: 48.6345497, lng: 18.4116852 },
    { lat: 48.634468, lng: 18.41184 },
    { lat: 48.63436, lng: 18.4121073 },
    { lat: 48.6343141, lng: 18.41234 },
    { lat: 48.6342846, lng: 18.4126159 },
    { lat: 48.6343975, lng: 18.4132892 },
    { lat: 48.6345528, lng: 18.4136454 },
    { lat: 48.6347185, lng: 18.4139454 },
    { lat: 48.6349753, lng: 18.4142602 },
    { lat: 48.6353395, lng: 18.4147637 },
    { lat: 48.6357991, lng: 18.4153802 },
    { lat: 48.6358547, lng: 18.41561 },
    { lat: 48.6358872, lng: 18.4162514 },
    { lat: 48.6358903, lng: 18.4165321 },
    { lat: 48.6359181, lng: 18.4173547 },
    { lat: 48.6359919, lng: 18.4182926 },
    { lat: 48.636001, lng: 18.4193308 },
    { lat: 48.6360339, lng: 18.4199819 },
    { lat: 48.6360484, lng: 18.4201406 },
    { lat: 48.63614, lng: 18.4204254 },
    { lat: 48.6361764, lng: 18.4207578 },
    { lat: 48.6362339, lng: 18.421506 },
    { lat: 48.6362129, lng: 18.4217355 },
    { lat: 48.636035, lng: 18.42215 },
    { lat: 48.6359963, lng: 18.4222648 },
    { lat: 48.635981, lng: 18.42276 },
    { lat: 48.6359774, lng: 18.4231827 },
    { lat: 48.6360827, lng: 18.4235129 },
    { lat: 48.6362669, lng: 18.423721 },
    { lat: 48.6362257, lng: 18.4238511 },
    { lat: 48.6360062, lng: 18.4239427 },
    { lat: 48.6361423, lng: 18.4241581 },
    { lat: 48.6362037, lng: 18.4241285 },
    { lat: 48.6363496, lng: 18.4243148 },
    { lat: 48.6363829, lng: 18.4244885 },
    { lat: 48.6364147, lng: 18.424716 },
    { lat: 48.6364366, lng: 18.4249276 },
    { lat: 48.6364655, lng: 18.4253662 },
    { lat: 48.6365227, lng: 18.4260081 },
    { lat: 48.6365564, lng: 18.4263491 },
    { lat: 48.6365952, lng: 18.4267037 },
    { lat: 48.6366405, lng: 18.4270707 },
    { lat: 48.6365447, lng: 18.427763 },
    { lat: 48.6365064, lng: 18.4279673 },
    { lat: 48.6364755, lng: 18.4281802 },
    { lat: 48.6363705, lng: 18.4288476 },
    { lat: 48.6364869, lng: 18.4299086 },
    { lat: 48.6366555, lng: 18.4314189 },
    { lat: 48.6368007, lng: 18.4321645 },
    { lat: 48.6370928, lng: 18.4329743 },
    { lat: 48.6371633, lng: 18.4332272 },
    { lat: 48.6372873, lng: 18.4334716 },
    { lat: 48.6373421, lng: 18.4336034 },
    { lat: 48.6374061, lng: 18.4338232 },
    { lat: 48.6375684, lng: 18.4341836 },
    { lat: 48.6377076, lng: 18.4345352 },
    { lat: 48.6377488, lng: 18.4346684 },
    { lat: 48.6377552, lng: 18.4346811 },
    { lat: 48.6377864, lng: 18.4348608 },
    { lat: 48.6377883, lng: 18.4349152 },
    { lat: 48.6378365, lng: 18.4352522 },
    { lat: 48.6378371, lng: 18.4353256 },
    { lat: 48.6380611, lng: 18.4359675 },
    { lat: 48.638233, lng: 18.436504 },
    { lat: 48.6382829, lng: 18.4366493 },
    { lat: 48.6382382, lng: 18.4368193 },
    { lat: 48.6382246, lng: 18.4369613 },
    { lat: 48.6381796, lng: 18.4371638 },
    { lat: 48.6381154, lng: 18.4375112 },
    { lat: 48.6380483, lng: 18.4377251 },
    { lat: 48.6380015, lng: 18.4379589 },
    { lat: 48.6379764, lng: 18.4381208 },
    { lat: 48.6379468, lng: 18.4383782 },
    { lat: 48.6379244, lng: 18.4386646 },
    { lat: 48.637758, lng: 18.438864 },
    { lat: 48.637858, lng: 18.43912 },
    { lat: 48.637916, lng: 18.439249 },
    { lat: 48.638009, lng: 18.43934 },
    { lat: 48.638095, lng: 18.439552 },
    { lat: 48.638169, lng: 18.439723 },
    { lat: 48.638263, lng: 18.439987 },
    { lat: 48.638305, lng: 18.440141 },
    { lat: 48.638348, lng: 18.440358 },
    { lat: 48.638401, lng: 18.440758 },
    { lat: 48.638455, lng: 18.441137 },
    { lat: 48.638478, lng: 18.441389 },
    { lat: 48.638492, lng: 18.441682 },
    { lat: 48.638473, lng: 18.441964 },
    { lat: 48.638425, lng: 18.442406 },
    { lat: 48.638406, lng: 18.442521 },
    { lat: 48.638369, lng: 18.442764 },
    { lat: 48.63835, lng: 18.442879 },
    { lat: 48.638266, lng: 18.443215 },
    { lat: 48.638167, lng: 18.443402 },
    { lat: 48.638061, lng: 18.443611 },
    { lat: 48.637967, lng: 18.443902 },
    { lat: 48.637888, lng: 18.444304 },
    { lat: 48.637898, lng: 18.444506 },
    { lat: 48.637944, lng: 18.444819 },
    { lat: 48.638017, lng: 18.445018 },
    { lat: 48.638141, lng: 18.445235 },
    { lat: 48.638201, lng: 18.445339 },
    { lat: 48.638121, lng: 18.445424 },
    { lat: 48.637923, lng: 18.44559 },
    { lat: 48.637912, lng: 18.445598 },
    { lat: 48.637889, lng: 18.445761 },
    { lat: 48.637877, lng: 18.445848 },
    { lat: 48.637847, lng: 18.446072 },
    { lat: 48.637844, lng: 18.446132 },
    { lat: 48.63784, lng: 18.446324 },
    { lat: 48.637843, lng: 18.446488 },
    { lat: 48.637853, lng: 18.446508 },
    { lat: 48.638303, lng: 18.447302 },
    { lat: 48.638727, lng: 18.448074 },
    { lat: 48.638983, lng: 18.448557 },
    { lat: 48.639212, lng: 18.448868 },
    { lat: 48.63958, lng: 18.449385 },
    { lat: 48.640073, lng: 18.450326 },
    { lat: 48.640156, lng: 18.450483 },
    { lat: 48.6402, lng: 18.450548 },
    { lat: 48.640245, lng: 18.450642 },
    { lat: 48.64027, lng: 18.4507 },
    { lat: 48.640792, lng: 18.450896 },
    { lat: 48.640803, lng: 18.4509 },
    { lat: 48.640924, lng: 18.450894 },
    { lat: 48.641004, lng: 18.45089 },
    { lat: 48.641034, lng: 18.450889 },
    { lat: 48.641412, lng: 18.451324 },
    { lat: 48.641433, lng: 18.45135 },
    { lat: 48.641507, lng: 18.451265 },
    { lat: 48.641518, lng: 18.451251 },
    { lat: 48.641527, lng: 18.451248 },
    { lat: 48.641622, lng: 18.451218 },
    { lat: 48.641676, lng: 18.451202 },
    { lat: 48.641907, lng: 18.451146 },
    { lat: 48.642034, lng: 18.451205 },
    { lat: 48.642119, lng: 18.451299 },
    { lat: 48.642229, lng: 18.451588 },
    { lat: 48.642271, lng: 18.45175 },
    { lat: 48.642307, lng: 18.451915 },
    { lat: 48.642305, lng: 18.452138 },
    { lat: 48.642304, lng: 18.452188 },
    { lat: 48.642231, lng: 18.452307 },
    { lat: 48.642183, lng: 18.452384 },
    { lat: 48.642301, lng: 18.452596 },
    { lat: 48.642396, lng: 18.452763 },
    { lat: 48.642544, lng: 18.452952 },
    { lat: 48.64267, lng: 18.45307 },
    { lat: 48.642896, lng: 18.453372 },
    { lat: 48.642962, lng: 18.45349 },
    { lat: 48.643085, lng: 18.453729 },
    { lat: 48.643129, lng: 18.453822 },
    { lat: 48.643248, lng: 18.454147 },
    { lat: 48.643273, lng: 18.454215 },
    { lat: 48.643347, lng: 18.454519 },
    { lat: 48.64337, lng: 18.454682 },
    { lat: 48.643299, lng: 18.454966 },
    { lat: 48.6434, lng: 18.455077 },
    { lat: 48.643502, lng: 18.45519 },
    { lat: 48.643517, lng: 18.455206 },
    { lat: 48.643873, lng: 18.455977 },
    { lat: 48.644092, lng: 18.456448 },
    { lat: 48.644183, lng: 18.456636 },
    { lat: 48.644048, lng: 18.456803 },
    { lat: 48.643641, lng: 18.457303 },
    { lat: 48.643574, lng: 18.457387 },
    { lat: 48.643559, lng: 18.457405 },
    { lat: 48.643708, lng: 18.457775 },
    { lat: 48.643718, lng: 18.457802 },
    { lat: 48.64378, lng: 18.457952 },
    { lat: 48.643816, lng: 18.458094 },
    { lat: 48.643854, lng: 18.458243 },
    { lat: 48.644063, lng: 18.458318 },
    { lat: 48.644263, lng: 18.458389 },
    { lat: 48.644319, lng: 18.458406 },
    { lat: 48.644391, lng: 18.458446 },
    { lat: 48.644495, lng: 18.458515 },
    { lat: 48.644541, lng: 18.458546 },
    { lat: 48.644803, lng: 18.458722 },
    { lat: 48.644866, lng: 18.458766 },
    { lat: 48.645078, lng: 18.458865 },
    { lat: 48.64523, lng: 18.458962 },
    { lat: 48.645277, lng: 18.458991 },
    { lat: 48.645309, lng: 18.459027 },
    { lat: 48.64541, lng: 18.459139 },
    { lat: 48.645523, lng: 18.459262 },
    { lat: 48.645663, lng: 18.459444 },
    { lat: 48.6459, lng: 18.459748 },
    { lat: 48.646065, lng: 18.459952 },
    { lat: 48.646117, lng: 18.460015 },
    { lat: 48.646167, lng: 18.460213 },
    { lat: 48.646154, lng: 18.460341 },
    { lat: 48.646142, lng: 18.460439 },
    { lat: 48.646183, lng: 18.460454 },
    { lat: 48.646263, lng: 18.460483 },
    { lat: 48.646334, lng: 18.460509 },
    { lat: 48.646346, lng: 18.460508 },
    { lat: 48.646483, lng: 18.460512 },
    { lat: 48.646523, lng: 18.460513 },
    { lat: 48.646819, lng: 18.460521 },
    { lat: 48.64736, lng: 18.460532 },
    { lat: 48.647713, lng: 18.460415 },
    { lat: 48.647975, lng: 18.460444 },
    { lat: 48.648631, lng: 18.460752 },
    { lat: 48.649009, lng: 18.46088 },
    { lat: 48.64919, lng: 18.461137 },
    { lat: 48.649499, lng: 18.461649 },
    { lat: 48.649619, lng: 18.461772 },
    { lat: 48.649947, lng: 18.462195 },
    { lat: 48.650213, lng: 18.462408 },
    { lat: 48.65063, lng: 18.462667 },
    { lat: 48.650864, lng: 18.462997 },
    { lat: 48.651067, lng: 18.463557 },
    { lat: 48.651132, lng: 18.46429 },
    { lat: 48.651277, lng: 18.464659 },
    { lat: 48.651576, lng: 18.465016 },
    { lat: 48.652253, lng: 18.465417 },
    { lat: 48.652786, lng: 18.465511 },
    { lat: 48.653043, lng: 18.465828 },
  ],
  MaléUherce: [
    { lat: 48.6213221, lng: 18.3870479 },
    { lat: 48.6212972, lng: 18.3867332 },
    { lat: 48.6213091, lng: 18.3848096 },
    { lat: 48.6213175, lng: 18.3844156 },
    { lat: 48.6213218, lng: 18.3840699 },
    { lat: 48.6213332, lng: 18.3836876 },
    { lat: 48.6201752, lng: 18.3828927 },
    { lat: 48.6194744, lng: 18.3834352 },
    { lat: 48.6188706, lng: 18.3838955 },
    { lat: 48.6183256, lng: 18.3842955 },
    { lat: 48.617538, lng: 18.3845549 },
    { lat: 48.6167618, lng: 18.3848374 },
    { lat: 48.6159489, lng: 18.3851471 },
    { lat: 48.614554, lng: 18.3856018 },
    { lat: 48.6134457, lng: 18.3855761 },
    { lat: 48.6124216, lng: 18.3855393 },
    { lat: 48.6116331, lng: 18.3855417 },
    { lat: 48.6106282, lng: 18.3855485 },
    { lat: 48.6094354, lng: 18.3855047 },
    { lat: 48.6088268, lng: 18.384871 },
    { lat: 48.6081926, lng: 18.3842003 },
    { lat: 48.6078737, lng: 18.3840274 },
    { lat: 48.6074728, lng: 18.3840004 },
    { lat: 48.60673, lng: 18.3840754 },
    { lat: 48.6060847, lng: 18.3850399 },
    { lat: 48.6056145, lng: 18.3855087 },
    { lat: 48.6051593, lng: 18.3857381 },
    { lat: 48.6045609, lng: 18.385835 },
    { lat: 48.6041193, lng: 18.3861472 },
    { lat: 48.6039358, lng: 18.3864351 },
    { lat: 48.6037359, lng: 18.3874191 },
    { lat: 48.6030576, lng: 18.3891676 },
    { lat: 48.6027694, lng: 18.3902861 },
    { lat: 48.6025738, lng: 18.3906001 },
    { lat: 48.6019408, lng: 18.3910417 },
    { lat: 48.6015363, lng: 18.3912539 },
    { lat: 48.6011371, lng: 18.3913771 },
    { lat: 48.6007939, lng: 18.391758 },
    { lat: 48.6003323, lng: 18.3921242 },
    { lat: 48.6002225, lng: 18.3922113 },
    { lat: 48.5995254, lng: 18.3930025 },
    { lat: 48.5992899, lng: 18.3931284 },
    { lat: 48.5987285, lng: 18.3931923 },
    { lat: 48.5977607, lng: 18.3927932 },
    { lat: 48.5975306, lng: 18.392331 },
    { lat: 48.5971131, lng: 18.3920281 },
    { lat: 48.5971549, lng: 18.3923132 },
    { lat: 48.5971794, lng: 18.3926077 },
    { lat: 48.5973272, lng: 18.3929934 },
    { lat: 48.5974515, lng: 18.3931573 },
    { lat: 48.5975258, lng: 18.393315 },
    { lat: 48.5975434, lng: 18.3934471 },
    { lat: 48.5975772, lng: 18.3938698 },
    { lat: 48.5975835, lng: 18.3940615 },
    { lat: 48.5975545, lng: 18.3943506 },
    { lat: 48.5975549, lng: 18.3945997 },
    { lat: 48.5975769, lng: 18.3951141 },
    { lat: 48.597655, lng: 18.3955012 },
    { lat: 48.5977944, lng: 18.3958167 },
    { lat: 48.5978934, lng: 18.395993 },
    { lat: 48.5979842, lng: 18.396103 },
    { lat: 48.5982516, lng: 18.3963863 },
    { lat: 48.5984466, lng: 18.3967061 },
    { lat: 48.5985624, lng: 18.3968201 },
    { lat: 48.5989499, lng: 18.3970706 },
    { lat: 48.5991021, lng: 18.3972088 },
    { lat: 48.5994514, lng: 18.3976256 },
    { lat: 48.5997164, lng: 18.3980481 },
    { lat: 48.599893, lng: 18.3984402 },
    { lat: 48.6000442, lng: 18.3986256 },
    { lat: 48.6003858, lng: 18.3988239 },
    { lat: 48.6007327, lng: 18.398829 },
    { lat: 48.6010448, lng: 18.3988634 },
    { lat: 48.6013172, lng: 18.3989546 },
    { lat: 48.6016691, lng: 18.3990147 },
    { lat: 48.6020328, lng: 18.3990594 },
    { lat: 48.6023172, lng: 18.3990856 },
    { lat: 48.6024977, lng: 18.3991258 },
    { lat: 48.6027452, lng: 18.3991121 },
    { lat: 48.6029658, lng: 18.3991219 },
    { lat: 48.6032858, lng: 18.3990727 },
    { lat: 48.603292, lng: 18.3990865 },
    { lat: 48.6036551, lng: 18.3996267 },
    { lat: 48.6038309, lng: 18.3998838 },
    { lat: 48.6040216, lng: 18.4000689 },
    { lat: 48.6041218, lng: 18.4001235 },
    { lat: 48.6045086, lng: 18.4003645 },
    { lat: 48.6046366, lng: 18.4005953 },
    { lat: 48.604761, lng: 18.4007395 },
    { lat: 48.6048499, lng: 18.4008797 },
    { lat: 48.6049389, lng: 18.4011187 },
    { lat: 48.6050635, lng: 18.4014308 },
    { lat: 48.6052059, lng: 18.4018978 },
    { lat: 48.6052931, lng: 18.4022069 },
    { lat: 48.6056744, lng: 18.4034004 },
    { lat: 48.6058913, lng: 18.404295 },
    { lat: 48.6059928, lng: 18.4044522 },
    { lat: 48.6062774, lng: 18.404863 },
    { lat: 48.6064164, lng: 18.4049684 },
    { lat: 48.6067619, lng: 18.4051362 },
    { lat: 48.6070695, lng: 18.4053895 },
    { lat: 48.6073183, lng: 18.4056845 },
    { lat: 48.6073367, lng: 18.4056326 },
    { lat: 48.6080672, lng: 18.4069155 },
    { lat: 48.6090411, lng: 18.4086588 },
    { lat: 48.6092866, lng: 18.4090765 },
    { lat: 48.6095222, lng: 18.4094551 },
    { lat: 48.6097583, lng: 18.4098661 },
    { lat: 48.610593, lng: 18.4113399 },
    { lat: 48.6113889, lng: 18.4127659 },
    { lat: 48.6119499, lng: 18.4137799 },
    { lat: 48.6125217, lng: 18.4145876 },
    { lat: 48.6128744, lng: 18.4154692 },
    { lat: 48.6128233, lng: 18.4157277 },
    { lat: 48.6129528, lng: 18.4167951 },
    { lat: 48.6133825, lng: 18.4176052 },
    { lat: 48.6138643, lng: 18.4184144 },
    { lat: 48.6139547, lng: 18.4186001 },
    { lat: 48.6140668, lng: 18.4188722 },
    { lat: 48.6144447, lng: 18.4194974 },
    { lat: 48.6146626, lng: 18.4202092 },
    { lat: 48.6147195, lng: 18.4205015 },
    { lat: 48.6149135, lng: 18.4216763 },
    { lat: 48.6150049, lng: 18.4218387 },
    { lat: 48.615167, lng: 18.4222581 },
    { lat: 48.6153728, lng: 18.4229529 },
    { lat: 48.6157644, lng: 18.4240503 },
    { lat: 48.6168755, lng: 18.4237663 },
    { lat: 48.6174205, lng: 18.423586 },
    { lat: 48.6174018, lng: 18.4234522 },
    { lat: 48.6182588, lng: 18.423123 },
    { lat: 48.61827, lng: 18.4232007 },
    { lat: 48.6183701, lng: 18.4232159 },
    { lat: 48.6189944, lng: 18.4229596 },
    { lat: 48.6193948, lng: 18.4228144 },
    { lat: 48.6199599, lng: 18.4226127 },
    { lat: 48.6203423, lng: 18.4224701 },
    { lat: 48.6206349, lng: 18.422355 },
    { lat: 48.6212029, lng: 18.4221026 },
    { lat: 48.6217975, lng: 18.4218183 },
    { lat: 48.6223465, lng: 18.4215494 },
    { lat: 48.6229916, lng: 18.4211988 },
    { lat: 48.6238707, lng: 18.4206753 },
    { lat: 48.624165, lng: 18.4204721 },
    { lat: 48.6242522, lng: 18.4203983 },
    { lat: 48.6250763, lng: 18.4197208 },
    { lat: 48.6260998, lng: 18.4189318 },
    { lat: 48.6261441, lng: 18.4188192 },
    { lat: 48.6273338, lng: 18.4179387 },
    { lat: 48.6285101, lng: 18.41706 },
    { lat: 48.629516, lng: 18.416304 },
    { lat: 48.6305516, lng: 18.4154929 },
    { lat: 48.6319716, lng: 18.4143752 },
    { lat: 48.6326302, lng: 18.4138455 },
    { lat: 48.6334327, lng: 18.4132415 },
    { lat: 48.634159, lng: 18.4126917 },
    { lat: 48.6342846, lng: 18.4126159 },
    { lat: 48.6343141, lng: 18.41234 },
    { lat: 48.63436, lng: 18.4121073 },
    { lat: 48.634468, lng: 18.41184 },
    { lat: 48.6343888, lng: 18.4117156 },
    { lat: 48.6342996, lng: 18.4115925 },
    { lat: 48.6338505, lng: 18.410924 },
    { lat: 48.6334572, lng: 18.4104846 },
    { lat: 48.6330694, lng: 18.4100245 },
    { lat: 48.632753, lng: 18.409734 },
    { lat: 48.6324244, lng: 18.4094851 },
    { lat: 48.6322257, lng: 18.4093416 },
    { lat: 48.6310971, lng: 18.4085448 },
    { lat: 48.6306699, lng: 18.4082652 },
    { lat: 48.6305695, lng: 18.4081442 },
    { lat: 48.6304758, lng: 18.4080623 },
    { lat: 48.630317, lng: 18.4078852 },
    { lat: 48.6301939, lng: 18.4077218 },
    { lat: 48.6301371, lng: 18.4076349 },
    { lat: 48.6298496, lng: 18.407151 },
    { lat: 48.6297501, lng: 18.4069283 },
    { lat: 48.6295292, lng: 18.4063615 },
    { lat: 48.6294588, lng: 18.406155 },
    { lat: 48.6292979, lng: 18.4056145 },
    { lat: 48.6292151, lng: 18.4053258 },
    { lat: 48.6292053, lng: 18.4053331 },
    { lat: 48.6291683, lng: 18.4051734 },
    { lat: 48.6290851, lng: 18.4047128 },
    { lat: 48.6288604, lng: 18.4034179 },
    { lat: 48.6288453, lng: 18.4036092 },
    { lat: 48.6287679, lng: 18.4036376 },
    { lat: 48.6284025, lng: 18.4038427 },
    { lat: 48.6282266, lng: 18.4029153 },
    { lat: 48.6281636, lng: 18.4026751 },
    { lat: 48.6280406, lng: 18.4026358 },
    { lat: 48.6274053, lng: 18.4030382 },
    { lat: 48.6271729, lng: 18.4024319 },
    { lat: 48.6273246, lng: 18.4022404 },
    { lat: 48.627403, lng: 18.4019851 },
    { lat: 48.6274554, lng: 18.4015294 },
    { lat: 48.6272679, lng: 18.399549 },
    { lat: 48.6270451, lng: 18.3976605 },
    { lat: 48.6270243, lng: 18.3972666 },
    { lat: 48.6270993, lng: 18.397057 },
    { lat: 48.6270202, lng: 18.3969097 },
    { lat: 48.6269959, lng: 18.3969827 },
    { lat: 48.6265262, lng: 18.3960275 },
    { lat: 48.6265456, lng: 18.3959473 },
    { lat: 48.626541, lng: 18.3959451 },
    { lat: 48.626552, lng: 18.3959012 },
    { lat: 48.6264984, lng: 18.3958724 },
    { lat: 48.6264921, lng: 18.3958996 },
    { lat: 48.6263642, lng: 18.3959326 },
    { lat: 48.6263012, lng: 18.3961974 },
    { lat: 48.6258473, lng: 18.3980075 },
    { lat: 48.6258072, lng: 18.3980775 },
    { lat: 48.6257912, lng: 18.3980749 },
    { lat: 48.6257081, lng: 18.3984032 },
    { lat: 48.6253807, lng: 18.3992545 },
    { lat: 48.6254362, lng: 18.399356 },
    { lat: 48.6252056, lng: 18.3995917 },
    { lat: 48.6251673, lng: 18.3995438 },
    { lat: 48.6252535, lng: 18.3993887 },
    { lat: 48.6252783, lng: 18.3992885 },
    { lat: 48.6254099, lng: 18.3977795 },
    { lat: 48.6228899, lng: 18.3983864 },
    { lat: 48.6219016, lng: 18.3986477 },
    { lat: 48.6218715, lng: 18.3983064 },
    { lat: 48.6216534, lng: 18.3983609 },
    { lat: 48.6215844, lng: 18.3976109 },
    { lat: 48.6207778, lng: 18.3992701 },
    { lat: 48.6205946, lng: 18.3997484 },
    { lat: 48.6205551, lng: 18.4001495 },
    { lat: 48.6202803, lng: 18.4001178 },
    { lat: 48.6202023, lng: 18.3999533 },
    { lat: 48.6200116, lng: 18.3996296 },
    { lat: 48.6198675, lng: 18.3996357 },
    { lat: 48.6198377, lng: 18.3996474 },
    { lat: 48.61987, lng: 18.3990335 },
    { lat: 48.6197861, lng: 18.3983824 },
    { lat: 48.6196551, lng: 18.3977123 },
    { lat: 48.6196518, lng: 18.3975102 },
    { lat: 48.6196655, lng: 18.3972528 },
    { lat: 48.6196411, lng: 18.396858 },
    { lat: 48.6195786, lng: 18.3964252 },
    { lat: 48.6195396, lng: 18.3960479 },
    { lat: 48.6194008, lng: 18.3955246 },
    { lat: 48.6192168, lng: 18.3946128 },
    { lat: 48.6191969, lng: 18.3938565 },
    { lat: 48.6191826, lng: 18.3934772 },
    { lat: 48.6192614, lng: 18.3923671 },
    { lat: 48.6193595, lng: 18.392186 },
    { lat: 48.6194113, lng: 18.3920431 },
    { lat: 48.619274, lng: 18.3917654 },
    { lat: 48.6191426, lng: 18.391474 },
    { lat: 48.619063, lng: 18.3915644 },
    { lat: 48.6187763, lng: 18.3918444 },
    { lat: 48.6182792, lng: 18.3923145 },
    { lat: 48.6180389, lng: 18.3925359 },
    { lat: 48.6179475, lng: 18.3925757 },
    { lat: 48.6178072, lng: 18.3925874 },
    { lat: 48.6175304, lng: 18.3925036 },
    { lat: 48.6171341, lng: 18.39215 },
    { lat: 48.6170687, lng: 18.3925163 },
    { lat: 48.6168798, lng: 18.3925486 },
    { lat: 48.6167198, lng: 18.392631 },
    { lat: 48.6164463, lng: 18.3928252 },
    { lat: 48.6162469, lng: 18.3930145 },
    { lat: 48.6157876, lng: 18.3934324 },
    { lat: 48.6157154, lng: 18.3934951 },
    { lat: 48.615384, lng: 18.3935201 },
    { lat: 48.6153763, lng: 18.39356 },
    { lat: 48.6152199, lng: 18.3936305 },
    { lat: 48.6151555, lng: 18.3931049 },
    { lat: 48.6146634, lng: 18.3932417 },
    { lat: 48.6146501, lng: 18.3926622 },
    { lat: 48.6145478, lng: 18.3926072 },
    { lat: 48.614484, lng: 18.391244 },
    { lat: 48.6144077, lng: 18.3912316 },
    { lat: 48.614214, lng: 18.3909771 },
    { lat: 48.6143174, lng: 18.3900034 },
    { lat: 48.6145337, lng: 18.3901791 },
    { lat: 48.6148001, lng: 18.3903017 },
    { lat: 48.6150104, lng: 18.3904308 },
    { lat: 48.6150396, lng: 18.3905094 },
    { lat: 48.6153363, lng: 18.390932 },
    { lat: 48.6153767, lng: 18.3909475 },
    { lat: 48.6160445, lng: 18.3914123 },
    { lat: 48.6162539, lng: 18.3914234 },
    { lat: 48.6165081, lng: 18.39161 },
    { lat: 48.6168938, lng: 18.3920088 },
    { lat: 48.6170664, lng: 18.3918495 },
    { lat: 48.6174843, lng: 18.3922032 },
    { lat: 48.6176345, lng: 18.392327 },
    { lat: 48.6179619, lng: 18.3923688 },
    { lat: 48.6190716, lng: 18.3913551 },
    { lat: 48.619245, lng: 18.3912253 },
    { lat: 48.6192828, lng: 18.3912365 },
    { lat: 48.6193493, lng: 18.3912762 },
    { lat: 48.6193777, lng: 18.3912601 },
    { lat: 48.619749, lng: 18.3913591 },
    { lat: 48.6202259, lng: 18.3912773 },
    { lat: 48.6205261, lng: 18.3913775 },
    { lat: 48.6210804, lng: 18.3910847 },
    { lat: 48.6212832, lng: 18.3909951 },
    { lat: 48.6215585, lng: 18.3907129 },
    { lat: 48.6214144, lng: 18.3898922 },
    { lat: 48.6212564, lng: 18.3894884 },
    { lat: 48.6211065, lng: 18.389087 },
    { lat: 48.6210506, lng: 18.3884302 },
    { lat: 48.6213811, lng: 18.388131 },
    { lat: 48.6214056, lng: 18.388104 },
    { lat: 48.6213221, lng: 18.3870479 },
  ],
  Nadlice: [
    { lat: 48.628619, lng: 18.215562 },
    { lat: 48.628371, lng: 18.216167 },
    { lat: 48.628229, lng: 18.21657 },
    { lat: 48.628095, lng: 18.216968 },
    { lat: 48.628022, lng: 18.217216 },
    { lat: 48.627993, lng: 18.217406 },
    { lat: 48.627975, lng: 18.217606 },
    { lat: 48.62798, lng: 18.218302 },
    { lat: 48.627984, lng: 18.218851 },
    { lat: 48.627998, lng: 18.219169 },
    { lat: 48.627997, lng: 18.219624 },
    { lat: 48.627984, lng: 18.219889 },
    { lat: 48.627965, lng: 18.220072 },
    { lat: 48.627969, lng: 18.220526 },
    { lat: 48.627955, lng: 18.221369 },
    { lat: 48.627928, lng: 18.221771 },
    { lat: 48.627904, lng: 18.222261 },
    { lat: 48.627929, lng: 18.22252 },
    { lat: 48.627961, lng: 18.222667 },
    { lat: 48.627998, lng: 18.222884 },
    { lat: 48.628015, lng: 18.223055 },
    { lat: 48.628015, lng: 18.223645 },
    { lat: 48.628028, lng: 18.223816 },
    { lat: 48.628037, lng: 18.223975 },
    { lat: 48.628058, lng: 18.224163 },
    { lat: 48.628088, lng: 18.224543 },
    { lat: 48.628144, lng: 18.224855 },
    { lat: 48.628184, lng: 18.224978 },
    { lat: 48.628205, lng: 18.22509 },
    { lat: 48.628276, lng: 18.225342 },
    { lat: 48.628423, lng: 18.225694 },
    { lat: 48.628605, lng: 18.226127 },
    { lat: 48.628788, lng: 18.225942 },
    { lat: 48.629025, lng: 18.226551 },
    { lat: 48.62929, lng: 18.227107 },
    { lat: 48.629305, lng: 18.227136 },
    { lat: 48.628908, lng: 18.227744 },
    { lat: 48.62841, lng: 18.228499 },
    { lat: 48.627331, lng: 18.230118 },
    { lat: 48.626419, lng: 18.231475 },
    { lat: 48.625906, lng: 18.232213 },
    { lat: 48.625743, lng: 18.232486 },
    { lat: 48.625406, lng: 18.232945 },
    { lat: 48.625146, lng: 18.233261 },
    { lat: 48.624883, lng: 18.233536 },
    { lat: 48.62431, lng: 18.234169 },
    { lat: 48.623496, lng: 18.235082 },
    { lat: 48.622718, lng: 18.235948 },
    { lat: 48.622199, lng: 18.236568 },
    { lat: 48.621967, lng: 18.236783 },
    { lat: 48.621605, lng: 18.237178 },
    { lat: 48.621154, lng: 18.237762 },
    { lat: 48.620848, lng: 18.238179 },
    { lat: 48.620689, lng: 18.23844 },
    { lat: 48.620484, lng: 18.23869 },
    { lat: 48.620368, lng: 18.238863 },
    { lat: 48.620062, lng: 18.239274 },
    { lat: 48.619803, lng: 18.239566 },
    { lat: 48.619574, lng: 18.239835 },
    { lat: 48.619117, lng: 18.240348 },
    { lat: 48.619078, lng: 18.240396 },
    { lat: 48.619009, lng: 18.240502 },
    { lat: 48.618885, lng: 18.240664 },
    { lat: 48.618472, lng: 18.241212 },
    { lat: 48.618073, lng: 18.241818 },
    { lat: 48.617811, lng: 18.241687 },
    { lat: 48.617755, lng: 18.24207 },
    { lat: 48.616524, lng: 18.244144 },
    { lat: 48.616047, lng: 18.244936 },
    { lat: 48.61598, lng: 18.245048 },
    { lat: 48.615979, lng: 18.245178 },
    { lat: 48.615936, lng: 18.245313 },
    { lat: 48.615803, lng: 18.245453 },
    { lat: 48.615733, lng: 18.245536 },
    { lat: 48.615658, lng: 18.245582 },
    { lat: 48.615577, lng: 18.245806 },
    { lat: 48.61476, lng: 18.24728 },
    { lat: 48.614619, lng: 18.247645 },
    { lat: 48.614535, lng: 18.247845 },
    { lat: 48.614476, lng: 18.247998 },
    { lat: 48.614403, lng: 18.248145 },
    { lat: 48.614288, lng: 18.248333 },
    { lat: 48.614218, lng: 18.248444 },
    { lat: 48.614128, lng: 18.248579 },
    { lat: 48.613975, lng: 18.248773 },
    { lat: 48.613627, lng: 18.249177 },
    { lat: 48.612851, lng: 18.250098 },
    { lat: 48.612726, lng: 18.250227 },
    { lat: 48.612029, lng: 18.251201 },
    { lat: 48.611381, lng: 18.252444 },
    { lat: 48.610767, lng: 18.253569 },
    { lat: 48.610006, lng: 18.254946 },
    { lat: 48.609446, lng: 18.255922 },
    { lat: 48.609355, lng: 18.256094 },
    { lat: 48.6094612, lng: 18.2563474 },
    { lat: 48.6095471, lng: 18.2565274 },
    { lat: 48.6097654, lng: 18.2564002 },
    { lat: 48.6099283, lng: 18.2561466 },
    { lat: 48.6101848, lng: 18.2565105 },
    { lat: 48.61008, lng: 18.2567284 },
    { lat: 48.6103053, lng: 18.2570378 },
    { lat: 48.6102705, lng: 18.2571989 },
    { lat: 48.6102698, lng: 18.257307 },
    { lat: 48.6102466, lng: 18.2573143 },
    { lat: 48.6102765, lng: 18.2577387 },
    { lat: 48.6103284, lng: 18.2578774 },
    { lat: 48.6104333, lng: 18.2580157 },
    { lat: 48.6105208, lng: 18.2582232 },
    { lat: 48.6106126, lng: 18.2580926 },
    { lat: 48.6112609, lng: 18.2586177 },
    { lat: 48.6116403, lng: 18.258761 },
    { lat: 48.6120298, lng: 18.2591586 },
    { lat: 48.6126106, lng: 18.2596872 },
    { lat: 48.6126383, lng: 18.2596372 },
    { lat: 48.6130752, lng: 18.259997 },
    { lat: 48.6132155, lng: 18.2599652 },
    { lat: 48.6138043, lng: 18.2596063 },
    { lat: 48.6139005, lng: 18.2595721 },
    { lat: 48.614177, lng: 18.2596524 },
    { lat: 48.6142444, lng: 18.2597064 },
    { lat: 48.6143178, lng: 18.2598238 },
    { lat: 48.6144496, lng: 18.2600584 },
    { lat: 48.6146017, lng: 18.2603896 },
    { lat: 48.6147268, lng: 18.260705 },
    { lat: 48.6147258, lng: 18.2607875 },
    { lat: 48.6146975, lng: 18.2608635 },
    { lat: 48.614575, lng: 18.2609704 },
    { lat: 48.6145035, lng: 18.2610799 },
    { lat: 48.6144819, lng: 18.2611789 },
    { lat: 48.6145137, lng: 18.2612803 },
    { lat: 48.6145791, lng: 18.2613767 },
    { lat: 48.6151231, lng: 18.2613599 },
    { lat: 48.615257, lng: 18.261274 },
    { lat: 48.6153217, lng: 18.2611275 },
    { lat: 48.6154109, lng: 18.2609256 },
    { lat: 48.6154836, lng: 18.2608944 },
    { lat: 48.6155483, lng: 18.2609224 },
    { lat: 48.6157169, lng: 18.2610884 },
    { lat: 48.6158006, lng: 18.2610708 },
    { lat: 48.6158684, lng: 18.260976 },
    { lat: 48.615972, lng: 18.2607558 },
    { lat: 48.6160724, lng: 18.2606347 },
    { lat: 48.6161712, lng: 18.260589 },
    { lat: 48.6163668, lng: 18.260656 },
    { lat: 48.6167058, lng: 18.261078 },
    { lat: 48.6167526, lng: 18.2611679 },
    { lat: 48.6167661, lng: 18.2612435 },
    { lat: 48.6167316, lng: 18.2613924 },
    { lat: 48.6164962, lng: 18.2615182 },
    { lat: 48.6164971, lng: 18.2615916 },
    { lat: 48.6165855, lng: 18.2617159 },
    { lat: 48.6168338, lng: 18.2623331 },
    { lat: 48.6169072, lng: 18.2623735 },
    { lat: 48.6171491, lng: 18.2623813 },
    { lat: 48.6172593, lng: 18.2624148 },
    { lat: 48.617351, lng: 18.2625969 },
    { lat: 48.6174753, lng: 18.2629352 },
    { lat: 48.6175393, lng: 18.2630702 },
    { lat: 48.6177249, lng: 18.2632145 },
    { lat: 48.6178216, lng: 18.2633681 },
    { lat: 48.6178759, lng: 18.2637847 },
    { lat: 48.6179233, lng: 18.2638456 },
    { lat: 48.6179967, lng: 18.2638838 },
    { lat: 48.6182876, lng: 18.2638229 },
    { lat: 48.6183149, lng: 18.2637876 },
    { lat: 48.6183609, lng: 18.2635463 },
    { lat: 48.6184657, lng: 18.2633437 },
    { lat: 48.618534, lng: 18.2628423 },
    { lat: 48.6185701, lng: 18.2627032 },
    { lat: 48.6186083, lng: 18.2626608 },
    { lat: 48.6187354, lng: 18.26289 },
    { lat: 48.6189119, lng: 18.2629377 },
    { lat: 48.6191604, lng: 18.2629621 },
    { lat: 48.6192942, lng: 18.2628962 },
    { lat: 48.6193488, lng: 18.2628046 },
    { lat: 48.6193212, lng: 18.2626363 },
    { lat: 48.6193667, lng: 18.2624261 },
    { lat: 48.6194014, lng: 18.2623791 },
    { lat: 48.619549, lng: 18.2625964 },
    { lat: 48.6198139, lng: 18.2626679 },
    { lat: 48.6198652, lng: 18.2627179 },
    { lat: 48.6198994, lng: 18.2628027 },
    { lat: 48.6199052, lng: 18.2630038 },
    { lat: 48.6198822, lng: 18.2631572 },
    { lat: 48.619943, lng: 18.2632899 },
    { lat: 48.6199577, lng: 18.2633892 },
    { lat: 48.6199375, lng: 18.2636704 },
    { lat: 48.61994, lng: 18.2638716 },
    { lat: 48.6200231, lng: 18.2641011 },
    { lat: 48.6201801, lng: 18.264283 },
    { lat: 48.6202478, lng: 18.2642432 },
    { lat: 48.6204262, lng: 18.2639882 },
    { lat: 48.6205238, lng: 18.2637804 },
    { lat: 48.6204984, lng: 18.2636339 },
    { lat: 48.620549, lng: 18.2633927 },
    { lat: 48.6206149, lng: 18.2633788 },
    { lat: 48.6207159, lng: 18.263131 },
    { lat: 48.6206887, lng: 18.2629537 },
    { lat: 48.6207371, lng: 18.262476 },
    { lat: 48.6208016, lng: 18.2623417 },
    { lat: 48.6208502, lng: 18.2623229 },
    { lat: 48.6214934, lng: 18.2627758 },
    { lat: 48.6216255, lng: 18.2630361 },
    { lat: 48.6217887, lng: 18.2631283 },
    { lat: 48.6219582, lng: 18.2631908 },
    { lat: 48.62214, lng: 18.2632143 },
    { lat: 48.6222633, lng: 18.2630795 },
    { lat: 48.6224404, lng: 18.2627655 },
    { lat: 48.6224123, lng: 18.2624678 },
    { lat: 48.6224812, lng: 18.2622909 },
    { lat: 48.6226296, lng: 18.2621443 },
    { lat: 48.6227337, lng: 18.2621283 },
    { lat: 48.6233162, lng: 18.2623708 },
    { lat: 48.6234463, lng: 18.2625746 },
    { lat: 48.623371, lng: 18.2630541 },
    { lat: 48.6234865, lng: 18.2634161 },
    { lat: 48.6235492, lng: 18.2634871 },
    { lat: 48.6236814, lng: 18.2633929 },
    { lat: 48.6237897, lng: 18.2634142 },
    { lat: 48.6238447, lng: 18.2634873 },
    { lat: 48.623862, lng: 18.2635917 },
    { lat: 48.6239334, lng: 18.2638567 },
    { lat: 48.6239973, lng: 18.263949 },
    { lat: 48.6243028, lng: 18.2640819 },
    { lat: 48.624423, lng: 18.2642689 },
    { lat: 48.624483, lng: 18.2643093 },
    { lat: 48.6246948, lng: 18.2641469 },
    { lat: 48.6247636, lng: 18.2641469 },
    { lat: 48.624838, lng: 18.2642036 },
    { lat: 48.6249881, lng: 18.2645419 },
    { lat: 48.625126, lng: 18.2645849 },
    { lat: 48.6254322, lng: 18.2647506 },
    { lat: 48.6254357, lng: 18.2647908 },
    { lat: 48.6252986, lng: 18.2651193 },
    { lat: 48.6253073, lng: 18.2651524 },
    { lat: 48.6253696, lng: 18.2651739 },
    { lat: 48.6254719, lng: 18.2651692 },
    { lat: 48.6256007, lng: 18.2653137 },
    { lat: 48.6257732, lng: 18.2654069 },
    { lat: 48.6258907, lng: 18.2654305 },
    { lat: 48.6259521, lng: 18.2654967 },
    { lat: 48.6259753, lng: 18.2656289 },
    { lat: 48.6259641, lng: 18.2656906 },
    { lat: 48.6258391, lng: 18.2658488 },
    { lat: 48.6257846, lng: 18.2659832 },
    { lat: 48.6257808, lng: 18.2660543 },
    { lat: 48.626004, lng: 18.2663691 },
    { lat: 48.6261463, lng: 18.266509 },
    { lat: 48.6264881, lng: 18.2667436 },
    { lat: 48.6266018, lng: 18.2667606 },
    { lat: 48.62655, lng: 18.266921 },
    { lat: 48.626495, lng: 18.2670063 },
    { lat: 48.6264514, lng: 18.2671289 },
    { lat: 48.6264293, lng: 18.2673747 },
    { lat: 48.6264092, lng: 18.2674365 },
    { lat: 48.6263179, lng: 18.2675404 },
    { lat: 48.6262524, lng: 18.2676555 },
    { lat: 48.6262387, lng: 18.2677361 },
    { lat: 48.6263853, lng: 18.2679682 },
    { lat: 48.6263748, lng: 18.2680409 },
    { lat: 48.6262412, lng: 18.2681731 },
    { lat: 48.6262879, lng: 18.268599 },
    { lat: 48.6263136, lng: 18.2686916 },
    { lat: 48.6263608, lng: 18.2687079 },
    { lat: 48.626561, lng: 18.2686563 },
    { lat: 48.6266843, lng: 18.2688785 },
    { lat: 48.62667, lng: 18.2689638 },
    { lat: 48.6265908, lng: 18.2690793 },
    { lat: 48.626712, lng: 18.2691481 },
    { lat: 48.6267777, lng: 18.269309 },
    { lat: 48.626881, lng: 18.2693591 },
    { lat: 48.6268998, lng: 18.2694723 },
    { lat: 48.6268725, lng: 18.2695671 },
    { lat: 48.6268754, lng: 18.2697797 },
    { lat: 48.6269934, lng: 18.2700286 },
    { lat: 48.6271661, lng: 18.2701468 },
    { lat: 48.6271891, lng: 18.2702344 },
    { lat: 48.6271936, lng: 18.2703504 },
    { lat: 48.6270856, lng: 18.2705346 },
    { lat: 48.6271712, lng: 18.2707286 },
    { lat: 48.627121, lng: 18.2708774 },
    { lat: 48.6271527, lng: 18.2709978 },
    { lat: 48.6272831, lng: 18.2711121 },
    { lat: 48.6273047, lng: 18.2712134 },
    { lat: 48.6275195, lng: 18.2711403 },
    { lat: 48.6275523, lng: 18.270845 },
    { lat: 48.6276623, lng: 18.2705971 },
    { lat: 48.6279164, lng: 18.2705837 },
    { lat: 48.6279458, lng: 18.2705008 },
    { lat: 48.6280284, lng: 18.2702496 },
    { lat: 48.6281899, lng: 18.2699137 },
    { lat: 48.6285771, lng: 18.2691084 },
    { lat: 48.6291667, lng: 18.2678712 },
    { lat: 48.6299136, lng: 18.2666842 },
    { lat: 48.6312265, lng: 18.2646568 },
    { lat: 48.6312207, lng: 18.2645334 },
    { lat: 48.6311688, lng: 18.2641999 },
    { lat: 48.6311459, lng: 18.2640769 },
    { lat: 48.6310835, lng: 18.2638318 },
    { lat: 48.6310396, lng: 18.2636348 },
    { lat: 48.6309892, lng: 18.2633653 },
    { lat: 48.6309576, lng: 18.2625754 },
    { lat: 48.630956, lng: 18.2624051 },
    { lat: 48.6309503, lng: 18.2622681 },
    { lat: 48.6309168, lng: 18.2618976 },
    { lat: 48.6308969, lng: 18.2616312 },
    { lat: 48.6308332, lng: 18.2608508 },
    { lat: 48.6307567, lng: 18.2605953 },
    { lat: 48.630677, lng: 18.2604045 },
    { lat: 48.6306221, lng: 18.2602925 },
    { lat: 48.6305688, lng: 18.260211 },
    { lat: 48.6305392, lng: 18.2601652 },
    { lat: 48.6305013, lng: 18.2601349 },
    { lat: 48.6304598, lng: 18.2600999 },
    { lat: 48.6303786, lng: 18.2601608 },
    { lat: 48.6303252, lng: 18.2601938 },
    { lat: 48.6302733, lng: 18.2601916 },
    { lat: 48.6302645, lng: 18.2601949 },
    { lat: 48.6302145, lng: 18.2601108 },
    { lat: 48.630205, lng: 18.2599443 },
    { lat: 48.6301934, lng: 18.259882 },
    { lat: 48.6301609, lng: 18.2598431 },
    { lat: 48.6301472, lng: 18.2598224 },
    { lat: 48.6298612, lng: 18.2598528 },
    { lat: 48.6298432, lng: 18.2598323 },
    { lat: 48.6298137, lng: 18.259733 },
    { lat: 48.6298765, lng: 18.2596064 },
    { lat: 48.6298425, lng: 18.2594468 },
    { lat: 48.629992, lng: 18.2587846 },
    { lat: 48.6300996, lng: 18.2583967 },
    { lat: 48.6302566, lng: 18.2576269 },
    { lat: 48.6304356, lng: 18.256769 },
    { lat: 48.630537, lng: 18.2562928 },
    { lat: 48.6307172, lng: 18.2554778 },
    { lat: 48.6308329, lng: 18.2549366 },
    { lat: 48.6309172, lng: 18.254534 },
    { lat: 48.6310294, lng: 18.2540307 },
    { lat: 48.6311607, lng: 18.2539576 },
    { lat: 48.6312484, lng: 18.2536391 },
    { lat: 48.6313527, lng: 18.2533287 },
    { lat: 48.6313892, lng: 18.2532566 },
    { lat: 48.6315437, lng: 18.252939 },
    { lat: 48.6317775, lng: 18.2524712 },
    { lat: 48.6319183, lng: 18.2520512 },
    { lat: 48.6319813, lng: 18.25187 },
    { lat: 48.6321244, lng: 18.2515322 },
    { lat: 48.6322534, lng: 18.2511836 },
    { lat: 48.6323261, lng: 18.2509733 },
    { lat: 48.6324299, lng: 18.2506598 },
    { lat: 48.6324834, lng: 18.2505662 },
    { lat: 48.6325843, lng: 18.2504778 },
    { lat: 48.6326552, lng: 18.2504154 },
    { lat: 48.6326957, lng: 18.2503755 },
    { lat: 48.6327686, lng: 18.2502851 },
    { lat: 48.6328735, lng: 18.2498911 },
    { lat: 48.6329804, lng: 18.2495893 },
    { lat: 48.6331349, lng: 18.249194 },
    { lat: 48.6332811, lng: 18.2487522 },
    { lat: 48.633433, lng: 18.2482298 },
    { lat: 48.6335386, lng: 18.2478854 },
    { lat: 48.6335967, lng: 18.2476199 },
    { lat: 48.6333213, lng: 18.2474624 },
    { lat: 48.6333594, lng: 18.2471992 },
    { lat: 48.6334008, lng: 18.2469762 },
    { lat: 48.6334419, lng: 18.2468881 },
    { lat: 48.6335689, lng: 18.2467606 },
    { lat: 48.6336201, lng: 18.246689 },
    { lat: 48.6334538, lng: 18.2466207 },
    { lat: 48.6335588, lng: 18.2462079 },
    { lat: 48.633627, lng: 18.2461194 },
    { lat: 48.6337845, lng: 18.2460904 },
    { lat: 48.634274, lng: 18.2460463 },
    { lat: 48.6344516, lng: 18.2460338 },
    { lat: 48.6346408, lng: 18.2460895 },
    { lat: 48.6348647, lng: 18.245365 },
    { lat: 48.6350346, lng: 18.2449465 },
    { lat: 48.6353142, lng: 18.2443697 },
    { lat: 48.6353546, lng: 18.2442859 },
    { lat: 48.6354642, lng: 18.2441097 },
    { lat: 48.6357024, lng: 18.2435785 },
    { lat: 48.6358867, lng: 18.2431713 },
    { lat: 48.636163, lng: 18.2425378 },
    { lat: 48.6363141, lng: 18.2421242 },
    { lat: 48.6364838, lng: 18.2420126 },
    { lat: 48.6366139, lng: 18.2418804 },
    { lat: 48.6366999, lng: 18.2417679 },
    { lat: 48.6369918, lng: 18.2413281 },
    { lat: 48.6372379, lng: 18.2410142 },
    { lat: 48.6378595, lng: 18.2401833 },
    { lat: 48.6379418, lng: 18.240104 },
    { lat: 48.6381603, lng: 18.2398779 },
    { lat: 48.6386072, lng: 18.2394279 },
    { lat: 48.6389034, lng: 18.2391417 },
    { lat: 48.6391813, lng: 18.2388911 },
    { lat: 48.6393365, lng: 18.2387228 },
    { lat: 48.6395839, lng: 18.2384515 },
    { lat: 48.6399526, lng: 18.2380363 },
    { lat: 48.6400731, lng: 18.2378946 },
    { lat: 48.6403278, lng: 18.2376513 },
    { lat: 48.6404959, lng: 18.2375323 },
    { lat: 48.6408635, lng: 18.237311 },
    { lat: 48.6410097, lng: 18.2372212 },
    { lat: 48.6412619, lng: 18.2371177 },
    { lat: 48.6415382, lng: 18.2370335 },
    { lat: 48.641436, lng: 18.2369623 },
    { lat: 48.6413266, lng: 18.2368056 },
    { lat: 48.6411765, lng: 18.2364923 },
    { lat: 48.6410504, lng: 18.2362811 },
    { lat: 48.6409226, lng: 18.2360398 },
    { lat: 48.6409066, lng: 18.236017 },
    { lat: 48.6407377, lng: 18.2358042 },
    { lat: 48.6405367, lng: 18.2355646 },
    { lat: 48.6401251, lng: 18.2351584 },
    { lat: 48.6396743, lng: 18.2347142 },
    { lat: 48.6395116, lng: 18.2345113 },
    { lat: 48.6391946, lng: 18.234141 },
    { lat: 48.6390319, lng: 18.2339389 },
    { lat: 48.6387438, lng: 18.2335779 },
    { lat: 48.6386069, lng: 18.2334138 },
    { lat: 48.6384155, lng: 18.2331067 },
    { lat: 48.6382305, lng: 18.2328743 },
    { lat: 48.6380878, lng: 18.2327056 },
    { lat: 48.6378488, lng: 18.2324357 },
    { lat: 48.6377117, lng: 18.2324029 },
    { lat: 48.6375217, lng: 18.2322183 },
    { lat: 48.6373841, lng: 18.2320828 },
    { lat: 48.6370355, lng: 18.231768 },
    { lat: 48.6368362, lng: 18.2315799 },
    { lat: 48.6365869, lng: 18.2312279 },
    { lat: 48.6363253, lng: 18.2308343 },
    { lat: 48.636038, lng: 18.2303523 },
    { lat: 48.6356955, lng: 18.2297731 },
    { lat: 48.6355105, lng: 18.2294772 },
    { lat: 48.6351148, lng: 18.2288735 },
    { lat: 48.6348482, lng: 18.2284934 },
    { lat: 48.6345366, lng: 18.2280568 },
    { lat: 48.6344405, lng: 18.2279349 },
    { lat: 48.6343349, lng: 18.2278085 },
    { lat: 48.6341355, lng: 18.2274785 },
    { lat: 48.6338896, lng: 18.2270668 },
    { lat: 48.6336092, lng: 18.226584 },
    { lat: 48.6333534, lng: 18.2260213 },
    { lat: 48.6332271, lng: 18.2257078 },
    { lat: 48.6330333, lng: 18.2251445 },
    { lat: 48.6329533, lng: 18.22483 },
    { lat: 48.6328147, lng: 18.2244011 },
    { lat: 48.6326865, lng: 18.2240739 },
    { lat: 48.6325898, lng: 18.2238069 },
    { lat: 48.6324858, lng: 18.2235279 },
    { lat: 48.6321393, lng: 18.2225836 },
    { lat: 48.6320713, lng: 18.222341 },
    { lat: 48.6319705, lng: 18.2219404 },
    { lat: 48.6319439, lng: 18.2218108 },
    { lat: 48.6318902, lng: 18.2216252 },
    { lat: 48.6315704, lng: 18.2206721 },
    { lat: 48.6315214, lng: 18.2205483 },
    { lat: 48.6313687, lng: 18.2201873 },
    { lat: 48.6311935, lng: 18.2198285 },
    { lat: 48.6309284, lng: 18.2193391 },
    { lat: 48.6307973, lng: 18.2191393 },
    { lat: 48.6305099, lng: 18.218553 },
    { lat: 48.6299709, lng: 18.2177567 },
    { lat: 48.628619, lng: 18.215562 },
  ],
  Nedanovce: [
    { lat: 48.5933877, lng: 18.2708724 },
    { lat: 48.5932733, lng: 18.2706662 },
    { lat: 48.5932283, lng: 18.270511 },
    { lat: 48.5930559, lng: 18.2702745 },
    { lat: 48.5928223, lng: 18.2704734 },
    { lat: 48.5926018, lng: 18.2706617 },
    { lat: 48.5923887, lng: 18.2708422 },
    { lat: 48.5921594, lng: 18.2710384 },
    { lat: 48.5919872, lng: 18.2712029 },
    { lat: 48.5909346, lng: 18.2719756 },
    { lat: 48.5904935, lng: 18.2721382 },
    { lat: 48.5895884, lng: 18.2725485 },
    { lat: 48.5893257, lng: 18.2727308 },
    { lat: 48.5892954, lng: 18.2727518 },
    { lat: 48.5892711, lng: 18.27276 },
    { lat: 48.5892212, lng: 18.2727771 },
    { lat: 48.589188, lng: 18.2727885 },
    { lat: 48.5891747, lng: 18.2727956 },
    { lat: 48.5864104, lng: 18.2742614 },
    { lat: 48.5861718, lng: 18.2743326 },
    { lat: 48.5857586, lng: 18.2743665 },
    { lat: 48.5854943, lng: 18.274343 },
    { lat: 48.5850099, lng: 18.2743576 },
    { lat: 48.5846302, lng: 18.2743658 },
    { lat: 48.58455, lng: 18.2744222 },
    { lat: 48.5843635, lng: 18.2746352 },
    { lat: 48.5840759, lng: 18.2751521 },
    { lat: 48.5831849, lng: 18.2765651 },
    { lat: 48.5826146, lng: 18.2774282 },
    { lat: 48.5820674, lng: 18.2780988 },
    { lat: 48.5800469, lng: 18.2795534 },
    { lat: 48.5794378, lng: 18.2799924 },
    { lat: 48.5783953, lng: 18.2805286 },
    { lat: 48.5782608, lng: 18.2820094 },
    { lat: 48.5782354, lng: 18.2833653 },
    { lat: 48.5782433, lng: 18.2841403 },
    { lat: 48.578197, lng: 18.2859803 },
    { lat: 48.5781446, lng: 18.2881336 },
    { lat: 48.5781261, lng: 18.2887399 },
    { lat: 48.578094, lng: 18.2897044 },
    { lat: 48.5780076, lng: 18.2913774 },
    { lat: 48.5772679, lng: 18.2924778 },
    { lat: 48.5775073, lng: 18.2940478 },
    { lat: 48.5777634, lng: 18.2956534 },
    { lat: 48.5775172, lng: 18.2964263 },
    { lat: 48.5774563, lng: 18.2966658 },
    { lat: 48.5773889, lng: 18.2970066 },
    { lat: 48.577339, lng: 18.297323 },
    { lat: 48.5772633, lng: 18.297895 },
    { lat: 48.5772515, lng: 18.2982415 },
    { lat: 48.5772505, lng: 18.2987226 },
    { lat: 48.5772051, lng: 18.2988935 },
    { lat: 48.5771417, lng: 18.2989508 },
    { lat: 48.5771691, lng: 18.2991343 },
    { lat: 48.577741, lng: 18.3025891 },
    { lat: 48.5777464, lng: 18.3026259 },
    { lat: 48.5773458, lng: 18.3032409 },
    { lat: 48.5768136, lng: 18.3038033 },
    { lat: 48.5763428, lng: 18.3042955 },
    { lat: 48.5761714, lng: 18.304458 },
    { lat: 48.5758397, lng: 18.3046572 },
    { lat: 48.5750904, lng: 18.3051695 },
    { lat: 48.5743772, lng: 18.3055105 },
    { lat: 48.5737008, lng: 18.3056775 },
    { lat: 48.5731477, lng: 18.3057726 },
    { lat: 48.5727111, lng: 18.3057943 },
    { lat: 48.5720978, lng: 18.3057451 },
    { lat: 48.5712932, lng: 18.3056411 },
    { lat: 48.5705529, lng: 18.3055192 },
    { lat: 48.5703254, lng: 18.3054473 },
    { lat: 48.5703057, lng: 18.3066291 },
    { lat: 48.5703116, lng: 18.3077712 },
    { lat: 48.5702843, lng: 18.3093583 },
    { lat: 48.5702066, lng: 18.3109095 },
    { lat: 48.5701759, lng: 18.3117817 },
    { lat: 48.5701612, lng: 18.312541 },
    { lat: 48.570156, lng: 18.3135833 },
    { lat: 48.5701321, lng: 18.3139964 },
    { lat: 48.5700736, lng: 18.3148159 },
    { lat: 48.5698752, lng: 18.3160349 },
    { lat: 48.5695538, lng: 18.317731 },
    { lat: 48.5695282, lng: 18.3179926 },
    { lat: 48.56956, lng: 18.3180993 },
    { lat: 48.5696731, lng: 18.3179052 },
    { lat: 48.5698829, lng: 18.3176441 },
    { lat: 48.5699875, lng: 18.317636 },
    { lat: 48.5705905, lng: 18.3177256 },
    { lat: 48.5707283, lng: 18.3177093 },
    { lat: 48.5711572, lng: 18.3175123 },
    { lat: 48.5715736, lng: 18.3175332 },
    { lat: 48.5719684, lng: 18.3174917 },
    { lat: 48.5728723, lng: 18.317317 },
    { lat: 48.5734039, lng: 18.317297 },
    { lat: 48.5733482, lng: 18.3170103 },
    { lat: 48.5736124, lng: 18.3168541 },
    { lat: 48.5740821, lng: 18.3164812 },
    { lat: 48.5741895, lng: 18.3163648 },
    { lat: 48.574498, lng: 18.3159168 },
    { lat: 48.5754489, lng: 18.3150939 },
    { lat: 48.5757499, lng: 18.3148906 },
    { lat: 48.5759074, lng: 18.3147652 },
    { lat: 48.5765411, lng: 18.3140206 },
    { lat: 48.5768172, lng: 18.3137146 },
    { lat: 48.5770964, lng: 18.3134253 },
    { lat: 48.5776555, lng: 18.3131784 },
    { lat: 48.5782001, lng: 18.3129715 },
    { lat: 48.5786226, lng: 18.3127036 },
    { lat: 48.5788931, lng: 18.3131342 },
    { lat: 48.5792908, lng: 18.3137806 },
    { lat: 48.5795904, lng: 18.3143485 },
    { lat: 48.580029, lng: 18.315221 },
    { lat: 48.5800699, lng: 18.3152044 },
    { lat: 48.5806003, lng: 18.3150474 },
    { lat: 48.5807313, lng: 18.3150309 },
    { lat: 48.581226, lng: 18.3148403 },
    { lat: 48.5817702, lng: 18.3147471 },
    { lat: 48.5830035, lng: 18.31467 },
    { lat: 48.583137, lng: 18.314656 },
    { lat: 48.5841692, lng: 18.3141743 },
    { lat: 48.5849354, lng: 18.3142738 },
    { lat: 48.5855236, lng: 18.3141022 },
    { lat: 48.5860143, lng: 18.3139026 },
    { lat: 48.5862066, lng: 18.3139712 },
    { lat: 48.5862323, lng: 18.3139867 },
    { lat: 48.5862552, lng: 18.3140112 },
    { lat: 48.5868779, lng: 18.3134721 },
    { lat: 48.5876818, lng: 18.312705 },
    { lat: 48.5878687, lng: 18.3125776 },
    { lat: 48.5882636, lng: 18.3123781 },
    { lat: 48.5888151, lng: 18.3122558 },
    { lat: 48.5889861, lng: 18.3121689 },
    { lat: 48.5892906, lng: 18.3119265 },
    { lat: 48.5899205, lng: 18.3109348 },
    { lat: 48.5900773, lng: 18.3105764 },
    { lat: 48.590139, lng: 18.3103132 },
    { lat: 48.590191, lng: 18.3099747 },
    { lat: 48.5902323, lng: 18.3095282 },
    { lat: 48.5903326, lng: 18.3088901 },
    { lat: 48.5903741, lng: 18.3087668 },
    { lat: 48.5905793, lng: 18.3084827 },
    { lat: 48.5907825, lng: 18.3082266 },
    { lat: 48.5916808, lng: 18.3071781 },
    { lat: 48.5939357, lng: 18.3047186 },
    { lat: 48.5954255, lng: 18.3031575 },
    { lat: 48.5954077, lng: 18.303122 },
    { lat: 48.5954869, lng: 18.3030679 },
    { lat: 48.5955119, lng: 18.3030508 },
    { lat: 48.5957215, lng: 18.3028651 },
    { lat: 48.5963164, lng: 18.3021151 },
    { lat: 48.5968287, lng: 18.3014983 },
    { lat: 48.5979231, lng: 18.3000471 },
    { lat: 48.5981121, lng: 18.2997999 },
    { lat: 48.5992347, lng: 18.2983304 },
    { lat: 48.5996588, lng: 18.2976508 },
    { lat: 48.599887, lng: 18.2973169 },
    { lat: 48.6000199, lng: 18.2971757 },
    { lat: 48.600592, lng: 18.2968587 },
    { lat: 48.6012607, lng: 18.2965937 },
    { lat: 48.6020195, lng: 18.2962869 },
    { lat: 48.6022522, lng: 18.2961923 },
    { lat: 48.6023186, lng: 18.2961655 },
    { lat: 48.6029779, lng: 18.2958979 },
    { lat: 48.6036359, lng: 18.2956813 },
    { lat: 48.6035819, lng: 18.2952669 },
    { lat: 48.6035433, lng: 18.2947457 },
    { lat: 48.6034944, lng: 18.294402 },
    { lat: 48.6034232, lng: 18.2940229 },
    { lat: 48.603337, lng: 18.2937742 },
    { lat: 48.6031958, lng: 18.2932363 },
    { lat: 48.6032508, lng: 18.2929732 },
    { lat: 48.6033708, lng: 18.2927848 },
    { lat: 48.6034379, lng: 18.2923348 },
    { lat: 48.6034448, lng: 18.2918601 },
    { lat: 48.6032869, lng: 18.2917614 },
    { lat: 48.6027752, lng: 18.2915948 },
    { lat: 48.6024408, lng: 18.291526 },
    { lat: 48.6017999, lng: 18.2915272 },
    { lat: 48.6016669, lng: 18.291472 },
    { lat: 48.601286, lng: 18.290901 },
    { lat: 48.6011358, lng: 18.2900855 },
    { lat: 48.6010756, lng: 18.2895047 },
    { lat: 48.6010907, lng: 18.2890554 },
    { lat: 48.6011899, lng: 18.2883806 },
    { lat: 48.6012739, lng: 18.287969 },
    { lat: 48.6014462, lng: 18.2875489 },
    { lat: 48.6015341, lng: 18.2867133 },
    { lat: 48.6015761, lng: 18.2861047 },
    { lat: 48.6014931, lng: 18.285498 },
    { lat: 48.6013999, lng: 18.2845525 },
    { lat: 48.6014091, lng: 18.2833475 },
    { lat: 48.6015034, lng: 18.2829617 },
    { lat: 48.6015283, lng: 18.282725 },
    { lat: 48.6015113, lng: 18.2817921 },
    { lat: 48.6014387, lng: 18.2814503 },
    { lat: 48.6012098, lng: 18.2809353 },
    { lat: 48.6006855, lng: 18.2802905 },
    { lat: 48.6005526, lng: 18.2801943 },
    { lat: 48.6004079, lng: 18.2801111 },
    { lat: 48.5997555, lng: 18.2798697 },
    { lat: 48.5993419, lng: 18.2798556 },
    { lat: 48.5986892, lng: 18.2800648 },
    { lat: 48.5981328, lng: 18.2801233 },
    { lat: 48.5978462, lng: 18.2800267 },
    { lat: 48.597548, lng: 18.2797595 },
    { lat: 48.5973786, lng: 18.2794791 },
    { lat: 48.5971264, lng: 18.2784805 },
    { lat: 48.597022, lng: 18.2781554 },
    { lat: 48.5967752, lng: 18.2771094 },
    { lat: 48.5966037, lng: 18.2767937 },
    { lat: 48.5964067, lng: 18.2765651 },
    { lat: 48.5960691, lng: 18.2763001 },
    { lat: 48.5957653, lng: 18.2758811 },
    { lat: 48.5953849, lng: 18.2746129 },
    { lat: 48.5953773, lng: 18.2744164 },
    { lat: 48.5953554, lng: 18.2743312 },
    { lat: 48.5949588, lng: 18.2740058 },
    { lat: 48.5945847, lng: 18.2739933 },
    { lat: 48.5943794, lng: 18.2741743 },
    { lat: 48.5940103, lng: 18.2744103 },
    { lat: 48.5936827, lng: 18.2747978 },
    { lat: 48.5936111, lng: 18.2755413 },
    { lat: 48.5934873, lng: 18.2757837 },
    { lat: 48.5934049, lng: 18.2758432 },
    { lat: 48.5933453, lng: 18.2758053 },
    { lat: 48.5931737, lng: 18.2755456 },
    { lat: 48.5928575, lng: 18.2744955 },
    { lat: 48.5928204, lng: 18.2741998 },
    { lat: 48.5928276, lng: 18.2738136 },
    { lat: 48.5929502, lng: 18.2734295 },
    { lat: 48.5931195, lng: 18.2731303 },
    { lat: 48.593545, lng: 18.2724817 },
    { lat: 48.5935822, lng: 18.2723421 },
    { lat: 48.5934616, lng: 18.2716702 },
    { lat: 48.5933877, lng: 18.2708724 },
  ],
  Pažiť: [
    { lat: 48.637758, lng: 18.438864 },
    { lat: 48.6379244, lng: 18.4386646 },
    { lat: 48.6379468, lng: 18.4383782 },
    { lat: 48.6379764, lng: 18.4381208 },
    { lat: 48.6380015, lng: 18.4379589 },
    { lat: 48.6380483, lng: 18.4377251 },
    { lat: 48.6381154, lng: 18.4375112 },
    { lat: 48.6381796, lng: 18.4371638 },
    { lat: 48.6382246, lng: 18.4369613 },
    { lat: 48.6382382, lng: 18.4368193 },
    { lat: 48.6382829, lng: 18.4366493 },
    { lat: 48.638233, lng: 18.436504 },
    { lat: 48.6380611, lng: 18.4359675 },
    { lat: 48.6378371, lng: 18.4353256 },
    { lat: 48.6378365, lng: 18.4352522 },
    { lat: 48.6377883, lng: 18.4349152 },
    { lat: 48.6377864, lng: 18.4348608 },
    { lat: 48.6377552, lng: 18.4346811 },
    { lat: 48.6377488, lng: 18.4346684 },
    { lat: 48.6377076, lng: 18.4345352 },
    { lat: 48.6375684, lng: 18.4341836 },
    { lat: 48.6374061, lng: 18.4338232 },
    { lat: 48.6373421, lng: 18.4336034 },
    { lat: 48.6372873, lng: 18.4334716 },
    { lat: 48.6371633, lng: 18.4332272 },
    { lat: 48.6370928, lng: 18.4329743 },
    { lat: 48.6368007, lng: 18.4321645 },
    { lat: 48.6366555, lng: 18.4314189 },
    { lat: 48.6364869, lng: 18.4299086 },
    { lat: 48.6363705, lng: 18.4288476 },
    { lat: 48.6364755, lng: 18.4281802 },
    { lat: 48.6365064, lng: 18.4279673 },
    { lat: 48.6365447, lng: 18.427763 },
    { lat: 48.6366405, lng: 18.4270707 },
    { lat: 48.6365952, lng: 18.4267037 },
    { lat: 48.6365564, lng: 18.4263491 },
    { lat: 48.6365227, lng: 18.4260081 },
    { lat: 48.6364655, lng: 18.4253662 },
    { lat: 48.6364366, lng: 18.4249276 },
    { lat: 48.6364147, lng: 18.424716 },
    { lat: 48.6363829, lng: 18.4244885 },
    { lat: 48.6363496, lng: 18.4243148 },
    { lat: 48.6362037, lng: 18.4241285 },
    { lat: 48.6361423, lng: 18.4241581 },
    { lat: 48.6360062, lng: 18.4239427 },
    { lat: 48.6338424, lng: 18.4248349 },
    { lat: 48.6338484, lng: 18.4266645 },
    { lat: 48.6338527, lng: 18.4286495 },
    { lat: 48.6338261, lng: 18.4296134 },
    { lat: 48.6337954, lng: 18.4301358 },
    { lat: 48.6337704, lng: 18.4301322 },
    { lat: 48.6326985, lng: 18.4298053 },
    { lat: 48.6324638, lng: 18.4297205 },
    { lat: 48.6322938, lng: 18.4297071 },
    { lat: 48.6312901, lng: 18.4298558 },
    { lat: 48.6304955, lng: 18.4301953 },
    { lat: 48.6297537, lng: 18.4304479 },
    { lat: 48.6286249, lng: 18.4305158 },
    { lat: 48.6272493, lng: 18.4304891 },
    { lat: 48.6272618, lng: 18.4305324 },
    { lat: 48.6275378, lng: 18.4314561 },
    { lat: 48.6278815, lng: 18.4324436 },
    { lat: 48.6283221, lng: 18.4335529 },
    { lat: 48.6286421, lng: 18.4337468 },
    { lat: 48.6286392, lng: 18.4339411 },
    { lat: 48.6284574, lng: 18.4338942 },
    { lat: 48.6289617, lng: 18.4351335 },
    { lat: 48.6297948, lng: 18.4371765 },
    { lat: 48.6297793, lng: 18.4372003 },
    { lat: 48.6297246, lng: 18.4372931 },
    { lat: 48.6297088, lng: 18.437314 },
    { lat: 48.629601, lng: 18.4370808 },
    { lat: 48.6295878, lng: 18.4370419 },
    { lat: 48.6288016, lng: 18.4368228 },
    { lat: 48.6274889, lng: 18.4364869 },
    { lat: 48.6261739, lng: 18.4361475 },
    { lat: 48.6261706, lng: 18.4362114 },
    { lat: 48.6260773, lng: 18.4370081 },
    { lat: 48.6260682, lng: 18.4371301 },
    { lat: 48.6259257, lng: 18.4385652 },
    { lat: 48.6258172, lng: 18.4398381 },
    { lat: 48.6257353, lng: 18.4408237 },
    { lat: 48.6256937, lng: 18.4412936 },
    { lat: 48.6255624, lng: 18.4426884 },
    { lat: 48.6255111, lng: 18.4431863 },
    { lat: 48.6254439, lng: 18.4439704 },
    { lat: 48.6253132, lng: 18.443953 },
    { lat: 48.6248036, lng: 18.4450743 },
    { lat: 48.6239981, lng: 18.4468422 },
    { lat: 48.623119, lng: 18.4487706 },
    { lat: 48.621765, lng: 18.4517404 },
    { lat: 48.6217315, lng: 18.4517835 },
    { lat: 48.6210235, lng: 18.4530163 },
    { lat: 48.6200926, lng: 18.454639 },
    { lat: 48.6192561, lng: 18.456096 },
    { lat: 48.61854, lng: 18.4573443 },
    { lat: 48.6179854, lng: 18.4583091 },
    { lat: 48.6174372, lng: 18.4591471 },
    { lat: 48.6170181, lng: 18.4589514 },
    { lat: 48.6161927, lng: 18.4585712 },
    { lat: 48.6160997, lng: 18.458621 },
    { lat: 48.6153789, lng: 18.4589931 },
    { lat: 48.6149203, lng: 18.4590605 },
    { lat: 48.6143274, lng: 18.4591494 },
    { lat: 48.6140221, lng: 18.4591992 },
    { lat: 48.6132273, lng: 18.4593296 },
    { lat: 48.6124397, lng: 18.459268 },
    { lat: 48.6118117, lng: 18.4592305 },
    { lat: 48.6112779, lng: 18.4591875 },
    { lat: 48.6108497, lng: 18.4597886 },
    { lat: 48.6105428, lng: 18.4605424 },
    { lat: 48.6102748, lng: 18.4612021 },
    { lat: 48.6099637, lng: 18.461941 },
    { lat: 48.6093667, lng: 18.4625665 },
    { lat: 48.6091666, lng: 18.4627817 },
    { lat: 48.6089714, lng: 18.4632914 },
    { lat: 48.6087189, lng: 18.4639285 },
    { lat: 48.6085694, lng: 18.4643062 },
    { lat: 48.6082616, lng: 18.4650839 },
    { lat: 48.607454, lng: 18.465259 },
    { lat: 48.607468, lng: 18.465276 },
    { lat: 48.607722, lng: 18.465512 },
    { lat: 48.607855, lng: 18.465644 },
    { lat: 48.608437, lng: 18.466219 },
    { lat: 48.609033, lng: 18.466811 },
    { lat: 48.609948, lng: 18.467701 },
    { lat: 48.610857, lng: 18.468558 },
    { lat: 48.611343, lng: 18.469049 },
    { lat: 48.611541, lng: 18.469247 },
    { lat: 48.611564, lng: 18.469271 },
    { lat: 48.611902, lng: 18.469611 },
    { lat: 48.612618, lng: 18.47031 },
    { lat: 48.613485, lng: 18.471158 },
    { lat: 48.614191, lng: 18.471851 },
    { lat: 48.61418, lng: 18.471973 },
    { lat: 48.614633, lng: 18.472395 },
    { lat: 48.615115, lng: 18.472872 },
    { lat: 48.615119, lng: 18.472857 },
    { lat: 48.615277, lng: 18.472893 },
    { lat: 48.615414, lng: 18.473187 },
    { lat: 48.615589, lng: 18.473267 },
    { lat: 48.615886, lng: 18.47353 },
    { lat: 48.615954, lng: 18.473622 },
    { lat: 48.616043, lng: 18.473678 },
    { lat: 48.61652, lng: 18.474182 },
    { lat: 48.616562, lng: 18.474198 },
    { lat: 48.616577, lng: 18.474111 },
    { lat: 48.616576, lng: 18.474048 },
    { lat: 48.616609, lng: 18.473996 },
    { lat: 48.616646, lng: 18.473917 },
    { lat: 48.616759, lng: 18.473785 },
    { lat: 48.616835, lng: 18.473637 },
    { lat: 48.616818, lng: 18.473556 },
    { lat: 48.616886, lng: 18.473352 },
    { lat: 48.616943, lng: 18.473246 },
    { lat: 48.616948, lng: 18.473172 },
    { lat: 48.616932, lng: 18.4731 },
    { lat: 48.616969, lng: 18.472963 },
    { lat: 48.616925, lng: 18.47283 },
    { lat: 48.616906, lng: 18.472666 },
    { lat: 48.616962, lng: 18.472627 },
    { lat: 48.61716, lng: 18.472537 },
    { lat: 48.617155, lng: 18.472349 },
    { lat: 48.617187, lng: 18.472302 },
    { lat: 48.617163, lng: 18.472148 },
    { lat: 48.617234, lng: 18.472013 },
    { lat: 48.617322, lng: 18.472045 },
    { lat: 48.61736, lng: 18.472001 },
    { lat: 48.617342, lng: 18.471831 },
    { lat: 48.617329, lng: 18.471795 },
    { lat: 48.617391, lng: 18.471594 },
    { lat: 48.6174, lng: 18.471527 },
    { lat: 48.61744, lng: 18.471463 },
    { lat: 48.617456, lng: 18.471456 },
    { lat: 48.617539, lng: 18.471441 },
    { lat: 48.617617, lng: 18.471391 },
    { lat: 48.617643, lng: 18.471402 },
    { lat: 48.617714, lng: 18.471349 },
    { lat: 48.617742, lng: 18.471323 },
    { lat: 48.617784, lng: 18.471219 },
    { lat: 48.61774, lng: 18.471167 },
    { lat: 48.617777, lng: 18.471083 },
    { lat: 48.617863, lng: 18.471025 },
    { lat: 48.617919, lng: 18.470875 },
    { lat: 48.618008, lng: 18.470812 },
    { lat: 48.617987, lng: 18.47071 },
    { lat: 48.618098, lng: 18.470655 },
    { lat: 48.618103, lng: 18.470578 },
    { lat: 48.618149, lng: 18.470462 },
    { lat: 48.618213, lng: 18.47036 },
    { lat: 48.618216, lng: 18.470231 },
    { lat: 48.618311, lng: 18.470172 },
    { lat: 48.618402, lng: 18.470059 },
    { lat: 48.618454, lng: 18.47004 },
    { lat: 48.618511, lng: 18.469947 },
    { lat: 48.618517, lng: 18.469862 },
    { lat: 48.618509, lng: 18.469823 },
    { lat: 48.618459, lng: 18.46975 },
    { lat: 48.618465, lng: 18.469722 },
    { lat: 48.618501, lng: 18.46969 },
    { lat: 48.618536, lng: 18.469566 },
    { lat: 48.618604, lng: 18.469532 },
    { lat: 48.618732, lng: 18.469528 },
    { lat: 48.618749, lng: 18.469402 },
    { lat: 48.618826, lng: 18.469285 },
    { lat: 48.618958, lng: 18.469215 },
    { lat: 48.619011, lng: 18.469108 },
    { lat: 48.61901, lng: 18.469064 },
    { lat: 48.618944, lng: 18.468988 },
    { lat: 48.618916, lng: 18.46897 },
    { lat: 48.618871, lng: 18.468747 },
    { lat: 48.618874, lng: 18.468705 },
    { lat: 48.618952, lng: 18.468591 },
    { lat: 48.619042, lng: 18.468562 },
    { lat: 48.619073, lng: 18.46833 },
    { lat: 48.619164, lng: 18.468259 },
    { lat: 48.619188, lng: 18.468099 },
    { lat: 48.619251, lng: 18.468096 },
    { lat: 48.619311, lng: 18.467942 },
    { lat: 48.619322, lng: 18.467806 },
    { lat: 48.619294, lng: 18.467718 },
    { lat: 48.61931, lng: 18.467684 },
    { lat: 48.619404, lng: 18.467701 },
    { lat: 48.61941, lng: 18.467582 },
    { lat: 48.619474, lng: 18.467451 },
    { lat: 48.619501, lng: 18.467375 },
    { lat: 48.619514, lng: 18.467348 },
    { lat: 48.619568, lng: 18.467293 },
    { lat: 48.619587, lng: 18.467176 },
    { lat: 48.619594, lng: 18.467055 },
    { lat: 48.619605, lng: 18.466983 },
    { lat: 48.619676, lng: 18.466752 },
    { lat: 48.619764, lng: 18.466593 },
    { lat: 48.619765, lng: 18.466532 },
    { lat: 48.619811, lng: 18.466487 },
    { lat: 48.619821, lng: 18.466412 },
    { lat: 48.61987, lng: 18.466385 },
    { lat: 48.619887, lng: 18.466256 },
    { lat: 48.619902, lng: 18.46622 },
    { lat: 48.619959, lng: 18.46622 },
    { lat: 48.619996, lng: 18.466115 },
    { lat: 48.62005, lng: 18.4661 },
    { lat: 48.620082, lng: 18.466113 },
    { lat: 48.620146, lng: 18.466126 },
    { lat: 48.620203, lng: 18.466137 },
    { lat: 48.62031, lng: 18.466078 },
    { lat: 48.620347, lng: 18.46605 },
    { lat: 48.620372, lng: 18.466013 },
    { lat: 48.620412, lng: 18.465951 },
    { lat: 48.620457, lng: 18.465936 },
    { lat: 48.620547, lng: 18.465977 },
    { lat: 48.620568, lng: 18.465962 },
    { lat: 48.62059, lng: 18.46594 },
    { lat: 48.620619, lng: 18.465848 },
    { lat: 48.620633, lng: 18.465799 },
    { lat: 48.620661, lng: 18.465771 },
    { lat: 48.620738, lng: 18.46569 },
    { lat: 48.620836, lng: 18.465593 },
    { lat: 48.620845, lng: 18.465572 },
    { lat: 48.620827, lng: 18.46548 },
    { lat: 48.62083, lng: 18.465463 },
    { lat: 48.620842, lng: 18.465397 },
    { lat: 48.62087, lng: 18.465359 },
    { lat: 48.620888, lng: 18.465303 },
    { lat: 48.620927, lng: 18.465271 },
    { lat: 48.620938, lng: 18.465245 },
    { lat: 48.620937, lng: 18.465148 },
    { lat: 48.620971, lng: 18.465067 },
    { lat: 48.621017, lng: 18.464929 },
    { lat: 48.621069, lng: 18.464905 },
    { lat: 48.621118, lng: 18.464841 },
    { lat: 48.621164, lng: 18.464854 },
    { lat: 48.621187, lng: 18.464861 },
    { lat: 48.621226, lng: 18.464831 },
    { lat: 48.621275, lng: 18.464775 },
    { lat: 48.621323, lng: 18.464707 },
    { lat: 48.62133, lng: 18.464704 },
    { lat: 48.621372, lng: 18.464685 },
    { lat: 48.621403, lng: 18.464593 },
    { lat: 48.621465, lng: 18.46462 },
    { lat: 48.621529, lng: 18.464696 },
    { lat: 48.621546, lng: 18.464666 },
    { lat: 48.621581, lng: 18.464584 },
    { lat: 48.621588, lng: 18.464559 },
    { lat: 48.621574, lng: 18.464453 },
    { lat: 48.62167, lng: 18.464378 },
    { lat: 48.621704, lng: 18.464314 },
    { lat: 48.621711, lng: 18.464124 },
    { lat: 48.621712, lng: 18.464077 },
    { lat: 48.621727, lng: 18.463999 },
    { lat: 48.621738, lng: 18.463949 },
    { lat: 48.621809, lng: 18.463748 },
    { lat: 48.621807, lng: 18.463723 },
    { lat: 48.6218, lng: 18.463651 },
    { lat: 48.621841, lng: 18.463604 },
    { lat: 48.621853, lng: 18.463544 },
    { lat: 48.621839, lng: 18.463514 },
    { lat: 48.621816, lng: 18.463467 },
    { lat: 48.621857, lng: 18.463379 },
    { lat: 48.621864, lng: 18.463311 },
    { lat: 48.621778, lng: 18.46325 },
    { lat: 48.621769, lng: 18.463225 },
    { lat: 48.621779, lng: 18.463144 },
    { lat: 48.621844, lng: 18.463098 },
    { lat: 48.62184, lng: 18.462985 },
    { lat: 48.621848, lng: 18.462957 },
    { lat: 48.621871, lng: 18.462918 },
    { lat: 48.621925, lng: 18.462866 },
    { lat: 48.621952, lng: 18.462782 },
    { lat: 48.621953, lng: 18.462679 },
    { lat: 48.622, lng: 18.462612 },
    { lat: 48.621994, lng: 18.462513 },
    { lat: 48.622021, lng: 18.462416 },
    { lat: 48.622086, lng: 18.462241 },
    { lat: 48.622106, lng: 18.462207 },
    { lat: 48.622142, lng: 18.462148 },
    { lat: 48.622154, lng: 18.462111 },
    { lat: 48.622129, lng: 18.462095 },
    { lat: 48.622061, lng: 18.462053 },
    { lat: 48.622113, lng: 18.461941 },
    { lat: 48.622166, lng: 18.461943 },
    { lat: 48.622196, lng: 18.461944 },
    { lat: 48.622204, lng: 18.461921 },
    { lat: 48.622196, lng: 18.46184 },
    { lat: 48.622188, lng: 18.461757 },
    { lat: 48.622201, lng: 18.461725 },
    { lat: 48.622231, lng: 18.46172 },
    { lat: 48.622274, lng: 18.461712 },
    { lat: 48.622298, lng: 18.461694 },
    { lat: 48.622306, lng: 18.461689 },
    { lat: 48.622303, lng: 18.461679 },
    { lat: 48.622288, lng: 18.461634 },
    { lat: 48.622268, lng: 18.461601 },
    { lat: 48.622225, lng: 18.461531 },
    { lat: 48.622227, lng: 18.461494 },
    { lat: 48.622309, lng: 18.461388 },
    { lat: 48.622387, lng: 18.461235 },
    { lat: 48.622428, lng: 18.461128 },
    { lat: 48.622467, lng: 18.46098 },
    { lat: 48.622516, lng: 18.460948 },
    { lat: 48.622691, lng: 18.460556 },
    { lat: 48.622767, lng: 18.46038 },
    { lat: 48.622864, lng: 18.460151 },
    { lat: 48.622888, lng: 18.460117 },
    { lat: 48.622934, lng: 18.460017 },
    { lat: 48.622955, lng: 18.459951 },
    { lat: 48.622964, lng: 18.459897 },
    { lat: 48.623007, lng: 18.45981 },
    { lat: 48.623033, lng: 18.459788 },
    { lat: 48.623106, lng: 18.459623 },
    { lat: 48.62311, lng: 18.459601 },
    { lat: 48.623132, lng: 18.459571 },
    { lat: 48.623198, lng: 18.459407 },
    { lat: 48.62323, lng: 18.459353 },
    { lat: 48.623271, lng: 18.459283 },
    { lat: 48.623292, lng: 18.459226 },
    { lat: 48.623332, lng: 18.459133 },
    { lat: 48.623351, lng: 18.4591 },
    { lat: 48.623389, lng: 18.458987 },
    { lat: 48.623394, lng: 18.458975 },
    { lat: 48.623396, lng: 18.458972 },
    { lat: 48.623412, lng: 18.458955 },
    { lat: 48.623452, lng: 18.458869 },
    { lat: 48.6235, lng: 18.458754 },
    { lat: 48.62354, lng: 18.45864 },
    { lat: 48.623562, lng: 18.458581 },
    { lat: 48.623586, lng: 18.458538 },
    { lat: 48.623653, lng: 18.458412 },
    { lat: 48.62374, lng: 18.458184 },
    { lat: 48.623746, lng: 18.458164 },
    { lat: 48.623782, lng: 18.458072 },
    { lat: 48.623795, lng: 18.458056 },
    { lat: 48.623827, lng: 18.458015 },
    { lat: 48.62386, lng: 18.45791 },
    { lat: 48.623862, lng: 18.457905 },
    { lat: 48.623875, lng: 18.457878 },
    { lat: 48.623879, lng: 18.457867 },
    { lat: 48.623927, lng: 18.457745 },
    { lat: 48.623939, lng: 18.457734 },
    { lat: 48.623968, lng: 18.457706 },
    { lat: 48.624006, lng: 18.457584 },
    { lat: 48.62403, lng: 18.457511 },
    { lat: 48.624051, lng: 18.457487 },
    { lat: 48.624092, lng: 18.457439 },
    { lat: 48.62417, lng: 18.457222 },
    { lat: 48.624186, lng: 18.457178 },
    { lat: 48.624239, lng: 18.457039 },
    { lat: 48.624262, lng: 18.457018 },
    { lat: 48.624286, lng: 18.456997 },
    { lat: 48.624293, lng: 18.456973 },
    { lat: 48.624315, lng: 18.456901 },
    { lat: 48.624335, lng: 18.456837 },
    { lat: 48.624355, lng: 18.456812 },
    { lat: 48.624384, lng: 18.456777 },
    { lat: 48.624388, lng: 18.456738 },
    { lat: 48.624396, lng: 18.456673 },
    { lat: 48.624456, lng: 18.45658 },
    { lat: 48.624461, lng: 18.456577 },
    { lat: 48.624465, lng: 18.456575 },
    { lat: 48.624469, lng: 18.456572 },
    { lat: 48.624489, lng: 18.45656 },
    { lat: 48.624525, lng: 18.456485 },
    { lat: 48.624526, lng: 18.45648 },
    { lat: 48.62453, lng: 18.456448 },
    { lat: 48.624535, lng: 18.456412 },
    { lat: 48.624539, lng: 18.456402 },
    { lat: 48.62462, lng: 18.456189 },
    { lat: 48.62473, lng: 18.455944 },
    { lat: 48.624756, lng: 18.455956 },
    { lat: 48.625127, lng: 18.455301 },
    { lat: 48.625538, lng: 18.454602 },
    { lat: 48.625696, lng: 18.454337 },
    { lat: 48.625919, lng: 18.453959 },
    { lat: 48.626017, lng: 18.45379 },
    { lat: 48.626385, lng: 18.453181 },
    { lat: 48.626685, lng: 18.452679 },
    { lat: 48.626916, lng: 18.452299 },
    { lat: 48.627345, lng: 18.451574 },
    { lat: 48.627563, lng: 18.451212 },
    { lat: 48.627625, lng: 18.451112 },
    { lat: 48.627709, lng: 18.450972 },
    { lat: 48.6278, lng: 18.450823 },
    { lat: 48.628086, lng: 18.450353 },
    { lat: 48.628123, lng: 18.450282 },
    { lat: 48.628339, lng: 18.449922 },
    { lat: 48.6285, lng: 18.449638 },
    { lat: 48.628526, lng: 18.449589 },
    { lat: 48.628713, lng: 18.449276 },
    { lat: 48.628931, lng: 18.448904 },
    { lat: 48.629076, lng: 18.448687 },
    { lat: 48.629293, lng: 18.448322 },
    { lat: 48.62936, lng: 18.448208 },
    { lat: 48.629796, lng: 18.447461 },
    { lat: 48.630081, lng: 18.446975 },
    { lat: 48.630245, lng: 18.446702 },
    { lat: 48.630328, lng: 18.446598 },
    { lat: 48.630396, lng: 18.446481 },
    { lat: 48.630882, lng: 18.447033 },
    { lat: 48.630996, lng: 18.44669 },
    { lat: 48.631004, lng: 18.446673 },
    { lat: 48.63109, lng: 18.44652 },
    { lat: 48.631197, lng: 18.446404 },
    { lat: 48.631279, lng: 18.446415 },
    { lat: 48.631385, lng: 18.446415 },
    { lat: 48.631452, lng: 18.446385 },
    { lat: 48.63151, lng: 18.446301 },
    { lat: 48.631536, lng: 18.446216 },
    { lat: 48.631602, lng: 18.446017 },
    { lat: 48.631664, lng: 18.445827 },
    { lat: 48.63174, lng: 18.445648 },
    { lat: 48.631921, lng: 18.445773 },
    { lat: 48.63214, lng: 18.445647 },
    { lat: 48.632383, lng: 18.445495 },
    { lat: 48.632472, lng: 18.445439 },
    { lat: 48.63261, lng: 18.445193 },
    { lat: 48.632613, lng: 18.445168 },
    { lat: 48.63261, lng: 18.444911 },
    { lat: 48.63266, lng: 18.444715 },
    { lat: 48.63269, lng: 18.444611 },
    { lat: 48.632755, lng: 18.444586 },
    { lat: 48.632803, lng: 18.444567 },
    { lat: 48.632813, lng: 18.444544 },
    { lat: 48.632855, lng: 18.444444 },
    { lat: 48.63295, lng: 18.444244 },
    { lat: 48.63316, lng: 18.44389 },
    { lat: 48.633464, lng: 18.443868 },
    { lat: 48.633796, lng: 18.443842 },
    { lat: 48.634033, lng: 18.443747 },
    { lat: 48.63417, lng: 18.443396 },
    { lat: 48.634302, lng: 18.443164 },
    { lat: 48.63437, lng: 18.443057 },
    { lat: 48.634423, lng: 18.442978 },
    { lat: 48.634437, lng: 18.442949 },
    { lat: 48.634486, lng: 18.442832 },
    { lat: 48.634524, lng: 18.442701 },
    { lat: 48.634535, lng: 18.442573 },
    { lat: 48.634512, lng: 18.442345 },
    { lat: 48.634512, lng: 18.442325 },
    { lat: 48.634521, lng: 18.44226 },
    { lat: 48.634566, lng: 18.442022 },
    { lat: 48.634494, lng: 18.441604 },
    { lat: 48.634504, lng: 18.441562 },
    { lat: 48.63457, lng: 18.441414 },
    { lat: 48.634618, lng: 18.441301 },
    { lat: 48.634726, lng: 18.441044 },
    { lat: 48.634756, lng: 18.440964 },
    { lat: 48.634759, lng: 18.440954 },
    { lat: 48.634804, lng: 18.440842 },
    { lat: 48.634819, lng: 18.440808 },
    { lat: 48.634877, lng: 18.440751 },
    { lat: 48.635124, lng: 18.440681 },
    { lat: 48.635315, lng: 18.440617 },
    { lat: 48.635411, lng: 18.440576 },
    { lat: 48.635482, lng: 18.440548 },
    { lat: 48.635552, lng: 18.440517 },
    { lat: 48.63566, lng: 18.440479 },
    { lat: 48.635707, lng: 18.440351 },
    { lat: 48.635728, lng: 18.440291 },
    { lat: 48.635739, lng: 18.440251 },
    { lat: 48.635758, lng: 18.440192 },
    { lat: 48.635778, lng: 18.440121 },
    { lat: 48.63587, lng: 18.439903 },
    { lat: 48.635896, lng: 18.439853 },
    { lat: 48.635924, lng: 18.439805 },
    { lat: 48.635955, lng: 18.439758 },
    { lat: 48.635983, lng: 18.439713 },
    { lat: 48.636017, lng: 18.43964 },
    { lat: 48.636038, lng: 18.439557 },
    { lat: 48.636112, lng: 18.439374 },
    { lat: 48.636181, lng: 18.439295 },
    { lat: 48.636437, lng: 18.43913 },
    { lat: 48.636651, lng: 18.439077 },
    { lat: 48.636682, lng: 18.438985 },
    { lat: 48.636694, lng: 18.438951 },
    { lat: 48.636784, lng: 18.438697 },
    { lat: 48.636878, lng: 18.438621 },
    { lat: 48.637006, lng: 18.438631 },
    { lat: 48.63706, lng: 18.438669 },
    { lat: 48.637286, lng: 18.438823 },
    { lat: 48.637597, lng: 18.439046 },
    { lat: 48.637632, lng: 18.439006 },
    { lat: 48.637758, lng: 18.438864 },
  ],
  Skačany: [
    { lat: 48.6505931, lng: 18.3778295 },
    { lat: 48.6506443, lng: 18.3778973 },
    { lat: 48.65077, lng: 18.3780264 },
    { lat: 48.6507179, lng: 18.3781266 },
    { lat: 48.6505714, lng: 18.3783094 },
    { lat: 48.6503548, lng: 18.3784719 },
    { lat: 48.650229, lng: 18.3786025 },
    { lat: 48.6501823, lng: 18.3786345 },
    { lat: 48.6501014, lng: 18.378637 },
    { lat: 48.6496732, lng: 18.3783074 },
    { lat: 48.6492785, lng: 18.3785119 },
    { lat: 48.6491118, lng: 18.3785581 },
    { lat: 48.6489139, lng: 18.3786669 },
    { lat: 48.6489484, lng: 18.3787813 },
    { lat: 48.649059, lng: 18.3808568 },
    { lat: 48.6491186, lng: 18.381798 },
    { lat: 48.6492062, lng: 18.3829624 },
    { lat: 48.6493187, lng: 18.3829566 },
    { lat: 48.6493328, lng: 18.3829841 },
    { lat: 48.6493524, lng: 18.3831321 },
    { lat: 48.6494293, lng: 18.3835812 },
    { lat: 48.649628, lng: 18.3846722 },
    { lat: 48.649857, lng: 18.3857612 },
    { lat: 48.6499454, lng: 18.3861488 },
    { lat: 48.6500181, lng: 18.3864389 },
    { lat: 48.6501123, lng: 18.3867744 },
    { lat: 48.6502281, lng: 18.3871279 },
    { lat: 48.6503933, lng: 18.3875421 },
    { lat: 48.6504706, lng: 18.3879565 },
    { lat: 48.6505493, lng: 18.3883957 },
    { lat: 48.6506329, lng: 18.3888999 },
    { lat: 48.6506949, lng: 18.3892402 },
    { lat: 48.6507014, lng: 18.3893182 },
    { lat: 48.6507989, lng: 18.3897893 },
    { lat: 48.6508316, lng: 18.3903146 },
    { lat: 48.650847, lng: 18.3906905 },
    { lat: 48.6508653, lng: 18.390874 },
    { lat: 48.6509229, lng: 18.391824 },
    { lat: 48.6506654, lng: 18.3918618 },
    { lat: 48.6505862, lng: 18.3918576 },
    { lat: 48.6504462, lng: 18.3918743 },
    { lat: 48.6502715, lng: 18.3918787 },
    { lat: 48.6501218, lng: 18.3918436 },
    { lat: 48.6499701, lng: 18.3917937 },
    { lat: 48.649926, lng: 18.3917922 },
    { lat: 48.6496336, lng: 18.3918098 },
    { lat: 48.6494069, lng: 18.3917919 },
    { lat: 48.6491594, lng: 18.3918438 },
    { lat: 48.6490505, lng: 18.392598 },
    { lat: 48.649032, lng: 18.3926132 },
    { lat: 48.6487967, lng: 18.3926203 },
    { lat: 48.6487563, lng: 18.3926636 },
    { lat: 48.6488552, lng: 18.3952837 },
    { lat: 48.6488699, lng: 18.3953435 },
    { lat: 48.6488952, lng: 18.3953521 },
    { lat: 48.650402, lng: 18.3952017 },
    { lat: 48.6507443, lng: 18.3951513 },
    { lat: 48.6511867, lng: 18.3950137 },
    { lat: 48.6518014, lng: 18.3948066 },
    { lat: 48.6520078, lng: 18.3947425 },
    { lat: 48.6524453, lng: 18.3946187 },
    { lat: 48.6527825, lng: 18.3945183 },
    { lat: 48.6530352, lng: 18.3944551 },
    { lat: 48.6535287, lng: 18.394403 },
    { lat: 48.6536287, lng: 18.3944577 },
    { lat: 48.6538736, lng: 18.3944997 },
    { lat: 48.6544836, lng: 18.3944923 },
    { lat: 48.6547704, lng: 18.3944761 },
    { lat: 48.6547772, lng: 18.3944795 },
    { lat: 48.6549079, lng: 18.394474 },
    { lat: 48.6551491, lng: 18.3944283 },
    { lat: 48.6557422, lng: 18.3943046 },
    { lat: 48.6559552, lng: 18.3942378 },
    { lat: 48.6561684, lng: 18.3941989 },
    { lat: 48.6565187, lng: 18.3941679 },
    { lat: 48.6572245, lng: 18.3940184 },
    { lat: 48.6576712, lng: 18.3939562 },
    { lat: 48.6579075, lng: 18.3950545 },
    { lat: 48.658797, lng: 18.3964575 },
    { lat: 48.6595364, lng: 18.3977005 },
    { lat: 48.6600291, lng: 18.3985217 },
    { lat: 48.6603347, lng: 18.398945 },
    { lat: 48.661309, lng: 18.400272 },
    { lat: 48.6615565, lng: 18.4013304 },
    { lat: 48.6618953, lng: 18.4022445 },
    { lat: 48.6617454, lng: 18.4024484 },
    { lat: 48.6616244, lng: 18.402649 },
    { lat: 48.6614937, lng: 18.4029529 },
    { lat: 48.6614368, lng: 18.4030447 },
    { lat: 48.6612988, lng: 18.4033424 },
    { lat: 48.6612068, lng: 18.4035318 },
    { lat: 48.6610455, lng: 18.4039795 },
    { lat: 48.6610105, lng: 18.4041188 },
    { lat: 48.6610554, lng: 18.4042584 },
    { lat: 48.661218, lng: 18.40452 },
    { lat: 48.6613541, lng: 18.4046964 },
    { lat: 48.6614018, lng: 18.4047214 },
    { lat: 48.6617554, lng: 18.4052168 },
    { lat: 48.6618455, lng: 18.4053145 },
    { lat: 48.6630341, lng: 18.4074296 },
    { lat: 48.6634079, lng: 18.4080455 },
    { lat: 48.6640406, lng: 18.4091528 },
    { lat: 48.6647201, lng: 18.4101133 },
    { lat: 48.6653648, lng: 18.4110931 },
    { lat: 48.6659514, lng: 18.4120029 },
    { lat: 48.666143, lng: 18.4124836 },
    { lat: 48.6663595, lng: 18.4124026 },
    { lat: 48.6665114, lng: 18.4124183 },
    { lat: 48.6666667, lng: 18.412511 },
    { lat: 48.6667885, lng: 18.4125708 },
    { lat: 48.6669439, lng: 18.4126867 },
    { lat: 48.6672274, lng: 18.4128756 },
    { lat: 48.6673238, lng: 18.4129682 },
    { lat: 48.6675615, lng: 18.412962 },
    { lat: 48.667776, lng: 18.4129622 },
    { lat: 48.6679523, lng: 18.412927 },
    { lat: 48.668059, lng: 18.4129199 },
    { lat: 48.6682976, lng: 18.4129688 },
    { lat: 48.6687085, lng: 18.412973 },
    { lat: 48.6692145, lng: 18.4130637 },
    { lat: 48.6693873, lng: 18.4130681 },
    { lat: 48.6696479, lng: 18.4131286 },
    { lat: 48.6701787, lng: 18.4132618 },
    { lat: 48.6703518, lng: 18.4136149 },
    { lat: 48.6711594, lng: 18.4140967 },
    { lat: 48.6714237, lng: 18.4141266 },
    { lat: 48.6722031, lng: 18.4142436 },
    { lat: 48.6729952, lng: 18.4157247 },
    { lat: 48.6734486, lng: 18.4177706 },
    { lat: 48.6738082, lng: 18.4175633 },
    { lat: 48.6739878, lng: 18.4175244 },
    { lat: 48.6743343, lng: 18.4174898 },
    { lat: 48.6746556, lng: 18.4175025 },
    { lat: 48.6751163, lng: 18.4174926 },
    { lat: 48.6758381, lng: 18.4173457 },
    { lat: 48.6760762, lng: 18.4172241 },
    { lat: 48.6767624, lng: 18.417268 },
    { lat: 48.6772916, lng: 18.4172919 },
    { lat: 48.6776606, lng: 18.4171951 },
    { lat: 48.6779258, lng: 18.416994 },
    { lat: 48.677995, lng: 18.41702 },
    { lat: 48.678049, lng: 18.417052 },
    { lat: 48.6784, lng: 18.416832 },
    { lat: 48.678903, lng: 18.4166 },
    { lat: 48.67908, lng: 18.4164 },
    { lat: 48.68008, lng: 18.41451 },
    { lat: 48.680465, lng: 18.413802 },
    { lat: 48.68101, lng: 18.412776 },
    { lat: 48.681109, lng: 18.412694 },
    { lat: 48.68118, lng: 18.412634 },
    { lat: 48.68156, lng: 18.412323 },
    { lat: 48.681592, lng: 18.412297 },
    { lat: 48.681642, lng: 18.41228 },
    { lat: 48.682212, lng: 18.412082 },
    { lat: 48.682692, lng: 18.411788 },
    { lat: 48.683097, lng: 18.411729 },
    { lat: 48.683568, lng: 18.411105 },
    { lat: 48.683914, lng: 18.410718 },
    { lat: 48.684006, lng: 18.410555 },
    { lat: 48.684144, lng: 18.410309 },
    { lat: 48.684161, lng: 18.410281 },
    { lat: 48.684173, lng: 18.410258 },
    { lat: 48.684254, lng: 18.410114 },
    { lat: 48.684328, lng: 18.410043 },
    { lat: 48.684462, lng: 18.409915 },
    { lat: 48.684529, lng: 18.409864 },
    { lat: 48.68472, lng: 18.409596 },
    { lat: 48.684938, lng: 18.409188 },
    { lat: 48.685138, lng: 18.409151 },
    { lat: 48.685244, lng: 18.40915 },
    { lat: 48.685388, lng: 18.409101 },
    { lat: 48.685574, lng: 18.408993 },
    { lat: 48.685614, lng: 18.40897 },
    { lat: 48.68565, lng: 18.408954 },
    { lat: 48.685693, lng: 18.408935 },
    { lat: 48.685768, lng: 18.408902 },
    { lat: 48.685878, lng: 18.408855 },
    { lat: 48.686441, lng: 18.40859 },
    { lat: 48.687201, lng: 18.408169 },
    { lat: 48.687393, lng: 18.408052 },
    { lat: 48.687387, lng: 18.408034 },
    { lat: 48.687508, lng: 18.407939 },
    { lat: 48.687866, lng: 18.407709 },
    { lat: 48.68789, lng: 18.407693 },
    { lat: 48.688401, lng: 18.407364 },
    { lat: 48.688411, lng: 18.407393 },
    { lat: 48.688508, lng: 18.407295 },
    { lat: 48.689007, lng: 18.40679 },
    { lat: 48.689519, lng: 18.407784 },
    { lat: 48.689609, lng: 18.40807 },
    { lat: 48.689737, lng: 18.408348 },
    { lat: 48.689888, lng: 18.40864 },
    { lat: 48.689939, lng: 18.408759 },
    { lat: 48.69012, lng: 18.408966 },
    { lat: 48.690315, lng: 18.409033 },
    { lat: 48.690657, lng: 18.409068 },
    { lat: 48.690876, lng: 18.409084 },
    { lat: 48.691121, lng: 18.409173 },
    { lat: 48.691307, lng: 18.409241 },
    { lat: 48.691505, lng: 18.409307 },
    { lat: 48.691743, lng: 18.409452 },
    { lat: 48.691952, lng: 18.409369 },
    { lat: 48.691982, lng: 18.409323 },
    { lat: 48.692019, lng: 18.409322 },
    { lat: 48.692288, lng: 18.40954 },
    { lat: 48.692448, lng: 18.409454 },
    { lat: 48.69271, lng: 18.409315 },
    { lat: 48.692732, lng: 18.409289 },
    { lat: 48.692785, lng: 18.409228 },
    { lat: 48.692943, lng: 18.408885 },
    { lat: 48.693253, lng: 18.408392 },
    { lat: 48.693345, lng: 18.408185 },
    { lat: 48.693551, lng: 18.407747 },
    { lat: 48.693799, lng: 18.407497 },
    { lat: 48.693955, lng: 18.407023 },
    { lat: 48.694023, lng: 18.406923 },
    { lat: 48.694237, lng: 18.406694 },
    { lat: 48.694373, lng: 18.406592 },
    { lat: 48.694772, lng: 18.406142 },
    { lat: 48.694834, lng: 18.406043 },
    { lat: 48.695027, lng: 18.405774 },
    { lat: 48.695126, lng: 18.405552 },
    { lat: 48.695177, lng: 18.405404 },
    { lat: 48.695315, lng: 18.405196 },
    { lat: 48.69553, lng: 18.404691 },
    { lat: 48.695679, lng: 18.404189 },
    { lat: 48.695901, lng: 18.403433 },
    { lat: 48.696084, lng: 18.403081 },
    { lat: 48.696127, lng: 18.402963 },
    { lat: 48.696173, lng: 18.40284 },
    { lat: 48.696134, lng: 18.40287 },
    { lat: 48.696106, lng: 18.402892 },
    { lat: 48.696068, lng: 18.402877 },
    { lat: 48.69605, lng: 18.40287 },
    { lat: 48.695908, lng: 18.402818 },
    { lat: 48.695679, lng: 18.401027 },
    { lat: 48.69557, lng: 18.399114 },
    { lat: 48.695426, lng: 18.398623 },
    { lat: 48.695161, lng: 18.397223 },
    { lat: 48.694896, lng: 18.395777 },
    { lat: 48.69479, lng: 18.393574 },
    { lat: 48.694763, lng: 18.392657 },
    { lat: 48.694471, lng: 18.391279 },
    { lat: 48.694386, lng: 18.391121 },
    { lat: 48.694344, lng: 18.391047 },
    { lat: 48.694329, lng: 18.390828 },
    { lat: 48.694319, lng: 18.390699 },
    { lat: 48.694291, lng: 18.39054 },
    { lat: 48.694285, lng: 18.39041 },
    { lat: 48.694278, lng: 18.390319 },
    { lat: 48.694173, lng: 18.390081 },
    { lat: 48.694096, lng: 18.389999 },
    { lat: 48.693999, lng: 18.389897 },
    { lat: 48.693791, lng: 18.389792 },
    { lat: 48.693404, lng: 18.3893 },
    { lat: 48.693055, lng: 18.389014 },
    { lat: 48.692776, lng: 18.388835 },
    { lat: 48.692671, lng: 18.388624 },
    { lat: 48.692603, lng: 18.388487 },
    { lat: 48.692412, lng: 18.388199 },
    { lat: 48.692233, lng: 18.387918 },
    { lat: 48.692133, lng: 18.387849 },
    { lat: 48.6918204, lng: 18.3876291 },
    { lat: 48.6917333, lng: 18.3875013 },
    { lat: 48.6916211, lng: 18.3874135 },
    { lat: 48.6913095, lng: 18.3872479 },
    { lat: 48.6910798, lng: 18.3871526 },
    { lat: 48.6909048, lng: 18.3870917 },
    { lat: 48.6906592, lng: 18.3870166 },
    { lat: 48.6904186, lng: 18.3868709 },
    { lat: 48.6901723, lng: 18.3865613 },
    { lat: 48.6900629, lng: 18.3864025 },
    { lat: 48.689922, lng: 18.3861401 },
    { lat: 48.6898908, lng: 18.3860056 },
    { lat: 48.6898251, lng: 18.3857976 },
    { lat: 48.6896857, lng: 18.3855465 },
    { lat: 48.6896276, lng: 18.3853957 },
    { lat: 48.6895323, lng: 18.3852043 },
    { lat: 48.6893649, lng: 18.3850346 },
    { lat: 48.6892916, lng: 18.3849972 },
    { lat: 48.6892124, lng: 18.3849295 },
    { lat: 48.6891134, lng: 18.3847939 },
    { lat: 48.6889805, lng: 18.3845956 },
    { lat: 48.6887979, lng: 18.3842982 },
    { lat: 48.6885428, lng: 18.3839933 },
    { lat: 48.6884294, lng: 18.3838803 },
    { lat: 48.6882847, lng: 18.3837542 },
    { lat: 48.6880812, lng: 18.3836648 },
    { lat: 48.6880028, lng: 18.383617 },
    { lat: 48.6878919, lng: 18.3835697 },
    { lat: 48.6878119, lng: 18.3835135 },
    { lat: 48.6876526, lng: 18.3833443 },
    { lat: 48.6876235, lng: 18.3832087 },
    { lat: 48.687574, lng: 18.383249 },
    { lat: 48.6872202, lng: 18.3839236 },
    { lat: 48.6862187, lng: 18.383203 },
    { lat: 48.6861088, lng: 18.383136 },
    { lat: 48.6859648, lng: 18.3830223 },
    { lat: 48.6857287, lng: 18.3828753 },
    { lat: 48.6854396, lng: 18.382648 },
    { lat: 48.6852114, lng: 18.3824829 },
    { lat: 48.6849981, lng: 18.3823205 },
    { lat: 48.6846213, lng: 18.3819966 },
    { lat: 48.684439, lng: 18.3818046 },
    { lat: 48.6843493, lng: 18.382057 },
    { lat: 48.6841007, lng: 18.3823501 },
    { lat: 48.6840107, lng: 18.3825376 },
    { lat: 48.683945, lng: 18.3826345 },
    { lat: 48.6838704, lng: 18.3826491 },
    { lat: 48.6838288, lng: 18.3826329 },
    { lat: 48.6838154, lng: 18.3826546 },
    { lat: 48.6836444, lng: 18.3827461 },
    { lat: 48.6835639, lng: 18.3828167 },
    { lat: 48.6834814, lng: 18.3829179 },
    { lat: 48.6833523, lng: 18.3833521 },
    { lat: 48.6833109, lng: 18.3834195 },
    { lat: 48.6830781, lng: 18.3835517 },
    { lat: 48.6829983, lng: 18.3836768 },
    { lat: 48.6827482, lng: 18.3838073 },
    { lat: 48.6826926, lng: 18.3837601 },
    { lat: 48.6816481, lng: 18.3843272 },
    { lat: 48.6813139, lng: 18.3845058 },
    { lat: 48.6811509, lng: 18.3844949 },
    { lat: 48.6810435, lng: 18.3845111 },
    { lat: 48.6804482, lng: 18.3846195 },
    { lat: 48.6805685, lng: 18.3843921 },
    { lat: 48.680626, lng: 18.3842063 },
    { lat: 48.6807627, lng: 18.3834037 },
    { lat: 48.680852, lng: 18.382824 },
    { lat: 48.680402, lng: 18.382785 },
    { lat: 48.6797152, lng: 18.3827295 },
    { lat: 48.6789601, lng: 18.3826606 },
    { lat: 48.6780961, lng: 18.3825859 },
    { lat: 48.6774885, lng: 18.3825349 },
    { lat: 48.6769554, lng: 18.3824851 },
    { lat: 48.675926, lng: 18.3823945 },
    { lat: 48.6747052, lng: 18.3822897 },
    { lat: 48.6739528, lng: 18.3823329 },
    { lat: 48.6739364, lng: 18.3821204 },
    { lat: 48.6738945, lng: 18.3818745 },
    { lat: 48.6738171, lng: 18.3816412 },
    { lat: 48.6737781, lng: 18.3815481 },
    { lat: 48.6735901, lng: 18.3812133 },
    { lat: 48.6734547, lng: 18.3809536 },
    { lat: 48.6731903, lng: 18.3806014 },
    { lat: 48.6730776, lng: 18.3804627 },
    { lat: 48.6729623, lng: 18.3803589 },
    { lat: 48.6727492, lng: 18.3801994 },
    { lat: 48.6725197, lng: 18.3800913 },
    { lat: 48.6722623, lng: 18.3800249 },
    { lat: 48.6726053, lng: 18.3780743 },
    { lat: 48.6726658, lng: 18.3777017 },
    { lat: 48.6727364, lng: 18.3773118 },
    { lat: 48.6729991, lng: 18.3757983 },
    { lat: 48.6731194, lng: 18.3751272 },
    { lat: 48.6732759, lng: 18.3742366 },
    { lat: 48.6735736, lng: 18.3725836 },
    { lat: 48.6737138, lng: 18.3718203 },
    { lat: 48.6739156, lng: 18.3706926 },
    { lat: 48.6740956, lng: 18.3696993 },
    { lat: 48.6741965, lng: 18.3691634 },
    { lat: 48.6738027, lng: 18.3692017 },
    { lat: 48.6732143, lng: 18.3692541 },
    { lat: 48.6732922, lng: 18.3690324 },
    { lat: 48.6736768, lng: 18.3681047 },
    { lat: 48.6739559, lng: 18.3672676 },
    { lat: 48.674013, lng: 18.3663558 },
    { lat: 48.6739549, lng: 18.366104 },
    { lat: 48.6737067, lng: 18.3656417 },
    { lat: 48.6737111, lng: 18.3648565 },
    { lat: 48.6737358, lng: 18.3644062 },
    { lat: 48.6737463, lng: 18.3642156 },
    { lat: 48.6737793, lng: 18.3637383 },
    { lat: 48.6737973, lng: 18.3632963 },
    { lat: 48.6738331, lng: 18.3630065 },
    { lat: 48.6741303, lng: 18.3618329 },
    { lat: 48.6743106, lng: 18.3611037 },
    { lat: 48.6746725, lng: 18.3597344 },
    { lat: 48.6747736, lng: 18.3594048 },
    { lat: 48.6750705, lng: 18.3586667 },
    { lat: 48.6752841, lng: 18.3583327 },
    { lat: 48.6754111, lng: 18.3581019 },
    { lat: 48.6755425, lng: 18.3578277 },
    { lat: 48.6758096, lng: 18.35738 },
    { lat: 48.6758195, lng: 18.3572956 },
    { lat: 48.6761449, lng: 18.3566831 },
    { lat: 48.6762328, lng: 18.3565008 },
    { lat: 48.6763456, lng: 18.3563218 },
    { lat: 48.676474, lng: 18.3561739 },
    { lat: 48.6767197, lng: 18.3558304 },
    { lat: 48.6771319, lng: 18.3550932 },
    { lat: 48.6772092, lng: 18.3549662 },
    { lat: 48.6771956, lng: 18.3549437 },
    { lat: 48.6773975, lng: 18.3546401 },
    { lat: 48.6774753, lng: 18.3544605 },
    { lat: 48.6776282, lng: 18.35403 },
    { lat: 48.6777475, lng: 18.353722 },
    { lat: 48.6778686, lng: 18.3533262 },
    { lat: 48.6780061, lng: 18.3531062 },
    { lat: 48.6781179, lng: 18.3529672 },
    { lat: 48.6783271, lng: 18.3526937 },
    { lat: 48.6784088, lng: 18.3525401 },
    { lat: 48.6786081, lng: 18.3519315 },
    { lat: 48.6790245, lng: 18.3509327 },
    { lat: 48.679232, lng: 18.3501064 },
    { lat: 48.6793151, lng: 18.3495795 },
    { lat: 48.6797386, lng: 18.3490632 },
    { lat: 48.6800164, lng: 18.3485891 },
    { lat: 48.6801846, lng: 18.3479695 },
    { lat: 48.6803556, lng: 18.3476802 },
    { lat: 48.6807817, lng: 18.3472913 },
    { lat: 48.6809069, lng: 18.3470289 },
    { lat: 48.6809968, lng: 18.3464629 },
    { lat: 48.6812881, lng: 18.3460634 },
    { lat: 48.681451, lng: 18.3454931 },
    { lat: 48.6814003, lng: 18.345135 },
    { lat: 48.6813764, lng: 18.3444497 },
    { lat: 48.681228, lng: 18.344556 },
    { lat: 48.681142, lng: 18.34476 },
    { lat: 48.680924, lng: 18.344953 },
    { lat: 48.680289, lng: 18.34509 },
    { lat: 48.679923, lng: 18.345188 },
    { lat: 48.679748, lng: 18.345257 },
    { lat: 48.67959, lng: 18.345343 },
    { lat: 48.679497, lng: 18.345372 },
    { lat: 48.67934, lng: 18.345351 },
    { lat: 48.679113, lng: 18.345276 },
    { lat: 48.678768, lng: 18.345082 },
    { lat: 48.678415, lng: 18.344731 },
    { lat: 48.678281, lng: 18.344633 },
    { lat: 48.678113, lng: 18.344578 },
    { lat: 48.677889, lng: 18.344578 },
    { lat: 48.677669, lng: 18.344649 },
    { lat: 48.677455, lng: 18.344701 },
    { lat: 48.676917, lng: 18.34471 },
    { lat: 48.676415, lng: 18.344762 },
    { lat: 48.676134, lng: 18.344759 },
    { lat: 48.676016, lng: 18.344726 },
    { lat: 48.675751, lng: 18.344706 },
    { lat: 48.675543, lng: 18.344711 },
    { lat: 48.675384, lng: 18.344712 },
    { lat: 48.6752373, lng: 18.3447342 },
    { lat: 48.675001, lng: 18.34477 },
    { lat: 48.67427, lng: 18.344996 },
    { lat: 48.673722, lng: 18.345042 },
    { lat: 48.67359, lng: 18.34503 },
    { lat: 48.673095, lng: 18.344934 },
    { lat: 48.672966, lng: 18.344942 },
    { lat: 48.672783, lng: 18.344984 },
    { lat: 48.67262, lng: 18.345009 },
    { lat: 48.672508, lng: 18.345004 },
    { lat: 48.672355, lng: 18.344985 },
    { lat: 48.672267, lng: 18.345032 },
    { lat: 48.672112, lng: 18.344971 },
    { lat: 48.672063, lng: 18.344952 },
    { lat: 48.671915, lng: 18.344894 },
    { lat: 48.67122, lng: 18.3448 },
    { lat: 48.670425, lng: 18.344544 },
    { lat: 48.670374, lng: 18.344514 },
    { lat: 48.670174, lng: 18.344348 },
    { lat: 48.66919, lng: 18.343746 },
    { lat: 48.668187, lng: 18.343177 },
    { lat: 48.668154, lng: 18.343168 },
    { lat: 48.66709, lng: 18.342875 },
    { lat: 48.666417, lng: 18.342816 },
    { lat: 48.666056, lng: 18.342783 },
    { lat: 48.665289, lng: 18.342326 },
    { lat: 48.665088, lng: 18.34229 },
    { lat: 48.664173, lng: 18.342242 },
    { lat: 48.663903, lng: 18.342317 },
    { lat: 48.663518, lng: 18.342416 },
    { lat: 48.663112, lng: 18.342473 },
    { lat: 48.66276, lng: 18.342507 },
    { lat: 48.662307, lng: 18.34255 },
    { lat: 48.662038, lng: 18.34266 },
    { lat: 48.661825, lng: 18.342708 },
    { lat: 48.661699, lng: 18.342768 },
    { lat: 48.661681, lng: 18.342776 },
    { lat: 48.66164, lng: 18.342782 },
    { lat: 48.661533, lng: 18.342796 },
    { lat: 48.661489, lng: 18.342846 },
    { lat: 48.661032, lng: 18.342927 },
    { lat: 48.660823, lng: 18.34291 },
    { lat: 48.660575, lng: 18.342909 },
    { lat: 48.660304, lng: 18.342973 },
    { lat: 48.660095, lng: 18.342996 },
    { lat: 48.659856, lng: 18.342978 },
    { lat: 48.659667, lng: 18.343023 },
    { lat: 48.659441, lng: 18.343052 },
    { lat: 48.659046, lng: 18.343101 },
    { lat: 48.65864, lng: 18.343162 },
    { lat: 48.658324, lng: 18.343253 },
    { lat: 48.658009, lng: 18.343409 },
    { lat: 48.657987, lng: 18.343419 },
    { lat: 48.6588418, lng: 18.345185 },
    { lat: 48.6592057, lng: 18.3464944 },
    { lat: 48.6598396, lng: 18.3479246 },
    { lat: 48.6601242, lng: 18.3485876 },
    { lat: 48.6612066, lng: 18.3509909 },
    { lat: 48.6610731, lng: 18.3512683 },
    { lat: 48.6608002, lng: 18.3518893 },
    { lat: 48.6606497, lng: 18.3522858 },
    { lat: 48.6602342, lng: 18.3535821 },
    { lat: 48.6600329, lng: 18.3542738 },
    { lat: 48.6596386, lng: 18.3559531 },
    { lat: 48.6593417, lng: 18.3560087 },
    { lat: 48.6581911, lng: 18.3563916 },
    { lat: 48.6578322, lng: 18.3565518 },
    { lat: 48.6576982, lng: 18.357157 },
    { lat: 48.6575723, lng: 18.3576888 },
    { lat: 48.6573638, lng: 18.3585559 },
    { lat: 48.6572923, lng: 18.3590304 },
    { lat: 48.6572363, lng: 18.3595393 },
    { lat: 48.6571202, lng: 18.359561 },
    { lat: 48.6568319, lng: 18.359549 },
    { lat: 48.6561247, lng: 18.3594939 },
    { lat: 48.6556754, lng: 18.3594669 },
    { lat: 48.655094, lng: 18.3594243 },
    { lat: 48.6550096, lng: 18.3598479 },
    { lat: 48.6549005, lng: 18.360116 },
    { lat: 48.6548803, lng: 18.3601981 },
    { lat: 48.654834, lng: 18.3602965 },
    { lat: 48.6547475, lng: 18.3605438 },
    { lat: 48.6546427, lng: 18.3608259 },
    { lat: 48.654604, lng: 18.3609818 },
    { lat: 48.6545643, lng: 18.3611799 },
    { lat: 48.6545016, lng: 18.3619708 },
    { lat: 48.6543942, lng: 18.3630722 },
    { lat: 48.6543314, lng: 18.3638235 },
    { lat: 48.6542696, lng: 18.364326 },
    { lat: 48.6541647, lng: 18.3649721 },
    { lat: 48.6541196, lng: 18.365213 },
    { lat: 48.6539191, lng: 18.3664051 },
    { lat: 48.6538737, lng: 18.3667042 },
    { lat: 48.6538125, lng: 18.3672006 },
    { lat: 48.6537737, lng: 18.3675934 },
    { lat: 48.6537667, lng: 18.3678887 },
    { lat: 48.6536355, lng: 18.3688703 },
    { lat: 48.6535101, lng: 18.3697709 },
    { lat: 48.6532996, lng: 18.3710078 },
    { lat: 48.6532097, lng: 18.3715763 },
    { lat: 48.6529416, lng: 18.3713825 },
    { lat: 48.6526469, lng: 18.3712785 },
    { lat: 48.6525229, lng: 18.3721882 },
    { lat: 48.652491, lng: 18.3725833 },
    { lat: 48.6523459, lng: 18.3740613 },
    { lat: 48.6521402, lng: 18.3764478 },
    { lat: 48.652127, lng: 18.3766347 },
    { lat: 48.6519379, lng: 18.3765843 },
    { lat: 48.6518248, lng: 18.3765784 },
    { lat: 48.6517491, lng: 18.376674 },
    { lat: 48.6516688, lng: 18.3769162 },
    { lat: 48.6516337, lng: 18.3770531 },
    { lat: 48.6515811, lng: 18.3771179 },
    { lat: 48.6514776, lng: 18.3771654 },
    { lat: 48.6513838, lng: 18.377162 },
    { lat: 48.6512863, lng: 18.3770721 },
    { lat: 48.6512794, lng: 18.376992 },
    { lat: 48.6510646, lng: 18.3768834 },
    { lat: 48.6509754, lng: 18.3769038 },
    { lat: 48.6509242, lng: 18.3769353 },
    { lat: 48.6508906, lng: 18.377098 },
    { lat: 48.6508681, lng: 18.3773428 },
    { lat: 48.6506484, lng: 18.3775699 },
    { lat: 48.6507112, lng: 18.3777018 },
    { lat: 48.6506566, lng: 18.3777954 },
    { lat: 48.6505931, lng: 18.3778295 },
  ],
  Turčianky: [
    { lat: 48.5701634, lng: 18.3240937 },
    { lat: 48.5700036, lng: 18.324499 },
    { lat: 48.5699024, lng: 18.3248462 },
    { lat: 48.5698197, lng: 18.3251992 },
    { lat: 48.5697351, lng: 18.3256582 },
    { lat: 48.5711155, lng: 18.326643 },
    { lat: 48.5709206, lng: 18.3271682 },
    { lat: 48.5708278, lng: 18.3275214 },
    { lat: 48.5712215, lng: 18.327861 },
    { lat: 48.5713958, lng: 18.328088 },
    { lat: 48.5721487, lng: 18.32923 },
    { lat: 48.5715531, lng: 18.3298747 },
    { lat: 48.5716901, lng: 18.3299801 },
    { lat: 48.5715081, lng: 18.3302753 },
    { lat: 48.5713725, lng: 18.3305702 },
    { lat: 48.5712478, lng: 18.3308598 },
    { lat: 48.5710695, lng: 18.3312197 },
    { lat: 48.5708122, lng: 18.3317757 },
    { lat: 48.5707355, lng: 18.3319363 },
    { lat: 48.5705814, lng: 18.3323052 },
    { lat: 48.5705151, lng: 18.3324295 },
    { lat: 48.5694109, lng: 18.3319175 },
    { lat: 48.5693762, lng: 18.3320826 },
    { lat: 48.5693227, lng: 18.3325123 },
    { lat: 48.5699863, lng: 18.332715 },
    { lat: 48.5702521, lng: 18.3328665 },
    { lat: 48.5707587, lng: 18.3331467 },
    { lat: 48.5710206, lng: 18.333345 },
    { lat: 48.5710597, lng: 18.3333393 },
    { lat: 48.5710986, lng: 18.333392 },
    { lat: 48.571206, lng: 18.3331741 },
    { lat: 48.571872, lng: 18.3322418 },
    { lat: 48.5720436, lng: 18.3319636 },
    { lat: 48.5725725, lng: 18.3312609 },
    { lat: 48.5730596, lng: 18.3307352 },
    { lat: 48.5732184, lng: 18.3306076 },
    { lat: 48.5733464, lng: 18.330538 },
    { lat: 48.5737895, lng: 18.3304929 },
    { lat: 48.5737919, lng: 18.3305564 },
    { lat: 48.573978, lng: 18.3307947 },
    { lat: 48.5741748, lng: 18.3310438 },
    { lat: 48.574274, lng: 18.3307998 },
    { lat: 48.5743483, lng: 18.3308958 },
    { lat: 48.5749109, lng: 18.331648 },
    { lat: 48.5760372, lng: 18.3330907 },
    { lat: 48.5777313, lng: 18.3346178 },
    { lat: 48.5779987, lng: 18.3353167 },
    { lat: 48.57869, lng: 18.3370709 },
    { lat: 48.5789037, lng: 18.3380162 },
    { lat: 48.5793039, lng: 18.3396902 },
    { lat: 48.5790827, lng: 18.3410497 },
    { lat: 48.5792412, lng: 18.3420189 },
    { lat: 48.5792873, lng: 18.3424747 },
    { lat: 48.5793006, lng: 18.3425108 },
    { lat: 48.5794629, lng: 18.3423275 },
    { lat: 48.5805787, lng: 18.3408879 },
    { lat: 48.5811991, lng: 18.340545 },
    { lat: 48.5817667, lng: 18.3404263 },
    { lat: 48.582034, lng: 18.3404836 },
    { lat: 48.5833195, lng: 18.3410707 },
    { lat: 48.5841842, lng: 18.3412763 },
    { lat: 48.5844521, lng: 18.3415081 },
    { lat: 48.5858594, lng: 18.3418316 },
    { lat: 48.5863344, lng: 18.3418324 },
    { lat: 48.5865863, lng: 18.3416786 },
    { lat: 48.5871136, lng: 18.3415465 },
    { lat: 48.5873995, lng: 18.3413541 },
    { lat: 48.5878519, lng: 18.3411924 },
    { lat: 48.5885289, lng: 18.3411523 },
    { lat: 48.5892213, lng: 18.341247 },
    { lat: 48.5897451, lng: 18.3414133 },
    { lat: 48.5907862, lng: 18.3413479 },
    { lat: 48.5912665, lng: 18.3414204 },
    { lat: 48.5915738, lng: 18.3413699 },
    { lat: 48.5917942, lng: 18.3412151 },
    { lat: 48.5921298, lng: 18.3407854 },
    { lat: 48.5924303, lng: 18.3402322 },
    { lat: 48.5925287, lng: 18.3399573 },
    { lat: 48.5927208, lng: 18.339062 },
    { lat: 48.5930229, lng: 18.3385959 },
    { lat: 48.593555, lng: 18.3374466 },
    { lat: 48.5941009, lng: 18.3364659 },
    { lat: 48.5942041, lng: 18.3358361 },
    { lat: 48.5941158, lng: 18.3349517 },
    { lat: 48.5939823, lng: 18.3343244 },
    { lat: 48.593588, lng: 18.3337567 },
    { lat: 48.5930066, lng: 18.3332122 },
    { lat: 48.5930537, lng: 18.3331143 },
    { lat: 48.5929933, lng: 18.3330767 },
    { lat: 48.5920657, lng: 18.3317366 },
    { lat: 48.5910165, lng: 18.3300594 },
    { lat: 48.5901424, lng: 18.3286082 },
    { lat: 48.5900703, lng: 18.3284709 },
    { lat: 48.5899543, lng: 18.3282324 },
    { lat: 48.589856, lng: 18.3279913 },
    { lat: 48.5898074, lng: 18.3278304 },
    { lat: 48.5895612, lng: 18.3271478 },
    { lat: 48.589322, lng: 18.3265877 },
    { lat: 48.5890874, lng: 18.3261142 },
    { lat: 48.5889679, lng: 18.3259756 },
    { lat: 48.5887732, lng: 18.3255637 },
    { lat: 48.5886861, lng: 18.3253401 },
    { lat: 48.5883515, lng: 18.3245912 },
    { lat: 48.5882449, lng: 18.3242823 },
    { lat: 48.588201, lng: 18.324025 },
    { lat: 48.5881669, lng: 18.3237435 },
    { lat: 48.5881753, lng: 18.3232731 },
    { lat: 48.5881954, lng: 18.3229109 },
    { lat: 48.5881966, lng: 18.3225722 },
    { lat: 48.5881182, lng: 18.3221425 },
    { lat: 48.5875988, lng: 18.3205817 },
    { lat: 48.5874828, lng: 18.3202873 },
    { lat: 48.5872894, lng: 18.319861 },
    { lat: 48.5871772, lng: 18.3193537 },
    { lat: 48.5871347, lng: 18.3190202 },
    { lat: 48.5871161, lng: 18.3186327 },
    { lat: 48.5871571, lng: 18.3182208 },
    { lat: 48.5872233, lng: 18.3178745 },
    { lat: 48.5872787, lng: 18.3176375 },
    { lat: 48.5873719, lng: 18.3173126 },
    { lat: 48.5875243, lng: 18.3169936 },
    { lat: 48.5877521, lng: 18.3165748 },
    { lat: 48.587775, lng: 18.3165775 },
    { lat: 48.5879576, lng: 18.3161994 },
    { lat: 48.588082, lng: 18.3158452 },
    { lat: 48.5881164, lng: 18.3156558 },
    { lat: 48.5881521, lng: 18.3152083 },
    { lat: 48.5881631, lng: 18.314669 },
    { lat: 48.5881805, lng: 18.3144366 },
    { lat: 48.5885321, lng: 18.3130139 },
    { lat: 48.5887172, lng: 18.3125977 },
    { lat: 48.5889915, lng: 18.312263 },
    { lat: 48.5889861, lng: 18.3121689 },
    { lat: 48.5888151, lng: 18.3122558 },
    { lat: 48.5882636, lng: 18.3123781 },
    { lat: 48.5878687, lng: 18.3125776 },
    { lat: 48.5876818, lng: 18.312705 },
    { lat: 48.5868779, lng: 18.3134721 },
    { lat: 48.5862552, lng: 18.3140112 },
    { lat: 48.5862323, lng: 18.3139867 },
    { lat: 48.5862066, lng: 18.3139712 },
    { lat: 48.5860143, lng: 18.3139026 },
    { lat: 48.5855236, lng: 18.3141022 },
    { lat: 48.5849354, lng: 18.3142738 },
    { lat: 48.5841692, lng: 18.3141743 },
    { lat: 48.583137, lng: 18.314656 },
    { lat: 48.5830035, lng: 18.31467 },
    { lat: 48.5817702, lng: 18.3147471 },
    { lat: 48.581226, lng: 18.3148403 },
    { lat: 48.5807313, lng: 18.3150309 },
    { lat: 48.5806003, lng: 18.3150474 },
    { lat: 48.5800699, lng: 18.3152044 },
    { lat: 48.580029, lng: 18.315221 },
    { lat: 48.5795904, lng: 18.3143485 },
    { lat: 48.5792908, lng: 18.3137806 },
    { lat: 48.5788931, lng: 18.3131342 },
    { lat: 48.5786226, lng: 18.3127036 },
    { lat: 48.5782001, lng: 18.3129715 },
    { lat: 48.5776555, lng: 18.3131784 },
    { lat: 48.5770964, lng: 18.3134253 },
    { lat: 48.5768172, lng: 18.3137146 },
    { lat: 48.5765411, lng: 18.3140206 },
    { lat: 48.5759074, lng: 18.3147652 },
    { lat: 48.5757499, lng: 18.3148906 },
    { lat: 48.5754489, lng: 18.3150939 },
    { lat: 48.574498, lng: 18.3159168 },
    { lat: 48.5741895, lng: 18.3163648 },
    { lat: 48.5740821, lng: 18.3164812 },
    { lat: 48.5736124, lng: 18.3168541 },
    { lat: 48.5733482, lng: 18.3170103 },
    { lat: 48.5734039, lng: 18.317297 },
    { lat: 48.5728723, lng: 18.317317 },
    { lat: 48.5719684, lng: 18.3174917 },
    { lat: 48.5715736, lng: 18.3175332 },
    { lat: 48.5711572, lng: 18.3175123 },
    { lat: 48.5707283, lng: 18.3177093 },
    { lat: 48.5705905, lng: 18.3177256 },
    { lat: 48.5699875, lng: 18.317636 },
    { lat: 48.5698829, lng: 18.3176441 },
    { lat: 48.5696731, lng: 18.3179052 },
    { lat: 48.56956, lng: 18.3180993 },
    { lat: 48.5695467, lng: 18.3181242 },
    { lat: 48.5695042, lng: 18.318447 },
    { lat: 48.5694945, lng: 18.3186763 },
    { lat: 48.5695084, lng: 18.318858 },
    { lat: 48.5699019, lng: 18.3188972 },
    { lat: 48.5699165, lng: 18.3197895 },
    { lat: 48.5698999, lng: 18.3205529 },
    { lat: 48.5698713, lng: 18.3213051 },
    { lat: 48.5698859, lng: 18.3222028 },
    { lat: 48.569905, lng: 18.3230361 },
    { lat: 48.5703439, lng: 18.3233149 },
    { lat: 48.5701634, lng: 18.3240937 },
  ],
  VeľkéKršteňany: [
    { lat: 48.6383307, lng: 18.3978947 },
    { lat: 48.6380663, lng: 18.3980246 },
    { lat: 48.6379894, lng: 18.3980305 },
    { lat: 48.6381624, lng: 18.39845 },
    { lat: 48.6382351, lng: 18.3987829 },
    { lat: 48.6381987, lng: 18.3988007 },
    { lat: 48.6382994, lng: 18.4000948 },
    { lat: 48.6385456, lng: 18.4032931 },
    { lat: 48.6386528, lng: 18.4046471 },
    { lat: 48.6387844, lng: 18.4054073 },
    { lat: 48.6390042, lng: 18.4063171 },
    { lat: 48.6392855, lng: 18.4075966 },
    { lat: 48.6395567, lng: 18.4090596 },
    { lat: 48.6397562, lng: 18.4101707 },
    { lat: 48.6400436, lng: 18.4116834 },
    { lat: 48.6406101, lng: 18.4146197 },
    { lat: 48.6406219, lng: 18.4146107 },
    { lat: 48.6412598, lng: 18.4163398 },
    { lat: 48.6415263, lng: 18.417053 },
    { lat: 48.6422747, lng: 18.4190688 },
    { lat: 48.6427722, lng: 18.4204033 },
    { lat: 48.6431997, lng: 18.4215574 },
    { lat: 48.6433137, lng: 18.4212768 },
    { lat: 48.6433959, lng: 18.4211312 },
    { lat: 48.6438146, lng: 18.4220456 },
    { lat: 48.6438686, lng: 18.4217584 },
    { lat: 48.6441259, lng: 18.420851 },
    { lat: 48.6446863, lng: 18.4221199 },
    { lat: 48.6442168, lng: 18.4226036 },
    { lat: 48.644416, lng: 18.4230218 },
    { lat: 48.6445432, lng: 18.4229981 },
    { lat: 48.6449312, lng: 18.4234395 },
    { lat: 48.6451114, lng: 18.4235993 },
    { lat: 48.6449916, lng: 18.4237354 },
    { lat: 48.6456623, lng: 18.4248413 },
    { lat: 48.6459525, lng: 18.4253532 },
    { lat: 48.6460117, lng: 18.4255044 },
    { lat: 48.6461754, lng: 18.4257488 },
    { lat: 48.646479, lng: 18.4261199 },
    { lat: 48.6467056, lng: 18.4264782 },
    { lat: 48.6467123, lng: 18.4267432 },
    { lat: 48.6468427, lng: 18.4267117 },
    { lat: 48.6469667, lng: 18.4268925 },
    { lat: 48.6472119, lng: 18.4271062 },
    { lat: 48.647761, lng: 18.4272632 },
    { lat: 48.648065, lng: 18.4276361 },
    { lat: 48.6484296, lng: 18.4279873 },
    { lat: 48.6490531, lng: 18.428507 },
    { lat: 48.6496468, lng: 18.4290205 },
    { lat: 48.6502422, lng: 18.4295196 },
    { lat: 48.650821, lng: 18.43005 },
    { lat: 48.6510584, lng: 18.4302625 },
    { lat: 48.6504053, lng: 18.4320306 },
    { lat: 48.6506493, lng: 18.4323415 },
    { lat: 48.6508568, lng: 18.4321351 },
    { lat: 48.6510587, lng: 18.431974 },
    { lat: 48.651497, lng: 18.4316605 },
    { lat: 48.652032, lng: 18.431342 },
    { lat: 48.6518729, lng: 18.4308512 },
    { lat: 48.6524488, lng: 18.4309631 },
    { lat: 48.6529356, lng: 18.4309939 },
    { lat: 48.6534437, lng: 18.4311451 },
    { lat: 48.6538489, lng: 18.4289327 },
    { lat: 48.6549958, lng: 18.4298415 },
    { lat: 48.6549289, lng: 18.43016 },
    { lat: 48.6548949, lng: 18.4305597 },
    { lat: 48.654902, lng: 18.4307729 },
    { lat: 48.6549978, lng: 18.4311957 },
    { lat: 48.6550829, lng: 18.4316131 },
    { lat: 48.6557588, lng: 18.4321034 },
    { lat: 48.6557226, lng: 18.4325876 },
    { lat: 48.6558443, lng: 18.4327053 },
    { lat: 48.6560175, lng: 18.4327376 },
    { lat: 48.6564623, lng: 18.4332508 },
    { lat: 48.6567538, lng: 18.4337452 },
    { lat: 48.6571999, lng: 18.4345669 },
    { lat: 48.6576583, lng: 18.4355301 },
    { lat: 48.6579145, lng: 18.4351673 },
    { lat: 48.6584122, lng: 18.4345181 },
    { lat: 48.6584556, lng: 18.4344694 },
    { lat: 48.6591491, lng: 18.4361708 },
    { lat: 48.6596708, lng: 18.43758 },
    { lat: 48.6596377, lng: 18.4376135 },
    { lat: 48.6590895, lng: 18.4382066 },
    { lat: 48.6582074, lng: 18.4392213 },
    { lat: 48.6577292, lng: 18.4397549 },
    { lat: 48.6575893, lng: 18.4398964 },
    { lat: 48.657608, lng: 18.4401306 },
    { lat: 48.6571227, lng: 18.4401481 },
    { lat: 48.6568021, lng: 18.4401654 },
    { lat: 48.6561432, lng: 18.440304 },
    { lat: 48.6555528, lng: 18.4404435 },
    { lat: 48.6557176, lng: 18.4412958 },
    { lat: 48.6558459, lng: 18.4421477 },
    { lat: 48.6560097, lng: 18.4431061 },
    { lat: 48.6561864, lng: 18.4442588 },
    { lat: 48.6564231, lng: 18.4457396 },
    { lat: 48.656538, lng: 18.4465119 },
    { lat: 48.6566217, lng: 18.4470462 },
    { lat: 48.6567467, lng: 18.4477904 },
    { lat: 48.6568768, lng: 18.4485515 },
    { lat: 48.6570779, lng: 18.4498013 },
    { lat: 48.6571418, lng: 18.4502425 },
    { lat: 48.6572706, lng: 18.4509963 },
    { lat: 48.6574509, lng: 18.4521128 },
    { lat: 48.6576737, lng: 18.4533664 },
    { lat: 48.658045, lng: 18.4554705 },
    { lat: 48.6584159, lng: 18.4573416 },
    { lat: 48.658592, lng: 18.45816 },
    { lat: 48.659272, lng: 18.457501 },
    { lat: 48.659621, lng: 18.457225 },
    { lat: 48.660033, lng: 18.456837 },
    { lat: 48.660799, lng: 18.456966 },
    { lat: 48.660895, lng: 18.456936 },
    { lat: 48.662026, lng: 18.457196 },
    { lat: 48.662593, lng: 18.457322 },
    { lat: 48.662912, lng: 18.457695 },
    { lat: 48.663363, lng: 18.458041 },
    { lat: 48.664145, lng: 18.458799 },
    { lat: 48.664411, lng: 18.459021 },
    { lat: 48.664861, lng: 18.45928 },
    { lat: 48.665303, lng: 18.459341 },
    { lat: 48.665625, lng: 18.459143 },
    { lat: 48.666441, lng: 18.458179 },
    { lat: 48.667555, lng: 18.456571 },
    { lat: 48.667839, lng: 18.456412 },
    { lat: 48.668318, lng: 18.456355 },
    { lat: 48.668871, lng: 18.456141 },
    { lat: 48.669488, lng: 18.456084 },
    { lat: 48.669924, lng: 18.456 },
    { lat: 48.670554, lng: 18.456151 },
    { lat: 48.671194, lng: 18.457038 },
    { lat: 48.672107, lng: 18.457581 },
    { lat: 48.672911, lng: 18.457877 },
    { lat: 48.673606, lng: 18.457735 },
    { lat: 48.674417, lng: 18.457668 },
    { lat: 48.675076, lng: 18.457681 },
    { lat: 48.675571, lng: 18.457691 },
    { lat: 48.67603, lng: 18.45773 },
    { lat: 48.6768, lng: 18.457552 },
    { lat: 48.677439, lng: 18.457396 },
    { lat: 48.677887, lng: 18.457389 },
    { lat: 48.678641, lng: 18.457184 },
    { lat: 48.678834, lng: 18.456977 },
    { lat: 48.678858, lng: 18.456953 },
    { lat: 48.679372, lng: 18.456408 },
    { lat: 48.679996, lng: 18.455809 },
    { lat: 48.680782, lng: 18.455329 },
    { lat: 48.682543, lng: 18.453205 },
    { lat: 48.683025, lng: 18.452307 },
    { lat: 48.68305, lng: 18.452262 },
    { lat: 48.683671, lng: 18.451109 },
    { lat: 48.683828, lng: 18.449503 },
    { lat: 48.683704, lng: 18.449001 },
    { lat: 48.683694, lng: 18.448963 },
    { lat: 48.683449, lng: 18.447998 },
    { lat: 48.683399, lng: 18.447775 },
    { lat: 48.684226, lng: 18.446383 },
    { lat: 48.684131, lng: 18.445419 },
    { lat: 48.683896, lng: 18.444531 },
    { lat: 48.683681, lng: 18.442674 },
    { lat: 48.683654, lng: 18.442265 },
    { lat: 48.683396, lng: 18.4415 },
    { lat: 48.683332, lng: 18.441098 },
    { lat: 48.683432, lng: 18.440774 },
    { lat: 48.683705, lng: 18.440404 },
    { lat: 48.683797, lng: 18.440148 },
    { lat: 48.683785, lng: 18.440054 },
    { lat: 48.683566, lng: 18.439925 },
    { lat: 48.683061, lng: 18.43992 },
    { lat: 48.682531, lng: 18.439815 },
    { lat: 48.682069, lng: 18.439764 },
    { lat: 48.681584, lng: 18.439773 },
    { lat: 48.68105, lng: 18.439888 },
    { lat: 48.680688, lng: 18.440017 },
    { lat: 48.680283, lng: 18.440127 },
    { lat: 48.680212, lng: 18.43989 },
    { lat: 48.680223, lng: 18.43916 },
    { lat: 48.680125, lng: 18.438316 },
    { lat: 48.680102, lng: 18.438064 },
    { lat: 48.680102, lng: 18.437928 },
    { lat: 48.680128, lng: 18.437798 },
    { lat: 48.680212, lng: 18.437258 },
    { lat: 48.680406, lng: 18.436505 },
    { lat: 48.680216, lng: 18.436286 },
    { lat: 48.680302, lng: 18.435733 },
    { lat: 48.680335, lng: 18.43552 },
    { lat: 48.680397, lng: 18.435133 },
    { lat: 48.680476, lng: 18.434424 },
    { lat: 48.680481, lng: 18.433761 },
    { lat: 48.680503, lng: 18.433238 },
    { lat: 48.680491, lng: 18.433064 },
    { lat: 48.680288, lng: 18.432353 },
    { lat: 48.680148, lng: 18.43206 },
    { lat: 48.679974, lng: 18.431565 },
    { lat: 48.679847, lng: 18.431079 },
    { lat: 48.679736, lng: 18.430441 },
    { lat: 48.679388, lng: 18.430309 },
    { lat: 48.678985, lng: 18.430398 },
    { lat: 48.678859, lng: 18.430442 },
    { lat: 48.678554, lng: 18.430425 },
    { lat: 48.67843, lng: 18.430366 },
    { lat: 48.678266, lng: 18.430222 },
    { lat: 48.678022, lng: 18.429612 },
    { lat: 48.677856, lng: 18.429202 },
    { lat: 48.677815, lng: 18.429046 },
    { lat: 48.677693, lng: 18.428522 },
    { lat: 48.677633, lng: 18.428398 },
    { lat: 48.677607, lng: 18.428302 },
    { lat: 48.677539, lng: 18.42787 },
    { lat: 48.677396, lng: 18.427409 },
    { lat: 48.677379, lng: 18.42718 },
    { lat: 48.677398, lng: 18.426813 },
    { lat: 48.677854, lng: 18.426301 },
    { lat: 48.678175, lng: 18.425616 },
    { lat: 48.678248, lng: 18.424817 },
    { lat: 48.678229, lng: 18.424155 },
    { lat: 48.677987, lng: 18.422939 },
    { lat: 48.677819, lng: 18.422146 },
    { lat: 48.677834, lng: 18.42155 },
    { lat: 48.677841, lng: 18.421274 },
    { lat: 48.678146, lng: 18.419702 },
    { lat: 48.678303, lng: 18.418855 },
    { lat: 48.678302, lng: 18.418229 },
    { lat: 48.678319, lng: 18.41822 },
    { lat: 48.678335, lng: 18.418212 },
    { lat: 48.678234, lng: 18.417893 },
    { lat: 48.678137, lng: 18.417343 },
    { lat: 48.677995, lng: 18.41702 },
    { lat: 48.6779258, lng: 18.416994 },
    { lat: 48.6776606, lng: 18.4171951 },
    { lat: 48.6772916, lng: 18.4172919 },
    { lat: 48.6767624, lng: 18.417268 },
    { lat: 48.6760762, lng: 18.4172241 },
    { lat: 48.6758381, lng: 18.4173457 },
    { lat: 48.6751163, lng: 18.4174926 },
    { lat: 48.6746556, lng: 18.4175025 },
    { lat: 48.6743343, lng: 18.4174898 },
    { lat: 48.6739878, lng: 18.4175244 },
    { lat: 48.6738082, lng: 18.4175633 },
    { lat: 48.6734486, lng: 18.4177706 },
    { lat: 48.6729952, lng: 18.4157247 },
    { lat: 48.6722031, lng: 18.4142436 },
    { lat: 48.6714237, lng: 18.4141266 },
    { lat: 48.6711594, lng: 18.4140967 },
    { lat: 48.6703518, lng: 18.4136149 },
    { lat: 48.6701787, lng: 18.4132618 },
    { lat: 48.6696479, lng: 18.4131286 },
    { lat: 48.6693873, lng: 18.4130681 },
    { lat: 48.6692145, lng: 18.4130637 },
    { lat: 48.6687085, lng: 18.412973 },
    { lat: 48.6682976, lng: 18.4129688 },
    { lat: 48.668059, lng: 18.4129199 },
    { lat: 48.6679523, lng: 18.412927 },
    { lat: 48.667776, lng: 18.4129622 },
    { lat: 48.6675615, lng: 18.412962 },
    { lat: 48.6673238, lng: 18.4129682 },
    { lat: 48.6672274, lng: 18.4128756 },
    { lat: 48.6669439, lng: 18.4126867 },
    { lat: 48.6667885, lng: 18.4125708 },
    { lat: 48.6666667, lng: 18.412511 },
    { lat: 48.6665114, lng: 18.4124183 },
    { lat: 48.6663595, lng: 18.4124026 },
    { lat: 48.666143, lng: 18.4124836 },
    { lat: 48.6659514, lng: 18.4120029 },
    { lat: 48.6653648, lng: 18.4110931 },
    { lat: 48.6647201, lng: 18.4101133 },
    { lat: 48.6640406, lng: 18.4091528 },
    { lat: 48.6634079, lng: 18.4080455 },
    { lat: 48.6630341, lng: 18.4074296 },
    { lat: 48.6618455, lng: 18.4053145 },
    { lat: 48.6617554, lng: 18.4052168 },
    { lat: 48.6614018, lng: 18.4047214 },
    { lat: 48.6613541, lng: 18.4046964 },
    { lat: 48.661218, lng: 18.40452 },
    { lat: 48.6610554, lng: 18.4042584 },
    { lat: 48.6610105, lng: 18.4041188 },
    { lat: 48.6610455, lng: 18.4039795 },
    { lat: 48.6612068, lng: 18.4035318 },
    { lat: 48.6612988, lng: 18.4033424 },
    { lat: 48.6614368, lng: 18.4030447 },
    { lat: 48.6614937, lng: 18.4029529 },
    { lat: 48.6616244, lng: 18.402649 },
    { lat: 48.6617454, lng: 18.4024484 },
    { lat: 48.6618953, lng: 18.4022445 },
    { lat: 48.6615565, lng: 18.4013304 },
    { lat: 48.661309, lng: 18.400272 },
    { lat: 48.6603347, lng: 18.398945 },
    { lat: 48.6600291, lng: 18.3985217 },
    { lat: 48.6595364, lng: 18.3977005 },
    { lat: 48.658797, lng: 18.3964575 },
    { lat: 48.6579075, lng: 18.3950545 },
    { lat: 48.6576712, lng: 18.3939562 },
    { lat: 48.6572245, lng: 18.3940184 },
    { lat: 48.6565187, lng: 18.3941679 },
    { lat: 48.6561684, lng: 18.3941989 },
    { lat: 48.6559552, lng: 18.3942378 },
    { lat: 48.6557422, lng: 18.3943046 },
    { lat: 48.6551491, lng: 18.3944283 },
    { lat: 48.6549079, lng: 18.394474 },
    { lat: 48.6547772, lng: 18.3944795 },
    { lat: 48.6547704, lng: 18.3944761 },
    { lat: 48.6544836, lng: 18.3944923 },
    { lat: 48.6538736, lng: 18.3944997 },
    { lat: 48.6536287, lng: 18.3944577 },
    { lat: 48.6535287, lng: 18.394403 },
    { lat: 48.6530352, lng: 18.3944551 },
    { lat: 48.6527825, lng: 18.3945183 },
    { lat: 48.6524453, lng: 18.3946187 },
    { lat: 48.6520078, lng: 18.3947425 },
    { lat: 48.6518014, lng: 18.3948066 },
    { lat: 48.6511867, lng: 18.3950137 },
    { lat: 48.6507443, lng: 18.3951513 },
    { lat: 48.650402, lng: 18.3952017 },
    { lat: 48.6488952, lng: 18.3953521 },
    { lat: 48.6488699, lng: 18.3953435 },
    { lat: 48.6488494, lng: 18.3953619 },
    { lat: 48.6488586, lng: 18.3954449 },
    { lat: 48.6488132, lng: 18.3954376 },
    { lat: 48.6473774, lng: 18.3952442 },
    { lat: 48.6463208, lng: 18.3951328 },
    { lat: 48.6457259, lng: 18.3950848 },
    { lat: 48.6451173, lng: 18.3950745 },
    { lat: 48.6445127, lng: 18.3952171 },
    { lat: 48.644219, lng: 18.3952908 },
    { lat: 48.6433714, lng: 18.3952639 },
    { lat: 48.643035, lng: 18.3953027 },
    { lat: 48.6425582, lng: 18.3955321 },
    { lat: 48.6423096, lng: 18.3956062 },
    { lat: 48.6415781, lng: 18.3955788 },
    { lat: 48.6414237, lng: 18.3956419 },
    { lat: 48.6410494, lng: 18.3959667 },
    { lat: 48.6404985, lng: 18.3965501 },
    { lat: 48.6399571, lng: 18.3969725 },
    { lat: 48.6392349, lng: 18.3974343 },
    { lat: 48.6387227, lng: 18.3976978 },
    { lat: 48.6383748, lng: 18.397873 },
    { lat: 48.6383307, lng: 18.3978947 },
  ],
  VeľkéUherce: [
    { lat: 48.6342846, lng: 18.4126159 },
    { lat: 48.634159, lng: 18.4126917 },
    { lat: 48.6334327, lng: 18.4132415 },
    { lat: 48.6326302, lng: 18.4138455 },
    { lat: 48.6319716, lng: 18.4143752 },
    { lat: 48.6305516, lng: 18.4154929 },
    { lat: 48.629516, lng: 18.416304 },
    { lat: 48.6285101, lng: 18.41706 },
    { lat: 48.6273338, lng: 18.4179387 },
    { lat: 48.6261441, lng: 18.4188192 },
    { lat: 48.6260998, lng: 18.4189318 },
    { lat: 48.6250763, lng: 18.4197208 },
    { lat: 48.6242522, lng: 18.4203983 },
    { lat: 48.624165, lng: 18.4204721 },
    { lat: 48.6238707, lng: 18.4206753 },
    { lat: 48.6229916, lng: 18.4211988 },
    { lat: 48.6223465, lng: 18.4215494 },
    { lat: 48.6217975, lng: 18.4218183 },
    { lat: 48.6212029, lng: 18.4221026 },
    { lat: 48.6206349, lng: 18.422355 },
    { lat: 48.6203423, lng: 18.4224701 },
    { lat: 48.6199599, lng: 18.4226127 },
    { lat: 48.6193948, lng: 18.4228144 },
    { lat: 48.6189944, lng: 18.4229596 },
    { lat: 48.6183701, lng: 18.4232159 },
    { lat: 48.61827, lng: 18.4232007 },
    { lat: 48.6182588, lng: 18.423123 },
    { lat: 48.6174018, lng: 18.4234522 },
    { lat: 48.6174205, lng: 18.423586 },
    { lat: 48.6168755, lng: 18.4237663 },
    { lat: 48.6157644, lng: 18.4240503 },
    { lat: 48.6153728, lng: 18.4229529 },
    { lat: 48.615167, lng: 18.4222581 },
    { lat: 48.6150049, lng: 18.4218387 },
    { lat: 48.6149135, lng: 18.4216763 },
    { lat: 48.6147195, lng: 18.4205015 },
    { lat: 48.6146626, lng: 18.4202092 },
    { lat: 48.6144447, lng: 18.4194974 },
    { lat: 48.6140668, lng: 18.4188722 },
    { lat: 48.6139547, lng: 18.4186001 },
    { lat: 48.6138643, lng: 18.4184144 },
    { lat: 48.6133825, lng: 18.4176052 },
    { lat: 48.6129528, lng: 18.4167951 },
    { lat: 48.6128233, lng: 18.4157277 },
    { lat: 48.6128744, lng: 18.4154692 },
    { lat: 48.6125217, lng: 18.4145876 },
    { lat: 48.6119499, lng: 18.4137799 },
    { lat: 48.6113889, lng: 18.4127659 },
    { lat: 48.610593, lng: 18.4113399 },
    { lat: 48.6100718, lng: 18.4120049 },
    { lat: 48.6093811, lng: 18.4125289 },
    { lat: 48.6088177, lng: 18.4130445 },
    { lat: 48.6080996, lng: 18.4133392 },
    { lat: 48.6075563, lng: 18.4136522 },
    { lat: 48.6074979, lng: 18.4141598 },
    { lat: 48.6074671, lng: 18.4142364 },
    { lat: 48.6072479, lng: 18.4148584 },
    { lat: 48.6069734, lng: 18.4146491 },
    { lat: 48.6069032, lng: 18.4148448 },
    { lat: 48.6068076, lng: 18.4151741 },
    { lat: 48.6064777, lng: 18.4157528 },
    { lat: 48.606116, lng: 18.416164 },
    { lat: 48.6058325, lng: 18.4167069 },
    { lat: 48.6054962, lng: 18.417374 },
    { lat: 48.6050643, lng: 18.4184464 },
    { lat: 48.6044498, lng: 18.4193662 },
    { lat: 48.6039781, lng: 18.4200102 },
    { lat: 48.6038989, lng: 18.4202309 },
    { lat: 48.6038236, lng: 18.4203773 },
    { lat: 48.6036691, lng: 18.420599 },
    { lat: 48.6034605, lng: 18.4206817 },
    { lat: 48.6033333, lng: 18.4208689 },
    { lat: 48.6031919, lng: 18.4211649 },
    { lat: 48.6030175, lng: 18.4212978 },
    { lat: 48.603022, lng: 18.421396 },
    { lat: 48.6028847, lng: 18.4216468 },
    { lat: 48.6028573, lng: 18.4217829 },
    { lat: 48.602772, lng: 18.4219755 },
    { lat: 48.6028544, lng: 18.4223817 },
    { lat: 48.602706, lng: 18.4225905 },
    { lat: 48.6025213, lng: 18.4227586 },
    { lat: 48.60233, lng: 18.4228883 },
    { lat: 48.6017964, lng: 18.4232154 },
    { lat: 48.6016638, lng: 18.4232842 },
    { lat: 48.6013517, lng: 18.4234317 },
    { lat: 48.6010561, lng: 18.4235529 },
    { lat: 48.6009201, lng: 18.4236022 },
    { lat: 48.6007728, lng: 18.4235941 },
    { lat: 48.6004253, lng: 18.424465 },
    { lat: 48.6000655, lng: 18.4253346 },
    { lat: 48.6000324, lng: 18.4254282 },
    { lat: 48.5998118, lng: 18.425763 },
    { lat: 48.5995104, lng: 18.4262863 },
    { lat: 48.5993267, lng: 18.4262293 },
    { lat: 48.5991302, lng: 18.426144 },
    { lat: 48.5988431, lng: 18.4259929 },
    { lat: 48.5978934, lng: 18.4283071 },
    { lat: 48.5976501, lng: 18.4286593 },
    { lat: 48.5976224, lng: 18.428727 },
    { lat: 48.5971071, lng: 18.4300916 },
    { lat: 48.596607, lng: 18.431424 },
    { lat: 48.5962345, lng: 18.4323945 },
    { lat: 48.5961527, lng: 18.4326268 },
    { lat: 48.5958575, lng: 18.4333844 },
    { lat: 48.595802, lng: 18.4335432 },
    { lat: 48.5955509, lng: 18.4342026 },
    { lat: 48.5954375, lng: 18.4342279 },
    { lat: 48.5953516, lng: 18.4342869 },
    { lat: 48.5953145, lng: 18.4342478 },
    { lat: 48.595305, lng: 18.4342222 },
    { lat: 48.5952264, lng: 18.4342286 },
    { lat: 48.5951713, lng: 18.4341896 },
    { lat: 48.5952179, lng: 18.4340964 },
    { lat: 48.595172, lng: 18.4339172 },
    { lat: 48.5951717, lng: 18.4338524 },
    { lat: 48.5950565, lng: 18.4336681 },
    { lat: 48.5946971, lng: 18.4337784 },
    { lat: 48.5946143, lng: 18.4337304 },
    { lat: 48.5941128, lng: 18.4334734 },
    { lat: 48.5936685, lng: 18.4332551 },
    { lat: 48.5929083, lng: 18.4329332 },
    { lat: 48.5927076, lng: 18.4328527 },
    { lat: 48.5925006, lng: 18.4330873 },
    { lat: 48.5920932, lng: 18.4335116 },
    { lat: 48.5918729, lng: 18.4337711 },
    { lat: 48.5915948, lng: 18.4342075 },
    { lat: 48.5914348, lng: 18.4346328 },
    { lat: 48.5913487, lng: 18.4348907 },
    { lat: 48.5906792, lng: 18.4354107 },
    { lat: 48.5904838, lng: 18.4356285 },
    { lat: 48.5903162, lng: 18.4357987 },
    { lat: 48.5900311, lng: 18.4360492 },
    { lat: 48.5899061, lng: 18.4361352 },
    { lat: 48.5896096, lng: 18.4363013 },
    { lat: 48.5894561, lng: 18.4364233 },
    { lat: 48.58929, lng: 18.4364585 },
    { lat: 48.5891324, lng: 18.4365444 },
    { lat: 48.5889785, lng: 18.4366172 },
    { lat: 48.5888179, lng: 18.4366504 },
    { lat: 48.5886634, lng: 18.4366934 },
    { lat: 48.5883991, lng: 18.4367492 },
    { lat: 48.5881092, lng: 18.4367694 },
    { lat: 48.5880514, lng: 18.43646 },
    { lat: 48.5873839, lng: 18.4366668 },
    { lat: 48.587214, lng: 18.4367571 },
    { lat: 48.5870714, lng: 18.4368706 },
    { lat: 48.5869382, lng: 18.4370142 },
    { lat: 48.5868118, lng: 18.4371945 },
    { lat: 48.5866441, lng: 18.4375235 },
    { lat: 48.5863917, lng: 18.4377339 },
    { lat: 48.58627, lng: 18.4379599 },
    { lat: 48.5858084, lng: 18.4389905 },
    { lat: 48.5855798, lng: 18.4395275 },
    { lat: 48.5855533, lng: 18.4394756 },
    { lat: 48.5853596, lng: 18.4392182 },
    { lat: 48.5852636, lng: 18.4391551 },
    { lat: 48.5851738, lng: 18.4391209 },
    { lat: 48.5851001, lng: 18.439117 },
    { lat: 48.584733, lng: 18.4400416 },
    { lat: 48.5843447, lng: 18.4409914 },
    { lat: 48.5836312, lng: 18.4428123 },
    { lat: 48.5833679, lng: 18.4431288 },
    { lat: 48.583281, lng: 18.4434928 },
    { lat: 48.5832372, lng: 18.4440068 },
    { lat: 48.5831111, lng: 18.4448215 },
    { lat: 48.5830292, lng: 18.4453392 },
    { lat: 48.5828113, lng: 18.4466942 },
    { lat: 48.5819931, lng: 18.4463445 },
    { lat: 48.5813581, lng: 18.4460851 },
    { lat: 48.5794516, lng: 18.4452933 },
    { lat: 48.5787682, lng: 18.4449905 },
    { lat: 48.5782668, lng: 18.4450209 },
    { lat: 48.5774457, lng: 18.4453297 },
    { lat: 48.5771083, lng: 18.4462016 },
    { lat: 48.5770986, lng: 18.4464381 },
    { lat: 48.5770915, lng: 18.4466098 },
    { lat: 48.5769796, lng: 18.4470735 },
    { lat: 48.5768079, lng: 18.4475351 },
    { lat: 48.576559, lng: 18.4479919 },
    { lat: 48.5763402, lng: 18.4482734 },
    { lat: 48.5761389, lng: 18.4485325 },
    { lat: 48.5757148, lng: 18.4490441 },
    { lat: 48.5748387, lng: 18.4496618 },
    { lat: 48.5743956, lng: 18.4499702 },
    { lat: 48.5740806, lng: 18.4499853 },
    { lat: 48.5734564, lng: 18.4499196 },
    { lat: 48.5727382, lng: 18.450252 },
    { lat: 48.5723433, lng: 18.4503933 },
    { lat: 48.5720168, lng: 18.4504056 },
    { lat: 48.5718323, lng: 18.4504046 },
    { lat: 48.571218, lng: 18.4504011 },
    { lat: 48.5709147, lng: 18.4506274 },
    { lat: 48.5704956, lng: 18.4509217 },
    { lat: 48.5699267, lng: 18.4509203 },
    { lat: 48.5693762, lng: 18.4512665 },
    { lat: 48.5687716, lng: 18.4511652 },
    { lat: 48.5679309, lng: 18.4513651 },
    { lat: 48.567215, lng: 18.4506379 },
    { lat: 48.5663819, lng: 18.4498367 },
    { lat: 48.5657806, lng: 18.4492468 },
    { lat: 48.5651785, lng: 18.4493323 },
    { lat: 48.5644858, lng: 18.4489045 },
    { lat: 48.5638409, lng: 18.4486873 },
    { lat: 48.5629291, lng: 18.4477494 },
    { lat: 48.5620147, lng: 18.4473539 },
    { lat: 48.5612035, lng: 18.4470234 },
    { lat: 48.5603667, lng: 18.446646 },
    { lat: 48.5597798, lng: 18.4463969 },
    { lat: 48.5590487, lng: 18.4460104 },
    { lat: 48.5582252, lng: 18.4441058 },
    { lat: 48.5567213, lng: 18.4406277 },
    { lat: 48.5566468, lng: 18.4408896 },
    { lat: 48.5565244, lng: 18.4410831 },
    { lat: 48.5562438, lng: 18.4413336 },
    { lat: 48.556075, lng: 18.4417459 },
    { lat: 48.5560149, lng: 18.4418596 },
    { lat: 48.555274, lng: 18.4420079 },
    { lat: 48.5549041, lng: 18.4425196 },
    { lat: 48.5548049, lng: 18.4427856 },
    { lat: 48.554717, lng: 18.4429894 },
    { lat: 48.5546293, lng: 18.4432974 },
    { lat: 48.5545734, lng: 18.4434091 },
    { lat: 48.5543095, lng: 18.4439192 },
    { lat: 48.5542349, lng: 18.4441156 },
    { lat: 48.5538093, lng: 18.4444972 },
    { lat: 48.5534957, lng: 18.444844 },
    { lat: 48.5532934, lng: 18.4452626 },
    { lat: 48.5531693, lng: 18.4455677 },
    { lat: 48.5530619, lng: 18.4458259 },
    { lat: 48.5528374, lng: 18.4464581 },
    { lat: 48.5527681, lng: 18.4470198 },
    { lat: 48.5527349, lng: 18.4472729 },
    { lat: 48.5526091, lng: 18.4478727 },
    { lat: 48.5520383, lng: 18.4484225 },
    { lat: 48.5517454, lng: 18.4488749 },
    { lat: 48.5515046, lng: 18.4493735 },
    { lat: 48.551364, lng: 18.4497882 },
    { lat: 48.5511464, lng: 18.4502372 },
    { lat: 48.5508567, lng: 18.450628 },
    { lat: 48.5501059, lng: 18.4517138 },
    { lat: 48.5499763, lng: 18.452104 },
    { lat: 48.5497935, lng: 18.4527127 },
    { lat: 48.5494719, lng: 18.4533994 },
    { lat: 48.5493544, lng: 18.4537033 },
    { lat: 48.549098, lng: 18.4544705 },
    { lat: 48.5488065, lng: 18.4551356 },
    { lat: 48.5485665, lng: 18.4562766 },
    { lat: 48.5484919, lng: 18.4569639 },
    { lat: 48.5485433, lng: 18.457546 },
    { lat: 48.5488071, lng: 18.4583178 },
    { lat: 48.5487878, lng: 18.4585795 },
    { lat: 48.548726, lng: 18.4590501 },
    { lat: 48.5486023, lng: 18.4595869 },
    { lat: 48.548571, lng: 18.459756 },
    { lat: 48.5485071, lng: 18.4602109 },
    { lat: 48.548407, lng: 18.461172 },
    { lat: 48.5483851, lng: 18.4614507 },
    { lat: 48.5484122, lng: 18.4619187 },
    { lat: 48.54851, lng: 18.4632576 },
    { lat: 48.5483392, lng: 18.4639594 },
    { lat: 48.5481483, lng: 18.4644445 },
    { lat: 48.5479191, lng: 18.4649497 },
    { lat: 48.5475971, lng: 18.4654907 },
    { lat: 48.5471255, lng: 18.4660206 },
    { lat: 48.5468737, lng: 18.4663609 },
    { lat: 48.546696, lng: 18.4671469 },
    { lat: 48.5466967, lng: 18.4674844 },
    { lat: 48.546598, lng: 18.46809 },
    { lat: 48.546645, lng: 18.468876 },
    { lat: 48.546439, lng: 18.470218 },
    { lat: 48.546985, lng: 18.472465 },
    { lat: 48.547223, lng: 18.473955 },
    { lat: 48.547278, lng: 18.474731 },
    { lat: 48.547598, lng: 18.476534 },
    { lat: 48.548602, lng: 18.478673 },
    { lat: 48.54876, lng: 18.479509 },
    { lat: 48.548902, lng: 18.479932 },
    { lat: 48.54897, lng: 18.480869 },
    { lat: 48.548852, lng: 18.48165 },
    { lat: 48.548662, lng: 18.482294 },
    { lat: 48.548332, lng: 18.482904 },
    { lat: 48.548318, lng: 18.48309 },
    { lat: 48.548456, lng: 18.483582 },
    { lat: 48.54853, lng: 18.483852 },
    { lat: 48.548693, lng: 18.484578 },
    { lat: 48.548888, lng: 18.485327 },
    { lat: 48.548998, lng: 18.485835 },
    { lat: 48.548999, lng: 18.48635 },
    { lat: 48.54909, lng: 18.486761 },
    { lat: 48.549216, lng: 18.487068 },
    { lat: 48.549302, lng: 18.487335 },
    { lat: 48.549333, lng: 18.487337 },
    { lat: 48.550041, lng: 18.487739 },
    { lat: 48.550189, lng: 18.487823 },
    { lat: 48.550339, lng: 18.487869 },
    { lat: 48.550379, lng: 18.487901 },
    { lat: 48.550466, lng: 18.487972 },
    { lat: 48.550974, lng: 18.488254 },
    { lat: 48.551302, lng: 18.488454 },
    { lat: 48.551495, lng: 18.488558 },
    { lat: 48.551969, lng: 18.488865 },
    { lat: 48.552346, lng: 18.489037 },
    { lat: 48.552531, lng: 18.48915 },
    { lat: 48.552682, lng: 18.489233 },
    { lat: 48.552877, lng: 18.489381 },
    { lat: 48.55313, lng: 18.489492 },
    { lat: 48.553229, lng: 18.48956 },
    { lat: 48.553705, lng: 18.489775 },
    { lat: 48.553842, lng: 18.48992 },
    { lat: 48.554181, lng: 18.490117 },
    { lat: 48.554345, lng: 18.49019 },
    { lat: 48.554794, lng: 18.490465 },
    { lat: 48.555496, lng: 18.490838 },
    { lat: 48.555942, lng: 18.491065 },
    { lat: 48.55638, lng: 18.491184 },
    { lat: 48.556486, lng: 18.491213 },
    { lat: 48.55674, lng: 18.491333 },
    { lat: 48.557033, lng: 18.491415 },
    { lat: 48.557259, lng: 18.491605 },
    { lat: 48.557507, lng: 18.491784 },
    { lat: 48.557638, lng: 18.491887 },
    { lat: 48.558087, lng: 18.492282 },
    { lat: 48.55827, lng: 18.492389 },
    { lat: 48.558567, lng: 18.492559 },
    { lat: 48.558961, lng: 18.492606 },
    { lat: 48.559183, lng: 18.492713 },
    { lat: 48.559356, lng: 18.492913 },
    { lat: 48.559686, lng: 18.49304 },
    { lat: 48.559794, lng: 18.493125 },
    { lat: 48.559966, lng: 18.493335 },
    { lat: 48.560105, lng: 18.493461 },
    { lat: 48.560192, lng: 18.493504 },
    { lat: 48.560511, lng: 18.493594 },
    { lat: 48.560803, lng: 18.493598 },
    { lat: 48.561141, lng: 18.493754 },
    { lat: 48.561447, lng: 18.493916 },
    { lat: 48.561635, lng: 18.49402 },
    { lat: 48.561972, lng: 18.494209 },
    { lat: 48.562533, lng: 18.494525 },
    { lat: 48.563639, lng: 18.495133 },
    { lat: 48.563639, lng: 18.495152 },
    { lat: 48.564108, lng: 18.495095 },
    { lat: 48.564253, lng: 18.495116 },
    { lat: 48.5643046, lng: 18.4951878 },
    { lat: 48.564399, lng: 18.495319 },
    { lat: 48.564584, lng: 18.495555 },
    { lat: 48.5649354, lng: 18.4958571 },
    { lat: 48.56512, lng: 18.495973 },
    { lat: 48.565763, lng: 18.496241 },
    { lat: 48.565997, lng: 18.496331 },
    { lat: 48.566144, lng: 18.4964863 },
    { lat: 48.566479, lng: 18.496689 },
    { lat: 48.566788, lng: 18.496795 },
    { lat: 48.567213, lng: 18.496992 },
    { lat: 48.567463, lng: 18.497055 },
    { lat: 48.567652, lng: 18.497136 },
    { lat: 48.568295, lng: 18.497381 },
    { lat: 48.568598, lng: 18.497484 },
    { lat: 48.568849, lng: 18.497467 },
    { lat: 48.568849, lng: 18.497498 },
    { lat: 48.56867, lng: 18.497758 },
    { lat: 48.568583, lng: 18.497823 },
    { lat: 48.568577, lng: 18.497889 },
    { lat: 48.569057, lng: 18.497973 },
    { lat: 48.569474, lng: 18.49716 },
    { lat: 48.569535, lng: 18.497045 },
    { lat: 48.569883, lng: 18.496407 },
    { lat: 48.570127, lng: 18.495841 },
    { lat: 48.570535, lng: 18.494954 },
    { lat: 48.5708307, lng: 18.4949499 },
    { lat: 48.571681, lng: 18.494938 },
    { lat: 48.572352, lng: 18.494744 },
    { lat: 48.572871, lng: 18.494565 },
    { lat: 48.573269, lng: 18.494361 },
    { lat: 48.574229, lng: 18.493546 },
    { lat: 48.57563, lng: 18.492377 },
    { lat: 48.577021, lng: 18.491752 },
    { lat: 48.578204, lng: 18.491097 },
    { lat: 48.578387, lng: 18.490996 },
    { lat: 48.579303, lng: 18.489889 },
    { lat: 48.579996, lng: 18.489047 },
    { lat: 48.580468, lng: 18.487461 },
    { lat: 48.580792, lng: 18.48656 },
    { lat: 48.580986, lng: 18.486022 },
    { lat: 48.581349, lng: 18.485102 },
    { lat: 48.581448, lng: 18.484854 },
    { lat: 48.581741, lng: 18.484422 },
    { lat: 48.582272, lng: 18.483522 },
    { lat: 48.582865, lng: 18.482516 },
    { lat: 48.583436, lng: 18.4815 },
    { lat: 48.583886, lng: 18.480701 },
    { lat: 48.584308, lng: 18.479933 },
    { lat: 48.584317, lng: 18.479915 },
    { lat: 48.584364, lng: 18.479779 },
    { lat: 48.584639, lng: 18.478967 },
    { lat: 48.585002, lng: 18.477906 },
    { lat: 48.585394, lng: 18.476758 },
    { lat: 48.585451, lng: 18.476683 },
    { lat: 48.58555, lng: 18.476549 },
    { lat: 48.585757, lng: 18.476267 },
    { lat: 48.586046, lng: 18.475881 },
    { lat: 48.586362, lng: 18.475474 },
    { lat: 48.586677, lng: 18.475075 },
    { lat: 48.587075, lng: 18.47458 },
    { lat: 48.587404, lng: 18.474132 },
    { lat: 48.587546, lng: 18.473939 },
    { lat: 48.587849, lng: 18.473362 },
    { lat: 48.588205, lng: 18.472693 },
    { lat: 48.588392, lng: 18.472316 },
    { lat: 48.588742, lng: 18.472162 },
    { lat: 48.589161, lng: 18.471987 },
    { lat: 48.589647, lng: 18.47178 },
    { lat: 48.590153, lng: 18.471565 },
    { lat: 48.590403, lng: 18.471674 },
    { lat: 48.590819, lng: 18.471854 },
    { lat: 48.591509, lng: 18.472148 },
    { lat: 48.592136, lng: 18.472418 },
    { lat: 48.592769, lng: 18.472602 },
    { lat: 48.593717, lng: 18.472874 },
    { lat: 48.593859, lng: 18.472915 },
    { lat: 48.594668, lng: 18.472603 },
    { lat: 48.59503, lng: 18.472465 },
    { lat: 48.595589, lng: 18.47223 },
    { lat: 48.595637, lng: 18.472208 },
    { lat: 48.59627, lng: 18.471943 },
    { lat: 48.596286, lng: 18.471937 },
    { lat: 48.597344, lng: 18.471007 },
    { lat: 48.598235, lng: 18.47023 },
    { lat: 48.598473, lng: 18.470014 },
    { lat: 48.598953, lng: 18.469588 },
    { lat: 48.599177, lng: 18.46939 },
    { lat: 48.599667, lng: 18.468964 },
    { lat: 48.5997274, lng: 18.4689109 },
    { lat: 48.600207, lng: 18.468489 },
    { lat: 48.600325, lng: 18.468385 },
    { lat: 48.601023, lng: 18.467773 },
    { lat: 48.60133, lng: 18.467506 },
    { lat: 48.601539, lng: 18.467327 },
    { lat: 48.602047, lng: 18.466882 },
    { lat: 48.602598, lng: 18.466403 },
    { lat: 48.602909, lng: 18.466128 },
    { lat: 48.603408, lng: 18.465686 },
    { lat: 48.60345, lng: 18.465648 },
    { lat: 48.604402, lng: 18.465877 },
    { lat: 48.60524, lng: 18.466079 },
    { lat: 48.605434, lng: 18.466074 },
    { lat: 48.605596, lng: 18.466072 },
    { lat: 48.605953, lng: 18.466061 },
    { lat: 48.606183, lng: 18.466051 },
    { lat: 48.606557, lng: 18.465847 },
    { lat: 48.607454, lng: 18.465259 },
    { lat: 48.6082616, lng: 18.4650839 },
    { lat: 48.6085694, lng: 18.4643062 },
    { lat: 48.6087189, lng: 18.4639285 },
    { lat: 48.6089714, lng: 18.4632914 },
    { lat: 48.6091666, lng: 18.4627817 },
    { lat: 48.6093667, lng: 18.4625665 },
    { lat: 48.6099637, lng: 18.461941 },
    { lat: 48.6102748, lng: 18.4612021 },
    { lat: 48.6105428, lng: 18.4605424 },
    { lat: 48.6108497, lng: 18.4597886 },
    { lat: 48.6112779, lng: 18.4591875 },
    { lat: 48.6118117, lng: 18.4592305 },
    { lat: 48.6124397, lng: 18.459268 },
    { lat: 48.6132273, lng: 18.4593296 },
    { lat: 48.6140221, lng: 18.4591992 },
    { lat: 48.6143274, lng: 18.4591494 },
    { lat: 48.6149203, lng: 18.4590605 },
    { lat: 48.6153789, lng: 18.4589931 },
    { lat: 48.6160997, lng: 18.458621 },
    { lat: 48.6161927, lng: 18.4585712 },
    { lat: 48.6170181, lng: 18.4589514 },
    { lat: 48.6174372, lng: 18.4591471 },
    { lat: 48.6179854, lng: 18.4583091 },
    { lat: 48.61854, lng: 18.4573443 },
    { lat: 48.6192561, lng: 18.456096 },
    { lat: 48.6200926, lng: 18.454639 },
    { lat: 48.6210235, lng: 18.4530163 },
    { lat: 48.6217315, lng: 18.4517835 },
    { lat: 48.621765, lng: 18.4517404 },
    { lat: 48.623119, lng: 18.4487706 },
    { lat: 48.6239981, lng: 18.4468422 },
    { lat: 48.6248036, lng: 18.4450743 },
    { lat: 48.6253132, lng: 18.443953 },
    { lat: 48.6254439, lng: 18.4439704 },
    { lat: 48.6255111, lng: 18.4431863 },
    { lat: 48.6255624, lng: 18.4426884 },
    { lat: 48.6256937, lng: 18.4412936 },
    { lat: 48.6257353, lng: 18.4408237 },
    { lat: 48.6258172, lng: 18.4398381 },
    { lat: 48.6259257, lng: 18.4385652 },
    { lat: 48.6260682, lng: 18.4371301 },
    { lat: 48.6260773, lng: 18.4370081 },
    { lat: 48.6261706, lng: 18.4362114 },
    { lat: 48.6261739, lng: 18.4361475 },
    { lat: 48.6274889, lng: 18.4364869 },
    { lat: 48.6288016, lng: 18.4368228 },
    { lat: 48.6295878, lng: 18.4370419 },
    { lat: 48.629601, lng: 18.4370808 },
    { lat: 48.6297088, lng: 18.437314 },
    { lat: 48.6297246, lng: 18.4372931 },
    { lat: 48.6297793, lng: 18.4372003 },
    { lat: 48.6297948, lng: 18.4371765 },
    { lat: 48.6289617, lng: 18.4351335 },
    { lat: 48.6284574, lng: 18.4338942 },
    { lat: 48.6286392, lng: 18.4339411 },
    { lat: 48.6286421, lng: 18.4337468 },
    { lat: 48.6283221, lng: 18.4335529 },
    { lat: 48.6278815, lng: 18.4324436 },
    { lat: 48.6275378, lng: 18.4314561 },
    { lat: 48.6272618, lng: 18.4305324 },
    { lat: 48.6272493, lng: 18.4304891 },
    { lat: 48.6286249, lng: 18.4305158 },
    { lat: 48.6297537, lng: 18.4304479 },
    { lat: 48.6304955, lng: 18.4301953 },
    { lat: 48.6312901, lng: 18.4298558 },
    { lat: 48.6322938, lng: 18.4297071 },
    { lat: 48.6324638, lng: 18.4297205 },
    { lat: 48.6326985, lng: 18.4298053 },
    { lat: 48.6337704, lng: 18.4301322 },
    { lat: 48.6337954, lng: 18.4301358 },
    { lat: 48.6338261, lng: 18.4296134 },
    { lat: 48.6338527, lng: 18.4286495 },
    { lat: 48.6338484, lng: 18.4266645 },
    { lat: 48.6338424, lng: 18.4248349 },
    { lat: 48.6360062, lng: 18.4239427 },
    { lat: 48.6362257, lng: 18.4238511 },
    { lat: 48.6362669, lng: 18.423721 },
    { lat: 48.6360827, lng: 18.4235129 },
    { lat: 48.6359774, lng: 18.4231827 },
    { lat: 48.635981, lng: 18.42276 },
    { lat: 48.6359963, lng: 18.4222648 },
    { lat: 48.636035, lng: 18.42215 },
    { lat: 48.6362129, lng: 18.4217355 },
    { lat: 48.6362339, lng: 18.421506 },
    { lat: 48.6361764, lng: 18.4207578 },
    { lat: 48.63614, lng: 18.4204254 },
    { lat: 48.6360484, lng: 18.4201406 },
    { lat: 48.6360339, lng: 18.4199819 },
    { lat: 48.636001, lng: 18.4193308 },
    { lat: 48.6359919, lng: 18.4182926 },
    { lat: 48.6359181, lng: 18.4173547 },
    { lat: 48.6358903, lng: 18.4165321 },
    { lat: 48.6358872, lng: 18.4162514 },
    { lat: 48.6358547, lng: 18.41561 },
    { lat: 48.6357991, lng: 18.4153802 },
    { lat: 48.6353395, lng: 18.4147637 },
    { lat: 48.6349753, lng: 18.4142602 },
    { lat: 48.6347185, lng: 18.4139454 },
    { lat: 48.6345528, lng: 18.4136454 },
    { lat: 48.6343975, lng: 18.4132892 },
    { lat: 48.6342846, lng: 18.4126159 },
  ],
  ŽabokrekynadNitrou: [
    { lat: 48.6327147, lng: 18.2899019 },
    { lat: 48.632652, lng: 18.2898914 },
    { lat: 48.6322721, lng: 18.2897761 },
    { lat: 48.6320899, lng: 18.2896913 },
    { lat: 48.6320027, lng: 18.2896829 },
    { lat: 48.6312257, lng: 18.2896325 },
    { lat: 48.6311616, lng: 18.2896144 },
    { lat: 48.6307251, lng: 18.2895193 },
    { lat: 48.6303253, lng: 18.2894148 },
    { lat: 48.6295521, lng: 18.2892883 },
    { lat: 48.6289538, lng: 18.2892248 },
    { lat: 48.6286006, lng: 18.2891838 },
    { lat: 48.6282673, lng: 18.2891359 },
    { lat: 48.6281881, lng: 18.2891312 },
    { lat: 48.6274417, lng: 18.2890401 },
    { lat: 48.62706, lng: 18.2889968 },
    { lat: 48.6263694, lng: 18.2889762 },
    { lat: 48.6258634, lng: 18.2889475 },
    { lat: 48.6253625, lng: 18.2888483 },
    { lat: 48.6245236, lng: 18.2887784 },
    { lat: 48.6236349, lng: 18.2887871 },
    { lat: 48.6227982, lng: 18.288635 },
    { lat: 48.6224393, lng: 18.2885772 },
    { lat: 48.6220412, lng: 18.2885977 },
    { lat: 48.6217738, lng: 18.2876833 },
    { lat: 48.6205216, lng: 18.2885225 },
    { lat: 48.619591, lng: 18.2891425 },
    { lat: 48.6187355, lng: 18.2896953 },
    { lat: 48.6186183, lng: 18.289238 },
    { lat: 48.6184721, lng: 18.2886549 },
    { lat: 48.6183105, lng: 18.2887867 },
    { lat: 48.6147808, lng: 18.291562 },
    { lat: 48.6145463, lng: 18.2913669 },
    { lat: 48.6132836, lng: 18.2920604 },
    { lat: 48.6124492, lng: 18.2926624 },
    { lat: 48.6118695, lng: 18.2932247 },
    { lat: 48.6113655, lng: 18.2938254 },
    { lat: 48.6103903, lng: 18.2949932 },
    { lat: 48.6103741, lng: 18.2949864 },
    { lat: 48.6103121, lng: 18.2951221 },
    { lat: 48.6106743, lng: 18.295659 },
    { lat: 48.6107581, lng: 18.2958653 },
    { lat: 48.6108265, lng: 18.2963738 },
    { lat: 48.6107445, lng: 18.2965006 },
    { lat: 48.6105964, lng: 18.2967141 },
    { lat: 48.6094212, lng: 18.2985116 },
    { lat: 48.6101957, lng: 18.3004675 },
    { lat: 48.61131, lng: 18.3032221 },
    { lat: 48.6115373, lng: 18.3037686 },
    { lat: 48.6127789, lng: 18.3068606 },
    { lat: 48.6117999, lng: 18.3127174 },
    { lat: 48.6118747, lng: 18.3131242 },
    { lat: 48.6120842, lng: 18.313734 },
    { lat: 48.6121764, lng: 18.3136302 },
    { lat: 48.6125501, lng: 18.3141119 },
    { lat: 48.6128584, lng: 18.3139153 },
    { lat: 48.6135004, lng: 18.3144149 },
    { lat: 48.6153607, lng: 18.3124416 },
    { lat: 48.6153364, lng: 18.3128259 },
    { lat: 48.6153779, lng: 18.3131404 },
    { lat: 48.6154758, lng: 18.3133545 },
    { lat: 48.6154855, lng: 18.3140643 },
    { lat: 48.6154339, lng: 18.3148059 },
    { lat: 48.6152947, lng: 18.3154168 },
    { lat: 48.6149333, lng: 18.3165298 },
    { lat: 48.6154863, lng: 18.3171094 },
    { lat: 48.6160729, lng: 18.3185215 },
    { lat: 48.6214253, lng: 18.3189162 },
    { lat: 48.6219732, lng: 18.3212821 },
    { lat: 48.6215905, lng: 18.3307989 },
    { lat: 48.6223575, lng: 18.3302977 },
    { lat: 48.6237384, lng: 18.3292551 },
    { lat: 48.6239021, lng: 18.3290978 },
    { lat: 48.6240494, lng: 18.32901 },
    { lat: 48.6244365, lng: 18.3286717 },
    { lat: 48.6251492, lng: 18.3281461 },
    { lat: 48.6256647, lng: 18.3277453 },
    { lat: 48.6261854, lng: 18.327296 },
    { lat: 48.6268046, lng: 18.3267517 },
    { lat: 48.6269709, lng: 18.3265994 },
    { lat: 48.6279093, lng: 18.3255161 },
    { lat: 48.6280704, lng: 18.3253534 },
    { lat: 48.6284236, lng: 18.3250576 },
    { lat: 48.6307912, lng: 18.3234051 },
    { lat: 48.6309367, lng: 18.3232066 },
    { lat: 48.6320697, lng: 18.3225501 },
    { lat: 48.632971, lng: 18.3217906 },
    { lat: 48.6334207, lng: 18.3213057 },
    { lat: 48.6343805, lng: 18.3202002 },
    { lat: 48.634419, lng: 18.3201239 },
    { lat: 48.6347857, lng: 18.3194631 },
    { lat: 48.6348832, lng: 18.3192351 },
    { lat: 48.6351437, lng: 18.3187927 },
    { lat: 48.6353366, lng: 18.3185332 },
    { lat: 48.6354471, lng: 18.3184508 },
    { lat: 48.6356717, lng: 18.3182129 },
    { lat: 48.6358034, lng: 18.3180286 },
    { lat: 48.6360533, lng: 18.3175398 },
    { lat: 48.6362906, lng: 18.316987 },
    { lat: 48.6363869, lng: 18.316696 },
    { lat: 48.6364593, lng: 18.3165001 },
    { lat: 48.6366388, lng: 18.3161249 },
    { lat: 48.6368338, lng: 18.3156399 },
    { lat: 48.6369184, lng: 18.3154425 },
    { lat: 48.6370095, lng: 18.3152973 },
    { lat: 48.6371967, lng: 18.3150745 },
    { lat: 48.6374885, lng: 18.3148086 },
    { lat: 48.6401, lng: 18.3115117 },
    { lat: 48.6400453, lng: 18.3110843 },
    { lat: 48.6399222, lng: 18.3107063 },
    { lat: 48.6393283, lng: 18.3102592 },
    { lat: 48.638977, lng: 18.3095549 },
    { lat: 48.6388825, lng: 18.3091216 },
    { lat: 48.6388336, lng: 18.3085763 },
    { lat: 48.6388487, lng: 18.3072912 },
    { lat: 48.6388949, lng: 18.3058946 },
    { lat: 48.6389434, lng: 18.3043825 },
    { lat: 48.639127, lng: 18.3033036 },
    { lat: 48.6392104, lng: 18.3033031 },
    { lat: 48.6393504, lng: 18.3024285 },
    { lat: 48.6394445, lng: 18.3017767 },
    { lat: 48.639539, lng: 18.30098 },
    { lat: 48.6396371, lng: 18.3001001 },
    { lat: 48.639758, lng: 18.2990516 },
    { lat: 48.6398307, lng: 18.298124 },
    { lat: 48.6400062, lng: 18.2959889 },
    { lat: 48.64013, lng: 18.2944339 },
    { lat: 48.6401597, lng: 18.2934247 },
    { lat: 48.6397384, lng: 18.2926258 },
    { lat: 48.6397114, lng: 18.2925662 },
    { lat: 48.639677, lng: 18.2925139 },
    { lat: 48.6382721, lng: 18.292631 },
    { lat: 48.6381603, lng: 18.2926518 },
    { lat: 48.6368384, lng: 18.2930985 },
    { lat: 48.636743, lng: 18.2931244 },
    { lat: 48.6365853, lng: 18.2931077 },
    { lat: 48.6364686, lng: 18.2930603 },
    { lat: 48.6362062, lng: 18.2929106 },
    { lat: 48.6350963, lng: 18.2922596 },
    { lat: 48.6338272, lng: 18.2914927 },
    { lat: 48.6335602, lng: 18.2913 },
    { lat: 48.6334616, lng: 18.2910541 },
    { lat: 48.6334106, lng: 18.2909091 },
    { lat: 48.6333299, lng: 18.2905842 },
    { lat: 48.6332702, lng: 18.2902477 },
    { lat: 48.6331713, lng: 18.2901553 },
    { lat: 48.6328748, lng: 18.2899229 },
    { lat: 48.6327147, lng: 18.2899019 },
  ],
  VeľkýKlíž: [
    { lat: 48.5710986, lng: 18.333392 },
    { lat: 48.5711339, lng: 18.3333978 },
    { lat: 48.5711654, lng: 18.3334148 },
    { lat: 48.5720951, lng: 18.3340519 },
    { lat: 48.5725163, lng: 18.3343972 },
    { lat: 48.5722494, lng: 18.3353057 },
    { lat: 48.5720944, lng: 18.3359177 },
    { lat: 48.571995, lng: 18.3363943 },
    { lat: 48.572119, lng: 18.336476 },
    { lat: 48.5718044, lng: 18.3382571 },
    { lat: 48.5717563, lng: 18.3383637 },
    { lat: 48.571662, lng: 18.3387114 },
    { lat: 48.571295, lng: 18.3383477 },
    { lat: 48.5711651, lng: 18.3383611 },
    { lat: 48.5709016, lng: 18.3395905 },
    { lat: 48.5709916, lng: 18.3398661 },
    { lat: 48.5710553, lng: 18.3402359 },
    { lat: 48.5711915, lng: 18.3403293 },
    { lat: 48.5708724, lng: 18.3408109 },
    { lat: 48.570775, lng: 18.3410471 },
    { lat: 48.5707804, lng: 18.3413588 },
    { lat: 48.5707571, lng: 18.3414708 },
    { lat: 48.5707528, lng: 18.3416538 },
    { lat: 48.5703525, lng: 18.3412972 },
    { lat: 48.5700088, lng: 18.3409052 },
    { lat: 48.5699107, lng: 18.3408055 },
    { lat: 48.5697341, lng: 18.3407341 },
    { lat: 48.5693515, lng: 18.3406538 },
    { lat: 48.5689913, lng: 18.3406106 },
    { lat: 48.5683747, lng: 18.3405015 },
    { lat: 48.5667075, lng: 18.3401691 },
    { lat: 48.5664389, lng: 18.3401465 },
    { lat: 48.5662867, lng: 18.3401279 },
    { lat: 48.5661027, lng: 18.3401469 },
    { lat: 48.5660137, lng: 18.3401668 },
    { lat: 48.5655928, lng: 18.3402275 },
    { lat: 48.5651603, lng: 18.3402238 },
    { lat: 48.5648566, lng: 18.3401783 },
    { lat: 48.5642062, lng: 18.3400287 },
    { lat: 48.5639076, lng: 18.3399167 },
    { lat: 48.5634924, lng: 18.3398149 },
    { lat: 48.5631791, lng: 18.3398024 },
    { lat: 48.5627577, lng: 18.3397342 },
    { lat: 48.5627423, lng: 18.3397579 },
    { lat: 48.5625803, lng: 18.340147 },
    { lat: 48.5619879, lng: 18.3416477 },
    { lat: 48.5616545, lng: 18.3424979 },
    { lat: 48.5609015, lng: 18.3416896 },
    { lat: 48.5606758, lng: 18.3435472 },
    { lat: 48.5602077, lng: 18.3457303 },
    { lat: 48.5601349, lng: 18.346058 },
    { lat: 48.559678, lng: 18.3471199 },
    { lat: 48.5578109, lng: 18.3490015 },
    { lat: 48.5574213, lng: 18.3485748 },
    { lat: 48.5571057, lng: 18.3487438 },
    { lat: 48.5523712, lng: 18.3514183 },
    { lat: 48.5522659, lng: 18.3514332 },
    { lat: 48.5510283, lng: 18.3521139 },
    { lat: 48.5504098, lng: 18.3524704 },
    { lat: 48.5510115, lng: 18.3559609 },
    { lat: 48.5506839, lng: 18.3560712 },
    { lat: 48.550335, lng: 18.3561279 },
    { lat: 48.5502474, lng: 18.3561376 },
    { lat: 48.5502305, lng: 18.3559543 },
    { lat: 48.5501946, lng: 18.3557381 },
    { lat: 48.5501026, lng: 18.3550284 },
    { lat: 48.5500233, lng: 18.3538385 },
    { lat: 48.549997, lng: 18.3534943 },
    { lat: 48.5499195, lng: 18.3530793 },
    { lat: 48.5490244, lng: 18.3535958 },
    { lat: 48.5487803, lng: 18.3537275 },
    { lat: 48.5486295, lng: 18.3537745 },
    { lat: 48.5484916, lng: 18.3538277 },
    { lat: 48.5483266, lng: 18.353924 },
    { lat: 48.5475404, lng: 18.3540864 },
    { lat: 48.5469551, lng: 18.3539704 },
    { lat: 48.5467766, lng: 18.3538864 },
    { lat: 48.5447405, lng: 18.3526964 },
    { lat: 48.5438737, lng: 18.352208 },
    { lat: 48.543607, lng: 18.3519815 },
    { lat: 48.5432041, lng: 18.3516207 },
    { lat: 48.5428053, lng: 18.3512491 },
    { lat: 48.5421393, lng: 18.3504865 },
    { lat: 48.5420066, lng: 18.3503504 },
    { lat: 48.5415843, lng: 18.3502068 },
    { lat: 48.5415065, lng: 18.3502449 },
    { lat: 48.5411306, lng: 18.3505044 },
    { lat: 48.5412889, lng: 18.3513711 },
    { lat: 48.5415518, lng: 18.3517095 },
    { lat: 48.5411125, lng: 18.3521046 },
    { lat: 48.5405357, lng: 18.3526181 },
    { lat: 48.5401266, lng: 18.3529883 },
    { lat: 48.5403401, lng: 18.3537322 },
    { lat: 48.5399848, lng: 18.3537958 },
    { lat: 48.5402904, lng: 18.3539696 },
    { lat: 48.5402827, lng: 18.3540931 },
    { lat: 48.5402974, lng: 18.3543166 },
    { lat: 48.5404343, lng: 18.3544045 },
    { lat: 48.5404069, lng: 18.3546572 },
    { lat: 48.5403214, lng: 18.3548629 },
    { lat: 48.5390048, lng: 18.3539841 },
    { lat: 48.5381345, lng: 18.3533954 },
    { lat: 48.5378122, lng: 18.3540019 },
    { lat: 48.5370625, lng: 18.3553737 },
    { lat: 48.5367558, lng: 18.3559971 },
    { lat: 48.5367372, lng: 18.3560443 },
    { lat: 48.5364834, lng: 18.3557866 },
    { lat: 48.5364363, lng: 18.3556516 },
    { lat: 48.5362641, lng: 18.3554408 },
    { lat: 48.5360814, lng: 18.3552769 },
    { lat: 48.5359991, lng: 18.3550596 },
    { lat: 48.5353806, lng: 18.3545754 },
    { lat: 48.5351795, lng: 18.3544089 },
    { lat: 48.5351186, lng: 18.3544121 },
    { lat: 48.5351204, lng: 18.3555635 },
    { lat: 48.5350403, lng: 18.3559464 },
    { lat: 48.5348584, lng: 18.35654 },
    { lat: 48.5346743, lng: 18.357179 },
    { lat: 48.5344473, lng: 18.3579132 },
    { lat: 48.5341456, lng: 18.3588054 },
    { lat: 48.5339981, lng: 18.3591713 },
    { lat: 48.5338771, lng: 18.3594491 },
    { lat: 48.5338161, lng: 18.3596086 },
    { lat: 48.5336256, lng: 18.3599483 },
    { lat: 48.5332548, lng: 18.3605763 },
    { lat: 48.5328946, lng: 18.3611107 },
    { lat: 48.5327864, lng: 18.3613345 },
    { lat: 48.5323942, lng: 18.362246 },
    { lat: 48.5321959, lng: 18.3627284 },
    { lat: 48.5320203, lng: 18.3632381 },
    { lat: 48.5318571, lng: 18.3635263 },
    { lat: 48.5317118, lng: 18.3638463 },
    { lat: 48.531538, lng: 18.3642074 },
    { lat: 48.5314552, lng: 18.3643408 },
    { lat: 48.5312759, lng: 18.3645238 },
    { lat: 48.5304894, lng: 18.3651634 },
    { lat: 48.5302147, lng: 18.3653729 },
    { lat: 48.5299308, lng: 18.3659406 },
    { lat: 48.5295821, lng: 18.3666204 },
    { lat: 48.5290707, lng: 18.3676584 },
    { lat: 48.5287748, lng: 18.3682525 },
    { lat: 48.5286113, lng: 18.3686151 },
    { lat: 48.5284378, lng: 18.3688589 },
    { lat: 48.5275111, lng: 18.3701955 },
    { lat: 48.5272943, lng: 18.3705157 },
    { lat: 48.5272483, lng: 18.3704575 },
    { lat: 48.5271199, lng: 18.3704204 },
    { lat: 48.526939, lng: 18.3704535 },
    { lat: 48.5266668, lng: 18.3704679 },
    { lat: 48.5264647, lng: 18.3705271 },
    { lat: 48.5262956, lng: 18.3705102 },
    { lat: 48.5260721, lng: 18.3704063 },
    { lat: 48.5259372, lng: 18.3704164 },
    { lat: 48.525767, lng: 18.3705989 },
    { lat: 48.525822, lng: 18.3707136 },
    { lat: 48.5259076, lng: 18.3710711 },
    { lat: 48.5258568, lng: 18.3712737 },
    { lat: 48.5258778, lng: 18.3714461 },
    { lat: 48.5259475, lng: 18.3715415 },
    { lat: 48.5259567, lng: 18.3717646 },
    { lat: 48.5259915, lng: 18.3718623 },
    { lat: 48.5259875, lng: 18.3720134 },
    { lat: 48.5258778, lng: 18.3722694 },
    { lat: 48.5257383, lng: 18.3722142 },
    { lat: 48.5257215, lng: 18.3722185 },
    { lat: 48.5256443, lng: 18.3721496 },
    { lat: 48.5255881, lng: 18.3721337 },
    { lat: 48.5255312, lng: 18.3721438 },
    { lat: 48.5254702, lng: 18.3722608 },
    { lat: 48.525449, lng: 18.3723468 },
    { lat: 48.5253178, lng: 18.3725393 },
    { lat: 48.5249079, lng: 18.3717118 },
    { lat: 48.5250071, lng: 18.3712692 },
    { lat: 48.5244464, lng: 18.3711103 },
    { lat: 48.5242757, lng: 18.3706616 },
    { lat: 48.5244241, lng: 18.3705345 },
    { lat: 48.5247705, lng: 18.370312 },
    { lat: 48.5247701, lng: 18.3702049 },
    { lat: 48.524667, lng: 18.3702156 },
    { lat: 48.5242276, lng: 18.3700466 },
    { lat: 48.5240825, lng: 18.3700342 },
    { lat: 48.5238495, lng: 18.370024 },
    { lat: 48.5238414, lng: 18.3700428 },
    { lat: 48.5237437, lng: 18.3699507 },
    { lat: 48.5234989, lng: 18.3697879 },
    { lat: 48.5233786, lng: 18.3697178 },
    { lat: 48.5231625, lng: 18.3696044 },
    { lat: 48.5229004, lng: 18.3695175 },
    { lat: 48.5227415, lng: 18.3694403 },
    { lat: 48.5225183, lng: 18.3694449 },
    { lat: 48.5223803, lng: 18.3694995 },
    { lat: 48.5222152, lng: 18.3695533 },
    { lat: 48.5220358, lng: 18.3696509 },
    { lat: 48.5218381, lng: 18.3698088 },
    { lat: 48.5217777, lng: 18.3700589 },
    { lat: 48.52137, lng: 18.3701123 },
    { lat: 48.5212058, lng: 18.3701183 },
    { lat: 48.5212372, lng: 18.3702762 },
    { lat: 48.5211837, lng: 18.3704474 },
    { lat: 48.5211665, lng: 18.370545 },
    { lat: 48.521124, lng: 18.3708711 },
    { lat: 48.5210968, lng: 18.3710091 },
    { lat: 48.5210139, lng: 18.371162 },
    { lat: 48.5209842, lng: 18.371554 },
    { lat: 48.5209207, lng: 18.3717693 },
    { lat: 48.5207531, lng: 18.3720597 },
    { lat: 48.520664, lng: 18.3721194 },
    { lat: 48.5206124, lng: 18.3722658 },
    { lat: 48.5205087, lng: 18.3723086 },
    { lat: 48.5204686, lng: 18.3723984 },
    { lat: 48.5204755, lng: 18.3726192 },
    { lat: 48.5203798, lng: 18.3727037 },
    { lat: 48.5203125, lng: 18.3728891 },
    { lat: 48.5202387, lng: 18.3730238 },
    { lat: 48.5201817, lng: 18.3730517 },
    { lat: 48.5200895, lng: 18.3731585 },
    { lat: 48.5199596, lng: 18.3732569 },
    { lat: 48.5198331, lng: 18.3732726 },
    { lat: 48.5197545, lng: 18.3733207 },
    { lat: 48.5196939, lng: 18.3734679 },
    { lat: 48.5196251, lng: 18.3734294 },
    { lat: 48.5193028, lng: 18.3735754 },
    { lat: 48.5192672, lng: 18.373523 },
    { lat: 48.518983, lng: 18.3735051 },
    { lat: 48.5187294, lng: 18.373447 },
    { lat: 48.5186015, lng: 18.3735441 },
    { lat: 48.5185828, lng: 18.3735308 },
    { lat: 48.5181143, lng: 18.3733044 },
    { lat: 48.5180552, lng: 18.3731582 },
    { lat: 48.517988, lng: 18.3731237 },
    { lat: 48.5179146, lng: 18.373185 },
    { lat: 48.5176768, lng: 18.3731099 },
    { lat: 48.5176032, lng: 18.3731634 },
    { lat: 48.5174551, lng: 18.3730186 },
    { lat: 48.517236, lng: 18.3730739 },
    { lat: 48.5170558, lng: 18.3729179 },
    { lat: 48.5170069, lng: 18.3729312 },
    { lat: 48.5167965, lng: 18.3731612 },
    { lat: 48.516575, lng: 18.3732168 },
    { lat: 48.5161888, lng: 18.3733699 },
    { lat: 48.5158908, lng: 18.3735061 },
    { lat: 48.5156377, lng: 18.3735206 },
    { lat: 48.5155327, lng: 18.3735617 },
    { lat: 48.5149294, lng: 18.3736051 },
    { lat: 48.5147534, lng: 18.3736072 },
    { lat: 48.5146372, lng: 18.3735832 },
    { lat: 48.5145347, lng: 18.3734895 },
    { lat: 48.5145183, lng: 18.3734575 },
    { lat: 48.5144904, lng: 18.3734237 },
    { lat: 48.5144844, lng: 18.3731743 },
    { lat: 48.5144227, lng: 18.3731401 },
    { lat: 48.5143471, lng: 18.3735415 },
    { lat: 48.5143154, lng: 18.3735993 },
    { lat: 48.5142588, lng: 18.3736186 },
    { lat: 48.5142216, lng: 18.3735963 },
    { lat: 48.5140171, lng: 18.3735928 },
    { lat: 48.5139269, lng: 18.3736317 },
    { lat: 48.5136084, lng: 18.3737495 },
    { lat: 48.5135888, lng: 18.3737193 },
    { lat: 48.5134033, lng: 18.3736126 },
    { lat: 48.5129793, lng: 18.3734752 },
    { lat: 48.5128768, lng: 18.3734776 },
    { lat: 48.5127761, lng: 18.3733719 },
    { lat: 48.5127612, lng: 18.3733265 },
    { lat: 48.5126292, lng: 18.3730266 },
    { lat: 48.5125624, lng: 18.3729437 },
    { lat: 48.5124794, lng: 18.3725486 },
    { lat: 48.5124472, lng: 18.3723374 },
    { lat: 48.5124496, lng: 18.3720012 },
    { lat: 48.5124058, lng: 18.3717446 },
    { lat: 48.512321, lng: 18.371518 },
    { lat: 48.5122583, lng: 18.3713659 },
    { lat: 48.5121992, lng: 18.3712788 },
    { lat: 48.5121483, lng: 18.371096 },
    { lat: 48.5119537, lng: 18.3707258 },
    { lat: 48.5119013, lng: 18.3704375 },
    { lat: 48.5118606, lng: 18.3701338 },
    { lat: 48.511856, lng: 18.3698718 },
    { lat: 48.5118123, lng: 18.3698347 },
    { lat: 48.5117591, lng: 18.3694949 },
    { lat: 48.511719, lng: 18.3694997 },
    { lat: 48.5113914, lng: 18.3690938 },
    { lat: 48.5111472, lng: 18.3689634 },
    { lat: 48.5106683, lng: 18.3687141 },
    { lat: 48.5106053, lng: 18.3685176 },
    { lat: 48.5104631, lng: 18.3683137 },
    { lat: 48.510313, lng: 18.3682349 },
    { lat: 48.5100857, lng: 18.3682034 },
    { lat: 48.5099172, lng: 18.3679986 },
    { lat: 48.5098828, lng: 18.3678671 },
    { lat: 48.5098346, lng: 18.3676313 },
    { lat: 48.509804, lng: 18.3674295 },
    { lat: 48.5097773, lng: 18.3673568 },
    { lat: 48.509396, lng: 18.3665141 },
    { lat: 48.5092383, lng: 18.3661563 },
    { lat: 48.5091958, lng: 18.3660062 },
    { lat: 48.5090407, lng: 18.3657335 },
    { lat: 48.5088469, lng: 18.3655576 },
    { lat: 48.5087193, lng: 18.3654959 },
    { lat: 48.5086665, lng: 18.3654191 },
    { lat: 48.5085731, lng: 18.3654662 },
    { lat: 48.5085801, lng: 18.3653511 },
    { lat: 48.5083966, lng: 18.3653161 },
    { lat: 48.5080367, lng: 18.3652763 },
    { lat: 48.507933, lng: 18.3653962 },
    { lat: 48.5078923, lng: 18.3652783 },
    { lat: 48.5076291, lng: 18.3652094 },
    { lat: 48.507521, lng: 18.3651115 },
    { lat: 48.5071703, lng: 18.3649374 },
    { lat: 48.5067838, lng: 18.3644057 },
    { lat: 48.5065513, lng: 18.3640002 },
    { lat: 48.5061514, lng: 18.3637506 },
    { lat: 48.5061296, lng: 18.3637245 },
    { lat: 48.5058516, lng: 18.3634978 },
    { lat: 48.5056566, lng: 18.3635205 },
    { lat: 48.5054981, lng: 18.3634101 },
    { lat: 48.5052267, lng: 18.3633209 },
    { lat: 48.5051365, lng: 18.3630005 },
    { lat: 48.5047468, lng: 18.362592 },
    { lat: 48.5046327, lng: 18.3624077 },
    { lat: 48.5043362, lng: 18.3619509 },
    { lat: 48.5040797, lng: 18.3614639 },
    { lat: 48.5037574, lng: 18.3612702 },
    { lat: 48.5035149, lng: 18.3609738 },
    { lat: 48.5028878, lng: 18.3605547 },
    { lat: 48.5029309, lng: 18.3603977 },
    { lat: 48.5026838, lng: 18.360078 },
    { lat: 48.5025555, lng: 18.3598607 },
    { lat: 48.5022589, lng: 18.3595652 },
    { lat: 48.5018467, lng: 18.3593784 },
    { lat: 48.5016461, lng: 18.3592005 },
    { lat: 48.5015111, lng: 18.3589865 },
    { lat: 48.5014074, lng: 18.3587482 },
    { lat: 48.5013534, lng: 18.3583908 },
    { lat: 48.5014451, lng: 18.3578785 },
    { lat: 48.5016999, lng: 18.3572142 },
    { lat: 48.5016409, lng: 18.3571278 },
    { lat: 48.5017406, lng: 18.3569553 },
    { lat: 48.5017791, lng: 18.3568156 },
    { lat: 48.5017758, lng: 18.3566787 },
    { lat: 48.5018256, lng: 18.3565425 },
    { lat: 48.501906, lng: 18.3560651 },
    { lat: 48.5020423, lng: 18.3555806 },
    { lat: 48.5021002, lng: 18.3555091 },
    { lat: 48.5021248, lng: 18.3553672 },
    { lat: 48.5020582, lng: 18.355263 },
    { lat: 48.5019675, lng: 18.3551771 },
    { lat: 48.5019327, lng: 18.3547784 },
    { lat: 48.5018404, lng: 18.3544832 },
    { lat: 48.5013934, lng: 18.3528401 },
    { lat: 48.5012055, lng: 18.3512074 },
    { lat: 48.501088, lng: 18.349817 },
    { lat: 48.500808, lng: 18.350256 },
    { lat: 48.500513, lng: 18.350666 },
    { lat: 48.500358, lng: 18.350835 },
    { lat: 48.50029, lng: 18.350875 },
    { lat: 48.500099, lng: 18.35095 },
    { lat: 48.499693, lng: 18.351088 },
    { lat: 48.499223, lng: 18.351225 },
    { lat: 48.49888, lng: 18.351327 },
    { lat: 48.498555, lng: 18.351364 },
    { lat: 48.498392, lng: 18.351344 },
    { lat: 48.497937, lng: 18.351141 },
    { lat: 48.497845, lng: 18.351098 },
    { lat: 48.497465, lng: 18.35089 },
    { lat: 48.497143, lng: 18.35077 },
    { lat: 48.496961, lng: 18.350704 },
    { lat: 48.496883, lng: 18.350689 },
    { lat: 48.496556, lng: 18.350749 },
    { lat: 48.496346, lng: 18.350823 },
    { lat: 48.496149, lng: 18.350798 },
    { lat: 48.495916, lng: 18.350905 },
    { lat: 48.495685, lng: 18.351105 },
    { lat: 48.495411, lng: 18.351336 },
    { lat: 48.494979, lng: 18.351516 },
    { lat: 48.494751, lng: 18.35171 },
    { lat: 48.49444, lng: 18.351832 },
    { lat: 48.494168, lng: 18.351931 },
    { lat: 48.493625, lng: 18.352409 },
    { lat: 48.493341, lng: 18.352649 },
    { lat: 48.492982, lng: 18.352738 },
    { lat: 48.492835, lng: 18.352773 },
    { lat: 48.492462, lng: 18.353098 },
    { lat: 48.492355, lng: 18.353216 },
    { lat: 48.492173, lng: 18.353432 },
    { lat: 48.491852, lng: 18.353842 },
    { lat: 48.491766, lng: 18.354134 },
    { lat: 48.491689, lng: 18.354392 },
    { lat: 48.491521, lng: 18.354834 },
    { lat: 48.491431, lng: 18.355142 },
    { lat: 48.491404, lng: 18.355262 },
    { lat: 48.491392, lng: 18.355324 },
    { lat: 48.491383, lng: 18.355462 },
    { lat: 48.49142, lng: 18.355699 },
    { lat: 48.491481, lng: 18.355797 },
    { lat: 48.491607, lng: 18.356194 },
    { lat: 48.491639, lng: 18.356478 },
    { lat: 48.491638, lng: 18.356753 },
    { lat: 48.4915979, lng: 18.3570497 },
    { lat: 48.49151, lng: 18.357611 },
    { lat: 48.491443, lng: 18.358083 },
    { lat: 48.491327, lng: 18.35856 },
    { lat: 48.491214, lng: 18.358726 },
    { lat: 48.490964, lng: 18.359039 },
    { lat: 48.490893, lng: 18.359136 },
    { lat: 48.490814, lng: 18.359263 },
    { lat: 48.490714, lng: 18.359483 },
    { lat: 48.490646, lng: 18.359744 },
    { lat: 48.490506, lng: 18.360154 },
    { lat: 48.490432, lng: 18.360381 },
    { lat: 48.490412, lng: 18.360461 },
    { lat: 48.49034, lng: 18.360808 },
    { lat: 48.490276, lng: 18.361203 },
    { lat: 48.490188, lng: 18.361818 },
    { lat: 48.490206, lng: 18.362031 },
    { lat: 48.490322, lng: 18.362463 },
    { lat: 48.490349, lng: 18.362693 },
    { lat: 48.490341, lng: 18.362817 },
    { lat: 48.490307, lng: 18.362928 },
    { lat: 48.490201, lng: 18.363037 },
    { lat: 48.490158, lng: 18.363068 },
    { lat: 48.489926, lng: 18.36319 },
    { lat: 48.489705, lng: 18.363351 },
    { lat: 48.489318, lng: 18.363516 },
    { lat: 48.488904, lng: 18.363687 },
    { lat: 48.488739, lng: 18.363802 },
    { lat: 48.488521, lng: 18.36392 },
    { lat: 48.488222, lng: 18.364005 },
    { lat: 48.487959, lng: 18.364109 },
    { lat: 48.487756, lng: 18.364256 },
    { lat: 48.48767, lng: 18.364291 },
    { lat: 48.487621, lng: 18.364321 },
    { lat: 48.487263, lng: 18.36444 },
    { lat: 48.487109, lng: 18.364659 },
    { lat: 48.486853, lng: 18.364805 },
    { lat: 48.486684, lng: 18.364918 },
    { lat: 48.486431, lng: 18.36513 },
    { lat: 48.486271, lng: 18.365381 },
    { lat: 48.486133, lng: 18.365531 },
    { lat: 48.4859821, lng: 18.3656946 },
    { lat: 48.485824, lng: 18.365866 },
    { lat: 48.485683, lng: 18.366031 },
    { lat: 48.485697, lng: 18.366058 },
    { lat: 48.48632, lng: 18.366362 },
    { lat: 48.486627, lng: 18.366203 },
    { lat: 48.487135, lng: 18.365946 },
    { lat: 48.487304, lng: 18.365827 },
    { lat: 48.488104, lng: 18.365462 },
    { lat: 48.488684, lng: 18.365201 },
    { lat: 48.488762, lng: 18.365304 },
    { lat: 48.488868, lng: 18.365494 },
    { lat: 48.489174, lng: 18.366041 },
    { lat: 48.489584, lng: 18.366199 },
    { lat: 48.489766, lng: 18.366225 },
    { lat: 48.489852, lng: 18.366194 },
    { lat: 48.490327, lng: 18.366023 },
    { lat: 48.490713, lng: 18.365883 },
    { lat: 48.491078, lng: 18.365794 },
    { lat: 48.491305, lng: 18.365763 },
    { lat: 48.491669, lng: 18.365659 },
    { lat: 48.491894, lng: 18.365584 },
    { lat: 48.491997, lng: 18.365671 },
    { lat: 48.492144, lng: 18.365699 },
    { lat: 48.492449, lng: 18.365752 },
    { lat: 48.492669, lng: 18.365793 },
    { lat: 48.493143, lng: 18.365864 },
    { lat: 48.49341, lng: 18.365824 },
    { lat: 48.493635, lng: 18.365806 },
    { lat: 48.493863, lng: 18.365791 },
    { lat: 48.493914, lng: 18.365823 },
    { lat: 48.49402, lng: 18.365987 },
    { lat: 48.494123, lng: 18.366235 },
    { lat: 48.494206, lng: 18.366503 },
    { lat: 48.494246, lng: 18.366674 },
    { lat: 48.494267, lng: 18.366867 },
    { lat: 48.49426, lng: 18.367431 },
    { lat: 48.4942598, lng: 18.3675217 },
    { lat: 48.494542, lng: 18.368425 },
    { lat: 48.494762, lng: 18.369096 },
    { lat: 48.495032, lng: 18.369837 },
    { lat: 48.495087, lng: 18.370136 },
    { lat: 48.495116, lng: 18.371195 },
    { lat: 48.495187, lng: 18.372199 },
    { lat: 48.495233, lng: 18.372859 },
    { lat: 48.495538, lng: 18.373183 },
    { lat: 48.495771, lng: 18.373437 },
    { lat: 48.496466, lng: 18.373933 },
    { lat: 48.496793, lng: 18.374263 },
    { lat: 48.497052, lng: 18.374524 },
    { lat: 48.497415, lng: 18.375482 },
    { lat: 48.497628, lng: 18.376017 },
    { lat: 48.498173, lng: 18.377648 },
    { lat: 48.498394, lng: 18.378109 },
    { lat: 48.498535, lng: 18.378488 },
    { lat: 48.498629, lng: 18.378778 },
    { lat: 48.498639, lng: 18.378824 },
    { lat: 48.49879, lng: 18.379496 },
    { lat: 48.498794, lng: 18.379603 },
    { lat: 48.498783, lng: 18.380188 },
    { lat: 48.49901, lng: 18.381463 },
    { lat: 48.499416, lng: 18.382011 },
    { lat: 48.499699, lng: 18.382567 },
    { lat: 48.499876, lng: 18.382918 },
    { lat: 48.500373, lng: 18.384912 },
    { lat: 48.500505, lng: 18.385745 },
    { lat: 48.500598, lng: 18.386342 },
    { lat: 48.500713, lng: 18.387102 },
    { lat: 48.501003, lng: 18.387378 },
    { lat: 48.501083, lng: 18.387522 },
    { lat: 48.501277, lng: 18.387953 },
    { lat: 48.501554, lng: 18.388297 },
    { lat: 48.501607, lng: 18.388355 },
    { lat: 48.50235, lng: 18.389101 },
    { lat: 48.502403, lng: 18.389154 },
    { lat: 48.502419, lng: 18.38917 },
    { lat: 48.503038, lng: 18.389287 },
    { lat: 48.503385, lng: 18.389379 },
    { lat: 48.50349, lng: 18.389383 },
    { lat: 48.504119, lng: 18.390419 },
    { lat: 48.50445, lng: 18.390658 },
    { lat: 48.504487, lng: 18.390788 },
    { lat: 48.504505, lng: 18.391033 },
    { lat: 48.505232, lng: 18.391914 },
    { lat: 48.505368, lng: 18.392081 },
    { lat: 48.505886, lng: 18.392434 },
    { lat: 48.506592, lng: 18.393136 },
    { lat: 48.507373, lng: 18.394096 },
    { lat: 48.508164, lng: 18.395206 },
    { lat: 48.50835, lng: 18.395485 },
    { lat: 48.509043, lng: 18.396588 },
    { lat: 48.509483, lng: 18.397612 },
    { lat: 48.510528, lng: 18.399668 },
    { lat: 48.511246, lng: 18.400992 },
    { lat: 48.511254, lng: 18.401004 },
    { lat: 48.511335, lng: 18.40111 },
    { lat: 48.511541, lng: 18.401366 },
    { lat: 48.51307, lng: 18.402731 },
    { lat: 48.514043, lng: 18.403912 },
    { lat: 48.514523, lng: 18.404677 },
    { lat: 48.515111, lng: 18.406691 },
    { lat: 48.515507, lng: 18.408994 },
    { lat: 48.515585, lng: 18.409198 },
    { lat: 48.515986, lng: 18.409719 },
    { lat: 48.516439, lng: 18.4104 },
    { lat: 48.51695, lng: 18.411179 },
    { lat: 48.518147, lng: 18.412113 },
    { lat: 48.519169, lng: 18.412629 },
    { lat: 48.519446, lng: 18.413199 },
    { lat: 48.519721, lng: 18.413881 },
    { lat: 48.520289, lng: 18.414372 },
    { lat: 48.520581, lng: 18.414404 },
    { lat: 48.520987, lng: 18.414443 },
    { lat: 48.52123, lng: 18.414885 },
    { lat: 48.52167, lng: 18.415768 },
    { lat: 48.521921, lng: 18.416465 },
    { lat: 48.522429, lng: 18.417763 },
    { lat: 48.522849, lng: 18.419531 },
    { lat: 48.523443, lng: 18.422326 },
    { lat: 48.523558, lng: 18.422908 },
    { lat: 48.523601, lng: 18.423057 },
    { lat: 48.523722, lng: 18.423434 },
    { lat: 48.523813, lng: 18.423692 },
    { lat: 48.523908, lng: 18.423955 },
    { lat: 48.524149, lng: 18.424607 },
    { lat: 48.526074, lng: 18.429755 },
    { lat: 48.526265, lng: 18.430971 },
    { lat: 48.526434, lng: 18.432097 },
    { lat: 48.527424, lng: 18.434876 },
    { lat: 48.52791, lng: 18.437264 },
    { lat: 48.528368, lng: 18.439887 },
    { lat: 48.528398, lng: 18.440094 },
    { lat: 48.528419, lng: 18.440232 },
    { lat: 48.528577, lng: 18.441299 },
    { lat: 48.528623, lng: 18.44139 },
    { lat: 48.52902, lng: 18.442258 },
    { lat: 48.529243, lng: 18.442758 },
    { lat: 48.529351, lng: 18.442986 },
    { lat: 48.529763, lng: 18.443398 },
    { lat: 48.530098, lng: 18.443664 },
    { lat: 48.530374, lng: 18.444063 },
    { lat: 48.530526, lng: 18.444221 },
    { lat: 48.530607, lng: 18.444438 },
    { lat: 48.530702, lng: 18.4449 },
    { lat: 48.530809, lng: 18.445211 },
    { lat: 48.530868, lng: 18.445611 },
    { lat: 48.531037, lng: 18.446109 },
    { lat: 48.531068, lng: 18.446188 },
    { lat: 48.5312, lng: 18.446502 },
    { lat: 48.531456, lng: 18.446872 },
    { lat: 48.531906, lng: 18.447716 },
    { lat: 48.532179, lng: 18.44808 },
    { lat: 48.532267, lng: 18.448159 },
    { lat: 48.532638, lng: 18.44851 },
    { lat: 48.533075, lng: 18.448917 },
    { lat: 48.533441, lng: 18.449298 },
    { lat: 48.534128, lng: 18.449654 },
    { lat: 48.534446, lng: 18.450026 },
    { lat: 48.535617, lng: 18.450664 },
    { lat: 48.536099, lng: 18.451727 },
    { lat: 48.536578, lng: 18.453321 },
    { lat: 48.536744, lng: 18.453873 },
    { lat: 48.537378, lng: 18.455205 },
    { lat: 48.538311, lng: 18.457125 },
    { lat: 48.538495, lng: 18.458471 },
    { lat: 48.538733, lng: 18.460165 },
    { lat: 48.538893, lng: 18.460733 },
    { lat: 48.53912, lng: 18.461506 },
    { lat: 48.539124, lng: 18.461712 },
    { lat: 48.539638, lng: 18.462813 },
    { lat: 48.539865, lng: 18.463252 },
    { lat: 48.540205, lng: 18.463572 },
    { lat: 48.540465, lng: 18.463691 },
    { lat: 48.540481, lng: 18.463701 },
    { lat: 48.540528, lng: 18.463725 },
    { lat: 48.540943, lng: 18.463832 },
    { lat: 48.541694, lng: 18.464207 },
    { lat: 48.541826, lng: 18.464341 },
    { lat: 48.541864, lng: 18.464378 },
    { lat: 48.543679, lng: 18.466198 },
    { lat: 48.544535, lng: 18.467005 },
    { lat: 48.545477, lng: 18.46777 },
    { lat: 48.546147, lng: 18.468016 },
    { lat: 48.546456, lng: 18.468042 },
    { lat: 48.546598, lng: 18.46809 },
    { lat: 48.5466967, lng: 18.4674844 },
    { lat: 48.546696, lng: 18.4671469 },
    { lat: 48.5468737, lng: 18.4663609 },
    { lat: 48.5471255, lng: 18.4660206 },
    { lat: 48.5475971, lng: 18.4654907 },
    { lat: 48.5479191, lng: 18.4649497 },
    { lat: 48.5481483, lng: 18.4644445 },
    { lat: 48.5483392, lng: 18.4639594 },
    { lat: 48.54851, lng: 18.4632576 },
    { lat: 48.5484122, lng: 18.4619187 },
    { lat: 48.5483851, lng: 18.4614507 },
    { lat: 48.548407, lng: 18.461172 },
    { lat: 48.5485071, lng: 18.4602109 },
    { lat: 48.548571, lng: 18.459756 },
    { lat: 48.5486023, lng: 18.4595869 },
    { lat: 48.548726, lng: 18.4590501 },
    { lat: 48.5487878, lng: 18.4585795 },
    { lat: 48.5488071, lng: 18.4583178 },
    { lat: 48.5485433, lng: 18.457546 },
    { lat: 48.5484919, lng: 18.4569639 },
    { lat: 48.5485665, lng: 18.4562766 },
    { lat: 48.5488065, lng: 18.4551356 },
    { lat: 48.549098, lng: 18.4544705 },
    { lat: 48.5493544, lng: 18.4537033 },
    { lat: 48.5494719, lng: 18.4533994 },
    { lat: 48.5497935, lng: 18.4527127 },
    { lat: 48.5499763, lng: 18.452104 },
    { lat: 48.5501059, lng: 18.4517138 },
    { lat: 48.5508567, lng: 18.450628 },
    { lat: 48.5511464, lng: 18.4502372 },
    { lat: 48.551364, lng: 18.4497882 },
    { lat: 48.5515046, lng: 18.4493735 },
    { lat: 48.5517454, lng: 18.4488749 },
    { lat: 48.5520383, lng: 18.4484225 },
    { lat: 48.5526091, lng: 18.4478727 },
    { lat: 48.5527349, lng: 18.4472729 },
    { lat: 48.5527681, lng: 18.4470198 },
    { lat: 48.5528374, lng: 18.4464581 },
    { lat: 48.5530619, lng: 18.4458259 },
    { lat: 48.5531693, lng: 18.4455677 },
    { lat: 48.5532934, lng: 18.4452626 },
    { lat: 48.5534957, lng: 18.444844 },
    { lat: 48.5538093, lng: 18.4444972 },
    { lat: 48.5542349, lng: 18.4441156 },
    { lat: 48.5543095, lng: 18.4439192 },
    { lat: 48.5545734, lng: 18.4434091 },
    { lat: 48.5546293, lng: 18.4432974 },
    { lat: 48.554717, lng: 18.4429894 },
    { lat: 48.5548049, lng: 18.4427856 },
    { lat: 48.5549041, lng: 18.4425196 },
    { lat: 48.555274, lng: 18.4420079 },
    { lat: 48.5560149, lng: 18.4418596 },
    { lat: 48.556075, lng: 18.4417459 },
    { lat: 48.5562438, lng: 18.4413336 },
    { lat: 48.5565244, lng: 18.4410831 },
    { lat: 48.5566468, lng: 18.4408896 },
    { lat: 48.5567213, lng: 18.4406277 },
    { lat: 48.5567683, lng: 18.4397885 },
    { lat: 48.5567786, lng: 18.4397455 },
    { lat: 48.5568666, lng: 18.4393787 },
    { lat: 48.5569579, lng: 18.4389983 },
    { lat: 48.5570031, lng: 18.4381482 },
    { lat: 48.5569134, lng: 18.4379197 },
    { lat: 48.5568232, lng: 18.4376898 },
    { lat: 48.5566211, lng: 18.4373635 },
    { lat: 48.556479, lng: 18.4371026 },
    { lat: 48.5562865, lng: 18.4365414 },
    { lat: 48.5561665, lng: 18.4363896 },
    { lat: 48.5557327, lng: 18.4351837 },
    { lat: 48.55552, lng: 18.4342412 },
    { lat: 48.5551611, lng: 18.433751 },
    { lat: 48.5550947, lng: 18.4335314 },
    { lat: 48.5550427, lng: 18.4329202 },
    { lat: 48.5549504, lng: 18.4322349 },
    { lat: 48.554775, lng: 18.4316193 },
    { lat: 48.5545158, lng: 18.431401 },
    { lat: 48.5542395, lng: 18.4311589 },
    { lat: 48.5541659, lng: 18.4309916 },
    { lat: 48.553918, lng: 18.4303694 },
    { lat: 48.5537904, lng: 18.4299629 },
    { lat: 48.5535149, lng: 18.42933 },
    { lat: 48.5534934, lng: 18.4290233 },
    { lat: 48.5539284, lng: 18.4281878 },
    { lat: 48.5539608, lng: 18.4280191 },
    { lat: 48.5539435, lng: 18.4277076 },
    { lat: 48.5537373, lng: 18.427024 },
    { lat: 48.5536729, lng: 18.4267212 },
    { lat: 48.55368, lng: 18.4256951 },
    { lat: 48.5537458, lng: 18.4252919 },
    { lat: 48.5542429, lng: 18.4235661 },
    { lat: 48.554357, lng: 18.4233056 },
    { lat: 48.5547464, lng: 18.4228009 },
    { lat: 48.5549463, lng: 18.4226471 },
    { lat: 48.5553504, lng: 18.422326 },
    { lat: 48.5559212, lng: 18.422002 },
    { lat: 48.5561725, lng: 18.4216497 },
    { lat: 48.5564881, lng: 18.4212644 },
    { lat: 48.5566249, lng: 18.4209661 },
    { lat: 48.556861, lng: 18.4200413 },
    { lat: 48.5568055, lng: 18.4183974 },
    { lat: 48.5568523, lng: 18.4177401 },
    { lat: 48.5566962, lng: 18.4165191 },
    { lat: 48.5566362, lng: 18.4158091 },
    { lat: 48.5564953, lng: 18.4154064 },
    { lat: 48.556416, lng: 18.4151159 },
    { lat: 48.5563591, lng: 18.4147834 },
    { lat: 48.5562955, lng: 18.4139919 },
    { lat: 48.5562468, lng: 18.4129967 },
    { lat: 48.5562135, lng: 18.4124425 },
    { lat: 48.5560023, lng: 18.411141 },
    { lat: 48.5559599, lng: 18.410928 },
    { lat: 48.5558647, lng: 18.410439 },
    { lat: 48.555847, lng: 18.4104441 },
    { lat: 48.555774, lng: 18.4099823 },
    { lat: 48.5558263, lng: 18.4095857 },
    { lat: 48.5561294, lng: 18.4081691 },
    { lat: 48.5562505, lng: 18.4076299 },
    { lat: 48.5564447, lng: 18.4067053 },
    { lat: 48.5565669, lng: 18.4060862 },
    { lat: 48.5565313, lng: 18.405428 },
    { lat: 48.5565121, lng: 18.4044179 },
    { lat: 48.5565426, lng: 18.4042196 },
    { lat: 48.5565821, lng: 18.4040754 },
    { lat: 48.5566101, lng: 18.4039126 },
    { lat: 48.5566374, lng: 18.4036941 },
    { lat: 48.5566759, lng: 18.4023242 },
    { lat: 48.5569284, lng: 18.4011717 },
    { lat: 48.5571554, lng: 18.4009528 },
    { lat: 48.5573009, lng: 18.4004083 },
    { lat: 48.55734, lng: 18.4000985 },
    { lat: 48.5574657, lng: 18.3997673 },
    { lat: 48.5575653, lng: 18.3996293 },
    { lat: 48.5581363, lng: 18.3992705 },
    { lat: 48.5586134, lng: 18.398903 },
    { lat: 48.5587574, lng: 18.3987351 },
    { lat: 48.5590013, lng: 18.3982572 },
    { lat: 48.5593008, lng: 18.3977836 },
    { lat: 48.5594641, lng: 18.3975744 },
    { lat: 48.5596908, lng: 18.3971307 },
    { lat: 48.5599087, lng: 18.3967711 },
    { lat: 48.5601362, lng: 18.3963586 },
    { lat: 48.5606263, lng: 18.3954006 },
    { lat: 48.561164, lng: 18.3943839 },
    { lat: 48.5621188, lng: 18.3934669 },
    { lat: 48.563095, lng: 18.3924851 },
    { lat: 48.5635991, lng: 18.3919954 },
    { lat: 48.5640222, lng: 18.3915943 },
    { lat: 48.5646429, lng: 18.3910129 },
    { lat: 48.5650302, lng: 18.3906178 },
    { lat: 48.5656098, lng: 18.3901092 },
    { lat: 48.5660467, lng: 18.3897091 },
    { lat: 48.5668009, lng: 18.3890137 },
    { lat: 48.5672756, lng: 18.3885658 },
    { lat: 48.5677952, lng: 18.3880552 },
    { lat: 48.5682121, lng: 18.3876434 },
    { lat: 48.5684947, lng: 18.3873706 },
    { lat: 48.5687016, lng: 18.3871772 },
    { lat: 48.5691129, lng: 18.3867462 },
    { lat: 48.5693062, lng: 18.3865503 },
    { lat: 48.5696069, lng: 18.3862409 },
    { lat: 48.5700492, lng: 18.3858362 },
    { lat: 48.5701778, lng: 18.3857551 },
    { lat: 48.5708559, lng: 18.3853139 },
    { lat: 48.5714399, lng: 18.3849065 },
    { lat: 48.5720482, lng: 18.3844038 },
    { lat: 48.5722984, lng: 18.3842194 },
    { lat: 48.5727683, lng: 18.3838682 },
    { lat: 48.5732309, lng: 18.3835674 },
    { lat: 48.5737925, lng: 18.3832401 },
    { lat: 48.5741074, lng: 18.3829795 },
    { lat: 48.5745642, lng: 18.3825754 },
    { lat: 48.5748499, lng: 18.3823196 },
    { lat: 48.5751267, lng: 18.3820663 },
    { lat: 48.5756635, lng: 18.381698 },
    { lat: 48.5762614, lng: 18.3813136 },
    { lat: 48.5766769, lng: 18.381038 },
    { lat: 48.5771405, lng: 18.3807175 },
    { lat: 48.577492, lng: 18.3804461 },
    { lat: 48.5777656, lng: 18.3802527 },
    { lat: 48.5779838, lng: 18.380102 },
    { lat: 48.5780205, lng: 18.3793281 },
    { lat: 48.5781269, lng: 18.3785482 },
    { lat: 48.5781622, lng: 18.3778336 },
    { lat: 48.5781535, lng: 18.3770544 },
    { lat: 48.5782562, lng: 18.3764342 },
    { lat: 48.5783699, lng: 18.3761044 },
    { lat: 48.5785299, lng: 18.3756614 },
    { lat: 48.5786034, lng: 18.3749435 },
    { lat: 48.5786107, lng: 18.3748263 },
    { lat: 48.5787121, lng: 18.3744439 },
    { lat: 48.5787851, lng: 18.3743103 },
    { lat: 48.5789473, lng: 18.3740134 },
    { lat: 48.579075, lng: 18.3739117 },
    { lat: 48.579253, lng: 18.3737699 },
    { lat: 48.5795942, lng: 18.3733802 },
    { lat: 48.5796221, lng: 18.3733484 },
    { lat: 48.5797496, lng: 18.3730471 },
    { lat: 48.5801658, lng: 18.3727678 },
    { lat: 48.5803088, lng: 18.3725998 },
    { lat: 48.5805364, lng: 18.3723325 },
    { lat: 48.580596, lng: 18.3721884 },
    { lat: 48.580657, lng: 18.3716878 },
    { lat: 48.5809118, lng: 18.3708832 },
    { lat: 48.5809091, lng: 18.3706929 },
    { lat: 48.5808684, lng: 18.3705804 },
    { lat: 48.5808142, lng: 18.3704307 },
    { lat: 48.5806197, lng: 18.3701264 },
    { lat: 48.5803119, lng: 18.3696446 },
    { lat: 48.5802399, lng: 18.3695706 },
    { lat: 48.5801443, lng: 18.3694725 },
    { lat: 48.5797944, lng: 18.369267 },
    { lat: 48.579717, lng: 18.3691852 },
    { lat: 48.5795149, lng: 18.3689714 },
    { lat: 48.5794782, lng: 18.3688591 },
    { lat: 48.5793867, lng: 18.3685794 },
    { lat: 48.5792797, lng: 18.3684641 },
    { lat: 48.579096, lng: 18.3682663 },
    { lat: 48.5789662, lng: 18.3679846 },
    { lat: 48.5789407, lng: 18.3679537 },
    { lat: 48.5786539, lng: 18.3676055 },
    { lat: 48.5785903, lng: 18.3674644 },
    { lat: 48.5783186, lng: 18.366862 },
    { lat: 48.5781653, lng: 18.366522 },
    { lat: 48.5780602, lng: 18.3661588 },
    { lat: 48.5780983, lng: 18.3657517 },
    { lat: 48.5780581, lng: 18.3651974 },
    { lat: 48.5781936, lng: 18.3644576 },
    { lat: 48.5783038, lng: 18.3641089 },
    { lat: 48.5784586, lng: 18.3638943 },
    { lat: 48.5785663, lng: 18.363459 },
    { lat: 48.5786645, lng: 18.3628409 },
    { lat: 48.578872, lng: 18.3615344 },
    { lat: 48.579252, lng: 18.3597358 },
    { lat: 48.5793701, lng: 18.3591769 },
    { lat: 48.5795359, lng: 18.3581913 },
    { lat: 48.5795501, lng: 18.358107 },
    { lat: 48.579547, lng: 18.3575194 },
    { lat: 48.579544, lng: 18.3569573 },
    { lat: 48.579484, lng: 18.3564134 },
    { lat: 48.5795541, lng: 18.3554819 },
    { lat: 48.5795711, lng: 18.3552553 },
    { lat: 48.5795104, lng: 18.3543934 },
    { lat: 48.5794934, lng: 18.3541528 },
    { lat: 48.5792555, lng: 18.3528557 },
    { lat: 48.57924, lng: 18.3526643 },
    { lat: 48.579145, lng: 18.3514937 },
    { lat: 48.5791268, lng: 18.3513654 },
    { lat: 48.5789968, lng: 18.350447 },
    { lat: 48.5788783, lng: 18.3499469 },
    { lat: 48.5783658, lng: 18.3487358 },
    { lat: 48.5781571, lng: 18.3482424 },
    { lat: 48.5780827, lng: 18.3481182 },
    { lat: 48.5780227, lng: 18.348018 },
    { lat: 48.577963, lng: 18.3478336 },
    { lat: 48.5778984, lng: 18.3476338 },
    { lat: 48.5777579, lng: 18.346584 },
    { lat: 48.5780442, lng: 18.3453591 },
    { lat: 48.5782993, lng: 18.3447606 },
    { lat: 48.5785952, lng: 18.344284 },
    { lat: 48.578902, lng: 18.3430846 },
    { lat: 48.5791413, lng: 18.3426876 },
    { lat: 48.5793006, lng: 18.3425108 },
    { lat: 48.5792873, lng: 18.3424747 },
    { lat: 48.5792412, lng: 18.3420189 },
    { lat: 48.5790827, lng: 18.3410497 },
    { lat: 48.5793039, lng: 18.3396902 },
    { lat: 48.5789037, lng: 18.3380162 },
    { lat: 48.57869, lng: 18.3370709 },
    { lat: 48.5779987, lng: 18.3353167 },
    { lat: 48.5777313, lng: 18.3346178 },
    { lat: 48.5760372, lng: 18.3330907 },
    { lat: 48.5749109, lng: 18.331648 },
    { lat: 48.5743483, lng: 18.3308958 },
    { lat: 48.574274, lng: 18.3307998 },
    { lat: 48.5741748, lng: 18.3310438 },
    { lat: 48.573978, lng: 18.3307947 },
    { lat: 48.5737919, lng: 18.3305564 },
    { lat: 48.5737895, lng: 18.3304929 },
    { lat: 48.5733464, lng: 18.330538 },
    { lat: 48.5732184, lng: 18.3306076 },
    { lat: 48.5730596, lng: 18.3307352 },
    { lat: 48.5725725, lng: 18.3312609 },
    { lat: 48.5720436, lng: 18.3319636 },
    { lat: 48.571872, lng: 18.3322418 },
    { lat: 48.571206, lng: 18.3331741 },
    { lat: 48.5710986, lng: 18.333392 },
  ],
  Ostratice: [
    { lat: 48.648581, lng: 18.324359 },
    { lat: 48.649462, lng: 18.324659 },
    { lat: 48.649779, lng: 18.324918 },
    { lat: 48.649927, lng: 18.324913 },
    { lat: 48.650588, lng: 18.324692 },
    { lat: 48.651485, lng: 18.32424 },
    { lat: 48.652163, lng: 18.323845 },
    { lat: 48.652295, lng: 18.323746 },
    { lat: 48.652566, lng: 18.323541 },
    { lat: 48.652843, lng: 18.323369 },
    { lat: 48.652879, lng: 18.323346 },
    { lat: 48.652914, lng: 18.323325 },
    { lat: 48.652951, lng: 18.323302 },
    { lat: 48.653005, lng: 18.323268 },
    { lat: 48.653196, lng: 18.323168 },
    { lat: 48.653401, lng: 18.322987 },
    { lat: 48.653608, lng: 18.322606 },
    { lat: 48.653946, lng: 18.322233 },
    { lat: 48.65419, lng: 18.322071 },
    { lat: 48.654672, lng: 18.321681 },
    { lat: 48.654681, lng: 18.321669 },
    { lat: 48.65485, lng: 18.32143 },
    { lat: 48.655672, lng: 18.320288 },
    { lat: 48.655909, lng: 18.319959 },
    { lat: 48.6568, lng: 18.318754 },
    { lat: 48.657036, lng: 18.318471 },
    { lat: 48.657347, lng: 18.318087 },
    { lat: 48.65784, lng: 18.317538 },
    { lat: 48.657868, lng: 18.317517 },
    { lat: 48.657944, lng: 18.317462 },
    { lat: 48.657951, lng: 18.317438 },
    { lat: 48.658014, lng: 18.317246 },
    { lat: 48.658115, lng: 18.316216 },
    { lat: 48.658216, lng: 18.315336 },
    { lat: 48.658209, lng: 18.315201 },
    { lat: 48.658102, lng: 18.314865 },
    { lat: 48.65807, lng: 18.314231 },
    { lat: 48.658053, lng: 18.313917 },
    { lat: 48.657957, lng: 18.312996 },
    { lat: 48.657845, lng: 18.312252 },
    { lat: 48.657835, lng: 18.312184 },
    { lat: 48.657797, lng: 18.312138 },
    { lat: 48.657765, lng: 18.31206 },
    { lat: 48.657593, lng: 18.311443 },
    { lat: 48.657537, lng: 18.311162 },
    { lat: 48.657415, lng: 18.309855 },
    { lat: 48.657429, lng: 18.309659 },
    { lat: 48.657432, lng: 18.309332 },
    { lat: 48.657432, lng: 18.309222 },
    { lat: 48.657611, lng: 18.309321 },
    { lat: 48.658075, lng: 18.309659 },
    { lat: 48.658391, lng: 18.30997 },
    { lat: 48.658816, lng: 18.31029 },
    { lat: 48.659278, lng: 18.31065 },
    { lat: 48.65987, lng: 18.311005 },
    { lat: 48.660472, lng: 18.311214 },
    { lat: 48.661247, lng: 18.311455 },
    { lat: 48.661797, lng: 18.311574 },
    { lat: 48.662245, lng: 18.311728 },
    { lat: 48.662955, lng: 18.311867 },
    { lat: 48.663072, lng: 18.311868 },
    { lat: 48.663166, lng: 18.311825 },
    { lat: 48.663933, lng: 18.311631 },
    { lat: 48.664566, lng: 18.311508 },
    { lat: 48.665212, lng: 18.31132 },
    { lat: 48.665351, lng: 18.311286 },
    { lat: 48.665417, lng: 18.311229 },
    { lat: 48.665666, lng: 18.311045 },
    { lat: 48.666212, lng: 18.31052 },
    { lat: 48.6666, lng: 18.310147 },
    { lat: 48.666481, lng: 18.30984 },
    { lat: 48.666053, lng: 18.308792 },
    { lat: 48.665998, lng: 18.308658 },
    { lat: 48.665947, lng: 18.308695 },
    { lat: 48.665743, lng: 18.308833 },
    { lat: 48.665194, lng: 18.309192 },
    { lat: 48.665049, lng: 18.309287 },
    { lat: 48.664891, lng: 18.309391 },
    { lat: 48.664706, lng: 18.308943 },
    { lat: 48.664593, lng: 18.308676 },
    { lat: 48.664579, lng: 18.308643 },
    { lat: 48.664558, lng: 18.308593 },
    { lat: 48.664471, lng: 18.308295 },
    { lat: 48.664451, lng: 18.30822 },
    { lat: 48.665486, lng: 18.307514 },
    { lat: 48.665515, lng: 18.307416 },
    { lat: 48.665227, lng: 18.306514 },
    { lat: 48.665206, lng: 18.306314 },
    { lat: 48.665261, lng: 18.306354 },
    { lat: 48.665261, lng: 18.306326 },
    { lat: 48.665262, lng: 18.306283 },
    { lat: 48.665237, lng: 18.306252 },
    { lat: 48.665154, lng: 18.306106 },
    { lat: 48.665143, lng: 18.306071 },
    { lat: 48.664836, lng: 18.305094 },
    { lat: 48.663951, lng: 18.302276 },
    { lat: 48.663566, lng: 18.30104 },
    { lat: 48.663492, lng: 18.300792 },
    { lat: 48.663231, lng: 18.3 },
    { lat: 48.66301, lng: 18.299289 },
    { lat: 48.662422, lng: 18.297433 },
    { lat: 48.66204, lng: 18.296233 },
    { lat: 48.662014, lng: 18.29614 },
    { lat: 48.662026, lng: 18.296103 },
    { lat: 48.662038, lng: 18.296064 },
    { lat: 48.66204, lng: 18.296059 },
    { lat: 48.662468, lng: 18.294827 },
    { lat: 48.662723, lng: 18.294093 },
    { lat: 48.663153, lng: 18.292858 },
    { lat: 48.663253, lng: 18.292566 },
    { lat: 48.663829, lng: 18.291087 },
    { lat: 48.664004, lng: 18.290626 },
    { lat: 48.664201, lng: 18.290065 },
    { lat: 48.664336, lng: 18.289418 },
    { lat: 48.664449, lng: 18.288943 },
    { lat: 48.664663, lng: 18.288312 },
    { lat: 48.664845, lng: 18.287988 },
    { lat: 48.665402, lng: 18.286648 },
    { lat: 48.665786, lng: 18.285767 },
    { lat: 48.666048, lng: 18.285139 },
    { lat: 48.666467, lng: 18.284223 },
    { lat: 48.66662, lng: 18.283799 },
    { lat: 48.666665, lng: 18.283656 },
    { lat: 48.666741, lng: 18.283099 },
    { lat: 48.666769, lng: 18.282699 },
    { lat: 48.66682, lng: 18.282257 },
    { lat: 48.666852, lng: 18.281889 },
    { lat: 48.666877, lng: 18.281637 },
    { lat: 48.666829, lng: 18.281632 },
    { lat: 48.666818, lng: 18.281599 },
    { lat: 48.666809, lng: 18.281572 },
    { lat: 48.666819, lng: 18.281494 },
    { lat: 48.666822, lng: 18.281476 },
    { lat: 48.666838, lng: 18.281343 },
    { lat: 48.666813, lng: 18.281254 },
    { lat: 48.666674, lng: 18.281058 },
    { lat: 48.666542, lng: 18.280868 },
    { lat: 48.666189, lng: 18.280409 },
    { lat: 48.665989, lng: 18.280108 },
    { lat: 48.665987, lng: 18.280104 },
    { lat: 48.665742, lng: 18.279796 },
    { lat: 48.665639, lng: 18.279641 },
    { lat: 48.665567, lng: 18.279489 },
    { lat: 48.665461, lng: 18.279332 },
    { lat: 48.665309, lng: 18.279148 },
    { lat: 48.665164, lng: 18.278913 },
    { lat: 48.664978, lng: 18.278685 },
    { lat: 48.664958, lng: 18.278667 },
    { lat: 48.664936, lng: 18.278646 },
    { lat: 48.664727, lng: 18.278452 },
    { lat: 48.664708, lng: 18.278434 },
    { lat: 48.664657, lng: 18.278388 },
    { lat: 48.664591, lng: 18.278326 },
    { lat: 48.664309, lng: 18.278025 },
    { lat: 48.664145, lng: 18.277889 },
    { lat: 48.663981, lng: 18.27777 },
    { lat: 48.663829, lng: 18.277702 },
    { lat: 48.663664, lng: 18.27759 },
    { lat: 48.66337, lng: 18.277461 },
    { lat: 48.663324, lng: 18.277429 },
    { lat: 48.663165, lng: 18.277308 },
    { lat: 48.663122, lng: 18.277293 },
    { lat: 48.662983, lng: 18.277184 },
    { lat: 48.662634, lng: 18.276949 },
    { lat: 48.662336, lng: 18.27684 },
    { lat: 48.662328, lng: 18.27683 },
    { lat: 48.662211, lng: 18.276786 },
    { lat: 48.661896, lng: 18.276584 },
    { lat: 48.661691, lng: 18.276496 },
    { lat: 48.661502, lng: 18.276371 },
    { lat: 48.661278, lng: 18.276239 },
    { lat: 48.661102, lng: 18.276192 },
    { lat: 48.661014, lng: 18.27614 },
    { lat: 48.660864, lng: 18.2761 },
    { lat: 48.660755, lng: 18.276042 },
    { lat: 48.660658, lng: 18.275953 },
    { lat: 48.660601, lng: 18.275899 },
    { lat: 48.660271, lng: 18.275754 },
    { lat: 48.660153, lng: 18.275722 },
    { lat: 48.659981, lng: 18.275674 },
    { lat: 48.659974, lng: 18.275663 },
    { lat: 48.659887, lng: 18.275648 },
    { lat: 48.659798, lng: 18.275591 },
    { lat: 48.65967, lng: 18.275534 },
    { lat: 48.659561, lng: 18.27549 },
    { lat: 48.659276, lng: 18.275328 },
    { lat: 48.658951, lng: 18.275177 },
    { lat: 48.658947, lng: 18.27518 },
    { lat: 48.6588, lng: 18.275126 },
    { lat: 48.658492, lng: 18.275033 },
    { lat: 48.658216, lng: 18.274896 },
    { lat: 48.658027, lng: 18.274821 },
    { lat: 48.657747, lng: 18.274717 },
    { lat: 48.657743, lng: 18.274669 },
    { lat: 48.657725, lng: 18.274569 },
    { lat: 48.657723, lng: 18.274555 },
    { lat: 48.657655, lng: 18.274179 },
    { lat: 48.657134, lng: 18.274274 },
    { lat: 48.657077, lng: 18.274172 },
    { lat: 48.657053, lng: 18.274121 },
    { lat: 48.657026, lng: 18.27407 },
    { lat: 48.656874, lng: 18.273789 },
    { lat: 48.656808, lng: 18.273668 },
    { lat: 48.65673, lng: 18.273524 },
    { lat: 48.656705, lng: 18.273468 },
    { lat: 48.656657, lng: 18.27337 },
    { lat: 48.656602, lng: 18.273228 },
    { lat: 48.656538, lng: 18.273064 },
    { lat: 48.656459, lng: 18.27286 },
    { lat: 48.656293, lng: 18.272913 },
    { lat: 48.656086, lng: 18.272964 },
    { lat: 48.655834, lng: 18.273036 },
    { lat: 48.655572, lng: 18.27311 },
    { lat: 48.655446, lng: 18.273145 },
    { lat: 48.655436, lng: 18.273099 },
    { lat: 48.655087, lng: 18.273164 },
    { lat: 48.65472, lng: 18.273066 },
    { lat: 48.654393, lng: 18.27271 },
    { lat: 48.654116, lng: 18.27229 },
    { lat: 48.653794, lng: 18.271625 },
    { lat: 48.653602, lng: 18.271073 },
    { lat: 48.653507, lng: 18.270982 },
    { lat: 48.653496, lng: 18.270973 },
    { lat: 48.653472, lng: 18.270871 },
    { lat: 48.653384, lng: 18.270562 },
    { lat: 48.653374, lng: 18.270528 },
    { lat: 48.653274, lng: 18.270187 },
    { lat: 48.65322, lng: 18.269998 },
    { lat: 48.652951, lng: 18.269105 },
    { lat: 48.652835, lng: 18.268704 },
    { lat: 48.652802, lng: 18.268588 },
    { lat: 48.652791, lng: 18.268549 },
    { lat: 48.65278, lng: 18.268503 },
    { lat: 48.652649, lng: 18.268006 },
    { lat: 48.652549, lng: 18.267523 },
    { lat: 48.652367, lng: 18.266799 },
    { lat: 48.65233, lng: 18.266645 },
    { lat: 48.652203, lng: 18.26612 },
    { lat: 48.651984, lng: 18.264956 },
    { lat: 48.651872, lng: 18.264891 },
    { lat: 48.65177, lng: 18.264879 },
    { lat: 48.651635, lng: 18.264836 },
    { lat: 48.651505, lng: 18.264804 },
    { lat: 48.65142, lng: 18.264775 },
    { lat: 48.651258, lng: 18.26478 },
    { lat: 48.651158, lng: 18.264946 },
    { lat: 48.651116, lng: 18.265065 },
    { lat: 48.650997, lng: 18.265137 },
    { lat: 48.650897, lng: 18.265146 },
    { lat: 48.650758, lng: 18.265175 },
    { lat: 48.650687, lng: 18.265338 },
    { lat: 48.650672, lng: 18.265374 },
    { lat: 48.650607, lng: 18.265455 },
    { lat: 48.650595, lng: 18.265467 },
    { lat: 48.650586, lng: 18.265445 },
    { lat: 48.650485, lng: 18.265303 },
    { lat: 48.650406, lng: 18.265215 },
    { lat: 48.650244, lng: 18.26484 },
    { lat: 48.650132, lng: 18.264696 },
    { lat: 48.650051, lng: 18.264506 },
    { lat: 48.649979, lng: 18.264414 },
    { lat: 48.649802, lng: 18.264268 },
    { lat: 48.64975, lng: 18.264184 },
    { lat: 48.649715, lng: 18.264094 },
    { lat: 48.649692, lng: 18.264008 },
    { lat: 48.64969, lng: 18.263903 },
    { lat: 48.649719, lng: 18.263793 },
    { lat: 48.649737, lng: 18.263685 },
    { lat: 48.64977, lng: 18.263636 },
    { lat: 48.649852, lng: 18.263536 },
    { lat: 48.649952, lng: 18.263449 },
    { lat: 48.650145, lng: 18.263407 },
    { lat: 48.650166, lng: 18.263394 },
    { lat: 48.650215, lng: 18.263363 },
    { lat: 48.650256, lng: 18.263284 },
    { lat: 48.650264, lng: 18.263272 },
    { lat: 48.650287, lng: 18.263165 },
    { lat: 48.65026, lng: 18.263022 },
    { lat: 48.650144, lng: 18.262797 },
    { lat: 48.650117, lng: 18.262744 },
    { lat: 48.650122, lng: 18.262699 },
    { lat: 48.650155, lng: 18.26243 },
    { lat: 48.650151, lng: 18.262292 },
    { lat: 48.650108, lng: 18.262033 },
    { lat: 48.650054, lng: 18.261728 },
    { lat: 48.650041, lng: 18.261539 },
    { lat: 48.650072, lng: 18.261294 },
    { lat: 48.650042, lng: 18.26105 },
    { lat: 48.650026, lng: 18.260902 },
    { lat: 48.649967, lng: 18.260618 },
    { lat: 48.649879, lng: 18.260395 },
    { lat: 48.649773, lng: 18.260141 },
    { lat: 48.649733, lng: 18.259826 },
    { lat: 48.649604, lng: 18.259333 },
    { lat: 48.649547, lng: 18.259005 },
    { lat: 48.649384, lng: 18.258495 },
    { lat: 48.649329, lng: 18.258378 },
    { lat: 48.649184, lng: 18.258187 },
    { lat: 48.649124, lng: 18.258012 },
    { lat: 48.649006, lng: 18.257624 },
    { lat: 48.648998, lng: 18.257611 },
    { lat: 48.6481188, lng: 18.2579114 },
    { lat: 48.6482366, lng: 18.2581559 },
    { lat: 48.6482548, lng: 18.2582759 },
    { lat: 48.6482552, lng: 18.2584454 },
    { lat: 48.6482442, lng: 18.2585085 },
    { lat: 48.6481433, lng: 18.258775 },
    { lat: 48.6480461, lng: 18.2589692 },
    { lat: 48.6479688, lng: 18.2592394 },
    { lat: 48.647944, lng: 18.2593561 },
    { lat: 48.648, lng: 18.2597649 },
    { lat: 48.648003, lng: 18.2598965 },
    { lat: 48.6479913, lng: 18.260105 },
    { lat: 48.6479625, lng: 18.2602715 },
    { lat: 48.6478621, lng: 18.2606092 },
    { lat: 48.6477746, lng: 18.2609164 },
    { lat: 48.6477361, lng: 18.2615666 },
    { lat: 48.6477186, lng: 18.2616955 },
    { lat: 48.6476035, lng: 18.2619899 },
    { lat: 48.6473634, lng: 18.2622908 },
    { lat: 48.6472499, lng: 18.2623577 },
    { lat: 48.6471456, lng: 18.2623691 },
    { lat: 48.646925, lng: 18.2622821 },
    { lat: 48.6468121, lng: 18.2623 },
    { lat: 48.646734, lng: 18.2622955 },
    { lat: 48.6466479, lng: 18.2622703 },
    { lat: 48.6463824, lng: 18.2621053 },
    { lat: 48.6463104, lng: 18.2620276 },
    { lat: 48.6461525, lng: 18.2618123 },
    { lat: 48.6460877, lng: 18.2618054 },
    { lat: 48.6460164, lng: 18.2618189 },
    { lat: 48.6459333, lng: 18.2619082 },
    { lat: 48.6458829, lng: 18.261995 },
    { lat: 48.6458346, lng: 18.2621344 },
    { lat: 48.6458446, lng: 18.2624303 },
    { lat: 48.6458766, lng: 18.2626335 },
    { lat: 48.6459112, lng: 18.2627478 },
    { lat: 48.6460514, lng: 18.2630457 },
    { lat: 48.6463277, lng: 18.2634787 },
    { lat: 48.6463488, lng: 18.2636137 },
    { lat: 48.6463471, lng: 18.2638194 },
    { lat: 48.6463392, lng: 18.2638804 },
    { lat: 48.6462244, lng: 18.2642218 },
    { lat: 48.6461832, lng: 18.26427 },
    { lat: 48.6461033, lng: 18.2642754 },
    { lat: 48.6452199, lng: 18.2644736 },
    { lat: 48.6450822, lng: 18.2638007 },
    { lat: 48.6445576, lng: 18.2640993 },
    { lat: 48.6443267, lng: 18.2642267 },
    { lat: 48.6441486, lng: 18.264307 },
    { lat: 48.6433083, lng: 18.2646473 },
    { lat: 48.6429385, lng: 18.2630109 },
    { lat: 48.6428165, lng: 18.2631887 },
    { lat: 48.6427188, lng: 18.2634126 },
    { lat: 48.6426241, lng: 18.2635716 },
    { lat: 48.6424945, lng: 18.2636954 },
    { lat: 48.642171, lng: 18.2639177 },
    { lat: 48.6420654, lng: 18.2640241 },
    { lat: 48.6418587, lng: 18.2642583 },
    { lat: 48.6417861, lng: 18.2643298 },
    { lat: 48.6413655, lng: 18.2645913 },
    { lat: 48.6407539, lng: 18.2651071 },
    { lat: 48.6400425, lng: 18.2658702 },
    { lat: 48.6398737, lng: 18.266073 },
    { lat: 48.6396477, lng: 18.2662854 },
    { lat: 48.6394432, lng: 18.2663401 },
    { lat: 48.6383885, lng: 18.2665234 },
    { lat: 48.6380239, lng: 18.2663417 },
    { lat: 48.6379412, lng: 18.2662754 },
    { lat: 48.6377642, lng: 18.2660822 },
    { lat: 48.6372692, lng: 18.266561 },
    { lat: 48.6367699, lng: 18.2669115 },
    { lat: 48.6362387, lng: 18.267389 },
    { lat: 48.6359566, lng: 18.2675668 },
    { lat: 48.6353095, lng: 18.2679544 },
    { lat: 48.6349823, lng: 18.2681324 },
    { lat: 48.6347902, lng: 18.2682052 },
    { lat: 48.6339568, lng: 18.2679584 },
    { lat: 48.632965, lng: 18.2676597 },
    { lat: 48.6329165, lng: 18.2676396 },
    { lat: 48.6326346, lng: 18.2669507 },
    { lat: 48.6324414, lng: 18.2663939 },
    { lat: 48.632068, lng: 18.2660365 },
    { lat: 48.6318595, lng: 18.2657264 },
    { lat: 48.6316549, lng: 18.2655208 },
    { lat: 48.6315808, lng: 18.2654073 },
    { lat: 48.631399, lng: 18.2650682 },
    { lat: 48.6312265, lng: 18.2646568 },
    { lat: 48.6299136, lng: 18.2666842 },
    { lat: 48.6291667, lng: 18.2678712 },
    { lat: 48.6285771, lng: 18.2691084 },
    { lat: 48.6281899, lng: 18.2699137 },
    { lat: 48.6280284, lng: 18.2702496 },
    { lat: 48.6279458, lng: 18.2705008 },
    { lat: 48.6280098, lng: 18.2704382 },
    { lat: 48.6282233, lng: 18.2705414 },
    { lat: 48.6282666, lng: 18.2708247 },
    { lat: 48.6282659, lng: 18.271011 },
    { lat: 48.6284087, lng: 18.2714954 },
    { lat: 48.6285216, lng: 18.2715557 },
    { lat: 48.6286456, lng: 18.271459 },
    { lat: 48.6288833, lng: 18.2714307 },
    { lat: 48.6289877, lng: 18.2709648 },
    { lat: 48.6290011, lng: 18.2709421 },
    { lat: 48.629051, lng: 18.2709302 },
    { lat: 48.6291891, lng: 18.271057 },
    { lat: 48.6293726, lng: 18.2712256 },
    { lat: 48.6294671, lng: 18.271183 },
    { lat: 48.629585, lng: 18.2710942 },
    { lat: 48.6296647, lng: 18.2710864 },
    { lat: 48.6297802, lng: 18.2711942 },
    { lat: 48.6299115, lng: 18.2713166 },
    { lat: 48.6300135, lng: 18.2714225 },
    { lat: 48.630174, lng: 18.2713507 },
    { lat: 48.6302892, lng: 18.2711527 },
    { lat: 48.6305734, lng: 18.2711319 },
    { lat: 48.6306839, lng: 18.2712292 },
    { lat: 48.6310212, lng: 18.2716224 },
    { lat: 48.6310897, lng: 18.271758 },
    { lat: 48.6313205, lng: 18.2717288 },
    { lat: 48.6314672, lng: 18.2716484 },
    { lat: 48.631611, lng: 18.2717568 },
    { lat: 48.631718, lng: 18.2717707 },
    { lat: 48.6319494, lng: 18.271674 },
    { lat: 48.6321053, lng: 18.2716798 },
    { lat: 48.6322496, lng: 18.2718138 },
    { lat: 48.6323417, lng: 18.271866 },
    { lat: 48.6324766, lng: 18.2719762 },
    { lat: 48.6327641, lng: 18.2722293 },
    { lat: 48.6330095, lng: 18.2722984 },
    { lat: 48.6330758, lng: 18.2723958 },
    { lat: 48.6332885, lng: 18.2725651 },
    { lat: 48.633351, lng: 18.272649 },
    { lat: 48.6334289, lng: 18.2728287 },
    { lat: 48.6338305, lng: 18.2730056 },
    { lat: 48.6341391, lng: 18.2732924 },
    { lat: 48.6343004, lng: 18.2733899 },
    { lat: 48.6345404, lng: 18.2735225 },
    { lat: 48.6347133, lng: 18.2737858 },
    { lat: 48.6348026, lng: 18.2739662 },
    { lat: 48.6345267, lng: 18.274669 },
    { lat: 48.6344756, lng: 18.2749218 },
    { lat: 48.634532, lng: 18.2753379 },
    { lat: 48.6343947, lng: 18.2756218 },
    { lat: 48.6343926, lng: 18.2757634 },
    { lat: 48.6343213, lng: 18.2760559 },
    { lat: 48.6343155, lng: 18.2765111 },
    { lat: 48.634317, lng: 18.2765159 },
    { lat: 48.6344186, lng: 18.2775661 },
    { lat: 48.6344081, lng: 18.277738 },
    { lat: 48.6342368, lng: 18.2780185 },
    { lat: 48.633996, lng: 18.2781128 },
    { lat: 48.6338331, lng: 18.278262 },
    { lat: 48.6337912, lng: 18.2784575 },
    { lat: 48.6338286, lng: 18.2787983 },
    { lat: 48.633814, lng: 18.2789979 },
    { lat: 48.6337551, lng: 18.279151 },
    { lat: 48.6336739, lng: 18.2791537 },
    { lat: 48.6335085, lng: 18.279122 },
    { lat: 48.6332072, lng: 18.279237 },
    { lat: 48.6329911, lng: 18.2792661 },
    { lat: 48.6328749, lng: 18.2794053 },
    { lat: 48.6328782, lng: 18.2797837 },
    { lat: 48.6327824, lng: 18.2801403 },
    { lat: 48.6327281, lng: 18.2802793 },
    { lat: 48.6327424, lng: 18.2806096 },
    { lat: 48.632834, lng: 18.280811 },
    { lat: 48.6329844, lng: 18.2810352 },
    { lat: 48.6331247, lng: 18.2815357 },
    { lat: 48.633205, lng: 18.2816376 },
    { lat: 48.6334306, lng: 18.2818164 },
    { lat: 48.6334318, lng: 18.2827105 },
    { lat: 48.6334133, lng: 18.2830001 },
    { lat: 48.6333489, lng: 18.2838517 },
    { lat: 48.6333555, lng: 18.2841248 },
    { lat: 48.6334211, lng: 18.2848429 },
    { lat: 48.6333989, lng: 18.285225 },
    { lat: 48.6333544, lng: 18.2855357 },
    { lat: 48.6333277, lng: 18.2859194 },
    { lat: 48.6332127, lng: 18.2862161 },
    { lat: 48.6332901, lng: 18.2864679 },
    { lat: 48.633268, lng: 18.2865739 },
    { lat: 48.633331, lng: 18.2868504 },
    { lat: 48.633462, lng: 18.2870717 },
    { lat: 48.6335568, lng: 18.287586 },
    { lat: 48.6335271, lng: 18.287837 },
    { lat: 48.6333895, lng: 18.2881159 },
    { lat: 48.6333116, lng: 18.2884927 },
    { lat: 48.6327147, lng: 18.2899019 },
    { lat: 48.6328748, lng: 18.2899229 },
    { lat: 48.6331713, lng: 18.2901553 },
    { lat: 48.6332702, lng: 18.2902477 },
    { lat: 48.6333299, lng: 18.2905842 },
    { lat: 48.6334106, lng: 18.2909091 },
    { lat: 48.6334616, lng: 18.2910541 },
    { lat: 48.6335602, lng: 18.2913 },
    { lat: 48.6338272, lng: 18.2914927 },
    { lat: 48.6350963, lng: 18.2922596 },
    { lat: 48.6362062, lng: 18.2929106 },
    { lat: 48.6364686, lng: 18.2930603 },
    { lat: 48.6365853, lng: 18.2931077 },
    { lat: 48.636743, lng: 18.2931244 },
    { lat: 48.6368384, lng: 18.2930985 },
    { lat: 48.6381603, lng: 18.2926518 },
    { lat: 48.6382721, lng: 18.292631 },
    { lat: 48.639677, lng: 18.2925139 },
    { lat: 48.6397114, lng: 18.2925662 },
    { lat: 48.6397384, lng: 18.2926258 },
    { lat: 48.6401597, lng: 18.2934247 },
    { lat: 48.64013, lng: 18.2944339 },
    { lat: 48.6400062, lng: 18.2959889 },
    { lat: 48.6398307, lng: 18.298124 },
    { lat: 48.639758, lng: 18.2990516 },
    { lat: 48.6396371, lng: 18.3001001 },
    { lat: 48.639539, lng: 18.30098 },
    { lat: 48.6394445, lng: 18.3017767 },
    { lat: 48.6393504, lng: 18.3024285 },
    { lat: 48.6392104, lng: 18.3033031 },
    { lat: 48.639127, lng: 18.3033036 },
    { lat: 48.6389434, lng: 18.3043825 },
    { lat: 48.6388949, lng: 18.3058946 },
    { lat: 48.6388487, lng: 18.3072912 },
    { lat: 48.6388336, lng: 18.3085763 },
    { lat: 48.6388825, lng: 18.3091216 },
    { lat: 48.638977, lng: 18.3095549 },
    { lat: 48.6393283, lng: 18.3102592 },
    { lat: 48.6399222, lng: 18.3107063 },
    { lat: 48.6400453, lng: 18.3110843 },
    { lat: 48.6401, lng: 18.3115117 },
    { lat: 48.640177, lng: 18.311418 },
    { lat: 48.6403675, lng: 18.3114558 },
    { lat: 48.6406752, lng: 18.3115285 },
    { lat: 48.640983, lng: 18.3116062 },
    { lat: 48.641377, lng: 18.3118084 },
    { lat: 48.6416376, lng: 18.3118069 },
    { lat: 48.6417591, lng: 18.3120224 },
    { lat: 48.6418899, lng: 18.3124583 },
    { lat: 48.6421517, lng: 18.312396 },
    { lat: 48.6423333, lng: 18.3130736 },
    { lat: 48.6424633, lng: 18.3130608 },
    { lat: 48.6426561, lng: 18.3137926 },
    { lat: 48.6435546, lng: 18.3138402 },
    { lat: 48.6442904, lng: 18.3138827 },
    { lat: 48.6450175, lng: 18.3139139 },
    { lat: 48.6450635, lng: 18.3138691 },
    { lat: 48.6450707, lng: 18.3139167 },
    { lat: 48.6452562, lng: 18.3148814 },
    { lat: 48.6454347, lng: 18.315603 },
    { lat: 48.6459285, lng: 18.316634 },
    { lat: 48.6462751, lng: 18.3172569 },
    { lat: 48.6464694, lng: 18.3176193 },
    { lat: 48.6467709, lng: 18.3182434 },
    { lat: 48.6468724, lng: 18.3185415 },
    { lat: 48.6469545, lng: 18.3188352 },
    { lat: 48.6470418, lng: 18.3191997 },
    { lat: 48.6472065, lng: 18.3198582 },
    { lat: 48.6475035, lng: 18.3210795 },
    { lat: 48.6478985, lng: 18.3225586 },
    { lat: 48.6484072, lng: 18.3237958 },
    { lat: 48.648581, lng: 18.324359 },
  ],
  KlátovaNováVes: [
    { lat: 48.501088, lng: 18.349817 },
    { lat: 48.50154, lng: 18.349721 },
    { lat: 48.5018191, lng: 18.3494882 },
    { lat: 48.5022353, lng: 18.3490839 },
    { lat: 48.5026936, lng: 18.3487083 },
    { lat: 48.5031879, lng: 18.3485323 },
    { lat: 48.5036061, lng: 18.3486454 },
    { lat: 48.5037982, lng: 18.3487311 },
    { lat: 48.5046127, lng: 18.3487102 },
    { lat: 48.5048472, lng: 18.3486898 },
    { lat: 48.5053392, lng: 18.3487899 },
    { lat: 48.5056616, lng: 18.3493034 },
    { lat: 48.5059982, lng: 18.3495293 },
    { lat: 48.5070843, lng: 18.349705 },
    { lat: 48.507624, lng: 18.34991 },
    { lat: 48.5080574, lng: 18.3495916 },
    { lat: 48.508391, lng: 18.3493494 },
    { lat: 48.5093688, lng: 18.3485814 },
    { lat: 48.5109259, lng: 18.3491018 },
    { lat: 48.5117658, lng: 18.3500723 },
    { lat: 48.5129035, lng: 18.3495795 },
    { lat: 48.5134663, lng: 18.3500755 },
    { lat: 48.5168708, lng: 18.34653 },
    { lat: 48.5195222, lng: 18.3460527 },
    { lat: 48.5209574, lng: 18.3446188 },
    { lat: 48.52155, lng: 18.3440227 },
    { lat: 48.5216729, lng: 18.3439628 },
    { lat: 48.5239115, lng: 18.3429438 },
    { lat: 48.5257429, lng: 18.3402139 },
    { lat: 48.5262539, lng: 18.34003 },
    { lat: 48.5290396, lng: 18.3390969 },
    { lat: 48.5304435, lng: 18.3386041 },
    { lat: 48.5317454, lng: 18.3374638 },
    { lat: 48.5341708, lng: 18.3363377 },
    { lat: 48.5352603, lng: 18.3352344 },
    { lat: 48.538661, lng: 18.3318242 },
    { lat: 48.5397943, lng: 18.3313545 },
    { lat: 48.541034, lng: 18.3306122 },
    { lat: 48.5416265, lng: 18.3303739 },
    { lat: 48.5416969, lng: 18.3305436 },
    { lat: 48.5420288, lng: 18.330527 },
    { lat: 48.5430808, lng: 18.3301524 },
    { lat: 48.5432167, lng: 18.3301211 },
    { lat: 48.5437036, lng: 18.3298133 },
    { lat: 48.5438703, lng: 18.3297107 },
    { lat: 48.5442125, lng: 18.3294763 },
    { lat: 48.5443438, lng: 18.3293038 },
    { lat: 48.5446255, lng: 18.3293168 },
    { lat: 48.545541, lng: 18.3298039 },
    { lat: 48.5456945, lng: 18.329984 },
    { lat: 48.5457418, lng: 18.3301011 },
    { lat: 48.5457632, lng: 18.3301831 },
    { lat: 48.5459142, lng: 18.3302987 },
    { lat: 48.5469993, lng: 18.330371 },
    { lat: 48.5477887, lng: 18.330483 },
    { lat: 48.5483396, lng: 18.3306512 },
    { lat: 48.5485264, lng: 18.3307013 },
    { lat: 48.5486869, lng: 18.330705 },
    { lat: 48.5488746, lng: 18.3305342 },
    { lat: 48.5492951, lng: 18.3303096 },
    { lat: 48.5494633, lng: 18.3302909 },
    { lat: 48.5495644, lng: 18.3303438 },
    { lat: 48.5498763, lng: 18.3304487 },
    { lat: 48.5501736, lng: 18.3304416 },
    { lat: 48.5503099, lng: 18.3302763 },
    { lat: 48.5503334, lng: 18.3303114 },
    { lat: 48.5504964, lng: 18.3302224 },
    { lat: 48.5507428, lng: 18.3301303 },
    { lat: 48.5508944, lng: 18.3300976 },
    { lat: 48.5510395, lng: 18.3301086 },
    { lat: 48.5510466, lng: 18.3301557 },
    { lat: 48.5512262, lng: 18.3302203 },
    { lat: 48.5513478, lng: 18.3303137 },
    { lat: 48.5513666, lng: 18.330308 },
    { lat: 48.5515268, lng: 18.330566 },
    { lat: 48.5516093, lng: 18.3305483 },
    { lat: 48.5518171, lng: 18.3310714 },
    { lat: 48.5518323, lng: 18.3310595 },
    { lat: 48.551922, lng: 18.3311534 },
    { lat: 48.5520166, lng: 18.3313297 },
    { lat: 48.5520906, lng: 18.3316179 },
    { lat: 48.5523914, lng: 18.3318699 },
    { lat: 48.55249, lng: 18.3320168 },
    { lat: 48.5526229, lng: 18.3323342 },
    { lat: 48.5526933, lng: 18.3325812 },
    { lat: 48.5528299, lng: 18.3329223 },
    { lat: 48.5528974, lng: 18.3330158 },
    { lat: 48.553252, lng: 18.3333211 },
    { lat: 48.5541895, lng: 18.3342006 },
    { lat: 48.5546985, lng: 18.3347226 },
    { lat: 48.5551124, lng: 18.335198 },
    { lat: 48.5551674, lng: 18.3353095 },
    { lat: 48.5553122, lng: 18.3354801 },
    { lat: 48.5556007, lng: 18.3358733 },
    { lat: 48.556427, lng: 18.3352406 },
    { lat: 48.556561, lng: 18.3358759 },
    { lat: 48.5566745, lng: 18.3362876 },
    { lat: 48.5567532, lng: 18.3365579 },
    { lat: 48.5567924, lng: 18.3368167 },
    { lat: 48.5568476, lng: 18.3370112 },
    { lat: 48.5571173, lng: 18.3375341 },
    { lat: 48.5575086, lng: 18.3380274 },
    { lat: 48.5577937, lng: 18.3383032 },
    { lat: 48.5578757, lng: 18.3386382 },
    { lat: 48.5580788, lng: 18.3383372 },
    { lat: 48.5583604, lng: 18.3386893 },
    { lat: 48.5585711, lng: 18.3389241 },
    { lat: 48.5586766, lng: 18.3390121 },
    { lat: 48.5588202, lng: 18.3386585 },
    { lat: 48.5588911, lng: 18.3387171 },
    { lat: 48.5590616, lng: 18.3383572 },
    { lat: 48.5591858, lng: 18.3380393 },
    { lat: 48.5594904, lng: 18.3382795 },
    { lat: 48.5601863, lng: 18.3388125 },
    { lat: 48.5606861, lng: 18.3392115 },
    { lat: 48.560956, lng: 18.3392752 },
    { lat: 48.561132, lng: 18.3394161 },
    { lat: 48.5612996, lng: 18.3394858 },
    { lat: 48.5620018, lng: 18.3396954 },
    { lat: 48.5625781, lng: 18.3397469 },
    { lat: 48.5627423, lng: 18.3397579 },
    { lat: 48.5627577, lng: 18.3397342 },
    { lat: 48.5631791, lng: 18.3398024 },
    { lat: 48.5634924, lng: 18.3398149 },
    { lat: 48.5639076, lng: 18.3399167 },
    { lat: 48.5642062, lng: 18.3400287 },
    { lat: 48.5648566, lng: 18.3401783 },
    { lat: 48.5651603, lng: 18.3402238 },
    { lat: 48.5655928, lng: 18.3402275 },
    { lat: 48.5660137, lng: 18.3401668 },
    { lat: 48.5661027, lng: 18.3401469 },
    { lat: 48.5662867, lng: 18.3401279 },
    { lat: 48.5664389, lng: 18.3401465 },
    { lat: 48.5667075, lng: 18.3401691 },
    { lat: 48.5683747, lng: 18.3405015 },
    { lat: 48.5689913, lng: 18.3406106 },
    { lat: 48.5693515, lng: 18.3406538 },
    { lat: 48.5697341, lng: 18.3407341 },
    { lat: 48.5699107, lng: 18.3408055 },
    { lat: 48.5700088, lng: 18.3409052 },
    { lat: 48.5703525, lng: 18.3412972 },
    { lat: 48.5707528, lng: 18.3416538 },
    { lat: 48.5707571, lng: 18.3414708 },
    { lat: 48.5707804, lng: 18.3413588 },
    { lat: 48.570775, lng: 18.3410471 },
    { lat: 48.5708724, lng: 18.3408109 },
    { lat: 48.5711915, lng: 18.3403293 },
    { lat: 48.5710553, lng: 18.3402359 },
    { lat: 48.5709916, lng: 18.3398661 },
    { lat: 48.5709016, lng: 18.3395905 },
    { lat: 48.5711651, lng: 18.3383611 },
    { lat: 48.571295, lng: 18.3383477 },
    { lat: 48.571662, lng: 18.3387114 },
    { lat: 48.5717563, lng: 18.3383637 },
    { lat: 48.5718044, lng: 18.3382571 },
    { lat: 48.572119, lng: 18.336476 },
    { lat: 48.571995, lng: 18.3363943 },
    { lat: 48.5720944, lng: 18.3359177 },
    { lat: 48.5722494, lng: 18.3353057 },
    { lat: 48.5725163, lng: 18.3343972 },
    { lat: 48.5720951, lng: 18.3340519 },
    { lat: 48.5711654, lng: 18.3334148 },
    { lat: 48.5711339, lng: 18.3333978 },
    { lat: 48.5710986, lng: 18.333392 },
    { lat: 48.5710597, lng: 18.3333393 },
    { lat: 48.5710206, lng: 18.333345 },
    { lat: 48.5707587, lng: 18.3331467 },
    { lat: 48.5702521, lng: 18.3328665 },
    { lat: 48.5699863, lng: 18.332715 },
    { lat: 48.5693227, lng: 18.3325123 },
    { lat: 48.5693762, lng: 18.3320826 },
    { lat: 48.5694109, lng: 18.3319175 },
    { lat: 48.5705151, lng: 18.3324295 },
    { lat: 48.5705814, lng: 18.3323052 },
    { lat: 48.5707355, lng: 18.3319363 },
    { lat: 48.5708122, lng: 18.3317757 },
    { lat: 48.5710695, lng: 18.3312197 },
    { lat: 48.5712478, lng: 18.3308598 },
    { lat: 48.5713725, lng: 18.3305702 },
    { lat: 48.5715081, lng: 18.3302753 },
    { lat: 48.5716901, lng: 18.3299801 },
    { lat: 48.5715531, lng: 18.3298747 },
    { lat: 48.5721487, lng: 18.32923 },
    { lat: 48.5713958, lng: 18.328088 },
    { lat: 48.5712215, lng: 18.327861 },
    { lat: 48.5708278, lng: 18.3275214 },
    { lat: 48.5709206, lng: 18.3271682 },
    { lat: 48.5711155, lng: 18.326643 },
    { lat: 48.5697351, lng: 18.3256582 },
    { lat: 48.5698197, lng: 18.3251992 },
    { lat: 48.5699024, lng: 18.3248462 },
    { lat: 48.5700036, lng: 18.324499 },
    { lat: 48.5701634, lng: 18.3240937 },
    { lat: 48.5703439, lng: 18.3233149 },
    { lat: 48.569905, lng: 18.3230361 },
    { lat: 48.5698859, lng: 18.3222028 },
    { lat: 48.5698713, lng: 18.3213051 },
    { lat: 48.5698999, lng: 18.3205529 },
    { lat: 48.5699165, lng: 18.3197895 },
    { lat: 48.5699019, lng: 18.3188972 },
    { lat: 48.5695084, lng: 18.318858 },
    { lat: 48.5694945, lng: 18.3186763 },
    { lat: 48.5695042, lng: 18.318447 },
    { lat: 48.5695467, lng: 18.3181242 },
    { lat: 48.56956, lng: 18.3180993 },
    { lat: 48.5695282, lng: 18.3179926 },
    { lat: 48.5695538, lng: 18.317731 },
    { lat: 48.5698752, lng: 18.3160349 },
    { lat: 48.5700736, lng: 18.3148159 },
    { lat: 48.5701321, lng: 18.3139964 },
    { lat: 48.570156, lng: 18.3135833 },
    { lat: 48.5701612, lng: 18.312541 },
    { lat: 48.5701759, lng: 18.3117817 },
    { lat: 48.5702066, lng: 18.3109095 },
    { lat: 48.5702843, lng: 18.3093583 },
    { lat: 48.5703116, lng: 18.3077712 },
    { lat: 48.5703057, lng: 18.3066291 },
    { lat: 48.5703254, lng: 18.3054473 },
    { lat: 48.5705529, lng: 18.3055192 },
    { lat: 48.5712932, lng: 18.3056411 },
    { lat: 48.5720978, lng: 18.3057451 },
    { lat: 48.5727111, lng: 18.3057943 },
    { lat: 48.5731477, lng: 18.3057726 },
    { lat: 48.5737008, lng: 18.3056775 },
    { lat: 48.5743772, lng: 18.3055105 },
    { lat: 48.5750904, lng: 18.3051695 },
    { lat: 48.5758397, lng: 18.3046572 },
    { lat: 48.5761714, lng: 18.304458 },
    { lat: 48.5763428, lng: 18.3042955 },
    { lat: 48.5768136, lng: 18.3038033 },
    { lat: 48.5773458, lng: 18.3032409 },
    { lat: 48.5777464, lng: 18.3026259 },
    { lat: 48.577741, lng: 18.3025891 },
    { lat: 48.5771691, lng: 18.2991343 },
    { lat: 48.5771417, lng: 18.2989508 },
    { lat: 48.5772051, lng: 18.2988935 },
    { lat: 48.5772505, lng: 18.2987226 },
    { lat: 48.5772515, lng: 18.2982415 },
    { lat: 48.5772633, lng: 18.297895 },
    { lat: 48.577339, lng: 18.297323 },
    { lat: 48.5773889, lng: 18.2970066 },
    { lat: 48.5774563, lng: 18.2966658 },
    { lat: 48.5775172, lng: 18.2964263 },
    { lat: 48.5777634, lng: 18.2956534 },
    { lat: 48.5775073, lng: 18.2940478 },
    { lat: 48.5772679, lng: 18.2924778 },
    { lat: 48.5780076, lng: 18.2913774 },
    { lat: 48.578094, lng: 18.2897044 },
    { lat: 48.5781261, lng: 18.2887399 },
    { lat: 48.5781446, lng: 18.2881336 },
    { lat: 48.578197, lng: 18.2859803 },
    { lat: 48.5782433, lng: 18.2841403 },
    { lat: 48.5782354, lng: 18.2833653 },
    { lat: 48.5782608, lng: 18.2820094 },
    { lat: 48.5783953, lng: 18.2805286 },
    { lat: 48.5782982, lng: 18.278587 },
    { lat: 48.5779073, lng: 18.2752725 },
    { lat: 48.5778965, lng: 18.2752607 },
    { lat: 48.5751527, lng: 18.2759381 },
    { lat: 48.5750815, lng: 18.2746696 },
    { lat: 48.5688545, lng: 18.2756262 },
    { lat: 48.5687828, lng: 18.2756348 },
    { lat: 48.5689394, lng: 18.2751172 },
    { lat: 48.569228, lng: 18.2742825 },
    { lat: 48.5693472, lng: 18.2739967 },
    { lat: 48.5694869, lng: 18.2737342 },
    { lat: 48.5696105, lng: 18.2735479 },
    { lat: 48.5700296, lng: 18.2731024 },
    { lat: 48.5703667, lng: 18.272819 },
    { lat: 48.5706054, lng: 18.2725925 },
    { lat: 48.570711, lng: 18.2724676 },
    { lat: 48.570829, lng: 18.2722592 },
    { lat: 48.5709535, lng: 18.27191 },
    { lat: 48.5709846, lng: 18.2717834 },
    { lat: 48.5709908, lng: 18.2715571 },
    { lat: 48.5709753, lng: 18.2713435 },
    { lat: 48.570853, lng: 18.2706258 },
    { lat: 48.5708078, lng: 18.2701682 },
    { lat: 48.5706818, lng: 18.2689511 },
    { lat: 48.5706346, lng: 18.267748 },
    { lat: 48.5706075, lng: 18.2676279 },
    { lat: 48.5704628, lng: 18.2672273 },
    { lat: 48.570282, lng: 18.2669051 },
    { lat: 48.5701506, lng: 18.2667569 },
    { lat: 48.5699206, lng: 18.266606 },
    { lat: 48.5696066, lng: 18.2664611 },
    { lat: 48.5695367, lng: 18.2665194 },
    { lat: 48.5666158, lng: 18.2687801 },
    { lat: 48.5656055, lng: 18.2695633 },
    { lat: 48.565568, lng: 18.2698542 },
    { lat: 48.5655425, lng: 18.2702205 },
    { lat: 48.5655079, lng: 18.2703842 },
    { lat: 48.5654363, lng: 18.2705132 },
    { lat: 48.5651752, lng: 18.2708021 },
    { lat: 48.5651083, lng: 18.2709144 },
    { lat: 48.5650621, lng: 18.271137 },
    { lat: 48.5650104, lng: 18.271615 },
    { lat: 48.5649594, lng: 18.2717271 },
    { lat: 48.564664, lng: 18.272087 },
    { lat: 48.5645532, lng: 18.2721626 },
    { lat: 48.5645644, lng: 18.2722025 },
    { lat: 48.5645162, lng: 18.272244 },
    { lat: 48.5641843, lng: 18.2725599 },
    { lat: 48.5638852, lng: 18.2728134 },
    { lat: 48.563646, lng: 18.2729719 },
    { lat: 48.5631182, lng: 18.2732358 },
    { lat: 48.5623609, lng: 18.2735466 },
    { lat: 48.5623023, lng: 18.2735684 },
    { lat: 48.562403, lng: 18.2733566 },
    { lat: 48.5625283, lng: 18.2730212 },
    { lat: 48.562681, lng: 18.2725718 },
    { lat: 48.5627588, lng: 18.2720958 },
    { lat: 48.5627667, lng: 18.2718786 },
    { lat: 48.5629665, lng: 18.2702411 },
    { lat: 48.5629205, lng: 18.2702849 },
    { lat: 48.5620612, lng: 18.2710634 },
    { lat: 48.5619885, lng: 18.2711348 },
    { lat: 48.5619817, lng: 18.2712532 },
    { lat: 48.5618997, lng: 18.2714362 },
    { lat: 48.5616741, lng: 18.2714765 },
    { lat: 48.5616637, lng: 18.2714148 },
    { lat: 48.5614794, lng: 18.2710725 },
    { lat: 48.5625247, lng: 18.2699129 },
    { lat: 48.562556, lng: 18.2699263 },
    { lat: 48.5626676, lng: 18.2700636 },
    { lat: 48.5627132, lng: 18.2700125 },
    { lat: 48.5627726, lng: 18.2699054 },
    { lat: 48.5628568, lng: 18.2696818 },
    { lat: 48.5628955, lng: 18.2694709 },
    { lat: 48.5629425, lng: 18.2694652 },
    { lat: 48.5628936, lng: 18.2691595 },
    { lat: 48.5628571, lng: 18.2690164 },
    { lat: 48.5627146, lng: 18.2686743 },
    { lat: 48.5625778, lng: 18.2683896 },
    { lat: 48.5623471, lng: 18.2680074 },
    { lat: 48.5622663, lng: 18.2678953 },
    { lat: 48.5620466, lng: 18.2676459 },
    { lat: 48.5619832, lng: 18.267564 },
    { lat: 48.5616544, lng: 18.267259 },
    { lat: 48.5615359, lng: 18.2671215 },
    { lat: 48.562357, lng: 18.266147 },
    { lat: 48.5625956, lng: 18.2658211 },
    { lat: 48.5627859, lng: 18.2655175 },
    { lat: 48.5628451, lng: 18.2652876 },
    { lat: 48.5628434, lng: 18.2650794 },
    { lat: 48.5625137, lng: 18.2649376 },
    { lat: 48.5623441, lng: 18.2647737 },
    { lat: 48.5621703, lng: 18.2645554 },
    { lat: 48.5619741, lng: 18.2644188 },
    { lat: 48.5618076, lng: 18.2643894 },
    { lat: 48.5616922, lng: 18.2643442 },
    { lat: 48.5616772, lng: 18.2643579 },
    { lat: 48.561936, lng: 18.2637148 },
    { lat: 48.5619357, lng: 18.2636682 },
    { lat: 48.5615795, lng: 18.2618785 },
    { lat: 48.5625034, lng: 18.2607673 },
    { lat: 48.562516, lng: 18.260715 },
    { lat: 48.5626539, lng: 18.2605593 },
    { lat: 48.5627328, lng: 18.2602224 },
    { lat: 48.5627612, lng: 18.2599875 },
    { lat: 48.5628359, lng: 18.2596398 },
    { lat: 48.5628504, lng: 18.2592403 },
    { lat: 48.562825, lng: 18.259052 },
    { lat: 48.5627712, lng: 18.2590789 },
    { lat: 48.5627529, lng: 18.2590346 },
    { lat: 48.5626541, lng: 18.2590603 },
    { lat: 48.5625006, lng: 18.2590798 },
    { lat: 48.562291, lng: 18.2591459 },
    { lat: 48.5619968, lng: 18.2592888 },
    { lat: 48.5591603, lng: 18.2556827 },
    { lat: 48.5573437, lng: 18.2534919 },
    { lat: 48.5563501, lng: 18.2522033 },
    { lat: 48.556037, lng: 18.252715 },
    { lat: 48.556029, lng: 18.252744 },
    { lat: 48.555828, lng: 18.25307 },
    { lat: 48.555558, lng: 18.25352 },
    { lat: 48.556056, lng: 18.254209 },
    { lat: 48.555975, lng: 18.254445 },
    { lat: 48.555953, lng: 18.254657 },
    { lat: 48.555973, lng: 18.254886 },
    { lat: 48.555669, lng: 18.255523 },
    { lat: 48.555445, lng: 18.256004 },
    { lat: 48.555274, lng: 18.256336 },
    { lat: 48.55524, lng: 18.256548 },
    { lat: 48.555036, lng: 18.256968 },
    { lat: 48.555142, lng: 18.257119 },
    { lat: 48.555066, lng: 18.257479 },
    { lat: 48.554571, lng: 18.257697 },
    { lat: 48.554268, lng: 18.257912 },
    { lat: 48.553972, lng: 18.258015 },
    { lat: 48.553828, lng: 18.258081 },
    { lat: 48.553719, lng: 18.258177 },
    { lat: 48.553691, lng: 18.258194 },
    { lat: 48.553584, lng: 18.258325 },
    { lat: 48.553486, lng: 18.258455 },
    { lat: 48.553425, lng: 18.258603 },
    { lat: 48.553762, lng: 18.259117 },
    { lat: 48.553387, lng: 18.259757 },
    { lat: 48.552946, lng: 18.260491 },
    { lat: 48.552935, lng: 18.260509 },
    { lat: 48.552827, lng: 18.260675 },
    { lat: 48.552737, lng: 18.260817 },
    { lat: 48.552652, lng: 18.260935 },
    { lat: 48.552544, lng: 18.261095 },
    { lat: 48.552532, lng: 18.261119 },
    { lat: 48.552427, lng: 18.261215 },
    { lat: 48.552279, lng: 18.261363 },
    { lat: 48.552066, lng: 18.261571 },
    { lat: 48.551907, lng: 18.261713 },
    { lat: 48.551798, lng: 18.261809 },
    { lat: 48.551569, lng: 18.262018 },
    { lat: 48.551487, lng: 18.262089 },
    { lat: 48.551437, lng: 18.262142 },
    { lat: 48.551196, lng: 18.262387 },
    { lat: 48.551157, lng: 18.262439 },
    { lat: 48.551076, lng: 18.262553 },
    { lat: 48.551018, lng: 18.262694 },
    { lat: 48.550949, lng: 18.262948 },
    { lat: 48.5520831, lng: 18.2642541 },
    { lat: 48.552452, lng: 18.264679 },
    { lat: 48.552017, lng: 18.266038 },
    { lat: 48.550267, lng: 18.267809 },
    { lat: 48.549832, lng: 18.267643 },
    { lat: 48.54965, lng: 18.267692 },
    { lat: 48.549528, lng: 18.267635 },
    { lat: 48.549166, lng: 18.26788 },
    { lat: 48.548947, lng: 18.267805 },
    { lat: 48.548964, lng: 18.268058 },
    { lat: 48.548509, lng: 18.268345 },
    { lat: 48.5483, lng: 18.268477 },
    { lat: 48.548305, lng: 18.268659 },
    { lat: 48.548271, lng: 18.268866 },
    { lat: 48.54819, lng: 18.269149 },
    { lat: 48.547971, lng: 18.26951 },
    { lat: 48.547657, lng: 18.269907 },
    { lat: 48.547537, lng: 18.270038 },
    { lat: 48.547352, lng: 18.270323 },
    { lat: 48.547266, lng: 18.270347 },
    { lat: 48.547188, lng: 18.270313 },
    { lat: 48.547128, lng: 18.270572 },
    { lat: 48.547067, lng: 18.270931 },
    { lat: 48.546999, lng: 18.271179 },
    { lat: 48.546926, lng: 18.271473 },
    { lat: 48.546886, lng: 18.271833 },
    { lat: 48.546883, lng: 18.27222 },
    { lat: 48.546823, lng: 18.272532 },
    { lat: 48.546637, lng: 18.273451 },
    { lat: 48.546542, lng: 18.273817 },
    { lat: 48.546261, lng: 18.274308 },
    { lat: 48.545928, lng: 18.27487 },
    { lat: 48.545759, lng: 18.275195 },
    { lat: 48.545439, lng: 18.275746 },
    { lat: 48.545281, lng: 18.276082 },
    { lat: 48.545096, lng: 18.276455 },
    { lat: 48.544985, lng: 18.27668 },
    { lat: 48.544907, lng: 18.27681 },
    { lat: 48.544883, lng: 18.276854 },
    { lat: 48.54473, lng: 18.277157 },
    { lat: 48.544473, lng: 18.27733 },
    { lat: 48.544334, lng: 18.277514 },
    { lat: 48.54407, lng: 18.277805 },
    { lat: 48.543891, lng: 18.27796 },
    { lat: 48.54381, lng: 18.278032 },
    { lat: 48.543651, lng: 18.278245 },
    { lat: 48.543487, lng: 18.278416 },
    { lat: 48.543255, lng: 18.278658 },
    { lat: 48.543228, lng: 18.278685 },
    { lat: 48.543049, lng: 18.278907 },
    { lat: 48.542975, lng: 18.278999 },
    { lat: 48.542809, lng: 18.279207 },
    { lat: 48.542794, lng: 18.279266 },
    { lat: 48.542786, lng: 18.279337 },
    { lat: 48.542737, lng: 18.27959 },
    { lat: 48.542706, lng: 18.279773 },
    { lat: 48.542677, lng: 18.280155 },
    { lat: 48.542697, lng: 18.280314 },
    { lat: 48.542643, lng: 18.280573 },
    { lat: 48.542638, lng: 18.281079 },
    { lat: 48.542561, lng: 18.281303 },
    { lat: 48.542461, lng: 18.281733 },
    { lat: 48.542439, lng: 18.282145 },
    { lat: 48.542387, lng: 18.282593 },
    { lat: 48.542287, lng: 18.282923 },
    { lat: 48.542145, lng: 18.283413 },
    { lat: 48.54196, lng: 18.28395 },
    { lat: 48.541884, lng: 18.284274 },
    { lat: 48.541775, lng: 18.284511 },
    { lat: 48.541613, lng: 18.284666 },
    { lat: 48.541586, lng: 18.284689 },
    { lat: 48.541356, lng: 18.28498 },
    { lat: 48.541042, lng: 18.285272 },
    { lat: 48.540876, lng: 18.285661 },
    { lat: 48.540648, lng: 18.285814 },
    { lat: 48.540479, lng: 18.285942 },
    { lat: 48.540187, lng: 18.286086 },
    { lat: 48.539857, lng: 18.286278 },
    { lat: 48.539598, lng: 18.286592 },
    { lat: 48.539395, lng: 18.286783 },
    { lat: 48.539185, lng: 18.286997 },
    { lat: 48.538914, lng: 18.287264 },
    { lat: 48.538673, lng: 18.287473 },
    { lat: 48.53849, lng: 18.287574 },
    { lat: 48.538459, lng: 18.287593 },
    { lat: 48.538378, lng: 18.28764 },
    { lat: 48.538268, lng: 18.287717 },
    { lat: 48.537981, lng: 18.287892 },
    { lat: 48.537674, lng: 18.288083 },
    { lat: 48.537352, lng: 18.288474 },
    { lat: 48.537025, lng: 18.288701 },
    { lat: 48.536705, lng: 18.288546 },
    { lat: 48.536548, lng: 18.288507 },
    { lat: 48.536283, lng: 18.288262 },
    { lat: 48.536169, lng: 18.287999 },
    { lat: 48.535927, lng: 18.288113 },
    { lat: 48.535854, lng: 18.288402 },
    { lat: 48.535789, lng: 18.288544 },
    { lat: 48.53545, lng: 18.288759 },
    { lat: 48.535341, lng: 18.288796 },
    { lat: 48.53498, lng: 18.289147 },
    { lat: 48.534573, lng: 18.289955 },
    { lat: 48.534128, lng: 18.290462 },
    { lat: 48.53386, lng: 18.290572 },
    { lat: 48.5336, lng: 18.290735 },
    { lat: 48.533429, lng: 18.290855 },
    { lat: 48.533305, lng: 18.290933 },
    { lat: 48.532846, lng: 18.291134 },
    { lat: 48.532601, lng: 18.291179 },
    { lat: 48.532534, lng: 18.291192 },
    { lat: 48.53243, lng: 18.291205 },
    { lat: 48.532329, lng: 18.291242 },
    { lat: 48.532208, lng: 18.291308 },
    { lat: 48.531995, lng: 18.291476 },
    { lat: 48.531568, lng: 18.291659 },
    { lat: 48.531237, lng: 18.291734 },
    { lat: 48.530679, lng: 18.292107 },
    { lat: 48.530305, lng: 18.292225 },
    { lat: 48.529793, lng: 18.292497 },
    { lat: 48.529202, lng: 18.29287 },
    { lat: 48.529164, lng: 18.292936 },
    { lat: 48.529083, lng: 18.29309 },
    { lat: 48.528994, lng: 18.293191 },
    { lat: 48.528905, lng: 18.29328 },
    { lat: 48.528672, lng: 18.293454 },
    { lat: 48.528355, lng: 18.293788 },
    { lat: 48.528018, lng: 18.294158 },
    { lat: 48.527906, lng: 18.294183 },
    { lat: 48.527722, lng: 18.29411 },
    { lat: 48.52764, lng: 18.294046 },
    { lat: 48.527507, lng: 18.294037 },
    { lat: 48.527402, lng: 18.294068 },
    { lat: 48.52718, lng: 18.2942 },
    { lat: 48.527038, lng: 18.294355 },
    { lat: 48.526794, lng: 18.294612 },
    { lat: 48.526611, lng: 18.29475 },
    { lat: 48.526464, lng: 18.294881 },
    { lat: 48.526055, lng: 18.295451 },
    { lat: 48.525754, lng: 18.295862 },
    { lat: 48.525511, lng: 18.296148 },
    { lat: 48.525275, lng: 18.296421 },
    { lat: 48.524941, lng: 18.296832 },
    { lat: 48.524609, lng: 18.297154 },
    { lat: 48.524531, lng: 18.297214 },
    { lat: 48.524287, lng: 18.297382 },
    { lat: 48.524136, lng: 18.297549 },
    { lat: 48.524006, lng: 18.297862 },
    { lat: 48.523847, lng: 18.298082 },
    { lat: 48.523686, lng: 18.298443 },
    { lat: 48.523569, lng: 18.298539 },
    { lat: 48.523207, lng: 18.298562 },
    { lat: 48.522947, lng: 18.298648 },
    { lat: 48.522589, lng: 18.298713 },
    { lat: 48.522503, lng: 18.298755 },
    { lat: 48.522271, lng: 18.298852 },
    { lat: 48.522052, lng: 18.299003 },
    { lat: 48.521878, lng: 18.299135 },
    { lat: 48.521663, lng: 18.299538 },
    { lat: 48.521419, lng: 18.299765 },
    { lat: 48.521235, lng: 18.299958 },
    { lat: 48.521181, lng: 18.300011 },
    { lat: 48.521026, lng: 18.300217 },
    { lat: 48.521015, lng: 18.300232 },
    { lat: 48.520862, lng: 18.300465 },
    { lat: 48.520769, lng: 18.300747 },
    { lat: 48.520673, lng: 18.301352 },
    { lat: 48.520643, lng: 18.30201 },
    { lat: 48.520589, lng: 18.302551 },
    { lat: 48.520453, lng: 18.302869 },
    { lat: 48.520351, lng: 18.303116 },
    { lat: 48.520329, lng: 18.303275 },
    { lat: 48.520258, lng: 18.303452 },
    { lat: 48.52018, lng: 18.303529 },
    { lat: 48.520071, lng: 18.303464 },
    { lat: 48.520012, lng: 18.303411 },
    { lat: 48.519934, lng: 18.30333 },
    { lat: 48.519832, lng: 18.303223 },
    { lat: 48.519747, lng: 18.303187 },
    { lat: 48.519547, lng: 18.30317 },
    { lat: 48.519446, lng: 18.303188 },
    { lat: 48.519159, lng: 18.303298 },
    { lat: 48.518882, lng: 18.3034 },
    { lat: 48.518585, lng: 18.303523 },
    { lat: 48.518358, lng: 18.303647 },
    { lat: 48.518093, lng: 18.303725 },
    { lat: 48.518036, lng: 18.303738 },
    { lat: 48.517878, lng: 18.303783 },
    { lat: 48.517773, lng: 18.303848 },
    { lat: 48.517657, lng: 18.304007 },
    { lat: 48.517492, lng: 18.304089 },
    { lat: 48.51709, lng: 18.304226 },
    { lat: 48.516982, lng: 18.304261 },
    { lat: 48.516798, lng: 18.304496 },
    { lat: 48.516601, lng: 18.304639 },
    { lat: 48.516576, lng: 18.304655 },
    { lat: 48.516451, lng: 18.304744 },
    { lat: 48.51626, lng: 18.304944 },
    { lat: 48.51605, lng: 18.305239 },
    { lat: 48.515816, lng: 18.305527 },
    { lat: 48.51566, lng: 18.305698 },
    { lat: 48.515512, lng: 18.305816 },
    { lat: 48.515434, lng: 18.305904 },
    { lat: 48.515345, lng: 18.306075 },
    { lat: 48.515314, lng: 18.306122 },
    { lat: 48.515063, lng: 18.306364 },
    { lat: 48.514971, lng: 18.306499 },
    { lat: 48.514892, lng: 18.306582 },
    { lat: 48.514821, lng: 18.306635 },
    { lat: 48.514747, lng: 18.306741 },
    { lat: 48.514697, lng: 18.306912 },
    { lat: 48.514608, lng: 18.307123 },
    { lat: 48.514468, lng: 18.307265 },
    { lat: 48.514202, lng: 18.307488 },
    { lat: 48.514117, lng: 18.307583 },
    { lat: 48.514062, lng: 18.307612 },
    { lat: 48.513958, lng: 18.307602 },
    { lat: 48.513941, lng: 18.307601 },
    { lat: 48.513886, lng: 18.307606 },
    { lat: 48.513711, lng: 18.307601 },
    { lat: 48.513621, lng: 18.307595 },
    { lat: 48.51354, lng: 18.307565 },
    { lat: 48.51347, lng: 18.307514 },
    { lat: 48.513377, lng: 18.307456 },
    { lat: 48.513364, lng: 18.307448 },
    { lat: 48.513278, lng: 18.307389 },
    { lat: 48.513184, lng: 18.307342 },
    { lat: 48.513117, lng: 18.307324 },
    { lat: 48.513028, lng: 18.307325 },
    { lat: 48.512923, lng: 18.307285 },
    { lat: 48.512832, lng: 18.307248 },
    { lat: 48.51259, lng: 18.307201 },
    { lat: 48.512525, lng: 18.307219 },
    { lat: 48.512338, lng: 18.307214 },
    { lat: 48.512321, lng: 18.307213 },
    { lat: 48.512259, lng: 18.307195 },
    { lat: 48.512083, lng: 18.307154 },
    { lat: 48.51199, lng: 18.307143 },
    { lat: 48.511815, lng: 18.307113 },
    { lat: 48.511744, lng: 18.307119 },
    { lat: 48.511674, lng: 18.307154 },
    { lat: 48.511514, lng: 18.30729 },
    { lat: 48.511424, lng: 18.307349 },
    { lat: 48.511257, lng: 18.30742 },
    { lat: 48.511073, lng: 18.307443 },
    { lat: 48.510956, lng: 18.307467 },
    { lat: 48.51089, lng: 18.307585 },
    { lat: 48.510789, lng: 18.30765 },
    { lat: 48.510723, lng: 18.307673 },
    { lat: 48.510559, lng: 18.307685 },
    { lat: 48.510454, lng: 18.307744 },
    { lat: 48.510301, lng: 18.307815 },
    { lat: 48.510196, lng: 18.307809 },
    { lat: 48.510071, lng: 18.307839 },
    { lat: 48.509895, lng: 18.307892 },
    { lat: 48.509665, lng: 18.308027 },
    { lat: 48.509575, lng: 18.308127 },
    { lat: 48.509401, lng: 18.308374 },
    { lat: 48.509346, lng: 18.308445 },
    { lat: 48.509104, lng: 18.308639 },
    { lat: 48.508986, lng: 18.308769 },
    { lat: 48.508945, lng: 18.308887 },
    { lat: 48.508886, lng: 18.308946 },
    { lat: 48.508827, lng: 18.308975 },
    { lat: 48.508718, lng: 18.308993 },
    { lat: 48.50856, lng: 18.309168 },
    { lat: 48.5085, lng: 18.309205 },
    { lat: 48.508387, lng: 18.309258 },
    { lat: 48.50825, lng: 18.309258 },
    { lat: 48.507829, lng: 18.309123 },
    { lat: 48.507809, lng: 18.309114 },
    { lat: 48.507736, lng: 18.309089 },
    { lat: 48.507628, lng: 18.309085 },
    { lat: 48.507463, lng: 18.30907 },
    { lat: 48.507288, lng: 18.309062 },
    { lat: 48.507097, lng: 18.309084 },
    { lat: 48.506771, lng: 18.309148 },
    { lat: 48.50656, lng: 18.309235 },
    { lat: 48.506306, lng: 18.309239 },
    { lat: 48.505837, lng: 18.309495 },
    { lat: 48.505687, lng: 18.30965 },
    { lat: 48.505596, lng: 18.309711 },
    { lat: 48.505531, lng: 18.30973 },
    { lat: 48.505444, lng: 18.309737 },
    { lat: 48.505308, lng: 18.309664 },
    { lat: 48.505039, lng: 18.309692 },
    { lat: 48.504884, lng: 18.309836 },
    { lat: 48.504784, lng: 18.309996 },
    { lat: 48.504679, lng: 18.310069 },
    { lat: 48.504559, lng: 18.3101 },
    { lat: 48.504443, lng: 18.310156 },
    { lat: 48.504125, lng: 18.31042 },
    { lat: 48.504067, lng: 18.310481 },
    { lat: 48.503876, lng: 18.310677 },
    { lat: 48.503725, lng: 18.310751 },
    { lat: 48.503621, lng: 18.310853 },
    { lat: 48.503486, lng: 18.311015 },
    { lat: 48.503413, lng: 18.311186 },
    { lat: 48.503356, lng: 18.311369 },
    { lat: 48.503216, lng: 18.311877 },
    { lat: 48.503159, lng: 18.312072 },
    { lat: 48.503129, lng: 18.312202 },
    { lat: 48.503022, lng: 18.31244 },
    { lat: 48.502875, lng: 18.312659 },
    { lat: 48.502809, lng: 18.312772 },
    { lat: 48.502736, lng: 18.312856 },
    { lat: 48.502639, lng: 18.312917 },
    { lat: 48.502542, lng: 18.312912 },
    { lat: 48.502443, lng: 18.312844 },
    { lat: 48.50225, lng: 18.312581 },
    { lat: 48.502171, lng: 18.312484 },
    { lat: 48.502061, lng: 18.312369 },
    { lat: 48.501995, lng: 18.312335 },
    { lat: 48.501873, lng: 18.312302 },
    { lat: 48.501694, lng: 18.312287 },
    { lat: 48.501678, lng: 18.312286 },
    { lat: 48.501542, lng: 18.312226 },
    { lat: 48.501375, lng: 18.312017 },
    { lat: 48.501223, lng: 18.311867 },
    { lat: 48.501136, lng: 18.311728 },
    { lat: 48.500917, lng: 18.311726 },
    { lat: 48.500831, lng: 18.31171 },
    { lat: 48.500732, lng: 18.3116 },
    { lat: 48.500575, lng: 18.311368 },
    { lat: 48.500432, lng: 18.311041 },
    { lat: 48.500206, lng: 18.311034 },
    { lat: 48.500006, lng: 18.310961 },
    { lat: 48.49992, lng: 18.311003 },
    { lat: 48.499749, lng: 18.311095 },
    { lat: 48.499649, lng: 18.311132 },
    { lat: 48.499532, lng: 18.311123 },
    { lat: 48.499418, lng: 18.311066 },
    { lat: 48.49934, lng: 18.311021 },
    { lat: 48.499273, lng: 18.310981 },
    { lat: 48.499093, lng: 18.310949 },
    { lat: 48.498981, lng: 18.310998 },
    { lat: 48.498871, lng: 18.3111 },
    { lat: 48.498571, lng: 18.311041 },
    { lat: 48.49832, lng: 18.310863 },
    { lat: 48.498157, lng: 18.310937 },
    { lat: 48.498107, lng: 18.310955 },
    { lat: 48.497907, lng: 18.311012 },
    { lat: 48.497554, lng: 18.311183 },
    { lat: 48.497468, lng: 18.311208 },
    { lat: 48.497294, lng: 18.311311 },
    { lat: 48.497038, lng: 18.311369 },
    { lat: 48.496886, lng: 18.311372 },
    { lat: 48.496763, lng: 18.312913 },
    { lat: 48.496673, lng: 18.314102 },
    { lat: 48.496183, lng: 18.314676 },
    { lat: 48.496032, lng: 18.314855 },
    { lat: 48.495764, lng: 18.315173 },
    { lat: 48.495513, lng: 18.316009 },
    { lat: 48.49544, lng: 18.316262 },
    { lat: 48.49518, lng: 18.317168 },
    { lat: 48.495193, lng: 18.317213 },
    { lat: 48.495278, lng: 18.318289 },
    { lat: 48.495017, lng: 18.319482 },
    { lat: 48.495014, lng: 18.31988 },
    { lat: 48.495016, lng: 18.320276 },
    { lat: 48.495022, lng: 18.320762 },
    { lat: 48.494995, lng: 18.320887 },
    { lat: 48.494847, lng: 18.321022 },
    { lat: 48.49471, lng: 18.321555 },
    { lat: 48.494466, lng: 18.322038 },
    { lat: 48.494392, lng: 18.322691 },
    { lat: 48.494327, lng: 18.322939 },
    { lat: 48.49419, lng: 18.3234 },
    { lat: 48.494157, lng: 18.323934 },
    { lat: 48.494201, lng: 18.324373 },
    { lat: 48.494095, lng: 18.324639 },
    { lat: 48.494069, lng: 18.324756 },
    { lat: 48.49402, lng: 18.3251 },
    { lat: 48.493938, lng: 18.325261 },
    { lat: 48.493817, lng: 18.325396 },
    { lat: 48.493645, lng: 18.325531 },
    { lat: 48.493574, lng: 18.32563 },
    { lat: 48.493513, lng: 18.325783 },
    { lat: 48.49352, lng: 18.326049 },
    { lat: 48.493564, lng: 18.326695 },
    { lat: 48.493516, lng: 18.326822 },
    { lat: 48.493327, lng: 18.327321 },
    { lat: 48.493213, lng: 18.327488 },
    { lat: 48.493181, lng: 18.327605 },
    { lat: 48.493194, lng: 18.327774 },
    { lat: 48.493181, lng: 18.32811 },
    { lat: 48.493209, lng: 18.328293 },
    { lat: 48.493211, lng: 18.32849 },
    { lat: 48.493128, lng: 18.328867 },
    { lat: 48.493107, lng: 18.329004 },
    { lat: 48.493029, lng: 18.32921 },
    { lat: 48.492905, lng: 18.329441 },
    { lat: 48.492655, lng: 18.330167 },
    { lat: 48.492615, lng: 18.330387 },
    { lat: 48.49263, lng: 18.330595 },
    { lat: 48.492609, lng: 18.330785 },
    { lat: 48.492542, lng: 18.331708 },
    { lat: 48.492466, lng: 18.332186 },
    { lat: 48.492407, lng: 18.332369 },
    { lat: 48.492348, lng: 18.332497 },
    { lat: 48.492321, lng: 18.332559 },
    { lat: 48.492066, lng: 18.333057 },
    { lat: 48.491933, lng: 18.333218 },
    { lat: 48.491867, lng: 18.333359 },
    { lat: 48.491774, lng: 18.333632 },
    { lat: 48.491679, lng: 18.333711 },
    { lat: 48.491425, lng: 18.333995 },
    { lat: 48.491373, lng: 18.33412 },
    { lat: 48.491297, lng: 18.3342 },
    { lat: 48.491169, lng: 18.334273 },
    { lat: 48.491126, lng: 18.334308 },
    { lat: 48.49109, lng: 18.334407 },
    { lat: 48.49097, lng: 18.334598 },
    { lat: 48.490827, lng: 18.334859 },
    { lat: 48.49053, lng: 18.335149 },
    { lat: 48.490231, lng: 18.335644 },
    { lat: 48.490115, lng: 18.335904 },
    { lat: 48.490035, lng: 18.336073 },
    { lat: 48.489907, lng: 18.336185 },
    { lat: 48.489819, lng: 18.336313 },
    { lat: 48.489776, lng: 18.336443 },
    { lat: 48.489826, lng: 18.337036 },
    { lat: 48.489877, lng: 18.337453 },
    { lat: 48.489959, lng: 18.337703 },
    { lat: 48.489923, lng: 18.338014 },
    { lat: 48.489829, lng: 18.338925 },
    { lat: 48.489825, lng: 18.339209 },
    { lat: 48.489808, lng: 18.339701 },
    { lat: 48.489761, lng: 18.340297 },
    { lat: 48.489748, lng: 18.340481 },
    { lat: 48.489745, lng: 18.340526 },
    { lat: 48.489709, lng: 18.340761 },
    { lat: 48.489707, lng: 18.341087 },
    { lat: 48.489707, lng: 18.341284 },
    { lat: 48.489748, lng: 18.341563 },
    { lat: 48.489811, lng: 18.34198 },
    { lat: 48.48986, lng: 18.342265 },
    { lat: 48.489977, lng: 18.342567 },
    { lat: 48.490056, lng: 18.342674 },
    { lat: 48.490361, lng: 18.343087 },
    { lat: 48.490529, lng: 18.343312 },
    { lat: 48.490683, lng: 18.343548 },
    { lat: 48.491308, lng: 18.344385 },
    { lat: 48.491482, lng: 18.344463 },
    { lat: 48.491759, lng: 18.3446 },
    { lat: 48.491988, lng: 18.344895 },
    { lat: 48.492118, lng: 18.345562 },
    { lat: 48.492221, lng: 18.345802 },
    { lat: 48.492403, lng: 18.345995 },
    { lat: 48.492634, lng: 18.346028 },
    { lat: 48.492783, lng: 18.346253 },
    { lat: 48.492965, lng: 18.34655 },
    { lat: 48.492984, lng: 18.346635 },
    { lat: 48.493104, lng: 18.346893 },
    { lat: 48.493267, lng: 18.347107 },
    { lat: 48.493418, lng: 18.347165 },
    { lat: 48.493537, lng: 18.347162 },
    { lat: 48.493705, lng: 18.347384 },
    { lat: 48.493813, lng: 18.347469 },
    { lat: 48.493961, lng: 18.347466 },
    { lat: 48.494112, lng: 18.347511 },
    { lat: 48.49442, lng: 18.347789 },
    { lat: 48.494679, lng: 18.348059 },
    { lat: 48.495165, lng: 18.348155 },
    { lat: 48.495376, lng: 18.348111 },
    { lat: 48.495487, lng: 18.347961 },
    { lat: 48.495557, lng: 18.347841 },
    { lat: 48.495651, lng: 18.347756 },
    { lat: 48.49578, lng: 18.347699 },
    { lat: 48.495945, lng: 18.347547 },
    { lat: 48.496096, lng: 18.347477 },
    { lat: 48.496246, lng: 18.347443 },
    { lat: 48.496484, lng: 18.347488 },
    { lat: 48.496638, lng: 18.347583 },
    { lat: 48.496666, lng: 18.347601 },
    { lat: 48.49673, lng: 18.347678 },
    { lat: 48.496836, lng: 18.347846 },
    { lat: 48.496933, lng: 18.347956 },
    { lat: 48.497059, lng: 18.348083 },
    { lat: 48.497179, lng: 18.348141 },
    { lat: 48.497452, lng: 18.348182 },
    { lat: 48.497802, lng: 18.348254 },
    { lat: 48.49813, lng: 18.34837 },
    { lat: 48.498307, lng: 18.348375 },
    { lat: 48.498822, lng: 18.348356 },
    { lat: 48.499285, lng: 18.348337 },
    { lat: 48.499415, lng: 18.348332 },
    { lat: 48.499795, lng: 18.348353 },
    { lat: 48.499948, lng: 18.348441 },
    { lat: 48.500086, lng: 18.348671 },
    { lat: 48.500184, lng: 18.348901 },
    { lat: 48.500434, lng: 18.349139 },
    { lat: 48.500618, lng: 18.349329 },
    { lat: 48.501088, lng: 18.349817 },
  ],
  Partizánske: [
    { lat: 48.6081926, lng: 18.3842003 },
    { lat: 48.6088268, lng: 18.384871 },
    { lat: 48.6094354, lng: 18.3855047 },
    { lat: 48.6106282, lng: 18.3855485 },
    { lat: 48.6116331, lng: 18.3855417 },
    { lat: 48.6124216, lng: 18.3855393 },
    { lat: 48.6134457, lng: 18.3855761 },
    { lat: 48.614554, lng: 18.3856018 },
    { lat: 48.6159489, lng: 18.3851471 },
    { lat: 48.6167618, lng: 18.3848374 },
    { lat: 48.617538, lng: 18.3845549 },
    { lat: 48.6183256, lng: 18.3842955 },
    { lat: 48.6188706, lng: 18.3838955 },
    { lat: 48.6194744, lng: 18.3834352 },
    { lat: 48.6201752, lng: 18.3828927 },
    { lat: 48.6213332, lng: 18.3836876 },
    { lat: 48.6213218, lng: 18.3840699 },
    { lat: 48.6213175, lng: 18.3844156 },
    { lat: 48.6213091, lng: 18.3848096 },
    { lat: 48.6212972, lng: 18.3867332 },
    { lat: 48.6213221, lng: 18.3870479 },
    { lat: 48.6214056, lng: 18.388104 },
    { lat: 48.6213811, lng: 18.388131 },
    { lat: 48.6210506, lng: 18.3884302 },
    { lat: 48.6211065, lng: 18.389087 },
    { lat: 48.6212564, lng: 18.3894884 },
    { lat: 48.6214144, lng: 18.3898922 },
    { lat: 48.6215585, lng: 18.3907129 },
    { lat: 48.6212832, lng: 18.3909951 },
    { lat: 48.6210804, lng: 18.3910847 },
    { lat: 48.6205261, lng: 18.3913775 },
    { lat: 48.6202259, lng: 18.3912773 },
    { lat: 48.619749, lng: 18.3913591 },
    { lat: 48.6193777, lng: 18.3912601 },
    { lat: 48.6193493, lng: 18.3912762 },
    { lat: 48.6192828, lng: 18.3912365 },
    { lat: 48.619245, lng: 18.3912253 },
    { lat: 48.6190716, lng: 18.3913551 },
    { lat: 48.6179619, lng: 18.3923688 },
    { lat: 48.6176345, lng: 18.392327 },
    { lat: 48.6174843, lng: 18.3922032 },
    { lat: 48.6170664, lng: 18.3918495 },
    { lat: 48.6168938, lng: 18.3920088 },
    { lat: 48.6165081, lng: 18.39161 },
    { lat: 48.6162539, lng: 18.3914234 },
    { lat: 48.6160445, lng: 18.3914123 },
    { lat: 48.6153767, lng: 18.3909475 },
    { lat: 48.6153363, lng: 18.390932 },
    { lat: 48.6150396, lng: 18.3905094 },
    { lat: 48.6150104, lng: 18.3904308 },
    { lat: 48.6148001, lng: 18.3903017 },
    { lat: 48.6145337, lng: 18.3901791 },
    { lat: 48.6143174, lng: 18.3900034 },
    { lat: 48.614214, lng: 18.3909771 },
    { lat: 48.6144077, lng: 18.3912316 },
    { lat: 48.614484, lng: 18.391244 },
    { lat: 48.6145478, lng: 18.3926072 },
    { lat: 48.6146501, lng: 18.3926622 },
    { lat: 48.6146634, lng: 18.3932417 },
    { lat: 48.6151555, lng: 18.3931049 },
    { lat: 48.6152199, lng: 18.3936305 },
    { lat: 48.6153763, lng: 18.39356 },
    { lat: 48.615384, lng: 18.3935201 },
    { lat: 48.6157154, lng: 18.3934951 },
    { lat: 48.6157876, lng: 18.3934324 },
    { lat: 48.6162469, lng: 18.3930145 },
    { lat: 48.6164463, lng: 18.3928252 },
    { lat: 48.6167198, lng: 18.392631 },
    { lat: 48.6168798, lng: 18.3925486 },
    { lat: 48.6170687, lng: 18.3925163 },
    { lat: 48.6171341, lng: 18.39215 },
    { lat: 48.6175304, lng: 18.3925036 },
    { lat: 48.6178072, lng: 18.3925874 },
    { lat: 48.6179475, lng: 18.3925757 },
    { lat: 48.6180389, lng: 18.3925359 },
    { lat: 48.6182792, lng: 18.3923145 },
    { lat: 48.6187763, lng: 18.3918444 },
    { lat: 48.619063, lng: 18.3915644 },
    { lat: 48.6191426, lng: 18.391474 },
    { lat: 48.619274, lng: 18.3917654 },
    { lat: 48.6194113, lng: 18.3920431 },
    { lat: 48.6193595, lng: 18.392186 },
    { lat: 48.6192614, lng: 18.3923671 },
    { lat: 48.6191826, lng: 18.3934772 },
    { lat: 48.6191969, lng: 18.3938565 },
    { lat: 48.6192168, lng: 18.3946128 },
    { lat: 48.6194008, lng: 18.3955246 },
    { lat: 48.6195396, lng: 18.3960479 },
    { lat: 48.6195786, lng: 18.3964252 },
    { lat: 48.6196411, lng: 18.396858 },
    { lat: 48.6196655, lng: 18.3972528 },
    { lat: 48.6196518, lng: 18.3975102 },
    { lat: 48.6196551, lng: 18.3977123 },
    { lat: 48.6197861, lng: 18.3983824 },
    { lat: 48.61987, lng: 18.3990335 },
    { lat: 48.6198377, lng: 18.3996474 },
    { lat: 48.6198675, lng: 18.3996357 },
    { lat: 48.6200116, lng: 18.3996296 },
    { lat: 48.6202023, lng: 18.3999533 },
    { lat: 48.6202803, lng: 18.4001178 },
    { lat: 48.6205551, lng: 18.4001495 },
    { lat: 48.6205946, lng: 18.3997484 },
    { lat: 48.6207778, lng: 18.3992701 },
    { lat: 48.6215844, lng: 18.3976109 },
    { lat: 48.6216534, lng: 18.3983609 },
    { lat: 48.6218715, lng: 18.3983064 },
    { lat: 48.6219016, lng: 18.3986477 },
    { lat: 48.6228899, lng: 18.3983864 },
    { lat: 48.6254099, lng: 18.3977795 },
    { lat: 48.6252783, lng: 18.3992885 },
    { lat: 48.6252535, lng: 18.3993887 },
    { lat: 48.6251673, lng: 18.3995438 },
    { lat: 48.6252056, lng: 18.3995917 },
    { lat: 48.6254362, lng: 18.399356 },
    { lat: 48.6253807, lng: 18.3992545 },
    { lat: 48.6257081, lng: 18.3984032 },
    { lat: 48.6257912, lng: 18.3980749 },
    { lat: 48.6258072, lng: 18.3980775 },
    { lat: 48.6258473, lng: 18.3980075 },
    { lat: 48.6263012, lng: 18.3961974 },
    { lat: 48.6263642, lng: 18.3959326 },
    { lat: 48.6264921, lng: 18.3958996 },
    { lat: 48.6264984, lng: 18.3958724 },
    { lat: 48.626552, lng: 18.3959012 },
    { lat: 48.626541, lng: 18.3959451 },
    { lat: 48.6265456, lng: 18.3959473 },
    { lat: 48.6265262, lng: 18.3960275 },
    { lat: 48.6269959, lng: 18.3969827 },
    { lat: 48.6270202, lng: 18.3969097 },
    { lat: 48.6270993, lng: 18.397057 },
    { lat: 48.6270243, lng: 18.3972666 },
    { lat: 48.6270451, lng: 18.3976605 },
    { lat: 48.6272679, lng: 18.399549 },
    { lat: 48.6274554, lng: 18.4015294 },
    { lat: 48.627403, lng: 18.4019851 },
    { lat: 48.6273246, lng: 18.4022404 },
    { lat: 48.6271729, lng: 18.4024319 },
    { lat: 48.6274053, lng: 18.4030382 },
    { lat: 48.6280406, lng: 18.4026358 },
    { lat: 48.6281636, lng: 18.4026751 },
    { lat: 48.6282266, lng: 18.4029153 },
    { lat: 48.6284025, lng: 18.4038427 },
    { lat: 48.6287679, lng: 18.4036376 },
    { lat: 48.6288453, lng: 18.4036092 },
    { lat: 48.6288604, lng: 18.4034179 },
    { lat: 48.6290851, lng: 18.4047128 },
    { lat: 48.6291683, lng: 18.4051734 },
    { lat: 48.6292053, lng: 18.4053331 },
    { lat: 48.6292151, lng: 18.4053258 },
    { lat: 48.6292979, lng: 18.4056145 },
    { lat: 48.6294588, lng: 18.406155 },
    { lat: 48.6295292, lng: 18.4063615 },
    { lat: 48.6297501, lng: 18.4069283 },
    { lat: 48.6298496, lng: 18.407151 },
    { lat: 48.6301371, lng: 18.4076349 },
    { lat: 48.6301939, lng: 18.4077218 },
    { lat: 48.630317, lng: 18.4078852 },
    { lat: 48.6304758, lng: 18.4080623 },
    { lat: 48.6305695, lng: 18.4081442 },
    { lat: 48.6306699, lng: 18.4082652 },
    { lat: 48.6310971, lng: 18.4085448 },
    { lat: 48.6322257, lng: 18.4093416 },
    { lat: 48.6324244, lng: 18.4094851 },
    { lat: 48.632753, lng: 18.409734 },
    { lat: 48.6330694, lng: 18.4100245 },
    { lat: 48.6334572, lng: 18.4104846 },
    { lat: 48.6338505, lng: 18.410924 },
    { lat: 48.6342996, lng: 18.4115925 },
    { lat: 48.6343888, lng: 18.4117156 },
    { lat: 48.634468, lng: 18.41184 },
    { lat: 48.6345497, lng: 18.4116852 },
    { lat: 48.63475, lng: 18.4114957 },
    { lat: 48.6348191, lng: 18.4113607 },
    { lat: 48.6348951, lng: 18.4111253 },
    { lat: 48.6348785, lng: 18.4109897 },
    { lat: 48.6348288, lng: 18.4107841 },
    { lat: 48.6347676, lng: 18.410416 },
    { lat: 48.6347425, lng: 18.4102073 },
    { lat: 48.6347329, lng: 18.4099132 },
    { lat: 48.6347021, lng: 18.409687 },
    { lat: 48.6346688, lng: 18.4095143 },
    { lat: 48.6346233, lng: 18.4093625 },
    { lat: 48.6344958, lng: 18.4092046 },
    { lat: 48.6344173, lng: 18.4090901 },
    { lat: 48.6343262, lng: 18.4091948 },
    { lat: 48.6334441, lng: 18.408913 },
    { lat: 48.6329671, lng: 18.4086979 },
    { lat: 48.6314988, lng: 18.4073935 },
    { lat: 48.6311121, lng: 18.406709 },
    { lat: 48.6313144, lng: 18.4063516 },
    { lat: 48.6316301, lng: 18.4059423 },
    { lat: 48.6315795, lng: 18.4056449 },
    { lat: 48.6314796, lng: 18.4051262 },
    { lat: 48.6314497, lng: 18.4048539 },
    { lat: 48.631396, lng: 18.4045005 },
    { lat: 48.6313161, lng: 18.4041576 },
    { lat: 48.6310314, lng: 18.4034844 },
    { lat: 48.6314437, lng: 18.4031331 },
    { lat: 48.6317539, lng: 18.4029885 },
    { lat: 48.6316353, lng: 18.4025203 },
    { lat: 48.6321418, lng: 18.4021578 },
    { lat: 48.6321417, lng: 18.4020558 },
    { lat: 48.6324748, lng: 18.4018569 },
    { lat: 48.6328383, lng: 18.4017179 },
    { lat: 48.6331636, lng: 18.4016477 },
    { lat: 48.6341717, lng: 18.4015752 },
    { lat: 48.6344883, lng: 18.4015478 },
    { lat: 48.6351501, lng: 18.4014387 },
    { lat: 48.6350813, lng: 18.400875 },
    { lat: 48.6350165, lng: 18.4000967 },
    { lat: 48.6349673, lng: 18.3986745 },
    { lat: 48.6352939, lng: 18.3986008 },
    { lat: 48.6361624, lng: 18.3982557 },
    { lat: 48.6362655, lng: 18.3982023 },
    { lat: 48.6371485, lng: 18.3977104 },
    { lat: 48.6372882, lng: 18.3981503 },
    { lat: 48.637917, lng: 18.3980361 },
    { lat: 48.6379894, lng: 18.3980305 },
    { lat: 48.6380663, lng: 18.3980246 },
    { lat: 48.6383307, lng: 18.3978947 },
    { lat: 48.6383748, lng: 18.397873 },
    { lat: 48.6387227, lng: 18.3976978 },
    { lat: 48.6392349, lng: 18.3974343 },
    { lat: 48.6399571, lng: 18.3969725 },
    { lat: 48.6404985, lng: 18.3965501 },
    { lat: 48.6410494, lng: 18.3959667 },
    { lat: 48.6414237, lng: 18.3956419 },
    { lat: 48.6415781, lng: 18.3955788 },
    { lat: 48.6423096, lng: 18.3956062 },
    { lat: 48.6425582, lng: 18.3955321 },
    { lat: 48.643035, lng: 18.3953027 },
    { lat: 48.6433714, lng: 18.3952639 },
    { lat: 48.644219, lng: 18.3952908 },
    { lat: 48.6445127, lng: 18.3952171 },
    { lat: 48.6451173, lng: 18.3950745 },
    { lat: 48.6457259, lng: 18.3950848 },
    { lat: 48.6463208, lng: 18.3951328 },
    { lat: 48.6473774, lng: 18.3952442 },
    { lat: 48.6488132, lng: 18.3954376 },
    { lat: 48.6488586, lng: 18.3954449 },
    { lat: 48.6488494, lng: 18.3953619 },
    { lat: 48.6488699, lng: 18.3953435 },
    { lat: 48.6488552, lng: 18.3952837 },
    { lat: 48.6487563, lng: 18.3926636 },
    { lat: 48.6487967, lng: 18.3926203 },
    { lat: 48.649032, lng: 18.3926132 },
    { lat: 48.6490505, lng: 18.392598 },
    { lat: 48.6491594, lng: 18.3918438 },
    { lat: 48.6494069, lng: 18.3917919 },
    { lat: 48.6496336, lng: 18.3918098 },
    { lat: 48.649926, lng: 18.3917922 },
    { lat: 48.6499701, lng: 18.3917937 },
    { lat: 48.6501218, lng: 18.3918436 },
    { lat: 48.6502715, lng: 18.3918787 },
    { lat: 48.6504462, lng: 18.3918743 },
    { lat: 48.6505862, lng: 18.3918576 },
    { lat: 48.6506654, lng: 18.3918618 },
    { lat: 48.6509229, lng: 18.391824 },
    { lat: 48.6508653, lng: 18.390874 },
    { lat: 48.650847, lng: 18.3906905 },
    { lat: 48.6508316, lng: 18.3903146 },
    { lat: 48.6507989, lng: 18.3897893 },
    { lat: 48.6507014, lng: 18.3893182 },
    { lat: 48.6506949, lng: 18.3892402 },
    { lat: 48.6506329, lng: 18.3888999 },
    { lat: 48.6505493, lng: 18.3883957 },
    { lat: 48.6504706, lng: 18.3879565 },
    { lat: 48.6503933, lng: 18.3875421 },
    { lat: 48.6502281, lng: 18.3871279 },
    { lat: 48.6501123, lng: 18.3867744 },
    { lat: 48.6500181, lng: 18.3864389 },
    { lat: 48.6499454, lng: 18.3861488 },
    { lat: 48.649857, lng: 18.3857612 },
    { lat: 48.649628, lng: 18.3846722 },
    { lat: 48.6494293, lng: 18.3835812 },
    { lat: 48.6493524, lng: 18.3831321 },
    { lat: 48.6493328, lng: 18.3829841 },
    { lat: 48.6493187, lng: 18.3829566 },
    { lat: 48.6492062, lng: 18.3829624 },
    { lat: 48.6491186, lng: 18.381798 },
    { lat: 48.649059, lng: 18.3808568 },
    { lat: 48.6489484, lng: 18.3787813 },
    { lat: 48.6489139, lng: 18.3786669 },
    { lat: 48.6491118, lng: 18.3785581 },
    { lat: 48.6492785, lng: 18.3785119 },
    { lat: 48.6496732, lng: 18.3783074 },
    { lat: 48.6501014, lng: 18.378637 },
    { lat: 48.6501823, lng: 18.3786345 },
    { lat: 48.650229, lng: 18.3786025 },
    { lat: 48.6503548, lng: 18.3784719 },
    { lat: 48.6505714, lng: 18.3783094 },
    { lat: 48.6507179, lng: 18.3781266 },
    { lat: 48.65077, lng: 18.3780264 },
    { lat: 48.6506443, lng: 18.3778973 },
    { lat: 48.6505931, lng: 18.3778295 },
    { lat: 48.6506566, lng: 18.3777954 },
    { lat: 48.6507112, lng: 18.3777018 },
    { lat: 48.6506484, lng: 18.3775699 },
    { lat: 48.6508681, lng: 18.3773428 },
    { lat: 48.6508906, lng: 18.377098 },
    { lat: 48.6509242, lng: 18.3769353 },
    { lat: 48.6509754, lng: 18.3769038 },
    { lat: 48.6510646, lng: 18.3768834 },
    { lat: 48.6512794, lng: 18.376992 },
    { lat: 48.6512863, lng: 18.3770721 },
    { lat: 48.6513838, lng: 18.377162 },
    { lat: 48.6514776, lng: 18.3771654 },
    { lat: 48.6515811, lng: 18.3771179 },
    { lat: 48.6516337, lng: 18.3770531 },
    { lat: 48.6516688, lng: 18.3769162 },
    { lat: 48.6517491, lng: 18.376674 },
    { lat: 48.6518248, lng: 18.3765784 },
    { lat: 48.6519379, lng: 18.3765843 },
    { lat: 48.652127, lng: 18.3766347 },
    { lat: 48.6521402, lng: 18.3764478 },
    { lat: 48.6523459, lng: 18.3740613 },
    { lat: 48.652491, lng: 18.3725833 },
    { lat: 48.6525229, lng: 18.3721882 },
    { lat: 48.6526469, lng: 18.3712785 },
    { lat: 48.6529416, lng: 18.3713825 },
    { lat: 48.6532097, lng: 18.3715763 },
    { lat: 48.6532996, lng: 18.3710078 },
    { lat: 48.6535101, lng: 18.3697709 },
    { lat: 48.6536355, lng: 18.3688703 },
    { lat: 48.6537667, lng: 18.3678887 },
    { lat: 48.6537737, lng: 18.3675934 },
    { lat: 48.6538125, lng: 18.3672006 },
    { lat: 48.6538737, lng: 18.3667042 },
    { lat: 48.6539191, lng: 18.3664051 },
    { lat: 48.6541196, lng: 18.365213 },
    { lat: 48.6541647, lng: 18.3649721 },
    { lat: 48.6542696, lng: 18.364326 },
    { lat: 48.6543314, lng: 18.3638235 },
    { lat: 48.6543942, lng: 18.3630722 },
    { lat: 48.6545016, lng: 18.3619708 },
    { lat: 48.6545643, lng: 18.3611799 },
    { lat: 48.654604, lng: 18.3609818 },
    { lat: 48.6546427, lng: 18.3608259 },
    { lat: 48.6547475, lng: 18.3605438 },
    { lat: 48.654834, lng: 18.3602965 },
    { lat: 48.6548803, lng: 18.3601981 },
    { lat: 48.6549005, lng: 18.360116 },
    { lat: 48.6550096, lng: 18.3598479 },
    { lat: 48.655094, lng: 18.3594243 },
    { lat: 48.6556754, lng: 18.3594669 },
    { lat: 48.6561247, lng: 18.3594939 },
    { lat: 48.6568319, lng: 18.359549 },
    { lat: 48.6571202, lng: 18.359561 },
    { lat: 48.6572363, lng: 18.3595393 },
    { lat: 48.6572923, lng: 18.3590304 },
    { lat: 48.6573638, lng: 18.3585559 },
    { lat: 48.6575723, lng: 18.3576888 },
    { lat: 48.6576982, lng: 18.357157 },
    { lat: 48.6578322, lng: 18.3565518 },
    { lat: 48.6581911, lng: 18.3563916 },
    { lat: 48.6593417, lng: 18.3560087 },
    { lat: 48.6596386, lng: 18.3559531 },
    { lat: 48.6600329, lng: 18.3542738 },
    { lat: 48.6602342, lng: 18.3535821 },
    { lat: 48.6606497, lng: 18.3522858 },
    { lat: 48.6608002, lng: 18.3518893 },
    { lat: 48.6610731, lng: 18.3512683 },
    { lat: 48.6612066, lng: 18.3509909 },
    { lat: 48.6601242, lng: 18.3485876 },
    { lat: 48.6598396, lng: 18.3479246 },
    { lat: 48.6592057, lng: 18.3464944 },
    { lat: 48.6588418, lng: 18.345185 },
    { lat: 48.657987, lng: 18.343419 },
    { lat: 48.657604, lng: 18.34243 },
    { lat: 48.657039, lng: 18.34112 },
    { lat: 48.656892, lng: 18.34072 },
    { lat: 48.656615, lng: 18.340055 },
    { lat: 48.656424, lng: 18.339503 },
    { lat: 48.65626, lng: 18.339089 },
    { lat: 48.655876, lng: 18.338341 },
    { lat: 48.655175, lng: 18.337142 },
    { lat: 48.654474, lng: 18.336398 },
    { lat: 48.653447, lng: 18.335846 },
    { lat: 48.653184, lng: 18.33576 },
    { lat: 48.652419, lng: 18.335604 },
    { lat: 48.650905, lng: 18.335157 },
    { lat: 48.650846, lng: 18.335145 },
    { lat: 48.649912, lng: 18.334944 },
    { lat: 48.649647, lng: 18.33485 },
    { lat: 48.649501, lng: 18.334742 },
    { lat: 48.64916, lng: 18.334452 },
    { lat: 48.64902, lng: 18.334322 },
    { lat: 48.6489052, lng: 18.3341909 },
    { lat: 48.6486947, lng: 18.3339505 },
    { lat: 48.648408, lng: 18.333623 },
    { lat: 48.64799, lng: 18.333169 },
    { lat: 48.647698, lng: 18.332893 },
    { lat: 48.647549, lng: 18.332757 },
    { lat: 48.647013, lng: 18.332035 },
    { lat: 48.646125, lng: 18.330875 },
    { lat: 48.645509, lng: 18.329957 },
    { lat: 48.645754, lng: 18.329342 },
    { lat: 48.645972, lng: 18.328877 },
    { lat: 48.646384, lng: 18.328295 },
    { lat: 48.646707, lng: 18.327801 },
    { lat: 48.646643, lng: 18.327684 },
    { lat: 48.647079, lng: 18.326823 },
    { lat: 48.647682, lng: 18.325758 },
    { lat: 48.648139, lng: 18.325047 },
    { lat: 48.648581, lng: 18.324359 },
    { lat: 48.6484072, lng: 18.3237958 },
    { lat: 48.6478985, lng: 18.3225586 },
    { lat: 48.6475035, lng: 18.3210795 },
    { lat: 48.6472065, lng: 18.3198582 },
    { lat: 48.6470418, lng: 18.3191997 },
    { lat: 48.6469545, lng: 18.3188352 },
    { lat: 48.6468724, lng: 18.3185415 },
    { lat: 48.6467709, lng: 18.3182434 },
    { lat: 48.6464694, lng: 18.3176193 },
    { lat: 48.6462751, lng: 18.3172569 },
    { lat: 48.6459285, lng: 18.316634 },
    { lat: 48.6454347, lng: 18.315603 },
    { lat: 48.6452562, lng: 18.3148814 },
    { lat: 48.6450707, lng: 18.3139167 },
    { lat: 48.6450635, lng: 18.3138691 },
    { lat: 48.6450175, lng: 18.3139139 },
    { lat: 48.6442904, lng: 18.3138827 },
    { lat: 48.6435546, lng: 18.3138402 },
    { lat: 48.6426561, lng: 18.3137926 },
    { lat: 48.6424633, lng: 18.3130608 },
    { lat: 48.6423333, lng: 18.3130736 },
    { lat: 48.6421517, lng: 18.312396 },
    { lat: 48.6418899, lng: 18.3124583 },
    { lat: 48.6417591, lng: 18.3120224 },
    { lat: 48.6416376, lng: 18.3118069 },
    { lat: 48.641377, lng: 18.3118084 },
    { lat: 48.640983, lng: 18.3116062 },
    { lat: 48.6406752, lng: 18.3115285 },
    { lat: 48.6403675, lng: 18.3114558 },
    { lat: 48.640177, lng: 18.311418 },
    { lat: 48.6401, lng: 18.3115117 },
    { lat: 48.6374885, lng: 18.3148086 },
    { lat: 48.6371967, lng: 18.3150745 },
    { lat: 48.6370095, lng: 18.3152973 },
    { lat: 48.6369184, lng: 18.3154425 },
    { lat: 48.6368338, lng: 18.3156399 },
    { lat: 48.6366388, lng: 18.3161249 },
    { lat: 48.6364593, lng: 18.3165001 },
    { lat: 48.6363869, lng: 18.316696 },
    { lat: 48.6362906, lng: 18.316987 },
    { lat: 48.6360533, lng: 18.3175398 },
    { lat: 48.6358034, lng: 18.3180286 },
    { lat: 48.6356717, lng: 18.3182129 },
    { lat: 48.6354471, lng: 18.3184508 },
    { lat: 48.6353366, lng: 18.3185332 },
    { lat: 48.6351437, lng: 18.3187927 },
    { lat: 48.6348832, lng: 18.3192351 },
    { lat: 48.6347857, lng: 18.3194631 },
    { lat: 48.634419, lng: 18.3201239 },
    { lat: 48.6343805, lng: 18.3202002 },
    { lat: 48.6334207, lng: 18.3213057 },
    { lat: 48.632971, lng: 18.3217906 },
    { lat: 48.6320697, lng: 18.3225501 },
    { lat: 48.6309367, lng: 18.3232066 },
    { lat: 48.6307912, lng: 18.3234051 },
    { lat: 48.6284236, lng: 18.3250576 },
    { lat: 48.6280704, lng: 18.3253534 },
    { lat: 48.6279093, lng: 18.3255161 },
    { lat: 48.6269709, lng: 18.3265994 },
    { lat: 48.6268046, lng: 18.3267517 },
    { lat: 48.6261854, lng: 18.327296 },
    { lat: 48.6256647, lng: 18.3277453 },
    { lat: 48.6251492, lng: 18.3281461 },
    { lat: 48.6244365, lng: 18.3286717 },
    { lat: 48.6240494, lng: 18.32901 },
    { lat: 48.6239021, lng: 18.3290978 },
    { lat: 48.6237384, lng: 18.3292551 },
    { lat: 48.6223575, lng: 18.3302977 },
    { lat: 48.6215905, lng: 18.3307989 },
    { lat: 48.6212994, lng: 18.3309775 },
    { lat: 48.6218921, lng: 18.3325432 },
    { lat: 48.6205587, lng: 18.3342864 },
    { lat: 48.6204164, lng: 18.3331846 },
    { lat: 48.6196841, lng: 18.3335016 },
    { lat: 48.6199337, lng: 18.3361444 },
    { lat: 48.6199334, lng: 18.3375205 },
    { lat: 48.6198616, lng: 18.339107 },
    { lat: 48.6199561, lng: 18.3395239 },
    { lat: 48.620061, lng: 18.3409226 },
    { lat: 48.6182715, lng: 18.3429811 },
    { lat: 48.6180423, lng: 18.3433984 },
    { lat: 48.6179161, lng: 18.3439035 },
    { lat: 48.6179348, lng: 18.3447127 },
    { lat: 48.6180689, lng: 18.3452136 },
    { lat: 48.6195501, lng: 18.3488343 },
    { lat: 48.6201748, lng: 18.3501286 },
    { lat: 48.6202955, lng: 18.3502482 },
    { lat: 48.6204485, lng: 18.3508017 },
    { lat: 48.6205264, lng: 18.3510823 },
    { lat: 48.6206403, lng: 18.3513402 },
    { lat: 48.6206768, lng: 18.3514693 },
    { lat: 48.62082, lng: 18.3517066 },
    { lat: 48.6207551, lng: 18.3518978 },
    { lat: 48.6202781, lng: 18.3516812 },
    { lat: 48.6202266, lng: 18.3517273 },
    { lat: 48.6204292, lng: 18.3532815 },
    { lat: 48.619997, lng: 18.3561083 },
    { lat: 48.6200115, lng: 18.3569883 },
    { lat: 48.6201802, lng: 18.3584237 },
    { lat: 48.6205496, lng: 18.3599779 },
    { lat: 48.6206037, lng: 18.3610569 },
    { lat: 48.6205196, lng: 18.3619702 },
    { lat: 48.6202142, lng: 18.3633807 },
    { lat: 48.6185471, lng: 18.369836 },
    { lat: 48.6179642, lng: 18.3705494 },
    { lat: 48.6176176, lng: 18.3707638 },
    { lat: 48.6172399, lng: 18.3712698 },
    { lat: 48.616035, lng: 18.3715132 },
    { lat: 48.614793, lng: 18.3713979 },
    { lat: 48.6132376, lng: 18.3716246 },
    { lat: 48.6114501, lng: 18.3723584 },
    { lat: 48.6112233, lng: 18.3727813 },
    { lat: 48.6109021, lng: 18.3728683 },
    { lat: 48.6099218, lng: 18.3735349 },
    { lat: 48.6093336, lng: 18.3738096 },
    { lat: 48.6090062, lng: 18.3740468 },
    { lat: 48.6087952, lng: 18.3750085 },
    { lat: 48.6085016, lng: 18.3759696 },
    { lat: 48.6083885, lng: 18.3789826 },
    { lat: 48.6082663, lng: 18.3801842 },
    { lat: 48.6081455, lng: 18.3823778 },
    { lat: 48.6082221, lng: 18.3832001 },
    { lat: 48.6081926, lng: 18.3842003 },
  ],
  Bošany: [
    { lat: 48.590603, lng: 18.239564 },
    { lat: 48.590542, lng: 18.239508 },
    { lat: 48.589443, lng: 18.238586 },
    { lat: 48.589395, lng: 18.238546 },
    { lat: 48.58933, lng: 18.23849 },
    { lat: 48.589266, lng: 18.238435 },
    { lat: 48.589184, lng: 18.238365 },
    { lat: 48.58904, lng: 18.238243 },
    { lat: 48.588522, lng: 18.237802 },
    { lat: 48.587835, lng: 18.237215 },
    { lat: 48.588957, lng: 18.234532 },
    { lat: 48.58929, lng: 18.234006 },
    { lat: 48.5893, lng: 18.233993 },
    { lat: 48.589304, lng: 18.233988 },
    { lat: 48.589322, lng: 18.233964 },
    { lat: 48.589328, lng: 18.233955 },
    { lat: 48.589333, lng: 18.23395 },
    { lat: 48.589371, lng: 18.233899 },
    { lat: 48.589453, lng: 18.233772 },
    { lat: 48.58733, lng: 18.230494 },
    { lat: 48.586333, lng: 18.228953 },
    { lat: 48.585701, lng: 18.227986 },
    { lat: 48.585005, lng: 18.226911 },
    { lat: 48.584373, lng: 18.22595 },
    { lat: 48.583672, lng: 18.22489 },
    { lat: 48.582793, lng: 18.223487 },
    { lat: 48.582447, lng: 18.222933 },
    { lat: 48.582435, lng: 18.222916 },
    { lat: 48.582297, lng: 18.222695 },
    { lat: 48.582259, lng: 18.222634 },
    { lat: 48.581871, lng: 18.221991 },
    { lat: 48.579143, lng: 18.217861 },
    { lat: 48.576791, lng: 18.214327 },
    { lat: 48.576334, lng: 18.214473 },
    { lat: 48.575391, lng: 18.214699 },
    { lat: 48.57487, lng: 18.214811 },
    { lat: 48.57474, lng: 18.214838 },
    { lat: 48.574156, lng: 18.214964 },
    { lat: 48.57413, lng: 18.214967 },
    { lat: 48.57404, lng: 18.21498 },
    { lat: 48.57383, lng: 18.21501 },
    { lat: 48.573794, lng: 18.215015 },
    { lat: 48.573716, lng: 18.215416 },
    { lat: 48.573792, lng: 18.215898 },
    { lat: 48.573794, lng: 18.216251 },
    { lat: 48.573796, lng: 18.21631 },
    { lat: 48.573797, lng: 18.216467 },
    { lat: 48.573837, lng: 18.216683 },
    { lat: 48.573718, lng: 18.216708 },
    { lat: 48.573721, lng: 18.216772 },
    { lat: 48.573762, lng: 18.216802 },
    { lat: 48.573721, lng: 18.216994 },
    { lat: 48.5737, lng: 18.217277 },
    { lat: 48.573686, lng: 18.217374 },
    { lat: 48.573654, lng: 18.217627 },
    { lat: 48.573363, lng: 18.217652 },
    { lat: 48.573147, lng: 18.218044 },
    { lat: 48.573013, lng: 18.217962 },
    { lat: 48.572725, lng: 18.217787 },
    { lat: 48.572635, lng: 18.218126 },
    { lat: 48.572518, lng: 18.218499 },
    { lat: 48.572351, lng: 18.218913 },
    { lat: 48.572193, lng: 18.218995 },
    { lat: 48.571215, lng: 18.217801 },
    { lat: 48.571169, lng: 18.218117 },
    { lat: 48.571025, lng: 18.218225 },
    { lat: 48.571043, lng: 18.218621 },
    { lat: 48.570983, lng: 18.218769 },
    { lat: 48.570904, lng: 18.218766 },
    { lat: 48.570829, lng: 18.218834 },
    { lat: 48.570759, lng: 18.219053 },
    { lat: 48.570729, lng: 18.219244 },
    { lat: 48.570674, lng: 18.219375 },
    { lat: 48.570468, lng: 18.219402 },
    { lat: 48.570419, lng: 18.219512 },
    { lat: 48.570368, lng: 18.219608 },
    { lat: 48.570312, lng: 18.21967 },
    { lat: 48.570219, lng: 18.219652 },
    { lat: 48.570166, lng: 18.219516 },
    { lat: 48.570111, lng: 18.219525 },
    { lat: 48.570044, lng: 18.219629 },
    { lat: 48.569998, lng: 18.219769 },
    { lat: 48.569711, lng: 18.21995 },
    { lat: 48.5692, lng: 18.221012 },
    { lat: 48.568472, lng: 18.222588 },
    { lat: 48.568384, lng: 18.222906 },
    { lat: 48.568162, lng: 18.223515 },
    { lat: 48.56784, lng: 18.223512 },
    { lat: 48.567504, lng: 18.223598 },
    { lat: 48.567334, lng: 18.223585 },
    { lat: 48.567235, lng: 18.223536 },
    { lat: 48.567006, lng: 18.223485 },
    { lat: 48.566935, lng: 18.223516 },
    { lat: 48.566822, lng: 18.223715 },
    { lat: 48.56675, lng: 18.223989 },
    { lat: 48.567189, lng: 18.224749 },
    { lat: 48.567119, lng: 18.225123 },
    { lat: 48.56667, lng: 18.224907 },
    { lat: 48.56646, lng: 18.225111 },
    { lat: 48.566718, lng: 18.225641 },
    { lat: 48.566772, lng: 18.225853 },
    { lat: 48.566657, lng: 18.226061 },
    { lat: 48.566741, lng: 18.226362 },
    { lat: 48.566823, lng: 18.226421 },
    { lat: 48.56665, lng: 18.226603 },
    { lat: 48.566714, lng: 18.22711 },
    { lat: 48.566786, lng: 18.227241 },
    { lat: 48.566753, lng: 18.227475 },
    { lat: 48.566624, lng: 18.227658 },
    { lat: 48.566592, lng: 18.227607 },
    { lat: 48.566422, lng: 18.227777 },
    { lat: 48.56644, lng: 18.227814 },
    { lat: 48.567667, lng: 18.229429 },
    { lat: 48.567349, lng: 18.230106 },
    { lat: 48.567488, lng: 18.230241 },
    { lat: 48.568923, lng: 18.232075 },
    { lat: 48.567352, lng: 18.235213 },
    { lat: 48.565695, lng: 18.233986 },
    { lat: 48.565577, lng: 18.233877 },
    { lat: 48.562685, lng: 18.232162 },
    { lat: 48.562599, lng: 18.232111 },
    { lat: 48.56212, lng: 18.231827 },
    { lat: 48.561972, lng: 18.232779 },
    { lat: 48.561932, lng: 18.232993 },
    { lat: 48.56189, lng: 18.233176 },
    { lat: 48.561852, lng: 18.233151 },
    { lat: 48.561801, lng: 18.233111 },
    { lat: 48.561765, lng: 18.233084 },
    { lat: 48.55696, lng: 18.228253 },
    { lat: 48.556897, lng: 18.228195 },
    { lat: 48.556754, lng: 18.228091 },
    { lat: 48.556662, lng: 18.228025 },
    { lat: 48.556397, lng: 18.22782 },
    { lat: 48.55617, lng: 18.227678 },
    { lat: 48.555999, lng: 18.227602 },
    { lat: 48.555758, lng: 18.227545 },
    { lat: 48.555738, lng: 18.227541 },
    { lat: 48.555783, lng: 18.227249 },
    { lat: 48.554969, lng: 18.226803 },
    { lat: 48.554898, lng: 18.226768 },
    { lat: 48.554816, lng: 18.227225 },
    { lat: 48.554736, lng: 18.227687 },
    { lat: 48.553615, lng: 18.229062 },
    { lat: 48.553504, lng: 18.229182 },
    { lat: 48.553317, lng: 18.229345 },
    { lat: 48.553092, lng: 18.229514 },
    { lat: 48.552813, lng: 18.229745 },
    { lat: 48.552574, lng: 18.229966 },
    { lat: 48.552035, lng: 18.230589 },
    { lat: 48.551831, lng: 18.230852 },
    { lat: 48.551629, lng: 18.231154 },
    { lat: 48.551444, lng: 18.231499 },
    { lat: 48.55142, lng: 18.231472 },
    { lat: 48.551376, lng: 18.231416 },
    { lat: 48.551352, lng: 18.231384 },
    { lat: 48.551339, lng: 18.231439 },
    { lat: 48.5510345, lng: 18.2321158 },
    { lat: 48.551061, lng: 18.23214 },
    { lat: 48.55111, lng: 18.232184 },
    { lat: 48.551135, lng: 18.232206 },
    { lat: 48.552167, lng: 18.233125 },
    { lat: 48.552233, lng: 18.233182 },
    { lat: 48.552484, lng: 18.233389 },
    { lat: 48.552875, lng: 18.233766 },
    { lat: 48.553354, lng: 18.234265 },
    { lat: 48.553847, lng: 18.234821 },
    { lat: 48.554516, lng: 18.235623 },
    { lat: 48.55487, lng: 18.236105 },
    { lat: 48.555596, lng: 18.23712 },
    { lat: 48.555962, lng: 18.237665 },
    { lat: 48.555989, lng: 18.237723 },
    { lat: 48.555483, lng: 18.238103 },
    { lat: 48.555266, lng: 18.238307 },
    { lat: 48.554675, lng: 18.238891 },
    { lat: 48.554436, lng: 18.239214 },
    { lat: 48.554161, lng: 18.239731 },
    { lat: 48.553953, lng: 18.24013 },
    { lat: 48.553709, lng: 18.240728 },
    { lat: 48.553159, lng: 18.241811 },
    { lat: 48.553009, lng: 18.242077 },
    { lat: 48.553041, lng: 18.242123 },
    { lat: 48.553464, lng: 18.242513 },
    { lat: 48.553743, lng: 18.242839 },
    { lat: 48.554285, lng: 18.243535 },
    { lat: 48.555045, lng: 18.244662 },
    { lat: 48.555077, lng: 18.244708 },
    { lat: 48.555183, lng: 18.244611 },
    { lat: 48.555326, lng: 18.244331 },
    { lat: 48.555456, lng: 18.243916 },
    { lat: 48.555529, lng: 18.243728 },
    { lat: 48.555609, lng: 18.2434 },
    { lat: 48.555777, lng: 18.243579 },
    { lat: 48.555876, lng: 18.243458 },
    { lat: 48.55615, lng: 18.24373 },
    { lat: 48.555734, lng: 18.244717 },
    { lat: 48.556638, lng: 18.245655 },
    { lat: 48.556378, lng: 18.246417 },
    { lat: 48.556158, lng: 18.247294 },
    { lat: 48.555867, lng: 18.248699 },
    { lat: 48.555891, lng: 18.248725 },
    { lat: 48.555913, lng: 18.248749 },
    { lat: 48.556484, lng: 18.249378 },
    { lat: 48.556524, lng: 18.249422 },
    { lat: 48.556474, lng: 18.249538 },
    { lat: 48.556397, lng: 18.249866 },
    { lat: 48.556315, lng: 18.249937 },
    { lat: 48.556144, lng: 18.249887 },
    { lat: 48.556037, lng: 18.249905 },
    { lat: 48.555799, lng: 18.250031 },
    { lat: 48.555603, lng: 18.250209 },
    { lat: 48.555564, lng: 18.250373 },
    { lat: 48.555582, lng: 18.250423 },
    { lat: 48.555654, lng: 18.250465 },
    { lat: 48.555704, lng: 18.25057 },
    { lat: 48.555669, lng: 18.250693 },
    { lat: 48.555294, lng: 18.251422 },
    { lat: 48.555346, lng: 18.25151 },
    { lat: 48.556037, lng: 18.252715 },
    { lat: 48.5563501, lng: 18.2522033 },
    { lat: 48.5573437, lng: 18.2534919 },
    { lat: 48.5591603, lng: 18.2556827 },
    { lat: 48.5619968, lng: 18.2592888 },
    { lat: 48.562291, lng: 18.2591459 },
    { lat: 48.5625006, lng: 18.2590798 },
    { lat: 48.5626541, lng: 18.2590603 },
    { lat: 48.5627529, lng: 18.2590346 },
    { lat: 48.5627712, lng: 18.2590789 },
    { lat: 48.562825, lng: 18.259052 },
    { lat: 48.5628504, lng: 18.2592403 },
    { lat: 48.5628359, lng: 18.2596398 },
    { lat: 48.5627612, lng: 18.2599875 },
    { lat: 48.5627328, lng: 18.2602224 },
    { lat: 48.5626539, lng: 18.2605593 },
    { lat: 48.562516, lng: 18.260715 },
    { lat: 48.5625034, lng: 18.2607673 },
    { lat: 48.5615795, lng: 18.2618785 },
    { lat: 48.5619357, lng: 18.2636682 },
    { lat: 48.561936, lng: 18.2637148 },
    { lat: 48.5616772, lng: 18.2643579 },
    { lat: 48.5616922, lng: 18.2643442 },
    { lat: 48.5618076, lng: 18.2643894 },
    { lat: 48.5619741, lng: 18.2644188 },
    { lat: 48.5621703, lng: 18.2645554 },
    { lat: 48.5623441, lng: 18.2647737 },
    { lat: 48.5625137, lng: 18.2649376 },
    { lat: 48.5628434, lng: 18.2650794 },
    { lat: 48.5628451, lng: 18.2652876 },
    { lat: 48.5627859, lng: 18.2655175 },
    { lat: 48.5625956, lng: 18.2658211 },
    { lat: 48.562357, lng: 18.266147 },
    { lat: 48.5615359, lng: 18.2671215 },
    { lat: 48.5616544, lng: 18.267259 },
    { lat: 48.5619832, lng: 18.267564 },
    { lat: 48.5620466, lng: 18.2676459 },
    { lat: 48.5622663, lng: 18.2678953 },
    { lat: 48.5623471, lng: 18.2680074 },
    { lat: 48.5625778, lng: 18.2683896 },
    { lat: 48.5627146, lng: 18.2686743 },
    { lat: 48.5628571, lng: 18.2690164 },
    { lat: 48.5628936, lng: 18.2691595 },
    { lat: 48.5629425, lng: 18.2694652 },
    { lat: 48.5628955, lng: 18.2694709 },
    { lat: 48.5628568, lng: 18.2696818 },
    { lat: 48.5627726, lng: 18.2699054 },
    { lat: 48.5627132, lng: 18.2700125 },
    { lat: 48.5626676, lng: 18.2700636 },
    { lat: 48.562556, lng: 18.2699263 },
    { lat: 48.5625247, lng: 18.2699129 },
    { lat: 48.5614794, lng: 18.2710725 },
    { lat: 48.5616637, lng: 18.2714148 },
    { lat: 48.5616741, lng: 18.2714765 },
    { lat: 48.5618997, lng: 18.2714362 },
    { lat: 48.5619817, lng: 18.2712532 },
    { lat: 48.5619885, lng: 18.2711348 },
    { lat: 48.5620612, lng: 18.2710634 },
    { lat: 48.5629205, lng: 18.2702849 },
    { lat: 48.5629665, lng: 18.2702411 },
    { lat: 48.5627667, lng: 18.2718786 },
    { lat: 48.5627588, lng: 18.2720958 },
    { lat: 48.562681, lng: 18.2725718 },
    { lat: 48.5625283, lng: 18.2730212 },
    { lat: 48.562403, lng: 18.2733566 },
    { lat: 48.5623023, lng: 18.2735684 },
    { lat: 48.5623609, lng: 18.2735466 },
    { lat: 48.5631182, lng: 18.2732358 },
    { lat: 48.563646, lng: 18.2729719 },
    { lat: 48.5638852, lng: 18.2728134 },
    { lat: 48.5641843, lng: 18.2725599 },
    { lat: 48.5645162, lng: 18.272244 },
    { lat: 48.5645644, lng: 18.2722025 },
    { lat: 48.5645532, lng: 18.2721626 },
    { lat: 48.564664, lng: 18.272087 },
    { lat: 48.5649594, lng: 18.2717271 },
    { lat: 48.5650104, lng: 18.271615 },
    { lat: 48.5650621, lng: 18.271137 },
    { lat: 48.5651083, lng: 18.2709144 },
    { lat: 48.5651752, lng: 18.2708021 },
    { lat: 48.5654363, lng: 18.2705132 },
    { lat: 48.5655079, lng: 18.2703842 },
    { lat: 48.5655425, lng: 18.2702205 },
    { lat: 48.565568, lng: 18.2698542 },
    { lat: 48.5656055, lng: 18.2695633 },
    { lat: 48.5666158, lng: 18.2687801 },
    { lat: 48.5695367, lng: 18.2665194 },
    { lat: 48.5696066, lng: 18.2664611 },
    { lat: 48.5699206, lng: 18.266606 },
    { lat: 48.5701506, lng: 18.2667569 },
    { lat: 48.570282, lng: 18.2669051 },
    { lat: 48.5704628, lng: 18.2672273 },
    { lat: 48.5706075, lng: 18.2676279 },
    { lat: 48.5706346, lng: 18.267748 },
    { lat: 48.5706818, lng: 18.2689511 },
    { lat: 48.5708078, lng: 18.2701682 },
    { lat: 48.570853, lng: 18.2706258 },
    { lat: 48.5709753, lng: 18.2713435 },
    { lat: 48.5709908, lng: 18.2715571 },
    { lat: 48.5709846, lng: 18.2717834 },
    { lat: 48.5709535, lng: 18.27191 },
    { lat: 48.570829, lng: 18.2722592 },
    { lat: 48.570711, lng: 18.2724676 },
    { lat: 48.5706054, lng: 18.2725925 },
    { lat: 48.5703667, lng: 18.272819 },
    { lat: 48.5700296, lng: 18.2731024 },
    { lat: 48.5696105, lng: 18.2735479 },
    { lat: 48.5694869, lng: 18.2737342 },
    { lat: 48.5693472, lng: 18.2739967 },
    { lat: 48.569228, lng: 18.2742825 },
    { lat: 48.5689394, lng: 18.2751172 },
    { lat: 48.5687828, lng: 18.2756348 },
    { lat: 48.5688545, lng: 18.2756262 },
    { lat: 48.5750815, lng: 18.2746696 },
    { lat: 48.5751527, lng: 18.2759381 },
    { lat: 48.5778965, lng: 18.2752607 },
    { lat: 48.5779073, lng: 18.2752725 },
    { lat: 48.5782982, lng: 18.278587 },
    { lat: 48.5783953, lng: 18.2805286 },
    { lat: 48.5794378, lng: 18.2799924 },
    { lat: 48.5800469, lng: 18.2795534 },
    { lat: 48.5820674, lng: 18.2780988 },
    { lat: 48.5826146, lng: 18.2774282 },
    { lat: 48.5831849, lng: 18.2765651 },
    { lat: 48.5840759, lng: 18.2751521 },
    { lat: 48.5843635, lng: 18.2746352 },
    { lat: 48.58455, lng: 18.2744222 },
    { lat: 48.5846302, lng: 18.2743658 },
    { lat: 48.5850099, lng: 18.2743576 },
    { lat: 48.5854943, lng: 18.274343 },
    { lat: 48.5857586, lng: 18.2743665 },
    { lat: 48.5861718, lng: 18.2743326 },
    { lat: 48.5864104, lng: 18.2742614 },
    { lat: 48.5891747, lng: 18.2727956 },
    { lat: 48.589188, lng: 18.2727885 },
    { lat: 48.5892212, lng: 18.2727771 },
    { lat: 48.5892711, lng: 18.27276 },
    { lat: 48.5892954, lng: 18.2727518 },
    { lat: 48.5893257, lng: 18.2727308 },
    { lat: 48.5895884, lng: 18.2725485 },
    { lat: 48.5904935, lng: 18.2721382 },
    { lat: 48.5909346, lng: 18.2719756 },
    { lat: 48.5919872, lng: 18.2712029 },
    { lat: 48.5921594, lng: 18.2710384 },
    { lat: 48.5923887, lng: 18.2708422 },
    { lat: 48.5926018, lng: 18.2706617 },
    { lat: 48.5928223, lng: 18.2704734 },
    { lat: 48.5930559, lng: 18.2702745 },
    { lat: 48.5932283, lng: 18.270511 },
    { lat: 48.5932733, lng: 18.2706662 },
    { lat: 48.5933877, lng: 18.2708724 },
    { lat: 48.5935102, lng: 18.2709809 },
    { lat: 48.5935697, lng: 18.2710335 },
    { lat: 48.5937333, lng: 18.2706173 },
    { lat: 48.5937522, lng: 18.2705771 },
    { lat: 48.5935032, lng: 18.2702864 },
    { lat: 48.5935494, lng: 18.2702248 },
    { lat: 48.5948189, lng: 18.2685265 },
    { lat: 48.5961894, lng: 18.2666591 },
    { lat: 48.5974364, lng: 18.2649817 },
    { lat: 48.5972415, lng: 18.2645706 },
    { lat: 48.5964327, lng: 18.2621034 },
    { lat: 48.5959055, lng: 18.2605025 },
    { lat: 48.5956626, lng: 18.2597862 },
    { lat: 48.5948607, lng: 18.2573007 },
    { lat: 48.5941445, lng: 18.2551174 },
    { lat: 48.5941332, lng: 18.2549777 },
    { lat: 48.5942063, lng: 18.2544403 },
    { lat: 48.59438, lng: 18.2539872 },
    { lat: 48.5944861, lng: 18.2537306 },
    { lat: 48.5947571, lng: 18.2532014 },
    { lat: 48.5953468, lng: 18.2520903 },
    { lat: 48.5954883, lng: 18.2519168 },
    { lat: 48.5960117, lng: 18.2510688 },
    { lat: 48.5964807, lng: 18.2503099 },
    { lat: 48.5964876, lng: 18.2502366 },
    { lat: 48.596504, lng: 18.2502049 },
    { lat: 48.5965213, lng: 18.250172 },
    { lat: 48.5965332, lng: 18.2501248 },
    { lat: 48.5965749, lng: 18.249949 },
    { lat: 48.5966589, lng: 18.2495984 },
    { lat: 48.5966759, lng: 18.2495438 },
    { lat: 48.596877, lng: 18.2489341 },
    { lat: 48.5969094, lng: 18.2488311 },
    { lat: 48.5969105, lng: 18.2488299 },
    { lat: 48.5969603, lng: 18.2486762 },
    { lat: 48.5971731, lng: 18.248019 },
    { lat: 48.5973286, lng: 18.2475421 },
    { lat: 48.5974522, lng: 18.2474544 },
    { lat: 48.598936, lng: 18.243997 },
    { lat: 48.598942, lng: 18.243991 },
    { lat: 48.597811, lng: 18.243569 },
    { lat: 48.595194, lng: 18.243434 },
    { lat: 48.593434, lng: 18.241939 },
    { lat: 48.593271, lng: 18.241799 },
    { lat: 48.593026, lng: 18.241595 },
    { lat: 48.592938, lng: 18.24152 },
    { lat: 48.592779, lng: 18.241398 },
    { lat: 48.592541, lng: 18.241186 },
    { lat: 48.592423, lng: 18.241085 },
    { lat: 48.592369, lng: 18.24104 },
    { lat: 48.592282, lng: 18.240967 },
    { lat: 48.592251, lng: 18.240941 },
    { lat: 48.591973, lng: 18.240706 },
    { lat: 48.591932, lng: 18.240671 },
    { lat: 48.590603, lng: 18.239564 },
  ],
};

export default UnitsPartizánske;
