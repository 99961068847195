import { Fragment } from "react";
import { Marker } from "@react-google-maps/api";
import ForSaleFlatDark from "../../Images/Pins/ForSaleFlatDark.png";
import ForSaleDark from "../../Images/Pins/ForSaleDark.png";
import ForSaleLandDark from "../../Images/Pins/ForSaleLandDark.png";

export default function ForSalePin({ visibilityOfMarkers, responseForSale, currentItem, filterSale }) {
  const obj = () => {
    if (filterSale.length === 0) {
      return responseForSale?.items;
    } else {
      return filterSale?.items;
    }
  };
  return visibilityOfMarkers === "singleForSalePin" ? (
    <>
      {obj()
        ?.filter((items, item) => item === currentItem)
        .map((forSaleItem, index) => (
          <Fragment key={index}>
            <Marker
              key={index}
              position={{ lat: Number(forSaleItem?.lat), lng: Number(forSaleItem?.lng) }}
              icon={{
                url: JSON.parse(forSaleItem?.parameters).type.includes("byt") ? ForSaleFlatDark : ForSaleDark,
                scaledSize: new window.google.maps.Size(50, 50),
              }}
            ></Marker>
          </Fragment>
        ))}
    </>
  ) : null;
}
