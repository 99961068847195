import { motion } from "framer-motion";
import parameters from "../../../Images/parameters.png";

export default function Parameters({ inputValues, setInputValues }) {
  return (
    <motion.div onClick={(e) => e.stopPropagation()} className="w-full flex flex-col items-start pt-1">
      <p className="font-semibold flex flex-row items-center gap-1">
        <img src={parameters} alt="" className="w- h-5" />
        Parametre
      </p>
      <div className="flex flex-row items-center w-full py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="w-full flex flex-col gap-2">
          <div className="flex flex-row gap-1 w-full items-center pb-2 border-b border-opacity-50 border-b-sky-500">
            <p>Rozloha: </p>
            <input
              placeholder="100"
              value={inputValues?.area || ""}
              onChange={(e) => setInputValues({ ...inputValues, area: e.target.value })}
              type="number"
              className="w-4/5 h-6 rounded-lg bg-sky-100 placeholder:text-gray-500 placeholder:font-normal border border-opacity-50 border-sky-500"
            />
            m²
          </div>
          <div className="flex flex-row gap-1 w-full items-center pb-2 border-b border-opacity-50 border-b-sky-500">
            <p>Stav: </p>
            <input
              placeholder="Kompletná rekonštrukcia"
              value={inputValues?.condition || ""}
              onChange={(e) => setInputValues({ ...inputValues, condition: e.target.value })}
              className="w-4/5 h-6 rounded-lg bg-sky-100 placeholder:text-gray-500 placeholder:font-normal border border-opacity-50 border-sky-500"
            />
          </div>
          <div className="flex flex-row gap-1 w-full items-center">
            <p>Typ: </p>
            <input
              placeholder="Dom"
              value={inputValues?.type || ""}
              onChange={(e) => setInputValues({ ...inputValues, type: e.target.value })}
              className="w-4/5 h-6 rounded-lg bg-sky-100 placeholder:text-gray-500 placeholder:font-normal border border-opacity-50 border-sky-500"
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
