import clsx from "clsx";
import { motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";
import { activityService } from "../../services/activityService";

export default function RScrollMenu({ showMenu, subMenu, size }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const saveActivity = async (activity) => {
    const email = JSON.parse(localStorage.getItem("userData"))?.m;
    const request = JSON.parse(JSON.stringify({ email: email, activityId: activity }));
    return await activityService.saveActivity(request);
  };

  return (
    <motion.div
      className={clsx("flex flex-col items-start overflow-x-scroll", {
        "no-scrollbar w-auto": isSmallDevice,
        "w-full": !isSmallDevice && size.full,
      })}
    >
      <div
        className={clsx("text-base w-auto flex items-center font-semibold flex-row", {
          "w-auto": isSmallDevice || !size.full,
          "w-full": !isSmallDevice && size.full,
        })}
      >
        <button
          onClick={(e) => {
            showMenu("lv");
            e.stopPropagation();
            saveActivity("5");
          }}
          className={clsx("py-1 border-b-2 border-gray-300", {
            "border-b-2 !border-sky-500 text-sky-500": subMenu?.lv,
            "w-24": isSmallDevice || !size.full,
            "w-[16.6666%]": !isSmallDevice && size.full,
          })}
        >
          Informácie
        </button>
        <button
          onClick={(e) => {
            showMenu("ds");
            e.stopPropagation();
            saveActivity("4");
          }}
          className={clsx("py-1 border-b-2 border-gray-300", {
            "border-b-2 !border-sky-500 text-sky-500": subMenu?.disp,
            "w-32": isSmallDevice || !size.full,
            "w-[16.6666%]": !isSmallDevice && size.full,
          })}
        >
          List Vlastnictva
        </button>
        <button
          onClick={(e) => {
            showMenu("in");
            e.stopPropagation();
            saveActivity("2");
          }}
          className={clsx("py-1 border-b-2 border-gray-300", {
            "border-b-2 !border-sky-500 text-sky-500": subMenu?.interest,
            "w-32": isSmallDevice || !size.full,
            "w-[16.6666%]": !isSmallDevice && size.full,
          })}
        >
          Mám záujem
        </button>
        <button
          onClick={(e) => {
            showMenu("ow");
            e.stopPropagation();
            saveActivity("1");
          }}
          className={clsx("py-1 border-b-2 border-gray-300", {
            "border-b-2 !border-sky-500 text-sky-500": subMenu?.owner,
            "w-32": isSmallDevice || !size.full,
            "w-[16.6666%]": !isSmallDevice && size.full,
          })}
        >
          Som vlastník
        </button>
        <button
          onClick={(e) => {
            showMenu("ra");
            e.stopPropagation();
            saveActivity("6");
          }}
          className={clsx("py-1 border-b-2 border-gray-300", {
            "border-b-2 !border-sky-500 text-sky-500": subMenu?.rating,
            "w-24": isSmallDevice || !size.full,
            "w-[16.6666%]": !isSmallDevice && size.full,
          })}
        >
          Hodnotiť
        </button>
        <button
          onClick={(e) => {
            showMenu("sh");
            e.stopPropagation();
            saveActivity("3");
          }}
          className={clsx("py-1 border-b-2 border-gray-300", {
            "border-b-2 !border-sky-500 text-sky-500": subMenu?.share,
            "w-24": isSmallDevice || !size.full,
            "w-[16.6666%]": !isSmallDevice && size.full,
          })}
        >
          Zdielať
        </button>
      </div>
    </motion.div>
  );
}
