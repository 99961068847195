const UnitsTvrdošín = {
  Brezovica: [
    { lat: 49.3212909, lng: 19.6399553 },
    { lat: 49.3212605, lng: 19.6400376 },
    { lat: 49.321169, lng: 19.6402085 },
    { lat: 49.3207812, lng: 19.6409873 },
    { lat: 49.3203532, lng: 19.6421047 },
    { lat: 49.3201959, lng: 19.6423962 },
    { lat: 49.319989, lng: 19.6426853 },
    { lat: 49.3195224, lng: 19.6431086 },
    { lat: 49.3193345, lng: 19.6432942 },
    { lat: 49.3192497, lng: 19.6433622 },
    { lat: 49.3189824, lng: 19.6435315 },
    { lat: 49.3188866, lng: 19.6435873 },
    { lat: 49.3188877, lng: 19.6436104 },
    { lat: 49.3186923, lng: 19.6436978 },
    { lat: 49.3185074, lng: 19.6436971 },
    { lat: 49.3180781, lng: 19.6435669 },
    { lat: 49.317969, lng: 19.6435446 },
    { lat: 49.317751, lng: 19.6435301 },
    { lat: 49.3176102, lng: 19.6435346 },
    { lat: 49.3174684, lng: 19.6434619 },
    { lat: 49.317273, lng: 19.6433984 },
    { lat: 49.3171099, lng: 19.6433041 },
    { lat: 49.3169245, lng: 19.6431854 },
    { lat: 49.3166622, lng: 19.6430617 },
    { lat: 49.3165048, lng: 19.6429939 },
    { lat: 49.3164433, lng: 19.64298 },
    { lat: 49.316209, lng: 19.6429574 },
    { lat: 49.3157609, lng: 19.6429599 },
    { lat: 49.3156689, lng: 19.6429192 },
    { lat: 49.3153652, lng: 19.6427525 },
    { lat: 49.3152173, lng: 19.642744 },
    { lat: 49.3151015, lng: 19.6427463 },
    { lat: 49.3151014, lng: 19.6427712 },
    { lat: 49.3149522, lng: 19.6427607 },
    { lat: 49.3148333, lng: 19.6427731 },
    { lat: 49.3146378, lng: 19.6428103 },
    { lat: 49.3142268, lng: 19.6429696 },
    { lat: 49.3141025, lng: 19.6429818 },
    { lat: 49.314009, lng: 19.6430623 },
    { lat: 49.313861, lng: 19.6430993 },
    { lat: 49.3137248, lng: 19.6431535 },
    { lat: 49.3134981, lng: 19.6432742 },
    { lat: 49.3133484, lng: 19.6433316 },
    { lat: 49.3131507, lng: 19.6434666 },
    { lat: 49.3126995, lng: 19.643556 },
    { lat: 49.3124552, lng: 19.6436908 },
    { lat: 49.3123306, lng: 19.6437236 },
    { lat: 49.3121902, lng: 19.6437342 },
    { lat: 49.312069, lng: 19.6437973 },
    { lat: 49.3119749, lng: 19.6438103 },
    { lat: 49.3118907, lng: 19.6438382 },
    { lat: 49.3116946, lng: 19.6438394 },
    { lat: 49.311644, lng: 19.6438168 },
    { lat: 49.3114935, lng: 19.6437772 },
    { lat: 49.3114683, lng: 19.6438907 },
    { lat: 49.3113378, lng: 19.6444021 },
    { lat: 49.3112882, lng: 19.6446087 },
    { lat: 49.311265, lng: 19.6447455 },
    { lat: 49.3112074, lng: 19.6451457 },
    { lat: 49.3110551, lng: 19.6459046 },
    { lat: 49.3110309, lng: 19.6460685 },
    { lat: 49.3110262, lng: 19.6462174 },
    { lat: 49.3110087, lng: 19.646308 },
    { lat: 49.3109255, lng: 19.6466662 },
    { lat: 49.310842, lng: 19.6469662 },
    { lat: 49.310794, lng: 19.6471831 },
    { lat: 49.3107207, lng: 19.6474095 },
    { lat: 49.3106389, lng: 19.6477202 },
    { lat: 49.3105959, lng: 19.6479226 },
    { lat: 49.310562, lng: 19.6482489 },
    { lat: 49.3105175, lng: 19.6485178 },
    { lat: 49.3104996, lng: 19.6487493 },
    { lat: 49.3104589, lng: 19.6491517 },
    { lat: 49.3103977, lng: 19.6495231 },
    { lat: 49.3102948, lng: 19.6501248 },
    { lat: 49.310263, lng: 19.6502711 },
    { lat: 49.3101294, lng: 19.6510706 },
    { lat: 49.3101093, lng: 19.6511767 },
    { lat: 49.3099849, lng: 19.6519294 },
    { lat: 49.309832, lng: 19.65265 },
    { lat: 49.3097302, lng: 19.6531982 },
    { lat: 49.309684, lng: 19.6534008 },
    { lat: 49.3095288, lng: 19.6539974 },
    { lat: 49.309417, lng: 19.6543712 },
    { lat: 49.3092926, lng: 19.6547126 },
    { lat: 49.3091917, lng: 19.6550588 },
    { lat: 49.3091314, lng: 19.6552485 },
    { lat: 49.3090191, lng: 19.6556381 },
    { lat: 49.3089322, lng: 19.6559165 },
    { lat: 49.3087774, lng: 19.6562372 },
    { lat: 49.3086181, lng: 19.6565581 },
    { lat: 49.3085255, lng: 19.6567532 },
    { lat: 49.30805, lng: 19.6577191 },
    { lat: 49.3079421, lng: 19.6579318 },
    { lat: 49.3077923, lng: 19.6582123 },
    { lat: 49.3077147, lng: 19.6583669 },
    { lat: 49.3074608, lng: 19.6589152 },
    { lat: 49.3070435, lng: 19.6598223 },
    { lat: 49.3068883, lng: 19.6601359 },
    { lat: 49.3067936, lng: 19.6603865 },
    { lat: 49.3065785, lng: 19.6608719 },
    { lat: 49.3064008, lng: 19.6612611 },
    { lat: 49.3061071, lng: 19.6619832 },
    { lat: 49.3058628, lng: 19.662698 },
    { lat: 49.30544, lng: 19.6639641 },
    { lat: 49.3050367, lng: 19.6652668 },
    { lat: 49.3049573, lng: 19.6660173 },
    { lat: 49.3048332, lng: 19.6671146 },
    { lat: 49.3047967, lng: 19.6674855 },
    { lat: 49.3046522, lng: 19.6674257 },
    { lat: 49.3034922, lng: 19.6669235 },
    { lat: 49.3033036, lng: 19.6668393 },
    { lat: 49.3028078, lng: 19.666479 },
    { lat: 49.302363, lng: 19.666152 },
    { lat: 49.3018049, lng: 19.6657296 },
    { lat: 49.3006583, lng: 19.6648958 },
    { lat: 49.2997459, lng: 19.6651034 },
    { lat: 49.2990799, lng: 19.6652249 },
    { lat: 49.2986399, lng: 19.6653115 },
    { lat: 49.2976729, lng: 19.6654803 },
    { lat: 49.2971234, lng: 19.6655891 },
    { lat: 49.2963413, lng: 19.6657348 },
    { lat: 49.2955065, lng: 19.6658882 },
    { lat: 49.294817, lng: 19.6660378 },
    { lat: 49.2939902, lng: 19.6662444 },
    { lat: 49.2932027, lng: 19.6664207 },
    { lat: 49.2923735, lng: 19.6666262 },
    { lat: 49.2918732, lng: 19.6667265 },
    { lat: 49.291552, lng: 19.6667973 },
    { lat: 49.2907339, lng: 19.6671748 },
    { lat: 49.2902357, lng: 19.6674226 },
    { lat: 49.290174, lng: 19.6674333 },
    { lat: 49.2903538, lng: 19.6690558 },
    { lat: 49.2905734, lng: 19.6714523 },
    { lat: 49.2911148, lng: 19.673049 },
    { lat: 49.2918092, lng: 19.6751274 },
    { lat: 49.292407, lng: 19.6769058 },
    { lat: 49.2928932, lng: 19.6783769 },
    { lat: 49.2930829, lng: 19.6794351 },
    { lat: 49.2934965, lng: 19.6810484 },
    { lat: 49.2936942, lng: 19.6818286 },
    { lat: 49.2942321, lng: 19.6831718 },
    { lat: 49.2945541, lng: 19.6839854 },
    { lat: 49.2954428, lng: 19.685877 },
    { lat: 49.295767, lng: 19.6865608 },
    { lat: 49.2958946, lng: 19.6868747 },
    { lat: 49.2961654, lng: 19.6875247 },
    { lat: 49.2963571, lng: 19.6879912 },
    { lat: 49.2963583, lng: 19.6879932 },
    { lat: 49.2967067, lng: 19.6888446 },
    { lat: 49.2968738, lng: 19.6892623 },
    { lat: 49.2970505, lng: 19.6896953 },
    { lat: 49.2971904, lng: 19.6900307 },
    { lat: 49.297289, lng: 19.6902761 },
    { lat: 49.2974624, lng: 19.690838 },
    { lat: 49.2975875, lng: 19.6912518 },
    { lat: 49.2978114, lng: 19.6920117 },
    { lat: 49.2978229, lng: 19.6920466 },
    { lat: 49.2978566, lng: 19.6921427 },
    { lat: 49.2978955, lng: 19.6922102 },
    { lat: 49.2986411, lng: 19.693615 },
    { lat: 49.2988299, lng: 19.6939154 },
    { lat: 49.2990094, lng: 19.694181 },
    { lat: 49.2998087, lng: 19.6950616 },
    { lat: 49.300455, lng: 19.6958774 },
    { lat: 49.3009456, lng: 19.6966598 },
    { lat: 49.3013851, lng: 19.6972547 },
    { lat: 49.3013827, lng: 19.6978661 },
    { lat: 49.3013468, lng: 19.6982271 },
    { lat: 49.3014316, lng: 19.6985931 },
    { lat: 49.3014318, lng: 19.6985978 },
    { lat: 49.3014011, lng: 19.6989796 },
    { lat: 49.301599, lng: 19.6999989 },
    { lat: 49.3017852, lng: 19.7003144 },
    { lat: 49.3021193, lng: 19.7012797 },
    { lat: 49.3023966, lng: 19.7018685 },
    { lat: 49.3027551, lng: 19.7024909 },
    { lat: 49.3030891, lng: 19.7029707 },
    { lat: 49.3034707, lng: 19.7036293 },
    { lat: 49.3035178, lng: 19.7037303 },
    { lat: 49.3038339, lng: 19.7044635 },
    { lat: 49.3040182, lng: 19.7050435 },
    { lat: 49.3041321, lng: 19.7053784 },
    { lat: 49.3043273, lng: 19.7059006 },
    { lat: 49.3043985, lng: 19.7060113 },
    { lat: 49.3045634, lng: 19.7061977 },
    { lat: 49.3047264, lng: 19.7065019 },
    { lat: 49.3047689, lng: 19.7066979 },
    { lat: 49.3048632, lng: 19.7069478 },
    { lat: 49.305068, lng: 19.7072802 },
    { lat: 49.3052042, lng: 19.7074298 },
    { lat: 49.3053303, lng: 19.7075559 },
    { lat: 49.3056723, lng: 19.7080071 },
    { lat: 49.3059526, lng: 19.7084657 },
    { lat: 49.3062212, lng: 19.7089144 },
    { lat: 49.3063651, lng: 19.7094996 },
    { lat: 49.3065185, lng: 19.7098167 },
    { lat: 49.3068058, lng: 19.7101501 },
    { lat: 49.3071054, lng: 19.7104317 },
    { lat: 49.3074696, lng: 19.710646 },
    { lat: 49.3078143, lng: 19.7109003 },
    { lat: 49.3080969, lng: 19.7111829 },
    { lat: 49.3082125, lng: 19.7113534 },
    { lat: 49.3085427, lng: 19.7119463 },
    { lat: 49.3086911, lng: 19.7122239 },
    { lat: 49.3087713, lng: 19.712359 },
    { lat: 49.3090123, lng: 19.7127398 },
    { lat: 49.3095378, lng: 19.7138853 },
    { lat: 49.309841, lng: 19.7144799 },
    { lat: 49.3098753, lng: 19.7143905 },
    { lat: 49.3100089, lng: 19.7137378 },
    { lat: 49.3100972, lng: 19.7132576 },
    { lat: 49.3100564, lng: 19.7129675 },
    { lat: 49.3100761, lng: 19.7120543 },
    { lat: 49.3099475, lng: 19.710507 },
    { lat: 49.3100346, lng: 19.7099739 },
    { lat: 49.3098672, lng: 19.709109 },
    { lat: 49.3098588, lng: 19.7088424 },
    { lat: 49.3098617, lng: 19.7084727 },
    { lat: 49.3098197, lng: 19.7081066 },
    { lat: 49.3099023, lng: 19.7076487 },
    { lat: 49.3098863, lng: 19.7072831 },
    { lat: 49.3098617, lng: 19.7069528 },
    { lat: 49.3099599, lng: 19.7066246 },
    { lat: 49.3100367, lng: 19.7059586 },
    { lat: 49.3100364, lng: 19.7059305 },
    { lat: 49.3098833, lng: 19.7055447 },
    { lat: 49.3098202, lng: 19.7046959 },
    { lat: 49.309709, lng: 19.7043676 },
    { lat: 49.3096729, lng: 19.7041102 },
    { lat: 49.309848, lng: 19.7038403 },
    { lat: 49.310074, lng: 19.7033947 },
    { lat: 49.3102862, lng: 19.7029642 },
    { lat: 49.3102821, lng: 19.7027458 },
    { lat: 49.3102679, lng: 19.7016732 },
    { lat: 49.3102642, lng: 19.7015407 },
    { lat: 49.3102913, lng: 19.7011589 },
    { lat: 49.3103018, lng: 19.7009567 },
    { lat: 49.3101168, lng: 19.699925 },
    { lat: 49.3109786, lng: 19.6991048 },
    { lat: 49.3116835, lng: 19.6984291 },
    { lat: 49.3123819, lng: 19.6977378 },
    { lat: 49.3127475, lng: 19.6968774 },
    { lat: 49.3129404, lng: 19.696399 },
    { lat: 49.3134406, lng: 19.6951673 },
    { lat: 49.313527, lng: 19.694979 },
    { lat: 49.3136016, lng: 19.6948832 },
    { lat: 49.3137424, lng: 19.6948052 },
    { lat: 49.3140016, lng: 19.6948577 },
    { lat: 49.3140234, lng: 19.6946337 },
    { lat: 49.314285, lng: 19.6944368 },
    { lat: 49.3144127, lng: 19.69455 },
    { lat: 49.3147047, lng: 19.6946569 },
    { lat: 49.3150558, lng: 19.6946219 },
    { lat: 49.315364, lng: 19.694613 },
    { lat: 49.3163855, lng: 19.6942975 },
    { lat: 49.3166598, lng: 19.6943632 },
    { lat: 49.3172342, lng: 19.6940532 },
    { lat: 49.3177013, lng: 19.6938629 },
    { lat: 49.3177765, lng: 19.6938071 },
    { lat: 49.318168, lng: 19.6941804 },
    { lat: 49.318371, lng: 19.6944375 },
    { lat: 49.3185374, lng: 19.6945346 },
    { lat: 49.3187295, lng: 19.6945971 },
    { lat: 49.3189514, lng: 19.6945769 },
    { lat: 49.3193248, lng: 19.6944843 },
    { lat: 49.3196127, lng: 19.6941915 },
    { lat: 49.3198684, lng: 19.6939832 },
    { lat: 49.3199877, lng: 19.6936727 },
    { lat: 49.3201106, lng: 19.6931119 },
    { lat: 49.3202136, lng: 19.6929427 },
    { lat: 49.3206447, lng: 19.6924759 },
    { lat: 49.3209568, lng: 19.691939 },
    { lat: 49.3211618, lng: 19.6913239 },
    { lat: 49.3212957, lng: 19.6911104 },
    { lat: 49.3213966, lng: 19.6911755 },
    { lat: 49.3215823, lng: 19.6908419 },
    { lat: 49.3218643, lng: 19.6904629 },
    { lat: 49.3220967, lng: 19.6899833 },
    { lat: 49.3222761, lng: 19.6898381 },
    { lat: 49.322448, lng: 19.6895694 },
    { lat: 49.3224693, lng: 19.6893126 },
    { lat: 49.3226485, lng: 19.6892126 },
    { lat: 49.3228004, lng: 19.6892363 },
    { lat: 49.3230409, lng: 19.688966 },
    { lat: 49.3231059, lng: 19.6889568 },
    { lat: 49.3232932, lng: 19.688654 },
    { lat: 49.323416, lng: 19.688377 },
    { lat: 49.3235297, lng: 19.688041 },
    { lat: 49.3235496, lng: 19.6877735 },
    { lat: 49.3235943, lng: 19.6876624 },
    { lat: 49.3239131, lng: 19.6872265 },
    { lat: 49.3240061, lng: 19.6870389 },
    { lat: 49.3242191, lng: 19.6867555 },
    { lat: 49.3242393, lng: 19.6863974 },
    { lat: 49.3246317, lng: 19.6859167 },
    { lat: 49.324852, lng: 19.6856019 },
    { lat: 49.3248728, lng: 19.685253 },
    { lat: 49.3249107, lng: 19.6851921 },
    { lat: 49.3250754, lng: 19.6850451 },
    { lat: 49.3251491, lng: 19.6849006 },
    { lat: 49.3251264, lng: 19.6846773 },
    { lat: 49.3252462, lng: 19.6843703 },
    { lat: 49.3255935, lng: 19.6842972 },
    { lat: 49.3256168, lng: 19.6840337 },
    { lat: 49.3256521, lng: 19.683865 },
    { lat: 49.3258978, lng: 19.6836126 },
    { lat: 49.3260205, lng: 19.6836115 },
    { lat: 49.3260684, lng: 19.683573 },
    { lat: 49.3261097, lng: 19.6834116 },
    { lat: 49.326176, lng: 19.6833026 },
    { lat: 49.3263556, lng: 19.6832862 },
    { lat: 49.326425, lng: 19.6831516 },
    { lat: 49.3267659, lng: 19.6828575 },
    { lat: 49.3267762, lng: 19.6825831 },
    { lat: 49.3267882, lng: 19.6824421 },
    { lat: 49.3268392, lng: 19.6823729 },
    { lat: 49.3270084, lng: 19.6823013 },
    { lat: 49.3271423, lng: 19.6820935 },
    { lat: 49.3272091, lng: 19.6818956 },
    { lat: 49.327294, lng: 19.6818561 },
    { lat: 49.3272956, lng: 19.6817371 },
    { lat: 49.3274286, lng: 19.6816357 },
    { lat: 49.3277113, lng: 19.6817897 },
    { lat: 49.3281974, lng: 19.6815973 },
    { lat: 49.3284166, lng: 19.681639 },
    { lat: 49.3285943, lng: 19.6816586 },
    { lat: 49.3287773, lng: 19.6816166 },
    { lat: 49.3290823, lng: 19.6817148 },
    { lat: 49.3291764, lng: 19.6817554 },
    { lat: 49.3295643, lng: 19.6820455 },
    { lat: 49.3297969, lng: 19.6821595 },
    { lat: 49.3300049, lng: 19.6821493 },
    { lat: 49.3302373, lng: 19.6818465 },
    { lat: 49.3304227, lng: 19.6818642 },
    { lat: 49.3305665, lng: 19.6820318 },
    { lat: 49.3306761, lng: 19.6820169 },
    { lat: 49.3308025, lng: 19.6821 },
    { lat: 49.3310783, lng: 19.6820914 },
    { lat: 49.3311293, lng: 19.6820482 },
    { lat: 49.3311814, lng: 19.6819779 },
    { lat: 49.331281, lng: 19.6818867 },
    { lat: 49.331557, lng: 19.681963 },
    { lat: 49.3318006, lng: 19.6820954 },
    { lat: 49.3319434, lng: 19.6820427 },
    { lat: 49.3320251, lng: 19.6817956 },
    { lat: 49.3320958, lng: 19.6817171 },
    { lat: 49.3321873, lng: 19.6815173 },
    { lat: 49.3322885, lng: 19.6813805 },
    { lat: 49.3326094, lng: 19.681303 },
    { lat: 49.3326812, lng: 19.6812992 },
    { lat: 49.3328485, lng: 19.6812061 },
    { lat: 49.3330214, lng: 19.68109 },
    { lat: 49.3331155, lng: 19.681184 },
    { lat: 49.3332398, lng: 19.6811676 },
    { lat: 49.3333286, lng: 19.6810056 },
    { lat: 49.3333745, lng: 19.6808203 },
    { lat: 49.3334876, lng: 19.6806764 },
    { lat: 49.3336954, lng: 19.680663 },
    { lat: 49.3339818, lng: 19.6805197 },
    { lat: 49.3340911, lng: 19.6805669 },
    { lat: 49.3342754, lng: 19.6805064 },
    { lat: 49.3343933, lng: 19.6804223 },
    { lat: 49.3347322, lng: 19.6800843 },
    { lat: 49.3348628, lng: 19.6799018 },
    { lat: 49.3349531, lng: 19.6797278 },
    { lat: 49.3350631, lng: 19.6795402 },
    { lat: 49.3350485, lng: 19.6792838 },
    { lat: 49.3350821, lng: 19.6791777 },
    { lat: 49.3352228, lng: 19.6790989 },
    { lat: 49.3353034, lng: 19.6791108 },
    { lat: 49.3354857, lng: 19.6790595 },
    { lat: 49.3355663, lng: 19.6788142 },
    { lat: 49.3356853, lng: 19.678703 },
    { lat: 49.3357402, lng: 19.6787219 },
    { lat: 49.3358502, lng: 19.678867 },
    { lat: 49.3358665, lng: 19.6790065 },
    { lat: 49.3359197, lng: 19.6790668 },
    { lat: 49.3359828, lng: 19.6790642 },
    { lat: 49.3360451, lng: 19.6789997 },
    { lat: 49.336096, lng: 19.6787708 },
    { lat: 49.3361756, lng: 19.6785582 },
    { lat: 49.3362915, lng: 19.6784776 },
    { lat: 49.3363771, lng: 19.6785294 },
    { lat: 49.3365233, lng: 19.6784721 },
    { lat: 49.3365383, lng: 19.6784062 },
    { lat: 49.3364838, lng: 19.678315 },
    { lat: 49.3364292, lng: 19.6780982 },
    { lat: 49.3364235, lng: 19.6779962 },
    { lat: 49.3364546, lng: 19.6779099 },
    { lat: 49.336625, lng: 19.6778381 },
    { lat: 49.3367712, lng: 19.6779112 },
    { lat: 49.3368903, lng: 19.6778548 },
    { lat: 49.3369491, lng: 19.6775764 },
    { lat: 49.3371429, lng: 19.677297 },
    { lat: 49.3372296, lng: 19.6772711 },
    { lat: 49.3372827, lng: 19.6771952 },
    { lat: 49.3373737, lng: 19.6769365 },
    { lat: 49.3374907, lng: 19.6768547 },
    { lat: 49.3375878, lng: 19.6768819 },
    { lat: 49.3376744, lng: 19.6767791 },
    { lat: 49.337705, lng: 19.6766271 },
    { lat: 49.3377122, lng: 19.6764858 },
    { lat: 49.3377796, lng: 19.6763018 },
    { lat: 49.3379532, lng: 19.6761257 },
    { lat: 49.3379996, lng: 19.6761296 },
    { lat: 49.3380852, lng: 19.6762349 },
    { lat: 49.3380269, lng: 19.67647 },
    { lat: 49.3380198, lng: 19.6765874 },
    { lat: 49.3380494, lng: 19.6766757 },
    { lat: 49.3380917, lng: 19.6767411 },
    { lat: 49.3382067, lng: 19.6768697 },
    { lat: 49.3382694, lng: 19.6768842 },
    { lat: 49.3383048, lng: 19.6768459 },
    { lat: 49.3383399, lng: 19.6766689 },
    { lat: 49.3383387, lng: 19.6765393 },
    { lat: 49.3383451, lng: 19.6764808 },
    { lat: 49.3383605, lng: 19.6764229 },
    { lat: 49.3384937, lng: 19.6763774 },
    { lat: 49.3386109, lng: 19.676353 },
    { lat: 49.3387319, lng: 19.6762589 },
    { lat: 49.3388227, lng: 19.6761501 },
    { lat: 49.3389208, lng: 19.6760465 },
    { lat: 49.3389253, lng: 19.6759705 },
    { lat: 49.3391336, lng: 19.67594 },
    { lat: 49.3391668, lng: 19.6757787 },
    { lat: 49.3389831, lng: 19.6753916 },
    { lat: 49.339057, lng: 19.6752514 },
    { lat: 49.3392989, lng: 19.6751979 },
    { lat: 49.3395487, lng: 19.6748824 },
    { lat: 49.3395996, lng: 19.6749602 },
    { lat: 49.3398316, lng: 19.6751675 },
    { lat: 49.3398985, lng: 19.6752032 },
    { lat: 49.3399754, lng: 19.675132 },
    { lat: 49.339991, lng: 19.6747681 },
    { lat: 49.3398907, lng: 19.6745118 },
    { lat: 49.3400182, lng: 19.6744694 },
    { lat: 49.3400795, lng: 19.6745259 },
    { lat: 49.3402894, lng: 19.6746366 },
    { lat: 49.34037, lng: 19.6746791 },
    { lat: 49.3403683, lng: 19.6745387 },
    { lat: 49.3403832, lng: 19.6742608 },
    { lat: 49.3405157, lng: 19.674198 },
    { lat: 49.3406864, lng: 19.6746486 },
    { lat: 49.3407175, lng: 19.6747382 },
    { lat: 49.3409843, lng: 19.6746876 },
    { lat: 49.3411827, lng: 19.6748181 },
    { lat: 49.341366, lng: 19.6745048 },
    { lat: 49.3416061, lng: 19.6744843 },
    { lat: 49.3419071, lng: 19.6742527 },
    { lat: 49.3422971, lng: 19.6742865 },
    { lat: 49.3423526, lng: 19.673477 },
    { lat: 49.3424838, lng: 19.6734345 },
    { lat: 49.3425765, lng: 19.6737494 },
    { lat: 49.3428404, lng: 19.6737796 },
    { lat: 49.3429801, lng: 19.6734519 },
    { lat: 49.3434924, lng: 19.6732401 },
    { lat: 49.343519, lng: 19.6732803 },
    { lat: 49.3436827, lng: 19.6731601 },
    { lat: 49.3438077, lng: 19.6732108 },
    { lat: 49.3438803, lng: 19.6732997 },
    { lat: 49.3439069, lng: 19.6734144 },
    { lat: 49.3439739, lng: 19.6734306 },
    { lat: 49.3444521, lng: 19.6730864 },
    { lat: 49.3444533, lng: 19.6728572 },
    { lat: 49.3446572, lng: 19.6728066 },
    { lat: 49.3446897, lng: 19.6730568 },
    { lat: 49.3445774, lng: 19.6730915 },
    { lat: 49.3446012, lng: 19.6732716 },
    { lat: 49.344724, lng: 19.673218 },
    { lat: 49.3447524, lng: 19.6733289 },
    { lat: 49.3447303, lng: 19.6733894 },
    { lat: 49.3448297, lng: 19.67355 },
    { lat: 49.3449604, lng: 19.6736012 },
    { lat: 49.3452936, lng: 19.6734165 },
    { lat: 49.3453542, lng: 19.6734641 },
    { lat: 49.345324, lng: 19.6738014 },
    { lat: 49.3454455, lng: 19.6739229 },
    { lat: 49.3455533, lng: 19.6742203 },
    { lat: 49.3458422, lng: 19.6744886 },
    { lat: 49.3458727, lng: 19.6744135 },
    { lat: 49.3458556, lng: 19.6740525 },
    { lat: 49.3460192, lng: 19.6740324 },
    { lat: 49.3460278, lng: 19.6736922 },
    { lat: 49.3459938, lng: 19.6730956 },
    { lat: 49.3460376, lng: 19.672504 },
    { lat: 49.3463137, lng: 19.6709027 },
    { lat: 49.346316, lng: 19.6701607 },
    { lat: 49.3463808, lng: 19.6685631 },
    { lat: 49.3467043, lng: 19.6670093 },
    { lat: 49.3468988, lng: 19.6665422 },
    { lat: 49.3473293, lng: 19.6648616 },
    { lat: 49.3473871, lng: 19.664284 },
    { lat: 49.3476322, lng: 19.6636325 },
    { lat: 49.3481883, lng: 19.6628336 },
    { lat: 49.3485876, lng: 19.6622058 },
    { lat: 49.3486395, lng: 19.6622315 },
    { lat: 49.3486812, lng: 19.6624646 },
    { lat: 49.3487542, lng: 19.6625636 },
    { lat: 49.3488018, lng: 19.6625923 },
    { lat: 49.3488755, lng: 19.6626038 },
    { lat: 49.3489364, lng: 19.6621993 },
    { lat: 49.349336, lng: 19.6619892 },
    { lat: 49.3493991, lng: 19.662012 },
    { lat: 49.349388, lng: 19.662119 },
    { lat: 49.3493172, lng: 19.6621997 },
    { lat: 49.3493559, lng: 19.6623865 },
    { lat: 49.3494475, lng: 19.6623914 },
    { lat: 49.3494806, lng: 19.6623761 },
    { lat: 49.3495717, lng: 19.6622958 },
    { lat: 49.3497744, lng: 19.6622452 },
    { lat: 49.3499766, lng: 19.6622807 },
    { lat: 49.3500344, lng: 19.6623665 },
    { lat: 49.3501521, lng: 19.6623521 },
    { lat: 49.3505043, lng: 19.6621654 },
    { lat: 49.3506246, lng: 19.6621844 },
    { lat: 49.3507655, lng: 19.6623179 },
    { lat: 49.3509116, lng: 19.6622844 },
    { lat: 49.3508812, lng: 19.6621802 },
    { lat: 49.3510455, lng: 19.6618962 },
    { lat: 49.350944, lng: 19.6616133 },
    { lat: 49.3510427, lng: 19.6615543 },
    { lat: 49.3513738, lng: 19.6606607 },
    { lat: 49.3514796, lng: 19.6606295 },
    { lat: 49.3516239, lng: 19.6600205 },
    { lat: 49.3518205, lng: 19.6592193 },
    { lat: 49.3521867, lng: 19.6580449 },
    { lat: 49.3525631, lng: 19.6572366 },
    { lat: 49.3528958, lng: 19.6573712 },
    { lat: 49.3532186, lng: 19.6571007 },
    { lat: 49.3524731, lng: 19.6561398 },
    { lat: 49.3514658, lng: 19.6554976 },
    { lat: 49.351354, lng: 19.6537121 },
    { lat: 49.3520598, lng: 19.6516891 },
    { lat: 49.352222, lng: 19.6503352 },
    { lat: 49.3522103, lng: 19.6503274 },
    { lat: 49.352085, lng: 19.650168 },
    { lat: 49.3520448, lng: 19.6499753 },
    { lat: 49.3520426, lng: 19.6497977 },
    { lat: 49.3519703, lng: 19.649713 },
    { lat: 49.3518784, lng: 19.6496763 },
    { lat: 49.3517246, lng: 19.6495087 },
    { lat: 49.3514122, lng: 19.6493471 },
    { lat: 49.3510922, lng: 19.6492209 },
    { lat: 49.3508058, lng: 19.6492134 },
    { lat: 49.3507145, lng: 19.6493392 },
    { lat: 49.3505494, lng: 19.6494012 },
    { lat: 49.3504924, lng: 19.6494891 },
    { lat: 49.3504162, lng: 19.649548 },
    { lat: 49.3502599, lng: 19.6493495 },
    { lat: 49.3500872, lng: 19.6492413 },
    { lat: 49.3499441, lng: 19.6490162 },
    { lat: 49.349928, lng: 19.6488022 },
    { lat: 49.3498381, lng: 19.6485777 },
    { lat: 49.3497247, lng: 19.6486878 },
    { lat: 49.3494312, lng: 19.6489058 },
    { lat: 49.3491133, lng: 19.6490789 },
    { lat: 49.3488859, lng: 19.6491824 },
    { lat: 49.3486, lng: 19.6492616 },
    { lat: 49.3481764, lng: 19.6493142 },
    { lat: 49.3480883, lng: 19.6490303 },
    { lat: 49.3480149, lng: 19.6487484 },
    { lat: 49.3479205, lng: 19.6484233 },
    { lat: 49.3478315, lng: 19.6481662 },
    { lat: 49.347785, lng: 19.6480543 },
    { lat: 49.3476883, lng: 19.6478812 },
    { lat: 49.3475909, lng: 19.6476702 },
    { lat: 49.347535, lng: 19.6474266 },
    { lat: 49.3475069, lng: 19.6473468 },
    { lat: 49.3474709, lng: 19.6471988 },
    { lat: 49.3474628, lng: 19.6470923 },
    { lat: 49.3474057, lng: 19.6470224 },
    { lat: 49.347383, lng: 19.6468397 },
    { lat: 49.3473414, lng: 19.6466894 },
    { lat: 49.3473094, lng: 19.6464741 },
    { lat: 49.3472114, lng: 19.6461952 },
    { lat: 49.3471773, lng: 19.6461138 },
    { lat: 49.3470716, lng: 19.6458126 },
    { lat: 49.3469613, lng: 19.6455599 },
    { lat: 49.3468777, lng: 19.6453236 },
    { lat: 49.346819, lng: 19.6450963 },
    { lat: 49.3467375, lng: 19.6448305 },
    { lat: 49.3467016, lng: 19.6446316 },
    { lat: 49.3466461, lng: 19.6444711 },
    { lat: 49.3465918, lng: 19.6443647 },
    { lat: 49.3465296, lng: 19.6441071 },
    { lat: 49.3465036, lng: 19.6439783 },
    { lat: 49.3464495, lng: 19.6437747 },
    { lat: 49.3463758, lng: 19.6435334 },
    { lat: 49.3463673, lng: 19.6434707 },
    { lat: 49.3463486, lng: 19.6434047 },
    { lat: 49.3463362, lng: 19.6432011 },
    { lat: 49.3463026, lng: 19.6429267 },
    { lat: 49.3462823, lng: 19.6428212 },
    { lat: 49.3462085, lng: 19.6425838 },
    { lat: 49.3461633, lng: 19.6422453 },
    { lat: 49.3461817, lng: 19.6421003 },
    { lat: 49.3461811, lng: 19.6420863 },
    { lat: 49.3460858, lng: 19.6418714 },
    { lat: 49.3460305, lng: 19.6416943 },
    { lat: 49.3459943, lng: 19.6415366 },
    { lat: 49.3459117, lng: 19.6415275 },
    { lat: 49.3459054, lng: 19.6415393 },
    { lat: 49.345827, lng: 19.641395 },
    { lat: 49.3457402, lng: 19.641167 },
    { lat: 49.3457244, lng: 19.6410888 },
    { lat: 49.3457303, lng: 19.6410665 },
    { lat: 49.3458196, lng: 19.6409719 },
    { lat: 49.3458284, lng: 19.6409421 },
    { lat: 49.3458287, lng: 19.6407462 },
    { lat: 49.345939, lng: 19.6405935 },
    { lat: 49.3460051, lng: 19.6405841 },
    { lat: 49.3460148, lng: 19.6405737 },
    { lat: 49.3460401, lng: 19.6404092 },
    { lat: 49.3460213, lng: 19.6403407 },
    { lat: 49.345945, lng: 19.6401976 },
    { lat: 49.3459721, lng: 19.6401695 },
    { lat: 49.3462811, lng: 19.6399506 },
    { lat: 49.3462955, lng: 19.6399447 },
    { lat: 49.3463702, lng: 19.6399785 },
    { lat: 49.346399, lng: 19.639968 },
    { lat: 49.3464434, lng: 19.6399268 },
    { lat: 49.3464652, lng: 19.6398585 },
    { lat: 49.3464666, lng: 19.6398374 },
    { lat: 49.3464148, lng: 19.6398131 },
    { lat: 49.3463602, lng: 19.6397541 },
    { lat: 49.3463731, lng: 19.6396884 },
    { lat: 49.3464073, lng: 19.6396173 },
    { lat: 49.3464609, lng: 19.6396356 },
    { lat: 49.3464693, lng: 19.6396477 },
    { lat: 49.3465002, lng: 19.6397334 },
    { lat: 49.3465466, lng: 19.6397355 },
    { lat: 49.3466757, lng: 19.6395757 },
    { lat: 49.3467606, lng: 19.6394567 },
    { lat: 49.3468721, lng: 19.6392565 },
    { lat: 49.3468742, lng: 19.6391233 },
    { lat: 49.3468384, lng: 19.6389749 },
    { lat: 49.3468183, lng: 19.6387775 },
    { lat: 49.3467833, lng: 19.638547 },
    { lat: 49.3467609, lng: 19.6382986 },
    { lat: 49.3467282, lng: 19.6381202 },
    { lat: 49.3466358, lng: 19.6376601 },
    { lat: 49.346614, lng: 19.6374239 },
    { lat: 49.3465792, lng: 19.6372725 },
    { lat: 49.346506, lng: 19.6366915 },
    { lat: 49.3464818, lng: 19.6364776 },
    { lat: 49.3464359, lng: 19.6364325 },
    { lat: 49.3463888, lng: 19.6358756 },
    { lat: 49.3463906, lng: 19.6357142 },
    { lat: 49.3462977, lng: 19.6353709 },
    { lat: 49.3462121, lng: 19.6349917 },
    { lat: 49.3462433, lng: 19.634832 },
    { lat: 49.3461463, lng: 19.6345505 },
    { lat: 49.3461453, lng: 19.6345293 },
    { lat: 49.346171, lng: 19.6344757 },
    { lat: 49.3460431, lng: 19.633646 },
    { lat: 49.3460262, lng: 19.6335217 },
    { lat: 49.3460133, lng: 19.6330287 },
    { lat: 49.3460089, lng: 19.6330295 },
    { lat: 49.3460092, lng: 19.6330114 },
    { lat: 49.345954, lng: 19.632709 },
    { lat: 49.345936, lng: 19.6325804 },
    { lat: 49.3458959, lng: 19.6323627 },
    { lat: 49.3457412, lng: 19.6323019 },
    { lat: 49.3455968, lng: 19.6323259 },
    { lat: 49.3455254, lng: 19.6323655 },
    { lat: 49.3454694, lng: 19.6324223 },
    { lat: 49.3452712, lng: 19.6324776 },
    { lat: 49.3452618, lng: 19.6324959 },
    { lat: 49.3451918, lng: 19.6324663 },
    { lat: 49.344999, lng: 19.6323347 },
    { lat: 49.3449463, lng: 19.6322903 },
    { lat: 49.3449258, lng: 19.6322606 },
    { lat: 49.3449109, lng: 19.6322018 },
    { lat: 49.344881, lng: 19.6321381 },
    { lat: 49.3448523, lng: 19.6321248 },
    { lat: 49.3448262, lng: 19.6320954 },
    { lat: 49.3448153, lng: 19.6320524 },
    { lat: 49.3448029, lng: 19.6319273 },
    { lat: 49.3447847, lng: 19.6318995 },
    { lat: 49.3447674, lng: 19.6318612 },
    { lat: 49.3447654, lng: 19.6318159 },
    { lat: 49.3447851, lng: 19.631756 },
    { lat: 49.3447594, lng: 19.6317338 },
    { lat: 49.3446259, lng: 19.6316675 },
    { lat: 49.3444711, lng: 19.63184 },
    { lat: 49.3442741, lng: 19.6317879 },
    { lat: 49.3441669, lng: 19.6318894 },
    { lat: 49.3441376, lng: 19.6319625 },
    { lat: 49.3440769, lng: 19.6320426 },
    { lat: 49.3440571, lng: 19.6320526 },
    { lat: 49.3440203, lng: 19.6320391 },
    { lat: 49.3440129, lng: 19.6320214 },
    { lat: 49.3440003, lng: 19.6320195 },
    { lat: 49.3439386, lng: 19.6320761 },
    { lat: 49.3439183, lng: 19.6320761 },
    { lat: 49.3438326, lng: 19.6319957 },
    { lat: 49.3436392, lng: 19.632005 },
    { lat: 49.3436282, lng: 19.6320123 },
    { lat: 49.3436049, lng: 19.6321206 },
    { lat: 49.3437305, lng: 19.6323333 },
    { lat: 49.3437695, lng: 19.6324247 },
    { lat: 49.3437671, lng: 19.6324495 },
    { lat: 49.3436695, lng: 19.632507 },
    { lat: 49.3436224, lng: 19.6325888 },
    { lat: 49.3434081, lng: 19.6325584 },
    { lat: 49.3433376, lng: 19.6324429 },
    { lat: 49.3433422, lng: 19.6323712 },
    { lat: 49.3433556, lng: 19.632342 },
    { lat: 49.3433769, lng: 19.632313 },
    { lat: 49.3433445, lng: 19.6322673 },
    { lat: 49.3432447, lng: 19.6322531 },
    { lat: 49.3431426, lng: 19.6323363 },
    { lat: 49.3431516, lng: 19.6324908 },
    { lat: 49.3431556, lng: 19.632507 },
    { lat: 49.343247, lng: 19.6326355 },
    { lat: 49.343472, lng: 19.6327056 },
    { lat: 49.3435493, lng: 19.6328194 },
    { lat: 49.3435809, lng: 19.6329505 },
    { lat: 49.3435661, lng: 19.6329679 },
    { lat: 49.343528, lng: 19.6329979 },
    { lat: 49.3435767, lng: 19.633059 },
    { lat: 49.3435873, lng: 19.633094 },
    { lat: 49.3435724, lng: 19.6331103 },
    { lat: 49.3434748, lng: 19.633071 },
    { lat: 49.3433721, lng: 19.633043 },
    { lat: 49.3433339, lng: 19.6330459 },
    { lat: 49.3432823, lng: 19.6329458 },
    { lat: 49.3431554, lng: 19.633007 },
    { lat: 49.3431219, lng: 19.6330123 },
    { lat: 49.3430662, lng: 19.6329751 },
    { lat: 49.3430287, lng: 19.6329173 },
    { lat: 49.3429028, lng: 19.6328473 },
    { lat: 49.342839, lng: 19.6329106 },
    { lat: 49.3428337, lng: 19.6328656 },
    { lat: 49.3428339, lng: 19.6326409 },
    { lat: 49.3428181, lng: 19.6325414 },
    { lat: 49.3427324, lng: 19.6325886 },
    { lat: 49.3426588, lng: 19.6327064 },
    { lat: 49.3425681, lng: 19.6326953 },
    { lat: 49.342556, lng: 19.6327244 },
    { lat: 49.3425457, lng: 19.6327757 },
    { lat: 49.3424696, lng: 19.6328833 },
    { lat: 49.3424562, lng: 19.6328919 },
    { lat: 49.3423949, lng: 19.632856 },
    { lat: 49.3422633, lng: 19.6328043 },
    { lat: 49.3422174, lng: 19.6328127 },
    { lat: 49.3421816, lng: 19.6328475 },
    { lat: 49.3421684, lng: 19.6328796 },
    { lat: 49.3422044, lng: 19.6330529 },
    { lat: 49.3422987, lng: 19.6330965 },
    { lat: 49.3423761, lng: 19.6331665 },
    { lat: 49.3424355, lng: 19.6334136 },
    { lat: 49.3425317, lng: 19.6335481 },
    { lat: 49.3425311, lng: 19.6336627 },
    { lat: 49.3425218, lng: 19.6337078 },
    { lat: 49.3424744, lng: 19.6338059 },
    { lat: 49.3421827, lng: 19.6337069 },
    { lat: 49.3420175, lng: 19.633309 },
    { lat: 49.3419513, lng: 19.6331161 },
    { lat: 49.3419529, lng: 19.6330722 },
    { lat: 49.3419783, lng: 19.6330385 },
    { lat: 49.341961, lng: 19.6330045 },
    { lat: 49.3419437, lng: 19.6329915 },
    { lat: 49.3418833, lng: 19.6330043 },
    { lat: 49.3418571, lng: 19.6330186 },
    { lat: 49.3417994, lng: 19.6330925 },
    { lat: 49.3417821, lng: 19.6330849 },
    { lat: 49.3417582, lng: 19.6330523 },
    { lat: 49.3417513, lng: 19.6330231 },
    { lat: 49.3418037, lng: 19.6329338 },
    { lat: 49.3417991, lng: 19.6329108 },
    { lat: 49.3417217, lng: 19.6327613 },
    { lat: 49.3416595, lng: 19.63268 },
    { lat: 49.3416561, lng: 19.632529 },
    { lat: 49.3415287, lng: 19.6324765 },
    { lat: 49.3415012, lng: 19.6324905 },
    { lat: 49.3414638, lng: 19.6325353 },
    { lat: 49.3414802, lng: 19.6326766 },
    { lat: 49.3415781, lng: 19.6328301 },
    { lat: 49.3416064, lng: 19.632829 },
    { lat: 49.3416487, lng: 19.6328928 },
    { lat: 49.3416523, lng: 19.6329264 },
    { lat: 49.3414307, lng: 19.6332627 },
    { lat: 49.341403, lng: 19.6332244 },
    { lat: 49.3413854, lng: 19.6331301 },
    { lat: 49.3413328, lng: 19.6330897 },
    { lat: 49.3413397, lng: 19.6330648 },
    { lat: 49.3413371, lng: 19.6329578 },
    { lat: 49.3413978, lng: 19.6327986 },
    { lat: 49.341406, lng: 19.6327551 },
    { lat: 49.3414124, lng: 19.6326493 },
    { lat: 49.3413476, lng: 19.6325358 },
    { lat: 49.3413034, lng: 19.6325277 },
    { lat: 49.3412794, lng: 19.6325729 },
    { lat: 49.3412569, lng: 19.6325965 },
    { lat: 49.341226, lng: 19.6326091 },
    { lat: 49.3411953, lng: 19.6327305 },
    { lat: 49.3411476, lng: 19.6327473 },
    { lat: 49.3411017, lng: 19.6328291 },
    { lat: 49.3410723, lng: 19.6329272 },
    { lat: 49.3411611, lng: 19.6330494 },
    { lat: 49.3411468, lng: 19.6331593 },
    { lat: 49.3411588, lng: 19.6332531 },
    { lat: 49.3410549, lng: 19.6334023 },
    { lat: 49.3410752, lng: 19.6335508 },
    { lat: 49.3411474, lng: 19.6336304 },
    { lat: 49.341174, lng: 19.6337223 },
    { lat: 49.3412461, lng: 19.6337726 },
    { lat: 49.3412521, lng: 19.6338084 },
    { lat: 49.3410652, lng: 19.6338626 },
    { lat: 49.3409492, lng: 19.6341145 },
    { lat: 49.3409288, lng: 19.6341119 },
    { lat: 49.340839, lng: 19.6339406 },
    { lat: 49.3407952, lng: 19.6339159 },
    { lat: 49.3407435, lng: 19.6339477 },
    { lat: 49.3406782, lng: 19.6340246 },
    { lat: 49.3406261, lng: 19.6341171 },
    { lat: 49.3406184, lng: 19.6343003 },
    { lat: 49.340631, lng: 19.6344348 },
    { lat: 49.3407599, lng: 19.6344666 },
    { lat: 49.3407227, lng: 19.6346236 },
    { lat: 49.3405627, lng: 19.6348 },
    { lat: 49.3404228, lng: 19.6347694 },
    { lat: 49.3402781, lng: 19.6347801 },
    { lat: 49.3402081, lng: 19.6347516 },
    { lat: 49.340155, lng: 19.6348532 },
    { lat: 49.3402062, lng: 19.6348905 },
    { lat: 49.3403999, lng: 19.6349145 },
    { lat: 49.3403201, lng: 19.6351684 },
    { lat: 49.340193, lng: 19.635225 },
    { lat: 49.3400774, lng: 19.6353855 },
    { lat: 49.3400233, lng: 19.6355664 },
    { lat: 49.339906, lng: 19.6356093 },
    { lat: 49.3398023, lng: 19.6355868 },
    { lat: 49.3396827, lng: 19.6354555 },
    { lat: 49.3396182, lng: 19.6352476 },
    { lat: 49.3393096, lng: 19.636107 },
    { lat: 49.3393012, lng: 19.6361256 },
    { lat: 49.3393406, lng: 19.6361728 },
    { lat: 49.3393853, lng: 19.6362657 },
    { lat: 49.3393685, lng: 19.6363711 },
    { lat: 49.3393204, lng: 19.6364299 },
    { lat: 49.3392962, lng: 19.6366723 },
    { lat: 49.3392702, lng: 19.6367675 },
    { lat: 49.3392588, lng: 19.6367922 },
    { lat: 49.3391871, lng: 19.6368524 },
    { lat: 49.3390763, lng: 19.6369188 },
    { lat: 49.3389686, lng: 19.6370565 },
    { lat: 49.3387394, lng: 19.6370669 },
    { lat: 49.3387093, lng: 19.6370484 },
    { lat: 49.3385981, lng: 19.6370867 },
    { lat: 49.3385621, lng: 19.6371356 },
    { lat: 49.338453, lng: 19.637088 },
    { lat: 49.3383189, lng: 19.6368852 },
    { lat: 49.3381559, lng: 19.6369675 },
    { lat: 49.3380459, lng: 19.6370534 },
    { lat: 49.3378608, lng: 19.6370744 },
    { lat: 49.3376808, lng: 19.6369501 },
    { lat: 49.3376771, lng: 19.6367637 },
    { lat: 49.3376233, lng: 19.6366956 },
    { lat: 49.3375946, lng: 19.6367362 },
    { lat: 49.3375451, lng: 19.6367637 },
    { lat: 49.3374972, lng: 19.6367748 },
    { lat: 49.3373629, lng: 19.6367436 },
    { lat: 49.3373254, lng: 19.6368339 },
    { lat: 49.3372629, lng: 19.6367721 },
    { lat: 49.3371792, lng: 19.6366623 },
    { lat: 49.3370912, lng: 19.6366118 },
    { lat: 49.337111, lng: 19.6364241 },
    { lat: 49.3370796, lng: 19.6363031 },
    { lat: 49.3370581, lng: 19.6362963 },
    { lat: 49.3370582, lng: 19.6362497 },
    { lat: 49.3370845, lng: 19.6362307 },
    { lat: 49.3371133, lng: 19.6362476 },
    { lat: 49.3371324, lng: 19.6362477 },
    { lat: 49.3371638, lng: 19.6361144 },
    { lat: 49.3370602, lng: 19.6360139 },
    { lat: 49.3369952, lng: 19.636076 },
    { lat: 49.3369472, lng: 19.6360593 },
    { lat: 49.3368673, lng: 19.6359079 },
    { lat: 49.3368764, lng: 19.6358087 },
    { lat: 49.3369323, lng: 19.6357769 },
    { lat: 49.336936, lng: 19.6356793 },
    { lat: 49.3368394, lng: 19.6355593 },
    { lat: 49.3368279, lng: 19.6356038 },
    { lat: 49.3367973, lng: 19.635602 },
    { lat: 49.336782, lng: 19.6355869 },
    { lat: 49.3367611, lng: 19.6354713 },
    { lat: 49.3366861, lng: 19.6353776 },
    { lat: 49.3366294, lng: 19.6355255 },
    { lat: 49.3366282, lng: 19.6354451 },
    { lat: 49.3365937, lng: 19.6352529 },
    { lat: 49.3365591, lng: 19.63516 },
    { lat: 49.3366407, lng: 19.635115 },
    { lat: 49.3366568, lng: 19.635074 },
    { lat: 49.3366651, lng: 19.6349568 },
    { lat: 49.3365707, lng: 19.6348373 },
    { lat: 49.3365574, lng: 19.6348387 },
    { lat: 49.3364645, lng: 19.6349809 },
    { lat: 49.3364372, lng: 19.6349989 },
    { lat: 49.3363632, lng: 19.6349318 },
    { lat: 49.3363443, lng: 19.6349367 },
    { lat: 49.3363165, lng: 19.6349692 },
    { lat: 49.3362708, lng: 19.6349078 },
    { lat: 49.3363259, lng: 19.6348255 },
    { lat: 49.336313, lng: 19.6347897 },
    { lat: 49.3362772, lng: 19.634771 },
    { lat: 49.3361529, lng: 19.6346622 },
    { lat: 49.3359947, lng: 19.6343965 },
    { lat: 49.3357626, lng: 19.6342931 },
    { lat: 49.3357059, lng: 19.6341339 },
    { lat: 49.3355502, lng: 19.6339753 },
    { lat: 49.335518, lng: 19.6338348 },
    { lat: 49.3354866, lng: 19.6337619 },
    { lat: 49.3354066, lng: 19.6337081 },
    { lat: 49.3353818, lng: 19.6337345 },
    { lat: 49.3353371, lng: 19.6340256 },
    { lat: 49.3352095, lng: 19.6340172 },
    { lat: 49.3351763, lng: 19.6340304 },
    { lat: 49.3351488, lng: 19.6340231 },
    { lat: 49.3351191, lng: 19.6339858 },
    { lat: 49.3348406, lng: 19.6339544 },
    { lat: 49.3346372, lng: 19.6339413 },
    { lat: 49.3344896, lng: 19.6339396 },
    { lat: 49.334478, lng: 19.6339072 },
    { lat: 49.3344596, lng: 19.6338983 },
    { lat: 49.3344213, lng: 19.6338488 },
    { lat: 49.3343982, lng: 19.6338332 },
    { lat: 49.3342312, lng: 19.6339593 },
    { lat: 49.3341126, lng: 19.6340731 },
    { lat: 49.3340837, lng: 19.6341874 },
    { lat: 49.3340604, lng: 19.6342209 },
    { lat: 49.3340081, lng: 19.6342346 },
    { lat: 49.3339005, lng: 19.6341934 },
    { lat: 49.3338645, lng: 19.634147 },
    { lat: 49.3338404, lng: 19.6341379 },
    { lat: 49.3338272, lng: 19.6341696 },
    { lat: 49.3338251, lng: 19.6343248 },
    { lat: 49.3338052, lng: 19.6344085 },
    { lat: 49.3336189, lng: 19.6344236 },
    { lat: 49.3335992, lng: 19.6344155 },
    { lat: 49.3334732, lng: 19.6342415 },
    { lat: 49.3334293, lng: 19.6342154 },
    { lat: 49.3332816, lng: 19.6340086 },
    { lat: 49.3331602, lng: 19.6339663 },
    { lat: 49.3331545, lng: 19.6339867 },
    { lat: 49.3331013, lng: 19.6340848 },
    { lat: 49.3330691, lng: 19.6341206 },
    { lat: 49.3329242, lng: 19.6342336 },
    { lat: 49.3329242, lng: 19.6342534 },
    { lat: 49.332881, lng: 19.6342489 },
    { lat: 49.3328592, lng: 19.6343155 },
    { lat: 49.3329039, lng: 19.634382 },
    { lat: 49.3329025, lng: 19.634402 },
    { lat: 49.3328272, lng: 19.6345565 },
    { lat: 49.3327122, lng: 19.6347097 },
    { lat: 49.3326458, lng: 19.6347094 },
    { lat: 49.3325706, lng: 19.6346897 },
    { lat: 49.3323666, lng: 19.6349457 },
    { lat: 49.3322999, lng: 19.6349906 },
    { lat: 49.3321488, lng: 19.6352397 },
    { lat: 49.331909, lng: 19.6352668 },
    { lat: 49.3316759, lng: 19.6352166 },
    { lat: 49.3316199, lng: 19.6352521 },
    { lat: 49.3315733, lng: 19.6353667 },
    { lat: 49.3315033, lng: 19.6354386 },
    { lat: 49.331426, lng: 19.6354792 },
    { lat: 49.3313325, lng: 19.6354271 },
    { lat: 49.3312671, lng: 19.6354253 },
    { lat: 49.3310208, lng: 19.6352063 },
    { lat: 49.3308608, lng: 19.6352544 },
    { lat: 49.3305809, lng: 19.6354742 },
    { lat: 49.3303877, lng: 19.6355644 },
    { lat: 49.3302469, lng: 19.6357163 },
    { lat: 49.3299648, lng: 19.6358323 },
    { lat: 49.3296809, lng: 19.6361591 },
    { lat: 49.3294881, lng: 19.6363623 },
    { lat: 49.3292842, lng: 19.636486 },
    { lat: 49.329128, lng: 19.6366735 },
    { lat: 49.3288276, lng: 19.6369648 },
    { lat: 49.3287101, lng: 19.6373371 },
    { lat: 49.3285907, lng: 19.6375399 },
    { lat: 49.3285366, lng: 19.6377413 },
    { lat: 49.3284122, lng: 19.6378593 },
    { lat: 49.3282862, lng: 19.6381632 },
    { lat: 49.3282029, lng: 19.6383177 },
    { lat: 49.3280452, lng: 19.6387502 },
    { lat: 49.3275253, lng: 19.6393496 },
    { lat: 49.3273995, lng: 19.6394548 },
    { lat: 49.3269239, lng: 19.6396324 },
    { lat: 49.3266502, lng: 19.6396851 },
    { lat: 49.3265255, lng: 19.639715 },
    { lat: 49.3263339, lng: 19.639819 },
    { lat: 49.326165, lng: 19.6399215 },
    { lat: 49.325878, lng: 19.6401037 },
    { lat: 49.3256452, lng: 19.6401358 },
    { lat: 49.3237947, lng: 19.6404306 },
    { lat: 49.3228233, lng: 19.6404188 },
    { lat: 49.3224506, lng: 19.6404265 },
    { lat: 49.3221243, lng: 19.6403563 },
    { lat: 49.3217364, lng: 19.6401738 },
    { lat: 49.3212909, lng: 19.6399553 },
  ],
  Habovka: [
    { lat: 49.290174, lng: 19.6674333 },
    { lat: 49.2897915, lng: 19.6684144 },
    { lat: 49.2889875, lng: 19.6693126 },
    { lat: 49.2885563, lng: 19.6705719 },
    { lat: 49.2883379, lng: 19.6713141 },
    { lat: 49.288246, lng: 19.6721897 },
    { lat: 49.2882641, lng: 19.6725783 },
    { lat: 49.288321, lng: 19.6729951 },
    { lat: 49.2883072, lng: 19.6735541 },
    { lat: 49.2882167, lng: 19.6736439 },
    { lat: 49.2878965, lng: 19.6739461 },
    { lat: 49.2872085, lng: 19.6744624 },
    { lat: 49.2866396, lng: 19.6753572 },
    { lat: 49.2859259, lng: 19.6761843 },
    { lat: 49.2852708, lng: 19.6765066 },
    { lat: 49.2843056, lng: 19.6769731 },
    { lat: 49.2839517, lng: 19.6777118 },
    { lat: 49.283268, lng: 19.6785288 },
    { lat: 49.2829107, lng: 19.6790355 },
    { lat: 49.2825884, lng: 19.679209 },
    { lat: 49.2809031, lng: 19.6809497 },
    { lat: 49.2806708, lng: 19.6812504 },
    { lat: 49.2801905, lng: 19.6816275 },
    { lat: 49.2785915, lng: 19.682486 },
    { lat: 49.2780903, lng: 19.6823618 },
    { lat: 49.2780727, lng: 19.6823694 },
    { lat: 49.2779643, lng: 19.6818274 },
    { lat: 49.2775112, lng: 19.6796251 },
    { lat: 49.2774563, lng: 19.6793486 },
    { lat: 49.2770978, lng: 19.677606 },
    { lat: 49.2768296, lng: 19.6762226 },
    { lat: 49.276405, lng: 19.6740578 },
    { lat: 49.2762362, lng: 19.6731703 },
    { lat: 49.275884, lng: 19.6714924 },
    { lat: 49.2755638, lng: 19.6698275 },
    { lat: 49.2752887, lng: 19.6684463 },
    { lat: 49.2757093, lng: 19.6685833 },
    { lat: 49.275777, lng: 19.6686087 },
    { lat: 49.2761438, lng: 19.668725 },
    { lat: 49.2765633, lng: 19.6688642 },
    { lat: 49.2768217, lng: 19.6689534 },
    { lat: 49.2769015, lng: 19.6689751 },
    { lat: 49.2769642, lng: 19.6689625 },
    { lat: 49.2772655, lng: 19.6689267 },
    { lat: 49.2775494, lng: 19.6688974 },
    { lat: 49.2778307, lng: 19.6688633 },
    { lat: 49.2781648, lng: 19.6688288 },
    { lat: 49.2791388, lng: 19.6687153 },
    { lat: 49.2795438, lng: 19.6686804 },
    { lat: 49.2798542, lng: 19.6686642 },
    { lat: 49.280032, lng: 19.6686592 },
    { lat: 49.2806354, lng: 19.6686294 },
    { lat: 49.2807453, lng: 19.6685878 },
    { lat: 49.2812567, lng: 19.6683596 },
    { lat: 49.2814604, lng: 19.6682791 },
    { lat: 49.2816016, lng: 19.6681367 },
    { lat: 49.2821866, lng: 19.6675268 },
    { lat: 49.2824237, lng: 19.6672898 },
    { lat: 49.2825809, lng: 19.6671223 },
    { lat: 49.2827007, lng: 19.6670032 },
    { lat: 49.2829869, lng: 19.6667004 },
    { lat: 49.2831738, lng: 19.6665159 },
    { lat: 49.2833881, lng: 19.6662711 },
    { lat: 49.2839002, lng: 19.6655925 },
    { lat: 49.284062, lng: 19.665382 },
    { lat: 49.2842541, lng: 19.6650864 },
    { lat: 49.2847282, lng: 19.664346 },
    { lat: 49.2854018, lng: 19.6633753 },
    { lat: 49.2858531, lng: 19.6629852 },
    { lat: 49.286116, lng: 19.6627642 },
    { lat: 49.2865283, lng: 19.6625061 },
    { lat: 49.2870561, lng: 19.6623166 },
    { lat: 49.2878511, lng: 19.6622312 },
    { lat: 49.2884678, lng: 19.6616337 },
    { lat: 49.2888279, lng: 19.6608364 },
    { lat: 49.2897131, lng: 19.6588927 },
    { lat: 49.290411, lng: 19.6573787 },
    { lat: 49.2905105, lng: 19.656726 },
    { lat: 49.2908281, lng: 19.6547391 },
    { lat: 49.2908531, lng: 19.6544344 },
    { lat: 49.2908483, lng: 19.6539755 },
    { lat: 49.2908226, lng: 19.6533418 },
    { lat: 49.2906784, lng: 19.6525801 },
    { lat: 49.2903493, lng: 19.651456 },
    { lat: 49.2901802, lng: 19.6508935 },
    { lat: 49.2901291, lng: 19.6506833 },
    { lat: 49.2900127, lng: 19.6504175 },
    { lat: 49.2899231, lng: 19.6502817 },
    { lat: 49.2897874, lng: 19.6500366 },
    { lat: 49.2897325, lng: 19.6498911 },
    { lat: 49.2895543, lng: 19.649305 },
    { lat: 49.2894626, lng: 19.6489626 },
    { lat: 49.2893245, lng: 19.6485947 },
    { lat: 49.2892228, lng: 19.648309 },
    { lat: 49.2891458, lng: 19.648021 },
    { lat: 49.2890402, lng: 19.6475934 },
    { lat: 49.2890038, lng: 19.6474579 },
    { lat: 49.2888893, lng: 19.6470847 },
    { lat: 49.2888142, lng: 19.6468673 },
    { lat: 49.2887519, lng: 19.6466287 },
    { lat: 49.2886329, lng: 19.6463606 },
    { lat: 49.2885976, lng: 19.6462524 },
    { lat: 49.2885283, lng: 19.6461105 },
    { lat: 49.2885119, lng: 19.6460454 },
    { lat: 49.2883983, lng: 19.6458721 },
    { lat: 49.28833, lng: 19.6457802 },
    { lat: 49.2882213, lng: 19.6456132 },
    { lat: 49.2881745, lng: 19.6454994 },
    { lat: 49.2881363, lng: 19.6453247 },
    { lat: 49.2882244, lng: 19.6448207 },
    { lat: 49.2882663, lng: 19.6445955 },
    { lat: 49.2883472, lng: 19.6437307 },
    { lat: 49.2883422, lng: 19.6431354 },
    { lat: 49.2882517, lng: 19.6426449 },
    { lat: 49.2882289, lng: 19.6423055 },
    { lat: 49.2881825, lng: 19.6414687 },
    { lat: 49.2881987, lng: 19.6411956 },
    { lat: 49.2882217, lng: 19.6407248 },
    { lat: 49.2882953, lng: 19.6403504 },
    { lat: 49.2883021, lng: 19.6398225 },
    { lat: 49.2883347, lng: 19.639692 },
    { lat: 49.288458, lng: 19.639144 },
    { lat: 49.2886358, lng: 19.6385835 },
    { lat: 49.2886635, lng: 19.6377602 },
    { lat: 49.288739, lng: 19.6372291 },
    { lat: 49.2887794, lng: 19.6370748 },
    { lat: 49.2889651, lng: 19.6364091 },
    { lat: 49.2890072, lng: 19.6362932 },
    { lat: 49.2891048, lng: 19.6360571 },
    { lat: 49.2892993, lng: 19.635615 },
    { lat: 49.2894015, lng: 19.6355091 },
    { lat: 49.2895489, lng: 19.6353772 },
    { lat: 49.2898226, lng: 19.6352004 },
    { lat: 49.2899455, lng: 19.6349224 },
    { lat: 49.2900414, lng: 19.6347201 },
    { lat: 49.2902033, lng: 19.6344852 },
    { lat: 49.2903637, lng: 19.6342643 },
    { lat: 49.2905053, lng: 19.6340579 },
    { lat: 49.2906897, lng: 19.6337987 },
    { lat: 49.2908335, lng: 19.6336066 },
    { lat: 49.2909812, lng: 19.6333826 },
    { lat: 49.2911837, lng: 19.6330995 },
    { lat: 49.2912836, lng: 19.6329358 },
    { lat: 49.2914209, lng: 19.6327313 },
    { lat: 49.2917136, lng: 19.6322692 },
    { lat: 49.2917844, lng: 19.632168 },
    { lat: 49.2920341, lng: 19.6317782 },
    { lat: 49.2922276, lng: 19.63147 },
    { lat: 49.2926968, lng: 19.6307449 },
    { lat: 49.2927239, lng: 19.6306966 },
    { lat: 49.2931085, lng: 19.6297152 },
    { lat: 49.2932667, lng: 19.6292977 },
    { lat: 49.2929422, lng: 19.628536 },
    { lat: 49.2927444, lng: 19.6276881 },
    { lat: 49.2927505, lng: 19.6272414 },
    { lat: 49.2927491, lng: 19.6270607 },
    { lat: 49.2926805, lng: 19.6268617 },
    { lat: 49.2926754, lng: 19.6263173 },
    { lat: 49.2926283, lng: 19.626093 },
    { lat: 49.2925583, lng: 19.625687 },
    { lat: 49.2925419, lng: 19.6252122 },
    { lat: 49.2924211, lng: 19.624853 },
    { lat: 49.2923838, lng: 19.6247273 },
    { lat: 49.2923628, lng: 19.6246108 },
    { lat: 49.2923279, lng: 19.62433 },
    { lat: 49.2923057, lng: 19.6242348 },
    { lat: 49.2922821, lng: 19.6240601 },
    { lat: 49.2922416, lng: 19.6236333 },
    { lat: 49.2919805, lng: 19.622551 },
    { lat: 49.291904, lng: 19.62225 },
    { lat: 49.2917108, lng: 19.6214537 },
    { lat: 49.2916049, lng: 19.6209991 },
    { lat: 49.2914972, lng: 19.6205794 },
    { lat: 49.2914665, lng: 19.6203465 },
    { lat: 49.291455, lng: 19.6201622 },
    { lat: 49.2914357, lng: 19.6196568 },
    { lat: 49.2914034, lng: 19.6193078 },
    { lat: 49.2914008, lng: 19.6191006 },
    { lat: 49.2914204, lng: 19.6189058 },
    { lat: 49.2914575, lng: 19.6187312 },
    { lat: 49.2914937, lng: 19.6186332 },
    { lat: 49.2915323, lng: 19.6184866 },
    { lat: 49.2916274, lng: 19.6182215 },
    { lat: 49.2916626, lng: 19.6181002 },
    { lat: 49.2918068, lng: 19.6177719 },
    { lat: 49.2918767, lng: 19.6175975 },
    { lat: 49.2919757, lng: 19.6173139 },
    { lat: 49.2920572, lng: 19.6171701 },
    { lat: 49.2924348, lng: 19.6166445 },
    { lat: 49.2927291, lng: 19.6161439 },
    { lat: 49.292977, lng: 19.6157906 },
    { lat: 49.293129, lng: 19.6155615 },
    { lat: 49.2933642, lng: 19.6150774 },
    { lat: 49.2934495, lng: 19.6149206 },
    { lat: 49.2935638, lng: 19.614779 },
    { lat: 49.293674, lng: 19.6146225 },
    { lat: 49.2937715, lng: 19.6144336 },
    { lat: 49.293812, lng: 19.6143308 },
    { lat: 49.293926, lng: 19.6140854 },
    { lat: 49.2939916, lng: 19.6139645 },
    { lat: 49.2941154, lng: 19.6137856 },
    { lat: 49.2942546, lng: 19.6135999 },
    { lat: 49.2943158, lng: 19.613478 },
    { lat: 49.2943623, lng: 19.6133349 },
    { lat: 49.2944113, lng: 19.6132695 },
    { lat: 49.2945132, lng: 19.6130026 },
    { lat: 49.2945492, lng: 19.6128271 },
    { lat: 49.2945975, lng: 19.6126701 },
    { lat: 49.2946275, lng: 19.6125391 },
    { lat: 49.2946608, lng: 19.6123483 },
    { lat: 49.2946691, lng: 19.6122067 },
    { lat: 49.2946702, lng: 19.6120304 },
    { lat: 49.2946854, lng: 19.6116365 },
    { lat: 49.2947017, lng: 19.6112983 },
    { lat: 49.294739, lng: 19.6109707 },
    { lat: 49.294778, lng: 19.610484 },
    { lat: 49.2948783, lng: 19.6100032 },
    { lat: 49.2949041, lng: 19.6098254 },
    { lat: 49.2949575, lng: 19.609609 },
    { lat: 49.2950006, lng: 19.6094104 },
    { lat: 49.2950536, lng: 19.6092135 },
    { lat: 49.2951785, lng: 19.6088316 },
    { lat: 49.2952833, lng: 19.608604 },
    { lat: 49.2953949, lng: 19.6083032 },
    { lat: 49.2955029, lng: 19.6080978 },
    { lat: 49.2955884, lng: 19.6080199 },
    { lat: 49.2956359, lng: 19.6078943 },
    { lat: 49.2956924, lng: 19.6077 },
    { lat: 49.2959258, lng: 19.6071973 },
    { lat: 49.2959921, lng: 19.6070164 },
    { lat: 49.2960555, lng: 19.6067503 },
    { lat: 49.2961973, lng: 19.6065413 },
    { lat: 49.2963855, lng: 19.6062942 },
    { lat: 49.2964885, lng: 19.6061282 },
    { lat: 49.2965957, lng: 19.6059047 },
    { lat: 49.2966976, lng: 19.6056136 },
    { lat: 49.2967568, lng: 19.6053749 },
    { lat: 49.2968212, lng: 19.6050783 },
    { lat: 49.296885, lng: 19.6048417 },
    { lat: 49.2969877, lng: 19.6045144 },
    { lat: 49.2970754, lng: 19.6042833 },
    { lat: 49.297177, lng: 19.6039636 },
    { lat: 49.2972841, lng: 19.6036618 },
    { lat: 49.2973787, lng: 19.6033585 },
    { lat: 49.2975042, lng: 19.603119 },
    { lat: 49.2976138, lng: 19.6028982 },
    { lat: 49.2976462, lng: 19.602815 },
    { lat: 49.2978002, lng: 19.6025794 },
    { lat: 49.2980065, lng: 19.6021572 },
    { lat: 49.2981911, lng: 19.6018491 },
    { lat: 49.2983199, lng: 19.6015778 },
    { lat: 49.2983566, lng: 19.601344 },
    { lat: 49.298386, lng: 19.6011933 },
    { lat: 49.2984172, lng: 19.6009821 },
    { lat: 49.2984656, lng: 19.6008078 },
    { lat: 49.2985379, lng: 19.6006352 },
    { lat: 49.2985909, lng: 19.6004846 },
    { lat: 49.2986759, lng: 19.6002688 },
    { lat: 49.2987677, lng: 19.6000848 },
    { lat: 49.2988982, lng: 19.5998506 },
    { lat: 49.299114, lng: 19.5994862 },
    { lat: 49.2992627, lng: 19.5991854 },
    { lat: 49.2993319, lng: 19.5990703 },
    { lat: 49.2994222, lng: 19.5988727 },
    { lat: 49.2995758, lng: 19.5985011 },
    { lat: 49.2995722, lng: 19.5985007 },
    { lat: 49.2997794, lng: 19.5977806 },
    { lat: 49.3001242, lng: 19.5966069 },
    { lat: 49.3011506, lng: 19.5940705 },
    { lat: 49.3013639, lng: 19.5931712 },
    { lat: 49.3017115, lng: 19.5899171 },
    { lat: 49.301713, lng: 19.5898761 },
    { lat: 49.3017021, lng: 19.5897816 },
    { lat: 49.3013518, lng: 19.5858662 },
    { lat: 49.3011051, lng: 19.584335 },
    { lat: 49.3004023, lng: 19.5843398 },
    { lat: 49.3000999, lng: 19.5843041 },
    { lat: 49.2993287, lng: 19.5845691 },
    { lat: 49.2979843, lng: 19.5853874 },
    { lat: 49.2967721, lng: 19.5852153 },
    { lat: 49.2949577, lng: 19.584826 },
    { lat: 49.2936486, lng: 19.5844995 },
    { lat: 49.2927631, lng: 19.5841993 },
    { lat: 49.2919341, lng: 19.5838041 },
    { lat: 49.2916127, lng: 19.5837494 },
    { lat: 49.2913975, lng: 19.5837467 },
    { lat: 49.2912137, lng: 19.5836692 },
    { lat: 49.2909743, lng: 19.5834536 },
    { lat: 49.2908295, lng: 19.5833681 },
    { lat: 49.2907483, lng: 19.5833369 },
    { lat: 49.2906964, lng: 19.5832861 },
    { lat: 49.2905638, lng: 19.5832722 },
    { lat: 49.2902367, lng: 19.5831105 },
    { lat: 49.2899523, lng: 19.5828982 },
    { lat: 49.2898375, lng: 19.5827532 },
    { lat: 49.2893159, lng: 19.5822532 },
    { lat: 49.2888172, lng: 19.5817819 },
    { lat: 49.2887377, lng: 19.5816956 },
    { lat: 49.2884344, lng: 19.5819653 },
    { lat: 49.2882249, lng: 19.5823399 },
    { lat: 49.2879033, lng: 19.58318 },
    { lat: 49.2877873, lng: 19.5839108 },
    { lat: 49.2878331, lng: 19.5839782 },
    { lat: 49.2877012, lng: 19.584507 },
    { lat: 49.2876176, lng: 19.5849536 },
    { lat: 49.2875855, lng: 19.5853535 },
    { lat: 49.2875288, lng: 19.585483 },
    { lat: 49.2873355, lng: 19.5856682 },
    { lat: 49.2871751, lng: 19.5857338 },
    { lat: 49.2870512, lng: 19.5857619 },
    { lat: 49.2868916, lng: 19.5858191 },
    { lat: 49.2863184, lng: 19.5859001 },
    { lat: 49.2862437, lng: 19.585865 },
    { lat: 49.2859407, lng: 19.5859935 },
    { lat: 49.2858066, lng: 19.5860412 },
    { lat: 49.2855914, lng: 19.5860922 },
    { lat: 49.2854942, lng: 19.5860891 },
    { lat: 49.2852887, lng: 19.5863015 },
    { lat: 49.2852131, lng: 19.5864733 },
    { lat: 49.2851804, lng: 19.5866031 },
    { lat: 49.2851654, lng: 19.5867955 },
    { lat: 49.2851487, lng: 19.5872492 },
    { lat: 49.2851124, lng: 19.5875233 },
    { lat: 49.2850411, lng: 19.5877394 },
    { lat: 49.2847084, lng: 19.5883387 },
    { lat: 49.2845946, lng: 19.5882109 },
    { lat: 49.2843214, lng: 19.5879476 },
    { lat: 49.2840797, lng: 19.5877802 },
    { lat: 49.2838325, lng: 19.5877488 },
    { lat: 49.2837567, lng: 19.5877636 },
    { lat: 49.2836598, lng: 19.5876406 },
    { lat: 49.2833336, lng: 19.587451 },
    { lat: 49.2832105, lng: 19.5875181 },
    { lat: 49.2831112, lng: 19.5875404 },
    { lat: 49.283024, lng: 19.5876029 },
    { lat: 49.2827331, lng: 19.5873227 },
    { lat: 49.2824872, lng: 19.5870407 },
    { lat: 49.2820071, lng: 19.5868377 },
    { lat: 49.2818777, lng: 19.5869162 },
    { lat: 49.2818116, lng: 19.5870018 },
    { lat: 49.2816832, lng: 19.5871004 },
    { lat: 49.2816195, lng: 19.5872652 },
    { lat: 49.2814568, lng: 19.587353 },
    { lat: 49.2812479, lng: 19.5875448 },
    { lat: 49.2811586, lng: 19.5877105 },
    { lat: 49.28093, lng: 19.587842 },
    { lat: 49.280797, lng: 19.5880916 },
    { lat: 49.2805823, lng: 19.5881011 },
    { lat: 49.2802012, lng: 19.5879674 },
    { lat: 49.2799191, lng: 19.5881107 },
    { lat: 49.2796557, lng: 19.5882173 },
    { lat: 49.2795375, lng: 19.5882921 },
    { lat: 49.2793013, lng: 19.5883537 },
    { lat: 49.2792411, lng: 19.5883164 },
    { lat: 49.2791598, lng: 19.5883613 },
    { lat: 49.2791449, lng: 19.5884813 },
    { lat: 49.2790819, lng: 19.5883825 },
    { lat: 49.2787761, lng: 19.5884965 },
    { lat: 49.2785525, lng: 19.5884607 },
    { lat: 49.2784021, lng: 19.5884757 },
    { lat: 49.2782094, lng: 19.5884743 },
    { lat: 49.2780049, lng: 19.5885091 },
    { lat: 49.2736577, lng: 19.5817224 },
    { lat: 49.2730329, lng: 19.5826362 },
    { lat: 49.2722418, lng: 19.5829086 },
    { lat: 49.2717754, lng: 19.5831051 },
    { lat: 49.2709929, lng: 19.583972 },
    { lat: 49.2701175, lng: 19.5846245 },
    { lat: 49.2696074, lng: 19.5848558 },
    { lat: 49.2688298, lng: 19.5851535 },
    { lat: 49.2686696, lng: 19.5853225 },
    { lat: 49.268509, lng: 19.5841813 },
    { lat: 49.2684099, lng: 19.5836822 },
    { lat: 49.2682852, lng: 19.5836881 },
    { lat: 49.2680903, lng: 19.5837374 },
    { lat: 49.2678264, lng: 19.5838589 },
    { lat: 49.2672522, lng: 19.5841937 },
    { lat: 49.2670446, lng: 19.5842865 },
    { lat: 49.2668611, lng: 19.5844169 },
    { lat: 49.2666167, lng: 19.5845421 },
    { lat: 49.2664754, lng: 19.5846561 },
    { lat: 49.2662071, lng: 19.5847795 },
    { lat: 49.2659782, lng: 19.5848757 },
    { lat: 49.2657136, lng: 19.5850567 },
    { lat: 49.2654224, lng: 19.5851702 },
    { lat: 49.2659343, lng: 19.5866065 },
    { lat: 49.2661875, lng: 19.5893391 },
    { lat: 49.2663149, lng: 19.590368 },
    { lat: 49.2666973, lng: 19.5910837 },
    { lat: 49.2668842, lng: 19.5923344 },
    { lat: 49.2668843, lng: 19.5925152 },
    { lat: 49.2667569, lng: 19.5926865 },
    { lat: 49.2664455, lng: 19.5933331 },
    { lat: 49.2661146, lng: 19.5938685 },
    { lat: 49.2662356, lng: 19.5941275 },
    { lat: 49.2664718, lng: 19.594347 },
    { lat: 49.2672654, lng: 19.5951879 },
    { lat: 49.2674436, lng: 19.5953399 },
    { lat: 49.2675063, lng: 19.5957117 },
    { lat: 49.2675438, lng: 19.5962937 },
    { lat: 49.2676739, lng: 19.597059 },
    { lat: 49.2678838, lng: 19.5970699 },
    { lat: 49.2679781, lng: 19.5972884 },
    { lat: 49.2683921, lng: 19.598029 },
    { lat: 49.2685478, lng: 19.5982581 },
    { lat: 49.2689007, lng: 19.5993966 },
    { lat: 49.2689619, lng: 19.5997567 },
    { lat: 49.2692309, lng: 19.6007067 },
    { lat: 49.2693412, lng: 19.6016631 },
    { lat: 49.269446, lng: 19.6024711 },
    { lat: 49.2699209, lng: 19.6044737 },
    { lat: 49.2704007, lng: 19.6064804 },
    { lat: 49.2704721, lng: 19.6070224 },
    { lat: 49.2704969, lng: 19.6073485 },
    { lat: 49.2705124, lng: 19.607695 },
    { lat: 49.2705216, lng: 19.6080805 },
    { lat: 49.2705625, lng: 19.6086899 },
    { lat: 49.2706174, lng: 19.6090616 },
    { lat: 49.27075, lng: 19.6096102 },
    { lat: 49.2707842, lng: 19.6097675 },
    { lat: 49.2708063, lng: 19.6097876 },
    { lat: 49.2708626, lng: 19.6103117 },
    { lat: 49.2709586, lng: 19.6114793 },
    { lat: 49.2710733, lng: 19.6115261 },
    { lat: 49.2710749, lng: 19.6119882 },
    { lat: 49.2711521, lng: 19.6141986 },
    { lat: 49.2711577, lng: 19.614272 },
    { lat: 49.2713224, lng: 19.6170037 },
    { lat: 49.2710237, lng: 19.6200838 },
    { lat: 49.2707266, lng: 19.6220067 },
    { lat: 49.2701207, lng: 19.6243618 },
    { lat: 49.2697137, lng: 19.6254945 },
    { lat: 49.2685968, lng: 19.6277367 },
    { lat: 49.2681743, lng: 19.6287987 },
    { lat: 49.2672983, lng: 19.6326031 },
    { lat: 49.267127, lng: 19.6334657 },
    { lat: 49.2666506, lng: 19.63638 },
    { lat: 49.2663892, lng: 19.6380557 },
    { lat: 49.2654892, lng: 19.642166 },
    { lat: 49.2650841, lng: 19.6446329 },
    { lat: 49.2649922, lng: 19.6450511 },
    { lat: 49.264789, lng: 19.6455 },
    { lat: 49.2642178, lng: 19.6464643 },
    { lat: 49.264124, lng: 19.6468621 },
    { lat: 49.2636822, lng: 19.6488122 },
    { lat: 49.2635967, lng: 19.6500897 },
    { lat: 49.2635344, lng: 19.6514247 },
    { lat: 49.2638771, lng: 19.6532866 },
    { lat: 49.2642128, lng: 19.6542998 },
    { lat: 49.2646955, lng: 19.6548469 },
    { lat: 49.2651472, lng: 19.6553279 },
    { lat: 49.2660134, lng: 19.6564958 },
    { lat: 49.2655235, lng: 19.6578484 },
    { lat: 49.2647669, lng: 19.6594125 },
    { lat: 49.2641324, lng: 19.6613913 },
    { lat: 49.2632386, lng: 19.6628043 },
    { lat: 49.2622602, lng: 19.6637624 },
    { lat: 49.2622183, lng: 19.6638067 },
    { lat: 49.2621768, lng: 19.6638355 },
    { lat: 49.2615613, lng: 19.6645913 },
    { lat: 49.2607303, lng: 19.6648798 },
    { lat: 49.2605912, lng: 19.6648469 },
    { lat: 49.2603652, lng: 19.664674 },
    { lat: 49.2601151, lng: 19.6636827 },
    { lat: 49.2599576, lng: 19.6638901 },
    { lat: 49.2598825, lng: 19.6640014 },
    { lat: 49.2595732, lng: 19.6645246 },
    { lat: 49.2594791, lng: 19.6646606 },
    { lat: 49.2593765, lng: 19.6648861 },
    { lat: 49.2593467, lng: 19.6649278 },
    { lat: 49.2588139, lng: 19.6654892 },
    { lat: 49.2588073, lng: 19.6660003 },
    { lat: 49.2588987, lng: 19.6665138 },
    { lat: 49.2588636, lng: 19.6669954 },
    { lat: 49.2586806, lng: 19.6672898 },
    { lat: 49.2583697, lng: 19.6675192 },
    { lat: 49.2581825, lng: 19.667769 },
    { lat: 49.2581476, lng: 19.6678249 },
    { lat: 49.2580985, lng: 19.6682938 },
    { lat: 49.2579943, lng: 19.6686883 },
    { lat: 49.2579579, lng: 19.6687559 },
    { lat: 49.2576871, lng: 19.6689479 },
    { lat: 49.2574711, lng: 19.66906 },
    { lat: 49.2572358, lng: 19.6691416 },
    { lat: 49.2570275, lng: 19.669395 },
    { lat: 49.2569016, lng: 19.6696338 },
    { lat: 49.256901, lng: 19.6698723 },
    { lat: 49.2569651, lng: 19.6702808 },
    { lat: 49.2571052, lng: 19.6708968 },
    { lat: 49.2572214, lng: 19.6715401 },
    { lat: 49.2573024, lng: 19.6720223 },
    { lat: 49.2572876, lng: 19.6723286 },
    { lat: 49.2572036, lng: 19.6724887 },
    { lat: 49.2570186, lng: 19.672533 },
    { lat: 49.2566788, lng: 19.6725432 },
    { lat: 49.2563974, lng: 19.6726541 },
    { lat: 49.2561378, lng: 19.6729986 },
    { lat: 49.2556332, lng: 19.6738675 },
    { lat: 49.2554955, lng: 19.6743195 },
    { lat: 49.2554922, lng: 19.6748056 },
    { lat: 49.2555411, lng: 19.6751007 },
    { lat: 49.2557997, lng: 19.6758033 },
    { lat: 49.2558583, lng: 19.676159 },
    { lat: 49.2558999, lng: 19.6766665 },
    { lat: 49.2558186, lng: 19.6770186 },
    { lat: 49.2557448, lng: 19.6773147 },
    { lat: 49.2556061, lng: 19.6778462 },
    { lat: 49.2556538, lng: 19.6784197 },
    { lat: 49.2556851, lng: 19.6786416 },
    { lat: 49.2557645, lng: 19.6787827 },
    { lat: 49.2557958, lng: 19.6789786 },
    { lat: 49.2558672, lng: 19.6792435 },
    { lat: 49.2561156, lng: 19.6800539 },
    { lat: 49.2561367, lng: 19.6802216 },
    { lat: 49.2561404, lng: 19.680434 },
    { lat: 49.2560963, lng: 19.680735 },
    { lat: 49.2558712, lng: 19.6812509 },
    { lat: 49.2557443, lng: 19.6821057 },
    { lat: 49.2556131, lng: 19.6827868 },
    { lat: 49.2554642, lng: 19.6833391 },
    { lat: 49.2554324, lng: 19.6836936 },
    { lat: 49.2555165, lng: 19.6840157 },
    { lat: 49.2556127, lng: 19.6843081 },
    { lat: 49.2556837, lng: 19.6845911 },
    { lat: 49.2556492, lng: 19.6847789 },
    { lat: 49.2554532, lng: 19.6850869 },
    { lat: 49.2552466, lng: 19.6853061 },
    { lat: 49.2551781, lng: 19.6854884 },
    { lat: 49.2551339, lng: 19.6856906 },
    { lat: 49.2552229, lng: 19.6862751 },
    { lat: 49.255473, lng: 19.6870161 },
    { lat: 49.2556723, lng: 19.6872961 },
    { lat: 49.2556964, lng: 19.6873621 },
    { lat: 49.2557055, lng: 19.6874398 },
    { lat: 49.2557045, lng: 19.688488 },
    { lat: 49.2559409, lng: 19.6892034 },
    { lat: 49.2559429, lng: 19.689399 },
    { lat: 49.2558617, lng: 19.6895509 },
    { lat: 49.2551728, lng: 19.6903585 },
    { lat: 49.2550675, lng: 19.6906535 },
    { lat: 49.2551356, lng: 19.6908961 },
    { lat: 49.2553235, lng: 19.6912991 },
    { lat: 49.255484, lng: 19.6916678 },
    { lat: 49.2555083, lng: 19.6919422 },
    { lat: 49.2555302, lng: 19.6923854 },
    { lat: 49.2555283, lng: 19.6928321 },
    { lat: 49.2555472, lng: 19.6931046 },
    { lat: 49.2555209, lng: 19.6932541 },
    { lat: 49.2553433, lng: 19.6936175 },
    { lat: 49.2551403, lng: 19.6940238 },
    { lat: 49.2550248, lng: 19.6943184 },
    { lat: 49.2547905, lng: 19.6947291 },
    { lat: 49.2544628, lng: 19.6952914 },
    { lat: 49.2540364, lng: 19.695843 },
    { lat: 49.2536773, lng: 19.6963137 },
    { lat: 49.2536026, lng: 19.6965871 },
    { lat: 49.253614, lng: 19.6967709 },
    { lat: 49.2537489, lng: 19.6972515 },
    { lat: 49.2537638, lng: 19.6972298 },
    { lat: 49.253879, lng: 19.697134 },
    { lat: 49.253967, lng: 19.6971383 },
    { lat: 49.2555877, lng: 19.698156 },
    { lat: 49.2578295, lng: 19.6986636 },
    { lat: 49.2577958, lng: 19.6994386 },
    { lat: 49.2577631, lng: 19.699593 },
    { lat: 49.2578015, lng: 19.6996706 },
    { lat: 49.2577933, lng: 19.6999726 },
    { lat: 49.2578415, lng: 19.7001198 },
    { lat: 49.2578752, lng: 19.7002679 },
    { lat: 49.2579958, lng: 19.7003745 },
    { lat: 49.2580311, lng: 19.7004852 },
    { lat: 49.2579865, lng: 19.7007019 },
    { lat: 49.2580112, lng: 19.7011069 },
    { lat: 49.2580761, lng: 19.7016117 },
    { lat: 49.2580743, lng: 19.7017767 },
    { lat: 49.2581709, lng: 19.7020297 },
    { lat: 49.258167, lng: 19.7021711 },
    { lat: 49.2582987, lng: 19.7024231 },
    { lat: 49.2584247, lng: 19.7035257 },
    { lat: 49.2585627, lng: 19.7043844 },
    { lat: 49.2585197, lng: 19.7045828 },
    { lat: 49.2585723, lng: 19.7047037 },
    { lat: 49.2588578, lng: 19.7060789 },
    { lat: 49.2590541, lng: 19.7071841 },
    { lat: 49.2590512, lng: 19.707329 },
    { lat: 49.2591416, lng: 19.7074359 },
    { lat: 49.2591106, lng: 19.7077041 },
    { lat: 49.259117, lng: 19.708286 },
    { lat: 49.2590511, lng: 19.7086339 },
    { lat: 49.2590465, lng: 19.709558 },
    { lat: 49.2590274, lng: 19.7097897 },
    { lat: 49.261232, lng: 19.7122847 },
    { lat: 49.2627722, lng: 19.713984 },
    { lat: 49.2626502, lng: 19.7146699 },
    { lat: 49.2625916, lng: 19.7151859 },
    { lat: 49.2623947, lng: 19.7157583 },
    { lat: 49.2623449, lng: 19.7161133 },
    { lat: 49.2623751, lng: 19.7165985 },
    { lat: 49.2624181, lng: 19.7168593 },
    { lat: 49.2624284, lng: 19.7174014 },
    { lat: 49.2623202, lng: 19.7178626 },
    { lat: 49.262227, lng: 19.7184417 },
    { lat: 49.2620168, lng: 19.7189612 },
    { lat: 49.2620106, lng: 19.7193875 },
    { lat: 49.2617205, lng: 19.7198622 },
    { lat: 49.2616902, lng: 19.7201246 },
    { lat: 49.2615458, lng: 19.7203529 },
    { lat: 49.2612657, lng: 19.7203592 },
    { lat: 49.2611147, lng: 19.7213665 },
    { lat: 49.2615982, lng: 19.7211613 },
    { lat: 49.262628, lng: 19.7207059 },
    { lat: 49.2681992, lng: 19.7182729 },
    { lat: 49.2696977, lng: 19.7168867 },
    { lat: 49.2712379, lng: 19.7154767 },
    { lat: 49.2720485, lng: 19.7147495 },
    { lat: 49.2722032, lng: 19.7145995 },
    { lat: 49.2728747, lng: 19.7139875 },
    { lat: 49.2749851, lng: 19.7120658 },
    { lat: 49.2768716, lng: 19.710334 },
    { lat: 49.2784128, lng: 19.7089278 },
    { lat: 49.2792921, lng: 19.7081179 },
    { lat: 49.2800407, lng: 19.7074355 },
    { lat: 49.2801357, lng: 19.7073417 },
    { lat: 49.2804488, lng: 19.70693 },
    { lat: 49.2813377, lng: 19.7059805 },
    { lat: 49.2817229, lng: 19.7057469 },
    { lat: 49.2822483, lng: 19.7054235 },
    { lat: 49.2832593, lng: 19.7047974 },
    { lat: 49.28463, lng: 19.7039395 },
    { lat: 49.2877002, lng: 19.7020026 },
    { lat: 49.2883323, lng: 19.7015921 },
    { lat: 49.2906645, lng: 19.7007253 },
    { lat: 49.2938351, lng: 19.699533 },
    { lat: 49.2949999, lng: 19.6990973 },
    { lat: 49.2951514, lng: 19.6990338 },
    { lat: 49.2953494, lng: 19.6989767 },
    { lat: 49.2964193, lng: 19.6955058 },
    { lat: 49.29644, lng: 19.6954405 },
    { lat: 49.2978229, lng: 19.6920466 },
    { lat: 49.2978114, lng: 19.6920117 },
    { lat: 49.2975875, lng: 19.6912518 },
    { lat: 49.2974624, lng: 19.690838 },
    { lat: 49.297289, lng: 19.6902761 },
    { lat: 49.2971904, lng: 19.6900307 },
    { lat: 49.2970505, lng: 19.6896953 },
    { lat: 49.2968738, lng: 19.6892623 },
    { lat: 49.2967067, lng: 19.6888446 },
    { lat: 49.2963583, lng: 19.6879932 },
    { lat: 49.2963571, lng: 19.6879912 },
    { lat: 49.2961654, lng: 19.6875247 },
    { lat: 49.2958946, lng: 19.6868747 },
    { lat: 49.295767, lng: 19.6865608 },
    { lat: 49.2954428, lng: 19.685877 },
    { lat: 49.2945541, lng: 19.6839854 },
    { lat: 49.2942321, lng: 19.6831718 },
    { lat: 49.2936942, lng: 19.6818286 },
    { lat: 49.2934965, lng: 19.6810484 },
    { lat: 49.2930829, lng: 19.6794351 },
    { lat: 49.2928932, lng: 19.6783769 },
    { lat: 49.292407, lng: 19.6769058 },
    { lat: 49.2918092, lng: 19.6751274 },
    { lat: 49.2911148, lng: 19.673049 },
    { lat: 49.2905734, lng: 19.6714523 },
    { lat: 49.2903538, lng: 19.6690558 },
    { lat: 49.290174, lng: 19.6674333 },
  ],
  Hladovka: [
    { lat: 49.412993, lng: 19.76758 },
    { lat: 49.412644, lng: 19.766243 },
    { lat: 49.412347, lng: 19.765096 },
    { lat: 49.412049, lng: 19.763857 },
    { lat: 49.41175, lng: 19.76262 },
    { lat: 49.4115895, lng: 19.7619776 },
    { lat: 49.411516, lng: 19.761683 },
    { lat: 49.41149, lng: 19.761582 },
    { lat: 49.41114, lng: 19.76018 },
    { lat: 49.410835, lng: 19.758943 },
    { lat: 49.410675, lng: 19.7582838 },
    { lat: 49.410534, lng: 19.757717 },
    { lat: 49.410154, lng: 19.756158 },
    { lat: 49.409838, lng: 19.754855 },
    { lat: 49.409493, lng: 19.753431 },
    { lat: 49.409175, lng: 19.752127 },
    { lat: 49.408757, lng: 19.75042 },
    { lat: 49.4087401, lng: 19.7503292 },
    { lat: 49.4081958, lng: 19.7479395 },
    { lat: 49.408192, lng: 19.747934 },
    { lat: 49.4079244, lng: 19.7463308 },
    { lat: 49.4079369, lng: 19.7462787 },
    { lat: 49.407149, lng: 19.74562 },
    { lat: 49.40663, lng: 19.744627 },
    { lat: 49.406167, lng: 19.743484 },
    { lat: 49.405455, lng: 19.742073 },
    { lat: 49.404765, lng: 19.74134 },
    { lat: 49.404121, lng: 19.741029 },
    { lat: 49.403609, lng: 19.740552 },
    { lat: 49.402835, lng: 19.740144 },
    { lat: 49.401946, lng: 19.73994 },
    { lat: 49.401233, lng: 19.739234 },
    { lat: 49.400584, lng: 19.738988 },
    { lat: 49.39995, lng: 19.738462 },
    { lat: 49.399272, lng: 19.737847 },
    { lat: 49.398708, lng: 19.737132 },
    { lat: 49.397956, lng: 19.736394 },
    { lat: 49.397188, lng: 19.735475 },
    { lat: 49.396278, lng: 19.734615 },
    { lat: 49.395525, lng: 19.733774 },
    { lat: 49.39479, lng: 19.733133 },
    { lat: 49.394224, lng: 19.732353 },
    { lat: 49.393662, lng: 19.731802 },
    { lat: 49.393023, lng: 19.731032 },
    { lat: 49.392361, lng: 19.730324 },
    { lat: 49.391737, lng: 19.729639 },
    { lat: 49.391116, lng: 19.728634 },
    { lat: 49.390548, lng: 19.727513 },
    { lat: 49.390172, lng: 19.726902 },
    { lat: 49.390013, lng: 19.726572 },
    { lat: 49.389807, lng: 19.725591 },
    { lat: 49.389594, lng: 19.724421 },
    { lat: 49.389254, lng: 19.723583 },
    { lat: 49.389196, lng: 19.723463 },
    { lat: 49.3891193, lng: 19.723309 },
    { lat: 49.388875, lng: 19.72284 },
    { lat: 49.388137, lng: 19.721425 },
    { lat: 49.387367, lng: 19.720324 },
    { lat: 49.387286, lng: 19.720189 },
    { lat: 49.3872316, lng: 19.7201254 },
    { lat: 49.3871048, lng: 19.7199085 },
    { lat: 49.3868954, lng: 19.7195502 },
    { lat: 49.38643, lng: 19.7190609 },
    { lat: 49.3862612, lng: 19.7200118 },
    { lat: 49.3866564, lng: 19.7201919 },
    { lat: 49.3866525, lng: 19.7207734 },
    { lat: 49.3866514, lng: 19.7209268 },
    { lat: 49.3866557, lng: 19.7210244 },
    { lat: 49.3866482, lng: 19.721032 },
    { lat: 49.3864034, lng: 19.7212845 },
    { lat: 49.3857403, lng: 19.7212685 },
    { lat: 49.3857248, lng: 19.7226173 },
    { lat: 49.3852349, lng: 19.7219233 },
    { lat: 49.3854233, lng: 19.7206892 },
    { lat: 49.385474, lng: 19.720509 },
    { lat: 49.38553, lng: 19.720342 },
    { lat: 49.3854719, lng: 19.7202503 },
    { lat: 49.385098, lng: 19.7201263 },
    { lat: 49.3848167, lng: 19.7199544 },
    { lat: 49.384642, lng: 19.7200294 },
    { lat: 49.3845683, lng: 19.7200666 },
    { lat: 49.3844536, lng: 19.7203578 },
    { lat: 49.384363, lng: 19.7206028 },
    { lat: 49.3843393, lng: 19.7208616 },
    { lat: 49.3844232, lng: 19.7210045 },
    { lat: 49.3843777, lng: 19.7210688 },
    { lat: 49.3843772, lng: 19.7211893 },
    { lat: 49.3843771, lng: 19.7213647 },
    { lat: 49.3844169, lng: 19.7213461 },
    { lat: 49.3845547, lng: 19.7212807 },
    { lat: 49.384847, lng: 19.7218871 },
    { lat: 49.3844148, lng: 19.7223869 },
    { lat: 49.3842541, lng: 19.7225238 },
    { lat: 49.3840226, lng: 19.7227199 },
    { lat: 49.383859, lng: 19.7226593 },
    { lat: 49.3837536, lng: 19.7226448 },
    { lat: 49.38371, lng: 19.7226246 },
    { lat: 49.3834456, lng: 19.7225056 },
    { lat: 49.3832464, lng: 19.7231551 },
    { lat: 49.3833417, lng: 19.7235543 },
    { lat: 49.3838775, lng: 19.7238585 },
    { lat: 49.3840037, lng: 19.7241435 },
    { lat: 49.3839385, lng: 19.725361 },
    { lat: 49.3839196, lng: 19.7257007 },
    { lat: 49.3835941, lng: 19.726042 },
    { lat: 49.3833837, lng: 19.7258661 },
    { lat: 49.3832155, lng: 19.7257025 },
    { lat: 49.3831157, lng: 19.7257124 },
    { lat: 49.3830787, lng: 19.7257154 },
    { lat: 49.3831221, lng: 19.7258065 },
    { lat: 49.3833565, lng: 19.7260164 },
    { lat: 49.3833844, lng: 19.7260898 },
    { lat: 49.3831318, lng: 19.7265495 },
    { lat: 49.3829496, lng: 19.7272265 },
    { lat: 49.382517, lng: 19.7278189 },
    { lat: 49.3825086, lng: 19.7278313 },
    { lat: 49.3825129, lng: 19.7279035 },
    { lat: 49.3824336, lng: 19.7278929 },
    { lat: 49.3820906, lng: 19.7276766 },
    { lat: 49.3819076, lng: 19.7281288 },
    { lat: 49.3818303, lng: 19.7283222 },
    { lat: 49.3817126, lng: 19.7286155 },
    { lat: 49.381457, lng: 19.7290831 },
    { lat: 49.3811394, lng: 19.7294496 },
    { lat: 49.3810397, lng: 19.7295119 },
    { lat: 49.3809059, lng: 19.7298553 },
    { lat: 49.3808895, lng: 19.7301013 },
    { lat: 49.3809363, lng: 19.7304219 },
    { lat: 49.3809892, lng: 19.7305528 },
    { lat: 49.3809942, lng: 19.7306687 },
    { lat: 49.3804947, lng: 19.7311239 },
    { lat: 49.380208, lng: 19.7313416 },
    { lat: 49.3800559, lng: 19.73165 },
    { lat: 49.3799252, lng: 19.7320136 },
    { lat: 49.379877, lng: 19.7321979 },
    { lat: 49.3798302, lng: 19.7322135 },
    { lat: 49.3796035, lng: 19.7321293 },
    { lat: 49.3793796, lng: 19.7318973 },
    { lat: 49.3792539, lng: 19.7318078 },
    { lat: 49.3791562, lng: 19.7318417 },
    { lat: 49.3790286, lng: 19.732094 },
    { lat: 49.3788734, lng: 19.7322538 },
    { lat: 49.3786226, lng: 19.7321857 },
    { lat: 49.378464, lng: 19.7323216 },
    { lat: 49.3783288, lng: 19.7324757 },
    { lat: 49.3781661, lng: 19.7325921 },
    { lat: 49.3781046, lng: 19.7327025 },
    { lat: 49.3781059, lng: 19.732891 },
    { lat: 49.3781429, lng: 19.7329386 },
    { lat: 49.3783349, lng: 19.7330542 },
    { lat: 49.3783372, lng: 19.7332868 },
    { lat: 49.3782489, lng: 19.7335619 },
    { lat: 49.3780908, lng: 19.7338398 },
    { lat: 49.3779928, lng: 19.7340732 },
    { lat: 49.3778909, lng: 19.734243 },
    { lat: 49.3777741, lng: 19.7341935 },
    { lat: 49.377675, lng: 19.7341729 },
    { lat: 49.3776489, lng: 19.7341922 },
    { lat: 49.3775473, lng: 19.7343967 },
    { lat: 49.3774799, lng: 19.7347625 },
    { lat: 49.3774738, lng: 19.7349576 },
    { lat: 49.377424, lng: 19.7350824 },
    { lat: 49.3773227, lng: 19.735186 },
    { lat: 49.3771902, lng: 19.7351193 },
    { lat: 49.377097, lng: 19.734892 },
    { lat: 49.3769627, lng: 19.7347351 },
    { lat: 49.3768315, lng: 19.734747 },
    { lat: 49.3767742, lng: 19.7348017 },
    { lat: 49.3767635, lng: 19.7349401 },
    { lat: 49.3767683, lng: 19.7351048 },
    { lat: 49.3767931, lng: 19.7352086 },
    { lat: 49.3767324, lng: 19.7353659 },
    { lat: 49.3765652, lng: 19.7354896 },
    { lat: 49.3762188, lng: 19.7355991 },
    { lat: 49.3760423, lng: 19.7354537 },
    { lat: 49.3758453, lng: 19.7350725 },
    { lat: 49.3760088, lng: 19.7348426 },
    { lat: 49.3759761, lng: 19.7347093 },
    { lat: 49.3759138, lng: 19.7346487 },
    { lat: 49.3757527, lng: 19.734521 },
    { lat: 49.3756393, lng: 19.7345802 },
    { lat: 49.3755593, lng: 19.7346838 },
    { lat: 49.3755032, lng: 19.7347387 },
    { lat: 49.3754535, lng: 19.7347369 },
    { lat: 49.3753838, lng: 19.734637 },
    { lat: 49.3753201, lng: 19.7346757 },
    { lat: 49.3752717, lng: 19.7347793 },
    { lat: 49.3752493, lng: 19.7349367 },
    { lat: 49.3753016, lng: 19.73508 },
    { lat: 49.3753365, lng: 19.7352036 },
    { lat: 49.3753373, lng: 19.7353521 },
    { lat: 49.3753273, lng: 19.7355892 },
    { lat: 49.375284, lng: 19.7357603 },
    { lat: 49.3751933, lng: 19.7358256 },
    { lat: 49.3750582, lng: 19.735878 },
    { lat: 49.374953, lng: 19.735999 },
    { lat: 49.3749309, lng: 19.7360053 },
    { lat: 49.3748106, lng: 19.7358838 },
    { lat: 49.3747796, lng: 19.7358168 },
    { lat: 49.3748002, lng: 19.7356972 },
    { lat: 49.3748611, lng: 19.7355945 },
    { lat: 49.3750348, lng: 19.735545 },
    { lat: 49.3750619, lng: 19.7354742 },
    { lat: 49.3750357, lng: 19.7353598 },
    { lat: 49.374998, lng: 19.7353242 },
    { lat: 49.3749648, lng: 19.7353356 },
    { lat: 49.3749192, lng: 19.7353243 },
    { lat: 49.3748594, lng: 19.7351439 },
    { lat: 49.3747807, lng: 19.7350153 },
    { lat: 49.3746573, lng: 19.7349469 },
    { lat: 49.3745262, lng: 19.7349118 },
    { lat: 49.3744501, lng: 19.7349482 },
    { lat: 49.3743958, lng: 19.7350249 },
    { lat: 49.3744151, lng: 19.7351574 },
    { lat: 49.3744304, lng: 19.7351953 },
    { lat: 49.3744791, lng: 19.7352575 },
    { lat: 49.3745672, lng: 19.73534 },
    { lat: 49.3745646, lng: 19.7353815 },
    { lat: 49.3745333, lng: 19.7354873 },
    { lat: 49.3745007, lng: 19.7355413 },
    { lat: 49.3743986, lng: 19.735658 },
    { lat: 49.3742821, lng: 19.7356988 },
    { lat: 49.374185, lng: 19.7357441 },
    { lat: 49.3740995, lng: 19.7357709 },
    { lat: 49.3738836, lng: 19.7358045 },
    { lat: 49.3736908, lng: 19.735779 },
    { lat: 49.3734421, lng: 19.7358843 },
    { lat: 49.3733518, lng: 19.7360395 },
    { lat: 49.3732753, lng: 19.7362234 },
    { lat: 49.3732727, lng: 19.7363418 },
    { lat: 49.3731621, lng: 19.7364909 },
    { lat: 49.3730894, lng: 19.7365554 },
    { lat: 49.3730134, lng: 19.7365942 },
    { lat: 49.3728841, lng: 19.7365734 },
    { lat: 49.3727993, lng: 19.7364653 },
    { lat: 49.372677, lng: 19.7363712 },
    { lat: 49.3725948, lng: 19.7363745 },
    { lat: 49.3725126, lng: 19.7364501 },
    { lat: 49.3723995, lng: 19.7365199 },
    { lat: 49.3723197, lng: 19.7367322 },
    { lat: 49.3722751, lng: 19.7369479 },
    { lat: 49.3722787, lng: 19.7371619 },
    { lat: 49.3723876, lng: 19.7373593 },
    { lat: 49.3724853, lng: 19.7374021 },
    { lat: 49.3726033, lng: 19.7373464 },
    { lat: 49.3727313, lng: 19.7374143 },
    { lat: 49.3728771, lng: 19.7372985 },
    { lat: 49.3730022, lng: 19.7373733 },
    { lat: 49.3730176, lng: 19.7374946 },
    { lat: 49.3729926, lng: 19.7375922 },
    { lat: 49.373011, lng: 19.7378636 },
    { lat: 49.3731156, lng: 19.7380156 },
    { lat: 49.3731741, lng: 19.7380646 },
    { lat: 49.3732009, lng: 19.7381388 },
    { lat: 49.3731878, lng: 19.7382219 },
    { lat: 49.3731785, lng: 19.7382423 },
    { lat: 49.3730808, lng: 19.7383774 },
    { lat: 49.3730313, lng: 19.7383795 },
    { lat: 49.3728169, lng: 19.738242 },
    { lat: 49.3726409, lng: 19.7382874 },
    { lat: 49.372587, lng: 19.7383179 },
    { lat: 49.3725489, lng: 19.7382455 },
    { lat: 49.3725416, lng: 19.738153 },
    { lat: 49.3724692, lng: 19.7380194 },
    { lat: 49.3723837, lng: 19.7378943 },
    { lat: 49.3722603, lng: 19.7378502 },
    { lat: 49.3721656, lng: 19.7378776 },
    { lat: 49.3721165, lng: 19.7381215 },
    { lat: 49.3721969, lng: 19.7383096 },
    { lat: 49.3721697, lng: 19.7384117 },
    { lat: 49.3721387, lng: 19.7384749 },
    { lat: 49.3720542, lng: 19.738579 },
    { lat: 49.3719281, lng: 19.7386038 },
    { lat: 49.3718334, lng: 19.7385263 },
    { lat: 49.3718401, lng: 19.7384689 },
    { lat: 49.3716712, lng: 19.7384225 },
    { lat: 49.3713025, lng: 19.7387258 },
    { lat: 49.3710934, lng: 19.7392553 },
    { lat: 49.3709144, lng: 19.7392031 },
    { lat: 49.3708457, lng: 19.7388945 },
    { lat: 49.3705178, lng: 19.7385517 },
    { lat: 49.3703146, lng: 19.7383606 },
    { lat: 49.3701999, lng: 19.7381341 },
    { lat: 49.3700255, lng: 19.7380579 },
    { lat: 49.369866, lng: 19.73781 },
    { lat: 49.3696869, lng: 19.7377578 },
    { lat: 49.3694778, lng: 19.7375853 },
    { lat: 49.3687055, lng: 19.7371852 },
    { lat: 49.368432, lng: 19.7370837 },
    { lat: 49.368097, lng: 19.7370753 },
    { lat: 49.3678177, lng: 19.7367936 },
    { lat: 49.3673122, lng: 19.7370819 },
    { lat: 49.3664538, lng: 19.7376502 },
    { lat: 49.3661361, lng: 19.7383223 },
    { lat: 49.3661108, lng: 19.7383658 },
    { lat: 49.3661004, lng: 19.7384615 },
    { lat: 49.3660696, lng: 19.7386067 },
    { lat: 49.3659818, lng: 19.7389424 },
    { lat: 49.3659639, lng: 19.7390259 },
    { lat: 49.3658702, lng: 19.7393839 },
    { lat: 49.3654937, lng: 19.7399973 },
    { lat: 49.3648993, lng: 19.7404207 },
    { lat: 49.3645539, lng: 19.7406623 },
    { lat: 49.3639991, lng: 19.7410141 },
    { lat: 49.3636013, lng: 19.742072 },
    { lat: 49.3635512, lng: 19.7422177 },
    { lat: 49.3631099, lng: 19.7419628 },
    { lat: 49.3621443, lng: 19.7415404 },
    { lat: 49.3618318, lng: 19.7414823 },
    { lat: 49.3611659, lng: 19.7413467 },
    { lat: 49.3605039, lng: 19.7412244 },
    { lat: 49.3600853, lng: 19.7413137 },
    { lat: 49.3599064, lng: 19.7414447 },
    { lat: 49.359583, lng: 19.7414711 },
    { lat: 49.3594064, lng: 19.7418397 },
    { lat: 49.3589539, lng: 19.7427523 },
    { lat: 49.358705, lng: 19.7434567 },
    { lat: 49.3586229, lng: 19.7439786 },
    { lat: 49.3586203, lng: 19.7440215 },
    { lat: 49.3585423, lng: 19.7447637 },
    { lat: 49.358467, lng: 19.7456282 },
    { lat: 49.3584108, lng: 19.7466111 },
    { lat: 49.358311, lng: 19.7478398 },
    { lat: 49.3581982, lng: 19.74875 },
    { lat: 49.3580337, lng: 19.749784 },
    { lat: 49.3578136, lng: 19.7507108 },
    { lat: 49.3575422, lng: 19.7511522 },
    { lat: 49.3573693, lng: 19.7514271 },
    { lat: 49.3570516, lng: 19.7516824 },
    { lat: 49.3567701, lng: 19.7518158 },
    { lat: 49.3566422, lng: 19.7520886 },
    { lat: 49.3560147, lng: 19.7519573 },
    { lat: 49.3554777, lng: 19.7524838 },
    { lat: 49.3551985, lng: 19.7530073 },
    { lat: 49.3550285, lng: 19.7534741 },
    { lat: 49.3547971, lng: 19.7539287 },
    { lat: 49.3545025, lng: 19.754463 },
    { lat: 49.3542474, lng: 19.7546595 },
    { lat: 49.3539105, lng: 19.7548643 },
    { lat: 49.3535033, lng: 19.7550062 },
    { lat: 49.3530082, lng: 19.7550959 },
    { lat: 49.3530074, lng: 19.755049 },
    { lat: 49.351677, lng: 19.7548413 },
    { lat: 49.3505931, lng: 19.7542382 },
    { lat: 49.3505933, lng: 19.7541118 },
    { lat: 49.3505419, lng: 19.754048 },
    { lat: 49.3504244, lng: 19.7541145 },
    { lat: 49.3500332, lng: 19.7542095 },
    { lat: 49.3497883, lng: 19.7542023 },
    { lat: 49.349258, lng: 19.7542573 },
    { lat: 49.3486272, lng: 19.7542824 },
    { lat: 49.3486011, lng: 19.7542518 },
    { lat: 49.3483507, lng: 19.7540352 },
    { lat: 49.3477135, lng: 19.7541465 },
    { lat: 49.347682, lng: 19.7541497 },
    { lat: 49.3468656, lng: 19.7542883 },
    { lat: 49.3460485, lng: 19.7545611 },
    { lat: 49.3456786, lng: 19.7545492 },
    { lat: 49.3451026, lng: 19.7544401 },
    { lat: 49.3435471, lng: 19.7549085 },
    { lat: 49.3432804, lng: 19.7549898 },
    { lat: 49.3414195, lng: 19.7552526 },
    { lat: 49.341037, lng: 19.7552625 },
    { lat: 49.3399278, lng: 19.7550866 },
    { lat: 49.3393071, lng: 19.755115 },
    { lat: 49.3390464, lng: 19.7554395 },
    { lat: 49.3389028, lng: 19.7555845 },
    { lat: 49.3382769, lng: 19.7562409 },
    { lat: 49.3379702, lng: 19.7565494 },
    { lat: 49.3371369, lng: 19.7571513 },
    { lat: 49.3359077, lng: 19.7585209 },
    { lat: 49.3350661, lng: 19.7590893 },
    { lat: 49.3340709, lng: 19.7592564 },
    { lat: 49.3329693, lng: 19.7592292 },
    { lat: 49.3323584, lng: 19.7589629 },
    { lat: 49.3323145, lng: 19.7589633 },
    { lat: 49.3299385, lng: 19.7593714 },
    { lat: 49.3243393, lng: 19.7646827 },
    { lat: 49.3228584, lng: 19.7699227 },
    { lat: 49.3215264, lng: 19.7723549 },
    { lat: 49.3204199, lng: 19.7744316 },
    { lat: 49.3204054, lng: 19.7744627 },
    { lat: 49.3184896, lng: 19.7769934 },
    { lat: 49.3179412, lng: 19.7776475 },
    { lat: 49.3177203, lng: 19.7781956 },
    { lat: 49.3147106, lng: 19.7803105 },
    { lat: 49.3153112, lng: 19.7808329 },
    { lat: 49.3159358, lng: 19.781293 },
    { lat: 49.3177766, lng: 19.7809095 },
    { lat: 49.3191172, lng: 19.7801302 },
    { lat: 49.3209071, lng: 19.7784305 },
    { lat: 49.3230861, lng: 19.776674 },
    { lat: 49.3234435, lng: 19.7763692 },
    { lat: 49.3242383, lng: 19.77594 },
    { lat: 49.3250668, lng: 19.7757706 },
    { lat: 49.3260409, lng: 19.7753972 },
    { lat: 49.3270024, lng: 19.7753342 },
    { lat: 49.3275904, lng: 19.7753343 },
    { lat: 49.3284603, lng: 19.7753164 },
    { lat: 49.3293038, lng: 19.7753672 },
    { lat: 49.3299819, lng: 19.77565 },
    { lat: 49.3304508, lng: 19.7757134 },
    { lat: 49.3305901, lng: 19.7757325 },
    { lat: 49.3306906, lng: 19.7757357 },
    { lat: 49.3313118, lng: 19.7757733 },
    { lat: 49.332309, lng: 19.7755207 },
    { lat: 49.3341001, lng: 19.7747388 },
    { lat: 49.3341598, lng: 19.7747186 },
    { lat: 49.3353275, lng: 19.7744184 },
    { lat: 49.3362798, lng: 19.7735956 },
    { lat: 49.3363164, lng: 19.773558 },
    { lat: 49.3367401, lng: 19.7731983 },
    { lat: 49.3367727, lng: 19.7731705 },
    { lat: 49.3371054, lng: 19.7728135 },
    { lat: 49.3381495, lng: 19.7716136 },
    { lat: 49.3392956, lng: 19.7709734 },
    { lat: 49.3403042, lng: 19.7709576 },
    { lat: 49.3403184, lng: 19.7710009 },
    { lat: 49.3404673, lng: 19.7709302 },
    { lat: 49.3405386, lng: 19.7709349 },
    { lat: 49.3406148, lng: 19.770929 },
    { lat: 49.3406549, lng: 19.7709184 },
    { lat: 49.340692, lng: 19.7709396 },
    { lat: 49.3407232, lng: 19.7709574 },
    { lat: 49.3408516, lng: 19.7709574 },
    { lat: 49.3409783, lng: 19.7709731 },
    { lat: 49.3411227, lng: 19.771004 },
    { lat: 49.3411958, lng: 19.7710526 },
    { lat: 49.3412641, lng: 19.7711194 },
    { lat: 49.3413908, lng: 19.7712384 },
    { lat: 49.3415071, lng: 19.7712978 },
    { lat: 49.3415756, lng: 19.7713407 },
    { lat: 49.3416818, lng: 19.7714011 },
    { lat: 49.3417185, lng: 19.7714201 },
    { lat: 49.3418016, lng: 19.7714652 },
    { lat: 49.3418345, lng: 19.7714712 },
    { lat: 49.3418673, lng: 19.7714715 },
    { lat: 49.3419334, lng: 19.771489 },
    { lat: 49.3419914, lng: 19.7715023 },
    { lat: 49.342066, lng: 19.7715356 },
    { lat: 49.3421651, lng: 19.771562 },
    { lat: 49.3422683, lng: 19.7715757 },
    { lat: 49.3423816, lng: 19.7715907 },
    { lat: 49.3424854, lng: 19.7715942 },
    { lat: 49.3425605, lng: 19.7716408 },
    { lat: 49.3426417, lng: 19.7716405 },
    { lat: 49.3426654, lng: 19.7716432 },
    { lat: 49.342748, lng: 19.7716518 },
    { lat: 49.3428078, lng: 19.7716819 },
    { lat: 49.3428747, lng: 19.7716906 },
    { lat: 49.3430184, lng: 19.7717331 },
    { lat: 49.343177, lng: 19.7717756 },
    { lat: 49.3433269, lng: 19.7718305 },
    { lat: 49.3435027, lng: 19.7719377 },
    { lat: 49.3435375, lng: 19.7719634 },
    { lat: 49.3436452, lng: 19.7720566 },
    { lat: 49.3436966, lng: 19.7721005 },
    { lat: 49.3437486, lng: 19.7721834 },
    { lat: 49.3438093, lng: 19.7723102 },
    { lat: 49.3439262, lng: 19.7724851 },
    { lat: 49.3440285, lng: 19.7727663 },
    { lat: 49.3440272, lng: 19.7729167 },
    { lat: 49.344218, lng: 19.7732135 },
    { lat: 49.3444965, lng: 19.7732731 },
    { lat: 49.3446356, lng: 19.7731556 },
    { lat: 49.3448838, lng: 19.7731688 },
    { lat: 49.345054, lng: 19.7731774 },
    { lat: 49.3452355, lng: 19.7729887 },
    { lat: 49.3453293, lng: 19.7728152 },
    { lat: 49.3453303, lng: 19.7724737 },
    { lat: 49.3453615, lng: 19.7722826 },
    { lat: 49.345483, lng: 19.7722534 },
    { lat: 49.3456129, lng: 19.7723652 },
    { lat: 49.3457762, lng: 19.7723913 },
    { lat: 49.3457977, lng: 19.7723913 },
    { lat: 49.3459023, lng: 19.7722347 },
    { lat: 49.3460683, lng: 19.7720893 },
    { lat: 49.3463164, lng: 19.7719637 },
    { lat: 49.3464422, lng: 19.7721107 },
    { lat: 49.3464452, lng: 19.7723387 },
    { lat: 49.3464805, lng: 19.7723467 },
    { lat: 49.346683, lng: 19.7723912 },
    { lat: 49.3469234, lng: 19.7724814 },
    { lat: 49.3471136, lng: 19.7725561 },
    { lat: 49.347329, lng: 19.772772 },
    { lat: 49.3474832, lng: 19.7729485 },
    { lat: 49.3475599, lng: 19.7731077 },
    { lat: 49.3476509, lng: 19.7732564 },
    { lat: 49.3477891, lng: 19.7733797 },
    { lat: 49.3478851, lng: 19.7734118 },
    { lat: 49.3480287, lng: 19.773424 },
    { lat: 49.3481009, lng: 19.7734294 },
    { lat: 49.3482306, lng: 19.7734321 },
    { lat: 49.3483324, lng: 19.7734175 },
    { lat: 49.3485546, lng: 19.7733491 },
    { lat: 49.3488844, lng: 19.7732066 },
    { lat: 49.3491986, lng: 19.7730487 },
    { lat: 49.3492797, lng: 19.7729986 },
    { lat: 49.3494237, lng: 19.7728897 },
    { lat: 49.3496232, lng: 19.7726621 },
    { lat: 49.3497844, lng: 19.7724044 },
    { lat: 49.3498141, lng: 19.7719176 },
    { lat: 49.3499484, lng: 19.7716334 },
    { lat: 49.3501921, lng: 19.7715422 },
    { lat: 49.3502686, lng: 19.7713798 },
    { lat: 49.3502955, lng: 19.7713023 },
    { lat: 49.3503158, lng: 19.771275 },
    { lat: 49.3504935, lng: 19.7710333 },
    { lat: 49.3506246, lng: 19.7708896 },
    { lat: 49.3507452, lng: 19.7708113 },
    { lat: 49.3508171, lng: 19.770783 },
    { lat: 49.3508397, lng: 19.7707822 },
    { lat: 49.3508763, lng: 19.7708207 },
    { lat: 49.3509484, lng: 19.7709543 },
    { lat: 49.351064, lng: 19.7710449 },
    { lat: 49.3512287, lng: 19.7711312 },
    { lat: 49.3513801, lng: 19.7710905 },
    { lat: 49.3515392, lng: 19.7709162 },
    { lat: 49.3516441, lng: 19.7707599 },
    { lat: 49.351908, lng: 19.77064 },
    { lat: 49.3521509, lng: 19.7704705 },
    { lat: 49.3523528, lng: 19.7703474 },
    { lat: 49.3525199, lng: 19.7701223 },
    { lat: 49.3527154, lng: 19.7699009 },
    { lat: 49.3529018, lng: 19.7693913 },
    { lat: 49.3530729, lng: 19.7690528 },
    { lat: 49.3532473, lng: 19.7686318 },
    { lat: 49.3534243, lng: 19.7687671 },
    { lat: 49.353543, lng: 19.7687507 },
    { lat: 49.3535999, lng: 19.7685257 },
    { lat: 49.3538705, lng: 19.7685062 },
    { lat: 49.354053, lng: 19.7684526 },
    { lat: 49.3541933, lng: 19.768313 },
    { lat: 49.3542966, lng: 19.7680442 },
    { lat: 49.3543827, lng: 19.767901 },
    { lat: 49.3544194, lng: 19.7678391 },
    { lat: 49.3545386, lng: 19.7678336 },
    { lat: 49.3545866, lng: 19.7679815 },
    { lat: 49.3547634, lng: 19.7681905 },
    { lat: 49.3549085, lng: 19.7680241 },
    { lat: 49.3550017, lng: 19.7675496 },
    { lat: 49.355088, lng: 19.7672514 },
    { lat: 49.3553071, lng: 19.7668776 },
    { lat: 49.3555996, lng: 19.7668183 },
    { lat: 49.35576, lng: 19.7668544 },
    { lat: 49.3559438, lng: 19.7672242 },
    { lat: 49.35611, lng: 19.7673378 },
    { lat: 49.3562269, lng: 19.7673079 },
    { lat: 49.356347, lng: 19.7671161 },
    { lat: 49.3565683, lng: 19.766819 },
    { lat: 49.3566894, lng: 19.7666709 },
    { lat: 49.3567107, lng: 19.7664866 },
    { lat: 49.3567732, lng: 19.7662942 },
    { lat: 49.3568107, lng: 19.766169 },
    { lat: 49.3568831, lng: 19.7659976 },
    { lat: 49.3570214, lng: 19.7659644 },
    { lat: 49.3571479, lng: 19.7660733 },
    { lat: 49.35725, lng: 19.766197 },
    { lat: 49.3573163, lng: 19.7663222 },
    { lat: 49.3573482, lng: 19.7663829 },
    { lat: 49.3574117, lng: 19.7664975 },
    { lat: 49.3575219, lng: 19.766649 },
    { lat: 49.3576301, lng: 19.7667009 },
    { lat: 49.3576677, lng: 19.7666862 },
    { lat: 49.3576867, lng: 19.7666558 },
    { lat: 49.3577163, lng: 19.7664826 },
    { lat: 49.3577147, lng: 19.7663143 },
    { lat: 49.3577051, lng: 19.7661422 },
    { lat: 49.3577312, lng: 19.7660474 },
    { lat: 49.3578665, lng: 19.7659437 },
    { lat: 49.3579247, lng: 19.7659089 },
    { lat: 49.3579753, lng: 19.7658788 },
    { lat: 49.3581145, lng: 19.7658181 },
    { lat: 49.358218, lng: 19.7657877 },
    { lat: 49.3582691, lng: 19.7657735 },
    { lat: 49.358423, lng: 19.7657362 },
    { lat: 49.358567, lng: 19.7656815 },
    { lat: 49.3586183, lng: 19.765668 },
    { lat: 49.3586898, lng: 19.7656546 },
    { lat: 49.3587892, lng: 19.7656073 },
    { lat: 49.3588895, lng: 19.7655295 },
    { lat: 49.3589428, lng: 19.765492 },
    { lat: 49.3590234, lng: 19.7654227 },
    { lat: 49.359085, lng: 19.7653399 },
    { lat: 49.3591808, lng: 19.7651321 },
    { lat: 49.3593747, lng: 19.7648508 },
    { lat: 49.3595079, lng: 19.7646743 },
    { lat: 49.3598195, lng: 19.7645599 },
    { lat: 49.3599583, lng: 19.7644353 },
    { lat: 49.3601887, lng: 19.7643197 },
    { lat: 49.3604669, lng: 19.7643739 },
    { lat: 49.3606615, lng: 19.7644188 },
    { lat: 49.3608028, lng: 19.7643507 },
    { lat: 49.3611339, lng: 19.764269 },
    { lat: 49.3612196, lng: 19.7642466 },
    { lat: 49.3614433, lng: 19.7641859 },
    { lat: 49.3618027, lng: 19.7641339 },
    { lat: 49.3622196, lng: 19.7640869 },
    { lat: 49.3623378, lng: 19.764085 },
    { lat: 49.3625975, lng: 19.7639398 },
    { lat: 49.3627511, lng: 19.7638989 },
    { lat: 49.3629785, lng: 19.7634559 },
    { lat: 49.3630719, lng: 19.7632946 },
    { lat: 49.3632271, lng: 19.7632655 },
    { lat: 49.363539, lng: 19.7633936 },
    { lat: 49.363736, lng: 19.7634119 },
    { lat: 49.3637363, lng: 19.7634747 },
    { lat: 49.3647589, lng: 19.7646167 },
    { lat: 49.3655884, lng: 19.7653053 },
    { lat: 49.3656323, lng: 19.7653261 },
    { lat: 49.365728, lng: 19.7654321 },
    { lat: 49.3658113, lng: 19.7655078 },
    { lat: 49.3659438, lng: 19.7656028 },
    { lat: 49.3668684, lng: 19.76638 },
    { lat: 49.3672262, lng: 19.766733 },
    { lat: 49.3672943, lng: 19.7667749 },
    { lat: 49.3677493, lng: 19.7670562 },
    { lat: 49.3680073, lng: 19.7672158 },
    { lat: 49.3681511, lng: 19.7672605 },
    { lat: 49.3682403, lng: 19.7672915 },
    { lat: 49.3683466, lng: 19.7673274 },
    { lat: 49.3684769, lng: 19.7673716 },
    { lat: 49.3685606, lng: 19.7674007 },
    { lat: 49.368661, lng: 19.7674296 },
    { lat: 49.3688493, lng: 19.7674896 },
    { lat: 49.3689419, lng: 19.7675186 },
    { lat: 49.3691303, lng: 19.7675801 },
    { lat: 49.369224, lng: 19.7676107 },
    { lat: 49.3692833, lng: 19.7676296 },
    { lat: 49.3695632, lng: 19.7677436 },
    { lat: 49.3696239, lng: 19.7677653 },
    { lat: 49.3698237, lng: 19.7678278 },
    { lat: 49.3699263, lng: 19.76786 },
    { lat: 49.3699972, lng: 19.7678829 },
    { lat: 49.370945, lng: 19.7681807 },
    { lat: 49.3724958, lng: 19.7686683 },
    { lat: 49.3747226, lng: 19.7696093 },
    { lat: 49.3747602, lng: 19.7696435 },
    { lat: 49.3747893, lng: 19.7696651 },
    { lat: 49.3751233, lng: 19.7698083 },
    { lat: 49.3751799, lng: 19.7698398 },
    { lat: 49.3767556, lng: 19.7705151 },
    { lat: 49.3768521, lng: 19.7705596 },
    { lat: 49.3778192, lng: 19.7705494 },
    { lat: 49.3787915, lng: 19.7705285 },
    { lat: 49.3798982, lng: 19.7703588 },
    { lat: 49.3804477, lng: 19.7700407 },
    { lat: 49.3822221, lng: 19.7692892 },
    { lat: 49.3832581, lng: 19.7686834 },
    { lat: 49.384243, lng: 19.768098 },
    { lat: 49.3848495, lng: 19.7675596 },
    { lat: 49.3861115, lng: 19.7663451 },
    { lat: 49.3862866, lng: 19.7663049 },
    { lat: 49.386353, lng: 19.7663025 },
    { lat: 49.3864232, lng: 19.7662611 },
    { lat: 49.3871489, lng: 19.7659029 },
    { lat: 49.3877147, lng: 19.7655974 },
    { lat: 49.3880104, lng: 19.7655034 },
    { lat: 49.3883295, lng: 19.7653289 },
    { lat: 49.3902425, lng: 19.7648173 },
    { lat: 49.390638, lng: 19.7650014 },
    { lat: 49.3906736, lng: 19.7650158 },
    { lat: 49.3914829, lng: 19.7653437 },
    { lat: 49.3921618, lng: 19.765677 },
    { lat: 49.3925155, lng: 19.7657004 },
    { lat: 49.3935865, lng: 19.7661674 },
    { lat: 49.3939941, lng: 19.7662378 },
    { lat: 49.3960005, lng: 19.7667429 },
    { lat: 49.396978, lng: 19.7668416 },
    { lat: 49.3986959, lng: 19.7671819 },
    { lat: 49.3995367, lng: 19.7672749 },
    { lat: 49.4003649, lng: 19.7674372 },
    { lat: 49.4021008, lng: 19.7678542 },
    { lat: 49.4039766, lng: 19.7683305 },
    { lat: 49.4059931, lng: 19.7687781 },
    { lat: 49.4072915, lng: 19.7681504 },
    { lat: 49.4086247, lng: 19.7681132 },
    { lat: 49.4087577, lng: 19.7681099 },
    { lat: 49.4106049, lng: 19.7679004 },
    { lat: 49.412993, lng: 19.76758 },
  ],
  Liesek: [
    { lat: 49.4064042, lng: 19.6642929 },
    { lat: 49.406351, lng: 19.6642479 },
    { lat: 49.4061615, lng: 19.6640414 },
    { lat: 49.4059751, lng: 19.6640792 },
    { lat: 49.405357, lng: 19.6638324 },
    { lat: 49.4043335, lng: 19.6634588 },
    { lat: 49.4038196, lng: 19.6631004 },
    { lat: 49.4033037, lng: 19.662893 },
    { lat: 49.402197, lng: 19.6632138 },
    { lat: 49.400875, lng: 19.6635591 },
    { lat: 49.3992754, lng: 19.6633547 },
    { lat: 49.3980645, lng: 19.6631803 },
    { lat: 49.3977709, lng: 19.6631398 },
    { lat: 49.3973355, lng: 19.66333 },
    { lat: 49.3962791, lng: 19.6637952 },
    { lat: 49.3947938, lng: 19.6644467 },
    { lat: 49.393583, lng: 19.6636867 },
    { lat: 49.3923428, lng: 19.6628673 },
    { lat: 49.3911014, lng: 19.6620559 },
    { lat: 49.3904322, lng: 19.6616443 },
    { lat: 49.3897551, lng: 19.6612371 },
    { lat: 49.3884571, lng: 19.6603876 },
    { lat: 49.3874556, lng: 19.6600531 },
    { lat: 49.3863083, lng: 19.6596566 },
    { lat: 49.3860225, lng: 19.6594881 },
    { lat: 49.3847717, lng: 19.6601196 },
    { lat: 49.3834746, lng: 19.6607692 },
    { lat: 49.3817702, lng: 19.6614581 },
    { lat: 49.3802011, lng: 19.661976 },
    { lat: 49.3795855, lng: 19.6609667 },
    { lat: 49.3786534, lng: 19.65945 },
    { lat: 49.3785595, lng: 19.6593126 },
    { lat: 49.3776969, lng: 19.6590126 },
    { lat: 49.3776673, lng: 19.6590048 },
    { lat: 49.3769837, lng: 19.6585036 },
    { lat: 49.3760055, lng: 19.6580349 },
    { lat: 49.3748668, lng: 19.6574808 },
    { lat: 49.3735986, lng: 19.6569759 },
    { lat: 49.3736074, lng: 19.6569454 },
    { lat: 49.3729793, lng: 19.6564183 },
    { lat: 49.371796, lng: 19.6554156 },
    { lat: 49.3708484, lng: 19.6544438 },
    { lat: 49.3706408, lng: 19.6544625 },
    { lat: 49.3695908, lng: 19.654563 },
    { lat: 49.3692332, lng: 19.6546008 },
    { lat: 49.3688557, lng: 19.6546002 },
    { lat: 49.3685179, lng: 19.6545535 },
    { lat: 49.3674087, lng: 19.6544317 },
    { lat: 49.3674188, lng: 19.6567271 },
    { lat: 49.3674297, lng: 19.656872 },
    { lat: 49.3670156, lng: 19.6569366 },
    { lat: 49.366498, lng: 19.6572783 },
    { lat: 49.366317, lng: 19.6574153 },
    { lat: 49.3660154, lng: 19.6575774 },
    { lat: 49.3658377, lng: 19.6578354 },
    { lat: 49.3656983, lng: 19.6581473 },
    { lat: 49.3656529, lng: 19.6582684 },
    { lat: 49.3655078, lng: 19.6581953 },
    { lat: 49.3654262, lng: 19.6582883 },
    { lat: 49.3652754, lng: 19.6582146 },
    { lat: 49.3650585, lng: 19.6579465 },
    { lat: 49.3649833, lng: 19.6579012 },
    { lat: 49.3647975, lng: 19.6578532 },
    { lat: 49.3646294, lng: 19.6579971 },
    { lat: 49.3646282, lng: 19.6580229 },
    { lat: 49.3646764, lng: 19.6580678 },
    { lat: 49.3646679, lng: 19.6581048 },
    { lat: 49.3645279, lng: 19.6581265 },
    { lat: 49.3644274, lng: 19.6579396 },
    { lat: 49.3643865, lng: 19.6580089 },
    { lat: 49.3642612, lng: 19.6579294 },
    { lat: 49.3642526, lng: 19.6577351 },
    { lat: 49.3641755, lng: 19.6578226 },
    { lat: 49.3641114, lng: 19.6577978 },
    { lat: 49.3640991, lng: 19.6580325 },
    { lat: 49.3640805, lng: 19.6580439 },
    { lat: 49.3636463, lng: 19.6580057 },
    { lat: 49.3636292, lng: 19.657975 },
    { lat: 49.3636992, lng: 19.6578557 },
    { lat: 49.3636705, lng: 19.657816 },
    { lat: 49.3635546, lng: 19.6578685 },
    { lat: 49.3635167, lng: 19.6577264 },
    { lat: 49.3634541, lng: 19.6577365 },
    { lat: 49.3634585, lng: 19.6575806 },
    { lat: 49.3632967, lng: 19.6574665 },
    { lat: 49.3632993, lng: 19.6573434 },
    { lat: 49.3632618, lng: 19.6572854 },
    { lat: 49.3632349, lng: 19.6573388 },
    { lat: 49.3631094, lng: 19.6570988 },
    { lat: 49.3631081, lng: 19.6569685 },
    { lat: 49.3629966, lng: 19.6568918 },
    { lat: 49.3630362, lng: 19.6568216 },
    { lat: 49.3630081, lng: 19.6567685 },
    { lat: 49.3629156, lng: 19.6568175 },
    { lat: 49.3628911, lng: 19.6567224 },
    { lat: 49.3629512, lng: 19.6566558 },
    { lat: 49.362939, lng: 19.6565826 },
    { lat: 49.3629718, lng: 19.6565604 },
    { lat: 49.3629713, lng: 19.6565225 },
    { lat: 49.3628579, lng: 19.6564291 },
    { lat: 49.3628904, lng: 19.6563271 },
    { lat: 49.3629361, lng: 19.656314 },
    { lat: 49.3629094, lng: 19.6562167 },
    { lat: 49.3628469, lng: 19.6561812 },
    { lat: 49.3628704, lng: 19.6560028 },
    { lat: 49.3628176, lng: 19.6559504 },
    { lat: 49.362845, lng: 19.65576 },
    { lat: 49.3627887, lng: 19.6557297 },
    { lat: 49.3628065, lng: 19.6555229 },
    { lat: 49.3627156, lng: 19.655378 },
    { lat: 49.3626892, lng: 19.6548288 },
    { lat: 49.3626579, lng: 19.654763 },
    { lat: 49.3626459, lng: 19.6543909 },
    { lat: 49.3627145, lng: 19.6541051 },
    { lat: 49.3626982, lng: 19.6536832 },
    { lat: 49.3626751, lng: 19.6532891 },
    { lat: 49.3625694, lng: 19.6529376 },
    { lat: 49.3625221, lng: 19.6525564 },
    { lat: 49.362467, lng: 19.6523561 },
    { lat: 49.3624785, lng: 19.652206 },
    { lat: 49.3623718, lng: 19.651881 },
    { lat: 49.3622317, lng: 19.6516212 },
    { lat: 49.3621387, lng: 19.651526 },
    { lat: 49.3621107, lng: 19.651534 },
    { lat: 49.3615671, lng: 19.6516207 },
    { lat: 49.360448, lng: 19.6516374 },
    { lat: 49.3595916, lng: 19.6516543 },
    { lat: 49.3594714, lng: 19.6517394 },
    { lat: 49.3589927, lng: 19.651767 },
    { lat: 49.3579356, lng: 19.6517342 },
    { lat: 49.3576234, lng: 19.6517272 },
    { lat: 49.3564685, lng: 19.6515445 },
    { lat: 49.3555898, lng: 19.6512919 },
    { lat: 49.3547142, lng: 19.65136 },
    { lat: 49.3536542, lng: 19.6510811 },
    { lat: 49.3533338, lng: 19.650947 },
    { lat: 49.3529555, lng: 19.650799 },
    { lat: 49.352222, lng: 19.6503352 },
    { lat: 49.3520598, lng: 19.6516891 },
    { lat: 49.351354, lng: 19.6537121 },
    { lat: 49.3514658, lng: 19.6554976 },
    { lat: 49.3524731, lng: 19.6561398 },
    { lat: 49.3532186, lng: 19.6571007 },
    { lat: 49.3528958, lng: 19.6573712 },
    { lat: 49.3525631, lng: 19.6572366 },
    { lat: 49.3521867, lng: 19.6580449 },
    { lat: 49.3518205, lng: 19.6592193 },
    { lat: 49.3516239, lng: 19.6600205 },
    { lat: 49.3514796, lng: 19.6606295 },
    { lat: 49.3513738, lng: 19.6606607 },
    { lat: 49.3510427, lng: 19.6615543 },
    { lat: 49.350944, lng: 19.6616133 },
    { lat: 49.3510455, lng: 19.6618962 },
    { lat: 49.3508812, lng: 19.6621802 },
    { lat: 49.3509116, lng: 19.6622844 },
    { lat: 49.3507655, lng: 19.6623179 },
    { lat: 49.3506246, lng: 19.6621844 },
    { lat: 49.3505043, lng: 19.6621654 },
    { lat: 49.3501521, lng: 19.6623521 },
    { lat: 49.3500344, lng: 19.6623665 },
    { lat: 49.3499766, lng: 19.6622807 },
    { lat: 49.3497744, lng: 19.6622452 },
    { lat: 49.3495717, lng: 19.6622958 },
    { lat: 49.3494806, lng: 19.6623761 },
    { lat: 49.3494475, lng: 19.6623914 },
    { lat: 49.3493559, lng: 19.6623865 },
    { lat: 49.3493172, lng: 19.6621997 },
    { lat: 49.349388, lng: 19.662119 },
    { lat: 49.3493991, lng: 19.662012 },
    { lat: 49.349336, lng: 19.6619892 },
    { lat: 49.3489364, lng: 19.6621993 },
    { lat: 49.3488755, lng: 19.6626038 },
    { lat: 49.3488018, lng: 19.6625923 },
    { lat: 49.3487542, lng: 19.6625636 },
    { lat: 49.3486812, lng: 19.6624646 },
    { lat: 49.3486395, lng: 19.6622315 },
    { lat: 49.3485876, lng: 19.6622058 },
    { lat: 49.3481883, lng: 19.6628336 },
    { lat: 49.3476322, lng: 19.6636325 },
    { lat: 49.3473871, lng: 19.664284 },
    { lat: 49.3473293, lng: 19.6648616 },
    { lat: 49.3468988, lng: 19.6665422 },
    { lat: 49.3467043, lng: 19.6670093 },
    { lat: 49.3463808, lng: 19.6685631 },
    { lat: 49.346316, lng: 19.6701607 },
    { lat: 49.3463137, lng: 19.6709027 },
    { lat: 49.3460376, lng: 19.672504 },
    { lat: 49.3459938, lng: 19.6730956 },
    { lat: 49.3460278, lng: 19.6736922 },
    { lat: 49.3460192, lng: 19.6740324 },
    { lat: 49.3458556, lng: 19.6740525 },
    { lat: 49.3458727, lng: 19.6744135 },
    { lat: 49.3458422, lng: 19.6744886 },
    { lat: 49.3455533, lng: 19.6742203 },
    { lat: 49.3454455, lng: 19.6739229 },
    { lat: 49.345324, lng: 19.6738014 },
    { lat: 49.3453542, lng: 19.6734641 },
    { lat: 49.3452936, lng: 19.6734165 },
    { lat: 49.3449604, lng: 19.6736012 },
    { lat: 49.3448297, lng: 19.67355 },
    { lat: 49.3447303, lng: 19.6733894 },
    { lat: 49.3447524, lng: 19.6733289 },
    { lat: 49.344724, lng: 19.673218 },
    { lat: 49.3446012, lng: 19.6732716 },
    { lat: 49.3445774, lng: 19.6730915 },
    { lat: 49.3446897, lng: 19.6730568 },
    { lat: 49.3446572, lng: 19.6728066 },
    { lat: 49.3444533, lng: 19.6728572 },
    { lat: 49.3444521, lng: 19.6730864 },
    { lat: 49.3439739, lng: 19.6734306 },
    { lat: 49.3439069, lng: 19.6734144 },
    { lat: 49.3438803, lng: 19.6732997 },
    { lat: 49.3438077, lng: 19.6732108 },
    { lat: 49.3436827, lng: 19.6731601 },
    { lat: 49.343519, lng: 19.6732803 },
    { lat: 49.3434924, lng: 19.6732401 },
    { lat: 49.3429801, lng: 19.6734519 },
    { lat: 49.3428404, lng: 19.6737796 },
    { lat: 49.3425765, lng: 19.6737494 },
    { lat: 49.3424838, lng: 19.6734345 },
    { lat: 49.3423526, lng: 19.673477 },
    { lat: 49.3422971, lng: 19.6742865 },
    { lat: 49.3419071, lng: 19.6742527 },
    { lat: 49.3416061, lng: 19.6744843 },
    { lat: 49.341366, lng: 19.6745048 },
    { lat: 49.3411827, lng: 19.6748181 },
    { lat: 49.3409843, lng: 19.6746876 },
    { lat: 49.3407175, lng: 19.6747382 },
    { lat: 49.3406864, lng: 19.6746486 },
    { lat: 49.3405157, lng: 19.674198 },
    { lat: 49.3403832, lng: 19.6742608 },
    { lat: 49.3403683, lng: 19.6745387 },
    { lat: 49.34037, lng: 19.6746791 },
    { lat: 49.3402894, lng: 19.6746366 },
    { lat: 49.3400795, lng: 19.6745259 },
    { lat: 49.3400182, lng: 19.6744694 },
    { lat: 49.3398907, lng: 19.6745118 },
    { lat: 49.339991, lng: 19.6747681 },
    { lat: 49.3399754, lng: 19.675132 },
    { lat: 49.3398985, lng: 19.6752032 },
    { lat: 49.3398316, lng: 19.6751675 },
    { lat: 49.3395996, lng: 19.6749602 },
    { lat: 49.3395487, lng: 19.6748824 },
    { lat: 49.3392989, lng: 19.6751979 },
    { lat: 49.339057, lng: 19.6752514 },
    { lat: 49.3389831, lng: 19.6753916 },
    { lat: 49.3391668, lng: 19.6757787 },
    { lat: 49.3391336, lng: 19.67594 },
    { lat: 49.3389253, lng: 19.6759705 },
    { lat: 49.3389208, lng: 19.6760465 },
    { lat: 49.3388227, lng: 19.6761501 },
    { lat: 49.3387319, lng: 19.6762589 },
    { lat: 49.3386109, lng: 19.676353 },
    { lat: 49.3384937, lng: 19.6763774 },
    { lat: 49.3383605, lng: 19.6764229 },
    { lat: 49.3383451, lng: 19.6764808 },
    { lat: 49.3383387, lng: 19.6765393 },
    { lat: 49.3383399, lng: 19.6766689 },
    { lat: 49.3383048, lng: 19.6768459 },
    { lat: 49.3382694, lng: 19.6768842 },
    { lat: 49.3382067, lng: 19.6768697 },
    { lat: 49.3380917, lng: 19.6767411 },
    { lat: 49.3380494, lng: 19.6766757 },
    { lat: 49.3380198, lng: 19.6765874 },
    { lat: 49.3380269, lng: 19.67647 },
    { lat: 49.3380852, lng: 19.6762349 },
    { lat: 49.3379996, lng: 19.6761296 },
    { lat: 49.3379532, lng: 19.6761257 },
    { lat: 49.3377796, lng: 19.6763018 },
    { lat: 49.3377122, lng: 19.6764858 },
    { lat: 49.337705, lng: 19.6766271 },
    { lat: 49.3376744, lng: 19.6767791 },
    { lat: 49.3375878, lng: 19.6768819 },
    { lat: 49.3374907, lng: 19.6768547 },
    { lat: 49.3373737, lng: 19.6769365 },
    { lat: 49.3372827, lng: 19.6771952 },
    { lat: 49.3372296, lng: 19.6772711 },
    { lat: 49.3371429, lng: 19.677297 },
    { lat: 49.3369491, lng: 19.6775764 },
    { lat: 49.3368903, lng: 19.6778548 },
    { lat: 49.3367712, lng: 19.6779112 },
    { lat: 49.336625, lng: 19.6778381 },
    { lat: 49.3364546, lng: 19.6779099 },
    { lat: 49.3364235, lng: 19.6779962 },
    { lat: 49.3364292, lng: 19.6780982 },
    { lat: 49.3364838, lng: 19.678315 },
    { lat: 49.3365383, lng: 19.6784062 },
    { lat: 49.3365233, lng: 19.6784721 },
    { lat: 49.3363771, lng: 19.6785294 },
    { lat: 49.3362915, lng: 19.6784776 },
    { lat: 49.3361756, lng: 19.6785582 },
    { lat: 49.336096, lng: 19.6787708 },
    { lat: 49.3360451, lng: 19.6789997 },
    { lat: 49.3359828, lng: 19.6790642 },
    { lat: 49.3359197, lng: 19.6790668 },
    { lat: 49.3358665, lng: 19.6790065 },
    { lat: 49.3358502, lng: 19.678867 },
    { lat: 49.3357402, lng: 19.6787219 },
    { lat: 49.3356853, lng: 19.678703 },
    { lat: 49.3355663, lng: 19.6788142 },
    { lat: 49.3354857, lng: 19.6790595 },
    { lat: 49.3353034, lng: 19.6791108 },
    { lat: 49.3352228, lng: 19.6790989 },
    { lat: 49.3350821, lng: 19.6791777 },
    { lat: 49.3350485, lng: 19.6792838 },
    { lat: 49.3350631, lng: 19.6795402 },
    { lat: 49.3349531, lng: 19.6797278 },
    { lat: 49.3348628, lng: 19.6799018 },
    { lat: 49.3347322, lng: 19.6800843 },
    { lat: 49.3343933, lng: 19.6804223 },
    { lat: 49.3342754, lng: 19.6805064 },
    { lat: 49.3340911, lng: 19.6805669 },
    { lat: 49.3339818, lng: 19.6805197 },
    { lat: 49.3336954, lng: 19.680663 },
    { lat: 49.3334876, lng: 19.6806764 },
    { lat: 49.3333745, lng: 19.6808203 },
    { lat: 49.3333286, lng: 19.6810056 },
    { lat: 49.3332398, lng: 19.6811676 },
    { lat: 49.3331155, lng: 19.681184 },
    { lat: 49.3330214, lng: 19.68109 },
    { lat: 49.3328485, lng: 19.6812061 },
    { lat: 49.3326812, lng: 19.6812992 },
    { lat: 49.3326094, lng: 19.681303 },
    { lat: 49.3322885, lng: 19.6813805 },
    { lat: 49.3321873, lng: 19.6815173 },
    { lat: 49.3320958, lng: 19.6817171 },
    { lat: 49.3320251, lng: 19.6817956 },
    { lat: 49.3319434, lng: 19.6820427 },
    { lat: 49.3318006, lng: 19.6820954 },
    { lat: 49.331557, lng: 19.681963 },
    { lat: 49.331281, lng: 19.6818867 },
    { lat: 49.3311814, lng: 19.6819779 },
    { lat: 49.3311293, lng: 19.6820482 },
    { lat: 49.3310783, lng: 19.6820914 },
    { lat: 49.3308025, lng: 19.6821 },
    { lat: 49.3306761, lng: 19.6820169 },
    { lat: 49.3305665, lng: 19.6820318 },
    { lat: 49.3304227, lng: 19.6818642 },
    { lat: 49.3302373, lng: 19.6818465 },
    { lat: 49.3300049, lng: 19.6821493 },
    { lat: 49.3297969, lng: 19.6821595 },
    { lat: 49.3295643, lng: 19.6820455 },
    { lat: 49.3291764, lng: 19.6817554 },
    { lat: 49.3290823, lng: 19.6817148 },
    { lat: 49.3287773, lng: 19.6816166 },
    { lat: 49.3285943, lng: 19.6816586 },
    { lat: 49.3284166, lng: 19.681639 },
    { lat: 49.3281974, lng: 19.6815973 },
    { lat: 49.3277113, lng: 19.6817897 },
    { lat: 49.3274286, lng: 19.6816357 },
    { lat: 49.3272956, lng: 19.6817371 },
    { lat: 49.327294, lng: 19.6818561 },
    { lat: 49.3272091, lng: 19.6818956 },
    { lat: 49.3271423, lng: 19.6820935 },
    { lat: 49.3270084, lng: 19.6823013 },
    { lat: 49.3268392, lng: 19.6823729 },
    { lat: 49.3267882, lng: 19.6824421 },
    { lat: 49.3267762, lng: 19.6825831 },
    { lat: 49.3267659, lng: 19.6828575 },
    { lat: 49.326425, lng: 19.6831516 },
    { lat: 49.3263556, lng: 19.6832862 },
    { lat: 49.326176, lng: 19.6833026 },
    { lat: 49.3261097, lng: 19.6834116 },
    { lat: 49.3260684, lng: 19.683573 },
    { lat: 49.3260205, lng: 19.6836115 },
    { lat: 49.3258978, lng: 19.6836126 },
    { lat: 49.3256521, lng: 19.683865 },
    { lat: 49.3256168, lng: 19.6840337 },
    { lat: 49.3255935, lng: 19.6842972 },
    { lat: 49.3252462, lng: 19.6843703 },
    { lat: 49.3251264, lng: 19.6846773 },
    { lat: 49.3251491, lng: 19.6849006 },
    { lat: 49.3250754, lng: 19.6850451 },
    { lat: 49.3249107, lng: 19.6851921 },
    { lat: 49.3248728, lng: 19.685253 },
    { lat: 49.324852, lng: 19.6856019 },
    { lat: 49.3246317, lng: 19.6859167 },
    { lat: 49.3242393, lng: 19.6863974 },
    { lat: 49.3242191, lng: 19.6867555 },
    { lat: 49.3240061, lng: 19.6870389 },
    { lat: 49.3239131, lng: 19.6872265 },
    { lat: 49.3235943, lng: 19.6876624 },
    { lat: 49.3235496, lng: 19.6877735 },
    { lat: 49.3235297, lng: 19.688041 },
    { lat: 49.323416, lng: 19.688377 },
    { lat: 49.3232932, lng: 19.688654 },
    { lat: 49.3231059, lng: 19.6889568 },
    { lat: 49.3230409, lng: 19.688966 },
    { lat: 49.3228004, lng: 19.6892363 },
    { lat: 49.3226485, lng: 19.6892126 },
    { lat: 49.3224693, lng: 19.6893126 },
    { lat: 49.322448, lng: 19.6895694 },
    { lat: 49.3222761, lng: 19.6898381 },
    { lat: 49.3220967, lng: 19.6899833 },
    { lat: 49.3218643, lng: 19.6904629 },
    { lat: 49.3215823, lng: 19.6908419 },
    { lat: 49.3213966, lng: 19.6911755 },
    { lat: 49.3212957, lng: 19.6911104 },
    { lat: 49.3211618, lng: 19.6913239 },
    { lat: 49.3209568, lng: 19.691939 },
    { lat: 49.3206447, lng: 19.6924759 },
    { lat: 49.3202136, lng: 19.6929427 },
    { lat: 49.3201106, lng: 19.6931119 },
    { lat: 49.3199877, lng: 19.6936727 },
    { lat: 49.3198684, lng: 19.6939832 },
    { lat: 49.3196127, lng: 19.6941915 },
    { lat: 49.3193248, lng: 19.6944843 },
    { lat: 49.3189514, lng: 19.6945769 },
    { lat: 49.3187295, lng: 19.6945971 },
    { lat: 49.3185374, lng: 19.6945346 },
    { lat: 49.318371, lng: 19.6944375 },
    { lat: 49.318168, lng: 19.6941804 },
    { lat: 49.3177765, lng: 19.6938071 },
    { lat: 49.3177013, lng: 19.6938629 },
    { lat: 49.3172342, lng: 19.6940532 },
    { lat: 49.3166598, lng: 19.6943632 },
    { lat: 49.3163855, lng: 19.6942975 },
    { lat: 49.315364, lng: 19.694613 },
    { lat: 49.3150558, lng: 19.6946219 },
    { lat: 49.3147047, lng: 19.6946569 },
    { lat: 49.3144127, lng: 19.69455 },
    { lat: 49.314285, lng: 19.6944368 },
    { lat: 49.3140234, lng: 19.6946337 },
    { lat: 49.3140016, lng: 19.6948577 },
    { lat: 49.3137424, lng: 19.6948052 },
    { lat: 49.3136016, lng: 19.6948832 },
    { lat: 49.313527, lng: 19.694979 },
    { lat: 49.3134406, lng: 19.6951673 },
    { lat: 49.3129404, lng: 19.696399 },
    { lat: 49.3127475, lng: 19.6968774 },
    { lat: 49.3123819, lng: 19.6977378 },
    { lat: 49.3116835, lng: 19.6984291 },
    { lat: 49.3109786, lng: 19.6991048 },
    { lat: 49.3101168, lng: 19.699925 },
    { lat: 49.3103018, lng: 19.7009567 },
    { lat: 49.3102913, lng: 19.7011589 },
    { lat: 49.3102642, lng: 19.7015407 },
    { lat: 49.3102679, lng: 19.7016732 },
    { lat: 49.3102821, lng: 19.7027458 },
    { lat: 49.3102862, lng: 19.7029642 },
    { lat: 49.310074, lng: 19.7033947 },
    { lat: 49.309848, lng: 19.7038403 },
    { lat: 49.3096729, lng: 19.7041102 },
    { lat: 49.309709, lng: 19.7043676 },
    { lat: 49.3098202, lng: 19.7046959 },
    { lat: 49.3098833, lng: 19.7055447 },
    { lat: 49.3100364, lng: 19.7059305 },
    { lat: 49.3100367, lng: 19.7059586 },
    { lat: 49.3099599, lng: 19.7066246 },
    { lat: 49.3098617, lng: 19.7069528 },
    { lat: 49.3098863, lng: 19.7072831 },
    { lat: 49.3099023, lng: 19.7076487 },
    { lat: 49.3098197, lng: 19.7081066 },
    { lat: 49.3098617, lng: 19.7084727 },
    { lat: 49.3098588, lng: 19.7088424 },
    { lat: 49.3098672, lng: 19.709109 },
    { lat: 49.3100346, lng: 19.7099739 },
    { lat: 49.3099475, lng: 19.710507 },
    { lat: 49.3100761, lng: 19.7120543 },
    { lat: 49.3100564, lng: 19.7129675 },
    { lat: 49.3100972, lng: 19.7132576 },
    { lat: 49.3100089, lng: 19.7137378 },
    { lat: 49.3098753, lng: 19.7143905 },
    { lat: 49.309841, lng: 19.7144799 },
    { lat: 49.3100636, lng: 19.715064 },
    { lat: 49.310313, lng: 19.7155912 },
    { lat: 49.3106268, lng: 19.7162231 },
    { lat: 49.3110649, lng: 19.7174044 },
    { lat: 49.3110742, lng: 19.7177208 },
    { lat: 49.3113174, lng: 19.7180511 },
    { lat: 49.3114587, lng: 19.7184281 },
    { lat: 49.31152, lng: 19.7187991 },
    { lat: 49.3116527, lng: 19.7189989 },
    { lat: 49.3117565, lng: 19.7193872 },
    { lat: 49.3118176, lng: 19.7198615 },
    { lat: 49.3121818, lng: 19.7205128 },
    { lat: 49.3121905, lng: 19.7206596 },
    { lat: 49.3124537, lng: 19.7215291 },
    { lat: 49.3125865, lng: 19.7217037 },
    { lat: 49.3126595, lng: 19.7219818 },
    { lat: 49.3128072, lng: 19.7226292 },
    { lat: 49.3129882, lng: 19.7231895 },
    { lat: 49.3131434, lng: 19.7236455 },
    { lat: 49.3134705, lng: 19.7247159 },
    { lat: 49.3135171, lng: 19.7252144 },
    { lat: 49.3137185, lng: 19.7255195 },
    { lat: 49.3139583, lng: 19.7258532 },
    { lat: 49.3140299, lng: 19.7260217 },
    { lat: 49.3140238, lng: 19.7261379 },
    { lat: 49.3141205, lng: 19.7263674 },
    { lat: 49.314199, lng: 19.7265915 },
    { lat: 49.3144661, lng: 19.7274438 },
    { lat: 49.3149041, lng: 19.7280858 },
    { lat: 49.315251, lng: 19.7288624 },
    { lat: 49.3154485, lng: 19.7292842 },
    { lat: 49.3160286, lng: 19.730418 },
    { lat: 49.3161181, lng: 19.7306067 },
    { lat: 49.3161639, lng: 19.7306508 },
    { lat: 49.3161869, lng: 19.7307679 },
    { lat: 49.3162925, lng: 19.7309702 },
    { lat: 49.3164357, lng: 19.7311784 },
    { lat: 49.3167021, lng: 19.7315769 },
    { lat: 49.3169214, lng: 19.7319282 },
    { lat: 49.317091, lng: 19.7322839 },
    { lat: 49.3174396, lng: 19.7327326 },
    { lat: 49.3178469, lng: 19.7332662 },
    { lat: 49.3178939, lng: 19.7333313 },
    { lat: 49.3181851, lng: 19.7337351 },
    { lat: 49.3183634, lng: 19.73403 },
    { lat: 49.3188036, lng: 19.7346964 },
    { lat: 49.3198413, lng: 19.7334347 },
    { lat: 49.319926, lng: 19.7333355 },
    { lat: 49.3207237, lng: 19.7327691 },
    { lat: 49.320969, lng: 19.7326358 },
    { lat: 49.3213665, lng: 19.7324992 },
    { lat: 49.321794, lng: 19.7323859 },
    { lat: 49.322034, lng: 19.7323845 },
    { lat: 49.3224486, lng: 19.7316669 },
    { lat: 49.3227753, lng: 19.7311746 },
    { lat: 49.3233333, lng: 19.7301502 },
    { lat: 49.3234118, lng: 19.7298815 },
    { lat: 49.3233786, lng: 19.7293525 },
    { lat: 49.3233301, lng: 19.729096 },
    { lat: 49.3229193, lng: 19.7274768 },
    { lat: 49.3226861, lng: 19.7265191 },
    { lat: 49.3227403, lng: 19.7262149 },
    { lat: 49.3229515, lng: 19.7256831 },
    { lat: 49.3230342, lng: 19.725155 },
    { lat: 49.3229797, lng: 19.7250143 },
    { lat: 49.3230911, lng: 19.7247772 },
    { lat: 49.3232266, lng: 19.7244525 },
    { lat: 49.3239316, lng: 19.7233906 },
    { lat: 49.324417, lng: 19.7230997 },
    { lat: 49.324807, lng: 19.7228731 },
    { lat: 49.3248317, lng: 19.7228713 },
    { lat: 49.3253704, lng: 19.7227007 },
    { lat: 49.3256069, lng: 19.7226451 },
    { lat: 49.3258691, lng: 19.7225082 },
    { lat: 49.3259633, lng: 19.7223963 },
    { lat: 49.3267023, lng: 19.7218318 },
    { lat: 49.3267549, lng: 19.7216953 },
    { lat: 49.3268457, lng: 19.7216821 },
    { lat: 49.3272751, lng: 19.7216345 },
    { lat: 49.3274429, lng: 19.7216604 },
    { lat: 49.3276728, lng: 19.7216661 },
    { lat: 49.3276727, lng: 19.721712 },
    { lat: 49.3277436, lng: 19.7217351 },
    { lat: 49.3281139, lng: 19.7218312 },
    { lat: 49.329213, lng: 19.7221085 },
    { lat: 49.3294896, lng: 19.7222254 },
    { lat: 49.3296325, lng: 19.7222776 },
    { lat: 49.3301674, lng: 19.722505 },
    { lat: 49.33027, lng: 19.7225552 },
    { lat: 49.3306828, lng: 19.7227973 },
    { lat: 49.3308178, lng: 19.7228239 },
    { lat: 49.3308363, lng: 19.7228047 },
    { lat: 49.3310492, lng: 19.7228855 },
    { lat: 49.331241, lng: 19.7229425 },
    { lat: 49.3315268, lng: 19.7230396 },
    { lat: 49.3316096, lng: 19.7230763 },
    { lat: 49.3319545, lng: 19.7231837 },
    { lat: 49.332062, lng: 19.723224 },
    { lat: 49.3326486, lng: 19.7234241 },
    { lat: 49.3329662, lng: 19.7235249 },
    { lat: 49.3330089, lng: 19.7235469 },
    { lat: 49.3330645, lng: 19.7235597 },
    { lat: 49.3333333, lng: 19.7236763 },
    { lat: 49.3337848, lng: 19.7238541 },
    { lat: 49.3338822, lng: 19.723889 },
    { lat: 49.3347945, lng: 19.7242458 },
    { lat: 49.33485, lng: 19.7242585 },
    { lat: 49.3348895, lng: 19.7242808 },
    { lat: 49.3354591, lng: 19.7245076 },
    { lat: 49.3357598, lng: 19.7246326 },
    { lat: 49.3362479, lng: 19.72482 },
    { lat: 49.3363481, lng: 19.7248448 },
    { lat: 49.3379017, lng: 19.7251614 },
    { lat: 49.338205, lng: 19.7252214 },
    { lat: 49.3384806, lng: 19.7252623 },
    { lat: 49.3385823, lng: 19.7252721 },
    { lat: 49.3388917, lng: 19.7253145 },
    { lat: 49.3389641, lng: 19.725318 },
    { lat: 49.3392235, lng: 19.7251993 },
    { lat: 49.3394816, lng: 19.7250704 },
    { lat: 49.3398704, lng: 19.7248919 },
    { lat: 49.3399623, lng: 19.7248338 },
    { lat: 49.3407561, lng: 19.7236382 },
    { lat: 49.3409737, lng: 19.7230755 },
    { lat: 49.3413961, lng: 19.7221194 },
    { lat: 49.3417022, lng: 19.7211824 },
    { lat: 49.3416942, lng: 19.7211793 },
    { lat: 49.341204, lng: 19.7207858 },
    { lat: 49.3409848, lng: 19.7206688 },
    { lat: 49.3405799, lng: 19.7201434 },
    { lat: 49.3403671, lng: 19.7199127 },
    { lat: 49.3400895, lng: 19.7196152 },
    { lat: 49.3398462, lng: 19.7193858 },
    { lat: 49.3397682, lng: 19.7192522 },
    { lat: 49.3396547, lng: 19.7189715 },
    { lat: 49.3395798, lng: 19.7188835 },
    { lat: 49.3394281, lng: 19.7186873 },
    { lat: 49.3392213, lng: 19.7182316 },
    { lat: 49.33923, lng: 19.717708 },
    { lat: 49.3391273, lng: 19.7171391 },
    { lat: 49.3391115, lng: 19.7168532 },
    { lat: 49.3390307, lng: 19.7162958 },
    { lat: 49.338921, lng: 19.7154592 },
    { lat: 49.338891, lng: 19.7151353 },
    { lat: 49.3386039, lng: 19.7140279 },
    { lat: 49.3385109, lng: 19.7137304 },
    { lat: 49.3381312, lng: 19.712955 },
    { lat: 49.3377274, lng: 19.7121966 },
    { lat: 49.3374861, lng: 19.7117258 },
    { lat: 49.3373564, lng: 19.7116206 },
    { lat: 49.3373434, lng: 19.7115832 },
    { lat: 49.3373122, lng: 19.7109984 },
    { lat: 49.3372687, lng: 19.7106741 },
    { lat: 49.3371282, lng: 19.7102146 },
    { lat: 49.3371545, lng: 19.7096596 },
    { lat: 49.3371119, lng: 19.7092547 },
    { lat: 49.3371861, lng: 19.7088876 },
    { lat: 49.3372559, lng: 19.7086273 },
    { lat: 49.3371372, lng: 19.7082078 },
    { lat: 49.3369475, lng: 19.7078858 },
    { lat: 49.3369264, lng: 19.7075871 },
    { lat: 49.337039, lng: 19.7072747 },
    { lat: 49.3370447, lng: 19.7070974 },
    { lat: 49.3368706, lng: 19.706593 },
    { lat: 49.3367963, lng: 19.7062881 },
    { lat: 49.3368175, lng: 19.706002 },
    { lat: 49.3370443, lng: 19.7055196 },
    { lat: 49.3370201, lng: 19.7051732 },
    { lat: 49.3370175, lng: 19.7051142 },
    { lat: 49.3370308, lng: 19.7051107 },
    { lat: 49.3370758, lng: 19.7050818 },
    { lat: 49.3372214, lng: 19.7048585 },
    { lat: 49.3372665, lng: 19.7048044 },
    { lat: 49.3374298, lng: 19.7046742 },
    { lat: 49.3375503, lng: 19.7046239 },
    { lat: 49.337699, lng: 19.7046234 },
    { lat: 49.3378997, lng: 19.7045761 },
    { lat: 49.3380835, lng: 19.7043748 },
    { lat: 49.3382081, lng: 19.7042869 },
    { lat: 49.3383161, lng: 19.7042375 },
    { lat: 49.3383859, lng: 19.7042343 },
    { lat: 49.3384514, lng: 19.7042374 },
    { lat: 49.338511, lng: 19.7042345 },
    { lat: 49.3387168, lng: 19.7041 },
    { lat: 49.3389384, lng: 19.7039909 },
    { lat: 49.3390383, lng: 19.7039048 },
    { lat: 49.339193, lng: 19.7037076 },
    { lat: 49.3392375, lng: 19.7036672 },
    { lat: 49.3392886, lng: 19.7036259 },
    { lat: 49.3393366, lng: 19.7036184 },
    { lat: 49.3394203, lng: 19.7036206 },
    { lat: 49.339522, lng: 19.7036583 },
    { lat: 49.3395804, lng: 19.7036765 },
    { lat: 49.3396246, lng: 19.7036831 },
    { lat: 49.3396542, lng: 19.7036642 },
    { lat: 49.3396773, lng: 19.7036243 },
    { lat: 49.3399074, lng: 19.7028641 },
    { lat: 49.3400054, lng: 19.702682 },
    { lat: 49.3401218, lng: 19.7025097 },
    { lat: 49.3401587, lng: 19.7024033 },
    { lat: 49.3402103, lng: 19.7021376 },
    { lat: 49.3402285, lng: 19.702067 },
    { lat: 49.3402524, lng: 19.7020476 },
    { lat: 49.3403113, lng: 19.7020549 },
    { lat: 49.3404744, lng: 19.7020985 },
    { lat: 49.3406387, lng: 19.7020726 },
    { lat: 49.3406698, lng: 19.7020333 },
    { lat: 49.3407057, lng: 19.7019534 },
    { lat: 49.3407532, lng: 19.7017552 },
    { lat: 49.3409062, lng: 19.7012858 },
    { lat: 49.3409514, lng: 19.7011872 },
    { lat: 49.341319, lng: 19.700682 },
    { lat: 49.3414633, lng: 19.7005541 },
    { lat: 49.3416801, lng: 19.7002544 },
    { lat: 49.3418065, lng: 19.7000044 },
    { lat: 49.3419336, lng: 19.6996944 },
    { lat: 49.3421312, lng: 19.6992153 },
    { lat: 49.3424318, lng: 19.6986479 },
    { lat: 49.3428103, lng: 19.6984136 },
    { lat: 49.3428512, lng: 19.6983715 },
    { lat: 49.3428969, lng: 19.698284 },
    { lat: 49.3429873, lng: 19.6980836 },
    { lat: 49.3430727, lng: 19.6979748 },
    { lat: 49.3431351, lng: 19.6979344 },
    { lat: 49.3431649, lng: 19.6979191 },
    { lat: 49.3432067, lng: 19.6978989 },
    { lat: 49.3433331, lng: 19.6978519 },
    { lat: 49.343777, lng: 19.6976979 },
    { lat: 49.3438988, lng: 19.6976222 },
    { lat: 49.3440432, lng: 19.6975022 },
    { lat: 49.344117, lng: 19.6974141 },
    { lat: 49.3442641, lng: 19.6971948 },
    { lat: 49.3443943, lng: 19.696977 },
    { lat: 49.3445452, lng: 19.6966937 },
    { lat: 49.3445954, lng: 19.6965307 },
    { lat: 49.3446044, lng: 19.696455 },
    { lat: 49.3445884, lng: 19.6963703 },
    { lat: 49.3445479, lng: 19.6962718 },
    { lat: 49.3444497, lng: 19.6961089 },
    { lat: 49.3444326, lng: 19.6959784 },
    { lat: 49.344445, lng: 19.6958261 },
    { lat: 49.3444587, lng: 19.6957759 },
    { lat: 49.3445295, lng: 19.6955931 },
    { lat: 49.3446083, lng: 19.6954629 },
    { lat: 49.3447449, lng: 19.695343 },
    { lat: 49.3449015, lng: 19.6951906 },
    { lat: 49.3449836, lng: 19.695033 },
    { lat: 49.3450552, lng: 19.6948136 },
    { lat: 49.34506, lng: 19.694567 },
    { lat: 49.3450468, lng: 19.6943899 },
    { lat: 49.3450475, lng: 19.6943313 },
    { lat: 49.3450651, lng: 19.694271 },
    { lat: 49.3450852, lng: 19.6942396 },
    { lat: 49.3452656, lng: 19.6941922 },
    { lat: 49.3453343, lng: 19.6941436 },
    { lat: 49.3455679, lng: 19.6939747 },
    { lat: 49.3456867, lng: 19.6939061 },
    { lat: 49.3457816, lng: 19.6937341 },
    { lat: 49.3458084, lng: 19.6935482 },
    { lat: 49.3458252, lng: 19.6934684 },
    { lat: 49.3458512, lng: 19.6933414 },
    { lat: 49.3458769, lng: 19.6932116 },
    { lat: 49.345899, lng: 19.6931219 },
    { lat: 49.3459277, lng: 19.6929795 },
    { lat: 49.3459737, lng: 19.6928508 },
    { lat: 49.3460102, lng: 19.6927568 },
    { lat: 49.3460701, lng: 19.6926057 },
    { lat: 49.3461234, lng: 19.6924586 },
    { lat: 49.3461498, lng: 19.6924208 },
    { lat: 49.346179, lng: 19.6923925 },
    { lat: 49.3463873, lng: 19.6921836 },
    { lat: 49.3465209, lng: 19.6920448 },
    { lat: 49.3465634, lng: 19.6920119 },
    { lat: 49.3465992, lng: 19.6920097 },
    { lat: 49.3467895, lng: 19.6920591 },
    { lat: 49.3470742, lng: 19.692131 },
    { lat: 49.3471038, lng: 19.6921319 },
    { lat: 49.3471332, lng: 19.6921141 },
    { lat: 49.34716, lng: 19.6920781 },
    { lat: 49.3472488, lng: 19.6919732 },
    { lat: 49.3472727, lng: 19.6919256 },
    { lat: 49.3472878, lng: 19.6918597 },
    { lat: 49.3473386, lng: 19.6915832 },
    { lat: 49.3473524, lng: 19.6915366 },
    { lat: 49.3473766, lng: 19.6914954 },
    { lat: 49.3474458, lng: 19.6913785 },
    { lat: 49.3475543, lng: 19.6911823 },
    { lat: 49.3475702, lng: 19.6911572 },
    { lat: 49.3477029, lng: 19.690977 },
    { lat: 49.3480288, lng: 19.6907259 },
    { lat: 49.3480695, lng: 19.6907087 },
    { lat: 49.3481721, lng: 19.6907058 },
    { lat: 49.3486491, lng: 19.6907683 },
    { lat: 49.3488682, lng: 19.6907581 },
    { lat: 49.3489477, lng: 19.6907195 },
    { lat: 49.3490601, lng: 19.6906353 },
    { lat: 49.3491665, lng: 19.690473 },
    { lat: 49.3492939, lng: 19.6903417 },
    { lat: 49.3494202, lng: 19.6901701 },
    { lat: 49.3495036, lng: 19.6899892 },
    { lat: 49.3495319, lng: 19.6898652 },
    { lat: 49.3495512, lng: 19.6897136 },
    { lat: 49.3495592, lng: 19.6896359 },
    { lat: 49.3495857, lng: 19.6894243 },
    { lat: 49.3496052, lng: 19.689277 },
    { lat: 49.349647, lng: 19.6890458 },
    { lat: 49.3496906, lng: 19.6888888 },
    { lat: 49.3497276, lng: 19.6887531 },
    { lat: 49.3497525, lng: 19.6886537 },
    { lat: 49.349791, lng: 19.6885569 },
    { lat: 49.3498482, lng: 19.6884983 },
    { lat: 49.3499039, lng: 19.6884073 },
    { lat: 49.3499335, lng: 19.6882868 },
    { lat: 49.3499484, lng: 19.6881624 },
    { lat: 49.3499442, lng: 19.6880197 },
    { lat: 49.3499202, lng: 19.6879091 },
    { lat: 49.3498176, lng: 19.6876191 },
    { lat: 49.3497924, lng: 19.6875617 },
    { lat: 49.3497824, lng: 19.6874915 },
    { lat: 49.3497842, lng: 19.6873493 },
    { lat: 49.3498043, lng: 19.687167 },
    { lat: 49.3498246, lng: 19.6866568 },
    { lat: 49.349848, lng: 19.6864145 },
    { lat: 49.3498866, lng: 19.6862458 },
    { lat: 49.3499597, lng: 19.6860612 },
    { lat: 49.3500401, lng: 19.685921 },
    { lat: 49.3501464, lng: 19.6857225 },
    { lat: 49.3502251, lng: 19.6855157 },
    { lat: 49.3502788, lng: 19.6852493 },
    { lat: 49.3503067, lng: 19.684914 },
    { lat: 49.3502914, lng: 19.6845109 },
    { lat: 49.3502903, lng: 19.6843578 },
    { lat: 49.3503382, lng: 19.6841913 },
    { lat: 49.3504027, lng: 19.6840463 },
    { lat: 49.3504572, lng: 19.6837726 },
    { lat: 49.3504897, lng: 19.6834119 },
    { lat: 49.3505179, lng: 19.6832843 },
    { lat: 49.3505429, lng: 19.6830862 },
    { lat: 49.3505534, lng: 19.6829916 },
    { lat: 49.3505653, lng: 19.6829022 },
    { lat: 49.350583, lng: 19.6828422 },
    { lat: 49.3506557, lng: 19.6827032 },
    { lat: 49.3507754, lng: 19.6825044 },
    { lat: 49.3508586, lng: 19.6822938 },
    { lat: 49.3509371, lng: 19.6820071 },
    { lat: 49.3509612, lng: 19.6818608 },
    { lat: 49.350962, lng: 19.6817513 },
    { lat: 49.3509845, lng: 19.6816236 },
    { lat: 49.3510392, lng: 19.6814571 },
    { lat: 49.3510988, lng: 19.6812775 },
    { lat: 49.3511263, lng: 19.6812086 },
    { lat: 49.3511765, lng: 19.6810946 },
    { lat: 49.3512089, lng: 19.6809887 },
    { lat: 49.3512536, lng: 19.6806473 },
    { lat: 49.3513321, lng: 19.6803548 },
    { lat: 49.3514136, lng: 19.6801856 },
    { lat: 49.3515769, lng: 19.6798512 },
    { lat: 49.3516398, lng: 19.6795155 },
    { lat: 49.3517579, lng: 19.6792052 },
    { lat: 49.3518941, lng: 19.6789212 },
    { lat: 49.3519573, lng: 19.678845 },
    { lat: 49.3520796, lng: 19.6787612 },
    { lat: 49.3521891, lng: 19.6787101 },
    { lat: 49.3523083, lng: 19.6787105 },
    { lat: 49.3525103, lng: 19.6787677 },
    { lat: 49.3528373, lng: 19.6789298 },
    { lat: 49.3530945, lng: 19.6789875 },
    { lat: 49.353304, lng: 19.6788827 },
    { lat: 49.3533798, lng: 19.6788131 },
    { lat: 49.3534255, lng: 19.6787257 },
    { lat: 49.3535236, lng: 19.6784485 },
    { lat: 49.3535688, lng: 19.6782971 },
    { lat: 49.3536137, lng: 19.6781902 },
    { lat: 49.353788, lng: 19.6779775 },
    { lat: 49.353978, lng: 19.6778704 },
    { lat: 49.3542356, lng: 19.6778865 },
    { lat: 49.3543993, lng: 19.6779216 },
    { lat: 49.3545727, lng: 19.6780425 },
    { lat: 49.354671, lng: 19.6780786 },
    { lat: 49.3547502, lng: 19.6780856 },
    { lat: 49.3548945, lng: 19.6780603 },
    { lat: 49.3549078, lng: 19.6780567 },
    { lat: 49.3552217, lng: 19.677895 },
    { lat: 49.3554752, lng: 19.677459 },
    { lat: 49.3555554, lng: 19.6774431 },
    { lat: 49.3558937, lng: 19.6765521 },
    { lat: 49.3564829, lng: 19.6762731 },
    { lat: 49.3564952, lng: 19.6761952 },
    { lat: 49.3565193, lng: 19.676179 },
    { lat: 49.3565785, lng: 19.6762151 },
    { lat: 49.3570999, lng: 19.6758823 },
    { lat: 49.3574191, lng: 19.6757366 },
    { lat: 49.3575466, lng: 19.6756638 },
    { lat: 49.3577082, lng: 19.6754733 },
    { lat: 49.3577744, lng: 19.6756414 },
    { lat: 49.3578727, lng: 19.6759822 },
    { lat: 49.3580156, lng: 19.6764126 },
    { lat: 49.358088, lng: 19.6765226 },
    { lat: 49.3581402, lng: 19.6765837 },
    { lat: 49.358161, lng: 19.6766484 },
    { lat: 49.3581548, lng: 19.6767383 },
    { lat: 49.3581205, lng: 19.6768749 },
    { lat: 49.3581227, lng: 19.6769516 },
    { lat: 49.3582387, lng: 19.6772335 },
    { lat: 49.3582868, lng: 19.6777637 },
    { lat: 49.3583106, lng: 19.6778954 },
    { lat: 49.3583601, lng: 19.6779958 },
    { lat: 49.3585206, lng: 19.6782369 },
    { lat: 49.3587734, lng: 19.6785585 },
    { lat: 49.3593485, lng: 19.6789028 },
    { lat: 49.3594509, lng: 19.6790068 },
    { lat: 49.359608, lng: 19.6792607 },
    { lat: 49.3598883, lng: 19.6796526 },
    { lat: 49.3601539, lng: 19.6802109 },
    { lat: 49.3603128, lng: 19.680446 },
    { lat: 49.3604926, lng: 19.680686 },
    { lat: 49.3606161, lng: 19.6808844 },
    { lat: 49.3606607, lng: 19.6809597 },
    { lat: 49.3607093, lng: 19.6810653 },
    { lat: 49.3608406, lng: 19.6812206 },
    { lat: 49.3609157, lng: 19.6814184 },
    { lat: 49.3609413, lng: 19.6814859 },
    { lat: 49.3610477, lng: 19.6820861 },
    { lat: 49.3610607, lng: 19.682688 },
    { lat: 49.361056, lng: 19.6829685 },
    { lat: 49.3609717, lng: 19.6836465 },
    { lat: 49.3609377, lng: 19.6839287 },
    { lat: 49.3608861, lng: 19.6847147 },
    { lat: 49.3608626, lng: 19.6848465 },
    { lat: 49.3608487, lng: 19.6851746 },
    { lat: 49.360855, lng: 19.6855522 },
    { lat: 49.3608569, lng: 19.6856667 },
    { lat: 49.3608729, lng: 19.6859286 },
    { lat: 49.3608693, lng: 19.6860981 },
    { lat: 49.3608849, lng: 19.6862009 },
    { lat: 49.36091, lng: 19.686284 },
    { lat: 49.3609929, lng: 19.6864294 },
    { lat: 49.3610601, lng: 19.6865457 },
    { lat: 49.3611906, lng: 19.6866971 },
    { lat: 49.3614143, lng: 19.6868923 },
    { lat: 49.3615009, lng: 19.6869879 },
    { lat: 49.3616653, lng: 19.6872698 },
    { lat: 49.3617362, lng: 19.6873481 },
    { lat: 49.3618697, lng: 19.6875932 },
    { lat: 49.3620322, lng: 19.6878041 },
    { lat: 49.3622703, lng: 19.6879628 },
    { lat: 49.3625134, lng: 19.6880647 },
    { lat: 49.3625756, lng: 19.6880673 },
    { lat: 49.3626266, lng: 19.688074 },
    { lat: 49.3627775, lng: 19.6880198 },
    { lat: 49.3629672, lng: 19.6878791 },
    { lat: 49.3631788, lng: 19.6877728 },
    { lat: 49.3634249, lng: 19.6878345 },
    { lat: 49.3635834, lng: 19.6877513 },
    { lat: 49.363702, lng: 19.6877608 },
    { lat: 49.3637541, lng: 19.687818 },
    { lat: 49.3639025, lng: 19.6880889 },
    { lat: 49.3640265, lng: 19.6885052 },
    { lat: 49.3642327, lng: 19.6885823 },
    { lat: 49.3641251, lng: 19.6888261 },
    { lat: 49.3641456, lng: 19.6889331 },
    { lat: 49.364319, lng: 19.688985 },
    { lat: 49.3644071, lng: 19.6893992 },
    { lat: 49.364542, lng: 19.6900297 },
    { lat: 49.3645972, lng: 19.690289 },
    { lat: 49.3646263, lng: 19.6904392 },
    { lat: 49.3646568, lng: 19.6905966 },
    { lat: 49.3647226, lng: 19.6909404 },
    { lat: 49.365024, lng: 19.6932142 },
    { lat: 49.3645213, lng: 19.6934095 },
    { lat: 49.3645904, lng: 19.694392 },
    { lat: 49.3647818, lng: 19.6948544 },
    { lat: 49.365292, lng: 19.6961876 },
    { lat: 49.3656049, lng: 19.6968516 },
    { lat: 49.3657044, lng: 19.6982976 },
    { lat: 49.3657595, lng: 19.6991189 },
    { lat: 49.3659199, lng: 19.6994375 },
    { lat: 49.3659632, lng: 19.6995381 },
    { lat: 49.3662823, lng: 19.700173 },
    { lat: 49.3663886, lng: 19.7003894 },
    { lat: 49.3664662, lng: 19.700873 },
    { lat: 49.3665728, lng: 19.7015347 },
    { lat: 49.3666988, lng: 19.702331 },
    { lat: 49.3662792, lng: 19.7033941 },
    { lat: 49.3662723, lng: 19.7035434 },
    { lat: 49.3661883, lng: 19.7053364 },
    { lat: 49.3640456, lng: 19.706229 },
    { lat: 49.3645037, lng: 19.7064541 },
    { lat: 49.3651483, lng: 19.7067718 },
    { lat: 49.3664151, lng: 19.7076332 },
    { lat: 49.3665701, lng: 19.7077008 },
    { lat: 49.3672769, lng: 19.7080197 },
    { lat: 49.3682398, lng: 19.7094835 },
    { lat: 49.3692752, lng: 19.7108025 },
    { lat: 49.3703356, lng: 19.7121212 },
    { lat: 49.3712672, lng: 19.7134173 },
    { lat: 49.37228, lng: 19.7148078 },
    { lat: 49.3728783, lng: 19.7158917 },
    { lat: 49.3737392, lng: 19.7174222 },
    { lat: 49.3746115, lng: 19.7186968 },
    { lat: 49.3757581, lng: 19.7201422 },
    { lat: 49.3760799, lng: 19.7205455 },
    { lat: 49.3768011, lng: 19.7214547 },
    { lat: 49.3771173, lng: 19.7218601 },
    { lat: 49.3777737, lng: 19.7227072 },
    { lat: 49.3785335, lng: 19.7248625 },
    { lat: 49.3791879, lng: 19.7260768 },
    { lat: 49.379896, lng: 19.7271754 },
    { lat: 49.3809061, lng: 19.7292623 },
    { lat: 49.381457, lng: 19.7290831 },
    { lat: 49.3817126, lng: 19.7286155 },
    { lat: 49.3818303, lng: 19.7283222 },
    { lat: 49.3819076, lng: 19.7281288 },
    { lat: 49.3820906, lng: 19.7276766 },
    { lat: 49.3824336, lng: 19.7278929 },
    { lat: 49.3825129, lng: 19.7279035 },
    { lat: 49.3825086, lng: 19.7278313 },
    { lat: 49.382517, lng: 19.7278189 },
    { lat: 49.3829496, lng: 19.7272265 },
    { lat: 49.3831318, lng: 19.7265495 },
    { lat: 49.3833844, lng: 19.7260898 },
    { lat: 49.3833565, lng: 19.7260164 },
    { lat: 49.3831221, lng: 19.7258065 },
    { lat: 49.3830787, lng: 19.7257154 },
    { lat: 49.3831157, lng: 19.7257124 },
    { lat: 49.3832155, lng: 19.7257025 },
    { lat: 49.3833837, lng: 19.7258661 },
    { lat: 49.3835941, lng: 19.726042 },
    { lat: 49.3839196, lng: 19.7257007 },
    { lat: 49.3839385, lng: 19.725361 },
    { lat: 49.3840037, lng: 19.7241435 },
    { lat: 49.3838775, lng: 19.7238585 },
    { lat: 49.3833417, lng: 19.7235543 },
    { lat: 49.3832464, lng: 19.7231551 },
    { lat: 49.3834456, lng: 19.7225056 },
    { lat: 49.38371, lng: 19.7226246 },
    { lat: 49.3837536, lng: 19.7226448 },
    { lat: 49.383859, lng: 19.7226593 },
    { lat: 49.3840226, lng: 19.7227199 },
    { lat: 49.3842541, lng: 19.7225238 },
    { lat: 49.3844148, lng: 19.7223869 },
    { lat: 49.384847, lng: 19.7218871 },
    { lat: 49.3845547, lng: 19.7212807 },
    { lat: 49.3844169, lng: 19.7213461 },
    { lat: 49.3843771, lng: 19.7213647 },
    { lat: 49.3843772, lng: 19.7211893 },
    { lat: 49.3843777, lng: 19.7210688 },
    { lat: 49.3844232, lng: 19.7210045 },
    { lat: 49.3843393, lng: 19.7208616 },
    { lat: 49.384363, lng: 19.7206028 },
    { lat: 49.3844536, lng: 19.7203578 },
    { lat: 49.3845683, lng: 19.7200666 },
    { lat: 49.384642, lng: 19.7200294 },
    { lat: 49.3848167, lng: 19.7199544 },
    { lat: 49.385098, lng: 19.7201263 },
    { lat: 49.3854719, lng: 19.7202503 },
    { lat: 49.38553, lng: 19.720342 },
    { lat: 49.385474, lng: 19.720509 },
    { lat: 49.3854233, lng: 19.7206892 },
    { lat: 49.3852349, lng: 19.7219233 },
    { lat: 49.3857248, lng: 19.7226173 },
    { lat: 49.3857403, lng: 19.7212685 },
    { lat: 49.3864034, lng: 19.7212845 },
    { lat: 49.3866482, lng: 19.721032 },
    { lat: 49.3866557, lng: 19.7210244 },
    { lat: 49.3866514, lng: 19.7209268 },
    { lat: 49.3866525, lng: 19.7207734 },
    { lat: 49.3866564, lng: 19.7201919 },
    { lat: 49.3862612, lng: 19.7200118 },
    { lat: 49.38643, lng: 19.7190609 },
    { lat: 49.3868954, lng: 19.7195502 },
    { lat: 49.3871048, lng: 19.7199085 },
    { lat: 49.3872316, lng: 19.7201254 },
    { lat: 49.387286, lng: 19.720189 },
    { lat: 49.387321, lng: 19.720134 },
    { lat: 49.387459, lng: 19.719745 },
    { lat: 49.387503, lng: 19.71957 },
    { lat: 49.387541, lng: 19.719196 },
    { lat: 49.387496, lng: 19.719045 },
    { lat: 49.387438, lng: 19.718956 },
    { lat: 49.387373, lng: 19.71898 },
    { lat: 49.387336, lng: 19.71906 },
    { lat: 49.387275, lng: 19.719089 },
    { lat: 49.387243, lng: 19.719081 },
    { lat: 49.387183, lng: 19.719065 },
    { lat: 49.387096, lng: 19.718953 },
    { lat: 49.387042, lng: 19.71899 },
    { lat: 49.387018, lng: 19.719032 },
    { lat: 49.387018, lng: 19.719182 },
    { lat: 49.387003, lng: 19.719234 },
    { lat: 49.386957, lng: 19.719269 },
    { lat: 49.386783, lng: 19.719235 },
    { lat: 49.386704, lng: 19.718956 },
    { lat: 49.386691, lng: 19.718866 },
    { lat: 49.386705, lng: 19.718724 },
    { lat: 49.386747, lng: 19.718537 },
    { lat: 49.386715, lng: 19.718363 },
    { lat: 49.386645, lng: 19.718173 },
    { lat: 49.386599, lng: 19.718013 },
    { lat: 49.386551, lng: 19.717928 },
    { lat: 49.386502, lng: 19.717908 },
    { lat: 49.38645, lng: 19.717815 },
    { lat: 49.386452, lng: 19.717752 },
    { lat: 49.386422, lng: 19.717643 },
    { lat: 49.386438, lng: 19.717536 },
    { lat: 49.38644, lng: 19.717254 },
    { lat: 49.386455, lng: 19.717145 },
    { lat: 49.386507, lng: 19.717041 },
    { lat: 49.386748, lng: 19.716839 },
    { lat: 49.386802, lng: 19.716766 },
    { lat: 49.386856, lng: 19.716634 },
    { lat: 49.386979, lng: 19.716439 },
    { lat: 49.387114, lng: 19.716317 },
    { lat: 49.387209, lng: 19.716253 },
    { lat: 49.387303, lng: 19.716211 },
    { lat: 49.387329, lng: 19.716223 },
    { lat: 49.387368, lng: 19.716286 },
    { lat: 49.38737, lng: 19.716361 },
    { lat: 49.387424, lng: 19.716513 },
    { lat: 49.387447, lng: 19.716645 },
    { lat: 49.387423, lng: 19.716854 },
    { lat: 49.387515, lng: 19.717043 },
    { lat: 49.387701, lng: 19.717339 },
    { lat: 49.387751, lng: 19.717751 },
    { lat: 49.387786, lng: 19.717847 },
    { lat: 49.387891, lng: 19.718008 },
    { lat: 49.387974, lng: 19.71806 },
    { lat: 49.388102, lng: 19.717906 },
    { lat: 49.388107, lng: 19.717854 },
    { lat: 49.388012, lng: 19.71754 },
    { lat: 49.388013, lng: 19.717506 },
    { lat: 49.388017, lng: 19.717366 },
    { lat: 49.388033, lng: 19.717331 },
    { lat: 49.38807, lng: 19.717248 },
    { lat: 49.388134, lng: 19.717154 },
    { lat: 49.388139, lng: 19.717054 },
    { lat: 49.388158, lng: 19.716959 },
    { lat: 49.388168, lng: 19.716728 },
    { lat: 49.388339, lng: 19.716476 },
    { lat: 49.388482, lng: 19.716382 },
    { lat: 49.388556, lng: 19.71647 },
    { lat: 49.388668, lng: 19.71666 },
    { lat: 49.388943, lng: 19.717031 },
    { lat: 49.3889525, lng: 19.7171004 },
    { lat: 49.388999, lng: 19.717442 },
    { lat: 49.388981, lng: 19.717495 },
    { lat: 49.388842, lng: 19.717609 },
    { lat: 49.388709, lng: 19.71769 },
    { lat: 49.388663, lng: 19.717826 },
    { lat: 49.388715, lng: 19.717985 },
    { lat: 49.388786, lng: 19.718137 },
    { lat: 49.388821, lng: 19.718169 },
    { lat: 49.388926, lng: 19.718128 },
    { lat: 49.389204, lng: 19.718081 },
    { lat: 49.389382, lng: 19.718041 },
    { lat: 49.389529, lng: 19.717933 },
    { lat: 49.389654, lng: 19.71768 },
    { lat: 49.389766, lng: 19.717502 },
    { lat: 49.389898, lng: 19.717345 },
    { lat: 49.390044, lng: 19.717128 },
    { lat: 49.390113, lng: 19.71692 },
    { lat: 49.390205, lng: 19.716732 },
    { lat: 49.390256, lng: 19.716564 },
    { lat: 49.390275, lng: 19.716324 },
    { lat: 49.390264, lng: 19.716068 },
    { lat: 49.390289, lng: 19.715898 },
    { lat: 49.390349, lng: 19.715734 },
    { lat: 49.390487, lng: 19.715637 },
    { lat: 49.39052, lng: 19.715642 },
    { lat: 49.390628, lng: 19.715658 },
    { lat: 49.390639, lng: 19.715679 },
    { lat: 49.390722, lng: 19.715841 },
    { lat: 49.390681, lng: 19.716232 },
    { lat: 49.390691, lng: 19.716309 },
    { lat: 49.390781, lng: 19.716517 },
    { lat: 49.390855, lng: 19.716549 },
    { lat: 49.390926, lng: 19.716548 },
    { lat: 49.390963, lng: 19.716499 },
    { lat: 49.390999, lng: 19.716351 },
    { lat: 49.391055, lng: 19.716239 },
    { lat: 49.391137, lng: 19.716037 },
    { lat: 49.391202, lng: 19.715815 },
    { lat: 49.391242, lng: 19.715621 },
    { lat: 49.391445, lng: 19.715294 },
    { lat: 49.391565, lng: 19.71507 },
    { lat: 49.391716, lng: 19.714885 },
    { lat: 49.391882, lng: 19.714598 },
    { lat: 49.392001, lng: 19.714326 },
    { lat: 49.392032, lng: 19.714216 },
    { lat: 49.392048, lng: 19.713917 },
    { lat: 49.391986, lng: 19.71343 },
    { lat: 49.391785, lng: 19.713274 },
    { lat: 49.391564, lng: 19.713283 },
    { lat: 49.391387, lng: 19.71338 },
    { lat: 49.391316, lng: 19.713333 },
    { lat: 49.391239, lng: 19.713122 },
    { lat: 49.391111, lng: 19.713071 },
    { lat: 49.391066, lng: 19.712947 },
    { lat: 49.391068, lng: 19.712863 },
    { lat: 49.391097, lng: 19.712819 },
    { lat: 49.39111, lng: 19.712799 },
    { lat: 49.391124, lng: 19.712793 },
    { lat: 49.39117, lng: 19.712775 },
    { lat: 49.391282, lng: 19.712781 },
    { lat: 49.391359, lng: 19.712657 },
    { lat: 49.391391, lng: 19.71256 },
    { lat: 49.391404, lng: 19.712464 },
    { lat: 49.391358, lng: 19.712311 },
    { lat: 49.391292, lng: 19.712269 },
    { lat: 49.391262, lng: 19.712166 },
    { lat: 49.391204, lng: 19.711697 },
    { lat: 49.391218, lng: 19.711538 },
    { lat: 49.391256, lng: 19.711382 },
    { lat: 49.391341, lng: 19.711136 },
    { lat: 49.391368, lng: 19.711022 },
    { lat: 49.39131, lng: 19.710884 },
    { lat: 49.391194, lng: 19.710775 },
    { lat: 49.391075, lng: 19.710713 },
    { lat: 49.390976, lng: 19.71069 },
    { lat: 49.390931, lng: 19.710661 },
    { lat: 49.390911, lng: 19.710621 },
    { lat: 49.390884, lng: 19.710566 },
    { lat: 49.390852, lng: 19.710454 },
    { lat: 49.390896, lng: 19.710321 },
    { lat: 49.390946, lng: 19.710165 },
    { lat: 49.390855, lng: 19.709945 },
    { lat: 49.390832, lng: 19.709795 },
    { lat: 49.390871, lng: 19.709593 },
    { lat: 49.391023, lng: 19.709246 },
    { lat: 49.391174, lng: 19.709039 },
    { lat: 49.391219, lng: 19.708955 },
    { lat: 49.39136, lng: 19.70877 },
    { lat: 49.391464, lng: 19.708689 },
    { lat: 49.391574, lng: 19.70864 },
    { lat: 49.391636, lng: 19.708668 },
    { lat: 49.391782, lng: 19.708789 },
    { lat: 49.391903, lng: 19.708805 },
    { lat: 49.391965, lng: 19.708864 },
    { lat: 49.391991, lng: 19.708937 },
    { lat: 49.391997, lng: 19.709208 },
    { lat: 49.392026, lng: 19.709265 },
    { lat: 49.392089, lng: 19.709301 },
    { lat: 49.392166, lng: 19.709289 },
    { lat: 49.392203, lng: 19.709327 },
    { lat: 49.392229, lng: 19.709388 },
    { lat: 49.392262, lng: 19.709598 },
    { lat: 49.392321, lng: 19.709782 },
    { lat: 49.392375, lng: 19.710111 },
    { lat: 49.392461, lng: 19.710384 },
    { lat: 49.39256, lng: 19.710547 },
    { lat: 49.39266, lng: 19.710605 },
    { lat: 49.392765, lng: 19.710628 },
    { lat: 49.392838, lng: 19.710605 },
    { lat: 49.392879, lng: 19.710567 },
    { lat: 49.392924, lng: 19.71032 },
    { lat: 49.392896, lng: 19.710246 },
    { lat: 49.392905, lng: 19.710149 },
    { lat: 49.39297, lng: 19.710016 },
    { lat: 49.393054, lng: 19.709966 },
    { lat: 49.393147, lng: 19.70997 },
    { lat: 49.393292, lng: 19.709832 },
    { lat: 49.393424, lng: 19.709593 },
    { lat: 49.39348, lng: 19.709515 },
    { lat: 49.393626, lng: 19.709343 },
    { lat: 49.393706, lng: 19.709216 },
    { lat: 49.393921, lng: 19.708801 },
    { lat: 49.394073, lng: 19.708541 },
    { lat: 49.394277, lng: 19.708352 },
    { lat: 49.394522, lng: 19.708219 },
    { lat: 49.39493, lng: 19.707829 },
    { lat: 49.394918, lng: 19.707715 },
    { lat: 49.394841, lng: 19.707555 },
    { lat: 49.394745, lng: 19.707447 },
    { lat: 49.394681, lng: 19.70745 },
    { lat: 49.394538, lng: 19.707538 },
    { lat: 49.394411, lng: 19.707502 },
    { lat: 49.394339, lng: 19.707507 },
    { lat: 49.39427, lng: 19.707498 },
    { lat: 49.394208, lng: 19.707442 },
    { lat: 49.394118, lng: 19.707286 },
    { lat: 49.394037, lng: 19.707187 },
    { lat: 49.393942, lng: 19.7071 },
    { lat: 49.393862, lng: 19.707008 },
    { lat: 49.393838, lng: 19.706949 },
    { lat: 49.393853, lng: 19.706818 },
    { lat: 49.393912, lng: 19.706697 },
    { lat: 49.39399, lng: 19.706654 },
    { lat: 49.394182, lng: 19.706679 },
    { lat: 49.394329, lng: 19.706768 },
    { lat: 49.394387, lng: 19.706775 },
    { lat: 49.394544, lng: 19.706735 },
    { lat: 49.394682, lng: 19.706765 },
    { lat: 49.394777, lng: 19.706772 },
    { lat: 49.394928, lng: 19.706807 },
    { lat: 49.395163, lng: 19.706747 },
    { lat: 49.395225, lng: 19.706741 },
    { lat: 49.3953, lng: 19.706711 },
    { lat: 49.395491, lng: 19.706662 },
    { lat: 49.395944, lng: 19.706519 },
    { lat: 49.396043, lng: 19.706475 },
    { lat: 49.396146, lng: 19.706293 },
    { lat: 49.396168, lng: 19.706199 },
    { lat: 49.396173, lng: 19.706094 },
    { lat: 49.396201, lng: 19.705958 },
    { lat: 49.39623, lng: 19.705882 },
    { lat: 49.396313, lng: 19.705736 },
    { lat: 49.396331, lng: 19.70566 },
    { lat: 49.396301, lng: 19.705541 },
    { lat: 49.396153, lng: 19.705235 },
    { lat: 49.39605, lng: 19.705149 },
    { lat: 49.395511, lng: 19.705331 },
    { lat: 49.39529, lng: 19.705293 },
    { lat: 49.395017, lng: 19.70508 },
    { lat: 49.394933, lng: 19.704925 },
    { lat: 49.394941, lng: 19.704719 },
    { lat: 49.395057, lng: 19.704585 },
    { lat: 49.395098, lng: 19.704507 },
    { lat: 49.395117, lng: 19.70441 },
    { lat: 49.395066, lng: 19.70397 },
    { lat: 49.395164, lng: 19.703444 },
    { lat: 49.3953, lng: 19.703311 },
    { lat: 49.395356, lng: 19.703286 },
    { lat: 49.395399, lng: 19.703324 },
    { lat: 49.395441, lng: 19.703431 },
    { lat: 49.395476, lng: 19.703474 },
    { lat: 49.395572, lng: 19.703501 },
    { lat: 49.39563, lng: 19.703535 },
    { lat: 49.395808, lng: 19.703833 },
    { lat: 49.395921, lng: 19.703952 },
    { lat: 49.396163, lng: 19.704139 },
    { lat: 49.396307, lng: 19.704427 },
    { lat: 49.396349, lng: 19.704482 },
    { lat: 49.396468, lng: 19.704548 },
    { lat: 49.396537, lng: 19.704554 },
    { lat: 49.396591, lng: 19.704504 },
    { lat: 49.396645, lng: 19.704417 },
    { lat: 49.396747, lng: 19.704117 },
    { lat: 49.39685, lng: 19.703832 },
    { lat: 49.396876, lng: 19.703717 },
    { lat: 49.396861, lng: 19.70366 },
    { lat: 49.396782, lng: 19.70354 },
    { lat: 49.396602, lng: 19.703379 },
    { lat: 49.396497, lng: 19.703251 },
    { lat: 49.39646, lng: 19.70323 },
    { lat: 49.396342, lng: 19.703215 },
    { lat: 49.396077, lng: 19.703066 },
    { lat: 49.395917, lng: 19.702787 },
    { lat: 49.395924, lng: 19.702564 },
    { lat: 49.396058, lng: 19.702364 },
    { lat: 49.396247, lng: 19.702094 },
    { lat: 49.396409, lng: 19.701803 },
    { lat: 49.396495, lng: 19.701782 },
    { lat: 49.396513, lng: 19.701949 },
    { lat: 49.396565, lng: 19.702038 },
    { lat: 49.396692, lng: 19.702016 },
    { lat: 49.396768, lng: 19.702209 },
    { lat: 49.396899, lng: 19.702178 },
    { lat: 49.397064, lng: 19.702455 },
    { lat: 49.397135, lng: 19.70251 },
    { lat: 49.397215, lng: 19.702532 },
    { lat: 49.397256, lng: 19.702514 },
    { lat: 49.397382, lng: 19.702244 },
    { lat: 49.397483, lng: 19.70203 },
    { lat: 49.397656, lng: 19.701906 },
    { lat: 49.39781, lng: 19.70182 },
    { lat: 49.397856, lng: 19.701773 },
    { lat: 49.397851, lng: 19.701569 },
    { lat: 49.397721, lng: 19.701325 },
    { lat: 49.397699, lng: 19.701194 },
    { lat: 49.397725, lng: 19.701068 },
    { lat: 49.397726, lng: 19.700979 },
    { lat: 49.397669, lng: 19.700806 },
    { lat: 49.3976, lng: 19.700677 },
    { lat: 49.397568, lng: 19.700436 },
    { lat: 49.397576, lng: 19.700257 },
    { lat: 49.397598, lng: 19.700201 },
    { lat: 49.397709, lng: 19.70013 },
    { lat: 49.398036, lng: 19.700169 },
    { lat: 49.398127, lng: 19.700172 },
    { lat: 49.398242, lng: 19.70021 },
    { lat: 49.398358, lng: 19.700367 },
    { lat: 49.398447, lng: 19.700583 },
    { lat: 49.398683, lng: 19.700669 },
    { lat: 49.398757, lng: 19.700657 },
    { lat: 49.398879, lng: 19.70057 },
    { lat: 49.398961, lng: 19.700456 },
    { lat: 49.399412, lng: 19.699988 },
    { lat: 49.39959, lng: 19.699854 },
    { lat: 49.399677, lng: 19.699767 },
    { lat: 49.399717, lng: 19.699652 },
    { lat: 49.399686, lng: 19.699588 },
    { lat: 49.39948, lng: 19.699343 },
    { lat: 49.399359, lng: 19.699113 },
    { lat: 49.399216, lng: 19.699037 },
    { lat: 49.399151, lng: 19.699039 },
    { lat: 49.399097, lng: 19.699011 },
    { lat: 49.399042, lng: 19.698903 },
    { lat: 49.399066, lng: 19.698827 },
    { lat: 49.399092, lng: 19.698744 },
    { lat: 49.399085, lng: 19.698647 },
    { lat: 49.399064, lng: 19.698583 },
    { lat: 49.399027, lng: 19.698566 },
    { lat: 49.398966, lng: 19.698585 },
    { lat: 49.398816, lng: 19.698673 },
    { lat: 49.398757, lng: 19.698801 },
    { lat: 49.398719, lng: 19.698932 },
    { lat: 49.398632, lng: 19.698988 },
    { lat: 49.398509, lng: 19.699003 },
    { lat: 49.398464, lng: 19.698996 },
    { lat: 49.398232, lng: 19.698825 },
    { lat: 49.398034, lng: 19.698605 },
    { lat: 49.397989, lng: 19.69848 },
    { lat: 49.397985, lng: 19.698405 },
    { lat: 49.398013, lng: 19.698271 },
    { lat: 49.398051, lng: 19.698213 },
    { lat: 49.398201, lng: 19.698199 },
    { lat: 49.398276, lng: 19.697978 },
    { lat: 49.398441, lng: 19.697608 },
    { lat: 49.39851, lng: 19.697607 },
    { lat: 49.398528, lng: 19.697671 },
    { lat: 49.398607, lng: 19.697857 },
    { lat: 49.39866, lng: 19.697879 },
    { lat: 49.398736, lng: 19.697844 },
    { lat: 49.398825, lng: 19.697776 },
    { lat: 49.398985, lng: 19.697737 },
    { lat: 49.399084, lng: 19.697684 },
    { lat: 49.399122, lng: 19.697629 },
    { lat: 49.399164, lng: 19.697476 },
    { lat: 49.3992, lng: 19.6974 },
    { lat: 49.399389, lng: 19.697098 },
    { lat: 49.399453, lng: 19.696928 },
    { lat: 49.399492, lng: 19.69676 },
    { lat: 49.399464, lng: 19.696424 },
    { lat: 49.399433, lng: 19.69631 },
    { lat: 49.399393, lng: 19.696251 },
    { lat: 49.39933, lng: 19.696225 },
    { lat: 49.399274, lng: 19.696275 },
    { lat: 49.399216, lng: 19.696276 },
    { lat: 49.399193, lng: 19.696199 },
    { lat: 49.399107, lng: 19.696062 },
    { lat: 49.399, lng: 19.695731 },
    { lat: 49.39895, lng: 19.695446 },
    { lat: 49.398975, lng: 19.695262 },
    { lat: 49.399084, lng: 19.694996 },
    { lat: 49.399147, lng: 19.694901 },
    { lat: 49.399249, lng: 19.694866 },
    { lat: 49.399323, lng: 19.694936 },
    { lat: 49.399507, lng: 19.695383 },
    { lat: 49.399597, lng: 19.695459 },
    { lat: 49.399655, lng: 19.695462 },
    { lat: 49.399726, lng: 19.695412 },
    { lat: 49.399786, lng: 19.69531 },
    { lat: 49.399817, lng: 19.695169 },
    { lat: 49.399656, lng: 19.694618 },
    { lat: 49.399625, lng: 19.694448 },
    { lat: 49.399619, lng: 19.694412 },
    { lat: 49.399622, lng: 19.694378 },
    { lat: 49.399647, lng: 19.694142 },
    { lat: 49.399573, lng: 19.693937 },
    { lat: 49.399441, lng: 19.693778 },
    { lat: 49.399338, lng: 19.693699 },
    { lat: 49.399267, lng: 19.693453 },
    { lat: 49.399347, lng: 19.693215 },
    { lat: 49.399402, lng: 19.693159 },
    { lat: 49.399425, lng: 19.693136 },
    { lat: 49.399527, lng: 19.693126 },
    { lat: 49.399566, lng: 19.693202 },
    { lat: 49.399565, lng: 19.693222 },
    { lat: 49.399554, lng: 19.693383 },
    { lat: 49.39957, lng: 19.693439 },
    { lat: 49.399644, lng: 19.693464 },
    { lat: 49.399767, lng: 19.693341 },
    { lat: 49.399848, lng: 19.693187 },
    { lat: 49.400287, lng: 19.692795 },
    { lat: 49.400379, lng: 19.6928 },
    { lat: 49.400454, lng: 19.692852 },
    { lat: 49.400513, lng: 19.692999 },
    { lat: 49.400453, lng: 19.693324 },
    { lat: 49.400274, lng: 19.693509 },
    { lat: 49.400285, lng: 19.693592 },
    { lat: 49.400317, lng: 19.693665 },
    { lat: 49.40045, lng: 19.693763 },
    { lat: 49.400597, lng: 19.693616 },
    { lat: 49.400625, lng: 19.693446 },
    { lat: 49.400689, lng: 19.693251 },
    { lat: 49.400875, lng: 19.692788 },
    { lat: 49.400875, lng: 19.692727 },
    { lat: 49.400788, lng: 19.692629 },
    { lat: 49.40063, lng: 19.692622 },
    { lat: 49.400597, lng: 19.692581 },
    { lat: 49.400561, lng: 19.692467 },
    { lat: 49.400515, lng: 19.692268 },
    { lat: 49.400301, lng: 19.692029 },
    { lat: 49.400042, lng: 19.691915 },
    { lat: 49.399799, lng: 19.691824 },
    { lat: 49.399717, lng: 19.691717 },
    { lat: 49.399676, lng: 19.691407 },
    { lat: 49.399707, lng: 19.690993 },
    { lat: 49.399862, lng: 19.690236 },
    { lat: 49.399865, lng: 19.690096 },
    { lat: 49.400031, lng: 19.689554 },
    { lat: 49.400175, lng: 19.689488 },
    { lat: 49.400332, lng: 19.689473 },
    { lat: 49.400457, lng: 19.68955 },
    { lat: 49.400438, lng: 19.689643 },
    { lat: 49.400421, lng: 19.689854 },
    { lat: 49.400319, lng: 19.690206 },
    { lat: 49.400275, lng: 19.690601 },
    { lat: 49.400286, lng: 19.69077 },
    { lat: 49.400316, lng: 19.690845 },
    { lat: 49.400366, lng: 19.690917 },
    { lat: 49.400441, lng: 19.690962 },
    { lat: 49.400543, lng: 19.690962 },
    { lat: 49.400625, lng: 19.691091 },
    { lat: 49.400713, lng: 19.691395 },
    { lat: 49.400813, lng: 19.691547 },
    { lat: 49.400952, lng: 19.691654 },
    { lat: 49.401032, lng: 19.691638 },
    { lat: 49.401206, lng: 19.691391 },
    { lat: 49.401255, lng: 19.691245 },
    { lat: 49.401215, lng: 19.69097 },
    { lat: 49.401078, lng: 19.690764 },
    { lat: 49.401018, lng: 19.690667 },
    { lat: 49.40101, lng: 19.690603 },
    { lat: 49.401034, lng: 19.690519 },
    { lat: 49.401051, lng: 19.69024 },
    { lat: 49.401005, lng: 19.689973 },
    { lat: 49.401034, lng: 19.689667 },
    { lat: 49.401068, lng: 19.689534 },
    { lat: 49.401179, lng: 19.689271 },
    { lat: 49.401185, lng: 19.689188 },
    { lat: 49.401136, lng: 19.68894 },
    { lat: 49.401108, lng: 19.688676 },
    { lat: 49.401164, lng: 19.688583 },
    { lat: 49.401294, lng: 19.688557 },
    { lat: 49.401469, lng: 19.688531 },
    { lat: 49.401553, lng: 19.688463 },
    { lat: 49.401604, lng: 19.688395 },
    { lat: 49.401648, lng: 19.688196 },
    { lat: 49.401697, lng: 19.688041 },
    { lat: 49.401753, lng: 19.687926 },
    { lat: 49.401919, lng: 19.687618 },
    { lat: 49.402205, lng: 19.687222 },
    { lat: 49.402328, lng: 19.687162 },
    { lat: 49.40252, lng: 19.687185 },
    { lat: 49.402732, lng: 19.687287 },
    { lat: 49.402901, lng: 19.687297 },
    { lat: 49.403021, lng: 19.68721 },
    { lat: 49.403103, lng: 19.687085 },
    { lat: 49.403089, lng: 19.687029 },
    { lat: 49.402971, lng: 19.686861 },
    { lat: 49.402783, lng: 19.686525 },
    { lat: 49.402456, lng: 19.686177 },
    { lat: 49.402258, lng: 19.685987 },
    { lat: 49.402232, lng: 19.685909 },
    { lat: 49.40227, lng: 19.685768 },
    { lat: 49.402431, lng: 19.685588 },
    { lat: 49.402665, lng: 19.68535 },
    { lat: 49.402814, lng: 19.685321 },
    { lat: 49.403178, lng: 19.685452 },
    { lat: 49.403316, lng: 19.685484 },
    { lat: 49.403378, lng: 19.685472 },
    { lat: 49.403584, lng: 19.685373 },
    { lat: 49.40361, lng: 19.685252 },
    { lat: 49.403425, lng: 19.685039 },
    { lat: 49.403281, lng: 19.684902 },
    { lat: 49.403266, lng: 19.684781 },
    { lat: 49.403336, lng: 19.684658 },
    { lat: 49.403351, lng: 19.68453 },
    { lat: 49.403332, lng: 19.68428 },
    { lat: 49.403116, lng: 19.684143 },
    { lat: 49.402966, lng: 19.683963 },
    { lat: 49.402881, lng: 19.68394 },
    { lat: 49.402838, lng: 19.683985 },
    { lat: 49.402714, lng: 19.684208 },
    { lat: 49.402675, lng: 19.684211 },
    { lat: 49.40264, lng: 19.684118 },
    { lat: 49.402617, lng: 19.683975 },
    { lat: 49.402616, lng: 19.683711 },
    { lat: 49.402713, lng: 19.683578 },
    { lat: 49.402784, lng: 19.683393 },
    { lat: 49.402827, lng: 19.683165 },
    { lat: 49.402941, lng: 19.682837 },
    { lat: 49.402999, lng: 19.682745 },
    { lat: 49.403029, lng: 19.682727 },
    { lat: 49.403117, lng: 19.682742 },
    { lat: 49.403173, lng: 19.682789 },
    { lat: 49.403223, lng: 19.682879 },
    { lat: 49.403279, lng: 19.682899 },
    { lat: 49.403719, lng: 19.682813 },
    { lat: 49.403803, lng: 19.682758 },
    { lat: 49.403834, lng: 19.682717 },
    { lat: 49.403914, lng: 19.682594 },
    { lat: 49.403973, lng: 19.682388 },
    { lat: 49.403978, lng: 19.682175 },
    { lat: 49.403959, lng: 19.682114 },
    { lat: 49.40385, lng: 19.682011 },
    { lat: 49.403773, lng: 19.681923 },
    { lat: 49.403739, lng: 19.68185 },
    { lat: 49.403598, lng: 19.681767 },
    { lat: 49.403404, lng: 19.681733 },
    { lat: 49.403293, lng: 19.68177 },
    { lat: 49.403169, lng: 19.681842 },
    { lat: 49.403068, lng: 19.681875 },
    { lat: 49.402656, lng: 19.681819 },
    { lat: 49.402478, lng: 19.681715 },
    { lat: 49.402299, lng: 19.681513 },
    { lat: 49.402243, lng: 19.681389 },
    { lat: 49.402071, lng: 19.680909 },
    { lat: 49.402053, lng: 19.6808 },
    { lat: 49.402154, lng: 19.680087 },
    { lat: 49.402294, lng: 19.679715 },
    { lat: 49.402522, lng: 19.67943 },
    { lat: 49.402616, lng: 19.679388 },
    { lat: 49.402731, lng: 19.679431 },
    { lat: 49.403089, lng: 19.679675 },
    { lat: 49.403158, lng: 19.679487 },
    { lat: 49.403462, lng: 19.679352 },
    { lat: 49.403614, lng: 19.679251 },
    { lat: 49.404089, lng: 19.679044 },
    { lat: 49.404286, lng: 19.678844 },
    { lat: 49.404369, lng: 19.67855 },
    { lat: 49.40433, lng: 19.6783 },
    { lat: 49.404291, lng: 19.678184 },
    { lat: 49.404135, lng: 19.678062 },
    { lat: 49.404072, lng: 19.678097 },
    { lat: 49.404014, lng: 19.678189 },
    { lat: 49.40399, lng: 19.678377 },
    { lat: 49.403935, lng: 19.678394 },
    { lat: 49.403834, lng: 19.678345 },
    { lat: 49.40365, lng: 19.678444 },
    { lat: 49.403473, lng: 19.678405 },
    { lat: 49.403345, lng: 19.678273 },
    { lat: 49.403173, lng: 19.678252 },
    { lat: 49.402961, lng: 19.678083 },
    { lat: 49.402919, lng: 19.677978 },
    { lat: 49.4029, lng: 19.677719 },
    { lat: 49.402909, lng: 19.677514 },
    { lat: 49.402948, lng: 19.677389 },
    { lat: 49.403, lng: 19.67733 },
    { lat: 49.40305, lng: 19.677312 },
    { lat: 49.403162, lng: 19.677463 },
    { lat: 49.403244, lng: 19.677487 },
    { lat: 49.403325, lng: 19.677403 },
    { lat: 49.403411, lng: 19.67712 },
    { lat: 49.403438, lng: 19.676791 },
    { lat: 49.403409, lng: 19.676571 },
    { lat: 49.403382, lng: 19.676527 },
    { lat: 49.403322, lng: 19.67652 },
    { lat: 49.403193, lng: 19.676624 },
    { lat: 49.403121, lng: 19.676561 },
    { lat: 49.403119, lng: 19.676486 },
    { lat: 49.40324, lng: 19.676261 },
    { lat: 49.403358, lng: 19.675931 },
    { lat: 49.403438, lng: 19.675847 },
    { lat: 49.403575, lng: 19.67579 },
    { lat: 49.403639, lng: 19.675841 },
    { lat: 49.403726, lng: 19.675963 },
    { lat: 49.40379, lng: 19.675985 },
    { lat: 49.40416, lng: 19.675962 },
    { lat: 49.404343, lng: 19.675862 },
    { lat: 49.404544, lng: 19.675622 },
    { lat: 49.404791, lng: 19.675097 },
    { lat: 49.404784, lng: 19.674815 },
    { lat: 49.404794, lng: 19.674431 },
    { lat: 49.404739, lng: 19.674215 },
    { lat: 49.404651, lng: 19.674192 },
    { lat: 49.404624, lng: 19.674142 },
    { lat: 49.40458, lng: 19.673897 },
    { lat: 49.404616, lng: 19.673739 },
    { lat: 49.404673, lng: 19.673595 },
    { lat: 49.404623, lng: 19.673088 },
    { lat: 49.404513, lng: 19.67285 },
    { lat: 49.404459, lng: 19.672787 },
    { lat: 49.404385, lng: 19.672784 },
    { lat: 49.404335, lng: 19.672832 },
    { lat: 49.404242, lng: 19.672973 },
    { lat: 49.404187, lng: 19.67302 },
    { lat: 49.404101, lng: 19.672999 },
    { lat: 49.404068, lng: 19.672881 },
    { lat: 49.404207, lng: 19.672607 },
    { lat: 49.404326, lng: 19.672342 },
    { lat: 49.404475, lng: 19.672071 },
    { lat: 49.404569, lng: 19.671989 },
    { lat: 49.404668, lng: 19.671948 },
    { lat: 49.404759, lng: 19.671939 },
    { lat: 49.405015, lng: 19.671994 },
    { lat: 49.405194, lng: 19.67208 },
    { lat: 49.405344, lng: 19.672241 },
    { lat: 49.405396, lng: 19.672358 },
    { lat: 49.405497, lng: 19.672427 },
    { lat: 49.40561, lng: 19.672438 },
    { lat: 49.405688, lng: 19.6724 },
    { lat: 49.405736, lng: 19.672317 },
    { lat: 49.405766, lng: 19.672191 },
    { lat: 49.405884, lng: 19.672052 },
    { lat: 49.405965, lng: 19.671913 },
    { lat: 49.406056, lng: 19.671652 },
    { lat: 49.406051, lng: 19.671465 },
    { lat: 49.406023, lng: 19.67135 },
    { lat: 49.405943, lng: 19.67121 },
    { lat: 49.405919, lng: 19.671123 },
    { lat: 49.405789, lng: 19.670778 },
    { lat: 49.405725, lng: 19.670575 },
    { lat: 49.405656, lng: 19.670309 },
    { lat: 49.405612, lng: 19.670207 },
    { lat: 49.405489, lng: 19.670055 },
    { lat: 49.405418, lng: 19.669888 },
    { lat: 49.4053581, lng: 19.6697292 },
    { lat: 49.4053893, lng: 19.6696582 },
    { lat: 49.4054603, lng: 19.6695542 },
    { lat: 49.405583, lng: 19.6693186 },
    { lat: 49.4057217, lng: 19.6692244 },
    { lat: 49.405861, lng: 19.669297 },
    { lat: 49.4059051, lng: 19.669267 },
    { lat: 49.4061309, lng: 19.6689386 },
    { lat: 49.4062183, lng: 19.6687854 },
    { lat: 49.4062638, lng: 19.6686238 },
    { lat: 49.406231, lng: 19.6683682 },
    { lat: 49.4061486, lng: 19.6681397 },
    { lat: 49.4060005, lng: 19.6680019 },
    { lat: 49.4058987, lng: 19.6679293 },
    { lat: 49.4058073, lng: 19.6677938 },
    { lat: 49.4057255, lng: 19.6677439 },
    { lat: 49.4056631, lng: 19.6676836 },
    { lat: 49.40559, lng: 19.66748 },
    { lat: 49.4055678, lng: 19.6673062 },
    { lat: 49.4055786, lng: 19.6671555 },
    { lat: 49.405621, lng: 19.6669301 },
    { lat: 49.4056678, lng: 19.6667829 },
    { lat: 49.4057679, lng: 19.6666123 },
    { lat: 49.4058593, lng: 19.6666023 },
    { lat: 49.405905, lng: 19.666752 },
    { lat: 49.4060488, lng: 19.6668879 },
    { lat: 49.4061222, lng: 19.6669241 },
    { lat: 49.4064352, lng: 19.6667845 },
    { lat: 49.4064574, lng: 19.6666919 },
    { lat: 49.4063963, lng: 19.6664793 },
    { lat: 49.406397, lng: 19.6663827 },
    { lat: 49.4064767, lng: 19.6662574 },
    { lat: 49.4066662, lng: 19.6661416 },
    { lat: 49.4067142, lng: 19.6660892 },
    { lat: 49.4067511, lng: 19.6660198 },
    { lat: 49.4068003, lng: 19.665781 },
    { lat: 49.4067746, lng: 19.6654706 },
    { lat: 49.4066777, lng: 19.665241 },
    { lat: 49.4065194, lng: 19.6650496 },
    { lat: 49.4062854, lng: 19.6649594 },
    { lat: 49.4062336, lng: 19.6648162 },
    { lat: 49.4062567, lng: 19.6647537 },
    { lat: 49.4062958, lng: 19.6647283 },
    { lat: 49.4063258, lng: 19.6647432 },
    { lat: 49.4063595, lng: 19.6647278 },
    { lat: 49.4063822, lng: 19.6646316 },
    { lat: 49.4063628, lng: 19.6644547 },
    { lat: 49.4063884, lng: 19.6643224 },
    { lat: 49.4064042, lng: 19.6642929 },
  ],
  OravskýBielyPotok: [
    { lat: 49.271538, lng: 19.536522 },
    { lat: 49.271536, lng: 19.5368108 },
    { lat: 49.271532, lng: 19.537384 },
    { lat: 49.271417, lng: 19.537831 },
    { lat: 49.271114, lng: 19.538447 },
    { lat: 49.27106, lng: 19.538622 },
    { lat: 49.270791, lng: 19.539274 },
    { lat: 49.270762, lng: 19.539483 },
    { lat: 49.270561, lng: 19.539502 },
    { lat: 49.270502, lng: 19.539555 },
    { lat: 49.270215, lng: 19.539824 },
    { lat: 49.269861, lng: 19.539944 },
    { lat: 49.269322, lng: 19.540012 },
    { lat: 49.26862, lng: 19.539978 },
    { lat: 49.268468, lng: 19.540036 },
    { lat: 49.267737, lng: 19.540204 },
    { lat: 49.267432, lng: 19.540088 },
    { lat: 49.267072, lng: 19.539874 },
    { lat: 49.266684, lng: 19.539893 },
    { lat: 49.266542, lng: 19.539954 },
    { lat: 49.26624, lng: 19.540086 },
    { lat: 49.265446, lng: 19.540192 },
    { lat: 49.265148, lng: 19.540229 },
    { lat: 49.264781, lng: 19.540326 },
    { lat: 49.264011, lng: 19.540428 },
    { lat: 49.2635372, lng: 19.5405449 },
    { lat: 49.263245, lng: 19.540617 },
    { lat: 49.261547, lng: 19.540443 },
    { lat: 49.25947, lng: 19.540593 },
    { lat: 49.257947, lng: 19.541103 },
    { lat: 49.255043, lng: 19.54184 },
    { lat: 49.254543, lng: 19.542075 },
    { lat: 49.254496, lng: 19.542097 },
    { lat: 49.251055, lng: 19.543713 },
    { lat: 49.250693, lng: 19.543778 },
    { lat: 49.250616, lng: 19.543814 },
    { lat: 49.249415, lng: 19.544089 },
    { lat: 49.24683, lng: 19.544514 },
    { lat: 49.245871, lng: 19.544757 },
    { lat: 49.245031, lng: 19.54497 },
    { lat: 49.244263, lng: 19.545904 },
    { lat: 49.243653, lng: 19.547109 },
    { lat: 49.243493, lng: 19.547831 },
    { lat: 49.243452, lng: 19.549461 },
    { lat: 49.243635, lng: 19.550939 },
    { lat: 49.243425, lng: 19.55242 },
    { lat: 49.24326, lng: 19.553418 },
    { lat: 49.243121, lng: 19.554296 },
    { lat: 49.243017, lng: 19.554891 },
    { lat: 49.242902, lng: 19.555748 },
    { lat: 49.242461, lng: 19.557881 },
    { lat: 49.242645, lng: 19.559606 },
    { lat: 49.242819, lng: 19.560734 },
    { lat: 49.242781, lng: 19.561599 },
    { lat: 49.242712, lng: 19.562555 },
    { lat: 49.242681, lng: 19.563728 },
    { lat: 49.242644, lng: 19.564197 },
    { lat: 49.24251, lng: 19.564667 },
    { lat: 49.241665, lng: 19.566245 },
    { lat: 49.241362, lng: 19.5669 },
    { lat: 49.241095, lng: 19.567766 },
    { lat: 49.240983, lng: 19.567789 },
    { lat: 49.240956, lng: 19.568337 },
    { lat: 49.240935, lng: 19.568773 },
    { lat: 49.240967, lng: 19.569512 },
    { lat: 49.241344, lng: 19.570739 },
    { lat: 49.241315, lng: 19.570856 },
    { lat: 49.241652, lng: 19.571932 },
    { lat: 49.241843, lng: 19.572182 },
    { lat: 49.2426887, lng: 19.5720174 },
    { lat: 49.2438846, lng: 19.5718517 },
    { lat: 49.2446972, lng: 19.571735 },
    { lat: 49.2455234, lng: 19.571638 },
    { lat: 49.2463313, lng: 19.5715174 },
    { lat: 49.2471779, lng: 19.5714018 },
    { lat: 49.2479774, lng: 19.571268 },
    { lat: 49.2483305, lng: 19.571228 },
    { lat: 49.2486389, lng: 19.5713851 },
    { lat: 49.2490058, lng: 19.5715869 },
    { lat: 49.2492802, lng: 19.5717555 },
    { lat: 49.2495717, lng: 19.571922 },
    { lat: 49.249912, lng: 19.5721448 },
    { lat: 49.2501679, lng: 19.5722221 },
    { lat: 49.2502639, lng: 19.572278 },
    { lat: 49.2510208, lng: 19.5728235 },
    { lat: 49.2511587, lng: 19.5729583 },
    { lat: 49.2514079, lng: 19.5732921 },
    { lat: 49.2516237, lng: 19.5736279 },
    { lat: 49.2517857, lng: 19.5737772 },
    { lat: 49.2535641, lng: 19.5751383 },
    { lat: 49.2564838, lng: 19.5776307 },
    { lat: 49.2581052, lng: 19.5791089 },
    { lat: 49.2584711, lng: 19.5793558 },
    { lat: 49.2590944, lng: 19.5795876 },
    { lat: 49.2595103, lng: 19.5798635 },
    { lat: 49.2604321, lng: 19.5805232 },
    { lat: 49.2613182, lng: 19.5813375 },
    { lat: 49.2616899, lng: 19.5818857 },
    { lat: 49.2621748, lng: 19.5825949 },
    { lat: 49.2624185, lng: 19.5829056 },
    { lat: 49.2628709, lng: 19.5835965 },
    { lat: 49.2633411, lng: 19.5840566 },
    { lat: 49.26393, lng: 19.5846258 },
    { lat: 49.2640947, lng: 19.5847084 },
    { lat: 49.2645113, lng: 19.5849274 },
    { lat: 49.2647256, lng: 19.5850828 },
    { lat: 49.2650548, lng: 19.5851846 },
    { lat: 49.2654224, lng: 19.5851702 },
    { lat: 49.2657136, lng: 19.5850567 },
    { lat: 49.2659782, lng: 19.5848757 },
    { lat: 49.2662071, lng: 19.5847795 },
    { lat: 49.2664754, lng: 19.5846561 },
    { lat: 49.2666167, lng: 19.5845421 },
    { lat: 49.2668611, lng: 19.5844169 },
    { lat: 49.2670446, lng: 19.5842865 },
    { lat: 49.2672522, lng: 19.5841937 },
    { lat: 49.2678264, lng: 19.5838589 },
    { lat: 49.2680903, lng: 19.5837374 },
    { lat: 49.2682852, lng: 19.5836881 },
    { lat: 49.2684099, lng: 19.5836822 },
    { lat: 49.268509, lng: 19.5841813 },
    { lat: 49.2686696, lng: 19.5853225 },
    { lat: 49.2688298, lng: 19.5851535 },
    { lat: 49.2696074, lng: 19.5848558 },
    { lat: 49.2701175, lng: 19.5846245 },
    { lat: 49.2709929, lng: 19.583972 },
    { lat: 49.2717754, lng: 19.5831051 },
    { lat: 49.2722418, lng: 19.5829086 },
    { lat: 49.2730329, lng: 19.5826362 },
    { lat: 49.2736577, lng: 19.5817224 },
    { lat: 49.2780049, lng: 19.5885091 },
    { lat: 49.2782094, lng: 19.5884743 },
    { lat: 49.2784021, lng: 19.5884757 },
    { lat: 49.2785525, lng: 19.5884607 },
    { lat: 49.2787761, lng: 19.5884965 },
    { lat: 49.2790819, lng: 19.5883825 },
    { lat: 49.2791449, lng: 19.5884813 },
    { lat: 49.2791598, lng: 19.5883613 },
    { lat: 49.2792411, lng: 19.5883164 },
    { lat: 49.2793013, lng: 19.5883537 },
    { lat: 49.2795375, lng: 19.5882921 },
    { lat: 49.2796557, lng: 19.5882173 },
    { lat: 49.2799191, lng: 19.5881107 },
    { lat: 49.2802012, lng: 19.5879674 },
    { lat: 49.2805823, lng: 19.5881011 },
    { lat: 49.280797, lng: 19.5880916 },
    { lat: 49.28093, lng: 19.587842 },
    { lat: 49.2811586, lng: 19.5877105 },
    { lat: 49.2812479, lng: 19.5875448 },
    { lat: 49.2814568, lng: 19.587353 },
    { lat: 49.2816195, lng: 19.5872652 },
    { lat: 49.2816832, lng: 19.5871004 },
    { lat: 49.2818116, lng: 19.5870018 },
    { lat: 49.2818777, lng: 19.5869162 },
    { lat: 49.2820071, lng: 19.5868377 },
    { lat: 49.2824872, lng: 19.5870407 },
    { lat: 49.2827331, lng: 19.5873227 },
    { lat: 49.283024, lng: 19.5876029 },
    { lat: 49.2831112, lng: 19.5875404 },
    { lat: 49.2832105, lng: 19.5875181 },
    { lat: 49.2833336, lng: 19.587451 },
    { lat: 49.2836598, lng: 19.5876406 },
    { lat: 49.2837567, lng: 19.5877636 },
    { lat: 49.2838325, lng: 19.5877488 },
    { lat: 49.2840797, lng: 19.5877802 },
    { lat: 49.2843214, lng: 19.5879476 },
    { lat: 49.2845946, lng: 19.5882109 },
    { lat: 49.2847084, lng: 19.5883387 },
    { lat: 49.2850411, lng: 19.5877394 },
    { lat: 49.2851124, lng: 19.5875233 },
    { lat: 49.2851487, lng: 19.5872492 },
    { lat: 49.2851654, lng: 19.5867955 },
    { lat: 49.2851804, lng: 19.5866031 },
    { lat: 49.2852131, lng: 19.5864733 },
    { lat: 49.2852887, lng: 19.5863015 },
    { lat: 49.2854942, lng: 19.5860891 },
    { lat: 49.2855914, lng: 19.5860922 },
    { lat: 49.2858066, lng: 19.5860412 },
    { lat: 49.2859407, lng: 19.5859935 },
    { lat: 49.2862437, lng: 19.585865 },
    { lat: 49.2863184, lng: 19.5859001 },
    { lat: 49.2868916, lng: 19.5858191 },
    { lat: 49.2870512, lng: 19.5857619 },
    { lat: 49.2871751, lng: 19.5857338 },
    { lat: 49.2873355, lng: 19.5856682 },
    { lat: 49.2875288, lng: 19.585483 },
    { lat: 49.2875855, lng: 19.5853535 },
    { lat: 49.2876176, lng: 19.5849536 },
    { lat: 49.2877012, lng: 19.584507 },
    { lat: 49.2878331, lng: 19.5839782 },
    { lat: 49.2877873, lng: 19.5839108 },
    { lat: 49.2879033, lng: 19.58318 },
    { lat: 49.2882249, lng: 19.5823399 },
    { lat: 49.2884344, lng: 19.5819653 },
    { lat: 49.2887377, lng: 19.5816956 },
    { lat: 49.2888172, lng: 19.5817819 },
    { lat: 49.2893159, lng: 19.5822532 },
    { lat: 49.2898375, lng: 19.5827532 },
    { lat: 49.2899523, lng: 19.5828982 },
    { lat: 49.2902367, lng: 19.5831105 },
    { lat: 49.2905638, lng: 19.5832722 },
    { lat: 49.2906964, lng: 19.5832861 },
    { lat: 49.2907483, lng: 19.5833369 },
    { lat: 49.2908295, lng: 19.5833681 },
    { lat: 49.2909743, lng: 19.5834536 },
    { lat: 49.2912137, lng: 19.5836692 },
    { lat: 49.2913975, lng: 19.5837467 },
    { lat: 49.2916127, lng: 19.5837494 },
    { lat: 49.2919341, lng: 19.5838041 },
    { lat: 49.2927631, lng: 19.5841993 },
    { lat: 49.2936486, lng: 19.5844995 },
    { lat: 49.2949577, lng: 19.584826 },
    { lat: 49.2967721, lng: 19.5852153 },
    { lat: 49.2979843, lng: 19.5853874 },
    { lat: 49.2993287, lng: 19.5845691 },
    { lat: 49.3000999, lng: 19.5843041 },
    { lat: 49.3004023, lng: 19.5843398 },
    { lat: 49.3011051, lng: 19.584335 },
    { lat: 49.3013958, lng: 19.5817759 },
    { lat: 49.3015175, lng: 19.5785656 },
    { lat: 49.3013786, lng: 19.5775809 },
    { lat: 49.3011266, lng: 19.5756831 },
    { lat: 49.3005852, lng: 19.5741912 },
    { lat: 49.3005744, lng: 19.5741765 },
    { lat: 49.2998542, lng: 19.5731663 },
    { lat: 49.298992, lng: 19.5719591 },
    { lat: 49.2984771, lng: 19.5706567 },
    { lat: 49.2982019, lng: 19.5694199 },
    { lat: 49.2982356, lng: 19.5680926 },
    { lat: 49.297931, lng: 19.567011 },
    { lat: 49.2976378, lng: 19.5659798 },
    { lat: 49.2971542, lng: 19.5649441 },
    { lat: 49.2965278, lng: 19.5640217 },
    { lat: 49.2957249, lng: 19.5626551 },
    { lat: 49.2953907, lng: 19.5617146 },
    { lat: 49.2953495, lng: 19.5616009 },
    { lat: 49.2952275, lng: 19.5604676 },
    { lat: 49.2951712, lng: 19.5591191 },
    { lat: 49.29513, lng: 19.5587065 },
    { lat: 49.2949916, lng: 19.55731 },
    { lat: 49.294837, lng: 19.5567286 },
    { lat: 49.2947339, lng: 19.5563431 },
    { lat: 49.2940925, lng: 19.5554144 },
    { lat: 49.2932578, lng: 19.5543189 },
    { lat: 49.2928389, lng: 19.5534495 },
    { lat: 49.2919589, lng: 19.5516794 },
    { lat: 49.2913244, lng: 19.5502506 },
    { lat: 49.2912309, lng: 19.549905 },
    { lat: 49.2910393, lng: 19.5488311 },
    { lat: 49.2911502, lng: 19.548365 },
    { lat: 49.2914268, lng: 19.5475062 },
    { lat: 49.2918096, lng: 19.5462811 },
    { lat: 49.2916966, lng: 19.5457711 },
    { lat: 49.2912593, lng: 19.545347 },
    { lat: 49.290791, lng: 19.544906 },
    { lat: 49.2902472, lng: 19.5443408 },
    { lat: 49.2901051, lng: 19.5441905 },
    { lat: 49.2892671, lng: 19.5432475 },
    { lat: 49.2891449, lng: 19.5431619 },
    { lat: 49.2886091, lng: 19.5428251 },
    { lat: 49.2881074, lng: 19.5422935 },
    { lat: 49.2880726, lng: 19.5420983 },
    { lat: 49.2878641, lng: 19.5415497 },
    { lat: 49.2875189, lng: 19.5412668 },
    { lat: 49.2873277, lng: 19.5411481 },
    { lat: 49.2872155, lng: 19.5411105 },
    { lat: 49.2870491, lng: 19.5409718 },
    { lat: 49.2867452, lng: 19.5404012 },
    { lat: 49.2866106, lng: 19.5402649 },
    { lat: 49.2863595, lng: 19.5398988 },
    { lat: 49.2863445, lng: 19.5396658 },
    { lat: 49.2864211, lng: 19.5397674 },
    { lat: 49.2865583, lng: 19.5394338 },
    { lat: 49.2874549, lng: 19.5380334 },
    { lat: 49.2876157, lng: 19.536564 },
    { lat: 49.2878799, lng: 19.5353558 },
    { lat: 49.288105, lng: 19.5349529 },
    { lat: 49.2883369, lng: 19.5345508 },
    { lat: 49.2886008, lng: 19.534206 },
    { lat: 49.2883036, lng: 19.5333954 },
    { lat: 49.2876373, lng: 19.5322377 },
    { lat: 49.2872505, lng: 19.5318629 },
    { lat: 49.2871234, lng: 19.5317395 },
    { lat: 49.2869502, lng: 19.5315756 },
    { lat: 49.2865271, lng: 19.5312119 },
    { lat: 49.2864202, lng: 19.5311182 },
    { lat: 49.2859698, lng: 19.530823 },
    { lat: 49.2859384, lng: 19.5308022 },
    { lat: 49.2858907, lng: 19.5307708 },
    { lat: 49.2858722, lng: 19.530759 },
    { lat: 49.2852408, lng: 19.5301272 },
    { lat: 49.2851985, lng: 19.5300906 },
    { lat: 49.2845315, lng: 19.5295427 },
    { lat: 49.2844354, lng: 19.5294601 },
    { lat: 49.2842828, lng: 19.5299487 },
    { lat: 49.2841021, lng: 19.530633 },
    { lat: 49.2839598, lng: 19.5309726 },
    { lat: 49.2837178, lng: 19.5314786 },
    { lat: 49.2837045, lng: 19.5315081 },
    { lat: 49.2834057, lng: 19.5318993 },
    { lat: 49.2831544, lng: 19.5325687 },
    { lat: 49.28306, lng: 19.5327743 },
    { lat: 49.2826249, lng: 19.5332423 },
    { lat: 49.2825327, lng: 19.5333968 },
    { lat: 49.282448, lng: 19.5336382 },
    { lat: 49.2823994, lng: 19.533836 },
    { lat: 49.2822997, lng: 19.5344329 },
    { lat: 49.2823036, lng: 19.534503 },
    { lat: 49.2822074, lng: 19.5344703 },
    { lat: 49.2820721, lng: 19.5344189 },
    { lat: 49.2819039, lng: 19.534213 },
    { lat: 49.2816737, lng: 19.5340818 },
    { lat: 49.2816009, lng: 19.5339131 },
    { lat: 49.2814386, lng: 19.5338642 },
    { lat: 49.2811544, lng: 19.5334602 },
    { lat: 49.2809615, lng: 19.5332568 },
    { lat: 49.2806404, lng: 19.5328343 },
    { lat: 49.2804291, lng: 19.5326997 },
    { lat: 49.2803789, lng: 19.5326329 },
    { lat: 49.2801854, lng: 19.5325884 },
    { lat: 49.2800739, lng: 19.5324681 },
    { lat: 49.2800269, lng: 19.5323472 },
    { lat: 49.2799068, lng: 19.5323069 },
    { lat: 49.2798578, lng: 19.5322002 },
    { lat: 49.2797719, lng: 19.5321905 },
    { lat: 49.2796809, lng: 19.5320998 },
    { lat: 49.2795651, lng: 19.5321305 },
    { lat: 49.2793535, lng: 19.5321078 },
    { lat: 49.2790221, lng: 19.5319322 },
    { lat: 49.278832, lng: 19.5319426 },
    { lat: 49.278472, lng: 19.5317765 },
    { lat: 49.2780416, lng: 19.531677 },
    { lat: 49.2778526, lng: 19.5315335 },
    { lat: 49.2777012, lng: 19.5314763 },
    { lat: 49.2775967, lng: 19.531482 },
    { lat: 49.2775898, lng: 19.5314824 },
    { lat: 49.2775123, lng: 19.5314866 },
    { lat: 49.2772877, lng: 19.5314527 },
    { lat: 49.2765036, lng: 19.5311027 },
    { lat: 49.2763409, lng: 19.5310918 },
    { lat: 49.2762464, lng: 19.5310235 },
    { lat: 49.2760527, lng: 19.5310531 },
    { lat: 49.2757912, lng: 19.5309505 },
    { lat: 49.2757155, lng: 19.5308694 },
    { lat: 49.2754887, lng: 19.530861 },
    { lat: 49.2751924, lng: 19.5307621 },
    { lat: 49.2750282, lng: 19.5306463 },
    { lat: 49.2749707, lng: 19.5305428 },
    { lat: 49.2738503, lng: 19.5297922 },
    { lat: 49.2736958, lng: 19.530185 },
    { lat: 49.2736414, lng: 19.5303491 },
    { lat: 49.2734596, lng: 19.5307144 },
    { lat: 49.2731062, lng: 19.5315677 },
    { lat: 49.2730469, lng: 19.5316516 },
    { lat: 49.2728616, lng: 19.5320642 },
    { lat: 49.2727919, lng: 19.5321661 },
    { lat: 49.2725325, lng: 19.5327568 },
    { lat: 49.27247, lng: 19.5328205 },
    { lat: 49.2723959, lng: 19.5330239 },
    { lat: 49.2722948, lng: 19.5333756 },
    { lat: 49.2722941, lng: 19.5334601 },
    { lat: 49.2722674, lng: 19.5335427 },
    { lat: 49.2722635, lng: 19.5336553 },
    { lat: 49.2722238, lng: 19.5337273 },
    { lat: 49.2721802, lng: 19.5339055 },
    { lat: 49.272071, lng: 19.5340852 },
    { lat: 49.271984, lng: 19.5343734 },
    { lat: 49.2719438, lng: 19.5346793 },
    { lat: 49.2719641, lng: 19.5348041 },
    { lat: 49.271948, lng: 19.5352724 },
    { lat: 49.2717153, lng: 19.5360313 },
    { lat: 49.271538, lng: 19.536522 },
  ],
  Podbiel: [
    { lat: 49.326356, lng: 19.425429 },
    { lat: 49.325666, lng: 19.4243083 },
    { lat: 49.3253589, lng: 19.4236985 },
    { lat: 49.324847, lng: 19.4229881 },
    { lat: 49.3237633, lng: 19.4220438 },
    { lat: 49.3227102, lng: 19.4209814 },
    { lat: 49.3206094, lng: 19.419837 },
    { lat: 49.319135, lng: 19.420215 },
    { lat: 49.318878, lng: 19.420513 },
    { lat: 49.317726, lng: 19.421744 },
    { lat: 49.317575, lng: 19.421937 },
    { lat: 49.317273, lng: 19.422538 },
    { lat: 49.316983, lng: 19.423322 },
    { lat: 49.316942, lng: 19.424296 },
    { lat: 49.316744, lng: 19.425038 },
    { lat: 49.316388, lng: 19.426408 },
    { lat: 49.316366, lng: 19.426514 },
    { lat: 49.316299, lng: 19.426732 },
    { lat: 49.315393, lng: 19.427839 },
    { lat: 49.314208, lng: 19.429098 },
    { lat: 49.313667, lng: 19.430002 },
    { lat: 49.312794, lng: 19.430863 },
    { lat: 49.312135, lng: 19.431469 },
    { lat: 49.311744, lng: 19.431787 },
    { lat: 49.311194, lng: 19.432214 },
    { lat: 49.310979, lng: 19.432399 },
    { lat: 49.310743, lng: 19.432642 },
    { lat: 49.310585, lng: 19.432823 },
    { lat: 49.310406, lng: 19.433064 },
    { lat: 49.310351, lng: 19.433612 },
    { lat: 49.310233, lng: 19.434045 },
    { lat: 49.30998, lng: 19.434524 },
    { lat: 49.309234, lng: 19.434719 },
    { lat: 49.308574, lng: 19.434926 },
    { lat: 49.307923, lng: 19.434876 },
    { lat: 49.3076, lng: 19.435035 },
    { lat: 49.307441, lng: 19.43528 },
    { lat: 49.307385, lng: 19.435535 },
    { lat: 49.307322, lng: 19.435784 },
    { lat: 49.306982, lng: 19.436155 },
    { lat: 49.30685, lng: 19.43661 },
    { lat: 49.306786, lng: 19.43705 },
    { lat: 49.306495, lng: 19.437672 },
    { lat: 49.306083, lng: 19.438207 },
    { lat: 49.305873, lng: 19.438397 },
    { lat: 49.305712, lng: 19.438403 },
    { lat: 49.305248, lng: 19.438634 },
    { lat: 49.305045, lng: 19.438819 },
    { lat: 49.304739, lng: 19.439256 },
    { lat: 49.304381, lng: 19.439772 },
    { lat: 49.304333, lng: 19.439902 },
    { lat: 49.304331, lng: 19.440621 },
    { lat: 49.304301, lng: 19.440801 },
    { lat: 49.304259, lng: 19.44117 },
    { lat: 49.304224, lng: 19.441576 },
    { lat: 49.304066, lng: 19.442177 },
    { lat: 49.303726, lng: 19.442675 },
    { lat: 49.303595, lng: 19.442992 },
    { lat: 49.303307, lng: 19.443713 },
    { lat: 49.303369, lng: 19.444013 },
    { lat: 49.303289, lng: 19.444612 },
    { lat: 49.303312, lng: 19.445467 },
    { lat: 49.303186, lng: 19.446071 },
    { lat: 49.302682, lng: 19.446542 },
    { lat: 49.302294, lng: 19.446835 },
    { lat: 49.302146, lng: 19.446965 },
    { lat: 49.301994, lng: 19.447552 },
    { lat: 49.301988, lng: 19.447657 },
    { lat: 49.301931, lng: 19.447832 },
    { lat: 49.30169, lng: 19.447985 },
    { lat: 49.301676, lng: 19.448412 },
    { lat: 49.301419, lng: 19.44918 },
    { lat: 49.300679, lng: 19.449299 },
    { lat: 49.299628, lng: 19.449702 },
    { lat: 49.299835, lng: 19.451876 },
    { lat: 49.299162, lng: 19.452074 },
    { lat: 49.298535, lng: 19.452142 },
    { lat: 49.298558, lng: 19.452579 },
    { lat: 49.297531, lng: 19.45293 },
    { lat: 49.297532, lng: 19.453074 },
    { lat: 49.296899, lng: 19.4534 },
    { lat: 49.296259, lng: 19.453292 },
    { lat: 49.295675, lng: 19.453716 },
    { lat: 49.295067, lng: 19.454541 },
    { lat: 49.294913, lng: 19.45488 },
    { lat: 49.294694, lng: 19.455113 },
    { lat: 49.294239, lng: 19.45545 },
    { lat: 49.293669, lng: 19.455961 },
    { lat: 49.293648, lng: 19.456362 },
    { lat: 49.293215, lng: 19.457191 },
    { lat: 49.293361, lng: 19.457367 },
    { lat: 49.293237, lng: 19.457612 },
    { lat: 49.293855, lng: 19.458504 },
    { lat: 49.293729, lng: 19.458792 },
    { lat: 49.293648, lng: 19.458925 },
    { lat: 49.293743, lng: 19.459145 },
    { lat: 49.293731, lng: 19.459378 },
    { lat: 49.293556, lng: 19.459523 },
    { lat: 49.293351, lng: 19.459772 },
    { lat: 49.293585, lng: 19.460379 },
    { lat: 49.293329, lng: 19.460911 },
    { lat: 49.293244, lng: 19.461517 },
    { lat: 49.293067, lng: 19.461647 },
    { lat: 49.292904, lng: 19.461765 },
    { lat: 49.292598, lng: 19.462272 },
    { lat: 49.292799, lng: 19.463059 },
    { lat: 49.292913, lng: 19.463786 },
    { lat: 49.292493, lng: 19.464247 },
    { lat: 49.293052, lng: 19.465556 },
    { lat: 49.292387, lng: 19.466815 },
    { lat: 49.292227, lng: 19.466988 },
    { lat: 49.292144, lng: 19.467158 },
    { lat: 49.292134, lng: 19.467407 },
    { lat: 49.292151, lng: 19.46771 },
    { lat: 49.291994, lng: 19.468283 },
    { lat: 49.292298, lng: 19.468877 },
    { lat: 49.292258, lng: 19.469284 },
    { lat: 49.292376, lng: 19.470441 },
    { lat: 49.29199, lng: 19.471467 },
    { lat: 49.292051, lng: 19.47235 },
    { lat: 49.292095, lng: 19.472828 },
    { lat: 49.29272, lng: 19.473118 },
    { lat: 49.292628, lng: 19.474017 },
    { lat: 49.292561, lng: 19.474366 },
    { lat: 49.292583, lng: 19.474882 },
    { lat: 49.292696, lng: 19.475271 },
    { lat: 49.293183, lng: 19.476886 },
    { lat: 49.29337, lng: 19.477201 },
    { lat: 49.293543, lng: 19.477647 },
    { lat: 49.293687, lng: 19.478081 },
    { lat: 49.293681, lng: 19.478638 },
    { lat: 49.293866, lng: 19.480017 },
    { lat: 49.294066, lng: 19.481824 },
    { lat: 49.294356, lng: 19.482646 },
    { lat: 49.294429, lng: 19.483082 },
    { lat: 49.29453, lng: 19.483359 },
    { lat: 49.294553, lng: 19.483377 },
    { lat: 49.294463, lng: 19.483867 },
    { lat: 49.294374, lng: 19.484326 },
    { lat: 49.294335, lng: 19.484669 },
    { lat: 49.294432, lng: 19.484856 },
    { lat: 49.294582, lng: 19.48498 },
    { lat: 49.294705, lng: 19.485136 },
    { lat: 49.294706, lng: 19.485366 },
    { lat: 49.294635, lng: 19.485656 },
    { lat: 49.294397, lng: 19.486666 },
    { lat: 49.294384, lng: 19.486759 },
    { lat: 49.294324, lng: 19.486931 },
    { lat: 49.294325, lng: 19.486971 },
    { lat: 49.294326, lng: 19.487159 },
    { lat: 49.294184, lng: 19.487516 },
    { lat: 49.294177, lng: 19.487809 },
    { lat: 49.294255, lng: 19.487993 },
    { lat: 49.294219, lng: 19.488167 },
    { lat: 49.294246, lng: 19.488137 },
    { lat: 49.293874, lng: 19.48934 },
    { lat: 49.293771, lng: 19.489672 },
    { lat: 49.292896, lng: 19.490493 },
    { lat: 49.291463, lng: 19.492058 },
    { lat: 49.291098, lng: 19.492083 },
    { lat: 49.289997, lng: 19.492406 },
    { lat: 49.289516, lng: 19.492277 },
    { lat: 49.288796, lng: 19.491798 },
    { lat: 49.288347, lng: 19.491585 },
    { lat: 49.288417, lng: 19.491906 },
    { lat: 49.288434, lng: 19.492194 },
    { lat: 49.288398, lng: 19.492584 },
    { lat: 49.288323, lng: 19.49277 },
    { lat: 49.288305, lng: 19.49279 },
    { lat: 49.288244, lng: 19.492844 },
    { lat: 49.288234, lng: 19.492913 },
    { lat: 49.28821, lng: 19.493218 },
    { lat: 49.288171, lng: 19.493683 },
    { lat: 49.288111, lng: 19.493746 },
    { lat: 49.287909, lng: 19.49396 },
    { lat: 49.287711, lng: 19.494545 },
    { lat: 49.287663, lng: 19.494696 },
    { lat: 49.287602, lng: 19.495039 },
    { lat: 49.287507, lng: 19.495434 },
    { lat: 49.287332, lng: 19.495916 },
    { lat: 49.287237, lng: 19.495878 },
    { lat: 49.286837, lng: 19.496464 },
    { lat: 49.28677, lng: 19.496701 },
    { lat: 49.284141, lng: 19.494685 },
    { lat: 49.283513, lng: 19.494349 },
    { lat: 49.283269, lng: 19.495396 },
    { lat: 49.283106, lng: 19.496144 },
    { lat: 49.282752, lng: 19.496955 },
    { lat: 49.282557, lng: 19.498458 },
    { lat: 49.281811, lng: 19.498206 },
    { lat: 49.28094, lng: 19.498968 },
    { lat: 49.280601, lng: 19.499273 },
    { lat: 49.280149, lng: 19.499796 },
    { lat: 49.279634, lng: 19.500571 },
    { lat: 49.279304, lng: 19.501238 },
    { lat: 49.279169, lng: 19.501496 },
    { lat: 49.278527, lng: 19.501967 },
    { lat: 49.277875, lng: 19.502062 },
    { lat: 49.277371, lng: 19.502385 },
    { lat: 49.276325, lng: 19.502688 },
    { lat: 49.276388, lng: 19.507124 },
    { lat: 49.276497, lng: 19.507161 },
    { lat: 49.277422, lng: 19.507893 },
    { lat: 49.277469, lng: 19.508506 },
    { lat: 49.277502, lng: 19.508972 },
    { lat: 49.277599, lng: 19.509258 },
    { lat: 49.277755, lng: 19.509874 },
    { lat: 49.277881, lng: 19.510038 },
    { lat: 49.277941, lng: 19.510173 },
    { lat: 49.27793, lng: 19.510379 },
    { lat: 49.278042, lng: 19.510469 },
    { lat: 49.27814, lng: 19.510679 },
    { lat: 49.278141, lng: 19.510806 },
    { lat: 49.278155, lng: 19.510913 },
    { lat: 49.27827, lng: 19.510885 },
    { lat: 49.278281, lng: 19.511223 },
    { lat: 49.278341, lng: 19.511465 },
    { lat: 49.278473, lng: 19.511639 },
    { lat: 49.278574, lng: 19.511859 },
    { lat: 49.278643, lng: 19.512057 },
    { lat: 49.278639, lng: 19.512247 },
    { lat: 49.278677, lng: 19.512439 },
    { lat: 49.278696, lng: 19.512635 },
    { lat: 49.2787, lng: 19.512948 },
    { lat: 49.278648, lng: 19.513303 },
    { lat: 49.27856, lng: 19.513544 },
    { lat: 49.278568, lng: 19.513688 },
    { lat: 49.27854, lng: 19.513836 },
    { lat: 49.278576, lng: 19.514255 },
    { lat: 49.278568, lng: 19.51434 },
    { lat: 49.278593, lng: 19.514489 },
    { lat: 49.278483, lng: 19.514627 },
    { lat: 49.278356, lng: 19.514792 },
    { lat: 49.277958, lng: 19.515173 },
    { lat: 49.277553, lng: 19.515616 },
    { lat: 49.277144, lng: 19.516121 },
    { lat: 49.276899, lng: 19.516349 },
    { lat: 49.276774, lng: 19.516576 },
    { lat: 49.276712, lng: 19.516505 },
    { lat: 49.276642, lng: 19.516662 },
    { lat: 49.276601, lng: 19.516725 },
    { lat: 49.276508, lng: 19.516956 },
    { lat: 49.276411, lng: 19.517057 },
    { lat: 49.27631, lng: 19.517232 },
    { lat: 49.276186, lng: 19.517541 },
    { lat: 49.276143, lng: 19.517588 },
    { lat: 49.27527, lng: 19.518933 },
    { lat: 49.274246, lng: 19.52297 },
    { lat: 49.273862, lng: 19.523953 },
    { lat: 49.273567, lng: 19.525206 },
    { lat: 49.273113, lng: 19.526645 },
    { lat: 49.272411, lng: 19.528988 },
    { lat: 49.272224, lng: 19.529613 },
    { lat: 49.271012, lng: 19.533213 },
    { lat: 49.270372, lng: 19.535367 },
    { lat: 49.271538, lng: 19.536522 },
    { lat: 49.2717153, lng: 19.5360313 },
    { lat: 49.271948, lng: 19.5352724 },
    { lat: 49.2719641, lng: 19.5348041 },
    { lat: 49.2719438, lng: 19.5346793 },
    { lat: 49.271984, lng: 19.5343734 },
    { lat: 49.272071, lng: 19.5340852 },
    { lat: 49.2721802, lng: 19.5339055 },
    { lat: 49.2722238, lng: 19.5337273 },
    { lat: 49.2722635, lng: 19.5336553 },
    { lat: 49.2722674, lng: 19.5335427 },
    { lat: 49.2722941, lng: 19.5334601 },
    { lat: 49.2722948, lng: 19.5333756 },
    { lat: 49.2723959, lng: 19.5330239 },
    { lat: 49.27247, lng: 19.5328205 },
    { lat: 49.2725325, lng: 19.5327568 },
    { lat: 49.2727919, lng: 19.5321661 },
    { lat: 49.2728616, lng: 19.5320642 },
    { lat: 49.2730469, lng: 19.5316516 },
    { lat: 49.2731062, lng: 19.5315677 },
    { lat: 49.2734596, lng: 19.5307144 },
    { lat: 49.2736414, lng: 19.5303491 },
    { lat: 49.2736958, lng: 19.530185 },
    { lat: 49.2738503, lng: 19.5297922 },
    { lat: 49.2749707, lng: 19.5305428 },
    { lat: 49.2750282, lng: 19.5306463 },
    { lat: 49.2751924, lng: 19.5307621 },
    { lat: 49.2754887, lng: 19.530861 },
    { lat: 49.2757155, lng: 19.5308694 },
    { lat: 49.2757912, lng: 19.5309505 },
    { lat: 49.2760527, lng: 19.5310531 },
    { lat: 49.2762464, lng: 19.5310235 },
    { lat: 49.2763409, lng: 19.5310918 },
    { lat: 49.2765036, lng: 19.5311027 },
    { lat: 49.2772877, lng: 19.5314527 },
    { lat: 49.2775123, lng: 19.5314866 },
    { lat: 49.2775898, lng: 19.5314824 },
    { lat: 49.2775967, lng: 19.531482 },
    { lat: 49.2777012, lng: 19.5314763 },
    { lat: 49.2778526, lng: 19.5315335 },
    { lat: 49.2780416, lng: 19.531677 },
    { lat: 49.278472, lng: 19.5317765 },
    { lat: 49.278832, lng: 19.5319426 },
    { lat: 49.2790221, lng: 19.5319322 },
    { lat: 49.2793535, lng: 19.5321078 },
    { lat: 49.2795651, lng: 19.5321305 },
    { lat: 49.2796809, lng: 19.5320998 },
    { lat: 49.2797719, lng: 19.5321905 },
    { lat: 49.2798578, lng: 19.5322002 },
    { lat: 49.2799068, lng: 19.5323069 },
    { lat: 49.2800269, lng: 19.5323472 },
    { lat: 49.2800739, lng: 19.5324681 },
    { lat: 49.2801854, lng: 19.5325884 },
    { lat: 49.2803789, lng: 19.5326329 },
    { lat: 49.2804291, lng: 19.5326997 },
    { lat: 49.2806404, lng: 19.5328343 },
    { lat: 49.2809615, lng: 19.5332568 },
    { lat: 49.2811544, lng: 19.5334602 },
    { lat: 49.2814386, lng: 19.5338642 },
    { lat: 49.2816009, lng: 19.5339131 },
    { lat: 49.2816737, lng: 19.5340818 },
    { lat: 49.2819039, lng: 19.534213 },
    { lat: 49.2820721, lng: 19.5344189 },
    { lat: 49.2822074, lng: 19.5344703 },
    { lat: 49.2823036, lng: 19.534503 },
    { lat: 49.2822997, lng: 19.5344329 },
    { lat: 49.2823994, lng: 19.533836 },
    { lat: 49.282448, lng: 19.5336382 },
    { lat: 49.2825327, lng: 19.5333968 },
    { lat: 49.2826249, lng: 19.5332423 },
    { lat: 49.28306, lng: 19.5327743 },
    { lat: 49.2831544, lng: 19.5325687 },
    { lat: 49.2834057, lng: 19.5318993 },
    { lat: 49.2837045, lng: 19.5315081 },
    { lat: 49.2837178, lng: 19.5314786 },
    { lat: 49.2839598, lng: 19.5309726 },
    { lat: 49.2841021, lng: 19.530633 },
    { lat: 49.2842828, lng: 19.5299487 },
    { lat: 49.2844354, lng: 19.5294601 },
    { lat: 49.2845315, lng: 19.5295427 },
    { lat: 49.2847296, lng: 19.5289449 },
    { lat: 49.2852778, lng: 19.5272951 },
    { lat: 49.2857896, lng: 19.525613 },
    { lat: 49.2863596, lng: 19.5236494 },
    { lat: 49.2866982, lng: 19.5226219 },
    { lat: 49.2867983, lng: 19.5223186 },
    { lat: 49.2868785, lng: 19.5220747 },
    { lat: 49.2870786, lng: 19.5214691 },
    { lat: 49.2872283, lng: 19.5211212 },
    { lat: 49.287371, lng: 19.5207905 },
    { lat: 49.287417, lng: 19.5206846 },
    { lat: 49.2875262, lng: 19.5204337 },
    { lat: 49.287829, lng: 19.5197349 },
    { lat: 49.2882379, lng: 19.5183945 },
    { lat: 49.2882538, lng: 19.5179998 },
    { lat: 49.2884968, lng: 19.5171773 },
    { lat: 49.2884135, lng: 19.5170779 },
    { lat: 49.2891472, lng: 19.5156684 },
    { lat: 49.2897759, lng: 19.5147294 },
    { lat: 49.2900569, lng: 19.5141682 },
    { lat: 49.2901913, lng: 19.5137821 },
    { lat: 49.2902398, lng: 19.5133121 },
    { lat: 49.2902339, lng: 19.5130564 },
    { lat: 49.2903892, lng: 19.5124064 },
    { lat: 49.2906872, lng: 19.5118008 },
    { lat: 49.2908391, lng: 19.5117211 },
    { lat: 49.2912442, lng: 19.5115899 },
    { lat: 49.2910879, lng: 19.5113968 },
    { lat: 49.2918333, lng: 19.5100494 },
    { lat: 49.2926839, lng: 19.5091297 },
    { lat: 49.2933864, lng: 19.5079077 },
    { lat: 49.2941049, lng: 19.5067366 },
    { lat: 49.2944572, lng: 19.5061378 },
    { lat: 49.2949498, lng: 19.5052997 },
    { lat: 49.2954025, lng: 19.5045757 },
    { lat: 49.2959931, lng: 19.5037189 },
    { lat: 49.2961211, lng: 19.5035331 },
    { lat: 49.2963674, lng: 19.5031762 },
    { lat: 49.2966121, lng: 19.5024901 },
    { lat: 49.296615, lng: 19.5024801 },
    { lat: 49.2966285, lng: 19.5024552 },
    { lat: 49.2971113, lng: 19.5015532 },
    { lat: 49.2971374, lng: 19.5015049 },
    { lat: 49.2972744, lng: 19.5012989 },
    { lat: 49.2974622, lng: 19.5010154 },
    { lat: 49.2981114, lng: 19.5000353 },
    { lat: 49.2985434, lng: 19.4997491 },
    { lat: 49.2985927, lng: 19.4997168 },
    { lat: 49.2988867, lng: 19.4995176 },
    { lat: 49.2992353, lng: 19.4992818 },
    { lat: 49.2993135, lng: 19.4992677 },
    { lat: 49.3003904, lng: 19.4985932 },
    { lat: 49.301273, lng: 19.4981095 },
    { lat: 49.3025492, lng: 19.4973343 },
    { lat: 49.3028148, lng: 19.4973543 },
    { lat: 49.3033102, lng: 19.4973191 },
    { lat: 49.3033329, lng: 19.4972275 },
    { lat: 49.303365, lng: 19.4970396 },
    { lat: 49.3038615, lng: 19.4969043 },
    { lat: 49.3041547, lng: 19.4968149 },
    { lat: 49.3041848, lng: 19.4963621 },
    { lat: 49.3041974, lng: 19.4961676 },
    { lat: 49.3041974, lng: 19.4957231 },
    { lat: 49.3042478, lng: 19.4954733 },
    { lat: 49.3043449, lng: 19.495277 },
    { lat: 49.304622, lng: 19.4951041 },
    { lat: 49.3046995, lng: 19.4951207 },
    { lat: 49.3047065, lng: 19.495103 },
    { lat: 49.3048473, lng: 19.4951002 },
    { lat: 49.3051109, lng: 19.4950251 },
    { lat: 49.3052995, lng: 19.494641 },
    { lat: 49.3054919, lng: 19.4941919 },
    { lat: 49.3053128, lng: 19.4940229 },
    { lat: 49.3055139, lng: 19.4936125 },
    { lat: 49.30594, lng: 19.4931464 },
    { lat: 49.3059957, lng: 19.4931354 },
    { lat: 49.3060505, lng: 19.4931241 },
    { lat: 49.3067537, lng: 19.4929818 },
    { lat: 49.3073353, lng: 19.4922181 },
    { lat: 49.3073832, lng: 19.4921549 },
    { lat: 49.3074012, lng: 19.4921309 },
    { lat: 49.3076362, lng: 19.4916779 },
    { lat: 49.3078016, lng: 19.4915255 },
    { lat: 49.3081249, lng: 19.4912281 },
    { lat: 49.3082641, lng: 19.4911421 },
    { lat: 49.3084876, lng: 19.4911771 },
    { lat: 49.3085854, lng: 19.4911926 },
    { lat: 49.3086802, lng: 19.4912739 },
    { lat: 49.3087905, lng: 19.4912224 },
    { lat: 49.3088275, lng: 19.4913914 },
    { lat: 49.308843, lng: 19.491385 },
    { lat: 49.3088541, lng: 19.4913799 },
    { lat: 49.3089314, lng: 19.4913456 },
    { lat: 49.3089426, lng: 19.4913412 },
    { lat: 49.3105242, lng: 19.4906475 },
    { lat: 49.3107098, lng: 19.4906634 },
    { lat: 49.3109969, lng: 19.4906851 },
    { lat: 49.3117324, lng: 19.488855 },
    { lat: 49.3121611, lng: 19.4875835 },
    { lat: 49.3122615, lng: 19.4870152 },
    { lat: 49.3123357, lng: 19.4858094 },
    { lat: 49.3122894, lng: 19.4851881 },
    { lat: 49.3126577, lng: 19.4851368 },
    { lat: 49.3127138, lng: 19.4851287 },
    { lat: 49.31294, lng: 19.4850784 },
    { lat: 49.3132298, lng: 19.4849348 },
    { lat: 49.3136617, lng: 19.4847651 },
    { lat: 49.3140122, lng: 19.4845723 },
    { lat: 49.3147074, lng: 19.4843 },
    { lat: 49.315161, lng: 19.4839455 },
    { lat: 49.3158183, lng: 19.4837084 },
    { lat: 49.3163906, lng: 19.4834597 },
    { lat: 49.3168917, lng: 19.4832804 },
    { lat: 49.3174419, lng: 19.4831431 },
    { lat: 49.3177986, lng: 19.4830583 },
    { lat: 49.3176255, lng: 19.4826236 },
    { lat: 49.317158, lng: 19.4813705 },
    { lat: 49.3177053, lng: 19.481118 },
    { lat: 49.3182359, lng: 19.4808918 },
    { lat: 49.3186673, lng: 19.480129 },
    { lat: 49.3192404, lng: 19.4790827 },
    { lat: 49.3194479, lng: 19.4780036 },
    { lat: 49.3197054, lng: 19.4774494 },
    { lat: 49.320477, lng: 19.4765635 },
    { lat: 49.3203552, lng: 19.4761666 },
    { lat: 49.320532, lng: 19.4750326 },
    { lat: 49.3205802, lng: 19.4741866 },
    { lat: 49.3204633, lng: 19.4737531 },
    { lat: 49.3203265, lng: 19.472613 },
    { lat: 49.3204372, lng: 19.471809 },
    { lat: 49.3204627, lng: 19.4708134 },
    { lat: 49.320355, lng: 19.4695723 },
    { lat: 49.3202969, lng: 19.468846 },
    { lat: 49.320274, lng: 19.4684902 },
    { lat: 49.320472, lng: 19.4675771 },
    { lat: 49.3204988, lng: 19.4670075 },
    { lat: 49.32037, lng: 19.4668971 },
    { lat: 49.3205736, lng: 19.4659101 },
    { lat: 49.3207388, lng: 19.4656569 },
    { lat: 49.3213576, lng: 19.465537 },
    { lat: 49.3216857, lng: 19.465293 },
    { lat: 49.322091, lng: 19.4649926 },
    { lat: 49.3225597, lng: 19.4644276 },
    { lat: 49.3233296, lng: 19.4630372 },
    { lat: 49.3235013, lng: 19.4627038 },
    { lat: 49.3235954, lng: 19.4628162 },
    { lat: 49.3243689, lng: 19.4611794 },
    { lat: 49.325191, lng: 19.4595739 },
    { lat: 49.3257254, lng: 19.4586773 },
    { lat: 49.3261217, lng: 19.4582788 },
    { lat: 49.3269494, lng: 19.457433 },
    { lat: 49.3279495, lng: 19.4564393 },
    { lat: 49.3288113, lng: 19.4559015 },
    { lat: 49.3288639, lng: 19.455868 },
    { lat: 49.3290776, lng: 19.4558082 },
    { lat: 49.3297043, lng: 19.4559376 },
    { lat: 49.3304366, lng: 19.4561551 },
    { lat: 49.3309128, lng: 19.4560973 },
    { lat: 49.3312996, lng: 19.4558574 },
    { lat: 49.3324925, lng: 19.4550422 },
    { lat: 49.3333381, lng: 19.4545617 },
    { lat: 49.333362, lng: 19.4545212 },
    { lat: 49.3336268, lng: 19.4540761 },
    { lat: 49.3338146, lng: 19.4537766 },
    { lat: 49.3341216, lng: 19.4532732 },
    { lat: 49.3344465, lng: 19.4527909 },
    { lat: 49.3346678, lng: 19.4525824 },
    { lat: 49.3349006, lng: 19.452299 },
    { lat: 49.3350459, lng: 19.4520554 },
    { lat: 49.3352394, lng: 19.4515342 },
    { lat: 49.3354134, lng: 19.4504412 },
    { lat: 49.3351661, lng: 19.4489855 },
    { lat: 49.3352102, lng: 19.4489182 },
    { lat: 49.3349919, lng: 19.4480641 },
    { lat: 49.3349445, lng: 19.4478705 },
    { lat: 49.3342267, lng: 19.4467422 },
    { lat: 49.3334307, lng: 19.4451866 },
    { lat: 49.3334647, lng: 19.4450468 },
    { lat: 49.3335406, lng: 19.4446386 },
    { lat: 49.3336909, lng: 19.4438434 },
    { lat: 49.3338293, lng: 19.4430792 },
    { lat: 49.3339769, lng: 19.4423215 },
    { lat: 49.3341451, lng: 19.441325 },
    { lat: 49.3343838, lng: 19.4402167 },
    { lat: 49.3344874, lng: 19.4397048 },
    { lat: 49.334568, lng: 19.439378 },
    { lat: 49.3328356, lng: 19.4358413 },
    { lat: 49.3323048, lng: 19.4340509 },
    { lat: 49.3312842, lng: 19.4321228 },
    { lat: 49.330276, lng: 19.430513 },
    { lat: 49.3297042, lng: 19.4296602 },
    { lat: 49.3286123, lng: 19.4284885 },
    { lat: 49.327406, lng: 19.427249 },
    { lat: 49.327204, lng: 19.42686 },
    { lat: 49.326783, lng: 19.426051 },
    { lat: 49.326356, lng: 19.425429 },
  ],
  SucháHora: [
    { lat: 49.3475348, lng: 19.7953266 },
    { lat: 49.348102, lng: 19.795537 },
    { lat: 49.348638, lng: 19.795833 },
    { lat: 49.349315, lng: 19.79641 },
    { lat: 49.349864, lng: 19.79683 },
    { lat: 49.350567, lng: 19.797743 },
    { lat: 49.351103, lng: 19.798271 },
    { lat: 49.35193, lng: 19.798792 },
    { lat: 49.35227, lng: 19.799318 },
    { lat: 49.352456, lng: 19.800049 },
    { lat: 49.352646, lng: 19.800703 },
    { lat: 49.352899, lng: 19.801645 },
    { lat: 49.353136, lng: 19.802451 },
    { lat: 49.353649, lng: 19.802424 },
    { lat: 49.354207, lng: 19.802465 },
    { lat: 49.355257, lng: 19.80222 },
    { lat: 49.3561365, lng: 19.8019381 },
    { lat: 49.3566451, lng: 19.8019641 },
    { lat: 49.357317, lng: 19.80189 },
    { lat: 49.35795, lng: 19.8018229 },
    { lat: 49.3590065, lng: 19.8014403 },
    { lat: 49.359716, lng: 19.80153 },
    { lat: 49.3597211, lng: 19.8015328 },
    { lat: 49.3604112, lng: 19.801635 },
    { lat: 49.3611119, lng: 19.8021341 },
    { lat: 49.361129, lng: 19.802145 },
    { lat: 49.3622694, lng: 19.802927 },
    { lat: 49.3630175, lng: 19.8032823 },
    { lat: 49.3637807, lng: 19.803571 },
    { lat: 49.3648155, lng: 19.8038175 },
    { lat: 49.3649109, lng: 19.8037871 },
    { lat: 49.365776, lng: 19.803721 },
    { lat: 49.3657806, lng: 19.8037234 },
    { lat: 49.3660767, lng: 19.8036783 },
    { lat: 49.3665846, lng: 19.8035274 },
    { lat: 49.3673546, lng: 19.8031908 },
    { lat: 49.368088, lng: 19.802858 },
    { lat: 49.3680935, lng: 19.8028599 },
    { lat: 49.369172, lng: 19.802493 },
    { lat: 49.3701107, lng: 19.8021791 },
    { lat: 49.370572, lng: 19.801839 },
    { lat: 49.3705797, lng: 19.8018378 },
    { lat: 49.371521, lng: 19.800673 },
    { lat: 49.3720717, lng: 19.7999974 },
    { lat: 49.372083, lng: 19.799972 },
    { lat: 49.372692, lng: 19.799037 },
    { lat: 49.3733279, lng: 19.7980682 },
    { lat: 49.373326, lng: 19.798063 },
    { lat: 49.373969, lng: 19.797418 },
    { lat: 49.3744678, lng: 19.7969258 },
    { lat: 49.37447, lng: 19.796918 },
    { lat: 49.375176, lng: 19.7962758 },
    { lat: 49.3755043, lng: 19.7958806 },
    { lat: 49.375937, lng: 19.795526 },
    { lat: 49.3759414, lng: 19.7955283 },
    { lat: 49.3764359, lng: 19.795057 },
    { lat: 49.3767084, lng: 19.7949222 },
    { lat: 49.3776143, lng: 19.7936611 },
    { lat: 49.3782186, lng: 19.7930246 },
    { lat: 49.3782565, lng: 19.7928464 },
    { lat: 49.378598, lng: 19.792557 },
    { lat: 49.3786026, lng: 19.7925599 },
    { lat: 49.3798701, lng: 19.7923897 },
    { lat: 49.380855, lng: 19.792235 },
    { lat: 49.3808613, lng: 19.7922371 },
    { lat: 49.3818402, lng: 19.7921036 },
    { lat: 49.3824166, lng: 19.7919675 },
    { lat: 49.3827635, lng: 19.7915963 },
    { lat: 49.3835114, lng: 19.7911317 },
    { lat: 49.384223, lng: 19.791065 },
    { lat: 49.384879, lng: 19.791005 },
    { lat: 49.385216, lng: 19.7909737 },
    { lat: 49.385364, lng: 19.79096 },
    { lat: 49.386928, lng: 19.79088 },
    { lat: 49.388351, lng: 19.79084 },
    { lat: 49.389651, lng: 19.790802 },
    { lat: 49.390891, lng: 19.790765 },
    { lat: 49.3921675, lng: 19.7907277 },
    { lat: 49.393639, lng: 19.79077 },
    { lat: 49.394687, lng: 19.790768 },
    { lat: 49.3949026, lng: 19.7908099 },
    { lat: 49.395096, lng: 19.790844 },
    { lat: 49.396691, lng: 19.7911425 },
    { lat: 49.397631, lng: 19.790796 },
    { lat: 49.398752, lng: 19.790387 },
    { lat: 49.3987597, lng: 19.790388 },
    { lat: 49.399784, lng: 19.789749 },
    { lat: 49.401091, lng: 19.788938 },
    { lat: 49.4010951, lng: 19.788941 },
    { lat: 49.402304, lng: 19.789147 },
    { lat: 49.4034961, lng: 19.7893542 },
    { lat: 49.403517, lng: 19.789355 },
    { lat: 49.404705, lng: 19.789532 },
    { lat: 49.4050047, lng: 19.7895796 },
    { lat: 49.405671, lng: 19.789678 },
    { lat: 49.406946, lng: 19.789868 },
    { lat: 49.408154, lng: 19.790107 },
    { lat: 49.409543, lng: 19.790367 },
    { lat: 49.4106809, lng: 19.7905928 },
    { lat: 49.4106881, lng: 19.7905922 },
    { lat: 49.410857, lng: 19.789004 },
    { lat: 49.411021, lng: 19.787483 },
    { lat: 49.41122, lng: 19.78581 },
    { lat: 49.4112976, lng: 19.7851306 },
    { lat: 49.411423, lng: 19.784088 },
    { lat: 49.411584, lng: 19.782808 },
    { lat: 49.4117354, lng: 19.7816202 },
    { lat: 49.411745, lng: 19.781514 },
    { lat: 49.411847, lng: 19.780418 },
    { lat: 49.412015, lng: 19.778919 },
    { lat: 49.412132, lng: 19.777708 },
    { lat: 49.412266, lng: 19.776338 },
    { lat: 49.412509, lng: 19.773383 },
    { lat: 49.412624, lng: 19.771923 },
    { lat: 49.412739, lng: 19.77047 },
    { lat: 49.412867, lng: 19.769009 },
    { lat: 49.412993, lng: 19.76758 },
    { lat: 49.4106049, lng: 19.7679004 },
    { lat: 49.4087577, lng: 19.7681099 },
    { lat: 49.4086247, lng: 19.7681132 },
    { lat: 49.4072915, lng: 19.7681504 },
    { lat: 49.4059931, lng: 19.7687781 },
    { lat: 49.4039766, lng: 19.7683305 },
    { lat: 49.4021008, lng: 19.7678542 },
    { lat: 49.4003649, lng: 19.7674372 },
    { lat: 49.3995367, lng: 19.7672749 },
    { lat: 49.3986959, lng: 19.7671819 },
    { lat: 49.396978, lng: 19.7668416 },
    { lat: 49.3960005, lng: 19.7667429 },
    { lat: 49.3939941, lng: 19.7662378 },
    { lat: 49.3935865, lng: 19.7661674 },
    { lat: 49.3925155, lng: 19.7657004 },
    { lat: 49.3921618, lng: 19.765677 },
    { lat: 49.3914829, lng: 19.7653437 },
    { lat: 49.3906736, lng: 19.7650158 },
    { lat: 49.390638, lng: 19.7650014 },
    { lat: 49.3902425, lng: 19.7648173 },
    { lat: 49.3883295, lng: 19.7653289 },
    { lat: 49.3880104, lng: 19.7655034 },
    { lat: 49.3877147, lng: 19.7655974 },
    { lat: 49.3871489, lng: 19.7659029 },
    { lat: 49.3864232, lng: 19.7662611 },
    { lat: 49.386353, lng: 19.7663025 },
    { lat: 49.3862866, lng: 19.7663049 },
    { lat: 49.3861115, lng: 19.7663451 },
    { lat: 49.3848495, lng: 19.7675596 },
    { lat: 49.384243, lng: 19.768098 },
    { lat: 49.3832581, lng: 19.7686834 },
    { lat: 49.3822221, lng: 19.7692892 },
    { lat: 49.3804477, lng: 19.7700407 },
    { lat: 49.3798982, lng: 19.7703588 },
    { lat: 49.3787915, lng: 19.7705285 },
    { lat: 49.3778192, lng: 19.7705494 },
    { lat: 49.3768521, lng: 19.7705596 },
    { lat: 49.3767556, lng: 19.7705151 },
    { lat: 49.3751799, lng: 19.7698398 },
    { lat: 49.3751233, lng: 19.7698083 },
    { lat: 49.3747893, lng: 19.7696651 },
    { lat: 49.3747602, lng: 19.7696435 },
    { lat: 49.3747226, lng: 19.7696093 },
    { lat: 49.3724958, lng: 19.7686683 },
    { lat: 49.370945, lng: 19.7681807 },
    { lat: 49.3699972, lng: 19.7678829 },
    { lat: 49.3699263, lng: 19.76786 },
    { lat: 49.3698237, lng: 19.7678278 },
    { lat: 49.3696239, lng: 19.7677653 },
    { lat: 49.3695632, lng: 19.7677436 },
    { lat: 49.3692833, lng: 19.7676296 },
    { lat: 49.369224, lng: 19.7676107 },
    { lat: 49.3691303, lng: 19.7675801 },
    { lat: 49.3689419, lng: 19.7675186 },
    { lat: 49.3688493, lng: 19.7674896 },
    { lat: 49.368661, lng: 19.7674296 },
    { lat: 49.3685606, lng: 19.7674007 },
    { lat: 49.3684769, lng: 19.7673716 },
    { lat: 49.3683466, lng: 19.7673274 },
    { lat: 49.3682403, lng: 19.7672915 },
    { lat: 49.3681511, lng: 19.7672605 },
    { lat: 49.3680073, lng: 19.7672158 },
    { lat: 49.3677493, lng: 19.7670562 },
    { lat: 49.3672943, lng: 19.7667749 },
    { lat: 49.3672262, lng: 19.766733 },
    { lat: 49.3668684, lng: 19.76638 },
    { lat: 49.3659438, lng: 19.7656028 },
    { lat: 49.3658113, lng: 19.7655078 },
    { lat: 49.365728, lng: 19.7654321 },
    { lat: 49.3656323, lng: 19.7653261 },
    { lat: 49.3655884, lng: 19.7653053 },
    { lat: 49.3647589, lng: 19.7646167 },
    { lat: 49.3637363, lng: 19.7634747 },
    { lat: 49.363736, lng: 19.7634119 },
    { lat: 49.363539, lng: 19.7633936 },
    { lat: 49.3632271, lng: 19.7632655 },
    { lat: 49.3630719, lng: 19.7632946 },
    { lat: 49.3629785, lng: 19.7634559 },
    { lat: 49.3627511, lng: 19.7638989 },
    { lat: 49.3625975, lng: 19.7639398 },
    { lat: 49.3623378, lng: 19.764085 },
    { lat: 49.3622196, lng: 19.7640869 },
    { lat: 49.3618027, lng: 19.7641339 },
    { lat: 49.3614433, lng: 19.7641859 },
    { lat: 49.3612196, lng: 19.7642466 },
    { lat: 49.3611339, lng: 19.764269 },
    { lat: 49.3608028, lng: 19.7643507 },
    { lat: 49.3606615, lng: 19.7644188 },
    { lat: 49.3604669, lng: 19.7643739 },
    { lat: 49.3601887, lng: 19.7643197 },
    { lat: 49.3599583, lng: 19.7644353 },
    { lat: 49.3598195, lng: 19.7645599 },
    { lat: 49.3595079, lng: 19.7646743 },
    { lat: 49.3593747, lng: 19.7648508 },
    { lat: 49.3591808, lng: 19.7651321 },
    { lat: 49.359085, lng: 19.7653399 },
    { lat: 49.3590234, lng: 19.7654227 },
    { lat: 49.3589428, lng: 19.765492 },
    { lat: 49.3588895, lng: 19.7655295 },
    { lat: 49.3587892, lng: 19.7656073 },
    { lat: 49.3586898, lng: 19.7656546 },
    { lat: 49.3586183, lng: 19.765668 },
    { lat: 49.358567, lng: 19.7656815 },
    { lat: 49.358423, lng: 19.7657362 },
    { lat: 49.3582691, lng: 19.7657735 },
    { lat: 49.358218, lng: 19.7657877 },
    { lat: 49.3581145, lng: 19.7658181 },
    { lat: 49.3579753, lng: 19.7658788 },
    { lat: 49.3579247, lng: 19.7659089 },
    { lat: 49.3578665, lng: 19.7659437 },
    { lat: 49.3577312, lng: 19.7660474 },
    { lat: 49.3577051, lng: 19.7661422 },
    { lat: 49.3577147, lng: 19.7663143 },
    { lat: 49.3577163, lng: 19.7664826 },
    { lat: 49.3576867, lng: 19.7666558 },
    { lat: 49.3576677, lng: 19.7666862 },
    { lat: 49.3576301, lng: 19.7667009 },
    { lat: 49.3575219, lng: 19.766649 },
    { lat: 49.3574117, lng: 19.7664975 },
    { lat: 49.3573482, lng: 19.7663829 },
    { lat: 49.3573163, lng: 19.7663222 },
    { lat: 49.35725, lng: 19.766197 },
    { lat: 49.3571479, lng: 19.7660733 },
    { lat: 49.3570214, lng: 19.7659644 },
    { lat: 49.3568831, lng: 19.7659976 },
    { lat: 49.3568107, lng: 19.766169 },
    { lat: 49.3567732, lng: 19.7662942 },
    { lat: 49.3567107, lng: 19.7664866 },
    { lat: 49.3566894, lng: 19.7666709 },
    { lat: 49.3565683, lng: 19.766819 },
    { lat: 49.356347, lng: 19.7671161 },
    { lat: 49.3562269, lng: 19.7673079 },
    { lat: 49.35611, lng: 19.7673378 },
    { lat: 49.3559438, lng: 19.7672242 },
    { lat: 49.35576, lng: 19.7668544 },
    { lat: 49.3555996, lng: 19.7668183 },
    { lat: 49.3553071, lng: 19.7668776 },
    { lat: 49.355088, lng: 19.7672514 },
    { lat: 49.3550017, lng: 19.7675496 },
    { lat: 49.3549085, lng: 19.7680241 },
    { lat: 49.3547634, lng: 19.7681905 },
    { lat: 49.3545866, lng: 19.7679815 },
    { lat: 49.3545386, lng: 19.7678336 },
    { lat: 49.3544194, lng: 19.7678391 },
    { lat: 49.3543827, lng: 19.767901 },
    { lat: 49.3542966, lng: 19.7680442 },
    { lat: 49.3541933, lng: 19.768313 },
    { lat: 49.354053, lng: 19.7684526 },
    { lat: 49.3538705, lng: 19.7685062 },
    { lat: 49.3535999, lng: 19.7685257 },
    { lat: 49.353543, lng: 19.7687507 },
    { lat: 49.3534243, lng: 19.7687671 },
    { lat: 49.3532473, lng: 19.7686318 },
    { lat: 49.3530729, lng: 19.7690528 },
    { lat: 49.3529018, lng: 19.7693913 },
    { lat: 49.3527154, lng: 19.7699009 },
    { lat: 49.3525199, lng: 19.7701223 },
    { lat: 49.3523528, lng: 19.7703474 },
    { lat: 49.3521509, lng: 19.7704705 },
    { lat: 49.351908, lng: 19.77064 },
    { lat: 49.3516441, lng: 19.7707599 },
    { lat: 49.3515392, lng: 19.7709162 },
    { lat: 49.3513801, lng: 19.7710905 },
    { lat: 49.3512287, lng: 19.7711312 },
    { lat: 49.351064, lng: 19.7710449 },
    { lat: 49.3509484, lng: 19.7709543 },
    { lat: 49.3508763, lng: 19.7708207 },
    { lat: 49.3508397, lng: 19.7707822 },
    { lat: 49.3508171, lng: 19.770783 },
    { lat: 49.3507452, lng: 19.7708113 },
    { lat: 49.3506246, lng: 19.7708896 },
    { lat: 49.3504935, lng: 19.7710333 },
    { lat: 49.3503158, lng: 19.771275 },
    { lat: 49.3502955, lng: 19.7713023 },
    { lat: 49.3502686, lng: 19.7713798 },
    { lat: 49.3501921, lng: 19.7715422 },
    { lat: 49.3499484, lng: 19.7716334 },
    { lat: 49.3498141, lng: 19.7719176 },
    { lat: 49.3497844, lng: 19.7724044 },
    { lat: 49.3496232, lng: 19.7726621 },
    { lat: 49.3494237, lng: 19.7728897 },
    { lat: 49.3492797, lng: 19.7729986 },
    { lat: 49.3491986, lng: 19.7730487 },
    { lat: 49.3488844, lng: 19.7732066 },
    { lat: 49.3485546, lng: 19.7733491 },
    { lat: 49.3483324, lng: 19.7734175 },
    { lat: 49.3482306, lng: 19.7734321 },
    { lat: 49.3481009, lng: 19.7734294 },
    { lat: 49.3480287, lng: 19.773424 },
    { lat: 49.3478851, lng: 19.7734118 },
    { lat: 49.3477891, lng: 19.7733797 },
    { lat: 49.3476509, lng: 19.7732564 },
    { lat: 49.3475599, lng: 19.7731077 },
    { lat: 49.3474832, lng: 19.7729485 },
    { lat: 49.347329, lng: 19.772772 },
    { lat: 49.3471136, lng: 19.7725561 },
    { lat: 49.3469234, lng: 19.7724814 },
    { lat: 49.346683, lng: 19.7723912 },
    { lat: 49.3464805, lng: 19.7723467 },
    { lat: 49.3464452, lng: 19.7723387 },
    { lat: 49.3464422, lng: 19.7721107 },
    { lat: 49.3463164, lng: 19.7719637 },
    { lat: 49.3460683, lng: 19.7720893 },
    { lat: 49.3459023, lng: 19.7722347 },
    { lat: 49.3457977, lng: 19.7723913 },
    { lat: 49.3457762, lng: 19.7723913 },
    { lat: 49.3456129, lng: 19.7723652 },
    { lat: 49.345483, lng: 19.7722534 },
    { lat: 49.3453615, lng: 19.7722826 },
    { lat: 49.3453303, lng: 19.7724737 },
    { lat: 49.3453293, lng: 19.7728152 },
    { lat: 49.3452355, lng: 19.7729887 },
    { lat: 49.345054, lng: 19.7731774 },
    { lat: 49.3448838, lng: 19.7731688 },
    { lat: 49.3446356, lng: 19.7731556 },
    { lat: 49.3444965, lng: 19.7732731 },
    { lat: 49.344218, lng: 19.7732135 },
    { lat: 49.3440272, lng: 19.7729167 },
    { lat: 49.3440285, lng: 19.7727663 },
    { lat: 49.3439262, lng: 19.7724851 },
    { lat: 49.3438093, lng: 19.7723102 },
    { lat: 49.3437486, lng: 19.7721834 },
    { lat: 49.3436966, lng: 19.7721005 },
    { lat: 49.3436452, lng: 19.7720566 },
    { lat: 49.3435375, lng: 19.7719634 },
    { lat: 49.3435027, lng: 19.7719377 },
    { lat: 49.3433269, lng: 19.7718305 },
    { lat: 49.343177, lng: 19.7717756 },
    { lat: 49.3430184, lng: 19.7717331 },
    { lat: 49.3428747, lng: 19.7716906 },
    { lat: 49.3428078, lng: 19.7716819 },
    { lat: 49.342748, lng: 19.7716518 },
    { lat: 49.3426654, lng: 19.7716432 },
    { lat: 49.3426417, lng: 19.7716405 },
    { lat: 49.3425605, lng: 19.7716408 },
    { lat: 49.3424854, lng: 19.7715942 },
    { lat: 49.3423816, lng: 19.7715907 },
    { lat: 49.3422683, lng: 19.7715757 },
    { lat: 49.3421651, lng: 19.771562 },
    { lat: 49.342066, lng: 19.7715356 },
    { lat: 49.3419914, lng: 19.7715023 },
    { lat: 49.3419334, lng: 19.771489 },
    { lat: 49.3418673, lng: 19.7714715 },
    { lat: 49.3418345, lng: 19.7714712 },
    { lat: 49.3418016, lng: 19.7714652 },
    { lat: 49.3417185, lng: 19.7714201 },
    { lat: 49.3416818, lng: 19.7714011 },
    { lat: 49.3415756, lng: 19.7713407 },
    { lat: 49.3415071, lng: 19.7712978 },
    { lat: 49.3413908, lng: 19.7712384 },
    { lat: 49.3412641, lng: 19.7711194 },
    { lat: 49.3411958, lng: 19.7710526 },
    { lat: 49.3411227, lng: 19.771004 },
    { lat: 49.3409783, lng: 19.7709731 },
    { lat: 49.3408516, lng: 19.7709574 },
    { lat: 49.3407232, lng: 19.7709574 },
    { lat: 49.340692, lng: 19.7709396 },
    { lat: 49.3406549, lng: 19.7709184 },
    { lat: 49.3406148, lng: 19.770929 },
    { lat: 49.3405386, lng: 19.7709349 },
    { lat: 49.3404673, lng: 19.7709302 },
    { lat: 49.3403184, lng: 19.7710009 },
    { lat: 49.3403042, lng: 19.7709576 },
    { lat: 49.3392956, lng: 19.7709734 },
    { lat: 49.3381495, lng: 19.7716136 },
    { lat: 49.3371054, lng: 19.7728135 },
    { lat: 49.3367727, lng: 19.7731705 },
    { lat: 49.3367401, lng: 19.7731983 },
    { lat: 49.3363164, lng: 19.773558 },
    { lat: 49.3362798, lng: 19.7735956 },
    { lat: 49.3353275, lng: 19.7744184 },
    { lat: 49.3341598, lng: 19.7747186 },
    { lat: 49.3341001, lng: 19.7747388 },
    { lat: 49.332309, lng: 19.7755207 },
    { lat: 49.3313118, lng: 19.7757733 },
    { lat: 49.3306906, lng: 19.7757357 },
    { lat: 49.3305901, lng: 19.7757325 },
    { lat: 49.3304508, lng: 19.7757134 },
    { lat: 49.3299819, lng: 19.77565 },
    { lat: 49.3293038, lng: 19.7753672 },
    { lat: 49.3284603, lng: 19.7753164 },
    { lat: 49.3275904, lng: 19.7753343 },
    { lat: 49.3270024, lng: 19.7753342 },
    { lat: 49.3260409, lng: 19.7753972 },
    { lat: 49.3250668, lng: 19.7757706 },
    { lat: 49.3242383, lng: 19.77594 },
    { lat: 49.3234435, lng: 19.7763692 },
    { lat: 49.3230861, lng: 19.776674 },
    { lat: 49.3209071, lng: 19.7784305 },
    { lat: 49.3191172, lng: 19.7801302 },
    { lat: 49.3177766, lng: 19.7809095 },
    { lat: 49.3159358, lng: 19.781293 },
    { lat: 49.3153112, lng: 19.7808329 },
    { lat: 49.3147106, lng: 19.7803105 },
    { lat: 49.3118104, lng: 19.7820112 },
    { lat: 49.3102351, lng: 19.7825216 },
    { lat: 49.3092632, lng: 19.7838587 },
    { lat: 49.3089133, lng: 19.7845164 },
    { lat: 49.3087382, lng: 19.7875333 },
    { lat: 49.3082973, lng: 19.7886514 },
    { lat: 49.3081513, lng: 19.791713 },
    { lat: 49.3082189, lng: 19.7923404 },
    { lat: 49.3081369, lng: 19.7934172 },
    { lat: 49.3077993, lng: 19.7939758 },
    { lat: 49.3077484, lng: 19.7940766 },
    { lat: 49.3075969, lng: 19.794377 },
    { lat: 49.307218, lng: 19.795579 },
    { lat: 49.307621, lng: 19.795917 },
    { lat: 49.308019, lng: 19.796209 },
    { lat: 49.308862, lng: 19.796758 },
    { lat: 49.309765, lng: 19.797212 },
    { lat: 49.310725, lng: 19.797776 },
    { lat: 49.311679, lng: 19.79839 },
    { lat: 49.313003, lng: 19.799241 },
    { lat: 49.313958, lng: 19.799736 },
    { lat: 49.314781, lng: 19.800103 },
    { lat: 49.315349, lng: 19.800557 },
    { lat: 49.315841, lng: 19.801102 },
    { lat: 49.316633, lng: 19.801573 },
    { lat: 49.317138, lng: 19.801848 },
    { lat: 49.317616, lng: 19.802056 },
    { lat: 49.318238, lng: 19.802773 },
    { lat: 49.319001, lng: 19.803165 },
    { lat: 49.319304, lng: 19.803798 },
    { lat: 49.32006, lng: 19.804511 },
    { lat: 49.320897, lng: 19.804668 },
    { lat: 49.321816, lng: 19.804838 },
    { lat: 49.322768, lng: 19.804964 },
    { lat: 49.323862, lng: 19.804653 },
    { lat: 49.3247516, lng: 19.8043076 },
    { lat: 49.32482, lng: 19.804281 },
    { lat: 49.325812, lng: 19.803808 },
    { lat: 49.326325, lng: 19.80348 },
    { lat: 49.3271176, lng: 19.8029601 },
    { lat: 49.327228, lng: 19.802907 },
    { lat: 49.32789, lng: 19.802485 },
    { lat: 49.328807, lng: 19.802412 },
    { lat: 49.329538, lng: 19.802351 },
    { lat: 49.33031, lng: 19.802289 },
    { lat: 49.33084, lng: 19.801762 },
    { lat: 49.331705, lng: 19.800898 },
    { lat: 49.332169, lng: 19.800438 },
    { lat: 49.332904, lng: 19.799694 },
    { lat: 49.33364, lng: 19.799108 },
    { lat: 49.334552, lng: 19.798937 },
    { lat: 49.335821, lng: 19.798671 },
    { lat: 49.336925, lng: 19.798435 },
    { lat: 49.337897, lng: 19.798047 },
    { lat: 49.339089, lng: 19.798115 },
    { lat: 49.339806, lng: 19.798005 },
    { lat: 49.340783, lng: 19.797983 },
    { lat: 49.341664, lng: 19.797604 },
    { lat: 49.342317, lng: 19.797323 },
    { lat: 49.34301, lng: 19.797025 },
    { lat: 49.343774, lng: 19.795866 },
    { lat: 49.344528, lng: 19.79472 },
    { lat: 49.345406, lng: 19.794793 },
    { lat: 49.346001, lng: 19.794841 },
    { lat: 49.346589, lng: 19.795181 },
    { lat: 49.3475348, lng: 19.7953266 },
  ],
  Zuberec: [
    { lat: 49.241843, lng: 19.572182 },
    { lat: 49.24163, lng: 19.573165 },
    { lat: 49.241038, lng: 19.574506 },
    { lat: 49.240244, lng: 19.575417 },
    { lat: 49.239407, lng: 19.576558 },
    { lat: 49.238757, lng: 19.577286 },
    { lat: 49.237803, lng: 19.578582 },
    { lat: 49.237432, lng: 19.579226 },
    { lat: 49.236385, lng: 19.581181 },
    { lat: 49.236373, lng: 19.581936 },
    { lat: 49.23627, lng: 19.582423 },
    { lat: 49.235973, lng: 19.583399 },
    { lat: 49.235706, lng: 19.584304 },
    { lat: 49.235148, lng: 19.584737 },
    { lat: 49.234688, lng: 19.584927 },
    { lat: 49.232909, lng: 19.585311 },
    { lat: 49.231569, lng: 19.586153 },
    { lat: 49.230229, lng: 19.586694 },
    { lat: 49.229692, lng: 19.585804 },
    { lat: 49.228894, lng: 19.585387 },
    { lat: 49.228359, lng: 19.585249 },
    { lat: 49.228138, lng: 19.584758 },
    { lat: 49.227932, lng: 19.58437 },
    { lat: 49.227577, lng: 19.584321 },
    { lat: 49.227071, lng: 19.584228 },
    { lat: 49.226444, lng: 19.584681 },
    { lat: 49.225724, lng: 19.58529 },
    { lat: 49.225423, lng: 19.586478 },
    { lat: 49.225199, lng: 19.587262 },
    { lat: 49.225081, lng: 19.587785 },
    { lat: 49.224899, lng: 19.588054 },
    { lat: 49.224691, lng: 19.588183 },
    { lat: 49.224339, lng: 19.588254 },
    { lat: 49.224114, lng: 19.588075 },
    { lat: 49.223898, lng: 19.58813 },
    { lat: 49.22366, lng: 19.588536 },
    { lat: 49.223707, lng: 19.588568 },
    { lat: 49.223545, lng: 19.589342 },
    { lat: 49.22353, lng: 19.590288 },
    { lat: 49.223622, lng: 19.590668 },
    { lat: 49.2237, lng: 19.591003 },
    { lat: 49.223721, lng: 19.591093 },
    { lat: 49.223805, lng: 19.59159 },
    { lat: 49.223698, lng: 19.592637 },
    { lat: 49.223577, lng: 19.593136 },
    { lat: 49.223451, lng: 19.593837 },
    { lat: 49.223342, lng: 19.595267 },
    { lat: 49.223329, lng: 19.595443 },
    { lat: 49.223142, lng: 19.595651 },
    { lat: 49.223106, lng: 19.596112 },
    { lat: 49.222923, lng: 19.596567 },
    { lat: 49.222866, lng: 19.596579 },
    { lat: 49.22267, lng: 19.596678 },
    { lat: 49.222553, lng: 19.596754 },
    { lat: 49.222469, lng: 19.596821 },
    { lat: 49.222429, lng: 19.596857 },
    { lat: 49.221884, lng: 19.597731 },
    { lat: 49.220929, lng: 19.59927 },
    { lat: 49.21959, lng: 19.601983 },
    { lat: 49.216916, lng: 19.603147 },
    { lat: 49.216575, lng: 19.6042 },
    { lat: 49.216388, lng: 19.604843 },
    { lat: 49.216434, lng: 19.605219 },
    { lat: 49.216139, lng: 19.606531 },
    { lat: 49.216304, lng: 19.607043 },
    { lat: 49.2167046, lng: 19.6094045 },
    { lat: 49.2166356, lng: 19.6128229 },
    { lat: 49.2163723, lng: 19.6140418 },
    { lat: 49.2154156, lng: 19.6159502 },
    { lat: 49.2147435, lng: 19.6169226 },
    { lat: 49.2142011, lng: 19.6178696 },
    { lat: 49.2141425, lng: 19.6179719 },
    { lat: 49.2141218, lng: 19.6184652 },
    { lat: 49.2131895, lng: 19.62034 },
    { lat: 49.2127028, lng: 19.6237485 },
    { lat: 49.2126305, lng: 19.6246779 },
    { lat: 49.2127979, lng: 19.6258064 },
    { lat: 49.2127995, lng: 19.6259695 },
    { lat: 49.2126999, lng: 19.6272184 },
    { lat: 49.2131445, lng: 19.628502 },
    { lat: 49.2129493, lng: 19.6300859 },
    { lat: 49.2124576, lng: 19.631867 },
    { lat: 49.212329, lng: 19.6343179 },
    { lat: 49.2124337, lng: 19.6350245 },
    { lat: 49.2121224, lng: 19.6355508 },
    { lat: 49.2117867, lng: 19.6393975 },
    { lat: 49.2115109, lng: 19.6408707 },
    { lat: 49.2112881, lng: 19.6413079 },
    { lat: 49.211119, lng: 19.6421931 },
    { lat: 49.2110459, lng: 19.6421679 },
    { lat: 49.2112229, lng: 19.6429048 },
    { lat: 49.2122198, lng: 19.646021 },
    { lat: 49.2135099, lng: 19.6478748 },
    { lat: 49.2148986, lng: 19.6476904 },
    { lat: 49.2170407, lng: 19.6493012 },
    { lat: 49.2187192, lng: 19.6515544 },
    { lat: 49.218873, lng: 19.6518452 },
    { lat: 49.2196878, lng: 19.6527146 },
    { lat: 49.2207068, lng: 19.6544826 },
    { lat: 49.222045, lng: 19.658584 },
    { lat: 49.22516, lng: 19.662748 },
    { lat: 49.226309, lng: 19.666499 },
    { lat: 49.226125, lng: 19.668975 },
    { lat: 49.226418, lng: 19.671486 },
    { lat: 49.226417, lng: 19.672916 },
    { lat: 49.226064, lng: 19.675352 },
    { lat: 49.226146, lng: 19.676282 },
    { lat: 49.224949, lng: 19.679577 },
    { lat: 49.223844, lng: 19.680536 },
    { lat: 49.222706, lng: 19.682307 },
    { lat: 49.220129, lng: 19.683341 },
    { lat: 49.216861, lng: 19.681866 },
    { lat: 49.21472, lng: 19.685505 },
    { lat: 49.213613, lng: 19.686213 },
    { lat: 49.212318, lng: 19.687498 },
    { lat: 49.211821, lng: 19.688008 },
    { lat: 49.209851, lng: 19.694138 },
    { lat: 49.208992, lng: 19.69935 },
    { lat: 49.208292, lng: 19.70226 },
    { lat: 49.207584, lng: 19.706897 },
    { lat: 49.206009, lng: 19.707414 },
    { lat: 49.203728, lng: 19.70436 },
    { lat: 49.201411, lng: 19.702706 },
    { lat: 49.199731, lng: 19.706204 },
    { lat: 49.1991443, lng: 19.7073177 },
    { lat: 49.197858, lng: 19.709759 },
    { lat: 49.197795, lng: 19.709945 },
    { lat: 49.198759, lng: 19.716358 },
    { lat: 49.199479, lng: 19.718772 },
    { lat: 49.200154, lng: 19.722858 },
    { lat: 49.200143, lng: 19.723123 },
    { lat: 49.200016, lng: 19.726187 },
    { lat: 49.199968, lng: 19.727066 },
    { lat: 49.199984, lng: 19.729042 },
    { lat: 49.199007, lng: 19.731202 },
    { lat: 49.198072, lng: 19.732933 },
    { lat: 49.196885, lng: 19.735616 },
    { lat: 49.196771, lng: 19.737215 },
    { lat: 49.196331, lng: 19.739002 },
    { lat: 49.196585, lng: 19.740133 },
    { lat: 49.196483, lng: 19.741804 },
    { lat: 49.1973, lng: 19.746102 },
    { lat: 49.197129, lng: 19.748232 },
    { lat: 49.197249, lng: 19.750319 },
    { lat: 49.19725, lng: 19.750338 },
    { lat: 49.197583, lng: 19.751034 },
    { lat: 49.198743, lng: 19.752993 },
    { lat: 49.199787, lng: 19.755328 },
    { lat: 49.200185, lng: 19.757478 },
    { lat: 49.200368, lng: 19.757905 },
    { lat: 49.201675, lng: 19.758168 },
    { lat: 49.202181, lng: 19.758412 },
    { lat: 49.202696, lng: 19.758757 },
    { lat: 49.2031602, lng: 19.7589267 },
    { lat: 49.203326, lng: 19.75896 },
    { lat: 49.2036388, lng: 19.7593443 },
    { lat: 49.2039308, lng: 19.7594797 },
    { lat: 49.2045106, lng: 19.7598383 },
    { lat: 49.2050074, lng: 19.7603486 },
    { lat: 49.2053369, lng: 19.7610449 },
    { lat: 49.2062281, lng: 19.761621 },
    { lat: 49.2070122, lng: 19.7627333 },
    { lat: 49.2075775, lng: 19.7631071 },
    { lat: 49.2085541, lng: 19.7627972 },
    { lat: 49.2098711, lng: 19.7608463 },
    { lat: 49.2101958, lng: 19.7605108 },
    { lat: 49.2112869, lng: 19.7597772 },
    { lat: 49.2121604, lng: 19.7594583 },
    { lat: 49.212906, lng: 19.7594278 },
    { lat: 49.2159804, lng: 19.758386 },
    { lat: 49.2179563, lng: 19.7609067 },
    { lat: 49.2189578, lng: 19.762727 },
    { lat: 49.2203741, lng: 19.7646397 },
    { lat: 49.2213851, lng: 19.7653752 },
    { lat: 49.2226128, lng: 19.7669425 },
    { lat: 49.2237249, lng: 19.7673373 },
    { lat: 49.2250735, lng: 19.7671406 },
    { lat: 49.2258894, lng: 19.7673815 },
    { lat: 49.2272044, lng: 19.7676674 },
    { lat: 49.2280767, lng: 19.7682626 },
    { lat: 49.2303009, lng: 19.7690418 },
    { lat: 49.2307081, lng: 19.7691856 },
    { lat: 49.2317648, lng: 19.7679639 },
    { lat: 49.232119, lng: 19.767795 },
    { lat: 49.2345695, lng: 19.7662571 },
    { lat: 49.2365983, lng: 19.7667676 },
    { lat: 49.236769, lng: 19.7656064 },
    { lat: 49.2367565, lng: 19.7653186 },
    { lat: 49.2367776, lng: 19.7647934 },
    { lat: 49.2368712, lng: 19.7635773 },
    { lat: 49.2374855, lng: 19.7609149 },
    { lat: 49.2380277, lng: 19.7600117 },
    { lat: 49.2379162, lng: 19.7590803 },
    { lat: 49.2386144, lng: 19.7579317 },
    { lat: 49.2387586, lng: 19.7571546 },
    { lat: 49.2390526, lng: 19.7561968 },
    { lat: 49.2398843, lng: 19.7558172 },
    { lat: 49.2406219, lng: 19.7551101 },
    { lat: 49.2408661, lng: 19.75482 },
    { lat: 49.2412363, lng: 19.7544471 },
    { lat: 49.2416478, lng: 19.7540907 },
    { lat: 49.2423668, lng: 19.7536615 },
    { lat: 49.2427545, lng: 19.7530161 },
    { lat: 49.2425854, lng: 19.7517462 },
    { lat: 49.24337, lng: 19.7506159 },
    { lat: 49.2442331, lng: 19.7482421 },
    { lat: 49.245175, lng: 19.7474174 },
    { lat: 49.2456853, lng: 19.7469246 },
    { lat: 49.2468067, lng: 19.7464725 },
    { lat: 49.2483743, lng: 19.7444699 },
    { lat: 49.2489317, lng: 19.7436369 },
    { lat: 49.2541523, lng: 19.7387098 },
    { lat: 49.2548339, lng: 19.727612 },
    { lat: 49.2561957, lng: 19.7248253 },
    { lat: 49.2611147, lng: 19.7213665 },
    { lat: 49.2612657, lng: 19.7203592 },
    { lat: 49.2615458, lng: 19.7203529 },
    { lat: 49.2616902, lng: 19.7201246 },
    { lat: 49.2617205, lng: 19.7198622 },
    { lat: 49.2620106, lng: 19.7193875 },
    { lat: 49.2620168, lng: 19.7189612 },
    { lat: 49.262227, lng: 19.7184417 },
    { lat: 49.2623202, lng: 19.7178626 },
    { lat: 49.2624284, lng: 19.7174014 },
    { lat: 49.2624181, lng: 19.7168593 },
    { lat: 49.2623751, lng: 19.7165985 },
    { lat: 49.2623449, lng: 19.7161133 },
    { lat: 49.2623947, lng: 19.7157583 },
    { lat: 49.2625916, lng: 19.7151859 },
    { lat: 49.2626502, lng: 19.7146699 },
    { lat: 49.2627722, lng: 19.713984 },
    { lat: 49.261232, lng: 19.7122847 },
    { lat: 49.2590274, lng: 19.7097897 },
    { lat: 49.2590465, lng: 19.709558 },
    { lat: 49.2590511, lng: 19.7086339 },
    { lat: 49.259117, lng: 19.708286 },
    { lat: 49.2591106, lng: 19.7077041 },
    { lat: 49.2591416, lng: 19.7074359 },
    { lat: 49.2590512, lng: 19.707329 },
    { lat: 49.2590541, lng: 19.7071841 },
    { lat: 49.2588578, lng: 19.7060789 },
    { lat: 49.2585723, lng: 19.7047037 },
    { lat: 49.2585197, lng: 19.7045828 },
    { lat: 49.2585627, lng: 19.7043844 },
    { lat: 49.2584247, lng: 19.7035257 },
    { lat: 49.2582987, lng: 19.7024231 },
    { lat: 49.258167, lng: 19.7021711 },
    { lat: 49.2581709, lng: 19.7020297 },
    { lat: 49.2580743, lng: 19.7017767 },
    { lat: 49.2580761, lng: 19.7016117 },
    { lat: 49.2580112, lng: 19.7011069 },
    { lat: 49.2579865, lng: 19.7007019 },
    { lat: 49.2580311, lng: 19.7004852 },
    { lat: 49.2579958, lng: 19.7003745 },
    { lat: 49.2578752, lng: 19.7002679 },
    { lat: 49.2578415, lng: 19.7001198 },
    { lat: 49.2577933, lng: 19.6999726 },
    { lat: 49.2578015, lng: 19.6996706 },
    { lat: 49.2577631, lng: 19.699593 },
    { lat: 49.2577958, lng: 19.6994386 },
    { lat: 49.2578295, lng: 19.6986636 },
    { lat: 49.2555877, lng: 19.698156 },
    { lat: 49.253967, lng: 19.6971383 },
    { lat: 49.253879, lng: 19.697134 },
    { lat: 49.2537638, lng: 19.6972298 },
    { lat: 49.2537489, lng: 19.6972515 },
    { lat: 49.253614, lng: 19.6967709 },
    { lat: 49.2536026, lng: 19.6965871 },
    { lat: 49.2536773, lng: 19.6963137 },
    { lat: 49.2540364, lng: 19.695843 },
    { lat: 49.2544628, lng: 19.6952914 },
    { lat: 49.2547905, lng: 19.6947291 },
    { lat: 49.2550248, lng: 19.6943184 },
    { lat: 49.2551403, lng: 19.6940238 },
    { lat: 49.2553433, lng: 19.6936175 },
    { lat: 49.2555209, lng: 19.6932541 },
    { lat: 49.2555472, lng: 19.6931046 },
    { lat: 49.2555283, lng: 19.6928321 },
    { lat: 49.2555302, lng: 19.6923854 },
    { lat: 49.2555083, lng: 19.6919422 },
    { lat: 49.255484, lng: 19.6916678 },
    { lat: 49.2553235, lng: 19.6912991 },
    { lat: 49.2551356, lng: 19.6908961 },
    { lat: 49.2550675, lng: 19.6906535 },
    { lat: 49.2551728, lng: 19.6903585 },
    { lat: 49.2558617, lng: 19.6895509 },
    { lat: 49.2559429, lng: 19.689399 },
    { lat: 49.2559409, lng: 19.6892034 },
    { lat: 49.2557045, lng: 19.688488 },
    { lat: 49.2557055, lng: 19.6874398 },
    { lat: 49.2556964, lng: 19.6873621 },
    { lat: 49.2556723, lng: 19.6872961 },
    { lat: 49.255473, lng: 19.6870161 },
    { lat: 49.2552229, lng: 19.6862751 },
    { lat: 49.2551339, lng: 19.6856906 },
    { lat: 49.2551781, lng: 19.6854884 },
    { lat: 49.2552466, lng: 19.6853061 },
    { lat: 49.2554532, lng: 19.6850869 },
    { lat: 49.2556492, lng: 19.6847789 },
    { lat: 49.2556837, lng: 19.6845911 },
    { lat: 49.2556127, lng: 19.6843081 },
    { lat: 49.2555165, lng: 19.6840157 },
    { lat: 49.2554324, lng: 19.6836936 },
    { lat: 49.2554642, lng: 19.6833391 },
    { lat: 49.2556131, lng: 19.6827868 },
    { lat: 49.2557443, lng: 19.6821057 },
    { lat: 49.2558712, lng: 19.6812509 },
    { lat: 49.2560963, lng: 19.680735 },
    { lat: 49.2561404, lng: 19.680434 },
    { lat: 49.2561367, lng: 19.6802216 },
    { lat: 49.2561156, lng: 19.6800539 },
    { lat: 49.2558672, lng: 19.6792435 },
    { lat: 49.2557958, lng: 19.6789786 },
    { lat: 49.2557645, lng: 19.6787827 },
    { lat: 49.2556851, lng: 19.6786416 },
    { lat: 49.2556538, lng: 19.6784197 },
    { lat: 49.2556061, lng: 19.6778462 },
    { lat: 49.2557448, lng: 19.6773147 },
    { lat: 49.2558186, lng: 19.6770186 },
    { lat: 49.2558999, lng: 19.6766665 },
    { lat: 49.2558583, lng: 19.676159 },
    { lat: 49.2557997, lng: 19.6758033 },
    { lat: 49.2555411, lng: 19.6751007 },
    { lat: 49.2554922, lng: 19.6748056 },
    { lat: 49.2554955, lng: 19.6743195 },
    { lat: 49.2556332, lng: 19.6738675 },
    { lat: 49.2561378, lng: 19.6729986 },
    { lat: 49.2563974, lng: 19.6726541 },
    { lat: 49.2566788, lng: 19.6725432 },
    { lat: 49.2570186, lng: 19.672533 },
    { lat: 49.2572036, lng: 19.6724887 },
    { lat: 49.2572876, lng: 19.6723286 },
    { lat: 49.2573024, lng: 19.6720223 },
    { lat: 49.2572214, lng: 19.6715401 },
    { lat: 49.2571052, lng: 19.6708968 },
    { lat: 49.2569651, lng: 19.6702808 },
    { lat: 49.256901, lng: 19.6698723 },
    { lat: 49.2569016, lng: 19.6696338 },
    { lat: 49.2570275, lng: 19.669395 },
    { lat: 49.2572358, lng: 19.6691416 },
    { lat: 49.2574711, lng: 19.66906 },
    { lat: 49.2576871, lng: 19.6689479 },
    { lat: 49.2579579, lng: 19.6687559 },
    { lat: 49.2579943, lng: 19.6686883 },
    { lat: 49.2580985, lng: 19.6682938 },
    { lat: 49.2581476, lng: 19.6678249 },
    { lat: 49.2581825, lng: 19.667769 },
    { lat: 49.2583697, lng: 19.6675192 },
    { lat: 49.2586806, lng: 19.6672898 },
    { lat: 49.2588636, lng: 19.6669954 },
    { lat: 49.2588987, lng: 19.6665138 },
    { lat: 49.2588073, lng: 19.6660003 },
    { lat: 49.2588139, lng: 19.6654892 },
    { lat: 49.2593467, lng: 19.6649278 },
    { lat: 49.2593765, lng: 19.6648861 },
    { lat: 49.2594791, lng: 19.6646606 },
    { lat: 49.2595732, lng: 19.6645246 },
    { lat: 49.2598825, lng: 19.6640014 },
    { lat: 49.2599576, lng: 19.6638901 },
    { lat: 49.2601151, lng: 19.6636827 },
    { lat: 49.2603652, lng: 19.664674 },
    { lat: 49.2605912, lng: 19.6648469 },
    { lat: 49.2607303, lng: 19.6648798 },
    { lat: 49.2615613, lng: 19.6645913 },
    { lat: 49.2621768, lng: 19.6638355 },
    { lat: 49.2622183, lng: 19.6638067 },
    { lat: 49.2622602, lng: 19.6637624 },
    { lat: 49.2632386, lng: 19.6628043 },
    { lat: 49.2641324, lng: 19.6613913 },
    { lat: 49.2647669, lng: 19.6594125 },
    { lat: 49.2655235, lng: 19.6578484 },
    { lat: 49.2660134, lng: 19.6564958 },
    { lat: 49.2651472, lng: 19.6553279 },
    { lat: 49.2646955, lng: 19.6548469 },
    { lat: 49.2642128, lng: 19.6542998 },
    { lat: 49.2638771, lng: 19.6532866 },
    { lat: 49.2635344, lng: 19.6514247 },
    { lat: 49.2635967, lng: 19.6500897 },
    { lat: 49.2636822, lng: 19.6488122 },
    { lat: 49.264124, lng: 19.6468621 },
    { lat: 49.2642178, lng: 19.6464643 },
    { lat: 49.264789, lng: 19.6455 },
    { lat: 49.2649922, lng: 19.6450511 },
    { lat: 49.2650841, lng: 19.6446329 },
    { lat: 49.2654892, lng: 19.642166 },
    { lat: 49.2663892, lng: 19.6380557 },
    { lat: 49.2666506, lng: 19.63638 },
    { lat: 49.267127, lng: 19.6334657 },
    { lat: 49.2672983, lng: 19.6326031 },
    { lat: 49.2681743, lng: 19.6287987 },
    { lat: 49.2685968, lng: 19.6277367 },
    { lat: 49.2697137, lng: 19.6254945 },
    { lat: 49.2701207, lng: 19.6243618 },
    { lat: 49.2707266, lng: 19.6220067 },
    { lat: 49.2710237, lng: 19.6200838 },
    { lat: 49.2713224, lng: 19.6170037 },
    { lat: 49.2711577, lng: 19.614272 },
    { lat: 49.2711521, lng: 19.6141986 },
    { lat: 49.2710749, lng: 19.6119882 },
    { lat: 49.2710733, lng: 19.6115261 },
    { lat: 49.2709586, lng: 19.6114793 },
    { lat: 49.2708626, lng: 19.6103117 },
    { lat: 49.2708063, lng: 19.6097876 },
    { lat: 49.2707842, lng: 19.6097675 },
    { lat: 49.27075, lng: 19.6096102 },
    { lat: 49.2706174, lng: 19.6090616 },
    { lat: 49.2705625, lng: 19.6086899 },
    { lat: 49.2705216, lng: 19.6080805 },
    { lat: 49.2705124, lng: 19.607695 },
    { lat: 49.2704969, lng: 19.6073485 },
    { lat: 49.2704721, lng: 19.6070224 },
    { lat: 49.2704007, lng: 19.6064804 },
    { lat: 49.2699209, lng: 19.6044737 },
    { lat: 49.269446, lng: 19.6024711 },
    { lat: 49.2693412, lng: 19.6016631 },
    { lat: 49.2692309, lng: 19.6007067 },
    { lat: 49.2689619, lng: 19.5997567 },
    { lat: 49.2689007, lng: 19.5993966 },
    { lat: 49.2685478, lng: 19.5982581 },
    { lat: 49.2683921, lng: 19.598029 },
    { lat: 49.2679781, lng: 19.5972884 },
    { lat: 49.2678838, lng: 19.5970699 },
    { lat: 49.2676739, lng: 19.597059 },
    { lat: 49.2675438, lng: 19.5962937 },
    { lat: 49.2675063, lng: 19.5957117 },
    { lat: 49.2674436, lng: 19.5953399 },
    { lat: 49.2672654, lng: 19.5951879 },
    { lat: 49.2664718, lng: 19.594347 },
    { lat: 49.2662356, lng: 19.5941275 },
    { lat: 49.2661146, lng: 19.5938685 },
    { lat: 49.2664455, lng: 19.5933331 },
    { lat: 49.2667569, lng: 19.5926865 },
    { lat: 49.2668843, lng: 19.5925152 },
    { lat: 49.2668842, lng: 19.5923344 },
    { lat: 49.2666973, lng: 19.5910837 },
    { lat: 49.2663149, lng: 19.590368 },
    { lat: 49.2661875, lng: 19.5893391 },
    { lat: 49.2659343, lng: 19.5866065 },
    { lat: 49.2654224, lng: 19.5851702 },
    { lat: 49.2650548, lng: 19.5851846 },
    { lat: 49.2647256, lng: 19.5850828 },
    { lat: 49.2645113, lng: 19.5849274 },
    { lat: 49.2640947, lng: 19.5847084 },
    { lat: 49.26393, lng: 19.5846258 },
    { lat: 49.2633411, lng: 19.5840566 },
    { lat: 49.2628709, lng: 19.5835965 },
    { lat: 49.2624185, lng: 19.5829056 },
    { lat: 49.2621748, lng: 19.5825949 },
    { lat: 49.2616899, lng: 19.5818857 },
    { lat: 49.2613182, lng: 19.5813375 },
    { lat: 49.2604321, lng: 19.5805232 },
    { lat: 49.2595103, lng: 19.5798635 },
    { lat: 49.2590944, lng: 19.5795876 },
    { lat: 49.2584711, lng: 19.5793558 },
    { lat: 49.2581052, lng: 19.5791089 },
    { lat: 49.2564838, lng: 19.5776307 },
    { lat: 49.2535641, lng: 19.5751383 },
    { lat: 49.2517857, lng: 19.5737772 },
    { lat: 49.2516237, lng: 19.5736279 },
    { lat: 49.2514079, lng: 19.5732921 },
    { lat: 49.2511587, lng: 19.5729583 },
    { lat: 49.2510208, lng: 19.5728235 },
    { lat: 49.2502639, lng: 19.572278 },
    { lat: 49.2501679, lng: 19.5722221 },
    { lat: 49.249912, lng: 19.5721448 },
    { lat: 49.2495717, lng: 19.571922 },
    { lat: 49.2492802, lng: 19.5717555 },
    { lat: 49.2490058, lng: 19.5715869 },
    { lat: 49.2486389, lng: 19.5713851 },
    { lat: 49.2483305, lng: 19.571228 },
    { lat: 49.2479774, lng: 19.571268 },
    { lat: 49.2471779, lng: 19.5714018 },
    { lat: 49.2463313, lng: 19.5715174 },
    { lat: 49.2455234, lng: 19.571638 },
    { lat: 49.2446972, lng: 19.571735 },
    { lat: 49.2438846, lng: 19.5718517 },
    { lat: 49.2426887, lng: 19.5720174 },
    { lat: 49.241843, lng: 19.572182 },
  ],
  Zábiedovo: [
    { lat: 49.3212909, lng: 19.6399553 },
    { lat: 49.3213018, lng: 19.6399242 },
    { lat: 49.321374, lng: 19.639647 },
    { lat: 49.3214824, lng: 19.6391966 },
    { lat: 49.3215239, lng: 19.6387581 },
    { lat: 49.3215763, lng: 19.6384457 },
    { lat: 49.3215925, lng: 19.638171 },
    { lat: 49.3216298, lng: 19.6379216 },
    { lat: 49.3216123, lng: 19.6375536 },
    { lat: 49.32164, lng: 19.6369869 },
    { lat: 49.3216382, lng: 19.6368199 },
    { lat: 49.3216463, lng: 19.6365923 },
    { lat: 49.3216281, lng: 19.6363623 },
    { lat: 49.3216349, lng: 19.6361341 },
    { lat: 49.321686, lng: 19.635838 },
    { lat: 49.3217249, lng: 19.6355758 },
    { lat: 49.3216895, lng: 19.6354599 },
    { lat: 49.3216987, lng: 19.6352899 },
    { lat: 49.3217322, lng: 19.6349008 },
    { lat: 49.321759, lng: 19.6347181 },
    { lat: 49.3217657, lng: 19.6343104 },
    { lat: 49.321654, lng: 19.634028 },
    { lat: 49.3215493, lng: 19.6339295 },
    { lat: 49.3214755, lng: 19.6339152 },
    { lat: 49.3214416, lng: 19.6338858 },
    { lat: 49.3213755, lng: 19.6336677 },
    { lat: 49.3212889, lng: 19.6336218 },
    { lat: 49.3212453, lng: 19.6335007 },
    { lat: 49.321148, lng: 19.6329586 },
    { lat: 49.3211473, lng: 19.6328153 },
    { lat: 49.3211761, lng: 19.632731 },
    { lat: 49.3212806, lng: 19.6324955 },
    { lat: 49.3213256, lng: 19.6324175 },
    { lat: 49.3214279, lng: 19.632313 },
    { lat: 49.3215667, lng: 19.6321913 },
    { lat: 49.3215941, lng: 19.6321484 },
    { lat: 49.3216803, lng: 19.631905 },
    { lat: 49.321773, lng: 19.63151 },
    { lat: 49.3218311, lng: 19.6314483 },
    { lat: 49.3221494, lng: 19.6313109 },
    { lat: 49.3223064, lng: 19.6312169 },
    { lat: 49.3223794, lng: 19.6311107 },
    { lat: 49.3224194, lng: 19.6309964 },
    { lat: 49.3225236, lng: 19.6308822 },
    { lat: 49.3228722, lng: 19.630513 },
    { lat: 49.3229949, lng: 19.6303312 },
    { lat: 49.3231717, lng: 19.6301304 },
    { lat: 49.3232995, lng: 19.6299127 },
    { lat: 49.3234486, lng: 19.6297939 },
    { lat: 49.323699, lng: 19.6294478 },
    { lat: 49.3237326, lng: 19.6291886 },
    { lat: 49.3238016, lng: 19.6289153 },
    { lat: 49.3238847, lng: 19.6287603 },
    { lat: 49.3240505, lng: 19.6284863 },
    { lat: 49.3243119, lng: 19.6283067 },
    { lat: 49.324421, lng: 19.6283022 },
    { lat: 49.3244478, lng: 19.6283214 },
    { lat: 49.3245993, lng: 19.6283356 },
    { lat: 49.3247004, lng: 19.6281525 },
    { lat: 49.3247619, lng: 19.628016 },
    { lat: 49.3248305, lng: 19.6279879 },
    { lat: 49.3249955, lng: 19.6280986 },
    { lat: 49.3251221, lng: 19.6281107 },
    { lat: 49.3253224, lng: 19.6281827 },
    { lat: 49.325512, lng: 19.6281615 },
    { lat: 49.3256636, lng: 19.6283267 },
    { lat: 49.3258121, lng: 19.6283224 },
    { lat: 49.3258716, lng: 19.6276105 },
    { lat: 49.3259183, lng: 19.6271379 },
    { lat: 49.3259897, lng: 19.6263394 },
    { lat: 49.3260255, lng: 19.6259828 },
    { lat: 49.3260641, lng: 19.6256548 },
    { lat: 49.3263315, lng: 19.6256913 },
    { lat: 49.3264805, lng: 19.6256971 },
    { lat: 49.3265926, lng: 19.6257121 },
    { lat: 49.3272276, lng: 19.6257796 },
    { lat: 49.32743, lng: 19.6257928 },
    { lat: 49.3276226, lng: 19.6257923 },
    { lat: 49.327919, lng: 19.625821 },
    { lat: 49.3280207, lng: 19.6254752 },
    { lat: 49.3283305, lng: 19.624459 },
    { lat: 49.3284773, lng: 19.623985 },
    { lat: 49.3284847, lng: 19.6240034 },
    { lat: 49.3285089, lng: 19.6241172 },
    { lat: 49.3285129, lng: 19.624128 },
    { lat: 49.3285774, lng: 19.6242148 },
    { lat: 49.3286517, lng: 19.6242331 },
    { lat: 49.3287396, lng: 19.6244381 },
    { lat: 49.3288932, lng: 19.624499 },
    { lat: 49.3289514, lng: 19.6244904 },
    { lat: 49.3289955, lng: 19.6244696 },
    { lat: 49.3290541, lng: 19.6245177 },
    { lat: 49.3291953, lng: 19.6245022 },
    { lat: 49.3292103, lng: 19.6245104 },
    { lat: 49.3292614, lng: 19.6245986 },
    { lat: 49.3293154, lng: 19.6246436 },
    { lat: 49.329362, lng: 19.6247038 },
    { lat: 49.3294837, lng: 19.6248291 },
    { lat: 49.3295059, lng: 19.6248997 },
    { lat: 49.3296358, lng: 19.624832 },
    { lat: 49.3297007, lng: 19.6248733 },
    { lat: 49.3297821, lng: 19.6248771 },
    { lat: 49.3297897, lng: 19.6249478 },
    { lat: 49.3299474, lng: 19.6250217 },
    { lat: 49.3299794, lng: 19.6250086 },
    { lat: 49.3299798, lng: 19.6249908 },
    { lat: 49.329856, lng: 19.6240808 },
    { lat: 49.3296678, lng: 19.6227387 },
    { lat: 49.3295636, lng: 19.6220221 },
    { lat: 49.3294208, lng: 19.6211162 },
    { lat: 49.3294015, lng: 19.6209622 },
    { lat: 49.329414, lng: 19.6209367 },
    { lat: 49.3294993, lng: 19.6209318 },
    { lat: 49.3298179, lng: 19.6208986 },
    { lat: 49.3299101, lng: 19.6208962 },
    { lat: 49.3300999, lng: 19.6204769 },
    { lat: 49.3301866, lng: 19.6202775 },
    { lat: 49.3299999, lng: 19.6195261 },
    { lat: 49.3299579, lng: 19.6193658 },
    { lat: 49.3298249, lng: 19.6188295 },
    { lat: 49.3296704, lng: 19.6181962 },
    { lat: 49.3295828, lng: 19.6178727 },
    { lat: 49.329689, lng: 19.616187 },
    { lat: 49.3297282, lng: 19.6151704 },
    { lat: 49.3297361, lng: 19.6145125 },
    { lat: 49.3297762, lng: 19.6132661 },
    { lat: 49.329802, lng: 19.6126346 },
    { lat: 49.3299322, lng: 19.6113823 },
    { lat: 49.3300592, lng: 19.6101384 },
    { lat: 49.3301832, lng: 19.6091559 },
    { lat: 49.3302022, lng: 19.6090264 },
    { lat: 49.330327, lng: 19.6084914 },
    { lat: 49.330432, lng: 19.6080188 },
    { lat: 49.3304664, lng: 19.6079058 },
    { lat: 49.3305881, lng: 19.6078826 },
    { lat: 49.330908, lng: 19.607804 },
    { lat: 49.3310726, lng: 19.6077311 },
    { lat: 49.3312532, lng: 19.6077357 },
    { lat: 49.3314455, lng: 19.6077539 },
    { lat: 49.3319411, lng: 19.607523 },
    { lat: 49.3325708, lng: 19.6072238 },
    { lat: 49.3329481, lng: 19.6070405 },
    { lat: 49.3337887, lng: 19.6066416 },
    { lat: 49.3336705, lng: 19.6059618 },
    { lat: 49.3336764, lng: 19.6055906 },
    { lat: 49.3337372, lng: 19.6055409 },
    { lat: 49.3341776, lng: 19.6052116 },
    { lat: 49.3344753, lng: 19.6050634 },
    { lat: 49.3349803, lng: 19.6048675 },
    { lat: 49.3351905, lng: 19.6048076 },
    { lat: 49.3355368, lng: 19.6047161 },
    { lat: 49.3357515, lng: 19.6046807 },
    { lat: 49.3362133, lng: 19.6046298 },
    { lat: 49.3365666, lng: 19.6046156 },
    { lat: 49.3365445, lng: 19.6041223 },
    { lat: 49.3367368, lng: 19.6040126 },
    { lat: 49.3367989, lng: 19.6039674 },
    { lat: 49.3372838, lng: 19.6034961 },
    { lat: 49.3375466, lng: 19.6032537 },
    { lat: 49.3377996, lng: 19.6030151 },
    { lat: 49.3380352, lng: 19.6028124 },
    { lat: 49.3382002, lng: 19.6026759 },
    { lat: 49.3384553, lng: 19.6024346 },
    { lat: 49.3386784, lng: 19.602236 },
    { lat: 49.3389121, lng: 19.6019908 },
    { lat: 49.3392939, lng: 19.601613 },
    { lat: 49.3397719, lng: 19.601188 },
    { lat: 49.3399965, lng: 19.6010221 },
    { lat: 49.3402028, lng: 19.6008253 },
    { lat: 49.3403664, lng: 19.6006568 },
    { lat: 49.3404074, lng: 19.6006651 },
    { lat: 49.3404794, lng: 19.6006121 },
    { lat: 49.3407187, lng: 19.601022 },
    { lat: 49.3407617, lng: 19.6010811 },
    { lat: 49.340819, lng: 19.6011268 },
    { lat: 49.340857, lng: 19.6011438 },
    { lat: 49.3409845, lng: 19.6011496 },
    { lat: 49.3413179, lng: 19.6010439 },
    { lat: 49.341533, lng: 19.6008927 },
    { lat: 49.3417264, lng: 19.6007337 },
    { lat: 49.3418402, lng: 19.6006336 },
    { lat: 49.3419349, lng: 19.6006086 },
    { lat: 49.3420873, lng: 19.6008433 },
    { lat: 49.3423019, lng: 19.6008288 },
    { lat: 49.3426321, lng: 19.6007574 },
    { lat: 49.3427471, lng: 19.6005839 },
    { lat: 49.3432621, lng: 19.5999312 },
    { lat: 49.3434345, lng: 19.5997528 },
    { lat: 49.3436648, lng: 19.5995407 },
    { lat: 49.3439513, lng: 19.599295 },
    { lat: 49.3440106, lng: 19.599251 },
    { lat: 49.3440489, lng: 19.5992437 },
    { lat: 49.3440884, lng: 19.5992461 },
    { lat: 49.3441158, lng: 19.5992344 },
    { lat: 49.3441426, lng: 19.5991993 },
    { lat: 49.3441572, lng: 19.5991571 },
    { lat: 49.3441829, lng: 19.5990585 },
    { lat: 49.3442147, lng: 19.5989949 },
    { lat: 49.3442394, lng: 19.5989701 },
    { lat: 49.3442686, lng: 19.5989524 },
    { lat: 49.3442951, lng: 19.5989262 },
    { lat: 49.3443796, lng: 19.5987836 },
    { lat: 49.3444499, lng: 19.5987096 },
    { lat: 49.3445284, lng: 19.5985427 },
    { lat: 49.3446399, lng: 19.5983919 },
    { lat: 49.3447405, lng: 19.5982586 },
    { lat: 49.3448421, lng: 19.5981677 },
    { lat: 49.3448706, lng: 19.5981353 },
    { lat: 49.3448974, lng: 19.598091 },
    { lat: 49.3449592, lng: 19.597965 },
    { lat: 49.3450024, lng: 19.5978748 },
    { lat: 49.3450468, lng: 19.5978075 },
    { lat: 49.345073, lng: 19.5977624 },
    { lat: 49.3451069, lng: 19.597678 },
    { lat: 49.3451676, lng: 19.597547 },
    { lat: 49.3452044, lng: 19.5974489 },
    { lat: 49.3452428, lng: 19.5973742 },
    { lat: 49.3452668, lng: 19.5973367 },
    { lat: 49.3452916, lng: 19.5973132 },
    { lat: 49.3453708, lng: 19.5972662 },
    { lat: 49.34547, lng: 19.5971664 },
    { lat: 49.345546, lng: 19.5971101 },
    { lat: 49.3456091, lng: 19.5970656 },
    { lat: 49.3456616, lng: 19.5970503 },
    { lat: 49.345697, lng: 19.5970582 },
    { lat: 49.3457181, lng: 19.597078 },
    { lat: 49.3457384, lng: 19.5970884 },
    { lat: 49.345776, lng: 19.5970873 },
    { lat: 49.3458107, lng: 19.5970658 },
    { lat: 49.3458477, lng: 19.5970209 },
    { lat: 49.345894, lng: 19.5969674 },
    { lat: 49.3459312, lng: 19.5969384 },
    { lat: 49.3459912, lng: 19.5969062 },
    { lat: 49.3460688, lng: 19.5968681 },
    { lat: 49.3461371, lng: 19.5968299 },
    { lat: 49.3461766, lng: 19.5967986 },
    { lat: 49.3462112, lng: 19.5967451 },
    { lat: 49.3463411, lng: 19.5964743 },
    { lat: 49.3463774, lng: 19.5964125 },
    { lat: 49.3464254, lng: 19.5963501 },
    { lat: 49.3464722, lng: 19.5962913 },
    { lat: 49.3465093, lng: 19.5962584 },
    { lat: 49.346554, lng: 19.5962209 },
    { lat: 49.3465772, lng: 19.596196 },
    { lat: 49.3465922, lng: 19.5961629 },
    { lat: 49.3466004, lng: 19.5961278 },
    { lat: 49.3466072, lng: 19.5960834 },
    { lat: 49.3466199, lng: 19.5960263 },
    { lat: 49.3466402, lng: 19.5959786 },
    { lat: 49.3467007, lng: 19.5958855 },
    { lat: 49.3467072, lng: 19.5958388 },
    { lat: 49.3467164, lng: 19.5958063 },
    { lat: 49.3467381, lng: 19.5957792 },
    { lat: 49.346764, lng: 19.5957333 },
    { lat: 49.3468221, lng: 19.5956056 },
    { lat: 49.3468722, lng: 19.5955134 },
    { lat: 49.3469048, lng: 19.5954566 },
    { lat: 49.3469312, lng: 19.595426 },
    { lat: 49.3469635, lng: 19.5954031 },
    { lat: 49.3469735, lng: 19.5953853 },
    { lat: 49.3469795, lng: 19.5953643 },
    { lat: 49.3469775, lng: 19.5953379 },
    { lat: 49.346955, lng: 19.5952851 },
    { lat: 49.3469519, lng: 19.5952487 },
    { lat: 49.3469553, lng: 19.5952154 },
    { lat: 49.3469957, lng: 19.5950818 },
    { lat: 49.3470022, lng: 19.5950395 },
    { lat: 49.3470027, lng: 19.5949901 },
    { lat: 49.3469905, lng: 19.5948728 },
    { lat: 49.3469696, lng: 19.5947634 },
    { lat: 49.3469633, lng: 19.594673 },
    { lat: 49.3469655, lng: 19.5946069 },
    { lat: 49.3469737, lng: 19.594557 },
    { lat: 49.3467603, lng: 19.5943886 },
    { lat: 49.3460082, lng: 19.5937726 },
    { lat: 49.3457351, lng: 19.5935577 },
    { lat: 49.3456301, lng: 19.5938588 },
    { lat: 49.3447713, lng: 19.5931969 },
    { lat: 49.3447234, lng: 19.5932063 },
    { lat: 49.3446678, lng: 19.5932996 },
    { lat: 49.3445581, lng: 19.5935438 },
    { lat: 49.3445077, lng: 19.5937005 },
    { lat: 49.344436, lng: 19.5938858 },
    { lat: 49.3442055, lng: 19.5942221 },
    { lat: 49.3434936, lng: 19.5949041 },
    { lat: 49.3433494, lng: 19.5950067 },
    { lat: 49.3432864, lng: 19.5950321 },
    { lat: 49.3431125, lng: 19.5950723 },
    { lat: 49.3429758, lng: 19.5951409 },
    { lat: 49.3428983, lng: 19.5951439 },
    { lat: 49.3428496, lng: 19.5951154 },
    { lat: 49.3427076, lng: 19.5951657 },
    { lat: 49.3425323, lng: 19.5952744 },
    { lat: 49.3422303, lng: 19.5955265 },
    { lat: 49.342149, lng: 19.595625 },
    { lat: 49.342054, lng: 19.5956653 },
    { lat: 49.341668, lng: 19.5959496 },
    { lat: 49.3416169, lng: 19.5959691 },
    { lat: 49.3413946, lng: 19.5961604 },
    { lat: 49.3410301, lng: 19.5963713 },
    { lat: 49.3410543, lng: 19.5965631 },
    { lat: 49.3412402, lng: 19.5973432 },
    { lat: 49.3414471, lng: 19.5976879 },
    { lat: 49.3413181, lng: 19.5983338 },
    { lat: 49.3411262, lng: 19.5983897 },
    { lat: 49.3408961, lng: 19.5986731 },
    { lat: 49.3407226, lng: 19.5986712 },
    { lat: 49.3405154, lng: 19.5987104 },
    { lat: 49.3402898, lng: 19.5988746 },
    { lat: 49.3402021, lng: 19.5990849 },
    { lat: 49.3401315, lng: 19.599055 },
    { lat: 49.3398925, lng: 19.5988772 },
    { lat: 49.3398311, lng: 19.5988366 },
    { lat: 49.3396857, lng: 19.5988855 },
    { lat: 49.3395688, lng: 19.5990686 },
    { lat: 49.3395804, lng: 19.5992488 },
    { lat: 49.3394822, lng: 19.5995726 },
    { lat: 49.3394162, lng: 19.5996106 },
    { lat: 49.3392836, lng: 19.5996447 },
    { lat: 49.3391729, lng: 19.5997127 },
    { lat: 49.3389526, lng: 19.5998987 },
    { lat: 49.3385954, lng: 19.6000972 },
    { lat: 49.338254, lng: 19.6000499 },
    { lat: 49.3381043, lng: 19.599976 },
    { lat: 49.337703, lng: 19.600023 },
    { lat: 49.3375126, lng: 19.6001741 },
    { lat: 49.3373373, lng: 19.6004833 },
    { lat: 49.3372089, lng: 19.6005881 },
    { lat: 49.3371287, lng: 19.6007078 },
    { lat: 49.3370454, lng: 19.6006858 },
    { lat: 49.3369883, lng: 19.6006177 },
    { lat: 49.3368354, lng: 19.6005456 },
    { lat: 49.3367834, lng: 19.6002909 },
    { lat: 49.3366929, lng: 19.6000583 },
    { lat: 49.3366473, lng: 19.5997651 },
    { lat: 49.336611, lng: 19.5996887 },
    { lat: 49.3365721, lng: 19.5993452 },
    { lat: 49.3361689, lng: 19.5995251 },
    { lat: 49.3355595, lng: 19.5997778 },
    { lat: 49.3349798, lng: 19.6002175 },
    { lat: 49.3348934, lng: 19.6004512 },
    { lat: 49.3347438, lng: 19.6007122 },
    { lat: 49.334696, lng: 19.6006988 },
    { lat: 49.3346746, lng: 19.6007487 },
    { lat: 49.334597, lng: 19.6006289 },
    { lat: 49.3344811, lng: 19.6005269 },
    { lat: 49.3344705, lng: 19.6004442 },
    { lat: 49.3343964, lng: 19.6003974 },
    { lat: 49.3343576, lng: 19.6003104 },
    { lat: 49.3343139, lng: 19.6002941 },
    { lat: 49.3342601, lng: 19.59992 },
    { lat: 49.333973, lng: 19.599725 },
    { lat: 49.3338471, lng: 19.5995529 },
    { lat: 49.3338502, lng: 19.5993965 },
    { lat: 49.333791, lng: 19.5992553 },
    { lat: 49.3337229, lng: 19.5992202 },
    { lat: 49.3336787, lng: 19.599241 },
    { lat: 49.3334217, lng: 19.5990364 },
    { lat: 49.3333026, lng: 19.5989661 },
    { lat: 49.3332867, lng: 19.5989111 },
    { lat: 49.33309, lng: 19.5987724 },
    { lat: 49.3330005, lng: 19.5987604 },
    { lat: 49.3329177, lng: 19.5986463 },
    { lat: 49.3327509, lng: 19.5986703 },
    { lat: 49.3326914, lng: 19.5986277 },
    { lat: 49.3325544, lng: 19.5986106 },
    { lat: 49.3325481, lng: 19.5987236 },
    { lat: 49.3325404, lng: 19.6002344 },
    { lat: 49.332376, lng: 19.6003167 },
    { lat: 49.3316313, lng: 19.6004423 },
    { lat: 49.3316065, lng: 19.6010962 },
    { lat: 49.3302194, lng: 19.60086 },
    { lat: 49.3291313, lng: 19.6003912 },
    { lat: 49.3286024, lng: 19.5997241 },
    { lat: 49.3275005, lng: 19.5985594 },
    { lat: 49.3273603, lng: 19.5984487 },
    { lat: 49.3261978, lng: 19.5976469 },
    { lat: 49.3256131, lng: 19.5972469 },
    { lat: 49.3246863, lng: 19.5971012 },
    { lat: 49.323736, lng: 19.5969147 },
    { lat: 49.3223248, lng: 19.5966945 },
    { lat: 49.3218627, lng: 19.5965182 },
    { lat: 49.3207103, lng: 19.5970488 },
    { lat: 49.3193689, lng: 19.5972601 },
    { lat: 49.3176726, lng: 19.596891 },
    { lat: 49.3170607, lng: 19.5962841 },
    { lat: 49.3162744, lng: 19.5957059 },
    { lat: 49.3147216, lng: 19.595947 },
    { lat: 49.3137334, lng: 19.5963751 },
    { lat: 49.3128673, lng: 19.5967558 },
    { lat: 49.312107, lng: 19.5967848 },
    { lat: 49.3111252, lng: 19.5959965 },
    { lat: 49.3110945, lng: 19.595965 },
    { lat: 49.3102498, lng: 19.594889 },
    { lat: 49.3089213, lng: 19.5952098 },
    { lat: 49.3078759, lng: 19.5962216 },
    { lat: 49.3069968, lng: 19.5975203 },
    { lat: 49.3060731, lng: 19.5987041 },
    { lat: 49.3050321, lng: 19.59926 },
    { lat: 49.3043915, lng: 19.5995206 },
    { lat: 49.3039917, lng: 19.5995971 },
    { lat: 49.3031032, lng: 19.5993041 },
    { lat: 49.3023403, lng: 19.5989269 },
    { lat: 49.3016238, lng: 19.5983484 },
    { lat: 49.3007814, lng: 19.5984038 },
    { lat: 49.2995828, lng: 19.5984841 },
    { lat: 49.2995758, lng: 19.5985011 },
    { lat: 49.2994222, lng: 19.5988727 },
    { lat: 49.2993319, lng: 19.5990703 },
    { lat: 49.2992627, lng: 19.5991854 },
    { lat: 49.299114, lng: 19.5994862 },
    { lat: 49.2988982, lng: 19.5998506 },
    { lat: 49.2987677, lng: 19.6000848 },
    { lat: 49.2986759, lng: 19.6002688 },
    { lat: 49.2985909, lng: 19.6004846 },
    { lat: 49.2985379, lng: 19.6006352 },
    { lat: 49.2984656, lng: 19.6008078 },
    { lat: 49.2984172, lng: 19.6009821 },
    { lat: 49.298386, lng: 19.6011933 },
    { lat: 49.2983566, lng: 19.601344 },
    { lat: 49.2983199, lng: 19.6015778 },
    { lat: 49.2981911, lng: 19.6018491 },
    { lat: 49.2980065, lng: 19.6021572 },
    { lat: 49.2978002, lng: 19.6025794 },
    { lat: 49.2976462, lng: 19.602815 },
    { lat: 49.2976138, lng: 19.6028982 },
    { lat: 49.2975042, lng: 19.603119 },
    { lat: 49.2973787, lng: 19.6033585 },
    { lat: 49.2972841, lng: 19.6036618 },
    { lat: 49.297177, lng: 19.6039636 },
    { lat: 49.2970754, lng: 19.6042833 },
    { lat: 49.2969877, lng: 19.6045144 },
    { lat: 49.296885, lng: 19.6048417 },
    { lat: 49.2968212, lng: 19.6050783 },
    { lat: 49.2967568, lng: 19.6053749 },
    { lat: 49.2966976, lng: 19.6056136 },
    { lat: 49.2965957, lng: 19.6059047 },
    { lat: 49.2964885, lng: 19.6061282 },
    { lat: 49.2963855, lng: 19.6062942 },
    { lat: 49.2961973, lng: 19.6065413 },
    { lat: 49.2960555, lng: 19.6067503 },
    { lat: 49.2959921, lng: 19.6070164 },
    { lat: 49.2959258, lng: 19.6071973 },
    { lat: 49.2956924, lng: 19.6077 },
    { lat: 49.2956359, lng: 19.6078943 },
    { lat: 49.2955884, lng: 19.6080199 },
    { lat: 49.2955029, lng: 19.6080978 },
    { lat: 49.2953949, lng: 19.6083032 },
    { lat: 49.2952833, lng: 19.608604 },
    { lat: 49.2951785, lng: 19.6088316 },
    { lat: 49.2950536, lng: 19.6092135 },
    { lat: 49.2950006, lng: 19.6094104 },
    { lat: 49.2949575, lng: 19.609609 },
    { lat: 49.2949041, lng: 19.6098254 },
    { lat: 49.2948783, lng: 19.6100032 },
    { lat: 49.294778, lng: 19.610484 },
    { lat: 49.294739, lng: 19.6109707 },
    { lat: 49.2947017, lng: 19.6112983 },
    { lat: 49.2946854, lng: 19.6116365 },
    { lat: 49.2946702, lng: 19.6120304 },
    { lat: 49.2946691, lng: 19.6122067 },
    { lat: 49.2946608, lng: 19.6123483 },
    { lat: 49.2946275, lng: 19.6125391 },
    { lat: 49.2945975, lng: 19.6126701 },
    { lat: 49.2945492, lng: 19.6128271 },
    { lat: 49.2945132, lng: 19.6130026 },
    { lat: 49.2944113, lng: 19.6132695 },
    { lat: 49.2943623, lng: 19.6133349 },
    { lat: 49.2943158, lng: 19.613478 },
    { lat: 49.2942546, lng: 19.6135999 },
    { lat: 49.2941154, lng: 19.6137856 },
    { lat: 49.2939916, lng: 19.6139645 },
    { lat: 49.293926, lng: 19.6140854 },
    { lat: 49.293812, lng: 19.6143308 },
    { lat: 49.2937715, lng: 19.6144336 },
    { lat: 49.293674, lng: 19.6146225 },
    { lat: 49.2935638, lng: 19.614779 },
    { lat: 49.2934495, lng: 19.6149206 },
    { lat: 49.2933642, lng: 19.6150774 },
    { lat: 49.293129, lng: 19.6155615 },
    { lat: 49.292977, lng: 19.6157906 },
    { lat: 49.2927291, lng: 19.6161439 },
    { lat: 49.2924348, lng: 19.6166445 },
    { lat: 49.2920572, lng: 19.6171701 },
    { lat: 49.2919757, lng: 19.6173139 },
    { lat: 49.2918767, lng: 19.6175975 },
    { lat: 49.2918068, lng: 19.6177719 },
    { lat: 49.2916626, lng: 19.6181002 },
    { lat: 49.2916274, lng: 19.6182215 },
    { lat: 49.2915323, lng: 19.6184866 },
    { lat: 49.2914937, lng: 19.6186332 },
    { lat: 49.2914575, lng: 19.6187312 },
    { lat: 49.2914204, lng: 19.6189058 },
    { lat: 49.2914008, lng: 19.6191006 },
    { lat: 49.2914034, lng: 19.6193078 },
    { lat: 49.2914357, lng: 19.6196568 },
    { lat: 49.291455, lng: 19.6201622 },
    { lat: 49.2914665, lng: 19.6203465 },
    { lat: 49.2914972, lng: 19.6205794 },
    { lat: 49.2916049, lng: 19.6209991 },
    { lat: 49.2917108, lng: 19.6214537 },
    { lat: 49.291904, lng: 19.62225 },
    { lat: 49.2919805, lng: 19.622551 },
    { lat: 49.2922416, lng: 19.6236333 },
    { lat: 49.2922821, lng: 19.6240601 },
    { lat: 49.2923057, lng: 19.6242348 },
    { lat: 49.2923279, lng: 19.62433 },
    { lat: 49.2923628, lng: 19.6246108 },
    { lat: 49.2923838, lng: 19.6247273 },
    { lat: 49.2924211, lng: 19.624853 },
    { lat: 49.2925419, lng: 19.6252122 },
    { lat: 49.2925583, lng: 19.625687 },
    { lat: 49.2926283, lng: 19.626093 },
    { lat: 49.2926754, lng: 19.6263173 },
    { lat: 49.2926805, lng: 19.6268617 },
    { lat: 49.2927491, lng: 19.6270607 },
    { lat: 49.2927505, lng: 19.6272414 },
    { lat: 49.2927444, lng: 19.6276881 },
    { lat: 49.2929422, lng: 19.628536 },
    { lat: 49.2932667, lng: 19.6292977 },
    { lat: 49.2931085, lng: 19.6297152 },
    { lat: 49.2927239, lng: 19.6306966 },
    { lat: 49.2926968, lng: 19.6307449 },
    { lat: 49.2922276, lng: 19.63147 },
    { lat: 49.2920341, lng: 19.6317782 },
    { lat: 49.2917844, lng: 19.632168 },
    { lat: 49.2917136, lng: 19.6322692 },
    { lat: 49.2914209, lng: 19.6327313 },
    { lat: 49.2912836, lng: 19.6329358 },
    { lat: 49.2911837, lng: 19.6330995 },
    { lat: 49.2909812, lng: 19.6333826 },
    { lat: 49.2908335, lng: 19.6336066 },
    { lat: 49.2906897, lng: 19.6337987 },
    { lat: 49.2905053, lng: 19.6340579 },
    { lat: 49.2903637, lng: 19.6342643 },
    { lat: 49.2902033, lng: 19.6344852 },
    { lat: 49.2900414, lng: 19.6347201 },
    { lat: 49.2899455, lng: 19.6349224 },
    { lat: 49.2898226, lng: 19.6352004 },
    { lat: 49.2895489, lng: 19.6353772 },
    { lat: 49.2894015, lng: 19.6355091 },
    { lat: 49.2892993, lng: 19.635615 },
    { lat: 49.2891048, lng: 19.6360571 },
    { lat: 49.2890072, lng: 19.6362932 },
    { lat: 49.2889651, lng: 19.6364091 },
    { lat: 49.2887794, lng: 19.6370748 },
    { lat: 49.288739, lng: 19.6372291 },
    { lat: 49.2886635, lng: 19.6377602 },
    { lat: 49.2886358, lng: 19.6385835 },
    { lat: 49.288458, lng: 19.639144 },
    { lat: 49.2883347, lng: 19.639692 },
    { lat: 49.2883021, lng: 19.6398225 },
    { lat: 49.2882953, lng: 19.6403504 },
    { lat: 49.2882217, lng: 19.6407248 },
    { lat: 49.2881987, lng: 19.6411956 },
    { lat: 49.2881825, lng: 19.6414687 },
    { lat: 49.2882289, lng: 19.6423055 },
    { lat: 49.2882517, lng: 19.6426449 },
    { lat: 49.2883422, lng: 19.6431354 },
    { lat: 49.2883472, lng: 19.6437307 },
    { lat: 49.2882663, lng: 19.6445955 },
    { lat: 49.2882244, lng: 19.6448207 },
    { lat: 49.2881363, lng: 19.6453247 },
    { lat: 49.2881745, lng: 19.6454994 },
    { lat: 49.2882213, lng: 19.6456132 },
    { lat: 49.28833, lng: 19.6457802 },
    { lat: 49.2883983, lng: 19.6458721 },
    { lat: 49.2885119, lng: 19.6460454 },
    { lat: 49.2885283, lng: 19.6461105 },
    { lat: 49.2885976, lng: 19.6462524 },
    { lat: 49.2886329, lng: 19.6463606 },
    { lat: 49.2887519, lng: 19.6466287 },
    { lat: 49.2888142, lng: 19.6468673 },
    { lat: 49.2888893, lng: 19.6470847 },
    { lat: 49.2890038, lng: 19.6474579 },
    { lat: 49.2890402, lng: 19.6475934 },
    { lat: 49.2891458, lng: 19.648021 },
    { lat: 49.2892228, lng: 19.648309 },
    { lat: 49.2893245, lng: 19.6485947 },
    { lat: 49.2894626, lng: 19.6489626 },
    { lat: 49.2895543, lng: 19.649305 },
    { lat: 49.2897325, lng: 19.6498911 },
    { lat: 49.2897874, lng: 19.6500366 },
    { lat: 49.2899231, lng: 19.6502817 },
    { lat: 49.2900127, lng: 19.6504175 },
    { lat: 49.2901291, lng: 19.6506833 },
    { lat: 49.2901802, lng: 19.6508935 },
    { lat: 49.2903493, lng: 19.651456 },
    { lat: 49.2906784, lng: 19.6525801 },
    { lat: 49.2908226, lng: 19.6533418 },
    { lat: 49.2908483, lng: 19.6539755 },
    { lat: 49.2908531, lng: 19.6544344 },
    { lat: 49.2908281, lng: 19.6547391 },
    { lat: 49.2905105, lng: 19.656726 },
    { lat: 49.290411, lng: 19.6573787 },
    { lat: 49.2897131, lng: 19.6588927 },
    { lat: 49.2888279, lng: 19.6608364 },
    { lat: 49.2884678, lng: 19.6616337 },
    { lat: 49.2878511, lng: 19.6622312 },
    { lat: 49.2870561, lng: 19.6623166 },
    { lat: 49.2865283, lng: 19.6625061 },
    { lat: 49.286116, lng: 19.6627642 },
    { lat: 49.2858531, lng: 19.6629852 },
    { lat: 49.2854018, lng: 19.6633753 },
    { lat: 49.2847282, lng: 19.664346 },
    { lat: 49.2842541, lng: 19.6650864 },
    { lat: 49.284062, lng: 19.665382 },
    { lat: 49.2839002, lng: 19.6655925 },
    { lat: 49.2833881, lng: 19.6662711 },
    { lat: 49.2831738, lng: 19.6665159 },
    { lat: 49.2829869, lng: 19.6667004 },
    { lat: 49.2827007, lng: 19.6670032 },
    { lat: 49.2825809, lng: 19.6671223 },
    { lat: 49.2824237, lng: 19.6672898 },
    { lat: 49.2821866, lng: 19.6675268 },
    { lat: 49.2816016, lng: 19.6681367 },
    { lat: 49.2814604, lng: 19.6682791 },
    { lat: 49.2812567, lng: 19.6683596 },
    { lat: 49.2807453, lng: 19.6685878 },
    { lat: 49.2806354, lng: 19.6686294 },
    { lat: 49.280032, lng: 19.6686592 },
    { lat: 49.2798542, lng: 19.6686642 },
    { lat: 49.2795438, lng: 19.6686804 },
    { lat: 49.2791388, lng: 19.6687153 },
    { lat: 49.2781648, lng: 19.6688288 },
    { lat: 49.2778307, lng: 19.6688633 },
    { lat: 49.2775494, lng: 19.6688974 },
    { lat: 49.2772655, lng: 19.6689267 },
    { lat: 49.2769642, lng: 19.6689625 },
    { lat: 49.2769015, lng: 19.6689751 },
    { lat: 49.2768217, lng: 19.6689534 },
    { lat: 49.2765633, lng: 19.6688642 },
    { lat: 49.2761438, lng: 19.668725 },
    { lat: 49.275777, lng: 19.6686087 },
    { lat: 49.2757093, lng: 19.6685833 },
    { lat: 49.2752887, lng: 19.6684463 },
    { lat: 49.2755638, lng: 19.6698275 },
    { lat: 49.275884, lng: 19.6714924 },
    { lat: 49.2762362, lng: 19.6731703 },
    { lat: 49.276405, lng: 19.6740578 },
    { lat: 49.2768296, lng: 19.6762226 },
    { lat: 49.2770978, lng: 19.677606 },
    { lat: 49.2774563, lng: 19.6793486 },
    { lat: 49.2775112, lng: 19.6796251 },
    { lat: 49.2779643, lng: 19.6818274 },
    { lat: 49.2780727, lng: 19.6823694 },
    { lat: 49.2780903, lng: 19.6823618 },
    { lat: 49.2785915, lng: 19.682486 },
    { lat: 49.2801905, lng: 19.6816275 },
    { lat: 49.2806708, lng: 19.6812504 },
    { lat: 49.2809031, lng: 19.6809497 },
    { lat: 49.2825884, lng: 19.679209 },
    { lat: 49.2829107, lng: 19.6790355 },
    { lat: 49.283268, lng: 19.6785288 },
    { lat: 49.2839517, lng: 19.6777118 },
    { lat: 49.2843056, lng: 19.6769731 },
    { lat: 49.2852708, lng: 19.6765066 },
    { lat: 49.2859259, lng: 19.6761843 },
    { lat: 49.2866396, lng: 19.6753572 },
    { lat: 49.2872085, lng: 19.6744624 },
    { lat: 49.2878965, lng: 19.6739461 },
    { lat: 49.2882167, lng: 19.6736439 },
    { lat: 49.2883072, lng: 19.6735541 },
    { lat: 49.288321, lng: 19.6729951 },
    { lat: 49.2882641, lng: 19.6725783 },
    { lat: 49.288246, lng: 19.6721897 },
    { lat: 49.2883379, lng: 19.6713141 },
    { lat: 49.2885563, lng: 19.6705719 },
    { lat: 49.2889875, lng: 19.6693126 },
    { lat: 49.2897915, lng: 19.6684144 },
    { lat: 49.290174, lng: 19.6674333 },
    { lat: 49.2902357, lng: 19.6674226 },
    { lat: 49.2907339, lng: 19.6671748 },
    { lat: 49.291552, lng: 19.6667973 },
    { lat: 49.2918732, lng: 19.6667265 },
    { lat: 49.2923735, lng: 19.6666262 },
    { lat: 49.2932027, lng: 19.6664207 },
    { lat: 49.2939902, lng: 19.6662444 },
    { lat: 49.294817, lng: 19.6660378 },
    { lat: 49.2955065, lng: 19.6658882 },
    { lat: 49.2963413, lng: 19.6657348 },
    { lat: 49.2971234, lng: 19.6655891 },
    { lat: 49.2976729, lng: 19.6654803 },
    { lat: 49.2986399, lng: 19.6653115 },
    { lat: 49.2990799, lng: 19.6652249 },
    { lat: 49.2997459, lng: 19.6651034 },
    { lat: 49.3006583, lng: 19.6648958 },
    { lat: 49.3018049, lng: 19.6657296 },
    { lat: 49.302363, lng: 19.666152 },
    { lat: 49.3028078, lng: 19.666479 },
    { lat: 49.3033036, lng: 19.6668393 },
    { lat: 49.3034922, lng: 19.6669235 },
    { lat: 49.3046522, lng: 19.6674257 },
    { lat: 49.3047967, lng: 19.6674855 },
    { lat: 49.3048332, lng: 19.6671146 },
    { lat: 49.3049573, lng: 19.6660173 },
    { lat: 49.3050367, lng: 19.6652668 },
    { lat: 49.30544, lng: 19.6639641 },
    { lat: 49.3058628, lng: 19.662698 },
    { lat: 49.3061071, lng: 19.6619832 },
    { lat: 49.3064008, lng: 19.6612611 },
    { lat: 49.3065785, lng: 19.6608719 },
    { lat: 49.3067936, lng: 19.6603865 },
    { lat: 49.3068883, lng: 19.6601359 },
    { lat: 49.3070435, lng: 19.6598223 },
    { lat: 49.3074608, lng: 19.6589152 },
    { lat: 49.3077147, lng: 19.6583669 },
    { lat: 49.3077923, lng: 19.6582123 },
    { lat: 49.3079421, lng: 19.6579318 },
    { lat: 49.30805, lng: 19.6577191 },
    { lat: 49.3085255, lng: 19.6567532 },
    { lat: 49.3086181, lng: 19.6565581 },
    { lat: 49.3087774, lng: 19.6562372 },
    { lat: 49.3089322, lng: 19.6559165 },
    { lat: 49.3090191, lng: 19.6556381 },
    { lat: 49.3091314, lng: 19.6552485 },
    { lat: 49.3091917, lng: 19.6550588 },
    { lat: 49.3092926, lng: 19.6547126 },
    { lat: 49.309417, lng: 19.6543712 },
    { lat: 49.3095288, lng: 19.6539974 },
    { lat: 49.309684, lng: 19.6534008 },
    { lat: 49.3097302, lng: 19.6531982 },
    { lat: 49.309832, lng: 19.65265 },
    { lat: 49.3099849, lng: 19.6519294 },
    { lat: 49.3101093, lng: 19.6511767 },
    { lat: 49.3101294, lng: 19.6510706 },
    { lat: 49.310263, lng: 19.6502711 },
    { lat: 49.3102948, lng: 19.6501248 },
    { lat: 49.3103977, lng: 19.6495231 },
    { lat: 49.3104589, lng: 19.6491517 },
    { lat: 49.3104996, lng: 19.6487493 },
    { lat: 49.3105175, lng: 19.6485178 },
    { lat: 49.310562, lng: 19.6482489 },
    { lat: 49.3105959, lng: 19.6479226 },
    { lat: 49.3106389, lng: 19.6477202 },
    { lat: 49.3107207, lng: 19.6474095 },
    { lat: 49.310794, lng: 19.6471831 },
    { lat: 49.310842, lng: 19.6469662 },
    { lat: 49.3109255, lng: 19.6466662 },
    { lat: 49.3110087, lng: 19.646308 },
    { lat: 49.3110262, lng: 19.6462174 },
    { lat: 49.3110309, lng: 19.6460685 },
    { lat: 49.3110551, lng: 19.6459046 },
    { lat: 49.3112074, lng: 19.6451457 },
    { lat: 49.311265, lng: 19.6447455 },
    { lat: 49.3112882, lng: 19.6446087 },
    { lat: 49.3113378, lng: 19.6444021 },
    { lat: 49.3114683, lng: 19.6438907 },
    { lat: 49.3114935, lng: 19.6437772 },
    { lat: 49.311644, lng: 19.6438168 },
    { lat: 49.3116946, lng: 19.6438394 },
    { lat: 49.3118907, lng: 19.6438382 },
    { lat: 49.3119749, lng: 19.6438103 },
    { lat: 49.312069, lng: 19.6437973 },
    { lat: 49.3121902, lng: 19.6437342 },
    { lat: 49.3123306, lng: 19.6437236 },
    { lat: 49.3124552, lng: 19.6436908 },
    { lat: 49.3126995, lng: 19.643556 },
    { lat: 49.3131507, lng: 19.6434666 },
    { lat: 49.3133484, lng: 19.6433316 },
    { lat: 49.3134981, lng: 19.6432742 },
    { lat: 49.3137248, lng: 19.6431535 },
    { lat: 49.313861, lng: 19.6430993 },
    { lat: 49.314009, lng: 19.6430623 },
    { lat: 49.3141025, lng: 19.6429818 },
    { lat: 49.3142268, lng: 19.6429696 },
    { lat: 49.3146378, lng: 19.6428103 },
    { lat: 49.3148333, lng: 19.6427731 },
    { lat: 49.3149522, lng: 19.6427607 },
    { lat: 49.3151014, lng: 19.6427712 },
    { lat: 49.3151015, lng: 19.6427463 },
    { lat: 49.3152173, lng: 19.642744 },
    { lat: 49.3153652, lng: 19.6427525 },
    { lat: 49.3156689, lng: 19.6429192 },
    { lat: 49.3157609, lng: 19.6429599 },
    { lat: 49.316209, lng: 19.6429574 },
    { lat: 49.3164433, lng: 19.64298 },
    { lat: 49.3165048, lng: 19.6429939 },
    { lat: 49.3166622, lng: 19.6430617 },
    { lat: 49.3169245, lng: 19.6431854 },
    { lat: 49.3171099, lng: 19.6433041 },
    { lat: 49.317273, lng: 19.6433984 },
    { lat: 49.3174684, lng: 19.6434619 },
    { lat: 49.3176102, lng: 19.6435346 },
    { lat: 49.317751, lng: 19.6435301 },
    { lat: 49.317969, lng: 19.6435446 },
    { lat: 49.3180781, lng: 19.6435669 },
    { lat: 49.3185074, lng: 19.6436971 },
    { lat: 49.3186923, lng: 19.6436978 },
    { lat: 49.3188877, lng: 19.6436104 },
    { lat: 49.3188866, lng: 19.6435873 },
    { lat: 49.3189824, lng: 19.6435315 },
    { lat: 49.3192497, lng: 19.6433622 },
    { lat: 49.3193345, lng: 19.6432942 },
    { lat: 49.3195224, lng: 19.6431086 },
    { lat: 49.319989, lng: 19.6426853 },
    { lat: 49.3201959, lng: 19.6423962 },
    { lat: 49.3203532, lng: 19.6421047 },
    { lat: 49.3207812, lng: 19.6409873 },
    { lat: 49.321169, lng: 19.6402085 },
    { lat: 49.3212605, lng: 19.6400376 },
    { lat: 49.3212909, lng: 19.6399553 },
  ],
  Čimhová: [
    { lat: 49.3417022, lng: 19.7211824 },
    { lat: 49.3419687, lng: 19.7212758 },
    { lat: 49.3420937, lng: 19.7213479 },
    { lat: 49.3421557, lng: 19.7214016 },
    { lat: 49.3422659, lng: 19.7214275 },
    { lat: 49.3424297, lng: 19.7214379 },
    { lat: 49.3426126, lng: 19.721495 },
    { lat: 49.3426827, lng: 19.7215287 },
    { lat: 49.3431379, lng: 19.722072 },
    { lat: 49.3432705, lng: 19.7220639 },
    { lat: 49.3436522, lng: 19.7219559 },
    { lat: 49.3440117, lng: 19.7217287 },
    { lat: 49.3441335, lng: 19.7217065 },
    { lat: 49.3447147, lng: 19.7217833 },
    { lat: 49.3449035, lng: 19.7216967 },
    { lat: 49.3451573, lng: 19.7217799 },
    { lat: 49.3454778, lng: 19.7217934 },
    { lat: 49.345615, lng: 19.721818 },
    { lat: 49.3457878, lng: 19.7217458 },
    { lat: 49.3459082, lng: 19.7216655 },
    { lat: 49.3460831, lng: 19.7215197 },
    { lat: 49.3462751, lng: 19.7212992 },
    { lat: 49.3465257, lng: 19.7209473 },
    { lat: 49.3466599, lng: 19.7208476 },
    { lat: 49.3468331, lng: 19.7206334 },
    { lat: 49.3470383, lng: 19.7203074 },
    { lat: 49.3475951, lng: 19.7198722 },
    { lat: 49.3476307, lng: 19.7198386 },
    { lat: 49.3476693, lng: 19.7198682 },
    { lat: 49.3482252, lng: 19.7201181 },
    { lat: 49.3487755, lng: 19.7205152 },
    { lat: 49.3487884, lng: 19.7205287 },
    { lat: 49.3489184, lng: 19.7206137 },
    { lat: 49.3493996, lng: 19.7209033 },
    { lat: 49.349502, lng: 19.7207187 },
    { lat: 49.3498234, lng: 19.720727 },
    { lat: 49.3502424, lng: 19.7201104 },
    { lat: 49.3506407, lng: 19.7198647 },
    { lat: 49.3510313, lng: 19.7196792 },
    { lat: 49.3511762, lng: 19.7196181 },
    { lat: 49.3512519, lng: 19.7197509 },
    { lat: 49.3513891, lng: 19.7196187 },
    { lat: 49.3519205, lng: 19.7191213 },
    { lat: 49.3520512, lng: 19.7189912 },
    { lat: 49.3523923, lng: 19.718677 },
    { lat: 49.3522107, lng: 19.7183621 },
    { lat: 49.352155, lng: 19.7182478 },
    { lat: 49.351906, lng: 19.7176815 },
    { lat: 49.3517416, lng: 19.7171924 },
    { lat: 49.3519326, lng: 19.7171288 },
    { lat: 49.3521912, lng: 19.7170124 },
    { lat: 49.3524626, lng: 19.7168339 },
    { lat: 49.3526723, lng: 19.716657 },
    { lat: 49.3526059, lng: 19.7164763 },
    { lat: 49.3529707, lng: 19.716371 },
    { lat: 49.3531458, lng: 19.716223 },
    { lat: 49.3531261, lng: 19.7159309 },
    { lat: 49.3534689, lng: 19.7158065 },
    { lat: 49.3535868, lng: 19.7157721 },
    { lat: 49.353472, lng: 19.7154676 },
    { lat: 49.3537565, lng: 19.7149937 },
    { lat: 49.3539794, lng: 19.7149365 },
    { lat: 49.3541761, lng: 19.7145908 },
    { lat: 49.3544255, lng: 19.7141403 },
    { lat: 49.3545201, lng: 19.7139832 },
    { lat: 49.3545977, lng: 19.7138253 },
    { lat: 49.354792, lng: 19.7138148 },
    { lat: 49.3551847, lng: 19.713802 },
    { lat: 49.3556115, lng: 19.7137742 },
    { lat: 49.3556309, lng: 19.7139066 },
    { lat: 49.3557444, lng: 19.7144927 },
    { lat: 49.3559431, lng: 19.7144752 },
    { lat: 49.3561916, lng: 19.7144429 },
    { lat: 49.3565841, lng: 19.7144308 },
    { lat: 49.3569402, lng: 19.7144822 },
    { lat: 49.3574742, lng: 19.7145865 },
    { lat: 49.3578358, lng: 19.7146387 },
    { lat: 49.3580979, lng: 19.7146581 },
    { lat: 49.3582259, lng: 19.714723 },
    { lat: 49.3585593, lng: 19.7149306 },
    { lat: 49.3587572, lng: 19.7150733 },
    { lat: 49.3589504, lng: 19.7152429 },
    { lat: 49.3592297, lng: 19.7154506 },
    { lat: 49.3593385, lng: 19.7155143 },
    { lat: 49.3593628, lng: 19.7156325 },
    { lat: 49.3594411, lng: 19.7155927 },
    { lat: 49.3597536, lng: 19.7154526 },
    { lat: 49.3598793, lng: 19.7154394 },
    { lat: 49.3610111, lng: 19.7156635 },
    { lat: 49.3611885, lng: 19.715679 },
    { lat: 49.3613618, lng: 19.7156735 },
    { lat: 49.3614119, lng: 19.7156579 },
    { lat: 49.3615519, lng: 19.7155615 },
    { lat: 49.3616997, lng: 19.7153899 },
    { lat: 49.3618106, lng: 19.7150164 },
    { lat: 49.3617739, lng: 19.714868 },
    { lat: 49.3615935, lng: 19.714559 },
    { lat: 49.3611754, lng: 19.7139857 },
    { lat: 49.3611568, lng: 19.7138178 },
    { lat: 49.3611535, lng: 19.7136681 },
    { lat: 49.3611655, lng: 19.7135534 },
    { lat: 49.3612217, lng: 19.7131924 },
    { lat: 49.3613254, lng: 19.7128048 },
    { lat: 49.3613388, lng: 19.7127225 },
    { lat: 49.3613396, lng: 19.7126639 },
    { lat: 49.3613249, lng: 19.712588 },
    { lat: 49.3612465, lng: 19.7124421 },
    { lat: 49.3611525, lng: 19.7122237 },
    { lat: 49.3610497, lng: 19.7118812 },
    { lat: 49.3609461, lng: 19.7113959 },
    { lat: 49.3608766, lng: 19.7109896 },
    { lat: 49.3608688, lng: 19.7108379 },
    { lat: 49.3609155, lng: 19.7106951 },
    { lat: 49.3609679, lng: 19.7106051 },
    { lat: 49.3611361, lng: 19.7104336 },
    { lat: 49.3613657, lng: 19.7103515 },
    { lat: 49.3616702, lng: 19.7103564 },
    { lat: 49.3619953, lng: 19.7102988 },
    { lat: 49.3622374, lng: 19.7100864 },
    { lat: 49.3624142, lng: 19.7098551 },
    { lat: 49.3624569, lng: 19.709777 },
    { lat: 49.3625534, lng: 19.7092532 },
    { lat: 49.3626205, lng: 19.7089572 },
    { lat: 49.3627163, lng: 19.7086007 },
    { lat: 49.3628829, lng: 19.7084152 },
    { lat: 49.3630266, lng: 19.7083499 },
    { lat: 49.3634238, lng: 19.7082686 },
    { lat: 49.363571, lng: 19.7082543 },
    { lat: 49.3637421, lng: 19.7080752 },
    { lat: 49.3638678, lng: 19.7078852 },
    { lat: 49.3640047, lng: 19.7074584 },
    { lat: 49.3640444, lng: 19.7072866 },
    { lat: 49.3640426, lng: 19.7070128 },
    { lat: 49.3640218, lng: 19.7068719 },
    { lat: 49.363936, lng: 19.7064542 },
    { lat: 49.3639183, lng: 19.7063082 },
    { lat: 49.3639906, lng: 19.7062895 },
    { lat: 49.3640456, lng: 19.706229 },
    { lat: 49.3661883, lng: 19.7053364 },
    { lat: 49.3662723, lng: 19.7035434 },
    { lat: 49.3662792, lng: 19.7033941 },
    { lat: 49.3666988, lng: 19.702331 },
    { lat: 49.3665728, lng: 19.7015347 },
    { lat: 49.3664662, lng: 19.700873 },
    { lat: 49.3663886, lng: 19.7003894 },
    { lat: 49.3662823, lng: 19.700173 },
    { lat: 49.3659632, lng: 19.6995381 },
    { lat: 49.3659199, lng: 19.6994375 },
    { lat: 49.3657595, lng: 19.6991189 },
    { lat: 49.3657044, lng: 19.6982976 },
    { lat: 49.3656049, lng: 19.6968516 },
    { lat: 49.365292, lng: 19.6961876 },
    { lat: 49.3647818, lng: 19.6948544 },
    { lat: 49.3645904, lng: 19.694392 },
    { lat: 49.3645213, lng: 19.6934095 },
    { lat: 49.365024, lng: 19.6932142 },
    { lat: 49.3647226, lng: 19.6909404 },
    { lat: 49.3646568, lng: 19.6905966 },
    { lat: 49.3646263, lng: 19.6904392 },
    { lat: 49.3645972, lng: 19.690289 },
    { lat: 49.364542, lng: 19.6900297 },
    { lat: 49.3644071, lng: 19.6893992 },
    { lat: 49.364319, lng: 19.688985 },
    { lat: 49.3641456, lng: 19.6889331 },
    { lat: 49.3641251, lng: 19.6888261 },
    { lat: 49.3642327, lng: 19.6885823 },
    { lat: 49.3640265, lng: 19.6885052 },
    { lat: 49.3639025, lng: 19.6880889 },
    { lat: 49.3637541, lng: 19.687818 },
    { lat: 49.363702, lng: 19.6877608 },
    { lat: 49.3635834, lng: 19.6877513 },
    { lat: 49.3634249, lng: 19.6878345 },
    { lat: 49.3631788, lng: 19.6877728 },
    { lat: 49.3629672, lng: 19.6878791 },
    { lat: 49.3627775, lng: 19.6880198 },
    { lat: 49.3626266, lng: 19.688074 },
    { lat: 49.3625756, lng: 19.6880673 },
    { lat: 49.3625134, lng: 19.6880647 },
    { lat: 49.3622703, lng: 19.6879628 },
    { lat: 49.3620322, lng: 19.6878041 },
    { lat: 49.3618697, lng: 19.6875932 },
    { lat: 49.3617362, lng: 19.6873481 },
    { lat: 49.3616653, lng: 19.6872698 },
    { lat: 49.3615009, lng: 19.6869879 },
    { lat: 49.3614143, lng: 19.6868923 },
    { lat: 49.3611906, lng: 19.6866971 },
    { lat: 49.3610601, lng: 19.6865457 },
    { lat: 49.3609929, lng: 19.6864294 },
    { lat: 49.36091, lng: 19.686284 },
    { lat: 49.3608849, lng: 19.6862009 },
    { lat: 49.3608693, lng: 19.6860981 },
    { lat: 49.3608729, lng: 19.6859286 },
    { lat: 49.3608569, lng: 19.6856667 },
    { lat: 49.360855, lng: 19.6855522 },
    { lat: 49.3608487, lng: 19.6851746 },
    { lat: 49.3608626, lng: 19.6848465 },
    { lat: 49.3608861, lng: 19.6847147 },
    { lat: 49.3609377, lng: 19.6839287 },
    { lat: 49.3609717, lng: 19.6836465 },
    { lat: 49.361056, lng: 19.6829685 },
    { lat: 49.3610607, lng: 19.682688 },
    { lat: 49.3610477, lng: 19.6820861 },
    { lat: 49.3609413, lng: 19.6814859 },
    { lat: 49.3609157, lng: 19.6814184 },
    { lat: 49.3608406, lng: 19.6812206 },
    { lat: 49.3607093, lng: 19.6810653 },
    { lat: 49.3606607, lng: 19.6809597 },
    { lat: 49.3606161, lng: 19.6808844 },
    { lat: 49.3604926, lng: 19.680686 },
    { lat: 49.3603128, lng: 19.680446 },
    { lat: 49.3601539, lng: 19.6802109 },
    { lat: 49.3598883, lng: 19.6796526 },
    { lat: 49.359608, lng: 19.6792607 },
    { lat: 49.3594509, lng: 19.6790068 },
    { lat: 49.3593485, lng: 19.6789028 },
    { lat: 49.3587734, lng: 19.6785585 },
    { lat: 49.3585206, lng: 19.6782369 },
    { lat: 49.3583601, lng: 19.6779958 },
    { lat: 49.3583106, lng: 19.6778954 },
    { lat: 49.3582868, lng: 19.6777637 },
    { lat: 49.3582387, lng: 19.6772335 },
    { lat: 49.3581227, lng: 19.6769516 },
    { lat: 49.3581205, lng: 19.6768749 },
    { lat: 49.3581548, lng: 19.6767383 },
    { lat: 49.358161, lng: 19.6766484 },
    { lat: 49.3581402, lng: 19.6765837 },
    { lat: 49.358088, lng: 19.6765226 },
    { lat: 49.3580156, lng: 19.6764126 },
    { lat: 49.3578727, lng: 19.6759822 },
    { lat: 49.3577744, lng: 19.6756414 },
    { lat: 49.3577082, lng: 19.6754733 },
    { lat: 49.3575466, lng: 19.6756638 },
    { lat: 49.3574191, lng: 19.6757366 },
    { lat: 49.3570999, lng: 19.6758823 },
    { lat: 49.3565785, lng: 19.6762151 },
    { lat: 49.3565193, lng: 19.676179 },
    { lat: 49.3564952, lng: 19.6761952 },
    { lat: 49.3564829, lng: 19.6762731 },
    { lat: 49.3558937, lng: 19.6765521 },
    { lat: 49.3555554, lng: 19.6774431 },
    { lat: 49.3554752, lng: 19.677459 },
    { lat: 49.3552217, lng: 19.677895 },
    { lat: 49.3549078, lng: 19.6780567 },
    { lat: 49.3548945, lng: 19.6780603 },
    { lat: 49.3547502, lng: 19.6780856 },
    { lat: 49.354671, lng: 19.6780786 },
    { lat: 49.3545727, lng: 19.6780425 },
    { lat: 49.3543993, lng: 19.6779216 },
    { lat: 49.3542356, lng: 19.6778865 },
    { lat: 49.353978, lng: 19.6778704 },
    { lat: 49.353788, lng: 19.6779775 },
    { lat: 49.3536137, lng: 19.6781902 },
    { lat: 49.3535688, lng: 19.6782971 },
    { lat: 49.3535236, lng: 19.6784485 },
    { lat: 49.3534255, lng: 19.6787257 },
    { lat: 49.3533798, lng: 19.6788131 },
    { lat: 49.353304, lng: 19.6788827 },
    { lat: 49.3530945, lng: 19.6789875 },
    { lat: 49.3528373, lng: 19.6789298 },
    { lat: 49.3525103, lng: 19.6787677 },
    { lat: 49.3523083, lng: 19.6787105 },
    { lat: 49.3521891, lng: 19.6787101 },
    { lat: 49.3520796, lng: 19.6787612 },
    { lat: 49.3519573, lng: 19.678845 },
    { lat: 49.3518941, lng: 19.6789212 },
    { lat: 49.3517579, lng: 19.6792052 },
    { lat: 49.3516398, lng: 19.6795155 },
    { lat: 49.3515769, lng: 19.6798512 },
    { lat: 49.3514136, lng: 19.6801856 },
    { lat: 49.3513321, lng: 19.6803548 },
    { lat: 49.3512536, lng: 19.6806473 },
    { lat: 49.3512089, lng: 19.6809887 },
    { lat: 49.3511765, lng: 19.6810946 },
    { lat: 49.3511263, lng: 19.6812086 },
    { lat: 49.3510988, lng: 19.6812775 },
    { lat: 49.3510392, lng: 19.6814571 },
    { lat: 49.3509845, lng: 19.6816236 },
    { lat: 49.350962, lng: 19.6817513 },
    { lat: 49.3509612, lng: 19.6818608 },
    { lat: 49.3509371, lng: 19.6820071 },
    { lat: 49.3508586, lng: 19.6822938 },
    { lat: 49.3507754, lng: 19.6825044 },
    { lat: 49.3506557, lng: 19.6827032 },
    { lat: 49.350583, lng: 19.6828422 },
    { lat: 49.3505653, lng: 19.6829022 },
    { lat: 49.3505534, lng: 19.6829916 },
    { lat: 49.3505429, lng: 19.6830862 },
    { lat: 49.3505179, lng: 19.6832843 },
    { lat: 49.3504897, lng: 19.6834119 },
    { lat: 49.3504572, lng: 19.6837726 },
    { lat: 49.3504027, lng: 19.6840463 },
    { lat: 49.3503382, lng: 19.6841913 },
    { lat: 49.3502903, lng: 19.6843578 },
    { lat: 49.3502914, lng: 19.6845109 },
    { lat: 49.3503067, lng: 19.684914 },
    { lat: 49.3502788, lng: 19.6852493 },
    { lat: 49.3502251, lng: 19.6855157 },
    { lat: 49.3501464, lng: 19.6857225 },
    { lat: 49.3500401, lng: 19.685921 },
    { lat: 49.3499597, lng: 19.6860612 },
    { lat: 49.3498866, lng: 19.6862458 },
    { lat: 49.349848, lng: 19.6864145 },
    { lat: 49.3498246, lng: 19.6866568 },
    { lat: 49.3498043, lng: 19.687167 },
    { lat: 49.3497842, lng: 19.6873493 },
    { lat: 49.3497824, lng: 19.6874915 },
    { lat: 49.3497924, lng: 19.6875617 },
    { lat: 49.3498176, lng: 19.6876191 },
    { lat: 49.3499202, lng: 19.6879091 },
    { lat: 49.3499442, lng: 19.6880197 },
    { lat: 49.3499484, lng: 19.6881624 },
    { lat: 49.3499335, lng: 19.6882868 },
    { lat: 49.3499039, lng: 19.6884073 },
    { lat: 49.3498482, lng: 19.6884983 },
    { lat: 49.349791, lng: 19.6885569 },
    { lat: 49.3497525, lng: 19.6886537 },
    { lat: 49.3497276, lng: 19.6887531 },
    { lat: 49.3496906, lng: 19.6888888 },
    { lat: 49.349647, lng: 19.6890458 },
    { lat: 49.3496052, lng: 19.689277 },
    { lat: 49.3495857, lng: 19.6894243 },
    { lat: 49.3495592, lng: 19.6896359 },
    { lat: 49.3495512, lng: 19.6897136 },
    { lat: 49.3495319, lng: 19.6898652 },
    { lat: 49.3495036, lng: 19.6899892 },
    { lat: 49.3494202, lng: 19.6901701 },
    { lat: 49.3492939, lng: 19.6903417 },
    { lat: 49.3491665, lng: 19.690473 },
    { lat: 49.3490601, lng: 19.6906353 },
    { lat: 49.3489477, lng: 19.6907195 },
    { lat: 49.3488682, lng: 19.6907581 },
    { lat: 49.3486491, lng: 19.6907683 },
    { lat: 49.3481721, lng: 19.6907058 },
    { lat: 49.3480695, lng: 19.6907087 },
    { lat: 49.3480288, lng: 19.6907259 },
    { lat: 49.3477029, lng: 19.690977 },
    { lat: 49.3475702, lng: 19.6911572 },
    { lat: 49.3475543, lng: 19.6911823 },
    { lat: 49.3474458, lng: 19.6913785 },
    { lat: 49.3473766, lng: 19.6914954 },
    { lat: 49.3473524, lng: 19.6915366 },
    { lat: 49.3473386, lng: 19.6915832 },
    { lat: 49.3472878, lng: 19.6918597 },
    { lat: 49.3472727, lng: 19.6919256 },
    { lat: 49.3472488, lng: 19.6919732 },
    { lat: 49.34716, lng: 19.6920781 },
    { lat: 49.3471332, lng: 19.6921141 },
    { lat: 49.3471038, lng: 19.6921319 },
    { lat: 49.3470742, lng: 19.692131 },
    { lat: 49.3467895, lng: 19.6920591 },
    { lat: 49.3465992, lng: 19.6920097 },
    { lat: 49.3465634, lng: 19.6920119 },
    { lat: 49.3465209, lng: 19.6920448 },
    { lat: 49.3463873, lng: 19.6921836 },
    { lat: 49.346179, lng: 19.6923925 },
    { lat: 49.3461498, lng: 19.6924208 },
    { lat: 49.3461234, lng: 19.6924586 },
    { lat: 49.3460701, lng: 19.6926057 },
    { lat: 49.3460102, lng: 19.6927568 },
    { lat: 49.3459737, lng: 19.6928508 },
    { lat: 49.3459277, lng: 19.6929795 },
    { lat: 49.345899, lng: 19.6931219 },
    { lat: 49.3458769, lng: 19.6932116 },
    { lat: 49.3458512, lng: 19.6933414 },
    { lat: 49.3458252, lng: 19.6934684 },
    { lat: 49.3458084, lng: 19.6935482 },
    { lat: 49.3457816, lng: 19.6937341 },
    { lat: 49.3456867, lng: 19.6939061 },
    { lat: 49.3455679, lng: 19.6939747 },
    { lat: 49.3453343, lng: 19.6941436 },
    { lat: 49.3452656, lng: 19.6941922 },
    { lat: 49.3450852, lng: 19.6942396 },
    { lat: 49.3450651, lng: 19.694271 },
    { lat: 49.3450475, lng: 19.6943313 },
    { lat: 49.3450468, lng: 19.6943899 },
    { lat: 49.34506, lng: 19.694567 },
    { lat: 49.3450552, lng: 19.6948136 },
    { lat: 49.3449836, lng: 19.695033 },
    { lat: 49.3449015, lng: 19.6951906 },
    { lat: 49.3447449, lng: 19.695343 },
    { lat: 49.3446083, lng: 19.6954629 },
    { lat: 49.3445295, lng: 19.6955931 },
    { lat: 49.3444587, lng: 19.6957759 },
    { lat: 49.344445, lng: 19.6958261 },
    { lat: 49.3444326, lng: 19.6959784 },
    { lat: 49.3444497, lng: 19.6961089 },
    { lat: 49.3445479, lng: 19.6962718 },
    { lat: 49.3445884, lng: 19.6963703 },
    { lat: 49.3446044, lng: 19.696455 },
    { lat: 49.3445954, lng: 19.6965307 },
    { lat: 49.3445452, lng: 19.6966937 },
    { lat: 49.3443943, lng: 19.696977 },
    { lat: 49.3442641, lng: 19.6971948 },
    { lat: 49.344117, lng: 19.6974141 },
    { lat: 49.3440432, lng: 19.6975022 },
    { lat: 49.3438988, lng: 19.6976222 },
    { lat: 49.343777, lng: 19.6976979 },
    { lat: 49.3433331, lng: 19.6978519 },
    { lat: 49.3432067, lng: 19.6978989 },
    { lat: 49.3431649, lng: 19.6979191 },
    { lat: 49.3431351, lng: 19.6979344 },
    { lat: 49.3430727, lng: 19.6979748 },
    { lat: 49.3429873, lng: 19.6980836 },
    { lat: 49.3428969, lng: 19.698284 },
    { lat: 49.3428512, lng: 19.6983715 },
    { lat: 49.3428103, lng: 19.6984136 },
    { lat: 49.3424318, lng: 19.6986479 },
    { lat: 49.3421312, lng: 19.6992153 },
    { lat: 49.3419336, lng: 19.6996944 },
    { lat: 49.3418065, lng: 19.7000044 },
    { lat: 49.3416801, lng: 19.7002544 },
    { lat: 49.3414633, lng: 19.7005541 },
    { lat: 49.341319, lng: 19.700682 },
    { lat: 49.3409514, lng: 19.7011872 },
    { lat: 49.3409062, lng: 19.7012858 },
    { lat: 49.3407532, lng: 19.7017552 },
    { lat: 49.3407057, lng: 19.7019534 },
    { lat: 49.3406698, lng: 19.7020333 },
    { lat: 49.3406387, lng: 19.7020726 },
    { lat: 49.3404744, lng: 19.7020985 },
    { lat: 49.3403113, lng: 19.7020549 },
    { lat: 49.3402524, lng: 19.7020476 },
    { lat: 49.3402285, lng: 19.702067 },
    { lat: 49.3402103, lng: 19.7021376 },
    { lat: 49.3401587, lng: 19.7024033 },
    { lat: 49.3401218, lng: 19.7025097 },
    { lat: 49.3400054, lng: 19.702682 },
    { lat: 49.3399074, lng: 19.7028641 },
    { lat: 49.3396773, lng: 19.7036243 },
    { lat: 49.3396542, lng: 19.7036642 },
    { lat: 49.3396246, lng: 19.7036831 },
    { lat: 49.3395804, lng: 19.7036765 },
    { lat: 49.339522, lng: 19.7036583 },
    { lat: 49.3394203, lng: 19.7036206 },
    { lat: 49.3393366, lng: 19.7036184 },
    { lat: 49.3392886, lng: 19.7036259 },
    { lat: 49.3392375, lng: 19.7036672 },
    { lat: 49.339193, lng: 19.7037076 },
    { lat: 49.3390383, lng: 19.7039048 },
    { lat: 49.3389384, lng: 19.7039909 },
    { lat: 49.3387168, lng: 19.7041 },
    { lat: 49.338511, lng: 19.7042345 },
    { lat: 49.3384514, lng: 19.7042374 },
    { lat: 49.3383859, lng: 19.7042343 },
    { lat: 49.3383161, lng: 19.7042375 },
    { lat: 49.3382081, lng: 19.7042869 },
    { lat: 49.3380835, lng: 19.7043748 },
    { lat: 49.3378997, lng: 19.7045761 },
    { lat: 49.337699, lng: 19.7046234 },
    { lat: 49.3375503, lng: 19.7046239 },
    { lat: 49.3374298, lng: 19.7046742 },
    { lat: 49.3372665, lng: 19.7048044 },
    { lat: 49.3372214, lng: 19.7048585 },
    { lat: 49.3370758, lng: 19.7050818 },
    { lat: 49.3370308, lng: 19.7051107 },
    { lat: 49.3370175, lng: 19.7051142 },
    { lat: 49.3370201, lng: 19.7051732 },
    { lat: 49.3370443, lng: 19.7055196 },
    { lat: 49.3368175, lng: 19.706002 },
    { lat: 49.3367963, lng: 19.7062881 },
    { lat: 49.3368706, lng: 19.706593 },
    { lat: 49.3370447, lng: 19.7070974 },
    { lat: 49.337039, lng: 19.7072747 },
    { lat: 49.3369264, lng: 19.7075871 },
    { lat: 49.3369475, lng: 19.7078858 },
    { lat: 49.3371372, lng: 19.7082078 },
    { lat: 49.3372559, lng: 19.7086273 },
    { lat: 49.3371861, lng: 19.7088876 },
    { lat: 49.3371119, lng: 19.7092547 },
    { lat: 49.3371545, lng: 19.7096596 },
    { lat: 49.3371282, lng: 19.7102146 },
    { lat: 49.3372687, lng: 19.7106741 },
    { lat: 49.3373122, lng: 19.7109984 },
    { lat: 49.3373434, lng: 19.7115832 },
    { lat: 49.3373564, lng: 19.7116206 },
    { lat: 49.3374861, lng: 19.7117258 },
    { lat: 49.3377274, lng: 19.7121966 },
    { lat: 49.3381312, lng: 19.712955 },
    { lat: 49.3385109, lng: 19.7137304 },
    { lat: 49.3386039, lng: 19.7140279 },
    { lat: 49.338891, lng: 19.7151353 },
    { lat: 49.338921, lng: 19.7154592 },
    { lat: 49.3390307, lng: 19.7162958 },
    { lat: 49.3391115, lng: 19.7168532 },
    { lat: 49.3391273, lng: 19.7171391 },
    { lat: 49.33923, lng: 19.717708 },
    { lat: 49.3392213, lng: 19.7182316 },
    { lat: 49.3394281, lng: 19.7186873 },
    { lat: 49.3395798, lng: 19.7188835 },
    { lat: 49.3396547, lng: 19.7189715 },
    { lat: 49.3397682, lng: 19.7192522 },
    { lat: 49.3398462, lng: 19.7193858 },
    { lat: 49.3400895, lng: 19.7196152 },
    { lat: 49.3403671, lng: 19.7199127 },
    { lat: 49.3405799, lng: 19.7201434 },
    { lat: 49.3409848, lng: 19.7206688 },
    { lat: 49.341204, lng: 19.7207858 },
    { lat: 49.3416942, lng: 19.7211793 },
    { lat: 49.3417022, lng: 19.7211824 },
  ],
  Trstená: [
    { lat: 49.413559, lng: 19.557087 },
    { lat: 49.412318, lng: 19.555897 },
    { lat: 49.411544, lng: 19.555196 },
    { lat: 49.411076, lng: 19.554642 },
    { lat: 49.411009, lng: 19.554565 },
    { lat: 49.410923, lng: 19.554463 },
    { lat: 49.409616, lng: 19.552922 },
    { lat: 49.409157, lng: 19.552352 },
    { lat: 49.408253, lng: 19.5509 },
    { lat: 49.407912, lng: 19.550345 },
    { lat: 49.407517, lng: 19.549446 },
    { lat: 49.40644, lng: 19.548091 },
    { lat: 49.405667, lng: 19.546847 },
    { lat: 49.405653, lng: 19.546788 },
    { lat: 49.404892, lng: 19.544532 },
    { lat: 49.404576, lng: 19.543695 },
    { lat: 49.404401, lng: 19.543156 },
    { lat: 49.404281, lng: 19.54281 },
    { lat: 49.404103, lng: 19.542257 },
    { lat: 49.404068, lng: 19.542151 },
    { lat: 49.403793, lng: 19.541503 },
    { lat: 49.403627, lng: 19.541126 },
    { lat: 49.403265, lng: 19.540344 },
    { lat: 49.403223, lng: 19.540269 },
    { lat: 49.402372, lng: 19.53902 },
    { lat: 49.402247, lng: 19.538851 },
    { lat: 49.402038, lng: 19.538482 },
    { lat: 49.4012, lng: 19.537758 },
    { lat: 49.400536, lng: 19.535534 },
    { lat: 49.400389, lng: 19.535084 },
    { lat: 49.400184, lng: 19.534507 },
    { lat: 49.400108, lng: 19.534312 },
    { lat: 49.40004, lng: 19.534006 },
    { lat: 49.399609, lng: 19.532325 },
    { lat: 49.399374, lng: 19.531423 },
    { lat: 49.399249, lng: 19.530969 },
    { lat: 49.398976, lng: 19.530046 },
    { lat: 49.398808, lng: 19.529471 },
    { lat: 49.398691, lng: 19.529093 },
    { lat: 49.398483, lng: 19.528477 },
    { lat: 49.398295, lng: 19.527914 },
    { lat: 49.398104, lng: 19.527344 },
    { lat: 49.397759, lng: 19.526321 },
    { lat: 49.3977, lng: 19.52618 },
    { lat: 49.396808, lng: 19.525802 },
    { lat: 49.396192, lng: 19.525143 },
    { lat: 49.396059, lng: 19.524982 },
    { lat: 49.395883, lng: 19.525009 },
    { lat: 49.395533, lng: 19.525284 },
    { lat: 49.395326, lng: 19.525238 },
    { lat: 49.394974, lng: 19.524511 },
    { lat: 49.39431, lng: 19.525307 },
    { lat: 49.394102, lng: 19.525642 },
    { lat: 49.39396, lng: 19.52593 },
    { lat: 49.393784, lng: 19.526397 },
    { lat: 49.393593, lng: 19.526809 },
    { lat: 49.393274, lng: 19.527382 },
    { lat: 49.392943, lng: 19.52813 },
    { lat: 49.392524, lng: 19.528864 },
    { lat: 49.392049, lng: 19.527615 },
    { lat: 49.391939, lng: 19.527338 },
    { lat: 49.391731, lng: 19.526723 },
    { lat: 49.391708, lng: 19.526654 },
    { lat: 49.391685, lng: 19.526588 },
    { lat: 49.391428, lng: 19.525904 },
    { lat: 49.391033, lng: 19.524886 },
    { lat: 49.39049, lng: 19.523564 },
    { lat: 49.390457, lng: 19.523487 },
    { lat: 49.390424, lng: 19.523405 },
    { lat: 49.390376, lng: 19.52329 },
    { lat: 49.390185, lng: 19.522826 },
    { lat: 49.389506, lng: 19.520711 },
    { lat: 49.388904, lng: 19.518081 },
    { lat: 49.388454, lng: 19.516444 },
    { lat: 49.388051, lng: 19.515428 },
    { lat: 49.387829, lng: 19.514864 },
    { lat: 49.387624, lng: 19.514539 },
    { lat: 49.387345, lng: 19.514188 },
    { lat: 49.387001, lng: 19.513851 },
    { lat: 49.386803, lng: 19.513585 },
    { lat: 49.386443, lng: 19.512866 },
    { lat: 49.386125, lng: 19.51233 },
    { lat: 49.385916, lng: 19.511815 },
    { lat: 49.385658, lng: 19.511464 },
    { lat: 49.385166, lng: 19.511078 },
    { lat: 49.385025, lng: 19.510647 },
    { lat: 49.384705, lng: 19.510301 },
    { lat: 49.384448, lng: 19.509847 },
    { lat: 49.384308, lng: 19.509725 },
    { lat: 49.384135, lng: 19.50942 },
    { lat: 49.38349, lng: 19.508888 },
    { lat: 49.383158, lng: 19.508794 },
    { lat: 49.382941, lng: 19.508603 },
    { lat: 49.382766, lng: 19.508531 },
    { lat: 49.38253, lng: 19.508552 },
    { lat: 49.382307, lng: 19.507624 },
    { lat: 49.382124, lng: 19.505887 },
    { lat: 49.382115, lng: 19.505802 },
    { lat: 49.382035, lng: 19.505043 },
    { lat: 49.381962, lng: 19.503671 },
    { lat: 49.380786, lng: 19.502214 },
    { lat: 49.380674, lng: 19.501958 },
    { lat: 49.380542, lng: 19.501403 },
    { lat: 49.380109, lng: 19.500604 },
    { lat: 49.379792, lng: 19.500389 },
    { lat: 49.379605, lng: 19.500271 },
    { lat: 49.379205, lng: 19.500155 },
    { lat: 49.37879, lng: 19.49984 },
    { lat: 49.378693, lng: 19.499776 },
    { lat: 49.378643, lng: 19.499744 },
    { lat: 49.377177, lng: 19.498776 },
    { lat: 49.37647, lng: 19.498506 },
    { lat: 49.376271, lng: 19.498377 },
    { lat: 49.37624, lng: 19.498379 },
    { lat: 49.375952, lng: 19.498301 },
    { lat: 49.375822, lng: 19.498315 },
    { lat: 49.375735, lng: 19.498324 },
    { lat: 49.375655, lng: 19.498339 },
    { lat: 49.375372, lng: 19.498336 },
    { lat: 49.375273, lng: 19.498332 },
    { lat: 49.374819, lng: 19.498152 },
    { lat: 49.374255, lng: 19.498144 },
    { lat: 49.374233, lng: 19.49815 },
    { lat: 49.373007, lng: 19.498669 },
    { lat: 49.371648, lng: 19.498691 },
    { lat: 49.370749, lng: 19.498456 },
    { lat: 49.370713, lng: 19.49846 },
    { lat: 49.369549, lng: 19.498666 },
    { lat: 49.3699798, lng: 19.5010723 },
    { lat: 49.3702096, lng: 19.5024832 },
    { lat: 49.3707032, lng: 19.5050351 },
    { lat: 49.3708368, lng: 19.5064356 },
    { lat: 49.3710486, lng: 19.5076249 },
    { lat: 49.3713938, lng: 19.5085751 },
    { lat: 49.3714958, lng: 19.5090318 },
    { lat: 49.3715812, lng: 19.5096756 },
    { lat: 49.3717411, lng: 19.5102179 },
    { lat: 49.3720862, lng: 19.5107195 },
    { lat: 49.3729165, lng: 19.5117352 },
    { lat: 49.3736301, lng: 19.5127634 },
    { lat: 49.3742497, lng: 19.5142984 },
    { lat: 49.3749316, lng: 19.5152197 },
    { lat: 49.3754036, lng: 19.5157932 },
    { lat: 49.3759938, lng: 19.5166802 },
    { lat: 49.3766961, lng: 19.5187497 },
    { lat: 49.3767578, lng: 19.5199121 },
    { lat: 49.3767151, lng: 19.5208554 },
    { lat: 49.3767355, lng: 19.5219506 },
    { lat: 49.3766885, lng: 19.523053 },
    { lat: 49.3768373, lng: 19.5238519 },
    { lat: 49.3773687, lng: 19.5257321 },
    { lat: 49.3776026, lng: 19.5268144 },
    { lat: 49.3777055, lng: 19.5275947 },
    { lat: 49.3776447, lng: 19.5277964 },
    { lat: 49.3775221, lng: 19.5283988 },
    { lat: 49.3774552, lng: 19.5287617 },
    { lat: 49.3774173, lng: 19.5290207 },
    { lat: 49.3775133, lng: 19.5290965 },
    { lat: 49.3775152, lng: 19.5294398 },
    { lat: 49.3773665, lng: 19.5296136 },
    { lat: 49.3773403, lng: 19.5300561 },
    { lat: 49.3773379, lng: 19.5307962 },
    { lat: 49.3773508, lng: 19.531061 },
    { lat: 49.3774139, lng: 19.5313602 },
    { lat: 49.3773749, lng: 19.531488 },
    { lat: 49.377451, lng: 19.5316043 },
    { lat: 49.3776014, lng: 19.531866 },
    { lat: 49.3777152, lng: 19.5321381 },
    { lat: 49.3777564, lng: 19.5323023 },
    { lat: 49.3778722, lng: 19.5327206 },
    { lat: 49.3778975, lng: 19.5328311 },
    { lat: 49.3779106, lng: 19.532897 },
    { lat: 49.3779407, lng: 19.5329882 },
    { lat: 49.3780491, lng: 19.5333441 },
    { lat: 49.3780377, lng: 19.5335405 },
    { lat: 49.3781652, lng: 19.5339696 },
    { lat: 49.3782215, lng: 19.534139 },
    { lat: 49.3782085, lng: 19.5344777 },
    { lat: 49.3783132, lng: 19.5347715 },
    { lat: 49.3783613, lng: 19.5350639 },
    { lat: 49.3784662, lng: 19.5353406 },
    { lat: 49.3785319, lng: 19.535474 },
    { lat: 49.378527, lng: 19.5355624 },
    { lat: 49.3785235, lng: 19.5356372 },
    { lat: 49.3785229, lng: 19.5356496 },
    { lat: 49.3785385, lng: 19.5358703 },
    { lat: 49.3786576, lng: 19.53596 },
    { lat: 49.3786911, lng: 19.5362016 },
    { lat: 49.3788176, lng: 19.5364612 },
    { lat: 49.3789599, lng: 19.5366898 },
    { lat: 49.3790313, lng: 19.536875 },
    { lat: 49.3791156, lng: 19.5370928 },
    { lat: 49.3791924, lng: 19.5373714 },
    { lat: 49.3792162, lng: 19.5373761 },
    { lat: 49.3792741, lng: 19.5373884 },
    { lat: 49.3792911, lng: 19.5376592 },
    { lat: 49.3794316, lng: 19.537853 },
    { lat: 49.3794115, lng: 19.5381082 },
    { lat: 49.3794837, lng: 19.538187 },
    { lat: 49.3794012, lng: 19.5384297 },
    { lat: 49.3793917, lng: 19.5387938 },
    { lat: 49.3796006, lng: 19.5389029 },
    { lat: 49.3798092, lng: 19.539157 },
    { lat: 49.3798538, lng: 19.5393432 },
    { lat: 49.3799808, lng: 19.5394382 },
    { lat: 49.3801151, lng: 19.5396666 },
    { lat: 49.3801873, lng: 19.5399689 },
    { lat: 49.3802731, lng: 19.5398979 },
    { lat: 49.3803276, lng: 19.5399554 },
    { lat: 49.3803257, lng: 19.5400381 },
    { lat: 49.380426, lng: 19.5401157 },
    { lat: 49.3802575, lng: 19.5403259 },
    { lat: 49.3805781, lng: 19.5408112 },
    { lat: 49.3807391, lng: 19.5408119 },
    { lat: 49.3808759, lng: 19.5410169 },
    { lat: 49.3809873, lng: 19.5415656 },
    { lat: 49.381282, lng: 19.5415553 },
    { lat: 49.3814937, lng: 19.5415032 },
    { lat: 49.3814799, lng: 19.5416454 },
    { lat: 49.3816485, lng: 19.5419598 },
    { lat: 49.3812585, lng: 19.5433785 },
    { lat: 49.3809573, lng: 19.5443409 },
    { lat: 49.3809888, lng: 19.545144 },
    { lat: 49.3810049, lng: 19.5457462 },
    { lat: 49.380945, lng: 19.5464909 },
    { lat: 49.380691, lng: 19.5473549 },
    { lat: 49.3805581, lng: 19.5487316 },
    { lat: 49.3804266, lng: 19.5500353 },
    { lat: 49.3800974, lng: 19.5514768 },
    { lat: 49.3798238, lng: 19.5518815 },
    { lat: 49.3793096, lng: 19.5544486 },
    { lat: 49.3799394, lng: 19.5550485 },
    { lat: 49.3806201, lng: 19.5557044 },
    { lat: 49.3809349, lng: 19.5561637 },
    { lat: 49.380967, lng: 19.5566043 },
    { lat: 49.3808408, lng: 19.5568249 },
    { lat: 49.3807209, lng: 19.5570675 },
    { lat: 49.3806207, lng: 19.5573161 },
    { lat: 49.3805036, lng: 19.5575696 },
    { lat: 49.3803868, lng: 19.5578271 },
    { lat: 49.3802775, lng: 19.5580535 },
    { lat: 49.3801667, lng: 19.5582916 },
    { lat: 49.3800412, lng: 19.5585615 },
    { lat: 49.379925, lng: 19.5588067 },
    { lat: 49.3794667, lng: 19.5598589 },
    { lat: 49.3792458, lng: 19.5603599 },
    { lat: 49.3789974, lng: 19.5607007 },
    { lat: 49.3757629, lng: 19.5570472 },
    { lat: 49.3757059, lng: 19.5569831 },
    { lat: 49.375699, lng: 19.556982 },
    { lat: 49.3756843, lng: 19.5569796 },
    { lat: 49.3756343, lng: 19.5569715 },
    { lat: 49.375631, lng: 19.5569715 },
    { lat: 49.375622, lng: 19.5569706 },
    { lat: 49.3755938, lng: 19.5569688 },
    { lat: 49.3755283, lng: 19.5569646 },
    { lat: 49.3754809, lng: 19.5569648 },
    { lat: 49.3754674, lng: 19.5569649 },
    { lat: 49.3754605, lng: 19.5569653 },
    { lat: 49.375447, lng: 19.5569627 },
    { lat: 49.3754301, lng: 19.5569595 },
    { lat: 49.3751495, lng: 19.5569103 },
    { lat: 49.3750947, lng: 19.5568723 },
    { lat: 49.375038, lng: 19.5568346 },
    { lat: 49.3750026, lng: 19.5570036 },
    { lat: 49.3748814, lng: 19.5569386 },
    { lat: 49.3747777, lng: 19.5568841 },
    { lat: 49.3744474, lng: 19.5565065 },
    { lat: 49.3741529, lng: 19.5560951 },
    { lat: 49.3739013, lng: 19.5556881 },
    { lat: 49.3733125, lng: 19.5549709 },
    { lat: 49.3725876, lng: 19.554439 },
    { lat: 49.3721232, lng: 19.5542093 },
    { lat: 49.3713428, lng: 19.5542633 },
    { lat: 49.3705283, lng: 19.5546924 },
    { lat: 49.3701124, lng: 19.5549609 },
    { lat: 49.3696509, lng: 19.5552469 },
    { lat: 49.3693176, lng: 19.5555041 },
    { lat: 49.3690955, lng: 19.55585 },
    { lat: 49.3689322, lng: 19.5560253 },
    { lat: 49.3686694, lng: 19.5562923 },
    { lat: 49.3684438, lng: 19.5565349 },
    { lat: 49.3682159, lng: 19.5568789 },
    { lat: 49.3680587, lng: 19.5572686 },
    { lat: 49.3676875, lng: 19.5577796 },
    { lat: 49.3673007, lng: 19.5583226 },
    { lat: 49.3668094, lng: 19.5588745 },
    { lat: 49.3665806, lng: 19.5590201 },
    { lat: 49.366346, lng: 19.5591666 },
    { lat: 49.3661657, lng: 19.5592909 },
    { lat: 49.3659023, lng: 19.5594698 },
    { lat: 49.3657346, lng: 19.5596206 },
    { lat: 49.3653326, lng: 19.560026 },
    { lat: 49.3649219, lng: 19.560442 },
    { lat: 49.3645132, lng: 19.5607971 },
    { lat: 49.3643228, lng: 19.5608946 },
    { lat: 49.3642774, lng: 19.561412 },
    { lat: 49.3641329, lng: 19.5614323 },
    { lat: 49.3640664, lng: 19.5618055 },
    { lat: 49.36404, lng: 19.5623216 },
    { lat: 49.36399, lng: 19.5629154 },
    { lat: 49.3637663, lng: 19.5644493 },
    { lat: 49.3637289, lng: 19.5646994 },
    { lat: 49.36354, lng: 19.5658327 },
    { lat: 49.3632484, lng: 19.5672179 },
    { lat: 49.3629715, lng: 19.5683478 },
    { lat: 49.3627091, lng: 19.5693275 },
    { lat: 49.3629497, lng: 19.5706718 },
    { lat: 49.3630461, lng: 19.571137 },
    { lat: 49.3631856, lng: 19.5718344 },
    { lat: 49.3635743, lng: 19.5737917 },
    { lat: 49.3640779, lng: 19.5752699 },
    { lat: 49.3633711, lng: 19.5774705 },
    { lat: 49.3626242, lng: 19.5784495 },
    { lat: 49.3616876, lng: 19.5793483 },
    { lat: 49.358584, lng: 19.5820414 },
    { lat: 49.3583159, lng: 19.5822641 },
    { lat: 49.3574398, lng: 19.5822499 },
    { lat: 49.3571003, lng: 19.582192 },
    { lat: 49.3566164, lng: 19.582103 },
    { lat: 49.356355, lng: 19.5820282 },
    { lat: 49.3559672, lng: 19.5819713 },
    { lat: 49.3554288, lng: 19.5820471 },
    { lat: 49.3552609, lng: 19.5821471 },
    { lat: 49.3550848, lng: 19.5823153 },
    { lat: 49.3548953, lng: 19.5822864 },
    { lat: 49.3545484, lng: 19.5823416 },
    { lat: 49.3542516, lng: 19.5824766 },
    { lat: 49.3538683, lng: 19.5827478 },
    { lat: 49.3531203, lng: 19.5835304 },
    { lat: 49.3529346, lng: 19.5837037 },
    { lat: 49.3524448, lng: 19.5842457 },
    { lat: 49.3523552, lng: 19.5835992 },
    { lat: 49.3522811, lng: 19.5836423 },
    { lat: 49.3521728, lng: 19.5837139 },
    { lat: 49.3521086, lng: 19.5837836 },
    { lat: 49.3520377, lng: 19.583896 },
    { lat: 49.3520035, lng: 19.5839811 },
    { lat: 49.3519852, lng: 19.584056 },
    { lat: 49.3519782, lng: 19.5841563 },
    { lat: 49.3519686, lng: 19.5842404 },
    { lat: 49.3519468, lng: 19.5843439 },
    { lat: 49.3519286, lng: 19.5843847 },
    { lat: 49.3519034, lng: 19.5844282 },
    { lat: 49.3518182, lng: 19.584555 },
    { lat: 49.3517818, lng: 19.5846334 },
    { lat: 49.3517448, lng: 19.5847146 },
    { lat: 49.3515968, lng: 19.5849817 },
    { lat: 49.3515273, lng: 19.5850792 },
    { lat: 49.3512656, lng: 19.5853833 },
    { lat: 49.3512337, lng: 19.5854103 },
    { lat: 49.3512018, lng: 19.5854479 },
    { lat: 49.3511519, lng: 19.5855394 },
    { lat: 49.3510965, lng: 19.5856503 },
    { lat: 49.3510185, lng: 19.5857638 },
    { lat: 49.3509735, lng: 19.5858093 },
    { lat: 49.3509241, lng: 19.5858481 },
    { lat: 49.3506516, lng: 19.5861256 },
    { lat: 49.350605, lng: 19.5861788 },
    { lat: 49.3505619, lng: 19.5862454 },
    { lat: 49.3504947, lng: 19.586355 },
    { lat: 49.350441, lng: 19.5864225 },
    { lat: 49.3503741, lng: 19.5864926 },
    { lat: 49.3503, lng: 19.5865325 },
    { lat: 49.3502461, lng: 19.586559 },
    { lat: 49.3501573, lng: 19.5865798 },
    { lat: 49.3501181, lng: 19.5866095 },
    { lat: 49.350072, lng: 19.5866579 },
    { lat: 49.3498584, lng: 19.5868798 },
    { lat: 49.3497805, lng: 19.5869714 },
    { lat: 49.3496406, lng: 19.5872105 },
    { lat: 49.3496029, lng: 19.5873025 },
    { lat: 49.3495897, lng: 19.5873757 },
    { lat: 49.3495964, lng: 19.5874309 },
    { lat: 49.3496103, lng: 19.5874653 },
    { lat: 49.3496434, lng: 19.587489 },
    { lat: 49.3496746, lng: 19.5875122 },
    { lat: 49.3496983, lng: 19.5875568 },
    { lat: 49.3496985, lng: 19.5875942 },
    { lat: 49.3496741, lng: 19.5876324 },
    { lat: 49.3496288, lng: 19.5876466 },
    { lat: 49.3495863, lng: 19.5876632 },
    { lat: 49.3495385, lng: 19.5876946 },
    { lat: 49.3494291, lng: 19.587795 },
    { lat: 49.3492871, lng: 19.5879271 },
    { lat: 49.3491805, lng: 19.5880795 },
    { lat: 49.3491692, lng: 19.5881209 },
    { lat: 49.3491725, lng: 19.5881621 },
    { lat: 49.3491839, lng: 19.5881829 },
    { lat: 49.3492138, lng: 19.5881896 },
    { lat: 49.3492425, lng: 19.5881847 },
    { lat: 49.3492634, lng: 19.5881918 },
    { lat: 49.3492786, lng: 19.5882157 },
    { lat: 49.349284, lng: 19.5882396 },
    { lat: 49.3492824, lng: 19.5882759 },
    { lat: 49.3492634, lng: 19.5883146 },
    { lat: 49.3492329, lng: 19.5883497 },
    { lat: 49.3491754, lng: 19.5884076 },
    { lat: 49.3491507, lng: 19.5884522 },
    { lat: 49.3491353, lng: 19.5885217 },
    { lat: 49.3491205, lng: 19.5886039 },
    { lat: 49.349113, lng: 19.5886703 },
    { lat: 49.3490998, lng: 19.5887137 },
    { lat: 49.3490746, lng: 19.5887792 },
    { lat: 49.3490663, lng: 19.5888214 },
    { lat: 49.3490633, lng: 19.5888849 },
    { lat: 49.3490678, lng: 19.588906 },
    { lat: 49.3490768, lng: 19.5889235 },
    { lat: 49.3490916, lng: 19.5889333 },
    { lat: 49.349111, lng: 19.5889272 },
    { lat: 49.3491435, lng: 19.5888484 },
    { lat: 49.3491617, lng: 19.5888388 },
    { lat: 49.3491823, lng: 19.5888505 },
    { lat: 49.3492233, lng: 19.5889052 },
    { lat: 49.3492511, lng: 19.5889642 },
    { lat: 49.3492734, lng: 19.5889903 },
    { lat: 49.3493568, lng: 19.5890659 },
    { lat: 49.3493632, lng: 19.589081 },
    { lat: 49.3493673, lng: 19.589105 },
    { lat: 49.3493604, lng: 19.5891459 },
    { lat: 49.3493479, lng: 19.5891664 },
    { lat: 49.349332, lng: 19.5891693 },
    { lat: 49.3492978, lng: 19.5891608 },
    { lat: 49.3492779, lng: 19.589174 },
    { lat: 49.3492494, lng: 19.5891932 },
    { lat: 49.3492267, lng: 19.589223 },
    { lat: 49.3491892, lng: 19.5892978 },
    { lat: 49.3491787, lng: 19.5893602 },
    { lat: 49.3491653, lng: 19.5893735 },
    { lat: 49.3491294, lng: 19.5893833 },
    { lat: 49.3491011, lng: 19.5893941 },
    { lat: 49.3490855, lng: 19.5894146 },
    { lat: 49.3490807, lng: 19.589438 },
    { lat: 49.3490985, lng: 19.589495 },
    { lat: 49.3491201, lng: 19.5895489 },
    { lat: 49.3491507, lng: 19.5895877 },
    { lat: 49.3492272, lng: 19.5896641 },
    { lat: 49.3492506, lng: 19.5896943 },
    { lat: 49.3492642, lng: 19.5897275 },
    { lat: 49.3492766, lng: 19.589789 },
    { lat: 49.3492882, lng: 19.5898215 },
    { lat: 49.3493067, lng: 19.5898495 },
    { lat: 49.349346, lng: 19.5898967 },
    { lat: 49.3493836, lng: 19.5899493 },
    { lat: 49.3494241, lng: 19.5899957 },
    { lat: 49.3494589, lng: 19.5900196 },
    { lat: 49.3494856, lng: 19.5900453 },
    { lat: 49.3495327, lng: 19.5901028 },
    { lat: 49.3496013, lng: 19.5901732 },
    { lat: 49.3496318, lng: 19.5901906 },
    { lat: 49.3496544, lng: 19.5901899 },
    { lat: 49.3496817, lng: 19.5901751 },
    { lat: 49.3497051, lng: 19.5901507 },
    { lat: 49.3497154, lng: 19.590131 },
    { lat: 49.3497302, lng: 19.590067 },
    { lat: 49.3497473, lng: 19.5900347 },
    { lat: 49.3498046, lng: 19.589996 },
    { lat: 49.3498585, lng: 19.5899724 },
    { lat: 49.3498888, lng: 19.5899714 },
    { lat: 49.3499179, lng: 19.5899855 },
    { lat: 49.3499393, lng: 19.5900137 },
    { lat: 49.3499446, lng: 19.590033 },
    { lat: 49.3499328, lng: 19.5900709 },
    { lat: 49.3499118, lng: 19.5901115 },
    { lat: 49.3499135, lng: 19.5901426 },
    { lat: 49.3499255, lng: 19.5901763 },
    { lat: 49.3499461, lng: 19.5902226 },
    { lat: 49.3499724, lng: 19.5902726 },
    { lat: 49.34998, lng: 19.5903229 },
    { lat: 49.3499743, lng: 19.5903714 },
    { lat: 49.3499745, lng: 19.5904174 },
    { lat: 49.3499711, lng: 19.5904688 },
    { lat: 49.3499594, lng: 19.5905093 },
    { lat: 49.3499419, lng: 19.5905445 },
    { lat: 49.3498865, lng: 19.5906001 },
    { lat: 49.3498617, lng: 19.5906326 },
    { lat: 49.3498496, lng: 19.5906704 },
    { lat: 49.3498439, lng: 19.5906941 },
    { lat: 49.3498323, lng: 19.590716 },
    { lat: 49.3498074, lng: 19.5907289 },
    { lat: 49.3497572, lng: 19.5907276 },
    { lat: 49.3497007, lng: 19.5907444 },
    { lat: 49.3496741, lng: 19.5907496 },
    { lat: 49.3496508, lng: 19.5907349 },
    { lat: 49.3496153, lng: 19.5906617 },
    { lat: 49.3495957, lng: 19.5906374 },
    { lat: 49.3495779, lng: 19.5906378 },
    { lat: 49.3495637, lng: 19.5906555 },
    { lat: 49.3495529, lng: 19.5906954 },
    { lat: 49.349549, lng: 19.5907301 },
    { lat: 49.3495485, lng: 19.5907683 },
    { lat: 49.3495556, lng: 19.5908271 },
    { lat: 49.3495592, lng: 19.5908506 },
    { lat: 49.3495594, lng: 19.5908776 },
    { lat: 49.34955, lng: 19.5909003 },
    { lat: 49.3495128, lng: 19.5909461 },
    { lat: 49.3494951, lng: 19.5909921 },
    { lat: 49.3494913, lng: 19.5910381 },
    { lat: 49.3494979, lng: 19.5910816 },
    { lat: 49.3495109, lng: 19.5911216 },
    { lat: 49.349527, lng: 19.5911548 },
    { lat: 49.34955, lng: 19.5911827 },
    { lat: 49.349588, lng: 19.5912067 },
    { lat: 49.3496047, lng: 19.5912308 },
    { lat: 49.3496055, lng: 19.5912685 },
    { lat: 49.3495946, lng: 19.5912983 },
    { lat: 49.3495716, lng: 19.5913123 },
    { lat: 49.3495325, lng: 19.5913203 },
    { lat: 49.3494566, lng: 19.5913053 },
    { lat: 49.3493673, lng: 19.5912797 },
    { lat: 49.3493438, lng: 19.5912557 },
    { lat: 49.3493271, lng: 19.5912229 },
    { lat: 49.3493052, lng: 19.5912122 },
    { lat: 49.3492787, lng: 19.5912307 },
    { lat: 49.3492555, lng: 19.5912837 },
    { lat: 49.3492361, lng: 19.5913256 },
    { lat: 49.3492197, lng: 19.5913418 },
    { lat: 49.3491963, lng: 19.5913475 },
    { lat: 49.3491531, lng: 19.5913473 },
    { lat: 49.3491226, lng: 19.5913569 },
    { lat: 49.3490863, lng: 19.5913883 },
    { lat: 49.3490403, lng: 19.5914373 },
    { lat: 49.3490115, lng: 19.5914487 },
    { lat: 49.3489925, lng: 19.5914432 },
    { lat: 49.3489776, lng: 19.5914323 },
    { lat: 49.3489373, lng: 19.5913966 },
    { lat: 49.3489069, lng: 19.591383 },
    { lat: 49.3488864, lng: 19.5913886 },
    { lat: 49.3488625, lng: 19.5914222 },
    { lat: 49.3488376, lng: 19.5914559 },
    { lat: 49.348818, lng: 19.5915018 },
    { lat: 49.3488055, lng: 19.5915465 },
    { lat: 49.3488022, lng: 19.5915972 },
    { lat: 49.3488046, lng: 19.5916305 },
    { lat: 49.3488163, lng: 19.5916639 },
    { lat: 49.3488397, lng: 19.59169 },
    { lat: 49.3488683, lng: 19.591704 },
    { lat: 49.348908, lng: 19.591698 },
    { lat: 49.3489979, lng: 19.5916696 },
    { lat: 49.3490798, lng: 19.5916837 },
    { lat: 49.3491434, lng: 19.5917083 },
    { lat: 49.3491866, lng: 19.5917221 },
    { lat: 49.3492165, lng: 19.5917441 },
    { lat: 49.3492259, lng: 19.5917696 },
    { lat: 49.3492286, lng: 19.5917951 },
    { lat: 49.34922, lng: 19.5918478 },
    { lat: 49.3492063, lng: 19.5919131 },
    { lat: 49.3491965, lng: 19.5919845 },
    { lat: 49.3491743, lng: 19.592044 },
    { lat: 49.3491543, lng: 19.5920938 },
    { lat: 49.349131, lng: 19.5921324 },
    { lat: 49.3491136, lng: 19.5921463 },
    { lat: 49.3490843, lng: 19.5921521 },
    { lat: 49.349027, lng: 19.5921657 },
    { lat: 49.3490037, lng: 19.5921911 },
    { lat: 49.3489936, lng: 19.5922297 },
    { lat: 49.3489904, lng: 19.5923044 },
    { lat: 49.3489881, lng: 19.5923529 },
    { lat: 49.3489805, lng: 19.5924076 },
    { lat: 49.3489812, lng: 19.5924338 },
    { lat: 49.3489912, lng: 19.592462 },
    { lat: 49.3490105, lng: 19.5924788 },
    { lat: 49.3490397, lng: 19.5925079 },
    { lat: 49.3490948, lng: 19.5925468 },
    { lat: 49.3491329, lng: 19.5925612 },
    { lat: 49.3491723, lng: 19.5925674 },
    { lat: 49.3492721, lng: 19.5925537 },
    { lat: 49.349316, lng: 19.5925586 },
    { lat: 49.3493547, lng: 19.5925797 },
    { lat: 49.3493769, lng: 19.5926037 },
    { lat: 49.3493945, lng: 19.5926388 },
    { lat: 49.3494069, lng: 19.5926729 },
    { lat: 49.34941, lng: 19.5927151 },
    { lat: 49.3493986, lng: 19.5927521 },
    { lat: 49.3493911, lng: 19.5928134 },
    { lat: 49.3493958, lng: 19.5928474 },
    { lat: 49.349414, lng: 19.5928979 },
    { lat: 49.3494177, lng: 19.5929423 },
    { lat: 49.3494079, lng: 19.5929854 },
    { lat: 49.3493893, lng: 19.5930057 },
    { lat: 49.3492989, lng: 19.5930169 },
    { lat: 49.349206, lng: 19.5930845 },
    { lat: 49.3491271, lng: 19.5931615 },
    { lat: 49.3490444, lng: 19.5932483 },
    { lat: 49.3489949, lng: 19.5933352 },
    { lat: 49.3489621, lng: 19.5934413 },
    { lat: 49.3489484, lng: 19.5934678 },
    { lat: 49.3489315, lng: 19.5934778 },
    { lat: 49.3489118, lng: 19.593479 },
    { lat: 49.348842, lng: 19.593472 },
    { lat: 49.3487251, lng: 19.5934687 },
    { lat: 49.3486945, lng: 19.5934659 },
    { lat: 49.348665, lng: 19.593471 },
    { lat: 49.348655, lng: 19.5934883 },
    { lat: 49.3486485, lng: 19.5935162 },
    { lat: 49.3486268, lng: 19.5935842 },
    { lat: 49.3486133, lng: 19.5936049 },
    { lat: 49.3485813, lng: 19.5936397 },
    { lat: 49.3484889, lng: 19.5936625 },
    { lat: 49.3484612, lng: 19.5936826 },
    { lat: 49.3484091, lng: 19.5937417 },
    { lat: 49.3483674, lng: 19.5938088 },
    { lat: 49.3483422, lng: 19.5938813 },
    { lat: 49.348327, lng: 19.5939081 },
    { lat: 49.348309, lng: 19.5939243 },
    { lat: 49.348277, lng: 19.5939189 },
    { lat: 49.348257, lng: 19.5939048 },
    { lat: 49.3482371, lng: 19.5938826 },
    { lat: 49.3481748, lng: 19.5938529 },
    { lat: 49.3481035, lng: 19.5938318 },
    { lat: 49.3480261, lng: 19.5938294 },
    { lat: 49.3479719, lng: 19.5938456 },
    { lat: 49.347913, lng: 19.5938968 },
    { lat: 49.3478423, lng: 19.593965 },
    { lat: 49.3478208, lng: 19.5939863 },
    { lat: 49.347794, lng: 19.5939996 },
    { lat: 49.3477796, lng: 19.593994 },
    { lat: 49.3477663, lng: 19.5939807 },
    { lat: 49.3477543, lng: 19.5939511 },
    { lat: 49.347745, lng: 19.5938666 },
    { lat: 49.3477334, lng: 19.5938529 },
    { lat: 49.3477207, lng: 19.5938484 },
    { lat: 49.3476825, lng: 19.593849 },
    { lat: 49.3476473, lng: 19.5938501 },
    { lat: 49.3476335, lng: 19.5938572 },
    { lat: 49.3476228, lng: 19.5938853 },
    { lat: 49.3476013, lng: 19.5939882 },
    { lat: 49.3475885, lng: 19.5940161 },
    { lat: 49.3475727, lng: 19.5940324 },
    { lat: 49.3475453, lng: 19.5940423 },
    { lat: 49.3475087, lng: 19.5940359 },
    { lat: 49.3474517, lng: 19.5939952 },
    { lat: 49.3474233, lng: 19.5939773 },
    { lat: 49.3474121, lng: 19.5939584 },
    { lat: 49.347404, lng: 19.5939325 },
    { lat: 49.3473997, lng: 19.5938732 },
    { lat: 49.3473868, lng: 19.5938525 },
    { lat: 49.3473732, lng: 19.5938422 },
    { lat: 49.3473485, lng: 19.5938507 },
    { lat: 49.3473269, lng: 19.5938708 },
    { lat: 49.3472962, lng: 19.5939318 },
    { lat: 49.3472757, lng: 19.5939635 },
    { lat: 49.3472492, lng: 19.5939952 },
    { lat: 49.3472259, lng: 19.5940399 },
    { lat: 49.3472051, lng: 19.5940919 },
    { lat: 49.3471894, lng: 19.5941296 },
    { lat: 49.3471645, lng: 19.5941521 },
    { lat: 49.347142, lng: 19.5941878 },
    { lat: 49.347097, lng: 19.5943496 },
    { lat: 49.3470809, lng: 19.5944017 },
    { lat: 49.347062, lng: 19.5944356 },
    { lat: 49.3470051, lng: 19.5944951 },
    { lat: 49.3469737, lng: 19.594557 },
    { lat: 49.3469655, lng: 19.5946069 },
    { lat: 49.3469633, lng: 19.594673 },
    { lat: 49.3469696, lng: 19.5947634 },
    { lat: 49.3469905, lng: 19.5948728 },
    { lat: 49.3470027, lng: 19.5949901 },
    { lat: 49.3470022, lng: 19.5950395 },
    { lat: 49.3469957, lng: 19.5950818 },
    { lat: 49.3469553, lng: 19.5952154 },
    { lat: 49.3469519, lng: 19.5952487 },
    { lat: 49.346955, lng: 19.5952851 },
    { lat: 49.3469775, lng: 19.5953379 },
    { lat: 49.3469795, lng: 19.5953643 },
    { lat: 49.3469735, lng: 19.5953853 },
    { lat: 49.3469635, lng: 19.5954031 },
    { lat: 49.3469312, lng: 19.595426 },
    { lat: 49.3469048, lng: 19.5954566 },
    { lat: 49.3468722, lng: 19.5955134 },
    { lat: 49.3468221, lng: 19.5956056 },
    { lat: 49.346764, lng: 19.5957333 },
    { lat: 49.3467381, lng: 19.5957792 },
    { lat: 49.3467164, lng: 19.5958063 },
    { lat: 49.3467072, lng: 19.5958388 },
    { lat: 49.3467007, lng: 19.5958855 },
    { lat: 49.3466402, lng: 19.5959786 },
    { lat: 49.3466199, lng: 19.5960263 },
    { lat: 49.3466072, lng: 19.5960834 },
    { lat: 49.3466004, lng: 19.5961278 },
    { lat: 49.3465922, lng: 19.5961629 },
    { lat: 49.3465772, lng: 19.596196 },
    { lat: 49.346554, lng: 19.5962209 },
    { lat: 49.3465093, lng: 19.5962584 },
    { lat: 49.3464722, lng: 19.5962913 },
    { lat: 49.3464254, lng: 19.5963501 },
    { lat: 49.3463774, lng: 19.5964125 },
    { lat: 49.3463411, lng: 19.5964743 },
    { lat: 49.3462112, lng: 19.5967451 },
    { lat: 49.3461766, lng: 19.5967986 },
    { lat: 49.3461371, lng: 19.5968299 },
    { lat: 49.3460688, lng: 19.5968681 },
    { lat: 49.3459912, lng: 19.5969062 },
    { lat: 49.3459312, lng: 19.5969384 },
    { lat: 49.345894, lng: 19.5969674 },
    { lat: 49.3458477, lng: 19.5970209 },
    { lat: 49.3458107, lng: 19.5970658 },
    { lat: 49.345776, lng: 19.5970873 },
    { lat: 49.3457384, lng: 19.5970884 },
    { lat: 49.3457181, lng: 19.597078 },
    { lat: 49.345697, lng: 19.5970582 },
    { lat: 49.3456616, lng: 19.5970503 },
    { lat: 49.3456091, lng: 19.5970656 },
    { lat: 49.345546, lng: 19.5971101 },
    { lat: 49.34547, lng: 19.5971664 },
    { lat: 49.3453708, lng: 19.5972662 },
    { lat: 49.3452916, lng: 19.5973132 },
    { lat: 49.3452668, lng: 19.5973367 },
    { lat: 49.3452428, lng: 19.5973742 },
    { lat: 49.3452044, lng: 19.5974489 },
    { lat: 49.3451676, lng: 19.597547 },
    { lat: 49.3451069, lng: 19.597678 },
    { lat: 49.345073, lng: 19.5977624 },
    { lat: 49.3450468, lng: 19.5978075 },
    { lat: 49.3450024, lng: 19.5978748 },
    { lat: 49.3449592, lng: 19.597965 },
    { lat: 49.3448974, lng: 19.598091 },
    { lat: 49.3448706, lng: 19.5981353 },
    { lat: 49.3448421, lng: 19.5981677 },
    { lat: 49.3447405, lng: 19.5982586 },
    { lat: 49.3446399, lng: 19.5983919 },
    { lat: 49.3445284, lng: 19.5985427 },
    { lat: 49.3444499, lng: 19.5987096 },
    { lat: 49.3443796, lng: 19.5987836 },
    { lat: 49.3442951, lng: 19.5989262 },
    { lat: 49.3442686, lng: 19.5989524 },
    { lat: 49.3442394, lng: 19.5989701 },
    { lat: 49.3442147, lng: 19.5989949 },
    { lat: 49.3441829, lng: 19.5990585 },
    { lat: 49.3441572, lng: 19.5991571 },
    { lat: 49.3441426, lng: 19.5991993 },
    { lat: 49.3441158, lng: 19.5992344 },
    { lat: 49.3440884, lng: 19.5992461 },
    { lat: 49.3440489, lng: 19.5992437 },
    { lat: 49.3440106, lng: 19.599251 },
    { lat: 49.3439513, lng: 19.599295 },
    { lat: 49.3436648, lng: 19.5995407 },
    { lat: 49.3434345, lng: 19.5997528 },
    { lat: 49.3432621, lng: 19.5999312 },
    { lat: 49.3427471, lng: 19.6005839 },
    { lat: 49.3426321, lng: 19.6007574 },
    { lat: 49.3423019, lng: 19.6008288 },
    { lat: 49.3420873, lng: 19.6008433 },
    { lat: 49.3419349, lng: 19.6006086 },
    { lat: 49.3418402, lng: 19.6006336 },
    { lat: 49.3417264, lng: 19.6007337 },
    { lat: 49.341533, lng: 19.6008927 },
    { lat: 49.3413179, lng: 19.6010439 },
    { lat: 49.3409845, lng: 19.6011496 },
    { lat: 49.340857, lng: 19.6011438 },
    { lat: 49.340819, lng: 19.6011268 },
    { lat: 49.3407617, lng: 19.6010811 },
    { lat: 49.3407187, lng: 19.601022 },
    { lat: 49.3404794, lng: 19.6006121 },
    { lat: 49.3404074, lng: 19.6006651 },
    { lat: 49.3403664, lng: 19.6006568 },
    { lat: 49.3402028, lng: 19.6008253 },
    { lat: 49.3399965, lng: 19.6010221 },
    { lat: 49.3397719, lng: 19.601188 },
    { lat: 49.3392939, lng: 19.601613 },
    { lat: 49.3389121, lng: 19.6019908 },
    { lat: 49.3386784, lng: 19.602236 },
    { lat: 49.3384553, lng: 19.6024346 },
    { lat: 49.3382002, lng: 19.6026759 },
    { lat: 49.3380352, lng: 19.6028124 },
    { lat: 49.3377996, lng: 19.6030151 },
    { lat: 49.3375466, lng: 19.6032537 },
    { lat: 49.3372838, lng: 19.6034961 },
    { lat: 49.3367989, lng: 19.6039674 },
    { lat: 49.3367368, lng: 19.6040126 },
    { lat: 49.3365445, lng: 19.6041223 },
    { lat: 49.3365666, lng: 19.6046156 },
    { lat: 49.3362133, lng: 19.6046298 },
    { lat: 49.3357515, lng: 19.6046807 },
    { lat: 49.3355368, lng: 19.6047161 },
    { lat: 49.3351905, lng: 19.6048076 },
    { lat: 49.3349803, lng: 19.6048675 },
    { lat: 49.3344753, lng: 19.6050634 },
    { lat: 49.3341776, lng: 19.6052116 },
    { lat: 49.3337372, lng: 19.6055409 },
    { lat: 49.3336764, lng: 19.6055906 },
    { lat: 49.3336705, lng: 19.6059618 },
    { lat: 49.3337887, lng: 19.6066416 },
    { lat: 49.3329481, lng: 19.6070405 },
    { lat: 49.3325708, lng: 19.6072238 },
    { lat: 49.3319411, lng: 19.607523 },
    { lat: 49.3314455, lng: 19.6077539 },
    { lat: 49.3312532, lng: 19.6077357 },
    { lat: 49.3310726, lng: 19.6077311 },
    { lat: 49.330908, lng: 19.607804 },
    { lat: 49.3305881, lng: 19.6078826 },
    { lat: 49.3304664, lng: 19.6079058 },
    { lat: 49.330432, lng: 19.6080188 },
    { lat: 49.330327, lng: 19.6084914 },
    { lat: 49.3302022, lng: 19.6090264 },
    { lat: 49.3301832, lng: 19.6091559 },
    { lat: 49.3300592, lng: 19.6101384 },
    { lat: 49.3299322, lng: 19.6113823 },
    { lat: 49.329802, lng: 19.6126346 },
    { lat: 49.3297762, lng: 19.6132661 },
    { lat: 49.3297361, lng: 19.6145125 },
    { lat: 49.3297282, lng: 19.6151704 },
    { lat: 49.329689, lng: 19.616187 },
    { lat: 49.3295828, lng: 19.6178727 },
    { lat: 49.3296704, lng: 19.6181962 },
    { lat: 49.3298249, lng: 19.6188295 },
    { lat: 49.3299579, lng: 19.6193658 },
    { lat: 49.3299999, lng: 19.6195261 },
    { lat: 49.3301866, lng: 19.6202775 },
    { lat: 49.3300999, lng: 19.6204769 },
    { lat: 49.3299101, lng: 19.6208962 },
    { lat: 49.3298179, lng: 19.6208986 },
    { lat: 49.3294993, lng: 19.6209318 },
    { lat: 49.329414, lng: 19.6209367 },
    { lat: 49.3294015, lng: 19.6209622 },
    { lat: 49.3294208, lng: 19.6211162 },
    { lat: 49.3295636, lng: 19.6220221 },
    { lat: 49.3296678, lng: 19.6227387 },
    { lat: 49.329856, lng: 19.6240808 },
    { lat: 49.3299798, lng: 19.6249908 },
    { lat: 49.3299794, lng: 19.6250086 },
    { lat: 49.3299474, lng: 19.6250217 },
    { lat: 49.3297897, lng: 19.6249478 },
    { lat: 49.3297821, lng: 19.6248771 },
    { lat: 49.3297007, lng: 19.6248733 },
    { lat: 49.3296358, lng: 19.624832 },
    { lat: 49.3295059, lng: 19.6248997 },
    { lat: 49.3294837, lng: 19.6248291 },
    { lat: 49.329362, lng: 19.6247038 },
    { lat: 49.3293154, lng: 19.6246436 },
    { lat: 49.3292614, lng: 19.6245986 },
    { lat: 49.3292103, lng: 19.6245104 },
    { lat: 49.3291953, lng: 19.6245022 },
    { lat: 49.3290541, lng: 19.6245177 },
    { lat: 49.3289955, lng: 19.6244696 },
    { lat: 49.3289514, lng: 19.6244904 },
    { lat: 49.3288932, lng: 19.624499 },
    { lat: 49.3287396, lng: 19.6244381 },
    { lat: 49.3286517, lng: 19.6242331 },
    { lat: 49.3285774, lng: 19.6242148 },
    { lat: 49.3285129, lng: 19.624128 },
    { lat: 49.3285089, lng: 19.6241172 },
    { lat: 49.3284847, lng: 19.6240034 },
    { lat: 49.3284773, lng: 19.623985 },
    { lat: 49.3283305, lng: 19.624459 },
    { lat: 49.3280207, lng: 19.6254752 },
    { lat: 49.327919, lng: 19.625821 },
    { lat: 49.3276226, lng: 19.6257923 },
    { lat: 49.32743, lng: 19.6257928 },
    { lat: 49.3272276, lng: 19.6257796 },
    { lat: 49.3265926, lng: 19.6257121 },
    { lat: 49.3264805, lng: 19.6256971 },
    { lat: 49.3263315, lng: 19.6256913 },
    { lat: 49.3260641, lng: 19.6256548 },
    { lat: 49.3260255, lng: 19.6259828 },
    { lat: 49.3259897, lng: 19.6263394 },
    { lat: 49.3259183, lng: 19.6271379 },
    { lat: 49.3258716, lng: 19.6276105 },
    { lat: 49.3258121, lng: 19.6283224 },
    { lat: 49.3256636, lng: 19.6283267 },
    { lat: 49.325512, lng: 19.6281615 },
    { lat: 49.3253224, lng: 19.6281827 },
    { lat: 49.3251221, lng: 19.6281107 },
    { lat: 49.3249955, lng: 19.6280986 },
    { lat: 49.3248305, lng: 19.6279879 },
    { lat: 49.3247619, lng: 19.628016 },
    { lat: 49.3247004, lng: 19.6281525 },
    { lat: 49.3245993, lng: 19.6283356 },
    { lat: 49.3244478, lng: 19.6283214 },
    { lat: 49.324421, lng: 19.6283022 },
    { lat: 49.3243119, lng: 19.6283067 },
    { lat: 49.3240505, lng: 19.6284863 },
    { lat: 49.3238847, lng: 19.6287603 },
    { lat: 49.3238016, lng: 19.6289153 },
    { lat: 49.3237326, lng: 19.6291886 },
    { lat: 49.323699, lng: 19.6294478 },
    { lat: 49.3234486, lng: 19.6297939 },
    { lat: 49.3232995, lng: 19.6299127 },
    { lat: 49.3231717, lng: 19.6301304 },
    { lat: 49.3229949, lng: 19.6303312 },
    { lat: 49.3228722, lng: 19.630513 },
    { lat: 49.3225236, lng: 19.6308822 },
    { lat: 49.3224194, lng: 19.6309964 },
    { lat: 49.3223794, lng: 19.6311107 },
    { lat: 49.3223064, lng: 19.6312169 },
    { lat: 49.3221494, lng: 19.6313109 },
    { lat: 49.3218311, lng: 19.6314483 },
    { lat: 49.321773, lng: 19.63151 },
    { lat: 49.3216803, lng: 19.631905 },
    { lat: 49.3215941, lng: 19.6321484 },
    { lat: 49.3215667, lng: 19.6321913 },
    { lat: 49.3214279, lng: 19.632313 },
    { lat: 49.3213256, lng: 19.6324175 },
    { lat: 49.3212806, lng: 19.6324955 },
    { lat: 49.3211761, lng: 19.632731 },
    { lat: 49.3211473, lng: 19.6328153 },
    { lat: 49.321148, lng: 19.6329586 },
    { lat: 49.3212453, lng: 19.6335007 },
    { lat: 49.3212889, lng: 19.6336218 },
    { lat: 49.3213755, lng: 19.6336677 },
    { lat: 49.3214416, lng: 19.6338858 },
    { lat: 49.3214755, lng: 19.6339152 },
    { lat: 49.3215493, lng: 19.6339295 },
    { lat: 49.321654, lng: 19.634028 },
    { lat: 49.3217657, lng: 19.6343104 },
    { lat: 49.321759, lng: 19.6347181 },
    { lat: 49.3217322, lng: 19.6349008 },
    { lat: 49.3216987, lng: 19.6352899 },
    { lat: 49.3216895, lng: 19.6354599 },
    { lat: 49.3217249, lng: 19.6355758 },
    { lat: 49.321686, lng: 19.635838 },
    { lat: 49.3216349, lng: 19.6361341 },
    { lat: 49.3216281, lng: 19.6363623 },
    { lat: 49.3216463, lng: 19.6365923 },
    { lat: 49.3216382, lng: 19.6368199 },
    { lat: 49.32164, lng: 19.6369869 },
    { lat: 49.3216123, lng: 19.6375536 },
    { lat: 49.3216298, lng: 19.6379216 },
    { lat: 49.3215925, lng: 19.638171 },
    { lat: 49.3215763, lng: 19.6384457 },
    { lat: 49.3215239, lng: 19.6387581 },
    { lat: 49.3214824, lng: 19.6391966 },
    { lat: 49.321374, lng: 19.639647 },
    { lat: 49.3213018, lng: 19.6399242 },
    { lat: 49.3212909, lng: 19.6399553 },
    { lat: 49.3217364, lng: 19.6401738 },
    { lat: 49.3221243, lng: 19.6403563 },
    { lat: 49.3224506, lng: 19.6404265 },
    { lat: 49.3228233, lng: 19.6404188 },
    { lat: 49.3237947, lng: 19.6404306 },
    { lat: 49.3256452, lng: 19.6401358 },
    { lat: 49.325878, lng: 19.6401037 },
    { lat: 49.326165, lng: 19.6399215 },
    { lat: 49.3263339, lng: 19.639819 },
    { lat: 49.3265255, lng: 19.639715 },
    { lat: 49.3266502, lng: 19.6396851 },
    { lat: 49.3269239, lng: 19.6396324 },
    { lat: 49.3273995, lng: 19.6394548 },
    { lat: 49.3275253, lng: 19.6393496 },
    { lat: 49.3280452, lng: 19.6387502 },
    { lat: 49.3282029, lng: 19.6383177 },
    { lat: 49.3282862, lng: 19.6381632 },
    { lat: 49.3284122, lng: 19.6378593 },
    { lat: 49.3285366, lng: 19.6377413 },
    { lat: 49.3285907, lng: 19.6375399 },
    { lat: 49.3287101, lng: 19.6373371 },
    { lat: 49.3288276, lng: 19.6369648 },
    { lat: 49.329128, lng: 19.6366735 },
    { lat: 49.3292842, lng: 19.636486 },
    { lat: 49.3294881, lng: 19.6363623 },
    { lat: 49.3296809, lng: 19.6361591 },
    { lat: 49.3299648, lng: 19.6358323 },
    { lat: 49.3302469, lng: 19.6357163 },
    { lat: 49.3303877, lng: 19.6355644 },
    { lat: 49.3305809, lng: 19.6354742 },
    { lat: 49.3308608, lng: 19.6352544 },
    { lat: 49.3310208, lng: 19.6352063 },
    { lat: 49.3312671, lng: 19.6354253 },
    { lat: 49.3313325, lng: 19.6354271 },
    { lat: 49.331426, lng: 19.6354792 },
    { lat: 49.3315033, lng: 19.6354386 },
    { lat: 49.3315733, lng: 19.6353667 },
    { lat: 49.3316199, lng: 19.6352521 },
    { lat: 49.3316759, lng: 19.6352166 },
    { lat: 49.331909, lng: 19.6352668 },
    { lat: 49.3321488, lng: 19.6352397 },
    { lat: 49.3322999, lng: 19.6349906 },
    { lat: 49.3323666, lng: 19.6349457 },
    { lat: 49.3325706, lng: 19.6346897 },
    { lat: 49.3326458, lng: 19.6347094 },
    { lat: 49.3327122, lng: 19.6347097 },
    { lat: 49.3328272, lng: 19.6345565 },
    { lat: 49.3329025, lng: 19.634402 },
    { lat: 49.3329039, lng: 19.634382 },
    { lat: 49.3328592, lng: 19.6343155 },
    { lat: 49.332881, lng: 19.6342489 },
    { lat: 49.3329242, lng: 19.6342534 },
    { lat: 49.3329242, lng: 19.6342336 },
    { lat: 49.3330691, lng: 19.6341206 },
    { lat: 49.3331013, lng: 19.6340848 },
    { lat: 49.3331545, lng: 19.6339867 },
    { lat: 49.3331602, lng: 19.6339663 },
    { lat: 49.3332816, lng: 19.6340086 },
    { lat: 49.3334293, lng: 19.6342154 },
    { lat: 49.3334732, lng: 19.6342415 },
    { lat: 49.3335992, lng: 19.6344155 },
    { lat: 49.3336189, lng: 19.6344236 },
    { lat: 49.3338052, lng: 19.6344085 },
    { lat: 49.3338251, lng: 19.6343248 },
    { lat: 49.3338272, lng: 19.6341696 },
    { lat: 49.3338404, lng: 19.6341379 },
    { lat: 49.3338645, lng: 19.634147 },
    { lat: 49.3339005, lng: 19.6341934 },
    { lat: 49.3340081, lng: 19.6342346 },
    { lat: 49.3340604, lng: 19.6342209 },
    { lat: 49.3340837, lng: 19.6341874 },
    { lat: 49.3341126, lng: 19.6340731 },
    { lat: 49.3342312, lng: 19.6339593 },
    { lat: 49.3343982, lng: 19.6338332 },
    { lat: 49.3344213, lng: 19.6338488 },
    { lat: 49.3344596, lng: 19.6338983 },
    { lat: 49.334478, lng: 19.6339072 },
    { lat: 49.3344896, lng: 19.6339396 },
    { lat: 49.3346372, lng: 19.6339413 },
    { lat: 49.3348406, lng: 19.6339544 },
    { lat: 49.3351191, lng: 19.6339858 },
    { lat: 49.3351488, lng: 19.6340231 },
    { lat: 49.3351763, lng: 19.6340304 },
    { lat: 49.3352095, lng: 19.6340172 },
    { lat: 49.3353371, lng: 19.6340256 },
    { lat: 49.3353818, lng: 19.6337345 },
    { lat: 49.3354066, lng: 19.6337081 },
    { lat: 49.3354866, lng: 19.6337619 },
    { lat: 49.335518, lng: 19.6338348 },
    { lat: 49.3355502, lng: 19.6339753 },
    { lat: 49.3357059, lng: 19.6341339 },
    { lat: 49.3357626, lng: 19.6342931 },
    { lat: 49.3359947, lng: 19.6343965 },
    { lat: 49.3361529, lng: 19.6346622 },
    { lat: 49.3362772, lng: 19.634771 },
    { lat: 49.336313, lng: 19.6347897 },
    { lat: 49.3363259, lng: 19.6348255 },
    { lat: 49.3362708, lng: 19.6349078 },
    { lat: 49.3363165, lng: 19.6349692 },
    { lat: 49.3363443, lng: 19.6349367 },
    { lat: 49.3363632, lng: 19.6349318 },
    { lat: 49.3364372, lng: 19.6349989 },
    { lat: 49.3364645, lng: 19.6349809 },
    { lat: 49.3365574, lng: 19.6348387 },
    { lat: 49.3365707, lng: 19.6348373 },
    { lat: 49.3366651, lng: 19.6349568 },
    { lat: 49.3366568, lng: 19.635074 },
    { lat: 49.3366407, lng: 19.635115 },
    { lat: 49.3365591, lng: 19.63516 },
    { lat: 49.3365937, lng: 19.6352529 },
    { lat: 49.3366282, lng: 19.6354451 },
    { lat: 49.3366294, lng: 19.6355255 },
    { lat: 49.3366861, lng: 19.6353776 },
    { lat: 49.3367611, lng: 19.6354713 },
    { lat: 49.336782, lng: 19.6355869 },
    { lat: 49.3367973, lng: 19.635602 },
    { lat: 49.3368279, lng: 19.6356038 },
    { lat: 49.3368394, lng: 19.6355593 },
    { lat: 49.336936, lng: 19.6356793 },
    { lat: 49.3369323, lng: 19.6357769 },
    { lat: 49.3368764, lng: 19.6358087 },
    { lat: 49.3368673, lng: 19.6359079 },
    { lat: 49.3369472, lng: 19.6360593 },
    { lat: 49.3369952, lng: 19.636076 },
    { lat: 49.3370602, lng: 19.6360139 },
    { lat: 49.3371638, lng: 19.6361144 },
    { lat: 49.3371324, lng: 19.6362477 },
    { lat: 49.3371133, lng: 19.6362476 },
    { lat: 49.3370845, lng: 19.6362307 },
    { lat: 49.3370582, lng: 19.6362497 },
    { lat: 49.3370581, lng: 19.6362963 },
    { lat: 49.3370796, lng: 19.6363031 },
    { lat: 49.337111, lng: 19.6364241 },
    { lat: 49.3370912, lng: 19.6366118 },
    { lat: 49.3371792, lng: 19.6366623 },
    { lat: 49.3372629, lng: 19.6367721 },
    { lat: 49.3373254, lng: 19.6368339 },
    { lat: 49.3373629, lng: 19.6367436 },
    { lat: 49.3374972, lng: 19.6367748 },
    { lat: 49.3375451, lng: 19.6367637 },
    { lat: 49.3375946, lng: 19.6367362 },
    { lat: 49.3376233, lng: 19.6366956 },
    { lat: 49.3376771, lng: 19.6367637 },
    { lat: 49.3376808, lng: 19.6369501 },
    { lat: 49.3378608, lng: 19.6370744 },
    { lat: 49.3380459, lng: 19.6370534 },
    { lat: 49.3381559, lng: 19.6369675 },
    { lat: 49.3383189, lng: 19.6368852 },
    { lat: 49.338453, lng: 19.637088 },
    { lat: 49.3385621, lng: 19.6371356 },
    { lat: 49.3385981, lng: 19.6370867 },
    { lat: 49.3387093, lng: 19.6370484 },
    { lat: 49.3387394, lng: 19.6370669 },
    { lat: 49.3389686, lng: 19.6370565 },
    { lat: 49.3390763, lng: 19.6369188 },
    { lat: 49.3391871, lng: 19.6368524 },
    { lat: 49.3392588, lng: 19.6367922 },
    { lat: 49.3392702, lng: 19.6367675 },
    { lat: 49.3392962, lng: 19.6366723 },
    { lat: 49.3393204, lng: 19.6364299 },
    { lat: 49.3393685, lng: 19.6363711 },
    { lat: 49.3393853, lng: 19.6362657 },
    { lat: 49.3393406, lng: 19.6361728 },
    { lat: 49.3393012, lng: 19.6361256 },
    { lat: 49.3393096, lng: 19.636107 },
    { lat: 49.3396182, lng: 19.6352476 },
    { lat: 49.3396827, lng: 19.6354555 },
    { lat: 49.3398023, lng: 19.6355868 },
    { lat: 49.339906, lng: 19.6356093 },
    { lat: 49.3400233, lng: 19.6355664 },
    { lat: 49.3400774, lng: 19.6353855 },
    { lat: 49.340193, lng: 19.635225 },
    { lat: 49.3403201, lng: 19.6351684 },
    { lat: 49.3403999, lng: 19.6349145 },
    { lat: 49.3402062, lng: 19.6348905 },
    { lat: 49.340155, lng: 19.6348532 },
    { lat: 49.3402081, lng: 19.6347516 },
    { lat: 49.3402781, lng: 19.6347801 },
    { lat: 49.3404228, lng: 19.6347694 },
    { lat: 49.3405627, lng: 19.6348 },
    { lat: 49.3407227, lng: 19.6346236 },
    { lat: 49.3407599, lng: 19.6344666 },
    { lat: 49.340631, lng: 19.6344348 },
    { lat: 49.3406184, lng: 19.6343003 },
    { lat: 49.3406261, lng: 19.6341171 },
    { lat: 49.3406782, lng: 19.6340246 },
    { lat: 49.3407435, lng: 19.6339477 },
    { lat: 49.3407952, lng: 19.6339159 },
    { lat: 49.340839, lng: 19.6339406 },
    { lat: 49.3409288, lng: 19.6341119 },
    { lat: 49.3409492, lng: 19.6341145 },
    { lat: 49.3410652, lng: 19.6338626 },
    { lat: 49.3412521, lng: 19.6338084 },
    { lat: 49.3412461, lng: 19.6337726 },
    { lat: 49.341174, lng: 19.6337223 },
    { lat: 49.3411474, lng: 19.6336304 },
    { lat: 49.3410752, lng: 19.6335508 },
    { lat: 49.3410549, lng: 19.6334023 },
    { lat: 49.3411588, lng: 19.6332531 },
    { lat: 49.3411468, lng: 19.6331593 },
    { lat: 49.3411611, lng: 19.6330494 },
    { lat: 49.3410723, lng: 19.6329272 },
    { lat: 49.3411017, lng: 19.6328291 },
    { lat: 49.3411476, lng: 19.6327473 },
    { lat: 49.3411953, lng: 19.6327305 },
    { lat: 49.341226, lng: 19.6326091 },
    { lat: 49.3412569, lng: 19.6325965 },
    { lat: 49.3412794, lng: 19.6325729 },
    { lat: 49.3413034, lng: 19.6325277 },
    { lat: 49.3413476, lng: 19.6325358 },
    { lat: 49.3414124, lng: 19.6326493 },
    { lat: 49.341406, lng: 19.6327551 },
    { lat: 49.3413978, lng: 19.6327986 },
    { lat: 49.3413371, lng: 19.6329578 },
    { lat: 49.3413397, lng: 19.6330648 },
    { lat: 49.3413328, lng: 19.6330897 },
    { lat: 49.3413854, lng: 19.6331301 },
    { lat: 49.341403, lng: 19.6332244 },
    { lat: 49.3414307, lng: 19.6332627 },
    { lat: 49.3416523, lng: 19.6329264 },
    { lat: 49.3416487, lng: 19.6328928 },
    { lat: 49.3416064, lng: 19.632829 },
    { lat: 49.3415781, lng: 19.6328301 },
    { lat: 49.3414802, lng: 19.6326766 },
    { lat: 49.3414638, lng: 19.6325353 },
    { lat: 49.3415012, lng: 19.6324905 },
    { lat: 49.3415287, lng: 19.6324765 },
    { lat: 49.3416561, lng: 19.632529 },
    { lat: 49.3416595, lng: 19.63268 },
    { lat: 49.3417217, lng: 19.6327613 },
    { lat: 49.3417991, lng: 19.6329108 },
    { lat: 49.3418037, lng: 19.6329338 },
    { lat: 49.3417513, lng: 19.6330231 },
    { lat: 49.3417582, lng: 19.6330523 },
    { lat: 49.3417821, lng: 19.6330849 },
    { lat: 49.3417994, lng: 19.6330925 },
    { lat: 49.3418571, lng: 19.6330186 },
    { lat: 49.3418833, lng: 19.6330043 },
    { lat: 49.3419437, lng: 19.6329915 },
    { lat: 49.341961, lng: 19.6330045 },
    { lat: 49.3419783, lng: 19.6330385 },
    { lat: 49.3419529, lng: 19.6330722 },
    { lat: 49.3419513, lng: 19.6331161 },
    { lat: 49.3420175, lng: 19.633309 },
    { lat: 49.3421827, lng: 19.6337069 },
    { lat: 49.3424744, lng: 19.6338059 },
    { lat: 49.3425218, lng: 19.6337078 },
    { lat: 49.3425311, lng: 19.6336627 },
    { lat: 49.3425317, lng: 19.6335481 },
    { lat: 49.3424355, lng: 19.6334136 },
    { lat: 49.3423761, lng: 19.6331665 },
    { lat: 49.3422987, lng: 19.6330965 },
    { lat: 49.3422044, lng: 19.6330529 },
    { lat: 49.3421684, lng: 19.6328796 },
    { lat: 49.3421816, lng: 19.6328475 },
    { lat: 49.3422174, lng: 19.6328127 },
    { lat: 49.3422633, lng: 19.6328043 },
    { lat: 49.3423949, lng: 19.632856 },
    { lat: 49.3424562, lng: 19.6328919 },
    { lat: 49.3424696, lng: 19.6328833 },
    { lat: 49.3425457, lng: 19.6327757 },
    { lat: 49.342556, lng: 19.6327244 },
    { lat: 49.3425681, lng: 19.6326953 },
    { lat: 49.3426588, lng: 19.6327064 },
    { lat: 49.3427324, lng: 19.6325886 },
    { lat: 49.3428181, lng: 19.6325414 },
    { lat: 49.3428339, lng: 19.6326409 },
    { lat: 49.3428337, lng: 19.6328656 },
    { lat: 49.342839, lng: 19.6329106 },
    { lat: 49.3429028, lng: 19.6328473 },
    { lat: 49.3430287, lng: 19.6329173 },
    { lat: 49.3430662, lng: 19.6329751 },
    { lat: 49.3431219, lng: 19.6330123 },
    { lat: 49.3431554, lng: 19.633007 },
    { lat: 49.3432823, lng: 19.6329458 },
    { lat: 49.3433339, lng: 19.6330459 },
    { lat: 49.3433721, lng: 19.633043 },
    { lat: 49.3434748, lng: 19.633071 },
    { lat: 49.3435724, lng: 19.6331103 },
    { lat: 49.3435873, lng: 19.633094 },
    { lat: 49.3435767, lng: 19.633059 },
    { lat: 49.343528, lng: 19.6329979 },
    { lat: 49.3435661, lng: 19.6329679 },
    { lat: 49.3435809, lng: 19.6329505 },
    { lat: 49.3435493, lng: 19.6328194 },
    { lat: 49.343472, lng: 19.6327056 },
    { lat: 49.343247, lng: 19.6326355 },
    { lat: 49.3431556, lng: 19.632507 },
    { lat: 49.3431516, lng: 19.6324908 },
    { lat: 49.3431426, lng: 19.6323363 },
    { lat: 49.3432447, lng: 19.6322531 },
    { lat: 49.3433445, lng: 19.6322673 },
    { lat: 49.3433769, lng: 19.632313 },
    { lat: 49.3433556, lng: 19.632342 },
    { lat: 49.3433422, lng: 19.6323712 },
    { lat: 49.3433376, lng: 19.6324429 },
    { lat: 49.3434081, lng: 19.6325584 },
    { lat: 49.3436224, lng: 19.6325888 },
    { lat: 49.3436695, lng: 19.632507 },
    { lat: 49.3437671, lng: 19.6324495 },
    { lat: 49.3437695, lng: 19.6324247 },
    { lat: 49.3437305, lng: 19.6323333 },
    { lat: 49.3436049, lng: 19.6321206 },
    { lat: 49.3436282, lng: 19.6320123 },
    { lat: 49.3436392, lng: 19.632005 },
    { lat: 49.3438326, lng: 19.6319957 },
    { lat: 49.3439183, lng: 19.6320761 },
    { lat: 49.3439386, lng: 19.6320761 },
    { lat: 49.3440003, lng: 19.6320195 },
    { lat: 49.3440129, lng: 19.6320214 },
    { lat: 49.3440203, lng: 19.6320391 },
    { lat: 49.3440571, lng: 19.6320526 },
    { lat: 49.3440769, lng: 19.6320426 },
    { lat: 49.3441376, lng: 19.6319625 },
    { lat: 49.3441669, lng: 19.6318894 },
    { lat: 49.3442741, lng: 19.6317879 },
    { lat: 49.3444711, lng: 19.63184 },
    { lat: 49.3446259, lng: 19.6316675 },
    { lat: 49.3447594, lng: 19.6317338 },
    { lat: 49.3447851, lng: 19.631756 },
    { lat: 49.3447654, lng: 19.6318159 },
    { lat: 49.3447674, lng: 19.6318612 },
    { lat: 49.3447847, lng: 19.6318995 },
    { lat: 49.3448029, lng: 19.6319273 },
    { lat: 49.3448153, lng: 19.6320524 },
    { lat: 49.3448262, lng: 19.6320954 },
    { lat: 49.3448523, lng: 19.6321248 },
    { lat: 49.344881, lng: 19.6321381 },
    { lat: 49.3449109, lng: 19.6322018 },
    { lat: 49.3449258, lng: 19.6322606 },
    { lat: 49.3449463, lng: 19.6322903 },
    { lat: 49.344999, lng: 19.6323347 },
    { lat: 49.3451918, lng: 19.6324663 },
    { lat: 49.3452618, lng: 19.6324959 },
    { lat: 49.3452712, lng: 19.6324776 },
    { lat: 49.3454694, lng: 19.6324223 },
    { lat: 49.3455254, lng: 19.6323655 },
    { lat: 49.3455968, lng: 19.6323259 },
    { lat: 49.3457412, lng: 19.6323019 },
    { lat: 49.3458959, lng: 19.6323627 },
    { lat: 49.345936, lng: 19.6325804 },
    { lat: 49.345954, lng: 19.632709 },
    { lat: 49.3460092, lng: 19.6330114 },
    { lat: 49.3460089, lng: 19.6330295 },
    { lat: 49.3460133, lng: 19.6330287 },
    { lat: 49.3460262, lng: 19.6335217 },
    { lat: 49.3460431, lng: 19.633646 },
    { lat: 49.346171, lng: 19.6344757 },
    { lat: 49.3461453, lng: 19.6345293 },
    { lat: 49.3461463, lng: 19.6345505 },
    { lat: 49.3462433, lng: 19.634832 },
    { lat: 49.3462121, lng: 19.6349917 },
    { lat: 49.3462977, lng: 19.6353709 },
    { lat: 49.3463906, lng: 19.6357142 },
    { lat: 49.3463888, lng: 19.6358756 },
    { lat: 49.3464359, lng: 19.6364325 },
    { lat: 49.3464818, lng: 19.6364776 },
    { lat: 49.346506, lng: 19.6366915 },
    { lat: 49.3465792, lng: 19.6372725 },
    { lat: 49.346614, lng: 19.6374239 },
    { lat: 49.3466358, lng: 19.6376601 },
    { lat: 49.3467282, lng: 19.6381202 },
    { lat: 49.3467609, lng: 19.6382986 },
    { lat: 49.3467833, lng: 19.638547 },
    { lat: 49.3468183, lng: 19.6387775 },
    { lat: 49.3468384, lng: 19.6389749 },
    { lat: 49.3468742, lng: 19.6391233 },
    { lat: 49.3468721, lng: 19.6392565 },
    { lat: 49.3467606, lng: 19.6394567 },
    { lat: 49.3466757, lng: 19.6395757 },
    { lat: 49.3465466, lng: 19.6397355 },
    { lat: 49.3465002, lng: 19.6397334 },
    { lat: 49.3464693, lng: 19.6396477 },
    { lat: 49.3464609, lng: 19.6396356 },
    { lat: 49.3464073, lng: 19.6396173 },
    { lat: 49.3463731, lng: 19.6396884 },
    { lat: 49.3463602, lng: 19.6397541 },
    { lat: 49.3464148, lng: 19.6398131 },
    { lat: 49.3464666, lng: 19.6398374 },
    { lat: 49.3464652, lng: 19.6398585 },
    { lat: 49.3464434, lng: 19.6399268 },
    { lat: 49.346399, lng: 19.639968 },
    { lat: 49.3463702, lng: 19.6399785 },
    { lat: 49.3462955, lng: 19.6399447 },
    { lat: 49.3462811, lng: 19.6399506 },
    { lat: 49.3459721, lng: 19.6401695 },
    { lat: 49.345945, lng: 19.6401976 },
    { lat: 49.3460213, lng: 19.6403407 },
    { lat: 49.3460401, lng: 19.6404092 },
    { lat: 49.3460148, lng: 19.6405737 },
    { lat: 49.3460051, lng: 19.6405841 },
    { lat: 49.345939, lng: 19.6405935 },
    { lat: 49.3458287, lng: 19.6407462 },
    { lat: 49.3458284, lng: 19.6409421 },
    { lat: 49.3458196, lng: 19.6409719 },
    { lat: 49.3457303, lng: 19.6410665 },
    { lat: 49.3457244, lng: 19.6410888 },
    { lat: 49.3457402, lng: 19.641167 },
    { lat: 49.345827, lng: 19.641395 },
    { lat: 49.3459054, lng: 19.6415393 },
    { lat: 49.3459117, lng: 19.6415275 },
    { lat: 49.3459943, lng: 19.6415366 },
    { lat: 49.3460305, lng: 19.6416943 },
    { lat: 49.3460858, lng: 19.6418714 },
    { lat: 49.3461811, lng: 19.6420863 },
    { lat: 49.3461817, lng: 19.6421003 },
    { lat: 49.3461633, lng: 19.6422453 },
    { lat: 49.3462085, lng: 19.6425838 },
    { lat: 49.3462823, lng: 19.6428212 },
    { lat: 49.3463026, lng: 19.6429267 },
    { lat: 49.3463362, lng: 19.6432011 },
    { lat: 49.3463486, lng: 19.6434047 },
    { lat: 49.3463673, lng: 19.6434707 },
    { lat: 49.3463758, lng: 19.6435334 },
    { lat: 49.3464495, lng: 19.6437747 },
    { lat: 49.3465036, lng: 19.6439783 },
    { lat: 49.3465296, lng: 19.6441071 },
    { lat: 49.3465918, lng: 19.6443647 },
    { lat: 49.3466461, lng: 19.6444711 },
    { lat: 49.3467016, lng: 19.6446316 },
    { lat: 49.3467375, lng: 19.6448305 },
    { lat: 49.346819, lng: 19.6450963 },
    { lat: 49.3468777, lng: 19.6453236 },
    { lat: 49.3469613, lng: 19.6455599 },
    { lat: 49.3470716, lng: 19.6458126 },
    { lat: 49.3471773, lng: 19.6461138 },
    { lat: 49.3472114, lng: 19.6461952 },
    { lat: 49.3473094, lng: 19.6464741 },
    { lat: 49.3473414, lng: 19.6466894 },
    { lat: 49.347383, lng: 19.6468397 },
    { lat: 49.3474057, lng: 19.6470224 },
    { lat: 49.3474628, lng: 19.6470923 },
    { lat: 49.3474709, lng: 19.6471988 },
    { lat: 49.3475069, lng: 19.6473468 },
    { lat: 49.347535, lng: 19.6474266 },
    { lat: 49.3475909, lng: 19.6476702 },
    { lat: 49.3476883, lng: 19.6478812 },
    { lat: 49.347785, lng: 19.6480543 },
    { lat: 49.3478315, lng: 19.6481662 },
    { lat: 49.3479205, lng: 19.6484233 },
    { lat: 49.3480149, lng: 19.6487484 },
    { lat: 49.3480883, lng: 19.6490303 },
    { lat: 49.3481764, lng: 19.6493142 },
    { lat: 49.3486, lng: 19.6492616 },
    { lat: 49.3488859, lng: 19.6491824 },
    { lat: 49.3491133, lng: 19.6490789 },
    { lat: 49.3494312, lng: 19.6489058 },
    { lat: 49.3497247, lng: 19.6486878 },
    { lat: 49.3498381, lng: 19.6485777 },
    { lat: 49.349928, lng: 19.6488022 },
    { lat: 49.3499441, lng: 19.6490162 },
    { lat: 49.3500872, lng: 19.6492413 },
    { lat: 49.3502599, lng: 19.6493495 },
    { lat: 49.3504162, lng: 19.649548 },
    { lat: 49.3504924, lng: 19.6494891 },
    { lat: 49.3505494, lng: 19.6494012 },
    { lat: 49.3507145, lng: 19.6493392 },
    { lat: 49.3508058, lng: 19.6492134 },
    { lat: 49.3510922, lng: 19.6492209 },
    { lat: 49.3514122, lng: 19.6493471 },
    { lat: 49.3517246, lng: 19.6495087 },
    { lat: 49.3518784, lng: 19.6496763 },
    { lat: 49.3519703, lng: 19.649713 },
    { lat: 49.3520426, lng: 19.6497977 },
    { lat: 49.3520448, lng: 19.6499753 },
    { lat: 49.352085, lng: 19.650168 },
    { lat: 49.3522103, lng: 19.6503274 },
    { lat: 49.352222, lng: 19.6503352 },
    { lat: 49.3529555, lng: 19.650799 },
    { lat: 49.3533338, lng: 19.650947 },
    { lat: 49.3536542, lng: 19.6510811 },
    { lat: 49.3547142, lng: 19.65136 },
    { lat: 49.3555898, lng: 19.6512919 },
    { lat: 49.3564685, lng: 19.6515445 },
    { lat: 49.3576234, lng: 19.6517272 },
    { lat: 49.3579356, lng: 19.6517342 },
    { lat: 49.3589927, lng: 19.651767 },
    { lat: 49.3594714, lng: 19.6517394 },
    { lat: 49.3595916, lng: 19.6516543 },
    { lat: 49.360448, lng: 19.6516374 },
    { lat: 49.3615671, lng: 19.6516207 },
    { lat: 49.3621107, lng: 19.651534 },
    { lat: 49.3621387, lng: 19.651526 },
    { lat: 49.3622317, lng: 19.6516212 },
    { lat: 49.3623718, lng: 19.651881 },
    { lat: 49.3624785, lng: 19.652206 },
    { lat: 49.362467, lng: 19.6523561 },
    { lat: 49.3625221, lng: 19.6525564 },
    { lat: 49.3625694, lng: 19.6529376 },
    { lat: 49.3626751, lng: 19.6532891 },
    { lat: 49.3626982, lng: 19.6536832 },
    { lat: 49.3627145, lng: 19.6541051 },
    { lat: 49.3626459, lng: 19.6543909 },
    { lat: 49.3626579, lng: 19.654763 },
    { lat: 49.3626892, lng: 19.6548288 },
    { lat: 49.3627156, lng: 19.655378 },
    { lat: 49.3628065, lng: 19.6555229 },
    { lat: 49.3627887, lng: 19.6557297 },
    { lat: 49.362845, lng: 19.65576 },
    { lat: 49.3628176, lng: 19.6559504 },
    { lat: 49.3628704, lng: 19.6560028 },
    { lat: 49.3628469, lng: 19.6561812 },
    { lat: 49.3629094, lng: 19.6562167 },
    { lat: 49.3629361, lng: 19.656314 },
    { lat: 49.3628904, lng: 19.6563271 },
    { lat: 49.3628579, lng: 19.6564291 },
    { lat: 49.3629713, lng: 19.6565225 },
    { lat: 49.3629718, lng: 19.6565604 },
    { lat: 49.362939, lng: 19.6565826 },
    { lat: 49.3629512, lng: 19.6566558 },
    { lat: 49.3628911, lng: 19.6567224 },
    { lat: 49.3629156, lng: 19.6568175 },
    { lat: 49.3630081, lng: 19.6567685 },
    { lat: 49.3630362, lng: 19.6568216 },
    { lat: 49.3629966, lng: 19.6568918 },
    { lat: 49.3631081, lng: 19.6569685 },
    { lat: 49.3631094, lng: 19.6570988 },
    { lat: 49.3632349, lng: 19.6573388 },
    { lat: 49.3632618, lng: 19.6572854 },
    { lat: 49.3632993, lng: 19.6573434 },
    { lat: 49.3632967, lng: 19.6574665 },
    { lat: 49.3634585, lng: 19.6575806 },
    { lat: 49.3634541, lng: 19.6577365 },
    { lat: 49.3635167, lng: 19.6577264 },
    { lat: 49.3635546, lng: 19.6578685 },
    { lat: 49.3636705, lng: 19.657816 },
    { lat: 49.3636992, lng: 19.6578557 },
    { lat: 49.3636292, lng: 19.657975 },
    { lat: 49.3636463, lng: 19.6580057 },
    { lat: 49.3640805, lng: 19.6580439 },
    { lat: 49.3640991, lng: 19.6580325 },
    { lat: 49.3641114, lng: 19.6577978 },
    { lat: 49.3641755, lng: 19.6578226 },
    { lat: 49.3642526, lng: 19.6577351 },
    { lat: 49.3642612, lng: 19.6579294 },
    { lat: 49.3643865, lng: 19.6580089 },
    { lat: 49.3644274, lng: 19.6579396 },
    { lat: 49.3645279, lng: 19.6581265 },
    { lat: 49.3646679, lng: 19.6581048 },
    { lat: 49.3646764, lng: 19.6580678 },
    { lat: 49.3646282, lng: 19.6580229 },
    { lat: 49.3646294, lng: 19.6579971 },
    { lat: 49.3647975, lng: 19.6578532 },
    { lat: 49.3649833, lng: 19.6579012 },
    { lat: 49.3650585, lng: 19.6579465 },
    { lat: 49.3652754, lng: 19.6582146 },
    { lat: 49.3654262, lng: 19.6582883 },
    { lat: 49.3655078, lng: 19.6581953 },
    { lat: 49.3656529, lng: 19.6582684 },
    { lat: 49.3656983, lng: 19.6581473 },
    { lat: 49.3658377, lng: 19.6578354 },
    { lat: 49.3660154, lng: 19.6575774 },
    { lat: 49.366317, lng: 19.6574153 },
    { lat: 49.366498, lng: 19.6572783 },
    { lat: 49.3670156, lng: 19.6569366 },
    { lat: 49.3674297, lng: 19.656872 },
    { lat: 49.3674188, lng: 19.6567271 },
    { lat: 49.3674087, lng: 19.6544317 },
    { lat: 49.3685179, lng: 19.6545535 },
    { lat: 49.3688557, lng: 19.6546002 },
    { lat: 49.3692332, lng: 19.6546008 },
    { lat: 49.3695908, lng: 19.654563 },
    { lat: 49.3706408, lng: 19.6544625 },
    { lat: 49.3708484, lng: 19.6544438 },
    { lat: 49.371796, lng: 19.6554156 },
    { lat: 49.3729793, lng: 19.6564183 },
    { lat: 49.3736074, lng: 19.6569454 },
    { lat: 49.3735986, lng: 19.6569759 },
    { lat: 49.3748668, lng: 19.6574808 },
    { lat: 49.3760055, lng: 19.6580349 },
    { lat: 49.3769837, lng: 19.6585036 },
    { lat: 49.3776673, lng: 19.6590048 },
    { lat: 49.3776969, lng: 19.6590126 },
    { lat: 49.3785595, lng: 19.6593126 },
    { lat: 49.3786534, lng: 19.65945 },
    { lat: 49.3795855, lng: 19.6609667 },
    { lat: 49.3802011, lng: 19.661976 },
    { lat: 49.3817702, lng: 19.6614581 },
    { lat: 49.3834746, lng: 19.6607692 },
    { lat: 49.3847717, lng: 19.6601196 },
    { lat: 49.3860225, lng: 19.6594881 },
    { lat: 49.3863083, lng: 19.6596566 },
    { lat: 49.3874556, lng: 19.6600531 },
    { lat: 49.3884571, lng: 19.6603876 },
    { lat: 49.3897551, lng: 19.6612371 },
    { lat: 49.3904322, lng: 19.6616443 },
    { lat: 49.3911014, lng: 19.6620559 },
    { lat: 49.3923428, lng: 19.6628673 },
    { lat: 49.393583, lng: 19.6636867 },
    { lat: 49.3947938, lng: 19.6644467 },
    { lat: 49.3962791, lng: 19.6637952 },
    { lat: 49.3973355, lng: 19.66333 },
    { lat: 49.3977709, lng: 19.6631398 },
    { lat: 49.3980645, lng: 19.6631803 },
    { lat: 49.3992754, lng: 19.6633547 },
    { lat: 49.400875, lng: 19.6635591 },
    { lat: 49.402197, lng: 19.6632138 },
    { lat: 49.4033037, lng: 19.662893 },
    { lat: 49.4038196, lng: 19.6631004 },
    { lat: 49.4043335, lng: 19.6634588 },
    { lat: 49.405357, lng: 19.6638324 },
    { lat: 49.4059751, lng: 19.6640792 },
    { lat: 49.4061615, lng: 19.6640414 },
    { lat: 49.406351, lng: 19.6642479 },
    { lat: 49.4064042, lng: 19.6642929 },
    { lat: 49.4064115, lng: 19.6642791 },
    { lat: 49.4064198, lng: 19.6642633 },
    { lat: 49.4064963, lng: 19.6639101 },
    { lat: 49.406709, lng: 19.663605 },
    { lat: 49.406834, lng: 19.663406 },
    { lat: 49.40689, lng: 19.66338 },
    { lat: 49.406919, lng: 19.663469 },
    { lat: 49.40688, lng: 19.663704 },
    { lat: 49.406897, lng: 19.663829 },
    { lat: 49.406923, lng: 19.663914 },
    { lat: 49.407039, lng: 19.664031 },
    { lat: 49.407273, lng: 19.664007 },
    { lat: 49.407401, lng: 19.66383 },
    { lat: 49.407499, lng: 19.663575 },
    { lat: 49.407583, lng: 19.663256 },
    { lat: 49.407605, lng: 19.663059 },
    { lat: 49.407682, lng: 19.662764 },
    { lat: 49.407673, lng: 19.66264 },
    { lat: 49.407649, lng: 19.662545 },
    { lat: 49.407603, lng: 19.662515 },
    { lat: 49.407467, lng: 19.662533 },
    { lat: 49.407266, lng: 19.662248 },
    { lat: 49.40717, lng: 19.662128 },
    { lat: 49.4071, lng: 19.662111 },
    { lat: 49.407051, lng: 19.662167 },
    { lat: 49.407007, lng: 19.662275 },
    { lat: 49.406999, lng: 19.662282 },
    { lat: 49.406923, lng: 19.662346 },
    { lat: 49.406866, lng: 19.662357 },
    { lat: 49.406815, lng: 19.662334 },
    { lat: 49.406781, lng: 19.662317 },
    { lat: 49.406731, lng: 19.662258 },
    { lat: 49.40665, lng: 19.662057 },
    { lat: 49.406557, lng: 19.661935 },
    { lat: 49.406484, lng: 19.661663 },
    { lat: 49.406442, lng: 19.6614 },
    { lat: 49.406459, lng: 19.661217 },
    { lat: 49.406514, lng: 19.661077 },
    { lat: 49.40659, lng: 19.661032 },
    { lat: 49.406633, lng: 19.661026 },
    { lat: 49.406771, lng: 19.661004 },
    { lat: 49.406922, lng: 19.660779 },
    { lat: 49.407191, lng: 19.660486 },
    { lat: 49.407223, lng: 19.66047 },
    { lat: 49.407356, lng: 19.660407 },
    { lat: 49.407554, lng: 19.660429 },
    { lat: 49.407777, lng: 19.660516 },
    { lat: 49.407932, lng: 19.660565 },
    { lat: 49.407967, lng: 19.660567 },
    { lat: 49.408021, lng: 19.66057 },
    { lat: 49.408187, lng: 19.660495 },
    { lat: 49.408329, lng: 19.660457 },
    { lat: 49.408443, lng: 19.660341 },
    { lat: 49.408464, lng: 19.66028 },
    { lat: 49.408456, lng: 19.660131 },
    { lat: 49.4084, lng: 19.659879 },
    { lat: 49.40836, lng: 19.659586 },
    { lat: 49.408281, lng: 19.659346 },
    { lat: 49.408064, lng: 19.659112 },
    { lat: 49.407916, lng: 19.658998 },
    { lat: 49.407822, lng: 19.658908 },
    { lat: 49.407711, lng: 19.658777 },
    { lat: 49.407622, lng: 19.658695 },
    { lat: 49.40759, lng: 19.658588 },
    { lat: 49.4076167, lng: 19.6584382 },
    { lat: 49.40764, lng: 19.658271 },
    { lat: 49.4076423, lng: 19.6582561 },
    { lat: 49.407669, lng: 19.658081 },
    { lat: 49.407737, lng: 19.657707 },
    { lat: 49.407749, lng: 19.657644 },
    { lat: 49.407848, lng: 19.657349 },
    { lat: 49.407908, lng: 19.657207 },
    { lat: 49.408058, lng: 19.656951 },
    { lat: 49.408075, lng: 19.656928 },
    { lat: 49.408143, lng: 19.656836 },
    { lat: 49.408219, lng: 19.656771 },
    { lat: 49.408269, lng: 19.656692 },
    { lat: 49.408287, lng: 19.656458 },
    { lat: 49.408231, lng: 19.656234 },
    { lat: 49.408195, lng: 19.655841 },
    { lat: 49.408162, lng: 19.655584 },
    { lat: 49.408144, lng: 19.655282 },
    { lat: 49.40813, lng: 19.655166 },
    { lat: 49.408075, lng: 19.654961 },
    { lat: 49.408036, lng: 19.65485 },
    { lat: 49.407967, lng: 19.654694 },
    { lat: 49.407932, lng: 19.654541 },
    { lat: 49.407905, lng: 19.654421 },
    { lat: 49.407899, lng: 19.654377 },
    { lat: 49.407863, lng: 19.654157 },
    { lat: 49.407845, lng: 19.654011 },
    { lat: 49.407857, lng: 19.653895 },
    { lat: 49.40792, lng: 19.653812 },
    { lat: 49.407987, lng: 19.653766 },
    { lat: 49.40807, lng: 19.653756 },
    { lat: 49.40815, lng: 19.653846 },
    { lat: 49.408239, lng: 19.653965 },
    { lat: 49.408301, lng: 19.654078 },
    { lat: 49.408405, lng: 19.654168 },
    { lat: 49.408545, lng: 19.653909 },
    { lat: 49.408539, lng: 19.653786 },
    { lat: 49.408417, lng: 19.653661 },
    { lat: 49.408278, lng: 19.653496 },
    { lat: 49.408189, lng: 19.653361 },
    { lat: 49.408064, lng: 19.653171 },
    { lat: 49.408015, lng: 19.653048 },
    { lat: 49.408011, lng: 19.652922 },
    { lat: 49.408014, lng: 19.652779 },
    { lat: 49.408081, lng: 19.65263 },
    { lat: 49.408156, lng: 19.652278 },
    { lat: 49.408194, lng: 19.652133 },
    { lat: 49.408305, lng: 19.651789 },
    { lat: 49.408405, lng: 19.651418 },
    { lat: 49.408473, lng: 19.651338 },
    { lat: 49.408559, lng: 19.651344 },
    { lat: 49.408833, lng: 19.65136 },
    { lat: 49.408992, lng: 19.651395 },
    { lat: 49.409039, lng: 19.651383 },
    { lat: 49.409154, lng: 19.651316 },
    { lat: 49.409306, lng: 19.651233 },
    { lat: 49.409534, lng: 19.65111 },
    { lat: 49.409863, lng: 19.650899 },
    { lat: 49.410002, lng: 19.650581 },
    { lat: 49.409978, lng: 19.650384 },
    { lat: 49.409991, lng: 19.650269 },
    { lat: 49.409963, lng: 19.65015 },
    { lat: 49.409906, lng: 19.650138 },
    { lat: 49.409874, lng: 19.650167 },
    { lat: 49.409773, lng: 19.650276 },
    { lat: 49.409733, lng: 19.65032 },
    { lat: 49.409676, lng: 19.650353 },
    { lat: 49.409632, lng: 19.650368 },
    { lat: 49.40957, lng: 19.650309 },
    { lat: 49.409503, lng: 19.65024 },
    { lat: 49.409464, lng: 19.650058 },
    { lat: 49.409459, lng: 19.650018 },
    { lat: 49.409434, lng: 19.649839 },
    { lat: 49.409359, lng: 19.649616 },
    { lat: 49.409066, lng: 19.648749 },
    { lat: 49.409033, lng: 19.648627 },
    { lat: 49.40897, lng: 19.64835 },
    { lat: 49.408973, lng: 19.648154 },
    { lat: 49.408994, lng: 19.647953 },
    { lat: 49.409094, lng: 19.64783 },
    { lat: 49.409125, lng: 19.647812 },
    { lat: 49.409231, lng: 19.647775 },
    { lat: 49.40948, lng: 19.647788 },
    { lat: 49.409602, lng: 19.647684 },
    { lat: 49.409645, lng: 19.647611 },
    { lat: 49.409665, lng: 19.647409 },
    { lat: 49.409654, lng: 19.647356 },
    { lat: 49.409629, lng: 19.647291 },
    { lat: 49.409569, lng: 19.647212 },
    { lat: 49.409398, lng: 19.647005 },
    { lat: 49.409362, lng: 19.646953 },
    { lat: 49.409312, lng: 19.646862 },
    { lat: 49.409225, lng: 19.646509 },
    { lat: 49.409196, lng: 19.646441 },
    { lat: 49.409081, lng: 19.646304 },
    { lat: 49.408891, lng: 19.646113 },
    { lat: 49.408704, lng: 19.646049 },
    { lat: 49.408618, lng: 19.646055 },
    { lat: 49.408496, lng: 19.646087 },
    { lat: 49.408396, lng: 19.64609 },
    { lat: 49.408289, lng: 19.646001 },
    { lat: 49.408226, lng: 19.645912 },
    { lat: 49.408208, lng: 19.645861 },
    { lat: 49.408184, lng: 19.645641 },
    { lat: 49.408197, lng: 19.645411 },
    { lat: 49.408281, lng: 19.645151 },
    { lat: 49.408261, lng: 19.645051 },
    { lat: 49.408184, lng: 19.644852 },
    { lat: 49.408178, lng: 19.644793 },
    { lat: 49.408181, lng: 19.644656 },
    { lat: 49.40822, lng: 19.644331 },
    { lat: 49.408297, lng: 19.644214 },
    { lat: 49.408388, lng: 19.644106 },
    { lat: 49.40845, lng: 19.644044 },
    { lat: 49.408516, lng: 19.644028 },
    { lat: 49.408554, lng: 19.644054 },
    { lat: 49.408615, lng: 19.644252 },
    { lat: 49.408618, lng: 19.644267 },
    { lat: 49.408634, lng: 19.644369 },
    { lat: 49.408649, lng: 19.644511 },
    { lat: 49.408657, lng: 19.644585 },
    { lat: 49.408681, lng: 19.644625 },
    { lat: 49.408736, lng: 19.644613 },
    { lat: 49.408835, lng: 19.64452 },
    { lat: 49.408982, lng: 19.644348 },
    { lat: 49.409093, lng: 19.644278 },
    { lat: 49.409142, lng: 19.64423 },
    { lat: 49.409184, lng: 19.644133 },
    { lat: 49.409185, lng: 19.644083 },
    { lat: 49.409193, lng: 19.644009 },
    { lat: 49.4092, lng: 19.643936 },
    { lat: 49.409236, lng: 19.643846 },
    { lat: 49.409318, lng: 19.643823 },
    { lat: 49.409385, lng: 19.643857 },
    { lat: 49.409447, lng: 19.643935 },
    { lat: 49.409456, lng: 19.643956 },
    { lat: 49.409465, lng: 19.643981 },
    { lat: 49.409489, lng: 19.644027 },
    { lat: 49.40954, lng: 19.644122 },
    { lat: 49.409586, lng: 19.644639 },
    { lat: 49.409599, lng: 19.644711 },
    { lat: 49.40963, lng: 19.644788 },
    { lat: 49.409695, lng: 19.644832 },
    { lat: 49.409721, lng: 19.644817 },
    { lat: 49.409841, lng: 19.644719 },
    { lat: 49.410022, lng: 19.644594 },
    { lat: 49.410185, lng: 19.644565 },
    { lat: 49.410224, lng: 19.644575 },
    { lat: 49.410376, lng: 19.644663 },
    { lat: 49.410463, lng: 19.644734 },
    { lat: 49.410552, lng: 19.644768 },
    { lat: 49.410618, lng: 19.644747 },
    { lat: 49.410648, lng: 19.644619 },
    { lat: 49.410624, lng: 19.644377 },
    { lat: 49.410597, lng: 19.644259 },
    { lat: 49.410495, lng: 19.644005 },
    { lat: 49.410495, lng: 19.643932 },
    { lat: 49.410392, lng: 19.643528 },
    { lat: 49.410379, lng: 19.643443 },
    { lat: 49.410492, lng: 19.642966 },
    { lat: 49.410546, lng: 19.642787 },
    { lat: 49.410622, lng: 19.642566 },
    { lat: 49.410681, lng: 19.642134 },
    { lat: 49.41068, lng: 19.642061 },
    { lat: 49.410665, lng: 19.641901 },
    { lat: 49.410622, lng: 19.641814 },
    { lat: 49.410549, lng: 19.641793 },
    { lat: 49.410497, lng: 19.641875 },
    { lat: 49.410406, lng: 19.641965 },
    { lat: 49.410213, lng: 19.642157 },
    { lat: 49.41012, lng: 19.642223 },
    { lat: 49.410087, lng: 19.642239 },
    { lat: 49.410009, lng: 19.642253 },
    { lat: 49.409972, lng: 19.642258 },
    { lat: 49.409945, lng: 19.642262 },
    { lat: 49.409853, lng: 19.642262 },
    { lat: 49.409798, lng: 19.642239 },
    { lat: 49.409767, lng: 19.642219 },
    { lat: 49.409706, lng: 19.642027 },
    { lat: 49.409684, lng: 19.641917 },
    { lat: 49.409705, lng: 19.641787 },
    { lat: 49.409776, lng: 19.641509 },
    { lat: 49.409797, lng: 19.641415 },
    { lat: 49.409791, lng: 19.641303 },
    { lat: 49.409771, lng: 19.641168 },
    { lat: 49.409807, lng: 19.641043 },
    { lat: 49.409897, lng: 19.640946 },
    { lat: 49.410093, lng: 19.640949 },
    { lat: 49.410151, lng: 19.640938 },
    { lat: 49.41022, lng: 19.640924 },
    { lat: 49.41035, lng: 19.640841 },
    { lat: 49.410439, lng: 19.640609 },
    { lat: 49.410433, lng: 19.640467 },
    { lat: 49.410485, lng: 19.640332 },
    { lat: 49.410575, lng: 19.640226 },
    { lat: 49.410796, lng: 19.64018 },
    { lat: 49.410922, lng: 19.640145 },
    { lat: 49.411014, lng: 19.640079 },
    { lat: 49.41114, lng: 19.63988 },
    { lat: 49.41117, lng: 19.639819 },
    { lat: 49.411222, lng: 19.639627 },
    { lat: 49.411262, lng: 19.639481 },
    { lat: 49.411271, lng: 19.639361 },
    { lat: 49.411269, lng: 19.639185 },
    { lat: 49.411253, lng: 19.639124 },
    { lat: 49.411217, lng: 19.639095 },
    { lat: 49.411133, lng: 19.639071 },
    { lat: 49.411002, lng: 19.639005 },
    { lat: 49.410903, lng: 19.638989 },
    { lat: 49.410671, lng: 19.639008 },
    { lat: 49.410599, lng: 19.639013 },
    { lat: 49.41051, lng: 19.639019 },
    { lat: 49.410385, lng: 19.63906 },
    { lat: 49.410278, lng: 19.639036 },
    { lat: 49.410115, lng: 19.638978 },
    { lat: 49.409884, lng: 19.639018 },
    { lat: 49.409707, lng: 19.639162 },
    { lat: 49.409672, lng: 19.639191 },
    { lat: 49.409461, lng: 19.639212 },
    { lat: 49.409337, lng: 19.639179 },
    { lat: 49.40926, lng: 19.639105 },
    { lat: 49.40921, lng: 19.639055 },
    { lat: 49.409135, lng: 19.63902 },
    { lat: 49.408944, lng: 19.638968 },
    { lat: 49.408872, lng: 19.638935 },
    { lat: 49.408741, lng: 19.638823 },
    { lat: 49.40866, lng: 19.638771 },
    { lat: 49.408623, lng: 19.63868 },
    { lat: 49.408683, lng: 19.638417 },
    { lat: 49.409006, lng: 19.637885 },
    { lat: 49.409136, lng: 19.637709 },
    { lat: 49.409424, lng: 19.637515 },
    { lat: 49.409534, lng: 19.637474 },
    { lat: 49.409582, lng: 19.637456 },
    { lat: 49.409669, lng: 19.637297 },
    { lat: 49.409696, lng: 19.637116 },
    { lat: 49.409708, lng: 19.636889 },
    { lat: 49.40977, lng: 19.636712 },
    { lat: 49.409812, lng: 19.63663 },
    { lat: 49.409884, lng: 19.636584 },
    { lat: 49.409957, lng: 19.636537 },
    { lat: 49.410064, lng: 19.636394 },
    { lat: 49.410126, lng: 19.636308 },
    { lat: 49.410207, lng: 19.636275 },
    { lat: 49.410244, lng: 19.63626 },
    { lat: 49.410296, lng: 19.636182 },
    { lat: 49.410339, lng: 19.636102 },
    { lat: 49.410386, lng: 19.635917 },
    { lat: 49.410387, lng: 19.635824 },
    { lat: 49.410249, lng: 19.635664 },
    { lat: 49.41018, lng: 19.63566 },
    { lat: 49.410156, lng: 19.635664 },
    { lat: 49.410086, lng: 19.635678 },
    { lat: 49.410013, lng: 19.635662 },
    { lat: 49.40993, lng: 19.635619 },
    { lat: 49.409755, lng: 19.635466 },
    { lat: 49.40968, lng: 19.635222 },
    { lat: 49.409562, lng: 19.635069 },
    { lat: 49.409511, lng: 19.634982 },
    { lat: 49.409439, lng: 19.634795 },
    { lat: 49.409427, lng: 19.634766 },
    { lat: 49.409406, lng: 19.634649 },
    { lat: 49.409402, lng: 19.634502 },
    { lat: 49.409425, lng: 19.634361 },
    { lat: 49.409471, lng: 19.634245 },
    { lat: 49.409559, lng: 19.634173 },
    { lat: 49.409636, lng: 19.634187 },
    { lat: 49.409704, lng: 19.634221 },
    { lat: 49.40976, lng: 19.63425 },
    { lat: 49.409789, lng: 19.634279 },
    { lat: 49.40985, lng: 19.634342 },
    { lat: 49.409909, lng: 19.634329 },
    { lat: 49.409953, lng: 19.634276 },
    { lat: 49.409967, lng: 19.634127 },
    { lat: 49.40995, lng: 19.634071 },
    { lat: 49.409854, lng: 19.63395 },
    { lat: 49.409721, lng: 19.63386 },
    { lat: 49.409711, lng: 19.633849 },
    { lat: 49.409678, lng: 19.633808 },
    { lat: 49.409634, lng: 19.63368 },
    { lat: 49.409613, lng: 19.633562 },
    { lat: 49.409587, lng: 19.633415 },
    { lat: 49.40953, lng: 19.633349 },
    { lat: 49.409379, lng: 19.633255 },
    { lat: 49.409332, lng: 19.633264 },
    { lat: 49.409264, lng: 19.633316 },
    { lat: 49.409212, lng: 19.63341 },
    { lat: 49.409087, lng: 19.633686 },
    { lat: 49.409059, lng: 19.633747 },
    { lat: 49.408972, lng: 19.633799 },
    { lat: 49.40889, lng: 19.633806 },
    { lat: 49.408797, lng: 19.633755 },
    { lat: 49.40864, lng: 19.633712 },
    { lat: 49.408543, lng: 19.63359 },
    { lat: 49.408473, lng: 19.633423 },
    { lat: 49.408446, lng: 19.633326 },
    { lat: 49.408419, lng: 19.633173 },
    { lat: 49.408405, lng: 19.633037 },
    { lat: 49.408407, lng: 19.632943 },
    { lat: 49.408449, lng: 19.632774 },
    { lat: 49.408583, lng: 19.632321 },
    { lat: 49.408598, lng: 19.632269 },
    { lat: 49.408678, lng: 19.632073 },
    { lat: 49.408747, lng: 19.631856 },
    { lat: 49.408797, lng: 19.631728 },
    { lat: 49.408921, lng: 19.631666 },
    { lat: 49.408974, lng: 19.631654 },
    { lat: 49.409009, lng: 19.631616 },
    { lat: 49.409016, lng: 19.631608 },
    { lat: 49.409129, lng: 19.63155 },
    { lat: 49.409327, lng: 19.631542 },
    { lat: 49.409391, lng: 19.631539 },
    { lat: 49.409478, lng: 19.631386 },
    { lat: 49.409537, lng: 19.631416 },
    { lat: 49.410324, lng: 19.632298 },
    { lat: 49.410951, lng: 19.633006 },
    { lat: 49.411561, lng: 19.633693 },
    { lat: 49.411578, lng: 19.633714 },
    { lat: 49.412057, lng: 19.634187 },
    { lat: 49.412513, lng: 19.634639 },
    { lat: 49.413074, lng: 19.634735 },
    { lat: 49.413587, lng: 19.635211 },
    { lat: 49.414213, lng: 19.635199 },
    { lat: 49.414923, lng: 19.635464 },
    { lat: 49.415864, lng: 19.635797 },
    { lat: 49.416619, lng: 19.636413 },
    { lat: 49.417586, lng: 19.637085 },
    { lat: 49.418216, lng: 19.637448 },
    { lat: 49.418814, lng: 19.637796 },
    { lat: 49.419791, lng: 19.637938 },
    { lat: 49.419809, lng: 19.637941 },
    { lat: 49.419944, lng: 19.637964 },
    { lat: 49.420087, lng: 19.637987 },
    { lat: 49.421051, lng: 19.638184 },
    { lat: 49.421851, lng: 19.638346 },
    { lat: 49.422658, lng: 19.63877 },
    { lat: 49.422725, lng: 19.638805 },
    { lat: 49.424202, lng: 19.63958 },
    { lat: 49.425122, lng: 19.640152 },
    { lat: 49.426064, lng: 19.640739 },
    { lat: 49.427476, lng: 19.641681 },
    { lat: 49.428448, lng: 19.642585 },
    { lat: 49.429555, lng: 19.643155 },
    { lat: 49.430734, lng: 19.643761 },
    { lat: 49.431242, lng: 19.644711 },
    { lat: 49.432215, lng: 19.645801 },
    { lat: 49.432205, lng: 19.645813 },
    { lat: 49.432228, lng: 19.645847 },
    { lat: 49.432241, lng: 19.64597 },
    { lat: 49.432362, lng: 19.646112 },
    { lat: 49.432393, lng: 19.646186 },
    { lat: 49.432441, lng: 19.646228 },
    { lat: 49.432442, lng: 19.646283 },
    { lat: 49.432461, lng: 19.646339 },
    { lat: 49.432503, lng: 19.646364 },
    { lat: 49.432552, lng: 19.646364 },
    { lat: 49.432623, lng: 19.646482 },
    { lat: 49.432656, lng: 19.646603 },
    { lat: 49.432701, lng: 19.646662 },
    { lat: 49.432755, lng: 19.646729 },
    { lat: 49.43276, lng: 19.646785 },
    { lat: 49.432813, lng: 19.646871 },
    { lat: 49.432841, lng: 19.64692 },
    { lat: 49.432958, lng: 19.646983 },
    { lat: 49.433023, lng: 19.647035 },
    { lat: 49.43305, lng: 19.647093 },
    { lat: 49.433033, lng: 19.647162 },
    { lat: 49.433015, lng: 19.647203 },
    { lat: 49.433027, lng: 19.647255 },
    { lat: 49.433087, lng: 19.647306 },
    { lat: 49.43312, lng: 19.647375 },
    { lat: 49.433232, lng: 19.647433 },
    { lat: 49.433228, lng: 19.647452 },
    { lat: 49.433212, lng: 19.647445 },
    { lat: 49.433181, lng: 19.647549 },
    { lat: 49.433213, lng: 19.647629 },
    { lat: 49.433441, lng: 19.647796 },
    { lat: 49.433568, lng: 19.647929 },
    { lat: 49.43359, lng: 19.647932 },
    { lat: 49.433603, lng: 19.647874 },
    { lat: 49.433691, lng: 19.647802 },
    { lat: 49.433735, lng: 19.647814 },
    { lat: 49.433784, lng: 19.647788 },
    { lat: 49.433863, lng: 19.647801 },
    { lat: 49.433894, lng: 19.647776 },
    { lat: 49.433946, lng: 19.647772 },
    { lat: 49.433975, lng: 19.647806 },
    { lat: 49.434067, lng: 19.647802 },
    { lat: 49.434118, lng: 19.647844 },
    { lat: 49.434189, lng: 19.64788 },
    { lat: 49.434235, lng: 19.647891 },
    { lat: 49.434273, lng: 19.647864 },
    { lat: 49.434332, lng: 19.647877 },
    { lat: 49.434418, lng: 19.647971 },
    { lat: 49.43446, lng: 19.647955 },
    { lat: 49.434472, lng: 19.647973 },
    { lat: 49.434466, lng: 19.647995 },
    { lat: 49.434473, lng: 19.648024 },
    { lat: 49.434486, lng: 19.648072 },
    { lat: 49.434494, lng: 19.6481 },
    { lat: 49.434515, lng: 19.648099 },
    { lat: 49.434527, lng: 19.648094 },
    { lat: 49.434548, lng: 19.648085 },
    { lat: 49.434591, lng: 19.648131 },
    { lat: 49.434625, lng: 19.648199 },
    { lat: 49.434642, lng: 19.648233 },
    { lat: 49.434782, lng: 19.64828 },
    { lat: 49.434807, lng: 19.648266 },
    { lat: 49.43481, lng: 19.648243 },
    { lat: 49.434825, lng: 19.64823 },
    { lat: 49.434935, lng: 19.648198 },
    { lat: 49.434994, lng: 19.648214 },
    { lat: 49.435039, lng: 19.648255 },
    { lat: 49.435057, lng: 19.648302 },
    { lat: 49.435179, lng: 19.648527 },
    { lat: 49.435343, lng: 19.648562 },
    { lat: 49.435365, lng: 19.648596 },
    { lat: 49.435386, lng: 19.64857 },
    { lat: 49.435386, lng: 19.648533 },
    { lat: 49.435405, lng: 19.648484 },
    { lat: 49.435452, lng: 19.648421 },
    { lat: 49.435487, lng: 19.648329 },
    { lat: 49.435478, lng: 19.648183 },
    { lat: 49.435472, lng: 19.648128 },
    { lat: 49.435489, lng: 19.648012 },
    { lat: 49.435727, lng: 19.647608 },
    { lat: 49.435766, lng: 19.647426 },
    { lat: 49.435734, lng: 19.647253 },
    { lat: 49.435737, lng: 19.647196 },
    { lat: 49.435775, lng: 19.647036 },
    { lat: 49.435803, lng: 19.646911 },
    { lat: 49.435842, lng: 19.646797 },
    { lat: 49.435884, lng: 19.646677 },
    { lat: 49.435935, lng: 19.646594 },
    { lat: 49.436004, lng: 19.646566 },
    { lat: 49.436064, lng: 19.646564 },
    { lat: 49.436092, lng: 19.646589 },
    { lat: 49.436122, lng: 19.646636 },
    { lat: 49.43621, lng: 19.646614 },
    { lat: 49.436318, lng: 19.646512 },
    { lat: 49.436363, lng: 19.646479 },
    { lat: 49.436377, lng: 19.646474 },
    { lat: 49.436517, lng: 19.646457 },
    { lat: 49.436667, lng: 19.646406 },
    { lat: 49.436768, lng: 19.64643 },
    { lat: 49.436865, lng: 19.6464 },
    { lat: 49.436945, lng: 19.646375 },
    { lat: 49.437012, lng: 19.646313 },
    { lat: 49.437033, lng: 19.646178 },
    { lat: 49.437022, lng: 19.646062 },
    { lat: 49.436984, lng: 19.645955 },
    { lat: 49.436965, lng: 19.645931 },
    { lat: 49.436905, lng: 19.645984 },
    { lat: 49.43686, lng: 19.64588 },
    { lat: 49.436938, lng: 19.645619 },
    { lat: 49.436995, lng: 19.645504 },
    { lat: 49.437056, lng: 19.645378 },
    { lat: 49.437107, lng: 19.645184 },
    { lat: 49.43709, lng: 19.645119 },
    { lat: 49.437054, lng: 19.645087 },
    { lat: 49.437064, lng: 19.645055 },
    { lat: 49.437068, lng: 19.644985 },
    { lat: 49.437068, lng: 19.644861 },
    { lat: 49.4370593, lng: 19.6447448 },
    { lat: 49.437035, lng: 19.644608 },
    { lat: 49.437006, lng: 19.644489 },
    { lat: 49.436975, lng: 19.6444 },
    { lat: 49.4369504, lng: 19.6443411 },
    { lat: 49.436945, lng: 19.644334 },
    { lat: 49.4368488, lng: 19.6442753 },
    { lat: 49.4367635, lng: 19.644166 },
    { lat: 49.4366466, lng: 19.6441781 },
    { lat: 49.4365967, lng: 19.6441387 },
    { lat: 49.436593, lng: 19.644134 },
    { lat: 49.436594, lng: 19.644001 },
    { lat: 49.4366107, lng: 19.6439472 },
    { lat: 49.4366384, lng: 19.6439027 },
    { lat: 49.436698, lng: 19.643809 },
    { lat: 49.436728, lng: 19.643778 },
    { lat: 49.4368228, lng: 19.6437298 },
    { lat: 49.436876, lng: 19.643797 },
    { lat: 49.4369348, lng: 19.6438758 },
    { lat: 49.4369559, lng: 19.6438822 },
    { lat: 49.436982, lng: 19.643871 },
    { lat: 49.4370246, lng: 19.6438381 },
    { lat: 49.4370235, lng: 19.643745 },
    { lat: 49.4371009, lng: 19.6435305 },
    { lat: 49.4371598, lng: 19.6432954 },
    { lat: 49.437313, lng: 19.642776 },
    { lat: 49.4373792, lng: 19.6427814 },
    { lat: 49.4373901, lng: 19.6428041 },
    { lat: 49.4373886, lng: 19.6429027 },
    { lat: 49.4375178, lng: 19.6429454 },
    { lat: 49.4376353, lng: 19.6429513 },
    { lat: 49.437743, lng: 19.642987 },
    { lat: 49.437787, lng: 19.643004 },
    { lat: 49.437824, lng: 19.643019 },
    { lat: 49.437841, lng: 19.643022 },
    { lat: 49.4379092, lng: 19.6428817 },
    { lat: 49.4379369, lng: 19.6427639 },
    { lat: 49.4379944, lng: 19.6426302 },
    { lat: 49.438057, lng: 19.6424378 },
    { lat: 49.4380707, lng: 19.6424149 },
    { lat: 49.438191, lng: 19.6422732 },
    { lat: 49.4383526, lng: 19.64194 },
    { lat: 49.4383658, lng: 19.6418342 },
    { lat: 49.4383606, lng: 19.6416305 },
    { lat: 49.438361, lng: 19.6413933 },
    { lat: 49.4383863, lng: 19.64137 },
    { lat: 49.4384337, lng: 19.6413555 },
    { lat: 49.4384542, lng: 19.6413559 },
    { lat: 49.438472, lng: 19.641376 },
    { lat: 49.438512, lng: 19.641474 },
    { lat: 49.4385157, lng: 19.6414799 },
    { lat: 49.438559, lng: 19.641527 },
    { lat: 49.4385906, lng: 19.6415702 },
    { lat: 49.438602, lng: 19.641586 },
    { lat: 49.4386098, lng: 19.6416024 },
    { lat: 49.4386265, lng: 19.6416443 },
    { lat: 49.438616, lng: 19.641708 },
    { lat: 49.4386123, lng: 19.6417571 },
    { lat: 49.4386286, lng: 19.6418574 },
    { lat: 49.4387912, lng: 19.641861 },
    { lat: 49.4388783, lng: 19.6418175 },
    { lat: 49.4389718, lng: 19.6417289 },
    { lat: 49.439065, lng: 19.641473 },
    { lat: 49.4390834, lng: 19.641305 },
    { lat: 49.4392003, lng: 19.6411494 },
    { lat: 49.4393786, lng: 19.6410987 },
    { lat: 49.4394819, lng: 19.6410312 },
    { lat: 49.4395985, lng: 19.640854 },
    { lat: 49.4396215, lng: 19.6408087 },
    { lat: 49.4396953, lng: 19.6406579 },
    { lat: 49.4397131, lng: 19.6406025 },
    { lat: 49.4397353, lng: 19.6404552 },
    { lat: 49.4397081, lng: 19.6403891 },
    { lat: 49.4396821, lng: 19.6403831 },
    { lat: 49.439609, lng: 19.640457 },
    { lat: 49.4395271, lng: 19.6405204 },
    { lat: 49.43951, lng: 19.640519 },
    { lat: 49.4394617, lng: 19.6405039 },
    { lat: 49.4393972, lng: 19.6404939 },
    { lat: 49.4393728, lng: 19.6404362 },
    { lat: 49.4393796, lng: 19.6403574 },
    { lat: 49.439385, lng: 19.640274 },
    { lat: 49.439302, lng: 19.6400703 },
    { lat: 49.4393, lng: 19.6399809 },
    { lat: 49.4393169, lng: 19.6398808 },
    { lat: 49.4393776, lng: 19.6398294 },
    { lat: 49.4394151, lng: 19.6397846 },
    { lat: 49.4394801, lng: 19.6397268 },
    { lat: 49.4395047, lng: 19.6397136 },
    { lat: 49.4396293, lng: 19.6397241 },
    { lat: 49.439689, lng: 19.6397111 },
    { lat: 49.4398122, lng: 19.6396526 },
    { lat: 49.4398425, lng: 19.6396457 },
    { lat: 49.4399984, lng: 19.6396532 },
    { lat: 49.440024, lng: 19.6396363 },
    { lat: 49.4400779, lng: 19.6395854 },
    { lat: 49.4400957, lng: 19.6394474 },
    { lat: 49.4401603, lng: 19.6391985 },
    { lat: 49.4402054, lng: 19.6390786 },
    { lat: 49.4402696, lng: 19.6389474 },
    { lat: 49.440351, lng: 19.6387496 },
    { lat: 49.440362, lng: 19.638562 },
    { lat: 49.4403743, lng: 19.6384241 },
    { lat: 49.44037, lng: 19.638416 },
    { lat: 49.4403304, lng: 19.6383229 },
    { lat: 49.4402661, lng: 19.6383542 },
    { lat: 49.4402211, lng: 19.6382929 },
    { lat: 49.440218, lng: 19.6382886 },
    { lat: 49.4402276, lng: 19.6382535 },
    { lat: 49.4402709, lng: 19.6381753 },
    { lat: 49.440364, lng: 19.638088 },
    { lat: 49.4404257, lng: 19.6379157 },
    { lat: 49.4404491, lng: 19.6378789 },
    { lat: 49.4405085, lng: 19.637815 },
    { lat: 49.4405483, lng: 19.6377964 },
    { lat: 49.4407167, lng: 19.6377649 },
    { lat: 49.4408197, lng: 19.6378497 },
    { lat: 49.4409918, lng: 19.6379625 },
    { lat: 49.441053, lng: 19.638084 },
    { lat: 49.4410638, lng: 19.6381273 },
    { lat: 49.441075, lng: 19.63827 },
    { lat: 49.4410772, lng: 19.6382759 },
    { lat: 49.4411449, lng: 19.6383654 },
    { lat: 49.4412146, lng: 19.638347 },
    { lat: 49.441229, lng: 19.638372 },
    { lat: 49.441251, lng: 19.638425 },
    { lat: 49.4412539, lng: 19.6384312 },
    { lat: 49.4412986, lng: 19.638421 },
    { lat: 49.4413299, lng: 19.6383957 },
    { lat: 49.4414362, lng: 19.6382277 },
    { lat: 49.4414481, lng: 19.6381918 },
    { lat: 49.4414586, lng: 19.6381013 },
    { lat: 49.4414459, lng: 19.638048 },
    { lat: 49.4415006, lng: 19.6379266 },
    { lat: 49.4415173, lng: 19.6378596 },
    { lat: 49.4415123, lng: 19.6378163 },
    { lat: 49.4415634, lng: 19.6376955 },
    { lat: 49.4415904, lng: 19.6376534 },
    { lat: 49.4416329, lng: 19.6376392 },
    { lat: 49.4417073, lng: 19.6377181 },
    { lat: 49.441714, lng: 19.637733 },
    { lat: 49.4417218, lng: 19.6377369 },
    { lat: 49.4417695, lng: 19.6377403 },
    { lat: 49.4418068, lng: 19.6377013 },
    { lat: 49.441851, lng: 19.6375592 },
    { lat: 49.4419411, lng: 19.6375078 },
    { lat: 49.4420154, lng: 19.6375078 },
    { lat: 49.4420913, lng: 19.6375448 },
    { lat: 49.4421369, lng: 19.6376403 },
    { lat: 49.4423072, lng: 19.6377651 },
    { lat: 49.4424643, lng: 19.6379835 },
    { lat: 49.4427328, lng: 19.6381373 },
    { lat: 49.4427657, lng: 19.6381504 },
    { lat: 49.4429954, lng: 19.6381654 },
    { lat: 49.4431361, lng: 19.638216 },
    { lat: 49.4432086, lng: 19.6382292 },
    { lat: 49.4433124, lng: 19.6383185 },
    { lat: 49.443379, lng: 19.638333 },
    { lat: 49.4435623, lng: 19.6383881 },
    { lat: 49.443673, lng: 19.638456 },
    { lat: 49.443824, lng: 19.638551 },
    { lat: 49.443884, lng: 19.638588 },
    { lat: 49.4438989, lng: 19.6385976 },
    { lat: 49.444124, lng: 19.638036 },
    { lat: 49.444137, lng: 19.638004 },
    { lat: 49.4441722, lng: 19.6379206 },
    { lat: 49.444218, lng: 19.637876 },
    { lat: 49.44423, lng: 19.637864 },
    { lat: 49.444344, lng: 19.63776 },
    { lat: 49.444362, lng: 19.637743 },
    { lat: 49.444612, lng: 19.637513 },
    { lat: 49.444628, lng: 19.637498 },
    { lat: 49.4447798, lng: 19.6373606 },
    { lat: 49.444847, lng: 19.637404 },
    { lat: 49.445156, lng: 19.637605 },
    { lat: 49.445323, lng: 19.637716 },
    { lat: 49.4455695, lng: 19.6378765 },
    { lat: 49.4461282, lng: 19.6378359 },
    { lat: 49.446136, lng: 19.637833 },
    { lat: 49.4481295, lng: 19.6377852 },
    { lat: 49.448964, lng: 19.640114 },
    { lat: 49.449483, lng: 19.641563 },
    { lat: 49.4494855, lng: 19.6415676 },
    { lat: 49.449569, lng: 19.641572 },
    { lat: 49.450198, lng: 19.641642 },
    { lat: 49.450266, lng: 19.641649 },
    { lat: 49.4516921, lng: 19.6418087 },
    { lat: 49.452631, lng: 19.641697 },
    { lat: 49.4532583, lng: 19.6416264 },
    { lat: 49.4536864, lng: 19.6421555 },
    { lat: 49.4545591, lng: 19.6428876 },
    { lat: 49.4550794, lng: 19.642538 },
    { lat: 49.4575599, lng: 19.6411189 },
    { lat: 49.457518, lng: 19.641029 },
    { lat: 49.456227, lng: 19.63834 },
    { lat: 49.454611, lng: 19.634998 },
    { lat: 49.452629, lng: 19.631094 },
    { lat: 49.451302, lng: 19.628423 },
    { lat: 49.450573, lng: 19.627115 },
    { lat: 49.450097, lng: 19.62626 },
    { lat: 49.449612, lng: 19.62543 },
    { lat: 49.449359, lng: 19.624084 },
    { lat: 49.449058, lng: 19.622489 },
    { lat: 49.448715, lng: 19.62067 },
    { lat: 49.448445, lng: 19.619229 },
    { lat: 49.448204, lng: 19.617999 },
    { lat: 49.44798, lng: 19.61685 },
    { lat: 49.447694, lng: 19.615513 },
    { lat: 49.447427, lng: 19.614257 },
    { lat: 49.447215, lng: 19.613251 },
    { lat: 49.44705, lng: 19.612381 },
    { lat: 49.447041, lng: 19.612331 },
    { lat: 49.447016, lng: 19.612198 },
    { lat: 49.44701, lng: 19.612164 },
    { lat: 49.446973, lng: 19.611971 },
    { lat: 49.446957, lng: 19.611887 },
    { lat: 49.446941, lng: 19.611802 },
    { lat: 49.446924, lng: 19.611715 },
    { lat: 49.446873, lng: 19.611446 },
    { lat: 49.446995, lng: 19.611375 },
    { lat: 49.447339, lng: 19.61132 },
    { lat: 49.447585, lng: 19.611212 },
    { lat: 49.447692, lng: 19.611118 },
    { lat: 49.447776, lng: 19.610984 },
    { lat: 49.447804, lng: 19.610927 },
    { lat: 49.447895, lng: 19.610609 },
    { lat: 49.448018, lng: 19.610534 },
    { lat: 49.448087, lng: 19.610476 },
    { lat: 49.448148, lng: 19.610419 },
    { lat: 49.448155, lng: 19.610397 },
    { lat: 49.448154, lng: 19.610077 },
    { lat: 49.448233, lng: 19.609936 },
    { lat: 49.448248, lng: 19.609871 },
    { lat: 49.448261, lng: 19.609759 },
    { lat: 49.448186, lng: 19.609597 },
    { lat: 49.448201, lng: 19.609497 },
    { lat: 49.448275, lng: 19.60943 },
    { lat: 49.44838, lng: 19.609439 },
    { lat: 49.448413, lng: 19.609449 },
    { lat: 49.448485, lng: 19.609599 },
    { lat: 49.448505, lng: 19.609715 },
    { lat: 49.448477, lng: 19.609891 },
    { lat: 49.44851, lng: 19.609952 },
    { lat: 49.44854, lng: 19.609955 },
    { lat: 49.448583, lng: 19.609958 },
    { lat: 49.448692, lng: 19.609936 },
    { lat: 49.448948, lng: 19.609848 },
    { lat: 49.449129, lng: 19.609783 },
    { lat: 49.449276, lng: 19.609725 },
    { lat: 49.449385, lng: 19.609721 },
    { lat: 49.449438, lng: 19.609672 },
    { lat: 49.449475, lng: 19.609639 },
    { lat: 49.449518, lng: 19.609589 },
    { lat: 49.449537, lng: 19.609566 },
    { lat: 49.44964, lng: 19.609504 },
    { lat: 49.449768, lng: 19.609451 },
    { lat: 49.449813, lng: 19.609402 },
    { lat: 49.449847, lng: 19.609324 },
    { lat: 49.449882, lng: 19.609307 },
    { lat: 49.449908, lng: 19.609296 },
    { lat: 49.449989, lng: 19.609259 },
    { lat: 49.450026, lng: 19.609219 },
    { lat: 49.450139, lng: 19.60909 },
    { lat: 49.450157, lng: 19.609062 },
    { lat: 49.450184, lng: 19.609007 },
    { lat: 49.450211, lng: 19.60893 },
    { lat: 49.450227, lng: 19.608825 },
    { lat: 49.450225, lng: 19.608733 },
    { lat: 49.450217, lng: 19.608681 },
    { lat: 49.450214, lng: 19.608661 },
    { lat: 49.450201, lng: 19.608593 },
    { lat: 49.450183, lng: 19.608558 },
    { lat: 49.450173, lng: 19.60852 },
    { lat: 49.450179, lng: 19.608443 },
    { lat: 49.450206, lng: 19.608369 },
    { lat: 49.450234, lng: 19.608339 },
    { lat: 49.450262, lng: 19.608336 },
    { lat: 49.450269, lng: 19.608335 },
    { lat: 49.450305, lng: 19.608365 },
    { lat: 49.450361, lng: 19.608457 },
    { lat: 49.450394, lng: 19.608468 },
    { lat: 49.450435, lng: 19.608447 },
    { lat: 49.450462, lng: 19.608376 },
    { lat: 49.450462, lng: 19.608369 },
    { lat: 49.450469, lng: 19.608314 },
    { lat: 49.450466, lng: 19.608248 },
    { lat: 49.450461, lng: 19.60815 },
    { lat: 49.450478, lng: 19.60808 },
    { lat: 49.4505, lng: 19.608022 },
    { lat: 49.450535, lng: 19.607979 },
    { lat: 49.450604, lng: 19.607903 },
    { lat: 49.450623, lng: 19.607864 },
    { lat: 49.450636, lng: 19.607794 },
    { lat: 49.450663, lng: 19.607749 },
    { lat: 49.450726, lng: 19.607664 },
    { lat: 49.450785, lng: 19.607595 },
    { lat: 49.450826, lng: 19.607535 },
    { lat: 49.450837, lng: 19.607483 },
    { lat: 49.450839, lng: 19.607472 },
    { lat: 49.450841, lng: 19.60743 },
    { lat: 49.450831, lng: 19.607372 },
    { lat: 49.450803, lng: 19.607286 },
    { lat: 49.450784, lng: 19.607206 },
    { lat: 49.450787, lng: 19.607117 },
    { lat: 49.450806, lng: 19.606958 },
    { lat: 49.450838, lng: 19.6068 },
    { lat: 49.450887, lng: 19.606672 },
    { lat: 49.450957, lng: 19.606499 },
    { lat: 49.451, lng: 19.606415 },
    { lat: 49.451038, lng: 19.606374 },
    { lat: 49.451047, lng: 19.606375 },
    { lat: 49.451093, lng: 19.60638 },
    { lat: 49.45112, lng: 19.606371 },
    { lat: 49.451168, lng: 19.606326 },
    { lat: 49.451183, lng: 19.606258 },
    { lat: 49.451193, lng: 19.606218 },
    { lat: 49.451203, lng: 19.606178 },
    { lat: 49.451231, lng: 19.606122 },
    { lat: 49.451289, lng: 19.60605 },
    { lat: 49.451339, lng: 19.606016 },
    { lat: 49.451374, lng: 19.605992 },
    { lat: 49.451447, lng: 19.605953 },
    { lat: 49.451535, lng: 19.60594 },
    { lat: 49.451585, lng: 19.605954 },
    { lat: 49.451616, lng: 19.605974 },
    { lat: 49.451626, lng: 19.605981 },
    { lat: 49.451646, lng: 19.606022 },
    { lat: 49.451668, lng: 19.606103 },
    { lat: 49.451712, lng: 19.606125 },
    { lat: 49.451794, lng: 19.606139 },
    { lat: 49.451831, lng: 19.606121 },
    { lat: 49.451856, lng: 19.60607 },
    { lat: 49.451874, lng: 19.605985 },
    { lat: 49.451875, lng: 19.605981 },
    { lat: 49.451914, lng: 19.605828 },
    { lat: 49.451956, lng: 19.605745 },
    { lat: 49.452064, lng: 19.605578 },
    { lat: 49.452089, lng: 19.605567 },
    { lat: 49.452092, lng: 19.605566 },
    { lat: 49.452128, lng: 19.605568 },
    { lat: 49.452166, lng: 19.60559 },
    { lat: 49.452217, lng: 19.605643 },
    { lat: 49.452261, lng: 19.605715 },
    { lat: 49.452318, lng: 19.605786 },
    { lat: 49.452388, lng: 19.605835 },
    { lat: 49.452479, lng: 19.605931 },
    { lat: 49.452543, lng: 19.605953 },
    { lat: 49.452579, lng: 19.605957 },
    { lat: 49.452597, lng: 19.605948 },
    { lat: 49.45263, lng: 19.605921 },
    { lat: 49.452644, lng: 19.605846 },
    { lat: 49.452646, lng: 19.605836 },
    { lat: 49.45265, lng: 19.605788 },
    { lat: 49.452675, lng: 19.605727 },
    { lat: 49.452711, lng: 19.605703 },
    { lat: 49.452787, lng: 19.605706 },
    { lat: 49.452807, lng: 19.60572 },
    { lat: 49.45284, lng: 19.605765 },
    { lat: 49.452877, lng: 19.605816 },
    { lat: 49.452921, lng: 19.605848 },
    { lat: 49.453002, lng: 19.605914 },
    { lat: 49.453103, lng: 19.605936 },
    { lat: 49.453124, lng: 19.605944 },
    { lat: 49.453129, lng: 19.605943 },
    { lat: 49.453161, lng: 19.605938 },
    { lat: 49.453216, lng: 19.605945 },
    { lat: 49.453301, lng: 19.605976 },
    { lat: 49.453394, lng: 19.606036 },
    { lat: 49.453402, lng: 19.606042 },
    { lat: 49.453425, lng: 19.6061 },
    { lat: 49.453461, lng: 19.606137 },
    { lat: 49.453521, lng: 19.606154 },
    { lat: 49.453571, lng: 19.606137 },
    { lat: 49.453583, lng: 19.60607 },
    { lat: 49.45357, lng: 19.605976 },
    { lat: 49.453553, lng: 19.605923 },
    { lat: 49.453533, lng: 19.605852 },
    { lat: 49.453524, lng: 19.605792 },
    { lat: 49.453496, lng: 19.605762 },
    { lat: 49.453452, lng: 19.605738 },
    { lat: 49.453446, lng: 19.605735 },
    { lat: 49.453396, lng: 19.605687 },
    { lat: 49.453338, lng: 19.605614 },
    { lat: 49.453331, lng: 19.605577 },
    { lat: 49.453325, lng: 19.605553 },
    { lat: 49.453329, lng: 19.605521 },
    { lat: 49.453346, lng: 19.605491 },
    { lat: 49.453361, lng: 19.605464 },
    { lat: 49.453372, lng: 19.605355 },
    { lat: 49.453364, lng: 19.605325 },
    { lat: 49.453346, lng: 19.605273 },
    { lat: 49.453324, lng: 19.605233 },
    { lat: 49.453323, lng: 19.605232 },
    { lat: 49.453298, lng: 19.605206 },
    { lat: 49.453326, lng: 19.605072 },
    { lat: 49.453357, lng: 19.60503 },
    { lat: 49.453383, lng: 19.605032 },
    { lat: 49.453408, lng: 19.605053 },
    { lat: 49.453437, lng: 19.605103 },
    { lat: 49.453469, lng: 19.605113 },
    { lat: 49.453498, lng: 19.605063 },
    { lat: 49.453525, lng: 19.604979 },
    { lat: 49.453543, lng: 19.604878 },
    { lat: 49.45358, lng: 19.60481 },
    { lat: 49.453637, lng: 19.604767 },
    { lat: 49.453708, lng: 19.604728 },
    { lat: 49.453774, lng: 19.604726 },
    { lat: 49.453837, lng: 19.604741 },
    { lat: 49.45389, lng: 19.604755 },
    { lat: 49.453977, lng: 19.604747 },
    { lat: 49.454006, lng: 19.604773 },
    { lat: 49.454063, lng: 19.604764 },
    { lat: 49.454094, lng: 19.604781 },
    { lat: 49.45412, lng: 19.604825 },
    { lat: 49.454184, lng: 19.604868 },
    { lat: 49.454218, lng: 19.604857 },
    { lat: 49.454276, lng: 19.604804 },
    { lat: 49.454315, lng: 19.604777 },
    { lat: 49.454354, lng: 19.604724 },
    { lat: 49.45437, lng: 19.604669 },
    { lat: 49.454371, lng: 19.604627 },
    { lat: 49.454334, lng: 19.604565 },
    { lat: 49.454282, lng: 19.604472 },
    { lat: 49.454264, lng: 19.604472 },
    { lat: 49.454234, lng: 19.604472 },
    { lat: 49.45422, lng: 19.604471 },
    { lat: 49.454195, lng: 19.604468 },
    { lat: 49.454139, lng: 19.604462 },
    { lat: 49.454059, lng: 19.604391 },
    { lat: 49.454056, lng: 19.604369 },
    { lat: 49.45408, lng: 19.604252 },
    { lat: 49.454079, lng: 19.604199 },
    { lat: 49.454104, lng: 19.604182 },
    { lat: 49.454139, lng: 19.604234 },
    { lat: 49.454167, lng: 19.604275 },
    { lat: 49.454209, lng: 19.604303 },
    { lat: 49.454268, lng: 19.604259 },
    { lat: 49.4543, lng: 19.604213 },
    { lat: 49.454334, lng: 19.604129 },
    { lat: 49.454371, lng: 19.604065 },
    { lat: 49.454368, lng: 19.604036 },
    { lat: 49.454363, lng: 19.603981 },
    { lat: 49.454411, lng: 19.603946 },
    { lat: 49.45442, lng: 19.603939 },
    { lat: 49.454403, lng: 19.603841 },
    { lat: 49.454381, lng: 19.603785 },
    { lat: 49.454398, lng: 19.603699 },
    { lat: 49.454454, lng: 19.603627 },
    { lat: 49.454514, lng: 19.603604 },
    { lat: 49.454536, lng: 19.603569 },
    { lat: 49.454508, lng: 19.603473 },
    { lat: 49.454474, lng: 19.603397 },
    { lat: 49.454464, lng: 19.603302 },
    { lat: 49.454465, lng: 19.60319 },
    { lat: 49.454479, lng: 19.603115 },
    { lat: 49.454512, lng: 19.603061 },
    { lat: 49.454566, lng: 19.603022 },
    { lat: 49.454619, lng: 19.60302 },
    { lat: 49.454654, lng: 19.603019 },
    { lat: 49.454734, lng: 19.602987 },
    { lat: 49.454779, lng: 19.602958 },
    { lat: 49.454834, lng: 19.602942 },
    { lat: 49.454902, lng: 19.602966 },
    { lat: 49.454944, lng: 19.602961 },
    { lat: 49.455071, lng: 19.602879 },
    { lat: 49.455157, lng: 19.602779 },
    { lat: 49.455178, lng: 19.602754 },
    { lat: 49.455327, lng: 19.602573 },
    { lat: 49.45545, lng: 19.602443 },
    { lat: 49.455528, lng: 19.602357 },
    { lat: 49.455562, lng: 19.602336 },
    { lat: 49.455601, lng: 19.602293 },
    { lat: 49.455646, lng: 19.602154 },
    { lat: 49.455692, lng: 19.602015 },
    { lat: 49.455768, lng: 19.601847 },
    { lat: 49.455823, lng: 19.601518 },
    { lat: 49.45584, lng: 19.601374 },
    { lat: 49.455833, lng: 19.601307 },
    { lat: 49.455825, lng: 19.601225 },
    { lat: 49.45584, lng: 19.601136 },
    { lat: 49.455878, lng: 19.601055 },
    { lat: 49.45587, lng: 19.600981 },
    { lat: 49.455912, lng: 19.600778 },
    { lat: 49.455902, lng: 19.600748 },
    { lat: 49.45592, lng: 19.600671 },
    { lat: 49.455944, lng: 19.600614 },
    { lat: 49.455981, lng: 19.600582 },
    { lat: 49.455993, lng: 19.600573 },
    { lat: 49.456071, lng: 19.600518 },
    { lat: 49.456112, lng: 19.600509 },
    { lat: 49.456182, lng: 19.600493 },
    { lat: 49.456285, lng: 19.600435 },
    { lat: 49.456374, lng: 19.600379 },
    { lat: 49.456456, lng: 19.600376 },
    { lat: 49.456473, lng: 19.600348 },
    { lat: 49.456515, lng: 19.60025 },
    { lat: 49.456555, lng: 19.600196 },
    { lat: 49.456556, lng: 19.600187 },
    { lat: 49.456564, lng: 19.600097 },
    { lat: 49.45662, lng: 19.600035 },
    { lat: 49.456644, lng: 19.600026 },
    { lat: 49.456744, lng: 19.599992 },
    { lat: 49.456789, lng: 19.599926 },
    { lat: 49.456801, lng: 19.599808 },
    { lat: 49.456716, lng: 19.599708 },
    { lat: 49.456634, lng: 19.599719 },
    { lat: 49.456601, lng: 19.599723 },
    { lat: 49.456591, lng: 19.599725 },
    { lat: 49.456537, lng: 19.599713 },
    { lat: 49.456507, lng: 19.599708 },
    { lat: 49.4565, lng: 19.599672 },
    { lat: 49.456487, lng: 19.599607 },
    { lat: 49.456513, lng: 19.599578 },
    { lat: 49.456586, lng: 19.59952 },
    { lat: 49.456611, lng: 19.599436 },
    { lat: 49.456651, lng: 19.599313 },
    { lat: 49.456689, lng: 19.599269 },
    { lat: 49.456769, lng: 19.599186 },
    { lat: 49.456834, lng: 19.599097 },
    { lat: 49.456877, lng: 19.599054 },
    { lat: 49.456926, lng: 19.59907 },
    { lat: 49.456938, lng: 19.599073 },
    { lat: 49.457002, lng: 19.599091 },
    { lat: 49.457092, lng: 19.599103 },
    { lat: 49.457109, lng: 19.599129 },
    { lat: 49.457056, lng: 19.599277 },
    { lat: 49.457056, lng: 19.599281 },
    { lat: 49.457049, lng: 19.599349 },
    { lat: 49.457047, lng: 19.599361 },
    { lat: 49.457027, lng: 19.599399 },
    { lat: 49.457009, lng: 19.599412 },
    { lat: 49.456979, lng: 19.599407 },
    { lat: 49.456958, lng: 19.599428 },
    { lat: 49.456931, lng: 19.599465 },
    { lat: 49.456935, lng: 19.599519 },
    { lat: 49.456957, lng: 19.599575 },
    { lat: 49.456998, lng: 19.599594 },
    { lat: 49.45704, lng: 19.599601 },
    { lat: 49.457121, lng: 19.599637 },
    { lat: 49.457171, lng: 19.599635 },
    { lat: 49.457203, lng: 19.599633 },
    { lat: 49.457259, lng: 19.599619 },
    { lat: 49.457283, lng: 19.599603 },
    { lat: 49.457323, lng: 19.599528 },
    { lat: 49.457364, lng: 19.599398 },
    { lat: 49.457374, lng: 19.599365 },
    { lat: 49.457369, lng: 19.599323 },
    { lat: 49.457366, lng: 19.599308 },
    { lat: 49.457352, lng: 19.599261 },
    { lat: 49.457326, lng: 19.599172 },
    { lat: 49.457327, lng: 19.599103 },
    { lat: 49.457351, lng: 19.59905 },
    { lat: 49.45737, lng: 19.599023 },
    { lat: 49.457396, lng: 19.599008 },
    { lat: 49.457432, lng: 19.598985 },
    { lat: 49.457524, lng: 19.598964 },
    { lat: 49.457597, lng: 19.598931 },
    { lat: 49.457647, lng: 19.598872 },
    { lat: 49.4577, lng: 19.598749 },
    { lat: 49.45782, lng: 19.598421 },
    { lat: 49.457891, lng: 19.598346 },
    { lat: 49.457928, lng: 19.598226 },
    { lat: 49.457924, lng: 19.598112 },
    { lat: 49.457902, lng: 19.597873 },
    { lat: 49.457904, lng: 19.597826 },
    { lat: 49.457915, lng: 19.597718 },
    { lat: 49.457938, lng: 19.597636 },
    { lat: 49.457989, lng: 19.597583 },
    { lat: 49.458057, lng: 19.597559 },
    { lat: 49.458062, lng: 19.59756 },
    { lat: 49.458131, lng: 19.597587 },
    { lat: 49.458199, lng: 19.597638 },
    { lat: 49.458233, lng: 19.597653 },
    { lat: 49.458284, lng: 19.597653 },
    { lat: 49.458423, lng: 19.597594 },
    { lat: 49.458486, lng: 19.597543 },
    { lat: 49.458533, lng: 19.597506 },
    { lat: 49.4586318, lng: 19.5974358 },
    { lat: 49.458548, lng: 19.597232 },
    { lat: 49.458377, lng: 19.596793 },
    { lat: 49.458316, lng: 19.596619 },
    { lat: 49.458298, lng: 19.596518 },
    { lat: 49.458297, lng: 19.596376 },
    { lat: 49.458323, lng: 19.596221 },
    { lat: 49.458397, lng: 19.595894 },
    { lat: 49.458517, lng: 19.59538 },
    { lat: 49.458545, lng: 19.595245 },
    { lat: 49.458582, lng: 19.595037 },
    { lat: 49.458607, lng: 19.594817 },
    { lat: 49.458639, lng: 19.59447 },
    { lat: 49.45867, lng: 19.594043 },
    { lat: 49.458676, lng: 19.593776 },
    { lat: 49.45866, lng: 19.593046 },
    { lat: 49.458636, lng: 19.59263 },
    { lat: 49.458585, lng: 19.592208 },
    { lat: 49.458481, lng: 19.591471 },
    { lat: 49.458117, lng: 19.589127 },
    { lat: 49.458069, lng: 19.58888 },
    { lat: 49.457974, lng: 19.588554 },
    { lat: 49.457878, lng: 19.588298 },
    { lat: 49.4578597, lng: 19.5882545 },
    { lat: 49.457842, lng: 19.588217 },
    { lat: 49.457742, lng: 19.588038 },
    { lat: 49.457555, lng: 19.587753 },
    { lat: 49.457116, lng: 19.587096 },
    { lat: 49.45698, lng: 19.586872 },
    { lat: 49.456867, lng: 19.586643 },
    { lat: 49.456755, lng: 19.586393 },
    { lat: 49.456599, lng: 19.585917 },
    { lat: 49.456514, lng: 19.585584 },
    { lat: 49.45647, lng: 19.585386 },
    { lat: 49.45643, lng: 19.585164 },
    { lat: 49.456364, lng: 19.584758 },
    { lat: 49.456324, lng: 19.5845 },
    { lat: 49.456259, lng: 19.584236 },
    { lat: 49.456143, lng: 19.583839 },
    { lat: 49.45601, lng: 19.583425 },
    { lat: 49.455864, lng: 19.582962 },
    { lat: 49.455712, lng: 19.582483 },
    { lat: 49.455592, lng: 19.582087 },
    { lat: 49.455499, lng: 19.581776 },
    { lat: 49.4554092, lng: 19.5814677 },
    { lat: 49.455357, lng: 19.581509 },
    { lat: 49.45522, lng: 19.581637 },
    { lat: 49.45521, lng: 19.58172 },
    { lat: 49.455134, lng: 19.581812 },
    { lat: 49.455081, lng: 19.581837 },
    { lat: 49.455005, lng: 19.581766 },
    { lat: 49.454944, lng: 19.58195 },
    { lat: 49.454869, lng: 19.581946 },
    { lat: 49.454883, lng: 19.58201 },
    { lat: 49.454846, lng: 19.582042 },
    { lat: 49.454765, lng: 19.582007 },
    { lat: 49.454704, lng: 19.581893 },
    { lat: 49.454649, lng: 19.581912 },
    { lat: 49.454625, lng: 19.581861 },
    { lat: 49.454664, lng: 19.581837 },
    { lat: 49.454632, lng: 19.581749 },
    { lat: 49.454497, lng: 19.581758 },
    { lat: 49.454392, lng: 19.581882 },
    { lat: 49.454405, lng: 19.581991 },
    { lat: 49.454437, lng: 19.582036 },
    { lat: 49.454448, lng: 19.582129 },
    { lat: 49.454397, lng: 19.582179 },
    { lat: 49.454325, lng: 19.582116 },
    { lat: 49.454306, lng: 19.582027 },
    { lat: 49.454235, lng: 19.581957 },
    { lat: 49.454129, lng: 19.582039 },
    { lat: 49.454107, lng: 19.582048 },
    { lat: 49.454143, lng: 19.581871 },
    { lat: 49.454136, lng: 19.581844 },
    { lat: 49.4541, lng: 19.581849 },
    { lat: 49.454039, lng: 19.581764 },
    { lat: 49.453981, lng: 19.581839 },
    { lat: 49.453965, lng: 19.581845 },
    { lat: 49.45396, lng: 19.581674 },
    { lat: 49.453919, lng: 19.58162 },
    { lat: 49.453908, lng: 19.581622 },
    { lat: 49.453906, lng: 19.581684 },
    { lat: 49.453788, lng: 19.581806 },
    { lat: 49.45379, lng: 19.581854 },
    { lat: 49.453852, lng: 19.581849 },
    { lat: 49.453854, lng: 19.581882 },
    { lat: 49.453816, lng: 19.581914 },
    { lat: 49.453782, lng: 19.581974 },
    { lat: 49.453638, lng: 19.581992 },
    { lat: 49.453588, lng: 19.582098 },
    { lat: 49.453489, lng: 19.582031 },
    { lat: 49.453428, lng: 19.582096 },
    { lat: 49.453375, lng: 19.582098 },
    { lat: 49.453332, lng: 19.582137 },
    { lat: 49.453259, lng: 19.582117 },
    { lat: 49.453245, lng: 19.582032 },
    { lat: 49.453173, lng: 19.581935 },
    { lat: 49.453163, lng: 19.58195 },
    { lat: 49.453172, lng: 19.582039 },
    { lat: 49.45311, lng: 19.582147 },
    { lat: 49.453104, lng: 19.58217 },
    { lat: 49.453091, lng: 19.582351 },
    { lat: 49.45314, lng: 19.582454 },
    { lat: 49.453156, lng: 19.582649 },
    { lat: 49.453115, lng: 19.582681 },
    { lat: 49.453043, lng: 19.582595 },
    { lat: 49.453001, lng: 19.582731 },
    { lat: 49.452959, lng: 19.582777 },
    { lat: 49.452844, lng: 19.582639 },
    { lat: 49.452807, lng: 19.582701 },
    { lat: 49.452883, lng: 19.582843 },
    { lat: 49.452871, lng: 19.582912 },
    { lat: 49.452863, lng: 19.582924 },
    { lat: 49.452826, lng: 19.582948 },
    { lat: 49.452755, lng: 19.583055 },
    { lat: 49.452602, lng: 19.58302 },
    { lat: 49.45248, lng: 19.582946 },
    { lat: 49.452497, lng: 19.582888 },
    { lat: 49.452437, lng: 19.582797 },
    { lat: 49.452307, lng: 19.582748 },
    { lat: 49.452241, lng: 19.58277 },
    { lat: 49.452226, lng: 19.582833 },
    { lat: 49.452095, lng: 19.582856 },
    { lat: 49.452064, lng: 19.582914 },
    { lat: 49.452079, lng: 19.582973 },
    { lat: 49.451994, lng: 19.583012 },
    { lat: 49.451938, lng: 19.58312 },
    { lat: 49.451824, lng: 19.583046 },
    { lat: 49.451772, lng: 19.582939 },
    { lat: 49.4518, lng: 19.58281 },
    { lat: 49.451745, lng: 19.582731 },
    { lat: 49.451558, lng: 19.582773 },
    { lat: 49.451562, lng: 19.58293 },
    { lat: 49.451543, lng: 19.583005 },
    { lat: 49.451465, lng: 19.583072 },
    { lat: 49.451377, lng: 19.583049 },
    { lat: 49.451288, lng: 19.583061 },
    { lat: 49.451217, lng: 19.583165 },
    { lat: 49.451159, lng: 19.583122 },
    { lat: 49.451079, lng: 19.583151 },
    { lat: 49.450945, lng: 19.583046 },
    { lat: 49.450842, lng: 19.583155 },
    { lat: 49.450662, lng: 19.583254 },
    { lat: 49.450664, lng: 19.583358 },
    { lat: 49.450612, lng: 19.583459 },
    { lat: 49.450419, lng: 19.583548 },
    { lat: 49.450377, lng: 19.583387 },
    { lat: 49.450249, lng: 19.583405 },
    { lat: 49.450241, lng: 19.583687 },
    { lat: 49.450276, lng: 19.583942 },
    { lat: 49.450278, lng: 19.58405 },
    { lat: 49.450261, lng: 19.584167 },
    { lat: 49.450186, lng: 19.584204 },
    { lat: 49.450142, lng: 19.584202 },
    { lat: 49.450093, lng: 19.584317 },
    { lat: 49.449993, lng: 19.584392 },
    { lat: 49.44995, lng: 19.584364 },
    { lat: 49.449843, lng: 19.584162 },
    { lat: 49.449895, lng: 19.583875 },
    { lat: 49.44988, lng: 19.583852 },
    { lat: 49.449776, lng: 19.583883 },
    { lat: 49.449762, lng: 19.583999 },
    { lat: 49.449396, lng: 19.584546 },
    { lat: 49.449431, lng: 19.584658 },
    { lat: 49.449365, lng: 19.584781 },
    { lat: 49.449411, lng: 19.58491 },
    { lat: 49.449407, lng: 19.585059 },
    { lat: 49.449316, lng: 19.585265 },
    { lat: 49.449217, lng: 19.585143 },
    { lat: 49.449163, lng: 19.585174 },
    { lat: 49.449153, lng: 19.585209 },
    { lat: 49.449221, lng: 19.585501 },
    { lat: 49.449175, lng: 19.585577 },
    { lat: 49.449138, lng: 19.585533 },
    { lat: 49.449051, lng: 19.585497 },
    { lat: 49.449046, lng: 19.585339 },
    { lat: 49.449001, lng: 19.585307 },
    { lat: 49.448955, lng: 19.585507 },
    { lat: 49.449047, lng: 19.585767 },
    { lat: 49.449052, lng: 19.585932 },
    { lat: 49.448909, lng: 19.585966 },
    { lat: 49.448824, lng: 19.585838 },
    { lat: 49.448672, lng: 19.585809 },
    { lat: 49.448589, lng: 19.585703 },
    { lat: 49.448558, lng: 19.585708 },
    { lat: 49.448536, lng: 19.585773 },
    { lat: 49.448539, lng: 19.585929 },
    { lat: 49.448456, lng: 19.585985 },
    { lat: 49.448379, lng: 19.585866 },
    { lat: 49.448262, lng: 19.585879 },
    { lat: 49.448089, lng: 19.58603 },
    { lat: 49.447921, lng: 19.585938 },
    { lat: 49.447787, lng: 19.585954 },
    { lat: 49.447693, lng: 19.586062 },
    { lat: 49.447788, lng: 19.586275 },
    { lat: 49.447836, lng: 19.586494 },
    { lat: 49.447741, lng: 19.586525 },
    { lat: 49.447686, lng: 19.586461 },
    { lat: 49.447682, lng: 19.586371 },
    { lat: 49.447627, lng: 19.58629 },
    { lat: 49.44754, lng: 19.58638 },
    { lat: 49.447484, lng: 19.586706 },
    { lat: 49.447415, lng: 19.586715 },
    { lat: 49.44729, lng: 19.586582 },
    { lat: 49.447118, lng: 19.586634 },
    { lat: 49.447081, lng: 19.586711 },
    { lat: 49.447071, lng: 19.586743 },
    { lat: 49.447227, lng: 19.58685 },
    { lat: 49.447232, lng: 19.586957 },
    { lat: 49.446908, lng: 19.587056 },
    { lat: 49.446655, lng: 19.587315 },
    { lat: 49.446582, lng: 19.587491 },
    { lat: 49.446588, lng: 19.587933 },
    { lat: 49.446735, lng: 19.588121 },
    { lat: 49.446796, lng: 19.588256 },
    { lat: 49.446724, lng: 19.588398 },
    { lat: 49.446587, lng: 19.588429 },
    { lat: 49.446579, lng: 19.58845 },
    { lat: 49.446643, lng: 19.588585 },
    { lat: 49.446654, lng: 19.588698 },
    { lat: 49.446466, lng: 19.58894 },
    { lat: 49.446135, lng: 19.588924 },
    { lat: 49.446033, lng: 19.589021 },
    { lat: 49.446, lng: 19.588919 },
    { lat: 49.445823, lng: 19.588793 },
    { lat: 49.445792, lng: 19.588883 },
    { lat: 49.44587, lng: 19.589261 },
    { lat: 49.445738, lng: 19.589482 },
    { lat: 49.445676, lng: 19.589413 },
    { lat: 49.445598, lng: 19.589178 },
    { lat: 49.445588, lng: 19.589169 },
    { lat: 49.445577, lng: 19.589178 },
    { lat: 49.445476, lng: 19.589357 },
    { lat: 49.445445, lng: 19.589534 },
    { lat: 49.445394, lng: 19.589598 },
    { lat: 49.445357, lng: 19.589575 },
    { lat: 49.445353, lng: 19.589394 },
    { lat: 49.44528, lng: 19.589375 },
    { lat: 49.445223, lng: 19.58926 },
    { lat: 49.445218, lng: 19.58925 },
    { lat: 49.445206, lng: 19.589249 },
    { lat: 49.445127, lng: 19.589351 },
    { lat: 49.444867, lng: 19.589415 },
    { lat: 49.444936, lng: 19.589589 },
    { lat: 49.445102, lng: 19.589525 },
    { lat: 49.445127, lng: 19.589581 },
    { lat: 49.445105, lng: 19.589693 },
    { lat: 49.445037, lng: 19.58979 },
    { lat: 49.444794, lng: 19.589655 },
    { lat: 49.444751, lng: 19.589618 },
    { lat: 49.444728, lng: 19.589558 },
    { lat: 49.444725, lng: 19.589468 },
    { lat: 49.444715, lng: 19.589448 },
    { lat: 49.444591, lng: 19.589401 },
    { lat: 49.444436, lng: 19.589685 },
    { lat: 49.44439, lng: 19.589694 },
    { lat: 49.44424, lng: 19.589377 },
    { lat: 49.444162, lng: 19.589441 },
    { lat: 49.444199, lng: 19.58978 },
    { lat: 49.444253, lng: 19.589904 },
    { lat: 49.444218, lng: 19.589953 },
    { lat: 49.44404, lng: 19.58987 },
    { lat: 49.443986, lng: 19.589703 },
    { lat: 49.44391, lng: 19.589666 },
    { lat: 49.443661, lng: 19.58972 },
    { lat: 49.443661, lng: 19.589798 },
    { lat: 49.443751, lng: 19.58989 },
    { lat: 49.443729, lng: 19.590004 },
    { lat: 49.443552, lng: 19.590095 },
    { lat: 49.44349, lng: 19.590248 },
    { lat: 49.443477, lng: 19.590264 },
    { lat: 49.443351, lng: 19.590275 },
    { lat: 49.443216, lng: 19.590381 },
    { lat: 49.443126, lng: 19.590339 },
    { lat: 49.443113, lng: 19.590259 },
    { lat: 49.443266, lng: 19.590095 },
    { lat: 49.443203, lng: 19.59002 },
    { lat: 49.443037, lng: 19.590117 },
    { lat: 49.442883, lng: 19.590336 },
    { lat: 49.442768, lng: 19.590374 },
    { lat: 49.442671, lng: 19.590348 },
    { lat: 49.442633, lng: 19.590259 },
    { lat: 49.442664, lng: 19.590219 },
    { lat: 49.442666, lng: 19.590124 },
    { lat: 49.442499, lng: 19.590113 },
    { lat: 49.442528, lng: 19.589966 },
    { lat: 49.442452, lng: 19.589855 },
    { lat: 49.442391, lng: 19.589927 },
    { lat: 49.442379, lng: 19.590121 },
    { lat: 49.442336, lng: 19.590173 },
    { lat: 49.442269, lng: 19.590169 },
    { lat: 49.442257, lng: 19.590174 },
    { lat: 49.442159, lng: 19.590245 },
    { lat: 49.44205, lng: 19.590297 },
    { lat: 49.441803, lng: 19.590382 },
    { lat: 49.441703, lng: 19.590461 },
    { lat: 49.441632, lng: 19.590573 },
    { lat: 49.441562, lng: 19.590916 },
    { lat: 49.441556, lng: 19.591359 },
    { lat: 49.441311, lng: 19.591534 },
    { lat: 49.441127, lng: 19.591703 },
    { lat: 49.441102, lng: 19.591974 },
    { lat: 49.44119, lng: 19.592341 },
    { lat: 49.441242, lng: 19.592481 },
    { lat: 49.441279, lng: 19.592641 },
    { lat: 49.441084, lng: 19.593027 },
    { lat: 49.441, lng: 19.593095 },
    { lat: 49.440992, lng: 19.593225 },
    { lat: 49.440995, lng: 19.593256 },
    { lat: 49.441129, lng: 19.593698 },
    { lat: 49.441096, lng: 19.593848 },
    { lat: 49.440933, lng: 19.593893 },
    { lat: 49.440656, lng: 19.593883 },
    { lat: 49.440591, lng: 19.594022 },
    { lat: 49.440535, lng: 19.594077 },
    { lat: 49.44051, lng: 19.594206 },
    { lat: 49.440439, lng: 19.594224 },
    { lat: 49.440419, lng: 19.594238 },
    { lat: 49.440389, lng: 19.594279 },
    { lat: 49.440357, lng: 19.594402 },
    { lat: 49.440361, lng: 19.594452 },
    { lat: 49.440376, lng: 19.59462 },
    { lat: 49.440446, lng: 19.594707 },
    { lat: 49.440407, lng: 19.594902 },
    { lat: 49.440398, lng: 19.594937 },
    { lat: 49.44037, lng: 19.594956 },
    { lat: 49.440315, lng: 19.594962 },
    { lat: 49.440127, lng: 19.59489 },
    { lat: 49.44011, lng: 19.594892 },
    { lat: 49.439944, lng: 19.595232 },
    { lat: 49.439834, lng: 19.595302 },
    { lat: 49.439658, lng: 19.595307 },
    { lat: 49.439535, lng: 19.595411 },
    { lat: 49.439529, lng: 19.595537 },
    { lat: 49.439613, lng: 19.595616 },
    { lat: 49.439672, lng: 19.595631 },
    { lat: 49.439787, lng: 19.595745 },
    { lat: 49.439832, lng: 19.595845 },
    { lat: 49.439826, lng: 19.595881 },
    { lat: 49.439809, lng: 19.595897 },
    { lat: 49.439494, lng: 19.595827 },
    { lat: 49.439427, lng: 19.595855 },
    { lat: 49.439311, lng: 19.595813 },
    { lat: 49.439207, lng: 19.595806 },
    { lat: 49.43905, lng: 19.59568 },
    { lat: 49.439036, lng: 19.595678 },
    { lat: 49.438976, lng: 19.595721 },
    { lat: 49.438881, lng: 19.595831 },
    { lat: 49.438698, lng: 19.595972 },
    { lat: 49.438687, lng: 19.596166 },
    { lat: 49.438687, lng: 19.59622 },
    { lat: 49.438733, lng: 19.596397 },
    { lat: 49.438866, lng: 19.596484 },
    { lat: 49.438889, lng: 19.596607 },
    { lat: 49.438865, lng: 19.596628 },
    { lat: 49.438656, lng: 19.59655 },
    { lat: 49.438613, lng: 19.596608 },
    { lat: 49.438647, lng: 19.596725 },
    { lat: 49.438619, lng: 19.596843 },
    { lat: 49.438601, lng: 19.596866 },
    { lat: 49.438506, lng: 19.596816 },
    { lat: 49.438369, lng: 19.596793 },
    { lat: 49.438302, lng: 19.596696 },
    { lat: 49.438316, lng: 19.596576 },
    { lat: 49.43836, lng: 19.596496 },
    { lat: 49.438271, lng: 19.596215 },
    { lat: 49.43822, lng: 19.596172 },
    { lat: 49.438167, lng: 19.596163 },
    { lat: 49.438136, lng: 19.59621 },
    { lat: 49.43811, lng: 19.596327 },
    { lat: 49.437982, lng: 19.596438 },
    { lat: 49.43786, lng: 19.596585 },
    { lat: 49.43777, lng: 19.596618 },
    { lat: 49.437713, lng: 19.596558 },
    { lat: 49.437607, lng: 19.596572 },
    { lat: 49.437543, lng: 19.596643 },
    { lat: 49.437477, lng: 19.596615 },
    { lat: 49.437468, lng: 19.596491 },
    { lat: 49.437511, lng: 19.596443 },
    { lat: 49.437487, lng: 19.596335 },
    { lat: 49.437435, lng: 19.596339 },
    { lat: 49.437346, lng: 19.59651 },
    { lat: 49.437283, lng: 19.596485 },
    { lat: 49.437219, lng: 19.596327 },
    { lat: 49.437154, lng: 19.596299 },
    { lat: 49.437123, lng: 19.596351 },
    { lat: 49.437126, lng: 19.596814 },
    { lat: 49.437082, lng: 19.596881 },
    { lat: 49.436978, lng: 19.59687 },
    { lat: 49.436761, lng: 19.596598 },
    { lat: 49.436703, lng: 19.596392 },
    { lat: 49.436654, lng: 19.596157 },
    { lat: 49.436618, lng: 19.59598 },
    { lat: 49.4364, lng: 19.596003 },
    { lat: 49.436375, lng: 19.595933 },
    { lat: 49.43515, lng: 19.593876 },
    { lat: 49.434667, lng: 19.593061 },
    { lat: 49.434619, lng: 19.592935 },
    { lat: 49.434661, lng: 19.591944 },
    { lat: 49.43454, lng: 19.591731 },
    { lat: 49.434331, lng: 19.591592 },
    { lat: 49.433602, lng: 19.591552 },
    { lat: 49.433556, lng: 19.591108 },
    { lat: 49.433382, lng: 19.590633 },
    { lat: 49.433363, lng: 19.59034 },
    { lat: 49.433198, lng: 19.590066 },
    { lat: 49.432979, lng: 19.589939 },
    { lat: 49.432928, lng: 19.589221 },
    { lat: 49.432859, lng: 19.588851 },
    { lat: 49.432749, lng: 19.588572 },
    { lat: 49.432554, lng: 19.588329 },
    { lat: 49.432556, lng: 19.587969 },
    { lat: 49.432392, lng: 19.587588 },
    { lat: 49.431939, lng: 19.587198 },
    { lat: 49.431467, lng: 19.587154 },
    { lat: 49.431032, lng: 19.58656 },
    { lat: 49.430974, lng: 19.586295 },
    { lat: 49.430977, lng: 19.585781 },
    { lat: 49.430508, lng: 19.584775 },
    { lat: 49.430257, lng: 19.58461 },
    { lat: 49.430149, lng: 19.584374 },
    { lat: 49.427993, lng: 19.58071 },
    { lat: 49.427921, lng: 19.580268 },
    { lat: 49.42715, lng: 19.579175 },
    { lat: 49.424906, lng: 19.576403 },
    { lat: 49.42317, lng: 19.573838 },
    { lat: 49.422948, lng: 19.573524 },
    { lat: 49.421802, lng: 19.572044 },
    { lat: 49.421556, lng: 19.571592 },
    { lat: 49.42127, lng: 19.571229 },
    { lat: 49.42122, lng: 19.571167 },
    { lat: 49.421063, lng: 19.570995 },
    { lat: 49.420863, lng: 19.570811 },
    { lat: 49.420769, lng: 19.570726 },
    { lat: 49.420407, lng: 19.570438 },
    { lat: 49.420247, lng: 19.570381 },
    { lat: 49.420046, lng: 19.570236 },
    { lat: 49.419232, lng: 19.569821 },
    { lat: 49.419144, lng: 19.569758 },
    { lat: 49.418796, lng: 19.569645 },
    { lat: 49.418563, lng: 19.569624 },
    { lat: 49.418422, lng: 19.569759 },
    { lat: 49.417859, lng: 19.569779 },
    { lat: 49.417736, lng: 19.569775 },
    { lat: 49.417582, lng: 19.568613 },
    { lat: 49.417601, lng: 19.567785 },
    { lat: 49.417608, lng: 19.567426 },
    { lat: 49.417614, lng: 19.567326 },
    { lat: 49.417826, lng: 19.566736 },
    { lat: 49.417904, lng: 19.566547 },
    { lat: 49.418179, lng: 19.565906 },
    { lat: 49.417663, lng: 19.565192 },
    { lat: 49.417166, lng: 19.564502 },
    { lat: 49.41685, lng: 19.563349 },
    { lat: 49.416841, lng: 19.563313 },
    { lat: 49.416482, lng: 19.562414 },
    { lat: 49.415889, lng: 19.560932 },
    { lat: 49.415501, lng: 19.560358 },
    { lat: 49.415391, lng: 19.560266 },
    { lat: 49.415285, lng: 19.560178 },
    { lat: 49.415278, lng: 19.560173 },
    { lat: 49.414954, lng: 19.559881 },
    { lat: 49.414918, lng: 19.559849 },
    { lat: 49.414874, lng: 19.559816 },
    { lat: 49.414621, lng: 19.559624 },
    { lat: 49.414493, lng: 19.5594 },
    { lat: 49.414346, lng: 19.559108 },
    { lat: 49.413995, lng: 19.558324 },
    { lat: 49.413984, lng: 19.558289 },
    { lat: 49.413755, lng: 19.557497 },
    { lat: 49.413745, lng: 19.557478 },
    { lat: 49.413622, lng: 19.557217 },
    { lat: 49.413559, lng: 19.557087 },
  ],
  Tvrdošín: [
    { lat: 49.3188036, lng: 19.7346964 },
    { lat: 49.3183634, lng: 19.73403 },
    { lat: 49.3181851, lng: 19.7337351 },
    { lat: 49.3178939, lng: 19.7333313 },
    { lat: 49.3177614, lng: 19.7337852 },
    { lat: 49.3166804, lng: 19.7358903 },
    { lat: 49.3165027, lng: 19.7360478 },
    { lat: 49.3156908, lng: 19.7367285 },
    { lat: 49.314462, lng: 19.737612 },
    { lat: 49.3142841, lng: 19.7377442 },
    { lat: 49.3138978, lng: 19.7378233 },
    { lat: 49.3136535, lng: 19.7378778 },
    { lat: 49.3130635, lng: 19.7376736 },
    { lat: 49.3129839, lng: 19.737555 },
    { lat: 49.312698, lng: 19.7373523 },
    { lat: 49.3124668, lng: 19.7371668 },
    { lat: 49.3122576, lng: 19.7369419 },
    { lat: 49.3121237, lng: 19.7367881 },
    { lat: 49.3116538, lng: 19.7361893 },
    { lat: 49.3113515, lng: 19.7359255 },
    { lat: 49.3110146, lng: 19.7355339 },
    { lat: 49.3109391, lng: 19.7354527 },
    { lat: 49.3108618, lng: 19.7353866 },
    { lat: 49.3108185, lng: 19.7353242 },
    { lat: 49.3103534, lng: 19.7347868 },
    { lat: 49.3101388, lng: 19.7345895 },
    { lat: 49.3099337, lng: 19.7343317 },
    { lat: 49.3098263, lng: 19.7342921 },
    { lat: 49.3096813, lng: 19.7340697 },
    { lat: 49.3094786, lng: 19.7338925 },
    { lat: 49.3093094, lng: 19.7338058 },
    { lat: 49.3091489, lng: 19.7336937 },
    { lat: 49.3089509, lng: 19.7335904 },
    { lat: 49.3088453, lng: 19.7334701 },
    { lat: 49.3087081, lng: 19.7331162 },
    { lat: 49.3086114, lng: 19.7329409 },
    { lat: 49.308394, lng: 19.73268 },
    { lat: 49.3081726, lng: 19.732356 },
    { lat: 49.3078571, lng: 19.7320438 },
    { lat: 49.3077354, lng: 19.7319104 },
    { lat: 49.3075776, lng: 19.731682 },
    { lat: 49.3075371, lng: 19.7316024 },
    { lat: 49.307368, lng: 19.7313132 },
    { lat: 49.3072873, lng: 19.731146 },
    { lat: 49.3071135, lng: 19.7308261 },
    { lat: 49.306922, lng: 19.7305966 },
    { lat: 49.3066239, lng: 19.7303205 },
    { lat: 49.3063767, lng: 19.730023 },
    { lat: 49.3054754, lng: 19.7289107 },
    { lat: 49.3054642, lng: 19.7288873 },
    { lat: 49.3051216, lng: 19.7284959 },
    { lat: 49.3048753, lng: 19.7281684 },
    { lat: 49.3047817, lng: 19.7280638 },
    { lat: 49.3046721, lng: 19.727926 },
    { lat: 49.3037234, lng: 19.7264027 },
    { lat: 49.3036538, lng: 19.726251 },
    { lat: 49.3035326, lng: 19.7260543 },
    { lat: 49.3034002, lng: 19.7259361 },
    { lat: 49.303227, lng: 19.7256062 },
    { lat: 49.3031164, lng: 19.7254478 },
    { lat: 49.3029452, lng: 19.7251639 },
    { lat: 49.3027562, lng: 19.7248633 },
    { lat: 49.302384, lng: 19.7242588 },
    { lat: 49.3009497, lng: 19.7207964 },
    { lat: 49.2996183, lng: 19.7177329 },
    { lat: 49.2992966, lng: 19.7171244 },
    { lat: 49.2986868, lng: 19.7159574 },
    { lat: 49.2986774, lng: 19.7158988 },
    { lat: 49.2986221, lng: 19.7158157 },
    { lat: 49.2984004, lng: 19.7153074 },
    { lat: 49.297772, lng: 19.7139203 },
    { lat: 49.2970175, lng: 19.7122531 },
    { lat: 49.2968934, lng: 19.7119413 },
    { lat: 49.2965665, lng: 19.7104982 },
    { lat: 49.296538, lng: 19.7105148 },
    { lat: 49.2943164, lng: 19.7119741 },
    { lat: 49.2933223, lng: 19.712631 },
    { lat: 49.2920087, lng: 19.7135822 },
    { lat: 49.2904599, lng: 19.7147194 },
    { lat: 49.2892711, lng: 19.7155877 },
    { lat: 49.2885277, lng: 19.7161296 },
    { lat: 49.2870609, lng: 19.7172103 },
    { lat: 49.2854033, lng: 19.7184299 },
    { lat: 49.2846695, lng: 19.7189874 },
    { lat: 49.2826901, lng: 19.7205024 },
    { lat: 49.2822079, lng: 19.7208667 },
    { lat: 49.2818642, lng: 19.7210663 },
    { lat: 49.2818238, lng: 19.7210716 },
    { lat: 49.2812395, lng: 19.7212088 },
    { lat: 49.2805762, lng: 19.7213672 },
    { lat: 49.2799992, lng: 19.7217487 },
    { lat: 49.2799375, lng: 19.7218593 },
    { lat: 49.2796684, lng: 19.7223771 },
    { lat: 49.2793185, lng: 19.7230572 },
    { lat: 49.2790244, lng: 19.7236195 },
    { lat: 49.2789173, lng: 19.7240846 },
    { lat: 49.2787763, lng: 19.7247474 },
    { lat: 49.2778914, lng: 19.7290674 },
    { lat: 49.2775583, lng: 19.7302097 },
    { lat: 49.277499, lng: 19.7304517 },
    { lat: 49.2772743, lng: 19.7314477 },
    { lat: 49.2775971, lng: 19.7325748 },
    { lat: 49.2786917, lng: 19.7364681 },
    { lat: 49.2788134, lng: 19.7369027 },
    { lat: 49.2789755, lng: 19.7374978 },
    { lat: 49.2793557, lng: 19.7388554 },
    { lat: 49.2797627, lng: 19.7404387 },
    { lat: 49.2799361, lng: 19.741108 },
    { lat: 49.2798952, lng: 19.7414921 },
    { lat: 49.2798309, lng: 19.7420597 },
    { lat: 49.2797791, lng: 19.7425492 },
    { lat: 49.2797538, lng: 19.742538 },
    { lat: 49.2766254, lng: 19.7426907 },
    { lat: 49.275214, lng: 19.743546 },
    { lat: 49.2745754, lng: 19.7475849 },
    { lat: 49.2740468, lng: 19.7542139 },
    { lat: 49.2740127, lng: 19.7546719 },
    { lat: 49.2740097, lng: 19.7547599 },
    { lat: 49.273965, lng: 19.7552583 },
    { lat: 49.2740446, lng: 19.755353 },
    { lat: 49.2740708, lng: 19.7553641 },
    { lat: 49.2741095, lng: 19.7553688 },
    { lat: 49.2744514, lng: 19.7553601 },
    { lat: 49.2744792, lng: 19.7553739 },
    { lat: 49.2747282, lng: 19.755608 },
    { lat: 49.2749015, lng: 19.7554504 },
    { lat: 49.2752144, lng: 19.7557051 },
    { lat: 49.2753604, lng: 19.7559017 },
    { lat: 49.2754993, lng: 19.7561939 },
    { lat: 49.2758192, lng: 19.7563509 },
    { lat: 49.2759737, lng: 19.7565076 },
    { lat: 49.2762005, lng: 19.7566192 },
    { lat: 49.2764907, lng: 19.7566886 },
    { lat: 49.2767527, lng: 19.7566556 },
    { lat: 49.2769937, lng: 19.7568822 },
    { lat: 49.2774019, lng: 19.7571568 },
    { lat: 49.2774476, lng: 19.7571713 },
    { lat: 49.2775169, lng: 19.7578592 },
    { lat: 49.2775521, lng: 19.7581742 },
    { lat: 49.2776385, lng: 19.7590519 },
    { lat: 49.2780302, lng: 19.7631457 },
    { lat: 49.2782349, lng: 19.7652966 },
    { lat: 49.2783103, lng: 19.7660246 },
    { lat: 49.2783103, lng: 19.7660444 },
    { lat: 49.2785923, lng: 19.7669915 },
    { lat: 49.2788133, lng: 19.7677217 },
    { lat: 49.2789603, lng: 19.7682332 },
    { lat: 49.2791108, lng: 19.7687447 },
    { lat: 49.2792203, lng: 19.7691363 },
    { lat: 49.2800623, lng: 19.7719869 },
    { lat: 49.2800824, lng: 19.7720585 },
    { lat: 49.2801991, lng: 19.7719731 },
    { lat: 49.2804506, lng: 19.7715894 },
    { lat: 49.2804939, lng: 19.771576 },
    { lat: 49.2808737, lng: 19.7715762 },
    { lat: 49.2817733, lng: 19.7726073 },
    { lat: 49.2821011, lng: 19.7730262 },
    { lat: 49.2821686, lng: 19.773075 },
    { lat: 49.28251, lng: 19.7731592 },
    { lat: 49.2825814, lng: 19.7731393 },
    { lat: 49.2828888, lng: 19.7732926 },
    { lat: 49.2829924, lng: 19.7737339 },
    { lat: 49.2830317, lng: 19.7738089 },
    { lat: 49.2833053, lng: 19.7738845 },
    { lat: 49.2834399, lng: 19.7738478 },
    { lat: 49.2838186, lng: 19.7735879 },
    { lat: 49.2838807, lng: 19.7735932 },
    { lat: 49.2840074, lng: 19.7740509 },
    { lat: 49.2842416, lng: 19.7741499 },
    { lat: 49.284318, lng: 19.7739361 },
    { lat: 49.284656, lng: 19.7738385 },
    { lat: 49.2849802, lng: 19.7739594 },
    { lat: 49.2850844, lng: 19.773924 },
    { lat: 49.2854742, lng: 19.7738178 },
    { lat: 49.2855672, lng: 19.7738336 },
    { lat: 49.2857238, lng: 19.7739892 },
    { lat: 49.2866863, lng: 19.7736649 },
    { lat: 49.2867247, lng: 19.7736679 },
    { lat: 49.2871056, lng: 19.7737766 },
    { lat: 49.2869673, lng: 19.7741698 },
    { lat: 49.2867848, lng: 19.774381 },
    { lat: 49.2865309, lng: 19.7744702 },
    { lat: 49.2863974, lng: 19.7746901 },
    { lat: 49.2862005, lng: 19.7748262 },
    { lat: 49.2860309, lng: 19.774791 },
    { lat: 49.2859725, lng: 19.7747388 },
    { lat: 49.2858733, lng: 19.7747391 },
    { lat: 49.2856425, lng: 19.7752914 },
    { lat: 49.285595, lng: 19.775285 },
    { lat: 49.2855273, lng: 19.7753077 },
    { lat: 49.285371, lng: 19.7753394 },
    { lat: 49.2853446, lng: 19.7753562 },
    { lat: 49.2853131, lng: 19.7754351 },
    { lat: 49.2849832, lng: 19.7758037 },
    { lat: 49.2850753, lng: 19.7759513 },
    { lat: 49.2851139, lng: 19.7766088 },
    { lat: 49.2850432, lng: 19.7766939 },
    { lat: 49.2848932, lng: 19.7765056 },
    { lat: 49.2847928, lng: 19.7764555 },
    { lat: 49.2846313, lng: 19.7764433 },
    { lat: 49.2844661, lng: 19.7764001 },
    { lat: 49.2844291, lng: 19.7766633 },
    { lat: 49.2846074, lng: 19.7771114 },
    { lat: 49.2845777, lng: 19.7771299 },
    { lat: 49.2844584, lng: 19.777133 },
    { lat: 49.284348, lng: 19.7772885 },
    { lat: 49.2842967, lng: 19.7774824 },
    { lat: 49.2843484, lng: 19.7779744 },
    { lat: 49.28441, lng: 19.7782027 },
    { lat: 49.2844427, lng: 19.7787237 },
    { lat: 49.2843439, lng: 19.778988 },
    { lat: 49.2842764, lng: 19.7796173 },
    { lat: 49.2842052, lng: 19.7796414 },
    { lat: 49.2841086, lng: 19.7798042 },
    { lat: 49.2840491, lng: 19.7800433 },
    { lat: 49.2840732, lng: 19.78034 },
    { lat: 49.2842454, lng: 19.7808292 },
    { lat: 49.2841441, lng: 19.7808333 },
    { lat: 49.2839277, lng: 19.7806217 },
    { lat: 49.2834875, lng: 19.7805987 },
    { lat: 49.2834588, lng: 19.7805644 },
    { lat: 49.283336, lng: 19.7806381 },
    { lat: 49.2833591, lng: 19.7808613 },
    { lat: 49.2836499, lng: 19.7811029 },
    { lat: 49.2836097, lng: 19.7812921 },
    { lat: 49.2833977, lng: 19.781285 },
    { lat: 49.2831638, lng: 19.7813725 },
    { lat: 49.283013, lng: 19.7814573 },
    { lat: 49.2830327, lng: 19.7818295 },
    { lat: 49.2831123, lng: 19.7819521 },
    { lat: 49.2832361, lng: 19.781927 },
    { lat: 49.2832471, lng: 19.7819475 },
    { lat: 49.2832497, lng: 19.7820562 },
    { lat: 49.282974, lng: 19.7824066 },
    { lat: 49.2829602, lng: 19.7825516 },
    { lat: 49.282894, lng: 19.7830779 },
    { lat: 49.2828712, lng: 19.7831018 },
    { lat: 49.2828598, lng: 19.7830961 },
    { lat: 49.282859, lng: 19.783076 },
    { lat: 49.2828809, lng: 19.782906 },
    { lat: 49.282766, lng: 19.7827498 },
    { lat: 49.2826065, lng: 19.7826855 },
    { lat: 49.2825232, lng: 19.7826859 },
    { lat: 49.2826288, lng: 19.7830148 },
    { lat: 49.2826624, lng: 19.7833499 },
    { lat: 49.2823265, lng: 19.7836836 },
    { lat: 49.2820474, lng: 19.7842934 },
    { lat: 49.2820111, lng: 19.7847278 },
    { lat: 49.2821882, lng: 19.7848168 },
    { lat: 49.2823919, lng: 19.7848587 },
    { lat: 49.282354, lng: 19.7850811 },
    { lat: 49.2821997, lng: 19.7855744 },
    { lat: 49.2821794, lng: 19.7856291 },
    { lat: 49.2821785, lng: 19.7857404 },
    { lat: 49.2824866, lng: 19.7859597 },
    { lat: 49.2825152, lng: 19.7859716 },
    { lat: 49.2828163, lng: 19.7858705 },
    { lat: 49.2827907, lng: 19.7861419 },
    { lat: 49.2830068, lng: 19.7868385 },
    { lat: 49.2827189, lng: 19.7869552 },
    { lat: 49.2826841, lng: 19.7870366 },
    { lat: 49.2827069, lng: 19.7871769 },
    { lat: 49.2829941, lng: 19.7874586 },
    { lat: 49.2830485, lng: 19.7877295 },
    { lat: 49.2827908, lng: 19.7881289 },
    { lat: 49.2827624, lng: 19.7884074 },
    { lat: 49.2830177, lng: 19.7889193 },
    { lat: 49.2831517, lng: 19.7888968 },
    { lat: 49.2832127, lng: 19.7886629 },
    { lat: 49.2833331, lng: 19.7886371 },
    { lat: 49.283418, lng: 19.7888797 },
    { lat: 49.2833285, lng: 19.7894151 },
    { lat: 49.2831268, lng: 19.789517 },
    { lat: 49.2830784, lng: 19.7900144 },
    { lat: 49.2831185, lng: 19.7902391 },
    { lat: 49.283367, lng: 19.7909884 },
    { lat: 49.2834679, lng: 19.7914992 },
    { lat: 49.2834553, lng: 19.7916019 },
    { lat: 49.2833385, lng: 19.7914769 },
    { lat: 49.2832077, lng: 19.791449 },
    { lat: 49.2830411, lng: 19.7915534 },
    { lat: 49.282955, lng: 19.791806 },
    { lat: 49.2829354, lng: 19.7922117 },
    { lat: 49.2828908, lng: 19.7922494 },
    { lat: 49.2828675, lng: 19.7924654 },
    { lat: 49.2829608, lng: 19.7926476 },
    { lat: 49.2831192, lng: 19.7927907 },
    { lat: 49.2833073, lng: 19.7928439 },
    { lat: 49.2833889, lng: 19.7928541 },
    { lat: 49.2832155, lng: 19.7930415 },
    { lat: 49.2831705, lng: 19.7931976 },
    { lat: 49.2832727, lng: 19.7935803 },
    { lat: 49.2831621, lng: 19.7937631 },
    { lat: 49.28302, lng: 19.7936746 },
    { lat: 49.2828272, lng: 19.7936482 },
    { lat: 49.2827496, lng: 19.7940179 },
    { lat: 49.2826761, lng: 19.7943219 },
    { lat: 49.282714, lng: 19.7945212 },
    { lat: 49.282681, lng: 19.7946707 },
    { lat: 49.2825755, lng: 19.7948141 },
    { lat: 49.2823207, lng: 19.7949382 },
    { lat: 49.2822997, lng: 19.79497 },
    { lat: 49.2823412, lng: 19.7953641 },
    { lat: 49.2821693, lng: 19.7955265 },
    { lat: 49.282174, lng: 19.7959763 },
    { lat: 49.2820808, lng: 19.7961404 },
    { lat: 49.2819333, lng: 19.7961419 },
    { lat: 49.2819051, lng: 19.7961657 },
    { lat: 49.2818901, lng: 19.7963671 },
    { lat: 49.2819589, lng: 19.7967073 },
    { lat: 49.2818548, lng: 19.7968076 },
    { lat: 49.2818444, lng: 19.7972445 },
    { lat: 49.2818323, lng: 19.7973543 },
    { lat: 49.2819357, lng: 19.7974237 },
    { lat: 49.2819372, lng: 19.7974592 },
    { lat: 49.2817618, lng: 19.7976288 },
    { lat: 49.2817676, lng: 19.7976542 },
    { lat: 49.2818964, lng: 19.797849 },
    { lat: 49.2817275, lng: 19.7983726 },
    { lat: 49.2816369, lng: 19.7988075 },
    { lat: 49.2817353, lng: 19.7996278 },
    { lat: 49.2816795, lng: 19.7998239 },
    { lat: 49.2814594, lng: 19.8002057 },
    { lat: 49.2815328, lng: 19.8005238 },
    { lat: 49.281453, lng: 19.800709 },
    { lat: 49.2814369, lng: 19.8006752 },
    { lat: 49.2815089, lng: 19.8005172 },
    { lat: 49.2813298, lng: 19.8003581 },
    { lat: 49.2812114, lng: 19.8004096 },
    { lat: 49.2811692, lng: 19.8005257 },
    { lat: 49.2812187, lng: 19.800865 },
    { lat: 49.2810453, lng: 19.8011584 },
    { lat: 49.2811268, lng: 19.8015049 },
    { lat: 49.2813814, lng: 19.801585 },
    { lat: 49.2812415, lng: 19.8017614 },
    { lat: 49.281275, lng: 19.8019154 },
    { lat: 49.2812527, lng: 19.802023 },
    { lat: 49.2811287, lng: 19.8021523 },
    { lat: 49.2811051, lng: 19.8022344 },
    { lat: 49.2813518, lng: 19.8023626 },
    { lat: 49.2813418, lng: 19.802496 },
    { lat: 49.2811995, lng: 19.8025688 },
    { lat: 49.2809696, lng: 19.8026186 },
    { lat: 49.280872, lng: 19.8027298 },
    { lat: 49.2807642, lng: 19.8025285 },
    { lat: 49.28064, lng: 19.8025514 },
    { lat: 49.2805661, lng: 19.802666 },
    { lat: 49.2805638, lng: 19.8029527 },
    { lat: 49.2806396, lng: 19.8031172 },
    { lat: 49.2805716, lng: 19.8032095 },
    { lat: 49.2805106, lng: 19.8034704 },
    { lat: 49.2805221, lng: 19.8037377 },
    { lat: 49.2805124, lng: 19.8039028 },
    { lat: 49.2806083, lng: 19.8039863 },
    { lat: 49.2806729, lng: 19.8039953 },
    { lat: 49.2806352, lng: 19.8041124 },
    { lat: 49.2805371, lng: 19.8042416 },
    { lat: 49.2802861, lng: 19.804847 },
    { lat: 49.2802786, lng: 19.8049098 },
    { lat: 49.2803423, lng: 19.8050304 },
    { lat: 49.2802597, lng: 19.8052335 },
    { lat: 49.2802358, lng: 19.8053568 },
    { lat: 49.280271, lng: 19.8054399 },
    { lat: 49.2803381, lng: 19.8054537 },
    { lat: 49.2804257, lng: 19.805398 },
    { lat: 49.2804401, lng: 19.8054698 },
    { lat: 49.2804343, lng: 19.8054949 },
    { lat: 49.2803385, lng: 19.8055936 },
    { lat: 49.2803187, lng: 19.8056332 },
    { lat: 49.2803337, lng: 19.8057197 },
    { lat: 49.2803792, lng: 19.8057837 },
    { lat: 49.2805047, lng: 19.8059255 },
    { lat: 49.2805761, lng: 19.8061203 },
    { lat: 49.2806228, lng: 19.8063386 },
    { lat: 49.2807735, lng: 19.8065172 },
    { lat: 49.2807621, lng: 19.806697 },
    { lat: 49.280917, lng: 19.8071039 },
    { lat: 49.2809067, lng: 19.8075451 },
    { lat: 49.2809408, lng: 19.8075803 },
    { lat: 49.2810825, lng: 19.807522 },
    { lat: 49.2811606, lng: 19.8077401 },
    { lat: 49.2812338, lng: 19.8080538 },
    { lat: 49.2812342, lng: 19.80814 },
    { lat: 49.281053, lng: 19.808226 },
    { lat: 49.2810145, lng: 19.8083785 },
    { lat: 49.2809367, lng: 19.8085061 },
    { lat: 49.2809393, lng: 19.8085394 },
    { lat: 49.2810983, lng: 19.8089113 },
    { lat: 49.2811196, lng: 19.8089352 },
    { lat: 49.2812022, lng: 19.8089734 },
    { lat: 49.281213, lng: 19.8090091 },
    { lat: 49.2811841, lng: 19.8092332 },
    { lat: 49.2811922, lng: 19.8092894 },
    { lat: 49.2812647, lng: 19.809456 },
    { lat: 49.2812952, lng: 19.8094836 },
    { lat: 49.2813756, lng: 19.8094929 },
    { lat: 49.2814616, lng: 19.8095817 },
    { lat: 49.2815268, lng: 19.8098136 },
    { lat: 49.2816098, lng: 19.8097522 },
    { lat: 49.2830399, lng: 19.8087229 },
    { lat: 49.2845466, lng: 19.8076645 },
    { lat: 49.2852826, lng: 19.8071507 },
    { lat: 49.2910607, lng: 19.8031069 },
    { lat: 49.293627, lng: 19.8013581 },
    { lat: 49.2951573, lng: 19.8003015 },
    { lat: 49.295828, lng: 19.799795 },
    { lat: 49.295863, lng: 19.799329 },
    { lat: 49.2958736, lng: 19.7985869 },
    { lat: 49.29605, lng: 19.797049 },
    { lat: 49.296048, lng: 19.796113 },
    { lat: 49.2960468, lng: 19.7951197 },
    { lat: 49.2960447, lng: 19.7945013 },
    { lat: 49.2962815, lng: 19.7943348 },
    { lat: 49.296949, lng: 19.793882 },
    { lat: 49.29803, lng: 19.79333 },
    { lat: 49.298913, lng: 19.792728 },
    { lat: 49.29974, lng: 19.792474 },
    { lat: 49.300855, lng: 19.792438 },
    { lat: 49.301592, lng: 19.792531 },
    { lat: 49.302572, lng: 19.792639 },
    { lat: 49.303432, lng: 19.792995 },
    { lat: 49.30439, lng: 19.793311 },
    { lat: 49.30495, lng: 19.793658 },
    { lat: 49.305412, lng: 19.793926 },
    { lat: 49.306431, lng: 19.79484 },
    { lat: 49.307218, lng: 19.795579 },
    { lat: 49.3075969, lng: 19.794377 },
    { lat: 49.3077484, lng: 19.7940766 },
    { lat: 49.3072112, lng: 19.7929264 },
    { lat: 49.3067421, lng: 19.7920806 },
    { lat: 49.3057738, lng: 19.7906542 },
    { lat: 49.3053271, lng: 19.789304 },
    { lat: 49.3046021, lng: 19.7894436 },
    { lat: 49.3037068, lng: 19.7896089 },
    { lat: 49.3028339, lng: 19.7897875 },
    { lat: 49.3018837, lng: 19.7899324 },
    { lat: 49.3009561, lng: 19.7901036 },
    { lat: 49.3000819, lng: 19.7902596 },
    { lat: 49.2994041, lng: 19.7903929 },
    { lat: 49.2992414, lng: 19.7891045 },
    { lat: 49.299096, lng: 19.7878187 },
    { lat: 49.2989531, lng: 19.786728 },
    { lat: 49.2996721, lng: 19.7867103 },
    { lat: 49.3004377, lng: 19.786598 },
    { lat: 49.3005545, lng: 19.786588 },
    { lat: 49.3014268, lng: 19.786678 },
    { lat: 49.3019703, lng: 19.7866376 },
    { lat: 49.3022979, lng: 19.7866085 },
    { lat: 49.303094, lng: 19.7865253 },
    { lat: 49.3039179, lng: 19.7866385 },
    { lat: 49.3038503, lng: 19.7856187 },
    { lat: 49.3037428, lng: 19.7852159 },
    { lat: 49.3035659, lng: 19.7850057 },
    { lat: 49.3035257, lng: 19.7848567 },
    { lat: 49.303501, lng: 19.7844419 },
    { lat: 49.3034555, lng: 19.7840393 },
    { lat: 49.3032857, lng: 19.7836818 },
    { lat: 49.3031112, lng: 19.7831388 },
    { lat: 49.3030054, lng: 19.7828755 },
    { lat: 49.3027768, lng: 19.7824088 },
    { lat: 49.3027077, lng: 19.7821898 },
    { lat: 49.3027065, lng: 19.7818517 },
    { lat: 49.3027019, lng: 19.7817172 },
    { lat: 49.3026682, lng: 19.7814845 },
    { lat: 49.3025958, lng: 19.7812449 },
    { lat: 49.3024747, lng: 19.7809705 },
    { lat: 49.3022394, lng: 19.7807373 },
    { lat: 49.3020287, lng: 19.780372 },
    { lat: 49.3020122, lng: 19.780143 },
    { lat: 49.3018113, lng: 19.7799511 },
    { lat: 49.3016447, lng: 19.7793568 },
    { lat: 49.301163, lng: 19.7786652 },
    { lat: 49.3008709, lng: 19.7785478 },
    { lat: 49.3006666, lng: 19.7782775 },
    { lat: 49.3006429, lng: 19.7780727 },
    { lat: 49.3006368, lng: 19.7777723 },
    { lat: 49.3005535, lng: 19.7773793 },
    { lat: 49.3004084, lng: 19.7772074 },
    { lat: 49.3001693, lng: 19.7766775 },
    { lat: 49.2999083, lng: 19.776527 },
    { lat: 49.299825, lng: 19.7764225 },
    { lat: 49.299684, lng: 19.7761592 },
    { lat: 49.2994896, lng: 19.7759659 },
    { lat: 49.299393, lng: 19.7758934 },
    { lat: 49.2992581, lng: 19.7758196 },
    { lat: 49.2991211, lng: 19.7755159 },
    { lat: 49.2989884, lng: 19.7751821 },
    { lat: 49.2991322, lng: 19.7748604 },
    { lat: 49.3001178, lng: 19.7725438 },
    { lat: 49.3006535, lng: 19.7719572 },
    { lat: 49.3013261, lng: 19.7712073 },
    { lat: 49.3020165, lng: 19.7704487 },
    { lat: 49.3015243, lng: 19.7694117 },
    { lat: 49.3011872, lng: 19.768233 },
    { lat: 49.3008965, lng: 19.7670583 },
    { lat: 49.300535, lng: 19.7658904 },
    { lat: 49.3003266, lng: 19.7652698 },
    { lat: 49.300112, lng: 19.7647826 },
    { lat: 49.3005614, lng: 19.7647598 },
    { lat: 49.3015025, lng: 19.764736 },
    { lat: 49.3016382, lng: 19.7647326 },
    { lat: 49.3020513, lng: 19.7649557 },
    { lat: 49.3026951, lng: 19.7653081 },
    { lat: 49.3029183, lng: 19.765419 },
    { lat: 49.3035709, lng: 19.7653749 },
    { lat: 49.3045448, lng: 19.7650799 },
    { lat: 49.3045938, lng: 19.7650666 },
    { lat: 49.3060643, lng: 19.7646364 },
    { lat: 49.3066765, lng: 19.7645675 },
    { lat: 49.3067831, lng: 19.7645617 },
    { lat: 49.3067845, lng: 19.7645392 },
    { lat: 49.3075676, lng: 19.7645483 },
    { lat: 49.3082345, lng: 19.7645496 },
    { lat: 49.3096213, lng: 19.7651979 },
    { lat: 49.3113802, lng: 19.7658623 },
    { lat: 49.3116363, lng: 19.7658453 },
    { lat: 49.3121161, lng: 19.7657675 },
    { lat: 49.3122709, lng: 19.7657489 },
    { lat: 49.3129249, lng: 19.7658173 },
    { lat: 49.3131943, lng: 19.7658481 },
    { lat: 49.3135449, lng: 19.7658808 },
    { lat: 49.3141762, lng: 19.7656331 },
    { lat: 49.3146194, lng: 19.7654625 },
    { lat: 49.3148661, lng: 19.765361 },
    { lat: 49.3149336, lng: 19.7653057 },
    { lat: 49.3153427, lng: 19.7649184 },
    { lat: 49.3154888, lng: 19.7647837 },
    { lat: 49.3161056, lng: 19.7643533 },
    { lat: 49.3162727, lng: 19.7642601 },
    { lat: 49.3164456, lng: 19.7641419 },
    { lat: 49.3168887, lng: 19.7638929 },
    { lat: 49.3173177, lng: 19.7636556 },
    { lat: 49.3176523, lng: 19.7634743 },
    { lat: 49.3182233, lng: 19.7631568 },
    { lat: 49.3187525, lng: 19.7630226 },
    { lat: 49.3189448, lng: 19.7629663 },
    { lat: 49.3192176, lng: 19.7628935 },
    { lat: 49.3196116, lng: 19.7627802 },
    { lat: 49.3202485, lng: 19.7626131 },
    { lat: 49.3203159, lng: 19.7624513 },
    { lat: 49.3205114, lng: 19.7620227 },
    { lat: 49.3206228, lng: 19.7617879 },
    { lat: 49.3208914, lng: 19.7612541 },
    { lat: 49.3210101, lng: 19.7610293 },
    { lat: 49.3211346, lng: 19.7607816 },
    { lat: 49.3212141, lng: 19.7606111 },
    { lat: 49.3214937, lng: 19.7599713 },
    { lat: 49.3217301, lng: 19.7594479 },
    { lat: 49.3217553, lng: 19.7593807 },
    { lat: 49.3220741, lng: 19.7586764 },
    { lat: 49.3222118, lng: 19.7583461 },
    { lat: 49.3225856, lng: 19.757462 },
    { lat: 49.3228456, lng: 19.7568557 },
    { lat: 49.3227434, lng: 19.7565494 },
    { lat: 49.3225239, lng: 19.7561921 },
    { lat: 49.3225198, lng: 19.7559665 },
    { lat: 49.3224211, lng: 19.7558252 },
    { lat: 49.3222471, lng: 19.7555508 },
    { lat: 49.3220985, lng: 19.7552651 },
    { lat: 49.3219801, lng: 19.7550286 },
    { lat: 49.3218731, lng: 19.7548467 },
    { lat: 49.3217945, lng: 19.7547008 },
    { lat: 49.3217317, lng: 19.7546014 },
    { lat: 49.3216575, lng: 19.7544208 },
    { lat: 49.3215154, lng: 19.7540565 },
    { lat: 49.3213119, lng: 19.7536261 },
    { lat: 49.3211779, lng: 19.7535184 },
    { lat: 49.3209671, lng: 19.7531534 },
    { lat: 49.3207095, lng: 19.752695 },
    { lat: 49.3204619, lng: 19.7522341 },
    { lat: 49.3203227, lng: 19.7521638 },
    { lat: 49.3202834, lng: 19.7520378 },
    { lat: 49.3203932, lng: 19.7518959 },
    { lat: 49.3203663, lng: 19.7515596 },
    { lat: 49.3204396, lng: 19.7514871 },
    { lat: 49.3205204, lng: 19.7516075 },
    { lat: 49.3205733, lng: 19.7513464 },
    { lat: 49.3203794, lng: 19.7508769 },
    { lat: 49.3202644, lng: 19.7500996 },
    { lat: 49.320319, lng: 19.7496925 },
    { lat: 49.3203899, lng: 19.74907 },
    { lat: 49.3203625, lng: 19.7487496 },
    { lat: 49.3204144, lng: 19.7484922 },
    { lat: 49.3203203, lng: 19.7479089 },
    { lat: 49.32029, lng: 19.7475758 },
    { lat: 49.3202283, lng: 19.7473232 },
    { lat: 49.3201361, lng: 19.7469122 },
    { lat: 49.3199393, lng: 19.746088 },
    { lat: 49.3198097, lng: 19.745986 },
    { lat: 49.3194509, lng: 19.7456094 },
    { lat: 49.3191044, lng: 19.7453601 },
    { lat: 49.3191269, lng: 19.745282 },
    { lat: 49.3192216, lng: 19.7450204 },
    { lat: 49.3191758, lng: 19.7447228 },
    { lat: 49.3191738, lng: 19.7443399 },
    { lat: 49.3192092, lng: 19.7441178 },
    { lat: 49.3192815, lng: 19.7437598 },
    { lat: 49.3192832, lng: 19.7431212 },
    { lat: 49.3194162, lng: 19.7426072 },
    { lat: 49.3194528, lng: 19.7421803 },
    { lat: 49.3194562, lng: 19.741894 },
    { lat: 49.3195594, lng: 19.7417546 },
    { lat: 49.3197419, lng: 19.7415771 },
    { lat: 49.3198102, lng: 19.7414643 },
    { lat: 49.3202982, lng: 19.740717 },
    { lat: 49.3203912, lng: 19.7405216 },
    { lat: 49.3204494, lng: 19.74026 },
    { lat: 49.3205322, lng: 19.7401934 },
    { lat: 49.3206121, lng: 19.7400105 },
    { lat: 49.3205872, lng: 19.7398783 },
    { lat: 49.3206323, lng: 19.7395906 },
    { lat: 49.3206837, lng: 19.7391467 },
    { lat: 49.3206825, lng: 19.7390905 },
    { lat: 49.3206535, lng: 19.7388367 },
    { lat: 49.3206054, lng: 19.7385343 },
    { lat: 49.3205155, lng: 19.7381495 },
    { lat: 49.320345, lng: 19.7378617 },
    { lat: 49.3203452, lng: 19.7377075 },
    { lat: 49.3203896, lng: 19.7372809 },
    { lat: 49.3202827, lng: 19.7370758 },
    { lat: 49.320164, lng: 19.7369359 },
    { lat: 49.3199459, lng: 19.736584 },
    { lat: 49.3194895, lng: 19.7358058 },
    { lat: 49.3191917, lng: 19.7354343 },
    { lat: 49.319015, lng: 19.7349973 },
    { lat: 49.3188036, lng: 19.7346964 },
  ],
  TvrdošínExt: [
    { lat: 49.3490195, lng: 19.5098529 },
    { lat: 49.3490746, lng: 19.5085153 },
    { lat: 49.3490132, lng: 19.5071659 },
    { lat: 49.3491375, lng: 19.505835 },
    { lat: 49.3493669, lng: 19.5046129 },
    { lat: 49.3494472, lng: 19.5037063 },
    { lat: 49.3495468, lng: 19.5026476 },
    { lat: 49.3499178, lng: 19.5019925 },
    { lat: 49.3502698, lng: 19.5014577 },
    { lat: 49.3504466, lng: 19.5012079 },
    { lat: 49.3511016, lng: 19.4998307 },
    { lat: 49.3515559, lng: 19.4990695 },
    { lat: 49.3523029, lng: 19.497903 },
    { lat: 49.3527949, lng: 19.4968811 },
    { lat: 49.3532128, lng: 19.4955968 },
    { lat: 49.353584, lng: 19.4930354 },
    { lat: 49.3538536, lng: 19.4916111 },
    { lat: 49.354055, lng: 19.4913329 },
    { lat: 49.3545881, lng: 19.4910157 },
    { lat: 49.3559811, lng: 19.4902862 },
    { lat: 49.3574748, lng: 19.4897834 },
    { lat: 49.3582332, lng: 19.4893434 },
    { lat: 49.3584946, lng: 19.4884558 },
    { lat: 49.3592574, lng: 19.4871459 },
    { lat: 49.3599169, lng: 19.4866358 },
    { lat: 49.36027, lng: 19.485542 },
    { lat: 49.359937, lng: 19.48516 },
    { lat: 49.359832, lng: 19.48483 },
    { lat: 49.3594, lng: 19.483486 },
    { lat: 49.358879, lng: 19.482154 },
    { lat: 49.358876, lng: 19.482121 },
    { lat: 49.358637, lng: 19.48036 },
    { lat: 49.358664, lng: 19.479376 },
    { lat: 49.358728, lng: 19.478937 },
    { lat: 49.358902, lng: 19.477884 },
    { lat: 49.359184, lng: 19.476871 },
    { lat: 49.358987, lng: 19.475985 },
    { lat: 49.358978, lng: 19.475671 },
    { lat: 49.359049, lng: 19.474393 },
    { lat: 49.359, lng: 19.473691 },
    { lat: 49.35816, lng: 19.472325 },
    { lat: 49.35775, lng: 19.471872 },
    { lat: 49.357675, lng: 19.47179 },
    { lat: 49.357139, lng: 19.4712 },
    { lat: 49.356861, lng: 19.47077 },
    { lat: 49.356708, lng: 19.470534 },
    { lat: 49.356162, lng: 19.469898 },
    { lat: 49.355644, lng: 19.469241 },
    { lat: 49.354963, lng: 19.468379 },
    { lat: 49.354368, lng: 19.467812 },
    { lat: 49.354002, lng: 19.467462 },
    { lat: 49.353732, lng: 19.467204 },
    { lat: 49.353701, lng: 19.467159 },
    { lat: 49.353623, lng: 19.467094 },
    { lat: 49.353483, lng: 19.466973 },
    { lat: 49.353159, lng: 19.466697 },
    { lat: 49.352461, lng: 19.4661 },
    { lat: 49.352266, lng: 19.465862 },
    { lat: 49.351767, lng: 19.464908 },
    { lat: 49.3515116, lng: 19.4652413 },
    { lat: 49.3513043, lng: 19.4655657 },
    { lat: 49.3512172, lng: 19.4657037 },
    { lat: 49.3509593, lng: 19.4661254 },
    { lat: 49.3509082, lng: 19.4662617 },
    { lat: 49.3508249, lng: 19.466485 },
    { lat: 49.3507638, lng: 19.4666498 },
    { lat: 49.3507095, lng: 19.4667944 },
    { lat: 49.3506021, lng: 19.4670583 },
    { lat: 49.3503055, lng: 19.4677876 },
    { lat: 49.3502098, lng: 19.4680122 },
    { lat: 49.350039, lng: 19.468414 },
    { lat: 49.3497591, lng: 19.4690433 },
    { lat: 49.3494689, lng: 19.4695429 },
    { lat: 49.3493804, lng: 19.4697566 },
    { lat: 49.3492902, lng: 19.4699719 },
    { lat: 49.3492658, lng: 19.470032 },
    { lat: 49.3489775, lng: 19.4707221 },
    { lat: 49.3488652, lng: 19.471003 },
    { lat: 49.3488403, lng: 19.4710715 },
    { lat: 49.3486084, lng: 19.4717158 },
    { lat: 49.3482562, lng: 19.4723673 },
    { lat: 49.3480522, lng: 19.4726136 },
    { lat: 49.3479434, lng: 19.4727445 },
    { lat: 49.3478452, lng: 19.4728631 },
    { lat: 49.3477821, lng: 19.4729386 },
    { lat: 49.3473691, lng: 19.4736843 },
    { lat: 49.3467324, lng: 19.4748155 },
    { lat: 49.346235, lng: 19.4757145 },
    { lat: 49.3460367, lng: 19.4761329 },
    { lat: 49.3459081, lng: 19.4764036 },
    { lat: 49.3458529, lng: 19.4768229 },
    { lat: 49.3458008, lng: 19.4772105 },
    { lat: 49.3457801, lng: 19.4773728 },
    { lat: 49.3455123, lng: 19.4778265 },
    { lat: 49.3452939, lng: 19.4785177 },
    { lat: 49.3450208, lng: 19.4789991 },
    { lat: 49.3449438, lng: 19.4791642 },
    { lat: 49.3445345, lng: 19.4800439 },
    { lat: 49.3441684, lng: 19.480542 },
    { lat: 49.3436026, lng: 19.4811541 },
    { lat: 49.3433554, lng: 19.4815101 },
    { lat: 49.343106, lng: 19.4817757 },
    { lat: 49.3432271, lng: 19.4820348 },
    { lat: 49.3433643, lng: 19.4823446 },
    { lat: 49.3434139, lng: 19.4824947 },
    { lat: 49.3436055, lng: 19.4830664 },
    { lat: 49.343735, lng: 19.4834562 },
    { lat: 49.3438306, lng: 19.4836971 },
    { lat: 49.3438943, lng: 19.4838612 },
    { lat: 49.3439061, lng: 19.4838986 },
    { lat: 49.3439224, lng: 19.4839352 },
    { lat: 49.3440078, lng: 19.4841494 },
    { lat: 49.3441098, lng: 19.4844091 },
    { lat: 49.3441559, lng: 19.4845292 },
    { lat: 49.34417, lng: 19.4845685 },
    { lat: 49.3441902, lng: 19.4846394 },
    { lat: 49.3442024, lng: 19.484684 },
    { lat: 49.3442068, lng: 19.4847081 },
    { lat: 49.3443314, lng: 19.4851909 },
    { lat: 49.344317, lng: 19.485222 },
    { lat: 49.3444024, lng: 19.4852877 },
    { lat: 49.3445168, lng: 19.4853792 },
    { lat: 49.3445902, lng: 19.4856514 },
    { lat: 49.344577, lng: 19.4857294 },
    { lat: 49.3445706, lng: 19.4857659 },
    { lat: 49.344614, lng: 19.4858273 },
    { lat: 49.344628, lng: 19.4858396 },
    { lat: 49.3447188, lng: 19.4858255 },
    { lat: 49.3447803, lng: 19.4858638 },
    { lat: 49.3448071, lng: 19.4859866 },
    { lat: 49.3448674, lng: 19.4860973 },
    { lat: 49.3450633, lng: 19.4864369 },
    { lat: 49.3452432, lng: 19.4867979 },
    { lat: 49.3453938, lng: 19.4871377 },
    { lat: 49.3455099, lng: 19.4871379 },
    { lat: 49.3455696, lng: 19.4871626 },
    { lat: 49.3456363, lng: 19.4873878 },
    { lat: 49.3456557, lng: 19.4875888 },
    { lat: 49.3456654, lng: 19.4876052 },
    { lat: 49.3457642, lng: 19.4876954 },
    { lat: 49.3458305, lng: 19.4879167 },
    { lat: 49.3459833, lng: 19.4880778 },
    { lat: 49.3461264, lng: 19.4882294 },
    { lat: 49.3463039, lng: 19.4884855 },
    { lat: 49.3463918, lng: 19.4886116 },
    { lat: 49.3464585, lng: 19.4887652 },
    { lat: 49.3466382, lng: 19.4891681 },
    { lat: 49.3466526, lng: 19.4892399 },
    { lat: 49.3466808, lng: 19.4895383 },
    { lat: 49.3466632, lng: 19.4896699 },
    { lat: 49.3466374, lng: 19.4898447 },
    { lat: 49.3466713, lng: 19.4900684 },
    { lat: 49.3466725, lng: 19.4904889 },
    { lat: 49.3465693, lng: 19.4905975 },
    { lat: 49.3466084, lng: 19.4908629 },
    { lat: 49.3467065, lng: 19.4909637 },
    { lat: 49.3468164, lng: 19.4910752 },
    { lat: 49.3468303, lng: 19.4911326 },
    { lat: 49.3468716, lng: 19.4913696 },
    { lat: 49.3470306, lng: 19.4915478 },
    { lat: 49.3470505, lng: 19.4916891 },
    { lat: 49.3470624, lng: 19.4917261 },
    { lat: 49.3471907, lng: 19.4920651 },
    { lat: 49.3472359, lng: 19.492172 },
    { lat: 49.3473229, lng: 19.4924948 },
    { lat: 49.347391, lng: 19.4925533 },
    { lat: 49.3474233, lng: 19.4925986 },
    { lat: 49.3476332, lng: 19.4929245 },
    { lat: 49.3477796, lng: 19.4933924 },
    { lat: 49.3479152, lng: 19.4938481 },
    { lat: 49.3479325, lng: 19.493907 },
    { lat: 49.3479582, lng: 19.4940723 },
    { lat: 49.3479888, lng: 19.494297 },
    { lat: 49.3480135, lng: 19.4944451 },
    { lat: 49.3479302, lng: 19.494892 },
    { lat: 49.3478983, lng: 19.4951549 },
    { lat: 49.3478886, lng: 19.4952383 },
    { lat: 49.3478682, lng: 19.4954121 },
    { lat: 49.3478257, lng: 19.4954167 },
    { lat: 49.3476602, lng: 19.4953711 },
    { lat: 49.3476177, lng: 19.4953547 },
    { lat: 49.347276, lng: 19.4954682 },
    { lat: 49.3470981, lng: 19.4955222 },
    { lat: 49.3468081, lng: 19.4954376 },
    { lat: 49.3465956, lng: 19.4953743 },
    { lat: 49.3465889, lng: 19.4953739 },
    { lat: 49.3462932, lng: 19.4953397 },
    { lat: 49.3461597, lng: 19.4953276 },
    { lat: 49.3459206, lng: 19.4951743 },
    { lat: 49.3457087, lng: 19.4950466 },
    { lat: 49.3457075, lng: 19.495046 },
    { lat: 49.3453934, lng: 19.4949047 },
    { lat: 49.3452219, lng: 19.4948247 },
    { lat: 49.3449811, lng: 19.4948049 },
    { lat: 49.3448997, lng: 19.4947999 },
    { lat: 49.3446417, lng: 19.4949991 },
    { lat: 49.3444209, lng: 19.495173 },
    { lat: 49.3442515, lng: 19.4956327 },
    { lat: 49.3441243, lng: 19.4959812 },
    { lat: 49.3440724, lng: 19.4961057 },
    { lat: 49.3439151, lng: 19.4965077 },
    { lat: 49.3438017, lng: 19.4967652 },
    { lat: 49.3435582, lng: 19.4973538 },
    { lat: 49.3434006, lng: 19.4977298 },
    { lat: 49.3432568, lng: 19.4981586 },
    { lat: 49.343096, lng: 19.4986104 },
    { lat: 49.3430459, lng: 19.4990187 },
    { lat: 49.3430086, lng: 19.4993136 },
    { lat: 49.3430072, lng: 19.4997755 },
    { lat: 49.343009, lng: 19.500115 },
    { lat: 49.3429408, lng: 19.5006903 },
    { lat: 49.3428746, lng: 19.5012701 },
    { lat: 49.3426966, lng: 19.5017871 },
    { lat: 49.3425003, lng: 19.5023515 },
    { lat: 49.3424642, lng: 19.5024504 },
    { lat: 49.3422173, lng: 19.5029642 },
    { lat: 49.3420096, lng: 19.5034004 },
    { lat: 49.341896, lng: 19.5036543 },
    { lat: 49.3418111, lng: 19.5038429 },
    { lat: 49.341625, lng: 19.5042624 },
    { lat: 49.3415301, lng: 19.5044745 },
    { lat: 49.3414942, lng: 19.5045531 },
    { lat: 49.3414151, lng: 19.5047227 },
    { lat: 49.3414407, lng: 19.504913 },
    { lat: 49.3414807, lng: 19.5052202 },
    { lat: 49.3415626, lng: 19.505859 },
    { lat: 49.3416099, lng: 19.506229 },
    { lat: 49.3416851, lng: 19.5068165 },
    { lat: 49.3417452, lng: 19.5073698 },
    { lat: 49.3417934, lng: 19.5077852 },
    { lat: 49.3418062, lng: 19.5078959 },
    { lat: 49.3418673, lng: 19.5084447 },
    { lat: 49.3419081, lng: 19.5087956 },
    { lat: 49.3419161, lng: 19.5088471 },
    { lat: 49.3418898, lng: 19.5088673 },
    { lat: 49.3418855, lng: 19.5088706 },
    { lat: 49.3417331, lng: 19.5089866 },
    { lat: 49.3414521, lng: 19.5091994 },
    { lat: 49.3411798, lng: 19.5094058 },
    { lat: 49.3411405, lng: 19.5094349 },
    { lat: 49.3408552, lng: 19.50965 },
    { lat: 49.3407573, lng: 19.5097294 },
    { lat: 49.3407132, lng: 19.5097721 },
    { lat: 49.340658, lng: 19.5098253 },
    { lat: 49.3403927, lng: 19.5100855 },
    { lat: 49.3403841, lng: 19.5100937 },
    { lat: 49.3402513, lng: 19.510222 },
    { lat: 49.340207, lng: 19.5102654 },
    { lat: 49.340164, lng: 19.5103061 },
    { lat: 49.3400344, lng: 19.5104315 },
    { lat: 49.3399827, lng: 19.5104826 },
    { lat: 49.3397534, lng: 19.5107426 },
    { lat: 49.3395508, lng: 19.5109694 },
    { lat: 49.3394468, lng: 19.5110863 },
    { lat: 49.3393846, lng: 19.5111558 },
    { lat: 49.3394898, lng: 19.5115121 },
    { lat: 49.3394141, lng: 19.5116094 },
    { lat: 49.3393055, lng: 19.5117492 },
    { lat: 49.3391353, lng: 19.5119682 },
    { lat: 49.3391235, lng: 19.5119829 },
    { lat: 49.3391368, lng: 19.5120273 },
    { lat: 49.3391558, lng: 19.5120972 },
    { lat: 49.3393043, lng: 19.5126631 },
    { lat: 49.3389992, lng: 19.5129153 },
    { lat: 49.3385963, lng: 19.5132718 },
    { lat: 49.33858, lng: 19.5132866 },
    { lat: 49.3383108, lng: 19.5135349 },
    { lat: 49.3382534, lng: 19.5135873 },
    { lat: 49.338152, lng: 19.513808 },
    { lat: 49.3381383, lng: 19.5145237 },
    { lat: 49.3379267, lng: 19.5145997 },
    { lat: 49.3378512, lng: 19.5146266 },
    { lat: 49.3376447, lng: 19.514697 },
    { lat: 49.3373474, lng: 19.5148991 },
    { lat: 49.3373465, lng: 19.5149003 },
    { lat: 49.3372438, lng: 19.5149727 },
    { lat: 49.3372329, lng: 19.5149814 },
    { lat: 49.3367532, lng: 19.5153107 },
    { lat: 49.3366836, lng: 19.5153636 },
    { lat: 49.3364649, lng: 19.5156102 },
    { lat: 49.3362022, lng: 19.5159037 },
    { lat: 49.3355158, lng: 19.516317 },
    { lat: 49.3350205, lng: 19.5166974 },
    { lat: 49.3347674, lng: 19.5168848 },
    { lat: 49.3347212, lng: 19.5169067 },
    { lat: 49.3344958, lng: 19.5170546 },
    { lat: 49.3344523, lng: 19.5171155 },
    { lat: 49.3341885, lng: 19.5175092 },
    { lat: 49.3340368, lng: 19.51774 },
    { lat: 49.3340182, lng: 19.517752 },
    { lat: 49.3338608, lng: 19.5178577 },
    { lat: 49.3337346, lng: 19.5179592 },
    { lat: 49.333531, lng: 19.5181641 },
    { lat: 49.3334821, lng: 19.5182061 },
    { lat: 49.3333655, lng: 19.5182648 },
    { lat: 49.333154, lng: 19.5183743 },
    { lat: 49.3329022, lng: 19.5185055 },
    { lat: 49.3329054, lng: 19.5185308 },
    { lat: 49.333108, lng: 19.5196639 },
    { lat: 49.3333362, lng: 19.5206707 },
    { lat: 49.3334358, lng: 19.5215745 },
    { lat: 49.3335079, lng: 19.5230654 },
    { lat: 49.3335739, lng: 19.5231563 },
    { lat: 49.3334697, lng: 19.5234669 },
    { lat: 49.3333291, lng: 19.5238688 },
    { lat: 49.3330359, lng: 19.5245047 },
    { lat: 49.332708, lng: 19.5251518 },
    { lat: 49.3325187, lng: 19.5259501 },
    { lat: 49.3324425, lng: 19.5263567 },
    { lat: 49.3319373, lng: 19.5262218 },
    { lat: 49.3315382, lng: 19.5261626 },
    { lat: 49.3309448, lng: 19.5260472 },
    { lat: 49.3302351, lng: 19.5258736 },
    { lat: 49.3299435, lng: 19.5257587 },
    { lat: 49.3298654, lng: 19.5257232 },
    { lat: 49.3293493, lng: 19.5256715 },
    { lat: 49.3291223, lng: 19.5246711 },
    { lat: 49.3290141, lng: 19.5238991 },
    { lat: 49.3289702, lng: 19.5238474 },
    { lat: 49.3289149, lng: 19.5238467 },
    { lat: 49.3285314, lng: 19.523812 },
    { lat: 49.328123, lng: 19.523777 },
    { lat: 49.3278843, lng: 19.5236318 },
    { lat: 49.3276846, lng: 19.5235504 },
    { lat: 49.3276003, lng: 19.5235283 },
    { lat: 49.3275387, lng: 19.5235389 },
    { lat: 49.3274711, lng: 19.5235374 },
    { lat: 49.3271899, lng: 19.5235474 },
    { lat: 49.327166, lng: 19.523594 },
    { lat: 49.3270571, lng: 19.5236023 },
    { lat: 49.326972, lng: 19.5235907 },
    { lat: 49.3269107, lng: 19.5236536 },
    { lat: 49.3268454, lng: 19.5236772 },
    { lat: 49.3267814, lng: 19.5237345 },
    { lat: 49.3267227, lng: 19.5236779 },
    { lat: 49.3265743, lng: 19.5237648 },
    { lat: 49.3264735, lng: 19.5238738 },
    { lat: 49.3262573, lng: 19.5240525 },
    { lat: 49.3261737, lng: 19.5241195 },
    { lat: 49.326093, lng: 19.5242549 },
    { lat: 49.3260083, lng: 19.5243979 },
    { lat: 49.3259426, lng: 19.5245147 },
    { lat: 49.3259152, lng: 19.5246299 },
    { lat: 49.3258988, lng: 19.5246689 },
    { lat: 49.3258628, lng: 19.5247644 },
    { lat: 49.3257991, lng: 19.5249547 },
    { lat: 49.3257492, lng: 19.5250467 },
    { lat: 49.3257105, lng: 19.5250887 },
    { lat: 49.3256713, lng: 19.5251146 },
    { lat: 49.3256368, lng: 19.5251226 },
    { lat: 49.3256018, lng: 19.525123 },
    { lat: 49.3255601, lng: 19.5251249 },
    { lat: 49.3255335, lng: 19.5251317 },
    { lat: 49.325529, lng: 19.5251347 },
    { lat: 49.3254986, lng: 19.5251567 },
    { lat: 49.3254683, lng: 19.5251867 },
    { lat: 49.3253956, lng: 19.5252699 },
    { lat: 49.3253634, lng: 19.5253051 },
    { lat: 49.3252654, lng: 19.5253841 },
    { lat: 49.3252234, lng: 19.5254244 },
    { lat: 49.3251328, lng: 19.5255145 },
    { lat: 49.3250936, lng: 19.5255458 },
    { lat: 49.3250728, lng: 19.5255556 },
    { lat: 49.325047, lng: 19.5255587 },
    { lat: 49.3250195, lng: 19.5255515 },
    { lat: 49.3249851, lng: 19.5255382 },
    { lat: 49.3248312, lng: 19.5254505 },
    { lat: 49.324751, lng: 19.5254203 },
    { lat: 49.3246566, lng: 19.5254008 },
    { lat: 49.3246064, lng: 19.5253873 },
    { lat: 49.3245739, lng: 19.5253879 },
    { lat: 49.3245389, lng: 19.5254133 },
    { lat: 49.3244471, lng: 19.5254981 },
    { lat: 49.3243802, lng: 19.5255627 },
    { lat: 49.3243381, lng: 19.5255823 },
    { lat: 49.3242719, lng: 19.5256114 },
    { lat: 49.3240003, lng: 19.5257099 },
    { lat: 49.3239249, lng: 19.5257374 },
    { lat: 49.3238961, lng: 19.5257477 },
    { lat: 49.3238064, lng: 19.52578 },
    { lat: 49.3236977, lng: 19.5258197 },
    { lat: 49.323661, lng: 19.5258066 },
    { lat: 49.3235906, lng: 19.525791 },
    { lat: 49.3235061, lng: 19.5265621 },
    { lat: 49.3235164, lng: 19.5270121 },
    { lat: 49.3234626, lng: 19.5273675 },
    { lat: 49.3234515, lng: 19.5274456 },
    { lat: 49.323453, lng: 19.5275773 },
    { lat: 49.3234498, lng: 19.5276062 },
    { lat: 49.3234249, lng: 19.5278064 },
    { lat: 49.3233263, lng: 19.5281163 },
    { lat: 49.3232222, lng: 19.5284015 },
    { lat: 49.3232154, lng: 19.5284495 },
    { lat: 49.3231736, lng: 19.5286999 },
    { lat: 49.3230986, lng: 19.5289037 },
    { lat: 49.3231098, lng: 19.5289278 },
    { lat: 49.3231069, lng: 19.5290668 },
    { lat: 49.3230838, lng: 19.5291248 },
    { lat: 49.323063, lng: 19.5293889 },
    { lat: 49.3230388, lng: 19.5294504 },
    { lat: 49.3229855, lng: 19.5294932 },
    { lat: 49.3230029, lng: 19.5296069 },
    { lat: 49.322989, lng: 19.5296229 },
    { lat: 49.3229537, lng: 19.5296613 },
    { lat: 49.3228873, lng: 19.5297908 },
    { lat: 49.3228181, lng: 19.5300026 },
    { lat: 49.322706, lng: 19.5301867 },
    { lat: 49.3226655, lng: 19.5302868 },
    { lat: 49.3225971, lng: 19.530346 },
    { lat: 49.3224766, lng: 19.5305954 },
    { lat: 49.3224292, lng: 19.5306698 },
    { lat: 49.322469, lng: 19.5307035 },
    { lat: 49.3223747, lng: 19.5308096 },
    { lat: 49.3223569, lng: 19.5308403 },
    { lat: 49.3223392, lng: 19.5308718 },
    { lat: 49.3223243, lng: 19.5309648 },
    { lat: 49.3222892, lng: 19.5310592 },
    { lat: 49.3222641, lng: 19.5311265 },
    { lat: 49.3222738, lng: 19.5312154 },
    { lat: 49.3222513, lng: 19.5312911 },
    { lat: 49.322194, lng: 19.5313192 },
    { lat: 49.322167, lng: 19.5313958 },
    { lat: 49.3221691, lng: 19.5314421 },
    { lat: 49.3220289, lng: 19.5315567 },
    { lat: 49.3219953, lng: 19.5315613 },
    { lat: 49.3219574, lng: 19.5315686 },
    { lat: 49.3219031, lng: 19.5316405 },
    { lat: 49.3218654, lng: 19.5317005 },
    { lat: 49.3218101, lng: 19.5317973 },
    { lat: 49.3218037, lng: 19.5318085 },
    { lat: 49.3217913, lng: 19.5318824 },
    { lat: 49.3217732, lng: 19.5319313 },
    { lat: 49.3217572, lng: 19.5319496 },
    { lat: 49.3217359, lng: 19.5319533 },
    { lat: 49.3217188, lng: 19.5319508 },
    { lat: 49.3216902, lng: 19.531965 },
    { lat: 49.3216584, lng: 19.5320085 },
    { lat: 49.3216336, lng: 19.5321332 },
    { lat: 49.3216347, lng: 19.5321782 },
    { lat: 49.3216141, lng: 19.5322501 },
    { lat: 49.3216007, lng: 19.5322992 },
    { lat: 49.3215757, lng: 19.5323452 },
    { lat: 49.3215706, lng: 19.5323584 },
    { lat: 49.3215688, lng: 19.5323658 },
    { lat: 49.3215617, lng: 19.5324135 },
    { lat: 49.3215611, lng: 19.532419 },
    { lat: 49.3215567, lng: 19.5324736 },
    { lat: 49.3215515, lng: 19.5325583 },
    { lat: 49.3215538, lng: 19.5326086 },
    { lat: 49.3215603, lng: 19.5326267 },
    { lat: 49.3215734, lng: 19.5326698 },
    { lat: 49.3215733, lng: 19.5326897 },
    { lat: 49.3215737, lng: 19.5326983 },
    { lat: 49.3215747, lng: 19.5327451 },
    { lat: 49.3215777, lng: 19.532812 },
    { lat: 49.3215821, lng: 19.5328361 },
    { lat: 49.3215832, lng: 19.5328609 },
    { lat: 49.3215688, lng: 19.5329137 },
    { lat: 49.3215705, lng: 19.5329291 },
    { lat: 49.3215867, lng: 19.5329642 },
    { lat: 49.3215882, lng: 19.5329716 },
    { lat: 49.321578, lng: 19.5329915 },
    { lat: 49.3215668, lng: 19.5329941 },
    { lat: 49.3215542, lng: 19.5330131 },
    { lat: 49.3215464, lng: 19.5330432 },
    { lat: 49.3215477, lng: 19.533094 },
    { lat: 49.3215554, lng: 19.5331174 },
    { lat: 49.3215883, lng: 19.5331721 },
    { lat: 49.321575, lng: 19.533254 },
    { lat: 49.321523, lng: 19.5332978 },
    { lat: 49.3215096, lng: 19.5333993 },
    { lat: 49.3214818, lng: 19.5334571 },
    { lat: 49.3214788, lng: 19.53349 },
    { lat: 49.321468, lng: 19.5335016 },
    { lat: 49.3214445, lng: 19.5335319 },
    { lat: 49.3214241, lng: 19.5335496 },
    { lat: 49.3213965, lng: 19.5335655 },
    { lat: 49.3213806, lng: 19.5335852 },
    { lat: 49.3213235, lng: 19.5336697 },
    { lat: 49.3212962, lng: 19.5336859 },
    { lat: 49.3212035, lng: 19.5337561 },
    { lat: 49.3211671, lng: 19.5337708 },
    { lat: 49.3211483, lng: 19.5337785 },
    { lat: 49.3212347, lng: 19.5341212 },
    { lat: 49.3212973, lng: 19.5343576 },
    { lat: 49.3212894, lng: 19.5345578 },
    { lat: 49.3212032, lng: 19.5347667 },
    { lat: 49.3210423, lng: 19.5349719 },
    { lat: 49.3210204, lng: 19.5349826 },
    { lat: 49.3209309, lng: 19.5350245 },
    { lat: 49.3208724, lng: 19.5350524 },
    { lat: 49.3206733, lng: 19.5351815 },
    { lat: 49.3206331, lng: 19.5352688 },
    { lat: 49.3206291, lng: 19.5352779 },
    { lat: 49.3205583, lng: 19.5354306 },
    { lat: 49.3205444, lng: 19.5354473 },
    { lat: 49.3205147, lng: 19.5354641 },
    { lat: 49.3204627, lng: 19.5355087 },
    { lat: 49.3204195, lng: 19.5355504 },
    { lat: 49.3204036, lng: 19.5355239 },
    { lat: 49.3203781, lng: 19.5354808 },
    { lat: 49.3203707, lng: 19.535491 },
    { lat: 49.3203949, lng: 19.5355292 },
    { lat: 49.3203681, lng: 19.5355588 },
    { lat: 49.3203518, lng: 19.5355201 },
    { lat: 49.3201537, lng: 19.535229 },
    { lat: 49.3199303, lng: 19.5349731 },
    { lat: 49.3197043, lng: 19.5347565 },
    { lat: 49.3197054, lng: 19.5346347 },
    { lat: 49.3197075, lng: 19.5345828 },
    { lat: 49.3197098, lng: 19.5345335 },
    { lat: 49.3197182, lng: 19.5344687 },
    { lat: 49.3197726, lng: 19.534153 },
    { lat: 49.3197839, lng: 19.5340806 },
    { lat: 49.3198912, lng: 19.5334152 },
    { lat: 49.3191718, lng: 19.5330758 },
    { lat: 49.3185208, lng: 19.5331065 },
    { lat: 49.3181798, lng: 19.5332907 },
    { lat: 49.3179495, lng: 19.5336543 },
    { lat: 49.3178726, lng: 19.5339412 },
    { lat: 49.3178521, lng: 19.5340153 },
    { lat: 49.3177767, lng: 19.5342902 },
    { lat: 49.3177765, lng: 19.5343328 },
    { lat: 49.3177751, lng: 19.5343795 },
    { lat: 49.317772, lng: 19.5344835 },
    { lat: 49.3177733, lng: 19.5345383 },
    { lat: 49.3177688, lng: 19.5347356 },
    { lat: 49.3177677, lng: 19.5347855 },
    { lat: 49.3178495, lng: 19.5353981 },
    { lat: 49.3175833, lng: 19.5354942 },
    { lat: 49.3175757, lng: 19.5354971 },
    { lat: 49.3174986, lng: 19.5355627 },
    { lat: 49.3173043, lng: 19.5357502 },
    { lat: 49.3171625, lng: 19.5359046 },
    { lat: 49.3169558, lng: 19.536089 },
    { lat: 49.3168375, lng: 19.5362135 },
    { lat: 49.3167999, lng: 19.5362536 },
    { lat: 49.3166556, lng: 19.5364513 },
    { lat: 49.3163422, lng: 19.5368243 },
    { lat: 49.3160209, lng: 19.5371906 },
    { lat: 49.3158433, lng: 19.5373777 },
    { lat: 49.3156419, lng: 19.5377306 },
    { lat: 49.3154043, lng: 19.5382036 },
    { lat: 49.3151544, lng: 19.5387082 },
    { lat: 49.3148282, lng: 19.539517 },
    { lat: 49.3147049, lng: 19.5397778 },
    { lat: 49.3145202, lng: 19.5401303 },
    { lat: 49.3144218, lng: 19.5402942 },
    { lat: 49.3142205, lng: 19.5406301 },
    { lat: 49.314118, lng: 19.5408016 },
    { lat: 49.313987, lng: 19.5408696 },
    { lat: 49.3137023, lng: 19.541054 },
    { lat: 49.3134619, lng: 19.5411401 },
    { lat: 49.3130476, lng: 19.5411717 },
    { lat: 49.3128541, lng: 19.5411802 },
    { lat: 49.3125767, lng: 19.5411273 },
    { lat: 49.3122508, lng: 19.540996 },
    { lat: 49.3120245, lng: 19.540899 },
    { lat: 49.3118677, lng: 19.5408202 },
    { lat: 49.3113619, lng: 19.5404466 },
    { lat: 49.3105955, lng: 19.5398676 },
    { lat: 49.3104788, lng: 19.5397298 },
    { lat: 49.3101203, lng: 19.5393257 },
    { lat: 49.3100029, lng: 19.5391219 },
    { lat: 49.3097975, lng: 19.5393411 },
    { lat: 49.3096752, lng: 19.5393765 },
    { lat: 49.3094837, lng: 19.5394487 },
    { lat: 49.3093432, lng: 19.539486 },
    { lat: 49.3091879, lng: 19.5396371 },
    { lat: 49.3090691, lng: 19.5398501 },
    { lat: 49.3090142, lng: 19.539905 },
    { lat: 49.3087798, lng: 19.5401303 },
    { lat: 49.3087042, lng: 19.5402025 },
    { lat: 49.308454, lng: 19.540376 },
    { lat: 49.3083838, lng: 19.5404675 },
    { lat: 49.3083469, lng: 19.5405975 },
    { lat: 49.3081726, lng: 19.540804 },
    { lat: 49.3079765, lng: 19.5409996 },
    { lat: 49.3076016, lng: 19.5413346 },
    { lat: 49.3073351, lng: 19.5416437 },
    { lat: 49.3071494, lng: 19.5418952 },
    { lat: 49.3070195, lng: 19.5419891 },
    { lat: 49.3068283, lng: 19.5420949 },
    { lat: 49.3067276, lng: 19.542211 },
    { lat: 49.3063885, lng: 19.5423866 },
    { lat: 49.3063057, lng: 19.5424726 },
    { lat: 49.3058278, lng: 19.5429164 },
    { lat: 49.3057112, lng: 19.543058 },
    { lat: 49.3055393, lng: 19.5431921 },
    { lat: 49.3052993, lng: 19.5433626 },
    { lat: 49.3051517, lng: 19.5434862 },
    { lat: 49.305081, lng: 19.5435464 },
    { lat: 49.3047317, lng: 19.5438404 },
    { lat: 49.3046502, lng: 19.5439086 },
    { lat: 49.3041953, lng: 19.5441643 },
    { lat: 49.3037476, lng: 19.5447525 },
    { lat: 49.3035954, lng: 19.5450488 },
    { lat: 49.3032027, lng: 19.5456102 },
    { lat: 49.3026452, lng: 19.546162 },
    { lat: 49.3026307, lng: 19.5462163 },
    { lat: 49.3024564, lng: 19.546868 },
    { lat: 49.3023974, lng: 19.5470876 },
    { lat: 49.3023403, lng: 19.5475185 },
    { lat: 49.3022782, lng: 19.5479147 },
    { lat: 49.3022572, lng: 19.5480461 },
    { lat: 49.3021009, lng: 19.5487512 },
    { lat: 49.3020462, lng: 19.5491855 },
    { lat: 49.3020578, lng: 19.5493175 },
    { lat: 49.3019408, lng: 19.5497223 },
    { lat: 49.3019058, lng: 19.5498466 },
    { lat: 49.301684, lng: 19.5502249 },
    { lat: 49.3015025, lng: 19.5504921 },
    { lat: 49.3013501, lng: 19.5507158 },
    { lat: 49.3012993, lng: 19.5507612 },
    { lat: 49.3010298, lng: 19.5510052 },
    { lat: 49.3006513, lng: 19.551234 },
    { lat: 49.3006126, lng: 19.551249 },
    { lat: 49.3002728, lng: 19.5513325 },
    { lat: 49.3000622, lng: 19.551384 },
    { lat: 49.29977, lng: 19.5514491 },
    { lat: 49.2995082, lng: 19.5513912 },
    { lat: 49.299301, lng: 19.5512427 },
    { lat: 49.2991695, lng: 19.5511534 },
    { lat: 49.2988885, lng: 19.5511159 },
    { lat: 49.2987441, lng: 19.5511134 },
    { lat: 49.2985853, lng: 19.5511414 },
    { lat: 49.2983721, lng: 19.5509304 },
    { lat: 49.2978289, lng: 19.5502058 },
    { lat: 49.29763, lng: 19.5501192 },
    { lat: 49.2973254, lng: 19.5500362 },
    { lat: 49.2972602, lng: 19.5500402 },
    { lat: 49.2967886, lng: 19.5500478 },
    { lat: 49.2966351, lng: 19.5500947 },
    { lat: 49.2965143, lng: 19.550236 },
    { lat: 49.29602, lng: 19.5507181 },
    { lat: 49.2959919, lng: 19.5507456 },
    { lat: 49.2959236, lng: 19.5508529 },
    { lat: 49.2957147, lng: 19.5511948 },
    { lat: 49.2956329, lng: 19.5513283 },
    { lat: 49.2954356, lng: 19.5517744 },
    { lat: 49.2954162, lng: 19.5518923 },
    { lat: 49.2953826, lng: 19.5520698 },
    { lat: 49.2953481, lng: 19.5521305 },
    { lat: 49.2950938, lng: 19.5524386 },
    { lat: 49.2949097, lng: 19.5526291 },
    { lat: 49.2946586, lng: 19.5531794 },
    { lat: 49.2945497, lng: 19.5533311 },
    { lat: 49.294506, lng: 19.5533919 },
    { lat: 49.2942099, lng: 19.5535954 },
    { lat: 49.2940972, lng: 19.5536726 },
    { lat: 49.2938832, lng: 19.5538204 },
    { lat: 49.2938069, lng: 19.553957 },
    { lat: 49.2935608, lng: 19.5541155 },
    { lat: 49.2932578, lng: 19.5543189 },
    { lat: 49.2940925, lng: 19.5554144 },
    { lat: 49.2947339, lng: 19.5563431 },
    { lat: 49.294837, lng: 19.5567286 },
    { lat: 49.2949916, lng: 19.55731 },
    { lat: 49.29513, lng: 19.5587065 },
    { lat: 49.2951712, lng: 19.5591191 },
    { lat: 49.2952275, lng: 19.5604676 },
    { lat: 49.2953495, lng: 19.5616009 },
    { lat: 49.2953907, lng: 19.5617146 },
    { lat: 49.2957249, lng: 19.5626551 },
    { lat: 49.2965278, lng: 19.5640217 },
    { lat: 49.2971542, lng: 19.5649441 },
    { lat: 49.2976378, lng: 19.5659798 },
    { lat: 49.297931, lng: 19.567011 },
    { lat: 49.2982356, lng: 19.5680926 },
    { lat: 49.2982019, lng: 19.5694199 },
    { lat: 49.2984771, lng: 19.5706567 },
    { lat: 49.298992, lng: 19.5719591 },
    { lat: 49.2998542, lng: 19.5731663 },
    { lat: 49.3005744, lng: 19.5741765 },
    { lat: 49.3005852, lng: 19.5741912 },
    { lat: 49.3011266, lng: 19.5756831 },
    { lat: 49.3013786, lng: 19.5775809 },
    { lat: 49.3015175, lng: 19.5785656 },
    { lat: 49.3013958, lng: 19.5817759 },
    { lat: 49.3011051, lng: 19.584335 },
    { lat: 49.3013518, lng: 19.5858662 },
    { lat: 49.3017021, lng: 19.5897816 },
    { lat: 49.301713, lng: 19.5898761 },
    { lat: 49.3017115, lng: 19.5899171 },
    { lat: 49.3013639, lng: 19.5931712 },
    { lat: 49.3011506, lng: 19.5940705 },
    { lat: 49.3001242, lng: 19.5966069 },
    { lat: 49.2997794, lng: 19.5977806 },
    { lat: 49.2995722, lng: 19.5985007 },
    { lat: 49.2995758, lng: 19.5985011 },
    { lat: 49.2995828, lng: 19.5984841 },
    { lat: 49.3007814, lng: 19.5984038 },
    { lat: 49.3016238, lng: 19.5983484 },
    { lat: 49.3023403, lng: 19.5989269 },
    { lat: 49.3031032, lng: 19.5993041 },
    { lat: 49.3039917, lng: 19.5995971 },
    { lat: 49.3043915, lng: 19.5995206 },
    { lat: 49.3050321, lng: 19.59926 },
    { lat: 49.3060731, lng: 19.5987041 },
    { lat: 49.3069968, lng: 19.5975203 },
    { lat: 49.3078759, lng: 19.5962216 },
    { lat: 49.3089213, lng: 19.5952098 },
    { lat: 49.3102498, lng: 19.594889 },
    { lat: 49.3110945, lng: 19.595965 },
    { lat: 49.3111252, lng: 19.5959965 },
    { lat: 49.312107, lng: 19.5967848 },
    { lat: 49.3128673, lng: 19.5967558 },
    { lat: 49.3137334, lng: 19.5963751 },
    { lat: 49.3147216, lng: 19.595947 },
    { lat: 49.3162744, lng: 19.5957059 },
    { lat: 49.3170607, lng: 19.5962841 },
    { lat: 49.3176726, lng: 19.596891 },
    { lat: 49.3193689, lng: 19.5972601 },
    { lat: 49.3207103, lng: 19.5970488 },
    { lat: 49.3218627, lng: 19.5965182 },
    { lat: 49.3223248, lng: 19.5966945 },
    { lat: 49.323736, lng: 19.5969147 },
    { lat: 49.3246863, lng: 19.5971012 },
    { lat: 49.3256131, lng: 19.5972469 },
    { lat: 49.3261978, lng: 19.5976469 },
    { lat: 49.3273603, lng: 19.5984487 },
    { lat: 49.3275005, lng: 19.5985594 },
    { lat: 49.3286024, lng: 19.5997241 },
    { lat: 49.3291313, lng: 19.6003912 },
    { lat: 49.3302194, lng: 19.60086 },
    { lat: 49.3316065, lng: 19.6010962 },
    { lat: 49.3316313, lng: 19.6004423 },
    { lat: 49.332376, lng: 19.6003167 },
    { lat: 49.3325404, lng: 19.6002344 },
    { lat: 49.3325481, lng: 19.5987236 },
    { lat: 49.3325544, lng: 19.5986106 },
    { lat: 49.3326914, lng: 19.5986277 },
    { lat: 49.3327509, lng: 19.5986703 },
    { lat: 49.3329177, lng: 19.5986463 },
    { lat: 49.3330005, lng: 19.5987604 },
    { lat: 49.33309, lng: 19.5987724 },
    { lat: 49.3332867, lng: 19.5989111 },
    { lat: 49.3333026, lng: 19.5989661 },
    { lat: 49.3334217, lng: 19.5990364 },
    { lat: 49.3336787, lng: 19.599241 },
    { lat: 49.3337229, lng: 19.5992202 },
    { lat: 49.333791, lng: 19.5992553 },
    { lat: 49.3338502, lng: 19.5993965 },
    { lat: 49.3338471, lng: 19.5995529 },
    { lat: 49.333973, lng: 19.599725 },
    { lat: 49.3342601, lng: 19.59992 },
    { lat: 49.3343139, lng: 19.6002941 },
    { lat: 49.3343576, lng: 19.6003104 },
    { lat: 49.3343964, lng: 19.6003974 },
    { lat: 49.3344705, lng: 19.6004442 },
    { lat: 49.3344811, lng: 19.6005269 },
    { lat: 49.334597, lng: 19.6006289 },
    { lat: 49.3346746, lng: 19.6007487 },
    { lat: 49.334696, lng: 19.6006988 },
    { lat: 49.3347438, lng: 19.6007122 },
    { lat: 49.3348934, lng: 19.6004512 },
    { lat: 49.3349798, lng: 19.6002175 },
    { lat: 49.3355595, lng: 19.5997778 },
    { lat: 49.3361689, lng: 19.5995251 },
    { lat: 49.3365721, lng: 19.5993452 },
    { lat: 49.336611, lng: 19.5996887 },
    { lat: 49.3366473, lng: 19.5997651 },
    { lat: 49.3366929, lng: 19.6000583 },
    { lat: 49.3367834, lng: 19.6002909 },
    { lat: 49.3368354, lng: 19.6005456 },
    { lat: 49.3369883, lng: 19.6006177 },
    { lat: 49.3370454, lng: 19.6006858 },
    { lat: 49.3371287, lng: 19.6007078 },
    { lat: 49.3372089, lng: 19.6005881 },
    { lat: 49.3373373, lng: 19.6004833 },
    { lat: 49.3375126, lng: 19.6001741 },
    { lat: 49.337703, lng: 19.600023 },
    { lat: 49.3381043, lng: 19.599976 },
    { lat: 49.338254, lng: 19.6000499 },
    { lat: 49.3385954, lng: 19.6000972 },
    { lat: 49.3389526, lng: 19.5998987 },
    { lat: 49.3391729, lng: 19.5997127 },
    { lat: 49.3392836, lng: 19.5996447 },
    { lat: 49.3394162, lng: 19.5996106 },
    { lat: 49.3394822, lng: 19.5995726 },
    { lat: 49.3395804, lng: 19.5992488 },
    { lat: 49.3395688, lng: 19.5990686 },
    { lat: 49.3396857, lng: 19.5988855 },
    { lat: 49.3398311, lng: 19.5988366 },
    { lat: 49.3398925, lng: 19.5988772 },
    { lat: 49.3401315, lng: 19.599055 },
    { lat: 49.3402021, lng: 19.5990849 },
    { lat: 49.3402898, lng: 19.5988746 },
    { lat: 49.3405154, lng: 19.5987104 },
    { lat: 49.3407226, lng: 19.5986712 },
    { lat: 49.3408961, lng: 19.5986731 },
    { lat: 49.3411262, lng: 19.5983897 },
    { lat: 49.3413181, lng: 19.5983338 },
    { lat: 49.3414471, lng: 19.5976879 },
    { lat: 49.3412402, lng: 19.5973432 },
    { lat: 49.3410543, lng: 19.5965631 },
    { lat: 49.3410301, lng: 19.5963713 },
    { lat: 49.3413946, lng: 19.5961604 },
    { lat: 49.3416169, lng: 19.5959691 },
    { lat: 49.341668, lng: 19.5959496 },
    { lat: 49.342054, lng: 19.5956653 },
    { lat: 49.342149, lng: 19.595625 },
    { lat: 49.3422303, lng: 19.5955265 },
    { lat: 49.3425323, lng: 19.5952744 },
    { lat: 49.3427076, lng: 19.5951657 },
    { lat: 49.3428496, lng: 19.5951154 },
    { lat: 49.3428983, lng: 19.5951439 },
    { lat: 49.3429758, lng: 19.5951409 },
    { lat: 49.3431125, lng: 19.5950723 },
    { lat: 49.3432864, lng: 19.5950321 },
    { lat: 49.3433494, lng: 19.5950067 },
    { lat: 49.3434936, lng: 19.5949041 },
    { lat: 49.3442055, lng: 19.5942221 },
    { lat: 49.344436, lng: 19.5938858 },
    { lat: 49.3445077, lng: 19.5937005 },
    { lat: 49.3445581, lng: 19.5935438 },
    { lat: 49.3446678, lng: 19.5932996 },
    { lat: 49.3447234, lng: 19.5932063 },
    { lat: 49.3447713, lng: 19.5931969 },
    { lat: 49.3456301, lng: 19.5938588 },
    { lat: 49.3457351, lng: 19.5935577 },
    { lat: 49.3460082, lng: 19.5937726 },
    { lat: 49.3467603, lng: 19.5943886 },
    { lat: 49.3469737, lng: 19.594557 },
    { lat: 49.3470051, lng: 19.5944951 },
    { lat: 49.347062, lng: 19.5944356 },
    { lat: 49.3470809, lng: 19.5944017 },
    { lat: 49.347097, lng: 19.5943496 },
    { lat: 49.347142, lng: 19.5941878 },
    { lat: 49.3471645, lng: 19.5941521 },
    { lat: 49.3471894, lng: 19.5941296 },
    { lat: 49.3472051, lng: 19.5940919 },
    { lat: 49.3472259, lng: 19.5940399 },
    { lat: 49.3472492, lng: 19.5939952 },
    { lat: 49.3472757, lng: 19.5939635 },
    { lat: 49.3472962, lng: 19.5939318 },
    { lat: 49.3473269, lng: 19.5938708 },
    { lat: 49.3473485, lng: 19.5938507 },
    { lat: 49.3473732, lng: 19.5938422 },
    { lat: 49.3473868, lng: 19.5938525 },
    { lat: 49.3473997, lng: 19.5938732 },
    { lat: 49.347404, lng: 19.5939325 },
    { lat: 49.3474121, lng: 19.5939584 },
    { lat: 49.3474233, lng: 19.5939773 },
    { lat: 49.3474517, lng: 19.5939952 },
    { lat: 49.3475087, lng: 19.5940359 },
    { lat: 49.3475453, lng: 19.5940423 },
    { lat: 49.3475727, lng: 19.5940324 },
    { lat: 49.3475885, lng: 19.5940161 },
    { lat: 49.3476013, lng: 19.5939882 },
    { lat: 49.3476228, lng: 19.5938853 },
    { lat: 49.3476335, lng: 19.5938572 },
    { lat: 49.3476473, lng: 19.5938501 },
    { lat: 49.3476825, lng: 19.593849 },
    { lat: 49.3477207, lng: 19.5938484 },
    { lat: 49.3477334, lng: 19.5938529 },
    { lat: 49.347745, lng: 19.5938666 },
    { lat: 49.3477543, lng: 19.5939511 },
    { lat: 49.3477663, lng: 19.5939807 },
    { lat: 49.3477796, lng: 19.593994 },
    { lat: 49.347794, lng: 19.5939996 },
    { lat: 49.3478208, lng: 19.5939863 },
    { lat: 49.3478423, lng: 19.593965 },
    { lat: 49.347913, lng: 19.5938968 },
    { lat: 49.3479719, lng: 19.5938456 },
    { lat: 49.3480261, lng: 19.5938294 },
    { lat: 49.3481035, lng: 19.5938318 },
    { lat: 49.3481748, lng: 19.5938529 },
    { lat: 49.3482371, lng: 19.5938826 },
    { lat: 49.348257, lng: 19.5939048 },
    { lat: 49.348277, lng: 19.5939189 },
    { lat: 49.348309, lng: 19.5939243 },
    { lat: 49.348327, lng: 19.5939081 },
    { lat: 49.3483422, lng: 19.5938813 },
    { lat: 49.3483674, lng: 19.5938088 },
    { lat: 49.3484091, lng: 19.5937417 },
    { lat: 49.3484612, lng: 19.5936826 },
    { lat: 49.3484889, lng: 19.5936625 },
    { lat: 49.3485813, lng: 19.5936397 },
    { lat: 49.3486133, lng: 19.5936049 },
    { lat: 49.3486268, lng: 19.5935842 },
    { lat: 49.3486485, lng: 19.5935162 },
    { lat: 49.348655, lng: 19.5934883 },
    { lat: 49.348665, lng: 19.593471 },
    { lat: 49.3486945, lng: 19.5934659 },
    { lat: 49.3487251, lng: 19.5934687 },
    { lat: 49.348842, lng: 19.593472 },
    { lat: 49.3489118, lng: 19.593479 },
    { lat: 49.3489315, lng: 19.5934778 },
    { lat: 49.3489484, lng: 19.5934678 },
    { lat: 49.3489621, lng: 19.5934413 },
    { lat: 49.3489949, lng: 19.5933352 },
    { lat: 49.3490444, lng: 19.5932483 },
    { lat: 49.3491271, lng: 19.5931615 },
    { lat: 49.349206, lng: 19.5930845 },
    { lat: 49.3492989, lng: 19.5930169 },
    { lat: 49.3493893, lng: 19.5930057 },
    { lat: 49.3494079, lng: 19.5929854 },
    { lat: 49.3494177, lng: 19.5929423 },
    { lat: 49.349414, lng: 19.5928979 },
    { lat: 49.3493958, lng: 19.5928474 },
    { lat: 49.3493911, lng: 19.5928134 },
    { lat: 49.3493986, lng: 19.5927521 },
    { lat: 49.34941, lng: 19.5927151 },
    { lat: 49.3494069, lng: 19.5926729 },
    { lat: 49.3493945, lng: 19.5926388 },
    { lat: 49.3493769, lng: 19.5926037 },
    { lat: 49.3493547, lng: 19.5925797 },
    { lat: 49.349316, lng: 19.5925586 },
    { lat: 49.3492721, lng: 19.5925537 },
    { lat: 49.3491723, lng: 19.5925674 },
    { lat: 49.3491329, lng: 19.5925612 },
    { lat: 49.3490948, lng: 19.5925468 },
    { lat: 49.3490397, lng: 19.5925079 },
    { lat: 49.3490105, lng: 19.5924788 },
    { lat: 49.3489912, lng: 19.592462 },
    { lat: 49.3489812, lng: 19.5924338 },
    { lat: 49.3489805, lng: 19.5924076 },
    { lat: 49.3489881, lng: 19.5923529 },
    { lat: 49.3489904, lng: 19.5923044 },
    { lat: 49.3489936, lng: 19.5922297 },
    { lat: 49.3490037, lng: 19.5921911 },
    { lat: 49.349027, lng: 19.5921657 },
    { lat: 49.3490843, lng: 19.5921521 },
    { lat: 49.3491136, lng: 19.5921463 },
    { lat: 49.349131, lng: 19.5921324 },
    { lat: 49.3491543, lng: 19.5920938 },
    { lat: 49.3491743, lng: 19.592044 },
    { lat: 49.3491965, lng: 19.5919845 },
    { lat: 49.3492063, lng: 19.5919131 },
    { lat: 49.34922, lng: 19.5918478 },
    { lat: 49.3492286, lng: 19.5917951 },
    { lat: 49.3492259, lng: 19.5917696 },
    { lat: 49.3492165, lng: 19.5917441 },
    { lat: 49.3491866, lng: 19.5917221 },
    { lat: 49.3491434, lng: 19.5917083 },
    { lat: 49.3490798, lng: 19.5916837 },
    { lat: 49.3489979, lng: 19.5916696 },
    { lat: 49.348908, lng: 19.591698 },
    { lat: 49.3488683, lng: 19.591704 },
    { lat: 49.3488397, lng: 19.59169 },
    { lat: 49.3488163, lng: 19.5916639 },
    { lat: 49.3488046, lng: 19.5916305 },
    { lat: 49.3488022, lng: 19.5915972 },
    { lat: 49.3488055, lng: 19.5915465 },
    { lat: 49.348818, lng: 19.5915018 },
    { lat: 49.3488376, lng: 19.5914559 },
    { lat: 49.3488625, lng: 19.5914222 },
    { lat: 49.3488864, lng: 19.5913886 },
    { lat: 49.3489069, lng: 19.591383 },
    { lat: 49.3489373, lng: 19.5913966 },
    { lat: 49.3489776, lng: 19.5914323 },
    { lat: 49.3489925, lng: 19.5914432 },
    { lat: 49.3490115, lng: 19.5914487 },
    { lat: 49.3490403, lng: 19.5914373 },
    { lat: 49.3490863, lng: 19.5913883 },
    { lat: 49.3491226, lng: 19.5913569 },
    { lat: 49.3491531, lng: 19.5913473 },
    { lat: 49.3491963, lng: 19.5913475 },
    { lat: 49.3492197, lng: 19.5913418 },
    { lat: 49.3492361, lng: 19.5913256 },
    { lat: 49.3492555, lng: 19.5912837 },
    { lat: 49.3492787, lng: 19.5912307 },
    { lat: 49.3493052, lng: 19.5912122 },
    { lat: 49.3493271, lng: 19.5912229 },
    { lat: 49.3493438, lng: 19.5912557 },
    { lat: 49.3493673, lng: 19.5912797 },
    { lat: 49.3494566, lng: 19.5913053 },
    { lat: 49.3495325, lng: 19.5913203 },
    { lat: 49.3495716, lng: 19.5913123 },
    { lat: 49.3495946, lng: 19.5912983 },
    { lat: 49.3496055, lng: 19.5912685 },
    { lat: 49.3496047, lng: 19.5912308 },
    { lat: 49.349588, lng: 19.5912067 },
    { lat: 49.34955, lng: 19.5911827 },
    { lat: 49.349527, lng: 19.5911548 },
    { lat: 49.3495109, lng: 19.5911216 },
    { lat: 49.3494979, lng: 19.5910816 },
    { lat: 49.3494913, lng: 19.5910381 },
    { lat: 49.3494951, lng: 19.5909921 },
    { lat: 49.3495128, lng: 19.5909461 },
    { lat: 49.34955, lng: 19.5909003 },
    { lat: 49.3495594, lng: 19.5908776 },
    { lat: 49.3495592, lng: 19.5908506 },
    { lat: 49.3495556, lng: 19.5908271 },
    { lat: 49.3495485, lng: 19.5907683 },
    { lat: 49.349549, lng: 19.5907301 },
    { lat: 49.3495529, lng: 19.5906954 },
    { lat: 49.3495637, lng: 19.5906555 },
    { lat: 49.3495779, lng: 19.5906378 },
    { lat: 49.3495957, lng: 19.5906374 },
    { lat: 49.3496153, lng: 19.5906617 },
    { lat: 49.3496508, lng: 19.5907349 },
    { lat: 49.3496741, lng: 19.5907496 },
    { lat: 49.3497007, lng: 19.5907444 },
    { lat: 49.3497572, lng: 19.5907276 },
    { lat: 49.3498074, lng: 19.5907289 },
    { lat: 49.3498323, lng: 19.590716 },
    { lat: 49.3498439, lng: 19.5906941 },
    { lat: 49.3498496, lng: 19.5906704 },
    { lat: 49.3498617, lng: 19.5906326 },
    { lat: 49.3498865, lng: 19.5906001 },
    { lat: 49.3499419, lng: 19.5905445 },
    { lat: 49.3499594, lng: 19.5905093 },
    { lat: 49.3499711, lng: 19.5904688 },
    { lat: 49.3499745, lng: 19.5904174 },
    { lat: 49.3499743, lng: 19.5903714 },
    { lat: 49.34998, lng: 19.5903229 },
    { lat: 49.3499724, lng: 19.5902726 },
    { lat: 49.3499461, lng: 19.5902226 },
    { lat: 49.3499255, lng: 19.5901763 },
    { lat: 49.3499135, lng: 19.5901426 },
    { lat: 49.3499118, lng: 19.5901115 },
    { lat: 49.3499328, lng: 19.5900709 },
    { lat: 49.3499446, lng: 19.590033 },
    { lat: 49.3499393, lng: 19.5900137 },
    { lat: 49.3499179, lng: 19.5899855 },
    { lat: 49.3498888, lng: 19.5899714 },
    { lat: 49.3498585, lng: 19.5899724 },
    { lat: 49.3498046, lng: 19.589996 },
    { lat: 49.3497473, lng: 19.5900347 },
    { lat: 49.3497302, lng: 19.590067 },
    { lat: 49.3497154, lng: 19.590131 },
    { lat: 49.3497051, lng: 19.5901507 },
    { lat: 49.3496817, lng: 19.5901751 },
    { lat: 49.3496544, lng: 19.5901899 },
    { lat: 49.3496318, lng: 19.5901906 },
    { lat: 49.3496013, lng: 19.5901732 },
    { lat: 49.3495327, lng: 19.5901028 },
    { lat: 49.3494856, lng: 19.5900453 },
    { lat: 49.3494589, lng: 19.5900196 },
    { lat: 49.3494241, lng: 19.5899957 },
    { lat: 49.3493836, lng: 19.5899493 },
    { lat: 49.349346, lng: 19.5898967 },
    { lat: 49.3493067, lng: 19.5898495 },
    { lat: 49.3492882, lng: 19.5898215 },
    { lat: 49.3492766, lng: 19.589789 },
    { lat: 49.3492642, lng: 19.5897275 },
    { lat: 49.3492506, lng: 19.5896943 },
    { lat: 49.3492272, lng: 19.5896641 },
    { lat: 49.3491507, lng: 19.5895877 },
    { lat: 49.3491201, lng: 19.5895489 },
    { lat: 49.3490985, lng: 19.589495 },
    { lat: 49.3490807, lng: 19.589438 },
    { lat: 49.3490855, lng: 19.5894146 },
    { lat: 49.3491011, lng: 19.5893941 },
    { lat: 49.3491294, lng: 19.5893833 },
    { lat: 49.3491653, lng: 19.5893735 },
    { lat: 49.3491787, lng: 19.5893602 },
    { lat: 49.3491892, lng: 19.5892978 },
    { lat: 49.3492267, lng: 19.589223 },
    { lat: 49.3492494, lng: 19.5891932 },
    { lat: 49.3492779, lng: 19.589174 },
    { lat: 49.3492978, lng: 19.5891608 },
    { lat: 49.349332, lng: 19.5891693 },
    { lat: 49.3493479, lng: 19.5891664 },
    { lat: 49.3493604, lng: 19.5891459 },
    { lat: 49.3493673, lng: 19.589105 },
    { lat: 49.3493632, lng: 19.589081 },
    { lat: 49.3493568, lng: 19.5890659 },
    { lat: 49.3492734, lng: 19.5889903 },
    { lat: 49.3492511, lng: 19.5889642 },
    { lat: 49.3492233, lng: 19.5889052 },
    { lat: 49.3491823, lng: 19.5888505 },
    { lat: 49.3491617, lng: 19.5888388 },
    { lat: 49.3491435, lng: 19.5888484 },
    { lat: 49.349111, lng: 19.5889272 },
    { lat: 49.3490916, lng: 19.5889333 },
    { lat: 49.3490768, lng: 19.5889235 },
    { lat: 49.3490678, lng: 19.588906 },
    { lat: 49.3490633, lng: 19.5888849 },
    { lat: 49.3490663, lng: 19.5888214 },
    { lat: 49.3490746, lng: 19.5887792 },
    { lat: 49.3490998, lng: 19.5887137 },
    { lat: 49.349113, lng: 19.5886703 },
    { lat: 49.3491205, lng: 19.5886039 },
    { lat: 49.3491353, lng: 19.5885217 },
    { lat: 49.3491507, lng: 19.5884522 },
    { lat: 49.3491754, lng: 19.5884076 },
    { lat: 49.3492329, lng: 19.5883497 },
    { lat: 49.3492634, lng: 19.5883146 },
    { lat: 49.3492824, lng: 19.5882759 },
    { lat: 49.349284, lng: 19.5882396 },
    { lat: 49.3492786, lng: 19.5882157 },
    { lat: 49.3492634, lng: 19.5881918 },
    { lat: 49.3492425, lng: 19.5881847 },
    { lat: 49.3492138, lng: 19.5881896 },
    { lat: 49.3491839, lng: 19.5881829 },
    { lat: 49.3491725, lng: 19.5881621 },
    { lat: 49.3491692, lng: 19.5881209 },
    { lat: 49.3491805, lng: 19.5880795 },
    { lat: 49.3492871, lng: 19.5879271 },
    { lat: 49.3494291, lng: 19.587795 },
    { lat: 49.3495385, lng: 19.5876946 },
    { lat: 49.3495863, lng: 19.5876632 },
    { lat: 49.3496288, lng: 19.5876466 },
    { lat: 49.3496741, lng: 19.5876324 },
    { lat: 49.3496985, lng: 19.5875942 },
    { lat: 49.3496983, lng: 19.5875568 },
    { lat: 49.3496746, lng: 19.5875122 },
    { lat: 49.3496434, lng: 19.587489 },
    { lat: 49.3496103, lng: 19.5874653 },
    { lat: 49.3495964, lng: 19.5874309 },
    { lat: 49.3495897, lng: 19.5873757 },
    { lat: 49.3496029, lng: 19.5873025 },
    { lat: 49.3496406, lng: 19.5872105 },
    { lat: 49.3497805, lng: 19.5869714 },
    { lat: 49.3498584, lng: 19.5868798 },
    { lat: 49.350072, lng: 19.5866579 },
    { lat: 49.3501181, lng: 19.5866095 },
    { lat: 49.3501573, lng: 19.5865798 },
    { lat: 49.3502461, lng: 19.586559 },
    { lat: 49.3503, lng: 19.5865325 },
    { lat: 49.3503741, lng: 19.5864926 },
    { lat: 49.350441, lng: 19.5864225 },
    { lat: 49.3504947, lng: 19.586355 },
    { lat: 49.3505619, lng: 19.5862454 },
    { lat: 49.350605, lng: 19.5861788 },
    { lat: 49.3506516, lng: 19.5861256 },
    { lat: 49.3509241, lng: 19.5858481 },
    { lat: 49.3509735, lng: 19.5858093 },
    { lat: 49.3510185, lng: 19.5857638 },
    { lat: 49.3510965, lng: 19.5856503 },
    { lat: 49.3511519, lng: 19.5855394 },
    { lat: 49.3512018, lng: 19.5854479 },
    { lat: 49.3512337, lng: 19.5854103 },
    { lat: 49.3512656, lng: 19.5853833 },
    { lat: 49.3515273, lng: 19.5850792 },
    { lat: 49.3515968, lng: 19.5849817 },
    { lat: 49.3517448, lng: 19.5847146 },
    { lat: 49.3517818, lng: 19.5846334 },
    { lat: 49.3518182, lng: 19.584555 },
    { lat: 49.3519034, lng: 19.5844282 },
    { lat: 49.3519286, lng: 19.5843847 },
    { lat: 49.3519468, lng: 19.5843439 },
    { lat: 49.3519686, lng: 19.5842404 },
    { lat: 49.3519782, lng: 19.5841563 },
    { lat: 49.3519852, lng: 19.584056 },
    { lat: 49.3520035, lng: 19.5839811 },
    { lat: 49.3520377, lng: 19.583896 },
    { lat: 49.3521086, lng: 19.5837836 },
    { lat: 49.3521728, lng: 19.5837139 },
    { lat: 49.3522811, lng: 19.5836423 },
    { lat: 49.3523552, lng: 19.5835992 },
    { lat: 49.3524448, lng: 19.5842457 },
    { lat: 49.3529346, lng: 19.5837037 },
    { lat: 49.3531203, lng: 19.5835304 },
    { lat: 49.3538683, lng: 19.5827478 },
    { lat: 49.3542516, lng: 19.5824766 },
    { lat: 49.3545484, lng: 19.5823416 },
    { lat: 49.3548953, lng: 19.5822864 },
    { lat: 49.3550848, lng: 19.5823153 },
    { lat: 49.3552609, lng: 19.5821471 },
    { lat: 49.3554288, lng: 19.5820471 },
    { lat: 49.3559672, lng: 19.5819713 },
    { lat: 49.356355, lng: 19.5820282 },
    { lat: 49.3566164, lng: 19.582103 },
    { lat: 49.3571003, lng: 19.582192 },
    { lat: 49.3574398, lng: 19.5822499 },
    { lat: 49.3583159, lng: 19.5822641 },
    { lat: 49.358584, lng: 19.5820414 },
    { lat: 49.3616876, lng: 19.5793483 },
    { lat: 49.3626242, lng: 19.5784495 },
    { lat: 49.3633711, lng: 19.5774705 },
    { lat: 49.3640779, lng: 19.5752699 },
    { lat: 49.3635743, lng: 19.5737917 },
    { lat: 49.3631856, lng: 19.5718344 },
    { lat: 49.3630461, lng: 19.571137 },
    { lat: 49.3629497, lng: 19.5706718 },
    { lat: 49.3627091, lng: 19.5693275 },
    { lat: 49.3629715, lng: 19.5683478 },
    { lat: 49.3632484, lng: 19.5672179 },
    { lat: 49.36354, lng: 19.5658327 },
    { lat: 49.3637289, lng: 19.5646994 },
    { lat: 49.3637663, lng: 19.5644493 },
    { lat: 49.36399, lng: 19.5629154 },
    { lat: 49.36404, lng: 19.5623216 },
    { lat: 49.3640664, lng: 19.5618055 },
    { lat: 49.3641329, lng: 19.5614323 },
    { lat: 49.3642774, lng: 19.561412 },
    { lat: 49.3643228, lng: 19.5608946 },
    { lat: 49.3639719, lng: 19.5610332 },
    { lat: 49.3638462, lng: 19.5610693 },
    { lat: 49.3634433, lng: 19.5610558 },
    { lat: 49.363184, lng: 19.5610007 },
    { lat: 49.3629258, lng: 19.5609235 },
    { lat: 49.3626653, lng: 19.5607424 },
    { lat: 49.3622632, lng: 19.5603186 },
    { lat: 49.361883, lng: 19.5596072 },
    { lat: 49.361818, lng: 19.5594901 },
    { lat: 49.3616312, lng: 19.5586936 },
    { lat: 49.3612095, lng: 19.5571618 },
    { lat: 49.3609851, lng: 19.556378 },
    { lat: 49.360883, lng: 19.5559405 },
    { lat: 49.3607014, lng: 19.5547611 },
    { lat: 49.3606801, lng: 19.5545407 },
    { lat: 49.3606345, lng: 19.553973 },
    { lat: 49.3605704, lng: 19.5531274 },
    { lat: 49.3605202, lng: 19.5524918 },
    { lat: 49.3604061, lng: 19.5507096 },
    { lat: 49.3603451, lng: 19.5499071 },
    { lat: 49.3602921, lng: 19.5491305 },
    { lat: 49.3603282, lng: 19.5488841 },
    { lat: 49.360363, lng: 19.5473583 },
    { lat: 49.3603225, lng: 19.5457136 },
    { lat: 49.3602499, lng: 19.5451047 },
    { lat: 49.3601035, lng: 19.5446535 },
    { lat: 49.3591513, lng: 19.5437813 },
    { lat: 49.3587802, lng: 19.5435448 },
    { lat: 49.3577894, lng: 19.54316 },
    { lat: 49.3571487, lng: 19.5433449 },
    { lat: 49.3570767, lng: 19.5433927 },
    { lat: 49.3570068, lng: 19.543146 },
    { lat: 49.3569624, lng: 19.5429826 },
    { lat: 49.3568242, lng: 19.5425942 },
    { lat: 49.3562102, lng: 19.5426951 },
    { lat: 49.3559507, lng: 19.542763 },
    { lat: 49.3542405, lng: 19.5431863 },
    { lat: 49.3531548, lng: 19.5438687 },
    { lat: 49.3530228, lng: 19.5440402 },
    { lat: 49.3530054, lng: 19.5440525 },
    { lat: 49.3530072, lng: 19.5439671 },
    { lat: 49.3529996, lng: 19.5439227 },
    { lat: 49.3529921, lng: 19.5438834 },
    { lat: 49.3529388, lng: 19.5436277 },
    { lat: 49.3527212, lng: 19.5430233 },
    { lat: 49.3527628, lng: 19.5427215 },
    { lat: 49.3527392, lng: 19.5422263 },
    { lat: 49.3525572, lng: 19.5419391 },
    { lat: 49.3525274, lng: 19.5416304 },
    { lat: 49.3525799, lng: 19.5414698 },
    { lat: 49.3525105, lng: 19.5410514 },
    { lat: 49.3524779, lng: 19.5406797 },
    { lat: 49.3523632, lng: 19.5404877 },
    { lat: 49.3523409, lng: 19.540168 },
    { lat: 49.3522547, lng: 19.5398786 },
    { lat: 49.3520988, lng: 19.5397473 },
    { lat: 49.3520655, lng: 19.5393598 },
    { lat: 49.3519081, lng: 19.5389443 },
    { lat: 49.3518005, lng: 19.5384595 },
    { lat: 49.3518138, lng: 19.5384306 },
    { lat: 49.3522719, lng: 19.5366259 },
    { lat: 49.3533332, lng: 19.5355065 },
    { lat: 49.353207, lng: 19.5347584 },
    { lat: 49.3529953, lng: 19.5338365 },
    { lat: 49.3529239, lng: 19.5334328 },
    { lat: 49.352959, lng: 19.5325382 },
    { lat: 49.3528703, lng: 19.531674 },
    { lat: 49.3525417, lng: 19.5292925 },
    { lat: 49.3527275, lng: 19.5285218 },
    { lat: 49.3526261, lng: 19.5280255 },
    { lat: 49.3524306, lng: 19.5261701 },
    { lat: 49.3523371, lng: 19.5253777 },
    { lat: 49.3523496, lng: 19.5236637 },
    { lat: 49.3522651, lng: 19.5226961 },
    { lat: 49.3521223, lng: 19.5214597 },
    { lat: 49.3520339, lng: 19.520433 },
    { lat: 49.351546, lng: 19.5180721 },
    { lat: 49.3513964, lng: 19.517482 },
    { lat: 49.3510851, lng: 19.5164085 },
    { lat: 49.3508534, lng: 19.5153764 },
    { lat: 49.3506333, lng: 19.5144258 },
    { lat: 49.3505113, lng: 19.5138234 },
    { lat: 49.350472, lng: 19.5132034 },
    { lat: 49.3501417, lng: 19.5125588 },
    { lat: 49.3499, lng: 19.5121241 },
    { lat: 49.3494585, lng: 19.5114581 },
    { lat: 49.3493489, lng: 19.5107564 },
    { lat: 49.3490195, lng: 19.5098529 },
  ],
  ŠtefanovnadOravou: [
    { lat: 49.3570767, lng: 19.5433927 },
    { lat: 49.3571487, lng: 19.5433449 },
    { lat: 49.3577894, lng: 19.54316 },
    { lat: 49.3587802, lng: 19.5435448 },
    { lat: 49.3591513, lng: 19.5437813 },
    { lat: 49.3601035, lng: 19.5446535 },
    { lat: 49.3602499, lng: 19.5451047 },
    { lat: 49.3603225, lng: 19.5457136 },
    { lat: 49.360363, lng: 19.5473583 },
    { lat: 49.3603282, lng: 19.5488841 },
    { lat: 49.3602921, lng: 19.5491305 },
    { lat: 49.3603451, lng: 19.5499071 },
    { lat: 49.3604061, lng: 19.5507096 },
    { lat: 49.3605202, lng: 19.5524918 },
    { lat: 49.3605704, lng: 19.5531274 },
    { lat: 49.3606345, lng: 19.553973 },
    { lat: 49.3606801, lng: 19.5545407 },
    { lat: 49.3607014, lng: 19.5547611 },
    { lat: 49.360883, lng: 19.5559405 },
    { lat: 49.3609851, lng: 19.556378 },
    { lat: 49.3612095, lng: 19.5571618 },
    { lat: 49.3616312, lng: 19.5586936 },
    { lat: 49.361818, lng: 19.5594901 },
    { lat: 49.361883, lng: 19.5596072 },
    { lat: 49.3622632, lng: 19.5603186 },
    { lat: 49.3626653, lng: 19.5607424 },
    { lat: 49.3629258, lng: 19.5609235 },
    { lat: 49.363184, lng: 19.5610007 },
    { lat: 49.3634433, lng: 19.5610558 },
    { lat: 49.3638462, lng: 19.5610693 },
    { lat: 49.3639719, lng: 19.5610332 },
    { lat: 49.3643228, lng: 19.5608946 },
    { lat: 49.3645132, lng: 19.5607971 },
    { lat: 49.3649219, lng: 19.560442 },
    { lat: 49.3653326, lng: 19.560026 },
    { lat: 49.3657346, lng: 19.5596206 },
    { lat: 49.3659023, lng: 19.5594698 },
    { lat: 49.3661657, lng: 19.5592909 },
    { lat: 49.366346, lng: 19.5591666 },
    { lat: 49.3665806, lng: 19.5590201 },
    { lat: 49.3668094, lng: 19.5588745 },
    { lat: 49.3673007, lng: 19.5583226 },
    { lat: 49.3676875, lng: 19.5577796 },
    { lat: 49.3680587, lng: 19.5572686 },
    { lat: 49.3682159, lng: 19.5568789 },
    { lat: 49.3684438, lng: 19.5565349 },
    { lat: 49.3686694, lng: 19.5562923 },
    { lat: 49.3689322, lng: 19.5560253 },
    { lat: 49.3690955, lng: 19.55585 },
    { lat: 49.3693176, lng: 19.5555041 },
    { lat: 49.3696509, lng: 19.5552469 },
    { lat: 49.3701124, lng: 19.5549609 },
    { lat: 49.3705283, lng: 19.5546924 },
    { lat: 49.3713428, lng: 19.5542633 },
    { lat: 49.3721232, lng: 19.5542093 },
    { lat: 49.3725876, lng: 19.554439 },
    { lat: 49.3733125, lng: 19.5549709 },
    { lat: 49.3739013, lng: 19.5556881 },
    { lat: 49.3741529, lng: 19.5560951 },
    { lat: 49.3744474, lng: 19.5565065 },
    { lat: 49.3747777, lng: 19.5568841 },
    { lat: 49.3748814, lng: 19.5569386 },
    { lat: 49.3750026, lng: 19.5570036 },
    { lat: 49.375038, lng: 19.5568346 },
    { lat: 49.3750947, lng: 19.5568723 },
    { lat: 49.3751495, lng: 19.5569103 },
    { lat: 49.3754301, lng: 19.5569595 },
    { lat: 49.375447, lng: 19.5569627 },
    { lat: 49.3754605, lng: 19.5569653 },
    { lat: 49.3754674, lng: 19.5569649 },
    { lat: 49.3754809, lng: 19.5569648 },
    { lat: 49.3755283, lng: 19.5569646 },
    { lat: 49.3755938, lng: 19.5569688 },
    { lat: 49.375622, lng: 19.5569706 },
    { lat: 49.375631, lng: 19.5569715 },
    { lat: 49.3756343, lng: 19.5569715 },
    { lat: 49.3756843, lng: 19.5569796 },
    { lat: 49.375699, lng: 19.556982 },
    { lat: 49.3757059, lng: 19.5569831 },
    { lat: 49.3757629, lng: 19.5570472 },
    { lat: 49.3789974, lng: 19.5607007 },
    { lat: 49.3792458, lng: 19.5603599 },
    { lat: 49.3794667, lng: 19.5598589 },
    { lat: 49.379925, lng: 19.5588067 },
    { lat: 49.3800412, lng: 19.5585615 },
    { lat: 49.3801667, lng: 19.5582916 },
    { lat: 49.3802775, lng: 19.5580535 },
    { lat: 49.3803868, lng: 19.5578271 },
    { lat: 49.3805036, lng: 19.5575696 },
    { lat: 49.3806207, lng: 19.5573161 },
    { lat: 49.3807209, lng: 19.5570675 },
    { lat: 49.3808408, lng: 19.5568249 },
    { lat: 49.380967, lng: 19.5566043 },
    { lat: 49.3809349, lng: 19.5561637 },
    { lat: 49.3806201, lng: 19.5557044 },
    { lat: 49.3799394, lng: 19.5550485 },
    { lat: 49.3793096, lng: 19.5544486 },
    { lat: 49.3798238, lng: 19.5518815 },
    { lat: 49.3800974, lng: 19.5514768 },
    { lat: 49.3804266, lng: 19.5500353 },
    { lat: 49.3805581, lng: 19.5487316 },
    { lat: 49.380691, lng: 19.5473549 },
    { lat: 49.380945, lng: 19.5464909 },
    { lat: 49.3810049, lng: 19.5457462 },
    { lat: 49.3809888, lng: 19.545144 },
    { lat: 49.3809573, lng: 19.5443409 },
    { lat: 49.3812585, lng: 19.5433785 },
    { lat: 49.3816485, lng: 19.5419598 },
    { lat: 49.3814799, lng: 19.5416454 },
    { lat: 49.3814937, lng: 19.5415032 },
    { lat: 49.381282, lng: 19.5415553 },
    { lat: 49.3809873, lng: 19.5415656 },
    { lat: 49.3808759, lng: 19.5410169 },
    { lat: 49.3807391, lng: 19.5408119 },
    { lat: 49.3805781, lng: 19.5408112 },
    { lat: 49.3802575, lng: 19.5403259 },
    { lat: 49.380426, lng: 19.5401157 },
    { lat: 49.3803257, lng: 19.5400381 },
    { lat: 49.3803276, lng: 19.5399554 },
    { lat: 49.3802731, lng: 19.5398979 },
    { lat: 49.3801873, lng: 19.5399689 },
    { lat: 49.3801151, lng: 19.5396666 },
    { lat: 49.3799808, lng: 19.5394382 },
    { lat: 49.3798538, lng: 19.5393432 },
    { lat: 49.3798092, lng: 19.539157 },
    { lat: 49.3796006, lng: 19.5389029 },
    { lat: 49.3793917, lng: 19.5387938 },
    { lat: 49.3794012, lng: 19.5384297 },
    { lat: 49.3794837, lng: 19.538187 },
    { lat: 49.3794115, lng: 19.5381082 },
    { lat: 49.3794316, lng: 19.537853 },
    { lat: 49.3792911, lng: 19.5376592 },
    { lat: 49.3792741, lng: 19.5373884 },
    { lat: 49.3792162, lng: 19.5373761 },
    { lat: 49.3791924, lng: 19.5373714 },
    { lat: 49.3791156, lng: 19.5370928 },
    { lat: 49.3790313, lng: 19.536875 },
    { lat: 49.3789599, lng: 19.5366898 },
    { lat: 49.3788176, lng: 19.5364612 },
    { lat: 49.3786911, lng: 19.5362016 },
    { lat: 49.3786576, lng: 19.53596 },
    { lat: 49.3785385, lng: 19.5358703 },
    { lat: 49.3785229, lng: 19.5356496 },
    { lat: 49.3785235, lng: 19.5356372 },
    { lat: 49.378527, lng: 19.5355624 },
    { lat: 49.3785319, lng: 19.535474 },
    { lat: 49.3784662, lng: 19.5353406 },
    { lat: 49.3783613, lng: 19.5350639 },
    { lat: 49.3783132, lng: 19.5347715 },
    { lat: 49.3782085, lng: 19.5344777 },
    { lat: 49.3782215, lng: 19.534139 },
    { lat: 49.3781652, lng: 19.5339696 },
    { lat: 49.3780377, lng: 19.5335405 },
    { lat: 49.3780491, lng: 19.5333441 },
    { lat: 49.3779407, lng: 19.5329882 },
    { lat: 49.3779106, lng: 19.532897 },
    { lat: 49.3778975, lng: 19.5328311 },
    { lat: 49.3778722, lng: 19.5327206 },
    { lat: 49.3777564, lng: 19.5323023 },
    { lat: 49.3777152, lng: 19.5321381 },
    { lat: 49.3776014, lng: 19.531866 },
    { lat: 49.377451, lng: 19.5316043 },
    { lat: 49.3773749, lng: 19.531488 },
    { lat: 49.3774139, lng: 19.5313602 },
    { lat: 49.3773508, lng: 19.531061 },
    { lat: 49.3773379, lng: 19.5307962 },
    { lat: 49.3773403, lng: 19.5300561 },
    { lat: 49.3773665, lng: 19.5296136 },
    { lat: 49.3775152, lng: 19.5294398 },
    { lat: 49.3775133, lng: 19.5290965 },
    { lat: 49.3774173, lng: 19.5290207 },
    { lat: 49.3774552, lng: 19.5287617 },
    { lat: 49.3775221, lng: 19.5283988 },
    { lat: 49.3776447, lng: 19.5277964 },
    { lat: 49.3777055, lng: 19.5275947 },
    { lat: 49.3776026, lng: 19.5268144 },
    { lat: 49.3773687, lng: 19.5257321 },
    { lat: 49.3768373, lng: 19.5238519 },
    { lat: 49.3766885, lng: 19.523053 },
    { lat: 49.3767355, lng: 19.5219506 },
    { lat: 49.3767151, lng: 19.5208554 },
    { lat: 49.3767578, lng: 19.5199121 },
    { lat: 49.3766961, lng: 19.5187497 },
    { lat: 49.3759938, lng: 19.5166802 },
    { lat: 49.3754036, lng: 19.5157932 },
    { lat: 49.3749316, lng: 19.5152197 },
    { lat: 49.3742497, lng: 19.5142984 },
    { lat: 49.3736301, lng: 19.5127634 },
    { lat: 49.3729165, lng: 19.5117352 },
    { lat: 49.3720862, lng: 19.5107195 },
    { lat: 49.3717411, lng: 19.5102179 },
    { lat: 49.3715812, lng: 19.5096756 },
    { lat: 49.3714958, lng: 19.5090318 },
    { lat: 49.3713938, lng: 19.5085751 },
    { lat: 49.3710486, lng: 19.5076249 },
    { lat: 49.3708368, lng: 19.5064356 },
    { lat: 49.3707032, lng: 19.5050351 },
    { lat: 49.3702096, lng: 19.5024832 },
    { lat: 49.3699798, lng: 19.5010723 },
    { lat: 49.369549, lng: 19.498666 },
    { lat: 49.369296, lng: 19.498363 },
    { lat: 49.368936, lng: 19.497835 },
    { lat: 49.36887, lng: 19.497663 },
    { lat: 49.368866, lng: 19.49765 },
    { lat: 49.368812, lng: 19.497476 },
    { lat: 49.368809, lng: 19.497467 },
    { lat: 49.368769, lng: 19.497315 },
    { lat: 49.368744, lng: 19.497122 },
    { lat: 49.368743, lng: 19.497112 },
    { lat: 49.368717, lng: 19.496669 },
    { lat: 49.3686956, lng: 19.4956315 },
    { lat: 49.368561, lng: 19.494439 },
    { lat: 49.368394, lng: 19.493249 },
    { lat: 49.3682, lng: 19.492158 },
    { lat: 49.367854, lng: 19.491129 },
    { lat: 49.367783, lng: 19.490915 },
    { lat: 49.367757, lng: 19.490836 },
    { lat: 49.367719, lng: 19.490725 },
    { lat: 49.367485, lng: 19.490345 },
    { lat: 49.367278, lng: 19.48998 },
    { lat: 49.367067, lng: 19.489533 },
    { lat: 49.366937, lng: 19.489293 },
    { lat: 49.366918, lng: 19.48908 },
    { lat: 49.366595, lng: 19.488247 },
    { lat: 49.366071, lng: 19.487583 },
    { lat: 49.366017, lng: 19.487485 },
    { lat: 49.365819, lng: 19.487104 },
    { lat: 49.364696, lng: 19.485835 },
    { lat: 49.363206, lng: 19.485297 },
    { lat: 49.362508, lng: 19.485223 },
    { lat: 49.36166, lng: 19.48518 },
    { lat: 49.360659, lng: 19.485181 },
    { lat: 49.36027, lng: 19.485542 },
    { lat: 49.3599169, lng: 19.4866358 },
    { lat: 49.3592574, lng: 19.4871459 },
    { lat: 49.3584946, lng: 19.4884558 },
    { lat: 49.3582332, lng: 19.4893434 },
    { lat: 49.3574748, lng: 19.4897834 },
    { lat: 49.3559811, lng: 19.4902862 },
    { lat: 49.3545881, lng: 19.4910157 },
    { lat: 49.354055, lng: 19.4913329 },
    { lat: 49.3538536, lng: 19.4916111 },
    { lat: 49.353584, lng: 19.4930354 },
    { lat: 49.3532128, lng: 19.4955968 },
    { lat: 49.3527949, lng: 19.4968811 },
    { lat: 49.3523029, lng: 19.497903 },
    { lat: 49.3515559, lng: 19.4990695 },
    { lat: 49.3511016, lng: 19.4998307 },
    { lat: 49.3504466, lng: 19.5012079 },
    { lat: 49.3502698, lng: 19.5014577 },
    { lat: 49.3499178, lng: 19.5019925 },
    { lat: 49.3495468, lng: 19.5026476 },
    { lat: 49.3494472, lng: 19.5037063 },
    { lat: 49.3493669, lng: 19.5046129 },
    { lat: 49.3491375, lng: 19.505835 },
    { lat: 49.3490132, lng: 19.5071659 },
    { lat: 49.3490746, lng: 19.5085153 },
    { lat: 49.3490195, lng: 19.5098529 },
    { lat: 49.3493489, lng: 19.5107564 },
    { lat: 49.3494585, lng: 19.5114581 },
    { lat: 49.3499, lng: 19.5121241 },
    { lat: 49.3501417, lng: 19.5125588 },
    { lat: 49.350472, lng: 19.5132034 },
    { lat: 49.3505113, lng: 19.5138234 },
    { lat: 49.3506333, lng: 19.5144258 },
    { lat: 49.3508534, lng: 19.5153764 },
    { lat: 49.3510851, lng: 19.5164085 },
    { lat: 49.3513964, lng: 19.517482 },
    { lat: 49.351546, lng: 19.5180721 },
    { lat: 49.3520339, lng: 19.520433 },
    { lat: 49.3521223, lng: 19.5214597 },
    { lat: 49.3522651, lng: 19.5226961 },
    { lat: 49.3523496, lng: 19.5236637 },
    { lat: 49.3523371, lng: 19.5253777 },
    { lat: 49.3524306, lng: 19.5261701 },
    { lat: 49.3526261, lng: 19.5280255 },
    { lat: 49.3527275, lng: 19.5285218 },
    { lat: 49.3525417, lng: 19.5292925 },
    { lat: 49.3528703, lng: 19.531674 },
    { lat: 49.352959, lng: 19.5325382 },
    { lat: 49.3529239, lng: 19.5334328 },
    { lat: 49.3529953, lng: 19.5338365 },
    { lat: 49.353207, lng: 19.5347584 },
    { lat: 49.3533332, lng: 19.5355065 },
    { lat: 49.3522719, lng: 19.5366259 },
    { lat: 49.3518138, lng: 19.5384306 },
    { lat: 49.3518005, lng: 19.5384595 },
    { lat: 49.3519081, lng: 19.5389443 },
    { lat: 49.3520655, lng: 19.5393598 },
    { lat: 49.3520988, lng: 19.5397473 },
    { lat: 49.3522547, lng: 19.5398786 },
    { lat: 49.3523409, lng: 19.540168 },
    { lat: 49.3523632, lng: 19.5404877 },
    { lat: 49.3524779, lng: 19.5406797 },
    { lat: 49.3525105, lng: 19.5410514 },
    { lat: 49.3525799, lng: 19.5414698 },
    { lat: 49.3525274, lng: 19.5416304 },
    { lat: 49.3525572, lng: 19.5419391 },
    { lat: 49.3527392, lng: 19.5422263 },
    { lat: 49.3527628, lng: 19.5427215 },
    { lat: 49.3527212, lng: 19.5430233 },
    { lat: 49.3529388, lng: 19.5436277 },
    { lat: 49.3529921, lng: 19.5438834 },
    { lat: 49.3529996, lng: 19.5439227 },
    { lat: 49.3530072, lng: 19.5439671 },
    { lat: 49.3530054, lng: 19.5440525 },
    { lat: 49.3530228, lng: 19.5440402 },
    { lat: 49.3531548, lng: 19.5438687 },
    { lat: 49.3542405, lng: 19.5431863 },
    { lat: 49.3559507, lng: 19.542763 },
    { lat: 49.3562102, lng: 19.5426951 },
    { lat: 49.3568242, lng: 19.5425942 },
    { lat: 49.3569624, lng: 19.5429826 },
    { lat: 49.3570068, lng: 19.543146 },
    { lat: 49.3570767, lng: 19.5433927 },
  ],
  Nižná: [
    { lat: 49.351767, lng: 19.464908 },
    { lat: 49.352, lng: 19.46415 },
    { lat: 49.351669, lng: 19.462086 },
    { lat: 49.351715, lng: 19.460605 },
    { lat: 49.351629, lng: 19.459627 },
    { lat: 49.351338, lng: 19.458408 },
    { lat: 49.351313, lng: 19.458073 },
    { lat: 49.351236, lng: 19.457306 },
    { lat: 49.350958, lng: 19.456685 },
    { lat: 49.350739, lng: 19.456255 },
    { lat: 49.350539, lng: 19.455944 },
    { lat: 49.349811, lng: 19.45522 },
    { lat: 49.349355, lng: 19.454678 },
    { lat: 49.348938, lng: 19.453899 },
    { lat: 49.348619, lng: 19.453037 },
    { lat: 49.348161, lng: 19.452148 },
    { lat: 49.348031, lng: 19.451301 },
    { lat: 49.347993, lng: 19.450848 },
    { lat: 49.347821, lng: 19.449688 },
    { lat: 49.347767, lng: 19.448849 },
    { lat: 49.347736, lng: 19.448118 },
    { lat: 49.347644, lng: 19.447444 },
    { lat: 49.347501, lng: 19.446794 },
    { lat: 49.347077, lng: 19.445984 },
    { lat: 49.346688, lng: 19.44519 },
    { lat: 49.346355, lng: 19.444918 },
    { lat: 49.346256, lng: 19.444881 },
    { lat: 49.345788, lng: 19.444571 },
    { lat: 49.345243, lng: 19.444115 },
    { lat: 49.344892, lng: 19.443553 },
    { lat: 49.344665, lng: 19.443189 },
    { lat: 49.344422, lng: 19.442499 },
    { lat: 49.344165, lng: 19.442099 },
    { lat: 49.344035, lng: 19.441886 },
    { lat: 49.343397, lng: 19.440992 },
    { lat: 49.342991, lng: 19.440633 },
    { lat: 49.342239, lng: 19.43984 },
    { lat: 49.341923, lng: 19.439318 },
    { lat: 49.341399, lng: 19.438686 },
    { lat: 49.34096, lng: 19.438186 },
    { lat: 49.340377, lng: 19.43744 },
    { lat: 49.33979, lng: 19.436942 },
    { lat: 49.33916, lng: 19.436406 },
    { lat: 49.337776, lng: 19.435804 },
    { lat: 49.336918, lng: 19.435286 },
    { lat: 49.336369, lng: 19.434713 },
    { lat: 49.335872, lng: 19.433542 },
    { lat: 49.335371, lng: 19.43555 },
    { lat: 49.335086, lng: 19.436897 },
    { lat: 49.334847, lng: 19.438035 },
    { lat: 49.334709, lng: 19.438679 },
    { lat: 49.334568, lng: 19.439378 },
    { lat: 49.3344874, lng: 19.4397048 },
    { lat: 49.3343838, lng: 19.4402167 },
    { lat: 49.3341451, lng: 19.441325 },
    { lat: 49.3339769, lng: 19.4423215 },
    { lat: 49.3338293, lng: 19.4430792 },
    { lat: 49.3336909, lng: 19.4438434 },
    { lat: 49.3335406, lng: 19.4446386 },
    { lat: 49.3334647, lng: 19.4450468 },
    { lat: 49.3334307, lng: 19.4451866 },
    { lat: 49.3342267, lng: 19.4467422 },
    { lat: 49.3349445, lng: 19.4478705 },
    { lat: 49.3349919, lng: 19.4480641 },
    { lat: 49.3352102, lng: 19.4489182 },
    { lat: 49.3351661, lng: 19.4489855 },
    { lat: 49.3354134, lng: 19.4504412 },
    { lat: 49.3352394, lng: 19.4515342 },
    { lat: 49.3350459, lng: 19.4520554 },
    { lat: 49.3349006, lng: 19.452299 },
    { lat: 49.3346678, lng: 19.4525824 },
    { lat: 49.3344465, lng: 19.4527909 },
    { lat: 49.3341216, lng: 19.4532732 },
    { lat: 49.3338146, lng: 19.4537766 },
    { lat: 49.3336268, lng: 19.4540761 },
    { lat: 49.333362, lng: 19.4545212 },
    { lat: 49.3333381, lng: 19.4545617 },
    { lat: 49.3324925, lng: 19.4550422 },
    { lat: 49.3312996, lng: 19.4558574 },
    { lat: 49.3309128, lng: 19.4560973 },
    { lat: 49.3304366, lng: 19.4561551 },
    { lat: 49.3297043, lng: 19.4559376 },
    { lat: 49.3290776, lng: 19.4558082 },
    { lat: 49.3288639, lng: 19.455868 },
    { lat: 49.3288113, lng: 19.4559015 },
    { lat: 49.3279495, lng: 19.4564393 },
    { lat: 49.3269494, lng: 19.457433 },
    { lat: 49.3261217, lng: 19.4582788 },
    { lat: 49.3257254, lng: 19.4586773 },
    { lat: 49.325191, lng: 19.4595739 },
    { lat: 49.3243689, lng: 19.4611794 },
    { lat: 49.3235954, lng: 19.4628162 },
    { lat: 49.3235013, lng: 19.4627038 },
    { lat: 49.3233296, lng: 19.4630372 },
    { lat: 49.3225597, lng: 19.4644276 },
    { lat: 49.322091, lng: 19.4649926 },
    { lat: 49.3216857, lng: 19.465293 },
    { lat: 49.3213576, lng: 19.465537 },
    { lat: 49.3207388, lng: 19.4656569 },
    { lat: 49.3205736, lng: 19.4659101 },
    { lat: 49.32037, lng: 19.4668971 },
    { lat: 49.3204988, lng: 19.4670075 },
    { lat: 49.320472, lng: 19.4675771 },
    { lat: 49.320274, lng: 19.4684902 },
    { lat: 49.3202969, lng: 19.468846 },
    { lat: 49.320355, lng: 19.4695723 },
    { lat: 49.3204627, lng: 19.4708134 },
    { lat: 49.3204372, lng: 19.471809 },
    { lat: 49.3203265, lng: 19.472613 },
    { lat: 49.3204633, lng: 19.4737531 },
    { lat: 49.3205802, lng: 19.4741866 },
    { lat: 49.320532, lng: 19.4750326 },
    { lat: 49.3203552, lng: 19.4761666 },
    { lat: 49.320477, lng: 19.4765635 },
    { lat: 49.3197054, lng: 19.4774494 },
    { lat: 49.3194479, lng: 19.4780036 },
    { lat: 49.3192404, lng: 19.4790827 },
    { lat: 49.3186673, lng: 19.480129 },
    { lat: 49.3182359, lng: 19.4808918 },
    { lat: 49.3177053, lng: 19.481118 },
    { lat: 49.317158, lng: 19.4813705 },
    { lat: 49.3176255, lng: 19.4826236 },
    { lat: 49.3177986, lng: 19.4830583 },
    { lat: 49.3174419, lng: 19.4831431 },
    { lat: 49.3168917, lng: 19.4832804 },
    { lat: 49.3163906, lng: 19.4834597 },
    { lat: 49.3158183, lng: 19.4837084 },
    { lat: 49.315161, lng: 19.4839455 },
    { lat: 49.3147074, lng: 19.4843 },
    { lat: 49.3140122, lng: 19.4845723 },
    { lat: 49.3136617, lng: 19.4847651 },
    { lat: 49.3132298, lng: 19.4849348 },
    { lat: 49.31294, lng: 19.4850784 },
    { lat: 49.3127138, lng: 19.4851287 },
    { lat: 49.3126577, lng: 19.4851368 },
    { lat: 49.3122894, lng: 19.4851881 },
    { lat: 49.3123357, lng: 19.4858094 },
    { lat: 49.3122615, lng: 19.4870152 },
    { lat: 49.3121611, lng: 19.4875835 },
    { lat: 49.3117324, lng: 19.488855 },
    { lat: 49.3109969, lng: 19.4906851 },
    { lat: 49.3107098, lng: 19.4906634 },
    { lat: 49.3105242, lng: 19.4906475 },
    { lat: 49.3089426, lng: 19.4913412 },
    { lat: 49.3089314, lng: 19.4913456 },
    { lat: 49.3088541, lng: 19.4913799 },
    { lat: 49.308843, lng: 19.491385 },
    { lat: 49.3088275, lng: 19.4913914 },
    { lat: 49.3087905, lng: 19.4912224 },
    { lat: 49.3086802, lng: 19.4912739 },
    { lat: 49.3085854, lng: 19.4911926 },
    { lat: 49.3084876, lng: 19.4911771 },
    { lat: 49.3082641, lng: 19.4911421 },
    { lat: 49.3081249, lng: 19.4912281 },
    { lat: 49.3078016, lng: 19.4915255 },
    { lat: 49.3076362, lng: 19.4916779 },
    { lat: 49.3074012, lng: 19.4921309 },
    { lat: 49.3073832, lng: 19.4921549 },
    { lat: 49.3073353, lng: 19.4922181 },
    { lat: 49.3067537, lng: 19.4929818 },
    { lat: 49.3060505, lng: 19.4931241 },
    { lat: 49.3059957, lng: 19.4931354 },
    { lat: 49.30594, lng: 19.4931464 },
    { lat: 49.3055139, lng: 19.4936125 },
    { lat: 49.3053128, lng: 19.4940229 },
    { lat: 49.3054919, lng: 19.4941919 },
    { lat: 49.3052995, lng: 19.494641 },
    { lat: 49.3051109, lng: 19.4950251 },
    { lat: 49.3048473, lng: 19.4951002 },
    { lat: 49.3047065, lng: 19.495103 },
    { lat: 49.3046995, lng: 19.4951207 },
    { lat: 49.304622, lng: 19.4951041 },
    { lat: 49.3043449, lng: 19.495277 },
    { lat: 49.3042478, lng: 19.4954733 },
    { lat: 49.3041974, lng: 19.4957231 },
    { lat: 49.3041974, lng: 19.4961676 },
    { lat: 49.3041848, lng: 19.4963621 },
    { lat: 49.3041547, lng: 19.4968149 },
    { lat: 49.3038615, lng: 19.4969043 },
    { lat: 49.303365, lng: 19.4970396 },
    { lat: 49.3033329, lng: 19.4972275 },
    { lat: 49.3033102, lng: 19.4973191 },
    { lat: 49.3028148, lng: 19.4973543 },
    { lat: 49.3025492, lng: 19.4973343 },
    { lat: 49.301273, lng: 19.4981095 },
    { lat: 49.3003904, lng: 19.4985932 },
    { lat: 49.2993135, lng: 19.4992677 },
    { lat: 49.2992353, lng: 19.4992818 },
    { lat: 49.2988867, lng: 19.4995176 },
    { lat: 49.2985927, lng: 19.4997168 },
    { lat: 49.2985434, lng: 19.4997491 },
    { lat: 49.2981114, lng: 19.5000353 },
    { lat: 49.2974622, lng: 19.5010154 },
    { lat: 49.2972744, lng: 19.5012989 },
    { lat: 49.2971374, lng: 19.5015049 },
    { lat: 49.2971113, lng: 19.5015532 },
    { lat: 49.2966285, lng: 19.5024552 },
    { lat: 49.296615, lng: 19.5024801 },
    { lat: 49.2966121, lng: 19.5024901 },
    { lat: 49.2963674, lng: 19.5031762 },
    { lat: 49.2961211, lng: 19.5035331 },
    { lat: 49.2959931, lng: 19.5037189 },
    { lat: 49.2954025, lng: 19.5045757 },
    { lat: 49.2949498, lng: 19.5052997 },
    { lat: 49.2944572, lng: 19.5061378 },
    { lat: 49.2941049, lng: 19.5067366 },
    { lat: 49.2933864, lng: 19.5079077 },
    { lat: 49.2926839, lng: 19.5091297 },
    { lat: 49.2918333, lng: 19.5100494 },
    { lat: 49.2910879, lng: 19.5113968 },
    { lat: 49.2912442, lng: 19.5115899 },
    { lat: 49.2908391, lng: 19.5117211 },
    { lat: 49.2906872, lng: 19.5118008 },
    { lat: 49.2903892, lng: 19.5124064 },
    { lat: 49.2902339, lng: 19.5130564 },
    { lat: 49.2902398, lng: 19.5133121 },
    { lat: 49.2901913, lng: 19.5137821 },
    { lat: 49.2900569, lng: 19.5141682 },
    { lat: 49.2897759, lng: 19.5147294 },
    { lat: 49.2891472, lng: 19.5156684 },
    { lat: 49.2884135, lng: 19.5170779 },
    { lat: 49.2884968, lng: 19.5171773 },
    { lat: 49.2882538, lng: 19.5179998 },
    { lat: 49.2882379, lng: 19.5183945 },
    { lat: 49.287829, lng: 19.5197349 },
    { lat: 49.2875262, lng: 19.5204337 },
    { lat: 49.287417, lng: 19.5206846 },
    { lat: 49.287371, lng: 19.5207905 },
    { lat: 49.2872283, lng: 19.5211212 },
    { lat: 49.2870786, lng: 19.5214691 },
    { lat: 49.2868785, lng: 19.5220747 },
    { lat: 49.2867983, lng: 19.5223186 },
    { lat: 49.2866982, lng: 19.5226219 },
    { lat: 49.2863596, lng: 19.5236494 },
    { lat: 49.2857896, lng: 19.525613 },
    { lat: 49.2852778, lng: 19.5272951 },
    { lat: 49.2847296, lng: 19.5289449 },
    { lat: 49.2845315, lng: 19.5295427 },
    { lat: 49.2851985, lng: 19.5300906 },
    { lat: 49.2852408, lng: 19.5301272 },
    { lat: 49.2858722, lng: 19.530759 },
    { lat: 49.2858907, lng: 19.5307708 },
    { lat: 49.2859384, lng: 19.5308022 },
    { lat: 49.2859698, lng: 19.530823 },
    { lat: 49.2864202, lng: 19.5311182 },
    { lat: 49.2865271, lng: 19.5312119 },
    { lat: 49.2869502, lng: 19.5315756 },
    { lat: 49.2871234, lng: 19.5317395 },
    { lat: 49.2872505, lng: 19.5318629 },
    { lat: 49.2876373, lng: 19.5322377 },
    { lat: 49.2883036, lng: 19.5333954 },
    { lat: 49.2886008, lng: 19.534206 },
    { lat: 49.2883369, lng: 19.5345508 },
    { lat: 49.288105, lng: 19.5349529 },
    { lat: 49.2878799, lng: 19.5353558 },
    { lat: 49.2876157, lng: 19.536564 },
    { lat: 49.2874549, lng: 19.5380334 },
    { lat: 49.2865583, lng: 19.5394338 },
    { lat: 49.2864211, lng: 19.5397674 },
    { lat: 49.2863445, lng: 19.5396658 },
    { lat: 49.2863595, lng: 19.5398988 },
    { lat: 49.2866106, lng: 19.5402649 },
    { lat: 49.2867452, lng: 19.5404012 },
    { lat: 49.2870491, lng: 19.5409718 },
    { lat: 49.2872155, lng: 19.5411105 },
    { lat: 49.2873277, lng: 19.5411481 },
    { lat: 49.2875189, lng: 19.5412668 },
    { lat: 49.2878641, lng: 19.5415497 },
    { lat: 49.2880726, lng: 19.5420983 },
    { lat: 49.2881074, lng: 19.5422935 },
    { lat: 49.2886091, lng: 19.5428251 },
    { lat: 49.2891449, lng: 19.5431619 },
    { lat: 49.2892671, lng: 19.5432475 },
    { lat: 49.2901051, lng: 19.5441905 },
    { lat: 49.2902472, lng: 19.5443408 },
    { lat: 49.290791, lng: 19.544906 },
    { lat: 49.2912593, lng: 19.545347 },
    { lat: 49.2916966, lng: 19.5457711 },
    { lat: 49.2918096, lng: 19.5462811 },
    { lat: 49.2914268, lng: 19.5475062 },
    { lat: 49.2911502, lng: 19.548365 },
    { lat: 49.2910393, lng: 19.5488311 },
    { lat: 49.2912309, lng: 19.549905 },
    { lat: 49.2913244, lng: 19.5502506 },
    { lat: 49.2919589, lng: 19.5516794 },
    { lat: 49.2928389, lng: 19.5534495 },
    { lat: 49.2932578, lng: 19.5543189 },
    { lat: 49.2935608, lng: 19.5541155 },
    { lat: 49.2938069, lng: 19.553957 },
    { lat: 49.2938832, lng: 19.5538204 },
    { lat: 49.2940972, lng: 19.5536726 },
    { lat: 49.2942099, lng: 19.5535954 },
    { lat: 49.294506, lng: 19.5533919 },
    { lat: 49.2945497, lng: 19.5533311 },
    { lat: 49.2946586, lng: 19.5531794 },
    { lat: 49.2949097, lng: 19.5526291 },
    { lat: 49.2950938, lng: 19.5524386 },
    { lat: 49.2953481, lng: 19.5521305 },
    { lat: 49.2953826, lng: 19.5520698 },
    { lat: 49.2954162, lng: 19.5518923 },
    { lat: 49.2954356, lng: 19.5517744 },
    { lat: 49.2956329, lng: 19.5513283 },
    { lat: 49.2957147, lng: 19.5511948 },
    { lat: 49.2959236, lng: 19.5508529 },
    { lat: 49.2959919, lng: 19.5507456 },
    { lat: 49.29602, lng: 19.5507181 },
    { lat: 49.2965143, lng: 19.550236 },
    { lat: 49.2966351, lng: 19.5500947 },
    { lat: 49.2967886, lng: 19.5500478 },
    { lat: 49.2972602, lng: 19.5500402 },
    { lat: 49.2973254, lng: 19.5500362 },
    { lat: 49.29763, lng: 19.5501192 },
    { lat: 49.2978289, lng: 19.5502058 },
    { lat: 49.2983721, lng: 19.5509304 },
    { lat: 49.2985853, lng: 19.5511414 },
    { lat: 49.2987441, lng: 19.5511134 },
    { lat: 49.2988885, lng: 19.5511159 },
    { lat: 49.2991695, lng: 19.5511534 },
    { lat: 49.299301, lng: 19.5512427 },
    { lat: 49.2995082, lng: 19.5513912 },
    { lat: 49.29977, lng: 19.5514491 },
    { lat: 49.3000622, lng: 19.551384 },
    { lat: 49.3002728, lng: 19.5513325 },
    { lat: 49.3006126, lng: 19.551249 },
    { lat: 49.3006513, lng: 19.551234 },
    { lat: 49.3010298, lng: 19.5510052 },
    { lat: 49.3012993, lng: 19.5507612 },
    { lat: 49.3013501, lng: 19.5507158 },
    { lat: 49.3015025, lng: 19.5504921 },
    { lat: 49.301684, lng: 19.5502249 },
    { lat: 49.3019058, lng: 19.5498466 },
    { lat: 49.3019408, lng: 19.5497223 },
    { lat: 49.3020578, lng: 19.5493175 },
    { lat: 49.3020462, lng: 19.5491855 },
    { lat: 49.3021009, lng: 19.5487512 },
    { lat: 49.3022572, lng: 19.5480461 },
    { lat: 49.3022782, lng: 19.5479147 },
    { lat: 49.3023403, lng: 19.5475185 },
    { lat: 49.3023974, lng: 19.5470876 },
    { lat: 49.3024564, lng: 19.546868 },
    { lat: 49.3026307, lng: 19.5462163 },
    { lat: 49.3026452, lng: 19.546162 },
    { lat: 49.3032027, lng: 19.5456102 },
    { lat: 49.3035954, lng: 19.5450488 },
    { lat: 49.3037476, lng: 19.5447525 },
    { lat: 49.3041953, lng: 19.5441643 },
    { lat: 49.3046502, lng: 19.5439086 },
    { lat: 49.3047317, lng: 19.5438404 },
    { lat: 49.305081, lng: 19.5435464 },
    { lat: 49.3051517, lng: 19.5434862 },
    { lat: 49.3052993, lng: 19.5433626 },
    { lat: 49.3055393, lng: 19.5431921 },
    { lat: 49.3057112, lng: 19.543058 },
    { lat: 49.3058278, lng: 19.5429164 },
    { lat: 49.3063057, lng: 19.5424726 },
    { lat: 49.3063885, lng: 19.5423866 },
    { lat: 49.3067276, lng: 19.542211 },
    { lat: 49.3068283, lng: 19.5420949 },
    { lat: 49.3070195, lng: 19.5419891 },
    { lat: 49.3071494, lng: 19.5418952 },
    { lat: 49.3073351, lng: 19.5416437 },
    { lat: 49.3076016, lng: 19.5413346 },
    { lat: 49.3079765, lng: 19.5409996 },
    { lat: 49.3081726, lng: 19.540804 },
    { lat: 49.3083469, lng: 19.5405975 },
    { lat: 49.3083838, lng: 19.5404675 },
    { lat: 49.308454, lng: 19.540376 },
    { lat: 49.3087042, lng: 19.5402025 },
    { lat: 49.3087798, lng: 19.5401303 },
    { lat: 49.3090142, lng: 19.539905 },
    { lat: 49.3090691, lng: 19.5398501 },
    { lat: 49.3091879, lng: 19.5396371 },
    { lat: 49.3093432, lng: 19.539486 },
    { lat: 49.3094837, lng: 19.5394487 },
    { lat: 49.3096752, lng: 19.5393765 },
    { lat: 49.3097975, lng: 19.5393411 },
    { lat: 49.3100029, lng: 19.5391219 },
    { lat: 49.3101203, lng: 19.5393257 },
    { lat: 49.3104788, lng: 19.5397298 },
    { lat: 49.3105955, lng: 19.5398676 },
    { lat: 49.3113619, lng: 19.5404466 },
    { lat: 49.3118677, lng: 19.5408202 },
    { lat: 49.3120245, lng: 19.540899 },
    { lat: 49.3122508, lng: 19.540996 },
    { lat: 49.3125767, lng: 19.5411273 },
    { lat: 49.3128541, lng: 19.5411802 },
    { lat: 49.3130476, lng: 19.5411717 },
    { lat: 49.3134619, lng: 19.5411401 },
    { lat: 49.3137023, lng: 19.541054 },
    { lat: 49.313987, lng: 19.5408696 },
    { lat: 49.314118, lng: 19.5408016 },
    { lat: 49.3142205, lng: 19.5406301 },
    { lat: 49.3144218, lng: 19.5402942 },
    { lat: 49.3145202, lng: 19.5401303 },
    { lat: 49.3147049, lng: 19.5397778 },
    { lat: 49.3148282, lng: 19.539517 },
    { lat: 49.3151544, lng: 19.5387082 },
    { lat: 49.3154043, lng: 19.5382036 },
    { lat: 49.3156419, lng: 19.5377306 },
    { lat: 49.3158433, lng: 19.5373777 },
    { lat: 49.3160209, lng: 19.5371906 },
    { lat: 49.3163422, lng: 19.5368243 },
    { lat: 49.3166556, lng: 19.5364513 },
    { lat: 49.3167999, lng: 19.5362536 },
    { lat: 49.3168375, lng: 19.5362135 },
    { lat: 49.3169558, lng: 19.536089 },
    { lat: 49.3171625, lng: 19.5359046 },
    { lat: 49.3173043, lng: 19.5357502 },
    { lat: 49.3174986, lng: 19.5355627 },
    { lat: 49.3175757, lng: 19.5354971 },
    { lat: 49.3175833, lng: 19.5354942 },
    { lat: 49.3178495, lng: 19.5353981 },
    { lat: 49.3177677, lng: 19.5347855 },
    { lat: 49.3177688, lng: 19.5347356 },
    { lat: 49.3177733, lng: 19.5345383 },
    { lat: 49.317772, lng: 19.5344835 },
    { lat: 49.3177751, lng: 19.5343795 },
    { lat: 49.3177765, lng: 19.5343328 },
    { lat: 49.3177767, lng: 19.5342902 },
    { lat: 49.3178521, lng: 19.5340153 },
    { lat: 49.3178726, lng: 19.5339412 },
    { lat: 49.3179495, lng: 19.5336543 },
    { lat: 49.3181798, lng: 19.5332907 },
    { lat: 49.3185208, lng: 19.5331065 },
    { lat: 49.3191718, lng: 19.5330758 },
    { lat: 49.3198912, lng: 19.5334152 },
    { lat: 49.3197839, lng: 19.5340806 },
    { lat: 49.3197726, lng: 19.534153 },
    { lat: 49.3197182, lng: 19.5344687 },
    { lat: 49.3197098, lng: 19.5345335 },
    { lat: 49.3197075, lng: 19.5345828 },
    { lat: 49.3197054, lng: 19.5346347 },
    { lat: 49.3197043, lng: 19.5347565 },
    { lat: 49.3199303, lng: 19.5349731 },
    { lat: 49.3201537, lng: 19.535229 },
    { lat: 49.3203518, lng: 19.5355201 },
    { lat: 49.3203681, lng: 19.5355588 },
    { lat: 49.3203949, lng: 19.5355292 },
    { lat: 49.3203707, lng: 19.535491 },
    { lat: 49.3203781, lng: 19.5354808 },
    { lat: 49.3204036, lng: 19.5355239 },
    { lat: 49.3204195, lng: 19.5355504 },
    { lat: 49.3204627, lng: 19.5355087 },
    { lat: 49.3205147, lng: 19.5354641 },
    { lat: 49.3205444, lng: 19.5354473 },
    { lat: 49.3205583, lng: 19.5354306 },
    { lat: 49.3206291, lng: 19.5352779 },
    { lat: 49.3206331, lng: 19.5352688 },
    { lat: 49.3206733, lng: 19.5351815 },
    { lat: 49.3208724, lng: 19.5350524 },
    { lat: 49.3209309, lng: 19.5350245 },
    { lat: 49.3210204, lng: 19.5349826 },
    { lat: 49.3210423, lng: 19.5349719 },
    { lat: 49.3212032, lng: 19.5347667 },
    { lat: 49.3212894, lng: 19.5345578 },
    { lat: 49.3212973, lng: 19.5343576 },
    { lat: 49.3212347, lng: 19.5341212 },
    { lat: 49.3211483, lng: 19.5337785 },
    { lat: 49.3211671, lng: 19.5337708 },
    { lat: 49.3212035, lng: 19.5337561 },
    { lat: 49.3212962, lng: 19.5336859 },
    { lat: 49.3213235, lng: 19.5336697 },
    { lat: 49.3213806, lng: 19.5335852 },
    { lat: 49.3213965, lng: 19.5335655 },
    { lat: 49.3214241, lng: 19.5335496 },
    { lat: 49.3214445, lng: 19.5335319 },
    { lat: 49.321468, lng: 19.5335016 },
    { lat: 49.3214788, lng: 19.53349 },
    { lat: 49.3214818, lng: 19.5334571 },
    { lat: 49.3215096, lng: 19.5333993 },
    { lat: 49.321523, lng: 19.5332978 },
    { lat: 49.321575, lng: 19.533254 },
    { lat: 49.3215883, lng: 19.5331721 },
    { lat: 49.3215554, lng: 19.5331174 },
    { lat: 49.3215477, lng: 19.533094 },
    { lat: 49.3215464, lng: 19.5330432 },
    { lat: 49.3215542, lng: 19.5330131 },
    { lat: 49.3215668, lng: 19.5329941 },
    { lat: 49.321578, lng: 19.5329915 },
    { lat: 49.3215882, lng: 19.5329716 },
    { lat: 49.3215867, lng: 19.5329642 },
    { lat: 49.3215705, lng: 19.5329291 },
    { lat: 49.3215688, lng: 19.5329137 },
    { lat: 49.3215832, lng: 19.5328609 },
    { lat: 49.3215821, lng: 19.5328361 },
    { lat: 49.3215777, lng: 19.532812 },
    { lat: 49.3215747, lng: 19.5327451 },
    { lat: 49.3215737, lng: 19.5326983 },
    { lat: 49.3215733, lng: 19.5326897 },
    { lat: 49.3215734, lng: 19.5326698 },
    { lat: 49.3215603, lng: 19.5326267 },
    { lat: 49.3215538, lng: 19.5326086 },
    { lat: 49.3215515, lng: 19.5325583 },
    { lat: 49.3215567, lng: 19.5324736 },
    { lat: 49.3215611, lng: 19.532419 },
    { lat: 49.3215617, lng: 19.5324135 },
    { lat: 49.3215688, lng: 19.5323658 },
    { lat: 49.3215706, lng: 19.5323584 },
    { lat: 49.3215757, lng: 19.5323452 },
    { lat: 49.3216007, lng: 19.5322992 },
    { lat: 49.3216141, lng: 19.5322501 },
    { lat: 49.3216347, lng: 19.5321782 },
    { lat: 49.3216336, lng: 19.5321332 },
    { lat: 49.3216584, lng: 19.5320085 },
    { lat: 49.3216902, lng: 19.531965 },
    { lat: 49.3217188, lng: 19.5319508 },
    { lat: 49.3217359, lng: 19.5319533 },
    { lat: 49.3217572, lng: 19.5319496 },
    { lat: 49.3217732, lng: 19.5319313 },
    { lat: 49.3217913, lng: 19.5318824 },
    { lat: 49.3218037, lng: 19.5318085 },
    { lat: 49.3218101, lng: 19.5317973 },
    { lat: 49.3218654, lng: 19.5317005 },
    { lat: 49.3219031, lng: 19.5316405 },
    { lat: 49.3219574, lng: 19.5315686 },
    { lat: 49.3219953, lng: 19.5315613 },
    { lat: 49.3220289, lng: 19.5315567 },
    { lat: 49.3221691, lng: 19.5314421 },
    { lat: 49.322167, lng: 19.5313958 },
    { lat: 49.322194, lng: 19.5313192 },
    { lat: 49.3222513, lng: 19.5312911 },
    { lat: 49.3222738, lng: 19.5312154 },
    { lat: 49.3222641, lng: 19.5311265 },
    { lat: 49.3222892, lng: 19.5310592 },
    { lat: 49.3223243, lng: 19.5309648 },
    { lat: 49.3223392, lng: 19.5308718 },
    { lat: 49.3223569, lng: 19.5308403 },
    { lat: 49.3223747, lng: 19.5308096 },
    { lat: 49.322469, lng: 19.5307035 },
    { lat: 49.3224292, lng: 19.5306698 },
    { lat: 49.3224766, lng: 19.5305954 },
    { lat: 49.3225971, lng: 19.530346 },
    { lat: 49.3226655, lng: 19.5302868 },
    { lat: 49.322706, lng: 19.5301867 },
    { lat: 49.3228181, lng: 19.5300026 },
    { lat: 49.3228873, lng: 19.5297908 },
    { lat: 49.3229537, lng: 19.5296613 },
    { lat: 49.322989, lng: 19.5296229 },
    { lat: 49.3230029, lng: 19.5296069 },
    { lat: 49.3229855, lng: 19.5294932 },
    { lat: 49.3230388, lng: 19.5294504 },
    { lat: 49.323063, lng: 19.5293889 },
    { lat: 49.3230838, lng: 19.5291248 },
    { lat: 49.3231069, lng: 19.5290668 },
    { lat: 49.3231098, lng: 19.5289278 },
    { lat: 49.3230986, lng: 19.5289037 },
    { lat: 49.3231736, lng: 19.5286999 },
    { lat: 49.3232154, lng: 19.5284495 },
    { lat: 49.3232222, lng: 19.5284015 },
    { lat: 49.3233263, lng: 19.5281163 },
    { lat: 49.3234249, lng: 19.5278064 },
    { lat: 49.3234498, lng: 19.5276062 },
    { lat: 49.323453, lng: 19.5275773 },
    { lat: 49.3234515, lng: 19.5274456 },
    { lat: 49.3234626, lng: 19.5273675 },
    { lat: 49.3235164, lng: 19.5270121 },
    { lat: 49.3235061, lng: 19.5265621 },
    { lat: 49.3235906, lng: 19.525791 },
    { lat: 49.323661, lng: 19.5258066 },
    { lat: 49.3236977, lng: 19.5258197 },
    { lat: 49.3238064, lng: 19.52578 },
    { lat: 49.3238961, lng: 19.5257477 },
    { lat: 49.3239249, lng: 19.5257374 },
    { lat: 49.3240003, lng: 19.5257099 },
    { lat: 49.3242719, lng: 19.5256114 },
    { lat: 49.3243381, lng: 19.5255823 },
    { lat: 49.3243802, lng: 19.5255627 },
    { lat: 49.3244471, lng: 19.5254981 },
    { lat: 49.3245389, lng: 19.5254133 },
    { lat: 49.3245739, lng: 19.5253879 },
    { lat: 49.3246064, lng: 19.5253873 },
    { lat: 49.3246566, lng: 19.5254008 },
    { lat: 49.324751, lng: 19.5254203 },
    { lat: 49.3248312, lng: 19.5254505 },
    { lat: 49.3249851, lng: 19.5255382 },
    { lat: 49.3250195, lng: 19.5255515 },
    { lat: 49.325047, lng: 19.5255587 },
    { lat: 49.3250728, lng: 19.5255556 },
    { lat: 49.3250936, lng: 19.5255458 },
    { lat: 49.3251328, lng: 19.5255145 },
    { lat: 49.3252234, lng: 19.5254244 },
    { lat: 49.3252654, lng: 19.5253841 },
    { lat: 49.3253634, lng: 19.5253051 },
    { lat: 49.3253956, lng: 19.5252699 },
    { lat: 49.3254683, lng: 19.5251867 },
    { lat: 49.3254986, lng: 19.5251567 },
    { lat: 49.325529, lng: 19.5251347 },
    { lat: 49.3255335, lng: 19.5251317 },
    { lat: 49.3255601, lng: 19.5251249 },
    { lat: 49.3256018, lng: 19.525123 },
    { lat: 49.3256368, lng: 19.5251226 },
    { lat: 49.3256713, lng: 19.5251146 },
    { lat: 49.3257105, lng: 19.5250887 },
    { lat: 49.3257492, lng: 19.5250467 },
    { lat: 49.3257991, lng: 19.5249547 },
    { lat: 49.3258628, lng: 19.5247644 },
    { lat: 49.3258988, lng: 19.5246689 },
    { lat: 49.3259152, lng: 19.5246299 },
    { lat: 49.3259426, lng: 19.5245147 },
    { lat: 49.3260083, lng: 19.5243979 },
    { lat: 49.326093, lng: 19.5242549 },
    { lat: 49.3261737, lng: 19.5241195 },
    { lat: 49.3262573, lng: 19.5240525 },
    { lat: 49.3264735, lng: 19.5238738 },
    { lat: 49.3265743, lng: 19.5237648 },
    { lat: 49.3267227, lng: 19.5236779 },
    { lat: 49.3267814, lng: 19.5237345 },
    { lat: 49.3268454, lng: 19.5236772 },
    { lat: 49.3269107, lng: 19.5236536 },
    { lat: 49.326972, lng: 19.5235907 },
    { lat: 49.3270571, lng: 19.5236023 },
    { lat: 49.327166, lng: 19.523594 },
    { lat: 49.3271899, lng: 19.5235474 },
    { lat: 49.3274711, lng: 19.5235374 },
    { lat: 49.3275387, lng: 19.5235389 },
    { lat: 49.3276003, lng: 19.5235283 },
    { lat: 49.3276846, lng: 19.5235504 },
    { lat: 49.3278843, lng: 19.5236318 },
    { lat: 49.328123, lng: 19.523777 },
    { lat: 49.3285314, lng: 19.523812 },
    { lat: 49.3289149, lng: 19.5238467 },
    { lat: 49.3289702, lng: 19.5238474 },
    { lat: 49.3290141, lng: 19.5238991 },
    { lat: 49.3291223, lng: 19.5246711 },
    { lat: 49.3293493, lng: 19.5256715 },
    { lat: 49.3298654, lng: 19.5257232 },
    { lat: 49.3299435, lng: 19.5257587 },
    { lat: 49.3302351, lng: 19.5258736 },
    { lat: 49.3309448, lng: 19.5260472 },
    { lat: 49.3315382, lng: 19.5261626 },
    { lat: 49.3319373, lng: 19.5262218 },
    { lat: 49.3324425, lng: 19.5263567 },
    { lat: 49.3325187, lng: 19.5259501 },
    { lat: 49.332708, lng: 19.5251518 },
    { lat: 49.3330359, lng: 19.5245047 },
    { lat: 49.3333291, lng: 19.5238688 },
    { lat: 49.3334697, lng: 19.5234669 },
    { lat: 49.3335739, lng: 19.5231563 },
    { lat: 49.3335079, lng: 19.5230654 },
    { lat: 49.3334358, lng: 19.5215745 },
    { lat: 49.3333362, lng: 19.5206707 },
    { lat: 49.333108, lng: 19.5196639 },
    { lat: 49.3329054, lng: 19.5185308 },
    { lat: 49.3329022, lng: 19.5185055 },
    { lat: 49.333154, lng: 19.5183743 },
    { lat: 49.3333655, lng: 19.5182648 },
    { lat: 49.3334821, lng: 19.5182061 },
    { lat: 49.333531, lng: 19.5181641 },
    { lat: 49.3337346, lng: 19.5179592 },
    { lat: 49.3338608, lng: 19.5178577 },
    { lat: 49.3340182, lng: 19.517752 },
    { lat: 49.3340368, lng: 19.51774 },
    { lat: 49.3341885, lng: 19.5175092 },
    { lat: 49.3344523, lng: 19.5171155 },
    { lat: 49.3344958, lng: 19.5170546 },
    { lat: 49.3347212, lng: 19.5169067 },
    { lat: 49.3347674, lng: 19.5168848 },
    { lat: 49.3350205, lng: 19.5166974 },
    { lat: 49.3355158, lng: 19.516317 },
    { lat: 49.3362022, lng: 19.5159037 },
    { lat: 49.3364649, lng: 19.5156102 },
    { lat: 49.3366836, lng: 19.5153636 },
    { lat: 49.3367532, lng: 19.5153107 },
    { lat: 49.3372329, lng: 19.5149814 },
    { lat: 49.3372438, lng: 19.5149727 },
    { lat: 49.3373465, lng: 19.5149003 },
    { lat: 49.3373474, lng: 19.5148991 },
    { lat: 49.3376447, lng: 19.514697 },
    { lat: 49.3378512, lng: 19.5146266 },
    { lat: 49.3379267, lng: 19.5145997 },
    { lat: 49.3381383, lng: 19.5145237 },
    { lat: 49.338152, lng: 19.513808 },
    { lat: 49.3382534, lng: 19.5135873 },
    { lat: 49.3383108, lng: 19.5135349 },
    { lat: 49.33858, lng: 19.5132866 },
    { lat: 49.3385963, lng: 19.5132718 },
    { lat: 49.3389992, lng: 19.5129153 },
    { lat: 49.3393043, lng: 19.5126631 },
    { lat: 49.3391558, lng: 19.5120972 },
    { lat: 49.3391368, lng: 19.5120273 },
    { lat: 49.3391235, lng: 19.5119829 },
    { lat: 49.3391353, lng: 19.5119682 },
    { lat: 49.3393055, lng: 19.5117492 },
    { lat: 49.3394141, lng: 19.5116094 },
    { lat: 49.3394898, lng: 19.5115121 },
    { lat: 49.3393846, lng: 19.5111558 },
    { lat: 49.3394468, lng: 19.5110863 },
    { lat: 49.3395508, lng: 19.5109694 },
    { lat: 49.3397534, lng: 19.5107426 },
    { lat: 49.3399827, lng: 19.5104826 },
    { lat: 49.3400344, lng: 19.5104315 },
    { lat: 49.340164, lng: 19.5103061 },
    { lat: 49.340207, lng: 19.5102654 },
    { lat: 49.3402513, lng: 19.510222 },
    { lat: 49.3403841, lng: 19.5100937 },
    { lat: 49.3403927, lng: 19.5100855 },
    { lat: 49.340658, lng: 19.5098253 },
    { lat: 49.3407132, lng: 19.5097721 },
    { lat: 49.3407573, lng: 19.5097294 },
    { lat: 49.3408552, lng: 19.50965 },
    { lat: 49.3411405, lng: 19.5094349 },
    { lat: 49.3411798, lng: 19.5094058 },
    { lat: 49.3414521, lng: 19.5091994 },
    { lat: 49.3417331, lng: 19.5089866 },
    { lat: 49.3418855, lng: 19.5088706 },
    { lat: 49.3418898, lng: 19.5088673 },
    { lat: 49.3419161, lng: 19.5088471 },
    { lat: 49.3419081, lng: 19.5087956 },
    { lat: 49.3418673, lng: 19.5084447 },
    { lat: 49.3418062, lng: 19.5078959 },
    { lat: 49.3417934, lng: 19.5077852 },
    { lat: 49.3417452, lng: 19.5073698 },
    { lat: 49.3416851, lng: 19.5068165 },
    { lat: 49.3416099, lng: 19.506229 },
    { lat: 49.3415626, lng: 19.505859 },
    { lat: 49.3414807, lng: 19.5052202 },
    { lat: 49.3414407, lng: 19.504913 },
    { lat: 49.3414151, lng: 19.5047227 },
    { lat: 49.3414942, lng: 19.5045531 },
    { lat: 49.3415301, lng: 19.5044745 },
    { lat: 49.341625, lng: 19.5042624 },
    { lat: 49.3418111, lng: 19.5038429 },
    { lat: 49.341896, lng: 19.5036543 },
    { lat: 49.3420096, lng: 19.5034004 },
    { lat: 49.3422173, lng: 19.5029642 },
    { lat: 49.3424642, lng: 19.5024504 },
    { lat: 49.3425003, lng: 19.5023515 },
    { lat: 49.3426966, lng: 19.5017871 },
    { lat: 49.3428746, lng: 19.5012701 },
    { lat: 49.3429408, lng: 19.5006903 },
    { lat: 49.343009, lng: 19.500115 },
    { lat: 49.3430072, lng: 19.4997755 },
    { lat: 49.3430086, lng: 19.4993136 },
    { lat: 49.3430459, lng: 19.4990187 },
    { lat: 49.343096, lng: 19.4986104 },
    { lat: 49.3432568, lng: 19.4981586 },
    { lat: 49.3434006, lng: 19.4977298 },
    { lat: 49.3435582, lng: 19.4973538 },
    { lat: 49.3438017, lng: 19.4967652 },
    { lat: 49.3439151, lng: 19.4965077 },
    { lat: 49.3440724, lng: 19.4961057 },
    { lat: 49.3441243, lng: 19.4959812 },
    { lat: 49.3442515, lng: 19.4956327 },
    { lat: 49.3444209, lng: 19.495173 },
    { lat: 49.3446417, lng: 19.4949991 },
    { lat: 49.3448997, lng: 19.4947999 },
    { lat: 49.3449811, lng: 19.4948049 },
    { lat: 49.3452219, lng: 19.4948247 },
    { lat: 49.3453934, lng: 19.4949047 },
    { lat: 49.3457075, lng: 19.495046 },
    { lat: 49.3457087, lng: 19.4950466 },
    { lat: 49.3459206, lng: 19.4951743 },
    { lat: 49.3461597, lng: 19.4953276 },
    { lat: 49.3462932, lng: 19.4953397 },
    { lat: 49.3465889, lng: 19.4953739 },
    { lat: 49.3465956, lng: 19.4953743 },
    { lat: 49.3468081, lng: 19.4954376 },
    { lat: 49.3470981, lng: 19.4955222 },
    { lat: 49.347276, lng: 19.4954682 },
    { lat: 49.3476177, lng: 19.4953547 },
    { lat: 49.3476602, lng: 19.4953711 },
    { lat: 49.3478257, lng: 19.4954167 },
    { lat: 49.3478682, lng: 19.4954121 },
    { lat: 49.3478886, lng: 19.4952383 },
    { lat: 49.3478983, lng: 19.4951549 },
    { lat: 49.3479302, lng: 19.494892 },
    { lat: 49.3480135, lng: 19.4944451 },
    { lat: 49.3479888, lng: 19.494297 },
    { lat: 49.3479582, lng: 19.4940723 },
    { lat: 49.3479325, lng: 19.493907 },
    { lat: 49.3479152, lng: 19.4938481 },
    { lat: 49.3477796, lng: 19.4933924 },
    { lat: 49.3476332, lng: 19.4929245 },
    { lat: 49.3474233, lng: 19.4925986 },
    { lat: 49.347391, lng: 19.4925533 },
    { lat: 49.3473229, lng: 19.4924948 },
    { lat: 49.3472359, lng: 19.492172 },
    { lat: 49.3471907, lng: 19.4920651 },
    { lat: 49.3470624, lng: 19.4917261 },
    { lat: 49.3470505, lng: 19.4916891 },
    { lat: 49.3470306, lng: 19.4915478 },
    { lat: 49.3468716, lng: 19.4913696 },
    { lat: 49.3468303, lng: 19.4911326 },
    { lat: 49.3468164, lng: 19.4910752 },
    { lat: 49.3467065, lng: 19.4909637 },
    { lat: 49.3466084, lng: 19.4908629 },
    { lat: 49.3465693, lng: 19.4905975 },
    { lat: 49.3466725, lng: 19.4904889 },
    { lat: 49.3466713, lng: 19.4900684 },
    { lat: 49.3466374, lng: 19.4898447 },
    { lat: 49.3466632, lng: 19.4896699 },
    { lat: 49.3466808, lng: 19.4895383 },
    { lat: 49.3466526, lng: 19.4892399 },
    { lat: 49.3466382, lng: 19.4891681 },
    { lat: 49.3464585, lng: 19.4887652 },
    { lat: 49.3463918, lng: 19.4886116 },
    { lat: 49.3463039, lng: 19.4884855 },
    { lat: 49.3461264, lng: 19.4882294 },
    { lat: 49.3459833, lng: 19.4880778 },
    { lat: 49.3458305, lng: 19.4879167 },
    { lat: 49.3457642, lng: 19.4876954 },
    { lat: 49.3456654, lng: 19.4876052 },
    { lat: 49.3456557, lng: 19.4875888 },
    { lat: 49.3456363, lng: 19.4873878 },
    { lat: 49.3455696, lng: 19.4871626 },
    { lat: 49.3455099, lng: 19.4871379 },
    { lat: 49.3453938, lng: 19.4871377 },
    { lat: 49.3452432, lng: 19.4867979 },
    { lat: 49.3450633, lng: 19.4864369 },
    { lat: 49.3448674, lng: 19.4860973 },
    { lat: 49.3448071, lng: 19.4859866 },
    { lat: 49.3447803, lng: 19.4858638 },
    { lat: 49.3447188, lng: 19.4858255 },
    { lat: 49.344628, lng: 19.4858396 },
    { lat: 49.344614, lng: 19.4858273 },
    { lat: 49.3445706, lng: 19.4857659 },
    { lat: 49.344577, lng: 19.4857294 },
    { lat: 49.3445902, lng: 19.4856514 },
    { lat: 49.3445168, lng: 19.4853792 },
    { lat: 49.3444024, lng: 19.4852877 },
    { lat: 49.344317, lng: 19.485222 },
    { lat: 49.3443314, lng: 19.4851909 },
    { lat: 49.3442068, lng: 19.4847081 },
    { lat: 49.3442024, lng: 19.484684 },
    { lat: 49.3441902, lng: 19.4846394 },
    { lat: 49.34417, lng: 19.4845685 },
    { lat: 49.3441559, lng: 19.4845292 },
    { lat: 49.3441098, lng: 19.4844091 },
    { lat: 49.3440078, lng: 19.4841494 },
    { lat: 49.3439224, lng: 19.4839352 },
    { lat: 49.3439061, lng: 19.4838986 },
    { lat: 49.3438943, lng: 19.4838612 },
    { lat: 49.3438306, lng: 19.4836971 },
    { lat: 49.343735, lng: 19.4834562 },
    { lat: 49.3436055, lng: 19.4830664 },
    { lat: 49.3434139, lng: 19.4824947 },
    { lat: 49.3433643, lng: 19.4823446 },
    { lat: 49.3432271, lng: 19.4820348 },
    { lat: 49.343106, lng: 19.4817757 },
    { lat: 49.3433554, lng: 19.4815101 },
    { lat: 49.3436026, lng: 19.4811541 },
    { lat: 49.3441684, lng: 19.480542 },
    { lat: 49.3445345, lng: 19.4800439 },
    { lat: 49.3449438, lng: 19.4791642 },
    { lat: 49.3450208, lng: 19.4789991 },
    { lat: 49.3452939, lng: 19.4785177 },
    { lat: 49.3455123, lng: 19.4778265 },
    { lat: 49.3457801, lng: 19.4773728 },
    { lat: 49.3458008, lng: 19.4772105 },
    { lat: 49.3458529, lng: 19.4768229 },
    { lat: 49.3459081, lng: 19.4764036 },
    { lat: 49.3460367, lng: 19.4761329 },
    { lat: 49.346235, lng: 19.4757145 },
    { lat: 49.3467324, lng: 19.4748155 },
    { lat: 49.3473691, lng: 19.4736843 },
    { lat: 49.3477821, lng: 19.4729386 },
    { lat: 49.3478452, lng: 19.4728631 },
    { lat: 49.3479434, lng: 19.4727445 },
    { lat: 49.3480522, lng: 19.4726136 },
    { lat: 49.3482562, lng: 19.4723673 },
    { lat: 49.3486084, lng: 19.4717158 },
    { lat: 49.3488403, lng: 19.4710715 },
    { lat: 49.3488652, lng: 19.471003 },
    { lat: 49.3489775, lng: 19.4707221 },
    { lat: 49.3492658, lng: 19.470032 },
    { lat: 49.3492902, lng: 19.4699719 },
    { lat: 49.3493804, lng: 19.4697566 },
    { lat: 49.3494689, lng: 19.4695429 },
    { lat: 49.3497591, lng: 19.4690433 },
    { lat: 49.350039, lng: 19.468414 },
    { lat: 49.3502098, lng: 19.4680122 },
    { lat: 49.3503055, lng: 19.4677876 },
    { lat: 49.3506021, lng: 19.4670583 },
    { lat: 49.3507095, lng: 19.4667944 },
    { lat: 49.3507638, lng: 19.4666498 },
    { lat: 49.3508249, lng: 19.466485 },
    { lat: 49.3509082, lng: 19.4662617 },
    { lat: 49.3509593, lng: 19.4661254 },
    { lat: 49.3512172, lng: 19.4657037 },
    { lat: 49.3513043, lng: 19.4655657 },
    { lat: 49.3515116, lng: 19.4652413 },
    { lat: 49.351767, lng: 19.464908 },
  ],
  Vitanová: [
    { lat: 49.2611147, lng: 19.7213665 },
    { lat: 49.2561957, lng: 19.7248253 },
    { lat: 49.2548339, lng: 19.727612 },
    { lat: 49.2541523, lng: 19.7387098 },
    { lat: 49.2489317, lng: 19.7436369 },
    { lat: 49.2483743, lng: 19.7444699 },
    { lat: 49.2468067, lng: 19.7464725 },
    { lat: 49.2456853, lng: 19.7469246 },
    { lat: 49.245175, lng: 19.7474174 },
    { lat: 49.2442331, lng: 19.7482421 },
    { lat: 49.24337, lng: 19.7506159 },
    { lat: 49.2425854, lng: 19.7517462 },
    { lat: 49.2427545, lng: 19.7530161 },
    { lat: 49.2423668, lng: 19.7536615 },
    { lat: 49.2416478, lng: 19.7540907 },
    { lat: 49.2412363, lng: 19.7544471 },
    { lat: 49.2408661, lng: 19.75482 },
    { lat: 49.2406219, lng: 19.7551101 },
    { lat: 49.2398843, lng: 19.7558172 },
    { lat: 49.2390526, lng: 19.7561968 },
    { lat: 49.2387586, lng: 19.7571546 },
    { lat: 49.2386144, lng: 19.7579317 },
    { lat: 49.2379162, lng: 19.7590803 },
    { lat: 49.2380277, lng: 19.7600117 },
    { lat: 49.2374855, lng: 19.7609149 },
    { lat: 49.2368712, lng: 19.7635773 },
    { lat: 49.2367776, lng: 19.7647934 },
    { lat: 49.2367565, lng: 19.7653186 },
    { lat: 49.236769, lng: 19.7656064 },
    { lat: 49.2365983, lng: 19.7667676 },
    { lat: 49.2364636, lng: 19.7694631 },
    { lat: 49.2375727, lng: 19.7707625 },
    { lat: 49.2376689, lng: 19.7708825 },
    { lat: 49.2380878, lng: 19.7713784 },
    { lat: 49.2384813, lng: 19.7722086 },
    { lat: 49.2390294, lng: 19.7733082 },
    { lat: 49.239147, lng: 19.773435 },
    { lat: 49.239574, lng: 19.773853 },
    { lat: 49.240074, lng: 19.77434 },
    { lat: 49.240573, lng: 19.774782 },
    { lat: 49.241301, lng: 19.775445 },
    { lat: 49.2415677, lng: 19.7756862 },
    { lat: 49.242021, lng: 19.776092 },
    { lat: 49.242361, lng: 19.776331 },
    { lat: 49.243127, lng: 19.776845 },
    { lat: 49.243945, lng: 19.777412 },
    { lat: 49.244679, lng: 19.777923 },
    { lat: 49.245401, lng: 19.778433 },
    { lat: 49.245706, lng: 19.780243 },
    { lat: 49.245882, lng: 19.781166 },
    { lat: 49.245859, lng: 19.781532 },
    { lat: 49.245974, lng: 19.781836 },
    { lat: 49.246079, lng: 19.782184 },
    { lat: 49.24618, lng: 19.782343 },
    { lat: 49.246271, lng: 19.782608 },
    { lat: 49.246396, lng: 19.782725 },
    { lat: 49.246485, lng: 19.783368 },
    { lat: 49.246471, lng: 19.783841 },
    { lat: 49.2462266, lng: 19.7846935 },
    { lat: 49.246663, lng: 19.785023 },
    { lat: 49.247625, lng: 19.785447 },
    { lat: 49.248515, lng: 19.785851 },
    { lat: 49.2490401, lng: 19.7861251 },
    { lat: 49.249279, lng: 19.786196 },
    { lat: 49.250229, lng: 19.786806 },
    { lat: 49.250843, lng: 19.787211 },
    { lat: 49.251434, lng: 19.78759 },
    { lat: 49.252038, lng: 19.788171 },
    { lat: 49.25243, lng: 19.78838 },
    { lat: 49.252828, lng: 19.788552 },
    { lat: 49.253582, lng: 19.788733 },
    { lat: 49.25378, lng: 19.789071 },
    { lat: 49.253908, lng: 19.789571 },
    { lat: 49.254343, lng: 19.789965 },
    { lat: 49.254896, lng: 19.790273 },
    { lat: 49.255259, lng: 19.790499 },
    { lat: 49.255594, lng: 19.790483 },
    { lat: 49.256597, lng: 19.790346 },
    { lat: 49.256823, lng: 19.790342 },
    { lat: 49.257106, lng: 19.790152 },
    { lat: 49.257626, lng: 19.789635 },
    { lat: 49.258422, lng: 19.789117 },
    { lat: 49.259047, lng: 19.789162 },
    { lat: 49.259557, lng: 19.789266 },
    { lat: 49.26024, lng: 19.78955 },
    { lat: 49.260391, lng: 19.789618 },
    { lat: 49.2609, lng: 19.789625 },
    { lat: 49.261621, lng: 19.788786 },
    { lat: 49.261756, lng: 19.788843 },
    { lat: 49.262131, lng: 19.789732 },
    { lat: 49.262746, lng: 19.791154 },
    { lat: 49.263215, lng: 19.791733 },
    { lat: 49.263418, lng: 19.792178 },
    { lat: 49.263549, lng: 19.792029 },
    { lat: 49.263843, lng: 19.791798 },
    { lat: 49.265004, lng: 19.791777 },
    { lat: 49.265624, lng: 19.791972 },
    { lat: 49.266228, lng: 19.792073 },
    { lat: 49.266716, lng: 19.7923 },
    { lat: 49.267242, lng: 19.792294 },
    { lat: 49.267969, lng: 19.792472 },
    { lat: 49.268539, lng: 19.793689 },
    { lat: 49.268877, lng: 19.794234 },
    { lat: 49.269146, lng: 19.794854 },
    { lat: 49.269189, lng: 19.795626 },
    { lat: 49.269399, lng: 19.796305 },
    { lat: 49.269687, lng: 19.797279 },
    { lat: 49.2699486, lng: 19.7982167 },
    { lat: 49.269999, lng: 19.79828 },
    { lat: 49.270045, lng: 19.798693 },
    { lat: 49.2701061, lng: 19.7991126 },
    { lat: 49.270125, lng: 19.799242 },
    { lat: 49.270439, lng: 19.800263 },
    { lat: 49.270529, lng: 19.801917 },
    { lat: 49.27062, lng: 19.802229 },
    { lat: 49.271105, lng: 19.80247 },
    { lat: 49.271593, lng: 19.802715 },
    { lat: 49.271843, lng: 19.802972 },
    { lat: 49.271976, lng: 19.803371 },
    { lat: 49.272207, lng: 19.803744 },
    { lat: 49.272653, lng: 19.803974 },
    { lat: 49.272847, lng: 19.804248 },
    { lat: 49.27333, lng: 19.804952 },
    { lat: 49.2735921, lng: 19.8056008 },
    { lat: 49.273631, lng: 19.805697 },
    { lat: 49.274489, lng: 19.806343 },
    { lat: 49.274568, lng: 19.806671 },
    { lat: 49.274639, lng: 19.807349 },
    { lat: 49.27437, lng: 19.808623 },
    { lat: 49.274551, lng: 19.809555 },
    { lat: 49.274886, lng: 19.81038 },
    { lat: 49.275258, lng: 19.810895 },
    { lat: 49.275305, lng: 19.811617 },
    { lat: 49.275263, lng: 19.812746 },
    { lat: 49.275241, lng: 19.813726 },
    { lat: 49.27516, lng: 19.814262 },
    { lat: 49.27511, lng: 19.814915 },
    { lat: 49.275092, lng: 19.815542 },
    { lat: 49.275295, lng: 19.815855 },
    { lat: 49.275687, lng: 19.816389 },
    { lat: 49.27594, lng: 19.817222 },
    { lat: 49.276089, lng: 19.817296 },
    { lat: 49.276395, lng: 19.817769 },
    { lat: 49.276403, lng: 19.818542 },
    { lat: 49.276684, lng: 19.819517 },
    { lat: 49.276472, lng: 19.820788 },
    { lat: 49.276646, lng: 19.821936 },
    { lat: 49.276794, lng: 19.822802 },
    { lat: 49.27711, lng: 19.822788 },
    { lat: 49.2777416, lng: 19.8227708 },
    { lat: 49.277809, lng: 19.822769 },
    { lat: 49.2779305, lng: 19.822752 },
    { lat: 49.278643, lng: 19.822652 },
    { lat: 49.279469, lng: 19.8222232 },
    { lat: 49.2797283, lng: 19.8220852 },
    { lat: 49.279864, lng: 19.822013 },
    { lat: 49.280705, lng: 19.821323 },
    { lat: 49.281238, lng: 19.820872 },
    { lat: 49.2819841, lng: 19.8205676 },
    { lat: 49.282609, lng: 19.820225 },
    { lat: 49.2834207, lng: 19.8197747 },
    { lat: 49.283577, lng: 19.819688 },
    { lat: 49.283739, lng: 19.819488 },
    { lat: 49.284115, lng: 19.819016 },
    { lat: 49.284492, lng: 19.818472 },
    { lat: 49.284824, lng: 19.817937 },
    { lat: 49.285334, lng: 19.817295 },
    { lat: 49.285641, lng: 19.817202 },
    { lat: 49.286154, lng: 19.816587 },
    { lat: 49.286863, lng: 19.815774 },
    { lat: 49.287811, lng: 19.815371 },
    { lat: 49.28869, lng: 19.814813 },
    { lat: 49.28893, lng: 19.814528 },
    { lat: 49.289284, lng: 19.814305 },
    { lat: 49.289833, lng: 19.81399 },
    { lat: 49.290541, lng: 19.813333 },
    { lat: 49.291076, lng: 19.812869 },
    { lat: 49.291891, lng: 19.812156 },
    { lat: 49.292212, lng: 19.811796 },
    { lat: 49.292708, lng: 19.811358 },
    { lat: 49.293129, lng: 19.811078 },
    { lat: 49.293388, lng: 19.810917 },
    { lat: 49.293825, lng: 19.810535 },
    { lat: 49.294372, lng: 19.810269 },
    { lat: 49.29491, lng: 19.809996 },
    { lat: 49.2953969, lng: 19.8098793 },
    { lat: 49.2955537, lng: 19.8084968 },
    { lat: 49.2955947, lng: 19.8076504 },
    { lat: 49.2956229, lng: 19.807068 },
    { lat: 49.295588, lng: 19.806339 },
    { lat: 49.295575, lng: 19.805643 },
    { lat: 49.29559, lng: 19.804816 },
    { lat: 49.295584, lng: 19.803319 },
    { lat: 49.295702, lng: 19.801627 },
    { lat: 49.295828, lng: 19.799795 },
    { lat: 49.2951573, lng: 19.8003015 },
    { lat: 49.293627, lng: 19.8013581 },
    { lat: 49.2910607, lng: 19.8031069 },
    { lat: 49.2852826, lng: 19.8071507 },
    { lat: 49.2845466, lng: 19.8076645 },
    { lat: 49.2830399, lng: 19.8087229 },
    { lat: 49.2816098, lng: 19.8097522 },
    { lat: 49.2815268, lng: 19.8098136 },
    { lat: 49.2814616, lng: 19.8095817 },
    { lat: 49.2813756, lng: 19.8094929 },
    { lat: 49.2812952, lng: 19.8094836 },
    { lat: 49.2812647, lng: 19.809456 },
    { lat: 49.2811922, lng: 19.8092894 },
    { lat: 49.2811841, lng: 19.8092332 },
    { lat: 49.281213, lng: 19.8090091 },
    { lat: 49.2812022, lng: 19.8089734 },
    { lat: 49.2811196, lng: 19.8089352 },
    { lat: 49.2810983, lng: 19.8089113 },
    { lat: 49.2809393, lng: 19.8085394 },
    { lat: 49.2809367, lng: 19.8085061 },
    { lat: 49.2810145, lng: 19.8083785 },
    { lat: 49.281053, lng: 19.808226 },
    { lat: 49.2812342, lng: 19.80814 },
    { lat: 49.2812338, lng: 19.8080538 },
    { lat: 49.2811606, lng: 19.8077401 },
    { lat: 49.2810825, lng: 19.807522 },
    { lat: 49.2809408, lng: 19.8075803 },
    { lat: 49.2809067, lng: 19.8075451 },
    { lat: 49.280917, lng: 19.8071039 },
    { lat: 49.2807621, lng: 19.806697 },
    { lat: 49.2807735, lng: 19.8065172 },
    { lat: 49.2806228, lng: 19.8063386 },
    { lat: 49.2805761, lng: 19.8061203 },
    { lat: 49.2805047, lng: 19.8059255 },
    { lat: 49.2803792, lng: 19.8057837 },
    { lat: 49.2803337, lng: 19.8057197 },
    { lat: 49.2803187, lng: 19.8056332 },
    { lat: 49.2803385, lng: 19.8055936 },
    { lat: 49.2804343, lng: 19.8054949 },
    { lat: 49.2804401, lng: 19.8054698 },
    { lat: 49.2804257, lng: 19.805398 },
    { lat: 49.2803381, lng: 19.8054537 },
    { lat: 49.280271, lng: 19.8054399 },
    { lat: 49.2802358, lng: 19.8053568 },
    { lat: 49.2802597, lng: 19.8052335 },
    { lat: 49.2803423, lng: 19.8050304 },
    { lat: 49.2802786, lng: 19.8049098 },
    { lat: 49.2802861, lng: 19.804847 },
    { lat: 49.2805371, lng: 19.8042416 },
    { lat: 49.2806352, lng: 19.8041124 },
    { lat: 49.2806729, lng: 19.8039953 },
    { lat: 49.2806083, lng: 19.8039863 },
    { lat: 49.2805124, lng: 19.8039028 },
    { lat: 49.2805221, lng: 19.8037377 },
    { lat: 49.2805106, lng: 19.8034704 },
    { lat: 49.2805716, lng: 19.8032095 },
    { lat: 49.2806396, lng: 19.8031172 },
    { lat: 49.2805638, lng: 19.8029527 },
    { lat: 49.2805661, lng: 19.802666 },
    { lat: 49.28064, lng: 19.8025514 },
    { lat: 49.2807642, lng: 19.8025285 },
    { lat: 49.280872, lng: 19.8027298 },
    { lat: 49.2809696, lng: 19.8026186 },
    { lat: 49.2811995, lng: 19.8025688 },
    { lat: 49.2813418, lng: 19.802496 },
    { lat: 49.2813518, lng: 19.8023626 },
    { lat: 49.2811051, lng: 19.8022344 },
    { lat: 49.2811287, lng: 19.8021523 },
    { lat: 49.2812527, lng: 19.802023 },
    { lat: 49.281275, lng: 19.8019154 },
    { lat: 49.2812415, lng: 19.8017614 },
    { lat: 49.2813814, lng: 19.801585 },
    { lat: 49.2811268, lng: 19.8015049 },
    { lat: 49.2810453, lng: 19.8011584 },
    { lat: 49.2812187, lng: 19.800865 },
    { lat: 49.2811692, lng: 19.8005257 },
    { lat: 49.2812114, lng: 19.8004096 },
    { lat: 49.2813298, lng: 19.8003581 },
    { lat: 49.2815089, lng: 19.8005172 },
    { lat: 49.2814369, lng: 19.8006752 },
    { lat: 49.281453, lng: 19.800709 },
    { lat: 49.2815328, lng: 19.8005238 },
    { lat: 49.2814594, lng: 19.8002057 },
    { lat: 49.2816795, lng: 19.7998239 },
    { lat: 49.2817353, lng: 19.7996278 },
    { lat: 49.2816369, lng: 19.7988075 },
    { lat: 49.2817275, lng: 19.7983726 },
    { lat: 49.2818964, lng: 19.797849 },
    { lat: 49.2817676, lng: 19.7976542 },
    { lat: 49.2817618, lng: 19.7976288 },
    { lat: 49.2819372, lng: 19.7974592 },
    { lat: 49.2819357, lng: 19.7974237 },
    { lat: 49.2818323, lng: 19.7973543 },
    { lat: 49.2818444, lng: 19.7972445 },
    { lat: 49.2818548, lng: 19.7968076 },
    { lat: 49.2819589, lng: 19.7967073 },
    { lat: 49.2818901, lng: 19.7963671 },
    { lat: 49.2819051, lng: 19.7961657 },
    { lat: 49.2819333, lng: 19.7961419 },
    { lat: 49.2820808, lng: 19.7961404 },
    { lat: 49.282174, lng: 19.7959763 },
    { lat: 49.2821693, lng: 19.7955265 },
    { lat: 49.2823412, lng: 19.7953641 },
    { lat: 49.2822997, lng: 19.79497 },
    { lat: 49.2823207, lng: 19.7949382 },
    { lat: 49.2825755, lng: 19.7948141 },
    { lat: 49.282681, lng: 19.7946707 },
    { lat: 49.282714, lng: 19.7945212 },
    { lat: 49.2826761, lng: 19.7943219 },
    { lat: 49.2827496, lng: 19.7940179 },
    { lat: 49.2828272, lng: 19.7936482 },
    { lat: 49.28302, lng: 19.7936746 },
    { lat: 49.2831621, lng: 19.7937631 },
    { lat: 49.2832727, lng: 19.7935803 },
    { lat: 49.2831705, lng: 19.7931976 },
    { lat: 49.2832155, lng: 19.7930415 },
    { lat: 49.2833889, lng: 19.7928541 },
    { lat: 49.2833073, lng: 19.7928439 },
    { lat: 49.2831192, lng: 19.7927907 },
    { lat: 49.2829608, lng: 19.7926476 },
    { lat: 49.2828675, lng: 19.7924654 },
    { lat: 49.2828908, lng: 19.7922494 },
    { lat: 49.2829354, lng: 19.7922117 },
    { lat: 49.282955, lng: 19.791806 },
    { lat: 49.2830411, lng: 19.7915534 },
    { lat: 49.2832077, lng: 19.791449 },
    { lat: 49.2833385, lng: 19.7914769 },
    { lat: 49.2834553, lng: 19.7916019 },
    { lat: 49.2834679, lng: 19.7914992 },
    { lat: 49.283367, lng: 19.7909884 },
    { lat: 49.2831185, lng: 19.7902391 },
    { lat: 49.2830784, lng: 19.7900144 },
    { lat: 49.2831268, lng: 19.789517 },
    { lat: 49.2833285, lng: 19.7894151 },
    { lat: 49.283418, lng: 19.7888797 },
    { lat: 49.2833331, lng: 19.7886371 },
    { lat: 49.2832127, lng: 19.7886629 },
    { lat: 49.2831517, lng: 19.7888968 },
    { lat: 49.2830177, lng: 19.7889193 },
    { lat: 49.2827624, lng: 19.7884074 },
    { lat: 49.2827908, lng: 19.7881289 },
    { lat: 49.2830485, lng: 19.7877295 },
    { lat: 49.2829941, lng: 19.7874586 },
    { lat: 49.2827069, lng: 19.7871769 },
    { lat: 49.2826841, lng: 19.7870366 },
    { lat: 49.2827189, lng: 19.7869552 },
    { lat: 49.2830068, lng: 19.7868385 },
    { lat: 49.2827907, lng: 19.7861419 },
    { lat: 49.2828163, lng: 19.7858705 },
    { lat: 49.2825152, lng: 19.7859716 },
    { lat: 49.2824866, lng: 19.7859597 },
    { lat: 49.2821785, lng: 19.7857404 },
    { lat: 49.2821794, lng: 19.7856291 },
    { lat: 49.2821997, lng: 19.7855744 },
    { lat: 49.282354, lng: 19.7850811 },
    { lat: 49.2823919, lng: 19.7848587 },
    { lat: 49.2821882, lng: 19.7848168 },
    { lat: 49.2820111, lng: 19.7847278 },
    { lat: 49.2820474, lng: 19.7842934 },
    { lat: 49.2823265, lng: 19.7836836 },
    { lat: 49.2826624, lng: 19.7833499 },
    { lat: 49.2826288, lng: 19.7830148 },
    { lat: 49.2825232, lng: 19.7826859 },
    { lat: 49.2826065, lng: 19.7826855 },
    { lat: 49.282766, lng: 19.7827498 },
    { lat: 49.2828809, lng: 19.782906 },
    { lat: 49.282859, lng: 19.783076 },
    { lat: 49.2828598, lng: 19.7830961 },
    { lat: 49.2828712, lng: 19.7831018 },
    { lat: 49.282894, lng: 19.7830779 },
    { lat: 49.2829602, lng: 19.7825516 },
    { lat: 49.282974, lng: 19.7824066 },
    { lat: 49.2832497, lng: 19.7820562 },
    { lat: 49.2832471, lng: 19.7819475 },
    { lat: 49.2832361, lng: 19.781927 },
    { lat: 49.2831123, lng: 19.7819521 },
    { lat: 49.2830327, lng: 19.7818295 },
    { lat: 49.283013, lng: 19.7814573 },
    { lat: 49.2831638, lng: 19.7813725 },
    { lat: 49.2833977, lng: 19.781285 },
    { lat: 49.2836097, lng: 19.7812921 },
    { lat: 49.2836499, lng: 19.7811029 },
    { lat: 49.2833591, lng: 19.7808613 },
    { lat: 49.283336, lng: 19.7806381 },
    { lat: 49.2834588, lng: 19.7805644 },
    { lat: 49.2834875, lng: 19.7805987 },
    { lat: 49.2839277, lng: 19.7806217 },
    { lat: 49.2841441, lng: 19.7808333 },
    { lat: 49.2842454, lng: 19.7808292 },
    { lat: 49.2840732, lng: 19.78034 },
    { lat: 49.2840491, lng: 19.7800433 },
    { lat: 49.2841086, lng: 19.7798042 },
    { lat: 49.2842052, lng: 19.7796414 },
    { lat: 49.2842764, lng: 19.7796173 },
    { lat: 49.2843439, lng: 19.778988 },
    { lat: 49.2844427, lng: 19.7787237 },
    { lat: 49.28441, lng: 19.7782027 },
    { lat: 49.2843484, lng: 19.7779744 },
    { lat: 49.2842967, lng: 19.7774824 },
    { lat: 49.284348, lng: 19.7772885 },
    { lat: 49.2844584, lng: 19.777133 },
    { lat: 49.2845777, lng: 19.7771299 },
    { lat: 49.2846074, lng: 19.7771114 },
    { lat: 49.2844291, lng: 19.7766633 },
    { lat: 49.2844661, lng: 19.7764001 },
    { lat: 49.2846313, lng: 19.7764433 },
    { lat: 49.2847928, lng: 19.7764555 },
    { lat: 49.2848932, lng: 19.7765056 },
    { lat: 49.2850432, lng: 19.7766939 },
    { lat: 49.2851139, lng: 19.7766088 },
    { lat: 49.2850753, lng: 19.7759513 },
    { lat: 49.2849832, lng: 19.7758037 },
    { lat: 49.2853131, lng: 19.7754351 },
    { lat: 49.2853446, lng: 19.7753562 },
    { lat: 49.285371, lng: 19.7753394 },
    { lat: 49.2855273, lng: 19.7753077 },
    { lat: 49.285595, lng: 19.775285 },
    { lat: 49.2856425, lng: 19.7752914 },
    { lat: 49.2858733, lng: 19.7747391 },
    { lat: 49.2859725, lng: 19.7747388 },
    { lat: 49.2860309, lng: 19.774791 },
    { lat: 49.2862005, lng: 19.7748262 },
    { lat: 49.2863974, lng: 19.7746901 },
    { lat: 49.2865309, lng: 19.7744702 },
    { lat: 49.2867848, lng: 19.774381 },
    { lat: 49.2869673, lng: 19.7741698 },
    { lat: 49.2871056, lng: 19.7737766 },
    { lat: 49.2867247, lng: 19.7736679 },
    { lat: 49.2866863, lng: 19.7736649 },
    { lat: 49.2857238, lng: 19.7739892 },
    { lat: 49.2855672, lng: 19.7738336 },
    { lat: 49.2854742, lng: 19.7738178 },
    { lat: 49.2850844, lng: 19.773924 },
    { lat: 49.2849802, lng: 19.7739594 },
    { lat: 49.284656, lng: 19.7738385 },
    { lat: 49.284318, lng: 19.7739361 },
    { lat: 49.2842416, lng: 19.7741499 },
    { lat: 49.2840074, lng: 19.7740509 },
    { lat: 49.2838807, lng: 19.7735932 },
    { lat: 49.2838186, lng: 19.7735879 },
    { lat: 49.2834399, lng: 19.7738478 },
    { lat: 49.2833053, lng: 19.7738845 },
    { lat: 49.2830317, lng: 19.7738089 },
    { lat: 49.2829924, lng: 19.7737339 },
    { lat: 49.2828888, lng: 19.7732926 },
    { lat: 49.2825814, lng: 19.7731393 },
    { lat: 49.28251, lng: 19.7731592 },
    { lat: 49.2821686, lng: 19.773075 },
    { lat: 49.2821011, lng: 19.7730262 },
    { lat: 49.2817733, lng: 19.7726073 },
    { lat: 49.2808737, lng: 19.7715762 },
    { lat: 49.2804939, lng: 19.771576 },
    { lat: 49.2804506, lng: 19.7715894 },
    { lat: 49.2801991, lng: 19.7719731 },
    { lat: 49.2800824, lng: 19.7720585 },
    { lat: 49.2800623, lng: 19.7719869 },
    { lat: 49.2792203, lng: 19.7691363 },
    { lat: 49.2791108, lng: 19.7687447 },
    { lat: 49.2789603, lng: 19.7682332 },
    { lat: 49.2788133, lng: 19.7677217 },
    { lat: 49.2785923, lng: 19.7669915 },
    { lat: 49.2783103, lng: 19.7660444 },
    { lat: 49.2783103, lng: 19.7660246 },
    { lat: 49.2782349, lng: 19.7652966 },
    { lat: 49.2780302, lng: 19.7631457 },
    { lat: 49.2776385, lng: 19.7590519 },
    { lat: 49.2775521, lng: 19.7581742 },
    { lat: 49.2775169, lng: 19.7578592 },
    { lat: 49.2774476, lng: 19.7571713 },
    { lat: 49.2774019, lng: 19.7571568 },
    { lat: 49.2769937, lng: 19.7568822 },
    { lat: 49.2767527, lng: 19.7566556 },
    { lat: 49.2764907, lng: 19.7566886 },
    { lat: 49.2762005, lng: 19.7566192 },
    { lat: 49.2759737, lng: 19.7565076 },
    { lat: 49.2758192, lng: 19.7563509 },
    { lat: 49.2754993, lng: 19.7561939 },
    { lat: 49.2753604, lng: 19.7559017 },
    { lat: 49.2752144, lng: 19.7557051 },
    { lat: 49.2749015, lng: 19.7554504 },
    { lat: 49.2747282, lng: 19.755608 },
    { lat: 49.2744792, lng: 19.7553739 },
    { lat: 49.2744514, lng: 19.7553601 },
    { lat: 49.2741095, lng: 19.7553688 },
    { lat: 49.2740708, lng: 19.7553641 },
    { lat: 49.2740446, lng: 19.755353 },
    { lat: 49.273965, lng: 19.7552583 },
    { lat: 49.2740097, lng: 19.7547599 },
    { lat: 49.2740127, lng: 19.7546719 },
    { lat: 49.2740468, lng: 19.7542139 },
    { lat: 49.2745754, lng: 19.7475849 },
    { lat: 49.275214, lng: 19.743546 },
    { lat: 49.2766254, lng: 19.7426907 },
    { lat: 49.2797538, lng: 19.742538 },
    { lat: 49.2797791, lng: 19.7425492 },
    { lat: 49.2798309, lng: 19.7420597 },
    { lat: 49.2798952, lng: 19.7414921 },
    { lat: 49.2799361, lng: 19.741108 },
    { lat: 49.2797627, lng: 19.7404387 },
    { lat: 49.2793557, lng: 19.7388554 },
    { lat: 49.2789755, lng: 19.7374978 },
    { lat: 49.2788134, lng: 19.7369027 },
    { lat: 49.2786917, lng: 19.7364681 },
    { lat: 49.2775971, lng: 19.7325748 },
    { lat: 49.2772743, lng: 19.7314477 },
    { lat: 49.277499, lng: 19.7304517 },
    { lat: 49.2775583, lng: 19.7302097 },
    { lat: 49.2778914, lng: 19.7290674 },
    { lat: 49.2787763, lng: 19.7247474 },
    { lat: 49.2789173, lng: 19.7240846 },
    { lat: 49.2790244, lng: 19.7236195 },
    { lat: 49.2793185, lng: 19.7230572 },
    { lat: 49.2796684, lng: 19.7223771 },
    { lat: 49.2799375, lng: 19.7218593 },
    { lat: 49.2799992, lng: 19.7217487 },
    { lat: 49.2805762, lng: 19.7213672 },
    { lat: 49.2812395, lng: 19.7212088 },
    { lat: 49.2818238, lng: 19.7210716 },
    { lat: 49.2818642, lng: 19.7210663 },
    { lat: 49.2822079, lng: 19.7208667 },
    { lat: 49.2826901, lng: 19.7205024 },
    { lat: 49.2846695, lng: 19.7189874 },
    { lat: 49.2854033, lng: 19.7184299 },
    { lat: 49.2870609, lng: 19.7172103 },
    { lat: 49.2885277, lng: 19.7161296 },
    { lat: 49.2892711, lng: 19.7155877 },
    { lat: 49.2904599, lng: 19.7147194 },
    { lat: 49.2920087, lng: 19.7135822 },
    { lat: 49.2933223, lng: 19.712631 },
    { lat: 49.2943164, lng: 19.7119741 },
    { lat: 49.296538, lng: 19.7105148 },
    { lat: 49.2965665, lng: 19.7104982 },
    { lat: 49.2968934, lng: 19.7119413 },
    { lat: 49.2970175, lng: 19.7122531 },
    { lat: 49.297772, lng: 19.7139203 },
    { lat: 49.2984004, lng: 19.7153074 },
    { lat: 49.2986221, lng: 19.7158157 },
    { lat: 49.2986774, lng: 19.7158988 },
    { lat: 49.2986868, lng: 19.7159574 },
    { lat: 49.2992966, lng: 19.7171244 },
    { lat: 49.2996183, lng: 19.7177329 },
    { lat: 49.3009497, lng: 19.7207964 },
    { lat: 49.302384, lng: 19.7242588 },
    { lat: 49.3027562, lng: 19.7248633 },
    { lat: 49.3029452, lng: 19.7251639 },
    { lat: 49.3031164, lng: 19.7254478 },
    { lat: 49.303227, lng: 19.7256062 },
    { lat: 49.3034002, lng: 19.7259361 },
    { lat: 49.3035326, lng: 19.7260543 },
    { lat: 49.3036538, lng: 19.726251 },
    { lat: 49.3037234, lng: 19.7264027 },
    { lat: 49.3046721, lng: 19.727926 },
    { lat: 49.3047817, lng: 19.7280638 },
    { lat: 49.3048753, lng: 19.7281684 },
    { lat: 49.3051216, lng: 19.7284959 },
    { lat: 49.3054642, lng: 19.7288873 },
    { lat: 49.3054754, lng: 19.7289107 },
    { lat: 49.3063767, lng: 19.730023 },
    { lat: 49.3066239, lng: 19.7303205 },
    { lat: 49.306922, lng: 19.7305966 },
    { lat: 49.3071135, lng: 19.7308261 },
    { lat: 49.3072873, lng: 19.731146 },
    { lat: 49.307368, lng: 19.7313132 },
    { lat: 49.3075371, lng: 19.7316024 },
    { lat: 49.3075776, lng: 19.731682 },
    { lat: 49.3077354, lng: 19.7319104 },
    { lat: 49.3078571, lng: 19.7320438 },
    { lat: 49.3081726, lng: 19.732356 },
    { lat: 49.308394, lng: 19.73268 },
    { lat: 49.3086114, lng: 19.7329409 },
    { lat: 49.3087081, lng: 19.7331162 },
    { lat: 49.3088453, lng: 19.7334701 },
    { lat: 49.3089509, lng: 19.7335904 },
    { lat: 49.3091489, lng: 19.7336937 },
    { lat: 49.3093094, lng: 19.7338058 },
    { lat: 49.3094786, lng: 19.7338925 },
    { lat: 49.3096813, lng: 19.7340697 },
    { lat: 49.3098263, lng: 19.7342921 },
    { lat: 49.3099337, lng: 19.7343317 },
    { lat: 49.3101388, lng: 19.7345895 },
    { lat: 49.3103534, lng: 19.7347868 },
    { lat: 49.3108185, lng: 19.7353242 },
    { lat: 49.3108618, lng: 19.7353866 },
    { lat: 49.3109391, lng: 19.7354527 },
    { lat: 49.3110146, lng: 19.7355339 },
    { lat: 49.3113515, lng: 19.7359255 },
    { lat: 49.3116538, lng: 19.7361893 },
    { lat: 49.3121237, lng: 19.7367881 },
    { lat: 49.3122576, lng: 19.7369419 },
    { lat: 49.3124668, lng: 19.7371668 },
    { lat: 49.312698, lng: 19.7373523 },
    { lat: 49.3129839, lng: 19.737555 },
    { lat: 49.3130635, lng: 19.7376736 },
    { lat: 49.3136535, lng: 19.7378778 },
    { lat: 49.3138978, lng: 19.7378233 },
    { lat: 49.3142841, lng: 19.7377442 },
    { lat: 49.314462, lng: 19.737612 },
    { lat: 49.3156908, lng: 19.7367285 },
    { lat: 49.3165027, lng: 19.7360478 },
    { lat: 49.3166804, lng: 19.7358903 },
    { lat: 49.3177614, lng: 19.7337852 },
    { lat: 49.3178939, lng: 19.7333313 },
    { lat: 49.3178469, lng: 19.7332662 },
    { lat: 49.3174396, lng: 19.7327326 },
    { lat: 49.317091, lng: 19.7322839 },
    { lat: 49.3169214, lng: 19.7319282 },
    { lat: 49.3167021, lng: 19.7315769 },
    { lat: 49.3164357, lng: 19.7311784 },
    { lat: 49.3162925, lng: 19.7309702 },
    { lat: 49.3161869, lng: 19.7307679 },
    { lat: 49.3161639, lng: 19.7306508 },
    { lat: 49.3161181, lng: 19.7306067 },
    { lat: 49.3160286, lng: 19.730418 },
    { lat: 49.3154485, lng: 19.7292842 },
    { lat: 49.315251, lng: 19.7288624 },
    { lat: 49.3149041, lng: 19.7280858 },
    { lat: 49.3144661, lng: 19.7274438 },
    { lat: 49.314199, lng: 19.7265915 },
    { lat: 49.3141205, lng: 19.7263674 },
    { lat: 49.3140238, lng: 19.7261379 },
    { lat: 49.3140299, lng: 19.7260217 },
    { lat: 49.3139583, lng: 19.7258532 },
    { lat: 49.3137185, lng: 19.7255195 },
    { lat: 49.3135171, lng: 19.7252144 },
    { lat: 49.3134705, lng: 19.7247159 },
    { lat: 49.3131434, lng: 19.7236455 },
    { lat: 49.3129882, lng: 19.7231895 },
    { lat: 49.3128072, lng: 19.7226292 },
    { lat: 49.3126595, lng: 19.7219818 },
    { lat: 49.3125865, lng: 19.7217037 },
    { lat: 49.3124537, lng: 19.7215291 },
    { lat: 49.3121905, lng: 19.7206596 },
    { lat: 49.3121818, lng: 19.7205128 },
    { lat: 49.3118176, lng: 19.7198615 },
    { lat: 49.3117565, lng: 19.7193872 },
    { lat: 49.3116527, lng: 19.7189989 },
    { lat: 49.31152, lng: 19.7187991 },
    { lat: 49.3114587, lng: 19.7184281 },
    { lat: 49.3113174, lng: 19.7180511 },
    { lat: 49.3110742, lng: 19.7177208 },
    { lat: 49.3110649, lng: 19.7174044 },
    { lat: 49.3106268, lng: 19.7162231 },
    { lat: 49.310313, lng: 19.7155912 },
    { lat: 49.3100636, lng: 19.715064 },
    { lat: 49.309841, lng: 19.7144799 },
    { lat: 49.3095378, lng: 19.7138853 },
    { lat: 49.3090123, lng: 19.7127398 },
    { lat: 49.3087713, lng: 19.712359 },
    { lat: 49.3086911, lng: 19.7122239 },
    { lat: 49.3085427, lng: 19.7119463 },
    { lat: 49.3082125, lng: 19.7113534 },
    { lat: 49.3080969, lng: 19.7111829 },
    { lat: 49.3078143, lng: 19.7109003 },
    { lat: 49.3074696, lng: 19.710646 },
    { lat: 49.3071054, lng: 19.7104317 },
    { lat: 49.3068058, lng: 19.7101501 },
    { lat: 49.3065185, lng: 19.7098167 },
    { lat: 49.3063651, lng: 19.7094996 },
    { lat: 49.3062212, lng: 19.7089144 },
    { lat: 49.3059526, lng: 19.7084657 },
    { lat: 49.3056723, lng: 19.7080071 },
    { lat: 49.3053303, lng: 19.7075559 },
    { lat: 49.3052042, lng: 19.7074298 },
    { lat: 49.305068, lng: 19.7072802 },
    { lat: 49.3048632, lng: 19.7069478 },
    { lat: 49.3047689, lng: 19.7066979 },
    { lat: 49.3047264, lng: 19.7065019 },
    { lat: 49.3045634, lng: 19.7061977 },
    { lat: 49.3043985, lng: 19.7060113 },
    { lat: 49.3043273, lng: 19.7059006 },
    { lat: 49.3041321, lng: 19.7053784 },
    { lat: 49.3040182, lng: 19.7050435 },
    { lat: 49.3038339, lng: 19.7044635 },
    { lat: 49.3035178, lng: 19.7037303 },
    { lat: 49.3034707, lng: 19.7036293 },
    { lat: 49.3030891, lng: 19.7029707 },
    { lat: 49.3027551, lng: 19.7024909 },
    { lat: 49.3023966, lng: 19.7018685 },
    { lat: 49.3021193, lng: 19.7012797 },
    { lat: 49.3017852, lng: 19.7003144 },
    { lat: 49.301599, lng: 19.6999989 },
    { lat: 49.3014011, lng: 19.6989796 },
    { lat: 49.3014318, lng: 19.6985978 },
    { lat: 49.3014316, lng: 19.6985931 },
    { lat: 49.3013468, lng: 19.6982271 },
    { lat: 49.3013827, lng: 19.6978661 },
    { lat: 49.3013851, lng: 19.6972547 },
    { lat: 49.3009456, lng: 19.6966598 },
    { lat: 49.300455, lng: 19.6958774 },
    { lat: 49.2998087, lng: 19.6950616 },
    { lat: 49.2990094, lng: 19.694181 },
    { lat: 49.2988299, lng: 19.6939154 },
    { lat: 49.2986411, lng: 19.693615 },
    { lat: 49.2978955, lng: 19.6922102 },
    { lat: 49.2978566, lng: 19.6921427 },
    { lat: 49.2978229, lng: 19.6920466 },
    { lat: 49.29644, lng: 19.6954405 },
    { lat: 49.2964193, lng: 19.6955058 },
    { lat: 49.2953494, lng: 19.6989767 },
    { lat: 49.2951514, lng: 19.6990338 },
    { lat: 49.2949999, lng: 19.6990973 },
    { lat: 49.2938351, lng: 19.699533 },
    { lat: 49.2906645, lng: 19.7007253 },
    { lat: 49.2883323, lng: 19.7015921 },
    { lat: 49.2877002, lng: 19.7020026 },
    { lat: 49.28463, lng: 19.7039395 },
    { lat: 49.2832593, lng: 19.7047974 },
    { lat: 49.2822483, lng: 19.7054235 },
    { lat: 49.2817229, lng: 19.7057469 },
    { lat: 49.2813377, lng: 19.7059805 },
    { lat: 49.2804488, lng: 19.70693 },
    { lat: 49.2801357, lng: 19.7073417 },
    { lat: 49.2800407, lng: 19.7074355 },
    { lat: 49.2792921, lng: 19.7081179 },
    { lat: 49.2784128, lng: 19.7089278 },
    { lat: 49.2768716, lng: 19.710334 },
    { lat: 49.2749851, lng: 19.7120658 },
    { lat: 49.2728747, lng: 19.7139875 },
    { lat: 49.2722032, lng: 19.7145995 },
    { lat: 49.2720485, lng: 19.7147495 },
    { lat: 49.2712379, lng: 19.7154767 },
    { lat: 49.2696977, lng: 19.7168867 },
    { lat: 49.2681992, lng: 19.7182729 },
    { lat: 49.262628, lng: 19.7207059 },
    { lat: 49.2615982, lng: 19.7211613 },
    { lat: 49.2611147, lng: 19.7213665 },
  ],
  VitanováExt: [
    { lat: 49.3417022, lng: 19.7211824 },
    { lat: 49.3413961, lng: 19.7221194 },
    { lat: 49.3409737, lng: 19.7230755 },
    { lat: 49.3407561, lng: 19.7236382 },
    { lat: 49.3399623, lng: 19.7248338 },
    { lat: 49.3398704, lng: 19.7248919 },
    { lat: 49.3394816, lng: 19.7250704 },
    { lat: 49.3392235, lng: 19.7251993 },
    { lat: 49.3389641, lng: 19.725318 },
    { lat: 49.3388917, lng: 19.7253145 },
    { lat: 49.3385823, lng: 19.7252721 },
    { lat: 49.3384806, lng: 19.7252623 },
    { lat: 49.338205, lng: 19.7252214 },
    { lat: 49.3379017, lng: 19.7251614 },
    { lat: 49.3363481, lng: 19.7248448 },
    { lat: 49.3362479, lng: 19.72482 },
    { lat: 49.3357598, lng: 19.7246326 },
    { lat: 49.3354591, lng: 19.7245076 },
    { lat: 49.3348895, lng: 19.7242808 },
    { lat: 49.33485, lng: 19.7242585 },
    { lat: 49.3347945, lng: 19.7242458 },
    { lat: 49.3338822, lng: 19.723889 },
    { lat: 49.3337848, lng: 19.7238541 },
    { lat: 49.3333333, lng: 19.7236763 },
    { lat: 49.3330645, lng: 19.7235597 },
    { lat: 49.3330089, lng: 19.7235469 },
    { lat: 49.3329662, lng: 19.7235249 },
    { lat: 49.3326486, lng: 19.7234241 },
    { lat: 49.332062, lng: 19.723224 },
    { lat: 49.3319545, lng: 19.7231837 },
    { lat: 49.3316096, lng: 19.7230763 },
    { lat: 49.3315268, lng: 19.7230396 },
    { lat: 49.331241, lng: 19.7229425 },
    { lat: 49.3310492, lng: 19.7228855 },
    { lat: 49.3308363, lng: 19.7228047 },
    { lat: 49.3308178, lng: 19.7228239 },
    { lat: 49.3306828, lng: 19.7227973 },
    { lat: 49.33027, lng: 19.7225552 },
    { lat: 49.3301674, lng: 19.722505 },
    { lat: 49.3296325, lng: 19.7222776 },
    { lat: 49.3294896, lng: 19.7222254 },
    { lat: 49.329213, lng: 19.7221085 },
    { lat: 49.3281139, lng: 19.7218312 },
    { lat: 49.3277436, lng: 19.7217351 },
    { lat: 49.3276727, lng: 19.721712 },
    { lat: 49.3276728, lng: 19.7216661 },
    { lat: 49.3274429, lng: 19.7216604 },
    { lat: 49.3272751, lng: 19.7216345 },
    { lat: 49.3268457, lng: 19.7216821 },
    { lat: 49.3267549, lng: 19.7216953 },
    { lat: 49.3267023, lng: 19.7218318 },
    { lat: 49.3259633, lng: 19.7223963 },
    { lat: 49.3258691, lng: 19.7225082 },
    { lat: 49.3256069, lng: 19.7226451 },
    { lat: 49.3253704, lng: 19.7227007 },
    { lat: 49.3248317, lng: 19.7228713 },
    { lat: 49.324807, lng: 19.7228731 },
    { lat: 49.324417, lng: 19.7230997 },
    { lat: 49.3239316, lng: 19.7233906 },
    { lat: 49.3232266, lng: 19.7244525 },
    { lat: 49.3230911, lng: 19.7247772 },
    { lat: 49.3229797, lng: 19.7250143 },
    { lat: 49.3230342, lng: 19.725155 },
    { lat: 49.3229515, lng: 19.7256831 },
    { lat: 49.3227403, lng: 19.7262149 },
    { lat: 49.3226861, lng: 19.7265191 },
    { lat: 49.3229193, lng: 19.7274768 },
    { lat: 49.3233301, lng: 19.729096 },
    { lat: 49.3233786, lng: 19.7293525 },
    { lat: 49.3234118, lng: 19.7298815 },
    { lat: 49.3233333, lng: 19.7301502 },
    { lat: 49.3227753, lng: 19.7311746 },
    { lat: 49.3224486, lng: 19.7316669 },
    { lat: 49.322034, lng: 19.7323845 },
    { lat: 49.321794, lng: 19.7323859 },
    { lat: 49.3213665, lng: 19.7324992 },
    { lat: 49.320969, lng: 19.7326358 },
    { lat: 49.3207237, lng: 19.7327691 },
    { lat: 49.319926, lng: 19.7333355 },
    { lat: 49.3198413, lng: 19.7334347 },
    { lat: 49.3188036, lng: 19.7346964 },
    { lat: 49.319015, lng: 19.7349973 },
    { lat: 49.3191917, lng: 19.7354343 },
    { lat: 49.3194895, lng: 19.7358058 },
    { lat: 49.3199459, lng: 19.736584 },
    { lat: 49.320164, lng: 19.7369359 },
    { lat: 49.3202827, lng: 19.7370758 },
    { lat: 49.3203896, lng: 19.7372809 },
    { lat: 49.3203452, lng: 19.7377075 },
    { lat: 49.320345, lng: 19.7378617 },
    { lat: 49.3205155, lng: 19.7381495 },
    { lat: 49.3206054, lng: 19.7385343 },
    { lat: 49.3206535, lng: 19.7388367 },
    { lat: 49.3206825, lng: 19.7390905 },
    { lat: 49.3206837, lng: 19.7391467 },
    { lat: 49.3206323, lng: 19.7395906 },
    { lat: 49.3205872, lng: 19.7398783 },
    { lat: 49.3206121, lng: 19.7400105 },
    { lat: 49.3205322, lng: 19.7401934 },
    { lat: 49.3204494, lng: 19.74026 },
    { lat: 49.3203912, lng: 19.7405216 },
    { lat: 49.3202982, lng: 19.740717 },
    { lat: 49.3198102, lng: 19.7414643 },
    { lat: 49.3197419, lng: 19.7415771 },
    { lat: 49.3195594, lng: 19.7417546 },
    { lat: 49.3194562, lng: 19.741894 },
    { lat: 49.3194528, lng: 19.7421803 },
    { lat: 49.3194162, lng: 19.7426072 },
    { lat: 49.3192832, lng: 19.7431212 },
    { lat: 49.3192815, lng: 19.7437598 },
    { lat: 49.3192092, lng: 19.7441178 },
    { lat: 49.3191738, lng: 19.7443399 },
    { lat: 49.3191758, lng: 19.7447228 },
    { lat: 49.3192216, lng: 19.7450204 },
    { lat: 49.3191269, lng: 19.745282 },
    { lat: 49.3191044, lng: 19.7453601 },
    { lat: 49.3194509, lng: 19.7456094 },
    { lat: 49.3198097, lng: 19.745986 },
    { lat: 49.3199393, lng: 19.746088 },
    { lat: 49.3201361, lng: 19.7469122 },
    { lat: 49.3202283, lng: 19.7473232 },
    { lat: 49.32029, lng: 19.7475758 },
    { lat: 49.3203203, lng: 19.7479089 },
    { lat: 49.3204144, lng: 19.7484922 },
    { lat: 49.3203625, lng: 19.7487496 },
    { lat: 49.3203899, lng: 19.74907 },
    { lat: 49.320319, lng: 19.7496925 },
    { lat: 49.3202644, lng: 19.7500996 },
    { lat: 49.3203794, lng: 19.7508769 },
    { lat: 49.3205733, lng: 19.7513464 },
    { lat: 49.3205204, lng: 19.7516075 },
    { lat: 49.3204396, lng: 19.7514871 },
    { lat: 49.3203663, lng: 19.7515596 },
    { lat: 49.3203932, lng: 19.7518959 },
    { lat: 49.3202834, lng: 19.7520378 },
    { lat: 49.3203227, lng: 19.7521638 },
    { lat: 49.3204619, lng: 19.7522341 },
    { lat: 49.3207095, lng: 19.752695 },
    { lat: 49.3209671, lng: 19.7531534 },
    { lat: 49.3211779, lng: 19.7535184 },
    { lat: 49.3213119, lng: 19.7536261 },
    { lat: 49.3215154, lng: 19.7540565 },
    { lat: 49.3216575, lng: 19.7544208 },
    { lat: 49.3217317, lng: 19.7546014 },
    { lat: 49.3217945, lng: 19.7547008 },
    { lat: 49.3218731, lng: 19.7548467 },
    { lat: 49.3219801, lng: 19.7550286 },
    { lat: 49.3220985, lng: 19.7552651 },
    { lat: 49.3222471, lng: 19.7555508 },
    { lat: 49.3224211, lng: 19.7558252 },
    { lat: 49.3225198, lng: 19.7559665 },
    { lat: 49.3225239, lng: 19.7561921 },
    { lat: 49.3227434, lng: 19.7565494 },
    { lat: 49.3228456, lng: 19.7568557 },
    { lat: 49.3225856, lng: 19.757462 },
    { lat: 49.3222118, lng: 19.7583461 },
    { lat: 49.3220741, lng: 19.7586764 },
    { lat: 49.3217553, lng: 19.7593807 },
    { lat: 49.3217301, lng: 19.7594479 },
    { lat: 49.3214937, lng: 19.7599713 },
    { lat: 49.3212141, lng: 19.7606111 },
    { lat: 49.3211346, lng: 19.7607816 },
    { lat: 49.3210101, lng: 19.7610293 },
    { lat: 49.3208914, lng: 19.7612541 },
    { lat: 49.3206228, lng: 19.7617879 },
    { lat: 49.3205114, lng: 19.7620227 },
    { lat: 49.3203159, lng: 19.7624513 },
    { lat: 49.3202485, lng: 19.7626131 },
    { lat: 49.3196116, lng: 19.7627802 },
    { lat: 49.3192176, lng: 19.7628935 },
    { lat: 49.3189448, lng: 19.7629663 },
    { lat: 49.3187525, lng: 19.7630226 },
    { lat: 49.3182233, lng: 19.7631568 },
    { lat: 49.3176523, lng: 19.7634743 },
    { lat: 49.3173177, lng: 19.7636556 },
    { lat: 49.3168887, lng: 19.7638929 },
    { lat: 49.3164456, lng: 19.7641419 },
    { lat: 49.3162727, lng: 19.7642601 },
    { lat: 49.3161056, lng: 19.7643533 },
    { lat: 49.3154888, lng: 19.7647837 },
    { lat: 49.3153427, lng: 19.7649184 },
    { lat: 49.3149336, lng: 19.7653057 },
    { lat: 49.3148661, lng: 19.765361 },
    { lat: 49.3146194, lng: 19.7654625 },
    { lat: 49.3141762, lng: 19.7656331 },
    { lat: 49.3135449, lng: 19.7658808 },
    { lat: 49.3131943, lng: 19.7658481 },
    { lat: 49.3129249, lng: 19.7658173 },
    { lat: 49.3122709, lng: 19.7657489 },
    { lat: 49.3121161, lng: 19.7657675 },
    { lat: 49.3116363, lng: 19.7658453 },
    { lat: 49.3113802, lng: 19.7658623 },
    { lat: 49.3096213, lng: 19.7651979 },
    { lat: 49.3082345, lng: 19.7645496 },
    { lat: 49.3075676, lng: 19.7645483 },
    { lat: 49.3067845, lng: 19.7645392 },
    { lat: 49.3067831, lng: 19.7645617 },
    { lat: 49.3066765, lng: 19.7645675 },
    { lat: 49.3060643, lng: 19.7646364 },
    { lat: 49.3045938, lng: 19.7650666 },
    { lat: 49.3045448, lng: 19.7650799 },
    { lat: 49.3035709, lng: 19.7653749 },
    { lat: 49.3029183, lng: 19.765419 },
    { lat: 49.3026951, lng: 19.7653081 },
    { lat: 49.3020513, lng: 19.7649557 },
    { lat: 49.3016382, lng: 19.7647326 },
    { lat: 49.3015025, lng: 19.764736 },
    { lat: 49.3005614, lng: 19.7647598 },
    { lat: 49.300112, lng: 19.7647826 },
    { lat: 49.3003266, lng: 19.7652698 },
    { lat: 49.300535, lng: 19.7658904 },
    { lat: 49.3008965, lng: 19.7670583 },
    { lat: 49.3011872, lng: 19.768233 },
    { lat: 49.3015243, lng: 19.7694117 },
    { lat: 49.3020165, lng: 19.7704487 },
    { lat: 49.3013261, lng: 19.7712073 },
    { lat: 49.3006535, lng: 19.7719572 },
    { lat: 49.3001178, lng: 19.7725438 },
    { lat: 49.2991322, lng: 19.7748604 },
    { lat: 49.2989884, lng: 19.7751821 },
    { lat: 49.2991211, lng: 19.7755159 },
    { lat: 49.2992581, lng: 19.7758196 },
    { lat: 49.299393, lng: 19.7758934 },
    { lat: 49.2994896, lng: 19.7759659 },
    { lat: 49.299684, lng: 19.7761592 },
    { lat: 49.299825, lng: 19.7764225 },
    { lat: 49.2999083, lng: 19.776527 },
    { lat: 49.3001693, lng: 19.7766775 },
    { lat: 49.3004084, lng: 19.7772074 },
    { lat: 49.3005535, lng: 19.7773793 },
    { lat: 49.3006368, lng: 19.7777723 },
    { lat: 49.3006429, lng: 19.7780727 },
    { lat: 49.3006666, lng: 19.7782775 },
    { lat: 49.3008709, lng: 19.7785478 },
    { lat: 49.301163, lng: 19.7786652 },
    { lat: 49.3016447, lng: 19.7793568 },
    { lat: 49.3018113, lng: 19.7799511 },
    { lat: 49.3020122, lng: 19.780143 },
    { lat: 49.3020287, lng: 19.780372 },
    { lat: 49.3022394, lng: 19.7807373 },
    { lat: 49.3024747, lng: 19.7809705 },
    { lat: 49.3025958, lng: 19.7812449 },
    { lat: 49.3026682, lng: 19.7814845 },
    { lat: 49.3027019, lng: 19.7817172 },
    { lat: 49.3027065, lng: 19.7818517 },
    { lat: 49.3027077, lng: 19.7821898 },
    { lat: 49.3027768, lng: 19.7824088 },
    { lat: 49.3030054, lng: 19.7828755 },
    { lat: 49.3031112, lng: 19.7831388 },
    { lat: 49.3032857, lng: 19.7836818 },
    { lat: 49.3034555, lng: 19.7840393 },
    { lat: 49.303501, lng: 19.7844419 },
    { lat: 49.3035257, lng: 19.7848567 },
    { lat: 49.3035659, lng: 19.7850057 },
    { lat: 49.3037428, lng: 19.7852159 },
    { lat: 49.3038503, lng: 19.7856187 },
    { lat: 49.3039179, lng: 19.7866385 },
    { lat: 49.303094, lng: 19.7865253 },
    { lat: 49.3022979, lng: 19.7866085 },
    { lat: 49.3019703, lng: 19.7866376 },
    { lat: 49.3014268, lng: 19.786678 },
    { lat: 49.3005545, lng: 19.786588 },
    { lat: 49.3004377, lng: 19.786598 },
    { lat: 49.2996721, lng: 19.7867103 },
    { lat: 49.2989531, lng: 19.786728 },
    { lat: 49.299096, lng: 19.7878187 },
    { lat: 49.2992414, lng: 19.7891045 },
    { lat: 49.2994041, lng: 19.7903929 },
    { lat: 49.3000819, lng: 19.7902596 },
    { lat: 49.3009561, lng: 19.7901036 },
    { lat: 49.3018837, lng: 19.7899324 },
    { lat: 49.3028339, lng: 19.7897875 },
    { lat: 49.3037068, lng: 19.7896089 },
    { lat: 49.3046021, lng: 19.7894436 },
    { lat: 49.3053271, lng: 19.789304 },
    { lat: 49.3057738, lng: 19.7906542 },
    { lat: 49.3067421, lng: 19.7920806 },
    { lat: 49.3072112, lng: 19.7929264 },
    { lat: 49.3077484, lng: 19.7940766 },
    { lat: 49.3077993, lng: 19.7939758 },
    { lat: 49.3081369, lng: 19.7934172 },
    { lat: 49.3082189, lng: 19.7923404 },
    { lat: 49.3081513, lng: 19.791713 },
    { lat: 49.3082973, lng: 19.7886514 },
    { lat: 49.3087382, lng: 19.7875333 },
    { lat: 49.3089133, lng: 19.7845164 },
    { lat: 49.3092632, lng: 19.7838587 },
    { lat: 49.3102351, lng: 19.7825216 },
    { lat: 49.3118104, lng: 19.7820112 },
    { lat: 49.3147106, lng: 19.7803105 },
    { lat: 49.3177203, lng: 19.7781956 },
    { lat: 49.3179412, lng: 19.7776475 },
    { lat: 49.3184896, lng: 19.7769934 },
    { lat: 49.3204054, lng: 19.7744627 },
    { lat: 49.3204199, lng: 19.7744316 },
    { lat: 49.3215264, lng: 19.7723549 },
    { lat: 49.3228584, lng: 19.7699227 },
    { lat: 49.3243393, lng: 19.7646827 },
    { lat: 49.3299385, lng: 19.7593714 },
    { lat: 49.3323145, lng: 19.7589633 },
    { lat: 49.3323584, lng: 19.7589629 },
    { lat: 49.3329693, lng: 19.7592292 },
    { lat: 49.3340709, lng: 19.7592564 },
    { lat: 49.3350661, lng: 19.7590893 },
    { lat: 49.3359077, lng: 19.7585209 },
    { lat: 49.3371369, lng: 19.7571513 },
    { lat: 49.3379702, lng: 19.7565494 },
    { lat: 49.3382769, lng: 19.7562409 },
    { lat: 49.3389028, lng: 19.7555845 },
    { lat: 49.3390464, lng: 19.7554395 },
    { lat: 49.3393071, lng: 19.755115 },
    { lat: 49.3399278, lng: 19.7550866 },
    { lat: 49.341037, lng: 19.7552625 },
    { lat: 49.3414195, lng: 19.7552526 },
    { lat: 49.3432804, lng: 19.7549898 },
    { lat: 49.3435471, lng: 19.7549085 },
    { lat: 49.3451026, lng: 19.7544401 },
    { lat: 49.3456786, lng: 19.7545492 },
    { lat: 49.3460485, lng: 19.7545611 },
    { lat: 49.3468656, lng: 19.7542883 },
    { lat: 49.347682, lng: 19.7541497 },
    { lat: 49.3477135, lng: 19.7541465 },
    { lat: 49.3483507, lng: 19.7540352 },
    { lat: 49.3486011, lng: 19.7542518 },
    { lat: 49.3486272, lng: 19.7542824 },
    { lat: 49.349258, lng: 19.7542573 },
    { lat: 49.3497883, lng: 19.7542023 },
    { lat: 49.3500332, lng: 19.7542095 },
    { lat: 49.3504244, lng: 19.7541145 },
    { lat: 49.3505419, lng: 19.754048 },
    { lat: 49.3505933, lng: 19.7541118 },
    { lat: 49.3505931, lng: 19.7542382 },
    { lat: 49.351677, lng: 19.7548413 },
    { lat: 49.3530074, lng: 19.755049 },
    { lat: 49.3530082, lng: 19.7550959 },
    { lat: 49.3535033, lng: 19.7550062 },
    { lat: 49.3539105, lng: 19.7548643 },
    { lat: 49.3542474, lng: 19.7546595 },
    { lat: 49.3545025, lng: 19.754463 },
    { lat: 49.3547971, lng: 19.7539287 },
    { lat: 49.3550285, lng: 19.7534741 },
    { lat: 49.3551985, lng: 19.7530073 },
    { lat: 49.3554777, lng: 19.7524838 },
    { lat: 49.3560147, lng: 19.7519573 },
    { lat: 49.3566422, lng: 19.7520886 },
    { lat: 49.3567701, lng: 19.7518158 },
    { lat: 49.3570516, lng: 19.7516824 },
    { lat: 49.3573693, lng: 19.7514271 },
    { lat: 49.3575422, lng: 19.7511522 },
    { lat: 49.3578136, lng: 19.7507108 },
    { lat: 49.3580337, lng: 19.749784 },
    { lat: 49.3581982, lng: 19.74875 },
    { lat: 49.358311, lng: 19.7478398 },
    { lat: 49.3584108, lng: 19.7466111 },
    { lat: 49.358467, lng: 19.7456282 },
    { lat: 49.3585423, lng: 19.7447637 },
    { lat: 49.3586203, lng: 19.7440215 },
    { lat: 49.3586229, lng: 19.7439786 },
    { lat: 49.358705, lng: 19.7434567 },
    { lat: 49.3589539, lng: 19.7427523 },
    { lat: 49.3594064, lng: 19.7418397 },
    { lat: 49.359583, lng: 19.7414711 },
    { lat: 49.3599064, lng: 19.7414447 },
    { lat: 49.3600853, lng: 19.7413137 },
    { lat: 49.3605039, lng: 19.7412244 },
    { lat: 49.3611659, lng: 19.7413467 },
    { lat: 49.3618318, lng: 19.7414823 },
    { lat: 49.3621443, lng: 19.7415404 },
    { lat: 49.3631099, lng: 19.7419628 },
    { lat: 49.3635512, lng: 19.7422177 },
    { lat: 49.3636013, lng: 19.742072 },
    { lat: 49.3639991, lng: 19.7410141 },
    { lat: 49.3645539, lng: 19.7406623 },
    { lat: 49.3648993, lng: 19.7404207 },
    { lat: 49.3654937, lng: 19.7399973 },
    { lat: 49.3658702, lng: 19.7393839 },
    { lat: 49.3659639, lng: 19.7390259 },
    { lat: 49.3659818, lng: 19.7389424 },
    { lat: 49.3660696, lng: 19.7386067 },
    { lat: 49.3661004, lng: 19.7384615 },
    { lat: 49.3661108, lng: 19.7383658 },
    { lat: 49.3661361, lng: 19.7383223 },
    { lat: 49.3664538, lng: 19.7376502 },
    { lat: 49.3673122, lng: 19.7370819 },
    { lat: 49.3678177, lng: 19.7367936 },
    { lat: 49.368097, lng: 19.7370753 },
    { lat: 49.368432, lng: 19.7370837 },
    { lat: 49.3687055, lng: 19.7371852 },
    { lat: 49.3694778, lng: 19.7375853 },
    { lat: 49.3696869, lng: 19.7377578 },
    { lat: 49.369866, lng: 19.73781 },
    { lat: 49.3700255, lng: 19.7380579 },
    { lat: 49.3701999, lng: 19.7381341 },
    { lat: 49.3703146, lng: 19.7383606 },
    { lat: 49.3705178, lng: 19.7385517 },
    { lat: 49.3708457, lng: 19.7388945 },
    { lat: 49.3709144, lng: 19.7392031 },
    { lat: 49.3710934, lng: 19.7392553 },
    { lat: 49.3713025, lng: 19.7387258 },
    { lat: 49.3716712, lng: 19.7384225 },
    { lat: 49.3718401, lng: 19.7384689 },
    { lat: 49.3718334, lng: 19.7385263 },
    { lat: 49.3719281, lng: 19.7386038 },
    { lat: 49.3720542, lng: 19.738579 },
    { lat: 49.3721387, lng: 19.7384749 },
    { lat: 49.3721697, lng: 19.7384117 },
    { lat: 49.3721969, lng: 19.7383096 },
    { lat: 49.3721165, lng: 19.7381215 },
    { lat: 49.3721656, lng: 19.7378776 },
    { lat: 49.3722603, lng: 19.7378502 },
    { lat: 49.3723837, lng: 19.7378943 },
    { lat: 49.3724692, lng: 19.7380194 },
    { lat: 49.3725416, lng: 19.738153 },
    { lat: 49.3725489, lng: 19.7382455 },
    { lat: 49.372587, lng: 19.7383179 },
    { lat: 49.3726409, lng: 19.7382874 },
    { lat: 49.3728169, lng: 19.738242 },
    { lat: 49.3730313, lng: 19.7383795 },
    { lat: 49.3730808, lng: 19.7383774 },
    { lat: 49.3731785, lng: 19.7382423 },
    { lat: 49.3731878, lng: 19.7382219 },
    { lat: 49.3732009, lng: 19.7381388 },
    { lat: 49.3731741, lng: 19.7380646 },
    { lat: 49.3731156, lng: 19.7380156 },
    { lat: 49.373011, lng: 19.7378636 },
    { lat: 49.3729926, lng: 19.7375922 },
    { lat: 49.3730176, lng: 19.7374946 },
    { lat: 49.3730022, lng: 19.7373733 },
    { lat: 49.3728771, lng: 19.7372985 },
    { lat: 49.3727313, lng: 19.7374143 },
    { lat: 49.3726033, lng: 19.7373464 },
    { lat: 49.3724853, lng: 19.7374021 },
    { lat: 49.3723876, lng: 19.7373593 },
    { lat: 49.3722787, lng: 19.7371619 },
    { lat: 49.3722751, lng: 19.7369479 },
    { lat: 49.3723197, lng: 19.7367322 },
    { lat: 49.3723995, lng: 19.7365199 },
    { lat: 49.3725126, lng: 19.7364501 },
    { lat: 49.3725948, lng: 19.7363745 },
    { lat: 49.372677, lng: 19.7363712 },
    { lat: 49.3727993, lng: 19.7364653 },
    { lat: 49.3728841, lng: 19.7365734 },
    { lat: 49.3730134, lng: 19.7365942 },
    { lat: 49.3730894, lng: 19.7365554 },
    { lat: 49.3731621, lng: 19.7364909 },
    { lat: 49.3732727, lng: 19.7363418 },
    { lat: 49.3732753, lng: 19.7362234 },
    { lat: 49.3733518, lng: 19.7360395 },
    { lat: 49.3734421, lng: 19.7358843 },
    { lat: 49.3736908, lng: 19.735779 },
    { lat: 49.3738836, lng: 19.7358045 },
    { lat: 49.3740995, lng: 19.7357709 },
    { lat: 49.374185, lng: 19.7357441 },
    { lat: 49.3742821, lng: 19.7356988 },
    { lat: 49.3743986, lng: 19.735658 },
    { lat: 49.3745007, lng: 19.7355413 },
    { lat: 49.3745333, lng: 19.7354873 },
    { lat: 49.3745646, lng: 19.7353815 },
    { lat: 49.3745672, lng: 19.73534 },
    { lat: 49.3744791, lng: 19.7352575 },
    { lat: 49.3744304, lng: 19.7351953 },
    { lat: 49.3744151, lng: 19.7351574 },
    { lat: 49.3743958, lng: 19.7350249 },
    { lat: 49.3744501, lng: 19.7349482 },
    { lat: 49.3745262, lng: 19.7349118 },
    { lat: 49.3746573, lng: 19.7349469 },
    { lat: 49.3747807, lng: 19.7350153 },
    { lat: 49.3748594, lng: 19.7351439 },
    { lat: 49.3749192, lng: 19.7353243 },
    { lat: 49.3749648, lng: 19.7353356 },
    { lat: 49.374998, lng: 19.7353242 },
    { lat: 49.3750357, lng: 19.7353598 },
    { lat: 49.3750619, lng: 19.7354742 },
    { lat: 49.3750348, lng: 19.735545 },
    { lat: 49.3748611, lng: 19.7355945 },
    { lat: 49.3748002, lng: 19.7356972 },
    { lat: 49.3747796, lng: 19.7358168 },
    { lat: 49.3748106, lng: 19.7358838 },
    { lat: 49.3749309, lng: 19.7360053 },
    { lat: 49.374953, lng: 19.735999 },
    { lat: 49.3750582, lng: 19.735878 },
    { lat: 49.3751933, lng: 19.7358256 },
    { lat: 49.375284, lng: 19.7357603 },
    { lat: 49.3753273, lng: 19.7355892 },
    { lat: 49.3753373, lng: 19.7353521 },
    { lat: 49.3753365, lng: 19.7352036 },
    { lat: 49.3753016, lng: 19.73508 },
    { lat: 49.3752493, lng: 19.7349367 },
    { lat: 49.3752717, lng: 19.7347793 },
    { lat: 49.3753201, lng: 19.7346757 },
    { lat: 49.3753838, lng: 19.734637 },
    { lat: 49.3754535, lng: 19.7347369 },
    { lat: 49.3755032, lng: 19.7347387 },
    { lat: 49.3755593, lng: 19.7346838 },
    { lat: 49.3756393, lng: 19.7345802 },
    { lat: 49.3757527, lng: 19.734521 },
    { lat: 49.3759138, lng: 19.7346487 },
    { lat: 49.3759761, lng: 19.7347093 },
    { lat: 49.3760088, lng: 19.7348426 },
    { lat: 49.3758453, lng: 19.7350725 },
    { lat: 49.3760423, lng: 19.7354537 },
    { lat: 49.3762188, lng: 19.7355991 },
    { lat: 49.3765652, lng: 19.7354896 },
    { lat: 49.3767324, lng: 19.7353659 },
    { lat: 49.3767931, lng: 19.7352086 },
    { lat: 49.3767683, lng: 19.7351048 },
    { lat: 49.3767635, lng: 19.7349401 },
    { lat: 49.3767742, lng: 19.7348017 },
    { lat: 49.3768315, lng: 19.734747 },
    { lat: 49.3769627, lng: 19.7347351 },
    { lat: 49.377097, lng: 19.734892 },
    { lat: 49.3771902, lng: 19.7351193 },
    { lat: 49.3773227, lng: 19.735186 },
    { lat: 49.377424, lng: 19.7350824 },
    { lat: 49.3774738, lng: 19.7349576 },
    { lat: 49.3774799, lng: 19.7347625 },
    { lat: 49.3775473, lng: 19.7343967 },
    { lat: 49.3776489, lng: 19.7341922 },
    { lat: 49.377675, lng: 19.7341729 },
    { lat: 49.3777741, lng: 19.7341935 },
    { lat: 49.3778909, lng: 19.734243 },
    { lat: 49.3779928, lng: 19.7340732 },
    { lat: 49.3780908, lng: 19.7338398 },
    { lat: 49.3782489, lng: 19.7335619 },
    { lat: 49.3783372, lng: 19.7332868 },
    { lat: 49.3783349, lng: 19.7330542 },
    { lat: 49.3781429, lng: 19.7329386 },
    { lat: 49.3781059, lng: 19.732891 },
    { lat: 49.3781046, lng: 19.7327025 },
    { lat: 49.3781661, lng: 19.7325921 },
    { lat: 49.3783288, lng: 19.7324757 },
    { lat: 49.378464, lng: 19.7323216 },
    { lat: 49.3786226, lng: 19.7321857 },
    { lat: 49.3788734, lng: 19.7322538 },
    { lat: 49.3790286, lng: 19.732094 },
    { lat: 49.3791562, lng: 19.7318417 },
    { lat: 49.3792539, lng: 19.7318078 },
    { lat: 49.3793796, lng: 19.7318973 },
    { lat: 49.3796035, lng: 19.7321293 },
    { lat: 49.3798302, lng: 19.7322135 },
    { lat: 49.379877, lng: 19.7321979 },
    { lat: 49.3799252, lng: 19.7320136 },
    { lat: 49.3800559, lng: 19.73165 },
    { lat: 49.380208, lng: 19.7313416 },
    { lat: 49.3804947, lng: 19.7311239 },
    { lat: 49.3809942, lng: 19.7306687 },
    { lat: 49.3809892, lng: 19.7305528 },
    { lat: 49.3809363, lng: 19.7304219 },
    { lat: 49.3808895, lng: 19.7301013 },
    { lat: 49.3809059, lng: 19.7298553 },
    { lat: 49.3810397, lng: 19.7295119 },
    { lat: 49.3811394, lng: 19.7294496 },
    { lat: 49.381457, lng: 19.7290831 },
    { lat: 49.3809061, lng: 19.7292623 },
    { lat: 49.379896, lng: 19.7271754 },
    { lat: 49.3791879, lng: 19.7260768 },
    { lat: 49.3785335, lng: 19.7248625 },
    { lat: 49.3777737, lng: 19.7227072 },
    { lat: 49.3771173, lng: 19.7218601 },
    { lat: 49.3768011, lng: 19.7214547 },
    { lat: 49.3760799, lng: 19.7205455 },
    { lat: 49.3757581, lng: 19.7201422 },
    { lat: 49.3746115, lng: 19.7186968 },
    { lat: 49.3737392, lng: 19.7174222 },
    { lat: 49.3728783, lng: 19.7158917 },
    { lat: 49.37228, lng: 19.7148078 },
    { lat: 49.3712672, lng: 19.7134173 },
    { lat: 49.3703356, lng: 19.7121212 },
    { lat: 49.3692752, lng: 19.7108025 },
    { lat: 49.3682398, lng: 19.7094835 },
    { lat: 49.3672769, lng: 19.7080197 },
    { lat: 49.3665701, lng: 19.7077008 },
    { lat: 49.3664151, lng: 19.7076332 },
    { lat: 49.3651483, lng: 19.7067718 },
    { lat: 49.3645037, lng: 19.7064541 },
    { lat: 49.3640456, lng: 19.706229 },
    { lat: 49.3639906, lng: 19.7062895 },
    { lat: 49.3639183, lng: 19.7063082 },
    { lat: 49.363936, lng: 19.7064542 },
    { lat: 49.3640218, lng: 19.7068719 },
    { lat: 49.3640426, lng: 19.7070128 },
    { lat: 49.3640444, lng: 19.7072866 },
    { lat: 49.3640047, lng: 19.7074584 },
    { lat: 49.3638678, lng: 19.7078852 },
    { lat: 49.3637421, lng: 19.7080752 },
    { lat: 49.363571, lng: 19.7082543 },
    { lat: 49.3634238, lng: 19.7082686 },
    { lat: 49.3630266, lng: 19.7083499 },
    { lat: 49.3628829, lng: 19.7084152 },
    { lat: 49.3627163, lng: 19.7086007 },
    { lat: 49.3626205, lng: 19.7089572 },
    { lat: 49.3625534, lng: 19.7092532 },
    { lat: 49.3624569, lng: 19.709777 },
    { lat: 49.3624142, lng: 19.7098551 },
    { lat: 49.3622374, lng: 19.7100864 },
    { lat: 49.3619953, lng: 19.7102988 },
    { lat: 49.3616702, lng: 19.7103564 },
    { lat: 49.3613657, lng: 19.7103515 },
    { lat: 49.3611361, lng: 19.7104336 },
    { lat: 49.3609679, lng: 19.7106051 },
    { lat: 49.3609155, lng: 19.7106951 },
    { lat: 49.3608688, lng: 19.7108379 },
    { lat: 49.3608766, lng: 19.7109896 },
    { lat: 49.3609461, lng: 19.7113959 },
    { lat: 49.3610497, lng: 19.7118812 },
    { lat: 49.3611525, lng: 19.7122237 },
    { lat: 49.3612465, lng: 19.7124421 },
    { lat: 49.3613249, lng: 19.712588 },
    { lat: 49.3613396, lng: 19.7126639 },
    { lat: 49.3613388, lng: 19.7127225 },
    { lat: 49.3613254, lng: 19.7128048 },
    { lat: 49.3612217, lng: 19.7131924 },
    { lat: 49.3611655, lng: 19.7135534 },
    { lat: 49.3611535, lng: 19.7136681 },
    { lat: 49.3611568, lng: 19.7138178 },
    { lat: 49.3611754, lng: 19.7139857 },
    { lat: 49.3615935, lng: 19.714559 },
    { lat: 49.3617739, lng: 19.714868 },
    { lat: 49.3618106, lng: 19.7150164 },
    { lat: 49.3616997, lng: 19.7153899 },
    { lat: 49.3615519, lng: 19.7155615 },
    { lat: 49.3614119, lng: 19.7156579 },
    { lat: 49.3613618, lng: 19.7156735 },
    { lat: 49.3611885, lng: 19.715679 },
    { lat: 49.3610111, lng: 19.7156635 },
    { lat: 49.3598793, lng: 19.7154394 },
    { lat: 49.3597536, lng: 19.7154526 },
    { lat: 49.3594411, lng: 19.7155927 },
    { lat: 49.3593628, lng: 19.7156325 },
    { lat: 49.3593385, lng: 19.7155143 },
    { lat: 49.3592297, lng: 19.7154506 },
    { lat: 49.3589504, lng: 19.7152429 },
    { lat: 49.3587572, lng: 19.7150733 },
    { lat: 49.3585593, lng: 19.7149306 },
    { lat: 49.3582259, lng: 19.714723 },
    { lat: 49.3580979, lng: 19.7146581 },
    { lat: 49.3578358, lng: 19.7146387 },
    { lat: 49.3574742, lng: 19.7145865 },
    { lat: 49.3569402, lng: 19.7144822 },
    { lat: 49.3565841, lng: 19.7144308 },
    { lat: 49.3561916, lng: 19.7144429 },
    { lat: 49.3559431, lng: 19.7144752 },
    { lat: 49.3557444, lng: 19.7144927 },
    { lat: 49.3556309, lng: 19.7139066 },
    { lat: 49.3556115, lng: 19.7137742 },
    { lat: 49.3551847, lng: 19.713802 },
    { lat: 49.354792, lng: 19.7138148 },
    { lat: 49.3545977, lng: 19.7138253 },
    { lat: 49.3545201, lng: 19.7139832 },
    { lat: 49.3544255, lng: 19.7141403 },
    { lat: 49.3541761, lng: 19.7145908 },
    { lat: 49.3539794, lng: 19.7149365 },
    { lat: 49.3537565, lng: 19.7149937 },
    { lat: 49.353472, lng: 19.7154676 },
    { lat: 49.3535868, lng: 19.7157721 },
    { lat: 49.3534689, lng: 19.7158065 },
    { lat: 49.3531261, lng: 19.7159309 },
    { lat: 49.3531458, lng: 19.716223 },
    { lat: 49.3529707, lng: 19.716371 },
    { lat: 49.3526059, lng: 19.7164763 },
    { lat: 49.3526723, lng: 19.716657 },
    { lat: 49.3524626, lng: 19.7168339 },
    { lat: 49.3521912, lng: 19.7170124 },
    { lat: 49.3519326, lng: 19.7171288 },
    { lat: 49.3517416, lng: 19.7171924 },
    { lat: 49.351906, lng: 19.7176815 },
    { lat: 49.352155, lng: 19.7182478 },
    { lat: 49.3522107, lng: 19.7183621 },
    { lat: 49.3523923, lng: 19.718677 },
    { lat: 49.3520512, lng: 19.7189912 },
    { lat: 49.3519205, lng: 19.7191213 },
    { lat: 49.3513891, lng: 19.7196187 },
    { lat: 49.3512519, lng: 19.7197509 },
    { lat: 49.3511762, lng: 19.7196181 },
    { lat: 49.3510313, lng: 19.7196792 },
    { lat: 49.3506407, lng: 19.7198647 },
    { lat: 49.3502424, lng: 19.7201104 },
    { lat: 49.3498234, lng: 19.720727 },
    { lat: 49.349502, lng: 19.7207187 },
    { lat: 49.3493996, lng: 19.7209033 },
    { lat: 49.3489184, lng: 19.7206137 },
    { lat: 49.3487884, lng: 19.7205287 },
    { lat: 49.3487755, lng: 19.7205152 },
    { lat: 49.3482252, lng: 19.7201181 },
    { lat: 49.3476693, lng: 19.7198682 },
    { lat: 49.3476307, lng: 19.7198386 },
    { lat: 49.3475951, lng: 19.7198722 },
    { lat: 49.3470383, lng: 19.7203074 },
    { lat: 49.3468331, lng: 19.7206334 },
    { lat: 49.3466599, lng: 19.7208476 },
    { lat: 49.3465257, lng: 19.7209473 },
    { lat: 49.3462751, lng: 19.7212992 },
    { lat: 49.3460831, lng: 19.7215197 },
    { lat: 49.3459082, lng: 19.7216655 },
    { lat: 49.3457878, lng: 19.7217458 },
    { lat: 49.345615, lng: 19.721818 },
    { lat: 49.3454778, lng: 19.7217934 },
    { lat: 49.3451573, lng: 19.7217799 },
    { lat: 49.3449035, lng: 19.7216967 },
    { lat: 49.3447147, lng: 19.7217833 },
    { lat: 49.3441335, lng: 19.7217065 },
    { lat: 49.3440117, lng: 19.7217287 },
    { lat: 49.3436522, lng: 19.7219559 },
    { lat: 49.3432705, lng: 19.7220639 },
    { lat: 49.3431379, lng: 19.722072 },
    { lat: 49.3426827, lng: 19.7215287 },
    { lat: 49.3426126, lng: 19.721495 },
    { lat: 49.3424297, lng: 19.7214379 },
    { lat: 49.3422659, lng: 19.7214275 },
    { lat: 49.3421557, lng: 19.7214016 },
    { lat: 49.3420937, lng: 19.7213479 },
    { lat: 49.3419687, lng: 19.7212758 },
    { lat: 49.3417022, lng: 19.7211824 },
  ],
};

export default UnitsTvrdošín;
