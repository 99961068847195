const UnitsRimavskáSobota = {
  Gortva: [
    { lat: 48.2931609, lng: 20.0399273 },
    { lat: 48.292721, lng: 20.039455 },
    { lat: 48.292295, lng: 20.0386665 },
    { lat: 48.2925759, lng: 20.0382702 },
    { lat: 48.2931925, lng: 20.037612 },
    { lat: 48.2934508, lng: 20.0372135 },
    { lat: 48.2924199, lng: 20.0362113 },
    { lat: 48.2922853, lng: 20.036012 },
    { lat: 48.2921471, lng: 20.0356423 },
    { lat: 48.2919877, lng: 20.0345749 },
    { lat: 48.2919942, lng: 20.03398 },
    { lat: 48.2918687, lng: 20.0333788 },
    { lat: 48.291599, lng: 20.0331229 },
    { lat: 48.2914292, lng: 20.0328089 },
    { lat: 48.2910919, lng: 20.0327153 },
    { lat: 48.2908541, lng: 20.0323334 },
    { lat: 48.2906668, lng: 20.0317624 },
    { lat: 48.2899168, lng: 20.030318 },
    { lat: 48.2898225, lng: 20.0303491 },
    { lat: 48.2892839, lng: 20.0308851 },
    { lat: 48.2889676, lng: 20.0306757 },
    { lat: 48.2886247, lng: 20.0308261 },
    { lat: 48.2884558, lng: 20.0316608 },
    { lat: 48.2877929, lng: 20.0321568 },
    { lat: 48.2876541, lng: 20.0322349 },
    { lat: 48.2868348, lng: 20.0321178 },
    { lat: 48.2868603, lng: 20.0335029 },
    { lat: 48.2865589, lng: 20.0339999 },
    { lat: 48.286434, lng: 20.0340629 },
    { lat: 48.2859645, lng: 20.0341466 },
    { lat: 48.2856264, lng: 20.0340316 },
    { lat: 48.2851891, lng: 20.0341081 },
    { lat: 48.2851939, lng: 20.0341709 },
    { lat: 48.2847861, lng: 20.0341679 },
    { lat: 48.2839749, lng: 20.0335718 },
    { lat: 48.283174, lng: 20.0334939 },
    { lat: 48.282947, lng: 20.0332641 },
    { lat: 48.2828699, lng: 20.0325793 },
    { lat: 48.282922, lng: 20.0323463 },
    { lat: 48.2823825, lng: 20.0321066 },
    { lat: 48.2818922, lng: 20.031694 },
    { lat: 48.2802625, lng: 20.0311629 },
    { lat: 48.2797246, lng: 20.0305561 },
    { lat: 48.2789504, lng: 20.0300882 },
    { lat: 48.2784614, lng: 20.0299287 },
    { lat: 48.277534, lng: 20.0298108 },
    { lat: 48.2773868, lng: 20.0298473 },
    { lat: 48.2771815, lng: 20.0297849 },
    { lat: 48.2769545, lng: 20.0296121 },
    { lat: 48.2766809, lng: 20.0289705 },
    { lat: 48.2764876, lng: 20.027558 },
    { lat: 48.2764592, lng: 20.0266112 },
    { lat: 48.275819, lng: 20.0262779 },
    { lat: 48.2753626, lng: 20.0257405 },
    { lat: 48.2745134, lng: 20.0243982 },
    { lat: 48.2742188, lng: 20.0241166 },
    { lat: 48.2739195, lng: 20.0239368 },
    { lat: 48.2730807, lng: 20.0230618 },
    { lat: 48.2728089, lng: 20.0229453 },
    { lat: 48.272462, lng: 20.0230561 },
    { lat: 48.2721288, lng: 20.0230302 },
    { lat: 48.2718921, lng: 20.0228396 },
    { lat: 48.2716454, lng: 20.0224611 },
    { lat: 48.2711146, lng: 20.0218676 },
    { lat: 48.2709436, lng: 20.021394 },
    { lat: 48.2708281, lng: 20.0208406 },
    { lat: 48.2708383, lng: 20.0201169 },
    { lat: 48.2707284, lng: 20.0197538 },
    { lat: 48.2706297, lng: 20.0196284 },
    { lat: 48.2695687, lng: 20.0193883 },
    { lat: 48.2691207, lng: 20.0188892 },
    { lat: 48.2689147, lng: 20.0184351 },
    { lat: 48.2687338, lng: 20.0181792 },
    { lat: 48.2685, lng: 20.0179746 },
    { lat: 48.2680543, lng: 20.0173969 },
    { lat: 48.2672688, lng: 20.0168815 },
    { lat: 48.2668936, lng: 20.0165025 },
    { lat: 48.2665185, lng: 20.0163392 },
    { lat: 48.265932, lng: 20.0163531 },
    { lat: 48.265533, lng: 20.0164802 },
    { lat: 48.2646998, lng: 20.0164866 },
    { lat: 48.2638522, lng: 20.0167674 },
    { lat: 48.2635599, lng: 20.0167055 },
    { lat: 48.2633397, lng: 20.016582 },
    { lat: 48.2630195, lng: 20.0162304 },
    { lat: 48.2623725, lng: 20.0157872 },
    { lat: 48.2621506, lng: 20.0155511 },
    { lat: 48.2616295, lng: 20.0146507 },
    { lat: 48.259978, lng: 20.0143504 },
    { lat: 48.2597132, lng: 20.0143231 },
    { lat: 48.2591551, lng: 20.0144454 },
    { lat: 48.2588102, lng: 20.0144387 },
    { lat: 48.2580959, lng: 20.0146237 },
    { lat: 48.2573597, lng: 20.0150403 },
    { lat: 48.2570819, lng: 20.0150531 },
    { lat: 48.2566456, lng: 20.0149111 },
    { lat: 48.2563015, lng: 20.0149524 },
    { lat: 48.2560731, lng: 20.0150605 },
    { lat: 48.2564253, lng: 20.0153465 },
    { lat: 48.2568631, lng: 20.0158503 },
    { lat: 48.2570545, lng: 20.0161921 },
    { lat: 48.2572925, lng: 20.0169512 },
    { lat: 48.2573072, lng: 20.0172238 },
    { lat: 48.2576093, lng: 20.018435 },
    { lat: 48.2576515, lng: 20.0188998 },
    { lat: 48.257641, lng: 20.0209602 },
    { lat: 48.2579258, lng: 20.0211932 },
    { lat: 48.2583638, lng: 20.0216949 },
    { lat: 48.2586738, lng: 20.0223727 },
    { lat: 48.2588491, lng: 20.0230342 },
    { lat: 48.2590512, lng: 20.0245466 },
    { lat: 48.2589414, lng: 20.0263633 },
    { lat: 48.2585441, lng: 20.0274149 },
    { lat: 48.2583391, lng: 20.0285448 },
    { lat: 48.25842, lng: 20.0289996 },
    { lat: 48.2588601, lng: 20.0302517 },
    { lat: 48.2590596, lng: 20.0316533 },
    { lat: 48.2593981, lng: 20.0323369 },
    { lat: 48.2595926, lng: 20.0329228 },
    { lat: 48.2596928, lng: 20.0334044 },
    { lat: 48.2596562, lng: 20.033685 },
    { lat: 48.2596967, lng: 20.0339824 },
    { lat: 48.2598423, lng: 20.0345269 },
    { lat: 48.2602865, lng: 20.0339404 },
    { lat: 48.2608811, lng: 20.0324083 },
    { lat: 48.2619319, lng: 20.0312172 },
    { lat: 48.2626293, lng: 20.0289382 },
    { lat: 48.2628346, lng: 20.0284074 },
    { lat: 48.2629243, lng: 20.0283979 },
    { lat: 48.263665, lng: 20.0278223 },
    { lat: 48.264093, lng: 20.0277948 },
    { lat: 48.2645022, lng: 20.0278546 },
    { lat: 48.2646847, lng: 20.027748 },
    { lat: 48.2650469, lng: 20.0277351 },
    { lat: 48.2651, lng: 20.0278178 },
    { lat: 48.2653129, lng: 20.0276316 },
    { lat: 48.2657818, lng: 20.0275267 },
    { lat: 48.2660386, lng: 20.0273912 },
    { lat: 48.2660827, lng: 20.0274814 },
    { lat: 48.2664064, lng: 20.0273021 },
    { lat: 48.266429, lng: 20.0272233 },
    { lat: 48.2665387, lng: 20.0271546 },
    { lat: 48.2665776, lng: 20.0272478 },
    { lat: 48.2668921, lng: 20.0272773 },
    { lat: 48.2677673, lng: 20.027249 },
    { lat: 48.2699687, lng: 20.0277954 },
    { lat: 48.2704106, lng: 20.0282566 },
    { lat: 48.2723965, lng: 20.0287703 },
    { lat: 48.2727831, lng: 20.0289878 },
    { lat: 48.2729772, lng: 20.0292156 },
    { lat: 48.2733664, lng: 20.0300871 },
    { lat: 48.273945, lng: 20.0306684 },
    { lat: 48.2747132, lng: 20.0317143 },
    { lat: 48.2750734, lng: 20.0324623 },
    { lat: 48.2752321, lng: 20.0329997 },
    { lat: 48.2756117, lng: 20.0346921 },
    { lat: 48.2764323, lng: 20.0353775 },
    { lat: 48.2767856, lng: 20.0359896 },
    { lat: 48.2767378, lng: 20.0367834 },
    { lat: 48.2770751, lng: 20.0391634 },
    { lat: 48.2780727, lng: 20.0405826 },
    { lat: 48.279454, lng: 20.0416154 },
    { lat: 48.2819147, lng: 20.0415168 },
    { lat: 48.2840153, lng: 20.0417278 },
    { lat: 48.2854651, lng: 20.0403281 },
    { lat: 48.2857089, lng: 20.040209 },
    { lat: 48.2868845, lng: 20.0408146 },
    { lat: 48.2882902, lng: 20.0422166 },
    { lat: 48.288675, lng: 20.0425003 },
    { lat: 48.2888655, lng: 20.0425577 },
    { lat: 48.2907631, lng: 20.0426026 },
    { lat: 48.2908646, lng: 20.0425803 },
    { lat: 48.2912298, lng: 20.0422319 },
    { lat: 48.291491, lng: 20.0418275 },
    { lat: 48.2918016, lng: 20.0415504 },
    { lat: 48.2924054, lng: 20.0411708 },
    { lat: 48.2925777, lng: 20.0409845 },
    { lat: 48.2931609, lng: 20.0399273 },
  ],
  GortvaExt: [
    { lat: 48.2820919, lng: 19.9679026 },
    { lat: 48.2814687, lng: 19.9676094 },
    { lat: 48.2806475, lng: 19.9675536 },
    { lat: 48.2804334, lng: 19.9677105 },
    { lat: 48.2801647, lng: 19.9680201 },
    { lat: 48.2800585, lng: 19.9683835 },
    { lat: 48.2796464, lng: 19.9688408 },
    { lat: 48.2794165, lng: 19.9688068 },
    { lat: 48.2790946, lng: 19.9686037 },
    { lat: 48.2785039, lng: 19.9679074 },
    { lat: 48.276619, lng: 19.9671017 },
    { lat: 48.27564, lng: 19.9671554 },
    { lat: 48.2752981, lng: 19.9673255 },
    { lat: 48.2748046, lng: 19.9674553 },
    { lat: 48.273611, lng: 19.9683159 },
    { lat: 48.2724132, lng: 19.9684151 },
    { lat: 48.2720941, lng: 19.9685668 },
    { lat: 48.2717852, lng: 19.9688296 },
    { lat: 48.2713413, lng: 19.9694892 },
    { lat: 48.2709245, lng: 19.9707386 },
    { lat: 48.2707078, lng: 19.971768 },
    { lat: 48.270302, lng: 19.9718675 },
    { lat: 48.2704387, lng: 19.9726461 },
    { lat: 48.2704002, lng: 19.9733305 },
    { lat: 48.2704451, lng: 19.974201 },
    { lat: 48.2703652, lng: 19.9749204 },
    { lat: 48.2704133, lng: 19.9758716 },
    { lat: 48.2705779, lng: 19.9762972 },
    { lat: 48.2706647, lng: 19.9767794 },
    { lat: 48.2707488, lng: 19.9777275 },
    { lat: 48.2707675, lng: 19.9782759 },
    { lat: 48.2706114, lng: 19.9792161 },
    { lat: 48.2707697, lng: 19.979682 },
    { lat: 48.2707868, lng: 19.9815724 },
    { lat: 48.2713222, lng: 19.9824335 },
    { lat: 48.2718024, lng: 19.9830537 },
    { lat: 48.2721561, lng: 19.9837758 },
    { lat: 48.2724498, lng: 19.9849929 },
    { lat: 48.2724591, lng: 19.9855812 },
    { lat: 48.2725148, lng: 19.9858867 },
    { lat: 48.2730373, lng: 19.9876133 },
    { lat: 48.2731059, lng: 19.9881697 },
    { lat: 48.2734664, lng: 19.9891819 },
    { lat: 48.2743808, lng: 19.9903484 },
    { lat: 48.2747508, lng: 19.9909487 },
    { lat: 48.2749886, lng: 19.9916716 },
    { lat: 48.2752739, lng: 19.9930644 },
    { lat: 48.2754447, lng: 19.9933763 },
    { lat: 48.2760566, lng: 19.993991 },
    { lat: 48.2774048, lng: 19.9950571 },
    { lat: 48.2776098, lng: 19.9953789 },
    { lat: 48.2776344, lng: 19.9955032 },
    { lat: 48.277906, lng: 19.9958007 },
    { lat: 48.2780886, lng: 19.9966056 },
    { lat: 48.2784794, lng: 19.9973742 },
    { lat: 48.2785221, lng: 19.9975855 },
    { lat: 48.2792852, lng: 19.9982397 },
    { lat: 48.2797153, lng: 19.9994873 },
    { lat: 48.2799236, lng: 20.0006884 },
    { lat: 48.2802865, lng: 20.0015463 },
    { lat: 48.280528, lng: 20.0024946 },
    { lat: 48.2809037, lng: 20.0026925 },
    { lat: 48.2810437, lng: 20.0040653 },
    { lat: 48.2812368, lng: 20.0049853 },
    { lat: 48.2814005, lng: 20.0055045 },
    { lat: 48.281485, lng: 20.005319 },
    { lat: 48.2815336, lng: 20.0035811 },
    { lat: 48.2826247, lng: 20.0036781 },
    { lat: 48.2837374, lng: 20.0041016 },
    { lat: 48.2839743, lng: 20.004134 },
    { lat: 48.2849352, lng: 20.0040541 },
    { lat: 48.2850243, lng: 20.0035728 },
    { lat: 48.2855496, lng: 20.0039348 },
    { lat: 48.2856606, lng: 20.002691 },
    { lat: 48.2856613, lng: 20.0025746 },
    { lat: 48.2857133, lng: 20.0017459 },
    { lat: 48.2858769, lng: 20.0011928 },
    { lat: 48.2861215, lng: 19.9994069 },
    { lat: 48.286165, lng: 19.9988337 },
    { lat: 48.2861055, lng: 19.9973152 },
    { lat: 48.2863499, lng: 19.9960354 },
    { lat: 48.2863538, lng: 19.9957818 },
    { lat: 48.2865284, lng: 19.9951144 },
    { lat: 48.2868622, lng: 19.9945342 },
    { lat: 48.2871813, lng: 19.994302 },
    { lat: 48.2874825, lng: 19.9940962 },
    { lat: 48.288095, lng: 19.9939834 },
    { lat: 48.2886375, lng: 19.9935984 },
    { lat: 48.2890609, lng: 19.9924207 },
    { lat: 48.2890383, lng: 19.9900699 },
    { lat: 48.2890804, lng: 19.9884572 },
    { lat: 48.289034, lng: 19.9876502 },
    { lat: 48.2886582, lng: 19.98545 },
    { lat: 48.2887475, lng: 19.9847376 },
    { lat: 48.2887059, lng: 19.9843691 },
    { lat: 48.2884014, lng: 19.983772 },
    { lat: 48.2883434, lng: 19.9834945 },
    { lat: 48.2882289, lng: 19.9821988 },
    { lat: 48.2883565, lng: 19.9813264 },
    { lat: 48.2885732, lng: 19.9805393 },
    { lat: 48.2885496, lng: 19.9803275 },
    { lat: 48.2882758, lng: 19.9797169 },
    { lat: 48.2880396, lng: 19.9788982 },
    { lat: 48.2872719, lng: 19.9774492 },
    { lat: 48.2868866, lng: 19.9764116 },
    { lat: 48.2868168, lng: 19.9754544 },
    { lat: 48.2865042, lng: 19.9744064 },
    { lat: 48.2863736, lng: 19.9737973 },
    { lat: 48.2860817, lng: 19.9733147 },
    { lat: 48.2859162, lng: 19.9727094 },
    { lat: 48.2856022, lng: 19.9719173 },
    { lat: 48.2854207, lng: 19.9715674 },
    { lat: 48.2844865, lng: 19.9702854 },
    { lat: 48.2832831, lng: 19.969217 },
    { lat: 48.2827723, lng: 19.9685392 },
    { lat: 48.2821641, lng: 19.9679874 },
    { lat: 48.2820919, lng: 19.9679026 },
  ],
  GortvaExt1: [
    { lat: 48.3140455, lng: 20.0195939 },
    { lat: 48.3129298, lng: 20.0203892 },
    { lat: 48.3117612, lng: 20.0211009 },
    { lat: 48.3093612, lng: 20.0213279 },
    { lat: 48.3084774, lng: 20.021126 },
    { lat: 48.3081903, lng: 20.0208881 },
    { lat: 48.308191, lng: 20.0206056 },
    { lat: 48.3072768, lng: 20.0207293 },
    { lat: 48.3047682, lng: 20.0213971 },
    { lat: 48.3044784, lng: 20.0209093 },
    { lat: 48.3031092, lng: 20.0215422 },
    { lat: 48.3010192, lng: 20.0220431 },
    { lat: 48.3009212, lng: 20.0214806 },
    { lat: 48.3006506, lng: 20.0204536 },
    { lat: 48.3001139, lng: 20.0204394 },
    { lat: 48.299928, lng: 20.0202493 },
    { lat: 48.2996665, lng: 20.0198175 },
    { lat: 48.2996683, lng: 20.0192428 },
    { lat: 48.2987021, lng: 20.0190318 },
    { lat: 48.2976896, lng: 20.0184512 },
    { lat: 48.2972425, lng: 20.0180756 },
    { lat: 48.2968037, lng: 20.0179008 },
    { lat: 48.2957583, lng: 20.0168801 },
    { lat: 48.2952167, lng: 20.0166471 },
    { lat: 48.2946392, lng: 20.0165764 },
    { lat: 48.2939508, lng: 20.0166746 },
    { lat: 48.2929047, lng: 20.0163912 },
    { lat: 48.2928899, lng: 20.0157732 },
    { lat: 48.2900405, lng: 20.0150884 },
    { lat: 48.2894553, lng: 20.0150485 },
    { lat: 48.288081, lng: 20.0152326 },
    { lat: 48.2868713, lng: 20.0152389 },
    { lat: 48.2863989, lng: 20.0151993 },
    { lat: 48.2861981, lng: 20.0154379 },
    { lat: 48.2859154, lng: 20.017181 },
    { lat: 48.2860489, lng: 20.0196626 },
    { lat: 48.2861332, lng: 20.0200858 },
    { lat: 48.286269, lng: 20.0202601 },
    { lat: 48.2870698, lng: 20.0205787 },
    { lat: 48.287376, lng: 20.0207932 },
    { lat: 48.2878328, lng: 20.021368 },
    { lat: 48.2882655, lng: 20.0220722 },
    { lat: 48.2884372, lng: 20.0222424 },
    { lat: 48.2888512, lng: 20.022417 },
    { lat: 48.2889227, lng: 20.0223767 },
    { lat: 48.289406, lng: 20.0225352 },
    { lat: 48.2905363, lng: 20.0232982 },
    { lat: 48.2908344, lng: 20.02372 },
    { lat: 48.2910932, lng: 20.024436 },
    { lat: 48.2910942, lng: 20.0252424 },
    { lat: 48.2912297, lng: 20.0257616 },
    { lat: 48.2914313, lng: 20.0269669 },
    { lat: 48.2913206, lng: 20.0277159 },
    { lat: 48.2914464, lng: 20.0278378 },
    { lat: 48.2914603, lng: 20.0280911 },
    { lat: 48.2917392, lng: 20.0281052 },
    { lat: 48.2919572, lng: 20.0279843 },
    { lat: 48.2919894, lng: 20.028191 },
    { lat: 48.2920335, lng: 20.0281946 },
    { lat: 48.2921286, lng: 20.0280208 },
    { lat: 48.2922609, lng: 20.028163 },
    { lat: 48.2923593, lng: 20.0280094 },
    { lat: 48.2925363, lng: 20.0280433 },
    { lat: 48.2925658, lng: 20.0279921 },
    { lat: 48.2925415, lng: 20.0277906 },
    { lat: 48.2926424, lng: 20.0276463 },
    { lat: 48.2927811, lng: 20.0278084 },
    { lat: 48.2928822, lng: 20.0276397 },
    { lat: 48.2931091, lng: 20.0278391 },
    { lat: 48.2931774, lng: 20.0278581 },
    { lat: 48.2932143, lng: 20.0277474 },
    { lat: 48.2932642, lng: 20.0277534 },
    { lat: 48.2933645, lng: 20.028046 },
    { lat: 48.2936474, lng: 20.0280505 },
    { lat: 48.2936977, lng: 20.028714 },
    { lat: 48.2938517, lng: 20.0289482 },
    { lat: 48.2938863, lng: 20.0292389 },
    { lat: 48.2941401, lng: 20.0298648 },
    { lat: 48.2941563, lng: 20.0302521 },
    { lat: 48.2945298, lng: 20.0312899 },
    { lat: 48.2945153, lng: 20.0315077 },
    { lat: 48.2946867, lng: 20.0316999 },
    { lat: 48.2948273, lng: 20.0320207 },
    { lat: 48.2948803, lng: 20.0323171 },
    { lat: 48.2950013, lng: 20.0325158 },
    { lat: 48.2952658, lng: 20.0331564 },
    { lat: 48.2953475, lng: 20.033518 },
    { lat: 48.2956803, lng: 20.0342296 },
    { lat: 48.2958327, lng: 20.035879 },
    { lat: 48.2958434, lng: 20.0361627 },
    { lat: 48.2959975, lng: 20.0361574 },
    { lat: 48.2974138, lng: 20.0360842 },
    { lat: 48.2989823, lng: 20.0356375 },
    { lat: 48.2997684, lng: 20.0338347 },
    { lat: 48.3020991, lng: 20.0325715 },
    { lat: 48.3056273, lng: 20.0326261 },
    { lat: 48.3067598, lng: 20.0324729 },
    { lat: 48.3091397, lng: 20.032605 },
    { lat: 48.3094177, lng: 20.0325421 },
    { lat: 48.3100075, lng: 20.0328826 },
    { lat: 48.3114687, lng: 20.0339125 },
    { lat: 48.3135562, lng: 20.0347496 },
    { lat: 48.3136134, lng: 20.0345825 },
    { lat: 48.3136187, lng: 20.0341679 },
    { lat: 48.3137688, lng: 20.0330437 },
    { lat: 48.313862, lng: 20.0326595 },
    { lat: 48.3138493, lng: 20.0324409 },
    { lat: 48.3139149, lng: 20.0319036 },
    { lat: 48.3139876, lng: 20.0317085 },
    { lat: 48.3140804, lng: 20.0308292 },
    { lat: 48.314054, lng: 20.0292279 },
    { lat: 48.3139532, lng: 20.0284889 },
    { lat: 48.3140764, lng: 20.0269956 },
    { lat: 48.3149029, lng: 20.0236029 },
    { lat: 48.3149691, lng: 20.0230301 },
    { lat: 48.3149179, lng: 20.0219163 },
    { lat: 48.3148449, lng: 20.0216503 },
    { lat: 48.3148187, lng: 20.0208134 },
    { lat: 48.3148033, lng: 20.0207939 },
    { lat: 48.3145871, lng: 20.0204222 },
    { lat: 48.3144989, lng: 20.0201449 },
    { lat: 48.3140455, lng: 20.0195939 },
  ],
  Jesenské: [
    { lat: 48.2958434, lng: 20.0361627 },
    { lat: 48.2959435, lng: 20.0368573 },
    { lat: 48.2959081, lng: 20.0369034 },
    { lat: 48.2958933, lng: 20.0371708 },
    { lat: 48.2959235, lng: 20.0372147 },
    { lat: 48.2959191, lng: 20.0378391 },
    { lat: 48.2959735, lng: 20.038411 },
    { lat: 48.2961495, lng: 20.0384709 },
    { lat: 48.2961777, lng: 20.0388527 },
    { lat: 48.2955594, lng: 20.0386632 },
    { lat: 48.2948511, lng: 20.0386979 },
    { lat: 48.2935763, lng: 20.0393283 },
    { lat: 48.2933859, lng: 20.0395673 },
    { lat: 48.2931609, lng: 20.0399273 },
    { lat: 48.2925777, lng: 20.0409845 },
    { lat: 48.2924054, lng: 20.0411708 },
    { lat: 48.2918016, lng: 20.0415504 },
    { lat: 48.291491, lng: 20.0418275 },
    { lat: 48.2912298, lng: 20.0422319 },
    { lat: 48.2908646, lng: 20.0425803 },
    { lat: 48.2907631, lng: 20.0426026 },
    { lat: 48.2888655, lng: 20.0425577 },
    { lat: 48.288675, lng: 20.0425003 },
    { lat: 48.2882902, lng: 20.0422166 },
    { lat: 48.2868845, lng: 20.0408146 },
    { lat: 48.2857089, lng: 20.040209 },
    { lat: 48.2854651, lng: 20.0403281 },
    { lat: 48.2840153, lng: 20.0417278 },
    { lat: 48.2819147, lng: 20.0415168 },
    { lat: 48.279454, lng: 20.0416154 },
    { lat: 48.2780727, lng: 20.0405826 },
    { lat: 48.2770751, lng: 20.0391634 },
    { lat: 48.2767378, lng: 20.0367834 },
    { lat: 48.2767856, lng: 20.0359896 },
    { lat: 48.2764323, lng: 20.0353775 },
    { lat: 48.2756117, lng: 20.0346921 },
    { lat: 48.2752321, lng: 20.0329997 },
    { lat: 48.2750734, lng: 20.0324623 },
    { lat: 48.2747132, lng: 20.0317143 },
    { lat: 48.273945, lng: 20.0306684 },
    { lat: 48.2733664, lng: 20.0300871 },
    { lat: 48.2729772, lng: 20.0292156 },
    { lat: 48.2727831, lng: 20.0289878 },
    { lat: 48.2723965, lng: 20.0287703 },
    { lat: 48.2704106, lng: 20.0282566 },
    { lat: 48.2699687, lng: 20.0277954 },
    { lat: 48.2677673, lng: 20.027249 },
    { lat: 48.2668921, lng: 20.0272773 },
    { lat: 48.2665776, lng: 20.0272478 },
    { lat: 48.2665387, lng: 20.0271546 },
    { lat: 48.266429, lng: 20.0272233 },
    { lat: 48.2664064, lng: 20.0273021 },
    { lat: 48.2660827, lng: 20.0274814 },
    { lat: 48.2660386, lng: 20.0273912 },
    { lat: 48.2657818, lng: 20.0275267 },
    { lat: 48.2653129, lng: 20.0276316 },
    { lat: 48.2651, lng: 20.0278178 },
    { lat: 48.2650469, lng: 20.0277351 },
    { lat: 48.2646847, lng: 20.027748 },
    { lat: 48.2645022, lng: 20.0278546 },
    { lat: 48.264093, lng: 20.0277948 },
    { lat: 48.263665, lng: 20.0278223 },
    { lat: 48.2629243, lng: 20.0283979 },
    { lat: 48.2628346, lng: 20.0284074 },
    { lat: 48.2626293, lng: 20.0289382 },
    { lat: 48.2619319, lng: 20.0312172 },
    { lat: 48.2608811, lng: 20.0324083 },
    { lat: 48.2602865, lng: 20.0339404 },
    { lat: 48.2598423, lng: 20.0345269 },
    { lat: 48.2599655, lng: 20.0349102 },
    { lat: 48.2604685, lng: 20.0358969 },
    { lat: 48.2606678, lng: 20.0365135 },
    { lat: 48.2610152, lng: 20.0372222 },
    { lat: 48.261083, lng: 20.0380872 },
    { lat: 48.2613401, lng: 20.0391979 },
    { lat: 48.2614868, lng: 20.0404143 },
    { lat: 48.2616301, lng: 20.0410976 },
    { lat: 48.2615416, lng: 20.0433186 },
    { lat: 48.2616047, lng: 20.0442625 },
    { lat: 48.2618495, lng: 20.0458046 },
    { lat: 48.2618009, lng: 20.046022 },
    { lat: 48.2615947, lng: 20.047069 },
    { lat: 48.261677, lng: 20.048418 },
    { lat: 48.2615836, lng: 20.0490709 },
    { lat: 48.2616105, lng: 20.049442 },
    { lat: 48.2614099, lng: 20.0498447 },
    { lat: 48.2613687, lng: 20.0501526 },
    { lat: 48.2614159, lng: 20.0514763 },
    { lat: 48.2616175, lng: 20.0526873 },
    { lat: 48.2620098, lng: 20.0530207 },
    { lat: 48.262321, lng: 20.0531075 },
    { lat: 48.2631448, lng: 20.0529562 },
    { lat: 48.2636214, lng: 20.0531718 },
    { lat: 48.2650721, lng: 20.0543304 },
    { lat: 48.2663155, lng: 20.0557064 },
    { lat: 48.2668114, lng: 20.0566337 },
    { lat: 48.266943, lng: 20.0578987 },
    { lat: 48.2670263, lng: 20.0580865 },
    { lat: 48.267426, lng: 20.0585409 },
    { lat: 48.267904, lng: 20.05925 },
    { lat: 48.2680085, lng: 20.0599795 },
    { lat: 48.2680461, lng: 20.0599597 },
    { lat: 48.268423, lng: 20.0606249 },
    { lat: 48.2685228, lng: 20.060722 },
    { lat: 48.2687764, lng: 20.0607015 },
    { lat: 48.2691812, lng: 20.0604678 },
    { lat: 48.2697925, lng: 20.0605132 },
    { lat: 48.2698938, lng: 20.0605646 },
    { lat: 48.270292, lng: 20.0610347 },
    { lat: 48.2703897, lng: 20.0610581 },
    { lat: 48.270483, lng: 20.0610519 },
    { lat: 48.2709133, lng: 20.0607812 },
    { lat: 48.2712479, lng: 20.0605109 },
    { lat: 48.271624, lng: 20.0601045 },
    { lat: 48.2717266, lng: 20.0602179 },
    { lat: 48.2718409, lng: 20.0600372 },
    { lat: 48.2719302, lng: 20.0600165 },
    { lat: 48.2724289, lng: 20.0603885 },
    { lat: 48.272738, lng: 20.0604823 },
    { lat: 48.2735001, lng: 20.0609847 },
    { lat: 48.2746223, lng: 20.0614795 },
    { lat: 48.2748075, lng: 20.0614106 },
    { lat: 48.2750233, lng: 20.0610425 },
    { lat: 48.2753737, lng: 20.0601844 },
    { lat: 48.2756896, lng: 20.0598971 },
    { lat: 48.275958, lng: 20.060068 },
    { lat: 48.276344, lng: 20.0601928 },
    { lat: 48.2770479, lng: 20.0601875 },
    { lat: 48.2775444, lng: 20.0601008 },
    { lat: 48.2779218, lng: 20.0598618 },
    { lat: 48.2784542, lng: 20.0598255 },
    { lat: 48.2785498, lng: 20.0599258 },
    { lat: 48.2798192, lng: 20.0604646 },
    { lat: 48.2799521, lng: 20.0604416 },
    { lat: 48.2803291, lng: 20.0608113 },
    { lat: 48.2807549, lng: 20.0609982 },
    { lat: 48.2817677, lng: 20.0616784 },
    { lat: 48.2823178, lng: 20.0622321 },
    { lat: 48.2825566, lng: 20.0627738 },
    { lat: 48.2829982, lng: 20.0630972 },
    { lat: 48.2839137, lng: 20.0633388 },
    { lat: 48.2850645, lng: 20.0638952 },
    { lat: 48.2856348, lng: 20.0639827 },
    { lat: 48.2862382, lng: 20.0643016 },
    { lat: 48.2867923, lng: 20.0648387 },
    { lat: 48.2886774, lng: 20.0658564 },
    { lat: 48.2893955, lng: 20.0667667 },
    { lat: 48.2895995, lng: 20.0670907 },
    { lat: 48.2899875, lng: 20.0679713 },
    { lat: 48.2901941, lng: 20.0682548 },
    { lat: 48.2905707, lng: 20.0679346 },
    { lat: 48.2921633, lng: 20.0672324 },
    { lat: 48.2923222, lng: 20.0667149 },
    { lat: 48.2939661, lng: 20.0671341 },
    { lat: 48.2939313, lng: 20.0667267 },
    { lat: 48.2949386, lng: 20.0670084 },
    { lat: 48.2952847, lng: 20.0672046 },
    { lat: 48.2954754, lng: 20.0676173 },
    { lat: 48.2957404, lng: 20.068519 },
    { lat: 48.2959352, lng: 20.06955 },
    { lat: 48.2960662, lng: 20.0711303 },
    { lat: 48.2961866, lng: 20.0716416 },
    { lat: 48.2965916, lng: 20.0724992 },
    { lat: 48.2968687, lng: 20.0722041 },
    { lat: 48.2969003, lng: 20.0723388 },
    { lat: 48.2972514, lng: 20.0785497 },
    { lat: 48.2974985, lng: 20.0812467 },
    { lat: 48.2972844, lng: 20.0815426 },
    { lat: 48.2972221, lng: 20.0817535 },
    { lat: 48.2972941, lng: 20.0819711 },
    { lat: 48.2973227, lng: 20.0823293 },
    { lat: 48.2974299, lng: 20.0826391 },
    { lat: 48.2977509, lng: 20.0827981 },
    { lat: 48.2979983, lng: 20.0827659 },
    { lat: 48.2981039, lng: 20.0828923 },
    { lat: 48.2981078, lng: 20.0831243 },
    { lat: 48.2990474, lng: 20.0833726 },
    { lat: 48.3012561, lng: 20.0841567 },
    { lat: 48.3057273, lng: 20.0858816 },
    { lat: 48.305859, lng: 20.0863962 },
    { lat: 48.3059825, lng: 20.0865499 },
    { lat: 48.3065029, lng: 20.0869307 },
    { lat: 48.3067132, lng: 20.0869483 },
    { lat: 48.3087353, lng: 20.0926497 },
    { lat: 48.3093593, lng: 20.0942297 },
    { lat: 48.3096233, lng: 20.095164 },
    { lat: 48.3140572, lng: 20.1075155 },
    { lat: 48.3160761, lng: 20.1132729 },
    { lat: 48.3160566, lng: 20.1133996 },
    { lat: 48.3163051, lng: 20.1134679 },
    { lat: 48.3172682, lng: 20.1141531 },
    { lat: 48.3172787, lng: 20.1140527 },
    { lat: 48.3181046, lng: 20.1065674 },
    { lat: 48.3186097, lng: 20.1072897 },
    { lat: 48.3205369, lng: 20.1097757 },
    { lat: 48.3222516, lng: 20.0980108 },
    { lat: 48.318219, lng: 20.0994533 },
    { lat: 48.3181766, lng: 20.099712 },
    { lat: 48.3170036, lng: 20.0988002 },
    { lat: 48.3162966, lng: 20.0989181 },
    { lat: 48.3149407, lng: 20.0976397 },
    { lat: 48.3140578, lng: 20.0969609 },
    { lat: 48.3134208, lng: 20.0949573 },
    { lat: 48.3133185, lng: 20.09428 },
    { lat: 48.3133891, lng: 20.0939716 },
    { lat: 48.3135792, lng: 20.0938842 },
    { lat: 48.31257, lng: 20.0871037 },
    { lat: 48.3120069, lng: 20.0870778 },
    { lat: 48.3118411, lng: 20.0864475 },
    { lat: 48.3118688, lng: 20.0861636 },
    { lat: 48.3121192, lng: 20.0862259 },
    { lat: 48.3124443, lng: 20.086569 },
    { lat: 48.3126462, lng: 20.0866015 },
    { lat: 48.3129065, lng: 20.0864691 },
    { lat: 48.3129767, lng: 20.0861218 },
    { lat: 48.3128197, lng: 20.0857333 },
    { lat: 48.3128038, lng: 20.0853219 },
    { lat: 48.3128664, lng: 20.0850648 },
    { lat: 48.3135162, lng: 20.0836455 },
    { lat: 48.313814, lng: 20.0833298 },
    { lat: 48.3146161, lng: 20.0830633 },
    { lat: 48.3149966, lng: 20.0831107 },
    { lat: 48.31518, lng: 20.0830516 },
    { lat: 48.3153689, lng: 20.0831808 },
    { lat: 48.3156918, lng: 20.0841194 },
    { lat: 48.3158904, lng: 20.084293 },
    { lat: 48.3161662, lng: 20.0843417 },
    { lat: 48.3163764, lng: 20.0844991 },
    { lat: 48.3164068, lng: 20.0843894 },
    { lat: 48.3155218, lng: 20.0780846 },
    { lat: 48.3156177, lng: 20.0776553 },
    { lat: 48.3157611, lng: 20.0774469 },
    { lat: 48.3157742, lng: 20.0771368 },
    { lat: 48.3159337, lng: 20.0770214 },
    { lat: 48.3161162, lng: 20.0771523 },
    { lat: 48.3162172, lng: 20.0771188 },
    { lat: 48.3170378, lng: 20.0768888 },
    { lat: 48.3163136, lng: 20.0745828 },
    { lat: 48.3150712, lng: 20.0705632 },
    { lat: 48.3224011, lng: 20.0655245 },
    { lat: 48.3243308, lng: 20.0645847 },
    { lat: 48.3176493, lng: 20.0555759 },
    { lat: 48.3144011, lng: 20.0495048 },
    { lat: 48.3138245, lng: 20.0480782 },
    { lat: 48.3130998, lng: 20.0458255 },
    { lat: 48.311646, lng: 20.0463231 },
    { lat: 48.3119587, lng: 20.0440999 },
    { lat: 48.3121971, lng: 20.0433864 },
    { lat: 48.3123383, lng: 20.0426435 },
    { lat: 48.3123402, lng: 20.0416589 },
    { lat: 48.3124377, lng: 20.0404625 },
    { lat: 48.312628, lng: 20.0398703 },
    { lat: 48.3128888, lng: 20.0394512 },
    { lat: 48.3129751, lng: 20.0391437 },
    { lat: 48.3132152, lng: 20.0374513 },
    { lat: 48.3132756, lng: 20.0360952 },
    { lat: 48.3134259, lng: 20.035218 },
    { lat: 48.3135562, lng: 20.0347496 },
    { lat: 48.3114687, lng: 20.0339125 },
    { lat: 48.3100075, lng: 20.0328826 },
    { lat: 48.3094177, lng: 20.0325421 },
    { lat: 48.3091397, lng: 20.032605 },
    { lat: 48.3067598, lng: 20.0324729 },
    { lat: 48.3056273, lng: 20.0326261 },
    { lat: 48.3020991, lng: 20.0325715 },
    { lat: 48.2997684, lng: 20.0338347 },
    { lat: 48.2989823, lng: 20.0356375 },
    { lat: 48.2974138, lng: 20.0360842 },
    { lat: 48.2959975, lng: 20.0361574 },
    { lat: 48.2958434, lng: 20.0361627 },
  ],
  NováBašta: [
    { lat: 48.1797763, lng: 19.9639956 },
    { lat: 48.1782627, lng: 19.9643228 },
    { lat: 48.1777871, lng: 19.9643476 },
    { lat: 48.1772573, lng: 19.9642053 },
    { lat: 48.1747456, lng: 19.9632073 },
    { lat: 48.1746339, lng: 19.9595559 },
    { lat: 48.174229, lng: 19.9593594 },
    { lat: 48.1746351, lng: 19.9571011 },
    { lat: 48.1748803, lng: 19.9554507 },
    { lat: 48.17495, lng: 19.9542607 },
    { lat: 48.1752075, lng: 19.9525343 },
    { lat: 48.1756873, lng: 19.9499421 },
    { lat: 48.1762728, lng: 19.9476669 },
    { lat: 48.1764534, lng: 19.9463561 },
    { lat: 48.1768891, lng: 19.9453205 },
    { lat: 48.1773941, lng: 19.944512 },
    { lat: 48.1779087, lng: 19.9426875 },
    { lat: 48.1781053, lng: 19.9422289 },
    { lat: 48.1783441, lng: 19.9414363 },
    { lat: 48.1788923, lng: 19.9403716 },
    { lat: 48.1791916, lng: 19.9396546 },
    { lat: 48.1795409, lng: 19.9380255 },
    { lat: 48.1808572, lng: 19.9328755 },
    { lat: 48.180982, lng: 19.9317249 },
    { lat: 48.1810097, lng: 19.9314695 },
    { lat: 48.1809983, lng: 19.930988 },
    { lat: 48.1809355, lng: 19.9305493 },
    { lat: 48.180975, lng: 19.9299705 },
    { lat: 48.1816636, lng: 19.9281166 },
    { lat: 48.1826126, lng: 19.9264411 },
    { lat: 48.184312, lng: 19.9230682 },
    { lat: 48.1844289, lng: 19.9231413 },
    { lat: 48.1846876, lng: 19.9222661 },
    { lat: 48.1849763, lng: 19.9216167 },
    { lat: 48.1855023, lng: 19.9207483 },
    { lat: 48.1864682, lng: 19.9187947 },
    { lat: 48.1864199, lng: 19.9174551 },
    { lat: 48.1864416, lng: 19.9169658 },
    { lat: 48.1870814, lng: 19.9161652 },
    { lat: 48.1872456, lng: 19.9159597 },
    { lat: 48.1874837, lng: 19.9153691 },
    { lat: 48.1876077, lng: 19.9146628 },
    { lat: 48.1875844, lng: 19.9137249 },
    { lat: 48.1877507, lng: 19.913065 },
    { lat: 48.1890141, lng: 19.9108729 },
    { lat: 48.1895565, lng: 19.9092627 },
    { lat: 48.1898256, lng: 19.9077172 },
    { lat: 48.1900236, lng: 19.9050665 },
    { lat: 48.1921645, lng: 19.8997686 },
    { lat: 48.193201, lng: 19.8981243 },
    { lat: 48.1941901, lng: 19.8959054 },
    { lat: 48.1945054, lng: 19.8955102 },
    { lat: 48.1947647, lng: 19.8950703 },
    { lat: 48.1954803, lng: 19.8934575 },
    { lat: 48.195688, lng: 19.892671 },
    { lat: 48.195224, lng: 19.893335 },
    { lat: 48.195046, lng: 19.893282 },
    { lat: 48.194649, lng: 19.893351 },
    { lat: 48.194197, lng: 19.893527 },
    { lat: 48.193578, lng: 19.893405 },
    { lat: 48.193375, lng: 19.893335 },
    { lat: 48.192763, lng: 19.893109 },
    { lat: 48.192618, lng: 19.893056 },
    { lat: 48.192368, lng: 19.892944 },
    { lat: 48.191307, lng: 19.892677 },
    { lat: 48.190784, lng: 19.89283 },
    { lat: 48.190118, lng: 19.892812 },
    { lat: 48.189665, lng: 19.892705 },
    { lat: 48.189615, lng: 19.893349 },
    { lat: 48.188915, lng: 19.894464 },
    { lat: 48.188781, lng: 19.894963 },
    { lat: 48.188496, lng: 19.895251 },
    { lat: 48.188065, lng: 19.895153 },
    { lat: 48.186735, lng: 19.894482 },
    { lat: 48.186309, lng: 19.894178 },
    { lat: 48.185993, lng: 19.893775 },
    { lat: 48.185646, lng: 19.893049 },
    { lat: 48.185623, lng: 19.892999 },
    { lat: 48.185535, lng: 19.893409 },
    { lat: 48.185411, lng: 19.894211 },
    { lat: 48.185391, lng: 19.894695 },
    { lat: 48.185412, lng: 19.89529 },
    { lat: 48.184348, lng: 19.895128 },
    { lat: 48.183583, lng: 19.894638 },
    { lat: 48.18088, lng: 19.892925 },
    { lat: 48.180468, lng: 19.892641 },
    { lat: 48.179919, lng: 19.892262 },
    { lat: 48.179735, lng: 19.892135 },
    { lat: 48.179437, lng: 19.891934 },
    { lat: 48.178748, lng: 19.89147 },
    { lat: 48.177496, lng: 19.890345 },
    { lat: 48.176253, lng: 19.889736 },
    { lat: 48.176218, lng: 19.889719 },
    { lat: 48.175789, lng: 19.889553 },
    { lat: 48.175094, lng: 19.888826 },
    { lat: 48.174725, lng: 19.888489 },
    { lat: 48.174678, lng: 19.888447 },
    { lat: 48.174406, lng: 19.888205 },
    { lat: 48.172943, lng: 19.886991 },
    { lat: 48.172867, lng: 19.886934 },
    { lat: 48.172451, lng: 19.886613 },
    { lat: 48.171281, lng: 19.885713 },
    { lat: 48.170953, lng: 19.885537 },
    { lat: 48.169691, lng: 19.885306 },
    { lat: 48.168975, lng: 19.884758 },
    { lat: 48.168286, lng: 19.884354 },
    { lat: 48.167601, lng: 19.883747 },
    { lat: 48.167104, lng: 19.883299 },
    { lat: 48.166731, lng: 19.883368 },
    { lat: 48.166556, lng: 19.883557 },
    { lat: 48.166187, lng: 19.883746 },
    { lat: 48.165768, lng: 19.884196 },
    { lat: 48.165598, lng: 19.884476 },
    { lat: 48.16537, lng: 19.884968 },
    { lat: 48.165278, lng: 19.885369 },
    { lat: 48.165228, lng: 19.885677 },
    { lat: 48.16536, lng: 19.886883 },
    { lat: 48.165503, lng: 19.887482 },
    { lat: 48.165257, lng: 19.887876 },
    { lat: 48.164969, lng: 19.888339 },
    { lat: 48.164689, lng: 19.888394 },
    { lat: 48.164088, lng: 19.889074 },
    { lat: 48.164143, lng: 19.889315 },
    { lat: 48.164404, lng: 19.890253 },
    { lat: 48.164561, lng: 19.890611 },
    { lat: 48.164624, lng: 19.890811 },
    { lat: 48.164663, lng: 19.890933 },
    { lat: 48.164702, lng: 19.891055 },
    { lat: 48.164778, lng: 19.891414 },
    { lat: 48.165021, lng: 19.892453 },
    { lat: 48.165133, lng: 19.893051 },
    { lat: 48.165265, lng: 19.893735 },
    { lat: 48.165312, lng: 19.894097 },
    { lat: 48.165355, lng: 19.894363 },
    { lat: 48.165551, lng: 19.895201 },
    { lat: 48.165666, lng: 19.89574 },
    { lat: 48.165765, lng: 19.896249 },
    { lat: 48.1657935, lng: 19.8963771 },
    { lat: 48.165994, lng: 19.897278 },
    { lat: 48.166094, lng: 19.897731 },
    { lat: 48.166312, lng: 19.898745 },
    { lat: 48.1668139, lng: 19.9013835 },
    { lat: 48.1685302, lng: 19.9089791 },
    { lat: 48.1684699, lng: 19.9098524 },
    { lat: 48.1685057, lng: 19.9100819 },
    { lat: 48.1685222, lng: 19.911331 },
    { lat: 48.1684599, lng: 19.9114538 },
    { lat: 48.1684791, lng: 19.9123575 },
    { lat: 48.1685226, lng: 19.9144033 },
    { lat: 48.1682001, lng: 19.9146598 },
    { lat: 48.1681167, lng: 19.9149723 },
    { lat: 48.1681217, lng: 19.9153523 },
    { lat: 48.1682763, lng: 19.9161685 },
    { lat: 48.1682826, lng: 19.9166563 },
    { lat: 48.1685681, lng: 19.9166698 },
    { lat: 48.168619, lng: 19.9168809 },
    { lat: 48.1685701, lng: 19.9170554 },
    { lat: 48.168345, lng: 19.9170862 },
    { lat: 48.1681421, lng: 19.9172161 },
    { lat: 48.1681595, lng: 19.9173845 },
    { lat: 48.1684005, lng: 19.9175918 },
    { lat: 48.1684022, lng: 19.9181044 },
    { lat: 48.1681378, lng: 19.9181087 },
    { lat: 48.1680914, lng: 19.9181823 },
    { lat: 48.16825, lng: 19.9187543 },
    { lat: 48.1682607, lng: 19.9191884 },
    { lat: 48.1683961, lng: 19.9195597 },
    { lat: 48.1683885, lng: 19.9200915 },
    { lat: 48.1683014, lng: 19.9202636 },
    { lat: 48.1680214, lng: 19.9202517 },
    { lat: 48.168081, lng: 19.9205113 },
    { lat: 48.1684106, lng: 19.9208922 },
    { lat: 48.1683139, lng: 19.921132 },
    { lat: 48.1681392, lng: 19.921208 },
    { lat: 48.1681991, lng: 19.9214253 },
    { lat: 48.1685074, lng: 19.9218107 },
    { lat: 48.1684921, lng: 19.9219001 },
    { lat: 48.1683131, lng: 19.9219264 },
    { lat: 48.1681687, lng: 19.9220532 },
    { lat: 48.1684896, lng: 19.9224946 },
    { lat: 48.1684624, lng: 19.922572 },
    { lat: 48.1681558, lng: 19.9226445 },
    { lat: 48.1684119, lng: 19.9233356 },
    { lat: 48.1683807, lng: 19.9235002 },
    { lat: 48.1682153, lng: 19.9237158 },
    { lat: 48.1683485, lng: 19.9240637 },
    { lat: 48.1685711, lng: 19.9240039 },
    { lat: 48.1685926, lng: 19.9243448 },
    { lat: 48.1682351, lng: 19.9250196 },
    { lat: 48.1683791, lng: 19.9255366 },
    { lat: 48.1682459, lng: 19.9266359 },
    { lat: 48.1682448, lng: 19.9272642 },
    { lat: 48.1683003, lng: 19.9276947 },
    { lat: 48.1681292, lng: 19.9280093 },
    { lat: 48.1681523, lng: 19.9281849 },
    { lat: 48.1683227, lng: 19.9285861 },
    { lat: 48.1682066, lng: 19.9289233 },
    { lat: 48.1683014, lng: 19.9297672 },
    { lat: 48.1682752, lng: 19.9298949 },
    { lat: 48.1681586, lng: 19.9299552 },
    { lat: 48.1680161, lng: 19.9297606 },
    { lat: 48.1680016, lng: 19.9300545 },
    { lat: 48.1681569, lng: 19.9305221 },
    { lat: 48.1679608, lng: 19.9308648 },
    { lat: 48.1679216, lng: 19.9314494 },
    { lat: 48.1678498, lng: 19.9317679 },
    { lat: 48.1676393, lng: 19.9321371 },
    { lat: 48.1676908, lng: 19.9324208 },
    { lat: 48.1675118, lng: 19.9327665 },
    { lat: 48.167593, lng: 19.9331885 },
    { lat: 48.167543, lng: 19.9332569 },
    { lat: 48.1672519, lng: 19.9332498 },
    { lat: 48.1672389, lng: 19.9334705 },
    { lat: 48.1673397, lng: 19.9336687 },
    { lat: 48.1673375, lng: 19.9339315 },
    { lat: 48.1670671, lng: 19.9341398 },
    { lat: 48.1670827, lng: 19.9342929 },
    { lat: 48.1671998, lng: 19.9343706 },
    { lat: 48.1671506, lng: 19.9345394 },
    { lat: 48.1670179, lng: 19.9345937 },
    { lat: 48.1669987, lng: 19.9349898 },
    { lat: 48.1669043, lng: 19.9349953 },
    { lat: 48.1667905, lng: 19.9352588 },
    { lat: 48.1667561, lng: 19.9352448 },
    { lat: 48.166793, lng: 19.9356312 },
    { lat: 48.1667408, lng: 19.935939 },
    { lat: 48.1664773, lng: 19.9367328 },
    { lat: 48.1663539, lng: 19.9382443 },
    { lat: 48.1658898, lng: 19.9404564 },
    { lat: 48.1657664, lng: 19.9439691 },
    { lat: 48.1658282, lng: 19.9442543 },
    { lat: 48.1662529, lng: 19.9453663 },
    { lat: 48.1660427, lng: 19.9456122 },
    { lat: 48.1656785, lng: 19.9457077 },
    { lat: 48.1648279, lng: 19.9461305 },
    { lat: 48.1646636, lng: 19.9461087 },
    { lat: 48.1644468, lng: 19.9458309 },
    { lat: 48.1632796, lng: 19.945725 },
    { lat: 48.1629418, lng: 19.9456246 },
    { lat: 48.1623915, lng: 19.945349 },
    { lat: 48.161001, lng: 19.9445728 },
    { lat: 48.160088, lng: 19.9435885 },
    { lat: 48.1600698, lng: 19.9435602 },
    { lat: 48.160088, lng: 19.944328 },
    { lat: 48.1598765, lng: 19.9531916 },
    { lat: 48.1599122, lng: 19.9536081 },
    { lat: 48.1600804, lng: 19.9545133 },
    { lat: 48.1602206, lng: 19.955104 },
    { lat: 48.1609863, lng: 19.9541733 },
    { lat: 48.1619866, lng: 19.9531404 },
    { lat: 48.1625868, lng: 19.9532695 },
    { lat: 48.1628548, lng: 19.9538023 },
    { lat: 48.1630406, lng: 19.9541106 },
    { lat: 48.1630823, lng: 19.9540023 },
    { lat: 48.1631159, lng: 19.9540388 },
    { lat: 48.163124, lng: 19.9540128 },
    { lat: 48.1631419, lng: 19.9539798 },
    { lat: 48.163157, lng: 19.9539495 },
    { lat: 48.1632033, lng: 19.9539956 },
    { lat: 48.1632838, lng: 19.9541263 },
    { lat: 48.1632778, lng: 19.9542826 },
    { lat: 48.1630072, lng: 19.9552569 },
    { lat: 48.1633041, lng: 19.9591015 },
    { lat: 48.1648709, lng: 19.9603878 },
    { lat: 48.1686168, lng: 19.9632262 },
    { lat: 48.1709413, lng: 19.9662761 },
    { lat: 48.1708037, lng: 19.9663269 },
    { lat: 48.1700633, lng: 19.965976 },
    { lat: 48.169614, lng: 19.965874 },
    { lat: 48.1683017, lng: 19.9658012 },
    { lat: 48.1682159, lng: 19.9675967 },
    { lat: 48.1682287, lng: 19.9692735 },
    { lat: 48.1677463, lng: 19.9692149 },
    { lat: 48.1672983, lng: 19.9692486 },
    { lat: 48.1669508, lng: 19.9699271 },
    { lat: 48.166661, lng: 19.9708728 },
    { lat: 48.1666942, lng: 19.9727141 },
    { lat: 48.1665999, lng: 19.9735174 },
    { lat: 48.1660152, lng: 19.9735454 },
    { lat: 48.166005, lng: 19.974394 },
    { lat: 48.165948, lng: 19.975328 },
    { lat: 48.165887, lng: 19.976313 },
    { lat: 48.165883, lng: 19.976388 },
    { lat: 48.165804, lng: 19.977663 },
    { lat: 48.165734, lng: 19.978893 },
    { lat: 48.16567, lng: 19.98022 },
    { lat: 48.165638, lng: 19.980612 },
    { lat: 48.165583, lng: 19.980901 },
    { lat: 48.16545, lng: 19.981187 },
    { lat: 48.164864, lng: 19.981952 },
    { lat: 48.164508, lng: 19.982413 },
    { lat: 48.164199, lng: 19.982806 },
    { lat: 48.163766, lng: 19.983262 },
    { lat: 48.163519, lng: 19.983508 },
    { lat: 48.1633, lng: 19.983828 },
    { lat: 48.162927, lng: 19.984392 },
    { lat: 48.162837, lng: 19.984479 },
    { lat: 48.162779, lng: 19.984583 },
    { lat: 48.162535, lng: 19.985203 },
    { lat: 48.16242, lng: 19.98569 },
    { lat: 48.162347, lng: 19.986181 },
    { lat: 48.162241, lng: 19.987262 },
    { lat: 48.162177, lng: 19.988012 },
    { lat: 48.162168, lng: 19.988706 },
    { lat: 48.162817, lng: 19.988938 },
    { lat: 48.163558, lng: 19.989204 },
    { lat: 48.163754, lng: 19.989273 },
    { lat: 48.163861, lng: 19.989324 },
    { lat: 48.164533, lng: 19.989638 },
    { lat: 48.164841, lng: 19.989783 },
    { lat: 48.165615, lng: 19.990145 },
    { lat: 48.166245, lng: 19.990437 },
    { lat: 48.166743, lng: 19.990669 },
    { lat: 48.166879, lng: 19.990733 },
    { lat: 48.16757, lng: 19.991054 },
    { lat: 48.168377, lng: 19.99143 },
    { lat: 48.168399, lng: 19.991441 },
    { lat: 48.168382, lng: 19.991707 },
    { lat: 48.16833, lng: 19.992178 },
    { lat: 48.168291, lng: 19.992445 },
    { lat: 48.168257, lng: 19.99272 },
    { lat: 48.1682892, lng: 19.9927102 },
    { lat: 48.1685266, lng: 19.9933734 },
    { lat: 48.1687179, lng: 19.9933645 },
    { lat: 48.1691253, lng: 19.9927998 },
    { lat: 48.1696718, lng: 19.9925321 },
    { lat: 48.1698877, lng: 19.9924965 },
    { lat: 48.1702559, lng: 19.992629 },
    { lat: 48.1704883, lng: 19.9929003 },
    { lat: 48.1706937, lng: 19.9935475 },
    { lat: 48.1714763, lng: 19.991548 },
    { lat: 48.1715365, lng: 19.9914809 },
    { lat: 48.1718077, lng: 19.9905938 },
    { lat: 48.1721729, lng: 19.9889761 },
    { lat: 48.17241, lng: 19.9884846 },
    { lat: 48.1728387, lng: 19.986478 },
    { lat: 48.1729651, lng: 19.9850208 },
    { lat: 48.1732209, lng: 19.9847015 },
    { lat: 48.1737035, lng: 19.9838353 },
    { lat: 48.17387, lng: 19.9828472 },
    { lat: 48.1740612, lng: 19.9811932 },
    { lat: 48.1746039, lng: 19.9810825 },
    { lat: 48.1747029, lng: 19.9800994 },
    { lat: 48.1747389, lng: 19.9786881 },
    { lat: 48.1746916, lng: 19.9778643 },
    { lat: 48.1786917, lng: 19.9773576 },
    { lat: 48.1794235, lng: 19.9773207 },
    { lat: 48.1804871, lng: 19.9778576 },
    { lat: 48.1813172, lng: 19.9776934 },
    { lat: 48.1815666, lng: 19.9770668 },
    { lat: 48.1813687, lng: 19.9755929 },
    { lat: 48.1817728, lng: 19.9760314 },
    { lat: 48.1820076, lng: 19.9760737 },
    { lat: 48.1820606, lng: 19.9760207 },
    { lat: 48.1821769, lng: 19.9756288 },
    { lat: 48.1827059, lng: 19.9743184 },
    { lat: 48.1827104, lng: 19.9733614 },
    { lat: 48.1813701, lng: 19.9729973 },
    { lat: 48.181277, lng: 19.9725004 },
    { lat: 48.1805138, lng: 19.9661816 },
    { lat: 48.1804279, lng: 19.9657549 },
    { lat: 48.1801762, lng: 19.9650263 },
    { lat: 48.1797763, lng: 19.9639956 },
  ],
  Valice: [
    { lat: 48.4533154, lng: 20.1929818 },
    { lat: 48.4527769, lng: 20.1933694 },
    { lat: 48.4527281, lng: 20.1931365 },
    { lat: 48.4525609, lng: 20.1931812 },
    { lat: 48.4524349, lng: 20.192432 },
    { lat: 48.4503933, lng: 20.1929981 },
    { lat: 48.4484445, lng: 20.1945555 },
    { lat: 48.4475481, lng: 20.1958714 },
    { lat: 48.4474618, lng: 20.1962716 },
    { lat: 48.4462742, lng: 20.1968754 },
    { lat: 48.4460286, lng: 20.1964547 },
    { lat: 48.4432211, lng: 20.1958491 },
    { lat: 48.439781, lng: 20.1983801 },
    { lat: 48.4391452, lng: 20.1988732 },
    { lat: 48.4380007, lng: 20.2002358 },
    { lat: 48.4383767, lng: 20.2007347 },
    { lat: 48.4386544, lng: 20.2008627 },
    { lat: 48.4392673, lng: 20.2014301 },
    { lat: 48.4396291, lng: 20.2016881 },
    { lat: 48.4401499, lng: 20.2023657 },
    { lat: 48.4405039, lng: 20.2032731 },
    { lat: 48.4417542, lng: 20.2071646 },
    { lat: 48.4423193, lng: 20.2083051 },
    { lat: 48.4421724, lng: 20.2086317 },
    { lat: 48.4425785, lng: 20.2128465 },
    { lat: 48.4426936, lng: 20.2132748 },
    { lat: 48.442846, lng: 20.2142365 },
    { lat: 48.4432053, lng: 20.2153028 },
    { lat: 48.4437956, lng: 20.21682 },
    { lat: 48.443953, lng: 20.2174904 },
    { lat: 48.4441566, lng: 20.2179054 },
    { lat: 48.445039, lng: 20.2193224 },
    { lat: 48.4453828, lng: 20.2196925 },
    { lat: 48.4453671, lng: 20.2197535 },
    { lat: 48.4455303, lng: 20.2199749 },
    { lat: 48.4459413, lng: 20.2203943 },
    { lat: 48.4463051, lng: 20.2208966 },
    { lat: 48.4465774, lng: 20.2215921 },
    { lat: 48.4469545, lng: 20.2222085 },
    { lat: 48.4471316, lng: 20.2226054 },
    { lat: 48.4475823, lng: 20.2237644 },
    { lat: 48.4483, lng: 20.224973 },
    { lat: 48.448482, lng: 20.224715 },
    { lat: 48.448667, lng: 20.224474 },
    { lat: 48.44883, lng: 20.224264 },
    { lat: 48.448963, lng: 20.224122 },
    { lat: 48.449013, lng: 20.2241 },
    { lat: 48.449118, lng: 20.224077 },
    { lat: 48.449233, lng: 20.224083 },
    { lat: 48.449293, lng: 20.224107 },
    { lat: 48.449377, lng: 20.224155 },
    { lat: 48.449559, lng: 20.224362 },
    { lat: 48.449729, lng: 20.224564 },
    { lat: 48.449856, lng: 20.224747 },
    { lat: 48.450196, lng: 20.225206 },
    { lat: 48.450379, lng: 20.225375 },
    { lat: 48.450543, lng: 20.225509 },
    { lat: 48.45055, lng: 20.225487 },
    { lat: 48.450852, lng: 20.225652 },
    { lat: 48.45134, lng: 20.225648 },
    { lat: 48.451645, lng: 20.225602 },
    { lat: 48.451938, lng: 20.225261 },
    { lat: 48.452192, lng: 20.224746 },
    { lat: 48.452229, lng: 20.224348 },
    { lat: 48.452237, lng: 20.22427 },
    { lat: 48.452476, lng: 20.223904 },
    { lat: 48.452589, lng: 20.223756 },
    { lat: 48.452736, lng: 20.223581 },
    { lat: 48.452913, lng: 20.223398 },
    { lat: 48.453373, lng: 20.22293 },
    { lat: 48.453742, lng: 20.222568 },
    { lat: 48.454107, lng: 20.222286 },
    { lat: 48.454176, lng: 20.222262 },
    { lat: 48.454606, lng: 20.222118 },
    { lat: 48.454876, lng: 20.221852 },
    { lat: 48.455098, lng: 20.221581 },
    { lat: 48.455216, lng: 20.221368 },
    { lat: 48.455418, lng: 20.221129 },
    { lat: 48.455604, lng: 20.220802 },
    { lat: 48.455649, lng: 20.220708 },
    { lat: 48.455925, lng: 20.220121 },
    { lat: 48.456136, lng: 20.219738 },
    { lat: 48.456326, lng: 20.219506 },
    { lat: 48.456401, lng: 20.219358 },
    { lat: 48.45654, lng: 20.219083 },
    { lat: 48.456613, lng: 20.219004 },
    { lat: 48.456659, lng: 20.218404 },
    { lat: 48.45671, lng: 20.217767 },
    { lat: 48.456728, lng: 20.217541 },
    { lat: 48.456714, lng: 20.216912 },
    { lat: 48.456757, lng: 20.216086 },
    { lat: 48.456858, lng: 20.215646 },
    { lat: 48.456974, lng: 20.215185 },
    { lat: 48.457018, lng: 20.215021 },
    { lat: 48.457167, lng: 20.214482 },
    { lat: 48.45716, lng: 20.214445 },
    { lat: 48.457362, lng: 20.214077 },
    { lat: 48.457454, lng: 20.214008 },
    { lat: 48.457717, lng: 20.213812 },
    { lat: 48.458016, lng: 20.213503 },
    { lat: 48.458324, lng: 20.213186 },
    { lat: 48.458403, lng: 20.21316 },
    { lat: 48.45852, lng: 20.213156 },
    { lat: 48.458787, lng: 20.213148 },
    { lat: 48.459137, lng: 20.213347 },
    { lat: 48.459512, lng: 20.213216 },
    { lat: 48.45977, lng: 20.213125 },
    { lat: 48.460124, lng: 20.21297 },
    { lat: 48.460261, lng: 20.212845 },
    { lat: 48.46039, lng: 20.21273 },
    { lat: 48.460529, lng: 20.212672 },
    { lat: 48.46091, lng: 20.212513 },
    { lat: 48.461277, lng: 20.21235 },
    { lat: 48.461617, lng: 20.21218 },
    { lat: 48.461911, lng: 20.212035 },
    { lat: 48.461988, lng: 20.212016 },
    { lat: 48.462026, lng: 20.212007 },
    { lat: 48.462023, lng: 20.211982 },
    { lat: 48.462394, lng: 20.211883 },
    { lat: 48.462685, lng: 20.211808 },
    { lat: 48.462829, lng: 20.211765 },
    { lat: 48.462993, lng: 20.211718 },
    { lat: 48.463011, lng: 20.21175 },
    { lat: 48.463274, lng: 20.211677 },
    { lat: 48.4635, lng: 20.211817 },
    { lat: 48.46426, lng: 20.212218 },
    { lat: 48.464937, lng: 20.212583 },
    { lat: 48.46517, lng: 20.212641 },
    { lat: 48.465263, lng: 20.212664 },
    { lat: 48.466019, lng: 20.21235 },
    { lat: 48.466087, lng: 20.212323 },
    { lat: 48.466252, lng: 20.212256 },
    { lat: 48.466448, lng: 20.212077 },
    { lat: 48.466699, lng: 20.211854 },
    { lat: 48.466717, lng: 20.211824 },
    { lat: 48.46675, lng: 20.211807 },
    { lat: 48.467253, lng: 20.211722 },
    { lat: 48.467639, lng: 20.211572 },
    { lat: 48.467695, lng: 20.211555 },
    { lat: 48.468227, lng: 20.211493 },
    { lat: 48.468697, lng: 20.211399 },
    { lat: 48.468831, lng: 20.211316 },
    { lat: 48.46919, lng: 20.211097 },
    { lat: 48.469471, lng: 20.210797 },
    { lat: 48.46982, lng: 20.210623 },
    { lat: 48.470023, lng: 20.210402 },
    { lat: 48.470097, lng: 20.210323 },
    { lat: 48.470269, lng: 20.210323 },
    { lat: 48.470458, lng: 20.210324 },
    { lat: 48.470592, lng: 20.210324 },
    { lat: 48.470681, lng: 20.210266 },
    { lat: 48.470785, lng: 20.210245 },
    { lat: 48.470839, lng: 20.210234 },
    { lat: 48.470888, lng: 20.210224 },
    { lat: 48.471339, lng: 20.21075 },
    { lat: 48.47136, lng: 20.210725 },
    { lat: 48.471587, lng: 20.211114 },
    { lat: 48.47196, lng: 20.211375 },
    { lat: 48.47237, lng: 20.211562 },
    { lat: 48.472744, lng: 20.211634 },
    { lat: 48.473089, lng: 20.211698 },
    { lat: 48.473517, lng: 20.211619 },
    { lat: 48.473521, lng: 20.211649 },
    { lat: 48.473679, lng: 20.211604 },
    { lat: 48.473781, lng: 20.211578 },
    { lat: 48.474247, lng: 20.211457 },
    { lat: 48.474781, lng: 20.210924 },
    { lat: 48.475047, lng: 20.210548 },
    { lat: 48.475381, lng: 20.210062 },
    { lat: 48.475559, lng: 20.209786 },
    { lat: 48.47571, lng: 20.209554 },
    { lat: 48.475875, lng: 20.209408 },
    { lat: 48.476059, lng: 20.209467 },
    { lat: 48.4755563, lng: 20.2088484 },
    { lat: 48.4745921, lng: 20.2087281 },
    { lat: 48.4740953, lng: 20.2083948 },
    { lat: 48.4735552, lng: 20.2082097 },
    { lat: 48.473233, lng: 20.2078159 },
    { lat: 48.4727108, lng: 20.2068426 },
    { lat: 48.4722674, lng: 20.2057588 },
    { lat: 48.4713354, lng: 20.2049087 },
    { lat: 48.4712434, lng: 20.2047293 },
    { lat: 48.4711841, lng: 20.2046137 },
    { lat: 48.4709477, lng: 20.2037252 },
    { lat: 48.4705319, lng: 20.2029335 },
    { lat: 48.4700415, lng: 20.2022552 },
    { lat: 48.4696785, lng: 20.202049 },
    { lat: 48.4693452, lng: 20.2016613 },
    { lat: 48.4687498, lng: 20.20111 },
    { lat: 48.4681423, lng: 20.2006479 },
    { lat: 48.4677672, lng: 20.2004808 },
    { lat: 48.4667761, lng: 20.1998414 },
    { lat: 48.4654952, lng: 20.1984043 },
    { lat: 48.4648649, lng: 20.1979365 },
    { lat: 48.4644505, lng: 20.1973805 },
    { lat: 48.4643318, lng: 20.1973179 },
    { lat: 48.4640458, lng: 20.1969546 },
    { lat: 48.4638237, lng: 20.1970022 },
    { lat: 48.4632905, lng: 20.1967662 },
    { lat: 48.4631262, lng: 20.1967726 },
    { lat: 48.4630451, lng: 20.1965759 },
    { lat: 48.4625154, lng: 20.1960625 },
    { lat: 48.4620565, lng: 20.1958023 },
    { lat: 48.4599382, lng: 20.1949421 },
    { lat: 48.4595314, lng: 20.1946265 },
    { lat: 48.459218, lng: 20.1942289 },
    { lat: 48.4591498, lng: 20.1937451 },
    { lat: 48.4591622, lng: 20.1935722 },
    { lat: 48.4593219, lng: 20.1931783 },
    { lat: 48.4594544, lng: 20.1925828 },
    { lat: 48.4594532, lng: 20.1923008 },
    { lat: 48.4590767, lng: 20.1918146 },
    { lat: 48.4589211, lng: 20.1915034 },
    { lat: 48.458925, lng: 20.1913789 },
    { lat: 48.4581953, lng: 20.1902673 },
    { lat: 48.4581644, lng: 20.1902556 },
    { lat: 48.4579861, lng: 20.1909439 },
    { lat: 48.4577764, lng: 20.1912746 },
    { lat: 48.4573565, lng: 20.1914197 },
    { lat: 48.4570881, lng: 20.1916873 },
    { lat: 48.4562845, lng: 20.1927069 },
    { lat: 48.4555545, lng: 20.1933436 },
    { lat: 48.4546299, lng: 20.1938204 },
    { lat: 48.4542791, lng: 20.1932516 },
    { lat: 48.4543822, lng: 20.1930467 },
    { lat: 48.4536032, lng: 20.1930237 },
    { lat: 48.4533154, lng: 20.1929818 },
  ],
  Hostice: [
    { lat: 48.2490823, lng: 20.0898653 },
    { lat: 48.2491142, lng: 20.0897927 },
    { lat: 48.2495168, lng: 20.0880635 },
    { lat: 48.2498112, lng: 20.0874725 },
    { lat: 48.2504067, lng: 20.086593 },
    { lat: 48.2514486, lng: 20.0854536 },
    { lat: 48.253429, lng: 20.0826609 },
    { lat: 48.2533984, lng: 20.0826266 },
    { lat: 48.2536148, lng: 20.0824679 },
    { lat: 48.2541338, lng: 20.0815898 },
    { lat: 48.2546763, lng: 20.0809017 },
    { lat: 48.2549813, lng: 20.0801636 },
    { lat: 48.255242, lng: 20.0799846 },
    { lat: 48.2554023, lng: 20.0795582 },
    { lat: 48.2555853, lng: 20.0793277 },
    { lat: 48.255827, lng: 20.0787697 },
    { lat: 48.2565591, lng: 20.0776571 },
    { lat: 48.2569985, lng: 20.0771723 },
    { lat: 48.2571986, lng: 20.0771073 },
    { lat: 48.2573955, lng: 20.0771272 },
    { lat: 48.2576551, lng: 20.0774371 },
    { lat: 48.2579374, lng: 20.0772966 },
    { lat: 48.2580407, lng: 20.0771806 },
    { lat: 48.2581055, lng: 20.0770286 },
    { lat: 48.2581816, lng: 20.0763661 },
    { lat: 48.2585113, lng: 20.0755687 },
    { lat: 48.2588198, lng: 20.0751897 },
    { lat: 48.2593613, lng: 20.0741205 },
    { lat: 48.2599004, lng: 20.0735963 },
    { lat: 48.2602171, lng: 20.0734095 },
    { lat: 48.2604657, lng: 20.0724842 },
    { lat: 48.2606918, lng: 20.0721482 },
    { lat: 48.2618795, lng: 20.0698574 },
    { lat: 48.2625898, lng: 20.0690573 },
    { lat: 48.2629758, lng: 20.0680209 },
    { lat: 48.2631761, lng: 20.0677176 },
    { lat: 48.2633758, lng: 20.0671001 },
    { lat: 48.2640448, lng: 20.0665552 },
    { lat: 48.2645829, lng: 20.0655508 },
    { lat: 48.2649819, lng: 20.0638729 },
    { lat: 48.2651775, lng: 20.0636771 },
    { lat: 48.2657688, lng: 20.0637022 },
    { lat: 48.266041, lng: 20.0638267 },
    { lat: 48.2662497, lng: 20.0637252 },
    { lat: 48.2667179, lng: 20.0636376 },
    { lat: 48.266995, lng: 20.063202 },
    { lat: 48.2673119, lng: 20.0621259 },
    { lat: 48.2675115, lng: 20.0611325 },
    { lat: 48.2676364, lng: 20.0602384 },
    { lat: 48.2676702, lng: 20.0602681 },
    { lat: 48.2677689, lng: 20.0600121 },
    { lat: 48.2680085, lng: 20.0599795 },
    { lat: 48.267904, lng: 20.05925 },
    { lat: 48.267426, lng: 20.0585409 },
    { lat: 48.2670263, lng: 20.0580865 },
    { lat: 48.266943, lng: 20.0578987 },
    { lat: 48.2668114, lng: 20.0566337 },
    { lat: 48.2663155, lng: 20.0557064 },
    { lat: 48.2650721, lng: 20.0543304 },
    { lat: 48.2636214, lng: 20.0531718 },
    { lat: 48.2631448, lng: 20.0529562 },
    { lat: 48.262321, lng: 20.0531075 },
    { lat: 48.2620098, lng: 20.0530207 },
    { lat: 48.2616175, lng: 20.0526873 },
    { lat: 48.2614159, lng: 20.0514763 },
    { lat: 48.2613687, lng: 20.0501526 },
    { lat: 48.2614099, lng: 20.0498447 },
    { lat: 48.2616105, lng: 20.049442 },
    { lat: 48.2615836, lng: 20.0490709 },
    { lat: 48.261677, lng: 20.048418 },
    { lat: 48.2615947, lng: 20.047069 },
    { lat: 48.2618009, lng: 20.046022 },
    { lat: 48.2592168, lng: 20.0446688 },
    { lat: 48.2573409, lng: 20.0473288 },
    { lat: 48.256388, lng: 20.0485704 },
    { lat: 48.2560234, lng: 20.0496883 },
    { lat: 48.2553814, lng: 20.0506603 },
    { lat: 48.2552847, lng: 20.0508776 },
    { lat: 48.2548029, lng: 20.0523464 },
    { lat: 48.2546915, lng: 20.0538859 },
    { lat: 48.2545648, lng: 20.0545216 },
    { lat: 48.2538852, lng: 20.0569431 },
    { lat: 48.2538369, lng: 20.0580051 },
    { lat: 48.2535978, lng: 20.059184 },
    { lat: 48.2532919, lng: 20.0596536 },
    { lat: 48.253352, lng: 20.0603443 },
    { lat: 48.2533212, lng: 20.0606289 },
    { lat: 48.2530807, lng: 20.0612144 },
    { lat: 48.2527179, lng: 20.0618827 },
    { lat: 48.2521831, lng: 20.0613519 },
    { lat: 48.2518491, lng: 20.0609042 },
    { lat: 48.2506546, lng: 20.0588888 },
    { lat: 48.2482581, lng: 20.0565508 },
    { lat: 48.2461734, lng: 20.057832 },
    { lat: 48.2453012, lng: 20.0571183 },
    { lat: 48.2445001, lng: 20.059304 },
    { lat: 48.2440234, lng: 20.0596252 },
    { lat: 48.2423261, lng: 20.0596823 },
    { lat: 48.2409459, lng: 20.0595388 },
    { lat: 48.2407372, lng: 20.0574487 },
    { lat: 48.2406365, lng: 20.0553623 },
    { lat: 48.2402431, lng: 20.0553807 },
    { lat: 48.239711, lng: 20.0552894 },
    { lat: 48.2392728, lng: 20.0553936 },
    { lat: 48.2386838, lng: 20.0557501 },
    { lat: 48.2386839, lng: 20.0561313 },
    { lat: 48.2386179, lng: 20.056547 },
    { lat: 48.2384986, lng: 20.0565504 },
    { lat: 48.2380349, lng: 20.0562068 },
    { lat: 48.2375591, lng: 20.0560329 },
    { lat: 48.2369956, lng: 20.0561397 },
    { lat: 48.2361683, lng: 20.056124 },
    { lat: 48.2356755, lng: 20.056192 },
    { lat: 48.2356249, lng: 20.0560049 },
    { lat: 48.2349151, lng: 20.0549225 },
    { lat: 48.2345641, lng: 20.0540392 },
    { lat: 48.2344655, lng: 20.0540018 },
    { lat: 48.2343587, lng: 20.0532535 },
    { lat: 48.2343346, lng: 20.0523771 },
    { lat: 48.2342023, lng: 20.0512019 },
    { lat: 48.2337675, lng: 20.0500731 },
    { lat: 48.2325466, lng: 20.0458953 },
    { lat: 48.2320138, lng: 20.0451948 },
    { lat: 48.2318103, lng: 20.0447199 },
    { lat: 48.2317335, lng: 20.0442827 },
    { lat: 48.2311514, lng: 20.0429441 },
    { lat: 48.2307784, lng: 20.0425385 },
    { lat: 48.2300807, lng: 20.0413151 },
    { lat: 48.2297182, lng: 20.0413734 },
    { lat: 48.2284034, lng: 20.0422025 },
    { lat: 48.2279982, lng: 20.0423165 },
    { lat: 48.2275317, lng: 20.0422079 },
    { lat: 48.2269781, lng: 20.041981 },
    { lat: 48.2266374, lng: 20.0419172 },
    { lat: 48.2264549, lng: 20.0427511 },
    { lat: 48.2260216, lng: 20.0435357 },
    { lat: 48.2258256, lng: 20.0440765 },
    { lat: 48.2257871, lng: 20.0446344 },
    { lat: 48.2256975, lng: 20.0450271 },
    { lat: 48.2256573, lng: 20.0463581 },
    { lat: 48.2254219, lng: 20.0472976 },
    { lat: 48.2249469, lng: 20.0498159 },
    { lat: 48.2248783, lng: 20.0510326 },
    { lat: 48.2245139, lng: 20.0529855 },
    { lat: 48.2243872, lng: 20.054054 },
    { lat: 48.2241742, lng: 20.0565583 },
    { lat: 48.2241636, lng: 20.0567691 },
    { lat: 48.2242205, lng: 20.05678 },
    { lat: 48.2241338, lng: 20.058862 },
    { lat: 48.2239532, lng: 20.0601234 },
    { lat: 48.2238044, lng: 20.0618209 },
    { lat: 48.2234061, lng: 20.0620788 },
    { lat: 48.2226632, lng: 20.0628988 },
    { lat: 48.2226587, lng: 20.0632266 },
    { lat: 48.2216928, lng: 20.0637247 },
    { lat: 48.2209596, lng: 20.0639457 },
    { lat: 48.2207163, lng: 20.0640825 },
    { lat: 48.2205723, lng: 20.0649493 },
    { lat: 48.2206072, lng: 20.066088 },
    { lat: 48.2205691, lng: 20.0663342 },
    { lat: 48.2204909, lng: 20.0666216 },
    { lat: 48.2202261, lng: 20.0671613 },
    { lat: 48.2197677, lng: 20.0677289 },
    { lat: 48.2197492, lng: 20.068395 },
    { lat: 48.2197881, lng: 20.0686789 },
    { lat: 48.2198974, lng: 20.0690068 },
    { lat: 48.2199463, lng: 20.0694727 },
    { lat: 48.2199571, lng: 20.0703077 },
    { lat: 48.2196475, lng: 20.0706026 },
    { lat: 48.2192747, lng: 20.0723959 },
    { lat: 48.219033, lng: 20.0733258 },
    { lat: 48.2186963, lng: 20.0735228 },
    { lat: 48.2179769, lng: 20.0737459 },
    { lat: 48.2176947, lng: 20.0743835 },
    { lat: 48.2175309, lng: 20.074913 },
    { lat: 48.2174168, lng: 20.0757173 },
    { lat: 48.2174112, lng: 20.0769674 },
    { lat: 48.2173209, lng: 20.0771817 },
    { lat: 48.2168386, lng: 20.0777988 },
    { lat: 48.2163791, lng: 20.0781247 },
    { lat: 48.2162344, lng: 20.0783582 },
    { lat: 48.2160286, lng: 20.0793416 },
    { lat: 48.2161819, lng: 20.0801064 },
    { lat: 48.2162106, lng: 20.0806901 },
    { lat: 48.2163204, lng: 20.0811913 },
    { lat: 48.2163502, lng: 20.0815555 },
    { lat: 48.2163153, lng: 20.0823417 },
    { lat: 48.21626, lng: 20.0826079 },
    { lat: 48.2161527, lng: 20.0828492 },
    { lat: 48.2157224, lng: 20.0834987 },
    { lat: 48.2157192, lng: 20.0835852 },
    { lat: 48.2155809, lng: 20.0835635 },
    { lat: 48.2142185, lng: 20.0839556 },
    { lat: 48.2140312, lng: 20.083926 },
    { lat: 48.2135989, lng: 20.0840654 },
    { lat: 48.2132821, lng: 20.0840305 },
    { lat: 48.2126699, lng: 20.0836625 },
    { lat: 48.2119211, lng: 20.0830731 },
    { lat: 48.210849, lng: 20.0829724 },
    { lat: 48.2106638, lng: 20.0830412 },
    { lat: 48.2099113, lng: 20.0829857 },
    { lat: 48.2097975, lng: 20.0830418 },
    { lat: 48.2097135, lng: 20.0831601 },
    { lat: 48.2095557, lng: 20.0842685 },
    { lat: 48.2093745, lng: 20.0844912 },
    { lat: 48.2081775, lng: 20.0850709 },
    { lat: 48.2079941, lng: 20.0853722 },
    { lat: 48.2077839, lng: 20.0860365 },
    { lat: 48.2070144, lng: 20.0877425 },
    { lat: 48.2062442, lng: 20.0885407 },
    { lat: 48.205876, lng: 20.0890586 },
    { lat: 48.205302, lng: 20.0901535 },
    { lat: 48.2047466, lng: 20.0905871 },
    { lat: 48.204545, lng: 20.0912979 },
    { lat: 48.2043489, lng: 20.0924107 },
    { lat: 48.2042399, lng: 20.0937234 },
    { lat: 48.204027, lng: 20.0954104 },
    { lat: 48.203962, lng: 20.095447 },
    { lat: 48.203998, lng: 20.095473 },
    { lat: 48.203944, lng: 20.095984 },
    { lat: 48.203899, lng: 20.096518 },
    { lat: 48.203866, lng: 20.096867 },
    { lat: 48.203792, lng: 20.097252 },
    { lat: 48.203725, lng: 20.097516 },
    { lat: 48.203624, lng: 20.097875 },
    { lat: 48.203589, lng: 20.098054 },
    { lat: 48.203585, lng: 20.098281 },
    { lat: 48.203643, lng: 20.09851 },
    { lat: 48.203785, lng: 20.098884 },
    { lat: 48.203939, lng: 20.099213 },
    { lat: 48.203985, lng: 20.099297 },
    { lat: 48.204211, lng: 20.099636 },
    { lat: 48.204383, lng: 20.100063 },
    { lat: 48.204458, lng: 20.100214 },
    { lat: 48.204494, lng: 20.100275 },
    { lat: 48.204584, lng: 20.100362 },
    { lat: 48.204989, lng: 20.100456 },
    { lat: 48.205202, lng: 20.100579 },
    { lat: 48.20534, lng: 20.100633 },
    { lat: 48.205706, lng: 20.1008 },
    { lat: 48.206066, lng: 20.100782 },
    { lat: 48.206171, lng: 20.100384 },
    { lat: 48.206421, lng: 20.099932 },
    { lat: 48.206738, lng: 20.099542 },
    { lat: 48.207062, lng: 20.099709 },
    { lat: 48.207252, lng: 20.09993 },
    { lat: 48.207407, lng: 20.100118 },
    { lat: 48.207959, lng: 20.100561 },
    { lat: 48.20823, lng: 20.101129 },
    { lat: 48.208545, lng: 20.101594 },
    { lat: 48.208875, lng: 20.101772 },
    { lat: 48.209192, lng: 20.101876 },
    { lat: 48.209453, lng: 20.102 },
    { lat: 48.209537, lng: 20.102506 },
    { lat: 48.209563, lng: 20.102917 },
    { lat: 48.209627, lng: 20.104085 },
    { lat: 48.209785, lng: 20.104619 },
    { lat: 48.209971, lng: 20.105519 },
    { lat: 48.210273, lng: 20.106065 },
    { lat: 48.210557, lng: 20.106522 },
    { lat: 48.210727, lng: 20.106749 },
    { lat: 48.210855, lng: 20.106988 },
    { lat: 48.211258, lng: 20.107286 },
    { lat: 48.211822, lng: 20.107739 },
    { lat: 48.212037, lng: 20.108088 },
    { lat: 48.212341, lng: 20.108679 },
    { lat: 48.212366, lng: 20.109613 },
    { lat: 48.212838, lng: 20.109911 },
    { lat: 48.213099, lng: 20.110205 },
    { lat: 48.213258, lng: 20.110389 },
    { lat: 48.213616, lng: 20.110821 },
    { lat: 48.213852, lng: 20.111453 },
    { lat: 48.214003, lng: 20.111696 },
    { lat: 48.21423, lng: 20.111941 },
    { lat: 48.214569, lng: 20.111665 },
    { lat: 48.215051, lng: 20.111854 },
    { lat: 48.215351, lng: 20.112164 },
    { lat: 48.215623, lng: 20.112348 },
    { lat: 48.216074, lng: 20.112544 },
    { lat: 48.216254, lng: 20.11313 },
    { lat: 48.216315, lng: 20.113809 },
    { lat: 48.216463, lng: 20.114293 },
    { lat: 48.216237, lng: 20.114969 },
    { lat: 48.216104, lng: 20.115613 },
    { lat: 48.215996, lng: 20.11626 },
    { lat: 48.216007, lng: 20.11669 },
    { lat: 48.216199, lng: 20.117081 },
    { lat: 48.216617, lng: 20.117414 },
    { lat: 48.216766, lng: 20.117709 },
    { lat: 48.217052, lng: 20.118069 },
    { lat: 48.217189, lng: 20.118505 },
    { lat: 48.217219, lng: 20.119056 },
    { lat: 48.217186, lng: 20.119919 },
    { lat: 48.217241, lng: 20.120115 },
    { lat: 48.217505, lng: 20.120039 },
    { lat: 48.218043, lng: 20.120295 },
    { lat: 48.218281, lng: 20.120619 },
    { lat: 48.218463, lng: 20.120852 },
    { lat: 48.219415, lng: 20.122069 },
    { lat: 48.219798, lng: 20.122666 },
    { lat: 48.220708, lng: 20.1226 },
    { lat: 48.220771, lng: 20.123231 },
    { lat: 48.22078, lng: 20.123998 },
    { lat: 48.220717, lng: 20.124359 },
    { lat: 48.220693, lng: 20.12477 },
    { lat: 48.220805, lng: 20.125665 },
    { lat: 48.220826, lng: 20.126297 },
    { lat: 48.220577, lng: 20.127127 },
    { lat: 48.220567, lng: 20.127149 },
    { lat: 48.220611, lng: 20.127312 },
    { lat: 48.220629, lng: 20.127369 },
    { lat: 48.220677, lng: 20.127525 },
    { lat: 48.220818, lng: 20.127848 },
    { lat: 48.221177, lng: 20.128409 },
    { lat: 48.22143, lng: 20.128916 },
    { lat: 48.221781, lng: 20.129382 },
    { lat: 48.222124, lng: 20.129954 },
    { lat: 48.222334, lng: 20.13023 },
    { lat: 48.222501, lng: 20.130392 },
    { lat: 48.222762, lng: 20.130699 },
    { lat: 48.222922, lng: 20.130847 },
    { lat: 48.223053, lng: 20.130938 },
    { lat: 48.223227, lng: 20.13101 },
    { lat: 48.223687, lng: 20.131286 },
    { lat: 48.223751, lng: 20.131313 },
    { lat: 48.223912, lng: 20.131339 },
    { lat: 48.224001, lng: 20.131359 },
    { lat: 48.22409, lng: 20.131387 },
    { lat: 48.224185, lng: 20.131428 },
    { lat: 48.224261, lng: 20.13149 },
    { lat: 48.224299, lng: 20.131524 },
    { lat: 48.224461, lng: 20.131738 },
    { lat: 48.224604, lng: 20.131885 },
    { lat: 48.224866, lng: 20.132139 },
    { lat: 48.225008, lng: 20.132232 },
    { lat: 48.225, lng: 20.132257 },
    { lat: 48.225063, lng: 20.133201 },
    { lat: 48.225132, lng: 20.133645 },
    { lat: 48.225182, lng: 20.134091 },
    { lat: 48.225508, lng: 20.134061 },
    { lat: 48.225749, lng: 20.134098 },
    { lat: 48.225833, lng: 20.134126 },
    { lat: 48.226175, lng: 20.134116 },
    { lat: 48.226425, lng: 20.134232 },
    { lat: 48.226642, lng: 20.134186 },
    { lat: 48.226917, lng: 20.13424 },
    { lat: 48.227326, lng: 20.133822 },
    { lat: 48.227474, lng: 20.133675 },
    { lat: 48.227523, lng: 20.133607 },
    { lat: 48.227875, lng: 20.133261 },
    { lat: 48.227793, lng: 20.1314063 },
    { lat: 48.2278579, lng: 20.1301855 },
    { lat: 48.2278243, lng: 20.1301667 },
    { lat: 48.2281433, lng: 20.1291016 },
    { lat: 48.229059, lng: 20.1270999 },
    { lat: 48.22933, lng: 20.1260713 },
    { lat: 48.2299005, lng: 20.1242242 },
    { lat: 48.2303447, lng: 20.1234145 },
    { lat: 48.2305712, lng: 20.1232513 },
    { lat: 48.2313922, lng: 20.1228623 },
    { lat: 48.2316068, lng: 20.1225196 },
    { lat: 48.2318691, lng: 20.1219122 },
    { lat: 48.2319783, lng: 20.1205158 },
    { lat: 48.2323932, lng: 20.1179799 },
    { lat: 48.2324937, lng: 20.1176304 },
    { lat: 48.2332252, lng: 20.1167429 },
    { lat: 48.2335888, lng: 20.1157537 },
    { lat: 48.2335999, lng: 20.1154721 },
    { lat: 48.2334299, lng: 20.1149275 },
    { lat: 48.233382, lng: 20.1136562 },
    { lat: 48.2335899, lng: 20.1127718 },
    { lat: 48.2337305, lng: 20.1123571 },
    { lat: 48.2339275, lng: 20.1119715 },
    { lat: 48.2349562, lng: 20.1107211 },
    { lat: 48.2356979, lng: 20.1104081 },
    { lat: 48.236141, lng: 20.1105014 },
    { lat: 48.2361662, lng: 20.1085704 },
    { lat: 48.2363043, lng: 20.1084799 },
    { lat: 48.2371673, lng: 20.1083429 },
    { lat: 48.2374875, lng: 20.1080787 },
    { lat: 48.2382548, lng: 20.107648 },
    { lat: 48.2386567, lng: 20.1075084 },
    { lat: 48.2389834, lng: 20.1072857 },
    { lat: 48.2389892, lng: 20.1060933 },
    { lat: 48.2392001, lng: 20.1053594 },
    { lat: 48.2394827, lng: 20.1050064 },
    { lat: 48.240119, lng: 20.1031172 },
    { lat: 48.2399054, lng: 20.1021978 },
    { lat: 48.2402188, lng: 20.1013345 },
    { lat: 48.2402097, lng: 20.100376 },
    { lat: 48.2403596, lng: 20.0987141 },
    { lat: 48.2408227, lng: 20.0987786 },
    { lat: 48.2416753, lng: 20.0990481 },
    { lat: 48.2419378, lng: 20.0990482 },
    { lat: 48.2440258, lng: 20.0977589 },
    { lat: 48.2441325, lng: 20.0968511 },
    { lat: 48.2441878, lng: 20.0948381 },
    { lat: 48.2444518, lng: 20.0929694 },
    { lat: 48.2450788, lng: 20.0924172 },
    { lat: 48.2457575, lng: 20.0922931 },
    { lat: 48.2461109, lng: 20.0918507 },
    { lat: 48.2464929, lng: 20.0910947 },
    { lat: 48.2466885, lng: 20.090813 },
    { lat: 48.2476523, lng: 20.0905875 },
    { lat: 48.2484617, lng: 20.0899215 },
    { lat: 48.2485587, lng: 20.0899496 },
    { lat: 48.2487153, lng: 20.0898435 },
    { lat: 48.2489845, lng: 20.0900864 },
    { lat: 48.2490672, lng: 20.0899328 },
    { lat: 48.2490823, lng: 20.0898653 },
  ],
  Tisovec: [
    { lat: 48.711057, lng: 19.818721 },
    { lat: 48.7101048, lng: 19.8199731 },
    { lat: 48.7099305, lng: 19.8206303 },
    { lat: 48.7093136, lng: 19.822486 },
    { lat: 48.7088201, lng: 19.8247169 },
    { lat: 48.7087381, lng: 19.8255825 },
    { lat: 48.708365, lng: 19.826489 },
    { lat: 48.7080501, lng: 19.8270235 },
    { lat: 48.7075507, lng: 19.8280788 },
    { lat: 48.707216, lng: 19.8289904 },
    { lat: 48.7071396, lng: 19.8306626 },
    { lat: 48.7070418, lng: 19.831446 },
    { lat: 48.7072513, lng: 19.8362898 },
    { lat: 48.7071112, lng: 19.8369578 },
    { lat: 48.7067142, lng: 19.8378613 },
    { lat: 48.7065565, lng: 19.8389251 },
    { lat: 48.7061774, lng: 19.8400636 },
    { lat: 48.7055267, lng: 19.8409346 },
    { lat: 48.7046402, lng: 19.8430771 },
    { lat: 48.7049108, lng: 19.8444344 },
    { lat: 48.70527, lng: 19.8457611 },
    { lat: 48.7057737, lng: 19.8470726 },
    { lat: 48.7057925, lng: 19.8475843 },
    { lat: 48.7056003, lng: 19.8486401 },
    { lat: 48.7050642, lng: 19.8497957 },
    { lat: 48.7040022, lng: 19.8518669 },
    { lat: 48.7037005, lng: 19.8527326 },
    { lat: 48.7033584, lng: 19.853318 },
    { lat: 48.7029269, lng: 19.8536329 },
    { lat: 48.7022482, lng: 19.853725 },
    { lat: 48.7013917, lng: 19.8539765 },
    { lat: 48.6997943, lng: 19.8540063 },
    { lat: 48.6988082, lng: 19.8536817 },
    { lat: 48.6981699, lng: 19.8536946 },
    { lat: 48.6963227, lng: 19.8544333 },
    { lat: 48.6957235, lng: 19.8550421 },
    { lat: 48.6948422, lng: 19.8562081 },
    { lat: 48.694303, lng: 19.8564544 },
    { lat: 48.6928994, lng: 19.8574528 },
    { lat: 48.6921191, lng: 19.8574628 },
    { lat: 48.6912553, lng: 19.8576757 },
    { lat: 48.6904493, lng: 19.8593915 },
    { lat: 48.6897954, lng: 19.8611066 },
    { lat: 48.6879194, lng: 19.8651304 },
    { lat: 48.6878089, lng: 19.8657075 },
    { lat: 48.6876383, lng: 19.8673443 },
    { lat: 48.6865745, lng: 19.8685193 },
    { lat: 48.6858818, lng: 19.8688093 },
    { lat: 48.6849172, lng: 19.869484 },
    { lat: 48.684158, lng: 19.8697751 },
    { lat: 48.6827715, lng: 19.8701249 },
    { lat: 48.6824381, lng: 19.8700192 },
    { lat: 48.6819238, lng: 19.8700041 },
    { lat: 48.6817233, lng: 19.8696405 },
    { lat: 48.6813227, lng: 19.8692819 },
    { lat: 48.6808715, lng: 19.8687479 },
    { lat: 48.679666, lng: 19.8680083 },
    { lat: 48.6791367, lng: 19.8671512 },
    { lat: 48.6771387, lng: 19.8672429 },
    { lat: 48.6768905, lng: 19.8670195 },
    { lat: 48.6760287, lng: 19.8659459 },
    { lat: 48.6754886, lng: 19.8655472 },
    { lat: 48.6751981, lng: 19.8654438 },
    { lat: 48.6747048, lng: 19.8648149 },
    { lat: 48.6738544, lng: 19.8646656 },
    { lat: 48.6728823, lng: 19.864402 },
    { lat: 48.672332, lng: 19.8646823 },
    { lat: 48.6719881, lng: 19.8647556 },
    { lat: 48.6714062, lng: 19.8647663 },
    { lat: 48.6707696, lng: 19.864894 },
    { lat: 48.6701653, lng: 19.8651963 },
    { lat: 48.6695938, lng: 19.8656865 },
    { lat: 48.6684219, lng: 19.8675396 },
    { lat: 48.6684163, lng: 19.8678049 },
    { lat: 48.6678887, lng: 19.8697067 },
    { lat: 48.6665353, lng: 19.8714534 },
    { lat: 48.6658187, lng: 19.8717403 },
    { lat: 48.6657623, lng: 19.8721863 },
    { lat: 48.6655714, lng: 19.8727752 },
    { lat: 48.6650259, lng: 19.8739744 },
    { lat: 48.6636848, lng: 19.8733869 },
    { lat: 48.6630936, lng: 19.8740228 },
    { lat: 48.6626618, lng: 19.8746441 },
    { lat: 48.6621659, lng: 19.8747179 },
    { lat: 48.6615656, lng: 19.8746687 },
    { lat: 48.6610515, lng: 19.8742953 },
    { lat: 48.6606333, lng: 19.8745782 },
    { lat: 48.6600321, lng: 19.8738481 },
    { lat: 48.6593385, lng: 19.8725187 },
    { lat: 48.65892, lng: 19.8718545 },
    { lat: 48.6588326, lng: 19.8715502 },
    { lat: 48.6580748, lng: 19.8704581 },
    { lat: 48.6577457, lng: 19.8697713 },
    { lat: 48.6572739, lng: 19.8694116 },
    { lat: 48.6566507, lng: 19.8696138 },
    { lat: 48.6563877, lng: 19.8696591 },
    { lat: 48.6561185, lng: 19.8696848 },
    { lat: 48.6559915, lng: 19.8697158 },
    { lat: 48.6559744, lng: 19.8697089 },
    { lat: 48.6558593, lng: 19.8701499 },
    { lat: 48.6558337, lng: 19.8704492 },
    { lat: 48.6558888, lng: 19.8707592 },
    { lat: 48.6559874, lng: 19.8714005 },
    { lat: 48.6560053, lng: 19.8718441 },
    { lat: 48.655829, lng: 19.8722797 },
    { lat: 48.6557361, lng: 19.8723212 },
    { lat: 48.6555406, lng: 19.8723582 },
    { lat: 48.6553592, lng: 19.872434 },
    { lat: 48.6552474, lng: 19.8724799 },
    { lat: 48.6549266, lng: 19.8725947 },
    { lat: 48.6546258, lng: 19.872747 },
    { lat: 48.6542571, lng: 19.8728202 },
    { lat: 48.6536346, lng: 19.8726506 },
    { lat: 48.6535459, lng: 19.8726785 },
    { lat: 48.6534323, lng: 19.872793 },
    { lat: 48.6532582, lng: 19.8730936 },
    { lat: 48.6532289, lng: 19.8731684 },
    { lat: 48.6530622, lng: 19.8735924 },
    { lat: 48.6530484, lng: 19.8735827 },
    { lat: 48.6530041, lng: 19.8736013 },
    { lat: 48.6523902, lng: 19.8739179 },
    { lat: 48.6518707, lng: 19.8741515 },
    { lat: 48.6516039, lng: 19.874184 },
    { lat: 48.6510644, lng: 19.8745271 },
    { lat: 48.6508175, lng: 19.8746771 },
    { lat: 48.6499001, lng: 19.8748783 },
    { lat: 48.6497751, lng: 19.8750908 },
    { lat: 48.6496598, lng: 19.8754238 },
    { lat: 48.6495196, lng: 19.8758315 },
    { lat: 48.6490669, lng: 19.8763051 },
    { lat: 48.6490555, lng: 19.8764088 },
    { lat: 48.6490475, lng: 19.8764848 },
    { lat: 48.6490065, lng: 19.8768675 },
    { lat: 48.6488656, lng: 19.8774185 },
    { lat: 48.6486493, lng: 19.8780988 },
    { lat: 48.6485787, lng: 19.8783946 },
    { lat: 48.6482226, lng: 19.8790732 },
    { lat: 48.6482363, lng: 19.8797124 },
    { lat: 48.6482376, lng: 19.8797639 },
    { lat: 48.648255, lng: 19.8806433 },
    { lat: 48.6483334, lng: 19.8810008 },
    { lat: 48.6482951, lng: 19.8810562 },
    { lat: 48.6480993, lng: 19.8812459 },
    { lat: 48.6478438, lng: 19.8815356 },
    { lat: 48.6477541, lng: 19.8816783 },
    { lat: 48.6476946, lng: 19.8818384 },
    { lat: 48.6476925, lng: 19.8820812 },
    { lat: 48.6477961, lng: 19.8825257 },
    { lat: 48.6478481, lng: 19.8829517 },
    { lat: 48.6477065, lng: 19.8830122 },
    { lat: 48.6476634, lng: 19.883031 },
    { lat: 48.6476079, lng: 19.8830257 },
    { lat: 48.6475119, lng: 19.8830155 },
    { lat: 48.6474915, lng: 19.8830139 },
    { lat: 48.647189, lng: 19.8831054 },
    { lat: 48.6470681, lng: 19.8831767 },
    { lat: 48.6466449, lng: 19.8832102 },
    { lat: 48.6462997, lng: 19.8830708 },
    { lat: 48.645647, lng: 19.8829012 },
    { lat: 48.645536, lng: 19.8828889 },
    { lat: 48.6454231, lng: 19.8834596 },
    { lat: 48.6451012, lng: 19.8838091 },
    { lat: 48.6447422, lng: 19.8839208 },
    { lat: 48.6447021, lng: 19.8843778 },
    { lat: 48.6446109, lng: 19.8848566 },
    { lat: 48.6444959, lng: 19.8847998 },
    { lat: 48.6439073, lng: 19.8846268 },
    { lat: 48.6439917, lng: 19.8854175 },
    { lat: 48.6440659, lng: 19.8859934 },
    { lat: 48.6440966, lng: 19.8862348 },
    { lat: 48.644091, lng: 19.8864463 },
    { lat: 48.6440888, lng: 19.8866805 },
    { lat: 48.6440424, lng: 19.8870477 },
    { lat: 48.6438975, lng: 19.8875812 },
    { lat: 48.6437525, lng: 19.8881653 },
    { lat: 48.64356, lng: 19.8886133 },
    { lat: 48.6433208, lng: 19.8888447 },
    { lat: 48.6430943, lng: 19.8890777 },
    { lat: 48.6431421, lng: 19.8892472 },
    { lat: 48.6430045, lng: 19.8896393 },
    { lat: 48.6427504, lng: 19.8898073 },
    { lat: 48.6423928, lng: 19.8900341 },
    { lat: 48.6421982, lng: 19.8901984 },
    { lat: 48.6421775, lng: 19.8902161 },
    { lat: 48.6415853, lng: 19.891116 },
    { lat: 48.6412845, lng: 19.891701 },
    { lat: 48.6407697, lng: 19.8916464 },
    { lat: 48.6409947, lng: 19.8923252 },
    { lat: 48.641044, lng: 19.8924739 },
    { lat: 48.6409481, lng: 19.8928633 },
    { lat: 48.6407349, lng: 19.8937293 },
    { lat: 48.6409677, lng: 19.8938506 },
    { lat: 48.6411125, lng: 19.893996 },
    { lat: 48.6412205, lng: 19.8942537 },
    { lat: 48.640939, lng: 19.894626 },
    { lat: 48.6403931, lng: 19.8952188 },
    { lat: 48.6401809, lng: 19.8958151 },
    { lat: 48.6400707, lng: 19.8962675 },
    { lat: 48.6403192, lng: 19.8966248 },
    { lat: 48.6402987, lng: 19.8969078 },
    { lat: 48.6402822, lng: 19.8971295 },
    { lat: 48.6401156, lng: 19.8974735 },
    { lat: 48.6398218, lng: 19.8979277 },
    { lat: 48.6395517, lng: 19.8984851 },
    { lat: 48.6395039, lng: 19.8986297 },
    { lat: 48.6392069, lng: 19.8995345 },
    { lat: 48.63915, lng: 19.899706 },
    { lat: 48.6390707, lng: 19.8999606 },
    { lat: 48.6390466, lng: 19.9000313 },
    { lat: 48.6388444, lng: 19.9009682 },
    { lat: 48.6386487, lng: 19.9011062 },
    { lat: 48.6383374, lng: 19.9016761 },
    { lat: 48.6378742, lng: 19.9025439 },
    { lat: 48.6378549, lng: 19.9025939 },
    { lat: 48.6375788, lng: 19.9025919 },
    { lat: 48.6374962, lng: 19.9027125 },
    { lat: 48.6374086, lng: 19.9030376 },
    { lat: 48.6373717, lng: 19.9031755 },
    { lat: 48.637077, lng: 19.9031561 },
    { lat: 48.6369537, lng: 19.9031481 },
    { lat: 48.6367363, lng: 19.9031493 },
    { lat: 48.63654, lng: 19.903246 },
    { lat: 48.6364137, lng: 19.9034542 },
    { lat: 48.6363256, lng: 19.9037139 },
    { lat: 48.6363505, lng: 19.9037667 },
    { lat: 48.6364037, lng: 19.9038797 },
    { lat: 48.6364394, lng: 19.9042659 },
    { lat: 48.6362885, lng: 19.9050556 },
    { lat: 48.6361118, lng: 19.9053025 },
    { lat: 48.6357708, lng: 19.9053606 },
    { lat: 48.6356597, lng: 19.9054497 },
    { lat: 48.6355348, lng: 19.9057981 },
    { lat: 48.6348748, lng: 19.9065122 },
    { lat: 48.6339085, lng: 19.906703 },
    { lat: 48.6336679, lng: 19.9073222 },
    { lat: 48.6336408, lng: 19.907317 },
    { lat: 48.6333451, lng: 19.9078888 },
    { lat: 48.6332206, lng: 19.9080858 },
    { lat: 48.6330382, lng: 19.9086958 },
    { lat: 48.6329678, lng: 19.9091307 },
    { lat: 48.6329497, lng: 19.9092322 },
    { lat: 48.63294, lng: 19.9095875 },
    { lat: 48.632935, lng: 19.909734 },
    { lat: 48.6329722, lng: 19.9101032 },
    { lat: 48.6329231, lng: 19.9104082 },
    { lat: 48.6328139, lng: 19.91088 },
    { lat: 48.6327874, lng: 19.9111611 },
    { lat: 48.6326119, lng: 19.9114588 },
    { lat: 48.6323495, lng: 19.9115651 },
    { lat: 48.6321032, lng: 19.9117062 },
    { lat: 48.6319031, lng: 19.9119802 },
    { lat: 48.6316719, lng: 19.9124753 },
    { lat: 48.6312698, lng: 19.9132162 },
    { lat: 48.6312082, lng: 19.9133547 },
    { lat: 48.6310716, lng: 19.9136674 },
    { lat: 48.6308062, lng: 19.9141017 },
    { lat: 48.6307801, lng: 19.9141484 },
    { lat: 48.6306282, lng: 19.9147062 },
    { lat: 48.6302051, lng: 19.9154577 },
    { lat: 48.6297104, lng: 19.9161189 },
    { lat: 48.6291784, lng: 19.9167688 },
    { lat: 48.6288171, lng: 19.9172033 },
    { lat: 48.6285464, lng: 19.9173777 },
    { lat: 48.6285476, lng: 19.9174043 },
    { lat: 48.6294994, lng: 19.9190453 },
    { lat: 48.6296642, lng: 19.9203982 },
    { lat: 48.6296664, lng: 19.9213549 },
    { lat: 48.6294202, lng: 19.924145 },
    { lat: 48.6299631, lng: 19.926237 },
    { lat: 48.6305585, lng: 19.9275711 },
    { lat: 48.6306547, lng: 19.9279564 },
    { lat: 48.6313689, lng: 19.9291754 },
    { lat: 48.6315172, lng: 19.9296429 },
    { lat: 48.6316959, lng: 19.9305316 },
    { lat: 48.6322704, lng: 19.9316755 },
    { lat: 48.6324322, lng: 19.9321941 },
    { lat: 48.6329761, lng: 19.9329285 },
    { lat: 48.6332311, lng: 19.9339752 },
    { lat: 48.633385, lng: 19.9342584 },
    { lat: 48.6331252, lng: 19.9358077 },
    { lat: 48.6336581, lng: 19.9379294 },
    { lat: 48.6336951, lng: 19.9385406 },
    { lat: 48.6335228, lng: 19.9394995 },
    { lat: 48.633621, lng: 19.940062 },
    { lat: 48.6336755, lng: 19.9410803 },
    { lat: 48.6334632, lng: 19.9417582 },
    { lat: 48.63336, lng: 19.9428871 },
    { lat: 48.6334839, lng: 19.9435764 },
    { lat: 48.6334013, lng: 19.9436506 },
    { lat: 48.6334177, lng: 19.9443257 },
    { lat: 48.6335546, lng: 19.9448731 },
    { lat: 48.6339001, lng: 19.9472348 },
    { lat: 48.6336271, lng: 19.9474363 },
    { lat: 48.6337264, lng: 19.9478644 },
    { lat: 48.6340677, lng: 19.9490909 },
    { lat: 48.6348288, lng: 19.9488929 },
    { lat: 48.6350742, lng: 19.9489177 },
    { lat: 48.6356081, lng: 19.949179 },
    { lat: 48.6357457, lng: 19.9496622 },
    { lat: 48.6360612, lng: 19.9500684 },
    { lat: 48.6361638, lng: 19.9504126 },
    { lat: 48.636297, lng: 19.9505037 },
    { lat: 48.6363062, lng: 19.9508487 },
    { lat: 48.6365446, lng: 19.9509212 },
    { lat: 48.6366132, lng: 19.9511874 },
    { lat: 48.6367082, lng: 19.951273 },
    { lat: 48.6367372, lng: 19.9515832 },
    { lat: 48.6366412, lng: 19.9517916 },
    { lat: 48.636778, lng: 19.9520961 },
    { lat: 48.6368125, lng: 19.9523268 },
    { lat: 48.6369049, lng: 19.9523791 },
    { lat: 48.6368984, lng: 19.9527056 },
    { lat: 48.6369413, lng: 19.9528707 },
    { lat: 48.6369102, lng: 19.9529848 },
    { lat: 48.6369829, lng: 19.9531623 },
    { lat: 48.6368691, lng: 19.9533973 },
    { lat: 48.6369478, lng: 19.9537954 },
    { lat: 48.6369446, lng: 19.9540952 },
    { lat: 48.6370459, lng: 19.9541731 },
    { lat: 48.6371285, lng: 19.9545807 },
    { lat: 48.6372382, lng: 19.9546688 },
    { lat: 48.6372219, lng: 19.9550593 },
    { lat: 48.6372653, lng: 19.9553383 },
    { lat: 48.6373667, lng: 19.9554468 },
    { lat: 48.6373473, lng: 19.955787 },
    { lat: 48.637481, lng: 19.9559611 },
    { lat: 48.637517, lng: 19.9564396 },
    { lat: 48.637611, lng: 19.9566421 },
    { lat: 48.6377235, lng: 19.9567178 },
    { lat: 48.6379111, lng: 19.9570242 },
    { lat: 48.6379973, lng: 19.9575448 },
    { lat: 48.6381969, lng: 19.9578711 },
    { lat: 48.638266, lng: 19.9581434 },
    { lat: 48.6383953, lng: 19.959473 },
    { lat: 48.6383735, lng: 19.9596788 },
    { lat: 48.6382792, lng: 19.9597648 },
    { lat: 48.6382651, lng: 19.9598844 },
    { lat: 48.6383397, lng: 19.96056 },
    { lat: 48.6382024, lng: 19.9611776 },
    { lat: 48.6381995, lng: 19.9619361 },
    { lat: 48.637885, lng: 19.9627001 },
    { lat: 48.6378392, lng: 19.9630574 },
    { lat: 48.6377033, lng: 19.9633618 },
    { lat: 48.6377143, lng: 19.9635452 },
    { lat: 48.6376349, lng: 19.9639564 },
    { lat: 48.6374049, lng: 19.9646473 },
    { lat: 48.6373647, lng: 19.964898 },
    { lat: 48.6372293, lng: 19.9649466 },
    { lat: 48.6371507, lng: 19.9652423 },
    { lat: 48.6370211, lng: 19.9654278 },
    { lat: 48.6368651, lng: 19.9660315 },
    { lat: 48.63651, lng: 19.9665065 },
    { lat: 48.6362882, lng: 19.967097 },
    { lat: 48.6361634, lng: 19.9679323 },
    { lat: 48.6363763, lng: 19.9692631 },
    { lat: 48.6367173, lng: 19.9701692 },
    { lat: 48.6382477, lng: 19.971467 },
    { lat: 48.6384796, lng: 19.971541 },
    { lat: 48.6386524, lng: 19.971851 },
    { lat: 48.6391011, lng: 19.9719618 },
    { lat: 48.6395149, lng: 19.9733354 },
    { lat: 48.6397784, lng: 19.9738184 },
    { lat: 48.6399434, lng: 19.9745253 },
    { lat: 48.6398805, lng: 19.9754428 },
    { lat: 48.6399359, lng: 19.9757943 },
    { lat: 48.6402702, lng: 19.9762439 },
    { lat: 48.6403574, lng: 19.9771391 },
    { lat: 48.6402394, lng: 19.9779187 },
    { lat: 48.6402953, lng: 19.9788216 },
    { lat: 48.6404401, lng: 19.9791048 },
    { lat: 48.6403548, lng: 19.9797548 },
    { lat: 48.640523, lng: 19.9806166 },
    { lat: 48.6404789, lng: 19.981123 },
    { lat: 48.6407027, lng: 19.9811967 },
    { lat: 48.6409819, lng: 19.9812957 },
    { lat: 48.6416498, lng: 19.9813219 },
    { lat: 48.6426263, lng: 19.9819387 },
    { lat: 48.6433849, lng: 19.9820545 },
    { lat: 48.6442347, lng: 19.9823593 },
    { lat: 48.645268, lng: 19.9830929 },
    { lat: 48.6458078, lng: 19.9836365 },
    { lat: 48.6461653, lng: 19.983993 },
    { lat: 48.6464778, lng: 19.9847586 },
    { lat: 48.6468957, lng: 19.9849954 },
    { lat: 48.6474343, lng: 19.9857532 },
    { lat: 48.6484181, lng: 19.9865408 },
    { lat: 48.6487953, lng: 19.9867544 },
    { lat: 48.650171, lng: 19.9873229 },
    { lat: 48.6505073, lng: 19.9873652 },
    { lat: 48.6507908, lng: 19.987196 },
    { lat: 48.6516847, lng: 19.9869146 },
    { lat: 48.6522365, lng: 19.9872074 },
    { lat: 48.6526826, lng: 19.9871554 },
    { lat: 48.6529507, lng: 19.9869905 },
    { lat: 48.6535629, lng: 19.9871411 },
    { lat: 48.6540062, lng: 19.9871226 },
    { lat: 48.6544382, lng: 19.9869505 },
    { lat: 48.6547612, lng: 19.9867322 },
    { lat: 48.6550259, lng: 19.9866499 },
    { lat: 48.6552474, lng: 19.986664 },
    { lat: 48.6552461, lng: 19.9865551 },
    { lat: 48.6577289, lng: 19.9877382 },
    { lat: 48.6582095, lng: 19.9879748 },
    { lat: 48.6582649, lng: 19.9904205 },
    { lat: 48.6590862, lng: 19.9913122 },
    { lat: 48.6601957, lng: 19.9928961 },
    { lat: 48.6611356, lng: 19.9961856 },
    { lat: 48.663241, lng: 19.998404 },
    { lat: 48.664018, lng: 19.997898 },
    { lat: 48.664435, lng: 19.997833 },
    { lat: 48.664715, lng: 19.997754 },
    { lat: 48.664855, lng: 19.997672 },
    { lat: 48.665177, lng: 19.997483 },
    { lat: 48.665219, lng: 19.997458 },
    { lat: 48.66532, lng: 19.997396 },
    { lat: 48.665735, lng: 19.997054 },
    { lat: 48.666161, lng: 19.997332 },
    { lat: 48.666939, lng: 19.99749 },
    { lat: 48.667199, lng: 19.9975535 },
    { lat: 48.667389, lng: 19.9976 },
    { lat: 48.667562, lng: 19.997641 },
    { lat: 48.667603, lng: 19.997674 },
    { lat: 48.667776, lng: 19.997799 },
    { lat: 48.668099, lng: 19.998032 },
    { lat: 48.668344, lng: 19.998165 },
    { lat: 48.668526, lng: 19.998321 },
    { lat: 48.668716, lng: 19.998482 },
    { lat: 48.66943, lng: 19.999115 },
    { lat: 48.669548, lng: 19.999527 },
    { lat: 48.66956, lng: 19.999578 },
    { lat: 48.669728, lng: 20.000114 },
    { lat: 48.669973, lng: 20.000482 },
    { lat: 48.670342, lng: 20.000924 },
    { lat: 48.670755, lng: 20.001223 },
    { lat: 48.671431, lng: 20.001556 },
    { lat: 48.671749, lng: 20.001889 },
    { lat: 48.671873, lng: 20.002016 },
    { lat: 48.672207, lng: 20.00234 },
    { lat: 48.672352, lng: 20.002479 },
    { lat: 48.672534, lng: 20.002651 },
    { lat: 48.672603, lng: 20.002682 },
    { lat: 48.673178, lng: 20.002945 },
    { lat: 48.673592, lng: 20.003225 },
    { lat: 48.673618, lng: 20.003246 },
    { lat: 48.674199, lng: 20.00317 },
    { lat: 48.674844, lng: 20.003092 },
    { lat: 48.675095, lng: 20.003021 },
    { lat: 48.675827, lng: 20.002803 },
    { lat: 48.67634, lng: 20.002558 },
    { lat: 48.676703, lng: 20.002329 },
    { lat: 48.677015, lng: 20.002133 },
    { lat: 48.677039, lng: 20.002118 },
    { lat: 48.677075, lng: 20.002096 },
    { lat: 48.677176, lng: 20.002031 },
    { lat: 48.677322, lng: 20.001941 },
    { lat: 48.677634, lng: 20.001731 },
    { lat: 48.677743, lng: 20.001658 },
    { lat: 48.678294, lng: 20.00129 },
    { lat: 48.678577, lng: 20.001105 },
    { lat: 48.678671, lng: 20.001044 },
    { lat: 48.679251, lng: 20.00066 },
    { lat: 48.679873, lng: 20.000256 },
    { lat: 48.680065, lng: 20.000124 },
    { lat: 48.680748, lng: 19.99975 },
    { lat: 48.681219, lng: 19.999459 },
    { lat: 48.68156, lng: 20.000534 },
    { lat: 48.681765, lng: 20.001173 },
    { lat: 48.682063, lng: 20.002114 },
    { lat: 48.682117, lng: 20.002294 },
    { lat: 48.682649, lng: 20.0031 },
    { lat: 48.682892, lng: 20.003786 },
    { lat: 48.683295, lng: 20.004079 },
    { lat: 48.683459, lng: 20.004155 },
    { lat: 48.683584, lng: 20.00421 },
    { lat: 48.683859, lng: 20.004243 },
    { lat: 48.684095, lng: 20.004264 },
    { lat: 48.684478, lng: 20.004338 },
    { lat: 48.684589, lng: 20.004674 },
    { lat: 48.685124, lng: 20.005077 },
    { lat: 48.685407, lng: 20.005263 },
    { lat: 48.68573, lng: 20.005549 },
    { lat: 48.686482, lng: 20.005639 },
    { lat: 48.686536, lng: 20.005645 },
    { lat: 48.686601, lng: 20.005649 },
    { lat: 48.686945, lng: 20.005629 },
    { lat: 48.687424, lng: 20.00557 },
    { lat: 48.687475, lng: 20.005569 },
    { lat: 48.687769, lng: 20.005556 },
    { lat: 48.688189, lng: 20.005621 },
    { lat: 48.688695, lng: 20.005697 },
    { lat: 48.68898, lng: 20.005648 },
    { lat: 48.68917, lng: 20.005612 },
    { lat: 48.689556, lng: 20.005331 },
    { lat: 48.690573, lng: 20.004436 },
    { lat: 48.690632, lng: 20.004345 },
    { lat: 48.691295, lng: 20.001016 },
    { lat: 48.691819, lng: 20.000267 },
    { lat: 48.693198, lng: 19.999173 },
    { lat: 48.693867, lng: 19.998764 },
    { lat: 48.694294, lng: 19.998402 },
    { lat: 48.694649, lng: 19.998319 },
    { lat: 48.69486, lng: 19.997892 },
    { lat: 48.694866, lng: 19.997306 },
    { lat: 48.694937, lng: 19.996851 },
    { lat: 48.694996, lng: 19.995869 },
    { lat: 48.695234, lng: 19.994767 },
    { lat: 48.695369, lng: 19.993862 },
    { lat: 48.6957, lng: 19.992875 },
    { lat: 48.695881, lng: 19.991489 },
    { lat: 48.699326, lng: 19.986934 },
    { lat: 48.700477, lng: 19.985154 },
    { lat: 48.700978, lng: 19.983886 },
    { lat: 48.703352, lng: 19.978828 },
    { lat: 48.703631, lng: 19.978469 },
    { lat: 48.705463, lng: 19.978323 },
    { lat: 48.705856, lng: 19.977611 },
    { lat: 48.706248, lng: 19.977157 },
    { lat: 48.706616, lng: 19.977244 },
    { lat: 48.706906, lng: 19.97677 },
    { lat: 48.707251, lng: 19.975249 },
    { lat: 48.706739, lng: 19.974156 },
    { lat: 48.706593, lng: 19.97367 },
    { lat: 48.706056, lng: 19.97243 },
    { lat: 48.705719, lng: 19.971749 },
    { lat: 48.705553, lng: 19.970923 },
    { lat: 48.704654, lng: 19.969323 },
    { lat: 48.704097, lng: 19.968529 },
    { lat: 48.703156, lng: 19.966556 },
    { lat: 48.70301, lng: 19.966091 },
    { lat: 48.703205, lng: 19.965281 },
    { lat: 48.702753, lng: 19.964291 },
    { lat: 48.702772, lng: 19.963975 },
    { lat: 48.704551, lng: 19.96273 },
    { lat: 48.705096, lng: 19.962087 },
    { lat: 48.705229, lng: 19.961837 },
    { lat: 48.705277, lng: 19.961615 },
    { lat: 48.705431, lng: 19.961469 },
    { lat: 48.705117, lng: 19.96104 },
    { lat: 48.705324, lng: 19.960702 },
    { lat: 48.706167, lng: 19.961273 },
    { lat: 48.706882, lng: 19.96157 },
    { lat: 48.707157, lng: 19.961593 },
    { lat: 48.708675, lng: 19.960968 },
    { lat: 48.709547, lng: 19.961333 },
    { lat: 48.709868, lng: 19.961669 },
    { lat: 48.710306, lng: 19.962301 },
    { lat: 48.71047, lng: 19.963363 },
    { lat: 48.710837, lng: 19.965036 },
    { lat: 48.711886, lng: 19.965121 },
    { lat: 48.712829, lng: 19.965409 },
    { lat: 48.712995, lng: 19.966822 },
    { lat: 48.713222, lng: 19.967291 },
    { lat: 48.713461, lng: 19.967228 },
    { lat: 48.714297, lng: 19.967553 },
    { lat: 48.715388, lng: 19.968485 },
    { lat: 48.715692, lng: 19.968502 },
    { lat: 48.716345, lng: 19.969449 },
    { lat: 48.716535, lng: 19.971297 },
    { lat: 48.716835, lng: 19.972069 },
    { lat: 48.7175653, lng: 19.9723308 },
    { lat: 48.719239, lng: 19.972931 },
    { lat: 48.720627, lng: 19.971838 },
    { lat: 48.721155, lng: 19.971522 },
    { lat: 48.721587, lng: 19.971079 },
    { lat: 48.721882, lng: 19.971572 },
    { lat: 48.723277, lng: 19.971734 },
    { lat: 48.723754, lng: 19.971218 },
    { lat: 48.7238487, lng: 19.9712695 },
    { lat: 48.724426, lng: 19.971583 },
    { lat: 48.724992, lng: 19.97135 },
    { lat: 48.725562, lng: 19.969823 },
    { lat: 48.726113, lng: 19.968953 },
    { lat: 48.726215, lng: 19.96856 },
    { lat: 48.72632, lng: 19.968395 },
    { lat: 48.728045, lng: 19.968168 },
    { lat: 48.729173, lng: 19.968594 },
    { lat: 48.729543, lng: 19.968616 },
    { lat: 48.730481, lng: 19.968925 },
    { lat: 48.731915, lng: 19.969521 },
    { lat: 48.732482, lng: 19.97013 },
    { lat: 48.73331, lng: 19.970391 },
    { lat: 48.733816, lng: 19.970076 },
    { lat: 48.735299, lng: 19.969636 },
    { lat: 48.735312, lng: 19.969391 },
    { lat: 48.736122, lng: 19.969098 },
    { lat: 48.737055, lng: 19.969587 },
    { lat: 48.738416, lng: 19.968196 },
    { lat: 48.738776, lng: 19.967743 },
    { lat: 48.739462, lng: 19.968159 },
    { lat: 48.74011, lng: 19.968313 },
    { lat: 48.742558, lng: 19.966898 },
    { lat: 48.743061, lng: 19.96627 },
    { lat: 48.744241, lng: 19.966053 },
    { lat: 48.744757, lng: 19.96517 },
    { lat: 48.744936, lng: 19.965169 },
    { lat: 48.745313, lng: 19.965401 },
    { lat: 48.745946, lng: 19.965328 },
    { lat: 48.746994, lng: 19.963556 },
    { lat: 48.74881, lng: 19.962512 },
    { lat: 48.749833, lng: 19.96214 },
    { lat: 48.750299, lng: 19.961556 },
    { lat: 48.750429, lng: 19.960894 },
    { lat: 48.750404, lng: 19.959555 },
    { lat: 48.75013, lng: 19.957335 },
    { lat: 48.750291, lng: 19.957063 },
    { lat: 48.750639, lng: 19.956054 },
    { lat: 48.750505, lng: 19.955356 },
    { lat: 48.750515, lng: 19.954324 },
    { lat: 48.75168, lng: 19.952049 },
    { lat: 48.752531, lng: 19.95075 },
    { lat: 48.753529, lng: 19.948627 },
    { lat: 48.753824, lng: 19.947656 },
    { lat: 48.754203, lng: 19.943707 },
    { lat: 48.754928, lng: 19.942662 },
    { lat: 48.755167, lng: 19.941844 },
    { lat: 48.755098, lng: 19.94036 },
    { lat: 48.754939, lng: 19.938908 },
    { lat: 48.754905, lng: 19.937889 },
    { lat: 48.75514, lng: 19.937008 },
    { lat: 48.755226, lng: 19.93628 },
    { lat: 48.755251, lng: 19.934423 },
    { lat: 48.754779, lng: 19.932246 },
    { lat: 48.754187, lng: 19.930501 },
    { lat: 48.754002, lng: 19.929143 },
    { lat: 48.75381, lng: 19.928548 },
    { lat: 48.754547, lng: 19.926768 },
    { lat: 48.755001, lng: 19.924892 },
    { lat: 48.755727, lng: 19.922942 },
    { lat: 48.755791, lng: 19.921819 },
    { lat: 48.756044, lng: 19.920317 },
    { lat: 48.756177, lng: 19.917388 },
    { lat: 48.756235, lng: 19.917024 },
    { lat: 48.756245, lng: 19.915958 },
    { lat: 48.756172, lng: 19.913979 },
    { lat: 48.760591, lng: 19.90891 },
    { lat: 48.76096, lng: 19.908639 },
    { lat: 48.760996, lng: 19.908527 },
    { lat: 48.761132, lng: 19.908414 },
    { lat: 48.761412, lng: 19.908004 },
    { lat: 48.761707, lng: 19.907374 },
    { lat: 48.762288, lng: 19.906616 },
    { lat: 48.762427, lng: 19.9060625 },
    { lat: 48.762625, lng: 19.90511 },
    { lat: 48.762909, lng: 19.903232 },
    { lat: 48.763981, lng: 19.901359 },
    { lat: 48.76472, lng: 19.900983 },
    { lat: 48.765435, lng: 19.900338 },
    { lat: 48.765834, lng: 19.899531 },
    { lat: 48.767595, lng: 19.893324 },
    { lat: 48.768271, lng: 19.891176 },
    { lat: 48.768556, lng: 19.889996 },
    { lat: 48.768777, lng: 19.88939 },
    { lat: 48.769584, lng: 19.888171 },
    { lat: 48.769574, lng: 19.888174 },
    { lat: 48.769128, lng: 19.888265 },
    { lat: 48.768921, lng: 19.888188 },
    { lat: 48.768692, lng: 19.888155 },
    { lat: 48.768064, lng: 19.887844 },
    { lat: 48.767331, lng: 19.887234 },
    { lat: 48.766343, lng: 19.886412 },
    { lat: 48.765406, lng: 19.885452 },
    { lat: 48.764605, lng: 19.884627 },
    { lat: 48.763957, lng: 19.883957 },
    { lat: 48.763107, lng: 19.883319 },
    { lat: 48.761905, lng: 19.88242 },
    { lat: 48.761488, lng: 19.881951 },
    { lat: 48.760888, lng: 19.881456 },
    { lat: 48.760266, lng: 19.880879 },
    { lat: 48.760061, lng: 19.880624 },
    { lat: 48.759641, lng: 19.879944 },
    { lat: 48.758983, lng: 19.879638 },
    { lat: 48.758225, lng: 19.879427 },
    { lat: 48.75778, lng: 19.879521 },
    { lat: 48.757265, lng: 19.87981 },
    { lat: 48.756686, lng: 19.880199 },
    { lat: 48.755839, lng: 19.880607 },
    { lat: 48.755407, lng: 19.880722 },
    { lat: 48.754961, lng: 19.880797 },
    { lat: 48.754252, lng: 19.880845 },
    { lat: 48.753836, lng: 19.880874 },
    { lat: 48.753476, lng: 19.880901 },
    { lat: 48.752603, lng: 19.880967 },
    { lat: 48.751849, lng: 19.881707 },
    { lat: 48.751407, lng: 19.880678 },
    { lat: 48.75089, lng: 19.879954 },
    { lat: 48.750473, lng: 19.879081 },
    { lat: 48.750359, lng: 19.87848 },
    { lat: 48.749947, lng: 19.877599 },
    { lat: 48.749868, lng: 19.877032 },
    { lat: 48.74942, lng: 19.877057 },
    { lat: 48.74851, lng: 19.87688 },
    { lat: 48.747397, lng: 19.87667 },
    { lat: 48.747304, lng: 19.876039 },
    { lat: 48.746906, lng: 19.874882 },
    { lat: 48.746807, lng: 19.874684 },
    { lat: 48.746743, lng: 19.874271 },
    { lat: 48.746951, lng: 19.873518 },
    { lat: 48.747185, lng: 19.872476 },
    { lat: 48.747654, lng: 19.871203 },
    { lat: 48.747752, lng: 19.869948 },
    { lat: 48.747671, lng: 19.868495 },
    { lat: 48.748223, lng: 19.867386 },
    { lat: 48.748756, lng: 19.866862 },
    { lat: 48.749057, lng: 19.866356 },
    { lat: 48.749502, lng: 19.865751 },
    { lat: 48.749456, lng: 19.864905 },
    { lat: 48.749865, lng: 19.863434 },
    { lat: 48.749954, lng: 19.862984 },
    { lat: 48.75001, lng: 19.862539 },
    { lat: 48.749996, lng: 19.862199 },
    { lat: 48.750034, lng: 19.861205 },
    { lat: 48.749552, lng: 19.860101 },
    { lat: 48.749339, lng: 19.859843 },
    { lat: 48.748784, lng: 19.858593 },
    { lat: 48.748153, lng: 19.857417 },
    { lat: 48.747765, lng: 19.856935 },
    { lat: 48.747423, lng: 19.855979 },
    { lat: 48.74668, lng: 19.854795 },
    { lat: 48.746384, lng: 19.854162 },
    { lat: 48.746276, lng: 19.85383 },
    { lat: 48.745785, lng: 19.853363 },
    { lat: 48.745092, lng: 19.852616 },
    { lat: 48.744865, lng: 19.85214 },
    { lat: 48.744141, lng: 19.851135 },
    { lat: 48.744055, lng: 19.850987 },
    { lat: 48.743457, lng: 19.850854 },
    { lat: 48.743187, lng: 19.850792 },
    { lat: 48.742697, lng: 19.850715 },
    { lat: 48.742279, lng: 19.850577 },
    { lat: 48.741709, lng: 19.850038 },
    { lat: 48.740924, lng: 19.849173 },
    { lat: 48.740577, lng: 19.848831 },
    { lat: 48.740066, lng: 19.848636 },
    { lat: 48.739332, lng: 19.848412 },
    { lat: 48.739102, lng: 19.848306 },
    { lat: 48.738873, lng: 19.848258 },
    { lat: 48.73866, lng: 19.847728 },
    { lat: 48.738164, lng: 19.846447 },
    { lat: 48.737919, lng: 19.845302 },
    { lat: 48.737496, lng: 19.845202 },
    { lat: 48.737108, lng: 19.843979 },
    { lat: 48.73652, lng: 19.842899 },
    { lat: 48.735986, lng: 19.842155 },
    { lat: 48.735761, lng: 19.841872 },
    { lat: 48.735384, lng: 19.841492 },
    { lat: 48.735244, lng: 19.841233 },
    { lat: 48.734986, lng: 19.840696 },
    { lat: 48.73497, lng: 19.840339 },
    { lat: 48.734667, lng: 19.839688 },
    { lat: 48.734364, lng: 19.837625 },
    { lat: 48.734301, lng: 19.836871 },
    { lat: 48.734139, lng: 19.835909 },
    { lat: 48.734077, lng: 19.835487 },
    { lat: 48.73305, lng: 19.835853 },
    { lat: 48.732186, lng: 19.835829 },
    { lat: 48.731972, lng: 19.835732 },
    { lat: 48.731528, lng: 19.835453 },
    { lat: 48.731311, lng: 19.83538 },
    { lat: 48.730976, lng: 19.83534 },
    { lat: 48.730502, lng: 19.835155 },
    { lat: 48.730133, lng: 19.835089 },
    { lat: 48.729898, lng: 19.834972 },
    { lat: 48.729741, lng: 19.834785 },
    { lat: 48.729485, lng: 19.834326 },
    { lat: 48.729336, lng: 19.834105 },
    { lat: 48.729307, lng: 19.834037 },
    { lat: 48.729138, lng: 19.833643 },
    { lat: 48.728798, lng: 19.832851 },
    { lat: 48.728177, lng: 19.83134 },
    { lat: 48.727701, lng: 19.830114 },
    { lat: 48.726865, lng: 19.830369 },
    { lat: 48.72655, lng: 19.830335 },
    { lat: 48.726306, lng: 19.830324 },
    { lat: 48.726257, lng: 19.829895 },
    { lat: 48.725999, lng: 19.829618 },
    { lat: 48.725641, lng: 19.829239 },
    { lat: 48.725341, lng: 19.82901 },
    { lat: 48.724799, lng: 19.828328 },
    { lat: 48.724006, lng: 19.827962 },
    { lat: 48.723705, lng: 19.827823 },
    { lat: 48.723098, lng: 19.828044 },
    { lat: 48.722493, lng: 19.82827 },
    { lat: 48.721855, lng: 19.828393 },
    { lat: 48.721363, lng: 19.828469 },
    { lat: 48.720526, lng: 19.828421 },
    { lat: 48.719688, lng: 19.828534 },
    { lat: 48.718853, lng: 19.828619 },
    { lat: 48.717827, lng: 19.828743 },
    { lat: 48.717123, lng: 19.828834 },
    { lat: 48.716207, lng: 19.828961 },
    { lat: 48.715709, lng: 19.828636 },
    { lat: 48.714999, lng: 19.828447 },
    { lat: 48.714587, lng: 19.828308 },
    { lat: 48.714076, lng: 19.828233 },
    { lat: 48.713493, lng: 19.828223 },
    { lat: 48.712792, lng: 19.828097 },
    { lat: 48.712192, lng: 19.827981 },
    { lat: 48.711806, lng: 19.827889 },
    { lat: 48.711657, lng: 19.827108 },
    { lat: 48.711472, lng: 19.826247 },
    { lat: 48.711289, lng: 19.825429 },
    { lat: 48.711036, lng: 19.82386 },
    { lat: 48.711011, lng: 19.823773 },
    { lat: 48.71099, lng: 19.822454 },
    { lat: 48.711114, lng: 19.821281 },
    { lat: 48.711081, lng: 19.82049 },
    { lat: 48.711077, lng: 19.81919 },
    { lat: 48.711057, lng: 19.818721 },
  ],
  Hrušovo: [
    { lat: 48.5473208, lng: 20.0258657 },
    { lat: 48.5467098, lng: 20.024424 },
    { lat: 48.5464702, lng: 20.0239721 },
    { lat: 48.5466038, lng: 20.0233167 },
    { lat: 48.5461236, lng: 20.0220131 },
    { lat: 48.5461982, lng: 20.0212907 },
    { lat: 48.5463533, lng: 20.0210144 },
    { lat: 48.5466219, lng: 20.0199754 },
    { lat: 48.5464469, lng: 20.019345 },
    { lat: 48.5463929, lng: 20.0185886 },
    { lat: 48.5461819, lng: 20.0185553 },
    { lat: 48.5460192, lng: 20.0177584 },
    { lat: 48.5452613, lng: 20.0181718 },
    { lat: 48.5451006, lng: 20.017748 },
    { lat: 48.5438187, lng: 20.0164796 },
    { lat: 48.5427352, lng: 20.0164277 },
    { lat: 48.5424173, lng: 20.0165307 },
    { lat: 48.5421252, lng: 20.0164414 },
    { lat: 48.5415795, lng: 20.0168333 },
    { lat: 48.5411544, lng: 20.0175003 },
    { lat: 48.540946, lng: 20.0174721 },
    { lat: 48.5406067, lng: 20.0171918 },
    { lat: 48.5385978, lng: 20.0187475 },
    { lat: 48.5376985, lng: 20.0188711 },
    { lat: 48.537388, lng: 20.0188298 },
    { lat: 48.5372466, lng: 20.0188915 },
    { lat: 48.5372628, lng: 20.0199303 },
    { lat: 48.5371392, lng: 20.0201507 },
    { lat: 48.5370417, lng: 20.0200835 },
    { lat: 48.5369804, lng: 20.0202311 },
    { lat: 48.536969, lng: 20.0204732 },
    { lat: 48.536826, lng: 20.020475 },
    { lat: 48.5363136, lng: 20.0208253 },
    { lat: 48.5360808, lng: 20.0215362 },
    { lat: 48.5357054, lng: 20.021908 },
    { lat: 48.5349873, lng: 20.0220812 },
    { lat: 48.5345922, lng: 20.0216068 },
    { lat: 48.5346054, lng: 20.0213006 },
    { lat: 48.5345422, lng: 20.0211324 },
    { lat: 48.5344952, lng: 20.0207661 },
    { lat: 48.5344961, lng: 20.0206242 },
    { lat: 48.5346005, lng: 20.0202916 },
    { lat: 48.5342953, lng: 20.0194039 },
    { lat: 48.5340722, lng: 20.0185386 },
    { lat: 48.5338158, lng: 20.0182566 },
    { lat: 48.5336156, lng: 20.0181821 },
    { lat: 48.5331846, lng: 20.0185673 },
    { lat: 48.532688, lng: 20.0190583 },
    { lat: 48.532583, lng: 20.0192971 },
    { lat: 48.5322091, lng: 20.0197533 },
    { lat: 48.5319422, lng: 20.0196766 },
    { lat: 48.5318733, lng: 20.0195633 },
    { lat: 48.5318276, lng: 20.0193356 },
    { lat: 48.5318928, lng: 20.0193027 },
    { lat: 48.5318968, lng: 20.0189409 },
    { lat: 48.5317963, lng: 20.0185851 },
    { lat: 48.5318755, lng: 20.0185659 },
    { lat: 48.5317495, lng: 20.0172746 },
    { lat: 48.5313867, lng: 20.0167104 },
    { lat: 48.5310422, lng: 20.015985 },
    { lat: 48.5308962, lng: 20.0154299 },
    { lat: 48.5307836, lng: 20.0157789 },
    { lat: 48.5310128, lng: 20.016168 },
    { lat: 48.5308898, lng: 20.0162749 },
    { lat: 48.5309206, lng: 20.0164692 },
    { lat: 48.530778, lng: 20.0167237 },
    { lat: 48.5305034, lng: 20.0174818 },
    { lat: 48.5301716, lng: 20.0180094 },
    { lat: 48.5297746, lng: 20.0188054 },
    { lat: 48.5295559, lng: 20.0185049 },
    { lat: 48.5294562, lng: 20.0182197 },
    { lat: 48.5293066, lng: 20.0180629 },
    { lat: 48.5290564, lng: 20.0179267 },
    { lat: 48.5288642, lng: 20.0176944 },
    { lat: 48.5285036, lng: 20.017069 },
    { lat: 48.5279237, lng: 20.0170209 },
    { lat: 48.5278364, lng: 20.0164456 },
    { lat: 48.5273106, lng: 20.0164752 },
    { lat: 48.5270225, lng: 20.0163781 },
    { lat: 48.526861, lng: 20.0162296 },
    { lat: 48.5267242, lng: 20.0160564 },
    { lat: 48.5266396, lng: 20.0155139 },
    { lat: 48.526471, lng: 20.015387 },
    { lat: 48.5262125, lng: 20.0164034 },
    { lat: 48.5261469, lng: 20.0165283 },
    { lat: 48.5261439, lng: 20.0166696 },
    { lat: 48.5260571, lng: 20.0167791 },
    { lat: 48.5257238, lng: 20.0167794 },
    { lat: 48.5257877, lng: 20.0161568 },
    { lat: 48.5256719, lng: 20.0157019 },
    { lat: 48.5255781, lng: 20.0158576 },
    { lat: 48.5255864, lng: 20.0160274 },
    { lat: 48.5253686, lng: 20.0161536 },
    { lat: 48.5251263, lng: 20.0160937 },
    { lat: 48.5250157, lng: 20.0160125 },
    { lat: 48.524804, lng: 20.015663 },
    { lat: 48.5246339, lng: 20.0150154 },
    { lat: 48.5234142, lng: 20.0160979 },
    { lat: 48.5234978, lng: 20.0162108 },
    { lat: 48.5230181, lng: 20.0169438 },
    { lat: 48.522698, lng: 20.0164799 },
    { lat: 48.522765, lng: 20.0162255 },
    { lat: 48.5226536, lng: 20.0160626 },
    { lat: 48.5225999, lng: 20.0156417 },
    { lat: 48.5216302, lng: 20.0167768 },
    { lat: 48.5208186, lng: 20.01744 },
    { lat: 48.5206364, lng: 20.0177956 },
    { lat: 48.5203236, lng: 20.0181286 },
    { lat: 48.5199173, lng: 20.0187239 },
    { lat: 48.5196835, lng: 20.0183605 },
    { lat: 48.5192808, lng: 20.0186982 },
    { lat: 48.5192219, lng: 20.0185279 },
    { lat: 48.5187986, lng: 20.0191003 },
    { lat: 48.5186128, lng: 20.0188564 },
    { lat: 48.5180886, lng: 20.0192531 },
    { lat: 48.517993, lng: 20.0190455 },
    { lat: 48.5178501, lng: 20.0189137 },
    { lat: 48.5176358, lng: 20.0190661 },
    { lat: 48.5173097, lng: 20.0191596 },
    { lat: 48.5165542, lng: 20.0204597 },
    { lat: 48.5163256, lng: 20.0210846 },
    { lat: 48.5161176, lng: 20.0214159 },
    { lat: 48.5157156, lng: 20.0218224 },
    { lat: 48.5155875, lng: 20.0215652 },
    { lat: 48.5146923, lng: 20.0224028 },
    { lat: 48.5144235, lng: 20.0232226 },
    { lat: 48.5132664, lng: 20.0244313 },
    { lat: 48.5131536, lng: 20.0245354 },
    { lat: 48.5129161, lng: 20.0244041 },
    { lat: 48.5127669, lng: 20.0244214 },
    { lat: 48.5121151, lng: 20.024617 },
    { lat: 48.5110089, lng: 20.0251841 },
    { lat: 48.5108043, lng: 20.0252468 },
    { lat: 48.5102669, lng: 20.0251943 },
    { lat: 48.5093141, lng: 20.0258165 },
    { lat: 48.5089773, lng: 20.0258683 },
    { lat: 48.5083973, lng: 20.0263814 },
    { lat: 48.5077568, lng: 20.0271758 },
    { lat: 48.5077903, lng: 20.0276579 },
    { lat: 48.5076725, lng: 20.0292075 },
    { lat: 48.507408, lng: 20.0308051 },
    { lat: 48.5072123, lng: 20.0316243 },
    { lat: 48.5068527, lng: 20.0321527 },
    { lat: 48.507044, lng: 20.0323158 },
    { lat: 48.5070697, lng: 20.0324161 },
    { lat: 48.5069358, lng: 20.032823 },
    { lat: 48.5068198, lng: 20.0331185 },
    { lat: 48.5067712, lng: 20.0330907 },
    { lat: 48.5066386, lng: 20.0341806 },
    { lat: 48.5060832, lng: 20.0345545 },
    { lat: 48.5058449, lng: 20.0346474 },
    { lat: 48.5055986, lng: 20.0349001 },
    { lat: 48.5052373, lng: 20.0349079 },
    { lat: 48.5052654, lng: 20.0350725 },
    { lat: 48.5049203, lng: 20.0353541 },
    { lat: 48.5048676, lng: 20.0355012 },
    { lat: 48.504047, lng: 20.0360514 },
    { lat: 48.5033851, lng: 20.0363063 },
    { lat: 48.5033875, lng: 20.0364159 },
    { lat: 48.5029024, lng: 20.0368832 },
    { lat: 48.5025009, lng: 20.037417 },
    { lat: 48.5017842, lng: 20.0374608 },
    { lat: 48.5015734, lng: 20.0375666 },
    { lat: 48.50128, lng: 20.0376071 },
    { lat: 48.5007449, lng: 20.0379022 },
    { lat: 48.5002513, lng: 20.0380603 },
    { lat: 48.4999322, lng: 20.0380783 },
    { lat: 48.4997843, lng: 20.0380745 },
    { lat: 48.4993196, lng: 20.0377578 },
    { lat: 48.4992308, lng: 20.0375755 },
    { lat: 48.4973733, lng: 20.0385991 },
    { lat: 48.4968957, lng: 20.0387102 },
    { lat: 48.4946047, lng: 20.0382795 },
    { lat: 48.4925023, lng: 20.0393517 },
    { lat: 48.4897781, lng: 20.0401784 },
    { lat: 48.4881511, lng: 20.040326 },
    { lat: 48.4855777, lng: 20.0413387 },
    { lat: 48.4844857, lng: 20.0418712 },
    { lat: 48.4814393, lng: 20.0427752 },
    { lat: 48.4800894, lng: 20.0434963 },
    { lat: 48.4817391, lng: 20.0437009 },
    { lat: 48.4819228, lng: 20.0455452 },
    { lat: 48.4822256, lng: 20.0458923 },
    { lat: 48.4823795, lng: 20.046748 },
    { lat: 48.4831456, lng: 20.0469678 },
    { lat: 48.4834378, lng: 20.04741 },
    { lat: 48.4836896, lng: 20.0479708 },
    { lat: 48.4841634, lng: 20.0481758 },
    { lat: 48.4846592, lng: 20.0479641 },
    { lat: 48.4861035, lng: 20.0488597 },
    { lat: 48.4864697, lng: 20.0499452 },
    { lat: 48.4865054, lng: 20.0504815 },
    { lat: 48.4867694, lng: 20.0508423 },
    { lat: 48.4867741, lng: 20.0513915 },
    { lat: 48.4864264, lng: 20.0526996 },
    { lat: 48.4862624, lng: 20.0528482 },
    { lat: 48.4860643, lng: 20.0539665 },
    { lat: 48.4880325, lng: 20.0551401 },
    { lat: 48.4903833, lng: 20.0566816 },
    { lat: 48.4908327, lng: 20.0568741 },
    { lat: 48.4911815, lng: 20.0566184 },
    { lat: 48.4913945, lng: 20.0574116 },
    { lat: 48.4916034, lng: 20.0574502 },
    { lat: 48.4918174, lng: 20.0573676 },
    { lat: 48.4923513, lng: 20.0568789 },
    { lat: 48.4925521, lng: 20.0567762 },
    { lat: 48.4928559, lng: 20.056742 },
    { lat: 48.4932854, lng: 20.0568022 },
    { lat: 48.4935732, lng: 20.0567357 },
    { lat: 48.495084, lng: 20.0562834 },
    { lat: 48.4962997, lng: 20.0556951 },
    { lat: 48.4967495, lng: 20.0556181 },
    { lat: 48.4970446, lng: 20.0554564 },
    { lat: 48.4973496, lng: 20.0551501 },
    { lat: 48.4974798, lng: 20.0549262 },
    { lat: 48.4978876, lng: 20.0538654 },
    { lat: 48.4982936, lng: 20.0532288 },
    { lat: 48.4984919, lng: 20.0533099 },
    { lat: 48.498805, lng: 20.0535806 },
    { lat: 48.4990716, lng: 20.0540027 },
    { lat: 48.4999429, lng: 20.0543466 },
    { lat: 48.5005843, lng: 20.0541674 },
    { lat: 48.5008699, lng: 20.0540177 },
    { lat: 48.5010794, lng: 20.0537206 },
    { lat: 48.5012785, lng: 20.0537111 },
    { lat: 48.5016933, lng: 20.0534231 },
    { lat: 48.5021288, lng: 20.0535203 },
    { lat: 48.5023858, lng: 20.0536841 },
    { lat: 48.5026482, lng: 20.0541482 },
    { lat: 48.5031063, lng: 20.0544379 },
    { lat: 48.5038005, lng: 20.0543933 },
    { lat: 48.5039388, lng: 20.0542794 },
    { lat: 48.5041485, lng: 20.054308 },
    { lat: 48.5046711, lng: 20.054906 },
    { lat: 48.5050301, lng: 20.0554396 },
    { lat: 48.5052709, lng: 20.0555949 },
    { lat: 48.5055697, lng: 20.0556276 },
    { lat: 48.5055615, lng: 20.0557829 },
    { lat: 48.5055129, lng: 20.0571677 },
    { lat: 48.5061026, lng: 20.0596493 },
    { lat: 48.5070639, lng: 20.0608598 },
    { lat: 48.5079188, lng: 20.0645559 },
    { lat: 48.5082747, lng: 20.0673832 },
    { lat: 48.5082829, lng: 20.0690747 },
    { lat: 48.5084733, lng: 20.071693 },
    { lat: 48.5084823, lng: 20.0720981 },
    { lat: 48.5081864, lng: 20.0731734 },
    { lat: 48.5080563, lng: 20.0740576 },
    { lat: 48.5083776, lng: 20.0744691 },
    { lat: 48.5085438, lng: 20.0746233 },
    { lat: 48.509722, lng: 20.0716819 },
    { lat: 48.5099832, lng: 20.0706695 },
    { lat: 48.5100278, lng: 20.0705794 },
    { lat: 48.5100777, lng: 20.0705864 },
    { lat: 48.5101933, lng: 20.0702477 },
    { lat: 48.5103438, lng: 20.0701839 },
    { lat: 48.5105141, lng: 20.0696439 },
    { lat: 48.5114, lng: 20.0681055 },
    { lat: 48.5135298, lng: 20.0657366 },
    { lat: 48.5152546, lng: 20.0622325 },
    { lat: 48.5173911, lng: 20.0612443 },
    { lat: 48.5189148, lng: 20.06078 },
    { lat: 48.5195231, lng: 20.0608608 },
    { lat: 48.5197777, lng: 20.060779 },
    { lat: 48.5200345, lng: 20.0608582 },
    { lat: 48.5206864, lng: 20.0614789 },
    { lat: 48.521271, lng: 20.0617804 },
    { lat: 48.521922, lng: 20.0607993 },
    { lat: 48.5223615, lng: 20.0609375 },
    { lat: 48.5229964, lng: 20.0613924 },
    { lat: 48.5243943, lng: 20.0600036 },
    { lat: 48.5243602, lng: 20.0598654 },
    { lat: 48.5247597, lng: 20.059367 },
    { lat: 48.5247692, lng: 20.0592432 },
    { lat: 48.5251482, lng: 20.0591166 },
    { lat: 48.5267715, lng: 20.0590675 },
    { lat: 48.526779, lng: 20.0590032 },
    { lat: 48.5270854, lng: 20.0589225 },
    { lat: 48.5271243, lng: 20.0587455 },
    { lat: 48.5275319, lng: 20.0585505 },
    { lat: 48.5277079, lng: 20.0580986 },
    { lat: 48.5288002, lng: 20.0575745 },
    { lat: 48.529611, lng: 20.0575164 },
    { lat: 48.5298029, lng: 20.0573637 },
    { lat: 48.5298828, lng: 20.0571959 },
    { lat: 48.531032, lng: 20.0570979 },
    { lat: 48.5317452, lng: 20.0571819 },
    { lat: 48.5320909, lng: 20.057314 },
    { lat: 48.5323187, lng: 20.0575104 },
    { lat: 48.5329539, lng: 20.0576753 },
    { lat: 48.5332349, lng: 20.0578781 },
    { lat: 48.5335887, lng: 20.0578758 },
    { lat: 48.5337556, lng: 20.0579909 },
    { lat: 48.5339944, lng: 20.0578814 },
    { lat: 48.534144, lng: 20.0580408 },
    { lat: 48.5343168, lng: 20.0578628 },
    { lat: 48.5345942, lng: 20.0578417 },
    { lat: 48.5346676, lng: 20.0578981 },
    { lat: 48.5347733, lng: 20.0577613 },
    { lat: 48.5349532, lng: 20.0578375 },
    { lat: 48.5352448, lng: 20.0577475 },
    { lat: 48.5353928, lng: 20.057815 },
    { lat: 48.535555, lng: 20.0576811 },
    { lat: 48.5360564, lng: 20.0575174 },
    { lat: 48.5366237, lng: 20.0574562 },
    { lat: 48.5367018, lng: 20.0575175 },
    { lat: 48.5368332, lng: 20.0574021 },
    { lat: 48.5372679, lng: 20.0572869 },
    { lat: 48.5373639, lng: 20.0573493 },
    { lat: 48.5375704, lng: 20.0571152 },
    { lat: 48.5379698, lng: 20.0568595 },
    { lat: 48.538071, lng: 20.0566925 },
    { lat: 48.538232, lng: 20.056688 },
    { lat: 48.5382703, lng: 20.0565529 },
    { lat: 48.5385182, lng: 20.056257 },
    { lat: 48.5389358, lng: 20.0559234 },
    { lat: 48.5395535, lng: 20.0557456 },
    { lat: 48.5397897, lng: 20.0558474 },
    { lat: 48.5399676, lng: 20.0560056 },
    { lat: 48.5405474, lng: 20.0567295 },
    { lat: 48.5405635, lng: 20.0569292 },
    { lat: 48.5412628, lng: 20.0576344 },
    { lat: 48.5413301, lng: 20.0578971 },
    { lat: 48.541318, lng: 20.0584766 },
    { lat: 48.5415718, lng: 20.0582949 },
    { lat: 48.541668, lng: 20.0584121 },
    { lat: 48.5417765, lng: 20.0583151 },
    { lat: 48.5419451, lng: 20.057706 },
    { lat: 48.5420511, lng: 20.057577 },
    { lat: 48.5425112, lng: 20.0574531 },
    { lat: 48.542563, lng: 20.0573707 },
    { lat: 48.543217, lng: 20.0563551 },
    { lat: 48.5432986, lng: 20.0559309 },
    { lat: 48.5431823, lng: 20.0539121 },
    { lat: 48.5430013, lng: 20.053896 },
    { lat: 48.5428315, lng: 20.0536856 },
    { lat: 48.5424983, lng: 20.0527127 },
    { lat: 48.54254, lng: 20.0521991 },
    { lat: 48.5434413, lng: 20.050274 },
    { lat: 48.5436128, lng: 20.0497943 },
    { lat: 48.5436395, lng: 20.0493802 },
    { lat: 48.5432599, lng: 20.0478614 },
    { lat: 48.5431225, lng: 20.0470191 },
    { lat: 48.5427251, lng: 20.0456695 },
    { lat: 48.5427057, lng: 20.0452835 },
    { lat: 48.5431479, lng: 20.0444843 },
    { lat: 48.5429789, lng: 20.0435927 },
    { lat: 48.5432558, lng: 20.0416631 },
    { lat: 48.5428124, lng: 20.0411052 },
    { lat: 48.5428895, lng: 20.040467 },
    { lat: 48.5428913, lng: 20.0394843 },
    { lat: 48.5429816, lng: 20.0386201 },
    { lat: 48.5429115, lng: 20.0383982 },
    { lat: 48.5431246, lng: 20.036889 },
    { lat: 48.5431241, lng: 20.0364685 },
    { lat: 48.5429409, lng: 20.035124 },
    { lat: 48.542734, lng: 20.0349459 },
    { lat: 48.54261, lng: 20.0346992 },
    { lat: 48.5425762, lng: 20.0343465 },
    { lat: 48.5424346, lng: 20.0340581 },
    { lat: 48.5424551, lng: 20.0340349 },
    { lat: 48.5429348, lng: 20.0336799 },
    { lat: 48.5435017, lng: 20.0338482 },
    { lat: 48.5437745, lng: 20.0338306 },
    { lat: 48.5443395, lng: 20.0334367 },
    { lat: 48.5447226, lng: 20.0334723 },
    { lat: 48.5451086, lng: 20.0333775 },
    { lat: 48.5463683, lng: 20.0324988 },
    { lat: 48.5466502, lng: 20.0321753 },
    { lat: 48.5467159, lng: 20.0313522 },
    { lat: 48.5477376, lng: 20.0310551 },
    { lat: 48.547497, lng: 20.029931 },
    { lat: 48.5474783, lng: 20.0298332 },
    { lat: 48.5469864, lng: 20.0270328 },
    { lat: 48.5472319, lng: 20.0261671 },
    { lat: 48.5473208, lng: 20.0258657 },
  ],
  Dražice: [
    { lat: 48.416784, lng: 20.0654965 },
    { lat: 48.4171835, lng: 20.068716 },
    { lat: 48.417704, lng: 20.0701864 },
    { lat: 48.4181477, lng: 20.0725196 },
    { lat: 48.4185042, lng: 20.0748181 },
    { lat: 48.4191188, lng: 20.0754614 },
    { lat: 48.4193772, lng: 20.0765379 },
    { lat: 48.4199168, lng: 20.0784429 },
    { lat: 48.4206229, lng: 20.0797993 },
    { lat: 48.4208051, lng: 20.081128 },
    { lat: 48.4205068, lng: 20.0838009 },
    { lat: 48.4206435, lng: 20.0878466 },
    { lat: 48.4206471, lng: 20.0894087 },
    { lat: 48.4210591, lng: 20.091015 },
    { lat: 48.4217428, lng: 20.0924024 },
    { lat: 48.4239609, lng: 20.0953348 },
    { lat: 48.4242586, lng: 20.0959944 },
    { lat: 48.4243583, lng: 20.0964504 },
    { lat: 48.4257477, lng: 20.1005361 },
    { lat: 48.4257569, lng: 20.1007902 },
    { lat: 48.4261824, lng: 20.100479 },
    { lat: 48.4264052, lng: 20.10042 },
    { lat: 48.4271379, lng: 20.1005129 },
    { lat: 48.4272939, lng: 20.100365 },
    { lat: 48.4275011, lng: 20.0996819 },
    { lat: 48.4276998, lng: 20.0992513 },
    { lat: 48.4280121, lng: 20.098929 },
    { lat: 48.4282119, lng: 20.0989168 },
    { lat: 48.4295409, lng: 20.1000317 },
    { lat: 48.4298246, lng: 20.1001671 },
    { lat: 48.4299965, lng: 20.099912 },
    { lat: 48.4303357, lng: 20.0986792 },
    { lat: 48.4305786, lng: 20.0985371 },
    { lat: 48.431075, lng: 20.0986633 },
    { lat: 48.4313421, lng: 20.0984786 },
    { lat: 48.4326504, lng: 20.096603 },
    { lat: 48.4327792, lng: 20.0963377 },
    { lat: 48.4330873, lng: 20.0950967 },
    { lat: 48.4349764, lng: 20.0912479 },
    { lat: 48.4351892, lng: 20.0907028 },
    { lat: 48.435233, lng: 20.0904543 },
    { lat: 48.4353243, lng: 20.0866039 },
    { lat: 48.4367605, lng: 20.0864129 },
    { lat: 48.4369742, lng: 20.0862724 },
    { lat: 48.436983, lng: 20.0857469 },
    { lat: 48.4371633, lng: 20.0855868 },
    { lat: 48.4372035, lng: 20.0853021 },
    { lat: 48.4379099, lng: 20.0849007 },
    { lat: 48.4382106, lng: 20.084569 },
    { lat: 48.4383153, lng: 20.0842421 },
    { lat: 48.4384931, lng: 20.083963 },
    { lat: 48.438949, lng: 20.0834118 },
    { lat: 48.4397631, lng: 20.0830244 },
    { lat: 48.4401783, lng: 20.0830931 },
    { lat: 48.4409212, lng: 20.0829468 },
    { lat: 48.4413355, lng: 20.0825023 },
    { lat: 48.4425239, lng: 20.0819082 },
    { lat: 48.4450292, lng: 20.0814448 },
    { lat: 48.4457557, lng: 20.0817471 },
    { lat: 48.4449016, lng: 20.0781193 },
    { lat: 48.4442459, lng: 20.0760693 },
    { lat: 48.443594, lng: 20.0734354 },
    { lat: 48.4435987, lng: 20.0731153 },
    { lat: 48.4434976, lng: 20.0727434 },
    { lat: 48.4435061, lng: 20.0723512 },
    { lat: 48.443716, lng: 20.0719747 },
    { lat: 48.4434124, lng: 20.0708746 },
    { lat: 48.4434954, lng: 20.0705929 },
    { lat: 48.4433777, lng: 20.0692494 },
    { lat: 48.4431053, lng: 20.0688751 },
    { lat: 48.4429677, lng: 20.0681614 },
    { lat: 48.4431207, lng: 20.0668248 },
    { lat: 48.4427993, lng: 20.0649158 },
    { lat: 48.4426315, lng: 20.0643458 },
    { lat: 48.4426386, lng: 20.063538 },
    { lat: 48.4425306, lng: 20.0631589 },
    { lat: 48.442779, lng: 20.0617914 },
    { lat: 48.4426284, lng: 20.0610921 },
    { lat: 48.4426175, lng: 20.0607483 },
    { lat: 48.4424345, lng: 20.0604912 },
    { lat: 48.442121, lng: 20.0596443 },
    { lat: 48.4421308, lng: 20.0593912 },
    { lat: 48.441932, lng: 20.0591047 },
    { lat: 48.4419557, lng: 20.058863 },
    { lat: 48.4414134, lng: 20.0577921 },
    { lat: 48.4413526, lng: 20.0575204 },
    { lat: 48.4414318, lng: 20.0573714 },
    { lat: 48.4412272, lng: 20.056729 },
    { lat: 48.441259, lng: 20.0556479 },
    { lat: 48.4417127, lng: 20.0539613 },
    { lat: 48.4418613, lng: 20.0535788 },
    { lat: 48.4421041, lng: 20.0522149 },
    { lat: 48.4420492, lng: 20.0517866 },
    { lat: 48.4417167, lng: 20.050646 },
    { lat: 48.4409741, lng: 20.0490686 },
    { lat: 48.4408863, lng: 20.0487459 },
    { lat: 48.4408681, lng: 20.0483375 },
    { lat: 48.4415773, lng: 20.0468613 },
    { lat: 48.4418809, lng: 20.0419019 },
    { lat: 48.4422495, lng: 20.0399642 },
    { lat: 48.4427909, lng: 20.0377881 },
    { lat: 48.4432971, lng: 20.0354237 },
    { lat: 48.4432942, lng: 20.0332467 },
    { lat: 48.4436308, lng: 20.0315996 },
    { lat: 48.4446065, lng: 20.0285809 },
    { lat: 48.4445611, lng: 20.0274435 },
    { lat: 48.4440428, lng: 20.0260662 },
    { lat: 48.4438031, lng: 20.0226495 },
    { lat: 48.4439494, lng: 20.021434 },
    { lat: 48.444722, lng: 20.0195462 },
    { lat: 48.4448125, lng: 20.0189111 },
    { lat: 48.4454425, lng: 20.0167625 },
    { lat: 48.4449133, lng: 20.0167199 },
    { lat: 48.4435411, lng: 20.0172248 },
    { lat: 48.4425681, lng: 20.0179285 },
    { lat: 48.441836, lng: 20.0182801 },
    { lat: 48.4409346, lng: 20.0185197 },
    { lat: 48.4400763, lng: 20.0181418 },
    { lat: 48.4394939, lng: 20.0182235 },
    { lat: 48.4387333, lng: 20.0180811 },
    { lat: 48.4381696, lng: 20.0180986 },
    { lat: 48.4373224, lng: 20.0182837 },
    { lat: 48.4366685, lng: 20.0187833 },
    { lat: 48.4361076, lng: 20.020074 },
    { lat: 48.4349828, lng: 20.02243 },
    { lat: 48.4343334, lng: 20.0246243 },
    { lat: 48.433962, lng: 20.0251982 },
    { lat: 48.4332928, lng: 20.0264334 },
    { lat: 48.4332169, lng: 20.0267474 },
    { lat: 48.4325768, lng: 20.0279006 },
    { lat: 48.4324043, lng: 20.0283532 },
    { lat: 48.4323055, lng: 20.0289552 },
    { lat: 48.4323877, lng: 20.029465 },
    { lat: 48.4323524, lng: 20.0299135 },
    { lat: 48.432145, lng: 20.0303154 },
    { lat: 48.4318298, lng: 20.0306704 },
    { lat: 48.4315268, lng: 20.031429 },
    { lat: 48.4311504, lng: 20.0319905 },
    { lat: 48.4305249, lng: 20.0326585 },
    { lat: 48.4302549, lng: 20.0327736 },
    { lat: 48.429181, lng: 20.0338449 },
    { lat: 48.4289383, lng: 20.0340182 },
    { lat: 48.4286063, lng: 20.0341332 },
    { lat: 48.4278427, lng: 20.0349493 },
    { lat: 48.4270197, lng: 20.0354705 },
    { lat: 48.4266383, lng: 20.0357784 },
    { lat: 48.4257227, lng: 20.0361339 },
    { lat: 48.4251628, lng: 20.0373229 },
    { lat: 48.4247465, lng: 20.0377381 },
    { lat: 48.4245041, lng: 20.0379478 },
    { lat: 48.4246009, lng: 20.0383217 },
    { lat: 48.4247306, lng: 20.038867 },
    { lat: 48.4247584, lng: 20.0394822 },
    { lat: 48.4249065, lng: 20.0404365 },
    { lat: 48.4249497, lng: 20.0404182 },
    { lat: 48.4249687, lng: 20.0407384 },
    { lat: 48.4251628, lng: 20.0414543 },
    { lat: 48.4252401, lng: 20.0416038 },
    { lat: 48.4252698, lng: 20.042616 },
    { lat: 48.425335, lng: 20.0429904 },
    { lat: 48.4257687, lng: 20.0445275 },
    { lat: 48.4258299, lng: 20.0451882 },
    { lat: 48.425723, lng: 20.0455972 },
    { lat: 48.425472, lng: 20.0458726 },
    { lat: 48.4257222, lng: 20.0467389 },
    { lat: 48.4256388, lng: 20.0477111 },
    { lat: 48.4252766, lng: 20.0493191 },
    { lat: 48.4249902, lng: 20.0499929 },
    { lat: 48.4248672, lng: 20.0501509 },
    { lat: 48.4246756, lng: 20.050226 },
    { lat: 48.4246283, lng: 20.0504464 },
    { lat: 48.4244678, lng: 20.0507576 },
    { lat: 48.4243146, lng: 20.0507816 },
    { lat: 48.4242728, lng: 20.0509678 },
    { lat: 48.424057, lng: 20.051226 },
    { lat: 48.4240946, lng: 20.0518307 },
    { lat: 48.4239646, lng: 20.0523082 },
    { lat: 48.4240098, lng: 20.0525253 },
    { lat: 48.4237695, lng: 20.0532472 },
    { lat: 48.4235031, lng: 20.0538336 },
    { lat: 48.4234744, lng: 20.0542285 },
    { lat: 48.4236125, lng: 20.0554326 },
    { lat: 48.4233582, lng: 20.0564403 },
    { lat: 48.4233728, lng: 20.0566336 },
    { lat: 48.4233059, lng: 20.0568126 },
    { lat: 48.4229917, lng: 20.0572219 },
    { lat: 48.422649, lng: 20.0571317 },
    { lat: 48.4217236, lng: 20.0566287 },
    { lat: 48.4213365, lng: 20.057803 },
    { lat: 48.4212409, lng: 20.0590038 },
    { lat: 48.4210058, lng: 20.0600653 },
    { lat: 48.4204567, lng: 20.0611944 },
    { lat: 48.4199909, lng: 20.0619101 },
    { lat: 48.4188827, lng: 20.0627844 },
    { lat: 48.4182471, lng: 20.0628606 },
    { lat: 48.4177229, lng: 20.0630661 },
    { lat: 48.4174114, lng: 20.0636492 },
    { lat: 48.4167403, lng: 20.0651477 },
    { lat: 48.416784, lng: 20.0654965 },
  ],
  VieskanadBlhom: [
    { lat: 48.3471808, lng: 20.1536916 },
    { lat: 48.346527, lng: 20.1532924 },
    { lat: 48.3461684, lng: 20.153418 },
    { lat: 48.3449183, lng: 20.1524022 },
    { lat: 48.3439929, lng: 20.1535737 },
    { lat: 48.3432015, lng: 20.1543324 },
    { lat: 48.3425337, lng: 20.155214 },
    { lat: 48.3419406, lng: 20.1562745 },
    { lat: 48.3414955, lng: 20.1577945 },
    { lat: 48.341408, lng: 20.1588572 },
    { lat: 48.3417742, lng: 20.1608183 },
    { lat: 48.3424648, lng: 20.1625691 },
    { lat: 48.3431925, lng: 20.1639807 },
    { lat: 48.3441802, lng: 20.1652268 },
    { lat: 48.3446955, lng: 20.1673175 },
    { lat: 48.3446222, lng: 20.1694439 },
    { lat: 48.3446612, lng: 20.1714631 },
    { lat: 48.344628, lng: 20.1734991 },
    { lat: 48.3445126, lng: 20.1755989 },
    { lat: 48.343517, lng: 20.1774862 },
    { lat: 48.3431947, lng: 20.1793687 },
    { lat: 48.3428804, lng: 20.1817065 },
    { lat: 48.3427772, lng: 20.1822168 },
    { lat: 48.3430456, lng: 20.1846934 },
    { lat: 48.3433884, lng: 20.1871808 },
    { lat: 48.343721, lng: 20.188691 },
    { lat: 48.3442592, lng: 20.1904151 },
    { lat: 48.3432824, lng: 20.1910478 },
    { lat: 48.3435391, lng: 20.1923815 },
    { lat: 48.3436512, lng: 20.1933166 },
    { lat: 48.3434778, lng: 20.1940178 },
    { lat: 48.3434772, lng: 20.1950612 },
    { lat: 48.3435747, lng: 20.1953816 },
    { lat: 48.3434951, lng: 20.1954441 },
    { lat: 48.343666, lng: 20.1959932 },
    { lat: 48.3437821, lng: 20.1966653 },
    { lat: 48.3443167, lng: 20.2002857 },
    { lat: 48.344652, lng: 20.2013344 },
    { lat: 48.3448633, lng: 20.2017461 },
    { lat: 48.3457618, lng: 20.203022 },
    { lat: 48.3460376, lng: 20.2034927 },
    { lat: 48.3477785, lng: 20.2073927 },
    { lat: 48.3478248, lng: 20.2077928 },
    { lat: 48.348005, lng: 20.2084043 },
    { lat: 48.3441735, lng: 20.2132742 },
    { lat: 48.3446972, lng: 20.2144535 },
    { lat: 48.3451204, lng: 20.2159543 },
    { lat: 48.3464361, lng: 20.2152326 },
    { lat: 48.3471887, lng: 20.215072 },
    { lat: 48.3479087, lng: 20.215106 },
    { lat: 48.3482276, lng: 20.2152184 },
    { lat: 48.3498512, lng: 20.2160455 },
    { lat: 48.3502108, lng: 20.2161383 },
    { lat: 48.3509677, lng: 20.215885 },
    { lat: 48.3518171, lng: 20.2154983 },
    { lat: 48.3520293, lng: 20.2169666 },
    { lat: 48.3519877, lng: 20.2174162 },
    { lat: 48.3537981, lng: 20.2175668 },
    { lat: 48.3551234, lng: 20.2189307 },
    { lat: 48.3557506, lng: 20.2192691 },
    { lat: 48.3561365, lng: 20.2199867 },
    { lat: 48.3570052, lng: 20.2210584 },
    { lat: 48.357677, lng: 20.2204569 },
    { lat: 48.3582414, lng: 20.219138 },
    { lat: 48.3595856, lng: 20.2161807 },
    { lat: 48.3591224, lng: 20.2149442 },
    { lat: 48.3586028, lng: 20.2140669 },
    { lat: 48.3575603, lng: 20.2136556 },
    { lat: 48.3561928, lng: 20.2126749 },
    { lat: 48.3550993, lng: 20.2111096 },
    { lat: 48.355297, lng: 20.2108435 },
    { lat: 48.3559309, lng: 20.2102793 },
    { lat: 48.3564822, lng: 20.209957 },
    { lat: 48.3588718, lng: 20.2092223 },
    { lat: 48.359095, lng: 20.2088918 },
    { lat: 48.3592755, lng: 20.2063252 },
    { lat: 48.3591852, lng: 20.205737 },
    { lat: 48.3589247, lng: 20.2049992 },
    { lat: 48.3587125, lng: 20.2045673 },
    { lat: 48.3584278, lng: 20.2042379 },
    { lat: 48.3575593, lng: 20.2036534 },
    { lat: 48.3572204, lng: 20.2034597 },
    { lat: 48.3570364, lng: 20.2034222 },
    { lat: 48.3567679, lng: 20.2028201 },
    { lat: 48.3574321, lng: 20.2026164 },
    { lat: 48.3579228, lng: 20.2023838 },
    { lat: 48.3588267, lng: 20.2018965 },
    { lat: 48.3593942, lng: 20.2015015 },
    { lat: 48.36205, lng: 20.1987081 },
    { lat: 48.3624285, lng: 20.1982392 },
    { lat: 48.3593292, lng: 20.1925045 },
    { lat: 48.3584576, lng: 20.1908626 },
    { lat: 48.3583103, lng: 20.1905073 },
    { lat: 48.3561624, lng: 20.1868045 },
    { lat: 48.3558409, lng: 20.1864538 },
    { lat: 48.3546464, lng: 20.1858763 },
    { lat: 48.3540252, lng: 20.185576 },
    { lat: 48.3522697, lng: 20.1846871 },
    { lat: 48.3514429, lng: 20.1834568 },
    { lat: 48.3494744, lng: 20.1808804 },
    { lat: 48.348763, lng: 20.1796968 },
    { lat: 48.3484141, lng: 20.178455 },
    { lat: 48.3481506, lng: 20.1772316 },
    { lat: 48.3475885, lng: 20.1671609 },
    { lat: 48.3469848, lng: 20.1644742 },
    { lat: 48.3468051, lng: 20.1634117 },
    { lat: 48.3466656, lng: 20.1620302 },
    { lat: 48.346623, lng: 20.161036 },
    { lat: 48.3458337, lng: 20.1575067 },
    { lat: 48.3457992, lng: 20.15622 },
    { lat: 48.3461414, lng: 20.1546073 },
    { lat: 48.3467202, lng: 20.1540773 },
    { lat: 48.3471023, lng: 20.1541396 },
    { lat: 48.3471808, lng: 20.1536916 },
  ],
  Pavlovce: [
    { lat: 48.3315448, lng: 20.1293136 },
    { lat: 48.3316534, lng: 20.129076 },
    { lat: 48.3323484, lng: 20.1294947 },
    { lat: 48.3325146, lng: 20.1297541 },
    { lat: 48.332484, lng: 20.1289268 },
    { lat: 48.3324297, lng: 20.1270802 },
    { lat: 48.3327705, lng: 20.1251762 },
    { lat: 48.3301644, lng: 20.1219998 },
    { lat: 48.3334827, lng: 20.1014207 },
    { lat: 48.333361, lng: 20.1014171 },
    { lat: 48.3348017, lng: 20.092603 },
    { lat: 48.3372889, lng: 20.0910525 },
    { lat: 48.337225, lng: 20.0909217 },
    { lat: 48.3376985, lng: 20.0896542 },
    { lat: 48.336652, lng: 20.0885595 },
    { lat: 48.3361012, lng: 20.0878259 },
    { lat: 48.3366764, lng: 20.0870545 },
    { lat: 48.3350287, lng: 20.0840393 },
    { lat: 48.3356096, lng: 20.0834015 },
    { lat: 48.3348301, lng: 20.0824528 },
    { lat: 48.3346847, lng: 20.0820645 },
    { lat: 48.3341422, lng: 20.081806 },
    { lat: 48.3339179, lng: 20.0819348 },
    { lat: 48.3323458, lng: 20.0789307 },
    { lat: 48.3332976, lng: 20.0780335 },
    { lat: 48.3328134, lng: 20.0773019 },
    { lat: 48.3323233, lng: 20.0763493 },
    { lat: 48.3321335, lng: 20.075846 },
    { lat: 48.3320405, lng: 20.0751534 },
    { lat: 48.330796, lng: 20.0748822 },
    { lat: 48.3303563, lng: 20.073869 },
    { lat: 48.3300991, lng: 20.0737758 },
    { lat: 48.3298956, lng: 20.0735697 },
    { lat: 48.3297424, lng: 20.0735128 },
    { lat: 48.3295572, lng: 20.0735605 },
    { lat: 48.3293919, lng: 20.0737522 },
    { lat: 48.3289501, lng: 20.0727694 },
    { lat: 48.3290772, lng: 20.0726867 },
    { lat: 48.3281618, lng: 20.0702788 },
    { lat: 48.3276358, lng: 20.0703898 },
    { lat: 48.3266013, lng: 20.0708364 },
    { lat: 48.3251343, lng: 20.0719389 },
    { lat: 48.3247754, lng: 20.0703499 },
    { lat: 48.3243398, lng: 20.0688697 },
    { lat: 48.3246884, lng: 20.0685278 },
    { lat: 48.3242854, lng: 20.0676098 },
    { lat: 48.3235381, lng: 20.0663254 },
    { lat: 48.3228365, lng: 20.0670344 },
    { lat: 48.3220641, lng: 20.068123 },
    { lat: 48.3221572, lng: 20.0685744 },
    { lat: 48.3220847, lng: 20.0691082 },
    { lat: 48.3219578, lng: 20.0689848 },
    { lat: 48.321772, lng: 20.0690641 },
    { lat: 48.3209529, lng: 20.0699839 },
    { lat: 48.3200062, lng: 20.0706796 },
    { lat: 48.3199525, lng: 20.0700051 },
    { lat: 48.3195388, lng: 20.0693483 },
    { lat: 48.3194053, lng: 20.0697154 },
    { lat: 48.3192506, lng: 20.0698147 },
    { lat: 48.319196, lng: 20.0697512 },
    { lat: 48.3191048, lng: 20.0698563 },
    { lat: 48.3191386, lng: 20.0699643 },
    { lat: 48.3190494, lng: 20.0702055 },
    { lat: 48.3184958, lng: 20.0707381 },
    { lat: 48.318314, lng: 20.0712424 },
    { lat: 48.3181534, lng: 20.0713299 },
    { lat: 48.3179294, lng: 20.0716374 },
    { lat: 48.317946, lng: 20.0720096 },
    { lat: 48.317781, lng: 20.0725381 },
    { lat: 48.3178032, lng: 20.0727491 },
    { lat: 48.3177453, lng: 20.0728727 },
    { lat: 48.3178993, lng: 20.0730795 },
    { lat: 48.3178591, lng: 20.0737157 },
    { lat: 48.3178877, lng: 20.0738857 },
    { lat: 48.3180275, lng: 20.0739743 },
    { lat: 48.3181371, lng: 20.0744418 },
    { lat: 48.3183575, lng: 20.0744071 },
    { lat: 48.3184249, lng: 20.074456 },
    { lat: 48.318421, lng: 20.0750672 },
    { lat: 48.3184737, lng: 20.0752502 },
    { lat: 48.3187504, lng: 20.0755928 },
    { lat: 48.3186897, lng: 20.0760083 },
    { lat: 48.318717, lng: 20.0763374 },
    { lat: 48.3186549, lng: 20.0764153 },
    { lat: 48.3184733, lng: 20.0764362 },
    { lat: 48.31823, lng: 20.0769281 },
    { lat: 48.3175887, lng: 20.0773269 },
    { lat: 48.3174494, lng: 20.0773363 },
    { lat: 48.3171294, lng: 20.0771963 },
    { lat: 48.3170378, lng: 20.0768888 },
    { lat: 48.3162172, lng: 20.0771188 },
    { lat: 48.3161162, lng: 20.0771523 },
    { lat: 48.3159337, lng: 20.0770214 },
    { lat: 48.3157742, lng: 20.0771368 },
    { lat: 48.3157611, lng: 20.0774469 },
    { lat: 48.3156177, lng: 20.0776553 },
    { lat: 48.3155218, lng: 20.0780846 },
    { lat: 48.3164068, lng: 20.0843894 },
    { lat: 48.3163764, lng: 20.0844991 },
    { lat: 48.3161662, lng: 20.0843417 },
    { lat: 48.3158904, lng: 20.084293 },
    { lat: 48.3156918, lng: 20.0841194 },
    { lat: 48.3153689, lng: 20.0831808 },
    { lat: 48.31518, lng: 20.0830516 },
    { lat: 48.3149966, lng: 20.0831107 },
    { lat: 48.3146161, lng: 20.0830633 },
    { lat: 48.313814, lng: 20.0833298 },
    { lat: 48.3135162, lng: 20.0836455 },
    { lat: 48.3128664, lng: 20.0850648 },
    { lat: 48.3128038, lng: 20.0853219 },
    { lat: 48.3128197, lng: 20.0857333 },
    { lat: 48.3129767, lng: 20.0861218 },
    { lat: 48.3129065, lng: 20.0864691 },
    { lat: 48.3126462, lng: 20.0866015 },
    { lat: 48.3124443, lng: 20.086569 },
    { lat: 48.3121192, lng: 20.0862259 },
    { lat: 48.3118688, lng: 20.0861636 },
    { lat: 48.3118411, lng: 20.0864475 },
    { lat: 48.3120069, lng: 20.0870778 },
    { lat: 48.31257, lng: 20.0871037 },
    { lat: 48.3135792, lng: 20.0938842 },
    { lat: 48.3133891, lng: 20.0939716 },
    { lat: 48.3133185, lng: 20.09428 },
    { lat: 48.3134208, lng: 20.0949573 },
    { lat: 48.3140578, lng: 20.0969609 },
    { lat: 48.3149407, lng: 20.0976397 },
    { lat: 48.3162966, lng: 20.0989181 },
    { lat: 48.3170036, lng: 20.0988002 },
    { lat: 48.3181766, lng: 20.099712 },
    { lat: 48.318219, lng: 20.0994533 },
    { lat: 48.3222516, lng: 20.0980108 },
    { lat: 48.3205369, lng: 20.1097757 },
    { lat: 48.3186097, lng: 20.1072897 },
    { lat: 48.3181046, lng: 20.1065674 },
    { lat: 48.3172787, lng: 20.1140527 },
    { lat: 48.3177083, lng: 20.1146376 },
    { lat: 48.3187519, lng: 20.1161895 },
    { lat: 48.3189727, lng: 20.1166002 },
    { lat: 48.3191742, lng: 20.1170052 },
    { lat: 48.3195973, lng: 20.1183126 },
    { lat: 48.322127, lng: 20.1205835 },
    { lat: 48.322661, lng: 20.1209731 },
    { lat: 48.323444, lng: 20.1213301 },
    { lat: 48.3240469, lng: 20.1217522 },
    { lat: 48.3244668, lng: 20.1221821 },
    { lat: 48.325413, lng: 20.1241596 },
    { lat: 48.3270541, lng: 20.1270404 },
    { lat: 48.3311226, lng: 20.1292626 },
    { lat: 48.3315448, lng: 20.1293136 },
  ],
  Zacharovce: [
    { lat: 48.4245041, lng: 20.0379478 },
    { lat: 48.4239108, lng: 20.0377125 },
    { lat: 48.4219019, lng: 20.0374885 },
    { lat: 48.4211147, lng: 20.0382471 },
    { lat: 48.4208802, lng: 20.0387028 },
    { lat: 48.4209465, lng: 20.0392342 },
    { lat: 48.4209193, lng: 20.040012 },
    { lat: 48.4204276, lng: 20.0402434 },
    { lat: 48.4197562, lng: 20.0404349 },
    { lat: 48.4194027, lng: 20.0403022 },
    { lat: 48.4193757, lng: 20.0403828 },
    { lat: 48.4167507, lng: 20.0394295 },
    { lat: 48.41647, lng: 20.0390999 },
    { lat: 48.4160791, lng: 20.0383183 },
    { lat: 48.4159679, lng: 20.0384866 },
    { lat: 48.4149718, lng: 20.0369149 },
    { lat: 48.4147517, lng: 20.0368502 },
    { lat: 48.4142672, lng: 20.0371139 },
    { lat: 48.4135007, lng: 20.0374006 },
    { lat: 48.4131007, lng: 20.0383974 },
    { lat: 48.4134727, lng: 20.03859 },
    { lat: 48.4130364, lng: 20.0397423 },
    { lat: 48.4129496, lng: 20.0402532 },
    { lat: 48.4127681, lng: 20.0406557 },
    { lat: 48.4127588, lng: 20.0409998 },
    { lat: 48.4120263, lng: 20.0422661 },
    { lat: 48.4116693, lng: 20.042722 },
    { lat: 48.4113997, lng: 20.0424218 },
    { lat: 48.4112014, lng: 20.0431457 },
    { lat: 48.4110033, lng: 20.0431501 },
    { lat: 48.4106284, lng: 20.0442342 },
    { lat: 48.4104621, lng: 20.0440569 },
    { lat: 48.4101892, lng: 20.0435352 },
    { lat: 48.4101134, lng: 20.043098 },
    { lat: 48.4093905, lng: 20.0434302 },
    { lat: 48.4085266, lng: 20.0441308 },
    { lat: 48.4078044, lng: 20.0445578 },
    { lat: 48.4076824, lng: 20.044554 },
    { lat: 48.4074299, lng: 20.0444133 },
    { lat: 48.4070673, lng: 20.0440113 },
    { lat: 48.4066403, lng: 20.0434119 },
    { lat: 48.4065509, lng: 20.0438365 },
    { lat: 48.4064495, lng: 20.0440783 },
    { lat: 48.4053825, lng: 20.0434522 },
    { lat: 48.4054207, lng: 20.0433663 },
    { lat: 48.405331, lng: 20.0432695 },
    { lat: 48.4052147, lng: 20.0435041 },
    { lat: 48.4049757, lng: 20.0444185 },
    { lat: 48.4050709, lng: 20.0451122 },
    { lat: 48.404101, lng: 20.0457004 },
    { lat: 48.4005995, lng: 20.0482623 },
    { lat: 48.4003086, lng: 20.0473872 },
    { lat: 48.4000894, lng: 20.0463968 },
    { lat: 48.4000917, lng: 20.0463445 },
    { lat: 48.3997475, lng: 20.0466551 },
    { lat: 48.3986878, lng: 20.0467153 },
    { lat: 48.3984078, lng: 20.0468331 },
    { lat: 48.3975591, lng: 20.0469039 },
    { lat: 48.3969594, lng: 20.0476252 },
    { lat: 48.3968143, lng: 20.0475768 },
    { lat: 48.3966118, lng: 20.0481211 },
    { lat: 48.3963428, lng: 20.0491038 },
    { lat: 48.3957937, lng: 20.049816 },
    { lat: 48.3958967, lng: 20.0499081 },
    { lat: 48.3958051, lng: 20.05009 },
    { lat: 48.395906, lng: 20.0504612 },
    { lat: 48.3956122, lng: 20.0509783 },
    { lat: 48.3954387, lng: 20.0516538 },
    { lat: 48.3951795, lng: 20.0520847 },
    { lat: 48.3947645, lng: 20.0524242 },
    { lat: 48.3924753, lng: 20.0539369 },
    { lat: 48.3910773, lng: 20.0561811 },
    { lat: 48.3907895, lng: 20.0558667 },
    { lat: 48.3903071, lng: 20.0564301 },
    { lat: 48.3903113, lng: 20.0565814 },
    { lat: 48.3901791, lng: 20.0568479 },
    { lat: 48.3896409, lng: 20.0573424 },
    { lat: 48.3895301, lng: 20.0580613 },
    { lat: 48.3894148, lng: 20.0583474 },
    { lat: 48.3896912, lng: 20.0586827 },
    { lat: 48.3895935, lng: 20.0588034 },
    { lat: 48.3896987, lng: 20.0589492 },
    { lat: 48.3900332, lng: 20.0599201 },
    { lat: 48.3901845, lng: 20.0626426 },
    { lat: 48.3904693, lng: 20.0642374 },
    { lat: 48.3905515, lng: 20.064942 },
    { lat: 48.3909622, lng: 20.0665867 },
    { lat: 48.3908859, lng: 20.0666473 },
    { lat: 48.391745, lng: 20.0722354 },
    { lat: 48.3917683, lng: 20.0724998 },
    { lat: 48.3920118, lng: 20.0725826 },
    { lat: 48.3920672, lng: 20.0728319 },
    { lat: 48.392418, lng: 20.0737167 },
    { lat: 48.3928347, lng: 20.0737941 },
    { lat: 48.3933103, lng: 20.0736904 },
    { lat: 48.393641, lng: 20.0735188 },
    { lat: 48.3949918, lng: 20.0732282 },
    { lat: 48.3954499, lng: 20.0732428 },
    { lat: 48.3964909, lng: 20.0736822 },
    { lat: 48.3979254, lng: 20.0737548 },
    { lat: 48.3982332, lng: 20.0736233 },
    { lat: 48.3985803, lng: 20.0732162 },
    { lat: 48.3990422, lng: 20.0724049 },
    { lat: 48.3993919, lng: 20.0720394 },
    { lat: 48.3995972, lng: 20.0718781 },
    { lat: 48.4001051, lng: 20.0716311 },
    { lat: 48.4006757, lng: 20.0716227 },
    { lat: 48.4012687, lng: 20.0717148 },
    { lat: 48.4023703, lng: 20.0714711 },
    { lat: 48.402653, lng: 20.0714695 },
    { lat: 48.4043937, lng: 20.072075 },
    { lat: 48.4051488, lng: 20.0724621 },
    { lat: 48.4057195, lng: 20.0730827 },
    { lat: 48.4057681, lng: 20.0730303 },
    { lat: 48.4064465, lng: 20.0723606 },
    { lat: 48.407651, lng: 20.0715273 },
    { lat: 48.4082241, lng: 20.0711394 },
    { lat: 48.4083345, lng: 20.0711397 },
    { lat: 48.40952, lng: 20.0704691 },
    { lat: 48.4100005, lng: 20.0696183 },
    { lat: 48.4112703, lng: 20.0678059 },
    { lat: 48.4121646, lng: 20.0675042 },
    { lat: 48.4131715, lng: 20.0670699 },
    { lat: 48.4133094, lng: 20.0674066 },
    { lat: 48.4148284, lng: 20.0672546 },
    { lat: 48.4152944, lng: 20.0669006 },
    { lat: 48.4154433, lng: 20.0667875 },
    { lat: 48.4159047, lng: 20.0659915 },
    { lat: 48.4165285, lng: 20.0654434 },
    { lat: 48.416784, lng: 20.0654965 },
    { lat: 48.4167403, lng: 20.0651477 },
    { lat: 48.4174114, lng: 20.0636492 },
    { lat: 48.4177229, lng: 20.0630661 },
    { lat: 48.4182471, lng: 20.0628606 },
    { lat: 48.4188827, lng: 20.0627844 },
    { lat: 48.4199909, lng: 20.0619101 },
    { lat: 48.4204567, lng: 20.0611944 },
    { lat: 48.4210058, lng: 20.0600653 },
    { lat: 48.4212409, lng: 20.0590038 },
    { lat: 48.4213365, lng: 20.057803 },
    { lat: 48.4217236, lng: 20.0566287 },
    { lat: 48.422649, lng: 20.0571317 },
    { lat: 48.4229917, lng: 20.0572219 },
    { lat: 48.4233059, lng: 20.0568126 },
    { lat: 48.4233728, lng: 20.0566336 },
    { lat: 48.4233582, lng: 20.0564403 },
    { lat: 48.4236125, lng: 20.0554326 },
    { lat: 48.4234744, lng: 20.0542285 },
    { lat: 48.4235031, lng: 20.0538336 },
    { lat: 48.4237695, lng: 20.0532472 },
    { lat: 48.4240098, lng: 20.0525253 },
    { lat: 48.4239646, lng: 20.0523082 },
    { lat: 48.4240946, lng: 20.0518307 },
    { lat: 48.424057, lng: 20.051226 },
    { lat: 48.4242728, lng: 20.0509678 },
    { lat: 48.4243146, lng: 20.0507816 },
    { lat: 48.4244678, lng: 20.0507576 },
    { lat: 48.4246283, lng: 20.0504464 },
    { lat: 48.4246756, lng: 20.050226 },
    { lat: 48.4248672, lng: 20.0501509 },
    { lat: 48.4249902, lng: 20.0499929 },
    { lat: 48.4252766, lng: 20.0493191 },
    { lat: 48.4256388, lng: 20.0477111 },
    { lat: 48.4257222, lng: 20.0467389 },
    { lat: 48.425472, lng: 20.0458726 },
    { lat: 48.425723, lng: 20.0455972 },
    { lat: 48.4258299, lng: 20.0451882 },
    { lat: 48.4257687, lng: 20.0445275 },
    { lat: 48.425335, lng: 20.0429904 },
    { lat: 48.4252698, lng: 20.042616 },
    { lat: 48.4252401, lng: 20.0416038 },
    { lat: 48.4251628, lng: 20.0414543 },
    { lat: 48.4249687, lng: 20.0407384 },
    { lat: 48.4249497, lng: 20.0404182 },
    { lat: 48.4249065, lng: 20.0404365 },
    { lat: 48.4247584, lng: 20.0394822 },
    { lat: 48.4247306, lng: 20.038867 },
    { lat: 48.4246009, lng: 20.0383217 },
    { lat: 48.4245041, lng: 20.0379478 },
  ],
  Včelince: [
    { lat: 48.3730964, lng: 20.3449334 },
    { lat: 48.3732094, lng: 20.344945 },
    { lat: 48.3741062, lng: 20.3448119 },
    { lat: 48.374757, lng: 20.3446044 },
    { lat: 48.3762027, lng: 20.3446536 },
    { lat: 48.3770091, lng: 20.3447682 },
    { lat: 48.3778736, lng: 20.3447576 },
    { lat: 48.3785088, lng: 20.3448161 },
    { lat: 48.3787317, lng: 20.3449332 },
    { lat: 48.3790071, lng: 20.3421795 },
    { lat: 48.3794535, lng: 20.3406378 },
    { lat: 48.3799585, lng: 20.3394679 },
    { lat: 48.3801924, lng: 20.3383944 },
    { lat: 48.3802721, lng: 20.3376679 },
    { lat: 48.3804589, lng: 20.3369422 },
    { lat: 48.3807464, lng: 20.3362926 },
    { lat: 48.3813517, lng: 20.3358138 },
    { lat: 48.3802416, lng: 20.3328626 },
    { lat: 48.3800839, lng: 20.3323133 },
    { lat: 48.3798386, lng: 20.327059 },
    { lat: 48.379766, lng: 20.3264721 },
    { lat: 48.3804858, lng: 20.3263574 },
    { lat: 48.3805035, lng: 20.326809 },
    { lat: 48.3814282, lng: 20.3268165 },
    { lat: 48.3883697, lng: 20.3266595 },
    { lat: 48.3892959, lng: 20.3264696 },
    { lat: 48.3900229, lng: 20.3266009 },
    { lat: 48.3919797, lng: 20.326616 },
    { lat: 48.398438, lng: 20.326459 },
    { lat: 48.398453, lng: 20.326039 },
    { lat: 48.398221, lng: 20.324835 },
    { lat: 48.398198, lng: 20.322399 },
    { lat: 48.398179, lng: 20.322323 },
    { lat: 48.39765, lng: 20.320652 },
    { lat: 48.397615, lng: 20.319516 },
    { lat: 48.398504, lng: 20.319473 },
    { lat: 48.399756, lng: 20.319412 },
    { lat: 48.399746, lng: 20.319136 },
    { lat: 48.399736, lng: 20.318582 },
    { lat: 48.399689, lng: 20.316092 },
    { lat: 48.399616, lng: 20.31283 },
    { lat: 48.399613, lng: 20.312715 },
    { lat: 48.399521, lng: 20.309392 },
    { lat: 48.399499, lng: 20.306808 },
    { lat: 48.399478, lng: 20.304358 },
    { lat: 48.399477, lng: 20.304278 },
    { lat: 48.399477, lng: 20.304276 },
    { lat: 48.399476, lng: 20.304248 },
    { lat: 48.399462, lng: 20.303992 },
    { lat: 48.399438, lng: 20.303509 },
    { lat: 48.399397, lng: 20.302712 },
    { lat: 48.399368, lng: 20.302144 },
    { lat: 48.399356, lng: 20.301912 },
    { lat: 48.399352, lng: 20.301829 },
    { lat: 48.399345, lng: 20.301686 },
    { lat: 48.399344, lng: 20.301673 },
    { lat: 48.399467, lng: 20.30134 },
    { lat: 48.399674, lng: 20.300782 },
    { lat: 48.399836, lng: 20.300341 },
    { lat: 48.399936, lng: 20.300072 },
    { lat: 48.400122, lng: 20.29957 },
    { lat: 48.400394, lng: 20.298834 },
    { lat: 48.401188, lng: 20.294396 },
    { lat: 48.402014, lng: 20.289321 },
    { lat: 48.402911, lng: 20.283803 },
    { lat: 48.4023403, lng: 20.2838409 },
    { lat: 48.4024088, lng: 20.2829541 },
    { lat: 48.4021481, lng: 20.2831414 },
    { lat: 48.4019663, lng: 20.2827281 },
    { lat: 48.4019327, lng: 20.2822247 },
    { lat: 48.4013926, lng: 20.2816672 },
    { lat: 48.4014498, lng: 20.2812049 },
    { lat: 48.3995423, lng: 20.2804751 },
    { lat: 48.3982359, lng: 20.2801903 },
    { lat: 48.3977627, lng: 20.2801539 },
    { lat: 48.3972072, lng: 20.2804668 },
    { lat: 48.396819, lng: 20.2805855 },
    { lat: 48.3964969, lng: 20.2804434 },
    { lat: 48.3952601, lng: 20.2819168 },
    { lat: 48.3942245, lng: 20.2826433 },
    { lat: 48.3919102, lng: 20.2842638 },
    { lat: 48.3911265, lng: 20.2848124 },
    { lat: 48.3864587, lng: 20.2877233 },
    { lat: 48.3834282, lng: 20.2885228 },
    { lat: 48.3818452, lng: 20.2881088 },
    { lat: 48.3805351, lng: 20.2876772 },
    { lat: 48.3799885, lng: 20.2873837 },
    { lat: 48.3797745, lng: 20.2877794 },
    { lat: 48.3791668, lng: 20.28924 },
    { lat: 48.3787134, lng: 20.2897721 },
    { lat: 48.3773371, lng: 20.2916157 },
    { lat: 48.3770511, lng: 20.2921073 },
    { lat: 48.3756203, lng: 20.2940856 },
    { lat: 48.3743357, lng: 20.2954352 },
    { lat: 48.3742262, lng: 20.2954871 },
    { lat: 48.3740155, lng: 20.2953698 },
    { lat: 48.3738344, lng: 20.2954642 },
    { lat: 48.3728579, lng: 20.2956329 },
    { lat: 48.3721735, lng: 20.2959846 },
    { lat: 48.3717818, lng: 20.2963868 },
    { lat: 48.3715082, lng: 20.2967898 },
    { lat: 48.3709554, lng: 20.2971939 },
    { lat: 48.3665156, lng: 20.299127 },
    { lat: 48.3659894, lng: 20.2994344 },
    { lat: 48.3655969, lng: 20.2997633 },
    { lat: 48.3653209, lng: 20.3001597 },
    { lat: 48.3652003, lng: 20.3004413 },
    { lat: 48.364897, lng: 20.3016296 },
    { lat: 48.3646444, lng: 20.3035977 },
    { lat: 48.3642158, lng: 20.3049189 },
    { lat: 48.3637574, lng: 20.3055765 },
    { lat: 48.3631529, lng: 20.3061295 },
    { lat: 48.3612037, lng: 20.3067619 },
    { lat: 48.3613839, lng: 20.3083041 },
    { lat: 48.3617762, lng: 20.3099188 },
    { lat: 48.3630715, lng: 20.3152471 },
    { lat: 48.3632011, lng: 20.3177978 },
    { lat: 48.3633134, lng: 20.3184767 },
    { lat: 48.3631603, lng: 20.3190792 },
    { lat: 48.3624264, lng: 20.3194048 },
    { lat: 48.3636884, lng: 20.3250692 },
    { lat: 48.3638025, lng: 20.32732 },
    { lat: 48.3640902, lng: 20.3293667 },
    { lat: 48.3639889, lng: 20.3299447 },
    { lat: 48.3640121, lng: 20.3307108 },
    { lat: 48.3642967, lng: 20.3308493 },
    { lat: 48.3648264, lng: 20.3304551 },
    { lat: 48.3649854, lng: 20.3310899 },
    { lat: 48.3648355, lng: 20.331464 },
    { lat: 48.3654014, lng: 20.3327095 },
    { lat: 48.3658345, lng: 20.3335068 },
    { lat: 48.3663986, lng: 20.3342496 },
    { lat: 48.3666406, lng: 20.3347039 },
    { lat: 48.3678736, lng: 20.3363401 },
    { lat: 48.3678192, lng: 20.3370487 },
    { lat: 48.3676675, lng: 20.3373163 },
    { lat: 48.3689184, lng: 20.3388098 },
    { lat: 48.3688694, lng: 20.339221 },
    { lat: 48.3693639, lng: 20.3405811 },
    { lat: 48.3695435, lng: 20.3408528 },
    { lat: 48.3702783, lng: 20.3422743 },
    { lat: 48.3704976, lng: 20.3428149 },
    { lat: 48.370899, lng: 20.3441535 },
    { lat: 48.3714962, lng: 20.3445064 },
    { lat: 48.3716477, lng: 20.344174 },
    { lat: 48.3721223, lng: 20.3443327 },
    { lat: 48.3726273, lng: 20.344749 },
    { lat: 48.3730964, lng: 20.3449334 },
  ],
  Studená: [
    { lat: 48.166005, lng: 19.974394 },
    { lat: 48.1660152, lng: 19.9735454 },
    { lat: 48.1665999, lng: 19.9735174 },
    { lat: 48.1666942, lng: 19.9727141 },
    { lat: 48.166661, lng: 19.9708728 },
    { lat: 48.1669508, lng: 19.9699271 },
    { lat: 48.1672983, lng: 19.9692486 },
    { lat: 48.1677463, lng: 19.9692149 },
    { lat: 48.1682287, lng: 19.9692735 },
    { lat: 48.1682159, lng: 19.9675967 },
    { lat: 48.1683017, lng: 19.9658012 },
    { lat: 48.169614, lng: 19.965874 },
    { lat: 48.1700633, lng: 19.965976 },
    { lat: 48.1708037, lng: 19.9663269 },
    { lat: 48.1709413, lng: 19.9662761 },
    { lat: 48.1686168, lng: 19.9632262 },
    { lat: 48.1648709, lng: 19.9603878 },
    { lat: 48.1633041, lng: 19.9591015 },
    { lat: 48.1630072, lng: 19.9552569 },
    { lat: 48.1632778, lng: 19.9542826 },
    { lat: 48.1632838, lng: 19.9541263 },
    { lat: 48.1632033, lng: 19.9539956 },
    { lat: 48.163157, lng: 19.9539495 },
    { lat: 48.1631419, lng: 19.9539798 },
    { lat: 48.163124, lng: 19.9540128 },
    { lat: 48.1631159, lng: 19.9540388 },
    { lat: 48.1630823, lng: 19.9540023 },
    { lat: 48.1630406, lng: 19.9541106 },
    { lat: 48.1628548, lng: 19.9538023 },
    { lat: 48.1625868, lng: 19.9532695 },
    { lat: 48.1619866, lng: 19.9531404 },
    { lat: 48.1609863, lng: 19.9541733 },
    { lat: 48.1602206, lng: 19.955104 },
    { lat: 48.1601607, lng: 19.9551556 },
    { lat: 48.1596666, lng: 19.9558515 },
    { lat: 48.1588935, lng: 19.9552664 },
    { lat: 48.1581836, lng: 19.9543914 },
    { lat: 48.1580947, lng: 19.9545194 },
    { lat: 48.1577067, lng: 19.9540067 },
    { lat: 48.151433, lng: 19.9524 },
    { lat: 48.151358, lng: 19.952895 },
    { lat: 48.151292, lng: 19.953735 },
    { lat: 48.151178, lng: 19.955067 },
    { lat: 48.151074, lng: 19.956306 },
    { lat: 48.151888, lng: 19.956131 },
    { lat: 48.151944, lng: 19.957202 },
    { lat: 48.152047, lng: 19.95781 },
    { lat: 48.151808, lng: 19.95796 },
    { lat: 48.151927, lng: 19.958619 },
    { lat: 48.151934, lng: 19.958657 },
    { lat: 48.151896, lng: 19.958735 },
    { lat: 48.152273, lng: 19.959256 },
    { lat: 48.152452, lng: 19.959718 },
    { lat: 48.152504, lng: 19.960045 },
    { lat: 48.152575, lng: 19.960609 },
    { lat: 48.152572, lng: 19.960877 },
    { lat: 48.152568, lng: 19.961146 },
    { lat: 48.152518, lng: 19.962152 },
    { lat: 48.152136, lng: 19.96209 },
    { lat: 48.152063, lng: 19.963003 },
    { lat: 48.152021, lng: 19.963565 },
    { lat: 48.15289, lng: 19.964239 },
    { lat: 48.153443, lng: 19.964668 },
    { lat: 48.154273, lng: 19.965311 },
    { lat: 48.15482, lng: 19.965737 },
    { lat: 48.155575, lng: 19.966322 },
    { lat: 48.156237, lng: 19.966834 },
    { lat: 48.156253, lng: 19.9668469 },
    { lat: 48.156271, lng: 19.966862 },
    { lat: 48.156309, lng: 19.966891 },
    { lat: 48.156879, lng: 19.967333 },
    { lat: 48.157262, lng: 19.967631 },
    { lat: 48.157424, lng: 19.967758 },
    { lat: 48.157735, lng: 19.968003 },
    { lat: 48.158067, lng: 19.968264 },
    { lat: 48.15815, lng: 19.968329 },
    { lat: 48.158214, lng: 19.96838 },
    { lat: 48.158258, lng: 19.968414 },
    { lat: 48.158663, lng: 19.968731 },
    { lat: 48.158856, lng: 19.968884 },
    { lat: 48.159577, lng: 19.969449 },
    { lat: 48.160504, lng: 19.97018 },
    { lat: 48.16127, lng: 19.970781 },
    { lat: 48.16167, lng: 19.971096 },
    { lat: 48.162144, lng: 19.97147 },
    { lat: 48.162652, lng: 19.971869 },
    { lat: 48.163132, lng: 19.972229 },
    { lat: 48.163524, lng: 19.972525 },
    { lat: 48.163745, lng: 19.972695 },
    { lat: 48.164428, lng: 19.973221 },
    { lat: 48.165215, lng: 19.973828 },
    { lat: 48.166005, lng: 19.974394 },
  ],
  Belín: [
    { lat: 48.3560022, lng: 20.1016996 },
    { lat: 48.3557189, lng: 20.1013045 },
    { lat: 48.3556043, lng: 20.1011235 },
    { lat: 48.3548557, lng: 20.1007775 },
    { lat: 48.3534457, lng: 20.1003223 },
    { lat: 48.3527998, lng: 20.1003362 },
    { lat: 48.3516627, lng: 20.1005168 },
    { lat: 48.3502734, lng: 20.1013241 },
    { lat: 48.3493686, lng: 20.1010412 },
    { lat: 48.3472168, lng: 20.1011428 },
    { lat: 48.3468326, lng: 20.1009483 },
    { lat: 48.346036, lng: 20.1008076 },
    { lat: 48.3460301, lng: 20.0992438 },
    { lat: 48.3457027, lng: 20.0993068 },
    { lat: 48.3456289, lng: 20.0988289 },
    { lat: 48.3450968, lng: 20.0984604 },
    { lat: 48.3442453, lng: 20.0986808 },
    { lat: 48.3439652, lng: 20.09648 },
    { lat: 48.3435723, lng: 20.0962934 },
    { lat: 48.3436514, lng: 20.0957281 },
    { lat: 48.3414551, lng: 20.0951868 },
    { lat: 48.3410047, lng: 20.0950257 },
    { lat: 48.3401409, lng: 20.0942956 },
    { lat: 48.3395453, lng: 20.0941388 },
    { lat: 48.3389366, lng: 20.0933641 },
    { lat: 48.3380888, lng: 20.0926091 },
    { lat: 48.3382315, lng: 20.0922478 },
    { lat: 48.3376012, lng: 20.0915768 },
    { lat: 48.3372889, lng: 20.0910525 },
    { lat: 48.3348017, lng: 20.092603 },
    { lat: 48.333361, lng: 20.1014171 },
    { lat: 48.3334827, lng: 20.1014207 },
    { lat: 48.3301644, lng: 20.1219998 },
    { lat: 48.3327705, lng: 20.1251762 },
    { lat: 48.3324297, lng: 20.1270802 },
    { lat: 48.332484, lng: 20.1289268 },
    { lat: 48.3325146, lng: 20.1297541 },
    { lat: 48.332963, lng: 20.1300873 },
    { lat: 48.3332419, lng: 20.1297448 },
    { lat: 48.3335774, lng: 20.1298802 },
    { lat: 48.3339301, lng: 20.1293075 },
    { lat: 48.3343803, lng: 20.1287513 },
    { lat: 48.3371032, lng: 20.1257131 },
    { lat: 48.3374063, lng: 20.1260193 },
    { lat: 48.3394577, lng: 20.1239902 },
    { lat: 48.3417352, lng: 20.1215463 },
    { lat: 48.3431224, lng: 20.1202204 },
    { lat: 48.3437845, lng: 20.1194221 },
    { lat: 48.344398, lng: 20.1183432 },
    { lat: 48.3447794, lng: 20.1179751 },
    { lat: 48.3451398, lng: 20.1177265 },
    { lat: 48.3453484, lng: 20.1174282 },
    { lat: 48.3456047, lng: 20.1167612 },
    { lat: 48.3460581, lng: 20.1163914 },
    { lat: 48.3465653, lng: 20.1157939 },
    { lat: 48.3473086, lng: 20.1153817 },
    { lat: 48.3478519, lng: 20.1149508 },
    { lat: 48.3487343, lng: 20.1144407 },
    { lat: 48.3492686, lng: 20.1138481 },
    { lat: 48.3502653, lng: 20.1132453 },
    { lat: 48.3504213, lng: 20.112847 },
    { lat: 48.3505286, lng: 20.1112113 },
    { lat: 48.3505966, lng: 20.111085 },
    { lat: 48.3512366, lng: 20.1107612 },
    { lat: 48.3520636, lng: 20.110083 },
    { lat: 48.3523818, lng: 20.1099565 },
    { lat: 48.3531521, lng: 20.1094078 },
    { lat: 48.3537449, lng: 20.1093072 },
    { lat: 48.35431, lng: 20.1079853 },
    { lat: 48.3547075, lng: 20.1081981 },
    { lat: 48.3550779, lng: 20.1074028 },
    { lat: 48.3556298, lng: 20.1059521 },
    { lat: 48.3553053, lng: 20.1040064 },
    { lat: 48.3553225, lng: 20.103771 },
    { lat: 48.3558105, lng: 20.1022433 },
    { lat: 48.3560022, lng: 20.1016996 },
  ],
  Hubovo: [
    { lat: 48.4198735, lng: 20.4178355 },
    { lat: 48.4200456, lng: 20.4180413 },
    { lat: 48.419865, lng: 20.417477 },
    { lat: 48.419563, lng: 20.416941 },
    { lat: 48.41944, lng: 20.416372 },
    { lat: 48.419339, lng: 20.41569 },
    { lat: 48.419329, lng: 20.415581 },
    { lat: 48.419527, lng: 20.414713 },
    { lat: 48.419608, lng: 20.41416 },
    { lat: 48.419591, lng: 20.413324 },
    { lat: 48.419525, lng: 20.412284 },
    { lat: 48.419612, lng: 20.41147 },
    { lat: 48.419671, lng: 20.410976 },
    { lat: 48.419647, lng: 20.410493 },
    { lat: 48.419458, lng: 20.409574 },
    { lat: 48.419373, lng: 20.408674 },
    { lat: 48.419326, lng: 20.40821 },
    { lat: 48.419332, lng: 20.407643 },
    { lat: 48.419387, lng: 20.407346 },
    { lat: 48.419821, lng: 20.406656 },
    { lat: 48.420439, lng: 20.405946 },
    { lat: 48.420916, lng: 20.405349 },
    { lat: 48.42151, lng: 20.404997 },
    { lat: 48.422144, lng: 20.404715 },
    { lat: 48.422744, lng: 20.404294 },
    { lat: 48.42334, lng: 20.403919 },
    { lat: 48.423832, lng: 20.403298 },
    { lat: 48.423864, lng: 20.403179 },
    { lat: 48.423932, lng: 20.402932 },
    { lat: 48.424086, lng: 20.402478 },
    { lat: 48.424376, lng: 20.401913 },
    { lat: 48.424514, lng: 20.401029 },
    { lat: 48.424677, lng: 20.400078 },
    { lat: 48.424875, lng: 20.398885 },
    { lat: 48.424926, lng: 20.397988 },
    { lat: 48.424964, lng: 20.397134 },
    { lat: 48.424761, lng: 20.395811 },
    { lat: 48.424689, lng: 20.39511 },
    { lat: 48.424538, lng: 20.394488 },
    { lat: 48.424251, lng: 20.39283 },
    { lat: 48.424118, lng: 20.392322 },
    { lat: 48.424038, lng: 20.391728 },
    { lat: 48.423718, lng: 20.390351 },
    { lat: 48.423394, lng: 20.388414 },
    { lat: 48.423164, lng: 20.386695 },
    { lat: 48.422732, lng: 20.385203 },
    { lat: 48.4214, lng: 20.383728 },
    { lat: 48.421143, lng: 20.383064 },
    { lat: 48.420556, lng: 20.382432 },
    { lat: 48.420143, lng: 20.382048 },
    { lat: 48.419502, lng: 20.381069 },
    { lat: 48.41864, lng: 20.379956 },
    { lat: 48.418521, lng: 20.379123 },
    { lat: 48.418397, lng: 20.378197 },
    { lat: 48.41786, lng: 20.376825 },
    { lat: 48.417638, lng: 20.376065 },
    { lat: 48.41757, lng: 20.375585 },
    { lat: 48.417686, lng: 20.37521 },
    { lat: 48.418178, lng: 20.374671 },
    { lat: 48.418155, lng: 20.374225 },
    { lat: 48.41794, lng: 20.373368 },
    { lat: 48.417709, lng: 20.373536 },
    { lat: 48.417149, lng: 20.372037 },
    { lat: 48.416491, lng: 20.371212 },
    { lat: 48.415984, lng: 20.371171 },
    { lat: 48.415001, lng: 20.371376 },
    { lat: 48.414306, lng: 20.371436 },
    { lat: 48.413616, lng: 20.371453 },
    { lat: 48.412389, lng: 20.371807 },
    { lat: 48.412117, lng: 20.372001 },
    { lat: 48.411532, lng: 20.372311 },
    { lat: 48.410728, lng: 20.372612 },
    { lat: 48.410317, lng: 20.372359 },
    { lat: 48.409951, lng: 20.372009 },
    { lat: 48.409648, lng: 20.371691 },
    { lat: 48.409001, lng: 20.370972 },
    { lat: 48.408405, lng: 20.370483 },
    { lat: 48.407962, lng: 20.370033 },
    { lat: 48.407101, lng: 20.369115 },
    { lat: 48.406823, lng: 20.368896 },
    { lat: 48.406276, lng: 20.368734 },
    { lat: 48.406124, lng: 20.368678 },
    { lat: 48.405366, lng: 20.368151 },
    { lat: 48.404973, lng: 20.367656 },
    { lat: 48.405143, lng: 20.367359 },
    { lat: 48.405315, lng: 20.366991 },
    { lat: 48.404855, lng: 20.366186 },
    { lat: 48.404825, lng: 20.366133 },
    { lat: 48.404073, lng: 20.364817 },
    { lat: 48.404048, lng: 20.364773 },
    { lat: 48.403648, lng: 20.364074 },
    { lat: 48.402665, lng: 20.362128 },
    { lat: 48.4003983, lng: 20.3634738 },
    { lat: 48.3973074, lng: 20.3622714 },
    { lat: 48.3933635, lng: 20.3624462 },
    { lat: 48.3929713, lng: 20.3636832 },
    { lat: 48.3926237, lng: 20.3658092 },
    { lat: 48.3926493, lng: 20.3662063 },
    { lat: 48.39251, lng: 20.3661568 },
    { lat: 48.3926519, lng: 20.3681549 },
    { lat: 48.3925805, lng: 20.368608 },
    { lat: 48.3924431, lng: 20.3688666 },
    { lat: 48.3922346, lng: 20.3691255 },
    { lat: 48.3918185, lng: 20.3693136 },
    { lat: 48.3915083, lng: 20.3693581 },
    { lat: 48.3898299, lng: 20.3692897 },
    { lat: 48.3894995, lng: 20.3693944 },
    { lat: 48.3892974, lng: 20.3695563 },
    { lat: 48.3892119, lng: 20.3697303 },
    { lat: 48.3884497, lng: 20.3687827 },
    { lat: 48.3879913, lng: 20.3691559 },
    { lat: 48.3867241, lng: 20.3696438 },
    { lat: 48.3863598, lng: 20.3698585 },
    { lat: 48.385269, lng: 20.370568 },
    { lat: 48.3852234, lng: 20.3707006 },
    { lat: 48.3830341, lng: 20.3703796 },
    { lat: 48.3827218, lng: 20.3714464 },
    { lat: 48.3826406, lng: 20.3719615 },
    { lat: 48.3817772, lng: 20.3723236 },
    { lat: 48.3804151, lng: 20.3726267 },
    { lat: 48.3793932, lng: 20.3729598 },
    { lat: 48.3784041, lng: 20.3731894 },
    { lat: 48.3779547, lng: 20.3753045 },
    { lat: 48.3779152, lng: 20.376116 },
    { lat: 48.3777021, lng: 20.3765735 },
    { lat: 48.3773942, lng: 20.3769695 },
    { lat: 48.3776972, lng: 20.3777542 },
    { lat: 48.3780616, lng: 20.3784368 },
    { lat: 48.3782596, lng: 20.3793929 },
    { lat: 48.3785087, lng: 20.3800945 },
    { lat: 48.3785537, lng: 20.3816354 },
    { lat: 48.3788283, lng: 20.3829058 },
    { lat: 48.379444, lng: 20.3851656 },
    { lat: 48.3801926, lng: 20.3867266 },
    { lat: 48.3804695, lng: 20.3870453 },
    { lat: 48.3817476, lng: 20.3877727 },
    { lat: 48.3833388, lng: 20.3881718 },
    { lat: 48.3844477, lng: 20.3882221 },
    { lat: 48.3846956, lng: 20.3883723 },
    { lat: 48.3854161, lng: 20.3892129 },
    { lat: 48.3855433, lng: 20.3896156 },
    { lat: 48.386199, lng: 20.3906447 },
    { lat: 48.3870908, lng: 20.3922998 },
    { lat: 48.3871406, lng: 20.3926292 },
    { lat: 48.3861933, lng: 20.3941111 },
    { lat: 48.3861, lng: 20.3943474 },
    { lat: 48.3857714, lng: 20.3971259 },
    { lat: 48.3849681, lng: 20.3995949 },
    { lat: 48.3845406, lng: 20.4003371 },
    { lat: 48.3841891, lng: 20.4012636 },
    { lat: 48.3839048, lng: 20.4026537 },
    { lat: 48.3837702, lng: 20.4040976 },
    { lat: 48.3838947, lng: 20.4053735 },
    { lat: 48.3840455, lng: 20.4061836 },
    { lat: 48.3842665, lng: 20.4066488 },
    { lat: 48.3844101, lng: 20.4069488 },
    { lat: 48.3844844, lng: 20.4070934 },
    { lat: 48.3846807, lng: 20.4075019 },
    { lat: 48.3850302, lng: 20.4077781 },
    { lat: 48.3926967, lng: 20.41102 },
    { lat: 48.393117, lng: 20.4114913 },
    { lat: 48.3940176, lng: 20.4100469 },
    { lat: 48.3945409, lng: 20.4096578 },
    { lat: 48.3948311, lng: 20.4096815 },
    { lat: 48.3950189, lng: 20.4090841 },
    { lat: 48.3948515, lng: 20.4080157 },
    { lat: 48.3949064, lng: 20.4077434 },
    { lat: 48.3948411, lng: 20.4076598 },
    { lat: 48.3949769, lng: 20.4069471 },
    { lat: 48.3950765, lng: 20.4068439 },
    { lat: 48.3957804, lng: 20.4083054 },
    { lat: 48.3958513, lng: 20.4088006 },
    { lat: 48.3959598, lng: 20.4086934 },
    { lat: 48.3965745, lng: 20.4091847 },
    { lat: 48.3966617, lng: 20.4093461 },
    { lat: 48.3973301, lng: 20.4090666 },
    { lat: 48.396914, lng: 20.4079734 },
    { lat: 48.396356, lng: 20.4068738 },
    { lat: 48.3961, lng: 20.405963 },
    { lat: 48.3961528, lng: 20.4048454 },
    { lat: 48.396448, lng: 20.4040303 },
    { lat: 48.3970003, lng: 20.4030493 },
    { lat: 48.3985621, lng: 20.4052102 },
    { lat: 48.4000991, lng: 20.4038027 },
    { lat: 48.3979882, lng: 20.4008103 },
    { lat: 48.3982359, lng: 20.399871 },
    { lat: 48.3995883, lng: 20.4007148 },
    { lat: 48.3999378, lng: 20.3994997 },
    { lat: 48.3993171, lng: 20.3987943 },
    { lat: 48.3995039, lng: 20.3979162 },
    { lat: 48.4015913, lng: 20.4000915 },
    { lat: 48.4015071, lng: 20.4004231 },
    { lat: 48.4015543, lng: 20.4007957 },
    { lat: 48.4021098, lng: 20.4013042 },
    { lat: 48.4028556, lng: 20.401717 },
    { lat: 48.4040784, lng: 20.4022713 },
    { lat: 48.4043368, lng: 20.4021376 },
    { lat: 48.4045997, lng: 20.4035213 },
    { lat: 48.4048584, lng: 20.4037179 },
    { lat: 48.4048582, lng: 20.4031002 },
    { lat: 48.4049476, lng: 20.4027015 },
    { lat: 48.4049484, lng: 20.4007957 },
    { lat: 48.4050177, lng: 20.4004016 },
    { lat: 48.404893, lng: 20.3991561 },
    { lat: 48.4050294, lng: 20.3987476 },
    { lat: 48.405336, lng: 20.398498 },
    { lat: 48.4060451, lng: 20.3969676 },
    { lat: 48.4063125, lng: 20.3958428 },
    { lat: 48.4066773, lng: 20.3947612 },
    { lat: 48.4067711, lng: 20.3942452 },
    { lat: 48.4069825, lng: 20.3937731 },
    { lat: 48.4078512, lng: 20.3927599 },
    { lat: 48.4084026, lng: 20.3922564 },
    { lat: 48.4093825, lng: 20.3908908 },
    { lat: 48.4089844, lng: 20.3902322 },
    { lat: 48.4105565, lng: 20.3914881 },
    { lat: 48.4109608, lng: 20.3916925 },
    { lat: 48.412314, lng: 20.3927269 },
    { lat: 48.4129114, lng: 20.3929747 },
    { lat: 48.4149347, lng: 20.3934315 },
    { lat: 48.4155133, lng: 20.3936572 },
    { lat: 48.4161025, lng: 20.3941638 },
    { lat: 48.4166189, lng: 20.3944396 },
    { lat: 48.4169112, lng: 20.3946878 },
    { lat: 48.417106, lng: 20.3951544 },
    { lat: 48.4172893, lng: 20.3961385 },
    { lat: 48.4174863, lng: 20.3982958 },
    { lat: 48.4176403, lng: 20.3988195 },
    { lat: 48.4180688, lng: 20.3997071 },
    { lat: 48.4187547, lng: 20.4009328 },
    { lat: 48.4191442, lng: 20.4021613 },
    { lat: 48.4187781, lng: 20.4033283 },
    { lat: 48.4184611, lng: 20.4047409 },
    { lat: 48.4184111, lng: 20.4062551 },
    { lat: 48.4182609, lng: 20.4079375 },
    { lat: 48.418323, lng: 20.408528 },
    { lat: 48.4185199, lng: 20.4090203 },
    { lat: 48.4188775, lng: 20.4103236 },
    { lat: 48.4188011, lng: 20.4108822 },
    { lat: 48.4189398, lng: 20.4116483 },
    { lat: 48.4189147, lng: 20.4123446 },
    { lat: 48.4188079, lng: 20.4129069 },
    { lat: 48.4189434, lng: 20.4139395 },
    { lat: 48.4190177, lng: 20.4152043 },
    { lat: 48.4192566, lng: 20.4156721 },
    { lat: 48.4195273, lng: 20.4169745 },
    { lat: 48.4198239, lng: 20.417516 },
    { lat: 48.4198487, lng: 20.4176438 },
    { lat: 48.4198735, lng: 20.4178355 },
  ],
  VeľkéTeriakovce: [
    { lat: 48.432718, lng: 19.925361 },
    { lat: 48.4319058, lng: 19.9264119 },
    { lat: 48.4316489, lng: 19.9266754 },
    { lat: 48.4311746, lng: 19.927388 },
    { lat: 48.430154, lng: 19.9286792 },
    { lat: 48.4290526, lng: 19.9299041 },
    { lat: 48.428661, lng: 19.9305465 },
    { lat: 48.4283757, lng: 19.9318336 },
    { lat: 48.4280915, lng: 19.9326476 },
    { lat: 48.4279797, lng: 19.9331145 },
    { lat: 48.4269073, lng: 19.9361067 },
    { lat: 48.4266748, lng: 19.9363071 },
    { lat: 48.425961, lng: 19.937371 },
    { lat: 48.4252974, lng: 19.9380303 },
    { lat: 48.4249053, lng: 19.9381638 },
    { lat: 48.4237562, lng: 19.938874 },
    { lat: 48.4229264, lng: 19.9390442 },
    { lat: 48.4217674, lng: 19.9390244 },
    { lat: 48.4193747, lng: 19.9410371 },
    { lat: 48.4189207, lng: 19.9412186 },
    { lat: 48.4183119, lng: 19.9417399 },
    { lat: 48.4176819, lng: 19.9419398 },
    { lat: 48.4168937, lng: 19.941951 },
    { lat: 48.4167534, lng: 19.9418272 },
    { lat: 48.4167492, lng: 19.9415751 },
    { lat: 48.4168135, lng: 19.9415487 },
    { lat: 48.416795, lng: 19.9413519 },
    { lat: 48.4169154, lng: 19.9410854 },
    { lat: 48.4170098, lng: 19.9410806 },
    { lat: 48.4170674, lng: 19.941003 },
    { lat: 48.417127, lng: 19.9407604 },
    { lat: 48.4171867, lng: 19.9407107 },
    { lat: 48.4172061, lng: 19.9406344 },
    { lat: 48.417166, lng: 19.940596 },
    { lat: 48.4171994, lng: 19.9405045 },
    { lat: 48.4173363, lng: 19.9403336 },
    { lat: 48.417321, lng: 19.9402123 },
    { lat: 48.4122349, lng: 19.9382355 },
    { lat: 48.4118057, lng: 19.9320202 },
    { lat: 48.4116459, lng: 19.9325036 },
    { lat: 48.4115105, lng: 19.9324716 },
    { lat: 48.4113879, lng: 19.9328199 },
    { lat: 48.4114069, lng: 19.9331325 },
    { lat: 48.4113348, lng: 19.9330526 },
    { lat: 48.411229, lng: 19.933133 },
    { lat: 48.4112089, lng: 19.9333484 },
    { lat: 48.4110444, lng: 19.9335596 },
    { lat: 48.4108197, lng: 19.9336819 },
    { lat: 48.4107177, lng: 19.9335885 },
    { lat: 48.4105634, lng: 19.9336942 },
    { lat: 48.4105804, lng: 19.9340102 },
    { lat: 48.4103513, lng: 19.9340291 },
    { lat: 48.4102515, lng: 19.9342252 },
    { lat: 48.4100564, lng: 19.9343266 },
    { lat: 48.409935, lng: 19.9343049 },
    { lat: 48.4099296, lng: 19.9344437 },
    { lat: 48.4098429, lng: 19.9344194 },
    { lat: 48.4097201, lng: 19.9346007 },
    { lat: 48.4093592, lng: 19.9354924 },
    { lat: 48.4092661, lng: 19.9355517 },
    { lat: 48.4093192, lng: 19.9355853 },
    { lat: 48.4093188, lng: 19.9357587 },
    { lat: 48.4092258, lng: 19.9357964 },
    { lat: 48.4092093, lng: 19.9360185 },
    { lat: 48.4090777, lng: 19.9360999 },
    { lat: 48.4091082, lng: 19.9363179 },
    { lat: 48.4090396, lng: 19.9364737 },
    { lat: 48.4089202, lng: 19.9363427 },
    { lat: 48.408693, lng: 19.9365677 },
    { lat: 48.4086472, lng: 19.9367827 },
    { lat: 48.4085681, lng: 19.9369091 },
    { lat: 48.4085085, lng: 19.936862 },
    { lat: 48.4084047, lng: 19.9371471 },
    { lat: 48.40834, lng: 19.9371079 },
    { lat: 48.4082973, lng: 19.9371645 },
    { lat: 48.4083691, lng: 19.9377416 },
    { lat: 48.4082869, lng: 19.9378711 },
    { lat: 48.4084229, lng: 19.9380225 },
    { lat: 48.4084185, lng: 19.9381584 },
    { lat: 48.4082864, lng: 19.938231 },
    { lat: 48.4081435, lng: 19.9384724 },
    { lat: 48.4082068, lng: 19.9387957 },
    { lat: 48.4081485, lng: 19.9388782 },
    { lat: 48.4081586, lng: 19.9390669 },
    { lat: 48.4080622, lng: 19.9395786 },
    { lat: 48.4081432, lng: 19.9396588 },
    { lat: 48.4081403, lng: 19.9397455 },
    { lat: 48.4079466, lng: 19.9397486 },
    { lat: 48.4079414, lng: 19.9398632 },
    { lat: 48.4080225, lng: 19.9398888 },
    { lat: 48.4080568, lng: 19.9401134 },
    { lat: 48.4079789, lng: 19.9401631 },
    { lat: 48.4079163, lng: 19.9400394 },
    { lat: 48.4076466, lng: 19.9412732 },
    { lat: 48.4075822, lng: 19.9411642 },
    { lat: 48.4076327, lng: 19.9409736 },
    { lat: 48.4074944, lng: 19.9411138 },
    { lat: 48.4074429, lng: 19.9413563 },
    { lat: 48.407573, lng: 19.9416611 },
    { lat: 48.4073723, lng: 19.9416825 },
    { lat: 48.4073108, lng: 19.9418022 },
    { lat: 48.4073732, lng: 19.9419147 },
    { lat: 48.4072733, lng: 19.942137 },
    { lat: 48.407142, lng: 19.9422279 },
    { lat: 48.4072509, lng: 19.9422969 },
    { lat: 48.4072818, lng: 19.9424974 },
    { lat: 48.4073896, lng: 19.9424336 },
    { lat: 48.4075038, lng: 19.9426045 },
    { lat: 48.4073804, lng: 19.9430648 },
    { lat: 48.4071223, lng: 19.943247 },
    { lat: 48.407159, lng: 19.9435275 },
    { lat: 48.4070667, lng: 19.9436094 },
    { lat: 48.4071939, lng: 19.9438209 },
    { lat: 48.4072926, lng: 19.943754 },
    { lat: 48.4072796, lng: 19.9439254 },
    { lat: 48.4071209, lng: 19.9440343 },
    { lat: 48.4071344, lng: 19.9445134 },
    { lat: 48.4073303, lng: 19.9445081 },
    { lat: 48.4073201, lng: 19.9445899 },
    { lat: 48.4071448, lng: 19.9445957 },
    { lat: 48.4070292, lng: 19.944504 },
    { lat: 48.4070136, lng: 19.9448263 },
    { lat: 48.4071301, lng: 19.94494 },
    { lat: 48.4070319, lng: 19.9454117 },
    { lat: 48.4068324, lng: 19.9453543 },
    { lat: 48.4068103, lng: 19.945896 },
    { lat: 48.4069183, lng: 19.9464242 },
    { lat: 48.4066894, lng: 19.9463115 },
    { lat: 48.4067019, lng: 19.9466038 },
    { lat: 48.4069112, lng: 19.9466809 },
    { lat: 48.4069096, lng: 19.9472809 },
    { lat: 48.4067499, lng: 19.9472069 },
    { lat: 48.4066847, lng: 19.9477088 },
    { lat: 48.4067013, lng: 19.9478884 },
    { lat: 48.406914, lng: 19.9479116 },
    { lat: 48.4067712, lng: 19.9481288 },
    { lat: 48.4066262, lng: 19.9480821 },
    { lat: 48.4065677, lng: 19.9485114 },
    { lat: 48.4065012, lng: 19.9484859 },
    { lat: 48.4064858, lng: 19.949395 },
    { lat: 48.4066978, lng: 19.9494232 },
    { lat: 48.4067475, lng: 19.9496358 },
    { lat: 48.4066403, lng: 19.9496868 },
    { lat: 48.4068117, lng: 19.9500643 },
    { lat: 48.4070295, lng: 19.9501785 },
    { lat: 48.4068695, lng: 19.9503069 },
    { lat: 48.4070927, lng: 19.9504433 },
    { lat: 48.4070837, lng: 19.950659 },
    { lat: 48.4067267, lng: 19.9508751 },
    { lat: 48.4067121, lng: 19.9510602 },
    { lat: 48.4066207, lng: 19.9510583 },
    { lat: 48.4067471, lng: 19.9518556 },
    { lat: 48.4065321, lng: 19.9520719 },
    { lat: 48.406425, lng: 19.9527076 },
    { lat: 48.4065172, lng: 19.9530225 },
    { lat: 48.4066112, lng: 19.9530854 },
    { lat: 48.4065366, lng: 19.9532397 },
    { lat: 48.40684, lng: 19.9531972 },
    { lat: 48.4071354, lng: 19.9538663 },
    { lat: 48.4072284, lng: 19.9538003 },
    { lat: 48.4072457, lng: 19.954284 },
    { lat: 48.4071079, lng: 19.9542522 },
    { lat: 48.4071718, lng: 19.9547453 },
    { lat: 48.4070132, lng: 19.9550749 },
    { lat: 48.4069724, lng: 19.9553859 },
    { lat: 48.4068603, lng: 19.955398 },
    { lat: 48.4069327, lng: 19.9556198 },
    { lat: 48.4067797, lng: 19.9556249 },
    { lat: 48.406737, lng: 19.955675 },
    { lat: 48.4067946, lng: 19.9557589 },
    { lat: 48.4066112, lng: 19.9559267 },
    { lat: 48.4066876, lng: 19.9561566 },
    { lat: 48.4066869, lng: 19.9563021 },
    { lat: 48.4067562, lng: 19.9564227 },
    { lat: 48.4066046, lng: 19.9565932 },
    { lat: 48.4067313, lng: 19.9566853 },
    { lat: 48.4068363, lng: 19.9569011 },
    { lat: 48.4066677, lng: 19.9571513 },
    { lat: 48.4065012, lng: 19.9571548 },
    { lat: 48.4064728, lng: 19.9569916 },
    { lat: 48.4065461, lng: 19.9569402 },
    { lat: 48.4065312, lng: 19.9568555 },
    { lat: 48.4064339, lng: 19.9568159 },
    { lat: 48.4063783, lng: 19.9569953 },
    { lat: 48.4062245, lng: 19.9569827 },
    { lat: 48.4062665, lng: 19.9575431 },
    { lat: 48.4062147, lng: 19.9578633 },
    { lat: 48.4061229, lng: 19.9578526 },
    { lat: 48.4060796, lng: 19.9584523 },
    { lat: 48.4059089, lng: 19.9581719 },
    { lat: 48.4058679, lng: 19.9581844 },
    { lat: 48.4058757, lng: 19.9583697 },
    { lat: 48.4057899, lng: 19.958523 },
    { lat: 48.4057991, lng: 19.9587167 },
    { lat: 48.4058564, lng: 19.9587105 },
    { lat: 48.4059412, lng: 19.9590109 },
    { lat: 48.4058845, lng: 19.9590046 },
    { lat: 48.4058067, lng: 19.9591574 },
    { lat: 48.4058822, lng: 19.9592686 },
    { lat: 48.4058254, lng: 19.9594704 },
    { lat: 48.4059712, lng: 19.9595568 },
    { lat: 48.4058294, lng: 19.9600157 },
    { lat: 48.4057105, lng: 19.9601554 },
    { lat: 48.4056634, lng: 19.9604545 },
    { lat: 48.4055529, lng: 19.9603754 },
    { lat: 48.4055049, lng: 19.9604122 },
    { lat: 48.4056224, lng: 19.960791 },
    { lat: 48.4057161, lng: 19.9607459 },
    { lat: 48.4057187, lng: 19.9608282 },
    { lat: 48.40582, lng: 19.9609345 },
    { lat: 48.4057453, lng: 19.9612157 },
    { lat: 48.4056518, lng: 19.9609771 },
    { lat: 48.4055952, lng: 19.9609726 },
    { lat: 48.4056166, lng: 19.9613712 },
    { lat: 48.4057479, lng: 19.9614125 },
    { lat: 48.4057427, lng: 19.9616336 },
    { lat: 48.4056889, lng: 19.9617734 },
    { lat: 48.4055997, lng: 19.9618468 },
    { lat: 48.4055778, lng: 19.9620273 },
    { lat: 48.4054861, lng: 19.9621463 },
    { lat: 48.4055614, lng: 19.9623791 },
    { lat: 48.4056327, lng: 19.9624134 },
    { lat: 48.405482, lng: 19.9625569 },
    { lat: 48.4054032, lng: 19.9627683 },
    { lat: 48.4051208, lng: 19.9628853 },
    { lat: 48.4051115, lng: 19.9628545 },
    { lat: 48.4050489, lng: 19.9626465 },
    { lat: 48.4049405, lng: 19.9627746 },
    { lat: 48.404916, lng: 19.9626472 },
    { lat: 48.4048179, lng: 19.9626495 },
    { lat: 48.4047486, lng: 19.9627412 },
    { lat: 48.4045601, lng: 19.9625709 },
    { lat: 48.4045148, lng: 19.9626171 },
    { lat: 48.4047292, lng: 19.9630522 },
    { lat: 48.4047896, lng: 19.963279 },
    { lat: 48.4045943, lng: 19.9633228 },
    { lat: 48.4046155, lng: 19.9635051 },
    { lat: 48.404519, lng: 19.9636221 },
    { lat: 48.404405, lng: 19.9635898 },
    { lat: 48.4043586, lng: 19.9638771 },
    { lat: 48.4044204, lng: 19.9641658 },
    { lat: 48.4044062, lng: 19.9643079 },
    { lat: 48.4045703, lng: 19.9644036 },
    { lat: 48.4044795, lng: 19.9647349 },
    { lat: 48.4044332, lng: 19.9645234 },
    { lat: 48.4043885, lng: 19.9645309 },
    { lat: 48.4043336, lng: 19.9643996 },
    { lat: 48.4042766, lng: 19.964599 },
    { lat: 48.4042975, lng: 19.9647473 },
    { lat: 48.404372, lng: 19.9648565 },
    { lat: 48.4042627, lng: 19.9649392 },
    { lat: 48.4044507, lng: 19.9651187 },
    { lat: 48.4044478, lng: 19.965425 },
    { lat: 48.4045426, lng: 19.965484 },
    { lat: 48.4045231, lng: 19.9657145 },
    { lat: 48.4044211, lng: 19.9656466 },
    { lat: 48.4044109, lng: 19.9655615 },
    { lat: 48.4043481, lng: 19.9657 },
    { lat: 48.4043949, lng: 19.9658746 },
    { lat: 48.404479, lng: 19.9659502 },
    { lat: 48.4045469, lng: 19.9658477 },
    { lat: 48.4045684, lng: 19.9659304 },
    { lat: 48.4043982, lng: 19.9662497 },
    { lat: 48.4045218, lng: 19.9663461 },
    { lat: 48.4046109, lng: 19.9661369 },
    { lat: 48.4046374, lng: 19.9662063 },
    { lat: 48.4046052, lng: 19.9663764 },
    { lat: 48.4047443, lng: 19.966546 },
    { lat: 48.4045529, lng: 19.9666579 },
    { lat: 48.4045713, lng: 19.9667955 },
    { lat: 48.404694, lng: 19.9668727 },
    { lat: 48.4045071, lng: 19.9671427 },
    { lat: 48.4046016, lng: 19.9672442 },
    { lat: 48.4047067, lng: 19.9672012 },
    { lat: 48.4047788, lng: 19.9674439 },
    { lat: 48.4047365, lng: 19.9675316 },
    { lat: 48.4046471, lng: 19.9674707 },
    { lat: 48.4046154, lng: 19.9675478 },
    { lat: 48.4046925, lng: 19.9677401 },
    { lat: 48.4048045, lng: 19.9676485 },
    { lat: 48.4048467, lng: 19.9677413 },
    { lat: 48.4048103, lng: 19.9681603 },
    { lat: 48.4050058, lng: 19.9680668 },
    { lat: 48.4050315, lng: 19.9681654 },
    { lat: 48.405002, lng: 19.968321 },
    { lat: 48.4051233, lng: 19.9683931 },
    { lat: 48.4050907, lng: 19.9685043 },
    { lat: 48.4051978, lng: 19.9685806 },
    { lat: 48.4051611, lng: 19.9687014 },
    { lat: 48.4054278, lng: 19.969011 },
    { lat: 48.4054314, lng: 19.9692018 },
    { lat: 48.4055504, lng: 19.9693038 },
    { lat: 48.4056683, lng: 19.9692944 },
    { lat: 48.4057522, lng: 19.9693867 },
    { lat: 48.405818, lng: 19.9695804 },
    { lat: 48.405565, lng: 19.9699392 },
    { lat: 48.4056596, lng: 19.9701496 },
    { lat: 48.4057898, lng: 19.9700858 },
    { lat: 48.4058132, lng: 19.9701587 },
    { lat: 48.4058121, lng: 19.9703499 },
    { lat: 48.4056427, lng: 19.9703647 },
    { lat: 48.4056089, lng: 19.9707086 },
    { lat: 48.4056505, lng: 19.9707599 },
    { lat: 48.4058392, lng: 19.9706441 },
    { lat: 48.4058579, lng: 19.9709805 },
    { lat: 48.4059802, lng: 19.9709146 },
    { lat: 48.4060014, lng: 19.9708027 },
    { lat: 48.4060584, lng: 19.9707926 },
    { lat: 48.4061364, lng: 19.9710355 },
    { lat: 48.4060861, lng: 19.9712572 },
    { lat: 48.4059711, lng: 19.9713363 },
    { lat: 48.4059802, lng: 19.9713932 },
    { lat: 48.4061817, lng: 19.9714428 },
    { lat: 48.4060929, lng: 19.9715817 },
    { lat: 48.4060256, lng: 19.9715356 },
    { lat: 48.405981, lng: 19.9716746 },
    { lat: 48.4060649, lng: 19.9718219 },
    { lat: 48.4060666, lng: 19.9719416 },
    { lat: 48.4061406, lng: 19.97196 },
    { lat: 48.4062184, lng: 19.9717509 },
    { lat: 48.4063017, lng: 19.9717234 },
    { lat: 48.4063417, lng: 19.9717373 },
    { lat: 48.4063326, lng: 19.9718389 },
    { lat: 48.4062811, lng: 19.9719275 },
    { lat: 48.4063487, lng: 19.972171 },
    { lat: 48.4064684, lng: 19.9719873 },
    { lat: 48.4065988, lng: 19.97214 },
    { lat: 48.4067186, lng: 19.9721258 },
    { lat: 48.4067915, lng: 19.9724092 },
    { lat: 48.4067566, lng: 19.9724884 },
    { lat: 48.4068005, lng: 19.9725725 },
    { lat: 48.406717, lng: 19.972702 },
    { lat: 48.4069376, lng: 19.9729621 },
    { lat: 48.4069037, lng: 19.9730356 },
    { lat: 48.4066597, lng: 19.9731262 },
    { lat: 48.4066436, lng: 19.9733054 },
    { lat: 48.4066782, lng: 19.9733237 },
    { lat: 48.4067296, lng: 19.9732124 },
    { lat: 48.4067871, lng: 19.9732087 },
    { lat: 48.4068278, lng: 19.973296 },
    { lat: 48.4069985, lng: 19.9732566 },
    { lat: 48.4070224, lng: 19.9734203 },
    { lat: 48.4070808, lng: 19.9734455 },
    { lat: 48.4070988, lng: 19.9735456 },
    { lat: 48.4070259, lng: 19.9736656 },
    { lat: 48.4070891, lng: 19.9738259 },
    { lat: 48.4069894, lng: 19.9739453 },
    { lat: 48.4069834, lng: 19.9740451 },
    { lat: 48.4071524, lng: 19.9740989 },
    { lat: 48.4072117, lng: 19.974196 },
    { lat: 48.4071646, lng: 19.9746285 },
    { lat: 48.4072352, lng: 19.9747515 },
    { lat: 48.4071378, lng: 19.974898 },
    { lat: 48.4071787, lng: 19.9749841 },
    { lat: 48.4071455, lng: 19.9752142 },
    { lat: 48.4073194, lng: 19.9754649 },
    { lat: 48.4075009, lng: 19.9761053 },
    { lat: 48.4075731, lng: 19.9762176 },
    { lat: 48.4077218, lng: 19.9762938 },
    { lat: 48.4080067, lng: 19.9766396 },
    { lat: 48.4081461, lng: 19.9767118 },
    { lat: 48.408545, lng: 19.9772243 },
    { lat: 48.4086491, lng: 19.9772343 },
    { lat: 48.4088377, lng: 19.9774017 },
    { lat: 48.4088732, lng: 19.9774434 },
    { lat: 48.4088663, lng: 19.9776543 },
    { lat: 48.40659, lng: 19.9793965 },
    { lat: 48.4069672, lng: 19.9799494 },
    { lat: 48.4059701, lng: 19.9816831 },
    { lat: 48.4056564, lng: 19.9825496 },
    { lat: 48.4052325, lng: 19.9835306 },
    { lat: 48.4050064, lng: 19.9847614 },
    { lat: 48.4052844, lng: 19.9850224 },
    { lat: 48.4057016, lng: 19.9857297 },
    { lat: 48.4057617, lng: 19.9859793 },
    { lat: 48.4060468, lng: 19.9863538 },
    { lat: 48.4065862, lng: 19.9857377 },
    { lat: 48.4076414, lng: 19.9851962 },
    { lat: 48.4080191, lng: 19.9848557 },
    { lat: 48.4082493, lng: 19.9844371 },
    { lat: 48.4085983, lng: 19.9843007 },
    { lat: 48.4089792, lng: 19.984035 },
    { lat: 48.4089472, lng: 19.9838931 },
    { lat: 48.4125002, lng: 19.9806341 },
    { lat: 48.4124316, lng: 19.9784792 },
    { lat: 48.4182335, lng: 19.9773431 },
    { lat: 48.4187186, lng: 19.9778674 },
    { lat: 48.4203116, lng: 19.9800557 },
    { lat: 48.4203417, lng: 19.9801603 },
    { lat: 48.4208447, lng: 19.9806497 },
    { lat: 48.4213563, lng: 19.9808148 },
    { lat: 48.4213831, lng: 19.980947 },
    { lat: 48.4218076, lng: 19.9826975 },
    { lat: 48.4219661, lng: 19.9828806 },
    { lat: 48.4222088, lng: 19.9842002 },
    { lat: 48.4224214, lng: 19.9850715 },
    { lat: 48.4224567, lng: 19.9855384 },
    { lat: 48.4224364, lng: 19.9864677 },
    { lat: 48.4223542, lng: 19.986899 },
    { lat: 48.4222712, lng: 19.9870693 },
    { lat: 48.4222895, lng: 19.9872907 },
    { lat: 48.4224975, lng: 19.9878404 },
    { lat: 48.4226418, lng: 19.9888335 },
    { lat: 48.4228456, lng: 19.9892819 },
    { lat: 48.4229378, lng: 19.9893859 },
    { lat: 48.423134, lng: 19.9894442 },
    { lat: 48.4234693, lng: 19.9899738 },
    { lat: 48.4237938, lng: 19.9908565 },
    { lat: 48.4241393, lng: 19.9914095 },
    { lat: 48.4241767, lng: 19.9917581 },
    { lat: 48.4246435, lng: 19.9926022 },
    { lat: 48.4255479, lng: 19.9953535 },
    { lat: 48.4273342, lng: 19.9958027 },
    { lat: 48.4282704, lng: 19.9961238 },
    { lat: 48.428605, lng: 19.9963396 },
    { lat: 48.428799, lng: 19.996585 },
    { lat: 48.4291304, lng: 19.9968593 },
    { lat: 48.4293723, lng: 19.9968803 },
    { lat: 48.4297664, lng: 19.9967439 },
    { lat: 48.4314167, lng: 19.9972778 },
    { lat: 48.4317719, lng: 19.9975796 },
    { lat: 48.4327854, lng: 19.9982183 },
    { lat: 48.4329981, lng: 19.9986383 },
    { lat: 48.4333215, lng: 19.9991223 },
    { lat: 48.4343392, lng: 20.0000217 },
    { lat: 48.4355492, lng: 20.0002924 },
    { lat: 48.4365215, lng: 19.9995726 },
    { lat: 48.4372511, lng: 19.9994551 },
    { lat: 48.4384146, lng: 19.9989164 },
    { lat: 48.4401516, lng: 19.9985439 },
    { lat: 48.4407746, lng: 19.9980885 },
    { lat: 48.4413512, lng: 19.9980032 },
    { lat: 48.4417993, lng: 19.9978365 },
    { lat: 48.4429354, lng: 19.9958447 },
    { lat: 48.4431127, lng: 19.9956423 },
    { lat: 48.4442156, lng: 19.9955374 },
    { lat: 48.4453496, lng: 19.9960101 },
    { lat: 48.4456789, lng: 19.9960438 },
    { lat: 48.4464498, lng: 19.9962937 },
    { lat: 48.4474923, lng: 19.996465 },
    { lat: 48.4479812, lng: 19.9967071 },
    { lat: 48.4496681, lng: 19.9968565 },
    { lat: 48.4499723, lng: 19.9971005 },
    { lat: 48.4508187, lng: 19.9971602 },
    { lat: 48.4512346, lng: 19.997301 },
    { lat: 48.451496, lng: 19.9974649 },
    { lat: 48.4522782, lng: 19.997689 },
    { lat: 48.4525863, lng: 19.997543 },
    { lat: 48.4535265, lng: 19.9974547 },
    { lat: 48.4543369, lng: 19.9977851 },
    { lat: 48.4543425, lng: 19.9977559 },
    { lat: 48.4544709, lng: 19.997194 },
    { lat: 48.4546484, lng: 19.9967282 },
    { lat: 48.454801, lng: 19.995912 },
    { lat: 48.4540655, lng: 19.994543 },
    { lat: 48.4540115, lng: 19.9944654 },
    { lat: 48.4536003, lng: 19.9934729 },
    { lat: 48.4529211, lng: 19.9923264 },
    { lat: 48.4524023, lng: 19.9908658 },
    { lat: 48.4519116, lng: 19.9903398 },
    { lat: 48.4519185, lng: 19.9897011 },
    { lat: 48.4518504, lng: 19.9895593 },
    { lat: 48.4506172, lng: 19.9891362 },
    { lat: 48.4495591, lng: 19.9880592 },
    { lat: 48.4482193, lng: 19.986473 },
    { lat: 48.4473394, lng: 19.9859617 },
    { lat: 48.446261, lng: 19.9860601 },
    { lat: 48.4459624, lng: 19.9860038 },
    { lat: 48.4458452, lng: 19.9860565 },
    { lat: 48.4457539, lng: 19.9858168 },
    { lat: 48.4455558, lng: 19.9856873 },
    { lat: 48.4453913, lng: 19.9850945 },
    { lat: 48.4451885, lng: 19.9847193 },
    { lat: 48.4447039, lng: 19.9841531 },
    { lat: 48.4443529, lng: 19.9822965 },
    { lat: 48.4441878, lng: 19.9816672 },
    { lat: 48.4442617, lng: 19.9813877 },
    { lat: 48.4441445, lng: 19.9810926 },
    { lat: 48.444188, lng: 19.9809214 },
    { lat: 48.4441158, lng: 19.9808435 },
    { lat: 48.4441071, lng: 19.9806878 },
    { lat: 48.4441652, lng: 19.9805709 },
    { lat: 48.4441543, lng: 19.9803633 },
    { lat: 48.4440527, lng: 19.9801502 },
    { lat: 48.4441037, lng: 19.9799927 },
    { lat: 48.4439637, lng: 19.9799362 },
    { lat: 48.4439919, lng: 19.9797206 },
    { lat: 48.4438983, lng: 19.9796398 },
    { lat: 48.4438879, lng: 19.9793931 },
    { lat: 48.443806, lng: 19.9792647 },
    { lat: 48.4438354, lng: 19.9789213 },
    { lat: 48.4439003, lng: 19.9787256 },
    { lat: 48.4438067, lng: 19.9781626 },
    { lat: 48.4438347, lng: 19.978028 },
    { lat: 48.4437887, lng: 19.9778149 },
    { lat: 48.4437987, lng: 19.9773867 },
    { lat: 48.44391, lng: 19.97701 },
    { lat: 48.4439245, lng: 19.9761518 },
    { lat: 48.4439859, lng: 19.9760058 },
    { lat: 48.4439864, lng: 19.9752415 },
    { lat: 48.4434094, lng: 19.9721463 },
    { lat: 48.4434512, lng: 19.971883 },
    { lat: 48.4435685, lng: 19.9715649 },
    { lat: 48.4437751, lng: 19.971366 },
    { lat: 48.4443288, lng: 19.9718045 },
    { lat: 48.4446004, lng: 19.9716465 },
    { lat: 48.4447244, lng: 19.9714917 },
    { lat: 48.4446301, lng: 19.9710703 },
    { lat: 48.4446734, lng: 19.9708941 },
    { lat: 48.4451693, lng: 19.9702109 },
    { lat: 48.4453479, lng: 19.9698802 },
    { lat: 48.4453546, lng: 19.9697692 },
    { lat: 48.4452809, lng: 19.9696248 },
    { lat: 48.4450872, lng: 19.9696752 },
    { lat: 48.4450017, lng: 19.9695748 },
    { lat: 48.4450184, lng: 19.9694615 },
    { lat: 48.4451799, lng: 19.9691767 },
    { lat: 48.4450819, lng: 19.9690977 },
    { lat: 48.4446968, lng: 19.969214 },
    { lat: 48.444585, lng: 19.9691264 },
    { lat: 48.444662, lng: 19.9676445 },
    { lat: 48.4447986, lng: 19.9674633 },
    { lat: 48.4449152, lng: 19.9675852 },
    { lat: 48.4449981, lng: 19.9680772 },
    { lat: 48.4450991, lng: 19.9682839 },
    { lat: 48.445081, lng: 19.9684908 },
    { lat: 48.4451434, lng: 19.9685043 },
    { lat: 48.4458757, lng: 19.9677073 },
    { lat: 48.4457683, lng: 19.9675135 },
    { lat: 48.4455695, lng: 19.9676564 },
    { lat: 48.4454558, lng: 19.9672858 },
    { lat: 48.445499, lng: 19.9669794 },
    { lat: 48.445456, lng: 19.9666812 },
    { lat: 48.4456702, lng: 19.9663662 },
    { lat: 48.4460452, lng: 19.9673171 },
    { lat: 48.4461799, lng: 19.9671755 },
    { lat: 48.446023, lng: 19.9665812 },
    { lat: 48.4464109, lng: 19.9661155 },
    { lat: 48.4465147, lng: 19.9657728 },
    { lat: 48.4469791, lng: 19.9650833 },
    { lat: 48.4471964, lng: 19.96556 },
    { lat: 48.4476778, lng: 19.9651442 },
    { lat: 48.4476053, lng: 19.9649471 },
    { lat: 48.4474919, lng: 19.9650119 },
    { lat: 48.4471255, lng: 19.9644991 },
    { lat: 48.4474111, lng: 19.964168 },
    { lat: 48.4474061, lng: 19.9634369 },
    { lat: 48.4474474, lng: 19.9632431 },
    { lat: 48.447581, lng: 19.9629915 },
    { lat: 48.4478555, lng: 19.9634095 },
    { lat: 48.4480456, lng: 19.9634271 },
    { lat: 48.4480738, lng: 19.9636703 },
    { lat: 48.4477818, lng: 19.9641152 },
    { lat: 48.4478642, lng: 19.964306 },
    { lat: 48.4486006, lng: 19.9645095 },
    { lat: 48.4487659, lng: 19.964372 },
    { lat: 48.4490287, lng: 19.9638983 },
    { lat: 48.4489761, lng: 19.963694 },
    { lat: 48.4485075, lng: 19.963643 },
    { lat: 48.4484051, lng: 19.9635662 },
    { lat: 48.4482097, lng: 19.963339 },
    { lat: 48.4480916, lng: 19.9629749 },
    { lat: 48.4482068, lng: 19.9624448 },
    { lat: 48.4485093, lng: 19.9617686 },
    { lat: 48.4488493, lng: 19.9621111 },
    { lat: 48.44908, lng: 19.9620497 },
    { lat: 48.449557, lng: 19.9621704 },
    { lat: 48.4512273, lng: 19.9633352 },
    { lat: 48.4514093, lng: 19.9632958 },
    { lat: 48.4516145, lng: 19.9634039 },
    { lat: 48.4516926, lng: 19.963521 },
    { lat: 48.4516201, lng: 19.9636472 },
    { lat: 48.4516168, lng: 19.9637536 },
    { lat: 48.4517234, lng: 19.9639031 },
    { lat: 48.4519043, lng: 19.9645295 },
    { lat: 48.4518461, lng: 19.9646976 },
    { lat: 48.4518536, lng: 19.964848 },
    { lat: 48.4520408, lng: 19.9650938 },
    { lat: 48.4519119, lng: 19.9653201 },
    { lat: 48.45201, lng: 19.9654573 },
    { lat: 48.4519516, lng: 19.9657024 },
    { lat: 48.452464, lng: 19.9663628 },
    { lat: 48.4530262, lng: 19.9660432 },
    { lat: 48.4529638, lng: 19.9658235 },
    { lat: 48.4529207, lng: 19.9652584 },
    { lat: 48.4528925, lng: 19.9650709 },
    { lat: 48.4525711, lng: 19.9606484 },
    { lat: 48.4532512, lng: 19.9604804 },
    { lat: 48.4534302, lng: 19.9602883 },
    { lat: 48.4538488, lng: 19.9600953 },
    { lat: 48.4540704, lng: 19.9598984 },
    { lat: 48.4536133, lng: 19.9581515 },
    { lat: 48.4532383, lng: 19.9564784 },
    { lat: 48.4542934, lng: 19.955142 },
    { lat: 48.4543959, lng: 19.9549302 },
    { lat: 48.4543352, lng: 19.9549868 },
    { lat: 48.4542882, lng: 19.9549139 },
    { lat: 48.4542598, lng: 19.9543769 },
    { lat: 48.4541825, lng: 19.9543083 },
    { lat: 48.4541853, lng: 19.9541615 },
    { lat: 48.4543487, lng: 19.9538967 },
    { lat: 48.4544362, lng: 19.953439 },
    { lat: 48.4545218, lng: 19.9533041 },
    { lat: 48.4546355, lng: 19.9532737 },
    { lat: 48.4546069, lng: 19.953049 },
    { lat: 48.4547744, lng: 19.9525695 },
    { lat: 48.4546963, lng: 19.9521096 },
    { lat: 48.4549467, lng: 19.9519571 },
    { lat: 48.4550421, lng: 19.9518141 },
    { lat: 48.455001, lng: 19.951746 },
    { lat: 48.4551071, lng: 19.9510367 },
    { lat: 48.4550886, lng: 19.9509722 },
    { lat: 48.4549597, lng: 19.9509618 },
    { lat: 48.4549404, lng: 19.9508522 },
    { lat: 48.4550522, lng: 19.9505668 },
    { lat: 48.4550061, lng: 19.9503803 },
    { lat: 48.455128, lng: 19.950149 },
    { lat: 48.4549664, lng: 19.9498752 },
    { lat: 48.455142, lng: 19.9498949 },
    { lat: 48.455155, lng: 19.9498287 },
    { lat: 48.4551423, lng: 19.9495017 },
    { lat: 48.454996, lng: 19.9493479 },
    { lat: 48.4550915, lng: 19.9490232 },
    { lat: 48.4549234, lng: 19.9490724 },
    { lat: 48.4549133, lng: 19.9492021 },
    { lat: 48.4548257, lng: 19.9492134 },
    { lat: 48.4547858, lng: 19.9489344 },
    { lat: 48.4546797, lng: 19.94877 },
    { lat: 48.4546916, lng: 19.9486293 },
    { lat: 48.4548472, lng: 19.9485516 },
    { lat: 48.4547715, lng: 19.9483594 },
    { lat: 48.4546383, lng: 19.9482736 },
    { lat: 48.4546672, lng: 19.9481824 },
    { lat: 48.4547497, lng: 19.9481411 },
    { lat: 48.4547903, lng: 19.9480066 },
    { lat: 48.4546205, lng: 19.9477997 },
    { lat: 48.4545637, lng: 19.9474968 },
    { lat: 48.4544502, lng: 19.9472419 },
    { lat: 48.4544707, lng: 19.9471139 },
    { lat: 48.4543877, lng: 19.9468316 },
    { lat: 48.4544932, lng: 19.9465319 },
    { lat: 48.4542419, lng: 19.9459676 },
    { lat: 48.4542912, lng: 19.9457787 },
    { lat: 48.4542189, lng: 19.9454027 },
    { lat: 48.4543925, lng: 19.9451128 },
    { lat: 48.454406, lng: 19.9449298 },
    { lat: 48.4545479, lng: 19.9448477 },
    { lat: 48.4545647, lng: 19.9447652 },
    { lat: 48.4544902, lng: 19.9446566 },
    { lat: 48.454391, lng: 19.9447311 },
    { lat: 48.4542874, lng: 19.9445988 },
    { lat: 48.4542942, lng: 19.9443103 },
    { lat: 48.4541727, lng: 19.9439724 },
    { lat: 48.4541916, lng: 19.9437797 },
    { lat: 48.4540748, lng: 19.9436301 },
    { lat: 48.4541111, lng: 19.9435287 },
    { lat: 48.4542508, lng: 19.9434234 },
    { lat: 48.4541793, lng: 19.943224 },
    { lat: 48.454215, lng: 19.9428739 },
    { lat: 48.4544713, lng: 19.9426463 },
    { lat: 48.4545169, lng: 19.9425305 },
    { lat: 48.4545177, lng: 19.9423107 },
    { lat: 48.4544123, lng: 19.9418201 },
    { lat: 48.4542373, lng: 19.9416513 },
    { lat: 48.4542292, lng: 19.9412713 },
    { lat: 48.4540997, lng: 19.9410353 },
    { lat: 48.4541825, lng: 19.9409451 },
    { lat: 48.4541639, lng: 19.940851 },
    { lat: 48.4539954, lng: 19.9405212 },
    { lat: 48.4539125, lng: 19.9404752 },
    { lat: 48.4538546, lng: 19.9401462 },
    { lat: 48.4536801, lng: 19.9400717 },
    { lat: 48.4536307, lng: 19.9399651 },
    { lat: 48.4536788, lng: 19.9396694 },
    { lat: 48.453758, lng: 19.9395465 },
    { lat: 48.4536249, lng: 19.939198 },
    { lat: 48.4535177, lng: 19.9390862 },
    { lat: 48.4535241, lng: 19.9388642 },
    { lat: 48.4536994, lng: 19.9386958 },
    { lat: 48.4536953, lng: 19.9385503 },
    { lat: 48.4534675, lng: 19.9378018 },
    { lat: 48.4534838, lng: 19.9375742 },
    { lat: 48.4533056, lng: 19.9371751 },
    { lat: 48.4533335, lng: 19.9369552 },
    { lat: 48.4532768, lng: 19.9367882 },
    { lat: 48.4533861, lng: 19.9362329 },
    { lat: 48.453476, lng: 19.9362051 },
    { lat: 48.453578, lng: 19.9360055 },
    { lat: 48.4536618, lng: 19.9361709 },
    { lat: 48.4537125, lng: 19.9361479 },
    { lat: 48.4537388, lng: 19.9358686 },
    { lat: 48.4538092, lng: 19.935853 },
    { lat: 48.4538298, lng: 19.9357818 },
    { lat: 48.4537229, lng: 19.9355724 },
    { lat: 48.4536821, lng: 19.9356718 },
    { lat: 48.4536061, lng: 19.9356834 },
    { lat: 48.4536161, lng: 19.9354425 },
    { lat: 48.4533982, lng: 19.9352207 },
    { lat: 48.4533706, lng: 19.9349157 },
    { lat: 48.4534802, lng: 19.9347409 },
    { lat: 48.453385, lng: 19.9346212 },
    { lat: 48.4534316, lng: 19.9344975 },
    { lat: 48.4535054, lng: 19.9344627 },
    { lat: 48.4535121, lng: 19.9345628 },
    { lat: 48.4535697, lng: 19.9345424 },
    { lat: 48.4535609, lng: 19.9343339 },
    { lat: 48.4534448, lng: 19.9341481 },
    { lat: 48.4534345, lng: 19.9339344 },
    { lat: 48.4534901, lng: 19.9337821 },
    { lat: 48.4535155, lng: 19.9330816 },
    { lat: 48.4534247, lng: 19.9329889 },
    { lat: 48.4535343, lng: 19.932786 },
    { lat: 48.4534542, lng: 19.9326514 },
    { lat: 48.4534637, lng: 19.9325291 },
    { lat: 48.4535359, lng: 19.9325076 },
    { lat: 48.453486, lng: 19.9324162 },
    { lat: 48.4533976, lng: 19.9324315 },
    { lat: 48.4534155, lng: 19.9322456 },
    { lat: 48.453549, lng: 19.9322338 },
    { lat: 48.4535436, lng: 19.9321275 },
    { lat: 48.4534428, lng: 19.9319566 },
    { lat: 48.4533242, lng: 19.9319556 },
    { lat: 48.4533177, lng: 19.9318795 },
    { lat: 48.4534814, lng: 19.9317261 },
    { lat: 48.4532862, lng: 19.9316448 },
    { lat: 48.4534108, lng: 19.9315001 },
    { lat: 48.4535085, lng: 19.9309615 },
    { lat: 48.4515212, lng: 19.931252 },
    { lat: 48.4510561, lng: 19.9282457 },
    { lat: 48.4509682, lng: 19.9284547 },
    { lat: 48.4502929, lng: 19.9256177 },
    { lat: 48.4497533, lng: 19.9231138 },
    { lat: 48.4496183, lng: 19.9222751 },
    { lat: 48.4489663, lng: 19.922491 },
    { lat: 48.4482332, lng: 19.9198575 },
    { lat: 48.4481839, lng: 19.9199676 },
    { lat: 48.4476975, lng: 19.9186857 },
    { lat: 48.4468955, lng: 19.9152371 },
    { lat: 48.4468742, lng: 19.914763 },
    { lat: 48.4463918, lng: 19.9130206 },
    { lat: 48.4397869, lng: 19.9170007 },
    { lat: 48.439529, lng: 19.9158669 },
    { lat: 48.4383581, lng: 19.9107251 },
    { lat: 48.438323, lng: 19.910668 },
    { lat: 48.438262, lng: 19.910844 },
    { lat: 48.43823, lng: 19.910953 },
    { lat: 48.438161, lng: 19.911166 },
    { lat: 48.438132, lng: 19.911275 },
    { lat: 48.437985, lng: 19.912013 },
    { lat: 48.437805, lng: 19.912932 },
    { lat: 48.437795, lng: 19.913198 },
    { lat: 48.437777, lng: 19.913869 },
    { lat: 48.437751, lng: 19.914852 },
    { lat: 48.437747, lng: 19.915089 },
    { lat: 48.437769, lng: 19.915649 },
    { lat: 48.437798, lng: 19.916361 },
    { lat: 48.437821, lng: 19.916888 },
    { lat: 48.437853, lng: 19.917585 },
    { lat: 48.437881, lng: 19.918157 },
    { lat: 48.437977, lng: 19.918678 },
    { lat: 48.438031, lng: 19.918977 },
    { lat: 48.438113, lng: 19.91942 },
    { lat: 48.438191, lng: 19.919845 },
    { lat: 48.438227, lng: 19.920008 },
    { lat: 48.438371, lng: 19.920708 },
    { lat: 48.43857, lng: 19.921689 },
    { lat: 48.438675, lng: 19.922197 },
    { lat: 48.438745, lng: 19.922512 },
    { lat: 48.438885, lng: 19.923169 },
    { lat: 48.438939, lng: 19.923423 },
    { lat: 48.438963, lng: 19.923596 },
    { lat: 48.438989, lng: 19.923849 },
    { lat: 48.439017, lng: 19.924269 },
    { lat: 48.439043, lng: 19.924683 },
    { lat: 48.439094, lng: 19.92497 },
    { lat: 48.439156, lng: 19.925329 },
    { lat: 48.439346, lng: 19.926484 },
    { lat: 48.439262, lng: 19.927335 },
    { lat: 48.439242, lng: 19.927516 },
    { lat: 48.439161, lng: 19.927432 },
    { lat: 48.438821, lng: 19.927118 },
    { lat: 48.43871, lng: 19.927016 },
    { lat: 48.438519, lng: 19.926845 },
    { lat: 48.438142, lng: 19.926481 },
    { lat: 48.438017, lng: 19.926368 },
    { lat: 48.437826, lng: 19.926186 },
    { lat: 48.437551, lng: 19.925926 },
    { lat: 48.437283, lng: 19.925651 },
    { lat: 48.436943, lng: 19.925298 },
    { lat: 48.436758, lng: 19.925107 },
    { lat: 48.436587, lng: 19.924929 },
    { lat: 48.436358, lng: 19.924762 },
    { lat: 48.436121, lng: 19.924592 },
    { lat: 48.435738, lng: 19.924318 },
    { lat: 48.435627, lng: 19.924236 },
    { lat: 48.435497, lng: 19.924144 },
    { lat: 48.435483, lng: 19.924136 },
    { lat: 48.435379, lng: 19.924061 },
    { lat: 48.434967, lng: 19.92408 },
    { lat: 48.434787, lng: 19.924084 },
    { lat: 48.434591, lng: 19.923777 },
    { lat: 48.434423, lng: 19.92352 },
    { lat: 48.434248, lng: 19.923249 },
    { lat: 48.433972, lng: 19.923525 },
    { lat: 48.433511, lng: 19.92383 },
    { lat: 48.433388, lng: 19.923913 },
    { lat: 48.433163, lng: 19.924554 },
    { lat: 48.432978, lng: 19.925075 },
    { lat: 48.43285, lng: 19.925216 },
    { lat: 48.432718, lng: 19.925361 },
  ],
  Budikovany: [
    { lat: 48.5064889, lng: 20.0773146 },
    { lat: 48.506076, lng: 20.0782778 },
    { lat: 48.5054648, lng: 20.0787797 },
    { lat: 48.5046808, lng: 20.0792952 },
    { lat: 48.504093, lng: 20.0798705 },
    { lat: 48.5039431, lng: 20.0803626 },
    { lat: 48.5037057, lng: 20.0808039 },
    { lat: 48.503032, lng: 20.0813229 },
    { lat: 48.5027322, lng: 20.0814241 },
    { lat: 48.5019067, lng: 20.0813997 },
    { lat: 48.5016871, lng: 20.0811581 },
    { lat: 48.5012838, lng: 20.0805018 },
    { lat: 48.5010246, lng: 20.0803065 },
    { lat: 48.500526, lng: 20.0800693 },
    { lat: 48.4987198, lng: 20.0806558 },
    { lat: 48.497422, lng: 20.0819061 },
    { lat: 48.4963614, lng: 20.0825148 },
    { lat: 48.4953006, lng: 20.0828806 },
    { lat: 48.4944556, lng: 20.083091 },
    { lat: 48.4932451, lng: 20.0829906 },
    { lat: 48.4905636, lng: 20.082947 },
    { lat: 48.4900569, lng: 20.082624 },
    { lat: 48.4893849, lng: 20.083126 },
    { lat: 48.4886946, lng: 20.0837568 },
    { lat: 48.4873378, lng: 20.0851923 },
    { lat: 48.4868356, lng: 20.085519 },
    { lat: 48.486355, lng: 20.0856107 },
    { lat: 48.4853694, lng: 20.0855025 },
    { lat: 48.4853115, lng: 20.0860671 },
    { lat: 48.4828727, lng: 20.0855967 },
    { lat: 48.4823863, lng: 20.0856272 },
    { lat: 48.4810302, lng: 20.0865568 },
    { lat: 48.4797918, lng: 20.0877495 },
    { lat: 48.4791036, lng: 20.0883512 },
    { lat: 48.4774954, lng: 20.0894192 },
    { lat: 48.4772395, lng: 20.089665 },
    { lat: 48.4772002, lng: 20.0913847 },
    { lat: 48.4772664, lng: 20.0916789 },
    { lat: 48.4772366, lng: 20.0920793 },
    { lat: 48.4770543, lng: 20.0921633 },
    { lat: 48.4769992, lng: 20.0920581 },
    { lat: 48.4768803, lng: 20.0919889 },
    { lat: 48.4767523, lng: 20.0922692 },
    { lat: 48.4767166, lng: 20.0927152 },
    { lat: 48.4767475, lng: 20.0928625 },
    { lat: 48.4768326, lng: 20.0929909 },
    { lat: 48.4769376, lng: 20.0929964 },
    { lat: 48.47702, lng: 20.0932731 },
    { lat: 48.4769849, lng: 20.0933481 },
    { lat: 48.4768, lng: 20.0934036 },
    { lat: 48.4765983, lng: 20.0936702 },
    { lat: 48.4766512, lng: 20.0937554 },
    { lat: 48.4768899, lng: 20.0936721 },
    { lat: 48.4770667, lng: 20.0938609 },
    { lat: 48.4771492, lng: 20.0938652 },
    { lat: 48.4776615, lng: 20.0975014 },
    { lat: 48.4776589, lng: 20.0975996 },
    { lat: 48.4773894, lng: 20.0975153 },
    { lat: 48.4773761, lng: 20.0976273 },
    { lat: 48.4775363, lng: 20.099162 },
    { lat: 48.4780844, lng: 20.1005527 },
    { lat: 48.4783649, lng: 20.1009902 },
    { lat: 48.4795071, lng: 20.1037007 },
    { lat: 48.4806801, lng: 20.1066458 },
    { lat: 48.481843, lng: 20.1064089 },
    { lat: 48.4820493, lng: 20.1065531 },
    { lat: 48.4823483, lng: 20.1074146 },
    { lat: 48.4825608, lng: 20.1077091 },
    { lat: 48.4831801, lng: 20.1079498 },
    { lat: 48.4838323, lng: 20.1085265 },
    { lat: 48.4844358, lng: 20.1090712 },
    { lat: 48.4847256, lng: 20.1079827 },
    { lat: 48.484819, lng: 20.1067682 },
    { lat: 48.4849613, lng: 20.1056831 },
    { lat: 48.4854825, lng: 20.1045234 },
    { lat: 48.4854985, lng: 20.1042009 },
    { lat: 48.4853275, lng: 20.1031939 },
    { lat: 48.4853254, lng: 20.1026487 },
    { lat: 48.4859436, lng: 20.1008639 },
    { lat: 48.4866063, lng: 20.0992044 },
    { lat: 48.4867064, lng: 20.0991706 },
    { lat: 48.487232, lng: 20.0981754 },
    { lat: 48.4875357, lng: 20.0982491 },
    { lat: 48.4876843, lng: 20.0983843 },
    { lat: 48.4884612, lng: 20.0973093 },
    { lat: 48.4894839, lng: 20.0956119 },
    { lat: 48.4913944, lng: 20.0958583 },
    { lat: 48.4923618, lng: 20.0959141 },
    { lat: 48.4935666, lng: 20.0958189 },
    { lat: 48.4939916, lng: 20.0959654 },
    { lat: 48.4943118, lng: 20.0968237 },
    { lat: 48.4945045, lng: 20.0967969 },
    { lat: 48.494704, lng: 20.0972361 },
    { lat: 48.4952983, lng: 20.0973388 },
    { lat: 48.4957491, lng: 20.0972339 },
    { lat: 48.4962252, lng: 20.0972524 },
    { lat: 48.4973947, lng: 20.0975601 },
    { lat: 48.4987502, lng: 20.0976011 },
    { lat: 48.4990319, lng: 20.0977401 },
    { lat: 48.4993082, lng: 20.098187 },
    { lat: 48.4998757, lng: 20.0982919 },
    { lat: 48.5009969, lng: 20.0980582 },
    { lat: 48.5013135, lng: 20.09825 },
    { lat: 48.5016998, lng: 20.0960318 },
    { lat: 48.5019888, lng: 20.0947345 },
    { lat: 48.5019803, lng: 20.0930759 },
    { lat: 48.5020912, lng: 20.0915291 },
    { lat: 48.5023466, lng: 20.0893934 },
    { lat: 48.5029811, lng: 20.0859873 },
    { lat: 48.5030411, lng: 20.085227 },
    { lat: 48.5032573, lng: 20.0839967 },
    { lat: 48.5038277, lng: 20.0828327 },
    { lat: 48.5055276, lng: 20.0805182 },
    { lat: 48.5061298, lng: 20.0788432 },
    { lat: 48.5064403, lng: 20.0775564 },
    { lat: 48.5064889, lng: 20.0773146 },
  ],
  Hnúšťa: [
    { lat: 48.6250502, lng: 19.9168086 },
    { lat: 48.624598, lng: 19.9164002 },
    { lat: 48.6242977, lng: 19.9159903 },
    { lat: 48.6238271, lng: 19.9155719 },
    { lat: 48.6234069, lng: 19.9154161 },
    { lat: 48.6227255, lng: 19.9151529 },
    { lat: 48.6223225, lng: 19.9150025 },
    { lat: 48.6222482, lng: 19.9149769 },
    { lat: 48.6220312, lng: 19.9149072 },
    { lat: 48.6218423, lng: 19.9150729 },
    { lat: 48.6215501, lng: 19.9153783 },
    { lat: 48.6209068, lng: 19.9158544 },
    { lat: 48.6205025, lng: 19.9159385 },
    { lat: 48.6201891, lng: 19.9158802 },
    { lat: 48.619919, lng: 19.9159398 },
    { lat: 48.6197529, lng: 19.9160592 },
    { lat: 48.6195973, lng: 19.9162676 },
    { lat: 48.619444, lng: 19.9165823 },
    { lat: 48.6192069, lng: 19.9166733 },
    { lat: 48.6187936, lng: 19.9168344 },
    { lat: 48.6180452, lng: 19.9171163 },
    { lat: 48.6175993, lng: 19.9172827 },
    { lat: 48.6167676, lng: 19.9176182 },
    { lat: 48.6164271, lng: 19.9177943 },
    { lat: 48.6156703, lng: 19.9180941 },
    { lat: 48.6151621, lng: 19.9182704 },
    { lat: 48.6146594, lng: 19.9184519 },
    { lat: 48.6139183, lng: 19.9186647 },
    { lat: 48.6136583, lng: 19.9187522 },
    { lat: 48.6134395, lng: 19.9188232 },
    { lat: 48.6130459, lng: 19.91895 },
    { lat: 48.6127633, lng: 19.9190539 },
    { lat: 48.6123589, lng: 19.9192131 },
    { lat: 48.6118918, lng: 19.9193042 },
    { lat: 48.6117924, lng: 19.9194577 },
    { lat: 48.6115937, lng: 19.9197653 },
    { lat: 48.6110709, lng: 19.919629 },
    { lat: 48.6108725, lng: 19.9194432 },
    { lat: 48.6104569, lng: 19.9193112 },
    { lat: 48.6102314, lng: 19.9193334 },
    { lat: 48.6098238, lng: 19.9192842 },
    { lat: 48.6092118, lng: 19.9192477 },
    { lat: 48.6090764, lng: 19.9190633 },
    { lat: 48.6087334, lng: 19.9187288 },
    { lat: 48.6085617, lng: 19.9186105 },
    { lat: 48.6081167, lng: 19.9183226 },
    { lat: 48.6073717, lng: 19.91789 },
    { lat: 48.607352, lng: 19.917878 },
    { lat: 48.6073147, lng: 19.9178503 },
    { lat: 48.6072963, lng: 19.9178378 },
    { lat: 48.6068993, lng: 19.9175908 },
    { lat: 48.6066352, lng: 19.917364 },
    { lat: 48.6062588, lng: 19.9169954 },
    { lat: 48.6060491, lng: 19.9167007 },
    { lat: 48.6056589, lng: 19.9161591 },
    { lat: 48.6052429, lng: 19.9156253 },
    { lat: 48.6048784, lng: 19.9153207 },
    { lat: 48.6043889, lng: 19.9150132 },
    { lat: 48.6041219, lng: 19.9148302 },
    { lat: 48.6038052, lng: 19.9146174 },
    { lat: 48.6032839, lng: 19.914333 },
    { lat: 48.6031859, lng: 19.9143325 },
    { lat: 48.6030505, lng: 19.9142727 },
    { lat: 48.602858, lng: 19.9143035 },
    { lat: 48.6026782, lng: 19.9142341 },
    { lat: 48.6025589, lng: 19.914209 },
    { lat: 48.6024574, lng: 19.914127 },
    { lat: 48.6023026, lng: 19.9140879 },
    { lat: 48.6022328, lng: 19.9140409 },
    { lat: 48.6021677, lng: 19.9140704 },
    { lat: 48.6020866, lng: 19.914101 },
    { lat: 48.6020102, lng: 19.9140255 },
    { lat: 48.6018383, lng: 19.9139293 },
    { lat: 48.601717, lng: 19.9138852 },
    { lat: 48.6016271, lng: 19.9138925 },
    { lat: 48.6015396, lng: 19.9138437 },
    { lat: 48.6013366, lng: 19.9136555 },
    { lat: 48.6011946, lng: 19.9135771 },
    { lat: 48.6010715, lng: 19.9135684 },
    { lat: 48.6009964, lng: 19.9135995 },
    { lat: 48.6008638, lng: 19.9136138 },
    { lat: 48.6006744, lng: 19.9134229 },
    { lat: 48.600572, lng: 19.9134235 },
    { lat: 48.6005306, lng: 19.9133001 },
    { lat: 48.6003898, lng: 19.9131921 },
    { lat: 48.6002376, lng: 19.9132171 },
    { lat: 48.6000047, lng: 19.9130625 },
    { lat: 48.5997499, lng: 19.9130893 },
    { lat: 48.5992388, lng: 19.9130713 },
    { lat: 48.5991153, lng: 19.9130765 },
    { lat: 48.5989568, lng: 19.9131399 },
    { lat: 48.5988858, lng: 19.9131421 },
    { lat: 48.5987899, lng: 19.9131077 },
    { lat: 48.5986375, lng: 19.9129431 },
    { lat: 48.5984901, lng: 19.9129467 },
    { lat: 48.5983501, lng: 19.9128364 },
    { lat: 48.5980949, lng: 19.9126352 },
    { lat: 48.5980172, lng: 19.912585 },
    { lat: 48.5979732, lng: 19.9125562 },
    { lat: 48.5978763, lng: 19.9125037 },
    { lat: 48.597575, lng: 19.9121759 },
    { lat: 48.5974777, lng: 19.9120295 },
    { lat: 48.5973888, lng: 19.9119548 },
    { lat: 48.5973439, lng: 19.911798 },
    { lat: 48.5971789, lng: 19.9115777 },
    { lat: 48.5970465, lng: 19.9112752 },
    { lat: 48.5969675, lng: 19.9111871 },
    { lat: 48.5968266, lng: 19.9109794 },
    { lat: 48.5967085, lng: 19.9109261 },
    { lat: 48.5966237, lng: 19.9108151 },
    { lat: 48.5964656, lng: 19.9106721 },
    { lat: 48.5963485, lng: 19.9107002 },
    { lat: 48.5962073, lng: 19.9106669 },
    { lat: 48.5960763, lng: 19.91066 },
    { lat: 48.5959409, lng: 19.9105472 },
    { lat: 48.5958683, lng: 19.9105378 },
    { lat: 48.5957949, lng: 19.9105613 },
    { lat: 48.5957094, lng: 19.9104839 },
    { lat: 48.5956596, lng: 19.9103734 },
    { lat: 48.5955373, lng: 19.9102781 },
    { lat: 48.5953484, lng: 19.9104199 },
    { lat: 48.5951785, lng: 19.9102403 },
    { lat: 48.5950302, lng: 19.9100622 },
    { lat: 48.5949959, lng: 19.9100257 },
    { lat: 48.5949197, lng: 19.9099555 },
    { lat: 48.5947777, lng: 19.9097693 },
    { lat: 48.5947135, lng: 19.9097438 },
    { lat: 48.5946427, lng: 19.9095336 },
    { lat: 48.5945689, lng: 19.9094966 },
    { lat: 48.5943462, lng: 19.9093165 },
    { lat: 48.5942504, lng: 19.9092126 },
    { lat: 48.5941864, lng: 19.909085 },
    { lat: 48.5939613, lng: 19.9090104 },
    { lat: 48.5939453, lng: 19.9090564 },
    { lat: 48.5938194, lng: 19.9091711 },
    { lat: 48.5937897, lng: 19.9091872 },
    { lat: 48.5937797, lng: 19.9091921 },
    { lat: 48.593596, lng: 19.9092946 },
    { lat: 48.593488, lng: 19.9094805 },
    { lat: 48.5933762, lng: 19.9097657 },
    { lat: 48.5932095, lng: 19.9102388 },
    { lat: 48.5931451, lng: 19.9102358 },
    { lat: 48.5930481, lng: 19.9102346 },
    { lat: 48.5930063, lng: 19.9102297 },
    { lat: 48.5929969, lng: 19.9102253 },
    { lat: 48.5922193, lng: 19.9097395 },
    { lat: 48.5920761, lng: 19.9096577 },
    { lat: 48.591962, lng: 19.9096004 },
    { lat: 48.5918746, lng: 19.9095061 },
    { lat: 48.5916322, lng: 19.9093396 },
    { lat: 48.5914351, lng: 19.9092907 },
    { lat: 48.591419, lng: 19.9093069 },
    { lat: 48.5913941, lng: 19.9093303 },
    { lat: 48.5912661, lng: 19.9092644 },
    { lat: 48.5912121, lng: 19.9091885 },
    { lat: 48.5911246, lng: 19.9091397 },
    { lat: 48.5910597, lng: 19.9090186 },
    { lat: 48.5909767, lng: 19.9089495 },
    { lat: 48.5908942, lng: 19.908919 },
    { lat: 48.5908349, lng: 19.9087689 },
    { lat: 48.5908856, lng: 19.9086629 },
    { lat: 48.5907575, lng: 19.9084875 },
    { lat: 48.5904564, lng: 19.9084779 },
    { lat: 48.5903718, lng: 19.9083434 },
    { lat: 48.5901961, lng: 19.9082377 },
    { lat: 48.5899454, lng: 19.9080389 },
    { lat: 48.5898526, lng: 19.9080323 },
    { lat: 48.5898085, lng: 19.9079455 },
    { lat: 48.5896818, lng: 19.907803 },
    { lat: 48.5895443, lng: 19.9077512 },
    { lat: 48.58938, lng: 19.9075963 },
    { lat: 48.5892325, lng: 19.9075694 },
    { lat: 48.5891473, lng: 19.9073923 },
    { lat: 48.5890528, lng: 19.9073683 },
    { lat: 48.5889261, lng: 19.9073596 },
    { lat: 48.588867, lng: 19.9072953 },
    { lat: 48.5887841, lng: 19.9073297 },
    { lat: 48.5886704, lng: 19.907309 },
    { lat: 48.5886191, lng: 19.907108 },
    { lat: 48.588527, lng: 19.9069579 },
    { lat: 48.5884975, lng: 19.9069522 },
    { lat: 48.5883999, lng: 19.9069332 },
    { lat: 48.5883757, lng: 19.9068694 },
    { lat: 48.588308, lng: 19.9068151 },
    { lat: 48.5881256, lng: 19.9065513 },
    { lat: 48.5880671, lng: 19.9063983 },
    { lat: 48.5880248, lng: 19.9062217 },
    { lat: 48.5879761, lng: 19.9061147 },
    { lat: 48.5876605, lng: 19.905766 },
    { lat: 48.5874919, lng: 19.9057215 },
    { lat: 48.5873626, lng: 19.9056721 },
    { lat: 48.5873036, lng: 19.9055085 },
    { lat: 48.5872371, lng: 19.9053986 },
    { lat: 48.5871301, lng: 19.9052709 },
    { lat: 48.5870899, lng: 19.9051976 },
    { lat: 48.5870623, lng: 19.9051875 },
    { lat: 48.5869976, lng: 19.9051468 },
    { lat: 48.5868939, lng: 19.905096 },
    { lat: 48.5869048, lng: 19.9050583 },
    { lat: 48.5867653, lng: 19.9046655 },
    { lat: 48.5866493, lng: 19.9044814 },
    { lat: 48.5866118, lng: 19.9044214 },
    { lat: 48.5865229, lng: 19.9043204 },
    { lat: 48.5861792, lng: 19.9040279 },
    { lat: 48.5858963, lng: 19.9039483 },
    { lat: 48.5858369, lng: 19.9038494 },
    { lat: 48.5857173, lng: 19.903554 },
    { lat: 48.5856984, lng: 19.9035046 },
    { lat: 48.5853719, lng: 19.9030613 },
    { lat: 48.5852044, lng: 19.9027278 },
    { lat: 48.5848079, lng: 19.9023112 },
    { lat: 48.584788, lng: 19.9022637 },
    { lat: 48.5846178, lng: 19.9018956 },
    { lat: 48.5844993, lng: 19.9017303 },
    { lat: 48.5841039, lng: 19.9013375 },
    { lat: 48.5838688, lng: 19.901001 },
    { lat: 48.5836205, lng: 19.900015 },
    { lat: 48.5834519, lng: 19.8994169 },
    { lat: 48.5834407, lng: 19.8993941 },
    { lat: 48.5834238, lng: 19.8993677 },
    { lat: 48.5832424, lng: 19.8990238 },
    { lat: 48.5829477, lng: 19.8985366 },
    { lat: 48.5827991, lng: 19.8981689 },
    { lat: 48.5826813, lng: 19.897922 },
    { lat: 48.5824663, lng: 19.897659 },
    { lat: 48.5823768, lng: 19.897513 },
    { lat: 48.5822773, lng: 19.8972971 },
    { lat: 48.5823377, lng: 19.8970756 },
    { lat: 48.5824433, lng: 19.8967017 },
    { lat: 48.5825361, lng: 19.8961077 },
    { lat: 48.5825241, lng: 19.89564 },
    { lat: 48.5824615, lng: 19.8951817 },
    { lat: 48.5824347, lng: 19.8949456 },
    { lat: 48.5822158, lng: 19.8944435 },
    { lat: 48.5819436, lng: 19.8937432 },
    { lat: 48.5817999, lng: 19.8934099 },
    { lat: 48.5817776, lng: 19.8933615 },
    { lat: 48.5815705, lng: 19.8928914 },
    { lat: 48.5813807, lng: 19.892225 },
    { lat: 48.5811832, lng: 19.891639 },
    { lat: 48.5812369, lng: 19.8911844 },
    { lat: 48.5812492, lng: 19.8907602 },
    { lat: 48.5812851, lng: 19.8904411 },
    { lat: 48.5812476, lng: 19.8901456 },
    { lat: 48.581206, lng: 19.8896985 },
    { lat: 48.5811029, lng: 19.8891318 },
    { lat: 48.5809964, lng: 19.8885427 },
    { lat: 48.5808502, lng: 19.8882061 },
    { lat: 48.5805577, lng: 19.8875882 },
    { lat: 48.5804204, lng: 19.886985 },
    { lat: 48.5803331, lng: 19.8859742 },
    { lat: 48.5797973, lng: 19.8856475 },
    { lat: 48.5793613, lng: 19.8853563 },
    { lat: 48.5789535, lng: 19.8850395 },
    { lat: 48.5788582, lng: 19.8849193 },
    { lat: 48.5788278, lng: 19.8849223 },
    { lat: 48.5786, lng: 19.8849438 },
    { lat: 48.5781822, lng: 19.8849998 },
    { lat: 48.5779319, lng: 19.8850971 },
    { lat: 48.5775545, lng: 19.8852022 },
    { lat: 48.57745, lng: 19.8851274 },
    { lat: 48.5774994, lng: 19.8849152 },
    { lat: 48.5773271, lng: 19.8847342 },
    { lat: 48.5771893, lng: 19.8842801 },
    { lat: 48.5771395, lng: 19.8840374 },
    { lat: 48.5774413, lng: 19.883644 },
    { lat: 48.5768813, lng: 19.8833247 },
    { lat: 48.576635, lng: 19.8833601 },
    { lat: 48.5762967, lng: 19.883244 },
    { lat: 48.5762684, lng: 19.8831415 },
    { lat: 48.5762564, lng: 19.8830992 },
    { lat: 48.5758351, lng: 19.883309 },
    { lat: 48.575572, lng: 19.8834488 },
    { lat: 48.5749559, lng: 19.8833997 },
    { lat: 48.5743996, lng: 19.8834592 },
    { lat: 48.5738468, lng: 19.8835206 },
    { lat: 48.5735727, lng: 19.8831397 },
    { lat: 48.5733151, lng: 19.8828098 },
    { lat: 48.573046, lng: 19.882507 },
    { lat: 48.57234, lng: 19.88293 },
    { lat: 48.57171, lng: 19.882656 },
    { lat: 48.571402, lng: 19.882654 },
    { lat: 48.570993, lng: 19.882601 },
    { lat: 48.570891, lng: 19.882625 },
    { lat: 48.570711, lng: 19.882728 },
    { lat: 48.570287, lng: 19.8835717 },
    { lat: 48.5699734, lng: 19.8849561 },
    { lat: 48.5686894, lng: 19.8888445 },
    { lat: 48.5685942, lng: 19.8895684 },
    { lat: 48.5685362, lng: 19.8909744 },
    { lat: 48.5683671, lng: 19.8919427 },
    { lat: 48.5667102, lng: 19.8967346 },
    { lat: 48.5664591, lng: 19.8972893 },
    { lat: 48.5661746, lng: 19.8976666 },
    { lat: 48.5656137, lng: 19.8987747 },
    { lat: 48.5655174, lng: 19.8988704 },
    { lat: 48.5652141, lng: 19.8996756 },
    { lat: 48.5651899, lng: 19.9002183 },
    { lat: 48.5650652, lng: 19.9006212 },
    { lat: 48.5649118, lng: 19.9021431 },
    { lat: 48.5645691, lng: 19.9032437 },
    { lat: 48.5642823, lng: 19.9065995 },
    { lat: 48.5630793, lng: 19.9098207 },
    { lat: 48.5631276, lng: 19.9099238 },
    { lat: 48.5626394, lng: 19.9118611 },
    { lat: 48.5620372, lng: 19.9126688 },
    { lat: 48.5617718, lng: 19.9129167 },
    { lat: 48.5613146, lng: 19.9130002 },
    { lat: 48.5608529, lng: 19.9133721 },
    { lat: 48.5607042, lng: 19.9136098 },
    { lat: 48.5599588, lng: 19.9136678 },
    { lat: 48.5584873, lng: 19.9139313 },
    { lat: 48.5587838, lng: 19.9152031 },
    { lat: 48.5594643, lng: 19.9182371 },
    { lat: 48.5599086, lng: 19.9209677 },
    { lat: 48.5600971, lng: 19.921613 },
    { lat: 48.5601146, lng: 19.9223386 },
    { lat: 48.5601937, lng: 19.9228772 },
    { lat: 48.5601204, lng: 19.9243545 },
    { lat: 48.5601455, lng: 19.9245444 },
    { lat: 48.5605877, lng: 19.9256425 },
    { lat: 48.5607403, lng: 19.9263711 },
    { lat: 48.5614841, lng: 19.9269578 },
    { lat: 48.5616909, lng: 19.9273184 },
    { lat: 48.5620503, lng: 19.9274263 },
    { lat: 48.561944, lng: 19.9279479 },
    { lat: 48.5615614, lng: 19.928646 },
    { lat: 48.5606621, lng: 19.9297187 },
    { lat: 48.5604168, lng: 19.9302289 },
    { lat: 48.5600054, lng: 19.9307572 },
    { lat: 48.5598074, lng: 19.9309189 },
    { lat: 48.5592126, lng: 19.9311054 },
    { lat: 48.5588254, lng: 19.9316976 },
    { lat: 48.5576647, lng: 19.9322193 },
    { lat: 48.5573689, lng: 19.9326795 },
    { lat: 48.5568445, lng: 19.9331935 },
    { lat: 48.5566111, lng: 19.9336669 },
    { lat: 48.5565415, lng: 19.9339122 },
    { lat: 48.556168, lng: 19.9360982 },
    { lat: 48.5557872, lng: 19.9367856 },
    { lat: 48.5554574, lng: 19.9381957 },
    { lat: 48.5553225, lng: 19.9386549 },
    { lat: 48.555158, lng: 19.9390225 },
    { lat: 48.5550636, lng: 19.939902 },
    { lat: 48.5548551, lng: 19.9409331 },
    { lat: 48.5550238, lng: 19.9412479 },
    { lat: 48.5552052, lng: 19.9420187 },
    { lat: 48.5554247, lng: 19.9424912 },
    { lat: 48.5560455, lng: 19.9448825 },
    { lat: 48.5563498, lng: 19.9470148 },
    { lat: 48.5560947, lng: 19.9490488 },
    { lat: 48.5557323, lng: 19.9540495 },
    { lat: 48.5559182, lng: 19.958682 },
    { lat: 48.556569, lng: 19.9622213 },
    { lat: 48.556401, lng: 19.9623242 },
    { lat: 48.5568093, lng: 19.9681225 },
    { lat: 48.5573078, lng: 19.9678685 },
    { lat: 48.5576747, lng: 19.9678308 },
    { lat: 48.5578961, lng: 19.9682506 },
    { lat: 48.5580293, lng: 19.9688164 },
    { lat: 48.5580471, lng: 19.9692897 },
    { lat: 48.5580084, lng: 19.9694407 },
    { lat: 48.5580422, lng: 19.9700782 },
    { lat: 48.5581468, lng: 19.9706109 },
    { lat: 48.5585227, lng: 19.97162 },
    { lat: 48.5584569, lng: 19.9728083 },
    { lat: 48.5582831, lng: 19.9730987 },
    { lat: 48.5583542, lng: 19.9732054 },
    { lat: 48.5582989, lng: 19.9734457 },
    { lat: 48.5584031, lng: 19.9736434 },
    { lat: 48.5583944, lng: 19.9738619 },
    { lat: 48.558338, lng: 19.9739978 },
    { lat: 48.5581244, lng: 19.9741381 },
    { lat: 48.5580593, lng: 19.9742513 },
    { lat: 48.5580654, lng: 19.9743738 },
    { lat: 48.5578824, lng: 19.9745449 },
    { lat: 48.5578574, lng: 19.9747023 },
    { lat: 48.557631, lng: 19.9749139 },
    { lat: 48.557571, lng: 19.97507 },
    { lat: 48.5574187, lng: 19.9756795 },
    { lat: 48.5573404, lng: 19.9758264 },
    { lat: 48.5573219, lng: 19.9763459 },
    { lat: 48.5573743, lng: 19.9766527 },
    { lat: 48.5573241, lng: 19.9769641 },
    { lat: 48.5573564, lng: 19.9772984 },
    { lat: 48.5569376, lng: 19.9780262 },
    { lat: 48.5562893, lng: 19.9797765 },
    { lat: 48.5560575, lng: 19.9801307 },
    { lat: 48.555974, lng: 19.9801821 },
    { lat: 48.5558828, lng: 19.9805553 },
    { lat: 48.555897, lng: 19.980869 },
    { lat: 48.5557037, lng: 19.9815709 },
    { lat: 48.5557099, lng: 19.9817751 },
    { lat: 48.5557611, lng: 19.9818413 },
    { lat: 48.5557432, lng: 19.9819781 },
    { lat: 48.5558006, lng: 19.9821135 },
    { lat: 48.5557489, lng: 19.9822461 },
    { lat: 48.5557684, lng: 19.982412 },
    { lat: 48.5558381, lng: 19.9824377 },
    { lat: 48.5558532, lng: 19.9825835 },
    { lat: 48.5558186, lng: 19.9827241 },
    { lat: 48.5558486, lng: 19.9831408 },
    { lat: 48.5556223, lng: 19.9839791 },
    { lat: 48.555532, lng: 19.9850698 },
    { lat: 48.5553088, lng: 19.9866695 },
    { lat: 48.5553105, lng: 19.988209 },
    { lat: 48.5551669, lng: 19.9893249 },
    { lat: 48.5551659, lng: 19.9893286 },
    { lat: 48.5553891, lng: 19.9893606 },
    { lat: 48.5563673, lng: 19.9897719 },
    { lat: 48.5567021, lng: 19.9900415 },
    { lat: 48.5576374, lng: 19.9905113 },
    { lat: 48.558083, lng: 19.9900941 },
    { lat: 48.5587923, lng: 19.9896043 },
    { lat: 48.559478, lng: 19.9895688 },
    { lat: 48.5599874, lng: 19.9897894 },
    { lat: 48.5606778, lng: 19.9896525 },
    { lat: 48.5606736, lng: 19.9898232 },
    { lat: 48.5607034, lng: 19.9898356 },
    { lat: 48.5606686, lng: 19.9900751 },
    { lat: 48.5606828, lng: 19.9905762 },
    { lat: 48.5605772, lng: 19.9913882 },
    { lat: 48.560366, lng: 19.9916939 },
    { lat: 48.560036, lng: 19.9924241 },
    { lat: 48.5595505, lng: 19.993066 },
    { lat: 48.5594533, lng: 19.9934323 },
    { lat: 48.5592452, lng: 19.9937889 },
    { lat: 48.5590868, lng: 19.9943103 },
    { lat: 48.558764, lng: 19.9944816 },
    { lat: 48.5582687, lng: 19.9952399 },
    { lat: 48.5582335, lng: 19.9954193 },
    { lat: 48.5579302, lng: 19.9959243 },
    { lat: 48.5579012, lng: 19.9962525 },
    { lat: 48.5574321, lng: 19.9970986 },
    { lat: 48.5572793, lng: 19.9979417 },
    { lat: 48.5572897, lng: 19.9982184 },
    { lat: 48.5569911, lng: 19.9997204 },
    { lat: 48.556286, lng: 20.0011988 },
    { lat: 48.5563246, lng: 20.0019841 },
    { lat: 48.5562973, lng: 20.0025393 },
    { lat: 48.5564746, lng: 20.0030632 },
    { lat: 48.5564916, lng: 20.0032533 },
    { lat: 48.5563478, lng: 20.0037719 },
    { lat: 48.5563432, lng: 20.0048174 },
    { lat: 48.5561752, lng: 20.0052739 },
    { lat: 48.5558151, lng: 20.0067866 },
    { lat: 48.5556811, lng: 20.0070546 },
    { lat: 48.555725, lng: 20.0071369 },
    { lat: 48.5556898, lng: 20.0073173 },
    { lat: 48.555563, lng: 20.007436 },
    { lat: 48.5554282, lng: 20.0080092 },
    { lat: 48.5554735, lng: 20.0082566 },
    { lat: 48.5551542, lng: 20.0099169 },
    { lat: 48.5548971, lng: 20.0101745 },
    { lat: 48.5548686, lng: 20.0104357 },
    { lat: 48.5547684, lng: 20.0104936 },
    { lat: 48.5546358, lng: 20.0109307 },
    { lat: 48.5543107, lng: 20.0113968 },
    { lat: 48.5543106, lng: 20.0115294 },
    { lat: 48.5541234, lng: 20.0118228 },
    { lat: 48.553689, lng: 20.0122604 },
    { lat: 48.5535929, lng: 20.0124697 },
    { lat: 48.553532, lng: 20.0128464 },
    { lat: 48.5533816, lng: 20.0130996 },
    { lat: 48.5530153, lng: 20.0134159 },
    { lat: 48.5527035, lng: 20.0142288 },
    { lat: 48.5524523, lng: 20.0144759 },
    { lat: 48.5523317, lng: 20.0148222 },
    { lat: 48.5523588, lng: 20.0148492 },
    { lat: 48.5520761, lng: 20.0154427 },
    { lat: 48.5516869, lng: 20.0157833 },
    { lat: 48.5516161, lng: 20.0159969 },
    { lat: 48.5508884, lng: 20.0166483 },
    { lat: 48.5507835, lng: 20.0168598 },
    { lat: 48.5505342, lng: 20.0169603 },
    { lat: 48.5503727, lng: 20.0172191 },
    { lat: 48.550222, lng: 20.0176284 },
    { lat: 48.5500855, lng: 20.0177867 },
    { lat: 48.5499791, lng: 20.0177986 },
    { lat: 48.5499113, lng: 20.0179259 },
    { lat: 48.5498991, lng: 20.0181784 },
    { lat: 48.549776, lng: 20.0185481 },
    { lat: 48.5492763, lng: 20.0187736 },
    { lat: 48.5492162, lng: 20.0190584 },
    { lat: 48.5489001, lng: 20.0198754 },
    { lat: 48.5488248, lng: 20.0205044 },
    { lat: 48.5486284, lng: 20.0208991 },
    { lat: 48.5485621, lng: 20.0213843 },
    { lat: 48.5482686, lng: 20.0221018 },
    { lat: 48.5480848, lng: 20.0227403 },
    { lat: 48.5480191, lng: 20.0235418 },
    { lat: 48.5478263, lng: 20.0237517 },
    { lat: 48.5473208, lng: 20.0258657 },
    { lat: 48.5472319, lng: 20.0261671 },
    { lat: 48.5469864, lng: 20.0270328 },
    { lat: 48.5474783, lng: 20.0298332 },
    { lat: 48.547995, lng: 20.0292273 },
    { lat: 48.5482178, lng: 20.0292773 },
    { lat: 48.5487884, lng: 20.0284302 },
    { lat: 48.5495728, lng: 20.0279013 },
    { lat: 48.5497804, lng: 20.0278532 },
    { lat: 48.5498936, lng: 20.0279751 },
    { lat: 48.5503818, lng: 20.0276875 },
    { lat: 48.5512262, lng: 20.0268116 },
    { lat: 48.5518009, lng: 20.0263576 },
    { lat: 48.5521598, lng: 20.026271 },
    { lat: 48.5523704, lng: 20.0264306 },
    { lat: 48.5541937, lng: 20.0268007 },
    { lat: 48.5545041, lng: 20.0267617 },
    { lat: 48.554992, lng: 20.0270611 },
    { lat: 48.5558654, lng: 20.0279613 },
    { lat: 48.5565066, lng: 20.0287234 },
    { lat: 48.5569383, lng: 20.0292714 },
    { lat: 48.5574527, lng: 20.0300729 },
    { lat: 48.5580686, lng: 20.0300958 },
    { lat: 48.5590814, lng: 20.0308043 },
    { lat: 48.5601384, lng: 20.0312161 },
    { lat: 48.5605612, lng: 20.0312526 },
    { lat: 48.561271, lng: 20.0310933 },
    { lat: 48.5627218, lng: 20.0319867 },
    { lat: 48.5634027, lng: 20.0322687 },
    { lat: 48.5636361, lng: 20.0322491 },
    { lat: 48.5639333, lng: 20.0320006 },
    { lat: 48.5645386, lng: 20.0303594 },
    { lat: 48.5647813, lng: 20.0298624 },
    { lat: 48.5649587, lng: 20.029873 },
    { lat: 48.5658665, lng: 20.0302509 },
    { lat: 48.5669211, lng: 20.0307721 },
    { lat: 48.5673595, lng: 20.0308583 },
    { lat: 48.5679249, lng: 20.0308276 },
    { lat: 48.5681923, lng: 20.0308663 },
    { lat: 48.5682521, lng: 20.0304659 },
    { lat: 48.5686041, lng: 20.0282062 },
    { lat: 48.5687514, lng: 20.028146 },
    { lat: 48.568795, lng: 20.0279214 },
    { lat: 48.5690052, lng: 20.0274509 },
    { lat: 48.5689933, lng: 20.0269491 },
    { lat: 48.5695833, lng: 20.0261603 },
    { lat: 48.5697129, lng: 20.0260527 },
    { lat: 48.5695204, lng: 20.0252739 },
    { lat: 48.56963, lng: 20.0246315 },
    { lat: 48.5697248, lng: 20.0243685 },
    { lat: 48.5702384, lng: 20.0238828 },
    { lat: 48.570626, lng: 20.0239891 },
    { lat: 48.5713229, lng: 20.0246828 },
    { lat: 48.5717944, lng: 20.0249963 },
    { lat: 48.5719066, lng: 20.0248231 },
    { lat: 48.5725, lng: 20.0246588 },
    { lat: 48.5731242, lng: 20.0237165 },
    { lat: 48.5735425, lng: 20.0237512 },
    { lat: 48.5738803, lng: 20.0241543 },
    { lat: 48.5739998, lng: 20.024484 },
    { lat: 48.5744304, lng: 20.0251931 },
    { lat: 48.575221, lng: 20.0239159 },
    { lat: 48.5767902, lng: 20.0243016 },
    { lat: 48.5776982, lng: 20.0243845 },
    { lat: 48.5783423, lng: 20.0237558 },
    { lat: 48.578596, lng: 20.023736 },
    { lat: 48.5791411, lng: 20.0239487 },
    { lat: 48.5795972, lng: 20.0238646 },
    { lat: 48.5801476, lng: 20.0234229 },
    { lat: 48.581029, lng: 20.023084 },
    { lat: 48.581454, lng: 20.0231501 },
    { lat: 48.5815158, lng: 20.0233346 },
    { lat: 48.5822445, lng: 20.0233303 },
    { lat: 48.5826139, lng: 20.0232227 },
    { lat: 48.5826266, lng: 20.0231489 },
    { lat: 48.5827017, lng: 20.0231649 },
    { lat: 48.5835016, lng: 20.0237366 },
    { lat: 48.5840899, lng: 20.0228271 },
    { lat: 48.5848016, lng: 20.0215504 },
    { lat: 48.5849823, lng: 20.0213504 },
    { lat: 48.5855443, lng: 20.0212082 },
    { lat: 48.5857081, lng: 20.0208461 },
    { lat: 48.5862965, lng: 20.0204481 },
    { lat: 48.5871899, lng: 20.0206398 },
    { lat: 48.5874472, lng: 20.0203013 },
    { lat: 48.5879728, lng: 20.0198583 },
    { lat: 48.5882803, lng: 20.0193701 },
    { lat: 48.5882955, lng: 20.0189272 },
    { lat: 48.5881769, lng: 20.0186527 },
    { lat: 48.5879929, lng: 20.0185072 },
    { lat: 48.5879382, lng: 20.0180342 },
    { lat: 48.5879961, lng: 20.0173691 },
    { lat: 48.5879354, lng: 20.0169386 },
    { lat: 48.5895457, lng: 20.0177084 },
    { lat: 48.590446, lng: 20.0173409 },
    { lat: 48.5905637, lng: 20.0172205 },
    { lat: 48.5907949, lng: 20.0167157 },
    { lat: 48.5909746, lng: 20.0164862 },
    { lat: 48.5912961, lng: 20.016255 },
    { lat: 48.5917375, lng: 20.0149549 },
    { lat: 48.5928511, lng: 20.0145681 },
    { lat: 48.5928741, lng: 20.0142831 },
    { lat: 48.5929353, lng: 20.0141047 },
    { lat: 48.5931315, lng: 20.0139184 },
    { lat: 48.5932829, lng: 20.0139265 },
    { lat: 48.5936122, lng: 20.0138041 },
    { lat: 48.5939736, lng: 20.0138556 },
    { lat: 48.5941427, lng: 20.0139641 },
    { lat: 48.5942753, lng: 20.0133935 },
    { lat: 48.5943107, lng: 20.0127831 },
    { lat: 48.5942681, lng: 20.0120335 },
    { lat: 48.5943011, lng: 20.0115019 },
    { lat: 48.5945428, lng: 20.0096349 },
    { lat: 48.5951894, lng: 20.0094724 },
    { lat: 48.595876, lng: 20.009568 },
    { lat: 48.596383, lng: 20.0090807 },
    { lat: 48.596783, lng: 20.0088971 },
    { lat: 48.5970979, lng: 20.0086448 },
    { lat: 48.5977628, lng: 20.0082706 },
    { lat: 48.5981969, lng: 20.007799 },
    { lat: 48.5985352, lng: 20.0076195 },
    { lat: 48.598702, lng: 20.0073514 },
    { lat: 48.5992334, lng: 20.00702 },
    { lat: 48.5994283, lng: 20.0066748 },
    { lat: 48.6003978, lng: 20.0069876 },
    { lat: 48.6007073, lng: 20.0074105 },
    { lat: 48.6009232, lng: 20.0078316 },
    { lat: 48.6011484, lng: 20.0080184 },
    { lat: 48.6015042, lng: 20.0080714 },
    { lat: 48.6017955, lng: 20.0078465 },
    { lat: 48.60185, lng: 20.0096351 },
    { lat: 48.602367, lng: 20.0098215 },
    { lat: 48.6031061, lng: 20.0103097 },
    { lat: 48.6032192, lng: 20.0103214 },
    { lat: 48.6035, lng: 20.0099519 },
    { lat: 48.6038308, lng: 20.0093514 },
    { lat: 48.6036617, lng: 20.0087282 },
    { lat: 48.6036895, lng: 20.0084782 },
    { lat: 48.6040003, lng: 20.0078273 },
    { lat: 48.6051272, lng: 20.0074603 },
    { lat: 48.6052679, lng: 20.0072408 },
    { lat: 48.605456, lng: 20.0067328 },
    { lat: 48.6054143, lng: 20.0060858 },
    { lat: 48.6054795, lng: 20.0054617 },
    { lat: 48.6055992, lng: 20.0053315 },
    { lat: 48.6056073, lng: 20.0049114 },
    { lat: 48.605866, lng: 20.0050067 },
    { lat: 48.60612, lng: 20.0052378 },
    { lat: 48.6062103, lng: 20.0054004 },
    { lat: 48.6068239, lng: 20.0058257 },
    { lat: 48.6071456, lng: 20.0062703 },
    { lat: 48.6077349, lng: 20.0067079 },
    { lat: 48.6078823, lng: 20.0072424 },
    { lat: 48.6078988, lng: 20.0076921 },
    { lat: 48.6083506, lng: 20.0073986 },
    { lat: 48.608731, lng: 20.0073905 },
    { lat: 48.6090785, lng: 20.0076191 },
    { lat: 48.6091475, lng: 20.0083258 },
    { lat: 48.6096835, lng: 20.007375 },
    { lat: 48.6098759, lng: 20.0069128 },
    { lat: 48.6111221, lng: 20.006171 },
    { lat: 48.6112267, lng: 20.0064109 },
    { lat: 48.6119092, lng: 20.0065937 },
    { lat: 48.6122096, lng: 20.0063701 },
    { lat: 48.6124396, lng: 20.006052 },
    { lat: 48.6127931, lng: 20.0058297 },
    { lat: 48.6130648, lng: 20.005434 },
    { lat: 48.6137305, lng: 20.0048164 },
    { lat: 48.613935, lng: 20.0045577 },
    { lat: 48.6142493, lng: 20.0044263 },
    { lat: 48.6153419, lng: 20.0043455 },
    { lat: 48.6159217, lng: 20.0042301 },
    { lat: 48.6175596, lng: 20.0027527 },
    { lat: 48.6176698, lng: 20.0015043 },
    { lat: 48.6177024, lng: 19.9995442 },
    { lat: 48.6178385, lng: 19.9988385 },
    { lat: 48.6179273, lng: 19.9987556 },
    { lat: 48.6180089, lng: 19.9985218 },
    { lat: 48.6182165, lng: 19.9981816 },
    { lat: 48.6185743, lng: 19.9977694 },
    { lat: 48.6188506, lng: 19.9976419 },
    { lat: 48.6190085, lng: 19.9976472 },
    { lat: 48.6196065, lng: 19.9973744 },
    { lat: 48.6199469, lng: 19.9969521 },
    { lat: 48.6200292, lng: 19.9967377 },
    { lat: 48.6200634, lng: 19.9958854 },
    { lat: 48.6199484, lng: 19.9950807 },
    { lat: 48.6199927, lng: 19.9946841 },
    { lat: 48.6200986, lng: 19.994339 },
    { lat: 48.6203939, lng: 19.9939612 },
    { lat: 48.6209431, lng: 19.99352 },
    { lat: 48.6212433, lng: 19.9929744 },
    { lat: 48.621628, lng: 19.992933 },
    { lat: 48.6217068, lng: 19.9928486 },
    { lat: 48.6221334, lng: 19.9927093 },
    { lat: 48.6229706, lng: 19.9928768 },
    { lat: 48.6234098, lng: 19.9931678 },
    { lat: 48.623696, lng: 19.9931195 },
    { lat: 48.6238911, lng: 19.9932287 },
    { lat: 48.6248553, lng: 19.9930929 },
    { lat: 48.6251899, lng: 19.9926675 },
    { lat: 48.6253812, lng: 19.9927154 },
    { lat: 48.6255994, lng: 19.9924126 },
    { lat: 48.6257246, lng: 19.992417 },
    { lat: 48.6261514, lng: 19.9919836 },
    { lat: 48.6265143, lng: 19.9918001 },
    { lat: 48.6268824, lng: 19.9917696 },
    { lat: 48.6271947, lng: 19.9915339 },
    { lat: 48.6273981, lng: 19.9914924 },
    { lat: 48.6276198, lng: 19.9913025 },
    { lat: 48.6278892, lng: 19.9906618 },
    { lat: 48.6281552, lng: 19.9904195 },
    { lat: 48.6282962, lng: 19.9901864 },
    { lat: 48.6283737, lng: 19.9902039 },
    { lat: 48.6285325, lng: 19.9900164 },
    { lat: 48.6286965, lng: 19.9900598 },
    { lat: 48.6288072, lng: 19.9900151 },
    { lat: 48.6288597, lng: 19.9898936 },
    { lat: 48.6290116, lng: 19.9900543 },
    { lat: 48.6294298, lng: 19.9900859 },
    { lat: 48.6300948, lng: 19.9898789 },
    { lat: 48.6301954, lng: 19.9899156 },
    { lat: 48.630324, lng: 19.9897901 },
    { lat: 48.630397, lng: 19.9898657 },
    { lat: 48.6304023, lng: 19.9899631 },
    { lat: 48.6304981, lng: 19.9899109 },
    { lat: 48.6305478, lng: 19.9899941 },
    { lat: 48.6308808, lng: 19.9899587 },
    { lat: 48.6313457, lng: 19.990107 },
    { lat: 48.6316995, lng: 19.9900828 },
    { lat: 48.6321408, lng: 19.990161 },
    { lat: 48.6322104, lng: 19.9900465 },
    { lat: 48.6323654, lng: 19.9899825 },
    { lat: 48.6327476, lng: 19.9895842 },
    { lat: 48.6328549, lng: 19.9894073 },
    { lat: 48.633544, lng: 19.9889159 },
    { lat: 48.6337046, lng: 19.9888774 },
    { lat: 48.6338063, lng: 19.988753 },
    { lat: 48.6342317, lng: 19.988607 },
    { lat: 48.6344023, lng: 19.9884603 },
    { lat: 48.6352224, lng: 19.9884838 },
    { lat: 48.6357118, lng: 19.9882049 },
    { lat: 48.6362888, lng: 19.9882085 },
    { lat: 48.6365714, lng: 19.9880454 },
    { lat: 48.6368748, lng: 19.9880538 },
    { lat: 48.6375567, lng: 19.9876877 },
    { lat: 48.6377138, lng: 19.9876745 },
    { lat: 48.6379526, lng: 19.9874853 },
    { lat: 48.638027, lng: 19.9873304 },
    { lat: 48.6381131, lng: 19.9873667 },
    { lat: 48.6383178, lng: 19.987173 },
    { lat: 48.6388658, lng: 19.9865123 },
    { lat: 48.6390112, lng: 19.9864884 },
    { lat: 48.6398991, lng: 19.9858522 },
    { lat: 48.6404585, lng: 19.9856263 },
    { lat: 48.640886, lng: 19.9853172 },
    { lat: 48.6411554, lng: 19.9852135 },
    { lat: 48.6416656, lng: 19.98545 },
    { lat: 48.6420821, lng: 19.9853628 },
    { lat: 48.6422087, lng: 19.9854329 },
    { lat: 48.6423058, lng: 19.9853556 },
    { lat: 48.6425837, lng: 19.985297 },
    { lat: 48.6428904, lng: 19.9850052 },
    { lat: 48.6431429, lng: 19.9846049 },
    { lat: 48.6434537, lng: 19.9844672 },
    { lat: 48.6436643, lng: 19.9844706 },
    { lat: 48.6436494, lng: 19.9844122 },
    { lat: 48.6439217, lng: 19.9845631 },
    { lat: 48.6443152, lng: 19.9842747 },
    { lat: 48.644826, lng: 19.9841286 },
    { lat: 48.6449798, lng: 19.9835248 },
    { lat: 48.6453435, lng: 19.9837938 },
    { lat: 48.6458078, lng: 19.9836365 },
    { lat: 48.645268, lng: 19.9830929 },
    { lat: 48.6442347, lng: 19.9823593 },
    { lat: 48.6433849, lng: 19.9820545 },
    { lat: 48.6426263, lng: 19.9819387 },
    { lat: 48.6416498, lng: 19.9813219 },
    { lat: 48.6409819, lng: 19.9812957 },
    { lat: 48.6407027, lng: 19.9811967 },
    { lat: 48.6404789, lng: 19.981123 },
    { lat: 48.640523, lng: 19.9806166 },
    { lat: 48.6403548, lng: 19.9797548 },
    { lat: 48.6404401, lng: 19.9791048 },
    { lat: 48.6402953, lng: 19.9788216 },
    { lat: 48.6402394, lng: 19.9779187 },
    { lat: 48.6403574, lng: 19.9771391 },
    { lat: 48.6402702, lng: 19.9762439 },
    { lat: 48.6399359, lng: 19.9757943 },
    { lat: 48.6398805, lng: 19.9754428 },
    { lat: 48.6399434, lng: 19.9745253 },
    { lat: 48.6397784, lng: 19.9738184 },
    { lat: 48.6395149, lng: 19.9733354 },
    { lat: 48.6391011, lng: 19.9719618 },
    { lat: 48.6386524, lng: 19.971851 },
    { lat: 48.6384796, lng: 19.971541 },
    { lat: 48.6382477, lng: 19.971467 },
    { lat: 48.6367173, lng: 19.9701692 },
    { lat: 48.6363763, lng: 19.9692631 },
    { lat: 48.6361634, lng: 19.9679323 },
    { lat: 48.6362882, lng: 19.967097 },
    { lat: 48.63651, lng: 19.9665065 },
    { lat: 48.6368651, lng: 19.9660315 },
    { lat: 48.6370211, lng: 19.9654278 },
    { lat: 48.6371507, lng: 19.9652423 },
    { lat: 48.6372293, lng: 19.9649466 },
    { lat: 48.6373647, lng: 19.964898 },
    { lat: 48.6374049, lng: 19.9646473 },
    { lat: 48.6376349, lng: 19.9639564 },
    { lat: 48.6377143, lng: 19.9635452 },
    { lat: 48.6377033, lng: 19.9633618 },
    { lat: 48.6378392, lng: 19.9630574 },
    { lat: 48.637885, lng: 19.9627001 },
    { lat: 48.6381995, lng: 19.9619361 },
    { lat: 48.6382024, lng: 19.9611776 },
    { lat: 48.6383397, lng: 19.96056 },
    { lat: 48.6382651, lng: 19.9598844 },
    { lat: 48.6382792, lng: 19.9597648 },
    { lat: 48.6383735, lng: 19.9596788 },
    { lat: 48.6383953, lng: 19.959473 },
    { lat: 48.638266, lng: 19.9581434 },
    { lat: 48.6381969, lng: 19.9578711 },
    { lat: 48.6379973, lng: 19.9575448 },
    { lat: 48.6379111, lng: 19.9570242 },
    { lat: 48.6377235, lng: 19.9567178 },
    { lat: 48.637611, lng: 19.9566421 },
    { lat: 48.637517, lng: 19.9564396 },
    { lat: 48.637481, lng: 19.9559611 },
    { lat: 48.6373473, lng: 19.955787 },
    { lat: 48.6373667, lng: 19.9554468 },
    { lat: 48.6372653, lng: 19.9553383 },
    { lat: 48.6372219, lng: 19.9550593 },
    { lat: 48.6372382, lng: 19.9546688 },
    { lat: 48.6371285, lng: 19.9545807 },
    { lat: 48.6370459, lng: 19.9541731 },
    { lat: 48.6369446, lng: 19.9540952 },
    { lat: 48.6369478, lng: 19.9537954 },
    { lat: 48.6368691, lng: 19.9533973 },
    { lat: 48.6369829, lng: 19.9531623 },
    { lat: 48.6369102, lng: 19.9529848 },
    { lat: 48.6369413, lng: 19.9528707 },
    { lat: 48.6368984, lng: 19.9527056 },
    { lat: 48.6369049, lng: 19.9523791 },
    { lat: 48.6368125, lng: 19.9523268 },
    { lat: 48.636778, lng: 19.9520961 },
    { lat: 48.6366412, lng: 19.9517916 },
    { lat: 48.6367372, lng: 19.9515832 },
    { lat: 48.6367082, lng: 19.951273 },
    { lat: 48.6366132, lng: 19.9511874 },
    { lat: 48.6365446, lng: 19.9509212 },
    { lat: 48.6363062, lng: 19.9508487 },
    { lat: 48.636297, lng: 19.9505037 },
    { lat: 48.6361638, lng: 19.9504126 },
    { lat: 48.6360612, lng: 19.9500684 },
    { lat: 48.6357457, lng: 19.9496622 },
    { lat: 48.6356081, lng: 19.949179 },
    { lat: 48.6350742, lng: 19.9489177 },
    { lat: 48.6348288, lng: 19.9488929 },
    { lat: 48.6340677, lng: 19.9490909 },
    { lat: 48.6337264, lng: 19.9478644 },
    { lat: 48.6336271, lng: 19.9474363 },
    { lat: 48.6339001, lng: 19.9472348 },
    { lat: 48.6335546, lng: 19.9448731 },
    { lat: 48.6334177, lng: 19.9443257 },
    { lat: 48.6334013, lng: 19.9436506 },
    { lat: 48.6334839, lng: 19.9435764 },
    { lat: 48.63336, lng: 19.9428871 },
    { lat: 48.6334632, lng: 19.9417582 },
    { lat: 48.6336755, lng: 19.9410803 },
    { lat: 48.633621, lng: 19.940062 },
    { lat: 48.6335228, lng: 19.9394995 },
    { lat: 48.6336951, lng: 19.9385406 },
    { lat: 48.6336581, lng: 19.9379294 },
    { lat: 48.6331252, lng: 19.9358077 },
    { lat: 48.633385, lng: 19.9342584 },
    { lat: 48.6332311, lng: 19.9339752 },
    { lat: 48.6329761, lng: 19.9329285 },
    { lat: 48.6324322, lng: 19.9321941 },
    { lat: 48.6322704, lng: 19.9316755 },
    { lat: 48.6316959, lng: 19.9305316 },
    { lat: 48.6315172, lng: 19.9296429 },
    { lat: 48.6313689, lng: 19.9291754 },
    { lat: 48.6306547, lng: 19.9279564 },
    { lat: 48.6305585, lng: 19.9275711 },
    { lat: 48.6299631, lng: 19.926237 },
    { lat: 48.6294202, lng: 19.924145 },
    { lat: 48.6296664, lng: 19.9213549 },
    { lat: 48.6296642, lng: 19.9203982 },
    { lat: 48.6294994, lng: 19.9190453 },
    { lat: 48.6285476, lng: 19.9174043 },
    { lat: 48.627948, lng: 19.9167403 },
    { lat: 48.6274431, lng: 19.9163359 },
    { lat: 48.6267994, lng: 19.9159831 },
    { lat: 48.626697, lng: 19.9159556 },
    { lat: 48.6265568, lng: 19.9159187 },
    { lat: 48.6263237, lng: 19.9160534 },
    { lat: 48.6255265, lng: 19.9165139 },
    { lat: 48.6250918, lng: 19.9167828 },
    { lat: 48.6250502, lng: 19.9168086 },
  ],
  Barca: [
    { lat: 48.3450442, lng: 20.2417154 },
    { lat: 48.3454397, lng: 20.2431268 },
    { lat: 48.3458835, lng: 20.2442559 },
    { lat: 48.3465623, lng: 20.2448343 },
    { lat: 48.3456265, lng: 20.2466419 },
    { lat: 48.3450539, lng: 20.2473073 },
    { lat: 48.3449224, lng: 20.2476855 },
    { lat: 48.3443729, lng: 20.2484766 },
    { lat: 48.3437578, lng: 20.2491776 },
    { lat: 48.3427464, lng: 20.251349 },
    { lat: 48.3426501, lng: 20.2542415 },
    { lat: 48.3425201, lng: 20.2551895 },
    { lat: 48.3422993, lng: 20.2558083 },
    { lat: 48.3416741, lng: 20.2567661 },
    { lat: 48.339349, lng: 20.2586217 },
    { lat: 48.3391771, lng: 20.2587724 },
    { lat: 48.3392971, lng: 20.2589836 },
    { lat: 48.339366, lng: 20.259244 },
    { lat: 48.3393463, lng: 20.2596535 },
    { lat: 48.3394031, lng: 20.2598672 },
    { lat: 48.3395336, lng: 20.2600031 },
    { lat: 48.3395835, lng: 20.2602068 },
    { lat: 48.3398688, lng: 20.260188 },
    { lat: 48.3399372, lng: 20.2602926 },
    { lat: 48.340173, lng: 20.2601632 },
    { lat: 48.3403978, lng: 20.2601246 },
    { lat: 48.3410251, lng: 20.260125 },
    { lat: 48.3410196, lng: 20.2601818 },
    { lat: 48.3412765, lng: 20.2602972 },
    { lat: 48.3416419, lng: 20.2605955 },
    { lat: 48.34229, lng: 20.2609466 },
    { lat: 48.3432927, lng: 20.261038 },
    { lat: 48.3437124, lng: 20.2607434 },
    { lat: 48.3439961, lng: 20.2604613 },
    { lat: 48.3454175, lng: 20.2594757 },
    { lat: 48.3464638, lng: 20.258483 },
    { lat: 48.3476751, lng: 20.2569699 },
    { lat: 48.3487836, lng: 20.2557909 },
    { lat: 48.3499286, lng: 20.2550172 },
    { lat: 48.3502404, lng: 20.2550137 },
    { lat: 48.3505922, lng: 20.2552243 },
    { lat: 48.3511434, lng: 20.2557413 },
    { lat: 48.3525956, lng: 20.2561517 },
    { lat: 48.3531231, lng: 20.2561285 },
    { lat: 48.3544175, lng: 20.2556055 },
    { lat: 48.3547353, lng: 20.2553255 },
    { lat: 48.3552575, lng: 20.2550623 },
    { lat: 48.355864, lng: 20.2549066 },
    { lat: 48.3573363, lng: 20.2549976 },
    { lat: 48.3579226, lng: 20.2548153 },
    { lat: 48.3596974, lng: 20.2544435 },
    { lat: 48.3601656, lng: 20.2541242 },
    { lat: 48.3607063, lng: 20.2535377 },
    { lat: 48.3612034, lng: 20.2519961 },
    { lat: 48.3624128, lng: 20.2507733 },
    { lat: 48.3634362, lng: 20.2509887 },
    { lat: 48.3648864, lng: 20.2499089 },
    { lat: 48.3653692, lng: 20.2499551 },
    { lat: 48.3671157, lng: 20.2516948 },
    { lat: 48.3680049, lng: 20.2518529 },
    { lat: 48.3682101, lng: 20.25194 },
    { lat: 48.3684676, lng: 20.2521592 },
    { lat: 48.3697207, lng: 20.2544049 },
    { lat: 48.3701732, lng: 20.2554432 },
    { lat: 48.3704763, lng: 20.2558191 },
    { lat: 48.3708697, lng: 20.2569548 },
    { lat: 48.371006, lng: 20.2579999 },
    { lat: 48.3715684, lng: 20.2585512 },
    { lat: 48.3722331, lng: 20.2593194 },
    { lat: 48.3727364, lng: 20.2596529 },
    { lat: 48.3731301, lng: 20.2596134 },
    { lat: 48.3735858, lng: 20.2593224 },
    { lat: 48.3745329, lng: 20.2590915 },
    { lat: 48.3760603, lng: 20.2578524 },
    { lat: 48.3764973, lng: 20.2571745 },
    { lat: 48.3772558, lng: 20.256563 },
    { lat: 48.378016, lng: 20.2553217 },
    { lat: 48.3780275, lng: 20.254366 },
    { lat: 48.3790898, lng: 20.2516026 },
    { lat: 48.379676, lng: 20.2504892 },
    { lat: 48.380368, lng: 20.2496252 },
    { lat: 48.3813125, lng: 20.2489412 },
    { lat: 48.3821424, lng: 20.2489077 },
    { lat: 48.3825917, lng: 20.2492418 },
    { lat: 48.3831707, lng: 20.2488485 },
    { lat: 48.3834203, lng: 20.2486149 },
    { lat: 48.383814, lng: 20.2479789 },
    { lat: 48.384494, lng: 20.2463053 },
    { lat: 48.3854771, lng: 20.2468709 },
    { lat: 48.3862479, lng: 20.2467948 },
    { lat: 48.3875991, lng: 20.2460797 },
    { lat: 48.3881018, lng: 20.2456142 },
    { lat: 48.3889084, lng: 20.2440687 },
    { lat: 48.3890021, lng: 20.2430284 },
    { lat: 48.3891111, lng: 20.2426575 },
    { lat: 48.3894414, lng: 20.2419343 },
    { lat: 48.3898013, lng: 20.2413016 },
    { lat: 48.3899147, lng: 20.2398818 },
    { lat: 48.3900609, lng: 20.2386918 },
    { lat: 48.3897304, lng: 20.2377804 },
    { lat: 48.3894234, lng: 20.2371965 },
    { lat: 48.3888936, lng: 20.2358942 },
    { lat: 48.3887883, lng: 20.2348673 },
    { lat: 48.3887891, lng: 20.2341801 },
    { lat: 48.3891994, lng: 20.2328139 },
    { lat: 48.3892698, lng: 20.2318154 },
    { lat: 48.3892527, lng: 20.2306281 },
    { lat: 48.389459, lng: 20.2288898 },
    { lat: 48.3895936, lng: 20.2285924 },
    { lat: 48.3894065, lng: 20.2279151 },
    { lat: 48.3891086, lng: 20.2264963 },
    { lat: 48.3890345, lng: 20.2251831 },
    { lat: 48.3889305, lng: 20.2246138 },
    { lat: 48.3889003, lng: 20.2242512 },
    { lat: 48.3879196, lng: 20.2237262 },
    { lat: 48.3873599, lng: 20.2235571 },
    { lat: 48.3870014, lng: 20.2233571 },
    { lat: 48.3868313, lng: 20.2231591 },
    { lat: 48.3856855, lng: 20.221213 },
    { lat: 48.3849392, lng: 20.219567 },
    { lat: 48.3844433, lng: 20.2186914 },
    { lat: 48.3830722, lng: 20.2169437 },
    { lat: 48.3825315, lng: 20.2161267 },
    { lat: 48.3821583, lng: 20.2154455 },
    { lat: 48.3820571, lng: 20.2149477 },
    { lat: 48.3818045, lng: 20.2127501 },
    { lat: 48.3815761, lng: 20.2112211 },
    { lat: 48.3814544, lng: 20.2109937 },
    { lat: 48.3811972, lng: 20.2107605 },
    { lat: 48.3810754, lng: 20.2107018 },
    { lat: 48.3809192, lng: 20.2107365 },
    { lat: 48.3806901, lng: 20.2106113 },
    { lat: 48.3804192, lng: 20.2102064 },
    { lat: 48.3802375, lng: 20.2100837 },
    { lat: 48.3800804, lng: 20.2098761 },
    { lat: 48.3800284, lng: 20.2096729 },
    { lat: 48.3800652, lng: 20.2091625 },
    { lat: 48.3802038, lng: 20.208379 },
    { lat: 48.3788069, lng: 20.207507 },
    { lat: 48.377635, lng: 20.2075475 },
    { lat: 48.3766109, lng: 20.2080145 },
    { lat: 48.3759931, lng: 20.2099864 },
    { lat: 48.3756131, lng: 20.2098395 },
    { lat: 48.3742805, lng: 20.2107811 },
    { lat: 48.3738404, lng: 20.2109595 },
    { lat: 48.3735678, lng: 20.2112945 },
    { lat: 48.3734397, lng: 20.2119693 },
    { lat: 48.3732229, lng: 20.2142841 },
    { lat: 48.3728759, lng: 20.2155154 },
    { lat: 48.3726904, lng: 20.2165612 },
    { lat: 48.3728143, lng: 20.2183216 },
    { lat: 48.3729304, lng: 20.2190847 },
    { lat: 48.3728143, lng: 20.2196145 },
    { lat: 48.3721951, lng: 20.2205158 },
    { lat: 48.3712969, lng: 20.2215407 },
    { lat: 48.3711029, lng: 20.222346 },
    { lat: 48.370752, lng: 20.2231461 },
    { lat: 48.3701641, lng: 20.223704 },
    { lat: 48.3697421, lng: 20.2244743 },
    { lat: 48.369079, lng: 20.2261941 },
    { lat: 48.3685095, lng: 20.2271842 },
    { lat: 48.367684, lng: 20.2276919 },
    { lat: 48.3657815, lng: 20.2277745 },
    { lat: 48.3653468, lng: 20.2266796 },
    { lat: 48.3650751, lng: 20.2263914 },
    { lat: 48.3648014, lng: 20.226362 },
    { lat: 48.3644384, lng: 20.226603 },
    { lat: 48.363903, lng: 20.22716 },
    { lat: 48.3633994, lng: 20.2275501 },
    { lat: 48.3631603, lng: 20.2275968 },
    { lat: 48.3626532, lng: 20.2272499 },
    { lat: 48.3625642, lng: 20.2270263 },
    { lat: 48.3622352, lng: 20.2256726 },
    { lat: 48.3619496, lng: 20.2251257 },
    { lat: 48.3618087, lng: 20.2245081 },
    { lat: 48.3614204, lng: 20.2247071 },
    { lat: 48.3607935, lng: 20.2232058 },
    { lat: 48.3596719, lng: 20.2234638 },
    { lat: 48.357146, lng: 20.2246485 },
    { lat: 48.3560422, lng: 20.2247306 },
    { lat: 48.3552133, lng: 20.2240604 },
    { lat: 48.3543812, lng: 20.2245163 },
    { lat: 48.3537086, lng: 20.225127 },
    { lat: 48.3525312, lng: 20.2264064 },
    { lat: 48.3518061, lng: 20.2275916 },
    { lat: 48.3507375, lng: 20.2285814 },
    { lat: 48.3504413, lng: 20.2287763 },
    { lat: 48.3503026, lng: 20.2297845 },
    { lat: 48.3500332, lng: 20.2308757 },
    { lat: 48.3495654, lng: 20.2321593 },
    { lat: 48.3489828, lng: 20.2346478 },
    { lat: 48.3479882, lng: 20.2358014 },
    { lat: 48.3478945, lng: 20.2358772 },
    { lat: 48.3470015, lng: 20.2371773 },
    { lat: 48.3465153, lng: 20.2380843 },
    { lat: 48.3462169, lng: 20.2390193 },
    { lat: 48.3455944, lng: 20.2402073 },
    { lat: 48.3454579, lng: 20.2409595 },
    { lat: 48.3450442, lng: 20.2417154 },
  ],
  Číž: [
    { lat: 48.3001556, lng: 20.2721236 },
    { lat: 48.3001432, lng: 20.2725184 },
    { lat: 48.2998632, lng: 20.2766957 },
    { lat: 48.2997456, lng: 20.2768551 },
    { lat: 48.2993528, lng: 20.2783916 },
    { lat: 48.2976677, lng: 20.2851971 },
    { lat: 48.2974356, lng: 20.2863892 },
    { lat: 48.2974795, lng: 20.2866453 },
    { lat: 48.2977244, lng: 20.2868824 },
    { lat: 48.2986058, lng: 20.2871842 },
    { lat: 48.298799, lng: 20.2874262 },
    { lat: 48.2983793, lng: 20.287894 },
    { lat: 48.2983301, lng: 20.2880176 },
    { lat: 48.2993879, lng: 20.2884229 },
    { lat: 48.3017047, lng: 20.2894463 },
    { lat: 48.3020401, lng: 20.2895247 },
    { lat: 48.3024018, lng: 20.2894458 },
    { lat: 48.3027429, lng: 20.2892747 },
    { lat: 48.3051823, lng: 20.2876819 },
    { lat: 48.3064031, lng: 20.2869959 },
    { lat: 48.3065663, lng: 20.2869693 },
    { lat: 48.3068566, lng: 20.2870468 },
    { lat: 48.3069485, lng: 20.2873474 },
    { lat: 48.3069621, lng: 20.2883449 },
    { lat: 48.3072696, lng: 20.2911873 },
    { lat: 48.3074141, lng: 20.2911415 },
    { lat: 48.3131984, lng: 20.2910253 },
    { lat: 48.3155167, lng: 20.2901872 },
    { lat: 48.3175699, lng: 20.2890774 },
    { lat: 48.3157191, lng: 20.2844606 },
    { lat: 48.3160338, lng: 20.2841357 },
    { lat: 48.317607, lng: 20.2813435 },
    { lat: 48.3194105, lng: 20.2791417 },
    { lat: 48.3197414, lng: 20.2782268 },
    { lat: 48.3208324, lng: 20.277167 },
    { lat: 48.3222596, lng: 20.2765499 },
    { lat: 48.3225223, lng: 20.2774667 },
    { lat: 48.3230502, lng: 20.2777692 },
    { lat: 48.3239378, lng: 20.277151 },
    { lat: 48.324664, lng: 20.276153 },
    { lat: 48.3252128, lng: 20.2755894 },
    { lat: 48.3260779, lng: 20.2748267 },
    { lat: 48.327323, lng: 20.2729177 },
    { lat: 48.3257781, lng: 20.2703459 },
    { lat: 48.3271245, lng: 20.2695935 },
    { lat: 48.3253828, lng: 20.2672602 },
    { lat: 48.3198793, lng: 20.2598915 },
    { lat: 48.3167748, lng: 20.2562615 },
    { lat: 48.3167317, lng: 20.2561118 },
    { lat: 48.3155519, lng: 20.2548853 },
    { lat: 48.3145539, lng: 20.254937 },
    { lat: 48.3129331, lng: 20.2546248 },
    { lat: 48.3098824, lng: 20.2516428 },
    { lat: 48.309657, lng: 20.2516969 },
    { lat: 48.3094277, lng: 20.2516768 },
    { lat: 48.3089626, lng: 20.2519315 },
    { lat: 48.3074754, lng: 20.2529342 },
    { lat: 48.3046076, lng: 20.2549298 },
    { lat: 48.3041974, lng: 20.2555163 },
    { lat: 48.3040817, lng: 20.2558337 },
    { lat: 48.3031479, lng: 20.259729 },
    { lat: 48.3027981, lng: 20.2609521 },
    { lat: 48.3024951, lng: 20.2614928 },
    { lat: 48.3021436, lng: 20.2619396 },
    { lat: 48.3002174, lng: 20.2639092 },
    { lat: 48.3002705, lng: 20.2642823 },
    { lat: 48.3000716, lng: 20.2644907 },
    { lat: 48.2999456, lng: 20.2642144 },
    { lat: 48.2988687, lng: 20.2653397 },
    { lat: 48.2990966, lng: 20.2680121 },
    { lat: 48.2992212, lng: 20.2686515 },
    { lat: 48.2998753, lng: 20.2704295 },
    { lat: 48.3000515, lng: 20.2711729 },
    { lat: 48.3001556, lng: 20.2721236 },
  ],
  Kaloša: [
    { lat: 48.411851, lng: 20.2064627 },
    { lat: 48.4118482, lng: 20.206726 },
    { lat: 48.4115319, lng: 20.2079098 },
    { lat: 48.4113617, lng: 20.2084088 },
    { lat: 48.4111539, lng: 20.2087878 },
    { lat: 48.4109048, lng: 20.2097048 },
    { lat: 48.4101076, lng: 20.211198 },
    { lat: 48.4097651, lng: 20.2119774 },
    { lat: 48.4092069, lng: 20.2113425 },
    { lat: 48.4086217, lng: 20.2105455 },
    { lat: 48.4084722, lng: 20.2100745 },
    { lat: 48.4078851, lng: 20.2087803 },
    { lat: 48.407678, lng: 20.207858 },
    { lat: 48.4068935, lng: 20.2064429 },
    { lat: 48.4068873, lng: 20.2062378 },
    { lat: 48.4068052, lng: 20.2059567 },
    { lat: 48.406725, lng: 20.2058396 },
    { lat: 48.4066922, lng: 20.2056416 },
    { lat: 48.4063435, lng: 20.2047002 },
    { lat: 48.4057952, lng: 20.2038156 },
    { lat: 48.4060496, lng: 20.2035263 },
    { lat: 48.4060074, lng: 20.2030945 },
    { lat: 48.4060406, lng: 20.2025779 },
    { lat: 48.4057502, lng: 20.2018017 },
    { lat: 48.4056436, lng: 20.2012833 },
    { lat: 48.4053096, lng: 20.2009017 },
    { lat: 48.4048327, lng: 20.2006629 },
    { lat: 48.4043785, lng: 20.2006844 },
    { lat: 48.4038474, lng: 20.2000028 },
    { lat: 48.4038919, lng: 20.199678 },
    { lat: 48.4038867, lng: 20.1991563 },
    { lat: 48.4037573, lng: 20.1987704 },
    { lat: 48.4036783, lng: 20.1981796 },
    { lat: 48.4030899, lng: 20.1966874 },
    { lat: 48.4028137, lng: 20.1957629 },
    { lat: 48.4022903, lng: 20.1956291 },
    { lat: 48.4019609, lng: 20.1956424 },
    { lat: 48.4001243, lng: 20.1960197 },
    { lat: 48.3998639, lng: 20.1961242 },
    { lat: 48.3992352, lng: 20.1969037 },
    { lat: 48.3991257, lng: 20.1978164 },
    { lat: 48.3986863, lng: 20.1993804 },
    { lat: 48.3985937, lng: 20.1999283 },
    { lat: 48.3982931, lng: 20.2006857 },
    { lat: 48.3978843, lng: 20.201386 },
    { lat: 48.3976205, lng: 20.201635 },
    { lat: 48.3972111, lng: 20.2017594 },
    { lat: 48.3969869, lng: 20.2017309 },
    { lat: 48.3964842, lng: 20.2014665 },
    { lat: 48.3957857, lng: 20.2012641 },
    { lat: 48.3955113, lng: 20.2012487 },
    { lat: 48.3953351, lng: 20.2013508 },
    { lat: 48.3947558, lng: 20.2013669 },
    { lat: 48.394117, lng: 20.2016979 },
    { lat: 48.393679, lng: 20.2017862 },
    { lat: 48.3933593, lng: 20.2017579 },
    { lat: 48.3925585, lng: 20.2013434 },
    { lat: 48.3920975, lng: 20.2007445 },
    { lat: 48.3915432, lng: 20.2004072 },
    { lat: 48.3913608, lng: 20.2002442 },
    { lat: 48.3911918, lng: 20.199987 },
    { lat: 48.3910132, lng: 20.1998611 },
    { lat: 48.3906436, lng: 20.199742 },
    { lat: 48.3905076, lng: 20.1995598 },
    { lat: 48.3897943, lng: 20.1989907 },
    { lat: 48.3889925, lng: 20.2001961 },
    { lat: 48.3889224, lng: 20.2003014 },
    { lat: 48.3885562, lng: 20.2010533 },
    { lat: 48.3884417, lng: 20.2014205 },
    { lat: 48.3883946, lng: 20.2022664 },
    { lat: 48.3879087, lng: 20.2038484 },
    { lat: 48.3876171, lng: 20.2044365 },
    { lat: 48.3870765, lng: 20.2051674 },
    { lat: 48.3867637, lng: 20.205874 },
    { lat: 48.3863296, lng: 20.2063637 },
    { lat: 48.3852439, lng: 20.2071181 },
    { lat: 48.3848827, lng: 20.2072934 },
    { lat: 48.384395, lng: 20.207377 },
    { lat: 48.3838002, lng: 20.207319 },
    { lat: 48.3823537, lng: 20.2076189 },
    { lat: 48.381037, lng: 20.2082039 },
    { lat: 48.3802038, lng: 20.208379 },
    { lat: 48.3800652, lng: 20.2091625 },
    { lat: 48.3800284, lng: 20.2096729 },
    { lat: 48.3800804, lng: 20.2098761 },
    { lat: 48.3802375, lng: 20.2100837 },
    { lat: 48.3804192, lng: 20.2102064 },
    { lat: 48.3806901, lng: 20.2106113 },
    { lat: 48.3809192, lng: 20.2107365 },
    { lat: 48.3810754, lng: 20.2107018 },
    { lat: 48.3811972, lng: 20.2107605 },
    { lat: 48.3814544, lng: 20.2109937 },
    { lat: 48.3815761, lng: 20.2112211 },
    { lat: 48.3818045, lng: 20.2127501 },
    { lat: 48.3820571, lng: 20.2149477 },
    { lat: 48.3821583, lng: 20.2154455 },
    { lat: 48.3825315, lng: 20.2161267 },
    { lat: 48.3830722, lng: 20.2169437 },
    { lat: 48.3844433, lng: 20.2186914 },
    { lat: 48.3849392, lng: 20.219567 },
    { lat: 48.3856855, lng: 20.221213 },
    { lat: 48.3868313, lng: 20.2231591 },
    { lat: 48.3870014, lng: 20.2233571 },
    { lat: 48.3873599, lng: 20.2235571 },
    { lat: 48.3879196, lng: 20.2237262 },
    { lat: 48.3889003, lng: 20.2242512 },
    { lat: 48.3889305, lng: 20.2246138 },
    { lat: 48.3891704, lng: 20.2245581 },
    { lat: 48.3898458, lng: 20.224463 },
    { lat: 48.3911373, lng: 20.2244529 },
    { lat: 48.3917363, lng: 20.2243654 },
    { lat: 48.392156, lng: 20.2244547 },
    { lat: 48.392576, lng: 20.2242406 },
    { lat: 48.3942476, lng: 20.2230092 },
    { lat: 48.3963031, lng: 20.222252 },
    { lat: 48.3967734, lng: 20.221932 },
    { lat: 48.397026, lng: 20.2219102 },
    { lat: 48.3971948, lng: 20.2216837 },
    { lat: 48.3984143, lng: 20.2210073 },
    { lat: 48.3994596, lng: 20.2218179 },
    { lat: 48.3996992, lng: 20.2220968 },
    { lat: 48.3988508, lng: 20.2242217 },
    { lat: 48.3987743, lng: 20.2245855 },
    { lat: 48.3987627, lng: 20.2249438 },
    { lat: 48.3989067, lng: 20.2254444 },
    { lat: 48.3991925, lng: 20.2260862 },
    { lat: 48.3995604, lng: 20.226439 },
    { lat: 48.3997712, lng: 20.2268392 },
    { lat: 48.3999346, lng: 20.2269828 },
    { lat: 48.4001016, lng: 20.2276649 },
    { lat: 48.400386, lng: 20.2282109 },
    { lat: 48.4007061, lng: 20.2286374 },
    { lat: 48.4008641, lng: 20.229153 },
    { lat: 48.4008881, lng: 20.2294457 },
    { lat: 48.4011381, lng: 20.2304549 },
    { lat: 48.4017683, lng: 20.2319057 },
    { lat: 48.4021219, lng: 20.2324905 },
    { lat: 48.4025755, lng: 20.2329624 },
    { lat: 48.4032797, lng: 20.2332504 },
    { lat: 48.4038429, lng: 20.2335938 },
    { lat: 48.4044938, lng: 20.2337292 },
    { lat: 48.4054052, lng: 20.2341584 },
    { lat: 48.4065471, lng: 20.2358724 },
    { lat: 48.4068046, lng: 20.2359785 },
    { lat: 48.407009, lng: 20.2362142 },
    { lat: 48.4073278, lng: 20.2363857 },
    { lat: 48.4075122, lng: 20.2365751 },
    { lat: 48.4073759, lng: 20.2373636 },
    { lat: 48.4072719, lng: 20.2377204 },
    { lat: 48.4088539, lng: 20.2396273 },
    { lat: 48.4099528, lng: 20.2407154 },
    { lat: 48.4107042, lng: 20.2407501 },
    { lat: 48.4111785, lng: 20.2411494 },
    { lat: 48.4113549, lng: 20.2415313 },
    { lat: 48.4115632, lng: 20.2417288 },
    { lat: 48.4116695, lng: 20.2417663 },
    { lat: 48.4118939, lng: 20.2417188 },
    { lat: 48.4121138, lng: 20.241894 },
    { lat: 48.4151788, lng: 20.2446432 },
    { lat: 48.4161332, lng: 20.2460084 },
    { lat: 48.4164798, lng: 20.2467043 },
    { lat: 48.4174646, lng: 20.2480696 },
    { lat: 48.4177856, lng: 20.2488026 },
    { lat: 48.4187137, lng: 20.2498845 },
    { lat: 48.4189603, lng: 20.2503856 },
    { lat: 48.4192904, lng: 20.2513805 },
    { lat: 48.4201913, lng: 20.2524837 },
    { lat: 48.4220123, lng: 20.2538627 },
    { lat: 48.42395, lng: 20.255596 },
    { lat: 48.423975, lng: 20.255401 },
    { lat: 48.423997, lng: 20.255175 },
    { lat: 48.424075, lng: 20.255001 },
    { lat: 48.424101, lng: 20.254836 },
    { lat: 48.424182, lng: 20.254522 },
    { lat: 48.424303, lng: 20.254501 },
    { lat: 48.424382, lng: 20.254328 },
    { lat: 48.424503, lng: 20.254165 },
    { lat: 48.424591, lng: 20.253988 },
    { lat: 48.424768, lng: 20.253767 },
    { lat: 48.424973, lng: 20.253709 },
    { lat: 48.425064, lng: 20.25356 },
    { lat: 48.425204, lng: 20.253496 },
    { lat: 48.42529, lng: 20.253548 },
    { lat: 48.425344, lng: 20.253534 },
    { lat: 48.425434, lng: 20.253387 },
    { lat: 48.425497, lng: 20.253203 },
    { lat: 48.425619, lng: 20.253223 },
    { lat: 48.425763, lng: 20.252854 },
    { lat: 48.4261, lng: 20.252537 },
    { lat: 48.426233, lng: 20.251931 },
    { lat: 48.426394, lng: 20.251935 },
    { lat: 48.426405, lng: 20.251852 },
    { lat: 48.42631, lng: 20.251717 },
    { lat: 48.426272, lng: 20.251549 },
    { lat: 48.426602, lng: 20.250942 },
    { lat: 48.426732, lng: 20.250782 },
    { lat: 48.426829, lng: 20.2506 },
    { lat: 48.427051, lng: 20.250546 },
    { lat: 48.427141, lng: 20.250431 },
    { lat: 48.427178, lng: 20.250278 },
    { lat: 48.427376, lng: 20.249661 },
    { lat: 48.427483, lng: 20.249583 },
    { lat: 48.427628, lng: 20.2492 },
    { lat: 48.427618, lng: 20.248998 },
    { lat: 48.427716, lng: 20.248774 },
    { lat: 48.42775, lng: 20.248548 },
    { lat: 48.427851, lng: 20.248329 },
    { lat: 48.427915, lng: 20.24799 },
    { lat: 48.427938, lng: 20.247939 },
    { lat: 48.428053, lng: 20.247678 },
    { lat: 48.428085, lng: 20.247537 },
    { lat: 48.428276, lng: 20.247155 },
    { lat: 48.428304, lng: 20.246922 },
    { lat: 48.428481, lng: 20.246687 },
    { lat: 48.428649, lng: 20.246621 },
    { lat: 48.428705, lng: 20.24657 },
    { lat: 48.429037, lng: 20.246639 },
    { lat: 48.429073, lng: 20.246545 },
    { lat: 48.429175, lng: 20.246523 },
    { lat: 48.429359, lng: 20.246482 },
    { lat: 48.429388, lng: 20.246473 },
    { lat: 48.429927, lng: 20.246279 },
    { lat: 48.430542, lng: 20.246252 },
    { lat: 48.430778, lng: 20.246231 },
    { lat: 48.431312, lng: 20.246311 },
    { lat: 48.431878, lng: 20.246472 },
    { lat: 48.433247, lng: 20.247079 },
    { lat: 48.433835, lng: 20.247248 },
    { lat: 48.434115, lng: 20.247168 },
    { lat: 48.434126, lng: 20.247171 },
    { lat: 48.434286, lng: 20.247134 },
    { lat: 48.435179, lng: 20.246866 },
    { lat: 48.435295, lng: 20.246849 },
    { lat: 48.435798, lng: 20.246786 },
    { lat: 48.436182, lng: 20.246429 },
    { lat: 48.436702, lng: 20.246176 },
    { lat: 48.437188, lng: 20.245844 },
    { lat: 48.437986, lng: 20.245474 },
    { lat: 48.438364, lng: 20.2469 },
    { lat: 48.438567, lng: 20.246862 },
    { lat: 48.439103, lng: 20.246664 },
    { lat: 48.439221, lng: 20.246669 },
    { lat: 48.439336, lng: 20.24673 },
    { lat: 48.439436, lng: 20.24681 },
    { lat: 48.439771, lng: 20.247084 },
    { lat: 48.440199, lng: 20.247342 },
    { lat: 48.440356, lng: 20.247468 },
    { lat: 48.440636, lng: 20.24756 },
    { lat: 48.440821, lng: 20.247564 },
    { lat: 48.44096, lng: 20.247511 },
    { lat: 48.441319, lng: 20.247291 },
    { lat: 48.441577, lng: 20.246971 },
    { lat: 48.441886, lng: 20.24666 },
    { lat: 48.442018, lng: 20.246451 },
    { lat: 48.442186, lng: 20.245574 },
    { lat: 48.44211, lng: 20.245157 },
    { lat: 48.441954, lng: 20.24477 },
    { lat: 48.441948, lng: 20.244644 },
    { lat: 48.442012, lng: 20.244181 },
    { lat: 48.442047, lng: 20.243685 },
    { lat: 48.442231, lng: 20.24321 },
    { lat: 48.442257, lng: 20.242763 },
    { lat: 48.442334, lng: 20.242243 },
    { lat: 48.442409, lng: 20.242039 },
    { lat: 48.442531, lng: 20.241691 },
    { lat: 48.442555, lng: 20.241484 },
    { lat: 48.442548, lng: 20.241272 },
    { lat: 48.442528, lng: 20.240975 },
    { lat: 48.442581, lng: 20.240648 },
    { lat: 48.442595, lng: 20.240567 },
    { lat: 48.442711, lng: 20.240149 },
    { lat: 48.44293, lng: 20.23948 },
    { lat: 48.443151, lng: 20.238553 },
    { lat: 48.443308, lng: 20.237492 },
    { lat: 48.443387, lng: 20.23636 },
    { lat: 48.443524, lng: 20.2353255 },
    { lat: 48.4437418, lng: 20.2342313 },
    { lat: 48.44424, lng: 20.2328635 },
    { lat: 48.4442951, lng: 20.2316504 },
    { lat: 48.4442179, lng: 20.2308181 },
    { lat: 48.4440133, lng: 20.2302938 },
    { lat: 48.4438928, lng: 20.2297879 },
    { lat: 48.4436463, lng: 20.2294554 },
    { lat: 48.4431033, lng: 20.2291686 },
    { lat: 48.4421139, lng: 20.2290428 },
    { lat: 48.4415633, lng: 20.2291316 },
    { lat: 48.4412024, lng: 20.2290057 },
    { lat: 48.4406881, lng: 20.2286507 },
    { lat: 48.4404258, lng: 20.2283126 },
    { lat: 48.4396799, lng: 20.2270975 },
    { lat: 48.4393408, lng: 20.2263953 },
    { lat: 48.4389378, lng: 20.2257214 },
    { lat: 48.4381755, lng: 20.2247132 },
    { lat: 48.4357, lng: 20.2235079 },
    { lat: 48.4353247, lng: 20.2232762 },
    { lat: 48.4344614, lng: 20.2225592 },
    { lat: 48.4337386, lng: 20.2218082 },
    { lat: 48.4331047, lng: 20.2209435 },
    { lat: 48.4314801, lng: 20.2191344 },
    { lat: 48.431069, lng: 20.2185461 },
    { lat: 48.4303776, lng: 20.2173133 },
    { lat: 48.4295806, lng: 20.216145 },
    { lat: 48.4295382, lng: 20.2156229 },
    { lat: 48.4300735, lng: 20.2136665 },
    { lat: 48.4292554, lng: 20.2105766 },
    { lat: 48.4289209, lng: 20.2099861 },
    { lat: 48.4283114, lng: 20.2091151 },
    { lat: 48.4282984, lng: 20.2077233 },
    { lat: 48.428174, lng: 20.2069247 },
    { lat: 48.4284763, lng: 20.206045 },
    { lat: 48.4285343, lng: 20.2054692 },
    { lat: 48.4285071, lng: 20.2051039 },
    { lat: 48.4283624, lng: 20.2047503 },
    { lat: 48.427647, lng: 20.2035182 },
    { lat: 48.4272014, lng: 20.2032742 },
    { lat: 48.426989, lng: 20.2029804 },
    { lat: 48.4267454, lng: 20.2028614 },
    { lat: 48.4265981, lng: 20.202867 },
    { lat: 48.4253049, lng: 20.2034225 },
    { lat: 48.4238655, lng: 20.2036711 },
    { lat: 48.4236827, lng: 20.2036381 },
    { lat: 48.4233152, lng: 20.2034612 },
    { lat: 48.4230793, lng: 20.2031954 },
    { lat: 48.4225877, lng: 20.2023462 },
    { lat: 48.422336, lng: 20.2020783 },
    { lat: 48.4221675, lng: 20.2019798 },
    { lat: 48.4215047, lng: 20.2018773 },
    { lat: 48.4207825, lng: 20.2021008 },
    { lat: 48.4200174, lng: 20.2030986 },
    { lat: 48.4195024, lng: 20.2034496 },
    { lat: 48.4192598, lng: 20.2034965 },
    { lat: 48.4188608, lng: 20.2037161 },
    { lat: 48.4187102, lng: 20.2036936 },
    { lat: 48.4181903, lng: 20.2038458 },
    { lat: 48.4174208, lng: 20.2037267 },
    { lat: 48.416878, lng: 20.2039509 },
    { lat: 48.4166738, lng: 20.2041431 },
    { lat: 48.4165302, lng: 20.204381 },
    { lat: 48.4164808, lng: 20.2046602 },
    { lat: 48.4165033, lng: 20.2049957 },
    { lat: 48.4165423, lng: 20.2052092 },
    { lat: 48.4167371, lng: 20.2054871 },
    { lat: 48.4167325, lng: 20.2059056 },
    { lat: 48.4162945, lng: 20.2071154 },
    { lat: 48.4162774, lng: 20.2074459 },
    { lat: 48.4159582, lng: 20.208663 },
    { lat: 48.4157602, lng: 20.2096786 },
    { lat: 48.4151949, lng: 20.2094537 },
    { lat: 48.4145195, lng: 20.209297 },
    { lat: 48.4136002, lng: 20.2086179 },
    { lat: 48.4129499, lng: 20.2084979 },
    { lat: 48.4119497, lng: 20.2067044 },
    { lat: 48.411851, lng: 20.2064627 },
  ],
  Rumince: [
    { lat: 48.3919102, lng: 20.2842638 },
    { lat: 48.3915648, lng: 20.2836504 },
    { lat: 48.3914394, lng: 20.2832672 },
    { lat: 48.3914449, lng: 20.2828698 },
    { lat: 48.3912523, lng: 20.282071 },
    { lat: 48.3899975, lng: 20.2785386 },
    { lat: 48.3895829, lng: 20.2769454 },
    { lat: 48.3892386, lng: 20.2761906 },
    { lat: 48.3884309, lng: 20.2749337 },
    { lat: 48.387943, lng: 20.2728837 },
    { lat: 48.3880043, lng: 20.2709095 },
    { lat: 48.3876887, lng: 20.2694578 },
    { lat: 48.3871781, lng: 20.2687952 },
    { lat: 48.3861564, lng: 20.2677125 },
    { lat: 48.3851249, lng: 20.2674892 },
    { lat: 48.3845142, lng: 20.2672299 },
    { lat: 48.3842005, lng: 20.2664205 },
    { lat: 48.3839423, lng: 20.265448 },
    { lat: 48.3838726, lng: 20.2638656 },
    { lat: 48.3837524, lng: 20.2633651 },
    { lat: 48.3835221, lng: 20.2619377 },
    { lat: 48.383401, lng: 20.2598841 },
    { lat: 48.383013, lng: 20.2586229 },
    { lat: 48.3827319, lng: 20.2577858 },
    { lat: 48.3815601, lng: 20.257182 },
    { lat: 48.3812379, lng: 20.2569551 },
    { lat: 48.3811074, lng: 20.2568715 },
    { lat: 48.3804604, lng: 20.2568559 },
    { lat: 48.3803496, lng: 20.2545861 },
    { lat: 48.3801603, lng: 20.2519853 },
    { lat: 48.3799575, lng: 20.2511629 },
    { lat: 48.3797335, lng: 20.2505754 },
    { lat: 48.379676, lng: 20.2504892 },
    { lat: 48.3790898, lng: 20.2516026 },
    { lat: 48.3780275, lng: 20.254366 },
    { lat: 48.378016, lng: 20.2553217 },
    { lat: 48.3772558, lng: 20.256563 },
    { lat: 48.3764973, lng: 20.2571745 },
    { lat: 48.3760603, lng: 20.2578524 },
    { lat: 48.3745329, lng: 20.2590915 },
    { lat: 48.3735858, lng: 20.2593224 },
    { lat: 48.3731301, lng: 20.2596134 },
    { lat: 48.3727364, lng: 20.2596529 },
    { lat: 48.3722331, lng: 20.2593194 },
    { lat: 48.3715684, lng: 20.2585512 },
    { lat: 48.371006, lng: 20.2579999 },
    { lat: 48.3708697, lng: 20.2569548 },
    { lat: 48.3704763, lng: 20.2558191 },
    { lat: 48.3701732, lng: 20.2554432 },
    { lat: 48.3697207, lng: 20.2544049 },
    { lat: 48.3684676, lng: 20.2521592 },
    { lat: 48.3682101, lng: 20.25194 },
    { lat: 48.3680049, lng: 20.2518529 },
    { lat: 48.3671157, lng: 20.2516948 },
    { lat: 48.3653692, lng: 20.2499551 },
    { lat: 48.3648864, lng: 20.2499089 },
    { lat: 48.3634362, lng: 20.2509887 },
    { lat: 48.3624128, lng: 20.2507733 },
    { lat: 48.3612034, lng: 20.2519961 },
    { lat: 48.3607063, lng: 20.2535377 },
    { lat: 48.3601656, lng: 20.2541242 },
    { lat: 48.3596974, lng: 20.2544435 },
    { lat: 48.3579226, lng: 20.2548153 },
    { lat: 48.3573363, lng: 20.2549976 },
    { lat: 48.355864, lng: 20.2549066 },
    { lat: 48.3552575, lng: 20.2550623 },
    { lat: 48.3547353, lng: 20.2553255 },
    { lat: 48.3544175, lng: 20.2556055 },
    { lat: 48.3531231, lng: 20.2561285 },
    { lat: 48.3525956, lng: 20.2561517 },
    { lat: 48.3511434, lng: 20.2557413 },
    { lat: 48.3505922, lng: 20.2552243 },
    { lat: 48.3502404, lng: 20.2550137 },
    { lat: 48.3499286, lng: 20.2550172 },
    { lat: 48.3487836, lng: 20.2557909 },
    { lat: 48.3486677, lng: 20.256075 },
    { lat: 48.3487438, lng: 20.2576462 },
    { lat: 48.3490387, lng: 20.2582344 },
    { lat: 48.3493094, lng: 20.2601136 },
    { lat: 48.3497643, lng: 20.26155 },
    { lat: 48.3501357, lng: 20.2636659 },
    { lat: 48.3497285, lng: 20.2661132 },
    { lat: 48.3490833, lng: 20.267591 },
    { lat: 48.3493004, lng: 20.2697342 },
    { lat: 48.349591, lng: 20.2710423 },
    { lat: 48.3496548, lng: 20.2713167 },
    { lat: 48.350431, lng: 20.2727057 },
    { lat: 48.3513256, lng: 20.2746184 },
    { lat: 48.3512744, lng: 20.2765633 },
    { lat: 48.3528075, lng: 20.2802044 },
    { lat: 48.353216, lng: 20.2809055 },
    { lat: 48.3532634, lng: 20.2812798 },
    { lat: 48.3533684, lng: 20.2816564 },
    { lat: 48.3534334, lng: 20.2815854 },
    { lat: 48.3542918, lng: 20.2824447 },
    { lat: 48.3545601, lng: 20.2828503 },
    { lat: 48.3551639, lng: 20.2842167 },
    { lat: 48.3554479, lng: 20.2851594 },
    { lat: 48.3557478, lng: 20.286451 },
    { lat: 48.3559946, lng: 20.2882208 },
    { lat: 48.3572589, lng: 20.2933298 },
    { lat: 48.3566996, lng: 20.2937733 },
    { lat: 48.3561901, lng: 20.2946682 },
    { lat: 48.3543617, lng: 20.2956587 },
    { lat: 48.3541102, lng: 20.2960174 },
    { lat: 48.3539753, lng: 20.2966544 },
    { lat: 48.3534274, lng: 20.2971748 },
    { lat: 48.3539888, lng: 20.2994161 },
    { lat: 48.3557382, lng: 20.3020222 },
    { lat: 48.3605632, lng: 20.3066852 },
    { lat: 48.3605806, lng: 20.3069287 },
    { lat: 48.3607839, lng: 20.3068841 },
    { lat: 48.3612037, lng: 20.3067619 },
    { lat: 48.3631529, lng: 20.3061295 },
    { lat: 48.3637574, lng: 20.3055765 },
    { lat: 48.3642158, lng: 20.3049189 },
    { lat: 48.3646444, lng: 20.3035977 },
    { lat: 48.364897, lng: 20.3016296 },
    { lat: 48.3652003, lng: 20.3004413 },
    { lat: 48.3653209, lng: 20.3001597 },
    { lat: 48.3655969, lng: 20.2997633 },
    { lat: 48.3659894, lng: 20.2994344 },
    { lat: 48.3665156, lng: 20.299127 },
    { lat: 48.3709554, lng: 20.2971939 },
    { lat: 48.3715082, lng: 20.2967898 },
    { lat: 48.3717818, lng: 20.2963868 },
    { lat: 48.3721735, lng: 20.2959846 },
    { lat: 48.3728579, lng: 20.2956329 },
    { lat: 48.3738344, lng: 20.2954642 },
    { lat: 48.3740155, lng: 20.2953698 },
    { lat: 48.3742262, lng: 20.2954871 },
    { lat: 48.3743357, lng: 20.2954352 },
    { lat: 48.3756203, lng: 20.2940856 },
    { lat: 48.3770511, lng: 20.2921073 },
    { lat: 48.3773371, lng: 20.2916157 },
    { lat: 48.3787134, lng: 20.2897721 },
    { lat: 48.3791668, lng: 20.28924 },
    { lat: 48.3797745, lng: 20.2877794 },
    { lat: 48.3799885, lng: 20.2873837 },
    { lat: 48.3805351, lng: 20.2876772 },
    { lat: 48.3818452, lng: 20.2881088 },
    { lat: 48.3834282, lng: 20.2885228 },
    { lat: 48.3864587, lng: 20.2877233 },
    { lat: 48.3911265, lng: 20.2848124 },
    { lat: 48.3919102, lng: 20.2842638 },
  ],
  Orávka: [
    { lat: 48.3288386, lng: 20.1734354 },
    { lat: 48.3273104, lng: 20.173885 },
    { lat: 48.3169497, lng: 20.1769478 },
    { lat: 48.3170827, lng: 20.1821413 },
    { lat: 48.3026039, lng: 20.1854808 },
    { lat: 48.3011752, lng: 20.1847965 },
    { lat: 48.299488, lng: 20.1850795 },
    { lat: 48.2992322, lng: 20.1851028 },
    { lat: 48.299195, lng: 20.1850468 },
    { lat: 48.2989993, lng: 20.1852202 },
    { lat: 48.2948024, lng: 20.1864914 },
    { lat: 48.294683, lng: 20.1867138 },
    { lat: 48.2946862, lng: 20.1869597 },
    { lat: 48.2949273, lng: 20.1874594 },
    { lat: 48.2950242, lng: 20.1874902 },
    { lat: 48.295039, lng: 20.1875777 },
    { lat: 48.2950455, lng: 20.187796 },
    { lat: 48.2948878, lng: 20.1879062 },
    { lat: 48.2947877, lng: 20.1880455 },
    { lat: 48.2947824, lng: 20.1882759 },
    { lat: 48.2947794, lng: 20.1887009 },
    { lat: 48.294616, lng: 20.1898714 },
    { lat: 48.2945919, lng: 20.1902792 },
    { lat: 48.2946391, lng: 20.1906343 },
    { lat: 48.2945033, lng: 20.1909523 },
    { lat: 48.2943591, lng: 20.1915076 },
    { lat: 48.2944144, lng: 20.1917604 },
    { lat: 48.2947015, lng: 20.1919264 },
    { lat: 48.2948104, lng: 20.1923349 },
    { lat: 48.2947743, lng: 20.1925568 },
    { lat: 48.2945711, lng: 20.1928253 },
    { lat: 48.2942887, lng: 20.194113 },
    { lat: 48.2942241, lng: 20.1947159 },
    { lat: 48.2942922, lng: 20.1947016 },
    { lat: 48.2945421, lng: 20.1962588 },
    { lat: 48.294538, lng: 20.1970444 },
    { lat: 48.2945825, lng: 20.1974507 },
    { lat: 48.2946926, lng: 20.1975808 },
    { lat: 48.2949655, lng: 20.1977289 },
    { lat: 48.2950775, lng: 20.1980774 },
    { lat: 48.2953906, lng: 20.1982726 },
    { lat: 48.2949912, lng: 20.1986735 },
    { lat: 48.2948107, lng: 20.1993057 },
    { lat: 48.2948865, lng: 20.2004292 },
    { lat: 48.2950187, lng: 20.2009428 },
    { lat: 48.2951697, lng: 20.2012521 },
    { lat: 48.2949525, lng: 20.2021744 },
    { lat: 48.294946, lng: 20.2028474 },
    { lat: 48.2947624, lng: 20.2034349 },
    { lat: 48.2941393, lng: 20.204492 },
    { lat: 48.2942096, lng: 20.2045843 },
    { lat: 48.2944034, lng: 20.2070699 },
    { lat: 48.294202, lng: 20.2091392 },
    { lat: 48.2940373, lng: 20.2097214 },
    { lat: 48.2937611, lng: 20.2114466 },
    { lat: 48.2945905, lng: 20.2135029 },
    { lat: 48.2947048, lng: 20.2139627 },
    { lat: 48.2946371, lng: 20.2142974 },
    { lat: 48.2950024, lng: 20.2146424 },
    { lat: 48.2998513, lng: 20.2119574 },
    { lat: 48.2997619, lng: 20.2112796 },
    { lat: 48.3127318, lng: 20.2040028 },
    { lat: 48.3174879, lng: 20.2012322 },
    { lat: 48.3195417, lng: 20.2055145 },
    { lat: 48.3220866, lng: 20.2071209 },
    { lat: 48.3234341, lng: 20.2057661 },
    { lat: 48.3256346, lng: 20.2036332 },
    { lat: 48.324724, lng: 20.2014444 },
    { lat: 48.3267837, lng: 20.1994266 },
    { lat: 48.3243582, lng: 20.1908631 },
    { lat: 48.3282754, lng: 20.1918592 },
    { lat: 48.3324527, lng: 20.1915745 },
    { lat: 48.3322531, lng: 20.1894218 },
    { lat: 48.3319411, lng: 20.1878669 },
    { lat: 48.3310087, lng: 20.1855898 },
    { lat: 48.3306221, lng: 20.1841904 },
    { lat: 48.3299445, lng: 20.1813437 },
    { lat: 48.329704, lng: 20.1800234 },
    { lat: 48.3288386, lng: 20.1734354 },
  ],
  Kociha: [
    { lat: 48.4836031, lng: 19.9500498 },
    { lat: 48.4836279, lng: 19.9501064 },
    { lat: 48.4839197, lng: 19.9503749 },
    { lat: 48.4842304, lng: 19.9509494 },
    { lat: 48.4852922, lng: 19.9513659 },
    { lat: 48.4854252, lng: 19.9513407 },
    { lat: 48.4854678, lng: 19.9512532 },
    { lat: 48.4854889, lng: 19.9510896 },
    { lat: 48.4854313, lng: 19.9506335 },
    { lat: 48.485496, lng: 19.9504323 },
    { lat: 48.4867217, lng: 19.9506971 },
    { lat: 48.4869221, lng: 19.9505912 },
    { lat: 48.4876639, lng: 19.9505488 },
    { lat: 48.4881051, lng: 19.9501698 },
    { lat: 48.4888273, lng: 19.9505958 },
    { lat: 48.4895708, lng: 19.9465115 },
    { lat: 48.489715, lng: 19.9455267 },
    { lat: 48.4900793, lng: 19.9437118 },
    { lat: 48.4903294, lng: 19.9422523 },
    { lat: 48.4903047, lng: 19.9421712 },
    { lat: 48.4916088, lng: 19.9413689 },
    { lat: 48.4918831, lng: 19.9412534 },
    { lat: 48.492119, lng: 19.938772 },
    { lat: 48.4922246, lng: 19.9381801 },
    { lat: 48.4925069, lng: 19.937454 },
    { lat: 48.4925724, lng: 19.9362974 },
    { lat: 48.4919721, lng: 19.935425 },
    { lat: 48.4916717, lng: 19.9344301 },
    { lat: 48.4917313, lng: 19.9333135 },
    { lat: 48.4918985, lng: 19.9333258 },
    { lat: 48.4920172, lng: 19.9328303 },
    { lat: 48.4925442, lng: 19.9317701 },
    { lat: 48.492489, lng: 19.9309988 },
    { lat: 48.4926002, lng: 19.9292762 },
    { lat: 48.4927812, lng: 19.9284227 },
    { lat: 48.4929063, lng: 19.9269439 },
    { lat: 48.492666, lng: 19.925854 },
    { lat: 48.4930271, lng: 19.9239919 },
    { lat: 48.4934969, lng: 19.922061 },
    { lat: 48.4935541, lng: 19.9221904 },
    { lat: 48.493614, lng: 19.9210616 },
    { lat: 48.493711, lng: 19.9203245 },
    { lat: 48.4981938, lng: 19.9176156 },
    { lat: 48.4980786, lng: 19.9173163 },
    { lat: 48.4980329, lng: 19.9169588 },
    { lat: 48.4954335, lng: 19.8989981 },
    { lat: 48.4951184, lng: 19.8964294 },
    { lat: 48.4949124, lng: 19.894015 },
    { lat: 48.4946297, lng: 19.8921814 },
    { lat: 48.4947873, lng: 19.8918119 },
    { lat: 48.4950418, lng: 19.8914715 },
    { lat: 48.4951767, lng: 19.8910176 },
    { lat: 48.4953281, lng: 19.8907105 },
    { lat: 48.4955552, lng: 19.8905194 },
    { lat: 48.4956873, lng: 19.8903126 },
    { lat: 48.4960706, lng: 19.8893217 },
    { lat: 48.4938451, lng: 19.8857224 },
    { lat: 48.492331, lng: 19.882848 },
    { lat: 48.492058, lng: 19.882977 },
    { lat: 48.491666, lng: 19.883208 },
    { lat: 48.491536, lng: 19.883283 },
    { lat: 48.491414, lng: 19.883273 },
    { lat: 48.491252, lng: 19.883255 },
    { lat: 48.491117, lng: 19.883185 },
    { lat: 48.491014, lng: 19.883133 },
    { lat: 48.490854, lng: 19.883125 },
    { lat: 48.490621, lng: 19.883112 },
    { lat: 48.490488, lng: 19.883106 },
    { lat: 48.49036, lng: 19.883111 },
    { lat: 48.490017, lng: 19.883122 },
    { lat: 48.489717, lng: 19.88313 },
    { lat: 48.489344, lng: 19.88314 },
    { lat: 48.489209, lng: 19.883112 },
    { lat: 48.488844, lng: 19.88304 },
    { lat: 48.488779, lng: 19.883025 },
    { lat: 48.488737, lng: 19.883021 },
    { lat: 48.488627, lng: 19.883025 },
    { lat: 48.488529, lng: 19.883031 },
    { lat: 48.488379, lng: 19.883029 },
    { lat: 48.488175, lng: 19.883022 },
    { lat: 48.48802, lng: 19.882957 },
    { lat: 48.48779, lng: 19.882858 },
    { lat: 48.48772, lng: 19.882811 },
    { lat: 48.487572, lng: 19.882703 },
    { lat: 48.487479, lng: 19.882635 },
    { lat: 48.487314, lng: 19.882525 },
    { lat: 48.487181, lng: 19.882436 },
    { lat: 48.487134, lng: 19.88259 },
    { lat: 48.487072, lng: 19.882813 },
    { lat: 48.486977, lng: 19.883137 },
    { lat: 48.486922, lng: 19.883314 },
    { lat: 48.486926, lng: 19.883692 },
    { lat: 48.486931, lng: 19.884113 },
    { lat: 48.486936, lng: 19.884308 },
    { lat: 48.48685, lng: 19.884493 },
    { lat: 48.486739, lng: 19.884738 },
    { lat: 48.486612, lng: 19.884854 },
    { lat: 48.486533, lng: 19.884924 },
    { lat: 48.486449, lng: 19.884909 },
    { lat: 48.486262, lng: 19.884889 },
    { lat: 48.486031, lng: 19.884861 },
    { lat: 48.485868, lng: 19.884688 },
    { lat: 48.485778, lng: 19.884587 },
    { lat: 48.485641, lng: 19.884451 },
    { lat: 48.485564, lng: 19.884412 },
    { lat: 48.485377, lng: 19.884318 },
    { lat: 48.485249, lng: 19.884253 },
    { lat: 48.485134, lng: 19.884325 },
    { lat: 48.485058, lng: 19.884372 },
    { lat: 48.484886, lng: 19.884482 },
    { lat: 48.484809, lng: 19.884529 },
    { lat: 48.484738, lng: 19.884648 },
    { lat: 48.484604, lng: 19.88487 },
    { lat: 48.484537, lng: 19.884988 },
    { lat: 48.484444, lng: 19.885074 },
    { lat: 48.484289, lng: 19.885217 },
    { lat: 48.484212, lng: 19.885293 },
    { lat: 48.484229, lng: 19.885748 },
    { lat: 48.484242, lng: 19.886038 },
    { lat: 48.484231, lng: 19.886166 },
    { lat: 48.484192, lng: 19.886577 },
    { lat: 48.484164, lng: 19.886894 },
    { lat: 48.484116, lng: 19.887433 },
    { lat: 48.484097, lng: 19.887623 },
    { lat: 48.484054, lng: 19.887844 },
    { lat: 48.483978, lng: 19.888231 },
    { lat: 48.483899, lng: 19.888641 },
    { lat: 48.483845, lng: 19.888909 },
    { lat: 48.483775, lng: 19.889247 },
    { lat: 48.483721, lng: 19.889497 },
    { lat: 48.483706, lng: 19.889652 },
    { lat: 48.483691, lng: 19.88981 },
    { lat: 48.483654, lng: 19.889914 },
    { lat: 48.483604, lng: 19.890041 },
    { lat: 48.483456, lng: 19.890426 },
    { lat: 48.483294, lng: 19.890848 },
    { lat: 48.483197, lng: 19.891103 },
    { lat: 48.483085, lng: 19.891404 },
    { lat: 48.482933, lng: 19.891802 },
    { lat: 48.482875, lng: 19.891952 },
    { lat: 48.482767, lng: 19.892169 },
    { lat: 48.482598, lng: 19.892501 },
    { lat: 48.482406, lng: 19.892875 },
    { lat: 48.482327, lng: 19.893056 },
    { lat: 48.482221, lng: 19.893289 },
    { lat: 48.482054, lng: 19.893653 },
    { lat: 48.481876, lng: 19.894048 },
    { lat: 48.481772, lng: 19.894277 },
    { lat: 48.481658, lng: 19.894528 },
    { lat: 48.481497, lng: 19.894873 },
    { lat: 48.481381, lng: 19.895116 },
    { lat: 48.481362, lng: 19.895144 },
    { lat: 48.481313, lng: 19.895189 },
    { lat: 48.4740451, lng: 19.9085482 },
    { lat: 48.4739489, lng: 19.9088256 },
    { lat: 48.4743045, lng: 19.9087624 },
    { lat: 48.4743356, lng: 19.9089934 },
    { lat: 48.4744957, lng: 19.9088341 },
    { lat: 48.4748187, lng: 19.9089342 },
    { lat: 48.4722985, lng: 19.9136197 },
    { lat: 48.4731988, lng: 19.914412 },
    { lat: 48.4706624, lng: 19.9206458 },
    { lat: 48.4696433, lng: 19.9206019 },
    { lat: 48.4680961, lng: 19.9208837 },
    { lat: 48.466786, lng: 19.9208842 },
    { lat: 48.4657681, lng: 19.9211603 },
    { lat: 48.4638286, lng: 19.9351157 },
    { lat: 48.4731124, lng: 19.937686 },
    { lat: 48.4732519, lng: 19.9379193 },
    { lat: 48.4732743, lng: 19.9380744 },
    { lat: 48.4731649, lng: 19.938233 },
    { lat: 48.47326, lng: 19.9384816 },
    { lat: 48.4732137, lng: 19.938687 },
    { lat: 48.4732134, lng: 19.9393445 },
    { lat: 48.4732615, lng: 19.9395497 },
    { lat: 48.473247, lng: 19.9396863 },
    { lat: 48.4733465, lng: 19.9400382 },
    { lat: 48.4732794, lng: 19.9414799 },
    { lat: 48.473092, lng: 19.9419737 },
    { lat: 48.4729494, lng: 19.9421411 },
    { lat: 48.4727934, lng: 19.9426567 },
    { lat: 48.4726974, lng: 19.9431029 },
    { lat: 48.4726847, lng: 19.9434946 },
    { lat: 48.472555, lng: 19.9440751 },
    { lat: 48.4725699, lng: 19.9443467 },
    { lat: 48.4724393, lng: 19.9446912 },
    { lat: 48.4725152, lng: 19.9448312 },
    { lat: 48.4725037, lng: 19.9450386 },
    { lat: 48.4723617, lng: 19.9456746 },
    { lat: 48.4721732, lng: 19.9460896 },
    { lat: 48.4720957, lng: 19.9461488 },
    { lat: 48.4720312, lng: 19.9463805 },
    { lat: 48.471916, lng: 19.9464512 },
    { lat: 48.4720961, lng: 19.9470363 },
    { lat: 48.4719256, lng: 19.9473672 },
    { lat: 48.4718627, lng: 19.947397 },
    { lat: 48.4717547, lng: 19.9476183 },
    { lat: 48.4716461, lng: 19.9476848 },
    { lat: 48.471443, lng: 19.9480497 },
    { lat: 48.4713562, lng: 19.9483676 },
    { lat: 48.4713692, lng: 19.9484842 },
    { lat: 48.4711576, lng: 19.9491276 },
    { lat: 48.4710458, lng: 19.949701 },
    { lat: 48.4710449, lng: 19.9499481 },
    { lat: 48.4701682, lng: 19.9502598 },
    { lat: 48.4689948, lng: 19.9505613 },
    { lat: 48.4686668, lng: 19.9507145 },
    { lat: 48.4680801, lng: 19.9511457 },
    { lat: 48.4678076, lng: 19.9511999 },
    { lat: 48.4677684, lng: 19.951155 },
    { lat: 48.4680124, lng: 19.9528156 },
    { lat: 48.4683909, lng: 19.9529742 },
    { lat: 48.4684572, lng: 19.9530789 },
    { lat: 48.4713181, lng: 19.9543175 },
    { lat: 48.4713629, lng: 19.954042 },
    { lat: 48.4714816, lng: 19.9538428 },
    { lat: 48.4714466, lng: 19.9537336 },
    { lat: 48.4712868, lng: 19.953684 },
    { lat: 48.4713139, lng: 19.9533654 },
    { lat: 48.4714234, lng: 19.9532658 },
    { lat: 48.471494, lng: 19.9530421 },
    { lat: 48.4713809, lng: 19.9525801 },
    { lat: 48.4718646, lng: 19.9522756 },
    { lat: 48.4720078, lng: 19.9521178 },
    { lat: 48.4721686, lng: 19.9521361 },
    { lat: 48.4722201, lng: 19.952609 },
    { lat: 48.472345, lng: 19.9526815 },
    { lat: 48.472399, lng: 19.9526312 },
    { lat: 48.4724139, lng: 19.952503 },
    { lat: 48.4725951, lng: 19.9523667 },
    { lat: 48.4727118, lng: 19.9519015 },
    { lat: 48.4734195, lng: 19.9514849 },
    { lat: 48.474207, lng: 19.9513799 },
    { lat: 48.4745158, lng: 19.9515423 },
    { lat: 48.4747129, lng: 19.9515138 },
    { lat: 48.4747506, lng: 19.9513682 },
    { lat: 48.4751884, lng: 19.9516482 },
    { lat: 48.4756773, lng: 19.9518661 },
    { lat: 48.4758049, lng: 19.9525096 },
    { lat: 48.4760172, lng: 19.9528113 },
    { lat: 48.4765507, lng: 19.9528286 },
    { lat: 48.4766475, lng: 19.952956 },
    { lat: 48.4770768, lng: 19.9529883 },
    { lat: 48.4773474, lng: 19.9531012 },
    { lat: 48.4774763, lng: 19.9532195 },
    { lat: 48.4776945, lng: 19.9530757 },
    { lat: 48.4788934, lng: 19.953059 },
    { lat: 48.4790585, lng: 19.9530219 },
    { lat: 48.4791078, lng: 19.9529032 },
    { lat: 48.4791708, lng: 19.9524032 },
    { lat: 48.4793262, lng: 19.9518146 },
    { lat: 48.47917, lng: 19.9505467 },
    { lat: 48.4793733, lng: 19.9499758 },
    { lat: 48.479602, lng: 19.9497625 },
    { lat: 48.4796732, lng: 19.9497447 },
    { lat: 48.47975, lng: 19.9499074 },
    { lat: 48.4797733, lng: 19.9504853 },
    { lat: 48.4798894, lng: 19.9508285 },
    { lat: 48.4800043, lng: 19.9508544 },
    { lat: 48.4802879, lng: 19.9504512 },
    { lat: 48.4805535, lng: 19.9502073 },
    { lat: 48.4811033, lng: 19.9499195 },
    { lat: 48.4811913, lng: 19.9500278 },
    { lat: 48.4817524, lng: 19.9502122 },
    { lat: 48.4818077, lng: 19.9504299 },
    { lat: 48.4821901, lng: 19.9506534 },
    { lat: 48.4823259, lng: 19.9508531 },
    { lat: 48.4824442, lng: 19.9508819 },
    { lat: 48.4825986, lng: 19.9508273 },
    { lat: 48.4826255, lng: 19.9502438 },
    { lat: 48.4829602, lng: 19.950059 },
    { lat: 48.4830812, lng: 19.949807 },
    { lat: 48.4831927, lng: 19.9491401 },
    { lat: 48.4832671, lng: 19.949009 },
    { lat: 48.4833531, lng: 19.9491776 },
    { lat: 48.4834395, lng: 19.9496991 },
    { lat: 48.4836031, lng: 19.9500498 },
  ],
  TeplýVrch: [
    { lat: 48.4844358, lng: 20.1090712 },
    { lat: 48.4838323, lng: 20.1085265 },
    { lat: 48.4831801, lng: 20.1079498 },
    { lat: 48.4825608, lng: 20.1077091 },
    { lat: 48.4823483, lng: 20.1074146 },
    { lat: 48.4820493, lng: 20.1065531 },
    { lat: 48.481843, lng: 20.1064089 },
    { lat: 48.4806801, lng: 20.1066458 },
    { lat: 48.4795071, lng: 20.1037007 },
    { lat: 48.4783649, lng: 20.1009902 },
    { lat: 48.4780844, lng: 20.1005527 },
    { lat: 48.4775363, lng: 20.099162 },
    { lat: 48.4773761, lng: 20.0976273 },
    { lat: 48.4773894, lng: 20.0975153 },
    { lat: 48.4776589, lng: 20.0975996 },
    { lat: 48.4776615, lng: 20.0975014 },
    { lat: 48.4771492, lng: 20.0938652 },
    { lat: 48.4770667, lng: 20.0938609 },
    { lat: 48.4768899, lng: 20.0936721 },
    { lat: 48.4766512, lng: 20.0937554 },
    { lat: 48.4765983, lng: 20.0936702 },
    { lat: 48.4768, lng: 20.0934036 },
    { lat: 48.4769849, lng: 20.0933481 },
    { lat: 48.47702, lng: 20.0932731 },
    { lat: 48.4769376, lng: 20.0929964 },
    { lat: 48.4768326, lng: 20.0929909 },
    { lat: 48.4767475, lng: 20.0928625 },
    { lat: 48.4767166, lng: 20.0927152 },
    { lat: 48.4767523, lng: 20.0922692 },
    { lat: 48.4768803, lng: 20.0919889 },
    { lat: 48.4769992, lng: 20.0920581 },
    { lat: 48.4770543, lng: 20.0921633 },
    { lat: 48.4772366, lng: 20.0920793 },
    { lat: 48.4772664, lng: 20.0916789 },
    { lat: 48.4772002, lng: 20.0913847 },
    { lat: 48.4772395, lng: 20.089665 },
    { lat: 48.4774954, lng: 20.0894192 },
    { lat: 48.4791036, lng: 20.0883512 },
    { lat: 48.4789237, lng: 20.0884131 },
    { lat: 48.4787208, lng: 20.0881061 },
    { lat: 48.4786493, lng: 20.0877911 },
    { lat: 48.4788641, lng: 20.0869119 },
    { lat: 48.4791142, lng: 20.0862041 },
    { lat: 48.4789933, lng: 20.0851568 },
    { lat: 48.47886, lng: 20.0832365 },
    { lat: 48.4791852, lng: 20.0814815 },
    { lat: 48.4793983, lng: 20.0810224 },
    { lat: 48.4796756, lng: 20.0805918 },
    { lat: 48.4812617, lng: 20.0793401 },
    { lat: 48.4816497, lng: 20.0791388 },
    { lat: 48.4818866, lng: 20.0789271 },
    { lat: 48.4821838, lng: 20.0788116 },
    { lat: 48.4824859, lng: 20.0782472 },
    { lat: 48.4821544, lng: 20.0775579 },
    { lat: 48.4812172, lng: 20.0774751 },
    { lat: 48.48124, lng: 20.0772904 },
    { lat: 48.4821118, lng: 20.0753013 },
    { lat: 48.4816759, lng: 20.0745653 },
    { lat: 48.4816813, lng: 20.0740674 },
    { lat: 48.4814986, lng: 20.0735998 },
    { lat: 48.480185, lng: 20.0742776 },
    { lat: 48.4799187, lng: 20.074293 },
    { lat: 48.4795777, lng: 20.074655 },
    { lat: 48.4791612, lng: 20.0749592 },
    { lat: 48.4786735, lng: 20.0769499 },
    { lat: 48.4787696, lng: 20.0784025 },
    { lat: 48.4787407, lng: 20.0790972 },
    { lat: 48.4781486, lng: 20.0801114 },
    { lat: 48.4781331, lng: 20.0805002 },
    { lat: 48.4779485, lng: 20.0808312 },
    { lat: 48.4777173, lng: 20.0808795 },
    { lat: 48.4775184, lng: 20.0812435 },
    { lat: 48.4774448, lng: 20.0815056 },
    { lat: 48.4771558, lng: 20.0821205 },
    { lat: 48.4771732, lng: 20.0832233 },
    { lat: 48.477104, lng: 20.0839479 },
    { lat: 48.4767994, lng: 20.084288 },
    { lat: 48.4764114, lng: 20.0843065 },
    { lat: 48.4764428, lng: 20.0851761 },
    { lat: 48.4760094, lng: 20.0845513 },
    { lat: 48.474692, lng: 20.0828731 },
    { lat: 48.473513, lng: 20.0811941 },
    { lat: 48.4735871, lng: 20.0809649 },
    { lat: 48.4728245, lng: 20.0802286 },
    { lat: 48.4724004, lng: 20.0795615 },
    { lat: 48.4723024, lng: 20.079424 },
    { lat: 48.4721457, lng: 20.0799147 },
    { lat: 48.4718452, lng: 20.0805207 },
    { lat: 48.4696962, lng: 20.0840941 },
    { lat: 48.4683635, lng: 20.0832694 },
    { lat: 48.4657225, lng: 20.0825449 },
    { lat: 48.464637, lng: 20.082527 },
    { lat: 48.4637728, lng: 20.0825203 },
    { lat: 48.4639652, lng: 20.0878371 },
    { lat: 48.4639368, lng: 20.0883522 },
    { lat: 48.4630165, lng: 20.0946616 },
    { lat: 48.4628761, lng: 20.0953543 },
    { lat: 48.4627053, lng: 20.0958564 },
    { lat: 48.4604968, lng: 20.1004043 },
    { lat: 48.4603227, lng: 20.1006647 },
    { lat: 48.460138, lng: 20.1010456 },
    { lat: 48.4602389, lng: 20.1012257 },
    { lat: 48.4607765, lng: 20.1010686 },
    { lat: 48.4622228, lng: 20.1047975 },
    { lat: 48.4624783, lng: 20.1056947 },
    { lat: 48.4625444, lng: 20.106097 },
    { lat: 48.4626845, lng: 20.1084398 },
    { lat: 48.4626326, lng: 20.1091739 },
    { lat: 48.4626309, lng: 20.1108925 },
    { lat: 48.4627689, lng: 20.1118915 },
    { lat: 48.4631052, lng: 20.1128682 },
    { lat: 48.4630799, lng: 20.1131019 },
    { lat: 48.463214, lng: 20.1133262 },
    { lat: 48.4632631, lng: 20.1135864 },
    { lat: 48.4633558, lng: 20.1137627 },
    { lat: 48.4635344, lng: 20.1145159 },
    { lat: 48.4642931, lng: 20.1155234 },
    { lat: 48.4643979, lng: 20.1157955 },
    { lat: 48.4647451, lng: 20.115945 },
    { lat: 48.4655845, lng: 20.1161128 },
    { lat: 48.4656853, lng: 20.1164314 },
    { lat: 48.4661911, lng: 20.1172022 },
    { lat: 48.4659235, lng: 20.1179007 },
    { lat: 48.4661804, lng: 20.1183684 },
    { lat: 48.4685631, lng: 20.1195315 },
    { lat: 48.4690577, lng: 20.1190974 },
    { lat: 48.4700604, lng: 20.1179191 },
    { lat: 48.4722651, lng: 20.1191385 },
    { lat: 48.4735231, lng: 20.1204556 },
    { lat: 48.4736245, lng: 20.1207607 },
    { lat: 48.4749547, lng: 20.1216925 },
    { lat: 48.4780832, lng: 20.1226421 },
    { lat: 48.4780654, lng: 20.1232795 },
    { lat: 48.4766839, lng: 20.1239527 },
    { lat: 48.4762029, lng: 20.1271218 },
    { lat: 48.47755, lng: 20.1267309 },
    { lat: 48.4779586, lng: 20.1269217 },
    { lat: 48.4784611, lng: 20.1273669 },
    { lat: 48.4787689, lng: 20.1277321 },
    { lat: 48.4794211, lng: 20.127981 },
    { lat: 48.4803812, lng: 20.128129 },
    { lat: 48.4811544, lng: 20.1277834 },
    { lat: 48.4812257, lng: 20.1276781 },
    { lat: 48.4816911, lng: 20.1269964 },
    { lat: 48.4821304, lng: 20.126087 },
    { lat: 48.4826332, lng: 20.1253034 },
    { lat: 48.4833282, lng: 20.1230176 },
    { lat: 48.4838026, lng: 20.1222505 },
    { lat: 48.4840852, lng: 20.1221434 },
    { lat: 48.4848123, lng: 20.1205323 },
    { lat: 48.4850916, lng: 20.1195151 },
    { lat: 48.4855409, lng: 20.1182738 },
    { lat: 48.4857092, lng: 20.1184747 },
    { lat: 48.486014, lng: 20.1177524 },
    { lat: 48.4861569, lng: 20.1172537 },
    { lat: 48.4862712, lng: 20.1164705 },
    { lat: 48.4870666, lng: 20.1143069 },
    { lat: 48.4867418, lng: 20.113144 },
    { lat: 48.4864412, lng: 20.1130881 },
    { lat: 48.4851634, lng: 20.111266 },
    { lat: 48.4853808, lng: 20.1109611 },
    { lat: 48.4851146, lng: 20.1104862 },
    { lat: 48.4844859, lng: 20.109631 },
    { lat: 48.4844358, lng: 20.1090712 },
  ],
  Dubno: [
    { lat: 48.2110757, lng: 20.0134459 },
    { lat: 48.211034, lng: 20.0133972 },
    { lat: 48.2104925, lng: 20.0130845 },
    { lat: 48.2099079, lng: 20.0120388 },
    { lat: 48.2091451, lng: 20.0123056 },
    { lat: 48.2090132, lng: 20.0114433 },
    { lat: 48.209036, lng: 20.010566 },
    { lat: 48.2087114, lng: 20.0083484 },
    { lat: 48.2083471, lng: 20.0074275 },
    { lat: 48.2080798, lng: 20.0069671 },
    { lat: 48.2079877, lng: 20.0066523 },
    { lat: 48.2078838, lng: 20.0058177 },
    { lat: 48.2077735, lng: 20.0054475 },
    { lat: 48.2075954, lng: 20.0051768 },
    { lat: 48.2072861, lng: 20.0049436 },
    { lat: 48.2070359, lng: 20.004649 },
    { lat: 48.2071366, lng: 20.0047907 },
    { lat: 48.2065659, lng: 20.0048333 },
    { lat: 48.2058841, lng: 20.0056268 },
    { lat: 48.2055031, lng: 20.0053503 },
    { lat: 48.2049471, lng: 20.0050966 },
    { lat: 48.2045657, lng: 20.005003 },
    { lat: 48.2041274, lng: 20.004949 },
    { lat: 48.2035131, lng: 20.0051253 },
    { lat: 48.2031407, lng: 20.0054578 },
    { lat: 48.201622, lng: 20.007211 },
    { lat: 48.2014016, lng: 20.0070616 },
    { lat: 48.201168, lng: 20.0074003 },
    { lat: 48.2001664, lng: 20.0091862 },
    { lat: 48.1993502, lng: 20.0083426 },
    { lat: 48.1979888, lng: 20.0073383 },
    { lat: 48.1978887, lng: 20.0071324 },
    { lat: 48.1975525, lng: 20.0057313 },
    { lat: 48.1976752, lng: 20.0043134 },
    { lat: 48.1976357, lng: 20.0035354 },
    { lat: 48.1973476, lng: 20.0020511 },
    { lat: 48.1971384, lng: 20.000512 },
    { lat: 48.1961412, lng: 19.996453 },
    { lat: 48.1951821, lng: 19.9970002 },
    { lat: 48.1948075, lng: 19.9970112 },
    { lat: 48.1935058, lng: 19.9972893 },
    { lat: 48.1932119, lng: 19.9963896 },
    { lat: 48.1932451, lng: 19.9943769 },
    { lat: 48.1925892, lng: 19.9943415 },
    { lat: 48.1909945, lng: 19.9946716 },
    { lat: 48.1902976, lng: 19.9949232 },
    { lat: 48.1896234, lng: 19.9950403 },
    { lat: 48.1888396, lng: 19.9949418 },
    { lat: 48.188224, lng: 19.994636 },
    { lat: 48.1879943, lng: 19.9941565 },
    { lat: 48.1877217, lng: 19.9940516 },
    { lat: 48.1875325, lng: 19.9938687 },
    { lat: 48.187769, lng: 19.9917081 },
    { lat: 48.187824, lng: 19.9914131 },
    { lat: 48.1878977, lng: 19.9914474 },
    { lat: 48.1879341, lng: 19.9910599 },
    { lat: 48.1876063, lng: 19.990872 },
    { lat: 48.1877, lng: 19.9903968 },
    { lat: 48.1874856, lng: 19.9897771 },
    { lat: 48.1872957, lng: 19.9896281 },
    { lat: 48.1872589, lng: 19.9895059 },
    { lat: 48.1867184, lng: 19.9892192 },
    { lat: 48.1863404, lng: 19.9886715 },
    { lat: 48.1866163, lng: 19.9870978 },
    { lat: 48.1860399, lng: 19.986871 },
    { lat: 48.1858087, lng: 19.9865664 },
    { lat: 48.1856838, lng: 19.9858524 },
    { lat: 48.1856485, lng: 19.9848091 },
    { lat: 48.1854182, lng: 19.9845041 },
    { lat: 48.1840659, lng: 19.9839598 },
    { lat: 48.18283, lng: 19.983216 },
    { lat: 48.1797297, lng: 19.9882102 },
    { lat: 48.1794207, lng: 19.9884389 },
    { lat: 48.1783509, lng: 19.9889803 },
    { lat: 48.1789702, lng: 19.9915819 },
    { lat: 48.179099, lng: 19.9926064 },
    { lat: 48.179208, lng: 19.9926776 },
    { lat: 48.1794797, lng: 19.9928164 },
    { lat: 48.18041, lng: 19.9916659 },
    { lat: 48.1814622, lng: 19.989589 },
    { lat: 48.1817118, lng: 19.9893123 },
    { lat: 48.1821293, lng: 19.9890652 },
    { lat: 48.1823642, lng: 19.9892392 },
    { lat: 48.1821112, lng: 19.9898361 },
    { lat: 48.1819921, lng: 19.9903454 },
    { lat: 48.1825694, lng: 19.9914754 },
    { lat: 48.1828006, lng: 19.9918573 },
    { lat: 48.1832553, lng: 19.9924331 },
    { lat: 48.1835958, lng: 19.9932392 },
    { lat: 48.1837222, lng: 19.9934045 },
    { lat: 48.1846941, lng: 19.9936334 },
    { lat: 48.1863487, lng: 19.9938705 },
    { lat: 48.186155, lng: 19.9950721 },
    { lat: 48.1858255, lng: 19.9963896 },
    { lat: 48.1854045, lng: 19.9969299 },
    { lat: 48.185676, lng: 19.998131 },
    { lat: 48.1856155, lng: 20.0000009 },
    { lat: 48.1854112, lng: 20.0013513 },
    { lat: 48.1854393, lng: 20.0016484 },
    { lat: 48.1856938, lng: 20.0026332 },
    { lat: 48.1861117, lng: 20.0037523 },
    { lat: 48.186507, lng: 20.0040408 },
    { lat: 48.1863236, lng: 20.0043649 },
    { lat: 48.1863719, lng: 20.0044701 },
    { lat: 48.1863647, lng: 20.0049138 },
    { lat: 48.1861059, lng: 20.0051363 },
    { lat: 48.185967, lng: 20.0053679 },
    { lat: 48.1860029, lng: 20.0059244 },
    { lat: 48.1858864, lng: 20.0061267 },
    { lat: 48.1857228, lng: 20.0060645 },
    { lat: 48.1856091, lng: 20.0061733 },
    { lat: 48.185843, lng: 20.0063793 },
    { lat: 48.1857783, lng: 20.0068778 },
    { lat: 48.1858088, lng: 20.0072537 },
    { lat: 48.1866149, lng: 20.007829 },
    { lat: 48.1869812, lng: 20.0080183 },
    { lat: 48.1874145, lng: 20.0081454 },
    { lat: 48.1874713, lng: 20.0111498 },
    { lat: 48.1877097, lng: 20.0111884 },
    { lat: 48.1876161, lng: 20.011964 },
    { lat: 48.188237, lng: 20.0122631 },
    { lat: 48.1883337, lng: 20.012368 },
    { lat: 48.1886022, lng: 20.0124543 },
    { lat: 48.1884917, lng: 20.0136798 },
    { lat: 48.1892813, lng: 20.0140569 },
    { lat: 48.1905788, lng: 20.0143728 },
    { lat: 48.1910523, lng: 20.0144055 },
    { lat: 48.1911289, lng: 20.0143542 },
    { lat: 48.1912324, lng: 20.0141093 },
    { lat: 48.1917423, lng: 20.0139416 },
    { lat: 48.1920799, lng: 20.0133707 },
    { lat: 48.1926519, lng: 20.0130972 },
    { lat: 48.1930966, lng: 20.0130119 },
    { lat: 48.1937918, lng: 20.0130403 },
    { lat: 48.1951615, lng: 20.0139461 },
    { lat: 48.196161, lng: 20.0150237 },
    { lat: 48.196239, lng: 20.0157829 },
    { lat: 48.1964815, lng: 20.0172889 },
    { lat: 48.1969461, lng: 20.0186348 },
    { lat: 48.1986614, lng: 20.0197272 },
    { lat: 48.1994717, lng: 20.0199779 },
    { lat: 48.1999153, lng: 20.0201869 },
    { lat: 48.2002722, lng: 20.0206638 },
    { lat: 48.2006945, lng: 20.0213836 },
    { lat: 48.2011683, lng: 20.0225802 },
    { lat: 48.2012245, lng: 20.0231932 },
    { lat: 48.2015086, lng: 20.0237353 },
    { lat: 48.203784, lng: 20.0208405 },
    { lat: 48.2050934, lng: 20.0190265 },
    { lat: 48.2058505, lng: 20.0191633 },
    { lat: 48.2073963, lng: 20.0196001 },
    { lat: 48.2092149, lng: 20.0199318 },
    { lat: 48.2107474, lng: 20.0202916 },
    { lat: 48.2107437, lng: 20.0192391 },
    { lat: 48.2107947, lng: 20.0186039 },
    { lat: 48.2112876, lng: 20.0149527 },
    { lat: 48.2110757, lng: 20.0134459 },
  ],
  Sútor: [
    { lat: 48.3826729, lng: 20.0865509 },
    { lat: 48.3822436, lng: 20.0862195 },
    { lat: 48.3819382, lng: 20.0858107 },
    { lat: 48.3811601, lng: 20.0851614 },
    { lat: 48.3803978, lng: 20.085085 },
    { lat: 48.3800518, lng: 20.0851378 },
    { lat: 48.3791146, lng: 20.0845923 },
    { lat: 48.3787429, lng: 20.0846163 },
    { lat: 48.3782379, lng: 20.0843909 },
    { lat: 48.3779865, lng: 20.0844699 },
    { lat: 48.3775269, lng: 20.0847955 },
    { lat: 48.3768115, lng: 20.0850917 },
    { lat: 48.3761559, lng: 20.085551 },
    { lat: 48.3756028, lng: 20.0861495 },
    { lat: 48.3751751, lng: 20.086487 },
    { lat: 48.3744879, lng: 20.0867805 },
    { lat: 48.3733814, lng: 20.0873753 },
    { lat: 48.3728536, lng: 20.0873883 },
    { lat: 48.3713457, lng: 20.0880193 },
    { lat: 48.3709781, lng: 20.0881194 },
    { lat: 48.3703135, lng: 20.0884757 },
    { lat: 48.3693475, lng: 20.0890842 },
    { lat: 48.3681688, lng: 20.0902743 },
    { lat: 48.3672125, lng: 20.0908989 },
    { lat: 48.3659029, lng: 20.0915106 },
    { lat: 48.3654844, lng: 20.0916065 },
    { lat: 48.3637023, lng: 20.0925327 },
    { lat: 48.3634271, lng: 20.092955 },
    { lat: 48.3629383, lng: 20.0939699 },
    { lat: 48.3621328, lng: 20.096149 },
    { lat: 48.361777, lng: 20.0972461 },
    { lat: 48.3613347, lng: 20.0980487 },
    { lat: 48.3602146, lng: 20.0986344 },
    { lat: 48.3598757, lng: 20.099049 },
    { lat: 48.3591897, lng: 20.1002241 },
    { lat: 48.3591528, lng: 20.1003947 },
    { lat: 48.3589973, lng: 20.1006372 },
    { lat: 48.3587673, lng: 20.100881 },
    { lat: 48.3581767, lng: 20.101223 },
    { lat: 48.3573073, lng: 20.101365 },
    { lat: 48.3566451, lng: 20.1013666 },
    { lat: 48.3560022, lng: 20.1016996 },
    { lat: 48.3558105, lng: 20.1022433 },
    { lat: 48.3553225, lng: 20.103771 },
    { lat: 48.3553053, lng: 20.1040064 },
    { lat: 48.3556298, lng: 20.1059521 },
    { lat: 48.3550779, lng: 20.1074028 },
    { lat: 48.3547075, lng: 20.1081981 },
    { lat: 48.35431, lng: 20.1079853 },
    { lat: 48.3537449, lng: 20.1093072 },
    { lat: 48.3531521, lng: 20.1094078 },
    { lat: 48.3523818, lng: 20.1099565 },
    { lat: 48.3520636, lng: 20.110083 },
    { lat: 48.3512366, lng: 20.1107612 },
    { lat: 48.3505966, lng: 20.111085 },
    { lat: 48.3505286, lng: 20.1112113 },
    { lat: 48.3504213, lng: 20.112847 },
    { lat: 48.3502653, lng: 20.1132453 },
    { lat: 48.3492686, lng: 20.1138481 },
    { lat: 48.3487343, lng: 20.1144407 },
    { lat: 48.3478519, lng: 20.1149508 },
    { lat: 48.3473086, lng: 20.1153817 },
    { lat: 48.3465653, lng: 20.1157939 },
    { lat: 48.3460581, lng: 20.1163914 },
    { lat: 48.3456047, lng: 20.1167612 },
    { lat: 48.3453484, lng: 20.1174282 },
    { lat: 48.3451398, lng: 20.1177265 },
    { lat: 48.3447794, lng: 20.1179751 },
    { lat: 48.344398, lng: 20.1183432 },
    { lat: 48.3437845, lng: 20.1194221 },
    { lat: 48.3431224, lng: 20.1202204 },
    { lat: 48.3417352, lng: 20.1215463 },
    { lat: 48.3394577, lng: 20.1239902 },
    { lat: 48.3374063, lng: 20.1260193 },
    { lat: 48.3371032, lng: 20.1257131 },
    { lat: 48.3343803, lng: 20.1287513 },
    { lat: 48.3339301, lng: 20.1293075 },
    { lat: 48.3335774, lng: 20.1298802 },
    { lat: 48.3332419, lng: 20.1297448 },
    { lat: 48.332963, lng: 20.1300873 },
    { lat: 48.3325146, lng: 20.1297541 },
    { lat: 48.3323484, lng: 20.1294947 },
    { lat: 48.3316534, lng: 20.129076 },
    { lat: 48.3315448, lng: 20.1293136 },
    { lat: 48.3307048, lng: 20.1306136 },
    { lat: 48.3304824, lng: 20.1310978 },
    { lat: 48.329994, lng: 20.131764 },
    { lat: 48.3294695, lng: 20.1326558 },
    { lat: 48.3311592, lng: 20.1357365 },
    { lat: 48.3322473, lng: 20.1381602 },
    { lat: 48.3331074, lng: 20.1396085 },
    { lat: 48.3337029, lng: 20.1418291 },
    { lat: 48.3338443, lng: 20.1429496 },
    { lat: 48.334714, lng: 20.1446056 },
    { lat: 48.3349359, lng: 20.1454047 },
    { lat: 48.3353362, lng: 20.1443987 },
    { lat: 48.3362433, lng: 20.1448446 },
    { lat: 48.3372415, lng: 20.146341 },
    { lat: 48.3376227, lng: 20.1475092 },
    { lat: 48.337709, lng: 20.1480504 },
    { lat: 48.3376676, lng: 20.148587 },
    { lat: 48.3378137, lng: 20.151669 },
    { lat: 48.3380331, lng: 20.1516075 },
    { lat: 48.3389262, lng: 20.1509141 },
    { lat: 48.3397701, lng: 20.1507252 },
    { lat: 48.3418071, lng: 20.1507683 },
    { lat: 48.3429899, lng: 20.1513782 },
    { lat: 48.3440107, lng: 20.1514406 },
    { lat: 48.3443997, lng: 20.1517016 },
    { lat: 48.3449183, lng: 20.1524022 },
    { lat: 48.3461684, lng: 20.153418 },
    { lat: 48.346527, lng: 20.1532924 },
    { lat: 48.3471808, lng: 20.1536916 },
    { lat: 48.3471988, lng: 20.1536057 },
    { lat: 48.347497, lng: 20.1527983 },
    { lat: 48.3481264, lng: 20.1519633 },
    { lat: 48.3486303, lng: 20.1507596 },
    { lat: 48.3489981, lng: 20.1501713 },
    { lat: 48.3495093, lng: 20.1496166 },
    { lat: 48.3498846, lng: 20.1493449 },
    { lat: 48.3502446, lng: 20.1491674 },
    { lat: 48.3507006, lng: 20.1492697 },
    { lat: 48.3510957, lng: 20.1491555 },
    { lat: 48.3512843, lng: 20.149028 },
    { lat: 48.3517547, lng: 20.1476406 },
    { lat: 48.3525414, lng: 20.1465195 },
    { lat: 48.3528907, lng: 20.1462756 },
    { lat: 48.3541715, lng: 20.1457578 },
    { lat: 48.3546798, lng: 20.1454607 },
    { lat: 48.3561409, lng: 20.1443072 },
    { lat: 48.3564935, lng: 20.1438679 },
    { lat: 48.3572557, lng: 20.143557 },
    { lat: 48.3584691, lng: 20.1428175 },
    { lat: 48.3587294, lng: 20.1427928 },
    { lat: 48.3591249, lng: 20.1426073 },
    { lat: 48.3596765, lng: 20.1415207 },
    { lat: 48.3601914, lng: 20.1402312 },
    { lat: 48.3605396, lng: 20.1397356 },
    { lat: 48.3610902, lng: 20.1387984 },
    { lat: 48.3612653, lng: 20.1386998 },
    { lat: 48.3614871, lng: 20.1383124 },
    { lat: 48.3619772, lng: 20.1373496 },
    { lat: 48.3622244, lng: 20.1365395 },
    { lat: 48.3625223, lng: 20.1342392 },
    { lat: 48.3625277, lng: 20.1338753 },
    { lat: 48.3632625, lng: 20.1316031 },
    { lat: 48.36303, lng: 20.1308769 },
    { lat: 48.3626521, lng: 20.1303117 },
    { lat: 48.3626572, lng: 20.1297509 },
    { lat: 48.3627543, lng: 20.1292308 },
    { lat: 48.3629371, lng: 20.1286909 },
    { lat: 48.3631065, lng: 20.1276863 },
    { lat: 48.3633987, lng: 20.1265985 },
    { lat: 48.36362, lng: 20.1253179 },
    { lat: 48.3638679, lng: 20.1246348 },
    { lat: 48.3643723, lng: 20.1235883 },
    { lat: 48.3652482, lng: 20.1230005 },
    { lat: 48.3659932, lng: 20.1228718 },
    { lat: 48.3667558, lng: 20.1224631 },
    { lat: 48.3674024, lng: 20.1222177 },
    { lat: 48.3692339, lng: 20.1218346 },
    { lat: 48.3707828, lng: 20.1224407 },
    { lat: 48.3712732, lng: 20.1223936 },
    { lat: 48.3721407, lng: 20.1220442 },
    { lat: 48.3725467, lng: 20.1216985 },
    { lat: 48.3737241, lng: 20.1197053 },
    { lat: 48.3743773, lng: 20.1181662 },
    { lat: 48.3750782, lng: 20.1171299 },
    { lat: 48.3758605, lng: 20.1162933 },
    { lat: 48.3770288, lng: 20.1159181 },
    { lat: 48.3771341, lng: 20.1158205 },
    { lat: 48.3774422, lng: 20.115421 },
    { lat: 48.3784543, lng: 20.1138474 },
    { lat: 48.3786122, lng: 20.1135249 },
    { lat: 48.3788472, lng: 20.1125019 },
    { lat: 48.379288, lng: 20.1111671 },
    { lat: 48.3810254, lng: 20.108054 },
    { lat: 48.3822845, lng: 20.1053166 },
    { lat: 48.3824529, lng: 20.105111 },
    { lat: 48.3828375, lng: 20.104822 },
    { lat: 48.3831338, lng: 20.1043608 },
    { lat: 48.3834169, lng: 20.1037935 },
    { lat: 48.3838526, lng: 20.1025054 },
    { lat: 48.3839573, lng: 20.1019287 },
    { lat: 48.3839958, lng: 20.1014142 },
    { lat: 48.3838587, lng: 20.1009276 },
    { lat: 48.3836972, lng: 20.0998799 },
    { lat: 48.3831444, lng: 20.0980532 },
    { lat: 48.382277, lng: 20.0968809 },
    { lat: 48.381938, lng: 20.0962767 },
    { lat: 48.3815779, lng: 20.094541 },
    { lat: 48.3817289, lng: 20.093257 },
    { lat: 48.3820819, lng: 20.0915514 },
    { lat: 48.3832059, lng: 20.089555 },
    { lat: 48.3833036, lng: 20.0891377 },
    { lat: 48.3831441, lng: 20.087598 },
    { lat: 48.3826729, lng: 20.0865509 },
  ],
  GemerskéDechtáre: [
    { lat: 48.2104925, lng: 20.0130845 },
    { lat: 48.211034, lng: 20.0133972 },
    { lat: 48.2110757, lng: 20.0134459 },
    { lat: 48.2114111, lng: 20.0140288 },
    { lat: 48.2117492, lng: 20.015131 },
    { lat: 48.2121377, lng: 20.0159597 },
    { lat: 48.2122352, lng: 20.0170196 },
    { lat: 48.212384, lng: 20.0173418 },
    { lat: 48.2132094, lng: 20.0174965 },
    { lat: 48.2133936, lng: 20.0175872 },
    { lat: 48.2139976, lng: 20.0182102 },
    { lat: 48.2148079, lng: 20.0193984 },
    { lat: 48.2149556, lng: 20.0196953 },
    { lat: 48.2152843, lng: 20.0207642 },
    { lat: 48.21561, lng: 20.0226942 },
    { lat: 48.2158554, lng: 20.0236018 },
    { lat: 48.2161097, lng: 20.0251217 },
    { lat: 48.2163373, lng: 20.0267854 },
    { lat: 48.2164319, lng: 20.0279658 },
    { lat: 48.2166086, lng: 20.0290102 },
    { lat: 48.2169262, lng: 20.0298676 },
    { lat: 48.2172058, lng: 20.0303828 },
    { lat: 48.2178632, lng: 20.0313659 },
    { lat: 48.2181393, lng: 20.031642 },
    { lat: 48.2182438, lng: 20.0317413 },
    { lat: 48.2190996, lng: 20.0317874 },
    { lat: 48.21939, lng: 20.032184 },
    { lat: 48.2194558, lng: 20.0324925 },
    { lat: 48.2197961, lng: 20.0328446 },
    { lat: 48.2204435, lng: 20.0332084 },
    { lat: 48.2209911, lng: 20.0332704 },
    { lat: 48.2215077, lng: 20.0332049 },
    { lat: 48.2222201, lng: 20.0333733 },
    { lat: 48.2236251, lng: 20.0331435 },
    { lat: 48.2238286, lng: 20.0329987 },
    { lat: 48.224306, lng: 20.0329118 },
    { lat: 48.2245132, lng: 20.0335296 },
    { lat: 48.2248357, lng: 20.0342056 },
    { lat: 48.2252271, lng: 20.0353515 },
    { lat: 48.2263512, lng: 20.0392172 },
    { lat: 48.2266374, lng: 20.0419172 },
    { lat: 48.2269781, lng: 20.041981 },
    { lat: 48.2275317, lng: 20.0422079 },
    { lat: 48.2279982, lng: 20.0423165 },
    { lat: 48.2284034, lng: 20.0422025 },
    { lat: 48.2297182, lng: 20.0413734 },
    { lat: 48.2300807, lng: 20.0413151 },
    { lat: 48.2307784, lng: 20.0425385 },
    { lat: 48.2311514, lng: 20.0429441 },
    { lat: 48.2317335, lng: 20.0442827 },
    { lat: 48.2318103, lng: 20.0447199 },
    { lat: 48.2320138, lng: 20.0451948 },
    { lat: 48.2325466, lng: 20.0458953 },
    { lat: 48.2337675, lng: 20.0500731 },
    { lat: 48.2342023, lng: 20.0512019 },
    { lat: 48.2343346, lng: 20.0523771 },
    { lat: 48.2343587, lng: 20.0532535 },
    { lat: 48.2344655, lng: 20.0540018 },
    { lat: 48.2345641, lng: 20.0540392 },
    { lat: 48.2349151, lng: 20.0549225 },
    { lat: 48.2356249, lng: 20.0560049 },
    { lat: 48.2356755, lng: 20.056192 },
    { lat: 48.2361683, lng: 20.056124 },
    { lat: 48.2369956, lng: 20.0561397 },
    { lat: 48.2375591, lng: 20.0560329 },
    { lat: 48.2380349, lng: 20.0562068 },
    { lat: 48.2384986, lng: 20.0565504 },
    { lat: 48.2386179, lng: 20.056547 },
    { lat: 48.2386839, lng: 20.0561313 },
    { lat: 48.2386838, lng: 20.0557501 },
    { lat: 48.2392728, lng: 20.0553936 },
    { lat: 48.239711, lng: 20.0552894 },
    { lat: 48.2402431, lng: 20.0553807 },
    { lat: 48.2406365, lng: 20.0553623 },
    { lat: 48.2407372, lng: 20.0574487 },
    { lat: 48.2409459, lng: 20.0595388 },
    { lat: 48.2423261, lng: 20.0596823 },
    { lat: 48.2440234, lng: 20.0596252 },
    { lat: 48.2445001, lng: 20.059304 },
    { lat: 48.2453012, lng: 20.0571183 },
    { lat: 48.2461734, lng: 20.057832 },
    { lat: 48.2482581, lng: 20.0565508 },
    { lat: 48.2506546, lng: 20.0588888 },
    { lat: 48.2518491, lng: 20.0609042 },
    { lat: 48.2521831, lng: 20.0613519 },
    { lat: 48.2527179, lng: 20.0618827 },
    { lat: 48.2530807, lng: 20.0612144 },
    { lat: 48.2533212, lng: 20.0606289 },
    { lat: 48.253352, lng: 20.0603443 },
    { lat: 48.2532919, lng: 20.0596536 },
    { lat: 48.2535978, lng: 20.059184 },
    { lat: 48.2538369, lng: 20.0580051 },
    { lat: 48.2538852, lng: 20.0569431 },
    { lat: 48.2545648, lng: 20.0545216 },
    { lat: 48.2546915, lng: 20.0538859 },
    { lat: 48.2548029, lng: 20.0523464 },
    { lat: 48.2552847, lng: 20.0508776 },
    { lat: 48.2553814, lng: 20.0506603 },
    { lat: 48.2560234, lng: 20.0496883 },
    { lat: 48.256388, lng: 20.0485704 },
    { lat: 48.2573409, lng: 20.0473288 },
    { lat: 48.2592168, lng: 20.0446688 },
    { lat: 48.2618009, lng: 20.046022 },
    { lat: 48.2618495, lng: 20.0458046 },
    { lat: 48.2616047, lng: 20.0442625 },
    { lat: 48.2615416, lng: 20.0433186 },
    { lat: 48.2616301, lng: 20.0410976 },
    { lat: 48.2614868, lng: 20.0404143 },
    { lat: 48.2613401, lng: 20.0391979 },
    { lat: 48.261083, lng: 20.0380872 },
    { lat: 48.2610152, lng: 20.0372222 },
    { lat: 48.2606678, lng: 20.0365135 },
    { lat: 48.2604685, lng: 20.0358969 },
    { lat: 48.2599655, lng: 20.0349102 },
    { lat: 48.2598423, lng: 20.0345269 },
    { lat: 48.2596967, lng: 20.0339824 },
    { lat: 48.2596562, lng: 20.033685 },
    { lat: 48.2596928, lng: 20.0334044 },
    { lat: 48.2595926, lng: 20.0329228 },
    { lat: 48.2593981, lng: 20.0323369 },
    { lat: 48.2590596, lng: 20.0316533 },
    { lat: 48.2588601, lng: 20.0302517 },
    { lat: 48.25842, lng: 20.0289996 },
    { lat: 48.2583391, lng: 20.0285448 },
    { lat: 48.2585441, lng: 20.0274149 },
    { lat: 48.2589414, lng: 20.0263633 },
    { lat: 48.2590512, lng: 20.0245466 },
    { lat: 48.2588491, lng: 20.0230342 },
    { lat: 48.2586738, lng: 20.0223727 },
    { lat: 48.2583638, lng: 20.0216949 },
    { lat: 48.2579258, lng: 20.0211932 },
    { lat: 48.257641, lng: 20.0209602 },
    { lat: 48.2576515, lng: 20.0188998 },
    { lat: 48.2576093, lng: 20.018435 },
    { lat: 48.2573072, lng: 20.0172238 },
    { lat: 48.2572925, lng: 20.0169512 },
    { lat: 48.2570545, lng: 20.0161921 },
    { lat: 48.2568631, lng: 20.0158503 },
    { lat: 48.2564253, lng: 20.0153465 },
    { lat: 48.2560731, lng: 20.0150605 },
    { lat: 48.25607, lng: 20.0150149 },
    { lat: 48.2553027, lng: 20.0149847 },
    { lat: 48.2550088, lng: 20.0148537 },
    { lat: 48.2547985, lng: 20.0145975 },
    { lat: 48.2545123, lng: 20.0140048 },
    { lat: 48.2541531, lng: 20.0138439 },
    { lat: 48.2531264, lng: 20.0136456 },
    { lat: 48.2512263, lng: 20.0129861 },
    { lat: 48.2504546, lng: 20.0123686 },
    { lat: 48.2501599, lng: 20.0120012 },
    { lat: 48.2498498, lng: 20.0113508 },
    { lat: 48.2494074, lng: 20.0108235 },
    { lat: 48.2490884, lng: 20.0105815 },
    { lat: 48.2478831, lng: 20.0103097 },
    { lat: 48.2466873, lng: 20.0097333 },
    { lat: 48.2465859, lng: 20.0093619 },
    { lat: 48.2466704, lng: 20.0085881 },
    { lat: 48.2468781, lng: 20.0079249 },
    { lat: 48.2474993, lng: 20.0065511 },
    { lat: 48.2481483, lng: 20.0054038 },
    { lat: 48.2484354, lng: 20.0050805 },
    { lat: 48.2488583, lng: 20.0050115 },
    { lat: 48.24926, lng: 20.0048647 },
    { lat: 48.2513006, lng: 20.0038105 },
    { lat: 48.2526612, lng: 20.0034851 },
    { lat: 48.2526237, lng: 20.0022755 },
    { lat: 48.253358, lng: 19.9998114 },
    { lat: 48.2534161, lng: 19.999399 },
    { lat: 48.2534964, lng: 19.9977552 },
    { lat: 48.2534697, lng: 19.9973863 },
    { lat: 48.2525719, lng: 19.9951472 },
    { lat: 48.2525134, lng: 19.9944062 },
    { lat: 48.2520493, lng: 19.9942158 },
    { lat: 48.2517939, lng: 19.9940078 },
    { lat: 48.2514783, lng: 19.9939242 },
    { lat: 48.2508965, lng: 19.9941571 },
    { lat: 48.2508139, lng: 19.9939829 },
    { lat: 48.2507756, lng: 19.9936638 },
    { lat: 48.2508307, lng: 19.9928895 },
    { lat: 48.2508315, lng: 19.9920309 },
    { lat: 48.2509484, lng: 19.991625 },
    { lat: 48.2523415, lng: 19.9902541 },
    { lat: 48.2525305, lng: 19.9899015 },
    { lat: 48.2527815, lng: 19.9890484 },
    { lat: 48.2529839, lng: 19.9885821 },
    { lat: 48.2536323, lng: 19.9872679 },
    { lat: 48.2538843, lng: 19.9869425 },
    { lat: 48.2539419, lng: 19.9861453 },
    { lat: 48.2539479, lng: 19.9861242 },
    { lat: 48.2538225, lng: 19.9859343 },
    { lat: 48.2529714, lng: 19.9851214 },
    { lat: 48.2521496, lng: 19.9838043 },
    { lat: 48.2515075, lng: 19.9831346 },
    { lat: 48.2511904, lng: 19.9832824 },
    { lat: 48.2501047, lng: 19.9831299 },
    { lat: 48.2484986, lng: 19.9822337 },
    { lat: 48.2479827, lng: 19.982048 },
    { lat: 48.2468332, lng: 19.9825405 },
    { lat: 48.2462221, lng: 19.9832213 },
    { lat: 48.2454762, lng: 19.9839364 },
    { lat: 48.2439362, lng: 19.9848628 },
    { lat: 48.2432807, lng: 19.9849186 },
    { lat: 48.2425834, lng: 19.9847869 },
    { lat: 48.2423132, lng: 19.9848164 },
    { lat: 48.2418193, lng: 19.9851 },
    { lat: 48.2416998, lng: 19.985048 },
    { lat: 48.2407682, lng: 19.9846428 },
    { lat: 48.2394593, lng: 19.9845376 },
    { lat: 48.2388693, lng: 19.9848964 },
    { lat: 48.2387811, lng: 19.984968 },
    { lat: 48.2379444, lng: 19.9848149 },
    { lat: 48.2368636, lng: 19.9849902 },
    { lat: 48.2368111, lng: 19.9862192 },
    { lat: 48.2368214, lng: 19.9875072 },
    { lat: 48.2368862, lng: 19.9881838 },
    { lat: 48.2372404, lng: 19.9899792 },
    { lat: 48.2371784, lng: 19.9907236 },
    { lat: 48.2367131, lng: 19.9924535 },
    { lat: 48.2364399, lng: 19.9930812 },
    { lat: 48.2362127, lng: 19.9933797 },
    { lat: 48.235742, lng: 19.9936776 },
    { lat: 48.2351657, lng: 19.9939322 },
    { lat: 48.2347852, lng: 19.9931619 },
    { lat: 48.2342431, lng: 19.9936885 },
    { lat: 48.2341873, lng: 19.9936757 },
    { lat: 48.2336799, lng: 19.9929694 },
    { lat: 48.2332212, lng: 19.9924892 },
    { lat: 48.2330729, lng: 19.9922591 },
    { lat: 48.2324355, lng: 19.9910351 },
    { lat: 48.2323887, lng: 19.99086 },
    { lat: 48.2322305, lng: 19.9906072 },
    { lat: 48.2320644, lng: 19.9904912 },
    { lat: 48.2311196, lng: 19.9888208 },
    { lat: 48.2307699, lng: 19.9886522 },
    { lat: 48.2306143, lng: 19.988432 },
    { lat: 48.2302948, lng: 19.9882291 },
    { lat: 48.2298494, lng: 19.988007 },
    { lat: 48.2294752, lng: 19.9879792 },
    { lat: 48.228184, lng: 19.9887948 },
    { lat: 48.2277435, lng: 19.989223 },
    { lat: 48.2275947, lng: 19.9898077 },
    { lat: 48.2273487, lng: 19.9904093 },
    { lat: 48.2273913, lng: 19.9909628 },
    { lat: 48.227246, lng: 19.9917866 },
    { lat: 48.2271762, lng: 19.9927778 },
    { lat: 48.2273569, lng: 19.9940358 },
    { lat: 48.2272545, lng: 19.9949986 },
    { lat: 48.2267027, lng: 19.9947937 },
    { lat: 48.2263867, lng: 19.9944053 },
    { lat: 48.2262337, lng: 19.9940626 },
    { lat: 48.2261101, lng: 19.9933497 },
    { lat: 48.2260208, lng: 19.9930745 },
    { lat: 48.2257626, lng: 19.9927806 },
    { lat: 48.2254305, lng: 19.9922228 },
    { lat: 48.2244046, lng: 19.9910838 },
    { lat: 48.223979, lng: 19.990454 },
    { lat: 48.2238232, lng: 19.9899403 },
    { lat: 48.2235102, lng: 19.9892492 },
    { lat: 48.2217132, lng: 19.9873272 },
    { lat: 48.2208719, lng: 19.985977 },
    { lat: 48.2206323, lng: 19.9849975 },
    { lat: 48.2203179, lng: 19.985181 },
    { lat: 48.2187915, lng: 19.9857923 },
    { lat: 48.2182147, lng: 19.9860074 },
    { lat: 48.2183467, lng: 19.9867282 },
    { lat: 48.2183409, lng: 19.9878174 },
    { lat: 48.2179452, lng: 19.9914306 },
    { lat: 48.2180798, lng: 19.9923263 },
    { lat: 48.2180828, lng: 19.993698 },
    { lat: 48.2179879, lng: 19.9947592 },
    { lat: 48.2179911, lng: 19.9956328 },
    { lat: 48.2185844, lng: 19.9959984 },
    { lat: 48.2183426, lng: 19.9966998 },
    { lat: 48.2180974, lng: 19.9969265 },
    { lat: 48.2174354, lng: 19.998746 },
    { lat: 48.2177364, lng: 19.9992325 },
    { lat: 48.2178497, lng: 19.9998082 },
    { lat: 48.2180921, lng: 20.0029113 },
    { lat: 48.2181691, lng: 20.0035324 },
    { lat: 48.2183661, lng: 20.0041683 },
    { lat: 48.2183405, lng: 20.0044685 },
    { lat: 48.2184454, lng: 20.0059425 },
    { lat: 48.2169013, lng: 20.0059244 },
    { lat: 48.2162631, lng: 20.0058317 },
    { lat: 48.2155247, lng: 20.0038652 },
    { lat: 48.2153376, lng: 20.0035441 },
    { lat: 48.2151229, lng: 20.0026461 },
    { lat: 48.2150244, lng: 20.0026116 },
    { lat: 48.2149409, lng: 20.002338 },
    { lat: 48.2147861, lng: 20.002271 },
    { lat: 48.2144332, lng: 20.0030171 },
    { lat: 48.2138844, lng: 20.0037559 },
    { lat: 48.2133391, lng: 20.0047458 },
    { lat: 48.2133607, lng: 20.0053089 },
    { lat: 48.2129911, lng: 20.0063224 },
    { lat: 48.2130279, lng: 20.0074859 },
    { lat: 48.212647, lng: 20.0088707 },
    { lat: 48.211632, lng: 20.0080718 },
    { lat: 48.2113896, lng: 20.0089751 },
    { lat: 48.2105285, lng: 20.0129741 },
    { lat: 48.2104925, lng: 20.0130845 },
  ],
  Rovné: [
    { lat: 48.6255994, lng: 19.9924126 },
    { lat: 48.6253812, lng: 19.9927154 },
    { lat: 48.6251899, lng: 19.9926675 },
    { lat: 48.6248553, lng: 19.9930929 },
    { lat: 48.6238911, lng: 19.9932287 },
    { lat: 48.623696, lng: 19.9931195 },
    { lat: 48.6234098, lng: 19.9931678 },
    { lat: 48.6229706, lng: 19.9928768 },
    { lat: 48.6221334, lng: 19.9927093 },
    { lat: 48.6217068, lng: 19.9928486 },
    { lat: 48.621628, lng: 19.992933 },
    { lat: 48.6212433, lng: 19.9929744 },
    { lat: 48.6209431, lng: 19.99352 },
    { lat: 48.6203939, lng: 19.9939612 },
    { lat: 48.6200986, lng: 19.994339 },
    { lat: 48.6199927, lng: 19.9946841 },
    { lat: 48.6199484, lng: 19.9950807 },
    { lat: 48.6200634, lng: 19.9958854 },
    { lat: 48.6200292, lng: 19.9967377 },
    { lat: 48.6199469, lng: 19.9969521 },
    { lat: 48.6196065, lng: 19.9973744 },
    { lat: 48.6190085, lng: 19.9976472 },
    { lat: 48.6188506, lng: 19.9976419 },
    { lat: 48.6185743, lng: 19.9977694 },
    { lat: 48.6182165, lng: 19.9981816 },
    { lat: 48.6180089, lng: 19.9985218 },
    { lat: 48.6179273, lng: 19.9987556 },
    { lat: 48.6178385, lng: 19.9988385 },
    { lat: 48.6177024, lng: 19.9995442 },
    { lat: 48.6176698, lng: 20.0015043 },
    { lat: 48.6175596, lng: 20.0027527 },
    { lat: 48.6159217, lng: 20.0042301 },
    { lat: 48.6153419, lng: 20.0043455 },
    { lat: 48.6142493, lng: 20.0044263 },
    { lat: 48.613935, lng: 20.0045577 },
    { lat: 48.6137305, lng: 20.0048164 },
    { lat: 48.6130648, lng: 20.005434 },
    { lat: 48.6127931, lng: 20.0058297 },
    { lat: 48.6124396, lng: 20.006052 },
    { lat: 48.6122096, lng: 20.0063701 },
    { lat: 48.6119092, lng: 20.0065937 },
    { lat: 48.6112267, lng: 20.0064109 },
    { lat: 48.6111221, lng: 20.006171 },
    { lat: 48.6098759, lng: 20.0069128 },
    { lat: 48.6096835, lng: 20.007375 },
    { lat: 48.6091475, lng: 20.0083258 },
    { lat: 48.6090785, lng: 20.0076191 },
    { lat: 48.608731, lng: 20.0073905 },
    { lat: 48.6083506, lng: 20.0073986 },
    { lat: 48.6078988, lng: 20.0076921 },
    { lat: 48.6078823, lng: 20.0072424 },
    { lat: 48.6077349, lng: 20.0067079 },
    { lat: 48.6071456, lng: 20.0062703 },
    { lat: 48.6068239, lng: 20.0058257 },
    { lat: 48.6062103, lng: 20.0054004 },
    { lat: 48.60612, lng: 20.0052378 },
    { lat: 48.605866, lng: 20.0050067 },
    { lat: 48.6056073, lng: 20.0049114 },
    { lat: 48.6055992, lng: 20.0053315 },
    { lat: 48.6054795, lng: 20.0054617 },
    { lat: 48.6054143, lng: 20.0060858 },
    { lat: 48.605456, lng: 20.0067328 },
    { lat: 48.6052679, lng: 20.0072408 },
    { lat: 48.6051272, lng: 20.0074603 },
    { lat: 48.6040003, lng: 20.0078273 },
    { lat: 48.6036895, lng: 20.0084782 },
    { lat: 48.6036617, lng: 20.0087282 },
    { lat: 48.6038308, lng: 20.0093514 },
    { lat: 48.603882, lng: 20.0095483 },
    { lat: 48.6041561, lng: 20.0100177 },
    { lat: 48.6042305, lng: 20.0109616 },
    { lat: 48.603747, lng: 20.011655 },
    { lat: 48.6030967, lng: 20.011941 },
    { lat: 48.6030108, lng: 20.0122075 },
    { lat: 48.6027064, lng: 20.0123898 },
    { lat: 48.6027303, lng: 20.012557 },
    { lat: 48.602524, lng: 20.0126342 },
    { lat: 48.60248, lng: 20.0136541 },
    { lat: 48.6020645, lng: 20.0142801 },
    { lat: 48.6013103, lng: 20.015855 },
    { lat: 48.6010897, lng: 20.0167746 },
    { lat: 48.6000303, lng: 20.017089 },
    { lat: 48.5997525, lng: 20.0176891 },
    { lat: 48.5996048, lng: 20.017849 },
    { lat: 48.5993167, lng: 20.0179358 },
    { lat: 48.599061, lng: 20.0178561 },
    { lat: 48.5986856, lng: 20.0179035 },
    { lat: 48.598948, lng: 20.0186038 },
    { lat: 48.5987675, lng: 20.0187 },
    { lat: 48.5991273, lng: 20.0202533 },
    { lat: 48.5991297, lng: 20.0207179 },
    { lat: 48.599229, lng: 20.0216369 },
    { lat: 48.5991938, lng: 20.0216314 },
    { lat: 48.599147, lng: 20.0220194 },
    { lat: 48.5989646, lng: 20.0221058 },
    { lat: 48.5986243, lng: 20.022132 },
    { lat: 48.5986139, lng: 20.0225262 },
    { lat: 48.5984059, lng: 20.0224295 },
    { lat: 48.597753, lng: 20.0224114 },
    { lat: 48.5974021, lng: 20.0222603 },
    { lat: 48.5970796, lng: 20.0222823 },
    { lat: 48.5969298, lng: 20.0223648 },
    { lat: 48.5966438, lng: 20.0220439 },
    { lat: 48.5965884, lng: 20.0217399 },
    { lat: 48.5964557, lng: 20.0216084 },
    { lat: 48.5961435, lng: 20.0215533 },
    { lat: 48.5959182, lng: 20.0218508 },
    { lat: 48.5958203, lng: 20.0226889 },
    { lat: 48.5959306, lng: 20.0229791 },
    { lat: 48.5959217, lng: 20.0233383 },
    { lat: 48.5958019, lng: 20.0236791 },
    { lat: 48.5954258, lng: 20.0239215 },
    { lat: 48.5949883, lng: 20.023885 },
    { lat: 48.5944436, lng: 20.0243847 },
    { lat: 48.5943003, lng: 20.0252433 },
    { lat: 48.5940597, lng: 20.0255266 },
    { lat: 48.5937617, lng: 20.0251823 },
    { lat: 48.5933984, lng: 20.0260878 },
    { lat: 48.5935896, lng: 20.0267557 },
    { lat: 48.5936618, lng: 20.0281675 },
    { lat: 48.5935538, lng: 20.0286272 },
    { lat: 48.5927533, lng: 20.0302586 },
    { lat: 48.5925126, lng: 20.0311876 },
    { lat: 48.5921867, lng: 20.0316376 },
    { lat: 48.5918116, lng: 20.0313703 },
    { lat: 48.5914551, lng: 20.0318961 },
    { lat: 48.591271, lng: 20.0316132 },
    { lat: 48.5912601, lng: 20.0314307 },
    { lat: 48.5907832, lng: 20.0319373 },
    { lat: 48.5904836, lng: 20.0315852 },
    { lat: 48.5903914, lng: 20.0313428 },
    { lat: 48.5899497, lng: 20.0311825 },
    { lat: 48.5893855, lng: 20.0321573 },
    { lat: 48.5891908, lng: 20.0324077 },
    { lat: 48.5886956, lng: 20.0326044 },
    { lat: 48.5885681, lng: 20.0325474 },
    { lat: 48.5881895, lng: 20.0326512 },
    { lat: 48.588172, lng: 20.033421 },
    { lat: 48.588262, lng: 20.0338753 },
    { lat: 48.5881318, lng: 20.0344566 },
    { lat: 48.5882734, lng: 20.0350174 },
    { lat: 48.5881912, lng: 20.0363206 },
    { lat: 48.5877443, lng: 20.0372448 },
    { lat: 48.5874947, lng: 20.0372592 },
    { lat: 48.5872213, lng: 20.0374543 },
    { lat: 48.5867963, lng: 20.0384458 },
    { lat: 48.5867511, lng: 20.0387956 },
    { lat: 48.5865625, lng: 20.0391912 },
    { lat: 48.5865623, lng: 20.0394303 },
    { lat: 48.5862162, lng: 20.0400984 },
    { lat: 48.5861456, lng: 20.0403819 },
    { lat: 48.585997, lng: 20.0403859 },
    { lat: 48.5858417, lng: 20.0402216 },
    { lat: 48.5854991, lng: 20.0401099 },
    { lat: 48.5853648, lng: 20.039917 },
    { lat: 48.5843861, lng: 20.0392388 },
    { lat: 48.584144, lng: 20.0392183 },
    { lat: 48.5838908, lng: 20.0390625 },
    { lat: 48.5829441, lng: 20.0392611 },
    { lat: 48.5824039, lng: 20.0402794 },
    { lat: 48.5823345, lng: 20.0402618 },
    { lat: 48.5818094, lng: 20.0416397 },
    { lat: 48.5816676, lng: 20.0413446 },
    { lat: 48.5814267, lng: 20.041456 },
    { lat: 48.581142, lng: 20.0414085 },
    { lat: 48.581119, lng: 20.0416668 },
    { lat: 48.580544, lng: 20.0415695 },
    { lat: 48.5802362, lng: 20.0413769 },
    { lat: 48.5795948, lng: 20.0406086 },
    { lat: 48.5790041, lng: 20.0400547 },
    { lat: 48.5787045, lng: 20.0396728 },
    { lat: 48.5785451, lng: 20.0394143 },
    { lat: 48.57843, lng: 20.0390602 },
    { lat: 48.5784472, lng: 20.0388196 },
    { lat: 48.578544, lng: 20.0386343 },
    { lat: 48.5782593, lng: 20.0385819 },
    { lat: 48.5781479, lng: 20.0386967 },
    { lat: 48.577902, lng: 20.0385264 },
    { lat: 48.5776112, lng: 20.0382198 },
    { lat: 48.5772118, lng: 20.0375273 },
    { lat: 48.5766298, lng: 20.037891 },
    { lat: 48.5763956, lng: 20.0381625 },
    { lat: 48.5759309, lng: 20.0381772 },
    { lat: 48.5757036, lng: 20.0383466 },
    { lat: 48.5755022, lng: 20.0384005 },
    { lat: 48.5748457, lng: 20.038327 },
    { lat: 48.5744363, lng: 20.0381522 },
    { lat: 48.5740628, lng: 20.0378105 },
    { lat: 48.5730414, lng: 20.0373844 },
    { lat: 48.5726589, lng: 20.0373416 },
    { lat: 48.5725661, lng: 20.0374147 },
    { lat: 48.572651, lng: 20.0374522 },
    { lat: 48.572802, lng: 20.0385626 },
    { lat: 48.5723464, lng: 20.0387945 },
    { lat: 48.5726559, lng: 20.0400031 },
    { lat: 48.5731331, lng: 20.0399957 },
    { lat: 48.5730805, lng: 20.0411968 },
    { lat: 48.572824, lng: 20.0428035 },
    { lat: 48.5728407, lng: 20.0433653 },
    { lat: 48.5729217, lng: 20.0438485 },
    { lat: 48.5728365, lng: 20.0448894 },
    { lat: 48.5726188, lng: 20.045479 },
    { lat: 48.5726682, lng: 20.0459911 },
    { lat: 48.5726264, lng: 20.046912 },
    { lat: 48.5724372, lng: 20.0480015 },
    { lat: 48.5724818, lng: 20.0482327 },
    { lat: 48.5725206, lng: 20.0482415 },
    { lat: 48.5735034, lng: 20.0479828 },
    { lat: 48.5734069, lng: 20.0489458 },
    { lat: 48.5734467, lng: 20.049524 },
    { lat: 48.5738563, lng: 20.0507082 },
    { lat: 48.5737682, lng: 20.0509178 },
    { lat: 48.5736929, lng: 20.0509804 },
    { lat: 48.5736805, lng: 20.0511146 },
    { lat: 48.5735813, lng: 20.0512214 },
    { lat: 48.5735335, lng: 20.051508 },
    { lat: 48.5731985, lng: 20.0520403 },
    { lat: 48.5730927, lng: 20.0523426 },
    { lat: 48.573083, lng: 20.05303 },
    { lat: 48.5731237, lng: 20.0534135 },
    { lat: 48.5734466, lng: 20.0538419 },
    { lat: 48.5744008, lng: 20.0547946 },
    { lat: 48.5742981, lng: 20.0552493 },
    { lat: 48.5745576, lng: 20.0554799 },
    { lat: 48.5743945, lng: 20.056271 },
    { lat: 48.5741299, lng: 20.0560042 },
    { lat: 48.5739233, lng: 20.0560983 },
    { lat: 48.5738842, lng: 20.0561358 },
    { lat: 48.5738128, lng: 20.0565306 },
    { lat: 48.5733212, lng: 20.057392 },
    { lat: 48.5728923, lng: 20.0577806 },
    { lat: 48.5725518, lng: 20.0579303 },
    { lat: 48.5728512, lng: 20.0592886 },
    { lat: 48.5730799, lng: 20.0592898 },
    { lat: 48.5732265, lng: 20.0594553 },
    { lat: 48.5731898, lng: 20.0598742 },
    { lat: 48.573089, lng: 20.0601579 },
    { lat: 48.5732019, lng: 20.0607349 },
    { lat: 48.57333, lng: 20.0610017 },
    { lat: 48.5733106, lng: 20.0612688 },
    { lat: 48.5735176, lng: 20.0612921 },
    { lat: 48.5738708, lng: 20.0614719 },
    { lat: 48.5746458, lng: 20.0623402 },
    { lat: 48.5757335, lng: 20.0631756 },
    { lat: 48.5758122, lng: 20.0627378 },
    { lat: 48.5757741, lng: 20.0623068 },
    { lat: 48.5762057, lng: 20.0620364 },
    { lat: 48.5765325, lng: 20.0619883 },
    { lat: 48.5772126, lng: 20.0623338 },
    { lat: 48.5772934, lng: 20.0624372 },
    { lat: 48.5774531, lng: 20.0622425 },
    { lat: 48.5779559, lng: 20.0621402 },
    { lat: 48.5781067, lng: 20.0618151 },
    { lat: 48.5784072, lng: 20.0615354 },
    { lat: 48.5784949, lng: 20.0611227 },
    { lat: 48.5784391, lng: 20.061081 },
    { lat: 48.5786242, lng: 20.0605734 },
    { lat: 48.5788429, lng: 20.0605486 },
    { lat: 48.5788826, lng: 20.0603912 },
    { lat: 48.5790105, lng: 20.0620663 },
    { lat: 48.5793169, lng: 20.0621229 },
    { lat: 48.5796647, lng: 20.0624694 },
    { lat: 48.5800716, lng: 20.0627257 },
    { lat: 48.5803922, lng: 20.0628233 },
    { lat: 48.5805733, lng: 20.062734 },
    { lat: 48.580793, lng: 20.0624363 },
    { lat: 48.5809736, lng: 20.062419 },
    { lat: 48.5813959, lng: 20.0629335 },
    { lat: 48.5816752, lng: 20.0631755 },
    { lat: 48.5822298, lng: 20.0639501 },
    { lat: 48.5825291, lng: 20.0642418 },
    { lat: 48.5833748, lng: 20.0646988 },
    { lat: 48.5838876, lng: 20.0643525 },
    { lat: 48.5844516, lng: 20.0642358 },
    { lat: 48.5851191, lng: 20.0639511 },
    { lat: 48.5855767, lng: 20.0639042 },
    { lat: 48.5857456, lng: 20.0637133 },
    { lat: 48.5862343, lng: 20.0634927 },
    { lat: 48.5870114, lng: 20.0628106 },
    { lat: 48.5886675, lng: 20.0599026 },
    { lat: 48.5890644, lng: 20.0584183 },
    { lat: 48.5892266, lng: 20.0575474 },
    { lat: 48.5897413, lng: 20.0553411 },
    { lat: 48.5907532, lng: 20.0529591 },
    { lat: 48.5905424, lng: 20.052357 },
    { lat: 48.5910982, lng: 20.0490818 },
    { lat: 48.5907901, lng: 20.0472486 },
    { lat: 48.5908977, lng: 20.0469915 },
    { lat: 48.5914761, lng: 20.046494 },
    { lat: 48.5928316, lng: 20.0451166 },
    { lat: 48.5944674, lng: 20.0442043 },
    { lat: 48.5950502, lng: 20.0426915 },
    { lat: 48.5963488, lng: 20.0410644 },
    { lat: 48.5977223, lng: 20.0403166 },
    { lat: 48.5982909, lng: 20.0391971 },
    { lat: 48.5991765, lng: 20.0377712 },
    { lat: 48.6005548, lng: 20.036758 },
    { lat: 48.6013907, lng: 20.0359758 },
    { lat: 48.6021327, lng: 20.0359398 },
    { lat: 48.6038378, lng: 20.0352796 },
    { lat: 48.6047508, lng: 20.0352403 },
    { lat: 48.6048126, lng: 20.0351832 },
    { lat: 48.6049461, lng: 20.0351976 },
    { lat: 48.6051774, lng: 20.0354814 },
    { lat: 48.6052565, lng: 20.0354882 },
    { lat: 48.6061364, lng: 20.0351405 },
    { lat: 48.6062379, lng: 20.0351975 },
    { lat: 48.606877, lng: 20.0346103 },
    { lat: 48.6074542, lng: 20.0343788 },
    { lat: 48.6085308, lng: 20.0340738 },
    { lat: 48.608901, lng: 20.0340661 },
    { lat: 48.6091891, lng: 20.0341656 },
    { lat: 48.6104006, lng: 20.0339076 },
    { lat: 48.6108608, lng: 20.0336277 },
    { lat: 48.6110538, lng: 20.0332054 },
    { lat: 48.612021, lng: 20.031662 },
    { lat: 48.61203, lng: 20.03165 },
    { lat: 48.612025, lng: 20.031597 },
    { lat: 48.612002, lng: 20.031328 },
    { lat: 48.612005, lng: 20.03125 },
    { lat: 48.612051, lng: 20.03104 },
    { lat: 48.61214, lng: 20.030731 },
    { lat: 48.612198, lng: 20.030608 },
    { lat: 48.612226, lng: 20.030512 },
    { lat: 48.612234, lng: 20.030483 },
    { lat: 48.61227, lng: 20.030358 },
    { lat: 48.612387, lng: 20.029925 },
    { lat: 48.612464, lng: 20.029423 },
    { lat: 48.612532, lng: 20.02901 },
    { lat: 48.612622, lng: 20.028568 },
    { lat: 48.612708, lng: 20.028153 },
    { lat: 48.612716, lng: 20.028112 },
    { lat: 48.612722, lng: 20.028081 },
    { lat: 48.612797, lng: 20.027734 },
    { lat: 48.612847, lng: 20.027466 },
    { lat: 48.612844, lng: 20.02713 },
    { lat: 48.612892, lng: 20.026892 },
    { lat: 48.613063, lng: 20.026475 },
    { lat: 48.613129, lng: 20.026312 },
    { lat: 48.613176, lng: 20.025996 },
    { lat: 48.613209, lng: 20.025837 },
    { lat: 48.61322, lng: 20.025727 },
    { lat: 48.613255, lng: 20.025258 },
    { lat: 48.613401, lng: 20.025263 },
    { lat: 48.613569, lng: 20.025272 },
    { lat: 48.613862, lng: 20.025313 },
    { lat: 48.613965, lng: 20.025314 },
    { lat: 48.614134, lng: 20.025295 },
    { lat: 48.614349, lng: 20.025323 },
    { lat: 48.614409, lng: 20.025328 },
    { lat: 48.61461, lng: 20.025395 },
    { lat: 48.614827, lng: 20.025469 },
    { lat: 48.614915, lng: 20.025043 },
    { lat: 48.614989, lng: 20.024751 },
    { lat: 48.615033, lng: 20.024589 },
    { lat: 48.615185, lng: 20.024389 },
    { lat: 48.615298, lng: 20.024162 },
    { lat: 48.615367, lng: 20.024037 },
    { lat: 48.615543, lng: 20.023741 },
    { lat: 48.615597, lng: 20.023613 },
    { lat: 48.615671, lng: 20.023419 },
    { lat: 48.615759, lng: 20.02315 },
    { lat: 48.615875, lng: 20.023062 },
    { lat: 48.615928, lng: 20.02302 },
    { lat: 48.616149, lng: 20.02236 },
    { lat: 48.616257, lng: 20.022005 },
    { lat: 48.61636, lng: 20.021817 },
    { lat: 48.616444, lng: 20.021728 },
    { lat: 48.616619, lng: 20.021559 },
    { lat: 48.616815, lng: 20.021414 },
    { lat: 48.616995, lng: 20.021273 },
    { lat: 48.617177, lng: 20.021137 },
    { lat: 48.617229, lng: 20.021091 },
    { lat: 48.617318, lng: 20.021009 },
    { lat: 48.617388, lng: 20.020938 },
    { lat: 48.617474, lng: 20.020856 },
    { lat: 48.617584, lng: 20.02077 },
    { lat: 48.617658, lng: 20.020718 },
    { lat: 48.617696, lng: 20.020709 },
    { lat: 48.61778, lng: 20.020699 },
    { lat: 48.617787, lng: 20.020698 },
    { lat: 48.6179251, lng: 20.0205254 },
    { lat: 48.6184787, lng: 20.0204316 },
    { lat: 48.618849, lng: 20.0206108 },
    { lat: 48.6191285, lng: 20.0198082 },
    { lat: 48.6190021, lng: 20.0189893 },
    { lat: 48.6187481, lng: 20.0181703 },
    { lat: 48.6186094, lng: 20.0171938 },
    { lat: 48.6184925, lng: 20.0170614 },
    { lat: 48.6184829, lng: 20.0162652 },
    { lat: 48.6187705, lng: 20.0156515 },
    { lat: 48.6190179, lng: 20.0153482 },
    { lat: 48.6190876, lng: 20.0150735 },
    { lat: 48.6189033, lng: 20.0148425 },
    { lat: 48.6179161, lng: 20.0141848 },
    { lat: 48.61702, lng: 20.0140065 },
    { lat: 48.6171879, lng: 20.0136576 },
    { lat: 48.61793, lng: 20.0129239 },
    { lat: 48.6186796, lng: 20.0108885 },
    { lat: 48.6189128, lng: 20.0107307 },
    { lat: 48.6192052, lng: 20.0106655 },
    { lat: 48.6192262, lng: 20.0105186 },
    { lat: 48.6194935, lng: 20.0105851 },
    { lat: 48.6200214, lng: 20.0105463 },
    { lat: 48.6203056, lng: 20.0101742 },
    { lat: 48.6201578, lng: 20.0094179 },
    { lat: 48.6201631, lng: 20.0088673 },
    { lat: 48.6201184, lng: 20.0086893 },
    { lat: 48.6202939, lng: 20.0075806 },
    { lat: 48.6204081, lng: 20.0077257 },
    { lat: 48.6205654, lng: 20.0077666 },
    { lat: 48.6208201, lng: 20.0075822 },
    { lat: 48.6211248, lng: 20.0074898 },
    { lat: 48.6215212, lng: 20.0076739 },
    { lat: 48.6228618, lng: 20.0073266 },
    { lat: 48.6229915, lng: 20.0074631 },
    { lat: 48.6232356, lng: 20.0075396 },
    { lat: 48.6234384, lng: 20.0075103 },
    { lat: 48.6237139, lng: 20.007315 },
    { lat: 48.6239199, lng: 20.0069628 },
    { lat: 48.624221, lng: 20.006916 },
    { lat: 48.6245651, lng: 20.006441 },
    { lat: 48.6250233, lng: 20.0040941 },
    { lat: 48.6257582, lng: 19.9996142 },
    { lat: 48.625603, lng: 19.9960392 },
    { lat: 48.6256162, lng: 19.9924641 },
    { lat: 48.6255994, lng: 19.9924126 },
  ],
  Ivanice: [
    { lat: 48.3391771, lng: 20.2587724 },
    { lat: 48.339349, lng: 20.2586217 },
    { lat: 48.3416741, lng: 20.2567661 },
    { lat: 48.3422993, lng: 20.2558083 },
    { lat: 48.3425201, lng: 20.2551895 },
    { lat: 48.3426501, lng: 20.2542415 },
    { lat: 48.3427464, lng: 20.251349 },
    { lat: 48.3437578, lng: 20.2491776 },
    { lat: 48.3443729, lng: 20.2484766 },
    { lat: 48.3449224, lng: 20.2476855 },
    { lat: 48.3450539, lng: 20.2473073 },
    { lat: 48.3456265, lng: 20.2466419 },
    { lat: 48.3465623, lng: 20.2448343 },
    { lat: 48.3458835, lng: 20.2442559 },
    { lat: 48.3454397, lng: 20.2431268 },
    { lat: 48.3450442, lng: 20.2417154 },
    { lat: 48.3440531, lng: 20.2416014 },
    { lat: 48.3436815, lng: 20.2417471 },
    { lat: 48.3431734, lng: 20.241517 },
    { lat: 48.3424937, lng: 20.2407374 },
    { lat: 48.3393098, lng: 20.2384051 },
    { lat: 48.3387684, lng: 20.2377982 },
    { lat: 48.33789, lng: 20.2370093 },
    { lat: 48.3371035, lng: 20.2367778 },
    { lat: 48.3360649, lng: 20.236213 },
    { lat: 48.3357502, lng: 20.2357518 },
    { lat: 48.3349476, lng: 20.2340754 },
    { lat: 48.3354525, lng: 20.234083 },
    { lat: 48.3355859, lng: 20.234007 },
    { lat: 48.335566, lng: 20.2339348 },
    { lat: 48.3328008, lng: 20.2276417 },
    { lat: 48.328366, lng: 20.2179212 },
    { lat: 48.3289298, lng: 20.215896 },
    { lat: 48.3287241, lng: 20.2151497 },
    { lat: 48.3226367, lng: 20.2196102 },
    { lat: 48.3192546, lng: 20.2151871 },
    { lat: 48.3189699, lng: 20.21556 },
    { lat: 48.3179069, lng: 20.2168798 },
    { lat: 48.3139506, lng: 20.2259704 },
    { lat: 48.3132314, lng: 20.2265147 },
    { lat: 48.3132957, lng: 20.2267976 },
    { lat: 48.3131605, lng: 20.226963 },
    { lat: 48.3131776, lng: 20.2271377 },
    { lat: 48.3141391, lng: 20.2300472 },
    { lat: 48.3145208, lng: 20.2308835 },
    { lat: 48.3149674, lng: 20.2314335 },
    { lat: 48.3152171, lng: 20.2316523 },
    { lat: 48.315778, lng: 20.2320734 },
    { lat: 48.3164656, lng: 20.232423 },
    { lat: 48.3164723, lng: 20.2325374 },
    { lat: 48.316526, lng: 20.2324692 },
    { lat: 48.3187525, lng: 20.2336985 },
    { lat: 48.319209, lng: 20.2341581 },
    { lat: 48.3194027, lng: 20.2344988 },
    { lat: 48.3196119, lng: 20.2351901 },
    { lat: 48.3205079, lng: 20.2365302 },
    { lat: 48.3216214, lng: 20.2379506 },
    { lat: 48.321851, lng: 20.2384841 },
    { lat: 48.3221757, lng: 20.2387422 },
    { lat: 48.3227218, lng: 20.2392972 },
    { lat: 48.322706, lng: 20.2393485 },
    { lat: 48.3228398, lng: 20.2394635 },
    { lat: 48.3231562, lng: 20.2398191 },
    { lat: 48.3233796, lng: 20.2400042 },
    { lat: 48.3245496, lng: 20.2412101 },
    { lat: 48.3266827, lng: 20.2431816 },
    { lat: 48.3267168, lng: 20.2433322 },
    { lat: 48.32758, lng: 20.2437642 },
    { lat: 48.3270119, lng: 20.2460608 },
    { lat: 48.3264745, lng: 20.2480567 },
    { lat: 48.3264024, lng: 20.2482005 },
    { lat: 48.3265392, lng: 20.2483 },
    { lat: 48.3264839, lng: 20.248351 },
    { lat: 48.3280593, lng: 20.2485121 },
    { lat: 48.328876, lng: 20.24956 },
    { lat: 48.3291422, lng: 20.2507525 },
    { lat: 48.329424, lng: 20.2523984 },
    { lat: 48.3337297, lng: 20.2628214 },
    { lat: 48.3347477, lng: 20.262789 },
    { lat: 48.3351106, lng: 20.2626266 },
    { lat: 48.3356533, lng: 20.2622566 },
    { lat: 48.338644, lng: 20.2592404 },
    { lat: 48.3391771, lng: 20.2587724 },
  ],
  Šimonovce: [
    { lat: 48.2777526, lng: 20.1578021 },
    { lat: 48.279634, lng: 20.1541702 },
    { lat: 48.2787964, lng: 20.1511424 },
    { lat: 48.2780873, lng: 20.1433872 },
    { lat: 48.2841353, lng: 20.1358645 },
    { lat: 48.2841136, lng: 20.1354464 },
    { lat: 48.2842306, lng: 20.1350586 },
    { lat: 48.284448, lng: 20.1347529 },
    { lat: 48.2849757, lng: 20.1346598 },
    { lat: 48.2852264, lng: 20.1343962 },
    { lat: 48.2855951, lng: 20.1341307 },
    { lat: 48.2859452, lng: 20.1336617 },
    { lat: 48.2860527, lng: 20.1331793 },
    { lat: 48.2860852, lng: 20.1313596 },
    { lat: 48.2859819, lng: 20.1310961 },
    { lat: 48.2859118, lng: 20.1310629 },
    { lat: 48.2855536, lng: 20.1311677 },
    { lat: 48.2853681, lng: 20.131508 },
    { lat: 48.2853655, lng: 20.1318297 },
    { lat: 48.2853028, lng: 20.1320845 },
    { lat: 48.2851792, lng: 20.1322589 },
    { lat: 48.2849752, lng: 20.1322303 },
    { lat: 48.2847034, lng: 20.1320526 },
    { lat: 48.284429, lng: 20.1319817 },
    { lat: 48.2842899, lng: 20.1318594 },
    { lat: 48.2841634, lng: 20.1315482 },
    { lat: 48.2841585, lng: 20.1310139 },
    { lat: 48.2840779, lng: 20.1307812 },
    { lat: 48.2838691, lng: 20.130525 },
    { lat: 48.2834391, lng: 20.1302834 },
    { lat: 48.2831723, lng: 20.1300102 },
    { lat: 48.2835894, lng: 20.1294424 },
    { lat: 48.2852722, lng: 20.1285953 },
    { lat: 48.2861389, lng: 20.1278941 },
    { lat: 48.2866139, lng: 20.1275848 },
    { lat: 48.2869764, lng: 20.1274131 },
    { lat: 48.2882867, lng: 20.1270625 },
    { lat: 48.2900322, lng: 20.1268917 },
    { lat: 48.2929506, lng: 20.1271095 },
    { lat: 48.2943132, lng: 20.1271604 },
    { lat: 48.2953749, lng: 20.1270933 },
    { lat: 48.2962608, lng: 20.1269402 },
    { lat: 48.29748, lng: 20.1265672 },
    { lat: 48.2986581, lng: 20.1261264 },
    { lat: 48.2992933, lng: 20.1258223 },
    { lat: 48.3012568, lng: 20.1243748 },
    { lat: 48.3019086, lng: 20.1238202 },
    { lat: 48.3020708, lng: 20.121597 },
    { lat: 48.3028625, lng: 20.1197614 },
    { lat: 48.303553, lng: 20.1183331 },
    { lat: 48.3051462, lng: 20.1167043 },
    { lat: 48.305315, lng: 20.1164237 },
    { lat: 48.3044212, lng: 20.1165742 },
    { lat: 48.3013323, lng: 20.1171716 },
    { lat: 48.3002886, lng: 20.1174817 },
    { lat: 48.2992739, lng: 20.1175672 },
    { lat: 48.2914, lng: 20.116297 },
    { lat: 48.2903449, lng: 20.1153426 },
    { lat: 48.2872529, lng: 20.1130733 },
    { lat: 48.2874146, lng: 20.1121282 },
    { lat: 48.2875474, lng: 20.1104869 },
    { lat: 48.2875141, lng: 20.1101943 },
    { lat: 48.2872391, lng: 20.1093451 },
    { lat: 48.2861464, lng: 20.109286 },
    { lat: 48.2847989, lng: 20.1089763 },
    { lat: 48.2839331, lng: 20.1083349 },
    { lat: 48.2832892, lng: 20.1077672 },
    { lat: 48.2828552, lng: 20.107047 },
    { lat: 48.2828479, lng: 20.1059715 },
    { lat: 48.2826382, lng: 20.1054721 },
    { lat: 48.2824703, lng: 20.1052797 },
    { lat: 48.2813939, lng: 20.104797 },
    { lat: 48.280376, lng: 20.1042338 },
    { lat: 48.2807098, lng: 20.1038337 },
    { lat: 48.2813969, lng: 20.103315 },
    { lat: 48.2815583, lng: 20.1029987 },
    { lat: 48.2816285, lng: 20.1027044 },
    { lat: 48.2816092, lng: 20.1019087 },
    { lat: 48.2788866, lng: 20.1003267 },
    { lat: 48.277029, lng: 20.1034602 },
    { lat: 48.2769357, lng: 20.1033553 },
    { lat: 48.2745976, lng: 20.1022692 },
    { lat: 48.2729693, lng: 20.1018154 },
    { lat: 48.271879, lng: 20.1015731 },
    { lat: 48.269266, lng: 20.1013314 },
    { lat: 48.2681613, lng: 20.1010661 },
    { lat: 48.2676506, lng: 20.1007825 },
    { lat: 48.2671534, lng: 20.1002257 },
    { lat: 48.2668959, lng: 20.0996892 },
    { lat: 48.2667355, lng: 20.0992173 },
    { lat: 48.2661943, lng: 20.0981959 },
    { lat: 48.2654238, lng: 20.0970494 },
    { lat: 48.2650431, lng: 20.096337 },
    { lat: 48.2641462, lng: 20.0984058 },
    { lat: 48.262925, lng: 20.1003099 },
    { lat: 48.2624195, lng: 20.1009679 },
    { lat: 48.2623769, lng: 20.1008909 },
    { lat: 48.2608958, lng: 20.1025618 },
    { lat: 48.2611633, lng: 20.1036371 },
    { lat: 48.260855, lng: 20.1081507 },
    { lat: 48.2610681, lng: 20.1083732 },
    { lat: 48.2609679, lng: 20.1091371 },
    { lat: 48.2602365, lng: 20.1105522 },
    { lat: 48.2612884, lng: 20.1115887 },
    { lat: 48.2629829, lng: 20.1100647 },
    { lat: 48.2638311, lng: 20.1098495 },
    { lat: 48.2642325, lng: 20.1098322 },
    { lat: 48.2643666, lng: 20.1098895 },
    { lat: 48.2659253, lng: 20.114449 },
    { lat: 48.2659129, lng: 20.1146392 },
    { lat: 48.2657063, lng: 20.1151713 },
    { lat: 48.2658004, lng: 20.1156808 },
    { lat: 48.2659495, lng: 20.1159131 },
    { lat: 48.2661888, lng: 20.1160614 },
    { lat: 48.2674206, lng: 20.1175011 },
    { lat: 48.2678477, lng: 20.1177205 },
    { lat: 48.2681087, lng: 20.117467 },
    { lat: 48.26835, lng: 20.1171201 },
    { lat: 48.2684872, lng: 20.1177467 },
    { lat: 48.2687044, lng: 20.1177938 },
    { lat: 48.2687609, lng: 20.1180997 },
    { lat: 48.2686123, lng: 20.1182676 },
    { lat: 48.2688168, lng: 20.1185799 },
    { lat: 48.2689926, lng: 20.1191874 },
    { lat: 48.2692264, lng: 20.1194207 },
    { lat: 48.2693704, lng: 20.1205692 },
    { lat: 48.2696342, lng: 20.1209277 },
    { lat: 48.2696436, lng: 20.1211054 },
    { lat: 48.2694698, lng: 20.1214541 },
    { lat: 48.2690918, lng: 20.1219567 },
    { lat: 48.2691515, lng: 20.1226682 },
    { lat: 48.2695639, lng: 20.1222897 },
    { lat: 48.2697171, lng: 20.1239627 },
    { lat: 48.2701549, lng: 20.1242543 },
    { lat: 48.2698488, lng: 20.1247828 },
    { lat: 48.2697318, lng: 20.1251121 },
    { lat: 48.2698023, lng: 20.1252131 },
    { lat: 48.2697531, lng: 20.1254135 },
    { lat: 48.269472, lng: 20.1254804 },
    { lat: 48.2693208, lng: 20.1253922 },
    { lat: 48.2689704, lng: 20.1256875 },
    { lat: 48.269077, lng: 20.1260079 },
    { lat: 48.2690331, lng: 20.1260936 },
    { lat: 48.2685759, lng: 20.126013 },
    { lat: 48.267964, lng: 20.1266358 },
    { lat: 48.2679261, lng: 20.1263681 },
    { lat: 48.2678549, lng: 20.1262552 },
    { lat: 48.2676461, lng: 20.1268782 },
    { lat: 48.267551, lng: 20.1269715 },
    { lat: 48.2674252, lng: 20.1276947 },
    { lat: 48.2674188, lng: 20.1282514 },
    { lat: 48.2676934, lng: 20.1283587 },
    { lat: 48.2677292, lng: 20.1286562 },
    { lat: 48.2681223, lng: 20.1299168 },
    { lat: 48.2685015, lng: 20.1306448 },
    { lat: 48.2686364, lng: 20.1306936 },
    { lat: 48.2687404, lng: 20.1308707 },
    { lat: 48.2689791, lng: 20.1306772 },
    { lat: 48.2690385, lng: 20.1309387 },
    { lat: 48.2691945, lng: 20.130819 },
    { lat: 48.2692478, lng: 20.1310191 },
    { lat: 48.2691544, lng: 20.1312968 },
    { lat: 48.2693389, lng: 20.1318932 },
    { lat: 48.2696089, lng: 20.1315583 },
    { lat: 48.2699318, lng: 20.1319846 },
    { lat: 48.2695751, lng: 20.1323805 },
    { lat: 48.2696832, lng: 20.13246 },
    { lat: 48.2697392, lng: 20.1324001 },
    { lat: 48.2699856, lng: 20.1326115 },
    { lat: 48.2701495, lng: 20.132298 },
    { lat: 48.2703324, lng: 20.1324986 },
    { lat: 48.2701164, lng: 20.1331948 },
    { lat: 48.2702135, lng: 20.133363 },
    { lat: 48.2709267, lng: 20.1335639 },
    { lat: 48.2707813, lng: 20.1339715 },
    { lat: 48.2708841, lng: 20.1341703 },
    { lat: 48.2709422, lng: 20.1346826 },
    { lat: 48.2711524, lng: 20.1354124 },
    { lat: 48.2713804, lng: 20.135285 },
    { lat: 48.271551, lng: 20.1357679 },
    { lat: 48.2722634, lng: 20.1352579 },
    { lat: 48.2724974, lng: 20.1355253 },
    { lat: 48.2727065, lng: 20.1359547 },
    { lat: 48.2729436, lng: 20.1360217 },
    { lat: 48.2730038, lng: 20.1363393 },
    { lat: 48.2730948, lng: 20.1364179 },
    { lat: 48.2731829, lng: 20.1366654 },
    { lat: 48.2734481, lng: 20.1370388 },
    { lat: 48.2732515, lng: 20.1374076 },
    { lat: 48.2732118, lng: 20.1380066 },
    { lat: 48.2735045, lng: 20.1382478 },
    { lat: 48.2734593, lng: 20.1388208 },
    { lat: 48.2733449, lng: 20.1390031 },
    { lat: 48.2733645, lng: 20.1394815 },
    { lat: 48.2731445, lng: 20.1402976 },
    { lat: 48.2728941, lng: 20.1407268 },
    { lat: 48.2731059, lng: 20.1410553 },
    { lat: 48.2731468, lng: 20.1415355 },
    { lat: 48.2728316, lng: 20.1419488 },
    { lat: 48.2727379, lng: 20.1421681 },
    { lat: 48.2727595, lng: 20.1425568 },
    { lat: 48.2727288, lng: 20.1428004 },
    { lat: 48.2725835, lng: 20.1432921 },
    { lat: 48.2722397, lng: 20.1435068 },
    { lat: 48.2730392, lng: 20.1456206 },
    { lat: 48.272996, lng: 20.1458872 },
    { lat: 48.2728337, lng: 20.1463787 },
    { lat: 48.2728355, lng: 20.1465101 },
    { lat: 48.2729195, lng: 20.1467463 },
    { lat: 48.2729196, lng: 20.1470476 },
    { lat: 48.2727842, lng: 20.1477031 },
    { lat: 48.2723963, lng: 20.1479434 },
    { lat: 48.2724098, lng: 20.1480758 },
    { lat: 48.2724835, lng: 20.1491417 },
    { lat: 48.2723679, lng: 20.1492577 },
    { lat: 48.2725016, lng: 20.1496354 },
    { lat: 48.272989, lng: 20.1497929 },
    { lat: 48.2733576, lng: 20.1500494 },
    { lat: 48.2736977, lng: 20.1499928 },
    { lat: 48.2741187, lng: 20.1500716 },
    { lat: 48.2744481, lng: 20.1517608 },
    { lat: 48.2753915, lng: 20.1518589 },
    { lat: 48.2777526, lng: 20.1578021 },
  ],
  Rakytník: [
    { lat: 48.3865274, lng: 20.1660062 },
    { lat: 48.3864966, lng: 20.1673882 },
    { lat: 48.3864342, lng: 20.1688446 },
    { lat: 48.3866407, lng: 20.1692378 },
    { lat: 48.3865772, lng: 20.1695639 },
    { lat: 48.3866, lng: 20.1709272 },
    { lat: 48.3865626, lng: 20.1722863 },
    { lat: 48.3859241, lng: 20.1756755 },
    { lat: 48.38593, lng: 20.1766831 },
    { lat: 48.3855593, lng: 20.177314 },
    { lat: 48.3851911, lng: 20.1776818 },
    { lat: 48.3832866, lng: 20.1789325 },
    { lat: 48.3823678, lng: 20.1794412 },
    { lat: 48.3834585, lng: 20.1821798 },
    { lat: 48.3836253, lng: 20.1834617 },
    { lat: 48.3839549, lng: 20.184759 },
    { lat: 48.3844042, lng: 20.1862321 },
    { lat: 48.3856166, lng: 20.1906443 },
    { lat: 48.3860322, lng: 20.1913676 },
    { lat: 48.3866392, lng: 20.1922031 },
    { lat: 48.3871953, lng: 20.1932853 },
    { lat: 48.3875161, lng: 20.1942564 },
    { lat: 48.3879565, lng: 20.1951745 },
    { lat: 48.3882428, lng: 20.1962137 },
    { lat: 48.3893344, lng: 20.1984212 },
    { lat: 48.3897943, lng: 20.1989907 },
    { lat: 48.3905076, lng: 20.1995598 },
    { lat: 48.3906436, lng: 20.199742 },
    { lat: 48.3910132, lng: 20.1998611 },
    { lat: 48.3911918, lng: 20.199987 },
    { lat: 48.3913608, lng: 20.2002442 },
    { lat: 48.3915432, lng: 20.2004072 },
    { lat: 48.3920975, lng: 20.2007445 },
    { lat: 48.3925585, lng: 20.2013434 },
    { lat: 48.3933593, lng: 20.2017579 },
    { lat: 48.393679, lng: 20.2017862 },
    { lat: 48.394117, lng: 20.2016979 },
    { lat: 48.3947558, lng: 20.2013669 },
    { lat: 48.3953351, lng: 20.2013508 },
    { lat: 48.3955113, lng: 20.2012487 },
    { lat: 48.3957857, lng: 20.2012641 },
    { lat: 48.3964842, lng: 20.2014665 },
    { lat: 48.3969869, lng: 20.2017309 },
    { lat: 48.3972111, lng: 20.2017594 },
    { lat: 48.3976205, lng: 20.201635 },
    { lat: 48.3978843, lng: 20.201386 },
    { lat: 48.3982931, lng: 20.2006857 },
    { lat: 48.3985937, lng: 20.1999283 },
    { lat: 48.3986863, lng: 20.1993804 },
    { lat: 48.3991257, lng: 20.1978164 },
    { lat: 48.3992352, lng: 20.1969037 },
    { lat: 48.3998639, lng: 20.1961242 },
    { lat: 48.4001243, lng: 20.1960197 },
    { lat: 48.4019609, lng: 20.1956424 },
    { lat: 48.4022903, lng: 20.1956291 },
    { lat: 48.4028137, lng: 20.1957629 },
    { lat: 48.4030899, lng: 20.1966874 },
    { lat: 48.4036783, lng: 20.1981796 },
    { lat: 48.4037573, lng: 20.1987704 },
    { lat: 48.4038867, lng: 20.1991563 },
    { lat: 48.4038919, lng: 20.199678 },
    { lat: 48.4038474, lng: 20.2000028 },
    { lat: 48.4043785, lng: 20.2006844 },
    { lat: 48.4048327, lng: 20.2006629 },
    { lat: 48.4053096, lng: 20.2009017 },
    { lat: 48.4056436, lng: 20.2012833 },
    { lat: 48.4057502, lng: 20.2018017 },
    { lat: 48.4060406, lng: 20.2025779 },
    { lat: 48.4060074, lng: 20.2030945 },
    { lat: 48.4060496, lng: 20.2035263 },
    { lat: 48.4057952, lng: 20.2038156 },
    { lat: 48.4063435, lng: 20.2047002 },
    { lat: 48.4066922, lng: 20.2056416 },
    { lat: 48.406725, lng: 20.2058396 },
    { lat: 48.4068052, lng: 20.2059567 },
    { lat: 48.4068873, lng: 20.2062378 },
    { lat: 48.4068935, lng: 20.2064429 },
    { lat: 48.407678, lng: 20.207858 },
    { lat: 48.4078851, lng: 20.2087803 },
    { lat: 48.4084722, lng: 20.2100745 },
    { lat: 48.4086217, lng: 20.2105455 },
    { lat: 48.4092069, lng: 20.2113425 },
    { lat: 48.4097651, lng: 20.2119774 },
    { lat: 48.4101076, lng: 20.211198 },
    { lat: 48.4109048, lng: 20.2097048 },
    { lat: 48.4111539, lng: 20.2087878 },
    { lat: 48.4113617, lng: 20.2084088 },
    { lat: 48.4115319, lng: 20.2079098 },
    { lat: 48.4118482, lng: 20.206726 },
    { lat: 48.411851, lng: 20.2064627 },
    { lat: 48.4105302, lng: 20.2049892 },
    { lat: 48.4099093, lng: 20.2038353 },
    { lat: 48.408899, lng: 20.2025198 },
    { lat: 48.4088327, lng: 20.2023255 },
    { lat: 48.408347, lng: 20.2017355 },
    { lat: 48.4087164, lng: 20.2015966 },
    { lat: 48.4097004, lng: 20.2014746 },
    { lat: 48.4115099, lng: 20.2002607 },
    { lat: 48.4121983, lng: 20.1988945 },
    { lat: 48.4133167, lng: 20.19733 },
    { lat: 48.413434, lng: 20.1972258 },
    { lat: 48.4134191, lng: 20.1971899 },
    { lat: 48.4126005, lng: 20.1957424 },
    { lat: 48.4120958, lng: 20.1945398 },
    { lat: 48.4125659, lng: 20.1915626 },
    { lat: 48.4127111, lng: 20.1895839 },
    { lat: 48.4126455, lng: 20.1895491 },
    { lat: 48.4128378, lng: 20.1890107 },
    { lat: 48.4134765, lng: 20.1879829 },
    { lat: 48.4135662, lng: 20.18764 },
    { lat: 48.4136631, lng: 20.1858259 },
    { lat: 48.4140603, lng: 20.1845353 },
    { lat: 48.4142796, lng: 20.1841409 },
    { lat: 48.4147895, lng: 20.1835176 },
    { lat: 48.4150282, lng: 20.1829313 },
    { lat: 48.4155353, lng: 20.1811857 },
    { lat: 48.4156833, lng: 20.1802465 },
    { lat: 48.4157439, lng: 20.1784377 },
    { lat: 48.4158289, lng: 20.17764 },
    { lat: 48.41575, lng: 20.1767236 },
    { lat: 48.415664, lng: 20.1764897 },
    { lat: 48.415485, lng: 20.1762408 },
    { lat: 48.4147975, lng: 20.1755659 },
    { lat: 48.4133858, lng: 20.1750014 },
    { lat: 48.4129707, lng: 20.1747331 },
    { lat: 48.4124905, lng: 20.1743062 },
    { lat: 48.4117373, lng: 20.1735639 },
    { lat: 48.4112253, lng: 20.1729116 },
    { lat: 48.4103366, lng: 20.1715469 },
    { lat: 48.4100326, lng: 20.1719958 },
    { lat: 48.4103504, lng: 20.1724468 },
    { lat: 48.4101036, lng: 20.1729132 },
    { lat: 48.4091952, lng: 20.1743416 },
    { lat: 48.4088065, lng: 20.173894 },
    { lat: 48.4081534, lng: 20.1735375 },
    { lat: 48.4084001, lng: 20.172789 },
    { lat: 48.4069709, lng: 20.1705705 },
    { lat: 48.4073689, lng: 20.1698602 },
    { lat: 48.4059089, lng: 20.168187 },
    { lat: 48.406604, lng: 20.1669443 },
    { lat: 48.4075536, lng: 20.1656428 },
    { lat: 48.4073596, lng: 20.1651902 },
    { lat: 48.4072306, lng: 20.1643736 },
    { lat: 48.4065611, lng: 20.1634753 },
    { lat: 48.4066313, lng: 20.1632877 },
    { lat: 48.4065131, lng: 20.1627938 },
    { lat: 48.4064802, lng: 20.1622051 },
    { lat: 48.4065363, lng: 20.1616697 },
    { lat: 48.4068174, lng: 20.1602196 },
    { lat: 48.4044771, lng: 20.1561252 },
    { lat: 48.403946, lng: 20.1567808 },
    { lat: 48.4027984, lng: 20.155318 },
    { lat: 48.4032456, lng: 20.1543769 },
    { lat: 48.4031044, lng: 20.1540925 },
    { lat: 48.4029281, lng: 20.1538825 },
    { lat: 48.4026991, lng: 20.1530698 },
    { lat: 48.4025273, lng: 20.1527535 },
    { lat: 48.4023655, lng: 20.1521814 },
    { lat: 48.4020304, lng: 20.1518357 },
    { lat: 48.4011622, lng: 20.1510673 },
    { lat: 48.4004962, lng: 20.1522163 },
    { lat: 48.3997719, lng: 20.1537123 },
    { lat: 48.4002538, lng: 20.1555601 },
    { lat: 48.4001554, lng: 20.1557694 },
    { lat: 48.3999506, lng: 20.1559172 },
    { lat: 48.3996855, lng: 20.1557366 },
    { lat: 48.3994811, lng: 20.1558926 },
    { lat: 48.3992154, lng: 20.1557025 },
    { lat: 48.3990501, lng: 20.1557063 },
    { lat: 48.3990015, lng: 20.1561177 },
    { lat: 48.3999905, lng: 20.1574773 },
    { lat: 48.3995998, lng: 20.1588094 },
    { lat: 48.4001744, lng: 20.159339 },
    { lat: 48.401975, lng: 20.159965 },
    { lat: 48.4017239, lng: 20.1602673 },
    { lat: 48.401669, lng: 20.1605559 },
    { lat: 48.4009679, lng: 20.1605185 },
    { lat: 48.3997839, lng: 20.1606196 },
    { lat: 48.3997814, lng: 20.1607542 },
    { lat: 48.399504, lng: 20.1609108 },
    { lat: 48.3993369, lng: 20.1621748 },
    { lat: 48.3989601, lng: 20.161963 },
    { lat: 48.3988942, lng: 20.1619804 },
    { lat: 48.3987916, lng: 20.1622269 },
    { lat: 48.3985723, lng: 20.1625096 },
    { lat: 48.3983548, lng: 20.1623959 },
    { lat: 48.3982662, lng: 20.1624927 },
    { lat: 48.398208, lng: 20.1624183 },
    { lat: 48.3976837, lng: 20.1624287 },
    { lat: 48.39741, lng: 20.163152 },
    { lat: 48.3973836, lng: 20.163471 },
    { lat: 48.397173, lng: 20.1635275 },
    { lat: 48.3968348, lng: 20.1634117 },
    { lat: 48.3964988, lng: 20.1634032 },
    { lat: 48.3958712, lng: 20.1636471 },
    { lat: 48.3955694, lng: 20.1635894 },
    { lat: 48.395441, lng: 20.1636709 },
    { lat: 48.3951688, lng: 20.1638798 },
    { lat: 48.3945596, lng: 20.1645466 },
    { lat: 48.3938333, lng: 20.1650197 },
    { lat: 48.3931978, lng: 20.1657902 },
    { lat: 48.3930695, lng: 20.1657934 },
    { lat: 48.3928158, lng: 20.1662051 },
    { lat: 48.392689, lng: 20.1662971 },
    { lat: 48.3927146, lng: 20.1667357 },
    { lat: 48.3925103, lng: 20.166889 },
    { lat: 48.3923636, lng: 20.1667175 },
    { lat: 48.3919665, lng: 20.1668127 },
    { lat: 48.3919241, lng: 20.1669317 },
    { lat: 48.3917186, lng: 20.1671713 },
    { lat: 48.3915437, lng: 20.1671902 },
    { lat: 48.3914136, lng: 20.1672875 },
    { lat: 48.3914066, lng: 20.1674455 },
    { lat: 48.3912163, lng: 20.1678109 },
    { lat: 48.3911028, lng: 20.1674864 },
    { lat: 48.3910724, lng: 20.1672078 },
    { lat: 48.3907392, lng: 20.1672684 },
    { lat: 48.3906476, lng: 20.167477 },
    { lat: 48.3906023, lng: 20.1674716 },
    { lat: 48.3905192, lng: 20.1673655 },
    { lat: 48.3905248, lng: 20.1669808 },
    { lat: 48.390472, lng: 20.1668134 },
    { lat: 48.3902216, lng: 20.1670008 },
    { lat: 48.3900285, lng: 20.1664068 },
    { lat: 48.3898388, lng: 20.1661463 },
    { lat: 48.3898535, lng: 20.165758 },
    { lat: 48.3898053, lng: 20.1657635 },
    { lat: 48.3895984, lng: 20.1661315 },
    { lat: 48.3895713, lng: 20.166325 },
    { lat: 48.389385, lng: 20.1666184 },
    { lat: 48.3892304, lng: 20.1670011 },
    { lat: 48.3888944, lng: 20.1673253 },
    { lat: 48.3887112, lng: 20.1673056 },
    { lat: 48.3884194, lng: 20.1671122 },
    { lat: 48.3881058, lng: 20.1673745 },
    { lat: 48.3879899, lng: 20.1673507 },
    { lat: 48.3878451, lng: 20.1671981 },
    { lat: 48.3873972, lng: 20.1677054 },
    { lat: 48.3872341, lng: 20.167652 },
    { lat: 48.3869598, lng: 20.1672494 },
    { lat: 48.386916, lng: 20.1669501 },
    { lat: 48.3865274, lng: 20.1660062 },
  ],
  Petrovce: [
    { lat: 48.179099, lng: 19.9926064 },
    { lat: 48.1791827, lng: 19.993328 },
    { lat: 48.1789818, lng: 19.9939594 },
    { lat: 48.1789494, lng: 19.9945769 },
    { lat: 48.1787255, lng: 19.9952772 },
    { lat: 48.1787036, lng: 19.9958535 },
    { lat: 48.1787778, lng: 19.996725 },
    { lat: 48.1783815, lng: 19.9976918 },
    { lat: 48.1782528, lng: 19.9978716 },
    { lat: 48.1779826, lng: 19.9978234 },
    { lat: 48.1778176, lng: 19.9981577 },
    { lat: 48.1769537, lng: 19.9993317 },
    { lat: 48.1764832, lng: 20.0003823 },
    { lat: 48.1765467, lng: 20.0010578 },
    { lat: 48.176143, lng: 20.0013684 },
    { lat: 48.1743341, lng: 20.0024935 },
    { lat: 48.1741135, lng: 20.002261 },
    { lat: 48.1724451, lng: 20.0035919 },
    { lat: 48.172283, lng: 20.003544 },
    { lat: 48.172283, lng: 20.003643 },
    { lat: 48.172169, lng: 20.003634 },
    { lat: 48.17202, lng: 20.00363 },
    { lat: 48.171759, lng: 20.00365 },
    { lat: 48.171768, lng: 20.003858 },
    { lat: 48.17177, lng: 20.004142 },
    { lat: 48.17172, lng: 20.00463 },
    { lat: 48.171711, lng: 20.004874 },
    { lat: 48.171703, lng: 20.005032 },
    { lat: 48.171699, lng: 20.005523 },
    { lat: 48.171704, lng: 20.005822 },
    { lat: 48.171714, lng: 20.00598 },
    { lat: 48.171759, lng: 20.006172 },
    { lat: 48.171812, lng: 20.006329 },
    { lat: 48.171855, lng: 20.006454 },
    { lat: 48.171918, lng: 20.006603 },
    { lat: 48.172, lng: 20.006848 },
    { lat: 48.1721, lng: 20.00724 },
    { lat: 48.172157, lng: 20.007528 },
    { lat: 48.172206, lng: 20.007732 },
    { lat: 48.17227, lng: 20.007934 },
    { lat: 48.172322, lng: 20.008063 },
    { lat: 48.172369, lng: 20.008247 },
    { lat: 48.172386, lng: 20.008438 },
    { lat: 48.172389, lng: 20.00863 },
    { lat: 48.172369, lng: 20.008867 },
    { lat: 48.172331, lng: 20.009048 },
    { lat: 48.172286, lng: 20.009261 },
    { lat: 48.172259, lng: 20.009387 },
    { lat: 48.1722095, lng: 20.00964 },
    { lat: 48.172193, lng: 20.009774 },
    { lat: 48.172203, lng: 20.010398 },
    { lat: 48.172214, lng: 20.010918 },
    { lat: 48.172215, lng: 20.011311 },
    { lat: 48.172225, lng: 20.011773 },
    { lat: 48.1722309, lng: 20.0118291 },
    { lat: 48.172249, lng: 20.012002 },
    { lat: 48.172277, lng: 20.012163 },
    { lat: 48.172297, lng: 20.012209 },
    { lat: 48.172322, lng: 20.012244 },
    { lat: 48.172514, lng: 20.01239 },
    { lat: 48.172624, lng: 20.012477 },
    { lat: 48.172787, lng: 20.012622 },
    { lat: 48.172866, lng: 20.012713 },
    { lat: 48.17297, lng: 20.012827 },
    { lat: 48.173126, lng: 20.013022 },
    { lat: 48.173161, lng: 20.013072 },
    { lat: 48.173265, lng: 20.013217 },
    { lat: 48.173281, lng: 20.01325 },
    { lat: 48.173292, lng: 20.013283 },
    { lat: 48.173381, lng: 20.013556 },
    { lat: 48.173475, lng: 20.01384 },
    { lat: 48.173509, lng: 20.013958 },
    { lat: 48.173581, lng: 20.014166 },
    { lat: 48.173676, lng: 20.01452 },
    { lat: 48.173721, lng: 20.014674 },
    { lat: 48.173763, lng: 20.014807 },
    { lat: 48.173787, lng: 20.015042 },
    { lat: 48.1737888, lng: 20.0151525 },
    { lat: 48.173773, lng: 20.015442 },
    { lat: 48.173748, lng: 20.015858 },
    { lat: 48.173773, lng: 20.016053 },
    { lat: 48.173796, lng: 20.016156 },
    { lat: 48.173851, lng: 20.016246 },
    { lat: 48.173914, lng: 20.016312 },
    { lat: 48.174035, lng: 20.016401 },
    { lat: 48.174431, lng: 20.016689 },
    { lat: 48.174784, lng: 20.016986 },
    { lat: 48.174897, lng: 20.017079 },
    { lat: 48.174995, lng: 20.017145 },
    { lat: 48.175093, lng: 20.017158 },
    { lat: 48.175125, lng: 20.017154 },
    { lat: 48.175222, lng: 20.017085 },
    { lat: 48.175341, lng: 20.016949 },
    { lat: 48.175528, lng: 20.016727 },
    { lat: 48.17564, lng: 20.01658 },
    { lat: 48.175769, lng: 20.016398 },
    { lat: 48.175893, lng: 20.016208 },
    { lat: 48.175927, lng: 20.016257 },
    { lat: 48.175967, lng: 20.016796 },
    { lat: 48.176021, lng: 20.01754 },
    { lat: 48.176131, lng: 20.019019 },
    { lat: 48.1762275, lng: 20.0200435 },
    { lat: 48.176268, lng: 20.020441 },
    { lat: 48.176323, lng: 20.021306 },
    { lat: 48.176349, lng: 20.021707 },
    { lat: 48.176429, lng: 20.022274 },
    { lat: 48.176468, lng: 20.022552 },
    { lat: 48.176652, lng: 20.023501 },
    { lat: 48.176677, lng: 20.023862 },
    { lat: 48.176686, lng: 20.024071 },
    { lat: 48.17676, lng: 20.024744 },
    { lat: 48.176802, lng: 20.025184 },
    { lat: 48.176938, lng: 20.026531 },
    { lat: 48.177083, lng: 20.027419 },
    { lat: 48.177192, lng: 20.028094 },
    { lat: 48.177451, lng: 20.027894 },
    { lat: 48.177569, lng: 20.028282 },
    { lat: 48.177639, lng: 20.028582 },
    { lat: 48.177685, lng: 20.029038 },
    { lat: 48.177322, lng: 20.029678 },
    { lat: 48.177025, lng: 20.030116 },
    { lat: 48.176712, lng: 20.030465 },
    { lat: 48.176894, lng: 20.030762 },
    { lat: 48.1766951, lng: 20.0311287 },
    { lat: 48.176587, lng: 20.031328 },
    { lat: 48.176325, lng: 20.03181 },
    { lat: 48.176291, lng: 20.031872 },
    { lat: 48.176283, lng: 20.03201 },
    { lat: 48.176239, lng: 20.032188 },
    { lat: 48.176191, lng: 20.032295 },
    { lat: 48.176068, lng: 20.03239 },
    { lat: 48.175835, lng: 20.03261 },
    { lat: 48.1757032, lng: 20.0327175 },
    { lat: 48.175662, lng: 20.032751 },
    { lat: 48.175448, lng: 20.032939 },
    { lat: 48.17525, lng: 20.033241 },
    { lat: 48.175121, lng: 20.033453 },
    { lat: 48.17508, lng: 20.033584 },
    { lat: 48.174904, lng: 20.034174 },
    { lat: 48.174796, lng: 20.034572 },
    { lat: 48.174694, lng: 20.035011 },
    { lat: 48.174592, lng: 20.035346 },
    { lat: 48.174541, lng: 20.035558 },
    { lat: 48.174462, lng: 20.036618 },
    { lat: 48.174427, lng: 20.036886 },
    { lat: 48.174415, lng: 20.037229 },
    { lat: 48.174428, lng: 20.037542 },
    { lat: 48.174452, lng: 20.037791 },
    { lat: 48.174525, lng: 20.038247 },
    { lat: 48.174508, lng: 20.038974 },
    { lat: 48.174424, lng: 20.039555 },
    { lat: 48.174344, lng: 20.039877 },
    { lat: 48.174302, lng: 20.040227 },
    { lat: 48.174286, lng: 20.04042 },
    { lat: 48.174235, lng: 20.040759 },
    { lat: 48.174169, lng: 20.040989 },
    { lat: 48.174126, lng: 20.041096 },
    { lat: 48.173999, lng: 20.041284 },
    { lat: 48.173886, lng: 20.041516 },
    { lat: 48.173763, lng: 20.041812 },
    { lat: 48.173719, lng: 20.041955 },
    { lat: 48.173621, lng: 20.042249 },
    { lat: 48.173541, lng: 20.042378 },
    { lat: 48.173376, lng: 20.042601 },
    { lat: 48.173273, lng: 20.042772 },
    { lat: 48.173202, lng: 20.04289 },
    { lat: 48.17313, lng: 20.043029 },
    { lat: 48.173009, lng: 20.043204 },
    { lat: 48.172772, lng: 20.043318 },
    { lat: 48.172647, lng: 20.043336 },
    { lat: 48.172465, lng: 20.043385 },
    { lat: 48.1723, lng: 20.043478 },
    { lat: 48.1721, lng: 20.04361 },
    { lat: 48.17197, lng: 20.043649 },
    { lat: 48.17175, lng: 20.04356 },
    { lat: 48.171608, lng: 20.043473 },
    { lat: 48.171417, lng: 20.043384 },
    { lat: 48.171272, lng: 20.043309 },
    { lat: 48.171078, lng: 20.043189 },
    { lat: 48.170865, lng: 20.043082 },
    { lat: 48.170757, lng: 20.0431 },
    { lat: 48.170605, lng: 20.043142 },
    { lat: 48.170469, lng: 20.043161 },
    { lat: 48.170181, lng: 20.043187 },
    { lat: 48.170002, lng: 20.043235 },
    { lat: 48.16985, lng: 20.04329 },
    { lat: 48.169653, lng: 20.043414 },
    { lat: 48.169515, lng: 20.043483 },
    { lat: 48.1693093, lng: 20.0435139 },
    { lat: 48.169128, lng: 20.043583 },
    { lat: 48.16899, lng: 20.043565 },
    { lat: 48.168841, lng: 20.043542 },
    { lat: 48.168724, lng: 20.043555 },
    { lat: 48.168539, lng: 20.043588 },
    { lat: 48.168402, lng: 20.043585 },
    { lat: 48.168284, lng: 20.04361 },
    { lat: 48.168186, lng: 20.043659 },
    { lat: 48.168079, lng: 20.043722 },
    { lat: 48.167912, lng: 20.043851 },
    { lat: 48.167744, lng: 20.043962 },
    { lat: 48.1676, lng: 20.044037 },
    { lat: 48.167469, lng: 20.044141 },
    { lat: 48.167337, lng: 20.044231 },
    { lat: 48.167282, lng: 20.044277 },
    { lat: 48.167243, lng: 20.044388 },
    { lat: 48.167215, lng: 20.0445 },
    { lat: 48.167221, lng: 20.044786 },
    { lat: 48.167214, lng: 20.044907 },
    { lat: 48.167207, lng: 20.044997 },
    { lat: 48.167174, lng: 20.045252 },
    { lat: 48.16716, lng: 20.04551 },
    { lat: 48.167142, lng: 20.045848 },
    { lat: 48.167138, lng: 20.046113 },
    { lat: 48.167149, lng: 20.046381 },
    { lat: 48.167156, lng: 20.046678 },
    { lat: 48.167241, lng: 20.046941 },
    { lat: 48.167338, lng: 20.047249 },
    { lat: 48.167364, lng: 20.04751 },
    { lat: 48.16738, lng: 20.047759 },
    { lat: 48.167386, lng: 20.047944 },
    { lat: 48.167399, lng: 20.048308 },
    { lat: 48.167327, lng: 20.048671 },
    { lat: 48.167239, lng: 20.048994 },
    { lat: 48.167206, lng: 20.049223 },
    { lat: 48.1672, lng: 20.049449 },
    { lat: 48.167249, lng: 20.049688 },
    { lat: 48.167281, lng: 20.049777 },
    { lat: 48.167369, lng: 20.050037 },
    { lat: 48.1674, lng: 20.050202 },
    { lat: 48.167437, lng: 20.050408 },
    { lat: 48.167587, lng: 20.051145 },
    { lat: 48.167663, lng: 20.05165 },
    { lat: 48.167699, lng: 20.051884 },
    { lat: 48.1677197, lng: 20.0519069 },
    { lat: 48.16875, lng: 20.052946 },
    { lat: 48.168898, lng: 20.053094 },
    { lat: 48.169081, lng: 20.053277 },
    { lat: 48.169709, lng: 20.054178 },
    { lat: 48.170014, lng: 20.054206 },
    { lat: 48.170587, lng: 20.054116 },
    { lat: 48.171091, lng: 20.054232 },
    { lat: 48.171689, lng: 20.05444 },
    { lat: 48.172408, lng: 20.054878 },
    { lat: 48.172943, lng: 20.055278 },
    { lat: 48.173364, lng: 20.055597 },
    { lat: 48.174161, lng: 20.055991 },
    { lat: 48.174514, lng: 20.056274 },
    { lat: 48.174633, lng: 20.056372 },
    { lat: 48.174677, lng: 20.057294 },
    { lat: 48.174668, lng: 20.058064 },
    { lat: 48.174836, lng: 20.058524 },
    { lat: 48.175151, lng: 20.058944 },
    { lat: 48.175495, lng: 20.059119 },
    { lat: 48.175688, lng: 20.059351 },
    { lat: 48.175844, lng: 20.05961 },
    { lat: 48.176209, lng: 20.060205 },
    { lat: 48.176722, lng: 20.060555 },
    { lat: 48.177005, lng: 20.060689 },
    { lat: 48.177424, lng: 20.06089 },
    { lat: 48.177753, lng: 20.061046 },
    { lat: 48.178117, lng: 20.061219 },
    { lat: 48.178365, lng: 20.061339 },
    { lat: 48.178371, lng: 20.061782 },
    { lat: 48.178286, lng: 20.06259 },
    { lat: 48.178324, lng: 20.063384 },
    { lat: 48.178441, lng: 20.063835 },
    { lat: 48.178371, lng: 20.064556 },
    { lat: 48.178163, lng: 20.065103 },
    { lat: 48.178048, lng: 20.065891 },
    { lat: 48.178104, lng: 20.066412 },
    { lat: 48.178459, lng: 20.067052 },
    { lat: 48.178558, lng: 20.067138 },
    { lat: 48.17854, lng: 20.067644 },
    { lat: 48.178566, lng: 20.067973 },
    { lat: 48.178516, lng: 20.068572 },
    { lat: 48.178711, lng: 20.069331 },
    { lat: 48.179194, lng: 20.06959 },
    { lat: 48.179304, lng: 20.070604 },
    { lat: 48.179171, lng: 20.071065 },
    { lat: 48.179116, lng: 20.071474 },
    { lat: 48.179347, lng: 20.072091 },
    { lat: 48.179485, lng: 20.072544 },
    { lat: 48.179606, lng: 20.072986 },
    { lat: 48.179918, lng: 20.073411 },
    { lat: 48.180061, lng: 20.073498 },
    { lat: 48.180456, lng: 20.072839 },
    { lat: 48.180881, lng: 20.073337 },
    { lat: 48.180942, lng: 20.07342 },
    { lat: 48.181445, lng: 20.074104 },
    { lat: 48.181805, lng: 20.074675 },
    { lat: 48.181941, lng: 20.074988 },
    { lat: 48.182047, lng: 20.075238 },
    { lat: 48.182157, lng: 20.075714 },
    { lat: 48.18244, lng: 20.076947 },
    { lat: 48.182532, lng: 20.077056 },
    { lat: 48.182856, lng: 20.077441 },
    { lat: 48.183247, lng: 20.078435 },
    { lat: 48.183739, lng: 20.078844 },
    { lat: 48.184056, lng: 20.078501 },
    { lat: 48.184088, lng: 20.078435 },
    { lat: 48.184362, lng: 20.077859 },
    { lat: 48.184587, lng: 20.077269 },
    { lat: 48.184647, lng: 20.076813 },
    { lat: 48.184619, lng: 20.076222 },
    { lat: 48.185371, lng: 20.075599 },
    { lat: 48.186157, lng: 20.075043 },
    { lat: 48.186299, lng: 20.074843 },
    { lat: 48.186958, lng: 20.07446 },
    { lat: 48.187177, lng: 20.07399 },
    { lat: 48.187317, lng: 20.073483 },
    { lat: 48.187326, lng: 20.072252 },
    { lat: 48.187305, lng: 20.071807 },
    { lat: 48.187219, lng: 20.07128 },
    { lat: 48.186617, lng: 20.069967 },
    { lat: 48.187191, lng: 20.069046 },
    { lat: 48.187684, lng: 20.068263 },
    { lat: 48.188054, lng: 20.067814 },
    { lat: 48.188502, lng: 20.067496 },
    { lat: 48.18863, lng: 20.067664 },
    { lat: 48.189277, lng: 20.06782 },
    { lat: 48.189594, lng: 20.067927 },
    { lat: 48.189911, lng: 20.068426 },
    { lat: 48.190015, lng: 20.068589 },
    { lat: 48.190471, lng: 20.068984 },
    { lat: 48.190519, lng: 20.069036 },
    { lat: 48.190596, lng: 20.06912 },
    { lat: 48.191105, lng: 20.06954 },
    { lat: 48.191702, lng: 20.070037 },
    { lat: 48.19166, lng: 20.070408 },
    { lat: 48.191663, lng: 20.070639 },
    { lat: 48.192151, lng: 20.071429 },
    { lat: 48.192564, lng: 20.071993 },
    { lat: 48.192897, lng: 20.072389 },
    { lat: 48.193179, lng: 20.072644 },
    { lat: 48.193781, lng: 20.074075 },
    { lat: 48.193997, lng: 20.074763 },
    { lat: 48.194266, lng: 20.076295 },
    { lat: 48.195227, lng: 20.076278 },
    { lat: 48.195557, lng: 20.076388 },
    { lat: 48.1955833, lng: 20.0757706 },
    { lat: 48.1957475, lng: 20.0745751 },
    { lat: 48.1963225, lng: 20.073747 },
    { lat: 48.1967248, lng: 20.0733964 },
    { lat: 48.197193, lng: 20.07252 },
    { lat: 48.1972661, lng: 20.0706808 },
    { lat: 48.1974304, lng: 20.0696204 },
    { lat: 48.1976737, lng: 20.0691321 },
    { lat: 48.197986, lng: 20.0680547 },
    { lat: 48.1980877, lng: 20.0673335 },
    { lat: 48.1987872, lng: 20.066469 },
    { lat: 48.1990117, lng: 20.0659866 },
    { lat: 48.1993245, lng: 20.0656248 },
    { lat: 48.1997856, lng: 20.0655031 },
    { lat: 48.2000476, lng: 20.0652485 },
    { lat: 48.2004314, lng: 20.0653229 },
    { lat: 48.200844, lng: 20.0651926 },
    { lat: 48.2011839, lng: 20.0648905 },
    { lat: 48.2012592, lng: 20.0637755 },
    { lat: 48.2012405, lng: 20.0619224 },
    { lat: 48.2011641, lng: 20.0612492 },
    { lat: 48.2010652, lng: 20.0609897 },
    { lat: 48.201068, lng: 20.0606208 },
    { lat: 48.2014035, lng: 20.0596439 },
    { lat: 48.2015311, lng: 20.0590067 },
    { lat: 48.2015155, lng: 20.0579552 },
    { lat: 48.2011414, lng: 20.0573055 },
    { lat: 48.2011498, lng: 20.0568616 },
    { lat: 48.2017239, lng: 20.0545838 },
    { lat: 48.2035713, lng: 20.0553653 },
    { lat: 48.2036079, lng: 20.0552422 },
    { lat: 48.2039203, lng: 20.0554422 },
    { lat: 48.2051566, lng: 20.0564814 },
    { lat: 48.2054986, lng: 20.0569043 },
    { lat: 48.2059343, lng: 20.0577069 },
    { lat: 48.2062065, lng: 20.0580763 },
    { lat: 48.2065569, lng: 20.0584853 },
    { lat: 48.2070082, lng: 20.0587734 },
    { lat: 48.2075599, lng: 20.0589779 },
    { lat: 48.2087175, lng: 20.0588889 },
    { lat: 48.2097478, lng: 20.0591257 },
    { lat: 48.2097733, lng: 20.0576546 },
    { lat: 48.2095034, lng: 20.05621 },
    { lat: 48.2095326, lng: 20.0559929 },
    { lat: 48.2096971, lng: 20.0560213 },
    { lat: 48.2095236, lng: 20.0538332 },
    { lat: 48.209828, lng: 20.0535427 },
    { lat: 48.2099503, lng: 20.0525873 },
    { lat: 48.2110388, lng: 20.0518951 },
    { lat: 48.2114268, lng: 20.0515816 },
    { lat: 48.2116613, lng: 20.0511546 },
    { lat: 48.2117255, lng: 20.0508332 },
    { lat: 48.2123294, lng: 20.0501639 },
    { lat: 48.2137408, lng: 20.0495375 },
    { lat: 48.2146368, lng: 20.0490687 },
    { lat: 48.2151532, lng: 20.0481074 },
    { lat: 48.2151965, lng: 20.0477417 },
    { lat: 48.2150386, lng: 20.0463073 },
    { lat: 48.2153789, lng: 20.0456916 },
    { lat: 48.2158253, lng: 20.0451328 },
    { lat: 48.216108, lng: 20.0446729 },
    { lat: 48.2164102, lng: 20.0443871 },
    { lat: 48.2169836, lng: 20.0436599 },
    { lat: 48.2171734, lng: 20.0433221 },
    { lat: 48.2172957, lng: 20.0419642 },
    { lat: 48.2175247, lng: 20.0412491 },
    { lat: 48.2177946, lng: 20.0399483 },
    { lat: 48.2181262, lng: 20.038775 },
    { lat: 48.2182228, lng: 20.0373951 },
    { lat: 48.2183627, lng: 20.0368864 },
    { lat: 48.2185358, lng: 20.0348883 },
    { lat: 48.2186957, lng: 20.0340294 },
    { lat: 48.2186789, lng: 20.0336785 },
    { lat: 48.2185457, lng: 20.0332145 },
    { lat: 48.2184618, lng: 20.032345 },
    { lat: 48.2182438, lng: 20.0317413 },
    { lat: 48.2181393, lng: 20.031642 },
    { lat: 48.2178632, lng: 20.0313659 },
    { lat: 48.2172058, lng: 20.0303828 },
    { lat: 48.2169262, lng: 20.0298676 },
    { lat: 48.2166086, lng: 20.0290102 },
    { lat: 48.2164319, lng: 20.0279658 },
    { lat: 48.2163373, lng: 20.0267854 },
    { lat: 48.2161097, lng: 20.0251217 },
    { lat: 48.2158554, lng: 20.0236018 },
    { lat: 48.21561, lng: 20.0226942 },
    { lat: 48.2152843, lng: 20.0207642 },
    { lat: 48.2149556, lng: 20.0196953 },
    { lat: 48.2148079, lng: 20.0193984 },
    { lat: 48.2139976, lng: 20.0182102 },
    { lat: 48.2133936, lng: 20.0175872 },
    { lat: 48.2132094, lng: 20.0174965 },
    { lat: 48.212384, lng: 20.0173418 },
    { lat: 48.2122352, lng: 20.0170196 },
    { lat: 48.2121377, lng: 20.0159597 },
    { lat: 48.2117492, lng: 20.015131 },
    { lat: 48.2114111, lng: 20.0140288 },
    { lat: 48.2110757, lng: 20.0134459 },
    { lat: 48.2112876, lng: 20.0149527 },
    { lat: 48.2107947, lng: 20.0186039 },
    { lat: 48.2107437, lng: 20.0192391 },
    { lat: 48.2107474, lng: 20.0202916 },
    { lat: 48.2092149, lng: 20.0199318 },
    { lat: 48.2073963, lng: 20.0196001 },
    { lat: 48.2058505, lng: 20.0191633 },
    { lat: 48.2050934, lng: 20.0190265 },
    { lat: 48.203784, lng: 20.0208405 },
    { lat: 48.2015086, lng: 20.0237353 },
    { lat: 48.2012245, lng: 20.0231932 },
    { lat: 48.2011683, lng: 20.0225802 },
    { lat: 48.2006945, lng: 20.0213836 },
    { lat: 48.2002722, lng: 20.0206638 },
    { lat: 48.1999153, lng: 20.0201869 },
    { lat: 48.1994717, lng: 20.0199779 },
    { lat: 48.1986614, lng: 20.0197272 },
    { lat: 48.1969461, lng: 20.0186348 },
    { lat: 48.1964815, lng: 20.0172889 },
    { lat: 48.196239, lng: 20.0157829 },
    { lat: 48.196161, lng: 20.0150237 },
    { lat: 48.1951615, lng: 20.0139461 },
    { lat: 48.1937918, lng: 20.0130403 },
    { lat: 48.1930966, lng: 20.0130119 },
    { lat: 48.1926519, lng: 20.0130972 },
    { lat: 48.1920799, lng: 20.0133707 },
    { lat: 48.1917423, lng: 20.0139416 },
    { lat: 48.1912324, lng: 20.0141093 },
    { lat: 48.1911289, lng: 20.0143542 },
    { lat: 48.1910523, lng: 20.0144055 },
    { lat: 48.1905788, lng: 20.0143728 },
    { lat: 48.1892813, lng: 20.0140569 },
    { lat: 48.1884917, lng: 20.0136798 },
    { lat: 48.1886022, lng: 20.0124543 },
    { lat: 48.1883337, lng: 20.012368 },
    { lat: 48.188237, lng: 20.0122631 },
    { lat: 48.1876161, lng: 20.011964 },
    { lat: 48.1877097, lng: 20.0111884 },
    { lat: 48.1874713, lng: 20.0111498 },
    { lat: 48.1874145, lng: 20.0081454 },
    { lat: 48.1869812, lng: 20.0080183 },
    { lat: 48.1866149, lng: 20.007829 },
    { lat: 48.1858088, lng: 20.0072537 },
    { lat: 48.1857783, lng: 20.0068778 },
    { lat: 48.185843, lng: 20.0063793 },
    { lat: 48.1856091, lng: 20.0061733 },
    { lat: 48.1857228, lng: 20.0060645 },
    { lat: 48.1858864, lng: 20.0061267 },
    { lat: 48.1860029, lng: 20.0059244 },
    { lat: 48.185967, lng: 20.0053679 },
    { lat: 48.1861059, lng: 20.0051363 },
    { lat: 48.1863647, lng: 20.0049138 },
    { lat: 48.1863719, lng: 20.0044701 },
    { lat: 48.1863236, lng: 20.0043649 },
    { lat: 48.186507, lng: 20.0040408 },
    { lat: 48.1861117, lng: 20.0037523 },
    { lat: 48.1856938, lng: 20.0026332 },
    { lat: 48.1854393, lng: 20.0016484 },
    { lat: 48.1854112, lng: 20.0013513 },
    { lat: 48.1856155, lng: 20.0000009 },
    { lat: 48.185676, lng: 19.998131 },
    { lat: 48.1854045, lng: 19.9969299 },
    { lat: 48.1858255, lng: 19.9963896 },
    { lat: 48.186155, lng: 19.9950721 },
    { lat: 48.1863487, lng: 19.9938705 },
    { lat: 48.1846941, lng: 19.9936334 },
    { lat: 48.1837222, lng: 19.9934045 },
    { lat: 48.1835958, lng: 19.9932392 },
    { lat: 48.1832553, lng: 19.9924331 },
    { lat: 48.1828006, lng: 19.9918573 },
    { lat: 48.1825694, lng: 19.9914754 },
    { lat: 48.1819921, lng: 19.9903454 },
    { lat: 48.1821112, lng: 19.9898361 },
    { lat: 48.1823642, lng: 19.9892392 },
    { lat: 48.1821293, lng: 19.9890652 },
    { lat: 48.1817118, lng: 19.9893123 },
    { lat: 48.1814622, lng: 19.989589 },
    { lat: 48.18041, lng: 19.9916659 },
    { lat: 48.1794797, lng: 19.9928164 },
    { lat: 48.179208, lng: 19.9926776 },
    { lat: 48.179099, lng: 19.9926064 },
  ],
  Konrádovce: [
    { lat: 48.3165309, lng: 19.9223479 },
    { lat: 48.316259, lng: 19.9215214 },
    { lat: 48.3160489, lng: 19.920717 },
    { lat: 48.3160203, lng: 19.9184462 },
    { lat: 48.3154772, lng: 19.9160263 },
    { lat: 48.3155538, lng: 19.9137708 },
    { lat: 48.315692, lng: 19.9131297 },
    { lat: 48.315772, lng: 19.911501 },
    { lat: 48.3161925, lng: 19.9101151 },
    { lat: 48.3164421, lng: 19.9080609 },
    { lat: 48.3156601, lng: 19.9062227 },
    { lat: 48.3155957, lng: 19.9050861 },
    { lat: 48.3154668, lng: 19.9043185 },
    { lat: 48.315467, lng: 19.9027998 },
    { lat: 48.3156335, lng: 19.9022489 },
    { lat: 48.3153125, lng: 19.9004643 },
    { lat: 48.3153233, lng: 19.8991187 },
    { lat: 48.3152716, lng: 19.8978913 },
    { lat: 48.3150975, lng: 19.8969105 },
    { lat: 48.3152795, lng: 19.8966922 },
    { lat: 48.3150844, lng: 19.895191 },
    { lat: 48.3149637, lng: 19.8931726 },
    { lat: 48.3135064, lng: 19.8899192 },
    { lat: 48.312997, lng: 19.8882946 },
    { lat: 48.3119643, lng: 19.8863962 },
    { lat: 48.3117163, lng: 19.8857655 },
    { lat: 48.3113491, lng: 19.8845928 },
    { lat: 48.3110323, lng: 19.8830441 },
    { lat: 48.3109147, lng: 19.8822278 },
    { lat: 48.310947, lng: 19.880661 },
    { lat: 48.310077, lng: 19.880755 },
    { lat: 48.307499, lng: 19.880253 },
    { lat: 48.306421, lng: 19.880216 },
    { lat: 48.304228, lng: 19.880319 },
    { lat: 48.302841, lng: 19.88029 },
    { lat: 48.301832, lng: 19.880364 },
    { lat: 48.300656, lng: 19.880241 },
    { lat: 48.298096, lng: 19.880533 },
    { lat: 48.296984, lng: 19.880393 },
    { lat: 48.296545, lng: 19.880535 },
    { lat: 48.29674, lng: 19.880911 },
    { lat: 48.296556, lng: 19.881879 },
    { lat: 48.296578, lng: 19.882335 },
    { lat: 48.295786, lng: 19.883425 },
    { lat: 48.295405, lng: 19.883878 },
    { lat: 48.29475, lng: 19.884489 },
    { lat: 48.295136, lng: 19.885771 },
    { lat: 48.295083, lng: 19.886392 },
    { lat: 48.295079, lng: 19.886439 },
    { lat: 48.294835, lng: 19.887237 },
    { lat: 48.294575, lng: 19.887915 },
    { lat: 48.294532, lng: 19.888025 },
    { lat: 48.293841, lng: 19.888534 },
    { lat: 48.293484, lng: 19.888712 },
    { lat: 48.293013, lng: 19.888653 },
    { lat: 48.292394, lng: 19.888332 },
    { lat: 48.292149, lng: 19.888148 },
    { lat: 48.291871, lng: 19.887939 },
    { lat: 48.291537, lng: 19.887948 },
    { lat: 48.291176, lng: 19.888105 },
    { lat: 48.290843, lng: 19.888827 },
    { lat: 48.290768, lng: 19.889036 },
    { lat: 48.290415, lng: 19.890024 },
    { lat: 48.2902, lng: 19.89039 },
    { lat: 48.290322, lng: 19.890564 },
    { lat: 48.290294, lng: 19.891138 },
    { lat: 48.290166, lng: 19.891522 },
    { lat: 48.289978, lng: 19.892078 },
    { lat: 48.289641, lng: 19.892738 },
    { lat: 48.289534, lng: 19.893771 },
    { lat: 48.289039, lng: 19.895373 },
    { lat: 48.28896, lng: 19.895552 },
    { lat: 48.288944, lng: 19.895588 },
    { lat: 48.288913, lng: 19.895659 },
    { lat: 48.288879, lng: 19.895737 },
    { lat: 48.28879, lng: 19.895931 },
    { lat: 48.288747, lng: 19.896027 },
    { lat: 48.288492, lng: 19.89659 },
    { lat: 48.288012, lng: 19.897012 },
    { lat: 48.287408, lng: 19.897623 },
    { lat: 48.286837, lng: 19.8983 },
    { lat: 48.286421, lng: 19.899104 },
    { lat: 48.286167, lng: 19.90032 },
    { lat: 48.286057, lng: 19.900846 },
    { lat: 48.286244, lng: 19.902071 },
    { lat: 48.286279, lng: 19.902811 },
    { lat: 48.286252, lng: 19.903463 },
    { lat: 48.286162, lng: 19.904043 },
    { lat: 48.285956, lng: 19.90489 },
    { lat: 48.285677, lng: 19.905553 },
    { lat: 48.2858145, lng: 19.9061849 },
    { lat: 48.2871925, lng: 19.9068669 },
    { lat: 48.2876422, lng: 19.9072409 },
    { lat: 48.2879563, lng: 19.9088593 },
    { lat: 48.2882396, lng: 19.9097662 },
    { lat: 48.2882527, lng: 19.9102301 },
    { lat: 48.2883728, lng: 19.9109546 },
    { lat: 48.2883817, lng: 19.9113157 },
    { lat: 48.2882536, lng: 19.9123719 },
    { lat: 48.2882582, lng: 19.9128769 },
    { lat: 48.2883512, lng: 19.9132553 },
    { lat: 48.2886973, lng: 19.9135258 },
    { lat: 48.2892113, lng: 19.9143465 },
    { lat: 48.2899305, lng: 19.9150657 },
    { lat: 48.2900964, lng: 19.9154639 },
    { lat: 48.290169, lng: 19.9159257 },
    { lat: 48.2902867, lng: 19.9161199 },
    { lat: 48.2903389, lng: 19.9167314 },
    { lat: 48.2904521, lng: 19.9172695 },
    { lat: 48.2904056, lng: 19.9175287 },
    { lat: 48.2904125, lng: 19.9181354 },
    { lat: 48.2906956, lng: 19.9197163 },
    { lat: 48.2907153, lng: 19.9203342 },
    { lat: 48.2906894, lng: 19.9206519 },
    { lat: 48.290609, lng: 19.9207968 },
    { lat: 48.2906773, lng: 19.9213934 },
    { lat: 48.2905848, lng: 19.921946 },
    { lat: 48.2906443, lng: 19.9220931 },
    { lat: 48.290625, lng: 19.9232259 },
    { lat: 48.2908053, lng: 19.9243034 },
    { lat: 48.2918981, lng: 19.9279612 },
    { lat: 48.292835, lng: 19.9291632 },
    { lat: 48.2934612, lng: 19.930187 },
    { lat: 48.2936016, lng: 19.9294136 },
    { lat: 48.2938386, lng: 19.9286341 },
    { lat: 48.2944469, lng: 19.9274747 },
    { lat: 48.2949744, lng: 19.9259785 },
    { lat: 48.2957688, lng: 19.9242744 },
    { lat: 48.2960163, lng: 19.9235045 },
    { lat: 48.2964645, lng: 19.922026 },
    { lat: 48.2965042, lng: 19.9216914 },
    { lat: 48.2965183, lng: 19.9203404 },
    { lat: 48.2965944, lng: 19.9198515 },
    { lat: 48.2969254, lng: 19.9193735 },
    { lat: 48.2970941, lng: 19.9192119 },
    { lat: 48.297384, lng: 19.9187432 },
    { lat: 48.2981024, lng: 19.917897 },
    { lat: 48.298453, lng: 19.9173244 },
    { lat: 48.2988336, lng: 19.9168735 },
    { lat: 48.2995254, lng: 19.9160624 },
    { lat: 48.3001206, lng: 19.915623 },
    { lat: 48.3006456, lng: 19.9151008 },
    { lat: 48.3009868, lng: 19.9145191 },
    { lat: 48.3012514, lng: 19.9138574 },
    { lat: 48.30153, lng: 19.9134743 },
    { lat: 48.3039466, lng: 19.9152324 },
    { lat: 48.304344, lng: 19.9153827 },
    { lat: 48.3046451, lng: 19.9153148 },
    { lat: 48.3047529, lng: 19.9152252 },
    { lat: 48.3051057, lng: 19.9143147 },
    { lat: 48.3055756, lng: 19.914107 },
    { lat: 48.3065002, lng: 19.9142259 },
    { lat: 48.3070883, lng: 19.9144909 },
    { lat: 48.3079175, lng: 19.9153102 },
    { lat: 48.3083203, lng: 19.9156143 },
    { lat: 48.3098967, lng: 19.9177212 },
    { lat: 48.3114565, lng: 19.919155 },
    { lat: 48.3124959, lng: 19.9198038 },
    { lat: 48.3136359, lng: 19.9201424 },
    { lat: 48.3148913, lng: 19.9207039 },
    { lat: 48.3163329, lng: 19.9219847 },
    { lat: 48.3165309, lng: 19.9223479 },
  ],
  Radnovce: [
    { lat: 48.357677, lng: 20.2204569 },
    { lat: 48.3570052, lng: 20.2210584 },
    { lat: 48.3561365, lng: 20.2199867 },
    { lat: 48.3557506, lng: 20.2192691 },
    { lat: 48.3551234, lng: 20.2189307 },
    { lat: 48.3537981, lng: 20.2175668 },
    { lat: 48.3519877, lng: 20.2174162 },
    { lat: 48.3520293, lng: 20.2169666 },
    { lat: 48.3518171, lng: 20.2154983 },
    { lat: 48.3509677, lng: 20.215885 },
    { lat: 48.3502108, lng: 20.2161383 },
    { lat: 48.3498512, lng: 20.2160455 },
    { lat: 48.3482276, lng: 20.2152184 },
    { lat: 48.3479087, lng: 20.215106 },
    { lat: 48.3471887, lng: 20.215072 },
    { lat: 48.3464361, lng: 20.2152326 },
    { lat: 48.3451204, lng: 20.2159543 },
    { lat: 48.3446972, lng: 20.2144535 },
    { lat: 48.3441735, lng: 20.2132742 },
    { lat: 48.348005, lng: 20.2084043 },
    { lat: 48.3478248, lng: 20.2077928 },
    { lat: 48.3477785, lng: 20.2073927 },
    { lat: 48.3460376, lng: 20.2034927 },
    { lat: 48.3457618, lng: 20.203022 },
    { lat: 48.3448633, lng: 20.2017461 },
    { lat: 48.344652, lng: 20.2013344 },
    { lat: 48.3443167, lng: 20.2002857 },
    { lat: 48.3437821, lng: 20.1966653 },
    { lat: 48.343666, lng: 20.1959932 },
    { lat: 48.3434951, lng: 20.1954441 },
    { lat: 48.3435747, lng: 20.1953816 },
    { lat: 48.3434772, lng: 20.1950612 },
    { lat: 48.3434778, lng: 20.1940178 },
    { lat: 48.3436512, lng: 20.1933166 },
    { lat: 48.3435391, lng: 20.1923815 },
    { lat: 48.3432824, lng: 20.1910478 },
    { lat: 48.3442592, lng: 20.1904151 },
    { lat: 48.343721, lng: 20.188691 },
    { lat: 48.3433884, lng: 20.1871808 },
    { lat: 48.3430456, lng: 20.1846934 },
    { lat: 48.3427772, lng: 20.1822168 },
    { lat: 48.3428804, lng: 20.1817065 },
    { lat: 48.3431947, lng: 20.1793687 },
    { lat: 48.343517, lng: 20.1774862 },
    { lat: 48.3445126, lng: 20.1755989 },
    { lat: 48.344628, lng: 20.1734991 },
    { lat: 48.3446612, lng: 20.1714631 },
    { lat: 48.3446222, lng: 20.1694439 },
    { lat: 48.3446955, lng: 20.1673175 },
    { lat: 48.3441802, lng: 20.1652268 },
    { lat: 48.3431925, lng: 20.1639807 },
    { lat: 48.3424648, lng: 20.1625691 },
    { lat: 48.3417742, lng: 20.1608183 },
    { lat: 48.341408, lng: 20.1588572 },
    { lat: 48.3414955, lng: 20.1577945 },
    { lat: 48.3419406, lng: 20.1562745 },
    { lat: 48.3425337, lng: 20.155214 },
    { lat: 48.3432015, lng: 20.1543324 },
    { lat: 48.3439929, lng: 20.1535737 },
    { lat: 48.3449183, lng: 20.1524022 },
    { lat: 48.3443997, lng: 20.1517016 },
    { lat: 48.3440107, lng: 20.1514406 },
    { lat: 48.3429899, lng: 20.1513782 },
    { lat: 48.3418071, lng: 20.1507683 },
    { lat: 48.3397701, lng: 20.1507252 },
    { lat: 48.3389262, lng: 20.1509141 },
    { lat: 48.3380331, lng: 20.1516075 },
    { lat: 48.3378137, lng: 20.151669 },
    { lat: 48.336406, lng: 20.1527504 },
    { lat: 48.3338324, lng: 20.1540743 },
    { lat: 48.3316271, lng: 20.1553228 },
    { lat: 48.3311668, lng: 20.1568499 },
    { lat: 48.3301272, lng: 20.1586187 },
    { lat: 48.328819, lng: 20.1606271 },
    { lat: 48.3272328, lng: 20.1642693 },
    { lat: 48.3270143, lng: 20.1672358 },
    { lat: 48.3283595, lng: 20.1713725 },
    { lat: 48.3287166, lng: 20.1727854 },
    { lat: 48.3288386, lng: 20.1734354 },
    { lat: 48.329704, lng: 20.1800234 },
    { lat: 48.3299445, lng: 20.1813437 },
    { lat: 48.3306221, lng: 20.1841904 },
    { lat: 48.3310087, lng: 20.1855898 },
    { lat: 48.3319411, lng: 20.1878669 },
    { lat: 48.3322531, lng: 20.1894218 },
    { lat: 48.3324527, lng: 20.1915745 },
    { lat: 48.3327794, lng: 20.1932721 },
    { lat: 48.332706, lng: 20.1949945 },
    { lat: 48.3319056, lng: 20.1986635 },
    { lat: 48.3319352, lng: 20.1993072 },
    { lat: 48.3318431, lng: 20.2010429 },
    { lat: 48.3321816, lng: 20.2021487 },
    { lat: 48.3322181, lng: 20.202744 },
    { lat: 48.332805, lng: 20.2033905 },
    { lat: 48.3329116, lng: 20.2036364 },
    { lat: 48.3330031, lng: 20.2042238 },
    { lat: 48.3329075, lng: 20.2056229 },
    { lat: 48.3329193, lng: 20.2063956 },
    { lat: 48.3329907, lng: 20.2070489 },
    { lat: 48.3331562, lng: 20.2077404 },
    { lat: 48.3327501, lng: 20.2085397 },
    { lat: 48.332737, lng: 20.2088283 },
    { lat: 48.3327774, lng: 20.2090165 },
    { lat: 48.3329727, lng: 20.2093662 },
    { lat: 48.3338367, lng: 20.2105424 },
    { lat: 48.3341484, lng: 20.211208 },
    { lat: 48.3342644, lng: 20.2115985 },
    { lat: 48.334548, lng: 20.212152 },
    { lat: 48.336636, lng: 20.2152239 },
    { lat: 48.3371104, lng: 20.2160748 },
    { lat: 48.3384505, lng: 20.2203545 },
    { lat: 48.338597, lng: 20.2203285 },
    { lat: 48.3388927, lng: 20.2194486 },
    { lat: 48.339299, lng: 20.2187393 },
    { lat: 48.3402521, lng: 20.2175086 },
    { lat: 48.340511, lng: 20.2180969 },
    { lat: 48.3413391, lng: 20.218998 },
    { lat: 48.3414099, lng: 20.2191329 },
    { lat: 48.3433865, lng: 20.2212362 },
    { lat: 48.344003, lng: 20.2217523 },
    { lat: 48.3446873, lng: 20.2228889 },
    { lat: 48.3455079, lng: 20.224364 },
    { lat: 48.3455248, lng: 20.2262436 },
    { lat: 48.345595, lng: 20.227658 },
    { lat: 48.346217, lng: 20.2283659 },
    { lat: 48.3479882, lng: 20.2358014 },
    { lat: 48.3489828, lng: 20.2346478 },
    { lat: 48.3495654, lng: 20.2321593 },
    { lat: 48.3500332, lng: 20.2308757 },
    { lat: 48.3503026, lng: 20.2297845 },
    { lat: 48.3504413, lng: 20.2287763 },
    { lat: 48.3507375, lng: 20.2285814 },
    { lat: 48.3518061, lng: 20.2275916 },
    { lat: 48.3525312, lng: 20.2264064 },
    { lat: 48.3537086, lng: 20.225127 },
    { lat: 48.3543812, lng: 20.2245163 },
    { lat: 48.3552133, lng: 20.2240604 },
    { lat: 48.3560422, lng: 20.2247306 },
    { lat: 48.357146, lng: 20.2246485 },
    { lat: 48.3596719, lng: 20.2234638 },
    { lat: 48.3607935, lng: 20.2232058 },
    { lat: 48.3614204, lng: 20.2247071 },
    { lat: 48.3618087, lng: 20.2245081 },
    { lat: 48.3619302, lng: 20.2238546 },
    { lat: 48.3617282, lng: 20.2230604 },
    { lat: 48.3614436, lng: 20.2224508 },
    { lat: 48.3612583, lng: 20.2221849 },
    { lat: 48.3607881, lng: 20.221948 },
    { lat: 48.360311, lng: 20.2219873 },
    { lat: 48.3596483, lng: 20.2221453 },
    { lat: 48.358733, lng: 20.2213699 },
    { lat: 48.358103, lng: 20.2206283 },
    { lat: 48.357677, lng: 20.2204569 },
  ],
  Martinová: [
    { lat: 48.291962, lng: 20.187222 },
    { lat: 48.2920434, lng: 20.1871979 },
    { lat: 48.2928578, lng: 20.1868688 },
    { lat: 48.2936274, lng: 20.1866793 },
    { lat: 48.2938549, lng: 20.1873196 },
    { lat: 48.2939593, lng: 20.1873395 },
    { lat: 48.2941276, lng: 20.1872135 },
    { lat: 48.2943185, lng: 20.1876948 },
    { lat: 48.2943489, lng: 20.1878926 },
    { lat: 48.2945208, lng: 20.1881614 },
    { lat: 48.2946443, lng: 20.1881519 },
    { lat: 48.2947877, lng: 20.1880455 },
    { lat: 48.2948878, lng: 20.1879062 },
    { lat: 48.2950455, lng: 20.187796 },
    { lat: 48.295039, lng: 20.1875777 },
    { lat: 48.2950242, lng: 20.1874902 },
    { lat: 48.2949273, lng: 20.1874594 },
    { lat: 48.2946862, lng: 20.1869597 },
    { lat: 48.294683, lng: 20.1867138 },
    { lat: 48.2948024, lng: 20.1864914 },
    { lat: 48.2989993, lng: 20.1852202 },
    { lat: 48.299195, lng: 20.1850468 },
    { lat: 48.2992322, lng: 20.1851028 },
    { lat: 48.299488, lng: 20.1850795 },
    { lat: 48.3011752, lng: 20.1847965 },
    { lat: 48.3026039, lng: 20.1854808 },
    { lat: 48.3170827, lng: 20.1821413 },
    { lat: 48.3169497, lng: 20.1769478 },
    { lat: 48.3169466, lng: 20.1767022 },
    { lat: 48.3167633, lng: 20.17155 },
    { lat: 48.3165808, lng: 20.171468 },
    { lat: 48.3163857, lng: 20.1715169 },
    { lat: 48.3163292, lng: 20.1714556 },
    { lat: 48.3161314, lng: 20.1714665 },
    { lat: 48.3157884, lng: 20.1709023 },
    { lat: 48.3154733, lng: 20.1707687 },
    { lat: 48.3153834, lng: 20.1707737 },
    { lat: 48.3152237, lng: 20.1709167 },
    { lat: 48.3151998, lng: 20.170776 },
    { lat: 48.3151091, lng: 20.1708423 },
    { lat: 48.31504, lng: 20.170747 },
    { lat: 48.3149084, lng: 20.1706961 },
    { lat: 48.3147833, lng: 20.1707542 },
    { lat: 48.3146694, lng: 20.1706641 },
    { lat: 48.314585, lng: 20.1709156 },
    { lat: 48.31452, lng: 20.170924 },
    { lat: 48.3144648, lng: 20.1708381 },
    { lat: 48.3142859, lng: 20.1710947 },
    { lat: 48.3141631, lng: 20.171152 },
    { lat: 48.314124, lng: 20.171072 },
    { lat: 48.3141004, lng: 20.1706591 },
    { lat: 48.314042, lng: 20.1706096 },
    { lat: 48.3139413, lng: 20.1707334 },
    { lat: 48.3138795, lng: 20.1707115 },
    { lat: 48.3137553, lng: 20.170565 },
    { lat: 48.3137409, lng: 20.1704025 },
    { lat: 48.3136109, lng: 20.1705312 },
    { lat: 48.3134754, lng: 20.1703857 },
    { lat: 48.3133095, lng: 20.1700725 },
    { lat: 48.3131676, lng: 20.170129 },
    { lat: 48.3131423, lng: 20.16981 },
    { lat: 48.3128859, lng: 20.16982 },
    { lat: 48.3125057, lng: 20.169612 },
    { lat: 48.3119753, lng: 20.1697245 },
    { lat: 48.311723, lng: 20.1695872 },
    { lat: 48.3116442, lng: 20.169645 },
    { lat: 48.3115027, lng: 20.169595 },
    { lat: 48.3114008, lng: 20.1694178 },
    { lat: 48.3113501, lng: 20.1694486 },
    { lat: 48.3113018, lng: 20.1696186 },
    { lat: 48.3102842, lng: 20.1684368 },
    { lat: 48.30981, lng: 20.1680768 },
    { lat: 48.309124, lng: 20.1673314 },
    { lat: 48.3080452, lng: 20.1650358 },
    { lat: 48.3077738, lng: 20.1647634 },
    { lat: 48.3078037, lng: 20.163891 },
    { lat: 48.3079024, lng: 20.1633835 },
    { lat: 48.3078978, lng: 20.1631882 },
    { lat: 48.3072236, lng: 20.1621776 },
    { lat: 48.3066764, lng: 20.1617917 },
    { lat: 48.3058143, lng: 20.1614768 },
    { lat: 48.3051892, lng: 20.16109 },
    { lat: 48.3038703, lng: 20.1609804 },
    { lat: 48.302939, lng: 20.1608498 },
    { lat: 48.3027908, lng: 20.1607806 },
    { lat: 48.3025119, lng: 20.1607343 },
    { lat: 48.3016085, lng: 20.160621 },
    { lat: 48.3014249, lng: 20.1609251 },
    { lat: 48.3008281, lng: 20.162723 },
    { lat: 48.2998172, lng: 20.1638476 },
    { lat: 48.2995855, lng: 20.1641861 },
    { lat: 48.2993982, lng: 20.1646239 },
    { lat: 48.2992186, lng: 20.1643039 },
    { lat: 48.2988164, lng: 20.1646346 },
    { lat: 48.2956938, lng: 20.1678347 },
    { lat: 48.2948485, lng: 20.1689812 },
    { lat: 48.2944098, lng: 20.1696901 },
    { lat: 48.2941102, lng: 20.1717095 },
    { lat: 48.293927, lng: 20.1721376 },
    { lat: 48.2939202, lng: 20.1723013 },
    { lat: 48.291421, lng: 20.1742988 },
    { lat: 48.2900826, lng: 20.1752085 },
    { lat: 48.2900997, lng: 20.1754075 },
    { lat: 48.290224, lng: 20.1755281 },
    { lat: 48.290359, lng: 20.1754107 },
    { lat: 48.2904047, lng: 20.1754571 },
    { lat: 48.290494, lng: 20.1758525 },
    { lat: 48.2904653, lng: 20.1760585 },
    { lat: 48.2900097, lng: 20.176545 },
    { lat: 48.2898604, lng: 20.1767789 },
    { lat: 48.2898826, lng: 20.1769944 },
    { lat: 48.2900057, lng: 20.1773064 },
    { lat: 48.2903216, lng: 20.1776225 },
    { lat: 48.2904094, lng: 20.1776226 },
    { lat: 48.2905676, lng: 20.1774678 },
    { lat: 48.2907823, lng: 20.1774316 },
    { lat: 48.2908833, lng: 20.1775055 },
    { lat: 48.2909188, lng: 20.1775776 },
    { lat: 48.2908852, lng: 20.1780252 },
    { lat: 48.2911211, lng: 20.1784816 },
    { lat: 48.2909491, lng: 20.1786861 },
    { lat: 48.2910192, lng: 20.1791041 },
    { lat: 48.2911238, lng: 20.1792672 },
    { lat: 48.2914326, lng: 20.1793271 },
    { lat: 48.2917055, lng: 20.1791735 },
    { lat: 48.2918462, lng: 20.1791667 },
    { lat: 48.292102, lng: 20.1795332 },
    { lat: 48.2923329, lng: 20.179226 },
    { lat: 48.2923815, lng: 20.1789828 },
    { lat: 48.2924824, lng: 20.1789142 },
    { lat: 48.2927864, lng: 20.1791882 },
    { lat: 48.2928979, lng: 20.1794419 },
    { lat: 48.2926455, lng: 20.1798801 },
    { lat: 48.2926779, lng: 20.1801789 },
    { lat: 48.2928478, lng: 20.1808421 },
    { lat: 48.292713, lng: 20.1812414 },
    { lat: 48.2924602, lng: 20.1814218 },
    { lat: 48.2924225, lng: 20.1819062 },
    { lat: 48.2925544, lng: 20.1822433 },
    { lat: 48.2933788, lng: 20.1826616 },
    { lat: 48.2935818, lng: 20.18297 },
    { lat: 48.2934808, lng: 20.1833658 },
    { lat: 48.2933074, lng: 20.1833692 },
    { lat: 48.293199, lng: 20.1836121 },
    { lat: 48.2931257, lng: 20.1836655 },
    { lat: 48.2930465, lng: 20.1836285 },
    { lat: 48.2929347, lng: 20.183095 },
    { lat: 48.292807, lng: 20.1829493 },
    { lat: 48.2926583, lng: 20.1828906 },
    { lat: 48.292555, lng: 20.1829786 },
    { lat: 48.2924979, lng: 20.1831316 },
    { lat: 48.2923808, lng: 20.1839628 },
    { lat: 48.2922295, lng: 20.184429 },
    { lat: 48.2925194, lng: 20.1846862 },
    { lat: 48.2926061, lng: 20.1849606 },
    { lat: 48.2925869, lng: 20.1851289 },
    { lat: 48.2924027, lng: 20.1853349 },
    { lat: 48.2924006, lng: 20.1854515 },
    { lat: 48.2924971, lng: 20.1857193 },
    { lat: 48.2924654, lng: 20.186049 },
    { lat: 48.2923493, lng: 20.1860503 },
    { lat: 48.2922024, lng: 20.1858434 },
    { lat: 48.2919877, lng: 20.1850993 },
    { lat: 48.2916721, lng: 20.1845932 },
    { lat: 48.2915693, lng: 20.1846408 },
    { lat: 48.2914182, lng: 20.1848614 },
    { lat: 48.2912232, lng: 20.1849138 },
    { lat: 48.2910915, lng: 20.1851936 },
    { lat: 48.290926, lng: 20.1853887 },
    { lat: 48.2909116, lng: 20.185564 },
    { lat: 48.2911451, lng: 20.1859332 },
    { lat: 48.2911721, lng: 20.1861794 },
    { lat: 48.291158, lng: 20.1863034 },
    { lat: 48.2909196, lng: 20.1863951 },
    { lat: 48.2909025, lng: 20.1866449 },
    { lat: 48.2909356, lng: 20.1867675 },
    { lat: 48.2913265, lng: 20.186797 },
    { lat: 48.2915127, lng: 20.1870252 },
    { lat: 48.2915622, lng: 20.1870242 },
    { lat: 48.291894, lng: 20.1865158 },
    { lat: 48.2919867, lng: 20.1865201 },
    { lat: 48.2920764, lng: 20.1866474 },
    { lat: 48.291962, lng: 20.187222 },
  ],
  StaráBašta: [
    { lat: 48.191896, lng: 19.9557023 },
    { lat: 48.191958, lng: 19.9546983 },
    { lat: 48.1922969, lng: 19.954403 },
    { lat: 48.1923066, lng: 19.9540783 },
    { lat: 48.1926645, lng: 19.9530941 },
    { lat: 48.1934573, lng: 19.9487803 },
    { lat: 48.1936272, lng: 19.9483026 },
    { lat: 48.1938999, lng: 19.9479613 },
    { lat: 48.1944196, lng: 19.9475723 },
    { lat: 48.1948509, lng: 19.9475431 },
    { lat: 48.1954404, lng: 19.9486608 },
    { lat: 48.1955622, lng: 19.9490884 },
    { lat: 48.1958077, lng: 19.9490325 },
    { lat: 48.1959698, lng: 19.9487374 },
    { lat: 48.1960678, lng: 19.9481857 },
    { lat: 48.196013, lng: 19.9472937 },
    { lat: 48.1960513, lng: 19.9463445 },
    { lat: 48.1962166, lng: 19.9455209 },
    { lat: 48.1967302, lng: 19.9440174 },
    { lat: 48.1967426, lng: 19.9432243 },
    { lat: 48.1968264, lng: 19.9426276 },
    { lat: 48.1968214, lng: 19.9416162 },
    { lat: 48.1967244, lng: 19.9399278 },
    { lat: 48.1967425, lng: 19.9391369 },
    { lat: 48.1971066, lng: 19.9359549 },
    { lat: 48.1970673, lng: 19.9351183 },
    { lat: 48.1971654, lng: 19.9343272 },
    { lat: 48.1990038, lng: 19.9326942 },
    { lat: 48.1998093, lng: 19.9309365 },
    { lat: 48.2007094, lng: 19.9297514 },
    { lat: 48.201007, lng: 19.9274692 },
    { lat: 48.2013402, lng: 19.9264089 },
    { lat: 48.2017239, lng: 19.9254791 },
    { lat: 48.202172, lng: 19.924565 },
    { lat: 48.202146, lng: 19.922976 },
    { lat: 48.202261, lng: 19.921225 },
    { lat: 48.202431, lng: 19.918991 },
    { lat: 48.202577, lng: 19.917551 },
    { lat: 48.202591, lng: 19.917414 },
    { lat: 48.202794, lng: 19.916672 },
    { lat: 48.202889, lng: 19.916203 },
    { lat: 48.202986, lng: 19.915721 },
    { lat: 48.203001, lng: 19.915644 },
    { lat: 48.203028, lng: 19.915507 },
    { lat: 48.202517, lng: 19.914783 },
    { lat: 48.202216, lng: 19.91438 },
    { lat: 48.201238, lng: 19.91327 },
    { lat: 48.201071, lng: 19.913033 },
    { lat: 48.20043, lng: 19.912303 },
    { lat: 48.199945, lng: 19.91151 },
    { lat: 48.199672, lng: 19.911299 },
    { lat: 48.197734, lng: 19.910071 },
    { lat: 48.196898, lng: 19.909651 },
    { lat: 48.196095, lng: 19.908774 },
    { lat: 48.194751, lng: 19.907015 },
    { lat: 48.19477, lng: 19.906458 },
    { lat: 48.194992, lng: 19.905485 },
    { lat: 48.195101, lng: 19.905002 },
    { lat: 48.195406, lng: 19.90411 },
    { lat: 48.195472, lng: 19.903764 },
    { lat: 48.195509, lng: 19.903463 },
    { lat: 48.195649, lng: 19.902551 },
    { lat: 48.195871, lng: 19.901959 },
    { lat: 48.196013, lng: 19.901179 },
    { lat: 48.196073, lng: 19.900771 },
    { lat: 48.196086, lng: 19.900682 },
    { lat: 48.196106, lng: 19.900543 },
    { lat: 48.196222, lng: 19.899697 },
    { lat: 48.196405, lng: 19.898946 },
    { lat: 48.196873, lng: 19.897196 },
    { lat: 48.197066, lng: 19.896736 },
    { lat: 48.197115, lng: 19.89662 },
    { lat: 48.19757, lng: 19.895537 },
    { lat: 48.197765, lng: 19.89468 },
    { lat: 48.197964, lng: 19.893397 },
    { lat: 48.198134, lng: 19.891942 },
    { lat: 48.198126, lng: 19.890468 },
    { lat: 48.198063, lng: 19.890491 },
    { lat: 48.195688, lng: 19.892671 },
    { lat: 48.1954803, lng: 19.8934575 },
    { lat: 48.1947647, lng: 19.8950703 },
    { lat: 48.1945054, lng: 19.8955102 },
    { lat: 48.1941901, lng: 19.8959054 },
    { lat: 48.193201, lng: 19.8981243 },
    { lat: 48.1921645, lng: 19.8997686 },
    { lat: 48.1900236, lng: 19.9050665 },
    { lat: 48.1898256, lng: 19.9077172 },
    { lat: 48.1895565, lng: 19.9092627 },
    { lat: 48.1890141, lng: 19.9108729 },
    { lat: 48.1877507, lng: 19.913065 },
    { lat: 48.1875844, lng: 19.9137249 },
    { lat: 48.1876077, lng: 19.9146628 },
    { lat: 48.1874837, lng: 19.9153691 },
    { lat: 48.1872456, lng: 19.9159597 },
    { lat: 48.1870814, lng: 19.9161652 },
    { lat: 48.1864416, lng: 19.9169658 },
    { lat: 48.1864199, lng: 19.9174551 },
    { lat: 48.1864682, lng: 19.9187947 },
    { lat: 48.1855023, lng: 19.9207483 },
    { lat: 48.1849763, lng: 19.9216167 },
    { lat: 48.1846876, lng: 19.9222661 },
    { lat: 48.1844289, lng: 19.9231413 },
    { lat: 48.184312, lng: 19.9230682 },
    { lat: 48.1826126, lng: 19.9264411 },
    { lat: 48.1816636, lng: 19.9281166 },
    { lat: 48.180975, lng: 19.9299705 },
    { lat: 48.1809355, lng: 19.9305493 },
    { lat: 48.1809983, lng: 19.930988 },
    { lat: 48.1810097, lng: 19.9314695 },
    { lat: 48.180982, lng: 19.9317249 },
    { lat: 48.1808572, lng: 19.9328755 },
    { lat: 48.1795409, lng: 19.9380255 },
    { lat: 48.1791916, lng: 19.9396546 },
    { lat: 48.1788923, lng: 19.9403716 },
    { lat: 48.1783441, lng: 19.9414363 },
    { lat: 48.1781053, lng: 19.9422289 },
    { lat: 48.1779087, lng: 19.9426875 },
    { lat: 48.1773941, lng: 19.944512 },
    { lat: 48.1768891, lng: 19.9453205 },
    { lat: 48.1764534, lng: 19.9463561 },
    { lat: 48.1762728, lng: 19.9476669 },
    { lat: 48.1756873, lng: 19.9499421 },
    { lat: 48.1752075, lng: 19.9525343 },
    { lat: 48.17495, lng: 19.9542607 },
    { lat: 48.1748803, lng: 19.9554507 },
    { lat: 48.1746351, lng: 19.9571011 },
    { lat: 48.174229, lng: 19.9593594 },
    { lat: 48.1746339, lng: 19.9595559 },
    { lat: 48.1747456, lng: 19.9632073 },
    { lat: 48.1772573, lng: 19.9642053 },
    { lat: 48.1777871, lng: 19.9643476 },
    { lat: 48.1782627, lng: 19.9643228 },
    { lat: 48.1797763, lng: 19.9639956 },
    { lat: 48.1800016, lng: 19.9641019 },
    { lat: 48.180031, lng: 19.9639438 },
    { lat: 48.1799379, lng: 19.9636862 },
    { lat: 48.1801423, lng: 19.9635711 },
    { lat: 48.1820268, lng: 19.9633829 },
    { lat: 48.1825052, lng: 19.9636336 },
    { lat: 48.1831474, lng: 19.9640853 },
    { lat: 48.1840694, lng: 19.9648939 },
    { lat: 48.1840712, lng: 19.9650148 },
    { lat: 48.1854442, lng: 19.9661751 },
    { lat: 48.1856387, lng: 19.9667203 },
    { lat: 48.1860166, lng: 19.967318 },
    { lat: 48.1870183, lng: 19.9685903 },
    { lat: 48.1878152, lng: 19.9674915 },
    { lat: 48.188054, lng: 19.9673336 },
    { lat: 48.1882266, lng: 19.9679233 },
    { lat: 48.1888763, lng: 19.9679134 },
    { lat: 48.1889056, lng: 19.9675414 },
    { lat: 48.1891116, lng: 19.9669012 },
    { lat: 48.1896902, lng: 19.9655981 },
    { lat: 48.1898571, lng: 19.9646213 },
    { lat: 48.1900215, lng: 19.9640076 },
    { lat: 48.1902504, lng: 19.9614711 },
    { lat: 48.1903229, lng: 19.9601869 },
    { lat: 48.1902704, lng: 19.9575791 },
    { lat: 48.1905104, lng: 19.9572907 },
    { lat: 48.1910123, lng: 19.9568521 },
    { lat: 48.191263, lng: 19.9563939 },
    { lat: 48.1915678, lng: 19.9560111 },
    { lat: 48.191896, lng: 19.9557023 },
  ],
  Žíp: [
    { lat: 48.3738404, lng: 20.2109595 },
    { lat: 48.3737728, lng: 20.2105937 },
    { lat: 48.3734423, lng: 20.2097405 },
    { lat: 48.3731695, lng: 20.2092317 },
    { lat: 48.3730195, lng: 20.2076867 },
    { lat: 48.3729509, lng: 20.2063762 },
    { lat: 48.3713983, lng: 20.2027841 },
    { lat: 48.37084, lng: 20.2019036 },
    { lat: 48.3705541, lng: 20.200457 },
    { lat: 48.3703127, lng: 20.1987949 },
    { lat: 48.3687554, lng: 20.1960166 },
    { lat: 48.3681824, lng: 20.1952703 },
    { lat: 48.3676846, lng: 20.1944329 },
    { lat: 48.3674041, lng: 20.1938464 },
    { lat: 48.3653747, lng: 20.1907908 },
    { lat: 48.363875, lng: 20.1876837 },
    { lat: 48.3628921, lng: 20.1854643 },
    { lat: 48.3622133, lng: 20.1835814 },
    { lat: 48.3617151, lng: 20.1824878 },
    { lat: 48.3610952, lng: 20.1811967 },
    { lat: 48.3600241, lng: 20.1793684 },
    { lat: 48.3592944, lng: 20.1778679 },
    { lat: 48.358985, lng: 20.1771297 },
    { lat: 48.3576004, lng: 20.1702632 },
    { lat: 48.3573471, lng: 20.1686073 },
    { lat: 48.3572212, lng: 20.1673096 },
    { lat: 48.3577303, lng: 20.1657005 },
    { lat: 48.3577825, lng: 20.1645182 },
    { lat: 48.3578952, lng: 20.1636111 },
    { lat: 48.3580676, lng: 20.1622262 },
    { lat: 48.3576566, lng: 20.1615332 },
    { lat: 48.355934, lng: 20.1600871 },
    { lat: 48.355145, lng: 20.1586628 },
    { lat: 48.3539827, lng: 20.1575866 },
    { lat: 48.3532226, lng: 20.157033 },
    { lat: 48.352478, lng: 20.1563987 },
    { lat: 48.3514747, lng: 20.1557973 },
    { lat: 48.3502415, lng: 20.1556657 },
    { lat: 48.3494247, lng: 20.1553239 },
    { lat: 48.3484661, lng: 20.1547139 },
    { lat: 48.3477731, lng: 20.1543773 },
    { lat: 48.3471023, lng: 20.1541396 },
    { lat: 48.3467202, lng: 20.1540773 },
    { lat: 48.3461414, lng: 20.1546073 },
    { lat: 48.3457992, lng: 20.15622 },
    { lat: 48.3458337, lng: 20.1575067 },
    { lat: 48.346623, lng: 20.161036 },
    { lat: 48.3466656, lng: 20.1620302 },
    { lat: 48.3468051, lng: 20.1634117 },
    { lat: 48.3469848, lng: 20.1644742 },
    { lat: 48.3475885, lng: 20.1671609 },
    { lat: 48.3481506, lng: 20.1772316 },
    { lat: 48.3484141, lng: 20.178455 },
    { lat: 48.348763, lng: 20.1796968 },
    { lat: 48.3494744, lng: 20.1808804 },
    { lat: 48.3514429, lng: 20.1834568 },
    { lat: 48.3522697, lng: 20.1846871 },
    { lat: 48.3540252, lng: 20.185576 },
    { lat: 48.3546464, lng: 20.1858763 },
    { lat: 48.3558409, lng: 20.1864538 },
    { lat: 48.3561624, lng: 20.1868045 },
    { lat: 48.3583103, lng: 20.1905073 },
    { lat: 48.3584576, lng: 20.1908626 },
    { lat: 48.3593292, lng: 20.1925045 },
    { lat: 48.3624285, lng: 20.1982392 },
    { lat: 48.36205, lng: 20.1987081 },
    { lat: 48.3593942, lng: 20.2015015 },
    { lat: 48.3588267, lng: 20.2018965 },
    { lat: 48.3579228, lng: 20.2023838 },
    { lat: 48.3574321, lng: 20.2026164 },
    { lat: 48.3567679, lng: 20.2028201 },
    { lat: 48.3570364, lng: 20.2034222 },
    { lat: 48.3572204, lng: 20.2034597 },
    { lat: 48.3575593, lng: 20.2036534 },
    { lat: 48.3584278, lng: 20.2042379 },
    { lat: 48.3587125, lng: 20.2045673 },
    { lat: 48.3589247, lng: 20.2049992 },
    { lat: 48.3591852, lng: 20.205737 },
    { lat: 48.3592755, lng: 20.2063252 },
    { lat: 48.359095, lng: 20.2088918 },
    { lat: 48.3588718, lng: 20.2092223 },
    { lat: 48.3564822, lng: 20.209957 },
    { lat: 48.3559309, lng: 20.2102793 },
    { lat: 48.355297, lng: 20.2108435 },
    { lat: 48.3550993, lng: 20.2111096 },
    { lat: 48.3561928, lng: 20.2126749 },
    { lat: 48.3575603, lng: 20.2136556 },
    { lat: 48.3586028, lng: 20.2140669 },
    { lat: 48.3591224, lng: 20.2149442 },
    { lat: 48.3595856, lng: 20.2161807 },
    { lat: 48.3582414, lng: 20.219138 },
    { lat: 48.357677, lng: 20.2204569 },
    { lat: 48.358103, lng: 20.2206283 },
    { lat: 48.358733, lng: 20.2213699 },
    { lat: 48.3596483, lng: 20.2221453 },
    { lat: 48.360311, lng: 20.2219873 },
    { lat: 48.3607881, lng: 20.221948 },
    { lat: 48.3612583, lng: 20.2221849 },
    { lat: 48.3614436, lng: 20.2224508 },
    { lat: 48.3617282, lng: 20.2230604 },
    { lat: 48.3619302, lng: 20.2238546 },
    { lat: 48.3618087, lng: 20.2245081 },
    { lat: 48.3619496, lng: 20.2251257 },
    { lat: 48.3622352, lng: 20.2256726 },
    { lat: 48.3625642, lng: 20.2270263 },
    { lat: 48.3626532, lng: 20.2272499 },
    { lat: 48.3631603, lng: 20.2275968 },
    { lat: 48.3633994, lng: 20.2275501 },
    { lat: 48.363903, lng: 20.22716 },
    { lat: 48.3644384, lng: 20.226603 },
    { lat: 48.3648014, lng: 20.226362 },
    { lat: 48.3650751, lng: 20.2263914 },
    { lat: 48.3653468, lng: 20.2266796 },
    { lat: 48.3657815, lng: 20.2277745 },
    { lat: 48.367684, lng: 20.2276919 },
    { lat: 48.3685095, lng: 20.2271842 },
    { lat: 48.369079, lng: 20.2261941 },
    { lat: 48.3697421, lng: 20.2244743 },
    { lat: 48.3701641, lng: 20.223704 },
    { lat: 48.370752, lng: 20.2231461 },
    { lat: 48.3711029, lng: 20.222346 },
    { lat: 48.3712969, lng: 20.2215407 },
    { lat: 48.3721951, lng: 20.2205158 },
    { lat: 48.3728143, lng: 20.2196145 },
    { lat: 48.3729304, lng: 20.2190847 },
    { lat: 48.3728143, lng: 20.2183216 },
    { lat: 48.3726904, lng: 20.2165612 },
    { lat: 48.3728759, lng: 20.2155154 },
    { lat: 48.3732229, lng: 20.2142841 },
    { lat: 48.3734397, lng: 20.2119693 },
    { lat: 48.3735678, lng: 20.2112945 },
    { lat: 48.3738404, lng: 20.2109595 },
  ],
  RimavskéBrezovo: [
    { lat: 48.5584873, lng: 19.9139313 },
    { lat: 48.5582928, lng: 19.9140692 },
    { lat: 48.5580731, lng: 19.9141478 },
    { lat: 48.5573919, lng: 19.9158691 },
    { lat: 48.5556035, lng: 19.9185756 },
    { lat: 48.5546402, lng: 19.9195801 },
    { lat: 48.553906, lng: 19.9208516 },
    { lat: 48.553636, lng: 19.9211025 },
    { lat: 48.5530478, lng: 19.9219185 },
    { lat: 48.5524282, lng: 19.9226014 },
    { lat: 48.5516488, lng: 19.9231888 },
    { lat: 48.551005, lng: 19.9239404 },
    { lat: 48.5502866, lng: 19.9246097 },
    { lat: 48.5500197, lng: 19.9246906 },
    { lat: 48.5497609, lng: 19.9249105 },
    { lat: 48.5495971, lng: 19.9252932 },
    { lat: 48.5495486, lng: 19.9256111 },
    { lat: 48.5493312, lng: 19.9261946 },
    { lat: 48.5488979, lng: 19.9268103 },
    { lat: 48.547906, lng: 19.9278035 },
    { lat: 48.5471807, lng: 19.9288101 },
    { lat: 48.5461935, lng: 19.9309454 },
    { lat: 48.5455749, lng: 19.931976 },
    { lat: 48.5454085, lng: 19.9325133 },
    { lat: 48.5441892, lng: 19.9335609 },
    { lat: 48.5439783, lng: 19.9340871 },
    { lat: 48.5437905, lng: 19.9343897 },
    { lat: 48.5425276, lng: 19.9358158 },
    { lat: 48.5423136, lng: 19.9362118 },
    { lat: 48.5420796, lng: 19.9369599 },
    { lat: 48.5416812, lng: 19.937319 },
    { lat: 48.5415684, lng: 19.937525 },
    { lat: 48.5414207, lng: 19.9381048 },
    { lat: 48.5413996, lng: 19.9388577 },
    { lat: 48.5410714, lng: 19.940355 },
    { lat: 48.5408516, lng: 19.9410452 },
    { lat: 48.5401713, lng: 19.9422112 },
    { lat: 48.5399032, lng: 19.9431972 },
    { lat: 48.539232, lng: 19.9441789 },
    { lat: 48.5388336, lng: 19.9450146 },
    { lat: 48.5385545, lng: 19.9451257 },
    { lat: 48.5384267, lng: 19.9452738 },
    { lat: 48.5382461, lng: 19.9457447 },
    { lat: 48.5381328, lng: 19.9457798 },
    { lat: 48.5376556, lng: 19.9466657 },
    { lat: 48.5372142, lng: 19.947124 },
    { lat: 48.5370042, lng: 19.94751 },
    { lat: 48.53682, lng: 19.9476574 },
    { lat: 48.5365327, lng: 19.9486085 },
    { lat: 48.5362616, lng: 19.9489673 },
    { lat: 48.5360506, lng: 19.9498097 },
    { lat: 48.5359505, lng: 19.94976 },
    { lat: 48.5357234, lng: 19.9505951 },
    { lat: 48.5356091, lng: 19.9505261 },
    { lat: 48.5354738, lng: 19.9512951 },
    { lat: 48.5351319, lng: 19.9518924 },
    { lat: 48.5348173, lng: 19.9526851 },
    { lat: 48.5345366, lng: 19.9530529 },
    { lat: 48.5341051, lng: 19.9534213 },
    { lat: 48.53387, lng: 19.9537777 },
    { lat: 48.5338051, lng: 19.9542136 },
    { lat: 48.5336478, lng: 19.9545952 },
    { lat: 48.5336463, lng: 19.9546931 },
    { lat: 48.5334666, lng: 19.9549986 },
    { lat: 48.5322426, lng: 19.9558059 },
    { lat: 48.5319485, lng: 19.9559113 },
    { lat: 48.5314843, lng: 19.9559384 },
    { lat: 48.5308342, lng: 19.9557478 },
    { lat: 48.530648, lng: 19.9559284 },
    { lat: 48.5303449, lng: 19.956084 },
    { lat: 48.5302907, lng: 19.9563188 },
    { lat: 48.5303085, lng: 19.9565289 },
    { lat: 48.5301707, lng: 19.956807 },
    { lat: 48.5299209, lng: 19.9575865 },
    { lat: 48.5294049, lng: 19.9588089 },
    { lat: 48.5294335, lng: 19.959513 },
    { lat: 48.5290832, lng: 19.9605776 },
    { lat: 48.5290231, lng: 19.960916 },
    { lat: 48.5288399, lng: 19.9631918 },
    { lat: 48.5280762, lng: 19.9660168 },
    { lat: 48.527625, lng: 19.9681369 },
    { lat: 48.5272971, lng: 19.9689579 },
    { lat: 48.5267682, lng: 19.9699048 },
    { lat: 48.5266053, lng: 19.9707978 },
    { lat: 48.5264671, lng: 19.9711536 },
    { lat: 48.5264209, lng: 19.9714466 },
    { lat: 48.5264566, lng: 19.971945 },
    { lat: 48.5263851, lng: 19.9725144 },
    { lat: 48.5258577, lng: 19.9737401 },
    { lat: 48.525678, lng: 19.974527 },
    { lat: 48.5256879, lng: 19.9750573 },
    { lat: 48.5254433, lng: 19.9761205 },
    { lat: 48.5247889, lng: 19.9778042 },
    { lat: 48.5241917, lng: 19.9786836 },
    { lat: 48.5235745, lng: 19.9791942 },
    { lat: 48.5230184, lng: 19.9798446 },
    { lat: 48.5224751, lng: 19.9802885 },
    { lat: 48.5217235, lng: 19.9804746 },
    { lat: 48.5215092, lng: 19.9808671 },
    { lat: 48.5211135, lng: 19.9814184 },
    { lat: 48.5209102, lng: 19.9816204 },
    { lat: 48.5203245, lng: 19.9820487 },
    { lat: 48.5201281, lng: 19.982066 },
    { lat: 48.5198158, lng: 19.9823297 },
    { lat: 48.519457, lng: 19.9828781 },
    { lat: 48.518914, lng: 19.9839706 },
    { lat: 48.5185715, lng: 19.9844787 },
    { lat: 48.5184179, lng: 19.9848675 },
    { lat: 48.5184848, lng: 19.9849329 },
    { lat: 48.5184578, lng: 19.9849913 },
    { lat: 48.518174, lng: 19.9855275 },
    { lat: 48.5179817, lng: 19.9861257 },
    { lat: 48.5178782, lng: 19.9862571 },
    { lat: 48.5177441, lng: 19.9866323 },
    { lat: 48.5174476, lng: 19.987164 },
    { lat: 48.517343, lng: 19.9875386 },
    { lat: 48.5168534, lng: 19.9886217 },
    { lat: 48.516558, lng: 19.9889898 },
    { lat: 48.516284, lng: 19.9897319 },
    { lat: 48.516049, lng: 19.9900129 },
    { lat: 48.515839, lng: 19.9904533 },
    { lat: 48.5158114, lng: 19.9905223 },
    { lat: 48.5163129, lng: 19.9906604 },
    { lat: 48.5170868, lng: 19.9912791 },
    { lat: 48.5176007, lng: 19.9918481 },
    { lat: 48.5177535, lng: 19.9922357 },
    { lat: 48.5179467, lng: 19.9925481 },
    { lat: 48.5182356, lng: 19.9927711 },
    { lat: 48.5192074, lng: 19.9926583 },
    { lat: 48.5194397, lng: 19.992558 },
    { lat: 48.5200859, lng: 19.9927871 },
    { lat: 48.5212072, lng: 19.9925066 },
    { lat: 48.5215324, lng: 19.992713 },
    { lat: 48.5217241, lng: 19.9927694 },
    { lat: 48.5221805, lng: 19.9927515 },
    { lat: 48.5224277, lng: 19.9929205 },
    { lat: 48.5226324, lng: 19.9929657 },
    { lat: 48.5232534, lng: 19.9928927 },
    { lat: 48.5239132, lng: 19.9929944 },
    { lat: 48.5245489, lng: 19.9935913 },
    { lat: 48.5247409, lng: 19.9935524 },
    { lat: 48.5250665, lng: 19.9933135 },
    { lat: 48.5255216, lng: 19.9927744 },
    { lat: 48.5259013, lng: 19.9924792 },
    { lat: 48.5261038, lng: 19.9921248 },
    { lat: 48.5265066, lng: 19.9917413 },
    { lat: 48.5267769, lng: 19.9916069 },
    { lat: 48.5274573, lng: 19.991552 },
    { lat: 48.5287677, lng: 19.9916173 },
    { lat: 48.5297308, lng: 19.9913983 },
    { lat: 48.5299002, lng: 19.9914084 },
    { lat: 48.5306381, lng: 19.9912493 },
    { lat: 48.5312522, lng: 19.9907213 },
    { lat: 48.5317978, lng: 19.9906494 },
    { lat: 48.5319966, lng: 19.9905558 },
    { lat: 48.5322877, lng: 19.9906173 },
    { lat: 48.532603, lng: 19.990937 },
    { lat: 48.5332668, lng: 19.9911623 },
    { lat: 48.533911, lng: 19.9911567 },
    { lat: 48.536116, lng: 19.9929115 },
    { lat: 48.5366845, lng: 19.9931996 },
    { lat: 48.5369721, lng: 19.9932067 },
    { lat: 48.5373868, lng: 19.9930492 },
    { lat: 48.5378071, lng: 19.9930529 },
    { lat: 48.5383009, lng: 19.9929291 },
    { lat: 48.5388547, lng: 19.9931603 },
    { lat: 48.5392792, lng: 19.993476 },
    { lat: 48.5411421, lng: 19.9935204 },
    { lat: 48.5419258, lng: 19.9933313 },
    { lat: 48.5427113, lng: 19.9933908 },
    { lat: 48.5431392, lng: 19.9931979 },
    { lat: 48.5434884, lng: 19.9932013 },
    { lat: 48.5443089, lng: 19.9936112 },
    { lat: 48.5448494, lng: 19.9936603 },
    { lat: 48.5465088, lng: 19.99361 },
    { lat: 48.5468154, lng: 19.9936145 },
    { lat: 48.5474836, lng: 19.9935391 },
    { lat: 48.5479444, lng: 19.9931913 },
    { lat: 48.550504, lng: 19.9910538 },
    { lat: 48.5525994, lng: 19.9889429 },
    { lat: 48.5527663, lng: 19.9889199 },
    { lat: 48.5532684, lng: 19.9890743 },
    { lat: 48.554126, lng: 19.9890797 },
    { lat: 48.5546613, lng: 19.9892473 },
    { lat: 48.5551669, lng: 19.9893249 },
    { lat: 48.5553105, lng: 19.988209 },
    { lat: 48.5553088, lng: 19.9866695 },
    { lat: 48.555532, lng: 19.9850698 },
    { lat: 48.5556223, lng: 19.9839791 },
    { lat: 48.5558486, lng: 19.9831408 },
    { lat: 48.5558186, lng: 19.9827241 },
    { lat: 48.5558532, lng: 19.9825835 },
    { lat: 48.5558381, lng: 19.9824377 },
    { lat: 48.5557684, lng: 19.982412 },
    { lat: 48.5557489, lng: 19.9822461 },
    { lat: 48.5558006, lng: 19.9821135 },
    { lat: 48.5557432, lng: 19.9819781 },
    { lat: 48.5557611, lng: 19.9818413 },
    { lat: 48.5557099, lng: 19.9817751 },
    { lat: 48.5557037, lng: 19.9815709 },
    { lat: 48.555897, lng: 19.980869 },
    { lat: 48.5558828, lng: 19.9805553 },
    { lat: 48.555974, lng: 19.9801821 },
    { lat: 48.5560575, lng: 19.9801307 },
    { lat: 48.5562893, lng: 19.9797765 },
    { lat: 48.5569376, lng: 19.9780262 },
    { lat: 48.5573564, lng: 19.9772984 },
    { lat: 48.5573241, lng: 19.9769641 },
    { lat: 48.5573743, lng: 19.9766527 },
    { lat: 48.5573219, lng: 19.9763459 },
    { lat: 48.5573404, lng: 19.9758264 },
    { lat: 48.5574187, lng: 19.9756795 },
    { lat: 48.557571, lng: 19.97507 },
    { lat: 48.557631, lng: 19.9749139 },
    { lat: 48.5578574, lng: 19.9747023 },
    { lat: 48.5578824, lng: 19.9745449 },
    { lat: 48.5580654, lng: 19.9743738 },
    { lat: 48.5580593, lng: 19.9742513 },
    { lat: 48.5581244, lng: 19.9741381 },
    { lat: 48.558338, lng: 19.9739978 },
    { lat: 48.5583944, lng: 19.9738619 },
    { lat: 48.5584031, lng: 19.9736434 },
    { lat: 48.5582989, lng: 19.9734457 },
    { lat: 48.5583542, lng: 19.9732054 },
    { lat: 48.5582831, lng: 19.9730987 },
    { lat: 48.5584569, lng: 19.9728083 },
    { lat: 48.5585227, lng: 19.97162 },
    { lat: 48.5581468, lng: 19.9706109 },
    { lat: 48.5580422, lng: 19.9700782 },
    { lat: 48.5580084, lng: 19.9694407 },
    { lat: 48.5580471, lng: 19.9692897 },
    { lat: 48.5580293, lng: 19.9688164 },
    { lat: 48.5578961, lng: 19.9682506 },
    { lat: 48.5576747, lng: 19.9678308 },
    { lat: 48.5573078, lng: 19.9678685 },
    { lat: 48.5568093, lng: 19.9681225 },
    { lat: 48.556401, lng: 19.9623242 },
    { lat: 48.556569, lng: 19.9622213 },
    { lat: 48.5559182, lng: 19.958682 },
    { lat: 48.5557323, lng: 19.9540495 },
    { lat: 48.5560947, lng: 19.9490488 },
    { lat: 48.5563498, lng: 19.9470148 },
    { lat: 48.5560455, lng: 19.9448825 },
    { lat: 48.5554247, lng: 19.9424912 },
    { lat: 48.5552052, lng: 19.9420187 },
    { lat: 48.5550238, lng: 19.9412479 },
    { lat: 48.5548551, lng: 19.9409331 },
    { lat: 48.5550636, lng: 19.939902 },
    { lat: 48.555158, lng: 19.9390225 },
    { lat: 48.5553225, lng: 19.9386549 },
    { lat: 48.5554574, lng: 19.9381957 },
    { lat: 48.5557872, lng: 19.9367856 },
    { lat: 48.556168, lng: 19.9360982 },
    { lat: 48.5565415, lng: 19.9339122 },
    { lat: 48.5566111, lng: 19.9336669 },
    { lat: 48.5568445, lng: 19.9331935 },
    { lat: 48.5573689, lng: 19.9326795 },
    { lat: 48.5576647, lng: 19.9322193 },
    { lat: 48.5588254, lng: 19.9316976 },
    { lat: 48.5592126, lng: 19.9311054 },
    { lat: 48.5598074, lng: 19.9309189 },
    { lat: 48.5600054, lng: 19.9307572 },
    { lat: 48.5604168, lng: 19.9302289 },
    { lat: 48.5606621, lng: 19.9297187 },
    { lat: 48.5615614, lng: 19.928646 },
    { lat: 48.561944, lng: 19.9279479 },
    { lat: 48.5620503, lng: 19.9274263 },
    { lat: 48.5616909, lng: 19.9273184 },
    { lat: 48.5614841, lng: 19.9269578 },
    { lat: 48.5607403, lng: 19.9263711 },
    { lat: 48.5605877, lng: 19.9256425 },
    { lat: 48.5601455, lng: 19.9245444 },
    { lat: 48.5601204, lng: 19.9243545 },
    { lat: 48.5601937, lng: 19.9228772 },
    { lat: 48.5601146, lng: 19.9223386 },
    { lat: 48.5600971, lng: 19.921613 },
    { lat: 48.5599086, lng: 19.9209677 },
    { lat: 48.5594643, lng: 19.9182371 },
    { lat: 48.5587838, lng: 19.9152031 },
    { lat: 48.5584873, lng: 19.9139313 },
  ],
  Neporadza: [
    { lat: 48.3821958, lng: 20.4029177 },
    { lat: 48.3823842, lng: 20.40272 },
    { lat: 48.3825668, lng: 20.4025293 },
    { lat: 48.3828348, lng: 20.4032609 },
    { lat: 48.3832449, lng: 20.4043883 },
    { lat: 48.3834621, lng: 20.4049873 },
    { lat: 48.3836834, lng: 20.4054372 },
    { lat: 48.3839107, lng: 20.405909 },
    { lat: 48.3840455, lng: 20.4061836 },
    { lat: 48.3838947, lng: 20.4053735 },
    { lat: 48.3837702, lng: 20.4040976 },
    { lat: 48.3839048, lng: 20.4026537 },
    { lat: 48.3841891, lng: 20.4012636 },
    { lat: 48.3845406, lng: 20.4003371 },
    { lat: 48.3849681, lng: 20.3995949 },
    { lat: 48.3857714, lng: 20.3971259 },
    { lat: 48.3861, lng: 20.3943474 },
    { lat: 48.3861933, lng: 20.3941111 },
    { lat: 48.3871406, lng: 20.3926292 },
    { lat: 48.3870908, lng: 20.3922998 },
    { lat: 48.386199, lng: 20.3906447 },
    { lat: 48.3855433, lng: 20.3896156 },
    { lat: 48.3854161, lng: 20.3892129 },
    { lat: 48.3846956, lng: 20.3883723 },
    { lat: 48.3844477, lng: 20.3882221 },
    { lat: 48.3833388, lng: 20.3881718 },
    { lat: 48.3817476, lng: 20.3877727 },
    { lat: 48.3804695, lng: 20.3870453 },
    { lat: 48.3801926, lng: 20.3867266 },
    { lat: 48.379444, lng: 20.3851656 },
    { lat: 48.3788283, lng: 20.3829058 },
    { lat: 48.3785537, lng: 20.3816354 },
    { lat: 48.3785087, lng: 20.3800945 },
    { lat: 48.3782596, lng: 20.3793929 },
    { lat: 48.3780616, lng: 20.3784368 },
    { lat: 48.3776972, lng: 20.3777542 },
    { lat: 48.3773942, lng: 20.3769695 },
    { lat: 48.3768451, lng: 20.3764844 },
    { lat: 48.3765779, lng: 20.3762768 },
    { lat: 48.3759232, lng: 20.3778026 },
    { lat: 48.3755669, lng: 20.3782882 },
    { lat: 48.3750795, lng: 20.379364 },
    { lat: 48.3743761, lng: 20.3805408 },
    { lat: 48.3743213, lng: 20.3804606 },
    { lat: 48.3740749, lng: 20.3809649 },
    { lat: 48.3739368, lng: 20.3811232 },
    { lat: 48.37379, lng: 20.3812263 },
    { lat: 48.3737735, lng: 20.3811481 },
    { lat: 48.3734958, lng: 20.3813926 },
    { lat: 48.3732082, lng: 20.3814135 },
    { lat: 48.3731938, lng: 20.3815341 },
    { lat: 48.3725802, lng: 20.3812158 },
    { lat: 48.3717407, lng: 20.3821636 },
    { lat: 48.3719861, lng: 20.3823954 },
    { lat: 48.3722201, lng: 20.3827943 },
    { lat: 48.3717744, lng: 20.3833808 },
    { lat: 48.3711644, lng: 20.3839731 },
    { lat: 48.3704799, lng: 20.3844451 },
    { lat: 48.3691944, lng: 20.3839912 },
    { lat: 48.3689833, lng: 20.3838365 },
    { lat: 48.36882, lng: 20.3836046 },
    { lat: 48.3686978, lng: 20.3832405 },
    { lat: 48.3687124, lng: 20.3830094 },
    { lat: 48.3688864, lng: 20.382236 },
    { lat: 48.3689358, lng: 20.3812173 },
    { lat: 48.3688241, lng: 20.3806594 },
    { lat: 48.3686792, lng: 20.3800568 },
    { lat: 48.3684276, lng: 20.3795217 },
    { lat: 48.3676206, lng: 20.3786051 },
    { lat: 48.3675588, lng: 20.3780588 },
    { lat: 48.3664122, lng: 20.3782291 },
    { lat: 48.3659369, lng: 20.3781385 },
    { lat: 48.3653563, lng: 20.3782094 },
    { lat: 48.3647587, lng: 20.3779046 },
    { lat: 48.364459, lng: 20.3774913 },
    { lat: 48.3639893, lng: 20.3766481 },
    { lat: 48.363391, lng: 20.3759733 },
    { lat: 48.3630513, lng: 20.3757799 },
    { lat: 48.3626829, lng: 20.3756889 },
    { lat: 48.3623483, lng: 20.3753683 },
    { lat: 48.3620187, lng: 20.3747076 },
    { lat: 48.361937, lng: 20.37464 },
    { lat: 48.361334, lng: 20.3736116 },
    { lat: 48.3611513, lng: 20.3731354 },
    { lat: 48.3607514, lng: 20.3714791 },
    { lat: 48.3604027, lng: 20.371465 },
    { lat: 48.3601721, lng: 20.3716987 },
    { lat: 48.3594521, lng: 20.3720698 },
    { lat: 48.3588916, lng: 20.3721988 },
    { lat: 48.3588811, lng: 20.3720984 },
    { lat: 48.3579267, lng: 20.371853 },
    { lat: 48.3576953, lng: 20.3719844 },
    { lat: 48.3568312, lng: 20.3720667 },
    { lat: 48.3564697, lng: 20.3722672 },
    { lat: 48.3564305, lng: 20.3726789 },
    { lat: 48.3556379, lng: 20.3727117 },
    { lat: 48.354923, lng: 20.3730079 },
    { lat: 48.3542524, lng: 20.3738356 },
    { lat: 48.3542386, lng: 20.3743491 },
    { lat: 48.3539399, lng: 20.3762001 },
    { lat: 48.353504, lng: 20.3776225 },
    { lat: 48.3523087, lng: 20.3788864 },
    { lat: 48.3513264, lng: 20.3794629 },
    { lat: 48.3505357, lng: 20.3796063 },
    { lat: 48.3502571, lng: 20.380635 },
    { lat: 48.3501765, lng: 20.3818666 },
    { lat: 48.3501944, lng: 20.3825353 },
    { lat: 48.3505318, lng: 20.3832235 },
    { lat: 48.3508739, lng: 20.3835912 },
    { lat: 48.3511552, lng: 20.3841231 },
    { lat: 48.3513621, lng: 20.3843379 },
    { lat: 48.3517888, lng: 20.3853733 },
    { lat: 48.3519783, lng: 20.3858155 },
    { lat: 48.3522632, lng: 20.3864725 },
    { lat: 48.3524087, lng: 20.3868425 },
    { lat: 48.3529626, lng: 20.3881559 },
    { lat: 48.3534609, lng: 20.3894709 },
    { lat: 48.3537699, lng: 20.39022 },
    { lat: 48.3542177, lng: 20.3912019 },
    { lat: 48.3545465, lng: 20.3919417 },
    { lat: 48.3550536, lng: 20.3931688 },
    { lat: 48.355335, lng: 20.39333 },
    { lat: 48.355453, lng: 20.393366 },
    { lat: 48.355529, lng: 20.393379 },
    { lat: 48.35555, lng: 20.393419 },
    { lat: 48.355601, lng: 20.393532 },
    { lat: 48.355879, lng: 20.394149 },
    { lat: 48.356141, lng: 20.394702 },
    { lat: 48.356232, lng: 20.394898 },
    { lat: 48.356261, lng: 20.39502 },
    { lat: 48.356257, lng: 20.395142 },
    { lat: 48.35622, lng: 20.395269 },
    { lat: 48.356066, lng: 20.395677 },
    { lat: 48.356028, lng: 20.395833 },
    { lat: 48.35605, lng: 20.395918 },
    { lat: 48.35632, lng: 20.396568 },
    { lat: 48.356306, lng: 20.396585 },
    { lat: 48.356015, lng: 20.396938 },
    { lat: 48.355793, lng: 20.397208 },
    { lat: 48.355929, lng: 20.397556 },
    { lat: 48.3560422, lng: 20.3981098 },
    { lat: 48.356336, lng: 20.398597 },
    { lat: 48.356491, lng: 20.398992 },
    { lat: 48.356679, lng: 20.398942 },
    { lat: 48.356858, lng: 20.398867 },
    { lat: 48.357235, lng: 20.398694 },
    { lat: 48.357248, lng: 20.398685 },
    { lat: 48.357478, lng: 20.399103 },
    { lat: 48.3582821, lng: 20.4004254 },
    { lat: 48.3589426, lng: 20.3989886 },
    { lat: 48.3592606, lng: 20.3998044 },
    { lat: 48.3595682, lng: 20.4008372 },
    { lat: 48.3600677, lng: 20.4020521 },
    { lat: 48.3603787, lng: 20.40293 },
    { lat: 48.3607838, lng: 20.4040602 },
    { lat: 48.3611001, lng: 20.4043566 },
    { lat: 48.361271, lng: 20.4045202 },
    { lat: 48.3615754, lng: 20.4049371 },
    { lat: 48.361742, lng: 20.405372 },
    { lat: 48.3618413, lng: 20.4056393 },
    { lat: 48.3619893, lng: 20.406024 },
    { lat: 48.3620615, lng: 20.4061449 },
    { lat: 48.3623602, lng: 20.4066729 },
    { lat: 48.3626545, lng: 20.4071878 },
    { lat: 48.3628213, lng: 20.4074785 },
    { lat: 48.3629868, lng: 20.4075556 },
    { lat: 48.363093, lng: 20.4076053 },
    { lat: 48.3632171, lng: 20.4075951 },
    { lat: 48.3633361, lng: 20.4075894 },
    { lat: 48.364442, lng: 20.407518 },
    { lat: 48.3646397, lng: 20.4075025 },
    { lat: 48.3646976, lng: 20.4076382 },
    { lat: 48.3651056, lng: 20.4085403 },
    { lat: 48.3653373, lng: 20.4090565 },
    { lat: 48.3655054, lng: 20.4094271 },
    { lat: 48.3658749, lng: 20.4098119 },
    { lat: 48.3661261, lng: 20.4099312 },
    { lat: 48.3664619, lng: 20.4099149 },
    { lat: 48.3665452, lng: 20.4098857 },
    { lat: 48.3669152, lng: 20.4097474 },
    { lat: 48.3672131, lng: 20.4096306 },
    { lat: 48.3679832, lng: 20.409333 },
    { lat: 48.3682355, lng: 20.4092381 },
    { lat: 48.36842, lng: 20.4095563 },
    { lat: 48.368627, lng: 20.4099059 },
    { lat: 48.3687791, lng: 20.4101667 },
    { lat: 48.369103, lng: 20.410724 },
    { lat: 48.3693646, lng: 20.4111586 },
    { lat: 48.3697682, lng: 20.4118427 },
    { lat: 48.3702856, lng: 20.4115007 },
    { lat: 48.3710905, lng: 20.4114796 },
    { lat: 48.3715887, lng: 20.4110802 },
    { lat: 48.3724179, lng: 20.4101193 },
    { lat: 48.3726342, lng: 20.409863 },
    { lat: 48.372841, lng: 20.409729 },
    { lat: 48.3736861, lng: 20.4091863 },
    { lat: 48.3738652, lng: 20.4090706 },
    { lat: 48.3739939, lng: 20.4089904 },
    { lat: 48.3743293, lng: 20.4087745 },
    { lat: 48.3746128, lng: 20.4085897 },
    { lat: 48.3749538, lng: 20.4083702 },
    { lat: 48.3755235, lng: 20.4080039 },
    { lat: 48.3762202, lng: 20.4078067 },
    { lat: 48.3764996, lng: 20.4077248 },
    { lat: 48.3768803, lng: 20.4076226 },
    { lat: 48.3770311, lng: 20.4075755 },
    { lat: 48.3773324, lng: 20.4074946 },
    { lat: 48.3779014, lng: 20.406855 },
    { lat: 48.3781254, lng: 20.4066014 },
    { lat: 48.3782098, lng: 20.4065924 },
    { lat: 48.3787167, lng: 20.4065343 },
    { lat: 48.378988, lng: 20.4066636 },
    { lat: 48.3793716, lng: 20.4068507 },
    { lat: 48.3797654, lng: 20.4064787 },
    { lat: 48.3799781, lng: 20.406004 },
    { lat: 48.3801722, lng: 20.4055707 },
    { lat: 48.38015, lng: 20.405187 },
    { lat: 48.3801412, lng: 20.4050373 },
    { lat: 48.3802883, lng: 20.4049022 },
    { lat: 48.3805626, lng: 20.4046567 },
    { lat: 48.3807209, lng: 20.404408 },
    { lat: 48.380895, lng: 20.4041415 },
    { lat: 48.380968, lng: 20.404009 },
    { lat: 48.3811181, lng: 20.4037985 },
    { lat: 48.3812428, lng: 20.4036024 },
    { lat: 48.3812951, lng: 20.4035217 },
    { lat: 48.3815431, lng: 20.4036147 },
    { lat: 48.3819827, lng: 20.403142 },
    { lat: 48.3821958, lng: 20.4029177 },
  ],
  ČiernyPotok: [
    { lat: 48.3188123, lng: 19.9299352 },
    { lat: 48.318734, lng: 19.9299212 },
    { lat: 48.3187069, lng: 19.929762 },
    { lat: 48.3177466, lng: 19.9291425 },
    { lat: 48.3168398, lng: 19.9276402 },
    { lat: 48.3162495, lng: 19.9261963 },
    { lat: 48.3162016, lng: 19.9261814 },
    { lat: 48.3162415, lng: 19.924747 },
    { lat: 48.3165309, lng: 19.9223479 },
    { lat: 48.3163329, lng: 19.9219847 },
    { lat: 48.3148913, lng: 19.9207039 },
    { lat: 48.3136359, lng: 19.9201424 },
    { lat: 48.3124959, lng: 19.9198038 },
    { lat: 48.3114565, lng: 19.919155 },
    { lat: 48.3098967, lng: 19.9177212 },
    { lat: 48.3083203, lng: 19.9156143 },
    { lat: 48.3079175, lng: 19.9153102 },
    { lat: 48.3070883, lng: 19.9144909 },
    { lat: 48.3065002, lng: 19.9142259 },
    { lat: 48.3055756, lng: 19.914107 },
    { lat: 48.3051057, lng: 19.9143147 },
    { lat: 48.3047529, lng: 19.9152252 },
    { lat: 48.3046451, lng: 19.9153148 },
    { lat: 48.304344, lng: 19.9153827 },
    { lat: 48.3039466, lng: 19.9152324 },
    { lat: 48.30153, lng: 19.9134743 },
    { lat: 48.3012514, lng: 19.9138574 },
    { lat: 48.3009868, lng: 19.9145191 },
    { lat: 48.3006456, lng: 19.9151008 },
    { lat: 48.3001206, lng: 19.915623 },
    { lat: 48.2995254, lng: 19.9160624 },
    { lat: 48.2988336, lng: 19.9168735 },
    { lat: 48.298453, lng: 19.9173244 },
    { lat: 48.2981024, lng: 19.917897 },
    { lat: 48.297384, lng: 19.9187432 },
    { lat: 48.2970941, lng: 19.9192119 },
    { lat: 48.2969254, lng: 19.9193735 },
    { lat: 48.2965944, lng: 19.9198515 },
    { lat: 48.2965183, lng: 19.9203404 },
    { lat: 48.2965042, lng: 19.9216914 },
    { lat: 48.2964645, lng: 19.922026 },
    { lat: 48.2960163, lng: 19.9235045 },
    { lat: 48.2957688, lng: 19.9242744 },
    { lat: 48.2949744, lng: 19.9259785 },
    { lat: 48.2944469, lng: 19.9274747 },
    { lat: 48.2938386, lng: 19.9286341 },
    { lat: 48.2936016, lng: 19.9294136 },
    { lat: 48.2934612, lng: 19.930187 },
    { lat: 48.2933326, lng: 19.930707 },
    { lat: 48.2930944, lng: 19.9313797 },
    { lat: 48.2931518, lng: 19.9314096 },
    { lat: 48.2931511, lng: 19.9321833 },
    { lat: 48.2928801, lng: 19.9337224 },
    { lat: 48.2927675, lng: 19.9347789 },
    { lat: 48.2922682, lng: 19.9380704 },
    { lat: 48.2921179, lng: 19.9393767 },
    { lat: 48.2918648, lng: 19.9406536 },
    { lat: 48.2917955, lng: 19.9414809 },
    { lat: 48.2916567, lng: 19.9416357 },
    { lat: 48.2911831, lng: 19.943366 },
    { lat: 48.2898835, lng: 19.9471017 },
    { lat: 48.2897017, lng: 19.9477824 },
    { lat: 48.2897969, lng: 19.9485324 },
    { lat: 48.2898501, lng: 19.9501785 },
    { lat: 48.2900947, lng: 19.9532793 },
    { lat: 48.2906605, lng: 19.9555345 },
    { lat: 48.2909159, lng: 19.9571928 },
    { lat: 48.2908538, lng: 19.9573208 },
    { lat: 48.2891044, lng: 19.9586495 },
    { lat: 48.2885127, lng: 19.9590954 },
    { lat: 48.2908319, lng: 19.9634857 },
    { lat: 48.2908949, lng: 19.9634581 },
    { lat: 48.291211, lng: 19.9641645 },
    { lat: 48.2915997, lng: 19.9647224 },
    { lat: 48.291889, lng: 19.9652499 },
    { lat: 48.2930938, lng: 19.9666158 },
    { lat: 48.2933581, lng: 19.9666302 },
    { lat: 48.2942323, lng: 19.9676665 },
    { lat: 48.294877, lng: 19.968602 },
    { lat: 48.2951299, lng: 19.9691192 },
    { lat: 48.2953092, lng: 19.9697364 },
    { lat: 48.2954061, lng: 19.9731616 },
    { lat: 48.2967989, lng: 19.9686319 },
    { lat: 48.2971208, lng: 19.967722 },
    { lat: 48.2998142, lng: 19.9585188 },
    { lat: 48.3035075, lng: 19.946725 },
    { lat: 48.304335, lng: 19.9457647 },
    { lat: 48.3043317, lng: 19.9442573 },
    { lat: 48.3042831, lng: 19.9438823 },
    { lat: 48.30406, lng: 19.9430131 },
    { lat: 48.3039501, lng: 19.9415994 },
    { lat: 48.3040415, lng: 19.9410182 },
    { lat: 48.3045166, lng: 19.9398768 },
    { lat: 48.3048557, lng: 19.9386136 },
    { lat: 48.3049113, lng: 19.938649 },
    { lat: 48.3057174, lng: 19.9363428 },
    { lat: 48.3061692, lng: 19.9357117 },
    { lat: 48.3064945, lng: 19.935121 },
    { lat: 48.3066926, lng: 19.9349046 },
    { lat: 48.3070169, lng: 19.9344025 },
    { lat: 48.3072147, lng: 19.9340247 },
    { lat: 48.3075171, lng: 19.9336382 },
    { lat: 48.3079981, lng: 19.9332934 },
    { lat: 48.3084318, lng: 19.9333229 },
    { lat: 48.3089786, lng: 19.9329869 },
    { lat: 48.3096053, lng: 19.9327866 },
    { lat: 48.3102794, lng: 19.9322159 },
    { lat: 48.3107705, lng: 19.9316591 },
    { lat: 48.3111118, lng: 19.9313712 },
    { lat: 48.3112279, lng: 19.931187 },
    { lat: 48.3114535, lng: 19.9306104 },
    { lat: 48.311924, lng: 19.9283934 },
    { lat: 48.317788, lng: 19.9307967 },
    { lat: 48.3188123, lng: 19.9299352 },
  ],
  Drienčany: [
    { lat: 48.5080563, lng: 20.0740576 },
    { lat: 48.5081864, lng: 20.0731734 },
    { lat: 48.5084823, lng: 20.0720981 },
    { lat: 48.5084733, lng: 20.071693 },
    { lat: 48.5082829, lng: 20.0690747 },
    { lat: 48.5082747, lng: 20.0673832 },
    { lat: 48.5079188, lng: 20.0645559 },
    { lat: 48.5070639, lng: 20.0608598 },
    { lat: 48.5061026, lng: 20.0596493 },
    { lat: 48.5055129, lng: 20.0571677 },
    { lat: 48.5055615, lng: 20.0557829 },
    { lat: 48.5055697, lng: 20.0556276 },
    { lat: 48.5052709, lng: 20.0555949 },
    { lat: 48.5050301, lng: 20.0554396 },
    { lat: 48.5046711, lng: 20.054906 },
    { lat: 48.5041485, lng: 20.054308 },
    { lat: 48.5039388, lng: 20.0542794 },
    { lat: 48.5038005, lng: 20.0543933 },
    { lat: 48.5031063, lng: 20.0544379 },
    { lat: 48.5026482, lng: 20.0541482 },
    { lat: 48.5023858, lng: 20.0536841 },
    { lat: 48.5021288, lng: 20.0535203 },
    { lat: 48.5016933, lng: 20.0534231 },
    { lat: 48.5012785, lng: 20.0537111 },
    { lat: 48.5010794, lng: 20.0537206 },
    { lat: 48.5008699, lng: 20.0540177 },
    { lat: 48.5005843, lng: 20.0541674 },
    { lat: 48.4999429, lng: 20.0543466 },
    { lat: 48.4990716, lng: 20.0540027 },
    { lat: 48.498805, lng: 20.0535806 },
    { lat: 48.4984919, lng: 20.0533099 },
    { lat: 48.4982936, lng: 20.0532288 },
    { lat: 48.4978876, lng: 20.0538654 },
    { lat: 48.4974798, lng: 20.0549262 },
    { lat: 48.4973496, lng: 20.0551501 },
    { lat: 48.4970446, lng: 20.0554564 },
    { lat: 48.4967495, lng: 20.0556181 },
    { lat: 48.4962997, lng: 20.0556951 },
    { lat: 48.495084, lng: 20.0562834 },
    { lat: 48.4935732, lng: 20.0567357 },
    { lat: 48.4932854, lng: 20.0568022 },
    { lat: 48.4928559, lng: 20.056742 },
    { lat: 48.4925521, lng: 20.0567762 },
    { lat: 48.4923513, lng: 20.0568789 },
    { lat: 48.4918174, lng: 20.0573676 },
    { lat: 48.4916034, lng: 20.0574502 },
    { lat: 48.4913945, lng: 20.0574116 },
    { lat: 48.4911815, lng: 20.0566184 },
    { lat: 48.4908327, lng: 20.0568741 },
    { lat: 48.4903833, lng: 20.0566816 },
    { lat: 48.4880325, lng: 20.0551401 },
    { lat: 48.4860643, lng: 20.0539665 },
    { lat: 48.4862624, lng: 20.0528482 },
    { lat: 48.4864264, lng: 20.0526996 },
    { lat: 48.4867741, lng: 20.0513915 },
    { lat: 48.4867694, lng: 20.0508423 },
    { lat: 48.4865054, lng: 20.0504815 },
    { lat: 48.4864697, lng: 20.0499452 },
    { lat: 48.4861035, lng: 20.0488597 },
    { lat: 48.4846592, lng: 20.0479641 },
    { lat: 48.4841634, lng: 20.0481758 },
    { lat: 48.4836896, lng: 20.0479708 },
    { lat: 48.4834378, lng: 20.04741 },
    { lat: 48.4831456, lng: 20.0469678 },
    { lat: 48.4823795, lng: 20.046748 },
    { lat: 48.4822256, lng: 20.0458923 },
    { lat: 48.4819228, lng: 20.0455452 },
    { lat: 48.4817391, lng: 20.0437009 },
    { lat: 48.4800894, lng: 20.0434963 },
    { lat: 48.4796757, lng: 20.043543 },
    { lat: 48.4792776, lng: 20.0437218 },
    { lat: 48.4792169, lng: 20.0438344 },
    { lat: 48.4781571, lng: 20.0445157 },
    { lat: 48.477631, lng: 20.0444594 },
    { lat: 48.4773306, lng: 20.0445473 },
    { lat: 48.4768836, lng: 20.0448486 },
    { lat: 48.4765163, lng: 20.0454717 },
    { lat: 48.4761202, lng: 20.0457788 },
    { lat: 48.4755037, lng: 20.0459607 },
    { lat: 48.4751859, lng: 20.0462253 },
    { lat: 48.4749884, lng: 20.0466234 },
    { lat: 48.4748433, lng: 20.0476857 },
    { lat: 48.4746608, lng: 20.047493 },
    { lat: 48.4742362, lng: 20.047249 },
    { lat: 48.4736979, lng: 20.0468218 },
    { lat: 48.4730054, lng: 20.0458227 },
    { lat: 48.4729173, lng: 20.0448977 },
    { lat: 48.4726752, lng: 20.0441744 },
    { lat: 48.472121, lng: 20.0436309 },
    { lat: 48.47199, lng: 20.0438111 },
    { lat: 48.4718077, lng: 20.043408 },
    { lat: 48.4714442, lng: 20.043095 },
    { lat: 48.4712391, lng: 20.0430398 },
    { lat: 48.4710947, lng: 20.0433022 },
    { lat: 48.4708403, lng: 20.043575 },
    { lat: 48.4707958, lng: 20.0437528 },
    { lat: 48.4707949, lng: 20.0446002 },
    { lat: 48.4707298, lng: 20.044765 },
    { lat: 48.4703112, lng: 20.0452619 },
    { lat: 48.4702288, lng: 20.0457432 },
    { lat: 48.4698851, lng: 20.0458494 },
    { lat: 48.4697378, lng: 20.0462917 },
    { lat: 48.4694222, lng: 20.0464452 },
    { lat: 48.468915, lng: 20.0461174 },
    { lat: 48.468338, lng: 20.0469196 },
    { lat: 48.4681118, lng: 20.0474133 },
    { lat: 48.4678871, lng: 20.0482689 },
    { lat: 48.4673156, lng: 20.049391 },
    { lat: 48.4672378, lng: 20.0507745 },
    { lat: 48.4662036, lng: 20.0561897 },
    { lat: 48.4661562, lng: 20.0621641 },
    { lat: 48.4660572, lng: 20.0625425 },
    { lat: 48.4645549, lng: 20.0659781 },
    { lat: 48.4645058, lng: 20.0663429 },
    { lat: 48.4636177, lng: 20.0781957 },
    { lat: 48.4637728, lng: 20.0825203 },
    { lat: 48.464637, lng: 20.082527 },
    { lat: 48.4657225, lng: 20.0825449 },
    { lat: 48.4683635, lng: 20.0832694 },
    { lat: 48.4696962, lng: 20.0840941 },
    { lat: 48.4718452, lng: 20.0805207 },
    { lat: 48.4721457, lng: 20.0799147 },
    { lat: 48.4723024, lng: 20.079424 },
    { lat: 48.4724004, lng: 20.0795615 },
    { lat: 48.4728245, lng: 20.0802286 },
    { lat: 48.4735871, lng: 20.0809649 },
    { lat: 48.473513, lng: 20.0811941 },
    { lat: 48.474692, lng: 20.0828731 },
    { lat: 48.4760094, lng: 20.0845513 },
    { lat: 48.4764428, lng: 20.0851761 },
    { lat: 48.4764114, lng: 20.0843065 },
    { lat: 48.4767994, lng: 20.084288 },
    { lat: 48.477104, lng: 20.0839479 },
    { lat: 48.4771732, lng: 20.0832233 },
    { lat: 48.4771558, lng: 20.0821205 },
    { lat: 48.4774448, lng: 20.0815056 },
    { lat: 48.4775184, lng: 20.0812435 },
    { lat: 48.4777173, lng: 20.0808795 },
    { lat: 48.4779485, lng: 20.0808312 },
    { lat: 48.4781331, lng: 20.0805002 },
    { lat: 48.4781486, lng: 20.0801114 },
    { lat: 48.4787407, lng: 20.0790972 },
    { lat: 48.4787696, lng: 20.0784025 },
    { lat: 48.4786735, lng: 20.0769499 },
    { lat: 48.4791612, lng: 20.0749592 },
    { lat: 48.4795777, lng: 20.074655 },
    { lat: 48.4799187, lng: 20.074293 },
    { lat: 48.480185, lng: 20.0742776 },
    { lat: 48.4814986, lng: 20.0735998 },
    { lat: 48.4816813, lng: 20.0740674 },
    { lat: 48.4816759, lng: 20.0745653 },
    { lat: 48.4821118, lng: 20.0753013 },
    { lat: 48.48124, lng: 20.0772904 },
    { lat: 48.4812172, lng: 20.0774751 },
    { lat: 48.4821544, lng: 20.0775579 },
    { lat: 48.4824859, lng: 20.0782472 },
    { lat: 48.4821838, lng: 20.0788116 },
    { lat: 48.4818866, lng: 20.0789271 },
    { lat: 48.4816497, lng: 20.0791388 },
    { lat: 48.4812617, lng: 20.0793401 },
    { lat: 48.4796756, lng: 20.0805918 },
    { lat: 48.4793983, lng: 20.0810224 },
    { lat: 48.4791852, lng: 20.0814815 },
    { lat: 48.47886, lng: 20.0832365 },
    { lat: 48.4789933, lng: 20.0851568 },
    { lat: 48.4791142, lng: 20.0862041 },
    { lat: 48.4788641, lng: 20.0869119 },
    { lat: 48.4786493, lng: 20.0877911 },
    { lat: 48.4787208, lng: 20.0881061 },
    { lat: 48.4789237, lng: 20.0884131 },
    { lat: 48.4791036, lng: 20.0883512 },
    { lat: 48.4797918, lng: 20.0877495 },
    { lat: 48.4810302, lng: 20.0865568 },
    { lat: 48.4823863, lng: 20.0856272 },
    { lat: 48.4828727, lng: 20.0855967 },
    { lat: 48.4853115, lng: 20.0860671 },
    { lat: 48.4853694, lng: 20.0855025 },
    { lat: 48.486355, lng: 20.0856107 },
    { lat: 48.4868356, lng: 20.085519 },
    { lat: 48.4873378, lng: 20.0851923 },
    { lat: 48.4886946, lng: 20.0837568 },
    { lat: 48.4893849, lng: 20.083126 },
    { lat: 48.4900569, lng: 20.082624 },
    { lat: 48.4905636, lng: 20.082947 },
    { lat: 48.4932451, lng: 20.0829906 },
    { lat: 48.4944556, lng: 20.083091 },
    { lat: 48.4953006, lng: 20.0828806 },
    { lat: 48.4963614, lng: 20.0825148 },
    { lat: 48.497422, lng: 20.0819061 },
    { lat: 48.4987198, lng: 20.0806558 },
    { lat: 48.500526, lng: 20.0800693 },
    { lat: 48.5010246, lng: 20.0803065 },
    { lat: 48.5012838, lng: 20.0805018 },
    { lat: 48.5016871, lng: 20.0811581 },
    { lat: 48.5019067, lng: 20.0813997 },
    { lat: 48.5027322, lng: 20.0814241 },
    { lat: 48.503032, lng: 20.0813229 },
    { lat: 48.5037057, lng: 20.0808039 },
    { lat: 48.5039431, lng: 20.0803626 },
    { lat: 48.504093, lng: 20.0798705 },
    { lat: 48.5046808, lng: 20.0792952 },
    { lat: 48.5054648, lng: 20.0787797 },
    { lat: 48.506076, lng: 20.0782778 },
    { lat: 48.5064889, lng: 20.0773146 },
    { lat: 48.5065864, lng: 20.0770532 },
    { lat: 48.5071343, lng: 20.0768773 },
    { lat: 48.5072734, lng: 20.0765876 },
    { lat: 48.5076601, lng: 20.0761607 },
    { lat: 48.5079095, lng: 20.0756844 },
    { lat: 48.5083649, lng: 20.0746522 },
    { lat: 48.5080563, lng: 20.0740576 },
  ],
  GemerskýJablonec: [
    { lat: 48.2104925, lng: 20.0130845 },
    { lat: 48.2105285, lng: 20.0129741 },
    { lat: 48.2113896, lng: 20.0089751 },
    { lat: 48.211632, lng: 20.0080718 },
    { lat: 48.212647, lng: 20.0088707 },
    { lat: 48.2130279, lng: 20.0074859 },
    { lat: 48.2129911, lng: 20.0063224 },
    { lat: 48.2133607, lng: 20.0053089 },
    { lat: 48.2133391, lng: 20.0047458 },
    { lat: 48.2138844, lng: 20.0037559 },
    { lat: 48.2144332, lng: 20.0030171 },
    { lat: 48.2147861, lng: 20.002271 },
    { lat: 48.2149409, lng: 20.002338 },
    { lat: 48.2150244, lng: 20.0026116 },
    { lat: 48.2151229, lng: 20.0026461 },
    { lat: 48.2153376, lng: 20.0035441 },
    { lat: 48.2155247, lng: 20.0038652 },
    { lat: 48.2162631, lng: 20.0058317 },
    { lat: 48.2169013, lng: 20.0059244 },
    { lat: 48.2184454, lng: 20.0059425 },
    { lat: 48.2183405, lng: 20.0044685 },
    { lat: 48.2183661, lng: 20.0041683 },
    { lat: 48.2181691, lng: 20.0035324 },
    { lat: 48.2180921, lng: 20.0029113 },
    { lat: 48.2178497, lng: 19.9998082 },
    { lat: 48.2177364, lng: 19.9992325 },
    { lat: 48.2174354, lng: 19.998746 },
    { lat: 48.2180974, lng: 19.9969265 },
    { lat: 48.2183426, lng: 19.9966998 },
    { lat: 48.2185844, lng: 19.9959984 },
    { lat: 48.2179911, lng: 19.9956328 },
    { lat: 48.2179879, lng: 19.9947592 },
    { lat: 48.2180828, lng: 19.993698 },
    { lat: 48.2180798, lng: 19.9923263 },
    { lat: 48.2179452, lng: 19.9914306 },
    { lat: 48.2183409, lng: 19.9878174 },
    { lat: 48.2183467, lng: 19.9867282 },
    { lat: 48.2182147, lng: 19.9860074 },
    { lat: 48.217628, lng: 19.986412 },
    { lat: 48.2166168, lng: 19.9866085 },
    { lat: 48.2155543, lng: 19.9875071 },
    { lat: 48.2150251, lng: 19.9878603 },
    { lat: 48.2145929, lng: 19.9874979 },
    { lat: 48.2136516, lng: 19.9870527 },
    { lat: 48.2125367, lng: 19.9866646 },
    { lat: 48.2119327, lng: 19.9866586 },
    { lat: 48.2114214, lng: 19.9849874 },
    { lat: 48.210585, lng: 19.9828961 },
    { lat: 48.2097962, lng: 19.9823599 },
    { lat: 48.2087659, lng: 19.9819438 },
    { lat: 48.2074668, lng: 19.9811453 },
    { lat: 48.2070055, lng: 19.9807832 },
    { lat: 48.2065276, lng: 19.9798493 },
    { lat: 48.2057748, lng: 19.978036 },
    { lat: 48.206372, lng: 19.9771109 },
    { lat: 48.2067933, lng: 19.9760482 },
    { lat: 48.2068765, lng: 19.9756713 },
    { lat: 48.2068923, lng: 19.9749048 },
    { lat: 48.2068108, lng: 19.9743631 },
    { lat: 48.2068945, lng: 19.9736269 },
    { lat: 48.2068536, lng: 19.9730647 },
    { lat: 48.2069123, lng: 19.972273 },
    { lat: 48.2068938, lng: 19.9720205 },
    { lat: 48.2067843, lng: 19.9714832 },
    { lat: 48.206504, lng: 19.9708808 },
    { lat: 48.2062676, lng: 19.9704877 },
    { lat: 48.2058339, lng: 19.9699533 },
    { lat: 48.2057048, lng: 19.9687995 },
    { lat: 48.2050845, lng: 19.9663963 },
    { lat: 48.2047857, lng: 19.9658636 },
    { lat: 48.204592, lng: 19.9657274 },
    { lat: 48.2043193, lng: 19.9653059 },
    { lat: 48.2028702, lng: 19.9658235 },
    { lat: 48.2018913, lng: 19.9659569 },
    { lat: 48.2018881, lng: 19.966311 },
    { lat: 48.2012374, lng: 19.9664248 },
    { lat: 48.1995642, lng: 19.9664406 },
    { lat: 48.1994965, lng: 19.9659376 },
    { lat: 48.1984461, lng: 19.9656608 },
    { lat: 48.1981769, lng: 19.9655317 },
    { lat: 48.1972945, lng: 19.9647538 },
    { lat: 48.1972679, lng: 19.9657157 },
    { lat: 48.1974346, lng: 19.9674935 },
    { lat: 48.1974368, lng: 19.9679124 },
    { lat: 48.1972172, lng: 19.9687369 },
    { lat: 48.1962704, lng: 19.9684057 },
    { lat: 48.1958445, lng: 19.9693593 },
    { lat: 48.1956195, lng: 19.9696042 },
    { lat: 48.1952744, lng: 19.9698089 },
    { lat: 48.1946221, lng: 19.969992 },
    { lat: 48.1940429, lng: 19.967761 },
    { lat: 48.1943093, lng: 19.9650213 },
    { lat: 48.1947008, lng: 19.9626273 },
    { lat: 48.1947325, lng: 19.9621257 },
    { lat: 48.1946855, lng: 19.9612606 },
    { lat: 48.1945801, lng: 19.9609794 },
    { lat: 48.1946019, lng: 19.9603555 },
    { lat: 48.1944636, lng: 19.9587753 },
    { lat: 48.1930704, lng: 19.9592039 },
    { lat: 48.1929714, lng: 19.9582043 },
    { lat: 48.1928117, lng: 19.9575755 },
    { lat: 48.1925789, lng: 19.9569774 },
    { lat: 48.1924503, lng: 19.956495 },
    { lat: 48.1923778, lng: 19.9564889 },
    { lat: 48.1922858, lng: 19.9560765 },
    { lat: 48.1921425, lng: 19.955855 },
    { lat: 48.191896, lng: 19.9557023 },
    { lat: 48.1915678, lng: 19.9560111 },
    { lat: 48.191263, lng: 19.9563939 },
    { lat: 48.1910123, lng: 19.9568521 },
    { lat: 48.1905104, lng: 19.9572907 },
    { lat: 48.1902704, lng: 19.9575791 },
    { lat: 48.1903229, lng: 19.9601869 },
    { lat: 48.1902504, lng: 19.9614711 },
    { lat: 48.1900215, lng: 19.9640076 },
    { lat: 48.1898571, lng: 19.9646213 },
    { lat: 48.1896902, lng: 19.9655981 },
    { lat: 48.1891116, lng: 19.9669012 },
    { lat: 48.1889056, lng: 19.9675414 },
    { lat: 48.1888763, lng: 19.9679134 },
    { lat: 48.1882266, lng: 19.9679233 },
    { lat: 48.188054, lng: 19.9673336 },
    { lat: 48.1878152, lng: 19.9674915 },
    { lat: 48.1870183, lng: 19.9685903 },
    { lat: 48.1860166, lng: 19.967318 },
    { lat: 48.1856387, lng: 19.9667203 },
    { lat: 48.1854442, lng: 19.9661751 },
    { lat: 48.1840712, lng: 19.9650148 },
    { lat: 48.1840694, lng: 19.9648939 },
    { lat: 48.1831474, lng: 19.9640853 },
    { lat: 48.1825052, lng: 19.9636336 },
    { lat: 48.1820268, lng: 19.9633829 },
    { lat: 48.1801423, lng: 19.9635711 },
    { lat: 48.1799379, lng: 19.9636862 },
    { lat: 48.180031, lng: 19.9639438 },
    { lat: 48.1800016, lng: 19.9641019 },
    { lat: 48.1797763, lng: 19.9639956 },
    { lat: 48.1801762, lng: 19.9650263 },
    { lat: 48.1804279, lng: 19.9657549 },
    { lat: 48.1805138, lng: 19.9661816 },
    { lat: 48.181277, lng: 19.9725004 },
    { lat: 48.1813701, lng: 19.9729973 },
    { lat: 48.1827104, lng: 19.9733614 },
    { lat: 48.1827059, lng: 19.9743184 },
    { lat: 48.1821769, lng: 19.9756288 },
    { lat: 48.1820606, lng: 19.9760207 },
    { lat: 48.1820076, lng: 19.9760737 },
    { lat: 48.1817728, lng: 19.9760314 },
    { lat: 48.1813687, lng: 19.9755929 },
    { lat: 48.1815666, lng: 19.9770668 },
    { lat: 48.1813172, lng: 19.9776934 },
    { lat: 48.1804871, lng: 19.9778576 },
    { lat: 48.1794235, lng: 19.9773207 },
    { lat: 48.1786917, lng: 19.9773576 },
    { lat: 48.1746916, lng: 19.9778643 },
    { lat: 48.1747389, lng: 19.9786881 },
    { lat: 48.1747029, lng: 19.9800994 },
    { lat: 48.1746039, lng: 19.9810825 },
    { lat: 48.1740612, lng: 19.9811932 },
    { lat: 48.17387, lng: 19.9828472 },
    { lat: 48.1737035, lng: 19.9838353 },
    { lat: 48.1732209, lng: 19.9847015 },
    { lat: 48.1729651, lng: 19.9850208 },
    { lat: 48.1728387, lng: 19.986478 },
    { lat: 48.17241, lng: 19.9884846 },
    { lat: 48.1721729, lng: 19.9889761 },
    { lat: 48.1718077, lng: 19.9905938 },
    { lat: 48.1715365, lng: 19.9914809 },
    { lat: 48.1714763, lng: 19.991548 },
    { lat: 48.1706937, lng: 19.9935475 },
    { lat: 48.1704883, lng: 19.9929003 },
    { lat: 48.1702559, lng: 19.992629 },
    { lat: 48.1698877, lng: 19.9924965 },
    { lat: 48.1696718, lng: 19.9925321 },
    { lat: 48.1691253, lng: 19.9927998 },
    { lat: 48.1687179, lng: 19.9933645 },
    { lat: 48.1685266, lng: 19.9933734 },
    { lat: 48.1682892, lng: 19.9927102 },
    { lat: 48.168257, lng: 19.99272 },
    { lat: 48.168218, lng: 19.99299 },
    { lat: 48.16815, lng: 19.993362 },
    { lat: 48.168133, lng: 19.993492 },
    { lat: 48.16808, lng: 19.993943 },
    { lat: 48.168045, lng: 19.99431 },
    { lat: 48.168012, lng: 19.994571 },
    { lat: 48.167989, lng: 19.99486 },
    { lat: 48.167979, lng: 19.994986 },
    { lat: 48.167984, lng: 19.995209 },
    { lat: 48.167995, lng: 19.995335 },
    { lat: 48.168038, lng: 19.995556 },
    { lat: 48.168134, lng: 19.995912 },
    { lat: 48.168237, lng: 19.996372 },
    { lat: 48.168286, lng: 19.99651 },
    { lat: 48.168334, lng: 19.99672 },
    { lat: 48.168384, lng: 19.997023 },
    { lat: 48.168427, lng: 19.997418 },
    { lat: 48.168472, lng: 19.997649 },
    { lat: 48.168525, lng: 19.997775 },
    { lat: 48.168567, lng: 19.997867 },
    { lat: 48.168651, lng: 19.997992 },
    { lat: 48.168724, lng: 19.998085 },
    { lat: 48.168818, lng: 19.998175 },
    { lat: 48.168919, lng: 19.99826 },
    { lat: 48.169073, lng: 19.998336 },
    { lat: 48.169204, lng: 19.998386 },
    { lat: 48.169348, lng: 19.99841 },
    { lat: 48.169499, lng: 19.998412 },
    { lat: 48.169597, lng: 19.998451 },
    { lat: 48.169679, lng: 19.998508 },
    { lat: 48.169732, lng: 19.998566 },
    { lat: 48.169782, lng: 19.998666 },
    { lat: 48.169812, lng: 19.998763 },
    { lat: 48.169851, lng: 19.998925 },
    { lat: 48.1698891, lng: 19.9992193 },
    { lat: 48.16994, lng: 19.999613 },
    { lat: 48.169953, lng: 19.999733 },
    { lat: 48.169981, lng: 20.000185 },
    { lat: 48.170034, lng: 20.000605 },
    { lat: 48.170055, lng: 20.000602 },
    { lat: 48.170315, lng: 20.001506 },
    { lat: 48.170498, lng: 20.001746 },
    { lat: 48.17104, lng: 20.002198 },
    { lat: 48.171079, lng: 20.002231 },
    { lat: 48.171157, lng: 20.002298 },
    { lat: 48.171575, lng: 20.002678 },
    { lat: 48.171962, lng: 20.00294 },
    { lat: 48.172283, lng: 20.003544 },
    { lat: 48.1724451, lng: 20.0035919 },
    { lat: 48.1741135, lng: 20.002261 },
    { lat: 48.1743341, lng: 20.0024935 },
    { lat: 48.176143, lng: 20.0013684 },
    { lat: 48.1765467, lng: 20.0010578 },
    { lat: 48.1764832, lng: 20.0003823 },
    { lat: 48.1769537, lng: 19.9993317 },
    { lat: 48.1778176, lng: 19.9981577 },
    { lat: 48.1779826, lng: 19.9978234 },
    { lat: 48.1782528, lng: 19.9978716 },
    { lat: 48.1783815, lng: 19.9976918 },
    { lat: 48.1787778, lng: 19.996725 },
    { lat: 48.1787036, lng: 19.9958535 },
    { lat: 48.1787255, lng: 19.9952772 },
    { lat: 48.1789494, lng: 19.9945769 },
    { lat: 48.1789818, lng: 19.9939594 },
    { lat: 48.1791827, lng: 19.993328 },
    { lat: 48.179099, lng: 19.9926064 },
    { lat: 48.1789702, lng: 19.9915819 },
    { lat: 48.1783509, lng: 19.9889803 },
    { lat: 48.1794207, lng: 19.9884389 },
    { lat: 48.1797297, lng: 19.9882102 },
    { lat: 48.18283, lng: 19.983216 },
    { lat: 48.1840659, lng: 19.9839598 },
    { lat: 48.1854182, lng: 19.9845041 },
    { lat: 48.1856485, lng: 19.9848091 },
    { lat: 48.1856838, lng: 19.9858524 },
    { lat: 48.1858087, lng: 19.9865664 },
    { lat: 48.1860399, lng: 19.986871 },
    { lat: 48.1866163, lng: 19.9870978 },
    { lat: 48.1863404, lng: 19.9886715 },
    { lat: 48.1867184, lng: 19.9892192 },
    { lat: 48.1872589, lng: 19.9895059 },
    { lat: 48.1872957, lng: 19.9896281 },
    { lat: 48.1874856, lng: 19.9897771 },
    { lat: 48.1877, lng: 19.9903968 },
    { lat: 48.1876063, lng: 19.990872 },
    { lat: 48.1879341, lng: 19.9910599 },
    { lat: 48.1878977, lng: 19.9914474 },
    { lat: 48.187824, lng: 19.9914131 },
    { lat: 48.187769, lng: 19.9917081 },
    { lat: 48.1875325, lng: 19.9938687 },
    { lat: 48.1877217, lng: 19.9940516 },
    { lat: 48.1879943, lng: 19.9941565 },
    { lat: 48.188224, lng: 19.994636 },
    { lat: 48.1888396, lng: 19.9949418 },
    { lat: 48.1896234, lng: 19.9950403 },
    { lat: 48.1902976, lng: 19.9949232 },
    { lat: 48.1909945, lng: 19.9946716 },
    { lat: 48.1925892, lng: 19.9943415 },
    { lat: 48.1932451, lng: 19.9943769 },
    { lat: 48.1932119, lng: 19.9963896 },
    { lat: 48.1935058, lng: 19.9972893 },
    { lat: 48.1948075, lng: 19.9970112 },
    { lat: 48.1951821, lng: 19.9970002 },
    { lat: 48.1961412, lng: 19.996453 },
    { lat: 48.1971384, lng: 20.000512 },
    { lat: 48.1973476, lng: 20.0020511 },
    { lat: 48.1976357, lng: 20.0035354 },
    { lat: 48.1976752, lng: 20.0043134 },
    { lat: 48.1975525, lng: 20.0057313 },
    { lat: 48.1978887, lng: 20.0071324 },
    { lat: 48.1979888, lng: 20.0073383 },
    { lat: 48.1993502, lng: 20.0083426 },
    { lat: 48.2001664, lng: 20.0091862 },
    { lat: 48.201168, lng: 20.0074003 },
    { lat: 48.2014016, lng: 20.0070616 },
    { lat: 48.201622, lng: 20.007211 },
    { lat: 48.2031407, lng: 20.0054578 },
    { lat: 48.2035131, lng: 20.0051253 },
    { lat: 48.2041274, lng: 20.004949 },
    { lat: 48.2045657, lng: 20.005003 },
    { lat: 48.2049471, lng: 20.0050966 },
    { lat: 48.2055031, lng: 20.0053503 },
    { lat: 48.2058841, lng: 20.0056268 },
    { lat: 48.2065659, lng: 20.0048333 },
    { lat: 48.2071366, lng: 20.0047907 },
    { lat: 48.2070359, lng: 20.004649 },
    { lat: 48.2072861, lng: 20.0049436 },
    { lat: 48.2075954, lng: 20.0051768 },
    { lat: 48.2077735, lng: 20.0054475 },
    { lat: 48.2078838, lng: 20.0058177 },
    { lat: 48.2079877, lng: 20.0066523 },
    { lat: 48.2080798, lng: 20.0069671 },
    { lat: 48.2083471, lng: 20.0074275 },
    { lat: 48.2087114, lng: 20.0083484 },
    { lat: 48.209036, lng: 20.010566 },
    { lat: 48.2090132, lng: 20.0114433 },
    { lat: 48.2091451, lng: 20.0123056 },
    { lat: 48.2099079, lng: 20.0120388 },
    { lat: 48.2104925, lng: 20.0130845 },
  ],
  Dulovo: [
    { lat: 48.3889925, lng: 20.2001961 },
    { lat: 48.3887654, lng: 20.1995913 },
    { lat: 48.3886653, lng: 20.1992574 },
    { lat: 48.3876557, lng: 20.1975739 },
    { lat: 48.3868525, lng: 20.1971236 },
    { lat: 48.3863828, lng: 20.1965076 },
    { lat: 48.3859631, lng: 20.1955947 },
    { lat: 48.3857448, lng: 20.19376 },
    { lat: 48.3852484, lng: 20.1927053 },
    { lat: 48.3839989, lng: 20.1920576 },
    { lat: 48.3825469, lng: 20.1898481 },
    { lat: 48.3824536, lng: 20.1895498 },
    { lat: 48.382349, lng: 20.1888018 },
    { lat: 48.382107, lng: 20.1876089 },
    { lat: 48.3812394, lng: 20.1850952 },
    { lat: 48.380953, lng: 20.1844743 },
    { lat: 48.3798149, lng: 20.1844902 },
    { lat: 48.3792352, lng: 20.1829689 },
    { lat: 48.3786018, lng: 20.1828173 },
    { lat: 48.3772059, lng: 20.1811389 },
    { lat: 48.3771153, lng: 20.180959 },
    { lat: 48.3769689, lng: 20.1803202 },
    { lat: 48.3768429, lng: 20.1802413 },
    { lat: 48.3767728, lng: 20.1805141 },
    { lat: 48.3766623, lng: 20.1805947 },
    { lat: 48.376445, lng: 20.1806496 },
    { lat: 48.3761228, lng: 20.1805384 },
    { lat: 48.3760923, lng: 20.1806478 },
    { lat: 48.3761195, lng: 20.1808472 },
    { lat: 48.376072, lng: 20.1809497 },
    { lat: 48.3759995, lng: 20.1809135 },
    { lat: 48.3759336, lng: 20.1806502 },
    { lat: 48.3758696, lng: 20.1806253 },
    { lat: 48.3757462, lng: 20.1807215 },
    { lat: 48.37589, lng: 20.1813521 },
    { lat: 48.3758557, lng: 20.1814484 },
    { lat: 48.375639, lng: 20.1814657 },
    { lat: 48.375665, lng: 20.1817461 },
    { lat: 48.3757638, lng: 20.1819368 },
    { lat: 48.3755575, lng: 20.1821519 },
    { lat: 48.3755048, lng: 20.1821326 },
    { lat: 48.3755266, lng: 20.1817502 },
    { lat: 48.3754638, lng: 20.1817021 },
    { lat: 48.3753343, lng: 20.181756 },
    { lat: 48.3752823, lng: 20.181866 },
    { lat: 48.3751579, lng: 20.1818246 },
    { lat: 48.3751415, lng: 20.1814775 },
    { lat: 48.3751034, lng: 20.1813984 },
    { lat: 48.3750094, lng: 20.1814162 },
    { lat: 48.3749178, lng: 20.1815439 },
    { lat: 48.3747413, lng: 20.1819114 },
    { lat: 48.3745368, lng: 20.1812035 },
    { lat: 48.3744247, lng: 20.1810749 },
    { lat: 48.3741983, lng: 20.1810225 },
    { lat: 48.3740805, lng: 20.1810627 },
    { lat: 48.374039, lng: 20.1810339 },
    { lat: 48.3739955, lng: 20.1808214 },
    { lat: 48.3738974, lng: 20.1807659 },
    { lat: 48.3735453, lng: 20.1806684 },
    { lat: 48.3734364, lng: 20.1807304 },
    { lat: 48.373545, lng: 20.1809929 },
    { lat: 48.3735891, lng: 20.1814689 },
    { lat: 48.3734277, lng: 20.1815697 },
    { lat: 48.373399, lng: 20.1819216 },
    { lat: 48.3732991, lng: 20.1820363 },
    { lat: 48.3732449, lng: 20.1824224 },
    { lat: 48.373129, lng: 20.1827617 },
    { lat: 48.3729635, lng: 20.1829564 },
    { lat: 48.372689, lng: 20.1829396 },
    { lat: 48.3725515, lng: 20.1828551 },
    { lat: 48.3724752, lng: 20.182889 },
    { lat: 48.3724207, lng: 20.1831267 },
    { lat: 48.3723452, lng: 20.1831279 },
    { lat: 48.3722304, lng: 20.1830155 },
    { lat: 48.3721282, lng: 20.1831371 },
    { lat: 48.3721783, lng: 20.1833153 },
    { lat: 48.3720799, lng: 20.1835542 },
    { lat: 48.3721303, lng: 20.1837427 },
    { lat: 48.3722763, lng: 20.1837874 },
    { lat: 48.3722233, lng: 20.1839234 },
    { lat: 48.3718687, lng: 20.1841261 },
    { lat: 48.3718409, lng: 20.1843011 },
    { lat: 48.3720889, lng: 20.1844755 },
    { lat: 48.3721787, lng: 20.1844659 },
    { lat: 48.3722681, lng: 20.184367 },
    { lat: 48.3723499, lng: 20.1844694 },
    { lat: 48.3725465, lng: 20.1844809 },
    { lat: 48.3725072, lng: 20.184816 },
    { lat: 48.372371, lng: 20.1849028 },
    { lat: 48.3723282, lng: 20.1851813 },
    { lat: 48.3724497, lng: 20.1852325 },
    { lat: 48.3726777, lng: 20.1850222 },
    { lat: 48.3726856, lng: 20.1848564 },
    { lat: 48.3729119, lng: 20.1848535 },
    { lat: 48.37287, lng: 20.1851018 },
    { lat: 48.3729573, lng: 20.1859189 },
    { lat: 48.3726575, lng: 20.1860858 },
    { lat: 48.3725844, lng: 20.1864194 },
    { lat: 48.3722963, lng: 20.1868704 },
    { lat: 48.37191, lng: 20.1869555 },
    { lat: 48.3718694, lng: 20.186843 },
    { lat: 48.3718648, lng: 20.1863677 },
    { lat: 48.3716965, lng: 20.1863841 },
    { lat: 48.3716711, lng: 20.1865341 },
    { lat: 48.3715684, lng: 20.1866958 },
    { lat: 48.3715432, lng: 20.1865741 },
    { lat: 48.3712837, lng: 20.1867884 },
    { lat: 48.3706242, lng: 20.1858247 },
    { lat: 48.3698683, lng: 20.1842911 },
    { lat: 48.3685939, lng: 20.1814145 },
    { lat: 48.368554, lng: 20.1811815 },
    { lat: 48.3684793, lng: 20.1811139 },
    { lat: 48.3684598, lng: 20.1809938 },
    { lat: 48.3683616, lng: 20.1809366 },
    { lat: 48.3674395, lng: 20.1797774 },
    { lat: 48.3650681, lng: 20.176648 },
    { lat: 48.3652713, lng: 20.1762994 },
    { lat: 48.3645842, lng: 20.1754143 },
    { lat: 48.3638395, lng: 20.1739054 },
    { lat: 48.3640016, lng: 20.1737135 },
    { lat: 48.3623105, lng: 20.170509 },
    { lat: 48.3620324, lng: 20.1698165 },
    { lat: 48.3617629, lng: 20.1684514 },
    { lat: 48.3616415, lng: 20.1680718 },
    { lat: 48.3617531, lng: 20.1678789 },
    { lat: 48.3614607, lng: 20.1668119 },
    { lat: 48.3613317, lng: 20.1664897 },
    { lat: 48.3600989, lng: 20.1651745 },
    { lat: 48.3593341, lng: 20.1646173 },
    { lat: 48.3589592, lng: 20.1641785 },
    { lat: 48.3578952, lng: 20.1636111 },
    { lat: 48.3577825, lng: 20.1645182 },
    { lat: 48.3577303, lng: 20.1657005 },
    { lat: 48.3572212, lng: 20.1673096 },
    { lat: 48.3573471, lng: 20.1686073 },
    { lat: 48.3576004, lng: 20.1702632 },
    { lat: 48.358985, lng: 20.1771297 },
    { lat: 48.3592944, lng: 20.1778679 },
    { lat: 48.3600241, lng: 20.1793684 },
    { lat: 48.3610952, lng: 20.1811967 },
    { lat: 48.3617151, lng: 20.1824878 },
    { lat: 48.3622133, lng: 20.1835814 },
    { lat: 48.3628921, lng: 20.1854643 },
    { lat: 48.363875, lng: 20.1876837 },
    { lat: 48.3653747, lng: 20.1907908 },
    { lat: 48.3674041, lng: 20.1938464 },
    { lat: 48.3676846, lng: 20.1944329 },
    { lat: 48.3681824, lng: 20.1952703 },
    { lat: 48.3687554, lng: 20.1960166 },
    { lat: 48.3703127, lng: 20.1987949 },
    { lat: 48.3705541, lng: 20.200457 },
    { lat: 48.37084, lng: 20.2019036 },
    { lat: 48.3713983, lng: 20.2027841 },
    { lat: 48.3729509, lng: 20.2063762 },
    { lat: 48.3730195, lng: 20.2076867 },
    { lat: 48.3731695, lng: 20.2092317 },
    { lat: 48.3734423, lng: 20.2097405 },
    { lat: 48.3737728, lng: 20.2105937 },
    { lat: 48.3738404, lng: 20.2109595 },
    { lat: 48.3742805, lng: 20.2107811 },
    { lat: 48.3756131, lng: 20.2098395 },
    { lat: 48.3759931, lng: 20.2099864 },
    { lat: 48.3766109, lng: 20.2080145 },
    { lat: 48.377635, lng: 20.2075475 },
    { lat: 48.3788069, lng: 20.207507 },
    { lat: 48.3802038, lng: 20.208379 },
    { lat: 48.381037, lng: 20.2082039 },
    { lat: 48.3823537, lng: 20.2076189 },
    { lat: 48.3838002, lng: 20.207319 },
    { lat: 48.384395, lng: 20.207377 },
    { lat: 48.3848827, lng: 20.2072934 },
    { lat: 48.3852439, lng: 20.2071181 },
    { lat: 48.3863296, lng: 20.2063637 },
    { lat: 48.3867637, lng: 20.205874 },
    { lat: 48.3870765, lng: 20.2051674 },
    { lat: 48.3876171, lng: 20.2044365 },
    { lat: 48.3879087, lng: 20.2038484 },
    { lat: 48.3883946, lng: 20.2022664 },
    { lat: 48.3884417, lng: 20.2014205 },
    { lat: 48.3885562, lng: 20.2010533 },
    { lat: 48.3889224, lng: 20.2003014 },
    { lat: 48.3889925, lng: 20.2001961 },
  ],
  Husiná: [
    { lat: 48.356069, lng: 19.865111 },
    { lat: 48.356031, lng: 19.865075 },
    { lat: 48.355519, lng: 19.864583 },
    { lat: 48.355459, lng: 19.864526 },
    { lat: 48.351397, lng: 19.860628 },
    { lat: 48.349792, lng: 19.859089 },
    { lat: 48.3488764, lng: 19.8582106 },
    { lat: 48.348218, lng: 19.857579 },
    { lat: 48.348063, lng: 19.857431 },
    { lat: 48.348037, lng: 19.856837 },
    { lat: 48.347959, lng: 19.855093 },
    { lat: 48.347933, lng: 19.854515 },
    { lat: 48.347525, lng: 19.854131 },
    { lat: 48.347512, lng: 19.85412 },
    { lat: 48.346634, lng: 19.854329 },
    { lat: 48.346047, lng: 19.854188 },
    { lat: 48.345917, lng: 19.854157 },
    { lat: 48.345776, lng: 19.854013 },
    { lat: 48.345759, lng: 19.853943 },
    { lat: 48.345584, lng: 19.853943 },
    { lat: 48.345551, lng: 19.853931 },
    { lat: 48.345512, lng: 19.853918 },
    { lat: 48.345501, lng: 19.85392 },
    { lat: 48.34535, lng: 19.853944 },
    { lat: 48.345311, lng: 19.853951 },
    { lat: 48.345284, lng: 19.853955 },
    { lat: 48.344979, lng: 19.854004 },
    { lat: 48.344711, lng: 19.85442 },
    { lat: 48.344238, lng: 19.854858 },
    { lat: 48.344194, lng: 19.8549 },
    { lat: 48.344093, lng: 19.854993 },
    { lat: 48.343835, lng: 19.854668 },
    { lat: 48.343783, lng: 19.854656 },
    { lat: 48.342286, lng: 19.854324 },
    { lat: 48.342189, lng: 19.854303 },
    { lat: 48.341277, lng: 19.854022 },
    { lat: 48.341195, lng: 19.853998 },
    { lat: 48.34115, lng: 19.854045 },
    { lat: 48.341039, lng: 19.854162 },
    { lat: 48.340212, lng: 19.85442 },
    { lat: 48.340158, lng: 19.854436 },
    { lat: 48.339768, lng: 19.854668 },
    { lat: 48.339452, lng: 19.854902 },
    { lat: 48.33915, lng: 19.855181 },
    { lat: 48.339014, lng: 19.855306 },
    { lat: 48.338766, lng: 19.855535 },
    { lat: 48.337329, lng: 19.856798 },
    { lat: 48.337316, lng: 19.856811 },
    { lat: 48.337144, lng: 19.857388 },
    { lat: 48.336859, lng: 19.858346 },
    { lat: 48.336761, lng: 19.858574 },
    { lat: 48.336393, lng: 19.859422 },
    { lat: 48.335757, lng: 19.860866 },
    { lat: 48.335539, lng: 19.861357 },
    { lat: 48.335397, lng: 19.861677 },
    { lat: 48.334595, lng: 19.8619 },
    { lat: 48.334547, lng: 19.861899 },
    { lat: 48.334416, lng: 19.861895 },
    { lat: 48.334353, lng: 19.861888 },
    { lat: 48.333968, lng: 19.861953 },
    { lat: 48.333544, lng: 19.861993 },
    { lat: 48.332561, lng: 19.862085 },
    { lat: 48.332213, lng: 19.862202 },
    { lat: 48.330959, lng: 19.862682 },
    { lat: 48.328639, lng: 19.863274 },
    { lat: 48.328759, lng: 19.864047 },
    { lat: 48.328347, lng: 19.865355 },
    { lat: 48.327548, lng: 19.867506 },
    { lat: 48.326109, lng: 19.868868 },
    { lat: 48.325469, lng: 19.869254 },
    { lat: 48.325397, lng: 19.869329 },
    { lat: 48.32537, lng: 19.869356 },
    { lat: 48.325097, lng: 19.869636 },
    { lat: 48.325014, lng: 19.86972 },
    { lat: 48.324578, lng: 19.870165 },
    { lat: 48.323535, lng: 19.871233 },
    { lat: 48.322791, lng: 19.871627 },
    { lat: 48.322664, lng: 19.871588 },
    { lat: 48.322367, lng: 19.871497 },
    { lat: 48.321394, lng: 19.871214 },
    { lat: 48.320482, lng: 19.871418 },
    { lat: 48.319663, lng: 19.871211 },
    { lat: 48.318998, lng: 19.871605 },
    { lat: 48.318507, lng: 19.871953 },
    { lat: 48.31838, lng: 19.872158 },
    { lat: 48.318283, lng: 19.872313 },
    { lat: 48.318095, lng: 19.872618 },
    { lat: 48.317843, lng: 19.872887 },
    { lat: 48.317666, lng: 19.873076 },
    { lat: 48.317556, lng: 19.873193 },
    { lat: 48.317475, lng: 19.87328 },
    { lat: 48.317287, lng: 19.87348 },
    { lat: 48.316848, lng: 19.873867 },
    { lat: 48.316597, lng: 19.874085 },
    { lat: 48.316389, lng: 19.874265 },
    { lat: 48.316319, lng: 19.874297 },
    { lat: 48.316269, lng: 19.87432 },
    { lat: 48.316048, lng: 19.874422 },
    { lat: 48.315939, lng: 19.874471 },
    { lat: 48.315547, lng: 19.874651 },
    { lat: 48.314577, lng: 19.874628 },
    { lat: 48.314101, lng: 19.874535 },
    { lat: 48.313585, lng: 19.874433 },
    { lat: 48.313331, lng: 19.874383 },
    { lat: 48.313083, lng: 19.874334 },
    { lat: 48.312529, lng: 19.873972 },
    { lat: 48.311479, lng: 19.874239 },
    { lat: 48.311444, lng: 19.874248 },
    { lat: 48.31139, lng: 19.87463 },
    { lat: 48.310759, lng: 19.875184 },
    { lat: 48.310762, lng: 19.875491 },
    { lat: 48.311169, lng: 19.875655 },
    { lat: 48.31146, lng: 19.876709 },
    { lat: 48.311393, lng: 19.876964 },
    { lat: 48.31136, lng: 19.877131 },
    { lat: 48.311268, lng: 19.877617 },
    { lat: 48.311231, lng: 19.877809 },
    { lat: 48.311135, lng: 19.878307 },
    { lat: 48.311114, lng: 19.878416 },
    { lat: 48.311052, lng: 19.879339 },
    { lat: 48.310947, lng: 19.880661 },
    { lat: 48.3109147, lng: 19.8822278 },
    { lat: 48.3110323, lng: 19.8830441 },
    { lat: 48.3113491, lng: 19.8845928 },
    { lat: 48.3117163, lng: 19.8857655 },
    { lat: 48.3119643, lng: 19.8863962 },
    { lat: 48.312997, lng: 19.8882946 },
    { lat: 48.3135064, lng: 19.8899192 },
    { lat: 48.3149637, lng: 19.8931726 },
    { lat: 48.3150844, lng: 19.895191 },
    { lat: 48.3152795, lng: 19.8966922 },
    { lat: 48.3150975, lng: 19.8969105 },
    { lat: 48.3152716, lng: 19.8978913 },
    { lat: 48.3153233, lng: 19.8991187 },
    { lat: 48.3153125, lng: 19.9004643 },
    { lat: 48.3156335, lng: 19.9022489 },
    { lat: 48.315467, lng: 19.9027998 },
    { lat: 48.3154668, lng: 19.9043185 },
    { lat: 48.3155957, lng: 19.9050861 },
    { lat: 48.3156601, lng: 19.9062227 },
    { lat: 48.3164421, lng: 19.9080609 },
    { lat: 48.3161925, lng: 19.9101151 },
    { lat: 48.315772, lng: 19.911501 },
    { lat: 48.315692, lng: 19.9131297 },
    { lat: 48.3155538, lng: 19.9137708 },
    { lat: 48.3154772, lng: 19.9160263 },
    { lat: 48.3160203, lng: 19.9184462 },
    { lat: 48.3160489, lng: 19.920717 },
    { lat: 48.316259, lng: 19.9215214 },
    { lat: 48.3165309, lng: 19.9223479 },
    { lat: 48.3177664, lng: 19.9212595 },
    { lat: 48.3197573, lng: 19.9207401 },
    { lat: 48.3202478, lng: 19.9228549 },
    { lat: 48.3220915, lng: 19.9247411 },
    { lat: 48.3228294, lng: 19.9253745 },
    { lat: 48.3231816, lng: 19.9260478 },
    { lat: 48.323484, lng: 19.9262945 },
    { lat: 48.3257974, lng: 19.9264646 },
    { lat: 48.3260726, lng: 19.9263142 },
    { lat: 48.3276193, lng: 19.9245764 },
    { lat: 48.3276828, lng: 19.9255363 },
    { lat: 48.3278954, lng: 19.9269521 },
    { lat: 48.3278217, lng: 19.9277553 },
    { lat: 48.3276289, lng: 19.9281215 },
    { lat: 48.329336, lng: 19.9280753 },
    { lat: 48.3301912, lng: 19.9282876 },
    { lat: 48.3305253, lng: 19.9285932 },
    { lat: 48.3311078, lng: 19.9294124 },
    { lat: 48.3314272, lng: 19.9303751 },
    { lat: 48.3313978, lng: 19.9312722 },
    { lat: 48.3311816, lng: 19.9324598 },
    { lat: 48.3310125, lng: 19.932851 },
    { lat: 48.3321036, lng: 19.9349514 },
    { lat: 48.3321842, lng: 19.935462 },
    { lat: 48.3334641, lng: 19.9360472 },
    { lat: 48.3340076, lng: 19.9358994 },
    { lat: 48.3346258, lng: 19.9354439 },
    { lat: 48.3355765, lng: 19.9351537 },
    { lat: 48.3360803, lng: 19.9348723 },
    { lat: 48.3374893, lng: 19.9342845 },
    { lat: 48.338845, lng: 19.9338179 },
    { lat: 48.3404971, lng: 19.9333285 },
    { lat: 48.3406927, lng: 19.9324472 },
    { lat: 48.341205, lng: 19.9293825 },
    { lat: 48.342228, lng: 19.9306481 },
    { lat: 48.3433552, lng: 19.9322516 },
    { lat: 48.3443066, lng: 19.9334244 },
    { lat: 48.3469863, lng: 19.9370819 },
    { lat: 48.3491963, lng: 19.9398471 },
    { lat: 48.3495141, lng: 19.9404562 },
    { lat: 48.351, lng: 19.9423639 },
    { lat: 48.3511241, lng: 19.9424458 },
    { lat: 48.3517294, lng: 19.943139 },
    { lat: 48.3525875, lng: 19.9443469 },
    { lat: 48.35285, lng: 19.944824 },
    { lat: 48.3532088, lng: 19.9452104 },
    { lat: 48.3550624, lng: 19.9476292 },
    { lat: 48.3561131, lng: 19.9489142 },
    { lat: 48.3568453, lng: 19.9499732 },
    { lat: 48.3577769, lng: 19.9511616 },
    { lat: 48.3581009, lng: 19.9516814 },
    { lat: 48.3581519, lng: 19.9517435 },
    { lat: 48.3582989, lng: 19.9519225 },
    { lat: 48.3588719, lng: 19.9526203 },
    { lat: 48.3604264, lng: 19.9548476 },
    { lat: 48.3610495, lng: 19.9555662 },
    { lat: 48.3616158, lng: 19.9563753 },
    { lat: 48.3618256, lng: 19.9566751 },
    { lat: 48.3622653, lng: 19.9571066 },
    { lat: 48.362588, lng: 19.9576244 },
    { lat: 48.364658, lng: 19.9603214 },
    { lat: 48.3648399, lng: 19.9561933 },
    { lat: 48.3649133, lng: 19.9553764 },
    { lat: 48.3644747, lng: 19.9544935 },
    { lat: 48.3634797, lng: 19.9540322 },
    { lat: 48.3632053, lng: 19.9537247 },
    { lat: 48.3627044, lng: 19.952648 },
    { lat: 48.3617336, lng: 19.9513059 },
    { lat: 48.361235, lng: 19.9493031 },
    { lat: 48.3610395, lng: 19.948387 },
    { lat: 48.3610709, lng: 19.9483578 },
    { lat: 48.3610566, lng: 19.9482341 },
    { lat: 48.3610197, lng: 19.9482362 },
    { lat: 48.3603113, lng: 19.945465 },
    { lat: 48.3603572, lng: 19.945434 },
    { lat: 48.3603203, lng: 19.9453058 },
    { lat: 48.360286, lng: 19.9453187 },
    { lat: 48.3599867, lng: 19.9437137 },
    { lat: 48.3589282, lng: 19.9420349 },
    { lat: 48.3590148, lng: 19.9417117 },
    { lat: 48.3590213, lng: 19.941128 },
    { lat: 48.3586226, lng: 19.9358974 },
    { lat: 48.3582529, lng: 19.9333864 },
    { lat: 48.3571126, lng: 19.9336986 },
    { lat: 48.355462, lng: 19.9345893 },
    { lat: 48.3546698, lng: 19.9328441 },
    { lat: 48.3539169, lng: 19.9314643 },
    { lat: 48.3533252, lng: 19.9306113 },
    { lat: 48.3527009, lng: 19.9295029 },
    { lat: 48.3525463, lng: 19.9290736 },
    { lat: 48.3518663, lng: 19.9280825 },
    { lat: 48.3508956, lng: 19.9269554 },
    { lat: 48.3476723, lng: 19.9266911 },
    { lat: 48.3469223, lng: 19.9255655 },
    { lat: 48.3454889, lng: 19.9246595 },
    { lat: 48.3447985, lng: 19.9243473 },
    { lat: 48.3439362, lng: 19.9241782 },
    { lat: 48.343186, lng: 19.9232571 },
    { lat: 48.3408315, lng: 19.921363 },
    { lat: 48.3410844, lng: 19.9185643 },
    { lat: 48.342248, lng: 19.916823 },
    { lat: 48.3426603, lng: 19.9165892 },
    { lat: 48.3427832, lng: 19.9159846 },
    { lat: 48.3423908, lng: 19.9150323 },
    { lat: 48.3429588, lng: 19.9145852 },
    { lat: 48.3430897, lng: 19.9140152 },
    { lat: 48.3429076, lng: 19.9126804 },
    { lat: 48.3422187, lng: 19.9118828 },
    { lat: 48.3417973, lng: 19.9117776 },
    { lat: 48.3414199, lng: 19.9117726 },
    { lat: 48.3406426, lng: 19.9101259 },
    { lat: 48.3387935, lng: 19.9071452 },
    { lat: 48.3390282, lng: 19.9054265 },
    { lat: 48.3393708, lng: 19.9037734 },
    { lat: 48.3396835, lng: 19.9025078 },
    { lat: 48.3397338, lng: 19.9024965 },
    { lat: 48.3397226, lng: 19.902418 },
    { lat: 48.3402872, lng: 19.9014541 },
    { lat: 48.3408913, lng: 19.9007512 },
    { lat: 48.3422746, lng: 19.8995182 },
    { lat: 48.3436358, lng: 19.8973228 },
    { lat: 48.3441158, lng: 19.8968305 },
    { lat: 48.3460269, lng: 19.8952672 },
    { lat: 48.3474696, lng: 19.8936571 },
    { lat: 48.3481689, lng: 19.89266 },
    { lat: 48.3477702, lng: 19.8899625 },
    { lat: 48.3475595, lng: 19.8895232 },
    { lat: 48.3474744, lng: 19.8889556 },
    { lat: 48.3475872, lng: 19.8842057 },
    { lat: 48.3475284, lng: 19.8838619 },
    { lat: 48.3483097, lng: 19.8827473 },
    { lat: 48.3511377, lng: 19.8820474 },
    { lat: 48.3532297, lng: 19.8825155 },
    { lat: 48.3538978, lng: 19.8782668 },
    { lat: 48.3555101, lng: 19.873782 },
    { lat: 48.3561449, lng: 19.8728302 },
    { lat: 48.3563186, lng: 19.8720258 },
    { lat: 48.35626, lng: 19.871633 },
    { lat: 48.356423, lng: 19.871444 },
    { lat: 48.3564606, lng: 19.8711403 },
    { lat: 48.3563296, lng: 19.8707191 },
    { lat: 48.3563944, lng: 19.8701825 },
    { lat: 48.3560672, lng: 19.8689474 },
    { lat: 48.3561577, lng: 19.8685691 },
    { lat: 48.3561336, lng: 19.8683975 },
    { lat: 48.3560458, lng: 19.8682732 },
    { lat: 48.3558967, lng: 19.8676091 },
    { lat: 48.3559237, lng: 19.8668544 },
    { lat: 48.3558809, lng: 19.8656309 },
    { lat: 48.356069, lng: 19.865111 },
  ],
  Klenovec: [
    { lat: 48.573046, lng: 19.882507 },
    { lat: 48.5733151, lng: 19.8828098 },
    { lat: 48.5735727, lng: 19.8831397 },
    { lat: 48.5738468, lng: 19.8835206 },
    { lat: 48.5743996, lng: 19.8834592 },
    { lat: 48.5749559, lng: 19.8833997 },
    { lat: 48.575572, lng: 19.8834488 },
    { lat: 48.5758351, lng: 19.883309 },
    { lat: 48.5762564, lng: 19.8830992 },
    { lat: 48.5762684, lng: 19.8831415 },
    { lat: 48.5762967, lng: 19.883244 },
    { lat: 48.576635, lng: 19.8833601 },
    { lat: 48.5768813, lng: 19.8833247 },
    { lat: 48.5774413, lng: 19.883644 },
    { lat: 48.5771395, lng: 19.8840374 },
    { lat: 48.5771893, lng: 19.8842801 },
    { lat: 48.5773271, lng: 19.8847342 },
    { lat: 48.5774994, lng: 19.8849152 },
    { lat: 48.57745, lng: 19.8851274 },
    { lat: 48.5775545, lng: 19.8852022 },
    { lat: 48.5779319, lng: 19.8850971 },
    { lat: 48.5781822, lng: 19.8849998 },
    { lat: 48.5786, lng: 19.8849438 },
    { lat: 48.5788278, lng: 19.8849223 },
    { lat: 48.5788582, lng: 19.8849193 },
    { lat: 48.5789535, lng: 19.8850395 },
    { lat: 48.5793613, lng: 19.8853563 },
    { lat: 48.5797973, lng: 19.8856475 },
    { lat: 48.5803331, lng: 19.8859742 },
    { lat: 48.5804204, lng: 19.886985 },
    { lat: 48.5805577, lng: 19.8875882 },
    { lat: 48.5808502, lng: 19.8882061 },
    { lat: 48.5809964, lng: 19.8885427 },
    { lat: 48.5811029, lng: 19.8891318 },
    { lat: 48.581206, lng: 19.8896985 },
    { lat: 48.5812476, lng: 19.8901456 },
    { lat: 48.5812851, lng: 19.8904411 },
    { lat: 48.5812492, lng: 19.8907602 },
    { lat: 48.5812369, lng: 19.8911844 },
    { lat: 48.5811832, lng: 19.891639 },
    { lat: 48.5813807, lng: 19.892225 },
    { lat: 48.5815705, lng: 19.8928914 },
    { lat: 48.5817776, lng: 19.8933615 },
    { lat: 48.5817999, lng: 19.8934099 },
    { lat: 48.5819436, lng: 19.8937432 },
    { lat: 48.5822158, lng: 19.8944435 },
    { lat: 48.5824347, lng: 19.8949456 },
    { lat: 48.5824615, lng: 19.8951817 },
    { lat: 48.5825241, lng: 19.89564 },
    { lat: 48.5825361, lng: 19.8961077 },
    { lat: 48.5824433, lng: 19.8967017 },
    { lat: 48.5823377, lng: 19.8970756 },
    { lat: 48.5822773, lng: 19.8972971 },
    { lat: 48.5823768, lng: 19.897513 },
    { lat: 48.5824663, lng: 19.897659 },
    { lat: 48.5826813, lng: 19.897922 },
    { lat: 48.5827991, lng: 19.8981689 },
    { lat: 48.5829477, lng: 19.8985366 },
    { lat: 48.5832424, lng: 19.8990238 },
    { lat: 48.5834238, lng: 19.8993677 },
    { lat: 48.5834407, lng: 19.8993941 },
    { lat: 48.5834519, lng: 19.8994169 },
    { lat: 48.5836205, lng: 19.900015 },
    { lat: 48.5838688, lng: 19.901001 },
    { lat: 48.5841039, lng: 19.9013375 },
    { lat: 48.5844993, lng: 19.9017303 },
    { lat: 48.5846178, lng: 19.9018956 },
    { lat: 48.584788, lng: 19.9022637 },
    { lat: 48.5848079, lng: 19.9023112 },
    { lat: 48.5852044, lng: 19.9027278 },
    { lat: 48.5853719, lng: 19.9030613 },
    { lat: 48.5856984, lng: 19.9035046 },
    { lat: 48.5857173, lng: 19.903554 },
    { lat: 48.5858369, lng: 19.9038494 },
    { lat: 48.5858963, lng: 19.9039483 },
    { lat: 48.5861792, lng: 19.9040279 },
    { lat: 48.5865229, lng: 19.9043204 },
    { lat: 48.5866118, lng: 19.9044214 },
    { lat: 48.5866493, lng: 19.9044814 },
    { lat: 48.5867653, lng: 19.9046655 },
    { lat: 48.5869048, lng: 19.9050583 },
    { lat: 48.5868939, lng: 19.905096 },
    { lat: 48.5869976, lng: 19.9051468 },
    { lat: 48.5870623, lng: 19.9051875 },
    { lat: 48.5870899, lng: 19.9051976 },
    { lat: 48.5871301, lng: 19.9052709 },
    { lat: 48.5872371, lng: 19.9053986 },
    { lat: 48.5873036, lng: 19.9055085 },
    { lat: 48.5873626, lng: 19.9056721 },
    { lat: 48.5874919, lng: 19.9057215 },
    { lat: 48.5876605, lng: 19.905766 },
    { lat: 48.5879761, lng: 19.9061147 },
    { lat: 48.5880248, lng: 19.9062217 },
    { lat: 48.5880671, lng: 19.9063983 },
    { lat: 48.5881256, lng: 19.9065513 },
    { lat: 48.588308, lng: 19.9068151 },
    { lat: 48.5883757, lng: 19.9068694 },
    { lat: 48.5883999, lng: 19.9069332 },
    { lat: 48.5884975, lng: 19.9069522 },
    { lat: 48.588527, lng: 19.9069579 },
    { lat: 48.5886191, lng: 19.907108 },
    { lat: 48.5886704, lng: 19.907309 },
    { lat: 48.5887841, lng: 19.9073297 },
    { lat: 48.588867, lng: 19.9072953 },
    { lat: 48.5889261, lng: 19.9073596 },
    { lat: 48.5890528, lng: 19.9073683 },
    { lat: 48.5891473, lng: 19.9073923 },
    { lat: 48.5892325, lng: 19.9075694 },
    { lat: 48.58938, lng: 19.9075963 },
    { lat: 48.5895443, lng: 19.9077512 },
    { lat: 48.5896818, lng: 19.907803 },
    { lat: 48.5898085, lng: 19.9079455 },
    { lat: 48.5898526, lng: 19.9080323 },
    { lat: 48.5899454, lng: 19.9080389 },
    { lat: 48.5901961, lng: 19.9082377 },
    { lat: 48.5903718, lng: 19.9083434 },
    { lat: 48.5904564, lng: 19.9084779 },
    { lat: 48.5907575, lng: 19.9084875 },
    { lat: 48.5908856, lng: 19.9086629 },
    { lat: 48.5908349, lng: 19.9087689 },
    { lat: 48.5908942, lng: 19.908919 },
    { lat: 48.5909767, lng: 19.9089495 },
    { lat: 48.5910597, lng: 19.9090186 },
    { lat: 48.5911246, lng: 19.9091397 },
    { lat: 48.5912121, lng: 19.9091885 },
    { lat: 48.5912661, lng: 19.9092644 },
    { lat: 48.5913941, lng: 19.9093303 },
    { lat: 48.591419, lng: 19.9093069 },
    { lat: 48.5914351, lng: 19.9092907 },
    { lat: 48.5916322, lng: 19.9093396 },
    { lat: 48.5918746, lng: 19.9095061 },
    { lat: 48.591962, lng: 19.9096004 },
    { lat: 48.5920761, lng: 19.9096577 },
    { lat: 48.5922193, lng: 19.9097395 },
    { lat: 48.5929969, lng: 19.9102253 },
    { lat: 48.5930063, lng: 19.9102297 },
    { lat: 48.5930481, lng: 19.9102346 },
    { lat: 48.5931451, lng: 19.9102358 },
    { lat: 48.5932095, lng: 19.9102388 },
    { lat: 48.5933762, lng: 19.9097657 },
    { lat: 48.593488, lng: 19.9094805 },
    { lat: 48.593596, lng: 19.9092946 },
    { lat: 48.5937797, lng: 19.9091921 },
    { lat: 48.5937897, lng: 19.9091872 },
    { lat: 48.5938194, lng: 19.9091711 },
    { lat: 48.5939453, lng: 19.9090564 },
    { lat: 48.5939613, lng: 19.9090104 },
    { lat: 48.5941864, lng: 19.909085 },
    { lat: 48.5942504, lng: 19.9092126 },
    { lat: 48.5943462, lng: 19.9093165 },
    { lat: 48.5945689, lng: 19.9094966 },
    { lat: 48.5946427, lng: 19.9095336 },
    { lat: 48.5947135, lng: 19.9097438 },
    { lat: 48.5947777, lng: 19.9097693 },
    { lat: 48.5949197, lng: 19.9099555 },
    { lat: 48.5949959, lng: 19.9100257 },
    { lat: 48.5950302, lng: 19.9100622 },
    { lat: 48.5951785, lng: 19.9102403 },
    { lat: 48.5953484, lng: 19.9104199 },
    { lat: 48.5955373, lng: 19.9102781 },
    { lat: 48.5956596, lng: 19.9103734 },
    { lat: 48.5957094, lng: 19.9104839 },
    { lat: 48.5957949, lng: 19.9105613 },
    { lat: 48.5958683, lng: 19.9105378 },
    { lat: 48.5959409, lng: 19.9105472 },
    { lat: 48.5960763, lng: 19.91066 },
    { lat: 48.5962073, lng: 19.9106669 },
    { lat: 48.5963485, lng: 19.9107002 },
    { lat: 48.5964656, lng: 19.9106721 },
    { lat: 48.5966237, lng: 19.9108151 },
    { lat: 48.5967085, lng: 19.9109261 },
    { lat: 48.5968266, lng: 19.9109794 },
    { lat: 48.5969675, lng: 19.9111871 },
    { lat: 48.5970465, lng: 19.9112752 },
    { lat: 48.5971789, lng: 19.9115777 },
    { lat: 48.5973439, lng: 19.911798 },
    { lat: 48.5973888, lng: 19.9119548 },
    { lat: 48.5974777, lng: 19.9120295 },
    { lat: 48.597575, lng: 19.9121759 },
    { lat: 48.5978763, lng: 19.9125037 },
    { lat: 48.5979732, lng: 19.9125562 },
    { lat: 48.5980172, lng: 19.912585 },
    { lat: 48.5980949, lng: 19.9126352 },
    { lat: 48.5983501, lng: 19.9128364 },
    { lat: 48.5984901, lng: 19.9129467 },
    { lat: 48.5986375, lng: 19.9129431 },
    { lat: 48.5987899, lng: 19.9131077 },
    { lat: 48.5988858, lng: 19.9131421 },
    { lat: 48.5989568, lng: 19.9131399 },
    { lat: 48.5991153, lng: 19.9130765 },
    { lat: 48.5992388, lng: 19.9130713 },
    { lat: 48.5997499, lng: 19.9130893 },
    { lat: 48.6000047, lng: 19.9130625 },
    { lat: 48.6002376, lng: 19.9132171 },
    { lat: 48.6003898, lng: 19.9131921 },
    { lat: 48.6005306, lng: 19.9133001 },
    { lat: 48.600572, lng: 19.9134235 },
    { lat: 48.6006744, lng: 19.9134229 },
    { lat: 48.6008638, lng: 19.9136138 },
    { lat: 48.6009964, lng: 19.9135995 },
    { lat: 48.6010715, lng: 19.9135684 },
    { lat: 48.6011946, lng: 19.9135771 },
    { lat: 48.6013366, lng: 19.9136555 },
    { lat: 48.6015396, lng: 19.9138437 },
    { lat: 48.6016271, lng: 19.9138925 },
    { lat: 48.601717, lng: 19.9138852 },
    { lat: 48.6018383, lng: 19.9139293 },
    { lat: 48.6020102, lng: 19.9140255 },
    { lat: 48.6020866, lng: 19.914101 },
    { lat: 48.6021677, lng: 19.9140704 },
    { lat: 48.6022328, lng: 19.9140409 },
    { lat: 48.6023026, lng: 19.9140879 },
    { lat: 48.6024574, lng: 19.914127 },
    { lat: 48.6025589, lng: 19.914209 },
    { lat: 48.6026782, lng: 19.9142341 },
    { lat: 48.602858, lng: 19.9143035 },
    { lat: 48.6030505, lng: 19.9142727 },
    { lat: 48.6031859, lng: 19.9143325 },
    { lat: 48.6032839, lng: 19.914333 },
    { lat: 48.6038052, lng: 19.9146174 },
    { lat: 48.6041219, lng: 19.9148302 },
    { lat: 48.6043889, lng: 19.9150132 },
    { lat: 48.6048784, lng: 19.9153207 },
    { lat: 48.6052429, lng: 19.9156253 },
    { lat: 48.6056589, lng: 19.9161591 },
    { lat: 48.6060491, lng: 19.9167007 },
    { lat: 48.6062588, lng: 19.9169954 },
    { lat: 48.6066352, lng: 19.917364 },
    { lat: 48.6068993, lng: 19.9175908 },
    { lat: 48.6072963, lng: 19.9178378 },
    { lat: 48.6073147, lng: 19.9178503 },
    { lat: 48.607352, lng: 19.917878 },
    { lat: 48.6073717, lng: 19.91789 },
    { lat: 48.6081167, lng: 19.9183226 },
    { lat: 48.6085617, lng: 19.9186105 },
    { lat: 48.6087334, lng: 19.9187288 },
    { lat: 48.6090764, lng: 19.9190633 },
    { lat: 48.6092118, lng: 19.9192477 },
    { lat: 48.6098238, lng: 19.9192842 },
    { lat: 48.6102314, lng: 19.9193334 },
    { lat: 48.6104569, lng: 19.9193112 },
    { lat: 48.6108725, lng: 19.9194432 },
    { lat: 48.6110709, lng: 19.919629 },
    { lat: 48.6115937, lng: 19.9197653 },
    { lat: 48.6117924, lng: 19.9194577 },
    { lat: 48.6118918, lng: 19.9193042 },
    { lat: 48.6123589, lng: 19.9192131 },
    { lat: 48.6127633, lng: 19.9190539 },
    { lat: 48.6130459, lng: 19.91895 },
    { lat: 48.6134395, lng: 19.9188232 },
    { lat: 48.6136583, lng: 19.9187522 },
    { lat: 48.6139183, lng: 19.9186647 },
    { lat: 48.6146594, lng: 19.9184519 },
    { lat: 48.6151621, lng: 19.9182704 },
    { lat: 48.6156703, lng: 19.9180941 },
    { lat: 48.6164271, lng: 19.9177943 },
    { lat: 48.6167676, lng: 19.9176182 },
    { lat: 48.6175993, lng: 19.9172827 },
    { lat: 48.6180452, lng: 19.9171163 },
    { lat: 48.6187936, lng: 19.9168344 },
    { lat: 48.6192069, lng: 19.9166733 },
    { lat: 48.619444, lng: 19.9165823 },
    { lat: 48.6195973, lng: 19.9162676 },
    { lat: 48.6197529, lng: 19.9160592 },
    { lat: 48.619919, lng: 19.9159398 },
    { lat: 48.6201891, lng: 19.9158802 },
    { lat: 48.6205025, lng: 19.9159385 },
    { lat: 48.6209068, lng: 19.9158544 },
    { lat: 48.6215501, lng: 19.9153783 },
    { lat: 48.6218423, lng: 19.9150729 },
    { lat: 48.6220312, lng: 19.9149072 },
    { lat: 48.6222482, lng: 19.9149769 },
    { lat: 48.6223225, lng: 19.9150025 },
    { lat: 48.6227255, lng: 19.9151529 },
    { lat: 48.6234069, lng: 19.9154161 },
    { lat: 48.6238271, lng: 19.9155719 },
    { lat: 48.6242977, lng: 19.9159903 },
    { lat: 48.624598, lng: 19.9164002 },
    { lat: 48.6250502, lng: 19.9168086 },
    { lat: 48.6250918, lng: 19.9167828 },
    { lat: 48.6255265, lng: 19.9165139 },
    { lat: 48.6263237, lng: 19.9160534 },
    { lat: 48.6265568, lng: 19.9159187 },
    { lat: 48.626697, lng: 19.9159556 },
    { lat: 48.6267994, lng: 19.9159831 },
    { lat: 48.6274431, lng: 19.9163359 },
    { lat: 48.627948, lng: 19.9167403 },
    { lat: 48.6285476, lng: 19.9174043 },
    { lat: 48.6285464, lng: 19.9173777 },
    { lat: 48.6288171, lng: 19.9172033 },
    { lat: 48.6291784, lng: 19.9167688 },
    { lat: 48.6297104, lng: 19.9161189 },
    { lat: 48.6302051, lng: 19.9154577 },
    { lat: 48.6306282, lng: 19.9147062 },
    { lat: 48.6307801, lng: 19.9141484 },
    { lat: 48.6308062, lng: 19.9141017 },
    { lat: 48.6310716, lng: 19.9136674 },
    { lat: 48.6312082, lng: 19.9133547 },
    { lat: 48.6312698, lng: 19.9132162 },
    { lat: 48.6316719, lng: 19.9124753 },
    { lat: 48.6319031, lng: 19.9119802 },
    { lat: 48.6321032, lng: 19.9117062 },
    { lat: 48.6323495, lng: 19.9115651 },
    { lat: 48.6326119, lng: 19.9114588 },
    { lat: 48.6327874, lng: 19.9111611 },
    { lat: 48.6328139, lng: 19.91088 },
    { lat: 48.6329231, lng: 19.9104082 },
    { lat: 48.6329722, lng: 19.9101032 },
    { lat: 48.632935, lng: 19.909734 },
    { lat: 48.63294, lng: 19.9095875 },
    { lat: 48.6329497, lng: 19.9092322 },
    { lat: 48.6329678, lng: 19.9091307 },
    { lat: 48.6330382, lng: 19.9086958 },
    { lat: 48.6332206, lng: 19.9080858 },
    { lat: 48.6333451, lng: 19.9078888 },
    { lat: 48.6336408, lng: 19.907317 },
    { lat: 48.6336679, lng: 19.9073222 },
    { lat: 48.6339085, lng: 19.906703 },
    { lat: 48.6348748, lng: 19.9065122 },
    { lat: 48.6355348, lng: 19.9057981 },
    { lat: 48.6356597, lng: 19.9054497 },
    { lat: 48.6357708, lng: 19.9053606 },
    { lat: 48.6361118, lng: 19.9053025 },
    { lat: 48.6362885, lng: 19.9050556 },
    { lat: 48.6364394, lng: 19.9042659 },
    { lat: 48.6364037, lng: 19.9038797 },
    { lat: 48.6363505, lng: 19.9037667 },
    { lat: 48.6363256, lng: 19.9037139 },
    { lat: 48.6364137, lng: 19.9034542 },
    { lat: 48.63654, lng: 19.903246 },
    { lat: 48.6367363, lng: 19.9031493 },
    { lat: 48.6369537, lng: 19.9031481 },
    { lat: 48.637077, lng: 19.9031561 },
    { lat: 48.6373717, lng: 19.9031755 },
    { lat: 48.6374086, lng: 19.9030376 },
    { lat: 48.6374962, lng: 19.9027125 },
    { lat: 48.6375788, lng: 19.9025919 },
    { lat: 48.6378549, lng: 19.9025939 },
    { lat: 48.6378742, lng: 19.9025439 },
    { lat: 48.6383374, lng: 19.9016761 },
    { lat: 48.6386487, lng: 19.9011062 },
    { lat: 48.6388444, lng: 19.9009682 },
    { lat: 48.6390466, lng: 19.9000313 },
    { lat: 48.6390707, lng: 19.8999606 },
    { lat: 48.63915, lng: 19.899706 },
    { lat: 48.6392069, lng: 19.8995345 },
    { lat: 48.6395039, lng: 19.8986297 },
    { lat: 48.6395517, lng: 19.8984851 },
    { lat: 48.6398218, lng: 19.8979277 },
    { lat: 48.6401156, lng: 19.8974735 },
    { lat: 48.6402822, lng: 19.8971295 },
    { lat: 48.6402987, lng: 19.8969078 },
    { lat: 48.6403192, lng: 19.8966248 },
    { lat: 48.6400707, lng: 19.8962675 },
    { lat: 48.6401809, lng: 19.8958151 },
    { lat: 48.6403931, lng: 19.8952188 },
    { lat: 48.640939, lng: 19.894626 },
    { lat: 48.6412205, lng: 19.8942537 },
    { lat: 48.6411125, lng: 19.893996 },
    { lat: 48.6409677, lng: 19.8938506 },
    { lat: 48.6407349, lng: 19.8937293 },
    { lat: 48.6409481, lng: 19.8928633 },
    { lat: 48.641044, lng: 19.8924739 },
    { lat: 48.6409947, lng: 19.8923252 },
    { lat: 48.6407697, lng: 19.8916464 },
    { lat: 48.6412845, lng: 19.891701 },
    { lat: 48.6415853, lng: 19.891116 },
    { lat: 48.6421775, lng: 19.8902161 },
    { lat: 48.6421982, lng: 19.8901984 },
    { lat: 48.6423928, lng: 19.8900341 },
    { lat: 48.6427504, lng: 19.8898073 },
    { lat: 48.6430045, lng: 19.8896393 },
    { lat: 48.6431421, lng: 19.8892472 },
    { lat: 48.6430943, lng: 19.8890777 },
    { lat: 48.6433208, lng: 19.8888447 },
    { lat: 48.64356, lng: 19.8886133 },
    { lat: 48.6437525, lng: 19.8881653 },
    { lat: 48.6438975, lng: 19.8875812 },
    { lat: 48.6440424, lng: 19.8870477 },
    { lat: 48.6440888, lng: 19.8866805 },
    { lat: 48.644091, lng: 19.8864463 },
    { lat: 48.6440966, lng: 19.8862348 },
    { lat: 48.6440659, lng: 19.8859934 },
    { lat: 48.6439917, lng: 19.8854175 },
    { lat: 48.6439073, lng: 19.8846268 },
    { lat: 48.6444959, lng: 19.8847998 },
    { lat: 48.6446109, lng: 19.8848566 },
    { lat: 48.6447021, lng: 19.8843778 },
    { lat: 48.6447422, lng: 19.8839208 },
    { lat: 48.6451012, lng: 19.8838091 },
    { lat: 48.6454231, lng: 19.8834596 },
    { lat: 48.645536, lng: 19.8828889 },
    { lat: 48.645647, lng: 19.8829012 },
    { lat: 48.6462997, lng: 19.8830708 },
    { lat: 48.6466449, lng: 19.8832102 },
    { lat: 48.6470681, lng: 19.8831767 },
    { lat: 48.647189, lng: 19.8831054 },
    { lat: 48.6474915, lng: 19.8830139 },
    { lat: 48.6475119, lng: 19.8830155 },
    { lat: 48.6476079, lng: 19.8830257 },
    { lat: 48.6476634, lng: 19.883031 },
    { lat: 48.6477065, lng: 19.8830122 },
    { lat: 48.6478481, lng: 19.8829517 },
    { lat: 48.6477961, lng: 19.8825257 },
    { lat: 48.6476925, lng: 19.8820812 },
    { lat: 48.6476946, lng: 19.8818384 },
    { lat: 48.6477541, lng: 19.8816783 },
    { lat: 48.6478438, lng: 19.8815356 },
    { lat: 48.6480993, lng: 19.8812459 },
    { lat: 48.6482951, lng: 19.8810562 },
    { lat: 48.6483334, lng: 19.8810008 },
    { lat: 48.648255, lng: 19.8806433 },
    { lat: 48.6482376, lng: 19.8797639 },
    { lat: 48.6482363, lng: 19.8797124 },
    { lat: 48.6482226, lng: 19.8790732 },
    { lat: 48.6485787, lng: 19.8783946 },
    { lat: 48.6486493, lng: 19.8780988 },
    { lat: 48.6488656, lng: 19.8774185 },
    { lat: 48.6490065, lng: 19.8768675 },
    { lat: 48.6490475, lng: 19.8764848 },
    { lat: 48.6490555, lng: 19.8764088 },
    { lat: 48.6490669, lng: 19.8763051 },
    { lat: 48.6495196, lng: 19.8758315 },
    { lat: 48.6496598, lng: 19.8754238 },
    { lat: 48.6497751, lng: 19.8750908 },
    { lat: 48.6499001, lng: 19.8748783 },
    { lat: 48.6508175, lng: 19.8746771 },
    { lat: 48.6510644, lng: 19.8745271 },
    { lat: 48.6516039, lng: 19.874184 },
    { lat: 48.6518707, lng: 19.8741515 },
    { lat: 48.6523902, lng: 19.8739179 },
    { lat: 48.6530041, lng: 19.8736013 },
    { lat: 48.6530484, lng: 19.8735827 },
    { lat: 48.6530622, lng: 19.8735924 },
    { lat: 48.6532289, lng: 19.8731684 },
    { lat: 48.6532582, lng: 19.8730936 },
    { lat: 48.6534323, lng: 19.872793 },
    { lat: 48.6535459, lng: 19.8726785 },
    { lat: 48.6536346, lng: 19.8726506 },
    { lat: 48.6542571, lng: 19.8728202 },
    { lat: 48.6546258, lng: 19.872747 },
    { lat: 48.6549266, lng: 19.8725947 },
    { lat: 48.6552474, lng: 19.8724799 },
    { lat: 48.6553592, lng: 19.872434 },
    { lat: 48.6555406, lng: 19.8723582 },
    { lat: 48.6557361, lng: 19.8723212 },
    { lat: 48.655829, lng: 19.8722797 },
    { lat: 48.6560053, lng: 19.8718441 },
    { lat: 48.6559874, lng: 19.8714005 },
    { lat: 48.6558888, lng: 19.8707592 },
    { lat: 48.6558337, lng: 19.8704492 },
    { lat: 48.6558593, lng: 19.8701499 },
    { lat: 48.6559744, lng: 19.8697089 },
    { lat: 48.6559915, lng: 19.8697158 },
    { lat: 48.6561185, lng: 19.8696848 },
    { lat: 48.6563877, lng: 19.8696591 },
    { lat: 48.6566507, lng: 19.8696138 },
    { lat: 48.6572739, lng: 19.8694116 },
    { lat: 48.6577457, lng: 19.8697713 },
    { lat: 48.6580748, lng: 19.8704581 },
    { lat: 48.6588326, lng: 19.8715502 },
    { lat: 48.65892, lng: 19.8718545 },
    { lat: 48.6593385, lng: 19.8725187 },
    { lat: 48.6600321, lng: 19.8738481 },
    { lat: 48.6606333, lng: 19.8745782 },
    { lat: 48.6610515, lng: 19.8742953 },
    { lat: 48.6615656, lng: 19.8746687 },
    { lat: 48.6621659, lng: 19.8747179 },
    { lat: 48.6626618, lng: 19.8746441 },
    { lat: 48.6630936, lng: 19.8740228 },
    { lat: 48.6636848, lng: 19.8733869 },
    { lat: 48.6650259, lng: 19.8739744 },
    { lat: 48.6655714, lng: 19.8727752 },
    { lat: 48.6657623, lng: 19.8721863 },
    { lat: 48.6658187, lng: 19.8717403 },
    { lat: 48.6665353, lng: 19.8714534 },
    { lat: 48.6678887, lng: 19.8697067 },
    { lat: 48.6684163, lng: 19.8678049 },
    { lat: 48.6684219, lng: 19.8675396 },
    { lat: 48.6695938, lng: 19.8656865 },
    { lat: 48.6701653, lng: 19.8651963 },
    { lat: 48.6707696, lng: 19.864894 },
    { lat: 48.6714062, lng: 19.8647663 },
    { lat: 48.6719881, lng: 19.8647556 },
    { lat: 48.672332, lng: 19.8646823 },
    { lat: 48.6728823, lng: 19.864402 },
    { lat: 48.6738544, lng: 19.8646656 },
    { lat: 48.6747048, lng: 19.8648149 },
    { lat: 48.6751981, lng: 19.8654438 },
    { lat: 48.6754886, lng: 19.8655472 },
    { lat: 48.6760287, lng: 19.8659459 },
    { lat: 48.6768905, lng: 19.8670195 },
    { lat: 48.6771387, lng: 19.8672429 },
    { lat: 48.6791367, lng: 19.8671512 },
    { lat: 48.679666, lng: 19.8680083 },
    { lat: 48.6808715, lng: 19.8687479 },
    { lat: 48.6813227, lng: 19.8692819 },
    { lat: 48.6817233, lng: 19.8696405 },
    { lat: 48.6819238, lng: 19.8700041 },
    { lat: 48.6824381, lng: 19.8700192 },
    { lat: 48.6827715, lng: 19.8701249 },
    { lat: 48.684158, lng: 19.8697751 },
    { lat: 48.6849172, lng: 19.869484 },
    { lat: 48.6858818, lng: 19.8688093 },
    { lat: 48.6865745, lng: 19.8685193 },
    { lat: 48.6876383, lng: 19.8673443 },
    { lat: 48.6878089, lng: 19.8657075 },
    { lat: 48.6879194, lng: 19.8651304 },
    { lat: 48.6897954, lng: 19.8611066 },
    { lat: 48.6904493, lng: 19.8593915 },
    { lat: 48.6912553, lng: 19.8576757 },
    { lat: 48.6921191, lng: 19.8574628 },
    { lat: 48.6928994, lng: 19.8574528 },
    { lat: 48.694303, lng: 19.8564544 },
    { lat: 48.6948422, lng: 19.8562081 },
    { lat: 48.6957235, lng: 19.8550421 },
    { lat: 48.6963227, lng: 19.8544333 },
    { lat: 48.6981699, lng: 19.8536946 },
    { lat: 48.6988082, lng: 19.8536817 },
    { lat: 48.6997943, lng: 19.8540063 },
    { lat: 48.7013917, lng: 19.8539765 },
    { lat: 48.7022482, lng: 19.853725 },
    { lat: 48.7029269, lng: 19.8536329 },
    { lat: 48.7033584, lng: 19.853318 },
    { lat: 48.7037005, lng: 19.8527326 },
    { lat: 48.7040022, lng: 19.8518669 },
    { lat: 48.7050642, lng: 19.8497957 },
    { lat: 48.7056003, lng: 19.8486401 },
    { lat: 48.7057925, lng: 19.8475843 },
    { lat: 48.7057737, lng: 19.8470726 },
    { lat: 48.70527, lng: 19.8457611 },
    { lat: 48.7049108, lng: 19.8444344 },
    { lat: 48.7046402, lng: 19.8430771 },
    { lat: 48.7055267, lng: 19.8409346 },
    { lat: 48.7061774, lng: 19.8400636 },
    { lat: 48.7065565, lng: 19.8389251 },
    { lat: 48.7067142, lng: 19.8378613 },
    { lat: 48.7071112, lng: 19.8369578 },
    { lat: 48.7072513, lng: 19.8362898 },
    { lat: 48.7070418, lng: 19.831446 },
    { lat: 48.7071396, lng: 19.8306626 },
    { lat: 48.707216, lng: 19.8289904 },
    { lat: 48.7075507, lng: 19.8280788 },
    { lat: 48.7080501, lng: 19.8270235 },
    { lat: 48.708365, lng: 19.826489 },
    { lat: 48.7087381, lng: 19.8255825 },
    { lat: 48.7088201, lng: 19.8247169 },
    { lat: 48.7093136, lng: 19.822486 },
    { lat: 48.7099305, lng: 19.8206303 },
    { lat: 48.7101048, lng: 19.8199731 },
    { lat: 48.711057, lng: 19.818721 },
    { lat: 48.711574, lng: 19.817038 },
    { lat: 48.712082, lng: 19.815415 },
    { lat: 48.712278, lng: 19.814752 },
    { lat: 48.712571, lng: 19.814015 },
    { lat: 48.712378, lng: 19.813069 },
    { lat: 48.712019, lng: 19.811635 },
    { lat: 48.711673, lng: 19.811704 },
    { lat: 48.711264, lng: 19.811819 },
    { lat: 48.711259, lng: 19.81183 },
    { lat: 48.710765, lng: 19.811948 },
    { lat: 48.710674, lng: 19.811962 },
    { lat: 48.710517, lng: 19.811985 },
    { lat: 48.710343, lng: 19.812014 },
    { lat: 48.710079, lng: 19.812053 },
    { lat: 48.709982, lng: 19.812076 },
    { lat: 48.709837, lng: 19.812141 },
    { lat: 48.709744, lng: 19.812171 },
    { lat: 48.709428, lng: 19.812274 },
    { lat: 48.70912, lng: 19.812485 },
    { lat: 48.708673, lng: 19.812433 },
    { lat: 48.707828, lng: 19.811675 },
    { lat: 48.707832, lng: 19.811664 },
    { lat: 48.707618, lng: 19.811496 },
    { lat: 48.70745, lng: 19.811351 },
    { lat: 48.707328, lng: 19.811205 },
    { lat: 48.707324, lng: 19.811215 },
    { lat: 48.706687, lng: 19.810652 },
    { lat: 48.706397, lng: 19.810229 },
    { lat: 48.705426, lng: 19.809449 },
    { lat: 48.705137, lng: 19.809074 },
    { lat: 48.704623, lng: 19.808057 },
    { lat: 48.704266, lng: 19.807187 },
    { lat: 48.703998, lng: 19.806738 },
    { lat: 48.70354, lng: 19.806498 },
    { lat: 48.70315, lng: 19.806291 },
    { lat: 48.702862, lng: 19.806288 },
    { lat: 48.70236, lng: 19.806289 },
    { lat: 48.70117, lng: 19.806447 },
    { lat: 48.700262, lng: 19.806809 },
    { lat: 48.699075, lng: 19.806201 },
    { lat: 48.698691, lng: 19.806231 },
    { lat: 48.697874, lng: 19.80628 },
    { lat: 48.697226, lng: 19.806368 },
    { lat: 48.696831, lng: 19.806381 },
    { lat: 48.696416, lng: 19.80619 },
    { lat: 48.695953, lng: 19.805658 },
    { lat: 48.695146, lng: 19.80439 },
    { lat: 48.695125, lng: 19.804326 },
    { lat: 48.694788, lng: 19.803276 },
    { lat: 48.694575, lng: 19.802677 },
    { lat: 48.69417, lng: 19.801627 },
    { lat: 48.694023, lng: 19.801265 },
    { lat: 48.693851, lng: 19.800562 },
    { lat: 48.693711, lng: 19.799666 },
    { lat: 48.693575, lng: 19.79829 },
    { lat: 48.693569, lng: 19.79767 },
    { lat: 48.693458, lng: 19.797181 },
    { lat: 48.693332, lng: 19.794816 },
    { lat: 48.693071, lng: 19.793765 },
    { lat: 48.692603, lng: 19.792347 },
    { lat: 48.692471, lng: 19.792089 },
    { lat: 48.692182, lng: 19.791556 },
    { lat: 48.691975, lng: 19.790988 },
    { lat: 48.691632, lng: 19.790397 },
    { lat: 48.691444, lng: 19.790013 },
    { lat: 48.691186, lng: 19.789469 },
    { lat: 48.690844, lng: 19.788852 },
    { lat: 48.690765, lng: 19.788306 },
    { lat: 48.690583, lng: 19.78788 },
    { lat: 48.690541, lng: 19.787737 },
    { lat: 48.690448, lng: 19.787225 },
    { lat: 48.690695, lng: 19.785929 },
    { lat: 48.690704, lng: 19.785879 },
    { lat: 48.690862, lng: 19.785438 },
    { lat: 48.690946, lng: 19.785133 },
    { lat: 48.691112, lng: 19.784555 },
    { lat: 48.691387, lng: 19.783751 },
    { lat: 48.691538, lng: 19.783447 },
    { lat: 48.691845, lng: 19.782888 },
    { lat: 48.692075, lng: 19.781757 },
    { lat: 48.692347, lng: 19.780358 },
    { lat: 48.692361, lng: 19.779725 },
    { lat: 48.69222, lng: 19.778813 },
    { lat: 48.691949, lng: 19.777903 },
    { lat: 48.692052, lng: 19.776785 },
    { lat: 48.692125, lng: 19.775547 },
    { lat: 48.692046, lng: 19.774849 },
    { lat: 48.691974, lng: 19.774478 },
    { lat: 48.691897, lng: 19.774058 },
    { lat: 48.691795, lng: 19.773832 },
    { lat: 48.691463, lng: 19.773273 },
    { lat: 48.690536, lng: 19.771725 },
    { lat: 48.689912, lng: 19.770849 },
    { lat: 48.689122, lng: 19.769719 },
    { lat: 48.688199, lng: 19.767777 },
    { lat: 48.687943, lng: 19.76682 },
    { lat: 48.68769, lng: 19.766445 },
    { lat: 48.687369, lng: 19.766114 },
    { lat: 48.686917, lng: 19.765576 },
    { lat: 48.686231, lng: 19.765106 },
    { lat: 48.685435, lng: 19.764228 },
    { lat: 48.68507, lng: 19.764476 },
    { lat: 48.684843, lng: 19.764592 },
    { lat: 48.684594, lng: 19.764719 },
    { lat: 48.683641, lng: 19.765211 },
    { lat: 48.682173, lng: 19.765698 },
    { lat: 48.681831, lng: 19.765818 },
    { lat: 48.679268, lng: 19.766723 },
    { lat: 48.678306, lng: 19.765541 },
    { lat: 48.677437, lng: 19.764554 },
    { lat: 48.676993, lng: 19.763058 },
    { lat: 48.676974, lng: 19.761675 },
    { lat: 48.676943, lng: 19.760521 },
    { lat: 48.67662, lng: 19.759372 },
    { lat: 48.676483, lng: 19.757943 },
    { lat: 48.67595, lng: 19.755824 },
    { lat: 48.67574, lng: 19.754707 },
    { lat: 48.675699, lng: 19.7545 },
    { lat: 48.675694, lng: 19.754469 },
    { lat: 48.675583, lng: 19.753882 },
    { lat: 48.675497, lng: 19.753413 },
    { lat: 48.67489, lng: 19.7522 },
    { lat: 48.674712, lng: 19.751831 },
    { lat: 48.674052, lng: 19.750456 },
    { lat: 48.673472, lng: 19.749211 },
    { lat: 48.673096, lng: 19.748047 },
    { lat: 48.672993, lng: 19.74755 },
    { lat: 48.672979, lng: 19.747156 },
    { lat: 48.67309, lng: 19.746714 },
    { lat: 48.673073, lng: 19.745696 },
    { lat: 48.673071, lng: 19.74555 },
    { lat: 48.673067, lng: 19.745297 },
    { lat: 48.672999, lng: 19.744372 },
    { lat: 48.672708, lng: 19.742467 },
    { lat: 48.672547, lng: 19.74148 },
    { lat: 48.672533, lng: 19.741253 },
    { lat: 48.672196, lng: 19.739476 },
    { lat: 48.671975, lng: 19.738574 },
    { lat: 48.671646, lng: 19.737399 },
    { lat: 48.671576, lng: 19.73715 },
    { lat: 48.671177, lng: 19.735624 },
    { lat: 48.670686, lng: 19.733819 },
    { lat: 48.670029, lng: 19.732821 },
    { lat: 48.669276, lng: 19.732603 },
    { lat: 48.668906, lng: 19.732493 },
    { lat: 48.668594, lng: 19.732401 },
    { lat: 48.668093, lng: 19.732249 },
    { lat: 48.667973, lng: 19.732213 },
    { lat: 48.667489, lng: 19.732186 },
    { lat: 48.667122, lng: 19.73216 },
    { lat: 48.66707, lng: 19.732157 },
    { lat: 48.666479, lng: 19.732113 },
    { lat: 48.666193, lng: 19.732095 },
    { lat: 48.665291, lng: 19.732024 },
    { lat: 48.665153, lng: 19.731838 },
    { lat: 48.664404, lng: 19.730857 },
    { lat: 48.664083, lng: 19.730485 },
    { lat: 48.663819, lng: 19.730179 },
    { lat: 48.663548, lng: 19.729867 },
    { lat: 48.663279, lng: 19.729553 },
    { lat: 48.662122, lng: 19.728458 },
    { lat: 48.66135, lng: 19.728223 },
    { lat: 48.660534, lng: 19.728005 },
    { lat: 48.660511, lng: 19.728014 },
    { lat: 48.66051, lng: 19.728092 },
    { lat: 48.660416, lng: 19.728249 },
    { lat: 48.659672, lng: 19.729531 },
    { lat: 48.659307, lng: 19.730263 },
    { lat: 48.659187, lng: 19.730503 },
    { lat: 48.658914, lng: 19.731572 },
    { lat: 48.658596, lng: 19.733009 },
    { lat: 48.658369, lng: 19.733534 },
    { lat: 48.657618, lng: 19.734367 },
    { lat: 48.656939, lng: 19.735119 },
    { lat: 48.656813, lng: 19.735258 },
    { lat: 48.656765, lng: 19.735312 },
    { lat: 48.65671, lng: 19.735408 },
    { lat: 48.656686, lng: 19.735448 },
    { lat: 48.656565, lng: 19.735656 },
    { lat: 48.655943, lng: 19.736725 },
    { lat: 48.655864, lng: 19.736859 },
    { lat: 48.655369, lng: 19.737714 },
    { lat: 48.655305, lng: 19.737823 },
    { lat: 48.655124, lng: 19.738235 },
    { lat: 48.655076, lng: 19.738509 },
    { lat: 48.655034, lng: 19.738754 },
    { lat: 48.654933, lng: 19.739338 },
    { lat: 48.654894, lng: 19.739771 },
    { lat: 48.654871, lng: 19.740045 },
    { lat: 48.654702, lng: 19.740363 },
    { lat: 48.654406, lng: 19.740915 },
    { lat: 48.654265, lng: 19.741109 },
    { lat: 48.654026, lng: 19.741257 },
    { lat: 48.653775, lng: 19.741469 },
    { lat: 48.653478, lng: 19.741689 },
    { lat: 48.653333, lng: 19.741784 },
    { lat: 48.653234, lng: 19.741804 },
    { lat: 48.653217, lng: 19.741808 },
    { lat: 48.652735, lng: 19.741865 },
    { lat: 48.652511, lng: 19.741987 },
    { lat: 48.652431, lng: 19.742033 },
    { lat: 48.652142, lng: 19.742199 },
    { lat: 48.652149, lng: 19.742226 },
    { lat: 48.651971, lng: 19.742409 },
    { lat: 48.651701, lng: 19.742668 },
    { lat: 48.651493, lng: 19.742936 },
    { lat: 48.651351, lng: 19.743194 },
    { lat: 48.651234, lng: 19.743335 },
    { lat: 48.651158, lng: 19.743417 },
    { lat: 48.650909, lng: 19.743703 },
    { lat: 48.650644, lng: 19.744147 },
    { lat: 48.650491, lng: 19.744598 },
    { lat: 48.650446, lng: 19.744725 },
    { lat: 48.650208, lng: 19.744999 },
    { lat: 48.649923, lng: 19.745495 },
    { lat: 48.649806, lng: 19.745657 },
    { lat: 48.649723, lng: 19.745877 },
    { lat: 48.649588, lng: 19.746448 },
    { lat: 48.649543, lng: 19.74685 },
    { lat: 48.649448, lng: 19.747127 },
    { lat: 48.649314, lng: 19.747482 },
    { lat: 48.64885, lng: 19.748281 },
    { lat: 48.648794, lng: 19.748384 },
    { lat: 48.648777, lng: 19.748368 },
    { lat: 48.648181, lng: 19.749437 },
    { lat: 48.647498, lng: 19.750706 },
    { lat: 48.646784, lng: 19.751929 },
    { lat: 48.646667, lng: 19.752154 },
    { lat: 48.646546, lng: 19.752382 },
    { lat: 48.646499, lng: 19.752468 },
    { lat: 48.646391, lng: 19.752675 },
    { lat: 48.646365, lng: 19.752723 },
    { lat: 48.646081, lng: 19.753255 },
    { lat: 48.645554, lng: 19.75419 },
    { lat: 48.644418, lng: 19.755382 },
    { lat: 48.643821, lng: 19.756237 },
    { lat: 48.643119, lng: 19.757265 },
    { lat: 48.642804, lng: 19.757777 },
    { lat: 48.642701, lng: 19.757944 },
    { lat: 48.642071, lng: 19.758961 },
    { lat: 48.641712, lng: 19.75942 },
    { lat: 48.641049, lng: 19.760529 },
    { lat: 48.640959, lng: 19.760782 },
    { lat: 48.640821, lng: 19.76132 },
    { lat: 48.640504, lng: 19.762383 },
    { lat: 48.640106, lng: 19.763351 },
    { lat: 48.639701, lng: 19.764533 },
    { lat: 48.639414, lng: 19.765492 },
    { lat: 48.639431, lng: 19.766655 },
    { lat: 48.639588, lng: 19.768361 },
    { lat: 48.639611, lng: 19.768612 },
    { lat: 48.639593, lng: 19.768615 },
    { lat: 48.6396, lng: 19.769396 },
    { lat: 48.639586, lng: 19.769617 },
    { lat: 48.639569, lng: 19.769821 },
    { lat: 48.639627, lng: 19.770457 },
    { lat: 48.639617, lng: 19.770974 },
    { lat: 48.6396, lng: 19.771272 },
    { lat: 48.639455, lng: 19.771856 },
    { lat: 48.63938, lng: 19.773032 },
    { lat: 48.639328, lng: 19.773483 },
    { lat: 48.639373, lng: 19.773861 },
    { lat: 48.639379, lng: 19.774016 },
    { lat: 48.639392, lng: 19.774446 },
    { lat: 48.639218, lng: 19.775359 },
    { lat: 48.639037, lng: 19.776092 },
    { lat: 48.638975, lng: 19.776326 },
    { lat: 48.638709, lng: 19.777078 },
    { lat: 48.638625, lng: 19.777409 },
    { lat: 48.638441, lng: 19.778135 },
    { lat: 48.638154, lng: 19.778786 },
    { lat: 48.637885, lng: 19.779445 },
    { lat: 48.637509, lng: 19.780022 },
    { lat: 48.63729, lng: 19.780334 },
    { lat: 48.637106, lng: 19.780882 },
    { lat: 48.637063, lng: 19.781143 },
    { lat: 48.636777, lng: 19.781697 },
    { lat: 48.636467, lng: 19.78234 },
    { lat: 48.636296, lng: 19.783039 },
    { lat: 48.636189, lng: 19.783402 },
    { lat: 48.635981, lng: 19.783819 },
    { lat: 48.635867, lng: 19.785413 },
    { lat: 48.635943, lng: 19.786414 },
    { lat: 48.635172, lng: 19.78737 },
    { lat: 48.634829, lng: 19.787677 },
    { lat: 48.634433, lng: 19.788174 },
    { lat: 48.634142, lng: 19.788354 },
    { lat: 48.633742, lng: 19.788351 },
    { lat: 48.633287, lng: 19.788337 },
    { lat: 48.632884, lng: 19.788404 },
    { lat: 48.632071, lng: 19.788297 },
    { lat: 48.631615, lng: 19.78837 },
    { lat: 48.631308, lng: 19.788558 },
    { lat: 48.631284, lng: 19.788539 },
    { lat: 48.631253, lng: 19.788544 },
    { lat: 48.63121, lng: 19.788549 },
    { lat: 48.630894, lng: 19.78856 },
    { lat: 48.630607, lng: 19.788628 },
    { lat: 48.630113, lng: 19.788874 },
    { lat: 48.629886, lng: 19.788906 },
    { lat: 48.629468, lng: 19.788992 },
    { lat: 48.629361, lng: 19.789049 },
    { lat: 48.629334, lng: 19.789065 },
    { lat: 48.628177, lng: 19.789648 },
    { lat: 48.628035, lng: 19.789677 },
    { lat: 48.627326, lng: 19.790182 },
    { lat: 48.626742, lng: 19.790567 },
    { lat: 48.62661, lng: 19.790599 },
    { lat: 48.626166, lng: 19.791041 },
    { lat: 48.626016, lng: 19.791622 },
    { lat: 48.625865, lng: 19.792101 },
    { lat: 48.625839, lng: 19.792266 },
    { lat: 48.625782, lng: 19.792901 },
    { lat: 48.62575, lng: 19.794079 },
    { lat: 48.625767, lng: 19.794386 },
    { lat: 48.62589, lng: 19.795734 },
    { lat: 48.625943, lng: 19.79716 },
    { lat: 48.626004, lng: 19.797541 },
    { lat: 48.626036, lng: 19.798282 },
    { lat: 48.626019, lng: 19.798938 },
    { lat: 48.626004, lng: 19.799269 },
    { lat: 48.62581, lng: 19.800118 },
    { lat: 48.62573, lng: 19.800492 },
    { lat: 48.625544, lng: 19.801506 },
    { lat: 48.625385, lng: 19.802728 },
    { lat: 48.624993, lng: 19.80327 },
    { lat: 48.624552, lng: 19.803793 },
    { lat: 48.624303, lng: 19.804217 },
    { lat: 48.624049, lng: 19.804648 },
    { lat: 48.623792, lng: 19.805499 },
    { lat: 48.623475, lng: 19.806875 },
    { lat: 48.623191, lng: 19.808836 },
    { lat: 48.623115, lng: 19.809376 },
    { lat: 48.62293, lng: 19.809399 },
    { lat: 48.622766, lng: 19.809433 },
    { lat: 48.622124, lng: 19.809567 },
    { lat: 48.621499, lng: 19.809521 },
    { lat: 48.621116, lng: 19.809806 },
    { lat: 48.620677, lng: 19.809891 },
    { lat: 48.620002, lng: 19.810026 },
    { lat: 48.619438, lng: 19.81012 },
    { lat: 48.619211, lng: 19.810313 },
    { lat: 48.618941, lng: 19.811322 },
    { lat: 48.618747, lng: 19.8121 },
    { lat: 48.618631, lng: 19.81256 },
    { lat: 48.618624, lng: 19.812585 },
    { lat: 48.618498, lng: 19.813081 },
    { lat: 48.618361, lng: 19.813289 },
    { lat: 48.617943, lng: 19.814447 },
    { lat: 48.617722, lng: 19.815101 },
    { lat: 48.617563, lng: 19.8158 },
    { lat: 48.617094, lng: 19.81775 },
    { lat: 48.61646, lng: 19.818921 },
    { lat: 48.61625, lng: 19.819588 },
    { lat: 48.616187, lng: 19.820637 },
    { lat: 48.616148, lng: 19.821336 },
    { lat: 48.61601, lng: 19.822943 },
    { lat: 48.615571, lng: 19.823743 },
    { lat: 48.614954, lng: 19.82425 },
    { lat: 48.614473, lng: 19.824949 },
    { lat: 48.614278, lng: 19.825281 },
    { lat: 48.613997, lng: 19.825788 },
    { lat: 48.61362, lng: 19.82626 },
    { lat: 48.613252, lng: 19.826629 },
    { lat: 48.613001, lng: 19.826732 },
    { lat: 48.612362, lng: 19.826855 },
    { lat: 48.612099, lng: 19.826927 },
    { lat: 48.611428, lng: 19.827122 },
    { lat: 48.611175, lng: 19.827206 },
    { lat: 48.610456, lng: 19.827442 },
    { lat: 48.609425, lng: 19.827707 },
    { lat: 48.608799, lng: 19.827956 },
    { lat: 48.60877, lng: 19.827967 },
    { lat: 48.607793, lng: 19.828458 },
    { lat: 48.606902, lng: 19.829217 },
    { lat: 48.606725, lng: 19.829388 },
    { lat: 48.606355, lng: 19.829685 },
    { lat: 48.605689, lng: 19.830234 },
    { lat: 48.60546, lng: 19.830427 },
    { lat: 48.604909, lng: 19.831001 },
    { lat: 48.604521, lng: 19.831501 },
    { lat: 48.603896, lng: 19.832086 },
    { lat: 48.603688, lng: 19.832209 },
    { lat: 48.603534, lng: 19.832722 },
    { lat: 48.603435, lng: 19.832893 },
    { lat: 48.60337, lng: 19.833006 },
    { lat: 48.603273, lng: 19.833176 },
    { lat: 48.603016, lng: 19.833628 },
    { lat: 48.602473, lng: 19.833773 },
    { lat: 48.602298, lng: 19.834488 },
    { lat: 48.602202, lng: 19.834918 },
    { lat: 48.601937, lng: 19.836125 },
    { lat: 48.60174, lng: 19.836915 },
    { lat: 48.601537, lng: 19.837723 },
    { lat: 48.600983, lng: 19.83867 },
    { lat: 48.600376, lng: 19.839748 },
    { lat: 48.600327, lng: 19.839832 },
    { lat: 48.60029, lng: 19.839899 },
    { lat: 48.598961, lng: 19.839636 },
    { lat: 48.598569, lng: 19.839424 },
    { lat: 48.597693, lng: 19.840281 },
    { lat: 48.596705, lng: 19.841263 },
    { lat: 48.596679, lng: 19.841287 },
    { lat: 48.59642, lng: 19.841544 },
    { lat: 48.595691, lng: 19.842205 },
    { lat: 48.595064, lng: 19.844543 },
    { lat: 48.594763, lng: 19.845382 },
    { lat: 48.594815, lng: 19.846022 },
    { lat: 48.594942, lng: 19.846766 },
    { lat: 48.594953, lng: 19.846824 },
    { lat: 48.595086, lng: 19.848448 },
    { lat: 48.595172, lng: 19.849473 },
    { lat: 48.595312, lng: 19.850599 },
    { lat: 48.595444, lng: 19.851644 },
    { lat: 48.595394, lng: 19.853249 },
    { lat: 48.59535, lng: 19.854229 },
    { lat: 48.594988, lng: 19.855373 },
    { lat: 48.594816, lng: 19.857534 },
    { lat: 48.594674, lng: 19.858669 },
    { lat: 48.594575, lng: 19.859641 },
    { lat: 48.594557, lng: 19.859825 },
    { lat: 48.594498, lng: 19.859986 },
    { lat: 48.594458, lng: 19.860093 },
    { lat: 48.594415, lng: 19.860209 },
    { lat: 48.594265, lng: 19.860567 },
    { lat: 48.594288, lng: 19.860606 },
    { lat: 48.593979, lng: 19.861221 },
    { lat: 48.593702, lng: 19.861896 },
    { lat: 48.593577, lng: 19.862081 },
    { lat: 48.593229, lng: 19.862614 },
    { lat: 48.593173, lng: 19.862754 },
    { lat: 48.593054, lng: 19.863329 },
    { lat: 48.59301, lng: 19.863415 },
    { lat: 48.592933, lng: 19.863565 },
    { lat: 48.592139, lng: 19.864421 },
    { lat: 48.591379, lng: 19.864997 },
    { lat: 48.590843, lng: 19.866031 },
    { lat: 48.590504, lng: 19.866598 },
    { lat: 48.590129, lng: 19.867212 },
    { lat: 48.589683, lng: 19.868062 },
    { lat: 48.589533, lng: 19.868651 },
    { lat: 48.589351, lng: 19.869064 },
    { lat: 48.589033, lng: 19.869661 },
    { lat: 48.588753, lng: 19.870285 },
    { lat: 48.588519, lng: 19.870614 },
    { lat: 48.588262, lng: 19.870827 },
    { lat: 48.587782, lng: 19.87121 },
    { lat: 48.587147, lng: 19.871852 },
    { lat: 48.586671, lng: 19.872314 },
    { lat: 48.586276, lng: 19.872487 },
    { lat: 48.585919, lng: 19.872742 },
    { lat: 48.585523, lng: 19.87328 },
    { lat: 48.58487, lng: 19.873851 },
    { lat: 48.584616, lng: 19.873983 },
    { lat: 48.584414, lng: 19.874166 },
    { lat: 48.584265, lng: 19.874303 },
    { lat: 48.583942, lng: 19.874403 },
    { lat: 48.583867, lng: 19.874406 },
    { lat: 48.583141, lng: 19.874879 },
    { lat: 48.582787, lng: 19.875165 },
    { lat: 48.582563, lng: 19.875385 },
    { lat: 48.581927, lng: 19.876863 },
    { lat: 48.581524, lng: 19.878421 },
    { lat: 48.581253, lng: 19.879026 },
    { lat: 48.581072, lng: 19.879417 },
    { lat: 48.580878, lng: 19.879853 },
    { lat: 48.580528, lng: 19.880335 },
    { lat: 48.58029, lng: 19.880649 },
    { lat: 48.579719, lng: 19.881428 },
    { lat: 48.578811, lng: 19.881683 },
    { lat: 48.578509, lng: 19.881696 },
    { lat: 48.577458, lng: 19.881748 },
    { lat: 48.577141, lng: 19.881579 },
    { lat: 48.57676, lng: 19.88134 },
    { lat: 48.576358, lng: 19.881165 },
    { lat: 48.576142, lng: 19.881186 },
    { lat: 48.575812, lng: 19.881301 },
    { lat: 48.57576, lng: 19.881336 },
    { lat: 48.575589, lng: 19.881452 },
    { lat: 48.57471, lng: 19.882068 },
    { lat: 48.573627, lng: 19.882315 },
    { lat: 48.573413, lng: 19.882345 },
    { lat: 48.573221, lng: 19.882417 },
    { lat: 48.57311, lng: 19.882474 },
    { lat: 48.573046, lng: 19.882507 },
  ],
  Bátka: [
    { lat: 48.3889925, lng: 20.2001961 },
    { lat: 48.3897943, lng: 20.1989907 },
    { lat: 48.3893344, lng: 20.1984212 },
    { lat: 48.3882428, lng: 20.1962137 },
    { lat: 48.3879565, lng: 20.1951745 },
    { lat: 48.3875161, lng: 20.1942564 },
    { lat: 48.3871953, lng: 20.1932853 },
    { lat: 48.3866392, lng: 20.1922031 },
    { lat: 48.3860322, lng: 20.1913676 },
    { lat: 48.3856166, lng: 20.1906443 },
    { lat: 48.3844042, lng: 20.1862321 },
    { lat: 48.3839549, lng: 20.184759 },
    { lat: 48.3836253, lng: 20.1834617 },
    { lat: 48.3834585, lng: 20.1821798 },
    { lat: 48.3823678, lng: 20.1794412 },
    { lat: 48.3832866, lng: 20.1789325 },
    { lat: 48.3851911, lng: 20.1776818 },
    { lat: 48.3855593, lng: 20.177314 },
    { lat: 48.38593, lng: 20.1766831 },
    { lat: 48.3859241, lng: 20.1756755 },
    { lat: 48.3865626, lng: 20.1722863 },
    { lat: 48.3866, lng: 20.1709272 },
    { lat: 48.3865772, lng: 20.1695639 },
    { lat: 48.3866407, lng: 20.1692378 },
    { lat: 48.3864342, lng: 20.1688446 },
    { lat: 48.3864966, lng: 20.1673882 },
    { lat: 48.3865274, lng: 20.1660062 },
    { lat: 48.3862691, lng: 20.1658297 },
    { lat: 48.3843969, lng: 20.1621417 },
    { lat: 48.3843346, lng: 20.1622172 },
    { lat: 48.3841054, lng: 20.1612312 },
    { lat: 48.3842591, lng: 20.1603323 },
    { lat: 48.3839212, lng: 20.159638 },
    { lat: 48.3836272, lng: 20.1585842 },
    { lat: 48.3834303, lng: 20.155076 },
    { lat: 48.3837637, lng: 20.1534463 },
    { lat: 48.3836615, lng: 20.1527626 },
    { lat: 48.3838429, lng: 20.1520483 },
    { lat: 48.3839525, lng: 20.149571 },
    { lat: 48.384065, lng: 20.1490139 },
    { lat: 48.3842645, lng: 20.1470531 },
    { lat: 48.3841595, lng: 20.1462215 },
    { lat: 48.3840023, lng: 20.1456839 },
    { lat: 48.3839745, lng: 20.1452514 },
    { lat: 48.3837203, lng: 20.1448708 },
    { lat: 48.3832249, lng: 20.1443268 },
    { lat: 48.3828003, lng: 20.1432518 },
    { lat: 48.38252, lng: 20.1419315 },
    { lat: 48.382609, lng: 20.1418727 },
    { lat: 48.3826979, lng: 20.1397817 },
    { lat: 48.3824721, lng: 20.1382764 },
    { lat: 48.3825366, lng: 20.1367122 },
    { lat: 48.3823651, lng: 20.1362128 },
    { lat: 48.3810633, lng: 20.1343978 },
    { lat: 48.3805839, lng: 20.1325701 },
    { lat: 48.3804525, lng: 20.1316656 },
    { lat: 48.3798308, lng: 20.1305428 },
    { lat: 48.3783305, lng: 20.1283442 },
    { lat: 48.3771891, lng: 20.1270157 },
    { lat: 48.3760179, lng: 20.1258626 },
    { lat: 48.375679, lng: 20.125157 },
    { lat: 48.3752286, lng: 20.1240326 },
    { lat: 48.3750221, lng: 20.1234175 },
    { lat: 48.3748186, lng: 20.1222775 },
    { lat: 48.3746885, lng: 20.121959 },
    { lat: 48.3741403, lng: 20.1212256 },
    { lat: 48.3737786, lng: 20.1208375 },
    { lat: 48.3732644, lng: 20.1213466 },
    { lat: 48.3725467, lng: 20.1216985 },
    { lat: 48.3721407, lng: 20.1220442 },
    { lat: 48.3712732, lng: 20.1223936 },
    { lat: 48.3707828, lng: 20.1224407 },
    { lat: 48.3692339, lng: 20.1218346 },
    { lat: 48.3674024, lng: 20.1222177 },
    { lat: 48.3667558, lng: 20.1224631 },
    { lat: 48.3659932, lng: 20.1228718 },
    { lat: 48.3652482, lng: 20.1230005 },
    { lat: 48.3643723, lng: 20.1235883 },
    { lat: 48.3638679, lng: 20.1246348 },
    { lat: 48.36362, lng: 20.1253179 },
    { lat: 48.3633987, lng: 20.1265985 },
    { lat: 48.3631065, lng: 20.1276863 },
    { lat: 48.3629371, lng: 20.1286909 },
    { lat: 48.3627543, lng: 20.1292308 },
    { lat: 48.3626572, lng: 20.1297509 },
    { lat: 48.3626521, lng: 20.1303117 },
    { lat: 48.36303, lng: 20.1308769 },
    { lat: 48.3632625, lng: 20.1316031 },
    { lat: 48.3625277, lng: 20.1338753 },
    { lat: 48.3625223, lng: 20.1342392 },
    { lat: 48.3622244, lng: 20.1365395 },
    { lat: 48.3619772, lng: 20.1373496 },
    { lat: 48.3614871, lng: 20.1383124 },
    { lat: 48.3612653, lng: 20.1386998 },
    { lat: 48.3610902, lng: 20.1387984 },
    { lat: 48.3605396, lng: 20.1397356 },
    { lat: 48.3601914, lng: 20.1402312 },
    { lat: 48.3596765, lng: 20.1415207 },
    { lat: 48.3591249, lng: 20.1426073 },
    { lat: 48.3587294, lng: 20.1427928 },
    { lat: 48.3584691, lng: 20.1428175 },
    { lat: 48.3572557, lng: 20.143557 },
    { lat: 48.3564935, lng: 20.1438679 },
    { lat: 48.3561409, lng: 20.1443072 },
    { lat: 48.3546798, lng: 20.1454607 },
    { lat: 48.3541715, lng: 20.1457578 },
    { lat: 48.3528907, lng: 20.1462756 },
    { lat: 48.3525414, lng: 20.1465195 },
    { lat: 48.3517547, lng: 20.1476406 },
    { lat: 48.3512843, lng: 20.149028 },
    { lat: 48.3510957, lng: 20.1491555 },
    { lat: 48.3507006, lng: 20.1492697 },
    { lat: 48.3502446, lng: 20.1491674 },
    { lat: 48.3498846, lng: 20.1493449 },
    { lat: 48.3495093, lng: 20.1496166 },
    { lat: 48.3489981, lng: 20.1501713 },
    { lat: 48.3486303, lng: 20.1507596 },
    { lat: 48.3481264, lng: 20.1519633 },
    { lat: 48.347497, lng: 20.1527983 },
    { lat: 48.3471988, lng: 20.1536057 },
    { lat: 48.3471808, lng: 20.1536916 },
    { lat: 48.3471023, lng: 20.1541396 },
    { lat: 48.3477731, lng: 20.1543773 },
    { lat: 48.3484661, lng: 20.1547139 },
    { lat: 48.3494247, lng: 20.1553239 },
    { lat: 48.3502415, lng: 20.1556657 },
    { lat: 48.3514747, lng: 20.1557973 },
    { lat: 48.352478, lng: 20.1563987 },
    { lat: 48.3532226, lng: 20.157033 },
    { lat: 48.3539827, lng: 20.1575866 },
    { lat: 48.355145, lng: 20.1586628 },
    { lat: 48.355934, lng: 20.1600871 },
    { lat: 48.3576566, lng: 20.1615332 },
    { lat: 48.3580676, lng: 20.1622262 },
    { lat: 48.3578952, lng: 20.1636111 },
    { lat: 48.3589592, lng: 20.1641785 },
    { lat: 48.3593341, lng: 20.1646173 },
    { lat: 48.3600989, lng: 20.1651745 },
    { lat: 48.3613317, lng: 20.1664897 },
    { lat: 48.3614607, lng: 20.1668119 },
    { lat: 48.3617531, lng: 20.1678789 },
    { lat: 48.3616415, lng: 20.1680718 },
    { lat: 48.3617629, lng: 20.1684514 },
    { lat: 48.3620324, lng: 20.1698165 },
    { lat: 48.3623105, lng: 20.170509 },
    { lat: 48.3640016, lng: 20.1737135 },
    { lat: 48.3638395, lng: 20.1739054 },
    { lat: 48.3645842, lng: 20.1754143 },
    { lat: 48.3652713, lng: 20.1762994 },
    { lat: 48.3650681, lng: 20.176648 },
    { lat: 48.3674395, lng: 20.1797774 },
    { lat: 48.3683616, lng: 20.1809366 },
    { lat: 48.3684598, lng: 20.1809938 },
    { lat: 48.3684793, lng: 20.1811139 },
    { lat: 48.368554, lng: 20.1811815 },
    { lat: 48.3685939, lng: 20.1814145 },
    { lat: 48.3698683, lng: 20.1842911 },
    { lat: 48.3706242, lng: 20.1858247 },
    { lat: 48.3712837, lng: 20.1867884 },
    { lat: 48.3715432, lng: 20.1865741 },
    { lat: 48.3715684, lng: 20.1866958 },
    { lat: 48.3716711, lng: 20.1865341 },
    { lat: 48.3716965, lng: 20.1863841 },
    { lat: 48.3718648, lng: 20.1863677 },
    { lat: 48.3718694, lng: 20.186843 },
    { lat: 48.37191, lng: 20.1869555 },
    { lat: 48.3722963, lng: 20.1868704 },
    { lat: 48.3725844, lng: 20.1864194 },
    { lat: 48.3726575, lng: 20.1860858 },
    { lat: 48.3729573, lng: 20.1859189 },
    { lat: 48.37287, lng: 20.1851018 },
    { lat: 48.3729119, lng: 20.1848535 },
    { lat: 48.3726856, lng: 20.1848564 },
    { lat: 48.3726777, lng: 20.1850222 },
    { lat: 48.3724497, lng: 20.1852325 },
    { lat: 48.3723282, lng: 20.1851813 },
    { lat: 48.372371, lng: 20.1849028 },
    { lat: 48.3725072, lng: 20.184816 },
    { lat: 48.3725465, lng: 20.1844809 },
    { lat: 48.3723499, lng: 20.1844694 },
    { lat: 48.3722681, lng: 20.184367 },
    { lat: 48.3721787, lng: 20.1844659 },
    { lat: 48.3720889, lng: 20.1844755 },
    { lat: 48.3718409, lng: 20.1843011 },
    { lat: 48.3718687, lng: 20.1841261 },
    { lat: 48.3722233, lng: 20.1839234 },
    { lat: 48.3722763, lng: 20.1837874 },
    { lat: 48.3721303, lng: 20.1837427 },
    { lat: 48.3720799, lng: 20.1835542 },
    { lat: 48.3721783, lng: 20.1833153 },
    { lat: 48.3721282, lng: 20.1831371 },
    { lat: 48.3722304, lng: 20.1830155 },
    { lat: 48.3723452, lng: 20.1831279 },
    { lat: 48.3724207, lng: 20.1831267 },
    { lat: 48.3724752, lng: 20.182889 },
    { lat: 48.3725515, lng: 20.1828551 },
    { lat: 48.372689, lng: 20.1829396 },
    { lat: 48.3729635, lng: 20.1829564 },
    { lat: 48.373129, lng: 20.1827617 },
    { lat: 48.3732449, lng: 20.1824224 },
    { lat: 48.3732991, lng: 20.1820363 },
    { lat: 48.373399, lng: 20.1819216 },
    { lat: 48.3734277, lng: 20.1815697 },
    { lat: 48.3735891, lng: 20.1814689 },
    { lat: 48.373545, lng: 20.1809929 },
    { lat: 48.3734364, lng: 20.1807304 },
    { lat: 48.3735453, lng: 20.1806684 },
    { lat: 48.3738974, lng: 20.1807659 },
    { lat: 48.3739955, lng: 20.1808214 },
    { lat: 48.374039, lng: 20.1810339 },
    { lat: 48.3740805, lng: 20.1810627 },
    { lat: 48.3741983, lng: 20.1810225 },
    { lat: 48.3744247, lng: 20.1810749 },
    { lat: 48.3745368, lng: 20.1812035 },
    { lat: 48.3747413, lng: 20.1819114 },
    { lat: 48.3749178, lng: 20.1815439 },
    { lat: 48.3750094, lng: 20.1814162 },
    { lat: 48.3751034, lng: 20.1813984 },
    { lat: 48.3751415, lng: 20.1814775 },
    { lat: 48.3751579, lng: 20.1818246 },
    { lat: 48.3752823, lng: 20.181866 },
    { lat: 48.3753343, lng: 20.181756 },
    { lat: 48.3754638, lng: 20.1817021 },
    { lat: 48.3755266, lng: 20.1817502 },
    { lat: 48.3755048, lng: 20.1821326 },
    { lat: 48.3755575, lng: 20.1821519 },
    { lat: 48.3757638, lng: 20.1819368 },
    { lat: 48.375665, lng: 20.1817461 },
    { lat: 48.375639, lng: 20.1814657 },
    { lat: 48.3758557, lng: 20.1814484 },
    { lat: 48.37589, lng: 20.1813521 },
    { lat: 48.3757462, lng: 20.1807215 },
    { lat: 48.3758696, lng: 20.1806253 },
    { lat: 48.3759336, lng: 20.1806502 },
    { lat: 48.3759995, lng: 20.1809135 },
    { lat: 48.376072, lng: 20.1809497 },
    { lat: 48.3761195, lng: 20.1808472 },
    { lat: 48.3760923, lng: 20.1806478 },
    { lat: 48.3761228, lng: 20.1805384 },
    { lat: 48.376445, lng: 20.1806496 },
    { lat: 48.3766623, lng: 20.1805947 },
    { lat: 48.3767728, lng: 20.1805141 },
    { lat: 48.3768429, lng: 20.1802413 },
    { lat: 48.3769689, lng: 20.1803202 },
    { lat: 48.3771153, lng: 20.180959 },
    { lat: 48.3772059, lng: 20.1811389 },
    { lat: 48.3786018, lng: 20.1828173 },
    { lat: 48.3792352, lng: 20.1829689 },
    { lat: 48.3798149, lng: 20.1844902 },
    { lat: 48.380953, lng: 20.1844743 },
    { lat: 48.3812394, lng: 20.1850952 },
    { lat: 48.382107, lng: 20.1876089 },
    { lat: 48.382349, lng: 20.1888018 },
    { lat: 48.3824536, lng: 20.1895498 },
    { lat: 48.3825469, lng: 20.1898481 },
    { lat: 48.3839989, lng: 20.1920576 },
    { lat: 48.3852484, lng: 20.1927053 },
    { lat: 48.3857448, lng: 20.19376 },
    { lat: 48.3859631, lng: 20.1955947 },
    { lat: 48.3863828, lng: 20.1965076 },
    { lat: 48.3868525, lng: 20.1971236 },
    { lat: 48.3876557, lng: 20.1975739 },
    { lat: 48.3886653, lng: 20.1992574 },
    { lat: 48.3887654, lng: 20.1995913 },
    { lat: 48.3889925, lng: 20.2001961 },
  ],
  NižnýSkálnik: [
    { lat: 48.4842441, lng: 19.9758125 },
    { lat: 48.4838009, lng: 19.9756983 },
    { lat: 48.4835778, lng: 19.9757487 },
    { lat: 48.482925, lng: 19.9763997 },
    { lat: 48.4827847, lng: 19.9764426 },
    { lat: 48.4820593, lng: 19.9769745 },
    { lat: 48.4819738, lng: 19.9769007 },
    { lat: 48.4819033, lng: 19.9769351 },
    { lat: 48.4818843, lng: 19.9770221 },
    { lat: 48.4805242, lng: 19.9766641 },
    { lat: 48.4796801, lng: 19.9762285 },
    { lat: 48.4788461, lng: 19.9756019 },
    { lat: 48.4781997, lng: 19.9755291 },
    { lat: 48.477493, lng: 19.975865 },
    { lat: 48.4768254, lng: 19.9760359 },
    { lat: 48.4765274, lng: 19.9759384 },
    { lat: 48.476027, lng: 19.9765208 },
    { lat: 48.4758706, lng: 19.9767938 },
    { lat: 48.4758316, lng: 19.9770716 },
    { lat: 48.4756741, lng: 19.9773164 },
    { lat: 48.475628, lng: 19.9774773 },
    { lat: 48.4756521, lng: 19.9778066 },
    { lat: 48.4756147, lng: 19.9781787 },
    { lat: 48.4754904, lng: 19.978673 },
    { lat: 48.4755179, lng: 19.9791405 },
    { lat: 48.4754849, lng: 19.9793204 },
    { lat: 48.474663, lng: 19.9788248 },
    { lat: 48.473962, lng: 19.9786013 },
    { lat: 48.4738247, lng: 19.9787383 },
    { lat: 48.473598, lng: 19.9783552 },
    { lat: 48.4726245, lng: 19.9783284 },
    { lat: 48.472145, lng: 19.9764143 },
    { lat: 48.471673, lng: 19.9761693 },
    { lat: 48.4707879, lng: 19.9762069 },
    { lat: 48.4704199, lng: 19.976536 },
    { lat: 48.4700543, lng: 19.9771627 },
    { lat: 48.469228, lng: 19.9766675 },
    { lat: 48.4687196, lng: 19.976474 },
    { lat: 48.4678307, lng: 19.9767107 },
    { lat: 48.4672975, lng: 19.9775795 },
    { lat: 48.466839, lng: 19.9771298 },
    { lat: 48.4665858, lng: 19.9766272 },
    { lat: 48.466152, lng: 19.9759822 },
    { lat: 48.4656015, lng: 19.9754845 },
    { lat: 48.4650869, lng: 19.9752476 },
    { lat: 48.4646592, lng: 19.9753889 },
    { lat: 48.4634311, lng: 19.9755192 },
    { lat: 48.4621841, lng: 19.9751187 },
    { lat: 48.4605951, lng: 19.9750489 },
    { lat: 48.4603256, lng: 19.9741905 },
    { lat: 48.4599234, lng: 19.9743255 },
    { lat: 48.4597034, lng: 19.9743138 },
    { lat: 48.459626, lng: 19.9742417 },
    { lat: 48.4595924, lng: 19.9743514 },
    { lat: 48.459129, lng: 19.9738931 },
    { lat: 48.4588802, lng: 19.9733359 },
    { lat: 48.4579689, lng: 19.9737342 },
    { lat: 48.4566003, lng: 19.9683804 },
    { lat: 48.4565673, lng: 19.9683974 },
    { lat: 48.455679, lng: 19.9646322 },
    { lat: 48.4556523, lng: 19.9644821 },
    { lat: 48.4555817, lng: 19.9644367 },
    { lat: 48.4552621, lng: 19.9642299 },
    { lat: 48.4550371, lng: 19.9642912 },
    { lat: 48.4549525, lng: 19.9643954 },
    { lat: 48.4550223, lng: 19.9646595 },
    { lat: 48.4550152, lng: 19.9649991 },
    { lat: 48.4549423, lng: 19.9650345 },
    { lat: 48.4546761, lng: 19.9647285 },
    { lat: 48.4543268, lng: 19.9647034 },
    { lat: 48.4542895, lng: 19.9643804 },
    { lat: 48.454206, lng: 19.9641925 },
    { lat: 48.454133, lng: 19.9641672 },
    { lat: 48.454054, lng: 19.9639523 },
    { lat: 48.4538836, lng: 19.9640804 },
    { lat: 48.4539183, lng: 19.9642311 },
    { lat: 48.4538449, lng: 19.9643112 },
    { lat: 48.4539503, lng: 19.9647255 },
    { lat: 48.4539101, lng: 19.9647585 },
    { lat: 48.4539797, lng: 19.9650478 },
    { lat: 48.4539362, lng: 19.9651077 },
    { lat: 48.4539657, lng: 19.9652461 },
    { lat: 48.4537164, lng: 19.9654782 },
    { lat: 48.4538191, lng: 19.9658822 },
    { lat: 48.453593, lng: 19.9656749 },
    { lat: 48.4535613, lng: 19.9652731 },
    { lat: 48.4533168, lng: 19.9651399 },
    { lat: 48.4531717, lng: 19.9652532 },
    { lat: 48.4529207, lng: 19.9652584 },
    { lat: 48.4529638, lng: 19.9658235 },
    { lat: 48.4530262, lng: 19.9660432 },
    { lat: 48.452464, lng: 19.9663628 },
    { lat: 48.4519516, lng: 19.9657024 },
    { lat: 48.45201, lng: 19.9654573 },
    { lat: 48.4519119, lng: 19.9653201 },
    { lat: 48.4520408, lng: 19.9650938 },
    { lat: 48.4518536, lng: 19.964848 },
    { lat: 48.4518461, lng: 19.9646976 },
    { lat: 48.4519043, lng: 19.9645295 },
    { lat: 48.4517234, lng: 19.9639031 },
    { lat: 48.4516168, lng: 19.9637536 },
    { lat: 48.4516201, lng: 19.9636472 },
    { lat: 48.4516926, lng: 19.963521 },
    { lat: 48.4516145, lng: 19.9634039 },
    { lat: 48.4514093, lng: 19.9632958 },
    { lat: 48.4512273, lng: 19.9633352 },
    { lat: 48.449557, lng: 19.9621704 },
    { lat: 48.44908, lng: 19.9620497 },
    { lat: 48.4488493, lng: 19.9621111 },
    { lat: 48.4485093, lng: 19.9617686 },
    { lat: 48.4482068, lng: 19.9624448 },
    { lat: 48.4480916, lng: 19.9629749 },
    { lat: 48.4482097, lng: 19.963339 },
    { lat: 48.4484051, lng: 19.9635662 },
    { lat: 48.4485075, lng: 19.963643 },
    { lat: 48.4489761, lng: 19.963694 },
    { lat: 48.4490287, lng: 19.9638983 },
    { lat: 48.4487659, lng: 19.964372 },
    { lat: 48.4486006, lng: 19.9645095 },
    { lat: 48.4478642, lng: 19.964306 },
    { lat: 48.4477818, lng: 19.9641152 },
    { lat: 48.4480738, lng: 19.9636703 },
    { lat: 48.4480456, lng: 19.9634271 },
    { lat: 48.4478555, lng: 19.9634095 },
    { lat: 48.447581, lng: 19.9629915 },
    { lat: 48.4474474, lng: 19.9632431 },
    { lat: 48.4474061, lng: 19.9634369 },
    { lat: 48.4474111, lng: 19.964168 },
    { lat: 48.4471255, lng: 19.9644991 },
    { lat: 48.4474919, lng: 19.9650119 },
    { lat: 48.4476053, lng: 19.9649471 },
    { lat: 48.4476778, lng: 19.9651442 },
    { lat: 48.4471964, lng: 19.96556 },
    { lat: 48.4469791, lng: 19.9650833 },
    { lat: 48.4465147, lng: 19.9657728 },
    { lat: 48.4464109, lng: 19.9661155 },
    { lat: 48.446023, lng: 19.9665812 },
    { lat: 48.4461799, lng: 19.9671755 },
    { lat: 48.4460452, lng: 19.9673171 },
    { lat: 48.4456702, lng: 19.9663662 },
    { lat: 48.445456, lng: 19.9666812 },
    { lat: 48.445499, lng: 19.9669794 },
    { lat: 48.4454558, lng: 19.9672858 },
    { lat: 48.4455695, lng: 19.9676564 },
    { lat: 48.4457683, lng: 19.9675135 },
    { lat: 48.4458757, lng: 19.9677073 },
    { lat: 48.4451434, lng: 19.9685043 },
    { lat: 48.445081, lng: 19.9684908 },
    { lat: 48.4450991, lng: 19.9682839 },
    { lat: 48.4449981, lng: 19.9680772 },
    { lat: 48.4449152, lng: 19.9675852 },
    { lat: 48.4447986, lng: 19.9674633 },
    { lat: 48.444662, lng: 19.9676445 },
    { lat: 48.444585, lng: 19.9691264 },
    { lat: 48.4446968, lng: 19.969214 },
    { lat: 48.4450819, lng: 19.9690977 },
    { lat: 48.4451799, lng: 19.9691767 },
    { lat: 48.4450184, lng: 19.9694615 },
    { lat: 48.4450017, lng: 19.9695748 },
    { lat: 48.4450872, lng: 19.9696752 },
    { lat: 48.4452809, lng: 19.9696248 },
    { lat: 48.4453546, lng: 19.9697692 },
    { lat: 48.4453479, lng: 19.9698802 },
    { lat: 48.4451693, lng: 19.9702109 },
    { lat: 48.4446734, lng: 19.9708941 },
    { lat: 48.4446301, lng: 19.9710703 },
    { lat: 48.4447244, lng: 19.9714917 },
    { lat: 48.4446004, lng: 19.9716465 },
    { lat: 48.4443288, lng: 19.9718045 },
    { lat: 48.4437751, lng: 19.971366 },
    { lat: 48.4435685, lng: 19.9715649 },
    { lat: 48.4434512, lng: 19.971883 },
    { lat: 48.4434094, lng: 19.9721463 },
    { lat: 48.4439864, lng: 19.9752415 },
    { lat: 48.4439859, lng: 19.9760058 },
    { lat: 48.4439245, lng: 19.9761518 },
    { lat: 48.44391, lng: 19.97701 },
    { lat: 48.4437987, lng: 19.9773867 },
    { lat: 48.4437887, lng: 19.9778149 },
    { lat: 48.4438347, lng: 19.978028 },
    { lat: 48.4438067, lng: 19.9781626 },
    { lat: 48.4439003, lng: 19.9787256 },
    { lat: 48.4438354, lng: 19.9789213 },
    { lat: 48.443806, lng: 19.9792647 },
    { lat: 48.4438879, lng: 19.9793931 },
    { lat: 48.4438983, lng: 19.9796398 },
    { lat: 48.4439919, lng: 19.9797206 },
    { lat: 48.4439637, lng: 19.9799362 },
    { lat: 48.4441037, lng: 19.9799927 },
    { lat: 48.4440527, lng: 19.9801502 },
    { lat: 48.4441543, lng: 19.9803633 },
    { lat: 48.4441652, lng: 19.9805709 },
    { lat: 48.4441071, lng: 19.9806878 },
    { lat: 48.4441158, lng: 19.9808435 },
    { lat: 48.444188, lng: 19.9809214 },
    { lat: 48.4441445, lng: 19.9810926 },
    { lat: 48.4442617, lng: 19.9813877 },
    { lat: 48.4441878, lng: 19.9816672 },
    { lat: 48.4443529, lng: 19.9822965 },
    { lat: 48.4447039, lng: 19.9841531 },
    { lat: 48.4451885, lng: 19.9847193 },
    { lat: 48.4453913, lng: 19.9850945 },
    { lat: 48.4455558, lng: 19.9856873 },
    { lat: 48.4457539, lng: 19.9858168 },
    { lat: 48.4458452, lng: 19.9860565 },
    { lat: 48.4459624, lng: 19.9860038 },
    { lat: 48.446261, lng: 19.9860601 },
    { lat: 48.4473394, lng: 19.9859617 },
    { lat: 48.4482193, lng: 19.986473 },
    { lat: 48.4495591, lng: 19.9880592 },
    { lat: 48.4506172, lng: 19.9891362 },
    { lat: 48.4518504, lng: 19.9895593 },
    { lat: 48.4519185, lng: 19.9897011 },
    { lat: 48.4519116, lng: 19.9903398 },
    { lat: 48.4524023, lng: 19.9908658 },
    { lat: 48.4529211, lng: 19.9923264 },
    { lat: 48.4536003, lng: 19.9934729 },
    { lat: 48.4540115, lng: 19.9944654 },
    { lat: 48.4555619, lng: 19.9923864 },
    { lat: 48.4585009, lng: 19.9890988 },
    { lat: 48.4585319, lng: 19.9891671 },
    { lat: 48.4589038, lng: 19.9893269 },
    { lat: 48.4587442, lng: 19.9895788 },
    { lat: 48.4587942, lng: 19.9897728 },
    { lat: 48.4589733, lng: 19.9900692 },
    { lat: 48.458914, lng: 19.9905894 },
    { lat: 48.4589474, lng: 19.9909511 },
    { lat: 48.4590642, lng: 19.9911298 },
    { lat: 48.459477, lng: 19.9914934 },
    { lat: 48.4597509, lng: 19.9919769 },
    { lat: 48.4598563, lng: 19.9919921 },
    { lat: 48.460022, lng: 19.9918898 },
    { lat: 48.4601963, lng: 19.9919591 },
    { lat: 48.4602497, lng: 19.9920271 },
    { lat: 48.4602526, lng: 19.9923374 },
    { lat: 48.4603171, lng: 19.9923671 },
    { lat: 48.4604682, lng: 19.9920769 },
    { lat: 48.4605489, lng: 19.9920693 },
    { lat: 48.4606099, lng: 19.9923421 },
    { lat: 48.4606946, lng: 19.9923741 },
    { lat: 48.4610176, lng: 19.9921247 },
    { lat: 48.4612493, lng: 19.9923302 },
    { lat: 48.4614161, lng: 19.9922764 },
    { lat: 48.4616587, lng: 19.9918096 },
    { lat: 48.4619436, lng: 19.9920164 },
    { lat: 48.4620468, lng: 19.9917656 },
    { lat: 48.4620339, lng: 19.991544 },
    { lat: 48.4620654, lng: 19.9914902 },
    { lat: 48.4623358, lng: 19.9913307 },
    { lat: 48.4627065, lng: 19.9908997 },
    { lat: 48.462811, lng: 19.9908709 },
    { lat: 48.463058, lng: 19.9911693 },
    { lat: 48.4632842, lng: 19.9912436 },
    { lat: 48.4634405, lng: 19.9912114 },
    { lat: 48.4635556, lng: 19.9914056 },
    { lat: 48.463792, lng: 19.9914526 },
    { lat: 48.4639313, lng: 19.9914213 },
    { lat: 48.4643652, lng: 19.9911301 },
    { lat: 48.4647977, lng: 19.9911021 },
    { lat: 48.4649517, lng: 19.9910097 },
    { lat: 48.465174, lng: 19.9910468 },
    { lat: 48.4652641, lng: 19.9908323 },
    { lat: 48.4655446, lng: 19.9908844 },
    { lat: 48.4655764, lng: 19.9906255 },
    { lat: 48.4656597, lng: 19.9904614 },
    { lat: 48.4657891, lng: 19.9905704 },
    { lat: 48.4658978, lng: 19.9903429 },
    { lat: 48.4659909, lng: 19.9902765 },
    { lat: 48.4660962, lng: 19.990318 },
    { lat: 48.4662925, lng: 19.9902428 },
    { lat: 48.4663625, lng: 19.9905418 },
    { lat: 48.4665443, lng: 19.9905479 },
    { lat: 48.4666466, lng: 19.9907089 },
    { lat: 48.4666927, lng: 19.9906257 },
    { lat: 48.4674067, lng: 19.9905677 },
    { lat: 48.4680976, lng: 19.9901747 },
    { lat: 48.4684217, lng: 19.990191 },
    { lat: 48.4689814, lng: 19.9903497 },
    { lat: 48.4696338, lng: 19.9906511 },
    { lat: 48.469959, lng: 19.990695 },
    { lat: 48.4704163, lng: 19.9909099 },
    { lat: 48.4714782, lng: 19.9910073 },
    { lat: 48.4720485, lng: 19.9912274 },
    { lat: 48.4721442, lng: 19.9913866 },
    { lat: 48.4729758, lng: 19.9915527 },
    { lat: 48.4731981, lng: 19.9915355 },
    { lat: 48.4736374, lng: 19.9913236 },
    { lat: 48.4744528, lng: 19.9924978 },
    { lat: 48.4748594, lng: 19.9920895 },
    { lat: 48.4765969, lng: 19.9917793 },
    { lat: 48.4773928, lng: 19.9878654 },
    { lat: 48.4775176, lng: 19.9869996 },
    { lat: 48.477611, lng: 19.9867578 },
    { lat: 48.4777497, lng: 19.9866267 },
    { lat: 48.4777955, lng: 19.9864864 },
    { lat: 48.4778715, lng: 19.9860993 },
    { lat: 48.4778519, lng: 19.9858473 },
    { lat: 48.477931, lng: 19.9856622 },
    { lat: 48.4779335, lng: 19.9854579 },
    { lat: 48.4780402, lng: 19.985235 },
    { lat: 48.4781985, lng: 19.9844992 },
    { lat: 48.478179, lng: 19.9842774 },
    { lat: 48.4783056, lng: 19.9838581 },
    { lat: 48.4782729, lng: 19.9837817 },
    { lat: 48.4783149, lng: 19.9837088 },
    { lat: 48.4782942, lng: 19.9834587 },
    { lat: 48.4779829, lng: 19.9829381 },
    { lat: 48.4778485, lng: 19.9828763 },
    { lat: 48.4777408, lng: 19.9826715 },
    { lat: 48.4776166, lng: 19.9826169 },
    { lat: 48.4775797, lng: 19.9823797 },
    { lat: 48.4775401, lng: 19.9819986 },
    { lat: 48.4776233, lng: 19.9816485 },
    { lat: 48.4778046, lng: 19.9812747 },
    { lat: 48.47781, lng: 19.9806291 },
    { lat: 48.4779119, lng: 19.980242 },
    { lat: 48.4784395, lng: 19.9800645 },
    { lat: 48.4786576, lng: 19.9798683 },
    { lat: 48.478787, lng: 19.979834 },
    { lat: 48.4790338, lng: 19.9794909 },
    { lat: 48.4792572, lng: 19.9795257 },
    { lat: 48.4795451, lng: 19.979407 },
    { lat: 48.4798979, lng: 19.9794383 },
    { lat: 48.4805074, lng: 19.9793098 },
    { lat: 48.4806774, lng: 19.979361 },
    { lat: 48.481069, lng: 19.9796418 },
    { lat: 48.4815964, lng: 19.9797538 },
    { lat: 48.4817521, lng: 19.9798426 },
    { lat: 48.4819921, lng: 19.9801076 },
    { lat: 48.4821127, lng: 19.9804317 },
    { lat: 48.482212, lng: 19.9805429 },
    { lat: 48.4824251, lng: 19.9803855 },
    { lat: 48.4824809, lng: 19.9804043 },
    { lat: 48.4825732, lng: 19.9805056 },
    { lat: 48.4827485, lng: 19.9809231 },
    { lat: 48.4828878, lng: 19.981075 },
    { lat: 48.4831083, lng: 19.9810681 },
    { lat: 48.4832392, lng: 19.9815531 },
    { lat: 48.4834253, lng: 19.9819312 },
    { lat: 48.4834989, lng: 19.9818894 },
    { lat: 48.4834844, lng: 19.9811444 },
    { lat: 48.4836853, lng: 19.9807024 },
    { lat: 48.4836557, lng: 19.9806108 },
    { lat: 48.484078, lng: 19.9802633 },
    { lat: 48.4841977, lng: 19.9802416 },
    { lat: 48.4842179, lng: 19.976903 },
    { lat: 48.4842441, lng: 19.9758125 },
  ],
  RimavskáSobota: [
    { lat: 48.3836043, lng: 20.0751139 },
    { lat: 48.3836645, lng: 20.075997 },
    { lat: 48.3836169, lng: 20.0801268 },
    { lat: 48.383324, lng: 20.080236 },
    { lat: 48.3834286, lng: 20.081127 },
    { lat: 48.3836729, lng: 20.0818838 },
    { lat: 48.3837098, lng: 20.0822056 },
    { lat: 48.3836613, lng: 20.0837037 },
    { lat: 48.3835868, lng: 20.0839747 },
    { lat: 48.3831367, lng: 20.0848029 },
    { lat: 48.3827762, lng: 20.0856983 },
    { lat: 48.3826729, lng: 20.0865509 },
    { lat: 48.3831441, lng: 20.087598 },
    { lat: 48.3833036, lng: 20.0891377 },
    { lat: 48.3832059, lng: 20.089555 },
    { lat: 48.3820819, lng: 20.0915514 },
    { lat: 48.3817289, lng: 20.093257 },
    { lat: 48.3815779, lng: 20.094541 },
    { lat: 48.381938, lng: 20.0962767 },
    { lat: 48.382277, lng: 20.0968809 },
    { lat: 48.3831444, lng: 20.0980532 },
    { lat: 48.3836972, lng: 20.0998799 },
    { lat: 48.3838587, lng: 20.1009276 },
    { lat: 48.3839958, lng: 20.1014142 },
    { lat: 48.3839573, lng: 20.1019287 },
    { lat: 48.3838526, lng: 20.1025054 },
    { lat: 48.3834169, lng: 20.1037935 },
    { lat: 48.3831338, lng: 20.1043608 },
    { lat: 48.3828375, lng: 20.104822 },
    { lat: 48.3824529, lng: 20.105111 },
    { lat: 48.3822845, lng: 20.1053166 },
    { lat: 48.3810254, lng: 20.108054 },
    { lat: 48.379288, lng: 20.1111671 },
    { lat: 48.3788472, lng: 20.1125019 },
    { lat: 48.3786122, lng: 20.1135249 },
    { lat: 48.3784543, lng: 20.1138474 },
    { lat: 48.3774422, lng: 20.115421 },
    { lat: 48.3783182, lng: 20.115279 },
    { lat: 48.3810908, lng: 20.1151283 },
    { lat: 48.3818924, lng: 20.1151969 },
    { lat: 48.3825382, lng: 20.1151513 },
    { lat: 48.3830466, lng: 20.1151918 },
    { lat: 48.3874506, lng: 20.1150506 },
    { lat: 48.3878621, lng: 20.1140699 },
    { lat: 48.389129, lng: 20.1141772 },
    { lat: 48.3902166, lng: 20.1150653 },
    { lat: 48.3915818, lng: 20.1157558 },
    { lat: 48.3923473, lng: 20.1166772 },
    { lat: 48.3935864, lng: 20.1183254 },
    { lat: 48.3941793, lng: 20.1184162 },
    { lat: 48.3955276, lng: 20.1182 },
    { lat: 48.3957558, lng: 20.1168417 },
    { lat: 48.3952352, lng: 20.1162058 },
    { lat: 48.3954119, lng: 20.1146924 },
    { lat: 48.3957603, lng: 20.1133594 },
    { lat: 48.3961237, lng: 20.1125009 },
    { lat: 48.3968922, lng: 20.1114111 },
    { lat: 48.397153, lng: 20.1111799 },
    { lat: 48.3979968, lng: 20.1099806 },
    { lat: 48.3988271, lng: 20.1089389 },
    { lat: 48.4000088, lng: 20.1080637 },
    { lat: 48.4002481, lng: 20.108023 },
    { lat: 48.4011159, lng: 20.1060926 },
    { lat: 48.4013804, lng: 20.104501 },
    { lat: 48.4023708, lng: 20.1016065 },
    { lat: 48.4026836, lng: 20.1013499 },
    { lat: 48.405595, lng: 20.1000866 },
    { lat: 48.4056874, lng: 20.0997355 },
    { lat: 48.4058895, lng: 20.0992488 },
    { lat: 48.4060731, lng: 20.0977489 },
    { lat: 48.4059978, lng: 20.0972319 },
    { lat: 48.4058254, lng: 20.0958909 },
    { lat: 48.4055767, lng: 20.0926487 },
    { lat: 48.4052131, lng: 20.0910433 },
    { lat: 48.4052342, lng: 20.0879533 },
    { lat: 48.4049764, lng: 20.0859418 },
    { lat: 48.4046857, lng: 20.0850405 },
    { lat: 48.4045841, lng: 20.0842154 },
    { lat: 48.4045813, lng: 20.0837949 },
    { lat: 48.4052311, lng: 20.0796491 },
    { lat: 48.4055775, lng: 20.0777871 },
    { lat: 48.4059091, lng: 20.0762984 },
    { lat: 48.4060477, lng: 20.0751032 },
    { lat: 48.4060827, lng: 20.0741817 },
    { lat: 48.4058914, lng: 20.0733708 },
    { lat: 48.4057681, lng: 20.0730303 },
    { lat: 48.4057195, lng: 20.0730827 },
    { lat: 48.4051488, lng: 20.0724621 },
    { lat: 48.4043937, lng: 20.072075 },
    { lat: 48.402653, lng: 20.0714695 },
    { lat: 48.4023703, lng: 20.0714711 },
    { lat: 48.4012687, lng: 20.0717148 },
    { lat: 48.4006757, lng: 20.0716227 },
    { lat: 48.4001051, lng: 20.0716311 },
    { lat: 48.3995972, lng: 20.0718781 },
    { lat: 48.3993919, lng: 20.0720394 },
    { lat: 48.3990422, lng: 20.0724049 },
    { lat: 48.3985803, lng: 20.0732162 },
    { lat: 48.3982332, lng: 20.0736233 },
    { lat: 48.3979254, lng: 20.0737548 },
    { lat: 48.3964909, lng: 20.0736822 },
    { lat: 48.3954499, lng: 20.0732428 },
    { lat: 48.3949918, lng: 20.0732282 },
    { lat: 48.393641, lng: 20.0735188 },
    { lat: 48.3933103, lng: 20.0736904 },
    { lat: 48.3928347, lng: 20.0737941 },
    { lat: 48.392418, lng: 20.0737167 },
    { lat: 48.3920672, lng: 20.0728319 },
    { lat: 48.3920118, lng: 20.0725826 },
    { lat: 48.3917683, lng: 20.0724998 },
    { lat: 48.391745, lng: 20.0722354 },
    { lat: 48.3908859, lng: 20.0666473 },
    { lat: 48.3909622, lng: 20.0665867 },
    { lat: 48.3905515, lng: 20.064942 },
    { lat: 48.3904693, lng: 20.0642374 },
    { lat: 48.3901845, lng: 20.0626426 },
    { lat: 48.3900332, lng: 20.0599201 },
    { lat: 48.3896987, lng: 20.0589492 },
    { lat: 48.3895935, lng: 20.0588034 },
    { lat: 48.3896912, lng: 20.0586827 },
    { lat: 48.3894148, lng: 20.0583474 },
    { lat: 48.3895301, lng: 20.0580613 },
    { lat: 48.3896409, lng: 20.0573424 },
    { lat: 48.3901791, lng: 20.0568479 },
    { lat: 48.3903113, lng: 20.0565814 },
    { lat: 48.3903071, lng: 20.0564301 },
    { lat: 48.3907895, lng: 20.0558667 },
    { lat: 48.3910773, lng: 20.0561811 },
    { lat: 48.3924753, lng: 20.0539369 },
    { lat: 48.3947645, lng: 20.0524242 },
    { lat: 48.3951795, lng: 20.0520847 },
    { lat: 48.3954387, lng: 20.0516538 },
    { lat: 48.3956122, lng: 20.0509783 },
    { lat: 48.395906, lng: 20.0504612 },
    { lat: 48.3958051, lng: 20.05009 },
    { lat: 48.3958967, lng: 20.0499081 },
    { lat: 48.3957937, lng: 20.049816 },
    { lat: 48.3963428, lng: 20.0491038 },
    { lat: 48.3966118, lng: 20.0481211 },
    { lat: 48.3968143, lng: 20.0475768 },
    { lat: 48.3969594, lng: 20.0476252 },
    { lat: 48.3975591, lng: 20.0469039 },
    { lat: 48.3984078, lng: 20.0468331 },
    { lat: 48.3986878, lng: 20.0467153 },
    { lat: 48.3997475, lng: 20.0466551 },
    { lat: 48.4000917, lng: 20.0463445 },
    { lat: 48.4000894, lng: 20.0463968 },
    { lat: 48.4003086, lng: 20.0473872 },
    { lat: 48.4005995, lng: 20.0482623 },
    { lat: 48.404101, lng: 20.0457004 },
    { lat: 48.4050709, lng: 20.0451122 },
    { lat: 48.4049757, lng: 20.0444185 },
    { lat: 48.4052147, lng: 20.0435041 },
    { lat: 48.405331, lng: 20.0432695 },
    { lat: 48.4054207, lng: 20.0433663 },
    { lat: 48.4053825, lng: 20.0434522 },
    { lat: 48.4064495, lng: 20.0440783 },
    { lat: 48.4065509, lng: 20.0438365 },
    { lat: 48.4066403, lng: 20.0434119 },
    { lat: 48.4070673, lng: 20.0440113 },
    { lat: 48.4074299, lng: 20.0444133 },
    { lat: 48.4076824, lng: 20.044554 },
    { lat: 48.4078044, lng: 20.0445578 },
    { lat: 48.4085266, lng: 20.0441308 },
    { lat: 48.4093905, lng: 20.0434302 },
    { lat: 48.4101134, lng: 20.043098 },
    { lat: 48.4101892, lng: 20.0435352 },
    { lat: 48.4104621, lng: 20.0440569 },
    { lat: 48.4106284, lng: 20.0442342 },
    { lat: 48.4110033, lng: 20.0431501 },
    { lat: 48.4112014, lng: 20.0431457 },
    { lat: 48.4113997, lng: 20.0424218 },
    { lat: 48.4116693, lng: 20.042722 },
    { lat: 48.4120263, lng: 20.0422661 },
    { lat: 48.4127588, lng: 20.0409998 },
    { lat: 48.4127681, lng: 20.0406557 },
    { lat: 48.4129496, lng: 20.0402532 },
    { lat: 48.4130364, lng: 20.0397423 },
    { lat: 48.4134727, lng: 20.03859 },
    { lat: 48.4131007, lng: 20.0383974 },
    { lat: 48.4135007, lng: 20.0374006 },
    { lat: 48.4142672, lng: 20.0371139 },
    { lat: 48.4147517, lng: 20.0368502 },
    { lat: 48.4149718, lng: 20.0369149 },
    { lat: 48.4159679, lng: 20.0384866 },
    { lat: 48.4160791, lng: 20.0383183 },
    { lat: 48.41647, lng: 20.0390999 },
    { lat: 48.4167507, lng: 20.0394295 },
    { lat: 48.4193757, lng: 20.0403828 },
    { lat: 48.4194027, lng: 20.0403022 },
    { lat: 48.4197562, lng: 20.0404349 },
    { lat: 48.4204276, lng: 20.0402434 },
    { lat: 48.4209193, lng: 20.040012 },
    { lat: 48.4209465, lng: 20.0392342 },
    { lat: 48.4208802, lng: 20.0387028 },
    { lat: 48.4211147, lng: 20.0382471 },
    { lat: 48.4219019, lng: 20.0374885 },
    { lat: 48.4239108, lng: 20.0377125 },
    { lat: 48.4245041, lng: 20.0379478 },
    { lat: 48.4247465, lng: 20.0377381 },
    { lat: 48.4251628, lng: 20.0373229 },
    { lat: 48.4257227, lng: 20.0361339 },
    { lat: 48.4266383, lng: 20.0357784 },
    { lat: 48.4270197, lng: 20.0354705 },
    { lat: 48.4278427, lng: 20.0349493 },
    { lat: 48.4286063, lng: 20.0341332 },
    { lat: 48.4289383, lng: 20.0340182 },
    { lat: 48.429181, lng: 20.0338449 },
    { lat: 48.4302549, lng: 20.0327736 },
    { lat: 48.4305249, lng: 20.0326585 },
    { lat: 48.4311504, lng: 20.0319905 },
    { lat: 48.4315268, lng: 20.031429 },
    { lat: 48.4318298, lng: 20.0306704 },
    { lat: 48.432145, lng: 20.0303154 },
    { lat: 48.4323524, lng: 20.0299135 },
    { lat: 48.4323877, lng: 20.029465 },
    { lat: 48.4323055, lng: 20.0289552 },
    { lat: 48.4324043, lng: 20.0283532 },
    { lat: 48.4325768, lng: 20.0279006 },
    { lat: 48.4332169, lng: 20.0267474 },
    { lat: 48.4332928, lng: 20.0264334 },
    { lat: 48.433962, lng: 20.0251982 },
    { lat: 48.4343334, lng: 20.0246243 },
    { lat: 48.4349828, lng: 20.02243 },
    { lat: 48.4361076, lng: 20.020074 },
    { lat: 48.4366685, lng: 20.0187833 },
    { lat: 48.4373224, lng: 20.0182837 },
    { lat: 48.4381696, lng: 20.0180986 },
    { lat: 48.4387333, lng: 20.0180811 },
    { lat: 48.4394939, lng: 20.0182235 },
    { lat: 48.4400763, lng: 20.0181418 },
    { lat: 48.4409346, lng: 20.0185197 },
    { lat: 48.441836, lng: 20.0182801 },
    { lat: 48.4425681, lng: 20.0179285 },
    { lat: 48.4435411, lng: 20.0172248 },
    { lat: 48.4449133, lng: 20.0167199 },
    { lat: 48.4454425, lng: 20.0167625 },
    { lat: 48.445965, lng: 20.0164686 },
    { lat: 48.4491288, lng: 20.0170712 },
    { lat: 48.4492358, lng: 20.0169099 },
    { lat: 48.4505794, lng: 20.0144866 },
    { lat: 48.4525524, lng: 20.0125912 },
    { lat: 48.4534091, lng: 20.0120434 },
    { lat: 48.4540138, lng: 20.0113101 },
    { lat: 48.4547108, lng: 20.0109311 },
    { lat: 48.4555019, lng: 20.0101844 },
    { lat: 48.4554883, lng: 20.0101019 },
    { lat: 48.4553909, lng: 20.0094972 },
    { lat: 48.4551494, lng: 20.009029 },
    { lat: 48.4550383, lng: 20.0078883 },
    { lat: 48.4546214, lng: 20.0071053 },
    { lat: 48.4554942, lng: 20.0053827 },
    { lat: 48.4557545, lng: 20.0044985 },
    { lat: 48.455794, lng: 20.004285 },
    { lat: 48.4558002, lng: 20.0036847 },
    { lat: 48.455764, lng: 20.0028236 },
    { lat: 48.4558858, lng: 20.0022606 },
    { lat: 48.4559215, lng: 20.0017685 },
    { lat: 48.4561936, lng: 20.001193 },
    { lat: 48.4563069, lng: 20.0004359 },
    { lat: 48.4562494, lng: 19.9997348 },
    { lat: 48.455924, lng: 19.998935 },
    { lat: 48.455338, lng: 19.998712 },
    { lat: 48.4543425, lng: 19.9977559 },
    { lat: 48.4543369, lng: 19.9977851 },
    { lat: 48.4535265, lng: 19.9974547 },
    { lat: 48.4525863, lng: 19.997543 },
    { lat: 48.4522782, lng: 19.997689 },
    { lat: 48.451496, lng: 19.9974649 },
    { lat: 48.4512346, lng: 19.997301 },
    { lat: 48.4508187, lng: 19.9971602 },
    { lat: 48.4499723, lng: 19.9971005 },
    { lat: 48.4496681, lng: 19.9968565 },
    { lat: 48.4479812, lng: 19.9967071 },
    { lat: 48.4474923, lng: 19.996465 },
    { lat: 48.4464498, lng: 19.9962937 },
    { lat: 48.4456789, lng: 19.9960438 },
    { lat: 48.4453496, lng: 19.9960101 },
    { lat: 48.4442156, lng: 19.9955374 },
    { lat: 48.4431127, lng: 19.9956423 },
    { lat: 48.4429354, lng: 19.9958447 },
    { lat: 48.4417993, lng: 19.9978365 },
    { lat: 48.4413512, lng: 19.9980032 },
    { lat: 48.4407746, lng: 19.9980885 },
    { lat: 48.4401516, lng: 19.9985439 },
    { lat: 48.4384146, lng: 19.9989164 },
    { lat: 48.4372511, lng: 19.9994551 },
    { lat: 48.4365215, lng: 19.9995726 },
    { lat: 48.4355492, lng: 20.0002924 },
    { lat: 48.4343392, lng: 20.0000217 },
    { lat: 48.4333215, lng: 19.9991223 },
    { lat: 48.4329981, lng: 19.9986383 },
    { lat: 48.4327854, lng: 19.9982183 },
    { lat: 48.4317719, lng: 19.9975796 },
    { lat: 48.4314167, lng: 19.9972778 },
    { lat: 48.4297664, lng: 19.9967439 },
    { lat: 48.4293723, lng: 19.9968803 },
    { lat: 48.4291304, lng: 19.9968593 },
    { lat: 48.428799, lng: 19.996585 },
    { lat: 48.428605, lng: 19.9963396 },
    { lat: 48.4282704, lng: 19.9961238 },
    { lat: 48.4273342, lng: 19.9958027 },
    { lat: 48.4255479, lng: 19.9953535 },
    { lat: 48.4255584, lng: 19.9956299 },
    { lat: 48.4252298, lng: 19.9962232 },
    { lat: 48.4251379, lng: 19.9965866 },
    { lat: 48.4246208, lng: 19.9968797 },
    { lat: 48.4233394, lng: 19.9974011 },
    { lat: 48.4218835, lng: 19.9986969 },
    { lat: 48.4195732, lng: 20.0004698 },
    { lat: 48.4176354, lng: 20.001642 },
    { lat: 48.4170685, lng: 20.0025982 },
    { lat: 48.4165114, lng: 20.0039021 },
    { lat: 48.4162756, lng: 20.0043475 },
    { lat: 48.4161609, lng: 20.0047523 },
    { lat: 48.4159461, lng: 20.0051404 },
    { lat: 48.4155914, lng: 20.0063715 },
    { lat: 48.4146227, lng: 20.0080943 },
    { lat: 48.4132245, lng: 20.0094799 },
    { lat: 48.4119759, lng: 20.0101133 },
    { lat: 48.4100767, lng: 20.0123428 },
    { lat: 48.4096479, lng: 20.0127564 },
    { lat: 48.4092326, lng: 20.0133268 },
    { lat: 48.4089369, lng: 20.0136097 },
    { lat: 48.408442, lng: 20.0142997 },
    { lat: 48.4079415, lng: 20.015233 },
    { lat: 48.4077948, lng: 20.0150891 },
    { lat: 48.4076498, lng: 20.0148557 },
    { lat: 48.407552, lng: 20.0148104 },
    { lat: 48.4075024, lng: 20.014915 },
    { lat: 48.4072329, lng: 20.0148838 },
    { lat: 48.4070301, lng: 20.0149338 },
    { lat: 48.4067901, lng: 20.0148519 },
    { lat: 48.4065342, lng: 20.0149807 },
    { lat: 48.4063154, lng: 20.0149755 },
    { lat: 48.4062319, lng: 20.0150544 },
    { lat: 48.4061721, lng: 20.0149692 },
    { lat: 48.4060026, lng: 20.0149059 },
    { lat: 48.4056836, lng: 20.0149541 },
    { lat: 48.4056373, lng: 20.0148681 },
    { lat: 48.405346, lng: 20.0147439 },
    { lat: 48.4052909, lng: 20.0146141 },
    { lat: 48.404793, lng: 20.0140206 },
    { lat: 48.404695, lng: 20.0136473 },
    { lat: 48.4045669, lng: 20.0133861 },
    { lat: 48.4045732, lng: 20.0132664 },
    { lat: 48.4044273, lng: 20.0129842 },
    { lat: 48.4043423, lng: 20.012971 },
    { lat: 48.4041943, lng: 20.0122915 },
    { lat: 48.4041416, lng: 20.0122448 },
    { lat: 48.4038096, lng: 20.011201 },
    { lat: 48.4036295, lng: 20.0109638 },
    { lat: 48.4035183, lng: 20.0110261 },
    { lat: 48.4033885, lng: 20.0109941 },
    { lat: 48.4033232, lng: 20.010889 },
    { lat: 48.4032871, lng: 20.0106421 },
    { lat: 48.4031924, lng: 20.0105897 },
    { lat: 48.4030486, lng: 20.0106505 },
    { lat: 48.4029744, lng: 20.0104395 },
    { lat: 48.4028806, lng: 20.0104601 },
    { lat: 48.4028266, lng: 20.0100561 },
    { lat: 48.402671, lng: 20.0097369 },
    { lat: 48.4026743, lng: 20.0096235 },
    { lat: 48.4025368, lng: 20.0094848 },
    { lat: 48.402498, lng: 20.0092874 },
    { lat: 48.4025182, lng: 20.0090431 },
    { lat: 48.402355, lng: 20.0088313 },
    { lat: 48.402335, lng: 20.0085985 },
    { lat: 48.4022029, lng: 20.0085663 },
    { lat: 48.4021449, lng: 20.0083652 },
    { lat: 48.402073, lng: 20.008289 },
    { lat: 48.4019611, lng: 20.0077934 },
    { lat: 48.4018538, lng: 20.0076845 },
    { lat: 48.4016524, lng: 20.007041 },
    { lat: 48.4013852, lng: 20.0066654 },
    { lat: 48.4013903, lng: 20.0063547 },
    { lat: 48.4014934, lng: 20.0059142 },
    { lat: 48.4014967, lng: 20.0056948 },
    { lat: 48.4017755, lng: 20.005416 },
    { lat: 48.4018932, lng: 20.0051872 },
    { lat: 48.4019033, lng: 20.0049739 },
    { lat: 48.4016823, lng: 20.0049959 },
    { lat: 48.4016355, lng: 20.0048972 },
    { lat: 48.4015502, lng: 20.0049063 },
    { lat: 48.4015684, lng: 20.0048018 },
    { lat: 48.4014781, lng: 20.0046115 },
    { lat: 48.4016647, lng: 20.0041712 },
    { lat: 48.4015684, lng: 20.0040808 },
    { lat: 48.4016224, lng: 20.0039168 },
    { lat: 48.4015604, lng: 20.0037561 },
    { lat: 48.4016298, lng: 20.0036389 },
    { lat: 48.4015479, lng: 20.0034818 },
    { lat: 48.401344, lng: 20.003297 },
    { lat: 48.4014564, lng: 20.0029446 },
    { lat: 48.401377, lng: 20.0028752 },
    { lat: 48.40136, lng: 20.0027676 },
    { lat: 48.4012854, lng: 20.002757 },
    { lat: 48.4013087, lng: 20.0026427 },
    { lat: 48.4012087, lng: 20.0023303 },
    { lat: 48.4013267, lng: 20.0021611 },
    { lat: 48.4013413, lng: 20.0020544 },
    { lat: 48.4010703, lng: 20.0011556 },
    { lat: 48.4010542, lng: 20.0008235 },
    { lat: 48.400789, lng: 20.0006519 },
    { lat: 48.4007437, lng: 20.0000589 },
    { lat: 48.400472, lng: 19.9990379 },
    { lat: 48.3995021, lng: 19.9974 },
    { lat: 48.3993348, lng: 19.9972284 },
    { lat: 48.3987273, lng: 19.9968703 },
    { lat: 48.3983031, lng: 19.9966681 },
    { lat: 48.3978692, lng: 19.9965547 },
    { lat: 48.397418, lng: 19.9965139 },
    { lat: 48.3957176, lng: 19.9968263 },
    { lat: 48.395437, lng: 19.9967934 },
    { lat: 48.3941932, lng: 19.9960686 },
    { lat: 48.3942143, lng: 19.9959816 },
    { lat: 48.3941555, lng: 19.9959474 },
    { lat: 48.3939089, lng: 19.9950702 },
    { lat: 48.3937712, lng: 19.9948763 },
    { lat: 48.3933258, lng: 19.9947821 },
    { lat: 48.3930321, lng: 19.9951113 },
    { lat: 48.3929559, lng: 19.9949899 },
    { lat: 48.3928185, lng: 19.9949097 },
    { lat: 48.3933504, lng: 19.9933883 },
    { lat: 48.3934598, lng: 19.9932563 },
    { lat: 48.3937428, lng: 19.9934506 },
    { lat: 48.3939851, lng: 19.9934265 },
    { lat: 48.3941051, lng: 19.9933878 },
    { lat: 48.3943356, lng: 19.9931391 },
    { lat: 48.3942113, lng: 19.9928046 },
    { lat: 48.3941817, lng: 19.9915707 },
    { lat: 48.3942638, lng: 19.9911936 },
    { lat: 48.3946018, lng: 19.991031 },
    { lat: 48.3950531, lng: 19.9911303 },
    { lat: 48.3952254, lng: 19.9909955 },
    { lat: 48.3959368, lng: 19.9906652 },
    { lat: 48.3963907, lng: 19.9910049 },
    { lat: 48.3965947, lng: 19.9907424 },
    { lat: 48.3967995, lng: 19.9906286 },
    { lat: 48.3968959, lng: 19.9908601 },
    { lat: 48.3970931, lng: 19.9908914 },
    { lat: 48.3971244, lng: 19.9908264 },
    { lat: 48.3971419, lng: 19.9910019 },
    { lat: 48.3974059, lng: 19.9910931 },
    { lat: 48.3974155, lng: 19.9909451 },
    { lat: 48.3976014, lng: 19.9909476 },
    { lat: 48.3977761, lng: 19.9911905 },
    { lat: 48.3980075, lng: 19.9911455 },
    { lat: 48.3980274, lng: 19.9914835 },
    { lat: 48.3979649, lng: 19.9917666 },
    { lat: 48.3977563, lng: 19.9918673 },
    { lat: 48.3978131, lng: 19.9921462 },
    { lat: 48.3979401, lng: 19.9923035 },
    { lat: 48.3981, lng: 19.992087 },
    { lat: 48.3981946, lng: 19.9931049 },
    { lat: 48.3983428, lng: 19.9931724 },
    { lat: 48.3986107, lng: 19.9929527 },
    { lat: 48.3987948, lng: 19.9929122 },
    { lat: 48.3988842, lng: 19.992546 },
    { lat: 48.3990253, lng: 19.9924177 },
    { lat: 48.3991076, lng: 19.9921522 },
    { lat: 48.3992295, lng: 19.9919978 },
    { lat: 48.3991832, lng: 19.9917547 },
    { lat: 48.3989669, lng: 19.9918097 },
    { lat: 48.3987926, lng: 19.9920038 },
    { lat: 48.3985371, lng: 19.9917669 },
    { lat: 48.3985488, lng: 19.9913482 },
    { lat: 48.3984642, lng: 19.9909989 },
    { lat: 48.3988554, lng: 19.9910071 },
    { lat: 48.3988753, lng: 19.9907564 },
    { lat: 48.3987734, lng: 19.9900231 },
    { lat: 48.3986743, lng: 19.9899967 },
    { lat: 48.3985886, lng: 19.9898354 },
    { lat: 48.3985829, lng: 19.9894074 },
    { lat: 48.3986635, lng: 19.989181 },
    { lat: 48.3989879, lng: 19.988751 },
    { lat: 48.3992025, lng: 19.9888472 },
    { lat: 48.3994081, lng: 19.9890992 },
    { lat: 48.3996197, lng: 19.9891461 },
    { lat: 48.3997905, lng: 19.9890869 },
    { lat: 48.3999265, lng: 19.9891842 },
    { lat: 48.3996404, lng: 19.9896932 },
    { lat: 48.3993324, lng: 19.9897905 },
    { lat: 48.3992807, lng: 19.9899791 },
    { lat: 48.3993345, lng: 19.9901579 },
    { lat: 48.3995198, lng: 19.9903878 },
    { lat: 48.3995046, lng: 19.9906941 },
    { lat: 48.4003634, lng: 19.9897425 },
    { lat: 48.4003121, lng: 19.9893557 },
    { lat: 48.4004064, lng: 19.989134 },
    { lat: 48.4007575, lng: 19.9890673 },
    { lat: 48.4010258, lng: 19.989235 },
    { lat: 48.4016538, lng: 19.9882334 },
    { lat: 48.4019936, lng: 19.9878505 },
    { lat: 48.4025858, lng: 19.9876254 },
    { lat: 48.4031867, lng: 19.9883067 },
    { lat: 48.4040034, lng: 19.9870786 },
    { lat: 48.4050064, lng: 19.9847614 },
    { lat: 48.4052325, lng: 19.9835306 },
    { lat: 48.4056564, lng: 19.9825496 },
    { lat: 48.4059701, lng: 19.9816831 },
    { lat: 48.4069672, lng: 19.9799494 },
    { lat: 48.40659, lng: 19.9793965 },
    { lat: 48.4088663, lng: 19.9776543 },
    { lat: 48.4088732, lng: 19.9774434 },
    { lat: 48.4088377, lng: 19.9774017 },
    { lat: 48.4086491, lng: 19.9772343 },
    { lat: 48.408545, lng: 19.9772243 },
    { lat: 48.4081461, lng: 19.9767118 },
    { lat: 48.4080067, lng: 19.9766396 },
    { lat: 48.4077218, lng: 19.9762938 },
    { lat: 48.4075731, lng: 19.9762176 },
    { lat: 48.4075009, lng: 19.9761053 },
    { lat: 48.4073194, lng: 19.9754649 },
    { lat: 48.4071455, lng: 19.9752142 },
    { lat: 48.4071787, lng: 19.9749841 },
    { lat: 48.4071378, lng: 19.974898 },
    { lat: 48.4072352, lng: 19.9747515 },
    { lat: 48.4071646, lng: 19.9746285 },
    { lat: 48.4072117, lng: 19.974196 },
    { lat: 48.4071524, lng: 19.9740989 },
    { lat: 48.4069834, lng: 19.9740451 },
    { lat: 48.4069894, lng: 19.9739453 },
    { lat: 48.4070891, lng: 19.9738259 },
    { lat: 48.4070259, lng: 19.9736656 },
    { lat: 48.4070988, lng: 19.9735456 },
    { lat: 48.4070808, lng: 19.9734455 },
    { lat: 48.4070224, lng: 19.9734203 },
    { lat: 48.4069985, lng: 19.9732566 },
    { lat: 48.4068278, lng: 19.973296 },
    { lat: 48.4067871, lng: 19.9732087 },
    { lat: 48.4067296, lng: 19.9732124 },
    { lat: 48.4066782, lng: 19.9733237 },
    { lat: 48.4066436, lng: 19.9733054 },
    { lat: 48.4066597, lng: 19.9731262 },
    { lat: 48.4069037, lng: 19.9730356 },
    { lat: 48.4069376, lng: 19.9729621 },
    { lat: 48.406717, lng: 19.972702 },
    { lat: 48.4068005, lng: 19.9725725 },
    { lat: 48.4067566, lng: 19.9724884 },
    { lat: 48.4067915, lng: 19.9724092 },
    { lat: 48.4067186, lng: 19.9721258 },
    { lat: 48.4065988, lng: 19.97214 },
    { lat: 48.4064684, lng: 19.9719873 },
    { lat: 48.4063487, lng: 19.972171 },
    { lat: 48.4062811, lng: 19.9719275 },
    { lat: 48.4063326, lng: 19.9718389 },
    { lat: 48.4063417, lng: 19.9717373 },
    { lat: 48.4063017, lng: 19.9717234 },
    { lat: 48.4062184, lng: 19.9717509 },
    { lat: 48.4061406, lng: 19.97196 },
    { lat: 48.4060666, lng: 19.9719416 },
    { lat: 48.4060649, lng: 19.9718219 },
    { lat: 48.405981, lng: 19.9716746 },
    { lat: 48.4060256, lng: 19.9715356 },
    { lat: 48.4060929, lng: 19.9715817 },
    { lat: 48.4061817, lng: 19.9714428 },
    { lat: 48.4059802, lng: 19.9713932 },
    { lat: 48.4059711, lng: 19.9713363 },
    { lat: 48.4060861, lng: 19.9712572 },
    { lat: 48.4061364, lng: 19.9710355 },
    { lat: 48.4060584, lng: 19.9707926 },
    { lat: 48.4060014, lng: 19.9708027 },
    { lat: 48.4059802, lng: 19.9709146 },
    { lat: 48.4058579, lng: 19.9709805 },
    { lat: 48.4058392, lng: 19.9706441 },
    { lat: 48.4056505, lng: 19.9707599 },
    { lat: 48.4056089, lng: 19.9707086 },
    { lat: 48.4056427, lng: 19.9703647 },
    { lat: 48.4058121, lng: 19.9703499 },
    { lat: 48.4058132, lng: 19.9701587 },
    { lat: 48.4057898, lng: 19.9700858 },
    { lat: 48.4056596, lng: 19.9701496 },
    { lat: 48.405565, lng: 19.9699392 },
    { lat: 48.405818, lng: 19.9695804 },
    { lat: 48.4057522, lng: 19.9693867 },
    { lat: 48.4056683, lng: 19.9692944 },
    { lat: 48.4055504, lng: 19.9693038 },
    { lat: 48.4054314, lng: 19.9692018 },
    { lat: 48.4054278, lng: 19.969011 },
    { lat: 48.4051611, lng: 19.9687014 },
    { lat: 48.4051978, lng: 19.9685806 },
    { lat: 48.4050907, lng: 19.9685043 },
    { lat: 48.4051233, lng: 19.9683931 },
    { lat: 48.405002, lng: 19.968321 },
    { lat: 48.4050315, lng: 19.9681654 },
    { lat: 48.4050058, lng: 19.9680668 },
    { lat: 48.4048103, lng: 19.9681603 },
    { lat: 48.4048467, lng: 19.9677413 },
    { lat: 48.4048045, lng: 19.9676485 },
    { lat: 48.4046925, lng: 19.9677401 },
    { lat: 48.4046154, lng: 19.9675478 },
    { lat: 48.4046471, lng: 19.9674707 },
    { lat: 48.4047365, lng: 19.9675316 },
    { lat: 48.4047788, lng: 19.9674439 },
    { lat: 48.4047067, lng: 19.9672012 },
    { lat: 48.4046016, lng: 19.9672442 },
    { lat: 48.4045071, lng: 19.9671427 },
    { lat: 48.404694, lng: 19.9668727 },
    { lat: 48.4045713, lng: 19.9667955 },
    { lat: 48.4045529, lng: 19.9666579 },
    { lat: 48.4047443, lng: 19.966546 },
    { lat: 48.4046052, lng: 19.9663764 },
    { lat: 48.4046374, lng: 19.9662063 },
    { lat: 48.4046109, lng: 19.9661369 },
    { lat: 48.4045218, lng: 19.9663461 },
    { lat: 48.4043982, lng: 19.9662497 },
    { lat: 48.4045684, lng: 19.9659304 },
    { lat: 48.4045469, lng: 19.9658477 },
    { lat: 48.404479, lng: 19.9659502 },
    { lat: 48.4043949, lng: 19.9658746 },
    { lat: 48.4043481, lng: 19.9657 },
    { lat: 48.4044109, lng: 19.9655615 },
    { lat: 48.4044211, lng: 19.9656466 },
    { lat: 48.4045231, lng: 19.9657145 },
    { lat: 48.4045426, lng: 19.965484 },
    { lat: 48.4044478, lng: 19.965425 },
    { lat: 48.4044507, lng: 19.9651187 },
    { lat: 48.4042627, lng: 19.9649392 },
    { lat: 48.404372, lng: 19.9648565 },
    { lat: 48.4042975, lng: 19.9647473 },
    { lat: 48.4042766, lng: 19.964599 },
    { lat: 48.4043336, lng: 19.9643996 },
    { lat: 48.4043885, lng: 19.9645309 },
    { lat: 48.4044332, lng: 19.9645234 },
    { lat: 48.4044795, lng: 19.9647349 },
    { lat: 48.4045703, lng: 19.9644036 },
    { lat: 48.4044062, lng: 19.9643079 },
    { lat: 48.4044204, lng: 19.9641658 },
    { lat: 48.4043586, lng: 19.9638771 },
    { lat: 48.404405, lng: 19.9635898 },
    { lat: 48.404519, lng: 19.9636221 },
    { lat: 48.4046155, lng: 19.9635051 },
    { lat: 48.4045943, lng: 19.9633228 },
    { lat: 48.4047896, lng: 19.963279 },
    { lat: 48.4047292, lng: 19.9630522 },
    { lat: 48.4045148, lng: 19.9626171 },
    { lat: 48.4045601, lng: 19.9625709 },
    { lat: 48.4047486, lng: 19.9627412 },
    { lat: 48.4048179, lng: 19.9626495 },
    { lat: 48.404916, lng: 19.9626472 },
    { lat: 48.4049405, lng: 19.9627746 },
    { lat: 48.4050489, lng: 19.9626465 },
    { lat: 48.4051115, lng: 19.9628545 },
    { lat: 48.4051208, lng: 19.9628853 },
    { lat: 48.4054032, lng: 19.9627683 },
    { lat: 48.405482, lng: 19.9625569 },
    { lat: 48.4056327, lng: 19.9624134 },
    { lat: 48.4055614, lng: 19.9623791 },
    { lat: 48.4054861, lng: 19.9621463 },
    { lat: 48.4055778, lng: 19.9620273 },
    { lat: 48.4055997, lng: 19.9618468 },
    { lat: 48.4056889, lng: 19.9617734 },
    { lat: 48.4057427, lng: 19.9616336 },
    { lat: 48.4057479, lng: 19.9614125 },
    { lat: 48.4056166, lng: 19.9613712 },
    { lat: 48.4055952, lng: 19.9609726 },
    { lat: 48.4056518, lng: 19.9609771 },
    { lat: 48.4057453, lng: 19.9612157 },
    { lat: 48.40582, lng: 19.9609345 },
    { lat: 48.4057187, lng: 19.9608282 },
    { lat: 48.4057161, lng: 19.9607459 },
    { lat: 48.4056224, lng: 19.960791 },
    { lat: 48.4055049, lng: 19.9604122 },
    { lat: 48.4055529, lng: 19.9603754 },
    { lat: 48.4056634, lng: 19.9604545 },
    { lat: 48.4057105, lng: 19.9601554 },
    { lat: 48.4058294, lng: 19.9600157 },
    { lat: 48.4059712, lng: 19.9595568 },
    { lat: 48.4058254, lng: 19.9594704 },
    { lat: 48.4058822, lng: 19.9592686 },
    { lat: 48.4058067, lng: 19.9591574 },
    { lat: 48.4058845, lng: 19.9590046 },
    { lat: 48.4059412, lng: 19.9590109 },
    { lat: 48.4058564, lng: 19.9587105 },
    { lat: 48.4057991, lng: 19.9587167 },
    { lat: 48.4057899, lng: 19.958523 },
    { lat: 48.4058757, lng: 19.9583697 },
    { lat: 48.4058679, lng: 19.9581844 },
    { lat: 48.4059089, lng: 19.9581719 },
    { lat: 48.4060796, lng: 19.9584523 },
    { lat: 48.4061229, lng: 19.9578526 },
    { lat: 48.4062147, lng: 19.9578633 },
    { lat: 48.4062665, lng: 19.9575431 },
    { lat: 48.4062245, lng: 19.9569827 },
    { lat: 48.4063783, lng: 19.9569953 },
    { lat: 48.4064339, lng: 19.9568159 },
    { lat: 48.4065312, lng: 19.9568555 },
    { lat: 48.4065461, lng: 19.9569402 },
    { lat: 48.4064728, lng: 19.9569916 },
    { lat: 48.4065012, lng: 19.9571548 },
    { lat: 48.4066677, lng: 19.9571513 },
    { lat: 48.4068363, lng: 19.9569011 },
    { lat: 48.4067313, lng: 19.9566853 },
    { lat: 48.4066046, lng: 19.9565932 },
    { lat: 48.4067562, lng: 19.9564227 },
    { lat: 48.4066869, lng: 19.9563021 },
    { lat: 48.4066876, lng: 19.9561566 },
    { lat: 48.4066112, lng: 19.9559267 },
    { lat: 48.4067946, lng: 19.9557589 },
    { lat: 48.406737, lng: 19.955675 },
    { lat: 48.4067797, lng: 19.9556249 },
    { lat: 48.4069327, lng: 19.9556198 },
    { lat: 48.4068603, lng: 19.955398 },
    { lat: 48.4066936, lng: 19.9552651 },
    { lat: 48.406033, lng: 19.9550461 },
    { lat: 48.405932, lng: 19.9548399 },
    { lat: 48.4052503, lng: 19.9539082 },
    { lat: 48.4051035, lng: 19.9535525 },
    { lat: 48.4049416, lng: 19.9534797 },
    { lat: 48.4048643, lng: 19.953278 },
    { lat: 48.40466, lng: 19.9531369 },
    { lat: 48.4045715, lng: 19.952938 },
    { lat: 48.4044482, lng: 19.9530012 },
    { lat: 48.4041397, lng: 19.9529021 },
    { lat: 48.4038654, lng: 19.9525459 },
    { lat: 48.4037855, lng: 19.9525454 },
    { lat: 48.4037138, lng: 19.9524225 },
    { lat: 48.4034158, lng: 19.952172 },
    { lat: 48.403275, lng: 19.9517743 },
    { lat: 48.4031371, lng: 19.9516287 },
    { lat: 48.4030927, lng: 19.9514071 },
    { lat: 48.402989, lng: 19.9512702 },
    { lat: 48.402892, lng: 19.9510041 },
    { lat: 48.4029335, lng: 19.9509196 },
    { lat: 48.4027596, lng: 19.9505389 },
    { lat: 48.4027139, lng: 19.9502072 },
    { lat: 48.4023668, lng: 19.9496456 },
    { lat: 48.4021034, lng: 19.9494673 },
    { lat: 48.40111, lng: 19.9487015 },
    { lat: 48.4005474, lng: 19.9499366 },
    { lat: 48.4000482, lng: 19.9544107 },
    { lat: 48.3992291, lng: 19.9544902 },
    { lat: 48.3975039, lng: 19.9520373 },
    { lat: 48.3958422, lng: 19.951165 },
    { lat: 48.3952028, lng: 19.9508838 },
    { lat: 48.3944133, lng: 19.9506472 },
    { lat: 48.3941067, lng: 19.9515436 },
    { lat: 48.3937475, lng: 19.9521338 },
    { lat: 48.3933497, lng: 19.9532931 },
    { lat: 48.3931426, lng: 19.9544641 },
    { lat: 48.3919468, lng: 19.9578469 },
    { lat: 48.3917189, lng: 19.9586116 },
    { lat: 48.3913546, lng: 19.9589453 },
    { lat: 48.3908113, lng: 19.9600258 },
    { lat: 48.3905724, lng: 19.9607667 },
    { lat: 48.3899516, lng: 19.9610296 },
    { lat: 48.3897616, lng: 19.9612533 },
    { lat: 48.3896489, lng: 19.9612775 },
    { lat: 48.3895444, lng: 19.9615746 },
    { lat: 48.3891638, lng: 19.961902 },
    { lat: 48.3888242, lng: 19.9625487 },
    { lat: 48.3888667, lng: 19.9626778 },
    { lat: 48.3888687, lng: 19.9633886 },
    { lat: 48.3886985, lng: 19.9635722 },
    { lat: 48.3886949, lng: 19.9640428 },
    { lat: 48.3887511, lng: 19.9640718 },
    { lat: 48.3885958, lng: 19.9642345 },
    { lat: 48.3885265, lng: 19.9641645 },
    { lat: 48.3883158, lng: 19.9646458 },
    { lat: 48.3882122, lng: 19.9646957 },
    { lat: 48.3881449, lng: 19.9649183 },
    { lat: 48.3876321, lng: 19.9647274 },
    { lat: 48.3875173, lng: 19.9648592 },
    { lat: 48.3874111, lng: 19.9648254 },
    { lat: 48.3873038, lng: 19.9650079 },
    { lat: 48.3873756, lng: 19.9650808 },
    { lat: 48.3873807, lng: 19.9652004 },
    { lat: 48.3872887, lng: 19.9653127 },
    { lat: 48.3872917, lng: 19.9653851 },
    { lat: 48.3871697, lng: 19.9654028 },
    { lat: 48.3871551, lng: 19.9652479 },
    { lat: 48.3870741, lng: 19.9653307 },
    { lat: 48.3870563, lng: 19.965516 },
    { lat: 48.3871283, lng: 19.9657005 },
    { lat: 48.3870457, lng: 19.9657736 },
    { lat: 48.3869438, lng: 19.9657872 },
    { lat: 48.3868672, lng: 19.9657053 },
    { lat: 48.386796, lng: 19.9657588 },
    { lat: 48.3868834, lng: 19.965961 },
    { lat: 48.386747, lng: 19.9660343 },
    { lat: 48.3866559, lng: 19.9659331 },
    { lat: 48.386727, lng: 19.9656942 },
    { lat: 48.3866694, lng: 19.9655838 },
    { lat: 48.3865268, lng: 19.9657066 },
    { lat: 48.3865512, lng: 19.9659094 },
    { lat: 48.3863592, lng: 19.9658172 },
    { lat: 48.3863277, lng: 19.9658985 },
    { lat: 48.3863445, lng: 19.9661606 },
    { lat: 48.3862546, lng: 19.9662171 },
    { lat: 48.3863356, lng: 19.9664577 },
    { lat: 48.3861687, lng: 19.9665013 },
    { lat: 48.3863052, lng: 19.9666924 },
    { lat: 48.3860214, lng: 19.9668588 },
    { lat: 48.3859933, lng: 19.9666194 },
    { lat: 48.3860347, lng: 19.9664546 },
    { lat: 48.3859551, lng: 19.9664356 },
    { lat: 48.3857147, lng: 19.9669299 },
    { lat: 48.3858448, lng: 19.9669983 },
    { lat: 48.3859052, lng: 19.9668802 },
    { lat: 48.3859483, lng: 19.9669671 },
    { lat: 48.3859416, lng: 19.9670472 },
    { lat: 48.3858744, lng: 19.9671103 },
    { lat: 48.3858951, lng: 19.9671987 },
    { lat: 48.3857665, lng: 19.9671954 },
    { lat: 48.3856897, lng: 19.9670819 },
    { lat: 48.3856622, lng: 19.9672337 },
    { lat: 48.3855076, lng: 19.9672523 },
    { lat: 48.3854996, lng: 19.9676752 },
    { lat: 48.3855671, lng: 19.9676978 },
    { lat: 48.385578, lng: 19.9677719 },
    { lat: 48.3854707, lng: 19.9679239 },
    { lat: 48.3853466, lng: 19.9679485 },
    { lat: 48.3853734, lng: 19.9681515 },
    { lat: 48.3853159, lng: 19.9683104 },
    { lat: 48.3854077, lng: 19.9687773 },
    { lat: 48.385334, lng: 19.9689257 },
    { lat: 48.3855185, lng: 19.9691583 },
    { lat: 48.3853673, lng: 19.96934 },
    { lat: 48.3854551, lng: 19.969551 },
    { lat: 48.3855872, lng: 19.9695607 },
    { lat: 48.3854763, lng: 19.969706 },
    { lat: 48.3854057, lng: 19.969662 },
    { lat: 48.3853269, lng: 19.9697401 },
    { lat: 48.3853871, lng: 19.9699143 },
    { lat: 48.3855085, lng: 19.9699875 },
    { lat: 48.3854366, lng: 19.9702286 },
    { lat: 48.3855863, lng: 19.9702806 },
    { lat: 48.3857006, lng: 19.9701587 },
    { lat: 48.3857121, lng: 19.9704092 },
    { lat: 48.3856313, lng: 19.9704893 },
    { lat: 48.3855163, lng: 19.9704116 },
    { lat: 48.3854764, lng: 19.9705838 },
    { lat: 48.3857317, lng: 19.9708716 },
    { lat: 48.3857672, lng: 19.9710685 },
    { lat: 48.385728, lng: 19.9711293 },
    { lat: 48.3856697, lng: 19.9711068 },
    { lat: 48.3855665, lng: 19.97133 },
    { lat: 48.3853696, lng: 19.9714966 },
    { lat: 48.3854183, lng: 19.9716869 },
    { lat: 48.3855226, lng: 19.9717804 },
    { lat: 48.3853495, lng: 19.9719525 },
    { lat: 48.3852887, lng: 19.9717924 },
    { lat: 48.3851793, lng: 19.9719531 },
    { lat: 48.3852993, lng: 19.9721001 },
    { lat: 48.3852438, lng: 19.9721986 },
    { lat: 48.3851422, lng: 19.9720846 },
    { lat: 48.3851128, lng: 19.9722164 },
    { lat: 48.3851616, lng: 19.9723323 },
    { lat: 48.3850228, lng: 19.9724327 },
    { lat: 48.3849915, lng: 19.972223 },
    { lat: 48.3847279, lng: 19.9724934 },
    { lat: 48.3846333, lng: 19.9723323 },
    { lat: 48.3846026, lng: 19.9723487 },
    { lat: 48.3845769, lng: 19.9725663 },
    { lat: 48.3846196, lng: 19.9726448 },
    { lat: 48.3846939, lng: 19.9725934 },
    { lat: 48.3847135, lng: 19.972633 },
    { lat: 48.3845509, lng: 19.9729398 },
    { lat: 48.3846157, lng: 19.9730102 },
    { lat: 48.3844903, lng: 19.9730537 },
    { lat: 48.384445, lng: 19.9729648 },
    { lat: 48.3844371, lng: 19.9731236 },
    { lat: 48.3844898, lng: 19.9732245 },
    { lat: 48.3843617, lng: 19.9731815 },
    { lat: 48.3843852, lng: 19.9733929 },
    { lat: 48.3843537, lng: 19.9734955 },
    { lat: 48.3844354, lng: 19.9735409 },
    { lat: 48.3844315, lng: 19.973633 },
    { lat: 48.3845094, lng: 19.9736387 },
    { lat: 48.3844741, lng: 19.9739189 },
    { lat: 48.3844205, lng: 19.973959 },
    { lat: 48.3844469, lng: 19.9741592 },
    { lat: 48.384341, lng: 19.9741562 },
    { lat: 48.3842453, lng: 19.9742376 },
    { lat: 48.3842274, lng: 19.9743499 },
    { lat: 48.3842769, lng: 19.9744246 },
    { lat: 48.3842054, lng: 19.9745703 },
    { lat: 48.3842444, lng: 19.9746952 },
    { lat: 48.3841866, lng: 19.9747404 },
    { lat: 48.3841338, lng: 19.9749538 },
    { lat: 48.3840165, lng: 19.9750044 },
    { lat: 48.3839904, lng: 19.9748686 },
    { lat: 48.3838387, lng: 19.9748526 },
    { lat: 48.383823, lng: 19.974667 },
    { lat: 48.3837649, lng: 19.9747059 },
    { lat: 48.3837294, lng: 19.9749045 },
    { lat: 48.3835209, lng: 19.9750874 },
    { lat: 48.3834359, lng: 19.9750749 },
    { lat: 48.3833077, lng: 19.9749224 },
    { lat: 48.383266, lng: 19.9752659 },
    { lat: 48.3831532, lng: 19.9753181 },
    { lat: 48.3830779, lng: 19.9752175 },
    { lat: 48.3829977, lng: 19.9753194 },
    { lat: 48.3828866, lng: 19.9752971 },
    { lat: 48.3829067, lng: 19.9754004 },
    { lat: 48.3830247, lng: 19.9755266 },
    { lat: 48.3830451, lng: 19.9756626 },
    { lat: 48.3828803, lng: 19.9759245 },
    { lat: 48.382865, lng: 19.9756698 },
    { lat: 48.3825763, lng: 19.9756077 },
    { lat: 48.3820973, lng: 19.9750945 },
    { lat: 48.3818353, lng: 19.974949 },
    { lat: 48.3817268, lng: 19.975257 },
    { lat: 48.381475, lng: 19.9754823 },
    { lat: 48.3808724, lng: 19.9751386 },
    { lat: 48.3805119, lng: 19.9748186 },
    { lat: 48.3803567, lng: 19.9745318 },
    { lat: 48.3805608, lng: 19.9742945 },
    { lat: 48.3806699, lng: 19.9737876 },
    { lat: 48.3806405, lng: 19.973227 },
    { lat: 48.3802109, lng: 19.9719405 },
    { lat: 48.3801865, lng: 19.9719716 },
    { lat: 48.3799343, lng: 19.9711271 },
    { lat: 48.3796729, lng: 19.970702 },
    { lat: 48.3795145, lng: 19.9698585 },
    { lat: 48.3795474, lng: 19.9688529 },
    { lat: 48.3792779, lng: 19.9674609 },
    { lat: 48.3788748, lng: 19.9669622 },
    { lat: 48.3777604, lng: 19.9667168 },
    { lat: 48.3768461, lng: 19.9666216 },
    { lat: 48.3769393, lng: 19.9663203 },
    { lat: 48.376987, lng: 19.9656918 },
    { lat: 48.3769467, lng: 19.9655653 },
    { lat: 48.3769699, lng: 19.9653434 },
    { lat: 48.3768816, lng: 19.9645354 },
    { lat: 48.3769245, lng: 19.9642447 },
    { lat: 48.3768897, lng: 19.9640081 },
    { lat: 48.3769196, lng: 19.9634876 },
    { lat: 48.3768059, lng: 19.9634896 },
    { lat: 48.3768059, lng: 19.9633836 },
    { lat: 48.3769073, lng: 19.9631475 },
    { lat: 48.3769012, lng: 19.9629277 },
    { lat: 48.3768067, lng: 19.9629243 },
    { lat: 48.3767674, lng: 19.9627725 },
    { lat: 48.3768324, lng: 19.9626841 },
    { lat: 48.3768001, lng: 19.9625086 },
    { lat: 48.3768296, lng: 19.9623804 },
    { lat: 48.3767205, lng: 19.96204 },
    { lat: 48.3765263, lng: 19.9619189 },
    { lat: 48.3765254, lng: 19.9618181 },
    { lat: 48.3761348, lng: 19.9619886 },
    { lat: 48.3760736, lng: 19.9618425 },
    { lat: 48.37603, lng: 19.9618541 },
    { lat: 48.3759623, lng: 19.961638 },
    { lat: 48.3759348, lng: 19.9604565 },
    { lat: 48.3760112, lng: 19.9600814 },
    { lat: 48.3759664, lng: 19.9593164 },
    { lat: 48.3761206, lng: 19.9584931 },
    { lat: 48.376114, lng: 19.9583382 },
    { lat: 48.3759081, lng: 19.9583979 },
    { lat: 48.3754523, lng: 19.9581421 },
    { lat: 48.3747391, lng: 19.9580863 },
    { lat: 48.3729478, lng: 19.9570732 },
    { lat: 48.3717412, lng: 19.9567266 },
    { lat: 48.3709766, lng: 19.9560986 },
    { lat: 48.3701882, lng: 19.9558881 },
    { lat: 48.3694457, lng: 19.9558097 },
    { lat: 48.3683093, lng: 19.9559981 },
    { lat: 48.3666425, lng: 19.9557977 },
    { lat: 48.3650627, lng: 19.9555251 },
    { lat: 48.3649671, lng: 19.9554202 },
    { lat: 48.3649133, lng: 19.9553764 },
    { lat: 48.3648399, lng: 19.9561933 },
    { lat: 48.364658, lng: 19.9603214 },
    { lat: 48.362588, lng: 19.9576244 },
    { lat: 48.3622653, lng: 19.9571066 },
    { lat: 48.3618256, lng: 19.9566751 },
    { lat: 48.3616158, lng: 19.9563753 },
    { lat: 48.3610495, lng: 19.9555662 },
    { lat: 48.3604264, lng: 19.9548476 },
    { lat: 48.3588719, lng: 19.9526203 },
    { lat: 48.3582989, lng: 19.9519225 },
    { lat: 48.3581519, lng: 19.9517435 },
    { lat: 48.3581009, lng: 19.9516814 },
    { lat: 48.3577769, lng: 19.9511616 },
    { lat: 48.3568453, lng: 19.9499732 },
    { lat: 48.3561131, lng: 19.9489142 },
    { lat: 48.3550624, lng: 19.9476292 },
    { lat: 48.3532088, lng: 19.9452104 },
    { lat: 48.35285, lng: 19.944824 },
    { lat: 48.3525875, lng: 19.9443469 },
    { lat: 48.3517294, lng: 19.943139 },
    { lat: 48.3511241, lng: 19.9424458 },
    { lat: 48.351, lng: 19.9423639 },
    { lat: 48.3495141, lng: 19.9404562 },
    { lat: 48.3491963, lng: 19.9398471 },
    { lat: 48.3469863, lng: 19.9370819 },
    { lat: 48.3443066, lng: 19.9334244 },
    { lat: 48.3433552, lng: 19.9322516 },
    { lat: 48.342228, lng: 19.9306481 },
    { lat: 48.341205, lng: 19.9293825 },
    { lat: 48.3406927, lng: 19.9324472 },
    { lat: 48.3404971, lng: 19.9333285 },
    { lat: 48.338845, lng: 19.9338179 },
    { lat: 48.3374893, lng: 19.9342845 },
    { lat: 48.3360803, lng: 19.9348723 },
    { lat: 48.3355765, lng: 19.9351537 },
    { lat: 48.3346258, lng: 19.9354439 },
    { lat: 48.3340076, lng: 19.9358994 },
    { lat: 48.3334641, lng: 19.9360472 },
    { lat: 48.3321842, lng: 19.935462 },
    { lat: 48.3321036, lng: 19.9349514 },
    { lat: 48.3310125, lng: 19.932851 },
    { lat: 48.3311816, lng: 19.9324598 },
    { lat: 48.3313978, lng: 19.9312722 },
    { lat: 48.3314272, lng: 19.9303751 },
    { lat: 48.3311078, lng: 19.9294124 },
    { lat: 48.3305253, lng: 19.9285932 },
    { lat: 48.3301912, lng: 19.9282876 },
    { lat: 48.329336, lng: 19.9280753 },
    { lat: 48.3276289, lng: 19.9281215 },
    { lat: 48.3278217, lng: 19.9277553 },
    { lat: 48.3278954, lng: 19.9269521 },
    { lat: 48.3276828, lng: 19.9255363 },
    { lat: 48.3276193, lng: 19.9245764 },
    { lat: 48.3260726, lng: 19.9263142 },
    { lat: 48.3257974, lng: 19.9264646 },
    { lat: 48.323484, lng: 19.9262945 },
    { lat: 48.3231816, lng: 19.9260478 },
    { lat: 48.3228294, lng: 19.9253745 },
    { lat: 48.3220915, lng: 19.9247411 },
    { lat: 48.3202478, lng: 19.9228549 },
    { lat: 48.3197573, lng: 19.9207401 },
    { lat: 48.3177664, lng: 19.9212595 },
    { lat: 48.3165309, lng: 19.9223479 },
    { lat: 48.3162415, lng: 19.924747 },
    { lat: 48.3162016, lng: 19.9261814 },
    { lat: 48.3162495, lng: 19.9261963 },
    { lat: 48.3168398, lng: 19.9276402 },
    { lat: 48.3177466, lng: 19.9291425 },
    { lat: 48.3187069, lng: 19.929762 },
    { lat: 48.318734, lng: 19.9299212 },
    { lat: 48.3188123, lng: 19.9299352 },
    { lat: 48.3215599, lng: 19.9304319 },
    { lat: 48.3225133, lng: 19.9323625 },
    { lat: 48.3228813, lng: 19.9338329 },
    { lat: 48.3231137, lng: 19.9350792 },
    { lat: 48.3239205, lng: 19.9359274 },
    { lat: 48.3263356, lng: 19.9394058 },
    { lat: 48.3265848, lng: 19.9401777 },
    { lat: 48.3267424, lng: 19.9424228 },
    { lat: 48.3274197, lng: 19.9439077 },
    { lat: 48.325918, lng: 19.9463083 },
    { lat: 48.3254887, lng: 19.9472082 },
    { lat: 48.3250363, lng: 19.948832 },
    { lat: 48.3246752, lng: 19.9507788 },
    { lat: 48.324101, lng: 19.9512096 },
    { lat: 48.3234179, lng: 19.9515426 },
    { lat: 48.322893, lng: 19.9523903 },
    { lat: 48.3223809, lng: 19.9536197 },
    { lat: 48.3223636, lng: 19.95369 },
    { lat: 48.3227093, lng: 19.9542131 },
    { lat: 48.3232547, lng: 19.9554097 },
    { lat: 48.3234509, lng: 19.9557095 },
    { lat: 48.3237516, lng: 19.9565253 },
    { lat: 48.3239278, lng: 19.9573881 },
    { lat: 48.3241897, lng: 19.9579578 },
    { lat: 48.3246653, lng: 19.9585435 },
    { lat: 48.3249121, lng: 19.9589418 },
    { lat: 48.3251387, lng: 19.9597683 },
    { lat: 48.3251089, lng: 19.9610281 },
    { lat: 48.3251547, lng: 19.9618949 },
    { lat: 48.3252304, lng: 19.9627786 },
    { lat: 48.3253983, lng: 19.9631736 },
    { lat: 48.3256253, lng: 19.9635338 },
    { lat: 48.3261921, lng: 19.9639346 },
    { lat: 48.3266125, lng: 19.9640226 },
    { lat: 48.3275798, lng: 19.9638262 },
    { lat: 48.3282562, lng: 19.9640509 },
    { lat: 48.3287642, lng: 19.9646566 },
    { lat: 48.3288869, lng: 19.9651069 },
    { lat: 48.3288999, lng: 19.9656005 },
    { lat: 48.3286659, lng: 19.9667528 },
    { lat: 48.3284453, lng: 19.967444 },
    { lat: 48.3283897, lng: 19.9678872 },
    { lat: 48.3285956, lng: 19.9693419 },
    { lat: 48.3286139, lng: 19.9704631 },
    { lat: 48.3285525, lng: 19.9709306 },
    { lat: 48.3284479, lng: 19.9712818 },
    { lat: 48.327743, lng: 19.9727451 },
    { lat: 48.3276067, lng: 19.9733351 },
    { lat: 48.3275752, lng: 19.9740004 },
    { lat: 48.3276262, lng: 19.9744575 },
    { lat: 48.3283961, lng: 19.9762264 },
    { lat: 48.3286291, lng: 19.9768853 },
    { lat: 48.3294736, lng: 19.9787402 },
    { lat: 48.3301626, lng: 19.9796099 },
    { lat: 48.3319327, lng: 19.9828391 },
    { lat: 48.3325392, lng: 19.9850631 },
    { lat: 48.3329956, lng: 19.9871998 },
    { lat: 48.3335003, lng: 19.9884589 },
    { lat: 48.3334907, lng: 19.9884949 },
    { lat: 48.334078, lng: 19.9911442 },
    { lat: 48.3358252, lng: 19.9995639 },
    { lat: 48.3414998, lng: 19.998933 },
    { lat: 48.3413201, lng: 19.9991052 },
    { lat: 48.3412339, lng: 19.9993332 },
    { lat: 48.3408857, lng: 20.0011787 },
    { lat: 48.3406638, lng: 20.0051933 },
    { lat: 48.3405225, lng: 20.0060646 },
    { lat: 48.3405522, lng: 20.0067434 },
    { lat: 48.3405108, lng: 20.0070448 },
    { lat: 48.3405358, lng: 20.0075338 },
    { lat: 48.3405035, lng: 20.007972 },
    { lat: 48.3402988, lng: 20.0085374 },
    { lat: 48.3401415, lng: 20.0094612 },
    { lat: 48.3402009, lng: 20.0102862 },
    { lat: 48.3404226, lng: 20.0107075 },
    { lat: 48.3406304, lng: 20.0114179 },
    { lat: 48.3406836, lng: 20.0119589 },
    { lat: 48.3408254, lng: 20.0122284 },
    { lat: 48.341156, lng: 20.0135801 },
    { lat: 48.3412166, lng: 20.0143261 },
    { lat: 48.3418401, lng: 20.0168644 },
    { lat: 48.3420357, lng: 20.0183805 },
    { lat: 48.3422692, lng: 20.0196547 },
    { lat: 48.343197, lng: 20.0230804 },
    { lat: 48.342586, lng: 20.0237148 },
    { lat: 48.3424009, lng: 20.0242995 },
    { lat: 48.3423425, lng: 20.0247076 },
    { lat: 48.3424092, lng: 20.0257083 },
    { lat: 48.3427409, lng: 20.0271479 },
    { lat: 48.3429891, lng: 20.0276911 },
    { lat: 48.3432215, lng: 20.0287764 },
    { lat: 48.3434194, lng: 20.0294387 },
    { lat: 48.3438375, lng: 20.0300112 },
    { lat: 48.344325, lng: 20.0301409 },
    { lat: 48.3444493, lng: 20.030286 },
    { lat: 48.3444532, lng: 20.0304004 },
    { lat: 48.3442063, lng: 20.0306382 },
    { lat: 48.3442533, lng: 20.0307404 },
    { lat: 48.3443549, lng: 20.0307485 },
    { lat: 48.344502, lng: 20.03119 },
    { lat: 48.3444868, lng: 20.0313954 },
    { lat: 48.3443758, lng: 20.0315989 },
    { lat: 48.3445324, lng: 20.0318366 },
    { lat: 48.3446096, lng: 20.0316909 },
    { lat: 48.3446732, lng: 20.031704 },
    { lat: 48.3448636, lng: 20.0319446 },
    { lat: 48.3450973, lng: 20.0318489 },
    { lat: 48.3452659, lng: 20.03214 },
    { lat: 48.3452051, lng: 20.032462 },
    { lat: 48.3453016, lng: 20.0325644 },
    { lat: 48.345496, lng: 20.032499 },
    { lat: 48.3455409, lng: 20.0323302 },
    { lat: 48.3456986, lng: 20.0324393 },
    { lat: 48.3456839, lng: 20.0327076 },
    { lat: 48.3459717, lng: 20.0329068 },
    { lat: 48.3459588, lng: 20.0331906 },
    { lat: 48.3458778, lng: 20.0333533 },
    { lat: 48.3459257, lng: 20.0338559 },
    { lat: 48.346103, lng: 20.0340262 },
    { lat: 48.3460631, lng: 20.034236 },
    { lat: 48.3458876, lng: 20.034402 },
    { lat: 48.3459395, lng: 20.0346435 },
    { lat: 48.3459078, lng: 20.0350208 },
    { lat: 48.3459811, lng: 20.0351024 },
    { lat: 48.3459472, lng: 20.0352862 },
    { lat: 48.346028, lng: 20.0353887 },
    { lat: 48.3460184, lng: 20.0356453 },
    { lat: 48.346053, lng: 20.0356884 },
    { lat: 48.3461047, lng: 20.0355805 },
    { lat: 48.346164, lng: 20.03565 },
    { lat: 48.346076, lng: 20.0362373 },
    { lat: 48.3461914, lng: 20.0368192 },
    { lat: 48.3462559, lng: 20.0370688 },
    { lat: 48.3465019, lng: 20.0375602 },
    { lat: 48.346356, lng: 20.0378706 },
    { lat: 48.346151, lng: 20.03779 },
    { lat: 48.3461406, lng: 20.0379755 },
    { lat: 48.3460028, lng: 20.0381004 },
    { lat: 48.3458847, lng: 20.03832 },
    { lat: 48.3458635, lng: 20.0384566 },
    { lat: 48.3459571, lng: 20.0385154 },
    { lat: 48.3460546, lng: 20.0387474 },
    { lat: 48.3462421, lng: 20.0386759 },
    { lat: 48.3463841, lng: 20.0387299 },
    { lat: 48.3462855, lng: 20.0390143 },
    { lat: 48.3469174, lng: 20.0390427 },
    { lat: 48.3474161, lng: 20.0391948 },
    { lat: 48.3478484, lng: 20.0385986 },
    { lat: 48.3482021, lng: 20.0372218 },
    { lat: 48.3491162, lng: 20.0368016 },
    { lat: 48.3512148, lng: 20.0338908 },
    { lat: 48.3514171, lng: 20.033507 },
    { lat: 48.3531846, lng: 20.0356065 },
    { lat: 48.3541064, lng: 20.0378547 },
    { lat: 48.3544896, lng: 20.0379416 },
    { lat: 48.3548302, lng: 20.0378409 },
    { lat: 48.354952, lng: 20.0379024 },
    { lat: 48.3552831, lng: 20.0375518 },
    { lat: 48.3554768, lng: 20.0374411 },
    { lat: 48.3555598, lng: 20.0372977 },
    { lat: 48.3562496, lng: 20.0378182 },
    { lat: 48.3572211, lng: 20.0395027 },
    { lat: 48.3586011, lng: 20.0415846 },
    { lat: 48.359331, lng: 20.0425028 },
    { lat: 48.3600805, lng: 20.0432392 },
    { lat: 48.3607094, lng: 20.0440263 },
    { lat: 48.3613003, lng: 20.0448276 },
    { lat: 48.3619188, lng: 20.0459884 },
    { lat: 48.362156, lng: 20.0463062 },
    { lat: 48.3647664, lng: 20.0491825 },
    { lat: 48.3654891, lng: 20.050086 },
    { lat: 48.3663685, lng: 20.051678 },
    { lat: 48.3667984, lng: 20.0526133 },
    { lat: 48.3675429, lng: 20.0544522 },
    { lat: 48.3677402, lng: 20.0544294 },
    { lat: 48.3678532, lng: 20.054732 },
    { lat: 48.370001, lng: 20.0590865 },
    { lat: 48.3703126, lng: 20.059809 },
    { lat: 48.370797, lng: 20.0607 },
    { lat: 48.3713634, lng: 20.0615397 },
    { lat: 48.3724999, lng: 20.0626741 },
    { lat: 48.3737403, lng: 20.0647418 },
    { lat: 48.3748001, lng: 20.0656623 },
    { lat: 48.3753298, lng: 20.0671582 },
    { lat: 48.3756411, lng: 20.0683638 },
    { lat: 48.3757822, lng: 20.0697002 },
    { lat: 48.3762434, lng: 20.0707451 },
    { lat: 48.3767806, lng: 20.0717199 },
    { lat: 48.3769892, lng: 20.0722975 },
    { lat: 48.3772179, lng: 20.072731 },
    { lat: 48.3774624, lng: 20.0730303 },
    { lat: 48.3785755, lng: 20.0739861 },
    { lat: 48.3804731, lng: 20.0747688 },
    { lat: 48.3815182, lng: 20.0747899 },
    { lat: 48.3836043, lng: 20.0751139 },
  ],
  Gemerček: [
    { lat: 48.3335003, lng: 19.9884589 },
    { lat: 48.3329956, lng: 19.9871998 },
    { lat: 48.3325392, lng: 19.9850631 },
    { lat: 48.3319327, lng: 19.9828391 },
    { lat: 48.3301626, lng: 19.9796099 },
    { lat: 48.3294736, lng: 19.9787402 },
    { lat: 48.3286291, lng: 19.9768853 },
    { lat: 48.3283961, lng: 19.9762264 },
    { lat: 48.3276262, lng: 19.9744575 },
    { lat: 48.3275752, lng: 19.9740004 },
    { lat: 48.3276067, lng: 19.9733351 },
    { lat: 48.327743, lng: 19.9727451 },
    { lat: 48.3284479, lng: 19.9712818 },
    { lat: 48.3285525, lng: 19.9709306 },
    { lat: 48.3286139, lng: 19.9704631 },
    { lat: 48.3285956, lng: 19.9693419 },
    { lat: 48.3283897, lng: 19.9678872 },
    { lat: 48.3284453, lng: 19.967444 },
    { lat: 48.3286659, lng: 19.9667528 },
    { lat: 48.3288999, lng: 19.9656005 },
    { lat: 48.3288869, lng: 19.9651069 },
    { lat: 48.3287642, lng: 19.9646566 },
    { lat: 48.3282562, lng: 19.9640509 },
    { lat: 48.3275798, lng: 19.9638262 },
    { lat: 48.3266125, lng: 19.9640226 },
    { lat: 48.3261921, lng: 19.9639346 },
    { lat: 48.3256253, lng: 19.9635338 },
    { lat: 48.3253983, lng: 19.9631736 },
    { lat: 48.3252304, lng: 19.9627786 },
    { lat: 48.3251547, lng: 19.9618949 },
    { lat: 48.3251089, lng: 19.9610281 },
    { lat: 48.3251387, lng: 19.9597683 },
    { lat: 48.3249121, lng: 19.9589418 },
    { lat: 48.3246653, lng: 19.9585435 },
    { lat: 48.3241897, lng: 19.9579578 },
    { lat: 48.3239278, lng: 19.9573881 },
    { lat: 48.3237516, lng: 19.9565253 },
    { lat: 48.3234509, lng: 19.9557095 },
    { lat: 48.3232547, lng: 19.9554097 },
    { lat: 48.3227093, lng: 19.9542131 },
    { lat: 48.3223636, lng: 19.95369 },
    { lat: 48.3222656, lng: 19.9538488 },
    { lat: 48.3222017, lng: 19.9541465 },
    { lat: 48.3220042, lng: 19.9545088 },
    { lat: 48.3214654, lng: 19.9549004 },
    { lat: 48.3209698, lng: 19.9554306 },
    { lat: 48.3207117, lng: 19.9561947 },
    { lat: 48.3200424, lng: 19.9571768 },
    { lat: 48.3196614, lng: 19.9581193 },
    { lat: 48.3187807, lng: 19.9600095 },
    { lat: 48.3184298, lng: 19.9602359 },
    { lat: 48.3171007, lng: 19.9615941 },
    { lat: 48.3156744, lng: 19.9637394 },
    { lat: 48.3156777, lng: 19.9651969 },
    { lat: 48.3151442, lng: 19.9662146 },
    { lat: 48.3150292, lng: 19.9665296 },
    { lat: 48.314798, lng: 19.9675069 },
    { lat: 48.3145705, lng: 19.9681496 },
    { lat: 48.3142823, lng: 19.9692119 },
    { lat: 48.3133416, lng: 19.9749543 },
    { lat: 48.3133229, lng: 19.9761345 },
    { lat: 48.313646, lng: 19.9771644 },
    { lat: 48.3137777, lng: 19.9788141 },
    { lat: 48.3139643, lng: 19.9792805 },
    { lat: 48.3140218, lng: 19.9795787 },
    { lat: 48.3137519, lng: 19.9806533 },
    { lat: 48.3135952, lng: 19.980889 },
    { lat: 48.3134819, lng: 19.981196 },
    { lat: 48.3135675, lng: 19.981937 },
    { lat: 48.3135963, lng: 19.9828356 },
    { lat: 48.3135676, lng: 19.983675 },
    { lat: 48.3134764, lng: 19.984075 },
    { lat: 48.3130127, lng: 19.9845091 },
    { lat: 48.3134778, lng: 19.9853864 },
    { lat: 48.3135089, lng: 19.9859316 },
    { lat: 48.3127368, lng: 19.9923508 },
    { lat: 48.3121185, lng: 19.9926777 },
    { lat: 48.3119269, lng: 19.9926467 },
    { lat: 48.3115808, lng: 19.9928518 },
    { lat: 48.3115577, lng: 19.9937196 },
    { lat: 48.3115828, lng: 19.9943984 },
    { lat: 48.3118182, lng: 19.9951175 },
    { lat: 48.3117691, lng: 19.9955036 },
    { lat: 48.3119771, lng: 19.9967451 },
    { lat: 48.3129555, lng: 19.9974055 },
    { lat: 48.3134485, lng: 20.0000681 },
    { lat: 48.313105, lng: 20.001244 },
    { lat: 48.3130536, lng: 20.0015773 },
    { lat: 48.3125223, lng: 20.0032434 },
    { lat: 48.3123538, lng: 20.0044038 },
    { lat: 48.3123919, lng: 20.0048832 },
    { lat: 48.3125132, lng: 20.0054372 },
    { lat: 48.3127463, lng: 20.0070108 },
    { lat: 48.3131332, lng: 20.0078809 },
    { lat: 48.3133278, lng: 20.0087305 },
    { lat: 48.3133278, lng: 20.0089712 },
    { lat: 48.3130171, lng: 20.0113107 },
    { lat: 48.3130751, lng: 20.0114284 },
    { lat: 48.3130396, lng: 20.0116847 },
    { lat: 48.3130749, lng: 20.0121478 },
    { lat: 48.3130537, lng: 20.0129577 },
    { lat: 48.3131657, lng: 20.0139886 },
    { lat: 48.313142, lng: 20.0148164 },
    { lat: 48.3132708, lng: 20.0159512 },
    { lat: 48.3132584, lng: 20.0168629 },
    { lat: 48.3134387, lng: 20.0173527 },
    { lat: 48.3138584, lng: 20.0189777 },
    { lat: 48.314076, lng: 20.0195712 },
    { lat: 48.3140455, lng: 20.0195939 },
    { lat: 48.3144989, lng: 20.0201449 },
    { lat: 48.3145871, lng: 20.0204222 },
    { lat: 48.3148033, lng: 20.0207939 },
    { lat: 48.3148187, lng: 20.0208134 },
    { lat: 48.3152406, lng: 20.0213663 },
    { lat: 48.3166483, lng: 20.0236646 },
    { lat: 48.3170641, lng: 20.0241021 },
    { lat: 48.3173083, lng: 20.0240925 },
    { lat: 48.3183579, lng: 20.0248122 },
    { lat: 48.3191091, lng: 20.0258057 },
    { lat: 48.3196714, lng: 20.0264043 },
    { lat: 48.3213656, lng: 20.0270478 },
    { lat: 48.3223525, lng: 20.0273463 },
    { lat: 48.3229929, lng: 20.0277057 },
    { lat: 48.3238568, lng: 20.027841 },
    { lat: 48.325292, lng: 20.0283546 },
    { lat: 48.3259337, lng: 20.0284539 },
    { lat: 48.3267281, lng: 20.0285394 },
    { lat: 48.3278275, lng: 20.028244 },
    { lat: 48.3281351, lng: 20.0280769 },
    { lat: 48.3293729, lng: 20.0274043 },
    { lat: 48.3298134, lng: 20.027354 },
    { lat: 48.3302601, lng: 20.0271537 },
    { lat: 48.3304299, lng: 20.0269012 },
    { lat: 48.330769, lng: 20.0265843 },
    { lat: 48.3340804, lng: 20.0253356 },
    { lat: 48.3346525, lng: 20.0247405 },
    { lat: 48.3347927, lng: 20.0245947 },
    { lat: 48.3366153, lng: 20.0233576 },
    { lat: 48.3385407, lng: 20.0226074 },
    { lat: 48.3397948, lng: 20.0226751 },
    { lat: 48.3407847, lng: 20.0231723 },
    { lat: 48.3423425, lng: 20.0247076 },
    { lat: 48.3424009, lng: 20.0242995 },
    { lat: 48.342586, lng: 20.0237148 },
    { lat: 48.343197, lng: 20.0230804 },
    { lat: 48.3422692, lng: 20.0196547 },
    { lat: 48.3420357, lng: 20.0183805 },
    { lat: 48.3418401, lng: 20.0168644 },
    { lat: 48.3412166, lng: 20.0143261 },
    { lat: 48.341156, lng: 20.0135801 },
    { lat: 48.3408254, lng: 20.0122284 },
    { lat: 48.3406836, lng: 20.0119589 },
    { lat: 48.3406304, lng: 20.0114179 },
    { lat: 48.3404226, lng: 20.0107075 },
    { lat: 48.3402009, lng: 20.0102862 },
    { lat: 48.3401415, lng: 20.0094612 },
    { lat: 48.3402988, lng: 20.0085374 },
    { lat: 48.3405035, lng: 20.007972 },
    { lat: 48.3405358, lng: 20.0075338 },
    { lat: 48.3405108, lng: 20.0070448 },
    { lat: 48.3405522, lng: 20.0067434 },
    { lat: 48.3405225, lng: 20.0060646 },
    { lat: 48.3406638, lng: 20.0051933 },
    { lat: 48.3408857, lng: 20.0011787 },
    { lat: 48.3412339, lng: 19.9993332 },
    { lat: 48.3413201, lng: 19.9991052 },
    { lat: 48.3414998, lng: 19.998933 },
    { lat: 48.3358252, lng: 19.9995639 },
    { lat: 48.334078, lng: 19.9911442 },
    { lat: 48.3334907, lng: 19.9884949 },
    { lat: 48.3335003, lng: 19.9884589 },
  ],
  VyšnýSkálnik: [
    { lat: 48.4713181, lng: 19.9543175 },
    { lat: 48.4715157, lng: 19.9543791 },
    { lat: 48.4715978, lng: 19.9543208 },
    { lat: 48.4715477, lng: 19.95407 },
    { lat: 48.471914, lng: 19.9540228 },
    { lat: 48.4719489, lng: 19.9540731 },
    { lat: 48.4719276, lng: 19.9544784 },
    { lat: 48.4717914, lng: 19.9546668 },
    { lat: 48.471655, lng: 19.9546622 },
    { lat: 48.4712968, lng: 19.9548999 },
    { lat: 48.4712544, lng: 19.955046 },
    { lat: 48.4714077, lng: 19.9550494 },
    { lat: 48.4716398, lng: 19.9554184 },
    { lat: 48.4717111, lng: 19.9553231 },
    { lat: 48.4717098, lng: 19.9551869 },
    { lat: 48.4717535, lng: 19.9551767 },
    { lat: 48.4718605, lng: 19.9555502 },
    { lat: 48.4720654, lng: 19.9555238 },
    { lat: 48.4722051, lng: 19.955656 },
    { lat: 48.4721506, lng: 19.9559636 },
    { lat: 48.4721764, lng: 19.9560925 },
    { lat: 48.4721063, lng: 19.9563272 },
    { lat: 48.4717302, lng: 19.9564353 },
    { lat: 48.4716152, lng: 19.9565407 },
    { lat: 48.4716009, lng: 19.9566277 },
    { lat: 48.4710529, lng: 19.9569876 },
    { lat: 48.4708921, lng: 19.9572352 },
    { lat: 48.4708774, lng: 19.9573403 },
    { lat: 48.4706692, lng: 19.9574738 },
    { lat: 48.4705717, lng: 19.9574359 },
    { lat: 48.4705295, lng: 19.9572902 },
    { lat: 48.4705565, lng: 19.9571825 },
    { lat: 48.4704645, lng: 19.9568438 },
    { lat: 48.4703208, lng: 19.9569634 },
    { lat: 48.4700113, lng: 19.9569174 },
    { lat: 48.4700966, lng: 19.9571244 },
    { lat: 48.4700728, lng: 19.9575445 },
    { lat: 48.4701349, lng: 19.9577327 },
    { lat: 48.4699804, lng: 19.9578913 },
    { lat: 48.4698851, lng: 19.9582174 },
    { lat: 48.470055, lng: 19.9582657 },
    { lat: 48.4699856, lng: 19.9584374 },
    { lat: 48.4699357, lng: 19.9585363 },
    { lat: 48.4697407, lng: 19.9585355 },
    { lat: 48.4696916, lng: 19.9582796 },
    { lat: 48.4694962, lng: 19.9582653 },
    { lat: 48.469419, lng: 19.9583593 },
    { lat: 48.4694541, lng: 19.9588696 },
    { lat: 48.4696218, lng: 19.9589444 },
    { lat: 48.4696727, lng: 19.9588409 },
    { lat: 48.4699054, lng: 19.9589123 },
    { lat: 48.4699292, lng: 19.9589686 },
    { lat: 48.4698739, lng: 19.9590442 },
    { lat: 48.4696195, lng: 19.9590515 },
    { lat: 48.4695347, lng: 19.9591998 },
    { lat: 48.4694582, lng: 19.9592068 },
    { lat: 48.4690861, lng: 19.958797 },
    { lat: 48.4690258, lng: 19.9589163 },
    { lat: 48.4690548, lng: 19.9590939 },
    { lat: 48.4689644, lng: 19.9593514 },
    { lat: 48.4686106, lng: 19.95938 },
    { lat: 48.4684205, lng: 19.9594894 },
    { lat: 48.4682492, lng: 19.9597539 },
    { lat: 48.4682547, lng: 19.9598826 },
    { lat: 48.4681249, lng: 19.9601975 },
    { lat: 48.4678089, lng: 19.9601308 },
    { lat: 48.4678335, lng: 19.9602889 },
    { lat: 48.467699, lng: 19.960491 },
    { lat: 48.4675164, lng: 19.9605163 },
    { lat: 48.4674011, lng: 19.9604241 },
    { lat: 48.4672095, lng: 19.9603984 },
    { lat: 48.4671596, lng: 19.9606012 },
    { lat: 48.4670242, lng: 19.9607264 },
    { lat: 48.4666301, lng: 19.9608635 },
    { lat: 48.4667469, lng: 19.9611239 },
    { lat: 48.4668888, lng: 19.9612019 },
    { lat: 48.4669451, lng: 19.9613892 },
    { lat: 48.466909, lng: 19.9616245 },
    { lat: 48.466943, lng: 19.9617905 },
    { lat: 48.466872, lng: 19.9619482 },
    { lat: 48.4666251, lng: 19.9621326 },
    { lat: 48.4661803, lng: 19.9621585 },
    { lat: 48.4658862, lng: 19.9621047 },
    { lat: 48.4657882, lng: 19.962052 },
    { lat: 48.4657254, lng: 19.9619028 },
    { lat: 48.4655449, lng: 19.9620781 },
    { lat: 48.4654011, lng: 19.9619311 },
    { lat: 48.4652186, lng: 19.961556 },
    { lat: 48.4650248, lng: 19.9614517 },
    { lat: 48.4649804, lng: 19.9613548 },
    { lat: 48.4649021, lng: 19.9613183 },
    { lat: 48.4647503, lng: 19.9616182 },
    { lat: 48.4645045, lng: 19.9613716 },
    { lat: 48.4643904, lng: 19.9613658 },
    { lat: 48.4643035, lng: 19.9616265 },
    { lat: 48.4645873, lng: 19.9621296 },
    { lat: 48.4645924, lng: 19.9622491 },
    { lat: 48.464551, lng: 19.9623085 },
    { lat: 48.4641467, lng: 19.9623383 },
    { lat: 48.4637292, lng: 19.9621894 },
    { lat: 48.4636468, lng: 19.9622942 },
    { lat: 48.4635006, lng: 19.9620867 },
    { lat: 48.4634102, lng: 19.961843 },
    { lat: 48.4631155, lng: 19.9619291 },
    { lat: 48.4631345, lng: 19.9621367 },
    { lat: 48.4630568, lng: 19.9621637 },
    { lat: 48.4628349, lng: 19.9620592 },
    { lat: 48.4627526, lng: 19.9621111 },
    { lat: 48.4628101, lng: 19.9626721 },
    { lat: 48.462859, lng: 19.9627608 },
    { lat: 48.4631009, lng: 19.9627319 },
    { lat: 48.4632058, lng: 19.9629753 },
    { lat: 48.4629193, lng: 19.9631763 },
    { lat: 48.4626651, lng: 19.9629714 },
    { lat: 48.462571, lng: 19.9630393 },
    { lat: 48.4623064, lng: 19.9630124 },
    { lat: 48.4622712, lng: 19.9627439 },
    { lat: 48.4619194, lng: 19.9623641 },
    { lat: 48.4616578, lng: 19.9628094 },
    { lat: 48.4611594, lng: 19.9623428 },
    { lat: 48.4608364, lng: 19.9622186 },
    { lat: 48.460804, lng: 19.9621461 },
    { lat: 48.4608336, lng: 19.9618068 },
    { lat: 48.4607414, lng: 19.9616751 },
    { lat: 48.4606837, lng: 19.9616735 },
    { lat: 48.460402, lng: 19.9618279 },
    { lat: 48.4603434, lng: 19.9619379 },
    { lat: 48.4604819, lng: 19.9624151 },
    { lat: 48.4604729, lng: 19.9627049 },
    { lat: 48.4602671, lng: 19.9627682 },
    { lat: 48.4600543, lng: 19.962932 },
    { lat: 48.4600281, lng: 19.9631875 },
    { lat: 48.4603498, lng: 19.9637885 },
    { lat: 48.4603345, lng: 19.9638514 },
    { lat: 48.4600183, lng: 19.9641276 },
    { lat: 48.4596062, lng: 19.9640783 },
    { lat: 48.4594586, lng: 19.9644782 },
    { lat: 48.4595672, lng: 19.9645966 },
    { lat: 48.4595573, lng: 19.9647349 },
    { lat: 48.4593769, lng: 19.9649971 },
    { lat: 48.4593004, lng: 19.9650236 },
    { lat: 48.458516, lng: 19.9645105 },
    { lat: 48.4584748, lng: 19.9646547 },
    { lat: 48.4583637, lng: 19.9646951 },
    { lat: 48.458318, lng: 19.9645455 },
    { lat: 48.4581593, lng: 19.9644389 },
    { lat: 48.4581378, lng: 19.9640648 },
    { lat: 48.4580392, lng: 19.9639202 },
    { lat: 48.4579816, lng: 19.9639431 },
    { lat: 48.4577819, lng: 19.9644108 },
    { lat: 48.4577881, lng: 19.9646412 },
    { lat: 48.4580558, lng: 19.9653495 },
    { lat: 48.458057, lng: 19.965457 },
    { lat: 48.457863, lng: 19.965719 },
    { lat: 48.4578202, lng: 19.9659293 },
    { lat: 48.4577229, lng: 19.9660301 },
    { lat: 48.4576524, lng: 19.9663833 },
    { lat: 48.4574968, lng: 19.966461 },
    { lat: 48.4573188, lng: 19.9663818 },
    { lat: 48.4568904, lng: 19.9654962 },
    { lat: 48.456704, lng: 19.9653479 },
    { lat: 48.4564195, lng: 19.9653634 },
    { lat: 48.4563287, lng: 19.9652631 },
    { lat: 48.4560635, lng: 19.9646416 },
    { lat: 48.4560013, lng: 19.9642387 },
    { lat: 48.4558286, lng: 19.9641232 },
    { lat: 48.4557692, lng: 19.9639685 },
    { lat: 48.4556178, lng: 19.9639553 },
    { lat: 48.4555229, lng: 19.964113 },
    { lat: 48.4557243, lng: 19.9642144 },
    { lat: 48.4557877, lng: 19.9643287 },
    { lat: 48.4557209, lng: 19.9645061 },
    { lat: 48.4556523, lng: 19.9644821 },
    { lat: 48.455679, lng: 19.9646322 },
    { lat: 48.4565673, lng: 19.9683974 },
    { lat: 48.4566003, lng: 19.9683804 },
    { lat: 48.4579689, lng: 19.9737342 },
    { lat: 48.4588802, lng: 19.9733359 },
    { lat: 48.459129, lng: 19.9738931 },
    { lat: 48.4595924, lng: 19.9743514 },
    { lat: 48.459626, lng: 19.9742417 },
    { lat: 48.4597034, lng: 19.9743138 },
    { lat: 48.4599234, lng: 19.9743255 },
    { lat: 48.4603256, lng: 19.9741905 },
    { lat: 48.4605951, lng: 19.9750489 },
    { lat: 48.4621841, lng: 19.9751187 },
    { lat: 48.4634311, lng: 19.9755192 },
    { lat: 48.4646592, lng: 19.9753889 },
    { lat: 48.4650869, lng: 19.9752476 },
    { lat: 48.4656015, lng: 19.9754845 },
    { lat: 48.466152, lng: 19.9759822 },
    { lat: 48.4665858, lng: 19.9766272 },
    { lat: 48.466839, lng: 19.9771298 },
    { lat: 48.4672975, lng: 19.9775795 },
    { lat: 48.4678307, lng: 19.9767107 },
    { lat: 48.4687196, lng: 19.976474 },
    { lat: 48.469228, lng: 19.9766675 },
    { lat: 48.4700543, lng: 19.9771627 },
    { lat: 48.4704199, lng: 19.976536 },
    { lat: 48.4707879, lng: 19.9762069 },
    { lat: 48.471673, lng: 19.9761693 },
    { lat: 48.472145, lng: 19.9764143 },
    { lat: 48.4726245, lng: 19.9783284 },
    { lat: 48.473598, lng: 19.9783552 },
    { lat: 48.4738247, lng: 19.9787383 },
    { lat: 48.473962, lng: 19.9786013 },
    { lat: 48.474663, lng: 19.9788248 },
    { lat: 48.4754849, lng: 19.9793204 },
    { lat: 48.4755179, lng: 19.9791405 },
    { lat: 48.4754904, lng: 19.978673 },
    { lat: 48.4756147, lng: 19.9781787 },
    { lat: 48.4756521, lng: 19.9778066 },
    { lat: 48.475628, lng: 19.9774773 },
    { lat: 48.4756741, lng: 19.9773164 },
    { lat: 48.4758316, lng: 19.9770716 },
    { lat: 48.4758706, lng: 19.9767938 },
    { lat: 48.476027, lng: 19.9765208 },
    { lat: 48.4765274, lng: 19.9759384 },
    { lat: 48.4768254, lng: 19.9760359 },
    { lat: 48.477493, lng: 19.975865 },
    { lat: 48.4781997, lng: 19.9755291 },
    { lat: 48.4788461, lng: 19.9756019 },
    { lat: 48.4796801, lng: 19.9762285 },
    { lat: 48.4805242, lng: 19.9766641 },
    { lat: 48.4818843, lng: 19.9770221 },
    { lat: 48.4819033, lng: 19.9769351 },
    { lat: 48.4819738, lng: 19.9769007 },
    { lat: 48.4820593, lng: 19.9769745 },
    { lat: 48.4827847, lng: 19.9764426 },
    { lat: 48.482925, lng: 19.9763997 },
    { lat: 48.4835778, lng: 19.9757487 },
    { lat: 48.4838009, lng: 19.9756983 },
    { lat: 48.4842441, lng: 19.9758125 },
    { lat: 48.4843574, lng: 19.9752421 },
    { lat: 48.4845984, lng: 19.9746002 },
    { lat: 48.4846595, lng: 19.9739953 },
    { lat: 48.4847371, lng: 19.9737528 },
    { lat: 48.4856191, lng: 19.9721733 },
    { lat: 48.4864324, lng: 19.9708687 },
    { lat: 48.4873316, lng: 19.9692231 },
    { lat: 48.4887237, lng: 19.9669024 },
    { lat: 48.4899858, lng: 19.9629929 },
    { lat: 48.4905358, lng: 19.9615378 },
    { lat: 48.4914891, lng: 19.9585144 },
    { lat: 48.4912473, lng: 19.9582276 },
    { lat: 48.4911922, lng: 19.9580461 },
    { lat: 48.4910192, lng: 19.9580041 },
    { lat: 48.4906672, lng: 19.9577235 },
    { lat: 48.4904401, lng: 19.9573607 },
    { lat: 48.4903059, lng: 19.9573579 },
    { lat: 48.4899802, lng: 19.9569033 },
    { lat: 48.4899546, lng: 19.9566437 },
    { lat: 48.48946, lng: 19.9562977 },
    { lat: 48.4894002, lng: 19.9561404 },
    { lat: 48.4892418, lng: 19.9560398 },
    { lat: 48.4892139, lng: 19.9559441 },
    { lat: 48.4890489, lng: 19.9559823 },
    { lat: 48.4885976, lng: 19.9557003 },
    { lat: 48.4882638, lng: 19.9555597 },
    { lat: 48.4881676, lng: 19.9556009 },
    { lat: 48.4880261, lng: 19.9554554 },
    { lat: 48.4878293, lng: 19.9554054 },
    { lat: 48.4875863, lng: 19.9552263 },
    { lat: 48.4874459, lng: 19.9549194 },
    { lat: 48.487314, lng: 19.9550209 },
    { lat: 48.4868044, lng: 19.9543503 },
    { lat: 48.4866491, lng: 19.9543492 },
    { lat: 48.4862855, lng: 19.9541707 },
    { lat: 48.4858824, lng: 19.9537257 },
    { lat: 48.4857181, lng: 19.9537042 },
    { lat: 48.4853596, lng: 19.9532993 },
    { lat: 48.485226, lng: 19.9526712 },
    { lat: 48.4849784, lng: 19.9526765 },
    { lat: 48.4849085, lng: 19.9523547 },
    { lat: 48.4849273, lng: 19.9519226 },
    { lat: 48.4848801, lng: 19.9518188 },
    { lat: 48.4841297, lng: 19.9513131 },
    { lat: 48.4838858, lng: 19.9512435 },
    { lat: 48.483758, lng: 19.9508857 },
    { lat: 48.4836429, lng: 19.9510708 },
    { lat: 48.4834931, lng: 19.9510194 },
    { lat: 48.483553, lng: 19.9501711 },
    { lat: 48.4836031, lng: 19.9500498 },
    { lat: 48.4834395, lng: 19.9496991 },
    { lat: 48.4833531, lng: 19.9491776 },
    { lat: 48.4832671, lng: 19.949009 },
    { lat: 48.4831927, lng: 19.9491401 },
    { lat: 48.4830812, lng: 19.949807 },
    { lat: 48.4829602, lng: 19.950059 },
    { lat: 48.4826255, lng: 19.9502438 },
    { lat: 48.4825986, lng: 19.9508273 },
    { lat: 48.4824442, lng: 19.9508819 },
    { lat: 48.4823259, lng: 19.9508531 },
    { lat: 48.4821901, lng: 19.9506534 },
    { lat: 48.4818077, lng: 19.9504299 },
    { lat: 48.4817524, lng: 19.9502122 },
    { lat: 48.4811913, lng: 19.9500278 },
    { lat: 48.4811033, lng: 19.9499195 },
    { lat: 48.4805535, lng: 19.9502073 },
    { lat: 48.4802879, lng: 19.9504512 },
    { lat: 48.4800043, lng: 19.9508544 },
    { lat: 48.4798894, lng: 19.9508285 },
    { lat: 48.4797733, lng: 19.9504853 },
    { lat: 48.47975, lng: 19.9499074 },
    { lat: 48.4796732, lng: 19.9497447 },
    { lat: 48.479602, lng: 19.9497625 },
    { lat: 48.4793733, lng: 19.9499758 },
    { lat: 48.47917, lng: 19.9505467 },
    { lat: 48.4793262, lng: 19.9518146 },
    { lat: 48.4791708, lng: 19.9524032 },
    { lat: 48.4791078, lng: 19.9529032 },
    { lat: 48.4790585, lng: 19.9530219 },
    { lat: 48.4788934, lng: 19.953059 },
    { lat: 48.4776945, lng: 19.9530757 },
    { lat: 48.4774763, lng: 19.9532195 },
    { lat: 48.4773474, lng: 19.9531012 },
    { lat: 48.4770768, lng: 19.9529883 },
    { lat: 48.4766475, lng: 19.952956 },
    { lat: 48.4765507, lng: 19.9528286 },
    { lat: 48.4760172, lng: 19.9528113 },
    { lat: 48.4758049, lng: 19.9525096 },
    { lat: 48.4756773, lng: 19.9518661 },
    { lat: 48.4751884, lng: 19.9516482 },
    { lat: 48.4747506, lng: 19.9513682 },
    { lat: 48.4747129, lng: 19.9515138 },
    { lat: 48.4745158, lng: 19.9515423 },
    { lat: 48.474207, lng: 19.9513799 },
    { lat: 48.4734195, lng: 19.9514849 },
    { lat: 48.4727118, lng: 19.9519015 },
    { lat: 48.4725951, lng: 19.9523667 },
    { lat: 48.4724139, lng: 19.952503 },
    { lat: 48.472399, lng: 19.9526312 },
    { lat: 48.472345, lng: 19.9526815 },
    { lat: 48.4722201, lng: 19.952609 },
    { lat: 48.4721686, lng: 19.9521361 },
    { lat: 48.4720078, lng: 19.9521178 },
    { lat: 48.4718646, lng: 19.9522756 },
    { lat: 48.4713809, lng: 19.9525801 },
    { lat: 48.471494, lng: 19.9530421 },
    { lat: 48.4714234, lng: 19.9532658 },
    { lat: 48.4713139, lng: 19.9533654 },
    { lat: 48.4712868, lng: 19.953684 },
    { lat: 48.4714466, lng: 19.9537336 },
    { lat: 48.4714816, lng: 19.9538428 },
    { lat: 48.4713629, lng: 19.954042 },
    { lat: 48.4713181, lng: 19.9543175 },
  ],
  Kružno: [
    { lat: 48.4021034, lng: 19.9494673 },
    { lat: 48.4023668, lng: 19.9496456 },
    { lat: 48.4027139, lng: 19.9502072 },
    { lat: 48.4027596, lng: 19.9505389 },
    { lat: 48.4029335, lng: 19.9509196 },
    { lat: 48.402892, lng: 19.9510041 },
    { lat: 48.402989, lng: 19.9512702 },
    { lat: 48.4030927, lng: 19.9514071 },
    { lat: 48.4031371, lng: 19.9516287 },
    { lat: 48.403275, lng: 19.9517743 },
    { lat: 48.4034158, lng: 19.952172 },
    { lat: 48.4037138, lng: 19.9524225 },
    { lat: 48.4037855, lng: 19.9525454 },
    { lat: 48.4038654, lng: 19.9525459 },
    { lat: 48.4041397, lng: 19.9529021 },
    { lat: 48.4044482, lng: 19.9530012 },
    { lat: 48.4045715, lng: 19.952938 },
    { lat: 48.40466, lng: 19.9531369 },
    { lat: 48.4048643, lng: 19.953278 },
    { lat: 48.4049416, lng: 19.9534797 },
    { lat: 48.4051035, lng: 19.9535525 },
    { lat: 48.4052503, lng: 19.9539082 },
    { lat: 48.405932, lng: 19.9548399 },
    { lat: 48.406033, lng: 19.9550461 },
    { lat: 48.4066936, lng: 19.9552651 },
    { lat: 48.4068603, lng: 19.955398 },
    { lat: 48.4069724, lng: 19.9553859 },
    { lat: 48.4070132, lng: 19.9550749 },
    { lat: 48.4071718, lng: 19.9547453 },
    { lat: 48.4071079, lng: 19.9542522 },
    { lat: 48.4072457, lng: 19.954284 },
    { lat: 48.4072284, lng: 19.9538003 },
    { lat: 48.4071354, lng: 19.9538663 },
    { lat: 48.40684, lng: 19.9531972 },
    { lat: 48.4065366, lng: 19.9532397 },
    { lat: 48.4066112, lng: 19.9530854 },
    { lat: 48.4065172, lng: 19.9530225 },
    { lat: 48.406425, lng: 19.9527076 },
    { lat: 48.4065321, lng: 19.9520719 },
    { lat: 48.4067471, lng: 19.9518556 },
    { lat: 48.4066207, lng: 19.9510583 },
    { lat: 48.4067121, lng: 19.9510602 },
    { lat: 48.4067267, lng: 19.9508751 },
    { lat: 48.4070837, lng: 19.950659 },
    { lat: 48.4070927, lng: 19.9504433 },
    { lat: 48.4068695, lng: 19.9503069 },
    { lat: 48.4070295, lng: 19.9501785 },
    { lat: 48.4068117, lng: 19.9500643 },
    { lat: 48.4066403, lng: 19.9496868 },
    { lat: 48.4067475, lng: 19.9496358 },
    { lat: 48.4066978, lng: 19.9494232 },
    { lat: 48.4064858, lng: 19.949395 },
    { lat: 48.4065012, lng: 19.9484859 },
    { lat: 48.4065677, lng: 19.9485114 },
    { lat: 48.4066262, lng: 19.9480821 },
    { lat: 48.4067712, lng: 19.9481288 },
    { lat: 48.406914, lng: 19.9479116 },
    { lat: 48.4067013, lng: 19.9478884 },
    { lat: 48.4066847, lng: 19.9477088 },
    { lat: 48.4067499, lng: 19.9472069 },
    { lat: 48.4069096, lng: 19.9472809 },
    { lat: 48.4069112, lng: 19.9466809 },
    { lat: 48.4067019, lng: 19.9466038 },
    { lat: 48.4066894, lng: 19.9463115 },
    { lat: 48.4069183, lng: 19.9464242 },
    { lat: 48.4068103, lng: 19.945896 },
    { lat: 48.4068324, lng: 19.9453543 },
    { lat: 48.4070319, lng: 19.9454117 },
    { lat: 48.4071301, lng: 19.94494 },
    { lat: 48.4070136, lng: 19.9448263 },
    { lat: 48.4070292, lng: 19.944504 },
    { lat: 48.4071448, lng: 19.9445957 },
    { lat: 48.4073201, lng: 19.9445899 },
    { lat: 48.4073303, lng: 19.9445081 },
    { lat: 48.4071344, lng: 19.9445134 },
    { lat: 48.4071209, lng: 19.9440343 },
    { lat: 48.4072796, lng: 19.9439254 },
    { lat: 48.4072926, lng: 19.943754 },
    { lat: 48.4071939, lng: 19.9438209 },
    { lat: 48.4070667, lng: 19.9436094 },
    { lat: 48.407159, lng: 19.9435275 },
    { lat: 48.4071223, lng: 19.943247 },
    { lat: 48.4073804, lng: 19.9430648 },
    { lat: 48.4075038, lng: 19.9426045 },
    { lat: 48.4073896, lng: 19.9424336 },
    { lat: 48.4072818, lng: 19.9424974 },
    { lat: 48.4072509, lng: 19.9422969 },
    { lat: 48.407142, lng: 19.9422279 },
    { lat: 48.4072733, lng: 19.942137 },
    { lat: 48.4073732, lng: 19.9419147 },
    { lat: 48.4073108, lng: 19.9418022 },
    { lat: 48.4073723, lng: 19.9416825 },
    { lat: 48.407573, lng: 19.9416611 },
    { lat: 48.4074429, lng: 19.9413563 },
    { lat: 48.4074944, lng: 19.9411138 },
    { lat: 48.4076327, lng: 19.9409736 },
    { lat: 48.4075822, lng: 19.9411642 },
    { lat: 48.4076466, lng: 19.9412732 },
    { lat: 48.4079163, lng: 19.9400394 },
    { lat: 48.4079789, lng: 19.9401631 },
    { lat: 48.4080568, lng: 19.9401134 },
    { lat: 48.4080225, lng: 19.9398888 },
    { lat: 48.4079414, lng: 19.9398632 },
    { lat: 48.4079466, lng: 19.9397486 },
    { lat: 48.4081403, lng: 19.9397455 },
    { lat: 48.4081432, lng: 19.9396588 },
    { lat: 48.4080622, lng: 19.9395786 },
    { lat: 48.4081586, lng: 19.9390669 },
    { lat: 48.4081485, lng: 19.9388782 },
    { lat: 48.4082068, lng: 19.9387957 },
    { lat: 48.4081435, lng: 19.9384724 },
    { lat: 48.4082864, lng: 19.938231 },
    { lat: 48.4084185, lng: 19.9381584 },
    { lat: 48.4084229, lng: 19.9380225 },
    { lat: 48.4082869, lng: 19.9378711 },
    { lat: 48.4083691, lng: 19.9377416 },
    { lat: 48.4082973, lng: 19.9371645 },
    { lat: 48.40834, lng: 19.9371079 },
    { lat: 48.4084047, lng: 19.9371471 },
    { lat: 48.4085085, lng: 19.936862 },
    { lat: 48.4085681, lng: 19.9369091 },
    { lat: 48.4086472, lng: 19.9367827 },
    { lat: 48.408693, lng: 19.9365677 },
    { lat: 48.4089202, lng: 19.9363427 },
    { lat: 48.4090396, lng: 19.9364737 },
    { lat: 48.4091082, lng: 19.9363179 },
    { lat: 48.4090777, lng: 19.9360999 },
    { lat: 48.4092093, lng: 19.9360185 },
    { lat: 48.4092258, lng: 19.9357964 },
    { lat: 48.4093188, lng: 19.9357587 },
    { lat: 48.4093192, lng: 19.9355853 },
    { lat: 48.4092661, lng: 19.9355517 },
    { lat: 48.4093592, lng: 19.9354924 },
    { lat: 48.4097201, lng: 19.9346007 },
    { lat: 48.4098429, lng: 19.9344194 },
    { lat: 48.4099296, lng: 19.9344437 },
    { lat: 48.409935, lng: 19.9343049 },
    { lat: 48.4100564, lng: 19.9343266 },
    { lat: 48.4102515, lng: 19.9342252 },
    { lat: 48.4103513, lng: 19.9340291 },
    { lat: 48.4105804, lng: 19.9340102 },
    { lat: 48.4105634, lng: 19.9336942 },
    { lat: 48.4107177, lng: 19.9335885 },
    { lat: 48.4108197, lng: 19.9336819 },
    { lat: 48.4110444, lng: 19.9335596 },
    { lat: 48.4112089, lng: 19.9333484 },
    { lat: 48.411229, lng: 19.933133 },
    { lat: 48.4113348, lng: 19.9330526 },
    { lat: 48.4114069, lng: 19.9331325 },
    { lat: 48.4113879, lng: 19.9328199 },
    { lat: 48.4115105, lng: 19.9324716 },
    { lat: 48.4116459, lng: 19.9325036 },
    { lat: 48.4118057, lng: 19.9320202 },
    { lat: 48.4122349, lng: 19.9382355 },
    { lat: 48.417321, lng: 19.9402123 },
    { lat: 48.4173363, lng: 19.9403336 },
    { lat: 48.4171994, lng: 19.9405045 },
    { lat: 48.417166, lng: 19.940596 },
    { lat: 48.4172061, lng: 19.9406344 },
    { lat: 48.4171867, lng: 19.9407107 },
    { lat: 48.417127, lng: 19.9407604 },
    { lat: 48.4170674, lng: 19.941003 },
    { lat: 48.4170098, lng: 19.9410806 },
    { lat: 48.4169154, lng: 19.9410854 },
    { lat: 48.416795, lng: 19.9413519 },
    { lat: 48.4168135, lng: 19.9415487 },
    { lat: 48.4167492, lng: 19.9415751 },
    { lat: 48.4167534, lng: 19.9418272 },
    { lat: 48.4168937, lng: 19.941951 },
    { lat: 48.4176819, lng: 19.9419398 },
    { lat: 48.4183119, lng: 19.9417399 },
    { lat: 48.4189207, lng: 19.9412186 },
    { lat: 48.4193747, lng: 19.9410371 },
    { lat: 48.4217674, lng: 19.9390244 },
    { lat: 48.4229264, lng: 19.9390442 },
    { lat: 48.4237562, lng: 19.938874 },
    { lat: 48.4249053, lng: 19.9381638 },
    { lat: 48.4252974, lng: 19.9380303 },
    { lat: 48.425961, lng: 19.937371 },
    { lat: 48.4266748, lng: 19.9363071 },
    { lat: 48.4269073, lng: 19.9361067 },
    { lat: 48.4279797, lng: 19.9331145 },
    { lat: 48.4280915, lng: 19.9326476 },
    { lat: 48.4283757, lng: 19.9318336 },
    { lat: 48.428661, lng: 19.9305465 },
    { lat: 48.4290526, lng: 19.9299041 },
    { lat: 48.430154, lng: 19.9286792 },
    { lat: 48.4311746, lng: 19.927388 },
    { lat: 48.4316489, lng: 19.9266754 },
    { lat: 48.4319058, lng: 19.9264119 },
    { lat: 48.432718, lng: 19.925361 },
    { lat: 48.432628, lng: 19.925108 },
    { lat: 48.432449, lng: 19.924618 },
    { lat: 48.432322, lng: 19.924271 },
    { lat: 48.432291, lng: 19.924185 },
    { lat: 48.432133, lng: 19.923751 },
    { lat: 48.432108, lng: 19.923683 },
    { lat: 48.431947, lng: 19.923236 },
    { lat: 48.431716, lng: 19.922605 },
    { lat: 48.431629, lng: 19.922359 },
    { lat: 48.431545, lng: 19.922126 },
    { lat: 48.43146, lng: 19.921883 },
    { lat: 48.431299, lng: 19.921449 },
    { lat: 48.431217, lng: 19.921211 },
    { lat: 48.430975, lng: 19.920547 },
    { lat: 48.430865, lng: 19.920243 },
    { lat: 48.430487, lng: 19.919205 },
    { lat: 48.430415, lng: 19.919008 },
    { lat: 48.430336, lng: 19.91879 },
    { lat: 48.430151, lng: 19.918295 },
    { lat: 48.429858, lng: 19.917483 },
    { lat: 48.429638, lng: 19.916873 },
    { lat: 48.429582, lng: 19.916718 },
    { lat: 48.42913, lng: 19.915478 },
    { lat: 48.428857, lng: 19.914728 },
    { lat: 48.428839, lng: 19.914677 },
    { lat: 48.42861, lng: 19.914728 },
    { lat: 48.428538, lng: 19.914752 },
    { lat: 48.428476, lng: 19.914762 },
    { lat: 48.428339, lng: 19.914794 },
    { lat: 48.42824, lng: 19.914818 },
    { lat: 48.427856, lng: 19.914906 },
    { lat: 48.427709, lng: 19.914939 },
    { lat: 48.427125, lng: 19.915062 },
    { lat: 48.427066, lng: 19.915087 },
    { lat: 48.426702, lng: 19.915152 },
    { lat: 48.426324, lng: 19.915228 },
    { lat: 48.425942, lng: 19.915327 },
    { lat: 48.425479, lng: 19.915426 },
    { lat: 48.425233, lng: 19.915476 },
    { lat: 48.424999, lng: 19.915531 },
    { lat: 48.424786, lng: 19.915576 },
    { lat: 48.42446, lng: 19.915642 },
    { lat: 48.423926, lng: 19.915759 },
    { lat: 48.423487, lng: 19.915854 },
    { lat: 48.423072, lng: 19.915944 },
    { lat: 48.422933, lng: 19.915975 },
    { lat: 48.422667, lng: 19.916037 },
    { lat: 48.422277, lng: 19.916126 },
    { lat: 48.422015, lng: 19.916183 },
    { lat: 48.421908, lng: 19.916203 },
    { lat: 48.421848, lng: 19.916203 },
    { lat: 48.421564, lng: 19.916151 },
    { lat: 48.421472, lng: 19.916132 },
    { lat: 48.421417, lng: 19.916119 },
    { lat: 48.421399, lng: 19.916071 },
    { lat: 48.421175, lng: 19.915977 },
    { lat: 48.420897, lng: 19.915858 },
    { lat: 48.420663, lng: 19.915763 },
    { lat: 48.420137, lng: 19.915574 },
    { lat: 48.419712, lng: 19.91544 },
    { lat: 48.419531, lng: 19.915343 },
    { lat: 48.419361, lng: 19.915244 },
    { lat: 48.41934, lng: 19.915221 },
    { lat: 48.419296, lng: 19.915176 },
    { lat: 48.418957, lng: 19.914978 },
    { lat: 48.418797, lng: 19.914882 },
    { lat: 48.418673, lng: 19.914819 },
    { lat: 48.418582, lng: 19.914783 },
    { lat: 48.41816, lng: 19.91462 },
    { lat: 48.417755, lng: 19.914698 },
    { lat: 48.417398, lng: 19.914791 },
    { lat: 48.417336, lng: 19.914818 },
    { lat: 48.417166, lng: 19.914865 },
    { lat: 48.416668, lng: 19.915011 },
    { lat: 48.416266, lng: 19.915113 },
    { lat: 48.416055, lng: 19.915161 },
    { lat: 48.415548, lng: 19.915318 },
    { lat: 48.415153, lng: 19.915431 },
    { lat: 48.414765, lng: 19.915545 },
    { lat: 48.414672, lng: 19.915573 },
    { lat: 48.414602, lng: 19.91558 },
    { lat: 48.414089, lng: 19.915606 },
    { lat: 48.413768, lng: 19.915631 },
    { lat: 48.413506, lng: 19.915641 },
    { lat: 48.413189, lng: 19.915661 },
    { lat: 48.413062, lng: 19.915666 },
    { lat: 48.41259, lng: 19.915686 },
    { lat: 48.412141, lng: 19.915705 },
    { lat: 48.411947, lng: 19.915714 },
    { lat: 48.41162, lng: 19.915755 },
    { lat: 48.411375, lng: 19.915802 },
    { lat: 48.41128, lng: 19.915831 },
    { lat: 48.410984, lng: 19.915926 },
    { lat: 48.410893, lng: 19.915952 },
    { lat: 48.410842, lng: 19.915975 },
    { lat: 48.410779, lng: 19.915987 },
    { lat: 48.410351, lng: 19.916182 },
    { lat: 48.410227, lng: 19.916234 },
    { lat: 48.410046, lng: 19.916284 },
    { lat: 48.409886, lng: 19.916317 },
    { lat: 48.409516, lng: 19.916385 },
    { lat: 48.40923, lng: 19.916435 },
    { lat: 48.409171, lng: 19.916448 },
    { lat: 48.408907, lng: 19.916532 },
    { lat: 48.408651, lng: 19.916564 },
    { lat: 48.408379, lng: 19.916587 },
    { lat: 48.408025, lng: 19.916564 },
    { lat: 48.407893, lng: 19.916551 },
    { lat: 48.407212, lng: 19.916356 },
    { lat: 48.407061, lng: 19.916325 },
    { lat: 48.406782, lng: 19.916264 },
    { lat: 48.406122, lng: 19.916418 },
    { lat: 48.405983, lng: 19.91645 },
    { lat: 48.40535, lng: 19.916283 },
    { lat: 48.405224, lng: 19.916252 },
    { lat: 48.404609, lng: 19.916096 },
    { lat: 48.404194, lng: 19.91604 },
    { lat: 48.404014, lng: 19.916015 },
    { lat: 48.403501, lng: 19.915916 },
    { lat: 48.403148, lng: 19.915785 },
    { lat: 48.402908, lng: 19.915629 },
    { lat: 48.40287, lng: 19.915665 },
    { lat: 48.402798, lng: 19.91665 },
    { lat: 48.4027, lng: 19.917841 },
    { lat: 48.402647, lng: 19.918399 },
    { lat: 48.402537, lng: 19.919698 },
    { lat: 48.402507, lng: 19.920052 },
    { lat: 48.402387, lng: 19.920032 },
    { lat: 48.402158, lng: 19.919992 },
    { lat: 48.401765, lng: 19.919931 },
    { lat: 48.401642, lng: 19.919911 },
    { lat: 48.401632, lng: 19.919898 },
    { lat: 48.401498, lng: 19.919753 },
    { lat: 48.401489, lng: 19.91974 },
    { lat: 48.401308, lng: 19.9197 },
    { lat: 48.401114, lng: 19.919532 },
    { lat: 48.400889, lng: 19.919336 },
    { lat: 48.400794, lng: 19.919248 },
    { lat: 48.400521, lng: 19.919005 },
    { lat: 48.400241, lng: 19.918754 },
    { lat: 48.399911, lng: 19.918452 },
    { lat: 48.399644, lng: 19.918219 },
    { lat: 48.399538, lng: 19.918131 },
    { lat: 48.399288, lng: 19.917929 },
    { lat: 48.399248, lng: 19.917893 },
    { lat: 48.399191, lng: 19.918006 },
    { lat: 48.399135, lng: 19.918121 },
    { lat: 48.398903, lng: 19.918595 },
    { lat: 48.398615, lng: 19.919159 },
    { lat: 48.398235, lng: 19.919909 },
    { lat: 48.39817, lng: 19.920036 },
    { lat: 48.398147, lng: 19.920072 },
    { lat: 48.398002, lng: 19.920232 },
    { lat: 48.3978165, lng: 19.9204984 },
    { lat: 48.3973901, lng: 19.9209121 },
    { lat: 48.396486, lng: 19.9215526 },
    { lat: 48.3953559, lng: 19.9219717 },
    { lat: 48.3948161, lng: 19.922017 },
    { lat: 48.4018874, lng: 19.9341539 },
    { lat: 48.4025171, lng: 19.9383816 },
    { lat: 48.4025785, lng: 19.9390809 },
    { lat: 48.4025533, lng: 19.939759 },
    { lat: 48.4021625, lng: 19.9427493 },
    { lat: 48.402258, lng: 19.9439898 },
    { lat: 48.4026316, lng: 19.9466291 },
    { lat: 48.4021034, lng: 19.9494673 },
  ],
  RimavskáBaňa: [
    { lat: 48.502643, lng: 19.9406273 },
    { lat: 48.5026243, lng: 19.9407467 },
    { lat: 48.5023967, lng: 19.9426786 },
    { lat: 48.5024182, lng: 19.9432129 },
    { lat: 48.5022548, lng: 19.9442445 },
    { lat: 48.5020878, lng: 19.9447931 },
    { lat: 48.5019165, lng: 19.9451409 },
    { lat: 48.5019116, lng: 19.9453414 },
    { lat: 48.5017994, lng: 19.945696 },
    { lat: 48.5017774, lng: 19.9459212 },
    { lat: 48.5016376, lng: 19.9462099 },
    { lat: 48.5017428, lng: 19.946565 },
    { lat: 48.5016078, lng: 19.9467542 },
    { lat: 48.5016729, lng: 19.9472541 },
    { lat: 48.5016407, lng: 19.9473719 },
    { lat: 48.5016694, lng: 19.947832 },
    { lat: 48.5018174, lng: 19.9480327 },
    { lat: 48.501889, lng: 19.9482563 },
    { lat: 48.5022072, lng: 19.9488527 },
    { lat: 48.5022532, lng: 19.9491396 },
    { lat: 48.5024418, lng: 19.9494969 },
    { lat: 48.5025854, lng: 19.9508109 },
    { lat: 48.5027499, lng: 19.9512668 },
    { lat: 48.5028467, lng: 19.9512323 },
    { lat: 48.5029042, lng: 19.9512929 },
    { lat: 48.5027802, lng: 19.9516572 },
    { lat: 48.5028457, lng: 19.9518228 },
    { lat: 48.5027376, lng: 19.9524346 },
    { lat: 48.5023589, lng: 19.9535465 },
    { lat: 48.5020354, lng: 19.9540457 },
    { lat: 48.5018135, lng: 19.9547131 },
    { lat: 48.5016327, lng: 19.9550985 },
    { lat: 48.5014582, lng: 19.9562444 },
    { lat: 48.5012873, lng: 19.9567297 },
    { lat: 48.5012219, lng: 19.9574709 },
    { lat: 48.5011051, lng: 19.9580689 },
    { lat: 48.5009661, lng: 19.9595971 },
    { lat: 48.5008852, lng: 19.9598439 },
    { lat: 48.5009011, lng: 19.9601117 },
    { lat: 48.5011656, lng: 19.9610631 },
    { lat: 48.5010548, lng: 19.9619081 },
    { lat: 48.5007561, lng: 19.9617389 },
    { lat: 48.5005408, lng: 19.9613379 },
    { lat: 48.5002562, lng: 19.9610821 },
    { lat: 48.4997801, lng: 19.9607981 },
    { lat: 48.4990106, lng: 19.9604761 },
    { lat: 48.4986319, lng: 19.960554 },
    { lat: 48.4984563, lng: 19.9607157 },
    { lat: 48.498163, lng: 19.9611317 },
    { lat: 48.4978056, lng: 19.9613648 },
    { lat: 48.4977462, lng: 19.9613162 },
    { lat: 48.4977381, lng: 19.9613937 },
    { lat: 48.4979637, lng: 19.9616416 },
    { lat: 48.4981024, lng: 19.9623863 },
    { lat: 48.498329, lng: 19.9625259 },
    { lat: 48.4985331, lng: 19.9627429 },
    { lat: 48.4987815, lng: 19.9634745 },
    { lat: 48.4987025, lng: 19.9637375 },
    { lat: 48.4987826, lng: 19.9643265 },
    { lat: 48.4986502, lng: 19.964791 },
    { lat: 48.4987923, lng: 19.9651134 },
    { lat: 48.4988066, lng: 19.9656928 },
    { lat: 48.4987399, lng: 19.965957 },
    { lat: 48.4990521, lng: 19.9675052 },
    { lat: 48.4995824, lng: 19.9682963 },
    { lat: 48.5000022, lng: 19.9684223 },
    { lat: 48.5002764, lng: 19.968671 },
    { lat: 48.5004679, lng: 19.968701 },
    { lat: 48.5011054, lng: 19.9691778 },
    { lat: 48.5014061, lng: 19.9692776 },
    { lat: 48.5014515, lng: 19.9693699 },
    { lat: 48.5018804, lng: 19.9696031 },
    { lat: 48.5019453, lng: 19.9697262 },
    { lat: 48.5021276, lng: 19.9696388 },
    { lat: 48.5022454, lng: 19.9696549 },
    { lat: 48.5023355, lng: 19.9698448 },
    { lat: 48.502482, lng: 19.9698684 },
    { lat: 48.5025419, lng: 19.969981 },
    { lat: 48.503282, lng: 19.9697408 },
    { lat: 48.5034729, lng: 19.9695666 },
    { lat: 48.5044499, lng: 19.9694706 },
    { lat: 48.5045514, lng: 19.9695764 },
    { lat: 48.5050337, lng: 19.9692372 },
    { lat: 48.5052727, lng: 19.9686818 },
    { lat: 48.5054798, lng: 19.9686485 },
    { lat: 48.5057722, lng: 19.9683679 },
    { lat: 48.505999, lng: 19.9685906 },
    { lat: 48.5061258, lng: 19.9685302 },
    { lat: 48.5070216, lng: 19.9693347 },
    { lat: 48.5076315, lng: 19.9703332 },
    { lat: 48.5080242, lng: 19.9711475 },
    { lat: 48.5082686, lng: 19.9719491 },
    { lat: 48.5084015, lng: 19.9726703 },
    { lat: 48.5084182, lng: 19.9734613 },
    { lat: 48.5085003, lng: 19.9738566 },
    { lat: 48.5084839, lng: 19.9747499 },
    { lat: 48.5090515, lng: 19.9773387 },
    { lat: 48.5092008, lng: 19.9777273 },
    { lat: 48.5092951, lng: 19.9783056 },
    { lat: 48.5095085, lng: 19.9789602 },
    { lat: 48.5098033, lng: 19.9795921 },
    { lat: 48.5098984, lng: 19.9799003 },
    { lat: 48.5103595, lng: 19.9832749 },
    { lat: 48.5106931, lng: 19.9847775 },
    { lat: 48.5109121, lng: 19.9855393 },
    { lat: 48.5111828, lng: 19.9856488 },
    { lat: 48.5116918, lng: 19.9857296 },
    { lat: 48.5119418, lng: 19.9857256 },
    { lat: 48.5124163, lng: 19.9855958 },
    { lat: 48.5129329, lng: 19.9856176 },
    { lat: 48.5135055, lng: 19.9855431 },
    { lat: 48.5138753, lng: 19.9854723 },
    { lat: 48.5143786, lng: 19.9852215 },
    { lat: 48.5145874, lng: 19.9852087 },
    { lat: 48.5147898, lng: 19.9853048 },
    { lat: 48.5158446, lng: 19.9848106 },
    { lat: 48.5164333, lng: 19.9847988 },
    { lat: 48.5166594, lng: 19.9848974 },
    { lat: 48.517377, lng: 19.9849199 },
    { lat: 48.5177621, lng: 19.9850015 },
    { lat: 48.5180636, lng: 19.984935 },
    { lat: 48.5184578, lng: 19.9849913 },
    { lat: 48.5184848, lng: 19.9849329 },
    { lat: 48.5184179, lng: 19.9848675 },
    { lat: 48.5185715, lng: 19.9844787 },
    { lat: 48.518914, lng: 19.9839706 },
    { lat: 48.519457, lng: 19.9828781 },
    { lat: 48.5198158, lng: 19.9823297 },
    { lat: 48.5201281, lng: 19.982066 },
    { lat: 48.5203245, lng: 19.9820487 },
    { lat: 48.5209102, lng: 19.9816204 },
    { lat: 48.5211135, lng: 19.9814184 },
    { lat: 48.5215092, lng: 19.9808671 },
    { lat: 48.5217235, lng: 19.9804746 },
    { lat: 48.5224751, lng: 19.9802885 },
    { lat: 48.5230184, lng: 19.9798446 },
    { lat: 48.5235745, lng: 19.9791942 },
    { lat: 48.5241917, lng: 19.9786836 },
    { lat: 48.5247889, lng: 19.9778042 },
    { lat: 48.5254433, lng: 19.9761205 },
    { lat: 48.5256879, lng: 19.9750573 },
    { lat: 48.525678, lng: 19.974527 },
    { lat: 48.5258577, lng: 19.9737401 },
    { lat: 48.5263851, lng: 19.9725144 },
    { lat: 48.5264566, lng: 19.971945 },
    { lat: 48.5264209, lng: 19.9714466 },
    { lat: 48.5264671, lng: 19.9711536 },
    { lat: 48.5266053, lng: 19.9707978 },
    { lat: 48.5267682, lng: 19.9699048 },
    { lat: 48.5272971, lng: 19.9689579 },
    { lat: 48.527625, lng: 19.9681369 },
    { lat: 48.5280762, lng: 19.9660168 },
    { lat: 48.5288399, lng: 19.9631918 },
    { lat: 48.5290231, lng: 19.960916 },
    { lat: 48.5290832, lng: 19.9605776 },
    { lat: 48.5294335, lng: 19.959513 },
    { lat: 48.5294049, lng: 19.9588089 },
    { lat: 48.5299209, lng: 19.9575865 },
    { lat: 48.5301707, lng: 19.956807 },
    { lat: 48.5303085, lng: 19.9565289 },
    { lat: 48.5302907, lng: 19.9563188 },
    { lat: 48.5303449, lng: 19.956084 },
    { lat: 48.530648, lng: 19.9559284 },
    { lat: 48.5308342, lng: 19.9557478 },
    { lat: 48.5314843, lng: 19.9559384 },
    { lat: 48.5319485, lng: 19.9559113 },
    { lat: 48.5322426, lng: 19.9558059 },
    { lat: 48.5334666, lng: 19.9549986 },
    { lat: 48.5336463, lng: 19.9546931 },
    { lat: 48.5336478, lng: 19.9545952 },
    { lat: 48.5338051, lng: 19.9542136 },
    { lat: 48.53387, lng: 19.9537777 },
    { lat: 48.5341051, lng: 19.9534213 },
    { lat: 48.5345366, lng: 19.9530529 },
    { lat: 48.5348173, lng: 19.9526851 },
    { lat: 48.5351319, lng: 19.9518924 },
    { lat: 48.5354738, lng: 19.9512951 },
    { lat: 48.5356091, lng: 19.9505261 },
    { lat: 48.5357234, lng: 19.9505951 },
    { lat: 48.5359505, lng: 19.94976 },
    { lat: 48.5360506, lng: 19.9498097 },
    { lat: 48.5362616, lng: 19.9489673 },
    { lat: 48.5365327, lng: 19.9486085 },
    { lat: 48.53682, lng: 19.9476574 },
    { lat: 48.5370042, lng: 19.94751 },
    { lat: 48.5372142, lng: 19.947124 },
    { lat: 48.5376556, lng: 19.9466657 },
    { lat: 48.5381328, lng: 19.9457798 },
    { lat: 48.5382461, lng: 19.9457447 },
    { lat: 48.5384267, lng: 19.9452738 },
    { lat: 48.5385545, lng: 19.9451257 },
    { lat: 48.5388336, lng: 19.9450146 },
    { lat: 48.539232, lng: 19.9441789 },
    { lat: 48.5399032, lng: 19.9431972 },
    { lat: 48.5401713, lng: 19.9422112 },
    { lat: 48.5408516, lng: 19.9410452 },
    { lat: 48.5410714, lng: 19.940355 },
    { lat: 48.5413996, lng: 19.9388577 },
    { lat: 48.5414207, lng: 19.9381048 },
    { lat: 48.5415684, lng: 19.937525 },
    { lat: 48.5416812, lng: 19.937319 },
    { lat: 48.5420796, lng: 19.9369599 },
    { lat: 48.5423136, lng: 19.9362118 },
    { lat: 48.5425276, lng: 19.9358158 },
    { lat: 48.5437905, lng: 19.9343897 },
    { lat: 48.5439783, lng: 19.9340871 },
    { lat: 48.5441892, lng: 19.9335609 },
    { lat: 48.5454085, lng: 19.9325133 },
    { lat: 48.5455749, lng: 19.931976 },
    { lat: 48.5461935, lng: 19.9309454 },
    { lat: 48.5471807, lng: 19.9288101 },
    { lat: 48.547906, lng: 19.9278035 },
    { lat: 48.5488979, lng: 19.9268103 },
    { lat: 48.5493312, lng: 19.9261946 },
    { lat: 48.5495486, lng: 19.9256111 },
    { lat: 48.5495971, lng: 19.9252932 },
    { lat: 48.5497609, lng: 19.9249105 },
    { lat: 48.5500197, lng: 19.9246906 },
    { lat: 48.5502866, lng: 19.9246097 },
    { lat: 48.551005, lng: 19.9239404 },
    { lat: 48.5516488, lng: 19.9231888 },
    { lat: 48.5524282, lng: 19.9226014 },
    { lat: 48.5530478, lng: 19.9219185 },
    { lat: 48.553636, lng: 19.9211025 },
    { lat: 48.553906, lng: 19.9208516 },
    { lat: 48.5546402, lng: 19.9195801 },
    { lat: 48.5556035, lng: 19.9185756 },
    { lat: 48.5573919, lng: 19.9158691 },
    { lat: 48.5580731, lng: 19.9141478 },
    { lat: 48.5582928, lng: 19.9140692 },
    { lat: 48.5584873, lng: 19.9139313 },
    { lat: 48.5599588, lng: 19.9136678 },
    { lat: 48.5607042, lng: 19.9136098 },
    { lat: 48.5608529, lng: 19.9133721 },
    { lat: 48.5613146, lng: 19.9130002 },
    { lat: 48.5617718, lng: 19.9129167 },
    { lat: 48.5620372, lng: 19.9126688 },
    { lat: 48.5626394, lng: 19.9118611 },
    { lat: 48.5631276, lng: 19.9099238 },
    { lat: 48.5630793, lng: 19.9098207 },
    { lat: 48.5642823, lng: 19.9065995 },
    { lat: 48.5645691, lng: 19.9032437 },
    { lat: 48.5649118, lng: 19.9021431 },
    { lat: 48.5650652, lng: 19.9006212 },
    { lat: 48.5651899, lng: 19.9002183 },
    { lat: 48.5652141, lng: 19.8996756 },
    { lat: 48.5655174, lng: 19.8988704 },
    { lat: 48.5656137, lng: 19.8987747 },
    { lat: 48.5661746, lng: 19.8976666 },
    { lat: 48.5664591, lng: 19.8972893 },
    { lat: 48.5667102, lng: 19.8967346 },
    { lat: 48.5683671, lng: 19.8919427 },
    { lat: 48.5685362, lng: 19.8909744 },
    { lat: 48.5685942, lng: 19.8895684 },
    { lat: 48.5686894, lng: 19.8888445 },
    { lat: 48.5699734, lng: 19.8849561 },
    { lat: 48.570287, lng: 19.8835717 },
    { lat: 48.5699042, lng: 19.8830541 },
    { lat: 48.5695173, lng: 19.882098 },
    { lat: 48.5689728, lng: 19.883374 },
    { lat: 48.5676512, lng: 19.8852866 },
    { lat: 48.5672849, lng: 19.8867291 },
    { lat: 48.5664613, lng: 19.8876434 },
    { lat: 48.5662734, lng: 19.8887487 },
    { lat: 48.5658555, lng: 19.8893033 },
    { lat: 48.5657175, lng: 19.8893726 },
    { lat: 48.5652768, lng: 19.8890214 },
    { lat: 48.5638094, lng: 19.8874304 },
    { lat: 48.5634042, lng: 19.887126 },
    { lat: 48.5631428, lng: 19.8866788 },
    { lat: 48.562832, lng: 19.8865262 },
    { lat: 48.5620863, lng: 19.886632 },
    { lat: 48.5615647, lng: 19.8868926 },
    { lat: 48.560859, lng: 19.8879305 },
    { lat: 48.5601765, lng: 19.8884913 },
    { lat: 48.5595683, lng: 19.8881476 },
    { lat: 48.5586563, lng: 19.8878726 },
    { lat: 48.557782, lng: 19.8880544 },
    { lat: 48.5566106, lng: 19.8881642 },
    { lat: 48.5560632, lng: 19.8883534 },
    { lat: 48.555441, lng: 19.8887624 },
    { lat: 48.5546507, lng: 19.8894343 },
    { lat: 48.5543499, lng: 19.889566 },
    { lat: 48.5541272, lng: 19.8898867 },
    { lat: 48.5528289, lng: 19.8897973 },
    { lat: 48.5517715, lng: 19.8894376 },
    { lat: 48.5506493, lng: 19.8885704 },
    { lat: 48.5495218, lng: 19.8886802 },
    { lat: 48.548949, lng: 19.8890829 },
    { lat: 48.5463798, lng: 19.8898866 },
    { lat: 48.5458252, lng: 19.8904838 },
    { lat: 48.5448462, lng: 19.8905613 },
    { lat: 48.5438086, lng: 19.8914266 },
    { lat: 48.5428646, lng: 19.8910044 },
    { lat: 48.5422561, lng: 19.8904795 },
    { lat: 48.5417227, lng: 19.8905154 },
    { lat: 48.5411606, lng: 19.8907286 },
    { lat: 48.5394633, lng: 19.890373 },
    { lat: 48.5383101, lng: 19.8899306 },
    { lat: 48.5373351, lng: 19.8898936 },
    { lat: 48.5362753, lng: 19.8914253 },
    { lat: 48.5359483, lng: 19.8917607 },
    { lat: 48.5337988, lng: 19.8923352 },
    { lat: 48.5331488, lng: 19.8927026 },
    { lat: 48.5323193, lng: 19.8936385 },
    { lat: 48.5312403, lng: 19.8943565 },
    { lat: 48.5308091, lng: 19.8945171 },
    { lat: 48.5299671, lng: 19.8953784 },
    { lat: 48.5294231, lng: 19.8955617 },
    { lat: 48.5289487, lng: 19.8958726 },
    { lat: 48.5285931, lng: 19.8959857 },
    { lat: 48.5282602, lng: 19.896184 },
    { lat: 48.5273625, lng: 19.8969014 },
    { lat: 48.526589, lng: 19.8977236 },
    { lat: 48.5260867, lng: 19.8987277 },
    { lat: 48.5260566, lng: 19.8997882 },
    { lat: 48.5255183, lng: 19.9025258 },
    { lat: 48.524609, lng: 19.9058129 },
    { lat: 48.5244424, lng: 19.9061858 },
    { lat: 48.5242625, lng: 19.9092755 },
    { lat: 48.5241414, lng: 19.9098107 },
    { lat: 48.5238727, lng: 19.9104562 },
    { lat: 48.5230283, lng: 19.9118635 },
    { lat: 48.5221747, lng: 19.914299 },
    { lat: 48.5210358, lng: 19.915779 },
    { lat: 48.5198092, lng: 19.9166778 },
    { lat: 48.5187409, lng: 19.9173017 },
    { lat: 48.5174262, lng: 19.9176702 },
    { lat: 48.516996, lng: 19.9180717 },
    { lat: 48.5167166, lng: 19.9182295 },
    { lat: 48.5161743, lng: 19.9183203 },
    { lat: 48.5152729, lng: 19.9180539 },
    { lat: 48.5147678, lng: 19.9177016 },
    { lat: 48.5143857, lng: 19.9176423 },
    { lat: 48.5142546, lng: 19.9177129 },
    { lat: 48.5140376, lng: 19.9176177 },
    { lat: 48.5135278, lng: 19.9171269 },
    { lat: 48.512627, lng: 19.9166903 },
    { lat: 48.5114602, lng: 19.916881 },
    { lat: 48.5106579, lng: 19.9170883 },
    { lat: 48.5102673, lng: 19.9171772 },
    { lat: 48.5102117, lng: 19.9177716 },
    { lat: 48.510237, lng: 19.9183149 },
    { lat: 48.5101786, lng: 19.918766 },
    { lat: 48.510161, lng: 19.9199359 },
    { lat: 48.5097758, lng: 19.9211523 },
    { lat: 48.5095106, lng: 19.9216924 },
    { lat: 48.5093672, lng: 19.9223212 },
    { lat: 48.5090169, lng: 19.9227977 },
    { lat: 48.5089745, lng: 19.9233649 },
    { lat: 48.5090457, lng: 19.9237381 },
    { lat: 48.509023, lng: 19.9243134 },
    { lat: 48.5083017, lng: 19.9252303 },
    { lat: 48.5081772, lng: 19.9254811 },
    { lat: 48.508086, lng: 19.9254853 },
    { lat: 48.5074945, lng: 19.9261403 },
    { lat: 48.5073436, lng: 19.9262234 },
    { lat: 48.5072375, lng: 19.9264298 },
    { lat: 48.5077359, lng: 19.9270737 },
    { lat: 48.5075796, lng: 19.9279822 },
    { lat: 48.5072091, lng: 19.9276645 },
    { lat: 48.5059078, lng: 19.9290633 },
    { lat: 48.5056127, lng: 19.9293003 },
    { lat: 48.5057245, lng: 19.9296255 },
    { lat: 48.5057535, lng: 19.9301727 },
    { lat: 48.5057183, lng: 19.9303267 },
    { lat: 48.5052735, lng: 19.9309408 },
    { lat: 48.5042441, lng: 19.9319516 },
    { lat: 48.5042432, lng: 19.9323016 },
    { lat: 48.5041958, lng: 19.9325349 },
    { lat: 48.5039763, lng: 19.9331738 },
    { lat: 48.5036616, lng: 19.9338773 },
    { lat: 48.5035689, lng: 19.9339527 },
    { lat: 48.503359, lng: 19.9346823 },
    { lat: 48.5032711, lng: 19.9352406 },
    { lat: 48.5029226, lng: 19.9358944 },
    { lat: 48.5027429, lng: 19.9379681 },
    { lat: 48.5026767, lng: 19.9398316 },
    { lat: 48.502643, lng: 19.9406273 },
  ],
  Hodejovec: [
    { lat: 48.2863989, lng: 20.0151993 },
    { lat: 48.2874075, lng: 20.0145407 },
    { lat: 48.2875021, lng: 20.0143828 },
    { lat: 48.2875902, lng: 20.0139844 },
    { lat: 48.2878855, lng: 20.013317 },
    { lat: 48.2880229, lng: 20.0132896 },
    { lat: 48.2881622, lng: 20.0130147 },
    { lat: 48.2884072, lng: 20.0121936 },
    { lat: 48.2884844, lng: 20.0108426 },
    { lat: 48.288409, lng: 20.0098526 },
    { lat: 48.288454, lng: 20.0093736 },
    { lat: 48.2888354, lng: 20.0084509 },
    { lat: 48.2889631, lng: 20.0082453 },
    { lat: 48.2895043, lng: 20.0066792 },
    { lat: 48.2896503, lng: 20.0065062 },
    { lat: 48.2901241, lng: 20.0054533 },
    { lat: 48.2901901, lng: 20.0048305 },
    { lat: 48.2899974, lng: 20.0038633 },
    { lat: 48.2897473, lng: 20.003541 },
    { lat: 48.2894574, lng: 20.0027011 },
    { lat: 48.2895047, lng: 20.0024349 },
    { lat: 48.2893046, lng: 20.0020425 },
    { lat: 48.2889386, lng: 20.0011104 },
    { lat: 48.2884934, lng: 20.0003538 },
    { lat: 48.2882471, lng: 20.0004486 },
    { lat: 48.2878531, lng: 20.0011197 },
    { lat: 48.2873201, lng: 20.0014093 },
    { lat: 48.2872402, lng: 20.0013563 },
    { lat: 48.2868872, lng: 20.0014279 },
    { lat: 48.2865446, lng: 20.0016669 },
    { lat: 48.2861711, lng: 20.0021578 },
    { lat: 48.2858217, lng: 20.0025332 },
    { lat: 48.2857209, lng: 20.0025714 },
    { lat: 48.2856613, lng: 20.0025746 },
    { lat: 48.2856606, lng: 20.002691 },
    { lat: 48.2855496, lng: 20.0039348 },
    { lat: 48.2850243, lng: 20.0035728 },
    { lat: 48.2849352, lng: 20.0040541 },
    { lat: 48.2839743, lng: 20.004134 },
    { lat: 48.2837374, lng: 20.0041016 },
    { lat: 48.2826247, lng: 20.0036781 },
    { lat: 48.2815336, lng: 20.0035811 },
    { lat: 48.281485, lng: 20.005319 },
    { lat: 48.2814005, lng: 20.0055045 },
    { lat: 48.2812368, lng: 20.0049853 },
    { lat: 48.2810437, lng: 20.0040653 },
    { lat: 48.2809037, lng: 20.0026925 },
    { lat: 48.280528, lng: 20.0024946 },
    { lat: 48.2802865, lng: 20.0015463 },
    { lat: 48.2799236, lng: 20.0006884 },
    { lat: 48.2797153, lng: 19.9994873 },
    { lat: 48.2792852, lng: 19.9982397 },
    { lat: 48.2785221, lng: 19.9975855 },
    { lat: 48.2784794, lng: 19.9973742 },
    { lat: 48.2780886, lng: 19.9966056 },
    { lat: 48.277906, lng: 19.9958007 },
    { lat: 48.2776344, lng: 19.9955032 },
    { lat: 48.2776098, lng: 19.9953789 },
    { lat: 48.2774048, lng: 19.9950571 },
    { lat: 48.2760566, lng: 19.993991 },
    { lat: 48.2754447, lng: 19.9933763 },
    { lat: 48.2752739, lng: 19.9930644 },
    { lat: 48.2749886, lng: 19.9916716 },
    { lat: 48.2747508, lng: 19.9909487 },
    { lat: 48.2743808, lng: 19.9903484 },
    { lat: 48.2734664, lng: 19.9891819 },
    { lat: 48.2731059, lng: 19.9881697 },
    { lat: 48.2730373, lng: 19.9876133 },
    { lat: 48.2725148, lng: 19.9858867 },
    { lat: 48.2724591, lng: 19.9855812 },
    { lat: 48.2724498, lng: 19.9849929 },
    { lat: 48.2721561, lng: 19.9837758 },
    { lat: 48.2718024, lng: 19.9830537 },
    { lat: 48.2713222, lng: 19.9824335 },
    { lat: 48.2707868, lng: 19.9815724 },
    { lat: 48.2707697, lng: 19.979682 },
    { lat: 48.2706114, lng: 19.9792161 },
    { lat: 48.2707675, lng: 19.9782759 },
    { lat: 48.2707488, lng: 19.9777275 },
    { lat: 48.2706647, lng: 19.9767794 },
    { lat: 48.2705779, lng: 19.9762972 },
    { lat: 48.2704133, lng: 19.9758716 },
    { lat: 48.2703652, lng: 19.9749204 },
    { lat: 48.2704451, lng: 19.974201 },
    { lat: 48.2704002, lng: 19.9733305 },
    { lat: 48.2704387, lng: 19.9726461 },
    { lat: 48.270302, lng: 19.9718675 },
    { lat: 48.2697906, lng: 19.9710403 },
    { lat: 48.2689054, lng: 19.9694567 },
    { lat: 48.2683382, lng: 19.9688845 },
    { lat: 48.2677329, lng: 19.9688233 },
    { lat: 48.2669147, lng: 19.9681778 },
    { lat: 48.2665154, lng: 19.9680345 },
    { lat: 48.2662708, lng: 19.9678177 },
    { lat: 48.2659937, lng: 19.967685 },
    { lat: 48.2656304, lng: 19.9676956 },
    { lat: 48.2655024, lng: 19.9677626 },
    { lat: 48.2651642, lng: 19.968446 },
    { lat: 48.2647954, lng: 19.9693915 },
    { lat: 48.264478, lng: 19.9705666 },
    { lat: 48.2639475, lng: 19.9720274 },
    { lat: 48.2634164, lng: 19.9740882 },
    { lat: 48.2627097, lng: 19.9760995 },
    { lat: 48.2624962, lng: 19.9764016 },
    { lat: 48.2623376, lng: 19.9764322 },
    { lat: 48.2619164, lng: 19.9770195 },
    { lat: 48.2615829, lng: 19.9776335 },
    { lat: 48.2609673, lng: 19.9780971 },
    { lat: 48.2596541, lng: 19.9785585 },
    { lat: 48.2592369, lng: 19.9791857 },
    { lat: 48.2589669, lng: 19.9797228 },
    { lat: 48.258559, lng: 19.9802812 },
    { lat: 48.2581484, lng: 19.9806133 },
    { lat: 48.2575138, lng: 19.9812685 },
    { lat: 48.2572444, lng: 19.9814002 },
    { lat: 48.2572592, lng: 19.9822015 },
    { lat: 48.2568653, lng: 19.9830071 },
    { lat: 48.2563729, lng: 19.9838548 },
    { lat: 48.2560341, lng: 19.9849015 },
    { lat: 48.2552661, lng: 19.9854965 },
    { lat: 48.2539479, lng: 19.9861242 },
    { lat: 48.2539419, lng: 19.9861453 },
    { lat: 48.2538843, lng: 19.9869425 },
    { lat: 48.2536323, lng: 19.9872679 },
    { lat: 48.2529839, lng: 19.9885821 },
    { lat: 48.2527815, lng: 19.9890484 },
    { lat: 48.2525305, lng: 19.9899015 },
    { lat: 48.2523415, lng: 19.9902541 },
    { lat: 48.2509484, lng: 19.991625 },
    { lat: 48.2508315, lng: 19.9920309 },
    { lat: 48.2508307, lng: 19.9928895 },
    { lat: 48.2507756, lng: 19.9936638 },
    { lat: 48.2508139, lng: 19.9939829 },
    { lat: 48.2508965, lng: 19.9941571 },
    { lat: 48.2514783, lng: 19.9939242 },
    { lat: 48.2517939, lng: 19.9940078 },
    { lat: 48.2520493, lng: 19.9942158 },
    { lat: 48.2525134, lng: 19.9944062 },
    { lat: 48.2525719, lng: 19.9951472 },
    { lat: 48.2534697, lng: 19.9973863 },
    { lat: 48.2534964, lng: 19.9977552 },
    { lat: 48.2534161, lng: 19.999399 },
    { lat: 48.253358, lng: 19.9998114 },
    { lat: 48.2526237, lng: 20.0022755 },
    { lat: 48.2526612, lng: 20.0034851 },
    { lat: 48.2513006, lng: 20.0038105 },
    { lat: 48.24926, lng: 20.0048647 },
    { lat: 48.2488583, lng: 20.0050115 },
    { lat: 48.2484354, lng: 20.0050805 },
    { lat: 48.2481483, lng: 20.0054038 },
    { lat: 48.2474993, lng: 20.0065511 },
    { lat: 48.2468781, lng: 20.0079249 },
    { lat: 48.2466704, lng: 20.0085881 },
    { lat: 48.2465859, lng: 20.0093619 },
    { lat: 48.2466873, lng: 20.0097333 },
    { lat: 48.2478831, lng: 20.0103097 },
    { lat: 48.2490884, lng: 20.0105815 },
    { lat: 48.2494074, lng: 20.0108235 },
    { lat: 48.2498498, lng: 20.0113508 },
    { lat: 48.2501599, lng: 20.0120012 },
    { lat: 48.2504546, lng: 20.0123686 },
    { lat: 48.2512263, lng: 20.0129861 },
    { lat: 48.2531264, lng: 20.0136456 },
    { lat: 48.2541531, lng: 20.0138439 },
    { lat: 48.2545123, lng: 20.0140048 },
    { lat: 48.2547985, lng: 20.0145975 },
    { lat: 48.2550088, lng: 20.0148537 },
    { lat: 48.2553027, lng: 20.0149847 },
    { lat: 48.25607, lng: 20.0150149 },
    { lat: 48.2560731, lng: 20.0150605 },
    { lat: 48.2563015, lng: 20.0149524 },
    { lat: 48.2566456, lng: 20.0149111 },
    { lat: 48.2570819, lng: 20.0150531 },
    { lat: 48.2573597, lng: 20.0150403 },
    { lat: 48.2580959, lng: 20.0146237 },
    { lat: 48.2588102, lng: 20.0144387 },
    { lat: 48.2591551, lng: 20.0144454 },
    { lat: 48.2597132, lng: 20.0143231 },
    { lat: 48.259978, lng: 20.0143504 },
    { lat: 48.2616295, lng: 20.0146507 },
    { lat: 48.2621506, lng: 20.0155511 },
    { lat: 48.2623725, lng: 20.0157872 },
    { lat: 48.2630195, lng: 20.0162304 },
    { lat: 48.2633397, lng: 20.016582 },
    { lat: 48.2635599, lng: 20.0167055 },
    { lat: 48.2638522, lng: 20.0167674 },
    { lat: 48.2646998, lng: 20.0164866 },
    { lat: 48.265533, lng: 20.0164802 },
    { lat: 48.265932, lng: 20.0163531 },
    { lat: 48.2665185, lng: 20.0163392 },
    { lat: 48.2668936, lng: 20.0165025 },
    { lat: 48.2672688, lng: 20.0168815 },
    { lat: 48.2680543, lng: 20.0173969 },
    { lat: 48.2685, lng: 20.0179746 },
    { lat: 48.2687338, lng: 20.0181792 },
    { lat: 48.2689147, lng: 20.0184351 },
    { lat: 48.2691207, lng: 20.0188892 },
    { lat: 48.2695687, lng: 20.0193883 },
    { lat: 48.2706297, lng: 20.0196284 },
    { lat: 48.2707284, lng: 20.0197538 },
    { lat: 48.2708383, lng: 20.0201169 },
    { lat: 48.2708281, lng: 20.0208406 },
    { lat: 48.2709436, lng: 20.021394 },
    { lat: 48.2711146, lng: 20.0218676 },
    { lat: 48.2716454, lng: 20.0224611 },
    { lat: 48.2718921, lng: 20.0228396 },
    { lat: 48.2721288, lng: 20.0230302 },
    { lat: 48.272462, lng: 20.0230561 },
    { lat: 48.2728089, lng: 20.0229453 },
    { lat: 48.2730807, lng: 20.0230618 },
    { lat: 48.2739195, lng: 20.0239368 },
    { lat: 48.2742188, lng: 20.0241166 },
    { lat: 48.2745134, lng: 20.0243982 },
    { lat: 48.2753626, lng: 20.0257405 },
    { lat: 48.275819, lng: 20.0262779 },
    { lat: 48.2764592, lng: 20.0266112 },
    { lat: 48.2764876, lng: 20.027558 },
    { lat: 48.2766809, lng: 20.0289705 },
    { lat: 48.2769545, lng: 20.0296121 },
    { lat: 48.2771815, lng: 20.0297849 },
    { lat: 48.2773868, lng: 20.0298473 },
    { lat: 48.277534, lng: 20.0298108 },
    { lat: 48.2784614, lng: 20.0299287 },
    { lat: 48.2789504, lng: 20.0300882 },
    { lat: 48.2797246, lng: 20.0305561 },
    { lat: 48.2802625, lng: 20.0311629 },
    { lat: 48.2818922, lng: 20.031694 },
    { lat: 48.2823825, lng: 20.0321066 },
    { lat: 48.282922, lng: 20.0323463 },
    { lat: 48.2828699, lng: 20.0325793 },
    { lat: 48.282947, lng: 20.0332641 },
    { lat: 48.283174, lng: 20.0334939 },
    { lat: 48.2839749, lng: 20.0335718 },
    { lat: 48.2847861, lng: 20.0341679 },
    { lat: 48.2851939, lng: 20.0341709 },
    { lat: 48.2851891, lng: 20.0341081 },
    { lat: 48.2856264, lng: 20.0340316 },
    { lat: 48.2859645, lng: 20.0341466 },
    { lat: 48.286434, lng: 20.0340629 },
    { lat: 48.2865589, lng: 20.0339999 },
    { lat: 48.2868603, lng: 20.0335029 },
    { lat: 48.2868348, lng: 20.0321178 },
    { lat: 48.2876541, lng: 20.0322349 },
    { lat: 48.2877929, lng: 20.0321568 },
    { lat: 48.2884558, lng: 20.0316608 },
    { lat: 48.2886247, lng: 20.0308261 },
    { lat: 48.2889676, lng: 20.0306757 },
    { lat: 48.2892839, lng: 20.0308851 },
    { lat: 48.2898225, lng: 20.0303491 },
    { lat: 48.2899168, lng: 20.030318 },
    { lat: 48.2906668, lng: 20.0317624 },
    { lat: 48.2908541, lng: 20.0323334 },
    { lat: 48.2910919, lng: 20.0327153 },
    { lat: 48.2914292, lng: 20.0328089 },
    { lat: 48.291599, lng: 20.0331229 },
    { lat: 48.2918687, lng: 20.0333788 },
    { lat: 48.2919942, lng: 20.03398 },
    { lat: 48.2919877, lng: 20.0345749 },
    { lat: 48.2921471, lng: 20.0356423 },
    { lat: 48.2922853, lng: 20.036012 },
    { lat: 48.2924199, lng: 20.0362113 },
    { lat: 48.2934508, lng: 20.0372135 },
    { lat: 48.2931925, lng: 20.037612 },
    { lat: 48.2925759, lng: 20.0382702 },
    { lat: 48.292295, lng: 20.0386665 },
    { lat: 48.292721, lng: 20.039455 },
    { lat: 48.2931609, lng: 20.0399273 },
    { lat: 48.2933859, lng: 20.0395673 },
    { lat: 48.2935763, lng: 20.0393283 },
    { lat: 48.2948511, lng: 20.0386979 },
    { lat: 48.2955594, lng: 20.0386632 },
    { lat: 48.2961777, lng: 20.0388527 },
    { lat: 48.2961495, lng: 20.0384709 },
    { lat: 48.2959735, lng: 20.038411 },
    { lat: 48.2959191, lng: 20.0378391 },
    { lat: 48.2959235, lng: 20.0372147 },
    { lat: 48.2958933, lng: 20.0371708 },
    { lat: 48.2959081, lng: 20.0369034 },
    { lat: 48.2959435, lng: 20.0368573 },
    { lat: 48.2958434, lng: 20.0361627 },
    { lat: 48.2958327, lng: 20.035879 },
    { lat: 48.2956803, lng: 20.0342296 },
    { lat: 48.2953475, lng: 20.033518 },
    { lat: 48.2952658, lng: 20.0331564 },
    { lat: 48.2950013, lng: 20.0325158 },
    { lat: 48.2948803, lng: 20.0323171 },
    { lat: 48.2948273, lng: 20.0320207 },
    { lat: 48.2946867, lng: 20.0316999 },
    { lat: 48.2945153, lng: 20.0315077 },
    { lat: 48.2945298, lng: 20.0312899 },
    { lat: 48.2941563, lng: 20.0302521 },
    { lat: 48.2941401, lng: 20.0298648 },
    { lat: 48.2938863, lng: 20.0292389 },
    { lat: 48.2938517, lng: 20.0289482 },
    { lat: 48.2936977, lng: 20.028714 },
    { lat: 48.2936474, lng: 20.0280505 },
    { lat: 48.2933645, lng: 20.028046 },
    { lat: 48.2932642, lng: 20.0277534 },
    { lat: 48.2932143, lng: 20.0277474 },
    { lat: 48.2931774, lng: 20.0278581 },
    { lat: 48.2931091, lng: 20.0278391 },
    { lat: 48.2928822, lng: 20.0276397 },
    { lat: 48.2927811, lng: 20.0278084 },
    { lat: 48.2926424, lng: 20.0276463 },
    { lat: 48.2925415, lng: 20.0277906 },
    { lat: 48.2925658, lng: 20.0279921 },
    { lat: 48.2925363, lng: 20.0280433 },
    { lat: 48.2923593, lng: 20.0280094 },
    { lat: 48.2922609, lng: 20.028163 },
    { lat: 48.2921286, lng: 20.0280208 },
    { lat: 48.2920335, lng: 20.0281946 },
    { lat: 48.2919894, lng: 20.028191 },
    { lat: 48.2919572, lng: 20.0279843 },
    { lat: 48.2917392, lng: 20.0281052 },
    { lat: 48.2914603, lng: 20.0280911 },
    { lat: 48.2914464, lng: 20.0278378 },
    { lat: 48.2913206, lng: 20.0277159 },
    { lat: 48.2914313, lng: 20.0269669 },
    { lat: 48.2912297, lng: 20.0257616 },
    { lat: 48.2910942, lng: 20.0252424 },
    { lat: 48.2910932, lng: 20.024436 },
    { lat: 48.2908344, lng: 20.02372 },
    { lat: 48.2905363, lng: 20.0232982 },
    { lat: 48.289406, lng: 20.0225352 },
    { lat: 48.2889227, lng: 20.0223767 },
    { lat: 48.2888512, lng: 20.022417 },
    { lat: 48.2884372, lng: 20.0222424 },
    { lat: 48.2882655, lng: 20.0220722 },
    { lat: 48.2878328, lng: 20.021368 },
    { lat: 48.287376, lng: 20.0207932 },
    { lat: 48.2870698, lng: 20.0205787 },
    { lat: 48.286269, lng: 20.0202601 },
    { lat: 48.2861332, lng: 20.0200858 },
    { lat: 48.2860489, lng: 20.0196626 },
    { lat: 48.2859154, lng: 20.017181 },
    { lat: 48.2861981, lng: 20.0154379 },
    { lat: 48.2863989, lng: 20.0151993 },
  ],
  Abovce: [
    { lat: 48.301539, lng: 20.3374306 },
    { lat: 48.301608, lng: 20.337449 },
    { lat: 48.301667, lng: 20.337465 },
    { lat: 48.301451, lng: 20.339458 },
    { lat: 48.301876, lng: 20.340112 },
    { lat: 48.302278, lng: 20.340834 },
    { lat: 48.302514, lng: 20.341299 },
    { lat: 48.303052, lng: 20.342419 },
    { lat: 48.30352, lng: 20.341972 },
    { lat: 48.303575, lng: 20.342098 },
    { lat: 48.303622, lng: 20.342204 },
    { lat: 48.303668, lng: 20.342308 },
    { lat: 48.303219, lng: 20.342739 },
    { lat: 48.303719, lng: 20.343891 },
    { lat: 48.303802, lng: 20.343822 },
    { lat: 48.304234, lng: 20.344727 },
    { lat: 48.304464, lng: 20.344528 },
    { lat: 48.304831, lng: 20.345289 },
    { lat: 48.30512, lng: 20.345851 },
    { lat: 48.30514, lng: 20.345829 },
    { lat: 48.305304, lng: 20.346106 },
    { lat: 48.305669, lng: 20.346831 },
    { lat: 48.305814, lng: 20.347152 },
    { lat: 48.305934, lng: 20.34706 },
    { lat: 48.306241, lng: 20.347862 },
    { lat: 48.306578, lng: 20.348704 },
    { lat: 48.306788, lng: 20.349285 },
    { lat: 48.307111, lng: 20.350014 },
    { lat: 48.307413, lng: 20.350691 },
    { lat: 48.307551, lng: 20.351055 },
    { lat: 48.307617, lng: 20.351289 },
    { lat: 48.307921, lng: 20.352667 },
    { lat: 48.308141, lng: 20.353637 },
    { lat: 48.308244, lng: 20.354069 },
    { lat: 48.308455, lng: 20.355006 },
    { lat: 48.308627, lng: 20.35543 },
    { lat: 48.308811, lng: 20.355842 },
    { lat: 48.309133, lng: 20.356541 },
    { lat: 48.309718, lng: 20.357802 },
    { lat: 48.309746, lng: 20.357861 },
    { lat: 48.309766, lng: 20.357908 },
    { lat: 48.312794, lng: 20.356404 },
    { lat: 48.312842, lng: 20.356538 },
    { lat: 48.313076, lng: 20.357166 },
    { lat: 48.313321, lng: 20.357661 },
    { lat: 48.313663, lng: 20.358398 },
    { lat: 48.313827, lng: 20.35879 },
    { lat: 48.314051, lng: 20.35934 },
    { lat: 48.314393, lng: 20.360216 },
    { lat: 48.314558, lng: 20.360639 },
    { lat: 48.314746, lng: 20.361071 },
    { lat: 48.314995, lng: 20.361604 },
    { lat: 48.315491, lng: 20.362573 },
    { lat: 48.315812, lng: 20.36324 },
    { lat: 48.316007, lng: 20.363707 },
    { lat: 48.316129, lng: 20.364114 },
    { lat: 48.316234, lng: 20.364518 },
    { lat: 48.31642, lng: 20.365327 },
    { lat: 48.316511, lng: 20.365535 },
    { lat: 48.316606, lng: 20.365658 },
    { lat: 48.316999, lng: 20.365977 },
    { lat: 48.317416, lng: 20.366422 },
    { lat: 48.317589, lng: 20.366634 },
    { lat: 48.317875, lng: 20.366932 },
    { lat: 48.318451, lng: 20.367385 },
    { lat: 48.318991, lng: 20.367729 },
    { lat: 48.319464, lng: 20.368026 },
    { lat: 48.319902, lng: 20.368296 },
    { lat: 48.32024, lng: 20.368491 },
    { lat: 48.320615, lng: 20.368748 },
    { lat: 48.321, lng: 20.369003 },
    { lat: 48.321061, lng: 20.369984 },
    { lat: 48.321215, lng: 20.369987 },
    { lat: 48.321378, lng: 20.370013 },
    { lat: 48.321625, lng: 20.370071 },
    { lat: 48.322142, lng: 20.370233 },
    { lat: 48.322838, lng: 20.370476 },
    { lat: 48.323528, lng: 20.37072 },
    { lat: 48.323735, lng: 20.370831 },
    { lat: 48.323921, lng: 20.370975 },
    { lat: 48.324212, lng: 20.371276 },
    { lat: 48.324922, lng: 20.372071 },
    { lat: 48.325585, lng: 20.372784 },
    { lat: 48.32611, lng: 20.373383 },
    { lat: 48.326593, lng: 20.373921 },
    { lat: 48.326968, lng: 20.374268 },
    { lat: 48.326989, lng: 20.374279 },
    { lat: 48.327014, lng: 20.374146 },
    { lat: 48.327058, lng: 20.373808 },
    { lat: 48.327136, lng: 20.37354 },
    { lat: 48.327222, lng: 20.373327 },
    { lat: 48.327344, lng: 20.373148 },
    { lat: 48.327585, lng: 20.372834 },
    { lat: 48.328004, lng: 20.372299 },
    { lat: 48.328368, lng: 20.371813 },
    { lat: 48.328667, lng: 20.371385 },
    { lat: 48.328788, lng: 20.371174 },
    { lat: 48.328972, lng: 20.370794 },
    { lat: 48.32927, lng: 20.370032 },
    { lat: 48.329439, lng: 20.369563 },
    { lat: 48.329602, lng: 20.369095 },
    { lat: 48.329814, lng: 20.36856 },
    { lat: 48.329968, lng: 20.368533 },
    { lat: 48.33001, lng: 20.368517 },
    { lat: 48.330764, lng: 20.368233 },
    { lat: 48.331145, lng: 20.368086 },
    { lat: 48.331775, lng: 20.367827 },
    { lat: 48.332187, lng: 20.367629 },
    { lat: 48.3280221, lng: 20.3541549 },
    { lat: 48.3265056, lng: 20.3528658 },
    { lat: 48.326142, lng: 20.3522073 },
    { lat: 48.3250598, lng: 20.3495566 },
    { lat: 48.3250759, lng: 20.3488733 },
    { lat: 48.3251373, lng: 20.3483979 },
    { lat: 48.3253718, lng: 20.3475064 },
    { lat: 48.3255695, lng: 20.3464618 },
    { lat: 48.3259038, lng: 20.3454203 },
    { lat: 48.3254895, lng: 20.3440387 },
    { lat: 48.3231848, lng: 20.3391281 },
    { lat: 48.3228622, lng: 20.3382248 },
    { lat: 48.3220563, lng: 20.3352481 },
    { lat: 48.3207136, lng: 20.3314766 },
    { lat: 48.3203739, lng: 20.3303052 },
    { lat: 48.3201877, lng: 20.3297834 },
    { lat: 48.3199893, lng: 20.3297776 },
    { lat: 48.319829, lng: 20.3296265 },
    { lat: 48.3195177, lng: 20.3287237 },
    { lat: 48.3194822, lng: 20.3283599 },
    { lat: 48.3193273, lng: 20.328088 },
    { lat: 48.319261, lng: 20.3278048 },
    { lat: 48.3192762, lng: 20.3276761 },
    { lat: 48.3193328, lng: 20.3274814 },
    { lat: 48.3194545, lng: 20.3273111 },
    { lat: 48.3198397, lng: 20.3271688 },
    { lat: 48.3198993, lng: 20.3268522 },
    { lat: 48.3197736, lng: 20.3267161 },
    { lat: 48.3195898, lng: 20.3263689 },
    { lat: 48.3194946, lng: 20.3262945 },
    { lat: 48.3192948, lng: 20.3263706 },
    { lat: 48.3192299, lng: 20.3263282 },
    { lat: 48.3191576, lng: 20.3260296 },
    { lat: 48.3189435, lng: 20.3257413 },
    { lat: 48.3188183, lng: 20.3257078 },
    { lat: 48.3175918, lng: 20.3259049 },
    { lat: 48.317321, lng: 20.3257208 },
    { lat: 48.3172818, lng: 20.3256131 },
    { lat: 48.3172283, lng: 20.3257112 },
    { lat: 48.3166951, lng: 20.3259841 },
    { lat: 48.3167008, lng: 20.3272492 },
    { lat: 48.3163252, lng: 20.3275523 },
    { lat: 48.31582, lng: 20.327047 },
    { lat: 48.3158492, lng: 20.3253518 },
    { lat: 48.3163861, lng: 20.3258036 },
    { lat: 48.3162216, lng: 20.3247376 },
    { lat: 48.3160857, lng: 20.3244641 },
    { lat: 48.315612, lng: 20.3241319 },
    { lat: 48.3152085, lng: 20.3241212 },
    { lat: 48.3148481, lng: 20.3242645 },
    { lat: 48.3146383, lng: 20.3236876 },
    { lat: 48.314333, lng: 20.3232196 },
    { lat: 48.3137738, lng: 20.3219433 },
    { lat: 48.3134461, lng: 20.3217784 },
    { lat: 48.3131882, lng: 20.3213996 },
    { lat: 48.3129475, lng: 20.3211562 },
    { lat: 48.3126564, lng: 20.3211091 },
    { lat: 48.3124396, lng: 20.3212386 },
    { lat: 48.3120237, lng: 20.3222921 },
    { lat: 48.3118432, lng: 20.3224549 },
    { lat: 48.3117328, lng: 20.3224509 },
    { lat: 48.3116245, lng: 20.3223621 },
    { lat: 48.3115632, lng: 20.3222072 },
    { lat: 48.3114921, lng: 20.3212576 },
    { lat: 48.3113424, lng: 20.3203414 },
    { lat: 48.3115433, lng: 20.320099 },
    { lat: 48.3119657, lng: 20.3197861 },
    { lat: 48.3120731, lng: 20.319623 },
    { lat: 48.3121506, lng: 20.3194152 },
    { lat: 48.3122608, lng: 20.3186657 },
    { lat: 48.3120106, lng: 20.3189224 },
    { lat: 48.3113179, lng: 20.3191184 },
    { lat: 48.3105884, lng: 20.3188408 },
    { lat: 48.3102119, lng: 20.3186003 },
    { lat: 48.3099558, lng: 20.3182745 },
    { lat: 48.309726, lng: 20.3178734 },
    { lat: 48.309323, lng: 20.317504 },
    { lat: 48.309087, lng: 20.3171442 },
    { lat: 48.3085989, lng: 20.3169341 },
    { lat: 48.3081966, lng: 20.3164723 },
    { lat: 48.3073713, lng: 20.3150447 },
    { lat: 48.3070651, lng: 20.3149338 },
    { lat: 48.3061404, lng: 20.3149332 },
    { lat: 48.3056722, lng: 20.314537 },
    { lat: 48.3051101, lng: 20.3142514 },
    { lat: 48.3045274, lng: 20.3144664 },
    { lat: 48.3043702, lng: 20.3148245 },
    { lat: 48.3034033, lng: 20.321765 },
    { lat: 48.302914, lng: 20.3217208 },
    { lat: 48.3026895, lng: 20.3239458 },
    { lat: 48.3021427, lng: 20.3238741 },
    { lat: 48.3020347, lng: 20.3237337 },
    { lat: 48.3017673, lng: 20.3235758 },
    { lat: 48.3014782, lng: 20.3232155 },
    { lat: 48.3013107, lng: 20.3231924 },
    { lat: 48.3009391, lng: 20.3233083 },
    { lat: 48.3005888, lng: 20.3232226 },
    { lat: 48.3004215, lng: 20.3232893 },
    { lat: 48.3001528, lng: 20.3235334 },
    { lat: 48.2997459, lng: 20.3240111 },
    { lat: 48.2995605, lng: 20.3240795 },
    { lat: 48.2993532, lng: 20.3239043 },
    { lat: 48.2992565, lng: 20.3239659 },
    { lat: 48.2990929, lng: 20.3244737 },
    { lat: 48.2990286, lng: 20.3244699 },
    { lat: 48.2988915, lng: 20.32428 },
    { lat: 48.2988365, lng: 20.3239673 },
    { lat: 48.2987414, lng: 20.3239551 },
    { lat: 48.2985017, lng: 20.3241609 },
    { lat: 48.2980714, lng: 20.3243025 },
    { lat: 48.2979809, lng: 20.3244089 },
    { lat: 48.2980201, lng: 20.3247736 },
    { lat: 48.2976755, lng: 20.3257462 },
    { lat: 48.2965827, lng: 20.3257366 },
    { lat: 48.2962547, lng: 20.3258759 },
    { lat: 48.2960773, lng: 20.3257745 },
    { lat: 48.2959425, lng: 20.3257814 },
    { lat: 48.2955024, lng: 20.3267373 },
    { lat: 48.2956822, lng: 20.327415 },
    { lat: 48.2953401, lng: 20.328079 },
    { lat: 48.2953284, lng: 20.3282434 },
    { lat: 48.2954066, lng: 20.3285986 },
    { lat: 48.2952389, lng: 20.328861 },
    { lat: 48.2950943, lng: 20.3292455 },
    { lat: 48.2941393, lng: 20.3301671 },
    { lat: 48.2938056, lng: 20.3303005 },
    { lat: 48.2935294, lng: 20.3301247 },
    { lat: 48.2934041, lng: 20.3302015 },
    { lat: 48.2932858, lng: 20.3304277 },
    { lat: 48.2930549, lng: 20.3306316 },
    { lat: 48.2930082, lng: 20.3309013 },
    { lat: 48.2928924, lng: 20.3310784 },
    { lat: 48.2926865, lng: 20.3318039 },
    { lat: 48.2923307, lng: 20.3321769 },
    { lat: 48.2918865, lng: 20.3321347 },
    { lat: 48.2916605, lng: 20.3322862 },
    { lat: 48.2915297, lng: 20.3324837 },
    { lat: 48.2913905, lng: 20.3324362 },
    { lat: 48.2910156, lng: 20.3319508 },
    { lat: 48.2905838, lng: 20.3323149 },
    { lat: 48.2904945, lng: 20.3334013 },
    { lat: 48.2907022, lng: 20.3336957 },
    { lat: 48.290863, lng: 20.333806 },
    { lat: 48.291126, lng: 20.333751 },
    { lat: 48.29115, lng: 20.33358 },
    { lat: 48.291197, lng: 20.333532 },
    { lat: 48.291238, lng: 20.333539 },
    { lat: 48.291308, lng: 20.333617 },
    { lat: 48.291343, lng: 20.333688 },
    { lat: 48.29139, lng: 20.333719 },
    { lat: 48.291473, lng: 20.333704 },
    { lat: 48.291564, lng: 20.333721 },
    { lat: 48.291653, lng: 20.333713 },
    { lat: 48.291852, lng: 20.333619 },
    { lat: 48.291934, lng: 20.333535 },
    { lat: 48.291941, lng: 20.333432 },
    { lat: 48.291902, lng: 20.333292 },
    { lat: 48.291729, lng: 20.332917 },
    { lat: 48.291689, lng: 20.332798 },
    { lat: 48.291692, lng: 20.332689 },
    { lat: 48.291725, lng: 20.332575 },
    { lat: 48.29177, lng: 20.332479 },
    { lat: 48.291852, lng: 20.332383 },
    { lat: 48.291899, lng: 20.332352 },
    { lat: 48.291987, lng: 20.332348 },
    { lat: 48.292036, lng: 20.332374 },
    { lat: 48.292084, lng: 20.332492 },
    { lat: 48.292092, lng: 20.33262 },
    { lat: 48.292069, lng: 20.332948 },
    { lat: 48.292088, lng: 20.33315 },
    { lat: 48.292137, lng: 20.333323 },
    { lat: 48.292217, lng: 20.333399 },
    { lat: 48.292269, lng: 20.33342 },
    { lat: 48.292351, lng: 20.333429 },
    { lat: 48.292437, lng: 20.33343 },
    { lat: 48.292584, lng: 20.333366 },
    { lat: 48.292619, lng: 20.333339 },
    { lat: 48.292745, lng: 20.333221 },
    { lat: 48.292751, lng: 20.333218 },
    { lat: 48.292806, lng: 20.333084 },
    { lat: 48.292811, lng: 20.332976 },
    { lat: 48.292748, lng: 20.332806 },
    { lat: 48.292771, lng: 20.33265 },
    { lat: 48.292811, lng: 20.332475 },
    { lat: 48.29293, lng: 20.332239 },
    { lat: 48.292983, lng: 20.332148 },
    { lat: 48.293044, lng: 20.332122 },
    { lat: 48.29314, lng: 20.332185 },
    { lat: 48.293239, lng: 20.332273 },
    { lat: 48.29335, lng: 20.332274 },
    { lat: 48.293458, lng: 20.332384 },
    { lat: 48.29347, lng: 20.332464 },
    { lat: 48.293403, lng: 20.332681 },
    { lat: 48.293368, lng: 20.332985 },
    { lat: 48.293341, lng: 20.333184 },
    { lat: 48.293366, lng: 20.33324 },
    { lat: 48.293408, lng: 20.333292 },
    { lat: 48.293498, lng: 20.333334 },
    { lat: 48.293635, lng: 20.333316 },
    { lat: 48.293736, lng: 20.333286 },
    { lat: 48.29387, lng: 20.333231 },
    { lat: 48.293949, lng: 20.333213 },
    { lat: 48.294044, lng: 20.333225 },
    { lat: 48.294112, lng: 20.333243 },
    { lat: 48.294267, lng: 20.333319 },
    { lat: 48.2944, lng: 20.33336 },
    { lat: 48.294447, lng: 20.333374 },
    { lat: 48.294504, lng: 20.333362 },
    { lat: 48.294581, lng: 20.333313 },
    { lat: 48.294654, lng: 20.33326 },
    { lat: 48.294739, lng: 20.333237 },
    { lat: 48.294839, lng: 20.333257 },
    { lat: 48.294919, lng: 20.333303 },
    { lat: 48.294947, lng: 20.333437 },
    { lat: 48.294947, lng: 20.333582 },
    { lat: 48.29497, lng: 20.333647 },
    { lat: 48.295031, lng: 20.333645 },
    { lat: 48.295076, lng: 20.333589 },
    { lat: 48.295147, lng: 20.333407 },
    { lat: 48.295189, lng: 20.333335 },
    { lat: 48.295215, lng: 20.333297 },
    { lat: 48.29526, lng: 20.333234 },
    { lat: 48.295349, lng: 20.333186 },
    { lat: 48.295501, lng: 20.333177 },
    { lat: 48.295566, lng: 20.33319 },
    { lat: 48.295605, lng: 20.333214 },
    { lat: 48.295632, lng: 20.333253 },
    { lat: 48.295669, lng: 20.333324 },
    { lat: 48.295723, lng: 20.333401 },
    { lat: 48.29579, lng: 20.333467 },
    { lat: 48.295865, lng: 20.333524 },
    { lat: 48.295901, lng: 20.333548 },
    { lat: 48.295948, lng: 20.333558 },
    { lat: 48.295993, lng: 20.33353 },
    { lat: 48.296095, lng: 20.333391 },
    { lat: 48.296137, lng: 20.333357 },
    { lat: 48.296337, lng: 20.333311 },
    { lat: 48.296439, lng: 20.333314 },
    { lat: 48.296568, lng: 20.33347 },
    { lat: 48.296692, lng: 20.333633 },
    { lat: 48.296723, lng: 20.333794 },
    { lat: 48.296705, lng: 20.333937 },
    { lat: 48.296677, lng: 20.334033 },
    { lat: 48.296627, lng: 20.334115 },
    { lat: 48.296567, lng: 20.334167 },
    { lat: 48.296505, lng: 20.334172 },
    { lat: 48.296454, lng: 20.334132 },
    { lat: 48.296408, lng: 20.334073 },
    { lat: 48.296341, lng: 20.333969 },
    { lat: 48.296272, lng: 20.333907 },
    { lat: 48.296203, lng: 20.333865 },
    { lat: 48.296152, lng: 20.333844 },
    { lat: 48.296101, lng: 20.333851 },
    { lat: 48.296049, lng: 20.333888 },
    { lat: 48.296032, lng: 20.333932 },
    { lat: 48.296022, lng: 20.333998 },
    { lat: 48.296026, lng: 20.334079 },
    { lat: 48.296049, lng: 20.334268 },
    { lat: 48.296052, lng: 20.334358 },
    { lat: 48.296039, lng: 20.334452 },
    { lat: 48.296018, lng: 20.334568 },
    { lat: 48.295921, lng: 20.334869 },
    { lat: 48.295884, lng: 20.335073 },
    { lat: 48.295837, lng: 20.335323 },
    { lat: 48.295842, lng: 20.335438 },
    { lat: 48.295891, lng: 20.335578 },
    { lat: 48.295975, lng: 20.33578 },
    { lat: 48.296051, lng: 20.335916 },
    { lat: 48.296176, lng: 20.336039 },
    { lat: 48.29627, lng: 20.33609 },
    { lat: 48.296401, lng: 20.336208 },
    { lat: 48.296453, lng: 20.336232 },
    { lat: 48.296517, lng: 20.336222 },
    { lat: 48.296561, lng: 20.336168 },
    { lat: 48.296602, lng: 20.336113 },
    { lat: 48.296634, lng: 20.335992 },
    { lat: 48.296669, lng: 20.335797 },
    { lat: 48.296702, lng: 20.335695 },
    { lat: 48.296766, lng: 20.33559 },
    { lat: 48.29684, lng: 20.335512 },
    { lat: 48.296943, lng: 20.33544 },
    { lat: 48.297027, lng: 20.335446 },
    { lat: 48.297085, lng: 20.335504 },
    { lat: 48.297121, lng: 20.335589 },
    { lat: 48.297138, lng: 20.335702 },
    { lat: 48.297166, lng: 20.335858 },
    { lat: 48.297222, lng: 20.335988 },
    { lat: 48.297287, lng: 20.336068 },
    { lat: 48.297607, lng: 20.336205 },
    { lat: 48.297685, lng: 20.336221 },
    { lat: 48.297763, lng: 20.336187 },
    { lat: 48.297815, lng: 20.336112 },
    { lat: 48.297852, lng: 20.335976 },
    { lat: 48.297928, lng: 20.33589 },
    { lat: 48.298018, lng: 20.33583 },
    { lat: 48.298119, lng: 20.335777 },
    { lat: 48.298202, lng: 20.335712 },
    { lat: 48.298329, lng: 20.335581 },
    { lat: 48.298394, lng: 20.335437 },
    { lat: 48.298442, lng: 20.33525 },
    { lat: 48.298845, lng: 20.335212 },
    { lat: 48.298943, lng: 20.33523 },
    { lat: 48.299022, lng: 20.335269 },
    { lat: 48.299091, lng: 20.335323 },
    { lat: 48.299159, lng: 20.335405 },
    { lat: 48.299531, lng: 20.335917 },
    { lat: 48.299708, lng: 20.336202 },
    { lat: 48.299886, lng: 20.336501 },
    { lat: 48.300004, lng: 20.3367 },
    { lat: 48.30005, lng: 20.336775 },
    { lat: 48.300064, lng: 20.336959 },
    { lat: 48.300084, lng: 20.336992 },
    { lat: 48.300417, lng: 20.337598 },
    { lat: 48.300741, lng: 20.338145 },
    { lat: 48.301223, lng: 20.339044 },
    { lat: 48.301277, lng: 20.338539 },
    { lat: 48.301402, lng: 20.337396 },
    { lat: 48.301539, lng: 20.3374306 },
  ],
  RimavskáSeč: [
    { lat: 48.3001556, lng: 20.2721236 },
    { lat: 48.3000515, lng: 20.2711729 },
    { lat: 48.2998753, lng: 20.2704295 },
    { lat: 48.2992212, lng: 20.2686515 },
    { lat: 48.2990966, lng: 20.2680121 },
    { lat: 48.2988687, lng: 20.2653397 },
    { lat: 48.2999456, lng: 20.2642144 },
    { lat: 48.3000716, lng: 20.2644907 },
    { lat: 48.3002705, lng: 20.2642823 },
    { lat: 48.3002174, lng: 20.2639092 },
    { lat: 48.3021436, lng: 20.2619396 },
    { lat: 48.3024951, lng: 20.2614928 },
    { lat: 48.3027981, lng: 20.2609521 },
    { lat: 48.3031479, lng: 20.259729 },
    { lat: 48.3040817, lng: 20.2558337 },
    { lat: 48.3041974, lng: 20.2555163 },
    { lat: 48.3046076, lng: 20.2549298 },
    { lat: 48.3074754, lng: 20.2529342 },
    { lat: 48.3089626, lng: 20.2519315 },
    { lat: 48.3094277, lng: 20.2516768 },
    { lat: 48.309657, lng: 20.2516969 },
    { lat: 48.3098722, lng: 20.2510228 },
    { lat: 48.3105438, lng: 20.2508026 },
    { lat: 48.3110654, lng: 20.250553 },
    { lat: 48.3116137, lng: 20.2501228 },
    { lat: 48.3121052, lng: 20.2493484 },
    { lat: 48.3123821, lng: 20.2486633 },
    { lat: 48.3125797, lng: 20.24769 },
    { lat: 48.3125887, lng: 20.2471294 },
    { lat: 48.312502, lng: 20.2462261 },
    { lat: 48.3118035, lng: 20.2434061 },
    { lat: 48.3117675, lng: 20.2426769 },
    { lat: 48.3119146, lng: 20.2422129 },
    { lat: 48.312396, lng: 20.2412388 },
    { lat: 48.3124628, lng: 20.2410505 },
    { lat: 48.3125322, lng: 20.240539 },
    { lat: 48.3129401, lng: 20.2393836 },
    { lat: 48.3132515, lng: 20.2387261 },
    { lat: 48.3137162, lng: 20.2386843 },
    { lat: 48.3151128, lng: 20.2359314 },
    { lat: 48.3144003, lng: 20.2342296 },
    { lat: 48.3145064, lng: 20.2333617 },
    { lat: 48.3144726, lng: 20.2328322 },
    { lat: 48.3145868, lng: 20.2326124 },
    { lat: 48.3145634, lng: 20.2323715 },
    { lat: 48.3148723, lng: 20.2315928 },
    { lat: 48.3149674, lng: 20.2314335 },
    { lat: 48.3145208, lng: 20.2308835 },
    { lat: 48.3141391, lng: 20.2300472 },
    { lat: 48.3131776, lng: 20.2271377 },
    { lat: 48.3131605, lng: 20.226963 },
    { lat: 48.3132957, lng: 20.2267976 },
    { lat: 48.3132314, lng: 20.2265147 },
    { lat: 48.3139506, lng: 20.2259704 },
    { lat: 48.3179069, lng: 20.2168798 },
    { lat: 48.3189699, lng: 20.21556 },
    { lat: 48.3192546, lng: 20.2151871 },
    { lat: 48.3238677, lng: 20.2090624 },
    { lat: 48.3221155, lng: 20.2071423 },
    { lat: 48.3220866, lng: 20.2071209 },
    { lat: 48.3195417, lng: 20.2055145 },
    { lat: 48.3174879, lng: 20.2012322 },
    { lat: 48.3127318, lng: 20.2040028 },
    { lat: 48.2997619, lng: 20.2112796 },
    { lat: 48.2998513, lng: 20.2119574 },
    { lat: 48.2950024, lng: 20.2146424 },
    { lat: 48.2946371, lng: 20.2142974 },
    { lat: 48.2947048, lng: 20.2139627 },
    { lat: 48.2945905, lng: 20.2135029 },
    { lat: 48.2937611, lng: 20.2114466 },
    { lat: 48.2940373, lng: 20.2097214 },
    { lat: 48.294202, lng: 20.2091392 },
    { lat: 48.2944034, lng: 20.2070699 },
    { lat: 48.2942096, lng: 20.2045843 },
    { lat: 48.2941393, lng: 20.204492 },
    { lat: 48.2947624, lng: 20.2034349 },
    { lat: 48.294946, lng: 20.2028474 },
    { lat: 48.2949525, lng: 20.2021744 },
    { lat: 48.2951697, lng: 20.2012521 },
    { lat: 48.2950187, lng: 20.2009428 },
    { lat: 48.2948865, lng: 20.2004292 },
    { lat: 48.2948107, lng: 20.1993057 },
    { lat: 48.2949912, lng: 20.1986735 },
    { lat: 48.2953906, lng: 20.1982726 },
    { lat: 48.2950775, lng: 20.1980774 },
    { lat: 48.2949655, lng: 20.1977289 },
    { lat: 48.2946926, lng: 20.1975808 },
    { lat: 48.2945825, lng: 20.1974507 },
    { lat: 48.294538, lng: 20.1970444 },
    { lat: 48.2945421, lng: 20.1962588 },
    { lat: 48.2942922, lng: 20.1947016 },
    { lat: 48.2942241, lng: 20.1947159 },
    { lat: 48.2942887, lng: 20.194113 },
    { lat: 48.2945711, lng: 20.1928253 },
    { lat: 48.2947743, lng: 20.1925568 },
    { lat: 48.2948104, lng: 20.1923349 },
    { lat: 48.2947015, lng: 20.1919264 },
    { lat: 48.2944144, lng: 20.1917604 },
    { lat: 48.2943591, lng: 20.1915076 },
    { lat: 48.2945033, lng: 20.1909523 },
    { lat: 48.2946391, lng: 20.1906343 },
    { lat: 48.2945919, lng: 20.1902792 },
    { lat: 48.294616, lng: 20.1898714 },
    { lat: 48.2947794, lng: 20.1887009 },
    { lat: 48.2947824, lng: 20.1882759 },
    { lat: 48.2947877, lng: 20.1880455 },
    { lat: 48.2946443, lng: 20.1881519 },
    { lat: 48.2945208, lng: 20.1881614 },
    { lat: 48.2943489, lng: 20.1878926 },
    { lat: 48.2943185, lng: 20.1876948 },
    { lat: 48.2941276, lng: 20.1872135 },
    { lat: 48.2939593, lng: 20.1873395 },
    { lat: 48.2938549, lng: 20.1873196 },
    { lat: 48.2936274, lng: 20.1866793 },
    { lat: 48.2928578, lng: 20.1868688 },
    { lat: 48.2920434, lng: 20.1871979 },
    { lat: 48.291962, lng: 20.187222 },
    { lat: 48.2919069, lng: 20.1874158 },
    { lat: 48.2919012, lng: 20.1878054 },
    { lat: 48.2918037, lng: 20.1881489 },
    { lat: 48.2918263, lng: 20.1882642 },
    { lat: 48.2920168, lng: 20.1884853 },
    { lat: 48.291987, lng: 20.1887239 },
    { lat: 48.2920212, lng: 20.1887926 },
    { lat: 48.2921082, lng: 20.1887964 },
    { lat: 48.2923364, lng: 20.1885623 },
    { lat: 48.2923181, lng: 20.188199 },
    { lat: 48.2923381, lng: 20.1881042 },
    { lat: 48.2924081, lng: 20.1880553 },
    { lat: 48.2925261, lng: 20.1881889 },
    { lat: 48.2926335, lng: 20.1887259 },
    { lat: 48.2926119, lng: 20.1888613 },
    { lat: 48.2924429, lng: 20.1891051 },
    { lat: 48.2925021, lng: 20.1893218 },
    { lat: 48.2925711, lng: 20.1893814 },
    { lat: 48.2926851, lng: 20.1893572 },
    { lat: 48.2928115, lng: 20.1891968 },
    { lat: 48.2928461, lng: 20.1886062 },
    { lat: 48.2929562, lng: 20.1885176 },
    { lat: 48.2933071, lng: 20.1887018 },
    { lat: 48.2936573, lng: 20.1890548 },
    { lat: 48.2936967, lng: 20.1891722 },
    { lat: 48.2936472, lng: 20.1893659 },
    { lat: 48.2934129, lng: 20.1894774 },
    { lat: 48.2933017, lng: 20.1892035 },
    { lat: 48.2932238, lng: 20.1892032 },
    { lat: 48.2931699, lng: 20.1894585 },
    { lat: 48.2932925, lng: 20.1898159 },
    { lat: 48.293389, lng: 20.1898333 },
    { lat: 48.2937342, lng: 20.1896495 },
    { lat: 48.2930826, lng: 20.1949808 },
    { lat: 48.2929749, lng: 20.1951823 },
    { lat: 48.2925812, lng: 20.1954214 },
    { lat: 48.2922831, lng: 20.1957081 },
    { lat: 48.2918697, lng: 20.1957623 },
    { lat: 48.2917871, lng: 20.1958918 },
    { lat: 48.291806, lng: 20.1961956 },
    { lat: 48.2917744, lng: 20.196248 },
    { lat: 48.2914326, lng: 20.1962592 },
    { lat: 48.2913258, lng: 20.1966007 },
    { lat: 48.2910655, lng: 20.1969564 },
    { lat: 48.2907831, lng: 20.1976898 },
    { lat: 48.2907784, lng: 20.1980233 },
    { lat: 48.2908406, lng: 20.1981362 },
    { lat: 48.2909722, lng: 20.1981341 },
    { lat: 48.2910459, lng: 20.1982597 },
    { lat: 48.2910828, lng: 20.1984767 },
    { lat: 48.291063, lng: 20.1986221 },
    { lat: 48.2907837, lng: 20.1989063 },
    { lat: 48.2906873, lng: 20.1988571 },
    { lat: 48.2905939, lng: 20.1986381 },
    { lat: 48.2904227, lng: 20.1979414 },
    { lat: 48.2902379, lng: 20.1980816 },
    { lat: 48.2900675, lng: 20.1985487 },
    { lat: 48.2900059, lng: 20.1986116 },
    { lat: 48.2898104, lng: 20.1985922 },
    { lat: 48.2893804, lng: 20.1988512 },
    { lat: 48.2892456, lng: 20.199025 },
    { lat: 48.2892125, lng: 20.1993198 },
    { lat: 48.2890466, lng: 20.1996489 },
    { lat: 48.2890617, lng: 20.1998516 },
    { lat: 48.2891769, lng: 20.2000862 },
    { lat: 48.2891099, lng: 20.2002681 },
    { lat: 48.2888986, lng: 20.2003611 },
    { lat: 48.2885684, lng: 20.2000208 },
    { lat: 48.2883821, lng: 20.1996522 },
    { lat: 48.2880934, lng: 20.1995278 },
    { lat: 48.2880267, lng: 20.1995543 },
    { lat: 48.2879528, lng: 20.1995589 },
    { lat: 48.2879336, lng: 20.1997254 },
    { lat: 48.2878555, lng: 20.1999663 },
    { lat: 48.2877825, lng: 20.2009752 },
    { lat: 48.2876828, lng: 20.2014097 },
    { lat: 48.2876439, lng: 20.2018956 },
    { lat: 48.2874602, lng: 20.2019225 },
    { lat: 48.2873031, lng: 20.2022122 },
    { lat: 48.2871291, lng: 20.2021993 },
    { lat: 48.2870702, lng: 20.2020257 },
    { lat: 48.2868847, lng: 20.2020606 },
    { lat: 48.2865284, lng: 20.202415 },
    { lat: 48.2863705, lng: 20.2026596 },
    { lat: 48.2864048, lng: 20.2028672 },
    { lat: 48.2866599, lng: 20.2028243 },
    { lat: 48.2868735, lng: 20.2030709 },
    { lat: 48.2868838, lng: 20.2032365 },
    { lat: 48.2869759, lng: 20.2034849 },
    { lat: 48.2869086, lng: 20.2037103 },
    { lat: 48.2869501, lng: 20.2041847 },
    { lat: 48.2877012, lng: 20.2054912 },
    { lat: 48.2879041, lng: 20.206079 },
    { lat: 48.2879708, lng: 20.2065631 },
    { lat: 48.2879684, lng: 20.2071158 },
    { lat: 48.2878441, lng: 20.2075801 },
    { lat: 48.288337, lng: 20.2089652 },
    { lat: 48.2884293, lng: 20.2088833 },
    { lat: 48.2885609, lng: 20.2089634 },
    { lat: 48.2886006, lng: 20.2091624 },
    { lat: 48.2884873, lng: 20.2097898 },
    { lat: 48.288648, lng: 20.2096943 },
    { lat: 48.2887371, lng: 20.2094231 },
    { lat: 48.2889733, lng: 20.2093264 },
    { lat: 48.2890683, lng: 20.2093666 },
    { lat: 48.2893765, lng: 20.2100251 },
    { lat: 48.2893666, lng: 20.2101438 },
    { lat: 48.2891167, lng: 20.2103429 },
    { lat: 48.2891762, lng: 20.2107587 },
    { lat: 48.2893284, lng: 20.211099 },
    { lat: 48.2893305, lng: 20.2114029 },
    { lat: 48.2892245, lng: 20.2121517 },
    { lat: 48.2890071, lng: 20.2121208 },
    { lat: 48.288038, lng: 20.2122494 },
    { lat: 48.2877413, lng: 20.2124365 },
    { lat: 48.286816, lng: 20.21337 },
    { lat: 48.2841027, lng: 20.2153545 },
    { lat: 48.2841759, lng: 20.2164999 },
    { lat: 48.28424, lng: 20.2175041 },
    { lat: 48.2835263, lng: 20.2179042 },
    { lat: 48.2831829, lng: 20.2173238 },
    { lat: 48.2829462, lng: 20.2175414 },
    { lat: 48.2820653, lng: 20.217621 },
    { lat: 48.2821048, lng: 20.2179845 },
    { lat: 48.2812667, lng: 20.2178445 },
    { lat: 48.2792362, lng: 20.2215133 },
    { lat: 48.2787608, lng: 20.222269 },
    { lat: 48.2780923, lng: 20.222702 },
    { lat: 48.2779211, lng: 20.2229289 },
    { lat: 48.2768534, lng: 20.2230412 },
    { lat: 48.2767246, lng: 20.2231942 },
    { lat: 48.2764614, lng: 20.2236267 },
    { lat: 48.2764872, lng: 20.2247968 },
    { lat: 48.2765779, lng: 20.225681 },
    { lat: 48.2768486, lng: 20.2263917 },
    { lat: 48.277232, lng: 20.22798 },
    { lat: 48.277264, lng: 20.228366 },
    { lat: 48.277259, lng: 20.228434 },
    { lat: 48.277256, lng: 20.228512 },
    { lat: 48.277108, lng: 20.229091 },
    { lat: 48.277098, lng: 20.229161 },
    { lat: 48.27707, lng: 20.229364 },
    { lat: 48.277069, lng: 20.229446 },
    { lat: 48.277068, lng: 20.229513 },
    { lat: 48.277044, lng: 20.229604 },
    { lat: 48.277057, lng: 20.229965 },
    { lat: 48.27706, lng: 20.230029 },
    { lat: 48.27707, lng: 20.230138 },
    { lat: 48.277092, lng: 20.230253 },
    { lat: 48.277172, lng: 20.230691 },
    { lat: 48.277285, lng: 20.230986 },
    { lat: 48.277387, lng: 20.231147 },
    { lat: 48.27746, lng: 20.231264 },
    { lat: 48.277508, lng: 20.231339 },
    { lat: 48.277581, lng: 20.231436 },
    { lat: 48.277608, lng: 20.231472 },
    { lat: 48.277621, lng: 20.231491 },
    { lat: 48.277659, lng: 20.231549 },
    { lat: 48.277743, lng: 20.231641 },
    { lat: 48.277834, lng: 20.23174 },
    { lat: 48.277875, lng: 20.231796 },
    { lat: 48.277928, lng: 20.231865 },
    { lat: 48.278017, lng: 20.232041 },
    { lat: 48.278049, lng: 20.232105 },
    { lat: 48.278077, lng: 20.232169 },
    { lat: 48.278149, lng: 20.232326 },
    { lat: 48.278222, lng: 20.23244 },
    { lat: 48.278297, lng: 20.232521 },
    { lat: 48.278326, lng: 20.232553 },
    { lat: 48.278372, lng: 20.232619 },
    { lat: 48.278473, lng: 20.232763 },
    { lat: 48.278591, lng: 20.232955 },
    { lat: 48.278664, lng: 20.233086 },
    { lat: 48.278802, lng: 20.233336 },
    { lat: 48.278812, lng: 20.233322 },
    { lat: 48.278929, lng: 20.233326 },
    { lat: 48.279335, lng: 20.233784 },
    { lat: 48.279506, lng: 20.234068 },
    { lat: 48.279544, lng: 20.23413 },
    { lat: 48.279933, lng: 20.234947 },
    { lat: 48.279938, lng: 20.235137 },
    { lat: 48.27994, lng: 20.235279 },
    { lat: 48.279935, lng: 20.235726 },
    { lat: 48.279857, lng: 20.236137 },
    { lat: 48.279843, lng: 20.236344 },
    { lat: 48.279817, lng: 20.236735 },
    { lat: 48.279882, lng: 20.237399 },
    { lat: 48.279866, lng: 20.23762 },
    { lat: 48.279841, lng: 20.237981 },
    { lat: 48.27964, lng: 20.23833 },
    { lat: 48.279641, lng: 20.238335 },
    { lat: 48.279677, lng: 20.238489 },
    { lat: 48.279847, lng: 20.239082 },
    { lat: 48.280098, lng: 20.239821 },
    { lat: 48.28019, lng: 20.240058 },
    { lat: 48.280324, lng: 20.240613 },
    { lat: 48.280493, lng: 20.241318 },
    { lat: 48.280262, lng: 20.241649 },
    { lat: 48.280135, lng: 20.241842 },
    { lat: 48.280076, lng: 20.241932 },
    { lat: 48.279954, lng: 20.242261 },
    { lat: 48.279888, lng: 20.242748 },
    { lat: 48.279884, lng: 20.24278 },
    { lat: 48.279728, lng: 20.243155 },
    { lat: 48.279625, lng: 20.243376 },
    { lat: 48.279551, lng: 20.243532 },
    { lat: 48.279349, lng: 20.243769 },
    { lat: 48.278945, lng: 20.244243 },
    { lat: 48.278241, lng: 20.244809 },
    { lat: 48.27805, lng: 20.245144 },
    { lat: 48.277946, lng: 20.245472 },
    { lat: 48.277942, lng: 20.245535 },
    { lat: 48.277927, lng: 20.245742 },
    { lat: 48.277916, lng: 20.245826 },
    { lat: 48.277909, lng: 20.246043 },
    { lat: 48.277905, lng: 20.246195 },
    { lat: 48.27816, lng: 20.246756 },
    { lat: 48.27827, lng: 20.24701 },
    { lat: 48.278389, lng: 20.247287 },
    { lat: 48.278419, lng: 20.247336 },
    { lat: 48.278558, lng: 20.247554 },
    { lat: 48.278668, lng: 20.247893 },
    { lat: 48.278741, lng: 20.248118 },
    { lat: 48.278975, lng: 20.249165 },
    { lat: 48.279, lng: 20.249271 },
    { lat: 48.279106, lng: 20.249724 },
    { lat: 48.278776, lng: 20.250097 },
    { lat: 48.278686, lng: 20.2502 },
    { lat: 48.278261, lng: 20.250678 },
    { lat: 48.278062, lng: 20.250786 },
    { lat: 48.278025, lng: 20.250837 },
    { lat: 48.277503, lng: 20.251559 },
    { lat: 48.27718, lng: 20.252477 },
    { lat: 48.277059, lng: 20.252704 },
    { lat: 48.276919, lng: 20.25295 },
    { lat: 48.276398, lng: 20.253878 },
    { lat: 48.276312, lng: 20.254028 },
    { lat: 48.276304, lng: 20.254054 },
    { lat: 48.276299, lng: 20.254056 },
    { lat: 48.276249, lng: 20.25408 },
    { lat: 48.276116, lng: 20.254219 },
    { lat: 48.276045, lng: 20.254304 },
    { lat: 48.276043, lng: 20.254306 },
    { lat: 48.275855, lng: 20.254505 },
    { lat: 48.275725, lng: 20.254631 },
    { lat: 48.275617, lng: 20.254688 },
    { lat: 48.275552, lng: 20.254704 },
    { lat: 48.275456, lng: 20.254713 },
    { lat: 48.27545, lng: 20.254718 },
    { lat: 48.275414, lng: 20.254751 },
    { lat: 48.275408, lng: 20.254803 },
    { lat: 48.275381, lng: 20.254879 },
    { lat: 48.275369, lng: 20.254915 },
    { lat: 48.275368, lng: 20.254918 },
    { lat: 48.275324, lng: 20.255067 },
    { lat: 48.275184, lng: 20.255351 },
    { lat: 48.275038, lng: 20.255581 },
    { lat: 48.27503, lng: 20.255592 },
    { lat: 48.274963, lng: 20.255742 },
    { lat: 48.274944, lng: 20.255805 },
    { lat: 48.274883, lng: 20.255998 },
    { lat: 48.27487, lng: 20.256148 },
    { lat: 48.27487, lng: 20.256337 },
    { lat: 48.27492, lng: 20.25665 },
    { lat: 48.274925, lng: 20.256728 },
    { lat: 48.274926, lng: 20.256771 },
    { lat: 48.274912, lng: 20.257 },
    { lat: 48.274889, lng: 20.257186 },
    { lat: 48.274877, lng: 20.257322 },
    { lat: 48.274841, lng: 20.257768 },
    { lat: 48.274829, lng: 20.258136 },
    { lat: 48.274848, lng: 20.258331 },
    { lat: 48.274861, lng: 20.258402 },
    { lat: 48.274865, lng: 20.258422 },
    { lat: 48.27487, lng: 20.25847 },
    { lat: 48.274876, lng: 20.258525 },
    { lat: 48.274789, lng: 20.259064 },
    { lat: 48.274787, lng: 20.259145 },
    { lat: 48.274811, lng: 20.259362 },
    { lat: 48.274811, lng: 20.259377 },
    { lat: 48.274809, lng: 20.259442 },
    { lat: 48.274813, lng: 20.259543 },
    { lat: 48.274823, lng: 20.25982 },
    { lat: 48.274824, lng: 20.260063 },
    { lat: 48.274827, lng: 20.260265 },
    { lat: 48.2748555, lng: 20.260471 },
    { lat: 48.2751797, lng: 20.261172 },
    { lat: 48.2753284, lng: 20.2616526 },
    { lat: 48.2759916, lng: 20.2617068 },
    { lat: 48.2761807, lng: 20.2617924 },
    { lat: 48.2767378, lng: 20.2624875 },
    { lat: 48.2772743, lng: 20.2632874 },
    { lat: 48.2773664, lng: 20.2635623 },
    { lat: 48.2779583, lng: 20.2643138 },
    { lat: 48.2798741, lng: 20.2659528 },
    { lat: 48.2807022, lng: 20.2664714 },
    { lat: 48.2814401, lng: 20.2667838 },
    { lat: 48.2822997, lng: 20.2672723 },
    { lat: 48.2828604, lng: 20.2681182 },
    { lat: 48.2830698, lng: 20.2685939 },
    { lat: 48.2856813, lng: 20.2679677 },
    { lat: 48.2858364, lng: 20.2688053 },
    { lat: 48.2895152, lng: 20.2707428 },
    { lat: 48.2895942, lng: 20.2706356 },
    { lat: 48.2918997, lng: 20.2717135 },
    { lat: 48.2961717, lng: 20.2726555 },
    { lat: 48.2969565, lng: 20.2731004 },
    { lat: 48.29862, lng: 20.2731588 },
    { lat: 48.2997671, lng: 20.2723779 },
    { lat: 48.3001556, lng: 20.2721236 },
  ],
  Stránska: [
    { lat: 48.3919102, lng: 20.2842638 },
    { lat: 48.3942245, lng: 20.2826433 },
    { lat: 48.3952601, lng: 20.2819168 },
    { lat: 48.3964969, lng: 20.2804434 },
    { lat: 48.396819, lng: 20.2805855 },
    { lat: 48.3972072, lng: 20.2804668 },
    { lat: 48.3977627, lng: 20.2801539 },
    { lat: 48.3982359, lng: 20.2801903 },
    { lat: 48.3995423, lng: 20.2804751 },
    { lat: 48.4014498, lng: 20.2812049 },
    { lat: 48.4013926, lng: 20.2816672 },
    { lat: 48.4019327, lng: 20.2822247 },
    { lat: 48.4019663, lng: 20.2827281 },
    { lat: 48.4021481, lng: 20.2831414 },
    { lat: 48.4024088, lng: 20.2829541 },
    { lat: 48.4023403, lng: 20.2838409 },
    { lat: 48.402911, lng: 20.283803 },
    { lat: 48.402987, lng: 20.283591 },
    { lat: 48.40303, lng: 20.283571 },
    { lat: 48.403104, lng: 20.283608 },
    { lat: 48.403293, lng: 20.28371 },
    { lat: 48.403468, lng: 20.283778 },
    { lat: 48.403499, lng: 20.283791 },
    { lat: 48.403519, lng: 20.283848 },
    { lat: 48.403575, lng: 20.284091 },
    { lat: 48.403614, lng: 20.284238 },
    { lat: 48.403706, lng: 20.28448 },
    { lat: 48.403784, lng: 20.28467 },
    { lat: 48.403836, lng: 20.284872 },
    { lat: 48.403868, lng: 20.284941 },
    { lat: 48.403948, lng: 20.285015 },
    { lat: 48.403974, lng: 20.285023 },
    { lat: 48.40404, lng: 20.285047 },
    { lat: 48.404089, lng: 20.285076 },
    { lat: 48.404137, lng: 20.285166 },
    { lat: 48.40415, lng: 20.285223 },
    { lat: 48.404156, lng: 20.285265 },
    { lat: 48.404162, lng: 20.285332 },
    { lat: 48.404177, lng: 20.285592 },
    { lat: 48.404191, lng: 20.285691 },
    { lat: 48.404221, lng: 20.285775 },
    { lat: 48.404271, lng: 20.285856 },
    { lat: 48.404327, lng: 20.285922 },
    { lat: 48.404424, lng: 20.286007 },
    { lat: 48.404469, lng: 20.286038 },
    { lat: 48.404577, lng: 20.286143 },
    { lat: 48.404635, lng: 20.286207 },
    { lat: 48.404668, lng: 20.286267 },
    { lat: 48.404688, lng: 20.286352 },
    { lat: 48.404692, lng: 20.286414 },
    { lat: 48.404685, lng: 20.286625 },
    { lat: 48.404675, lng: 20.286839 },
    { lat: 48.404697, lng: 20.286954 },
    { lat: 48.40475, lng: 20.287051 },
    { lat: 48.404787, lng: 20.287077 },
    { lat: 48.404814, lng: 20.287076 },
    { lat: 48.404917, lng: 20.287074 },
    { lat: 48.404939, lng: 20.287065 },
    { lat: 48.405045, lng: 20.286946 },
    { lat: 48.405158, lng: 20.28682 },
    { lat: 48.405201, lng: 20.286773 },
    { lat: 48.405227, lng: 20.286754 },
    { lat: 48.405281, lng: 20.28674 },
    { lat: 48.405306, lng: 20.286748 },
    { lat: 48.405329, lng: 20.286757 },
    { lat: 48.405354, lng: 20.286779 },
    { lat: 48.405392, lng: 20.286815 },
    { lat: 48.405417, lng: 20.286848 },
    { lat: 48.405428, lng: 20.286881 },
    { lat: 48.405435, lng: 20.286931 },
    { lat: 48.405433, lng: 20.287427 },
    { lat: 48.405466, lng: 20.287695 },
    { lat: 48.405546, lng: 20.287844 },
    { lat: 48.405602, lng: 20.28792 },
    { lat: 48.405625, lng: 20.287932 },
    { lat: 48.405648, lng: 20.287923 },
    { lat: 48.405828, lng: 20.287781 },
    { lat: 48.405904, lng: 20.287768 },
    { lat: 48.40593, lng: 20.287781 },
    { lat: 48.406007, lng: 20.287843 },
    { lat: 48.406105, lng: 20.287922 },
    { lat: 48.406117, lng: 20.287875 },
    { lat: 48.406126, lng: 20.287841 },
    { lat: 48.406326, lng: 20.286934 },
    { lat: 48.406384, lng: 20.28668 },
    { lat: 48.406472, lng: 20.286341 },
    { lat: 48.406542, lng: 20.285999 },
    { lat: 48.406584, lng: 20.286003 },
    { lat: 48.406726, lng: 20.286018 },
    { lat: 48.406749, lng: 20.286017 },
    { lat: 48.40691, lng: 20.286016 },
    { lat: 48.40699, lng: 20.286015 },
    { lat: 48.407082, lng: 20.286015 },
    { lat: 48.40716, lng: 20.285962 },
    { lat: 48.407166, lng: 20.285957 },
    { lat: 48.407265, lng: 20.285817 },
    { lat: 48.407326, lng: 20.285733 },
    { lat: 48.407338, lng: 20.285707 },
    { lat: 48.407387, lng: 20.285605 },
    { lat: 48.407396, lng: 20.285587 },
    { lat: 48.407464, lng: 20.285448 },
    { lat: 48.407642, lng: 20.285451 },
    { lat: 48.407673, lng: 20.285416 },
    { lat: 48.407683, lng: 20.285404 },
    { lat: 48.407719, lng: 20.285364 },
    { lat: 48.407729, lng: 20.285351 },
    { lat: 48.407914, lng: 20.285141 },
    { lat: 48.408031, lng: 20.285089 },
    { lat: 48.408121, lng: 20.285134 },
    { lat: 48.408167, lng: 20.285121 },
    { lat: 48.408201, lng: 20.285112 },
    { lat: 48.408395, lng: 20.285024 },
    { lat: 48.408555, lng: 20.285047 },
    { lat: 48.408588, lng: 20.285257 },
    { lat: 48.408577, lng: 20.285277 },
    { lat: 48.408502, lng: 20.285412 },
    { lat: 48.408518, lng: 20.285517 },
    { lat: 48.408565, lng: 20.285567 },
    { lat: 48.408648, lng: 20.285608 },
    { lat: 48.408743, lng: 20.285637 },
    { lat: 48.40882, lng: 20.285735 },
    { lat: 48.408973, lng: 20.285739 },
    { lat: 48.409041, lng: 20.285815 },
    { lat: 48.409084, lng: 20.285953 },
    { lat: 48.409073, lng: 20.286052 },
    { lat: 48.409071, lng: 20.286068 },
    { lat: 48.409067, lng: 20.286109 },
    { lat: 48.409069, lng: 20.286125 },
    { lat: 48.409116, lng: 20.286524 },
    { lat: 48.409271, lng: 20.286835 },
    { lat: 48.409334, lng: 20.286848 },
    { lat: 48.409424, lng: 20.286799 },
    { lat: 48.409524, lng: 20.286787 },
    { lat: 48.409652, lng: 20.286636 },
    { lat: 48.409879, lng: 20.286622 },
    { lat: 48.410007, lng: 20.286578 },
    { lat: 48.410021, lng: 20.286565 },
    { lat: 48.410055, lng: 20.286533 },
    { lat: 48.41007, lng: 20.28652 },
    { lat: 48.410285, lng: 20.286319 },
    { lat: 48.410349, lng: 20.286265 },
    { lat: 48.410438, lng: 20.286254 },
    { lat: 48.410505, lng: 20.286275 },
    { lat: 48.410573, lng: 20.286345 },
    { lat: 48.410649, lng: 20.286478 },
    { lat: 48.410676, lng: 20.286439 },
    { lat: 48.410733, lng: 20.286521 },
    { lat: 48.410856, lng: 20.286604 },
    { lat: 48.410888, lng: 20.286755 },
    { lat: 48.410854, lng: 20.286861 },
    { lat: 48.410846, lng: 20.286887 },
    { lat: 48.410906, lng: 20.286993 },
    { lat: 48.410934, lng: 20.287043 },
    { lat: 48.411028, lng: 20.287102 },
    { lat: 48.411093, lng: 20.287202 },
    { lat: 48.411143, lng: 20.287336 },
    { lat: 48.411346, lng: 20.2873 },
    { lat: 48.411373, lng: 20.287242 },
    { lat: 48.411434, lng: 20.287212 },
    { lat: 48.41149, lng: 20.287183 },
    { lat: 48.411594, lng: 20.287128 },
    { lat: 48.411676, lng: 20.287161 },
    { lat: 48.411736, lng: 20.287184 },
    { lat: 48.41178, lng: 20.28722 },
    { lat: 48.41183, lng: 20.28726 },
    { lat: 48.411837, lng: 20.287266 },
    { lat: 48.411892, lng: 20.287258 },
    { lat: 48.411947, lng: 20.287249 },
    { lat: 48.411988, lng: 20.287243 },
    { lat: 48.412121, lng: 20.287177 },
    { lat: 48.412111, lng: 20.286996 },
    { lat: 48.41211, lng: 20.28697 },
    { lat: 48.4121579, lng: 20.2869823 },
    { lat: 48.412177, lng: 20.286945 },
    { lat: 48.412187, lng: 20.286941 },
    { lat: 48.412196, lng: 20.286937 },
    { lat: 48.41225, lng: 20.286917 },
    { lat: 48.412253, lng: 20.286906 },
    { lat: 48.412257, lng: 20.286894 },
    { lat: 48.412498, lng: 20.286005 },
    { lat: 48.412514, lng: 20.285959 },
    { lat: 48.41263, lng: 20.285947 },
    { lat: 48.412657, lng: 20.285814 },
    { lat: 48.412693, lng: 20.285726 },
    { lat: 48.412768, lng: 20.28573 },
    { lat: 48.412828, lng: 20.28577 },
    { lat: 48.412858, lng: 20.285806 },
    { lat: 48.412922, lng: 20.285899 },
    { lat: 48.412967, lng: 20.285987 },
    { lat: 48.413005, lng: 20.286128 },
    { lat: 48.412994, lng: 20.286302 },
    { lat: 48.413011, lng: 20.286397 },
    { lat: 48.413045, lng: 20.286454 },
    { lat: 48.413167, lng: 20.286471 },
    { lat: 48.413303, lng: 20.286465 },
    { lat: 48.413433, lng: 20.286439 },
    { lat: 48.413507, lng: 20.286338 },
    { lat: 48.413611, lng: 20.286256 },
    { lat: 48.413708, lng: 20.286255 },
    { lat: 48.413769, lng: 20.286337 },
    { lat: 48.413824, lng: 20.286397 },
    { lat: 48.413832, lng: 20.286407 },
    { lat: 48.413838, lng: 20.286413 },
    { lat: 48.413898, lng: 20.286438 },
    { lat: 48.413916, lng: 20.286446 },
    { lat: 48.413956, lng: 20.286463 },
    { lat: 48.41402, lng: 20.286436 },
    { lat: 48.414062, lng: 20.286398 },
    { lat: 48.414077, lng: 20.286384 },
    { lat: 48.41408, lng: 20.286377 },
    { lat: 48.414097, lng: 20.286345 },
    { lat: 48.414101, lng: 20.286328 },
    { lat: 48.414106, lng: 20.286308 },
    { lat: 48.41411, lng: 20.286291 },
    { lat: 48.414098, lng: 20.286059 },
    { lat: 48.414128, lng: 20.285998 },
    { lat: 48.414157, lng: 20.285965 },
    { lat: 48.414243, lng: 20.285922 },
    { lat: 48.414363, lng: 20.285897 },
    { lat: 48.414451, lng: 20.285894 },
    { lat: 48.414516, lng: 20.285917 },
    { lat: 48.414595, lng: 20.285977 },
    { lat: 48.414662, lng: 20.28606 },
    { lat: 48.4147, lng: 20.286131 },
    { lat: 48.414707, lng: 20.286147 },
    { lat: 48.414709, lng: 20.28619 },
    { lat: 48.41471, lng: 20.286208 },
    { lat: 48.414719, lng: 20.286458 },
    { lat: 48.414767, lng: 20.286485 },
    { lat: 48.414882, lng: 20.286468 },
    { lat: 48.415044, lng: 20.286433 },
    { lat: 48.415065, lng: 20.286265 },
    { lat: 48.414989, lng: 20.286116 },
    { lat: 48.414983, lng: 20.286106 },
    { lat: 48.414965, lng: 20.286068 },
    { lat: 48.414957, lng: 20.286054 },
    { lat: 48.41491, lng: 20.285962 },
    { lat: 48.414907, lng: 20.285915 },
    { lat: 48.414921, lng: 20.28587 },
    { lat: 48.414996, lng: 20.285819 },
    { lat: 48.415133, lng: 20.285816 },
    { lat: 48.415326, lng: 20.285747 },
    { lat: 48.415437, lng: 20.285747 },
    { lat: 48.41555, lng: 20.285629 },
    { lat: 48.415624, lng: 20.285644 },
    { lat: 48.415668, lng: 20.285634 },
    { lat: 48.415706, lng: 20.285586 },
    { lat: 48.415811, lng: 20.285335 },
    { lat: 48.415854, lng: 20.285322 },
    { lat: 48.415949, lng: 20.285428 },
    { lat: 48.416089, lng: 20.285345 },
    { lat: 48.416217, lng: 20.285343 },
    { lat: 48.416371, lng: 20.285412 },
    { lat: 48.416529, lng: 20.285345 },
    { lat: 48.416559, lng: 20.28555 },
    { lat: 48.416615, lng: 20.285597 },
    { lat: 48.416624, lng: 20.285604 },
    { lat: 48.416645, lng: 20.285621 },
    { lat: 48.416656, lng: 20.285649 },
    { lat: 48.416661, lng: 20.28566 },
    { lat: 48.416721, lng: 20.285797 },
    { lat: 48.416895, lng: 20.285964 },
    { lat: 48.416956, lng: 20.285981 },
    { lat: 48.41706, lng: 20.286011 },
    { lat: 48.417146, lng: 20.286162 },
    { lat: 48.417195, lng: 20.286189 },
    { lat: 48.417242, lng: 20.286178 },
    { lat: 48.417278, lng: 20.286113 },
    { lat: 48.417263, lng: 20.285978 },
    { lat: 48.41731, lng: 20.285836 },
    { lat: 48.417519, lng: 20.28585 },
    { lat: 48.418053, lng: 20.285887 },
    { lat: 48.418114, lng: 20.285885 },
    { lat: 48.418421, lng: 20.285919 },
    { lat: 48.418517, lng: 20.285862 },
    { lat: 48.41854, lng: 20.285849 },
    { lat: 48.418618, lng: 20.285802 },
    { lat: 48.418637, lng: 20.28579 },
    { lat: 48.418723, lng: 20.285739 },
    { lat: 48.418766, lng: 20.285726 },
    { lat: 48.418805, lng: 20.285723 },
    { lat: 48.418856, lng: 20.285738 },
    { lat: 48.419016, lng: 20.285842 },
    { lat: 48.419046, lng: 20.285852 },
    { lat: 48.419064, lng: 20.285858 },
    { lat: 48.419158, lng: 20.28589 },
    { lat: 48.419172, lng: 20.285894 },
    { lat: 48.419301, lng: 20.285937 },
    { lat: 48.419326, lng: 20.28594 },
    { lat: 48.419345, lng: 20.285942 },
    { lat: 48.419392, lng: 20.285931 },
    { lat: 48.419426, lng: 20.285881 },
    { lat: 48.41945, lng: 20.285827 },
    { lat: 48.419438, lng: 20.285767 },
    { lat: 48.419395, lng: 20.28572 },
    { lat: 48.419385, lng: 20.285712 },
    { lat: 48.419362, lng: 20.28569 },
    { lat: 48.419349, lng: 20.285679 },
    { lat: 48.419314, lng: 20.285563 },
    { lat: 48.419315, lng: 20.285491 },
    { lat: 48.419353, lng: 20.285482 },
    { lat: 48.419391, lng: 20.285499 },
    { lat: 48.419478, lng: 20.285548 },
    { lat: 48.419519, lng: 20.285527 },
    { lat: 48.419637, lng: 20.285463 },
    { lat: 48.419669, lng: 20.285385 },
    { lat: 48.419681, lng: 20.285354 },
    { lat: 48.419732, lng: 20.285226 },
    { lat: 48.419776, lng: 20.285191 },
    { lat: 48.419989, lng: 20.285014 },
    { lat: 48.420079, lng: 20.284936 },
    { lat: 48.420152, lng: 20.28487 },
    { lat: 48.420246, lng: 20.28475 },
    { lat: 48.420364, lng: 20.284773 },
    { lat: 48.420433, lng: 20.284744 },
    { lat: 48.42041, lng: 20.284665 },
    { lat: 48.420488, lng: 20.28456 },
    { lat: 48.420539, lng: 20.284639 },
    { lat: 48.420594, lng: 20.284656 },
    { lat: 48.420813, lng: 20.284478 },
    { lat: 48.420876, lng: 20.284423 },
    { lat: 48.420899, lng: 20.284126 },
    { lat: 48.420948, lng: 20.284036 },
    { lat: 48.42101, lng: 20.283987 },
    { lat: 48.420946, lng: 20.283753 },
    { lat: 48.421188, lng: 20.283532 },
    { lat: 48.42106, lng: 20.282912 },
    { lat: 48.420973, lng: 20.282488 },
    { lat: 48.420858, lng: 20.28184 },
    { lat: 48.420827, lng: 20.281661 },
    { lat: 48.420621, lng: 20.281235 },
    { lat: 48.420422, lng: 20.280814 },
    { lat: 48.420195, lng: 20.280325 },
    { lat: 48.419979, lng: 20.279862 },
    { lat: 48.419773, lng: 20.279423 },
    { lat: 48.419655, lng: 20.279166 },
    { lat: 48.419555, lng: 20.278946 },
    { lat: 48.419509, lng: 20.278848 },
    { lat: 48.419502, lng: 20.278831 },
    { lat: 48.41946, lng: 20.278671 },
    { lat: 48.419387, lng: 20.278389 },
    { lat: 48.419253, lng: 20.277873 },
    { lat: 48.419154, lng: 20.277488 },
    { lat: 48.419033, lng: 20.277102 },
    { lat: 48.418937, lng: 20.276825 },
    { lat: 48.418865, lng: 20.27657 },
    { lat: 48.418785, lng: 20.276294 },
    { lat: 48.418703, lng: 20.276012 },
    { lat: 48.418663, lng: 20.275878 },
    { lat: 48.418601, lng: 20.275578 },
    { lat: 48.418518, lng: 20.27518 },
    { lat: 48.418455, lng: 20.274879 },
    { lat: 48.4184, lng: 20.274614 },
    { lat: 48.418403, lng: 20.274136 },
    { lat: 48.418611, lng: 20.273609 },
    { lat: 48.418699, lng: 20.273392 },
    { lat: 48.419033, lng: 20.27287 },
    { lat: 48.419093, lng: 20.272491 },
    { lat: 48.419271, lng: 20.271845 },
    { lat: 48.419316, lng: 20.271742 },
    { lat: 48.41942, lng: 20.2715 },
    { lat: 48.419496, lng: 20.271319 },
    { lat: 48.419621, lng: 20.27094 },
    { lat: 48.419714, lng: 20.270642 },
    { lat: 48.419704, lng: 20.270155 },
    { lat: 48.419782, lng: 20.269734 },
    { lat: 48.420073, lng: 20.269107 },
    { lat: 48.420104, lng: 20.268898 },
    { lat: 48.420093, lng: 20.268425 },
    { lat: 48.420041, lng: 20.268127 },
    { lat: 48.42006, lng: 20.267475 },
    { lat: 48.420067, lng: 20.267053 },
    { lat: 48.420124, lng: 20.266335 },
    { lat: 48.420174, lng: 20.265716 },
    { lat: 48.41992, lng: 20.265432 },
    { lat: 48.419901, lng: 20.265404 },
    { lat: 48.419883, lng: 20.265346 },
    { lat: 48.41968, lng: 20.264782 },
    { lat: 48.4192071, lng: 20.2634342 },
    { lat: 48.4182063, lng: 20.263051 },
    { lat: 48.4176412, lng: 20.2589533 },
    { lat: 48.4173918, lng: 20.2592217 },
    { lat: 48.4169017, lng: 20.2600077 },
    { lat: 48.4166297, lng: 20.2609898 },
    { lat: 48.4163797, lng: 20.261354 },
    { lat: 48.4156094, lng: 20.2620903 },
    { lat: 48.4154132, lng: 20.2622019 },
    { lat: 48.4141872, lng: 20.2624409 },
    { lat: 48.4134818, lng: 20.2621738 },
    { lat: 48.4132644, lng: 20.2621727 },
    { lat: 48.4127605, lng: 20.2623842 },
    { lat: 48.4122868, lng: 20.2624863 },
    { lat: 48.4122587, lng: 20.2629954 },
    { lat: 48.4123451, lng: 20.263636 },
    { lat: 48.4121063, lng: 20.264885 },
    { lat: 48.4119783, lng: 20.2653446 },
    { lat: 48.4119513, lng: 20.2657706 },
    { lat: 48.4119887, lng: 20.2659222 },
    { lat: 48.4118907, lng: 20.2669115 },
    { lat: 48.4119484, lng: 20.2670347 },
    { lat: 48.4119313, lng: 20.2678799 },
    { lat: 48.4118872, lng: 20.2679895 },
    { lat: 48.4118598, lng: 20.2685705 },
    { lat: 48.4117735, lng: 20.2691493 },
    { lat: 48.4114998, lng: 20.2692936 },
    { lat: 48.4113769, lng: 20.2694837 },
    { lat: 48.4113048, lng: 20.2697978 },
    { lat: 48.4113106, lng: 20.2700848 },
    { lat: 48.4112285, lng: 20.2702286 },
    { lat: 48.4112702, lng: 20.2706322 },
    { lat: 48.4112273, lng: 20.2709129 },
    { lat: 48.4109898, lng: 20.2718505 },
    { lat: 48.411006, lng: 20.272484 },
    { lat: 48.4109586, lng: 20.2728845 },
    { lat: 48.4104947, lng: 20.2735099 },
    { lat: 48.4104776, lng: 20.2738464 },
    { lat: 48.4102402, lng: 20.2744486 },
    { lat: 48.410198, lng: 20.2748002 },
    { lat: 48.4100584, lng: 20.2750579 },
    { lat: 48.4099659, lng: 20.2757089 },
    { lat: 48.4098073, lng: 20.2758872 },
    { lat: 48.4098597, lng: 20.2759591 },
    { lat: 48.4097029, lng: 20.2763194 },
    { lat: 48.4094361, lng: 20.276518 },
    { lat: 48.4093102, lng: 20.2766941 },
    { lat: 48.4091739, lng: 20.2771785 },
    { lat: 48.4086735, lng: 20.2782186 },
    { lat: 48.408168, lng: 20.2789631 },
    { lat: 48.4080685, lng: 20.2790373 },
    { lat: 48.4079339, lng: 20.2794218 },
    { lat: 48.4077517, lng: 20.279654 },
    { lat: 48.4063665, lng: 20.2779541 },
    { lat: 48.4056213, lng: 20.2772551 },
    { lat: 48.4047069, lng: 20.2766851 },
    { lat: 48.4036023, lng: 20.2753307 },
    { lat: 48.4004825, lng: 20.2704455 },
    { lat: 48.4000874, lng: 20.2708204 },
    { lat: 48.39945, lng: 20.2691746 },
    { lat: 48.3988155, lng: 20.2678049 },
    { lat: 48.398384, lng: 20.266947 },
    { lat: 48.3978056, lng: 20.2661649 },
    { lat: 48.3976333, lng: 20.2659971 },
    { lat: 48.3968857, lng: 20.265524 },
    { lat: 48.3968544, lng: 20.2656392 },
    { lat: 48.3965755, lng: 20.2655405 },
    { lat: 48.3957586, lng: 20.2650222 },
    { lat: 48.3958123, lng: 20.2648714 },
    { lat: 48.3954524, lng: 20.2646567 },
    { lat: 48.3948519, lng: 20.2639131 },
    { lat: 48.3946832, lng: 20.2638361 },
    { lat: 48.3941472, lng: 20.2633828 },
    { lat: 48.3934204, lng: 20.2626616 },
    { lat: 48.3933224, lng: 20.2621558 },
    { lat: 48.3929176, lng: 20.2619182 },
    { lat: 48.3925756, lng: 20.2614726 },
    { lat: 48.3923211, lng: 20.2615876 },
    { lat: 48.392191, lng: 20.2615163 },
    { lat: 48.3921195, lng: 20.261415 },
    { lat: 48.3920594, lng: 20.2611511 },
    { lat: 48.3918586, lng: 20.2610334 },
    { lat: 48.3916949, lng: 20.2608507 },
    { lat: 48.391425, lng: 20.260831 },
    { lat: 48.3912127, lng: 20.2607079 },
    { lat: 48.3909382, lng: 20.2601195 },
    { lat: 48.390618, lng: 20.2598832 },
    { lat: 48.3904866, lng: 20.2598624 },
    { lat: 48.3903783, lng: 20.259691 },
    { lat: 48.3902879, lng: 20.2589735 },
    { lat: 48.3904687, lng: 20.2579179 },
    { lat: 48.3905912, lng: 20.2575703 },
    { lat: 48.3905095, lng: 20.256313 },
    { lat: 48.3902277, lng: 20.2556544 },
    { lat: 48.3898103, lng: 20.2551596 },
    { lat: 48.3893429, lng: 20.2547877 },
    { lat: 48.3883911, lng: 20.2543332 },
    { lat: 48.3884508, lng: 20.2538835 },
    { lat: 48.3883139, lng: 20.2537828 },
    { lat: 48.3878267, lng: 20.2536257 },
    { lat: 48.3875784, lng: 20.253299 },
    { lat: 48.3874645, lng: 20.2535508 },
    { lat: 48.3873712, lng: 20.2535553 },
    { lat: 48.3866844, lng: 20.2529686 },
    { lat: 48.3862696, lng: 20.2532426 },
    { lat: 48.3847506, lng: 20.2546039 },
    { lat: 48.3832677, lng: 20.2545592 },
    { lat: 48.382274, lng: 20.2560497 },
    { lat: 48.3812909, lng: 20.2568388 },
    { lat: 48.3811074, lng: 20.2568715 },
    { lat: 48.3812379, lng: 20.2569551 },
    { lat: 48.3815601, lng: 20.257182 },
    { lat: 48.3827319, lng: 20.2577858 },
    { lat: 48.383013, lng: 20.2586229 },
    { lat: 48.383401, lng: 20.2598841 },
    { lat: 48.3835221, lng: 20.2619377 },
    { lat: 48.3837524, lng: 20.2633651 },
    { lat: 48.3838726, lng: 20.2638656 },
    { lat: 48.3839423, lng: 20.265448 },
    { lat: 48.3842005, lng: 20.2664205 },
    { lat: 48.3845142, lng: 20.2672299 },
    { lat: 48.3851249, lng: 20.2674892 },
    { lat: 48.3861564, lng: 20.2677125 },
    { lat: 48.3871781, lng: 20.2687952 },
    { lat: 48.3876887, lng: 20.2694578 },
    { lat: 48.3880043, lng: 20.2709095 },
    { lat: 48.387943, lng: 20.2728837 },
    { lat: 48.3884309, lng: 20.2749337 },
    { lat: 48.3892386, lng: 20.2761906 },
    { lat: 48.3895829, lng: 20.2769454 },
    { lat: 48.3899975, lng: 20.2785386 },
    { lat: 48.3912523, lng: 20.282071 },
    { lat: 48.3914449, lng: 20.2828698 },
    { lat: 48.3914394, lng: 20.2832672 },
    { lat: 48.3915648, lng: 20.2836504 },
    { lat: 48.3919102, lng: 20.2842638 },
  ],
  RimavskéZalužany: [
    { lat: 48.4977381, lng: 19.9613937 },
    { lat: 48.4977462, lng: 19.9613162 },
    { lat: 48.4978056, lng: 19.9613648 },
    { lat: 48.498163, lng: 19.9611317 },
    { lat: 48.4984563, lng: 19.9607157 },
    { lat: 48.4986319, lng: 19.960554 },
    { lat: 48.4990106, lng: 19.9604761 },
    { lat: 48.4997801, lng: 19.9607981 },
    { lat: 48.5002562, lng: 19.9610821 },
    { lat: 48.5005408, lng: 19.9613379 },
    { lat: 48.5007561, lng: 19.9617389 },
    { lat: 48.5010548, lng: 19.9619081 },
    { lat: 48.5011656, lng: 19.9610631 },
    { lat: 48.5009011, lng: 19.9601117 },
    { lat: 48.5008852, lng: 19.9598439 },
    { lat: 48.5009661, lng: 19.9595971 },
    { lat: 48.5011051, lng: 19.9580689 },
    { lat: 48.5012219, lng: 19.9574709 },
    { lat: 48.5012873, lng: 19.9567297 },
    { lat: 48.5014582, lng: 19.9562444 },
    { lat: 48.5016327, lng: 19.9550985 },
    { lat: 48.5018135, lng: 19.9547131 },
    { lat: 48.5020354, lng: 19.9540457 },
    { lat: 48.5023589, lng: 19.9535465 },
    { lat: 48.5027376, lng: 19.9524346 },
    { lat: 48.5028457, lng: 19.9518228 },
    { lat: 48.5027802, lng: 19.9516572 },
    { lat: 48.5029042, lng: 19.9512929 },
    { lat: 48.5028467, lng: 19.9512323 },
    { lat: 48.5027499, lng: 19.9512668 },
    { lat: 48.5025854, lng: 19.9508109 },
    { lat: 48.5024418, lng: 19.9494969 },
    { lat: 48.5022532, lng: 19.9491396 },
    { lat: 48.5022072, lng: 19.9488527 },
    { lat: 48.501889, lng: 19.9482563 },
    { lat: 48.5018174, lng: 19.9480327 },
    { lat: 48.5016694, lng: 19.947832 },
    { lat: 48.5016407, lng: 19.9473719 },
    { lat: 48.5016729, lng: 19.9472541 },
    { lat: 48.5016078, lng: 19.9467542 },
    { lat: 48.5017428, lng: 19.946565 },
    { lat: 48.5016376, lng: 19.9462099 },
    { lat: 48.5017774, lng: 19.9459212 },
    { lat: 48.5017994, lng: 19.945696 },
    { lat: 48.5019116, lng: 19.9453414 },
    { lat: 48.5019165, lng: 19.9451409 },
    { lat: 48.5020878, lng: 19.9447931 },
    { lat: 48.5022548, lng: 19.9442445 },
    { lat: 48.5024182, lng: 19.9432129 },
    { lat: 48.5023967, lng: 19.9426786 },
    { lat: 48.5026243, lng: 19.9407467 },
    { lat: 48.502643, lng: 19.9406273 },
    { lat: 48.5026767, lng: 19.9398316 },
    { lat: 48.5027429, lng: 19.9379681 },
    { lat: 48.5029226, lng: 19.9358944 },
    { lat: 48.5032711, lng: 19.9352406 },
    { lat: 48.503359, lng: 19.9346823 },
    { lat: 48.5035689, lng: 19.9339527 },
    { lat: 48.5036616, lng: 19.9338773 },
    { lat: 48.5039763, lng: 19.9331738 },
    { lat: 48.5041958, lng: 19.9325349 },
    { lat: 48.5042432, lng: 19.9323016 },
    { lat: 48.5042441, lng: 19.9319516 },
    { lat: 48.5052735, lng: 19.9309408 },
    { lat: 48.5057183, lng: 19.9303267 },
    { lat: 48.5057535, lng: 19.9301727 },
    { lat: 48.5057245, lng: 19.9296255 },
    { lat: 48.5056127, lng: 19.9293003 },
    { lat: 48.5059078, lng: 19.9290633 },
    { lat: 48.5072091, lng: 19.9276645 },
    { lat: 48.5075796, lng: 19.9279822 },
    { lat: 48.5077359, lng: 19.9270737 },
    { lat: 48.5072375, lng: 19.9264298 },
    { lat: 48.5073436, lng: 19.9262234 },
    { lat: 48.5074945, lng: 19.9261403 },
    { lat: 48.508086, lng: 19.9254853 },
    { lat: 48.5081772, lng: 19.9254811 },
    { lat: 48.5083017, lng: 19.9252303 },
    { lat: 48.509023, lng: 19.9243134 },
    { lat: 48.5090457, lng: 19.9237381 },
    { lat: 48.5089745, lng: 19.9233649 },
    { lat: 48.5090169, lng: 19.9227977 },
    { lat: 48.5093672, lng: 19.9223212 },
    { lat: 48.5095106, lng: 19.9216924 },
    { lat: 48.5097758, lng: 19.9211523 },
    { lat: 48.510161, lng: 19.9199359 },
    { lat: 48.5101786, lng: 19.918766 },
    { lat: 48.510237, lng: 19.9183149 },
    { lat: 48.5102117, lng: 19.9177716 },
    { lat: 48.5102673, lng: 19.9171772 },
    { lat: 48.5102004, lng: 19.9171634 },
    { lat: 48.5101684, lng: 19.9169956 },
    { lat: 48.5070194, lng: 19.9171978 },
    { lat: 48.5038919, lng: 19.917139 },
    { lat: 48.5027056, lng: 19.91701 },
    { lat: 48.5018744, lng: 19.9171142 },
    { lat: 48.5005193, lng: 19.9169646 },
    { lat: 48.4980329, lng: 19.9169588 },
    { lat: 48.4980786, lng: 19.9173163 },
    { lat: 48.4981938, lng: 19.9176156 },
    { lat: 48.493711, lng: 19.9203245 },
    { lat: 48.493614, lng: 19.9210616 },
    { lat: 48.4935541, lng: 19.9221904 },
    { lat: 48.4934969, lng: 19.922061 },
    { lat: 48.4930271, lng: 19.9239919 },
    { lat: 48.492666, lng: 19.925854 },
    { lat: 48.4929063, lng: 19.9269439 },
    { lat: 48.4927812, lng: 19.9284227 },
    { lat: 48.4926002, lng: 19.9292762 },
    { lat: 48.492489, lng: 19.9309988 },
    { lat: 48.4925442, lng: 19.9317701 },
    { lat: 48.4920172, lng: 19.9328303 },
    { lat: 48.4918985, lng: 19.9333258 },
    { lat: 48.4917313, lng: 19.9333135 },
    { lat: 48.4916717, lng: 19.9344301 },
    { lat: 48.4919721, lng: 19.935425 },
    { lat: 48.4925724, lng: 19.9362974 },
    { lat: 48.4925069, lng: 19.937454 },
    { lat: 48.4922246, lng: 19.9381801 },
    { lat: 48.492119, lng: 19.938772 },
    { lat: 48.4918831, lng: 19.9412534 },
    { lat: 48.4916088, lng: 19.9413689 },
    { lat: 48.4903047, lng: 19.9421712 },
    { lat: 48.4903294, lng: 19.9422523 },
    { lat: 48.4900793, lng: 19.9437118 },
    { lat: 48.489715, lng: 19.9455267 },
    { lat: 48.4895708, lng: 19.9465115 },
    { lat: 48.4888273, lng: 19.9505958 },
    { lat: 48.4881051, lng: 19.9501698 },
    { lat: 48.4876639, lng: 19.9505488 },
    { lat: 48.4869221, lng: 19.9505912 },
    { lat: 48.4867217, lng: 19.9506971 },
    { lat: 48.485496, lng: 19.9504323 },
    { lat: 48.4854313, lng: 19.9506335 },
    { lat: 48.4854889, lng: 19.9510896 },
    { lat: 48.4854678, lng: 19.9512532 },
    { lat: 48.4854252, lng: 19.9513407 },
    { lat: 48.4852922, lng: 19.9513659 },
    { lat: 48.4842304, lng: 19.9509494 },
    { lat: 48.4839197, lng: 19.9503749 },
    { lat: 48.4836279, lng: 19.9501064 },
    { lat: 48.4836031, lng: 19.9500498 },
    { lat: 48.483553, lng: 19.9501711 },
    { lat: 48.4834931, lng: 19.9510194 },
    { lat: 48.4836429, lng: 19.9510708 },
    { lat: 48.483758, lng: 19.9508857 },
    { lat: 48.4838858, lng: 19.9512435 },
    { lat: 48.4841297, lng: 19.9513131 },
    { lat: 48.4848801, lng: 19.9518188 },
    { lat: 48.4849273, lng: 19.9519226 },
    { lat: 48.4849085, lng: 19.9523547 },
    { lat: 48.4849784, lng: 19.9526765 },
    { lat: 48.485226, lng: 19.9526712 },
    { lat: 48.4853596, lng: 19.9532993 },
    { lat: 48.4857181, lng: 19.9537042 },
    { lat: 48.4858824, lng: 19.9537257 },
    { lat: 48.4862855, lng: 19.9541707 },
    { lat: 48.4866491, lng: 19.9543492 },
    { lat: 48.4868044, lng: 19.9543503 },
    { lat: 48.487314, lng: 19.9550209 },
    { lat: 48.4874459, lng: 19.9549194 },
    { lat: 48.4875863, lng: 19.9552263 },
    { lat: 48.4878293, lng: 19.9554054 },
    { lat: 48.4880261, lng: 19.9554554 },
    { lat: 48.4881676, lng: 19.9556009 },
    { lat: 48.4882638, lng: 19.9555597 },
    { lat: 48.4885976, lng: 19.9557003 },
    { lat: 48.4890489, lng: 19.9559823 },
    { lat: 48.4892139, lng: 19.9559441 },
    { lat: 48.4892418, lng: 19.9560398 },
    { lat: 48.4894002, lng: 19.9561404 },
    { lat: 48.48946, lng: 19.9562977 },
    { lat: 48.4899546, lng: 19.9566437 },
    { lat: 48.4899802, lng: 19.9569033 },
    { lat: 48.4903059, lng: 19.9573579 },
    { lat: 48.4904401, lng: 19.9573607 },
    { lat: 48.4906672, lng: 19.9577235 },
    { lat: 48.4910192, lng: 19.9580041 },
    { lat: 48.4911922, lng: 19.9580461 },
    { lat: 48.4912473, lng: 19.9582276 },
    { lat: 48.4914891, lng: 19.9585144 },
    { lat: 48.4928799, lng: 19.959383 },
    { lat: 48.4936284, lng: 19.9595268 },
    { lat: 48.4944652, lng: 19.9595195 },
    { lat: 48.4950406, lng: 19.9596227 },
    { lat: 48.495792, lng: 19.9596473 },
    { lat: 48.496376, lng: 19.9597629 },
    { lat: 48.4971007, lng: 19.9603601 },
    { lat: 48.4975737, lng: 19.960861 },
    { lat: 48.4977381, lng: 19.9613937 },
  ],
  Babinec: [
    { lat: 48.5465088, lng: 19.99361 },
    { lat: 48.5448494, lng: 19.9936603 },
    { lat: 48.5443089, lng: 19.9936112 },
    { lat: 48.5434884, lng: 19.9932013 },
    { lat: 48.5431392, lng: 19.9931979 },
    { lat: 48.5427113, lng: 19.9933908 },
    { lat: 48.5419258, lng: 19.9933313 },
    { lat: 48.5411421, lng: 19.9935204 },
    { lat: 48.5392792, lng: 19.993476 },
    { lat: 48.5388547, lng: 19.9931603 },
    { lat: 48.5383009, lng: 19.9929291 },
    { lat: 48.5378071, lng: 19.9930529 },
    { lat: 48.5373868, lng: 19.9930492 },
    { lat: 48.5369721, lng: 19.9932067 },
    { lat: 48.5366845, lng: 19.9931996 },
    { lat: 48.536116, lng: 19.9929115 },
    { lat: 48.533911, lng: 19.9911567 },
    { lat: 48.5332668, lng: 19.9911623 },
    { lat: 48.532603, lng: 19.990937 },
    { lat: 48.5322877, lng: 19.9906173 },
    { lat: 48.5319966, lng: 19.9905558 },
    { lat: 48.5317978, lng: 19.9906494 },
    { lat: 48.5312522, lng: 19.9907213 },
    { lat: 48.5306381, lng: 19.9912493 },
    { lat: 48.5299002, lng: 19.9914084 },
    { lat: 48.5297308, lng: 19.9913983 },
    { lat: 48.5287677, lng: 19.9916173 },
    { lat: 48.5274573, lng: 19.991552 },
    { lat: 48.5267769, lng: 19.9916069 },
    { lat: 48.5265066, lng: 19.9917413 },
    { lat: 48.5261038, lng: 19.9921248 },
    { lat: 48.5259013, lng: 19.9924792 },
    { lat: 48.5255216, lng: 19.9927744 },
    { lat: 48.5250665, lng: 19.9933135 },
    { lat: 48.5247409, lng: 19.9935524 },
    { lat: 48.5245489, lng: 19.9935913 },
    { lat: 48.5239132, lng: 19.9929944 },
    { lat: 48.5232534, lng: 19.9928927 },
    { lat: 48.5226324, lng: 19.9929657 },
    { lat: 48.5224277, lng: 19.9929205 },
    { lat: 48.5221805, lng: 19.9927515 },
    { lat: 48.5217241, lng: 19.9927694 },
    { lat: 48.5215324, lng: 19.992713 },
    { lat: 48.5212072, lng: 19.9925066 },
    { lat: 48.5200859, lng: 19.9927871 },
    { lat: 48.5194397, lng: 19.992558 },
    { lat: 48.5192074, lng: 19.9926583 },
    { lat: 48.5182356, lng: 19.9927711 },
    { lat: 48.5179467, lng: 19.9925481 },
    { lat: 48.5177535, lng: 19.9922357 },
    { lat: 48.5176007, lng: 19.9918481 },
    { lat: 48.5170868, lng: 19.9912791 },
    { lat: 48.5163129, lng: 19.9906604 },
    { lat: 48.5158114, lng: 19.9905223 },
    { lat: 48.515839, lng: 19.9904533 },
    { lat: 48.515454, lng: 19.9902746 },
    { lat: 48.5148797, lng: 19.9905439 },
    { lat: 48.5138669, lng: 19.9901974 },
    { lat: 48.5136516, lng: 19.9901905 },
    { lat: 48.5131111, lng: 19.9903326 },
    { lat: 48.5125417, lng: 19.9905837 },
    { lat: 48.5118199, lng: 19.9911519 },
    { lat: 48.5111164, lng: 19.9919984 },
    { lat: 48.51035, lng: 19.9936999 },
    { lat: 48.5099075, lng: 19.9942191 },
    { lat: 48.5090437, lng: 19.9948911 },
    { lat: 48.5084608, lng: 19.9956591 },
    { lat: 48.508255, lng: 19.9961491 },
    { lat: 48.5080955, lng: 19.9963756 },
    { lat: 48.5076996, lng: 19.9973237 },
    { lat: 48.5069402, lng: 19.9986102 },
    { lat: 48.5064291, lng: 19.9992872 },
    { lat: 48.5057345, lng: 20.0007475 },
    { lat: 48.5055562, lng: 20.0009804 },
    { lat: 48.5046438, lng: 20.0015978 },
    { lat: 48.5038088, lng: 20.0023947 },
    { lat: 48.5035853, lng: 20.0027097 },
    { lat: 48.5032727, lng: 20.0033389 },
    { lat: 48.5032595, lng: 20.0035864 },
    { lat: 48.5032576, lng: 20.0035968 },
    { lat: 48.5032668, lng: 20.0036823 },
    { lat: 48.50271, lng: 20.0060053 },
    { lat: 48.5027229, lng: 20.0065834 },
    { lat: 48.502932, lng: 20.0077044 },
    { lat: 48.5033016, lng: 20.0088621 },
    { lat: 48.5037631, lng: 20.0095515 },
    { lat: 48.5040932, lng: 20.0104657 },
    { lat: 48.5041534, lng: 20.0108032 },
    { lat: 48.5045742, lng: 20.0122733 },
    { lat: 48.5047218, lng: 20.0131033 },
    { lat: 48.5048448, lng: 20.0134081 },
    { lat: 48.504907, lng: 20.0144888 },
    { lat: 48.5048118, lng: 20.0151184 },
    { lat: 48.5049267, lng: 20.0152023 },
    { lat: 48.5049625, lng: 20.0156778 },
    { lat: 48.5049095, lng: 20.0156746 },
    { lat: 48.5048205, lng: 20.0158383 },
    { lat: 48.5049303, lng: 20.0160442 },
    { lat: 48.504854, lng: 20.0163967 },
    { lat: 48.5046977, lng: 20.0165145 },
    { lat: 48.5047097, lng: 20.0169606 },
    { lat: 48.5048233, lng: 20.0174699 },
    { lat: 48.5047805, lng: 20.017496 },
    { lat: 48.5048913, lng: 20.0191464 },
    { lat: 48.5050219, lng: 20.0191195 },
    { lat: 48.5050682, lng: 20.0187187 },
    { lat: 48.5051415, lng: 20.0185594 },
    { lat: 48.5051929, lng: 20.018573 },
    { lat: 48.5052395, lng: 20.018803 },
    { lat: 48.5053003, lng: 20.0187489 },
    { lat: 48.5053017, lng: 20.01867 },
    { lat: 48.5055496, lng: 20.0185697 },
    { lat: 48.5059126, lng: 20.0186522 },
    { lat: 48.5060305, lng: 20.0184585 },
    { lat: 48.5060013, lng: 20.018295 },
    { lat: 48.5062843, lng: 20.0181652 },
    { lat: 48.5064181, lng: 20.0182715 },
    { lat: 48.5063788, lng: 20.0184099 },
    { lat: 48.5065203, lng: 20.0186103 },
    { lat: 48.5065958, lng: 20.0185307 },
    { lat: 48.5066608, lng: 20.0186333 },
    { lat: 48.5068235, lng: 20.0187258 },
    { lat: 48.5069743, lng: 20.0186682 },
    { lat: 48.5070896, lng: 20.01849 },
    { lat: 48.507206, lng: 20.0186324 },
    { lat: 48.5073469, lng: 20.0186887 },
    { lat: 48.5073381, lng: 20.0189926 },
    { lat: 48.5074207, lng: 20.0189979 },
    { lat: 48.5075416, lng: 20.0188458 },
    { lat: 48.5076607, lng: 20.0190309 },
    { lat: 48.5074937, lng: 20.0192933 },
    { lat: 48.5075207, lng: 20.019588 },
    { lat: 48.5073655, lng: 20.0197544 },
    { lat: 48.5074281, lng: 20.0200092 },
    { lat: 48.507562, lng: 20.0200058 },
    { lat: 48.5076108, lng: 20.0202587 },
    { lat: 48.5078202, lng: 20.0203085 },
    { lat: 48.5078275, lng: 20.0206194 },
    { lat: 48.5079392, lng: 20.0205417 },
    { lat: 48.5080405, lng: 20.0203498 },
    { lat: 48.5081099, lng: 20.0204246 },
    { lat: 48.5081787, lng: 20.0203435 },
    { lat: 48.5082454, lng: 20.0204857 },
    { lat: 48.5081593, lng: 20.0205784 },
    { lat: 48.5082506, lng: 20.0207402 },
    { lat: 48.5085287, lng: 20.02085 },
    { lat: 48.5087194, lng: 20.0207754 },
    { lat: 48.5087026, lng: 20.0204534 },
    { lat: 48.5087809, lng: 20.0203892 },
    { lat: 48.5087767, lng: 20.0202318 },
    { lat: 48.5089315, lng: 20.0202189 },
    { lat: 48.5090435, lng: 20.0200421 },
    { lat: 48.5091033, lng: 20.0200954 },
    { lat: 48.5091736, lng: 20.0200244 },
    { lat: 48.5092215, lng: 20.0201204 },
    { lat: 48.5092839, lng: 20.0201334 },
    { lat: 48.5093282, lng: 20.0203302 },
    { lat: 48.5094759, lng: 20.0201983 },
    { lat: 48.509563, lng: 20.020205 },
    { lat: 48.5096524, lng: 20.0200562 },
    { lat: 48.5096358, lng: 20.0198748 },
    { lat: 48.5096834, lng: 20.0197986 },
    { lat: 48.5097335, lng: 20.0198119 },
    { lat: 48.5098569, lng: 20.020255 },
    { lat: 48.5099567, lng: 20.0199791 },
    { lat: 48.5100673, lng: 20.019981 },
    { lat: 48.5100891, lng: 20.019888 },
    { lat: 48.5102873, lng: 20.0198007 },
    { lat: 48.5107192, lng: 20.0201371 },
    { lat: 48.5107947, lng: 20.0200873 },
    { lat: 48.5107544, lng: 20.0200084 },
    { lat: 48.5107765, lng: 20.0199718 },
    { lat: 48.5108674, lng: 20.0199618 },
    { lat: 48.5109517, lng: 20.020043 },
    { lat: 48.5112889, lng: 20.0198881 },
    { lat: 48.5113499, lng: 20.0198106 },
    { lat: 48.5112875, lng: 20.0196964 },
    { lat: 48.5113943, lng: 20.0195513 },
    { lat: 48.51143, lng: 20.0196829 },
    { lat: 48.5114986, lng: 20.019675 },
    { lat: 48.5116345, lng: 20.0195893 },
    { lat: 48.5116462, lng: 20.0194344 },
    { lat: 48.5117853, lng: 20.019261 },
    { lat: 48.511847, lng: 20.0192278 },
    { lat: 48.5119417, lng: 20.0193368 },
    { lat: 48.5119818, lng: 20.0192459 },
    { lat: 48.512055, lng: 20.0192475 },
    { lat: 48.5120338, lng: 20.018955 },
    { lat: 48.5120647, lng: 20.0189073 },
    { lat: 48.5121693, lng: 20.0189589 },
    { lat: 48.5121379, lng: 20.0188301 },
    { lat: 48.5121813, lng: 20.0187606 },
    { lat: 48.5122627, lng: 20.0188232 },
    { lat: 48.5123543, lng: 20.0187506 },
    { lat: 48.5123904, lng: 20.0186186 },
    { lat: 48.5124668, lng: 20.0186401 },
    { lat: 48.5125328, lng: 20.0185759 },
    { lat: 48.5125615, lng: 20.0184833 },
    { lat: 48.5126034, lng: 20.0185657 },
    { lat: 48.5126997, lng: 20.0185264 },
    { lat: 48.5127445, lng: 20.0184404 },
    { lat: 48.5126905, lng: 20.0183049 },
    { lat: 48.5127662, lng: 20.0182305 },
    { lat: 48.5127734, lng: 20.0181315 },
    { lat: 48.5129646, lng: 20.0183684 },
    { lat: 48.5129585, lng: 20.0185232 },
    { lat: 48.513024, lng: 20.0185532 },
    { lat: 48.5133087, lng: 20.0183554 },
    { lat: 48.5133858, lng: 20.0184511 },
    { lat: 48.5134808, lng: 20.0182147 },
    { lat: 48.51358, lng: 20.0181606 },
    { lat: 48.5136215, lng: 20.0182959 },
    { lat: 48.5136866, lng: 20.0182883 },
    { lat: 48.5137518, lng: 20.0181493 },
    { lat: 48.5136628, lng: 20.0179346 },
    { lat: 48.5137119, lng: 20.0178148 },
    { lat: 48.513814, lng: 20.0178851 },
    { lat: 48.5138779, lng: 20.0178222 },
    { lat: 48.5138429, lng: 20.0176848 },
    { lat: 48.5137537, lng: 20.0177359 },
    { lat: 48.5137891, lng: 20.017561 },
    { lat: 48.5138266, lng: 20.0175162 },
    { lat: 48.5139277, lng: 20.0175858 },
    { lat: 48.5139601, lng: 20.0175313 },
    { lat: 48.5139206, lng: 20.0174153 },
    { lat: 48.514005, lng: 20.0173334 },
    { lat: 48.5140297, lng: 20.0171466 },
    { lat: 48.514193, lng: 20.016902 },
    { lat: 48.5143145, lng: 20.0170088 },
    { lat: 48.514208, lng: 20.0171244 },
    { lat: 48.5142057, lng: 20.0173129 },
    { lat: 48.5143029, lng: 20.0173727 },
    { lat: 48.514468, lng: 20.0172018 },
    { lat: 48.5146163, lng: 20.0168694 },
    { lat: 48.5147482, lng: 20.0169258 },
    { lat: 48.5148433, lng: 20.0167775 },
    { lat: 48.5149901, lng: 20.0168105 },
    { lat: 48.5149667, lng: 20.0168717 },
    { lat: 48.5150005, lng: 20.0169243 },
    { lat: 48.5150673, lng: 20.0168234 },
    { lat: 48.5151211, lng: 20.016845 },
    { lat: 48.5150885, lng: 20.0170073 },
    { lat: 48.515162, lng: 20.0170451 },
    { lat: 48.5151765, lng: 20.0170405 },
    { lat: 48.5152081, lng: 20.0169043 },
    { lat: 48.5152768, lng: 20.0169333 },
    { lat: 48.515313, lng: 20.016884 },
    { lat: 48.5152999, lng: 20.0167058 },
    { lat: 48.5153407, lng: 20.0165481 },
    { lat: 48.5152618, lng: 20.0163883 },
    { lat: 48.5151579, lng: 20.0164038 },
    { lat: 48.5151647, lng: 20.0163008 },
    { lat: 48.5153735, lng: 20.0161762 },
    { lat: 48.5153932, lng: 20.0159473 },
    { lat: 48.5152678, lng: 20.0160459 },
    { lat: 48.5152216, lng: 20.0159078 },
    { lat: 48.5151411, lng: 20.0158966 },
    { lat: 48.5151319, lng: 20.0158399 },
    { lat: 48.5169413, lng: 20.013208 },
    { lat: 48.5191184, lng: 20.0117491 },
    { lat: 48.5225861, lng: 20.008102 },
    { lat: 48.5238323, lng: 20.0081088 },
    { lat: 48.524054, lng: 20.0080259 },
    { lat: 48.5245918, lng: 20.0080118 },
    { lat: 48.5247824, lng: 20.0080438 },
    { lat: 48.524819, lng: 20.008111 },
    { lat: 48.5249779, lng: 20.0081617 },
    { lat: 48.5250522, lng: 20.0080811 },
    { lat: 48.5252529, lng: 20.0081402 },
    { lat: 48.525294, lng: 20.0080222 },
    { lat: 48.5258265, lng: 20.0080939 },
    { lat: 48.5258319, lng: 20.0081965 },
    { lat: 48.5266125, lng: 20.0078754 },
    { lat: 48.5266838, lng: 20.0078245 },
    { lat: 48.5267388, lng: 20.0076608 },
    { lat: 48.5268613, lng: 20.0076279 },
    { lat: 48.5269093, lng: 20.0075103 },
    { lat: 48.5270461, lng: 20.0074117 },
    { lat: 48.5271841, lng: 20.0075048 },
    { lat: 48.5273424, lng: 20.007364 },
    { lat: 48.5274246, lng: 20.0072009 },
    { lat: 48.5276094, lng: 20.0071435 },
    { lat: 48.5277866, lng: 20.007238 },
    { lat: 48.5278697, lng: 20.0069856 },
    { lat: 48.5279591, lng: 20.0069717 },
    { lat: 48.5280689, lng: 20.0066575 },
    { lat: 48.5281953, lng: 20.006638 },
    { lat: 48.5282772, lng: 20.0064685 },
    { lat: 48.5283634, lng: 20.0064873 },
    { lat: 48.5286573, lng: 20.0063471 },
    { lat: 48.5289071, lng: 20.005908 },
    { lat: 48.5294124, lng: 20.005496 },
    { lat: 48.5294814, lng: 20.0053953 },
    { lat: 48.5295472, lng: 20.005136 },
    { lat: 48.5296777, lng: 20.0049708 },
    { lat: 48.5299092, lng: 20.0050373 },
    { lat: 48.5304007, lng: 20.0044596 },
    { lat: 48.5305628, lng: 20.0040807 },
    { lat: 48.5305623, lng: 20.003634 },
    { lat: 48.5306362, lng: 20.0033586 },
    { lat: 48.5305651, lng: 20.0031945 },
    { lat: 48.5306603, lng: 20.003045 },
    { lat: 48.5307555, lng: 20.0030577 },
    { lat: 48.5308207, lng: 20.0027618 },
    { lat: 48.5307862, lng: 20.0025295 },
    { lat: 48.5308542, lng: 20.0024054 },
    { lat: 48.5308947, lng: 20.0020262 },
    { lat: 48.5310587, lng: 20.001856 },
    { lat: 48.5311416, lng: 20.0018748 },
    { lat: 48.5312822, lng: 20.0016244 },
    { lat: 48.5316402, lng: 20.0013238 },
    { lat: 48.531879, lng: 20.0008678 },
    { lat: 48.5320946, lng: 20.0006092 },
    { lat: 48.5320946, lng: 20.000424 },
    { lat: 48.5322538, lng: 20.0002966 },
    { lat: 48.5322971, lng: 20.0001752 },
    { lat: 48.5324549, lng: 20.0001502 },
    { lat: 48.5325534, lng: 19.9998651 },
    { lat: 48.5326413, lng: 19.9998677 },
    { lat: 48.5327358, lng: 19.9994866 },
    { lat: 48.5328674, lng: 19.999322 },
    { lat: 48.5333353, lng: 19.9989288 },
    { lat: 48.5337849, lng: 19.9984275 },
    { lat: 48.5339914, lng: 19.9983531 },
    { lat: 48.5340265, lng: 19.9981426 },
    { lat: 48.5344637, lng: 19.9975249 },
    { lat: 48.5347322, lng: 19.9970287 },
    { lat: 48.5348909, lng: 19.9968675 },
    { lat: 48.5349738, lng: 19.9969353 },
    { lat: 48.5350432, lng: 19.996868 },
    { lat: 48.5352207, lng: 19.9968553 },
    { lat: 48.5352872, lng: 19.9967545 },
    { lat: 48.5355966, lng: 19.9967421 },
    { lat: 48.5357438, lng: 19.9966264 },
    { lat: 48.5360337, lng: 19.9967723 },
    { lat: 48.536236, lng: 19.996678 },
    { lat: 48.5364371, lng: 19.9967225 },
    { lat: 48.5368609, lng: 19.9965673 },
    { lat: 48.5369979, lng: 19.9963393 },
    { lat: 48.5372837, lng: 19.9962534 },
    { lat: 48.5376427, lng: 19.9964606 },
    { lat: 48.5378009, lng: 19.996309 },
    { lat: 48.5379252, lng: 19.9962915 },
    { lat: 48.5380855, lng: 19.9961668 },
    { lat: 48.538186, lng: 19.9962781 },
    { lat: 48.5383923, lng: 19.9962005 },
    { lat: 48.5384892, lng: 19.995962 },
    { lat: 48.5396886, lng: 19.9953654 },
    { lat: 48.5400578, lng: 19.9953843 },
    { lat: 48.5403023, lng: 19.9952807 },
    { lat: 48.5404934, lng: 19.9953517 },
    { lat: 48.5407441, lng: 19.9952848 },
    { lat: 48.5409802, lng: 19.995112 },
    { lat: 48.5416949, lng: 19.9950994 },
    { lat: 48.542042, lng: 19.9950247 },
    { lat: 48.5424938, lng: 19.9951314 },
    { lat: 48.5425442, lng: 19.9952581 },
    { lat: 48.5426911, lng: 19.9952985 },
    { lat: 48.544275, lng: 19.9947872 },
    { lat: 48.5453172, lng: 19.9946602 },
    { lat: 48.5460928, lng: 19.9944605 },
    { lat: 48.5462529, lng: 19.9943052 },
    { lat: 48.546343, lng: 19.9940346 },
    { lat: 48.5465088, lng: 19.99361 },
  ],
  Chrámec: [
    { lat: 48.2900826, lng: 20.1752085 },
    { lat: 48.2900624, lng: 20.1751287 },
    { lat: 48.2897026, lng: 20.1748651 },
    { lat: 48.2896362, lng: 20.17473 },
    { lat: 48.2896222, lng: 20.1744367 },
    { lat: 48.2898584, lng: 20.1741793 },
    { lat: 48.2893231, lng: 20.1731469 },
    { lat: 48.2885365, lng: 20.1723632 },
    { lat: 48.2884265, lng: 20.1723177 },
    { lat: 48.2879264, lng: 20.1725101 },
    { lat: 48.287755, lng: 20.172392 },
    { lat: 48.2875519, lng: 20.1720806 },
    { lat: 48.287228, lng: 20.1720628 },
    { lat: 48.2869951, lng: 20.1719284 },
    { lat: 48.2804457, lng: 20.1628064 },
    { lat: 48.2777526, lng: 20.1578021 },
    { lat: 48.2753915, lng: 20.1518589 },
    { lat: 48.2744481, lng: 20.1517608 },
    { lat: 48.2741187, lng: 20.1500716 },
    { lat: 48.2736977, lng: 20.1499928 },
    { lat: 48.2733576, lng: 20.1500494 },
    { lat: 48.272989, lng: 20.1497929 },
    { lat: 48.2725016, lng: 20.1496354 },
    { lat: 48.2723679, lng: 20.1492577 },
    { lat: 48.2724835, lng: 20.1491417 },
    { lat: 48.2724098, lng: 20.1480758 },
    { lat: 48.2723963, lng: 20.1479434 },
    { lat: 48.271726, lng: 20.1473073 },
    { lat: 48.2706166, lng: 20.1479121 },
    { lat: 48.2700328, lng: 20.1485101 },
    { lat: 48.2697492, lng: 20.1489579 },
    { lat: 48.2691788, lng: 20.1491375 },
    { lat: 48.2686928, lng: 20.1495126 },
    { lat: 48.268021, lng: 20.1491704 },
    { lat: 48.267585, lng: 20.1493882 },
    { lat: 48.2670802, lng: 20.1491068 },
    { lat: 48.2667788, lng: 20.1490312 },
    { lat: 48.2667405, lng: 20.149198 },
    { lat: 48.2665177, lng: 20.1492337 },
    { lat: 48.2664254, lng: 20.1493715 },
    { lat: 48.2659432, lng: 20.15174 },
    { lat: 48.2652041, lng: 20.1521468 },
    { lat: 48.2642195, lng: 20.1518138 },
    { lat: 48.2637886, lng: 20.1511361 },
    { lat: 48.2631904, lng: 20.1504959 },
    { lat: 48.262859, lng: 20.1504673 },
    { lat: 48.261237, lng: 20.1506286 },
    { lat: 48.2608694, lng: 20.1502056 },
    { lat: 48.2609093, lng: 20.1491426 },
    { lat: 48.2604035, lng: 20.1482593 },
    { lat: 48.2597024, lng: 20.1476156 },
    { lat: 48.2592504, lng: 20.146918 },
    { lat: 48.2589242, lng: 20.1466227 },
    { lat: 48.2587896, lng: 20.146558 },
    { lat: 48.2582366, lng: 20.1465589 },
    { lat: 48.2578147, lng: 20.1464558 },
    { lat: 48.257437, lng: 20.1458719 },
    { lat: 48.2570404, lng: 20.1454548 },
    { lat: 48.2568615, lng: 20.1453744 },
    { lat: 48.2562647, lng: 20.1440337 },
    { lat: 48.2558108, lng: 20.1435947 },
    { lat: 48.255443, lng: 20.143446 },
    { lat: 48.255379, lng: 20.144049 },
    { lat: 48.255185, lng: 20.144415 },
    { lat: 48.254642, lng: 20.144937 },
    { lat: 48.254655, lng: 20.145211 },
    { lat: 48.254728, lng: 20.145603 },
    { lat: 48.254924, lng: 20.146236 },
    { lat: 48.254982, lng: 20.146603 },
    { lat: 48.254981, lng: 20.146605 },
    { lat: 48.254869, lng: 20.146999 },
    { lat: 48.254647, lng: 20.147459 },
    { lat: 48.254464, lng: 20.147617 },
    { lat: 48.254295, lng: 20.147723 },
    { lat: 48.254186, lng: 20.147823 },
    { lat: 48.254032, lng: 20.147808 },
    { lat: 48.253932, lng: 20.147778 },
    { lat: 48.253769, lng: 20.147832 },
    { lat: 48.253701, lng: 20.147948 },
    { lat: 48.25365, lng: 20.148246 },
    { lat: 48.253514, lng: 20.149086 },
    { lat: 48.253395, lng: 20.149451 },
    { lat: 48.253424, lng: 20.149685 },
    { lat: 48.253441, lng: 20.14971 },
    { lat: 48.253269, lng: 20.149934 },
    { lat: 48.253081, lng: 20.150223 },
    { lat: 48.252971, lng: 20.150404 },
    { lat: 48.252792, lng: 20.150673 },
    { lat: 48.252775, lng: 20.150642 },
    { lat: 48.252549, lng: 20.150707 },
    { lat: 48.252413, lng: 20.150718 },
    { lat: 48.252335, lng: 20.150795 },
    { lat: 48.252076, lng: 20.151095 },
    { lat: 48.252096, lng: 20.151143 },
    { lat: 48.251986, lng: 20.151319 },
    { lat: 48.251833, lng: 20.151618 },
    { lat: 48.251784, lng: 20.151767 },
    { lat: 48.251649, lng: 20.15202 },
    { lat: 48.25152, lng: 20.152226 },
    { lat: 48.251269, lng: 20.152587 },
    { lat: 48.251221, lng: 20.152689 },
    { lat: 48.251114, lng: 20.152909 },
    { lat: 48.251061, lng: 20.152981 },
    { lat: 48.251023, lng: 20.153055 },
    { lat: 48.250997, lng: 20.153114 },
    { lat: 48.250985, lng: 20.153158 },
    { lat: 48.250934, lng: 20.15331 },
    { lat: 48.250884, lng: 20.15339 },
    { lat: 48.250833, lng: 20.153439 },
    { lat: 48.250741, lng: 20.153502 },
    { lat: 48.250546, lng: 20.153582 },
    { lat: 48.250483, lng: 20.153598 },
    { lat: 48.250422, lng: 20.153624 },
    { lat: 48.250293, lng: 20.153755 },
    { lat: 48.25026, lng: 20.153819 },
    { lat: 48.25025, lng: 20.15389 },
    { lat: 48.250246, lng: 20.153989 },
    { lat: 48.25026, lng: 20.154064 },
    { lat: 48.250335, lng: 20.154215 },
    { lat: 48.250394, lng: 20.154383 },
    { lat: 48.250436, lng: 20.154478 },
    { lat: 48.250501, lng: 20.154893 },
    { lat: 48.250683, lng: 20.155238 },
    { lat: 48.250865, lng: 20.155579 },
    { lat: 48.251103, lng: 20.155912 },
    { lat: 48.251191, lng: 20.156222 },
    { lat: 48.251234, lng: 20.156448 },
    { lat: 48.251139, lng: 20.156754 },
    { lat: 48.251027, lng: 20.157042 },
    { lat: 48.250945, lng: 20.15734 },
    { lat: 48.250822, lng: 20.157564 },
    { lat: 48.250752, lng: 20.157817 },
    { lat: 48.250769, lng: 20.158779 },
    { lat: 48.250569, lng: 20.159721 },
    { lat: 48.250717, lng: 20.160355 },
    { lat: 48.250803, lng: 20.161011 },
    { lat: 48.2508, lng: 20.161021 },
    { lat: 48.250689, lng: 20.161426 },
    { lat: 48.250693, lng: 20.161822 },
    { lat: 48.2508, lng: 20.16266 },
    { lat: 48.250995, lng: 20.163546 },
    { lat: 48.251177, lng: 20.164326 },
    { lat: 48.251182, lng: 20.164827 },
    { lat: 48.250848, lng: 20.165705 },
    { lat: 48.250926, lng: 20.165773 },
    { lat: 48.25108, lng: 20.165987 },
    { lat: 48.251277, lng: 20.166404 },
    { lat: 48.251467, lng: 20.166888 },
    { lat: 48.251559, lng: 20.167234 },
    { lat: 48.251599, lng: 20.167777 },
    { lat: 48.251604, lng: 20.168429 },
    { lat: 48.251534, lng: 20.168766 },
    { lat: 48.251468, lng: 20.169082 },
    { lat: 48.251339, lng: 20.169571 },
    { lat: 48.251315, lng: 20.170251 },
    { lat: 48.251205, lng: 20.170689 },
    { lat: 48.251078, lng: 20.170987 },
    { lat: 48.251063, lng: 20.171023 },
    { lat: 48.250853, lng: 20.171791 },
    { lat: 48.250797, lng: 20.172207 },
    { lat: 48.250779, lng: 20.172505 },
    { lat: 48.2511245, lng: 20.173284 },
    { lat: 48.2512145, lng: 20.1738303 },
    { lat: 48.2514633, lng: 20.174191 },
    { lat: 48.2522924, lng: 20.1750269 },
    { lat: 48.253508, lng: 20.1767765 },
    { lat: 48.2537593, lng: 20.176971 },
    { lat: 48.2541012, lng: 20.1770448 },
    { lat: 48.2546327, lng: 20.1786783 },
    { lat: 48.2551322, lng: 20.1805208 },
    { lat: 48.2556849, lng: 20.181176 },
    { lat: 48.2559156, lng: 20.1816438 },
    { lat: 48.2559878, lng: 20.181593 },
    { lat: 48.2560811, lng: 20.1818956 },
    { lat: 48.2563614, lng: 20.1823596 },
    { lat: 48.2567094, lng: 20.1827213 },
    { lat: 48.2568784, lng: 20.183083 },
    { lat: 48.2570121, lng: 20.1836848 },
    { lat: 48.2574119, lng: 20.1846858 },
    { lat: 48.2574837, lng: 20.1852857 },
    { lat: 48.2575579, lng: 20.1853394 },
    { lat: 48.2575157, lng: 20.1854928 },
    { lat: 48.2575552, lng: 20.1855228 },
    { lat: 48.2576061, lng: 20.1858362 },
    { lat: 48.2576665, lng: 20.1858233 },
    { lat: 48.2579931, lng: 20.1861319 },
    { lat: 48.2581305, lng: 20.1866025 },
    { lat: 48.258341, lng: 20.1870987 },
    { lat: 48.2583537, lng: 20.1872994 },
    { lat: 48.2584668, lng: 20.1873662 },
    { lat: 48.2585949, lng: 20.187579 },
    { lat: 48.258641, lng: 20.1877738 },
    { lat: 48.2588506, lng: 20.1879431 },
    { lat: 48.2588888, lng: 20.188081 },
    { lat: 48.2593936, lng: 20.1881387 },
    { lat: 48.259457, lng: 20.1882361 },
    { lat: 48.2594651, lng: 20.1884351 },
    { lat: 48.2595906, lng: 20.1888365 },
    { lat: 48.2599265, lng: 20.1888654 },
    { lat: 48.2599703, lng: 20.1887002 },
    { lat: 48.2600158, lng: 20.1888223 },
    { lat: 48.2602942, lng: 20.1890762 },
    { lat: 48.2604832, lng: 20.1891269 },
    { lat: 48.260748, lng: 20.1889855 },
    { lat: 48.2610314, lng: 20.1889795 },
    { lat: 48.2616097, lng: 20.1892942 },
    { lat: 48.2622988, lng: 20.1897929 },
    { lat: 48.2630564, lng: 20.1901716 },
    { lat: 48.2636954, lng: 20.1907342 },
    { lat: 48.2641014, lng: 20.1914454 },
    { lat: 48.2641758, lng: 20.1914761 },
    { lat: 48.2648625, lng: 20.1927138 },
    { lat: 48.2648448, lng: 20.1930594 },
    { lat: 48.2649297, lng: 20.19346 },
    { lat: 48.2649005, lng: 20.1937942 },
    { lat: 48.2650599, lng: 20.1942262 },
    { lat: 48.2651495, lng: 20.1946575 },
    { lat: 48.2651132, lng: 20.1948212 },
    { lat: 48.2651448, lng: 20.1951818 },
    { lat: 48.2651103, lng: 20.195274 },
    { lat: 48.265182, lng: 20.1955474 },
    { lat: 48.2651951, lng: 20.1957318 },
    { lat: 48.2651404, lng: 20.1958835 },
    { lat: 48.2651962, lng: 20.1962298 },
    { lat: 48.2651263, lng: 20.1965925 },
    { lat: 48.2651355, lng: 20.1967599 },
    { lat: 48.2650794, lng: 20.1968781 },
    { lat: 48.2651323, lng: 20.1978832 },
    { lat: 48.2652611, lng: 20.1982265 },
    { lat: 48.2655808, lng: 20.1987493 },
    { lat: 48.2656643, lng: 20.1991451 },
    { lat: 48.2657213, lng: 20.1992173 },
    { lat: 48.2658334, lng: 20.1990958 },
    { lat: 48.2661147, lng: 20.1989996 },
    { lat: 48.2665371, lng: 20.1992568 },
    { lat: 48.2671719, lng: 20.1989086 },
    { lat: 48.2675351, lng: 20.198892 },
    { lat: 48.2677133, lng: 20.1988153 },
    { lat: 48.2678564, lng: 20.1988762 },
    { lat: 48.2679316, lng: 20.1987849 },
    { lat: 48.2679796, lng: 20.1988584 },
    { lat: 48.2681528, lng: 20.1987925 },
    { lat: 48.2687819, lng: 20.1990051 },
    { lat: 48.2689292, lng: 20.1991116 },
    { lat: 48.2689839, lng: 20.1993499 },
    { lat: 48.2690636, lng: 20.1991496 },
    { lat: 48.2690349, lng: 20.1989638 },
    { lat: 48.2691458, lng: 20.1988116 },
    { lat: 48.2696966, lng: 20.1986464 },
    { lat: 48.270669, lng: 20.1984889 },
    { lat: 48.2717224, lng: 20.1982166 },
    { lat: 48.2726998, lng: 20.1983747 },
    { lat: 48.2733277, lng: 20.1980862 },
    { lat: 48.2734992, lng: 20.198066 },
    { lat: 48.2738803, lng: 20.1982393 },
    { lat: 48.2746914, lng: 20.1989146 },
    { lat: 48.2752316, lng: 20.1990713 },
    { lat: 48.2757147, lng: 20.199071 },
    { lat: 48.276045, lng: 20.1989908 },
    { lat: 48.27608, lng: 20.1990527 },
    { lat: 48.2786307, lng: 20.1987623 },
    { lat: 48.2811102, lng: 20.1986898 },
    { lat: 48.2825242, lng: 20.1984777 },
    { lat: 48.2827064, lng: 20.1985015 },
    { lat: 48.2826939, lng: 20.1988354 },
    { lat: 48.2835053, lng: 20.1985962 },
    { lat: 48.2837997, lng: 20.1991647 },
    { lat: 48.283939, lng: 20.1989547 },
    { lat: 48.2840339, lng: 20.198968 },
    { lat: 48.2841501, lng: 20.1987754 },
    { lat: 48.2844386, lng: 20.1987779 },
    { lat: 48.2845146, lng: 20.1990418 },
    { lat: 48.2849336, lng: 20.1996266 },
    { lat: 48.2852691, lng: 20.1998762 },
    { lat: 48.2853653, lng: 20.2001122 },
    { lat: 48.2856009, lng: 20.2003642 },
    { lat: 48.2858132, lng: 20.2004903 },
    { lat: 48.2862353, lng: 20.2002604 },
    { lat: 48.2867758, lng: 20.1996979 },
    { lat: 48.2869285, lng: 20.199185 },
    { lat: 48.2871514, lng: 20.198988 },
    { lat: 48.2875097, lng: 20.1990775 },
    { lat: 48.2879528, lng: 20.1995589 },
    { lat: 48.2880267, lng: 20.1995543 },
    { lat: 48.2880934, lng: 20.1995278 },
    { lat: 48.2883821, lng: 20.1996522 },
    { lat: 48.2885684, lng: 20.2000208 },
    { lat: 48.2888986, lng: 20.2003611 },
    { lat: 48.2891099, lng: 20.2002681 },
    { lat: 48.2891769, lng: 20.2000862 },
    { lat: 48.2890617, lng: 20.1998516 },
    { lat: 48.2890466, lng: 20.1996489 },
    { lat: 48.2892125, lng: 20.1993198 },
    { lat: 48.2892456, lng: 20.199025 },
    { lat: 48.2893804, lng: 20.1988512 },
    { lat: 48.2898104, lng: 20.1985922 },
    { lat: 48.2900059, lng: 20.1986116 },
    { lat: 48.2900675, lng: 20.1985487 },
    { lat: 48.2902379, lng: 20.1980816 },
    { lat: 48.2904227, lng: 20.1979414 },
    { lat: 48.2905939, lng: 20.1986381 },
    { lat: 48.2906873, lng: 20.1988571 },
    { lat: 48.2907837, lng: 20.1989063 },
    { lat: 48.291063, lng: 20.1986221 },
    { lat: 48.2910828, lng: 20.1984767 },
    { lat: 48.2910459, lng: 20.1982597 },
    { lat: 48.2909722, lng: 20.1981341 },
    { lat: 48.2908406, lng: 20.1981362 },
    { lat: 48.2907784, lng: 20.1980233 },
    { lat: 48.2907831, lng: 20.1976898 },
    { lat: 48.2910655, lng: 20.1969564 },
    { lat: 48.2913258, lng: 20.1966007 },
    { lat: 48.2914326, lng: 20.1962592 },
    { lat: 48.2917744, lng: 20.196248 },
    { lat: 48.291806, lng: 20.1961956 },
    { lat: 48.2917871, lng: 20.1958918 },
    { lat: 48.2918697, lng: 20.1957623 },
    { lat: 48.2922831, lng: 20.1957081 },
    { lat: 48.2925812, lng: 20.1954214 },
    { lat: 48.2929749, lng: 20.1951823 },
    { lat: 48.2930826, lng: 20.1949808 },
    { lat: 48.2937342, lng: 20.1896495 },
    { lat: 48.293389, lng: 20.1898333 },
    { lat: 48.2932925, lng: 20.1898159 },
    { lat: 48.2931699, lng: 20.1894585 },
    { lat: 48.2932238, lng: 20.1892032 },
    { lat: 48.2933017, lng: 20.1892035 },
    { lat: 48.2934129, lng: 20.1894774 },
    { lat: 48.2936472, lng: 20.1893659 },
    { lat: 48.2936967, lng: 20.1891722 },
    { lat: 48.2936573, lng: 20.1890548 },
    { lat: 48.2933071, lng: 20.1887018 },
    { lat: 48.2929562, lng: 20.1885176 },
    { lat: 48.2928461, lng: 20.1886062 },
    { lat: 48.2928115, lng: 20.1891968 },
    { lat: 48.2926851, lng: 20.1893572 },
    { lat: 48.2925711, lng: 20.1893814 },
    { lat: 48.2925021, lng: 20.1893218 },
    { lat: 48.2924429, lng: 20.1891051 },
    { lat: 48.2926119, lng: 20.1888613 },
    { lat: 48.2926335, lng: 20.1887259 },
    { lat: 48.2925261, lng: 20.1881889 },
    { lat: 48.2924081, lng: 20.1880553 },
    { lat: 48.2923381, lng: 20.1881042 },
    { lat: 48.2923181, lng: 20.188199 },
    { lat: 48.2923364, lng: 20.1885623 },
    { lat: 48.2921082, lng: 20.1887964 },
    { lat: 48.2920212, lng: 20.1887926 },
    { lat: 48.291987, lng: 20.1887239 },
    { lat: 48.2920168, lng: 20.1884853 },
    { lat: 48.2918263, lng: 20.1882642 },
    { lat: 48.2918037, lng: 20.1881489 },
    { lat: 48.2919012, lng: 20.1878054 },
    { lat: 48.2919069, lng: 20.1874158 },
    { lat: 48.291962, lng: 20.187222 },
    { lat: 48.2920764, lng: 20.1866474 },
    { lat: 48.2919867, lng: 20.1865201 },
    { lat: 48.291894, lng: 20.1865158 },
    { lat: 48.2915622, lng: 20.1870242 },
    { lat: 48.2915127, lng: 20.1870252 },
    { lat: 48.2913265, lng: 20.186797 },
    { lat: 48.2909356, lng: 20.1867675 },
    { lat: 48.2909025, lng: 20.1866449 },
    { lat: 48.2909196, lng: 20.1863951 },
    { lat: 48.291158, lng: 20.1863034 },
    { lat: 48.2911721, lng: 20.1861794 },
    { lat: 48.2911451, lng: 20.1859332 },
    { lat: 48.2909116, lng: 20.185564 },
    { lat: 48.290926, lng: 20.1853887 },
    { lat: 48.2910915, lng: 20.1851936 },
    { lat: 48.2912232, lng: 20.1849138 },
    { lat: 48.2914182, lng: 20.1848614 },
    { lat: 48.2915693, lng: 20.1846408 },
    { lat: 48.2916721, lng: 20.1845932 },
    { lat: 48.2919877, lng: 20.1850993 },
    { lat: 48.2922024, lng: 20.1858434 },
    { lat: 48.2923493, lng: 20.1860503 },
    { lat: 48.2924654, lng: 20.186049 },
    { lat: 48.2924971, lng: 20.1857193 },
    { lat: 48.2924006, lng: 20.1854515 },
    { lat: 48.2924027, lng: 20.1853349 },
    { lat: 48.2925869, lng: 20.1851289 },
    { lat: 48.2926061, lng: 20.1849606 },
    { lat: 48.2925194, lng: 20.1846862 },
    { lat: 48.2922295, lng: 20.184429 },
    { lat: 48.2923808, lng: 20.1839628 },
    { lat: 48.2924979, lng: 20.1831316 },
    { lat: 48.292555, lng: 20.1829786 },
    { lat: 48.2926583, lng: 20.1828906 },
    { lat: 48.292807, lng: 20.1829493 },
    { lat: 48.2929347, lng: 20.183095 },
    { lat: 48.2930465, lng: 20.1836285 },
    { lat: 48.2931257, lng: 20.1836655 },
    { lat: 48.293199, lng: 20.1836121 },
    { lat: 48.2933074, lng: 20.1833692 },
    { lat: 48.2934808, lng: 20.1833658 },
    { lat: 48.2935818, lng: 20.18297 },
    { lat: 48.2933788, lng: 20.1826616 },
    { lat: 48.2925544, lng: 20.1822433 },
    { lat: 48.2924225, lng: 20.1819062 },
    { lat: 48.2924602, lng: 20.1814218 },
    { lat: 48.292713, lng: 20.1812414 },
    { lat: 48.2928478, lng: 20.1808421 },
    { lat: 48.2926779, lng: 20.1801789 },
    { lat: 48.2926455, lng: 20.1798801 },
    { lat: 48.2928979, lng: 20.1794419 },
    { lat: 48.2927864, lng: 20.1791882 },
    { lat: 48.2924824, lng: 20.1789142 },
    { lat: 48.2923815, lng: 20.1789828 },
    { lat: 48.2923329, lng: 20.179226 },
    { lat: 48.292102, lng: 20.1795332 },
    { lat: 48.2918462, lng: 20.1791667 },
    { lat: 48.2917055, lng: 20.1791735 },
    { lat: 48.2914326, lng: 20.1793271 },
    { lat: 48.2911238, lng: 20.1792672 },
    { lat: 48.2910192, lng: 20.1791041 },
    { lat: 48.2909491, lng: 20.1786861 },
    { lat: 48.2911211, lng: 20.1784816 },
    { lat: 48.2908852, lng: 20.1780252 },
    { lat: 48.2909188, lng: 20.1775776 },
    { lat: 48.2908833, lng: 20.1775055 },
    { lat: 48.2907823, lng: 20.1774316 },
    { lat: 48.2905676, lng: 20.1774678 },
    { lat: 48.2904094, lng: 20.1776226 },
    { lat: 48.2903216, lng: 20.1776225 },
    { lat: 48.2900057, lng: 20.1773064 },
    { lat: 48.2898826, lng: 20.1769944 },
    { lat: 48.2898604, lng: 20.1767789 },
    { lat: 48.2900097, lng: 20.176545 },
    { lat: 48.2904653, lng: 20.1760585 },
    { lat: 48.290494, lng: 20.1758525 },
    { lat: 48.2904047, lng: 20.1754571 },
    { lat: 48.290359, lng: 20.1754107 },
    { lat: 48.290224, lng: 20.1755281 },
    { lat: 48.2900997, lng: 20.1754075 },
    { lat: 48.2900826, lng: 20.1752085 },
  ],
  Večelkov: [
    { lat: 48.1600698, lng: 19.9435602 },
    { lat: 48.160088, lng: 19.9435885 },
    { lat: 48.161001, lng: 19.9445728 },
    { lat: 48.1623915, lng: 19.945349 },
    { lat: 48.1629418, lng: 19.9456246 },
    { lat: 48.1632796, lng: 19.945725 },
    { lat: 48.1644468, lng: 19.9458309 },
    { lat: 48.1646636, lng: 19.9461087 },
    { lat: 48.1648279, lng: 19.9461305 },
    { lat: 48.1656785, lng: 19.9457077 },
    { lat: 48.1660427, lng: 19.9456122 },
    { lat: 48.1662529, lng: 19.9453663 },
    { lat: 48.1658282, lng: 19.9442543 },
    { lat: 48.1657664, lng: 19.9439691 },
    { lat: 48.1658898, lng: 19.9404564 },
    { lat: 48.1663539, lng: 19.9382443 },
    { lat: 48.1664773, lng: 19.9367328 },
    { lat: 48.1667408, lng: 19.935939 },
    { lat: 48.166793, lng: 19.9356312 },
    { lat: 48.1667561, lng: 19.9352448 },
    { lat: 48.1667905, lng: 19.9352588 },
    { lat: 48.1669043, lng: 19.9349953 },
    { lat: 48.1669987, lng: 19.9349898 },
    { lat: 48.1670179, lng: 19.9345937 },
    { lat: 48.1671506, lng: 19.9345394 },
    { lat: 48.1671998, lng: 19.9343706 },
    { lat: 48.1670827, lng: 19.9342929 },
    { lat: 48.1670671, lng: 19.9341398 },
    { lat: 48.1673375, lng: 19.9339315 },
    { lat: 48.1673397, lng: 19.9336687 },
    { lat: 48.1672389, lng: 19.9334705 },
    { lat: 48.1672519, lng: 19.9332498 },
    { lat: 48.167543, lng: 19.9332569 },
    { lat: 48.167593, lng: 19.9331885 },
    { lat: 48.1675118, lng: 19.9327665 },
    { lat: 48.1676908, lng: 19.9324208 },
    { lat: 48.1676393, lng: 19.9321371 },
    { lat: 48.1678498, lng: 19.9317679 },
    { lat: 48.1679216, lng: 19.9314494 },
    { lat: 48.1679608, lng: 19.9308648 },
    { lat: 48.1681569, lng: 19.9305221 },
    { lat: 48.1680016, lng: 19.9300545 },
    { lat: 48.1680161, lng: 19.9297606 },
    { lat: 48.1681586, lng: 19.9299552 },
    { lat: 48.1682752, lng: 19.9298949 },
    { lat: 48.1683014, lng: 19.9297672 },
    { lat: 48.1682066, lng: 19.9289233 },
    { lat: 48.1683227, lng: 19.9285861 },
    { lat: 48.1681523, lng: 19.9281849 },
    { lat: 48.1681292, lng: 19.9280093 },
    { lat: 48.1683003, lng: 19.9276947 },
    { lat: 48.1682448, lng: 19.9272642 },
    { lat: 48.1682459, lng: 19.9266359 },
    { lat: 48.1683791, lng: 19.9255366 },
    { lat: 48.1682351, lng: 19.9250196 },
    { lat: 48.1685926, lng: 19.9243448 },
    { lat: 48.1685711, lng: 19.9240039 },
    { lat: 48.1683485, lng: 19.9240637 },
    { lat: 48.1682153, lng: 19.9237158 },
    { lat: 48.1683807, lng: 19.9235002 },
    { lat: 48.1684119, lng: 19.9233356 },
    { lat: 48.1681558, lng: 19.9226445 },
    { lat: 48.1684624, lng: 19.922572 },
    { lat: 48.1684896, lng: 19.9224946 },
    { lat: 48.1681687, lng: 19.9220532 },
    { lat: 48.1683131, lng: 19.9219264 },
    { lat: 48.1684921, lng: 19.9219001 },
    { lat: 48.1685074, lng: 19.9218107 },
    { lat: 48.1681991, lng: 19.9214253 },
    { lat: 48.1681392, lng: 19.921208 },
    { lat: 48.1683139, lng: 19.921132 },
    { lat: 48.1684106, lng: 19.9208922 },
    { lat: 48.168081, lng: 19.9205113 },
    { lat: 48.1680214, lng: 19.9202517 },
    { lat: 48.1683014, lng: 19.9202636 },
    { lat: 48.1683885, lng: 19.9200915 },
    { lat: 48.1683961, lng: 19.9195597 },
    { lat: 48.1682607, lng: 19.9191884 },
    { lat: 48.16825, lng: 19.9187543 },
    { lat: 48.1680914, lng: 19.9181823 },
    { lat: 48.1681378, lng: 19.9181087 },
    { lat: 48.1684022, lng: 19.9181044 },
    { lat: 48.1684005, lng: 19.9175918 },
    { lat: 48.1681595, lng: 19.9173845 },
    { lat: 48.1681421, lng: 19.9172161 },
    { lat: 48.168345, lng: 19.9170862 },
    { lat: 48.1685701, lng: 19.9170554 },
    { lat: 48.168619, lng: 19.9168809 },
    { lat: 48.1685681, lng: 19.9166698 },
    { lat: 48.1682826, lng: 19.9166563 },
    { lat: 48.1682763, lng: 19.9161685 },
    { lat: 48.1681217, lng: 19.9153523 },
    { lat: 48.1681167, lng: 19.9149723 },
    { lat: 48.1682001, lng: 19.9146598 },
    { lat: 48.1685226, lng: 19.9144033 },
    { lat: 48.1684791, lng: 19.9123575 },
    { lat: 48.1684599, lng: 19.9114538 },
    { lat: 48.1685222, lng: 19.911331 },
    { lat: 48.1685057, lng: 19.9100819 },
    { lat: 48.1684699, lng: 19.9098524 },
    { lat: 48.1685302, lng: 19.9089791 },
    { lat: 48.1668139, lng: 19.9013835 },
    { lat: 48.166312, lng: 19.898745 },
    { lat: 48.165961, lng: 19.898904 },
    { lat: 48.16569, lng: 19.898998 },
    { lat: 48.165305, lng: 19.899157 },
    { lat: 48.164745, lng: 19.89938 },
    { lat: 48.164523, lng: 19.899468 },
    { lat: 48.16375, lng: 19.899778 },
    { lat: 48.163447, lng: 19.8999 },
    { lat: 48.163255, lng: 19.899969 },
    { lat: 48.162856, lng: 19.900143 },
    { lat: 48.162537, lng: 19.900065 },
    { lat: 48.162354, lng: 19.90001 },
    { lat: 48.162097, lng: 19.899943 },
    { lat: 48.162041, lng: 19.899994 },
    { lat: 48.161698, lng: 19.900317 },
    { lat: 48.161128, lng: 19.900553 },
    { lat: 48.160957, lng: 19.900764 },
    { lat: 48.160887, lng: 19.900842 },
    { lat: 48.160538, lng: 19.901112 },
    { lat: 48.160234, lng: 19.901354 },
    { lat: 48.16009, lng: 19.901634 },
    { lat: 48.159607, lng: 19.90188 },
    { lat: 48.159447, lng: 19.901961 },
    { lat: 48.159356, lng: 19.902008 },
    { lat: 48.159024, lng: 19.902168 },
    { lat: 48.15881, lng: 19.902277 },
    { lat: 48.158492, lng: 19.902413 },
    { lat: 48.157883, lng: 19.902706 },
    { lat: 48.157829, lng: 19.902732 },
    { lat: 48.157483, lng: 19.902896 },
    { lat: 48.157187, lng: 19.90304 },
    { lat: 48.15674, lng: 19.903284 },
    { lat: 48.156374, lng: 19.903442 },
    { lat: 48.156055, lng: 19.903575 },
    { lat: 48.155674, lng: 19.90378 },
    { lat: 48.154978, lng: 19.904117 },
    { lat: 48.15453, lng: 19.904328 },
    { lat: 48.154161, lng: 19.904483 },
    { lat: 48.15384, lng: 19.904629 },
    { lat: 48.153242, lng: 19.904949 },
    { lat: 48.152755, lng: 19.905217 },
    { lat: 48.15265, lng: 19.905262 },
    { lat: 48.1523777, lng: 19.9053829 },
    { lat: 48.152297, lng: 19.905424 },
    { lat: 48.152254, lng: 19.905448 },
    { lat: 48.152141, lng: 19.905513 },
    { lat: 48.152028, lng: 19.90558 },
    { lat: 48.15173, lng: 19.905676 },
    { lat: 48.151304, lng: 19.90591 },
    { lat: 48.151052, lng: 19.906219 },
    { lat: 48.150808, lng: 19.906543 },
    { lat: 48.150412, lng: 19.906701 },
    { lat: 48.150192, lng: 19.906912 },
    { lat: 48.150052, lng: 19.907563 },
    { lat: 48.149892, lng: 19.908219 },
    { lat: 48.149606, lng: 19.908751 },
    { lat: 48.149507, lng: 19.909113 },
    { lat: 48.149337, lng: 19.909805 },
    { lat: 48.149185, lng: 19.910169 },
    { lat: 48.149045, lng: 19.910441 },
    { lat: 48.149008, lng: 19.910769 },
    { lat: 48.148924, lng: 19.910849 },
    { lat: 48.148783, lng: 19.911297 },
    { lat: 48.14856, lng: 19.911647 },
    { lat: 48.148401, lng: 19.912 },
    { lat: 48.148284, lng: 19.912447 },
    { lat: 48.147963, lng: 19.912964 },
    { lat: 48.147772, lng: 19.91341 },
    { lat: 48.147563, lng: 19.913731 },
    { lat: 48.147352, lng: 19.914018 },
    { lat: 48.146863, lng: 19.914536 },
    { lat: 48.146833, lng: 19.914485 },
    { lat: 48.146242, lng: 19.913505 },
    { lat: 48.145863, lng: 19.912883 },
    { lat: 48.145521, lng: 19.912603 },
    { lat: 48.14515, lng: 19.912295 },
    { lat: 48.144581, lng: 19.911825 },
    { lat: 48.144085, lng: 19.911418 },
    { lat: 48.1442296, lng: 19.9121113 },
    { lat: 48.145568, lng: 19.9140494 },
    { lat: 48.1459643, lng: 19.9150628 },
    { lat: 48.1464062, lng: 19.9158808 },
    { lat: 48.1465243, lng: 19.9169234 },
    { lat: 48.1471421, lng: 19.9175618 },
    { lat: 48.1473374, lng: 19.9187478 },
    { lat: 48.1475635, lng: 19.9194238 },
    { lat: 48.1481034, lng: 19.9200115 },
    { lat: 48.1483272, lng: 19.9197915 },
    { lat: 48.1484682, lng: 19.9200277 },
    { lat: 48.1485377, lng: 19.9205202 },
    { lat: 48.1490709, lng: 19.9214963 },
    { lat: 48.1491969, lng: 19.923539 },
    { lat: 48.1499359, lng: 19.9252952 },
    { lat: 48.1509718, lng: 19.9274349 },
    { lat: 48.151851, lng: 19.9274481 },
    { lat: 48.1540481, lng: 19.9278496 },
    { lat: 48.1566168, lng: 19.9321326 },
    { lat: 48.1565878, lng: 19.9321678 },
    { lat: 48.1590198, lng: 19.9365538 },
    { lat: 48.1600794, lng: 19.9434411 },
    { lat: 48.1600698, lng: 19.9435602 },
  ],
  Tachty: [
    { lat: 48.144085, lng: 19.911418 },
    { lat: 48.143909, lng: 19.911126 },
    { lat: 48.143224, lng: 19.910417 },
    { lat: 48.142408, lng: 19.909964 },
    { lat: 48.141867, lng: 19.909629 },
    { lat: 48.141607, lng: 19.909731 },
    { lat: 48.141608, lng: 19.909759 },
    { lat: 48.141468, lng: 19.909864 },
    { lat: 48.141294, lng: 19.909815 },
    { lat: 48.14121, lng: 19.909714 },
    { lat: 48.141155, lng: 19.909834 },
    { lat: 48.141036, lng: 19.909882 },
    { lat: 48.140826, lng: 19.909865 },
    { lat: 48.140739, lng: 19.90977 },
    { lat: 48.140653, lng: 19.909757 },
    { lat: 48.140573, lng: 19.90977 },
    { lat: 48.140504, lng: 19.90984 },
    { lat: 48.140398, lng: 19.909901 },
    { lat: 48.140338, lng: 19.909728 },
    { lat: 48.140238, lng: 19.90967 },
    { lat: 48.140158, lng: 19.909766 },
    { lat: 48.140106, lng: 19.90975 },
    { lat: 48.140105, lng: 19.90967 },
    { lat: 48.140053, lng: 19.909658 },
    { lat: 48.13981, lng: 19.910093 },
    { lat: 48.139788, lng: 19.910075 },
    { lat: 48.13977, lng: 19.910099 },
    { lat: 48.139788, lng: 19.910182 },
    { lat: 48.139766, lng: 19.91027 },
    { lat: 48.139715, lng: 19.910273 },
    { lat: 48.139621, lng: 19.910151 },
    { lat: 48.13957, lng: 19.909967 },
    { lat: 48.139599, lng: 19.909717 },
    { lat: 48.139678, lng: 19.909691 },
    { lat: 48.139617, lng: 19.909623 },
    { lat: 48.139536, lng: 19.909611 },
    { lat: 48.13947, lng: 19.909714 },
    { lat: 48.139409, lng: 19.909689 },
    { lat: 48.139356, lng: 19.909599 },
    { lat: 48.139392, lng: 19.909342 },
    { lat: 48.139436, lng: 19.909002 },
    { lat: 48.139076, lng: 19.909085 },
    { lat: 48.139016, lng: 19.909048 },
    { lat: 48.138929, lng: 19.90886 },
    { lat: 48.13886, lng: 19.908768 },
    { lat: 48.139018, lng: 19.908433 },
    { lat: 48.138971, lng: 19.908353 },
    { lat: 48.138907, lng: 19.908293 },
    { lat: 48.138825, lng: 19.908437 },
    { lat: 48.138776, lng: 19.908471 },
    { lat: 48.138746, lng: 19.908414 },
    { lat: 48.138755, lng: 19.908283 },
    { lat: 48.138727, lng: 19.908161 },
    { lat: 48.138687, lng: 19.90822 },
    { lat: 48.138685, lng: 19.908282 },
    { lat: 48.138658, lng: 19.908333 },
    { lat: 48.138619, lng: 19.908269 },
    { lat: 48.138618, lng: 19.908083 },
    { lat: 48.138698, lng: 19.908002 },
    { lat: 48.13865, lng: 19.907902 },
    { lat: 48.138579, lng: 19.907859 },
    { lat: 48.138476, lng: 19.907968 },
    { lat: 48.138428, lng: 19.907985 },
    { lat: 48.138378, lng: 19.907924 },
    { lat: 48.13835, lng: 19.907711 },
    { lat: 48.138259, lng: 19.907709 },
    { lat: 48.138188, lng: 19.9076 },
    { lat: 48.13818, lng: 19.907187 },
    { lat: 48.138298, lng: 19.907036 },
    { lat: 48.138257, lng: 19.906939 },
    { lat: 48.138203, lng: 19.906898 },
    { lat: 48.138192, lng: 19.906961 },
    { lat: 48.138123, lng: 19.907026 },
    { lat: 48.138059, lng: 19.907029 },
    { lat: 48.138045, lng: 19.906971 },
    { lat: 48.138138, lng: 19.90685 },
    { lat: 48.138099, lng: 19.906657 },
    { lat: 48.138062, lng: 19.906635 },
    { lat: 48.138031, lng: 19.906584 },
    { lat: 48.137968, lng: 19.906693 },
    { lat: 48.1379, lng: 19.906644 },
    { lat: 48.137875, lng: 19.90658 },
    { lat: 48.138053, lng: 19.906444 },
    { lat: 48.137973, lng: 19.906241 },
    { lat: 48.137938, lng: 19.906217 },
    { lat: 48.137942, lng: 19.906091 },
    { lat: 48.137844, lng: 19.906126 },
    { lat: 48.13782, lng: 19.90611 },
    { lat: 48.137861, lng: 19.906017 },
    { lat: 48.137872, lng: 19.905841 },
    { lat: 48.137835, lng: 19.905826 },
    { lat: 48.137833, lng: 19.905762 },
    { lat: 48.137762, lng: 19.905605 },
    { lat: 48.137747, lng: 19.905444 },
    { lat: 48.137774, lng: 19.90513 },
    { lat: 48.137859, lng: 19.90509 },
    { lat: 48.137919, lng: 19.905006 },
    { lat: 48.137907, lng: 19.9049 },
    { lat: 48.137937, lng: 19.904868 },
    { lat: 48.137922, lng: 19.9046 },
    { lat: 48.137845, lng: 19.904566 },
    { lat: 48.137774, lng: 19.904677 },
    { lat: 48.137684, lng: 19.904624 },
    { lat: 48.137673, lng: 19.904592 },
    { lat: 48.137791, lng: 19.904197 },
    { lat: 48.137645, lng: 19.904075 },
    { lat: 48.13757, lng: 19.903971 },
    { lat: 48.137572, lng: 19.90393 },
    { lat: 48.137667, lng: 19.90383 },
    { lat: 48.137632, lng: 19.903693 },
    { lat: 48.137364, lng: 19.903389 },
    { lat: 48.137323, lng: 19.90327 },
    { lat: 48.137276, lng: 19.903226 },
    { lat: 48.137198, lng: 19.902968 },
    { lat: 48.137142, lng: 19.902771 },
    { lat: 48.137047, lng: 19.90274 },
    { lat: 48.136947, lng: 19.902759 },
    { lat: 48.136907, lng: 19.902752 },
    { lat: 48.136823, lng: 19.90267 },
    { lat: 48.136813, lng: 19.902544 },
    { lat: 48.136775, lng: 19.902455 },
    { lat: 48.136677, lng: 19.902334 },
    { lat: 48.136575, lng: 19.902104 },
    { lat: 48.136365, lng: 19.902118 },
    { lat: 48.136212, lng: 19.901994 },
    { lat: 48.136158, lng: 19.902028 },
    { lat: 48.136021, lng: 19.902006 },
    { lat: 48.135721, lng: 19.901855 },
    { lat: 48.135466, lng: 19.901514 },
    { lat: 48.135371, lng: 19.90165 },
    { lat: 48.135295, lng: 19.901693 },
    { lat: 48.135197, lng: 19.901616 },
    { lat: 48.135141, lng: 19.901489 },
    { lat: 48.135087, lng: 19.901464 },
    { lat: 48.135009, lng: 19.9015 },
    { lat: 48.134912, lng: 19.901446 },
    { lat: 48.134839, lng: 19.901409 },
    { lat: 48.13477, lng: 19.901476 },
    { lat: 48.134702, lng: 19.901666 },
    { lat: 48.134564, lng: 19.901789 },
    { lat: 48.134482, lng: 19.901777 },
    { lat: 48.134447, lng: 19.901723 },
    { lat: 48.134471, lng: 19.901642 },
    { lat: 48.134439, lng: 19.901567 },
    { lat: 48.134282, lng: 19.901498 },
    { lat: 48.134217, lng: 19.901462 },
    { lat: 48.134148, lng: 19.901568 },
    { lat: 48.134017, lng: 19.901674 },
    { lat: 48.133989, lng: 19.901571 },
    { lat: 48.133869, lng: 19.901538 },
    { lat: 48.133828, lng: 19.90158 },
    { lat: 48.133743, lng: 19.901414 },
    { lat: 48.133654, lng: 19.901486 },
    { lat: 48.133614, lng: 19.9016 },
    { lat: 48.133503, lng: 19.901706 },
    { lat: 48.133402, lng: 19.901601 },
    { lat: 48.133323, lng: 19.901436 },
    { lat: 48.133273, lng: 19.901425 },
    { lat: 48.13324, lng: 19.901322 },
    { lat: 48.133158, lng: 19.901375 },
    { lat: 48.133235, lng: 19.901573 },
    { lat: 48.133175, lng: 19.901706 },
    { lat: 48.133062, lng: 19.901616 },
    { lat: 48.13304, lng: 19.90148 },
    { lat: 48.132919, lng: 19.90145 },
    { lat: 48.13284, lng: 19.901534 },
    { lat: 48.132782, lng: 19.901494 },
    { lat: 48.132695, lng: 19.90162 },
    { lat: 48.132616, lng: 19.901571 },
    { lat: 48.13266, lng: 19.901329 },
    { lat: 48.132603, lng: 19.901302 },
    { lat: 48.132542, lng: 19.901327 },
    { lat: 48.13233, lng: 19.901466 },
    { lat: 48.132201, lng: 19.901597 },
    { lat: 48.132046, lng: 19.901586 },
    { lat: 48.132017, lng: 19.901453 },
    { lat: 48.132056, lng: 19.901307 },
    { lat: 48.132027, lng: 19.901221 },
    { lat: 48.131913, lng: 19.901216 },
    { lat: 48.131861, lng: 19.901144 },
    { lat: 48.131736, lng: 19.90119 },
    { lat: 48.131704, lng: 19.901147 },
    { lat: 48.131755, lng: 19.901005 },
    { lat: 48.131749, lng: 19.900783 },
    { lat: 48.131702, lng: 19.900732 },
    { lat: 48.13166, lng: 19.900792 },
    { lat: 48.131618, lng: 19.900763 },
    { lat: 48.131585, lng: 19.900631 },
    { lat: 48.131657, lng: 19.900481 },
    { lat: 48.131582, lng: 19.900398 },
    { lat: 48.131518, lng: 19.900396 },
    { lat: 48.131343, lng: 19.90031 },
    { lat: 48.131185, lng: 19.900102 },
    { lat: 48.131125, lng: 19.900122 },
    { lat: 48.131062, lng: 19.900212 },
    { lat: 48.130973, lng: 19.900233 },
    { lat: 48.130934, lng: 19.900186 },
    { lat: 48.130926, lng: 19.900074 },
    { lat: 48.130842, lng: 19.899997 },
    { lat: 48.130785, lng: 19.900116 },
    { lat: 48.130658, lng: 19.900093 },
    { lat: 48.130542, lng: 19.900015 },
    { lat: 48.130541, lng: 19.899989 },
    { lat: 48.130469, lng: 19.899853 },
    { lat: 48.130337, lng: 19.899603 },
    { lat: 48.12989, lng: 19.898754 },
    { lat: 48.129759, lng: 19.898506 },
    { lat: 48.129471, lng: 19.897958 },
    { lat: 48.12867, lng: 19.898017 },
    { lat: 48.12783, lng: 19.89808 },
    { lat: 48.12685, lng: 19.898154 },
    { lat: 48.125904, lng: 19.898225 },
    { lat: 48.125289, lng: 19.898271 },
    { lat: 48.124902, lng: 19.898298 },
    { lat: 48.124871, lng: 19.898593 },
    { lat: 48.124793, lng: 19.899324 },
    { lat: 48.124754, lng: 19.899317 },
    { lat: 48.124694, lng: 19.89966 },
    { lat: 48.124588, lng: 19.89994 },
    { lat: 48.124439, lng: 19.900179 },
    { lat: 48.124308, lng: 19.900299 },
    { lat: 48.124205, lng: 19.900332 },
    { lat: 48.124127, lng: 19.900433 },
    { lat: 48.124145, lng: 19.900476 },
    { lat: 48.124306, lng: 19.900672 },
    { lat: 48.124468, lng: 19.900938 },
    { lat: 48.124772, lng: 19.901301 },
    { lat: 48.124983, lng: 19.901905 },
    { lat: 48.125149, lng: 19.902356 },
    { lat: 48.12527, lng: 19.902652 },
    { lat: 48.125357, lng: 19.902802 },
    { lat: 48.12557, lng: 19.903088 },
    { lat: 48.125614, lng: 19.903355 },
    { lat: 48.125679, lng: 19.903586 },
    { lat: 48.125944, lng: 19.904092 },
    { lat: 48.126073, lng: 19.904478 },
    { lat: 48.126203, lng: 19.90491 },
    { lat: 48.126317, lng: 19.905293 },
    { lat: 48.12671, lng: 19.906047 },
    { lat: 48.1267092, lng: 19.906093 },
    { lat: 48.126805, lng: 19.906409 },
    { lat: 48.126985, lng: 19.906623 },
    { lat: 48.127208, lng: 19.906761 },
    { lat: 48.1274818, lng: 19.9067725 },
    { lat: 48.128158, lng: 19.90681 },
    { lat: 48.12884, lng: 19.906864 },
    { lat: 48.128917, lng: 19.907248 },
    { lat: 48.129143, lng: 19.907519 },
    { lat: 48.1294405, lng: 19.9080063 },
    { lat: 48.129469, lng: 19.908053 },
    { lat: 48.129472, lng: 19.90809 },
    { lat: 48.129545, lng: 19.908308 },
    { lat: 48.129572, lng: 19.908565 },
    { lat: 48.129571, lng: 19.908774 },
    { lat: 48.129606, lng: 19.909005 },
    { lat: 48.129686, lng: 19.909269 },
    { lat: 48.129715, lng: 19.909414 },
    { lat: 48.129717, lng: 19.909539 },
    { lat: 48.129743, lng: 19.909776 },
    { lat: 48.129811, lng: 19.910034 },
    { lat: 48.1298219, lng: 19.9101499 },
    { lat: 48.129836, lng: 19.910301 },
    { lat: 48.129842, lng: 19.910561 },
    { lat: 48.129844, lng: 19.910856 },
    { lat: 48.129804, lng: 19.91109 },
    { lat: 48.129667, lng: 19.911675 },
    { lat: 48.129435, lng: 19.912373 },
    { lat: 48.129304, lng: 19.912696 },
    { lat: 48.12909, lng: 19.913227 },
    { lat: 48.129037, lng: 19.913768 },
    { lat: 48.128913, lng: 19.913973 },
    { lat: 48.128861, lng: 19.914138 },
    { lat: 48.128752, lng: 19.914562 },
    { lat: 48.128667, lng: 19.91491 },
    { lat: 48.128642, lng: 19.914897 },
    { lat: 48.128459, lng: 19.915758 },
    { lat: 48.128783, lng: 19.916305 },
    { lat: 48.129244, lng: 19.916958 },
    { lat: 48.129266, lng: 19.916961 },
    { lat: 48.129249, lng: 19.917273 },
    { lat: 48.129245, lng: 19.91732 },
    { lat: 48.12909, lng: 19.918067 },
    { lat: 48.129042, lng: 19.918289 },
    { lat: 48.129022, lng: 19.918457 },
    { lat: 48.1290281, lng: 19.9185256 },
    { lat: 48.12903, lng: 19.918635 },
    { lat: 48.129065, lng: 19.91885 },
    { lat: 48.129109, lng: 19.918988 },
    { lat: 48.129162, lng: 19.919664 },
    { lat: 48.129243, lng: 19.920571 },
    { lat: 48.129288, lng: 19.921353 },
    { lat: 48.129278, lng: 19.921815 },
    { lat: 48.129279, lng: 19.922794 },
    { lat: 48.12956, lng: 19.923843 },
    { lat: 48.129723, lng: 19.924895 },
    { lat: 48.129783, lng: 19.925528 },
    { lat: 48.129841, lng: 19.926147 },
    { lat: 48.129927, lng: 19.927012 },
    { lat: 48.129964, lng: 19.927585 },
    { lat: 48.130114, lng: 19.928178 },
    { lat: 48.130078, lng: 19.928434 },
    { lat: 48.13006, lng: 19.928808 },
    { lat: 48.130111, lng: 19.929366 },
    { lat: 48.130398, lng: 19.93016 },
    { lat: 48.130633, lng: 19.930886 },
    { lat: 48.130767, lng: 19.931139 },
    { lat: 48.130964, lng: 19.931381 },
    { lat: 48.131569, lng: 19.932281 },
    { lat: 48.131639, lng: 19.932479 },
    { lat: 48.131889, lng: 19.933116 },
    { lat: 48.131962, lng: 19.933361 },
    { lat: 48.132036, lng: 19.933711 },
    { lat: 48.132047, lng: 19.93383 },
    { lat: 48.132055, lng: 19.933929 },
    { lat: 48.132048, lng: 19.93414 },
    { lat: 48.132043, lng: 19.934173 },
    { lat: 48.13204, lng: 19.934353 },
    { lat: 48.132001, lng: 19.934564 },
    { lat: 48.131983, lng: 19.934657 },
    { lat: 48.131858, lng: 19.935298 },
    { lat: 48.13184, lng: 19.935445 },
    { lat: 48.131623, lng: 19.936459 },
    { lat: 48.131512, lng: 19.936778 },
    { lat: 48.131426, lng: 19.936892 },
    { lat: 48.1312403, lng: 19.9370707 },
    { lat: 48.131187, lng: 19.937122 },
    { lat: 48.131132, lng: 19.937226 },
    { lat: 48.13111, lng: 19.93733 },
    { lat: 48.131118, lng: 19.937383 },
    { lat: 48.131169, lng: 19.937456 },
    { lat: 48.131249, lng: 19.937494 },
    { lat: 48.1314198, lng: 19.9374412 },
    { lat: 48.131469, lng: 19.937426 },
    { lat: 48.131598, lng: 19.937407 },
    { lat: 48.131617, lng: 19.937411 },
    { lat: 48.131779, lng: 19.937474 },
    { lat: 48.131866, lng: 19.937509 },
    { lat: 48.132192, lng: 19.937622 },
    { lat: 48.13263, lng: 19.937934 },
    { lat: 48.132904, lng: 19.93812 },
    { lat: 48.133211, lng: 19.938357 },
    { lat: 48.133318, lng: 19.938434 },
    { lat: 48.133517, lng: 19.93854 },
    { lat: 48.133861, lng: 19.938839 },
    { lat: 48.133872, lng: 19.938816 },
    { lat: 48.134296, lng: 19.938731 },
    { lat: 48.134476, lng: 19.938627 },
    { lat: 48.134711, lng: 19.938457 },
    { lat: 48.134992, lng: 19.938115 },
    { lat: 48.135305, lng: 19.938032 },
    { lat: 48.1353824, lng: 19.93791 },
    { lat: 48.135557, lng: 19.937635 },
    { lat: 48.136214, lng: 19.9373066 },
    { lat: 48.136267, lng: 19.937173 },
    { lat: 48.136736, lng: 19.937145 },
    { lat: 48.137238, lng: 19.937114 },
    { lat: 48.137277, lng: 19.937368 },
    { lat: 48.137292, lng: 19.937608 },
    { lat: 48.137225, lng: 19.937958 },
    { lat: 48.137174, lng: 19.938152 },
    { lat: 48.13745, lng: 19.938539 },
    { lat: 48.137971, lng: 19.938994 },
    { lat: 48.13834, lng: 19.939114 },
    { lat: 48.138729, lng: 19.939477 },
    { lat: 48.138765, lng: 19.939512 },
    { lat: 48.139404, lng: 19.940231 },
    { lat: 48.13987, lng: 19.940594 },
    { lat: 48.139988, lng: 19.941132 },
    { lat: 48.140199, lng: 19.941826 },
    { lat: 48.140419, lng: 19.942333 },
    { lat: 48.140521, lng: 19.942567 },
    { lat: 48.140704, lng: 19.942474 },
    { lat: 48.140954, lng: 19.942886 },
    { lat: 48.141387, lng: 19.943609 },
    { lat: 48.141233, lng: 19.944065 },
    { lat: 48.141146, lng: 19.944516 },
    { lat: 48.141283, lng: 19.944808 },
    { lat: 48.141417, lng: 19.944674 },
    { lat: 48.141587, lng: 19.94456 },
    { lat: 48.141817, lng: 19.944337 },
    { lat: 48.142052, lng: 19.944395 },
    { lat: 48.142214, lng: 19.94449 },
    { lat: 48.142525, lng: 19.944847 },
    { lat: 48.142624, lng: 19.944913 },
    { lat: 48.142958, lng: 19.945272 },
    { lat: 48.14299, lng: 19.945377 },
    { lat: 48.143051, lng: 19.945829 },
    { lat: 48.143104, lng: 19.9462 },
    { lat: 48.143125, lng: 19.946689 },
    { lat: 48.143274, lng: 19.946633 },
    { lat: 48.143692, lng: 19.94654 },
    { lat: 48.144268, lng: 19.946377 },
    { lat: 48.1447, lng: 19.947177 },
    { lat: 48.144981, lng: 19.947548 },
    { lat: 48.145582, lng: 19.948498 },
    { lat: 48.145612, lng: 19.948484 },
    { lat: 48.145741, lng: 19.948424 },
    { lat: 48.1459039, lng: 19.9486546 },
    { lat: 48.146112, lng: 19.948745 },
    { lat: 48.146195, lng: 19.949316 },
    { lat: 48.146277, lng: 19.949766 },
    { lat: 48.146723, lng: 19.949586 },
    { lat: 48.146696, lng: 19.949425 },
    { lat: 48.146802, lng: 19.949445 },
    { lat: 48.147371, lng: 19.949763 },
    { lat: 48.147812, lng: 19.950009 },
    { lat: 48.147824, lng: 19.950284 },
    { lat: 48.148385, lng: 19.950413 },
    { lat: 48.149318, lng: 19.950644 },
    { lat: 48.149369, lng: 19.950656 },
    { lat: 48.149395, lng: 19.950667 },
    { lat: 48.149544, lng: 19.950727 },
    { lat: 48.149566, lng: 19.950736 },
    { lat: 48.149943, lng: 19.950888 },
    { lat: 48.1499875, lng: 19.9509081 },
    { lat: 48.150059, lng: 19.950935 },
    { lat: 48.150738, lng: 19.951219 },
    { lat: 48.151548, lng: 19.951557 },
    { lat: 48.151433, lng: 19.9524 },
    { lat: 48.1577067, lng: 19.9540067 },
    { lat: 48.1580947, lng: 19.9545194 },
    { lat: 48.1581836, lng: 19.9543914 },
    { lat: 48.1588935, lng: 19.9552664 },
    { lat: 48.1596666, lng: 19.9558515 },
    { lat: 48.1601607, lng: 19.9551556 },
    { lat: 48.1602206, lng: 19.955104 },
    { lat: 48.1600804, lng: 19.9545133 },
    { lat: 48.1599122, lng: 19.9536081 },
    { lat: 48.1598765, lng: 19.9531916 },
    { lat: 48.160088, lng: 19.944328 },
    { lat: 48.1600698, lng: 19.9435602 },
    { lat: 48.1600794, lng: 19.9434411 },
    { lat: 48.1590198, lng: 19.9365538 },
    { lat: 48.1565878, lng: 19.9321678 },
    { lat: 48.1566168, lng: 19.9321326 },
    { lat: 48.1540481, lng: 19.9278496 },
    { lat: 48.151851, lng: 19.9274481 },
    { lat: 48.1509718, lng: 19.9274349 },
    { lat: 48.1499359, lng: 19.9252952 },
    { lat: 48.1491969, lng: 19.923539 },
    { lat: 48.1490709, lng: 19.9214963 },
    { lat: 48.1485377, lng: 19.9205202 },
    { lat: 48.1484682, lng: 19.9200277 },
    { lat: 48.1483272, lng: 19.9197915 },
    { lat: 48.1481034, lng: 19.9200115 },
    { lat: 48.1475635, lng: 19.9194238 },
    { lat: 48.1473374, lng: 19.9187478 },
    { lat: 48.1471421, lng: 19.9175618 },
    { lat: 48.1465243, lng: 19.9169234 },
    { lat: 48.1464062, lng: 19.9158808 },
    { lat: 48.1459643, lng: 19.9150628 },
    { lat: 48.145568, lng: 19.9140494 },
    { lat: 48.1442296, lng: 19.9121113 },
    { lat: 48.144085, lng: 19.911418 },
  ],
  RatkovskáSuchá: [
    { lat: 48.5733106, lng: 20.0612688 },
    { lat: 48.5730249, lng: 20.0613867 },
    { lat: 48.5728318, lng: 20.0613757 },
    { lat: 48.5723276, lng: 20.0610596 },
    { lat: 48.5719946, lng: 20.0607708 },
    { lat: 48.5718886, lng: 20.0610376 },
    { lat: 48.5715874, lng: 20.0615169 },
    { lat: 48.5713007, lng: 20.0613123 },
    { lat: 48.5708105, lng: 20.0612008 },
    { lat: 48.5706501, lng: 20.06143 },
    { lat: 48.5706007, lng: 20.0615843 },
    { lat: 48.5705473, lng: 20.0617515 },
    { lat: 48.5703843, lng: 20.0619262 },
    { lat: 48.5693907, lng: 20.0627102 },
    { lat: 48.5690539, lng: 20.0631156 },
    { lat: 48.5698345, lng: 20.063499 },
    { lat: 48.5706435, lng: 20.0637744 },
    { lat: 48.570861, lng: 20.0646224 },
    { lat: 48.5705392, lng: 20.0646029 },
    { lat: 48.5703803, lng: 20.065258 },
    { lat: 48.5703671, lng: 20.0673355 },
    { lat: 48.5700418, lng: 20.0673977 },
    { lat: 48.5690148, lng: 20.0674244 },
    { lat: 48.5691514, lng: 20.0679778 },
    { lat: 48.5686994, lng: 20.0682962 },
    { lat: 48.5682865, lng: 20.0684718 },
    { lat: 48.568282, lng: 20.0690192 },
    { lat: 48.5680353, lng: 20.0697277 },
    { lat: 48.5675466, lng: 20.0696786 },
    { lat: 48.5674635, lng: 20.0699584 },
    { lat: 48.5672837, lng: 20.070269 },
    { lat: 48.5671486, lng: 20.0700765 },
    { lat: 48.5667515, lng: 20.0706856 },
    { lat: 48.5664577, lng: 20.0709909 },
    { lat: 48.5660481, lng: 20.0711648 },
    { lat: 48.5658141, lng: 20.071174 },
    { lat: 48.5640291, lng: 20.07208 },
    { lat: 48.5639197, lng: 20.0721051 },
    { lat: 48.5634661, lng: 20.071866 },
    { lat: 48.562902, lng: 20.0721991 },
    { lat: 48.562052, lng: 20.073901 },
    { lat: 48.5619727, lng: 20.0741641 },
    { lat: 48.5618564, lng: 20.0743699 },
    { lat: 48.5619217, lng: 20.0744552 },
    { lat: 48.562224, lng: 20.0742995 },
    { lat: 48.5638315, lng: 20.0741717 },
    { lat: 48.5643291, lng: 20.0739725 },
    { lat: 48.564747, lng: 20.0737224 },
    { lat: 48.5650423, lng: 20.0736208 },
    { lat: 48.5654267, lng: 20.0733269 },
    { lat: 48.5660256, lng: 20.0726093 },
    { lat: 48.5667035, lng: 20.072199 },
    { lat: 48.5672027, lng: 20.0716042 },
    { lat: 48.5675901, lng: 20.0718453 },
    { lat: 48.5680228, lng: 20.0725889 },
    { lat: 48.5683276, lng: 20.0734213 },
    { lat: 48.5684288, lng: 20.0737742 },
    { lat: 48.5684861, lng: 20.0742074 },
    { lat: 48.568515, lng: 20.0751812 },
    { lat: 48.5689141, lng: 20.0762271 },
    { lat: 48.5700179, lng: 20.0777519 },
    { lat: 48.5707723, lng: 20.0784277 },
    { lat: 48.5711427, lng: 20.0784813 },
    { lat: 48.5715694, lng: 20.0784178 },
    { lat: 48.5718556, lng: 20.0785578 },
    { lat: 48.5722259, lng: 20.078579 },
    { lat: 48.5722858, lng: 20.0787213 },
    { lat: 48.5723573, lng: 20.0787616 },
    { lat: 48.5723874, lng: 20.0789697 },
    { lat: 48.572844, lng: 20.0795003 },
    { lat: 48.5728361, lng: 20.0796639 },
    { lat: 48.572953, lng: 20.0798482 },
    { lat: 48.5730326, lng: 20.0798664 },
    { lat: 48.573753, lng: 20.0807023 },
    { lat: 48.5738444, lng: 20.0811743 },
    { lat: 48.5738049, lng: 20.0814191 },
    { lat: 48.5738537, lng: 20.0815896 },
    { lat: 48.574089, lng: 20.0819709 },
    { lat: 48.5744953, lng: 20.0823977 },
    { lat: 48.5746132, lng: 20.0826631 },
    { lat: 48.5749005, lng: 20.0836218 },
    { lat: 48.574958, lng: 20.0840311 },
    { lat: 48.575045, lng: 20.0842026 },
    { lat: 48.5750824, lng: 20.0844563 },
    { lat: 48.5752988, lng: 20.0847586 },
    { lat: 48.5753211, lng: 20.0850265 },
    { lat: 48.5754384, lng: 20.0852766 },
    { lat: 48.5754671, lng: 20.0855624 },
    { lat: 48.5756123, lng: 20.0856996 },
    { lat: 48.5757404, lng: 20.086319 },
    { lat: 48.5757165, lng: 20.0868334 },
    { lat: 48.5757983, lng: 20.0878918 },
    { lat: 48.5759868, lng: 20.0881982 },
    { lat: 48.576033, lng: 20.088149 },
    { lat: 48.575964, lng: 20.087943 },
    { lat: 48.575935, lng: 20.087732 },
    { lat: 48.575928, lng: 20.087577 },
    { lat: 48.575941, lng: 20.087441 },
    { lat: 48.576062, lng: 20.087315 },
    { lat: 48.576216, lng: 20.087269 },
    { lat: 48.576238, lng: 20.087185 },
    { lat: 48.576242, lng: 20.087056 },
    { lat: 48.576259, lng: 20.087012 },
    { lat: 48.576416, lng: 20.086992 },
    { lat: 48.576447, lng: 20.086852 },
    { lat: 48.5766, lng: 20.08679 },
    { lat: 48.576681, lng: 20.086595 },
    { lat: 48.576683, lng: 20.086226 },
    { lat: 48.576869, lng: 20.085962 },
    { lat: 48.576938, lng: 20.085809 },
    { lat: 48.57698, lng: 20.085591 },
    { lat: 48.577055, lng: 20.085393 },
    { lat: 48.577102, lng: 20.085303 },
    { lat: 48.577106, lng: 20.085216 },
    { lat: 48.5772, lng: 20.085035 },
    { lat: 48.577343, lng: 20.084967 },
    { lat: 48.577449, lng: 20.08485 },
    { lat: 48.577575, lng: 20.08479 },
    { lat: 48.577681, lng: 20.084812 },
    { lat: 48.577938, lng: 20.084713 },
    { lat: 48.578195, lng: 20.08446 },
    { lat: 48.578433, lng: 20.084348 },
    { lat: 48.578516, lng: 20.08436 },
    { lat: 48.578692, lng: 20.084234 },
    { lat: 48.578961, lng: 20.084041 },
    { lat: 48.579483, lng: 20.083736 },
    { lat: 48.579837, lng: 20.08369 },
    { lat: 48.579964, lng: 20.083719 },
    { lat: 48.580047, lng: 20.083703 },
    { lat: 48.580133, lng: 20.083733 },
    { lat: 48.580239, lng: 20.083676 },
    { lat: 48.580333, lng: 20.083761 },
    { lat: 48.580613, lng: 20.083618 },
    { lat: 48.580707, lng: 20.083697 },
    { lat: 48.580822, lng: 20.083758 },
    { lat: 48.581023, lng: 20.083841 },
    { lat: 48.581213, lng: 20.083804 },
    { lat: 48.581344, lng: 20.08381 },
    { lat: 48.58141, lng: 20.083734 },
    { lat: 48.581437, lng: 20.08377 },
    { lat: 48.581682, lng: 20.083992 },
    { lat: 48.581825, lng: 20.083685 },
    { lat: 48.581951, lng: 20.083451 },
    { lat: 48.582076, lng: 20.083327 },
    { lat: 48.582174, lng: 20.08344 },
    { lat: 48.582194, lng: 20.083462 },
    { lat: 48.582248, lng: 20.083525 },
    { lat: 48.582481, lng: 20.083757 },
    { lat: 48.582554, lng: 20.083783 },
    { lat: 48.582642, lng: 20.08385 },
    { lat: 48.582734, lng: 20.084014 },
    { lat: 48.582781, lng: 20.084139 },
    { lat: 48.582798, lng: 20.084067 },
    { lat: 48.582884, lng: 20.084009 },
    { lat: 48.583074, lng: 20.08366 },
    { lat: 48.583144, lng: 20.083781 },
    { lat: 48.583201, lng: 20.083792 },
    { lat: 48.583294, lng: 20.083726 },
    { lat: 48.583389, lng: 20.083691 },
    { lat: 48.583517, lng: 20.083678 },
    { lat: 48.583585, lng: 20.083647 },
    { lat: 48.583705, lng: 20.083456 },
    { lat: 48.584043, lng: 20.083301 },
    { lat: 48.584192, lng: 20.083132 },
    { lat: 48.584636, lng: 20.082257 },
    { lat: 48.584703, lng: 20.08211 },
    { lat: 48.584708, lng: 20.08208 },
    { lat: 48.584719, lng: 20.082017 },
    { lat: 48.584734, lng: 20.082011 },
    { lat: 48.584768, lng: 20.081999 },
    { lat: 48.584761, lng: 20.081652 },
    { lat: 48.584815, lng: 20.081366 },
    { lat: 48.584876, lng: 20.08095 },
    { lat: 48.584892, lng: 20.080611 },
    { lat: 48.58487, lng: 20.080391 },
    { lat: 48.584904, lng: 20.079659 },
    { lat: 48.585037, lng: 20.078621 },
    { lat: 48.585012, lng: 20.078091 },
    { lat: 48.585008, lng: 20.077552 },
    { lat: 48.584892, lng: 20.077087 },
    { lat: 48.58496, lng: 20.076507 },
    { lat: 48.58491, lng: 20.076225 },
    { lat: 48.58491, lng: 20.076051 },
    { lat: 48.584928, lng: 20.075949 },
    { lat: 48.584797, lng: 20.075442 },
    { lat: 48.584757, lng: 20.075281 },
    { lat: 48.584759, lng: 20.074975 },
    { lat: 48.58485, lng: 20.074513 },
    { lat: 48.58493, lng: 20.074223 },
    { lat: 48.58486, lng: 20.073853 },
    { lat: 48.584825, lng: 20.073544 },
    { lat: 48.584813, lng: 20.073506 },
    { lat: 48.584746, lng: 20.073288 },
    { lat: 48.584673, lng: 20.073056 },
    { lat: 48.584608, lng: 20.07272 },
    { lat: 48.584563, lng: 20.072405 },
    { lat: 48.584504, lng: 20.071712 },
    { lat: 48.584413, lng: 20.071407 },
    { lat: 48.584396, lng: 20.071157 },
    { lat: 48.584242, lng: 20.070153 },
    { lat: 48.584235, lng: 20.070026 },
    { lat: 48.584245, lng: 20.069789 },
    { lat: 48.584264, lng: 20.06965 },
    { lat: 48.584338, lng: 20.069395 },
    { lat: 48.584469, lng: 20.069134 },
    { lat: 48.584624, lng: 20.06893 },
    { lat: 48.585282, lng: 20.06853 },
    { lat: 48.585464, lng: 20.068275 },
    { lat: 48.585486, lng: 20.067866 },
    { lat: 48.5855, lng: 20.06775 },
    { lat: 48.585568, lng: 20.067248 },
    { lat: 48.585611, lng: 20.067128 },
    { lat: 48.585653, lng: 20.067057 },
    { lat: 48.585732, lng: 20.066985 },
    { lat: 48.585782, lng: 20.066848 },
    { lat: 48.585826, lng: 20.066746 },
    { lat: 48.585846, lng: 20.066702 },
    { lat: 48.585923, lng: 20.066582 },
    { lat: 48.585941, lng: 20.066416 },
    { lat: 48.585937, lng: 20.066273 },
    { lat: 48.585924, lng: 20.066166 },
    { lat: 48.586088, lng: 20.065818 },
    { lat: 48.586158, lng: 20.065619 },
    { lat: 48.586216, lng: 20.065492 },
    { lat: 48.586274, lng: 20.065376 },
    { lat: 48.586344, lng: 20.065276 },
    { lat: 48.586487, lng: 20.065126 },
    { lat: 48.587039, lng: 20.065013 },
    { lat: 48.587395, lng: 20.064687 },
    { lat: 48.587658, lng: 20.064417 },
    { lat: 48.588237, lng: 20.063708 },
    { lat: 48.588318, lng: 20.063563 },
    { lat: 48.588363, lng: 20.063483 },
    { lat: 48.588408, lng: 20.063478 },
    { lat: 48.588475, lng: 20.063523 },
    { lat: 48.588634, lng: 20.063504 },
    { lat: 48.588977, lng: 20.063541 },
    { lat: 48.589066, lng: 20.063541 },
    { lat: 48.589134, lng: 20.063559 },
    { lat: 48.58922, lng: 20.063606 },
    { lat: 48.589408, lng: 20.063506 },
    { lat: 48.589538, lng: 20.063317 },
    { lat: 48.589652, lng: 20.063271 },
    { lat: 48.589758, lng: 20.063222 },
    { lat: 48.589916, lng: 20.063288 },
    { lat: 48.589997, lng: 20.063262 },
    { lat: 48.590145, lng: 20.063152 },
    { lat: 48.590332, lng: 20.063151 },
    { lat: 48.590613, lng: 20.063318 },
    { lat: 48.590768, lng: 20.063493 },
    { lat: 48.590871, lng: 20.063563 },
    { lat: 48.590985, lng: 20.063701 },
    { lat: 48.591128, lng: 20.063731 },
    { lat: 48.591177, lng: 20.063763 },
    { lat: 48.591278, lng: 20.06387 },
    { lat: 48.591416, lng: 20.064072 },
    { lat: 48.591592, lng: 20.064216 },
    { lat: 48.591725, lng: 20.063989 },
    { lat: 48.591896, lng: 20.063778 },
    { lat: 48.592013, lng: 20.063791 },
    { lat: 48.592501, lng: 20.063667 },
    { lat: 48.592612, lng: 20.06364 },
    { lat: 48.592624, lng: 20.063631 },
    { lat: 48.59265, lng: 20.063615 },
    { lat: 48.59301, lng: 20.063378 },
    { lat: 48.593191, lng: 20.063127 },
    { lat: 48.593226, lng: 20.063108 },
    { lat: 48.593351, lng: 20.063037 },
    { lat: 48.593584, lng: 20.062693 },
    { lat: 48.593786, lng: 20.062499 },
    { lat: 48.594257, lng: 20.062182 },
    { lat: 48.5944, lng: 20.062086 },
    { lat: 48.594844, lng: 20.061791 },
    { lat: 48.595385, lng: 20.06143 },
    { lat: 48.596114, lng: 20.060944 },
    { lat: 48.596696, lng: 20.060912 },
    { lat: 48.596759, lng: 20.060909 },
    { lat: 48.596854, lng: 20.060903 },
    { lat: 48.597194, lng: 20.060884 },
    { lat: 48.597411, lng: 20.060873 },
    { lat: 48.597704, lng: 20.060865 },
    { lat: 48.597855, lng: 20.060773 },
    { lat: 48.598008, lng: 20.06068 },
    { lat: 48.598426, lng: 20.060447 },
    { lat: 48.598599, lng: 20.060343 },
    { lat: 48.598864, lng: 20.060185 },
    { lat: 48.60038, lng: 20.059352 },
    { lat: 48.600508, lng: 20.059282 },
    { lat: 48.600532, lng: 20.059269 },
    { lat: 48.600529, lng: 20.059346 },
    { lat: 48.600614, lng: 20.05979 },
    { lat: 48.60074, lng: 20.060635 },
    { lat: 48.601005, lng: 20.060631 },
    { lat: 48.601175, lng: 20.060477 },
    { lat: 48.601272, lng: 20.060344 },
    { lat: 48.601339, lng: 20.060218 },
    { lat: 48.601433, lng: 20.059946 },
    { lat: 48.601912, lng: 20.059079 },
    { lat: 48.602274, lng: 20.058769 },
    { lat: 48.602362, lng: 20.05855 },
    { lat: 48.602438, lng: 20.058389 },
    { lat: 48.602785, lng: 20.057968 },
    { lat: 48.602826, lng: 20.057919 },
    { lat: 48.602856, lng: 20.057882 },
    { lat: 48.602998, lng: 20.057711 },
    { lat: 48.603238, lng: 20.057259 },
    { lat: 48.603833, lng: 20.057096 },
    { lat: 48.603975, lng: 20.057038 },
    { lat: 48.604381, lng: 20.05686 },
    { lat: 48.605176, lng: 20.056167 },
    { lat: 48.605341, lng: 20.056 },
    { lat: 48.605299, lng: 20.05471 },
    { lat: 48.605387, lng: 20.054156 },
    { lat: 48.605373, lng: 20.053594 },
    { lat: 48.605241, lng: 20.052919 },
    { lat: 48.605221, lng: 20.05281 },
    { lat: 48.6052, lng: 20.052268 },
    { lat: 48.605489, lng: 20.051172 },
    { lat: 48.605592, lng: 20.0509 },
    { lat: 48.6057, lng: 20.050614 },
    { lat: 48.605753, lng: 20.050382 },
    { lat: 48.605763, lng: 20.050143 },
    { lat: 48.605772, lng: 20.04994 },
    { lat: 48.605794, lng: 20.049677 },
    { lat: 48.605779, lng: 20.049282 },
    { lat: 48.605744, lng: 20.049172 },
    { lat: 48.60574, lng: 20.048929 },
    { lat: 48.605698, lng: 20.048598 },
    { lat: 48.605732, lng: 20.048149 },
    { lat: 48.605696, lng: 20.047828 },
    { lat: 48.605569, lng: 20.046918 },
    { lat: 48.605537, lng: 20.04668 },
    { lat: 48.605529, lng: 20.046344 },
    { lat: 48.605559, lng: 20.045112 },
    { lat: 48.605552, lng: 20.04476 },
    { lat: 48.605569, lng: 20.04455 },
    { lat: 48.605722, lng: 20.043929 },
    { lat: 48.605821, lng: 20.043652 },
    { lat: 48.60588, lng: 20.043415 },
    { lat: 48.605986, lng: 20.04306 },
    { lat: 48.606112, lng: 20.042753 },
    { lat: 48.606248, lng: 20.042492 },
    { lat: 48.606466, lng: 20.042267 },
    { lat: 48.606477, lng: 20.04228 },
    { lat: 48.606539, lng: 20.042225 },
    { lat: 48.606529, lng: 20.042206 },
    { lat: 48.606892, lng: 20.042026 },
    { lat: 48.606954, lng: 20.041996 },
    { lat: 48.607023, lng: 20.041961 },
    { lat: 48.607112, lng: 20.041918 },
    { lat: 48.607168, lng: 20.04189 },
    { lat: 48.607866, lng: 20.041573 },
    { lat: 48.608276, lng: 20.041383 },
    { lat: 48.608755, lng: 20.041046 },
    { lat: 48.609018, lng: 20.040785 },
    { lat: 48.609626, lng: 20.040415 },
    { lat: 48.610201, lng: 20.039518 },
    { lat: 48.610833, lng: 20.039235 },
    { lat: 48.61135, lng: 20.039411 },
    { lat: 48.611492, lng: 20.039459 },
    { lat: 48.611562, lng: 20.039482 },
    { lat: 48.611664, lng: 20.039455 },
    { lat: 48.61181, lng: 20.039416 },
    { lat: 48.612338, lng: 20.039274 },
    { lat: 48.612732, lng: 20.039216 },
    { lat: 48.613451, lng: 20.039131 },
    { lat: 48.613502, lng: 20.039075 },
    { lat: 48.613552, lng: 20.039021 },
    { lat: 48.613581, lng: 20.03899 },
    { lat: 48.613569, lng: 20.038903 },
    { lat: 48.613401, lng: 20.037584 },
    { lat: 48.613392, lng: 20.037514 },
    { lat: 48.613391, lng: 20.037505 },
    { lat: 48.613389, lng: 20.03749 },
    { lat: 48.613311, lng: 20.036838 },
    { lat: 48.613304, lng: 20.036523 },
    { lat: 48.613317, lng: 20.036263 },
    { lat: 48.613372, lng: 20.036037 },
    { lat: 48.613415, lng: 20.035755 },
    { lat: 48.613334, lng: 20.035415 },
    { lat: 48.613167, lng: 20.035095 },
    { lat: 48.612935, lng: 20.034679 },
    { lat: 48.612933, lng: 20.034578 },
    { lat: 48.612821, lng: 20.034357 },
    { lat: 48.612688, lng: 20.034098 },
    { lat: 48.612666, lng: 20.034053 },
    { lat: 48.612643, lng: 20.033909 },
    { lat: 48.612538, lng: 20.033386 },
    { lat: 48.612453, lng: 20.032887 },
    { lat: 48.612261, lng: 20.03246 },
    { lat: 48.612234, lng: 20.032369 },
    { lat: 48.612227, lng: 20.032208 },
    { lat: 48.612216, lng: 20.032128 },
    { lat: 48.612189, lng: 20.03197 },
    { lat: 48.612155, lng: 20.031836 },
    { lat: 48.612021, lng: 20.031662 },
    { lat: 48.6110538, lng: 20.0332054 },
    { lat: 48.6108608, lng: 20.0336277 },
    { lat: 48.6104006, lng: 20.0339076 },
    { lat: 48.6091891, lng: 20.0341656 },
    { lat: 48.608901, lng: 20.0340661 },
    { lat: 48.6085308, lng: 20.0340738 },
    { lat: 48.6074542, lng: 20.0343788 },
    { lat: 48.606877, lng: 20.0346103 },
    { lat: 48.6062379, lng: 20.0351975 },
    { lat: 48.6061364, lng: 20.0351405 },
    { lat: 48.6052565, lng: 20.0354882 },
    { lat: 48.6051774, lng: 20.0354814 },
    { lat: 48.6049461, lng: 20.0351976 },
    { lat: 48.6048126, lng: 20.0351832 },
    { lat: 48.6047508, lng: 20.0352403 },
    { lat: 48.6038378, lng: 20.0352796 },
    { lat: 48.6021327, lng: 20.0359398 },
    { lat: 48.6013907, lng: 20.0359758 },
    { lat: 48.6005548, lng: 20.036758 },
    { lat: 48.5991765, lng: 20.0377712 },
    { lat: 48.5982909, lng: 20.0391971 },
    { lat: 48.5977223, lng: 20.0403166 },
    { lat: 48.5963488, lng: 20.0410644 },
    { lat: 48.5950502, lng: 20.0426915 },
    { lat: 48.5944674, lng: 20.0442043 },
    { lat: 48.5928316, lng: 20.0451166 },
    { lat: 48.5914761, lng: 20.046494 },
    { lat: 48.5908977, lng: 20.0469915 },
    { lat: 48.5907901, lng: 20.0472486 },
    { lat: 48.5910982, lng: 20.0490818 },
    { lat: 48.5905424, lng: 20.052357 },
    { lat: 48.5907532, lng: 20.0529591 },
    { lat: 48.5897413, lng: 20.0553411 },
    { lat: 48.5892266, lng: 20.0575474 },
    { lat: 48.5890644, lng: 20.0584183 },
    { lat: 48.5886675, lng: 20.0599026 },
    { lat: 48.5870114, lng: 20.0628106 },
    { lat: 48.5862343, lng: 20.0634927 },
    { lat: 48.5857456, lng: 20.0637133 },
    { lat: 48.5855767, lng: 20.0639042 },
    { lat: 48.5851191, lng: 20.0639511 },
    { lat: 48.5844516, lng: 20.0642358 },
    { lat: 48.5838876, lng: 20.0643525 },
    { lat: 48.5833748, lng: 20.0646988 },
    { lat: 48.5825291, lng: 20.0642418 },
    { lat: 48.5822298, lng: 20.0639501 },
    { lat: 48.5816752, lng: 20.0631755 },
    { lat: 48.5813959, lng: 20.0629335 },
    { lat: 48.5809736, lng: 20.062419 },
    { lat: 48.580793, lng: 20.0624363 },
    { lat: 48.5805733, lng: 20.062734 },
    { lat: 48.5803922, lng: 20.0628233 },
    { lat: 48.5800716, lng: 20.0627257 },
    { lat: 48.5796647, lng: 20.0624694 },
    { lat: 48.5793169, lng: 20.0621229 },
    { lat: 48.5790105, lng: 20.0620663 },
    { lat: 48.5788826, lng: 20.0603912 },
    { lat: 48.5788429, lng: 20.0605486 },
    { lat: 48.5786242, lng: 20.0605734 },
    { lat: 48.5784391, lng: 20.061081 },
    { lat: 48.5784949, lng: 20.0611227 },
    { lat: 48.5784072, lng: 20.0615354 },
    { lat: 48.5781067, lng: 20.0618151 },
    { lat: 48.5779559, lng: 20.0621402 },
    { lat: 48.5774531, lng: 20.0622425 },
    { lat: 48.5772934, lng: 20.0624372 },
    { lat: 48.5772126, lng: 20.0623338 },
    { lat: 48.5765325, lng: 20.0619883 },
    { lat: 48.5762057, lng: 20.0620364 },
    { lat: 48.5757741, lng: 20.0623068 },
    { lat: 48.5758122, lng: 20.0627378 },
    { lat: 48.5757335, lng: 20.0631756 },
    { lat: 48.5746458, lng: 20.0623402 },
    { lat: 48.5738708, lng: 20.0614719 },
    { lat: 48.5735176, lng: 20.0612921 },
    { lat: 48.5733106, lng: 20.0612688 },
  ],
  Slizké: [
    { lat: 48.5013135, lng: 20.09825 },
    { lat: 48.5013082, lng: 20.0983393 },
    { lat: 48.5008888, lng: 20.1010932 },
    { lat: 48.5011495, lng: 20.1011893 },
    { lat: 48.5014208, lng: 20.1011339 },
    { lat: 48.5021208, lng: 20.1013383 },
    { lat: 48.5022556, lng: 20.1011631 },
    { lat: 48.5023472, lng: 20.1011745 },
    { lat: 48.5026102, lng: 20.1014345 },
    { lat: 48.5029364, lng: 20.101451 },
    { lat: 48.5033616, lng: 20.1017354 },
    { lat: 48.5036328, lng: 20.1016998 },
    { lat: 48.5038508, lng: 20.101774 },
    { lat: 48.5039323, lng: 20.1019188 },
    { lat: 48.5042221, lng: 20.1021177 },
    { lat: 48.5042803, lng: 20.102416 },
    { lat: 48.504405, lng: 20.1024857 },
    { lat: 48.5049573, lng: 20.1024972 },
    { lat: 48.505129, lng: 20.1025619 },
    { lat: 48.5051942, lng: 20.1027757 },
    { lat: 48.505164, lng: 20.1030303 },
    { lat: 48.5052545, lng: 20.1030663 },
    { lat: 48.5053583, lng: 20.102932 },
    { lat: 48.5054792, lng: 20.1030273 },
    { lat: 48.5054997, lng: 20.1032214 },
    { lat: 48.5055701, lng: 20.1032916 },
    { lat: 48.5057565, lng: 20.1033062 },
    { lat: 48.5058943, lng: 20.1035655 },
    { lat: 48.5060239, lng: 20.1035073 },
    { lat: 48.506254, lng: 20.1036276 },
    { lat: 48.5063664, lng: 20.1037841 },
    { lat: 48.5065363, lng: 20.1038049 },
    { lat: 48.5065954, lng: 20.1039564 },
    { lat: 48.5069692, lng: 20.1037887 },
    { lat: 48.5073439, lng: 20.1041094 },
    { lat: 48.5077249, lng: 20.104252 },
    { lat: 48.5078071, lng: 20.1041171 },
    { lat: 48.5082062, lng: 20.1045059 },
    { lat: 48.5083583, lng: 20.104538 },
    { lat: 48.5083848, lng: 20.1046083 },
    { lat: 48.5085664, lng: 20.1046102 },
    { lat: 48.5086778, lng: 20.1046922 },
    { lat: 48.5090142, lng: 20.1046297 },
    { lat: 48.5090958, lng: 20.1044498 },
    { lat: 48.5093677, lng: 20.1045403 },
    { lat: 48.5093964, lng: 20.1047746 },
    { lat: 48.5097542, lng: 20.1048713 },
    { lat: 48.5097755, lng: 20.1047063 },
    { lat: 48.5098593, lng: 20.1046385 },
    { lat: 48.5099932, lng: 20.1049624 },
    { lat: 48.5102223, lng: 20.1048321 },
    { lat: 48.5105374, lng: 20.1051324 },
    { lat: 48.5106447, lng: 20.1050833 },
    { lat: 48.510751, lng: 20.1051779 },
    { lat: 48.5107953, lng: 20.1053482 },
    { lat: 48.5109232, lng: 20.1055029 },
    { lat: 48.5110359, lng: 20.1053919 },
    { lat: 48.511329, lng: 20.1057284 },
    { lat: 48.5115867, lng: 20.1058323 },
    { lat: 48.5116742, lng: 20.1059336 },
    { lat: 48.5116895, lng: 20.1060878 },
    { lat: 48.5118175, lng: 20.1062673 },
    { lat: 48.5118261, lng: 20.1065351 },
    { lat: 48.5121086, lng: 20.1069124 },
    { lat: 48.5120976, lng: 20.107031 },
    { lat: 48.5120028, lng: 20.107135 },
    { lat: 48.5120106, lng: 20.1072394 },
    { lat: 48.5120699, lng: 20.1073682 },
    { lat: 48.5120688, lng: 20.107618 },
    { lat: 48.5121496, lng: 20.1077459 },
    { lat: 48.5122082, lng: 20.1080228 },
    { lat: 48.5123093, lng: 20.1080699 },
    { lat: 48.5124021, lng: 20.1079977 },
    { lat: 48.5125896, lng: 20.1081209 },
    { lat: 48.512663, lng: 20.1083148 },
    { lat: 48.5126464, lng: 20.108435 },
    { lat: 48.5125867, lng: 20.1084868 },
    { lat: 48.5126056, lng: 20.108539 },
    { lat: 48.5126572, lng: 20.1085317 },
    { lat: 48.5127374, lng: 20.1083459 },
    { lat: 48.5127935, lng: 20.1083386 },
    { lat: 48.5132213, lng: 20.1087724 },
    { lat: 48.5132924, lng: 20.1089686 },
    { lat: 48.5134414, lng: 20.1089487 },
    { lat: 48.5135549, lng: 20.1091073 },
    { lat: 48.51382, lng: 20.1098045 },
    { lat: 48.5139892, lng: 20.109949 },
    { lat: 48.5140707, lng: 20.1099034 },
    { lat: 48.5142258, lng: 20.1099505 },
    { lat: 48.5143992, lng: 20.1098111 },
    { lat: 48.5146057, lng: 20.109821 },
    { lat: 48.5147752, lng: 20.1097236 },
    { lat: 48.51526, lng: 20.1096785 },
    { lat: 48.516051, lng: 20.1093592 },
    { lat: 48.5163044, lng: 20.1091939 },
    { lat: 48.5169141, lng: 20.109145 },
    { lat: 48.5169558, lng: 20.109063 },
    { lat: 48.517135, lng: 20.1091213 },
    { lat: 48.5171833, lng: 20.1090376 },
    { lat: 48.5174649, lng: 20.1089006 },
    { lat: 48.5174911, lng: 20.1089639 },
    { lat: 48.5177029, lng: 20.1089371 },
    { lat: 48.5180072, lng: 20.1090761 },
    { lat: 48.5181561, lng: 20.1090808 },
    { lat: 48.5183407, lng: 20.1085293 },
    { lat: 48.5184013, lng: 20.1085218 },
    { lat: 48.5184653, lng: 20.1082704 },
    { lat: 48.5185544, lng: 20.1081413 },
    { lat: 48.5186189, lng: 20.1081492 },
    { lat: 48.5186302, lng: 20.1080092 },
    { lat: 48.5186843, lng: 20.1079822 },
    { lat: 48.5188347, lng: 20.1080815 },
    { lat: 48.518909, lng: 20.107937 },
    { lat: 48.518782, lng: 20.107906 },
    { lat: 48.518718, lng: 20.1079 },
    { lat: 48.518643, lng: 20.107891 },
    { lat: 48.518496, lng: 20.107921 },
    { lat: 48.518422, lng: 20.107937 },
    { lat: 48.518371, lng: 20.107943 },
    { lat: 48.518309, lng: 20.107949 },
    { lat: 48.518214, lng: 20.107949 },
    { lat: 48.518195, lng: 20.107946 },
    { lat: 48.518134, lng: 20.107923 },
    { lat: 48.518082, lng: 20.107903 },
    { lat: 48.518014, lng: 20.107838 },
    { lat: 48.517975, lng: 20.107799 },
    { lat: 48.517934, lng: 20.107755 },
    { lat: 48.517909, lng: 20.107721 },
    { lat: 48.517954, lng: 20.107647 },
    { lat: 48.518054, lng: 20.107474 },
    { lat: 48.518186, lng: 20.107238 },
    { lat: 48.518261, lng: 20.107098 },
    { lat: 48.51834, lng: 20.106961 },
    { lat: 48.518541, lng: 20.106616 },
    { lat: 48.518593, lng: 20.106528 },
    { lat: 48.518764, lng: 20.106249 },
    { lat: 48.518987, lng: 20.105886 },
    { lat: 48.519082, lng: 20.105741 },
    { lat: 48.519163, lng: 20.105617 },
    { lat: 48.519276, lng: 20.105423 },
    { lat: 48.519441, lng: 20.105149 },
    { lat: 48.519673, lng: 20.104732 },
    { lat: 48.519905, lng: 20.10431 },
    { lat: 48.520015, lng: 20.104113 },
    { lat: 48.520133, lng: 20.10391 },
    { lat: 48.520415, lng: 20.103417 },
    { lat: 48.520613, lng: 20.103073 },
    { lat: 48.520734, lng: 20.10284 },
    { lat: 48.520928, lng: 20.102463 },
    { lat: 48.521086, lng: 20.102151 },
    { lat: 48.521268, lng: 20.101799 },
    { lat: 48.521519, lng: 20.101388 },
    { lat: 48.521803, lng: 20.100923 },
    { lat: 48.521889, lng: 20.100778 },
    { lat: 48.521904, lng: 20.100753 },
    { lat: 48.521891, lng: 20.100688 },
    { lat: 48.521851, lng: 20.100495 },
    { lat: 48.521793, lng: 20.100209 },
    { lat: 48.521685, lng: 20.099668 },
    { lat: 48.521635, lng: 20.099383 },
    { lat: 48.521579, lng: 20.099068 },
    { lat: 48.521555, lng: 20.098936 },
    { lat: 48.521513, lng: 20.098689 },
    { lat: 48.52139, lng: 20.097985 },
    { lat: 48.521267, lng: 20.09728 },
    { lat: 48.521222, lng: 20.097021 },
    { lat: 48.52121, lng: 20.096956 },
    { lat: 48.5212, lng: 20.096898 },
    { lat: 48.521198, lng: 20.096885 },
    { lat: 48.521188, lng: 20.096827 },
    { lat: 48.521177, lng: 20.096762 },
    { lat: 48.521121, lng: 20.096444 },
    { lat: 48.521044, lng: 20.096079 },
    { lat: 48.520981, lng: 20.095785 },
    { lat: 48.520891, lng: 20.095355 },
    { lat: 48.520785, lng: 20.094834 },
    { lat: 48.52064, lng: 20.094086 },
    { lat: 48.520617, lng: 20.093971 },
    { lat: 48.520604, lng: 20.093906 },
    { lat: 48.520636, lng: 20.093722 },
    { lat: 48.520745, lng: 20.093066 },
    { lat: 48.520791, lng: 20.092813 },
    { lat: 48.520934, lng: 20.092786 },
    { lat: 48.521006, lng: 20.092774 },
    { lat: 48.521042, lng: 20.092767 },
    { lat: 48.521096, lng: 20.092757 },
    { lat: 48.521145, lng: 20.092745 },
    { lat: 48.521163, lng: 20.092746 },
    { lat: 48.521195, lng: 20.09274 },
    { lat: 48.521321, lng: 20.092717 },
    { lat: 48.521373, lng: 20.092694 },
    { lat: 48.521408, lng: 20.092679 },
    { lat: 48.521597, lng: 20.09259 },
    { lat: 48.521819, lng: 20.092484 },
    { lat: 48.522, lng: 20.0924 },
    { lat: 48.522126, lng: 20.092131 },
    { lat: 48.522257, lng: 20.091845 },
    { lat: 48.522272, lng: 20.091702 },
    { lat: 48.522299, lng: 20.091498 },
    { lat: 48.522307, lng: 20.091429 },
    { lat: 48.522449, lng: 20.091293 },
    { lat: 48.522546, lng: 20.091193 },
    { lat: 48.522607, lng: 20.091008 },
    { lat: 48.522679, lng: 20.090811 },
    { lat: 48.522842, lng: 20.090592 },
    { lat: 48.522969, lng: 20.090414 },
    { lat: 48.523068, lng: 20.090244 },
    { lat: 48.523175, lng: 20.090005 },
    { lat: 48.523268, lng: 20.089594 },
    { lat: 48.523354, lng: 20.089227 },
    { lat: 48.523373, lng: 20.088863 },
    { lat: 48.523412, lng: 20.088222 },
    { lat: 48.52344, lng: 20.087792 },
    { lat: 48.523564, lng: 20.087456 },
    { lat: 48.523706, lng: 20.087081 },
    { lat: 48.523821, lng: 20.086678 },
    { lat: 48.523939, lng: 20.086269 },
    { lat: 48.524073, lng: 20.085943 },
    { lat: 48.524231, lng: 20.085549 },
    { lat: 48.52438, lng: 20.085023 },
    { lat: 48.524461, lng: 20.084828 },
    { lat: 48.524594, lng: 20.08451 },
    { lat: 48.524816, lng: 20.084565 },
    { lat: 48.525185, lng: 20.084659 },
    { lat: 48.525302, lng: 20.084598 },
    { lat: 48.525563, lng: 20.084462 },
    { lat: 48.525711, lng: 20.084387 },
    { lat: 48.52597, lng: 20.084256 },
    { lat: 48.526073, lng: 20.08421 },
    { lat: 48.526152, lng: 20.084173 },
    { lat: 48.526338, lng: 20.084081 },
    { lat: 48.526468, lng: 20.08402 },
    { lat: 48.526586, lng: 20.083951 },
    { lat: 48.526782, lng: 20.083906 },
    { lat: 48.526921, lng: 20.083876 },
    { lat: 48.527077, lng: 20.083852 },
    { lat: 48.527202, lng: 20.083841 },
    { lat: 48.527264, lng: 20.083831 },
    { lat: 48.527377, lng: 20.083795 },
    { lat: 48.52761, lng: 20.083888 },
    { lat: 48.527712, lng: 20.083933 },
    { lat: 48.527809, lng: 20.083975 },
    { lat: 48.528063, lng: 20.084184 },
    { lat: 48.528106, lng: 20.084217 },
    { lat: 48.528276, lng: 20.084346 },
    { lat: 48.52841, lng: 20.084452 },
    { lat: 48.528439, lng: 20.084475 },
    { lat: 48.528647, lng: 20.084649 },
    { lat: 48.528803, lng: 20.084396 },
    { lat: 48.528887, lng: 20.084264 },
    { lat: 48.528947, lng: 20.084173 },
    { lat: 48.528906, lng: 20.083895 },
    { lat: 48.528825, lng: 20.083379 },
    { lat: 48.528785, lng: 20.083122 },
    { lat: 48.528748, lng: 20.082868 },
    { lat: 48.528733, lng: 20.082754 },
    { lat: 48.528724, lng: 20.082696 },
    { lat: 48.528732, lng: 20.082413 },
    { lat: 48.528742, lng: 20.082098 },
    { lat: 48.528819, lng: 20.081816 },
    { lat: 48.528905, lng: 20.081498 },
    { lat: 48.528993, lng: 20.081169 },
    { lat: 48.529105, lng: 20.080603 },
    { lat: 48.52916, lng: 20.08033 },
    { lat: 48.52924, lng: 20.08011 },
    { lat: 48.529329, lng: 20.079858 },
    { lat: 48.529436, lng: 20.079553 },
    { lat: 48.529502, lng: 20.079362 },
    { lat: 48.529523, lng: 20.079288 },
    { lat: 48.529532, lng: 20.079323 },
    { lat: 48.52957, lng: 20.079208 },
    { lat: 48.529626, lng: 20.079032 },
    { lat: 48.52968, lng: 20.078876 },
    { lat: 48.529687, lng: 20.078486 },
    { lat: 48.529686, lng: 20.078339 },
    { lat: 48.529728, lng: 20.078055 },
    { lat: 48.529788, lng: 20.077849 },
    { lat: 48.529835, lng: 20.077647 },
    { lat: 48.529875, lng: 20.077461 },
    { lat: 48.529906, lng: 20.077397 },
    { lat: 48.529967, lng: 20.077303 },
    { lat: 48.530008, lng: 20.077243 },
    { lat: 48.530141, lng: 20.077135 },
    { lat: 48.53031, lng: 20.076959 },
    { lat: 48.530441, lng: 20.076821 },
    { lat: 48.5304193, lng: 20.0766681 },
    { lat: 48.5306487, lng: 20.0763297 },
    { lat: 48.5310202, lng: 20.0752625 },
    { lat: 48.5312465, lng: 20.0722611 },
    { lat: 48.5316535, lng: 20.0708825 },
    { lat: 48.5318549, lng: 20.0704947 },
    { lat: 48.5330061, lng: 20.0694347 },
    { lat: 48.533396, lng: 20.0688914 },
    { lat: 48.5337351, lng: 20.0679135 },
    { lat: 48.5343417, lng: 20.0669503 },
    { lat: 48.5346454, lng: 20.0656584 },
    { lat: 48.5348653, lng: 20.0652589 },
    { lat: 48.5356598, lng: 20.0641533 },
    { lat: 48.536135, lng: 20.0636864 },
    { lat: 48.537026, lng: 20.0631155 },
    { lat: 48.5376658, lng: 20.0628678 },
    { lat: 48.5384742, lng: 20.0623725 },
    { lat: 48.5391267, lng: 20.0616736 },
    { lat: 48.5397398, lng: 20.0608621 },
    { lat: 48.540347, lng: 20.0603227 },
    { lat: 48.5412377, lng: 20.0597954 },
    { lat: 48.5411639, lng: 20.0585348 },
    { lat: 48.541318, lng: 20.0584766 },
    { lat: 48.5413301, lng: 20.0578971 },
    { lat: 48.5412628, lng: 20.0576344 },
    { lat: 48.5405635, lng: 20.0569292 },
    { lat: 48.5405474, lng: 20.0567295 },
    { lat: 48.5399676, lng: 20.0560056 },
    { lat: 48.5397897, lng: 20.0558474 },
    { lat: 48.5395535, lng: 20.0557456 },
    { lat: 48.5389358, lng: 20.0559234 },
    { lat: 48.5385182, lng: 20.056257 },
    { lat: 48.5382703, lng: 20.0565529 },
    { lat: 48.538232, lng: 20.056688 },
    { lat: 48.538071, lng: 20.0566925 },
    { lat: 48.5379698, lng: 20.0568595 },
    { lat: 48.5375704, lng: 20.0571152 },
    { lat: 48.5373639, lng: 20.0573493 },
    { lat: 48.5372679, lng: 20.0572869 },
    { lat: 48.5368332, lng: 20.0574021 },
    { lat: 48.5367018, lng: 20.0575175 },
    { lat: 48.5366237, lng: 20.0574562 },
    { lat: 48.5360564, lng: 20.0575174 },
    { lat: 48.535555, lng: 20.0576811 },
    { lat: 48.5353928, lng: 20.057815 },
    { lat: 48.5352448, lng: 20.0577475 },
    { lat: 48.5349532, lng: 20.0578375 },
    { lat: 48.5347733, lng: 20.0577613 },
    { lat: 48.5346676, lng: 20.0578981 },
    { lat: 48.5345942, lng: 20.0578417 },
    { lat: 48.5343168, lng: 20.0578628 },
    { lat: 48.534144, lng: 20.0580408 },
    { lat: 48.5339944, lng: 20.0578814 },
    { lat: 48.5337556, lng: 20.0579909 },
    { lat: 48.5335887, lng: 20.0578758 },
    { lat: 48.5332349, lng: 20.0578781 },
    { lat: 48.5329539, lng: 20.0576753 },
    { lat: 48.5323187, lng: 20.0575104 },
    { lat: 48.5320909, lng: 20.057314 },
    { lat: 48.5317452, lng: 20.0571819 },
    { lat: 48.531032, lng: 20.0570979 },
    { lat: 48.5298828, lng: 20.0571959 },
    { lat: 48.5298029, lng: 20.0573637 },
    { lat: 48.529611, lng: 20.0575164 },
    { lat: 48.5288002, lng: 20.0575745 },
    { lat: 48.5277079, lng: 20.0580986 },
    { lat: 48.5275319, lng: 20.0585505 },
    { lat: 48.5271243, lng: 20.0587455 },
    { lat: 48.5270854, lng: 20.0589225 },
    { lat: 48.526779, lng: 20.0590032 },
    { lat: 48.5267715, lng: 20.0590675 },
    { lat: 48.5251482, lng: 20.0591166 },
    { lat: 48.5247692, lng: 20.0592432 },
    { lat: 48.5247597, lng: 20.059367 },
    { lat: 48.5243602, lng: 20.0598654 },
    { lat: 48.5243943, lng: 20.0600036 },
    { lat: 48.5229964, lng: 20.0613924 },
    { lat: 48.5223615, lng: 20.0609375 },
    { lat: 48.521922, lng: 20.0607993 },
    { lat: 48.521271, lng: 20.0617804 },
    { lat: 48.5206864, lng: 20.0614789 },
    { lat: 48.5200345, lng: 20.0608582 },
    { lat: 48.5197777, lng: 20.060779 },
    { lat: 48.5195231, lng: 20.0608608 },
    { lat: 48.5189148, lng: 20.06078 },
    { lat: 48.5173911, lng: 20.0612443 },
    { lat: 48.5152546, lng: 20.0622325 },
    { lat: 48.5135298, lng: 20.0657366 },
    { lat: 48.5114, lng: 20.0681055 },
    { lat: 48.5105141, lng: 20.0696439 },
    { lat: 48.5103438, lng: 20.0701839 },
    { lat: 48.5101933, lng: 20.0702477 },
    { lat: 48.5100777, lng: 20.0705864 },
    { lat: 48.5100278, lng: 20.0705794 },
    { lat: 48.5099832, lng: 20.0706695 },
    { lat: 48.509722, lng: 20.0716819 },
    { lat: 48.5085438, lng: 20.0746233 },
    { lat: 48.5083776, lng: 20.0744691 },
    { lat: 48.5080563, lng: 20.0740576 },
    { lat: 48.5083649, lng: 20.0746522 },
    { lat: 48.5079095, lng: 20.0756844 },
    { lat: 48.5076601, lng: 20.0761607 },
    { lat: 48.5072734, lng: 20.0765876 },
    { lat: 48.5071343, lng: 20.0768773 },
    { lat: 48.5065864, lng: 20.0770532 },
    { lat: 48.5064889, lng: 20.0773146 },
    { lat: 48.5064403, lng: 20.0775564 },
    { lat: 48.5061298, lng: 20.0788432 },
    { lat: 48.5055276, lng: 20.0805182 },
    { lat: 48.5038277, lng: 20.0828327 },
    { lat: 48.5032573, lng: 20.0839967 },
    { lat: 48.5030411, lng: 20.085227 },
    { lat: 48.5029811, lng: 20.0859873 },
    { lat: 48.5023466, lng: 20.0893934 },
    { lat: 48.5020912, lng: 20.0915291 },
    { lat: 48.5019803, lng: 20.0930759 },
    { lat: 48.5019888, lng: 20.0947345 },
    { lat: 48.5016998, lng: 20.0960318 },
    { lat: 48.5013135, lng: 20.09825 },
  ],
  Lenka: [
    { lat: 48.3884497, lng: 20.3687827 },
    { lat: 48.3892119, lng: 20.3697303 },
    { lat: 48.3892974, lng: 20.3695563 },
    { lat: 48.3894995, lng: 20.3693944 },
    { lat: 48.3898299, lng: 20.3692897 },
    { lat: 48.3915083, lng: 20.3693581 },
    { lat: 48.3918185, lng: 20.3693136 },
    { lat: 48.3922346, lng: 20.3691255 },
    { lat: 48.3924431, lng: 20.3688666 },
    { lat: 48.3925805, lng: 20.368608 },
    { lat: 48.3926519, lng: 20.3681549 },
    { lat: 48.39251, lng: 20.3661568 },
    { lat: 48.3926493, lng: 20.3662063 },
    { lat: 48.3926237, lng: 20.3658092 },
    { lat: 48.3929713, lng: 20.3636832 },
    { lat: 48.3933635, lng: 20.3624462 },
    { lat: 48.3973074, lng: 20.3622714 },
    { lat: 48.4003983, lng: 20.3634738 },
    { lat: 48.402665, lng: 20.362128 },
    { lat: 48.403075, lng: 20.360775 },
    { lat: 48.403254, lng: 20.360138 },
    { lat: 48.402698, lng: 20.359865 },
    { lat: 48.40099, lng: 20.359026 },
    { lat: 48.400648, lng: 20.355942 },
    { lat: 48.399854, lng: 20.347364 },
    { lat: 48.399631, lng: 20.345733 },
    { lat: 48.398919, lng: 20.340507 },
    { lat: 48.398781, lng: 20.339068 },
    { lat: 48.398736, lng: 20.337916 },
    { lat: 48.398857, lng: 20.337214 },
    { lat: 48.398949, lng: 20.33509 },
    { lat: 48.398906, lng: 20.334661 },
    { lat: 48.398966, lng: 20.330893 },
    { lat: 48.398998, lng: 20.328569 },
    { lat: 48.398445, lng: 20.326691 },
    { lat: 48.398438, lng: 20.326459 },
    { lat: 48.3919797, lng: 20.326616 },
    { lat: 48.3900229, lng: 20.3266009 },
    { lat: 48.3892959, lng: 20.3264696 },
    { lat: 48.3883697, lng: 20.3266595 },
    { lat: 48.3814282, lng: 20.3268165 },
    { lat: 48.3805035, lng: 20.326809 },
    { lat: 48.3804858, lng: 20.3263574 },
    { lat: 48.379766, lng: 20.3264721 },
    { lat: 48.3798386, lng: 20.327059 },
    { lat: 48.3800839, lng: 20.3323133 },
    { lat: 48.3802416, lng: 20.3328626 },
    { lat: 48.3813517, lng: 20.3358138 },
    { lat: 48.3807464, lng: 20.3362926 },
    { lat: 48.3804589, lng: 20.3369422 },
    { lat: 48.3802721, lng: 20.3376679 },
    { lat: 48.3801924, lng: 20.3383944 },
    { lat: 48.3799585, lng: 20.3394679 },
    { lat: 48.3794535, lng: 20.3406378 },
    { lat: 48.3790071, lng: 20.3421795 },
    { lat: 48.3787317, lng: 20.3449332 },
    { lat: 48.3785088, lng: 20.3448161 },
    { lat: 48.3778736, lng: 20.3447576 },
    { lat: 48.3770091, lng: 20.3447682 },
    { lat: 48.3762027, lng: 20.3446536 },
    { lat: 48.374757, lng: 20.3446044 },
    { lat: 48.3741062, lng: 20.3448119 },
    { lat: 48.3732094, lng: 20.344945 },
    { lat: 48.3730964, lng: 20.3449334 },
    { lat: 48.373618, lng: 20.3453462 },
    { lat: 48.3738341, lng: 20.3457734 },
    { lat: 48.3741625, lng: 20.3462539 },
    { lat: 48.374804, lng: 20.3469615 },
    { lat: 48.3755516, lng: 20.3474451 },
    { lat: 48.3760287, lng: 20.3478668 },
    { lat: 48.3768113, lng: 20.3483863 },
    { lat: 48.3769908, lng: 20.3480772 },
    { lat: 48.377106, lng: 20.3481751 },
    { lat: 48.3772335, lng: 20.3479773 },
    { lat: 48.3803796, lng: 20.3511399 },
    { lat: 48.3807927, lng: 20.3523775 },
    { lat: 48.3829067, lng: 20.3570863 },
    { lat: 48.3831001, lng: 20.3576294 },
    { lat: 48.3837112, lng: 20.3590081 },
    { lat: 48.383821, lng: 20.3591068 },
    { lat: 48.3867242, lng: 20.3643705 },
    { lat: 48.3875656, lng: 20.3657926 },
    { lat: 48.3884802, lng: 20.3687545 },
    { lat: 48.3884497, lng: 20.3687827 },
  ],
  Kraskovo: [
    { lat: 48.5184578, lng: 19.9849913 },
    { lat: 48.5180636, lng: 19.984935 },
    { lat: 48.5177621, lng: 19.9850015 },
    { lat: 48.517377, lng: 19.9849199 },
    { lat: 48.5166594, lng: 19.9848974 },
    { lat: 48.5164333, lng: 19.9847988 },
    { lat: 48.5158446, lng: 19.9848106 },
    { lat: 48.5147898, lng: 19.9853048 },
    { lat: 48.5145874, lng: 19.9852087 },
    { lat: 48.5143786, lng: 19.9852215 },
    { lat: 48.5138753, lng: 19.9854723 },
    { lat: 48.5135055, lng: 19.9855431 },
    { lat: 48.5129329, lng: 19.9856176 },
    { lat: 48.5124163, lng: 19.9855958 },
    { lat: 48.5119418, lng: 19.9857256 },
    { lat: 48.5116918, lng: 19.9857296 },
    { lat: 48.5111828, lng: 19.9856488 },
    { lat: 48.5109121, lng: 19.9855393 },
    { lat: 48.5106931, lng: 19.9847775 },
    { lat: 48.5103595, lng: 19.9832749 },
    { lat: 48.5098984, lng: 19.9799003 },
    { lat: 48.5098033, lng: 19.9795921 },
    { lat: 48.5095085, lng: 19.9789602 },
    { lat: 48.5092951, lng: 19.9783056 },
    { lat: 48.5092008, lng: 19.9777273 },
    { lat: 48.5090515, lng: 19.9773387 },
    { lat: 48.5084839, lng: 19.9747499 },
    { lat: 48.5085003, lng: 19.9738566 },
    { lat: 48.5084182, lng: 19.9734613 },
    { lat: 48.5084015, lng: 19.9726703 },
    { lat: 48.5082686, lng: 19.9719491 },
    { lat: 48.5080242, lng: 19.9711475 },
    { lat: 48.5076315, lng: 19.9703332 },
    { lat: 48.5070216, lng: 19.9693347 },
    { lat: 48.5061258, lng: 19.9685302 },
    { lat: 48.505999, lng: 19.9685906 },
    { lat: 48.5057722, lng: 19.9683679 },
    { lat: 48.5054798, lng: 19.9686485 },
    { lat: 48.5052727, lng: 19.9686818 },
    { lat: 48.5050337, lng: 19.9692372 },
    { lat: 48.5045514, lng: 19.9695764 },
    { lat: 48.5044499, lng: 19.9694706 },
    { lat: 48.5034729, lng: 19.9695666 },
    { lat: 48.503282, lng: 19.9697408 },
    { lat: 48.5025419, lng: 19.969981 },
    { lat: 48.502482, lng: 19.9698684 },
    { lat: 48.5023355, lng: 19.9698448 },
    { lat: 48.5022454, lng: 19.9696549 },
    { lat: 48.5021276, lng: 19.9696388 },
    { lat: 48.5019453, lng: 19.9697262 },
    { lat: 48.5018804, lng: 19.9696031 },
    { lat: 48.5014515, lng: 19.9693699 },
    { lat: 48.5014061, lng: 19.9692776 },
    { lat: 48.5011054, lng: 19.9691778 },
    { lat: 48.5004679, lng: 19.968701 },
    { lat: 48.5002764, lng: 19.968671 },
    { lat: 48.5000022, lng: 19.9684223 },
    { lat: 48.4995824, lng: 19.9682963 },
    { lat: 48.4990521, lng: 19.9675052 },
    { lat: 48.4987399, lng: 19.965957 },
    { lat: 48.4988066, lng: 19.9656928 },
    { lat: 48.4987923, lng: 19.9651134 },
    { lat: 48.4986502, lng: 19.964791 },
    { lat: 48.4987826, lng: 19.9643265 },
    { lat: 48.4987025, lng: 19.9637375 },
    { lat: 48.4987815, lng: 19.9634745 },
    { lat: 48.4985331, lng: 19.9627429 },
    { lat: 48.498329, lng: 19.9625259 },
    { lat: 48.4981024, lng: 19.9623863 },
    { lat: 48.4979637, lng: 19.9616416 },
    { lat: 48.4977381, lng: 19.9613937 },
    { lat: 48.4975737, lng: 19.960861 },
    { lat: 48.4971007, lng: 19.9603601 },
    { lat: 48.496376, lng: 19.9597629 },
    { lat: 48.495792, lng: 19.9596473 },
    { lat: 48.4950406, lng: 19.9596227 },
    { lat: 48.4944652, lng: 19.9595195 },
    { lat: 48.4936284, lng: 19.9595268 },
    { lat: 48.4928799, lng: 19.959383 },
    { lat: 48.4914891, lng: 19.9585144 },
    { lat: 48.4905358, lng: 19.9615378 },
    { lat: 48.4899858, lng: 19.9629929 },
    { lat: 48.4887237, lng: 19.9669024 },
    { lat: 48.4873316, lng: 19.9692231 },
    { lat: 48.4864324, lng: 19.9708687 },
    { lat: 48.4856191, lng: 19.9721733 },
    { lat: 48.4847371, lng: 19.9737528 },
    { lat: 48.4846595, lng: 19.9739953 },
    { lat: 48.4845984, lng: 19.9746002 },
    { lat: 48.4843574, lng: 19.9752421 },
    { lat: 48.4842441, lng: 19.9758125 },
    { lat: 48.4842179, lng: 19.976903 },
    { lat: 48.4841977, lng: 19.9802416 },
    { lat: 48.4841966, lng: 19.9803216 },
    { lat: 48.4853466, lng: 19.9824017 },
    { lat: 48.485006, lng: 19.9838101 },
    { lat: 48.4850754, lng: 19.9842286 },
    { lat: 48.4849306, lng: 19.9854702 },
    { lat: 48.4847997, lng: 19.9857593 },
    { lat: 48.4848827, lng: 19.9859925 },
    { lat: 48.4847404, lng: 19.9863573 },
    { lat: 48.484763, lng: 19.9869726 },
    { lat: 48.4847291, lng: 19.9872656 },
    { lat: 48.484923, lng: 19.9882051 },
    { lat: 48.4851677, lng: 19.9886399 },
    { lat: 48.4853373, lng: 19.9887043 },
    { lat: 48.4858315, lng: 19.9896103 },
    { lat: 48.4859638, lng: 19.9899436 },
    { lat: 48.4862293, lng: 19.9899602 },
    { lat: 48.4863401, lng: 19.9901577 },
    { lat: 48.486429, lng: 19.990184 },
    { lat: 48.4866752, lng: 19.9907845 },
    { lat: 48.4871724, lng: 19.9912787 },
    { lat: 48.4872683, lng: 19.9912522 },
    { lat: 48.4874922, lng: 19.9917038 },
    { lat: 48.4879069, lng: 19.9919509 },
    { lat: 48.4880471, lng: 19.9921251 },
    { lat: 48.4879437, lng: 19.9922091 },
    { lat: 48.4880583, lng: 19.9930011 },
    { lat: 48.4880093, lng: 19.9941666 },
    { lat: 48.4877331, lng: 19.9949958 },
    { lat: 48.4880814, lng: 19.9958578 },
    { lat: 48.4883293, lng: 19.9960463 },
    { lat: 48.4886073, lng: 19.996925 },
    { lat: 48.4886656, lng: 19.9972685 },
    { lat: 48.4891837, lng: 19.9980715 },
    { lat: 48.4894229, lng: 19.9978922 },
    { lat: 48.4899131, lng: 19.997684 },
    { lat: 48.4916413, lng: 19.997096 },
    { lat: 48.4921679, lng: 19.9969763 },
    { lat: 48.4924916, lng: 19.9978163 },
    { lat: 48.4930734, lng: 20.0005634 },
    { lat: 48.4938277, lng: 20.0037761 },
    { lat: 48.4946729, lng: 20.0036798 },
    { lat: 48.495494, lng: 20.0034061 },
    { lat: 48.4962496, lng: 20.0032385 },
    { lat: 48.4973229, lng: 20.0034501 },
    { lat: 48.4973373, lng: 20.003333 },
    { lat: 48.4987298, lng: 20.0037445 },
    { lat: 48.4987622, lng: 20.00355 },
    { lat: 48.4993248, lng: 20.0036695 },
    { lat: 48.500263, lng: 20.0037474 },
    { lat: 48.5011726, lng: 20.0034628 },
    { lat: 48.5013987, lng: 20.0034836 },
    { lat: 48.5020186, lng: 20.0033845 },
    { lat: 48.5025262, lng: 20.003559 },
    { lat: 48.5029193, lng: 20.0036126 },
    { lat: 48.5032576, lng: 20.0035968 },
    { lat: 48.5032595, lng: 20.0035864 },
    { lat: 48.5032727, lng: 20.0033389 },
    { lat: 48.5035853, lng: 20.0027097 },
    { lat: 48.5038088, lng: 20.0023947 },
    { lat: 48.5046438, lng: 20.0015978 },
    { lat: 48.5055562, lng: 20.0009804 },
    { lat: 48.5057345, lng: 20.0007475 },
    { lat: 48.5064291, lng: 19.9992872 },
    { lat: 48.5069402, lng: 19.9986102 },
    { lat: 48.5076996, lng: 19.9973237 },
    { lat: 48.5080955, lng: 19.9963756 },
    { lat: 48.508255, lng: 19.9961491 },
    { lat: 48.5084608, lng: 19.9956591 },
    { lat: 48.5090437, lng: 19.9948911 },
    { lat: 48.5099075, lng: 19.9942191 },
    { lat: 48.51035, lng: 19.9936999 },
    { lat: 48.5111164, lng: 19.9919984 },
    { lat: 48.5118199, lng: 19.9911519 },
    { lat: 48.5125417, lng: 19.9905837 },
    { lat: 48.5131111, lng: 19.9903326 },
    { lat: 48.5136516, lng: 19.9901905 },
    { lat: 48.5138669, lng: 19.9901974 },
    { lat: 48.5148797, lng: 19.9905439 },
    { lat: 48.515454, lng: 19.9902746 },
    { lat: 48.515839, lng: 19.9904533 },
    { lat: 48.516049, lng: 19.9900129 },
    { lat: 48.516284, lng: 19.9897319 },
    { lat: 48.516558, lng: 19.9889898 },
    { lat: 48.5168534, lng: 19.9886217 },
    { lat: 48.517343, lng: 19.9875386 },
    { lat: 48.5174476, lng: 19.987164 },
    { lat: 48.5177441, lng: 19.9866323 },
    { lat: 48.5178782, lng: 19.9862571 },
    { lat: 48.5179817, lng: 19.9861257 },
    { lat: 48.518174, lng: 19.9855275 },
    { lat: 48.5184578, lng: 19.9849913 },
  ],
  Poproč: [
    { lat: 48.5994283, lng: 20.0066748 },
    { lat: 48.5992334, lng: 20.00702 },
    { lat: 48.598702, lng: 20.0073514 },
    { lat: 48.5985352, lng: 20.0076195 },
    { lat: 48.5981969, lng: 20.007799 },
    { lat: 48.5977628, lng: 20.0082706 },
    { lat: 48.5970979, lng: 20.0086448 },
    { lat: 48.596783, lng: 20.0088971 },
    { lat: 48.596383, lng: 20.0090807 },
    { lat: 48.595876, lng: 20.009568 },
    { lat: 48.5951894, lng: 20.0094724 },
    { lat: 48.5945428, lng: 20.0096349 },
    { lat: 48.5943011, lng: 20.0115019 },
    { lat: 48.5942681, lng: 20.0120335 },
    { lat: 48.5943107, lng: 20.0127831 },
    { lat: 48.5942753, lng: 20.0133935 },
    { lat: 48.5941427, lng: 20.0139641 },
    { lat: 48.5939736, lng: 20.0138556 },
    { lat: 48.5936122, lng: 20.0138041 },
    { lat: 48.5932829, lng: 20.0139265 },
    { lat: 48.5931315, lng: 20.0139184 },
    { lat: 48.5929353, lng: 20.0141047 },
    { lat: 48.5928741, lng: 20.0142831 },
    { lat: 48.5928511, lng: 20.0145681 },
    { lat: 48.5917375, lng: 20.0149549 },
    { lat: 48.5912961, lng: 20.016255 },
    { lat: 48.5909746, lng: 20.0164862 },
    { lat: 48.5907949, lng: 20.0167157 },
    { lat: 48.5905637, lng: 20.0172205 },
    { lat: 48.590446, lng: 20.0173409 },
    { lat: 48.5895457, lng: 20.0177084 },
    { lat: 48.5879354, lng: 20.0169386 },
    { lat: 48.5879961, lng: 20.0173691 },
    { lat: 48.5879382, lng: 20.0180342 },
    { lat: 48.5879929, lng: 20.0185072 },
    { lat: 48.5881769, lng: 20.0186527 },
    { lat: 48.5882955, lng: 20.0189272 },
    { lat: 48.5882803, lng: 20.0193701 },
    { lat: 48.5879728, lng: 20.0198583 },
    { lat: 48.5874472, lng: 20.0203013 },
    { lat: 48.5871899, lng: 20.0206398 },
    { lat: 48.5862965, lng: 20.0204481 },
    { lat: 48.5857081, lng: 20.0208461 },
    { lat: 48.5855443, lng: 20.0212082 },
    { lat: 48.5849823, lng: 20.0213504 },
    { lat: 48.5848016, lng: 20.0215504 },
    { lat: 48.5840899, lng: 20.0228271 },
    { lat: 48.5835016, lng: 20.0237366 },
    { lat: 48.5827017, lng: 20.0231649 },
    { lat: 48.5826266, lng: 20.0231489 },
    { lat: 48.5826139, lng: 20.0232227 },
    { lat: 48.5822445, lng: 20.0233303 },
    { lat: 48.5815158, lng: 20.0233346 },
    { lat: 48.581454, lng: 20.0231501 },
    { lat: 48.581029, lng: 20.023084 },
    { lat: 48.5801476, lng: 20.0234229 },
    { lat: 48.5795972, lng: 20.0238646 },
    { lat: 48.5791411, lng: 20.0239487 },
    { lat: 48.578596, lng: 20.023736 },
    { lat: 48.5783423, lng: 20.0237558 },
    { lat: 48.5776982, lng: 20.0243845 },
    { lat: 48.5767902, lng: 20.0243016 },
    { lat: 48.575221, lng: 20.0239159 },
    { lat: 48.5744304, lng: 20.0251931 },
    { lat: 48.5739998, lng: 20.024484 },
    { lat: 48.5738803, lng: 20.0241543 },
    { lat: 48.5735425, lng: 20.0237512 },
    { lat: 48.5731242, lng: 20.0237165 },
    { lat: 48.5725, lng: 20.0246588 },
    { lat: 48.5719066, lng: 20.0248231 },
    { lat: 48.5717944, lng: 20.0249963 },
    { lat: 48.5713229, lng: 20.0246828 },
    { lat: 48.570626, lng: 20.0239891 },
    { lat: 48.5702384, lng: 20.0238828 },
    { lat: 48.5697248, lng: 20.0243685 },
    { lat: 48.56963, lng: 20.0246315 },
    { lat: 48.5695204, lng: 20.0252739 },
    { lat: 48.5697129, lng: 20.0260527 },
    { lat: 48.5695833, lng: 20.0261603 },
    { lat: 48.5689933, lng: 20.0269491 },
    { lat: 48.5690052, lng: 20.0274509 },
    { lat: 48.568795, lng: 20.0279214 },
    { lat: 48.5687514, lng: 20.028146 },
    { lat: 48.5686041, lng: 20.0282062 },
    { lat: 48.5682521, lng: 20.0304659 },
    { lat: 48.5681923, lng: 20.0308663 },
    { lat: 48.5681273, lng: 20.031497 },
    { lat: 48.5684774, lng: 20.0316794 },
    { lat: 48.5686608, lng: 20.0320252 },
    { lat: 48.5685816, lng: 20.0322617 },
    { lat: 48.5678004, lng: 20.0332489 },
    { lat: 48.5677869, lng: 20.0346532 },
    { lat: 48.5678286, lng: 20.0352524 },
    { lat: 48.567896, lng: 20.0353819 },
    { lat: 48.5683033, lng: 20.0352598 },
    { lat: 48.568144, lng: 20.0361455 },
    { lat: 48.5681704, lng: 20.0365648 },
    { lat: 48.5684177, lng: 20.0365788 },
    { lat: 48.5686643, lng: 20.0369514 },
    { lat: 48.5687597, lng: 20.0369975 },
    { lat: 48.5691393, lng: 20.036999 },
    { lat: 48.5692185, lng: 20.0369296 },
    { lat: 48.569512, lng: 20.0370228 },
    { lat: 48.5699063, lng: 20.0369976 },
    { lat: 48.5701548, lng: 20.0370965 },
    { lat: 48.5705914, lng: 20.0370286 },
    { lat: 48.5709986, lng: 20.0366946 },
    { lat: 48.5712561, lng: 20.0367894 },
    { lat: 48.5714428, lng: 20.0367568 },
    { lat: 48.5716216, lng: 20.0365593 },
    { lat: 48.572141, lng: 20.0369943 },
    { lat: 48.5723828, lng: 20.0373371 },
    { lat: 48.5724886, lng: 20.037389 },
    { lat: 48.5725661, lng: 20.0374147 },
    { lat: 48.5726589, lng: 20.0373416 },
    { lat: 48.5730414, lng: 20.0373844 },
    { lat: 48.5740628, lng: 20.0378105 },
    { lat: 48.5744363, lng: 20.0381522 },
    { lat: 48.5748457, lng: 20.038327 },
    { lat: 48.5755022, lng: 20.0384005 },
    { lat: 48.5757036, lng: 20.0383466 },
    { lat: 48.5759309, lng: 20.0381772 },
    { lat: 48.5763956, lng: 20.0381625 },
    { lat: 48.5766298, lng: 20.037891 },
    { lat: 48.5772118, lng: 20.0375273 },
    { lat: 48.5776112, lng: 20.0382198 },
    { lat: 48.577902, lng: 20.0385264 },
    { lat: 48.5781479, lng: 20.0386967 },
    { lat: 48.5782593, lng: 20.0385819 },
    { lat: 48.578544, lng: 20.0386343 },
    { lat: 48.5784472, lng: 20.0388196 },
    { lat: 48.57843, lng: 20.0390602 },
    { lat: 48.5785451, lng: 20.0394143 },
    { lat: 48.5787045, lng: 20.0396728 },
    { lat: 48.5790041, lng: 20.0400547 },
    { lat: 48.5795948, lng: 20.0406086 },
    { lat: 48.5802362, lng: 20.0413769 },
    { lat: 48.580544, lng: 20.0415695 },
    { lat: 48.581119, lng: 20.0416668 },
    { lat: 48.581142, lng: 20.0414085 },
    { lat: 48.5814267, lng: 20.041456 },
    { lat: 48.5816676, lng: 20.0413446 },
    { lat: 48.5818094, lng: 20.0416397 },
    { lat: 48.5823345, lng: 20.0402618 },
    { lat: 48.5824039, lng: 20.0402794 },
    { lat: 48.5829441, lng: 20.0392611 },
    { lat: 48.5838908, lng: 20.0390625 },
    { lat: 48.584144, lng: 20.0392183 },
    { lat: 48.5843861, lng: 20.0392388 },
    { lat: 48.5853648, lng: 20.039917 },
    { lat: 48.5854991, lng: 20.0401099 },
    { lat: 48.5858417, lng: 20.0402216 },
    { lat: 48.585997, lng: 20.0403859 },
    { lat: 48.5861456, lng: 20.0403819 },
    { lat: 48.5862162, lng: 20.0400984 },
    { lat: 48.5865623, lng: 20.0394303 },
    { lat: 48.5865625, lng: 20.0391912 },
    { lat: 48.5867511, lng: 20.0387956 },
    { lat: 48.5867963, lng: 20.0384458 },
    { lat: 48.5872213, lng: 20.0374543 },
    { lat: 48.5874947, lng: 20.0372592 },
    { lat: 48.5877443, lng: 20.0372448 },
    { lat: 48.5881912, lng: 20.0363206 },
    { lat: 48.5882734, lng: 20.0350174 },
    { lat: 48.5881318, lng: 20.0344566 },
    { lat: 48.588262, lng: 20.0338753 },
    { lat: 48.588172, lng: 20.033421 },
    { lat: 48.5881895, lng: 20.0326512 },
    { lat: 48.5885681, lng: 20.0325474 },
    { lat: 48.5886956, lng: 20.0326044 },
    { lat: 48.5891908, lng: 20.0324077 },
    { lat: 48.5893855, lng: 20.0321573 },
    { lat: 48.5899497, lng: 20.0311825 },
    { lat: 48.5903914, lng: 20.0313428 },
    { lat: 48.5904836, lng: 20.0315852 },
    { lat: 48.5907832, lng: 20.0319373 },
    { lat: 48.5912601, lng: 20.0314307 },
    { lat: 48.591271, lng: 20.0316132 },
    { lat: 48.5914551, lng: 20.0318961 },
    { lat: 48.5918116, lng: 20.0313703 },
    { lat: 48.5921867, lng: 20.0316376 },
    { lat: 48.5925126, lng: 20.0311876 },
    { lat: 48.5927533, lng: 20.0302586 },
    { lat: 48.5935538, lng: 20.0286272 },
    { lat: 48.5936618, lng: 20.0281675 },
    { lat: 48.5935896, lng: 20.0267557 },
    { lat: 48.5933984, lng: 20.0260878 },
    { lat: 48.5937617, lng: 20.0251823 },
    { lat: 48.5940597, lng: 20.0255266 },
    { lat: 48.5943003, lng: 20.0252433 },
    { lat: 48.5944436, lng: 20.0243847 },
    { lat: 48.5949883, lng: 20.023885 },
    { lat: 48.5954258, lng: 20.0239215 },
    { lat: 48.5958019, lng: 20.0236791 },
    { lat: 48.5959217, lng: 20.0233383 },
    { lat: 48.5959306, lng: 20.0229791 },
    { lat: 48.5958203, lng: 20.0226889 },
    { lat: 48.5959182, lng: 20.0218508 },
    { lat: 48.5961435, lng: 20.0215533 },
    { lat: 48.5964557, lng: 20.0216084 },
    { lat: 48.5965884, lng: 20.0217399 },
    { lat: 48.5966438, lng: 20.0220439 },
    { lat: 48.5969298, lng: 20.0223648 },
    { lat: 48.5970796, lng: 20.0222823 },
    { lat: 48.5974021, lng: 20.0222603 },
    { lat: 48.597753, lng: 20.0224114 },
    { lat: 48.5984059, lng: 20.0224295 },
    { lat: 48.5986139, lng: 20.0225262 },
    { lat: 48.5986243, lng: 20.022132 },
    { lat: 48.5989646, lng: 20.0221058 },
    { lat: 48.599147, lng: 20.0220194 },
    { lat: 48.5991938, lng: 20.0216314 },
    { lat: 48.599229, lng: 20.0216369 },
    { lat: 48.5991297, lng: 20.0207179 },
    { lat: 48.5991273, lng: 20.0202533 },
    { lat: 48.5987675, lng: 20.0187 },
    { lat: 48.598948, lng: 20.0186038 },
    { lat: 48.5986856, lng: 20.0179035 },
    { lat: 48.599061, lng: 20.0178561 },
    { lat: 48.5993167, lng: 20.0179358 },
    { lat: 48.5996048, lng: 20.017849 },
    { lat: 48.5997525, lng: 20.0176891 },
    { lat: 48.6000303, lng: 20.017089 },
    { lat: 48.6010897, lng: 20.0167746 },
    { lat: 48.6013103, lng: 20.015855 },
    { lat: 48.6020645, lng: 20.0142801 },
    { lat: 48.60248, lng: 20.0136541 },
    { lat: 48.602524, lng: 20.0126342 },
    { lat: 48.6027303, lng: 20.012557 },
    { lat: 48.6027064, lng: 20.0123898 },
    { lat: 48.6030108, lng: 20.0122075 },
    { lat: 48.6030967, lng: 20.011941 },
    { lat: 48.603747, lng: 20.011655 },
    { lat: 48.6042305, lng: 20.0109616 },
    { lat: 48.6041561, lng: 20.0100177 },
    { lat: 48.603882, lng: 20.0095483 },
    { lat: 48.6038308, lng: 20.0093514 },
    { lat: 48.6035, lng: 20.0099519 },
    { lat: 48.6032192, lng: 20.0103214 },
    { lat: 48.6031061, lng: 20.0103097 },
    { lat: 48.602367, lng: 20.0098215 },
    { lat: 48.60185, lng: 20.0096351 },
    { lat: 48.6017955, lng: 20.0078465 },
    { lat: 48.6015042, lng: 20.0080714 },
    { lat: 48.6011484, lng: 20.0080184 },
    { lat: 48.6009232, lng: 20.0078316 },
    { lat: 48.6007073, lng: 20.0074105 },
    { lat: 48.6003978, lng: 20.0069876 },
    { lat: 48.5994283, lng: 20.0066748 },
  ],
  ŠpaniePole: [
    { lat: 48.518909, lng: 20.107937 },
    { lat: 48.5193573, lng: 20.108681 },
    { lat: 48.5194995, lng: 20.1087971 },
    { lat: 48.5201791, lng: 20.108892 },
    { lat: 48.5196643, lng: 20.1103195 },
    { lat: 48.5194532, lng: 20.1112134 },
    { lat: 48.5194455, lng: 20.1117677 },
    { lat: 48.5191207, lng: 20.1129938 },
    { lat: 48.5189536, lng: 20.114164 },
    { lat: 48.5189218, lng: 20.1154196 },
    { lat: 48.5192565, lng: 20.1166662 },
    { lat: 48.5194929, lng: 20.117983 },
    { lat: 48.5195914, lng: 20.1188727 },
    { lat: 48.5182195, lng: 20.1204957 },
    { lat: 48.517557, lng: 20.1211238 },
    { lat: 48.5173254, lng: 20.121493 },
    { lat: 48.516934, lng: 20.121784 },
    { lat: 48.5163026, lng: 20.1219047 },
    { lat: 48.5157185, lng: 20.1216402 },
    { lat: 48.5150388, lng: 20.1216826 },
    { lat: 48.5148175, lng: 20.1217781 },
    { lat: 48.5147316, lng: 20.1218816 },
    { lat: 48.5146196, lng: 20.1223108 },
    { lat: 48.5144545, lng: 20.1225968 },
    { lat: 48.5141709, lng: 20.1228238 },
    { lat: 48.513346, lng: 20.1232793 },
    { lat: 48.5126854, lng: 20.1240648 },
    { lat: 48.5119092, lng: 20.1242589 },
    { lat: 48.5111493, lng: 20.1245947 },
    { lat: 48.5110809, lng: 20.1247985 },
    { lat: 48.511049, lng: 20.1251436 },
    { lat: 48.510506, lng: 20.1278992 },
    { lat: 48.5104441, lng: 20.1281465 },
    { lat: 48.5101851, lng: 20.128698 },
    { lat: 48.5095071, lng: 20.1294754 },
    { lat: 48.5090268, lng: 20.1299308 },
    { lat: 48.5074138, lng: 20.1309776 },
    { lat: 48.5070239, lng: 20.1313593 },
    { lat: 48.5064814, lng: 20.1323614 },
    { lat: 48.5056649, lng: 20.133023 },
    { lat: 48.5051199, lng: 20.133637 },
    { lat: 48.504939, lng: 20.1338644 },
    { lat: 48.5046287, lng: 20.1344841 },
    { lat: 48.5041394, lng: 20.1366529 },
    { lat: 48.5043861, lng: 20.1373497 },
    { lat: 48.5046128, lng: 20.1387633 },
    { lat: 48.5049729, lng: 20.1394155 },
    { lat: 48.5056151, lng: 20.1408278 },
    { lat: 48.5060779, lng: 20.1414368 },
    { lat: 48.5064122, lng: 20.1420929 },
    { lat: 48.5065181, lng: 20.1428145 },
    { lat: 48.5068728, lng: 20.1444409 },
    { lat: 48.5072988, lng: 20.1444463 },
    { lat: 48.5089776, lng: 20.1447128 },
    { lat: 48.5096105, lng: 20.1446523 },
    { lat: 48.5098164, lng: 20.1448692 },
    { lat: 48.5099232, lng: 20.1448661 },
    { lat: 48.5101966, lng: 20.1450781 },
    { lat: 48.510609, lng: 20.145087 },
    { lat: 48.510935, lng: 20.144938 },
    { lat: 48.511231, lng: 20.144803 },
    { lat: 48.511478, lng: 20.144692 },
    { lat: 48.512023, lng: 20.144741 },
    { lat: 48.512516, lng: 20.14482 },
    { lat: 48.513045, lng: 20.145147 },
    { lat: 48.51382, lng: 20.145392 },
    { lat: 48.514539, lng: 20.145748 },
    { lat: 48.514576, lng: 20.145768 },
    { lat: 48.5146, lng: 20.145779 },
    { lat: 48.514719, lng: 20.145751 },
    { lat: 48.514886, lng: 20.145713 },
    { lat: 48.514942, lng: 20.145698 },
    { lat: 48.51624, lng: 20.145331 },
    { lat: 48.516992, lng: 20.145263 },
    { lat: 48.51703, lng: 20.145259 },
    { lat: 48.517616, lng: 20.145206 },
    { lat: 48.518569, lng: 20.145125 },
    { lat: 48.518727, lng: 20.145108 },
    { lat: 48.518782, lng: 20.145103 },
    { lat: 48.519201, lng: 20.14506 },
    { lat: 48.519461, lng: 20.145033 },
    { lat: 48.519696, lng: 20.145023 },
    { lat: 48.519967, lng: 20.145072 },
    { lat: 48.519994, lng: 20.145296 },
    { lat: 48.520338, lng: 20.145687 },
    { lat: 48.520526, lng: 20.145902 },
    { lat: 48.520609, lng: 20.145996 },
    { lat: 48.521186, lng: 20.146646 },
    { lat: 48.52121, lng: 20.146672 },
    { lat: 48.521453, lng: 20.14693 },
    { lat: 48.521596, lng: 20.147068 },
    { lat: 48.522189, lng: 20.147556 },
    { lat: 48.522443, lng: 20.147889 },
    { lat: 48.522477, lng: 20.147931 },
    { lat: 48.522504, lng: 20.147966 },
    { lat: 48.522536, lng: 20.148007 },
    { lat: 48.522697, lng: 20.148219 },
    { lat: 48.523013, lng: 20.148459 },
    { lat: 48.523474, lng: 20.148818 },
    { lat: 48.524065, lng: 20.149283 },
    { lat: 48.524083, lng: 20.149298 },
    { lat: 48.524128, lng: 20.149333 },
    { lat: 48.524525, lng: 20.149631 },
    { lat: 48.525339, lng: 20.15024 },
    { lat: 48.52563, lng: 20.150603 },
    { lat: 48.525651, lng: 20.15063 },
    { lat: 48.525684, lng: 20.150671 },
    { lat: 48.525706, lng: 20.150697 },
    { lat: 48.525727, lng: 20.150724 },
    { lat: 48.525748, lng: 20.150751 },
    { lat: 48.525798, lng: 20.150813 },
    { lat: 48.525849, lng: 20.150878 },
    { lat: 48.525898, lng: 20.150937 },
    { lat: 48.525929, lng: 20.150976 },
    { lat: 48.52596, lng: 20.151015 },
    { lat: 48.525981, lng: 20.151041 },
    { lat: 48.526026, lng: 20.151097 },
    { lat: 48.526059, lng: 20.151138 },
    { lat: 48.526075, lng: 20.151153 },
    { lat: 48.526094, lng: 20.15117 },
    { lat: 48.526114, lng: 20.151188 },
    { lat: 48.526139, lng: 20.151209 },
    { lat: 48.52616, lng: 20.151228 },
    { lat: 48.526204, lng: 20.151268 },
    { lat: 48.526253, lng: 20.151312 },
    { lat: 48.526278, lng: 20.151333 },
    { lat: 48.526321, lng: 20.151372 },
    { lat: 48.526329, lng: 20.15138 },
    { lat: 48.526344, lng: 20.151393 },
    { lat: 48.526361, lng: 20.151408 },
    { lat: 48.526399, lng: 20.151442 },
    { lat: 48.526437, lng: 20.151475 },
    { lat: 48.526461, lng: 20.151497 },
    { lat: 48.526511, lng: 20.151541 },
    { lat: 48.526554, lng: 20.151579 },
    { lat: 48.526582, lng: 20.151605 },
    { lat: 48.526611, lng: 20.15163 },
    { lat: 48.52664, lng: 20.151657 },
    { lat: 48.526674, lng: 20.151687 },
    { lat: 48.526714, lng: 20.151723 },
    { lat: 48.526741, lng: 20.151741 },
    { lat: 48.526771, lng: 20.151759 },
    { lat: 48.526795, lng: 20.151776 },
    { lat: 48.526843, lng: 20.151809 },
    { lat: 48.526918, lng: 20.151858 },
    { lat: 48.526953, lng: 20.151883 },
    { lat: 48.527008, lng: 20.151919 },
    { lat: 48.527051, lng: 20.151947 },
    { lat: 48.527156, lng: 20.152018 },
    { lat: 48.5272, lng: 20.152046 },
    { lat: 48.527252, lng: 20.152082 },
    { lat: 48.527302, lng: 20.152115 },
    { lat: 48.527317, lng: 20.152125 },
    { lat: 48.527347, lng: 20.152145 },
    { lat: 48.527379, lng: 20.152167 },
    { lat: 48.527412, lng: 20.152189 },
    { lat: 48.527458, lng: 20.15222 },
    { lat: 48.527484, lng: 20.152236 },
    { lat: 48.527538, lng: 20.152272 },
    { lat: 48.52757, lng: 20.152294 },
    { lat: 48.527722, lng: 20.152393 },
    { lat: 48.52787, lng: 20.152491 },
    { lat: 48.528063, lng: 20.152619 },
    { lat: 48.528114, lng: 20.152652 },
    { lat: 48.528209, lng: 20.152715 },
    { lat: 48.528311, lng: 20.152784 },
    { lat: 48.528459, lng: 20.152885 },
    { lat: 48.528682, lng: 20.153036 },
    { lat: 48.529247, lng: 20.153398 },
    { lat: 48.530149, lng: 20.154061 },
    { lat: 48.530494, lng: 20.154794 },
    { lat: 48.530952, lng: 20.155347 },
    { lat: 48.531619, lng: 20.15588 },
    { lat: 48.532185, lng: 20.156588 },
    { lat: 48.532782, lng: 20.157168 },
    { lat: 48.53284, lng: 20.157228 },
    { lat: 48.532854, lng: 20.157242 },
    { lat: 48.533533, lng: 20.157915 },
    { lat: 48.533584, lng: 20.157754 },
    { lat: 48.533634, lng: 20.157379 },
    { lat: 48.533684, lng: 20.157155 },
    { lat: 48.533709, lng: 20.157004 },
    { lat: 48.533606, lng: 20.156803 },
    { lat: 48.533557, lng: 20.156752 },
    { lat: 48.533473, lng: 20.156605 },
    { lat: 48.533578, lng: 20.156248 },
    { lat: 48.533634, lng: 20.155991 },
    { lat: 48.533659, lng: 20.155846 },
    { lat: 48.533696, lng: 20.155475 },
    { lat: 48.533772, lng: 20.155089 },
    { lat: 48.53372, lng: 20.154883 },
    { lat: 48.533701, lng: 20.154766 },
    { lat: 48.533809, lng: 20.154726 },
    { lat: 48.53388, lng: 20.15466 },
    { lat: 48.534219, lng: 20.15382 },
    { lat: 48.534204, lng: 20.153667 },
    { lat: 48.534148, lng: 20.153576 },
    { lat: 48.534124, lng: 20.153398 },
    { lat: 48.534149, lng: 20.153102 },
    { lat: 48.534193, lng: 20.152964 },
    { lat: 48.534294, lng: 20.152864 },
    { lat: 48.534388, lng: 20.152838 },
    { lat: 48.534518, lng: 20.152868 },
    { lat: 48.534676, lng: 20.152901 },
    { lat: 48.534731, lng: 20.15289 },
    { lat: 48.534767, lng: 20.152882 },
    { lat: 48.534784, lng: 20.152881 },
    { lat: 48.534971, lng: 20.152969 },
    { lat: 48.535037, lng: 20.153027 },
    { lat: 48.535099, lng: 20.153353 },
    { lat: 48.535135, lng: 20.153399 },
    { lat: 48.535276, lng: 20.15322 },
    { lat: 48.535372, lng: 20.153057 },
    { lat: 48.535507, lng: 20.152853 },
    { lat: 48.535576, lng: 20.152754 },
    { lat: 48.535624, lng: 20.152658 },
    { lat: 48.535639, lng: 20.152534 },
    { lat: 48.535629, lng: 20.152436 },
    { lat: 48.535654, lng: 20.152274 },
    { lat: 48.535741, lng: 20.152177 },
    { lat: 48.535777, lng: 20.152141 },
    { lat: 48.535796, lng: 20.152096 },
    { lat: 48.535795, lng: 20.152045 },
    { lat: 48.535805, lng: 20.151939 },
    { lat: 48.535805, lng: 20.151849 },
    { lat: 48.535782, lng: 20.151588 },
    { lat: 48.53579, lng: 20.151303 },
    { lat: 48.535741, lng: 20.150907 },
    { lat: 48.535789, lng: 20.150901 },
    { lat: 48.535891, lng: 20.150693 },
    { lat: 48.536038, lng: 20.150709 },
    { lat: 48.536146, lng: 20.150762 },
    { lat: 48.536265, lng: 20.151082 },
    { lat: 48.536368, lng: 20.151131 },
    { lat: 48.53643, lng: 20.1511 },
    { lat: 48.536512, lng: 20.15092 },
    { lat: 48.536608, lng: 20.150744 },
    { lat: 48.536694, lng: 20.150708 },
    { lat: 48.536716, lng: 20.150573 },
    { lat: 48.536789, lng: 20.150473 },
    { lat: 48.536859, lng: 20.150163 },
    { lat: 48.536784, lng: 20.149588 },
    { lat: 48.53681, lng: 20.149473 },
    { lat: 48.536889, lng: 20.149333 },
    { lat: 48.53708, lng: 20.149275 },
    { lat: 48.537226, lng: 20.149135 },
    { lat: 48.537315, lng: 20.148978 },
    { lat: 48.53745, lng: 20.148825 },
    { lat: 48.537528, lng: 20.148659 },
    { lat: 48.537595, lng: 20.148586 },
    { lat: 48.537889, lng: 20.148613 },
    { lat: 48.537913, lng: 20.14848 },
    { lat: 48.53806, lng: 20.14806 },
    { lat: 48.538146, lng: 20.147736 },
    { lat: 48.538164, lng: 20.147608 },
    { lat: 48.538174, lng: 20.147343 },
    { lat: 48.538214, lng: 20.147054 },
    { lat: 48.538197, lng: 20.146976 },
    { lat: 48.53845, lng: 20.146823 },
    { lat: 48.538635, lng: 20.146867 },
    { lat: 48.538753, lng: 20.146934 },
    { lat: 48.539112, lng: 20.146971 },
    { lat: 48.539104, lng: 20.14709 },
    { lat: 48.539339, lng: 20.147073 },
    { lat: 48.53939, lng: 20.14706 },
    { lat: 48.539749, lng: 20.146939 },
    { lat: 48.539784, lng: 20.146307 },
    { lat: 48.539797, lng: 20.146102 },
    { lat: 48.539748, lng: 20.146036 },
    { lat: 48.539757, lng: 20.145972 },
    { lat: 48.53971, lng: 20.145555 },
    { lat: 48.539616, lng: 20.145029 },
    { lat: 48.53959, lng: 20.144667 },
    { lat: 48.539559, lng: 20.144408 },
    { lat: 48.53959, lng: 20.144205 },
    { lat: 48.539643, lng: 20.14414 },
    { lat: 48.539796, lng: 20.144033 },
    { lat: 48.53996, lng: 20.143817 },
    { lat: 48.539928, lng: 20.143747 },
    { lat: 48.539963, lng: 20.142974 },
    { lat: 48.540294, lng: 20.142989 },
    { lat: 48.540537, lng: 20.142678 },
    { lat: 48.540691, lng: 20.142428 },
    { lat: 48.54078, lng: 20.141908 },
    { lat: 48.540877, lng: 20.141528 },
    { lat: 48.540926, lng: 20.141317 },
    { lat: 48.540901, lng: 20.140563 },
    { lat: 48.540941, lng: 20.14059 },
    { lat: 48.541053, lng: 20.140568 },
    { lat: 48.541343, lng: 20.140062 },
    { lat: 48.541485, lng: 20.139867 },
    { lat: 48.541573, lng: 20.139711 },
    { lat: 48.541709, lng: 20.139308 },
    { lat: 48.541766, lng: 20.139026 },
    { lat: 48.541784, lng: 20.138887 },
    { lat: 48.54131, lng: 20.138682 },
    { lat: 48.541245, lng: 20.138523 },
    { lat: 48.541234, lng: 20.138381 },
    { lat: 48.541402, lng: 20.138086 },
    { lat: 48.54143, lng: 20.137964 },
    { lat: 48.541322, lng: 20.137834 },
    { lat: 48.541373, lng: 20.137654 },
    { lat: 48.541455, lng: 20.137476 },
    { lat: 48.541555, lng: 20.137306 },
    { lat: 48.541577, lng: 20.137196 },
    { lat: 48.542482, lng: 20.136318 },
    { lat: 48.542594, lng: 20.136446 },
    { lat: 48.54263, lng: 20.136459 },
    { lat: 48.542833, lng: 20.136357 },
    { lat: 48.542923, lng: 20.136377 },
    { lat: 48.543038, lng: 20.136497 },
    { lat: 48.543097, lng: 20.136593 },
    { lat: 48.543137, lng: 20.136571 },
    { lat: 48.54317, lng: 20.136464 },
    { lat: 48.543242, lng: 20.136374 },
    { lat: 48.543328, lng: 20.13636 },
    { lat: 48.543398, lng: 20.136224 },
    { lat: 48.543459, lng: 20.136175 },
    { lat: 48.543603, lng: 20.136261 },
    { lat: 48.543667, lng: 20.136309 },
    { lat: 48.543684, lng: 20.13636 },
    { lat: 48.543978, lng: 20.136168 },
    { lat: 48.544102, lng: 20.136126 },
    { lat: 48.544187, lng: 20.135984 },
    { lat: 48.544149, lng: 20.135974 },
    { lat: 48.544132, lng: 20.135385 },
    { lat: 48.544104, lng: 20.135249 },
    { lat: 48.544064, lng: 20.134962 },
    { lat: 48.544042, lng: 20.134908 },
    { lat: 48.544002, lng: 20.134865 },
    { lat: 48.543953, lng: 20.134746 },
    { lat: 48.543921, lng: 20.13441 },
    { lat: 48.544089, lng: 20.134135 },
    { lat: 48.544013, lng: 20.133756 },
    { lat: 48.543846, lng: 20.133357 },
    { lat: 48.543809, lng: 20.133215 },
    { lat: 48.543732, lng: 20.133076 },
    { lat: 48.543676, lng: 20.132827 },
    { lat: 48.543403, lng: 20.132328 },
    { lat: 48.54337, lng: 20.132127 },
    { lat: 48.543296, lng: 20.131847 },
    { lat: 48.543198, lng: 20.131567 },
    { lat: 48.543117, lng: 20.131498 },
    { lat: 48.542979, lng: 20.131462 },
    { lat: 48.542959, lng: 20.131468 },
    { lat: 48.542867, lng: 20.131196 },
    { lat: 48.542766, lng: 20.131044 },
    { lat: 48.542837, lng: 20.130942 },
    { lat: 48.542844, lng: 20.130885 },
    { lat: 48.542833, lng: 20.130808 },
    { lat: 48.542745, lng: 20.130742 },
    { lat: 48.54267, lng: 20.130732 },
    { lat: 48.542647, lng: 20.130627 },
    { lat: 48.542673, lng: 20.130465 },
    { lat: 48.542685, lng: 20.130269 },
    { lat: 48.542642, lng: 20.13019 },
    { lat: 48.542583, lng: 20.130177 },
    { lat: 48.542575, lng: 20.130142 },
    { lat: 48.542592, lng: 20.130136 },
    { lat: 48.542922, lng: 20.129952 },
    { lat: 48.543026, lng: 20.129686 },
    { lat: 48.543041, lng: 20.12965 },
    { lat: 48.543066, lng: 20.129577 },
    { lat: 48.54303, lng: 20.129542 },
    { lat: 48.543002, lng: 20.129513 },
    { lat: 48.542971, lng: 20.129376 },
    { lat: 48.543002, lng: 20.129272 },
    { lat: 48.543021, lng: 20.128972 },
    { lat: 48.54299, lng: 20.128997 },
    { lat: 48.542649, lng: 20.129137 },
    { lat: 48.542467, lng: 20.12841 },
    { lat: 48.542398, lng: 20.128059 },
    { lat: 48.542194, lng: 20.127863 },
    { lat: 48.542167, lng: 20.127838 },
    { lat: 48.542146, lng: 20.12782 },
    { lat: 48.541044, lng: 20.126984 },
    { lat: 48.540975, lng: 20.126936 },
    { lat: 48.540843, lng: 20.125768 },
    { lat: 48.540666, lng: 20.124425 },
    { lat: 48.540384, lng: 20.122718 },
    { lat: 48.540167, lng: 20.121433 },
    { lat: 48.540053, lng: 20.120754 },
    { lat: 48.539989, lng: 20.120372 },
    { lat: 48.539853, lng: 20.120161 },
    { lat: 48.539523, lng: 20.119668 },
    { lat: 48.539339, lng: 20.119709 },
    { lat: 48.539214, lng: 20.119736 },
    { lat: 48.538484, lng: 20.11957 },
    { lat: 48.538201, lng: 20.119342 },
    { lat: 48.538268, lng: 20.119119 },
    { lat: 48.53828, lng: 20.119086 },
    { lat: 48.5382, lng: 20.119043 },
    { lat: 48.538124, lng: 20.118937 },
    { lat: 48.537977, lng: 20.118794 },
    { lat: 48.537869, lng: 20.118714 },
    { lat: 48.537752, lng: 20.118647 },
    { lat: 48.537693, lng: 20.118489 },
    { lat: 48.537532, lng: 20.118323 },
    { lat: 48.537375, lng: 20.118079 },
    { lat: 48.537257, lng: 20.118015 },
    { lat: 48.53717, lng: 20.117993 },
    { lat: 48.536971, lng: 20.117926 },
    { lat: 48.536857, lng: 20.11795 },
    { lat: 48.536791, lng: 20.117945 },
    { lat: 48.536721, lng: 20.117975 },
    { lat: 48.536511, lng: 20.117924 },
    { lat: 48.536287, lng: 20.117699 },
    { lat: 48.536154, lng: 20.117535 },
    { lat: 48.536159, lng: 20.117525 },
    { lat: 48.536166, lng: 20.117509 },
    { lat: 48.536394, lng: 20.117005 },
    { lat: 48.536525, lng: 20.116715 },
    { lat: 48.53654, lng: 20.116683 },
    { lat: 48.536917, lng: 20.115848 },
    { lat: 48.536944, lng: 20.115788 },
    { lat: 48.536858, lng: 20.115743 },
    { lat: 48.536825, lng: 20.115725 },
    { lat: 48.53681, lng: 20.115718 },
    { lat: 48.536776, lng: 20.115704 },
    { lat: 48.536717, lng: 20.11567 },
    { lat: 48.536671, lng: 20.11563 },
    { lat: 48.536484, lng: 20.115456 },
    { lat: 48.536406, lng: 20.115628 },
    { lat: 48.536392, lng: 20.115662 },
    { lat: 48.535963, lng: 20.115036 },
    { lat: 48.535878, lng: 20.114893 },
    { lat: 48.535779, lng: 20.114706 },
    { lat: 48.535708, lng: 20.114619 },
    { lat: 48.535625, lng: 20.114572 },
    { lat: 48.535649, lng: 20.114542 },
    { lat: 48.535648, lng: 20.114517 },
    { lat: 48.535643, lng: 20.114421 },
    { lat: 48.53564, lng: 20.114366 },
    { lat: 48.535433, lng: 20.112975 },
    { lat: 48.535241, lng: 20.112435 },
    { lat: 48.534822, lng: 20.111806 },
    { lat: 48.534492, lng: 20.111327 },
    { lat: 48.53432, lng: 20.111077 },
    { lat: 48.534271, lng: 20.111007 },
    { lat: 48.533972, lng: 20.110745 },
    { lat: 48.533861, lng: 20.110708 },
    { lat: 48.533726, lng: 20.110664 },
    { lat: 48.533504, lng: 20.1105 },
    { lat: 48.533476, lng: 20.1105 },
    { lat: 48.53344, lng: 20.110421 },
    { lat: 48.533393, lng: 20.110388 },
    { lat: 48.533327, lng: 20.110297 },
    { lat: 48.533102, lng: 20.109951 },
    { lat: 48.533085, lng: 20.109977 },
    { lat: 48.532489, lng: 20.111069 },
    { lat: 48.532162, lng: 20.111212 },
    { lat: 48.532141, lng: 20.111249 },
    { lat: 48.53211, lng: 20.111305 },
    { lat: 48.532087, lng: 20.111348 },
    { lat: 48.531742, lng: 20.11197 },
    { lat: 48.531333, lng: 20.112594 },
    { lat: 48.530908, lng: 20.113076 },
    { lat: 48.530886, lng: 20.113068 },
    { lat: 48.530828, lng: 20.113047 },
    { lat: 48.530802, lng: 20.113038 },
    { lat: 48.530404, lng: 20.112719 },
    { lat: 48.530299, lng: 20.112659 },
    { lat: 48.529794, lng: 20.112696 },
    { lat: 48.52928, lng: 20.112715 },
    { lat: 48.529056, lng: 20.112596 },
    { lat: 48.529028, lng: 20.112503 },
    { lat: 48.528885, lng: 20.112299 },
    { lat: 48.528744, lng: 20.112162 },
    { lat: 48.528569, lng: 20.111872 },
    { lat: 48.528383, lng: 20.111361 },
    { lat: 48.528291, lng: 20.111211 },
    { lat: 48.527963, lng: 20.111217 },
    { lat: 48.52782, lng: 20.110861 },
    { lat: 48.527695, lng: 20.1107 },
    { lat: 48.527586, lng: 20.110631 },
    { lat: 48.526842, lng: 20.11024 },
    { lat: 48.526195, lng: 20.109833 },
    { lat: 48.525802, lng: 20.109906 },
    { lat: 48.525302, lng: 20.11016 },
    { lat: 48.524801, lng: 20.109722 },
    { lat: 48.524427, lng: 20.109456 },
    { lat: 48.524358, lng: 20.109264 },
    { lat: 48.524024, lng: 20.108738 },
    { lat: 48.523932, lng: 20.108783 },
    { lat: 48.523813, lng: 20.108702 },
    { lat: 48.523544, lng: 20.108644 },
    { lat: 48.523094, lng: 20.108725 },
    { lat: 48.522849, lng: 20.10884 },
    { lat: 48.522699, lng: 20.108856 },
    { lat: 48.522351, lng: 20.108751 },
    { lat: 48.52201, lng: 20.108475 },
    { lat: 48.521714, lng: 20.10813 },
    { lat: 48.521559, lng: 20.107689 },
    { lat: 48.52132, lng: 20.107403 },
    { lat: 48.520972, lng: 20.107109 },
    { lat: 48.520715, lng: 20.106511 },
    { lat: 48.520699, lng: 20.106533 },
    { lat: 48.520656, lng: 20.10659 },
    { lat: 48.520638, lng: 20.106613 },
    { lat: 48.52023, lng: 20.106806 },
    { lat: 48.520196, lng: 20.106822 },
    { lat: 48.520176, lng: 20.106832 },
    { lat: 48.519948, lng: 20.107003 },
    { lat: 48.518909, lng: 20.107937 },
  ],
  Hodejov: [
    { lat: 48.3188123, lng: 19.9299352 },
    { lat: 48.317788, lng: 19.9307967 },
    { lat: 48.311924, lng: 19.9283934 },
    { lat: 48.3114535, lng: 19.9306104 },
    { lat: 48.3112279, lng: 19.931187 },
    { lat: 48.3111118, lng: 19.9313712 },
    { lat: 48.3107705, lng: 19.9316591 },
    { lat: 48.3102794, lng: 19.9322159 },
    { lat: 48.3096053, lng: 19.9327866 },
    { lat: 48.3089786, lng: 19.9329869 },
    { lat: 48.3084318, lng: 19.9333229 },
    { lat: 48.3079981, lng: 19.9332934 },
    { lat: 48.3075171, lng: 19.9336382 },
    { lat: 48.3072147, lng: 19.9340247 },
    { lat: 48.3070169, lng: 19.9344025 },
    { lat: 48.3066926, lng: 19.9349046 },
    { lat: 48.3064945, lng: 19.935121 },
    { lat: 48.3061692, lng: 19.9357117 },
    { lat: 48.3057174, lng: 19.9363428 },
    { lat: 48.3049113, lng: 19.938649 },
    { lat: 48.3048557, lng: 19.9386136 },
    { lat: 48.3045166, lng: 19.9398768 },
    { lat: 48.3040415, lng: 19.9410182 },
    { lat: 48.3039501, lng: 19.9415994 },
    { lat: 48.30406, lng: 19.9430131 },
    { lat: 48.3042831, lng: 19.9438823 },
    { lat: 48.3043317, lng: 19.9442573 },
    { lat: 48.304335, lng: 19.9457647 },
    { lat: 48.3035075, lng: 19.946725 },
    { lat: 48.2998142, lng: 19.9585188 },
    { lat: 48.2971208, lng: 19.967722 },
    { lat: 48.2967989, lng: 19.9686319 },
    { lat: 48.2954061, lng: 19.9731616 },
    { lat: 48.2953092, lng: 19.9697364 },
    { lat: 48.2951299, lng: 19.9691192 },
    { lat: 48.294877, lng: 19.968602 },
    { lat: 48.2942323, lng: 19.9676665 },
    { lat: 48.2933581, lng: 19.9666302 },
    { lat: 48.2930938, lng: 19.9666158 },
    { lat: 48.291889, lng: 19.9652499 },
    { lat: 48.2915997, lng: 19.9647224 },
    { lat: 48.291211, lng: 19.9641645 },
    { lat: 48.2908949, lng: 19.9634581 },
    { lat: 48.2908319, lng: 19.9634857 },
    { lat: 48.2885127, lng: 19.9590954 },
    { lat: 48.2881536, lng: 19.9593674 },
    { lat: 48.2868546, lng: 19.960317 },
    { lat: 48.2859865, lng: 19.96112 },
    { lat: 48.2858013, lng: 19.9615118 },
    { lat: 48.2854137, lng: 19.9620398 },
    { lat: 48.2850781, lng: 19.9623579 },
    { lat: 48.2849042, lng: 19.9627703 },
    { lat: 48.2847824, lng: 19.9632208 },
    { lat: 48.2844149, lng: 19.9637161 },
    { lat: 48.2838204, lng: 19.9647033 },
    { lat: 48.2833102, lng: 19.9657186 },
    { lat: 48.2831837, lng: 19.9661077 },
    { lat: 48.2829461, lng: 19.966455 },
    { lat: 48.282482, lng: 19.9673841 },
    { lat: 48.2820919, lng: 19.9679026 },
    { lat: 48.2821641, lng: 19.9679874 },
    { lat: 48.2827723, lng: 19.9685392 },
    { lat: 48.2832831, lng: 19.969217 },
    { lat: 48.2844865, lng: 19.9702854 },
    { lat: 48.2854207, lng: 19.9715674 },
    { lat: 48.2856022, lng: 19.9719173 },
    { lat: 48.2859162, lng: 19.9727094 },
    { lat: 48.2860817, lng: 19.9733147 },
    { lat: 48.2863736, lng: 19.9737973 },
    { lat: 48.2865042, lng: 19.9744064 },
    { lat: 48.2868168, lng: 19.9754544 },
    { lat: 48.2868866, lng: 19.9764116 },
    { lat: 48.2872719, lng: 19.9774492 },
    { lat: 48.2880396, lng: 19.9788982 },
    { lat: 48.2882758, lng: 19.9797169 },
    { lat: 48.2885496, lng: 19.9803275 },
    { lat: 48.2885732, lng: 19.9805393 },
    { lat: 48.2883565, lng: 19.9813264 },
    { lat: 48.2882289, lng: 19.9821988 },
    { lat: 48.2883434, lng: 19.9834945 },
    { lat: 48.2884014, lng: 19.983772 },
    { lat: 48.2887059, lng: 19.9843691 },
    { lat: 48.2887475, lng: 19.9847376 },
    { lat: 48.2886582, lng: 19.98545 },
    { lat: 48.289034, lng: 19.9876502 },
    { lat: 48.2890804, lng: 19.9884572 },
    { lat: 48.2890383, lng: 19.9900699 },
    { lat: 48.2890609, lng: 19.9924207 },
    { lat: 48.2886375, lng: 19.9935984 },
    { lat: 48.288095, lng: 19.9939834 },
    { lat: 48.2874825, lng: 19.9940962 },
    { lat: 48.2871813, lng: 19.994302 },
    { lat: 48.2868622, lng: 19.9945342 },
    { lat: 48.2865284, lng: 19.9951144 },
    { lat: 48.2863538, lng: 19.9957818 },
    { lat: 48.2863499, lng: 19.9960354 },
    { lat: 48.2861055, lng: 19.9973152 },
    { lat: 48.286165, lng: 19.9988337 },
    { lat: 48.2861215, lng: 19.9994069 },
    { lat: 48.2858769, lng: 20.0011928 },
    { lat: 48.2857133, lng: 20.0017459 },
    { lat: 48.2856613, lng: 20.0025746 },
    { lat: 48.2857209, lng: 20.0025714 },
    { lat: 48.2858217, lng: 20.0025332 },
    { lat: 48.2861711, lng: 20.0021578 },
    { lat: 48.2865446, lng: 20.0016669 },
    { lat: 48.2868872, lng: 20.0014279 },
    { lat: 48.2872402, lng: 20.0013563 },
    { lat: 48.2873201, lng: 20.0014093 },
    { lat: 48.2878531, lng: 20.0011197 },
    { lat: 48.2882471, lng: 20.0004486 },
    { lat: 48.2884934, lng: 20.0003538 },
    { lat: 48.2889386, lng: 20.0011104 },
    { lat: 48.2893046, lng: 20.0020425 },
    { lat: 48.2895047, lng: 20.0024349 },
    { lat: 48.2894574, lng: 20.0027011 },
    { lat: 48.2897473, lng: 20.003541 },
    { lat: 48.2899974, lng: 20.0038633 },
    { lat: 48.2901901, lng: 20.0048305 },
    { lat: 48.2901241, lng: 20.0054533 },
    { lat: 48.2896503, lng: 20.0065062 },
    { lat: 48.2895043, lng: 20.0066792 },
    { lat: 48.2889631, lng: 20.0082453 },
    { lat: 48.2888354, lng: 20.0084509 },
    { lat: 48.288454, lng: 20.0093736 },
    { lat: 48.288409, lng: 20.0098526 },
    { lat: 48.2884844, lng: 20.0108426 },
    { lat: 48.2884072, lng: 20.0121936 },
    { lat: 48.2881622, lng: 20.0130147 },
    { lat: 48.2880229, lng: 20.0132896 },
    { lat: 48.2878855, lng: 20.013317 },
    { lat: 48.2875902, lng: 20.0139844 },
    { lat: 48.2875021, lng: 20.0143828 },
    { lat: 48.2874075, lng: 20.0145407 },
    { lat: 48.2863989, lng: 20.0151993 },
    { lat: 48.2868713, lng: 20.0152389 },
    { lat: 48.288081, lng: 20.0152326 },
    { lat: 48.2894553, lng: 20.0150485 },
    { lat: 48.2900405, lng: 20.0150884 },
    { lat: 48.2928899, lng: 20.0157732 },
    { lat: 48.2929047, lng: 20.0163912 },
    { lat: 48.2939508, lng: 20.0166746 },
    { lat: 48.2946392, lng: 20.0165764 },
    { lat: 48.2952167, lng: 20.0166471 },
    { lat: 48.2957583, lng: 20.0168801 },
    { lat: 48.2968037, lng: 20.0179008 },
    { lat: 48.2972425, lng: 20.0180756 },
    { lat: 48.2976896, lng: 20.0184512 },
    { lat: 48.2987021, lng: 20.0190318 },
    { lat: 48.2996683, lng: 20.0192428 },
    { lat: 48.2996665, lng: 20.0198175 },
    { lat: 48.299928, lng: 20.0202493 },
    { lat: 48.3001139, lng: 20.0204394 },
    { lat: 48.3006506, lng: 20.0204536 },
    { lat: 48.3009212, lng: 20.0214806 },
    { lat: 48.3010192, lng: 20.0220431 },
    { lat: 48.3031092, lng: 20.0215422 },
    { lat: 48.3044784, lng: 20.0209093 },
    { lat: 48.3047682, lng: 20.0213971 },
    { lat: 48.3072768, lng: 20.0207293 },
    { lat: 48.308191, lng: 20.0206056 },
    { lat: 48.3081903, lng: 20.0208881 },
    { lat: 48.3084774, lng: 20.021126 },
    { lat: 48.3093612, lng: 20.0213279 },
    { lat: 48.3117612, lng: 20.0211009 },
    { lat: 48.3129298, lng: 20.0203892 },
    { lat: 48.3140455, lng: 20.0195939 },
    { lat: 48.314076, lng: 20.0195712 },
    { lat: 48.3138584, lng: 20.0189777 },
    { lat: 48.3134387, lng: 20.0173527 },
    { lat: 48.3132584, lng: 20.0168629 },
    { lat: 48.3132708, lng: 20.0159512 },
    { lat: 48.313142, lng: 20.0148164 },
    { lat: 48.3131657, lng: 20.0139886 },
    { lat: 48.3130537, lng: 20.0129577 },
    { lat: 48.3130749, lng: 20.0121478 },
    { lat: 48.3130396, lng: 20.0116847 },
    { lat: 48.3130751, lng: 20.0114284 },
    { lat: 48.3130171, lng: 20.0113107 },
    { lat: 48.3133278, lng: 20.0089712 },
    { lat: 48.3133278, lng: 20.0087305 },
    { lat: 48.3131332, lng: 20.0078809 },
    { lat: 48.3127463, lng: 20.0070108 },
    { lat: 48.3125132, lng: 20.0054372 },
    { lat: 48.3123919, lng: 20.0048832 },
    { lat: 48.3123538, lng: 20.0044038 },
    { lat: 48.3125223, lng: 20.0032434 },
    { lat: 48.3130536, lng: 20.0015773 },
    { lat: 48.313105, lng: 20.001244 },
    { lat: 48.3134485, lng: 20.0000681 },
    { lat: 48.3129555, lng: 19.9974055 },
    { lat: 48.3119771, lng: 19.9967451 },
    { lat: 48.3117691, lng: 19.9955036 },
    { lat: 48.3118182, lng: 19.9951175 },
    { lat: 48.3115828, lng: 19.9943984 },
    { lat: 48.3115577, lng: 19.9937196 },
    { lat: 48.3115808, lng: 19.9928518 },
    { lat: 48.3119269, lng: 19.9926467 },
    { lat: 48.3121185, lng: 19.9926777 },
    { lat: 48.3127368, lng: 19.9923508 },
    { lat: 48.3135089, lng: 19.9859316 },
    { lat: 48.3134778, lng: 19.9853864 },
    { lat: 48.3130127, lng: 19.9845091 },
    { lat: 48.3134764, lng: 19.984075 },
    { lat: 48.3135676, lng: 19.983675 },
    { lat: 48.3135963, lng: 19.9828356 },
    { lat: 48.3135675, lng: 19.981937 },
    { lat: 48.3134819, lng: 19.981196 },
    { lat: 48.3135952, lng: 19.980889 },
    { lat: 48.3137519, lng: 19.9806533 },
    { lat: 48.3140218, lng: 19.9795787 },
    { lat: 48.3139643, lng: 19.9792805 },
    { lat: 48.3137777, lng: 19.9788141 },
    { lat: 48.313646, lng: 19.9771644 },
    { lat: 48.3133229, lng: 19.9761345 },
    { lat: 48.3133416, lng: 19.9749543 },
    { lat: 48.3142823, lng: 19.9692119 },
    { lat: 48.3145705, lng: 19.9681496 },
    { lat: 48.314798, lng: 19.9675069 },
    { lat: 48.3150292, lng: 19.9665296 },
    { lat: 48.3151442, lng: 19.9662146 },
    { lat: 48.3156777, lng: 19.9651969 },
    { lat: 48.3156744, lng: 19.9637394 },
    { lat: 48.3171007, lng: 19.9615941 },
    { lat: 48.3184298, lng: 19.9602359 },
    { lat: 48.3187807, lng: 19.9600095 },
    { lat: 48.3196614, lng: 19.9581193 },
    { lat: 48.3200424, lng: 19.9571768 },
    { lat: 48.3207117, lng: 19.9561947 },
    { lat: 48.3209698, lng: 19.9554306 },
    { lat: 48.3214654, lng: 19.9549004 },
    { lat: 48.3220042, lng: 19.9545088 },
    { lat: 48.3222017, lng: 19.9541465 },
    { lat: 48.3222656, lng: 19.9538488 },
    { lat: 48.3223636, lng: 19.95369 },
    { lat: 48.3223809, lng: 19.9536197 },
    { lat: 48.322893, lng: 19.9523903 },
    { lat: 48.3234179, lng: 19.9515426 },
    { lat: 48.324101, lng: 19.9512096 },
    { lat: 48.3246752, lng: 19.9507788 },
    { lat: 48.3250363, lng: 19.948832 },
    { lat: 48.3254887, lng: 19.9472082 },
    { lat: 48.325918, lng: 19.9463083 },
    { lat: 48.3274197, lng: 19.9439077 },
    { lat: 48.3267424, lng: 19.9424228 },
    { lat: 48.3265848, lng: 19.9401777 },
    { lat: 48.3263356, lng: 19.9394058 },
    { lat: 48.3239205, lng: 19.9359274 },
    { lat: 48.3231137, lng: 19.9350792 },
    { lat: 48.3228813, lng: 19.9338329 },
    { lat: 48.3225133, lng: 19.9323625 },
    { lat: 48.3215599, lng: 19.9304319 },
    { lat: 48.3188123, lng: 19.9299352 },
  ],
  Drňa: [
    { lat: 48.2723963, lng: 20.1479434 },
    { lat: 48.2727842, lng: 20.1477031 },
    { lat: 48.2729196, lng: 20.1470476 },
    { lat: 48.2729195, lng: 20.1467463 },
    { lat: 48.2728355, lng: 20.1465101 },
    { lat: 48.2728337, lng: 20.1463787 },
    { lat: 48.272996, lng: 20.1458872 },
    { lat: 48.2730392, lng: 20.1456206 },
    { lat: 48.2722397, lng: 20.1435068 },
    { lat: 48.2725835, lng: 20.1432921 },
    { lat: 48.2727288, lng: 20.1428004 },
    { lat: 48.2727595, lng: 20.1425568 },
    { lat: 48.2727379, lng: 20.1421681 },
    { lat: 48.2728316, lng: 20.1419488 },
    { lat: 48.2731468, lng: 20.1415355 },
    { lat: 48.2731059, lng: 20.1410553 },
    { lat: 48.2728941, lng: 20.1407268 },
    { lat: 48.2731445, lng: 20.1402976 },
    { lat: 48.2733645, lng: 20.1394815 },
    { lat: 48.2733449, lng: 20.1390031 },
    { lat: 48.2734593, lng: 20.1388208 },
    { lat: 48.2735045, lng: 20.1382478 },
    { lat: 48.2732118, lng: 20.1380066 },
    { lat: 48.2732515, lng: 20.1374076 },
    { lat: 48.2734481, lng: 20.1370388 },
    { lat: 48.2731829, lng: 20.1366654 },
    { lat: 48.2730948, lng: 20.1364179 },
    { lat: 48.2730038, lng: 20.1363393 },
    { lat: 48.2729436, lng: 20.1360217 },
    { lat: 48.2727065, lng: 20.1359547 },
    { lat: 48.2724974, lng: 20.1355253 },
    { lat: 48.2722634, lng: 20.1352579 },
    { lat: 48.271551, lng: 20.1357679 },
    { lat: 48.2713804, lng: 20.135285 },
    { lat: 48.2711524, lng: 20.1354124 },
    { lat: 48.2709422, lng: 20.1346826 },
    { lat: 48.2708841, lng: 20.1341703 },
    { lat: 48.2707813, lng: 20.1339715 },
    { lat: 48.2709267, lng: 20.1335639 },
    { lat: 48.2702135, lng: 20.133363 },
    { lat: 48.2701164, lng: 20.1331948 },
    { lat: 48.2703324, lng: 20.1324986 },
    { lat: 48.2701495, lng: 20.132298 },
    { lat: 48.2699856, lng: 20.1326115 },
    { lat: 48.2697392, lng: 20.1324001 },
    { lat: 48.2696832, lng: 20.13246 },
    { lat: 48.2695751, lng: 20.1323805 },
    { lat: 48.2699318, lng: 20.1319846 },
    { lat: 48.2696089, lng: 20.1315583 },
    { lat: 48.2693389, lng: 20.1318932 },
    { lat: 48.2691544, lng: 20.1312968 },
    { lat: 48.2692478, lng: 20.1310191 },
    { lat: 48.2691945, lng: 20.130819 },
    { lat: 48.2690385, lng: 20.1309387 },
    { lat: 48.2689791, lng: 20.1306772 },
    { lat: 48.2687404, lng: 20.1308707 },
    { lat: 48.2686364, lng: 20.1306936 },
    { lat: 48.2685015, lng: 20.1306448 },
    { lat: 48.2681223, lng: 20.1299168 },
    { lat: 48.2677292, lng: 20.1286562 },
    { lat: 48.2676934, lng: 20.1283587 },
    { lat: 48.2674188, lng: 20.1282514 },
    { lat: 48.2674252, lng: 20.1276947 },
    { lat: 48.267551, lng: 20.1269715 },
    { lat: 48.2676461, lng: 20.1268782 },
    { lat: 48.2678549, lng: 20.1262552 },
    { lat: 48.2679261, lng: 20.1263681 },
    { lat: 48.267964, lng: 20.1266358 },
    { lat: 48.2685759, lng: 20.126013 },
    { lat: 48.2690331, lng: 20.1260936 },
    { lat: 48.269077, lng: 20.1260079 },
    { lat: 48.2689704, lng: 20.1256875 },
    { lat: 48.2693208, lng: 20.1253922 },
    { lat: 48.269472, lng: 20.1254804 },
    { lat: 48.2697531, lng: 20.1254135 },
    { lat: 48.2698023, lng: 20.1252131 },
    { lat: 48.2697318, lng: 20.1251121 },
    { lat: 48.2698488, lng: 20.1247828 },
    { lat: 48.2701549, lng: 20.1242543 },
    { lat: 48.2697171, lng: 20.1239627 },
    { lat: 48.2695639, lng: 20.1222897 },
    { lat: 48.2691515, lng: 20.1226682 },
    { lat: 48.2690918, lng: 20.1219567 },
    { lat: 48.2694698, lng: 20.1214541 },
    { lat: 48.2696436, lng: 20.1211054 },
    { lat: 48.2696342, lng: 20.1209277 },
    { lat: 48.2693704, lng: 20.1205692 },
    { lat: 48.2692264, lng: 20.1194207 },
    { lat: 48.2689926, lng: 20.1191874 },
    { lat: 48.2688168, lng: 20.1185799 },
    { lat: 48.2686123, lng: 20.1182676 },
    { lat: 48.2687609, lng: 20.1180997 },
    { lat: 48.2687044, lng: 20.1177938 },
    { lat: 48.2684872, lng: 20.1177467 },
    { lat: 48.26835, lng: 20.1171201 },
    { lat: 48.2681087, lng: 20.117467 },
    { lat: 48.2678477, lng: 20.1177205 },
    { lat: 48.2674206, lng: 20.1175011 },
    { lat: 48.2661888, lng: 20.1160614 },
    { lat: 48.2659495, lng: 20.1159131 },
    { lat: 48.2658004, lng: 20.1156808 },
    { lat: 48.2657063, lng: 20.1151713 },
    { lat: 48.2659129, lng: 20.1146392 },
    { lat: 48.2659253, lng: 20.114449 },
    { lat: 48.2643666, lng: 20.1098895 },
    { lat: 48.2642325, lng: 20.1098322 },
    { lat: 48.2638311, lng: 20.1098495 },
    { lat: 48.2629829, lng: 20.1100647 },
    { lat: 48.2612884, lng: 20.1115887 },
    { lat: 48.2602365, lng: 20.1105522 },
    { lat: 48.2609679, lng: 20.1091371 },
    { lat: 48.2610681, lng: 20.1083732 },
    { lat: 48.260855, lng: 20.1081507 },
    { lat: 48.2611633, lng: 20.1036371 },
    { lat: 48.2608958, lng: 20.1025618 },
    { lat: 48.2603907, lng: 20.1014587 },
    { lat: 48.2593364, lng: 20.1002541 },
    { lat: 48.2570256, lng: 20.0978716 },
    { lat: 48.2490823, lng: 20.0898653 },
    { lat: 48.2490672, lng: 20.0899328 },
    { lat: 48.2489845, lng: 20.0900864 },
    { lat: 48.2487153, lng: 20.0898435 },
    { lat: 48.2485587, lng: 20.0899496 },
    { lat: 48.2484617, lng: 20.0899215 },
    { lat: 48.2476523, lng: 20.0905875 },
    { lat: 48.2466885, lng: 20.090813 },
    { lat: 48.2464929, lng: 20.0910947 },
    { lat: 48.2461109, lng: 20.0918507 },
    { lat: 48.2457575, lng: 20.0922931 },
    { lat: 48.2450788, lng: 20.0924172 },
    { lat: 48.2444518, lng: 20.0929694 },
    { lat: 48.2441878, lng: 20.0948381 },
    { lat: 48.2441325, lng: 20.0968511 },
    { lat: 48.2440258, lng: 20.0977589 },
    { lat: 48.2419378, lng: 20.0990482 },
    { lat: 48.2416753, lng: 20.0990481 },
    { lat: 48.2408227, lng: 20.0987786 },
    { lat: 48.2403596, lng: 20.0987141 },
    { lat: 48.2402097, lng: 20.100376 },
    { lat: 48.2402188, lng: 20.1013345 },
    { lat: 48.2399054, lng: 20.1021978 },
    { lat: 48.240119, lng: 20.1031172 },
    { lat: 48.2394827, lng: 20.1050064 },
    { lat: 48.2392001, lng: 20.1053594 },
    { lat: 48.2389892, lng: 20.1060933 },
    { lat: 48.2389834, lng: 20.1072857 },
    { lat: 48.2386567, lng: 20.1075084 },
    { lat: 48.2382548, lng: 20.107648 },
    { lat: 48.2374875, lng: 20.1080787 },
    { lat: 48.2371673, lng: 20.1083429 },
    { lat: 48.2363043, lng: 20.1084799 },
    { lat: 48.2361662, lng: 20.1085704 },
    { lat: 48.236141, lng: 20.1105014 },
    { lat: 48.2356979, lng: 20.1104081 },
    { lat: 48.2349562, lng: 20.1107211 },
    { lat: 48.2339275, lng: 20.1119715 },
    { lat: 48.2337305, lng: 20.1123571 },
    { lat: 48.2335899, lng: 20.1127718 },
    { lat: 48.233382, lng: 20.1136562 },
    { lat: 48.2334299, lng: 20.1149275 },
    { lat: 48.2335999, lng: 20.1154721 },
    { lat: 48.2335888, lng: 20.1157537 },
    { lat: 48.2332252, lng: 20.1167429 },
    { lat: 48.2324937, lng: 20.1176304 },
    { lat: 48.2323932, lng: 20.1179799 },
    { lat: 48.2319783, lng: 20.1205158 },
    { lat: 48.2318691, lng: 20.1219122 },
    { lat: 48.2316068, lng: 20.1225196 },
    { lat: 48.2313922, lng: 20.1228623 },
    { lat: 48.2305712, lng: 20.1232513 },
    { lat: 48.2303447, lng: 20.1234145 },
    { lat: 48.2299005, lng: 20.1242242 },
    { lat: 48.22933, lng: 20.1260713 },
    { lat: 48.229059, lng: 20.1270999 },
    { lat: 48.2281433, lng: 20.1291016 },
    { lat: 48.2278243, lng: 20.1301667 },
    { lat: 48.2278579, lng: 20.1301855 },
    { lat: 48.227793, lng: 20.1314063 },
    { lat: 48.227875, lng: 20.133261 },
    { lat: 48.228484, lng: 20.133506 },
    { lat: 48.228482, lng: 20.133534 },
    { lat: 48.228602, lng: 20.133572 },
    { lat: 48.228802, lng: 20.133679 },
    { lat: 48.229103, lng: 20.133857 },
    { lat: 48.229216, lng: 20.133914 },
    { lat: 48.229347, lng: 20.133957 },
    { lat: 48.229592, lng: 20.134036 },
    { lat: 48.22974, lng: 20.134057 },
    { lat: 48.229814, lng: 20.134068 },
    { lat: 48.230187, lng: 20.134138 },
    { lat: 48.230411, lng: 20.134218 },
    { lat: 48.230635, lng: 20.134321 },
    { lat: 48.230764, lng: 20.134357 },
    { lat: 48.230906, lng: 20.134353 },
    { lat: 48.231038, lng: 20.134313 },
    { lat: 48.231252, lng: 20.134207 },
    { lat: 48.231542, lng: 20.134136 },
    { lat: 48.231756, lng: 20.134032 },
    { lat: 48.231937, lng: 20.13391 },
    { lat: 48.232289, lng: 20.133672 },
    { lat: 48.232421, lng: 20.13359 },
    { lat: 48.232602, lng: 20.133593 },
    { lat: 48.233076, lng: 20.133711 },
    { lat: 48.233427, lng: 20.133704 },
    { lat: 48.233578, lng: 20.133746 },
    { lat: 48.233659, lng: 20.133795 },
    { lat: 48.233776, lng: 20.133894 },
    { lat: 48.234042, lng: 20.133994 },
    { lat: 48.23433, lng: 20.134132 },
    { lat: 48.234514, lng: 20.134132 },
    { lat: 48.23476, lng: 20.134256 },
    { lat: 48.23495, lng: 20.134297 },
    { lat: 48.235036, lng: 20.13431 },
    { lat: 48.235597, lng: 20.134445 },
    { lat: 48.235926, lng: 20.134666 },
    { lat: 48.236136, lng: 20.134872 },
    { lat: 48.236334, lng: 20.135073 },
    { lat: 48.23641, lng: 20.135144 },
    { lat: 48.236483, lng: 20.135204 },
    { lat: 48.236712, lng: 20.13535 },
    { lat: 48.2368, lng: 20.135442 },
    { lat: 48.237044, lng: 20.135686 },
    { lat: 48.237241, lng: 20.136 },
    { lat: 48.237328, lng: 20.136244 },
    { lat: 48.237498, lng: 20.136512 },
    { lat: 48.237682, lng: 20.136781 },
    { lat: 48.237692, lng: 20.136763 },
    { lat: 48.237747, lng: 20.136836 },
    { lat: 48.237841, lng: 20.136887 },
    { lat: 48.237937, lng: 20.136918 },
    { lat: 48.238129, lng: 20.136913 },
    { lat: 48.238136, lng: 20.136968 },
    { lat: 48.238639, lng: 20.137134 },
    { lat: 48.238928, lng: 20.137225 },
    { lat: 48.239034, lng: 20.137911 },
    { lat: 48.239226, lng: 20.138172 },
    { lat: 48.239504, lng: 20.138533 },
    { lat: 48.239993, lng: 20.139184 },
    { lat: 48.240644, lng: 20.13907 },
    { lat: 48.240655, lng: 20.139114 },
    { lat: 48.240854, lng: 20.139071 },
    { lat: 48.240957, lng: 20.139063 },
    { lat: 48.241092, lng: 20.139082 },
    { lat: 48.241379, lng: 20.139215 },
    { lat: 48.241511, lng: 20.139262 },
    { lat: 48.241731, lng: 20.139332 },
    { lat: 48.24208, lng: 20.13945 },
    { lat: 48.242296, lng: 20.139483 },
    { lat: 48.242445, lng: 20.139524 },
    { lat: 48.242671, lng: 20.1396 },
    { lat: 48.242789, lng: 20.139625 },
    { lat: 48.242869, lng: 20.139631 },
    { lat: 48.243024, lng: 20.139626 },
    { lat: 48.243203, lng: 20.139573 },
    { lat: 48.243325, lng: 20.139513 },
    { lat: 48.243516, lng: 20.139372 },
    { lat: 48.243683, lng: 20.139266 },
    { lat: 48.243836, lng: 20.139178 },
    { lat: 48.243918, lng: 20.139117 },
    { lat: 48.243956, lng: 20.139069 },
    { lat: 48.243979, lng: 20.139022 },
    { lat: 48.244086, lng: 20.138723 },
    { lat: 48.244129, lng: 20.138621 },
    { lat: 48.244187, lng: 20.138499 },
    { lat: 48.244285, lng: 20.138291 },
    { lat: 48.24436, lng: 20.138084 },
    { lat: 48.244463, lng: 20.137887 },
    { lat: 48.244569, lng: 20.13775 },
    { lat: 48.244633, lng: 20.137662 },
    { lat: 48.244707, lng: 20.137494 },
    { lat: 48.244758, lng: 20.137351 },
    { lat: 48.244878, lng: 20.136957 },
    { lat: 48.244935, lng: 20.136854 },
    { lat: 48.244982, lng: 20.136798 },
    { lat: 48.245327, lng: 20.136574 },
    { lat: 48.245437, lng: 20.136492 },
    { lat: 48.245521, lng: 20.136373 },
    { lat: 48.245619, lng: 20.136113 },
    { lat: 48.245644, lng: 20.135971 },
    { lat: 48.245681, lng: 20.135595 },
    { lat: 48.245686, lng: 20.135329 },
    { lat: 48.245718, lng: 20.135144 },
    { lat: 48.245752, lng: 20.134989 },
    { lat: 48.245904, lng: 20.134516 },
    { lat: 48.246014, lng: 20.134245 },
    { lat: 48.24607, lng: 20.134164 },
    { lat: 48.246133, lng: 20.134081 },
    { lat: 48.246217, lng: 20.134012 },
    { lat: 48.246285, lng: 20.133916 },
    { lat: 48.246339, lng: 20.133766 },
    { lat: 48.246398, lng: 20.133563 },
    { lat: 48.246482, lng: 20.13332 },
    { lat: 48.246549, lng: 20.133175 },
    { lat: 48.246676, lng: 20.132967 },
    { lat: 48.246787, lng: 20.132828 },
    { lat: 48.247023, lng: 20.132524 },
    { lat: 48.247097, lng: 20.13237 },
    { lat: 48.24717, lng: 20.13226 },
    { lat: 48.247338, lng: 20.132149 },
    { lat: 48.247448, lng: 20.132096 },
    { lat: 48.247661, lng: 20.132058 },
    { lat: 48.247858, lng: 20.131935 },
    { lat: 48.247965, lng: 20.1319 },
    { lat: 48.248138, lng: 20.131864 },
    { lat: 48.248282, lng: 20.131861 },
    { lat: 48.248356, lng: 20.131865 },
    { lat: 48.248454, lng: 20.131878 },
    { lat: 48.248662, lng: 20.131931 },
    { lat: 48.248809, lng: 20.131977 },
    { lat: 48.248972, lng: 20.132053 },
    { lat: 48.249075, lng: 20.132111 },
    { lat: 48.249258, lng: 20.132207 },
    { lat: 48.249342, lng: 20.132236 },
    { lat: 48.249405, lng: 20.132231 },
    { lat: 48.249578, lng: 20.132201 },
    { lat: 48.249701, lng: 20.132235 },
    { lat: 48.249866, lng: 20.132347 },
    { lat: 48.250026, lng: 20.132471 },
    { lat: 48.250176, lng: 20.132607 },
    { lat: 48.250333, lng: 20.132743 },
    { lat: 48.250466, lng: 20.132805 },
    { lat: 48.250604, lng: 20.132849 },
    { lat: 48.250664, lng: 20.132884 },
    { lat: 48.250984, lng: 20.13301 },
    { lat: 48.251064, lng: 20.133063 },
    { lat: 48.251106, lng: 20.133108 },
    { lat: 48.251178, lng: 20.133204 },
    { lat: 48.251239, lng: 20.133297 },
    { lat: 48.251335, lng: 20.1335 },
    { lat: 48.251404, lng: 20.133655 },
    { lat: 48.251603, lng: 20.134045 },
    { lat: 48.251663, lng: 20.134097 },
    { lat: 48.251726, lng: 20.134113 },
    { lat: 48.251893, lng: 20.134079 },
    { lat: 48.251891, lng: 20.134048 },
    { lat: 48.251999, lng: 20.134002 },
    { lat: 48.252317, lng: 20.133634 },
    { lat: 48.252595, lng: 20.13345 },
    { lat: 48.253023, lng: 20.133482 },
    { lat: 48.253469, lng: 20.133395 },
    { lat: 48.2535211, lng: 20.1333834 },
    { lat: 48.253563, lng: 20.133377 },
    { lat: 48.253591, lng: 20.133372 },
    { lat: 48.253706, lng: 20.13336 },
    { lat: 48.253982, lng: 20.133188 },
    { lat: 48.254112, lng: 20.133676 },
    { lat: 48.254168, lng: 20.133881 },
    { lat: 48.254149, lng: 20.134218 },
    { lat: 48.254252, lng: 20.134651 },
    { lat: 48.254365, lng: 20.135063 },
    { lat: 48.254384, lng: 20.135152 },
    { lat: 48.254389, lng: 20.135422 },
    { lat: 48.254413, lng: 20.135489 },
    { lat: 48.254447, lng: 20.135545 },
    { lat: 48.254488, lng: 20.135584 },
    { lat: 48.254747, lng: 20.135879 },
    { lat: 48.254914, lng: 20.136022 },
    { lat: 48.254984, lng: 20.13612 },
    { lat: 48.255063, lng: 20.1364 },
    { lat: 48.255111, lng: 20.136556 },
    { lat: 48.255153, lng: 20.136703 },
    { lat: 48.255136, lng: 20.137042 },
    { lat: 48.254997, lng: 20.137385 },
    { lat: 48.254827, lng: 20.137916 },
    { lat: 48.254684, lng: 20.138305 },
    { lat: 48.254647, lng: 20.138673 },
    { lat: 48.25455, lng: 20.139124 },
    { lat: 48.25442, lng: 20.139837 },
    { lat: 48.254323, lng: 20.140236 },
    { lat: 48.254297, lng: 20.140866 },
    { lat: 48.254295, lng: 20.141151 },
    { lat: 48.254565, lng: 20.141529 },
    { lat: 48.254669, lng: 20.141976 },
    { lat: 48.254774, lng: 20.142426 },
    { lat: 48.255069, lng: 20.142648 },
    { lat: 48.255189, lng: 20.14296 },
    { lat: 48.255443, lng: 20.143446 },
    { lat: 48.2558108, lng: 20.1435947 },
    { lat: 48.2562647, lng: 20.1440337 },
    { lat: 48.2568615, lng: 20.1453744 },
    { lat: 48.2570404, lng: 20.1454548 },
    { lat: 48.257437, lng: 20.1458719 },
    { lat: 48.2578147, lng: 20.1464558 },
    { lat: 48.2582366, lng: 20.1465589 },
    { lat: 48.2587896, lng: 20.146558 },
    { lat: 48.2589242, lng: 20.1466227 },
    { lat: 48.2592504, lng: 20.146918 },
    { lat: 48.2597024, lng: 20.1476156 },
    { lat: 48.2604035, lng: 20.1482593 },
    { lat: 48.2609093, lng: 20.1491426 },
    { lat: 48.2608694, lng: 20.1502056 },
    { lat: 48.261237, lng: 20.1506286 },
    { lat: 48.262859, lng: 20.1504673 },
    { lat: 48.2631904, lng: 20.1504959 },
    { lat: 48.2637886, lng: 20.1511361 },
    { lat: 48.2642195, lng: 20.1518138 },
    { lat: 48.2652041, lng: 20.1521468 },
    { lat: 48.2659432, lng: 20.15174 },
    { lat: 48.2664254, lng: 20.1493715 },
    { lat: 48.2665177, lng: 20.1492337 },
    { lat: 48.2667405, lng: 20.149198 },
    { lat: 48.2667788, lng: 20.1490312 },
    { lat: 48.2670802, lng: 20.1491068 },
    { lat: 48.267585, lng: 20.1493882 },
    { lat: 48.268021, lng: 20.1491704 },
    { lat: 48.2686928, lng: 20.1495126 },
    { lat: 48.2691788, lng: 20.1491375 },
    { lat: 48.2697492, lng: 20.1489579 },
    { lat: 48.2700328, lng: 20.1485101 },
    { lat: 48.2706166, lng: 20.1479121 },
    { lat: 48.271726, lng: 20.1473073 },
    { lat: 48.2723963, lng: 20.1479434 },
  ],
  Jestice: [
    { lat: 48.2182438, lng: 20.0317413 },
    { lat: 48.2184618, lng: 20.032345 },
    { lat: 48.2185457, lng: 20.0332145 },
    { lat: 48.2186789, lng: 20.0336785 },
    { lat: 48.2186957, lng: 20.0340294 },
    { lat: 48.2185358, lng: 20.0348883 },
    { lat: 48.2183627, lng: 20.0368864 },
    { lat: 48.2182228, lng: 20.0373951 },
    { lat: 48.2181262, lng: 20.038775 },
    { lat: 48.2177946, lng: 20.0399483 },
    { lat: 48.2175247, lng: 20.0412491 },
    { lat: 48.2172957, lng: 20.0419642 },
    { lat: 48.2171734, lng: 20.0433221 },
    { lat: 48.2169836, lng: 20.0436599 },
    { lat: 48.2164102, lng: 20.0443871 },
    { lat: 48.216108, lng: 20.0446729 },
    { lat: 48.2158253, lng: 20.0451328 },
    { lat: 48.2153789, lng: 20.0456916 },
    { lat: 48.2150386, lng: 20.0463073 },
    { lat: 48.2151965, lng: 20.0477417 },
    { lat: 48.2151532, lng: 20.0481074 },
    { lat: 48.2146368, lng: 20.0490687 },
    { lat: 48.2137408, lng: 20.0495375 },
    { lat: 48.2123294, lng: 20.0501639 },
    { lat: 48.2117255, lng: 20.0508332 },
    { lat: 48.2116613, lng: 20.0511546 },
    { lat: 48.2114268, lng: 20.0515816 },
    { lat: 48.2110388, lng: 20.0518951 },
    { lat: 48.2099503, lng: 20.0525873 },
    { lat: 48.209828, lng: 20.0535427 },
    { lat: 48.2095236, lng: 20.0538332 },
    { lat: 48.2096971, lng: 20.0560213 },
    { lat: 48.2095326, lng: 20.0559929 },
    { lat: 48.2095034, lng: 20.05621 },
    { lat: 48.2097733, lng: 20.0576546 },
    { lat: 48.2097478, lng: 20.0591257 },
    { lat: 48.2087175, lng: 20.0588889 },
    { lat: 48.2075599, lng: 20.0589779 },
    { lat: 48.2070082, lng: 20.0587734 },
    { lat: 48.2065569, lng: 20.0584853 },
    { lat: 48.2062065, lng: 20.0580763 },
    { lat: 48.2059343, lng: 20.0577069 },
    { lat: 48.2054986, lng: 20.0569043 },
    { lat: 48.2051566, lng: 20.0564814 },
    { lat: 48.2039203, lng: 20.0554422 },
    { lat: 48.2036079, lng: 20.0552422 },
    { lat: 48.2035713, lng: 20.0553653 },
    { lat: 48.2017239, lng: 20.0545838 },
    { lat: 48.2011498, lng: 20.0568616 },
    { lat: 48.2011414, lng: 20.0573055 },
    { lat: 48.2015155, lng: 20.0579552 },
    { lat: 48.2015311, lng: 20.0590067 },
    { lat: 48.2014035, lng: 20.0596439 },
    { lat: 48.201068, lng: 20.0606208 },
    { lat: 48.2010652, lng: 20.0609897 },
    { lat: 48.2011641, lng: 20.0612492 },
    { lat: 48.2012405, lng: 20.0619224 },
    { lat: 48.2012592, lng: 20.0637755 },
    { lat: 48.2011839, lng: 20.0648905 },
    { lat: 48.200844, lng: 20.0651926 },
    { lat: 48.2004314, lng: 20.0653229 },
    { lat: 48.2000476, lng: 20.0652485 },
    { lat: 48.1997856, lng: 20.0655031 },
    { lat: 48.1993245, lng: 20.0656248 },
    { lat: 48.1990117, lng: 20.0659866 },
    { lat: 48.1987872, lng: 20.066469 },
    { lat: 48.1980877, lng: 20.0673335 },
    { lat: 48.197986, lng: 20.0680547 },
    { lat: 48.1976737, lng: 20.0691321 },
    { lat: 48.1974304, lng: 20.0696204 },
    { lat: 48.1972661, lng: 20.0706808 },
    { lat: 48.197193, lng: 20.07252 },
    { lat: 48.1967248, lng: 20.0733964 },
    { lat: 48.1963225, lng: 20.073747 },
    { lat: 48.1957475, lng: 20.0745751 },
    { lat: 48.1955833, lng: 20.0757706 },
    { lat: 48.195557, lng: 20.076388 },
    { lat: 48.196013, lng: 20.077068 },
    { lat: 48.196427, lng: 20.078257 },
    { lat: 48.196605, lng: 20.07932 },
    { lat: 48.196696, lng: 20.080204 },
    { lat: 48.196637, lng: 20.080974 },
    { lat: 48.196743, lng: 20.081454 },
    { lat: 48.196678, lng: 20.082237 },
    { lat: 48.19667, lng: 20.082606 },
    { lat: 48.19624, lng: 20.082955 },
    { lat: 48.195843, lng: 20.083442 },
    { lat: 48.195545, lng: 20.083745 },
    { lat: 48.195541, lng: 20.08473 },
    { lat: 48.195484, lng: 20.085173 },
    { lat: 48.195876, lng: 20.085911 },
    { lat: 48.196182, lng: 20.086167 },
    { lat: 48.196062, lng: 20.086959 },
    { lat: 48.196059, lng: 20.087269 },
    { lat: 48.196214, lng: 20.087479 },
    { lat: 48.196894, lng: 20.088583 },
    { lat: 48.196707, lng: 20.088833 },
    { lat: 48.196562, lng: 20.089264 },
    { lat: 48.196836, lng: 20.090187 },
    { lat: 48.196823, lng: 20.090197 },
    { lat: 48.19701, lng: 20.09076 },
    { lat: 48.197082, lng: 20.09086 },
    { lat: 48.197151, lng: 20.090899 },
    { lat: 48.197212, lng: 20.090927 },
    { lat: 48.197372, lng: 20.090922 },
    { lat: 48.19745, lng: 20.090917 },
    { lat: 48.197639, lng: 20.090853 },
    { lat: 48.197688, lng: 20.090832 },
    { lat: 48.197812, lng: 20.090836 },
    { lat: 48.197811, lng: 20.09086 },
    { lat: 48.198171, lng: 20.091152 },
    { lat: 48.198127, lng: 20.091396 },
    { lat: 48.198068, lng: 20.091886 },
    { lat: 48.198189, lng: 20.092193 },
    { lat: 48.198613, lng: 20.09271 },
    { lat: 48.198733, lng: 20.092808 },
    { lat: 48.198929, lng: 20.093092 },
    { lat: 48.199417, lng: 20.093375 },
    { lat: 48.199971, lng: 20.093138 },
    { lat: 48.200875, lng: 20.093012 },
    { lat: 48.201193, lng: 20.093483 },
    { lat: 48.201736, lng: 20.094062 },
    { lat: 48.202443, lng: 20.094513 },
    { lat: 48.203321, lng: 20.095048 },
    { lat: 48.2038954, lng: 20.0954056 },
    { lat: 48.203962, lng: 20.095447 },
    { lat: 48.204027, lng: 20.0954104 },
    { lat: 48.2042399, lng: 20.0937234 },
    { lat: 48.2043489, lng: 20.0924107 },
    { lat: 48.204545, lng: 20.0912979 },
    { lat: 48.2047466, lng: 20.0905871 },
    { lat: 48.205302, lng: 20.0901535 },
    { lat: 48.205876, lng: 20.0890586 },
    { lat: 48.2062442, lng: 20.0885407 },
    { lat: 48.2070144, lng: 20.0877425 },
    { lat: 48.2077839, lng: 20.0860365 },
    { lat: 48.2079941, lng: 20.0853722 },
    { lat: 48.2081775, lng: 20.0850709 },
    { lat: 48.2093745, lng: 20.0844912 },
    { lat: 48.2095557, lng: 20.0842685 },
    { lat: 48.2097135, lng: 20.0831601 },
    { lat: 48.2097975, lng: 20.0830418 },
    { lat: 48.2099113, lng: 20.0829857 },
    { lat: 48.2106638, lng: 20.0830412 },
    { lat: 48.210849, lng: 20.0829724 },
    { lat: 48.2119211, lng: 20.0830731 },
    { lat: 48.2126699, lng: 20.0836625 },
    { lat: 48.2132821, lng: 20.0840305 },
    { lat: 48.2135989, lng: 20.0840654 },
    { lat: 48.2140312, lng: 20.083926 },
    { lat: 48.2142185, lng: 20.0839556 },
    { lat: 48.2155809, lng: 20.0835635 },
    { lat: 48.2157192, lng: 20.0835852 },
    { lat: 48.2157224, lng: 20.0834987 },
    { lat: 48.2161527, lng: 20.0828492 },
    { lat: 48.21626, lng: 20.0826079 },
    { lat: 48.2163153, lng: 20.0823417 },
    { lat: 48.2163502, lng: 20.0815555 },
    { lat: 48.2163204, lng: 20.0811913 },
    { lat: 48.2162106, lng: 20.0806901 },
    { lat: 48.2161819, lng: 20.0801064 },
    { lat: 48.2160286, lng: 20.0793416 },
    { lat: 48.2162344, lng: 20.0783582 },
    { lat: 48.2163791, lng: 20.0781247 },
    { lat: 48.2168386, lng: 20.0777988 },
    { lat: 48.2173209, lng: 20.0771817 },
    { lat: 48.2174112, lng: 20.0769674 },
    { lat: 48.2174168, lng: 20.0757173 },
    { lat: 48.2175309, lng: 20.074913 },
    { lat: 48.2176947, lng: 20.0743835 },
    { lat: 48.2179769, lng: 20.0737459 },
    { lat: 48.2186963, lng: 20.0735228 },
    { lat: 48.219033, lng: 20.0733258 },
    { lat: 48.2192747, lng: 20.0723959 },
    { lat: 48.2196475, lng: 20.0706026 },
    { lat: 48.2199571, lng: 20.0703077 },
    { lat: 48.2199463, lng: 20.0694727 },
    { lat: 48.2198974, lng: 20.0690068 },
    { lat: 48.2197881, lng: 20.0686789 },
    { lat: 48.2197492, lng: 20.068395 },
    { lat: 48.2197677, lng: 20.0677289 },
    { lat: 48.2202261, lng: 20.0671613 },
    { lat: 48.2204909, lng: 20.0666216 },
    { lat: 48.2205691, lng: 20.0663342 },
    { lat: 48.2206072, lng: 20.066088 },
    { lat: 48.2205723, lng: 20.0649493 },
    { lat: 48.2207163, lng: 20.0640825 },
    { lat: 48.2209596, lng: 20.0639457 },
    { lat: 48.2216928, lng: 20.0637247 },
    { lat: 48.2226587, lng: 20.0632266 },
    { lat: 48.2226632, lng: 20.0628988 },
    { lat: 48.2234061, lng: 20.0620788 },
    { lat: 48.2238044, lng: 20.0618209 },
    { lat: 48.2239532, lng: 20.0601234 },
    { lat: 48.2241338, lng: 20.058862 },
    { lat: 48.2242205, lng: 20.05678 },
    { lat: 48.2241636, lng: 20.0567691 },
    { lat: 48.2241742, lng: 20.0565583 },
    { lat: 48.2243872, lng: 20.054054 },
    { lat: 48.2245139, lng: 20.0529855 },
    { lat: 48.2248783, lng: 20.0510326 },
    { lat: 48.2249469, lng: 20.0498159 },
    { lat: 48.2254219, lng: 20.0472976 },
    { lat: 48.2256573, lng: 20.0463581 },
    { lat: 48.2256975, lng: 20.0450271 },
    { lat: 48.2257871, lng: 20.0446344 },
    { lat: 48.2258256, lng: 20.0440765 },
    { lat: 48.2260216, lng: 20.0435357 },
    { lat: 48.2264549, lng: 20.0427511 },
    { lat: 48.2266374, lng: 20.0419172 },
    { lat: 48.2263512, lng: 20.0392172 },
    { lat: 48.2252271, lng: 20.0353515 },
    { lat: 48.2248357, lng: 20.0342056 },
    { lat: 48.2245132, lng: 20.0335296 },
    { lat: 48.224306, lng: 20.0329118 },
    { lat: 48.2238286, lng: 20.0329987 },
    { lat: 48.2236251, lng: 20.0331435 },
    { lat: 48.2222201, lng: 20.0333733 },
    { lat: 48.2215077, lng: 20.0332049 },
    { lat: 48.2209911, lng: 20.0332704 },
    { lat: 48.2204435, lng: 20.0332084 },
    { lat: 48.2197961, lng: 20.0328446 },
    { lat: 48.2194558, lng: 20.0324925 },
    { lat: 48.21939, lng: 20.032184 },
    { lat: 48.2190996, lng: 20.0317874 },
    { lat: 48.2182438, lng: 20.0317413 },
  ],
  Tomášovce: [
    { lat: 48.3955276, lng: 20.1182 },
    { lat: 48.3941793, lng: 20.1184162 },
    { lat: 48.3935864, lng: 20.1183254 },
    { lat: 48.3923473, lng: 20.1166772 },
    { lat: 48.3915818, lng: 20.1157558 },
    { lat: 48.3902166, lng: 20.1150653 },
    { lat: 48.389129, lng: 20.1141772 },
    { lat: 48.3878621, lng: 20.1140699 },
    { lat: 48.3874506, lng: 20.1150506 },
    { lat: 48.3830466, lng: 20.1151918 },
    { lat: 48.3825382, lng: 20.1151513 },
    { lat: 48.3818924, lng: 20.1151969 },
    { lat: 48.3810908, lng: 20.1151283 },
    { lat: 48.3783182, lng: 20.115279 },
    { lat: 48.3774422, lng: 20.115421 },
    { lat: 48.3771341, lng: 20.1158205 },
    { lat: 48.3770288, lng: 20.1159181 },
    { lat: 48.3758605, lng: 20.1162933 },
    { lat: 48.3750782, lng: 20.1171299 },
    { lat: 48.3743773, lng: 20.1181662 },
    { lat: 48.3737241, lng: 20.1197053 },
    { lat: 48.3725467, lng: 20.1216985 },
    { lat: 48.3732644, lng: 20.1213466 },
    { lat: 48.3737786, lng: 20.1208375 },
    { lat: 48.3741403, lng: 20.1212256 },
    { lat: 48.3746885, lng: 20.121959 },
    { lat: 48.3748186, lng: 20.1222775 },
    { lat: 48.3750221, lng: 20.1234175 },
    { lat: 48.3752286, lng: 20.1240326 },
    { lat: 48.375679, lng: 20.125157 },
    { lat: 48.3760179, lng: 20.1258626 },
    { lat: 48.3771891, lng: 20.1270157 },
    { lat: 48.3783305, lng: 20.1283442 },
    { lat: 48.3798308, lng: 20.1305428 },
    { lat: 48.3804525, lng: 20.1316656 },
    { lat: 48.3805839, lng: 20.1325701 },
    { lat: 48.3810633, lng: 20.1343978 },
    { lat: 48.3823651, lng: 20.1362128 },
    { lat: 48.3825366, lng: 20.1367122 },
    { lat: 48.3824721, lng: 20.1382764 },
    { lat: 48.3826979, lng: 20.1397817 },
    { lat: 48.382609, lng: 20.1418727 },
    { lat: 48.38252, lng: 20.1419315 },
    { lat: 48.3828003, lng: 20.1432518 },
    { lat: 48.3832249, lng: 20.1443268 },
    { lat: 48.3837203, lng: 20.1448708 },
    { lat: 48.3839745, lng: 20.1452514 },
    { lat: 48.3840023, lng: 20.1456839 },
    { lat: 48.3841595, lng: 20.1462215 },
    { lat: 48.3842645, lng: 20.1470531 },
    { lat: 48.384065, lng: 20.1490139 },
    { lat: 48.3839525, lng: 20.149571 },
    { lat: 48.3838429, lng: 20.1520483 },
    { lat: 48.3836615, lng: 20.1527626 },
    { lat: 48.3837637, lng: 20.1534463 },
    { lat: 48.3834303, lng: 20.155076 },
    { lat: 48.3836272, lng: 20.1585842 },
    { lat: 48.3839212, lng: 20.159638 },
    { lat: 48.3842591, lng: 20.1603323 },
    { lat: 48.3841054, lng: 20.1612312 },
    { lat: 48.3843346, lng: 20.1622172 },
    { lat: 48.3843969, lng: 20.1621417 },
    { lat: 48.3862691, lng: 20.1658297 },
    { lat: 48.3865274, lng: 20.1660062 },
    { lat: 48.386916, lng: 20.1669501 },
    { lat: 48.3869598, lng: 20.1672494 },
    { lat: 48.3872341, lng: 20.167652 },
    { lat: 48.3873972, lng: 20.1677054 },
    { lat: 48.3878451, lng: 20.1671981 },
    { lat: 48.3879899, lng: 20.1673507 },
    { lat: 48.3881058, lng: 20.1673745 },
    { lat: 48.3884194, lng: 20.1671122 },
    { lat: 48.3887112, lng: 20.1673056 },
    { lat: 48.3888944, lng: 20.1673253 },
    { lat: 48.3892304, lng: 20.1670011 },
    { lat: 48.389385, lng: 20.1666184 },
    { lat: 48.3895713, lng: 20.166325 },
    { lat: 48.3895984, lng: 20.1661315 },
    { lat: 48.3898053, lng: 20.1657635 },
    { lat: 48.3898535, lng: 20.165758 },
    { lat: 48.3898388, lng: 20.1661463 },
    { lat: 48.3900285, lng: 20.1664068 },
    { lat: 48.3902216, lng: 20.1670008 },
    { lat: 48.390472, lng: 20.1668134 },
    { lat: 48.3905248, lng: 20.1669808 },
    { lat: 48.3905192, lng: 20.1673655 },
    { lat: 48.3906023, lng: 20.1674716 },
    { lat: 48.3906476, lng: 20.167477 },
    { lat: 48.3907392, lng: 20.1672684 },
    { lat: 48.3910724, lng: 20.1672078 },
    { lat: 48.3911028, lng: 20.1674864 },
    { lat: 48.3912163, lng: 20.1678109 },
    { lat: 48.3914066, lng: 20.1674455 },
    { lat: 48.3914136, lng: 20.1672875 },
    { lat: 48.3915437, lng: 20.1671902 },
    { lat: 48.3917186, lng: 20.1671713 },
    { lat: 48.3919241, lng: 20.1669317 },
    { lat: 48.3919665, lng: 20.1668127 },
    { lat: 48.3923636, lng: 20.1667175 },
    { lat: 48.3925103, lng: 20.166889 },
    { lat: 48.3927146, lng: 20.1667357 },
    { lat: 48.392689, lng: 20.1662971 },
    { lat: 48.3928158, lng: 20.1662051 },
    { lat: 48.3930695, lng: 20.1657934 },
    { lat: 48.3931978, lng: 20.1657902 },
    { lat: 48.3938333, lng: 20.1650197 },
    { lat: 48.3945596, lng: 20.1645466 },
    { lat: 48.3951688, lng: 20.1638798 },
    { lat: 48.395441, lng: 20.1636709 },
    { lat: 48.3955694, lng: 20.1635894 },
    { lat: 48.3958712, lng: 20.1636471 },
    { lat: 48.3964988, lng: 20.1634032 },
    { lat: 48.3968348, lng: 20.1634117 },
    { lat: 48.397173, lng: 20.1635275 },
    { lat: 48.3973836, lng: 20.163471 },
    { lat: 48.39741, lng: 20.163152 },
    { lat: 48.3976837, lng: 20.1624287 },
    { lat: 48.398208, lng: 20.1624183 },
    { lat: 48.3982662, lng: 20.1624927 },
    { lat: 48.3983548, lng: 20.1623959 },
    { lat: 48.3985723, lng: 20.1625096 },
    { lat: 48.3987916, lng: 20.1622269 },
    { lat: 48.3988942, lng: 20.1619804 },
    { lat: 48.3989601, lng: 20.161963 },
    { lat: 48.3993369, lng: 20.1621748 },
    { lat: 48.399504, lng: 20.1609108 },
    { lat: 48.3997814, lng: 20.1607542 },
    { lat: 48.3997839, lng: 20.1606196 },
    { lat: 48.4009679, lng: 20.1605185 },
    { lat: 48.401669, lng: 20.1605559 },
    { lat: 48.4017239, lng: 20.1602673 },
    { lat: 48.401975, lng: 20.159965 },
    { lat: 48.4001744, lng: 20.159339 },
    { lat: 48.3995998, lng: 20.1588094 },
    { lat: 48.3999905, lng: 20.1574773 },
    { lat: 48.3990015, lng: 20.1561177 },
    { lat: 48.3990501, lng: 20.1557063 },
    { lat: 48.3992154, lng: 20.1557025 },
    { lat: 48.3994811, lng: 20.1558926 },
    { lat: 48.3996855, lng: 20.1557366 },
    { lat: 48.3999506, lng: 20.1559172 },
    { lat: 48.4001554, lng: 20.1557694 },
    { lat: 48.4002538, lng: 20.1555601 },
    { lat: 48.3997719, lng: 20.1537123 },
    { lat: 48.3994432, lng: 20.1530768 },
    { lat: 48.3975513, lng: 20.1499639 },
    { lat: 48.3962399, lng: 20.147943 },
    { lat: 48.3951712, lng: 20.1459297 },
    { lat: 48.3953574, lng: 20.1457162 },
    { lat: 48.3953021, lng: 20.1456047 },
    { lat: 48.3953752, lng: 20.1454913 },
    { lat: 48.3952741, lng: 20.1453326 },
    { lat: 48.3953508, lng: 20.1450619 },
    { lat: 48.3949535, lng: 20.1434639 },
    { lat: 48.3949406, lng: 20.1422703 },
    { lat: 48.3951291, lng: 20.1373968 },
    { lat: 48.3955683, lng: 20.1327382 },
    { lat: 48.3958927, lng: 20.1285027 },
    { lat: 48.3954661, lng: 20.1270553 },
    { lat: 48.3952407, lng: 20.1259487 },
    { lat: 48.395158, lng: 20.1247311 },
    { lat: 48.3955263, lng: 20.1211015 },
    { lat: 48.3955565, lng: 20.1186574 },
    { lat: 48.3955276, lng: 20.1182 },
  ],
  Padarovce: [
    { lat: 48.468915, lng: 20.0461174 },
    { lat: 48.4687739, lng: 20.0455411 },
    { lat: 48.4686046, lng: 20.0438827 },
    { lat: 48.4679568, lng: 20.0420948 },
    { lat: 48.4674907, lng: 20.0393431 },
    { lat: 48.4676226, lng: 20.037995 },
    { lat: 48.4673964, lng: 20.0374562 },
    { lat: 48.4675213, lng: 20.0371562 },
    { lat: 48.4674677, lng: 20.0368439 },
    { lat: 48.4673989, lng: 20.0367851 },
    { lat: 48.4673383, lng: 20.0361466 },
    { lat: 48.4671609, lng: 20.0358325 },
    { lat: 48.4670836, lng: 20.0353319 },
    { lat: 48.4666932, lng: 20.0340462 },
    { lat: 48.4664206, lng: 20.0336122 },
    { lat: 48.4661727, lng: 20.033532 },
    { lat: 48.4653983, lng: 20.0328146 },
    { lat: 48.4644742, lng: 20.0325103 },
    { lat: 48.4641392, lng: 20.032253 },
    { lat: 48.4639977, lng: 20.03224 },
    { lat: 48.4635058, lng: 20.031873 },
    { lat: 48.4630883, lng: 20.0313154 },
    { lat: 48.4625265, lng: 20.0308896 },
    { lat: 48.4621061, lng: 20.0308004 },
    { lat: 48.4617429, lng: 20.030977 },
    { lat: 48.4614993, lng: 20.030835 },
    { lat: 48.4611983, lng: 20.0305064 },
    { lat: 48.4609895, lng: 20.0303614 },
    { lat: 48.4600978, lng: 20.0301588 },
    { lat: 48.4591652, lng: 20.0303235 },
    { lat: 48.4587657, lng: 20.0306834 },
    { lat: 48.458301, lng: 20.0308831 },
    { lat: 48.4580164, lng: 20.0310834 },
    { lat: 48.4570087, lng: 20.0320661 },
    { lat: 48.4566948, lng: 20.0325858 },
    { lat: 48.4562679, lng: 20.0313953 },
    { lat: 48.4562272, lng: 20.0310145 },
    { lat: 48.4563112, lng: 20.0307383 },
    { lat: 48.4561229, lng: 20.0301915 },
    { lat: 48.4561578, lng: 20.0291945 },
    { lat: 48.4563476, lng: 20.0285843 },
    { lat: 48.4562472, lng: 20.0275594 },
    { lat: 48.4560967, lng: 20.027054 },
    { lat: 48.455636, lng: 20.025869 },
    { lat: 48.4554035, lng: 20.0254853 },
    { lat: 48.4544726, lng: 20.0251215 },
    { lat: 48.4542588, lng: 20.0251286 },
    { lat: 48.4541519, lng: 20.0250205 },
    { lat: 48.4536917, lng: 20.0248206 },
    { lat: 48.4537936, lng: 20.0245856 },
    { lat: 48.4540702, lng: 20.023501 },
    { lat: 48.4543553, lng: 20.0229091 },
    { lat: 48.4545421, lng: 20.0226114 },
    { lat: 48.4547971, lng: 20.0225902 },
    { lat: 48.4549863, lng: 20.0222426 },
    { lat: 48.4553117, lng: 20.0218857 },
    { lat: 48.4554075, lng: 20.0219398 },
    { lat: 48.455476, lng: 20.0218851 },
    { lat: 48.4554277, lng: 20.021752 },
    { lat: 48.4554564, lng: 20.0216811 },
    { lat: 48.4556744, lng: 20.0216747 },
    { lat: 48.4558855, lng: 20.0214103 },
    { lat: 48.4559731, lng: 20.0211128 },
    { lat: 48.4559009, lng: 20.0210273 },
    { lat: 48.4559405, lng: 20.0210044 },
    { lat: 48.4559828, lng: 20.0207513 },
    { lat: 48.4555162, lng: 20.0201776 },
    { lat: 48.4552246, lng: 20.0199965 },
    { lat: 48.454197, lng: 20.0190749 },
    { lat: 48.4539562, lng: 20.0190231 },
    { lat: 48.4534856, lng: 20.0183586 },
    { lat: 48.4525655, lng: 20.0177445 },
    { lat: 48.4517935, lng: 20.0177636 },
    { lat: 48.4512267, lng: 20.0176221 },
    { lat: 48.4505539, lng: 20.0173224 },
    { lat: 48.4501286, lng: 20.0172774 },
    { lat: 48.449844, lng: 20.017343 },
    { lat: 48.4491288, lng: 20.0170712 },
    { lat: 48.445965, lng: 20.0164686 },
    { lat: 48.4454425, lng: 20.0167625 },
    { lat: 48.4448125, lng: 20.0189111 },
    { lat: 48.444722, lng: 20.0195462 },
    { lat: 48.4439494, lng: 20.021434 },
    { lat: 48.4438031, lng: 20.0226495 },
    { lat: 48.4440428, lng: 20.0260662 },
    { lat: 48.4445611, lng: 20.0274435 },
    { lat: 48.4446065, lng: 20.0285809 },
    { lat: 48.4436308, lng: 20.0315996 },
    { lat: 48.4432942, lng: 20.0332467 },
    { lat: 48.4432971, lng: 20.0354237 },
    { lat: 48.4427909, lng: 20.0377881 },
    { lat: 48.4422495, lng: 20.0399642 },
    { lat: 48.4418809, lng: 20.0419019 },
    { lat: 48.4415773, lng: 20.0468613 },
    { lat: 48.4408681, lng: 20.0483375 },
    { lat: 48.4408863, lng: 20.0487459 },
    { lat: 48.4409741, lng: 20.0490686 },
    { lat: 48.4417167, lng: 20.050646 },
    { lat: 48.4420492, lng: 20.0517866 },
    { lat: 48.4421041, lng: 20.0522149 },
    { lat: 48.4418613, lng: 20.0535788 },
    { lat: 48.4417127, lng: 20.0539613 },
    { lat: 48.441259, lng: 20.0556479 },
    { lat: 48.4412272, lng: 20.056729 },
    { lat: 48.4414318, lng: 20.0573714 },
    { lat: 48.4413526, lng: 20.0575204 },
    { lat: 48.4414134, lng: 20.0577921 },
    { lat: 48.4419557, lng: 20.058863 },
    { lat: 48.441932, lng: 20.0591047 },
    { lat: 48.4421308, lng: 20.0593912 },
    { lat: 48.442121, lng: 20.0596443 },
    { lat: 48.4424345, lng: 20.0604912 },
    { lat: 48.4426175, lng: 20.0607483 },
    { lat: 48.4426284, lng: 20.0610921 },
    { lat: 48.442779, lng: 20.0617914 },
    { lat: 48.4425306, lng: 20.0631589 },
    { lat: 48.4426386, lng: 20.063538 },
    { lat: 48.4426315, lng: 20.0643458 },
    { lat: 48.4427993, lng: 20.0649158 },
    { lat: 48.4431207, lng: 20.0668248 },
    { lat: 48.4429677, lng: 20.0681614 },
    { lat: 48.4431053, lng: 20.0688751 },
    { lat: 48.4433777, lng: 20.0692494 },
    { lat: 48.4434954, lng: 20.0705929 },
    { lat: 48.4434124, lng: 20.0708746 },
    { lat: 48.443716, lng: 20.0719747 },
    { lat: 48.4435061, lng: 20.0723512 },
    { lat: 48.4434976, lng: 20.0727434 },
    { lat: 48.4435987, lng: 20.0731153 },
    { lat: 48.443594, lng: 20.0734354 },
    { lat: 48.4442459, lng: 20.0760693 },
    { lat: 48.4449016, lng: 20.0781193 },
    { lat: 48.4457557, lng: 20.0817471 },
    { lat: 48.4463828, lng: 20.0822821 },
    { lat: 48.4468789, lng: 20.0825448 },
    { lat: 48.4478099, lng: 20.0825814 },
    { lat: 48.4482305, lng: 20.0827291 },
    { lat: 48.4485187, lng: 20.0831628 },
    { lat: 48.4490353, lng: 20.084681 },
    { lat: 48.4497949, lng: 20.0863565 },
    { lat: 48.4504387, lng: 20.086618 },
    { lat: 48.4512998, lng: 20.0861906 },
    { lat: 48.4523125, lng: 20.0860845 },
    { lat: 48.4527905, lng: 20.0862045 },
    { lat: 48.4531684, lng: 20.0865994 },
    { lat: 48.4545771, lng: 20.088689 },
    { lat: 48.4552922, lng: 20.0893969 },
    { lat: 48.4556135, lng: 20.089186 },
    { lat: 48.4559381, lng: 20.0893236 },
    { lat: 48.4561552, lng: 20.0895919 },
    { lat: 48.45641, lng: 20.0907171 },
    { lat: 48.4569114, lng: 20.0911016 },
    { lat: 48.4570649, lng: 20.0913545 },
    { lat: 48.4603342, lng: 20.0995489 },
    { lat: 48.460352, lng: 20.1002514 },
    { lat: 48.4603227, lng: 20.1006647 },
    { lat: 48.4604968, lng: 20.1004043 },
    { lat: 48.4627053, lng: 20.0958564 },
    { lat: 48.4628761, lng: 20.0953543 },
    { lat: 48.4630165, lng: 20.0946616 },
    { lat: 48.4639368, lng: 20.0883522 },
    { lat: 48.4639652, lng: 20.0878371 },
    { lat: 48.4637728, lng: 20.0825203 },
    { lat: 48.4636177, lng: 20.0781957 },
    { lat: 48.4645058, lng: 20.0663429 },
    { lat: 48.4645549, lng: 20.0659781 },
    { lat: 48.4660572, lng: 20.0625425 },
    { lat: 48.4661562, lng: 20.0621641 },
    { lat: 48.4662036, lng: 20.0561897 },
    { lat: 48.4672378, lng: 20.0507745 },
    { lat: 48.4673156, lng: 20.049391 },
    { lat: 48.4678871, lng: 20.0482689 },
    { lat: 48.4681118, lng: 20.0474133 },
    { lat: 48.468338, lng: 20.0469196 },
    { lat: 48.468915, lng: 20.0461174 },
  ],
  Blhovce: [
    { lat: 48.256349, lng: 19.910526 },
    { lat: 48.255786, lng: 19.911306 },
    { lat: 48.255357, lng: 19.911722 },
    { lat: 48.25476, lng: 19.912275 },
    { lat: 48.254654, lng: 19.912372 },
    { lat: 48.254347, lng: 19.912912 },
    { lat: 48.254212, lng: 19.913153 },
    { lat: 48.253365, lng: 19.913868 },
    { lat: 48.252601, lng: 19.914517 },
    { lat: 48.252035, lng: 19.91523 },
    { lat: 48.251854, lng: 19.915315 },
    { lat: 48.251828, lng: 19.915632 },
    { lat: 48.251752, lng: 19.916071 },
    { lat: 48.25161, lng: 19.916657 },
    { lat: 48.251444, lng: 19.91726 },
    { lat: 48.251322, lng: 19.917242 },
    { lat: 48.251217, lng: 19.918184 },
    { lat: 48.251177, lng: 19.918596 },
    { lat: 48.251377, lng: 19.91923 },
    { lat: 48.251557, lng: 19.919972 },
    { lat: 48.251557, lng: 19.92034 },
    { lat: 48.251557, lng: 19.920574 },
    { lat: 48.251576, lng: 19.921624 },
    { lat: 48.251473, lng: 19.922255 },
    { lat: 48.251373, lng: 19.922544 },
    { lat: 48.251178, lng: 19.923101 },
    { lat: 48.251157, lng: 19.923168 },
    { lat: 48.251097, lng: 19.92336 },
    { lat: 48.251003, lng: 19.923702 },
    { lat: 48.250901, lng: 19.924075 },
    { lat: 48.250746, lng: 19.924752 },
    { lat: 48.250615, lng: 19.925022 },
    { lat: 48.250482, lng: 19.925367 },
    { lat: 48.250441, lng: 19.925472 },
    { lat: 48.250393, lng: 19.925946 },
    { lat: 48.25024, lng: 19.926879 },
    { lat: 48.250197, lng: 19.92714 },
    { lat: 48.249971, lng: 19.927908 },
    { lat: 48.249892, lng: 19.928125 },
    { lat: 48.249648, lng: 19.928793 },
    { lat: 48.249521, lng: 19.930024 },
    { lat: 48.249538, lng: 19.930828 },
    { lat: 48.249521, lng: 19.931364 },
    { lat: 48.249583, lng: 19.9322689 },
    { lat: 48.249447, lng: 19.933515 },
    { lat: 48.249408, lng: 19.933865 },
    { lat: 48.249385, lng: 19.934076 },
    { lat: 48.249356, lng: 19.934327 },
    { lat: 48.249551, lng: 19.934695 },
    { lat: 48.249731, lng: 19.935035 },
    { lat: 48.2496102, lng: 19.9364772 },
    { lat: 48.2496249, lng: 19.9370583 },
    { lat: 48.2496803, lng: 19.9379338 },
    { lat: 48.2498949, lng: 19.9387132 },
    { lat: 48.2504942, lng: 19.9404521 },
    { lat: 48.2509597, lng: 19.9412752 },
    { lat: 48.2511069, lng: 19.9417376 },
    { lat: 48.2515666, lng: 19.9425363 },
    { lat: 48.2530843, lng: 19.944466 },
    { lat: 48.2535135, lng: 19.9456504 },
    { lat: 48.2537579, lng: 19.9466536 },
    { lat: 48.253922, lng: 19.947148 },
    { lat: 48.2540282, lng: 19.9486857 },
    { lat: 48.2541186, lng: 19.9491935 },
    { lat: 48.2543809, lng: 19.9499878 },
    { lat: 48.254551, lng: 19.9506991 },
    { lat: 48.2544555, lng: 19.9539529 },
    { lat: 48.2543557, lng: 19.9546542 },
    { lat: 48.2539954, lng: 19.9561937 },
    { lat: 48.2535755, lng: 19.9592939 },
    { lat: 48.2534125, lng: 19.9600471 },
    { lat: 48.2535023, lng: 19.9604659 },
    { lat: 48.2536107, lng: 19.9607871 },
    { lat: 48.2538204, lng: 19.9605798 },
    { lat: 48.2543312, lng: 19.9613059 },
    { lat: 48.2546793, lng: 19.9610416 },
    { lat: 48.2548459, lng: 19.960563 },
    { lat: 48.2552736, lng: 19.9608675 },
    { lat: 48.2554505, lng: 19.9611352 },
    { lat: 48.2559367, lng: 19.9625 },
    { lat: 48.2563041, lng: 19.9631677 },
    { lat: 48.2563963, lng: 19.9645705 },
    { lat: 48.2568283, lng: 19.9664089 },
    { lat: 48.2568585, lng: 19.9664032 },
    { lat: 48.2568916, lng: 19.9666277 },
    { lat: 48.2571282, lng: 19.9672623 },
    { lat: 48.2573115, lng: 19.9680275 },
    { lat: 48.2574413, lng: 19.9683538 },
    { lat: 48.2581178, lng: 19.9696158 },
    { lat: 48.2585403, lng: 19.9710712 },
    { lat: 48.2586639, lng: 19.9713299 },
    { lat: 48.2597088, lng: 19.9727503 },
    { lat: 48.2604415, lng: 19.9739571 },
    { lat: 48.261103, lng: 19.9748385 },
    { lat: 48.2615585, lng: 19.9758328 },
    { lat: 48.2616207, lng: 19.9760256 },
    { lat: 48.2615829, lng: 19.9776335 },
    { lat: 48.2619164, lng: 19.9770195 },
    { lat: 48.2623376, lng: 19.9764322 },
    { lat: 48.2624962, lng: 19.9764016 },
    { lat: 48.2627097, lng: 19.9760995 },
    { lat: 48.2634164, lng: 19.9740882 },
    { lat: 48.2639475, lng: 19.9720274 },
    { lat: 48.264478, lng: 19.9705666 },
    { lat: 48.2647954, lng: 19.9693915 },
    { lat: 48.2651642, lng: 19.968446 },
    { lat: 48.2655024, lng: 19.9677626 },
    { lat: 48.2656304, lng: 19.9676956 },
    { lat: 48.2659937, lng: 19.967685 },
    { lat: 48.2662708, lng: 19.9678177 },
    { lat: 48.2665154, lng: 19.9680345 },
    { lat: 48.2669147, lng: 19.9681778 },
    { lat: 48.2677329, lng: 19.9688233 },
    { lat: 48.2683382, lng: 19.9688845 },
    { lat: 48.2689054, lng: 19.9694567 },
    { lat: 48.2697906, lng: 19.9710403 },
    { lat: 48.270302, lng: 19.9718675 },
    { lat: 48.2707078, lng: 19.971768 },
    { lat: 48.2709245, lng: 19.9707386 },
    { lat: 48.2713413, lng: 19.9694892 },
    { lat: 48.2717852, lng: 19.9688296 },
    { lat: 48.2720941, lng: 19.9685668 },
    { lat: 48.2724132, lng: 19.9684151 },
    { lat: 48.273611, lng: 19.9683159 },
    { lat: 48.2748046, lng: 19.9674553 },
    { lat: 48.2752981, lng: 19.9673255 },
    { lat: 48.27564, lng: 19.9671554 },
    { lat: 48.276619, lng: 19.9671017 },
    { lat: 48.2785039, lng: 19.9679074 },
    { lat: 48.2790946, lng: 19.9686037 },
    { lat: 48.2794165, lng: 19.9688068 },
    { lat: 48.2796464, lng: 19.9688408 },
    { lat: 48.2800585, lng: 19.9683835 },
    { lat: 48.2801647, lng: 19.9680201 },
    { lat: 48.2804334, lng: 19.9677105 },
    { lat: 48.2806475, lng: 19.9675536 },
    { lat: 48.2814687, lng: 19.9676094 },
    { lat: 48.2820919, lng: 19.9679026 },
    { lat: 48.282482, lng: 19.9673841 },
    { lat: 48.2829461, lng: 19.966455 },
    { lat: 48.2831837, lng: 19.9661077 },
    { lat: 48.2833102, lng: 19.9657186 },
    { lat: 48.2838204, lng: 19.9647033 },
    { lat: 48.2844149, lng: 19.9637161 },
    { lat: 48.2847824, lng: 19.9632208 },
    { lat: 48.2849042, lng: 19.9627703 },
    { lat: 48.2850781, lng: 19.9623579 },
    { lat: 48.2854137, lng: 19.9620398 },
    { lat: 48.2858013, lng: 19.9615118 },
    { lat: 48.2859865, lng: 19.96112 },
    { lat: 48.2868546, lng: 19.960317 },
    { lat: 48.2881536, lng: 19.9593674 },
    { lat: 48.2885127, lng: 19.9590954 },
    { lat: 48.2891044, lng: 19.9586495 },
    { lat: 48.2908538, lng: 19.9573208 },
    { lat: 48.2909159, lng: 19.9571928 },
    { lat: 48.2906605, lng: 19.9555345 },
    { lat: 48.2900947, lng: 19.9532793 },
    { lat: 48.2898501, lng: 19.9501785 },
    { lat: 48.2897969, lng: 19.9485324 },
    { lat: 48.2897017, lng: 19.9477824 },
    { lat: 48.2898835, lng: 19.9471017 },
    { lat: 48.2911831, lng: 19.943366 },
    { lat: 48.2916567, lng: 19.9416357 },
    { lat: 48.2917955, lng: 19.9414809 },
    { lat: 48.2918648, lng: 19.9406536 },
    { lat: 48.2921179, lng: 19.9393767 },
    { lat: 48.2922682, lng: 19.9380704 },
    { lat: 48.2927675, lng: 19.9347789 },
    { lat: 48.2928801, lng: 19.9337224 },
    { lat: 48.2931511, lng: 19.9321833 },
    { lat: 48.2931518, lng: 19.9314096 },
    { lat: 48.2930944, lng: 19.9313797 },
    { lat: 48.2933326, lng: 19.930707 },
    { lat: 48.2934612, lng: 19.930187 },
    { lat: 48.292835, lng: 19.9291632 },
    { lat: 48.2918981, lng: 19.9279612 },
    { lat: 48.2908053, lng: 19.9243034 },
    { lat: 48.290625, lng: 19.9232259 },
    { lat: 48.2906443, lng: 19.9220931 },
    { lat: 48.2905848, lng: 19.921946 },
    { lat: 48.2906773, lng: 19.9213934 },
    { lat: 48.290609, lng: 19.9207968 },
    { lat: 48.2906894, lng: 19.9206519 },
    { lat: 48.2907153, lng: 19.9203342 },
    { lat: 48.2906956, lng: 19.9197163 },
    { lat: 48.2904125, lng: 19.9181354 },
    { lat: 48.2904056, lng: 19.9175287 },
    { lat: 48.2904521, lng: 19.9172695 },
    { lat: 48.2903389, lng: 19.9167314 },
    { lat: 48.2902867, lng: 19.9161199 },
    { lat: 48.290169, lng: 19.9159257 },
    { lat: 48.2900964, lng: 19.9154639 },
    { lat: 48.2899305, lng: 19.9150657 },
    { lat: 48.2892113, lng: 19.9143465 },
    { lat: 48.2886973, lng: 19.9135258 },
    { lat: 48.2883512, lng: 19.9132553 },
    { lat: 48.2882582, lng: 19.9128769 },
    { lat: 48.2882536, lng: 19.9123719 },
    { lat: 48.2883817, lng: 19.9113157 },
    { lat: 48.2883728, lng: 19.9109546 },
    { lat: 48.2882527, lng: 19.9102301 },
    { lat: 48.2882396, lng: 19.9097662 },
    { lat: 48.2879563, lng: 19.9088593 },
    { lat: 48.2876422, lng: 19.9072409 },
    { lat: 48.2871925, lng: 19.9068669 },
    { lat: 48.2858145, lng: 19.9061849 },
    { lat: 48.285677, lng: 19.905553 },
    { lat: 48.285239, lng: 19.904478 },
    { lat: 48.284838, lng: 19.903844 },
    { lat: 48.283928, lng: 19.903495 },
    { lat: 48.283518, lng: 19.9035605 },
    { lat: 48.283218, lng: 19.903661 },
    { lat: 48.282281, lng: 19.903304 },
    { lat: 48.281472, lng: 19.903284 },
    { lat: 48.280953, lng: 19.903499 },
    { lat: 48.280085, lng: 19.90397 },
    { lat: 48.279489, lng: 19.904323 },
    { lat: 48.279328, lng: 19.904479 },
    { lat: 48.278858, lng: 19.905016 },
    { lat: 48.278382, lng: 19.905271 },
    { lat: 48.277836, lng: 19.905627 },
    { lat: 48.277631, lng: 19.905768 },
    { lat: 48.277591, lng: 19.905796 },
    { lat: 48.277518, lng: 19.905846 },
    { lat: 48.276837, lng: 19.906927 },
    { lat: 48.27684, lng: 19.90704 },
    { lat: 48.276842, lng: 19.907094 },
    { lat: 48.276637, lng: 19.907291 },
    { lat: 48.276433, lng: 19.907634 },
    { lat: 48.27622, lng: 19.907992 },
    { lat: 48.276247, lng: 19.908509 },
    { lat: 48.275683, lng: 19.908695 },
    { lat: 48.275479, lng: 19.909315 },
    { lat: 48.27508, lng: 19.909549 },
    { lat: 48.274809, lng: 19.909939 },
    { lat: 48.274413, lng: 19.910314 },
    { lat: 48.274163, lng: 19.910425 },
    { lat: 48.27394, lng: 19.910676 },
    { lat: 48.273918, lng: 19.9107 },
    { lat: 48.273324, lng: 19.911052 },
    { lat: 48.272874, lng: 19.911364 },
    { lat: 48.272691, lng: 19.911452 },
    { lat: 48.272198, lng: 19.91169 },
    { lat: 48.271402, lng: 19.911741 },
    { lat: 48.270993, lng: 19.911951 },
    { lat: 48.270203, lng: 19.912357 },
    { lat: 48.26935, lng: 19.912719 },
    { lat: 48.26933, lng: 19.911259 },
    { lat: 48.269273, lng: 19.910903 },
    { lat: 48.26912, lng: 19.910545 },
    { lat: 48.268733, lng: 19.910035 },
    { lat: 48.268195, lng: 19.909748 },
    { lat: 48.268082, lng: 19.909571 },
    { lat: 48.267811, lng: 19.909477 },
    { lat: 48.267519, lng: 19.909821 },
    { lat: 48.26701, lng: 19.909677 },
    { lat: 48.266887, lng: 19.909649 },
    { lat: 48.266528, lng: 19.909568 },
    { lat: 48.266173, lng: 19.909494 },
    { lat: 48.265434, lng: 19.909373 },
    { lat: 48.265035, lng: 19.909307 },
    { lat: 48.264548, lng: 19.909227 },
    { lat: 48.263383, lng: 19.909025 },
    { lat: 48.263226, lng: 19.909011 },
    { lat: 48.262662, lng: 19.908961 },
    { lat: 48.262106, lng: 19.908905 },
    { lat: 48.262076, lng: 19.908901 },
    { lat: 48.261886, lng: 19.908882 },
    { lat: 48.261845, lng: 19.908878 },
    { lat: 48.26123, lng: 19.909155 },
    { lat: 48.260993, lng: 19.909114 },
    { lat: 48.26054, lng: 19.909262 },
    { lat: 48.260235, lng: 19.909217 },
    { lat: 48.259922, lng: 19.909269 },
    { lat: 48.259589, lng: 19.909324 },
    { lat: 48.258585, lng: 19.909484 },
    { lat: 48.257661, lng: 19.908861 },
    { lat: 48.25729, lng: 19.909327 },
    { lat: 48.256849, lng: 19.909894 },
    { lat: 48.256349, lng: 19.910526 },
  ],
  Vlkyňa: [
    { lat: 48.267325, lng: 20.273116 },
    { lat: 48.26733, lng: 20.273773 },
    { lat: 48.2673, lng: 20.274346 },
    { lat: 48.267236, lng: 20.274995 },
    { lat: 48.267156, lng: 20.275867 },
    { lat: 48.267085, lng: 20.276325 },
    { lat: 48.266914, lng: 20.276937 },
    { lat: 48.266764, lng: 20.277484 },
    { lat: 48.266556, lng: 20.278471 },
    { lat: 48.266579, lng: 20.278958 },
    { lat: 48.266571, lng: 20.279067 },
    { lat: 48.266507, lng: 20.279585 },
    { lat: 48.266402, lng: 20.280329 },
    { lat: 48.266015, lng: 20.280943 },
    { lat: 48.265809, lng: 20.281077 },
    { lat: 48.265435, lng: 20.281247 },
    { lat: 48.265235, lng: 20.281355 },
    { lat: 48.265004, lng: 20.281505 },
    { lat: 48.264868, lng: 20.281565 },
    { lat: 48.264722, lng: 20.281572 },
    { lat: 48.264652, lng: 20.281593 },
    { lat: 48.264281, lng: 20.281705 },
    { lat: 48.264198, lng: 20.281765 },
    { lat: 48.264107, lng: 20.281833 },
    { lat: 48.263893, lng: 20.281976 },
    { lat: 48.263637, lng: 20.282141 },
    { lat: 48.263548, lng: 20.282232 },
    { lat: 48.263005, lng: 20.28294 },
    { lat: 48.262723, lng: 20.283315 },
    { lat: 48.262416, lng: 20.283803 },
    { lat: 48.262171, lng: 20.284206 },
    { lat: 48.261991, lng: 20.284558 },
    { lat: 48.261984, lng: 20.284822 },
    { lat: 48.261985, lng: 20.285066 },
    { lat: 48.261968, lng: 20.285293 },
    { lat: 48.261947, lng: 20.285425 },
    { lat: 48.261889, lng: 20.285637 },
    { lat: 48.261808, lng: 20.285878 },
    { lat: 48.261755, lng: 20.286207 },
    { lat: 48.261687, lng: 20.286557 },
    { lat: 48.261654, lng: 20.286698 },
    { lat: 48.26164, lng: 20.286858 },
    { lat: 48.261596, lng: 20.287555 },
    { lat: 48.261634, lng: 20.288075 },
    { lat: 48.261656, lng: 20.288297 },
    { lat: 48.261686, lng: 20.288454 },
    { lat: 48.261694, lng: 20.28856 },
    { lat: 48.261693, lng: 20.288804 },
    { lat: 48.261677, lng: 20.288984 },
    { lat: 48.26167, lng: 20.289105 },
    { lat: 48.261676, lng: 20.28927 },
    { lat: 48.261669, lng: 20.289377 },
    { lat: 48.261642, lng: 20.289506 },
    { lat: 48.261595, lng: 20.289646 },
    { lat: 48.261564, lng: 20.28981 },
    { lat: 48.261563, lng: 20.290063 },
    { lat: 48.261574, lng: 20.290251 },
    { lat: 48.261582, lng: 20.290628 },
    { lat: 48.261587, lng: 20.290768 },
    { lat: 48.26161, lng: 20.290901 },
    { lat: 48.261652, lng: 20.291058 },
    { lat: 48.261715, lng: 20.29131 },
    { lat: 48.261744, lng: 20.291407 },
    { lat: 48.261857, lng: 20.291863 },
    { lat: 48.261896, lng: 20.29193 },
    { lat: 48.262054, lng: 20.292169 },
    { lat: 48.262165, lng: 20.292363 },
    { lat: 48.262392, lng: 20.29271 },
    { lat: 48.262476, lng: 20.292836 },
    { lat: 48.262581, lng: 20.292963 },
    { lat: 48.262666, lng: 20.293042 },
    { lat: 48.262708, lng: 20.293092 },
    { lat: 48.26291, lng: 20.293398 },
    { lat: 48.262954, lng: 20.293532 },
    { lat: 48.263023, lng: 20.293724 },
    { lat: 48.263064, lng: 20.293902 },
    { lat: 48.263077, lng: 20.294119 },
    { lat: 48.263064, lng: 20.294417 },
    { lat: 48.263052, lng: 20.294533 },
    { lat: 48.262991, lng: 20.294788 },
    { lat: 48.262959, lng: 20.295425 },
    { lat: 48.262984, lng: 20.296137 },
    { lat: 48.262965, lng: 20.296492 },
    { lat: 48.262893, lng: 20.297213 },
    { lat: 48.262896, lng: 20.297402 },
    { lat: 48.26299, lng: 20.29795 },
    { lat: 48.26311, lng: 20.298664 },
    { lat: 48.263131, lng: 20.299207 },
    { lat: 48.263168, lng: 20.299662 },
    { lat: 48.263216, lng: 20.299917 },
    { lat: 48.263289, lng: 20.300252 },
    { lat: 48.263312, lng: 20.300325 },
    { lat: 48.26338, lng: 20.300469 },
    { lat: 48.263616, lng: 20.30095 },
    { lat: 48.263922, lng: 20.301436 },
    { lat: 48.263963, lng: 20.301529 },
    { lat: 48.263995, lng: 20.30164 },
    { lat: 48.264077, lng: 20.301977 },
    { lat: 48.264095, lng: 20.302016 },
    { lat: 48.264202, lng: 20.302239 },
    { lat: 48.264535, lng: 20.302976 },
    { lat: 48.264684, lng: 20.303327 },
    { lat: 48.264711, lng: 20.303426 },
    { lat: 48.264771, lng: 20.303902 },
    { lat: 48.264774, lng: 20.304021 },
    { lat: 48.26477, lng: 20.304284 },
    { lat: 48.264782, lng: 20.304519 },
    { lat: 48.264786, lng: 20.304591 },
    { lat: 48.264726, lng: 20.304894 },
    { lat: 48.264681, lng: 20.305067 },
    { lat: 48.264669, lng: 20.305152 },
    { lat: 48.264688, lng: 20.30532 },
    { lat: 48.264738, lng: 20.305662 },
    { lat: 48.264756, lng: 20.305849 },
    { lat: 48.264749, lng: 20.306125 },
    { lat: 48.264636, lng: 20.306416 },
    { lat: 48.264611, lng: 20.306512 },
    { lat: 48.264585, lng: 20.30666 },
    { lat: 48.264607, lng: 20.307051 },
    { lat: 48.264647, lng: 20.307313 },
    { lat: 48.264713, lng: 20.30753 },
    { lat: 48.264773, lng: 20.307711 },
    { lat: 48.264859, lng: 20.307889 },
    { lat: 48.264903, lng: 20.308006 },
    { lat: 48.264954, lng: 20.308179 },
    { lat: 48.264974, lng: 20.308288 },
    { lat: 48.264987, lng: 20.308381 },
    { lat: 48.264993, lng: 20.308587 },
    { lat: 48.265022, lng: 20.308827 },
    { lat: 48.265029, lng: 20.308879 },
    { lat: 48.265046, lng: 20.309022 },
    { lat: 48.265004, lng: 20.309479 },
    { lat: 48.265207, lng: 20.310172 },
    { lat: 48.265405, lng: 20.310438 },
    { lat: 48.265591, lng: 20.310963 },
    { lat: 48.266181, lng: 20.310577 },
    { lat: 48.267081, lng: 20.309742 },
    { lat: 48.268514, lng: 20.309456 },
    { lat: 48.26923, lng: 20.308239 },
    { lat: 48.269321, lng: 20.307735 },
    { lat: 48.269289, lng: 20.307237 },
    { lat: 48.269889, lng: 20.305762 },
    { lat: 48.27251, lng: 20.306555 },
    { lat: 48.273067, lng: 20.307711 },
    { lat: 48.273359, lng: 20.3091 },
    { lat: 48.273373, lng: 20.310182 },
    { lat: 48.273253, lng: 20.31234 },
    { lat: 48.273334, lng: 20.313045 },
    { lat: 48.272558, lng: 20.315989 },
    { lat: 48.2726958, lng: 20.3216639 },
    { lat: 48.272794, lng: 20.325711 },
    { lat: 48.274437, lng: 20.325914 },
    { lat: 48.276889, lng: 20.326353 },
    { lat: 48.287998, lng: 20.3280321 },
    { lat: 48.2877627, lng: 20.3268373 },
    { lat: 48.2873644, lng: 20.3260977 },
    { lat: 48.2872667, lng: 20.3255334 },
    { lat: 48.2873877, lng: 20.3234237 },
    { lat: 48.2867228, lng: 20.319378 },
    { lat: 48.2872919, lng: 20.3182722 },
    { lat: 48.2866296, lng: 20.3150633 },
    { lat: 48.2849655, lng: 20.30759 },
    { lat: 48.2847492, lng: 20.3063606 },
    { lat: 48.2847341, lng: 20.3057795 },
    { lat: 48.2848169, lng: 20.3054264 },
    { lat: 48.2866347, lng: 20.3014324 },
    { lat: 48.2878869, lng: 20.2938241 },
    { lat: 48.2880814, lng: 20.2934471 },
    { lat: 48.2909474, lng: 20.2901107 },
    { lat: 48.2914102, lng: 20.2898853 },
    { lat: 48.2959188, lng: 20.2891518 },
    { lat: 48.2968347, lng: 20.2889026 },
    { lat: 48.2976746, lng: 20.2884725 },
    { lat: 48.2983301, lng: 20.2880176 },
    { lat: 48.2983793, lng: 20.287894 },
    { lat: 48.298799, lng: 20.2874262 },
    { lat: 48.2986058, lng: 20.2871842 },
    { lat: 48.2977244, lng: 20.2868824 },
    { lat: 48.2974795, lng: 20.2866453 },
    { lat: 48.2974356, lng: 20.2863892 },
    { lat: 48.2976677, lng: 20.2851971 },
    { lat: 48.2993528, lng: 20.2783916 },
    { lat: 48.2997456, lng: 20.2768551 },
    { lat: 48.2998632, lng: 20.2766957 },
    { lat: 48.3001432, lng: 20.2725184 },
    { lat: 48.3001556, lng: 20.2721236 },
    { lat: 48.2997671, lng: 20.2723779 },
    { lat: 48.29862, lng: 20.2731588 },
    { lat: 48.2969565, lng: 20.2731004 },
    { lat: 48.2961717, lng: 20.2726555 },
    { lat: 48.2918997, lng: 20.2717135 },
    { lat: 48.2895942, lng: 20.2706356 },
    { lat: 48.2895152, lng: 20.2707428 },
    { lat: 48.2858364, lng: 20.2688053 },
    { lat: 48.2856813, lng: 20.2679677 },
    { lat: 48.2830698, lng: 20.2685939 },
    { lat: 48.2828604, lng: 20.2681182 },
    { lat: 48.2822997, lng: 20.2672723 },
    { lat: 48.2814401, lng: 20.2667838 },
    { lat: 48.2807022, lng: 20.2664714 },
    { lat: 48.2798741, lng: 20.2659528 },
    { lat: 48.2779583, lng: 20.2643138 },
    { lat: 48.2773664, lng: 20.2635623 },
    { lat: 48.2772743, lng: 20.2632874 },
    { lat: 48.2767378, lng: 20.2624875 },
    { lat: 48.2761807, lng: 20.2617924 },
    { lat: 48.2759916, lng: 20.2617068 },
    { lat: 48.2753284, lng: 20.2616526 },
    { lat: 48.2751797, lng: 20.261172 },
    { lat: 48.2748555, lng: 20.260471 },
    { lat: 48.274827, lng: 20.260265 },
    { lat: 48.274825, lng: 20.260358 },
    { lat: 48.274824, lng: 20.260372 },
    { lat: 48.274819, lng: 20.260614 },
    { lat: 48.274803, lng: 20.260771 },
    { lat: 48.274765, lng: 20.261017 },
    { lat: 48.274732, lng: 20.261124 },
    { lat: 48.274687, lng: 20.261195 },
    { lat: 48.274583, lng: 20.26126 },
    { lat: 48.274405, lng: 20.261329 },
    { lat: 48.274307, lng: 20.261409 },
    { lat: 48.274189, lng: 20.261576 },
    { lat: 48.274128, lng: 20.261631 },
    { lat: 48.274074, lng: 20.261663 },
    { lat: 48.273887, lng: 20.26168 },
    { lat: 48.273698, lng: 20.26172 },
    { lat: 48.273436, lng: 20.2617 },
    { lat: 48.27321, lng: 20.261708 },
    { lat: 48.27305, lng: 20.261747 },
    { lat: 48.272721, lng: 20.26178 },
    { lat: 48.27257, lng: 20.261912 },
    { lat: 48.272535, lng: 20.261942 },
    { lat: 48.272322, lng: 20.26226 },
    { lat: 48.27229, lng: 20.262312 },
    { lat: 48.272243, lng: 20.262339 },
    { lat: 48.271938, lng: 20.26274 },
    { lat: 48.271864, lng: 20.262842 },
    { lat: 48.271747, lng: 20.263049 },
    { lat: 48.271691, lng: 20.263172 },
    { lat: 48.271578, lng: 20.263381 },
    { lat: 48.271497, lng: 20.263563 },
    { lat: 48.271372, lng: 20.26393 },
    { lat: 48.271286, lng: 20.264311 },
    { lat: 48.271134, lng: 20.264864 },
    { lat: 48.271106, lng: 20.265337 },
    { lat: 48.270981, lng: 20.266554 },
    { lat: 48.270974, lng: 20.266756 },
    { lat: 48.270997, lng: 20.266905 },
    { lat: 48.271002, lng: 20.266994 },
    { lat: 48.270988, lng: 20.26711 },
    { lat: 48.270938, lng: 20.26732 },
    { lat: 48.270921, lng: 20.267453 },
    { lat: 48.270877, lng: 20.267601 },
    { lat: 48.270801, lng: 20.267802 },
    { lat: 48.270681, lng: 20.268053 },
    { lat: 48.270537, lng: 20.268393 },
    { lat: 48.270331, lng: 20.268971 },
    { lat: 48.270158, lng: 20.269134 },
    { lat: 48.269825, lng: 20.26926 },
    { lat: 48.269237, lng: 20.269389 },
    { lat: 48.269039, lng: 20.269489 },
    { lat: 48.268751, lng: 20.26972 },
    { lat: 48.268013, lng: 20.269623 },
    { lat: 48.267918, lng: 20.269956 },
    { lat: 48.2678, lng: 20.270365 },
    { lat: 48.267576, lng: 20.271629 },
    { lat: 48.267325, lng: 20.273116 },
  ],
  Lipovec: [
    { lat: 48.5602538, lng: 20.0758233 },
    { lat: 48.560077, lng: 20.0757154 },
    { lat: 48.5598073, lng: 20.0748322 },
    { lat: 48.5597843, lng: 20.0746277 },
    { lat: 48.5595483, lng: 20.0741481 },
    { lat: 48.5591102, lng: 20.0735246 },
    { lat: 48.5588934, lng: 20.0731054 },
    { lat: 48.5586639, lng: 20.0728635 },
    { lat: 48.5580698, lng: 20.0720071 },
    { lat: 48.5575893, lng: 20.0715796 },
    { lat: 48.557476, lng: 20.0713733 },
    { lat: 48.5566608, lng: 20.0710042 },
    { lat: 48.5563425, lng: 20.0706867 },
    { lat: 48.5558732, lng: 20.0703414 },
    { lat: 48.5552896, lng: 20.0703385 },
    { lat: 48.5552519, lng: 20.0698901 },
    { lat: 48.555117, lng: 20.0695391 },
    { lat: 48.5551089, lng: 20.0693699 },
    { lat: 48.5548628, lng: 20.068785 },
    { lat: 48.5546551, lng: 20.0688816 },
    { lat: 48.5544266, lng: 20.0688885 },
    { lat: 48.5538162, lng: 20.0685091 },
    { lat: 48.5528576, lng: 20.0676124 },
    { lat: 48.5521654, lng: 20.0668049 },
    { lat: 48.5514008, lng: 20.066215 },
    { lat: 48.5509719, lng: 20.065728 },
    { lat: 48.5504379, lng: 20.0646074 },
    { lat: 48.5500444, lng: 20.0642487 },
    { lat: 48.549931, lng: 20.0639255 },
    { lat: 48.5495858, lng: 20.0635068 },
    { lat: 48.5493531, lng: 20.0628404 },
    { lat: 48.5491584, lng: 20.0624882 },
    { lat: 48.5488148, lng: 20.0623253 },
    { lat: 48.5485135, lng: 20.0619857 },
    { lat: 48.5483665, lng: 20.0617005 },
    { lat: 48.5483329, lng: 20.0617285 },
    { lat: 48.547117, lng: 20.0607544 },
    { lat: 48.5464828, lng: 20.0601643 },
    { lat: 48.5453053, lng: 20.0587439 },
    { lat: 48.5452867, lng: 20.0588128 },
    { lat: 48.5441359, lng: 20.0580582 },
    { lat: 48.5433791, lng: 20.058226 },
    { lat: 48.5430314, lng: 20.0576342 },
    { lat: 48.5425581, lng: 20.0573882 },
    { lat: 48.542563, lng: 20.0573707 },
    { lat: 48.5425112, lng: 20.0574531 },
    { lat: 48.5420511, lng: 20.057577 },
    { lat: 48.5419451, lng: 20.057706 },
    { lat: 48.5417765, lng: 20.0583151 },
    { lat: 48.541668, lng: 20.0584121 },
    { lat: 48.5415718, lng: 20.0582949 },
    { lat: 48.541318, lng: 20.0584766 },
    { lat: 48.5411639, lng: 20.0585348 },
    { lat: 48.5412377, lng: 20.0597954 },
    { lat: 48.540347, lng: 20.0603227 },
    { lat: 48.5397398, lng: 20.0608621 },
    { lat: 48.5391267, lng: 20.0616736 },
    { lat: 48.5384742, lng: 20.0623725 },
    { lat: 48.5376658, lng: 20.0628678 },
    { lat: 48.537026, lng: 20.0631155 },
    { lat: 48.536135, lng: 20.0636864 },
    { lat: 48.5356598, lng: 20.0641533 },
    { lat: 48.5348653, lng: 20.0652589 },
    { lat: 48.5346454, lng: 20.0656584 },
    { lat: 48.5343417, lng: 20.0669503 },
    { lat: 48.5337351, lng: 20.0679135 },
    { lat: 48.533396, lng: 20.0688914 },
    { lat: 48.5330061, lng: 20.0694347 },
    { lat: 48.5318549, lng: 20.0704947 },
    { lat: 48.5316535, lng: 20.0708825 },
    { lat: 48.5312465, lng: 20.0722611 },
    { lat: 48.5310202, lng: 20.0752625 },
    { lat: 48.5306487, lng: 20.0763297 },
    { lat: 48.5304193, lng: 20.0766681 },
    { lat: 48.530441, lng: 20.076821 },
    { lat: 48.530453, lng: 20.076834 },
    { lat: 48.530571, lng: 20.076961 },
    { lat: 48.530872, lng: 20.077352 },
    { lat: 48.531035, lng: 20.077563 },
    { lat: 48.531197, lng: 20.077774 },
    { lat: 48.531306, lng: 20.077933 },
    { lat: 48.531613, lng: 20.078581 },
    { lat: 48.531703, lng: 20.078766 },
    { lat: 48.531741, lng: 20.079004 },
    { lat: 48.531791, lng: 20.079342 },
    { lat: 48.531906, lng: 20.079664 },
    { lat: 48.531995, lng: 20.079905 },
    { lat: 48.532008, lng: 20.079988 },
    { lat: 48.532049, lng: 20.080153 },
    { lat: 48.532123, lng: 20.080507 },
    { lat: 48.53219, lng: 20.080804 },
    { lat: 48.53229, lng: 20.081296 },
    { lat: 48.532373, lng: 20.081488 },
    { lat: 48.532478, lng: 20.081741 },
    { lat: 48.532629, lng: 20.082112 },
    { lat: 48.532712, lng: 20.082321 },
    { lat: 48.532871, lng: 20.082572 },
    { lat: 48.533218, lng: 20.083466 },
    { lat: 48.533336, lng: 20.083692 },
    { lat: 48.533361, lng: 20.083733 },
    { lat: 48.533782, lng: 20.083103 },
    { lat: 48.533844, lng: 20.082923 },
    { lat: 48.533875, lng: 20.082861 },
    { lat: 48.533939, lng: 20.082761 },
    { lat: 48.533975, lng: 20.082726 },
    { lat: 48.534328, lng: 20.082438 },
    { lat: 48.534471, lng: 20.082311 },
    { lat: 48.534658, lng: 20.082188 },
    { lat: 48.534791, lng: 20.082101 },
    { lat: 48.534839, lng: 20.082043 },
    { lat: 48.534943, lng: 20.081902 },
    { lat: 48.534967, lng: 20.081916 },
    { lat: 48.535014, lng: 20.081949 },
    { lat: 48.535048, lng: 20.081982 },
    { lat: 48.535072, lng: 20.081988 },
    { lat: 48.535084, lng: 20.082011 },
    { lat: 48.535104, lng: 20.082077 },
    { lat: 48.535107, lng: 20.082128 },
    { lat: 48.535129, lng: 20.082176 },
    { lat: 48.535149, lng: 20.082186 },
    { lat: 48.535169, lng: 20.082167 },
    { lat: 48.535173, lng: 20.08214 },
    { lat: 48.535182, lng: 20.082121 },
    { lat: 48.535195, lng: 20.082093 },
    { lat: 48.535229, lng: 20.08209 },
    { lat: 48.535272, lng: 20.08208 },
    { lat: 48.53535, lng: 20.082173 },
    { lat: 48.535378, lng: 20.082219 },
    { lat: 48.535428, lng: 20.082375 },
    { lat: 48.535448, lng: 20.082396 },
    { lat: 48.535483, lng: 20.082405 },
    { lat: 48.53554, lng: 20.082549 },
    { lat: 48.535561, lng: 20.082624 },
    { lat: 48.535615, lng: 20.082652 },
    { lat: 48.535684, lng: 20.082639 },
    { lat: 48.535763, lng: 20.082671 },
    { lat: 48.535788, lng: 20.082719 },
    { lat: 48.535846, lng: 20.082729 },
    { lat: 48.5359, lng: 20.082694 },
    { lat: 48.535956, lng: 20.082664 },
    { lat: 48.535998, lng: 20.082667 },
    { lat: 48.536034, lng: 20.082681 },
    { lat: 48.536075, lng: 20.08268 },
    { lat: 48.536115, lng: 20.082647 },
    { lat: 48.536155, lng: 20.082595 },
    { lat: 48.536188, lng: 20.082572 },
    { lat: 48.536242, lng: 20.08254 },
    { lat: 48.536287, lng: 20.082554 },
    { lat: 48.536354, lng: 20.08252 },
    { lat: 48.536382, lng: 20.082488 },
    { lat: 48.536418, lng: 20.082476 },
    { lat: 48.53648, lng: 20.082475 },
    { lat: 48.536538, lng: 20.082461 },
    { lat: 48.53668, lng: 20.082502 },
    { lat: 48.536732, lng: 20.082467 },
    { lat: 48.536812, lng: 20.082466 },
    { lat: 48.536867, lng: 20.082508 },
    { lat: 48.536912, lng: 20.082516 },
    { lat: 48.536957, lng: 20.082479 },
    { lat: 48.537006, lng: 20.082486 },
    { lat: 48.537037, lng: 20.082513 },
    { lat: 48.537074, lng: 20.082512 },
    { lat: 48.537109, lng: 20.082444 },
    { lat: 48.53717, lng: 20.082423 },
    { lat: 48.537188, lng: 20.082437 },
    { lat: 48.537229, lng: 20.082474 },
    { lat: 48.537271, lng: 20.082479 },
    { lat: 48.537307, lng: 20.082443 },
    { lat: 48.537457, lng: 20.082474 },
    { lat: 48.537511, lng: 20.08243 },
    { lat: 48.537544, lng: 20.082418 },
    { lat: 48.53761, lng: 20.082421 },
    { lat: 48.537687, lng: 20.082339 },
    { lat: 48.537742, lng: 20.082331 },
    { lat: 48.537763, lng: 20.082347 },
    { lat: 48.537794, lng: 20.082392 },
    { lat: 48.537829, lng: 20.082401 },
    { lat: 48.537862, lng: 20.082383 },
    { lat: 48.53792, lng: 20.082395 },
    { lat: 48.537963, lng: 20.08245 },
    { lat: 48.538012, lng: 20.082452 },
    { lat: 48.538049, lng: 20.082415 },
    { lat: 48.538124, lng: 20.082391 },
    { lat: 48.53813, lng: 20.082327 },
    { lat: 48.538141, lng: 20.082294 },
    { lat: 48.538199, lng: 20.082285 },
    { lat: 48.538227, lng: 20.082241 },
    { lat: 48.538301, lng: 20.082255 },
    { lat: 48.538338, lng: 20.082299 },
    { lat: 48.5384, lng: 20.08229 },
    { lat: 48.538422, lng: 20.082264 },
    { lat: 48.53846, lng: 20.08227 },
    { lat: 48.538489, lng: 20.082294 },
    { lat: 48.538538, lng: 20.082265 },
    { lat: 48.538572, lng: 20.082206 },
    { lat: 48.538601, lng: 20.082194 },
    { lat: 48.538635, lng: 20.082155 },
    { lat: 48.538641, lng: 20.082098 },
    { lat: 48.538694, lng: 20.082063 },
    { lat: 48.538729, lng: 20.08206 },
    { lat: 48.538734, lng: 20.082024 },
    { lat: 48.53876, lng: 20.081994 },
    { lat: 48.538783, lng: 20.081981 },
    { lat: 48.538804, lng: 20.081999 },
    { lat: 48.538827, lng: 20.081982 },
    { lat: 48.538844, lng: 20.081933 },
    { lat: 48.538837, lng: 20.081883 },
    { lat: 48.538855, lng: 20.081825 },
    { lat: 48.538888, lng: 20.081819 },
    { lat: 48.538905, lng: 20.081752 },
    { lat: 48.538942, lng: 20.081715 },
    { lat: 48.538963, lng: 20.081723 },
    { lat: 48.538981, lng: 20.081784 },
    { lat: 48.538968, lng: 20.081792 },
    { lat: 48.539576, lng: 20.082972 },
    { lat: 48.540204, lng: 20.084172 },
    { lat: 48.54072, lng: 20.085162 },
    { lat: 48.540964, lng: 20.085634 },
    { lat: 48.541004, lng: 20.085689 },
    { lat: 48.541186, lng: 20.085214 },
    { lat: 48.54131, lng: 20.084905 },
    { lat: 48.541546, lng: 20.084591 },
    { lat: 48.541962, lng: 20.084291 },
    { lat: 48.542298, lng: 20.084068 },
    { lat: 48.542698, lng: 20.083857 },
    { lat: 48.542848, lng: 20.083798 },
    { lat: 48.543134, lng: 20.083661 },
    { lat: 48.543276, lng: 20.083575 },
    { lat: 48.543478, lng: 20.0834 },
    { lat: 48.543701, lng: 20.083078 },
    { lat: 48.543838, lng: 20.082955 },
    { lat: 48.544088, lng: 20.082767 },
    { lat: 48.544188, lng: 20.082667 },
    { lat: 48.544287, lng: 20.082585 },
    { lat: 48.544347, lng: 20.082556 },
    { lat: 48.544414, lng: 20.082573 },
    { lat: 48.544577, lng: 20.082772 },
    { lat: 48.544692, lng: 20.082853 },
    { lat: 48.544977, lng: 20.083055 },
    { lat: 48.545019, lng: 20.083051 },
    { lat: 48.545452, lng: 20.082905 },
    { lat: 48.545879, lng: 20.082646 },
    { lat: 48.546117, lng: 20.082597 },
    { lat: 48.546258, lng: 20.082578 },
    { lat: 48.546398, lng: 20.082588 },
    { lat: 48.546468, lng: 20.082605 },
    { lat: 48.546995, lng: 20.082179 },
    { lat: 48.547073, lng: 20.082139 },
    { lat: 48.547154, lng: 20.082121 },
    { lat: 48.547208, lng: 20.082123 },
    { lat: 48.547292, lng: 20.082154 },
    { lat: 48.547494, lng: 20.082282 },
    { lat: 48.54767, lng: 20.082493 },
    { lat: 48.547757, lng: 20.082628 },
    { lat: 48.547881, lng: 20.082759 },
    { lat: 48.547952, lng: 20.082816 },
    { lat: 48.548364, lng: 20.082906 },
    { lat: 48.548583, lng: 20.082932 },
    { lat: 48.548746, lng: 20.082942 },
    { lat: 48.548883, lng: 20.082969 },
    { lat: 48.548988, lng: 20.083012 },
    { lat: 48.549189, lng: 20.083157 },
    { lat: 48.549264, lng: 20.083203 },
    { lat: 48.549345, lng: 20.083225 },
    { lat: 48.549394, lng: 20.083223 },
    { lat: 48.54944, lng: 20.083191 },
    { lat: 48.549472, lng: 20.083142 },
    { lat: 48.549575, lng: 20.082889 },
    { lat: 48.549671, lng: 20.082695 },
    { lat: 48.549712, lng: 20.082645 },
    { lat: 48.549884, lng: 20.082536 },
    { lat: 48.550462, lng: 20.082386 },
    { lat: 48.550903, lng: 20.082284 },
    { lat: 48.551041, lng: 20.082249 },
    { lat: 48.551293, lng: 20.082301 },
    { lat: 48.551637, lng: 20.082465 },
    { lat: 48.551939, lng: 20.082604 },
    { lat: 48.55228, lng: 20.082683 },
    { lat: 48.552444, lng: 20.082696 },
    { lat: 48.552506, lng: 20.082679 },
    { lat: 48.552666, lng: 20.082615 },
    { lat: 48.552989, lng: 20.082588 },
    { lat: 48.55305, lng: 20.082571 },
    { lat: 48.553211, lng: 20.082535 },
    { lat: 48.553292, lng: 20.082535 },
    { lat: 48.553488, lng: 20.082637 },
    { lat: 48.553678, lng: 20.082645 },
    { lat: 48.553963, lng: 20.082558 },
    { lat: 48.554101, lng: 20.082528 },
    { lat: 48.5547433, lng: 20.0823954 },
    { lat: 48.5552462, lng: 20.0820842 },
    { lat: 48.5554425, lng: 20.0817341 },
    { lat: 48.5562167, lng: 20.0811141 },
    { lat: 48.5564514, lng: 20.0807177 },
    { lat: 48.557646, lng: 20.0797786 },
    { lat: 48.5577207, lng: 20.0796503 },
    { lat: 48.5577285, lng: 20.0795355 },
    { lat: 48.5575318, lng: 20.0791404 },
    { lat: 48.5575683, lng: 20.0790406 },
    { lat: 48.55777, lng: 20.0788478 },
    { lat: 48.5581709, lng: 20.0787147 },
    { lat: 48.5585562, lng: 20.0783842 },
    { lat: 48.5587606, lng: 20.0780696 },
    { lat: 48.5591282, lng: 20.0772895 },
    { lat: 48.5597975, lng: 20.0768592 },
    { lat: 48.5599853, lng: 20.0765528 },
    { lat: 48.560251, lng: 20.0758356 },
    { lat: 48.5602538, lng: 20.0758233 },
  ],
  Kyjatice: [
    { lat: 48.5465088, lng: 19.99361 },
    { lat: 48.546343, lng: 19.9940346 },
    { lat: 48.5462529, lng: 19.9943052 },
    { lat: 48.5460928, lng: 19.9944605 },
    { lat: 48.5453172, lng: 19.9946602 },
    { lat: 48.544275, lng: 19.9947872 },
    { lat: 48.5426911, lng: 19.9952985 },
    { lat: 48.5425442, lng: 19.9952581 },
    { lat: 48.5424938, lng: 19.9951314 },
    { lat: 48.542042, lng: 19.9950247 },
    { lat: 48.5416949, lng: 19.9950994 },
    { lat: 48.5409802, lng: 19.995112 },
    { lat: 48.5407441, lng: 19.9952848 },
    { lat: 48.5404934, lng: 19.9953517 },
    { lat: 48.5403023, lng: 19.9952807 },
    { lat: 48.5400578, lng: 19.9953843 },
    { lat: 48.5396886, lng: 19.9953654 },
    { lat: 48.5384892, lng: 19.995962 },
    { lat: 48.5383923, lng: 19.9962005 },
    { lat: 48.538186, lng: 19.9962781 },
    { lat: 48.5380855, lng: 19.9961668 },
    { lat: 48.5379252, lng: 19.9962915 },
    { lat: 48.5378009, lng: 19.996309 },
    { lat: 48.5376427, lng: 19.9964606 },
    { lat: 48.5372837, lng: 19.9962534 },
    { lat: 48.5369979, lng: 19.9963393 },
    { lat: 48.5368609, lng: 19.9965673 },
    { lat: 48.5364371, lng: 19.9967225 },
    { lat: 48.536236, lng: 19.996678 },
    { lat: 48.5360337, lng: 19.9967723 },
    { lat: 48.5357438, lng: 19.9966264 },
    { lat: 48.5355966, lng: 19.9967421 },
    { lat: 48.5352872, lng: 19.9967545 },
    { lat: 48.5352207, lng: 19.9968553 },
    { lat: 48.5350432, lng: 19.996868 },
    { lat: 48.5349738, lng: 19.9969353 },
    { lat: 48.5348909, lng: 19.9968675 },
    { lat: 48.5347322, lng: 19.9970287 },
    { lat: 48.5344637, lng: 19.9975249 },
    { lat: 48.5340265, lng: 19.9981426 },
    { lat: 48.5339914, lng: 19.9983531 },
    { lat: 48.5337849, lng: 19.9984275 },
    { lat: 48.5333353, lng: 19.9989288 },
    { lat: 48.5328674, lng: 19.999322 },
    { lat: 48.5327358, lng: 19.9994866 },
    { lat: 48.5326413, lng: 19.9998677 },
    { lat: 48.5325534, lng: 19.9998651 },
    { lat: 48.5324549, lng: 20.0001502 },
    { lat: 48.5322971, lng: 20.0001752 },
    { lat: 48.5322538, lng: 20.0002966 },
    { lat: 48.5320946, lng: 20.000424 },
    { lat: 48.5320946, lng: 20.0006092 },
    { lat: 48.531879, lng: 20.0008678 },
    { lat: 48.5316402, lng: 20.0013238 },
    { lat: 48.5312822, lng: 20.0016244 },
    { lat: 48.5311416, lng: 20.0018748 },
    { lat: 48.5310587, lng: 20.001856 },
    { lat: 48.5308947, lng: 20.0020262 },
    { lat: 48.5308542, lng: 20.0024054 },
    { lat: 48.5307862, lng: 20.0025295 },
    { lat: 48.5308207, lng: 20.0027618 },
    { lat: 48.5307555, lng: 20.0030577 },
    { lat: 48.5306603, lng: 20.003045 },
    { lat: 48.5305651, lng: 20.0031945 },
    { lat: 48.5306362, lng: 20.0033586 },
    { lat: 48.5305623, lng: 20.003634 },
    { lat: 48.5305628, lng: 20.0040807 },
    { lat: 48.5304007, lng: 20.0044596 },
    { lat: 48.5299092, lng: 20.0050373 },
    { lat: 48.5296777, lng: 20.0049708 },
    { lat: 48.5295472, lng: 20.005136 },
    { lat: 48.5294814, lng: 20.0053953 },
    { lat: 48.5294124, lng: 20.005496 },
    { lat: 48.5289071, lng: 20.005908 },
    { lat: 48.5286573, lng: 20.0063471 },
    { lat: 48.5283634, lng: 20.0064873 },
    { lat: 48.5282772, lng: 20.0064685 },
    { lat: 48.5281953, lng: 20.006638 },
    { lat: 48.5280689, lng: 20.0066575 },
    { lat: 48.5279591, lng: 20.0069717 },
    { lat: 48.5278697, lng: 20.0069856 },
    { lat: 48.5277866, lng: 20.007238 },
    { lat: 48.5276094, lng: 20.0071435 },
    { lat: 48.5274246, lng: 20.0072009 },
    { lat: 48.5273424, lng: 20.007364 },
    { lat: 48.5271841, lng: 20.0075048 },
    { lat: 48.5270461, lng: 20.0074117 },
    { lat: 48.5269093, lng: 20.0075103 },
    { lat: 48.5268613, lng: 20.0076279 },
    { lat: 48.5267388, lng: 20.0076608 },
    { lat: 48.5266838, lng: 20.0078245 },
    { lat: 48.5266125, lng: 20.0078754 },
    { lat: 48.5258319, lng: 20.0081965 },
    { lat: 48.5258265, lng: 20.0080939 },
    { lat: 48.525294, lng: 20.0080222 },
    { lat: 48.5252529, lng: 20.0081402 },
    { lat: 48.5250522, lng: 20.0080811 },
    { lat: 48.5249779, lng: 20.0081617 },
    { lat: 48.524819, lng: 20.008111 },
    { lat: 48.5247824, lng: 20.0080438 },
    { lat: 48.5245918, lng: 20.0080118 },
    { lat: 48.524054, lng: 20.0080259 },
    { lat: 48.5238323, lng: 20.0081088 },
    { lat: 48.5225861, lng: 20.008102 },
    { lat: 48.5191184, lng: 20.0117491 },
    { lat: 48.5169413, lng: 20.013208 },
    { lat: 48.5151319, lng: 20.0158399 },
    { lat: 48.5151411, lng: 20.0158966 },
    { lat: 48.5152216, lng: 20.0159078 },
    { lat: 48.5152678, lng: 20.0160459 },
    { lat: 48.5153932, lng: 20.0159473 },
    { lat: 48.5153735, lng: 20.0161762 },
    { lat: 48.5151647, lng: 20.0163008 },
    { lat: 48.5151579, lng: 20.0164038 },
    { lat: 48.5152618, lng: 20.0163883 },
    { lat: 48.5153407, lng: 20.0165481 },
    { lat: 48.5152999, lng: 20.0167058 },
    { lat: 48.515313, lng: 20.016884 },
    { lat: 48.5152768, lng: 20.0169333 },
    { lat: 48.5152081, lng: 20.0169043 },
    { lat: 48.5151765, lng: 20.0170405 },
    { lat: 48.515241, lng: 20.0172056 },
    { lat: 48.5146532, lng: 20.0175875 },
    { lat: 48.5148747, lng: 20.0182792 },
    { lat: 48.5149389, lng: 20.0190348 },
    { lat: 48.5152114, lng: 20.0206476 },
    { lat: 48.5153059, lng: 20.0214558 },
    { lat: 48.5156658, lng: 20.0213091 },
    { lat: 48.5155875, lng: 20.0215652 },
    { lat: 48.5157156, lng: 20.0218224 },
    { lat: 48.5161176, lng: 20.0214159 },
    { lat: 48.5163256, lng: 20.0210846 },
    { lat: 48.5165542, lng: 20.0204597 },
    { lat: 48.5173097, lng: 20.0191596 },
    { lat: 48.5176358, lng: 20.0190661 },
    { lat: 48.5178501, lng: 20.0189137 },
    { lat: 48.517993, lng: 20.0190455 },
    { lat: 48.5180886, lng: 20.0192531 },
    { lat: 48.5186128, lng: 20.0188564 },
    { lat: 48.5187986, lng: 20.0191003 },
    { lat: 48.5192219, lng: 20.0185279 },
    { lat: 48.5192808, lng: 20.0186982 },
    { lat: 48.5196835, lng: 20.0183605 },
    { lat: 48.5199173, lng: 20.0187239 },
    { lat: 48.5203236, lng: 20.0181286 },
    { lat: 48.5206364, lng: 20.0177956 },
    { lat: 48.5208186, lng: 20.01744 },
    { lat: 48.5216302, lng: 20.0167768 },
    { lat: 48.5225999, lng: 20.0156417 },
    { lat: 48.5226536, lng: 20.0160626 },
    { lat: 48.522765, lng: 20.0162255 },
    { lat: 48.522698, lng: 20.0164799 },
    { lat: 48.5230181, lng: 20.0169438 },
    { lat: 48.5234978, lng: 20.0162108 },
    { lat: 48.5234142, lng: 20.0160979 },
    { lat: 48.5246339, lng: 20.0150154 },
    { lat: 48.524804, lng: 20.015663 },
    { lat: 48.5250157, lng: 20.0160125 },
    { lat: 48.5251263, lng: 20.0160937 },
    { lat: 48.5253686, lng: 20.0161536 },
    { lat: 48.5255864, lng: 20.0160274 },
    { lat: 48.5255781, lng: 20.0158576 },
    { lat: 48.5256719, lng: 20.0157019 },
    { lat: 48.5257877, lng: 20.0161568 },
    { lat: 48.5257238, lng: 20.0167794 },
    { lat: 48.5260571, lng: 20.0167791 },
    { lat: 48.5261439, lng: 20.0166696 },
    { lat: 48.5261469, lng: 20.0165283 },
    { lat: 48.5262125, lng: 20.0164034 },
    { lat: 48.526471, lng: 20.015387 },
    { lat: 48.5266396, lng: 20.0155139 },
    { lat: 48.5267242, lng: 20.0160564 },
    { lat: 48.526861, lng: 20.0162296 },
    { lat: 48.5270225, lng: 20.0163781 },
    { lat: 48.5273106, lng: 20.0164752 },
    { lat: 48.5278364, lng: 20.0164456 },
    { lat: 48.5279237, lng: 20.0170209 },
    { lat: 48.5285036, lng: 20.017069 },
    { lat: 48.5288642, lng: 20.0176944 },
    { lat: 48.5290564, lng: 20.0179267 },
    { lat: 48.5293066, lng: 20.0180629 },
    { lat: 48.5294562, lng: 20.0182197 },
    { lat: 48.5295559, lng: 20.0185049 },
    { lat: 48.5297746, lng: 20.0188054 },
    { lat: 48.5301716, lng: 20.0180094 },
    { lat: 48.5305034, lng: 20.0174818 },
    { lat: 48.530778, lng: 20.0167237 },
    { lat: 48.5309206, lng: 20.0164692 },
    { lat: 48.5308898, lng: 20.0162749 },
    { lat: 48.5310128, lng: 20.016168 },
    { lat: 48.5307836, lng: 20.0157789 },
    { lat: 48.5308962, lng: 20.0154299 },
    { lat: 48.5310422, lng: 20.015985 },
    { lat: 48.5313867, lng: 20.0167104 },
    { lat: 48.5317495, lng: 20.0172746 },
    { lat: 48.5318755, lng: 20.0185659 },
    { lat: 48.5317963, lng: 20.0185851 },
    { lat: 48.5318968, lng: 20.0189409 },
    { lat: 48.5318928, lng: 20.0193027 },
    { lat: 48.5318276, lng: 20.0193356 },
    { lat: 48.5318733, lng: 20.0195633 },
    { lat: 48.5319422, lng: 20.0196766 },
    { lat: 48.5322091, lng: 20.0197533 },
    { lat: 48.532583, lng: 20.0192971 },
    { lat: 48.532688, lng: 20.0190583 },
    { lat: 48.5331846, lng: 20.0185673 },
    { lat: 48.5336156, lng: 20.0181821 },
    { lat: 48.5338158, lng: 20.0182566 },
    { lat: 48.5340722, lng: 20.0185386 },
    { lat: 48.5342953, lng: 20.0194039 },
    { lat: 48.5346005, lng: 20.0202916 },
    { lat: 48.5344961, lng: 20.0206242 },
    { lat: 48.5344952, lng: 20.0207661 },
    { lat: 48.5345422, lng: 20.0211324 },
    { lat: 48.5346054, lng: 20.0213006 },
    { lat: 48.5345922, lng: 20.0216068 },
    { lat: 48.5349873, lng: 20.0220812 },
    { lat: 48.5357054, lng: 20.021908 },
    { lat: 48.5360808, lng: 20.0215362 },
    { lat: 48.5363136, lng: 20.0208253 },
    { lat: 48.536826, lng: 20.020475 },
    { lat: 48.536969, lng: 20.0204732 },
    { lat: 48.5369804, lng: 20.0202311 },
    { lat: 48.5370417, lng: 20.0200835 },
    { lat: 48.5371392, lng: 20.0201507 },
    { lat: 48.5372628, lng: 20.0199303 },
    { lat: 48.5372466, lng: 20.0188915 },
    { lat: 48.537388, lng: 20.0188298 },
    { lat: 48.5376985, lng: 20.0188711 },
    { lat: 48.5385978, lng: 20.0187475 },
    { lat: 48.5406067, lng: 20.0171918 },
    { lat: 48.540946, lng: 20.0174721 },
    { lat: 48.5411544, lng: 20.0175003 },
    { lat: 48.5415795, lng: 20.0168333 },
    { lat: 48.5421252, lng: 20.0164414 },
    { lat: 48.5424173, lng: 20.0165307 },
    { lat: 48.5427352, lng: 20.0164277 },
    { lat: 48.5438187, lng: 20.0164796 },
    { lat: 48.5451006, lng: 20.017748 },
    { lat: 48.5452613, lng: 20.0181718 },
    { lat: 48.5460192, lng: 20.0177584 },
    { lat: 48.5461819, lng: 20.0185553 },
    { lat: 48.5463929, lng: 20.0185886 },
    { lat: 48.5464469, lng: 20.019345 },
    { lat: 48.5466219, lng: 20.0199754 },
    { lat: 48.5463533, lng: 20.0210144 },
    { lat: 48.5461982, lng: 20.0212907 },
    { lat: 48.5461236, lng: 20.0220131 },
    { lat: 48.5466038, lng: 20.0233167 },
    { lat: 48.5464702, lng: 20.0239721 },
    { lat: 48.5467098, lng: 20.024424 },
    { lat: 48.5473208, lng: 20.0258657 },
    { lat: 48.5478263, lng: 20.0237517 },
    { lat: 48.5480191, lng: 20.0235418 },
    { lat: 48.5480848, lng: 20.0227403 },
    { lat: 48.5482686, lng: 20.0221018 },
    { lat: 48.5485621, lng: 20.0213843 },
    { lat: 48.5486284, lng: 20.0208991 },
    { lat: 48.5488248, lng: 20.0205044 },
    { lat: 48.5489001, lng: 20.0198754 },
    { lat: 48.5492162, lng: 20.0190584 },
    { lat: 48.5492763, lng: 20.0187736 },
    { lat: 48.549776, lng: 20.0185481 },
    { lat: 48.5498991, lng: 20.0181784 },
    { lat: 48.5499113, lng: 20.0179259 },
    { lat: 48.5499791, lng: 20.0177986 },
    { lat: 48.5500855, lng: 20.0177867 },
    { lat: 48.550222, lng: 20.0176284 },
    { lat: 48.5503727, lng: 20.0172191 },
    { lat: 48.5505342, lng: 20.0169603 },
    { lat: 48.5507835, lng: 20.0168598 },
    { lat: 48.5508884, lng: 20.0166483 },
    { lat: 48.5516161, lng: 20.0159969 },
    { lat: 48.5516869, lng: 20.0157833 },
    { lat: 48.5520761, lng: 20.0154427 },
    { lat: 48.5523588, lng: 20.0148492 },
    { lat: 48.5523317, lng: 20.0148222 },
    { lat: 48.5524523, lng: 20.0144759 },
    { lat: 48.5527035, lng: 20.0142288 },
    { lat: 48.5530153, lng: 20.0134159 },
    { lat: 48.5533816, lng: 20.0130996 },
    { lat: 48.553532, lng: 20.0128464 },
    { lat: 48.5535929, lng: 20.0124697 },
    { lat: 48.553689, lng: 20.0122604 },
    { lat: 48.5541234, lng: 20.0118228 },
    { lat: 48.5543106, lng: 20.0115294 },
    { lat: 48.5543107, lng: 20.0113968 },
    { lat: 48.5546358, lng: 20.0109307 },
    { lat: 48.5547684, lng: 20.0104936 },
    { lat: 48.5548686, lng: 20.0104357 },
    { lat: 48.5548971, lng: 20.0101745 },
    { lat: 48.5551542, lng: 20.0099169 },
    { lat: 48.5554735, lng: 20.0082566 },
    { lat: 48.5554282, lng: 20.0080092 },
    { lat: 48.555563, lng: 20.007436 },
    { lat: 48.5556898, lng: 20.0073173 },
    { lat: 48.555725, lng: 20.0071369 },
    { lat: 48.5556811, lng: 20.0070546 },
    { lat: 48.5558151, lng: 20.0067866 },
    { lat: 48.5561752, lng: 20.0052739 },
    { lat: 48.5563432, lng: 20.0048174 },
    { lat: 48.5563478, lng: 20.0037719 },
    { lat: 48.5564916, lng: 20.0032533 },
    { lat: 48.5564746, lng: 20.0030632 },
    { lat: 48.5562973, lng: 20.0025393 },
    { lat: 48.5563246, lng: 20.0019841 },
    { lat: 48.556286, lng: 20.0011988 },
    { lat: 48.5569911, lng: 19.9997204 },
    { lat: 48.5572897, lng: 19.9982184 },
    { lat: 48.5572793, lng: 19.9979417 },
    { lat: 48.5574321, lng: 19.9970986 },
    { lat: 48.5579012, lng: 19.9962525 },
    { lat: 48.5579302, lng: 19.9959243 },
    { lat: 48.5582335, lng: 19.9954193 },
    { lat: 48.5582687, lng: 19.9952399 },
    { lat: 48.558764, lng: 19.9944816 },
    { lat: 48.5590868, lng: 19.9943103 },
    { lat: 48.5592452, lng: 19.9937889 },
    { lat: 48.5594533, lng: 19.9934323 },
    { lat: 48.5595505, lng: 19.993066 },
    { lat: 48.560036, lng: 19.9924241 },
    { lat: 48.560366, lng: 19.9916939 },
    { lat: 48.5605772, lng: 19.9913882 },
    { lat: 48.5606828, lng: 19.9905762 },
    { lat: 48.5606686, lng: 19.9900751 },
    { lat: 48.5607034, lng: 19.9898356 },
    { lat: 48.5606736, lng: 19.9898232 },
    { lat: 48.5606778, lng: 19.9896525 },
    { lat: 48.5599874, lng: 19.9897894 },
    { lat: 48.559478, lng: 19.9895688 },
    { lat: 48.5587923, lng: 19.9896043 },
    { lat: 48.558083, lng: 19.9900941 },
    { lat: 48.5576374, lng: 19.9905113 },
    { lat: 48.5567021, lng: 19.9900415 },
    { lat: 48.5563673, lng: 19.9897719 },
    { lat: 48.5553891, lng: 19.9893606 },
    { lat: 48.5551659, lng: 19.9893286 },
    { lat: 48.5551669, lng: 19.9893249 },
    { lat: 48.5546613, lng: 19.9892473 },
    { lat: 48.554126, lng: 19.9890797 },
    { lat: 48.5532684, lng: 19.9890743 },
    { lat: 48.5527663, lng: 19.9889199 },
    { lat: 48.5525994, lng: 19.9889429 },
    { lat: 48.550504, lng: 19.9910538 },
    { lat: 48.5479444, lng: 19.9931913 },
    { lat: 48.5474836, lng: 19.9935391 },
    { lat: 48.5468154, lng: 19.9936145 },
    { lat: 48.5465088, lng: 19.99361 },
  ],
  Lukovištia: [
    { lat: 48.5151765, lng: 20.0170405 },
    { lat: 48.515162, lng: 20.0170451 },
    { lat: 48.5150885, lng: 20.0170073 },
    { lat: 48.5151211, lng: 20.016845 },
    { lat: 48.5150673, lng: 20.0168234 },
    { lat: 48.5150005, lng: 20.0169243 },
    { lat: 48.5149667, lng: 20.0168717 },
    { lat: 48.5149901, lng: 20.0168105 },
    { lat: 48.5148433, lng: 20.0167775 },
    { lat: 48.5147482, lng: 20.0169258 },
    { lat: 48.5146163, lng: 20.0168694 },
    { lat: 48.514468, lng: 20.0172018 },
    { lat: 48.5143029, lng: 20.0173727 },
    { lat: 48.5142057, lng: 20.0173129 },
    { lat: 48.514208, lng: 20.0171244 },
    { lat: 48.5143145, lng: 20.0170088 },
    { lat: 48.514193, lng: 20.016902 },
    { lat: 48.5140297, lng: 20.0171466 },
    { lat: 48.514005, lng: 20.0173334 },
    { lat: 48.5139206, lng: 20.0174153 },
    { lat: 48.5139601, lng: 20.0175313 },
    { lat: 48.5139277, lng: 20.0175858 },
    { lat: 48.5138266, lng: 20.0175162 },
    { lat: 48.5137891, lng: 20.017561 },
    { lat: 48.5137537, lng: 20.0177359 },
    { lat: 48.5138429, lng: 20.0176848 },
    { lat: 48.5138779, lng: 20.0178222 },
    { lat: 48.513814, lng: 20.0178851 },
    { lat: 48.5137119, lng: 20.0178148 },
    { lat: 48.5136628, lng: 20.0179346 },
    { lat: 48.5137518, lng: 20.0181493 },
    { lat: 48.5136866, lng: 20.0182883 },
    { lat: 48.5136215, lng: 20.0182959 },
    { lat: 48.51358, lng: 20.0181606 },
    { lat: 48.5134808, lng: 20.0182147 },
    { lat: 48.5133858, lng: 20.0184511 },
    { lat: 48.5133087, lng: 20.0183554 },
    { lat: 48.513024, lng: 20.0185532 },
    { lat: 48.5129585, lng: 20.0185232 },
    { lat: 48.5129646, lng: 20.0183684 },
    { lat: 48.5127734, lng: 20.0181315 },
    { lat: 48.5127662, lng: 20.0182305 },
    { lat: 48.5126905, lng: 20.0183049 },
    { lat: 48.5127445, lng: 20.0184404 },
    { lat: 48.5126997, lng: 20.0185264 },
    { lat: 48.5126034, lng: 20.0185657 },
    { lat: 48.5125615, lng: 20.0184833 },
    { lat: 48.5125328, lng: 20.0185759 },
    { lat: 48.5124668, lng: 20.0186401 },
    { lat: 48.5123904, lng: 20.0186186 },
    { lat: 48.5123543, lng: 20.0187506 },
    { lat: 48.5122627, lng: 20.0188232 },
    { lat: 48.5121813, lng: 20.0187606 },
    { lat: 48.5121379, lng: 20.0188301 },
    { lat: 48.5121693, lng: 20.0189589 },
    { lat: 48.5120647, lng: 20.0189073 },
    { lat: 48.5120338, lng: 20.018955 },
    { lat: 48.512055, lng: 20.0192475 },
    { lat: 48.5119818, lng: 20.0192459 },
    { lat: 48.5119417, lng: 20.0193368 },
    { lat: 48.511847, lng: 20.0192278 },
    { lat: 48.5117853, lng: 20.019261 },
    { lat: 48.5116462, lng: 20.0194344 },
    { lat: 48.5116345, lng: 20.0195893 },
    { lat: 48.5114986, lng: 20.019675 },
    { lat: 48.51143, lng: 20.0196829 },
    { lat: 48.5113943, lng: 20.0195513 },
    { lat: 48.5112875, lng: 20.0196964 },
    { lat: 48.5113499, lng: 20.0198106 },
    { lat: 48.5112889, lng: 20.0198881 },
    { lat: 48.5109517, lng: 20.020043 },
    { lat: 48.5108674, lng: 20.0199618 },
    { lat: 48.5107765, lng: 20.0199718 },
    { lat: 48.5107544, lng: 20.0200084 },
    { lat: 48.5107947, lng: 20.0200873 },
    { lat: 48.5107192, lng: 20.0201371 },
    { lat: 48.5102873, lng: 20.0198007 },
    { lat: 48.5100891, lng: 20.019888 },
    { lat: 48.5100673, lng: 20.019981 },
    { lat: 48.5099567, lng: 20.0199791 },
    { lat: 48.5098569, lng: 20.020255 },
    { lat: 48.5097335, lng: 20.0198119 },
    { lat: 48.5096834, lng: 20.0197986 },
    { lat: 48.5096358, lng: 20.0198748 },
    { lat: 48.5096524, lng: 20.0200562 },
    { lat: 48.509563, lng: 20.020205 },
    { lat: 48.5094759, lng: 20.0201983 },
    { lat: 48.5093282, lng: 20.0203302 },
    { lat: 48.5092839, lng: 20.0201334 },
    { lat: 48.5092215, lng: 20.0201204 },
    { lat: 48.5091736, lng: 20.0200244 },
    { lat: 48.5091033, lng: 20.0200954 },
    { lat: 48.5090435, lng: 20.0200421 },
    { lat: 48.5089315, lng: 20.0202189 },
    { lat: 48.5087767, lng: 20.0202318 },
    { lat: 48.5087809, lng: 20.0203892 },
    { lat: 48.5087026, lng: 20.0204534 },
    { lat: 48.5087194, lng: 20.0207754 },
    { lat: 48.5085287, lng: 20.02085 },
    { lat: 48.5082506, lng: 20.0207402 },
    { lat: 48.5081593, lng: 20.0205784 },
    { lat: 48.5082454, lng: 20.0204857 },
    { lat: 48.5081787, lng: 20.0203435 },
    { lat: 48.5081099, lng: 20.0204246 },
    { lat: 48.5080405, lng: 20.0203498 },
    { lat: 48.5079392, lng: 20.0205417 },
    { lat: 48.5078275, lng: 20.0206194 },
    { lat: 48.5078202, lng: 20.0203085 },
    { lat: 48.5076108, lng: 20.0202587 },
    { lat: 48.507562, lng: 20.0200058 },
    { lat: 48.5074281, lng: 20.0200092 },
    { lat: 48.5073655, lng: 20.0197544 },
    { lat: 48.5075207, lng: 20.019588 },
    { lat: 48.5074937, lng: 20.0192933 },
    { lat: 48.5076607, lng: 20.0190309 },
    { lat: 48.5075416, lng: 20.0188458 },
    { lat: 48.5074207, lng: 20.0189979 },
    { lat: 48.5073381, lng: 20.0189926 },
    { lat: 48.5073469, lng: 20.0186887 },
    { lat: 48.507206, lng: 20.0186324 },
    { lat: 48.5070896, lng: 20.01849 },
    { lat: 48.5069743, lng: 20.0186682 },
    { lat: 48.5068235, lng: 20.0187258 },
    { lat: 48.5066608, lng: 20.0186333 },
    { lat: 48.5065958, lng: 20.0185307 },
    { lat: 48.5065203, lng: 20.0186103 },
    { lat: 48.5063788, lng: 20.0184099 },
    { lat: 48.5064181, lng: 20.0182715 },
    { lat: 48.5062843, lng: 20.0181652 },
    { lat: 48.5060013, lng: 20.018295 },
    { lat: 48.5060305, lng: 20.0184585 },
    { lat: 48.5059126, lng: 20.0186522 },
    { lat: 48.5055496, lng: 20.0185697 },
    { lat: 48.5053017, lng: 20.01867 },
    { lat: 48.5053003, lng: 20.0187489 },
    { lat: 48.5052395, lng: 20.018803 },
    { lat: 48.5051929, lng: 20.018573 },
    { lat: 48.5051415, lng: 20.0185594 },
    { lat: 48.5050682, lng: 20.0187187 },
    { lat: 48.5050219, lng: 20.0191195 },
    { lat: 48.5048913, lng: 20.0191464 },
    { lat: 48.5047805, lng: 20.017496 },
    { lat: 48.5048233, lng: 20.0174699 },
    { lat: 48.5047097, lng: 20.0169606 },
    { lat: 48.5046977, lng: 20.0165145 },
    { lat: 48.504854, lng: 20.0163967 },
    { lat: 48.5049303, lng: 20.0160442 },
    { lat: 48.5048205, lng: 20.0158383 },
    { lat: 48.5049095, lng: 20.0156746 },
    { lat: 48.5049625, lng: 20.0156778 },
    { lat: 48.5049267, lng: 20.0152023 },
    { lat: 48.5048118, lng: 20.0151184 },
    { lat: 48.504907, lng: 20.0144888 },
    { lat: 48.5048448, lng: 20.0134081 },
    { lat: 48.5047218, lng: 20.0131033 },
    { lat: 48.5045742, lng: 20.0122733 },
    { lat: 48.5041534, lng: 20.0108032 },
    { lat: 48.5040932, lng: 20.0104657 },
    { lat: 48.5037631, lng: 20.0095515 },
    { lat: 48.5033016, lng: 20.0088621 },
    { lat: 48.502932, lng: 20.0077044 },
    { lat: 48.5027229, lng: 20.0065834 },
    { lat: 48.50271, lng: 20.0060053 },
    { lat: 48.5032668, lng: 20.0036823 },
    { lat: 48.5032576, lng: 20.0035968 },
    { lat: 48.5029193, lng: 20.0036126 },
    { lat: 48.5025262, lng: 20.003559 },
    { lat: 48.5020186, lng: 20.0033845 },
    { lat: 48.5013987, lng: 20.0034836 },
    { lat: 48.5011726, lng: 20.0034628 },
    { lat: 48.500263, lng: 20.0037474 },
    { lat: 48.4993248, lng: 20.0036695 },
    { lat: 48.4987622, lng: 20.00355 },
    { lat: 48.4987298, lng: 20.0037445 },
    { lat: 48.4973373, lng: 20.003333 },
    { lat: 48.4973229, lng: 20.0034501 },
    { lat: 48.4962496, lng: 20.0032385 },
    { lat: 48.495494, lng: 20.0034061 },
    { lat: 48.4946729, lng: 20.0036798 },
    { lat: 48.4938277, lng: 20.0037761 },
    { lat: 48.4935963, lng: 20.0037639 },
    { lat: 48.4929386, lng: 20.0036332 },
    { lat: 48.492508, lng: 20.0034127 },
    { lat: 48.4920945, lng: 20.0036498 },
    { lat: 48.4911881, lng: 20.0034972 },
    { lat: 48.4901287, lng: 20.0035137 },
    { lat: 48.489566, lng: 20.003207 },
    { lat: 48.4892654, lng: 20.0029671 },
    { lat: 48.488717, lng: 20.0028651 },
    { lat: 48.4883736, lng: 20.0026796 },
    { lat: 48.4872283, lng: 20.0023083 },
    { lat: 48.4856996, lng: 20.0014476 },
    { lat: 48.4854733, lng: 20.0013998 },
    { lat: 48.4847494, lng: 20.0015403 },
    { lat: 48.4823282, lng: 20.0017753 },
    { lat: 48.4819379, lng: 20.0019203 },
    { lat: 48.4813368, lng: 20.0019852 },
    { lat: 48.4805419, lng: 20.0022634 },
    { lat: 48.4803545, lng: 20.0022301 },
    { lat: 48.4799903, lng: 20.002298 },
    { lat: 48.4792929, lng: 20.0025926 },
    { lat: 48.4784791, lng: 20.0027412 },
    { lat: 48.4769164, lng: 20.0027852 },
    { lat: 48.4767265, lng: 20.0029048 },
    { lat: 48.476319, lng: 20.0030212 },
    { lat: 48.4746383, lng: 20.0044673 },
    { lat: 48.4742081, lng: 20.0047677 },
    { lat: 48.4737235, lng: 20.0048957 },
    { lat: 48.4730632, lng: 20.0062328 },
    { lat: 48.4729285, lng: 20.0066193 },
    { lat: 48.4725598, lng: 20.0073384 },
    { lat: 48.4721394, lng: 20.0083236 },
    { lat: 48.4719282, lng: 20.0089563 },
    { lat: 48.4717975, lng: 20.0095197 },
    { lat: 48.4715838, lng: 20.0100074 },
    { lat: 48.4708591, lng: 20.0110214 },
    { lat: 48.4702104, lng: 20.0114035 },
    { lat: 48.4697155, lng: 20.0119299 },
    { lat: 48.469231, lng: 20.0126535 },
    { lat: 48.4689606, lng: 20.0128921 },
    { lat: 48.46859, lng: 20.0129186 },
    { lat: 48.4680673, lng: 20.0128386 },
    { lat: 48.46782, lng: 20.0127442 },
    { lat: 48.4669568, lng: 20.0121707 },
    { lat: 48.4656664, lng: 20.0118358 },
    { lat: 48.4646566, lng: 20.0117175 },
    { lat: 48.4629705, lng: 20.0113963 },
    { lat: 48.4574641, lng: 20.0098054 },
    { lat: 48.4566586, lng: 20.0101521 },
    { lat: 48.4555019, lng: 20.0101844 },
    { lat: 48.4547108, lng: 20.0109311 },
    { lat: 48.4540138, lng: 20.0113101 },
    { lat: 48.4534091, lng: 20.0120434 },
    { lat: 48.4525524, lng: 20.0125912 },
    { lat: 48.4505794, lng: 20.0144866 },
    { lat: 48.4492358, lng: 20.0169099 },
    { lat: 48.4491288, lng: 20.0170712 },
    { lat: 48.449844, lng: 20.017343 },
    { lat: 48.4501286, lng: 20.0172774 },
    { lat: 48.4505539, lng: 20.0173224 },
    { lat: 48.4512267, lng: 20.0176221 },
    { lat: 48.4517935, lng: 20.0177636 },
    { lat: 48.4525655, lng: 20.0177445 },
    { lat: 48.4534856, lng: 20.0183586 },
    { lat: 48.4539562, lng: 20.0190231 },
    { lat: 48.454197, lng: 20.0190749 },
    { lat: 48.4552246, lng: 20.0199965 },
    { lat: 48.4555162, lng: 20.0201776 },
    { lat: 48.4559828, lng: 20.0207513 },
    { lat: 48.4559405, lng: 20.0210044 },
    { lat: 48.4559009, lng: 20.0210273 },
    { lat: 48.4559731, lng: 20.0211128 },
    { lat: 48.4558855, lng: 20.0214103 },
    { lat: 48.4556744, lng: 20.0216747 },
    { lat: 48.4554564, lng: 20.0216811 },
    { lat: 48.4554277, lng: 20.021752 },
    { lat: 48.455476, lng: 20.0218851 },
    { lat: 48.4554075, lng: 20.0219398 },
    { lat: 48.4553117, lng: 20.0218857 },
    { lat: 48.4549863, lng: 20.0222426 },
    { lat: 48.4547971, lng: 20.0225902 },
    { lat: 48.4545421, lng: 20.0226114 },
    { lat: 48.4543553, lng: 20.0229091 },
    { lat: 48.4540702, lng: 20.023501 },
    { lat: 48.4537936, lng: 20.0245856 },
    { lat: 48.4536917, lng: 20.0248206 },
    { lat: 48.4541519, lng: 20.0250205 },
    { lat: 48.4542588, lng: 20.0251286 },
    { lat: 48.4544726, lng: 20.0251215 },
    { lat: 48.4554035, lng: 20.0254853 },
    { lat: 48.455636, lng: 20.025869 },
    { lat: 48.4560967, lng: 20.027054 },
    { lat: 48.4562472, lng: 20.0275594 },
    { lat: 48.4563476, lng: 20.0285843 },
    { lat: 48.4561578, lng: 20.0291945 },
    { lat: 48.4561229, lng: 20.0301915 },
    { lat: 48.4563112, lng: 20.0307383 },
    { lat: 48.4562272, lng: 20.0310145 },
    { lat: 48.4562679, lng: 20.0313953 },
    { lat: 48.4566948, lng: 20.0325858 },
    { lat: 48.4570087, lng: 20.0320661 },
    { lat: 48.4580164, lng: 20.0310834 },
    { lat: 48.458301, lng: 20.0308831 },
    { lat: 48.4587657, lng: 20.0306834 },
    { lat: 48.4591652, lng: 20.0303235 },
    { lat: 48.4600978, lng: 20.0301588 },
    { lat: 48.4609895, lng: 20.0303614 },
    { lat: 48.4611983, lng: 20.0305064 },
    { lat: 48.4614993, lng: 20.030835 },
    { lat: 48.4617429, lng: 20.030977 },
    { lat: 48.4621061, lng: 20.0308004 },
    { lat: 48.4625265, lng: 20.0308896 },
    { lat: 48.4630883, lng: 20.0313154 },
    { lat: 48.4635058, lng: 20.031873 },
    { lat: 48.4639977, lng: 20.03224 },
    { lat: 48.4641392, lng: 20.032253 },
    { lat: 48.4644742, lng: 20.0325103 },
    { lat: 48.4653983, lng: 20.0328146 },
    { lat: 48.4661727, lng: 20.033532 },
    { lat: 48.4664206, lng: 20.0336122 },
    { lat: 48.4666932, lng: 20.0340462 },
    { lat: 48.4670836, lng: 20.0353319 },
    { lat: 48.4671609, lng: 20.0358325 },
    { lat: 48.4673383, lng: 20.0361466 },
    { lat: 48.4673989, lng: 20.0367851 },
    { lat: 48.4674677, lng: 20.0368439 },
    { lat: 48.4675213, lng: 20.0371562 },
    { lat: 48.4673964, lng: 20.0374562 },
    { lat: 48.4676226, lng: 20.037995 },
    { lat: 48.4674907, lng: 20.0393431 },
    { lat: 48.4679568, lng: 20.0420948 },
    { lat: 48.4686046, lng: 20.0438827 },
    { lat: 48.4687739, lng: 20.0455411 },
    { lat: 48.468915, lng: 20.0461174 },
    { lat: 48.4694222, lng: 20.0464452 },
    { lat: 48.4697378, lng: 20.0462917 },
    { lat: 48.4698851, lng: 20.0458494 },
    { lat: 48.4702288, lng: 20.0457432 },
    { lat: 48.4703112, lng: 20.0452619 },
    { lat: 48.4707298, lng: 20.044765 },
    { lat: 48.4707949, lng: 20.0446002 },
    { lat: 48.4707958, lng: 20.0437528 },
    { lat: 48.4708403, lng: 20.043575 },
    { lat: 48.4710947, lng: 20.0433022 },
    { lat: 48.4712391, lng: 20.0430398 },
    { lat: 48.4714442, lng: 20.043095 },
    { lat: 48.4718077, lng: 20.043408 },
    { lat: 48.47199, lng: 20.0438111 },
    { lat: 48.472121, lng: 20.0436309 },
    { lat: 48.4726752, lng: 20.0441744 },
    { lat: 48.4729173, lng: 20.0448977 },
    { lat: 48.4730054, lng: 20.0458227 },
    { lat: 48.4736979, lng: 20.0468218 },
    { lat: 48.4742362, lng: 20.047249 },
    { lat: 48.4746608, lng: 20.047493 },
    { lat: 48.4748433, lng: 20.0476857 },
    { lat: 48.4749884, lng: 20.0466234 },
    { lat: 48.4751859, lng: 20.0462253 },
    { lat: 48.4755037, lng: 20.0459607 },
    { lat: 48.4761202, lng: 20.0457788 },
    { lat: 48.4765163, lng: 20.0454717 },
    { lat: 48.4768836, lng: 20.0448486 },
    { lat: 48.4773306, lng: 20.0445473 },
    { lat: 48.477631, lng: 20.0444594 },
    { lat: 48.4781571, lng: 20.0445157 },
    { lat: 48.4792169, lng: 20.0438344 },
    { lat: 48.4792776, lng: 20.0437218 },
    { lat: 48.4796757, lng: 20.043543 },
    { lat: 48.4800894, lng: 20.0434963 },
    { lat: 48.4814393, lng: 20.0427752 },
    { lat: 48.4844857, lng: 20.0418712 },
    { lat: 48.4855777, lng: 20.0413387 },
    { lat: 48.4881511, lng: 20.040326 },
    { lat: 48.4897781, lng: 20.0401784 },
    { lat: 48.4925023, lng: 20.0393517 },
    { lat: 48.4946047, lng: 20.0382795 },
    { lat: 48.4968957, lng: 20.0387102 },
    { lat: 48.4973733, lng: 20.0385991 },
    { lat: 48.4992308, lng: 20.0375755 },
    { lat: 48.4993196, lng: 20.0377578 },
    { lat: 48.4997843, lng: 20.0380745 },
    { lat: 48.4999322, lng: 20.0380783 },
    { lat: 48.5002513, lng: 20.0380603 },
    { lat: 48.5007449, lng: 20.0379022 },
    { lat: 48.50128, lng: 20.0376071 },
    { lat: 48.5015734, lng: 20.0375666 },
    { lat: 48.5017842, lng: 20.0374608 },
    { lat: 48.5025009, lng: 20.037417 },
    { lat: 48.5029024, lng: 20.0368832 },
    { lat: 48.5033875, lng: 20.0364159 },
    { lat: 48.5033851, lng: 20.0363063 },
    { lat: 48.504047, lng: 20.0360514 },
    { lat: 48.5048676, lng: 20.0355012 },
    { lat: 48.5049203, lng: 20.0353541 },
    { lat: 48.5052654, lng: 20.0350725 },
    { lat: 48.5052373, lng: 20.0349079 },
    { lat: 48.5055986, lng: 20.0349001 },
    { lat: 48.5058449, lng: 20.0346474 },
    { lat: 48.5060832, lng: 20.0345545 },
    { lat: 48.5066386, lng: 20.0341806 },
    { lat: 48.5067712, lng: 20.0330907 },
    { lat: 48.5068198, lng: 20.0331185 },
    { lat: 48.5069358, lng: 20.032823 },
    { lat: 48.5070697, lng: 20.0324161 },
    { lat: 48.507044, lng: 20.0323158 },
    { lat: 48.5068527, lng: 20.0321527 },
    { lat: 48.5072123, lng: 20.0316243 },
    { lat: 48.507408, lng: 20.0308051 },
    { lat: 48.5076725, lng: 20.0292075 },
    { lat: 48.5077903, lng: 20.0276579 },
    { lat: 48.5077568, lng: 20.0271758 },
    { lat: 48.5083973, lng: 20.0263814 },
    { lat: 48.5089773, lng: 20.0258683 },
    { lat: 48.5093141, lng: 20.0258165 },
    { lat: 48.5102669, lng: 20.0251943 },
    { lat: 48.5108043, lng: 20.0252468 },
    { lat: 48.5110089, lng: 20.0251841 },
    { lat: 48.5121151, lng: 20.024617 },
    { lat: 48.5127669, lng: 20.0244214 },
    { lat: 48.5129161, lng: 20.0244041 },
    { lat: 48.5131536, lng: 20.0245354 },
    { lat: 48.5132664, lng: 20.0244313 },
    { lat: 48.5144235, lng: 20.0232226 },
    { lat: 48.5146923, lng: 20.0224028 },
    { lat: 48.5155875, lng: 20.0215652 },
    { lat: 48.5156658, lng: 20.0213091 },
    { lat: 48.5153059, lng: 20.0214558 },
    { lat: 48.5152114, lng: 20.0206476 },
    { lat: 48.5149389, lng: 20.0190348 },
    { lat: 48.5148747, lng: 20.0182792 },
    { lat: 48.5146532, lng: 20.0175875 },
    { lat: 48.515241, lng: 20.0172056 },
    { lat: 48.5151765, lng: 20.0170405 },
  ],
  Hajnáčka: [
    { lat: 48.2539479, lng: 19.9861242 },
    { lat: 48.2552661, lng: 19.9854965 },
    { lat: 48.2560341, lng: 19.9849015 },
    { lat: 48.2563729, lng: 19.9838548 },
    { lat: 48.2568653, lng: 19.9830071 },
    { lat: 48.2572592, lng: 19.9822015 },
    { lat: 48.2572444, lng: 19.9814002 },
    { lat: 48.2575138, lng: 19.9812685 },
    { lat: 48.2581484, lng: 19.9806133 },
    { lat: 48.258559, lng: 19.9802812 },
    { lat: 48.2589669, lng: 19.9797228 },
    { lat: 48.2592369, lng: 19.9791857 },
    { lat: 48.2596541, lng: 19.9785585 },
    { lat: 48.2609673, lng: 19.9780971 },
    { lat: 48.2615829, lng: 19.9776335 },
    { lat: 48.2616207, lng: 19.9760256 },
    { lat: 48.2615585, lng: 19.9758328 },
    { lat: 48.261103, lng: 19.9748385 },
    { lat: 48.2604415, lng: 19.9739571 },
    { lat: 48.2597088, lng: 19.9727503 },
    { lat: 48.2586639, lng: 19.9713299 },
    { lat: 48.2585403, lng: 19.9710712 },
    { lat: 48.2581178, lng: 19.9696158 },
    { lat: 48.2574413, lng: 19.9683538 },
    { lat: 48.2573115, lng: 19.9680275 },
    { lat: 48.2571282, lng: 19.9672623 },
    { lat: 48.2568916, lng: 19.9666277 },
    { lat: 48.2568585, lng: 19.9664032 },
    { lat: 48.2568283, lng: 19.9664089 },
    { lat: 48.2563963, lng: 19.9645705 },
    { lat: 48.2563041, lng: 19.9631677 },
    { lat: 48.2559367, lng: 19.9625 },
    { lat: 48.2554505, lng: 19.9611352 },
    { lat: 48.2552736, lng: 19.9608675 },
    { lat: 48.2548459, lng: 19.960563 },
    { lat: 48.2546793, lng: 19.9610416 },
    { lat: 48.2543312, lng: 19.9613059 },
    { lat: 48.2538204, lng: 19.9605798 },
    { lat: 48.2536107, lng: 19.9607871 },
    { lat: 48.2535023, lng: 19.9604659 },
    { lat: 48.2534125, lng: 19.9600471 },
    { lat: 48.2535755, lng: 19.9592939 },
    { lat: 48.2539954, lng: 19.9561937 },
    { lat: 48.2543557, lng: 19.9546542 },
    { lat: 48.2544555, lng: 19.9539529 },
    { lat: 48.254551, lng: 19.9506991 },
    { lat: 48.2543809, lng: 19.9499878 },
    { lat: 48.2541186, lng: 19.9491935 },
    { lat: 48.2540282, lng: 19.9486857 },
    { lat: 48.253922, lng: 19.947148 },
    { lat: 48.2537579, lng: 19.9466536 },
    { lat: 48.2535135, lng: 19.9456504 },
    { lat: 48.2530843, lng: 19.944466 },
    { lat: 48.2515666, lng: 19.9425363 },
    { lat: 48.2511069, lng: 19.9417376 },
    { lat: 48.2509597, lng: 19.9412752 },
    { lat: 48.2504942, lng: 19.9404521 },
    { lat: 48.2498949, lng: 19.9387132 },
    { lat: 48.2496803, lng: 19.9379338 },
    { lat: 48.2496249, lng: 19.9370583 },
    { lat: 48.2496102, lng: 19.9364772 },
    { lat: 48.249731, lng: 19.935035 },
    { lat: 48.248511, lng: 19.935416 },
    { lat: 48.248458, lng: 19.935432 },
    { lat: 48.248012, lng: 19.935571 },
    { lat: 48.246821, lng: 19.936518 },
    { lat: 48.246181, lng: 19.937462 },
    { lat: 48.245771, lng: 19.937797 },
    { lat: 48.245271, lng: 19.938442 },
    { lat: 48.244872, lng: 19.939547 },
    { lat: 48.244846, lng: 19.939542 },
    { lat: 48.244823, lng: 19.939539 },
    { lat: 48.244494, lng: 19.939485 },
    { lat: 48.2439, lng: 19.938865 },
    { lat: 48.243633, lng: 19.938353 },
    { lat: 48.243192, lng: 19.937505 },
    { lat: 48.241389, lng: 19.938051 },
    { lat: 48.24102, lng: 19.938699 },
    { lat: 48.240557, lng: 19.9392 },
    { lat: 48.23996, lng: 19.939798 },
    { lat: 48.239185, lng: 19.939146 },
    { lat: 48.238665, lng: 19.939408 },
    { lat: 48.238698, lng: 19.940026 },
    { lat: 48.238795, lng: 19.940522 },
    { lat: 48.23893, lng: 19.940762 },
    { lat: 48.239148, lng: 19.941699 },
    { lat: 48.238765, lng: 19.942197 },
    { lat: 48.238587, lng: 19.942254 },
    { lat: 48.238392, lng: 19.942113 },
    { lat: 48.238184, lng: 19.941676 },
    { lat: 48.237947, lng: 19.941831 },
    { lat: 48.237665, lng: 19.941958 },
    { lat: 48.237547, lng: 19.94269 },
    { lat: 48.236777, lng: 19.942726 },
    { lat: 48.236202, lng: 19.942645 },
    { lat: 48.235714, lng: 19.942406 },
    { lat: 48.235482, lng: 19.942089 },
    { lat: 48.235237, lng: 19.941702 },
    { lat: 48.235198, lng: 19.941372 },
    { lat: 48.234991, lng: 19.940984 },
    { lat: 48.235006, lng: 19.939764 },
    { lat: 48.235093, lng: 19.939061 },
    { lat: 48.235068, lng: 19.938918 },
    { lat: 48.235059, lng: 19.938859 },
    { lat: 48.235521, lng: 19.938182 },
    { lat: 48.235645, lng: 19.938001 },
    { lat: 48.235775, lng: 19.937812 },
    { lat: 48.235856, lng: 19.937693 },
    { lat: 48.235833, lng: 19.9376 },
    { lat: 48.235778, lng: 19.937376 },
    { lat: 48.235723, lng: 19.937153 },
    { lat: 48.235668, lng: 19.936931 },
    { lat: 48.235684, lng: 19.936717 },
    { lat: 48.2357, lng: 19.936503 },
    { lat: 48.235726, lng: 19.936292 },
    { lat: 48.235755, lng: 19.93608 },
    { lat: 48.235782, lng: 19.935869 },
    { lat: 48.235803, lng: 19.935656 },
    { lat: 48.235849, lng: 19.935213 },
    { lat: 48.235867, lng: 19.935067 },
    { lat: 48.235896, lng: 19.934826 },
    { lat: 48.235942, lng: 19.934435 },
    { lat: 48.235988, lng: 19.934034 },
    { lat: 48.236038, lng: 19.93363 },
    { lat: 48.236102, lng: 19.933242 },
    { lat: 48.236169, lng: 19.932847 },
    { lat: 48.236179, lng: 19.932794 },
    { lat: 48.236235, lng: 19.932459 },
    { lat: 48.236113, lng: 19.932377 },
    { lat: 48.23564, lng: 19.932062 },
    { lat: 48.235566, lng: 19.932015 },
    { lat: 48.235427, lng: 19.931856 },
    { lat: 48.234879, lng: 19.931769 },
    { lat: 48.234867, lng: 19.931728 },
    { lat: 48.234762, lng: 19.931684 },
    { lat: 48.234715, lng: 19.931664 },
    { lat: 48.234557, lng: 19.9316 },
    { lat: 48.234385, lng: 19.93153 },
    { lat: 48.234365, lng: 19.931522 },
    { lat: 48.234346, lng: 19.931518 },
    { lat: 48.234272, lng: 19.931504 },
    { lat: 48.234225, lng: 19.931494 },
    { lat: 48.233941, lng: 19.931303 },
    { lat: 48.233938, lng: 19.931304 },
    { lat: 48.233936, lng: 19.93133 },
    { lat: 48.233905, lng: 19.931745 },
    { lat: 48.233896, lng: 19.931885 },
    { lat: 48.233835, lng: 19.931973 },
    { lat: 48.233828, lng: 19.931984 },
    { lat: 48.2338, lng: 19.932023 },
    { lat: 48.233791, lng: 19.932084 },
    { lat: 48.23375, lng: 19.932059 },
    { lat: 48.23372, lng: 19.932139 },
    { lat: 48.233477, lng: 19.931911 },
    { lat: 48.233422, lng: 19.931816 },
    { lat: 48.233421, lng: 19.931718 },
    { lat: 48.233517, lng: 19.931088 },
    { lat: 48.233377, lng: 19.931329 },
    { lat: 48.232686, lng: 19.931222 },
    { lat: 48.231124, lng: 19.931266 },
    { lat: 48.230668, lng: 19.931255 },
    { lat: 48.229798, lng: 19.931115 },
    { lat: 48.228873, lng: 19.930975 },
    { lat: 48.228336, lng: 19.930949 },
    { lat: 48.228201, lng: 19.930886 },
    { lat: 48.227761, lng: 19.930802 },
    { lat: 48.22741, lng: 19.930645 },
    { lat: 48.227321, lng: 19.930605 },
    { lat: 48.227068, lng: 19.930592 },
    { lat: 48.226665, lng: 19.930651 },
    { lat: 48.226379, lng: 19.929296 },
    { lat: 48.225956, lng: 19.929385 },
    { lat: 48.225639, lng: 19.929309 },
    { lat: 48.225289, lng: 19.929155 },
    { lat: 48.22522, lng: 19.929124 },
    { lat: 48.225005, lng: 19.929103 },
    { lat: 48.224672, lng: 19.929117 },
    { lat: 48.224481, lng: 19.929067 },
    { lat: 48.22382, lng: 19.928712 },
    { lat: 48.22221, lng: 19.927673 },
    { lat: 48.221825, lng: 19.927424 },
    { lat: 48.221555, lng: 19.927337 },
    { lat: 48.221418, lng: 19.927158 },
    { lat: 48.221037, lng: 19.926846 },
    { lat: 48.220766, lng: 19.926745 },
    { lat: 48.220408, lng: 19.926435 },
    { lat: 48.219942, lng: 19.926087 },
    { lat: 48.219178, lng: 19.926381 },
    { lat: 48.21862, lng: 19.926431 },
    { lat: 48.21857, lng: 19.926436 },
    { lat: 48.218314, lng: 19.926459 },
    { lat: 48.217793, lng: 19.926644 },
    { lat: 48.21756, lng: 19.926664 },
    { lat: 48.217215, lng: 19.926859 },
    { lat: 48.21642, lng: 19.927493 },
    { lat: 48.216121, lng: 19.927949 },
    { lat: 48.215585, lng: 19.928596 },
    { lat: 48.215555, lng: 19.928646 },
    { lat: 48.215043, lng: 19.927953 },
    { lat: 48.214657, lng: 19.927603 },
    { lat: 48.21407, lng: 19.927409 },
    { lat: 48.213569, lng: 19.927166 },
    { lat: 48.212976, lng: 19.92714 },
    { lat: 48.212497, lng: 19.927052 },
    { lat: 48.211903, lng: 19.927066 },
    { lat: 48.211458, lng: 19.926804 },
    { lat: 48.211259, lng: 19.92649 },
    { lat: 48.210944, lng: 19.926392 },
    { lat: 48.210553, lng: 19.92627 },
    { lat: 48.2103, lng: 19.926056 },
    { lat: 48.209391, lng: 19.925665 },
    { lat: 48.209399, lng: 19.925642 },
    { lat: 48.208972, lng: 19.925303 },
    { lat: 48.208, lng: 19.923797 },
    { lat: 48.207533, lng: 19.922825 },
    { lat: 48.20706, lng: 19.922153 },
    { lat: 48.206801, lng: 19.921943 },
    { lat: 48.206661, lng: 19.921815 },
    { lat: 48.20664, lng: 19.921793 },
    { lat: 48.206611, lng: 19.921791 },
    { lat: 48.206219, lng: 19.92136 },
    { lat: 48.205522, lng: 19.921174 },
    { lat: 48.205138, lng: 19.921334 },
    { lat: 48.204619, lng: 19.921686 },
    { lat: 48.204256, lng: 19.922274 },
    { lat: 48.204012, lng: 19.923122 },
    { lat: 48.203875, lng: 19.924139 },
    { lat: 48.202746, lng: 19.924026 },
    { lat: 48.202172, lng: 19.924565 },
    { lat: 48.2017239, lng: 19.9254791 },
    { lat: 48.2013402, lng: 19.9264089 },
    { lat: 48.201007, lng: 19.9274692 },
    { lat: 48.2007094, lng: 19.9297514 },
    { lat: 48.1998093, lng: 19.9309365 },
    { lat: 48.1990038, lng: 19.9326942 },
    { lat: 48.1971654, lng: 19.9343272 },
    { lat: 48.1970673, lng: 19.9351183 },
    { lat: 48.1971066, lng: 19.9359549 },
    { lat: 48.1967425, lng: 19.9391369 },
    { lat: 48.1967244, lng: 19.9399278 },
    { lat: 48.1968214, lng: 19.9416162 },
    { lat: 48.1968264, lng: 19.9426276 },
    { lat: 48.1967426, lng: 19.9432243 },
    { lat: 48.1967302, lng: 19.9440174 },
    { lat: 48.1962166, lng: 19.9455209 },
    { lat: 48.1960513, lng: 19.9463445 },
    { lat: 48.196013, lng: 19.9472937 },
    { lat: 48.1960678, lng: 19.9481857 },
    { lat: 48.1959698, lng: 19.9487374 },
    { lat: 48.1958077, lng: 19.9490325 },
    { lat: 48.1955622, lng: 19.9490884 },
    { lat: 48.1954404, lng: 19.9486608 },
    { lat: 48.1948509, lng: 19.9475431 },
    { lat: 48.1944196, lng: 19.9475723 },
    { lat: 48.1938999, lng: 19.9479613 },
    { lat: 48.1936272, lng: 19.9483026 },
    { lat: 48.1934573, lng: 19.9487803 },
    { lat: 48.1926645, lng: 19.9530941 },
    { lat: 48.1923066, lng: 19.9540783 },
    { lat: 48.1922969, lng: 19.954403 },
    { lat: 48.191958, lng: 19.9546983 },
    { lat: 48.191896, lng: 19.9557023 },
    { lat: 48.1921425, lng: 19.955855 },
    { lat: 48.1922858, lng: 19.9560765 },
    { lat: 48.1923778, lng: 19.9564889 },
    { lat: 48.1924503, lng: 19.956495 },
    { lat: 48.1925789, lng: 19.9569774 },
    { lat: 48.1928117, lng: 19.9575755 },
    { lat: 48.1929714, lng: 19.9582043 },
    { lat: 48.1930704, lng: 19.9592039 },
    { lat: 48.1944636, lng: 19.9587753 },
    { lat: 48.1946019, lng: 19.9603555 },
    { lat: 48.1945801, lng: 19.9609794 },
    { lat: 48.1946855, lng: 19.9612606 },
    { lat: 48.1947325, lng: 19.9621257 },
    { lat: 48.1947008, lng: 19.9626273 },
    { lat: 48.1943093, lng: 19.9650213 },
    { lat: 48.1940429, lng: 19.967761 },
    { lat: 48.1946221, lng: 19.969992 },
    { lat: 48.1952744, lng: 19.9698089 },
    { lat: 48.1956195, lng: 19.9696042 },
    { lat: 48.1958445, lng: 19.9693593 },
    { lat: 48.1962704, lng: 19.9684057 },
    { lat: 48.1972172, lng: 19.9687369 },
    { lat: 48.1974368, lng: 19.9679124 },
    { lat: 48.1974346, lng: 19.9674935 },
    { lat: 48.1972679, lng: 19.9657157 },
    { lat: 48.1972945, lng: 19.9647538 },
    { lat: 48.1981769, lng: 19.9655317 },
    { lat: 48.1984461, lng: 19.9656608 },
    { lat: 48.1994965, lng: 19.9659376 },
    { lat: 48.1995642, lng: 19.9664406 },
    { lat: 48.2012374, lng: 19.9664248 },
    { lat: 48.2018881, lng: 19.966311 },
    { lat: 48.2018913, lng: 19.9659569 },
    { lat: 48.2028702, lng: 19.9658235 },
    { lat: 48.2043193, lng: 19.9653059 },
    { lat: 48.204592, lng: 19.9657274 },
    { lat: 48.2047857, lng: 19.9658636 },
    { lat: 48.2050845, lng: 19.9663963 },
    { lat: 48.2057048, lng: 19.9687995 },
    { lat: 48.2058339, lng: 19.9699533 },
    { lat: 48.2062676, lng: 19.9704877 },
    { lat: 48.206504, lng: 19.9708808 },
    { lat: 48.2067843, lng: 19.9714832 },
    { lat: 48.2068938, lng: 19.9720205 },
    { lat: 48.2069123, lng: 19.972273 },
    { lat: 48.2068536, lng: 19.9730647 },
    { lat: 48.2068945, lng: 19.9736269 },
    { lat: 48.2068108, lng: 19.9743631 },
    { lat: 48.2068923, lng: 19.9749048 },
    { lat: 48.2068765, lng: 19.9756713 },
    { lat: 48.2067933, lng: 19.9760482 },
    { lat: 48.206372, lng: 19.9771109 },
    { lat: 48.2057748, lng: 19.978036 },
    { lat: 48.2065276, lng: 19.9798493 },
    { lat: 48.2070055, lng: 19.9807832 },
    { lat: 48.2074668, lng: 19.9811453 },
    { lat: 48.2087659, lng: 19.9819438 },
    { lat: 48.2097962, lng: 19.9823599 },
    { lat: 48.210585, lng: 19.9828961 },
    { lat: 48.2114214, lng: 19.9849874 },
    { lat: 48.2119327, lng: 19.9866586 },
    { lat: 48.2125367, lng: 19.9866646 },
    { lat: 48.2136516, lng: 19.9870527 },
    { lat: 48.2145929, lng: 19.9874979 },
    { lat: 48.2150251, lng: 19.9878603 },
    { lat: 48.2155543, lng: 19.9875071 },
    { lat: 48.2166168, lng: 19.9866085 },
    { lat: 48.217628, lng: 19.986412 },
    { lat: 48.2182147, lng: 19.9860074 },
    { lat: 48.2187915, lng: 19.9857923 },
    { lat: 48.2203179, lng: 19.985181 },
    { lat: 48.2206323, lng: 19.9849975 },
    { lat: 48.2208719, lng: 19.985977 },
    { lat: 48.2217132, lng: 19.9873272 },
    { lat: 48.2235102, lng: 19.9892492 },
    { lat: 48.2238232, lng: 19.9899403 },
    { lat: 48.223979, lng: 19.990454 },
    { lat: 48.2244046, lng: 19.9910838 },
    { lat: 48.2254305, lng: 19.9922228 },
    { lat: 48.2257626, lng: 19.9927806 },
    { lat: 48.2260208, lng: 19.9930745 },
    { lat: 48.2261101, lng: 19.9933497 },
    { lat: 48.2262337, lng: 19.9940626 },
    { lat: 48.2263867, lng: 19.9944053 },
    { lat: 48.2267027, lng: 19.9947937 },
    { lat: 48.2272545, lng: 19.9949986 },
    { lat: 48.2273569, lng: 19.9940358 },
    { lat: 48.2271762, lng: 19.9927778 },
    { lat: 48.227246, lng: 19.9917866 },
    { lat: 48.2273913, lng: 19.9909628 },
    { lat: 48.2273487, lng: 19.9904093 },
    { lat: 48.2275947, lng: 19.9898077 },
    { lat: 48.2277435, lng: 19.989223 },
    { lat: 48.228184, lng: 19.9887948 },
    { lat: 48.2294752, lng: 19.9879792 },
    { lat: 48.2298494, lng: 19.988007 },
    { lat: 48.2302948, lng: 19.9882291 },
    { lat: 48.2306143, lng: 19.988432 },
    { lat: 48.2307699, lng: 19.9886522 },
    { lat: 48.2311196, lng: 19.9888208 },
    { lat: 48.2320644, lng: 19.9904912 },
    { lat: 48.2322305, lng: 19.9906072 },
    { lat: 48.2323887, lng: 19.99086 },
    { lat: 48.2324355, lng: 19.9910351 },
    { lat: 48.2330729, lng: 19.9922591 },
    { lat: 48.2332212, lng: 19.9924892 },
    { lat: 48.2336799, lng: 19.9929694 },
    { lat: 48.2341873, lng: 19.9936757 },
    { lat: 48.2342431, lng: 19.9936885 },
    { lat: 48.2347852, lng: 19.9931619 },
    { lat: 48.2351657, lng: 19.9939322 },
    { lat: 48.235742, lng: 19.9936776 },
    { lat: 48.2362127, lng: 19.9933797 },
    { lat: 48.2364399, lng: 19.9930812 },
    { lat: 48.2367131, lng: 19.9924535 },
    { lat: 48.2371784, lng: 19.9907236 },
    { lat: 48.2372404, lng: 19.9899792 },
    { lat: 48.2368862, lng: 19.9881838 },
    { lat: 48.2368214, lng: 19.9875072 },
    { lat: 48.2368111, lng: 19.9862192 },
    { lat: 48.2368636, lng: 19.9849902 },
    { lat: 48.2379444, lng: 19.9848149 },
    { lat: 48.2387811, lng: 19.984968 },
    { lat: 48.2388693, lng: 19.9848964 },
    { lat: 48.2394593, lng: 19.9845376 },
    { lat: 48.2407682, lng: 19.9846428 },
    { lat: 48.2416998, lng: 19.985048 },
    { lat: 48.2418193, lng: 19.9851 },
    { lat: 48.2423132, lng: 19.9848164 },
    { lat: 48.2425834, lng: 19.9847869 },
    { lat: 48.2432807, lng: 19.9849186 },
    { lat: 48.2439362, lng: 19.9848628 },
    { lat: 48.2454762, lng: 19.9839364 },
    { lat: 48.2462221, lng: 19.9832213 },
    { lat: 48.2468332, lng: 19.9825405 },
    { lat: 48.2479827, lng: 19.982048 },
    { lat: 48.2484986, lng: 19.9822337 },
    { lat: 48.2501047, lng: 19.9831299 },
    { lat: 48.2511904, lng: 19.9832824 },
    { lat: 48.2515075, lng: 19.9831346 },
    { lat: 48.2521496, lng: 19.9838043 },
    { lat: 48.2529714, lng: 19.9851214 },
    { lat: 48.2538225, lng: 19.9859343 },
    { lat: 48.2539479, lng: 19.9861242 },
  ],
  Štrkovec: [
    { lat: 48.3497931, lng: 20.3154104 },
    { lat: 48.3508481, lng: 20.3180641 },
    { lat: 48.3518768, lng: 20.3209175 },
    { lat: 48.3535142, lng: 20.3246675 },
    { lat: 48.353679, lng: 20.324819 },
    { lat: 48.3543188, lng: 20.3309698 },
    { lat: 48.3539272, lng: 20.3317507 },
    { lat: 48.3533356, lng: 20.3320044 },
    { lat: 48.3524315, lng: 20.3329548 },
    { lat: 48.352444, lng: 20.3343409 },
    { lat: 48.3525288, lng: 20.3352398 },
    { lat: 48.3525472, lng: 20.3368871 },
    { lat: 48.3524756, lng: 20.3387168 },
    { lat: 48.3525409, lng: 20.3412486 },
    { lat: 48.352403, lng: 20.3428449 },
    { lat: 48.352553, lng: 20.345332 },
    { lat: 48.3524974, lng: 20.346301 },
    { lat: 48.3530322, lng: 20.346356 },
    { lat: 48.3538886, lng: 20.3470316 },
    { lat: 48.3540327, lng: 20.3470586 },
    { lat: 48.3543184, lng: 20.3478549 },
    { lat: 48.3544224, lng: 20.3502276 },
    { lat: 48.3544965, lng: 20.3505961 },
    { lat: 48.3547748, lng: 20.3509806 },
    { lat: 48.3552379, lng: 20.3514792 },
    { lat: 48.3553552, lng: 20.3518845 },
    { lat: 48.3559078, lng: 20.3525091 },
    { lat: 48.3559202, lng: 20.3540136 },
    { lat: 48.3569223, lng: 20.3545233 },
    { lat: 48.3571333, lng: 20.3547367 },
    { lat: 48.3589926, lng: 20.3556609 },
    { lat: 48.3620121, lng: 20.3572822 },
    { lat: 48.362169, lng: 20.3573478 },
    { lat: 48.3637246, lng: 20.3575346 },
    { lat: 48.3640486, lng: 20.3575812 },
    { lat: 48.3652591, lng: 20.350152 },
    { lat: 48.3662931, lng: 20.3443907 },
    { lat: 48.3664341, lng: 20.3443977 },
    { lat: 48.369306, lng: 20.3411171 },
    { lat: 48.3695435, lng: 20.3408528 },
    { lat: 48.3693639, lng: 20.3405811 },
    { lat: 48.3688694, lng: 20.339221 },
    { lat: 48.3689184, lng: 20.3388098 },
    { lat: 48.3676675, lng: 20.3373163 },
    { lat: 48.3678192, lng: 20.3370487 },
    { lat: 48.3678736, lng: 20.3363401 },
    { lat: 48.3666406, lng: 20.3347039 },
    { lat: 48.3663986, lng: 20.3342496 },
    { lat: 48.3658345, lng: 20.3335068 },
    { lat: 48.3654014, lng: 20.3327095 },
    { lat: 48.3648355, lng: 20.331464 },
    { lat: 48.3649854, lng: 20.3310899 },
    { lat: 48.3648264, lng: 20.3304551 },
    { lat: 48.3642967, lng: 20.3308493 },
    { lat: 48.3640121, lng: 20.3307108 },
    { lat: 48.3639889, lng: 20.3299447 },
    { lat: 48.3640902, lng: 20.3293667 },
    { lat: 48.3638025, lng: 20.32732 },
    { lat: 48.3636884, lng: 20.3250692 },
    { lat: 48.3624264, lng: 20.3194048 },
    { lat: 48.3631603, lng: 20.3190792 },
    { lat: 48.3633134, lng: 20.3184767 },
    { lat: 48.3632011, lng: 20.3177978 },
    { lat: 48.3630715, lng: 20.3152471 },
    { lat: 48.3617762, lng: 20.3099188 },
    { lat: 48.3613839, lng: 20.3083041 },
    { lat: 48.3591191, lng: 20.3088233 },
    { lat: 48.3587215, lng: 20.3090516 },
    { lat: 48.3583927, lng: 20.3095117 },
    { lat: 48.3582225, lng: 20.3099886 },
    { lat: 48.3579044, lng: 20.3119258 },
    { lat: 48.3577511, lng: 20.3124928 },
    { lat: 48.3566634, lng: 20.3138728 },
    { lat: 48.3564385, lng: 20.3141126 },
    { lat: 48.3559876, lng: 20.3144458 },
    { lat: 48.3554932, lng: 20.3146436 },
    { lat: 48.3516213, lng: 20.3148145 },
    { lat: 48.3506741, lng: 20.3150432 },
    { lat: 48.3500133, lng: 20.3153084 },
    { lat: 48.3497931, lng: 20.3154104 },
  ],
  Janice: [
    { lat: 48.2879528, lng: 20.1995589 },
    { lat: 48.2875097, lng: 20.1990775 },
    { lat: 48.2871514, lng: 20.198988 },
    { lat: 48.2869285, lng: 20.199185 },
    { lat: 48.2867758, lng: 20.1996979 },
    { lat: 48.2862353, lng: 20.2002604 },
    { lat: 48.2858132, lng: 20.2004903 },
    { lat: 48.2856009, lng: 20.2003642 },
    { lat: 48.2853653, lng: 20.2001122 },
    { lat: 48.2852691, lng: 20.1998762 },
    { lat: 48.2849336, lng: 20.1996266 },
    { lat: 48.2845146, lng: 20.1990418 },
    { lat: 48.2844386, lng: 20.1987779 },
    { lat: 48.2841501, lng: 20.1987754 },
    { lat: 48.2840339, lng: 20.198968 },
    { lat: 48.283939, lng: 20.1989547 },
    { lat: 48.2837997, lng: 20.1991647 },
    { lat: 48.2835053, lng: 20.1985962 },
    { lat: 48.2826939, lng: 20.1988354 },
    { lat: 48.2827064, lng: 20.1985015 },
    { lat: 48.2825242, lng: 20.1984777 },
    { lat: 48.2811102, lng: 20.1986898 },
    { lat: 48.2786307, lng: 20.1987623 },
    { lat: 48.27608, lng: 20.1990527 },
    { lat: 48.276045, lng: 20.1989908 },
    { lat: 48.2757147, lng: 20.199071 },
    { lat: 48.2752316, lng: 20.1990713 },
    { lat: 48.2746914, lng: 20.1989146 },
    { lat: 48.2738803, lng: 20.1982393 },
    { lat: 48.2734992, lng: 20.198066 },
    { lat: 48.2733277, lng: 20.1980862 },
    { lat: 48.2726998, lng: 20.1983747 },
    { lat: 48.2717224, lng: 20.1982166 },
    { lat: 48.270669, lng: 20.1984889 },
    { lat: 48.2696966, lng: 20.1986464 },
    { lat: 48.2691458, lng: 20.1988116 },
    { lat: 48.2690349, lng: 20.1989638 },
    { lat: 48.2690636, lng: 20.1991496 },
    { lat: 48.2689839, lng: 20.1993499 },
    { lat: 48.2689292, lng: 20.1991116 },
    { lat: 48.2687819, lng: 20.1990051 },
    { lat: 48.2681528, lng: 20.1987925 },
    { lat: 48.2679796, lng: 20.1988584 },
    { lat: 48.2679316, lng: 20.1987849 },
    { lat: 48.2678564, lng: 20.1988762 },
    { lat: 48.2677133, lng: 20.1988153 },
    { lat: 48.2675351, lng: 20.198892 },
    { lat: 48.2671719, lng: 20.1989086 },
    { lat: 48.2665371, lng: 20.1992568 },
    { lat: 48.2661147, lng: 20.1989996 },
    { lat: 48.2658334, lng: 20.1990958 },
    { lat: 48.2657213, lng: 20.1992173 },
    { lat: 48.2656643, lng: 20.1991451 },
    { lat: 48.2655808, lng: 20.1987493 },
    { lat: 48.2652611, lng: 20.1982265 },
    { lat: 48.2651323, lng: 20.1978832 },
    { lat: 48.2650794, lng: 20.1968781 },
    { lat: 48.2651355, lng: 20.1967599 },
    { lat: 48.2651263, lng: 20.1965925 },
    { lat: 48.2651962, lng: 20.1962298 },
    { lat: 48.2651404, lng: 20.1958835 },
    { lat: 48.2651951, lng: 20.1957318 },
    { lat: 48.265182, lng: 20.1955474 },
    { lat: 48.2651103, lng: 20.195274 },
    { lat: 48.2651448, lng: 20.1951818 },
    { lat: 48.2651132, lng: 20.1948212 },
    { lat: 48.2651495, lng: 20.1946575 },
    { lat: 48.2650599, lng: 20.1942262 },
    { lat: 48.2649005, lng: 20.1937942 },
    { lat: 48.2649297, lng: 20.19346 },
    { lat: 48.2648448, lng: 20.1930594 },
    { lat: 48.2648625, lng: 20.1927138 },
    { lat: 48.2641758, lng: 20.1914761 },
    { lat: 48.2641014, lng: 20.1914454 },
    { lat: 48.2636954, lng: 20.1907342 },
    { lat: 48.2630564, lng: 20.1901716 },
    { lat: 48.2622988, lng: 20.1897929 },
    { lat: 48.2616097, lng: 20.1892942 },
    { lat: 48.2610314, lng: 20.1889795 },
    { lat: 48.260748, lng: 20.1889855 },
    { lat: 48.2604832, lng: 20.1891269 },
    { lat: 48.2602942, lng: 20.1890762 },
    { lat: 48.2600158, lng: 20.1888223 },
    { lat: 48.2599703, lng: 20.1887002 },
    { lat: 48.2599265, lng: 20.1888654 },
    { lat: 48.2595906, lng: 20.1888365 },
    { lat: 48.2594651, lng: 20.1884351 },
    { lat: 48.259457, lng: 20.1882361 },
    { lat: 48.2593936, lng: 20.1881387 },
    { lat: 48.2588888, lng: 20.188081 },
    { lat: 48.2588506, lng: 20.1879431 },
    { lat: 48.258641, lng: 20.1877738 },
    { lat: 48.2585949, lng: 20.187579 },
    { lat: 48.2584668, lng: 20.1873662 },
    { lat: 48.2583537, lng: 20.1872994 },
    { lat: 48.258341, lng: 20.1870987 },
    { lat: 48.2581305, lng: 20.1866025 },
    { lat: 48.2579931, lng: 20.1861319 },
    { lat: 48.2576665, lng: 20.1858233 },
    { lat: 48.2576061, lng: 20.1858362 },
    { lat: 48.2575552, lng: 20.1855228 },
    { lat: 48.2575157, lng: 20.1854928 },
    { lat: 48.2575579, lng: 20.1853394 },
    { lat: 48.2574837, lng: 20.1852857 },
    { lat: 48.2574119, lng: 20.1846858 },
    { lat: 48.2570121, lng: 20.1836848 },
    { lat: 48.2568784, lng: 20.183083 },
    { lat: 48.2567094, lng: 20.1827213 },
    { lat: 48.2563614, lng: 20.1823596 },
    { lat: 48.2560811, lng: 20.1818956 },
    { lat: 48.2559878, lng: 20.181593 },
    { lat: 48.2559156, lng: 20.1816438 },
    { lat: 48.2556849, lng: 20.181176 },
    { lat: 48.2551322, lng: 20.1805208 },
    { lat: 48.2546327, lng: 20.1786783 },
    { lat: 48.2541012, lng: 20.1770448 },
    { lat: 48.2537593, lng: 20.176971 },
    { lat: 48.253508, lng: 20.1767765 },
    { lat: 48.2522924, lng: 20.1750269 },
    { lat: 48.2514633, lng: 20.174191 },
    { lat: 48.2512145, lng: 20.1738303 },
    { lat: 48.2511245, lng: 20.173284 },
    { lat: 48.250779, lng: 20.172505 },
    { lat: 48.250641, lng: 20.172718 },
    { lat: 48.250343, lng: 20.172943 },
    { lat: 48.249952, lng: 20.173226 },
    { lat: 48.249657, lng: 20.173401 },
    { lat: 48.249466, lng: 20.173547 },
    { lat: 48.249289, lng: 20.173738 },
    { lat: 48.249185, lng: 20.174112 },
    { lat: 48.249135, lng: 20.174306 },
    { lat: 48.249143, lng: 20.174857 },
    { lat: 48.249039, lng: 20.175499 },
    { lat: 48.248981, lng: 20.175973 },
    { lat: 48.24902, lng: 20.176476 },
    { lat: 48.249005, lng: 20.176844 },
    { lat: 48.249018, lng: 20.177018 },
    { lat: 48.249004, lng: 20.177367 },
    { lat: 48.248906, lng: 20.177866 },
    { lat: 48.248909, lng: 20.178076 },
    { lat: 48.249042, lng: 20.178664 },
    { lat: 48.249231, lng: 20.179479 },
    { lat: 48.249116, lng: 20.180029 },
    { lat: 48.249251, lng: 20.180267 },
    { lat: 48.249324, lng: 20.180671 },
    { lat: 48.249393, lng: 20.181371 },
    { lat: 48.249307, lng: 20.181797 },
    { lat: 48.249159, lng: 20.181928 },
    { lat: 48.249025, lng: 20.182215 },
    { lat: 48.248961, lng: 20.182494 },
    { lat: 48.248966, lng: 20.182698 },
    { lat: 48.248846, lng: 20.183035 },
    { lat: 48.248789, lng: 20.183208 },
    { lat: 48.24877, lng: 20.18359 },
    { lat: 48.248602, lng: 20.184003 },
    { lat: 48.248344, lng: 20.184377 },
    { lat: 48.248038, lng: 20.184832 },
    { lat: 48.247978, lng: 20.184933 },
    { lat: 48.24799, lng: 20.18515 },
    { lat: 48.248053, lng: 20.185418 },
    { lat: 48.24816, lng: 20.185687 },
    { lat: 48.248212, lng: 20.186074 },
    { lat: 48.248222, lng: 20.186315 },
    { lat: 48.248302, lng: 20.186539 },
    { lat: 48.248403, lng: 20.186992 },
    { lat: 48.248357, lng: 20.187364 },
    { lat: 48.248384, lng: 20.187625 },
    { lat: 48.248658, lng: 20.188322 },
    { lat: 48.248578, lng: 20.188502 },
    { lat: 48.248499, lng: 20.188766 },
    { lat: 48.24836, lng: 20.189034 },
    { lat: 48.248287, lng: 20.189286 },
    { lat: 48.24819, lng: 20.1895 },
    { lat: 48.24807, lng: 20.189876 },
    { lat: 48.248164, lng: 20.190325 },
    { lat: 48.248188, lng: 20.190587 },
    { lat: 48.248178, lng: 20.190988 },
    { lat: 48.248207, lng: 20.19135 },
    { lat: 48.248144, lng: 20.191728 },
    { lat: 48.248125, lng: 20.19193 },
    { lat: 48.248083, lng: 20.192065 },
    { lat: 48.248074, lng: 20.192228 },
    { lat: 48.248102, lng: 20.19248 },
    { lat: 48.248142, lng: 20.192693 },
    { lat: 48.248193, lng: 20.192903 },
    { lat: 48.248265, lng: 20.193204 },
    { lat: 48.248373, lng: 20.193704 },
    { lat: 48.248475, lng: 20.194174 },
    { lat: 48.248497, lng: 20.194664 },
    { lat: 48.248496, lng: 20.194904 },
    { lat: 48.248476, lng: 20.195394 },
    { lat: 48.248476, lng: 20.195557 },
    { lat: 48.248517, lng: 20.195746 },
    { lat: 48.248592, lng: 20.195944 },
    { lat: 48.248732, lng: 20.19626 },
    { lat: 48.248821, lng: 20.196383 },
    { lat: 48.249032, lng: 20.196638 },
    { lat: 48.249119, lng: 20.196728 },
    { lat: 48.249174, lng: 20.19679 },
    { lat: 48.249338, lng: 20.196913 },
    { lat: 48.249443, lng: 20.196972 },
    { lat: 48.250044, lng: 20.197433 },
    { lat: 48.250052, lng: 20.197657 },
    { lat: 48.249898, lng: 20.198564 },
    { lat: 48.250021, lng: 20.198895 },
    { lat: 48.250116, lng: 20.199154 },
    { lat: 48.250236, lng: 20.199699 },
    { lat: 48.250298, lng: 20.19998 },
    { lat: 48.250304, lng: 20.200323 },
    { lat: 48.25032, lng: 20.20122 },
    { lat: 48.250789, lng: 20.202514 },
    { lat: 48.250852, lng: 20.202687 },
    { lat: 48.250938, lng: 20.20313 },
    { lat: 48.2511, lng: 20.203692 },
    { lat: 48.251146, lng: 20.204005 },
    { lat: 48.251111, lng: 20.20441 },
    { lat: 48.25114, lng: 20.204754 },
    { lat: 48.25098, lng: 20.205322 },
    { lat: 48.25092, lng: 20.205793 },
    { lat: 48.250979, lng: 20.206162 },
    { lat: 48.251116, lng: 20.206317 },
    { lat: 48.251467, lng: 20.206573 },
    { lat: 48.2518174, lng: 20.2070372 },
    { lat: 48.251932, lng: 20.207189 },
    { lat: 48.25219, lng: 20.207411 },
    { lat: 48.252437, lng: 20.207595 },
    { lat: 48.252694, lng: 20.2077 },
    { lat: 48.252933, lng: 20.207713 },
    { lat: 48.253892, lng: 20.207288 },
    { lat: 48.254107, lng: 20.207166 },
    { lat: 48.254919, lng: 20.206677 },
    { lat: 48.255201, lng: 20.206372 },
    { lat: 48.25587, lng: 20.205533 },
    { lat: 48.25629, lng: 20.205118 },
    { lat: 48.256815, lng: 20.204412 },
    { lat: 48.257058, lng: 20.204262 },
    { lat: 48.257382, lng: 20.204013 },
    { lat: 48.257446, lng: 20.20397 },
    { lat: 48.2576, lng: 20.203887 },
    { lat: 48.257892, lng: 20.203805 },
    { lat: 48.258102, lng: 20.203676 },
    { lat: 48.258173, lng: 20.203633 },
    { lat: 48.258214, lng: 20.203608 },
    { lat: 48.25873, lng: 20.203378 },
    { lat: 48.259079, lng: 20.203129 },
    { lat: 48.259242, lng: 20.203149 },
    { lat: 48.260165, lng: 20.203515 },
    { lat: 48.260375, lng: 20.203604 },
    { lat: 48.260618, lng: 20.203546 },
    { lat: 48.260998, lng: 20.203413 },
    { lat: 48.261557, lng: 20.20363 },
    { lat: 48.261906, lng: 20.20383 },
    { lat: 48.262059, lng: 20.204521 },
    { lat: 48.262008, lng: 20.204925 },
    { lat: 48.262081, lng: 20.20576 },
    { lat: 48.262108, lng: 20.206125 },
    { lat: 48.261721, lng: 20.206325 },
    { lat: 48.261294, lng: 20.206574 },
    { lat: 48.260976, lng: 20.2069 },
    { lat: 48.260879, lng: 20.207324 },
    { lat: 48.260835, lng: 20.207515 },
    { lat: 48.260381, lng: 20.20825 },
    { lat: 48.259968, lng: 20.208811 },
    { lat: 48.259846, lng: 20.208955 },
    { lat: 48.259627, lng: 20.20921 },
    { lat: 48.259515, lng: 20.209525 },
    { lat: 48.259488, lng: 20.210556 },
    { lat: 48.259645, lng: 20.211167 },
    { lat: 48.259628, lng: 20.211688 },
    { lat: 48.259667, lng: 20.212184 },
    { lat: 48.259375, lng: 20.212782 },
    { lat: 48.259254, lng: 20.213145 },
    { lat: 48.259044, lng: 20.213586 },
    { lat: 48.258975, lng: 20.21417 },
    { lat: 48.2589, lng: 20.214605 },
    { lat: 48.258971, lng: 20.215293 },
    { lat: 48.259123, lng: 20.215568 },
    { lat: 48.259463, lng: 20.216098 },
    { lat: 48.259784, lng: 20.216706 },
    { lat: 48.260052, lng: 20.217289 },
    { lat: 48.260238, lng: 20.217507 },
    { lat: 48.26034, lng: 20.21765 },
    { lat: 48.260372, lng: 20.217694 },
    { lat: 48.260939, lng: 20.218443 },
    { lat: 48.261311, lng: 20.21899 },
    { lat: 48.261581, lng: 20.219259 },
    { lat: 48.262214, lng: 20.219816 },
    { lat: 48.262502, lng: 20.220094 },
    { lat: 48.262619, lng: 20.220203 },
    { lat: 48.262812, lng: 20.220681 },
    { lat: 48.262892, lng: 20.220937 },
    { lat: 48.263031, lng: 20.221718 },
    { lat: 48.263067, lng: 20.221813 },
    { lat: 48.263189, lng: 20.222391 },
    { lat: 48.263253, lng: 20.222702 },
    { lat: 48.263281, lng: 20.223882 },
    { lat: 48.263299, lng: 20.223883 },
    { lat: 48.26329, lng: 20.224469 },
    { lat: 48.26325, lng: 20.224837 },
    { lat: 48.2632, lng: 20.225131 },
    { lat: 48.263114, lng: 20.22543 },
    { lat: 48.263038, lng: 20.22566 },
    { lat: 48.262864, lng: 20.226066 },
    { lat: 48.262769, lng: 20.226295 },
    { lat: 48.262693, lng: 20.22654 },
    { lat: 48.262672, lng: 20.226674 },
    { lat: 48.262565, lng: 20.227116 },
    { lat: 48.262551, lng: 20.227238 },
    { lat: 48.262552, lng: 20.227312 },
    { lat: 48.262576, lng: 20.227442 },
    { lat: 48.262685, lng: 20.227912 },
    { lat: 48.262768, lng: 20.22834 },
    { lat: 48.262779, lng: 20.228466 },
    { lat: 48.262803, lng: 20.228465 },
    { lat: 48.262973, lng: 20.228561 },
    { lat: 48.26326, lng: 20.228619 },
    { lat: 48.263741, lng: 20.22866 },
    { lat: 48.264132, lng: 20.228644 },
    { lat: 48.264756, lng: 20.228515 },
    { lat: 48.2653, lng: 20.228555 },
    { lat: 48.265841, lng: 20.228655 },
    { lat: 48.266, lng: 20.228797 },
    { lat: 48.266338, lng: 20.229391 },
    { lat: 48.266673, lng: 20.229982 },
    { lat: 48.266827, lng: 20.230368 },
    { lat: 48.267386, lng: 20.231274 },
    { lat: 48.267961, lng: 20.231961 },
    { lat: 48.268123, lng: 20.232084 },
    { lat: 48.268393, lng: 20.232204 },
    { lat: 48.268606, lng: 20.232384 },
    { lat: 48.269024, lng: 20.233218 },
    { lat: 48.269498, lng: 20.233925 },
    { lat: 48.270017, lng: 20.234451 },
    { lat: 48.270716, lng: 20.234959 },
    { lat: 48.2713, lng: 20.235381 },
    { lat: 48.271826, lng: 20.235358 },
    { lat: 48.272346, lng: 20.234853 },
    { lat: 48.272524, lng: 20.234666 },
    { lat: 48.272721, lng: 20.234335 },
    { lat: 48.272926, lng: 20.233864 },
    { lat: 48.273169, lng: 20.2331 },
    { lat: 48.27343, lng: 20.232294 },
    { lat: 48.273694, lng: 20.231749 },
    { lat: 48.2738492, lng: 20.2315065 },
    { lat: 48.273938, lng: 20.231369 },
    { lat: 48.274429, lng: 20.231007 },
    { lat: 48.274645, lng: 20.230777 },
    { lat: 48.274732, lng: 20.230638 },
    { lat: 48.274825, lng: 20.230327 },
    { lat: 48.274858, lng: 20.230014 },
    { lat: 48.274975, lng: 20.229539 },
    { lat: 48.275052, lng: 20.22906 },
    { lat: 48.275154, lng: 20.229037 },
    { lat: 48.275258, lng: 20.229014 },
    { lat: 48.2755641, lng: 20.2289323 },
    { lat: 48.275615, lng: 20.228914 },
    { lat: 48.276077, lng: 20.228621 },
    { lat: 48.276485, lng: 20.228331 },
    { lat: 48.276819, lng: 20.228145 },
    { lat: 48.27722, lng: 20.227986 },
    { lat: 48.277232, lng: 20.22798 },
    { lat: 48.2768486, lng: 20.2263917 },
    { lat: 48.2765779, lng: 20.225681 },
    { lat: 48.2764872, lng: 20.2247968 },
    { lat: 48.2764614, lng: 20.2236267 },
    { lat: 48.2767246, lng: 20.2231942 },
    { lat: 48.2768534, lng: 20.2230412 },
    { lat: 48.2779211, lng: 20.2229289 },
    { lat: 48.2780923, lng: 20.222702 },
    { lat: 48.2787608, lng: 20.222269 },
    { lat: 48.2792362, lng: 20.2215133 },
    { lat: 48.2812667, lng: 20.2178445 },
    { lat: 48.2821048, lng: 20.2179845 },
    { lat: 48.2820653, lng: 20.217621 },
    { lat: 48.2829462, lng: 20.2175414 },
    { lat: 48.2831829, lng: 20.2173238 },
    { lat: 48.2835263, lng: 20.2179042 },
    { lat: 48.28424, lng: 20.2175041 },
    { lat: 48.2841759, lng: 20.2164999 },
    { lat: 48.2841027, lng: 20.2153545 },
    { lat: 48.286816, lng: 20.21337 },
    { lat: 48.2877413, lng: 20.2124365 },
    { lat: 48.288038, lng: 20.2122494 },
    { lat: 48.2890071, lng: 20.2121208 },
    { lat: 48.2892245, lng: 20.2121517 },
    { lat: 48.2893305, lng: 20.2114029 },
    { lat: 48.2893284, lng: 20.211099 },
    { lat: 48.2891762, lng: 20.2107587 },
    { lat: 48.2891167, lng: 20.2103429 },
    { lat: 48.2893666, lng: 20.2101438 },
    { lat: 48.2893765, lng: 20.2100251 },
    { lat: 48.2890683, lng: 20.2093666 },
    { lat: 48.2889733, lng: 20.2093264 },
    { lat: 48.2887371, lng: 20.2094231 },
    { lat: 48.288648, lng: 20.2096943 },
    { lat: 48.2884873, lng: 20.2097898 },
    { lat: 48.2886006, lng: 20.2091624 },
    { lat: 48.2885609, lng: 20.2089634 },
    { lat: 48.2884293, lng: 20.2088833 },
    { lat: 48.288337, lng: 20.2089652 },
    { lat: 48.2878441, lng: 20.2075801 },
    { lat: 48.2879684, lng: 20.2071158 },
    { lat: 48.2879708, lng: 20.2065631 },
    { lat: 48.2879041, lng: 20.206079 },
    { lat: 48.2877012, lng: 20.2054912 },
    { lat: 48.2869501, lng: 20.2041847 },
    { lat: 48.2869086, lng: 20.2037103 },
    { lat: 48.2869759, lng: 20.2034849 },
    { lat: 48.2868838, lng: 20.2032365 },
    { lat: 48.2868735, lng: 20.2030709 },
    { lat: 48.2866599, lng: 20.2028243 },
    { lat: 48.2864048, lng: 20.2028672 },
    { lat: 48.2863705, lng: 20.2026596 },
    { lat: 48.2865284, lng: 20.202415 },
    { lat: 48.2868847, lng: 20.2020606 },
    { lat: 48.2870702, lng: 20.2020257 },
    { lat: 48.2871291, lng: 20.2021993 },
    { lat: 48.2873031, lng: 20.2022122 },
    { lat: 48.2874602, lng: 20.2019225 },
    { lat: 48.2876439, lng: 20.2018956 },
    { lat: 48.2876828, lng: 20.2014097 },
    { lat: 48.2877825, lng: 20.2009752 },
    { lat: 48.2878555, lng: 20.1999663 },
    { lat: 48.2879336, lng: 20.1997254 },
    { lat: 48.2879528, lng: 20.1995589 },
  ],
  LehotanadRimavicou: [
    { lat: 48.570287, lng: 19.8835717 },
    { lat: 48.570711, lng: 19.882728 },
    { lat: 48.570706, lng: 19.88261 },
    { lat: 48.570716, lng: 19.879211 },
    { lat: 48.570271, lng: 19.879659 },
    { lat: 48.569821, lng: 19.880011 },
    { lat: 48.569276, lng: 19.880583 },
    { lat: 48.568978, lng: 19.880686 },
    { lat: 48.568711, lng: 19.880777 },
    { lat: 48.568241, lng: 19.880777 },
    { lat: 48.567456, lng: 19.880866 },
    { lat: 48.566941, lng: 19.880919 },
    { lat: 48.566943, lng: 19.880939 },
    { lat: 48.566732, lng: 19.880968 },
    { lat: 48.566114, lng: 19.881051 },
    { lat: 48.565622, lng: 19.881217 },
    { lat: 48.564761, lng: 19.881127 },
    { lat: 48.563851, lng: 19.880914 },
    { lat: 48.563474, lng: 19.880631 },
    { lat: 48.56313, lng: 19.880456 },
    { lat: 48.563126, lng: 19.880475 },
    { lat: 48.562095, lng: 19.880011 },
    { lat: 48.561639, lng: 19.879766 },
    { lat: 48.560248, lng: 19.878954 },
    { lat: 48.559931, lng: 19.878817 },
    { lat: 48.559706, lng: 19.878292 },
    { lat: 48.559842, lng: 19.87749 },
    { lat: 48.559967, lng: 19.87693 },
    { lat: 48.55999, lng: 19.87644 },
    { lat: 48.560124, lng: 19.875656 },
    { lat: 48.559914, lng: 19.874157 },
    { lat: 48.559938, lng: 19.87368 },
    { lat: 48.559947, lng: 19.872554 },
    { lat: 48.55985, lng: 19.871606 },
    { lat: 48.559834, lng: 19.871454 },
    { lat: 48.559565, lng: 19.870928 },
    { lat: 48.559291, lng: 19.870587 },
    { lat: 48.559045, lng: 19.870405 },
    { lat: 48.558541, lng: 19.870107 },
    { lat: 48.558065, lng: 19.869778 },
    { lat: 48.557794, lng: 19.869477 },
    { lat: 48.557257, lng: 19.868859 },
    { lat: 48.557194, lng: 19.868754 },
    { lat: 48.556737, lng: 19.867983 },
    { lat: 48.55649, lng: 19.86766 },
    { lat: 48.555931, lng: 19.866801 },
    { lat: 48.55544, lng: 19.866441 },
    { lat: 48.555143, lng: 19.866203 },
    { lat: 48.55494, lng: 19.865788 },
    { lat: 48.554803, lng: 19.865503 },
    { lat: 48.554724, lng: 19.865423 },
    { lat: 48.554437, lng: 19.865132 },
    { lat: 48.554238, lng: 19.86493 },
    { lat: 48.554042, lng: 19.864628 },
    { lat: 48.553653, lng: 19.864124 },
    { lat: 48.553425, lng: 19.863926 },
    { lat: 48.553179, lng: 19.863559 },
    { lat: 48.553096, lng: 19.863353 },
    { lat: 48.553016, lng: 19.863244 },
    { lat: 48.552398, lng: 19.862396 },
    { lat: 48.552078, lng: 19.862157 },
    { lat: 48.551865, lng: 19.861932 },
    { lat: 48.551619, lng: 19.861724 },
    { lat: 48.551181, lng: 19.861233 },
    { lat: 48.550946, lng: 19.861028 },
    { lat: 48.550705, lng: 19.860719 },
    { lat: 48.550696, lng: 19.860737 },
    { lat: 48.550285, lng: 19.860628 },
    { lat: 48.549816, lng: 19.860292 },
    { lat: 48.549411, lng: 19.859878 },
    { lat: 48.549033, lng: 19.859572 },
    { lat: 48.548926, lng: 19.859388 },
    { lat: 48.548101, lng: 19.858172 },
    { lat: 48.547952, lng: 19.858413 },
    { lat: 48.547268, lng: 19.858355 },
    { lat: 48.547078, lng: 19.858249 },
    { lat: 48.546583, lng: 19.857941 },
    { lat: 48.546367, lng: 19.857852 },
    { lat: 48.545934, lng: 19.857726 },
    { lat: 48.545526, lng: 19.857352 },
    { lat: 48.54533, lng: 19.856992 },
    { lat: 48.545294, lng: 19.856966 },
    { lat: 48.545479, lng: 19.856452 },
    { lat: 48.545451, lng: 19.856369 },
    { lat: 48.545325, lng: 19.855837 },
    { lat: 48.545265, lng: 19.855667 },
    { lat: 48.545237, lng: 19.855635 },
    { lat: 48.545196, lng: 19.855586 },
    { lat: 48.545179, lng: 19.855567 },
    { lat: 48.545056, lng: 19.855308 },
    { lat: 48.544972, lng: 19.855085 },
    { lat: 48.54488, lng: 19.854557 },
    { lat: 48.544854, lng: 19.85423 },
    { lat: 48.544842, lng: 19.854067 },
    { lat: 48.54501, lng: 19.853975 },
    { lat: 48.545222, lng: 19.853676 },
    { lat: 48.545295, lng: 19.853499 },
    { lat: 48.545445, lng: 19.853297 },
    { lat: 48.545579, lng: 19.853214 },
    { lat: 48.545723, lng: 19.853173 },
    { lat: 48.546045, lng: 19.853114 },
    { lat: 48.546311, lng: 19.85304 },
    { lat: 48.546378, lng: 19.852929 },
    { lat: 48.546492, lng: 19.85276 },
    { lat: 48.546508, lng: 19.852626 },
    { lat: 48.546544, lng: 19.852436 },
    { lat: 48.546506, lng: 19.852329 },
    { lat: 48.546525, lng: 19.852308 },
    { lat: 48.546805, lng: 19.852043 },
    { lat: 48.546888, lng: 19.852067 },
    { lat: 48.547089, lng: 19.851896 },
    { lat: 48.547217, lng: 19.851947 },
    { lat: 48.547347, lng: 19.852005 },
    { lat: 48.547501, lng: 19.851747 },
    { lat: 48.547599, lng: 19.851772 },
    { lat: 48.547739, lng: 19.851466 },
    { lat: 48.547759, lng: 19.850934 },
    { lat: 48.547655, lng: 19.85082 },
    { lat: 48.547575, lng: 19.850534 },
    { lat: 48.547478, lng: 19.850299 },
    { lat: 48.547426, lng: 19.850172 },
    { lat: 48.547403, lng: 19.850121 },
    { lat: 48.54739, lng: 19.85008 },
    { lat: 48.547379, lng: 19.850045 },
    { lat: 48.547416, lng: 19.850026 },
    { lat: 48.547436, lng: 19.850021 },
    { lat: 48.5475, lng: 19.850004 },
    { lat: 48.54763, lng: 19.84993 },
    { lat: 48.54763, lng: 19.849866 },
    { lat: 48.547599, lng: 19.849702 },
    { lat: 48.547604, lng: 19.84959 },
    { lat: 48.547514, lng: 19.849339 },
    { lat: 48.54751, lng: 19.849287 },
    { lat: 48.547535, lng: 19.849234 },
    { lat: 48.547645, lng: 19.849122 },
    { lat: 48.547684, lng: 19.848991 },
    { lat: 48.547649, lng: 19.848862 },
    { lat: 48.547588, lng: 19.848656 },
    { lat: 48.547625, lng: 19.848289 },
    { lat: 48.547771, lng: 19.848078 },
    { lat: 48.547773, lng: 19.847436 },
    { lat: 48.547813, lng: 19.84744 },
    { lat: 48.547903, lng: 19.847442 },
    { lat: 48.54792, lng: 19.847183 },
    { lat: 48.5479, lng: 19.847115 },
    { lat: 48.547947, lng: 19.846865 },
    { lat: 48.547962, lng: 19.846787 },
    { lat: 48.548175, lng: 19.846001 },
    { lat: 48.548441, lng: 19.84563 },
    { lat: 48.548511, lng: 19.845409 },
    { lat: 48.548544, lng: 19.845148 },
    { lat: 48.548555, lng: 19.844949 },
    { lat: 48.54858, lng: 19.844449 },
    { lat: 48.548619, lng: 19.844409 },
    { lat: 48.548715, lng: 19.844151 },
    { lat: 48.548726, lng: 19.843804 },
    { lat: 48.548728, lng: 19.843709 },
    { lat: 48.548529, lng: 19.843149 },
    { lat: 48.54836, lng: 19.842276 },
    { lat: 48.548474, lng: 19.842177 },
    { lat: 48.548441, lng: 19.841891 },
    { lat: 48.548316, lng: 19.841512 },
    { lat: 48.548355, lng: 19.841227 },
    { lat: 48.548384, lng: 19.841015 },
    { lat: 48.548283, lng: 19.840698 },
    { lat: 48.547851, lng: 19.840013 },
    { lat: 48.547879, lng: 19.839845 },
    { lat: 48.548004, lng: 19.839501 },
    { lat: 48.548082, lng: 19.839201 },
    { lat: 48.548259, lng: 19.839121 },
    { lat: 48.548362, lng: 19.838987 },
    { lat: 48.548294, lng: 19.838645 },
    { lat: 48.548007, lng: 19.838386 },
    { lat: 48.547952, lng: 19.838214 },
    { lat: 48.547972, lng: 19.838101 },
    { lat: 48.548073, lng: 19.837898 },
    { lat: 48.548001, lng: 19.837391 },
    { lat: 48.548054, lng: 19.837059 },
    { lat: 48.547963, lng: 19.836751 },
    { lat: 48.547804, lng: 19.836208 },
    { lat: 48.547739, lng: 19.835952 },
    { lat: 48.547791, lng: 19.835657 },
    { lat: 48.547786, lng: 19.835444 },
    { lat: 48.547734, lng: 19.835187 },
    { lat: 48.547657, lng: 19.834929 },
    { lat: 48.547499, lng: 19.834698 },
    { lat: 48.547343, lng: 19.83454 },
    { lat: 48.547159, lng: 19.834402 },
    { lat: 48.547068, lng: 19.834298 },
    { lat: 48.546992, lng: 19.834337 },
    { lat: 48.546865, lng: 19.834286 },
    { lat: 48.546729, lng: 19.834104 },
    { lat: 48.546507, lng: 19.833952 },
    { lat: 48.546371, lng: 19.833772 },
    { lat: 48.546111, lng: 19.833451 },
    { lat: 48.54554, lng: 19.832936 },
    { lat: 48.545333, lng: 19.832737 },
    { lat: 48.54531, lng: 19.832473 },
    { lat: 48.545228, lng: 19.832483 },
    { lat: 48.545087, lng: 19.83233 },
    { lat: 48.545058, lng: 19.832338 },
    { lat: 48.544449, lng: 19.831803 },
    { lat: 48.544409, lng: 19.831578 },
    { lat: 48.544333, lng: 19.83144 },
    { lat: 48.544168, lng: 19.831334 },
    { lat: 48.544077, lng: 19.831278 },
    { lat: 48.543746, lng: 19.831433 },
    { lat: 48.543391, lng: 19.831006 },
    { lat: 48.543239, lng: 19.830961 },
    { lat: 48.543133, lng: 19.830673 },
    { lat: 48.542769, lng: 19.830492 },
    { lat: 48.542706, lng: 19.830461 },
    { lat: 48.542477, lng: 19.830379 },
    { lat: 48.542433, lng: 19.830364 },
    { lat: 48.5424, lng: 19.830352 },
    { lat: 48.541694, lng: 19.830091 },
    { lat: 48.541213, lng: 19.829407 },
    { lat: 48.54097, lng: 19.828842 },
    { lat: 48.540977, lng: 19.828803 },
    { lat: 48.540987, lng: 19.828765 },
    { lat: 48.540013, lng: 19.827629 },
    { lat: 48.539464, lng: 19.826902 },
    { lat: 48.539439, lng: 19.826922 },
    { lat: 48.539412, lng: 19.826944 },
    { lat: 48.539315, lng: 19.82698 },
    { lat: 48.539217, lng: 19.826996 },
    { lat: 48.539173, lng: 19.826904 },
    { lat: 48.539018, lng: 19.826833 },
    { lat: 48.53886, lng: 19.826857 },
    { lat: 48.538819, lng: 19.826747 },
    { lat: 48.538758, lng: 19.82673 },
    { lat: 48.538617, lng: 19.826804 },
    { lat: 48.53857, lng: 19.826944 },
    { lat: 48.538525, lng: 19.826866 },
    { lat: 48.538299, lng: 19.826732 },
    { lat: 48.538157, lng: 19.826472 },
    { lat: 48.53806, lng: 19.826441 },
    { lat: 48.537942, lng: 19.826515 },
    { lat: 48.537825, lng: 19.826464 },
    { lat: 48.53772, lng: 19.826087 },
    { lat: 48.537637, lng: 19.826021 },
    { lat: 48.537571, lng: 19.825807 },
    { lat: 48.537506, lng: 19.825753 },
    { lat: 48.537407, lng: 19.825816 },
    { lat: 48.537228, lng: 19.825811 },
    { lat: 48.537179, lng: 19.825655 },
    { lat: 48.536879, lng: 19.825582 },
    { lat: 48.536806, lng: 19.825312 },
    { lat: 48.536721, lng: 19.825375 },
    { lat: 48.536633, lng: 19.825315 },
    { lat: 48.536632, lng: 19.825022 },
    { lat: 48.536655, lng: 19.824992 },
    { lat: 48.536109, lng: 19.824753 },
    { lat: 48.535891, lng: 19.824683 },
    { lat: 48.535507, lng: 19.824563 },
    { lat: 48.535131, lng: 19.824558 },
    { lat: 48.534733, lng: 19.824662 },
    { lat: 48.534624, lng: 19.824675 },
    { lat: 48.534456, lng: 19.824691 },
    { lat: 48.533748, lng: 19.824612 },
    { lat: 48.532742, lng: 19.824442 },
    { lat: 48.53225, lng: 19.824432 },
    { lat: 48.531902, lng: 19.824566 },
    { lat: 48.531719, lng: 19.824636 },
    { lat: 48.531465, lng: 19.824634 },
    { lat: 48.53106, lng: 19.82463 },
    { lat: 48.530531, lng: 19.824595 },
    { lat: 48.530209, lng: 19.824621 },
    { lat: 48.529809, lng: 19.824696 },
    { lat: 48.529671, lng: 19.824638 },
    { lat: 48.529388, lng: 19.824631 },
    { lat: 48.528785, lng: 19.824618 },
    { lat: 48.527671, lng: 19.824559 },
    { lat: 48.526887, lng: 19.824473 },
    { lat: 48.526665, lng: 19.824382 },
    { lat: 48.526163, lng: 19.824129 },
    { lat: 48.525469, lng: 19.823757 },
    { lat: 48.525456, lng: 19.823751 },
    { lat: 48.525193, lng: 19.824674 },
    { lat: 48.525172, lng: 19.824665 },
    { lat: 48.524731, lng: 19.825773 },
    { lat: 48.524064, lng: 19.826951 },
    { lat: 48.52343, lng: 19.827904 },
    { lat: 48.522956, lng: 19.82882 },
    { lat: 48.522551, lng: 19.82979 },
    { lat: 48.522178, lng: 19.830716 },
    { lat: 48.521922, lng: 19.831402 },
    { lat: 48.52151, lng: 19.832414 },
    { lat: 48.521151, lng: 19.833345 },
    { lat: 48.521142, lng: 19.833363 },
    { lat: 48.521252, lng: 19.833509 },
    { lat: 48.521654, lng: 19.833711 },
    { lat: 48.521835, lng: 19.833848 },
    { lat: 48.522168, lng: 19.834098 },
    { lat: 48.522318, lng: 19.83426 },
    { lat: 48.522382, lng: 19.834293 },
    { lat: 48.523407, lng: 19.834645 },
    { lat: 48.523634, lng: 19.834819 },
    { lat: 48.524279, lng: 19.835027 },
    { lat: 48.524432, lng: 19.835065 },
    { lat: 48.524749, lng: 19.835857 },
    { lat: 48.524778, lng: 19.837243 },
    { lat: 48.524747, lng: 19.838258 },
    { lat: 48.52472, lng: 19.838471 },
    { lat: 48.524739, lng: 19.838945 },
    { lat: 48.524769, lng: 19.840645 },
    { lat: 48.523705, lng: 19.840807 },
    { lat: 48.522835, lng: 19.841107 },
    { lat: 48.522448, lng: 19.841167 },
    { lat: 48.521456, lng: 19.842019 },
    { lat: 48.521155, lng: 19.842442 },
    { lat: 48.520892, lng: 19.84299 },
    { lat: 48.520632, lng: 19.843369 },
    { lat: 48.520487, lng: 19.843551 },
    { lat: 48.519878, lng: 19.844049 },
    { lat: 48.519423, lng: 19.844348 },
    { lat: 48.51901, lng: 19.844546 },
    { lat: 48.518709, lng: 19.844623 },
    { lat: 48.518489, lng: 19.844864 },
    { lat: 48.51837, lng: 19.845039 },
    { lat: 48.517765, lng: 19.845545 },
    { lat: 48.517476, lng: 19.845752 },
    { lat: 48.517346, lng: 19.845906 },
    { lat: 48.517157, lng: 19.846073 },
    { lat: 48.517037, lng: 19.846247 },
    { lat: 48.516908, lng: 19.846525 },
    { lat: 48.516831, lng: 19.846662 },
    { lat: 48.516459, lng: 19.847135 },
    { lat: 48.516328, lng: 19.847357 },
    { lat: 48.5162, lng: 19.84758 },
    { lat: 48.515961, lng: 19.847861 },
    { lat: 48.515858, lng: 19.848108 },
    { lat: 48.515721, lng: 19.848328 },
    { lat: 48.515686, lng: 19.848441 },
    { lat: 48.515634, lng: 19.84884 },
    { lat: 48.515537, lng: 19.849266 },
    { lat: 48.515492, lng: 19.849401 },
    { lat: 48.515365, lng: 19.849571 },
    { lat: 48.51521, lng: 19.849836 },
    { lat: 48.515072, lng: 19.850003 },
    { lat: 48.514832, lng: 19.850154 },
    { lat: 48.514651, lng: 19.850238 },
    { lat: 48.514496, lng: 19.850253 },
    { lat: 48.514378, lng: 19.850282 },
    { lat: 48.514073, lng: 19.850493 },
    { lat: 48.513797, lng: 19.850649 },
    { lat: 48.51357, lng: 19.850808 },
    { lat: 48.513373, lng: 19.850871 },
    { lat: 48.51325, lng: 19.850955 },
    { lat: 48.513109, lng: 19.851134 },
    { lat: 48.512862, lng: 19.851573 },
    { lat: 48.512731, lng: 19.851787 },
    { lat: 48.51264, lng: 19.851999 },
    { lat: 48.512513, lng: 19.852417 },
    { lat: 48.512459, lng: 19.852548 },
    { lat: 48.512071, lng: 19.853159 },
    { lat: 48.511871, lng: 19.85337 },
    { lat: 48.511744, lng: 19.853531 },
    { lat: 48.511592, lng: 19.85377 },
    { lat: 48.511528, lng: 19.853847 },
    { lat: 48.511411, lng: 19.853936 },
    { lat: 48.51122, lng: 19.854 },
    { lat: 48.511018, lng: 19.854155 },
    { lat: 48.510922, lng: 19.854266 },
    { lat: 48.510665, lng: 19.854715 },
    { lat: 48.510514, lng: 19.85489 },
    { lat: 48.510332, lng: 19.855149 },
    { lat: 48.50958, lng: 19.855918 },
    { lat: 48.509132, lng: 19.856475 },
    { lat: 48.508671, lng: 19.857064 },
    { lat: 48.508174, lng: 19.857672 },
    { lat: 48.507068, lng: 19.858663 },
    { lat: 48.506339, lng: 19.858956 },
    { lat: 48.50568, lng: 19.859013 },
    { lat: 48.505383, lng: 19.859089 },
    { lat: 48.505141, lng: 19.859222 },
    { lat: 48.504813, lng: 19.859369 },
    { lat: 48.504589, lng: 19.859554 },
    { lat: 48.504461, lng: 19.859651 },
    { lat: 48.504378, lng: 19.85979 },
    { lat: 48.504305, lng: 19.859949 },
    { lat: 48.504071, lng: 19.860349 },
    { lat: 48.504018, lng: 19.860433 },
    { lat: 48.503845, lng: 19.860886 },
    { lat: 48.50361, lng: 19.861453 },
    { lat: 48.5035, lng: 19.86192 },
    { lat: 48.503427, lng: 19.862122 },
    { lat: 48.503348, lng: 19.862351 },
    { lat: 48.503272, lng: 19.862658 },
    { lat: 48.503135, lng: 19.863093 },
    { lat: 48.502906, lng: 19.863702 },
    { lat: 48.50287, lng: 19.863773 },
    { lat: 48.502736, lng: 19.863984 },
    { lat: 48.50231, lng: 19.86446 },
    { lat: 48.502028, lng: 19.864683 },
    { lat: 48.501762, lng: 19.865003 },
    { lat: 48.501425, lng: 19.865252 },
    { lat: 48.501306, lng: 19.865377 },
    { lat: 48.500974, lng: 19.865786 },
    { lat: 48.500516, lng: 19.866174 },
    { lat: 48.500426, lng: 19.866298 },
    { lat: 48.50021, lng: 19.866644 },
    { lat: 48.500031, lng: 19.866857 },
    { lat: 48.49979, lng: 19.867318 },
    { lat: 48.499673, lng: 19.867572 },
    { lat: 48.499542, lng: 19.867811 },
    { lat: 48.499471, lng: 19.867912 },
    { lat: 48.49933, lng: 19.868071 },
    { lat: 48.499199, lng: 19.868324 },
    { lat: 48.499117, lng: 19.868571 },
    { lat: 48.499082, lng: 19.868759 },
    { lat: 48.498842, lng: 19.869388 },
    { lat: 48.49873, lng: 19.869847 },
    { lat: 48.498403, lng: 19.870511 },
    { lat: 48.498303, lng: 19.870665 },
    { lat: 48.498202, lng: 19.870838 },
    { lat: 48.498129, lng: 19.871001 },
    { lat: 48.498059, lng: 19.871396 },
    { lat: 48.498046, lng: 19.871601 },
    { lat: 48.498025, lng: 19.871751 },
    { lat: 48.497955, lng: 19.871939 },
    { lat: 48.497918, lng: 19.872082 },
    { lat: 48.497864, lng: 19.872388 },
    { lat: 48.497865, lng: 19.872706 },
    { lat: 48.497817, lng: 19.873171 },
    { lat: 48.497682, lng: 19.874139 },
    { lat: 48.497661, lng: 19.874415 },
    { lat: 48.497596, lng: 19.874777 },
    { lat: 48.497411, lng: 19.875507 },
    { lat: 48.497303, lng: 19.875808 },
    { lat: 48.496677, lng: 19.876604 },
    { lat: 48.496281, lng: 19.877068 },
    { lat: 48.496123, lng: 19.877488 },
    { lat: 48.495682, lng: 19.87802 },
    { lat: 48.495577, lng: 19.87821 },
    { lat: 48.495464, lng: 19.87847 },
    { lat: 48.495219, lng: 19.878857 },
    { lat: 48.495094, lng: 19.879129 },
    { lat: 48.494887, lng: 19.879697 },
    { lat: 48.494732, lng: 19.879966 },
    { lat: 48.494449, lng: 19.880367 },
    { lat: 48.493935, lng: 19.880591 },
    { lat: 48.49356, lng: 19.88081 },
    { lat: 48.493459, lng: 19.880869 },
    { lat: 48.493385, lng: 19.880969 },
    { lat: 48.493197, lng: 19.881355 },
    { lat: 48.493157, lng: 19.881461 },
    { lat: 48.493087, lng: 19.881768 },
    { lat: 48.493053, lng: 19.881873 },
    { lat: 48.492999, lng: 19.881996 },
    { lat: 48.492625, lng: 19.882471 },
    { lat: 48.492331, lng: 19.882848 },
    { lat: 48.4938451, lng: 19.8857224 },
    { lat: 48.4960706, lng: 19.8893217 },
    { lat: 48.4956873, lng: 19.8903126 },
    { lat: 48.4955552, lng: 19.8905194 },
    { lat: 48.4953281, lng: 19.8907105 },
    { lat: 48.4951767, lng: 19.8910176 },
    { lat: 48.4950418, lng: 19.8914715 },
    { lat: 48.4947873, lng: 19.8918119 },
    { lat: 48.4946297, lng: 19.8921814 },
    { lat: 48.4949124, lng: 19.894015 },
    { lat: 48.4951184, lng: 19.8964294 },
    { lat: 48.4954335, lng: 19.8989981 },
    { lat: 48.4980329, lng: 19.9169588 },
    { lat: 48.5005193, lng: 19.9169646 },
    { lat: 48.5018744, lng: 19.9171142 },
    { lat: 48.5027056, lng: 19.91701 },
    { lat: 48.5038919, lng: 19.917139 },
    { lat: 48.5070194, lng: 19.9171978 },
    { lat: 48.5101684, lng: 19.9169956 },
    { lat: 48.5102004, lng: 19.9171634 },
    { lat: 48.5102673, lng: 19.9171772 },
    { lat: 48.5106579, lng: 19.9170883 },
    { lat: 48.5114602, lng: 19.916881 },
    { lat: 48.512627, lng: 19.9166903 },
    { lat: 48.5135278, lng: 19.9171269 },
    { lat: 48.5140376, lng: 19.9176177 },
    { lat: 48.5142546, lng: 19.9177129 },
    { lat: 48.5143857, lng: 19.9176423 },
    { lat: 48.5147678, lng: 19.9177016 },
    { lat: 48.5152729, lng: 19.9180539 },
    { lat: 48.5161743, lng: 19.9183203 },
    { lat: 48.5167166, lng: 19.9182295 },
    { lat: 48.516996, lng: 19.9180717 },
    { lat: 48.5174262, lng: 19.9176702 },
    { lat: 48.5187409, lng: 19.9173017 },
    { lat: 48.5198092, lng: 19.9166778 },
    { lat: 48.5210358, lng: 19.915779 },
    { lat: 48.5221747, lng: 19.914299 },
    { lat: 48.5230283, lng: 19.9118635 },
    { lat: 48.5238727, lng: 19.9104562 },
    { lat: 48.5241414, lng: 19.9098107 },
    { lat: 48.5242625, lng: 19.9092755 },
    { lat: 48.5244424, lng: 19.9061858 },
    { lat: 48.524609, lng: 19.9058129 },
    { lat: 48.5255183, lng: 19.9025258 },
    { lat: 48.5260566, lng: 19.8997882 },
    { lat: 48.5260867, lng: 19.8987277 },
    { lat: 48.526589, lng: 19.8977236 },
    { lat: 48.5273625, lng: 19.8969014 },
    { lat: 48.5282602, lng: 19.896184 },
    { lat: 48.5285931, lng: 19.8959857 },
    { lat: 48.5289487, lng: 19.8958726 },
    { lat: 48.5294231, lng: 19.8955617 },
    { lat: 48.5299671, lng: 19.8953784 },
    { lat: 48.5308091, lng: 19.8945171 },
    { lat: 48.5312403, lng: 19.8943565 },
    { lat: 48.5323193, lng: 19.8936385 },
    { lat: 48.5331488, lng: 19.8927026 },
    { lat: 48.5337988, lng: 19.8923352 },
    { lat: 48.5359483, lng: 19.8917607 },
    { lat: 48.5362753, lng: 19.8914253 },
    { lat: 48.5373351, lng: 19.8898936 },
    { lat: 48.5383101, lng: 19.8899306 },
    { lat: 48.5394633, lng: 19.890373 },
    { lat: 48.5411606, lng: 19.8907286 },
    { lat: 48.5417227, lng: 19.8905154 },
    { lat: 48.5422561, lng: 19.8904795 },
    { lat: 48.5428646, lng: 19.8910044 },
    { lat: 48.5438086, lng: 19.8914266 },
    { lat: 48.5448462, lng: 19.8905613 },
    { lat: 48.5458252, lng: 19.8904838 },
    { lat: 48.5463798, lng: 19.8898866 },
    { lat: 48.548949, lng: 19.8890829 },
    { lat: 48.5495218, lng: 19.8886802 },
    { lat: 48.5506493, lng: 19.8885704 },
    { lat: 48.5517715, lng: 19.8894376 },
    { lat: 48.5528289, lng: 19.8897973 },
    { lat: 48.5541272, lng: 19.8898867 },
    { lat: 48.5543499, lng: 19.889566 },
    { lat: 48.5546507, lng: 19.8894343 },
    { lat: 48.555441, lng: 19.8887624 },
    { lat: 48.5560632, lng: 19.8883534 },
    { lat: 48.5566106, lng: 19.8881642 },
    { lat: 48.557782, lng: 19.8880544 },
    { lat: 48.5586563, lng: 19.8878726 },
    { lat: 48.5595683, lng: 19.8881476 },
    { lat: 48.5601765, lng: 19.8884913 },
    { lat: 48.560859, lng: 19.8879305 },
    { lat: 48.5615647, lng: 19.8868926 },
    { lat: 48.5620863, lng: 19.886632 },
    { lat: 48.562832, lng: 19.8865262 },
    { lat: 48.5631428, lng: 19.8866788 },
    { lat: 48.5634042, lng: 19.887126 },
    { lat: 48.5638094, lng: 19.8874304 },
    { lat: 48.5652768, lng: 19.8890214 },
    { lat: 48.5657175, lng: 19.8893726 },
    { lat: 48.5658555, lng: 19.8893033 },
    { lat: 48.5662734, lng: 19.8887487 },
    { lat: 48.5664613, lng: 19.8876434 },
    { lat: 48.5672849, lng: 19.8867291 },
    { lat: 48.5676512, lng: 19.8852866 },
    { lat: 48.5689728, lng: 19.883374 },
    { lat: 48.5695173, lng: 19.882098 },
    { lat: 48.5699042, lng: 19.8830541 },
    { lat: 48.570287, lng: 19.8835717 },
  ],
  Cakov: [
    { lat: 48.3220866, lng: 20.2071209 },
    { lat: 48.3221155, lng: 20.2071423 },
    { lat: 48.3238677, lng: 20.2090624 },
    { lat: 48.3192546, lng: 20.2151871 },
    { lat: 48.3226367, lng: 20.2196102 },
    { lat: 48.3287241, lng: 20.2151497 },
    { lat: 48.3289298, lng: 20.215896 },
    { lat: 48.328366, lng: 20.2179212 },
    { lat: 48.3328008, lng: 20.2276417 },
    { lat: 48.335566, lng: 20.2339348 },
    { lat: 48.3355859, lng: 20.234007 },
    { lat: 48.3354525, lng: 20.234083 },
    { lat: 48.3349476, lng: 20.2340754 },
    { lat: 48.3357502, lng: 20.2357518 },
    { lat: 48.3360649, lng: 20.236213 },
    { lat: 48.3371035, lng: 20.2367778 },
    { lat: 48.33789, lng: 20.2370093 },
    { lat: 48.3387684, lng: 20.2377982 },
    { lat: 48.3393098, lng: 20.2384051 },
    { lat: 48.3424937, lng: 20.2407374 },
    { lat: 48.3431734, lng: 20.241517 },
    { lat: 48.3436815, lng: 20.2417471 },
    { lat: 48.3440531, lng: 20.2416014 },
    { lat: 48.3450442, lng: 20.2417154 },
    { lat: 48.3454579, lng: 20.2409595 },
    { lat: 48.3455944, lng: 20.2402073 },
    { lat: 48.3462169, lng: 20.2390193 },
    { lat: 48.3465153, lng: 20.2380843 },
    { lat: 48.3470015, lng: 20.2371773 },
    { lat: 48.3478945, lng: 20.2358772 },
    { lat: 48.3479882, lng: 20.2358014 },
    { lat: 48.346217, lng: 20.2283659 },
    { lat: 48.345595, lng: 20.227658 },
    { lat: 48.3455248, lng: 20.2262436 },
    { lat: 48.3455079, lng: 20.224364 },
    { lat: 48.3446873, lng: 20.2228889 },
    { lat: 48.344003, lng: 20.2217523 },
    { lat: 48.3433865, lng: 20.2212362 },
    { lat: 48.3414099, lng: 20.2191329 },
    { lat: 48.3413391, lng: 20.218998 },
    { lat: 48.340511, lng: 20.2180969 },
    { lat: 48.3402521, lng: 20.2175086 },
    { lat: 48.339299, lng: 20.2187393 },
    { lat: 48.3388927, lng: 20.2194486 },
    { lat: 48.338597, lng: 20.2203285 },
    { lat: 48.3384505, lng: 20.2203545 },
    { lat: 48.3371104, lng: 20.2160748 },
    { lat: 48.336636, lng: 20.2152239 },
    { lat: 48.334548, lng: 20.212152 },
    { lat: 48.3342644, lng: 20.2115985 },
    { lat: 48.3341484, lng: 20.211208 },
    { lat: 48.3338367, lng: 20.2105424 },
    { lat: 48.3329727, lng: 20.2093662 },
    { lat: 48.3327774, lng: 20.2090165 },
    { lat: 48.332737, lng: 20.2088283 },
    { lat: 48.3327501, lng: 20.2085397 },
    { lat: 48.3331562, lng: 20.2077404 },
    { lat: 48.3329907, lng: 20.2070489 },
    { lat: 48.3329193, lng: 20.2063956 },
    { lat: 48.3329075, lng: 20.2056229 },
    { lat: 48.3330031, lng: 20.2042238 },
    { lat: 48.3329116, lng: 20.2036364 },
    { lat: 48.332805, lng: 20.2033905 },
    { lat: 48.3322181, lng: 20.202744 },
    { lat: 48.3321816, lng: 20.2021487 },
    { lat: 48.3318431, lng: 20.2010429 },
    { lat: 48.3319352, lng: 20.1993072 },
    { lat: 48.3319056, lng: 20.1986635 },
    { lat: 48.332706, lng: 20.1949945 },
    { lat: 48.3327794, lng: 20.1932721 },
    { lat: 48.3324527, lng: 20.1915745 },
    { lat: 48.3282754, lng: 20.1918592 },
    { lat: 48.3243582, lng: 20.1908631 },
    { lat: 48.3267837, lng: 20.1994266 },
    { lat: 48.324724, lng: 20.2014444 },
    { lat: 48.3256346, lng: 20.2036332 },
    { lat: 48.3234341, lng: 20.2057661 },
    { lat: 48.3220866, lng: 20.2071209 },
  ],
  Krokava: [
    { lat: 48.6458078, lng: 19.9836365 },
    { lat: 48.6453435, lng: 19.9837938 },
    { lat: 48.6449798, lng: 19.9835248 },
    { lat: 48.644826, lng: 19.9841286 },
    { lat: 48.6443152, lng: 19.9842747 },
    { lat: 48.6439217, lng: 19.9845631 },
    { lat: 48.6436494, lng: 19.9844122 },
    { lat: 48.6436643, lng: 19.9844706 },
    { lat: 48.6434537, lng: 19.9844672 },
    { lat: 48.6431429, lng: 19.9846049 },
    { lat: 48.6428904, lng: 19.9850052 },
    { lat: 48.6425837, lng: 19.985297 },
    { lat: 48.6423058, lng: 19.9853556 },
    { lat: 48.6422087, lng: 19.9854329 },
    { lat: 48.6420821, lng: 19.9853628 },
    { lat: 48.6416656, lng: 19.98545 },
    { lat: 48.6411554, lng: 19.9852135 },
    { lat: 48.640886, lng: 19.9853172 },
    { lat: 48.6404585, lng: 19.9856263 },
    { lat: 48.6398991, lng: 19.9858522 },
    { lat: 48.6390112, lng: 19.9864884 },
    { lat: 48.6388658, lng: 19.9865123 },
    { lat: 48.6383178, lng: 19.987173 },
    { lat: 48.6381131, lng: 19.9873667 },
    { lat: 48.638027, lng: 19.9873304 },
    { lat: 48.6379526, lng: 19.9874853 },
    { lat: 48.6377138, lng: 19.9876745 },
    { lat: 48.6375567, lng: 19.9876877 },
    { lat: 48.6368748, lng: 19.9880538 },
    { lat: 48.6365714, lng: 19.9880454 },
    { lat: 48.6362888, lng: 19.9882085 },
    { lat: 48.6357118, lng: 19.9882049 },
    { lat: 48.6352224, lng: 19.9884838 },
    { lat: 48.6344023, lng: 19.9884603 },
    { lat: 48.6342317, lng: 19.988607 },
    { lat: 48.6338063, lng: 19.988753 },
    { lat: 48.6337046, lng: 19.9888774 },
    { lat: 48.633544, lng: 19.9889159 },
    { lat: 48.6328549, lng: 19.9894073 },
    { lat: 48.6327476, lng: 19.9895842 },
    { lat: 48.6323654, lng: 19.9899825 },
    { lat: 48.6322104, lng: 19.9900465 },
    { lat: 48.6321408, lng: 19.990161 },
    { lat: 48.6316995, lng: 19.9900828 },
    { lat: 48.6313457, lng: 19.990107 },
    { lat: 48.6308808, lng: 19.9899587 },
    { lat: 48.6305478, lng: 19.9899941 },
    { lat: 48.6304981, lng: 19.9899109 },
    { lat: 48.6304023, lng: 19.9899631 },
    { lat: 48.630397, lng: 19.9898657 },
    { lat: 48.630324, lng: 19.9897901 },
    { lat: 48.6301954, lng: 19.9899156 },
    { lat: 48.6300948, lng: 19.9898789 },
    { lat: 48.6294298, lng: 19.9900859 },
    { lat: 48.6290116, lng: 19.9900543 },
    { lat: 48.6288597, lng: 19.9898936 },
    { lat: 48.6288072, lng: 19.9900151 },
    { lat: 48.6286965, lng: 19.9900598 },
    { lat: 48.6285325, lng: 19.9900164 },
    { lat: 48.6283737, lng: 19.9902039 },
    { lat: 48.6282962, lng: 19.9901864 },
    { lat: 48.6281552, lng: 19.9904195 },
    { lat: 48.6278892, lng: 19.9906618 },
    { lat: 48.6276198, lng: 19.9913025 },
    { lat: 48.6273981, lng: 19.9914924 },
    { lat: 48.6271947, lng: 19.9915339 },
    { lat: 48.6268824, lng: 19.9917696 },
    { lat: 48.6265143, lng: 19.9918001 },
    { lat: 48.6261514, lng: 19.9919836 },
    { lat: 48.6257246, lng: 19.992417 },
    { lat: 48.6255994, lng: 19.9924126 },
    { lat: 48.6256162, lng: 19.9924641 },
    { lat: 48.625603, lng: 19.9960392 },
    { lat: 48.6257582, lng: 19.9996142 },
    { lat: 48.6250233, lng: 20.0040941 },
    { lat: 48.6245651, lng: 20.006441 },
    { lat: 48.624221, lng: 20.006916 },
    { lat: 48.6239199, lng: 20.0069628 },
    { lat: 48.6237139, lng: 20.007315 },
    { lat: 48.6234384, lng: 20.0075103 },
    { lat: 48.6232356, lng: 20.0075396 },
    { lat: 48.6229915, lng: 20.0074631 },
    { lat: 48.6228618, lng: 20.0073266 },
    { lat: 48.6215212, lng: 20.0076739 },
    { lat: 48.6211248, lng: 20.0074898 },
    { lat: 48.6208201, lng: 20.0075822 },
    { lat: 48.6205654, lng: 20.0077666 },
    { lat: 48.6204081, lng: 20.0077257 },
    { lat: 48.6202939, lng: 20.0075806 },
    { lat: 48.6201184, lng: 20.0086893 },
    { lat: 48.6201631, lng: 20.0088673 },
    { lat: 48.6201578, lng: 20.0094179 },
    { lat: 48.6203056, lng: 20.0101742 },
    { lat: 48.6200214, lng: 20.0105463 },
    { lat: 48.6194935, lng: 20.0105851 },
    { lat: 48.6192262, lng: 20.0105186 },
    { lat: 48.6192052, lng: 20.0106655 },
    { lat: 48.6189128, lng: 20.0107307 },
    { lat: 48.6186796, lng: 20.0108885 },
    { lat: 48.61793, lng: 20.0129239 },
    { lat: 48.6171879, lng: 20.0136576 },
    { lat: 48.61702, lng: 20.0140065 },
    { lat: 48.6179161, lng: 20.0141848 },
    { lat: 48.6189033, lng: 20.0148425 },
    { lat: 48.6190876, lng: 20.0150735 },
    { lat: 48.6190179, lng: 20.0153482 },
    { lat: 48.6187705, lng: 20.0156515 },
    { lat: 48.6184829, lng: 20.0162652 },
    { lat: 48.6184925, lng: 20.0170614 },
    { lat: 48.6186094, lng: 20.0171938 },
    { lat: 48.6187481, lng: 20.0181703 },
    { lat: 48.6190021, lng: 20.0189893 },
    { lat: 48.6191285, lng: 20.0198082 },
    { lat: 48.618849, lng: 20.0206108 },
    { lat: 48.6184787, lng: 20.0204316 },
    { lat: 48.6179251, lng: 20.0205254 },
    { lat: 48.617787, lng: 20.020698 },
    { lat: 48.617821, lng: 20.020719 },
    { lat: 48.617935, lng: 20.021004 },
    { lat: 48.618123, lng: 20.021502 },
    { lat: 48.618181, lng: 20.021616 },
    { lat: 48.618292, lng: 20.021833 },
    { lat: 48.618416, lng: 20.022069 },
    { lat: 48.61818, lng: 20.022365 },
    { lat: 48.617969, lng: 20.022662 },
    { lat: 48.617848, lng: 20.022897 },
    { lat: 48.617797, lng: 20.02308 },
    { lat: 48.617772, lng: 20.023135 },
    { lat: 48.617772, lng: 20.023171 },
    { lat: 48.617751, lng: 20.023265 },
    { lat: 48.61772, lng: 20.023451 },
    { lat: 48.617638, lng: 20.023942 },
    { lat: 48.617626, lng: 20.024049 },
    { lat: 48.617608, lng: 20.024305 },
    { lat: 48.617795, lng: 20.024404 },
    { lat: 48.617509, lng: 20.024949 },
    { lat: 48.617613, lng: 20.02498 },
    { lat: 48.617742, lng: 20.025019 },
    { lat: 48.618337, lng: 20.025192 },
    { lat: 48.618468, lng: 20.02564 },
    { lat: 48.618557, lng: 20.025934 },
    { lat: 48.618574, lng: 20.026378 },
    { lat: 48.618588, lng: 20.026736 },
    { lat: 48.61861, lng: 20.027227 },
    { lat: 48.61886, lng: 20.027294 },
    { lat: 48.619051, lng: 20.027343 },
    { lat: 48.618996, lng: 20.027561 },
    { lat: 48.618939, lng: 20.027793 },
    { lat: 48.618932, lng: 20.027817 },
    { lat: 48.61892, lng: 20.027864 },
    { lat: 48.61891, lng: 20.028019 },
    { lat: 48.618728, lng: 20.028411 },
    { lat: 48.618725, lng: 20.028761 },
    { lat: 48.618725, lng: 20.029064 },
    { lat: 48.618746, lng: 20.029375 },
    { lat: 48.618708, lng: 20.029376 },
    { lat: 48.618641, lng: 20.029369 },
    { lat: 48.618613, lng: 20.029768 },
    { lat: 48.618629, lng: 20.030209 },
    { lat: 48.6186, lng: 20.030436 },
    { lat: 48.618631, lng: 20.031058 },
    { lat: 48.618742, lng: 20.031409 },
    { lat: 48.618797, lng: 20.031576 },
    { lat: 48.618812, lng: 20.031945 },
    { lat: 48.61882, lng: 20.03219 },
    { lat: 48.618743, lng: 20.03235 },
    { lat: 48.618739, lng: 20.032715 },
    { lat: 48.618696, lng: 20.033031 },
    { lat: 48.618658, lng: 20.033115 },
    { lat: 48.618531, lng: 20.033288 },
    { lat: 48.618535, lng: 20.033343 },
    { lat: 48.618707, lng: 20.033309 },
    { lat: 48.618952, lng: 20.033301 },
    { lat: 48.619134, lng: 20.033292 },
    { lat: 48.619366, lng: 20.033513 },
    { lat: 48.619537, lng: 20.033672 },
    { lat: 48.619781, lng: 20.033806 },
    { lat: 48.61989, lng: 20.033864 },
    { lat: 48.620183, lng: 20.033965 },
    { lat: 48.620334, lng: 20.034022 },
    { lat: 48.620599, lng: 20.03404 },
    { lat: 48.620802, lng: 20.034085 },
    { lat: 48.621144, lng: 20.034176 },
    { lat: 48.621207, lng: 20.034172 },
    { lat: 48.621295, lng: 20.034152 },
    { lat: 48.621339, lng: 20.034166 },
    { lat: 48.621502, lng: 20.034259 },
    { lat: 48.621695, lng: 20.034369 },
    { lat: 48.621747, lng: 20.03437 },
    { lat: 48.621913, lng: 20.034346 },
    { lat: 48.622123, lng: 20.034314 },
    { lat: 48.622458, lng: 20.034258 },
    { lat: 48.62257, lng: 20.034237 },
    { lat: 48.622582, lng: 20.034273 },
    { lat: 48.6226, lng: 20.034323 },
    { lat: 48.622649, lng: 20.034463 },
    { lat: 48.622658, lng: 20.0345 },
    { lat: 48.622715, lng: 20.034775 },
    { lat: 48.622725, lng: 20.034821 },
    { lat: 48.622738, lng: 20.034876 },
    { lat: 48.622775, lng: 20.034965 },
    { lat: 48.622805, lng: 20.035037 },
    { lat: 48.62282, lng: 20.035071 },
    { lat: 48.622829, lng: 20.035122 },
    { lat: 48.622859, lng: 20.035286 },
    { lat: 48.622946, lng: 20.035744 },
    { lat: 48.622951, lng: 20.035765 },
    { lat: 48.622956, lng: 20.035789 },
    { lat: 48.622885, lng: 20.035872 },
    { lat: 48.622846, lng: 20.035937 },
    { lat: 48.622814, lng: 20.035987 },
    { lat: 48.622822, lng: 20.036005 },
    { lat: 48.622838, lng: 20.036033 },
    { lat: 48.622843, lng: 20.036039 },
    { lat: 48.622859, lng: 20.036056 },
    { lat: 48.622967, lng: 20.036188 },
    { lat: 48.623037, lng: 20.036368 },
    { lat: 48.623267, lng: 20.036887 },
    { lat: 48.623282, lng: 20.036914 },
    { lat: 48.62329, lng: 20.03694 },
    { lat: 48.623416, lng: 20.036735 },
    { lat: 48.623536, lng: 20.036558 },
    { lat: 48.623718, lng: 20.036383 },
    { lat: 48.623899, lng: 20.036226 },
    { lat: 48.624008, lng: 20.036209 },
    { lat: 48.624052, lng: 20.036202 },
    { lat: 48.624123, lng: 20.03622 },
    { lat: 48.624158, lng: 20.036228 },
    { lat: 48.624211, lng: 20.036251 },
    { lat: 48.624314, lng: 20.036308 },
    { lat: 48.624323, lng: 20.036312 },
    { lat: 48.624447, lng: 20.036407 },
    { lat: 48.624632, lng: 20.036557 },
    { lat: 48.624742, lng: 20.036656 },
    { lat: 48.624848, lng: 20.036755 },
    { lat: 48.624917, lng: 20.036807 },
    { lat: 48.625082, lng: 20.036781 },
    { lat: 48.625235, lng: 20.036715 },
    { lat: 48.625315, lng: 20.036641 },
    { lat: 48.625379, lng: 20.036564 },
    { lat: 48.625435, lng: 20.036282 },
    { lat: 48.62542, lng: 20.036056 },
    { lat: 48.62544, lng: 20.035706 },
    { lat: 48.625659, lng: 20.03532 },
    { lat: 48.625757, lng: 20.03512 },
    { lat: 48.625953, lng: 20.03474 },
    { lat: 48.626173, lng: 20.03447 },
    { lat: 48.626294, lng: 20.034424 },
    { lat: 48.626525, lng: 20.034419 },
    { lat: 48.626649, lng: 20.034406 },
    { lat: 48.626757, lng: 20.034363 },
    { lat: 48.626912, lng: 20.034299 },
    { lat: 48.627092, lng: 20.034179 },
    { lat: 48.627334, lng: 20.034026 },
    { lat: 48.62755, lng: 20.033892 },
    { lat: 48.627597, lng: 20.033852 },
    { lat: 48.627626, lng: 20.033823 },
    { lat: 48.627731, lng: 20.033795 },
    { lat: 48.62778, lng: 20.033774 },
    { lat: 48.627859, lng: 20.03371 },
    { lat: 48.627992, lng: 20.033612 },
    { lat: 48.628001, lng: 20.033628 },
    { lat: 48.628218, lng: 20.033501 },
    { lat: 48.628307, lng: 20.033421 },
    { lat: 48.628317, lng: 20.033413 },
    { lat: 48.628488, lng: 20.033262 },
    { lat: 48.628581, lng: 20.033223 },
    { lat: 48.628639, lng: 20.033129 },
    { lat: 48.628663, lng: 20.033092 },
    { lat: 48.628765, lng: 20.032934 },
    { lat: 48.62913, lng: 20.032533 },
    { lat: 48.629147, lng: 20.032514 },
    { lat: 48.629232, lng: 20.032373 },
    { lat: 48.629242, lng: 20.032355 },
    { lat: 48.629298, lng: 20.032284 },
    { lat: 48.629321, lng: 20.032258 },
    { lat: 48.629347, lng: 20.032191 },
    { lat: 48.629361, lng: 20.032147 },
    { lat: 48.629402, lng: 20.032111 },
    { lat: 48.629465, lng: 20.032059 },
    { lat: 48.629548, lng: 20.031986 },
    { lat: 48.629616, lng: 20.031906 },
    { lat: 48.62971, lng: 20.031791 },
    { lat: 48.629752, lng: 20.031722 },
    { lat: 48.629823, lng: 20.031603 },
    { lat: 48.629856, lng: 20.031535 },
    { lat: 48.629905, lng: 20.031442 },
    { lat: 48.630103, lng: 20.031169 },
    { lat: 48.630223, lng: 20.030997 },
    { lat: 48.6303, lng: 20.03088 },
    { lat: 48.630333, lng: 20.030824 },
    { lat: 48.630488, lng: 20.030581 },
    { lat: 48.630602, lng: 20.030409 },
    { lat: 48.630766, lng: 20.030234 },
    { lat: 48.630909, lng: 20.030078 },
    { lat: 48.631094, lng: 20.030021 },
    { lat: 48.631271, lng: 20.029965 },
    { lat: 48.631348, lng: 20.029854 },
    { lat: 48.631466, lng: 20.029682 },
    { lat: 48.631501, lng: 20.029636 },
    { lat: 48.631649, lng: 20.02951 },
    { lat: 48.631835, lng: 20.029359 },
    { lat: 48.631997, lng: 20.029227 },
    { lat: 48.63213, lng: 20.029135 },
    { lat: 48.632335, lng: 20.02899 },
    { lat: 48.63242, lng: 20.028934 },
    { lat: 48.632502, lng: 20.028898 },
    { lat: 48.632585, lng: 20.028865 },
    { lat: 48.63266, lng: 20.028846 },
    { lat: 48.632874, lng: 20.028796 },
    { lat: 48.632997, lng: 20.028769 },
    { lat: 48.633027, lng: 20.028751 },
    { lat: 48.633246, lng: 20.028629 },
    { lat: 48.633504, lng: 20.028486 },
    { lat: 48.633768, lng: 20.028341 },
    { lat: 48.63381, lng: 20.028318 },
    { lat: 48.633869, lng: 20.028295 },
    { lat: 48.634123, lng: 20.028198 },
    { lat: 48.634775, lng: 20.027955 },
    { lat: 48.634979, lng: 20.027738 },
    { lat: 48.635072, lng: 20.027639 },
    { lat: 48.635267, lng: 20.027436 },
    { lat: 48.635415, lng: 20.027283 },
    { lat: 48.635649, lng: 20.027039 },
    { lat: 48.63576, lng: 20.026775 },
    { lat: 48.635714, lng: 20.025688 },
    { lat: 48.635717, lng: 20.025531 },
    { lat: 48.635732, lng: 20.024676 },
    { lat: 48.635733, lng: 20.024622 },
    { lat: 48.635723, lng: 20.024308 },
    { lat: 48.635717, lng: 20.0243 },
    { lat: 48.635811, lng: 20.023907 },
    { lat: 48.635859, lng: 20.023526 },
    { lat: 48.635894, lng: 20.023214 },
    { lat: 48.63594, lng: 20.022827 },
    { lat: 48.6359, lng: 20.022539 },
    { lat: 48.63591, lng: 20.022537 },
    { lat: 48.636012, lng: 20.022186 },
    { lat: 48.636109, lng: 20.02186 },
    { lat: 48.6364, lng: 20.021661 },
    { lat: 48.636774, lng: 20.021412 },
    { lat: 48.637123, lng: 20.021177 },
    { lat: 48.637489, lng: 20.02093 },
    { lat: 48.637792, lng: 20.020724 },
    { lat: 48.637805, lng: 20.020715 },
    { lat: 48.638084, lng: 20.020524 },
    { lat: 48.638467, lng: 20.020172 },
    { lat: 48.638746, lng: 20.019825 },
    { lat: 48.63908, lng: 20.019404 },
    { lat: 48.639056, lng: 20.018858 },
    { lat: 48.639068, lng: 20.01876 },
    { lat: 48.639122, lng: 20.018307 },
    { lat: 48.639162, lng: 20.018035 },
    { lat: 48.639173, lng: 20.016821 },
    { lat: 48.639188, lng: 20.016042 },
    { lat: 48.639319, lng: 20.01551 },
    { lat: 48.639399, lng: 20.015382 },
    { lat: 48.639617, lng: 20.015038 },
    { lat: 48.639705, lng: 20.014953 },
    { lat: 48.639759, lng: 20.014694 },
    { lat: 48.639809, lng: 20.014463 },
    { lat: 48.639824, lng: 20.014349 },
    { lat: 48.640033, lng: 20.013943 },
    { lat: 48.64007, lng: 20.013847 },
    { lat: 48.640102, lng: 20.013801 },
    { lat: 48.640266, lng: 20.013471 },
    { lat: 48.640299, lng: 20.013395 },
    { lat: 48.640656, lng: 20.012921 },
    { lat: 48.640882, lng: 20.012633 },
    { lat: 48.640933, lng: 20.012575 },
    { lat: 48.641253, lng: 20.012287 },
    { lat: 48.641579, lng: 20.012133 },
    { lat: 48.64185, lng: 20.012009 },
    { lat: 48.642206, lng: 20.011861 },
    { lat: 48.642561, lng: 20.011721 },
    { lat: 48.642685, lng: 20.01167 },
    { lat: 48.642742, lng: 20.011641 },
    { lat: 48.64298, lng: 20.011529 },
    { lat: 48.643183, lng: 20.011442 },
    { lat: 48.643422, lng: 20.011352 },
    { lat: 48.643637, lng: 20.011254 },
    { lat: 48.643863, lng: 20.011155 },
    { lat: 48.644047, lng: 20.011093 },
    { lat: 48.644334, lng: 20.010968 },
    { lat: 48.644446, lng: 20.010907 },
    { lat: 48.644991, lng: 20.010691 },
    { lat: 48.645394, lng: 20.010567 },
    { lat: 48.645757, lng: 20.010404 },
    { lat: 48.646207, lng: 20.010235 },
    { lat: 48.646252, lng: 20.010219 },
    { lat: 48.646301, lng: 20.010196 },
    { lat: 48.646324, lng: 20.010185 },
    { lat: 48.646622, lng: 20.010044 },
    { lat: 48.646959, lng: 20.009905 },
    { lat: 48.64713, lng: 20.009841 },
    { lat: 48.647353, lng: 20.009742 },
    { lat: 48.647613, lng: 20.009601 },
    { lat: 48.647759, lng: 20.009533 },
    { lat: 48.64797, lng: 20.009418 },
    { lat: 48.648097, lng: 20.009515 },
    { lat: 48.648459, lng: 20.009766 },
    { lat: 48.648499, lng: 20.009785 },
    { lat: 48.648552, lng: 20.009809 },
    { lat: 48.648627, lng: 20.009861 },
    { lat: 48.649046, lng: 20.010151 },
    { lat: 48.649342, lng: 20.010358 },
    { lat: 48.649558, lng: 20.010495 },
    { lat: 48.649843, lng: 20.010667 },
    { lat: 48.650148, lng: 20.010876 },
    { lat: 48.65067, lng: 20.011135 },
    { lat: 48.650748, lng: 20.01117 },
    { lat: 48.651113, lng: 20.011384 },
    { lat: 48.6515, lng: 20.011666 },
    { lat: 48.651698, lng: 20.011837 },
    { lat: 48.652163, lng: 20.012184 },
    { lat: 48.652412, lng: 20.012347 },
    { lat: 48.652798, lng: 20.012574 },
    { lat: 48.653174, lng: 20.012788 },
    { lat: 48.653473, lng: 20.013003 },
    { lat: 48.653671, lng: 20.012977 },
    { lat: 48.653717, lng: 20.012966 },
    { lat: 48.654172, lng: 20.012911 },
    { lat: 48.654221, lng: 20.012913 },
    { lat: 48.654443, lng: 20.012868 },
    { lat: 48.654735, lng: 20.012798 },
    { lat: 48.655052, lng: 20.012816 },
    { lat: 48.655226, lng: 20.012846 },
    { lat: 48.655669, lng: 20.012941 },
    { lat: 48.656022, lng: 20.013018 },
    { lat: 48.656466, lng: 20.01313 },
    { lat: 48.65706, lng: 20.013299 },
    { lat: 48.657213, lng: 20.013334 },
    { lat: 48.657556, lng: 20.013032 },
    { lat: 48.657873, lng: 20.012648 },
    { lat: 48.657963, lng: 20.012555 },
    { lat: 48.658339, lng: 20.012139 },
    { lat: 48.658433, lng: 20.011779 },
    { lat: 48.658559, lng: 20.011282 },
    { lat: 48.658657, lng: 20.010947 },
    { lat: 48.658839, lng: 20.010357 },
    { lat: 48.658901, lng: 20.010145 },
    { lat: 48.659071, lng: 20.00952 },
    { lat: 48.659181, lng: 20.00918 },
    { lat: 48.659408, lng: 20.008648 },
    { lat: 48.659525, lng: 20.00838 },
    { lat: 48.659681, lng: 20.008072 },
    { lat: 48.659967, lng: 20.00752 },
    { lat: 48.660344, lng: 20.006727 },
    { lat: 48.66057, lng: 20.006262 },
    { lat: 48.660792, lng: 20.005825 },
    { lat: 48.660951, lng: 20.005532 },
    { lat: 48.661193, lng: 20.005072 },
    { lat: 48.661235, lng: 20.005017 },
    { lat: 48.661483, lng: 20.004148 },
    { lat: 48.66165, lng: 20.003548 },
    { lat: 48.661833, lng: 20.002976 },
    { lat: 48.661985, lng: 20.002468 },
    { lat: 48.662064, lng: 20.002197 },
    { lat: 48.662212, lng: 20.001736 },
    { lat: 48.662241, lng: 20.001662 },
    { lat: 48.662449, lng: 20.000971 },
    { lat: 48.662596, lng: 20.000486 },
    { lat: 48.662695, lng: 20.000153 },
    { lat: 48.662869, lng: 19.99959 },
    { lat: 48.663037, lng: 19.999048 },
    { lat: 48.663241, lng: 19.998404 },
    { lat: 48.6611356, lng: 19.9961856 },
    { lat: 48.6601957, lng: 19.9928961 },
    { lat: 48.6590862, lng: 19.9913122 },
    { lat: 48.6582649, lng: 19.9904205 },
    { lat: 48.6582095, lng: 19.9879748 },
    { lat: 48.6577289, lng: 19.9877382 },
    { lat: 48.6552461, lng: 19.9865551 },
    { lat: 48.6552474, lng: 19.986664 },
    { lat: 48.6550259, lng: 19.9866499 },
    { lat: 48.6547612, lng: 19.9867322 },
    { lat: 48.6544382, lng: 19.9869505 },
    { lat: 48.6540062, lng: 19.9871226 },
    { lat: 48.6535629, lng: 19.9871411 },
    { lat: 48.6529507, lng: 19.9869905 },
    { lat: 48.6526826, lng: 19.9871554 },
    { lat: 48.6522365, lng: 19.9872074 },
    { lat: 48.6516847, lng: 19.9869146 },
    { lat: 48.6507908, lng: 19.987196 },
    { lat: 48.6505073, lng: 19.9873652 },
    { lat: 48.650171, lng: 19.9873229 },
    { lat: 48.6487953, lng: 19.9867544 },
    { lat: 48.6484181, lng: 19.9865408 },
    { lat: 48.6474343, lng: 19.9857532 },
    { lat: 48.6468957, lng: 19.9849954 },
    { lat: 48.6464778, lng: 19.9847586 },
    { lat: 48.6461653, lng: 19.983993 },
    { lat: 48.6458078, lng: 19.9836365 },
  ],
  Figa: [
    { lat: 48.379676, lng: 20.2504892 },
    { lat: 48.3797335, lng: 20.2505754 },
    { lat: 48.3799575, lng: 20.2511629 },
    { lat: 48.3801603, lng: 20.2519853 },
    { lat: 48.3803496, lng: 20.2545861 },
    { lat: 48.3804604, lng: 20.2568559 },
    { lat: 48.3811074, lng: 20.2568715 },
    { lat: 48.3812909, lng: 20.2568388 },
    { lat: 48.382274, lng: 20.2560497 },
    { lat: 48.3832677, lng: 20.2545592 },
    { lat: 48.3847506, lng: 20.2546039 },
    { lat: 48.3862696, lng: 20.2532426 },
    { lat: 48.3866844, lng: 20.2529686 },
    { lat: 48.3873712, lng: 20.2535553 },
    { lat: 48.3874645, lng: 20.2535508 },
    { lat: 48.3875784, lng: 20.253299 },
    { lat: 48.3878267, lng: 20.2536257 },
    { lat: 48.3883139, lng: 20.2537828 },
    { lat: 48.3884508, lng: 20.2538835 },
    { lat: 48.3883911, lng: 20.2543332 },
    { lat: 48.3893429, lng: 20.2547877 },
    { lat: 48.3898103, lng: 20.2551596 },
    { lat: 48.3902277, lng: 20.2556544 },
    { lat: 48.3905095, lng: 20.256313 },
    { lat: 48.3905912, lng: 20.2575703 },
    { lat: 48.3904687, lng: 20.2579179 },
    { lat: 48.3902879, lng: 20.2589735 },
    { lat: 48.3903783, lng: 20.259691 },
    { lat: 48.3904866, lng: 20.2598624 },
    { lat: 48.390618, lng: 20.2598832 },
    { lat: 48.3909382, lng: 20.2601195 },
    { lat: 48.3912127, lng: 20.2607079 },
    { lat: 48.391425, lng: 20.260831 },
    { lat: 48.3916949, lng: 20.2608507 },
    { lat: 48.3918586, lng: 20.2610334 },
    { lat: 48.3920594, lng: 20.2611511 },
    { lat: 48.3921195, lng: 20.261415 },
    { lat: 48.392191, lng: 20.2615163 },
    { lat: 48.3923211, lng: 20.2615876 },
    { lat: 48.3925756, lng: 20.2614726 },
    { lat: 48.3929176, lng: 20.2619182 },
    { lat: 48.3933224, lng: 20.2621558 },
    { lat: 48.3934204, lng: 20.2626616 },
    { lat: 48.3941472, lng: 20.2633828 },
    { lat: 48.3946832, lng: 20.2638361 },
    { lat: 48.3948519, lng: 20.2639131 },
    { lat: 48.3954524, lng: 20.2646567 },
    { lat: 48.3958123, lng: 20.2648714 },
    { lat: 48.3957586, lng: 20.2650222 },
    { lat: 48.3965755, lng: 20.2655405 },
    { lat: 48.3968544, lng: 20.2656392 },
    { lat: 48.3968857, lng: 20.265524 },
    { lat: 48.3976333, lng: 20.2659971 },
    { lat: 48.3978056, lng: 20.2661649 },
    { lat: 48.398384, lng: 20.266947 },
    { lat: 48.3988155, lng: 20.2678049 },
    { lat: 48.39945, lng: 20.2691746 },
    { lat: 48.4000874, lng: 20.2708204 },
    { lat: 48.4004825, lng: 20.2704455 },
    { lat: 48.4036023, lng: 20.2753307 },
    { lat: 48.4047069, lng: 20.2766851 },
    { lat: 48.4056213, lng: 20.2772551 },
    { lat: 48.4063665, lng: 20.2779541 },
    { lat: 48.4077517, lng: 20.279654 },
    { lat: 48.4079339, lng: 20.2794218 },
    { lat: 48.4080685, lng: 20.2790373 },
    { lat: 48.408168, lng: 20.2789631 },
    { lat: 48.4086735, lng: 20.2782186 },
    { lat: 48.4091739, lng: 20.2771785 },
    { lat: 48.4093102, lng: 20.2766941 },
    { lat: 48.4094361, lng: 20.276518 },
    { lat: 48.4097029, lng: 20.2763194 },
    { lat: 48.4098597, lng: 20.2759591 },
    { lat: 48.4098073, lng: 20.2758872 },
    { lat: 48.4099659, lng: 20.2757089 },
    { lat: 48.4100584, lng: 20.2750579 },
    { lat: 48.410198, lng: 20.2748002 },
    { lat: 48.4102402, lng: 20.2744486 },
    { lat: 48.4104776, lng: 20.2738464 },
    { lat: 48.4104947, lng: 20.2735099 },
    { lat: 48.4109586, lng: 20.2728845 },
    { lat: 48.411006, lng: 20.272484 },
    { lat: 48.4109898, lng: 20.2718505 },
    { lat: 48.4112273, lng: 20.2709129 },
    { lat: 48.4112702, lng: 20.2706322 },
    { lat: 48.4112285, lng: 20.2702286 },
    { lat: 48.4113106, lng: 20.2700848 },
    { lat: 48.4113048, lng: 20.2697978 },
    { lat: 48.4113769, lng: 20.2694837 },
    { lat: 48.4114998, lng: 20.2692936 },
    { lat: 48.4117735, lng: 20.2691493 },
    { lat: 48.4118598, lng: 20.2685705 },
    { lat: 48.4118872, lng: 20.2679895 },
    { lat: 48.4119313, lng: 20.2678799 },
    { lat: 48.4119484, lng: 20.2670347 },
    { lat: 48.4118907, lng: 20.2669115 },
    { lat: 48.4119887, lng: 20.2659222 },
    { lat: 48.4119513, lng: 20.2657706 },
    { lat: 48.4119783, lng: 20.2653446 },
    { lat: 48.4121063, lng: 20.264885 },
    { lat: 48.4123451, lng: 20.263636 },
    { lat: 48.4122587, lng: 20.2629954 },
    { lat: 48.4122868, lng: 20.2624863 },
    { lat: 48.4127605, lng: 20.2623842 },
    { lat: 48.4132644, lng: 20.2621727 },
    { lat: 48.4134818, lng: 20.2621738 },
    { lat: 48.4141872, lng: 20.2624409 },
    { lat: 48.4154132, lng: 20.2622019 },
    { lat: 48.4156094, lng: 20.2620903 },
    { lat: 48.4163797, lng: 20.261354 },
    { lat: 48.4166297, lng: 20.2609898 },
    { lat: 48.4169017, lng: 20.2600077 },
    { lat: 48.4173918, lng: 20.2592217 },
    { lat: 48.4176412, lng: 20.2589533 },
    { lat: 48.4182063, lng: 20.263051 },
    { lat: 48.4192071, lng: 20.2634342 },
    { lat: 48.41968, lng: 20.264782 },
    { lat: 48.419702, lng: 20.264719 },
    { lat: 48.4197, lng: 20.264619 },
    { lat: 48.419723, lng: 20.264515 },
    { lat: 48.419808, lng: 20.264485 },
    { lat: 48.419822, lng: 20.264414 },
    { lat: 48.419783, lng: 20.264354 },
    { lat: 48.419722, lng: 20.264356 },
    { lat: 48.419697, lng: 20.264329 },
    { lat: 48.419698, lng: 20.264144 },
    { lat: 48.419791, lng: 20.264169 },
    { lat: 48.419856, lng: 20.263981 },
    { lat: 48.419891, lng: 20.263967 },
    { lat: 48.41993, lng: 20.263994 },
    { lat: 48.420015, lng: 20.263988 },
    { lat: 48.420038, lng: 20.263849 },
    { lat: 48.419943, lng: 20.263803 },
    { lat: 48.419924, lng: 20.263723 },
    { lat: 48.420115, lng: 20.263396 },
    { lat: 48.420223, lng: 20.26329 },
    { lat: 48.420265, lng: 20.263179 },
    { lat: 48.420341, lng: 20.263024 },
    { lat: 48.420412, lng: 20.262998 },
    { lat: 48.420463, lng: 20.262907 },
    { lat: 48.420465, lng: 20.262857 },
    { lat: 48.420469, lng: 20.262763 },
    { lat: 48.420546, lng: 20.262627 },
    { lat: 48.420514, lng: 20.262372 },
    { lat: 48.420523, lng: 20.262347 },
    { lat: 48.420596, lng: 20.262322 },
    { lat: 48.420617, lng: 20.262236 },
    { lat: 48.420749, lng: 20.262154 },
    { lat: 48.420762, lng: 20.262034 },
    { lat: 48.420786, lng: 20.26192 },
    { lat: 48.420742, lng: 20.261857 },
    { lat: 48.420651, lng: 20.2618 },
    { lat: 48.420657, lng: 20.261702 },
    { lat: 48.420702, lng: 20.261618 },
    { lat: 48.420748, lng: 20.26143 },
    { lat: 48.420826, lng: 20.261477 },
    { lat: 48.420881, lng: 20.261363 },
    { lat: 48.420987, lng: 20.261394 },
    { lat: 48.421005, lng: 20.261341 },
    { lat: 48.420955, lng: 20.261236 },
    { lat: 48.420952, lng: 20.261149 },
    { lat: 48.420972, lng: 20.26102 },
    { lat: 48.421015, lng: 20.26098 },
    { lat: 48.421077, lng: 20.261008 },
    { lat: 48.421158, lng: 20.260996 },
    { lat: 48.421211, lng: 20.26104 },
    { lat: 48.421287, lng: 20.260934 },
    { lat: 48.421241, lng: 20.260848 },
    { lat: 48.421279, lng: 20.260785 },
    { lat: 48.421301, lng: 20.260659 },
    { lat: 48.421331, lng: 20.260606 },
    { lat: 48.421358, lng: 20.260448 },
    { lat: 48.421386, lng: 20.260483 },
    { lat: 48.421381, lng: 20.260559 },
    { lat: 48.421408, lng: 20.260584 },
    { lat: 48.421433, lng: 20.260562 },
    { lat: 48.421473, lng: 20.260473 },
    { lat: 48.421508, lng: 20.260437 },
    { lat: 48.421512, lng: 20.260399 },
    { lat: 48.421457, lng: 20.260393 },
    { lat: 48.421418, lng: 20.260417 },
    { lat: 48.421399, lng: 20.260372 },
    { lat: 48.421442, lng: 20.260318 },
    { lat: 48.421561, lng: 20.260097 },
    { lat: 48.421522, lng: 20.259949 },
    { lat: 48.421532, lng: 20.259927 },
    { lat: 48.421606, lng: 20.25989 },
    { lat: 48.421627, lng: 20.259851 },
    { lat: 48.421621, lng: 20.259819 },
    { lat: 48.421566, lng: 20.259791 },
    { lat: 48.42157, lng: 20.25968 },
    { lat: 48.421587, lng: 20.259663 },
    { lat: 48.421664, lng: 20.259664 },
    { lat: 48.421708, lng: 20.259624 },
    { lat: 48.421829, lng: 20.25956 },
    { lat: 48.421841, lng: 20.259433 },
    { lat: 48.421922, lng: 20.259328 },
    { lat: 48.421961, lng: 20.259045 },
    { lat: 48.42202, lng: 20.259109 },
    { lat: 48.422086, lng: 20.259077 },
    { lat: 48.422152, lng: 20.259066 },
    { lat: 48.422226, lng: 20.259045 },
    { lat: 48.422322, lng: 20.258981 },
    { lat: 48.422336, lng: 20.258988 },
    { lat: 48.422362, lng: 20.259071 },
    { lat: 48.422374, lng: 20.259105 },
    { lat: 48.422409, lng: 20.259108 },
    { lat: 48.422444, lng: 20.259097 },
    { lat: 48.422534, lng: 20.259063 },
    { lat: 48.422596, lng: 20.258856 },
    { lat: 48.422717, lng: 20.258821 },
    { lat: 48.42273, lng: 20.258792 },
    { lat: 48.422729, lng: 20.258769 },
    { lat: 48.422718, lng: 20.258713 },
    { lat: 48.422728, lng: 20.258562 },
    { lat: 48.42278, lng: 20.25856 },
    { lat: 48.422845, lng: 20.258513 },
    { lat: 48.422925, lng: 20.258339 },
    { lat: 48.42294, lng: 20.258338 },
    { lat: 48.42302, lng: 20.258411 },
    { lat: 48.423033, lng: 20.258394 },
    { lat: 48.423016, lng: 20.258329 },
    { lat: 48.423018, lng: 20.258315 },
    { lat: 48.423048, lng: 20.258295 },
    { lat: 48.423082, lng: 20.258198 },
    { lat: 48.423188, lng: 20.25815 },
    { lat: 48.423233, lng: 20.258082 },
    { lat: 48.423174, lng: 20.25798 },
    { lat: 48.423178, lng: 20.257937 },
    { lat: 48.423242, lng: 20.257864 },
    { lat: 48.423348, lng: 20.257743 },
    { lat: 48.423332, lng: 20.257607 },
    { lat: 48.423262, lng: 20.257495 },
    { lat: 48.423267, lng: 20.257472 },
    { lat: 48.423278, lng: 20.257416 },
    { lat: 48.423286, lng: 20.257372 },
    { lat: 48.423333, lng: 20.25729 },
    { lat: 48.423333, lng: 20.257168 },
    { lat: 48.423348, lng: 20.257111 },
    { lat: 48.423349, lng: 20.257089 },
    { lat: 48.423328, lng: 20.257006 },
    { lat: 48.423385, lng: 20.256918 },
    { lat: 48.423402, lng: 20.256864 },
    { lat: 48.423447, lng: 20.256714 },
    { lat: 48.423437, lng: 20.256545 },
    { lat: 48.423443, lng: 20.256522 },
    { lat: 48.423455, lng: 20.256506 },
    { lat: 48.423548, lng: 20.25648 },
    { lat: 48.423672, lng: 20.256449 },
    { lat: 48.423687, lng: 20.256382 },
    { lat: 48.42361, lng: 20.256304 },
    { lat: 48.423601, lng: 20.256284 },
    { lat: 48.423602, lng: 20.256249 },
    { lat: 48.423673, lng: 20.256144 },
    { lat: 48.42374, lng: 20.256149 },
    { lat: 48.423753, lng: 20.256136 },
    { lat: 48.423734, lng: 20.255999 },
    { lat: 48.423732, lng: 20.255978 },
    { lat: 48.423755, lng: 20.255872 },
    { lat: 48.423822, lng: 20.255774 },
    { lat: 48.423878, lng: 20.255769 },
    { lat: 48.423892, lng: 20.255765 },
    { lat: 48.423939, lng: 20.255709 },
    { lat: 48.423945, lng: 20.255647 },
    { lat: 48.42395, lng: 20.255596 },
    { lat: 48.4220123, lng: 20.2538627 },
    { lat: 48.4201913, lng: 20.2524837 },
    { lat: 48.4192904, lng: 20.2513805 },
    { lat: 48.4189603, lng: 20.2503856 },
    { lat: 48.4187137, lng: 20.2498845 },
    { lat: 48.4177856, lng: 20.2488026 },
    { lat: 48.4174646, lng: 20.2480696 },
    { lat: 48.4164798, lng: 20.2467043 },
    { lat: 48.4161332, lng: 20.2460084 },
    { lat: 48.4151788, lng: 20.2446432 },
    { lat: 48.4121138, lng: 20.241894 },
    { lat: 48.4118939, lng: 20.2417188 },
    { lat: 48.4116695, lng: 20.2417663 },
    { lat: 48.4115632, lng: 20.2417288 },
    { lat: 48.4113549, lng: 20.2415313 },
    { lat: 48.4111785, lng: 20.2411494 },
    { lat: 48.4107042, lng: 20.2407501 },
    { lat: 48.4099528, lng: 20.2407154 },
    { lat: 48.4088539, lng: 20.2396273 },
    { lat: 48.4072719, lng: 20.2377204 },
    { lat: 48.4073759, lng: 20.2373636 },
    { lat: 48.4075122, lng: 20.2365751 },
    { lat: 48.4073278, lng: 20.2363857 },
    { lat: 48.407009, lng: 20.2362142 },
    { lat: 48.4068046, lng: 20.2359785 },
    { lat: 48.4065471, lng: 20.2358724 },
    { lat: 48.4054052, lng: 20.2341584 },
    { lat: 48.4044938, lng: 20.2337292 },
    { lat: 48.4038429, lng: 20.2335938 },
    { lat: 48.4032797, lng: 20.2332504 },
    { lat: 48.4025755, lng: 20.2329624 },
    { lat: 48.4021219, lng: 20.2324905 },
    { lat: 48.4017683, lng: 20.2319057 },
    { lat: 48.4011381, lng: 20.2304549 },
    { lat: 48.4008881, lng: 20.2294457 },
    { lat: 48.4008641, lng: 20.229153 },
    { lat: 48.4007061, lng: 20.2286374 },
    { lat: 48.400386, lng: 20.2282109 },
    { lat: 48.4001016, lng: 20.2276649 },
    { lat: 48.3999346, lng: 20.2269828 },
    { lat: 48.3997712, lng: 20.2268392 },
    { lat: 48.3995604, lng: 20.226439 },
    { lat: 48.3991925, lng: 20.2260862 },
    { lat: 48.3989067, lng: 20.2254444 },
    { lat: 48.3987627, lng: 20.2249438 },
    { lat: 48.3987743, lng: 20.2245855 },
    { lat: 48.3988508, lng: 20.2242217 },
    { lat: 48.3996992, lng: 20.2220968 },
    { lat: 48.3994596, lng: 20.2218179 },
    { lat: 48.3984143, lng: 20.2210073 },
    { lat: 48.3971948, lng: 20.2216837 },
    { lat: 48.397026, lng: 20.2219102 },
    { lat: 48.3967734, lng: 20.221932 },
    { lat: 48.3963031, lng: 20.222252 },
    { lat: 48.3942476, lng: 20.2230092 },
    { lat: 48.392576, lng: 20.2242406 },
    { lat: 48.392156, lng: 20.2244547 },
    { lat: 48.3917363, lng: 20.2243654 },
    { lat: 48.3911373, lng: 20.2244529 },
    { lat: 48.3898458, lng: 20.224463 },
    { lat: 48.3891704, lng: 20.2245581 },
    { lat: 48.3889305, lng: 20.2246138 },
    { lat: 48.3890345, lng: 20.2251831 },
    { lat: 48.3891086, lng: 20.2264963 },
    { lat: 48.3894065, lng: 20.2279151 },
    { lat: 48.3895936, lng: 20.2285924 },
    { lat: 48.389459, lng: 20.2288898 },
    { lat: 48.3892527, lng: 20.2306281 },
    { lat: 48.3892698, lng: 20.2318154 },
    { lat: 48.3891994, lng: 20.2328139 },
    { lat: 48.3887891, lng: 20.2341801 },
    { lat: 48.3887883, lng: 20.2348673 },
    { lat: 48.3888936, lng: 20.2358942 },
    { lat: 48.3894234, lng: 20.2371965 },
    { lat: 48.3897304, lng: 20.2377804 },
    { lat: 48.3900609, lng: 20.2386918 },
    { lat: 48.3899147, lng: 20.2398818 },
    { lat: 48.3898013, lng: 20.2413016 },
    { lat: 48.3894414, lng: 20.2419343 },
    { lat: 48.3891111, lng: 20.2426575 },
    { lat: 48.3890021, lng: 20.2430284 },
    { lat: 48.3889084, lng: 20.2440687 },
    { lat: 48.3881018, lng: 20.2456142 },
    { lat: 48.3875991, lng: 20.2460797 },
    { lat: 48.3862479, lng: 20.2467948 },
    { lat: 48.3854771, lng: 20.2468709 },
    { lat: 48.384494, lng: 20.2463053 },
    { lat: 48.383814, lng: 20.2479789 },
    { lat: 48.3834203, lng: 20.2486149 },
    { lat: 48.3831707, lng: 20.2488485 },
    { lat: 48.3825917, lng: 20.2492418 },
    { lat: 48.3821424, lng: 20.2489077 },
    { lat: 48.3813125, lng: 20.2489412 },
    { lat: 48.380368, lng: 20.2496252 },
    { lat: 48.379676, lng: 20.2504892 },
  ],
  Chanava: [
    { lat: 48.3612037, lng: 20.3067619 },
    { lat: 48.3607839, lng: 20.3068841 },
    { lat: 48.3605806, lng: 20.3069287 },
    { lat: 48.3605632, lng: 20.3066852 },
    { lat: 48.3557382, lng: 20.3020222 },
    { lat: 48.3539888, lng: 20.2994161 },
    { lat: 48.3534274, lng: 20.2971748 },
    { lat: 48.3539753, lng: 20.2966544 },
    { lat: 48.3541102, lng: 20.2960174 },
    { lat: 48.3543617, lng: 20.2956587 },
    { lat: 48.3561901, lng: 20.2946682 },
    { lat: 48.3566996, lng: 20.2937733 },
    { lat: 48.3572589, lng: 20.2933298 },
    { lat: 48.3559946, lng: 20.2882208 },
    { lat: 48.3557478, lng: 20.286451 },
    { lat: 48.3554479, lng: 20.2851594 },
    { lat: 48.3551639, lng: 20.2842167 },
    { lat: 48.3545601, lng: 20.2828503 },
    { lat: 48.3542918, lng: 20.2824447 },
    { lat: 48.3534334, lng: 20.2815854 },
    { lat: 48.3533684, lng: 20.2816564 },
    { lat: 48.3532634, lng: 20.2812798 },
    { lat: 48.353216, lng: 20.2809055 },
    { lat: 48.3528075, lng: 20.2802044 },
    { lat: 48.3512744, lng: 20.2765633 },
    { lat: 48.3513256, lng: 20.2746184 },
    { lat: 48.350431, lng: 20.2727057 },
    { lat: 48.3496548, lng: 20.2713167 },
    { lat: 48.349591, lng: 20.2710423 },
    { lat: 48.3493004, lng: 20.2697342 },
    { lat: 48.3490833, lng: 20.267591 },
    { lat: 48.3497285, lng: 20.2661132 },
    { lat: 48.3501357, lng: 20.2636659 },
    { lat: 48.3497643, lng: 20.26155 },
    { lat: 48.3493094, lng: 20.2601136 },
    { lat: 48.3490387, lng: 20.2582344 },
    { lat: 48.3487438, lng: 20.2576462 },
    { lat: 48.3486677, lng: 20.256075 },
    { lat: 48.3487836, lng: 20.2557909 },
    { lat: 48.3476751, lng: 20.2569699 },
    { lat: 48.3464638, lng: 20.258483 },
    { lat: 48.3454175, lng: 20.2594757 },
    { lat: 48.3439961, lng: 20.2604613 },
    { lat: 48.3437124, lng: 20.2607434 },
    { lat: 48.3432927, lng: 20.261038 },
    { lat: 48.34229, lng: 20.2609466 },
    { lat: 48.3416419, lng: 20.2605955 },
    { lat: 48.3412765, lng: 20.2602972 },
    { lat: 48.3410196, lng: 20.2601818 },
    { lat: 48.3410251, lng: 20.260125 },
    { lat: 48.3403978, lng: 20.2601246 },
    { lat: 48.340173, lng: 20.2601632 },
    { lat: 48.3399372, lng: 20.2602926 },
    { lat: 48.3398688, lng: 20.260188 },
    { lat: 48.3395835, lng: 20.2602068 },
    { lat: 48.3395336, lng: 20.2600031 },
    { lat: 48.3394031, lng: 20.2598672 },
    { lat: 48.3393463, lng: 20.2596535 },
    { lat: 48.339366, lng: 20.259244 },
    { lat: 48.3392971, lng: 20.2589836 },
    { lat: 48.3391771, lng: 20.2587724 },
    { lat: 48.338644, lng: 20.2592404 },
    { lat: 48.3356533, lng: 20.2622566 },
    { lat: 48.3351106, lng: 20.2626266 },
    { lat: 48.3347477, lng: 20.262789 },
    { lat: 48.3337297, lng: 20.2628214 },
    { lat: 48.3334952, lng: 20.2629022 },
    { lat: 48.3325755, lng: 20.2633644 },
    { lat: 48.3319219, lng: 20.2634378 },
    { lat: 48.3316549, lng: 20.2635254 },
    { lat: 48.3313814, lng: 20.2637268 },
    { lat: 48.3304121, lng: 20.2648669 },
    { lat: 48.329753, lng: 20.2657382 },
    { lat: 48.3292224, lng: 20.2667582 },
    { lat: 48.3287236, lng: 20.2675195 },
    { lat: 48.3280353, lng: 20.268365 },
    { lat: 48.327315, lng: 20.2694872 },
    { lat: 48.3271245, lng: 20.2695935 },
    { lat: 48.3257781, lng: 20.2703459 },
    { lat: 48.327323, lng: 20.2729177 },
    { lat: 48.3260779, lng: 20.2748267 },
    { lat: 48.3252128, lng: 20.2755894 },
    { lat: 48.324664, lng: 20.276153 },
    { lat: 48.3239378, lng: 20.277151 },
    { lat: 48.3230502, lng: 20.2777692 },
    { lat: 48.3225223, lng: 20.2774667 },
    { lat: 48.3222596, lng: 20.2765499 },
    { lat: 48.3208324, lng: 20.277167 },
    { lat: 48.3197414, lng: 20.2782268 },
    { lat: 48.3194105, lng: 20.2791417 },
    { lat: 48.317607, lng: 20.2813435 },
    { lat: 48.3160338, lng: 20.2841357 },
    { lat: 48.3157191, lng: 20.2844606 },
    { lat: 48.3175699, lng: 20.2890774 },
    { lat: 48.3181096, lng: 20.2903067 },
    { lat: 48.3166487, lng: 20.2912689 },
    { lat: 48.316507, lng: 20.2960582 },
    { lat: 48.3170213, lng: 20.2978156 },
    { lat: 48.3142661, lng: 20.2997406 },
    { lat: 48.3142527, lng: 20.3007395 },
    { lat: 48.3142218, lng: 20.3007585 },
    { lat: 48.313439, lng: 20.300955 },
    { lat: 48.3120693, lng: 20.3006103 },
    { lat: 48.3104786, lng: 20.3005679 },
    { lat: 48.3089866, lng: 20.301289 },
    { lat: 48.3086414, lng: 20.3015291 },
    { lat: 48.3086528, lng: 20.3019027 },
    { lat: 48.3085332, lng: 20.3024111 },
    { lat: 48.305175, lng: 20.3111773 },
    { lat: 48.3052243, lng: 20.3112621 },
    { lat: 48.3051836, lng: 20.3112597 },
    { lat: 48.304674, lng: 20.3121308 },
    { lat: 48.3045562, lng: 20.3125137 },
    { lat: 48.3045426, lng: 20.3129401 },
    { lat: 48.3043561, lng: 20.3138077 },
    { lat: 48.3046403, lng: 20.3138749 },
    { lat: 48.304673, lng: 20.3141076 },
    { lat: 48.3045203, lng: 20.3144592 },
    { lat: 48.3045274, lng: 20.3144664 },
    { lat: 48.3051101, lng: 20.3142514 },
    { lat: 48.3056722, lng: 20.314537 },
    { lat: 48.3061404, lng: 20.3149332 },
    { lat: 48.3070651, lng: 20.3149338 },
    { lat: 48.3073713, lng: 20.3150447 },
    { lat: 48.3081966, lng: 20.3164723 },
    { lat: 48.3085989, lng: 20.3169341 },
    { lat: 48.309087, lng: 20.3171442 },
    { lat: 48.309323, lng: 20.317504 },
    { lat: 48.309726, lng: 20.3178734 },
    { lat: 48.3099558, lng: 20.3182745 },
    { lat: 48.3102119, lng: 20.3186003 },
    { lat: 48.3105884, lng: 20.3188408 },
    { lat: 48.3113179, lng: 20.3191184 },
    { lat: 48.3120106, lng: 20.3189224 },
    { lat: 48.3122608, lng: 20.3186657 },
    { lat: 48.3121506, lng: 20.3194152 },
    { lat: 48.3120731, lng: 20.319623 },
    { lat: 48.3119657, lng: 20.3197861 },
    { lat: 48.3115433, lng: 20.320099 },
    { lat: 48.3113424, lng: 20.3203414 },
    { lat: 48.3114921, lng: 20.3212576 },
    { lat: 48.3115632, lng: 20.3222072 },
    { lat: 48.3116245, lng: 20.3223621 },
    { lat: 48.3117328, lng: 20.3224509 },
    { lat: 48.3118432, lng: 20.3224549 },
    { lat: 48.3120237, lng: 20.3222921 },
    { lat: 48.3124396, lng: 20.3212386 },
    { lat: 48.3126564, lng: 20.3211091 },
    { lat: 48.3129475, lng: 20.3211562 },
    { lat: 48.3131882, lng: 20.3213996 },
    { lat: 48.3134461, lng: 20.3217784 },
    { lat: 48.3137738, lng: 20.3219433 },
    { lat: 48.314333, lng: 20.3232196 },
    { lat: 48.3146383, lng: 20.3236876 },
    { lat: 48.3148481, lng: 20.3242645 },
    { lat: 48.3152085, lng: 20.3241212 },
    { lat: 48.315612, lng: 20.3241319 },
    { lat: 48.3160857, lng: 20.3244641 },
    { lat: 48.3162216, lng: 20.3247376 },
    { lat: 48.3163861, lng: 20.3258036 },
    { lat: 48.3158492, lng: 20.3253518 },
    { lat: 48.31582, lng: 20.327047 },
    { lat: 48.3163252, lng: 20.3275523 },
    { lat: 48.3167008, lng: 20.3272492 },
    { lat: 48.3166951, lng: 20.3259841 },
    { lat: 48.3172283, lng: 20.3257112 },
    { lat: 48.3172818, lng: 20.3256131 },
    { lat: 48.3172777, lng: 20.3252501 },
    { lat: 48.3173321, lng: 20.32497 },
    { lat: 48.3175227, lng: 20.3244642 },
    { lat: 48.3174452, lng: 20.3239499 },
    { lat: 48.3165064, lng: 20.3238484 },
    { lat: 48.3163152, lng: 20.3240585 },
    { lat: 48.3162514, lng: 20.3227455 },
    { lat: 48.3161989, lng: 20.3226171 },
    { lat: 48.3160023, lng: 20.3224006 },
    { lat: 48.315811, lng: 20.3219993 },
    { lat: 48.3166903, lng: 20.3204454 },
    { lat: 48.3169213, lng: 20.3197651 },
    { lat: 48.3169655, lng: 20.3194845 },
    { lat: 48.317297, lng: 20.3190625 },
    { lat: 48.3176073, lng: 20.3188521 },
    { lat: 48.317855, lng: 20.3187368 },
    { lat: 48.3182212, lng: 20.3187706 },
    { lat: 48.3185782, lng: 20.3189672 },
    { lat: 48.3190197, lng: 20.3193128 },
    { lat: 48.3196975, lng: 20.3203944 },
    { lat: 48.3201935, lng: 20.3206393 },
    { lat: 48.3203353, lng: 20.3207754 },
    { lat: 48.3205047, lng: 20.3208167 },
    { lat: 48.3207809, lng: 20.3207406 },
    { lat: 48.3216547, lng: 20.3208774 },
    { lat: 48.3220783, lng: 20.3208828 },
    { lat: 48.3228488, lng: 20.3214888 },
    { lat: 48.3232857, lng: 20.3216296 },
    { lat: 48.3228774, lng: 20.32293 },
    { lat: 48.3221177, lng: 20.3242639 },
    { lat: 48.3222057, lng: 20.3245814 },
    { lat: 48.3223882, lng: 20.3246969 },
    { lat: 48.3234301, lng: 20.3243019 },
    { lat: 48.3234445, lng: 20.3257324 },
    { lat: 48.3235155, lng: 20.3259877 },
    { lat: 48.3236496, lng: 20.3261634 },
    { lat: 48.3237872, lng: 20.3262018 },
    { lat: 48.3240115, lng: 20.3261204 },
    { lat: 48.3243305, lng: 20.3258651 },
    { lat: 48.3244921, lng: 20.325395 },
    { lat: 48.3249379, lng: 20.3244384 },
    { lat: 48.3258334, lng: 20.3235288 },
    { lat: 48.3272038, lng: 20.3244117 },
    { lat: 48.326895, lng: 20.3258396 },
    { lat: 48.3267152, lng: 20.3262802 },
    { lat: 48.3266168, lng: 20.3271032 },
    { lat: 48.327107, lng: 20.3275978 },
    { lat: 48.3275008, lng: 20.3279014 },
    { lat: 48.3276747, lng: 20.3281178 },
    { lat: 48.3279935, lng: 20.327745 },
    { lat: 48.3279218, lng: 20.3273854 },
    { lat: 48.3299918, lng: 20.3270076 },
    { lat: 48.3299201, lng: 20.3275388 },
    { lat: 48.3302967, lng: 20.3277462 },
    { lat: 48.3307023, lng: 20.3278421 },
    { lat: 48.3311795, lng: 20.3281181 },
    { lat: 48.3312469, lng: 20.3281161 },
    { lat: 48.3312249, lng: 20.3280125 },
    { lat: 48.3312611, lng: 20.32727 },
    { lat: 48.3314019, lng: 20.3271578 },
    { lat: 48.3313325, lng: 20.3269668 },
    { lat: 48.3313048, lng: 20.3266029 },
    { lat: 48.3314206, lng: 20.3261694 },
    { lat: 48.3316553, lng: 20.3259253 },
    { lat: 48.3327679, lng: 20.3254601 },
    { lat: 48.3330111, lng: 20.3254319 },
    { lat: 48.3330842, lng: 20.3254849 },
    { lat: 48.3332195, lng: 20.325835 },
    { lat: 48.3331973, lng: 20.3262425 },
    { lat: 48.3330594, lng: 20.3263202 },
    { lat: 48.332529, lng: 20.3262934 },
    { lat: 48.3324385, lng: 20.3263917 },
    { lat: 48.3323641, lng: 20.3265635 },
    { lat: 48.3323508, lng: 20.3267972 },
    { lat: 48.332499, lng: 20.327332 },
    { lat: 48.3331524, lng: 20.3281418 },
    { lat: 48.3333749, lng: 20.3289065 },
    { lat: 48.3335368, lng: 20.3292115 },
    { lat: 48.3338214, lng: 20.3295488 },
    { lat: 48.3341978, lng: 20.3296451 },
    { lat: 48.3343761, lng: 20.3295947 },
    { lat: 48.3346663, lng: 20.3293567 },
    { lat: 48.3348195, lng: 20.3291545 },
    { lat: 48.334941, lng: 20.3288334 },
    { lat: 48.3350191, lng: 20.3282743 },
    { lat: 48.3349838, lng: 20.327915 },
    { lat: 48.3349078, lng: 20.3275851 },
    { lat: 48.3347691, lng: 20.3273507 },
    { lat: 48.3344663, lng: 20.3276471 },
    { lat: 48.3342456, lng: 20.3277041 },
    { lat: 48.3340844, lng: 20.3275839 },
    { lat: 48.3339381, lng: 20.3273033 },
    { lat: 48.3339186, lng: 20.3271171 },
    { lat: 48.3340488, lng: 20.3263595 },
    { lat: 48.3339302, lng: 20.3260062 },
    { lat: 48.3342593, lng: 20.325438 },
    { lat: 48.3345232, lng: 20.3247911 },
    { lat: 48.3348521, lng: 20.3245596 },
    { lat: 48.3348361, lng: 20.3238092 },
    { lat: 48.3349475, lng: 20.3233187 },
    { lat: 48.3353417, lng: 20.323235 },
    { lat: 48.3356325, lng: 20.3233562 },
    { lat: 48.3357602, lng: 20.323598 },
    { lat: 48.3357981, lng: 20.3237879 },
    { lat: 48.3357325, lng: 20.3242086 },
    { lat: 48.3366248, lng: 20.3245952 },
    { lat: 48.3373268, lng: 20.3246043 },
    { lat: 48.3371587, lng: 20.3243374 },
    { lat: 48.3371912, lng: 20.3241317 },
    { lat: 48.3374978, lng: 20.3238788 },
    { lat: 48.3375331, lng: 20.3237457 },
    { lat: 48.3374793, lng: 20.3226327 },
    { lat: 48.3373225, lng: 20.3222526 },
    { lat: 48.3373218, lng: 20.3221521 },
    { lat: 48.3373753, lng: 20.3220224 },
    { lat: 48.337614, lng: 20.3219047 },
    { lat: 48.3377646, lng: 20.3217272 },
    { lat: 48.3380537, lng: 20.3212029 },
    { lat: 48.3382148, lng: 20.3211776 },
    { lat: 48.3382915, lng: 20.3213769 },
    { lat: 48.3381124, lng: 20.3223258 },
    { lat: 48.3381605, lng: 20.3226319 },
    { lat: 48.3383996, lng: 20.3226986 },
    { lat: 48.33875, lng: 20.3229855 },
    { lat: 48.3389949, lng: 20.3226276 },
    { lat: 48.3392162, lng: 20.3225008 },
    { lat: 48.3392971, lng: 20.322524 },
    { lat: 48.3394456, lng: 20.3225722 },
    { lat: 48.3394225, lng: 20.3227894 },
    { lat: 48.3397492, lng: 20.3231099 },
    { lat: 48.3397439, lng: 20.324521 },
    { lat: 48.3396831, lng: 20.3251841 },
    { lat: 48.3404074, lng: 20.3252142 },
    { lat: 48.3405695, lng: 20.3250696 },
    { lat: 48.3405034, lng: 20.3246776 },
    { lat: 48.3406239, lng: 20.3237855 },
    { lat: 48.3405926, lng: 20.3235352 },
    { lat: 48.3403104, lng: 20.3230865 },
    { lat: 48.3397539, lng: 20.322883 },
    { lat: 48.3396535, lng: 20.3225917 },
    { lat: 48.3397728, lng: 20.322047 },
    { lat: 48.3399416, lng: 20.3216402 },
    { lat: 48.340285, lng: 20.3210937 },
    { lat: 48.3405073, lng: 20.3209611 },
    { lat: 48.3408705, lng: 20.3209495 },
    { lat: 48.3412439, lng: 20.3210245 },
    { lat: 48.3416444, lng: 20.3213232 },
    { lat: 48.3417233, lng: 20.3215297 },
    { lat: 48.341718, lng: 20.3220258 },
    { lat: 48.3417774, lng: 20.322445 },
    { lat: 48.3418917, lng: 20.3227177 },
    { lat: 48.3422174, lng: 20.3229519 },
    { lat: 48.3423616, lng: 20.322868 },
    { lat: 48.3425137, lng: 20.3226135 },
    { lat: 48.3426014, lng: 20.3222914 },
    { lat: 48.3426525, lng: 20.3217608 },
    { lat: 48.3427097, lng: 20.3216365 },
    { lat: 48.3428319, lng: 20.32154 },
    { lat: 48.3429796, lng: 20.321544 },
    { lat: 48.3430682, lng: 20.3216183 },
    { lat: 48.3432833, lng: 20.3223908 },
    { lat: 48.3433945, lng: 20.3225831 },
    { lat: 48.3437411, lng: 20.3222636 },
    { lat: 48.344194, lng: 20.3216408 },
    { lat: 48.3444859, lng: 20.3213577 },
    { lat: 48.3450782, lng: 20.3210964 },
    { lat: 48.3452722, lng: 20.3211564 },
    { lat: 48.3453494, lng: 20.321351 },
    { lat: 48.3453467, lng: 20.3216533 },
    { lat: 48.3450987, lng: 20.3225623 },
    { lat: 48.3448345, lng: 20.3231773 },
    { lat: 48.344838, lng: 20.323441 },
    { lat: 48.3449262, lng: 20.3236747 },
    { lat: 48.3451623, lng: 20.3239834 },
    { lat: 48.3455106, lng: 20.3241615 },
    { lat: 48.3457528, lng: 20.3241348 },
    { lat: 48.3462541, lng: 20.3234193 },
    { lat: 48.3465096, lng: 20.3232745 },
    { lat: 48.3469096, lng: 20.3231662 },
    { lat: 48.3470049, lng: 20.3230385 },
    { lat: 48.3469795, lng: 20.3227628 },
    { lat: 48.3468572, lng: 20.3223718 },
    { lat: 48.3468517, lng: 20.3219489 },
    { lat: 48.3469886, lng: 20.3210199 },
    { lat: 48.3469199, lng: 20.3205013 },
    { lat: 48.3470979, lng: 20.319644 },
    { lat: 48.3470781, lng: 20.3192839 },
    { lat: 48.3469558, lng: 20.3190363 },
    { lat: 48.3468104, lng: 20.3191214 },
    { lat: 48.3467218, lng: 20.3203315 },
    { lat: 48.3459793, lng: 20.3200956 },
    { lat: 48.3457891, lng: 20.3197233 },
    { lat: 48.3457558, lng: 20.3190798 },
    { lat: 48.3455923, lng: 20.3184981 },
    { lat: 48.3456135, lng: 20.3180371 },
    { lat: 48.3457581, lng: 20.3175612 },
    { lat: 48.3459452, lng: 20.3172756 },
    { lat: 48.3460701, lng: 20.3167618 },
    { lat: 48.346026, lng: 20.3162411 },
    { lat: 48.3457042, lng: 20.315445 },
    { lat: 48.3456938, lng: 20.3152112 },
    { lat: 48.3460941, lng: 20.3144203 },
    { lat: 48.3463928, lng: 20.3135127 },
    { lat: 48.3465926, lng: 20.3132418 },
    { lat: 48.3468162, lng: 20.3130811 },
    { lat: 48.3471114, lng: 20.3131407 },
    { lat: 48.3473081, lng: 20.3134203 },
    { lat: 48.3475452, lng: 20.3141799 },
    { lat: 48.3476744, lng: 20.3144574 },
    { lat: 48.3477765, lng: 20.314563 },
    { lat: 48.3480175, lng: 20.3147038 },
    { lat: 48.3482424, lng: 20.3147267 },
    { lat: 48.3487033, lng: 20.3145882 },
    { lat: 48.3488063, lng: 20.3149144 },
    { lat: 48.3490698, lng: 20.3153446 },
    { lat: 48.3493296, lng: 20.3154522 },
    { lat: 48.349507, lng: 20.3148077 },
    { lat: 48.3495541, lng: 20.3147993 },
    { lat: 48.3497931, lng: 20.3154104 },
    { lat: 48.3500133, lng: 20.3153084 },
    { lat: 48.3506741, lng: 20.3150432 },
    { lat: 48.3516213, lng: 20.3148145 },
    { lat: 48.3554932, lng: 20.3146436 },
    { lat: 48.3559876, lng: 20.3144458 },
    { lat: 48.3564385, lng: 20.3141126 },
    { lat: 48.3566634, lng: 20.3138728 },
    { lat: 48.3577511, lng: 20.3124928 },
    { lat: 48.3579044, lng: 20.3119258 },
    { lat: 48.3582225, lng: 20.3099886 },
    { lat: 48.3583927, lng: 20.3095117 },
    { lat: 48.3587215, lng: 20.3090516 },
    { lat: 48.3591191, lng: 20.3088233 },
    { lat: 48.3613839, lng: 20.3083041 },
    { lat: 48.3612037, lng: 20.3067619 },
  ],
  DolnéZahorany: [
    { lat: 48.3532297, lng: 19.8825155 },
    { lat: 48.3511377, lng: 19.8820474 },
    { lat: 48.3483097, lng: 19.8827473 },
    { lat: 48.3475284, lng: 19.8838619 },
    { lat: 48.3475872, lng: 19.8842057 },
    { lat: 48.3474744, lng: 19.8889556 },
    { lat: 48.3475595, lng: 19.8895232 },
    { lat: 48.3477702, lng: 19.8899625 },
    { lat: 48.3481689, lng: 19.89266 },
    { lat: 48.3474696, lng: 19.8936571 },
    { lat: 48.3460269, lng: 19.8952672 },
    { lat: 48.3441158, lng: 19.8968305 },
    { lat: 48.3436358, lng: 19.8973228 },
    { lat: 48.3422746, lng: 19.8995182 },
    { lat: 48.3408913, lng: 19.9007512 },
    { lat: 48.3402872, lng: 19.9014541 },
    { lat: 48.3397226, lng: 19.902418 },
    { lat: 48.3397338, lng: 19.9024965 },
    { lat: 48.3396835, lng: 19.9025078 },
    { lat: 48.3393708, lng: 19.9037734 },
    { lat: 48.3390282, lng: 19.9054265 },
    { lat: 48.3387935, lng: 19.9071452 },
    { lat: 48.3406426, lng: 19.9101259 },
    { lat: 48.3414199, lng: 19.9117726 },
    { lat: 48.3417973, lng: 19.9117776 },
    { lat: 48.3422187, lng: 19.9118828 },
    { lat: 48.3429076, lng: 19.9126804 },
    { lat: 48.3430897, lng: 19.9140152 },
    { lat: 48.3429588, lng: 19.9145852 },
    { lat: 48.3423908, lng: 19.9150323 },
    { lat: 48.3427832, lng: 19.9159846 },
    { lat: 48.3426603, lng: 19.9165892 },
    { lat: 48.342248, lng: 19.916823 },
    { lat: 48.3410844, lng: 19.9185643 },
    { lat: 48.3408315, lng: 19.921363 },
    { lat: 48.343186, lng: 19.9232571 },
    { lat: 48.3439362, lng: 19.9241782 },
    { lat: 48.3447985, lng: 19.9243473 },
    { lat: 48.3454889, lng: 19.9246595 },
    { lat: 48.3469223, lng: 19.9255655 },
    { lat: 48.3476723, lng: 19.9266911 },
    { lat: 48.3508956, lng: 19.9269554 },
    { lat: 48.3518663, lng: 19.9280825 },
    { lat: 48.3525463, lng: 19.9290736 },
    { lat: 48.3527009, lng: 19.9295029 },
    { lat: 48.3533252, lng: 19.9306113 },
    { lat: 48.3539169, lng: 19.9314643 },
    { lat: 48.3546698, lng: 19.9328441 },
    { lat: 48.355462, lng: 19.9345893 },
    { lat: 48.3571126, lng: 19.9336986 },
    { lat: 48.3582529, lng: 19.9333864 },
    { lat: 48.3580891, lng: 19.9302631 },
    { lat: 48.3577749, lng: 19.9285782 },
    { lat: 48.3579712, lng: 19.9273178 },
    { lat: 48.35792, lng: 19.922119 },
    { lat: 48.3581141, lng: 19.9199952 },
    { lat: 48.3582418, lng: 19.9195266 },
    { lat: 48.3588736, lng: 19.9187063 },
    { lat: 48.3591781, lng: 19.9181654 },
    { lat: 48.3602591, lng: 19.9156039 },
    { lat: 48.3607943, lng: 19.9145243 },
    { lat: 48.3627567, lng: 19.9096627 },
    { lat: 48.3624861, lng: 19.908923 },
    { lat: 48.3618424, lng: 19.90629 },
    { lat: 48.3617121, lng: 19.9049331 },
    { lat: 48.3617618, lng: 19.9029682 },
    { lat: 48.3611567, lng: 19.8994793 },
    { lat: 48.3613575, lng: 19.8986965 },
    { lat: 48.3614828, lng: 19.8973163 },
    { lat: 48.3617006, lng: 19.8964085 },
    { lat: 48.3613909, lng: 19.8953336 },
    { lat: 48.3607011, lng: 19.8949891 },
    { lat: 48.3598693, lng: 19.8919701 },
    { lat: 48.3601218, lng: 19.8903009 },
    { lat: 48.3603071, lng: 19.8897629 },
    { lat: 48.3604488, lng: 19.8890729 },
    { lat: 48.3604012, lng: 19.8877598 },
    { lat: 48.3594518, lng: 19.8855221 },
    { lat: 48.3592526, lng: 19.8852874 },
    { lat: 48.3560359, lng: 19.8832486 },
    { lat: 48.3532297, lng: 19.8825155 },
  ],
  HornéZahorany: [
    { lat: 48.4938277, lng: 20.0037761 },
    { lat: 48.4930734, lng: 20.0005634 },
    { lat: 48.4924916, lng: 19.9978163 },
    { lat: 48.4921679, lng: 19.9969763 },
    { lat: 48.4916413, lng: 19.997096 },
    { lat: 48.4899131, lng: 19.997684 },
    { lat: 48.4894229, lng: 19.9978922 },
    { lat: 48.4891837, lng: 19.9980715 },
    { lat: 48.4886656, lng: 19.9972685 },
    { lat: 48.4886073, lng: 19.996925 },
    { lat: 48.4883293, lng: 19.9960463 },
    { lat: 48.4880814, lng: 19.9958578 },
    { lat: 48.4877331, lng: 19.9949958 },
    { lat: 48.4880093, lng: 19.9941666 },
    { lat: 48.4880583, lng: 19.9930011 },
    { lat: 48.4879437, lng: 19.9922091 },
    { lat: 48.4880471, lng: 19.9921251 },
    { lat: 48.4879069, lng: 19.9919509 },
    { lat: 48.4874922, lng: 19.9917038 },
    { lat: 48.4872683, lng: 19.9912522 },
    { lat: 48.4871724, lng: 19.9912787 },
    { lat: 48.4866752, lng: 19.9907845 },
    { lat: 48.486429, lng: 19.990184 },
    { lat: 48.4863401, lng: 19.9901577 },
    { lat: 48.4862293, lng: 19.9899602 },
    { lat: 48.4859638, lng: 19.9899436 },
    { lat: 48.4858315, lng: 19.9896103 },
    { lat: 48.4853373, lng: 19.9887043 },
    { lat: 48.4851677, lng: 19.9886399 },
    { lat: 48.484923, lng: 19.9882051 },
    { lat: 48.4847291, lng: 19.9872656 },
    { lat: 48.484763, lng: 19.9869726 },
    { lat: 48.4847404, lng: 19.9863573 },
    { lat: 48.4848827, lng: 19.9859925 },
    { lat: 48.4847997, lng: 19.9857593 },
    { lat: 48.4849306, lng: 19.9854702 },
    { lat: 48.4850754, lng: 19.9842286 },
    { lat: 48.485006, lng: 19.9838101 },
    { lat: 48.4853466, lng: 19.9824017 },
    { lat: 48.4841966, lng: 19.9803216 },
    { lat: 48.4841977, lng: 19.9802416 },
    { lat: 48.484078, lng: 19.9802633 },
    { lat: 48.4836557, lng: 19.9806108 },
    { lat: 48.4836853, lng: 19.9807024 },
    { lat: 48.4834844, lng: 19.9811444 },
    { lat: 48.4834989, lng: 19.9818894 },
    { lat: 48.4834253, lng: 19.9819312 },
    { lat: 48.4832392, lng: 19.9815531 },
    { lat: 48.4831083, lng: 19.9810681 },
    { lat: 48.4828878, lng: 19.981075 },
    { lat: 48.4827485, lng: 19.9809231 },
    { lat: 48.4825732, lng: 19.9805056 },
    { lat: 48.4824809, lng: 19.9804043 },
    { lat: 48.4824251, lng: 19.9803855 },
    { lat: 48.482212, lng: 19.9805429 },
    { lat: 48.4821127, lng: 19.9804317 },
    { lat: 48.4819921, lng: 19.9801076 },
    { lat: 48.4817521, lng: 19.9798426 },
    { lat: 48.4815964, lng: 19.9797538 },
    { lat: 48.481069, lng: 19.9796418 },
    { lat: 48.4806774, lng: 19.979361 },
    { lat: 48.4805074, lng: 19.9793098 },
    { lat: 48.4798979, lng: 19.9794383 },
    { lat: 48.4795451, lng: 19.979407 },
    { lat: 48.4792572, lng: 19.9795257 },
    { lat: 48.4790338, lng: 19.9794909 },
    { lat: 48.478787, lng: 19.979834 },
    { lat: 48.4786576, lng: 19.9798683 },
    { lat: 48.4784395, lng: 19.9800645 },
    { lat: 48.4779119, lng: 19.980242 },
    { lat: 48.47781, lng: 19.9806291 },
    { lat: 48.4778046, lng: 19.9812747 },
    { lat: 48.4776233, lng: 19.9816485 },
    { lat: 48.4775401, lng: 19.9819986 },
    { lat: 48.4775797, lng: 19.9823797 },
    { lat: 48.4776166, lng: 19.9826169 },
    { lat: 48.4777408, lng: 19.9826715 },
    { lat: 48.4778485, lng: 19.9828763 },
    { lat: 48.4779829, lng: 19.9829381 },
    { lat: 48.4782942, lng: 19.9834587 },
    { lat: 48.4783149, lng: 19.9837088 },
    { lat: 48.4782729, lng: 19.9837817 },
    { lat: 48.4783056, lng: 19.9838581 },
    { lat: 48.478179, lng: 19.9842774 },
    { lat: 48.4781985, lng: 19.9844992 },
    { lat: 48.4780402, lng: 19.985235 },
    { lat: 48.4779335, lng: 19.9854579 },
    { lat: 48.477931, lng: 19.9856622 },
    { lat: 48.4778519, lng: 19.9858473 },
    { lat: 48.4778715, lng: 19.9860993 },
    { lat: 48.4777955, lng: 19.9864864 },
    { lat: 48.4777497, lng: 19.9866267 },
    { lat: 48.477611, lng: 19.9867578 },
    { lat: 48.4775176, lng: 19.9869996 },
    { lat: 48.4773928, lng: 19.9878654 },
    { lat: 48.4765969, lng: 19.9917793 },
    { lat: 48.4748594, lng: 19.9920895 },
    { lat: 48.4744528, lng: 19.9924978 },
    { lat: 48.4736374, lng: 19.9913236 },
    { lat: 48.4731981, lng: 19.9915355 },
    { lat: 48.4729758, lng: 19.9915527 },
    { lat: 48.4721442, lng: 19.9913866 },
    { lat: 48.4720485, lng: 19.9912274 },
    { lat: 48.4714782, lng: 19.9910073 },
    { lat: 48.4704163, lng: 19.9909099 },
    { lat: 48.469959, lng: 19.990695 },
    { lat: 48.4696338, lng: 19.9906511 },
    { lat: 48.4689814, lng: 19.9903497 },
    { lat: 48.4684217, lng: 19.990191 },
    { lat: 48.4680976, lng: 19.9901747 },
    { lat: 48.4674067, lng: 19.9905677 },
    { lat: 48.4666927, lng: 19.9906257 },
    { lat: 48.4666466, lng: 19.9907089 },
    { lat: 48.4665443, lng: 19.9905479 },
    { lat: 48.4663625, lng: 19.9905418 },
    { lat: 48.4662925, lng: 19.9902428 },
    { lat: 48.4660962, lng: 19.990318 },
    { lat: 48.4659909, lng: 19.9902765 },
    { lat: 48.4658978, lng: 19.9903429 },
    { lat: 48.4657891, lng: 19.9905704 },
    { lat: 48.4656597, lng: 19.9904614 },
    { lat: 48.4655764, lng: 19.9906255 },
    { lat: 48.4655446, lng: 19.9908844 },
    { lat: 48.4652641, lng: 19.9908323 },
    { lat: 48.465174, lng: 19.9910468 },
    { lat: 48.4649517, lng: 19.9910097 },
    { lat: 48.4647977, lng: 19.9911021 },
    { lat: 48.4643652, lng: 19.9911301 },
    { lat: 48.4639313, lng: 19.9914213 },
    { lat: 48.463792, lng: 19.9914526 },
    { lat: 48.4635556, lng: 19.9914056 },
    { lat: 48.4634405, lng: 19.9912114 },
    { lat: 48.4632842, lng: 19.9912436 },
    { lat: 48.463058, lng: 19.9911693 },
    { lat: 48.462811, lng: 19.9908709 },
    { lat: 48.4627065, lng: 19.9908997 },
    { lat: 48.4623358, lng: 19.9913307 },
    { lat: 48.4620654, lng: 19.9914902 },
    { lat: 48.4620339, lng: 19.991544 },
    { lat: 48.4620468, lng: 19.9917656 },
    { lat: 48.4619436, lng: 19.9920164 },
    { lat: 48.4616587, lng: 19.9918096 },
    { lat: 48.4614161, lng: 19.9922764 },
    { lat: 48.4612493, lng: 19.9923302 },
    { lat: 48.4610176, lng: 19.9921247 },
    { lat: 48.4606946, lng: 19.9923741 },
    { lat: 48.4606099, lng: 19.9923421 },
    { lat: 48.4605489, lng: 19.9920693 },
    { lat: 48.4604682, lng: 19.9920769 },
    { lat: 48.4603171, lng: 19.9923671 },
    { lat: 48.4602526, lng: 19.9923374 },
    { lat: 48.4602497, lng: 19.9920271 },
    { lat: 48.4601963, lng: 19.9919591 },
    { lat: 48.460022, lng: 19.9918898 },
    { lat: 48.4598563, lng: 19.9919921 },
    { lat: 48.4597509, lng: 19.9919769 },
    { lat: 48.459477, lng: 19.9914934 },
    { lat: 48.4590642, lng: 19.9911298 },
    { lat: 48.4589474, lng: 19.9909511 },
    { lat: 48.458914, lng: 19.9905894 },
    { lat: 48.4589733, lng: 19.9900692 },
    { lat: 48.4587942, lng: 19.9897728 },
    { lat: 48.4587442, lng: 19.9895788 },
    { lat: 48.4589038, lng: 19.9893269 },
    { lat: 48.4585319, lng: 19.9891671 },
    { lat: 48.4585009, lng: 19.9890988 },
    { lat: 48.4555619, lng: 19.9923864 },
    { lat: 48.4540115, lng: 19.9944654 },
    { lat: 48.4540655, lng: 19.994543 },
    { lat: 48.454801, lng: 19.995912 },
    { lat: 48.4546484, lng: 19.9967282 },
    { lat: 48.4544709, lng: 19.997194 },
    { lat: 48.4543425, lng: 19.9977559 },
    { lat: 48.455338, lng: 19.998712 },
    { lat: 48.455924, lng: 19.998935 },
    { lat: 48.4562494, lng: 19.9997348 },
    { lat: 48.4563069, lng: 20.0004359 },
    { lat: 48.4561936, lng: 20.001193 },
    { lat: 48.4559215, lng: 20.0017685 },
    { lat: 48.4558858, lng: 20.0022606 },
    { lat: 48.455764, lng: 20.0028236 },
    { lat: 48.4558002, lng: 20.0036847 },
    { lat: 48.455794, lng: 20.004285 },
    { lat: 48.4557545, lng: 20.0044985 },
    { lat: 48.4554942, lng: 20.0053827 },
    { lat: 48.4546214, lng: 20.0071053 },
    { lat: 48.4550383, lng: 20.0078883 },
    { lat: 48.4551494, lng: 20.009029 },
    { lat: 48.4553909, lng: 20.0094972 },
    { lat: 48.4554883, lng: 20.0101019 },
    { lat: 48.4555019, lng: 20.0101844 },
    { lat: 48.4566586, lng: 20.0101521 },
    { lat: 48.4574641, lng: 20.0098054 },
    { lat: 48.4629705, lng: 20.0113963 },
    { lat: 48.4646566, lng: 20.0117175 },
    { lat: 48.4656664, lng: 20.0118358 },
    { lat: 48.4669568, lng: 20.0121707 },
    { lat: 48.46782, lng: 20.0127442 },
    { lat: 48.4680673, lng: 20.0128386 },
    { lat: 48.46859, lng: 20.0129186 },
    { lat: 48.4689606, lng: 20.0128921 },
    { lat: 48.469231, lng: 20.0126535 },
    { lat: 48.4697155, lng: 20.0119299 },
    { lat: 48.4702104, lng: 20.0114035 },
    { lat: 48.4708591, lng: 20.0110214 },
    { lat: 48.4715838, lng: 20.0100074 },
    { lat: 48.4717975, lng: 20.0095197 },
    { lat: 48.4719282, lng: 20.0089563 },
    { lat: 48.4721394, lng: 20.0083236 },
    { lat: 48.4725598, lng: 20.0073384 },
    { lat: 48.4729285, lng: 20.0066193 },
    { lat: 48.4730632, lng: 20.0062328 },
    { lat: 48.4737235, lng: 20.0048957 },
    { lat: 48.4742081, lng: 20.0047677 },
    { lat: 48.4746383, lng: 20.0044673 },
    { lat: 48.476319, lng: 20.0030212 },
    { lat: 48.4767265, lng: 20.0029048 },
    { lat: 48.4769164, lng: 20.0027852 },
    { lat: 48.4784791, lng: 20.0027412 },
    { lat: 48.4792929, lng: 20.0025926 },
    { lat: 48.4799903, lng: 20.002298 },
    { lat: 48.4803545, lng: 20.0022301 },
    { lat: 48.4805419, lng: 20.0022634 },
    { lat: 48.4813368, lng: 20.0019852 },
    { lat: 48.4819379, lng: 20.0019203 },
    { lat: 48.4823282, lng: 20.0017753 },
    { lat: 48.4847494, lng: 20.0015403 },
    { lat: 48.4854733, lng: 20.0013998 },
    { lat: 48.4856996, lng: 20.0014476 },
    { lat: 48.4872283, lng: 20.0023083 },
    { lat: 48.4883736, lng: 20.0026796 },
    { lat: 48.488717, lng: 20.0028651 },
    { lat: 48.4892654, lng: 20.0029671 },
    { lat: 48.489566, lng: 20.003207 },
    { lat: 48.4901287, lng: 20.0035137 },
    { lat: 48.4911881, lng: 20.0034972 },
    { lat: 48.4920945, lng: 20.0036498 },
    { lat: 48.492508, lng: 20.0034127 },
    { lat: 48.4929386, lng: 20.0036332 },
    { lat: 48.4935963, lng: 20.0037639 },
    { lat: 48.4938277, lng: 20.0037761 },
  ],
  Lenartovce: [
    { lat: 48.291126, lng: 20.333751 },
    { lat: 48.290863, lng: 20.333806 },
    { lat: 48.2907022, lng: 20.3336957 },
    { lat: 48.2904945, lng: 20.3334013 },
    { lat: 48.2905838, lng: 20.3323149 },
    { lat: 48.2910156, lng: 20.3319508 },
    { lat: 48.2913905, lng: 20.3324362 },
    { lat: 48.2915297, lng: 20.3324837 },
    { lat: 48.2916605, lng: 20.3322862 },
    { lat: 48.2918865, lng: 20.3321347 },
    { lat: 48.2923307, lng: 20.3321769 },
    { lat: 48.2926865, lng: 20.3318039 },
    { lat: 48.2928924, lng: 20.3310784 },
    { lat: 48.2930082, lng: 20.3309013 },
    { lat: 48.2930549, lng: 20.3306316 },
    { lat: 48.2932858, lng: 20.3304277 },
    { lat: 48.2934041, lng: 20.3302015 },
    { lat: 48.2935294, lng: 20.3301247 },
    { lat: 48.2938056, lng: 20.3303005 },
    { lat: 48.2941393, lng: 20.3301671 },
    { lat: 48.2950943, lng: 20.3292455 },
    { lat: 48.2952389, lng: 20.328861 },
    { lat: 48.2954066, lng: 20.3285986 },
    { lat: 48.2953284, lng: 20.3282434 },
    { lat: 48.2953401, lng: 20.328079 },
    { lat: 48.2956822, lng: 20.327415 },
    { lat: 48.2955024, lng: 20.3267373 },
    { lat: 48.2959425, lng: 20.3257814 },
    { lat: 48.2960773, lng: 20.3257745 },
    { lat: 48.2962547, lng: 20.3258759 },
    { lat: 48.2965827, lng: 20.3257366 },
    { lat: 48.2976755, lng: 20.3257462 },
    { lat: 48.2980201, lng: 20.3247736 },
    { lat: 48.2979809, lng: 20.3244089 },
    { lat: 48.2980714, lng: 20.3243025 },
    { lat: 48.2985017, lng: 20.3241609 },
    { lat: 48.2987414, lng: 20.3239551 },
    { lat: 48.2988365, lng: 20.3239673 },
    { lat: 48.2988915, lng: 20.32428 },
    { lat: 48.2990286, lng: 20.3244699 },
    { lat: 48.2990929, lng: 20.3244737 },
    { lat: 48.2992565, lng: 20.3239659 },
    { lat: 48.2993532, lng: 20.3239043 },
    { lat: 48.2995605, lng: 20.3240795 },
    { lat: 48.2997459, lng: 20.3240111 },
    { lat: 48.3001528, lng: 20.3235334 },
    { lat: 48.3004215, lng: 20.3232893 },
    { lat: 48.3005888, lng: 20.3232226 },
    { lat: 48.3009391, lng: 20.3233083 },
    { lat: 48.3013107, lng: 20.3231924 },
    { lat: 48.3014782, lng: 20.3232155 },
    { lat: 48.3017673, lng: 20.3235758 },
    { lat: 48.3020347, lng: 20.3237337 },
    { lat: 48.3021427, lng: 20.3238741 },
    { lat: 48.3026895, lng: 20.3239458 },
    { lat: 48.302914, lng: 20.3217208 },
    { lat: 48.3034033, lng: 20.321765 },
    { lat: 48.3043702, lng: 20.3148245 },
    { lat: 48.3045274, lng: 20.3144664 },
    { lat: 48.3045203, lng: 20.3144592 },
    { lat: 48.304673, lng: 20.3141076 },
    { lat: 48.3046403, lng: 20.3138749 },
    { lat: 48.3043561, lng: 20.3138077 },
    { lat: 48.3045426, lng: 20.3129401 },
    { lat: 48.3045562, lng: 20.3125137 },
    { lat: 48.304674, lng: 20.3121308 },
    { lat: 48.3051836, lng: 20.3112597 },
    { lat: 48.3052243, lng: 20.3112621 },
    { lat: 48.305175, lng: 20.3111773 },
    { lat: 48.3085332, lng: 20.3024111 },
    { lat: 48.3086528, lng: 20.3019027 },
    { lat: 48.3086414, lng: 20.3015291 },
    { lat: 48.3089866, lng: 20.301289 },
    { lat: 48.3104786, lng: 20.3005679 },
    { lat: 48.3120693, lng: 20.3006103 },
    { lat: 48.313439, lng: 20.300955 },
    { lat: 48.3142218, lng: 20.3007585 },
    { lat: 48.3142527, lng: 20.3007395 },
    { lat: 48.3142661, lng: 20.2997406 },
    { lat: 48.3170213, lng: 20.2978156 },
    { lat: 48.316507, lng: 20.2960582 },
    { lat: 48.3166487, lng: 20.2912689 },
    { lat: 48.3181096, lng: 20.2903067 },
    { lat: 48.3175699, lng: 20.2890774 },
    { lat: 48.3155167, lng: 20.2901872 },
    { lat: 48.3131984, lng: 20.2910253 },
    { lat: 48.3074141, lng: 20.2911415 },
    { lat: 48.3072696, lng: 20.2911873 },
    { lat: 48.3069621, lng: 20.2883449 },
    { lat: 48.3069485, lng: 20.2873474 },
    { lat: 48.3068566, lng: 20.2870468 },
    { lat: 48.3065663, lng: 20.2869693 },
    { lat: 48.3064031, lng: 20.2869959 },
    { lat: 48.3051823, lng: 20.2876819 },
    { lat: 48.3027429, lng: 20.2892747 },
    { lat: 48.3024018, lng: 20.2894458 },
    { lat: 48.3020401, lng: 20.2895247 },
    { lat: 48.3017047, lng: 20.2894463 },
    { lat: 48.2993879, lng: 20.2884229 },
    { lat: 48.2983301, lng: 20.2880176 },
    { lat: 48.2976746, lng: 20.2884725 },
    { lat: 48.2968347, lng: 20.2889026 },
    { lat: 48.2959188, lng: 20.2891518 },
    { lat: 48.2914102, lng: 20.2898853 },
    { lat: 48.2909474, lng: 20.2901107 },
    { lat: 48.2880814, lng: 20.2934471 },
    { lat: 48.2878869, lng: 20.2938241 },
    { lat: 48.2866347, lng: 20.3014324 },
    { lat: 48.2848169, lng: 20.3054264 },
    { lat: 48.2847341, lng: 20.3057795 },
    { lat: 48.2847492, lng: 20.3063606 },
    { lat: 48.2849655, lng: 20.30759 },
    { lat: 48.2866296, lng: 20.3150633 },
    { lat: 48.2872919, lng: 20.3182722 },
    { lat: 48.2867228, lng: 20.319378 },
    { lat: 48.2873877, lng: 20.3234237 },
    { lat: 48.2872667, lng: 20.3255334 },
    { lat: 48.2873644, lng: 20.3260977 },
    { lat: 48.2877627, lng: 20.3268373 },
    { lat: 48.287998, lng: 20.3280321 },
    { lat: 48.288447, lng: 20.3281 },
    { lat: 48.288512, lng: 20.3286 },
    { lat: 48.288505, lng: 20.330644 },
    { lat: 48.28841, lng: 20.331099 },
    { lat: 48.288224, lng: 20.331654 },
    { lat: 48.287498, lng: 20.332834 },
    { lat: 48.287418, lng: 20.333149 },
    { lat: 48.287405, lng: 20.333669 },
    { lat: 48.287672, lng: 20.335755 },
    { lat: 48.2876963, lng: 20.3357268 },
    { lat: 48.288872, lng: 20.33485 },
    { lat: 48.288979, lng: 20.334852 },
    { lat: 48.28901, lng: 20.334745 },
    { lat: 48.289041, lng: 20.334502 },
    { lat: 48.289092, lng: 20.334332 },
    { lat: 48.289147, lng: 20.334206 },
    { lat: 48.289205, lng: 20.334105 },
    { lat: 48.289266, lng: 20.334037 },
    { lat: 48.289331, lng: 20.334028 },
    { lat: 48.289389, lng: 20.334056 },
    { lat: 48.289443, lng: 20.334112 },
    { lat: 48.289492, lng: 20.334172 },
    { lat: 48.289538, lng: 20.334216 },
    { lat: 48.289585, lng: 20.334244 },
    { lat: 48.289636, lng: 20.334267 },
    { lat: 48.289693, lng: 20.334287 },
    { lat: 48.289751, lng: 20.334307 },
    { lat: 48.289811, lng: 20.334326 },
    { lat: 48.289889, lng: 20.33436 },
    { lat: 48.289963, lng: 20.334408 },
    { lat: 48.289945, lng: 20.334639 },
    { lat: 48.289875, lng: 20.335037 },
    { lat: 48.289939, lng: 20.335226 },
    { lat: 48.289965, lng: 20.335267 },
    { lat: 48.289996, lng: 20.335314 },
    { lat: 48.290024, lng: 20.335359 },
    { lat: 48.290054, lng: 20.335395 },
    { lat: 48.290123, lng: 20.335476 },
    { lat: 48.290154, lng: 20.335506 },
    { lat: 48.290184, lng: 20.335536 },
    { lat: 48.29025, lng: 20.335592 },
    { lat: 48.290312, lng: 20.335655 },
    { lat: 48.290344, lng: 20.335683 },
    { lat: 48.290377, lng: 20.335712 },
    { lat: 48.29041, lng: 20.335741 },
    { lat: 48.290443, lng: 20.33577 },
    { lat: 48.290537, lng: 20.335841 },
    { lat: 48.290604, lng: 20.335793 },
    { lat: 48.290687, lng: 20.33563 },
    { lat: 48.290731, lng: 20.335554 },
    { lat: 48.290798, lng: 20.335472 },
    { lat: 48.290876, lng: 20.335362 },
    { lat: 48.290942, lng: 20.335212 },
    { lat: 48.290962, lng: 20.335122 },
    { lat: 48.290966, lng: 20.335015 },
    { lat: 48.29096, lng: 20.334764 },
    { lat: 48.290974, lng: 20.334671 },
    { lat: 48.291017, lng: 20.334627 },
    { lat: 48.291094, lng: 20.334597 },
    { lat: 48.291171, lng: 20.334604 },
    { lat: 48.291305, lng: 20.334655 },
    { lat: 48.291498, lng: 20.334613 },
    { lat: 48.291612, lng: 20.334552 },
    { lat: 48.291668, lng: 20.334437 },
    { lat: 48.291701, lng: 20.334315 },
    { lat: 48.291699, lng: 20.33421 },
    { lat: 48.291678, lng: 20.334135 },
    { lat: 48.291636, lng: 20.33407 },
    { lat: 48.291589, lng: 20.33405 },
    { lat: 48.291539, lng: 20.334069 },
    { lat: 48.291469, lng: 20.334174 },
    { lat: 48.29138, lng: 20.334229 },
    { lat: 48.291325, lng: 20.334215 },
    { lat: 48.291238, lng: 20.334069 },
    { lat: 48.291175, lng: 20.333936 },
    { lat: 48.291126, lng: 20.333751 },
  ],
  Čerenčany: [
    { lat: 48.4255479, lng: 19.9953535 },
    { lat: 48.4246435, lng: 19.9926022 },
    { lat: 48.4241767, lng: 19.9917581 },
    { lat: 48.4241393, lng: 19.9914095 },
    { lat: 48.4237938, lng: 19.9908565 },
    { lat: 48.4234693, lng: 19.9899738 },
    { lat: 48.423134, lng: 19.9894442 },
    { lat: 48.4229378, lng: 19.9893859 },
    { lat: 48.4228456, lng: 19.9892819 },
    { lat: 48.4226418, lng: 19.9888335 },
    { lat: 48.4224975, lng: 19.9878404 },
    { lat: 48.4222895, lng: 19.9872907 },
    { lat: 48.4222712, lng: 19.9870693 },
    { lat: 48.4223542, lng: 19.986899 },
    { lat: 48.4224364, lng: 19.9864677 },
    { lat: 48.4224567, lng: 19.9855384 },
    { lat: 48.4224214, lng: 19.9850715 },
    { lat: 48.4222088, lng: 19.9842002 },
    { lat: 48.4219661, lng: 19.9828806 },
    { lat: 48.4218076, lng: 19.9826975 },
    { lat: 48.4213831, lng: 19.980947 },
    { lat: 48.4213563, lng: 19.9808148 },
    { lat: 48.4208447, lng: 19.9806497 },
    { lat: 48.4203417, lng: 19.9801603 },
    { lat: 48.4203116, lng: 19.9800557 },
    { lat: 48.4187186, lng: 19.9778674 },
    { lat: 48.4182335, lng: 19.9773431 },
    { lat: 48.4124316, lng: 19.9784792 },
    { lat: 48.4125002, lng: 19.9806341 },
    { lat: 48.4089472, lng: 19.9838931 },
    { lat: 48.4089792, lng: 19.984035 },
    { lat: 48.4085983, lng: 19.9843007 },
    { lat: 48.4082493, lng: 19.9844371 },
    { lat: 48.4080191, lng: 19.9848557 },
    { lat: 48.4076414, lng: 19.9851962 },
    { lat: 48.4065862, lng: 19.9857377 },
    { lat: 48.4060468, lng: 19.9863538 },
    { lat: 48.4057617, lng: 19.9859793 },
    { lat: 48.4057016, lng: 19.9857297 },
    { lat: 48.4052844, lng: 19.9850224 },
    { lat: 48.4050064, lng: 19.9847614 },
    { lat: 48.4040034, lng: 19.9870786 },
    { lat: 48.4031867, lng: 19.9883067 },
    { lat: 48.4025858, lng: 19.9876254 },
    { lat: 48.4019936, lng: 19.9878505 },
    { lat: 48.4016538, lng: 19.9882334 },
    { lat: 48.4010258, lng: 19.989235 },
    { lat: 48.4007575, lng: 19.9890673 },
    { lat: 48.4004064, lng: 19.989134 },
    { lat: 48.4003121, lng: 19.9893557 },
    { lat: 48.4003634, lng: 19.9897425 },
    { lat: 48.3995046, lng: 19.9906941 },
    { lat: 48.3995198, lng: 19.9903878 },
    { lat: 48.3993345, lng: 19.9901579 },
    { lat: 48.3992807, lng: 19.9899791 },
    { lat: 48.3993324, lng: 19.9897905 },
    { lat: 48.3996404, lng: 19.9896932 },
    { lat: 48.3999265, lng: 19.9891842 },
    { lat: 48.3997905, lng: 19.9890869 },
    { lat: 48.3996197, lng: 19.9891461 },
    { lat: 48.3994081, lng: 19.9890992 },
    { lat: 48.3992025, lng: 19.9888472 },
    { lat: 48.3989879, lng: 19.988751 },
    { lat: 48.3986635, lng: 19.989181 },
    { lat: 48.3985829, lng: 19.9894074 },
    { lat: 48.3985886, lng: 19.9898354 },
    { lat: 48.3986743, lng: 19.9899967 },
    { lat: 48.3987734, lng: 19.9900231 },
    { lat: 48.3988753, lng: 19.9907564 },
    { lat: 48.3988554, lng: 19.9910071 },
    { lat: 48.3984642, lng: 19.9909989 },
    { lat: 48.3985488, lng: 19.9913482 },
    { lat: 48.3985371, lng: 19.9917669 },
    { lat: 48.3987926, lng: 19.9920038 },
    { lat: 48.3989669, lng: 19.9918097 },
    { lat: 48.3991832, lng: 19.9917547 },
    { lat: 48.3992295, lng: 19.9919978 },
    { lat: 48.3991076, lng: 19.9921522 },
    { lat: 48.3990253, lng: 19.9924177 },
    { lat: 48.3988842, lng: 19.992546 },
    { lat: 48.3987948, lng: 19.9929122 },
    { lat: 48.3986107, lng: 19.9929527 },
    { lat: 48.3983428, lng: 19.9931724 },
    { lat: 48.3981946, lng: 19.9931049 },
    { lat: 48.3981, lng: 19.992087 },
    { lat: 48.3979401, lng: 19.9923035 },
    { lat: 48.3978131, lng: 19.9921462 },
    { lat: 48.3977563, lng: 19.9918673 },
    { lat: 48.3979649, lng: 19.9917666 },
    { lat: 48.3980274, lng: 19.9914835 },
    { lat: 48.3980075, lng: 19.9911455 },
    { lat: 48.3977761, lng: 19.9911905 },
    { lat: 48.3976014, lng: 19.9909476 },
    { lat: 48.3974155, lng: 19.9909451 },
    { lat: 48.3974059, lng: 19.9910931 },
    { lat: 48.3971419, lng: 19.9910019 },
    { lat: 48.3971244, lng: 19.9908264 },
    { lat: 48.3970931, lng: 19.9908914 },
    { lat: 48.3968959, lng: 19.9908601 },
    { lat: 48.3967995, lng: 19.9906286 },
    { lat: 48.3965947, lng: 19.9907424 },
    { lat: 48.3963907, lng: 19.9910049 },
    { lat: 48.3959368, lng: 19.9906652 },
    { lat: 48.3952254, lng: 19.9909955 },
    { lat: 48.3950531, lng: 19.9911303 },
    { lat: 48.3946018, lng: 19.991031 },
    { lat: 48.3942638, lng: 19.9911936 },
    { lat: 48.3941817, lng: 19.9915707 },
    { lat: 48.3942113, lng: 19.9928046 },
    { lat: 48.3943356, lng: 19.9931391 },
    { lat: 48.3941051, lng: 19.9933878 },
    { lat: 48.3939851, lng: 19.9934265 },
    { lat: 48.3937428, lng: 19.9934506 },
    { lat: 48.3934598, lng: 19.9932563 },
    { lat: 48.3933504, lng: 19.9933883 },
    { lat: 48.3928185, lng: 19.9949097 },
    { lat: 48.3929559, lng: 19.9949899 },
    { lat: 48.3930321, lng: 19.9951113 },
    { lat: 48.3933258, lng: 19.9947821 },
    { lat: 48.3937712, lng: 19.9948763 },
    { lat: 48.3939089, lng: 19.9950702 },
    { lat: 48.3941555, lng: 19.9959474 },
    { lat: 48.3942143, lng: 19.9959816 },
    { lat: 48.3941932, lng: 19.9960686 },
    { lat: 48.395437, lng: 19.9967934 },
    { lat: 48.3957176, lng: 19.9968263 },
    { lat: 48.397418, lng: 19.9965139 },
    { lat: 48.3978692, lng: 19.9965547 },
    { lat: 48.3983031, lng: 19.9966681 },
    { lat: 48.3987273, lng: 19.9968703 },
    { lat: 48.3993348, lng: 19.9972284 },
    { lat: 48.3995021, lng: 19.9974 },
    { lat: 48.400472, lng: 19.9990379 },
    { lat: 48.4007437, lng: 20.0000589 },
    { lat: 48.400789, lng: 20.0006519 },
    { lat: 48.4010542, lng: 20.0008235 },
    { lat: 48.4010703, lng: 20.0011556 },
    { lat: 48.4013413, lng: 20.0020544 },
    { lat: 48.4013267, lng: 20.0021611 },
    { lat: 48.4012087, lng: 20.0023303 },
    { lat: 48.4013087, lng: 20.0026427 },
    { lat: 48.4012854, lng: 20.002757 },
    { lat: 48.40136, lng: 20.0027676 },
    { lat: 48.401377, lng: 20.0028752 },
    { lat: 48.4014564, lng: 20.0029446 },
    { lat: 48.401344, lng: 20.003297 },
    { lat: 48.4015479, lng: 20.0034818 },
    { lat: 48.4016298, lng: 20.0036389 },
    { lat: 48.4015604, lng: 20.0037561 },
    { lat: 48.4016224, lng: 20.0039168 },
    { lat: 48.4015684, lng: 20.0040808 },
    { lat: 48.4016647, lng: 20.0041712 },
    { lat: 48.4014781, lng: 20.0046115 },
    { lat: 48.4015684, lng: 20.0048018 },
    { lat: 48.4015502, lng: 20.0049063 },
    { lat: 48.4016355, lng: 20.0048972 },
    { lat: 48.4016823, lng: 20.0049959 },
    { lat: 48.4019033, lng: 20.0049739 },
    { lat: 48.4018932, lng: 20.0051872 },
    { lat: 48.4017755, lng: 20.005416 },
    { lat: 48.4014967, lng: 20.0056948 },
    { lat: 48.4014934, lng: 20.0059142 },
    { lat: 48.4013903, lng: 20.0063547 },
    { lat: 48.4013852, lng: 20.0066654 },
    { lat: 48.4016524, lng: 20.007041 },
    { lat: 48.4018538, lng: 20.0076845 },
    { lat: 48.4019611, lng: 20.0077934 },
    { lat: 48.402073, lng: 20.008289 },
    { lat: 48.4021449, lng: 20.0083652 },
    { lat: 48.4022029, lng: 20.0085663 },
    { lat: 48.402335, lng: 20.0085985 },
    { lat: 48.402355, lng: 20.0088313 },
    { lat: 48.4025182, lng: 20.0090431 },
    { lat: 48.402498, lng: 20.0092874 },
    { lat: 48.4025368, lng: 20.0094848 },
    { lat: 48.4026743, lng: 20.0096235 },
    { lat: 48.402671, lng: 20.0097369 },
    { lat: 48.4028266, lng: 20.0100561 },
    { lat: 48.4028806, lng: 20.0104601 },
    { lat: 48.4029744, lng: 20.0104395 },
    { lat: 48.4030486, lng: 20.0106505 },
    { lat: 48.4031924, lng: 20.0105897 },
    { lat: 48.4032871, lng: 20.0106421 },
    { lat: 48.4033232, lng: 20.010889 },
    { lat: 48.4033885, lng: 20.0109941 },
    { lat: 48.4035183, lng: 20.0110261 },
    { lat: 48.4036295, lng: 20.0109638 },
    { lat: 48.4038096, lng: 20.011201 },
    { lat: 48.4041416, lng: 20.0122448 },
    { lat: 48.4041943, lng: 20.0122915 },
    { lat: 48.4043423, lng: 20.012971 },
    { lat: 48.4044273, lng: 20.0129842 },
    { lat: 48.4045732, lng: 20.0132664 },
    { lat: 48.4045669, lng: 20.0133861 },
    { lat: 48.404695, lng: 20.0136473 },
    { lat: 48.404793, lng: 20.0140206 },
    { lat: 48.4052909, lng: 20.0146141 },
    { lat: 48.405346, lng: 20.0147439 },
    { lat: 48.4056373, lng: 20.0148681 },
    { lat: 48.4056836, lng: 20.0149541 },
    { lat: 48.4060026, lng: 20.0149059 },
    { lat: 48.4061721, lng: 20.0149692 },
    { lat: 48.4062319, lng: 20.0150544 },
    { lat: 48.4063154, lng: 20.0149755 },
    { lat: 48.4065342, lng: 20.0149807 },
    { lat: 48.4067901, lng: 20.0148519 },
    { lat: 48.4070301, lng: 20.0149338 },
    { lat: 48.4072329, lng: 20.0148838 },
    { lat: 48.4075024, lng: 20.014915 },
    { lat: 48.407552, lng: 20.0148104 },
    { lat: 48.4076498, lng: 20.0148557 },
    { lat: 48.4077948, lng: 20.0150891 },
    { lat: 48.4079415, lng: 20.015233 },
    { lat: 48.408442, lng: 20.0142997 },
    { lat: 48.4089369, lng: 20.0136097 },
    { lat: 48.4092326, lng: 20.0133268 },
    { lat: 48.4096479, lng: 20.0127564 },
    { lat: 48.4100767, lng: 20.0123428 },
    { lat: 48.4119759, lng: 20.0101133 },
    { lat: 48.4132245, lng: 20.0094799 },
    { lat: 48.4146227, lng: 20.0080943 },
    { lat: 48.4155914, lng: 20.0063715 },
    { lat: 48.4159461, lng: 20.0051404 },
    { lat: 48.4161609, lng: 20.0047523 },
    { lat: 48.4162756, lng: 20.0043475 },
    { lat: 48.4165114, lng: 20.0039021 },
    { lat: 48.4170685, lng: 20.0025982 },
    { lat: 48.4176354, lng: 20.001642 },
    { lat: 48.4195732, lng: 20.0004698 },
    { lat: 48.4218835, lng: 19.9986969 },
    { lat: 48.4233394, lng: 19.9974011 },
    { lat: 48.4246208, lng: 19.9968797 },
    { lat: 48.4251379, lng: 19.9965866 },
    { lat: 48.4252298, lng: 19.9962232 },
    { lat: 48.4255584, lng: 19.9956299 },
    { lat: 48.4255479, lng: 19.9953535 },
  ],
  GemerskéMichalovce: [
    { lat: 48.411851, lng: 20.2064627 },
    { lat: 48.4119497, lng: 20.2067044 },
    { lat: 48.4129499, lng: 20.2084979 },
    { lat: 48.4136002, lng: 20.2086179 },
    { lat: 48.4145195, lng: 20.209297 },
    { lat: 48.4151949, lng: 20.2094537 },
    { lat: 48.4157602, lng: 20.2096786 },
    { lat: 48.4159582, lng: 20.208663 },
    { lat: 48.4162774, lng: 20.2074459 },
    { lat: 48.4162945, lng: 20.2071154 },
    { lat: 48.4167325, lng: 20.2059056 },
    { lat: 48.4167371, lng: 20.2054871 },
    { lat: 48.4165423, lng: 20.2052092 },
    { lat: 48.4165033, lng: 20.2049957 },
    { lat: 48.4164808, lng: 20.2046602 },
    { lat: 48.4165302, lng: 20.204381 },
    { lat: 48.4166738, lng: 20.2041431 },
    { lat: 48.416878, lng: 20.2039509 },
    { lat: 48.4174208, lng: 20.2037267 },
    { lat: 48.4181903, lng: 20.2038458 },
    { lat: 48.4187102, lng: 20.2036936 },
    { lat: 48.4188608, lng: 20.2037161 },
    { lat: 48.4192598, lng: 20.2034965 },
    { lat: 48.4195024, lng: 20.2034496 },
    { lat: 48.4200174, lng: 20.2030986 },
    { lat: 48.4207825, lng: 20.2021008 },
    { lat: 48.4215047, lng: 20.2018773 },
    { lat: 48.4221675, lng: 20.2019798 },
    { lat: 48.422336, lng: 20.2020783 },
    { lat: 48.4225877, lng: 20.2023462 },
    { lat: 48.4230793, lng: 20.2031954 },
    { lat: 48.4233152, lng: 20.2034612 },
    { lat: 48.4236827, lng: 20.2036381 },
    { lat: 48.4238655, lng: 20.2036711 },
    { lat: 48.4253049, lng: 20.2034225 },
    { lat: 48.4265981, lng: 20.202867 },
    { lat: 48.4267454, lng: 20.2028614 },
    { lat: 48.426989, lng: 20.2029804 },
    { lat: 48.4272014, lng: 20.2032742 },
    { lat: 48.427647, lng: 20.2035182 },
    { lat: 48.4283624, lng: 20.2047503 },
    { lat: 48.4285071, lng: 20.2051039 },
    { lat: 48.4285343, lng: 20.2054692 },
    { lat: 48.4284763, lng: 20.206045 },
    { lat: 48.428174, lng: 20.2069247 },
    { lat: 48.4282984, lng: 20.2077233 },
    { lat: 48.4283114, lng: 20.2091151 },
    { lat: 48.4289209, lng: 20.2099861 },
    { lat: 48.4292554, lng: 20.2105766 },
    { lat: 48.4300735, lng: 20.2136665 },
    { lat: 48.4295382, lng: 20.2156229 },
    { lat: 48.4295806, lng: 20.216145 },
    { lat: 48.4303776, lng: 20.2173133 },
    { lat: 48.431069, lng: 20.2185461 },
    { lat: 48.4314801, lng: 20.2191344 },
    { lat: 48.4331047, lng: 20.2209435 },
    { lat: 48.4337386, lng: 20.2218082 },
    { lat: 48.4344614, lng: 20.2225592 },
    { lat: 48.4353247, lng: 20.2232762 },
    { lat: 48.4357, lng: 20.2235079 },
    { lat: 48.4381755, lng: 20.2247132 },
    { lat: 48.4389378, lng: 20.2257214 },
    { lat: 48.4393408, lng: 20.2263953 },
    { lat: 48.4396799, lng: 20.2270975 },
    { lat: 48.4404258, lng: 20.2283126 },
    { lat: 48.4406881, lng: 20.2286507 },
    { lat: 48.4412024, lng: 20.2290057 },
    { lat: 48.4415633, lng: 20.2291316 },
    { lat: 48.4421139, lng: 20.2290428 },
    { lat: 48.4431033, lng: 20.2291686 },
    { lat: 48.4436463, lng: 20.2294554 },
    { lat: 48.4438928, lng: 20.2297879 },
    { lat: 48.4440133, lng: 20.2302938 },
    { lat: 48.4442179, lng: 20.2308181 },
    { lat: 48.4442951, lng: 20.2316504 },
    { lat: 48.44424, lng: 20.2328635 },
    { lat: 48.4437418, lng: 20.2342313 },
    { lat: 48.443524, lng: 20.2353255 },
    { lat: 48.443387, lng: 20.23636 },
    { lat: 48.443754, lng: 20.236501 },
    { lat: 48.443763, lng: 20.23651 },
    { lat: 48.443775, lng: 20.236491 },
    { lat: 48.44377, lng: 20.235975 },
    { lat: 48.44384, lng: 20.235446 },
    { lat: 48.444225, lng: 20.234646 },
    { lat: 48.444504, lng: 20.234187 },
    { lat: 48.444721, lng: 20.233579 },
    { lat: 48.445014, lng: 20.232629 },
    { lat: 48.445079, lng: 20.232291 },
    { lat: 48.445331, lng: 20.23169 },
    { lat: 48.445377, lng: 20.231464 },
    { lat: 48.445417, lng: 20.231062 },
    { lat: 48.445391, lng: 20.230586 },
    { lat: 48.4454, lng: 20.229971 },
    { lat: 48.445372, lng: 20.229503 },
    { lat: 48.445332, lng: 20.228956 },
    { lat: 48.44535, lng: 20.2284 },
    { lat: 48.445376, lng: 20.22802 },
    { lat: 48.445463, lng: 20.2278 },
    { lat: 48.445947, lng: 20.227291 },
    { lat: 48.446355, lng: 20.226725 },
    { lat: 48.446819, lng: 20.226101 },
    { lat: 48.447214, lng: 20.225562 },
    { lat: 48.44734, lng: 20.225444 },
    { lat: 48.447459, lng: 20.225387 },
    { lat: 48.447703, lng: 20.22542 },
    { lat: 48.448015, lng: 20.225291 },
    { lat: 48.448082, lng: 20.225251 },
    { lat: 48.448161, lng: 20.225178 },
    { lat: 48.4483, lng: 20.224973 },
    { lat: 48.4475823, lng: 20.2237644 },
    { lat: 48.4471316, lng: 20.2226054 },
    { lat: 48.4469545, lng: 20.2222085 },
    { lat: 48.4465774, lng: 20.2215921 },
    { lat: 48.4463051, lng: 20.2208966 },
    { lat: 48.4459413, lng: 20.2203943 },
    { lat: 48.4455303, lng: 20.2199749 },
    { lat: 48.4453671, lng: 20.2197535 },
    { lat: 48.4453828, lng: 20.2196925 },
    { lat: 48.445039, lng: 20.2193224 },
    { lat: 48.4441566, lng: 20.2179054 },
    { lat: 48.443953, lng: 20.2174904 },
    { lat: 48.4437956, lng: 20.21682 },
    { lat: 48.4432053, lng: 20.2153028 },
    { lat: 48.442846, lng: 20.2142365 },
    { lat: 48.4426936, lng: 20.2132748 },
    { lat: 48.4425785, lng: 20.2128465 },
    { lat: 48.4421724, lng: 20.2086317 },
    { lat: 48.4423193, lng: 20.2083051 },
    { lat: 48.4417542, lng: 20.2071646 },
    { lat: 48.4405039, lng: 20.2032731 },
    { lat: 48.4401499, lng: 20.2023657 },
    { lat: 48.4396291, lng: 20.2016881 },
    { lat: 48.4392673, lng: 20.2014301 },
    { lat: 48.4386544, lng: 20.2008627 },
    { lat: 48.4383767, lng: 20.2007347 },
    { lat: 48.4380007, lng: 20.2002358 },
    { lat: 48.4391452, lng: 20.1988732 },
    { lat: 48.439781, lng: 20.1983801 },
    { lat: 48.4391124, lng: 20.1948166 },
    { lat: 48.4374802, lng: 20.1919337 },
    { lat: 48.436679, lng: 20.1910366 },
    { lat: 48.4368844, lng: 20.1892022 },
    { lat: 48.4370314, lng: 20.1885759 },
    { lat: 48.4366096, lng: 20.1873066 },
    { lat: 48.4365351, lng: 20.1869424 },
    { lat: 48.436628, lng: 20.1865945 },
    { lat: 48.436796, lng: 20.186289 },
    { lat: 48.436516, lng: 20.1862756 },
    { lat: 48.436011, lng: 20.1856775 },
    { lat: 48.4351406, lng: 20.1853204 },
    { lat: 48.4343357, lng: 20.1851916 },
    { lat: 48.4335709, lng: 20.1840503 },
    { lat: 48.432332, lng: 20.1832427 },
    { lat: 48.4319222, lng: 20.1836667 },
    { lat: 48.4307829, lng: 20.1848453 },
    { lat: 48.430513, lng: 20.1849296 },
    { lat: 48.4303545, lng: 20.1849791 },
    { lat: 48.4297234, lng: 20.184465 },
    { lat: 48.428798, lng: 20.1834458 },
    { lat: 48.4285793, lng: 20.1830806 },
    { lat: 48.42829, lng: 20.1817733 },
    { lat: 48.4282559, lng: 20.1814751 },
    { lat: 48.4284324, lng: 20.1802602 },
    { lat: 48.4284649, lng: 20.1794181 },
    { lat: 48.4284313, lng: 20.1784771 },
    { lat: 48.4280206, lng: 20.1767621 },
    { lat: 48.4279565, lng: 20.1762069 },
    { lat: 48.4279376, lng: 20.1750458 },
    { lat: 48.4277756, lng: 20.1736045 },
    { lat: 48.4271742, lng: 20.1739145 },
    { lat: 48.4266619, lng: 20.1743064 },
    { lat: 48.4265498, lng: 20.1744861 },
    { lat: 48.4262591, lng: 20.1747631 },
    { lat: 48.4262942, lng: 20.1755184 },
    { lat: 48.4262227, lng: 20.1761704 },
    { lat: 48.4260877, lng: 20.1766189 },
    { lat: 48.425667, lng: 20.1767162 },
    { lat: 48.4250753, lng: 20.1767047 },
    { lat: 48.4244593, lng: 20.1762923 },
    { lat: 48.4240872, lng: 20.1761997 },
    { lat: 48.4235091, lng: 20.176221 },
    { lat: 48.4222255, lng: 20.1760079 },
    { lat: 48.4216885, lng: 20.1757623 },
    { lat: 48.4213302, lng: 20.1757855 },
    { lat: 48.4210802, lng: 20.1757034 },
    { lat: 48.4207552, lng: 20.175492 },
    { lat: 48.4205317, lng: 20.1754592 },
    { lat: 48.4204041, lng: 20.1762531 },
    { lat: 48.4203158, lng: 20.1765488 },
    { lat: 48.4201452, lng: 20.1767835 },
    { lat: 48.4200597, lng: 20.1770097 },
    { lat: 48.4199442, lng: 20.177578 },
    { lat: 48.4206066, lng: 20.1787529 },
    { lat: 48.4208486, lng: 20.1794123 },
    { lat: 48.4213829, lng: 20.1802052 },
    { lat: 48.421514, lng: 20.1805227 },
    { lat: 48.4216709, lng: 20.1810275 },
    { lat: 48.4217084, lng: 20.1814003 },
    { lat: 48.4217918, lng: 20.1816252 },
    { lat: 48.4218351, lng: 20.1820542 },
    { lat: 48.4219833, lng: 20.1827123 },
    { lat: 48.4224923, lng: 20.1839346 },
    { lat: 48.4226049, lng: 20.1844087 },
    { lat: 48.4226119, lng: 20.1852761 },
    { lat: 48.4226886, lng: 20.1855272 },
    { lat: 48.4233018, lng: 20.1865734 },
    { lat: 48.4233642, lng: 20.1868616 },
    { lat: 48.4233743, lng: 20.1872231 },
    { lat: 48.4231962, lng: 20.1879429 },
    { lat: 48.4231458, lng: 20.1884546 },
    { lat: 48.4233763, lng: 20.1890832 },
    { lat: 48.4234134, lng: 20.1893344 },
    { lat: 48.4235259, lng: 20.1920092 },
    { lat: 48.4234873, lng: 20.1924439 },
    { lat: 48.4233182, lng: 20.1931146 },
    { lat: 48.4232227, lng: 20.1941516 },
    { lat: 48.4232026, lng: 20.1952169 },
    { lat: 48.4233658, lng: 20.1962782 },
    { lat: 48.4233855, lng: 20.1974622 },
    { lat: 48.4232355, lng: 20.1981843 },
    { lat: 48.4231053, lng: 20.1985844 },
    { lat: 48.4225749, lng: 20.1978622 },
    { lat: 48.4222207, lng: 20.1970618 },
    { lat: 48.4217329, lng: 20.1963393 },
    { lat: 48.4208827, lng: 20.1963649 },
    { lat: 48.4183569, lng: 20.1971912 },
    { lat: 48.4176634, lng: 20.1973135 },
    { lat: 48.417234, lng: 20.1976394 },
    { lat: 48.4166001, lng: 20.1984243 },
    { lat: 48.4154936, lng: 20.1973209 },
    { lat: 48.4149898, lng: 20.1970675 },
    { lat: 48.4145493, lng: 20.197036 },
    { lat: 48.4139203, lng: 20.1972978 },
    { lat: 48.4135555, lng: 20.1975281 },
    { lat: 48.413434, lng: 20.1972258 },
    { lat: 48.4133167, lng: 20.19733 },
    { lat: 48.4121983, lng: 20.1988945 },
    { lat: 48.4115099, lng: 20.2002607 },
    { lat: 48.4097004, lng: 20.2014746 },
    { lat: 48.4087164, lng: 20.2015966 },
    { lat: 48.408347, lng: 20.2017355 },
    { lat: 48.4088327, lng: 20.2023255 },
    { lat: 48.408899, lng: 20.2025198 },
    { lat: 48.4099093, lng: 20.2038353 },
    { lat: 48.4105302, lng: 20.2049892 },
    { lat: 48.411851, lng: 20.2064627 },
  ],
  Bottovo: [
    { lat: 48.3315448, lng: 20.1293136 },
    { lat: 48.3311226, lng: 20.1292626 },
    { lat: 48.3270541, lng: 20.1270404 },
    { lat: 48.325413, lng: 20.1241596 },
    { lat: 48.3244668, lng: 20.1221821 },
    { lat: 48.3240469, lng: 20.1217522 },
    { lat: 48.323444, lng: 20.1213301 },
    { lat: 48.322661, lng: 20.1209731 },
    { lat: 48.322127, lng: 20.1205835 },
    { lat: 48.3195973, lng: 20.1183126 },
    { lat: 48.3191742, lng: 20.1170052 },
    { lat: 48.3189727, lng: 20.1166002 },
    { lat: 48.3187519, lng: 20.1161895 },
    { lat: 48.3177083, lng: 20.1146376 },
    { lat: 48.3172787, lng: 20.1140527 },
    { lat: 48.3172682, lng: 20.1141531 },
    { lat: 48.3163051, lng: 20.1134679 },
    { lat: 48.3160566, lng: 20.1133996 },
    { lat: 48.3159163, lng: 20.1133283 },
    { lat: 48.3157718, lng: 20.1132132 },
    { lat: 48.3149465, lng: 20.112918 },
    { lat: 48.3140796, lng: 20.1124377 },
    { lat: 48.3138907, lng: 20.1124417 },
    { lat: 48.3136778, lng: 20.1125795 },
    { lat: 48.3129059, lng: 20.1135288 },
    { lat: 48.3122342, lng: 20.113851 },
    { lat: 48.3098567, lng: 20.1138819 },
    { lat: 48.309538, lng: 20.1136951 },
    { lat: 48.3079284, lng: 20.1139925 },
    { lat: 48.3067182, lng: 20.1140941 },
    { lat: 48.3065418, lng: 20.1142113 },
    { lat: 48.3065392, lng: 20.1142579 },
    { lat: 48.3090952, lng: 20.1417446 },
    { lat: 48.3073147, lng: 20.1486941 },
    { lat: 48.3027908, lng: 20.1607806 },
    { lat: 48.302939, lng: 20.1608498 },
    { lat: 48.3038703, lng: 20.1609804 },
    { lat: 48.3051892, lng: 20.16109 },
    { lat: 48.3058143, lng: 20.1614768 },
    { lat: 48.3066764, lng: 20.1617917 },
    { lat: 48.3072236, lng: 20.1621776 },
    { lat: 48.3078978, lng: 20.1631882 },
    { lat: 48.3079024, lng: 20.1633835 },
    { lat: 48.3078037, lng: 20.163891 },
    { lat: 48.3077738, lng: 20.1647634 },
    { lat: 48.3080452, lng: 20.1650358 },
    { lat: 48.309124, lng: 20.1673314 },
    { lat: 48.30981, lng: 20.1680768 },
    { lat: 48.3102842, lng: 20.1684368 },
    { lat: 48.3113018, lng: 20.1696186 },
    { lat: 48.3113501, lng: 20.1694486 },
    { lat: 48.3114008, lng: 20.1694178 },
    { lat: 48.3115027, lng: 20.169595 },
    { lat: 48.3116442, lng: 20.169645 },
    { lat: 48.311723, lng: 20.1695872 },
    { lat: 48.3119753, lng: 20.1697245 },
    { lat: 48.3125057, lng: 20.169612 },
    { lat: 48.3128859, lng: 20.16982 },
    { lat: 48.3131423, lng: 20.16981 },
    { lat: 48.3131676, lng: 20.170129 },
    { lat: 48.3133095, lng: 20.1700725 },
    { lat: 48.3134754, lng: 20.1703857 },
    { lat: 48.3136109, lng: 20.1705312 },
    { lat: 48.3137409, lng: 20.1704025 },
    { lat: 48.3137553, lng: 20.170565 },
    { lat: 48.3138795, lng: 20.1707115 },
    { lat: 48.3139413, lng: 20.1707334 },
    { lat: 48.314042, lng: 20.1706096 },
    { lat: 48.3141004, lng: 20.1706591 },
    { lat: 48.314124, lng: 20.171072 },
    { lat: 48.3141631, lng: 20.171152 },
    { lat: 48.3142859, lng: 20.1710947 },
    { lat: 48.3144648, lng: 20.1708381 },
    { lat: 48.31452, lng: 20.170924 },
    { lat: 48.314585, lng: 20.1709156 },
    { lat: 48.3146694, lng: 20.1706641 },
    { lat: 48.3147833, lng: 20.1707542 },
    { lat: 48.3149084, lng: 20.1706961 },
    { lat: 48.31504, lng: 20.170747 },
    { lat: 48.3151091, lng: 20.1708423 },
    { lat: 48.3151998, lng: 20.170776 },
    { lat: 48.3152237, lng: 20.1709167 },
    { lat: 48.3153834, lng: 20.1707737 },
    { lat: 48.3154733, lng: 20.1707687 },
    { lat: 48.3157884, lng: 20.1709023 },
    { lat: 48.3161314, lng: 20.1714665 },
    { lat: 48.3163292, lng: 20.1714556 },
    { lat: 48.3163857, lng: 20.1715169 },
    { lat: 48.3165808, lng: 20.171468 },
    { lat: 48.3167633, lng: 20.17155 },
    { lat: 48.3169466, lng: 20.1767022 },
    { lat: 48.3169497, lng: 20.1769478 },
    { lat: 48.3273104, lng: 20.173885 },
    { lat: 48.3288386, lng: 20.1734354 },
    { lat: 48.3287166, lng: 20.1727854 },
    { lat: 48.3283595, lng: 20.1713725 },
    { lat: 48.3270143, lng: 20.1672358 },
    { lat: 48.3272328, lng: 20.1642693 },
    { lat: 48.328819, lng: 20.1606271 },
    { lat: 48.3301272, lng: 20.1586187 },
    { lat: 48.3311668, lng: 20.1568499 },
    { lat: 48.3316271, lng: 20.1553228 },
    { lat: 48.3338324, lng: 20.1540743 },
    { lat: 48.336406, lng: 20.1527504 },
    { lat: 48.3378137, lng: 20.151669 },
    { lat: 48.3376676, lng: 20.148587 },
    { lat: 48.337709, lng: 20.1480504 },
    { lat: 48.3376227, lng: 20.1475092 },
    { lat: 48.3372415, lng: 20.146341 },
    { lat: 48.3362433, lng: 20.1448446 },
    { lat: 48.3353362, lng: 20.1443987 },
    { lat: 48.3349359, lng: 20.1454047 },
    { lat: 48.334714, lng: 20.1446056 },
    { lat: 48.3338443, lng: 20.1429496 },
    { lat: 48.3337029, lng: 20.1418291 },
    { lat: 48.3331074, lng: 20.1396085 },
    { lat: 48.3322473, lng: 20.1381602 },
    { lat: 48.3311592, lng: 20.1357365 },
    { lat: 48.3294695, lng: 20.1326558 },
    { lat: 48.329994, lng: 20.131764 },
    { lat: 48.3304824, lng: 20.1310978 },
    { lat: 48.3307048, lng: 20.1306136 },
    { lat: 48.3315448, lng: 20.1293136 },
  ],
  Zádor: [
    { lat: 48.3337297, lng: 20.2628214 },
    { lat: 48.329424, lng: 20.2523984 },
    { lat: 48.3291422, lng: 20.2507525 },
    { lat: 48.328876, lng: 20.24956 },
    { lat: 48.3280593, lng: 20.2485121 },
    { lat: 48.3264839, lng: 20.248351 },
    { lat: 48.3265392, lng: 20.2483 },
    { lat: 48.3264024, lng: 20.2482005 },
    { lat: 48.3264745, lng: 20.2480567 },
    { lat: 48.3270119, lng: 20.2460608 },
    { lat: 48.32758, lng: 20.2437642 },
    { lat: 48.3267168, lng: 20.2433322 },
    { lat: 48.3266827, lng: 20.2431816 },
    { lat: 48.3245496, lng: 20.2412101 },
    { lat: 48.3233796, lng: 20.2400042 },
    { lat: 48.3231562, lng: 20.2398191 },
    { lat: 48.3228398, lng: 20.2394635 },
    { lat: 48.322706, lng: 20.2393485 },
    { lat: 48.3227218, lng: 20.2392972 },
    { lat: 48.3221757, lng: 20.2387422 },
    { lat: 48.321851, lng: 20.2384841 },
    { lat: 48.3216214, lng: 20.2379506 },
    { lat: 48.3205079, lng: 20.2365302 },
    { lat: 48.3196119, lng: 20.2351901 },
    { lat: 48.3194027, lng: 20.2344988 },
    { lat: 48.319209, lng: 20.2341581 },
    { lat: 48.3187525, lng: 20.2336985 },
    { lat: 48.316526, lng: 20.2324692 },
    { lat: 48.3164723, lng: 20.2325374 },
    { lat: 48.3164656, lng: 20.232423 },
    { lat: 48.315778, lng: 20.2320734 },
    { lat: 48.3152171, lng: 20.2316523 },
    { lat: 48.3149674, lng: 20.2314335 },
    { lat: 48.3148723, lng: 20.2315928 },
    { lat: 48.3145634, lng: 20.2323715 },
    { lat: 48.3145868, lng: 20.2326124 },
    { lat: 48.3144726, lng: 20.2328322 },
    { lat: 48.3145064, lng: 20.2333617 },
    { lat: 48.3144003, lng: 20.2342296 },
    { lat: 48.3151128, lng: 20.2359314 },
    { lat: 48.3137162, lng: 20.2386843 },
    { lat: 48.3132515, lng: 20.2387261 },
    { lat: 48.3129401, lng: 20.2393836 },
    { lat: 48.3125322, lng: 20.240539 },
    { lat: 48.3124628, lng: 20.2410505 },
    { lat: 48.312396, lng: 20.2412388 },
    { lat: 48.3119146, lng: 20.2422129 },
    { lat: 48.3117675, lng: 20.2426769 },
    { lat: 48.3118035, lng: 20.2434061 },
    { lat: 48.312502, lng: 20.2462261 },
    { lat: 48.3125887, lng: 20.2471294 },
    { lat: 48.3125797, lng: 20.24769 },
    { lat: 48.3123821, lng: 20.2486633 },
    { lat: 48.3121052, lng: 20.2493484 },
    { lat: 48.3116137, lng: 20.2501228 },
    { lat: 48.3110654, lng: 20.250553 },
    { lat: 48.3105438, lng: 20.2508026 },
    { lat: 48.3098722, lng: 20.2510228 },
    { lat: 48.309657, lng: 20.2516969 },
    { lat: 48.3098824, lng: 20.2516428 },
    { lat: 48.3129331, lng: 20.2546248 },
    { lat: 48.3145539, lng: 20.254937 },
    { lat: 48.3155519, lng: 20.2548853 },
    { lat: 48.3167317, lng: 20.2561118 },
    { lat: 48.3167748, lng: 20.2562615 },
    { lat: 48.3198793, lng: 20.2598915 },
    { lat: 48.3253828, lng: 20.2672602 },
    { lat: 48.3271245, lng: 20.2695935 },
    { lat: 48.327315, lng: 20.2694872 },
    { lat: 48.3280353, lng: 20.268365 },
    { lat: 48.3287236, lng: 20.2675195 },
    { lat: 48.3292224, lng: 20.2667582 },
    { lat: 48.329753, lng: 20.2657382 },
    { lat: 48.3304121, lng: 20.2648669 },
    { lat: 48.3313814, lng: 20.2637268 },
    { lat: 48.3316549, lng: 20.2635254 },
    { lat: 48.3319219, lng: 20.2634378 },
    { lat: 48.3325755, lng: 20.2633644 },
    { lat: 48.3334952, lng: 20.2629022 },
    { lat: 48.3337297, lng: 20.2628214 },
  ],
  Kráľ: [
    { lat: 48.3511552, lng: 20.3841231 },
    { lat: 48.3508739, lng: 20.3835912 },
    { lat: 48.3505318, lng: 20.3832235 },
    { lat: 48.3501944, lng: 20.3825353 },
    { lat: 48.3501765, lng: 20.3818666 },
    { lat: 48.3502571, lng: 20.380635 },
    { lat: 48.3505357, lng: 20.3796063 },
    { lat: 48.3513264, lng: 20.3794629 },
    { lat: 48.3523087, lng: 20.3788864 },
    { lat: 48.353504, lng: 20.3776225 },
    { lat: 48.3539399, lng: 20.3762001 },
    { lat: 48.3542386, lng: 20.3743491 },
    { lat: 48.3542524, lng: 20.3738356 },
    { lat: 48.354923, lng: 20.3730079 },
    { lat: 48.3556379, lng: 20.3727117 },
    { lat: 48.3564305, lng: 20.3726789 },
    { lat: 48.3564697, lng: 20.3722672 },
    { lat: 48.3568312, lng: 20.3720667 },
    { lat: 48.3576953, lng: 20.3719844 },
    { lat: 48.3579267, lng: 20.371853 },
    { lat: 48.3588811, lng: 20.3720984 },
    { lat: 48.3588916, lng: 20.3721988 },
    { lat: 48.3594521, lng: 20.3720698 },
    { lat: 48.3601721, lng: 20.3716987 },
    { lat: 48.3604027, lng: 20.371465 },
    { lat: 48.3607514, lng: 20.3714791 },
    { lat: 48.3611513, lng: 20.3731354 },
    { lat: 48.361334, lng: 20.3736116 },
    { lat: 48.361937, lng: 20.37464 },
    { lat: 48.3620187, lng: 20.3747076 },
    { lat: 48.3623483, lng: 20.3753683 },
    { lat: 48.3626829, lng: 20.3756889 },
    { lat: 48.3630513, lng: 20.3757799 },
    { lat: 48.363391, lng: 20.3759733 },
    { lat: 48.3639893, lng: 20.3766481 },
    { lat: 48.364459, lng: 20.3774913 },
    { lat: 48.3647587, lng: 20.3779046 },
    { lat: 48.3653563, lng: 20.3782094 },
    { lat: 48.3659369, lng: 20.3781385 },
    { lat: 48.3664122, lng: 20.3782291 },
    { lat: 48.3675588, lng: 20.3780588 },
    { lat: 48.3676206, lng: 20.3786051 },
    { lat: 48.3684276, lng: 20.3795217 },
    { lat: 48.3686792, lng: 20.3800568 },
    { lat: 48.3688241, lng: 20.3806594 },
    { lat: 48.3689358, lng: 20.3812173 },
    { lat: 48.3688864, lng: 20.382236 },
    { lat: 48.3687124, lng: 20.3830094 },
    { lat: 48.3686978, lng: 20.3832405 },
    { lat: 48.36882, lng: 20.3836046 },
    { lat: 48.3689833, lng: 20.3838365 },
    { lat: 48.3691944, lng: 20.3839912 },
    { lat: 48.3704799, lng: 20.3844451 },
    { lat: 48.3711644, lng: 20.3839731 },
    { lat: 48.3717744, lng: 20.3833808 },
    { lat: 48.3722201, lng: 20.3827943 },
    { lat: 48.3719861, lng: 20.3823954 },
    { lat: 48.3717407, lng: 20.3821636 },
    { lat: 48.3725802, lng: 20.3812158 },
    { lat: 48.3731938, lng: 20.3815341 },
    { lat: 48.3732082, lng: 20.3814135 },
    { lat: 48.3734958, lng: 20.3813926 },
    { lat: 48.3737735, lng: 20.3811481 },
    { lat: 48.37379, lng: 20.3812263 },
    { lat: 48.3739368, lng: 20.3811232 },
    { lat: 48.3740749, lng: 20.3809649 },
    { lat: 48.3743213, lng: 20.3804606 },
    { lat: 48.3743761, lng: 20.3805408 },
    { lat: 48.3750795, lng: 20.379364 },
    { lat: 48.3755669, lng: 20.3782882 },
    { lat: 48.3759232, lng: 20.3778026 },
    { lat: 48.3765779, lng: 20.3762768 },
    { lat: 48.3760924, lng: 20.3758959 },
    { lat: 48.3754875, lng: 20.3753112 },
    { lat: 48.3750006, lng: 20.3749525 },
    { lat: 48.3735639, lng: 20.3721195 },
    { lat: 48.3734655, lng: 20.3719003 },
    { lat: 48.3733385, lng: 20.3712742 },
    { lat: 48.3731569, lng: 20.3706866 },
    { lat: 48.3730124, lng: 20.3703879 },
    { lat: 48.3727727, lng: 20.3696672 },
    { lat: 48.3725846, lng: 20.3692901 },
    { lat: 48.3720598, lng: 20.367088 },
    { lat: 48.3716785, lng: 20.366202 },
    { lat: 48.3713813, lng: 20.3657084 },
    { lat: 48.370461, lng: 20.3645749 },
    { lat: 48.3703214, lng: 20.3642889 },
    { lat: 48.3698625, lng: 20.3629124 },
    { lat: 48.3693971, lng: 20.3620409 },
    { lat: 48.3682026, lng: 20.3605441 },
    { lat: 48.3671716, lng: 20.3602424 },
    { lat: 48.3669579, lng: 20.3600473 },
    { lat: 48.3665087, lng: 20.3594754 },
    { lat: 48.3655338, lng: 20.3585282 },
    { lat: 48.3646423, lng: 20.3578149 },
    { lat: 48.3640486, lng: 20.3575812 },
    { lat: 48.3637246, lng: 20.3575346 },
    { lat: 48.362169, lng: 20.3573478 },
    { lat: 48.3620121, lng: 20.3572822 },
    { lat: 48.3589926, lng: 20.3556609 },
    { lat: 48.3571333, lng: 20.3547367 },
    { lat: 48.3569223, lng: 20.3545233 },
    { lat: 48.3559202, lng: 20.3540136 },
    { lat: 48.3558258, lng: 20.3538997 },
    { lat: 48.3535545, lng: 20.3543374 },
    { lat: 48.3530211, lng: 20.3551299 },
    { lat: 48.3526785, lng: 20.3554167 },
    { lat: 48.3524745, lng: 20.3554675 },
    { lat: 48.3521315, lng: 20.3554226 },
    { lat: 48.3514105, lng: 20.3552168 },
    { lat: 48.3512176, lng: 20.3555237 },
    { lat: 48.350771, lng: 20.3557987 },
    { lat: 48.3503335, lng: 20.3558958 },
    { lat: 48.3495126, lng: 20.3558457 },
    { lat: 48.3487612, lng: 20.3553498 },
    { lat: 48.3481499, lng: 20.3544568 },
    { lat: 48.3480299, lng: 20.3541505 },
    { lat: 48.3478587, lng: 20.3540087 },
    { lat: 48.3465895, lng: 20.3521886 },
    { lat: 48.3462296, lng: 20.351886 },
    { lat: 48.3458448, lng: 20.3516639 },
    { lat: 48.3455583, lng: 20.3516225 },
    { lat: 48.345577, lng: 20.3515511 },
    { lat: 48.3448384, lng: 20.3514436 },
    { lat: 48.3441864, lng: 20.3510095 },
    { lat: 48.3437065, lng: 20.3510672 },
    { lat: 48.3431972, lng: 20.3505109 },
    { lat: 48.3427459, lng: 20.3489915 },
    { lat: 48.34197, lng: 20.3471418 },
    { lat: 48.340933, lng: 20.3460532 },
    { lat: 48.3401272, lng: 20.3453811 },
    { lat: 48.3398195, lng: 20.3449713 },
    { lat: 48.3396744, lng: 20.3449186 },
    { lat: 48.3385851, lng: 20.3433239 },
    { lat: 48.3384876, lng: 20.343222 },
    { lat: 48.3377256, lng: 20.3428296 },
    { lat: 48.3368973, lng: 20.3425825 },
    { lat: 48.3361575, lng: 20.3417206 },
    { lat: 48.3357624, lng: 20.3417019 },
    { lat: 48.335424, lng: 20.3408812 },
    { lat: 48.3351962, lng: 20.3404684 },
    { lat: 48.3343224, lng: 20.3408143 },
    { lat: 48.3339265, lng: 20.3406315 },
    { lat: 48.3334278, lng: 20.3402315 },
    { lat: 48.3330932, lng: 20.3398214 },
    { lat: 48.3323885, lng: 20.3375592 },
    { lat: 48.3319267, lng: 20.3363499 },
    { lat: 48.3318129, lng: 20.3357151 },
    { lat: 48.3312288, lng: 20.3305788 },
    { lat: 48.3313893, lng: 20.3304539 },
    { lat: 48.3314041, lng: 20.3302841 },
    { lat: 48.3316189, lng: 20.3303069 },
    { lat: 48.3318895, lng: 20.3301139 },
    { lat: 48.3321407, lng: 20.329599 },
    { lat: 48.3320393, lng: 20.3291907 },
    { lat: 48.3314955, lng: 20.3284488 },
    { lat: 48.3313253, lng: 20.3280759 },
    { lat: 48.3312469, lng: 20.3281161 },
    { lat: 48.3311795, lng: 20.3281181 },
    { lat: 48.3307023, lng: 20.3278421 },
    { lat: 48.3302967, lng: 20.3277462 },
    { lat: 48.3299201, lng: 20.3275388 },
    { lat: 48.3299918, lng: 20.3270076 },
    { lat: 48.3279218, lng: 20.3273854 },
    { lat: 48.3279935, lng: 20.327745 },
    { lat: 48.3276747, lng: 20.3281178 },
    { lat: 48.3275008, lng: 20.3279014 },
    { lat: 48.327107, lng: 20.3275978 },
    { lat: 48.3266168, lng: 20.3271032 },
    { lat: 48.3267152, lng: 20.3262802 },
    { lat: 48.326895, lng: 20.3258396 },
    { lat: 48.3272038, lng: 20.3244117 },
    { lat: 48.3258334, lng: 20.3235288 },
    { lat: 48.3249379, lng: 20.3244384 },
    { lat: 48.3244921, lng: 20.325395 },
    { lat: 48.3243305, lng: 20.3258651 },
    { lat: 48.3240115, lng: 20.3261204 },
    { lat: 48.3237872, lng: 20.3262018 },
    { lat: 48.3236496, lng: 20.3261634 },
    { lat: 48.3235155, lng: 20.3259877 },
    { lat: 48.3234445, lng: 20.3257324 },
    { lat: 48.3234301, lng: 20.3243019 },
    { lat: 48.3223882, lng: 20.3246969 },
    { lat: 48.3222057, lng: 20.3245814 },
    { lat: 48.3221177, lng: 20.3242639 },
    { lat: 48.3228774, lng: 20.32293 },
    { lat: 48.3232857, lng: 20.3216296 },
    { lat: 48.3228488, lng: 20.3214888 },
    { lat: 48.3220783, lng: 20.3208828 },
    { lat: 48.3216547, lng: 20.3208774 },
    { lat: 48.3207809, lng: 20.3207406 },
    { lat: 48.3205047, lng: 20.3208167 },
    { lat: 48.3203353, lng: 20.3207754 },
    { lat: 48.3201935, lng: 20.3206393 },
    { lat: 48.3196975, lng: 20.3203944 },
    { lat: 48.3190197, lng: 20.3193128 },
    { lat: 48.3185782, lng: 20.3189672 },
    { lat: 48.3182212, lng: 20.3187706 },
    { lat: 48.317855, lng: 20.3187368 },
    { lat: 48.3176073, lng: 20.3188521 },
    { lat: 48.317297, lng: 20.3190625 },
    { lat: 48.3169655, lng: 20.3194845 },
    { lat: 48.3169213, lng: 20.3197651 },
    { lat: 48.3166903, lng: 20.3204454 },
    { lat: 48.315811, lng: 20.3219993 },
    { lat: 48.3160023, lng: 20.3224006 },
    { lat: 48.3161989, lng: 20.3226171 },
    { lat: 48.3162514, lng: 20.3227455 },
    { lat: 48.3163152, lng: 20.3240585 },
    { lat: 48.3165064, lng: 20.3238484 },
    { lat: 48.3174452, lng: 20.3239499 },
    { lat: 48.3175227, lng: 20.3244642 },
    { lat: 48.3173321, lng: 20.32497 },
    { lat: 48.3172777, lng: 20.3252501 },
    { lat: 48.3172818, lng: 20.3256131 },
    { lat: 48.317321, lng: 20.3257208 },
    { lat: 48.3175918, lng: 20.3259049 },
    { lat: 48.3188183, lng: 20.3257078 },
    { lat: 48.3189435, lng: 20.3257413 },
    { lat: 48.3191576, lng: 20.3260296 },
    { lat: 48.3192299, lng: 20.3263282 },
    { lat: 48.3192948, lng: 20.3263706 },
    { lat: 48.3194946, lng: 20.3262945 },
    { lat: 48.3195898, lng: 20.3263689 },
    { lat: 48.3197736, lng: 20.3267161 },
    { lat: 48.3198993, lng: 20.3268522 },
    { lat: 48.3198397, lng: 20.3271688 },
    { lat: 48.3194545, lng: 20.3273111 },
    { lat: 48.3193328, lng: 20.3274814 },
    { lat: 48.3192762, lng: 20.3276761 },
    { lat: 48.319261, lng: 20.3278048 },
    { lat: 48.3193273, lng: 20.328088 },
    { lat: 48.3194822, lng: 20.3283599 },
    { lat: 48.3195177, lng: 20.3287237 },
    { lat: 48.319829, lng: 20.3296265 },
    { lat: 48.3199893, lng: 20.3297776 },
    { lat: 48.3201877, lng: 20.3297834 },
    { lat: 48.3203739, lng: 20.3303052 },
    { lat: 48.3207136, lng: 20.3314766 },
    { lat: 48.3220563, lng: 20.3352481 },
    { lat: 48.3228622, lng: 20.3382248 },
    { lat: 48.3231848, lng: 20.3391281 },
    { lat: 48.3254895, lng: 20.3440387 },
    { lat: 48.3259038, lng: 20.3454203 },
    { lat: 48.3255695, lng: 20.3464618 },
    { lat: 48.3253718, lng: 20.3475064 },
    { lat: 48.3251373, lng: 20.3483979 },
    { lat: 48.3250759, lng: 20.3488733 },
    { lat: 48.3250598, lng: 20.3495566 },
    { lat: 48.326142, lng: 20.3522073 },
    { lat: 48.3265056, lng: 20.3528658 },
    { lat: 48.3280221, lng: 20.3541549 },
    { lat: 48.332187, lng: 20.367629 },
    { lat: 48.332375, lng: 20.368239 },
    { lat: 48.332518, lng: 20.36867 },
    { lat: 48.332606, lng: 20.36889 },
    { lat: 48.332731, lng: 20.369166 },
    { lat: 48.332838, lng: 20.369438 },
    { lat: 48.333102, lng: 20.370199 },
    { lat: 48.333339, lng: 20.370956 },
    { lat: 48.333474, lng: 20.371345 },
    { lat: 48.333577, lng: 20.371606 },
    { lat: 48.333703, lng: 20.371848 },
    { lat: 48.333802, lng: 20.371935 },
    { lat: 48.333914, lng: 20.371966 },
    { lat: 48.333999, lng: 20.371967 },
    { lat: 48.334193, lng: 20.37193 },
    { lat: 48.334283, lng: 20.371936 },
    { lat: 48.334371, lng: 20.371985 },
    { lat: 48.334449, lng: 20.372053 },
    { lat: 48.334756, lng: 20.372387 },
    { lat: 48.334906, lng: 20.372589 },
    { lat: 48.335159, lng: 20.372954 },
    { lat: 48.335435, lng: 20.373376 },
    { lat: 48.335835, lng: 20.373931 },
    { lat: 48.336176, lng: 20.374372 },
    { lat: 48.336285, lng: 20.374514 },
    { lat: 48.336776, lng: 20.375201 },
    { lat: 48.337242, lng: 20.375736 },
    { lat: 48.337622, lng: 20.376191 },
    { lat: 48.33792, lng: 20.376575 },
    { lat: 48.338198, lng: 20.376938 },
    { lat: 48.338616, lng: 20.377431 },
    { lat: 48.338998, lng: 20.377904 },
    { lat: 48.339351, lng: 20.378368 },
    { lat: 48.339596, lng: 20.378703 },
    { lat: 48.33971, lng: 20.378849 },
    { lat: 48.339818, lng: 20.378979 },
    { lat: 48.339962, lng: 20.379136 },
    { lat: 48.340578, lng: 20.3798208 },
    { lat: 48.340717, lng: 20.3799551 },
    { lat: 48.3408628, lng: 20.3800945 },
    { lat: 48.3411187, lng: 20.3803244 },
    { lat: 48.3416105, lng: 20.3807866 },
    { lat: 48.3422287, lng: 20.3813711 },
    { lat: 48.3429098, lng: 20.3820034 },
    { lat: 48.3434554, lng: 20.3825143 },
    { lat: 48.3442699, lng: 20.3832683 },
    { lat: 48.3449069, lng: 20.3838647 },
    { lat: 48.3452327, lng: 20.3841725 },
    { lat: 48.3457665, lng: 20.3846731 },
    { lat: 48.3462539, lng: 20.3851302 },
    { lat: 48.3467866, lng: 20.3856293 },
    { lat: 48.3473243, lng: 20.3861304 },
    { lat: 48.34751, lng: 20.386297 },
    { lat: 48.34765, lng: 20.3864362 },
    { lat: 48.34775, lng: 20.3865239 },
    { lat: 48.3481106, lng: 20.3865225 },
    { lat: 48.348484, lng: 20.386516 },
    { lat: 48.3486706, lng: 20.3865103 },
    { lat: 48.349222, lng: 20.3862601 },
    { lat: 48.349905, lng: 20.3859515 },
    { lat: 48.3503356, lng: 20.3857498 },
    { lat: 48.350809, lng: 20.3853604 },
    { lat: 48.3510384, lng: 20.3847161 },
    { lat: 48.3511001, lng: 20.3843944 },
    { lat: 48.3511552, lng: 20.3841231 },
  ],
  Riečka: [
    { lat: 48.3559202, lng: 20.3540136 },
    { lat: 48.3559078, lng: 20.3525091 },
    { lat: 48.3553552, lng: 20.3518845 },
    { lat: 48.3552379, lng: 20.3514792 },
    { lat: 48.3547748, lng: 20.3509806 },
    { lat: 48.3544965, lng: 20.3505961 },
    { lat: 48.3544224, lng: 20.3502276 },
    { lat: 48.3543184, lng: 20.3478549 },
    { lat: 48.3540327, lng: 20.3470586 },
    { lat: 48.3538886, lng: 20.3470316 },
    { lat: 48.3530322, lng: 20.346356 },
    { lat: 48.3524974, lng: 20.346301 },
    { lat: 48.352553, lng: 20.345332 },
    { lat: 48.352403, lng: 20.3428449 },
    { lat: 48.3525409, lng: 20.3412486 },
    { lat: 48.3524756, lng: 20.3387168 },
    { lat: 48.3525472, lng: 20.3368871 },
    { lat: 48.3525288, lng: 20.3352398 },
    { lat: 48.352444, lng: 20.3343409 },
    { lat: 48.3524315, lng: 20.3329548 },
    { lat: 48.3533356, lng: 20.3320044 },
    { lat: 48.3539272, lng: 20.3317507 },
    { lat: 48.3543188, lng: 20.3309698 },
    { lat: 48.353679, lng: 20.324819 },
    { lat: 48.3535142, lng: 20.3246675 },
    { lat: 48.3518768, lng: 20.3209175 },
    { lat: 48.3508481, lng: 20.3180641 },
    { lat: 48.3497931, lng: 20.3154104 },
    { lat: 48.3495541, lng: 20.3147993 },
    { lat: 48.349507, lng: 20.3148077 },
    { lat: 48.3493296, lng: 20.3154522 },
    { lat: 48.3490698, lng: 20.3153446 },
    { lat: 48.3488063, lng: 20.3149144 },
    { lat: 48.3487033, lng: 20.3145882 },
    { lat: 48.3482424, lng: 20.3147267 },
    { lat: 48.3480175, lng: 20.3147038 },
    { lat: 48.3477765, lng: 20.314563 },
    { lat: 48.3476744, lng: 20.3144574 },
    { lat: 48.3475452, lng: 20.3141799 },
    { lat: 48.3473081, lng: 20.3134203 },
    { lat: 48.3471114, lng: 20.3131407 },
    { lat: 48.3468162, lng: 20.3130811 },
    { lat: 48.3465926, lng: 20.3132418 },
    { lat: 48.3463928, lng: 20.3135127 },
    { lat: 48.3460941, lng: 20.3144203 },
    { lat: 48.3456938, lng: 20.3152112 },
    { lat: 48.3457042, lng: 20.315445 },
    { lat: 48.346026, lng: 20.3162411 },
    { lat: 48.3460701, lng: 20.3167618 },
    { lat: 48.3459452, lng: 20.3172756 },
    { lat: 48.3457581, lng: 20.3175612 },
    { lat: 48.3456135, lng: 20.3180371 },
    { lat: 48.3455923, lng: 20.3184981 },
    { lat: 48.3457558, lng: 20.3190798 },
    { lat: 48.3457891, lng: 20.3197233 },
    { lat: 48.3459793, lng: 20.3200956 },
    { lat: 48.3467218, lng: 20.3203315 },
    { lat: 48.3468104, lng: 20.3191214 },
    { lat: 48.3469558, lng: 20.3190363 },
    { lat: 48.3470781, lng: 20.3192839 },
    { lat: 48.3470979, lng: 20.319644 },
    { lat: 48.3469199, lng: 20.3205013 },
    { lat: 48.3469886, lng: 20.3210199 },
    { lat: 48.3468517, lng: 20.3219489 },
    { lat: 48.3468572, lng: 20.3223718 },
    { lat: 48.3469795, lng: 20.3227628 },
    { lat: 48.3470049, lng: 20.3230385 },
    { lat: 48.3469096, lng: 20.3231662 },
    { lat: 48.3465096, lng: 20.3232745 },
    { lat: 48.3462541, lng: 20.3234193 },
    { lat: 48.3457528, lng: 20.3241348 },
    { lat: 48.3455106, lng: 20.3241615 },
    { lat: 48.3451623, lng: 20.3239834 },
    { lat: 48.3449262, lng: 20.3236747 },
    { lat: 48.344838, lng: 20.323441 },
    { lat: 48.3448345, lng: 20.3231773 },
    { lat: 48.3450987, lng: 20.3225623 },
    { lat: 48.3453467, lng: 20.3216533 },
    { lat: 48.3453494, lng: 20.321351 },
    { lat: 48.3452722, lng: 20.3211564 },
    { lat: 48.3450782, lng: 20.3210964 },
    { lat: 48.3444859, lng: 20.3213577 },
    { lat: 48.344194, lng: 20.3216408 },
    { lat: 48.3437411, lng: 20.3222636 },
    { lat: 48.3433945, lng: 20.3225831 },
    { lat: 48.3432833, lng: 20.3223908 },
    { lat: 48.3430682, lng: 20.3216183 },
    { lat: 48.3429796, lng: 20.321544 },
    { lat: 48.3428319, lng: 20.32154 },
    { lat: 48.3427097, lng: 20.3216365 },
    { lat: 48.3426525, lng: 20.3217608 },
    { lat: 48.3426014, lng: 20.3222914 },
    { lat: 48.3425137, lng: 20.3226135 },
    { lat: 48.3423616, lng: 20.322868 },
    { lat: 48.3422174, lng: 20.3229519 },
    { lat: 48.3418917, lng: 20.3227177 },
    { lat: 48.3417774, lng: 20.322445 },
    { lat: 48.341718, lng: 20.3220258 },
    { lat: 48.3417233, lng: 20.3215297 },
    { lat: 48.3416444, lng: 20.3213232 },
    { lat: 48.3412439, lng: 20.3210245 },
    { lat: 48.3408705, lng: 20.3209495 },
    { lat: 48.3405073, lng: 20.3209611 },
    { lat: 48.340285, lng: 20.3210937 },
    { lat: 48.3399416, lng: 20.3216402 },
    { lat: 48.3397728, lng: 20.322047 },
    { lat: 48.3396535, lng: 20.3225917 },
    { lat: 48.3397539, lng: 20.322883 },
    { lat: 48.3403104, lng: 20.3230865 },
    { lat: 48.3405926, lng: 20.3235352 },
    { lat: 48.3406239, lng: 20.3237855 },
    { lat: 48.3405034, lng: 20.3246776 },
    { lat: 48.3405695, lng: 20.3250696 },
    { lat: 48.3404074, lng: 20.3252142 },
    { lat: 48.3396831, lng: 20.3251841 },
    { lat: 48.3397439, lng: 20.324521 },
    { lat: 48.3397492, lng: 20.3231099 },
    { lat: 48.3394225, lng: 20.3227894 },
    { lat: 48.3394456, lng: 20.3225722 },
    { lat: 48.3392971, lng: 20.322524 },
    { lat: 48.3392162, lng: 20.3225008 },
    { lat: 48.3389949, lng: 20.3226276 },
    { lat: 48.33875, lng: 20.3229855 },
    { lat: 48.3383996, lng: 20.3226986 },
    { lat: 48.3381605, lng: 20.3226319 },
    { lat: 48.3381124, lng: 20.3223258 },
    { lat: 48.3382915, lng: 20.3213769 },
    { lat: 48.3382148, lng: 20.3211776 },
    { lat: 48.3380537, lng: 20.3212029 },
    { lat: 48.3377646, lng: 20.3217272 },
    { lat: 48.337614, lng: 20.3219047 },
    { lat: 48.3373753, lng: 20.3220224 },
    { lat: 48.3373218, lng: 20.3221521 },
    { lat: 48.3373225, lng: 20.3222526 },
    { lat: 48.3374793, lng: 20.3226327 },
    { lat: 48.3375331, lng: 20.3237457 },
    { lat: 48.3374978, lng: 20.3238788 },
    { lat: 48.3371912, lng: 20.3241317 },
    { lat: 48.3371587, lng: 20.3243374 },
    { lat: 48.3373268, lng: 20.3246043 },
    { lat: 48.3366248, lng: 20.3245952 },
    { lat: 48.3357325, lng: 20.3242086 },
    { lat: 48.3357981, lng: 20.3237879 },
    { lat: 48.3357602, lng: 20.323598 },
    { lat: 48.3356325, lng: 20.3233562 },
    { lat: 48.3353417, lng: 20.323235 },
    { lat: 48.3349475, lng: 20.3233187 },
    { lat: 48.3348361, lng: 20.3238092 },
    { lat: 48.3348521, lng: 20.3245596 },
    { lat: 48.3345232, lng: 20.3247911 },
    { lat: 48.3342593, lng: 20.325438 },
    { lat: 48.3339302, lng: 20.3260062 },
    { lat: 48.3340488, lng: 20.3263595 },
    { lat: 48.3339186, lng: 20.3271171 },
    { lat: 48.3339381, lng: 20.3273033 },
    { lat: 48.3340844, lng: 20.3275839 },
    { lat: 48.3342456, lng: 20.3277041 },
    { lat: 48.3344663, lng: 20.3276471 },
    { lat: 48.3347691, lng: 20.3273507 },
    { lat: 48.3349078, lng: 20.3275851 },
    { lat: 48.3349838, lng: 20.327915 },
    { lat: 48.3350191, lng: 20.3282743 },
    { lat: 48.334941, lng: 20.3288334 },
    { lat: 48.3348195, lng: 20.3291545 },
    { lat: 48.3346663, lng: 20.3293567 },
    { lat: 48.3343761, lng: 20.3295947 },
    { lat: 48.3341978, lng: 20.3296451 },
    { lat: 48.3338214, lng: 20.3295488 },
    { lat: 48.3335368, lng: 20.3292115 },
    { lat: 48.3333749, lng: 20.3289065 },
    { lat: 48.3331524, lng: 20.3281418 },
    { lat: 48.332499, lng: 20.327332 },
    { lat: 48.3323508, lng: 20.3267972 },
    { lat: 48.3323641, lng: 20.3265635 },
    { lat: 48.3324385, lng: 20.3263917 },
    { lat: 48.332529, lng: 20.3262934 },
    { lat: 48.3330594, lng: 20.3263202 },
    { lat: 48.3331973, lng: 20.3262425 },
    { lat: 48.3332195, lng: 20.325835 },
    { lat: 48.3330842, lng: 20.3254849 },
    { lat: 48.3330111, lng: 20.3254319 },
    { lat: 48.3327679, lng: 20.3254601 },
    { lat: 48.3316553, lng: 20.3259253 },
    { lat: 48.3314206, lng: 20.3261694 },
    { lat: 48.3313048, lng: 20.3266029 },
    { lat: 48.3313325, lng: 20.3269668 },
    { lat: 48.3314019, lng: 20.3271578 },
    { lat: 48.3312611, lng: 20.32727 },
    { lat: 48.3312249, lng: 20.3280125 },
    { lat: 48.3312469, lng: 20.3281161 },
    { lat: 48.3313253, lng: 20.3280759 },
    { lat: 48.3314955, lng: 20.3284488 },
    { lat: 48.3320393, lng: 20.3291907 },
    { lat: 48.3321407, lng: 20.329599 },
    { lat: 48.3318895, lng: 20.3301139 },
    { lat: 48.3316189, lng: 20.3303069 },
    { lat: 48.3314041, lng: 20.3302841 },
    { lat: 48.3313893, lng: 20.3304539 },
    { lat: 48.3312288, lng: 20.3305788 },
    { lat: 48.3318129, lng: 20.3357151 },
    { lat: 48.3319267, lng: 20.3363499 },
    { lat: 48.3323885, lng: 20.3375592 },
    { lat: 48.3330932, lng: 20.3398214 },
    { lat: 48.3334278, lng: 20.3402315 },
    { lat: 48.3339265, lng: 20.3406315 },
    { lat: 48.3343224, lng: 20.3408143 },
    { lat: 48.3351962, lng: 20.3404684 },
    { lat: 48.335424, lng: 20.3408812 },
    { lat: 48.3357624, lng: 20.3417019 },
    { lat: 48.3361575, lng: 20.3417206 },
    { lat: 48.3368973, lng: 20.3425825 },
    { lat: 48.3377256, lng: 20.3428296 },
    { lat: 48.3384876, lng: 20.343222 },
    { lat: 48.3385851, lng: 20.3433239 },
    { lat: 48.3396744, lng: 20.3449186 },
    { lat: 48.3398195, lng: 20.3449713 },
    { lat: 48.3401272, lng: 20.3453811 },
    { lat: 48.340933, lng: 20.3460532 },
    { lat: 48.34197, lng: 20.3471418 },
    { lat: 48.3427459, lng: 20.3489915 },
    { lat: 48.3431972, lng: 20.3505109 },
    { lat: 48.3437065, lng: 20.3510672 },
    { lat: 48.3441864, lng: 20.3510095 },
    { lat: 48.3448384, lng: 20.3514436 },
    { lat: 48.345577, lng: 20.3515511 },
    { lat: 48.3455583, lng: 20.3516225 },
    { lat: 48.3458448, lng: 20.3516639 },
    { lat: 48.3462296, lng: 20.351886 },
    { lat: 48.3465895, lng: 20.3521886 },
    { lat: 48.3478587, lng: 20.3540087 },
    { lat: 48.3480299, lng: 20.3541505 },
    { lat: 48.3481499, lng: 20.3544568 },
    { lat: 48.3487612, lng: 20.3553498 },
    { lat: 48.3495126, lng: 20.3558457 },
    { lat: 48.3503335, lng: 20.3558958 },
    { lat: 48.350771, lng: 20.3557987 },
    { lat: 48.3512176, lng: 20.3555237 },
    { lat: 48.3514105, lng: 20.3552168 },
    { lat: 48.3521315, lng: 20.3554226 },
    { lat: 48.3524745, lng: 20.3554675 },
    { lat: 48.3526785, lng: 20.3554167 },
    { lat: 48.3530211, lng: 20.3551299 },
    { lat: 48.3535545, lng: 20.3543374 },
    { lat: 48.3558258, lng: 20.3538997 },
    { lat: 48.3559202, lng: 20.3540136 },
  ],
  UzovskáPanica: [
    { lat: 48.4473806, lng: 20.1609549 },
    { lat: 48.4472061, lng: 20.1606793 },
    { lat: 48.4470857, lng: 20.1606254 },
    { lat: 48.4469785, lng: 20.1604572 },
    { lat: 48.4466542, lng: 20.1593237 },
    { lat: 48.4465374, lng: 20.1588015 },
    { lat: 48.446475, lng: 20.1581559 },
    { lat: 48.446297, lng: 20.1575737 },
    { lat: 48.4462765, lng: 20.1571801 },
    { lat: 48.4461777, lng: 20.1571867 },
    { lat: 48.4460675, lng: 20.1566891 },
    { lat: 48.446029, lng: 20.1560509 },
    { lat: 48.4457969, lng: 20.1556298 },
    { lat: 48.4455658, lng: 20.1554616 },
    { lat: 48.4454786, lng: 20.1553128 },
    { lat: 48.4454108, lng: 20.1550047 },
    { lat: 48.445231, lng: 20.154763 },
    { lat: 48.4452096, lng: 20.1545943 },
    { lat: 48.4449729, lng: 20.1543695 },
    { lat: 48.4448999, lng: 20.1542105 },
    { lat: 48.4447447, lng: 20.154134 },
    { lat: 48.4446719, lng: 20.1540027 },
    { lat: 48.4445717, lng: 20.1537855 },
    { lat: 48.4444824, lng: 20.153059 },
    { lat: 48.4432605, lng: 20.1524789 },
    { lat: 48.4427919, lng: 20.1524525 },
    { lat: 48.4421886, lng: 20.1527116 },
    { lat: 48.4420603, lng: 20.1529873 },
    { lat: 48.4408944, lng: 20.152932 },
    { lat: 48.4403838, lng: 20.1531442 },
    { lat: 48.4400785, lng: 20.1525593 },
    { lat: 48.4392712, lng: 20.1515151 },
    { lat: 48.4388344, lng: 20.1513017 },
    { lat: 48.438415, lng: 20.1502644 },
    { lat: 48.438292, lng: 20.1489888 },
    { lat: 48.4383301, lng: 20.1485979 },
    { lat: 48.4382197, lng: 20.1481831 },
    { lat: 48.4377068, lng: 20.1477295 },
    { lat: 48.4358669, lng: 20.1453946 },
    { lat: 48.4339885, lng: 20.1433025 },
    { lat: 48.4324279, lng: 20.1413295 },
    { lat: 48.4315053, lng: 20.1400303 },
    { lat: 48.429662, lng: 20.1371756 },
    { lat: 48.4298455, lng: 20.1369537 },
    { lat: 48.4301107, lng: 20.1368243 },
    { lat: 48.4306763, lng: 20.1366941 },
    { lat: 48.4311702, lng: 20.1366801 },
    { lat: 48.4314032, lng: 20.1365934 },
    { lat: 48.4318087, lng: 20.1363158 },
    { lat: 48.4321602, lng: 20.1359887 },
    { lat: 48.4329984, lng: 20.1349438 },
    { lat: 48.4321286, lng: 20.1339735 },
    { lat: 48.4307328, lng: 20.132618 },
    { lat: 48.4291159, lng: 20.1335383 },
    { lat: 48.4279368, lng: 20.1347022 },
    { lat: 48.42659, lng: 20.1326993 },
    { lat: 48.4246761, lng: 20.130022 },
    { lat: 48.4235555, lng: 20.1279882 },
    { lat: 48.423396, lng: 20.1280008 },
    { lat: 48.4232661, lng: 20.1282929 },
    { lat: 48.4231845, lng: 20.1279554 },
    { lat: 48.4231527, lng: 20.1280023 },
    { lat: 48.420772, lng: 20.1312853 },
    { lat: 48.4179188, lng: 20.1252836 },
    { lat: 48.4179221, lng: 20.1251227 },
    { lat: 48.417052, lng: 20.1240596 },
    { lat: 48.4168616, lng: 20.1191444 },
    { lat: 48.4164739, lng: 20.1153743 },
    { lat: 48.4163816, lng: 20.1140351 },
    { lat: 48.416396, lng: 20.1135339 },
    { lat: 48.4165499, lng: 20.1123977 },
    { lat: 48.4166963, lng: 20.1117945 },
    { lat: 48.4166174, lng: 20.1117419 },
    { lat: 48.416762, lng: 20.1110392 },
    { lat: 48.4163161, lng: 20.1107405 },
    { lat: 48.4163285, lng: 20.1103011 },
    { lat: 48.4164699, lng: 20.1095207 },
    { lat: 48.4164002, lng: 20.1089783 },
    { lat: 48.4164431, lng: 20.1085988 },
    { lat: 48.4160585, lng: 20.1072697 },
    { lat: 48.4160301, lng: 20.1072908 },
    { lat: 48.4154562, lng: 20.1066466 },
    { lat: 48.414627, lng: 20.1040399 },
    { lat: 48.414313, lng: 20.1027306 },
    { lat: 48.4137105, lng: 20.1030096 },
    { lat: 48.4135565, lng: 20.1027438 },
    { lat: 48.4132663, lng: 20.1025612 },
    { lat: 48.4127197, lng: 20.1027481 },
    { lat: 48.4118718, lng: 20.1026992 },
    { lat: 48.4116819, lng: 20.1029023 },
    { lat: 48.4112098, lng: 20.1031439 },
    { lat: 48.4105455, lng: 20.1030429 },
    { lat: 48.4100476, lng: 20.1027941 },
    { lat: 48.4097226, lng: 20.1023127 },
    { lat: 48.4092685, lng: 20.1009446 },
    { lat: 48.4092098, lng: 20.1005558 },
    { lat: 48.408978, lng: 20.1002636 },
    { lat: 48.4083386, lng: 20.1001089 },
    { lat: 48.4073308, lng: 20.1008548 },
    { lat: 48.4066769, lng: 20.1005958 },
    { lat: 48.4063281, lng: 20.1002468 },
    { lat: 48.4061797, lng: 20.0995664 },
    { lat: 48.406072, lng: 20.0984612 },
    { lat: 48.4060731, lng: 20.0977489 },
    { lat: 48.4058895, lng: 20.0992488 },
    { lat: 48.4056874, lng: 20.0997355 },
    { lat: 48.405595, lng: 20.1000866 },
    { lat: 48.4026836, lng: 20.1013499 },
    { lat: 48.4023708, lng: 20.1016065 },
    { lat: 48.4013804, lng: 20.104501 },
    { lat: 48.4011159, lng: 20.1060926 },
    { lat: 48.4002481, lng: 20.108023 },
    { lat: 48.4000088, lng: 20.1080637 },
    { lat: 48.3988271, lng: 20.1089389 },
    { lat: 48.3979968, lng: 20.1099806 },
    { lat: 48.397153, lng: 20.1111799 },
    { lat: 48.3968922, lng: 20.1114111 },
    { lat: 48.3961237, lng: 20.1125009 },
    { lat: 48.3957603, lng: 20.1133594 },
    { lat: 48.3954119, lng: 20.1146924 },
    { lat: 48.3952352, lng: 20.1162058 },
    { lat: 48.3957558, lng: 20.1168417 },
    { lat: 48.3955276, lng: 20.1182 },
    { lat: 48.3955565, lng: 20.1186574 },
    { lat: 48.3955263, lng: 20.1211015 },
    { lat: 48.395158, lng: 20.1247311 },
    { lat: 48.3952407, lng: 20.1259487 },
    { lat: 48.3954661, lng: 20.1270553 },
    { lat: 48.3958927, lng: 20.1285027 },
    { lat: 48.3955683, lng: 20.1327382 },
    { lat: 48.3951291, lng: 20.1373968 },
    { lat: 48.3949406, lng: 20.1422703 },
    { lat: 48.3949535, lng: 20.1434639 },
    { lat: 48.3953508, lng: 20.1450619 },
    { lat: 48.3952741, lng: 20.1453326 },
    { lat: 48.3953752, lng: 20.1454913 },
    { lat: 48.3953021, lng: 20.1456047 },
    { lat: 48.3953574, lng: 20.1457162 },
    { lat: 48.3951712, lng: 20.1459297 },
    { lat: 48.3962399, lng: 20.147943 },
    { lat: 48.3975513, lng: 20.1499639 },
    { lat: 48.3994432, lng: 20.1530768 },
    { lat: 48.3997719, lng: 20.1537123 },
    { lat: 48.4004962, lng: 20.1522163 },
    { lat: 48.4011622, lng: 20.1510673 },
    { lat: 48.4020304, lng: 20.1518357 },
    { lat: 48.4023655, lng: 20.1521814 },
    { lat: 48.4025273, lng: 20.1527535 },
    { lat: 48.4026991, lng: 20.1530698 },
    { lat: 48.4029281, lng: 20.1538825 },
    { lat: 48.4031044, lng: 20.1540925 },
    { lat: 48.4032456, lng: 20.1543769 },
    { lat: 48.4027984, lng: 20.155318 },
    { lat: 48.403946, lng: 20.1567808 },
    { lat: 48.4044771, lng: 20.1561252 },
    { lat: 48.4068174, lng: 20.1602196 },
    { lat: 48.4065363, lng: 20.1616697 },
    { lat: 48.4064802, lng: 20.1622051 },
    { lat: 48.4065131, lng: 20.1627938 },
    { lat: 48.4066313, lng: 20.1632877 },
    { lat: 48.4065611, lng: 20.1634753 },
    { lat: 48.4072306, lng: 20.1643736 },
    { lat: 48.4073596, lng: 20.1651902 },
    { lat: 48.4075536, lng: 20.1656428 },
    { lat: 48.406604, lng: 20.1669443 },
    { lat: 48.4059089, lng: 20.168187 },
    { lat: 48.4073689, lng: 20.1698602 },
    { lat: 48.4069709, lng: 20.1705705 },
    { lat: 48.4084001, lng: 20.172789 },
    { lat: 48.4081534, lng: 20.1735375 },
    { lat: 48.4088065, lng: 20.173894 },
    { lat: 48.4091952, lng: 20.1743416 },
    { lat: 48.4101036, lng: 20.1729132 },
    { lat: 48.4103504, lng: 20.1724468 },
    { lat: 48.4100326, lng: 20.1719958 },
    { lat: 48.4103366, lng: 20.1715469 },
    { lat: 48.4112253, lng: 20.1729116 },
    { lat: 48.4117373, lng: 20.1735639 },
    { lat: 48.4124905, lng: 20.1743062 },
    { lat: 48.4129707, lng: 20.1747331 },
    { lat: 48.4133858, lng: 20.1750014 },
    { lat: 48.4147975, lng: 20.1755659 },
    { lat: 48.415485, lng: 20.1762408 },
    { lat: 48.415664, lng: 20.1764897 },
    { lat: 48.41575, lng: 20.1767236 },
    { lat: 48.4158289, lng: 20.17764 },
    { lat: 48.4157439, lng: 20.1784377 },
    { lat: 48.4156833, lng: 20.1802465 },
    { lat: 48.4155353, lng: 20.1811857 },
    { lat: 48.4150282, lng: 20.1829313 },
    { lat: 48.4147895, lng: 20.1835176 },
    { lat: 48.4142796, lng: 20.1841409 },
    { lat: 48.4140603, lng: 20.1845353 },
    { lat: 48.4136631, lng: 20.1858259 },
    { lat: 48.4135662, lng: 20.18764 },
    { lat: 48.4134765, lng: 20.1879829 },
    { lat: 48.4128378, lng: 20.1890107 },
    { lat: 48.4126455, lng: 20.1895491 },
    { lat: 48.4127111, lng: 20.1895839 },
    { lat: 48.4125659, lng: 20.1915626 },
    { lat: 48.4120958, lng: 20.1945398 },
    { lat: 48.4126005, lng: 20.1957424 },
    { lat: 48.4134191, lng: 20.1971899 },
    { lat: 48.413434, lng: 20.1972258 },
    { lat: 48.4135555, lng: 20.1975281 },
    { lat: 48.4139203, lng: 20.1972978 },
    { lat: 48.4145493, lng: 20.197036 },
    { lat: 48.4149898, lng: 20.1970675 },
    { lat: 48.4154936, lng: 20.1973209 },
    { lat: 48.4166001, lng: 20.1984243 },
    { lat: 48.417234, lng: 20.1976394 },
    { lat: 48.4176634, lng: 20.1973135 },
    { lat: 48.4183569, lng: 20.1971912 },
    { lat: 48.4208827, lng: 20.1963649 },
    { lat: 48.4217329, lng: 20.1963393 },
    { lat: 48.4222207, lng: 20.1970618 },
    { lat: 48.4225749, lng: 20.1978622 },
    { lat: 48.4231053, lng: 20.1985844 },
    { lat: 48.4232355, lng: 20.1981843 },
    { lat: 48.4233855, lng: 20.1974622 },
    { lat: 48.4233658, lng: 20.1962782 },
    { lat: 48.4232026, lng: 20.1952169 },
    { lat: 48.4232227, lng: 20.1941516 },
    { lat: 48.4233182, lng: 20.1931146 },
    { lat: 48.4234873, lng: 20.1924439 },
    { lat: 48.4235259, lng: 20.1920092 },
    { lat: 48.4234134, lng: 20.1893344 },
    { lat: 48.4233763, lng: 20.1890832 },
    { lat: 48.4231458, lng: 20.1884546 },
    { lat: 48.4231962, lng: 20.1879429 },
    { lat: 48.4233743, lng: 20.1872231 },
    { lat: 48.4233642, lng: 20.1868616 },
    { lat: 48.4233018, lng: 20.1865734 },
    { lat: 48.4226886, lng: 20.1855272 },
    { lat: 48.4226119, lng: 20.1852761 },
    { lat: 48.4226049, lng: 20.1844087 },
    { lat: 48.4224923, lng: 20.1839346 },
    { lat: 48.4219833, lng: 20.1827123 },
    { lat: 48.4218351, lng: 20.1820542 },
    { lat: 48.4217918, lng: 20.1816252 },
    { lat: 48.4217084, lng: 20.1814003 },
    { lat: 48.4216709, lng: 20.1810275 },
    { lat: 48.421514, lng: 20.1805227 },
    { lat: 48.4213829, lng: 20.1802052 },
    { lat: 48.4208486, lng: 20.1794123 },
    { lat: 48.4206066, lng: 20.1787529 },
    { lat: 48.4199442, lng: 20.177578 },
    { lat: 48.4200597, lng: 20.1770097 },
    { lat: 48.4201452, lng: 20.1767835 },
    { lat: 48.4203158, lng: 20.1765488 },
    { lat: 48.4204041, lng: 20.1762531 },
    { lat: 48.4205317, lng: 20.1754592 },
    { lat: 48.4207552, lng: 20.175492 },
    { lat: 48.4210802, lng: 20.1757034 },
    { lat: 48.4213302, lng: 20.1757855 },
    { lat: 48.4216885, lng: 20.1757623 },
    { lat: 48.4222255, lng: 20.1760079 },
    { lat: 48.4235091, lng: 20.176221 },
    { lat: 48.4240872, lng: 20.1761997 },
    { lat: 48.4244593, lng: 20.1762923 },
    { lat: 48.4250753, lng: 20.1767047 },
    { lat: 48.425667, lng: 20.1767162 },
    { lat: 48.4260877, lng: 20.1766189 },
    { lat: 48.4262227, lng: 20.1761704 },
    { lat: 48.4262942, lng: 20.1755184 },
    { lat: 48.4262591, lng: 20.1747631 },
    { lat: 48.4265498, lng: 20.1744861 },
    { lat: 48.4266619, lng: 20.1743064 },
    { lat: 48.4271742, lng: 20.1739145 },
    { lat: 48.4277756, lng: 20.1736045 },
    { lat: 48.4279376, lng: 20.1750458 },
    { lat: 48.4279565, lng: 20.1762069 },
    { lat: 48.4280206, lng: 20.1767621 },
    { lat: 48.4284313, lng: 20.1784771 },
    { lat: 48.4284649, lng: 20.1794181 },
    { lat: 48.4284324, lng: 20.1802602 },
    { lat: 48.4282559, lng: 20.1814751 },
    { lat: 48.42829, lng: 20.1817733 },
    { lat: 48.4285793, lng: 20.1830806 },
    { lat: 48.428798, lng: 20.1834458 },
    { lat: 48.4297234, lng: 20.184465 },
    { lat: 48.4303545, lng: 20.1849791 },
    { lat: 48.430513, lng: 20.1849296 },
    { lat: 48.4307829, lng: 20.1848453 },
    { lat: 48.4319222, lng: 20.1836667 },
    { lat: 48.432332, lng: 20.1832427 },
    { lat: 48.4335709, lng: 20.1840503 },
    { lat: 48.4343357, lng: 20.1851916 },
    { lat: 48.4351406, lng: 20.1853204 },
    { lat: 48.436011, lng: 20.1856775 },
    { lat: 48.436516, lng: 20.1862756 },
    { lat: 48.436796, lng: 20.186289 },
    { lat: 48.4377688, lng: 20.1849978 },
    { lat: 48.4378298, lng: 20.1847851 },
    { lat: 48.4385632, lng: 20.1833085 },
    { lat: 48.4387362, lng: 20.1826043 },
    { lat: 48.4389542, lng: 20.1806125 },
    { lat: 48.4389744, lng: 20.1788602 },
    { lat: 48.4393165, lng: 20.1779059 },
    { lat: 48.4392948, lng: 20.1774227 },
    { lat: 48.4393407, lng: 20.177251 },
    { lat: 48.4393443, lng: 20.1761685 },
    { lat: 48.4394514, lng: 20.1757288 },
    { lat: 48.4395349, lng: 20.1755363 },
    { lat: 48.439648, lng: 20.1754387 },
    { lat: 48.4399632, lng: 20.1741815 },
    { lat: 48.4401601, lng: 20.1737587 },
    { lat: 48.4403782, lng: 20.1735263 },
    { lat: 48.4403684, lng: 20.173424 },
    { lat: 48.4406148, lng: 20.1729452 },
    { lat: 48.440654, lng: 20.1726912 },
    { lat: 48.4408356, lng: 20.172449 },
    { lat: 48.440973, lng: 20.1723906 },
    { lat: 48.4409802, lng: 20.1722598 },
    { lat: 48.4412091, lng: 20.1717983 },
    { lat: 48.4412254, lng: 20.1715907 },
    { lat: 48.4412894, lng: 20.1715263 },
    { lat: 48.441505, lng: 20.1708999 },
    { lat: 48.4416312, lng: 20.1707883 },
    { lat: 48.4417823, lng: 20.1707968 },
    { lat: 48.4423153, lng: 20.1700524 },
    { lat: 48.4423392, lng: 20.1698906 },
    { lat: 48.4426981, lng: 20.1690737 },
    { lat: 48.4427656, lng: 20.1688242 },
    { lat: 48.4427646, lng: 20.1683287 },
    { lat: 48.4428644, lng: 20.1678453 },
    { lat: 48.4429242, lng: 20.1677365 },
    { lat: 48.4429179, lng: 20.1675793 },
    { lat: 48.4430766, lng: 20.1671876 },
    { lat: 48.4431921, lng: 20.1665672 },
    { lat: 48.4433502, lng: 20.1662994 },
    { lat: 48.4433678, lng: 20.1661747 },
    { lat: 48.4435857, lng: 20.166048 },
    { lat: 48.4437887, lng: 20.1657516 },
    { lat: 48.4439712, lng: 20.1652521 },
    { lat: 48.444059, lng: 20.1645015 },
    { lat: 48.444176, lng: 20.1642744 },
    { lat: 48.4443048, lng: 20.1636219 },
    { lat: 48.4447683, lng: 20.1627492 },
    { lat: 48.4449733, lng: 20.1624739 },
    { lat: 48.4450758, lng: 20.1621676 },
    { lat: 48.4450875, lng: 20.1618455 },
    { lat: 48.4451881, lng: 20.1615489 },
    { lat: 48.445198, lng: 20.1613756 },
    { lat: 48.4454695, lng: 20.1610179 },
    { lat: 48.4460931, lng: 20.1609759 },
    { lat: 48.4462882, lng: 20.1610389 },
    { lat: 48.4468227, lng: 20.1610547 },
    { lat: 48.4472031, lng: 20.1609946 },
    { lat: 48.4473806, lng: 20.1609549 },
  ],
  Hrachovo: [
    { lat: 48.4397869, lng: 19.9170007 },
    { lat: 48.4463918, lng: 19.9130206 },
    { lat: 48.4468742, lng: 19.914763 },
    { lat: 48.4468955, lng: 19.9152371 },
    { lat: 48.4476975, lng: 19.9186857 },
    { lat: 48.4481839, lng: 19.9199676 },
    { lat: 48.4482332, lng: 19.9198575 },
    { lat: 48.4489663, lng: 19.922491 },
    { lat: 48.4496183, lng: 19.9222751 },
    { lat: 48.4497533, lng: 19.9231138 },
    { lat: 48.4502929, lng: 19.9256177 },
    { lat: 48.4509682, lng: 19.9284547 },
    { lat: 48.4510561, lng: 19.9282457 },
    { lat: 48.4515212, lng: 19.931252 },
    { lat: 48.4535085, lng: 19.9309615 },
    { lat: 48.4534108, lng: 19.9315001 },
    { lat: 48.4532862, lng: 19.9316448 },
    { lat: 48.4534814, lng: 19.9317261 },
    { lat: 48.4533177, lng: 19.9318795 },
    { lat: 48.4533242, lng: 19.9319556 },
    { lat: 48.4534428, lng: 19.9319566 },
    { lat: 48.4535436, lng: 19.9321275 },
    { lat: 48.453549, lng: 19.9322338 },
    { lat: 48.4534155, lng: 19.9322456 },
    { lat: 48.4533976, lng: 19.9324315 },
    { lat: 48.453486, lng: 19.9324162 },
    { lat: 48.4535359, lng: 19.9325076 },
    { lat: 48.4534637, lng: 19.9325291 },
    { lat: 48.4534542, lng: 19.9326514 },
    { lat: 48.4535343, lng: 19.932786 },
    { lat: 48.4534247, lng: 19.9329889 },
    { lat: 48.4535155, lng: 19.9330816 },
    { lat: 48.4534901, lng: 19.9337821 },
    { lat: 48.4534345, lng: 19.9339344 },
    { lat: 48.4534448, lng: 19.9341481 },
    { lat: 48.4535609, lng: 19.9343339 },
    { lat: 48.4535697, lng: 19.9345424 },
    { lat: 48.4535121, lng: 19.9345628 },
    { lat: 48.4535054, lng: 19.9344627 },
    { lat: 48.4534316, lng: 19.9344975 },
    { lat: 48.453385, lng: 19.9346212 },
    { lat: 48.4534802, lng: 19.9347409 },
    { lat: 48.4533706, lng: 19.9349157 },
    { lat: 48.4533982, lng: 19.9352207 },
    { lat: 48.4536161, lng: 19.9354425 },
    { lat: 48.4536061, lng: 19.9356834 },
    { lat: 48.4536821, lng: 19.9356718 },
    { lat: 48.4537229, lng: 19.9355724 },
    { lat: 48.4538298, lng: 19.9357818 },
    { lat: 48.4538092, lng: 19.935853 },
    { lat: 48.4537388, lng: 19.9358686 },
    { lat: 48.4537125, lng: 19.9361479 },
    { lat: 48.4536618, lng: 19.9361709 },
    { lat: 48.453578, lng: 19.9360055 },
    { lat: 48.453476, lng: 19.9362051 },
    { lat: 48.4533861, lng: 19.9362329 },
    { lat: 48.4532768, lng: 19.9367882 },
    { lat: 48.4533335, lng: 19.9369552 },
    { lat: 48.4533056, lng: 19.9371751 },
    { lat: 48.4534838, lng: 19.9375742 },
    { lat: 48.4534675, lng: 19.9378018 },
    { lat: 48.4536953, lng: 19.9385503 },
    { lat: 48.4536994, lng: 19.9386958 },
    { lat: 48.4535241, lng: 19.9388642 },
    { lat: 48.4535177, lng: 19.9390862 },
    { lat: 48.4536249, lng: 19.939198 },
    { lat: 48.453758, lng: 19.9395465 },
    { lat: 48.4536788, lng: 19.9396694 },
    { lat: 48.4536307, lng: 19.9399651 },
    { lat: 48.4536801, lng: 19.9400717 },
    { lat: 48.4538546, lng: 19.9401462 },
    { lat: 48.4539125, lng: 19.9404752 },
    { lat: 48.4539954, lng: 19.9405212 },
    { lat: 48.4541639, lng: 19.940851 },
    { lat: 48.4541825, lng: 19.9409451 },
    { lat: 48.4540997, lng: 19.9410353 },
    { lat: 48.4542292, lng: 19.9412713 },
    { lat: 48.4542373, lng: 19.9416513 },
    { lat: 48.4544123, lng: 19.9418201 },
    { lat: 48.4545177, lng: 19.9423107 },
    { lat: 48.4545169, lng: 19.9425305 },
    { lat: 48.4544713, lng: 19.9426463 },
    { lat: 48.454215, lng: 19.9428739 },
    { lat: 48.4541793, lng: 19.943224 },
    { lat: 48.4542508, lng: 19.9434234 },
    { lat: 48.4541111, lng: 19.9435287 },
    { lat: 48.4540748, lng: 19.9436301 },
    { lat: 48.4541916, lng: 19.9437797 },
    { lat: 48.4541727, lng: 19.9439724 },
    { lat: 48.4542942, lng: 19.9443103 },
    { lat: 48.4542874, lng: 19.9445988 },
    { lat: 48.454391, lng: 19.9447311 },
    { lat: 48.4544902, lng: 19.9446566 },
    { lat: 48.4545647, lng: 19.9447652 },
    { lat: 48.4545479, lng: 19.9448477 },
    { lat: 48.454406, lng: 19.9449298 },
    { lat: 48.4543925, lng: 19.9451128 },
    { lat: 48.4542189, lng: 19.9454027 },
    { lat: 48.4542912, lng: 19.9457787 },
    { lat: 48.4542419, lng: 19.9459676 },
    { lat: 48.4544932, lng: 19.9465319 },
    { lat: 48.4543877, lng: 19.9468316 },
    { lat: 48.4544707, lng: 19.9471139 },
    { lat: 48.4544502, lng: 19.9472419 },
    { lat: 48.4545637, lng: 19.9474968 },
    { lat: 48.4546205, lng: 19.9477997 },
    { lat: 48.4547903, lng: 19.9480066 },
    { lat: 48.4547497, lng: 19.9481411 },
    { lat: 48.4546672, lng: 19.9481824 },
    { lat: 48.4546383, lng: 19.9482736 },
    { lat: 48.4547715, lng: 19.9483594 },
    { lat: 48.4548472, lng: 19.9485516 },
    { lat: 48.4546916, lng: 19.9486293 },
    { lat: 48.4546797, lng: 19.94877 },
    { lat: 48.4547858, lng: 19.9489344 },
    { lat: 48.4548257, lng: 19.9492134 },
    { lat: 48.4549133, lng: 19.9492021 },
    { lat: 48.4549234, lng: 19.9490724 },
    { lat: 48.4550915, lng: 19.9490232 },
    { lat: 48.454996, lng: 19.9493479 },
    { lat: 48.4551423, lng: 19.9495017 },
    { lat: 48.455155, lng: 19.9498287 },
    { lat: 48.455142, lng: 19.9498949 },
    { lat: 48.4549664, lng: 19.9498752 },
    { lat: 48.455128, lng: 19.950149 },
    { lat: 48.4550061, lng: 19.9503803 },
    { lat: 48.4550522, lng: 19.9505668 },
    { lat: 48.4549404, lng: 19.9508522 },
    { lat: 48.4549597, lng: 19.9509618 },
    { lat: 48.4550886, lng: 19.9509722 },
    { lat: 48.4551071, lng: 19.9510367 },
    { lat: 48.455001, lng: 19.951746 },
    { lat: 48.4550421, lng: 19.9518141 },
    { lat: 48.4549467, lng: 19.9519571 },
    { lat: 48.4546963, lng: 19.9521096 },
    { lat: 48.4547744, lng: 19.9525695 },
    { lat: 48.4546069, lng: 19.953049 },
    { lat: 48.4546355, lng: 19.9532737 },
    { lat: 48.4545218, lng: 19.9533041 },
    { lat: 48.4544362, lng: 19.953439 },
    { lat: 48.4543487, lng: 19.9538967 },
    { lat: 48.4541853, lng: 19.9541615 },
    { lat: 48.4541825, lng: 19.9543083 },
    { lat: 48.4542598, lng: 19.9543769 },
    { lat: 48.4542882, lng: 19.9549139 },
    { lat: 48.4543352, lng: 19.9549868 },
    { lat: 48.4543959, lng: 19.9549302 },
    { lat: 48.4542934, lng: 19.955142 },
    { lat: 48.4532383, lng: 19.9564784 },
    { lat: 48.4536133, lng: 19.9581515 },
    { lat: 48.4540704, lng: 19.9598984 },
    { lat: 48.4538488, lng: 19.9600953 },
    { lat: 48.4534302, lng: 19.9602883 },
    { lat: 48.4532512, lng: 19.9604804 },
    { lat: 48.4525711, lng: 19.9606484 },
    { lat: 48.4528925, lng: 19.9650709 },
    { lat: 48.4529207, lng: 19.9652584 },
    { lat: 48.4531717, lng: 19.9652532 },
    { lat: 48.4533168, lng: 19.9651399 },
    { lat: 48.4535613, lng: 19.9652731 },
    { lat: 48.453593, lng: 19.9656749 },
    { lat: 48.4538191, lng: 19.9658822 },
    { lat: 48.4537164, lng: 19.9654782 },
    { lat: 48.4539657, lng: 19.9652461 },
    { lat: 48.4539362, lng: 19.9651077 },
    { lat: 48.4539797, lng: 19.9650478 },
    { lat: 48.4539101, lng: 19.9647585 },
    { lat: 48.4539503, lng: 19.9647255 },
    { lat: 48.4538449, lng: 19.9643112 },
    { lat: 48.4539183, lng: 19.9642311 },
    { lat: 48.4538836, lng: 19.9640804 },
    { lat: 48.454054, lng: 19.9639523 },
    { lat: 48.454133, lng: 19.9641672 },
    { lat: 48.454206, lng: 19.9641925 },
    { lat: 48.4542895, lng: 19.9643804 },
    { lat: 48.4543268, lng: 19.9647034 },
    { lat: 48.4546761, lng: 19.9647285 },
    { lat: 48.4549423, lng: 19.9650345 },
    { lat: 48.4550152, lng: 19.9649991 },
    { lat: 48.4550223, lng: 19.9646595 },
    { lat: 48.4549525, lng: 19.9643954 },
    { lat: 48.4550371, lng: 19.9642912 },
    { lat: 48.4552621, lng: 19.9642299 },
    { lat: 48.4555817, lng: 19.9644367 },
    { lat: 48.4556523, lng: 19.9644821 },
    { lat: 48.4557209, lng: 19.9645061 },
    { lat: 48.4557877, lng: 19.9643287 },
    { lat: 48.4557243, lng: 19.9642144 },
    { lat: 48.4555229, lng: 19.964113 },
    { lat: 48.4556178, lng: 19.9639553 },
    { lat: 48.4557692, lng: 19.9639685 },
    { lat: 48.4558286, lng: 19.9641232 },
    { lat: 48.4560013, lng: 19.9642387 },
    { lat: 48.4560635, lng: 19.9646416 },
    { lat: 48.4563287, lng: 19.9652631 },
    { lat: 48.4564195, lng: 19.9653634 },
    { lat: 48.456704, lng: 19.9653479 },
    { lat: 48.4568904, lng: 19.9654962 },
    { lat: 48.4573188, lng: 19.9663818 },
    { lat: 48.4574968, lng: 19.966461 },
    { lat: 48.4576524, lng: 19.9663833 },
    { lat: 48.4577229, lng: 19.9660301 },
    { lat: 48.4578202, lng: 19.9659293 },
    { lat: 48.457863, lng: 19.965719 },
    { lat: 48.458057, lng: 19.965457 },
    { lat: 48.4580558, lng: 19.9653495 },
    { lat: 48.4577881, lng: 19.9646412 },
    { lat: 48.4577819, lng: 19.9644108 },
    { lat: 48.4579816, lng: 19.9639431 },
    { lat: 48.4580392, lng: 19.9639202 },
    { lat: 48.4581378, lng: 19.9640648 },
    { lat: 48.4581593, lng: 19.9644389 },
    { lat: 48.458318, lng: 19.9645455 },
    { lat: 48.4583637, lng: 19.9646951 },
    { lat: 48.4584748, lng: 19.9646547 },
    { lat: 48.458516, lng: 19.9645105 },
    { lat: 48.4593004, lng: 19.9650236 },
    { lat: 48.4593769, lng: 19.9649971 },
    { lat: 48.4595573, lng: 19.9647349 },
    { lat: 48.4595672, lng: 19.9645966 },
    { lat: 48.4594586, lng: 19.9644782 },
    { lat: 48.4596062, lng: 19.9640783 },
    { lat: 48.4600183, lng: 19.9641276 },
    { lat: 48.4603345, lng: 19.9638514 },
    { lat: 48.4603498, lng: 19.9637885 },
    { lat: 48.4600281, lng: 19.9631875 },
    { lat: 48.4600543, lng: 19.962932 },
    { lat: 48.4602671, lng: 19.9627682 },
    { lat: 48.4604729, lng: 19.9627049 },
    { lat: 48.4604819, lng: 19.9624151 },
    { lat: 48.4603434, lng: 19.9619379 },
    { lat: 48.460402, lng: 19.9618279 },
    { lat: 48.4606837, lng: 19.9616735 },
    { lat: 48.4607414, lng: 19.9616751 },
    { lat: 48.4608336, lng: 19.9618068 },
    { lat: 48.460804, lng: 19.9621461 },
    { lat: 48.4608364, lng: 19.9622186 },
    { lat: 48.4611594, lng: 19.9623428 },
    { lat: 48.4616578, lng: 19.9628094 },
    { lat: 48.4619194, lng: 19.9623641 },
    { lat: 48.4622712, lng: 19.9627439 },
    { lat: 48.4623064, lng: 19.9630124 },
    { lat: 48.462571, lng: 19.9630393 },
    { lat: 48.4626651, lng: 19.9629714 },
    { lat: 48.4629193, lng: 19.9631763 },
    { lat: 48.4632058, lng: 19.9629753 },
    { lat: 48.4631009, lng: 19.9627319 },
    { lat: 48.462859, lng: 19.9627608 },
    { lat: 48.4628101, lng: 19.9626721 },
    { lat: 48.4627526, lng: 19.9621111 },
    { lat: 48.4628349, lng: 19.9620592 },
    { lat: 48.4630568, lng: 19.9621637 },
    { lat: 48.4631345, lng: 19.9621367 },
    { lat: 48.4631155, lng: 19.9619291 },
    { lat: 48.4634102, lng: 19.961843 },
    { lat: 48.4635006, lng: 19.9620867 },
    { lat: 48.4636468, lng: 19.9622942 },
    { lat: 48.4637292, lng: 19.9621894 },
    { lat: 48.4641467, lng: 19.9623383 },
    { lat: 48.464551, lng: 19.9623085 },
    { lat: 48.4645924, lng: 19.9622491 },
    { lat: 48.4645873, lng: 19.9621296 },
    { lat: 48.4643035, lng: 19.9616265 },
    { lat: 48.4643904, lng: 19.9613658 },
    { lat: 48.4645045, lng: 19.9613716 },
    { lat: 48.4647503, lng: 19.9616182 },
    { lat: 48.4649021, lng: 19.9613183 },
    { lat: 48.4649804, lng: 19.9613548 },
    { lat: 48.4650248, lng: 19.9614517 },
    { lat: 48.4652186, lng: 19.961556 },
    { lat: 48.4654011, lng: 19.9619311 },
    { lat: 48.4655449, lng: 19.9620781 },
    { lat: 48.4657254, lng: 19.9619028 },
    { lat: 48.4657882, lng: 19.962052 },
    { lat: 48.4658862, lng: 19.9621047 },
    { lat: 48.4661803, lng: 19.9621585 },
    { lat: 48.4666251, lng: 19.9621326 },
    { lat: 48.466872, lng: 19.9619482 },
    { lat: 48.466943, lng: 19.9617905 },
    { lat: 48.466909, lng: 19.9616245 },
    { lat: 48.4669451, lng: 19.9613892 },
    { lat: 48.4668888, lng: 19.9612019 },
    { lat: 48.4667469, lng: 19.9611239 },
    { lat: 48.4666301, lng: 19.9608635 },
    { lat: 48.4670242, lng: 19.9607264 },
    { lat: 48.4671596, lng: 19.9606012 },
    { lat: 48.4672095, lng: 19.9603984 },
    { lat: 48.4674011, lng: 19.9604241 },
    { lat: 48.4675164, lng: 19.9605163 },
    { lat: 48.467699, lng: 19.960491 },
    { lat: 48.4678335, lng: 19.9602889 },
    { lat: 48.4678089, lng: 19.9601308 },
    { lat: 48.4681249, lng: 19.9601975 },
    { lat: 48.4682547, lng: 19.9598826 },
    { lat: 48.4682492, lng: 19.9597539 },
    { lat: 48.4684205, lng: 19.9594894 },
    { lat: 48.4686106, lng: 19.95938 },
    { lat: 48.4689644, lng: 19.9593514 },
    { lat: 48.4690548, lng: 19.9590939 },
    { lat: 48.4690258, lng: 19.9589163 },
    { lat: 48.4690861, lng: 19.958797 },
    { lat: 48.4694582, lng: 19.9592068 },
    { lat: 48.4695347, lng: 19.9591998 },
    { lat: 48.4696195, lng: 19.9590515 },
    { lat: 48.4698739, lng: 19.9590442 },
    { lat: 48.4699292, lng: 19.9589686 },
    { lat: 48.4699054, lng: 19.9589123 },
    { lat: 48.4696727, lng: 19.9588409 },
    { lat: 48.4696218, lng: 19.9589444 },
    { lat: 48.4694541, lng: 19.9588696 },
    { lat: 48.469419, lng: 19.9583593 },
    { lat: 48.4694962, lng: 19.9582653 },
    { lat: 48.4696916, lng: 19.9582796 },
    { lat: 48.4697407, lng: 19.9585355 },
    { lat: 48.4699357, lng: 19.9585363 },
    { lat: 48.4699856, lng: 19.9584374 },
    { lat: 48.470055, lng: 19.9582657 },
    { lat: 48.4698851, lng: 19.9582174 },
    { lat: 48.4699804, lng: 19.9578913 },
    { lat: 48.4701349, lng: 19.9577327 },
    { lat: 48.4700728, lng: 19.9575445 },
    { lat: 48.4700966, lng: 19.9571244 },
    { lat: 48.4700113, lng: 19.9569174 },
    { lat: 48.4703208, lng: 19.9569634 },
    { lat: 48.4704645, lng: 19.9568438 },
    { lat: 48.4705565, lng: 19.9571825 },
    { lat: 48.4705295, lng: 19.9572902 },
    { lat: 48.4705717, lng: 19.9574359 },
    { lat: 48.4706692, lng: 19.9574738 },
    { lat: 48.4708774, lng: 19.9573403 },
    { lat: 48.4708921, lng: 19.9572352 },
    { lat: 48.4710529, lng: 19.9569876 },
    { lat: 48.4716009, lng: 19.9566277 },
    { lat: 48.4716152, lng: 19.9565407 },
    { lat: 48.4717302, lng: 19.9564353 },
    { lat: 48.4721063, lng: 19.9563272 },
    { lat: 48.4721764, lng: 19.9560925 },
    { lat: 48.4721506, lng: 19.9559636 },
    { lat: 48.4722051, lng: 19.955656 },
    { lat: 48.4720654, lng: 19.9555238 },
    { lat: 48.4718605, lng: 19.9555502 },
    { lat: 48.4717535, lng: 19.9551767 },
    { lat: 48.4717098, lng: 19.9551869 },
    { lat: 48.4717111, lng: 19.9553231 },
    { lat: 48.4716398, lng: 19.9554184 },
    { lat: 48.4714077, lng: 19.9550494 },
    { lat: 48.4712544, lng: 19.955046 },
    { lat: 48.4712968, lng: 19.9548999 },
    { lat: 48.471655, lng: 19.9546622 },
    { lat: 48.4717914, lng: 19.9546668 },
    { lat: 48.4719276, lng: 19.9544784 },
    { lat: 48.4719489, lng: 19.9540731 },
    { lat: 48.471914, lng: 19.9540228 },
    { lat: 48.4715477, lng: 19.95407 },
    { lat: 48.4715978, lng: 19.9543208 },
    { lat: 48.4715157, lng: 19.9543791 },
    { lat: 48.4713181, lng: 19.9543175 },
    { lat: 48.4684572, lng: 19.9530789 },
    { lat: 48.4683909, lng: 19.9529742 },
    { lat: 48.4680124, lng: 19.9528156 },
    { lat: 48.4677684, lng: 19.951155 },
    { lat: 48.4678076, lng: 19.9511999 },
    { lat: 48.4680801, lng: 19.9511457 },
    { lat: 48.4686668, lng: 19.9507145 },
    { lat: 48.4689948, lng: 19.9505613 },
    { lat: 48.4701682, lng: 19.9502598 },
    { lat: 48.4710449, lng: 19.9499481 },
    { lat: 48.4710458, lng: 19.949701 },
    { lat: 48.4711576, lng: 19.9491276 },
    { lat: 48.4713692, lng: 19.9484842 },
    { lat: 48.4713562, lng: 19.9483676 },
    { lat: 48.471443, lng: 19.9480497 },
    { lat: 48.4716461, lng: 19.9476848 },
    { lat: 48.4717547, lng: 19.9476183 },
    { lat: 48.4718627, lng: 19.947397 },
    { lat: 48.4719256, lng: 19.9473672 },
    { lat: 48.4720961, lng: 19.9470363 },
    { lat: 48.471916, lng: 19.9464512 },
    { lat: 48.4720312, lng: 19.9463805 },
    { lat: 48.4720957, lng: 19.9461488 },
    { lat: 48.4721732, lng: 19.9460896 },
    { lat: 48.4723617, lng: 19.9456746 },
    { lat: 48.4725037, lng: 19.9450386 },
    { lat: 48.4725152, lng: 19.9448312 },
    { lat: 48.4724393, lng: 19.9446912 },
    { lat: 48.4725699, lng: 19.9443467 },
    { lat: 48.472555, lng: 19.9440751 },
    { lat: 48.4726847, lng: 19.9434946 },
    { lat: 48.4726974, lng: 19.9431029 },
    { lat: 48.4727934, lng: 19.9426567 },
    { lat: 48.4729494, lng: 19.9421411 },
    { lat: 48.473092, lng: 19.9419737 },
    { lat: 48.4732794, lng: 19.9414799 },
    { lat: 48.4733465, lng: 19.9400382 },
    { lat: 48.473247, lng: 19.9396863 },
    { lat: 48.4732615, lng: 19.9395497 },
    { lat: 48.4732134, lng: 19.9393445 },
    { lat: 48.4732137, lng: 19.938687 },
    { lat: 48.47326, lng: 19.9384816 },
    { lat: 48.4731649, lng: 19.938233 },
    { lat: 48.4732743, lng: 19.9380744 },
    { lat: 48.4732519, lng: 19.9379193 },
    { lat: 48.4731124, lng: 19.937686 },
    { lat: 48.4638286, lng: 19.9351157 },
    { lat: 48.4657681, lng: 19.9211603 },
    { lat: 48.466786, lng: 19.9208842 },
    { lat: 48.4680961, lng: 19.9208837 },
    { lat: 48.4696433, lng: 19.9206019 },
    { lat: 48.4706624, lng: 19.9206458 },
    { lat: 48.4731988, lng: 19.914412 },
    { lat: 48.4722985, lng: 19.9136197 },
    { lat: 48.4748187, lng: 19.9089342 },
    { lat: 48.4744957, lng: 19.9088341 },
    { lat: 48.4743356, lng: 19.9089934 },
    { lat: 48.4743045, lng: 19.9087624 },
    { lat: 48.4739489, lng: 19.9088256 },
    { lat: 48.4740451, lng: 19.9085482 },
    { lat: 48.481313, lng: 19.895189 },
    { lat: 48.480631, lng: 19.895621 },
    { lat: 48.480595, lng: 19.895644 },
    { lat: 48.480552, lng: 19.895671 },
    { lat: 48.479898, lng: 19.896048 },
    { lat: 48.477258, lng: 19.897687 },
    { lat: 48.474806, lng: 19.89921 },
    { lat: 48.474724, lng: 19.899258 },
    { lat: 48.474608, lng: 19.899176 },
    { lat: 48.474537, lng: 19.899232 },
    { lat: 48.474508, lng: 19.89909 },
    { lat: 48.474482, lng: 19.898994 },
    { lat: 48.47447, lng: 19.898953 },
    { lat: 48.474381, lng: 19.898993 },
    { lat: 48.474348, lng: 19.898969 },
    { lat: 48.474239, lng: 19.898983 },
    { lat: 48.474175, lng: 19.89893 },
    { lat: 48.474104, lng: 19.898888 },
    { lat: 48.473965, lng: 19.898711 },
    { lat: 48.473899, lng: 19.898788 },
    { lat: 48.473815, lng: 19.898756 },
    { lat: 48.473732, lng: 19.898762 },
    { lat: 48.473591, lng: 19.898501 },
    { lat: 48.473563, lng: 19.898422 },
    { lat: 48.473489, lng: 19.898347 },
    { lat: 48.473399, lng: 19.898149 },
    { lat: 48.473381, lng: 19.89807 },
    { lat: 48.473459, lng: 19.898054 },
    { lat: 48.473482, lng: 19.897986 },
    { lat: 48.473404, lng: 19.897818 },
    { lat: 48.473208, lng: 19.897749 },
    { lat: 48.473148, lng: 19.897759 },
    { lat: 48.473091, lng: 19.897582 },
    { lat: 48.472964, lng: 19.897504 },
    { lat: 48.472892, lng: 19.897486 },
    { lat: 48.472798, lng: 19.897371 },
    { lat: 48.472651, lng: 19.897313 },
    { lat: 48.472665, lng: 19.897098 },
    { lat: 48.472611, lng: 19.89693 },
    { lat: 48.472584, lng: 19.896897 },
    { lat: 48.472471, lng: 19.896915 },
    { lat: 48.472342, lng: 19.896868 },
    { lat: 48.472318, lng: 19.896744 },
    { lat: 48.472268, lng: 19.896618 },
    { lat: 48.472216, lng: 19.896485 },
    { lat: 48.472232, lng: 19.89635 },
    { lat: 48.472207, lng: 19.896187 },
    { lat: 48.472039, lng: 19.896001 },
    { lat: 48.472006, lng: 19.895802 },
    { lat: 48.471851, lng: 19.895698 },
    { lat: 48.471743, lng: 19.8957 },
    { lat: 48.471694, lng: 19.895715 },
    { lat: 48.471654, lng: 19.895685 },
    { lat: 48.471624, lng: 19.895761 },
    { lat: 48.47133, lng: 19.896461 },
    { lat: 48.468909, lng: 19.90224 },
    { lat: 48.468815, lng: 19.902463 },
    { lat: 48.468762, lng: 19.902526 },
    { lat: 48.468738, lng: 19.902418 },
    { lat: 48.468705, lng: 19.902332 },
    { lat: 48.468665, lng: 19.902291 },
    { lat: 48.468618, lng: 19.902239 },
    { lat: 48.468684, lng: 19.902161 },
    { lat: 48.468715, lng: 19.902126 },
    { lat: 48.468766, lng: 19.902084 },
    { lat: 48.468756, lng: 19.902031 },
    { lat: 48.468708, lng: 19.902024 },
    { lat: 48.468614, lng: 19.901926 },
    { lat: 48.468556, lng: 19.902034 },
    { lat: 48.468525, lng: 19.901995 },
    { lat: 48.468508, lng: 19.901971 },
    { lat: 48.468487, lng: 19.901927 },
    { lat: 48.468476, lng: 19.901851 },
    { lat: 48.468512, lng: 19.901771 },
    { lat: 48.468514, lng: 19.901669 },
    { lat: 48.468451, lng: 19.901603 },
    { lat: 48.468284, lng: 19.901553 },
    { lat: 48.468205, lng: 19.90153 },
    { lat: 48.468177, lng: 19.901413 },
    { lat: 48.468157, lng: 19.901251 },
    { lat: 48.468074, lng: 19.901226 },
    { lat: 48.467995, lng: 19.901206 },
    { lat: 48.467942, lng: 19.901125 },
    { lat: 48.467898, lng: 19.901036 },
    { lat: 48.467882, lng: 19.901006 },
    { lat: 48.467737, lng: 19.90086 },
    { lat: 48.467691, lng: 19.900813 },
    { lat: 48.467627, lng: 19.900774 },
    { lat: 48.46757, lng: 19.90074 },
    { lat: 48.467556, lng: 19.900731 },
    { lat: 48.4675, lng: 19.900765 },
    { lat: 48.467356, lng: 19.900736 },
    { lat: 48.467148, lng: 19.900615 },
    { lat: 48.467065, lng: 19.900581 },
    { lat: 48.467026, lng: 19.900566 },
    { lat: 48.467012, lng: 19.90056 },
    { lat: 48.466864, lng: 19.900541 },
    { lat: 48.466777, lng: 19.900667 },
    { lat: 48.466598, lng: 19.900537 },
    { lat: 48.466548, lng: 19.900446 },
    { lat: 48.46654, lng: 19.900311 },
    { lat: 48.466531, lng: 19.900198 },
    { lat: 48.466485, lng: 19.900137 },
    { lat: 48.466456, lng: 19.90007 },
    { lat: 48.466421, lng: 19.899993 },
    { lat: 48.466403, lng: 19.899936 },
    { lat: 48.466359, lng: 19.899796 },
    { lat: 48.466321, lng: 19.899663 },
    { lat: 48.466301, lng: 19.899587 },
    { lat: 48.466255, lng: 19.899542 },
    { lat: 48.466198, lng: 19.899492 },
    { lat: 48.466122, lng: 19.899299 },
    { lat: 48.465995, lng: 19.89931 },
    { lat: 48.465911, lng: 19.899317 },
    { lat: 48.465819, lng: 19.899325 },
    { lat: 48.465803, lng: 19.899254 },
    { lat: 48.465792, lng: 19.899185 },
    { lat: 48.465777, lng: 19.899081 },
    { lat: 48.465769, lng: 19.899026 },
    { lat: 48.465658, lng: 19.898962 },
    { lat: 48.465551, lng: 19.89893 },
    { lat: 48.465449, lng: 19.898898 },
    { lat: 48.465448, lng: 19.898885 },
    { lat: 48.465417, lng: 19.898721 },
    { lat: 48.465317, lng: 19.898564 },
    { lat: 48.465301, lng: 19.898295 },
    { lat: 48.465206, lng: 19.898057 },
    { lat: 48.465172, lng: 19.897777 },
    { lat: 48.465003, lng: 19.897586 },
    { lat: 48.464877, lng: 19.897673 },
    { lat: 48.464746, lng: 19.89754 },
    { lat: 48.464652, lng: 19.897576 },
    { lat: 48.464513, lng: 19.897554 },
    { lat: 48.464476, lng: 19.897501 },
    { lat: 48.464428, lng: 19.897433 },
    { lat: 48.464398, lng: 19.89739 },
    { lat: 48.46441, lng: 19.89715 },
    { lat: 48.464095, lng: 19.896883 },
    { lat: 48.463825, lng: 19.896518 },
    { lat: 48.463814, lng: 19.8965 },
    { lat: 48.463783, lng: 19.896321 },
    { lat: 48.463514, lng: 19.896282 },
    { lat: 48.46343, lng: 19.896156 },
    { lat: 48.463362, lng: 19.896039 },
    { lat: 48.463187, lng: 19.895975 },
    { lat: 48.463118, lng: 19.896185 },
    { lat: 48.462915, lng: 19.896064 },
    { lat: 48.462779, lng: 19.895945 },
    { lat: 48.462569, lng: 19.895785 },
    { lat: 48.462407, lng: 19.89566 },
    { lat: 48.462208, lng: 19.895714 },
    { lat: 48.462026, lng: 19.895496 },
    { lat: 48.461926, lng: 19.895284 },
    { lat: 48.461865, lng: 19.895121 },
    { lat: 48.46178, lng: 19.895069 },
    { lat: 48.461715, lng: 19.895174 },
    { lat: 48.461636, lng: 19.895194 },
    { lat: 48.461584, lng: 19.895205 },
    { lat: 48.46143, lng: 19.894829 },
    { lat: 48.461403, lng: 19.894788 },
    { lat: 48.461315, lng: 19.894671 },
    { lat: 48.461285, lng: 19.894632 },
    { lat: 48.461158, lng: 19.894336 },
    { lat: 48.46112, lng: 19.894251 },
    { lat: 48.461022, lng: 19.894081 },
    { lat: 48.460927, lng: 19.893978 },
    { lat: 48.460806, lng: 19.893811 },
    { lat: 48.46071, lng: 19.893674 },
    { lat: 48.460636, lng: 19.893583 },
    { lat: 48.460515, lng: 19.893476 },
    { lat: 48.460296, lng: 19.89327 },
    { lat: 48.460242, lng: 19.893071 },
    { lat: 48.460161, lng: 19.893154 },
    { lat: 48.459936, lng: 19.893106 },
    { lat: 48.459843, lng: 19.893063 },
    { lat: 48.459773, lng: 19.89303 },
    { lat: 48.45974, lng: 19.893015 },
    { lat: 48.459703, lng: 19.892973 },
    { lat: 48.459633, lng: 19.892896 },
    { lat: 48.459465, lng: 19.892733 },
    { lat: 48.459408, lng: 19.892686 },
    { lat: 48.459344, lng: 19.892641 },
    { lat: 48.459261, lng: 19.892597 },
    { lat: 48.459197, lng: 19.892431 },
    { lat: 48.459096, lng: 19.892213 },
    { lat: 48.459031, lng: 19.892131 },
    { lat: 48.458961, lng: 19.892065 },
    { lat: 48.458875, lng: 19.892023 },
    { lat: 48.458802, lng: 19.892026 },
    { lat: 48.458571, lng: 19.89192 },
    { lat: 48.458513, lng: 19.89191 },
    { lat: 48.458438, lng: 19.891902 },
    { lat: 48.458333, lng: 19.891808 },
    { lat: 48.45822, lng: 19.891666 },
    { lat: 48.458095, lng: 19.891506 },
    { lat: 48.458028, lng: 19.891486 },
    { lat: 48.457972, lng: 19.891437 },
    { lat: 48.457881, lng: 19.891398 },
    { lat: 48.457834, lng: 19.891377 },
    { lat: 48.457783, lng: 19.89133 },
    { lat: 48.457682, lng: 19.891274 },
    { lat: 48.457512, lng: 19.891176 },
    { lat: 48.45725, lng: 19.891029 },
    { lat: 48.457184, lng: 19.891017 },
    { lat: 48.457025, lng: 19.89106 },
    { lat: 48.456932, lng: 19.891238 },
    { lat: 48.456889, lng: 19.891391 },
    { lat: 48.456808, lng: 19.89146 },
    { lat: 48.456754, lng: 19.891195 },
    { lat: 48.45661, lng: 19.89109 },
    { lat: 48.456188, lng: 19.890739 },
    { lat: 48.456127, lng: 19.890677 },
    { lat: 48.455925, lng: 19.890448 },
    { lat: 48.455852, lng: 19.890401 },
    { lat: 48.455488, lng: 19.890287 },
    { lat: 48.455387, lng: 19.890505 },
    { lat: 48.455021, lng: 19.891293 },
    { lat: 48.454819, lng: 19.891687 },
    { lat: 48.454542, lng: 19.892387 },
    { lat: 48.454266, lng: 19.893104 },
    { lat: 48.453751, lng: 19.893648 },
    { lat: 48.453282, lng: 19.89411 },
    { lat: 48.453135, lng: 19.894252 },
    { lat: 48.452879, lng: 19.894439 },
    { lat: 48.452714, lng: 19.894656 },
    { lat: 48.452593, lng: 19.894753 },
    { lat: 48.451828, lng: 19.895553 },
    { lat: 48.451467, lng: 19.895918 },
    { lat: 48.450987, lng: 19.896464 },
    { lat: 48.450578, lng: 19.89678 },
    { lat: 48.450483, lng: 19.896846 },
    { lat: 48.450438, lng: 19.896875 },
    { lat: 48.450381, lng: 19.89691 },
    { lat: 48.450064, lng: 19.897111 },
    { lat: 48.449905, lng: 19.897211 },
    { lat: 48.449496, lng: 19.897446 },
    { lat: 48.449375, lng: 19.897516 },
    { lat: 48.449259, lng: 19.897601 },
    { lat: 48.449188, lng: 19.89768 },
    { lat: 48.449122, lng: 19.897718 },
    { lat: 48.449107, lng: 19.897723 },
    { lat: 48.449023, lng: 19.897829 },
    { lat: 48.448967, lng: 19.897856 },
    { lat: 48.44881, lng: 19.897969 },
    { lat: 48.448758, lng: 19.89803 },
    { lat: 48.448698, lng: 19.898052 },
    { lat: 48.448439, lng: 19.898251 },
    { lat: 48.448119, lng: 19.898497 },
    { lat: 48.447755, lng: 19.898648 },
    { lat: 48.447514, lng: 19.898786 },
    { lat: 48.447497, lng: 19.8988 },
    { lat: 48.447426, lng: 19.898832 },
    { lat: 48.447123, lng: 19.898976 },
    { lat: 48.446938, lng: 19.899018 },
    { lat: 48.446513, lng: 19.898786 },
    { lat: 48.446463, lng: 19.898768 },
    { lat: 48.446089, lng: 19.898855 },
    { lat: 48.445941, lng: 19.898827 },
    { lat: 48.445817, lng: 19.898711 },
    { lat: 48.445745, lng: 19.898642 },
    { lat: 48.445678, lng: 19.898569 },
    { lat: 48.44565, lng: 19.898536 },
    { lat: 48.445562, lng: 19.898666 },
    { lat: 48.445263, lng: 19.899134 },
    { lat: 48.445064, lng: 19.899423 },
    { lat: 48.445023, lng: 19.899484 },
    { lat: 48.444901, lng: 19.899735 },
    { lat: 48.444604, lng: 19.900189 },
    { lat: 48.444349, lng: 19.900578 },
    { lat: 48.443948, lng: 19.901219 },
    { lat: 48.443758, lng: 19.901445 },
    { lat: 48.443425, lng: 19.901935 },
    { lat: 48.443378, lng: 19.902021 },
    { lat: 48.443256, lng: 19.902247 },
    { lat: 48.442885, lng: 19.902833 },
    { lat: 48.44262, lng: 19.90306 },
    { lat: 48.44231, lng: 19.903475 },
    { lat: 48.44195, lng: 19.903784 },
    { lat: 48.441886, lng: 19.903852 },
    { lat: 48.441784, lng: 19.903944 },
    { lat: 48.441536, lng: 19.904371 },
    { lat: 48.4414, lng: 19.904485 },
    { lat: 48.441091, lng: 19.904943 },
    { lat: 48.440901, lng: 19.905201 },
    { lat: 48.440538, lng: 19.905815 },
    { lat: 48.440394, lng: 19.90606 },
    { lat: 48.44008, lng: 19.906832 },
    { lat: 48.440035, lng: 19.90696 },
    { lat: 48.439992, lng: 19.907288 },
    { lat: 48.43982, lng: 19.907789 },
    { lat: 48.439688, lng: 19.908187 },
    { lat: 48.439306, lng: 19.908797 },
    { lat: 48.439238, lng: 19.909052 },
    { lat: 48.43918, lng: 19.909157 },
    { lat: 48.439019, lng: 19.909273 },
    { lat: 48.438922, lng: 19.909432 },
    { lat: 48.438859, lng: 19.90958 },
    { lat: 48.438803, lng: 19.90964 },
    { lat: 48.438692, lng: 19.909805 },
    { lat: 48.438501, lng: 19.910279 },
    { lat: 48.438323, lng: 19.910668 },
    { lat: 48.4383581, lng: 19.9107251 },
    { lat: 48.439529, lng: 19.9158669 },
    { lat: 48.4397869, lng: 19.9170007 },
  ],
  Širkovce: [
    { lat: 48.3160566, lng: 20.1133996 },
    { lat: 48.3160761, lng: 20.1132729 },
    { lat: 48.3140572, lng: 20.1075155 },
    { lat: 48.3096233, lng: 20.095164 },
    { lat: 48.3093593, lng: 20.0942297 },
    { lat: 48.3087353, lng: 20.0926497 },
    { lat: 48.3067132, lng: 20.0869483 },
    { lat: 48.3065029, lng: 20.0869307 },
    { lat: 48.3059825, lng: 20.0865499 },
    { lat: 48.305859, lng: 20.0863962 },
    { lat: 48.3057273, lng: 20.0858816 },
    { lat: 48.3012561, lng: 20.0841567 },
    { lat: 48.2990474, lng: 20.0833726 },
    { lat: 48.2981078, lng: 20.0831243 },
    { lat: 48.2981039, lng: 20.0828923 },
    { lat: 48.2979983, lng: 20.0827659 },
    { lat: 48.2977509, lng: 20.0827981 },
    { lat: 48.2974299, lng: 20.0826391 },
    { lat: 48.2973227, lng: 20.0823293 },
    { lat: 48.2972941, lng: 20.0819711 },
    { lat: 48.2972221, lng: 20.0817535 },
    { lat: 48.2972844, lng: 20.0815426 },
    { lat: 48.2974985, lng: 20.0812467 },
    { lat: 48.2972514, lng: 20.0785497 },
    { lat: 48.2969003, lng: 20.0723388 },
    { lat: 48.2968687, lng: 20.0722041 },
    { lat: 48.2965916, lng: 20.0724992 },
    { lat: 48.2961866, lng: 20.0716416 },
    { lat: 48.2960662, lng: 20.0711303 },
    { lat: 48.2959352, lng: 20.06955 },
    { lat: 48.2957404, lng: 20.068519 },
    { lat: 48.2954754, lng: 20.0676173 },
    { lat: 48.2952847, lng: 20.0672046 },
    { lat: 48.2949386, lng: 20.0670084 },
    { lat: 48.2939313, lng: 20.0667267 },
    { lat: 48.2939661, lng: 20.0671341 },
    { lat: 48.2923222, lng: 20.0667149 },
    { lat: 48.2921633, lng: 20.0672324 },
    { lat: 48.2905707, lng: 20.0679346 },
    { lat: 48.2901941, lng: 20.0682548 },
    { lat: 48.2899875, lng: 20.0679713 },
    { lat: 48.2895995, lng: 20.0670907 },
    { lat: 48.2893955, lng: 20.0667667 },
    { lat: 48.2886774, lng: 20.0658564 },
    { lat: 48.2867923, lng: 20.0648387 },
    { lat: 48.2862382, lng: 20.0643016 },
    { lat: 48.2856348, lng: 20.0639827 },
    { lat: 48.2850645, lng: 20.0638952 },
    { lat: 48.2839137, lng: 20.0633388 },
    { lat: 48.2829982, lng: 20.0630972 },
    { lat: 48.2825566, lng: 20.0627738 },
    { lat: 48.2823178, lng: 20.0622321 },
    { lat: 48.2817677, lng: 20.0616784 },
    { lat: 48.2807549, lng: 20.0609982 },
    { lat: 48.2803291, lng: 20.0608113 },
    { lat: 48.2799521, lng: 20.0604416 },
    { lat: 48.2798192, lng: 20.0604646 },
    { lat: 48.2785498, lng: 20.0599258 },
    { lat: 48.2784542, lng: 20.0598255 },
    { lat: 48.2779218, lng: 20.0598618 },
    { lat: 48.2775444, lng: 20.0601008 },
    { lat: 48.2770479, lng: 20.0601875 },
    { lat: 48.276344, lng: 20.0601928 },
    { lat: 48.275958, lng: 20.060068 },
    { lat: 48.2756896, lng: 20.0598971 },
    { lat: 48.2753737, lng: 20.0601844 },
    { lat: 48.2750233, lng: 20.0610425 },
    { lat: 48.2748075, lng: 20.0614106 },
    { lat: 48.2746223, lng: 20.0614795 },
    { lat: 48.2735001, lng: 20.0609847 },
    { lat: 48.272738, lng: 20.0604823 },
    { lat: 48.2724289, lng: 20.0603885 },
    { lat: 48.2719302, lng: 20.0600165 },
    { lat: 48.2718409, lng: 20.0600372 },
    { lat: 48.2717266, lng: 20.0602179 },
    { lat: 48.271624, lng: 20.0601045 },
    { lat: 48.2712479, lng: 20.0605109 },
    { lat: 48.2709133, lng: 20.0607812 },
    { lat: 48.270483, lng: 20.0610519 },
    { lat: 48.2703897, lng: 20.0610581 },
    { lat: 48.270292, lng: 20.0610347 },
    { lat: 48.2698938, lng: 20.0605646 },
    { lat: 48.2697925, lng: 20.0605132 },
    { lat: 48.2691812, lng: 20.0604678 },
    { lat: 48.2687764, lng: 20.0607015 },
    { lat: 48.2685228, lng: 20.060722 },
    { lat: 48.268423, lng: 20.0606249 },
    { lat: 48.2680461, lng: 20.0599597 },
    { lat: 48.2680085, lng: 20.0599795 },
    { lat: 48.2677689, lng: 20.0600121 },
    { lat: 48.2676702, lng: 20.0602681 },
    { lat: 48.2676364, lng: 20.0602384 },
    { lat: 48.2675115, lng: 20.0611325 },
    { lat: 48.2673119, lng: 20.0621259 },
    { lat: 48.266995, lng: 20.063202 },
    { lat: 48.2667179, lng: 20.0636376 },
    { lat: 48.2662497, lng: 20.0637252 },
    { lat: 48.266041, lng: 20.0638267 },
    { lat: 48.2657688, lng: 20.0637022 },
    { lat: 48.2651775, lng: 20.0636771 },
    { lat: 48.2649819, lng: 20.0638729 },
    { lat: 48.2645829, lng: 20.0655508 },
    { lat: 48.2640448, lng: 20.0665552 },
    { lat: 48.2633758, lng: 20.0671001 },
    { lat: 48.2631761, lng: 20.0677176 },
    { lat: 48.2629758, lng: 20.0680209 },
    { lat: 48.2625898, lng: 20.0690573 },
    { lat: 48.2618795, lng: 20.0698574 },
    { lat: 48.2606918, lng: 20.0721482 },
    { lat: 48.2604657, lng: 20.0724842 },
    { lat: 48.2602171, lng: 20.0734095 },
    { lat: 48.2599004, lng: 20.0735963 },
    { lat: 48.2593613, lng: 20.0741205 },
    { lat: 48.2588198, lng: 20.0751897 },
    { lat: 48.2585113, lng: 20.0755687 },
    { lat: 48.2581816, lng: 20.0763661 },
    { lat: 48.2581055, lng: 20.0770286 },
    { lat: 48.2580407, lng: 20.0771806 },
    { lat: 48.2579374, lng: 20.0772966 },
    { lat: 48.2576551, lng: 20.0774371 },
    { lat: 48.2573955, lng: 20.0771272 },
    { lat: 48.2571986, lng: 20.0771073 },
    { lat: 48.2569985, lng: 20.0771723 },
    { lat: 48.2565591, lng: 20.0776571 },
    { lat: 48.255827, lng: 20.0787697 },
    { lat: 48.2555853, lng: 20.0793277 },
    { lat: 48.2554023, lng: 20.0795582 },
    { lat: 48.255242, lng: 20.0799846 },
    { lat: 48.2549813, lng: 20.0801636 },
    { lat: 48.2546763, lng: 20.0809017 },
    { lat: 48.2541338, lng: 20.0815898 },
    { lat: 48.2536148, lng: 20.0824679 },
    { lat: 48.2533984, lng: 20.0826266 },
    { lat: 48.253429, lng: 20.0826609 },
    { lat: 48.2514486, lng: 20.0854536 },
    { lat: 48.2504067, lng: 20.086593 },
    { lat: 48.2498112, lng: 20.0874725 },
    { lat: 48.2495168, lng: 20.0880635 },
    { lat: 48.2491142, lng: 20.0897927 },
    { lat: 48.2490823, lng: 20.0898653 },
    { lat: 48.2570256, lng: 20.0978716 },
    { lat: 48.2593364, lng: 20.1002541 },
    { lat: 48.2603907, lng: 20.1014587 },
    { lat: 48.2608958, lng: 20.1025618 },
    { lat: 48.2623769, lng: 20.1008909 },
    { lat: 48.2624195, lng: 20.1009679 },
    { lat: 48.262925, lng: 20.1003099 },
    { lat: 48.2641462, lng: 20.0984058 },
    { lat: 48.2650431, lng: 20.096337 },
    { lat: 48.2654238, lng: 20.0970494 },
    { lat: 48.2661943, lng: 20.0981959 },
    { lat: 48.2667355, lng: 20.0992173 },
    { lat: 48.2668959, lng: 20.0996892 },
    { lat: 48.2671534, lng: 20.1002257 },
    { lat: 48.2676506, lng: 20.1007825 },
    { lat: 48.2681613, lng: 20.1010661 },
    { lat: 48.269266, lng: 20.1013314 },
    { lat: 48.271879, lng: 20.1015731 },
    { lat: 48.2729693, lng: 20.1018154 },
    { lat: 48.2745976, lng: 20.1022692 },
    { lat: 48.2769357, lng: 20.1033553 },
    { lat: 48.277029, lng: 20.1034602 },
    { lat: 48.2788866, lng: 20.1003267 },
    { lat: 48.2816092, lng: 20.1019087 },
    { lat: 48.2816285, lng: 20.1027044 },
    { lat: 48.2815583, lng: 20.1029987 },
    { lat: 48.2813969, lng: 20.103315 },
    { lat: 48.2807098, lng: 20.1038337 },
    { lat: 48.280376, lng: 20.1042338 },
    { lat: 48.2813939, lng: 20.104797 },
    { lat: 48.2824703, lng: 20.1052797 },
    { lat: 48.2826382, lng: 20.1054721 },
    { lat: 48.2828479, lng: 20.1059715 },
    { lat: 48.2828552, lng: 20.107047 },
    { lat: 48.2832892, lng: 20.1077672 },
    { lat: 48.2839331, lng: 20.1083349 },
    { lat: 48.2847989, lng: 20.1089763 },
    { lat: 48.2861464, lng: 20.109286 },
    { lat: 48.2872391, lng: 20.1093451 },
    { lat: 48.2875141, lng: 20.1101943 },
    { lat: 48.2875474, lng: 20.1104869 },
    { lat: 48.2874146, lng: 20.1121282 },
    { lat: 48.2872529, lng: 20.1130733 },
    { lat: 48.2903449, lng: 20.1153426 },
    { lat: 48.2914, lng: 20.116297 },
    { lat: 48.2992739, lng: 20.1175672 },
    { lat: 48.3002886, lng: 20.1174817 },
    { lat: 48.3013323, lng: 20.1171716 },
    { lat: 48.3044212, lng: 20.1165742 },
    { lat: 48.305315, lng: 20.1164237 },
    { lat: 48.3057054, lng: 20.1163318 },
    { lat: 48.3055471, lng: 20.1158801 },
    { lat: 48.3056885, lng: 20.1157018 },
    { lat: 48.305644, lng: 20.1153886 },
    { lat: 48.3059039, lng: 20.1146959 },
    { lat: 48.3063366, lng: 20.114318 },
    { lat: 48.3065418, lng: 20.1142113 },
    { lat: 48.3067182, lng: 20.1140941 },
    { lat: 48.3079284, lng: 20.1139925 },
    { lat: 48.309538, lng: 20.1136951 },
    { lat: 48.3098567, lng: 20.1138819 },
    { lat: 48.3122342, lng: 20.113851 },
    { lat: 48.3129059, lng: 20.1135288 },
    { lat: 48.3136778, lng: 20.1125795 },
    { lat: 48.3138907, lng: 20.1124417 },
    { lat: 48.3140796, lng: 20.1124377 },
    { lat: 48.3149465, lng: 20.112918 },
    { lat: 48.3157718, lng: 20.1132132 },
    { lat: 48.3159163, lng: 20.1133283 },
    { lat: 48.3160566, lng: 20.1133996 },
  ],
  Kesovce: [
    { lat: 48.3773942, lng: 20.3769695 },
    { lat: 48.3777021, lng: 20.3765735 },
    { lat: 48.3779152, lng: 20.376116 },
    { lat: 48.3779547, lng: 20.3753045 },
    { lat: 48.3784041, lng: 20.3731894 },
    { lat: 48.3793932, lng: 20.3729598 },
    { lat: 48.3804151, lng: 20.3726267 },
    { lat: 48.3817772, lng: 20.3723236 },
    { lat: 48.3826406, lng: 20.3719615 },
    { lat: 48.3827218, lng: 20.3714464 },
    { lat: 48.3830341, lng: 20.3703796 },
    { lat: 48.3852234, lng: 20.3707006 },
    { lat: 48.385269, lng: 20.370568 },
    { lat: 48.3863598, lng: 20.3698585 },
    { lat: 48.3867241, lng: 20.3696438 },
    { lat: 48.3879913, lng: 20.3691559 },
    { lat: 48.3884497, lng: 20.3687827 },
    { lat: 48.3884802, lng: 20.3687545 },
    { lat: 48.3875656, lng: 20.3657926 },
    { lat: 48.3867242, lng: 20.3643705 },
    { lat: 48.383821, lng: 20.3591068 },
    { lat: 48.3837112, lng: 20.3590081 },
    { lat: 48.3831001, lng: 20.3576294 },
    { lat: 48.3829067, lng: 20.3570863 },
    { lat: 48.3807927, lng: 20.3523775 },
    { lat: 48.3803796, lng: 20.3511399 },
    { lat: 48.3772335, lng: 20.3479773 },
    { lat: 48.377106, lng: 20.3481751 },
    { lat: 48.3769908, lng: 20.3480772 },
    { lat: 48.3768113, lng: 20.3483863 },
    { lat: 48.3760287, lng: 20.3478668 },
    { lat: 48.3755516, lng: 20.3474451 },
    { lat: 48.374804, lng: 20.3469615 },
    { lat: 48.3741625, lng: 20.3462539 },
    { lat: 48.3738341, lng: 20.3457734 },
    { lat: 48.373618, lng: 20.3453462 },
    { lat: 48.3730964, lng: 20.3449334 },
    { lat: 48.3726273, lng: 20.344749 },
    { lat: 48.3721223, lng: 20.3443327 },
    { lat: 48.3716477, lng: 20.344174 },
    { lat: 48.3714962, lng: 20.3445064 },
    { lat: 48.370899, lng: 20.3441535 },
    { lat: 48.3704976, lng: 20.3428149 },
    { lat: 48.3702783, lng: 20.3422743 },
    { lat: 48.3695435, lng: 20.3408528 },
    { lat: 48.369306, lng: 20.3411171 },
    { lat: 48.3664341, lng: 20.3443977 },
    { lat: 48.3662931, lng: 20.3443907 },
    { lat: 48.3652591, lng: 20.350152 },
    { lat: 48.3640486, lng: 20.3575812 },
    { lat: 48.3646423, lng: 20.3578149 },
    { lat: 48.3655338, lng: 20.3585282 },
    { lat: 48.3665087, lng: 20.3594754 },
    { lat: 48.3669579, lng: 20.3600473 },
    { lat: 48.3671716, lng: 20.3602424 },
    { lat: 48.3682026, lng: 20.3605441 },
    { lat: 48.3693971, lng: 20.3620409 },
    { lat: 48.3698625, lng: 20.3629124 },
    { lat: 48.3703214, lng: 20.3642889 },
    { lat: 48.370461, lng: 20.3645749 },
    { lat: 48.3713813, lng: 20.3657084 },
    { lat: 48.3716785, lng: 20.366202 },
    { lat: 48.3720598, lng: 20.367088 },
    { lat: 48.3725846, lng: 20.3692901 },
    { lat: 48.3727727, lng: 20.3696672 },
    { lat: 48.3730124, lng: 20.3703879 },
    { lat: 48.3731569, lng: 20.3706866 },
    { lat: 48.3733385, lng: 20.3712742 },
    { lat: 48.3734655, lng: 20.3719003 },
    { lat: 48.3735639, lng: 20.3721195 },
    { lat: 48.3750006, lng: 20.3749525 },
    { lat: 48.3754875, lng: 20.3753112 },
    { lat: 48.3760924, lng: 20.3758959 },
    { lat: 48.3765779, lng: 20.3762768 },
    { lat: 48.3768451, lng: 20.3764844 },
    { lat: 48.3773942, lng: 20.3769695 },
  ],
  VeľkýBlh: [
    { lat: 48.4231845, lng: 20.1279554 },
    { lat: 48.4232661, lng: 20.1282929 },
    { lat: 48.423396, lng: 20.1280008 },
    { lat: 48.4235555, lng: 20.1279882 },
    { lat: 48.4246761, lng: 20.130022 },
    { lat: 48.42659, lng: 20.1326993 },
    { lat: 48.4279368, lng: 20.1347022 },
    { lat: 48.4291159, lng: 20.1335383 },
    { lat: 48.4307328, lng: 20.132618 },
    { lat: 48.4321286, lng: 20.1339735 },
    { lat: 48.4329984, lng: 20.1349438 },
    { lat: 48.4321602, lng: 20.1359887 },
    { lat: 48.4318087, lng: 20.1363158 },
    { lat: 48.4314032, lng: 20.1365934 },
    { lat: 48.4311702, lng: 20.1366801 },
    { lat: 48.4306763, lng: 20.1366941 },
    { lat: 48.4301107, lng: 20.1368243 },
    { lat: 48.4298455, lng: 20.1369537 },
    { lat: 48.429662, lng: 20.1371756 },
    { lat: 48.4315053, lng: 20.1400303 },
    { lat: 48.4324279, lng: 20.1413295 },
    { lat: 48.4339885, lng: 20.1433025 },
    { lat: 48.4358669, lng: 20.1453946 },
    { lat: 48.4377068, lng: 20.1477295 },
    { lat: 48.4382197, lng: 20.1481831 },
    { lat: 48.4383301, lng: 20.1485979 },
    { lat: 48.438292, lng: 20.1489888 },
    { lat: 48.438415, lng: 20.1502644 },
    { lat: 48.4388344, lng: 20.1513017 },
    { lat: 48.4392712, lng: 20.1515151 },
    { lat: 48.4400785, lng: 20.1525593 },
    { lat: 48.4403838, lng: 20.1531442 },
    { lat: 48.4408944, lng: 20.152932 },
    { lat: 48.4420603, lng: 20.1529873 },
    { lat: 48.4421886, lng: 20.1527116 },
    { lat: 48.4427919, lng: 20.1524525 },
    { lat: 48.4432605, lng: 20.1524789 },
    { lat: 48.4444824, lng: 20.153059 },
    { lat: 48.4445717, lng: 20.1537855 },
    { lat: 48.4446719, lng: 20.1540027 },
    { lat: 48.4447447, lng: 20.154134 },
    { lat: 48.4448999, lng: 20.1542105 },
    { lat: 48.4449729, lng: 20.1543695 },
    { lat: 48.4452096, lng: 20.1545943 },
    { lat: 48.445231, lng: 20.154763 },
    { lat: 48.4454108, lng: 20.1550047 },
    { lat: 48.4454786, lng: 20.1553128 },
    { lat: 48.4455658, lng: 20.1554616 },
    { lat: 48.4457969, lng: 20.1556298 },
    { lat: 48.446029, lng: 20.1560509 },
    { lat: 48.4460675, lng: 20.1566891 },
    { lat: 48.4461777, lng: 20.1571867 },
    { lat: 48.4462765, lng: 20.1571801 },
    { lat: 48.446297, lng: 20.1575737 },
    { lat: 48.446475, lng: 20.1581559 },
    { lat: 48.4465374, lng: 20.1588015 },
    { lat: 48.4466542, lng: 20.1593237 },
    { lat: 48.4469785, lng: 20.1604572 },
    { lat: 48.4470857, lng: 20.1606254 },
    { lat: 48.4472061, lng: 20.1606793 },
    { lat: 48.4473806, lng: 20.1609549 },
    { lat: 48.4478353, lng: 20.1611942 },
    { lat: 48.448243, lng: 20.1614714 },
    { lat: 48.4485242, lng: 20.1614896 },
    { lat: 48.4488824, lng: 20.1613783 },
    { lat: 48.4495825, lng: 20.1613691 },
    { lat: 48.4502664, lng: 20.1614816 },
    { lat: 48.4508634, lng: 20.1614555 },
    { lat: 48.4516117, lng: 20.1611619 },
    { lat: 48.4524201, lng: 20.1605168 },
    { lat: 48.4529713, lng: 20.1600021 },
    { lat: 48.4541999, lng: 20.158443 },
    { lat: 48.4546839, lng: 20.1583535 },
    { lat: 48.4551653, lng: 20.1581439 },
    { lat: 48.4553989, lng: 20.1581828 },
    { lat: 48.4556709, lng: 20.1581149 },
    { lat: 48.4565201, lng: 20.1578368 },
    { lat: 48.4568266, lng: 20.1576436 },
    { lat: 48.4572828, lng: 20.1575605 },
    { lat: 48.4575799, lng: 20.157722 },
    { lat: 48.4580774, lng: 20.157606 },
    { lat: 48.4582127, lng: 20.1600758 },
    { lat: 48.4584004, lng: 20.1603659 },
    { lat: 48.459638, lng: 20.1612238 },
    { lat: 48.4606399, lng: 20.161794 },
    { lat: 48.4618786, lng: 20.1617304 },
    { lat: 48.4629091, lng: 20.1604256 },
    { lat: 48.4635261, lng: 20.160112 },
    { lat: 48.463837, lng: 20.1598347 },
    { lat: 48.4645054, lng: 20.1590443 },
    { lat: 48.4647818, lng: 20.158614 },
    { lat: 48.4655652, lng: 20.15785 },
    { lat: 48.4659108, lng: 20.1573749 },
    { lat: 48.4671972, lng: 20.1566853 },
    { lat: 48.4681913, lng: 20.1563735 },
    { lat: 48.4692881, lng: 20.1552569 },
    { lat: 48.4702523, lng: 20.1551207 },
    { lat: 48.4715392, lng: 20.1555536 },
    { lat: 48.472116, lng: 20.1562722 },
    { lat: 48.4721152, lng: 20.1562297 },
    { lat: 48.4728449, lng: 20.1560292 },
    { lat: 48.4734523, lng: 20.155678 },
    { lat: 48.4741413, lng: 20.1555287 },
    { lat: 48.4742003, lng: 20.1560107 },
    { lat: 48.4743287, lng: 20.1564816 },
    { lat: 48.4745443, lng: 20.1569644 },
    { lat: 48.474882, lng: 20.1574584 },
    { lat: 48.4754034, lng: 20.1585911 },
    { lat: 48.4758424, lng: 20.1596941 },
    { lat: 48.4761088, lng: 20.1600711 },
    { lat: 48.4761886, lng: 20.160374 },
    { lat: 48.4761854, lng: 20.1612369 },
    { lat: 48.4762404, lng: 20.1615382 },
    { lat: 48.4764417, lng: 20.1617547 },
    { lat: 48.4769179, lng: 20.1617973 },
    { lat: 48.477189, lng: 20.1619019 },
    { lat: 48.4777507, lng: 20.162255 },
    { lat: 48.4780767, lng: 20.1624896 },
    { lat: 48.4787862, lng: 20.1633218 },
    { lat: 48.4792738, lng: 20.1636239 },
    { lat: 48.4796605, lng: 20.163631 },
    { lat: 48.4800889, lng: 20.1635029 },
    { lat: 48.4809523, lng: 20.1635234 },
    { lat: 48.4814193, lng: 20.1636187 },
    { lat: 48.4819165, lng: 20.1639081 },
    { lat: 48.4824401, lng: 20.1644643 },
    { lat: 48.483006, lng: 20.1649201 },
    { lat: 48.4838766, lng: 20.1654196 },
    { lat: 48.4843011, lng: 20.1655521 },
    { lat: 48.4849423, lng: 20.1655021 },
    { lat: 48.4854044, lng: 20.1650436 },
    { lat: 48.485775, lng: 20.1647653 },
    { lat: 48.486578, lng: 20.1645695 },
    { lat: 48.4870504, lng: 20.1641057 },
    { lat: 48.4874403, lng: 20.1636112 },
    { lat: 48.4878128, lng: 20.1627472 },
    { lat: 48.4882105, lng: 20.1624434 },
    { lat: 48.4891953, lng: 20.1620334 },
    { lat: 48.4895709, lng: 20.161718 },
    { lat: 48.4899153, lng: 20.1609911 },
    { lat: 48.4902665, lng: 20.1606773 },
    { lat: 48.4905544, lng: 20.1597227 },
    { lat: 48.4911169, lng: 20.1597353 },
    { lat: 48.4916643, lng: 20.1595692 },
    { lat: 48.4920877, lng: 20.1589291 },
    { lat: 48.4925895, lng: 20.1585595 },
    { lat: 48.4933133, lng: 20.1576557 },
    { lat: 48.4940264, lng: 20.1570529 },
    { lat: 48.4945544, lng: 20.1562994 },
    { lat: 48.4947514, lng: 20.1552124 },
    { lat: 48.4943148, lng: 20.154422 },
    { lat: 48.4942285, lng: 20.1542918 },
    { lat: 48.4932304, lng: 20.1536472 },
    { lat: 48.4929755, lng: 20.1528397 },
    { lat: 48.4929133, lng: 20.151973 },
    { lat: 48.4926801, lng: 20.1516429 },
    { lat: 48.4924993, lng: 20.1512136 },
    { lat: 48.4923622, lng: 20.1503608 },
    { lat: 48.4923408, lng: 20.1496959 },
    { lat: 48.4924247, lng: 20.1493502 },
    { lat: 48.4923585, lng: 20.1484724 },
    { lat: 48.4925013, lng: 20.1475499 },
    { lat: 48.4925892, lng: 20.1464162 },
    { lat: 48.4924514, lng: 20.145914 },
    { lat: 48.4923197, lng: 20.1456626 },
    { lat: 48.4918961, lng: 20.1451889 },
    { lat: 48.4912618, lng: 20.1448284 },
    { lat: 48.49098, lng: 20.1445448 },
    { lat: 48.4908092, lng: 20.1445285 },
    { lat: 48.4899732, lng: 20.1449919 },
    { lat: 48.4896037, lng: 20.1451272 },
    { lat: 48.4888834, lng: 20.1451709 },
    { lat: 48.4884414, lng: 20.1449929 },
    { lat: 48.4881262, lng: 20.144745 },
    { lat: 48.4878048, lng: 20.1442147 },
    { lat: 48.4873236, lng: 20.1421316 },
    { lat: 48.4872472, lng: 20.1412247 },
    { lat: 48.4871616, lng: 20.1408147 },
    { lat: 48.487236, lng: 20.1406473 },
    { lat: 48.4870811, lng: 20.1404369 },
    { lat: 48.4869935, lng: 20.1405287 },
    { lat: 48.4868996, lng: 20.140438 },
    { lat: 48.486867, lng: 20.1402976 },
    { lat: 48.4866811, lng: 20.1402707 },
    { lat: 48.4866009, lng: 20.1401283 },
    { lat: 48.4865091, lng: 20.1400867 },
    { lat: 48.4864716, lng: 20.1399389 },
    { lat: 48.4863843, lng: 20.1398404 },
    { lat: 48.4863988, lng: 20.1397567 },
    { lat: 48.486203, lng: 20.139735 },
    { lat: 48.486173, lng: 20.1393575 },
    { lat: 48.4862414, lng: 20.1387963 },
    { lat: 48.4863106, lng: 20.1386983 },
    { lat: 48.4864637, lng: 20.1381984 },
    { lat: 48.4863486, lng: 20.1374773 },
    { lat: 48.4862556, lng: 20.1373513 },
    { lat: 48.4860476, lng: 20.1372739 },
    { lat: 48.486028, lng: 20.1369314 },
    { lat: 48.4859496, lng: 20.1368335 },
    { lat: 48.4858806, lng: 20.1366048 },
    { lat: 48.485919, lng: 20.1363616 },
    { lat: 48.4858685, lng: 20.1360325 },
    { lat: 48.4858934, lng: 20.1358158 },
    { lat: 48.4861179, lng: 20.1354681 },
    { lat: 48.4861273, lng: 20.1352257 },
    { lat: 48.4863392, lng: 20.1349587 },
    { lat: 48.4864064, lng: 20.1347838 },
    { lat: 48.4864092, lng: 20.1344349 },
    { lat: 48.4863316, lng: 20.1342454 },
    { lat: 48.4862162, lng: 20.1341779 },
    { lat: 48.4862015, lng: 20.1338747 },
    { lat: 48.4860866, lng: 20.1333492 },
    { lat: 48.4861453, lng: 20.1330476 },
    { lat: 48.4863321, lng: 20.1328241 },
    { lat: 48.4862683, lng: 20.1327767 },
    { lat: 48.4860837, lng: 20.1328616 },
    { lat: 48.4848547, lng: 20.132613 },
    { lat: 48.4845713, lng: 20.1327308 },
    { lat: 48.4842646, lng: 20.1326942 },
    { lat: 48.4840134, lng: 20.1325601 },
    { lat: 48.4839909, lng: 20.131899 },
    { lat: 48.4834321, lng: 20.1319485 },
    { lat: 48.4833592, lng: 20.1316826 },
    { lat: 48.483259, lng: 20.1316787 },
    { lat: 48.4829925, lng: 20.1314992 },
    { lat: 48.4828302, lng: 20.1312763 },
    { lat: 48.4821099, lng: 20.1311501 },
    { lat: 48.4815557, lng: 20.1312365 },
    { lat: 48.481448, lng: 20.1298147 },
    { lat: 48.4816258, lng: 20.1298341 },
    { lat: 48.4817494, lng: 20.1296064 },
    { lat: 48.4820137, lng: 20.128775 },
    { lat: 48.4820399, lng: 20.12856 },
    { lat: 48.481977, lng: 20.1282345 },
    { lat: 48.4818015, lng: 20.1280168 },
    { lat: 48.481672, lng: 20.1279336 },
    { lat: 48.4811561, lng: 20.1279622 },
    { lat: 48.4812257, lng: 20.1276781 },
    { lat: 48.4811544, lng: 20.1277834 },
    { lat: 48.4803812, lng: 20.128129 },
    { lat: 48.4794211, lng: 20.127981 },
    { lat: 48.4787689, lng: 20.1277321 },
    { lat: 48.4784611, lng: 20.1273669 },
    { lat: 48.4779586, lng: 20.1269217 },
    { lat: 48.47755, lng: 20.1267309 },
    { lat: 48.4762029, lng: 20.1271218 },
    { lat: 48.4766839, lng: 20.1239527 },
    { lat: 48.4780654, lng: 20.1232795 },
    { lat: 48.4780832, lng: 20.1226421 },
    { lat: 48.4749547, lng: 20.1216925 },
    { lat: 48.4736245, lng: 20.1207607 },
    { lat: 48.4735231, lng: 20.1204556 },
    { lat: 48.4722651, lng: 20.1191385 },
    { lat: 48.4700604, lng: 20.1179191 },
    { lat: 48.4690577, lng: 20.1190974 },
    { lat: 48.4685631, lng: 20.1195315 },
    { lat: 48.4661804, lng: 20.1183684 },
    { lat: 48.4659235, lng: 20.1179007 },
    { lat: 48.4661911, lng: 20.1172022 },
    { lat: 48.4656853, lng: 20.1164314 },
    { lat: 48.4655845, lng: 20.1161128 },
    { lat: 48.4647451, lng: 20.115945 },
    { lat: 48.4643979, lng: 20.1157955 },
    { lat: 48.4642931, lng: 20.1155234 },
    { lat: 48.4635344, lng: 20.1145159 },
    { lat: 48.4633558, lng: 20.1137627 },
    { lat: 48.4632631, lng: 20.1135864 },
    { lat: 48.463214, lng: 20.1133262 },
    { lat: 48.4630799, lng: 20.1131019 },
    { lat: 48.4631052, lng: 20.1128682 },
    { lat: 48.4627689, lng: 20.1118915 },
    { lat: 48.4626309, lng: 20.1108925 },
    { lat: 48.4626326, lng: 20.1091739 },
    { lat: 48.4626845, lng: 20.1084398 },
    { lat: 48.4625444, lng: 20.106097 },
    { lat: 48.4624783, lng: 20.1056947 },
    { lat: 48.4622228, lng: 20.1047975 },
    { lat: 48.4607765, lng: 20.1010686 },
    { lat: 48.4602389, lng: 20.1012257 },
    { lat: 48.460138, lng: 20.1010456 },
    { lat: 48.4603227, lng: 20.1006647 },
    { lat: 48.460352, lng: 20.1002514 },
    { lat: 48.4603342, lng: 20.0995489 },
    { lat: 48.4570649, lng: 20.0913545 },
    { lat: 48.4569114, lng: 20.0911016 },
    { lat: 48.45641, lng: 20.0907171 },
    { lat: 48.4561552, lng: 20.0895919 },
    { lat: 48.4559381, lng: 20.0893236 },
    { lat: 48.4556135, lng: 20.089186 },
    { lat: 48.4552922, lng: 20.0893969 },
    { lat: 48.4545771, lng: 20.088689 },
    { lat: 48.4531684, lng: 20.0865994 },
    { lat: 48.4527905, lng: 20.0862045 },
    { lat: 48.4523125, lng: 20.0860845 },
    { lat: 48.4512998, lng: 20.0861906 },
    { lat: 48.4504387, lng: 20.086618 },
    { lat: 48.4497949, lng: 20.0863565 },
    { lat: 48.4490353, lng: 20.084681 },
    { lat: 48.4485187, lng: 20.0831628 },
    { lat: 48.4482305, lng: 20.0827291 },
    { lat: 48.4478099, lng: 20.0825814 },
    { lat: 48.4468789, lng: 20.0825448 },
    { lat: 48.4463828, lng: 20.0822821 },
    { lat: 48.4457557, lng: 20.0817471 },
    { lat: 48.4450292, lng: 20.0814448 },
    { lat: 48.4425239, lng: 20.0819082 },
    { lat: 48.4413355, lng: 20.0825023 },
    { lat: 48.4409212, lng: 20.0829468 },
    { lat: 48.4401783, lng: 20.0830931 },
    { lat: 48.4397631, lng: 20.0830244 },
    { lat: 48.438949, lng: 20.0834118 },
    { lat: 48.4384931, lng: 20.083963 },
    { lat: 48.4383153, lng: 20.0842421 },
    { lat: 48.4382106, lng: 20.084569 },
    { lat: 48.4379099, lng: 20.0849007 },
    { lat: 48.4372035, lng: 20.0853021 },
    { lat: 48.4371633, lng: 20.0855868 },
    { lat: 48.436983, lng: 20.0857469 },
    { lat: 48.4369742, lng: 20.0862724 },
    { lat: 48.4367605, lng: 20.0864129 },
    { lat: 48.4353243, lng: 20.0866039 },
    { lat: 48.435233, lng: 20.0904543 },
    { lat: 48.4351892, lng: 20.0907028 },
    { lat: 48.4349764, lng: 20.0912479 },
    { lat: 48.4330873, lng: 20.0950967 },
    { lat: 48.4327792, lng: 20.0963377 },
    { lat: 48.4326504, lng: 20.096603 },
    { lat: 48.4313421, lng: 20.0984786 },
    { lat: 48.431075, lng: 20.0986633 },
    { lat: 48.4305786, lng: 20.0985371 },
    { lat: 48.4303357, lng: 20.0986792 },
    { lat: 48.4299965, lng: 20.099912 },
    { lat: 48.4298246, lng: 20.1001671 },
    { lat: 48.4295409, lng: 20.1000317 },
    { lat: 48.4282119, lng: 20.0989168 },
    { lat: 48.4280121, lng: 20.098929 },
    { lat: 48.4276998, lng: 20.0992513 },
    { lat: 48.4275011, lng: 20.0996819 },
    { lat: 48.4272939, lng: 20.100365 },
    { lat: 48.4271379, lng: 20.1005129 },
    { lat: 48.4264052, lng: 20.10042 },
    { lat: 48.4261824, lng: 20.100479 },
    { lat: 48.4257569, lng: 20.1007902 },
    { lat: 48.4257477, lng: 20.1005361 },
    { lat: 48.4243583, lng: 20.0964504 },
    { lat: 48.4242586, lng: 20.0959944 },
    { lat: 48.4239609, lng: 20.0953348 },
    { lat: 48.4217428, lng: 20.0924024 },
    { lat: 48.4210591, lng: 20.091015 },
    { lat: 48.4206471, lng: 20.0894087 },
    { lat: 48.4206435, lng: 20.0878466 },
    { lat: 48.4205068, lng: 20.0838009 },
    { lat: 48.4208051, lng: 20.081128 },
    { lat: 48.4206229, lng: 20.0797993 },
    { lat: 48.4199168, lng: 20.0784429 },
    { lat: 48.4193772, lng: 20.0765379 },
    { lat: 48.4191188, lng: 20.0754614 },
    { lat: 48.4185042, lng: 20.0748181 },
    { lat: 48.4181477, lng: 20.0725196 },
    { lat: 48.417704, lng: 20.0701864 },
    { lat: 48.4171835, lng: 20.068716 },
    { lat: 48.416784, lng: 20.0654965 },
    { lat: 48.4165285, lng: 20.0654434 },
    { lat: 48.4159047, lng: 20.0659915 },
    { lat: 48.4154433, lng: 20.0667875 },
    { lat: 48.4152944, lng: 20.0669006 },
    { lat: 48.4148284, lng: 20.0672546 },
    { lat: 48.4133094, lng: 20.0674066 },
    { lat: 48.4131715, lng: 20.0670699 },
    { lat: 48.4121646, lng: 20.0675042 },
    { lat: 48.4112703, lng: 20.0678059 },
    { lat: 48.4100005, lng: 20.0696183 },
    { lat: 48.40952, lng: 20.0704691 },
    { lat: 48.4083345, lng: 20.0711397 },
    { lat: 48.4082241, lng: 20.0711394 },
    { lat: 48.407651, lng: 20.0715273 },
    { lat: 48.4064465, lng: 20.0723606 },
    { lat: 48.4057681, lng: 20.0730303 },
    { lat: 48.4058914, lng: 20.0733708 },
    { lat: 48.4060827, lng: 20.0741817 },
    { lat: 48.4060477, lng: 20.0751032 },
    { lat: 48.4059091, lng: 20.0762984 },
    { lat: 48.4055775, lng: 20.0777871 },
    { lat: 48.4052311, lng: 20.0796491 },
    { lat: 48.4045813, lng: 20.0837949 },
    { lat: 48.4045841, lng: 20.0842154 },
    { lat: 48.4046857, lng: 20.0850405 },
    { lat: 48.4049764, lng: 20.0859418 },
    { lat: 48.4052342, lng: 20.0879533 },
    { lat: 48.4052131, lng: 20.0910433 },
    { lat: 48.4055767, lng: 20.0926487 },
    { lat: 48.4058254, lng: 20.0958909 },
    { lat: 48.4059978, lng: 20.0972319 },
    { lat: 48.4060731, lng: 20.0977489 },
    { lat: 48.406072, lng: 20.0984612 },
    { lat: 48.4061797, lng: 20.0995664 },
    { lat: 48.4063281, lng: 20.1002468 },
    { lat: 48.4066769, lng: 20.1005958 },
    { lat: 48.4073308, lng: 20.1008548 },
    { lat: 48.4083386, lng: 20.1001089 },
    { lat: 48.408978, lng: 20.1002636 },
    { lat: 48.4092098, lng: 20.1005558 },
    { lat: 48.4092685, lng: 20.1009446 },
    { lat: 48.4097226, lng: 20.1023127 },
    { lat: 48.4100476, lng: 20.1027941 },
    { lat: 48.4105455, lng: 20.1030429 },
    { lat: 48.4112098, lng: 20.1031439 },
    { lat: 48.4116819, lng: 20.1029023 },
    { lat: 48.4118718, lng: 20.1026992 },
    { lat: 48.4127197, lng: 20.1027481 },
    { lat: 48.4132663, lng: 20.1025612 },
    { lat: 48.4135565, lng: 20.1027438 },
    { lat: 48.4137105, lng: 20.1030096 },
    { lat: 48.414313, lng: 20.1027306 },
    { lat: 48.414627, lng: 20.1040399 },
    { lat: 48.4154562, lng: 20.1066466 },
    { lat: 48.4160301, lng: 20.1072908 },
    { lat: 48.4160585, lng: 20.1072697 },
    { lat: 48.4164431, lng: 20.1085988 },
    { lat: 48.4164002, lng: 20.1089783 },
    { lat: 48.4164699, lng: 20.1095207 },
    { lat: 48.4163285, lng: 20.1103011 },
    { lat: 48.4163161, lng: 20.1107405 },
    { lat: 48.416762, lng: 20.1110392 },
    { lat: 48.4166174, lng: 20.1117419 },
    { lat: 48.4166963, lng: 20.1117945 },
    { lat: 48.4165499, lng: 20.1123977 },
    { lat: 48.416396, lng: 20.1135339 },
    { lat: 48.4163816, lng: 20.1140351 },
    { lat: 48.4164739, lng: 20.1153743 },
    { lat: 48.4168616, lng: 20.1191444 },
    { lat: 48.417052, lng: 20.1240596 },
    { lat: 48.4179221, lng: 20.1251227 },
    { lat: 48.4179188, lng: 20.1252836 },
    { lat: 48.420772, lng: 20.1312853 },
    { lat: 48.4231527, lng: 20.1280023 },
    { lat: 48.4231845, lng: 20.1279554 },
  ],
  RimavskéJanovce: [
    { lat: 48.3170378, lng: 20.0768888 },
    { lat: 48.3171294, lng: 20.0771963 },
    { lat: 48.3174494, lng: 20.0773363 },
    { lat: 48.3175887, lng: 20.0773269 },
    { lat: 48.31823, lng: 20.0769281 },
    { lat: 48.3184733, lng: 20.0764362 },
    { lat: 48.3186549, lng: 20.0764153 },
    { lat: 48.318717, lng: 20.0763374 },
    { lat: 48.3186897, lng: 20.0760083 },
    { lat: 48.3187504, lng: 20.0755928 },
    { lat: 48.3184737, lng: 20.0752502 },
    { lat: 48.318421, lng: 20.0750672 },
    { lat: 48.3184249, lng: 20.074456 },
    { lat: 48.3183575, lng: 20.0744071 },
    { lat: 48.3181371, lng: 20.0744418 },
    { lat: 48.3180275, lng: 20.0739743 },
    { lat: 48.3178877, lng: 20.0738857 },
    { lat: 48.3178591, lng: 20.0737157 },
    { lat: 48.3178993, lng: 20.0730795 },
    { lat: 48.3177453, lng: 20.0728727 },
    { lat: 48.3178032, lng: 20.0727491 },
    { lat: 48.317781, lng: 20.0725381 },
    { lat: 48.317946, lng: 20.0720096 },
    { lat: 48.3179294, lng: 20.0716374 },
    { lat: 48.3181534, lng: 20.0713299 },
    { lat: 48.318314, lng: 20.0712424 },
    { lat: 48.3184958, lng: 20.0707381 },
    { lat: 48.3190494, lng: 20.0702055 },
    { lat: 48.3191386, lng: 20.0699643 },
    { lat: 48.3191048, lng: 20.0698563 },
    { lat: 48.319196, lng: 20.0697512 },
    { lat: 48.3192506, lng: 20.0698147 },
    { lat: 48.3194053, lng: 20.0697154 },
    { lat: 48.3195388, lng: 20.0693483 },
    { lat: 48.3199525, lng: 20.0700051 },
    { lat: 48.3200062, lng: 20.0706796 },
    { lat: 48.3209529, lng: 20.0699839 },
    { lat: 48.321772, lng: 20.0690641 },
    { lat: 48.3219578, lng: 20.0689848 },
    { lat: 48.3220847, lng: 20.0691082 },
    { lat: 48.3221572, lng: 20.0685744 },
    { lat: 48.3220641, lng: 20.068123 },
    { lat: 48.3228365, lng: 20.0670344 },
    { lat: 48.3235381, lng: 20.0663254 },
    { lat: 48.3242854, lng: 20.0676098 },
    { lat: 48.3246884, lng: 20.0685278 },
    { lat: 48.3243398, lng: 20.0688697 },
    { lat: 48.3247754, lng: 20.0703499 },
    { lat: 48.3251343, lng: 20.0719389 },
    { lat: 48.3266013, lng: 20.0708364 },
    { lat: 48.3276358, lng: 20.0703898 },
    { lat: 48.3281618, lng: 20.0702788 },
    { lat: 48.3290772, lng: 20.0726867 },
    { lat: 48.3289501, lng: 20.0727694 },
    { lat: 48.3293919, lng: 20.0737522 },
    { lat: 48.3295572, lng: 20.0735605 },
    { lat: 48.3297424, lng: 20.0735128 },
    { lat: 48.3298956, lng: 20.0735697 },
    { lat: 48.3300991, lng: 20.0737758 },
    { lat: 48.3303563, lng: 20.073869 },
    { lat: 48.330796, lng: 20.0748822 },
    { lat: 48.3320405, lng: 20.0751534 },
    { lat: 48.3321335, lng: 20.075846 },
    { lat: 48.3323233, lng: 20.0763493 },
    { lat: 48.3328134, lng: 20.0773019 },
    { lat: 48.3332976, lng: 20.0780335 },
    { lat: 48.3323458, lng: 20.0789307 },
    { lat: 48.3339179, lng: 20.0819348 },
    { lat: 48.3341422, lng: 20.081806 },
    { lat: 48.3346847, lng: 20.0820645 },
    { lat: 48.3348301, lng: 20.0824528 },
    { lat: 48.3356096, lng: 20.0834015 },
    { lat: 48.3350287, lng: 20.0840393 },
    { lat: 48.3366764, lng: 20.0870545 },
    { lat: 48.3361012, lng: 20.0878259 },
    { lat: 48.336652, lng: 20.0885595 },
    { lat: 48.3376985, lng: 20.0896542 },
    { lat: 48.337225, lng: 20.0909217 },
    { lat: 48.3372889, lng: 20.0910525 },
    { lat: 48.3376012, lng: 20.0915768 },
    { lat: 48.3382315, lng: 20.0922478 },
    { lat: 48.3380888, lng: 20.0926091 },
    { lat: 48.3389366, lng: 20.0933641 },
    { lat: 48.3395453, lng: 20.0941388 },
    { lat: 48.3401409, lng: 20.0942956 },
    { lat: 48.3410047, lng: 20.0950257 },
    { lat: 48.3414551, lng: 20.0951868 },
    { lat: 48.3436514, lng: 20.0957281 },
    { lat: 48.3435723, lng: 20.0962934 },
    { lat: 48.3439652, lng: 20.09648 },
    { lat: 48.3442453, lng: 20.0986808 },
    { lat: 48.3450968, lng: 20.0984604 },
    { lat: 48.3456289, lng: 20.0988289 },
    { lat: 48.3457027, lng: 20.0993068 },
    { lat: 48.3460301, lng: 20.0992438 },
    { lat: 48.346036, lng: 20.1008076 },
    { lat: 48.3468326, lng: 20.1009483 },
    { lat: 48.3472168, lng: 20.1011428 },
    { lat: 48.3493686, lng: 20.1010412 },
    { lat: 48.3502734, lng: 20.1013241 },
    { lat: 48.3516627, lng: 20.1005168 },
    { lat: 48.3527998, lng: 20.1003362 },
    { lat: 48.3534457, lng: 20.1003223 },
    { lat: 48.3548557, lng: 20.1007775 },
    { lat: 48.3556043, lng: 20.1011235 },
    { lat: 48.3557189, lng: 20.1013045 },
    { lat: 48.3560022, lng: 20.1016996 },
    { lat: 48.3566451, lng: 20.1013666 },
    { lat: 48.3573073, lng: 20.101365 },
    { lat: 48.3581767, lng: 20.101223 },
    { lat: 48.3587673, lng: 20.100881 },
    { lat: 48.3589973, lng: 20.1006372 },
    { lat: 48.3591528, lng: 20.1003947 },
    { lat: 48.3591897, lng: 20.1002241 },
    { lat: 48.3598757, lng: 20.099049 },
    { lat: 48.3602146, lng: 20.0986344 },
    { lat: 48.3613347, lng: 20.0980487 },
    { lat: 48.361777, lng: 20.0972461 },
    { lat: 48.3621328, lng: 20.096149 },
    { lat: 48.3629383, lng: 20.0939699 },
    { lat: 48.3634271, lng: 20.092955 },
    { lat: 48.3637023, lng: 20.0925327 },
    { lat: 48.3654844, lng: 20.0916065 },
    { lat: 48.3659029, lng: 20.0915106 },
    { lat: 48.3672125, lng: 20.0908989 },
    { lat: 48.3681688, lng: 20.0902743 },
    { lat: 48.3693475, lng: 20.0890842 },
    { lat: 48.3703135, lng: 20.0884757 },
    { lat: 48.3709781, lng: 20.0881194 },
    { lat: 48.3713457, lng: 20.0880193 },
    { lat: 48.3728536, lng: 20.0873883 },
    { lat: 48.3733814, lng: 20.0873753 },
    { lat: 48.3744879, lng: 20.0867805 },
    { lat: 48.3751751, lng: 20.086487 },
    { lat: 48.3756028, lng: 20.0861495 },
    { lat: 48.3761559, lng: 20.085551 },
    { lat: 48.3768115, lng: 20.0850917 },
    { lat: 48.3775269, lng: 20.0847955 },
    { lat: 48.3779865, lng: 20.0844699 },
    { lat: 48.3782379, lng: 20.0843909 },
    { lat: 48.3787429, lng: 20.0846163 },
    { lat: 48.3791146, lng: 20.0845923 },
    { lat: 48.3800518, lng: 20.0851378 },
    { lat: 48.3803978, lng: 20.085085 },
    { lat: 48.3811601, lng: 20.0851614 },
    { lat: 48.3819382, lng: 20.0858107 },
    { lat: 48.3822436, lng: 20.0862195 },
    { lat: 48.3826729, lng: 20.0865509 },
    { lat: 48.3827762, lng: 20.0856983 },
    { lat: 48.3831367, lng: 20.0848029 },
    { lat: 48.3835868, lng: 20.0839747 },
    { lat: 48.3836613, lng: 20.0837037 },
    { lat: 48.3837098, lng: 20.0822056 },
    { lat: 48.3836729, lng: 20.0818838 },
    { lat: 48.3834286, lng: 20.081127 },
    { lat: 48.383324, lng: 20.080236 },
    { lat: 48.3836169, lng: 20.0801268 },
    { lat: 48.3836645, lng: 20.075997 },
    { lat: 48.3836043, lng: 20.0751139 },
    { lat: 48.3815182, lng: 20.0747899 },
    { lat: 48.3804731, lng: 20.0747688 },
    { lat: 48.3785755, lng: 20.0739861 },
    { lat: 48.3774624, lng: 20.0730303 },
    { lat: 48.3772179, lng: 20.072731 },
    { lat: 48.3769892, lng: 20.0722975 },
    { lat: 48.3767806, lng: 20.0717199 },
    { lat: 48.3762434, lng: 20.0707451 },
    { lat: 48.3757822, lng: 20.0697002 },
    { lat: 48.3756411, lng: 20.0683638 },
    { lat: 48.3753298, lng: 20.0671582 },
    { lat: 48.3748001, lng: 20.0656623 },
    { lat: 48.3737403, lng: 20.0647418 },
    { lat: 48.3724999, lng: 20.0626741 },
    { lat: 48.3713634, lng: 20.0615397 },
    { lat: 48.370797, lng: 20.0607 },
    { lat: 48.3703126, lng: 20.059809 },
    { lat: 48.370001, lng: 20.0590865 },
    { lat: 48.3678532, lng: 20.054732 },
    { lat: 48.3677402, lng: 20.0544294 },
    { lat: 48.3675429, lng: 20.0544522 },
    { lat: 48.3667984, lng: 20.0526133 },
    { lat: 48.3663685, lng: 20.051678 },
    { lat: 48.3654891, lng: 20.050086 },
    { lat: 48.3647664, lng: 20.0491825 },
    { lat: 48.362156, lng: 20.0463062 },
    { lat: 48.3619188, lng: 20.0459884 },
    { lat: 48.3613003, lng: 20.0448276 },
    { lat: 48.3607094, lng: 20.0440263 },
    { lat: 48.3600805, lng: 20.0432392 },
    { lat: 48.359331, lng: 20.0425028 },
    { lat: 48.3586011, lng: 20.0415846 },
    { lat: 48.3572211, lng: 20.0395027 },
    { lat: 48.3562496, lng: 20.0378182 },
    { lat: 48.3555598, lng: 20.0372977 },
    { lat: 48.3554768, lng: 20.0374411 },
    { lat: 48.3552831, lng: 20.0375518 },
    { lat: 48.354952, lng: 20.0379024 },
    { lat: 48.3548302, lng: 20.0378409 },
    { lat: 48.3544896, lng: 20.0379416 },
    { lat: 48.3541064, lng: 20.0378547 },
    { lat: 48.3531846, lng: 20.0356065 },
    { lat: 48.3514171, lng: 20.033507 },
    { lat: 48.3512148, lng: 20.0338908 },
    { lat: 48.3491162, lng: 20.0368016 },
    { lat: 48.3482021, lng: 20.0372218 },
    { lat: 48.3478484, lng: 20.0385986 },
    { lat: 48.3474161, lng: 20.0391948 },
    { lat: 48.3469174, lng: 20.0390427 },
    { lat: 48.3462855, lng: 20.0390143 },
    { lat: 48.3463841, lng: 20.0387299 },
    { lat: 48.3462421, lng: 20.0386759 },
    { lat: 48.3460546, lng: 20.0387474 },
    { lat: 48.3459571, lng: 20.0385154 },
    { lat: 48.3458635, lng: 20.0384566 },
    { lat: 48.3458847, lng: 20.03832 },
    { lat: 48.3460028, lng: 20.0381004 },
    { lat: 48.3461406, lng: 20.0379755 },
    { lat: 48.346151, lng: 20.03779 },
    { lat: 48.346356, lng: 20.0378706 },
    { lat: 48.3465019, lng: 20.0375602 },
    { lat: 48.3462559, lng: 20.0370688 },
    { lat: 48.3461914, lng: 20.0368192 },
    { lat: 48.346076, lng: 20.0362373 },
    { lat: 48.346164, lng: 20.03565 },
    { lat: 48.3461047, lng: 20.0355805 },
    { lat: 48.346053, lng: 20.0356884 },
    { lat: 48.3460184, lng: 20.0356453 },
    { lat: 48.346028, lng: 20.0353887 },
    { lat: 48.3459472, lng: 20.0352862 },
    { lat: 48.3459811, lng: 20.0351024 },
    { lat: 48.3459078, lng: 20.0350208 },
    { lat: 48.3459395, lng: 20.0346435 },
    { lat: 48.3458876, lng: 20.034402 },
    { lat: 48.3460631, lng: 20.034236 },
    { lat: 48.346103, lng: 20.0340262 },
    { lat: 48.3459257, lng: 20.0338559 },
    { lat: 48.3458778, lng: 20.0333533 },
    { lat: 48.3459588, lng: 20.0331906 },
    { lat: 48.3459717, lng: 20.0329068 },
    { lat: 48.3456839, lng: 20.0327076 },
    { lat: 48.3456986, lng: 20.0324393 },
    { lat: 48.3455409, lng: 20.0323302 },
    { lat: 48.345496, lng: 20.032499 },
    { lat: 48.3453016, lng: 20.0325644 },
    { lat: 48.3452051, lng: 20.032462 },
    { lat: 48.3452659, lng: 20.03214 },
    { lat: 48.3450973, lng: 20.0318489 },
    { lat: 48.3448636, lng: 20.0319446 },
    { lat: 48.3446732, lng: 20.031704 },
    { lat: 48.3446096, lng: 20.0316909 },
    { lat: 48.3445324, lng: 20.0318366 },
    { lat: 48.3443758, lng: 20.0315989 },
    { lat: 48.3444868, lng: 20.0313954 },
    { lat: 48.344502, lng: 20.03119 },
    { lat: 48.3443549, lng: 20.0307485 },
    { lat: 48.3442533, lng: 20.0307404 },
    { lat: 48.3442063, lng: 20.0306382 },
    { lat: 48.3444532, lng: 20.0304004 },
    { lat: 48.3444493, lng: 20.030286 },
    { lat: 48.344325, lng: 20.0301409 },
    { lat: 48.3438375, lng: 20.0300112 },
    { lat: 48.3434194, lng: 20.0294387 },
    { lat: 48.3432215, lng: 20.0287764 },
    { lat: 48.3429891, lng: 20.0276911 },
    { lat: 48.3427409, lng: 20.0271479 },
    { lat: 48.3424092, lng: 20.0257083 },
    { lat: 48.3423425, lng: 20.0247076 },
    { lat: 48.3407847, lng: 20.0231723 },
    { lat: 48.3397948, lng: 20.0226751 },
    { lat: 48.3385407, lng: 20.0226074 },
    { lat: 48.3366153, lng: 20.0233576 },
    { lat: 48.3347927, lng: 20.0245947 },
    { lat: 48.3346525, lng: 20.0247405 },
    { lat: 48.3340804, lng: 20.0253356 },
    { lat: 48.330769, lng: 20.0265843 },
    { lat: 48.3304299, lng: 20.0269012 },
    { lat: 48.3302601, lng: 20.0271537 },
    { lat: 48.3298134, lng: 20.027354 },
    { lat: 48.3293729, lng: 20.0274043 },
    { lat: 48.3281351, lng: 20.0280769 },
    { lat: 48.3278275, lng: 20.028244 },
    { lat: 48.3267281, lng: 20.0285394 },
    { lat: 48.3259337, lng: 20.0284539 },
    { lat: 48.325292, lng: 20.0283546 },
    { lat: 48.3238568, lng: 20.027841 },
    { lat: 48.3229929, lng: 20.0277057 },
    { lat: 48.3223525, lng: 20.0273463 },
    { lat: 48.3213656, lng: 20.0270478 },
    { lat: 48.3196714, lng: 20.0264043 },
    { lat: 48.3191091, lng: 20.0258057 },
    { lat: 48.3183579, lng: 20.0248122 },
    { lat: 48.3173083, lng: 20.0240925 },
    { lat: 48.3170641, lng: 20.0241021 },
    { lat: 48.3166483, lng: 20.0236646 },
    { lat: 48.3152406, lng: 20.0213663 },
    { lat: 48.3148187, lng: 20.0208134 },
    { lat: 48.3148449, lng: 20.0216503 },
    { lat: 48.3149179, lng: 20.0219163 },
    { lat: 48.3149691, lng: 20.0230301 },
    { lat: 48.3149029, lng: 20.0236029 },
    { lat: 48.3140764, lng: 20.0269956 },
    { lat: 48.3139532, lng: 20.0284889 },
    { lat: 48.314054, lng: 20.0292279 },
    { lat: 48.3140804, lng: 20.0308292 },
    { lat: 48.3139876, lng: 20.0317085 },
    { lat: 48.3139149, lng: 20.0319036 },
    { lat: 48.3138493, lng: 20.0324409 },
    { lat: 48.313862, lng: 20.0326595 },
    { lat: 48.3137688, lng: 20.0330437 },
    { lat: 48.3136187, lng: 20.0341679 },
    { lat: 48.3136134, lng: 20.0345825 },
    { lat: 48.3135562, lng: 20.0347496 },
    { lat: 48.3134259, lng: 20.035218 },
    { lat: 48.3132756, lng: 20.0360952 },
    { lat: 48.3132152, lng: 20.0374513 },
    { lat: 48.3129751, lng: 20.0391437 },
    { lat: 48.3128888, lng: 20.0394512 },
    { lat: 48.312628, lng: 20.0398703 },
    { lat: 48.3124377, lng: 20.0404625 },
    { lat: 48.3123402, lng: 20.0416589 },
    { lat: 48.3123383, lng: 20.0426435 },
    { lat: 48.3121971, lng: 20.0433864 },
    { lat: 48.3119587, lng: 20.0440999 },
    { lat: 48.311646, lng: 20.0463231 },
    { lat: 48.3130998, lng: 20.0458255 },
    { lat: 48.3138245, lng: 20.0480782 },
    { lat: 48.3144011, lng: 20.0495048 },
    { lat: 48.3176493, lng: 20.0555759 },
    { lat: 48.3243308, lng: 20.0645847 },
    { lat: 48.3224011, lng: 20.0655245 },
    { lat: 48.3150712, lng: 20.0705632 },
    { lat: 48.3163136, lng: 20.0745828 },
    { lat: 48.3170378, lng: 20.0768888 },
  ],
  RatkovskáLehota: [
    { lat: 48.5602538, lng: 20.0758233 },
    { lat: 48.560251, lng: 20.0758356 },
    { lat: 48.5599853, lng: 20.0765528 },
    { lat: 48.5597975, lng: 20.0768592 },
    { lat: 48.5591282, lng: 20.0772895 },
    { lat: 48.5587606, lng: 20.0780696 },
    { lat: 48.5585562, lng: 20.0783842 },
    { lat: 48.5581709, lng: 20.0787147 },
    { lat: 48.55777, lng: 20.0788478 },
    { lat: 48.5575683, lng: 20.0790406 },
    { lat: 48.5575318, lng: 20.0791404 },
    { lat: 48.5577285, lng: 20.0795355 },
    { lat: 48.5577207, lng: 20.0796503 },
    { lat: 48.557646, lng: 20.0797786 },
    { lat: 48.5564514, lng: 20.0807177 },
    { lat: 48.5562167, lng: 20.0811141 },
    { lat: 48.5554425, lng: 20.0817341 },
    { lat: 48.5552462, lng: 20.0820842 },
    { lat: 48.5547433, lng: 20.0823954 },
    { lat: 48.554101, lng: 20.082528 },
    { lat: 48.554107, lng: 20.082581 },
    { lat: 48.554179, lng: 20.083281 },
    { lat: 48.554284, lng: 20.083606 },
    { lat: 48.554365, lng: 20.084082 },
    { lat: 48.554374, lng: 20.084231 },
    { lat: 48.554384, lng: 20.084572 },
    { lat: 48.554436, lng: 20.08508 },
    { lat: 48.554547, lng: 20.085681 },
    { lat: 48.554588, lng: 20.085892 },
    { lat: 48.554665, lng: 20.086257 },
    { lat: 48.554709, lng: 20.086456 },
    { lat: 48.554713, lng: 20.086478 },
    { lat: 48.554491, lng: 20.086471 },
    { lat: 48.553913, lng: 20.086283 },
    { lat: 48.553721, lng: 20.086244 },
    { lat: 48.553565, lng: 20.086244 },
    { lat: 48.553437, lng: 20.086297 },
    { lat: 48.553371, lng: 20.086395 },
    { lat: 48.553168, lng: 20.086833 },
    { lat: 48.553058, lng: 20.087351 },
    { lat: 48.552976, lng: 20.087597 },
    { lat: 48.552741, lng: 20.088126 },
    { lat: 48.552711, lng: 20.088206 },
    { lat: 48.552669, lng: 20.08847 },
    { lat: 48.552669, lng: 20.088684 },
    { lat: 48.552706, lng: 20.089352 },
    { lat: 48.552673, lng: 20.089556 },
    { lat: 48.55254, lng: 20.089882 },
    { lat: 48.55246, lng: 20.09027 },
    { lat: 48.55239, lng: 20.090483 },
    { lat: 48.55229, lng: 20.090643 },
    { lat: 48.552155, lng: 20.090851 },
    { lat: 48.551899, lng: 20.091289 },
    { lat: 48.551575, lng: 20.091643 },
    { lat: 48.551503, lng: 20.091788 },
    { lat: 48.551345, lng: 20.092022 },
    { lat: 48.551035, lng: 20.092259 },
    { lat: 48.550931, lng: 20.092368 },
    { lat: 48.550747, lng: 20.092596 },
    { lat: 48.550577, lng: 20.092862 },
    { lat: 48.550495, lng: 20.093029 },
    { lat: 48.55044, lng: 20.093085 },
    { lat: 48.550259, lng: 20.093483 },
    { lat: 48.550099, lng: 20.093891 },
    { lat: 48.549943, lng: 20.094329 },
    { lat: 48.54972, lng: 20.094817 },
    { lat: 48.549673, lng: 20.094977 },
    { lat: 48.549619, lng: 20.095149 },
    { lat: 48.549431, lng: 20.095565 },
    { lat: 48.549238, lng: 20.095862 },
    { lat: 48.549246, lng: 20.095875 },
    { lat: 48.549256, lng: 20.095888 },
    { lat: 48.549233, lng: 20.095967 },
    { lat: 48.549019, lng: 20.096661 },
    { lat: 48.549185, lng: 20.0971 },
    { lat: 48.549394, lng: 20.097719 },
    { lat: 48.549362, lng: 20.097733 },
    { lat: 48.549353, lng: 20.09774 },
    { lat: 48.549475, lng: 20.097995 },
    { lat: 48.549532, lng: 20.098291 },
    { lat: 48.549623, lng: 20.099126 },
    { lat: 48.549687, lng: 20.099576 },
    { lat: 48.549704, lng: 20.099782 },
    { lat: 48.549786, lng: 20.100244 },
    { lat: 48.549788, lng: 20.100395 },
    { lat: 48.549794, lng: 20.100627 },
    { lat: 48.549783, lng: 20.100947 },
    { lat: 48.549687, lng: 20.101203 },
    { lat: 48.54952, lng: 20.101682 },
    { lat: 48.549367, lng: 20.10199 },
    { lat: 48.549376, lng: 20.102008 },
    { lat: 48.549417, lng: 20.102088 },
    { lat: 48.549577, lng: 20.102422 },
    { lat: 48.54963, lng: 20.102624 },
    { lat: 48.549862, lng: 20.10347 },
    { lat: 48.550156, lng: 20.104501 },
    { lat: 48.550245, lng: 20.104732 },
    { lat: 48.550367, lng: 20.104947 },
    { lat: 48.550496, lng: 20.10514 },
    { lat: 48.55062, lng: 20.105286 },
    { lat: 48.550666, lng: 20.105324 },
    { lat: 48.550713, lng: 20.105349 },
    { lat: 48.550842, lng: 20.105497 },
    { lat: 48.550955, lng: 20.105685 },
    { lat: 48.550987, lng: 20.105769 },
    { lat: 48.551005, lng: 20.105869 },
    { lat: 48.551062, lng: 20.105996 },
    { lat: 48.55113, lng: 20.106061 },
    { lat: 48.551211, lng: 20.10608 },
    { lat: 48.551261, lng: 20.106182 },
    { lat: 48.551273, lng: 20.106267 },
    { lat: 48.551361, lng: 20.10634 },
    { lat: 48.551423, lng: 20.106422 },
    { lat: 48.551508, lng: 20.106568 },
    { lat: 48.551685, lng: 20.106776 },
    { lat: 48.551827, lng: 20.106857 },
    { lat: 48.551894, lng: 20.107012 },
    { lat: 48.551982, lng: 20.10708 },
    { lat: 48.552097, lng: 20.107256 },
    { lat: 48.552137, lng: 20.10737 },
    { lat: 48.552222, lng: 20.107413 },
    { lat: 48.552275, lng: 20.107431 },
    { lat: 48.552333, lng: 20.107568 },
    { lat: 48.552403, lng: 20.107547 },
    { lat: 48.552468, lng: 20.10761 },
    { lat: 48.552509, lng: 20.10769 },
    { lat: 48.552579, lng: 20.107837 },
    { lat: 48.552725, lng: 20.107889 },
    { lat: 48.552778, lng: 20.107907 },
    { lat: 48.552853, lng: 20.107903 },
    { lat: 48.552883, lng: 20.107988 },
    { lat: 48.552939, lng: 20.108004 },
    { lat: 48.553001, lng: 20.108047 },
    { lat: 48.553069, lng: 20.108025 },
    { lat: 48.553143, lng: 20.108039 },
    { lat: 48.553212, lng: 20.108047 },
    { lat: 48.553406, lng: 20.10806 },
    { lat: 48.5535, lng: 20.108136 },
    { lat: 48.553569, lng: 20.108144 },
    { lat: 48.553618, lng: 20.108264 },
    { lat: 48.553695, lng: 20.108322 },
    { lat: 48.553753, lng: 20.108452 },
    { lat: 48.553783, lng: 20.108514 },
    { lat: 48.553866, lng: 20.108662 },
    { lat: 48.553923, lng: 20.108688 },
    { lat: 48.553959, lng: 20.108673 },
    { lat: 48.554016, lng: 20.108674 },
    { lat: 48.554085, lng: 20.108822 },
    { lat: 48.55419, lng: 20.108885 },
    { lat: 48.554293, lng: 20.108976 },
    { lat: 48.554426, lng: 20.109069 },
    { lat: 48.554519, lng: 20.109127 },
    { lat: 48.55456, lng: 20.109153 },
    { lat: 48.554605, lng: 20.109196 },
    { lat: 48.554655, lng: 20.109259 },
    { lat: 48.554689, lng: 20.109346 },
    { lat: 48.554706, lng: 20.109515 },
    { lat: 48.55471, lng: 20.109679 },
    { lat: 48.554721, lng: 20.109731 },
    { lat: 48.554719, lng: 20.110031 },
    { lat: 48.554682, lng: 20.110219 },
    { lat: 48.554669, lng: 20.110313 },
    { lat: 48.554568, lng: 20.111099 },
    { lat: 48.554548, lng: 20.111333 },
    { lat: 48.554487, lng: 20.11136 },
    { lat: 48.554424, lng: 20.111357 },
    { lat: 48.554357, lng: 20.111369 },
    { lat: 48.554285, lng: 20.111434 },
    { lat: 48.554266, lng: 20.111473 },
    { lat: 48.554201, lng: 20.111591 },
    { lat: 48.554172, lng: 20.111749 },
    { lat: 48.554093, lng: 20.111763 },
    { lat: 48.553946, lng: 20.111979 },
    { lat: 48.553833, lng: 20.112205 },
    { lat: 48.553677, lng: 20.112426 },
    { lat: 48.553518, lng: 20.112573 },
    { lat: 48.553348, lng: 20.112756 },
    { lat: 48.553309, lng: 20.112802 },
    { lat: 48.553241, lng: 20.112879 },
    { lat: 48.55313, lng: 20.112982 },
    { lat: 48.552976, lng: 20.113245 },
    { lat: 48.552764, lng: 20.113455 },
    { lat: 48.552665, lng: 20.113587 },
    { lat: 48.552526, lng: 20.113654 },
    { lat: 48.552253, lng: 20.113923 },
    { lat: 48.552263, lng: 20.113946 },
    { lat: 48.55203, lng: 20.114148 },
    { lat: 48.551863, lng: 20.114436 },
    { lat: 48.551738, lng: 20.114851 },
    { lat: 48.551689, lng: 20.115105 },
    { lat: 48.551656, lng: 20.115286 },
    { lat: 48.551652, lng: 20.115455 },
    { lat: 48.55154, lng: 20.115738 },
    { lat: 48.55147, lng: 20.115878 },
    { lat: 48.551183, lng: 20.116162 },
    { lat: 48.55117, lng: 20.116194 },
    { lat: 48.551032, lng: 20.116549 },
    { lat: 48.551043, lng: 20.116936 },
    { lat: 48.550993, lng: 20.117034 },
    { lat: 48.550924, lng: 20.117271 },
    { lat: 48.550904, lng: 20.117402 },
    { lat: 48.550891, lng: 20.117526 },
    { lat: 48.551173, lng: 20.117709 },
    { lat: 48.551277, lng: 20.117578 },
    { lat: 48.551405, lng: 20.117352 },
    { lat: 48.551433, lng: 20.117275 },
    { lat: 48.551458, lng: 20.117204 },
    { lat: 48.551482, lng: 20.11719 },
    { lat: 48.551493, lng: 20.117183 },
    { lat: 48.5515, lng: 20.116936 },
    { lat: 48.551532, lng: 20.116819 },
    { lat: 48.551651, lng: 20.116576 },
    { lat: 48.551757, lng: 20.116116 },
    { lat: 48.551938, lng: 20.115668 },
    { lat: 48.551991, lng: 20.115613 },
    { lat: 48.552087, lng: 20.115646 },
    { lat: 48.552136, lng: 20.115611 },
    { lat: 48.552251, lng: 20.115244 },
    { lat: 48.552639, lng: 20.114988 },
    { lat: 48.552708, lng: 20.114932 },
    { lat: 48.552816, lng: 20.114618 },
    { lat: 48.552783, lng: 20.114567 },
    { lat: 48.552943, lng: 20.113822 },
    { lat: 48.553084, lng: 20.113718 },
    { lat: 48.553119, lng: 20.113693 },
    { lat: 48.553073, lng: 20.113615 },
    { lat: 48.553029, lng: 20.113517 },
    { lat: 48.553018, lng: 20.113274 },
    { lat: 48.55306, lng: 20.113175 },
    { lat: 48.553133, lng: 20.113059 },
    { lat: 48.553331, lng: 20.112854 },
    { lat: 48.553352, lng: 20.112893 },
    { lat: 48.553561, lng: 20.113298 },
    { lat: 48.553767, lng: 20.113321 },
    { lat: 48.553966, lng: 20.113387 },
    { lat: 48.554245, lng: 20.113501 },
    { lat: 48.554547, lng: 20.113721 },
    { lat: 48.55498, lng: 20.114104 },
    { lat: 48.555168, lng: 20.113943 },
    { lat: 48.555229, lng: 20.113772 },
    { lat: 48.555245, lng: 20.11348 },
    { lat: 48.555328, lng: 20.11318 },
    { lat: 48.555401, lng: 20.112774 },
    { lat: 48.555617, lng: 20.112868 },
    { lat: 48.555791, lng: 20.112673 },
    { lat: 48.555962, lng: 20.112414 },
    { lat: 48.555989, lng: 20.112363 },
    { lat: 48.555865, lng: 20.111873 },
    { lat: 48.556142, lng: 20.111461 },
    { lat: 48.556269, lng: 20.111272 },
    { lat: 48.556237, lng: 20.11123 },
    { lat: 48.556344, lng: 20.111058 },
    { lat: 48.55667, lng: 20.110908 },
    { lat: 48.556667, lng: 20.110847 },
    { lat: 48.556666, lng: 20.110815 },
    { lat: 48.556654, lng: 20.110517 },
    { lat: 48.556668, lng: 20.110382 },
    { lat: 48.556704, lng: 20.110336 },
    { lat: 48.556742, lng: 20.110281 },
    { lat: 48.556886, lng: 20.110254 },
    { lat: 48.557106, lng: 20.110111 },
    { lat: 48.557302, lng: 20.110036 },
    { lat: 48.55745, lng: 20.109951 },
    { lat: 48.55762, lng: 20.109873 },
    { lat: 48.557995, lng: 20.109666 },
    { lat: 48.55813, lng: 20.110182 },
    { lat: 48.5581, lng: 20.110292 },
    { lat: 48.558062, lng: 20.110406 },
    { lat: 48.558125, lng: 20.110657 },
    { lat: 48.558247, lng: 20.110637 },
    { lat: 48.558416, lng: 20.110702 },
    { lat: 48.558551, lng: 20.110705 },
    { lat: 48.558677, lng: 20.110749 },
    { lat: 48.558726, lng: 20.110726 },
    { lat: 48.558779, lng: 20.110702 },
    { lat: 48.558789, lng: 20.110651 },
    { lat: 48.55882, lng: 20.110502 },
    { lat: 48.558849, lng: 20.110243 },
    { lat: 48.558726, lng: 20.110218 },
    { lat: 48.558678, lng: 20.110048 },
    { lat: 48.558666, lng: 20.109978 },
    { lat: 48.558979, lng: 20.109902 },
    { lat: 48.559224, lng: 20.109976 },
    { lat: 48.55927, lng: 20.109968 },
    { lat: 48.559367, lng: 20.109857 },
    { lat: 48.559346, lng: 20.109506 },
    { lat: 48.559388, lng: 20.109428 },
    { lat: 48.559425, lng: 20.109512 },
    { lat: 48.559548, lng: 20.109906 },
    { lat: 48.55957, lng: 20.109978 },
    { lat: 48.559759, lng: 20.110584 },
    { lat: 48.559794, lng: 20.110611 },
    { lat: 48.559865, lng: 20.110605 },
    { lat: 48.55994, lng: 20.110642 },
    { lat: 48.560107, lng: 20.110469 },
    { lat: 48.56024, lng: 20.110342 },
    { lat: 48.560408, lng: 20.110062 },
    { lat: 48.560591, lng: 20.109802 },
    { lat: 48.560702, lng: 20.109641 },
    { lat: 48.560684, lng: 20.109612 },
    { lat: 48.5606, lng: 20.109486 },
    { lat: 48.560592, lng: 20.109426 },
    { lat: 48.560784, lng: 20.109504 },
    { lat: 48.560789, lng: 20.109425 },
    { lat: 48.560901, lng: 20.109481 },
    { lat: 48.560959, lng: 20.109416 },
    { lat: 48.561091, lng: 20.109147 },
    { lat: 48.561305, lng: 20.10888 },
    { lat: 48.561349, lng: 20.108619 },
    { lat: 48.561702, lng: 20.108017 },
    { lat: 48.562073, lng: 20.107661 },
    { lat: 48.562176, lng: 20.107477 },
    { lat: 48.562465, lng: 20.107257 },
    { lat: 48.562731, lng: 20.106937 },
    { lat: 48.562837, lng: 20.106779 },
    { lat: 48.562918, lng: 20.106733 },
    { lat: 48.562992, lng: 20.106716 },
    { lat: 48.563024, lng: 20.106781 },
    { lat: 48.563097, lng: 20.106843 },
    { lat: 48.563145, lng: 20.106811 },
    { lat: 48.563266, lng: 20.10645 },
    { lat: 48.563247, lng: 20.106392 },
    { lat: 48.563324, lng: 20.106336 },
    { lat: 48.563372, lng: 20.106299 },
    { lat: 48.563399, lng: 20.106278 },
    { lat: 48.563425, lng: 20.106269 },
    { lat: 48.563483, lng: 20.106245 },
    { lat: 48.563603, lng: 20.106231 },
    { lat: 48.563672, lng: 20.106123 },
    { lat: 48.563702, lng: 20.105815 },
    { lat: 48.563817, lng: 20.10566 },
    { lat: 48.564248, lng: 20.105756 },
    { lat: 48.564418, lng: 20.105645 },
    { lat: 48.564527, lng: 20.105475 },
    { lat: 48.564627, lng: 20.105239 },
    { lat: 48.564666, lng: 20.105196 },
    { lat: 48.564717, lng: 20.105186 },
    { lat: 48.564911, lng: 20.105236 },
    { lat: 48.564949, lng: 20.105207 },
    { lat: 48.565019, lng: 20.105156 },
    { lat: 48.565098, lng: 20.105124 },
    { lat: 48.565107, lng: 20.105175 },
    { lat: 48.565169, lng: 20.105635 },
    { lat: 48.565481, lng: 20.105587 },
    { lat: 48.56543, lng: 20.105284 },
    { lat: 48.565421, lng: 20.105242 },
    { lat: 48.565493, lng: 20.105185 },
    { lat: 48.565567, lng: 20.105116 },
    { lat: 48.565668, lng: 20.105019 },
    { lat: 48.565755, lng: 20.104813 },
    { lat: 48.56585, lng: 20.104764 },
    { lat: 48.565947, lng: 20.104775 },
    { lat: 48.566037, lng: 20.104786 },
    { lat: 48.566127, lng: 20.104763 },
    { lat: 48.566194, lng: 20.104689 },
    { lat: 48.566245, lng: 20.104574 },
    { lat: 48.566272, lng: 20.104585 },
    { lat: 48.56633, lng: 20.104615 },
    { lat: 48.566645, lng: 20.104343 },
    { lat: 48.566669, lng: 20.104308 },
    { lat: 48.566735, lng: 20.104366 },
    { lat: 48.566829, lng: 20.10439 },
    { lat: 48.566918, lng: 20.104385 },
    { lat: 48.567004, lng: 20.104343 },
    { lat: 48.567134, lng: 20.10416 },
    { lat: 48.567166, lng: 20.103932 },
    { lat: 48.567322, lng: 20.103932 },
    { lat: 48.567478, lng: 20.103759 },
    { lat: 48.567632, lng: 20.103664 },
    { lat: 48.567678, lng: 20.103443 },
    { lat: 48.567751, lng: 20.103396 },
    { lat: 48.567962, lng: 20.103338 },
    { lat: 48.568064, lng: 20.103362 },
    { lat: 48.56816, lng: 20.103348 },
    { lat: 48.568275, lng: 20.103139 },
    { lat: 48.568325, lng: 20.103047 },
    { lat: 48.568384, lng: 20.102971 },
    { lat: 48.568444, lng: 20.102888 },
    { lat: 48.56852, lng: 20.102819 },
    { lat: 48.568568, lng: 20.102871 },
    { lat: 48.568642, lng: 20.102464 },
    { lat: 48.56875, lng: 20.10235 },
    { lat: 48.568825, lng: 20.102313 },
    { lat: 48.569308, lng: 20.101852 },
    { lat: 48.569346, lng: 20.10184 },
    { lat: 48.569681, lng: 20.101729 },
    { lat: 48.569769, lng: 20.101647 },
    { lat: 48.570269, lng: 20.101561 },
    { lat: 48.570519, lng: 20.101481 },
    { lat: 48.570618, lng: 20.101955 },
    { lat: 48.570937, lng: 20.10192 },
    { lat: 48.571689, lng: 20.101704 },
    { lat: 48.572068, lng: 20.101352 },
    { lat: 48.572451, lng: 20.101029 },
    { lat: 48.572557, lng: 20.101311 },
    { lat: 48.572647, lng: 20.101296 },
    { lat: 48.57282, lng: 20.101105 },
    { lat: 48.573316, lng: 20.100803 },
    { lat: 48.573442, lng: 20.100818 },
    { lat: 48.57369, lng: 20.100796 },
    { lat: 48.573837, lng: 20.100758 },
    { lat: 48.573855, lng: 20.1007 },
    { lat: 48.573897, lng: 20.100568 },
    { lat: 48.573976, lng: 20.100315 },
    { lat: 48.574047, lng: 20.099969 },
    { lat: 48.574049, lng: 20.099955 },
    { lat: 48.574121, lng: 20.099793 },
    { lat: 48.574104, lng: 20.099614 },
    { lat: 48.574353, lng: 20.099527 },
    { lat: 48.574383, lng: 20.099531 },
    { lat: 48.574413, lng: 20.099341 },
    { lat: 48.574432, lng: 20.098796 },
    { lat: 48.574451, lng: 20.098296 },
    { lat: 48.574468, lng: 20.097811 },
    { lat: 48.574494, lng: 20.097124 },
    { lat: 48.574527, lng: 20.095973 },
    { lat: 48.574515, lng: 20.095968 },
    { lat: 48.574516, lng: 20.095952 },
    { lat: 48.574528, lng: 20.095692 },
    { lat: 48.574471, lng: 20.095389 },
    { lat: 48.574359, lng: 20.094925 },
    { lat: 48.574702, lng: 20.094519 },
    { lat: 48.574913, lng: 20.094068 },
    { lat: 48.575009, lng: 20.093604 },
    { lat: 48.575057, lng: 20.093271 },
    { lat: 48.575264, lng: 20.092738 },
    { lat: 48.575263, lng: 20.092587 },
    { lat: 48.57544, lng: 20.092282 },
    { lat: 48.575472, lng: 20.092204 },
    { lat: 48.575494, lng: 20.092205 },
    { lat: 48.575549, lng: 20.092206 },
    { lat: 48.575515, lng: 20.0919 },
    { lat: 48.5755, lng: 20.091484 },
    { lat: 48.575505, lng: 20.091241 },
    { lat: 48.575515, lng: 20.091119 },
    { lat: 48.575549, lng: 20.09068 },
    { lat: 48.575565, lng: 20.090573 },
    { lat: 48.575654, lng: 20.090102 },
    { lat: 48.575694, lng: 20.089939 },
    { lat: 48.57579, lng: 20.089645 },
    { lat: 48.575825, lng: 20.089526 },
    { lat: 48.575871, lng: 20.089406 },
    { lat: 48.575905, lng: 20.089323 },
    { lat: 48.57603, lng: 20.089036 },
    { lat: 48.576068, lng: 20.088925 },
    { lat: 48.576084, lng: 20.088847 },
    { lat: 48.576099, lng: 20.088709 },
    { lat: 48.576104, lng: 20.08856 },
    { lat: 48.576104, lng: 20.088423 },
    { lat: 48.576082, lng: 20.088269 },
    { lat: 48.576033, lng: 20.088149 },
    { lat: 48.5759868, lng: 20.0881982 },
    { lat: 48.5757983, lng: 20.0878918 },
    { lat: 48.5757165, lng: 20.0868334 },
    { lat: 48.5757404, lng: 20.086319 },
    { lat: 48.5756123, lng: 20.0856996 },
    { lat: 48.5754671, lng: 20.0855624 },
    { lat: 48.5754384, lng: 20.0852766 },
    { lat: 48.5753211, lng: 20.0850265 },
    { lat: 48.5752988, lng: 20.0847586 },
    { lat: 48.5750824, lng: 20.0844563 },
    { lat: 48.575045, lng: 20.0842026 },
    { lat: 48.574958, lng: 20.0840311 },
    { lat: 48.5749005, lng: 20.0836218 },
    { lat: 48.5746132, lng: 20.0826631 },
    { lat: 48.5744953, lng: 20.0823977 },
    { lat: 48.574089, lng: 20.0819709 },
    { lat: 48.5738537, lng: 20.0815896 },
    { lat: 48.5738049, lng: 20.0814191 },
    { lat: 48.5738444, lng: 20.0811743 },
    { lat: 48.573753, lng: 20.0807023 },
    { lat: 48.5730326, lng: 20.0798664 },
    { lat: 48.572953, lng: 20.0798482 },
    { lat: 48.5728361, lng: 20.0796639 },
    { lat: 48.572844, lng: 20.0795003 },
    { lat: 48.5723874, lng: 20.0789697 },
    { lat: 48.5723573, lng: 20.0787616 },
    { lat: 48.5722858, lng: 20.0787213 },
    { lat: 48.5722259, lng: 20.078579 },
    { lat: 48.5718556, lng: 20.0785578 },
    { lat: 48.5715694, lng: 20.0784178 },
    { lat: 48.5711427, lng: 20.0784813 },
    { lat: 48.5707723, lng: 20.0784277 },
    { lat: 48.5700179, lng: 20.0777519 },
    { lat: 48.5689141, lng: 20.0762271 },
    { lat: 48.568515, lng: 20.0751812 },
    { lat: 48.5684861, lng: 20.0742074 },
    { lat: 48.5684288, lng: 20.0737742 },
    { lat: 48.5683276, lng: 20.0734213 },
    { lat: 48.5680228, lng: 20.0725889 },
    { lat: 48.5675901, lng: 20.0718453 },
    { lat: 48.5672027, lng: 20.0716042 },
    { lat: 48.5667035, lng: 20.072199 },
    { lat: 48.5660256, lng: 20.0726093 },
    { lat: 48.5654267, lng: 20.0733269 },
    { lat: 48.5650423, lng: 20.0736208 },
    { lat: 48.564747, lng: 20.0737224 },
    { lat: 48.5643291, lng: 20.0739725 },
    { lat: 48.5638315, lng: 20.0741717 },
    { lat: 48.562224, lng: 20.0742995 },
    { lat: 48.5619217, lng: 20.0744552 },
    { lat: 48.5618564, lng: 20.0743699 },
    { lat: 48.5616062, lng: 20.0748329 },
    { lat: 48.5611007, lng: 20.0750882 },
    { lat: 48.5607091, lng: 20.0757508 },
    { lat: 48.5604055, lng: 20.0758674 },
    { lat: 48.5602538, lng: 20.0758233 },
  ],
  Ožďany: [
    { lat: 48.40063, lng: 19.839679 },
    { lat: 48.399941, lng: 19.839588 },
    { lat: 48.398885, lng: 19.83944 },
    { lat: 48.397878, lng: 19.839247 },
    { lat: 48.397342, lng: 19.839138 },
    { lat: 48.396702, lng: 19.838687 },
    { lat: 48.396301, lng: 19.838403 },
    { lat: 48.395899, lng: 19.838118 },
    { lat: 48.395578, lng: 19.83767 },
    { lat: 48.395198, lng: 19.837126 },
    { lat: 48.395131, lng: 19.837041 },
    { lat: 48.394721, lng: 19.836608 },
    { lat: 48.39439, lng: 19.836246 },
    { lat: 48.393938, lng: 19.835751 },
    { lat: 48.393492, lng: 19.835264 },
    { lat: 48.393221, lng: 19.834966 },
    { lat: 48.392533, lng: 19.834295 },
    { lat: 48.3922, lng: 19.833979 },
    { lat: 48.391847, lng: 19.833638 },
    { lat: 48.391616, lng: 19.833418 },
    { lat: 48.391412, lng: 19.833379 },
    { lat: 48.39085, lng: 19.833271 },
    { lat: 48.390679, lng: 19.83324 },
    { lat: 48.390643, lng: 19.833225 },
    { lat: 48.390602, lng: 19.833222 },
    { lat: 48.390296, lng: 19.833223 },
    { lat: 48.389746, lng: 19.833374 },
    { lat: 48.389225, lng: 19.833525 },
    { lat: 48.388793, lng: 19.833642 },
    { lat: 48.388439, lng: 19.833744 },
    { lat: 48.388314, lng: 19.833866 },
    { lat: 48.388109, lng: 19.834056 },
    { lat: 48.387844, lng: 19.834311 },
    { lat: 48.3873, lng: 19.834827 },
    { lat: 48.387278, lng: 19.834847 },
    { lat: 48.386699, lng: 19.835392 },
    { lat: 48.386416, lng: 19.835403 },
    { lat: 48.386091, lng: 19.835404 },
    { lat: 48.385796, lng: 19.835417 },
    { lat: 48.385457, lng: 19.835241 },
    { lat: 48.385125, lng: 19.835065 },
    { lat: 48.384939, lng: 19.83499 },
    { lat: 48.384471, lng: 19.834804 },
    { lat: 48.383939, lng: 19.834581 },
    { lat: 48.383832, lng: 19.834528 },
    { lat: 48.383604, lng: 19.834956 },
    { lat: 48.382979, lng: 19.836105 },
    { lat: 48.382899, lng: 19.83625 },
    { lat: 48.382824, lng: 19.836389 },
    { lat: 48.382654, lng: 19.836764 },
    { lat: 48.382449, lng: 19.837204 },
    { lat: 48.382282, lng: 19.837564 },
    { lat: 48.381984, lng: 19.838006 },
    { lat: 48.38185, lng: 19.838203 },
    { lat: 48.381157, lng: 19.838818 },
    { lat: 48.380907, lng: 19.839093 },
    { lat: 48.380606, lng: 19.839413 },
    { lat: 48.380154, lng: 19.839895 },
    { lat: 48.380089, lng: 19.839963 },
    { lat: 48.379849, lng: 19.840127 },
    { lat: 48.379479, lng: 19.840366 },
    { lat: 48.379253, lng: 19.840512 },
    { lat: 48.37875, lng: 19.840878 },
    { lat: 48.37867, lng: 19.840936 },
    { lat: 48.3783, lng: 19.841226 },
    { lat: 48.378248, lng: 19.841256 },
    { lat: 48.378124, lng: 19.841332 },
    { lat: 48.377558, lng: 19.841674 },
    { lat: 48.377192, lng: 19.841899 },
    { lat: 48.377088, lng: 19.841994 },
    { lat: 48.376528, lng: 19.842512 },
    { lat: 48.376031, lng: 19.842976 },
    { lat: 48.3755, lng: 19.843468 },
    { lat: 48.375182, lng: 19.843764 },
    { lat: 48.374944, lng: 19.844076 },
    { lat: 48.374923, lng: 19.844093 },
    { lat: 48.374908, lng: 19.844115 },
    { lat: 48.374829, lng: 19.844894 },
    { lat: 48.374714, lng: 19.846078 },
    { lat: 48.374558, lng: 19.847638 },
    { lat: 48.374543, lng: 19.847703 },
    { lat: 48.374452, lng: 19.84809 },
    { lat: 48.374296, lng: 19.848757 },
    { lat: 48.373962, lng: 19.850147 },
    { lat: 48.373956, lng: 19.850156 },
    { lat: 48.373906, lng: 19.85024 },
    { lat: 48.373875, lng: 19.850291 },
    { lat: 48.373559, lng: 19.851552 },
    { lat: 48.37327, lng: 19.852693 },
    { lat: 48.373004, lng: 19.853764 },
    { lat: 48.372516, lng: 19.855687 },
    { lat: 48.372148, lng: 19.857215 },
    { lat: 48.371887, lng: 19.858325 },
    { lat: 48.371476, lng: 19.859274 },
    { lat: 48.370589, lng: 19.861302 },
    { lat: 48.370064, lng: 19.861615 },
    { lat: 48.369492, lng: 19.861428 },
    { lat: 48.369223, lng: 19.861274 },
    { lat: 48.368605, lng: 19.860913 },
    { lat: 48.368149, lng: 19.860646 },
    { lat: 48.3681, lng: 19.860637 },
    { lat: 48.368067, lng: 19.860632 },
    { lat: 48.367883, lng: 19.860601 },
    { lat: 48.367713, lng: 19.860649 },
    { lat: 48.367414, lng: 19.860841 },
    { lat: 48.366985, lng: 19.861112 },
    { lat: 48.366436, lng: 19.861464 },
    { lat: 48.366054, lng: 19.861553 },
    { lat: 48.36543, lng: 19.861701 },
    { lat: 48.365425, lng: 19.861721 },
    { lat: 48.365402, lng: 19.861763 },
    { lat: 48.364268, lng: 19.862767 },
    { lat: 48.363793, lng: 19.863193 },
    { lat: 48.363402, lng: 19.863507 },
    { lat: 48.363099, lng: 19.863697 },
    { lat: 48.362896, lng: 19.863704 },
    { lat: 48.362338, lng: 19.863685 },
    { lat: 48.361691, lng: 19.863663 },
    { lat: 48.361097, lng: 19.863635 },
    { lat: 48.361067, lng: 19.863633 },
    { lat: 48.360978, lng: 19.863421 },
    { lat: 48.360988, lng: 19.863378 },
    { lat: 48.361085, lng: 19.86296 },
    { lat: 48.361383, lng: 19.861686 },
    { lat: 48.36159, lng: 19.860815 },
    { lat: 48.361513, lng: 19.860556 },
    { lat: 48.361347, lng: 19.860411 },
    { lat: 48.361221, lng: 19.860194 },
    { lat: 48.36109, lng: 19.860066 },
    { lat: 48.360965, lng: 19.859889 },
    { lat: 48.360588, lng: 19.859545 },
    { lat: 48.360535, lng: 19.859396 },
    { lat: 48.360348, lng: 19.859288 },
    { lat: 48.360353, lng: 19.858963 },
    { lat: 48.360235, lng: 19.858862 },
    { lat: 48.359994, lng: 19.858945 },
    { lat: 48.359737, lng: 19.858711 },
    { lat: 48.359721, lng: 19.858734 },
    { lat: 48.359713, lng: 19.858728 },
    { lat: 48.359295, lng: 19.859285 },
    { lat: 48.359301, lng: 19.859303 },
    { lat: 48.35926, lng: 19.859365 },
    { lat: 48.359252, lng: 19.859345 },
    { lat: 48.358948, lng: 19.85977 },
    { lat: 48.358502, lng: 19.860415 },
    { lat: 48.357982, lng: 19.861161 },
    { lat: 48.35755, lng: 19.861772 },
    { lat: 48.357329, lng: 19.862021 },
    { lat: 48.357095, lng: 19.862259 },
    { lat: 48.356916, lng: 19.862561 },
    { lat: 48.356802, lng: 19.862839 },
    { lat: 48.356768, lng: 19.86304 },
    { lat: 48.356652, lng: 19.863271 },
    { lat: 48.356501, lng: 19.86341 },
    { lat: 48.356272, lng: 19.863866 },
    { lat: 48.356301, lng: 19.864007 },
    { lat: 48.356234, lng: 19.864152 },
    { lat: 48.356239, lng: 19.864536 },
    { lat: 48.356158, lng: 19.865007 },
    { lat: 48.356069, lng: 19.865111 },
    { lat: 48.3558809, lng: 19.8656309 },
    { lat: 48.3559237, lng: 19.8668544 },
    { lat: 48.3558967, lng: 19.8676091 },
    { lat: 48.3560458, lng: 19.8682732 },
    { lat: 48.3561336, lng: 19.8683975 },
    { lat: 48.3561577, lng: 19.8685691 },
    { lat: 48.3560672, lng: 19.8689474 },
    { lat: 48.3563944, lng: 19.8701825 },
    { lat: 48.3563296, lng: 19.8707191 },
    { lat: 48.3564606, lng: 19.8711403 },
    { lat: 48.356423, lng: 19.871444 },
    { lat: 48.35626, lng: 19.871633 },
    { lat: 48.3563186, lng: 19.8720258 },
    { lat: 48.3561449, lng: 19.8728302 },
    { lat: 48.3555101, lng: 19.873782 },
    { lat: 48.3538978, lng: 19.8782668 },
    { lat: 48.3532297, lng: 19.8825155 },
    { lat: 48.3560359, lng: 19.8832486 },
    { lat: 48.3592526, lng: 19.8852874 },
    { lat: 48.3594518, lng: 19.8855221 },
    { lat: 48.3604012, lng: 19.8877598 },
    { lat: 48.3604488, lng: 19.8890729 },
    { lat: 48.3603071, lng: 19.8897629 },
    { lat: 48.3601218, lng: 19.8903009 },
    { lat: 48.3598693, lng: 19.8919701 },
    { lat: 48.3607011, lng: 19.8949891 },
    { lat: 48.3613909, lng: 19.8953336 },
    { lat: 48.3617006, lng: 19.8964085 },
    { lat: 48.3614828, lng: 19.8973163 },
    { lat: 48.3613575, lng: 19.8986965 },
    { lat: 48.3611567, lng: 19.8994793 },
    { lat: 48.3617618, lng: 19.9029682 },
    { lat: 48.3617121, lng: 19.9049331 },
    { lat: 48.3618424, lng: 19.90629 },
    { lat: 48.3624861, lng: 19.908923 },
    { lat: 48.3627567, lng: 19.9096627 },
    { lat: 48.3607943, lng: 19.9145243 },
    { lat: 48.3602591, lng: 19.9156039 },
    { lat: 48.3591781, lng: 19.9181654 },
    { lat: 48.3588736, lng: 19.9187063 },
    { lat: 48.3582418, lng: 19.9195266 },
    { lat: 48.3581141, lng: 19.9199952 },
    { lat: 48.35792, lng: 19.922119 },
    { lat: 48.3579712, lng: 19.9273178 },
    { lat: 48.3577749, lng: 19.9285782 },
    { lat: 48.3580891, lng: 19.9302631 },
    { lat: 48.3582529, lng: 19.9333864 },
    { lat: 48.3586226, lng: 19.9358974 },
    { lat: 48.3590213, lng: 19.941128 },
    { lat: 48.3590148, lng: 19.9417117 },
    { lat: 48.3589282, lng: 19.9420349 },
    { lat: 48.3599867, lng: 19.9437137 },
    { lat: 48.360286, lng: 19.9453187 },
    { lat: 48.3603203, lng: 19.9453058 },
    { lat: 48.3603572, lng: 19.945434 },
    { lat: 48.3603113, lng: 19.945465 },
    { lat: 48.3610197, lng: 19.9482362 },
    { lat: 48.3610566, lng: 19.9482341 },
    { lat: 48.3610709, lng: 19.9483578 },
    { lat: 48.3610395, lng: 19.948387 },
    { lat: 48.361235, lng: 19.9493031 },
    { lat: 48.3617336, lng: 19.9513059 },
    { lat: 48.3627044, lng: 19.952648 },
    { lat: 48.3632053, lng: 19.9537247 },
    { lat: 48.3634797, lng: 19.9540322 },
    { lat: 48.3644747, lng: 19.9544935 },
    { lat: 48.3649133, lng: 19.9553764 },
    { lat: 48.3649671, lng: 19.9554202 },
    { lat: 48.3650627, lng: 19.9555251 },
    { lat: 48.3666425, lng: 19.9557977 },
    { lat: 48.3683093, lng: 19.9559981 },
    { lat: 48.3694457, lng: 19.9558097 },
    { lat: 48.3701882, lng: 19.9558881 },
    { lat: 48.3709766, lng: 19.9560986 },
    { lat: 48.3717412, lng: 19.9567266 },
    { lat: 48.3729478, lng: 19.9570732 },
    { lat: 48.3747391, lng: 19.9580863 },
    { lat: 48.3754523, lng: 19.9581421 },
    { lat: 48.3759081, lng: 19.9583979 },
    { lat: 48.376114, lng: 19.9583382 },
    { lat: 48.3761206, lng: 19.9584931 },
    { lat: 48.3759664, lng: 19.9593164 },
    { lat: 48.3760112, lng: 19.9600814 },
    { lat: 48.3759348, lng: 19.9604565 },
    { lat: 48.3759623, lng: 19.961638 },
    { lat: 48.37603, lng: 19.9618541 },
    { lat: 48.3760736, lng: 19.9618425 },
    { lat: 48.3761348, lng: 19.9619886 },
    { lat: 48.3765254, lng: 19.9618181 },
    { lat: 48.3765263, lng: 19.9619189 },
    { lat: 48.3767205, lng: 19.96204 },
    { lat: 48.3768296, lng: 19.9623804 },
    { lat: 48.3768001, lng: 19.9625086 },
    { lat: 48.3768324, lng: 19.9626841 },
    { lat: 48.3767674, lng: 19.9627725 },
    { lat: 48.3768067, lng: 19.9629243 },
    { lat: 48.3769012, lng: 19.9629277 },
    { lat: 48.3769073, lng: 19.9631475 },
    { lat: 48.3768059, lng: 19.9633836 },
    { lat: 48.3768059, lng: 19.9634896 },
    { lat: 48.3769196, lng: 19.9634876 },
    { lat: 48.3768897, lng: 19.9640081 },
    { lat: 48.3769245, lng: 19.9642447 },
    { lat: 48.3768816, lng: 19.9645354 },
    { lat: 48.3769699, lng: 19.9653434 },
    { lat: 48.3769467, lng: 19.9655653 },
    { lat: 48.376987, lng: 19.9656918 },
    { lat: 48.3769393, lng: 19.9663203 },
    { lat: 48.3768461, lng: 19.9666216 },
    { lat: 48.3777604, lng: 19.9667168 },
    { lat: 48.3788748, lng: 19.9669622 },
    { lat: 48.3792779, lng: 19.9674609 },
    { lat: 48.3795474, lng: 19.9688529 },
    { lat: 48.3795145, lng: 19.9698585 },
    { lat: 48.3796729, lng: 19.970702 },
    { lat: 48.3799343, lng: 19.9711271 },
    { lat: 48.3801865, lng: 19.9719716 },
    { lat: 48.3802109, lng: 19.9719405 },
    { lat: 48.3806405, lng: 19.973227 },
    { lat: 48.3806699, lng: 19.9737876 },
    { lat: 48.3805608, lng: 19.9742945 },
    { lat: 48.3803567, lng: 19.9745318 },
    { lat: 48.3805119, lng: 19.9748186 },
    { lat: 48.3808724, lng: 19.9751386 },
    { lat: 48.381475, lng: 19.9754823 },
    { lat: 48.3817268, lng: 19.975257 },
    { lat: 48.3818353, lng: 19.974949 },
    { lat: 48.3820973, lng: 19.9750945 },
    { lat: 48.3825763, lng: 19.9756077 },
    { lat: 48.382865, lng: 19.9756698 },
    { lat: 48.3828803, lng: 19.9759245 },
    { lat: 48.3830451, lng: 19.9756626 },
    { lat: 48.3830247, lng: 19.9755266 },
    { lat: 48.3829067, lng: 19.9754004 },
    { lat: 48.3828866, lng: 19.9752971 },
    { lat: 48.3829977, lng: 19.9753194 },
    { lat: 48.3830779, lng: 19.9752175 },
    { lat: 48.3831532, lng: 19.9753181 },
    { lat: 48.383266, lng: 19.9752659 },
    { lat: 48.3833077, lng: 19.9749224 },
    { lat: 48.3834359, lng: 19.9750749 },
    { lat: 48.3835209, lng: 19.9750874 },
    { lat: 48.3837294, lng: 19.9749045 },
    { lat: 48.3837649, lng: 19.9747059 },
    { lat: 48.383823, lng: 19.974667 },
    { lat: 48.3838387, lng: 19.9748526 },
    { lat: 48.3839904, lng: 19.9748686 },
    { lat: 48.3840165, lng: 19.9750044 },
    { lat: 48.3841338, lng: 19.9749538 },
    { lat: 48.3841866, lng: 19.9747404 },
    { lat: 48.3842444, lng: 19.9746952 },
    { lat: 48.3842054, lng: 19.9745703 },
    { lat: 48.3842769, lng: 19.9744246 },
    { lat: 48.3842274, lng: 19.9743499 },
    { lat: 48.3842453, lng: 19.9742376 },
    { lat: 48.384341, lng: 19.9741562 },
    { lat: 48.3844469, lng: 19.9741592 },
    { lat: 48.3844205, lng: 19.973959 },
    { lat: 48.3844741, lng: 19.9739189 },
    { lat: 48.3845094, lng: 19.9736387 },
    { lat: 48.3844315, lng: 19.973633 },
    { lat: 48.3844354, lng: 19.9735409 },
    { lat: 48.3843537, lng: 19.9734955 },
    { lat: 48.3843852, lng: 19.9733929 },
    { lat: 48.3843617, lng: 19.9731815 },
    { lat: 48.3844898, lng: 19.9732245 },
    { lat: 48.3844371, lng: 19.9731236 },
    { lat: 48.384445, lng: 19.9729648 },
    { lat: 48.3844903, lng: 19.9730537 },
    { lat: 48.3846157, lng: 19.9730102 },
    { lat: 48.3845509, lng: 19.9729398 },
    { lat: 48.3847135, lng: 19.972633 },
    { lat: 48.3846939, lng: 19.9725934 },
    { lat: 48.3846196, lng: 19.9726448 },
    { lat: 48.3845769, lng: 19.9725663 },
    { lat: 48.3846026, lng: 19.9723487 },
    { lat: 48.3846333, lng: 19.9723323 },
    { lat: 48.3847279, lng: 19.9724934 },
    { lat: 48.3849915, lng: 19.972223 },
    { lat: 48.3850228, lng: 19.9724327 },
    { lat: 48.3851616, lng: 19.9723323 },
    { lat: 48.3851128, lng: 19.9722164 },
    { lat: 48.3851422, lng: 19.9720846 },
    { lat: 48.3852438, lng: 19.9721986 },
    { lat: 48.3852993, lng: 19.9721001 },
    { lat: 48.3851793, lng: 19.9719531 },
    { lat: 48.3852887, lng: 19.9717924 },
    { lat: 48.3853495, lng: 19.9719525 },
    { lat: 48.3855226, lng: 19.9717804 },
    { lat: 48.3854183, lng: 19.9716869 },
    { lat: 48.3853696, lng: 19.9714966 },
    { lat: 48.3855665, lng: 19.97133 },
    { lat: 48.3856697, lng: 19.9711068 },
    { lat: 48.385728, lng: 19.9711293 },
    { lat: 48.3857672, lng: 19.9710685 },
    { lat: 48.3857317, lng: 19.9708716 },
    { lat: 48.3854764, lng: 19.9705838 },
    { lat: 48.3855163, lng: 19.9704116 },
    { lat: 48.3856313, lng: 19.9704893 },
    { lat: 48.3857121, lng: 19.9704092 },
    { lat: 48.3857006, lng: 19.9701587 },
    { lat: 48.3855863, lng: 19.9702806 },
    { lat: 48.3854366, lng: 19.9702286 },
    { lat: 48.3855085, lng: 19.9699875 },
    { lat: 48.3853871, lng: 19.9699143 },
    { lat: 48.3853269, lng: 19.9697401 },
    { lat: 48.3854057, lng: 19.969662 },
    { lat: 48.3854763, lng: 19.969706 },
    { lat: 48.3855872, lng: 19.9695607 },
    { lat: 48.3854551, lng: 19.969551 },
    { lat: 48.3853673, lng: 19.96934 },
    { lat: 48.3855185, lng: 19.9691583 },
    { lat: 48.385334, lng: 19.9689257 },
    { lat: 48.3854077, lng: 19.9687773 },
    { lat: 48.3853159, lng: 19.9683104 },
    { lat: 48.3853734, lng: 19.9681515 },
    { lat: 48.3853466, lng: 19.9679485 },
    { lat: 48.3854707, lng: 19.9679239 },
    { lat: 48.385578, lng: 19.9677719 },
    { lat: 48.3855671, lng: 19.9676978 },
    { lat: 48.3854996, lng: 19.9676752 },
    { lat: 48.3855076, lng: 19.9672523 },
    { lat: 48.3856622, lng: 19.9672337 },
    { lat: 48.3856897, lng: 19.9670819 },
    { lat: 48.3857665, lng: 19.9671954 },
    { lat: 48.3858951, lng: 19.9671987 },
    { lat: 48.3858744, lng: 19.9671103 },
    { lat: 48.3859416, lng: 19.9670472 },
    { lat: 48.3859483, lng: 19.9669671 },
    { lat: 48.3859052, lng: 19.9668802 },
    { lat: 48.3858448, lng: 19.9669983 },
    { lat: 48.3857147, lng: 19.9669299 },
    { lat: 48.3859551, lng: 19.9664356 },
    { lat: 48.3860347, lng: 19.9664546 },
    { lat: 48.3859933, lng: 19.9666194 },
    { lat: 48.3860214, lng: 19.9668588 },
    { lat: 48.3863052, lng: 19.9666924 },
    { lat: 48.3861687, lng: 19.9665013 },
    { lat: 48.3863356, lng: 19.9664577 },
    { lat: 48.3862546, lng: 19.9662171 },
    { lat: 48.3863445, lng: 19.9661606 },
    { lat: 48.3863277, lng: 19.9658985 },
    { lat: 48.3863592, lng: 19.9658172 },
    { lat: 48.3865512, lng: 19.9659094 },
    { lat: 48.3865268, lng: 19.9657066 },
    { lat: 48.3866694, lng: 19.9655838 },
    { lat: 48.386727, lng: 19.9656942 },
    { lat: 48.3866559, lng: 19.9659331 },
    { lat: 48.386747, lng: 19.9660343 },
    { lat: 48.3868834, lng: 19.965961 },
    { lat: 48.386796, lng: 19.9657588 },
    { lat: 48.3868672, lng: 19.9657053 },
    { lat: 48.3869438, lng: 19.9657872 },
    { lat: 48.3870457, lng: 19.9657736 },
    { lat: 48.3871283, lng: 19.9657005 },
    { lat: 48.3870563, lng: 19.965516 },
    { lat: 48.3870741, lng: 19.9653307 },
    { lat: 48.3871551, lng: 19.9652479 },
    { lat: 48.3871697, lng: 19.9654028 },
    { lat: 48.3872917, lng: 19.9653851 },
    { lat: 48.3872887, lng: 19.9653127 },
    { lat: 48.3873807, lng: 19.9652004 },
    { lat: 48.3873756, lng: 19.9650808 },
    { lat: 48.3873038, lng: 19.9650079 },
    { lat: 48.3874111, lng: 19.9648254 },
    { lat: 48.3875173, lng: 19.9648592 },
    { lat: 48.3876321, lng: 19.9647274 },
    { lat: 48.3881449, lng: 19.9649183 },
    { lat: 48.3882122, lng: 19.9646957 },
    { lat: 48.3883158, lng: 19.9646458 },
    { lat: 48.3885265, lng: 19.9641645 },
    { lat: 48.3885958, lng: 19.9642345 },
    { lat: 48.3887511, lng: 19.9640718 },
    { lat: 48.3886949, lng: 19.9640428 },
    { lat: 48.3886985, lng: 19.9635722 },
    { lat: 48.3888687, lng: 19.9633886 },
    { lat: 48.3888667, lng: 19.9626778 },
    { lat: 48.3888242, lng: 19.9625487 },
    { lat: 48.3891638, lng: 19.961902 },
    { lat: 48.3895444, lng: 19.9615746 },
    { lat: 48.3896489, lng: 19.9612775 },
    { lat: 48.3897616, lng: 19.9612533 },
    { lat: 48.3899516, lng: 19.9610296 },
    { lat: 48.3905724, lng: 19.9607667 },
    { lat: 48.3908113, lng: 19.9600258 },
    { lat: 48.3913546, lng: 19.9589453 },
    { lat: 48.3917189, lng: 19.9586116 },
    { lat: 48.3919468, lng: 19.9578469 },
    { lat: 48.3931426, lng: 19.9544641 },
    { lat: 48.3933497, lng: 19.9532931 },
    { lat: 48.3937475, lng: 19.9521338 },
    { lat: 48.3941067, lng: 19.9515436 },
    { lat: 48.3944133, lng: 19.9506472 },
    { lat: 48.3952028, lng: 19.9508838 },
    { lat: 48.3958422, lng: 19.951165 },
    { lat: 48.3975039, lng: 19.9520373 },
    { lat: 48.3992291, lng: 19.9544902 },
    { lat: 48.4000482, lng: 19.9544107 },
    { lat: 48.4005474, lng: 19.9499366 },
    { lat: 48.40111, lng: 19.9487015 },
    { lat: 48.4021034, lng: 19.9494673 },
    { lat: 48.4026316, lng: 19.9466291 },
    { lat: 48.402258, lng: 19.9439898 },
    { lat: 48.4021625, lng: 19.9427493 },
    { lat: 48.4025533, lng: 19.939759 },
    { lat: 48.4025785, lng: 19.9390809 },
    { lat: 48.4025171, lng: 19.9383816 },
    { lat: 48.4018874, lng: 19.9341539 },
    { lat: 48.3948161, lng: 19.922017 },
    { lat: 48.3953559, lng: 19.9219717 },
    { lat: 48.396486, lng: 19.9215526 },
    { lat: 48.3973901, lng: 19.9209121 },
    { lat: 48.3978165, lng: 19.9204984 },
    { lat: 48.398002, lng: 19.920232 },
    { lat: 48.397998, lng: 19.919696 },
    { lat: 48.397987, lng: 19.918684 },
    { lat: 48.397978, lng: 19.918103 },
    { lat: 48.397983, lng: 19.917892 },
    { lat: 48.397986, lng: 19.91697 },
    { lat: 48.398062, lng: 19.91619 },
    { lat: 48.39812, lng: 19.915875 },
    { lat: 48.398337, lng: 19.914738 },
    { lat: 48.398144, lng: 19.914065 },
    { lat: 48.398144, lng: 19.913235 },
    { lat: 48.398145, lng: 19.912011 },
    { lat: 48.398153, lng: 19.911674 },
    { lat: 48.398101, lng: 19.911086 },
    { lat: 48.398182, lng: 19.911112 },
    { lat: 48.39848, lng: 19.910115 },
    { lat: 48.398633, lng: 19.909725 },
    { lat: 48.398639, lng: 19.909711 },
    { lat: 48.398878, lng: 19.909105 },
    { lat: 48.399053, lng: 19.909103 },
    { lat: 48.399449, lng: 19.909108 },
    { lat: 48.399834, lng: 19.909115 },
    { lat: 48.400264, lng: 19.909121 },
    { lat: 48.400261, lng: 19.909069 },
    { lat: 48.400039, lng: 19.908163 },
    { lat: 48.399781, lng: 19.907119 },
    { lat: 48.399471, lng: 19.905873 },
    { lat: 48.39946, lng: 19.905828 },
    { lat: 48.399322, lng: 19.905296 },
    { lat: 48.399313, lng: 19.905257 },
    { lat: 48.399197, lng: 19.904807 },
    { lat: 48.399185, lng: 19.904761 },
    { lat: 48.399001, lng: 19.904717 },
    { lat: 48.398537, lng: 19.903907 },
    { lat: 48.398053, lng: 19.903468 },
    { lat: 48.397852, lng: 19.903346 },
    { lat: 48.39787, lng: 19.902932 },
    { lat: 48.397841, lng: 19.902721 },
    { lat: 48.397763, lng: 19.902085 },
    { lat: 48.397815, lng: 19.90163 },
    { lat: 48.39768, lng: 19.901728 },
    { lat: 48.39742, lng: 19.901917 },
    { lat: 48.397303, lng: 19.901995 },
    { lat: 48.397168, lng: 19.901959 },
    { lat: 48.397028, lng: 19.901922 },
    { lat: 48.396729, lng: 19.901554 },
    { lat: 48.396534, lng: 19.900811 },
    { lat: 48.396765, lng: 19.900838 },
    { lat: 48.396742, lng: 19.900746 },
    { lat: 48.396558, lng: 19.899888 },
    { lat: 48.396356, lng: 19.898923 },
    { lat: 48.396304, lng: 19.898663 },
    { lat: 48.396127, lng: 19.89783 },
    { lat: 48.395963, lng: 19.897038 },
    { lat: 48.395796, lng: 19.896235 },
    { lat: 48.395613, lng: 19.895359 },
    { lat: 48.395478, lng: 19.894706 },
    { lat: 48.395297, lng: 19.893856 },
    { lat: 48.394972, lng: 19.892297 },
    { lat: 48.39483, lng: 19.891611 },
    { lat: 48.394828, lng: 19.891605 },
    { lat: 48.394784, lng: 19.891387 },
    { lat: 48.394777, lng: 19.891352 },
    { lat: 48.39466, lng: 19.890809 },
    { lat: 48.394149, lng: 19.888413 },
    { lat: 48.393933, lng: 19.887407 },
    { lat: 48.39369, lng: 19.886258 },
    { lat: 48.393594, lng: 19.886265 },
    { lat: 48.393406, lng: 19.885183 },
    { lat: 48.393238, lng: 19.88421 },
    { lat: 48.393819, lng: 19.883612 },
    { lat: 48.393715, lng: 19.882475 },
    { lat: 48.393766, lng: 19.881421 },
    { lat: 48.39377, lng: 19.881381 },
    { lat: 48.393786, lng: 19.881205 },
    { lat: 48.393791, lng: 19.881162 },
    { lat: 48.393863, lng: 19.880353 },
    { lat: 48.394832, lng: 19.880164 },
    { lat: 48.395162, lng: 19.879861 },
    { lat: 48.394902, lng: 19.877409 },
    { lat: 48.395622, lng: 19.874726 },
    { lat: 48.395895, lng: 19.87372 },
    { lat: 48.396134, lng: 19.872979 },
    { lat: 48.397487, lng: 19.868923 },
    { lat: 48.397807, lng: 19.866807 },
    { lat: 48.398197, lng: 19.864952 },
    { lat: 48.398408, lng: 19.863936 },
    { lat: 48.398563, lng: 19.86344 },
    { lat: 48.398755, lng: 19.863059 },
    { lat: 48.398943, lng: 19.86267 },
    { lat: 48.399673, lng: 19.861033 },
    { lat: 48.400672, lng: 19.858214 },
    { lat: 48.400739, lng: 19.857342 },
    { lat: 48.400764, lng: 19.857063 },
    { lat: 48.400896, lng: 19.856265 },
    { lat: 48.401028, lng: 19.855503 },
    { lat: 48.401045, lng: 19.855362 },
    { lat: 48.401056, lng: 19.855273 },
    { lat: 48.401083, lng: 19.855048 },
    { lat: 48.40111, lng: 19.854835 },
    { lat: 48.401157, lng: 19.854467 },
    { lat: 48.40124, lng: 19.853828 },
    { lat: 48.401343, lng: 19.85303 },
    { lat: 48.401484, lng: 19.851907 },
    { lat: 48.40155, lng: 19.851379 },
    { lat: 48.401539, lng: 19.851104 },
    { lat: 48.401504, lng: 19.850388 },
    { lat: 48.40146, lng: 19.849381 },
    { lat: 48.401467, lng: 19.84862 },
    { lat: 48.40147, lng: 19.847982 },
    { lat: 48.401475, lng: 19.847116 },
    { lat: 48.401481, lng: 19.845891 },
    { lat: 48.40145, lng: 19.845446 },
    { lat: 48.401393, lng: 19.844698 },
    { lat: 48.401316, lng: 19.84368 },
    { lat: 48.401234, lng: 19.842599 },
    { lat: 48.401179, lng: 19.841868 },
    { lat: 48.400959, lng: 19.840984 },
    { lat: 48.400751, lng: 19.840168 },
    { lat: 48.40063, lng: 19.839679 },
  ],
  Potok: [
    { lat: 48.5681923, lng: 20.0308663 },
    { lat: 48.5679249, lng: 20.0308276 },
    { lat: 48.5673595, lng: 20.0308583 },
    { lat: 48.5669211, lng: 20.0307721 },
    { lat: 48.5658665, lng: 20.0302509 },
    { lat: 48.5649587, lng: 20.029873 },
    { lat: 48.5647813, lng: 20.0298624 },
    { lat: 48.5645386, lng: 20.0303594 },
    { lat: 48.5639333, lng: 20.0320006 },
    { lat: 48.5636361, lng: 20.0322491 },
    { lat: 48.5634027, lng: 20.0322687 },
    { lat: 48.5627218, lng: 20.0319867 },
    { lat: 48.561271, lng: 20.0310933 },
    { lat: 48.5605612, lng: 20.0312526 },
    { lat: 48.5601384, lng: 20.0312161 },
    { lat: 48.5590814, lng: 20.0308043 },
    { lat: 48.5580686, lng: 20.0300958 },
    { lat: 48.5574527, lng: 20.0300729 },
    { lat: 48.5569383, lng: 20.0292714 },
    { lat: 48.5565066, lng: 20.0287234 },
    { lat: 48.5558654, lng: 20.0279613 },
    { lat: 48.554992, lng: 20.0270611 },
    { lat: 48.5545041, lng: 20.0267617 },
    { lat: 48.5541937, lng: 20.0268007 },
    { lat: 48.5523704, lng: 20.0264306 },
    { lat: 48.5521598, lng: 20.026271 },
    { lat: 48.5518009, lng: 20.0263576 },
    { lat: 48.5512262, lng: 20.0268116 },
    { lat: 48.5503818, lng: 20.0276875 },
    { lat: 48.5498936, lng: 20.0279751 },
    { lat: 48.5497804, lng: 20.0278532 },
    { lat: 48.5495728, lng: 20.0279013 },
    { lat: 48.5487884, lng: 20.0284302 },
    { lat: 48.5482178, lng: 20.0292773 },
    { lat: 48.547995, lng: 20.0292273 },
    { lat: 48.5474783, lng: 20.0298332 },
    { lat: 48.547497, lng: 20.029931 },
    { lat: 48.5477376, lng: 20.0310551 },
    { lat: 48.5467159, lng: 20.0313522 },
    { lat: 48.5466502, lng: 20.0321753 },
    { lat: 48.5463683, lng: 20.0324988 },
    { lat: 48.5451086, lng: 20.0333775 },
    { lat: 48.5447226, lng: 20.0334723 },
    { lat: 48.5443395, lng: 20.0334367 },
    { lat: 48.5437745, lng: 20.0338306 },
    { lat: 48.5435017, lng: 20.0338482 },
    { lat: 48.5429348, lng: 20.0336799 },
    { lat: 48.5424551, lng: 20.0340349 },
    { lat: 48.5424346, lng: 20.0340581 },
    { lat: 48.5425762, lng: 20.0343465 },
    { lat: 48.54261, lng: 20.0346992 },
    { lat: 48.542734, lng: 20.0349459 },
    { lat: 48.5429409, lng: 20.035124 },
    { lat: 48.5431241, lng: 20.0364685 },
    { lat: 48.5431246, lng: 20.036889 },
    { lat: 48.5429115, lng: 20.0383982 },
    { lat: 48.5429816, lng: 20.0386201 },
    { lat: 48.5428913, lng: 20.0394843 },
    { lat: 48.5428895, lng: 20.040467 },
    { lat: 48.5428124, lng: 20.0411052 },
    { lat: 48.5432558, lng: 20.0416631 },
    { lat: 48.5429789, lng: 20.0435927 },
    { lat: 48.5431479, lng: 20.0444843 },
    { lat: 48.5427057, lng: 20.0452835 },
    { lat: 48.5427251, lng: 20.0456695 },
    { lat: 48.5431225, lng: 20.0470191 },
    { lat: 48.5432599, lng: 20.0478614 },
    { lat: 48.5436395, lng: 20.0493802 },
    { lat: 48.5436128, lng: 20.0497943 },
    { lat: 48.5434413, lng: 20.050274 },
    { lat: 48.54254, lng: 20.0521991 },
    { lat: 48.5424983, lng: 20.0527127 },
    { lat: 48.5428315, lng: 20.0536856 },
    { lat: 48.5430013, lng: 20.053896 },
    { lat: 48.5431823, lng: 20.0539121 },
    { lat: 48.5432986, lng: 20.0559309 },
    { lat: 48.543217, lng: 20.0563551 },
    { lat: 48.542563, lng: 20.0573707 },
    { lat: 48.5425581, lng: 20.0573882 },
    { lat: 48.5430314, lng: 20.0576342 },
    { lat: 48.5433791, lng: 20.058226 },
    { lat: 48.5441359, lng: 20.0580582 },
    { lat: 48.5452867, lng: 20.0588128 },
    { lat: 48.5453053, lng: 20.0587439 },
    { lat: 48.5464828, lng: 20.0601643 },
    { lat: 48.547117, lng: 20.0607544 },
    { lat: 48.5483329, lng: 20.0617285 },
    { lat: 48.5483665, lng: 20.0617005 },
    { lat: 48.5485135, lng: 20.0619857 },
    { lat: 48.5488148, lng: 20.0623253 },
    { lat: 48.5491584, lng: 20.0624882 },
    { lat: 48.5493531, lng: 20.0628404 },
    { lat: 48.5495858, lng: 20.0635068 },
    { lat: 48.549931, lng: 20.0639255 },
    { lat: 48.5500444, lng: 20.0642487 },
    { lat: 48.5504379, lng: 20.0646074 },
    { lat: 48.5509719, lng: 20.065728 },
    { lat: 48.5514008, lng: 20.066215 },
    { lat: 48.5521654, lng: 20.0668049 },
    { lat: 48.5528576, lng: 20.0676124 },
    { lat: 48.5538162, lng: 20.0685091 },
    { lat: 48.5544266, lng: 20.0688885 },
    { lat: 48.5546551, lng: 20.0688816 },
    { lat: 48.5548628, lng: 20.068785 },
    { lat: 48.5551089, lng: 20.0693699 },
    { lat: 48.555117, lng: 20.0695391 },
    { lat: 48.5552519, lng: 20.0698901 },
    { lat: 48.5552896, lng: 20.0703385 },
    { lat: 48.5558732, lng: 20.0703414 },
    { lat: 48.5563425, lng: 20.0706867 },
    { lat: 48.5566608, lng: 20.0710042 },
    { lat: 48.557476, lng: 20.0713733 },
    { lat: 48.5575893, lng: 20.0715796 },
    { lat: 48.5580698, lng: 20.0720071 },
    { lat: 48.5586639, lng: 20.0728635 },
    { lat: 48.5588934, lng: 20.0731054 },
    { lat: 48.5591102, lng: 20.0735246 },
    { lat: 48.5595483, lng: 20.0741481 },
    { lat: 48.5597843, lng: 20.0746277 },
    { lat: 48.5598073, lng: 20.0748322 },
    { lat: 48.560077, lng: 20.0757154 },
    { lat: 48.5602538, lng: 20.0758233 },
    { lat: 48.5604055, lng: 20.0758674 },
    { lat: 48.5607091, lng: 20.0757508 },
    { lat: 48.5611007, lng: 20.0750882 },
    { lat: 48.5616062, lng: 20.0748329 },
    { lat: 48.5618564, lng: 20.0743699 },
    { lat: 48.5619727, lng: 20.0741641 },
    { lat: 48.562052, lng: 20.073901 },
    { lat: 48.562902, lng: 20.0721991 },
    { lat: 48.5634661, lng: 20.071866 },
    { lat: 48.5639197, lng: 20.0721051 },
    { lat: 48.5640291, lng: 20.07208 },
    { lat: 48.5658141, lng: 20.071174 },
    { lat: 48.5660481, lng: 20.0711648 },
    { lat: 48.5664577, lng: 20.0709909 },
    { lat: 48.5667515, lng: 20.0706856 },
    { lat: 48.5671486, lng: 20.0700765 },
    { lat: 48.5672837, lng: 20.070269 },
    { lat: 48.5674635, lng: 20.0699584 },
    { lat: 48.5675466, lng: 20.0696786 },
    { lat: 48.5680353, lng: 20.0697277 },
    { lat: 48.568282, lng: 20.0690192 },
    { lat: 48.5682865, lng: 20.0684718 },
    { lat: 48.5686994, lng: 20.0682962 },
    { lat: 48.5691514, lng: 20.0679778 },
    { lat: 48.5690148, lng: 20.0674244 },
    { lat: 48.5700418, lng: 20.0673977 },
    { lat: 48.5703671, lng: 20.0673355 },
    { lat: 48.5703803, lng: 20.065258 },
    { lat: 48.5705392, lng: 20.0646029 },
    { lat: 48.570861, lng: 20.0646224 },
    { lat: 48.5706435, lng: 20.0637744 },
    { lat: 48.5698345, lng: 20.063499 },
    { lat: 48.5690539, lng: 20.0631156 },
    { lat: 48.5693907, lng: 20.0627102 },
    { lat: 48.5703843, lng: 20.0619262 },
    { lat: 48.5705473, lng: 20.0617515 },
    { lat: 48.5706007, lng: 20.0615843 },
    { lat: 48.5706501, lng: 20.06143 },
    { lat: 48.5708105, lng: 20.0612008 },
    { lat: 48.5713007, lng: 20.0613123 },
    { lat: 48.5715874, lng: 20.0615169 },
    { lat: 48.5718886, lng: 20.0610376 },
    { lat: 48.5719946, lng: 20.0607708 },
    { lat: 48.5723276, lng: 20.0610596 },
    { lat: 48.5728318, lng: 20.0613757 },
    { lat: 48.5730249, lng: 20.0613867 },
    { lat: 48.5733106, lng: 20.0612688 },
    { lat: 48.57333, lng: 20.0610017 },
    { lat: 48.5732019, lng: 20.0607349 },
    { lat: 48.573089, lng: 20.0601579 },
    { lat: 48.5731898, lng: 20.0598742 },
    { lat: 48.5732265, lng: 20.0594553 },
    { lat: 48.5730799, lng: 20.0592898 },
    { lat: 48.5728512, lng: 20.0592886 },
    { lat: 48.5725518, lng: 20.0579303 },
    { lat: 48.5728923, lng: 20.0577806 },
    { lat: 48.5733212, lng: 20.057392 },
    { lat: 48.5738128, lng: 20.0565306 },
    { lat: 48.5738842, lng: 20.0561358 },
    { lat: 48.5739233, lng: 20.0560983 },
    { lat: 48.5741299, lng: 20.0560042 },
    { lat: 48.5743945, lng: 20.056271 },
    { lat: 48.5745576, lng: 20.0554799 },
    { lat: 48.5742981, lng: 20.0552493 },
    { lat: 48.5744008, lng: 20.0547946 },
    { lat: 48.5734466, lng: 20.0538419 },
    { lat: 48.5731237, lng: 20.0534135 },
    { lat: 48.573083, lng: 20.05303 },
    { lat: 48.5730927, lng: 20.0523426 },
    { lat: 48.5731985, lng: 20.0520403 },
    { lat: 48.5735335, lng: 20.051508 },
    { lat: 48.5735813, lng: 20.0512214 },
    { lat: 48.5736805, lng: 20.0511146 },
    { lat: 48.5736929, lng: 20.0509804 },
    { lat: 48.5737682, lng: 20.0509178 },
    { lat: 48.5738563, lng: 20.0507082 },
    { lat: 48.5734467, lng: 20.049524 },
    { lat: 48.5734069, lng: 20.0489458 },
    { lat: 48.5735034, lng: 20.0479828 },
    { lat: 48.5725206, lng: 20.0482415 },
    { lat: 48.5724818, lng: 20.0482327 },
    { lat: 48.5724372, lng: 20.0480015 },
    { lat: 48.5726264, lng: 20.046912 },
    { lat: 48.5726682, lng: 20.0459911 },
    { lat: 48.5726188, lng: 20.045479 },
    { lat: 48.5728365, lng: 20.0448894 },
    { lat: 48.5729217, lng: 20.0438485 },
    { lat: 48.5728407, lng: 20.0433653 },
    { lat: 48.572824, lng: 20.0428035 },
    { lat: 48.5730805, lng: 20.0411968 },
    { lat: 48.5731331, lng: 20.0399957 },
    { lat: 48.5726559, lng: 20.0400031 },
    { lat: 48.5723464, lng: 20.0387945 },
    { lat: 48.572802, lng: 20.0385626 },
    { lat: 48.572651, lng: 20.0374522 },
    { lat: 48.5725661, lng: 20.0374147 },
    { lat: 48.5724886, lng: 20.037389 },
    { lat: 48.5723828, lng: 20.0373371 },
    { lat: 48.572141, lng: 20.0369943 },
    { lat: 48.5716216, lng: 20.0365593 },
    { lat: 48.5714428, lng: 20.0367568 },
    { lat: 48.5712561, lng: 20.0367894 },
    { lat: 48.5709986, lng: 20.0366946 },
    { lat: 48.5705914, lng: 20.0370286 },
    { lat: 48.5701548, lng: 20.0370965 },
    { lat: 48.5699063, lng: 20.0369976 },
    { lat: 48.569512, lng: 20.0370228 },
    { lat: 48.5692185, lng: 20.0369296 },
    { lat: 48.5691393, lng: 20.036999 },
    { lat: 48.5687597, lng: 20.0369975 },
    { lat: 48.5686643, lng: 20.0369514 },
    { lat: 48.5684177, lng: 20.0365788 },
    { lat: 48.5681704, lng: 20.0365648 },
    { lat: 48.568144, lng: 20.0361455 },
    { lat: 48.5683033, lng: 20.0352598 },
    { lat: 48.567896, lng: 20.0353819 },
    { lat: 48.5678286, lng: 20.0352524 },
    { lat: 48.5677869, lng: 20.0346532 },
    { lat: 48.5678004, lng: 20.0332489 },
    { lat: 48.5685816, lng: 20.0322617 },
    { lat: 48.5686608, lng: 20.0320252 },
    { lat: 48.5684774, lng: 20.0316794 },
    { lat: 48.5681273, lng: 20.031497 },
    { lat: 48.5681923, lng: 20.0308663 },
  ],
  Hostišovce: [
    { lat: 48.4812257, lng: 20.1276781 },
    { lat: 48.4811561, lng: 20.1279622 },
    { lat: 48.481672, lng: 20.1279336 },
    { lat: 48.4818015, lng: 20.1280168 },
    { lat: 48.481977, lng: 20.1282345 },
    { lat: 48.4820399, lng: 20.12856 },
    { lat: 48.4820137, lng: 20.128775 },
    { lat: 48.4817494, lng: 20.1296064 },
    { lat: 48.4816258, lng: 20.1298341 },
    { lat: 48.481448, lng: 20.1298147 },
    { lat: 48.4815557, lng: 20.1312365 },
    { lat: 48.4821099, lng: 20.1311501 },
    { lat: 48.4828302, lng: 20.1312763 },
    { lat: 48.4829925, lng: 20.1314992 },
    { lat: 48.483259, lng: 20.1316787 },
    { lat: 48.4833592, lng: 20.1316826 },
    { lat: 48.4834321, lng: 20.1319485 },
    { lat: 48.4839909, lng: 20.131899 },
    { lat: 48.4840134, lng: 20.1325601 },
    { lat: 48.4842646, lng: 20.1326942 },
    { lat: 48.4845713, lng: 20.1327308 },
    { lat: 48.4848547, lng: 20.132613 },
    { lat: 48.4860837, lng: 20.1328616 },
    { lat: 48.4862683, lng: 20.1327767 },
    { lat: 48.4863321, lng: 20.1328241 },
    { lat: 48.4861453, lng: 20.1330476 },
    { lat: 48.4860866, lng: 20.1333492 },
    { lat: 48.4862015, lng: 20.1338747 },
    { lat: 48.4862162, lng: 20.1341779 },
    { lat: 48.4863316, lng: 20.1342454 },
    { lat: 48.4864092, lng: 20.1344349 },
    { lat: 48.4864064, lng: 20.1347838 },
    { lat: 48.4863392, lng: 20.1349587 },
    { lat: 48.4861273, lng: 20.1352257 },
    { lat: 48.4861179, lng: 20.1354681 },
    { lat: 48.4858934, lng: 20.1358158 },
    { lat: 48.4858685, lng: 20.1360325 },
    { lat: 48.485919, lng: 20.1363616 },
    { lat: 48.4858806, lng: 20.1366048 },
    { lat: 48.4859496, lng: 20.1368335 },
    { lat: 48.486028, lng: 20.1369314 },
    { lat: 48.4860476, lng: 20.1372739 },
    { lat: 48.4862556, lng: 20.1373513 },
    { lat: 48.4863486, lng: 20.1374773 },
    { lat: 48.4864637, lng: 20.1381984 },
    { lat: 48.4863106, lng: 20.1386983 },
    { lat: 48.4862414, lng: 20.1387963 },
    { lat: 48.486173, lng: 20.1393575 },
    { lat: 48.486203, lng: 20.139735 },
    { lat: 48.4863988, lng: 20.1397567 },
    { lat: 48.4863843, lng: 20.1398404 },
    { lat: 48.4864716, lng: 20.1399389 },
    { lat: 48.4865091, lng: 20.1400867 },
    { lat: 48.4866009, lng: 20.1401283 },
    { lat: 48.4866811, lng: 20.1402707 },
    { lat: 48.486867, lng: 20.1402976 },
    { lat: 48.4868996, lng: 20.140438 },
    { lat: 48.4869935, lng: 20.1405287 },
    { lat: 48.4870811, lng: 20.1404369 },
    { lat: 48.487236, lng: 20.1406473 },
    { lat: 48.4871616, lng: 20.1408147 },
    { lat: 48.4872472, lng: 20.1412247 },
    { lat: 48.4873236, lng: 20.1421316 },
    { lat: 48.4878048, lng: 20.1442147 },
    { lat: 48.4881262, lng: 20.144745 },
    { lat: 48.4884414, lng: 20.1449929 },
    { lat: 48.4888834, lng: 20.1451709 },
    { lat: 48.4896037, lng: 20.1451272 },
    { lat: 48.4899732, lng: 20.1449919 },
    { lat: 48.4908092, lng: 20.1445285 },
    { lat: 48.49098, lng: 20.1445448 },
    { lat: 48.4912618, lng: 20.1448284 },
    { lat: 48.4918961, lng: 20.1451889 },
    { lat: 48.4923197, lng: 20.1456626 },
    { lat: 48.4924514, lng: 20.145914 },
    { lat: 48.4925892, lng: 20.1464162 },
    { lat: 48.4925013, lng: 20.1475499 },
    { lat: 48.4923585, lng: 20.1484724 },
    { lat: 48.4924247, lng: 20.1493502 },
    { lat: 48.4923408, lng: 20.1496959 },
    { lat: 48.4923622, lng: 20.1503608 },
    { lat: 48.4924993, lng: 20.1512136 },
    { lat: 48.4926801, lng: 20.1516429 },
    { lat: 48.4929133, lng: 20.151973 },
    { lat: 48.4929755, lng: 20.1528397 },
    { lat: 48.4932304, lng: 20.1536472 },
    { lat: 48.4942285, lng: 20.1542918 },
    { lat: 48.4943148, lng: 20.154422 },
    { lat: 48.4947514, lng: 20.1552124 },
    { lat: 48.4948006, lng: 20.1555921 },
    { lat: 48.4951111, lng: 20.1560245 },
    { lat: 48.49544, lng: 20.157127 },
    { lat: 48.495478, lng: 20.157058 },
    { lat: 48.495653, lng: 20.156781 },
    { lat: 48.495782, lng: 20.156578 },
    { lat: 48.495827, lng: 20.156162 },
    { lat: 48.495905, lng: 20.155534 },
    { lat: 48.495962, lng: 20.155095 },
    { lat: 48.496007, lng: 20.154798 },
    { lat: 48.496045, lng: 20.154559 },
    { lat: 48.496039, lng: 20.154218 },
    { lat: 48.49608, lng: 20.153901 },
    { lat: 48.496113, lng: 20.153542 },
    { lat: 48.496189, lng: 20.15277 },
    { lat: 48.496211, lng: 20.15239 },
    { lat: 48.496232, lng: 20.152028 },
    { lat: 48.496249, lng: 20.151778 },
    { lat: 48.496159, lng: 20.151518 },
    { lat: 48.496101, lng: 20.150945 },
    { lat: 48.496067, lng: 20.150654 },
    { lat: 48.49604, lng: 20.150394 },
    { lat: 48.496019, lng: 20.150186 },
    { lat: 48.49598, lng: 20.149741 },
    { lat: 48.495962, lng: 20.149512 },
    { lat: 48.496037, lng: 20.149268 },
    { lat: 48.49614, lng: 20.148929 },
    { lat: 48.496228, lng: 20.148239 },
    { lat: 48.496278, lng: 20.147818 },
    { lat: 48.496378, lng: 20.147471 },
    { lat: 48.496482, lng: 20.1471 },
    { lat: 48.496628, lng: 20.146854 },
    { lat: 48.496812, lng: 20.146573 },
    { lat: 48.496944, lng: 20.146372 },
    { lat: 48.497135, lng: 20.146195 },
    { lat: 48.497486, lng: 20.145874 },
    { lat: 48.497689, lng: 20.145678 },
    { lat: 48.498022, lng: 20.145535 },
    { lat: 48.49823, lng: 20.145447 },
    { lat: 48.498339, lng: 20.145334 },
    { lat: 48.498564, lng: 20.145097 },
    { lat: 48.498671, lng: 20.144984 },
    { lat: 48.49871, lng: 20.144961 },
    { lat: 48.498851, lng: 20.144905 },
    { lat: 48.499359, lng: 20.145134 },
    { lat: 48.499475, lng: 20.145251 },
    { lat: 48.499677, lng: 20.145446 },
    { lat: 48.499789, lng: 20.145553 },
    { lat: 48.499949, lng: 20.145597 },
    { lat: 48.500229, lng: 20.145676 },
    { lat: 48.500534, lng: 20.145735 },
    { lat: 48.500829, lng: 20.145785 },
    { lat: 48.501175, lng: 20.145874 },
    { lat: 48.50137, lng: 20.145927 },
    { lat: 48.501655, lng: 20.146003 },
    { lat: 48.501923, lng: 20.145999 },
    { lat: 48.502212, lng: 20.145996 },
    { lat: 48.502425, lng: 20.145908 },
    { lat: 48.502715, lng: 20.145783 },
    { lat: 48.502841, lng: 20.145733 },
    { lat: 48.503066, lng: 20.145971 },
    { lat: 48.503328, lng: 20.146239 },
    { lat: 48.503895, lng: 20.146668 },
    { lat: 48.504128, lng: 20.146839 },
    { lat: 48.504379, lng: 20.146981 },
    { lat: 48.504609, lng: 20.147106 },
    { lat: 48.504702, lng: 20.147216 },
    { lat: 48.504923, lng: 20.147472 },
    { lat: 48.505056, lng: 20.147627 },
    { lat: 48.505181, lng: 20.14775 },
    { lat: 48.505447, lng: 20.148 },
    { lat: 48.505877, lng: 20.148153 },
    { lat: 48.506144, lng: 20.148293 },
    { lat: 48.506289, lng: 20.148444 },
    { lat: 48.506388, lng: 20.148641 },
    { lat: 48.506615, lng: 20.148718 },
    { lat: 48.506786, lng: 20.148768 },
    { lat: 48.506852, lng: 20.148783 },
    { lat: 48.506903, lng: 20.148777 },
    { lat: 48.50718, lng: 20.148726 },
    { lat: 48.507455, lng: 20.14861 },
    { lat: 48.507683, lng: 20.148585 },
    { lat: 48.50787, lng: 20.148392 },
    { lat: 48.507977, lng: 20.148353 },
    { lat: 48.508293, lng: 20.148231 },
    { lat: 48.508585, lng: 20.148208 },
    { lat: 48.508832, lng: 20.147902 },
    { lat: 48.509109, lng: 20.14753 },
    { lat: 48.509247, lng: 20.14735 },
    { lat: 48.509409, lng: 20.147159 },
    { lat: 48.509616, lng: 20.146919 },
    { lat: 48.509698, lng: 20.146762 },
    { lat: 48.509942, lng: 20.146312 },
    { lat: 48.510064, lng: 20.146067 },
    { lat: 48.510174, lng: 20.145844 },
    { lat: 48.510384, lng: 20.145507 },
    { lat: 48.510523, lng: 20.145285 },
    { lat: 48.510609, lng: 20.145087 },
    { lat: 48.5101966, lng: 20.1450781 },
    { lat: 48.5099232, lng: 20.1448661 },
    { lat: 48.5098164, lng: 20.1448692 },
    { lat: 48.5096105, lng: 20.1446523 },
    { lat: 48.5089776, lng: 20.1447128 },
    { lat: 48.5072988, lng: 20.1444463 },
    { lat: 48.5068728, lng: 20.1444409 },
    { lat: 48.5065181, lng: 20.1428145 },
    { lat: 48.5064122, lng: 20.1420929 },
    { lat: 48.5060779, lng: 20.1414368 },
    { lat: 48.5056151, lng: 20.1408278 },
    { lat: 48.5049729, lng: 20.1394155 },
    { lat: 48.5046128, lng: 20.1387633 },
    { lat: 48.5043861, lng: 20.1373497 },
    { lat: 48.5041394, lng: 20.1366529 },
    { lat: 48.5046287, lng: 20.1344841 },
    { lat: 48.504939, lng: 20.1338644 },
    { lat: 48.5051199, lng: 20.133637 },
    { lat: 48.5056649, lng: 20.133023 },
    { lat: 48.5064814, lng: 20.1323614 },
    { lat: 48.5070239, lng: 20.1313593 },
    { lat: 48.5074138, lng: 20.1309776 },
    { lat: 48.5090268, lng: 20.1299308 },
    { lat: 48.5095071, lng: 20.1294754 },
    { lat: 48.5101851, lng: 20.128698 },
    { lat: 48.5104441, lng: 20.1281465 },
    { lat: 48.510506, lng: 20.1278992 },
    { lat: 48.511049, lng: 20.1251436 },
    { lat: 48.5110809, lng: 20.1247985 },
    { lat: 48.5111493, lng: 20.1245947 },
    { lat: 48.5119092, lng: 20.1242589 },
    { lat: 48.5126854, lng: 20.1240648 },
    { lat: 48.513346, lng: 20.1232793 },
    { lat: 48.5141709, lng: 20.1228238 },
    { lat: 48.5144545, lng: 20.1225968 },
    { lat: 48.5146196, lng: 20.1223108 },
    { lat: 48.5147316, lng: 20.1218816 },
    { lat: 48.5148175, lng: 20.1217781 },
    { lat: 48.5150388, lng: 20.1216826 },
    { lat: 48.5157185, lng: 20.1216402 },
    { lat: 48.5163026, lng: 20.1219047 },
    { lat: 48.516934, lng: 20.121784 },
    { lat: 48.5173254, lng: 20.121493 },
    { lat: 48.517557, lng: 20.1211238 },
    { lat: 48.5182195, lng: 20.1204957 },
    { lat: 48.5195914, lng: 20.1188727 },
    { lat: 48.5194929, lng: 20.117983 },
    { lat: 48.5192565, lng: 20.1166662 },
    { lat: 48.5189218, lng: 20.1154196 },
    { lat: 48.5189536, lng: 20.114164 },
    { lat: 48.5191207, lng: 20.1129938 },
    { lat: 48.5194455, lng: 20.1117677 },
    { lat: 48.5194532, lng: 20.1112134 },
    { lat: 48.5196643, lng: 20.1103195 },
    { lat: 48.5201791, lng: 20.108892 },
    { lat: 48.5194995, lng: 20.1087971 },
    { lat: 48.5193573, lng: 20.108681 },
    { lat: 48.518909, lng: 20.107937 },
    { lat: 48.5188347, lng: 20.1080815 },
    { lat: 48.5186843, lng: 20.1079822 },
    { lat: 48.5186302, lng: 20.1080092 },
    { lat: 48.5186189, lng: 20.1081492 },
    { lat: 48.5185544, lng: 20.1081413 },
    { lat: 48.5184653, lng: 20.1082704 },
    { lat: 48.5184013, lng: 20.1085218 },
    { lat: 48.5183407, lng: 20.1085293 },
    { lat: 48.5181561, lng: 20.1090808 },
    { lat: 48.5180072, lng: 20.1090761 },
    { lat: 48.5177029, lng: 20.1089371 },
    { lat: 48.5174911, lng: 20.1089639 },
    { lat: 48.5174649, lng: 20.1089006 },
    { lat: 48.5171833, lng: 20.1090376 },
    { lat: 48.517135, lng: 20.1091213 },
    { lat: 48.5169558, lng: 20.109063 },
    { lat: 48.5169141, lng: 20.109145 },
    { lat: 48.5163044, lng: 20.1091939 },
    { lat: 48.516051, lng: 20.1093592 },
    { lat: 48.51526, lng: 20.1096785 },
    { lat: 48.5147752, lng: 20.1097236 },
    { lat: 48.5146057, lng: 20.109821 },
    { lat: 48.5143992, lng: 20.1098111 },
    { lat: 48.5142258, lng: 20.1099505 },
    { lat: 48.5140707, lng: 20.1099034 },
    { lat: 48.5139892, lng: 20.109949 },
    { lat: 48.51382, lng: 20.1098045 },
    { lat: 48.5135549, lng: 20.1091073 },
    { lat: 48.5134414, lng: 20.1089487 },
    { lat: 48.5132924, lng: 20.1089686 },
    { lat: 48.5132213, lng: 20.1087724 },
    { lat: 48.5127935, lng: 20.1083386 },
    { lat: 48.5127374, lng: 20.1083459 },
    { lat: 48.5126572, lng: 20.1085317 },
    { lat: 48.5126056, lng: 20.108539 },
    { lat: 48.5125867, lng: 20.1084868 },
    { lat: 48.5126464, lng: 20.108435 },
    { lat: 48.512663, lng: 20.1083148 },
    { lat: 48.5125896, lng: 20.1081209 },
    { lat: 48.5124021, lng: 20.1079977 },
    { lat: 48.5123093, lng: 20.1080699 },
    { lat: 48.5122082, lng: 20.1080228 },
    { lat: 48.5121496, lng: 20.1077459 },
    { lat: 48.5120688, lng: 20.107618 },
    { lat: 48.5120699, lng: 20.1073682 },
    { lat: 48.5120106, lng: 20.1072394 },
    { lat: 48.5120028, lng: 20.107135 },
    { lat: 48.5120976, lng: 20.107031 },
    { lat: 48.5121086, lng: 20.1069124 },
    { lat: 48.5118261, lng: 20.1065351 },
    { lat: 48.5118175, lng: 20.1062673 },
    { lat: 48.5116895, lng: 20.1060878 },
    { lat: 48.5116742, lng: 20.1059336 },
    { lat: 48.5115867, lng: 20.1058323 },
    { lat: 48.511329, lng: 20.1057284 },
    { lat: 48.5110359, lng: 20.1053919 },
    { lat: 48.5109232, lng: 20.1055029 },
    { lat: 48.5107953, lng: 20.1053482 },
    { lat: 48.510751, lng: 20.1051779 },
    { lat: 48.5106447, lng: 20.1050833 },
    { lat: 48.5105374, lng: 20.1051324 },
    { lat: 48.5102223, lng: 20.1048321 },
    { lat: 48.5099932, lng: 20.1049624 },
    { lat: 48.5098593, lng: 20.1046385 },
    { lat: 48.5097755, lng: 20.1047063 },
    { lat: 48.5097542, lng: 20.1048713 },
    { lat: 48.5093964, lng: 20.1047746 },
    { lat: 48.5093677, lng: 20.1045403 },
    { lat: 48.5090958, lng: 20.1044498 },
    { lat: 48.5090142, lng: 20.1046297 },
    { lat: 48.5086778, lng: 20.1046922 },
    { lat: 48.5085664, lng: 20.1046102 },
    { lat: 48.5083848, lng: 20.1046083 },
    { lat: 48.5083583, lng: 20.104538 },
    { lat: 48.5082062, lng: 20.1045059 },
    { lat: 48.5078071, lng: 20.1041171 },
    { lat: 48.5077249, lng: 20.104252 },
    { lat: 48.5073439, lng: 20.1041094 },
    { lat: 48.5069692, lng: 20.1037887 },
    { lat: 48.5065954, lng: 20.1039564 },
    { lat: 48.5065363, lng: 20.1038049 },
    { lat: 48.5063664, lng: 20.1037841 },
    { lat: 48.506254, lng: 20.1036276 },
    { lat: 48.5060239, lng: 20.1035073 },
    { lat: 48.5058943, lng: 20.1035655 },
    { lat: 48.5057565, lng: 20.1033062 },
    { lat: 48.5055701, lng: 20.1032916 },
    { lat: 48.5054997, lng: 20.1032214 },
    { lat: 48.5054792, lng: 20.1030273 },
    { lat: 48.5053583, lng: 20.102932 },
    { lat: 48.5052545, lng: 20.1030663 },
    { lat: 48.505164, lng: 20.1030303 },
    { lat: 48.5051942, lng: 20.1027757 },
    { lat: 48.505129, lng: 20.1025619 },
    { lat: 48.5049573, lng: 20.1024972 },
    { lat: 48.504405, lng: 20.1024857 },
    { lat: 48.5042803, lng: 20.102416 },
    { lat: 48.5042221, lng: 20.1021177 },
    { lat: 48.5039323, lng: 20.1019188 },
    { lat: 48.5038508, lng: 20.101774 },
    { lat: 48.5036328, lng: 20.1016998 },
    { lat: 48.5033616, lng: 20.1017354 },
    { lat: 48.5029364, lng: 20.101451 },
    { lat: 48.5026102, lng: 20.1014345 },
    { lat: 48.5023472, lng: 20.1011745 },
    { lat: 48.5022556, lng: 20.1011631 },
    { lat: 48.5021208, lng: 20.1013383 },
    { lat: 48.5014208, lng: 20.1011339 },
    { lat: 48.5011495, lng: 20.1011893 },
    { lat: 48.5008888, lng: 20.1010932 },
    { lat: 48.5013082, lng: 20.0983393 },
    { lat: 48.5013135, lng: 20.09825 },
    { lat: 48.5009969, lng: 20.0980582 },
    { lat: 48.4998757, lng: 20.0982919 },
    { lat: 48.4993082, lng: 20.098187 },
    { lat: 48.4990319, lng: 20.0977401 },
    { lat: 48.4987502, lng: 20.0976011 },
    { lat: 48.4973947, lng: 20.0975601 },
    { lat: 48.4962252, lng: 20.0972524 },
    { lat: 48.4957491, lng: 20.0972339 },
    { lat: 48.4952983, lng: 20.0973388 },
    { lat: 48.494704, lng: 20.0972361 },
    { lat: 48.4945045, lng: 20.0967969 },
    { lat: 48.4943118, lng: 20.0968237 },
    { lat: 48.4939916, lng: 20.0959654 },
    { lat: 48.4935666, lng: 20.0958189 },
    { lat: 48.4923618, lng: 20.0959141 },
    { lat: 48.4913944, lng: 20.0958583 },
    { lat: 48.4894839, lng: 20.0956119 },
    { lat: 48.4884612, lng: 20.0973093 },
    { lat: 48.4876843, lng: 20.0983843 },
    { lat: 48.4875357, lng: 20.0982491 },
    { lat: 48.487232, lng: 20.0981754 },
    { lat: 48.4867064, lng: 20.0991706 },
    { lat: 48.4866063, lng: 20.0992044 },
    { lat: 48.4859436, lng: 20.1008639 },
    { lat: 48.4853254, lng: 20.1026487 },
    { lat: 48.4853275, lng: 20.1031939 },
    { lat: 48.4854985, lng: 20.1042009 },
    { lat: 48.4854825, lng: 20.1045234 },
    { lat: 48.4849613, lng: 20.1056831 },
    { lat: 48.484819, lng: 20.1067682 },
    { lat: 48.4847256, lng: 20.1079827 },
    { lat: 48.4844358, lng: 20.1090712 },
    { lat: 48.4844859, lng: 20.109631 },
    { lat: 48.4851146, lng: 20.1104862 },
    { lat: 48.4853808, lng: 20.1109611 },
    { lat: 48.4851634, lng: 20.111266 },
    { lat: 48.4864412, lng: 20.1130881 },
    { lat: 48.4867418, lng: 20.113144 },
    { lat: 48.4870666, lng: 20.1143069 },
    { lat: 48.4862712, lng: 20.1164705 },
    { lat: 48.4861569, lng: 20.1172537 },
    { lat: 48.486014, lng: 20.1177524 },
    { lat: 48.4857092, lng: 20.1184747 },
    { lat: 48.4855409, lng: 20.1182738 },
    { lat: 48.4850916, lng: 20.1195151 },
    { lat: 48.4848123, lng: 20.1205323 },
    { lat: 48.4840852, lng: 20.1221434 },
    { lat: 48.4838026, lng: 20.1222505 },
    { lat: 48.4833282, lng: 20.1230176 },
    { lat: 48.4826332, lng: 20.1253034 },
    { lat: 48.4821304, lng: 20.126087 },
    { lat: 48.4816911, lng: 20.1269964 },
    { lat: 48.4812257, lng: 20.1276781 },
  ],
  Dubovec: [
    { lat: 48.2777526, lng: 20.1578021 },
    { lat: 48.2804457, lng: 20.1628064 },
    { lat: 48.2869951, lng: 20.1719284 },
    { lat: 48.287228, lng: 20.1720628 },
    { lat: 48.2875519, lng: 20.1720806 },
    { lat: 48.287755, lng: 20.172392 },
    { lat: 48.2879264, lng: 20.1725101 },
    { lat: 48.2884265, lng: 20.1723177 },
    { lat: 48.2885365, lng: 20.1723632 },
    { lat: 48.2893231, lng: 20.1731469 },
    { lat: 48.2898584, lng: 20.1741793 },
    { lat: 48.2896222, lng: 20.1744367 },
    { lat: 48.2896362, lng: 20.17473 },
    { lat: 48.2897026, lng: 20.1748651 },
    { lat: 48.2900624, lng: 20.1751287 },
    { lat: 48.2900826, lng: 20.1752085 },
    { lat: 48.291421, lng: 20.1742988 },
    { lat: 48.2939202, lng: 20.1723013 },
    { lat: 48.293927, lng: 20.1721376 },
    { lat: 48.2941102, lng: 20.1717095 },
    { lat: 48.2944098, lng: 20.1696901 },
    { lat: 48.2948485, lng: 20.1689812 },
    { lat: 48.2956938, lng: 20.1678347 },
    { lat: 48.2988164, lng: 20.1646346 },
    { lat: 48.2992186, lng: 20.1643039 },
    { lat: 48.2993982, lng: 20.1646239 },
    { lat: 48.2995855, lng: 20.1641861 },
    { lat: 48.2998172, lng: 20.1638476 },
    { lat: 48.3008281, lng: 20.162723 },
    { lat: 48.3014249, lng: 20.1609251 },
    { lat: 48.3016085, lng: 20.160621 },
    { lat: 48.3025119, lng: 20.1607343 },
    { lat: 48.3027908, lng: 20.1607806 },
    { lat: 48.3073147, lng: 20.1486941 },
    { lat: 48.3090952, lng: 20.1417446 },
    { lat: 48.3065392, lng: 20.1142579 },
    { lat: 48.3065418, lng: 20.1142113 },
    { lat: 48.3063366, lng: 20.114318 },
    { lat: 48.3059039, lng: 20.1146959 },
    { lat: 48.305644, lng: 20.1153886 },
    { lat: 48.3056885, lng: 20.1157018 },
    { lat: 48.3055471, lng: 20.1158801 },
    { lat: 48.3057054, lng: 20.1163318 },
    { lat: 48.305315, lng: 20.1164237 },
    { lat: 48.3051462, lng: 20.1167043 },
    { lat: 48.303553, lng: 20.1183331 },
    { lat: 48.3028625, lng: 20.1197614 },
    { lat: 48.3020708, lng: 20.121597 },
    { lat: 48.3019086, lng: 20.1238202 },
    { lat: 48.3012568, lng: 20.1243748 },
    { lat: 48.2992933, lng: 20.1258223 },
    { lat: 48.2986581, lng: 20.1261264 },
    { lat: 48.29748, lng: 20.1265672 },
    { lat: 48.2962608, lng: 20.1269402 },
    { lat: 48.2953749, lng: 20.1270933 },
    { lat: 48.2943132, lng: 20.1271604 },
    { lat: 48.2929506, lng: 20.1271095 },
    { lat: 48.2900322, lng: 20.1268917 },
    { lat: 48.2882867, lng: 20.1270625 },
    { lat: 48.2869764, lng: 20.1274131 },
    { lat: 48.2866139, lng: 20.1275848 },
    { lat: 48.2861389, lng: 20.1278941 },
    { lat: 48.2852722, lng: 20.1285953 },
    { lat: 48.2835894, lng: 20.1294424 },
    { lat: 48.2831723, lng: 20.1300102 },
    { lat: 48.2834391, lng: 20.1302834 },
    { lat: 48.2838691, lng: 20.130525 },
    { lat: 48.2840779, lng: 20.1307812 },
    { lat: 48.2841585, lng: 20.1310139 },
    { lat: 48.2841634, lng: 20.1315482 },
    { lat: 48.2842899, lng: 20.1318594 },
    { lat: 48.284429, lng: 20.1319817 },
    { lat: 48.2847034, lng: 20.1320526 },
    { lat: 48.2849752, lng: 20.1322303 },
    { lat: 48.2851792, lng: 20.1322589 },
    { lat: 48.2853028, lng: 20.1320845 },
    { lat: 48.2853655, lng: 20.1318297 },
    { lat: 48.2853681, lng: 20.131508 },
    { lat: 48.2855536, lng: 20.1311677 },
    { lat: 48.2859118, lng: 20.1310629 },
    { lat: 48.2859819, lng: 20.1310961 },
    { lat: 48.2860852, lng: 20.1313596 },
    { lat: 48.2860527, lng: 20.1331793 },
    { lat: 48.2859452, lng: 20.1336617 },
    { lat: 48.2855951, lng: 20.1341307 },
    { lat: 48.2852264, lng: 20.1343962 },
    { lat: 48.2849757, lng: 20.1346598 },
    { lat: 48.284448, lng: 20.1347529 },
    { lat: 48.2842306, lng: 20.1350586 },
    { lat: 48.2841136, lng: 20.1354464 },
    { lat: 48.2841353, lng: 20.1358645 },
    { lat: 48.2780873, lng: 20.1433872 },
    { lat: 48.2787964, lng: 20.1511424 },
    { lat: 48.279634, lng: 20.1541702 },
    { lat: 48.2777526, lng: 20.1578021 },
  ],
  VyšnéValice: [
    { lat: 48.477601, lng: 20.209943 },
    { lat: 48.477611, lng: 20.209946 },
    { lat: 48.477648, lng: 20.20987 },
    { lat: 48.477755, lng: 20.209629 },
    { lat: 48.478021, lng: 20.20903 },
    { lat: 48.478135, lng: 20.208748 },
    { lat: 48.478299, lng: 20.208336 },
    { lat: 48.47839, lng: 20.208008 },
    { lat: 48.478629, lng: 20.207339 },
    { lat: 48.478907, lng: 20.206554 },
    { lat: 48.479155, lng: 20.20613 },
    { lat: 48.479336, lng: 20.205817 },
    { lat: 48.479384, lng: 20.205724 },
    { lat: 48.479439, lng: 20.205567 },
    { lat: 48.479502, lng: 20.205394 },
    { lat: 48.479621, lng: 20.20505 },
    { lat: 48.479698, lng: 20.204826 },
    { lat: 48.479676, lng: 20.203941 },
    { lat: 48.479622, lng: 20.20373 },
    { lat: 48.479575, lng: 20.20354 },
    { lat: 48.479588, lng: 20.203539 },
    { lat: 48.479553, lng: 20.203257 },
    { lat: 48.479581, lng: 20.203008 },
    { lat: 48.479638, lng: 20.202914 },
    { lat: 48.479649, lng: 20.202897 },
    { lat: 48.479719, lng: 20.202831 },
    { lat: 48.479762, lng: 20.202726 },
    { lat: 48.479842, lng: 20.202536 },
    { lat: 48.479927, lng: 20.202068 },
    { lat: 48.47995, lng: 20.201938 },
    { lat: 48.480015, lng: 20.201657 },
    { lat: 48.480049, lng: 20.201293 },
    { lat: 48.480129, lng: 20.200782 },
    { lat: 48.480134, lng: 20.200751 },
    { lat: 48.480168, lng: 20.20032 },
    { lat: 48.480241, lng: 20.20016 },
    { lat: 48.480385, lng: 20.199981 },
    { lat: 48.480522, lng: 20.199773 },
    { lat: 48.480614, lng: 20.199548 },
    { lat: 48.480714, lng: 20.199426 },
    { lat: 48.480757, lng: 20.199387 },
    { lat: 48.480782, lng: 20.199374 },
    { lat: 48.480859, lng: 20.199326 },
    { lat: 48.480917, lng: 20.199275 },
    { lat: 48.481051, lng: 20.199145 },
    { lat: 48.481188, lng: 20.199004 },
    { lat: 48.481226, lng: 20.198969 },
    { lat: 48.481319, lng: 20.198822 },
    { lat: 48.48133, lng: 20.198804 },
    { lat: 48.481419, lng: 20.19866 },
    { lat: 48.481446, lng: 20.198611 },
    { lat: 48.481518, lng: 20.198451 },
    { lat: 48.48153, lng: 20.198368 },
    { lat: 48.481535, lng: 20.198349 },
    { lat: 48.481563, lng: 20.198252 },
    { lat: 48.481703, lng: 20.197976 },
    { lat: 48.481909, lng: 20.197566 },
    { lat: 48.481928, lng: 20.197504 },
    { lat: 48.482044, lng: 20.197135 },
    { lat: 48.48221, lng: 20.196813 },
    { lat: 48.482245, lng: 20.1967 },
    { lat: 48.482289, lng: 20.196564 },
    { lat: 48.482472, lng: 20.196396 },
    { lat: 48.482473, lng: 20.196393 },
    { lat: 48.482552, lng: 20.196185 },
    { lat: 48.482568, lng: 20.1961 },
    { lat: 48.482624, lng: 20.195753 },
    { lat: 48.482691, lng: 20.195507 },
    { lat: 48.482821, lng: 20.195028 },
    { lat: 48.482835, lng: 20.194979 },
    { lat: 48.482911, lng: 20.194757 },
    { lat: 48.482938, lng: 20.19468 },
    { lat: 48.482946, lng: 20.194656 },
    { lat: 48.482962, lng: 20.194605 },
    { lat: 48.483039, lng: 20.194298 },
    { lat: 48.483139, lng: 20.193913 },
    { lat: 48.483227, lng: 20.193565 },
    { lat: 48.48325, lng: 20.193462 },
    { lat: 48.483278, lng: 20.193272 },
    { lat: 48.483299, lng: 20.193166 },
    { lat: 48.483393, lng: 20.192944 },
    { lat: 48.483439, lng: 20.192805 },
    { lat: 48.483462, lng: 20.192738 },
    { lat: 48.48354, lng: 20.192504 },
    { lat: 48.48367, lng: 20.192179 },
    { lat: 48.483742, lng: 20.192041 },
    { lat: 48.483813, lng: 20.191805 },
    { lat: 48.483839, lng: 20.19152 },
    { lat: 48.483871, lng: 20.191285 },
    { lat: 48.483871, lng: 20.191241 },
    { lat: 48.483875, lng: 20.190717 },
    { lat: 48.483908, lng: 20.190456 },
    { lat: 48.483911, lng: 20.190437 },
    { lat: 48.483929, lng: 20.190304 },
    { lat: 48.483949, lng: 20.190162 },
    { lat: 48.483961, lng: 20.19008 },
    { lat: 48.483978, lng: 20.189843 },
    { lat: 48.484036, lng: 20.18947 },
    { lat: 48.484047, lng: 20.189411 },
    { lat: 48.484111, lng: 20.189153 },
    { lat: 48.484184, lng: 20.188853 },
    { lat: 48.484204, lng: 20.188717 },
    { lat: 48.484212, lng: 20.18869 },
    { lat: 48.484327, lng: 20.188488 },
    { lat: 48.484378, lng: 20.188434 },
    { lat: 48.484419, lng: 20.188363 },
    { lat: 48.484538, lng: 20.188163 },
    { lat: 48.48478, lng: 20.187799 },
    { lat: 48.484833, lng: 20.187612 },
    { lat: 48.484979, lng: 20.187321 },
    { lat: 48.485025, lng: 20.187176 },
    { lat: 48.485037, lng: 20.186652 },
    { lat: 48.485078, lng: 20.186495 },
    { lat: 48.485167, lng: 20.18633 },
    { lat: 48.485261, lng: 20.186219 },
    { lat: 48.485434, lng: 20.18592 },
    { lat: 48.485509, lng: 20.185835 },
    { lat: 48.485755, lng: 20.185558 },
    { lat: 48.485823, lng: 20.185376 },
    { lat: 48.485913, lng: 20.185192 },
    { lat: 48.486012, lng: 20.185031 },
    { lat: 48.48616, lng: 20.184628 },
    { lat: 48.486402, lng: 20.184156 },
    { lat: 48.486502, lng: 20.183868 },
    { lat: 48.486584, lng: 20.183566 },
    { lat: 48.486806, lng: 20.183181 },
    { lat: 48.486904, lng: 20.182827 },
    { lat: 48.486975, lng: 20.182398 },
    { lat: 48.487067, lng: 20.182111 },
    { lat: 48.487247, lng: 20.181828 },
    { lat: 48.487367, lng: 20.181534 },
    { lat: 48.487504, lng: 20.18122 },
    { lat: 48.487667, lng: 20.18076 },
    { lat: 48.487682, lng: 20.18077 },
    { lat: 48.487731, lng: 20.180633 },
    { lat: 48.487853, lng: 20.180287 },
    { lat: 48.488001, lng: 20.18005 },
    { lat: 48.488145, lng: 20.179819 },
    { lat: 48.488338, lng: 20.179577 },
    { lat: 48.488434, lng: 20.179396 },
    { lat: 48.488508, lng: 20.179223 },
    { lat: 48.488762, lng: 20.178713 },
    { lat: 48.488918, lng: 20.17819 },
    { lat: 48.489078, lng: 20.177516 },
    { lat: 48.48947, lng: 20.176763 },
    { lat: 48.489653, lng: 20.176164 },
    { lat: 48.48978, lng: 20.175837 },
    { lat: 48.489965, lng: 20.175443 },
    { lat: 48.49028, lng: 20.174552 },
    { lat: 48.490774, lng: 20.173517 },
    { lat: 48.490773, lng: 20.17323 },
    { lat: 48.490789, lng: 20.172691 },
    { lat: 48.490805, lng: 20.172244 },
    { lat: 48.490799, lng: 20.171972 },
    { lat: 48.490784, lng: 20.171971 },
    { lat: 48.490784, lng: 20.171837 },
    { lat: 48.490787, lng: 20.171743 },
    { lat: 48.490797, lng: 20.171661 },
    { lat: 48.490819, lng: 20.171485 },
    { lat: 48.490818, lng: 20.17143 },
    { lat: 48.49082, lng: 20.17134 },
    { lat: 48.490825, lng: 20.171249 },
    { lat: 48.490835, lng: 20.171174 },
    { lat: 48.490853, lng: 20.17114 },
    { lat: 48.490822, lng: 20.171134 },
    { lat: 48.490901, lng: 20.17093 },
    { lat: 48.491173, lng: 20.170479 },
    { lat: 48.491192, lng: 20.170443 },
    { lat: 48.491585, lng: 20.169773 },
    { lat: 48.491703, lng: 20.1695 },
    { lat: 48.491934, lng: 20.169107 },
    { lat: 48.49232, lng: 20.16852 },
    { lat: 48.492483, lng: 20.167629 },
    { lat: 48.492538, lng: 20.167196 },
    { lat: 48.492609, lng: 20.166923 },
    { lat: 48.492647, lng: 20.166811 },
    { lat: 48.492619, lng: 20.166338 },
    { lat: 48.492527, lng: 20.165731 },
    { lat: 48.492453, lng: 20.165328 },
    { lat: 48.492431, lng: 20.164852 },
    { lat: 48.492642, lng: 20.163878 },
    { lat: 48.492683, lng: 20.163653 },
    { lat: 48.492727, lng: 20.163341 },
    { lat: 48.492756, lng: 20.163116 },
    { lat: 48.492791, lng: 20.162934 },
    { lat: 48.492904, lng: 20.162665 },
    { lat: 48.492973, lng: 20.162467 },
    { lat: 48.49301, lng: 20.162345 },
    { lat: 48.493087, lng: 20.162045 },
    { lat: 48.493123, lng: 20.161905 },
    { lat: 48.493164, lng: 20.161827 },
    { lat: 48.493359, lng: 20.161441 },
    { lat: 48.493431, lng: 20.1613 },
    { lat: 48.493493, lng: 20.161121 },
    { lat: 48.49359, lng: 20.160865 },
    { lat: 48.493662, lng: 20.160704 },
    { lat: 48.493807, lng: 20.160393 },
    { lat: 48.493865, lng: 20.160252 },
    { lat: 48.494048, lng: 20.159795 },
    { lat: 48.494267, lng: 20.159269 },
    { lat: 48.49444, lng: 20.158854 },
    { lat: 48.494647, lng: 20.158458 },
    { lat: 48.49473, lng: 20.158326 },
    { lat: 48.494961, lng: 20.157966 },
    { lat: 48.495153, lng: 20.157694 },
    { lat: 48.495229, lng: 20.157586 },
    { lat: 48.495273, lng: 20.157551 },
    { lat: 48.495362, lng: 20.157443 },
    { lat: 48.495463, lng: 20.157321 },
    { lat: 48.495416, lng: 20.157257 },
    { lat: 48.49544, lng: 20.157127 },
    { lat: 48.4951111, lng: 20.1560245 },
    { lat: 48.4948006, lng: 20.1555921 },
    { lat: 48.4947514, lng: 20.1552124 },
    { lat: 48.4945544, lng: 20.1562994 },
    { lat: 48.4940264, lng: 20.1570529 },
    { lat: 48.4933133, lng: 20.1576557 },
    { lat: 48.4925895, lng: 20.1585595 },
    { lat: 48.4920877, lng: 20.1589291 },
    { lat: 48.4916643, lng: 20.1595692 },
    { lat: 48.4911169, lng: 20.1597353 },
    { lat: 48.4905544, lng: 20.1597227 },
    { lat: 48.4902665, lng: 20.1606773 },
    { lat: 48.4899153, lng: 20.1609911 },
    { lat: 48.4895709, lng: 20.161718 },
    { lat: 48.4891953, lng: 20.1620334 },
    { lat: 48.4882105, lng: 20.1624434 },
    { lat: 48.4878128, lng: 20.1627472 },
    { lat: 48.4874403, lng: 20.1636112 },
    { lat: 48.4870504, lng: 20.1641057 },
    { lat: 48.486578, lng: 20.1645695 },
    { lat: 48.485775, lng: 20.1647653 },
    { lat: 48.4854044, lng: 20.1650436 },
    { lat: 48.4849423, lng: 20.1655021 },
    { lat: 48.4843011, lng: 20.1655521 },
    { lat: 48.4838766, lng: 20.1654196 },
    { lat: 48.483006, lng: 20.1649201 },
    { lat: 48.4824401, lng: 20.1644643 },
    { lat: 48.4819165, lng: 20.1639081 },
    { lat: 48.4814193, lng: 20.1636187 },
    { lat: 48.4809523, lng: 20.1635234 },
    { lat: 48.4800889, lng: 20.1635029 },
    { lat: 48.4796605, lng: 20.163631 },
    { lat: 48.4792738, lng: 20.1636239 },
    { lat: 48.4787862, lng: 20.1633218 },
    { lat: 48.4780767, lng: 20.1624896 },
    { lat: 48.4777507, lng: 20.162255 },
    { lat: 48.477189, lng: 20.1619019 },
    { lat: 48.4769179, lng: 20.1617973 },
    { lat: 48.4764417, lng: 20.1617547 },
    { lat: 48.4762404, lng: 20.1615382 },
    { lat: 48.4761854, lng: 20.1612369 },
    { lat: 48.4761886, lng: 20.160374 },
    { lat: 48.4761088, lng: 20.1600711 },
    { lat: 48.4758424, lng: 20.1596941 },
    { lat: 48.4754034, lng: 20.1585911 },
    { lat: 48.474882, lng: 20.1574584 },
    { lat: 48.4745443, lng: 20.1569644 },
    { lat: 48.4743287, lng: 20.1564816 },
    { lat: 48.4742003, lng: 20.1560107 },
    { lat: 48.4741413, lng: 20.1555287 },
    { lat: 48.4734523, lng: 20.155678 },
    { lat: 48.4728449, lng: 20.1560292 },
    { lat: 48.4721152, lng: 20.1562297 },
    { lat: 48.472116, lng: 20.1562722 },
    { lat: 48.4715392, lng: 20.1555536 },
    { lat: 48.4702523, lng: 20.1551207 },
    { lat: 48.4692881, lng: 20.1552569 },
    { lat: 48.4681913, lng: 20.1563735 },
    { lat: 48.4671972, lng: 20.1566853 },
    { lat: 48.4659108, lng: 20.1573749 },
    { lat: 48.4655652, lng: 20.15785 },
    { lat: 48.4647818, lng: 20.158614 },
    { lat: 48.4645054, lng: 20.1590443 },
    { lat: 48.463837, lng: 20.1598347 },
    { lat: 48.4635261, lng: 20.160112 },
    { lat: 48.4629091, lng: 20.1604256 },
    { lat: 48.4618786, lng: 20.1617304 },
    { lat: 48.4606399, lng: 20.161794 },
    { lat: 48.459638, lng: 20.1612238 },
    { lat: 48.4584004, lng: 20.1603659 },
    { lat: 48.4582127, lng: 20.1600758 },
    { lat: 48.4580774, lng: 20.157606 },
    { lat: 48.4575799, lng: 20.157722 },
    { lat: 48.4572828, lng: 20.1575605 },
    { lat: 48.4568266, lng: 20.1576436 },
    { lat: 48.4565201, lng: 20.1578368 },
    { lat: 48.4556709, lng: 20.1581149 },
    { lat: 48.4553989, lng: 20.1581828 },
    { lat: 48.4551653, lng: 20.1581439 },
    { lat: 48.4546839, lng: 20.1583535 },
    { lat: 48.4541999, lng: 20.158443 },
    { lat: 48.4529713, lng: 20.1600021 },
    { lat: 48.4524201, lng: 20.1605168 },
    { lat: 48.4516117, lng: 20.1611619 },
    { lat: 48.4508634, lng: 20.1614555 },
    { lat: 48.4502664, lng: 20.1614816 },
    { lat: 48.4495825, lng: 20.1613691 },
    { lat: 48.4488824, lng: 20.1613783 },
    { lat: 48.4485242, lng: 20.1614896 },
    { lat: 48.448243, lng: 20.1614714 },
    { lat: 48.4478353, lng: 20.1611942 },
    { lat: 48.4473806, lng: 20.1609549 },
    { lat: 48.4472031, lng: 20.1609946 },
    { lat: 48.4468227, lng: 20.1610547 },
    { lat: 48.4462882, lng: 20.1610389 },
    { lat: 48.4460931, lng: 20.1609759 },
    { lat: 48.4454695, lng: 20.1610179 },
    { lat: 48.445198, lng: 20.1613756 },
    { lat: 48.4451881, lng: 20.1615489 },
    { lat: 48.4450875, lng: 20.1618455 },
    { lat: 48.4450758, lng: 20.1621676 },
    { lat: 48.4449733, lng: 20.1624739 },
    { lat: 48.4447683, lng: 20.1627492 },
    { lat: 48.4443048, lng: 20.1636219 },
    { lat: 48.444176, lng: 20.1642744 },
    { lat: 48.444059, lng: 20.1645015 },
    { lat: 48.4439712, lng: 20.1652521 },
    { lat: 48.4437887, lng: 20.1657516 },
    { lat: 48.4435857, lng: 20.166048 },
    { lat: 48.4433678, lng: 20.1661747 },
    { lat: 48.4433502, lng: 20.1662994 },
    { lat: 48.4431921, lng: 20.1665672 },
    { lat: 48.4430766, lng: 20.1671876 },
    { lat: 48.4429179, lng: 20.1675793 },
    { lat: 48.4429242, lng: 20.1677365 },
    { lat: 48.4428644, lng: 20.1678453 },
    { lat: 48.4427646, lng: 20.1683287 },
    { lat: 48.4427656, lng: 20.1688242 },
    { lat: 48.4426981, lng: 20.1690737 },
    { lat: 48.4423392, lng: 20.1698906 },
    { lat: 48.4423153, lng: 20.1700524 },
    { lat: 48.4417823, lng: 20.1707968 },
    { lat: 48.4416312, lng: 20.1707883 },
    { lat: 48.441505, lng: 20.1708999 },
    { lat: 48.4412894, lng: 20.1715263 },
    { lat: 48.4412254, lng: 20.1715907 },
    { lat: 48.4412091, lng: 20.1717983 },
    { lat: 48.4409802, lng: 20.1722598 },
    { lat: 48.440973, lng: 20.1723906 },
    { lat: 48.4408356, lng: 20.172449 },
    { lat: 48.440654, lng: 20.1726912 },
    { lat: 48.4406148, lng: 20.1729452 },
    { lat: 48.4403684, lng: 20.173424 },
    { lat: 48.4403782, lng: 20.1735263 },
    { lat: 48.4401601, lng: 20.1737587 },
    { lat: 48.4399632, lng: 20.1741815 },
    { lat: 48.439648, lng: 20.1754387 },
    { lat: 48.4395349, lng: 20.1755363 },
    { lat: 48.4394514, lng: 20.1757288 },
    { lat: 48.4393443, lng: 20.1761685 },
    { lat: 48.4393407, lng: 20.177251 },
    { lat: 48.4392948, lng: 20.1774227 },
    { lat: 48.4393165, lng: 20.1779059 },
    { lat: 48.4389744, lng: 20.1788602 },
    { lat: 48.4389542, lng: 20.1806125 },
    { lat: 48.4387362, lng: 20.1826043 },
    { lat: 48.4385632, lng: 20.1833085 },
    { lat: 48.4378298, lng: 20.1847851 },
    { lat: 48.4377688, lng: 20.1849978 },
    { lat: 48.436796, lng: 20.186289 },
    { lat: 48.436628, lng: 20.1865945 },
    { lat: 48.4365351, lng: 20.1869424 },
    { lat: 48.4366096, lng: 20.1873066 },
    { lat: 48.4370314, lng: 20.1885759 },
    { lat: 48.4368844, lng: 20.1892022 },
    { lat: 48.436679, lng: 20.1910366 },
    { lat: 48.4374802, lng: 20.1919337 },
    { lat: 48.4391124, lng: 20.1948166 },
    { lat: 48.439781, lng: 20.1983801 },
    { lat: 48.4432211, lng: 20.1958491 },
    { lat: 48.4460286, lng: 20.1964547 },
    { lat: 48.4462742, lng: 20.1968754 },
    { lat: 48.4474618, lng: 20.1962716 },
    { lat: 48.4475481, lng: 20.1958714 },
    { lat: 48.4484445, lng: 20.1945555 },
    { lat: 48.4503933, lng: 20.1929981 },
    { lat: 48.4524349, lng: 20.192432 },
    { lat: 48.4525609, lng: 20.1931812 },
    { lat: 48.4527281, lng: 20.1931365 },
    { lat: 48.4527769, lng: 20.1933694 },
    { lat: 48.4533154, lng: 20.1929818 },
    { lat: 48.4536032, lng: 20.1930237 },
    { lat: 48.4543822, lng: 20.1930467 },
    { lat: 48.4542791, lng: 20.1932516 },
    { lat: 48.4546299, lng: 20.1938204 },
    { lat: 48.4555545, lng: 20.1933436 },
    { lat: 48.4562845, lng: 20.1927069 },
    { lat: 48.4570881, lng: 20.1916873 },
    { lat: 48.4573565, lng: 20.1914197 },
    { lat: 48.4577764, lng: 20.1912746 },
    { lat: 48.4579861, lng: 20.1909439 },
    { lat: 48.4581644, lng: 20.1902556 },
    { lat: 48.4581953, lng: 20.1902673 },
    { lat: 48.458925, lng: 20.1913789 },
    { lat: 48.4589211, lng: 20.1915034 },
    { lat: 48.4590767, lng: 20.1918146 },
    { lat: 48.4594532, lng: 20.1923008 },
    { lat: 48.4594544, lng: 20.1925828 },
    { lat: 48.4593219, lng: 20.1931783 },
    { lat: 48.4591622, lng: 20.1935722 },
    { lat: 48.4591498, lng: 20.1937451 },
    { lat: 48.459218, lng: 20.1942289 },
    { lat: 48.4595314, lng: 20.1946265 },
    { lat: 48.4599382, lng: 20.1949421 },
    { lat: 48.4620565, lng: 20.1958023 },
    { lat: 48.4625154, lng: 20.1960625 },
    { lat: 48.4630451, lng: 20.1965759 },
    { lat: 48.4631262, lng: 20.1967726 },
    { lat: 48.4632905, lng: 20.1967662 },
    { lat: 48.4638237, lng: 20.1970022 },
    { lat: 48.4640458, lng: 20.1969546 },
    { lat: 48.4643318, lng: 20.1973179 },
    { lat: 48.4644505, lng: 20.1973805 },
    { lat: 48.4648649, lng: 20.1979365 },
    { lat: 48.4654952, lng: 20.1984043 },
    { lat: 48.4667761, lng: 20.1998414 },
    { lat: 48.4677672, lng: 20.2004808 },
    { lat: 48.4681423, lng: 20.2006479 },
    { lat: 48.4687498, lng: 20.20111 },
    { lat: 48.4693452, lng: 20.2016613 },
    { lat: 48.4696785, lng: 20.202049 },
    { lat: 48.4700415, lng: 20.2022552 },
    { lat: 48.4705319, lng: 20.2029335 },
    { lat: 48.4709477, lng: 20.2037252 },
    { lat: 48.4711841, lng: 20.2046137 },
    { lat: 48.4712434, lng: 20.2047293 },
    { lat: 48.4713354, lng: 20.2049087 },
    { lat: 48.4722674, lng: 20.2057588 },
    { lat: 48.4727108, lng: 20.2068426 },
    { lat: 48.473233, lng: 20.2078159 },
    { lat: 48.4735552, lng: 20.2082097 },
    { lat: 48.4740953, lng: 20.2083948 },
    { lat: 48.4745921, lng: 20.2087281 },
    { lat: 48.4755563, lng: 20.2088484 },
    { lat: 48.476059, lng: 20.209467 },
    { lat: 48.476224, lng: 20.209524 },
    { lat: 48.476604, lng: 20.209676 },
    { lat: 48.476687, lng: 20.209682 },
    { lat: 48.476781, lng: 20.209676 },
    { lat: 48.477026, lng: 20.209821 },
    { lat: 48.477116, lng: 20.209768 },
    { lat: 48.477165, lng: 20.209747 },
    { lat: 48.477458, lng: 20.209877 },
    { lat: 48.477601, lng: 20.209943 },
  ],
};

export default UnitsRimavskáSobota;
