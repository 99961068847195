const UnitsSabinov = {
  Oľšov: [
    { lat: 49.2238673, lng: 20.8323485 },
    { lat: 49.2235517, lng: 20.8322048 },
    { lat: 49.2230498, lng: 20.8320371 },
    { lat: 49.2227551, lng: 20.8320491 },
    { lat: 49.2226874, lng: 20.8316836 },
    { lat: 49.2225344, lng: 20.831685 },
    { lat: 49.2223915, lng: 20.8319463 },
    { lat: 49.2222955, lng: 20.8319061 },
    { lat: 49.2222648, lng: 20.8316273 },
    { lat: 49.2221446, lng: 20.8316736 },
    { lat: 49.2220827, lng: 20.8319666 },
    { lat: 49.2218588, lng: 20.8320655 },
    { lat: 49.2215572, lng: 20.8317096 },
    { lat: 49.2214078, lng: 20.8318804 },
    { lat: 49.2212733, lng: 20.8319314 },
    { lat: 49.2212033, lng: 20.8317883 },
    { lat: 49.2209853, lng: 20.8316368 },
    { lat: 49.2208253, lng: 20.8317025 },
    { lat: 49.2204882, lng: 20.8315209 },
    { lat: 49.2203333, lng: 20.8311015 },
    { lat: 49.2196521, lng: 20.8309002 },
    { lat: 49.2194648, lng: 20.8309195 },
    { lat: 49.2193405, lng: 20.8312996 },
    { lat: 49.2189836, lng: 20.8314904 },
    { lat: 49.218851, lng: 20.8313998 },
    { lat: 49.2187446, lng: 20.8311851 },
    { lat: 49.2185975, lng: 20.8311936 },
    { lat: 49.2183621, lng: 20.8310906 },
    { lat: 49.2181008, lng: 20.8315119 },
    { lat: 49.217943, lng: 20.8314074 },
    { lat: 49.2179654, lng: 20.8312073 },
    { lat: 49.2179336, lng: 20.8311324 },
    { lat: 49.2177441, lng: 20.8311234 },
    { lat: 49.2176887, lng: 20.8313078 },
    { lat: 49.2175971, lng: 20.8312986 },
    { lat: 49.217498, lng: 20.8310357 },
    { lat: 49.2172806, lng: 20.8311895 },
    { lat: 49.2170748, lng: 20.8310342 },
    { lat: 49.2169214, lng: 20.831059 },
    { lat: 49.2166773, lng: 20.8314861 },
    { lat: 49.2165096, lng: 20.8314235 },
    { lat: 49.2165111, lng: 20.8312678 },
    { lat: 49.2162294, lng: 20.8314581 },
    { lat: 49.2160252, lng: 20.8313764 },
    { lat: 49.2158993, lng: 20.8315434 },
    { lat: 49.2155131, lng: 20.8313467 },
    { lat: 49.2153991, lng: 20.8315995 },
    { lat: 49.2152594, lng: 20.8316953 },
    { lat: 49.21516, lng: 20.8316496 },
    { lat: 49.2150536, lng: 20.8317938 },
    { lat: 49.2149772, lng: 20.831802 },
    { lat: 49.2147692, lng: 20.8321034 },
    { lat: 49.2147132, lng: 20.8324038 },
    { lat: 49.2146019, lng: 20.832345 },
    { lat: 49.2146488, lng: 20.8331203 },
    { lat: 49.2143885, lng: 20.8336764 },
    { lat: 49.2143702, lng: 20.8338298 },
    { lat: 49.2142393, lng: 20.8341466 },
    { lat: 49.2142752, lng: 20.8343079 },
    { lat: 49.2141363, lng: 20.8345894 },
    { lat: 49.2140678, lng: 20.8348641 },
    { lat: 49.2140149, lng: 20.8355837 },
    { lat: 49.2140842, lng: 20.8356405 },
    { lat: 49.2140867, lng: 20.8357108 },
    { lat: 49.2139005, lng: 20.8360893 },
    { lat: 49.2139022, lng: 20.8362421 },
    { lat: 49.213812, lng: 20.8365269 },
    { lat: 49.2138796, lng: 20.8366994 },
    { lat: 49.2138273, lng: 20.8369761 },
    { lat: 49.2139015, lng: 20.8371728 },
    { lat: 49.2138312, lng: 20.8374549 },
    { lat: 49.2138123, lng: 20.8378557 },
    { lat: 49.2136957, lng: 20.8380659 },
    { lat: 49.2136334, lng: 20.8383207 },
    { lat: 49.2134542, lng: 20.8386046 },
    { lat: 49.2134314, lng: 20.8393246 },
    { lat: 49.2133227, lng: 20.8396998 },
    { lat: 49.213356, lng: 20.8405082 },
    { lat: 49.2135289, lng: 20.8409315 },
    { lat: 49.2135408, lng: 20.8412085 },
    { lat: 49.2134731, lng: 20.841436 },
    { lat: 49.2135407, lng: 20.8417316 },
    { lat: 49.2135188, lng: 20.8422147 },
    { lat: 49.2134808, lng: 20.8423545 },
    { lat: 49.2133692, lng: 20.8423796 },
    { lat: 49.2133469, lng: 20.8425495 },
    { lat: 49.2135156, lng: 20.8434087 },
    { lat: 49.2139154, lng: 20.8440736 },
    { lat: 49.2142594, lng: 20.8452166 },
    { lat: 49.2142032, lng: 20.8454835 },
    { lat: 49.2143111, lng: 20.845772 },
    { lat: 49.2142605, lng: 20.8460104 },
    { lat: 49.2143029, lng: 20.8466223 },
    { lat: 49.2142515, lng: 20.8467358 },
    { lat: 49.214239, lng: 20.8469917 },
    { lat: 49.2138527, lng: 20.8481056 },
    { lat: 49.2136665, lng: 20.8484279 },
    { lat: 49.2134953, lng: 20.8485876 },
    { lat: 49.2128661, lng: 20.8494472 },
    { lat: 49.2124516, lng: 20.8501616 },
    { lat: 49.2122903, lng: 20.8502475 },
    { lat: 49.2121597, lng: 20.8504494 },
    { lat: 49.2120439, lng: 20.8508164 },
    { lat: 49.2118289, lng: 20.8510131 },
    { lat: 49.211675, lng: 20.851286 },
    { lat: 49.2115553, lng: 20.8513383 },
    { lat: 49.2108948, lng: 20.8512104 },
    { lat: 49.2107773, lng: 20.8510635 },
    { lat: 49.2101677, lng: 20.8508768 },
    { lat: 49.209852, lng: 20.8505703 },
    { lat: 49.2096889, lng: 20.850801 },
    { lat: 49.2093774, lng: 20.8505478 },
    { lat: 49.2089746, lng: 20.8504844 },
    { lat: 49.2081655, lng: 20.8504871 },
    { lat: 49.2077151, lng: 20.850643 },
    { lat: 49.207599, lng: 20.8507732 },
    { lat: 49.2075335, lng: 20.8509606 },
    { lat: 49.2074861, lng: 20.8514891 },
    { lat: 49.2073362, lng: 20.8521428 },
    { lat: 49.2068152, lng: 20.853471 },
    { lat: 49.2064692, lng: 20.8540884 },
    { lat: 49.2059392, lng: 20.8547798 },
    { lat: 49.2055551, lng: 20.8548373 },
    { lat: 49.2049096, lng: 20.8550886 },
    { lat: 49.2042005, lng: 20.8554867 },
    { lat: 49.2037426, lng: 20.8554903 },
    { lat: 49.2023876, lng: 20.8557233 },
    { lat: 49.2022177, lng: 20.8556252 },
    { lat: 49.2020246, lng: 20.8556383 },
    { lat: 49.2016571, lng: 20.8558186 },
    { lat: 49.2003517, lng: 20.855957 },
    { lat: 49.199579, lng: 20.8561355 },
    { lat: 49.1991524, lng: 20.8563745 },
    { lat: 49.1987891, lng: 20.8567099 },
    { lat: 49.1981042, lng: 20.8569524 },
    { lat: 49.1973095, lng: 20.8575174 },
    { lat: 49.1967561, lng: 20.8580906 },
    { lat: 49.1965985, lng: 20.8583172 },
    { lat: 49.195593, lng: 20.8605572 },
    { lat: 49.1950359, lng: 20.8613898 },
    { lat: 49.193806, lng: 20.8623896 },
    { lat: 49.1933663, lng: 20.8626295 },
    { lat: 49.1928549, lng: 20.8627171 },
    { lat: 49.1916853, lng: 20.8628424 },
    { lat: 49.1906978, lng: 20.8628003 },
    { lat: 49.1898254, lng: 20.8628624 },
    { lat: 49.1895044, lng: 20.8629893 },
    { lat: 49.1888795, lng: 20.8630819 },
    { lat: 49.1877818, lng: 20.8630654 },
    { lat: 49.1871369, lng: 20.8632373 },
    { lat: 49.1863755, lng: 20.8636498 },
    { lat: 49.1857462, lng: 20.8640741 },
    { lat: 49.1844013, lng: 20.8651735 },
    { lat: 49.1839273, lng: 20.8654973 },
    { lat: 49.1827382, lng: 20.8666433 },
    { lat: 49.1823184, lng: 20.8671363 },
    { lat: 49.1819383, lng: 20.8674448 },
    { lat: 49.1809867, lng: 20.8684622 },
    { lat: 49.1794083, lng: 20.8699406 },
    { lat: 49.1787093, lng: 20.8708304 },
    { lat: 49.1776445, lng: 20.872357 },
    { lat: 49.1773494, lng: 20.8728821 },
    { lat: 49.1769158, lng: 20.8734365 },
    { lat: 49.1760228, lng: 20.8744854 },
    { lat: 49.1751464, lng: 20.8753621 },
    { lat: 49.1741082, lng: 20.8760446 },
    { lat: 49.1739292, lng: 20.8761091 },
    { lat: 49.1726789, lng: 20.8763148 },
    { lat: 49.1721401, lng: 20.8765195 },
    { lat: 49.1717686, lng: 20.8765552 },
    { lat: 49.1710113, lng: 20.8767577 },
    { lat: 49.1706404, lng: 20.87671 },
    { lat: 49.1706414, lng: 20.8767412 },
    { lat: 49.1711284, lng: 20.8779222 },
    { lat: 49.1716907, lng: 20.8786771 },
    { lat: 49.1710166, lng: 20.8802428 },
    { lat: 49.1699466, lng: 20.8818114 },
    { lat: 49.171375, lng: 20.8874093 },
    { lat: 49.170827, lng: 20.8883152 },
    { lat: 49.1716736, lng: 20.891217 },
    { lat: 49.1729728, lng: 20.8913617 },
    { lat: 49.1742729, lng: 20.8915064 },
    { lat: 49.1764523, lng: 20.8897838 },
    { lat: 49.1814631, lng: 20.8865157 },
    { lat: 49.1819661, lng: 20.8862833 },
    { lat: 49.1841488, lng: 20.8856951 },
    { lat: 49.1867988, lng: 20.8856749 },
    { lat: 49.1880655, lng: 20.8859251 },
    { lat: 49.1899767, lng: 20.8861594 },
    { lat: 49.1904075, lng: 20.8860525 },
    { lat: 49.1924054, lng: 20.8849944 },
    { lat: 49.1929041, lng: 20.8846007 },
    { lat: 49.1934432, lng: 20.8840322 },
    { lat: 49.1938301, lng: 20.8833608 },
    { lat: 49.1941306, lng: 20.8831943 },
    { lat: 49.1946557, lng: 20.8832109 },
    { lat: 49.1958519, lng: 20.8827342 },
    { lat: 49.1964895, lng: 20.8822839 },
    { lat: 49.1965274, lng: 20.8824083 },
    { lat: 49.1974282, lng: 20.8831846 },
    { lat: 49.1975632, lng: 20.88312 },
    { lat: 49.1976427, lng: 20.8832371 },
    { lat: 49.1976936, lng: 20.8834174 },
    { lat: 49.1977059, lng: 20.8839433 },
    { lat: 49.1977763, lng: 20.8843664 },
    { lat: 49.1980149, lng: 20.885396 },
    { lat: 49.1982387, lng: 20.8855223 },
    { lat: 49.1985443, lng: 20.885444 },
    { lat: 49.1997563, lng: 20.885869 },
    { lat: 49.2007989, lng: 20.8857133 },
    { lat: 49.2007892, lng: 20.8837288 },
    { lat: 49.2010417, lng: 20.8836783 },
    { lat: 49.2012825, lng: 20.8834045 },
    { lat: 49.20159, lng: 20.8833127 },
    { lat: 49.2018484, lng: 20.8829983 },
    { lat: 49.2021547, lng: 20.8828684 },
    { lat: 49.2025425, lng: 20.882592 },
    { lat: 49.202891, lng: 20.8825765 },
    { lat: 49.2032401, lng: 20.8827869 },
    { lat: 49.2035132, lng: 20.8830717 },
    { lat: 49.2036426, lng: 20.8831043 },
    { lat: 49.2038887, lng: 20.8829273 },
    { lat: 49.2041449, lng: 20.8824133 },
    { lat: 49.2043749, lng: 20.881598 },
    { lat: 49.2045882, lng: 20.8811834 },
    { lat: 49.205007, lng: 20.8808546 },
    { lat: 49.2056811, lng: 20.8808814 },
    { lat: 49.2062187, lng: 20.8808013 },
    { lat: 49.2063711, lng: 20.8807187 },
    { lat: 49.2066311, lng: 20.8800878 },
    { lat: 49.2070832, lng: 20.8795435 },
    { lat: 49.2072801, lng: 20.879145 },
    { lat: 49.2073802, lng: 20.8787721 },
    { lat: 49.2073913, lng: 20.878402 },
    { lat: 49.2074787, lng: 20.8778915 },
    { lat: 49.2076799, lng: 20.8777525 },
    { lat: 49.2085908, lng: 20.8773629 },
    { lat: 49.2088884, lng: 20.8768135 },
    { lat: 49.2094295, lng: 20.8753734 },
    { lat: 49.2101676, lng: 20.8745063 },
    { lat: 49.210766, lng: 20.87392 },
    { lat: 49.210781, lng: 20.873879 },
    { lat: 49.210983, lng: 20.872033 },
    { lat: 49.211445, lng: 20.868171 },
    { lat: 49.212427, lng: 20.86851 },
    { lat: 49.212418, lng: 20.869952 },
    { lat: 49.213374, lng: 20.86917 },
    { lat: 49.213632, lng: 20.86879 },
    { lat: 49.214694, lng: 20.869191 },
    { lat: 49.214696, lng: 20.869122 },
    { lat: 49.214757, lng: 20.868525 },
    { lat: 49.215497, lng: 20.868145 },
    { lat: 49.216206, lng: 20.868087 },
    { lat: 49.21687, lng: 20.868352 },
    { lat: 49.217182, lng: 20.867533 },
    { lat: 49.217996, lng: 20.865553 },
    { lat: 49.218086, lng: 20.865172 },
    { lat: 49.218279, lng: 20.864974 },
    { lat: 49.218353, lng: 20.864749 },
    { lat: 49.2186, lng: 20.864662 },
    { lat: 49.219077, lng: 20.864089 },
    { lat: 49.219116, lng: 20.864043 },
    { lat: 49.219121, lng: 20.864038 },
    { lat: 49.21922, lng: 20.863796 },
    { lat: 49.219332, lng: 20.863408 },
    { lat: 49.219876, lng: 20.862546 },
    { lat: 49.220085, lng: 20.862354 },
    { lat: 49.220134, lng: 20.862308 },
    { lat: 49.220223, lng: 20.862227 },
    { lat: 49.22012, lng: 20.862204 },
    { lat: 49.220067, lng: 20.862014 },
    { lat: 49.220115, lng: 20.861969 },
    { lat: 49.220166, lng: 20.861942 },
    { lat: 49.22096, lng: 20.860502 },
    { lat: 49.221813, lng: 20.859935 },
    { lat: 49.221912, lng: 20.860216 },
    { lat: 49.222127, lng: 20.86011 },
    { lat: 49.222086, lng: 20.859668 },
    { lat: 49.221927, lng: 20.8589 },
    { lat: 49.221652, lng: 20.858079 },
    { lat: 49.221932, lng: 20.857643 },
    { lat: 49.221974, lng: 20.857236 },
    { lat: 49.222068, lng: 20.856343 },
    { lat: 49.221326, lng: 20.855615 },
    { lat: 49.220999, lng: 20.85562 },
    { lat: 49.220747, lng: 20.855593 },
    { lat: 49.220695, lng: 20.855684 },
    { lat: 49.2209, lng: 20.85617 },
    { lat: 49.220775, lng: 20.856262 },
    { lat: 49.221047, lng: 20.856977 },
    { lat: 49.220952, lng: 20.857406 },
    { lat: 49.220587, lng: 20.85799 },
    { lat: 49.220819, lng: 20.858309 },
    { lat: 49.220443, lng: 20.858652 },
    { lat: 49.219737, lng: 20.857781 },
    { lat: 49.219595, lng: 20.857609 },
    { lat: 49.219522, lng: 20.857698 },
    { lat: 49.219234, lng: 20.857263 },
    { lat: 49.218887, lng: 20.857423 },
    { lat: 49.218242, lng: 20.856572 },
    { lat: 49.218681, lng: 20.854771 },
    { lat: 49.218533, lng: 20.854235 },
    { lat: 49.218796, lng: 20.853692 },
    { lat: 49.218745, lng: 20.853483 },
    { lat: 49.218309, lng: 20.852898 },
    { lat: 49.218253, lng: 20.852146 },
    { lat: 49.218358, lng: 20.850908 },
    { lat: 49.218041, lng: 20.850135 },
    { lat: 49.218023, lng: 20.850068 },
    { lat: 49.218177, lng: 20.849987 },
    { lat: 49.21826, lng: 20.849847 },
    { lat: 49.218453, lng: 20.849518 },
    { lat: 49.218714, lng: 20.849387 },
    { lat: 49.218784, lng: 20.849067 },
    { lat: 49.218777, lng: 20.848942 },
    { lat: 49.218679, lng: 20.84874 },
    { lat: 49.218435, lng: 20.848537 },
    { lat: 49.217667, lng: 20.848675 },
    { lat: 49.216777, lng: 20.848916 },
    { lat: 49.216431, lng: 20.849428 },
    { lat: 49.216264, lng: 20.850174 },
    { lat: 49.215888, lng: 20.849864 },
    { lat: 49.216082, lng: 20.849613 },
    { lat: 49.216107, lng: 20.848996 },
    { lat: 49.215959, lng: 20.84895 },
    { lat: 49.216269, lng: 20.848743 },
    { lat: 49.216882, lng: 20.846973 },
    { lat: 49.216891, lng: 20.846088 },
    { lat: 49.216672, lng: 20.846032 },
    { lat: 49.216586, lng: 20.84601 },
    { lat: 49.216812, lng: 20.845622 },
    { lat: 49.217017, lng: 20.845616 },
    { lat: 49.217132, lng: 20.845997 },
    { lat: 49.217482, lng: 20.846163 },
    { lat: 49.218033, lng: 20.846433 },
    { lat: 49.218468, lng: 20.845944 },
    { lat: 49.218021, lng: 20.845326 },
    { lat: 49.217353, lng: 20.844917 },
    { lat: 49.217306, lng: 20.844583 },
    { lat: 49.216998, lng: 20.844199 },
    { lat: 49.216758, lng: 20.843912 },
    { lat: 49.216175, lng: 20.844226 },
    { lat: 49.215776, lng: 20.843592 },
    { lat: 49.215714, lng: 20.84322 },
    { lat: 49.215677, lng: 20.842668 },
    { lat: 49.216372, lng: 20.84259 },
    { lat: 49.216744, lng: 20.843361 },
    { lat: 49.217076, lng: 20.843481 },
    { lat: 49.217313, lng: 20.843956 },
    { lat: 49.217438, lng: 20.843866 },
    { lat: 49.217836, lng: 20.844605 },
    { lat: 49.218272, lng: 20.844243 },
    { lat: 49.21884, lng: 20.844309 },
    { lat: 49.219064, lng: 20.844155 },
    { lat: 49.219137, lng: 20.843987 },
    { lat: 49.219238, lng: 20.844059 },
    { lat: 49.21918, lng: 20.84481 },
    { lat: 49.219477, lng: 20.845043 },
    { lat: 49.219822, lng: 20.84485 },
    { lat: 49.219997, lng: 20.844423 },
    { lat: 49.220204, lng: 20.844582 },
    { lat: 49.22013, lng: 20.845048 },
    { lat: 49.220279, lng: 20.845151 },
    { lat: 49.220248, lng: 20.845333 },
    { lat: 49.220025, lng: 20.845283 },
    { lat: 49.219857, lng: 20.845936 },
    { lat: 49.220047, lng: 20.846176 },
    { lat: 49.219837, lng: 20.846575 },
    { lat: 49.219631, lng: 20.846903 },
    { lat: 49.219883, lng: 20.847391 },
    { lat: 49.220122, lng: 20.848535 },
    { lat: 49.220406, lng: 20.849939 },
    { lat: 49.22088, lng: 20.850481 },
    { lat: 49.220625, lng: 20.851128 },
    { lat: 49.221064, lng: 20.851035 },
    { lat: 49.221487, lng: 20.851215 },
    { lat: 49.221815, lng: 20.851684 },
    { lat: 49.222074, lng: 20.851932 },
    { lat: 49.222545, lng: 20.851686 },
    { lat: 49.222669, lng: 20.851329 },
    { lat: 49.223305, lng: 20.851708 },
    { lat: 49.223497, lng: 20.852094 },
    { lat: 49.224047, lng: 20.852146 },
    { lat: 49.22456, lng: 20.852529 },
    { lat: 49.224566, lng: 20.852531 },
    { lat: 49.225176, lng: 20.850985 },
    { lat: 49.225417, lng: 20.850723 },
    { lat: 49.22559, lng: 20.849733 },
    { lat: 49.225939, lng: 20.848931 },
    { lat: 49.226587, lng: 20.848024 },
    { lat: 49.226727, lng: 20.847948 },
    { lat: 49.227128, lng: 20.847788 },
    { lat: 49.22703, lng: 20.847795 },
    { lat: 49.226309, lng: 20.847323 },
    { lat: 49.227351, lng: 20.847125 },
    { lat: 49.227164, lng: 20.846225 },
    { lat: 49.227107, lng: 20.845018 },
    { lat: 49.227917, lng: 20.844256 },
    { lat: 49.227514, lng: 20.843143 },
    { lat: 49.226863, lng: 20.841374 },
    { lat: 49.22744, lng: 20.841143 },
    { lat: 49.226099, lng: 20.838369 },
    { lat: 49.225944, lng: 20.83822 },
    { lat: 49.225814, lng: 20.838098 },
    { lat: 49.225654, lng: 20.837585 },
    { lat: 49.225337, lng: 20.837477 },
    { lat: 49.225179, lng: 20.836643 },
    { lat: 49.223905, lng: 20.832352 },
    { lat: 49.2238673, lng: 20.8323485 },
  ],
  Bajerovce: [
    { lat: 49.2238673, lng: 20.8323485 },
    { lat: 49.223905, lng: 20.832352 },
    { lat: 49.224023, lng: 20.832525 },
    { lat: 49.224441, lng: 20.832797 },
    { lat: 49.224666, lng: 20.833037 },
    { lat: 49.224702, lng: 20.832978 },
    { lat: 49.224721, lng: 20.832985 },
    { lat: 49.224892, lng: 20.833086 },
    { lat: 49.225035, lng: 20.833184 },
    { lat: 49.225257, lng: 20.833219 },
    { lat: 49.225551, lng: 20.833333 },
    { lat: 49.22581, lng: 20.83334 },
    { lat: 49.22604, lng: 20.833148 },
    { lat: 49.22628, lng: 20.832754 },
    { lat: 49.227606, lng: 20.831465 },
    { lat: 49.228667, lng: 20.8303 },
    { lat: 49.228942, lng: 20.829153 },
    { lat: 49.230104, lng: 20.826678 },
    { lat: 49.231242, lng: 20.825649 },
    { lat: 49.231796, lng: 20.825399 },
    { lat: 49.231895, lng: 20.824434 },
    { lat: 49.231854, lng: 20.821083 },
    { lat: 49.231304, lng: 20.820453 },
    { lat: 49.230467, lng: 20.820429 },
    { lat: 49.229614, lng: 20.818742 },
    { lat: 49.228704, lng: 20.816551 },
    { lat: 49.229531, lng: 20.812544 },
    { lat: 49.230213, lng: 20.811125 },
    { lat: 49.230717, lng: 20.808793 },
    { lat: 49.228759, lng: 20.806937 },
    { lat: 49.227725, lng: 20.805192 },
    { lat: 49.227285, lng: 20.804887 },
    { lat: 49.227206, lng: 20.804444 },
    { lat: 49.226882, lng: 20.804632 },
    { lat: 49.22683, lng: 20.800922 },
    { lat: 49.226937, lng: 20.798634 },
    { lat: 49.227315, lng: 20.796939 },
    { lat: 49.227186, lng: 20.795616 },
    { lat: 49.227791, lng: 20.793549 },
    { lat: 49.227461, lng: 20.78964 },
    { lat: 49.227531, lng: 20.787205 },
    { lat: 49.228287, lng: 20.784394 },
    { lat: 49.228766, lng: 20.783308 },
    { lat: 49.228858, lng: 20.782648 },
    { lat: 49.229562, lng: 20.779571 },
    { lat: 49.229545, lng: 20.777758 },
    { lat: 49.22977, lng: 20.776524 },
    { lat: 49.230336, lng: 20.775094 },
    { lat: 49.230128, lng: 20.774304 },
    { lat: 49.229996, lng: 20.771523 },
    { lat: 49.228509, lng: 20.771658 },
    { lat: 49.227921, lng: 20.771711 },
    { lat: 49.227441, lng: 20.771342 },
    { lat: 49.227392, lng: 20.771347 },
    { lat: 49.226968, lng: 20.771398 },
    { lat: 49.225883, lng: 20.771422 },
    { lat: 49.224698, lng: 20.77199 },
    { lat: 49.22396, lng: 20.772294 },
    { lat: 49.223331, lng: 20.771675 },
    { lat: 49.222175, lng: 20.771348 },
    { lat: 49.221736, lng: 20.771005 },
    { lat: 49.221713, lng: 20.771004 },
    { lat: 49.221637, lng: 20.770999 },
    { lat: 49.221616, lng: 20.770997 },
    { lat: 49.221613, lng: 20.770998 },
    { lat: 49.221522, lng: 20.771045 },
    { lat: 49.220912, lng: 20.771219 },
    { lat: 49.22041, lng: 20.771451 },
    { lat: 49.219958, lng: 20.772142 },
    { lat: 49.219543, lng: 20.772401 },
    { lat: 49.218871, lng: 20.77317 },
    { lat: 49.218519, lng: 20.773303 },
    { lat: 49.217747, lng: 20.772487 },
    { lat: 49.215886, lng: 20.771381 },
    { lat: 49.216504, lng: 20.769739 },
    { lat: 49.21571, lng: 20.76996 },
    { lat: 49.215095, lng: 20.770118 },
    { lat: 49.214262, lng: 20.770146 },
    { lat: 49.213665, lng: 20.770079 },
    { lat: 49.21281, lng: 20.769338 },
    { lat: 49.212948, lng: 20.768617 },
    { lat: 49.212997, lng: 20.768331 },
    { lat: 49.21148, lng: 20.766812 },
    { lat: 49.21124, lng: 20.767068 },
    { lat: 49.208959, lng: 20.765369 },
    { lat: 49.208193, lng: 20.764784 },
    { lat: 49.207709, lng: 20.764419 },
    { lat: 49.207631, lng: 20.764343 },
    { lat: 49.2069662, lng: 20.763709 },
    { lat: 49.2065676, lng: 20.7633165 },
    { lat: 49.2065442, lng: 20.7632932 },
    { lat: 49.2059242, lng: 20.7626812 },
    { lat: 49.2058495, lng: 20.7626069 },
    { lat: 49.20572, lng: 20.7622827 },
    { lat: 49.2056178, lng: 20.7620296 },
    { lat: 49.2057062, lng: 20.7617845 },
    { lat: 49.205681, lng: 20.7613247 },
    { lat: 49.2057101, lng: 20.7607399 },
    { lat: 49.2056316, lng: 20.7600965 },
    { lat: 49.2056915, lng: 20.7600099 },
    { lat: 49.2057253, lng: 20.7596879 },
    { lat: 49.2058093, lng: 20.7594503 },
    { lat: 49.2055493, lng: 20.7592245 },
    { lat: 49.2052452, lng: 20.7589598 },
    { lat: 49.2048039, lng: 20.7583106 },
    { lat: 49.2046986, lng: 20.758164 },
    { lat: 49.2038931, lng: 20.7570476 },
    { lat: 49.2035734, lng: 20.7565311 },
    { lat: 49.2032479, lng: 20.7560119 },
    { lat: 49.2030509, lng: 20.7555898 },
    { lat: 49.2027219, lng: 20.7549967 },
    { lat: 49.2025382, lng: 20.7546661 },
    { lat: 49.2021506, lng: 20.7542034 },
    { lat: 49.2017119, lng: 20.7540238 },
    { lat: 49.2012299, lng: 20.7538233 },
    { lat: 49.2007411, lng: 20.7536528 },
    { lat: 49.2003667, lng: 20.7535688 },
    { lat: 49.200065, lng: 20.7534997 },
    { lat: 49.1996358, lng: 20.75349 },
    { lat: 49.1994274, lng: 20.7535191 },
    { lat: 49.1987711, lng: 20.7538741 },
    { lat: 49.1985789, lng: 20.7539419 },
    { lat: 49.1984141, lng: 20.7539927 },
    { lat: 49.1976108, lng: 20.7541693 },
    { lat: 49.1975023, lng: 20.7539945 },
    { lat: 49.1969458, lng: 20.7537889 },
    { lat: 49.1966835, lng: 20.7537537 },
    { lat: 49.1964793, lng: 20.7537077 },
    { lat: 49.1961794, lng: 20.7535982 },
    { lat: 49.1960756, lng: 20.7534965 },
    { lat: 49.1959339, lng: 20.753336 },
    { lat: 49.1956408, lng: 20.7531964 },
    { lat: 49.1952575, lng: 20.7529508 },
    { lat: 49.194912, lng: 20.7527565 },
    { lat: 49.1947654, lng: 20.7526867 },
    { lat: 49.1946054, lng: 20.7525813 },
    { lat: 49.1945047, lng: 20.7525099 },
    { lat: 49.1943002, lng: 20.7523527 },
    { lat: 49.1941079, lng: 20.7521956 },
    { lat: 49.1938362, lng: 20.7519607 },
    { lat: 49.1935899, lng: 20.7517091 },
    { lat: 49.1934356, lng: 20.7515442 },
    { lat: 49.1933361, lng: 20.7514396 },
    { lat: 49.1930579, lng: 20.7512067 },
    { lat: 49.1927744, lng: 20.7509487 },
    { lat: 49.1924492, lng: 20.7506354 },
    { lat: 49.1918665, lng: 20.749935 },
    { lat: 49.1916798, lng: 20.7497098 },
    { lat: 49.1916033, lng: 20.749621 },
    { lat: 49.1913786, lng: 20.7494054 },
    { lat: 49.19112, lng: 20.7491529 },
    { lat: 49.1911126, lng: 20.7491377 },
    { lat: 49.1911152, lng: 20.7491112 },
    { lat: 49.189065, lng: 20.7523847 },
    { lat: 49.1884545, lng: 20.7536931 },
    { lat: 49.1878757, lng: 20.7553961 },
    { lat: 49.1867438, lng: 20.7579929 },
    { lat: 49.1858433, lng: 20.7599282 },
    { lat: 49.1853454, lng: 20.7608822 },
    { lat: 49.1852248, lng: 20.7618829 },
    { lat: 49.185231, lng: 20.7624452 },
    { lat: 49.1845943, lng: 20.7652336 },
    { lat: 49.1845253, lng: 20.7670027 },
    { lat: 49.1843565, lng: 20.767332 },
    { lat: 49.1843806, lng: 20.7681543 },
    { lat: 49.1845307, lng: 20.7693883 },
    { lat: 49.1845738, lng: 20.770181 },
    { lat: 49.1850649, lng: 20.7720664 },
    { lat: 49.185574, lng: 20.773598 },
    { lat: 49.1858299, lng: 20.7755535 },
    { lat: 49.1860505, lng: 20.7758735 },
    { lat: 49.1864966, lng: 20.7761825 },
    { lat: 49.18733, lng: 20.7774214 },
    { lat: 49.185991, lng: 20.778699 },
    { lat: 49.1862166, lng: 20.7793602 },
    { lat: 49.1858124, lng: 20.7800758 },
    { lat: 49.1858722, lng: 20.7803735 },
    { lat: 49.1852134, lng: 20.7819142 },
    { lat: 49.1849529, lng: 20.7820011 },
    { lat: 49.1843791, lng: 20.7812958 },
    { lat: 49.1842698, lng: 20.7812913 },
    { lat: 49.1839616, lng: 20.7820084 },
    { lat: 49.1836607, lng: 20.7827455 },
    { lat: 49.1838032, lng: 20.7828355 },
    { lat: 49.184785, lng: 20.7833622 },
    { lat: 49.1851358, lng: 20.7837383 },
    { lat: 49.185557, lng: 20.7843628 },
    { lat: 49.1857265, lng: 20.7845114 },
    { lat: 49.1862096, lng: 20.7831505 },
    { lat: 49.1869479, lng: 20.7826972 },
    { lat: 49.1878479, lng: 20.7825865 },
    { lat: 49.1880662, lng: 20.7826516 },
    { lat: 49.1884968, lng: 20.7826345 },
    { lat: 49.1887853, lng: 20.7827454 },
    { lat: 49.1891176, lng: 20.7827496 },
    { lat: 49.1896876, lng: 20.7830213 },
    { lat: 49.190347, lng: 20.7831481 },
    { lat: 49.1907367, lng: 20.7834149 },
    { lat: 49.1910095, lng: 20.7834948 },
    { lat: 49.1912374, lng: 20.7837319 },
    { lat: 49.1916631, lng: 20.7839369 },
    { lat: 49.1926349, lng: 20.7850878 },
    { lat: 49.1929118, lng: 20.7852188 },
    { lat: 49.1929174, lng: 20.7853781 },
    { lat: 49.1931702, lng: 20.7856582 },
    { lat: 49.1933407, lng: 20.7860321 },
    { lat: 49.1936385, lng: 20.7863078 },
    { lat: 49.1936884, lng: 20.786189 },
    { lat: 49.1939484, lng: 20.786511 },
    { lat: 49.1941616, lng: 20.7864639 },
    { lat: 49.1949956, lng: 20.7867548 },
    { lat: 49.1953198, lng: 20.7867535 },
    { lat: 49.1958182, lng: 20.7870387 },
    { lat: 49.1962287, lng: 20.7880709 },
    { lat: 49.1964618, lng: 20.7888547 },
    { lat: 49.1967116, lng: 20.7889861 },
    { lat: 49.1969725, lng: 20.7890082 },
    { lat: 49.1972846, lng: 20.7893768 },
    { lat: 49.1976755, lng: 20.7897131 },
    { lat: 49.1980552, lng: 20.7908911 },
    { lat: 49.1985216, lng: 20.7916177 },
    { lat: 49.1986824, lng: 20.7924613 },
    { lat: 49.1989284, lng: 20.7933633 },
    { lat: 49.1989733, lng: 20.7943692 },
    { lat: 49.199201, lng: 20.7946381 },
    { lat: 49.1995208, lng: 20.7958433 },
    { lat: 49.1993639, lng: 20.7963904 },
    { lat: 49.1996603, lng: 20.7969543 },
    { lat: 49.2023203, lng: 20.8005457 },
    { lat: 49.203236, lng: 20.8025544 },
    { lat: 49.2041789, lng: 20.8040826 },
    { lat: 49.2053955, lng: 20.8073092 },
    { lat: 49.2086521, lng: 20.8142206 },
    { lat: 49.2095237, lng: 20.8157146 },
    { lat: 49.2110266, lng: 20.8174587 },
    { lat: 49.2135662, lng: 20.8193713 },
    { lat: 49.2149362, lng: 20.8200282 },
    { lat: 49.2153984, lng: 20.8207742 },
    { lat: 49.2184278, lng: 20.8232864 },
    { lat: 49.2234369, lng: 20.8311841 },
    { lat: 49.2238834, lng: 20.8322878 },
    { lat: 49.2238673, lng: 20.8323485 },
  ],
  ŠarišskéSokolovce: [
    { lat: 49.0978392, lng: 21.1805441 },
    { lat: 49.0980092, lng: 21.1806567 },
    { lat: 49.099514, lng: 21.1830354 },
    { lat: 49.1007049, lng: 21.1853892 },
    { lat: 49.100908, lng: 21.1847959 },
    { lat: 49.1018154, lng: 21.1846241 },
    { lat: 49.1017899, lng: 21.1845326 },
    { lat: 49.1022867, lng: 21.184117 },
    { lat: 49.1033872, lng: 21.1833602 },
    { lat: 49.103741, lng: 21.1829811 },
    { lat: 49.1038978, lng: 21.1827395 },
    { lat: 49.1039743, lng: 21.1824865 },
    { lat: 49.1050315, lng: 21.1830753 },
    { lat: 49.1054268, lng: 21.1825129 },
    { lat: 49.1056104, lng: 21.1819066 },
    { lat: 49.1055615, lng: 21.1817478 },
    { lat: 49.1056317, lng: 21.1813294 },
    { lat: 49.1055712, lng: 21.1804075 },
    { lat: 49.1058195, lng: 21.1785236 },
    { lat: 49.1061929, lng: 21.1777236 },
    { lat: 49.1065643, lng: 21.1777968 },
    { lat: 49.1075738, lng: 21.1732381 },
    { lat: 49.1073083, lng: 21.1731346 },
    { lat: 49.1072508, lng: 21.1730221 },
    { lat: 49.1073795, lng: 21.1723989 },
    { lat: 49.1076893, lng: 21.1697508 },
    { lat: 49.1079626, lng: 21.1697815 },
    { lat: 49.1081696, lng: 21.1700653 },
    { lat: 49.1083582, lng: 21.170058 },
    { lat: 49.1086767, lng: 21.1699116 },
    { lat: 49.1091769, lng: 21.1694984 },
    { lat: 49.1101721, lng: 21.1694065 },
    { lat: 49.1105985, lng: 21.1696546 },
    { lat: 49.1109735, lng: 21.1695231 },
    { lat: 49.1115164, lng: 21.1694645 },
    { lat: 49.1122891, lng: 21.1689164 },
    { lat: 49.1128451, lng: 21.1690958 },
    { lat: 49.113566, lng: 21.1688328 },
    { lat: 49.1141534, lng: 21.1690425 },
    { lat: 49.1148208, lng: 21.1691203 },
    { lat: 49.1151641, lng: 21.1690533 },
    { lat: 49.1158763, lng: 21.1691591 },
    { lat: 49.1164771, lng: 21.1693678 },
    { lat: 49.116605, lng: 21.1695683 },
    { lat: 49.1167761, lng: 21.1697143 },
    { lat: 49.1173284, lng: 21.1700315 },
    { lat: 49.1181143, lng: 21.1702264 },
    { lat: 49.1183655, lng: 21.1700925 },
    { lat: 49.1187387, lng: 21.1701493 },
    { lat: 49.1196492, lng: 21.170693 },
    { lat: 49.1204856, lng: 21.1714523 },
    { lat: 49.1212937, lng: 21.1719615 },
    { lat: 49.1220362, lng: 21.172202 },
    { lat: 49.1228101, lng: 21.1722301 },
    { lat: 49.123688, lng: 21.1725237 },
    { lat: 49.1250353, lng: 21.1734984 },
    { lat: 49.1253169, lng: 21.1735092 },
    { lat: 49.1259138, lng: 21.1733817 },
    { lat: 49.1274353, lng: 21.1728818 },
    { lat: 49.1286099, lng: 21.1728074 },
    { lat: 49.1306706, lng: 21.1722071 },
    { lat: 49.1316744, lng: 21.1718164 },
    { lat: 49.1333858, lng: 21.1716249 },
    { lat: 49.1338997, lng: 21.1714703 },
    { lat: 49.1356153, lng: 21.1715635 },
    { lat: 49.1364155, lng: 21.1720353 },
    { lat: 49.1373071, lng: 21.1718989 },
    { lat: 49.1375956, lng: 21.1710922 },
    { lat: 49.1378634, lng: 21.170585 },
    { lat: 49.1379022, lng: 21.1702433 },
    { lat: 49.1382801, lng: 21.1693856 },
    { lat: 49.1384319, lng: 21.1691635 },
    { lat: 49.1387099, lng: 21.1683763 },
    { lat: 49.1390235, lng: 21.1680728 },
    { lat: 49.1392448, lng: 21.1679571 },
    { lat: 49.139387, lng: 21.1676136 },
    { lat: 49.1396926, lng: 21.1674921 },
    { lat: 49.1399434, lng: 21.1679482 },
    { lat: 49.1401308, lng: 21.1680043 },
    { lat: 49.1403009, lng: 21.1680066 },
    { lat: 49.1406928, lng: 21.1677387 },
    { lat: 49.1409056, lng: 21.1678446 },
    { lat: 49.1410823, lng: 21.1677728 },
    { lat: 49.1412035, lng: 21.167834 },
    { lat: 49.1413715, lng: 21.1677776 },
    { lat: 49.1418426, lng: 21.1678352 },
    { lat: 49.1422672, lng: 21.1677346 },
    { lat: 49.1424215, lng: 21.1675278 },
    { lat: 49.1426736, lng: 21.1674544 },
    { lat: 49.1429377, lng: 21.1672288 },
    { lat: 49.1432341, lng: 21.1665664 },
    { lat: 49.1434427, lng: 21.1663599 },
    { lat: 49.1435822, lng: 21.1663972 },
    { lat: 49.1437094, lng: 21.1663254 },
    { lat: 49.1441533, lng: 21.1659138 },
    { lat: 49.1445945, lng: 21.1653069 },
    { lat: 49.1446508, lng: 21.1651633 },
    { lat: 49.1447923, lng: 21.1650494 },
    { lat: 49.1452314, lng: 21.1649843 },
    { lat: 49.1455045, lng: 21.1650819 },
    { lat: 49.1456975, lng: 21.165245 },
    { lat: 49.1465049, lng: 21.165116 },
    { lat: 49.1467609, lng: 21.1655258 },
    { lat: 49.1472219, lng: 21.1657003 },
    { lat: 49.1474733, lng: 21.1660314 },
    { lat: 49.1478293, lng: 21.1662229 },
    { lat: 49.148207, lng: 21.1667162 },
    { lat: 49.1485505, lng: 21.1669416 },
    { lat: 49.1497714, lng: 21.1668325 },
    { lat: 49.1501476, lng: 21.1665516 },
    { lat: 49.1504732, lng: 21.1664584 },
    { lat: 49.1511934, lng: 21.1668509 },
    { lat: 49.1512785, lng: 21.1667293 },
    { lat: 49.1516391, lng: 21.1666405 },
    { lat: 49.1518364, lng: 21.1667976 },
    { lat: 49.1523541, lng: 21.1665818 },
    { lat: 49.1526552, lng: 21.166672 },
    { lat: 49.1528942, lng: 21.1665784 },
    { lat: 49.1530341, lng: 21.1667005 },
    { lat: 49.1533553, lng: 21.1665383 },
    { lat: 49.1539051, lng: 21.1666333 },
    { lat: 49.1540664, lng: 21.166783 },
    { lat: 49.1543097, lng: 21.1665772 },
    { lat: 49.1546737, lng: 21.1666265 },
    { lat: 49.1551545, lng: 21.1661399 },
    { lat: 49.1556936, lng: 21.1654577 },
    { lat: 49.1562474, lng: 21.1645307 },
    { lat: 49.1566674, lng: 21.1639691 },
    { lat: 49.1568945, lng: 21.1634312 },
    { lat: 49.1572345, lng: 21.1628954 },
    { lat: 49.1572737, lng: 21.1626718 },
    { lat: 49.1580457, lng: 21.1618176 },
    { lat: 49.1595411, lng: 21.1606301 },
    { lat: 49.1597794, lng: 21.1602305 },
    { lat: 49.1600492, lng: 21.1595406 },
    { lat: 49.1606479, lng: 21.1571496 },
    { lat: 49.1609929, lng: 21.156452 },
    { lat: 49.1612591, lng: 21.1560791 },
    { lat: 49.1619887, lng: 21.1546259 },
    { lat: 49.1621441, lng: 21.1545196 },
    { lat: 49.1622666, lng: 21.1542715 },
    { lat: 49.1628356, lng: 21.1536854 },
    { lat: 49.162913, lng: 21.1532468 },
    { lat: 49.1630442, lng: 21.153056 },
    { lat: 49.1639682, lng: 21.1524148 },
    { lat: 49.1642205, lng: 21.1521734 },
    { lat: 49.1643505, lng: 21.1518327 },
    { lat: 49.1645285, lng: 21.1515927 },
    { lat: 49.164887, lng: 21.1510415 },
    { lat: 49.1646785, lng: 21.1508155 },
    { lat: 49.163902, lng: 21.1502402 },
    { lat: 49.1636674, lng: 21.150188 },
    { lat: 49.1634936, lng: 21.1500321 },
    { lat: 49.1632091, lng: 21.149505 },
    { lat: 49.1629698, lng: 21.1488411 },
    { lat: 49.1627775, lng: 21.1480217 },
    { lat: 49.162743, lng: 21.1475335 },
    { lat: 49.1628004, lng: 21.1472819 },
    { lat: 49.1627713, lng: 21.1468982 },
    { lat: 49.1628517, lng: 21.1467341 },
    { lat: 49.1628613, lng: 21.1464317 },
    { lat: 49.1629584, lng: 21.1460135 },
    { lat: 49.1630765, lng: 21.1450488 },
    { lat: 49.1632388, lng: 21.1445273 },
    { lat: 49.1633768, lng: 21.1434446 },
    { lat: 49.1633451, lng: 21.1428694 },
    { lat: 49.1631154, lng: 21.14254 },
    { lat: 49.1629811, lng: 21.1423482 },
    { lat: 49.1618426, lng: 21.1424688 },
    { lat: 49.159194, lng: 21.1417368 },
    { lat: 49.1584221, lng: 21.140913 },
    { lat: 49.1583322, lng: 21.1410307 },
    { lat: 49.1534758, lng: 21.1398963 },
    { lat: 49.1528871, lng: 21.1381482 },
    { lat: 49.1525429, lng: 21.13851 },
    { lat: 49.1522228, lng: 21.1386036 },
    { lat: 49.1514356, lng: 21.1391144 },
    { lat: 49.1508042, lng: 21.1400049 },
    { lat: 49.1505394, lng: 21.1399216 },
    { lat: 49.1500435, lng: 21.1400037 },
    { lat: 49.1493777, lng: 21.140658 },
    { lat: 49.1492299, lng: 21.140965 },
    { lat: 49.1488577, lng: 21.1410053 },
    { lat: 49.1485745, lng: 21.1409478 },
    { lat: 49.1476715, lng: 21.1416809 },
    { lat: 49.1470902, lng: 21.1428688 },
    { lat: 49.1466737, lng: 21.1431866 },
    { lat: 49.1461003, lng: 21.1440544 },
    { lat: 49.1459127, lng: 21.144208 },
    { lat: 49.145554, lng: 21.1447875 },
    { lat: 49.1451152, lng: 21.1451098 },
    { lat: 49.1449009, lng: 21.1451999 },
    { lat: 49.1442569, lng: 21.1459035 },
    { lat: 49.144013, lng: 21.1460954 },
    { lat: 49.1430555, lng: 21.1474118 },
    { lat: 49.1427705, lng: 21.1475887 },
    { lat: 49.142551, lng: 21.1479387 },
    { lat: 49.142034, lng: 21.1483166 },
    { lat: 49.141674, lng: 21.1494252 },
    { lat: 49.141094, lng: 21.1505492 },
    { lat: 49.1404409, lng: 21.1516055 },
    { lat: 49.1398486, lng: 21.151916 },
    { lat: 49.1388529, lng: 21.1516032 },
    { lat: 49.1382089, lng: 21.1522032 },
    { lat: 49.1375675, lng: 21.152345 },
    { lat: 49.1369625, lng: 21.1523178 },
    { lat: 49.1365439, lng: 21.1524268 },
    { lat: 49.1356013, lng: 21.1532613 },
    { lat: 49.1353844, lng: 21.1533095 },
    { lat: 49.1347705, lng: 21.1530741 },
    { lat: 49.1337054, lng: 21.1521994 },
    { lat: 49.1330053, lng: 21.1514446 },
    { lat: 49.1326988, lng: 21.1512896 },
    { lat: 49.1321255, lng: 21.150771 },
    { lat: 49.1312861, lng: 21.1504167 },
    { lat: 49.1309924, lng: 21.1501353 },
    { lat: 49.1306237, lng: 21.1500035 },
    { lat: 49.1303752, lng: 21.1500418 },
    { lat: 49.1302992, lng: 21.1499209 },
    { lat: 49.1302322, lng: 21.1499707 },
    { lat: 49.1298787, lng: 21.1498209 },
    { lat: 49.129239, lng: 21.1499127 },
    { lat: 49.1290502, lng: 21.1498104 },
    { lat: 49.1286352, lng: 21.1499971 },
    { lat: 49.1274709, lng: 21.1501899 },
    { lat: 49.1273395, lng: 21.1502678 },
    { lat: 49.1270665, lng: 21.1502497 },
    { lat: 49.1268746, lng: 21.1501595 },
    { lat: 49.1268345, lng: 21.1502041 },
    { lat: 49.1266477, lng: 21.1501726 },
    { lat: 49.1264566, lng: 21.1498934 },
    { lat: 49.1263153, lng: 21.1499073 },
    { lat: 49.1262133, lng: 21.1496726 },
    { lat: 49.1259237, lng: 21.1495134 },
    { lat: 49.1255144, lng: 21.1490972 },
    { lat: 49.1254539, lng: 21.1491084 },
    { lat: 49.1253739, lng: 21.148995 },
    { lat: 49.1249058, lng: 21.1487102 },
    { lat: 49.1247658, lng: 21.1485549 },
    { lat: 49.1245779, lng: 21.1485949 },
    { lat: 49.1238011, lng: 21.1482242 },
    { lat: 49.1237917, lng: 21.1483525 },
    { lat: 49.1232362, lng: 21.148363 },
    { lat: 49.123123, lng: 21.1484898 },
    { lat: 49.12299, lng: 21.1488327 },
    { lat: 49.1227688, lng: 21.1485345 },
    { lat: 49.1225533, lng: 21.1484755 },
    { lat: 49.1219617, lng: 21.1485207 },
    { lat: 49.1209807, lng: 21.1487801 },
    { lat: 49.1202546, lng: 21.1491603 },
    { lat: 49.119967, lng: 21.1492147 },
    { lat: 49.1196153, lng: 21.1491245 },
    { lat: 49.1194564, lng: 21.1492593 },
    { lat: 49.1193512, lng: 21.1494596 },
    { lat: 49.1193215, lng: 21.1496242 },
    { lat: 49.1192253, lng: 21.1496388 },
    { lat: 49.1191176, lng: 21.1494681 },
    { lat: 49.1189392, lng: 21.1493827 },
    { lat: 49.1182424, lng: 21.1494428 },
    { lat: 49.117873, lng: 21.1494023 },
    { lat: 49.1176985, lng: 21.1495735 },
    { lat: 49.1169416, lng: 21.1498359 },
    { lat: 49.1167113, lng: 21.1501017 },
    { lat: 49.1164289, lng: 21.1499618 },
    { lat: 49.1151318, lng: 21.1496494 },
    { lat: 49.1149412, lng: 21.149566 },
    { lat: 49.1146165, lng: 21.1492967 },
    { lat: 49.1144703, lng: 21.1492686 },
    { lat: 49.1144556, lng: 21.1495879 },
    { lat: 49.1123039, lng: 21.1498655 },
    { lat: 49.1118686, lng: 21.1497986 },
    { lat: 49.1115209, lng: 21.1504067 },
    { lat: 49.1100227, lng: 21.1503576 },
    { lat: 49.1094539, lng: 21.1504851 },
    { lat: 49.1083515, lng: 21.1506036 },
    { lat: 49.107634, lng: 21.1505835 },
    { lat: 49.1067839, lng: 21.1507848 },
    { lat: 49.1062493, lng: 21.1508153 },
    { lat: 49.1058105, lng: 21.1510694 },
    { lat: 49.1046554, lng: 21.1511554 },
    { lat: 49.1038117, lng: 21.1514183 },
    { lat: 49.1025131, lng: 21.1516634 },
    { lat: 49.1017326, lng: 21.1517188 },
    { lat: 49.1009915, lng: 21.1516606 },
    { lat: 49.0987342, lng: 21.1520208 },
    { lat: 49.0984712, lng: 21.1521377 },
    { lat: 49.0957056, lng: 21.1526557 },
    { lat: 49.0944119, lng: 21.1532358 },
    { lat: 49.0936746, lng: 21.1534399 },
    { lat: 49.0925392, lng: 21.1541969 },
    { lat: 49.0914334, lng: 21.1547011 },
    { lat: 49.0913382, lng: 21.1556455 },
    { lat: 49.091399, lng: 21.1573581 },
    { lat: 49.0914688, lng: 21.1578593 },
    { lat: 49.0916813, lng: 21.1584969 },
    { lat: 49.0923029, lng: 21.1599815 },
    { lat: 49.0925233, lng: 21.1602261 },
    { lat: 49.0926845, lng: 21.1605518 },
    { lat: 49.0927669, lng: 21.1609203 },
    { lat: 49.0929145, lng: 21.1611386 },
    { lat: 49.0931131, lng: 21.1612674 },
    { lat: 49.0935646, lng: 21.161414 },
    { lat: 49.0940831, lng: 21.1617654 },
    { lat: 49.0943558, lng: 21.1617013 },
    { lat: 49.0948014, lng: 21.161774 },
    { lat: 49.0950517, lng: 21.1617159 },
    { lat: 49.0952636, lng: 21.1617654 },
    { lat: 49.095783, lng: 21.1621476 },
    { lat: 49.0959648, lng: 21.162417 },
    { lat: 49.096141, lng: 21.1624709 },
    { lat: 49.0965429, lng: 21.1623303 },
    { lat: 49.096706, lng: 21.1624685 },
    { lat: 49.0969166, lng: 21.1630183 },
    { lat: 49.0971522, lng: 21.1632809 },
    { lat: 49.0973009, lng: 21.1633145 },
    { lat: 49.0970752, lng: 21.1640862 },
    { lat: 49.0969236, lng: 21.1649562 },
    { lat: 49.0981895, lng: 21.1652381 },
    { lat: 49.0982929, lng: 21.1651979 },
    { lat: 49.0983057, lng: 21.1655729 },
    { lat: 49.098448, lng: 21.1663425 },
    { lat: 49.0984422, lng: 21.1666227 },
    { lat: 49.0983622, lng: 21.167012 },
    { lat: 49.0975122, lng: 21.1684317 },
    { lat: 49.0964331, lng: 21.1715468 },
    { lat: 49.0972662, lng: 21.1723125 },
    { lat: 49.0984104, lng: 21.173136 },
    { lat: 49.0986998, lng: 21.1732122 },
    { lat: 49.0990318, lng: 21.1734599 },
    { lat: 49.0997381, lng: 21.1723046 },
    { lat: 49.1006737, lng: 21.1727138 },
    { lat: 49.1005362, lng: 21.1753235 },
    { lat: 49.1004775, lng: 21.1758197 },
    { lat: 49.1001175, lng: 21.1767563 },
    { lat: 49.0989805, lng: 21.1785418 },
    { lat: 49.0987642, lng: 21.1789593 },
    { lat: 49.0986561, lng: 21.1792818 },
    { lat: 49.0985006, lng: 21.1794206 },
    { lat: 49.0980154, lng: 21.1801011 },
    { lat: 49.0979408, lng: 21.1804071 },
    { lat: 49.0978489, lng: 21.1805305 },
    { lat: 49.0978392, lng: 21.1805441 },
  ],
  Jarovnice: [
    { lat: 49.079551, lng: 20.991208 },
    { lat: 49.079558, lng: 20.99124 },
    { lat: 49.07959, lng: 20.992065 },
    { lat: 49.079575, lng: 20.992321 },
    { lat: 49.079577, lng: 20.992654 },
    { lat: 49.079539, lng: 20.992905 },
    { lat: 49.079561, lng: 20.993346 },
    { lat: 49.079713, lng: 20.994216 },
    { lat: 49.079793, lng: 20.994575 },
    { lat: 49.079961, lng: 20.995622 },
    { lat: 49.079976, lng: 20.995907 },
    { lat: 49.079999, lng: 20.996208 },
    { lat: 49.079959, lng: 20.996509 },
    { lat: 49.080038, lng: 20.997212 },
    { lat: 49.079913, lng: 20.998132 },
    { lat: 49.079887, lng: 20.998669 },
    { lat: 49.080021, lng: 20.999255 },
    { lat: 49.080101, lng: 20.999573 },
    { lat: 49.080177, lng: 21.000499 },
    { lat: 49.080216, lng: 21.000821 },
    { lat: 49.080253, lng: 21.001047 },
    { lat: 49.080288, lng: 21.001193 },
    { lat: 49.080292, lng: 21.001389 },
    { lat: 49.080267, lng: 21.001602 },
    { lat: 49.080236, lng: 21.001735 },
    { lat: 49.080132, lng: 21.002029 },
    { lat: 49.080043, lng: 21.002327 },
    { lat: 49.079999, lng: 21.002557 },
    { lat: 49.079967, lng: 21.002706 },
    { lat: 49.079948, lng: 21.002962 },
    { lat: 49.079514, lng: 21.004449 },
    { lat: 49.07938, lng: 21.004703 },
    { lat: 49.07876, lng: 21.005522 },
    { lat: 49.078486, lng: 21.005786 },
    { lat: 49.078479, lng: 21.00577 },
    { lat: 49.078274, lng: 21.00601 },
    { lat: 49.078061, lng: 21.006199 },
    { lat: 49.077943, lng: 21.006416 },
    { lat: 49.077736, lng: 21.006593 },
    { lat: 49.077475, lng: 21.006889 },
    { lat: 49.077338, lng: 21.007108 },
    { lat: 49.077105, lng: 21.007649 },
    { lat: 49.076875, lng: 21.008037 },
    { lat: 49.076699, lng: 21.008249 },
    { lat: 49.076514, lng: 21.008413 },
    { lat: 49.076277, lng: 21.008742 },
    { lat: 49.075898, lng: 21.008986 },
    { lat: 49.075261, lng: 21.009474 },
    { lat: 49.074946, lng: 21.009626 },
    { lat: 49.074725, lng: 21.009793 },
    { lat: 49.074606, lng: 21.009844 },
    { lat: 49.074273, lng: 21.009987 },
    { lat: 49.073709, lng: 21.010487 },
    { lat: 49.073359, lng: 21.01063 },
    { lat: 49.072894, lng: 21.011033 },
    { lat: 49.072582, lng: 21.011358 },
    { lat: 49.071887, lng: 21.012354 },
    { lat: 49.070885, lng: 21.013339 },
    { lat: 49.070807, lng: 21.013386 },
    { lat: 49.070694, lng: 21.01339 },
    { lat: 49.070325, lng: 21.013586 },
    { lat: 49.069695, lng: 21.014049 },
    { lat: 49.069541, lng: 21.014127 },
    { lat: 49.069293, lng: 21.014501 },
    { lat: 49.068942, lng: 21.014731 },
    { lat: 49.068561, lng: 21.015016 },
    { lat: 49.067991, lng: 21.015131 },
    { lat: 49.067503, lng: 21.015155 },
    { lat: 49.066988, lng: 21.015248 },
    { lat: 49.066811, lng: 21.015266 },
    { lat: 49.066521, lng: 21.015307 },
    { lat: 49.066291, lng: 21.015317 },
    { lat: 49.065552, lng: 21.015418 },
    { lat: 49.065093, lng: 21.015598 },
    { lat: 49.064727, lng: 21.015853 },
    { lat: 49.064394, lng: 21.016146 },
    { lat: 49.064178, lng: 21.016316 },
    { lat: 49.063907, lng: 21.016482 },
    { lat: 49.063757, lng: 21.016655 },
    { lat: 49.063709, lng: 21.016841 },
    { lat: 49.063452, lng: 21.017233 },
    { lat: 49.063205, lng: 21.017738 },
    { lat: 49.063041, lng: 21.018298 },
    { lat: 49.062913, lng: 21.018653 },
    { lat: 49.062804, lng: 21.018885 },
    { lat: 49.062694, lng: 21.019185 },
    { lat: 49.062409, lng: 21.019966 },
    { lat: 49.062332, lng: 21.020088 },
    { lat: 49.062297, lng: 21.020222 },
    { lat: 49.061972, lng: 21.020473 },
    { lat: 49.060938, lng: 21.020779 },
    { lat: 49.060853, lng: 21.020824 },
    { lat: 49.060562, lng: 21.020422 },
    { lat: 49.060428, lng: 21.020171 },
    { lat: 49.060216, lng: 21.019865 },
    { lat: 49.059732, lng: 21.019201 },
    { lat: 49.059518, lng: 21.018689 },
    { lat: 49.059487, lng: 21.018781 },
    { lat: 49.05941, lng: 21.018959 },
    { lat: 49.059041, lng: 21.019855 },
    { lat: 49.058961, lng: 21.020074 },
    { lat: 49.0589, lng: 21.020457 },
    { lat: 49.058537, lng: 21.021926 },
    { lat: 49.058355, lng: 21.022885 },
    { lat: 49.058203, lng: 21.023255 },
    { lat: 49.058006, lng: 21.023116 },
    { lat: 49.057964, lng: 21.023325 },
    { lat: 49.057922, lng: 21.023893 },
    { lat: 49.057912, lng: 21.024675 },
    { lat: 49.057894, lng: 21.024989 },
    { lat: 49.057839, lng: 21.025404 },
    { lat: 49.057734, lng: 21.02568 },
    { lat: 49.057606, lng: 21.025865 },
    { lat: 49.057386, lng: 21.026119 },
    { lat: 49.057225, lng: 21.026386 },
    { lat: 49.056987, lng: 21.026697 },
    { lat: 49.05685, lng: 21.026851 },
    { lat: 49.056741, lng: 21.027019 },
    { lat: 49.056488, lng: 21.027377 },
    { lat: 49.056368, lng: 21.027591 },
    { lat: 49.055992, lng: 21.028162 },
    { lat: 49.055643, lng: 21.028663 },
    { lat: 49.055472, lng: 21.029052 },
    { lat: 49.055314, lng: 21.029323 },
    { lat: 49.055142, lng: 21.029428 },
    { lat: 49.055119, lng: 21.029453 },
    { lat: 49.054888, lng: 21.029636 },
    { lat: 49.054714, lng: 21.029728 },
    { lat: 49.054608, lng: 21.029763 },
    { lat: 49.054133, lng: 21.030545 },
    { lat: 49.054035, lng: 21.030652 },
    { lat: 49.053892, lng: 21.030751 },
    { lat: 49.05359, lng: 21.030899 },
    { lat: 49.053417, lng: 21.031238 },
    { lat: 49.053285, lng: 21.031459 },
    { lat: 49.052865, lng: 21.031905 },
    { lat: 49.05242, lng: 21.032556 },
    { lat: 49.052021, lng: 21.033002 },
    { lat: 49.051675, lng: 21.03347 },
    { lat: 49.051371, lng: 21.03393 },
    { lat: 49.050828, lng: 21.034865 },
    { lat: 49.050633, lng: 21.035174 },
    { lat: 49.04952, lng: 21.036949 },
    { lat: 49.048604, lng: 21.038351 },
    { lat: 49.048193, lng: 21.039045 },
    { lat: 49.047899, lng: 21.039493 },
    { lat: 49.047137, lng: 21.040443 },
    { lat: 49.045697, lng: 21.041974 },
    { lat: 49.045619, lng: 21.042007 },
    { lat: 49.045171, lng: 21.042591 },
    { lat: 49.044733, lng: 21.041979 },
    { lat: 49.044707, lng: 21.041918 },
    { lat: 49.044666, lng: 21.041826 },
    { lat: 49.04448, lng: 21.041422 },
    { lat: 49.043704, lng: 21.039384 },
    { lat: 49.043679, lng: 21.039319 },
    { lat: 49.043607, lng: 21.039065 },
    { lat: 49.042639, lng: 21.039693 },
    { lat: 49.042592, lng: 21.03954 },
    { lat: 49.042471, lng: 21.039524 },
    { lat: 49.042118, lng: 21.039554 },
    { lat: 49.041864, lng: 21.039666 },
    { lat: 49.041702, lng: 21.039062 },
    { lat: 49.04141, lng: 21.039187 },
    { lat: 49.041232, lng: 21.039316 },
    { lat: 49.041094, lng: 21.039438 },
    { lat: 49.041737, lng: 21.040594 },
    { lat: 49.041088, lng: 21.040846 },
    { lat: 49.040484, lng: 21.041043 },
    { lat: 49.039979, lng: 21.041142 },
    { lat: 49.039697, lng: 21.041153 },
    { lat: 49.039411, lng: 21.041151 },
    { lat: 49.039156, lng: 21.041183 },
    { lat: 49.038925, lng: 21.04124 },
    { lat: 49.039398, lng: 21.044844 },
    { lat: 49.03955, lng: 21.045459 },
    { lat: 49.039687, lng: 21.046297 },
    { lat: 49.039794, lng: 21.047786 },
    { lat: 49.039947, lng: 21.048949 },
    { lat: 49.040109, lng: 21.049188 },
    { lat: 49.040648, lng: 21.049641 },
    { lat: 49.041077, lng: 21.050139 },
    { lat: 49.041007, lng: 21.050588 },
    { lat: 49.040987, lng: 21.050715 },
    { lat: 49.040867, lng: 21.051484 },
    { lat: 49.040785, lng: 21.052388 },
    { lat: 49.040961, lng: 21.054354 },
    { lat: 49.0411959, lng: 21.0563797 },
    { lat: 49.0411698, lng: 21.0566217 },
    { lat: 49.0433084, lng: 21.0563135 },
    { lat: 49.0444878, lng: 21.0564205 },
    { lat: 49.044226, lng: 21.0568509 },
    { lat: 49.0436946, lng: 21.0574013 },
    { lat: 49.0450627, lng: 21.0574899 },
    { lat: 49.0464905, lng: 21.0585538 },
    { lat: 49.0463584, lng: 21.0588898 },
    { lat: 49.0459005, lng: 21.0596543 },
    { lat: 49.0459405, lng: 21.0611742 },
    { lat: 49.0464477, lng: 21.0621999 },
    { lat: 49.0466032, lng: 21.0629399 },
    { lat: 49.0450194, lng: 21.0632095 },
    { lat: 49.043839, lng: 21.0635579 },
    { lat: 49.0439705, lng: 21.0660252 },
    { lat: 49.0444178, lng: 21.0682343 },
    { lat: 49.0433389, lng: 21.0690442 },
    { lat: 49.0433241, lng: 21.0704688 },
    { lat: 49.0430625, lng: 21.0707664 },
    { lat: 49.0431322, lng: 21.0713549 },
    { lat: 49.04128, lng: 21.073164 },
    { lat: 49.04061, lng: 21.073921 },
    { lat: 49.039839, lng: 21.07503 },
    { lat: 49.040071, lng: 21.075684 },
    { lat: 49.040476, lng: 21.076821 },
    { lat: 49.041289, lng: 21.077621 },
    { lat: 49.041937, lng: 21.076951 },
    { lat: 49.042213, lng: 21.076827 },
    { lat: 49.042594, lng: 21.076656 },
    { lat: 49.043081, lng: 21.076662 },
    { lat: 49.043592, lng: 21.076203 },
    { lat: 49.044196, lng: 21.076389 },
    { lat: 49.042698, lng: 21.079043 },
    { lat: 49.041501, lng: 21.081847 },
    { lat: 49.04161, lng: 21.082048 },
    { lat: 49.041756, lng: 21.081912 },
    { lat: 49.042077, lng: 21.081572 },
    { lat: 49.04227, lng: 21.081417 },
    { lat: 49.042464, lng: 21.081469 },
    { lat: 49.042572, lng: 21.081171 },
    { lat: 49.042943, lng: 21.081208 },
    { lat: 49.043303, lng: 21.081025 },
    { lat: 49.043419, lng: 21.081058 },
    { lat: 49.043589, lng: 21.080966 },
    { lat: 49.043807, lng: 21.080758 },
    { lat: 49.043831, lng: 21.080882 },
    { lat: 49.043869, lng: 21.08104 },
    { lat: 49.043483, lng: 21.082209 },
    { lat: 49.043846, lng: 21.082497 },
    { lat: 49.043727, lng: 21.082664 },
    { lat: 49.043529, lng: 21.083081 },
    { lat: 49.043188, lng: 21.083611 },
    { lat: 49.042697, lng: 21.084557 },
    { lat: 49.04236, lng: 21.085224 },
    { lat: 49.042105, lng: 21.085533 },
    { lat: 49.041459, lng: 21.087011 },
    { lat: 49.041341, lng: 21.087058 },
    { lat: 49.040735, lng: 21.089027 },
    { lat: 49.040554, lng: 21.08946 },
    { lat: 49.040567, lng: 21.089699 },
    { lat: 49.040795, lng: 21.090244 },
    { lat: 49.04085, lng: 21.090516 },
    { lat: 49.040975, lng: 21.090713 },
    { lat: 49.041447, lng: 21.09121 },
    { lat: 49.041723, lng: 21.091399 },
    { lat: 49.042095, lng: 21.091471 },
    { lat: 49.042243, lng: 21.091462 },
    { lat: 49.042249, lng: 21.091485 },
    { lat: 49.042432, lng: 21.091699 },
    { lat: 49.042534, lng: 21.091777 },
    { lat: 49.04283, lng: 21.091638 },
    { lat: 49.043094, lng: 21.091484 },
    { lat: 49.043386, lng: 21.091267 },
    { lat: 49.04359, lng: 21.091033 },
    { lat: 49.044361, lng: 21.090589 },
    { lat: 49.044404, lng: 21.090564 },
    { lat: 49.044484, lng: 21.090738 },
    { lat: 49.044756, lng: 21.091417 },
    { lat: 49.044958, lng: 21.091946 },
    { lat: 49.045063, lng: 21.092215 },
    { lat: 49.045262, lng: 21.092732 },
    { lat: 49.045391, lng: 21.093103 },
    { lat: 49.045471, lng: 21.093308 },
    { lat: 49.045445, lng: 21.093345 },
    { lat: 49.045408, lng: 21.093328 },
    { lat: 49.045373, lng: 21.093368 },
    { lat: 49.04506, lng: 21.093505 },
    { lat: 49.044907, lng: 21.093563 },
    { lat: 49.044809, lng: 21.093592 },
    { lat: 49.04467, lng: 21.093607 },
    { lat: 49.044577, lng: 21.093666 },
    { lat: 49.044551, lng: 21.093787 },
    { lat: 49.044373, lng: 21.094319 },
    { lat: 49.044308, lng: 21.094365 },
    { lat: 49.044211, lng: 21.094277 },
    { lat: 49.044037, lng: 21.09408 },
    { lat: 49.043929, lng: 21.093933 },
    { lat: 49.043792, lng: 21.094061 },
    { lat: 49.043785, lng: 21.094154 },
    { lat: 49.043717, lng: 21.094627 },
    { lat: 49.04371, lng: 21.094703 },
    { lat: 49.043659, lng: 21.094681 },
    { lat: 49.043619, lng: 21.094621 },
    { lat: 49.043452, lng: 21.094611 },
    { lat: 49.043178, lng: 21.094558 },
    { lat: 49.043075, lng: 21.094503 },
    { lat: 49.043026, lng: 21.094462 },
    { lat: 49.042914, lng: 21.094405 },
    { lat: 49.042861, lng: 21.094467 },
    { lat: 49.042851, lng: 21.094563 },
    { lat: 49.04281, lng: 21.094727 },
    { lat: 49.042745, lng: 21.094854 },
    { lat: 49.042696, lng: 21.094893 },
    { lat: 49.042489, lng: 21.095609 },
    { lat: 49.042452, lng: 21.095669 },
    { lat: 49.042401, lng: 21.095608 },
    { lat: 49.042351, lng: 21.095431 },
    { lat: 49.042241, lng: 21.095068 },
    { lat: 49.042213, lng: 21.094894 },
    { lat: 49.042177, lng: 21.094871 },
    { lat: 49.042042, lng: 21.094971 },
    { lat: 49.04183, lng: 21.095225 },
    { lat: 49.041749, lng: 21.095261 },
    { lat: 49.041706, lng: 21.095472 },
    { lat: 49.041654, lng: 21.095632 },
    { lat: 49.041581, lng: 21.095938 },
    { lat: 49.041578, lng: 21.096084 },
    { lat: 49.041498, lng: 21.096285 },
    { lat: 49.041409, lng: 21.096357 },
    { lat: 49.041321, lng: 21.096407 },
    { lat: 49.041019, lng: 21.096317 },
    { lat: 49.040964, lng: 21.096224 },
    { lat: 49.040919, lng: 21.095962 },
    { lat: 49.040899, lng: 21.095675 },
    { lat: 49.040863, lng: 21.095566 },
    { lat: 49.040664, lng: 21.095813 },
    { lat: 49.040426, lng: 21.095904 },
    { lat: 49.040057, lng: 21.096218 },
    { lat: 49.039622, lng: 21.096745 },
    { lat: 49.039535, lng: 21.09677 },
    { lat: 49.039538, lng: 21.096848 },
    { lat: 49.040077, lng: 21.097979 },
    { lat: 49.040132, lng: 21.098085 },
    { lat: 49.040187, lng: 21.09819 },
    { lat: 49.040242, lng: 21.098296 },
    { lat: 49.040295, lng: 21.098398 },
    { lat: 49.040411, lng: 21.09862 },
    { lat: 49.040513, lng: 21.098814 },
    { lat: 49.040653, lng: 21.09888 },
    { lat: 49.03993, lng: 21.099392 },
    { lat: 49.039582, lng: 21.099652 },
    { lat: 49.039425, lng: 21.099785 },
    { lat: 49.039404, lng: 21.099847 },
    { lat: 49.039584, lng: 21.100144 },
    { lat: 49.039646, lng: 21.100437 },
    { lat: 49.039798, lng: 21.100689 },
    { lat: 49.040129, lng: 21.100741 },
    { lat: 49.040386, lng: 21.100921 },
    { lat: 49.04049, lng: 21.101143 },
    { lat: 49.040733, lng: 21.101416 },
    { lat: 49.041199, lng: 21.102192 },
    { lat: 49.041403, lng: 21.102267 },
    { lat: 49.041737, lng: 21.102165 },
    { lat: 49.041736, lng: 21.102273 },
    { lat: 49.041753, lng: 21.102349 },
    { lat: 49.041829, lng: 21.102406 },
    { lat: 49.041912, lng: 21.102351 },
    { lat: 49.04198, lng: 21.102364 },
    { lat: 49.042066, lng: 21.102513 },
    { lat: 49.04222, lng: 21.102679 },
    { lat: 49.042366, lng: 21.10272 },
    { lat: 49.042476, lng: 21.102757 },
    { lat: 49.042549, lng: 21.1027 },
    { lat: 49.042684, lng: 21.102875 },
    { lat: 49.042804, lng: 21.102935 },
    { lat: 49.042869, lng: 21.103026 },
    { lat: 49.043002, lng: 21.103019 },
    { lat: 49.043194, lng: 21.103212 },
    { lat: 49.043288, lng: 21.103172 },
    { lat: 49.043415, lng: 21.103244 },
    { lat: 49.043431, lng: 21.103299 },
    { lat: 49.043745, lng: 21.103178 },
    { lat: 49.043982, lng: 21.103459 },
    { lat: 49.044107, lng: 21.10346 },
    { lat: 49.044224, lng: 21.103625 },
    { lat: 49.044225, lng: 21.103969 },
    { lat: 49.04432, lng: 21.104024 },
    { lat: 49.044409, lng: 21.10418 },
    { lat: 49.044457, lng: 21.104402 },
    { lat: 49.044549, lng: 21.104455 },
    { lat: 49.044624, lng: 21.104588 },
    { lat: 49.044786, lng: 21.104667 },
    { lat: 49.044828, lng: 21.104949 },
    { lat: 49.044932, lng: 21.105048 },
    { lat: 49.044942, lng: 21.105192 },
    { lat: 49.04519, lng: 21.105658 },
    { lat: 49.045315, lng: 21.106014 },
    { lat: 49.045566, lng: 21.106419 },
    { lat: 49.04572, lng: 21.106619 },
    { lat: 49.045931, lng: 21.106667 },
    { lat: 49.046281, lng: 21.106626 },
    { lat: 49.046396, lng: 21.106528 },
    { lat: 49.046628, lng: 21.106313 },
    { lat: 49.04695, lng: 21.106467 },
    { lat: 49.047275, lng: 21.106383 },
    { lat: 49.047431, lng: 21.106227 },
    { lat: 49.047614, lng: 21.106363 },
    { lat: 49.047787, lng: 21.10624 },
    { lat: 49.0478188, lng: 21.106109 },
    { lat: 49.0481838, lng: 21.1058423 },
    { lat: 49.0485892, lng: 21.1057764 },
    { lat: 49.0503412, lng: 21.1046056 },
    { lat: 49.0503783, lng: 21.1047108 },
    { lat: 49.050534, lng: 21.1046189 },
    { lat: 49.0507407, lng: 21.1043256 },
    { lat: 49.0507964, lng: 21.1038561 },
    { lat: 49.0510231, lng: 21.1029538 },
    { lat: 49.0507866, lng: 21.1024881 },
    { lat: 49.0507091, lng: 21.1020708 },
    { lat: 49.0507245, lng: 21.101435 },
    { lat: 49.0510864, lng: 21.1007466 },
    { lat: 49.0514877, lng: 21.1002425 },
    { lat: 49.0515632, lng: 21.1000679 },
    { lat: 49.0516538, lng: 21.0988262 },
    { lat: 49.052437, lng: 21.0962943 },
    { lat: 49.0526559, lng: 21.0957165 },
    { lat: 49.0533221, lng: 21.0947309 },
    { lat: 49.0538709, lng: 21.0941968 },
    { lat: 49.0547655, lng: 21.0927156 },
    { lat: 49.0552989, lng: 21.0923318 },
    { lat: 49.0556006, lng: 21.0919147 },
    { lat: 49.0556941, lng: 21.0916326 },
    { lat: 49.0556957, lng: 21.0910221 },
    { lat: 49.0561393, lng: 21.0895535 },
    { lat: 49.056544, lng: 21.088768 },
    { lat: 49.0575797, lng: 21.087345 },
    { lat: 49.0578261, lng: 21.0868146 },
    { lat: 49.0582656, lng: 21.0862049 },
    { lat: 49.0584618, lng: 21.0857263 },
    { lat: 49.0587431, lng: 21.0852701 },
    { lat: 49.0592998, lng: 21.0841015 },
    { lat: 49.0594219, lng: 21.0839101 },
    { lat: 49.0591132, lng: 21.0833464 },
    { lat: 49.0583708, lng: 21.0817116 },
    { lat: 49.0574067, lng: 21.0794535 },
    { lat: 49.0570688, lng: 21.0787758 },
    { lat: 49.0568247, lng: 21.0785679 },
    { lat: 49.0567884, lng: 21.0783212 },
    { lat: 49.0568592, lng: 21.0782783 },
    { lat: 49.0569693, lng: 21.0782044 },
    { lat: 49.0577513, lng: 21.0782333 },
    { lat: 49.0577594, lng: 21.0781031 },
    { lat: 49.0578644, lng: 21.078012 },
    { lat: 49.0581285, lng: 21.0779575 },
    { lat: 49.0592566, lng: 21.0779782 },
    { lat: 49.0594076, lng: 21.0779446 },
    { lat: 49.0596026, lng: 21.0777439 },
    { lat: 49.0599783, lng: 21.0777079 },
    { lat: 49.0602186, lng: 21.0777959 },
    { lat: 49.0603471, lng: 21.0776646 },
    { lat: 49.0603698, lng: 21.0774914 },
    { lat: 49.0608079, lng: 21.0774289 },
    { lat: 49.0613218, lng: 21.0771387 },
    { lat: 49.0617918, lng: 21.0769561 },
    { lat: 49.0620461, lng: 21.0764311 },
    { lat: 49.0623079, lng: 21.0760653 },
    { lat: 49.0624964, lng: 21.0759066 },
    { lat: 49.0628349, lng: 21.0759685 },
    { lat: 49.0629641, lng: 21.075932 },
    { lat: 49.063673, lng: 21.0752675 },
    { lat: 49.0638874, lng: 21.0753843 },
    { lat: 49.064071, lng: 21.0752862 },
    { lat: 49.0643405, lng: 21.0750569 },
    { lat: 49.0644646, lng: 21.0748244 },
    { lat: 49.0646205, lng: 21.074737 },
    { lat: 49.0654387, lng: 21.0744943 },
    { lat: 49.0660156, lng: 21.0742015 },
    { lat: 49.0664082, lng: 21.0740917 },
    { lat: 49.0674792, lng: 21.0735538 },
    { lat: 49.0685626, lng: 21.0732335 },
    { lat: 49.0689135, lng: 21.0728727 },
    { lat: 49.0692132, lng: 21.0727491 },
    { lat: 49.0694207, lng: 21.0724494 },
    { lat: 49.0696188, lng: 21.0722723 },
    { lat: 49.0697957, lng: 21.0719684 },
    { lat: 49.0701782, lng: 21.0716451 },
    { lat: 49.0709557, lng: 21.0711541 },
    { lat: 49.0712976, lng: 21.0711103 },
    { lat: 49.0715322, lng: 21.0709187 },
    { lat: 49.0722165, lng: 21.0706762 },
    { lat: 49.072609, lng: 21.0704577 },
    { lat: 49.073035, lng: 21.0699585 },
    { lat: 49.072748, lng: 21.0691177 },
    { lat: 49.0727941, lng: 21.069012 },
    { lat: 49.0728013, lng: 21.0687824 },
    { lat: 49.0727385, lng: 21.0683331 },
    { lat: 49.0729168, lng: 21.0669532 },
    { lat: 49.0730203, lng: 21.0666109 },
    { lat: 49.0729661, lng: 21.0662169 },
    { lat: 49.0732963, lng: 21.0650159 },
    { lat: 49.0743977, lng: 21.0632948 },
    { lat: 49.0745389, lng: 21.0632789 },
    { lat: 49.0755428, lng: 21.0623737 },
    { lat: 49.0777223, lng: 21.0589555 },
    { lat: 49.0780994, lng: 21.058579 },
    { lat: 49.0783055, lng: 21.0585416 },
    { lat: 49.0785633, lng: 21.0582656 },
    { lat: 49.0789002, lng: 21.0573443 },
    { lat: 49.0799426, lng: 21.0565168 },
    { lat: 49.0799554, lng: 21.0562556 },
    { lat: 49.0801845, lng: 21.0563091 },
    { lat: 49.0807361, lng: 21.0556553 },
    { lat: 49.080901, lng: 21.0556705 },
    { lat: 49.0809888, lng: 21.055702 },
    { lat: 49.0814581, lng: 21.0555354 },
    { lat: 49.0817475, lng: 21.0551575 },
    { lat: 49.0822766, lng: 21.0547763 },
    { lat: 49.0824012, lng: 21.0545986 },
    { lat: 49.0823688, lng: 21.0544646 },
    { lat: 49.0825944, lng: 21.0543069 },
    { lat: 49.0835973, lng: 21.0532719 },
    { lat: 49.0846149, lng: 21.0524785 },
    { lat: 49.0849386, lng: 21.052885 },
    { lat: 49.0852681, lng: 21.0530096 },
    { lat: 49.0853725, lng: 21.0531098 },
    { lat: 49.0854465, lng: 21.0532756 },
    { lat: 49.0854654, lng: 21.0535089 },
    { lat: 49.0853964, lng: 21.0538111 },
    { lat: 49.0864747, lng: 21.0553433 },
    { lat: 49.0870199, lng: 21.0546629 },
    { lat: 49.087599, lng: 21.0543059 },
    { lat: 49.0882884, lng: 21.0533166 },
    { lat: 49.0887847, lng: 21.0530736 },
    { lat: 49.0892412, lng: 21.0525036 },
    { lat: 49.0896612, lng: 21.0521991 },
    { lat: 49.0898495, lng: 21.0518298 },
    { lat: 49.0897789, lng: 21.0515967 },
    { lat: 49.0897786, lng: 21.0513102 },
    { lat: 49.0894477, lng: 21.0511333 },
    { lat: 49.0895664, lng: 21.0505997 },
    { lat: 49.0897515, lng: 21.0503711 },
    { lat: 49.0897108, lng: 21.050186 },
    { lat: 49.0897522, lng: 21.050045 },
    { lat: 49.0897278, lng: 21.049882 },
    { lat: 49.0895836, lng: 21.0496674 },
    { lat: 49.0902706, lng: 21.049131 },
    { lat: 49.0902097, lng: 21.0465287 },
    { lat: 49.0899672, lng: 21.0463365 },
    { lat: 49.0898225, lng: 21.0463836 },
    { lat: 49.0897096, lng: 21.0463412 },
    { lat: 49.0895222, lng: 21.0460102 },
    { lat: 49.0891371, lng: 21.0455878 },
    { lat: 49.0889168, lng: 21.0454942 },
    { lat: 49.088442, lng: 21.0451145 },
    { lat: 49.0883055, lng: 21.0449334 },
    { lat: 49.0880034, lng: 21.0449479 },
    { lat: 49.087897, lng: 21.0447225 },
    { lat: 49.0879495, lng: 21.0432518 },
    { lat: 49.0880422, lng: 21.0430297 },
    { lat: 49.0880073, lng: 21.0422941 },
    { lat: 49.0879561, lng: 21.0421411 },
    { lat: 49.0879412, lng: 21.0418593 },
    { lat: 49.0880073, lng: 21.0406331 },
    { lat: 49.087992, lng: 21.0401643 },
    { lat: 49.0878104, lng: 21.0393582 },
    { lat: 49.087723, lng: 21.0392327 },
    { lat: 49.087649, lng: 21.0384437 },
    { lat: 49.0874427, lng: 21.0385027 },
    { lat: 49.0866738, lng: 21.0398112 },
    { lat: 49.0865586, lng: 21.0395916 },
    { lat: 49.0865516, lng: 21.0394474 },
    { lat: 49.0863023, lng: 21.0391491 },
    { lat: 49.0861884, lng: 21.039106 },
    { lat: 49.0856289, lng: 21.0387928 },
    { lat: 49.0851168, lng: 21.0378903 },
    { lat: 49.0858346, lng: 21.0368427 },
    { lat: 49.0861063, lng: 21.0361698 },
    { lat: 49.0861734, lng: 21.0351885 },
    { lat: 49.08592, lng: 21.0348734 },
    { lat: 49.0859975, lng: 21.0336259 },
    { lat: 49.0852067, lng: 21.0321478 },
    { lat: 49.0846193, lng: 21.0320504 },
    { lat: 49.0841015, lng: 21.031631 },
    { lat: 49.0835423, lng: 21.031502 },
    { lat: 49.0838253, lng: 21.0312702 },
    { lat: 49.0838009, lng: 21.0311425 },
    { lat: 49.0836336, lng: 21.0309874 },
    { lat: 49.0830307, lng: 21.030791 },
    { lat: 49.0828472, lng: 21.030955 },
    { lat: 49.0825354, lng: 21.0307531 },
    { lat: 49.0812811, lng: 21.032388 },
    { lat: 49.0812113, lng: 21.0322874 },
    { lat: 49.0807249, lng: 21.0315889 },
    { lat: 49.0808255, lng: 21.0307685 },
    { lat: 49.080093, lng: 21.0288087 },
    { lat: 49.0767508, lng: 21.0213805 },
    { lat: 49.0757736, lng: 21.0199931 },
    { lat: 49.0763386, lng: 21.0158956 },
    { lat: 49.076864, lng: 21.0146201 },
    { lat: 49.0774571, lng: 21.0136905 },
    { lat: 49.0792053, lng: 21.0093794 },
    { lat: 49.0815074, lng: 21.0061427 },
    { lat: 49.0817357, lng: 21.0055527 },
    { lat: 49.0818176, lng: 21.0051314 },
    { lat: 49.0820386, lng: 21.0045272 },
    { lat: 49.0824156, lng: 21.0038074 },
    { lat: 49.083278, lng: 21.0024133 },
    { lat: 49.0835874, lng: 21.0011051 },
    { lat: 49.0835344, lng: 21.0007982 },
    { lat: 49.083489, lng: 20.9998272 },
    { lat: 49.0839149, lng: 20.9985066 },
    { lat: 49.0841306, lng: 20.9979813 },
    { lat: 49.0849208, lng: 20.9965513 },
    { lat: 49.0866878, lng: 20.9927566 },
    { lat: 49.08661, lng: 20.992237 },
    { lat: 49.086576, lng: 20.992127 },
    { lat: 49.08654, lng: 20.992012 },
    { lat: 49.08651, lng: 20.991924 },
    { lat: 49.08648, lng: 20.991838 },
    { lat: 49.086441, lng: 20.991738 },
    { lat: 49.086407, lng: 20.991652 },
    { lat: 49.086171, lng: 20.991224 },
    { lat: 49.085901, lng: 20.990976 },
    { lat: 49.085531, lng: 20.99027 },
    { lat: 49.085198, lng: 20.989732 },
    { lat: 49.084807, lng: 20.989205 },
    { lat: 49.084439, lng: 20.988683 },
    { lat: 49.084297, lng: 20.988296 },
    { lat: 49.084097, lng: 20.987953 },
    { lat: 49.083768, lng: 20.987304 },
    { lat: 49.083492, lng: 20.986798 },
    { lat: 49.083446, lng: 20.986728 },
    { lat: 49.083343, lng: 20.986755 },
    { lat: 49.083158, lng: 20.986748 },
    { lat: 49.08294, lng: 20.986834 },
    { lat: 49.082215, lng: 20.98727 },
    { lat: 49.08212, lng: 20.987375 },
    { lat: 49.081667, lng: 20.987723 },
    { lat: 49.081428, lng: 20.987985 },
    { lat: 49.081285, lng: 20.988058 },
    { lat: 49.081054, lng: 20.988305 },
    { lat: 49.081076, lng: 20.98857 },
    { lat: 49.081168, lng: 20.989012 },
    { lat: 49.081153, lng: 20.989038 },
    { lat: 49.080895, lng: 20.989479 },
    { lat: 49.080564, lng: 20.989665 },
    { lat: 49.080418, lng: 20.989826 },
    { lat: 49.080218, lng: 20.990113 },
    { lat: 49.080133, lng: 20.990189 },
    { lat: 49.079804, lng: 20.990338 },
    { lat: 49.079739, lng: 20.990705 },
    { lat: 49.079708, lng: 20.990999 },
    { lat: 49.079566, lng: 20.991198 },
    { lat: 49.079551, lng: 20.991208 },
  ],
  Brezovička: [
    { lat: 49.1347675, lng: 20.8780182 },
    { lat: 49.1349544, lng: 20.8777207 },
    { lat: 49.1349826, lng: 20.8771617 },
    { lat: 49.1350973, lng: 20.8765923 },
    { lat: 49.1350917, lng: 20.876129 },
    { lat: 49.1352357, lng: 20.8744639 },
    { lat: 49.1354679, lng: 20.8736458 },
    { lat: 49.1354912, lng: 20.8732687 },
    { lat: 49.135215, lng: 20.8727269 },
    { lat: 49.1355926, lng: 20.8710868 },
    { lat: 49.1353779, lng: 20.8697706 },
    { lat: 49.1351948, lng: 20.8695488 },
    { lat: 49.1349622, lng: 20.8687002 },
    { lat: 49.1346211, lng: 20.8682954 },
    { lat: 49.1341865, lng: 20.8675943 },
    { lat: 49.133981, lng: 20.8675099 },
    { lat: 49.1339461, lng: 20.8672107 },
    { lat: 49.1338606, lng: 20.8670838 },
    { lat: 49.13392, lng: 20.8669087 },
    { lat: 49.1339486, lng: 20.8665912 },
    { lat: 49.1338305, lng: 20.8661973 },
    { lat: 49.1338245, lng: 20.8659533 },
    { lat: 49.1339482, lng: 20.8657527 },
    { lat: 49.1338892, lng: 20.8651765 },
    { lat: 49.1339612, lng: 20.8651097 },
    { lat: 49.1340725, lng: 20.8645747 },
    { lat: 49.134032, lng: 20.8643755 },
    { lat: 49.134077, lng: 20.8641452 },
    { lat: 49.1342444, lng: 20.8638725 },
    { lat: 49.1345247, lng: 20.8629129 },
    { lat: 49.1344812, lng: 20.8626608 },
    { lat: 49.1345927, lng: 20.8625269 },
    { lat: 49.1345644, lng: 20.861825 },
    { lat: 49.1346685, lng: 20.8616136 },
    { lat: 49.1348021, lng: 20.8608763 },
    { lat: 49.134707, lng: 20.8606584 },
    { lat: 49.1347446, lng: 20.8600796 },
    { lat: 49.1349074, lng: 20.8596042 },
    { lat: 49.1355274, lng: 20.8542518 },
    { lat: 49.1356779, lng: 20.8537741 },
    { lat: 49.1357066, lng: 20.8531363 },
    { lat: 49.135626, lng: 20.8511305 },
    { lat: 49.1357816, lng: 20.8507436 },
    { lat: 49.135806, lng: 20.8505044 },
    { lat: 49.1356982, lng: 20.8498185 },
    { lat: 49.1356496, lng: 20.8487555 },
    { lat: 49.13549, lng: 20.8481693 },
    { lat: 49.1353859, lng: 20.8480579 },
    { lat: 49.1324435, lng: 20.8392213 },
    { lat: 49.1316172, lng: 20.838433 },
    { lat: 49.1311707, lng: 20.8381105 },
    { lat: 49.1309848, lng: 20.8380736 },
    { lat: 49.1312409, lng: 20.8369426 },
    { lat: 49.1315913, lng: 20.8358325 },
    { lat: 49.1316687, lng: 20.8353997 },
    { lat: 49.1316148, lng: 20.8350722 },
    { lat: 49.1315077, lng: 20.8348033 },
    { lat: 49.1314255, lng: 20.8347714 },
    { lat: 49.1312615, lng: 20.8349127 },
    { lat: 49.1306537, lng: 20.8348798 },
    { lat: 49.1293435, lng: 20.8340881 },
    { lat: 49.1290484, lng: 20.8337572 },
    { lat: 49.1286371, lng: 20.8334495 },
    { lat: 49.1284599, lng: 20.8334324 },
    { lat: 49.1277714, lng: 20.8337402 },
    { lat: 49.1275974, lng: 20.8337145 },
    { lat: 49.1268732, lng: 20.8331127 },
    { lat: 49.1264285, lng: 20.8329105 },
    { lat: 49.1263426, lng: 20.8321816 },
    { lat: 49.1262026, lng: 20.8317101 },
    { lat: 49.1258085, lng: 20.8312427 },
    { lat: 49.1255579, lng: 20.8316159 },
    { lat: 49.124794, lng: 20.8316591 },
    { lat: 49.1244537, lng: 20.8312898 },
    { lat: 49.1243194, lng: 20.8309501 },
    { lat: 49.1240235, lng: 20.830596 },
    { lat: 49.123819, lng: 20.8304707 },
    { lat: 49.1236562, lng: 20.8304491 },
    { lat: 49.1233285, lng: 20.8305769 },
    { lat: 49.123114, lng: 20.830492 },
    { lat: 49.1229945, lng: 20.8303559 },
    { lat: 49.1226395, lng: 20.8291322 },
    { lat: 49.1225332, lng: 20.8285233 },
    { lat: 49.1222904, lng: 20.8265676 },
    { lat: 49.1222751, lng: 20.8259927 },
    { lat: 49.122202, lng: 20.8256277 },
    { lat: 49.1221068, lng: 20.825419 },
    { lat: 49.1217907, lng: 20.8249982 },
    { lat: 49.1215179, lng: 20.824293 },
    { lat: 49.1215183, lng: 20.823751 },
    { lat: 49.1212762, lng: 20.8225661 },
    { lat: 49.1211509, lng: 20.8211506 },
    { lat: 49.1209658, lng: 20.8206116 },
    { lat: 49.1204478, lng: 20.8203076 },
    { lat: 49.1202993, lng: 20.8202767 },
    { lat: 49.1200631, lng: 20.8200541 },
    { lat: 49.1194075, lng: 20.8191854 },
    { lat: 49.1190244, lng: 20.8184912 },
    { lat: 49.1187169, lng: 20.8182553 },
    { lat: 49.1183181, lng: 20.8180827 },
    { lat: 49.1177384, lng: 20.8173589 },
    { lat: 49.117488, lng: 20.8172187 },
    { lat: 49.1172874, lng: 20.8171456 },
    { lat: 49.1161457, lng: 20.8175871 },
    { lat: 49.1154607, lng: 20.817409 },
    { lat: 49.1146819, lng: 20.8170185 },
    { lat: 49.1137676, lng: 20.8166801 },
    { lat: 49.1126817, lng: 20.8157774 },
    { lat: 49.1125814, lng: 20.8155084 },
    { lat: 49.1119086, lng: 20.8142788 },
    { lat: 49.1118138, lng: 20.8140399 },
    { lat: 49.1117721, lng: 20.8136785 },
    { lat: 49.1118426, lng: 20.8131084 },
    { lat: 49.1117709, lng: 20.8120155 },
    { lat: 49.1125743, lng: 20.8008518 },
    { lat: 49.1124356, lng: 20.7994796 },
    { lat: 49.1124845, lng: 20.7994606 },
    { lat: 49.1110215, lng: 20.7948466 },
    { lat: 49.1105845, lng: 20.7934118 },
    { lat: 49.1107, lng: 20.790183 },
    { lat: 49.110045, lng: 20.791516 },
    { lat: 49.109573, lng: 20.79257 },
    { lat: 49.109085, lng: 20.79336 },
    { lat: 49.109028, lng: 20.793624 },
    { lat: 49.108864, lng: 20.793973 },
    { lat: 49.108341, lng: 20.794841 },
    { lat: 49.107661, lng: 20.795603 },
    { lat: 49.107385, lng: 20.795925 },
    { lat: 49.107158, lng: 20.796334 },
    { lat: 49.106903, lng: 20.796898 },
    { lat: 49.106877, lng: 20.797459 },
    { lat: 49.106709, lng: 20.798133 },
    { lat: 49.106546, lng: 20.798398 },
    { lat: 49.106472, lng: 20.798544 },
    { lat: 49.106385, lng: 20.798624 },
    { lat: 49.106273, lng: 20.798862 },
    { lat: 49.106145, lng: 20.798943 },
    { lat: 49.105961, lng: 20.798956 },
    { lat: 49.105693, lng: 20.799226 },
    { lat: 49.105567, lng: 20.799435 },
    { lat: 49.10528, lng: 20.799716 },
    { lat: 49.105166, lng: 20.799858 },
    { lat: 49.105022, lng: 20.799979 },
    { lat: 49.104892, lng: 20.80011 },
    { lat: 49.104769, lng: 20.800235 },
    { lat: 49.1043, lng: 20.800726 },
    { lat: 49.104101, lng: 20.801563 },
    { lat: 49.103421, lng: 20.802191 },
    { lat: 49.10315, lng: 20.802501 },
    { lat: 49.102709, lng: 20.802684 },
    { lat: 49.102475, lng: 20.802737 },
    { lat: 49.10216, lng: 20.802878 },
    { lat: 49.101979, lng: 20.802997 },
    { lat: 49.101846, lng: 20.802997 },
    { lat: 49.101104, lng: 20.803325 },
    { lat: 49.100509, lng: 20.80402 },
    { lat: 49.09965, lng: 20.804798 },
    { lat: 49.09897, lng: 20.805443 },
    { lat: 49.0996337, lng: 20.8065318 },
    { lat: 49.0993908, lng: 20.8072902 },
    { lat: 49.0994359, lng: 20.8077754 },
    { lat: 49.1018682, lng: 20.8072531 },
    { lat: 49.1031648, lng: 20.8075466 },
    { lat: 49.1051502, lng: 20.8117471 },
    { lat: 49.1056951, lng: 20.8118177 },
    { lat: 49.1059871, lng: 20.8122891 },
    { lat: 49.1064362, lng: 20.8127766 },
    { lat: 49.1074264, lng: 20.8162357 },
    { lat: 49.1090966, lng: 20.8189419 },
    { lat: 49.1107376, lng: 20.8224436 },
    { lat: 49.1134966, lng: 20.8250336 },
    { lat: 49.1142368, lng: 20.833845 },
    { lat: 49.117876, lng: 20.8360841 },
    { lat: 49.1176309, lng: 20.8375366 },
    { lat: 49.1177169, lng: 20.8405786 },
    { lat: 49.117493, lng: 20.8423549 },
    { lat: 49.1173742, lng: 20.8432619 },
    { lat: 49.1166874, lng: 20.8454289 },
    { lat: 49.1167182, lng: 20.8459284 },
    { lat: 49.1169361, lng: 20.8465417 },
    { lat: 49.1169607, lng: 20.8471316 },
    { lat: 49.1165781, lng: 20.847661 },
    { lat: 49.1165899, lng: 20.8479443 },
    { lat: 49.1168473, lng: 20.8481973 },
    { lat: 49.1167149, lng: 20.8498244 },
    { lat: 49.1165993, lng: 20.8533272 },
    { lat: 49.1164646, lng: 20.8542311 },
    { lat: 49.1165738, lng: 20.8544265 },
    { lat: 49.1164409, lng: 20.857004 },
    { lat: 49.1161438, lng: 20.8574978 },
    { lat: 49.1159342, lng: 20.858413 },
    { lat: 49.1158344, lng: 20.8595815 },
    { lat: 49.1155891, lng: 20.8605451 },
    { lat: 49.1153334, lng: 20.8608693 },
    { lat: 49.1151531, lng: 20.8615871 },
    { lat: 49.1140921, lng: 20.8685532 },
    { lat: 49.1123789, lng: 20.8752785 },
    { lat: 49.1122231, lng: 20.8757262 },
    { lat: 49.1125938, lng: 20.8757028 },
    { lat: 49.1137291, lng: 20.875824 },
    { lat: 49.1141031, lng: 20.8757396 },
    { lat: 49.1148429, lng: 20.8757773 },
    { lat: 49.1151671, lng: 20.8756511 },
    { lat: 49.1152842, lng: 20.8754537 },
    { lat: 49.1169965, lng: 20.8761262 },
    { lat: 49.1171721, lng: 20.8759924 },
    { lat: 49.1172466, lng: 20.8760311 },
    { lat: 49.1175721, lng: 20.8754413 },
    { lat: 49.1176712, lng: 20.8753721 },
    { lat: 49.1178655, lng: 20.8753999 },
    { lat: 49.1180275, lng: 20.8752955 },
    { lat: 49.1179126, lng: 20.87473 },
    { lat: 49.1179577, lng: 20.874531 },
    { lat: 49.1180424, lng: 20.8744061 },
    { lat: 49.1186978, lng: 20.8740854 },
    { lat: 49.1188269, lng: 20.873907 },
    { lat: 49.1189561, lng: 20.8739324 },
    { lat: 49.119239, lng: 20.8737851 },
    { lat: 49.1196842, lng: 20.8733016 },
    { lat: 49.11986, lng: 20.8733097 },
    { lat: 49.119993, lng: 20.8731846 },
    { lat: 49.1204505, lng: 20.8730666 },
    { lat: 49.1207614, lng: 20.8728096 },
    { lat: 49.1209589, lng: 20.8727229 },
    { lat: 49.1217938, lng: 20.8727847 },
    { lat: 49.1223247, lng: 20.8725765 },
    { lat: 49.1227451, lng: 20.8725922 },
    { lat: 49.1230672, lng: 20.8726948 },
    { lat: 49.1233951, lng: 20.8726765 },
    { lat: 49.1237276, lng: 20.8729521 },
    { lat: 49.1238354, lng: 20.8729447 },
    { lat: 49.1240993, lng: 20.8727277 },
    { lat: 49.1242145, lng: 20.8727043 },
    { lat: 49.1243325, lng: 20.8728039 },
    { lat: 49.124867, lng: 20.8722712 },
    { lat: 49.1249537, lng: 20.8724976 },
    { lat: 49.1254144, lng: 20.8727082 },
    { lat: 49.1262981, lng: 20.8733807 },
    { lat: 49.1278559, lng: 20.8747695 },
    { lat: 49.1282869, lng: 20.8749322 },
    { lat: 49.1285724, lng: 20.8751954 },
    { lat: 49.1291998, lng: 20.8756046 },
    { lat: 49.1297601, lng: 20.8761589 },
    { lat: 49.1304478, lng: 20.8769917 },
    { lat: 49.1316016, lng: 20.8778911 },
    { lat: 49.13158, lng: 20.8780538 },
    { lat: 49.1326408, lng: 20.8775369 },
    { lat: 49.1329879, lng: 20.8772224 },
    { lat: 49.1331998, lng: 20.8779281 },
    { lat: 49.1342961, lng: 20.8778598 },
    { lat: 49.1345256, lng: 20.8779233 },
    { lat: 49.1347675, lng: 20.8780182 },
  ],
  TichýPotok: [
    { lat: 49.1107, lng: 20.790183 },
    { lat: 49.1105845, lng: 20.7934118 },
    { lat: 49.1109306, lng: 20.7929044 },
    { lat: 49.1115632, lng: 20.7921547 },
    { lat: 49.1116669, lng: 20.79216 },
    { lat: 49.1121734, lng: 20.7919037 },
    { lat: 49.1127825, lng: 20.7918451 },
    { lat: 49.1138738, lng: 20.792149 },
    { lat: 49.1147023, lng: 20.7919272 },
    { lat: 49.114948, lng: 20.7920005 },
    { lat: 49.1155089, lng: 20.7924649 },
    { lat: 49.1160761, lng: 20.7933679 },
    { lat: 49.1162685, lng: 20.7935026 },
    { lat: 49.1169519, lng: 20.793539 },
    { lat: 49.1173107, lng: 20.7933632 },
    { lat: 49.11746, lng: 20.7933922 },
    { lat: 49.1176941, lng: 20.7932231 },
    { lat: 49.1180165, lng: 20.793203 },
    { lat: 49.1187265, lng: 20.793299 },
    { lat: 49.1187995, lng: 20.7933905 },
    { lat: 49.1189226, lng: 20.7933691 },
    { lat: 49.1190655, lng: 20.7934633 },
    { lat: 49.1196425, lng: 20.7935466 },
    { lat: 49.1205291, lng: 20.794188 },
    { lat: 49.1207564, lng: 20.7944788 },
    { lat: 49.1212558, lng: 20.7948275 },
    { lat: 49.1215739, lng: 20.7949755 },
    { lat: 49.1218927, lng: 20.7950473 },
    { lat: 49.1222263, lng: 20.7950295 },
    { lat: 49.1223643, lng: 20.7951125 },
    { lat: 49.1226271, lng: 20.7950325 },
    { lat: 49.1229083, lng: 20.7950861 },
    { lat: 49.1231114, lng: 20.7949113 },
    { lat: 49.1232659, lng: 20.7949165 },
    { lat: 49.1233873, lng: 20.7948478 },
    { lat: 49.1238973, lng: 20.794203 },
    { lat: 49.1244631, lng: 20.7937364 },
    { lat: 49.1247591, lng: 20.793704 },
    { lat: 49.1253291, lng: 20.7934899 },
    { lat: 49.125581, lng: 20.7936465 },
    { lat: 49.1260154, lng: 20.7936084 },
    { lat: 49.126209, lng: 20.7934785 },
    { lat: 49.1262933, lng: 20.7935418 },
    { lat: 49.1275273, lng: 20.7935196 },
    { lat: 49.1278221, lng: 20.7936163 },
    { lat: 49.128194, lng: 20.7934624 },
    { lat: 49.1283828, lng: 20.7935482 },
    { lat: 49.1284843, lng: 20.7934886 },
    { lat: 49.1286687, lng: 20.7936517 },
    { lat: 49.1292106, lng: 20.7934971 },
    { lat: 49.1299001, lng: 20.7936142 },
    { lat: 49.1299742, lng: 20.7937725 },
    { lat: 49.1301131, lng: 20.7937247 },
    { lat: 49.1302263, lng: 20.7937734 },
    { lat: 49.1304799, lng: 20.7940737 },
    { lat: 49.1306489, lng: 20.79405 },
    { lat: 49.1308024, lng: 20.7941585 },
    { lat: 49.1309257, lng: 20.7943063 },
    { lat: 49.1310057, lng: 20.7945055 },
    { lat: 49.1312502, lng: 20.794543 },
    { lat: 49.1313463, lng: 20.7947146 },
    { lat: 49.1314729, lng: 20.7946994 },
    { lat: 49.1322979, lng: 20.7954483 },
    { lat: 49.1324174, lng: 20.7954862 },
    { lat: 49.1325727, lng: 20.7957156 },
    { lat: 49.1328341, lng: 20.7957841 },
    { lat: 49.1330241, lng: 20.795748 },
    { lat: 49.1333828, lng: 20.7962239 },
    { lat: 49.1334931, lng: 20.7962887 },
    { lat: 49.1337341, lng: 20.7962273 },
    { lat: 49.1340523, lng: 20.7964436 },
    { lat: 49.1342706, lng: 20.796379 },
    { lat: 49.1343708, lng: 20.7965079 },
    { lat: 49.1348727, lng: 20.7965355 },
    { lat: 49.1350963, lng: 20.7967861 },
    { lat: 49.1357373, lng: 20.7968999 },
    { lat: 49.1362527, lng: 20.7971274 },
    { lat: 49.1367115, lng: 20.7972088 },
    { lat: 49.1370245, lng: 20.7971154 },
    { lat: 49.1371782, lng: 20.797487 },
    { lat: 49.1373858, lng: 20.7975706 },
    { lat: 49.137563, lng: 20.7973191 },
    { lat: 49.1379501, lng: 20.7977093 },
    { lat: 49.138389, lng: 20.79771 },
    { lat: 49.1385983, lng: 20.7980663 },
    { lat: 49.1387711, lng: 20.7981797 },
    { lat: 49.138924, lng: 20.7981416 },
    { lat: 49.1390184, lng: 20.7982638 },
    { lat: 49.1391002, lng: 20.7985484 },
    { lat: 49.1397346, lng: 20.799155 },
    { lat: 49.1399131, lng: 20.7992413 },
    { lat: 49.1401447, lng: 20.7991314 },
    { lat: 49.1402144, lng: 20.7991628 },
    { lat: 49.1401899, lng: 20.8075539 },
    { lat: 49.140442, lng: 20.8074227 },
    { lat: 49.1407182, lng: 20.8074638 },
    { lat: 49.1409677, lng: 20.8073259 },
    { lat: 49.1410763, lng: 20.8073416 },
    { lat: 49.1411968, lng: 20.8075042 },
    { lat: 49.1413484, lng: 20.8074284 },
    { lat: 49.1414932, lng: 20.8074819 },
    { lat: 49.1415599, lng: 20.807652 },
    { lat: 49.1417077, lng: 20.8077325 },
    { lat: 49.1417998, lng: 20.8079876 },
    { lat: 49.141805, lng: 20.8081378 },
    { lat: 49.1422207, lng: 20.8084076 },
    { lat: 49.1429816, lng: 20.8086011 },
    { lat: 49.1434541, lng: 20.8086291 },
    { lat: 49.1440112, lng: 20.8087595 },
    { lat: 49.1442185, lng: 20.8089565 },
    { lat: 49.1444331, lng: 20.8095023 },
    { lat: 49.1450905, lng: 20.8100939 },
    { lat: 49.1454424, lng: 20.8102793 },
    { lat: 49.1456573, lng: 20.8103078 },
    { lat: 49.1457894, lng: 20.8101919 },
    { lat: 49.1465394, lng: 20.8101009 },
    { lat: 49.1497014, lng: 20.8119705 },
    { lat: 49.156998, lng: 20.8139849 },
    { lat: 49.1579802, lng: 20.8142329 },
    { lat: 49.1581403, lng: 20.813855 },
    { lat: 49.1584397, lng: 20.8127069 },
    { lat: 49.1586549, lng: 20.8121265 },
    { lat: 49.1589093, lng: 20.8115703 },
    { lat: 49.1592008, lng: 20.8111093 },
    { lat: 49.1600594, lng: 20.8101245 },
    { lat: 49.1611578, lng: 20.8095643 },
    { lat: 49.1624861, lng: 20.808521 },
    { lat: 49.1635655, lng: 20.8073131 },
    { lat: 49.164209, lng: 20.8063911 },
    { lat: 49.165028, lng: 20.8044857 },
    { lat: 49.1663809, lng: 20.800707 },
    { lat: 49.1672355, lng: 20.7985324 },
    { lat: 49.1696122, lng: 20.793726 },
    { lat: 49.1699631, lng: 20.7922579 },
    { lat: 49.1701445, lng: 20.7912822 },
    { lat: 49.1709137, lng: 20.7893483 },
    { lat: 49.1715026, lng: 20.7884062 },
    { lat: 49.1717414, lng: 20.7878665 },
    { lat: 49.172315, lng: 20.787394 },
    { lat: 49.1723083, lng: 20.7871668 },
    { lat: 49.1719867, lng: 20.7864639 },
    { lat: 49.1721259, lng: 20.785803 },
    { lat: 49.1739794, lng: 20.7852421 },
    { lat: 49.1736864, lng: 20.7866622 },
    { lat: 49.173869, lng: 20.7863766 },
    { lat: 49.1740264, lng: 20.7854613 },
    { lat: 49.1743552, lng: 20.7849434 },
    { lat: 49.1744246, lng: 20.7847403 },
    { lat: 49.1743909, lng: 20.7841559 },
    { lat: 49.1744543, lng: 20.783776 },
    { lat: 49.1743977, lng: 20.7824336 },
    { lat: 49.1745291, lng: 20.7817816 },
    { lat: 49.1749401, lng: 20.781298 },
    { lat: 49.1759609, lng: 20.7803512 },
    { lat: 49.1759846, lng: 20.779416 },
    { lat: 49.1773142, lng: 20.7793551 },
    { lat: 49.1791048, lng: 20.7781848 },
    { lat: 49.1792122, lng: 20.7790104 },
    { lat: 49.1800236, lng: 20.7791655 },
    { lat: 49.1813194, lng: 20.7796966 },
    { lat: 49.1819564, lng: 20.7800816 },
    { lat: 49.1824528, lng: 20.7807637 },
    { lat: 49.1827129, lng: 20.7813478 },
    { lat: 49.1830122, lng: 20.7822513 },
    { lat: 49.1836607, lng: 20.7827455 },
    { lat: 49.1839616, lng: 20.7820084 },
    { lat: 49.1842698, lng: 20.7812913 },
    { lat: 49.1843791, lng: 20.7812958 },
    { lat: 49.1849529, lng: 20.7820011 },
    { lat: 49.1852134, lng: 20.7819142 },
    { lat: 49.1858722, lng: 20.7803735 },
    { lat: 49.1858124, lng: 20.7800758 },
    { lat: 49.1862166, lng: 20.7793602 },
    { lat: 49.185991, lng: 20.778699 },
    { lat: 49.18733, lng: 20.7774214 },
    { lat: 49.1864966, lng: 20.7761825 },
    { lat: 49.1860505, lng: 20.7758735 },
    { lat: 49.1858299, lng: 20.7755535 },
    { lat: 49.185574, lng: 20.773598 },
    { lat: 49.1850649, lng: 20.7720664 },
    { lat: 49.1845738, lng: 20.770181 },
    { lat: 49.1845307, lng: 20.7693883 },
    { lat: 49.1843806, lng: 20.7681543 },
    { lat: 49.1843565, lng: 20.767332 },
    { lat: 49.1845253, lng: 20.7670027 },
    { lat: 49.1845943, lng: 20.7652336 },
    { lat: 49.185231, lng: 20.7624452 },
    { lat: 49.1852248, lng: 20.7618829 },
    { lat: 49.1853454, lng: 20.7608822 },
    { lat: 49.1858433, lng: 20.7599282 },
    { lat: 49.1867438, lng: 20.7579929 },
    { lat: 49.1878757, lng: 20.7553961 },
    { lat: 49.1884545, lng: 20.7536931 },
    { lat: 49.189065, lng: 20.7523847 },
    { lat: 49.1911152, lng: 20.7491112 },
    { lat: 49.1912393, lng: 20.7487686 },
    { lat: 49.191249, lng: 20.7484306 },
    { lat: 49.1912639, lng: 20.7478327 },
    { lat: 49.1912918, lng: 20.7474349 },
    { lat: 49.1913637, lng: 20.747044 },
    { lat: 49.191437, lng: 20.7467242 },
    { lat: 49.1916305, lng: 20.7456947 },
    { lat: 49.191752, lng: 20.745245 },
    { lat: 49.1918117, lng: 20.7449573 },
    { lat: 49.1918742, lng: 20.744457 },
    { lat: 49.1919912, lng: 20.7439102 },
    { lat: 49.1920172, lng: 20.7437843 },
    { lat: 49.1920354, lng: 20.7433682 },
    { lat: 49.191959, lng: 20.7427004 },
    { lat: 49.1918244, lng: 20.7423612 },
    { lat: 49.191737, lng: 20.7422082 },
    { lat: 49.1913433, lng: 20.7417969 },
    { lat: 49.1912315, lng: 20.741757 },
    { lat: 49.1910039, lng: 20.7414942 },
    { lat: 49.1907687, lng: 20.7412719 },
    { lat: 49.1904976, lng: 20.7410453 },
    { lat: 49.1900104, lng: 20.7406708 },
    { lat: 49.1894018, lng: 20.7400709 },
    { lat: 49.1887447, lng: 20.7394332 },
    { lat: 49.1884207, lng: 20.7390526 },
    { lat: 49.1880605, lng: 20.7383774 },
    { lat: 49.1879039, lng: 20.7380811 },
    { lat: 49.1876406, lng: 20.7375081 },
    { lat: 49.1872094, lng: 20.7369635 },
    { lat: 49.1866967, lng: 20.7361784 },
    { lat: 49.1865824, lng: 20.7358053 },
    { lat: 49.1862915, lng: 20.7353119 },
    { lat: 49.18606, lng: 20.7347094 },
    { lat: 49.1858945, lng: 20.7342285 },
    { lat: 49.1856428, lng: 20.7337312 },
    { lat: 49.1854849, lng: 20.7333994 },
    { lat: 49.1853451, lng: 20.7331391 },
    { lat: 49.1852303, lng: 20.7328441 },
    { lat: 49.1851397, lng: 20.7324467 },
    { lat: 49.1851319, lng: 20.7322242 },
    { lat: 49.1850498, lng: 20.7320038 },
    { lat: 49.1848545, lng: 20.7315999 },
    { lat: 49.1847332, lng: 20.73106 },
    { lat: 49.1846111, lng: 20.7305043 },
    { lat: 49.1845764, lng: 20.729935 },
    { lat: 49.1845051, lng: 20.7295029 },
    { lat: 49.1843086, lng: 20.7290991 },
    { lat: 49.1842135, lng: 20.7288632 },
    { lat: 49.1839431, lng: 20.728537 },
    { lat: 49.1835024, lng: 20.7280736 },
    { lat: 49.1832939, lng: 20.7278381 },
    { lat: 49.1828618, lng: 20.7275561 },
    { lat: 49.1822977, lng: 20.7271967 },
    { lat: 49.1819522, lng: 20.7269763 },
    { lat: 49.1817166, lng: 20.7268714 },
    { lat: 49.1811267, lng: 20.7267052 },
    { lat: 49.1803541, lng: 20.726277 },
    { lat: 49.1801007, lng: 20.7259203 },
    { lat: 49.1797595, lng: 20.725312 },
    { lat: 49.1792716, lng: 20.7243046 },
    { lat: 49.1788309, lng: 20.7233894 },
    { lat: 49.1786112, lng: 20.7224885 },
    { lat: 49.17838, lng: 20.7215158 },
    { lat: 49.1783346, lng: 20.7210576 },
    { lat: 49.1782208, lng: 20.7199361 },
    { lat: 49.1781249, lng: 20.7194482 },
    { lat: 49.17794, lng: 20.7187394 },
    { lat: 49.1776563, lng: 20.7180046 },
    { lat: 49.1775037, lng: 20.7176055 },
    { lat: 49.1774095, lng: 20.7173272 },
    { lat: 49.1771707, lng: 20.7166495 },
    { lat: 49.1771079, lng: 20.7164658 },
    { lat: 49.1770326, lng: 20.7162431 },
    { lat: 49.1768129, lng: 20.7158586 },
    { lat: 49.1765552, lng: 20.7155084 },
    { lat: 49.1763778, lng: 20.7152945 },
    { lat: 49.1761439, lng: 20.7150162 },
    { lat: 49.176016, lng: 20.7146805 },
    { lat: 49.1758362, lng: 20.7144643 },
    { lat: 49.1754281, lng: 20.7135786 },
    { lat: 49.1750414, lng: 20.7128624 },
    { lat: 49.1746311, lng: 20.7123704 },
    { lat: 49.1742622, lng: 20.7119298 },
    { lat: 49.1742549, lng: 20.7119498 },
    { lat: 49.1742361, lng: 20.711992 },
    { lat: 49.1736642, lng: 20.7115414 },
    { lat: 49.1736039, lng: 20.711456 },
    { lat: 49.1734047, lng: 20.7112055 },
    { lat: 49.1729607, lng: 20.7108426 },
    { lat: 49.1726745, lng: 20.7105459 },
    { lat: 49.1723734, lng: 20.7102084 },
    { lat: 49.1718031, lng: 20.7099951 },
    { lat: 49.1716549, lng: 20.7098206 },
    { lat: 49.1715609, lng: 20.7097362 },
    { lat: 49.1713293, lng: 20.7093303 },
    { lat: 49.1709339, lng: 20.7085257 },
    { lat: 49.1708606, lng: 20.7082349 },
    { lat: 49.1706691, lng: 20.7075621 },
    { lat: 49.1703369, lng: 20.7063505 },
    { lat: 49.1701666, lng: 20.7058911 },
    { lat: 49.1700322, lng: 20.7052787 },
    { lat: 49.1697617, lng: 20.7047578 },
    { lat: 49.1691838, lng: 20.7040455 },
    { lat: 49.1688257, lng: 20.7037494 },
    { lat: 49.1685202, lng: 20.7035152 },
    { lat: 49.1681198, lng: 20.7028938 },
    { lat: 49.1678509, lng: 20.7026738 },
    { lat: 49.1676837, lng: 20.7024933 },
    { lat: 49.1674031, lng: 20.702197 },
    { lat: 49.1672661, lng: 20.7021108 },
    { lat: 49.1670515, lng: 20.701772 },
    { lat: 49.1669607, lng: 20.7016582 },
    { lat: 49.1663153, lng: 20.700911 },
    { lat: 49.1652599, lng: 20.6996917 },
    { lat: 49.1648976, lng: 20.6992773 },
    { lat: 49.1648531, lng: 20.698753 },
    { lat: 49.1647137, lng: 20.6971343 },
    { lat: 49.1651011, lng: 20.6961593 },
    { lat: 49.1654805, lng: 20.6952039 },
    { lat: 49.1658193, lng: 20.694348 },
    { lat: 49.1658526, lng: 20.6942686 },
    { lat: 49.1658753, lng: 20.6942088 },
    { lat: 49.1661325, lng: 20.6932736 },
    { lat: 49.1662164, lng: 20.6929717 },
    { lat: 49.16645, lng: 20.6921359 },
    { lat: 49.1668842, lng: 20.6920616 },
    { lat: 49.1671512, lng: 20.6920676 },
    { lat: 49.1674337, lng: 20.6920652 },
    { lat: 49.1676036, lng: 20.6918735 },
    { lat: 49.1677918, lng: 20.6915943 },
    { lat: 49.1680928, lng: 20.6915418 },
    { lat: 49.1693403, lng: 20.6906946 },
    { lat: 49.1695643, lng: 20.6905988 },
    { lat: 49.169584, lng: 20.6906173 },
    { lat: 49.1698422, lng: 20.690087 },
    { lat: 49.1698743, lng: 20.6899746 },
    { lat: 49.1698913, lng: 20.6899174 },
    { lat: 49.1699835, lng: 20.6895961 },
    { lat: 49.1700973, lng: 20.6888072 },
    { lat: 49.1701674, lng: 20.6882125 },
    { lat: 49.1702188, lng: 20.6871556 },
    { lat: 49.1702578, lng: 20.6866049 },
    { lat: 49.170285, lng: 20.6862033 },
    { lat: 49.1703676, lng: 20.6859253 },
    { lat: 49.1703839, lng: 20.6858793 },
    { lat: 49.1705628, lng: 20.6853695 },
    { lat: 49.1705799, lng: 20.6853135 },
    { lat: 49.1706483, lng: 20.6850819 },
    { lat: 49.1708031, lng: 20.6846849 },
    { lat: 49.1708485, lng: 20.6845678 },
    { lat: 49.170893, lng: 20.6844584 },
    { lat: 49.1712522, lng: 20.6838614 },
    { lat: 49.1713769, lng: 20.6835647 },
    { lat: 49.1717572, lng: 20.6802365 },
    { lat: 49.1714519, lng: 20.6797254 },
    { lat: 49.1711934, lng: 20.6792607 },
    { lat: 49.1707221, lng: 20.678418 },
    { lat: 49.1703761, lng: 20.6778034 },
    { lat: 49.1701653, lng: 20.6770373 },
    { lat: 49.1701085, lng: 20.6766733 },
    { lat: 49.1700208, lng: 20.6761111 },
    { lat: 49.1699044, lng: 20.6752138 },
    { lat: 49.1697289, lng: 20.673889 },
    { lat: 49.1696187, lng: 20.6732927 },
    { lat: 49.1695128, lng: 20.672623 },
    { lat: 49.1693676, lng: 20.6717069 },
    { lat: 49.1691931, lng: 20.6706041 },
    { lat: 49.1690354, lng: 20.6696872 },
    { lat: 49.1687857, lng: 20.6690881 },
    { lat: 49.1683332, lng: 20.6681709 },
    { lat: 49.1682649, lng: 20.6677729 },
    { lat: 49.168007, lng: 20.6668572 },
    { lat: 49.1678089, lng: 20.6664474 },
    { lat: 49.1674876, lng: 20.6659917 },
    { lat: 49.1673442, lng: 20.6657881 },
    { lat: 49.167172, lng: 20.6656031 },
    { lat: 49.1668293, lng: 20.6652387 },
    { lat: 49.1666095, lng: 20.6650091 },
    { lat: 49.1663945, lng: 20.6647878 },
    { lat: 49.1660206, lng: 20.6643458 },
    { lat: 49.1655227, lng: 20.6641148 },
    { lat: 49.1651201, lng: 20.6639051 },
    { lat: 49.1647743, lng: 20.6636741 },
    { lat: 49.1643458, lng: 20.6634679 },
    { lat: 49.1642687, lng: 20.6633898 },
    { lat: 49.1642337, lng: 20.6633535 },
    { lat: 49.1642232, lng: 20.6633432 },
    { lat: 49.1636899, lng: 20.6628152 },
    { lat: 49.1625657, lng: 20.6616939 },
    { lat: 49.1620894, lng: 20.6612184 },
    { lat: 49.1619446, lng: 20.6610747 },
    { lat: 49.1610626, lng: 20.6602689 },
    { lat: 49.1605178, lng: 20.6597639 },
    { lat: 49.1602491, lng: 20.6595229 },
    { lat: 49.1598232, lng: 20.6591303 },
    { lat: 49.1594598, lng: 20.658698 },
    { lat: 49.1593579, lng: 20.658522 },
    { lat: 49.1590803, lng: 20.6578415 },
    { lat: 49.1589217, lng: 20.6574382 },
    { lat: 49.1586392, lng: 20.6563989 },
    { lat: 49.1585475, lng: 20.6557868 },
    { lat: 49.1582985, lng: 20.6553094 },
    { lat: 49.1578017, lng: 20.6544523 },
    { lat: 49.157565, lng: 20.6539426 },
    { lat: 49.1575537, lng: 20.6539676 },
    { lat: 49.1568426, lng: 20.6535377 },
    { lat: 49.1564101, lng: 20.6532162 },
    { lat: 49.1558996, lng: 20.6528282 },
    { lat: 49.1554208, lng: 20.6524663 },
    { lat: 49.1550897, lng: 20.6522126 },
    { lat: 49.1550282, lng: 20.6521951 },
    { lat: 49.1548574, lng: 20.6524247 },
    { lat: 49.1544454, lng: 20.6529542 },
    { lat: 49.1537233, lng: 20.6539159 },
    { lat: 49.1534707, lng: 20.654252 },
    { lat: 49.1527603, lng: 20.6551911 },
    { lat: 49.1521199, lng: 20.6560413 },
    { lat: 49.1516525, lng: 20.6566663 },
    { lat: 49.1509679, lng: 20.657573 },
    { lat: 49.1506658, lng: 20.6579725 },
    { lat: 49.149493, lng: 20.6595149 },
    { lat: 49.1493293, lng: 20.6597259 },
    { lat: 49.1487568, lng: 20.6604675 },
    { lat: 49.1479005, lng: 20.6615739 },
    { lat: 49.1470268, lng: 20.6627047 },
    { lat: 49.1467502, lng: 20.6630617 },
    { lat: 49.1464411, lng: 20.6634487 },
    { lat: 49.1459238, lng: 20.6640982 },
    { lat: 49.1453292, lng: 20.6648469 },
    { lat: 49.1451633, lng: 20.665088 },
    { lat: 49.1445695, lng: 20.6659506 },
    { lat: 49.1440975, lng: 20.6666387 },
    { lat: 49.1436513, lng: 20.6672895 },
    { lat: 49.1431153, lng: 20.6680728 },
    { lat: 49.1424645, lng: 20.669012 },
    { lat: 49.1419484, lng: 20.6697551 },
    { lat: 49.1414091, lng: 20.6705401 },
    { lat: 49.1407569, lng: 20.6714797 },
    { lat: 49.1404927, lng: 20.6718622 },
    { lat: 49.1397489, lng: 20.6729132 },
    { lat: 49.1394512, lng: 20.6733441 },
    { lat: 49.1391934, lng: 20.6737218 },
    { lat: 49.1390149, lng: 20.6740202 },
    { lat: 49.1387232, lng: 20.6745196 },
    { lat: 49.1381791, lng: 20.6754548 },
    { lat: 49.1377941, lng: 20.6761173 },
    { lat: 49.1375764, lng: 20.676488 },
    { lat: 49.1374737, lng: 20.6767646 },
    { lat: 49.1373384, lng: 20.6771386 },
    { lat: 49.1373025, lng: 20.6772357 },
    { lat: 49.136725, lng: 20.6783081 },
    { lat: 49.1366063, lng: 20.6785182 },
    { lat: 49.1358427, lng: 20.6798967 },
    { lat: 49.1357419, lng: 20.6800999 },
    { lat: 49.1350696, lng: 20.6815168 },
    { lat: 49.1343052, lng: 20.6831322 },
    { lat: 49.1341651, lng: 20.6834303 },
    { lat: 49.1338288, lng: 20.684135 },
    { lat: 49.1336764, lng: 20.6844718 },
    { lat: 49.1335757, lng: 20.6846782 },
    { lat: 49.1335538, lng: 20.6847257 },
    { lat: 49.1334931, lng: 20.6848569 },
    { lat: 49.1334174, lng: 20.6850116 },
    { lat: 49.1333391, lng: 20.6851759 },
    { lat: 49.1329052, lng: 20.6860834 },
    { lat: 49.1325309, lng: 20.6868623 },
    { lat: 49.1322135, lng: 20.6875291 },
    { lat: 49.1320058, lng: 20.6879546 },
    { lat: 49.1318357, lng: 20.6883008 },
    { lat: 49.1314981, lng: 20.6889965 },
    { lat: 49.1311487, lng: 20.6897133 },
    { lat: 49.1308475, lng: 20.6903298 },
    { lat: 49.1305842, lng: 20.6908745 },
    { lat: 49.1303123, lng: 20.6914289 },
    { lat: 49.1301108, lng: 20.6918395 },
    { lat: 49.1299652, lng: 20.6921419 },
    { lat: 49.1299001, lng: 20.6922749 },
    { lat: 49.12981, lng: 20.6924689 },
    { lat: 49.1296428, lng: 20.6928295 },
    { lat: 49.1295278, lng: 20.6930784 },
    { lat: 49.1292854, lng: 20.6936059 },
    { lat: 49.1291953, lng: 20.6937988 },
    { lat: 49.1290113, lng: 20.6941964 },
    { lat: 49.128955, lng: 20.6943175 },
    { lat: 49.1288253, lng: 20.6945981 },
    { lat: 49.1287302, lng: 20.6948029 },
    { lat: 49.1285954, lng: 20.6950991 },
    { lat: 49.1283996, lng: 20.6955127 },
    { lat: 49.1282142, lng: 20.695905 },
    { lat: 49.1279724, lng: 20.6964174 },
    { lat: 49.1277515, lng: 20.6968848 },
    { lat: 49.1274908, lng: 20.6974426 },
    { lat: 49.1271318, lng: 20.6982036 },
    { lat: 49.1269726, lng: 20.6985419 },
    { lat: 49.1267676, lng: 20.6989797 },
    { lat: 49.1267509, lng: 20.6990145 },
    { lat: 49.1265494, lng: 20.6994347 },
    { lat: 49.1264721, lng: 20.699601 },
    { lat: 49.126315, lng: 20.6999345 },
    { lat: 49.1262336, lng: 20.7001137 },
    { lat: 49.1258592, lng: 20.7009176 },
    { lat: 49.1257879, lng: 20.7010691 },
    { lat: 49.1257136, lng: 20.7012283 },
    { lat: 49.1256289, lng: 20.7014099 },
    { lat: 49.125567, lng: 20.7015397 },
    { lat: 49.1254205, lng: 20.7018512 },
    { lat: 49.1253148, lng: 20.7020766 },
    { lat: 49.1252344, lng: 20.7022507 },
    { lat: 49.1246841, lng: 20.7034047 },
    { lat: 49.1244893, lng: 20.7038124 },
    { lat: 49.124245, lng: 20.7043224 },
    { lat: 49.1242345, lng: 20.7043448 },
    { lat: 49.1239413, lng: 20.7049652 },
    { lat: 49.1238555, lng: 20.7051454 },
    { lat: 49.1235323, lng: 20.7054642 },
    { lat: 49.1232309, lng: 20.705761 },
    { lat: 49.1222218, lng: 20.7067607 },
    { lat: 49.1213549, lng: 20.7076185 },
    { lat: 49.1209884, lng: 20.70798 },
    { lat: 49.1203272, lng: 20.7086006 },
    { lat: 49.1201886, lng: 20.7087313 },
    { lat: 49.1200747, lng: 20.7089128 },
    { lat: 49.1198988, lng: 20.7091939 },
    { lat: 49.1196504, lng: 20.7095801 },
    { lat: 49.1196397, lng: 20.7095968 },
    { lat: 49.1195496, lng: 20.7096645 },
    { lat: 49.1194934, lng: 20.7098564 },
    { lat: 49.1195737, lng: 20.7102807 },
    { lat: 49.1194623, lng: 20.7107243 },
    { lat: 49.1194719, lng: 20.7109625 },
    { lat: 49.1194624, lng: 20.7112073 },
    { lat: 49.1194726, lng: 20.7113035 },
    { lat: 49.1193914, lng: 20.7115563 },
    { lat: 49.1193539, lng: 20.7118027 },
    { lat: 49.1193722, lng: 20.712033 },
    { lat: 49.1193056, lng: 20.7123446 },
    { lat: 49.119383, lng: 20.7131353 },
    { lat: 49.1195275, lng: 20.7136244 },
    { lat: 49.1198958, lng: 20.7138555 },
    { lat: 49.1201682, lng: 20.7141415 },
    { lat: 49.1205449, lng: 20.7144485 },
    { lat: 49.1206141, lng: 20.7145567 },
    { lat: 49.120718, lng: 20.7147268 },
    { lat: 49.1208125, lng: 20.7149193 },
    { lat: 49.1208815, lng: 20.7153704 },
    { lat: 49.1209589, lng: 20.7159998 },
    { lat: 49.1208284, lng: 20.7164201 },
    { lat: 49.1207659, lng: 20.7165924 },
    { lat: 49.1206803, lng: 20.7168147 },
    { lat: 49.1205993, lng: 20.7169791 },
    { lat: 49.120499, lng: 20.717163 },
    { lat: 49.1203543, lng: 20.7174695 },
    { lat: 49.1202099, lng: 20.7179349 },
    { lat: 49.1200301, lng: 20.718296 },
    { lat: 49.1198873, lng: 20.7184256 },
    { lat: 49.1196339, lng: 20.7186454 },
    { lat: 49.1193425, lng: 20.7189701 },
    { lat: 49.1190319, lng: 20.7193851 },
    { lat: 49.1188744, lng: 20.7197419 },
    { lat: 49.1187544, lng: 20.7200108 },
    { lat: 49.1185897, lng: 20.720352 },
    { lat: 49.1184248, lng: 20.7210069 },
    { lat: 49.1183601, lng: 20.7220429 },
    { lat: 49.1184824, lng: 20.7230901 },
    { lat: 49.1182909, lng: 20.7239129 },
    { lat: 49.117909, lng: 20.724628 },
    { lat: 49.117893, lng: 20.724657 },
    { lat: 49.117864, lng: 20.72471 },
    { lat: 49.117856, lng: 20.724726 },
    { lat: 49.118164, lng: 20.725175 },
    { lat: 49.118316, lng: 20.725397 },
    { lat: 49.1184, lng: 20.725536 },
    { lat: 49.118427, lng: 20.725582 },
    { lat: 49.118516, lng: 20.726151 },
    { lat: 49.118638, lng: 20.726318 },
    { lat: 49.11927, lng: 20.726514 },
    { lat: 49.119643, lng: 20.726439 },
    { lat: 49.11996, lng: 20.726234 },
    { lat: 49.120261, lng: 20.725869 },
    { lat: 49.120324, lng: 20.725454 },
    { lat: 49.120371, lng: 20.72512 },
    { lat: 49.12039, lng: 20.724789 },
    { lat: 49.120394, lng: 20.724693 },
    { lat: 49.120493, lng: 20.724881 },
    { lat: 49.120496, lng: 20.724856 },
    { lat: 49.120541, lng: 20.724912 },
    { lat: 49.120574, lng: 20.725017 },
    { lat: 49.120572, lng: 20.725183 },
    { lat: 49.12057, lng: 20.72531 },
    { lat: 49.120624, lng: 20.72543 },
    { lat: 49.120904, lng: 20.725772 },
    { lat: 49.120998, lng: 20.725889 },
    { lat: 49.121178, lng: 20.726124 },
    { lat: 49.121353, lng: 20.72619 },
    { lat: 49.121467, lng: 20.726294 },
    { lat: 49.121534, lng: 20.726354 },
    { lat: 49.121723, lng: 20.726534 },
    { lat: 49.121857, lng: 20.726692 },
    { lat: 49.121967, lng: 20.727034 },
    { lat: 49.122082, lng: 20.727525 },
    { lat: 49.122098, lng: 20.727846 },
    { lat: 49.122106, lng: 20.728028 },
    { lat: 49.12211, lng: 20.728538 },
    { lat: 49.122109, lng: 20.728559 },
    { lat: 49.122083, lng: 20.72878 },
    { lat: 49.122178, lng: 20.729096 },
    { lat: 49.122188, lng: 20.729131 },
    { lat: 49.122477, lng: 20.730194 },
    { lat: 49.122576, lng: 20.730408 },
    { lat: 49.122638, lng: 20.730653 },
    { lat: 49.12261, lng: 20.730743 },
    { lat: 49.122492, lng: 20.731126 },
    { lat: 49.12246, lng: 20.731451 },
    { lat: 49.12235, lng: 20.731753 },
    { lat: 49.122288, lng: 20.731966 },
    { lat: 49.122207, lng: 20.732486 },
    { lat: 49.122188, lng: 20.73261 },
    { lat: 49.12208, lng: 20.733373 },
    { lat: 49.122062, lng: 20.733522 },
    { lat: 49.122049, lng: 20.733545 },
    { lat: 49.121973, lng: 20.733674 },
    { lat: 49.121932, lng: 20.733781 },
    { lat: 49.121901, lng: 20.734242 },
    { lat: 49.121916, lng: 20.734455 },
    { lat: 49.121934, lng: 20.734461 },
    { lat: 49.121938, lng: 20.734462 },
    { lat: 49.1219, lng: 20.734795 },
    { lat: 49.121899, lng: 20.734799 },
    { lat: 49.121898, lng: 20.734801 },
    { lat: 49.12182, lng: 20.735018 },
    { lat: 49.121819, lng: 20.735021 },
    { lat: 49.121816, lng: 20.735037 },
    { lat: 49.121712, lng: 20.735766 },
    { lat: 49.121677, lng: 20.735977 },
    { lat: 49.121829, lng: 20.736746 },
    { lat: 49.121822, lng: 20.737151 },
    { lat: 49.121764, lng: 20.737613 },
    { lat: 49.121738, lng: 20.738224 },
    { lat: 49.121834, lng: 20.73857 },
    { lat: 49.121824, lng: 20.738883 },
    { lat: 49.121928, lng: 20.73983 },
    { lat: 49.121948, lng: 20.740014 },
    { lat: 49.121821, lng: 20.740482 },
    { lat: 49.121836, lng: 20.740765 },
    { lat: 49.121799, lng: 20.74135 },
    { lat: 49.121922, lng: 20.741799 },
    { lat: 49.12199, lng: 20.74205 },
    { lat: 49.122069, lng: 20.742338 },
    { lat: 49.121926, lng: 20.743332 },
    { lat: 49.121866, lng: 20.744124 },
    { lat: 49.121809, lng: 20.744854 },
    { lat: 49.121647, lng: 20.745516 },
    { lat: 49.121464, lng: 20.746257 },
    { lat: 49.121463, lng: 20.746268 },
    { lat: 49.121964, lng: 20.746926 },
    { lat: 49.12226, lng: 20.747314 },
    { lat: 49.122834, lng: 20.747974 },
    { lat: 49.122992, lng: 20.747777 },
    { lat: 49.123154, lng: 20.748117 },
    { lat: 49.123585, lng: 20.749022 },
    { lat: 49.12365, lng: 20.749159 },
    { lat: 49.123732, lng: 20.749465 },
    { lat: 49.123735, lng: 20.749475 },
    { lat: 49.124158, lng: 20.750058 },
    { lat: 49.123858, lng: 20.750263 },
    { lat: 49.123664, lng: 20.750588 },
    { lat: 49.123383, lng: 20.75144 },
    { lat: 49.123343, lng: 20.75156 },
    { lat: 49.123184, lng: 20.752043 },
    { lat: 49.123052, lng: 20.752441 },
    { lat: 49.122955, lng: 20.752733 },
    { lat: 49.122904, lng: 20.752888 },
    { lat: 49.122812, lng: 20.753 },
    { lat: 49.122633, lng: 20.753217 },
    { lat: 49.122444, lng: 20.753445 },
    { lat: 49.122214, lng: 20.753723 },
    { lat: 49.121991, lng: 20.753993 },
    { lat: 49.121756, lng: 20.754276 },
    { lat: 49.121504, lng: 20.754581 },
    { lat: 49.121471, lng: 20.754619 },
    { lat: 49.121266, lng: 20.75486 },
    { lat: 49.121084, lng: 20.755072 },
    { lat: 49.120825, lng: 20.755377 },
    { lat: 49.120619, lng: 20.755616 },
    { lat: 49.120391, lng: 20.755883 },
    { lat: 49.12019, lng: 20.756119 },
    { lat: 49.120012, lng: 20.756327 },
    { lat: 49.119811, lng: 20.756562 },
    { lat: 49.119587, lng: 20.756823 },
    { lat: 49.119578, lng: 20.756845 },
    { lat: 49.118984, lng: 20.756256 },
    { lat: 49.118865, lng: 20.756761 },
    { lat: 49.118692, lng: 20.758128 },
    { lat: 49.118472, lng: 20.758816 },
    { lat: 49.118193, lng: 20.759125 },
    { lat: 49.117637, lng: 20.759565 },
    { lat: 49.117487, lng: 20.759728 },
    { lat: 49.117254, lng: 20.759769 },
    { lat: 49.116956, lng: 20.759918 },
    { lat: 49.116177, lng: 20.760203 },
    { lat: 49.115865, lng: 20.760371 },
    { lat: 49.115426, lng: 20.7605 },
    { lat: 49.115076, lng: 20.760648 },
    { lat: 49.114192, lng: 20.761338 },
    { lat: 49.113824, lng: 20.761506 },
    { lat: 49.113295, lng: 20.762171 },
    { lat: 49.113103, lng: 20.762582 },
    { lat: 49.112606, lng: 20.763304 },
    { lat: 49.112582, lng: 20.763567 },
    { lat: 49.112149, lng: 20.763821 },
    { lat: 49.111942, lng: 20.76407 },
    { lat: 49.111749, lng: 20.764182 },
    { lat: 49.111342, lng: 20.764622 },
    { lat: 49.111109, lng: 20.764336 },
    { lat: 49.110826, lng: 20.764331 },
    { lat: 49.109446, lng: 20.765029 },
    { lat: 49.109322, lng: 20.76542 },
    { lat: 49.109127, lng: 20.765586 },
    { lat: 49.109037, lng: 20.765671 },
    { lat: 49.108522, lng: 20.768238 },
    { lat: 49.109445, lng: 20.768909 },
    { lat: 49.109993, lng: 20.769416 },
    { lat: 49.110648, lng: 20.769813 },
    { lat: 49.110706, lng: 20.770097 },
    { lat: 49.110953, lng: 20.770198 },
    { lat: 49.111127, lng: 20.772317 },
    { lat: 49.111235, lng: 20.772956 },
    { lat: 49.111735, lng: 20.773847 },
    { lat: 49.111826, lng: 20.774104 },
    { lat: 49.112213, lng: 20.774965 },
    { lat: 49.112228, lng: 20.775283 },
    { lat: 49.11243, lng: 20.775966 },
    { lat: 49.112519, lng: 20.776531 },
    { lat: 49.112843, lng: 20.777232 },
    { lat: 49.111832, lng: 20.779144 },
    { lat: 49.112239, lng: 20.779992 },
    { lat: 49.112429, lng: 20.780264 },
    { lat: 49.113088, lng: 20.780848 },
    { lat: 49.11335, lng: 20.781255 },
    { lat: 49.114062, lng: 20.781905 },
    { lat: 49.11386, lng: 20.783743 },
    { lat: 49.113768, lng: 20.784581 },
    { lat: 49.113646, lng: 20.785443 },
    { lat: 49.1132, lng: 20.786083 },
    { lat: 49.113072, lng: 20.786978 },
    { lat: 49.112453, lng: 20.787901 },
    { lat: 49.11215, lng: 20.78806 },
    { lat: 49.111879, lng: 20.788043 },
    { lat: 49.111413, lng: 20.788433 },
    { lat: 49.1107, lng: 20.790183 },
  ],
  Ľutina: [
    { lat: 49.1897217, lng: 21.0397101 },
    { lat: 49.1894393, lng: 21.038774 },
    { lat: 49.1889803, lng: 21.0372541 },
    { lat: 49.1880091, lng: 21.0359078 },
    { lat: 49.1849307, lng: 21.0375217 },
    { lat: 49.1837572, lng: 21.0367996 },
    { lat: 49.1826214, lng: 21.0365142 },
    { lat: 49.1815102, lng: 21.0359417 },
    { lat: 49.1810172, lng: 21.0357686 },
    { lat: 49.1793396, lng: 21.0358526 },
    { lat: 49.1785468, lng: 21.0360036 },
    { lat: 49.1779563, lng: 21.0357723 },
    { lat: 49.1774981, lng: 21.0357361 },
    { lat: 49.1771917, lng: 21.0357913 },
    { lat: 49.1767545, lng: 21.0356704 },
    { lat: 49.1762153, lng: 21.0360781 },
    { lat: 49.176045, lng: 21.0358327 },
    { lat: 49.175789, lng: 21.0358128 },
    { lat: 49.1751272, lng: 21.0355712 },
    { lat: 49.1749732, lng: 21.0354343 },
    { lat: 49.173897, lng: 21.0358437 },
    { lat: 49.1736503, lng: 21.0357627 },
    { lat: 49.1728226, lng: 21.0361267 },
    { lat: 49.1733091, lng: 21.0340585 },
    { lat: 49.1700039, lng: 21.0338585 },
    { lat: 49.1696569, lng: 21.0335277 },
    { lat: 49.16935, lng: 21.0333362 },
    { lat: 49.1690031, lng: 21.0332574 },
    { lat: 49.1689251, lng: 21.0331421 },
    { lat: 49.1687482, lng: 21.0333752 },
    { lat: 49.1687411, lng: 21.0337491 },
    { lat: 49.1686291, lng: 21.034042 },
    { lat: 49.1685889, lng: 21.0345004 },
    { lat: 49.1663053, lng: 21.0328663 },
    { lat: 49.1661983, lng: 21.0327252 },
    { lat: 49.1650042, lng: 21.0318294 },
    { lat: 49.1649385, lng: 21.0318449 },
    { lat: 49.1648736, lng: 21.0319591 },
    { lat: 49.164407, lng: 21.0314201 },
    { lat: 49.1629691, lng: 21.033301 },
    { lat: 49.1627174, lng: 21.0338667 },
    { lat: 49.1623016, lng: 21.0345098 },
    { lat: 49.1617702, lng: 21.0350563 },
    { lat: 49.1614154, lng: 21.0352503 },
    { lat: 49.1599179, lng: 21.0353355 },
    { lat: 49.159894, lng: 21.0357397 },
    { lat: 49.1598817, lng: 21.0358463 },
    { lat: 49.1596842, lng: 21.0360033 },
    { lat: 49.1592196, lng: 21.0360765 },
    { lat: 49.1594026, lng: 21.0377591 },
    { lat: 49.1588558, lng: 21.0384204 },
    { lat: 49.1583195, lng: 21.0388571 },
    { lat: 49.1574839, lng: 21.0392528 },
    { lat: 49.1569487, lng: 21.0383702 },
    { lat: 49.1560247, lng: 21.0383329 },
    { lat: 49.1548328, lng: 21.0374989 },
    { lat: 49.1543048, lng: 21.0370422 },
    { lat: 49.1541775, lng: 21.0370727 },
    { lat: 49.1538481, lng: 21.0369063 },
    { lat: 49.1533178, lng: 21.0367994 },
    { lat: 49.1516566, lng: 21.0368983 },
    { lat: 49.1509102, lng: 21.0382766 },
    { lat: 49.1487867, lng: 21.0417817 },
    { lat: 49.1478006, lng: 21.0435349 },
    { lat: 49.1472081, lng: 21.0447372 },
    { lat: 49.1471342, lng: 21.0450932 },
    { lat: 49.1471401, lng: 21.0458617 },
    { lat: 49.1470982, lng: 21.0462355 },
    { lat: 49.1468634, lng: 21.0469787 },
    { lat: 49.146847, lng: 21.0472754 },
    { lat: 49.1461935, lng: 21.0493984 },
    { lat: 49.1461722, lng: 21.0494755 },
    { lat: 49.1480757, lng: 21.0497284 },
    { lat: 49.1503931, lng: 21.049743 },
    { lat: 49.1518641, lng: 21.0493241 },
    { lat: 49.1519703, lng: 21.0503842 },
    { lat: 49.1530456, lng: 21.0556117 },
    { lat: 49.1534043, lng: 21.0565119 },
    { lat: 49.1534963, lng: 21.0564687 },
    { lat: 49.153823, lng: 21.056653 },
    { lat: 49.1538991, lng: 21.056533 },
    { lat: 49.1540012, lng: 21.0568021 },
    { lat: 49.1541049, lng: 21.0568087 },
    { lat: 49.1541625, lng: 21.0569845 },
    { lat: 49.1542568, lng: 21.0570137 },
    { lat: 49.1543922, lng: 21.0569191 },
    { lat: 49.1556586, lng: 21.0582919 },
    { lat: 49.15576, lng: 21.0583289 },
    { lat: 49.1562287, lng: 21.0587632 },
    { lat: 49.1570327, lng: 21.0597221 },
    { lat: 49.1572173, lng: 21.0598597 },
    { lat: 49.1573382, lng: 21.0599462 },
    { lat: 49.1573603, lng: 21.0599 },
    { lat: 49.1576579, lng: 21.0599129 },
    { lat: 49.1582546, lng: 21.0603754 },
    { lat: 49.158517, lng: 21.0604179 },
    { lat: 49.1588874, lng: 21.060663 },
    { lat: 49.1591398, lng: 21.0609148 },
    { lat: 49.1593355, lng: 21.0609141 },
    { lat: 49.1594769, lng: 21.0610797 },
    { lat: 49.1598101, lng: 21.0611809 },
    { lat: 49.1601258, lng: 21.0615135 },
    { lat: 49.1603059, lng: 21.06155 },
    { lat: 49.1605587, lng: 21.0619589 },
    { lat: 49.1609078, lng: 21.0620693 },
    { lat: 49.1613213, lng: 21.0624298 },
    { lat: 49.1615219, lng: 21.0624428 },
    { lat: 49.1618589, lng: 21.0628375 },
    { lat: 49.1625163, lng: 21.063406 },
    { lat: 49.1627742, lng: 21.0637246 },
    { lat: 49.1632672, lng: 21.0647754 },
    { lat: 49.1636022, lng: 21.0652832 },
    { lat: 49.1641284, lng: 21.0659363 },
    { lat: 49.1641834, lng: 21.0657204 },
    { lat: 49.1646452, lng: 21.0650345 },
    { lat: 49.1648246, lng: 21.0653623 },
    { lat: 49.1651453, lng: 21.0656371 },
    { lat: 49.165255, lng: 21.0656238 },
    { lat: 49.1653025, lng: 21.0657382 },
    { lat: 49.1661575, lng: 21.0654795 },
    { lat: 49.1669752, lng: 21.0672839 },
    { lat: 49.1678691, lng: 21.0685525 },
    { lat: 49.1686236, lng: 21.0677311 },
    { lat: 49.1692196, lng: 21.0682384 },
    { lat: 49.1734394, lng: 21.0703631 },
    { lat: 49.1741643, lng: 21.0683799 },
    { lat: 49.1747953, lng: 21.0673158 },
    { lat: 49.1754623, lng: 21.0657379 },
    { lat: 49.1754423, lng: 21.0651844 },
    { lat: 49.1757673, lng: 21.0637148 },
    { lat: 49.1759888, lng: 21.0622422 },
    { lat: 49.1761266, lng: 21.06177 },
    { lat: 49.1763243, lng: 21.0614807 },
    { lat: 49.1754603, lng: 21.0607219 },
    { lat: 49.1740514, lng: 21.0600598 },
    { lat: 49.1728729, lng: 21.0583472 },
    { lat: 49.1730847, lng: 21.0583197 },
    { lat: 49.1737784, lng: 21.0577784 },
    { lat: 49.173785, lng: 21.0575305 },
    { lat: 49.1735969, lng: 21.057276 },
    { lat: 49.1734993, lng: 21.0573214 },
    { lat: 49.1731525, lng: 21.0570403 },
    { lat: 49.173452, lng: 21.0561057 },
    { lat: 49.1734403, lng: 21.0558811 },
    { lat: 49.1736529, lng: 21.0554914 },
    { lat: 49.1739254, lng: 21.0552184 },
    { lat: 49.1737, lng: 21.0551033 },
    { lat: 49.1736814, lng: 21.0540771 },
    { lat: 49.1739526, lng: 21.0537985 },
    { lat: 49.1740438, lng: 21.0534564 },
    { lat: 49.1745551, lng: 21.0536758 },
    { lat: 49.1750868, lng: 21.0533441 },
    { lat: 49.1754333, lng: 21.0536533 },
    { lat: 49.1757121, lng: 21.0533625 },
    { lat: 49.175365, lng: 21.0527239 },
    { lat: 49.1755601, lng: 21.0521435 },
    { lat: 49.1765304, lng: 21.0525312 },
    { lat: 49.1769345, lng: 21.0529896 },
    { lat: 49.1769712, lng: 21.0529426 },
    { lat: 49.176895, lng: 21.0528109 },
    { lat: 49.1774043, lng: 21.0521737 },
    { lat: 49.1777337, lng: 21.0528571 },
    { lat: 49.1785719, lng: 21.0519142 },
    { lat: 49.1787987, lng: 21.052209 },
    { lat: 49.1798048, lng: 21.0508497 },
    { lat: 49.1807495, lng: 21.0497584 },
    { lat: 49.1814145, lng: 21.049096 },
    { lat: 49.1818928, lng: 21.0484981 },
    { lat: 49.1827855, lng: 21.0469163 },
    { lat: 49.1827818, lng: 21.0466914 },
    { lat: 49.1842776, lng: 21.043716 },
    { lat: 49.1846457, lng: 21.0432638 },
    { lat: 49.1848514, lng: 21.0438134 },
    { lat: 49.1849573, lng: 21.044272 },
    { lat: 49.1852493, lng: 21.04415 },
    { lat: 49.1856397, lng: 21.0436571 },
    { lat: 49.1866099, lng: 21.0421024 },
    { lat: 49.1867449, lng: 21.0417515 },
    { lat: 49.1863838, lng: 21.0408185 },
    { lat: 49.1864727, lng: 21.0404049 },
    { lat: 49.1877382, lng: 21.0403187 },
    { lat: 49.1885237, lng: 21.0403838 },
    { lat: 49.1897217, lng: 21.0397101 },
  ],
  Brezovica: [
    { lat: 49.1645924, lng: 20.8466693 },
    { lat: 49.1644597, lng: 20.8465403 },
    { lat: 49.1643109, lng: 20.8461051 },
    { lat: 49.1642492, lng: 20.8457126 },
    { lat: 49.1640185, lng: 20.8452845 },
    { lat: 49.163985, lng: 20.8450966 },
    { lat: 49.1625038, lng: 20.8432545 },
    { lat: 49.1609187, lng: 20.8411377 },
    { lat: 49.1609132, lng: 20.8410724 },
    { lat: 49.1611977, lng: 20.8405351 },
    { lat: 49.1621929, lng: 20.8389937 },
    { lat: 49.1617576, lng: 20.8388043 },
    { lat: 49.1610631, lng: 20.8390692 },
    { lat: 49.1592081, lng: 20.8391667 },
    { lat: 49.1588409, lng: 20.8393178 },
    { lat: 49.158819, lng: 20.8387484 },
    { lat: 49.1590364, lng: 20.8384913 },
    { lat: 49.1590373, lng: 20.8379234 },
    { lat: 49.1591543, lng: 20.8378224 },
    { lat: 49.159203, lng: 20.8374384 },
    { lat: 49.1592787, lng: 20.8374108 },
    { lat: 49.1592202, lng: 20.8369548 },
    { lat: 49.1593196, lng: 20.8366699 },
    { lat: 49.1591469, lng: 20.8364532 },
    { lat: 49.1593001, lng: 20.8359947 },
    { lat: 49.1593499, lng: 20.8353847 },
    { lat: 49.1595218, lng: 20.8349485 },
    { lat: 49.1597172, lng: 20.8342414 },
    { lat: 49.160187, lng: 20.8332715 },
    { lat: 49.1603206, lng: 20.8332241 },
    { lat: 49.1604906, lng: 20.8324039 },
    { lat: 49.1604595, lng: 20.8318954 },
    { lat: 49.1603483, lng: 20.831473 },
    { lat: 49.1604186, lng: 20.8305389 },
    { lat: 49.1599813, lng: 20.8290431 },
    { lat: 49.159316, lng: 20.8260825 },
    { lat: 49.1591018, lng: 20.8246647 },
    { lat: 49.1587845, lng: 20.8230983 },
    { lat: 49.1587975, lng: 20.822555 },
    { lat: 49.1585852, lng: 20.8215524 },
    { lat: 49.1586057, lng: 20.8212019 },
    { lat: 49.1579802, lng: 20.8142329 },
    { lat: 49.156998, lng: 20.8139849 },
    { lat: 49.1497014, lng: 20.8119705 },
    { lat: 49.1465394, lng: 20.8101009 },
    { lat: 49.1457894, lng: 20.8101919 },
    { lat: 49.1456573, lng: 20.8103078 },
    { lat: 49.1454424, lng: 20.8102793 },
    { lat: 49.1450905, lng: 20.8100939 },
    { lat: 49.1444331, lng: 20.8095023 },
    { lat: 49.1442185, lng: 20.8089565 },
    { lat: 49.1440112, lng: 20.8087595 },
    { lat: 49.1434541, lng: 20.8086291 },
    { lat: 49.1429816, lng: 20.8086011 },
    { lat: 49.1422207, lng: 20.8084076 },
    { lat: 49.141805, lng: 20.8081378 },
    { lat: 49.1417998, lng: 20.8079876 },
    { lat: 49.1417077, lng: 20.8077325 },
    { lat: 49.1415599, lng: 20.807652 },
    { lat: 49.1414932, lng: 20.8074819 },
    { lat: 49.1413484, lng: 20.8074284 },
    { lat: 49.1411968, lng: 20.8075042 },
    { lat: 49.1410763, lng: 20.8073416 },
    { lat: 49.1409677, lng: 20.8073259 },
    { lat: 49.1407182, lng: 20.8074638 },
    { lat: 49.140442, lng: 20.8074227 },
    { lat: 49.1401899, lng: 20.8075539 },
    { lat: 49.1402144, lng: 20.7991628 },
    { lat: 49.1401447, lng: 20.7991314 },
    { lat: 49.1399131, lng: 20.7992413 },
    { lat: 49.1397346, lng: 20.799155 },
    { lat: 49.1391002, lng: 20.7985484 },
    { lat: 49.1390184, lng: 20.7982638 },
    { lat: 49.138924, lng: 20.7981416 },
    { lat: 49.1387711, lng: 20.7981797 },
    { lat: 49.1385983, lng: 20.7980663 },
    { lat: 49.138389, lng: 20.79771 },
    { lat: 49.1379501, lng: 20.7977093 },
    { lat: 49.137563, lng: 20.7973191 },
    { lat: 49.1373858, lng: 20.7975706 },
    { lat: 49.1371782, lng: 20.797487 },
    { lat: 49.1370245, lng: 20.7971154 },
    { lat: 49.1367115, lng: 20.7972088 },
    { lat: 49.1362527, lng: 20.7971274 },
    { lat: 49.1357373, lng: 20.7968999 },
    { lat: 49.1350963, lng: 20.7967861 },
    { lat: 49.1348727, lng: 20.7965355 },
    { lat: 49.1343708, lng: 20.7965079 },
    { lat: 49.1342706, lng: 20.796379 },
    { lat: 49.1340523, lng: 20.7964436 },
    { lat: 49.1337341, lng: 20.7962273 },
    { lat: 49.1334931, lng: 20.7962887 },
    { lat: 49.1333828, lng: 20.7962239 },
    { lat: 49.1330241, lng: 20.795748 },
    { lat: 49.1328341, lng: 20.7957841 },
    { lat: 49.1325727, lng: 20.7957156 },
    { lat: 49.1324174, lng: 20.7954862 },
    { lat: 49.1322979, lng: 20.7954483 },
    { lat: 49.1314729, lng: 20.7946994 },
    { lat: 49.1313463, lng: 20.7947146 },
    { lat: 49.1312502, lng: 20.794543 },
    { lat: 49.1310057, lng: 20.7945055 },
    { lat: 49.1309257, lng: 20.7943063 },
    { lat: 49.1308024, lng: 20.7941585 },
    { lat: 49.1306489, lng: 20.79405 },
    { lat: 49.1304799, lng: 20.7940737 },
    { lat: 49.1302263, lng: 20.7937734 },
    { lat: 49.1301131, lng: 20.7937247 },
    { lat: 49.1299742, lng: 20.7937725 },
    { lat: 49.1299001, lng: 20.7936142 },
    { lat: 49.1292106, lng: 20.7934971 },
    { lat: 49.1286687, lng: 20.7936517 },
    { lat: 49.1284843, lng: 20.7934886 },
    { lat: 49.1283828, lng: 20.7935482 },
    { lat: 49.128194, lng: 20.7934624 },
    { lat: 49.1278221, lng: 20.7936163 },
    { lat: 49.1275273, lng: 20.7935196 },
    { lat: 49.1262933, lng: 20.7935418 },
    { lat: 49.126209, lng: 20.7934785 },
    { lat: 49.1260154, lng: 20.7936084 },
    { lat: 49.125581, lng: 20.7936465 },
    { lat: 49.1253291, lng: 20.7934899 },
    { lat: 49.1247591, lng: 20.793704 },
    { lat: 49.1244631, lng: 20.7937364 },
    { lat: 49.1238973, lng: 20.794203 },
    { lat: 49.1233873, lng: 20.7948478 },
    { lat: 49.1232659, lng: 20.7949165 },
    { lat: 49.1231114, lng: 20.7949113 },
    { lat: 49.1229083, lng: 20.7950861 },
    { lat: 49.1226271, lng: 20.7950325 },
    { lat: 49.1223643, lng: 20.7951125 },
    { lat: 49.1222263, lng: 20.7950295 },
    { lat: 49.1218927, lng: 20.7950473 },
    { lat: 49.1215739, lng: 20.7949755 },
    { lat: 49.1212558, lng: 20.7948275 },
    { lat: 49.1207564, lng: 20.7944788 },
    { lat: 49.1205291, lng: 20.794188 },
    { lat: 49.1196425, lng: 20.7935466 },
    { lat: 49.1190655, lng: 20.7934633 },
    { lat: 49.1189226, lng: 20.7933691 },
    { lat: 49.1187995, lng: 20.7933905 },
    { lat: 49.1187265, lng: 20.793299 },
    { lat: 49.1180165, lng: 20.793203 },
    { lat: 49.1176941, lng: 20.7932231 },
    { lat: 49.11746, lng: 20.7933922 },
    { lat: 49.1173107, lng: 20.7933632 },
    { lat: 49.1169519, lng: 20.793539 },
    { lat: 49.1162685, lng: 20.7935026 },
    { lat: 49.1160761, lng: 20.7933679 },
    { lat: 49.1155089, lng: 20.7924649 },
    { lat: 49.114948, lng: 20.7920005 },
    { lat: 49.1147023, lng: 20.7919272 },
    { lat: 49.1138738, lng: 20.792149 },
    { lat: 49.1127825, lng: 20.7918451 },
    { lat: 49.1121734, lng: 20.7919037 },
    { lat: 49.1116669, lng: 20.79216 },
    { lat: 49.1115632, lng: 20.7921547 },
    { lat: 49.1109306, lng: 20.7929044 },
    { lat: 49.1105845, lng: 20.7934118 },
    { lat: 49.1110215, lng: 20.7948466 },
    { lat: 49.1124845, lng: 20.7994606 },
    { lat: 49.1124356, lng: 20.7994796 },
    { lat: 49.1125743, lng: 20.8008518 },
    { lat: 49.1117709, lng: 20.8120155 },
    { lat: 49.1118426, lng: 20.8131084 },
    { lat: 49.1117721, lng: 20.8136785 },
    { lat: 49.1118138, lng: 20.8140399 },
    { lat: 49.1119086, lng: 20.8142788 },
    { lat: 49.1125814, lng: 20.8155084 },
    { lat: 49.1126817, lng: 20.8157774 },
    { lat: 49.1137676, lng: 20.8166801 },
    { lat: 49.1146819, lng: 20.8170185 },
    { lat: 49.1154607, lng: 20.817409 },
    { lat: 49.1161457, lng: 20.8175871 },
    { lat: 49.1172874, lng: 20.8171456 },
    { lat: 49.117488, lng: 20.8172187 },
    { lat: 49.1177384, lng: 20.8173589 },
    { lat: 49.1183181, lng: 20.8180827 },
    { lat: 49.1187169, lng: 20.8182553 },
    { lat: 49.1190244, lng: 20.8184912 },
    { lat: 49.1194075, lng: 20.8191854 },
    { lat: 49.1200631, lng: 20.8200541 },
    { lat: 49.1202993, lng: 20.8202767 },
    { lat: 49.1204478, lng: 20.8203076 },
    { lat: 49.1209658, lng: 20.8206116 },
    { lat: 49.1211509, lng: 20.8211506 },
    { lat: 49.1212762, lng: 20.8225661 },
    { lat: 49.1215183, lng: 20.823751 },
    { lat: 49.1215179, lng: 20.824293 },
    { lat: 49.1217907, lng: 20.8249982 },
    { lat: 49.1221068, lng: 20.825419 },
    { lat: 49.122202, lng: 20.8256277 },
    { lat: 49.1222751, lng: 20.8259927 },
    { lat: 49.1222904, lng: 20.8265676 },
    { lat: 49.1225332, lng: 20.8285233 },
    { lat: 49.1226395, lng: 20.8291322 },
    { lat: 49.1229945, lng: 20.8303559 },
    { lat: 49.123114, lng: 20.830492 },
    { lat: 49.1233285, lng: 20.8305769 },
    { lat: 49.1236562, lng: 20.8304491 },
    { lat: 49.123819, lng: 20.8304707 },
    { lat: 49.1240235, lng: 20.830596 },
    { lat: 49.1243194, lng: 20.8309501 },
    { lat: 49.1244537, lng: 20.8312898 },
    { lat: 49.124794, lng: 20.8316591 },
    { lat: 49.1255579, lng: 20.8316159 },
    { lat: 49.1258085, lng: 20.8312427 },
    { lat: 49.1262026, lng: 20.8317101 },
    { lat: 49.1263426, lng: 20.8321816 },
    { lat: 49.1264285, lng: 20.8329105 },
    { lat: 49.1268732, lng: 20.8331127 },
    { lat: 49.1275974, lng: 20.8337145 },
    { lat: 49.1277714, lng: 20.8337402 },
    { lat: 49.1284599, lng: 20.8334324 },
    { lat: 49.1286371, lng: 20.8334495 },
    { lat: 49.1290484, lng: 20.8337572 },
    { lat: 49.1293435, lng: 20.8340881 },
    { lat: 49.1306537, lng: 20.8348798 },
    { lat: 49.1312615, lng: 20.8349127 },
    { lat: 49.1314255, lng: 20.8347714 },
    { lat: 49.1315077, lng: 20.8348033 },
    { lat: 49.1316148, lng: 20.8350722 },
    { lat: 49.1316687, lng: 20.8353997 },
    { lat: 49.1315913, lng: 20.8358325 },
    { lat: 49.1312409, lng: 20.8369426 },
    { lat: 49.1309848, lng: 20.8380736 },
    { lat: 49.1311707, lng: 20.8381105 },
    { lat: 49.1316172, lng: 20.838433 },
    { lat: 49.1324435, lng: 20.8392213 },
    { lat: 49.1353859, lng: 20.8480579 },
    { lat: 49.13549, lng: 20.8481693 },
    { lat: 49.1356496, lng: 20.8487555 },
    { lat: 49.1356982, lng: 20.8498185 },
    { lat: 49.135806, lng: 20.8505044 },
    { lat: 49.1357816, lng: 20.8507436 },
    { lat: 49.135626, lng: 20.8511305 },
    { lat: 49.1357066, lng: 20.8531363 },
    { lat: 49.1356779, lng: 20.8537741 },
    { lat: 49.1355274, lng: 20.8542518 },
    { lat: 49.1349074, lng: 20.8596042 },
    { lat: 49.1347446, lng: 20.8600796 },
    { lat: 49.134707, lng: 20.8606584 },
    { lat: 49.1348021, lng: 20.8608763 },
    { lat: 49.1346685, lng: 20.8616136 },
    { lat: 49.1345644, lng: 20.861825 },
    { lat: 49.1345927, lng: 20.8625269 },
    { lat: 49.1344812, lng: 20.8626608 },
    { lat: 49.1345247, lng: 20.8629129 },
    { lat: 49.1342444, lng: 20.8638725 },
    { lat: 49.134077, lng: 20.8641452 },
    { lat: 49.134032, lng: 20.8643755 },
    { lat: 49.1340725, lng: 20.8645747 },
    { lat: 49.1339612, lng: 20.8651097 },
    { lat: 49.1338892, lng: 20.8651765 },
    { lat: 49.1339482, lng: 20.8657527 },
    { lat: 49.1338245, lng: 20.8659533 },
    { lat: 49.1338305, lng: 20.8661973 },
    { lat: 49.1339486, lng: 20.8665912 },
    { lat: 49.13392, lng: 20.8669087 },
    { lat: 49.1338606, lng: 20.8670838 },
    { lat: 49.1339461, lng: 20.8672107 },
    { lat: 49.133981, lng: 20.8675099 },
    { lat: 49.1341865, lng: 20.8675943 },
    { lat: 49.1346211, lng: 20.8682954 },
    { lat: 49.1349622, lng: 20.8687002 },
    { lat: 49.1351948, lng: 20.8695488 },
    { lat: 49.1353779, lng: 20.8697706 },
    { lat: 49.1355926, lng: 20.8710868 },
    { lat: 49.135215, lng: 20.8727269 },
    { lat: 49.1354912, lng: 20.8732687 },
    { lat: 49.1354679, lng: 20.8736458 },
    { lat: 49.1352357, lng: 20.8744639 },
    { lat: 49.1350917, lng: 20.876129 },
    { lat: 49.1350973, lng: 20.8765923 },
    { lat: 49.1349826, lng: 20.8771617 },
    { lat: 49.1349544, lng: 20.8777207 },
    { lat: 49.1347675, lng: 20.8780182 },
    { lat: 49.1346962, lng: 20.8782374 },
    { lat: 49.1345726, lng: 20.8784811 },
    { lat: 49.1343628, lng: 20.8791669 },
    { lat: 49.1354281, lng: 20.878784 },
    { lat: 49.136429, lng: 20.8786549 },
    { lat: 49.1370303, lng: 20.8786996 },
    { lat: 49.1372008, lng: 20.878652 },
    { lat: 49.1373872, lng: 20.8786683 },
    { lat: 49.1381017, lng: 20.8768338 },
    { lat: 49.1390218, lng: 20.8754575 },
    { lat: 49.1399869, lng: 20.8746439 },
    { lat: 49.1396841, lng: 20.8717229 },
    { lat: 49.1396644, lng: 20.8706428 },
    { lat: 49.1394612, lng: 20.8703281 },
    { lat: 49.1394531, lng: 20.8698596 },
    { lat: 49.1399796, lng: 20.8693889 },
    { lat: 49.1406621, lng: 20.868171 },
    { lat: 49.1408555, lng: 20.8679717 },
    { lat: 49.1412923, lng: 20.8680415 },
    { lat: 49.1414042, lng: 20.86762 },
    { lat: 49.1415482, lng: 20.8675888 },
    { lat: 49.1415376, lng: 20.8674427 },
    { lat: 49.14166, lng: 20.8672683 },
    { lat: 49.1421263, lng: 20.8667768 },
    { lat: 49.142692, lng: 20.8660034 },
    { lat: 49.1434684, lng: 20.8651361 },
    { lat: 49.1436563, lng: 20.8641409 },
    { lat: 49.1442422, lng: 20.8628047 },
    { lat: 49.1446128, lng: 20.8621811 },
    { lat: 49.145181, lng: 20.8626737 },
    { lat: 49.1471626, lng: 20.8631926 },
    { lat: 49.1480092, lng: 20.8635313 },
    { lat: 49.1487701, lng: 20.8640234 },
    { lat: 49.1492461, lng: 20.8623334 },
    { lat: 49.1499645, lng: 20.862708 },
    { lat: 49.1502781, lng: 20.8632254 },
    { lat: 49.1508342, lng: 20.8647488 },
    { lat: 49.1512238, lng: 20.8651865 },
    { lat: 49.1515669, lng: 20.8663381 },
    { lat: 49.151967, lng: 20.8669861 },
    { lat: 49.1540357, lng: 20.8692711 },
    { lat: 49.1542638, lng: 20.8696623 },
    { lat: 49.1543685, lng: 20.8695275 },
    { lat: 49.1544065, lng: 20.8695511 },
    { lat: 49.1549773, lng: 20.867205 },
    { lat: 49.1548388, lng: 20.8656475 },
    { lat: 49.15565, lng: 20.8640476 },
    { lat: 49.1556639, lng: 20.8628318 },
    { lat: 49.1565885, lng: 20.8612243 },
    { lat: 49.1568678, lng: 20.8602705 },
    { lat: 49.1566032, lng: 20.8597107 },
    { lat: 49.1586056, lng: 20.8562719 },
    { lat: 49.1587719, lng: 20.8557693 },
    { lat: 49.1593447, lng: 20.8555988 },
    { lat: 49.1597644, lng: 20.8546406 },
    { lat: 49.1602313, lng: 20.8546306 },
    { lat: 49.160769, lng: 20.8540608 },
    { lat: 49.1612168, lng: 20.8533592 },
    { lat: 49.1613943, lng: 20.8525961 },
    { lat: 49.1617032, lng: 20.8520814 },
    { lat: 49.1621211, lng: 20.8521936 },
    { lat: 49.1622671, lng: 20.8518454 },
    { lat: 49.1621662, lng: 20.8513348 },
    { lat: 49.1631155, lng: 20.8497299 },
    { lat: 49.1636458, lng: 20.8489452 },
    { lat: 49.1644139, lng: 20.8483958 },
    { lat: 49.1643041, lng: 20.8475849 },
    { lat: 49.1645924, lng: 20.8466693 },
  ],
  ČervenicapriSabinove: [
    { lat: 49.1383533, lng: 21.0070438 },
    { lat: 49.1384277, lng: 21.0073573 },
    { lat: 49.1384435, lng: 21.0078021 },
    { lat: 49.13819, lng: 21.0087883 },
    { lat: 49.13783, lng: 21.0093775 },
    { lat: 49.1375254, lng: 21.0096968 },
    { lat: 49.1371368, lng: 21.0099647 },
    { lat: 49.1363145, lng: 21.0110099 },
    { lat: 49.1361673, lng: 21.011944 },
    { lat: 49.1352814, lng: 21.0139306 },
    { lat: 49.1339555, lng: 21.0175053 },
    { lat: 49.1330752, lng: 21.0193906 },
    { lat: 49.1327223, lng: 21.0198839 },
    { lat: 49.1326163, lng: 21.0201205 },
    { lat: 49.1319281, lng: 21.0222431 },
    { lat: 49.1315359, lng: 21.0226373 },
    { lat: 49.131075, lng: 21.0229313 },
    { lat: 49.1306346, lng: 21.022951 },
    { lat: 49.1299079, lng: 21.0237935 },
    { lat: 49.1294946, lng: 21.0247509 },
    { lat: 49.1293351, lng: 21.0250012 },
    { lat: 49.129153, lng: 21.0252159 },
    { lat: 49.1288737, lng: 21.0254153 },
    { lat: 49.1285325, lng: 21.0263065 },
    { lat: 49.1284135, lng: 21.0264535 },
    { lat: 49.1277047, lng: 21.0271511 },
    { lat: 49.126759, lng: 21.0275838 },
    { lat: 49.1265607, lng: 21.0277164 },
    { lat: 49.1263604, lng: 21.0279579 },
    { lat: 49.1262864, lng: 21.028279 },
    { lat: 49.1263085, lng: 21.0287182 },
    { lat: 49.1262736, lng: 21.0290234 },
    { lat: 49.1259717, lng: 21.0300212 },
    { lat: 49.1258774, lng: 21.030817 },
    { lat: 49.1258743, lng: 21.031656 },
    { lat: 49.1259872, lng: 21.0322561 },
    { lat: 49.1259608, lng: 21.0323796 },
    { lat: 49.1258338, lng: 21.0325186 },
    { lat: 49.1255668, lng: 21.0326836 },
    { lat: 49.1279266, lng: 21.0356868 },
    { lat: 49.1286347, lng: 21.0348288 },
    { lat: 49.1286628, lng: 21.0351051 },
    { lat: 49.1289828, lng: 21.0356344 },
    { lat: 49.1289254, lng: 21.0357059 },
    { lat: 49.1289284, lng: 21.0357966 },
    { lat: 49.1292248, lng: 21.0358091 },
    { lat: 49.1299499, lng: 21.036063 },
    { lat: 49.1312757, lng: 21.0357943 },
    { lat: 49.1328126, lng: 21.0358453 },
    { lat: 49.1335156, lng: 21.0360405 },
    { lat: 49.1341435, lng: 21.0363859 },
    { lat: 49.1356073, lng: 21.0370017 },
    { lat: 49.1363961, lng: 21.0372031 },
    { lat: 49.1368109, lng: 21.0371225 },
    { lat: 49.1379251, lng: 21.036533 },
    { lat: 49.1382354, lng: 21.0364884 },
    { lat: 49.1390279, lng: 21.0365277 },
    { lat: 49.1397453, lng: 21.0364074 },
    { lat: 49.1397796, lng: 21.0363577 },
    { lat: 49.1413573, lng: 21.0361788 },
    { lat: 49.1419461, lng: 21.0359794 },
    { lat: 49.1433529, lng: 21.0352218 },
    { lat: 49.1445103, lng: 21.0349901 },
    { lat: 49.1457595, lng: 21.0352372 },
    { lat: 49.1469544, lng: 21.03578 },
    { lat: 49.147791, lng: 21.0360008 },
    { lat: 49.149417, lng: 21.0366544 },
    { lat: 49.1495746, lng: 21.0365537 },
    { lat: 49.1495696, lng: 21.0365012 },
    { lat: 49.1498154, lng: 21.0366196 },
    { lat: 49.1502807, lng: 21.0366725 },
    { lat: 49.1503859, lng: 21.0367505 },
    { lat: 49.1514428, lng: 21.0368946 },
    { lat: 49.1516566, lng: 21.0368983 },
    { lat: 49.1533178, lng: 21.0367994 },
    { lat: 49.1538481, lng: 21.0369063 },
    { lat: 49.1541775, lng: 21.0370727 },
    { lat: 49.1543048, lng: 21.0370422 },
    { lat: 49.1548328, lng: 21.0374989 },
    { lat: 49.1560247, lng: 21.0383329 },
    { lat: 49.1569487, lng: 21.0383702 },
    { lat: 49.1574839, lng: 21.0392528 },
    { lat: 49.1583195, lng: 21.0388571 },
    { lat: 49.1588558, lng: 21.0384204 },
    { lat: 49.1594026, lng: 21.0377591 },
    { lat: 49.1592196, lng: 21.0360765 },
    { lat: 49.1596842, lng: 21.0360033 },
    { lat: 49.1598817, lng: 21.0358463 },
    { lat: 49.159894, lng: 21.0357397 },
    { lat: 49.1599179, lng: 21.0353355 },
    { lat: 49.1599461, lng: 21.0350205 },
    { lat: 49.1600854, lng: 21.0349837 },
    { lat: 49.1601197, lng: 21.0347895 },
    { lat: 49.1608145, lng: 21.0333213 },
    { lat: 49.1609486, lng: 21.0329055 },
    { lat: 49.1610866, lng: 21.0327541 },
    { lat: 49.1612802, lng: 21.0316157 },
    { lat: 49.1615561, lng: 21.0306146 },
    { lat: 49.1618659, lng: 21.0278331 },
    { lat: 49.1618478, lng: 21.0271322 },
    { lat: 49.1619118, lng: 21.0265404 },
    { lat: 49.1618388, lng: 21.0259307 },
    { lat: 49.1618432, lng: 21.0253324 },
    { lat: 49.1623947, lng: 21.0235807 },
    { lat: 49.1623773, lng: 21.0234227 },
    { lat: 49.1625647, lng: 21.0225077 },
    { lat: 49.1624563, lng: 21.0223954 },
    { lat: 49.1614226, lng: 21.0221696 },
    { lat: 49.1610423, lng: 21.0221061 },
    { lat: 49.1605269, lng: 21.0221412 },
    { lat: 49.1603211, lng: 21.0218666 },
    { lat: 49.1600685, lng: 21.021678 },
    { lat: 49.1598798, lng: 21.021693 },
    { lat: 49.1597334, lng: 21.0214848 },
    { lat: 49.1595113, lng: 21.0215354 },
    { lat: 49.1592412, lng: 21.0214675 },
    { lat: 49.1590823, lng: 21.0216007 },
    { lat: 49.1590252, lng: 21.0215773 },
    { lat: 49.1589167, lng: 21.0214543 },
    { lat: 49.1588825, lng: 21.0213088 },
    { lat: 49.1585621, lng: 21.0212457 },
    { lat: 49.1582821, lng: 21.020911 },
    { lat: 49.1578155, lng: 21.0207501 },
    { lat: 49.1574239, lng: 21.0207528 },
    { lat: 49.1572404, lng: 21.0206794 },
    { lat: 49.1570121, lng: 21.0203404 },
    { lat: 49.1567242, lng: 21.020176 },
    { lat: 49.156517, lng: 21.0199673 },
    { lat: 49.1561528, lng: 21.019908 },
    { lat: 49.1555062, lng: 21.0201339 },
    { lat: 49.1554551, lng: 21.0199458 },
    { lat: 49.1548258, lng: 21.0189027 },
    { lat: 49.154818, lng: 21.018629 },
    { lat: 49.1544589, lng: 21.018767 },
    { lat: 49.1543766, lng: 21.0179006 },
    { lat: 49.1543008, lng: 21.0175443 },
    { lat: 49.1543011, lng: 21.0169679 },
    { lat: 49.1542392, lng: 21.0167914 },
    { lat: 49.154037, lng: 21.0148045 },
    { lat: 49.1540256, lng: 21.0147669 },
    { lat: 49.1538857, lng: 21.0136252 },
    { lat: 49.1531338, lng: 21.0147926 },
    { lat: 49.1525852, lng: 21.0137431 },
    { lat: 49.152972, lng: 21.0127635 },
    { lat: 49.1530833, lng: 21.01146 },
    { lat: 49.1530109, lng: 21.0098688 },
    { lat: 49.1529641, lng: 21.0063079 },
    { lat: 49.1528273, lng: 21.0054662 },
    { lat: 49.1522624, lng: 21.0057074 },
    { lat: 49.1517727, lng: 21.0057332 },
    { lat: 49.1502703, lng: 21.0062808 },
    { lat: 49.1492069, lng: 21.0064966 },
    { lat: 49.1487509, lng: 21.0067945 },
    { lat: 49.1486508, lng: 21.0068701 },
    { lat: 49.1484887, lng: 21.0070403 },
    { lat: 49.1481678, lng: 21.0072365 },
    { lat: 49.1470984, lng: 21.0075416 },
    { lat: 49.1462359, lng: 21.0073513 },
    { lat: 49.1455353, lng: 21.0069589 },
    { lat: 49.14527, lng: 21.0067271 },
    { lat: 49.1450934, lng: 21.0067276 },
    { lat: 49.1448085, lng: 21.0068927 },
    { lat: 49.1448722, lng: 21.0071884 },
    { lat: 49.1439123, lng: 21.007677 },
    { lat: 49.1439152, lng: 21.0077282 },
    { lat: 49.1417407, lng: 21.0081 },
    { lat: 49.1403082, lng: 21.0077659 },
    { lat: 49.1398335, lng: 21.0075288 },
    { lat: 49.1384523, lng: 21.0070697 },
    { lat: 49.1383533, lng: 21.0070438 },
  ],
  Dubovica: [
    { lat: 49.1506361, lng: 20.9528765 },
    { lat: 49.1502751, lng: 20.9524808 },
    { lat: 49.1489809, lng: 20.9509949 },
    { lat: 49.1488803, lng: 20.9507428 },
    { lat: 49.1486971, lng: 20.9504797 },
    { lat: 49.1479512, lng: 20.9488667 },
    { lat: 49.1475535, lng: 20.9476643 },
    { lat: 49.1472617, lng: 20.9470477 },
    { lat: 49.1468809, lng: 20.9463995 },
    { lat: 49.1462186, lng: 20.9454696 },
    { lat: 49.145546, lng: 20.9443993 },
    { lat: 49.1451224, lng: 20.943542 },
    { lat: 49.1445539, lng: 20.9429644 },
    { lat: 49.1434191, lng: 20.9416007 },
    { lat: 49.1429931, lng: 20.9411462 },
    { lat: 49.1419077, lng: 20.940218 },
    { lat: 49.1405849, lng: 20.9393999 },
    { lat: 49.1397799, lng: 20.9384788 },
    { lat: 49.1395123, lng: 20.9380844 },
    { lat: 49.1393051, lng: 20.9381145 },
    { lat: 49.1390562, lng: 20.9380072 },
    { lat: 49.1379822, lng: 20.9370898 },
    { lat: 49.1376703, lng: 20.9367826 },
    { lat: 49.1365392, lng: 20.9351992 },
    { lat: 49.1364039, lng: 20.9350021 },
    { lat: 49.1362366, lng: 20.9349308 },
    { lat: 49.1353558, lng: 20.9345721 },
    { lat: 49.1350991, lng: 20.9345719 },
    { lat: 49.1348969, lng: 20.934483 },
    { lat: 49.1337928, lng: 20.9333711 },
    { lat: 49.1336331, lng: 20.9335413 },
    { lat: 49.1331942, lng: 20.9329061 },
    { lat: 49.1329836, lng: 20.9330051 },
    { lat: 49.1328018, lng: 20.9327169 },
    { lat: 49.1318, lng: 20.931738 },
    { lat: 49.1313001, lng: 20.9310922 },
    { lat: 49.1311753, lng: 20.9312604 },
    { lat: 49.1304652, lng: 20.9299843 },
    { lat: 49.1300948, lng: 20.9291408 },
    { lat: 49.1299391, lng: 20.9283856 },
    { lat: 49.1290913, lng: 20.9275415 },
    { lat: 49.1286521, lng: 20.9272917 },
    { lat: 49.1283403, lng: 20.9267564 },
    { lat: 49.1282801, lng: 20.9252966 },
    { lat: 49.1277506, lng: 20.9227621 },
    { lat: 49.1276254, lng: 20.9218421 },
    { lat: 49.1272368, lng: 20.9207949 },
    { lat: 49.1269638, lng: 20.9198344 },
    { lat: 49.1263617, lng: 20.9193354 },
    { lat: 49.1258995, lng: 20.9186411 },
    { lat: 49.1246515, lng: 20.9173034 },
    { lat: 49.1235386, lng: 20.9159003 },
    { lat: 49.1230127, lng: 20.9153595 },
    { lat: 49.1226313, lng: 20.9146921 },
    { lat: 49.1222401, lng: 20.9136693 },
    { lat: 49.1218116, lng: 20.9121033 },
    { lat: 49.121767, lng: 20.9117446 },
    { lat: 49.121826, lng: 20.9104834 },
    { lat: 49.1217701, lng: 20.9097245 },
    { lat: 49.1203597, lng: 20.9070828 },
    { lat: 49.1200234, lng: 20.9067519 },
    { lat: 49.1199264, lng: 20.9066115 },
    { lat: 49.119156, lng: 20.906059 },
    { lat: 49.1183552, lng: 20.9056448 },
    { lat: 49.1177031, lng: 20.9055606 },
    { lat: 49.1152478, lng: 20.9031774 },
    { lat: 49.1139839, lng: 20.9021408 },
    { lat: 49.1131103, lng: 20.9006301 },
    { lat: 49.1112977, lng: 20.9000451 },
    { lat: 49.1109924, lng: 20.8997693 },
    { lat: 49.1103646, lng: 20.8994434 },
    { lat: 49.1087118, lng: 20.8994297 },
    { lat: 49.1084739, lng: 20.8995155 },
    { lat: 49.1075674, lng: 20.8993019 },
    { lat: 49.1071754, lng: 20.8986946 },
    { lat: 49.1057172, lng: 20.8993116 },
    { lat: 49.1047176, lng: 20.8992658 },
    { lat: 49.1030751, lng: 20.8994622 },
    { lat: 49.1029552, lng: 20.899542 },
    { lat: 49.1026258, lng: 20.9000857 },
    { lat: 49.1018787, lng: 20.9015627 },
    { lat: 49.1018142, lng: 20.9020576 },
    { lat: 49.1018926, lng: 20.9027785 },
    { lat: 49.1016082, lng: 20.9050292 },
    { lat: 49.1002094, lng: 20.9074728 },
    { lat: 49.0990483, lng: 20.9092261 },
    { lat: 49.0984251, lng: 20.9098404 },
    { lat: 49.0975521, lng: 20.9117219 },
    { lat: 49.0974868, lng: 20.9118374 },
    { lat: 49.0957843, lng: 20.914849 },
    { lat: 49.0951503, lng: 20.9166808 },
    { lat: 49.0957881, lng: 20.9183141 },
    { lat: 49.0963009, lng: 20.9193746 },
    { lat: 49.098684, lng: 20.923779 },
    { lat: 49.0976048, lng: 20.9262387 },
    { lat: 49.0976829, lng: 20.9280974 },
    { lat: 49.0985088, lng: 20.9301757 },
    { lat: 49.0985979, lng: 20.9306178 },
    { lat: 49.0984941, lng: 20.931082 },
    { lat: 49.0974788, lng: 20.9327615 },
    { lat: 49.09786, lng: 20.9340687 },
    { lat: 49.0990412, lng: 20.9339772 },
    { lat: 49.1005824, lng: 20.9348382 },
    { lat: 49.1008904, lng: 20.9352942 },
    { lat: 49.1014678, lng: 20.9365386 },
    { lat: 49.1017312, lng: 20.9379578 },
    { lat: 49.1018931, lng: 20.9383565 },
    { lat: 49.1011663, lng: 20.9404562 },
    { lat: 49.1011005, lng: 20.9410439 },
    { lat: 49.1011035, lng: 20.9438341 },
    { lat: 49.1011698, lng: 20.9443365 },
    { lat: 49.1010409, lng: 20.9447922 },
    { lat: 49.1009587, lng: 20.9452989 },
    { lat: 49.10085, lng: 20.9468981 },
    { lat: 49.1009126, lng: 20.9486425 },
    { lat: 49.1009467, lng: 20.9496674 },
    { lat: 49.1019047, lng: 20.9488676 },
    { lat: 49.1023123, lng: 20.9487973 },
    { lat: 49.1024973, lng: 20.9488803 },
    { lat: 49.103132, lng: 20.9496241 },
    { lat: 49.1038368, lng: 20.9502715 },
    { lat: 49.1057945, lng: 20.9510629 },
    { lat: 49.1076117, lng: 20.9532849 },
    { lat: 49.108361, lng: 20.9534462 },
    { lat: 49.1089005, lng: 20.95292 },
    { lat: 49.1105986, lng: 20.953552 },
    { lat: 49.1110814, lng: 20.9561196 },
    { lat: 49.1112573, lng: 20.9577227 },
    { lat: 49.1128319, lng: 20.9579344 },
    { lat: 49.1137902, lng: 20.9576715 },
    { lat: 49.1149804, lng: 20.9577627 },
    { lat: 49.1154161, lng: 20.959293 },
    { lat: 49.1159556, lng: 20.9602837 },
    { lat: 49.1164017, lng: 20.960536 },
    { lat: 49.1169977, lng: 20.9602462 },
    { lat: 49.1174157, lng: 20.960362 },
    { lat: 49.117547, lng: 20.9603185 },
    { lat: 49.1180036, lng: 20.9604816 },
    { lat: 49.1181642, lng: 20.9606004 },
    { lat: 49.1186065, lng: 20.9606048 },
    { lat: 49.1187361, lng: 20.9605359 },
    { lat: 49.1188697, lng: 20.960255 },
    { lat: 49.1192252, lng: 20.9598831 },
    { lat: 49.1211466, lng: 20.9616869 },
    { lat: 49.1224001, lng: 20.9600183 },
    { lat: 49.1229721, lng: 20.9600288 },
    { lat: 49.1228715, lng: 20.9609255 },
    { lat: 49.1229877, lng: 20.961406 },
    { lat: 49.1247345, lng: 20.9614127 },
    { lat: 49.1251253, lng: 20.9625731 },
    { lat: 49.1251474, lng: 20.9634098 },
    { lat: 49.1261812, lng: 20.9639731 },
    { lat: 49.1276577, lng: 20.9643783 },
    { lat: 49.1280266, lng: 20.9647779 },
    { lat: 49.128937, lng: 20.9655901 },
    { lat: 49.1295425, lng: 20.9641617 },
    { lat: 49.1305411, lng: 20.9648845 },
    { lat: 49.131166, lng: 20.9657766 },
    { lat: 49.1319484, lng: 20.9665998 },
    { lat: 49.132904, lng: 20.9671106 },
    { lat: 49.1335428, lng: 20.9677433 },
    { lat: 49.1338203, lng: 20.9679319 },
    { lat: 49.135155, lng: 20.9686408 },
    { lat: 49.13697, lng: 20.9698239 },
    { lat: 49.1377107, lng: 20.9699273 },
    { lat: 49.1392854, lng: 20.9713605 },
    { lat: 49.1405999, lng: 20.972317 },
    { lat: 49.1422613, lng: 20.9739266 },
    { lat: 49.1424102, lng: 20.9740705 },
    { lat: 49.1430679, lng: 20.9725508 },
    { lat: 49.1433889, lng: 20.9716016 },
    { lat: 49.1435456, lng: 20.9705931 },
    { lat: 49.1435507, lng: 20.9694851 },
    { lat: 49.1436208, lng: 20.9688169 },
    { lat: 49.1437856, lng: 20.9679112 },
    { lat: 49.1438985, lng: 20.9675521 },
    { lat: 49.1441308, lng: 20.9670557 },
    { lat: 49.1446244, lng: 20.9664271 },
    { lat: 49.1450691, lng: 20.9660618 },
    { lat: 49.1458232, lng: 20.9652104 },
    { lat: 49.1462898, lng: 20.964893 },
    { lat: 49.146473, lng: 20.9646801 },
    { lat: 49.1464885, lng: 20.9637602 },
    { lat: 49.1465022, lng: 20.9629483 },
    { lat: 49.1463993, lng: 20.9621505 },
    { lat: 49.1459799, lng: 20.9615509 },
    { lat: 49.1458773, lng: 20.9614782 },
    { lat: 49.1458071, lng: 20.9609911 },
    { lat: 49.1458263, lng: 20.960788 },
    { lat: 49.1463288, lng: 20.9607068 },
    { lat: 49.1465589, lng: 20.9608894 },
    { lat: 49.1471952, lng: 20.9608313 },
    { lat: 49.1476125, lng: 20.9610986 },
    { lat: 49.1475762, lng: 20.9608864 },
    { lat: 49.1478702, lng: 20.9598388 },
    { lat: 49.1485477, lng: 20.9570302 },
    { lat: 49.1491529, lng: 20.955594 },
    { lat: 49.1505285, lng: 20.9530933 },
    { lat: 49.1506361, lng: 20.9528765 },
  ],
  Sabinov: [
    { lat: 49.0864631, lng: 21.1006648 },
    { lat: 49.0864368, lng: 21.1007192 },
    { lat: 49.0860277, lng: 21.1014342 },
    { lat: 49.0857942, lng: 21.1017356 },
    { lat: 49.0860623, lng: 21.1024858 },
    { lat: 49.0857639, lng: 21.1026533 },
    { lat: 49.0855978, lng: 21.1028782 },
    { lat: 49.0851442, lng: 21.1024537 },
    { lat: 49.0847146, lng: 21.1017628 },
    { lat: 49.0823614, lng: 21.1037248 },
    { lat: 49.0799626, lng: 21.1043722 },
    { lat: 49.079542, lng: 21.1038591 },
    { lat: 49.0794212, lng: 21.1035256 },
    { lat: 49.0792048, lng: 21.1035894 },
    { lat: 49.07875, lng: 21.1035843 },
    { lat: 49.0787114, lng: 21.1033646 },
    { lat: 49.0786131, lng: 21.1033152 },
    { lat: 49.0783947, lng: 21.1034897 },
    { lat: 49.0781831, lng: 21.1035556 },
    { lat: 49.0780276, lng: 21.1038314 },
    { lat: 49.0779138, lng: 21.1039001 },
    { lat: 49.0777566, lng: 21.1038015 },
    { lat: 49.0771537, lng: 21.1043416 },
    { lat: 49.0770515, lng: 21.1042813 },
    { lat: 49.0767063, lng: 21.1048482 },
    { lat: 49.0758944, lng: 21.1053599 },
    { lat: 49.0754469, lng: 21.1057973 },
    { lat: 49.0751831, lng: 21.105674 },
    { lat: 49.0749302, lng: 21.1058639 },
    { lat: 49.0747885, lng: 21.1058995 },
    { lat: 49.0746538, lng: 21.1060534 },
    { lat: 49.0742884, lng: 21.1062356 },
    { lat: 49.0740856, lng: 21.1062269 },
    { lat: 49.0738792, lng: 21.1063222 },
    { lat: 49.0736991, lng: 21.1065674 },
    { lat: 49.0734912, lng: 21.1066141 },
    { lat: 49.0731033, lng: 21.1069382 },
    { lat: 49.073144, lng: 21.1071954 },
    { lat: 49.0725576, lng: 21.1081408 },
    { lat: 49.0723109, lng: 21.1088059 },
    { lat: 49.0719031, lng: 21.1094595 },
    { lat: 49.0715659, lng: 21.1101765 },
    { lat: 49.07134, lng: 21.1105399 },
    { lat: 49.0710686, lng: 21.1106716 },
    { lat: 49.070992, lng: 21.1114135 },
    { lat: 49.0718792, lng: 21.1126554 },
    { lat: 49.0719286, lng: 21.112725 },
    { lat: 49.072999, lng: 21.1144546 },
    { lat: 49.0759668, lng: 21.1205737 },
    { lat: 49.0764333, lng: 21.1216499 },
    { lat: 49.0769059, lng: 21.1224949 },
    { lat: 49.077478, lng: 21.1238222 },
    { lat: 49.0776353, lng: 21.1245217 },
    { lat: 49.0783269, lng: 21.1259579 },
    { lat: 49.078554, lng: 21.1261275 },
    { lat: 49.0786842, lng: 21.1263979 },
    { lat: 49.0790866, lng: 21.1263475 },
    { lat: 49.0791655, lng: 21.1264565 },
    { lat: 49.0793819, lng: 21.1271808 },
    { lat: 49.0796295, lng: 21.1275298 },
    { lat: 49.0797543, lng: 21.1275673 },
    { lat: 49.079793, lng: 21.1276499 },
    { lat: 49.0799068, lng: 21.1280773 },
    { lat: 49.0797623, lng: 21.1280653 },
    { lat: 49.0799867, lng: 21.1282911 },
    { lat: 49.0798416, lng: 21.1286834 },
    { lat: 49.0799925, lng: 21.128859 },
    { lat: 49.0800761, lng: 21.1288722 },
    { lat: 49.0800492, lng: 21.129161 },
    { lat: 49.0800869, lng: 21.1293532 },
    { lat: 49.0803171, lng: 21.1295828 },
    { lat: 49.0803939, lng: 21.1295582 },
    { lat: 49.0805847, lng: 21.1296489 },
    { lat: 49.0805258, lng: 21.1300981 },
    { lat: 49.0806045, lng: 21.1302736 },
    { lat: 49.0806181, lng: 21.1304948 },
    { lat: 49.0805789, lng: 21.1306416 },
    { lat: 49.0806848, lng: 21.1308568 },
    { lat: 49.080684, lng: 21.1310406 },
    { lat: 49.0805346, lng: 21.1313022 },
    { lat: 49.0805381, lng: 21.1313744 },
    { lat: 49.0807671, lng: 21.1315683 },
    { lat: 49.0809034, lng: 21.1318211 },
    { lat: 49.0810709, lng: 21.1319595 },
    { lat: 49.0810416, lng: 21.1318809 },
    { lat: 49.0810912, lng: 21.1317441 },
    { lat: 49.0810785, lng: 21.1314829 },
    { lat: 49.0811732, lng: 21.1316005 },
    { lat: 49.0813641, lng: 21.1315894 },
    { lat: 49.0813575, lng: 21.131983 },
    { lat: 49.0813024, lng: 21.1320154 },
    { lat: 49.0812916, lng: 21.1321361 },
    { lat: 49.0815112, lng: 21.1322858 },
    { lat: 49.0816183, lng: 21.1325407 },
    { lat: 49.081968, lng: 21.1330308 },
    { lat: 49.0821663, lng: 21.1329998 },
    { lat: 49.0822056, lng: 21.1335328 },
    { lat: 49.0822867, lng: 21.1336001 },
    { lat: 49.0824856, lng: 21.1331357 },
    { lat: 49.0826221, lng: 21.1334319 },
    { lat: 49.0826588, lng: 21.1337706 },
    { lat: 49.0828229, lng: 21.1339032 },
    { lat: 49.0828915, lng: 21.1340802 },
    { lat: 49.08315, lng: 21.1342453 },
    { lat: 49.0833333, lng: 21.1347018 },
    { lat: 49.0834437, lng: 21.1346729 },
    { lat: 49.0835556, lng: 21.1348334 },
    { lat: 49.0835375, lng: 21.1351467 },
    { lat: 49.0837129, lng: 21.1351395 },
    { lat: 49.0838639, lng: 21.1349694 },
    { lat: 49.0839307, lng: 21.1350535 },
    { lat: 49.0839411, lng: 21.1352775 },
    { lat: 49.083963, lng: 21.1353279 },
    { lat: 49.0840187, lng: 21.1352405 },
    { lat: 49.0840483, lng: 21.1353931 },
    { lat: 49.0840849, lng: 21.1353344 },
    { lat: 49.0842321, lng: 21.1359336 },
    { lat: 49.0843761, lng: 21.1360411 },
    { lat: 49.0845428, lng: 21.1359359 },
    { lat: 49.0846722, lng: 21.1359425 },
    { lat: 49.0846858, lng: 21.1363702 },
    { lat: 49.0848136, lng: 21.1364958 },
    { lat: 49.08502, lng: 21.1363661 },
    { lat: 49.0852987, lng: 21.1363187 },
    { lat: 49.0854483, lng: 21.1366714 },
    { lat: 49.0855758, lng: 21.1367507 },
    { lat: 49.0856597, lng: 21.1367043 },
    { lat: 49.0857925, lng: 21.1365592 },
    { lat: 49.0860156, lng: 21.1362832 },
    { lat: 49.0861791, lng: 21.1362637 },
    { lat: 49.0862936, lng: 21.1363946 },
    { lat: 49.0864521, lng: 21.1364246 },
    { lat: 49.0866036, lng: 21.1362347 },
    { lat: 49.0869529, lng: 21.136498 },
    { lat: 49.0870878, lng: 21.1368109 },
    { lat: 49.0873672, lng: 21.1368248 },
    { lat: 49.0875765, lng: 21.1369254 },
    { lat: 49.0885181, lng: 21.1379833 },
    { lat: 49.0886871, lng: 21.1379877 },
    { lat: 49.0888913, lng: 21.1381497 },
    { lat: 49.089074, lng: 21.1381248 },
    { lat: 49.0894916, lng: 21.1382669 },
    { lat: 49.0897599, lng: 21.1382874 },
    { lat: 49.0900803, lng: 21.1380621 },
    { lat: 49.0904532, lng: 21.1376469 },
    { lat: 49.0907783, lng: 21.1376069 },
    { lat: 49.0909058, lng: 21.1376865 },
    { lat: 49.0911134, lng: 21.1376365 },
    { lat: 49.0914176, lng: 21.1373624 },
    { lat: 49.0917881, lng: 21.1371542 },
    { lat: 49.0934506, lng: 21.1355416 },
    { lat: 49.0936962, lng: 21.135224 },
    { lat: 49.0945401, lng: 21.1343745 },
    { lat: 49.0949877, lng: 21.1340784 },
    { lat: 49.0950788, lng: 21.1339026 },
    { lat: 49.095295, lng: 21.1331566 },
    { lat: 49.0966031, lng: 21.1319684 },
    { lat: 49.097181, lng: 21.1315955 },
    { lat: 49.0996569, lng: 21.1309658 },
    { lat: 49.1008181, lng: 21.12958 },
    { lat: 49.1010728, lng: 21.1290247 },
    { lat: 49.1012547, lng: 21.1290799 },
    { lat: 49.1019704, lng: 21.129011 },
    { lat: 49.1023352, lng: 21.1289464 },
    { lat: 49.1025834, lng: 21.1287852 },
    { lat: 49.1035129, lng: 21.1275423 },
    { lat: 49.1038438, lng: 21.1272626 },
    { lat: 49.104225, lng: 21.126333 },
    { lat: 49.1042425, lng: 21.1260002 },
    { lat: 49.1042524, lng: 21.1258131 },
    { lat: 49.1043666, lng: 21.1251917 },
    { lat: 49.1044729, lng: 21.124613 },
    { lat: 49.1043266, lng: 21.1242271 },
    { lat: 49.1042681, lng: 21.1240729 },
    { lat: 49.1043051, lng: 21.1238597 },
    { lat: 49.1045019, lng: 21.1233291 },
    { lat: 49.1045271, lng: 21.1230241 },
    { lat: 49.1044302, lng: 21.1229802 },
    { lat: 49.1043531, lng: 21.1226461 },
    { lat: 49.1042867, lng: 21.1222947 },
    { lat: 49.1043555, lng: 21.1222627 },
    { lat: 49.1044255, lng: 21.1222296 },
    { lat: 49.1049782, lng: 21.1218138 },
    { lat: 49.1058303, lng: 21.1214981 },
    { lat: 49.1061943, lng: 21.1214798 },
    { lat: 49.1065115, lng: 21.1216224 },
    { lat: 49.1065856, lng: 21.121507 },
    { lat: 49.1069811, lng: 21.1214193 },
    { lat: 49.10726, lng: 21.1215567 },
    { lat: 49.1078348, lng: 21.122006 },
    { lat: 49.1080675, lng: 21.1220704 },
    { lat: 49.1082569, lng: 21.1223998 },
    { lat: 49.1084078, lng: 21.1222953 },
    { lat: 49.108579, lng: 21.1219091 },
    { lat: 49.1089125, lng: 21.1220655 },
    { lat: 49.1090119, lng: 21.1215445 },
    { lat: 49.1092002, lng: 21.1214139 },
    { lat: 49.1095389, lng: 21.1214525 },
    { lat: 49.1095543, lng: 21.1214032 },
    { lat: 49.1094464, lng: 21.1211633 },
    { lat: 49.1099113, lng: 21.1207055 },
    { lat: 49.109996, lng: 21.1203972 },
    { lat: 49.1100751, lng: 21.1203719 },
    { lat: 49.110411, lng: 21.1207063 },
    { lat: 49.1106231, lng: 21.1204748 },
    { lat: 49.1108432, lng: 21.1203957 },
    { lat: 49.1110456, lng: 21.1204918 },
    { lat: 49.1112363, lng: 21.120297 },
    { lat: 49.1114549, lng: 21.1202316 },
    { lat: 49.11191, lng: 21.1202828 },
    { lat: 49.112091, lng: 21.119782 },
    { lat: 49.1122153, lng: 21.1196589 },
    { lat: 49.1123727, lng: 21.1195805 },
    { lat: 49.112595, lng: 21.1196751 },
    { lat: 49.1126281, lng: 21.1192976 },
    { lat: 49.1127155, lng: 21.1191773 },
    { lat: 49.1129689, lng: 21.119257 },
    { lat: 49.1130997, lng: 21.119127 },
    { lat: 49.1131526, lng: 21.1189148 },
    { lat: 49.1131402, lng: 21.1187332 },
    { lat: 49.1133093, lng: 21.1185993 },
    { lat: 49.1134188, lng: 21.1187593 },
    { lat: 49.1136064, lng: 21.1186057 },
    { lat: 49.1137031, lng: 21.1184218 },
    { lat: 49.1139619, lng: 21.118351 },
    { lat: 49.1141171, lng: 21.1182062 },
    { lat: 49.1142254, lng: 21.118214 },
    { lat: 49.1143253, lng: 21.1183152 },
    { lat: 49.1144443, lng: 21.1186613 },
    { lat: 49.1145768, lng: 21.1187945 },
    { lat: 49.114709, lng: 21.1187028 },
    { lat: 49.1146903, lng: 21.1183284 },
    { lat: 49.1149221, lng: 21.1179733 },
    { lat: 49.1150376, lng: 21.1176102 },
    { lat: 49.1151623, lng: 21.1177777 },
    { lat: 49.1152994, lng: 21.1174931 },
    { lat: 49.1155846, lng: 21.1173977 },
    { lat: 49.1158709, lng: 21.1166361 },
    { lat: 49.1159758, lng: 21.1166064 },
    { lat: 49.1160248, lng: 21.1169833 },
    { lat: 49.1161393, lng: 21.1170096 },
    { lat: 49.1163064, lng: 21.1168504 },
    { lat: 49.1165957, lng: 21.1164277 },
    { lat: 49.1168337, lng: 21.1160498 },
    { lat: 49.117094, lng: 21.1160643 },
    { lat: 49.117507, lng: 21.1156088 },
    { lat: 49.1181461, lng: 21.1155346 },
    { lat: 49.1184001, lng: 21.1153459 },
    { lat: 49.1185314, lng: 21.1154037 },
    { lat: 49.1187623, lng: 21.1152332 },
    { lat: 49.1189261, lng: 21.1152908 },
    { lat: 49.1191355, lng: 21.1149714 },
    { lat: 49.1193408, lng: 21.1148488 },
    { lat: 49.1194269, lng: 21.1146215 },
    { lat: 49.1195475, lng: 21.114559 },
    { lat: 49.1196252, lng: 21.1146956 },
    { lat: 49.1197967, lng: 21.1147853 },
    { lat: 49.1198579, lng: 21.1146572 },
    { lat: 49.120118, lng: 21.1147292 },
    { lat: 49.1202158, lng: 21.1145146 },
    { lat: 49.1203189, lng: 21.1144643 },
    { lat: 49.1214279, lng: 21.1143442 },
    { lat: 49.1215629, lng: 21.1146209 },
    { lat: 49.1217497, lng: 21.1147271 },
    { lat: 49.1218603, lng: 21.1146007 },
    { lat: 49.1218816, lng: 21.1146322 },
    { lat: 49.1219304, lng: 21.1148912 },
    { lat: 49.1220911, lng: 21.1150564 },
    { lat: 49.1221009, lng: 21.115336 },
    { lat: 49.1222386, lng: 21.1154174 },
    { lat: 49.1222339, lng: 21.1156549 },
    { lat: 49.1224048, lng: 21.1155783 },
    { lat: 49.1230891, lng: 21.1155108 },
    { lat: 49.1232303, lng: 21.115288 },
    { lat: 49.1233007, lng: 21.1150177 },
    { lat: 49.1234847, lng: 21.1148628 },
    { lat: 49.1236708, lng: 21.1149831 },
    { lat: 49.1239242, lng: 21.1136116 },
    { lat: 49.1239653, lng: 21.1126719 },
    { lat: 49.1244284, lng: 21.1123383 },
    { lat: 49.1243871, lng: 21.1121002 },
    { lat: 49.1252458, lng: 21.1110482 },
    { lat: 49.1262188, lng: 21.1095554 },
    { lat: 49.1259845, lng: 21.1091578 },
    { lat: 49.1263587, lng: 21.1081527 },
    { lat: 49.1262535, lng: 21.1077441 },
    { lat: 49.1260729, lng: 21.1075537 },
    { lat: 49.1259843, lng: 21.1073202 },
    { lat: 49.12524, lng: 21.1064541 },
    { lat: 49.1252593, lng: 21.1063553 },
    { lat: 49.1251187, lng: 21.1061099 },
    { lat: 49.1249471, lng: 21.1059225 },
    { lat: 49.1246874, lng: 21.1057869 },
    { lat: 49.1244268, lng: 21.1058378 },
    { lat: 49.1243782, lng: 21.1061476 },
    { lat: 49.1240592, lng: 21.1065179 },
    { lat: 49.1231963, lng: 21.1077564 },
    { lat: 49.1231258, lng: 21.1076675 },
    { lat: 49.1226815, lng: 21.1088347 },
    { lat: 49.1224518, lng: 21.1083771 },
    { lat: 49.122265, lng: 21.107805 },
    { lat: 49.1213679, lng: 21.1084703 },
    { lat: 49.1202287, lng: 21.1091285 },
    { lat: 49.1199022, lng: 21.1092637 },
    { lat: 49.1189832, lng: 21.1093519 },
    { lat: 49.1185526, lng: 21.1093284 },
    { lat: 49.1180512, lng: 21.109099 },
    { lat: 49.1164458, lng: 21.1088328 },
    { lat: 49.1155343, lng: 21.1084749 },
    { lat: 49.1152103, lng: 21.1084454 },
    { lat: 49.1151477, lng: 21.1077862 },
    { lat: 49.1151691, lng: 21.1071182 },
    { lat: 49.1156179, lng: 21.1055273 },
    { lat: 49.114986, lng: 21.1049444 },
    { lat: 49.1151155, lng: 21.1044893 },
    { lat: 49.1155821, lng: 21.1035652 },
    { lat: 49.1168087, lng: 21.1040559 },
    { lat: 49.1174343, lng: 21.1042236 },
    { lat: 49.1174447, lng: 21.1044093 },
    { lat: 49.1185036, lng: 21.1042718 },
    { lat: 49.1192212, lng: 21.1040451 },
    { lat: 49.1192088, lng: 21.1039745 },
    { lat: 49.1198195, lng: 21.1036838 },
    { lat: 49.1200953, lng: 21.1034058 },
    { lat: 49.1201664, lng: 21.1036531 },
    { lat: 49.1210012, lng: 21.1031593 },
    { lat: 49.1216039, lng: 21.1029662 },
    { lat: 49.1225044, lng: 21.1029353 },
    { lat: 49.122506, lng: 21.1028806 },
    { lat: 49.1234131, lng: 21.1029897 },
    { lat: 49.1234351, lng: 21.1027649 },
    { lat: 49.1240348, lng: 21.1028954 },
    { lat: 49.1240566, lng: 21.1028406 },
    { lat: 49.1244803, lng: 21.1029659 },
    { lat: 49.1251967, lng: 21.1028103 },
    { lat: 49.1260791, lng: 21.1034495 },
    { lat: 49.1264111, lng: 21.1030652 },
    { lat: 49.1264643, lng: 21.1031816 },
    { lat: 49.1262244, lng: 21.1035271 },
    { lat: 49.1258692, lng: 21.1038197 },
    { lat: 49.1257751, lng: 21.1037235 },
    { lat: 49.1253205, lng: 21.1041834 },
    { lat: 49.1253787, lng: 21.1045269 },
    { lat: 49.1258506, lng: 21.1050305 },
    { lat: 49.1263092, lng: 21.105367 },
    { lat: 49.1266153, lng: 21.1056878 },
    { lat: 49.1271285, lng: 21.1062502 },
    { lat: 49.1272947, lng: 21.1066974 },
    { lat: 49.1277147, lng: 21.1070256 },
    { lat: 49.1278377, lng: 21.106609 },
    { lat: 49.1278844, lng: 21.1062743 },
    { lat: 49.128414, lng: 21.1052411 },
    { lat: 49.1286948, lng: 21.1043744 },
    { lat: 49.1292067, lng: 21.1038114 },
    { lat: 49.1303019, lng: 21.1019464 },
    { lat: 49.1305087, lng: 21.1017999 },
    { lat: 49.1307758, lng: 21.1014947 },
    { lat: 49.1307947, lng: 21.1009927 },
    { lat: 49.1309629, lng: 21.1008699 },
    { lat: 49.1309352, lng: 21.1007789 },
    { lat: 49.1310254, lng: 21.1005756 },
    { lat: 49.1309506, lng: 21.1001388 },
    { lat: 49.1310437, lng: 21.1000572 },
    { lat: 49.1309244, lng: 21.0997401 },
    { lat: 49.1308022, lng: 21.0996493 },
    { lat: 49.1307553, lng: 21.0994163 },
    { lat: 49.1306152, lng: 21.099325 },
    { lat: 49.1305633, lng: 21.0990755 },
    { lat: 49.130605, lng: 21.0990746 },
    { lat: 49.1305309, lng: 21.0987998 },
    { lat: 49.1307433, lng: 21.0989001 },
    { lat: 49.1309378, lng: 21.0992464 },
    { lat: 49.1313984, lng: 21.0991497 },
    { lat: 49.1316679, lng: 21.0987089 },
    { lat: 49.1324944, lng: 21.0985156 },
    { lat: 49.1326258, lng: 21.0980807 },
    { lat: 49.132967, lng: 21.0978753 },
    { lat: 49.1329544, lng: 21.0976581 },
    { lat: 49.1327743, lng: 21.0973063 },
    { lat: 49.1327839, lng: 21.0965157 },
    { lat: 49.1326502, lng: 21.0961031 },
    { lat: 49.1325972, lng: 21.0957146 },
    { lat: 49.1320706, lng: 21.0959927 },
    { lat: 49.1317182, lng: 21.0960926 },
    { lat: 49.1297105, lng: 21.0964114 },
    { lat: 49.1291198, lng: 21.0963375 },
    { lat: 49.1280064, lng: 21.0958043 },
    { lat: 49.1271442, lng: 21.0955806 },
    { lat: 49.1260592, lng: 21.0954058 },
    { lat: 49.1253283, lng: 21.0954238 },
    { lat: 49.1246072, lng: 21.0949385 },
    { lat: 49.1233087, lng: 21.0945525 },
    { lat: 49.1232782, lng: 21.0939906 },
    { lat: 49.1234234, lng: 21.0920985 },
    { lat: 49.1232616, lng: 21.0920028 },
    { lat: 49.1229176, lng: 21.0920772 },
    { lat: 49.12271, lng: 21.0919561 },
    { lat: 49.1224202, lng: 21.0914876 },
    { lat: 49.1224623, lng: 21.091358 },
    { lat: 49.1226684, lng: 21.0912554 },
    { lat: 49.1228388, lng: 21.0908187 },
    { lat: 49.1228216, lng: 21.0907675 },
    { lat: 49.1236161, lng: 21.0898935 },
    { lat: 49.124212, lng: 21.089031 },
    { lat: 49.1230437, lng: 21.0859062 },
    { lat: 49.1229193, lng: 21.0854703 },
    { lat: 49.1227128, lng: 21.0852098 },
    { lat: 49.1227655, lng: 21.0849921 },
    { lat: 49.1227791, lng: 21.0844665 },
    { lat: 49.1228782, lng: 21.0840128 },
    { lat: 49.1227284, lng: 21.0843571 },
    { lat: 49.1225084, lng: 21.0838137 },
    { lat: 49.1236815, lng: 21.0824316 },
    { lat: 49.1232061, lng: 21.0815735 },
    { lat: 49.1242787, lng: 21.0802125 },
    { lat: 49.1237371, lng: 21.0795757 },
    { lat: 49.1231407, lng: 21.0785297 },
    { lat: 49.1234171, lng: 21.0782001 },
    { lat: 49.123171, lng: 21.0778221 },
    { lat: 49.1229724, lng: 21.0772103 },
    { lat: 49.1228115, lng: 21.0769292 },
    { lat: 49.1224918, lng: 21.0765431 },
    { lat: 49.1228004, lng: 21.0763077 },
    { lat: 49.1227324, lng: 21.076187 },
    { lat: 49.1226021, lng: 21.0761608 },
    { lat: 49.1221386, lng: 21.076554 },
    { lat: 49.1219622, lng: 21.0766261 },
    { lat: 49.121747, lng: 21.0765484 },
    { lat: 49.1215416, lng: 21.0767768 },
    { lat: 49.1212359, lng: 21.0769646 },
    { lat: 49.1211667, lng: 21.0773344 },
    { lat: 49.1212049, lng: 21.0774361 },
    { lat: 49.1211807, lng: 21.0774908 },
    { lat: 49.1210776, lng: 21.077358 },
    { lat: 49.1208063, lng: 21.0772169 },
    { lat: 49.120718, lng: 21.077382 },
    { lat: 49.1205318, lng: 21.0773998 },
    { lat: 49.1199246, lng: 21.0777581 },
    { lat: 49.1197297, lng: 21.0779692 },
    { lat: 49.1195988, lng: 21.0779545 },
    { lat: 49.1193745, lng: 21.077732 },
    { lat: 49.1191913, lng: 21.0780191 },
    { lat: 49.119128, lng: 21.0779412 },
    { lat: 49.1193649, lng: 21.0774334 },
    { lat: 49.1195551, lng: 21.0776125 },
    { lat: 49.1195048, lng: 21.0776917 },
    { lat: 49.1196453, lng: 21.0778224 },
    { lat: 49.1197449, lng: 21.0778089 },
    { lat: 49.119794, lng: 21.0777226 },
    { lat: 49.1197955, lng: 21.0775648 },
    { lat: 49.1201128, lng: 21.0774581 },
    { lat: 49.1201785, lng: 21.0772944 },
    { lat: 49.1203523, lng: 21.0772361 },
    { lat: 49.1204498, lng: 21.0770194 },
    { lat: 49.1207341, lng: 21.0769715 },
    { lat: 49.1209022, lng: 21.0767373 },
    { lat: 49.1209363, lng: 21.0768519 },
    { lat: 49.1210309, lng: 21.0767514 },
    { lat: 49.1211536, lng: 21.0764728 },
    { lat: 49.121273, lng: 21.0764401 },
    { lat: 49.1214686, lng: 21.0765002 },
    { lat: 49.1215733, lng: 21.0762952 },
    { lat: 49.1217368, lng: 21.0762679 },
    { lat: 49.1217824, lng: 21.0764247 },
    { lat: 49.1219217, lng: 21.0764531 },
    { lat: 49.1222048, lng: 21.0763037 },
    { lat: 49.122549, lng: 21.0758734 },
    { lat: 49.1228058, lng: 21.0759836 },
    { lat: 49.1229722, lng: 21.0759445 },
    { lat: 49.1231773, lng: 21.0754679 },
    { lat: 49.1233969, lng: 21.0751899 },
    { lat: 49.1237438, lng: 21.0753069 },
    { lat: 49.1238092, lng: 21.0750308 },
    { lat: 49.1239882, lng: 21.0747544 },
    { lat: 49.124311, lng: 21.0744306 },
    { lat: 49.1243838, lng: 21.0741776 },
    { lat: 49.1245485, lng: 21.0739461 },
    { lat: 49.1248668, lng: 21.0739762 },
    { lat: 49.1250459, lng: 21.0741162 },
    { lat: 49.1249923, lng: 21.0736772 },
    { lat: 49.1252127, lng: 21.0729705 },
    { lat: 49.1255668, lng: 21.0724036 },
    { lat: 49.1249387, lng: 21.0727445 },
    { lat: 49.1235548, lng: 21.0737013 },
    { lat: 49.123255, lng: 21.0724615 },
    { lat: 49.1230206, lng: 21.0718966 },
    { lat: 49.122995, lng: 21.0718726 },
    { lat: 49.1228331, lng: 21.0720806 },
    { lat: 49.1221485, lng: 21.072628 },
    { lat: 49.121868, lng: 21.0729673 },
    { lat: 49.1214564, lng: 21.0738525 },
    { lat: 49.1207254, lng: 21.0746361 },
    { lat: 49.1197318, lng: 21.0755493 },
    { lat: 49.1195722, lng: 21.0756611 },
    { lat: 49.1195876, lng: 21.0756118 },
    { lat: 49.1186942, lng: 21.0753013 },
    { lat: 49.1182963, lng: 21.0753505 },
    { lat: 49.1179044, lng: 21.0758346 },
    { lat: 49.1175598, lng: 21.0760022 },
    { lat: 49.1171897, lng: 21.0764603 },
    { lat: 49.1167509, lng: 21.0759768 },
    { lat: 49.1165239, lng: 21.0755634 },
    { lat: 49.1137124, lng: 21.072312 },
    { lat: 49.1135361, lng: 21.0721081 },
    { lat: 49.1125068, lng: 21.0711469 },
    { lat: 49.111004, lng: 21.069451 },
    { lat: 49.1105152, lng: 21.0690957 },
    { lat: 49.1100948, lng: 21.068935 },
    { lat: 49.1095408, lng: 21.0685048 },
    { lat: 49.1092657, lng: 21.0682793 },
    { lat: 49.108754, lng: 21.0676312 },
    { lat: 49.1077365, lng: 21.0670328 },
    { lat: 49.1071975, lng: 21.0665833 },
    { lat: 49.1070014, lng: 21.0665369 },
    { lat: 49.1065372, lng: 21.067044 },
    { lat: 49.1062918, lng: 21.0674262 },
    { lat: 49.1061749, lng: 21.0683354 },
    { lat: 49.1041109, lng: 21.0690348 },
    { lat: 49.1037304, lng: 21.069097 },
    { lat: 49.1033082, lng: 21.0692698 },
    { lat: 49.103006, lng: 21.0695959 },
    { lat: 49.1028137, lng: 21.0701928 },
    { lat: 49.1022333, lng: 21.0711492 },
    { lat: 49.1015023, lng: 21.072662 },
    { lat: 49.1012691, lng: 21.0730361 },
    { lat: 49.1011462, lng: 21.0735201 },
    { lat: 49.1007949, lng: 21.0738286 },
    { lat: 49.1004288, lng: 21.0746238 },
    { lat: 49.1001854, lng: 21.075032 },
    { lat: 49.099182, lng: 21.0758088 },
    { lat: 49.0983676, lng: 21.0769032 },
    { lat: 49.0980857, lng: 21.0774126 },
    { lat: 49.0978269, lng: 21.0775217 },
    { lat: 49.0968705, lng: 21.0784011 },
    { lat: 49.0957282, lng: 21.0791005 },
    { lat: 49.0950054, lng: 21.079437 },
    { lat: 49.0946431, lng: 21.0797858 },
    { lat: 49.0933758, lng: 21.0807296 },
    { lat: 49.093161, lng: 21.0809453 },
    { lat: 49.093058, lng: 21.0807838 },
    { lat: 49.0924927, lng: 21.081546 },
    { lat: 49.0917283, lng: 21.0828325 },
    { lat: 49.0912495, lng: 21.0834089 },
    { lat: 49.0901297, lng: 21.0844195 },
    { lat: 49.0889824, lng: 21.0865753 },
    { lat: 49.0871942, lng: 21.089132 },
    { lat: 49.0860392, lng: 21.0904189 },
    { lat: 49.0851693, lng: 21.091295 },
    { lat: 49.0852367, lng: 21.0913647 },
    { lat: 49.085136, lng: 21.0916293 },
    { lat: 49.0851558, lng: 21.0918202 },
    { lat: 49.0852036, lng: 21.0920547 },
    { lat: 49.0853419, lng: 21.0923589 },
    { lat: 49.0852087, lng: 21.0925229 },
    { lat: 49.0852037, lng: 21.0929325 },
    { lat: 49.0850988, lng: 21.0932412 },
    { lat: 49.085143, lng: 21.0937743 },
    { lat: 49.0850085, lng: 21.093897 },
    { lat: 49.0846949, lng: 21.0936988 },
    { lat: 49.0844322, lng: 21.0936842 },
    { lat: 49.0843214, lng: 21.0941574 },
    { lat: 49.0843928, lng: 21.0943449 },
    { lat: 49.0843515, lng: 21.0946398 },
    { lat: 49.0847367, lng: 21.0947886 },
    { lat: 49.0849671, lng: 21.0950982 },
    { lat: 49.0848979, lng: 21.0952196 },
    { lat: 49.0847782, lng: 21.0958048 },
    { lat: 49.0848396, lng: 21.0959636 },
    { lat: 49.0847953, lng: 21.0961988 },
    { lat: 49.0848394, lng: 21.0965606 },
    { lat: 49.0848905, lng: 21.096675 },
    { lat: 49.0850581, lng: 21.0968133 },
    { lat: 49.0850491, lng: 21.0974071 },
    { lat: 49.0853985, lng: 21.0979872 },
    { lat: 49.0853914, lng: 21.0986778 },
    { lat: 49.0855296, lng: 21.0988183 },
    { lat: 49.085546, lng: 21.0992565 },
    { lat: 49.0855913, lng: 21.0994058 },
    { lat: 49.085686, lng: 21.0994095 },
    { lat: 49.0857507, lng: 21.0991828 },
    { lat: 49.0860495, lng: 21.0991693 },
    { lat: 49.086321, lng: 21.0995232 },
    { lat: 49.0864063, lng: 21.0998307 },
    { lat: 49.0862184, lng: 21.1001207 },
    { lat: 49.0861196, lng: 21.1004071 },
    { lat: 49.0861565, lng: 21.1006115 },
    { lat: 49.0862643, lng: 21.100672 },
    { lat: 49.0864631, lng: 21.1006648 },
  ],
  Bodovce: [
    { lat: 49.1007049, lng: 21.1853892 },
    { lat: 49.0999839, lng: 21.1871289 },
    { lat: 49.0984823, lng: 21.1878288 },
    { lat: 49.0976547, lng: 21.1878841 },
    { lat: 49.0972331, lng: 21.1877563 },
    { lat: 49.0969398, lng: 21.1881966 },
    { lat: 49.096696, lng: 21.189079 },
    { lat: 49.096521, lng: 21.1894554 },
    { lat: 49.0962725, lng: 21.1903955 },
    { lat: 49.0961485, lng: 21.1910746 },
    { lat: 49.0959453, lng: 21.1916656 },
    { lat: 49.0958708, lng: 21.1923464 },
    { lat: 49.0958803, lng: 21.1927562 },
    { lat: 49.095634, lng: 21.1947784 },
    { lat: 49.0953559, lng: 21.1953188 },
    { lat: 49.0953162, lng: 21.1957034 },
    { lat: 49.0954548, lng: 21.1956416 },
    { lat: 49.0954917, lng: 21.1957726 },
    { lat: 49.0955829, lng: 21.1966102 },
    { lat: 49.0959039, lng: 21.1980962 },
    { lat: 49.0963516, lng: 21.1997906 },
    { lat: 49.0970359, lng: 21.1995401 },
    { lat: 49.0983427, lng: 21.1992971 },
    { lat: 49.0998596, lng: 21.1988327 },
    { lat: 49.1016638, lng: 21.1985188 },
    { lat: 49.1020027, lng: 21.1983087 },
    { lat: 49.1027363, lng: 21.1973252 },
    { lat: 49.1030778, lng: 21.1969489 },
    { lat: 49.1039016, lng: 21.1962641 },
    { lat: 49.1039705, lng: 21.1963101 },
    { lat: 49.1047482, lng: 21.1957374 },
    { lat: 49.1056253, lng: 21.1947427 },
    { lat: 49.1069064, lng: 21.1926316 },
    { lat: 49.1083987, lng: 21.1925613 },
    { lat: 49.1091227, lng: 21.1914597 },
    { lat: 49.1093413, lng: 21.1906752 },
    { lat: 49.1094406, lng: 21.190464 },
    { lat: 49.1100456, lng: 21.189769 },
    { lat: 49.1105756, lng: 21.1894469 },
    { lat: 49.1108095, lng: 21.1899066 },
    { lat: 49.1133024, lng: 21.1880488 },
    { lat: 49.113262, lng: 21.187513 },
    { lat: 49.1143546, lng: 21.1868957 },
    { lat: 49.1151047, lng: 21.1862697 },
    { lat: 49.1157186, lng: 21.1858574 },
    { lat: 49.1165438, lng: 21.185078 },
    { lat: 49.1171768, lng: 21.1848086 },
    { lat: 49.1171567, lng: 21.1846341 },
    { lat: 49.1174589, lng: 21.1846168 },
    { lat: 49.1179739, lng: 21.1848895 },
    { lat: 49.1180657, lng: 21.1846942 },
    { lat: 49.1183989, lng: 21.185025 },
    { lat: 49.1186027, lng: 21.1850655 },
    { lat: 49.1193902, lng: 21.184989 },
    { lat: 49.119791, lng: 21.1850616 },
    { lat: 49.1199782, lng: 21.1850085 },
    { lat: 49.1204315, lng: 21.1846713 },
    { lat: 49.1214035, lng: 21.1836922 },
    { lat: 49.1217617, lng: 21.1834204 },
    { lat: 49.1219224, lng: 21.1831982 },
    { lat: 49.1229826, lng: 21.1809365 },
    { lat: 49.1232594, lng: 21.1806488 },
    { lat: 49.1235196, lng: 21.1801847 },
    { lat: 49.123666, lng: 21.1800472 },
    { lat: 49.1242533, lng: 21.1798652 },
    { lat: 49.1243972, lng: 21.1800024 },
    { lat: 49.1245584, lng: 21.1803378 },
    { lat: 49.1247453, lng: 21.1805153 },
    { lat: 49.1249941, lng: 21.1804879 },
    { lat: 49.1251042, lng: 21.1803725 },
    { lat: 49.1252581, lng: 21.1800383 },
    { lat: 49.1253421, lng: 21.1794153 },
    { lat: 49.1259489, lng: 21.1779868 },
    { lat: 49.1260675, lng: 21.1778585 },
    { lat: 49.1264058, lng: 21.1777366 },
    { lat: 49.1265557, lng: 21.1779229 },
    { lat: 49.1267316, lng: 21.1779367 },
    { lat: 49.1269914, lng: 21.1777551 },
    { lat: 49.1271907, lng: 21.177792 },
    { lat: 49.1276641, lng: 21.1782811 },
    { lat: 49.1284854, lng: 21.1787093 },
    { lat: 49.1287086, lng: 21.1787574 },
    { lat: 49.1291699, lng: 21.1787914 },
    { lat: 49.1298051, lng: 21.1786996 },
    { lat: 49.1302764, lng: 21.1788026 },
    { lat: 49.1310022, lng: 21.1783345 },
    { lat: 49.1314161, lng: 21.1783292 },
    { lat: 49.1317416, lng: 21.1782254 },
    { lat: 49.1320156, lng: 21.1784613 },
    { lat: 49.1321948, lng: 21.1793334 },
    { lat: 49.1322081, lng: 21.1795775 },
    { lat: 49.1323049, lng: 21.1798295 },
    { lat: 49.1325191, lng: 21.1802028 },
    { lat: 49.1327972, lng: 21.1804992 },
    { lat: 49.1330636, lng: 21.1806325 },
    { lat: 49.1335122, lng: 21.1807261 },
    { lat: 49.1338915, lng: 21.1806245 },
    { lat: 49.1343128, lng: 21.1802385 },
    { lat: 49.1346955, lng: 21.1802092 },
    { lat: 49.1350955, lng: 21.1804752 },
    { lat: 49.1354828, lng: 21.1808384 },
    { lat: 49.1358408, lng: 21.1810302 },
    { lat: 49.1362053, lng: 21.1810598 },
    { lat: 49.1366746, lng: 21.1812797 },
    { lat: 49.1368048, lng: 21.1814068 },
    { lat: 49.1369891, lng: 21.1819102 },
    { lat: 49.1370857, lng: 21.1823683 },
    { lat: 49.1373325, lng: 21.1825616 },
    { lat: 49.1391802, lng: 21.1828145 },
    { lat: 49.1397514, lng: 21.1827961 },
    { lat: 49.1398427, lng: 21.1828426 },
    { lat: 49.1400229, lng: 21.1832073 },
    { lat: 49.1401885, lng: 21.183353 },
    { lat: 49.1408567, lng: 21.1834173 },
    { lat: 49.1412404, lng: 21.1839563 },
    { lat: 49.1415559, lng: 21.1840843 },
    { lat: 49.1419611, lng: 21.1845832 },
    { lat: 49.1422423, lng: 21.1848385 },
    { lat: 49.1426163, lng: 21.1854267 },
    { lat: 49.1427496, lng: 21.1854422 },
    { lat: 49.1435786, lng: 21.1851401 },
    { lat: 49.1437772, lng: 21.185162 },
    { lat: 49.1440639, lng: 21.1853676 },
    { lat: 49.1446355, lng: 21.1855113 },
    { lat: 49.1448103, lng: 21.1856697 },
    { lat: 49.1450753, lng: 21.1857209 },
    { lat: 49.1454161, lng: 21.186079 },
    { lat: 49.145834, lng: 21.186056 },
    { lat: 49.145823, lng: 21.186026 },
    { lat: 49.145841, lng: 21.185821 },
    { lat: 49.145854, lng: 21.185252 },
    { lat: 49.145913, lng: 21.183504 },
    { lat: 49.146007, lng: 21.18316 },
    { lat: 49.146926, lng: 21.18137 },
    { lat: 49.14732, lng: 21.180746 },
    { lat: 49.147575, lng: 21.180274 },
    { lat: 49.148095, lng: 21.180055 },
    { lat: 49.148738, lng: 21.179685 },
    { lat: 49.14888, lng: 21.179374 },
    { lat: 49.149165, lng: 21.178898 },
    { lat: 49.150124, lng: 21.178157 },
    { lat: 49.150525, lng: 21.177871 },
    { lat: 49.151035, lng: 21.177998 },
    { lat: 49.151221, lng: 21.178095 },
    { lat: 49.151465, lng: 21.178342 },
    { lat: 49.151558, lng: 21.178401 },
    { lat: 49.152023, lng: 21.178356 },
    { lat: 49.152324, lng: 21.178248 },
    { lat: 49.152522, lng: 21.178262 },
    { lat: 49.15254, lng: 21.178258 },
    { lat: 49.152607, lng: 21.17821 },
    { lat: 49.152971, lng: 21.177936 },
    { lat: 49.153173, lng: 21.177667 },
    { lat: 49.153525, lng: 21.177526 },
    { lat: 49.153759, lng: 21.177244 },
    { lat: 49.154012, lng: 21.177182 },
    { lat: 49.154235, lng: 21.177094 },
    { lat: 49.154851, lng: 21.177118 },
    { lat: 49.155473, lng: 21.177088 },
    { lat: 49.15611, lng: 21.176949 },
    { lat: 49.156373, lng: 21.176736 },
    { lat: 49.156651, lng: 21.176445 },
    { lat: 49.156855, lng: 21.176027 },
    { lat: 49.15692, lng: 21.175705 },
    { lat: 49.157358, lng: 21.174594 },
    { lat: 49.157622, lng: 21.174243 },
    { lat: 49.157766, lng: 21.174152 },
    { lat: 49.15791, lng: 21.17415 },
    { lat: 49.158076, lng: 21.174037 },
    { lat: 49.158376, lng: 21.173799 },
    { lat: 49.158633, lng: 21.173754 },
    { lat: 49.158996, lng: 21.173881 },
    { lat: 49.159511, lng: 21.173725 },
    { lat: 49.159768, lng: 21.173574 },
    { lat: 49.160002, lng: 21.17349 },
    { lat: 49.160385, lng: 21.173186 },
    { lat: 49.160487, lng: 21.173032 },
    { lat: 49.160752, lng: 21.172946 },
    { lat: 49.160899, lng: 21.172834 },
    { lat: 49.161148, lng: 21.172499 },
    { lat: 49.161413, lng: 21.172226 },
    { lat: 49.161551, lng: 21.172014 },
    { lat: 49.161798, lng: 21.171353 },
    { lat: 49.161969, lng: 21.170939 },
    { lat: 49.162114, lng: 21.170649 },
    { lat: 49.162219, lng: 21.170295 },
    { lat: 49.162302, lng: 21.169894 },
    { lat: 49.162375, lng: 21.169618 },
    { lat: 49.162777, lng: 21.168919 },
    { lat: 49.163001, lng: 21.168805 },
    { lat: 49.163379, lng: 21.168397 },
    { lat: 49.163695, lng: 21.168221 },
    { lat: 49.163902, lng: 21.168179 },
    { lat: 49.164052, lng: 21.168036 },
    { lat: 49.164074, lng: 21.167999 },
    { lat: 49.164264, lng: 21.167674 },
    { lat: 49.164341, lng: 21.167433 },
    { lat: 49.16448, lng: 21.167272 },
    { lat: 49.164587, lng: 21.167016 },
    { lat: 49.164762, lng: 21.166577 },
    { lat: 49.164875, lng: 21.166358 },
    { lat: 49.164975, lng: 21.16595 },
    { lat: 49.164998, lng: 21.165909 },
    { lat: 49.165376, lng: 21.164949 },
    { lat: 49.165664, lng: 21.164263 },
    { lat: 49.165736, lng: 21.164154 },
    { lat: 49.165942, lng: 21.163848 },
    { lat: 49.1657886, lng: 21.1632051 },
    { lat: 49.1657379, lng: 21.1623426 },
    { lat: 49.1656562, lng: 21.1591 },
    { lat: 49.1655864, lng: 21.1584902 },
    { lat: 49.1656011, lng: 21.1580278 },
    { lat: 49.1662794, lng: 21.1548051 },
    { lat: 49.166417, lng: 21.1535274 },
    { lat: 49.1663867, lng: 21.1524915 },
    { lat: 49.1652758, lng: 21.1514502 },
    { lat: 49.164887, lng: 21.1510415 },
    { lat: 49.1645285, lng: 21.1515927 },
    { lat: 49.1643505, lng: 21.1518327 },
    { lat: 49.1642205, lng: 21.1521734 },
    { lat: 49.1639682, lng: 21.1524148 },
    { lat: 49.1630442, lng: 21.153056 },
    { lat: 49.162913, lng: 21.1532468 },
    { lat: 49.1628356, lng: 21.1536854 },
    { lat: 49.1622666, lng: 21.1542715 },
    { lat: 49.1621441, lng: 21.1545196 },
    { lat: 49.1619887, lng: 21.1546259 },
    { lat: 49.1612591, lng: 21.1560791 },
    { lat: 49.1609929, lng: 21.156452 },
    { lat: 49.1606479, lng: 21.1571496 },
    { lat: 49.1600492, lng: 21.1595406 },
    { lat: 49.1597794, lng: 21.1602305 },
    { lat: 49.1595411, lng: 21.1606301 },
    { lat: 49.1580457, lng: 21.1618176 },
    { lat: 49.1572737, lng: 21.1626718 },
    { lat: 49.1572345, lng: 21.1628954 },
    { lat: 49.1568945, lng: 21.1634312 },
    { lat: 49.1566674, lng: 21.1639691 },
    { lat: 49.1562474, lng: 21.1645307 },
    { lat: 49.1556936, lng: 21.1654577 },
    { lat: 49.1551545, lng: 21.1661399 },
    { lat: 49.1546737, lng: 21.1666265 },
    { lat: 49.1543097, lng: 21.1665772 },
    { lat: 49.1540664, lng: 21.166783 },
    { lat: 49.1539051, lng: 21.1666333 },
    { lat: 49.1533553, lng: 21.1665383 },
    { lat: 49.1530341, lng: 21.1667005 },
    { lat: 49.1528942, lng: 21.1665784 },
    { lat: 49.1526552, lng: 21.166672 },
    { lat: 49.1523541, lng: 21.1665818 },
    { lat: 49.1518364, lng: 21.1667976 },
    { lat: 49.1516391, lng: 21.1666405 },
    { lat: 49.1512785, lng: 21.1667293 },
    { lat: 49.1511934, lng: 21.1668509 },
    { lat: 49.1504732, lng: 21.1664584 },
    { lat: 49.1501476, lng: 21.1665516 },
    { lat: 49.1497714, lng: 21.1668325 },
    { lat: 49.1485505, lng: 21.1669416 },
    { lat: 49.148207, lng: 21.1667162 },
    { lat: 49.1478293, lng: 21.1662229 },
    { lat: 49.1474733, lng: 21.1660314 },
    { lat: 49.1472219, lng: 21.1657003 },
    { lat: 49.1467609, lng: 21.1655258 },
    { lat: 49.1465049, lng: 21.165116 },
    { lat: 49.1456975, lng: 21.165245 },
    { lat: 49.1455045, lng: 21.1650819 },
    { lat: 49.1452314, lng: 21.1649843 },
    { lat: 49.1447923, lng: 21.1650494 },
    { lat: 49.1446508, lng: 21.1651633 },
    { lat: 49.1445945, lng: 21.1653069 },
    { lat: 49.1441533, lng: 21.1659138 },
    { lat: 49.1437094, lng: 21.1663254 },
    { lat: 49.1435822, lng: 21.1663972 },
    { lat: 49.1434427, lng: 21.1663599 },
    { lat: 49.1432341, lng: 21.1665664 },
    { lat: 49.1429377, lng: 21.1672288 },
    { lat: 49.1426736, lng: 21.1674544 },
    { lat: 49.1424215, lng: 21.1675278 },
    { lat: 49.1422672, lng: 21.1677346 },
    { lat: 49.1418426, lng: 21.1678352 },
    { lat: 49.1413715, lng: 21.1677776 },
    { lat: 49.1412035, lng: 21.167834 },
    { lat: 49.1410823, lng: 21.1677728 },
    { lat: 49.1409056, lng: 21.1678446 },
    { lat: 49.1406928, lng: 21.1677387 },
    { lat: 49.1403009, lng: 21.1680066 },
    { lat: 49.1401308, lng: 21.1680043 },
    { lat: 49.1399434, lng: 21.1679482 },
    { lat: 49.1396926, lng: 21.1674921 },
    { lat: 49.139387, lng: 21.1676136 },
    { lat: 49.1392448, lng: 21.1679571 },
    { lat: 49.1390235, lng: 21.1680728 },
    { lat: 49.1387099, lng: 21.1683763 },
    { lat: 49.1384319, lng: 21.1691635 },
    { lat: 49.1382801, lng: 21.1693856 },
    { lat: 49.1379022, lng: 21.1702433 },
    { lat: 49.1378634, lng: 21.170585 },
    { lat: 49.1375956, lng: 21.1710922 },
    { lat: 49.1373071, lng: 21.1718989 },
    { lat: 49.1364155, lng: 21.1720353 },
    { lat: 49.1356153, lng: 21.1715635 },
    { lat: 49.1338997, lng: 21.1714703 },
    { lat: 49.1333858, lng: 21.1716249 },
    { lat: 49.1316744, lng: 21.1718164 },
    { lat: 49.1306706, lng: 21.1722071 },
    { lat: 49.1286099, lng: 21.1728074 },
    { lat: 49.1274353, lng: 21.1728818 },
    { lat: 49.1259138, lng: 21.1733817 },
    { lat: 49.1253169, lng: 21.1735092 },
    { lat: 49.1250353, lng: 21.1734984 },
    { lat: 49.123688, lng: 21.1725237 },
    { lat: 49.1228101, lng: 21.1722301 },
    { lat: 49.1220362, lng: 21.172202 },
    { lat: 49.1212937, lng: 21.1719615 },
    { lat: 49.1204856, lng: 21.1714523 },
    { lat: 49.1196492, lng: 21.170693 },
    { lat: 49.1187387, lng: 21.1701493 },
    { lat: 49.1183655, lng: 21.1700925 },
    { lat: 49.1181143, lng: 21.1702264 },
    { lat: 49.1173284, lng: 21.1700315 },
    { lat: 49.1167761, lng: 21.1697143 },
    { lat: 49.116605, lng: 21.1695683 },
    { lat: 49.1164771, lng: 21.1693678 },
    { lat: 49.1158763, lng: 21.1691591 },
    { lat: 49.1151641, lng: 21.1690533 },
    { lat: 49.1148208, lng: 21.1691203 },
    { lat: 49.1141534, lng: 21.1690425 },
    { lat: 49.113566, lng: 21.1688328 },
    { lat: 49.1128451, lng: 21.1690958 },
    { lat: 49.1122891, lng: 21.1689164 },
    { lat: 49.1115164, lng: 21.1694645 },
    { lat: 49.1109735, lng: 21.1695231 },
    { lat: 49.1105985, lng: 21.1696546 },
    { lat: 49.1101721, lng: 21.1694065 },
    { lat: 49.1091769, lng: 21.1694984 },
    { lat: 49.1086767, lng: 21.1699116 },
    { lat: 49.1083582, lng: 21.170058 },
    { lat: 49.1081696, lng: 21.1700653 },
    { lat: 49.1079626, lng: 21.1697815 },
    { lat: 49.1076893, lng: 21.1697508 },
    { lat: 49.1073795, lng: 21.1723989 },
    { lat: 49.1072508, lng: 21.1730221 },
    { lat: 49.1073083, lng: 21.1731346 },
    { lat: 49.1075738, lng: 21.1732381 },
    { lat: 49.1065643, lng: 21.1777968 },
    { lat: 49.1061929, lng: 21.1777236 },
    { lat: 49.1058195, lng: 21.1785236 },
    { lat: 49.1055712, lng: 21.1804075 },
    { lat: 49.1056317, lng: 21.1813294 },
    { lat: 49.1055615, lng: 21.1817478 },
    { lat: 49.1056104, lng: 21.1819066 },
    { lat: 49.1054268, lng: 21.1825129 },
    { lat: 49.1050315, lng: 21.1830753 },
    { lat: 49.1039743, lng: 21.1824865 },
    { lat: 49.1038978, lng: 21.1827395 },
    { lat: 49.103741, lng: 21.1829811 },
    { lat: 49.1033872, lng: 21.1833602 },
    { lat: 49.1022867, lng: 21.184117 },
    { lat: 49.1017899, lng: 21.1845326 },
    { lat: 49.1018154, lng: 21.1846241 },
    { lat: 49.100908, lng: 21.1847959 },
    { lat: 49.1007049, lng: 21.1853892 },
  ],
  Daletice: [
    { lat: 49.020832, lng: 21.084828 },
    { lat: 49.021472, lng: 21.084627 },
    { lat: 49.021684, lng: 21.083844 },
    { lat: 49.022216, lng: 21.083591 },
    { lat: 49.023111, lng: 21.082385 },
    { lat: 49.023447, lng: 21.083039 },
    { lat: 49.023999, lng: 21.082581 },
    { lat: 49.024412, lng: 21.083144 },
    { lat: 49.024816, lng: 21.082707 },
    { lat: 49.02502, lng: 21.082894 },
    { lat: 49.025919, lng: 21.081875 },
    { lat: 49.026092, lng: 21.081481 },
    { lat: 49.027351, lng: 21.080341 },
    { lat: 49.027777, lng: 21.080284 },
    { lat: 49.02852, lng: 21.081285 },
    { lat: 49.029101, lng: 21.080061 },
    { lat: 49.029399, lng: 21.079047 },
    { lat: 49.029717, lng: 21.078808 },
    { lat: 49.030028, lng: 21.079172 },
    { lat: 49.030535, lng: 21.080357 },
    { lat: 49.031371, lng: 21.08021 },
    { lat: 49.032031, lng: 21.078894 },
    { lat: 49.031822, lng: 21.078107 },
    { lat: 49.032026, lng: 21.077532 },
    { lat: 49.031911, lng: 21.076963 },
    { lat: 49.03201, lng: 21.076482 },
    { lat: 49.031883, lng: 21.076112 },
    { lat: 49.032066, lng: 21.075709 },
    { lat: 49.032526, lng: 21.076043 },
    { lat: 49.032584, lng: 21.076383 },
    { lat: 49.03308, lng: 21.075784 },
    { lat: 49.034098, lng: 21.077773 },
    { lat: 49.033902, lng: 21.0782 },
    { lat: 49.033812, lng: 21.079193 },
    { lat: 49.034078, lng: 21.07951 },
    { lat: 49.035643, lng: 21.07743 },
    { lat: 49.035718, lng: 21.076953 },
    { lat: 49.036942, lng: 21.075057 },
    { lat: 49.037527, lng: 21.075979 },
    { lat: 49.037945, lng: 21.075406 },
    { lat: 49.038459, lng: 21.074893 },
    { lat: 49.03829, lng: 21.074621 },
    { lat: 49.038476, lng: 21.073011 },
    { lat: 49.04128, lng: 21.073164 },
    { lat: 49.0431322, lng: 21.0713549 },
    { lat: 49.0430625, lng: 21.0707664 },
    { lat: 49.0433241, lng: 21.0704688 },
    { lat: 49.0433389, lng: 21.0690442 },
    { lat: 49.0444178, lng: 21.0682343 },
    { lat: 49.0439705, lng: 21.0660252 },
    { lat: 49.043839, lng: 21.0635579 },
    { lat: 49.0450194, lng: 21.0632095 },
    { lat: 49.0466032, lng: 21.0629399 },
    { lat: 49.0464477, lng: 21.0621999 },
    { lat: 49.0459405, lng: 21.0611742 },
    { lat: 49.0459005, lng: 21.0596543 },
    { lat: 49.0463584, lng: 21.0588898 },
    { lat: 49.0464905, lng: 21.0585538 },
    { lat: 49.0450627, lng: 21.0574899 },
    { lat: 49.0436946, lng: 21.0574013 },
    { lat: 49.044226, lng: 21.0568509 },
    { lat: 49.0444878, lng: 21.0564205 },
    { lat: 49.0433084, lng: 21.0563135 },
    { lat: 49.0411698, lng: 21.0566217 },
    { lat: 49.0411959, lng: 21.0563797 },
    { lat: 49.040961, lng: 21.054354 },
    { lat: 49.040922, lng: 21.054381 },
    { lat: 49.039088, lng: 21.055511 },
    { lat: 49.035936, lng: 21.057547 },
    { lat: 49.032879, lng: 21.059612 },
    { lat: 49.030339, lng: 21.061276 },
    { lat: 49.030289, lng: 21.061443 },
    { lat: 49.030742, lng: 21.061921 },
    { lat: 49.030696, lng: 21.062103 },
    { lat: 49.031755, lng: 21.063332 },
    { lat: 49.031751, lng: 21.063527 },
    { lat: 49.031416, lng: 21.063851 },
    { lat: 49.031126, lng: 21.064273 },
    { lat: 49.030964, lng: 21.064645 },
    { lat: 49.030729, lng: 21.064881 },
    { lat: 49.030721, lng: 21.065201 },
    { lat: 49.030504, lng: 21.06554 },
    { lat: 49.030519, lng: 21.065898 },
    { lat: 49.030361, lng: 21.06602 },
    { lat: 49.030298, lng: 21.066521 },
    { lat: 49.029925, lng: 21.068365 },
    { lat: 49.029592, lng: 21.069156 },
    { lat: 49.02897, lng: 21.070199 },
    { lat: 49.028673, lng: 21.070695 },
    { lat: 49.028063, lng: 21.071095 },
    { lat: 49.027626, lng: 21.071459 },
    { lat: 49.027306, lng: 21.071795 },
    { lat: 49.027161, lng: 21.072117 },
    { lat: 49.026685, lng: 21.073024 },
    { lat: 49.02626, lng: 21.074054 },
    { lat: 49.026103, lng: 21.075035 },
    { lat: 49.025956, lng: 21.075482 },
    { lat: 49.025617, lng: 21.07615 },
    { lat: 49.025677, lng: 21.076624 },
    { lat: 49.025463, lng: 21.077222 },
    { lat: 49.02494, lng: 21.07757 },
    { lat: 49.024803, lng: 21.07806 },
    { lat: 49.02463, lng: 21.078345 },
    { lat: 49.024419, lng: 21.078992 },
    { lat: 49.024194, lng: 21.079177 },
    { lat: 49.024201, lng: 21.079631 },
    { lat: 49.024181, lng: 21.079876 },
    { lat: 49.023922, lng: 21.080205 },
    { lat: 49.02376, lng: 21.080234 },
    { lat: 49.023688, lng: 21.080734 },
    { lat: 49.023416, lng: 21.08109 },
    { lat: 49.023258, lng: 21.081319 },
    { lat: 49.022752, lng: 21.082157 },
    { lat: 49.022284, lng: 21.082813 },
    { lat: 49.021998, lng: 21.083232 },
    { lat: 49.021665, lng: 21.083459 },
    { lat: 49.02106, lng: 21.083836 },
    { lat: 49.02075, lng: 21.084161 },
    { lat: 49.020756, lng: 21.084482 },
    { lat: 49.020786, lng: 21.084744 },
    { lat: 49.020832, lng: 21.084828 },
  ],
  Ďačov: [
    { lat: 49.1515417, lng: 20.9033004 },
    { lat: 49.1507535, lng: 20.9035246 },
    { lat: 49.1501475, lng: 20.9034077 },
    { lat: 49.1493537, lng: 20.9037326 },
    { lat: 49.1489186, lng: 20.9037138 },
    { lat: 49.1487095, lng: 20.9038923 },
    { lat: 49.1482265, lng: 20.9037852 },
    { lat: 49.1479186, lng: 20.9035303 },
    { lat: 49.1476558, lng: 20.903613 },
    { lat: 49.1474191, lng: 20.9034417 },
    { lat: 49.1470189, lng: 20.903518 },
    { lat: 49.1466195, lng: 20.9032202 },
    { lat: 49.145992, lng: 20.9030062 },
    { lat: 49.1457016, lng: 20.9031026 },
    { lat: 49.1451981, lng: 20.9035313 },
    { lat: 49.1445536, lng: 20.9033718 },
    { lat: 49.1443024, lng: 20.9034964 },
    { lat: 49.1440793, lng: 20.9036929 },
    { lat: 49.143406, lng: 20.9034507 },
    { lat: 49.1429907, lng: 20.9028473 },
    { lat: 49.1424829, lng: 20.9022446 },
    { lat: 49.141869, lng: 20.9018246 },
    { lat: 49.1419873, lng: 20.9011654 },
    { lat: 49.1420066, lng: 20.9001958 },
    { lat: 49.1414506, lng: 20.8979602 },
    { lat: 49.1402946, lng: 20.8982599 },
    { lat: 49.1400164, lng: 20.8964502 },
    { lat: 49.1393497, lng: 20.8941389 },
    { lat: 49.139306, lng: 20.893771 },
    { lat: 49.1386539, lng: 20.8937941 },
    { lat: 49.1385325, lng: 20.8939999 },
    { lat: 49.1381998, lng: 20.8940436 },
    { lat: 49.1363534, lng: 20.8947652 },
    { lat: 49.136559, lng: 20.8961546 },
    { lat: 49.1364698, lng: 20.8961094 },
    { lat: 49.1359893, lng: 20.8963121 },
    { lat: 49.135594, lng: 20.8963046 },
    { lat: 49.1350634, lng: 20.8960622 },
    { lat: 49.1345113, lng: 20.8959088 },
    { lat: 49.1340772, lng: 20.8959878 },
    { lat: 49.1332043, lng: 20.895834 },
    { lat: 49.1321067, lng: 20.8970313 },
    { lat: 49.1306021, lng: 20.8982986 },
    { lat: 49.12985, lng: 20.8987628 },
    { lat: 49.1295255, lng: 20.898918 },
    { lat: 49.1255739, lng: 20.899918 },
    { lat: 49.1253426, lng: 20.9000684 },
    { lat: 49.1233664, lng: 20.9025807 },
    { lat: 49.1212881, lng: 20.905338 },
    { lat: 49.1205857, lng: 20.9065093 },
    { lat: 49.1203597, lng: 20.9070828 },
    { lat: 49.1217701, lng: 20.9097245 },
    { lat: 49.121826, lng: 20.9104834 },
    { lat: 49.121767, lng: 20.9117446 },
    { lat: 49.1218116, lng: 20.9121033 },
    { lat: 49.1222401, lng: 20.9136693 },
    { lat: 49.1226313, lng: 20.9146921 },
    { lat: 49.1230127, lng: 20.9153595 },
    { lat: 49.1235386, lng: 20.9159003 },
    { lat: 49.1246515, lng: 20.9173034 },
    { lat: 49.1258995, lng: 20.9186411 },
    { lat: 49.1263617, lng: 20.9193354 },
    { lat: 49.1269638, lng: 20.9198344 },
    { lat: 49.1272368, lng: 20.9207949 },
    { lat: 49.1276254, lng: 20.9218421 },
    { lat: 49.1277506, lng: 20.9227621 },
    { lat: 49.1282801, lng: 20.9252966 },
    { lat: 49.1283403, lng: 20.9267564 },
    { lat: 49.1286521, lng: 20.9272917 },
    { lat: 49.1290913, lng: 20.9275415 },
    { lat: 49.1299391, lng: 20.9283856 },
    { lat: 49.1300948, lng: 20.9291408 },
    { lat: 49.1304652, lng: 20.9299843 },
    { lat: 49.1311753, lng: 20.9312604 },
    { lat: 49.1313001, lng: 20.9310922 },
    { lat: 49.1318, lng: 20.931738 },
    { lat: 49.1328018, lng: 20.9327169 },
    { lat: 49.1329836, lng: 20.9330051 },
    { lat: 49.1331942, lng: 20.9329061 },
    { lat: 49.1336331, lng: 20.9335413 },
    { lat: 49.1337928, lng: 20.9333711 },
    { lat: 49.1348969, lng: 20.934483 },
    { lat: 49.1350991, lng: 20.9345719 },
    { lat: 49.1353558, lng: 20.9345721 },
    { lat: 49.1362366, lng: 20.9349308 },
    { lat: 49.1364039, lng: 20.9350021 },
    { lat: 49.1365392, lng: 20.9351992 },
    { lat: 49.1376703, lng: 20.9367826 },
    { lat: 49.1379822, lng: 20.9370898 },
    { lat: 49.1390562, lng: 20.9380072 },
    { lat: 49.1393051, lng: 20.9381145 },
    { lat: 49.1395123, lng: 20.9380844 },
    { lat: 49.1397799, lng: 20.9384788 },
    { lat: 49.1405849, lng: 20.9393999 },
    { lat: 49.1419077, lng: 20.940218 },
    { lat: 49.1429931, lng: 20.9411462 },
    { lat: 49.1434191, lng: 20.9416007 },
    { lat: 49.1445539, lng: 20.9429644 },
    { lat: 49.1451224, lng: 20.943542 },
    { lat: 49.145546, lng: 20.9443993 },
    { lat: 49.1462186, lng: 20.9454696 },
    { lat: 49.1468809, lng: 20.9463995 },
    { lat: 49.1472617, lng: 20.9470477 },
    { lat: 49.1475535, lng: 20.9476643 },
    { lat: 49.1479512, lng: 20.9488667 },
    { lat: 49.1486971, lng: 20.9504797 },
    { lat: 49.1488803, lng: 20.9507428 },
    { lat: 49.1489809, lng: 20.9509949 },
    { lat: 49.1502751, lng: 20.9524808 },
    { lat: 49.1506361, lng: 20.9528765 },
    { lat: 49.1509414, lng: 20.9531218 },
    { lat: 49.1529332, lng: 20.9490187 },
    { lat: 49.1528386, lng: 20.9487139 },
    { lat: 49.1532188, lng: 20.9484386 },
    { lat: 49.1544234, lng: 20.9459468 },
    { lat: 49.1546705, lng: 20.9466756 },
    { lat: 49.1546635, lng: 20.9462939 },
    { lat: 49.1548251, lng: 20.9462462 },
    { lat: 49.1548789, lng: 20.9461057 },
    { lat: 49.1546202, lng: 20.9456677 },
    { lat: 49.1549467, lng: 20.945203 },
    { lat: 49.1550899, lng: 20.9454443 },
    { lat: 49.1554407, lng: 20.9444205 },
    { lat: 49.1554549, lng: 20.9435959 },
    { lat: 49.1557345, lng: 20.9435135 },
    { lat: 49.1564235, lng: 20.9427741 },
    { lat: 49.1566787, lng: 20.9418536 },
    { lat: 49.1572874, lng: 20.9404321 },
    { lat: 49.1573629, lng: 20.9400289 },
    { lat: 49.1577642, lng: 20.9389992 },
    { lat: 49.1575587, lng: 20.9387084 },
    { lat: 49.1578247, lng: 20.9385861 },
    { lat: 49.1579755, lng: 20.9384194 },
    { lat: 49.1583924, lng: 20.9382281 },
    { lat: 49.1584378, lng: 20.9381023 },
    { lat: 49.1590959, lng: 20.9377611 },
    { lat: 49.1593898, lng: 20.9374575 },
    { lat: 49.1594722, lng: 20.9372346 },
    { lat: 49.159763, lng: 20.9371426 },
    { lat: 49.1599512, lng: 20.9369166 },
    { lat: 49.1603076, lng: 20.9369023 },
    { lat: 49.160617, lng: 20.936492 },
    { lat: 49.1612724, lng: 20.9364717 },
    { lat: 49.161769, lng: 20.9365469 },
    { lat: 49.1619955, lng: 20.936558 },
    { lat: 49.1625872, lng: 20.9360759 },
    { lat: 49.1627439, lng: 20.935648 },
    { lat: 49.1626734, lng: 20.9355557 },
    { lat: 49.1634676, lng: 20.9347331 },
    { lat: 49.1634583, lng: 20.934491 },
    { lat: 49.1633239, lng: 20.9343087 },
    { lat: 49.1643089, lng: 20.9325337 },
    { lat: 49.1644294, lng: 20.930237 },
    { lat: 49.1635363, lng: 20.9292451 },
    { lat: 49.1606775, lng: 20.9264182 },
    { lat: 49.1605231, lng: 20.9267812 },
    { lat: 49.1598977, lng: 20.9266543 },
    { lat: 49.1596418, lng: 20.9270856 },
    { lat: 49.1593131, lng: 20.9258651 },
    { lat: 49.1593619, lng: 20.9251407 },
    { lat: 49.159052, lng: 20.9244204 },
    { lat: 49.160019, lng: 20.9226471 },
    { lat: 49.1597474, lng: 20.9224309 },
    { lat: 49.1606847, lng: 20.91954 },
    { lat: 49.1561422, lng: 20.9160488 },
    { lat: 49.1546954, lng: 20.9152933 },
    { lat: 49.154404, lng: 20.9148234 },
    { lat: 49.1553863, lng: 20.9119716 },
    { lat: 49.151837, lng: 20.9092675 },
    { lat: 49.1529446, lng: 20.905896 },
    { lat: 49.152062, lng: 20.9052851 },
    { lat: 49.151586, lng: 20.9048556 },
    { lat: 49.1516005, lng: 20.9035468 },
    { lat: 49.1515417, lng: 20.9033004 },
  ],
  Olejníkov: [
    { lat: 49.164887, lng: 21.1510415 },
    { lat: 49.1652758, lng: 21.1514502 },
    { lat: 49.1663867, lng: 21.1524915 },
    { lat: 49.166417, lng: 21.1535274 },
    { lat: 49.1662794, lng: 21.1548051 },
    { lat: 49.1656011, lng: 21.1580278 },
    { lat: 49.1655864, lng: 21.1584902 },
    { lat: 49.1656562, lng: 21.1591 },
    { lat: 49.1657379, lng: 21.1623426 },
    { lat: 49.1657886, lng: 21.1632051 },
    { lat: 49.165942, lng: 21.163848 },
    { lat: 49.166337, lng: 21.164043 },
    { lat: 49.166365, lng: 21.164059 },
    { lat: 49.16737, lng: 21.164558 },
    { lat: 49.16794, lng: 21.165015 },
    { lat: 49.168692, lng: 21.165395 },
    { lat: 49.169557, lng: 21.165912 },
    { lat: 49.169779, lng: 21.166114 },
    { lat: 49.170657, lng: 21.166981 },
    { lat: 49.17107, lng: 21.167389 },
    { lat: 49.171312, lng: 21.16772 },
    { lat: 49.171374, lng: 21.167998 },
    { lat: 49.171403, lng: 21.168036 },
    { lat: 49.171422, lng: 21.168058 },
    { lat: 49.171463, lng: 21.168111 },
    { lat: 49.171704, lng: 21.168419 },
    { lat: 49.174052, lng: 21.166627 },
    { lat: 49.17466, lng: 21.166222 },
    { lat: 49.175069, lng: 21.165937 },
    { lat: 49.175085, lng: 21.165926 },
    { lat: 49.176172, lng: 21.165166 },
    { lat: 49.176336, lng: 21.165034 },
    { lat: 49.176407, lng: 21.164977 },
    { lat: 49.178191, lng: 21.163542 },
    { lat: 49.178295, lng: 21.163471 },
    { lat: 49.178307, lng: 21.163464 },
    { lat: 49.178308, lng: 21.163745 },
    { lat: 49.178194, lng: 21.164411 },
    { lat: 49.178252, lng: 21.164515 },
    { lat: 49.178244, lng: 21.164677 },
    { lat: 49.178174, lng: 21.164788 },
    { lat: 49.178227, lng: 21.165015 },
    { lat: 49.178217, lng: 21.165129 },
    { lat: 49.17811, lng: 21.165275 },
    { lat: 49.178121, lng: 21.165415 },
    { lat: 49.178179, lng: 21.165468 },
    { lat: 49.17827, lng: 21.165661 },
    { lat: 49.178196, lng: 21.165766 },
    { lat: 49.178202, lng: 21.16603 },
    { lat: 49.178177, lng: 21.166154 },
    { lat: 49.17814, lng: 21.166263 },
    { lat: 49.178172, lng: 21.16644 },
    { lat: 49.178188, lng: 21.166656 },
    { lat: 49.178076, lng: 21.167008 },
    { lat: 49.17805, lng: 21.167169 },
    { lat: 49.17815, lng: 21.167502 },
    { lat: 49.178192, lng: 21.167532 },
    { lat: 49.178268, lng: 21.167948 },
    { lat: 49.178159, lng: 21.168171 },
    { lat: 49.178157, lng: 21.168366 },
    { lat: 49.178147, lng: 21.168424 },
    { lat: 49.178177, lng: 21.168781 },
    { lat: 49.178194, lng: 21.168856 },
    { lat: 49.178345, lng: 21.168896 },
    { lat: 49.178366, lng: 21.168901 },
    { lat: 49.178561, lng: 21.169001 },
    { lat: 49.179049, lng: 21.169078 },
    { lat: 49.179293, lng: 21.16927 },
    { lat: 49.179707, lng: 21.169444 },
    { lat: 49.179831, lng: 21.169459 },
    { lat: 49.180178, lng: 21.169578 },
    { lat: 49.181299, lng: 21.170254 },
    { lat: 49.181582, lng: 21.170503 },
    { lat: 49.181682, lng: 21.17071 },
    { lat: 49.182277, lng: 21.171393 },
    { lat: 49.182473, lng: 21.171658 },
    { lat: 49.182636, lng: 21.171849 },
    { lat: 49.182942, lng: 21.172208 },
    { lat: 49.184011, lng: 21.173906 },
    { lat: 49.184304, lng: 21.174886 },
    { lat: 49.185159, lng: 21.174651 },
    { lat: 49.185167, lng: 21.174631 },
    { lat: 49.185177, lng: 21.174606 },
    { lat: 49.185375, lng: 21.174082 },
    { lat: 49.185474, lng: 21.17386 },
    { lat: 49.185572, lng: 21.173676 },
    { lat: 49.185734, lng: 21.173355 },
    { lat: 49.185935, lng: 21.172925 },
    { lat: 49.186153, lng: 21.172557 },
    { lat: 49.186517, lng: 21.172242 },
    { lat: 49.187223, lng: 21.171831 },
    { lat: 49.187836, lng: 21.171291 },
    { lat: 49.188343, lng: 21.1705 },
    { lat: 49.188643, lng: 21.169682 },
    { lat: 49.188858, lng: 21.169198 },
    { lat: 49.189005, lng: 21.169007 },
    { lat: 49.189113, lng: 21.168936 },
    { lat: 49.189434, lng: 21.168918 },
    { lat: 49.189742, lng: 21.169011 },
    { lat: 49.189985, lng: 21.169184 },
    { lat: 49.190218, lng: 21.169333 },
    { lat: 49.190715, lng: 21.169465 },
    { lat: 49.190878, lng: 21.169558 },
    { lat: 49.191054, lng: 21.169787 },
    { lat: 49.191293, lng: 21.169872 },
    { lat: 49.191642, lng: 21.169868 },
    { lat: 49.192531, lng: 21.169606 },
    { lat: 49.192749, lng: 21.169557 },
    { lat: 49.192912, lng: 21.169393 },
    { lat: 49.193355, lng: 21.169115 },
    { lat: 49.193651, lng: 21.169072 },
    { lat: 49.194262, lng: 21.168784 },
    { lat: 49.194759, lng: 21.168596 },
    { lat: 49.195121, lng: 21.168432 },
    { lat: 49.195731, lng: 21.168122 },
    { lat: 49.195851, lng: 21.168005 },
    { lat: 49.196038, lng: 21.167765 },
    { lat: 49.196076, lng: 21.167698 },
    { lat: 49.196087, lng: 21.167711 },
    { lat: 49.19619, lng: 21.167506 },
    { lat: 49.196266, lng: 21.167171 },
    { lat: 49.196365, lng: 21.166932 },
    { lat: 49.196428, lng: 21.166615 },
    { lat: 49.196464, lng: 21.166493 },
    { lat: 49.196571, lng: 21.165885 },
    { lat: 49.196435, lng: 21.16495 },
    { lat: 49.196421, lng: 21.164409 },
    { lat: 49.196432, lng: 21.164138 },
    { lat: 49.196315, lng: 21.163857 },
    { lat: 49.196203, lng: 21.163403 },
    { lat: 49.196172, lng: 21.163197 },
    { lat: 49.19612, lng: 21.16247 },
    { lat: 49.196126, lng: 21.16206 },
    { lat: 49.196097, lng: 21.161663 },
    { lat: 49.196215, lng: 21.161036 },
    { lat: 49.19657, lng: 21.159785 },
    { lat: 49.196592, lng: 21.159521 },
    { lat: 49.196908, lng: 21.158585 },
    { lat: 49.1971, lng: 21.158384 },
    { lat: 49.197632, lng: 21.157653 },
    { lat: 49.197877, lng: 21.157183 },
    { lat: 49.197923, lng: 21.156725 },
    { lat: 49.19803, lng: 21.15654 },
    { lat: 49.198395, lng: 21.155768 },
    { lat: 49.198832, lng: 21.15415 },
    { lat: 49.198887, lng: 21.15383 },
    { lat: 49.198834, lng: 21.153544 },
    { lat: 49.198608, lng: 21.152986 },
    { lat: 49.198536, lng: 21.152645 },
    { lat: 49.198395, lng: 21.151647 },
    { lat: 49.198352, lng: 21.151425 },
    { lat: 49.198272, lng: 21.150575 },
    { lat: 49.198247, lng: 21.149811 },
    { lat: 49.198306, lng: 21.149106 },
    { lat: 49.198505, lng: 21.148195 },
    { lat: 49.198666, lng: 21.147017 },
    { lat: 49.198619, lng: 21.146318 },
    { lat: 49.198654, lng: 21.146304 },
    { lat: 49.199381, lng: 21.145447 },
    { lat: 49.200052, lng: 21.14457 },
    { lat: 49.20038, lng: 21.144002 },
    { lat: 49.200586, lng: 21.143619 },
    { lat: 49.201432, lng: 21.141784 },
    { lat: 49.201624, lng: 21.141448 },
    { lat: 49.201657, lng: 21.141088 },
    { lat: 49.201759, lng: 21.141096 },
    { lat: 49.202142, lng: 21.141235 },
    { lat: 49.202414, lng: 21.141175 },
    { lat: 49.20301, lng: 21.141121 },
    { lat: 49.203463, lng: 21.140974 },
    { lat: 49.204459, lng: 21.140514 },
    { lat: 49.204735, lng: 21.140346 },
    { lat: 49.204933, lng: 21.140172 },
    { lat: 49.205092, lng: 21.139959 },
    { lat: 49.205284, lng: 21.1398 },
    { lat: 49.205539, lng: 21.139705 },
    { lat: 49.205938, lng: 21.139672 },
    { lat: 49.206479, lng: 21.139702 },
    { lat: 49.206832, lng: 21.139626 },
    { lat: 49.207274, lng: 21.13966 },
    { lat: 49.207603, lng: 21.139642 },
    { lat: 49.207831, lng: 21.139467 },
    { lat: 49.208157, lng: 21.139031 },
    { lat: 49.208521, lng: 21.138025 },
    { lat: 49.208538, lng: 21.137768 },
    { lat: 49.208664, lng: 21.137209 },
    { lat: 49.208707, lng: 21.136843 },
    { lat: 49.208855, lng: 21.136579 },
    { lat: 49.208848, lng: 21.136282 },
    { lat: 49.208786, lng: 21.135978 },
    { lat: 49.208828, lng: 21.135626 },
    { lat: 49.208999, lng: 21.13529 },
    { lat: 49.209358, lng: 21.134704 },
    { lat: 49.209553, lng: 21.13426 },
    { lat: 49.209728, lng: 21.134039 },
    { lat: 49.209951, lng: 21.133655 },
    { lat: 49.210263, lng: 21.133148 },
    { lat: 49.210502, lng: 21.132726 },
    { lat: 49.210684, lng: 21.132613 },
    { lat: 49.21094, lng: 21.132316 },
    { lat: 49.211146, lng: 21.132002 },
    { lat: 49.211296, lng: 21.131574 },
    { lat: 49.211557, lng: 21.131246 },
    { lat: 49.21175, lng: 21.130882 },
    { lat: 49.21198, lng: 21.130561 },
    { lat: 49.212084, lng: 21.130264 },
    { lat: 49.212402, lng: 21.129088 },
    { lat: 49.212475, lng: 21.128889 },
    { lat: 49.212474, lng: 21.128168 },
    { lat: 49.21253, lng: 21.127712 },
    { lat: 49.212598, lng: 21.126962 },
    { lat: 49.212559, lng: 21.1259 },
    { lat: 49.213442, lng: 21.125326 },
    { lat: 49.213548, lng: 21.125304 },
    { lat: 49.213705, lng: 21.125382 },
    { lat: 49.213986, lng: 21.125475 },
    { lat: 49.214964, lng: 21.12591 },
    { lat: 49.215456, lng: 21.125461 },
    { lat: 49.215839, lng: 21.125012 },
    { lat: 49.216597, lng: 21.124545 },
    { lat: 49.216679, lng: 21.124526 },
    { lat: 49.216792, lng: 21.124409 },
    { lat: 49.217006, lng: 21.124097 },
    { lat: 49.217544, lng: 21.123575 },
    { lat: 49.21803, lng: 21.122961 },
    { lat: 49.218277, lng: 21.122714 },
    { lat: 49.218609, lng: 21.122228 },
    { lat: 49.218819, lng: 21.122038 },
    { lat: 49.219244, lng: 21.121774 },
    { lat: 49.219538, lng: 21.121503 },
    { lat: 49.220098, lng: 21.121213 },
    { lat: 49.220277, lng: 21.120933 },
    { lat: 49.220338, lng: 21.120866 },
    { lat: 49.220602, lng: 21.120688 },
    { lat: 49.220853, lng: 21.120115 },
    { lat: 49.221326, lng: 21.119251 },
    { lat: 49.221681, lng: 21.118311 },
    { lat: 49.221645, lng: 21.117956 },
    { lat: 49.221611, lng: 21.117504 },
    { lat: 49.221449, lng: 21.11638 },
    { lat: 49.221246, lng: 21.115672 },
    { lat: 49.220927, lng: 21.115095 },
    { lat: 49.220485, lng: 21.114719 },
    { lat: 49.220127, lng: 21.11444 },
    { lat: 49.219893, lng: 21.114196 },
    { lat: 49.219751, lng: 21.113876 },
    { lat: 49.219719, lng: 21.113664 },
    { lat: 49.219586, lng: 21.113533 },
    { lat: 49.219567, lng: 21.113227 },
    { lat: 49.219474, lng: 21.112999 },
    { lat: 49.219365, lng: 21.112626 },
    { lat: 49.219305, lng: 21.112068 },
    { lat: 49.219198, lng: 21.111915 },
    { lat: 49.219142, lng: 21.111515 },
    { lat: 49.219144, lng: 21.111439 },
    { lat: 49.219181, lng: 21.111138 },
    { lat: 49.219193, lng: 21.110801 },
    { lat: 49.219069, lng: 21.11032 },
    { lat: 49.218926, lng: 21.110066 },
    { lat: 49.218792, lng: 21.109659 },
    { lat: 49.218749, lng: 21.109099 },
    { lat: 49.218764, lng: 21.108424 },
    { lat: 49.218573, lng: 21.107884 },
    { lat: 49.218452, lng: 21.107453 },
    { lat: 49.218489, lng: 21.107256 },
    { lat: 49.218504, lng: 21.106738 },
    { lat: 49.218579, lng: 21.106467 },
    { lat: 49.218643, lng: 21.106417 },
    { lat: 49.218728, lng: 21.106192 },
    { lat: 49.218771, lng: 21.105928 },
    { lat: 49.218965, lng: 21.105297 },
    { lat: 49.219038, lng: 21.104779 },
    { lat: 49.219246, lng: 21.104338 },
    { lat: 49.219264, lng: 21.10289 },
    { lat: 49.219249, lng: 21.102722 },
    { lat: 49.219143, lng: 21.102415 },
    { lat: 49.219068, lng: 21.102036 },
    { lat: 49.218911, lng: 21.101792 },
    { lat: 49.218831, lng: 21.101542 },
    { lat: 49.218704, lng: 21.101268 },
    { lat: 49.218608, lng: 21.100937 },
    { lat: 49.218526, lng: 21.100744 },
    { lat: 49.218386, lng: 21.100687 },
    { lat: 49.21833, lng: 21.100346 },
    { lat: 49.218248, lng: 21.100126 },
    { lat: 49.218112, lng: 21.100094 },
    { lat: 49.21785, lng: 21.099766 },
    { lat: 49.217758, lng: 21.0996 },
    { lat: 49.217611, lng: 21.099412 },
    { lat: 49.217563, lng: 21.099238 },
    { lat: 49.217451, lng: 21.099035 },
    { lat: 49.217286, lng: 21.098932 },
    { lat: 49.217313, lng: 21.098756 },
    { lat: 49.217396, lng: 21.098486 },
    { lat: 49.217464, lng: 21.098151 },
    { lat: 49.21752, lng: 21.097936 },
    { lat: 49.217584, lng: 21.097489 },
    { lat: 49.217589, lng: 21.097106 },
    { lat: 49.217611, lng: 21.096749 },
    { lat: 49.217604, lng: 21.096194 },
    { lat: 49.217465, lng: 21.095642 },
    { lat: 49.217558, lng: 21.095594 },
    { lat: 49.217509, lng: 21.095386 },
    { lat: 49.217344, lng: 21.095224 },
    { lat: 49.21728, lng: 21.095012 },
    { lat: 49.217234, lng: 21.095073 },
    { lat: 49.217156, lng: 21.094679 },
    { lat: 49.217057, lng: 21.094492 },
    { lat: 49.216942, lng: 21.094466 },
    { lat: 49.216839, lng: 21.093955 },
    { lat: 49.21669, lng: 21.093806 },
    { lat: 49.216287, lng: 21.093406 },
    { lat: 49.216083, lng: 21.093174 },
    { lat: 49.215839, lng: 21.093106 },
    { lat: 49.215713, lng: 21.092903 },
    { lat: 49.215715, lng: 21.092041 },
    { lat: 49.215716, lng: 21.091649 },
    { lat: 49.216047, lng: 21.089125 },
    { lat: 49.216064, lng: 21.089129 },
    { lat: 49.216107, lng: 21.088527 },
    { lat: 49.216066, lng: 21.088224 },
    { lat: 49.216012, lng: 21.088102 },
    { lat: 49.215803, lng: 21.08781 },
    { lat: 49.215624, lng: 21.087458 },
    { lat: 49.215481, lng: 21.087096 },
    { lat: 49.215327, lng: 21.086804 },
    { lat: 49.215047, lng: 21.086307 },
    { lat: 49.214913, lng: 21.085981 },
    { lat: 49.214849, lng: 21.085822 },
    { lat: 49.214752, lng: 21.085436 },
    { lat: 49.214603, lng: 21.085138 },
    { lat: 49.214552, lng: 21.084984 },
    { lat: 49.214533, lng: 21.084917 },
    { lat: 49.214498, lng: 21.084805 },
    { lat: 49.214473, lng: 21.084724 },
    { lat: 49.214457, lng: 21.084673 },
    { lat: 49.21429, lng: 21.084247 },
    { lat: 49.214154, lng: 21.083833 },
    { lat: 49.213876, lng: 21.083567 },
    { lat: 49.213713, lng: 21.083122 },
    { lat: 49.21378, lng: 21.08273 },
    { lat: 49.213702, lng: 21.082405 },
    { lat: 49.213588, lng: 21.082193 },
    { lat: 49.213612, lng: 21.081541 },
    { lat: 49.213424, lng: 21.080941 },
    { lat: 49.213372, lng: 21.080698 },
    { lat: 49.213362, lng: 21.080653 },
    { lat: 49.213176, lng: 21.080247 },
    { lat: 49.213124, lng: 21.079825 },
    { lat: 49.212975, lng: 21.079552 },
    { lat: 49.212886, lng: 21.079289 },
    { lat: 49.212672, lng: 21.07908 },
    { lat: 49.212632, lng: 21.078953 },
    { lat: 49.212609, lng: 21.078734 },
    { lat: 49.212612, lng: 21.078317 },
    { lat: 49.212527, lng: 21.077961 },
    { lat: 49.212411, lng: 21.077646 },
    { lat: 49.212457, lng: 21.077316 },
    { lat: 49.212411, lng: 21.076835 },
    { lat: 49.212425, lng: 21.076462 },
    { lat: 49.212564, lng: 21.075762 },
    { lat: 49.212576, lng: 21.07551 },
    { lat: 49.21264, lng: 21.075223 },
    { lat: 49.212726, lng: 21.075066 },
    { lat: 49.212754, lng: 21.074329 },
    { lat: 49.212787, lng: 21.07419 },
    { lat: 49.21288, lng: 21.074141 },
    { lat: 49.212908, lng: 21.074133 },
    { lat: 49.213111, lng: 21.074063 },
    { lat: 49.213262, lng: 21.073988 },
    { lat: 49.213263, lng: 21.074011 },
    { lat: 49.213789, lng: 21.07336 },
    { lat: 49.214178, lng: 21.072927 },
    { lat: 49.214499, lng: 21.072437 },
    { lat: 49.214795, lng: 21.071632 },
    { lat: 49.214976, lng: 21.071129 },
    { lat: 49.215115, lng: 21.070539 },
    { lat: 49.215312, lng: 21.070186 },
    { lat: 49.215729, lng: 21.069605 },
    { lat: 49.215863, lng: 21.069217 },
    { lat: 49.215901, lng: 21.069137 },
    { lat: 49.215947, lng: 21.06904 },
    { lat: 49.216244, lng: 21.068412 },
    { lat: 49.216438, lng: 21.068051 },
    { lat: 49.216689, lng: 21.067508 },
    { lat: 49.216739, lng: 21.06734 },
    { lat: 49.21682, lng: 21.067091 },
    { lat: 49.216842, lng: 21.066848 },
    { lat: 49.216851, lng: 21.066521 },
    { lat: 49.216932, lng: 21.066077 },
    { lat: 49.216978, lng: 21.06573 },
    { lat: 49.217208, lng: 21.064743 },
    { lat: 49.217536, lng: 21.064028 },
    { lat: 49.217848, lng: 21.063384 },
    { lat: 49.218281, lng: 21.063142 },
    { lat: 49.218687, lng: 21.063073 },
    { lat: 49.219124, lng: 21.062633 },
    { lat: 49.219558, lng: 21.062226 },
    { lat: 49.219945, lng: 21.061515 },
    { lat: 49.220187, lng: 21.060937 },
    { lat: 49.220492, lng: 21.060386 },
    { lat: 49.220529, lng: 21.060277 },
    { lat: 49.220683, lng: 21.059774 },
    { lat: 49.220878, lng: 21.059398 },
    { lat: 49.221195, lng: 21.058622 },
    { lat: 49.221424, lng: 21.058223 },
    { lat: 49.22148, lng: 21.058165 },
    { lat: 49.221539, lng: 21.05809 },
    { lat: 49.222122, lng: 21.057035 },
    { lat: 49.222215, lng: 21.056779 },
    { lat: 49.222279, lng: 21.056482 },
    { lat: 49.222333, lng: 21.056418 },
    { lat: 49.222306, lng: 21.056095 },
    { lat: 49.222282, lng: 21.055902 },
    { lat: 49.222242, lng: 21.055693 },
    { lat: 49.22215, lng: 21.055322 },
    { lat: 49.222127, lng: 21.055205 },
    { lat: 49.221436, lng: 21.054474 },
    { lat: 49.221187, lng: 21.054214 },
    { lat: 49.220845, lng: 21.053716 },
    { lat: 49.22058, lng: 21.053825 },
    { lat: 49.220343, lng: 21.053662 },
    { lat: 49.220182, lng: 21.053454 },
    { lat: 49.220037, lng: 21.053256 },
    { lat: 49.219927, lng: 21.053213 },
    { lat: 49.2198, lng: 21.053057 },
    { lat: 49.21966, lng: 21.053038 },
    { lat: 49.219355, lng: 21.052916 },
    { lat: 49.219223, lng: 21.052348 },
    { lat: 49.219158, lng: 21.052019 },
    { lat: 49.219069, lng: 21.051435 },
    { lat: 49.219, lng: 21.051182 },
    { lat: 49.218815, lng: 21.051061 },
    { lat: 49.218587, lng: 21.050958 },
    { lat: 49.218228, lng: 21.050865 },
    { lat: 49.217835, lng: 21.050601 },
    { lat: 49.217792, lng: 21.050482 },
    { lat: 49.217737, lng: 21.050259 },
    { lat: 49.217767, lng: 21.049992 },
    { lat: 49.217856, lng: 21.049783 },
    { lat: 49.217805, lng: 21.049641 },
    { lat: 49.21769, lng: 21.049567 },
    { lat: 49.217615, lng: 21.049438 },
    { lat: 49.217542, lng: 21.049256 },
    { lat: 49.217403, lng: 21.049178 },
    { lat: 49.217254, lng: 21.048921 },
    { lat: 49.217114, lng: 21.048879 },
    { lat: 49.21708, lng: 21.048869 },
    { lat: 49.216956, lng: 21.048649 },
    { lat: 49.216835, lng: 21.048313 },
    { lat: 49.216747, lng: 21.047861 },
    { lat: 49.216705, lng: 21.047667 },
    { lat: 49.216644, lng: 21.047295 },
    { lat: 49.216654, lng: 21.047082 },
    { lat: 49.216653, lng: 21.046842 },
    { lat: 49.216656, lng: 21.046638 },
    { lat: 49.216697, lng: 21.046455 },
    { lat: 49.216688, lng: 21.046259 },
    { lat: 49.216685, lng: 21.046097 },
    { lat: 49.216714, lng: 21.04574 },
    { lat: 49.216793, lng: 21.045472 },
    { lat: 49.216903, lng: 21.045247 },
    { lat: 49.216935, lng: 21.044962 },
    { lat: 49.217094, lng: 21.044501 },
    { lat: 49.217143, lng: 21.044249 },
    { lat: 49.217181, lng: 21.044004 },
    { lat: 49.2172, lng: 21.043912 },
    { lat: 49.217245, lng: 21.043635 },
    { lat: 49.217218, lng: 21.043514 },
    { lat: 49.217398, lng: 21.043327 },
    { lat: 49.21748, lng: 21.042951 },
    { lat: 49.217478, lng: 21.042688 },
    { lat: 49.217602, lng: 21.042285 },
    { lat: 49.217644, lng: 21.042149 },
    { lat: 49.217772, lng: 21.042089 },
    { lat: 49.217945, lng: 21.041676 },
    { lat: 49.218046, lng: 21.040826 },
    { lat: 49.218166, lng: 21.040627 },
    { lat: 49.218266, lng: 21.040289 },
    { lat: 49.2175975, lng: 21.0396236 },
    { lat: 49.2174561, lng: 21.0393553 },
    { lat: 49.2169912, lng: 21.0392512 },
    { lat: 49.2167332, lng: 21.0390986 },
    { lat: 49.2164198, lng: 21.0391198 },
    { lat: 49.216139, lng: 21.0389999 },
    { lat: 49.2160945, lng: 21.0390461 },
    { lat: 49.215034, lng: 21.0389643 },
    { lat: 49.2147811, lng: 21.0391134 },
    { lat: 49.2141872, lng: 21.0391536 },
    { lat: 49.2139958, lng: 21.038947 },
    { lat: 49.2138133, lng: 21.0389396 },
    { lat: 49.2134499, lng: 21.0390844 },
    { lat: 49.213108, lng: 21.0389832 },
    { lat: 49.213073, lng: 21.0390535 },
    { lat: 49.2128153, lng: 21.0391868 },
    { lat: 49.2125236, lng: 21.0391793 },
    { lat: 49.2124386, lng: 21.0394071 },
    { lat: 49.2122629, lng: 21.0396112 },
    { lat: 49.2122669, lng: 21.03991 },
    { lat: 49.2121795, lng: 21.0399544 },
    { lat: 49.2119942, lng: 21.0401739 },
    { lat: 49.2119152, lng: 21.0403703 },
    { lat: 49.2118389, lng: 21.0403833 },
    { lat: 49.2117225, lng: 21.0405382 },
    { lat: 49.2116715, lng: 21.040739 },
    { lat: 49.2117379, lng: 21.0408092 },
    { lat: 49.2117047, lng: 21.0409992 },
    { lat: 49.211523, lng: 21.0414305 },
    { lat: 49.2111153, lng: 21.0413904 },
    { lat: 49.2109463, lng: 21.041523 },
    { lat: 49.2108579, lng: 21.0417788 },
    { lat: 49.2107181, lng: 21.0417731 },
    { lat: 49.2101496, lng: 21.0421451 },
    { lat: 49.2098957, lng: 21.0421268 },
    { lat: 49.2093001, lng: 21.0424615 },
    { lat: 49.2090878, lng: 21.0424373 },
    { lat: 49.208893, lng: 21.0425412 },
    { lat: 49.2086888, lng: 21.042525 },
    { lat: 49.2086273, lng: 21.0427456 },
    { lat: 49.2083331, lng: 21.0428373 },
    { lat: 49.2082239, lng: 21.0430482 },
    { lat: 49.2079841, lng: 21.0431145 },
    { lat: 49.207874, lng: 21.0432895 },
    { lat: 49.207693, lng: 21.0431905 },
    { lat: 49.207445, lng: 21.043527 },
    { lat: 49.2071046, lng: 21.0435739 },
    { lat: 49.2070287, lng: 21.0436981 },
    { lat: 49.206873, lng: 21.0437556 },
    { lat: 49.2067577, lng: 21.0436757 },
    { lat: 49.2066724, lng: 21.0437516 },
    { lat: 49.2066003, lng: 21.0435415 },
    { lat: 49.2062771, lng: 21.0435684 },
    { lat: 49.2061155, lng: 21.0434424 },
    { lat: 49.2056711, lng: 21.0432697 },
    { lat: 49.2054706, lng: 21.0430581 },
    { lat: 49.2050083, lng: 21.0429242 },
    { lat: 49.2049097, lng: 21.0429367 },
    { lat: 49.2048091, lng: 21.0431002 },
    { lat: 49.2047228, lng: 21.0431445 },
    { lat: 49.2045757, lng: 21.0430868 },
    { lat: 49.2044864, lng: 21.0432426 },
    { lat: 49.2042572, lng: 21.0432934 },
    { lat: 49.2040587, lng: 21.0431061 },
    { lat: 49.2038368, lng: 21.0433121 },
    { lat: 49.2037237, lng: 21.0431166 },
    { lat: 49.2035517, lng: 21.0431933 },
    { lat: 49.2034736, lng: 21.0431064 },
    { lat: 49.203328, lng: 21.0430968 },
    { lat: 49.2032263, lng: 21.0429415 },
    { lat: 49.2029597, lng: 21.0430822 },
    { lat: 49.2026441, lng: 21.0431373 },
    { lat: 49.2025279, lng: 21.0432336 },
    { lat: 49.2023716, lng: 21.0430334 },
    { lat: 49.2022393, lng: 21.0429739 },
    { lat: 49.2019812, lng: 21.0431374 },
    { lat: 49.2015578, lng: 21.042953 },
    { lat: 49.2012392, lng: 21.0433661 },
    { lat: 49.2006961, lng: 21.0431782 },
    { lat: 49.2004278, lng: 21.0429549 },
    { lat: 49.2003221, lng: 21.0430251 },
    { lat: 49.200215, lng: 21.042981 },
    { lat: 49.2000398, lng: 21.0431717 },
    { lat: 49.1997604, lng: 21.0430549 },
    { lat: 49.1996396, lng: 21.0430738 },
    { lat: 49.1991791, lng: 21.0433108 },
    { lat: 49.1990944, lng: 21.0435129 },
    { lat: 49.1990381, lng: 21.0442412 },
    { lat: 49.1989999, lng: 21.0443157 },
    { lat: 49.1987387, lng: 21.044375 },
    { lat: 49.1985765, lng: 21.0445438 },
    { lat: 49.1985426, lng: 21.0447123 },
    { lat: 49.1984453, lng: 21.0448761 },
    { lat: 49.1983996, lng: 21.0451318 },
    { lat: 49.1982657, lng: 21.0453737 },
    { lat: 49.1983238, lng: 21.045777 },
    { lat: 49.1982765, lng: 21.0459188 },
    { lat: 49.1981879, lng: 21.0459272 },
    { lat: 49.198131, lng: 21.0460427 },
    { lat: 49.1981405, lng: 21.0462327 },
    { lat: 49.1979839, lng: 21.0465393 },
    { lat: 49.1979545, lng: 21.0470572 },
    { lat: 49.1978619, lng: 21.0471192 },
    { lat: 49.197786, lng: 21.0474473 },
    { lat: 49.1974517, lng: 21.0475157 },
    { lat: 49.1971861, lng: 21.0479643 },
    { lat: 49.1969998, lng: 21.0478409 },
    { lat: 49.1967758, lng: 21.0480899 },
    { lat: 49.196689, lng: 21.0483217 },
    { lat: 49.1963041, lng: 21.0483939 },
    { lat: 49.1961677, lng: 21.0483186 },
    { lat: 49.1957621, lng: 21.0487194 },
    { lat: 49.1957957, lng: 21.0489256 },
    { lat: 49.1956828, lng: 21.0490895 },
    { lat: 49.1954957, lng: 21.0487658 },
    { lat: 49.1952458, lng: 21.0485856 },
    { lat: 49.1950693, lng: 21.0486263 },
    { lat: 49.1949801, lng: 21.0488284 },
    { lat: 49.1947865, lng: 21.0490376 },
    { lat: 49.1945544, lng: 21.0490303 },
    { lat: 49.1936905, lng: 21.0493851 },
    { lat: 49.1933263, lng: 21.0493291 },
    { lat: 49.1929731, lng: 21.0495421 },
    { lat: 49.1926827, lng: 21.0491239 },
    { lat: 49.1924035, lng: 21.0483901 },
    { lat: 49.1923667, lng: 21.0481255 },
    { lat: 49.1922037, lng: 21.0476082 },
    { lat: 49.1921772, lng: 21.0467178 },
    { lat: 49.1920099, lng: 21.0461083 },
    { lat: 49.1917714, lng: 21.0448264 },
    { lat: 49.1917058, lng: 21.0440815 },
    { lat: 49.1916714, lng: 21.0415309 },
    { lat: 49.1918868, lng: 21.0411642 },
    { lat: 49.1919547, lng: 21.0405164 },
    { lat: 49.1917803, lng: 21.0403461 },
    { lat: 49.1910275, lng: 21.040516 },
    { lat: 49.1900395, lng: 21.0403449 },
    { lat: 49.1898206, lng: 21.0400171 },
    { lat: 49.1897217, lng: 21.0397101 },
    { lat: 49.1885237, lng: 21.0403838 },
    { lat: 49.1877382, lng: 21.0403187 },
    { lat: 49.1864727, lng: 21.0404049 },
    { lat: 49.1863838, lng: 21.0408185 },
    { lat: 49.1867449, lng: 21.0417515 },
    { lat: 49.1866099, lng: 21.0421024 },
    { lat: 49.1856397, lng: 21.0436571 },
    { lat: 49.1852493, lng: 21.04415 },
    { lat: 49.1849573, lng: 21.044272 },
    { lat: 49.1848514, lng: 21.0438134 },
    { lat: 49.1846457, lng: 21.0432638 },
    { lat: 49.1842776, lng: 21.043716 },
    { lat: 49.1827818, lng: 21.0466914 },
    { lat: 49.1827855, lng: 21.0469163 },
    { lat: 49.1818928, lng: 21.0484981 },
    { lat: 49.1814145, lng: 21.049096 },
    { lat: 49.1807495, lng: 21.0497584 },
    { lat: 49.1798048, lng: 21.0508497 },
    { lat: 49.1787987, lng: 21.052209 },
    { lat: 49.1785719, lng: 21.0519142 },
    { lat: 49.1777337, lng: 21.0528571 },
    { lat: 49.1774043, lng: 21.0521737 },
    { lat: 49.176895, lng: 21.0528109 },
    { lat: 49.1769712, lng: 21.0529426 },
    { lat: 49.1769345, lng: 21.0529896 },
    { lat: 49.1765304, lng: 21.0525312 },
    { lat: 49.1755601, lng: 21.0521435 },
    { lat: 49.175365, lng: 21.0527239 },
    { lat: 49.1757121, lng: 21.0533625 },
    { lat: 49.1754333, lng: 21.0536533 },
    { lat: 49.1750868, lng: 21.0533441 },
    { lat: 49.1745551, lng: 21.0536758 },
    { lat: 49.1740438, lng: 21.0534564 },
    { lat: 49.1739526, lng: 21.0537985 },
    { lat: 49.1736814, lng: 21.0540771 },
    { lat: 49.1737, lng: 21.0551033 },
    { lat: 49.1739254, lng: 21.0552184 },
    { lat: 49.1736529, lng: 21.0554914 },
    { lat: 49.1734403, lng: 21.0558811 },
    { lat: 49.173452, lng: 21.0561057 },
    { lat: 49.1731525, lng: 21.0570403 },
    { lat: 49.1734993, lng: 21.0573214 },
    { lat: 49.1735969, lng: 21.057276 },
    { lat: 49.173785, lng: 21.0575305 },
    { lat: 49.1737784, lng: 21.0577784 },
    { lat: 49.1730847, lng: 21.0583197 },
    { lat: 49.1728729, lng: 21.0583472 },
    { lat: 49.1740514, lng: 21.0600598 },
    { lat: 49.1754603, lng: 21.0607219 },
    { lat: 49.1763243, lng: 21.0614807 },
    { lat: 49.1761266, lng: 21.06177 },
    { lat: 49.1759888, lng: 21.0622422 },
    { lat: 49.1757673, lng: 21.0637148 },
    { lat: 49.1754423, lng: 21.0651844 },
    { lat: 49.1754623, lng: 21.0657379 },
    { lat: 49.1747953, lng: 21.0673158 },
    { lat: 49.1741643, lng: 21.0683799 },
    { lat: 49.1734394, lng: 21.0703631 },
    { lat: 49.1725407, lng: 21.0710081 },
    { lat: 49.1724223, lng: 21.0712425 },
    { lat: 49.1719479, lng: 21.0717121 },
    { lat: 49.171476, lng: 21.072297 },
    { lat: 49.1710992, lng: 21.0730013 },
    { lat: 49.17073, lng: 21.0735623 },
    { lat: 49.170572, lng: 21.07442 },
    { lat: 49.169855, lng: 21.0769005 },
    { lat: 49.1696811, lng: 21.0788793 },
    { lat: 49.1695778, lng: 21.0808089 },
    { lat: 49.1678257, lng: 21.0857559 },
    { lat: 49.1671008, lng: 21.0888735 },
    { lat: 49.1670051, lng: 21.0896768 },
    { lat: 49.166151, lng: 21.0908391 },
    { lat: 49.1658626, lng: 21.0928643 },
    { lat: 49.1658924, lng: 21.094084 },
    { lat: 49.1659969, lng: 21.0946757 },
    { lat: 49.1663764, lng: 21.0953843 },
    { lat: 49.1675099, lng: 21.097116 },
    { lat: 49.1676427, lng: 21.0976121 },
    { lat: 49.1676782, lng: 21.0979831 },
    { lat: 49.1676259, lng: 21.0982506 },
    { lat: 49.1673078, lng: 21.0989658 },
    { lat: 49.1672108, lng: 21.0995171 },
    { lat: 49.1675574, lng: 21.1008604 },
    { lat: 49.1675968, lng: 21.1023078 },
    { lat: 49.1675328, lng: 21.1028729 },
    { lat: 49.1675255, lng: 21.1040932 },
    { lat: 49.1676202, lng: 21.104125 },
    { lat: 49.1677875, lng: 21.1044316 },
    { lat: 49.1680106, lng: 21.1050091 },
    { lat: 49.1684321, lng: 21.105765 },
    { lat: 49.1686178, lng: 21.1062296 },
    { lat: 49.1689482, lng: 21.1076946 },
    { lat: 49.1690931, lng: 21.1085689 },
    { lat: 49.1692267, lng: 21.1088783 },
    { lat: 49.1693329, lng: 21.1094585 },
    { lat: 49.1693385, lng: 21.1105128 },
    { lat: 49.1696932, lng: 21.1124321 },
    { lat: 49.1697822, lng: 21.1126784 },
    { lat: 49.1696839, lng: 21.113406 },
    { lat: 49.1696831, lng: 21.1138786 },
    { lat: 49.1691997, lng: 21.1146344 },
    { lat: 49.1688063, lng: 21.1151144 },
    { lat: 49.1685912, lng: 21.1156025 },
    { lat: 49.1681541, lng: 21.1162563 },
    { lat: 49.1679448, lng: 21.1167936 },
    { lat: 49.1672213, lng: 21.1168967 },
    { lat: 49.1669191, lng: 21.1168472 },
    { lat: 49.1662972, lng: 21.1170007 },
    { lat: 49.1658235, lng: 21.1169671 },
    { lat: 49.1656139, lng: 21.1170649 },
    { lat: 49.1653764, lng: 21.1173076 },
    { lat: 49.1649559, lng: 21.1179957 },
    { lat: 49.1642735, lng: 21.1202058 },
    { lat: 49.1629627, lng: 21.1217392 },
    { lat: 49.1624212, lng: 21.1219799 },
    { lat: 49.1619212, lng: 21.1221121 },
    { lat: 49.1619269, lng: 21.1221491 },
    { lat: 49.1619598, lng: 21.1224066 },
    { lat: 49.1619091, lng: 21.1231126 },
    { lat: 49.162, lng: 21.1239548 },
    { lat: 49.1619305, lng: 21.1250621 },
    { lat: 49.161957, lng: 21.1253248 },
    { lat: 49.162161, lng: 21.1259433 },
    { lat: 49.1627051, lng: 21.1269125 },
    { lat: 49.1631849, lng: 21.127712 },
    { lat: 49.1636786, lng: 21.1282304 },
    { lat: 49.1639416, lng: 21.1292143 },
    { lat: 49.1638483, lng: 21.1309572 },
    { lat: 49.1639917, lng: 21.1343465 },
    { lat: 49.1640598, lng: 21.1351135 },
    { lat: 49.1641079, lng: 21.1353204 },
    { lat: 49.1644153, lng: 21.1358537 },
    { lat: 49.1645673, lng: 21.1362535 },
    { lat: 49.164572, lng: 21.1366462 },
    { lat: 49.1641319, lng: 21.1378163 },
    { lat: 49.1638418, lng: 21.1395345 },
    { lat: 49.163488, lng: 21.1405562 },
    { lat: 49.1629811, lng: 21.1423482 },
    { lat: 49.1631154, lng: 21.14254 },
    { lat: 49.1633451, lng: 21.1428694 },
    { lat: 49.1633768, lng: 21.1434446 },
    { lat: 49.1632388, lng: 21.1445273 },
    { lat: 49.1630765, lng: 21.1450488 },
    { lat: 49.1629584, lng: 21.1460135 },
    { lat: 49.1628613, lng: 21.1464317 },
    { lat: 49.1628517, lng: 21.1467341 },
    { lat: 49.1627713, lng: 21.1468982 },
    { lat: 49.1628004, lng: 21.1472819 },
    { lat: 49.162743, lng: 21.1475335 },
    { lat: 49.1627775, lng: 21.1480217 },
    { lat: 49.1629698, lng: 21.1488411 },
    { lat: 49.1632091, lng: 21.149505 },
    { lat: 49.1634936, lng: 21.1500321 },
    { lat: 49.1636674, lng: 21.150188 },
    { lat: 49.163902, lng: 21.1502402 },
    { lat: 49.1646785, lng: 21.1508155 },
    { lat: 49.164887, lng: 21.1510415 },
  ],
  PečovskáNováVes: [
    { lat: 49.1255668, lng: 21.0326836 },
    { lat: 49.1246463, lng: 21.0315156 },
    { lat: 49.1240629, lng: 21.0304609 },
    { lat: 49.1239179, lng: 21.0299839 },
    { lat: 49.1237257, lng: 21.0298537 },
    { lat: 49.1234388, lng: 21.0293069 },
    { lat: 49.1229253, lng: 21.0289143 },
    { lat: 49.1224832, lng: 21.0288496 },
    { lat: 49.1223393, lng: 21.0287556 },
    { lat: 49.1222147, lng: 21.028558 },
    { lat: 49.1221364, lng: 21.0280879 },
    { lat: 49.1219883, lng: 21.0277905 },
    { lat: 49.1217293, lng: 21.0276164 },
    { lat: 49.121397, lng: 21.0267045 },
    { lat: 49.1207598, lng: 21.026046 },
    { lat: 49.1199009, lng: 21.0256565 },
    { lat: 49.1194095, lng: 21.0253599 },
    { lat: 49.1190337, lng: 21.0250528 },
    { lat: 49.1189203, lng: 21.0251638 },
    { lat: 49.1173912, lng: 21.0243124 },
    { lat: 49.1170203, lng: 21.0243283 },
    { lat: 49.1163035, lng: 21.0238484 },
    { lat: 49.1157394, lng: 21.0237672 },
    { lat: 49.1153643, lng: 21.0238646 },
    { lat: 49.1151029, lng: 21.0235452 },
    { lat: 49.1148782, lng: 21.0234865 },
    { lat: 49.1135642, lng: 21.0235023 },
    { lat: 49.1136751, lng: 21.0251385 },
    { lat: 49.1137883, lng: 21.0254066 },
    { lat: 49.1139128, lng: 21.0262497 },
    { lat: 49.1137199, lng: 21.0267575 },
    { lat: 49.113486, lng: 21.0270691 },
    { lat: 49.1123998, lng: 21.0298262 },
    { lat: 49.1123273, lng: 21.0304029 },
    { lat: 49.1120778, lng: 21.0310666 },
    { lat: 49.1118352, lng: 21.0321554 },
    { lat: 49.1117254, lng: 21.0332748 },
    { lat: 49.1116142, lng: 21.0338354 },
    { lat: 49.1117197, lng: 21.0343458 },
    { lat: 49.1116858, lng: 21.0353373 },
    { lat: 49.111331, lng: 21.0358501 },
    { lat: 49.1110668, lng: 21.0364775 },
    { lat: 49.1107828, lng: 21.0375048 },
    { lat: 49.1107505, lng: 21.0379699 },
    { lat: 49.1103194, lng: 21.0385557 },
    { lat: 49.1101205, lng: 21.0384945 },
    { lat: 49.1097538, lng: 21.0390553 },
    { lat: 49.1096608, lng: 21.0395153 },
    { lat: 49.109333, lng: 21.0398848 },
    { lat: 49.1092499, lng: 21.0401375 },
    { lat: 49.1092726, lng: 21.0406908 },
    { lat: 49.1088985, lng: 21.0416824 },
    { lat: 49.108834, lng: 21.0424707 },
    { lat: 49.1087404, lng: 21.0429131 },
    { lat: 49.1085695, lng: 21.0433725 },
    { lat: 49.1084795, lng: 21.0439271 },
    { lat: 49.1084997, lng: 21.0446867 },
    { lat: 49.1090184, lng: 21.0462759 },
    { lat: 49.1089984, lng: 21.0467326 },
    { lat: 49.1090454, lng: 21.0472826 },
    { lat: 49.1088388, lng: 21.0485812 },
    { lat: 49.1082747, lng: 21.0500662 },
    { lat: 49.1083396, lng: 21.0503663 },
    { lat: 49.1082594, lng: 21.0507707 },
    { lat: 49.1082364, lng: 21.0515533 },
    { lat: 49.1084917, lng: 21.0532903 },
    { lat: 49.1087384, lng: 21.0538149 },
    { lat: 49.1090886, lng: 21.0558766 },
    { lat: 49.1089995, lng: 21.0569775 },
    { lat: 49.1087194, lng: 21.0585108 },
    { lat: 49.1081473, lng: 21.060099 },
    { lat: 49.1079608, lng: 21.0603924 },
    { lat: 49.1076972, lng: 21.0612829 },
    { lat: 49.1069688, lng: 21.0625688 },
    { lat: 49.1067208, lng: 21.06398 },
    { lat: 49.1069385, lng: 21.0654568 },
    { lat: 49.1070014, lng: 21.0665369 },
    { lat: 49.1071975, lng: 21.0665833 },
    { lat: 49.1077365, lng: 21.0670328 },
    { lat: 49.108754, lng: 21.0676312 },
    { lat: 49.1092657, lng: 21.0682793 },
    { lat: 49.1095408, lng: 21.0685048 },
    { lat: 49.1100948, lng: 21.068935 },
    { lat: 49.1105152, lng: 21.0690957 },
    { lat: 49.111004, lng: 21.069451 },
    { lat: 49.1125068, lng: 21.0711469 },
    { lat: 49.1135361, lng: 21.0721081 },
    { lat: 49.1137124, lng: 21.072312 },
    { lat: 49.1165239, lng: 21.0755634 },
    { lat: 49.1167509, lng: 21.0759768 },
    { lat: 49.1171897, lng: 21.0764603 },
    { lat: 49.1175598, lng: 21.0760022 },
    { lat: 49.1179044, lng: 21.0758346 },
    { lat: 49.1182963, lng: 21.0753505 },
    { lat: 49.1186942, lng: 21.0753013 },
    { lat: 49.1195876, lng: 21.0756118 },
    { lat: 49.1195722, lng: 21.0756611 },
    { lat: 49.1197318, lng: 21.0755493 },
    { lat: 49.1207254, lng: 21.0746361 },
    { lat: 49.1214564, lng: 21.0738525 },
    { lat: 49.121868, lng: 21.0729673 },
    { lat: 49.1221485, lng: 21.072628 },
    { lat: 49.1228331, lng: 21.0720806 },
    { lat: 49.122995, lng: 21.0718726 },
    { lat: 49.123668, lng: 21.0707494 },
    { lat: 49.1241074, lng: 21.070211 },
    { lat: 49.1252352, lng: 21.0691683 },
    { lat: 49.12546, lng: 21.069016 },
    { lat: 49.125875, lng: 21.0689051 },
    { lat: 49.1265139, lng: 21.0684753 },
    { lat: 49.1267547, lng: 21.0683732 },
    { lat: 49.1272841, lng: 21.0683145 },
    { lat: 49.128097, lng: 21.068332 },
    { lat: 49.1287555, lng: 21.0685748 },
    { lat: 49.1291707, lng: 21.0684701 },
    { lat: 49.1299739, lng: 21.0681102 },
    { lat: 49.1305592, lng: 21.0680085 },
    { lat: 49.1309964, lng: 21.0678558 },
    { lat: 49.1317215, lng: 21.0678313 },
    { lat: 49.1320792, lng: 21.0679619 },
    { lat: 49.1328101, lng: 21.0684681 },
    { lat: 49.1330276, lng: 21.068721 },
    { lat: 49.133713, lng: 21.0692497 },
    { lat: 49.1342743, lng: 21.0699387 },
    { lat: 49.1349571, lng: 21.0703163 },
    { lat: 49.1360096, lng: 21.0711928 },
    { lat: 49.1366823, lng: 21.0712948 },
    { lat: 49.1369317, lng: 21.0714146 },
    { lat: 49.137108, lng: 21.0715814 },
    { lat: 49.1383888, lng: 21.0712685 },
    { lat: 49.1389468, lng: 21.0712979 },
    { lat: 49.1395607, lng: 21.0712121 },
    { lat: 49.1399623, lng: 21.0709275 },
    { lat: 49.1401943, lng: 21.070858 },
    { lat: 49.1403189, lng: 21.0709576 },
    { lat: 49.1404366, lng: 21.0711548 },
    { lat: 49.1406023, lng: 21.0712347 },
    { lat: 49.1414523, lng: 21.0711725 },
    { lat: 49.1420293, lng: 21.0711973 },
    { lat: 49.1424038, lng: 21.0712958 },
    { lat: 49.1428969, lng: 21.0712657 },
    { lat: 49.1430816, lng: 21.0712382 },
    { lat: 49.1431374, lng: 21.0710809 },
    { lat: 49.1433671, lng: 21.0689221 },
    { lat: 49.1435149, lng: 21.0681598 },
    { lat: 49.1445179, lng: 21.0608099 },
    { lat: 49.1455714, lng: 21.0536144 },
    { lat: 49.1457628, lng: 21.0526057 },
    { lat: 49.1457574, lng: 21.0518484 },
    { lat: 49.1459388, lng: 21.0510216 },
    { lat: 49.1461722, lng: 21.0494755 },
    { lat: 49.1461935, lng: 21.0493984 },
    { lat: 49.146847, lng: 21.0472754 },
    { lat: 49.1468634, lng: 21.0469787 },
    { lat: 49.1470982, lng: 21.0462355 },
    { lat: 49.1471401, lng: 21.0458617 },
    { lat: 49.1471342, lng: 21.0450932 },
    { lat: 49.1472081, lng: 21.0447372 },
    { lat: 49.1478006, lng: 21.0435349 },
    { lat: 49.1487867, lng: 21.0417817 },
    { lat: 49.1509102, lng: 21.0382766 },
    { lat: 49.1516566, lng: 21.0368983 },
    { lat: 49.1514428, lng: 21.0368946 },
    { lat: 49.1503859, lng: 21.0367505 },
    { lat: 49.1502807, lng: 21.0366725 },
    { lat: 49.1498154, lng: 21.0366196 },
    { lat: 49.1495696, lng: 21.0365012 },
    { lat: 49.1495746, lng: 21.0365537 },
    { lat: 49.149417, lng: 21.0366544 },
    { lat: 49.147791, lng: 21.0360008 },
    { lat: 49.1469544, lng: 21.03578 },
    { lat: 49.1457595, lng: 21.0352372 },
    { lat: 49.1445103, lng: 21.0349901 },
    { lat: 49.1433529, lng: 21.0352218 },
    { lat: 49.1419461, lng: 21.0359794 },
    { lat: 49.1413573, lng: 21.0361788 },
    { lat: 49.1397796, lng: 21.0363577 },
    { lat: 49.1397453, lng: 21.0364074 },
    { lat: 49.1390279, lng: 21.0365277 },
    { lat: 49.1382354, lng: 21.0364884 },
    { lat: 49.1379251, lng: 21.036533 },
    { lat: 49.1368109, lng: 21.0371225 },
    { lat: 49.1363961, lng: 21.0372031 },
    { lat: 49.1356073, lng: 21.0370017 },
    { lat: 49.1341435, lng: 21.0363859 },
    { lat: 49.1335156, lng: 21.0360405 },
    { lat: 49.1328126, lng: 21.0358453 },
    { lat: 49.1312757, lng: 21.0357943 },
    { lat: 49.1299499, lng: 21.036063 },
    { lat: 49.1292248, lng: 21.0358091 },
    { lat: 49.1289284, lng: 21.0357966 },
    { lat: 49.1289254, lng: 21.0357059 },
    { lat: 49.1289828, lng: 21.0356344 },
    { lat: 49.1286628, lng: 21.0351051 },
    { lat: 49.1286347, lng: 21.0348288 },
    { lat: 49.1279266, lng: 21.0356868 },
    { lat: 49.1255668, lng: 21.0326836 },
  ],
  Lipany: [
    { lat: 49.1565446, lng: 20.9965783 },
    { lat: 49.1565233, lng: 20.9964483 },
    { lat: 49.1565263, lng: 20.9951307 },
    { lat: 49.1567461, lng: 20.9921989 },
    { lat: 49.1567422, lng: 20.9910191 },
    { lat: 49.1570763, lng: 20.9910802 },
    { lat: 49.1571415, lng: 20.990839 },
    { lat: 49.1590185, lng: 20.991694 },
    { lat: 49.1600652, lng: 20.9922403 },
    { lat: 49.16092, lng: 20.9929409 },
    { lat: 49.1614974, lng: 20.9931798 },
    { lat: 49.1617753, lng: 20.9919492 },
    { lat: 49.1620234, lng: 20.9905587 },
    { lat: 49.1621646, lng: 20.9901347 },
    { lat: 49.1624739, lng: 20.9895126 },
    { lat: 49.16291, lng: 20.9891218 },
    { lat: 49.1633823, lng: 20.9905443 },
    { lat: 49.1634396, lng: 20.9904367 },
    { lat: 49.1635388, lng: 20.9905139 },
    { lat: 49.1638508, lng: 20.9908943 },
    { lat: 49.1639108, lng: 20.9910745 },
    { lat: 49.1640427, lng: 20.9912572 },
    { lat: 49.164159, lng: 20.9912663 },
    { lat: 49.1643181, lng: 20.9914149 },
    { lat: 49.1649781, lng: 20.9915327 },
    { lat: 49.165602, lng: 20.9913803 },
    { lat: 49.1661051, lng: 20.9911023 },
    { lat: 49.1663315, lng: 20.9901471 },
    { lat: 49.1663606, lng: 20.9890825 },
    { lat: 49.1662069, lng: 20.986433 },
    { lat: 49.1669187, lng: 20.9834427 },
    { lat: 49.167035, lng: 20.9831504 },
    { lat: 49.1671359, lng: 20.9831351 },
    { lat: 49.1675905, lng: 20.9826539 },
    { lat: 49.16819, lng: 20.9823026 },
    { lat: 49.1683601, lng: 20.9825118 },
    { lat: 49.1685235, lng: 20.9824198 },
    { lat: 49.1686875, lng: 20.9822008 },
    { lat: 49.1688706, lng: 20.9823324 },
    { lat: 49.1694412, lng: 20.9820574 },
    { lat: 49.1698938, lng: 20.9825093 },
    { lat: 49.1703714, lng: 20.9824803 },
    { lat: 49.1709767, lng: 20.9828867 },
    { lat: 49.1713072, lng: 20.9829113 },
    { lat: 49.1714923, lng: 20.9833032 },
    { lat: 49.1723488, lng: 20.9837177 },
    { lat: 49.1725108, lng: 20.9839902 },
    { lat: 49.1739484, lng: 20.9841397 },
    { lat: 49.1743162, lng: 20.9844363 },
    { lat: 49.1750315, lng: 20.9839441 },
    { lat: 49.1759197, lng: 20.9837443 },
    { lat: 49.1763606, lng: 20.9824375 },
    { lat: 49.1763989, lng: 20.981863 },
    { lat: 49.1769098, lng: 20.9817206 },
    { lat: 49.1771321, lng: 20.9812963 },
    { lat: 49.177098, lng: 20.9812198 },
    { lat: 49.176995, lng: 20.9805223 },
    { lat: 49.176975, lng: 20.9800156 },
    { lat: 49.1768719, lng: 20.9794497 },
    { lat: 49.1770226, lng: 20.9789339 },
    { lat: 49.1770003, lng: 20.9771284 },
    { lat: 49.1766179, lng: 20.9757408 },
    { lat: 49.1762282, lng: 20.9738593 },
    { lat: 49.1767591, lng: 20.9736828 },
    { lat: 49.1771466, lng: 20.9719545 },
    { lat: 49.1770082, lng: 20.9705216 },
    { lat: 49.1771502, lng: 20.9698801 },
    { lat: 49.1770465, lng: 20.9693704 },
    { lat: 49.1773203, lng: 20.9687975 },
    { lat: 49.1780059, lng: 20.9676444 },
    { lat: 49.1791076, lng: 20.9659454 },
    { lat: 49.1802171, lng: 20.9644743 },
    { lat: 49.1812654, lng: 20.9628951 },
    { lat: 49.1814758, lng: 20.9624832 },
    { lat: 49.1816302, lng: 20.9612341 },
    { lat: 49.1815621, lng: 20.9591168 },
    { lat: 49.1811746, lng: 20.9575732 },
    { lat: 49.1821923, lng: 20.9561246 },
    { lat: 49.1825171, lng: 20.9540457 },
    { lat: 49.182021, lng: 20.9540196 },
    { lat: 49.1820204, lng: 20.9537606 },
    { lat: 49.1809417, lng: 20.9538821 },
    { lat: 49.1809745, lng: 20.9534112 },
    { lat: 49.1815098, lng: 20.9533328 },
    { lat: 49.1816938, lng: 20.9530797 },
    { lat: 49.1811554, lng: 20.9525194 },
    { lat: 49.1814251, lng: 20.9520672 },
    { lat: 49.1815859, lng: 20.9519616 },
    { lat: 49.1815316, lng: 20.9518525 },
    { lat: 49.1813872, lng: 20.9504531 },
    { lat: 49.1808697, lng: 20.9495768 },
    { lat: 49.1809038, lng: 20.9489482 },
    { lat: 49.1803133, lng: 20.9471628 },
    { lat: 49.180271, lng: 20.9459278 },
    { lat: 49.1801206, lng: 20.9441421 },
    { lat: 49.1815882, lng: 20.9436036 },
    { lat: 49.1809655, lng: 20.9425823 },
    { lat: 49.1815361, lng: 20.9382239 },
    { lat: 49.1814709, lng: 20.9351249 },
    { lat: 49.180729, lng: 20.9350116 },
    { lat: 49.1805658, lng: 20.9351819 },
    { lat: 49.1805029, lng: 20.9351223 },
    { lat: 49.1786806, lng: 20.9352135 },
    { lat: 49.1771164, lng: 20.9348496 },
    { lat: 49.1767866, lng: 20.9347123 },
    { lat: 49.174624, lng: 20.9351651 },
    { lat: 49.1745378, lng: 20.9352459 },
    { lat: 49.1741317, lng: 20.9351562 },
    { lat: 49.1728319, lng: 20.9361977 },
    { lat: 49.1719708, lng: 20.9370749 },
    { lat: 49.171051, lng: 20.9383181 },
    { lat: 49.1701679, lng: 20.9388999 },
    { lat: 49.1691802, lng: 20.9392477 },
    { lat: 49.1683204, lng: 20.9392482 },
    { lat: 49.1677193, lng: 20.9393509 },
    { lat: 49.1671196, lng: 20.9392859 },
    { lat: 49.1666726, lng: 20.9395521 },
    { lat: 49.1663828, lng: 20.9396322 },
    { lat: 49.1661204, lng: 20.939395 },
    { lat: 49.1653832, lng: 20.9395651 },
    { lat: 49.1651853, lng: 20.9392309 },
    { lat: 49.164962, lng: 20.9392478 },
    { lat: 49.164845, lng: 20.9389512 },
    { lat: 49.1645205, lng: 20.9388735 },
    { lat: 49.16439, lng: 20.9386991 },
    { lat: 49.1642684, lng: 20.9386316 },
    { lat: 49.1640222, lng: 20.9386741 },
    { lat: 49.1639, lng: 20.9384199 },
    { lat: 49.1635098, lng: 20.9385635 },
    { lat: 49.1631327, lng: 20.9383945 },
    { lat: 49.1628578, lng: 20.9379084 },
    { lat: 49.162648, lng: 20.9377636 },
    { lat: 49.1620055, lng: 20.9365875 },
    { lat: 49.1619955, lng: 20.936558 },
    { lat: 49.161769, lng: 20.9365469 },
    { lat: 49.1612724, lng: 20.9364717 },
    { lat: 49.160617, lng: 20.936492 },
    { lat: 49.1603076, lng: 20.9369023 },
    { lat: 49.1599512, lng: 20.9369166 },
    { lat: 49.159763, lng: 20.9371426 },
    { lat: 49.1594722, lng: 20.9372346 },
    { lat: 49.1593898, lng: 20.9374575 },
    { lat: 49.1590959, lng: 20.9377611 },
    { lat: 49.1584378, lng: 20.9381023 },
    { lat: 49.1583924, lng: 20.9382281 },
    { lat: 49.1579755, lng: 20.9384194 },
    { lat: 49.1578247, lng: 20.9385861 },
    { lat: 49.1575587, lng: 20.9387084 },
    { lat: 49.1577642, lng: 20.9389992 },
    { lat: 49.1573629, lng: 20.9400289 },
    { lat: 49.1572874, lng: 20.9404321 },
    { lat: 49.1566787, lng: 20.9418536 },
    { lat: 49.1564235, lng: 20.9427741 },
    { lat: 49.1557345, lng: 20.9435135 },
    { lat: 49.1554549, lng: 20.9435959 },
    { lat: 49.1554407, lng: 20.9444205 },
    { lat: 49.1550899, lng: 20.9454443 },
    { lat: 49.1549467, lng: 20.945203 },
    { lat: 49.1546202, lng: 20.9456677 },
    { lat: 49.1548789, lng: 20.9461057 },
    { lat: 49.1548251, lng: 20.9462462 },
    { lat: 49.1546635, lng: 20.9462939 },
    { lat: 49.1546705, lng: 20.9466756 },
    { lat: 49.1544234, lng: 20.9459468 },
    { lat: 49.1532188, lng: 20.9484386 },
    { lat: 49.1528386, lng: 20.9487139 },
    { lat: 49.1529332, lng: 20.9490187 },
    { lat: 49.1509414, lng: 20.9531218 },
    { lat: 49.1506361, lng: 20.9528765 },
    { lat: 49.1505285, lng: 20.9530933 },
    { lat: 49.1491529, lng: 20.955594 },
    { lat: 49.1485477, lng: 20.9570302 },
    { lat: 49.1478702, lng: 20.9598388 },
    { lat: 49.1475762, lng: 20.9608864 },
    { lat: 49.1476125, lng: 20.9610986 },
    { lat: 49.1471952, lng: 20.9608313 },
    { lat: 49.1465589, lng: 20.9608894 },
    { lat: 49.1463288, lng: 20.9607068 },
    { lat: 49.1458263, lng: 20.960788 },
    { lat: 49.1458071, lng: 20.9609911 },
    { lat: 49.1458773, lng: 20.9614782 },
    { lat: 49.1459799, lng: 20.9615509 },
    { lat: 49.1463993, lng: 20.9621505 },
    { lat: 49.1465022, lng: 20.9629483 },
    { lat: 49.1464885, lng: 20.9637602 },
    { lat: 49.146473, lng: 20.9646801 },
    { lat: 49.1462898, lng: 20.964893 },
    { lat: 49.1458232, lng: 20.9652104 },
    { lat: 49.1450691, lng: 20.9660618 },
    { lat: 49.1446244, lng: 20.9664271 },
    { lat: 49.1441308, lng: 20.9670557 },
    { lat: 49.1438985, lng: 20.9675521 },
    { lat: 49.1437856, lng: 20.9679112 },
    { lat: 49.1436208, lng: 20.9688169 },
    { lat: 49.1435507, lng: 20.9694851 },
    { lat: 49.1435456, lng: 20.9705931 },
    { lat: 49.1433889, lng: 20.9716016 },
    { lat: 49.1430679, lng: 20.9725508 },
    { lat: 49.1424102, lng: 20.9740705 },
    { lat: 49.1450417, lng: 20.9753251 },
    { lat: 49.1464345, lng: 20.9768084 },
    { lat: 49.1465161, lng: 20.9774471 },
    { lat: 49.1465046, lng: 20.977984 },
    { lat: 49.1464015, lng: 20.9789198 },
    { lat: 49.1459675, lng: 20.9800959 },
    { lat: 49.1458832, lng: 20.9815666 },
    { lat: 49.145718, lng: 20.9822835 },
    { lat: 49.1453678, lng: 20.9832018 },
    { lat: 49.1449025, lng: 20.983973 },
    { lat: 49.1450658, lng: 20.9841841 },
    { lat: 49.145626, lng: 20.9838647 },
    { lat: 49.1450398, lng: 20.9858861 },
    { lat: 49.1453478, lng: 20.9860153 },
    { lat: 49.1456765, lng: 20.9859775 },
    { lat: 49.1478938, lng: 20.9852027 },
    { lat: 49.1486697, lng: 20.9848036 },
    { lat: 49.1492822, lng: 20.9844018 },
    { lat: 49.1497272, lng: 20.9844159 },
    { lat: 49.1502232, lng: 20.9853971 },
    { lat: 49.1509348, lng: 20.9857816 },
    { lat: 49.1514791, lng: 20.9863522 },
    { lat: 49.1509767, lng: 20.9898594 },
    { lat: 49.1518515, lng: 20.9901779 },
    { lat: 49.1514317, lng: 20.9919925 },
    { lat: 49.1517361, lng: 20.9921037 },
    { lat: 49.1519634, lng: 20.9921025 },
    { lat: 49.1523052, lng: 20.9919901 },
    { lat: 49.1527172, lng: 20.9919971 },
    { lat: 49.1540369, lng: 20.9921543 },
    { lat: 49.1537724, lng: 20.9943825 },
    { lat: 49.154401, lng: 20.9949083 },
    { lat: 49.1548099, lng: 20.995132 },
    { lat: 49.1564915, lng: 20.9965403 },
    { lat: 49.1565446, lng: 20.9965783 },
  ],
  Jakubovany: [
    { lat: 49.1043555, lng: 21.1222627 },
    { lat: 49.1042867, lng: 21.1222947 },
    { lat: 49.1043531, lng: 21.1226461 },
    { lat: 49.1044302, lng: 21.1229802 },
    { lat: 49.1045271, lng: 21.1230241 },
    { lat: 49.1045019, lng: 21.1233291 },
    { lat: 49.1043051, lng: 21.1238597 },
    { lat: 49.1042681, lng: 21.1240729 },
    { lat: 49.1043266, lng: 21.1242271 },
    { lat: 49.1044729, lng: 21.124613 },
    { lat: 49.1043666, lng: 21.1251917 },
    { lat: 49.1042524, lng: 21.1258131 },
    { lat: 49.1042425, lng: 21.1260002 },
    { lat: 49.104225, lng: 21.126333 },
    { lat: 49.1038438, lng: 21.1272626 },
    { lat: 49.1035129, lng: 21.1275423 },
    { lat: 49.1025834, lng: 21.1287852 },
    { lat: 49.1023352, lng: 21.1289464 },
    { lat: 49.1019704, lng: 21.129011 },
    { lat: 49.1012547, lng: 21.1290799 },
    { lat: 49.1010728, lng: 21.1290247 },
    { lat: 49.1008181, lng: 21.12958 },
    { lat: 49.0996569, lng: 21.1309658 },
    { lat: 49.097181, lng: 21.1315955 },
    { lat: 49.0966031, lng: 21.1319684 },
    { lat: 49.095295, lng: 21.1331566 },
    { lat: 49.0950788, lng: 21.1339026 },
    { lat: 49.0949877, lng: 21.1340784 },
    { lat: 49.0945401, lng: 21.1343745 },
    { lat: 49.0936962, lng: 21.135224 },
    { lat: 49.0934506, lng: 21.1355416 },
    { lat: 49.0917881, lng: 21.1371542 },
    { lat: 49.0914176, lng: 21.1373624 },
    { lat: 49.0911134, lng: 21.1376365 },
    { lat: 49.0909058, lng: 21.1376865 },
    { lat: 49.0907783, lng: 21.1376069 },
    { lat: 49.0904532, lng: 21.1376469 },
    { lat: 49.0900803, lng: 21.1380621 },
    { lat: 49.0897599, lng: 21.1382874 },
    { lat: 49.0894916, lng: 21.1382669 },
    { lat: 49.089074, lng: 21.1381248 },
    { lat: 49.0888913, lng: 21.1381497 },
    { lat: 49.0886871, lng: 21.1379877 },
    { lat: 49.0885181, lng: 21.1379833 },
    { lat: 49.0875765, lng: 21.1369254 },
    { lat: 49.0873672, lng: 21.1368248 },
    { lat: 49.0870878, lng: 21.1368109 },
    { lat: 49.0869529, lng: 21.136498 },
    { lat: 49.0866036, lng: 21.1362347 },
    { lat: 49.0864521, lng: 21.1364246 },
    { lat: 49.0862936, lng: 21.1363946 },
    { lat: 49.0861791, lng: 21.1362637 },
    { lat: 49.0860156, lng: 21.1362832 },
    { lat: 49.0857925, lng: 21.1365592 },
    { lat: 49.0856597, lng: 21.1367043 },
    { lat: 49.0855897, lng: 21.1370864 },
    { lat: 49.0850946, lng: 21.1382577 },
    { lat: 49.0846161, lng: 21.1396707 },
    { lat: 49.0827193, lng: 21.1471946 },
    { lat: 49.0832035, lng: 21.1475946 },
    { lat: 49.083447, lng: 21.1478966 },
    { lat: 49.0841423, lng: 21.148498 },
    { lat: 49.0843574, lng: 21.1486122 },
    { lat: 49.0847095, lng: 21.148962 },
    { lat: 49.0848629, lng: 21.1492225 },
    { lat: 49.0847628, lng: 21.1493677 },
    { lat: 49.0852185, lng: 21.1494675 },
    { lat: 49.0851252, lng: 21.1501873 },
    { lat: 49.0854059, lng: 21.1501657 },
    { lat: 49.0860143, lng: 21.1499065 },
    { lat: 49.0868054, lng: 21.1498008 },
    { lat: 49.0870417, lng: 21.1498313 },
    { lat: 49.0874708, lng: 21.1497371 },
    { lat: 49.0880679, lng: 21.1498287 },
    { lat: 49.0888284, lng: 21.1503906 },
    { lat: 49.0896853, lng: 21.150693 },
    { lat: 49.0900169, lng: 21.1510023 },
    { lat: 49.0901298, lng: 21.1512615 },
    { lat: 49.0902237, lng: 21.1513171 },
    { lat: 49.0900713, lng: 21.1514105 },
    { lat: 49.0900141, lng: 21.1515572 },
    { lat: 49.0901284, lng: 21.1516814 },
    { lat: 49.0903026, lng: 21.1517156 },
    { lat: 49.0904172, lng: 21.1518868 },
    { lat: 49.0904783, lng: 21.1521135 },
    { lat: 49.0904775, lng: 21.1522964 },
    { lat: 49.0908388, lng: 21.1528358 },
    { lat: 49.0913514, lng: 21.1543523 },
    { lat: 49.0914334, lng: 21.1547011 },
    { lat: 49.0925392, lng: 21.1541969 },
    { lat: 49.0936746, lng: 21.1534399 },
    { lat: 49.0944119, lng: 21.1532358 },
    { lat: 49.0957056, lng: 21.1526557 },
    { lat: 49.0984712, lng: 21.1521377 },
    { lat: 49.0987342, lng: 21.1520208 },
    { lat: 49.1009915, lng: 21.1516606 },
    { lat: 49.1017326, lng: 21.1517188 },
    { lat: 49.1025131, lng: 21.1516634 },
    { lat: 49.1038117, lng: 21.1514183 },
    { lat: 49.1046554, lng: 21.1511554 },
    { lat: 49.1058105, lng: 21.1510694 },
    { lat: 49.1062493, lng: 21.1508153 },
    { lat: 49.1067839, lng: 21.1507848 },
    { lat: 49.107634, lng: 21.1505835 },
    { lat: 49.1083515, lng: 21.1506036 },
    { lat: 49.1094539, lng: 21.1504851 },
    { lat: 49.1100227, lng: 21.1503576 },
    { lat: 49.1115209, lng: 21.1504067 },
    { lat: 49.1118686, lng: 21.1497986 },
    { lat: 49.1123039, lng: 21.1498655 },
    { lat: 49.1144556, lng: 21.1495879 },
    { lat: 49.1144703, lng: 21.1492686 },
    { lat: 49.1146165, lng: 21.1492967 },
    { lat: 49.1149412, lng: 21.149566 },
    { lat: 49.1151318, lng: 21.1496494 },
    { lat: 49.1164289, lng: 21.1499618 },
    { lat: 49.1167113, lng: 21.1501017 },
    { lat: 49.1169416, lng: 21.1498359 },
    { lat: 49.1176985, lng: 21.1495735 },
    { lat: 49.117873, lng: 21.1494023 },
    { lat: 49.1182424, lng: 21.1494428 },
    { lat: 49.1189392, lng: 21.1493827 },
    { lat: 49.1191176, lng: 21.1494681 },
    { lat: 49.1192253, lng: 21.1496388 },
    { lat: 49.1193215, lng: 21.1496242 },
    { lat: 49.1193512, lng: 21.1494596 },
    { lat: 49.1194564, lng: 21.1492593 },
    { lat: 49.1196153, lng: 21.1491245 },
    { lat: 49.119967, lng: 21.1492147 },
    { lat: 49.1202546, lng: 21.1491603 },
    { lat: 49.1209807, lng: 21.1487801 },
    { lat: 49.1219617, lng: 21.1485207 },
    { lat: 49.1225533, lng: 21.1484755 },
    { lat: 49.1227688, lng: 21.1485345 },
    { lat: 49.12299, lng: 21.1488327 },
    { lat: 49.123123, lng: 21.1484898 },
    { lat: 49.1232362, lng: 21.148363 },
    { lat: 49.1237917, lng: 21.1483525 },
    { lat: 49.1238011, lng: 21.1482242 },
    { lat: 49.1245779, lng: 21.1485949 },
    { lat: 49.1247658, lng: 21.1485549 },
    { lat: 49.1249058, lng: 21.1487102 },
    { lat: 49.1253739, lng: 21.148995 },
    { lat: 49.1254539, lng: 21.1491084 },
    { lat: 49.1255144, lng: 21.1490972 },
    { lat: 49.1259237, lng: 21.1495134 },
    { lat: 49.1262133, lng: 21.1496726 },
    { lat: 49.1263153, lng: 21.1499073 },
    { lat: 49.1264566, lng: 21.1498934 },
    { lat: 49.1266477, lng: 21.1501726 },
    { lat: 49.1268345, lng: 21.1502041 },
    { lat: 49.1268746, lng: 21.1501595 },
    { lat: 49.1270665, lng: 21.1502497 },
    { lat: 49.1273395, lng: 21.1502678 },
    { lat: 49.1274709, lng: 21.1501899 },
    { lat: 49.1286352, lng: 21.1499971 },
    { lat: 49.1290502, lng: 21.1498104 },
    { lat: 49.129239, lng: 21.1499127 },
    { lat: 49.1298787, lng: 21.1498209 },
    { lat: 49.1302322, lng: 21.1499707 },
    { lat: 49.1302992, lng: 21.1499209 },
    { lat: 49.1303752, lng: 21.1500418 },
    { lat: 49.1306237, lng: 21.1500035 },
    { lat: 49.1309924, lng: 21.1501353 },
    { lat: 49.1312861, lng: 21.1504167 },
    { lat: 49.1321255, lng: 21.150771 },
    { lat: 49.1326988, lng: 21.1512896 },
    { lat: 49.1330053, lng: 21.1514446 },
    { lat: 49.1337054, lng: 21.1521994 },
    { lat: 49.1347705, lng: 21.1530741 },
    { lat: 49.1353844, lng: 21.1533095 },
    { lat: 49.1356013, lng: 21.1532613 },
    { lat: 49.1365439, lng: 21.1524268 },
    { lat: 49.1369625, lng: 21.1523178 },
    { lat: 49.1375675, lng: 21.152345 },
    { lat: 49.1382089, lng: 21.1522032 },
    { lat: 49.1388529, lng: 21.1516032 },
    { lat: 49.1398486, lng: 21.151916 },
    { lat: 49.1404409, lng: 21.1516055 },
    { lat: 49.141094, lng: 21.1505492 },
    { lat: 49.141674, lng: 21.1494252 },
    { lat: 49.142034, lng: 21.1483166 },
    { lat: 49.142551, lng: 21.1479387 },
    { lat: 49.1427705, lng: 21.1475887 },
    { lat: 49.1430555, lng: 21.1474118 },
    { lat: 49.144013, lng: 21.1460954 },
    { lat: 49.1442569, lng: 21.1459035 },
    { lat: 49.1449009, lng: 21.1451999 },
    { lat: 49.1451152, lng: 21.1451098 },
    { lat: 49.145554, lng: 21.1447875 },
    { lat: 49.1459127, lng: 21.144208 },
    { lat: 49.1461003, lng: 21.1440544 },
    { lat: 49.1466737, lng: 21.1431866 },
    { lat: 49.1470902, lng: 21.1428688 },
    { lat: 49.1476715, lng: 21.1416809 },
    { lat: 49.1485745, lng: 21.1409478 },
    { lat: 49.1488577, lng: 21.1410053 },
    { lat: 49.1492299, lng: 21.140965 },
    { lat: 49.1493777, lng: 21.140658 },
    { lat: 49.1500435, lng: 21.1400037 },
    { lat: 49.1505394, lng: 21.1399216 },
    { lat: 49.1508042, lng: 21.1400049 },
    { lat: 49.1514356, lng: 21.1391144 },
    { lat: 49.1522228, lng: 21.1386036 },
    { lat: 49.1525429, lng: 21.13851 },
    { lat: 49.1497413, lng: 21.1371773 },
    { lat: 49.149557, lng: 21.1370083 },
    { lat: 49.1494582, lng: 21.1370828 },
    { lat: 49.1492818, lng: 21.1367018 },
    { lat: 49.1491967, lng: 21.1366045 },
    { lat: 49.1490703, lng: 21.1365973 },
    { lat: 49.1488696, lng: 21.1361965 },
    { lat: 49.148267, lng: 21.1360345 },
    { lat: 49.1479832, lng: 21.1358813 },
    { lat: 49.1474745, lng: 21.1358436 },
    { lat: 49.14705, lng: 21.1356265 },
    { lat: 49.1463397, lng: 21.1358628 },
    { lat: 49.1460807, lng: 21.1358592 },
    { lat: 49.145773, lng: 21.1357337 },
    { lat: 49.1453973, lng: 21.1353142 },
    { lat: 49.1453203, lng: 21.135124 },
    { lat: 49.1450679, lng: 21.133938 },
    { lat: 49.1449868, lng: 21.1338631 },
    { lat: 49.1447175, lng: 21.1338201 },
    { lat: 49.1445659, lng: 21.1335462 },
    { lat: 49.1440493, lng: 21.133972 },
    { lat: 49.1439564, lng: 21.1341284 },
    { lat: 49.1435116, lng: 21.1344379 },
    { lat: 49.1433318, lng: 21.1346956 },
    { lat: 49.1433, lng: 21.1346533 },
    { lat: 49.1431795, lng: 21.1348606 },
    { lat: 49.1429617, lng: 21.1355179 },
    { lat: 49.1421818, lng: 21.1368638 },
    { lat: 49.1419236, lng: 21.1371322 },
    { lat: 49.1405301, lng: 21.1370471 },
    { lat: 49.1402355, lng: 21.1367399 },
    { lat: 49.1400555, lng: 21.136246 },
    { lat: 49.1400391, lng: 21.1360768 },
    { lat: 49.1398277, lng: 21.135655 },
    { lat: 49.1396077, lng: 21.1354623 },
    { lat: 49.1394622, lng: 21.1350608 },
    { lat: 49.1391634, lng: 21.1347234 },
    { lat: 49.1387596, lng: 21.1341266 },
    { lat: 49.138594, lng: 21.1339742 },
    { lat: 49.1371431, lng: 21.1333306 },
    { lat: 49.1368058, lng: 21.1334391 },
    { lat: 49.1368074, lng: 21.133383 },
    { lat: 49.1366503, lng: 21.1332928 },
    { lat: 49.1362103, lng: 21.1334711 },
    { lat: 49.1359913, lng: 21.1334517 },
    { lat: 49.1359247, lng: 21.1333365 },
    { lat: 49.1357219, lng: 21.1334337 },
    { lat: 49.135643, lng: 21.133363 },
    { lat: 49.1355477, lng: 21.1329075 },
    { lat: 49.1350837, lng: 21.1330056 },
    { lat: 49.134511, lng: 21.1324724 },
    { lat: 49.1340045, lng: 21.1321546 },
    { lat: 49.133649, lng: 21.1317251 },
    { lat: 49.1335613, lng: 21.1315192 },
    { lat: 49.1333771, lng: 21.1314958 },
    { lat: 49.13329, lng: 21.13127 },
    { lat: 49.1331453, lng: 21.131174 },
    { lat: 49.1330653, lng: 21.1308844 },
    { lat: 49.1327971, lng: 21.1309099 },
    { lat: 49.1327866, lng: 21.1305783 },
    { lat: 49.132716, lng: 21.13048 },
    { lat: 49.1323778, lng: 21.1303896 },
    { lat: 49.1322647, lng: 21.1302351 },
    { lat: 49.1321685, lng: 21.1298552 },
    { lat: 49.1319756, lng: 21.1298503 },
    { lat: 49.1318404, lng: 21.1293777 },
    { lat: 49.1317153, lng: 21.1292335 },
    { lat: 49.1315502, lng: 21.1292754 },
    { lat: 49.1314471, lng: 21.1291479 },
    { lat: 49.1313741, lng: 21.1285507 },
    { lat: 49.131175, lng: 21.1282369 },
    { lat: 49.1306031, lng: 21.1278033 },
    { lat: 49.1302597, lng: 21.1279342 },
    { lat: 49.1299918, lng: 21.1276158 },
    { lat: 49.1299204, lng: 21.1273174 },
    { lat: 49.1297636, lng: 21.1271644 },
    { lat: 49.1294251, lng: 21.1271682 },
    { lat: 49.1293343, lng: 21.1269654 },
    { lat: 49.1291049, lng: 21.126974 },
    { lat: 49.1288373, lng: 21.1270886 },
    { lat: 49.1285072, lng: 21.126934 },
    { lat: 49.1283034, lng: 21.1269666 },
    { lat: 49.1279632, lng: 21.1270974 },
    { lat: 49.1277849, lng: 21.1272585 },
    { lat: 49.1276244, lng: 21.1272308 },
    { lat: 49.127474, lng: 21.1274575 },
    { lat: 49.1273716, lng: 21.1274245 },
    { lat: 49.1272747, lng: 21.1270701 },
    { lat: 49.1270162, lng: 21.1268969 },
    { lat: 49.1267992, lng: 21.1271555 },
    { lat: 49.1261592, lng: 21.127443 },
    { lat: 49.1253071, lng: 21.1280039 },
    { lat: 49.1249204, lng: 21.1277021 },
    { lat: 49.1240838, lng: 21.1282162 },
    { lat: 49.1239321, lng: 21.128398 },
    { lat: 49.1234212, lng: 21.1284033 },
    { lat: 49.1231585, lng: 21.1283145 },
    { lat: 49.122957, lng: 21.1283854 },
    { lat: 49.1229044, lng: 21.128464 },
    { lat: 49.1227044, lng: 21.1284346 },
    { lat: 49.1225804, lng: 21.1282878 },
    { lat: 49.1224169, lng: 21.1283135 },
    { lat: 49.1221359, lng: 21.1288875 },
    { lat: 49.1216897, lng: 21.128874 },
    { lat: 49.1213662, lng: 21.1283604 },
    { lat: 49.1211061, lng: 21.1283896 },
    { lat: 49.1210308, lng: 21.1282184 },
    { lat: 49.1208267, lng: 21.1280933 },
    { lat: 49.1205899, lng: 21.1282264 },
    { lat: 49.1204439, lng: 21.1280177 },
    { lat: 49.1201292, lng: 21.1278576 },
    { lat: 49.1199528, lng: 21.1279008 },
    { lat: 49.1194587, lng: 21.1276828 },
    { lat: 49.1193693, lng: 21.1277056 },
    { lat: 49.1193858, lng: 21.1276232 },
    { lat: 49.1193098, lng: 21.1276008 },
    { lat: 49.1191437, lng: 21.1276496 },
    { lat: 49.1191918, lng: 21.1273583 },
    { lat: 49.118385, lng: 21.1270808 },
    { lat: 49.1184302, lng: 21.1264477 },
    { lat: 49.1183342, lng: 21.1258677 },
    { lat: 49.1183333, lng: 21.1242134 },
    { lat: 49.1181746, lng: 21.1222636 },
    { lat: 49.1172041, lng: 21.1193455 },
    { lat: 49.1163223, lng: 21.1168916 },
    { lat: 49.1163064, lng: 21.1168504 },
    { lat: 49.1161393, lng: 21.1170096 },
    { lat: 49.1160248, lng: 21.1169833 },
    { lat: 49.1159758, lng: 21.1166064 },
    { lat: 49.1158709, lng: 21.1166361 },
    { lat: 49.1155846, lng: 21.1173977 },
    { lat: 49.1152994, lng: 21.1174931 },
    { lat: 49.1151623, lng: 21.1177777 },
    { lat: 49.1150376, lng: 21.1176102 },
    { lat: 49.1149221, lng: 21.1179733 },
    { lat: 49.1146903, lng: 21.1183284 },
    { lat: 49.114709, lng: 21.1187028 },
    { lat: 49.1145768, lng: 21.1187945 },
    { lat: 49.1144443, lng: 21.1186613 },
    { lat: 49.1143253, lng: 21.1183152 },
    { lat: 49.1142254, lng: 21.118214 },
    { lat: 49.1141171, lng: 21.1182062 },
    { lat: 49.1139619, lng: 21.118351 },
    { lat: 49.1137031, lng: 21.1184218 },
    { lat: 49.1136064, lng: 21.1186057 },
    { lat: 49.1134188, lng: 21.1187593 },
    { lat: 49.1133093, lng: 21.1185993 },
    { lat: 49.1131402, lng: 21.1187332 },
    { lat: 49.1131526, lng: 21.1189148 },
    { lat: 49.1130997, lng: 21.119127 },
    { lat: 49.1129689, lng: 21.119257 },
    { lat: 49.1127155, lng: 21.1191773 },
    { lat: 49.1126281, lng: 21.1192976 },
    { lat: 49.112595, lng: 21.1196751 },
    { lat: 49.1123727, lng: 21.1195805 },
    { lat: 49.1122153, lng: 21.1196589 },
    { lat: 49.112091, lng: 21.119782 },
    { lat: 49.11191, lng: 21.1202828 },
    { lat: 49.1114549, lng: 21.1202316 },
    { lat: 49.1112363, lng: 21.120297 },
    { lat: 49.1110456, lng: 21.1204918 },
    { lat: 49.1108432, lng: 21.1203957 },
    { lat: 49.1106231, lng: 21.1204748 },
    { lat: 49.110411, lng: 21.1207063 },
    { lat: 49.1100751, lng: 21.1203719 },
    { lat: 49.109996, lng: 21.1203972 },
    { lat: 49.1099113, lng: 21.1207055 },
    { lat: 49.1094464, lng: 21.1211633 },
    { lat: 49.1095543, lng: 21.1214032 },
    { lat: 49.1095389, lng: 21.1214525 },
    { lat: 49.1092002, lng: 21.1214139 },
    { lat: 49.1090119, lng: 21.1215445 },
    { lat: 49.1089125, lng: 21.1220655 },
    { lat: 49.108579, lng: 21.1219091 },
    { lat: 49.1084078, lng: 21.1222953 },
    { lat: 49.1082569, lng: 21.1223998 },
    { lat: 49.1080675, lng: 21.1220704 },
    { lat: 49.1078348, lng: 21.122006 },
    { lat: 49.10726, lng: 21.1215567 },
    { lat: 49.1069811, lng: 21.1214193 },
    { lat: 49.1065856, lng: 21.121507 },
    { lat: 49.1065115, lng: 21.1216224 },
    { lat: 49.1061943, lng: 21.1214798 },
    { lat: 49.1058303, lng: 21.1214981 },
    { lat: 49.1049782, lng: 21.1218138 },
    { lat: 49.1044255, lng: 21.1222296 },
    { lat: 49.1043555, lng: 21.1222627 },
  ],
  ŠarišskéDravce: [
    { lat: 49.1830701, lng: 20.8279097 },
    { lat: 49.1828853, lng: 20.8281605 },
    { lat: 49.1810308, lng: 20.8302142 },
    { lat: 49.180409, lng: 20.8306225 },
    { lat: 49.1797359, lng: 20.831967 },
    { lat: 49.1794025, lng: 20.8305509 },
    { lat: 49.1784115, lng: 20.8324751 },
    { lat: 49.1784465, lng: 20.8327051 },
    { lat: 49.1783998, lng: 20.8329534 },
    { lat: 49.1783423, lng: 20.8330159 },
    { lat: 49.1783508, lng: 20.8332638 },
    { lat: 49.1782345, lng: 20.8335188 },
    { lat: 49.1777474, lng: 20.8344732 },
    { lat: 49.1773444, lng: 20.8354007 },
    { lat: 49.1765328, lng: 20.8367795 },
    { lat: 49.1762068, lng: 20.8372177 },
    { lat: 49.1751998, lng: 20.8383094 },
    { lat: 49.1743056, lng: 20.8391853 },
    { lat: 49.1739208, lng: 20.8394839 },
    { lat: 49.1737484, lng: 20.8395109 },
    { lat: 49.1734184, lng: 20.8398364 },
    { lat: 49.1726702, lng: 20.8409316 },
    { lat: 49.1719723, lng: 20.8421446 },
    { lat: 49.1720643, lng: 20.8423046 },
    { lat: 49.1718933, lng: 20.8427315 },
    { lat: 49.1715993, lng: 20.8432868 },
    { lat: 49.1712225, lng: 20.8438578 },
    { lat: 49.1709921, lng: 20.844425 },
    { lat: 49.1687206, lng: 20.8512478 },
    { lat: 49.1696913, lng: 20.8519585 },
    { lat: 49.1697189, lng: 20.8519718 },
    { lat: 49.1676541, lng: 20.8571127 },
    { lat: 49.1650857, lng: 20.863235 },
    { lat: 49.1649437, lng: 20.8643162 },
    { lat: 49.1661321, lng: 20.8692518 },
    { lat: 49.1661775, lng: 20.869596 },
    { lat: 49.1664137, lng: 20.870419 },
    { lat: 49.1665593, lng: 20.8712988 },
    { lat: 49.166597, lng: 20.8723759 },
    { lat: 49.1673627, lng: 20.8721835 },
    { lat: 49.1678599, lng: 20.8724086 },
    { lat: 49.1679159, lng: 20.8750273 },
    { lat: 49.1694018, lng: 20.8766554 },
    { lat: 49.1697147, lng: 20.8768901 },
    { lat: 49.1706414, lng: 20.8767412 },
    { lat: 49.1706404, lng: 20.87671 },
    { lat: 49.1710113, lng: 20.8767577 },
    { lat: 49.1717686, lng: 20.8765552 },
    { lat: 49.1721401, lng: 20.8765195 },
    { lat: 49.1726789, lng: 20.8763148 },
    { lat: 49.1739292, lng: 20.8761091 },
    { lat: 49.1741082, lng: 20.8760446 },
    { lat: 49.1751464, lng: 20.8753621 },
    { lat: 49.1760228, lng: 20.8744854 },
    { lat: 49.1769158, lng: 20.8734365 },
    { lat: 49.1773494, lng: 20.8728821 },
    { lat: 49.1776445, lng: 20.872357 },
    { lat: 49.1787093, lng: 20.8708304 },
    { lat: 49.1794083, lng: 20.8699406 },
    { lat: 49.1809867, lng: 20.8684622 },
    { lat: 49.1819383, lng: 20.8674448 },
    { lat: 49.1823184, lng: 20.8671363 },
    { lat: 49.1827382, lng: 20.8666433 },
    { lat: 49.1839273, lng: 20.8654973 },
    { lat: 49.1844013, lng: 20.8651735 },
    { lat: 49.1857462, lng: 20.8640741 },
    { lat: 49.1863755, lng: 20.8636498 },
    { lat: 49.1871369, lng: 20.8632373 },
    { lat: 49.1877818, lng: 20.8630654 },
    { lat: 49.1888795, lng: 20.8630819 },
    { lat: 49.1895044, lng: 20.8629893 },
    { lat: 49.1898254, lng: 20.8628624 },
    { lat: 49.1906978, lng: 20.8628003 },
    { lat: 49.1916853, lng: 20.8628424 },
    { lat: 49.1928549, lng: 20.8627171 },
    { lat: 49.1933663, lng: 20.8626295 },
    { lat: 49.193806, lng: 20.8623896 },
    { lat: 49.1950359, lng: 20.8613898 },
    { lat: 49.195593, lng: 20.8605572 },
    { lat: 49.1965985, lng: 20.8583172 },
    { lat: 49.1967561, lng: 20.8580906 },
    { lat: 49.1973095, lng: 20.8575174 },
    { lat: 49.1981042, lng: 20.8569524 },
    { lat: 49.1987891, lng: 20.8567099 },
    { lat: 49.1991524, lng: 20.8563745 },
    { lat: 49.199579, lng: 20.8561355 },
    { lat: 49.2003517, lng: 20.855957 },
    { lat: 49.2016571, lng: 20.8558186 },
    { lat: 49.2020246, lng: 20.8556383 },
    { lat: 49.2022177, lng: 20.8556252 },
    { lat: 49.2023876, lng: 20.8557233 },
    { lat: 49.2037426, lng: 20.8554903 },
    { lat: 49.2042005, lng: 20.8554867 },
    { lat: 49.2049096, lng: 20.8550886 },
    { lat: 49.2055551, lng: 20.8548373 },
    { lat: 49.2059392, lng: 20.8547798 },
    { lat: 49.2064692, lng: 20.8540884 },
    { lat: 49.2068152, lng: 20.853471 },
    { lat: 49.2073362, lng: 20.8521428 },
    { lat: 49.2074861, lng: 20.8514891 },
    { lat: 49.2075335, lng: 20.8509606 },
    { lat: 49.207599, lng: 20.8507732 },
    { lat: 49.2077151, lng: 20.850643 },
    { lat: 49.2081655, lng: 20.8504871 },
    { lat: 49.2089746, lng: 20.8504844 },
    { lat: 49.2093774, lng: 20.8505478 },
    { lat: 49.2096889, lng: 20.850801 },
    { lat: 49.209852, lng: 20.8505703 },
    { lat: 49.2101677, lng: 20.8508768 },
    { lat: 49.2107773, lng: 20.8510635 },
    { lat: 49.2108948, lng: 20.8512104 },
    { lat: 49.2115553, lng: 20.8513383 },
    { lat: 49.211675, lng: 20.851286 },
    { lat: 49.2118289, lng: 20.8510131 },
    { lat: 49.2120439, lng: 20.8508164 },
    { lat: 49.2121597, lng: 20.8504494 },
    { lat: 49.2122903, lng: 20.8502475 },
    { lat: 49.2124516, lng: 20.8501616 },
    { lat: 49.2128661, lng: 20.8494472 },
    { lat: 49.2134953, lng: 20.8485876 },
    { lat: 49.2136665, lng: 20.8484279 },
    { lat: 49.2138527, lng: 20.8481056 },
    { lat: 49.214239, lng: 20.8469917 },
    { lat: 49.2142515, lng: 20.8467358 },
    { lat: 49.2143029, lng: 20.8466223 },
    { lat: 49.2142605, lng: 20.8460104 },
    { lat: 49.2143111, lng: 20.845772 },
    { lat: 49.2142032, lng: 20.8454835 },
    { lat: 49.2142594, lng: 20.8452166 },
    { lat: 49.2139154, lng: 20.8440736 },
    { lat: 49.2135156, lng: 20.8434087 },
    { lat: 49.2133469, lng: 20.8425495 },
    { lat: 49.2133692, lng: 20.8423796 },
    { lat: 49.2134808, lng: 20.8423545 },
    { lat: 49.2135188, lng: 20.8422147 },
    { lat: 49.2135407, lng: 20.8417316 },
    { lat: 49.2134731, lng: 20.841436 },
    { lat: 49.2135408, lng: 20.8412085 },
    { lat: 49.2135289, lng: 20.8409315 },
    { lat: 49.213356, lng: 20.8405082 },
    { lat: 49.2133227, lng: 20.8396998 },
    { lat: 49.2134314, lng: 20.8393246 },
    { lat: 49.2134542, lng: 20.8386046 },
    { lat: 49.2136334, lng: 20.8383207 },
    { lat: 49.2136957, lng: 20.8380659 },
    { lat: 49.2138123, lng: 20.8378557 },
    { lat: 49.2138312, lng: 20.8374549 },
    { lat: 49.2139015, lng: 20.8371728 },
    { lat: 49.2138273, lng: 20.8369761 },
    { lat: 49.2138796, lng: 20.8366994 },
    { lat: 49.213812, lng: 20.8365269 },
    { lat: 49.2139022, lng: 20.8362421 },
    { lat: 49.2139005, lng: 20.8360893 },
    { lat: 49.2140867, lng: 20.8357108 },
    { lat: 49.2140842, lng: 20.8356405 },
    { lat: 49.2140149, lng: 20.8355837 },
    { lat: 49.2140678, lng: 20.8348641 },
    { lat: 49.2141363, lng: 20.8345894 },
    { lat: 49.2142752, lng: 20.8343079 },
    { lat: 49.2142393, lng: 20.8341466 },
    { lat: 49.2143702, lng: 20.8338298 },
    { lat: 49.2143885, lng: 20.8336764 },
    { lat: 49.2146488, lng: 20.8331203 },
    { lat: 49.2146019, lng: 20.832345 },
    { lat: 49.2147132, lng: 20.8324038 },
    { lat: 49.2147692, lng: 20.8321034 },
    { lat: 49.2149772, lng: 20.831802 },
    { lat: 49.2150536, lng: 20.8317938 },
    { lat: 49.21516, lng: 20.8316496 },
    { lat: 49.2152594, lng: 20.8316953 },
    { lat: 49.2153991, lng: 20.8315995 },
    { lat: 49.2155131, lng: 20.8313467 },
    { lat: 49.2158993, lng: 20.8315434 },
    { lat: 49.2160252, lng: 20.8313764 },
    { lat: 49.2162294, lng: 20.8314581 },
    { lat: 49.2165111, lng: 20.8312678 },
    { lat: 49.2164837, lng: 20.8310947 },
    { lat: 49.2163528, lng: 20.8309157 },
    { lat: 49.2161285, lng: 20.8307784 },
    { lat: 49.2157424, lng: 20.8306818 },
    { lat: 49.2156143, lng: 20.830714 },
    { lat: 49.2155032, lng: 20.8309194 },
    { lat: 49.2152865, lng: 20.8307758 },
    { lat: 49.2150797, lng: 20.8308124 },
    { lat: 49.21475, lng: 20.8310126 },
    { lat: 49.2145783, lng: 20.830896 },
    { lat: 49.2145111, lng: 20.8312333 },
    { lat: 49.2143532, lng: 20.8310324 },
    { lat: 49.2141556, lng: 20.8309142 },
    { lat: 49.2140237, lng: 20.8309402 },
    { lat: 49.2139194, lng: 20.8311113 },
    { lat: 49.2138071, lng: 20.8311515 },
    { lat: 49.2136109, lng: 20.8311114 },
    { lat: 49.2135184, lng: 20.8309463 },
    { lat: 49.2132495, lng: 20.8309789 },
    { lat: 49.2129262, lng: 20.8307387 },
    { lat: 49.2128136, lng: 20.8305442 },
    { lat: 49.2127458, lng: 20.8305331 },
    { lat: 49.2121949, lng: 20.8295978 },
    { lat: 49.2119344, lng: 20.8289883 },
    { lat: 49.2117361, lng: 20.8282326 },
    { lat: 49.2114863, lng: 20.8280664 },
    { lat: 49.2107499, lng: 20.8277951 },
    { lat: 49.2102349, lng: 20.8273171 },
    { lat: 49.2077019, lng: 20.8338429 },
    { lat: 49.2073536, lng: 20.8343897 },
    { lat: 49.2070485, lng: 20.8346811 },
    { lat: 49.2065283, lng: 20.8348698 },
    { lat: 49.2062613, lng: 20.8348292 },
    { lat: 49.2061841, lng: 20.8354397 },
    { lat: 49.2061427, lng: 20.8363328 },
    { lat: 49.2059977, lng: 20.8372921 },
    { lat: 49.2056349, lng: 20.8382706 },
    { lat: 49.2052103, lng: 20.8388206 },
    { lat: 49.2047478, lng: 20.839614 },
    { lat: 49.2042006, lng: 20.8397745 },
    { lat: 49.2035631, lng: 20.8403198 },
    { lat: 49.2033549, lng: 20.840417 },
    { lat: 49.2031089, lng: 20.8403005 },
    { lat: 49.2029915, lng: 20.8405233 },
    { lat: 49.2025743, lng: 20.8408621 },
    { lat: 49.2025444, lng: 20.8409728 },
    { lat: 49.202417, lng: 20.8410696 },
    { lat: 49.2022915, lng: 20.8412816 },
    { lat: 49.2021263, lng: 20.8417223 },
    { lat: 49.2020385, lng: 20.8417188 },
    { lat: 49.201915, lng: 20.8419209 },
    { lat: 49.2018099, lng: 20.8419807 },
    { lat: 49.2018215, lng: 20.8420841 },
    { lat: 49.201753, lng: 20.842226 },
    { lat: 49.2017564, lng: 20.8425205 },
    { lat: 49.2016588, lng: 20.8425974 },
    { lat: 49.2002522, lng: 20.8423421 },
    { lat: 49.1996212, lng: 20.8420216 },
    { lat: 49.1987822, lng: 20.8414523 },
    { lat: 49.1988229, lng: 20.8412555 },
    { lat: 49.1975057, lng: 20.8408834 },
    { lat: 49.1971765, lng: 20.8407076 },
    { lat: 49.1971681, lng: 20.8409851 },
    { lat: 49.1962733, lng: 20.8409861 },
    { lat: 49.1951153, lng: 20.8406632 },
    { lat: 49.1944425, lng: 20.8403065 },
    { lat: 49.1941214, lng: 20.8404965 },
    { lat: 49.1934149, lng: 20.841163 },
    { lat: 49.1932503, lng: 20.8412531 },
    { lat: 49.1923221, lng: 20.8409664 },
    { lat: 49.1915406, lng: 20.840624 },
    { lat: 49.1906077, lng: 20.8401045 },
    { lat: 49.1902233, lng: 20.8395604 },
    { lat: 49.190227, lng: 20.8391099 },
    { lat: 49.1902997, lng: 20.8387666 },
    { lat: 49.1903135, lng: 20.8384463 },
    { lat: 49.19033, lng: 20.8364583 },
    { lat: 49.1902487, lng: 20.83635 },
    { lat: 49.1901394, lng: 20.8363486 },
    { lat: 49.1899553, lng: 20.8358066 },
    { lat: 49.1899159, lng: 20.835474 },
    { lat: 49.1899504, lng: 20.8352017 },
    { lat: 49.189553, lng: 20.8349671 },
    { lat: 49.1893341, lng: 20.8347517 },
    { lat: 49.1890213, lng: 20.8342286 },
    { lat: 49.1874887, lng: 20.8326005 },
    { lat: 49.1865221, lng: 20.8318838 },
    { lat: 49.186236, lng: 20.8318062 },
    { lat: 49.1857183, lng: 20.831117 },
    { lat: 49.184667, lng: 20.8300351 },
    { lat: 49.1838182, lng: 20.8289831 },
    { lat: 49.1836443, lng: 20.8286375 },
    { lat: 49.1830701, lng: 20.8279097 },
  ],
  NižnýSlavkov: [
    { lat: 49.1122231, lng: 20.8757262 },
    { lat: 49.1123789, lng: 20.8752785 },
    { lat: 49.1140921, lng: 20.8685532 },
    { lat: 49.1151531, lng: 20.8615871 },
    { lat: 49.1153334, lng: 20.8608693 },
    { lat: 49.1155891, lng: 20.8605451 },
    { lat: 49.1158344, lng: 20.8595815 },
    { lat: 49.1159342, lng: 20.858413 },
    { lat: 49.1161438, lng: 20.8574978 },
    { lat: 49.1164409, lng: 20.857004 },
    { lat: 49.1165738, lng: 20.8544265 },
    { lat: 49.1164646, lng: 20.8542311 },
    { lat: 49.1165993, lng: 20.8533272 },
    { lat: 49.1167149, lng: 20.8498244 },
    { lat: 49.1168473, lng: 20.8481973 },
    { lat: 49.1165899, lng: 20.8479443 },
    { lat: 49.1165781, lng: 20.847661 },
    { lat: 49.1169607, lng: 20.8471316 },
    { lat: 49.1169361, lng: 20.8465417 },
    { lat: 49.1167182, lng: 20.8459284 },
    { lat: 49.1166874, lng: 20.8454289 },
    { lat: 49.1173742, lng: 20.8432619 },
    { lat: 49.117493, lng: 20.8423549 },
    { lat: 49.1177169, lng: 20.8405786 },
    { lat: 49.1176309, lng: 20.8375366 },
    { lat: 49.117876, lng: 20.8360841 },
    { lat: 49.1142368, lng: 20.833845 },
    { lat: 49.1134966, lng: 20.8250336 },
    { lat: 49.1107376, lng: 20.8224436 },
    { lat: 49.1090966, lng: 20.8189419 },
    { lat: 49.1074264, lng: 20.8162357 },
    { lat: 49.1064362, lng: 20.8127766 },
    { lat: 49.1059871, lng: 20.8122891 },
    { lat: 49.1056951, lng: 20.8118177 },
    { lat: 49.1051502, lng: 20.8117471 },
    { lat: 49.1031648, lng: 20.8075466 },
    { lat: 49.1018682, lng: 20.8072531 },
    { lat: 49.0994359, lng: 20.8077754 },
    { lat: 49.0993908, lng: 20.8072902 },
    { lat: 49.0996337, lng: 20.8065318 },
    { lat: 49.09897, lng: 20.805443 },
    { lat: 49.096822, lng: 20.807293 },
    { lat: 49.095029, lng: 20.808812 },
    { lat: 49.09419, lng: 20.809413 },
    { lat: 49.093966, lng: 20.809558 },
    { lat: 49.093964, lng: 20.809553 },
    { lat: 49.093662, lng: 20.809907 },
    { lat: 49.093418, lng: 20.810357 },
    { lat: 49.093307, lng: 20.810465 },
    { lat: 49.093092, lng: 20.811336 },
    { lat: 49.091026, lng: 20.813175 },
    { lat: 49.088961, lng: 20.815859 },
    { lat: 49.088199, lng: 20.817822 },
    { lat: 49.085022, lng: 20.822352 },
    { lat: 49.083869, lng: 20.824831 },
    { lat: 49.083476, lng: 20.825438 },
    { lat: 49.08288, lng: 20.824444 },
    { lat: 49.082144, lng: 20.824252 },
    { lat: 49.08116, lng: 20.825048 },
    { lat: 49.080868, lng: 20.825283 },
    { lat: 49.078255, lng: 20.828308 },
    { lat: 49.077834, lng: 20.828558 },
    { lat: 49.077836, lng: 20.828671 },
    { lat: 49.077436, lng: 20.829181 },
    { lat: 49.07741, lng: 20.829233 },
    { lat: 49.077384, lng: 20.829296 },
    { lat: 49.077356, lng: 20.829366 },
    { lat: 49.07732, lng: 20.829453 },
    { lat: 49.077456, lng: 20.82957 },
    { lat: 49.077372, lng: 20.829761 },
    { lat: 49.077619, lng: 20.830156 },
    { lat: 49.077572, lng: 20.83042 },
    { lat: 49.077552, lng: 20.830529 },
    { lat: 49.077613, lng: 20.830592 },
    { lat: 49.077867, lng: 20.830861 },
    { lat: 49.077909, lng: 20.830906 },
    { lat: 49.078144, lng: 20.831062 },
    { lat: 49.078277, lng: 20.83115 },
    { lat: 49.078364, lng: 20.831208 },
    { lat: 49.078401, lng: 20.831232 },
    { lat: 49.078448, lng: 20.831285 },
    { lat: 49.078766, lng: 20.831643 },
    { lat: 49.078703, lng: 20.831973 },
    { lat: 49.078687, lng: 20.832055 },
    { lat: 49.078783, lng: 20.832184 },
    { lat: 49.078972, lng: 20.832439 },
    { lat: 49.078997, lng: 20.832659 },
    { lat: 49.079036, lng: 20.833016 },
    { lat: 49.079085, lng: 20.833245 },
    { lat: 49.079108, lng: 20.833353 },
    { lat: 49.079196, lng: 20.833774 },
    { lat: 49.07923, lng: 20.833935 },
    { lat: 49.079318, lng: 20.834353 },
    { lat: 49.079567, lng: 20.834758 },
    { lat: 49.07962, lng: 20.835062 },
    { lat: 49.079548, lng: 20.835298 },
    { lat: 49.07952, lng: 20.835374 },
    { lat: 49.079457, lng: 20.835555 },
    { lat: 49.079432, lng: 20.835623 },
    { lat: 49.079574, lng: 20.835797 },
    { lat: 49.079813, lng: 20.836089 },
    { lat: 49.0798114, lng: 20.8362904 },
    { lat: 49.07981, lng: 20.836473 },
    { lat: 49.079857, lng: 20.83708 },
    { lat: 49.079932, lng: 20.837479 },
    { lat: 49.080005, lng: 20.837868 },
    { lat: 49.080016, lng: 20.838081 },
    { lat: 49.080037, lng: 20.838538 },
    { lat: 49.080179, lng: 20.839341 },
    { lat: 49.080181, lng: 20.839361 },
    { lat: 49.080241, lng: 20.839919 },
    { lat: 49.080257, lng: 20.840065 },
    { lat: 49.080284, lng: 20.840309 },
    { lat: 49.0803, lng: 20.840478 },
    { lat: 49.080337, lng: 20.840861 },
    { lat: 49.080393, lng: 20.841442 },
    { lat: 49.080456, lng: 20.841665 },
    { lat: 49.080491, lng: 20.841789 },
    { lat: 49.080512, lng: 20.842276 },
    { lat: 49.080738, lng: 20.842956 },
    { lat: 49.080759, lng: 20.84302 },
    { lat: 49.08115, lng: 20.843681 },
    { lat: 49.081263, lng: 20.843844 },
    { lat: 49.081666, lng: 20.844433 },
    { lat: 49.081891, lng: 20.844759 },
    { lat: 49.081984, lng: 20.844839 },
    { lat: 49.082121, lng: 20.844956 },
    { lat: 49.082325, lng: 20.84513 },
    { lat: 49.082341, lng: 20.845525 },
    { lat: 49.08235, lng: 20.84572 },
    { lat: 49.082463, lng: 20.845762 },
    { lat: 49.082651, lng: 20.845834 },
    { lat: 49.082825, lng: 20.845936 },
    { lat: 49.082925, lng: 20.846039 },
    { lat: 49.082975, lng: 20.846092 },
    { lat: 49.08337, lng: 20.846652 },
    { lat: 49.083353, lng: 20.846688 },
    { lat: 49.08342, lng: 20.847029 },
    { lat: 49.083396, lng: 20.847286 },
    { lat: 49.08339, lng: 20.847613 },
    { lat: 49.083362, lng: 20.848094 },
    { lat: 49.083235, lng: 20.849126 },
    { lat: 49.083197, lng: 20.849307 },
    { lat: 49.083014, lng: 20.850198 },
    { lat: 49.082957, lng: 20.850759 },
    { lat: 49.08318, lng: 20.854344 },
    { lat: 49.083313, lng: 20.856572 },
    { lat: 49.083341, lng: 20.856974 },
    { lat: 49.084491, lng: 20.859444 },
    { lat: 49.08478, lng: 20.860727 },
    { lat: 49.085105, lng: 20.861499 },
    { lat: 49.085496, lng: 20.862165 },
    { lat: 49.085788, lng: 20.862822 },
    { lat: 49.086654, lng: 20.863952 },
    { lat: 49.087983, lng: 20.864872 },
    { lat: 49.08765, lng: 20.865639 },
    { lat: 49.0874688, lng: 20.8657115 },
    { lat: 49.087195, lng: 20.865821 },
    { lat: 49.087168, lng: 20.866275 },
    { lat: 49.086995, lng: 20.86733 },
    { lat: 49.086718, lng: 20.868811 },
    { lat: 49.086008, lng: 20.869878 },
    { lat: 49.08586, lng: 20.870217 },
    { lat: 49.086056, lng: 20.87098 },
    { lat: 49.086126, lng: 20.874249 },
    { lat: 49.086045, lng: 20.874593 },
    { lat: 49.086054, lng: 20.875862 },
    { lat: 49.086477, lng: 20.87881 },
    { lat: 49.08615, lng: 20.879654 },
    { lat: 49.085863, lng: 20.880232 },
    { lat: 49.0860465, lng: 20.8803182 },
    { lat: 49.085101, lng: 20.882032 },
    { lat: 49.084446, lng: 20.884498 },
    { lat: 49.08403, lng: 20.885727 },
    { lat: 49.083398, lng: 20.887585 },
    { lat: 49.083361, lng: 20.88778 },
    { lat: 49.08321, lng: 20.8889 },
    { lat: 49.083176, lng: 20.889016 },
    { lat: 49.082637, lng: 20.8909 },
    { lat: 49.079834, lng: 20.893894 },
    { lat: 49.078794, lng: 20.896147 },
    { lat: 49.076433, lng: 20.898531 },
    { lat: 49.075865, lng: 20.900299 },
    { lat: 49.075558, lng: 20.902754 },
    { lat: 49.075501, lng: 20.903168 },
    { lat: 49.07726, lng: 20.907955 },
    { lat: 49.077997, lng: 20.908506 },
    { lat: 49.078058, lng: 20.908702 },
    { lat: 49.07838, lng: 20.909752 },
    { lat: 49.078398, lng: 20.909815 },
    { lat: 49.079257, lng: 20.912969 },
    { lat: 49.080038, lng: 20.913252 },
    { lat: 49.082865, lng: 20.914686 },
    { lat: 49.085585, lng: 20.914823 },
    { lat: 49.086471, lng: 20.91659 },
    { lat: 49.08672, lng: 20.917387 },
    { lat: 49.0874, lng: 20.919565 },
    { lat: 49.088633, lng: 20.920309 },
    { lat: 49.090482, lng: 20.919932 },
    { lat: 49.092986, lng: 20.920168 },
    { lat: 49.093732, lng: 20.920124 },
    { lat: 49.0947729, lng: 20.9176084 },
    { lat: 49.0951503, lng: 20.9166808 },
    { lat: 49.0957843, lng: 20.914849 },
    { lat: 49.0974868, lng: 20.9118374 },
    { lat: 49.0975521, lng: 20.9117219 },
    { lat: 49.0984251, lng: 20.9098404 },
    { lat: 49.0990483, lng: 20.9092261 },
    { lat: 49.1002094, lng: 20.9074728 },
    { lat: 49.1016082, lng: 20.9050292 },
    { lat: 49.1018926, lng: 20.9027785 },
    { lat: 49.1018142, lng: 20.9020576 },
    { lat: 49.1018787, lng: 20.9015627 },
    { lat: 49.1023749, lng: 20.8994204 },
    { lat: 49.1013815, lng: 20.8958645 },
    { lat: 49.1014482, lng: 20.893305 },
    { lat: 49.100832, lng: 20.8887322 },
    { lat: 49.1020989, lng: 20.8876551 },
    { lat: 49.1028296, lng: 20.8862242 },
    { lat: 49.1035507, lng: 20.8854186 },
    { lat: 49.1041615, lng: 20.8828827 },
    { lat: 49.1038164, lng: 20.8813009 },
    { lat: 49.1037074, lng: 20.8799426 },
    { lat: 49.1039155, lng: 20.8780458 },
    { lat: 49.1072116, lng: 20.8754442 },
    { lat: 49.1098239, lng: 20.8765715 },
    { lat: 49.1104224, lng: 20.8776517 },
    { lat: 49.1114295, lng: 20.8782443 },
    { lat: 49.1118292, lng: 20.8768574 },
    { lat: 49.1122231, lng: 20.8757262 },
  ],
  Kamenica: [
    { lat: 49.1814709, lng: 20.9351249 },
    { lat: 49.1815361, lng: 20.9382239 },
    { lat: 49.1809655, lng: 20.9425823 },
    { lat: 49.1815882, lng: 20.9436036 },
    { lat: 49.1801206, lng: 20.9441421 },
    { lat: 49.180271, lng: 20.9459278 },
    { lat: 49.1803133, lng: 20.9471628 },
    { lat: 49.1809038, lng: 20.9489482 },
    { lat: 49.1808697, lng: 20.9495768 },
    { lat: 49.1813872, lng: 20.9504531 },
    { lat: 49.1815316, lng: 20.9518525 },
    { lat: 49.1815859, lng: 20.9519616 },
    { lat: 49.1814251, lng: 20.9520672 },
    { lat: 49.1811554, lng: 20.9525194 },
    { lat: 49.1816938, lng: 20.9530797 },
    { lat: 49.1815098, lng: 20.9533328 },
    { lat: 49.1809745, lng: 20.9534112 },
    { lat: 49.1809417, lng: 20.9538821 },
    { lat: 49.1820204, lng: 20.9537606 },
    { lat: 49.182021, lng: 20.9540196 },
    { lat: 49.1825171, lng: 20.9540457 },
    { lat: 49.1821923, lng: 20.9561246 },
    { lat: 49.1811746, lng: 20.9575732 },
    { lat: 49.1815621, lng: 20.9591168 },
    { lat: 49.1816302, lng: 20.9612341 },
    { lat: 49.1814758, lng: 20.9624832 },
    { lat: 49.1812654, lng: 20.9628951 },
    { lat: 49.1802171, lng: 20.9644743 },
    { lat: 49.1791076, lng: 20.9659454 },
    { lat: 49.1780059, lng: 20.9676444 },
    { lat: 49.1773203, lng: 20.9687975 },
    { lat: 49.1770465, lng: 20.9693704 },
    { lat: 49.1771502, lng: 20.9698801 },
    { lat: 49.1770082, lng: 20.9705216 },
    { lat: 49.1771466, lng: 20.9719545 },
    { lat: 49.1767591, lng: 20.9736828 },
    { lat: 49.1762282, lng: 20.9738593 },
    { lat: 49.1766179, lng: 20.9757408 },
    { lat: 49.1770003, lng: 20.9771284 },
    { lat: 49.1770226, lng: 20.9789339 },
    { lat: 49.1768719, lng: 20.9794497 },
    { lat: 49.176975, lng: 20.9800156 },
    { lat: 49.176995, lng: 20.9805223 },
    { lat: 49.177098, lng: 20.9812198 },
    { lat: 49.1771321, lng: 20.9812963 },
    { lat: 49.1779193, lng: 20.9796442 },
    { lat: 49.1782379, lng: 20.9792715 },
    { lat: 49.1787811, lng: 20.9788467 },
    { lat: 49.1790548, lng: 20.9785156 },
    { lat: 49.1792015, lng: 20.9781856 },
    { lat: 49.1796461, lng: 20.9782224 },
    { lat: 49.1797881, lng: 20.9777514 },
    { lat: 49.179931, lng: 20.9777523 },
    { lat: 49.1801506, lng: 20.9778887 },
    { lat: 49.1805353, lng: 20.977812 },
    { lat: 49.1807154, lng: 20.9776449 },
    { lat: 49.1813918, lng: 20.9773048 },
    { lat: 49.1816611, lng: 20.9775162 },
    { lat: 49.181867, lng: 20.9775881 },
    { lat: 49.1821933, lng: 20.9774156 },
    { lat: 49.1823906, lng: 20.9772205 },
    { lat: 49.1827895, lng: 20.9771658 },
    { lat: 49.183094, lng: 20.9773583 },
    { lat: 49.1835266, lng: 20.977469 },
    { lat: 49.1840878, lng: 20.9778399 },
    { lat: 49.1843365, lng: 20.9778709 },
    { lat: 49.1845838, lng: 20.978062 },
    { lat: 49.1851092, lng: 20.9780961 },
    { lat: 49.1854886, lng: 20.9784383 },
    { lat: 49.1857928, lng: 20.9785509 },
    { lat: 49.1859053, lng: 20.9787534 },
    { lat: 49.1864889, lng: 20.9794214 },
    { lat: 49.187157, lng: 20.980502 },
    { lat: 49.1875224, lng: 20.9812421 },
    { lat: 49.1875181, lng: 20.9813817 },
    { lat: 49.1880897, lng: 20.982442 },
    { lat: 49.1884985, lng: 20.9828604 },
    { lat: 49.1887889, lng: 20.9830331 },
    { lat: 49.1892993, lng: 20.9835938 },
    { lat: 49.1893753, lng: 20.9838891 },
    { lat: 49.1896009, lng: 20.9841431 },
    { lat: 49.1897713, lng: 20.9845003 },
    { lat: 49.1906333, lng: 20.9851441 },
    { lat: 49.1909917, lng: 20.9853286 },
    { lat: 49.1916599, lng: 20.9854245 },
    { lat: 49.1919967, lng: 20.9857427 },
    { lat: 49.1921159, lng: 20.986147 },
    { lat: 49.1925524, lng: 20.9863179 },
    { lat: 49.1933304, lng: 20.9861518 },
    { lat: 49.1934348, lng: 20.9861794 },
    { lat: 49.1934893, lng: 20.9863303 },
    { lat: 49.1938594, lng: 20.9861245 },
    { lat: 49.1941307, lng: 20.9858483 },
    { lat: 49.194508, lng: 20.9857553 },
    { lat: 49.1952807, lng: 20.985265 },
    { lat: 49.1955886, lng: 20.9855566 },
    { lat: 49.1959619, lng: 20.9854819 },
    { lat: 49.1962871, lng: 20.9855113 },
    { lat: 49.196741, lng: 20.985388 },
    { lat: 49.1969733, lng: 20.985575 },
    { lat: 49.1975589, lng: 20.9853113 },
    { lat: 49.1978252, lng: 20.985569 },
    { lat: 49.1982488, lng: 20.9855142 },
    { lat: 49.198733, lng: 20.9857643 },
    { lat: 49.1991053, lng: 20.9856908 },
    { lat: 49.1992585, lng: 20.9860051 },
    { lat: 49.1996999, lng: 20.9861087 },
    { lat: 49.2004665, lng: 20.9865304 },
    { lat: 49.2004735, lng: 20.9865345 },
    { lat: 49.2041027, lng: 20.9890251 },
    { lat: 49.2045236, lng: 20.9895738 },
    { lat: 49.2048886, lng: 20.9902315 },
    { lat: 49.2052379, lng: 20.9905916 },
    { lat: 49.205623, lng: 20.991454 },
    { lat: 49.2057773, lng: 20.9916646 },
    { lat: 49.2061395, lng: 20.9919641 },
    { lat: 49.2062655, lng: 20.9922406 },
    { lat: 49.2063628, lng: 20.9928036 },
    { lat: 49.2063125, lng: 20.9931541 },
    { lat: 49.2063882, lng: 20.9935409 },
    { lat: 49.2066799, lng: 20.994056 },
    { lat: 49.2068239, lng: 20.9940896 },
    { lat: 49.2068951, lng: 20.9942037 },
    { lat: 49.2069335, lng: 20.9944476 },
    { lat: 49.2068789, lng: 20.9946728 },
    { lat: 49.206995, lng: 20.9948475 },
    { lat: 49.2070889, lng: 20.9951725 },
    { lat: 49.2071286, lng: 20.995592 },
    { lat: 49.2072418, lng: 20.9957158 },
    { lat: 49.2074403, lng: 20.9961437 },
    { lat: 49.2073945, lng: 20.9968034 },
    { lat: 49.2072944, lng: 20.9969428 },
    { lat: 49.2072529, lng: 20.9975967 },
    { lat: 49.2074176, lng: 20.9980894 },
    { lat: 49.2074156, lng: 20.9982674 },
    { lat: 49.2072416, lng: 20.9985251 },
    { lat: 49.2073214, lng: 20.9990034 },
    { lat: 49.2072985, lng: 20.9990894 },
    { lat: 49.2073569, lng: 20.9996688 },
    { lat: 49.2072729, lng: 20.9997907 },
    { lat: 49.2072376, lng: 21.0000796 },
    { lat: 49.2073019, lng: 21.0000556 },
    { lat: 49.2074165, lng: 21.0003923 },
    { lat: 49.2074207, lng: 21.0005871 },
    { lat: 49.2076034, lng: 21.0009439 },
    { lat: 49.2076459, lng: 21.0013783 },
    { lat: 49.2076184, lng: 21.001438 },
    { lat: 49.207679, lng: 21.001703 },
    { lat: 49.2076539, lng: 21.0020688 },
    { lat: 49.207788, lng: 21.0029015 },
    { lat: 49.2079662, lng: 21.0034958 },
    { lat: 49.2080948, lng: 21.0041594 },
    { lat: 49.2080126, lng: 21.004503 },
    { lat: 49.2080834, lng: 21.0053596 },
    { lat: 49.2080709, lng: 21.0056625 },
    { lat: 49.2076709, lng: 21.0071972 },
    { lat: 49.2075843, lng: 21.0080244 },
    { lat: 49.2075865, lng: 21.0084011 },
    { lat: 49.2076394, lng: 21.0087772 },
    { lat: 49.2076287, lng: 21.0090335 },
    { lat: 49.2077013, lng: 21.0091554 },
    { lat: 49.2077408, lng: 21.0093986 },
    { lat: 49.2084447, lng: 21.0114804 },
    { lat: 49.2085217, lng: 21.0124298 },
    { lat: 49.208649, lng: 21.0128114 },
    { lat: 49.2086406, lng: 21.0130661 },
    { lat: 49.2087738, lng: 21.0135999 },
    { lat: 49.2087598, lng: 21.0151703 },
    { lat: 49.208703, lng: 21.0157361 },
    { lat: 49.2087306, lng: 21.0159971 },
    { lat: 49.2086665, lng: 21.0162726 },
    { lat: 49.2086786, lng: 21.0185796 },
    { lat: 49.2087917, lng: 21.0189007 },
    { lat: 49.2091091, lng: 21.0190087 },
    { lat: 49.2095341, lng: 21.0193424 },
    { lat: 49.2094522, lng: 21.0201808 },
    { lat: 49.2101678, lng: 21.0223185 },
    { lat: 49.2104282, lng: 21.0227102 },
    { lat: 49.2107305, lng: 21.0229348 },
    { lat: 49.2109838, lng: 21.0234923 },
    { lat: 49.2113374, lng: 21.0239521 },
    { lat: 49.2120763, lng: 21.0256641 },
    { lat: 49.2122071, lng: 21.0257755 },
    { lat: 49.2123611, lng: 21.0259074 },
    { lat: 49.2125168, lng: 21.0263661 },
    { lat: 49.212793, lng: 21.0269401 },
    { lat: 49.2131549, lng: 21.0273716 },
    { lat: 49.2133205, lng: 21.0273724 },
    { lat: 49.2137863, lng: 21.0278889 },
    { lat: 49.2140804, lng: 21.0283183 },
    { lat: 49.214246, lng: 21.028296 },
    { lat: 49.214264, lng: 21.028292 },
    { lat: 49.214342, lng: 21.027669 },
    { lat: 49.214459, lng: 21.027017 },
    { lat: 49.214607, lng: 21.026683 },
    { lat: 49.214618, lng: 21.026479 },
    { lat: 49.214572, lng: 21.026248 },
    { lat: 49.214537, lng: 21.025889 },
    { lat: 49.214613, lng: 21.025404 },
    { lat: 49.214481, lng: 21.023566 },
    { lat: 49.214511, lng: 21.022842 },
    { lat: 49.214469, lng: 21.021996 },
    { lat: 49.214468, lng: 21.021292 },
    { lat: 49.214517, lng: 21.020887 },
    { lat: 49.214643, lng: 21.020542 },
    { lat: 49.21475, lng: 21.020352 },
    { lat: 49.215205, lng: 21.019862 },
    { lat: 49.215299, lng: 21.019552 },
    { lat: 49.215634, lng: 21.018649 },
    { lat: 49.215999, lng: 21.018235 },
    { lat: 49.216445, lng: 21.017976 },
    { lat: 49.216923, lng: 21.017395 },
    { lat: 49.21723, lng: 21.017147 },
    { lat: 49.217842, lng: 21.01651 },
    { lat: 49.218061, lng: 21.016349 },
    { lat: 49.218172, lng: 21.016205 },
    { lat: 49.218197, lng: 21.016172 },
    { lat: 49.218296, lng: 21.016099 },
    { lat: 49.218408, lng: 21.015814 },
    { lat: 49.218415, lng: 21.015798 },
    { lat: 49.218424, lng: 21.015431 },
    { lat: 49.21838, lng: 21.014853 },
    { lat: 49.21844, lng: 21.014539 },
    { lat: 49.218567, lng: 21.013876 },
    { lat: 49.218548, lng: 21.013468 },
    { lat: 49.218613, lng: 21.013106 },
    { lat: 49.218616, lng: 21.013088 },
    { lat: 49.218785, lng: 21.012781 },
    { lat: 49.218923, lng: 21.01253 },
    { lat: 49.218928, lng: 21.012525 },
    { lat: 49.219083, lng: 21.012321 },
    { lat: 49.219212, lng: 21.012152 },
    { lat: 49.219384, lng: 21.011925 },
    { lat: 49.219386, lng: 21.011923 },
    { lat: 49.219547, lng: 21.011764 },
    { lat: 49.219665, lng: 21.011645 },
    { lat: 49.219682, lng: 21.011628 },
    { lat: 49.219801, lng: 21.011411 },
    { lat: 49.219813, lng: 21.011387 },
    { lat: 49.220027, lng: 21.011275 },
    { lat: 49.220077, lng: 21.01125 },
    { lat: 49.220282, lng: 21.011046 },
    { lat: 49.220489, lng: 21.01084 },
    { lat: 49.220505, lng: 21.010823 },
    { lat: 49.220684, lng: 21.010583 },
    { lat: 49.220701, lng: 21.01056 },
    { lat: 49.220888, lng: 21.010318 },
    { lat: 49.220904, lng: 21.010297 },
    { lat: 49.221004, lng: 21.010217 },
    { lat: 49.221081, lng: 21.010156 },
    { lat: 49.221124, lng: 21.010112 },
    { lat: 49.221428, lng: 21.009814 },
    { lat: 49.221439, lng: 21.009804 },
    { lat: 49.221549, lng: 21.009438 },
    { lat: 49.221568, lng: 21.009378 },
    { lat: 49.221585, lng: 21.009019 },
    { lat: 49.221745, lng: 21.008672 },
    { lat: 49.221762, lng: 21.008635 },
    { lat: 49.221775, lng: 21.008605 },
    { lat: 49.22199, lng: 21.00799 },
    { lat: 49.221991, lng: 21.007978 },
    { lat: 49.222014, lng: 21.007617 },
    { lat: 49.222012, lng: 21.007249 },
    { lat: 49.222013, lng: 21.006905 },
    { lat: 49.221983, lng: 21.006726 },
    { lat: 49.222081, lng: 21.006446 },
    { lat: 49.222233, lng: 21.005895 },
    { lat: 49.222349, lng: 21.005592 },
    { lat: 49.222408, lng: 21.00544 },
    { lat: 49.222423, lng: 21.005398 },
    { lat: 49.222781, lng: 21.005387 },
    { lat: 49.2231, lng: 21.00498 },
    { lat: 49.223399, lng: 21.004719 },
    { lat: 49.223656, lng: 21.004602 },
    { lat: 49.223847, lng: 21.004511 },
    { lat: 49.224178, lng: 21.004453 },
    { lat: 49.224507, lng: 21.00451 },
    { lat: 49.224706, lng: 21.004494 },
    { lat: 49.225018, lng: 21.004379 },
    { lat: 49.22541, lng: 21.004275 },
    { lat: 49.225715, lng: 21.003983 },
    { lat: 49.226084, lng: 21.003713 },
    { lat: 49.226285, lng: 21.003557 },
    { lat: 49.226447, lng: 21.003429 },
    { lat: 49.226583, lng: 21.003247 },
    { lat: 49.226874, lng: 21.003058 },
    { lat: 49.227221, lng: 21.002759 },
    { lat: 49.22738, lng: 21.002591 },
    { lat: 49.227572, lng: 21.002483 },
    { lat: 49.227835, lng: 21.00227 },
    { lat: 49.228234, lng: 21.002083 },
    { lat: 49.228331, lng: 21.001845 },
    { lat: 49.228502, lng: 21.001574 },
    { lat: 49.228763, lng: 21.001309 },
    { lat: 49.229234, lng: 21.001002 },
    { lat: 49.229337, lng: 21.000833 },
    { lat: 49.229325, lng: 21.00081 },
    { lat: 49.229344, lng: 21.000268 },
    { lat: 49.229688, lng: 20.999096 },
    { lat: 49.229967, lng: 20.997514 },
    { lat: 49.230153, lng: 20.996577 },
    { lat: 49.230207, lng: 20.99624 },
    { lat: 49.230324, lng: 20.99521 },
    { lat: 49.230453, lng: 20.994443 },
    { lat: 49.228276, lng: 20.992129 },
    { lat: 49.228102, lng: 20.988902 },
    { lat: 49.22757, lng: 20.985454 },
    { lat: 49.227323, lng: 20.984735 },
    { lat: 49.227221, lng: 20.984444 },
    { lat: 49.227114, lng: 20.983995 },
    { lat: 49.226935, lng: 20.983466 },
    { lat: 49.22686, lng: 20.983013 },
    { lat: 49.226657, lng: 20.982225 },
    { lat: 49.226596, lng: 20.981621 },
    { lat: 49.226583, lng: 20.981187 },
    { lat: 49.226222, lng: 20.980919 },
    { lat: 49.225662, lng: 20.980133 },
    { lat: 49.225259, lng: 20.979606 },
    { lat: 49.224917, lng: 20.97925 },
    { lat: 49.224491, lng: 20.978995 },
    { lat: 49.224243, lng: 20.978875 },
    { lat: 49.223672, lng: 20.978688 },
    { lat: 49.223072, lng: 20.978454 },
    { lat: 49.222799, lng: 20.978424 },
    { lat: 49.2224472, lng: 20.9782703 },
    { lat: 49.2222966, lng: 20.9782259 },
    { lat: 49.222192, lng: 20.978113 },
    { lat: 49.222061, lng: 20.978103 },
    { lat: 49.221883, lng: 20.977884 },
    { lat: 49.221728, lng: 20.977958 },
    { lat: 49.221475, lng: 20.977806 },
    { lat: 49.221168, lng: 20.977427 },
    { lat: 49.220887, lng: 20.977247 },
    { lat: 49.22056, lng: 20.976854 },
    { lat: 49.220305, lng: 20.976394 },
    { lat: 49.220132, lng: 20.975762 },
    { lat: 49.220038, lng: 20.975521 },
    { lat: 49.219948, lng: 20.975149 },
    { lat: 49.21972, lng: 20.974099 },
    { lat: 49.219667, lng: 20.973875 },
    { lat: 49.21964, lng: 20.973893 },
    { lat: 49.2185669, lng: 20.9721376 },
    { lat: 49.21845, lng: 20.970923 },
    { lat: 49.21863, lng: 20.970392 },
    { lat: 49.218638, lng: 20.969773 },
    { lat: 49.218969, lng: 20.967168 },
    { lat: 49.218899, lng: 20.965416 },
    { lat: 49.218765, lng: 20.964724 },
    { lat: 49.21823, lng: 20.962521 },
    { lat: 49.217834, lng: 20.961722 },
    { lat: 49.217666, lng: 20.961333 },
    { lat: 49.217253, lng: 20.960683 },
    { lat: 49.216751, lng: 20.959996 },
    { lat: 49.215982, lng: 20.959271 },
    { lat: 49.215419, lng: 20.958666 },
    { lat: 49.214839, lng: 20.95772 },
    { lat: 49.214617, lng: 20.957113 },
    { lat: 49.214352, lng: 20.956344 },
    { lat: 49.214121, lng: 20.955674 },
    { lat: 49.213912, lng: 20.953934 },
    { lat: 49.213772, lng: 20.953368 },
    { lat: 49.212773, lng: 20.951171 },
    { lat: 49.211908, lng: 20.950058 },
    { lat: 49.210617, lng: 20.949606 },
    { lat: 49.209487, lng: 20.949504 },
    { lat: 49.208713, lng: 20.94966 },
    { lat: 49.208933, lng: 20.948991 },
    { lat: 49.209999, lng: 20.945744 },
    { lat: 49.210665, lng: 20.943617 },
    { lat: 49.210668, lng: 20.943614 },
    { lat: 49.210864, lng: 20.943226 },
    { lat: 49.210856, lng: 20.943145 },
    { lat: 49.210709, lng: 20.941681 },
    { lat: 49.210728, lng: 20.941633 },
    { lat: 49.210971, lng: 20.941026 },
    { lat: 49.211298, lng: 20.940203 },
    { lat: 49.21165, lng: 20.939319 },
    { lat: 49.211806, lng: 20.938928 },
    { lat: 49.21212, lng: 20.938144 },
    { lat: 49.212249, lng: 20.937819 },
    { lat: 49.212267, lng: 20.937653 },
    { lat: 49.212381, lng: 20.936543 },
    { lat: 49.212228, lng: 20.936656 },
    { lat: 49.21173, lng: 20.93695 },
    { lat: 49.211435, lng: 20.936817 },
    { lat: 49.21119, lng: 20.936602 },
    { lat: 49.210724, lng: 20.935889 },
    { lat: 49.210872, lng: 20.935345 },
    { lat: 49.210587, lng: 20.934935 },
    { lat: 49.211436, lng: 20.932057 },
    { lat: 49.211014, lng: 20.931275 },
    { lat: 49.210319, lng: 20.930512 },
    { lat: 49.2111, lng: 20.9297 },
    { lat: 49.211889, lng: 20.928789 },
    { lat: 49.21259, lng: 20.927981 },
    { lat: 49.21389, lng: 20.926448 },
    { lat: 49.213054, lng: 20.925124 },
    { lat: 49.212815, lng: 20.924745 },
    { lat: 49.212913, lng: 20.924057 },
    { lat: 49.212523, lng: 20.923655 },
    { lat: 49.212233, lng: 20.923405 },
    { lat: 49.212057, lng: 20.923252 },
    { lat: 49.211431, lng: 20.922749 },
    { lat: 49.211319, lng: 20.92265 },
    { lat: 49.210687, lng: 20.921862 },
    { lat: 49.210662, lng: 20.921832 },
    { lat: 49.210289, lng: 20.921155 },
    { lat: 49.210001, lng: 20.9209 },
    { lat: 49.20698, lng: 20.918229 },
    { lat: 49.206151, lng: 20.916616 },
    { lat: 49.205736, lng: 20.915553 },
    { lat: 49.2055681, lng: 20.9156358 },
    { lat: 49.2060799, lng: 20.9169395 },
    { lat: 49.2053171, lng: 20.9182256 },
    { lat: 49.2045854, lng: 20.9199334 },
    { lat: 49.2039035, lng: 20.9207744 },
    { lat: 49.2028765, lng: 20.9213972 },
    { lat: 49.2020659, lng: 20.9220668 },
    { lat: 49.2009962, lng: 20.9242034 },
    { lat: 49.200418, lng: 20.9243133 },
    { lat: 49.199729, lng: 20.9234262 },
    { lat: 49.199196, lng: 20.9255644 },
    { lat: 49.1981841, lng: 20.9265058 },
    { lat: 49.1976887, lng: 20.9258581 },
    { lat: 49.1969494, lng: 20.9270028 },
    { lat: 49.1966152, lng: 20.9282146 },
    { lat: 49.1962192, lng: 20.9290612 },
    { lat: 49.1955923, lng: 20.9298722 },
    { lat: 49.1944112, lng: 20.9305653 },
    { lat: 49.1923808, lng: 20.931947 },
    { lat: 49.1921709, lng: 20.930683 },
    { lat: 49.1915105, lng: 20.9303479 },
    { lat: 49.1903388, lng: 20.9335831 },
    { lat: 49.1899359, lng: 20.9351689 },
    { lat: 49.1892079, lng: 20.9372364 },
    { lat: 49.1888236, lng: 20.9379589 },
    { lat: 49.1883744, lng: 20.9386637 },
    { lat: 49.1877323, lng: 20.9387491 },
    { lat: 49.1869466, lng: 20.9396588 },
    { lat: 49.1863551, lng: 20.940004 },
    { lat: 49.1851987, lng: 20.9399595 },
    { lat: 49.1849009, lng: 20.9393609 },
    { lat: 49.1844715, lng: 20.9364404 },
    { lat: 49.1838275, lng: 20.9348523 },
    { lat: 49.183925, lng: 20.9337255 },
    { lat: 49.1826504, lng: 20.9346534 },
    { lat: 49.1814709, lng: 20.9351249 },
  ],
  KrásnaLúka: [
    { lat: 49.2238673, lng: 20.8323485 },
    { lat: 49.2238834, lng: 20.8322878 },
    { lat: 49.2234369, lng: 20.8311841 },
    { lat: 49.2184278, lng: 20.8232864 },
    { lat: 49.2153984, lng: 20.8207742 },
    { lat: 49.2149362, lng: 20.8200282 },
    { lat: 49.2135662, lng: 20.8193713 },
    { lat: 49.2110266, lng: 20.8174587 },
    { lat: 49.2095237, lng: 20.8157146 },
    { lat: 49.2086521, lng: 20.8142206 },
    { lat: 49.2053955, lng: 20.8073092 },
    { lat: 49.2041789, lng: 20.8040826 },
    { lat: 49.203236, lng: 20.8025544 },
    { lat: 49.2023203, lng: 20.8005457 },
    { lat: 49.1996603, lng: 20.7969543 },
    { lat: 49.1993639, lng: 20.7963904 },
    { lat: 49.1995208, lng: 20.7958433 },
    { lat: 49.199201, lng: 20.7946381 },
    { lat: 49.1989733, lng: 20.7943692 },
    { lat: 49.1989284, lng: 20.7933633 },
    { lat: 49.1986824, lng: 20.7924613 },
    { lat: 49.1985216, lng: 20.7916177 },
    { lat: 49.1980552, lng: 20.7908911 },
    { lat: 49.1976755, lng: 20.7897131 },
    { lat: 49.1972846, lng: 20.7893768 },
    { lat: 49.1969725, lng: 20.7890082 },
    { lat: 49.1967116, lng: 20.7889861 },
    { lat: 49.1964618, lng: 20.7888547 },
    { lat: 49.1962287, lng: 20.7880709 },
    { lat: 49.1958182, lng: 20.7870387 },
    { lat: 49.1953198, lng: 20.7867535 },
    { lat: 49.1949956, lng: 20.7867548 },
    { lat: 49.1941616, lng: 20.7864639 },
    { lat: 49.1939484, lng: 20.786511 },
    { lat: 49.1936884, lng: 20.786189 },
    { lat: 49.1936385, lng: 20.7863078 },
    { lat: 49.1933407, lng: 20.7860321 },
    { lat: 49.1931702, lng: 20.7856582 },
    { lat: 49.1929174, lng: 20.7853781 },
    { lat: 49.1929118, lng: 20.7852188 },
    { lat: 49.1926349, lng: 20.7850878 },
    { lat: 49.1916631, lng: 20.7839369 },
    { lat: 49.1912374, lng: 20.7837319 },
    { lat: 49.1910095, lng: 20.7834948 },
    { lat: 49.1907367, lng: 20.7834149 },
    { lat: 49.190347, lng: 20.7831481 },
    { lat: 49.1896876, lng: 20.7830213 },
    { lat: 49.1891176, lng: 20.7827496 },
    { lat: 49.1887853, lng: 20.7827454 },
    { lat: 49.1884968, lng: 20.7826345 },
    { lat: 49.1880662, lng: 20.7826516 },
    { lat: 49.1878479, lng: 20.7825865 },
    { lat: 49.1869479, lng: 20.7826972 },
    { lat: 49.1862096, lng: 20.7831505 },
    { lat: 49.1857265, lng: 20.7845114 },
    { lat: 49.185557, lng: 20.7843628 },
    { lat: 49.1851358, lng: 20.7837383 },
    { lat: 49.184785, lng: 20.7833622 },
    { lat: 49.1838032, lng: 20.7828355 },
    { lat: 49.1836607, lng: 20.7827455 },
    { lat: 49.1834699, lng: 20.784678 },
    { lat: 49.1830882, lng: 20.7856486 },
    { lat: 49.1828656, lng: 20.7870179 },
    { lat: 49.1826697, lng: 20.7871507 },
    { lat: 49.1824064, lng: 20.7871848 },
    { lat: 49.1821932, lng: 20.7882138 },
    { lat: 49.1822471, lng: 20.7890186 },
    { lat: 49.1826625, lng: 20.7899674 },
    { lat: 49.1827497, lng: 20.790404 },
    { lat: 49.1830618, lng: 20.7913856 },
    { lat: 49.1829667, lng: 20.7924482 },
    { lat: 49.1829545, lng: 20.7932958 },
    { lat: 49.1827226, lng: 20.7939499 },
    { lat: 49.1827475, lng: 20.7950917 },
    { lat: 49.1827101, lng: 20.7955042 },
    { lat: 49.182807, lng: 20.7957072 },
    { lat: 49.1828156, lng: 20.7958271 },
    { lat: 49.1827246, lng: 20.7968746 },
    { lat: 49.1828799, lng: 20.7974896 },
    { lat: 49.1828649, lng: 20.7976775 },
    { lat: 49.1826336, lng: 20.7992587 },
    { lat: 49.1825038, lng: 20.7996725 },
    { lat: 49.1825142, lng: 20.7998375 },
    { lat: 49.1824633, lng: 20.7998999 },
    { lat: 49.1824306, lng: 20.8005163 },
    { lat: 49.1822568, lng: 20.8009606 },
    { lat: 49.1822347, lng: 20.8011959 },
    { lat: 49.1820044, lng: 20.8016983 },
    { lat: 49.1818229, lng: 20.8019561 },
    { lat: 49.1817519, lng: 20.8022435 },
    { lat: 49.1814528, lng: 20.8026788 },
    { lat: 49.1810457, lng: 20.803014 },
    { lat: 49.1806497, lng: 20.803964 },
    { lat: 49.1805989, lng: 20.8042915 },
    { lat: 49.1804121, lng: 20.8046444 },
    { lat: 49.1803732, lng: 20.8049882 },
    { lat: 49.1802664, lng: 20.8052572 },
    { lat: 49.1803216, lng: 20.8054219 },
    { lat: 49.1803242, lng: 20.805757 },
    { lat: 49.1804176, lng: 20.8060784 },
    { lat: 49.1802879, lng: 20.8062359 },
    { lat: 49.1802159, lng: 20.8064997 },
    { lat: 49.1803048, lng: 20.8067264 },
    { lat: 49.1803991, lng: 20.8074366 },
    { lat: 49.1802977, lng: 20.8083201 },
    { lat: 49.180434, lng: 20.8084829 },
    { lat: 49.180465, lng: 20.8086305 },
    { lat: 49.1803614, lng: 20.8089233 },
    { lat: 49.1804128, lng: 20.8091772 },
    { lat: 49.1803895, lng: 20.8093136 },
    { lat: 49.1801743, lng: 20.809573 },
    { lat: 49.1801584, lng: 20.8096635 },
    { lat: 49.1801964, lng: 20.8097558 },
    { lat: 49.1804355, lng: 20.8099309 },
    { lat: 49.1803422, lng: 20.8109814 },
    { lat: 49.1805102, lng: 20.8113471 },
    { lat: 49.1805528, lng: 20.8117359 },
    { lat: 49.1804997, lng: 20.8118643 },
    { lat: 49.1805253, lng: 20.8122177 },
    { lat: 49.180491, lng: 20.8123295 },
    { lat: 49.1803195, lng: 20.8123838 },
    { lat: 49.1802782, lng: 20.8124926 },
    { lat: 49.1802852, lng: 20.8126974 },
    { lat: 49.1801339, lng: 20.8128437 },
    { lat: 49.1800751, lng: 20.8130618 },
    { lat: 49.1803024, lng: 20.8138477 },
    { lat: 49.1801949, lng: 20.8140607 },
    { lat: 49.1801543, lng: 20.81406 },
    { lat: 49.1803393, lng: 20.8147212 },
    { lat: 49.1803792, lng: 20.8150655 },
    { lat: 49.1803332, lng: 20.8152379 },
    { lat: 49.1803545, lng: 20.8153625 },
    { lat: 49.1806964, lng: 20.8155849 },
    { lat: 49.1806886, lng: 20.816373 },
    { lat: 49.1803085, lng: 20.8173662 },
    { lat: 49.180342, lng: 20.8178129 },
    { lat: 49.1802587, lng: 20.8181754 },
    { lat: 49.1802304, lng: 20.8181665 },
    { lat: 49.1802157, lng: 20.8184332 },
    { lat: 49.1798196, lng: 20.8186624 },
    { lat: 49.1795822, lng: 20.8190239 },
    { lat: 49.179573, lng: 20.8193455 },
    { lat: 49.1797389, lng: 20.8195193 },
    { lat: 49.1797555, lng: 20.819681 },
    { lat: 49.1795809, lng: 20.8200346 },
    { lat: 49.1792593, lng: 20.8202736 },
    { lat: 49.1795575, lng: 20.8209607 },
    { lat: 49.1795219, lng: 20.8212035 },
    { lat: 49.1794351, lng: 20.8212997 },
    { lat: 49.1792297, lng: 20.8212161 },
    { lat: 49.1791888, lng: 20.8213301 },
    { lat: 49.1793812, lng: 20.821754 },
    { lat: 49.179387, lng: 20.8219248 },
    { lat: 49.1793225, lng: 20.8220779 },
    { lat: 49.1794179, lng: 20.8223019 },
    { lat: 49.1795815, lng: 20.8225133 },
    { lat: 49.1797291, lng: 20.8228746 },
    { lat: 49.1798914, lng: 20.8230819 },
    { lat: 49.1799359, lng: 20.8232309 },
    { lat: 49.1799208, lng: 20.8236433 },
    { lat: 49.1799921, lng: 20.823748 },
    { lat: 49.1800125, lng: 20.8239177 },
    { lat: 49.1799503, lng: 20.8241389 },
    { lat: 49.1800892, lng: 20.8242807 },
    { lat: 49.179963, lng: 20.8246045 },
    { lat: 49.1799664, lng: 20.8248067 },
    { lat: 49.1800335, lng: 20.8248939 },
    { lat: 49.1803086, lng: 20.8249374 },
    { lat: 49.1803972, lng: 20.8251278 },
    { lat: 49.1804604, lng: 20.8254915 },
    { lat: 49.1807027, lng: 20.8254315 },
    { lat: 49.1820079, lng: 20.826537 },
    { lat: 49.182316, lng: 20.8268912 },
    { lat: 49.182838, lng: 20.8276681 },
    { lat: 49.1830701, lng: 20.8279097 },
    { lat: 49.1836443, lng: 20.8286375 },
    { lat: 49.1838182, lng: 20.8289831 },
    { lat: 49.184667, lng: 20.8300351 },
    { lat: 49.1857183, lng: 20.831117 },
    { lat: 49.186236, lng: 20.8318062 },
    { lat: 49.1865221, lng: 20.8318838 },
    { lat: 49.1874887, lng: 20.8326005 },
    { lat: 49.1890213, lng: 20.8342286 },
    { lat: 49.1893341, lng: 20.8347517 },
    { lat: 49.189553, lng: 20.8349671 },
    { lat: 49.1899504, lng: 20.8352017 },
    { lat: 49.1899159, lng: 20.835474 },
    { lat: 49.1899553, lng: 20.8358066 },
    { lat: 49.1901394, lng: 20.8363486 },
    { lat: 49.1902487, lng: 20.83635 },
    { lat: 49.19033, lng: 20.8364583 },
    { lat: 49.1903135, lng: 20.8384463 },
    { lat: 49.1902997, lng: 20.8387666 },
    { lat: 49.190227, lng: 20.8391099 },
    { lat: 49.1902233, lng: 20.8395604 },
    { lat: 49.1906077, lng: 20.8401045 },
    { lat: 49.1915406, lng: 20.840624 },
    { lat: 49.1923221, lng: 20.8409664 },
    { lat: 49.1932503, lng: 20.8412531 },
    { lat: 49.1934149, lng: 20.841163 },
    { lat: 49.1941214, lng: 20.8404965 },
    { lat: 49.1944425, lng: 20.8403065 },
    { lat: 49.1951153, lng: 20.8406632 },
    { lat: 49.1962733, lng: 20.8409861 },
    { lat: 49.1971681, lng: 20.8409851 },
    { lat: 49.1971765, lng: 20.8407076 },
    { lat: 49.1975057, lng: 20.8408834 },
    { lat: 49.1988229, lng: 20.8412555 },
    { lat: 49.1987822, lng: 20.8414523 },
    { lat: 49.1996212, lng: 20.8420216 },
    { lat: 49.2002522, lng: 20.8423421 },
    { lat: 49.2016588, lng: 20.8425974 },
    { lat: 49.2017564, lng: 20.8425205 },
    { lat: 49.201753, lng: 20.842226 },
    { lat: 49.2018215, lng: 20.8420841 },
    { lat: 49.2018099, lng: 20.8419807 },
    { lat: 49.201915, lng: 20.8419209 },
    { lat: 49.2020385, lng: 20.8417188 },
    { lat: 49.2021263, lng: 20.8417223 },
    { lat: 49.2022915, lng: 20.8412816 },
    { lat: 49.202417, lng: 20.8410696 },
    { lat: 49.2025444, lng: 20.8409728 },
    { lat: 49.2025743, lng: 20.8408621 },
    { lat: 49.2029915, lng: 20.8405233 },
    { lat: 49.2031089, lng: 20.8403005 },
    { lat: 49.2033549, lng: 20.840417 },
    { lat: 49.2035631, lng: 20.8403198 },
    { lat: 49.2042006, lng: 20.8397745 },
    { lat: 49.2047478, lng: 20.839614 },
    { lat: 49.2052103, lng: 20.8388206 },
    { lat: 49.2056349, lng: 20.8382706 },
    { lat: 49.2059977, lng: 20.8372921 },
    { lat: 49.2061427, lng: 20.8363328 },
    { lat: 49.2061841, lng: 20.8354397 },
    { lat: 49.2062613, lng: 20.8348292 },
    { lat: 49.2065283, lng: 20.8348698 },
    { lat: 49.2070485, lng: 20.8346811 },
    { lat: 49.2073536, lng: 20.8343897 },
    { lat: 49.2077019, lng: 20.8338429 },
    { lat: 49.2102349, lng: 20.8273171 },
    { lat: 49.2107499, lng: 20.8277951 },
    { lat: 49.2114863, lng: 20.8280664 },
    { lat: 49.2117361, lng: 20.8282326 },
    { lat: 49.2119344, lng: 20.8289883 },
    { lat: 49.2121949, lng: 20.8295978 },
    { lat: 49.2127458, lng: 20.8305331 },
    { lat: 49.2128136, lng: 20.8305442 },
    { lat: 49.2129262, lng: 20.8307387 },
    { lat: 49.2132495, lng: 20.8309789 },
    { lat: 49.2135184, lng: 20.8309463 },
    { lat: 49.2136109, lng: 20.8311114 },
    { lat: 49.2138071, lng: 20.8311515 },
    { lat: 49.2139194, lng: 20.8311113 },
    { lat: 49.2140237, lng: 20.8309402 },
    { lat: 49.2141556, lng: 20.8309142 },
    { lat: 49.2143532, lng: 20.8310324 },
    { lat: 49.2145111, lng: 20.8312333 },
    { lat: 49.2145783, lng: 20.830896 },
    { lat: 49.21475, lng: 20.8310126 },
    { lat: 49.2150797, lng: 20.8308124 },
    { lat: 49.2152865, lng: 20.8307758 },
    { lat: 49.2155032, lng: 20.8309194 },
    { lat: 49.2156143, lng: 20.830714 },
    { lat: 49.2157424, lng: 20.8306818 },
    { lat: 49.2161285, lng: 20.8307784 },
    { lat: 49.2163528, lng: 20.8309157 },
    { lat: 49.2164837, lng: 20.8310947 },
    { lat: 49.2165111, lng: 20.8312678 },
    { lat: 49.2165096, lng: 20.8314235 },
    { lat: 49.2166773, lng: 20.8314861 },
    { lat: 49.2169214, lng: 20.831059 },
    { lat: 49.2170748, lng: 20.8310342 },
    { lat: 49.2172806, lng: 20.8311895 },
    { lat: 49.217498, lng: 20.8310357 },
    { lat: 49.2175971, lng: 20.8312986 },
    { lat: 49.2176887, lng: 20.8313078 },
    { lat: 49.2177441, lng: 20.8311234 },
    { lat: 49.2179336, lng: 20.8311324 },
    { lat: 49.2179654, lng: 20.8312073 },
    { lat: 49.217943, lng: 20.8314074 },
    { lat: 49.2181008, lng: 20.8315119 },
    { lat: 49.2183621, lng: 20.8310906 },
    { lat: 49.2185975, lng: 20.8311936 },
    { lat: 49.2187446, lng: 20.8311851 },
    { lat: 49.218851, lng: 20.8313998 },
    { lat: 49.2189836, lng: 20.8314904 },
    { lat: 49.2193405, lng: 20.8312996 },
    { lat: 49.2194648, lng: 20.8309195 },
    { lat: 49.2196521, lng: 20.8309002 },
    { lat: 49.2203333, lng: 20.8311015 },
    { lat: 49.2204882, lng: 20.8315209 },
    { lat: 49.2208253, lng: 20.8317025 },
    { lat: 49.2209853, lng: 20.8316368 },
    { lat: 49.2212033, lng: 20.8317883 },
    { lat: 49.2212733, lng: 20.8319314 },
    { lat: 49.2214078, lng: 20.8318804 },
    { lat: 49.2215572, lng: 20.8317096 },
    { lat: 49.2218588, lng: 20.8320655 },
    { lat: 49.2220827, lng: 20.8319666 },
    { lat: 49.2221446, lng: 20.8316736 },
    { lat: 49.2222648, lng: 20.8316273 },
    { lat: 49.2222955, lng: 20.8319061 },
    { lat: 49.2223915, lng: 20.8319463 },
    { lat: 49.2225344, lng: 20.831685 },
    { lat: 49.2226874, lng: 20.8316836 },
    { lat: 49.2227551, lng: 20.8320491 },
    { lat: 49.2230498, lng: 20.8320371 },
    { lat: 49.2235517, lng: 20.8322048 },
    { lat: 49.2238673, lng: 20.8323485 },
  ],
  Vysoká: [
    { lat: 49.1122231, lng: 20.8757262 },
    { lat: 49.1118292, lng: 20.8768574 },
    { lat: 49.1114295, lng: 20.8782443 },
    { lat: 49.1104224, lng: 20.8776517 },
    { lat: 49.1098239, lng: 20.8765715 },
    { lat: 49.1072116, lng: 20.8754442 },
    { lat: 49.1039155, lng: 20.8780458 },
    { lat: 49.1037074, lng: 20.8799426 },
    { lat: 49.1038164, lng: 20.8813009 },
    { lat: 49.1041615, lng: 20.8828827 },
    { lat: 49.1035507, lng: 20.8854186 },
    { lat: 49.1028296, lng: 20.8862242 },
    { lat: 49.1020989, lng: 20.8876551 },
    { lat: 49.100832, lng: 20.8887322 },
    { lat: 49.1014482, lng: 20.893305 },
    { lat: 49.1013815, lng: 20.8958645 },
    { lat: 49.1023749, lng: 20.8994204 },
    { lat: 49.1018787, lng: 20.9015627 },
    { lat: 49.1026258, lng: 20.9000857 },
    { lat: 49.1029552, lng: 20.899542 },
    { lat: 49.1030751, lng: 20.8994622 },
    { lat: 49.1047176, lng: 20.8992658 },
    { lat: 49.1057172, lng: 20.8993116 },
    { lat: 49.1071754, lng: 20.8986946 },
    { lat: 49.1075674, lng: 20.8993019 },
    { lat: 49.1084739, lng: 20.8995155 },
    { lat: 49.1087118, lng: 20.8994297 },
    { lat: 49.1103646, lng: 20.8994434 },
    { lat: 49.1109924, lng: 20.8997693 },
    { lat: 49.1112977, lng: 20.9000451 },
    { lat: 49.1131103, lng: 20.9006301 },
    { lat: 49.1139839, lng: 20.9021408 },
    { lat: 49.1152478, lng: 20.9031774 },
    { lat: 49.1177031, lng: 20.9055606 },
    { lat: 49.1183552, lng: 20.9056448 },
    { lat: 49.119156, lng: 20.906059 },
    { lat: 49.1199264, lng: 20.9066115 },
    { lat: 49.1200234, lng: 20.9067519 },
    { lat: 49.1203597, lng: 20.9070828 },
    { lat: 49.1205857, lng: 20.9065093 },
    { lat: 49.1212881, lng: 20.905338 },
    { lat: 49.1233664, lng: 20.9025807 },
    { lat: 49.1253426, lng: 20.9000684 },
    { lat: 49.1255739, lng: 20.899918 },
    { lat: 49.1295255, lng: 20.898918 },
    { lat: 49.12985, lng: 20.8987628 },
    { lat: 49.1306021, lng: 20.8982986 },
    { lat: 49.1321067, lng: 20.8970313 },
    { lat: 49.1332043, lng: 20.895834 },
    { lat: 49.1340772, lng: 20.8959878 },
    { lat: 49.1345113, lng: 20.8959088 },
    { lat: 49.1350634, lng: 20.8960622 },
    { lat: 49.135594, lng: 20.8963046 },
    { lat: 49.1359893, lng: 20.8963121 },
    { lat: 49.1364698, lng: 20.8961094 },
    { lat: 49.136559, lng: 20.8961546 },
    { lat: 49.1363534, lng: 20.8947652 },
    { lat: 49.1381998, lng: 20.8940436 },
    { lat: 49.1379776, lng: 20.8934607 },
    { lat: 49.1378423, lng: 20.8928218 },
    { lat: 49.1376479, lng: 20.8924977 },
    { lat: 49.1373033, lng: 20.8925269 },
    { lat: 49.1366471, lng: 20.8919583 },
    { lat: 49.1365586, lng: 20.8917061 },
    { lat: 49.1360435, lng: 20.8890866 },
    { lat: 49.1357964, lng: 20.8871006 },
    { lat: 49.1358489, lng: 20.8863222 },
    { lat: 49.1360328, lng: 20.885673 },
    { lat: 49.1362344, lng: 20.88448 },
    { lat: 49.1360439, lng: 20.8821377 },
    { lat: 49.1361598, lng: 20.8816415 },
    { lat: 49.1365198, lng: 20.8807383 },
    { lat: 49.1372008, lng: 20.878652 },
    { lat: 49.1370303, lng: 20.8786996 },
    { lat: 49.136429, lng: 20.8786549 },
    { lat: 49.1354281, lng: 20.878784 },
    { lat: 49.1343628, lng: 20.8791669 },
    { lat: 49.1345726, lng: 20.8784811 },
    { lat: 49.1346962, lng: 20.8782374 },
    { lat: 49.1347675, lng: 20.8780182 },
    { lat: 49.1345256, lng: 20.8779233 },
    { lat: 49.1342961, lng: 20.8778598 },
    { lat: 49.1331998, lng: 20.8779281 },
    { lat: 49.1329879, lng: 20.8772224 },
    { lat: 49.1326408, lng: 20.8775369 },
    { lat: 49.13158, lng: 20.8780538 },
    { lat: 49.1316016, lng: 20.8778911 },
    { lat: 49.1304478, lng: 20.8769917 },
    { lat: 49.1297601, lng: 20.8761589 },
    { lat: 49.1291998, lng: 20.8756046 },
    { lat: 49.1285724, lng: 20.8751954 },
    { lat: 49.1282869, lng: 20.8749322 },
    { lat: 49.1278559, lng: 20.8747695 },
    { lat: 49.1262981, lng: 20.8733807 },
    { lat: 49.1254144, lng: 20.8727082 },
    { lat: 49.1249537, lng: 20.8724976 },
    { lat: 49.124867, lng: 20.8722712 },
    { lat: 49.1243325, lng: 20.8728039 },
    { lat: 49.1242145, lng: 20.8727043 },
    { lat: 49.1240993, lng: 20.8727277 },
    { lat: 49.1238354, lng: 20.8729447 },
    { lat: 49.1237276, lng: 20.8729521 },
    { lat: 49.1233951, lng: 20.8726765 },
    { lat: 49.1230672, lng: 20.8726948 },
    { lat: 49.1227451, lng: 20.8725922 },
    { lat: 49.1223247, lng: 20.8725765 },
    { lat: 49.1217938, lng: 20.8727847 },
    { lat: 49.1209589, lng: 20.8727229 },
    { lat: 49.1207614, lng: 20.8728096 },
    { lat: 49.1204505, lng: 20.8730666 },
    { lat: 49.119993, lng: 20.8731846 },
    { lat: 49.11986, lng: 20.8733097 },
    { lat: 49.1196842, lng: 20.8733016 },
    { lat: 49.119239, lng: 20.8737851 },
    { lat: 49.1189561, lng: 20.8739324 },
    { lat: 49.1188269, lng: 20.873907 },
    { lat: 49.1186978, lng: 20.8740854 },
    { lat: 49.1180424, lng: 20.8744061 },
    { lat: 49.1179577, lng: 20.874531 },
    { lat: 49.1179126, lng: 20.87473 },
    { lat: 49.1180275, lng: 20.8752955 },
    { lat: 49.1178655, lng: 20.8753999 },
    { lat: 49.1176712, lng: 20.8753721 },
    { lat: 49.1175721, lng: 20.8754413 },
    { lat: 49.1172466, lng: 20.8760311 },
    { lat: 49.1171721, lng: 20.8759924 },
    { lat: 49.1169965, lng: 20.8761262 },
    { lat: 49.1152842, lng: 20.8754537 },
    { lat: 49.1151671, lng: 20.8756511 },
    { lat: 49.1148429, lng: 20.8757773 },
    { lat: 49.1141031, lng: 20.8757396 },
    { lat: 49.1137291, lng: 20.875824 },
    { lat: 49.1125938, lng: 20.8757028 },
    { lat: 49.1122231, lng: 20.8757262 },
  ],
  Hanigovce: [
    { lat: 49.1599179, lng: 21.0353355 },
    { lat: 49.1614154, lng: 21.0352503 },
    { lat: 49.1617702, lng: 21.0350563 },
    { lat: 49.1623016, lng: 21.0345098 },
    { lat: 49.1627174, lng: 21.0338667 },
    { lat: 49.1629691, lng: 21.033301 },
    { lat: 49.164407, lng: 21.0314201 },
    { lat: 49.1648736, lng: 21.0319591 },
    { lat: 49.1649385, lng: 21.0318449 },
    { lat: 49.1650042, lng: 21.0318294 },
    { lat: 49.1661983, lng: 21.0327252 },
    { lat: 49.1663053, lng: 21.0328663 },
    { lat: 49.1685889, lng: 21.0345004 },
    { lat: 49.1686291, lng: 21.034042 },
    { lat: 49.1687411, lng: 21.0337491 },
    { lat: 49.1687482, lng: 21.0333752 },
    { lat: 49.1689251, lng: 21.0331421 },
    { lat: 49.1690031, lng: 21.0332574 },
    { lat: 49.16935, lng: 21.0333362 },
    { lat: 49.1696569, lng: 21.0335277 },
    { lat: 49.1700039, lng: 21.0338585 },
    { lat: 49.1733091, lng: 21.0340585 },
    { lat: 49.1728226, lng: 21.0361267 },
    { lat: 49.1736503, lng: 21.0357627 },
    { lat: 49.173897, lng: 21.0358437 },
    { lat: 49.1749732, lng: 21.0354343 },
    { lat: 49.1751272, lng: 21.0355712 },
    { lat: 49.175789, lng: 21.0358128 },
    { lat: 49.176045, lng: 21.0358327 },
    { lat: 49.1762153, lng: 21.0360781 },
    { lat: 49.1767545, lng: 21.0356704 },
    { lat: 49.1771917, lng: 21.0357913 },
    { lat: 49.1774981, lng: 21.0357361 },
    { lat: 49.1779563, lng: 21.0357723 },
    { lat: 49.1785468, lng: 21.0360036 },
    { lat: 49.1793396, lng: 21.0358526 },
    { lat: 49.1810172, lng: 21.0357686 },
    { lat: 49.1815102, lng: 21.0359417 },
    { lat: 49.1826214, lng: 21.0365142 },
    { lat: 49.1837572, lng: 21.0367996 },
    { lat: 49.1849307, lng: 21.0375217 },
    { lat: 49.1880091, lng: 21.0359078 },
    { lat: 49.1889803, lng: 21.0372541 },
    { lat: 49.1894393, lng: 21.038774 },
    { lat: 49.1897217, lng: 21.0397101 },
    { lat: 49.1898206, lng: 21.0400171 },
    { lat: 49.1900395, lng: 21.0403449 },
    { lat: 49.1910275, lng: 21.040516 },
    { lat: 49.1917803, lng: 21.0403461 },
    { lat: 49.1919547, lng: 21.0405164 },
    { lat: 49.1918868, lng: 21.0411642 },
    { lat: 49.1916714, lng: 21.0415309 },
    { lat: 49.1917058, lng: 21.0440815 },
    { lat: 49.1917714, lng: 21.0448264 },
    { lat: 49.1920099, lng: 21.0461083 },
    { lat: 49.1921772, lng: 21.0467178 },
    { lat: 49.1922037, lng: 21.0476082 },
    { lat: 49.1923667, lng: 21.0481255 },
    { lat: 49.1924035, lng: 21.0483901 },
    { lat: 49.1926827, lng: 21.0491239 },
    { lat: 49.1929731, lng: 21.0495421 },
    { lat: 49.1933263, lng: 21.0493291 },
    { lat: 49.1936905, lng: 21.0493851 },
    { lat: 49.1945544, lng: 21.0490303 },
    { lat: 49.1947865, lng: 21.0490376 },
    { lat: 49.1949801, lng: 21.0488284 },
    { lat: 49.1950693, lng: 21.0486263 },
    { lat: 49.1952458, lng: 21.0485856 },
    { lat: 49.1954957, lng: 21.0487658 },
    { lat: 49.1956828, lng: 21.0490895 },
    { lat: 49.1957957, lng: 21.0489256 },
    { lat: 49.1957621, lng: 21.0487194 },
    { lat: 49.1961677, lng: 21.0483186 },
    { lat: 49.1963041, lng: 21.0483939 },
    { lat: 49.196689, lng: 21.0483217 },
    { lat: 49.1967758, lng: 21.0480899 },
    { lat: 49.1969998, lng: 21.0478409 },
    { lat: 49.1971861, lng: 21.0479643 },
    { lat: 49.1974517, lng: 21.0475157 },
    { lat: 49.197786, lng: 21.0474473 },
    { lat: 49.1978619, lng: 21.0471192 },
    { lat: 49.1979545, lng: 21.0470572 },
    { lat: 49.1979839, lng: 21.0465393 },
    { lat: 49.1981405, lng: 21.0462327 },
    { lat: 49.198131, lng: 21.0460427 },
    { lat: 49.1981879, lng: 21.0459272 },
    { lat: 49.1982765, lng: 21.0459188 },
    { lat: 49.1983238, lng: 21.045777 },
    { lat: 49.1982657, lng: 21.0453737 },
    { lat: 49.1983996, lng: 21.0451318 },
    { lat: 49.1984453, lng: 21.0448761 },
    { lat: 49.1985426, lng: 21.0447123 },
    { lat: 49.1985765, lng: 21.0445438 },
    { lat: 49.1987387, lng: 21.044375 },
    { lat: 49.1989999, lng: 21.0443157 },
    { lat: 49.1990381, lng: 21.0442412 },
    { lat: 49.1990944, lng: 21.0435129 },
    { lat: 49.1991791, lng: 21.0433108 },
    { lat: 49.1996396, lng: 21.0430738 },
    { lat: 49.1997604, lng: 21.0430549 },
    { lat: 49.2000398, lng: 21.0431717 },
    { lat: 49.200215, lng: 21.042981 },
    { lat: 49.2003221, lng: 21.0430251 },
    { lat: 49.2004278, lng: 21.0429549 },
    { lat: 49.2006961, lng: 21.0431782 },
    { lat: 49.2012392, lng: 21.0433661 },
    { lat: 49.2015578, lng: 21.042953 },
    { lat: 49.2019812, lng: 21.0431374 },
    { lat: 49.2022393, lng: 21.0429739 },
    { lat: 49.2023716, lng: 21.0430334 },
    { lat: 49.2025279, lng: 21.0432336 },
    { lat: 49.2026441, lng: 21.0431373 },
    { lat: 49.2029597, lng: 21.0430822 },
    { lat: 49.2032263, lng: 21.0429415 },
    { lat: 49.203328, lng: 21.0430968 },
    { lat: 49.2034736, lng: 21.0431064 },
    { lat: 49.2035517, lng: 21.0431933 },
    { lat: 49.2037237, lng: 21.0431166 },
    { lat: 49.2038368, lng: 21.0433121 },
    { lat: 49.2040587, lng: 21.0431061 },
    { lat: 49.2042572, lng: 21.0432934 },
    { lat: 49.2044864, lng: 21.0432426 },
    { lat: 49.2045757, lng: 21.0430868 },
    { lat: 49.2047228, lng: 21.0431445 },
    { lat: 49.2048091, lng: 21.0431002 },
    { lat: 49.2049097, lng: 21.0429367 },
    { lat: 49.2050083, lng: 21.0429242 },
    { lat: 49.2054706, lng: 21.0430581 },
    { lat: 49.2056711, lng: 21.0432697 },
    { lat: 49.2061155, lng: 21.0434424 },
    { lat: 49.2062771, lng: 21.0435684 },
    { lat: 49.2066003, lng: 21.0435415 },
    { lat: 49.2066724, lng: 21.0437516 },
    { lat: 49.2067577, lng: 21.0436757 },
    { lat: 49.206873, lng: 21.0437556 },
    { lat: 49.2070287, lng: 21.0436981 },
    { lat: 49.2071046, lng: 21.0435739 },
    { lat: 49.207445, lng: 21.043527 },
    { lat: 49.207693, lng: 21.0431905 },
    { lat: 49.207874, lng: 21.0432895 },
    { lat: 49.2079841, lng: 21.0431145 },
    { lat: 49.2082239, lng: 21.0430482 },
    { lat: 49.2083331, lng: 21.0428373 },
    { lat: 49.2086273, lng: 21.0427456 },
    { lat: 49.2086888, lng: 21.042525 },
    { lat: 49.208893, lng: 21.0425412 },
    { lat: 49.2090878, lng: 21.0424373 },
    { lat: 49.2093001, lng: 21.0424615 },
    { lat: 49.2098957, lng: 21.0421268 },
    { lat: 49.2101496, lng: 21.0421451 },
    { lat: 49.2107181, lng: 21.0417731 },
    { lat: 49.2108579, lng: 21.0417788 },
    { lat: 49.2109463, lng: 21.041523 },
    { lat: 49.2111153, lng: 21.0413904 },
    { lat: 49.211523, lng: 21.0414305 },
    { lat: 49.2117047, lng: 21.0409992 },
    { lat: 49.2117379, lng: 21.0408092 },
    { lat: 49.2116715, lng: 21.040739 },
    { lat: 49.2117225, lng: 21.0405382 },
    { lat: 49.2118389, lng: 21.0403833 },
    { lat: 49.2119152, lng: 21.0403703 },
    { lat: 49.2119942, lng: 21.0401739 },
    { lat: 49.2121795, lng: 21.0399544 },
    { lat: 49.2122669, lng: 21.03991 },
    { lat: 49.2122629, lng: 21.0396112 },
    { lat: 49.2124386, lng: 21.0394071 },
    { lat: 49.2125236, lng: 21.0391793 },
    { lat: 49.2128153, lng: 21.0391868 },
    { lat: 49.213073, lng: 21.0390535 },
    { lat: 49.213108, lng: 21.0389832 },
    { lat: 49.2134499, lng: 21.0390844 },
    { lat: 49.2138133, lng: 21.0389396 },
    { lat: 49.2139958, lng: 21.038947 },
    { lat: 49.2141872, lng: 21.0391536 },
    { lat: 49.2147811, lng: 21.0391134 },
    { lat: 49.215034, lng: 21.0389643 },
    { lat: 49.2160945, lng: 21.0390461 },
    { lat: 49.216139, lng: 21.0389999 },
    { lat: 49.2164198, lng: 21.0391198 },
    { lat: 49.2167332, lng: 21.0390986 },
    { lat: 49.2169912, lng: 21.0392512 },
    { lat: 49.2174561, lng: 21.0393553 },
    { lat: 49.2175975, lng: 21.0396236 },
    { lat: 49.218266, lng: 21.040289 },
    { lat: 49.218533, lng: 21.039737 },
    { lat: 49.218674, lng: 21.0394 },
    { lat: 49.218716, lng: 21.039247 },
    { lat: 49.218661, lng: 21.039212 },
    { lat: 49.218715, lng: 21.038967 },
    { lat: 49.218777, lng: 21.038981 },
    { lat: 49.2188, lng: 21.038884 },
    { lat: 49.218725, lng: 21.038833 },
    { lat: 49.218775, lng: 21.0386 },
    { lat: 49.218909, lng: 21.03839 },
    { lat: 49.218945, lng: 21.038039 },
    { lat: 49.219034, lng: 21.037371 },
    { lat: 49.218964, lng: 21.037354 },
    { lat: 49.218937, lng: 21.037206 },
    { lat: 49.218732, lng: 21.037036 },
    { lat: 49.218765, lng: 21.036534 },
    { lat: 49.218691, lng: 21.036389 },
    { lat: 49.218806, lng: 21.0357 },
    { lat: 49.218763, lng: 21.035703 },
    { lat: 49.218814, lng: 21.035295 },
    { lat: 49.218779, lng: 21.035127 },
    { lat: 49.218829, lng: 21.035034 },
    { lat: 49.218844, lng: 21.035006 },
    { lat: 49.218893, lng: 21.034994 },
    { lat: 49.218952, lng: 21.03478 },
    { lat: 49.21846, lng: 21.034539 },
    { lat: 49.218252, lng: 21.034422 },
    { lat: 49.217755, lng: 21.033891 },
    { lat: 49.21762, lng: 21.03357 },
    { lat: 49.217545, lng: 21.033246 },
    { lat: 49.21748, lng: 21.032094 },
    { lat: 49.217479, lng: 21.032057 },
    { lat: 49.21739, lng: 21.031914 },
    { lat: 49.217317, lng: 21.031898 },
    { lat: 49.217001, lng: 21.032156 },
    { lat: 49.216911, lng: 21.03222 },
    { lat: 49.21668, lng: 21.032172 },
    { lat: 49.216251, lng: 21.031851 },
    { lat: 49.215995, lng: 21.031444 },
    { lat: 49.215846, lng: 21.031155 },
    { lat: 49.215668, lng: 21.030807 },
    { lat: 49.215159, lng: 21.030099 },
    { lat: 49.215097, lng: 21.030019 },
    { lat: 49.214916, lng: 21.02978 },
    { lat: 49.214748, lng: 21.02956 },
    { lat: 49.214471, lng: 21.028977 },
    { lat: 49.214407, lng: 21.028783 },
    { lat: 49.214327, lng: 21.028544 },
    { lat: 49.214319, lng: 21.028518 },
    { lat: 49.214246, lng: 21.028296 },
    { lat: 49.2140804, lng: 21.0283183 },
    { lat: 49.2137863, lng: 21.0278889 },
    { lat: 49.2133205, lng: 21.0273724 },
    { lat: 49.2131549, lng: 21.0273716 },
    { lat: 49.212793, lng: 21.0269401 },
    { lat: 49.2125168, lng: 21.0263661 },
    { lat: 49.2123611, lng: 21.0259074 },
    { lat: 49.2122071, lng: 21.0257755 },
    { lat: 49.2116734, lng: 21.0259707 },
    { lat: 49.2116666, lng: 21.0260738 },
    { lat: 49.2113781, lng: 21.0260626 },
    { lat: 49.2109049, lng: 21.0262911 },
    { lat: 49.2107743, lng: 21.0266685 },
    { lat: 49.2105342, lng: 21.0265154 },
    { lat: 49.2104524, lng: 21.0263211 },
    { lat: 49.2104446, lng: 21.0261453 },
    { lat: 49.2102171, lng: 21.0260409 },
    { lat: 49.2101315, lng: 21.0257587 },
    { lat: 49.2101609, lng: 21.025385 },
    { lat: 49.2100774, lng: 21.0254137 },
    { lat: 49.2099178, lng: 21.0251051 },
    { lat: 49.2097433, lng: 21.0250024 },
    { lat: 49.2096823, lng: 21.0248883 },
    { lat: 49.2091338, lng: 21.0248745 },
    { lat: 49.2084402, lng: 21.0250783 },
    { lat: 49.2081175, lng: 21.0250815 },
    { lat: 49.2080155, lng: 21.0249899 },
    { lat: 49.2078404, lng: 21.0250072 },
    { lat: 49.2078703, lng: 21.0252313 },
    { lat: 49.2083548, lng: 21.0259756 },
    { lat: 49.2086281, lng: 21.0268709 },
    { lat: 49.2087259, lng: 21.0278702 },
    { lat: 49.2089779, lng: 21.0288679 },
    { lat: 49.2090111, lng: 21.0297857 },
    { lat: 49.2089662, lng: 21.029864 },
    { lat: 49.2090624, lng: 21.0302897 },
    { lat: 49.2090893, lng: 21.0307052 },
    { lat: 49.2093167, lng: 21.0309453 },
    { lat: 49.2094045, lng: 21.0311877 },
    { lat: 49.209404, lng: 21.0313469 },
    { lat: 49.2094879, lng: 21.031438 },
    { lat: 49.2094079, lng: 21.0316021 },
    { lat: 49.2095225, lng: 21.0318778 },
    { lat: 49.209385, lng: 21.0320077 },
    { lat: 49.208724, lng: 21.0319656 },
    { lat: 49.2080117, lng: 21.0317943 },
    { lat: 49.2074927, lng: 21.0318534 },
    { lat: 49.207332, lng: 21.0316553 },
    { lat: 49.207277, lng: 21.0316613 },
    { lat: 49.2072104, lng: 21.0314752 },
    { lat: 49.2072007, lng: 21.0310048 },
    { lat: 49.207158, lng: 21.0310113 },
    { lat: 49.2070105, lng: 21.030695 },
    { lat: 49.2068693, lng: 21.0306815 },
    { lat: 49.206641, lng: 21.0300589 },
    { lat: 49.2065578, lng: 21.0300317 },
    { lat: 49.2065335, lng: 21.0305961 },
    { lat: 49.2065982, lng: 21.0312362 },
    { lat: 49.2066927, lng: 21.0313356 },
    { lat: 49.2067456, lng: 21.0317519 },
    { lat: 49.206742, lng: 21.0320625 },
    { lat: 49.2066295, lng: 21.032098 },
    { lat: 49.2050115, lng: 21.0319304 },
    { lat: 49.2043087, lng: 21.0316396 },
    { lat: 49.2040821, lng: 21.0316229 },
    { lat: 49.2035991, lng: 21.0314126 },
    { lat: 49.2033922, lng: 21.0309982 },
    { lat: 49.2032558, lng: 21.0309211 },
    { lat: 49.2029705, lng: 21.0310449 },
    { lat: 49.2025471, lng: 21.0316233 },
    { lat: 49.2020222, lng: 21.0318869 },
    { lat: 49.2016444, lng: 21.0322106 },
    { lat: 49.201205, lng: 21.0323328 },
    { lat: 49.2001038, lng: 21.0323874 },
    { lat: 49.1997981, lng: 21.0321154 },
    { lat: 49.1994802, lng: 21.0321377 },
    { lat: 49.1988367, lng: 21.0318629 },
    { lat: 49.1985898, lng: 21.0316089 },
    { lat: 49.1981208, lng: 21.0316185 },
    { lat: 49.1979424, lng: 21.0317007 },
    { lat: 49.1976664, lng: 21.0316608 },
    { lat: 49.1971819, lng: 21.0317097 },
    { lat: 49.1968702, lng: 21.0318201 },
    { lat: 49.1966278, lng: 21.031805 },
    { lat: 49.1957882, lng: 21.0322183 },
    { lat: 49.1954166, lng: 21.032901 },
    { lat: 49.1950261, lng: 21.0334231 },
    { lat: 49.1945398, lng: 21.033728 },
    { lat: 49.1942059, lng: 21.0333586 },
    { lat: 49.1940335, lng: 21.0330028 },
    { lat: 49.193858, lng: 21.0329337 },
    { lat: 49.1934224, lng: 21.0330724 },
    { lat: 49.1931129, lng: 21.033039 },
    { lat: 49.1927878, lng: 21.0327664 },
    { lat: 49.1924692, lng: 21.0323102 },
    { lat: 49.1922881, lng: 21.0317946 },
    { lat: 49.1921549, lng: 21.0316072 },
    { lat: 49.1916856, lng: 21.0319183 },
    { lat: 49.1912312, lng: 21.0320276 },
    { lat: 49.1907827, lng: 21.0319072 },
    { lat: 49.1901146, lng: 21.031508 },
    { lat: 49.1898542, lng: 21.031251 },
    { lat: 49.1894131, lng: 21.0303511 },
    { lat: 49.1892199, lng: 21.0278771 },
    { lat: 49.1882911, lng: 21.0257888 },
    { lat: 49.1882251, lng: 21.0255553 },
    { lat: 49.1881952, lng: 21.0250211 },
    { lat: 49.188287, lng: 21.0243165 },
    { lat: 49.1881598, lng: 21.0236911 },
    { lat: 49.187794, lng: 21.0223805 },
    { lat: 49.187142, lng: 21.0219896 },
    { lat: 49.1867245, lng: 21.0219893 },
    { lat: 49.1864043, lng: 21.0218657 },
    { lat: 49.185973, lng: 21.0219607 },
    { lat: 49.1856458, lng: 21.0219007 },
    { lat: 49.1852336, lng: 21.021511 },
    { lat: 49.1850892, lng: 21.0212615 },
    { lat: 49.1850608, lng: 21.0204287 },
    { lat: 49.1842702, lng: 21.0197886 },
    { lat: 49.1839157, lng: 21.0198224 },
    { lat: 49.1830313, lng: 21.0201388 },
    { lat: 49.1825829, lng: 21.0201211 },
    { lat: 49.181727, lng: 21.0192062 },
    { lat: 49.1814453, lng: 21.018403 },
    { lat: 49.1804995, lng: 21.0163885 },
    { lat: 49.1808657, lng: 21.0149228 },
    { lat: 49.1809571, lng: 21.0147906 },
    { lat: 49.1811208, lng: 21.014221 },
    { lat: 49.181333, lng: 21.0142775 },
    { lat: 49.1816017, lng: 21.0135437 },
    { lat: 49.1810741, lng: 21.0128589 },
    { lat: 49.1814649, lng: 21.0119061 },
    { lat: 49.1818093, lng: 21.0123195 },
    { lat: 49.1823114, lng: 21.0104671 },
    { lat: 49.1825736, lng: 21.0091254 },
    { lat: 49.1826837, lng: 21.0091541 },
    { lat: 49.1825297, lng: 21.0087795 },
    { lat: 49.1799849, lng: 21.0073977 },
    { lat: 49.1797082, lng: 21.0078112 },
    { lat: 49.1797035, lng: 21.0081148 },
    { lat: 49.1795052, lng: 21.0083548 },
    { lat: 49.179542, lng: 21.0085487 },
    { lat: 49.1792379, lng: 21.0088482 },
    { lat: 49.1792464, lng: 21.0089691 },
    { lat: 49.1791162, lng: 21.0090837 },
    { lat: 49.1791074, lng: 21.0091631 },
    { lat: 49.1768439, lng: 21.0084251 },
    { lat: 49.1769394, lng: 21.0081808 },
    { lat: 49.1759495, lng: 21.0079234 },
    { lat: 49.1744917, lng: 21.0067371 },
    { lat: 49.1744286, lng: 21.0067419 },
    { lat: 49.1741518, lng: 21.0074928 },
    { lat: 49.1739272, lng: 21.0077207 },
    { lat: 49.1737817, lng: 21.0080879 },
    { lat: 49.1694467, lng: 21.0082964 },
    { lat: 49.1694488, lng: 21.0077705 },
    { lat: 49.1692614, lng: 21.0077204 },
    { lat: 49.1688868, lng: 21.0079636 },
    { lat: 49.1686858, lng: 21.0082566 },
    { lat: 49.1686782, lng: 21.0092929 },
    { lat: 49.1684569, lng: 21.009449 },
    { lat: 49.1683335, lng: 21.0096979 },
    { lat: 49.1675474, lng: 21.0076218 },
    { lat: 49.1674932, lng: 21.007818 },
    { lat: 49.1665695, lng: 21.0096124 },
    { lat: 49.166714, lng: 21.0101067 },
    { lat: 49.166826, lng: 21.010264 },
    { lat: 49.1669302, lng: 21.0105966 },
    { lat: 49.166458, lng: 21.0119837 },
    { lat: 49.1662476, lng: 21.011917 },
    { lat: 49.1659856, lng: 21.012025 },
    { lat: 49.1652725, lng: 21.0121422 },
    { lat: 49.1649722, lng: 21.0120453 },
    { lat: 49.1647356, lng: 21.011899 },
    { lat: 49.1647212, lng: 21.0118069 },
    { lat: 49.1645215, lng: 21.0117513 },
    { lat: 49.1645131, lng: 21.0117026 },
    { lat: 49.1639098, lng: 21.0117007 },
    { lat: 49.1633787, lng: 21.0116001 },
    { lat: 49.1630376, lng: 21.0111493 },
    { lat: 49.1628559, lng: 21.0107193 },
    { lat: 49.1629467, lng: 21.0106405 },
    { lat: 49.1627259, lng: 21.0101859 },
    { lat: 49.1618992, lng: 21.0106134 },
    { lat: 49.1613317, lng: 21.0110209 },
    { lat: 49.1606275, lng: 21.0121306 },
    { lat: 49.1595823, lng: 21.0122025 },
    { lat: 49.1579041, lng: 21.0099315 },
    { lat: 49.1574129, lng: 21.0101562 },
    { lat: 49.1569116, lng: 21.0110019 },
    { lat: 49.1563348, lng: 21.0123585 },
    { lat: 49.1557739, lng: 21.0140983 },
    { lat: 49.1556496, lng: 21.0139758 },
    { lat: 49.1550436, lng: 21.0141006 },
    { lat: 49.1547454, lng: 21.014206 },
    { lat: 49.1542359, lng: 21.0145913 },
    { lat: 49.1540256, lng: 21.0147669 },
    { lat: 49.154037, lng: 21.0148045 },
    { lat: 49.1542392, lng: 21.0167914 },
    { lat: 49.1543011, lng: 21.0169679 },
    { lat: 49.1543008, lng: 21.0175443 },
    { lat: 49.1543766, lng: 21.0179006 },
    { lat: 49.1544589, lng: 21.018767 },
    { lat: 49.154818, lng: 21.018629 },
    { lat: 49.1548258, lng: 21.0189027 },
    { lat: 49.1554551, lng: 21.0199458 },
    { lat: 49.1555062, lng: 21.0201339 },
    { lat: 49.1561528, lng: 21.019908 },
    { lat: 49.156517, lng: 21.0199673 },
    { lat: 49.1567242, lng: 21.020176 },
    { lat: 49.1570121, lng: 21.0203404 },
    { lat: 49.1572404, lng: 21.0206794 },
    { lat: 49.1574239, lng: 21.0207528 },
    { lat: 49.1578155, lng: 21.0207501 },
    { lat: 49.1582821, lng: 21.020911 },
    { lat: 49.1585621, lng: 21.0212457 },
    { lat: 49.1588825, lng: 21.0213088 },
    { lat: 49.1589167, lng: 21.0214543 },
    { lat: 49.1590252, lng: 21.0215773 },
    { lat: 49.1590823, lng: 21.0216007 },
    { lat: 49.1592412, lng: 21.0214675 },
    { lat: 49.1595113, lng: 21.0215354 },
    { lat: 49.1597334, lng: 21.0214848 },
    { lat: 49.1598798, lng: 21.021693 },
    { lat: 49.1600685, lng: 21.021678 },
    { lat: 49.1603211, lng: 21.0218666 },
    { lat: 49.1605269, lng: 21.0221412 },
    { lat: 49.1610423, lng: 21.0221061 },
    { lat: 49.1614226, lng: 21.0221696 },
    { lat: 49.1624563, lng: 21.0223954 },
    { lat: 49.1625647, lng: 21.0225077 },
    { lat: 49.1623773, lng: 21.0234227 },
    { lat: 49.1623947, lng: 21.0235807 },
    { lat: 49.1618432, lng: 21.0253324 },
    { lat: 49.1618388, lng: 21.0259307 },
    { lat: 49.1619118, lng: 21.0265404 },
    { lat: 49.1618478, lng: 21.0271322 },
    { lat: 49.1618659, lng: 21.0278331 },
    { lat: 49.1615561, lng: 21.0306146 },
    { lat: 49.1612802, lng: 21.0316157 },
    { lat: 49.1610866, lng: 21.0327541 },
    { lat: 49.1609486, lng: 21.0329055 },
    { lat: 49.1608145, lng: 21.0333213 },
    { lat: 49.1601197, lng: 21.0347895 },
    { lat: 49.1600854, lng: 21.0349837 },
    { lat: 49.1599461, lng: 21.0350205 },
    { lat: 49.1599179, lng: 21.0353355 },
  ],
  Krivany: [
    { lat: 49.1814709, lng: 20.9351249 },
    { lat: 49.1826504, lng: 20.9346534 },
    { lat: 49.183925, lng: 20.9337255 },
    { lat: 49.1838275, lng: 20.9348523 },
    { lat: 49.1844715, lng: 20.9364404 },
    { lat: 49.1849009, lng: 20.9393609 },
    { lat: 49.1851987, lng: 20.9399595 },
    { lat: 49.1863551, lng: 20.940004 },
    { lat: 49.1869466, lng: 20.9396588 },
    { lat: 49.1877323, lng: 20.9387491 },
    { lat: 49.1883744, lng: 20.9386637 },
    { lat: 49.1888236, lng: 20.9379589 },
    { lat: 49.1892079, lng: 20.9372364 },
    { lat: 49.1899359, lng: 20.9351689 },
    { lat: 49.1903388, lng: 20.9335831 },
    { lat: 49.1915105, lng: 20.9303479 },
    { lat: 49.1921709, lng: 20.930683 },
    { lat: 49.1923808, lng: 20.931947 },
    { lat: 49.1944112, lng: 20.9305653 },
    { lat: 49.1955923, lng: 20.9298722 },
    { lat: 49.1962192, lng: 20.9290612 },
    { lat: 49.1966152, lng: 20.9282146 },
    { lat: 49.1969494, lng: 20.9270028 },
    { lat: 49.1976887, lng: 20.9258581 },
    { lat: 49.1981841, lng: 20.9265058 },
    { lat: 49.199196, lng: 20.9255644 },
    { lat: 49.199729, lng: 20.9234262 },
    { lat: 49.200418, lng: 20.9243133 },
    { lat: 49.2009962, lng: 20.9242034 },
    { lat: 49.2020659, lng: 20.9220668 },
    { lat: 49.2028765, lng: 20.9213972 },
    { lat: 49.2039035, lng: 20.9207744 },
    { lat: 49.2045854, lng: 20.9199334 },
    { lat: 49.2053171, lng: 20.9182256 },
    { lat: 49.2060799, lng: 20.9169395 },
    { lat: 49.2055681, lng: 20.9156358 },
    { lat: 49.205736, lng: 20.915553 },
    { lat: 49.205827, lng: 20.914569 },
    { lat: 49.205598, lng: 20.913881 },
    { lat: 49.205778, lng: 20.912426 },
    { lat: 49.206763, lng: 20.910836 },
    { lat: 49.208019, lng: 20.908591 },
    { lat: 49.20879, lng: 20.908115 },
    { lat: 49.207653, lng: 20.906867 },
    { lat: 49.206973, lng: 20.905841 },
    { lat: 49.207979, lng: 20.903593 },
    { lat: 49.207962, lng: 20.903503 },
    { lat: 49.208284, lng: 20.901785 },
    { lat: 49.208159, lng: 20.900056 },
    { lat: 49.208764, lng: 20.899026 },
    { lat: 49.209027, lng: 20.898808 },
    { lat: 49.208095, lng: 20.898171 },
    { lat: 49.207501, lng: 20.897356 },
    { lat: 49.207094, lng: 20.896703 },
    { lat: 49.206902, lng: 20.896054 },
    { lat: 49.206499, lng: 20.89558 },
    { lat: 49.205982, lng: 20.895563 },
    { lat: 49.205991, lng: 20.8953 },
    { lat: 49.205372, lng: 20.895267 },
    { lat: 49.205292, lng: 20.895001 },
    { lat: 49.205864, lng: 20.89469 },
    { lat: 49.205876, lng: 20.894484 },
    { lat: 49.206959, lng: 20.893495 },
    { lat: 49.207568, lng: 20.893075 },
    { lat: 49.207434, lng: 20.892953 },
    { lat: 49.207464, lng: 20.892869 },
    { lat: 49.208405, lng: 20.891954 },
    { lat: 49.208994, lng: 20.890476 },
    { lat: 49.209721, lng: 20.890109 },
    { lat: 49.209977, lng: 20.88965 },
    { lat: 49.210021, lng: 20.88959 },
    { lat: 49.210514, lng: 20.88904 },
    { lat: 49.210299, lng: 20.888787 },
    { lat: 49.209746, lng: 20.887803 },
    { lat: 49.209476, lng: 20.88713 },
    { lat: 49.209272, lng: 20.886233 },
    { lat: 49.20934, lng: 20.886174 },
    { lat: 49.209602, lng: 20.885947 },
    { lat: 49.210093, lng: 20.885671 },
    { lat: 49.210101, lng: 20.886396 },
    { lat: 49.210426, lng: 20.887345 },
    { lat: 49.210666, lng: 20.887183 },
    { lat: 49.210631, lng: 20.886741 },
    { lat: 49.210562, lng: 20.886242 },
    { lat: 49.211121, lng: 20.886267 },
    { lat: 49.211021, lng: 20.885653 },
    { lat: 49.212171, lng: 20.885421 },
    { lat: 49.21261, lng: 20.885564 },
    { lat: 49.213778, lng: 20.886608 },
    { lat: 49.214005, lng: 20.886444 },
    { lat: 49.214099, lng: 20.886533 },
    { lat: 49.214191, lng: 20.885924 },
    { lat: 49.214442, lng: 20.885873 },
    { lat: 49.215524, lng: 20.885625 },
    { lat: 49.217161, lng: 20.885552 },
    { lat: 49.2173, lng: 20.886077 },
    { lat: 49.21747, lng: 20.886009 },
    { lat: 49.217872, lng: 20.885849 },
    { lat: 49.217975, lng: 20.885866 },
    { lat: 49.218038, lng: 20.885876 },
    { lat: 49.217772, lng: 20.887804 },
    { lat: 49.217765, lng: 20.887853 },
    { lat: 49.217766, lng: 20.88789 },
    { lat: 49.217766, lng: 20.88797 },
    { lat: 49.21777, lng: 20.888257 },
    { lat: 49.218019, lng: 20.887948 },
    { lat: 49.218547, lng: 20.887484 },
    { lat: 49.218578, lng: 20.88753 },
    { lat: 49.21938, lng: 20.886497 },
    { lat: 49.219844, lng: 20.886316 },
    { lat: 49.220017, lng: 20.887101 },
    { lat: 49.220286, lng: 20.887708 },
    { lat: 49.220552, lng: 20.887545 },
    { lat: 49.220922, lng: 20.887578 },
    { lat: 49.221159, lng: 20.887586 },
    { lat: 49.221162, lng: 20.887742 },
    { lat: 49.221237, lng: 20.887771 },
    { lat: 49.22128, lng: 20.887828 },
    { lat: 49.221371, lng: 20.887873 },
    { lat: 49.221376, lng: 20.887873 },
    { lat: 49.221627, lng: 20.887906 },
    { lat: 49.221811, lng: 20.887931 },
    { lat: 49.222013, lng: 20.887926 },
    { lat: 49.222187, lng: 20.887891 },
    { lat: 49.222561, lng: 20.887817 },
    { lat: 49.222902, lng: 20.887707 },
    { lat: 49.223239, lng: 20.887599 },
    { lat: 49.223476, lng: 20.887522 },
    { lat: 49.22371, lng: 20.887447 },
    { lat: 49.223945, lng: 20.887372 },
    { lat: 49.224181, lng: 20.887296 },
    { lat: 49.224356, lng: 20.887102 },
    { lat: 49.224663, lng: 20.887042 },
    { lat: 49.225035, lng: 20.886969 },
    { lat: 49.225212, lng: 20.886934 },
    { lat: 49.225484, lng: 20.886843 },
    { lat: 49.225574, lng: 20.886812 },
    { lat: 49.225618, lng: 20.886974 },
    { lat: 49.225788, lng: 20.886858 },
    { lat: 49.225843, lng: 20.88682 },
    { lat: 49.226028, lng: 20.886695 },
    { lat: 49.226214, lng: 20.88657 },
    { lat: 49.226415, lng: 20.886433 },
    { lat: 49.226613, lng: 20.886299 },
    { lat: 49.226655, lng: 20.886271 },
    { lat: 49.22652, lng: 20.885497 },
    { lat: 49.22638, lng: 20.884719 },
    { lat: 49.225781, lng: 20.88462 },
    { lat: 49.224801, lng: 20.884739 },
    { lat: 49.225031, lng: 20.882954 },
    { lat: 49.22534, lng: 20.881178 },
    { lat: 49.226271, lng: 20.876807 },
    { lat: 49.2275512, lng: 20.8754515 },
    { lat: 49.227801, lng: 20.875187 },
    { lat: 49.226806, lng: 20.875028 },
    { lat: 49.22676, lng: 20.875075 },
    { lat: 49.226764, lng: 20.875167 },
    { lat: 49.226717, lng: 20.875208 },
    { lat: 49.226662, lng: 20.875141 },
    { lat: 49.226623, lng: 20.875272 },
    { lat: 49.226466, lng: 20.875173 },
    { lat: 49.226446, lng: 20.875311 },
    { lat: 49.2264, lng: 20.875344 },
    { lat: 49.226418, lng: 20.875518 },
    { lat: 49.226384, lng: 20.875559 },
    { lat: 49.226333, lng: 20.87552 },
    { lat: 49.226281, lng: 20.875394 },
    { lat: 49.226208, lng: 20.875406 },
    { lat: 49.226129, lng: 20.875356 },
    { lat: 49.2261, lng: 20.875448 },
    { lat: 49.22602, lng: 20.87549 },
    { lat: 49.226044, lng: 20.875691 },
    { lat: 49.225978, lng: 20.875646 },
    { lat: 49.225845, lng: 20.875784 },
    { lat: 49.225848, lng: 20.875867 },
    { lat: 49.225936, lng: 20.87588 },
    { lat: 49.225851, lng: 20.876037 },
    { lat: 49.225784, lng: 20.87601 },
    { lat: 49.225671, lng: 20.876136 },
    { lat: 49.22561, lng: 20.876023 },
    { lat: 49.2255, lng: 20.87603 },
    { lat: 49.225426, lng: 20.876107 },
    { lat: 49.225345, lng: 20.876077 },
    { lat: 49.225252, lng: 20.876185 },
    { lat: 49.225113, lng: 20.876108 },
    { lat: 49.225105, lng: 20.876201 },
    { lat: 49.22498, lng: 20.876199 },
    { lat: 49.224805, lng: 20.876108 },
    { lat: 49.224649, lng: 20.876148 },
    { lat: 49.224514, lng: 20.876355 },
    { lat: 49.224368, lng: 20.87642 },
    { lat: 49.22432, lng: 20.876582 },
    { lat: 49.224229, lng: 20.876619 },
    { lat: 49.224152, lng: 20.876762 },
    { lat: 49.224161, lng: 20.876866 },
    { lat: 49.224081, lng: 20.876894 },
    { lat: 49.224063, lng: 20.876948 },
    { lat: 49.22404, lng: 20.876884 },
    { lat: 49.224009, lng: 20.877166 },
    { lat: 49.223925, lng: 20.877297 },
    { lat: 49.223895, lng: 20.877394 },
    { lat: 49.223758, lng: 20.877437 },
    { lat: 49.223669, lng: 20.877502 },
    { lat: 49.223653, lng: 20.877569 },
    { lat: 49.223608, lng: 20.877621 },
    { lat: 49.223552, lng: 20.877601 },
    { lat: 49.223524, lng: 20.877494 },
    { lat: 49.22328, lng: 20.877528 },
    { lat: 49.223218, lng: 20.877676 },
    { lat: 49.223127, lng: 20.877739 },
    { lat: 49.222989, lng: 20.877794 },
    { lat: 49.222968, lng: 20.877913 },
    { lat: 49.222809, lng: 20.877998 },
    { lat: 49.222682, lng: 20.877824 },
    { lat: 49.222487, lng: 20.877903 },
    { lat: 49.222359, lng: 20.877932 },
    { lat: 49.22224, lng: 20.877909 },
    { lat: 49.222101, lng: 20.877869 },
    { lat: 49.221971, lng: 20.877768 },
    { lat: 49.221828, lng: 20.877747 },
    { lat: 49.221707, lng: 20.877631 },
    { lat: 49.221551, lng: 20.877449 },
    { lat: 49.221217, lng: 20.877393 },
    { lat: 49.221205, lng: 20.877276 },
    { lat: 49.221188, lng: 20.876914 },
    { lat: 49.221324, lng: 20.876422 },
    { lat: 49.221034, lng: 20.876267 },
    { lat: 49.220445, lng: 20.876217 },
    { lat: 49.219389, lng: 20.876335 },
    { lat: 49.218284, lng: 20.876655 },
    { lat: 49.218118, lng: 20.876576 },
    { lat: 49.217718, lng: 20.876238 },
    { lat: 49.217196, lng: 20.875992 },
    { lat: 49.217034, lng: 20.87584 },
    { lat: 49.216862, lng: 20.875911 },
    { lat: 49.216877, lng: 20.876192 },
    { lat: 49.216824, lng: 20.876471 },
    { lat: 49.216645, lng: 20.876303 },
    { lat: 49.216511, lng: 20.876094 },
    { lat: 49.21629, lng: 20.87583 },
    { lat: 49.216216, lng: 20.875789 },
    { lat: 49.216075, lng: 20.875616 },
    { lat: 49.216059, lng: 20.875536 },
    { lat: 49.216003, lng: 20.875474 },
    { lat: 49.215932, lng: 20.875297 },
    { lat: 49.215869, lng: 20.875234 },
    { lat: 49.215788, lng: 20.8752 },
    { lat: 49.215711, lng: 20.875109 },
    { lat: 49.215459, lng: 20.87505 },
    { lat: 49.215307, lng: 20.874928 },
    { lat: 49.215219, lng: 20.874617 },
    { lat: 49.215076, lng: 20.874486 },
    { lat: 49.214987, lng: 20.874436 },
    { lat: 49.214623, lng: 20.874001 },
    { lat: 49.214342, lng: 20.874061 },
    { lat: 49.213965, lng: 20.873699 },
    { lat: 49.21404, lng: 20.87336 },
    { lat: 49.213975, lng: 20.873359 },
    { lat: 49.213762, lng: 20.873325 },
    { lat: 49.213378, lng: 20.873149 },
    { lat: 49.213194, lng: 20.873184 },
    { lat: 49.212876, lng: 20.873144 },
    { lat: 49.212768, lng: 20.873212 },
    { lat: 49.212613, lng: 20.873435 },
    { lat: 49.21252, lng: 20.873437 },
    { lat: 49.212451, lng: 20.873564 },
    { lat: 49.212411, lng: 20.873568 },
    { lat: 49.212199, lng: 20.873484 },
    { lat: 49.212132, lng: 20.873938 },
    { lat: 49.21185, lng: 20.874538 },
    { lat: 49.211779, lng: 20.874588 },
    { lat: 49.211674, lng: 20.874822 },
    { lat: 49.21159, lng: 20.874807 },
    { lat: 49.211432, lng: 20.875178 },
    { lat: 49.211372, lng: 20.875074 },
    { lat: 49.21134, lng: 20.874885 },
    { lat: 49.211271, lng: 20.874653 },
    { lat: 49.210985, lng: 20.874479 },
    { lat: 49.210898, lng: 20.874218 },
    { lat: 49.210833, lng: 20.873994 },
    { lat: 49.210751, lng: 20.873961 },
    { lat: 49.210766, lng: 20.87392 },
    { lat: 49.2101676, lng: 20.8745063 },
    { lat: 49.2094295, lng: 20.8753734 },
    { lat: 49.2088884, lng: 20.8768135 },
    { lat: 49.2085908, lng: 20.8773629 },
    { lat: 49.2076799, lng: 20.8777525 },
    { lat: 49.2074787, lng: 20.8778915 },
    { lat: 49.2073913, lng: 20.878402 },
    { lat: 49.2073802, lng: 20.8787721 },
    { lat: 49.2072801, lng: 20.879145 },
    { lat: 49.2070832, lng: 20.8795435 },
    { lat: 49.2066311, lng: 20.8800878 },
    { lat: 49.2063711, lng: 20.8807187 },
    { lat: 49.2062187, lng: 20.8808013 },
    { lat: 49.2056811, lng: 20.8808814 },
    { lat: 49.205007, lng: 20.8808546 },
    { lat: 49.2045882, lng: 20.8811834 },
    { lat: 49.2043749, lng: 20.881598 },
    { lat: 49.2041449, lng: 20.8824133 },
    { lat: 49.2038887, lng: 20.8829273 },
    { lat: 49.2036426, lng: 20.8831043 },
    { lat: 49.2035132, lng: 20.8830717 },
    { lat: 49.2032401, lng: 20.8827869 },
    { lat: 49.202891, lng: 20.8825765 },
    { lat: 49.2025425, lng: 20.882592 },
    { lat: 49.2021547, lng: 20.8828684 },
    { lat: 49.2018484, lng: 20.8829983 },
    { lat: 49.20159, lng: 20.8833127 },
    { lat: 49.2012825, lng: 20.8834045 },
    { lat: 49.2010417, lng: 20.8836783 },
    { lat: 49.2007892, lng: 20.8837288 },
    { lat: 49.2007989, lng: 20.8857133 },
    { lat: 49.1997563, lng: 20.885869 },
    { lat: 49.1985443, lng: 20.885444 },
    { lat: 49.1982387, lng: 20.8855223 },
    { lat: 49.1980149, lng: 20.885396 },
    { lat: 49.1977763, lng: 20.8843664 },
    { lat: 49.1977059, lng: 20.8839433 },
    { lat: 49.1976936, lng: 20.8834174 },
    { lat: 49.1976427, lng: 20.8832371 },
    { lat: 49.1975632, lng: 20.88312 },
    { lat: 49.1974282, lng: 20.8831846 },
    { lat: 49.1965274, lng: 20.8824083 },
    { lat: 49.1964895, lng: 20.8822839 },
    { lat: 49.1958519, lng: 20.8827342 },
    { lat: 49.1946557, lng: 20.8832109 },
    { lat: 49.1941306, lng: 20.8831943 },
    { lat: 49.1938301, lng: 20.8833608 },
    { lat: 49.1934432, lng: 20.8840322 },
    { lat: 49.1929041, lng: 20.8846007 },
    { lat: 49.1924054, lng: 20.8849944 },
    { lat: 49.1904075, lng: 20.8860525 },
    { lat: 49.1899767, lng: 20.8861594 },
    { lat: 49.1880655, lng: 20.8859251 },
    { lat: 49.1867988, lng: 20.8856749 },
    { lat: 49.1841488, lng: 20.8856951 },
    { lat: 49.1819661, lng: 20.8862833 },
    { lat: 49.1814631, lng: 20.8865157 },
    { lat: 49.1764523, lng: 20.8897838 },
    { lat: 49.1742729, lng: 20.8915064 },
    { lat: 49.1739889, lng: 20.8917564 },
    { lat: 49.1737596, lng: 20.8917676 },
    { lat: 49.173105, lng: 20.8923521 },
    { lat: 49.1712729, lng: 20.8945868 },
    { lat: 49.1713995, lng: 20.8949423 },
    { lat: 49.1709717, lng: 20.8951668 },
    { lat: 49.1690648, lng: 20.8966968 },
    { lat: 49.1692656, lng: 20.8972807 },
    { lat: 49.1673578, lng: 20.89928 },
    { lat: 49.1668337, lng: 20.897754 },
    { lat: 49.1667627, lng: 20.8960461 },
    { lat: 49.1660749, lng: 20.8945409 },
    { lat: 49.1660122, lng: 20.8942538 },
    { lat: 49.165553, lng: 20.8906486 },
    { lat: 49.164898, lng: 20.8923498 },
    { lat: 49.1641623, lng: 20.8957335 },
    { lat: 49.1641209, lng: 20.8968761 },
    { lat: 49.1641704, lng: 20.8973434 },
    { lat: 49.1641357, lng: 20.8976791 },
    { lat: 49.1640064, lng: 20.8979162 },
    { lat: 49.163928, lng: 20.8978975 },
    { lat: 49.1629678, lng: 20.8993947 },
    { lat: 49.1632346, lng: 20.8999011 },
    { lat: 49.1631063, lng: 20.9002283 },
    { lat: 49.1635399, lng: 20.9019138 },
    { lat: 49.1636248, lng: 20.9026631 },
    { lat: 49.1634255, lng: 20.9036286 },
    { lat: 49.1635075, lng: 20.9049323 },
    { lat: 49.1638023, lng: 20.9066697 },
    { lat: 49.1638484, lng: 20.9072343 },
    { lat: 49.1638548, lng: 20.9084278 },
    { lat: 49.16372, lng: 20.9084031 },
    { lat: 49.1635838, lng: 20.9081681 },
    { lat: 49.1634669, lng: 20.9081417 },
    { lat: 49.1633122, lng: 20.908193 },
    { lat: 49.1625586, lng: 20.908942 },
    { lat: 49.1619251, lng: 20.9088501 },
    { lat: 49.1616539, lng: 20.9089825 },
    { lat: 49.1614937, lng: 20.9089676 },
    { lat: 49.1610114, lng: 20.9083483 },
    { lat: 49.1609454, lng: 20.9080909 },
    { lat: 49.1604827, lng: 20.9079595 },
    { lat: 49.1603902, lng: 20.907747 },
    { lat: 49.1604044, lng: 20.9075021 },
    { lat: 49.1603247, lng: 20.9074055 },
    { lat: 49.1600095, lng: 20.9076754 },
    { lat: 49.1599329, lng: 20.9076421 },
    { lat: 49.1598911, lng: 20.9074293 },
    { lat: 49.1600308, lng: 20.9071019 },
    { lat: 49.160049, lng: 20.9069405 },
    { lat: 49.1599439, lng: 20.9066912 },
    { lat: 49.1598712, lng: 20.9066771 },
    { lat: 49.1596884, lng: 20.9068928 },
    { lat: 49.1595821, lng: 20.9066411 },
    { lat: 49.159517, lng: 20.9063124 },
    { lat: 49.1594526, lng: 20.906243 },
    { lat: 49.1590838, lng: 20.9064531 },
    { lat: 49.1589912, lng: 20.9064424 },
    { lat: 49.1589367, lng: 20.90626 },
    { lat: 49.1590441, lng: 20.9057719 },
    { lat: 49.1590175, lng: 20.9054848 },
    { lat: 49.1589102, lng: 20.9054098 },
    { lat: 49.1588422, lng: 20.9054921 },
    { lat: 49.1586808, lng: 20.906147 },
    { lat: 49.1586094, lng: 20.9061971 },
    { lat: 49.158452, lng: 20.9061037 },
    { lat: 49.1583587, lng: 20.9057428 },
    { lat: 49.1582613, lng: 20.9055886 },
    { lat: 49.1581238, lng: 20.9054835 },
    { lat: 49.1579278, lng: 20.9054492 },
    { lat: 49.1576922, lng: 20.9054986 },
    { lat: 49.1576567, lng: 20.9053823 },
    { lat: 49.1576688, lng: 20.9050761 },
    { lat: 49.1575819, lng: 20.9048973 },
    { lat: 49.1568709, lng: 20.904675 },
    { lat: 49.1564848, lng: 20.9042388 },
    { lat: 49.1559484, lng: 20.9037809 },
    { lat: 49.1558404, lng: 20.9035862 },
    { lat: 49.1556228, lng: 20.9034396 },
    { lat: 49.1554193, lng: 20.9036439 },
    { lat: 49.1542067, lng: 20.9030952 },
    { lat: 49.1538242, lng: 20.9032988 },
    { lat: 49.1530065, lng: 20.9033545 },
    { lat: 49.1528024, lng: 20.9032396 },
    { lat: 49.1521677, lng: 20.9033795 },
    { lat: 49.1518752, lng: 20.9033491 },
    { lat: 49.1515417, lng: 20.9033004 },
    { lat: 49.1516005, lng: 20.9035468 },
    { lat: 49.151586, lng: 20.9048556 },
    { lat: 49.152062, lng: 20.9052851 },
    { lat: 49.1529446, lng: 20.905896 },
    { lat: 49.151837, lng: 20.9092675 },
    { lat: 49.1553863, lng: 20.9119716 },
    { lat: 49.154404, lng: 20.9148234 },
    { lat: 49.1546954, lng: 20.9152933 },
    { lat: 49.1561422, lng: 20.9160488 },
    { lat: 49.1606847, lng: 20.91954 },
    { lat: 49.1597474, lng: 20.9224309 },
    { lat: 49.160019, lng: 20.9226471 },
    { lat: 49.159052, lng: 20.9244204 },
    { lat: 49.1593619, lng: 20.9251407 },
    { lat: 49.1593131, lng: 20.9258651 },
    { lat: 49.1596418, lng: 20.9270856 },
    { lat: 49.1598977, lng: 20.9266543 },
    { lat: 49.1605231, lng: 20.9267812 },
    { lat: 49.1606775, lng: 20.9264182 },
    { lat: 49.1635363, lng: 20.9292451 },
    { lat: 49.1644294, lng: 20.930237 },
    { lat: 49.1643089, lng: 20.9325337 },
    { lat: 49.1633239, lng: 20.9343087 },
    { lat: 49.1634583, lng: 20.934491 },
    { lat: 49.1634676, lng: 20.9347331 },
    { lat: 49.1626734, lng: 20.9355557 },
    { lat: 49.1627439, lng: 20.935648 },
    { lat: 49.1625872, lng: 20.9360759 },
    { lat: 49.1619955, lng: 20.936558 },
    { lat: 49.1620055, lng: 20.9365875 },
    { lat: 49.162648, lng: 20.9377636 },
    { lat: 49.1628578, lng: 20.9379084 },
    { lat: 49.1631327, lng: 20.9383945 },
    { lat: 49.1635098, lng: 20.9385635 },
    { lat: 49.1639, lng: 20.9384199 },
    { lat: 49.1640222, lng: 20.9386741 },
    { lat: 49.1642684, lng: 20.9386316 },
    { lat: 49.16439, lng: 20.9386991 },
    { lat: 49.1645205, lng: 20.9388735 },
    { lat: 49.164845, lng: 20.9389512 },
    { lat: 49.164962, lng: 20.9392478 },
    { lat: 49.1651853, lng: 20.9392309 },
    { lat: 49.1653832, lng: 20.9395651 },
    { lat: 49.1661204, lng: 20.939395 },
    { lat: 49.1663828, lng: 20.9396322 },
    { lat: 49.1666726, lng: 20.9395521 },
    { lat: 49.1671196, lng: 20.9392859 },
    { lat: 49.1677193, lng: 20.9393509 },
    { lat: 49.1683204, lng: 20.9392482 },
    { lat: 49.1691802, lng: 20.9392477 },
    { lat: 49.1701679, lng: 20.9388999 },
    { lat: 49.171051, lng: 20.9383181 },
    { lat: 49.1719708, lng: 20.9370749 },
    { lat: 49.1728319, lng: 20.9361977 },
    { lat: 49.1741317, lng: 20.9351562 },
    { lat: 49.1745378, lng: 20.9352459 },
    { lat: 49.174624, lng: 20.9351651 },
    { lat: 49.1767866, lng: 20.9347123 },
    { lat: 49.1771164, lng: 20.9348496 },
    { lat: 49.1786806, lng: 20.9352135 },
    { lat: 49.1805029, lng: 20.9351223 },
    { lat: 49.1805658, lng: 20.9351819 },
    { lat: 49.180729, lng: 20.9350116 },
    { lat: 49.1814709, lng: 20.9351249 },
  ],
  Poloma: [
    { lat: 49.1715026, lng: 20.7884062 },
    { lat: 49.1709137, lng: 20.7893483 },
    { lat: 49.1701445, lng: 20.7912822 },
    { lat: 49.1699631, lng: 20.7922579 },
    { lat: 49.1696122, lng: 20.793726 },
    { lat: 49.1672355, lng: 20.7985324 },
    { lat: 49.1663809, lng: 20.800707 },
    { lat: 49.165028, lng: 20.8044857 },
    { lat: 49.164209, lng: 20.8063911 },
    { lat: 49.1635655, lng: 20.8073131 },
    { lat: 49.1624861, lng: 20.808521 },
    { lat: 49.1611578, lng: 20.8095643 },
    { lat: 49.1600594, lng: 20.8101245 },
    { lat: 49.1592008, lng: 20.8111093 },
    { lat: 49.1589093, lng: 20.8115703 },
    { lat: 49.1586549, lng: 20.8121265 },
    { lat: 49.1584397, lng: 20.8127069 },
    { lat: 49.1581403, lng: 20.813855 },
    { lat: 49.1579802, lng: 20.8142329 },
    { lat: 49.1586057, lng: 20.8212019 },
    { lat: 49.1585852, lng: 20.8215524 },
    { lat: 49.1587975, lng: 20.822555 },
    { lat: 49.1587845, lng: 20.8230983 },
    { lat: 49.1591018, lng: 20.8246647 },
    { lat: 49.159316, lng: 20.8260825 },
    { lat: 49.1599813, lng: 20.8290431 },
    { lat: 49.1604186, lng: 20.8305389 },
    { lat: 49.1603483, lng: 20.831473 },
    { lat: 49.1604595, lng: 20.8318954 },
    { lat: 49.1604906, lng: 20.8324039 },
    { lat: 49.1603206, lng: 20.8332241 },
    { lat: 49.160187, lng: 20.8332715 },
    { lat: 49.1597172, lng: 20.8342414 },
    { lat: 49.1595218, lng: 20.8349485 },
    { lat: 49.1593499, lng: 20.8353847 },
    { lat: 49.1593001, lng: 20.8359947 },
    { lat: 49.1591469, lng: 20.8364532 },
    { lat: 49.1593196, lng: 20.8366699 },
    { lat: 49.1592202, lng: 20.8369548 },
    { lat: 49.1592787, lng: 20.8374108 },
    { lat: 49.159203, lng: 20.8374384 },
    { lat: 49.1591543, lng: 20.8378224 },
    { lat: 49.1590373, lng: 20.8379234 },
    { lat: 49.1590364, lng: 20.8384913 },
    { lat: 49.158819, lng: 20.8387484 },
    { lat: 49.1588409, lng: 20.8393178 },
    { lat: 49.1592081, lng: 20.8391667 },
    { lat: 49.1610631, lng: 20.8390692 },
    { lat: 49.1617576, lng: 20.8388043 },
    { lat: 49.1621929, lng: 20.8389937 },
    { lat: 49.1611977, lng: 20.8405351 },
    { lat: 49.1609132, lng: 20.8410724 },
    { lat: 49.1609187, lng: 20.8411377 },
    { lat: 49.1625038, lng: 20.8432545 },
    { lat: 49.163985, lng: 20.8450966 },
    { lat: 49.1640185, lng: 20.8452845 },
    { lat: 49.1642492, lng: 20.8457126 },
    { lat: 49.1643109, lng: 20.8461051 },
    { lat: 49.1644597, lng: 20.8465403 },
    { lat: 49.1645924, lng: 20.8466693 },
    { lat: 49.1660588, lng: 20.8479837 },
    { lat: 49.166239, lng: 20.848447 },
    { lat: 49.1687206, lng: 20.8512478 },
    { lat: 49.1709921, lng: 20.844425 },
    { lat: 49.1712225, lng: 20.8438578 },
    { lat: 49.1715993, lng: 20.8432868 },
    { lat: 49.1718933, lng: 20.8427315 },
    { lat: 49.1720643, lng: 20.8423046 },
    { lat: 49.1719723, lng: 20.8421446 },
    { lat: 49.1726702, lng: 20.8409316 },
    { lat: 49.1734184, lng: 20.8398364 },
    { lat: 49.1737484, lng: 20.8395109 },
    { lat: 49.1739208, lng: 20.8394839 },
    { lat: 49.1743056, lng: 20.8391853 },
    { lat: 49.1751998, lng: 20.8383094 },
    { lat: 49.1762068, lng: 20.8372177 },
    { lat: 49.1765328, lng: 20.8367795 },
    { lat: 49.1773444, lng: 20.8354007 },
    { lat: 49.1777474, lng: 20.8344732 },
    { lat: 49.1782345, lng: 20.8335188 },
    { lat: 49.1783508, lng: 20.8332638 },
    { lat: 49.1783423, lng: 20.8330159 },
    { lat: 49.1783998, lng: 20.8329534 },
    { lat: 49.1784465, lng: 20.8327051 },
    { lat: 49.1784115, lng: 20.8324751 },
    { lat: 49.1794025, lng: 20.8305509 },
    { lat: 49.1797359, lng: 20.831967 },
    { lat: 49.180409, lng: 20.8306225 },
    { lat: 49.1810308, lng: 20.8302142 },
    { lat: 49.1828853, lng: 20.8281605 },
    { lat: 49.1830701, lng: 20.8279097 },
    { lat: 49.182838, lng: 20.8276681 },
    { lat: 49.182316, lng: 20.8268912 },
    { lat: 49.1820079, lng: 20.826537 },
    { lat: 49.1807027, lng: 20.8254315 },
    { lat: 49.1804604, lng: 20.8254915 },
    { lat: 49.1803972, lng: 20.8251278 },
    { lat: 49.1803086, lng: 20.8249374 },
    { lat: 49.1800335, lng: 20.8248939 },
    { lat: 49.1799664, lng: 20.8248067 },
    { lat: 49.179963, lng: 20.8246045 },
    { lat: 49.1800892, lng: 20.8242807 },
    { lat: 49.1799503, lng: 20.8241389 },
    { lat: 49.1800125, lng: 20.8239177 },
    { lat: 49.1799921, lng: 20.823748 },
    { lat: 49.1799208, lng: 20.8236433 },
    { lat: 49.1799359, lng: 20.8232309 },
    { lat: 49.1798914, lng: 20.8230819 },
    { lat: 49.1797291, lng: 20.8228746 },
    { lat: 49.1795815, lng: 20.8225133 },
    { lat: 49.1794179, lng: 20.8223019 },
    { lat: 49.1793225, lng: 20.8220779 },
    { lat: 49.179387, lng: 20.8219248 },
    { lat: 49.1793812, lng: 20.821754 },
    { lat: 49.1791888, lng: 20.8213301 },
    { lat: 49.1792297, lng: 20.8212161 },
    { lat: 49.1794351, lng: 20.8212997 },
    { lat: 49.1795219, lng: 20.8212035 },
    { lat: 49.1795575, lng: 20.8209607 },
    { lat: 49.1792593, lng: 20.8202736 },
    { lat: 49.1795809, lng: 20.8200346 },
    { lat: 49.1797555, lng: 20.819681 },
    { lat: 49.1797389, lng: 20.8195193 },
    { lat: 49.179573, lng: 20.8193455 },
    { lat: 49.1795822, lng: 20.8190239 },
    { lat: 49.1798196, lng: 20.8186624 },
    { lat: 49.1802157, lng: 20.8184332 },
    { lat: 49.1802304, lng: 20.8181665 },
    { lat: 49.1802587, lng: 20.8181754 },
    { lat: 49.180342, lng: 20.8178129 },
    { lat: 49.1803085, lng: 20.8173662 },
    { lat: 49.1806886, lng: 20.816373 },
    { lat: 49.1806964, lng: 20.8155849 },
    { lat: 49.1803545, lng: 20.8153625 },
    { lat: 49.1803332, lng: 20.8152379 },
    { lat: 49.1803792, lng: 20.8150655 },
    { lat: 49.1803393, lng: 20.8147212 },
    { lat: 49.1801543, lng: 20.81406 },
    { lat: 49.1801949, lng: 20.8140607 },
    { lat: 49.1803024, lng: 20.8138477 },
    { lat: 49.1800751, lng: 20.8130618 },
    { lat: 49.1801339, lng: 20.8128437 },
    { lat: 49.1802852, lng: 20.8126974 },
    { lat: 49.1802782, lng: 20.8124926 },
    { lat: 49.1803195, lng: 20.8123838 },
    { lat: 49.180491, lng: 20.8123295 },
    { lat: 49.1805253, lng: 20.8122177 },
    { lat: 49.1804997, lng: 20.8118643 },
    { lat: 49.1805528, lng: 20.8117359 },
    { lat: 49.1805102, lng: 20.8113471 },
    { lat: 49.1803422, lng: 20.8109814 },
    { lat: 49.1804355, lng: 20.8099309 },
    { lat: 49.1801964, lng: 20.8097558 },
    { lat: 49.1801584, lng: 20.8096635 },
    { lat: 49.1801743, lng: 20.809573 },
    { lat: 49.1803895, lng: 20.8093136 },
    { lat: 49.1804128, lng: 20.8091772 },
    { lat: 49.1803614, lng: 20.8089233 },
    { lat: 49.180465, lng: 20.8086305 },
    { lat: 49.180434, lng: 20.8084829 },
    { lat: 49.1802977, lng: 20.8083201 },
    { lat: 49.1803991, lng: 20.8074366 },
    { lat: 49.1803048, lng: 20.8067264 },
    { lat: 49.1802159, lng: 20.8064997 },
    { lat: 49.1802879, lng: 20.8062359 },
    { lat: 49.1804176, lng: 20.8060784 },
    { lat: 49.1803242, lng: 20.805757 },
    { lat: 49.1803216, lng: 20.8054219 },
    { lat: 49.1802664, lng: 20.8052572 },
    { lat: 49.1803732, lng: 20.8049882 },
    { lat: 49.1804121, lng: 20.8046444 },
    { lat: 49.1805989, lng: 20.8042915 },
    { lat: 49.1806497, lng: 20.803964 },
    { lat: 49.1810457, lng: 20.803014 },
    { lat: 49.1814528, lng: 20.8026788 },
    { lat: 49.1817519, lng: 20.8022435 },
    { lat: 49.1818229, lng: 20.8019561 },
    { lat: 49.1820044, lng: 20.8016983 },
    { lat: 49.1822347, lng: 20.8011959 },
    { lat: 49.1822568, lng: 20.8009606 },
    { lat: 49.1824306, lng: 20.8005163 },
    { lat: 49.1824633, lng: 20.7998999 },
    { lat: 49.1825142, lng: 20.7998375 },
    { lat: 49.1825038, lng: 20.7996725 },
    { lat: 49.1826336, lng: 20.7992587 },
    { lat: 49.1828649, lng: 20.7976775 },
    { lat: 49.1828799, lng: 20.7974896 },
    { lat: 49.1827246, lng: 20.7968746 },
    { lat: 49.1828156, lng: 20.7958271 },
    { lat: 49.182807, lng: 20.7957072 },
    { lat: 49.1827101, lng: 20.7955042 },
    { lat: 49.1827475, lng: 20.7950917 },
    { lat: 49.1827226, lng: 20.7939499 },
    { lat: 49.1829545, lng: 20.7932958 },
    { lat: 49.1829667, lng: 20.7924482 },
    { lat: 49.1830618, lng: 20.7913856 },
    { lat: 49.1827497, lng: 20.790404 },
    { lat: 49.1826625, lng: 20.7899674 },
    { lat: 49.1822471, lng: 20.7890186 },
    { lat: 49.1821932, lng: 20.7882138 },
    { lat: 49.1824064, lng: 20.7871848 },
    { lat: 49.1826697, lng: 20.7871507 },
    { lat: 49.1828656, lng: 20.7870179 },
    { lat: 49.1830882, lng: 20.7856486 },
    { lat: 49.1834699, lng: 20.784678 },
    { lat: 49.1836607, lng: 20.7827455 },
    { lat: 49.1830122, lng: 20.7822513 },
    { lat: 49.1827129, lng: 20.7813478 },
    { lat: 49.1824528, lng: 20.7807637 },
    { lat: 49.1819564, lng: 20.7800816 },
    { lat: 49.1813194, lng: 20.7796966 },
    { lat: 49.1800236, lng: 20.7791655 },
    { lat: 49.1792122, lng: 20.7790104 },
    { lat: 49.1791048, lng: 20.7781848 },
    { lat: 49.1773142, lng: 20.7793551 },
    { lat: 49.1759846, lng: 20.779416 },
    { lat: 49.1759609, lng: 20.7803512 },
    { lat: 49.1749401, lng: 20.781298 },
    { lat: 49.1745291, lng: 20.7817816 },
    { lat: 49.1743977, lng: 20.7824336 },
    { lat: 49.1744543, lng: 20.783776 },
    { lat: 49.1743909, lng: 20.7841559 },
    { lat: 49.1744246, lng: 20.7847403 },
    { lat: 49.1743552, lng: 20.7849434 },
    { lat: 49.1740264, lng: 20.7854613 },
    { lat: 49.173869, lng: 20.7863766 },
    { lat: 49.1736864, lng: 20.7866622 },
    { lat: 49.1739794, lng: 20.7852421 },
    { lat: 49.1721259, lng: 20.785803 },
    { lat: 49.1719867, lng: 20.7864639 },
    { lat: 49.1723083, lng: 20.7871668 },
    { lat: 49.172315, lng: 20.787394 },
    { lat: 49.1717414, lng: 20.7878665 },
    { lat: 49.1715026, lng: 20.7884062 },
  ],
  ŠarišskéMichaľany: [
    { lat: 49.0900141, lng: 21.1515572 },
    { lat: 49.0900713, lng: 21.1514105 },
    { lat: 49.0902237, lng: 21.1513171 },
    { lat: 49.0901298, lng: 21.1512615 },
    { lat: 49.0900169, lng: 21.1510023 },
    { lat: 49.0896853, lng: 21.150693 },
    { lat: 49.0888284, lng: 21.1503906 },
    { lat: 49.0880679, lng: 21.1498287 },
    { lat: 49.0874708, lng: 21.1497371 },
    { lat: 49.0870417, lng: 21.1498313 },
    { lat: 49.0868054, lng: 21.1498008 },
    { lat: 49.0860143, lng: 21.1499065 },
    { lat: 49.0854059, lng: 21.1501657 },
    { lat: 49.0851252, lng: 21.1501873 },
    { lat: 49.0852185, lng: 21.1494675 },
    { lat: 49.0847628, lng: 21.1493677 },
    { lat: 49.0848629, lng: 21.1492225 },
    { lat: 49.0847095, lng: 21.148962 },
    { lat: 49.0843574, lng: 21.1486122 },
    { lat: 49.0841423, lng: 21.148498 },
    { lat: 49.083447, lng: 21.1478966 },
    { lat: 49.0832035, lng: 21.1475946 },
    { lat: 49.0827193, lng: 21.1471946 },
    { lat: 49.0846161, lng: 21.1396707 },
    { lat: 49.0850946, lng: 21.1382577 },
    { lat: 49.0855897, lng: 21.1370864 },
    { lat: 49.0856597, lng: 21.1367043 },
    { lat: 49.0855758, lng: 21.1367507 },
    { lat: 49.0854483, lng: 21.1366714 },
    { lat: 49.0852987, lng: 21.1363187 },
    { lat: 49.08502, lng: 21.1363661 },
    { lat: 49.0848136, lng: 21.1364958 },
    { lat: 49.0846858, lng: 21.1363702 },
    { lat: 49.0846722, lng: 21.1359425 },
    { lat: 49.0845428, lng: 21.1359359 },
    { lat: 49.0843761, lng: 21.1360411 },
    { lat: 49.0842321, lng: 21.1359336 },
    { lat: 49.0840849, lng: 21.1353344 },
    { lat: 49.0840483, lng: 21.1353931 },
    { lat: 49.0840187, lng: 21.1352405 },
    { lat: 49.083963, lng: 21.1353279 },
    { lat: 49.0839411, lng: 21.1352775 },
    { lat: 49.0839307, lng: 21.1350535 },
    { lat: 49.0838639, lng: 21.1349694 },
    { lat: 49.0837129, lng: 21.1351395 },
    { lat: 49.0835375, lng: 21.1351467 },
    { lat: 49.0835556, lng: 21.1348334 },
    { lat: 49.0834437, lng: 21.1346729 },
    { lat: 49.0833333, lng: 21.1347018 },
    { lat: 49.08315, lng: 21.1342453 },
    { lat: 49.0828915, lng: 21.1340802 },
    { lat: 49.0828229, lng: 21.1339032 },
    { lat: 49.0826588, lng: 21.1337706 },
    { lat: 49.0826221, lng: 21.1334319 },
    { lat: 49.0824856, lng: 21.1331357 },
    { lat: 49.0822867, lng: 21.1336001 },
    { lat: 49.0822056, lng: 21.1335328 },
    { lat: 49.0821663, lng: 21.1329998 },
    { lat: 49.081968, lng: 21.1330308 },
    { lat: 49.0816183, lng: 21.1325407 },
    { lat: 49.0815112, lng: 21.1322858 },
    { lat: 49.0812916, lng: 21.1321361 },
    { lat: 49.0813024, lng: 21.1320154 },
    { lat: 49.0813575, lng: 21.131983 },
    { lat: 49.0813641, lng: 21.1315894 },
    { lat: 49.0811732, lng: 21.1316005 },
    { lat: 49.0810785, lng: 21.1314829 },
    { lat: 49.0810912, lng: 21.1317441 },
    { lat: 49.0810416, lng: 21.1318809 },
    { lat: 49.0810709, lng: 21.1319595 },
    { lat: 49.0809034, lng: 21.1318211 },
    { lat: 49.0807671, lng: 21.1315683 },
    { lat: 49.0805381, lng: 21.1313744 },
    { lat: 49.0805346, lng: 21.1313022 },
    { lat: 49.080684, lng: 21.1310406 },
    { lat: 49.0806848, lng: 21.1308568 },
    { lat: 49.0805789, lng: 21.1306416 },
    { lat: 49.0806181, lng: 21.1304948 },
    { lat: 49.0806045, lng: 21.1302736 },
    { lat: 49.0805258, lng: 21.1300981 },
    { lat: 49.0805847, lng: 21.1296489 },
    { lat: 49.0803939, lng: 21.1295582 },
    { lat: 49.0803171, lng: 21.1295828 },
    { lat: 49.0800869, lng: 21.1293532 },
    { lat: 49.0800492, lng: 21.129161 },
    { lat: 49.0800761, lng: 21.1288722 },
    { lat: 49.0799925, lng: 21.128859 },
    { lat: 49.0798416, lng: 21.1286834 },
    { lat: 49.0799867, lng: 21.1282911 },
    { lat: 49.0797623, lng: 21.1280653 },
    { lat: 49.0799068, lng: 21.1280773 },
    { lat: 49.079793, lng: 21.1276499 },
    { lat: 49.0797543, lng: 21.1275673 },
    { lat: 49.0796295, lng: 21.1275298 },
    { lat: 49.0793819, lng: 21.1271808 },
    { lat: 49.0791655, lng: 21.1264565 },
    { lat: 49.0790866, lng: 21.1263475 },
    { lat: 49.0786842, lng: 21.1263979 },
    { lat: 49.078554, lng: 21.1261275 },
    { lat: 49.0783269, lng: 21.1259579 },
    { lat: 49.0776353, lng: 21.1245217 },
    { lat: 49.077478, lng: 21.1238222 },
    { lat: 49.0769059, lng: 21.1224949 },
    { lat: 49.0764333, lng: 21.1216499 },
    { lat: 49.0759668, lng: 21.1205737 },
    { lat: 49.072999, lng: 21.1144546 },
    { lat: 49.0719286, lng: 21.112725 },
    { lat: 49.0718792, lng: 21.1126554 },
    { lat: 49.0712845, lng: 21.1133799 },
    { lat: 49.0699866, lng: 21.1142845 },
    { lat: 49.0690795, lng: 21.1148489 },
    { lat: 49.0676659, lng: 21.118308 },
    { lat: 49.06697, lng: 21.1194533 },
    { lat: 49.066839, lng: 21.1194428 },
    { lat: 49.06675, lng: 21.1201431 },
    { lat: 49.0659839, lng: 21.1219596 },
    { lat: 49.0656638, lng: 21.1224028 },
    { lat: 49.0646232, lng: 21.1231226 },
    { lat: 49.0644552, lng: 21.1234307 },
    { lat: 49.0643578, lng: 21.1238323 },
    { lat: 49.0640957, lng: 21.1260299 },
    { lat: 49.0640049, lng: 21.1265042 },
    { lat: 49.0637888, lng: 21.1269601 },
    { lat: 49.0635602, lng: 21.1276627 },
    { lat: 49.0633419, lng: 21.1273469 },
    { lat: 49.0630668, lng: 21.1271188 },
    { lat: 49.0630454, lng: 21.1270464 },
    { lat: 49.0623808, lng: 21.1268158 },
    { lat: 49.0616092, lng: 21.126719 },
    { lat: 49.0610359, lng: 21.1282239 },
    { lat: 49.0611369, lng: 21.1283597 },
    { lat: 49.061149, lng: 21.1286667 },
    { lat: 49.0610958, lng: 21.128803 },
    { lat: 49.0609155, lng: 21.1289307 },
    { lat: 49.0608301, lng: 21.1290788 },
    { lat: 49.0608037, lng: 21.1292052 },
    { lat: 49.0608434, lng: 21.129602 },
    { lat: 49.0607645, lng: 21.130234 },
    { lat: 49.0604493, lng: 21.1301643 },
    { lat: 49.060222, lng: 21.1306267 },
    { lat: 49.0602498, lng: 21.1307208 },
    { lat: 49.0606271, lng: 21.1311596 },
    { lat: 49.0603732, lng: 21.1319106 },
    { lat: 49.0598191, lng: 21.133102 },
    { lat: 49.0590329, lng: 21.134498 },
    { lat: 49.0587947, lng: 21.1341951 },
    { lat: 49.0577785, lng: 21.1362135 },
    { lat: 49.0570637, lng: 21.1360719 },
    { lat: 49.0565923, lng: 21.1371722 },
    { lat: 49.056191, lng: 21.13901 },
    { lat: 49.056179, lng: 21.139046 },
    { lat: 49.056844, lng: 21.140783 },
    { lat: 49.056875, lng: 21.14078 },
    { lat: 49.056899, lng: 21.140777 },
    { lat: 49.057996, lng: 21.14057 },
    { lat: 49.058041, lng: 21.140562 },
    { lat: 49.05826, lng: 21.141241 },
    { lat: 49.058713, lng: 21.143428 },
    { lat: 49.058812, lng: 21.143932 },
    { lat: 49.058892, lng: 21.144358 },
    { lat: 49.059011, lng: 21.14493 },
    { lat: 49.058544, lng: 21.146862 },
    { lat: 49.058558, lng: 21.146903 },
    { lat: 49.059682, lng: 21.147775 },
    { lat: 49.06077, lng: 21.148612 },
    { lat: 49.061509, lng: 21.149109 },
    { lat: 49.062161, lng: 21.149542 },
    { lat: 49.063115, lng: 21.149803 },
    { lat: 49.063141, lng: 21.149813 },
    { lat: 49.063172, lng: 21.149827 },
    { lat: 49.063195, lng: 21.149828 },
    { lat: 49.064301, lng: 21.150679 },
    { lat: 49.06443, lng: 21.150676 },
    { lat: 49.064478, lng: 21.150673 },
    { lat: 49.064621, lng: 21.150684 },
    { lat: 49.064856, lng: 21.150695 },
    { lat: 49.065087, lng: 21.150707 },
    { lat: 49.06516, lng: 21.150709 },
    { lat: 49.06517, lng: 21.150787 },
    { lat: 49.064963, lng: 21.152043 },
    { lat: 49.064814, lng: 21.153132 },
    { lat: 49.064772, lng: 21.153441 },
    { lat: 49.064675, lng: 21.154369 },
    { lat: 49.064588, lng: 21.155056 },
    { lat: 49.064517, lng: 21.156267 },
    { lat: 49.0645, lng: 21.156631 },
    { lat: 49.064402, lng: 21.158884 },
    { lat: 49.0644, lng: 21.15894 },
    { lat: 49.064395, lng: 21.159627 },
    { lat: 49.064357, lng: 21.160442 },
    { lat: 49.064302, lng: 21.161718 },
    { lat: 49.06424, lng: 21.163026 },
    { lat: 49.064063, lng: 21.167273 },
    { lat: 49.064059, lng: 21.167385 },
    { lat: 49.064055, lng: 21.167442 },
    { lat: 49.064028, lng: 21.168098 },
    { lat: 49.064005, lng: 21.16876 },
    { lat: 49.063963, lng: 21.169258 },
    { lat: 49.06396, lng: 21.169448 },
    { lat: 49.063933, lng: 21.170326 },
    { lat: 49.063773, lng: 21.170393 },
    { lat: 49.063729, lng: 21.171509 },
    { lat: 49.063878, lng: 21.171561 },
    { lat: 49.063799, lng: 21.173383 },
    { lat: 49.063717, lng: 21.174958 },
    { lat: 49.063703, lng: 21.175348 },
    { lat: 49.063685, lng: 21.175836 },
    { lat: 49.06364, lng: 21.176871 },
    { lat: 49.063598, lng: 21.177812 },
    { lat: 49.063594, lng: 21.177848 },
    { lat: 49.063585, lng: 21.177936 },
    { lat: 49.063628, lng: 21.177934 },
    { lat: 49.063649, lng: 21.177933 },
    { lat: 49.06367, lng: 21.177932 },
    { lat: 49.06371, lng: 21.177931 },
    { lat: 49.064031, lng: 21.177893 },
    { lat: 49.064895, lng: 21.177044 },
    { lat: 49.06522, lng: 21.176726 },
    { lat: 49.065477, lng: 21.176453 },
    { lat: 49.065536, lng: 21.176386 },
    { lat: 49.065815, lng: 21.176126 },
    { lat: 49.066071, lng: 21.175887 },
    { lat: 49.066183, lng: 21.17578 },
    { lat: 49.06636, lng: 21.175604 },
    { lat: 49.06679, lng: 21.175312 },
    { lat: 49.066844, lng: 21.175254 },
    { lat: 49.066906, lng: 21.175149 },
    { lat: 49.067384, lng: 21.17369 },
    { lat: 49.06741, lng: 21.173528 },
    { lat: 49.067422, lng: 21.173385 },
    { lat: 49.067439, lng: 21.173315 },
    { lat: 49.067479, lng: 21.173214 },
    { lat: 49.067564, lng: 21.172975 },
    { lat: 49.067585, lng: 21.172845 },
    { lat: 49.067586, lng: 21.172793 },
    { lat: 49.067559, lng: 21.172494 },
    { lat: 49.06757, lng: 21.172286 },
    { lat: 49.067583, lng: 21.172228 },
    { lat: 49.067625, lng: 21.172185 },
    { lat: 49.06786, lng: 21.172079 },
    { lat: 49.068032, lng: 21.171979 },
    { lat: 49.068069, lng: 21.171977 },
    { lat: 49.068106, lng: 21.171995 },
    { lat: 49.068172, lng: 21.172052 },
    { lat: 49.068226, lng: 21.172083 },
    { lat: 49.068262, lng: 21.172081 },
    { lat: 49.068277, lng: 21.172058 },
    { lat: 49.068291, lng: 21.17201 },
    { lat: 49.068307, lng: 21.171895 },
    { lat: 49.06833, lng: 21.171791 },
    { lat: 49.068333, lng: 21.171708 },
    { lat: 49.068317, lng: 21.171602 },
    { lat: 49.068221, lng: 21.171401 },
    { lat: 49.068179, lng: 21.171375 },
    { lat: 49.068151, lng: 21.171335 },
    { lat: 49.068118, lng: 21.171213 },
    { lat: 49.068093, lng: 21.17106 },
    { lat: 49.068104, lng: 21.170989 },
    { lat: 49.068128, lng: 21.170936 },
    { lat: 49.068171, lng: 21.170911 },
    { lat: 49.068213, lng: 21.170915 },
    { lat: 49.068425, lng: 21.171014 },
    { lat: 49.06889, lng: 21.17113 },
    { lat: 49.068924, lng: 21.171118 },
    { lat: 49.069214, lng: 21.17089 },
    { lat: 49.069365, lng: 21.170608 },
    { lat: 49.069381, lng: 21.170465 },
    { lat: 49.069395, lng: 21.170426 },
    { lat: 49.069415, lng: 21.170393 },
    { lat: 49.069461, lng: 21.17038 },
    { lat: 49.06958, lng: 21.170398 },
    { lat: 49.069715, lng: 21.170449 },
    { lat: 49.069855, lng: 21.170557 },
    { lat: 49.069907, lng: 21.170575 },
    { lat: 49.069941, lng: 21.170558 },
    { lat: 49.069964, lng: 21.1705 },
    { lat: 49.069966, lng: 21.17045 },
    { lat: 49.069975, lng: 21.170411 },
    { lat: 49.069998, lng: 21.170395 },
    { lat: 49.070068, lng: 21.170423 },
    { lat: 49.0700898, lng: 21.1704106 },
    { lat: 49.070105, lng: 21.170402 },
    { lat: 49.070218, lng: 21.170198 },
    { lat: 49.070264, lng: 21.170148 },
    { lat: 49.070304, lng: 21.170142 },
    { lat: 49.070439, lng: 21.17024 },
    { lat: 49.070791, lng: 21.170454 },
    { lat: 49.070886, lng: 21.170505 },
    { lat: 49.070929, lng: 21.170512 },
    { lat: 49.071089, lng: 21.170367 },
    { lat: 49.071156, lng: 21.17038 },
    { lat: 49.071306, lng: 21.170341 },
    { lat: 49.071361, lng: 21.170407 },
    { lat: 49.071397, lng: 21.170447 },
    { lat: 49.071436, lng: 21.170464 },
    { lat: 49.071552, lng: 21.170395 },
    { lat: 49.071615, lng: 21.17032 },
    { lat: 49.071788, lng: 21.170079 },
    { lat: 49.072041, lng: 21.16988 },
    { lat: 49.072109, lng: 21.169891 },
    { lat: 49.072156, lng: 21.169883 },
    { lat: 49.072202, lng: 21.169853 },
    { lat: 49.072243, lng: 21.16977 },
    { lat: 49.072249, lng: 21.169707 },
    { lat: 49.072276, lng: 21.169649 },
    { lat: 49.072321, lng: 21.169592 },
    { lat: 49.072394, lng: 21.169544 },
    { lat: 49.0725, lng: 21.169489 },
    { lat: 49.072602, lng: 21.169467 },
    { lat: 49.072715, lng: 21.169397 },
    { lat: 49.072751, lng: 21.169338 },
    { lat: 49.072762, lng: 21.169347 },
    { lat: 49.072822, lng: 21.169415 },
    { lat: 49.072845, lng: 21.16945 },
    { lat: 49.073813, lng: 21.171639 },
    { lat: 49.073836, lng: 21.171698 },
    { lat: 49.07389, lng: 21.171878 },
    { lat: 49.075048, lng: 21.174036 },
    { lat: 49.075365, lng: 21.174647 },
    { lat: 49.075392, lng: 21.174727 },
    { lat: 49.07593, lng: 21.17399 },
    { lat: 49.076002, lng: 21.173891 },
    { lat: 49.076172, lng: 21.173625 },
    { lat: 49.076235, lng: 21.173539 },
    { lat: 49.076413, lng: 21.173373 },
    { lat: 49.076458, lng: 21.173279 },
    { lat: 49.076578, lng: 21.173128 },
    { lat: 49.076659, lng: 21.173046 },
    { lat: 49.076716, lng: 21.172998 },
    { lat: 49.0768, lng: 21.172885 },
    { lat: 49.076848, lng: 21.172805 },
    { lat: 49.076873, lng: 21.172732 },
    { lat: 49.076894, lng: 21.172609 },
    { lat: 49.076923, lng: 21.172496 },
    { lat: 49.076967, lng: 21.172424 },
    { lat: 49.077034, lng: 21.172316 },
    { lat: 49.077105, lng: 21.1722 },
    { lat: 49.077154, lng: 21.172129 },
    { lat: 49.077209, lng: 21.172029 },
    { lat: 49.077277, lng: 21.171894 },
    { lat: 49.077301, lng: 21.171792 },
    { lat: 49.077319, lng: 21.171697 },
    { lat: 49.077358, lng: 21.171544 },
    { lat: 49.077374, lng: 21.17145 },
    { lat: 49.077397, lng: 21.17137 },
    { lat: 49.077445, lng: 21.171247 },
    { lat: 49.077544, lng: 21.171108 },
    { lat: 49.077645, lng: 21.170989 },
    { lat: 49.077863, lng: 21.17081 },
    { lat: 49.077927, lng: 21.170724 },
    { lat: 49.078002, lng: 21.170639 },
    { lat: 49.078149, lng: 21.170481 },
    { lat: 49.078303, lng: 21.170251 },
    { lat: 49.078623, lng: 21.169866 },
    { lat: 49.078663, lng: 21.169685 },
    { lat: 49.078766, lng: 21.169525 },
    { lat: 49.078845, lng: 21.169471 },
    { lat: 49.078919, lng: 21.169339 },
    { lat: 49.07916, lng: 21.168915 },
    { lat: 49.079633, lng: 21.16791 },
    { lat: 49.079901, lng: 21.167288 },
    { lat: 49.080608, lng: 21.166304 },
    { lat: 49.081319, lng: 21.166006 },
    { lat: 49.08178, lng: 21.165649 },
    { lat: 49.081954, lng: 21.165576 },
    { lat: 49.082852, lng: 21.165784 },
    { lat: 49.082895, lng: 21.16574 },
    { lat: 49.08301, lng: 21.165665 },
    { lat: 49.083057, lng: 21.165504 },
    { lat: 49.083062, lng: 21.165303 },
    { lat: 49.084249, lng: 21.165271 },
    { lat: 49.085004, lng: 21.164995 },
    { lat: 49.0849767, lng: 21.1647214 },
    { lat: 49.0842661, lng: 21.1648812 },
    { lat: 49.0834072, lng: 21.1646251 },
    { lat: 49.0849282, lng: 21.1615681 },
    { lat: 49.0862498, lng: 21.162622 },
    { lat: 49.0865686, lng: 21.1620954 },
    { lat: 49.0869209, lng: 21.1613182 },
    { lat: 49.0872074, lng: 21.1609796 },
    { lat: 49.0873989, lng: 21.1606576 },
    { lat: 49.0876283, lng: 21.160154 },
    { lat: 49.0876134, lng: 21.158291 },
    { lat: 49.0878385, lng: 21.1577561 },
    { lat: 49.0880769, lng: 21.1573592 },
    { lat: 49.0882758, lng: 21.1566653 },
    { lat: 49.0883564, lng: 21.1561184 },
    { lat: 49.0884865, lng: 21.1557509 },
    { lat: 49.0891878, lng: 21.1549345 },
    { lat: 49.0892075, lng: 21.1547374 },
    { lat: 49.0891355, lng: 21.1538519 },
    { lat: 49.0899703, lng: 21.1516707 },
    { lat: 49.0900141, lng: 21.1515572 },
  ],
  Ražňany: [
    { lat: 49.0690795, lng: 21.1148489 },
    { lat: 49.0699866, lng: 21.1142845 },
    { lat: 49.0712845, lng: 21.1133799 },
    { lat: 49.0718792, lng: 21.1126554 },
    { lat: 49.070992, lng: 21.1114135 },
    { lat: 49.0710686, lng: 21.1106716 },
    { lat: 49.07134, lng: 21.1105399 },
    { lat: 49.0715659, lng: 21.1101765 },
    { lat: 49.0719031, lng: 21.1094595 },
    { lat: 49.0723109, lng: 21.1088059 },
    { lat: 49.0725576, lng: 21.1081408 },
    { lat: 49.073144, lng: 21.1071954 },
    { lat: 49.0731033, lng: 21.1069382 },
    { lat: 49.0734912, lng: 21.1066141 },
    { lat: 49.0736991, lng: 21.1065674 },
    { lat: 49.0738792, lng: 21.1063222 },
    { lat: 49.0740856, lng: 21.1062269 },
    { lat: 49.0742884, lng: 21.1062356 },
    { lat: 49.0746538, lng: 21.1060534 },
    { lat: 49.0747885, lng: 21.1058995 },
    { lat: 49.0749302, lng: 21.1058639 },
    { lat: 49.0751831, lng: 21.105674 },
    { lat: 49.0754469, lng: 21.1057973 },
    { lat: 49.0758944, lng: 21.1053599 },
    { lat: 49.0767063, lng: 21.1048482 },
    { lat: 49.0770515, lng: 21.1042813 },
    { lat: 49.0771537, lng: 21.1043416 },
    { lat: 49.0777566, lng: 21.1038015 },
    { lat: 49.0779138, lng: 21.1039001 },
    { lat: 49.0780276, lng: 21.1038314 },
    { lat: 49.0781831, lng: 21.1035556 },
    { lat: 49.0783947, lng: 21.1034897 },
    { lat: 49.0786131, lng: 21.1033152 },
    { lat: 49.0787114, lng: 21.1033646 },
    { lat: 49.07875, lng: 21.1035843 },
    { lat: 49.0792048, lng: 21.1035894 },
    { lat: 49.0794212, lng: 21.1035256 },
    { lat: 49.079542, lng: 21.1038591 },
    { lat: 49.0799626, lng: 21.1043722 },
    { lat: 49.0823614, lng: 21.1037248 },
    { lat: 49.0847146, lng: 21.1017628 },
    { lat: 49.0851442, lng: 21.1024537 },
    { lat: 49.0855978, lng: 21.1028782 },
    { lat: 49.0857639, lng: 21.1026533 },
    { lat: 49.0860623, lng: 21.1024858 },
    { lat: 49.0857942, lng: 21.1017356 },
    { lat: 49.0860277, lng: 21.1014342 },
    { lat: 49.0864368, lng: 21.1007192 },
    { lat: 49.0864631, lng: 21.1006648 },
    { lat: 49.0862643, lng: 21.100672 },
    { lat: 49.0861565, lng: 21.1006115 },
    { lat: 49.0861196, lng: 21.1004071 },
    { lat: 49.0862184, lng: 21.1001207 },
    { lat: 49.0864063, lng: 21.0998307 },
    { lat: 49.086321, lng: 21.0995232 },
    { lat: 49.0860495, lng: 21.0991693 },
    { lat: 49.0857507, lng: 21.0991828 },
    { lat: 49.085686, lng: 21.0994095 },
    { lat: 49.0855913, lng: 21.0994058 },
    { lat: 49.085546, lng: 21.0992565 },
    { lat: 49.0855296, lng: 21.0988183 },
    { lat: 49.0853914, lng: 21.0986778 },
    { lat: 49.0853985, lng: 21.0979872 },
    { lat: 49.0850491, lng: 21.0974071 },
    { lat: 49.0850581, lng: 21.0968133 },
    { lat: 49.0848905, lng: 21.096675 },
    { lat: 49.0848394, lng: 21.0965606 },
    { lat: 49.0847953, lng: 21.0961988 },
    { lat: 49.0848396, lng: 21.0959636 },
    { lat: 49.0847782, lng: 21.0958048 },
    { lat: 49.0848979, lng: 21.0952196 },
    { lat: 49.0849671, lng: 21.0950982 },
    { lat: 49.0847367, lng: 21.0947886 },
    { lat: 49.0843515, lng: 21.0946398 },
    { lat: 49.0843928, lng: 21.0943449 },
    { lat: 49.0843214, lng: 21.0941574 },
    { lat: 49.0844322, lng: 21.0936842 },
    { lat: 49.0846949, lng: 21.0936988 },
    { lat: 49.0850085, lng: 21.093897 },
    { lat: 49.085143, lng: 21.0937743 },
    { lat: 49.0850988, lng: 21.0932412 },
    { lat: 49.0852037, lng: 21.0929325 },
    { lat: 49.0852087, lng: 21.0925229 },
    { lat: 49.0853419, lng: 21.0923589 },
    { lat: 49.0852036, lng: 21.0920547 },
    { lat: 49.0851558, lng: 21.0918202 },
    { lat: 49.085136, lng: 21.0916293 },
    { lat: 49.0852367, lng: 21.0913647 },
    { lat: 49.0851693, lng: 21.091295 },
    { lat: 49.0860392, lng: 21.0904189 },
    { lat: 49.0871942, lng: 21.089132 },
    { lat: 49.0889824, lng: 21.0865753 },
    { lat: 49.0901297, lng: 21.0844195 },
    { lat: 49.0912495, lng: 21.0834089 },
    { lat: 49.0917283, lng: 21.0828325 },
    { lat: 49.0924927, lng: 21.081546 },
    { lat: 49.093058, lng: 21.0807838 },
    { lat: 49.093161, lng: 21.0809453 },
    { lat: 49.0933758, lng: 21.0807296 },
    { lat: 49.0946431, lng: 21.0797858 },
    { lat: 49.0950054, lng: 21.079437 },
    { lat: 49.0957282, lng: 21.0791005 },
    { lat: 49.0968705, lng: 21.0784011 },
    { lat: 49.0978269, lng: 21.0775217 },
    { lat: 49.0980857, lng: 21.0774126 },
    { lat: 49.0983676, lng: 21.0769032 },
    { lat: 49.099182, lng: 21.0758088 },
    { lat: 49.1001854, lng: 21.075032 },
    { lat: 49.1004288, lng: 21.0746238 },
    { lat: 49.1007949, lng: 21.0738286 },
    { lat: 49.1011462, lng: 21.0735201 },
    { lat: 49.1012691, lng: 21.0730361 },
    { lat: 49.1015023, lng: 21.072662 },
    { lat: 49.1022333, lng: 21.0711492 },
    { lat: 49.1028137, lng: 21.0701928 },
    { lat: 49.103006, lng: 21.0695959 },
    { lat: 49.1033082, lng: 21.0692698 },
    { lat: 49.1037304, lng: 21.069097 },
    { lat: 49.1041109, lng: 21.0690348 },
    { lat: 49.1061749, lng: 21.0683354 },
    { lat: 49.1062918, lng: 21.0674262 },
    { lat: 49.1065372, lng: 21.067044 },
    { lat: 49.1070014, lng: 21.0665369 },
    { lat: 49.1069385, lng: 21.0654568 },
    { lat: 49.1067208, lng: 21.06398 },
    { lat: 49.1069688, lng: 21.0625688 },
    { lat: 49.1076972, lng: 21.0612829 },
    { lat: 49.1079608, lng: 21.0603924 },
    { lat: 49.1081473, lng: 21.060099 },
    { lat: 49.1087194, lng: 21.0585108 },
    { lat: 49.1089995, lng: 21.0569775 },
    { lat: 49.1090886, lng: 21.0558766 },
    { lat: 49.1087384, lng: 21.0538149 },
    { lat: 49.1084917, lng: 21.0532903 },
    { lat: 49.1082364, lng: 21.0515533 },
    { lat: 49.1082594, lng: 21.0507707 },
    { lat: 49.1083396, lng: 21.0503663 },
    { lat: 49.1082747, lng: 21.0500662 },
    { lat: 49.1088388, lng: 21.0485812 },
    { lat: 49.1090454, lng: 21.0472826 },
    { lat: 49.1089984, lng: 21.0467326 },
    { lat: 49.1090184, lng: 21.0462759 },
    { lat: 49.1084997, lng: 21.0446867 },
    { lat: 49.1084795, lng: 21.0439271 },
    { lat: 49.1080633, lng: 21.0436147 },
    { lat: 49.1079214, lng: 21.0436178 },
    { lat: 49.1075647, lng: 21.0434479 },
    { lat: 49.1072393, lng: 21.0434404 },
    { lat: 49.1069231, lng: 21.0432311 },
    { lat: 49.106563, lng: 21.0431968 },
    { lat: 49.1059964, lng: 21.0433214 },
    { lat: 49.1055902, lng: 21.0432525 },
    { lat: 49.1049167, lng: 21.0436901 },
    { lat: 49.1044611, lng: 21.0441106 },
    { lat: 49.1039631, lng: 21.0447152 },
    { lat: 49.1029359, lng: 21.0454897 },
    { lat: 49.1027183, lng: 21.0458236 },
    { lat: 49.1024262, lng: 21.0464594 },
    { lat: 49.1023164, lng: 21.0468621 },
    { lat: 49.1021433, lng: 21.0471459 },
    { lat: 49.1020444, lng: 21.0474277 },
    { lat: 49.102031, lng: 21.0476029 },
    { lat: 49.1018414, lng: 21.0480029 },
    { lat: 49.1015175, lng: 21.0484576 },
    { lat: 49.1009551, lng: 21.0500292 },
    { lat: 49.1007283, lng: 21.0502556 },
    { lat: 49.1005272, lng: 21.0510278 },
    { lat: 49.1003097, lng: 21.0513308 },
    { lat: 49.099927, lng: 21.0537924 },
    { lat: 49.0998067, lng: 21.0541487 },
    { lat: 49.0998432, lng: 21.0544387 },
    { lat: 49.0996294, lng: 21.0549586 },
    { lat: 49.0996272, lng: 21.0553779 },
    { lat: 49.0996813, lng: 21.0556299 },
    { lat: 49.0995763, lng: 21.0559607 },
    { lat: 49.0995883, lng: 21.0562291 },
    { lat: 49.0994861, lng: 21.056963 },
    { lat: 49.0994893, lng: 21.0575506 },
    { lat: 49.0993355, lng: 21.0581526 },
    { lat: 49.0992045, lng: 21.0582458 },
    { lat: 49.0991082, lng: 21.0584688 },
    { lat: 49.0989507, lng: 21.0585726 },
    { lat: 49.0989361, lng: 21.0588847 },
    { lat: 49.0987432, lng: 21.0589814 },
    { lat: 49.0984171, lng: 21.059296 },
    { lat: 49.0983728, lng: 21.0596039 },
    { lat: 49.0982587, lng: 21.0598297 },
    { lat: 49.0982276, lng: 21.0600131 },
    { lat: 49.0980455, lng: 21.060165 },
    { lat: 49.098055, lng: 21.0606251 },
    { lat: 49.0978372, lng: 21.0607164 },
    { lat: 49.0975929, lng: 21.0609563 },
    { lat: 49.0974204, lng: 21.0612964 },
    { lat: 49.097475, lng: 21.0616224 },
    { lat: 49.0973613, lng: 21.0621096 },
    { lat: 49.097143, lng: 21.0626015 },
    { lat: 49.096879, lng: 21.0630651 },
    { lat: 49.0967881, lng: 21.0631125 },
    { lat: 49.0956065, lng: 21.065267 },
    { lat: 49.0951929, lng: 21.0658823 },
    { lat: 49.0949087, lng: 21.0662763 },
    { lat: 49.0923949, lng: 21.0688338 },
    { lat: 49.0919404, lng: 21.0692563 },
    { lat: 49.0916297, lng: 21.0693934 },
    { lat: 49.0913596, lng: 21.068103 },
    { lat: 49.0899423, lng: 21.0686162 },
    { lat: 49.0894983, lng: 21.0688372 },
    { lat: 49.0894276, lng: 21.0684278 },
    { lat: 49.0894822, lng: 21.0682715 },
    { lat: 49.0894428, lng: 21.0679204 },
    { lat: 49.0893347, lng: 21.0677407 },
    { lat: 49.0889615, lng: 21.0677909 },
    { lat: 49.0889803, lng: 21.066071 },
    { lat: 49.0890761, lng: 21.0655899 },
    { lat: 49.0890746, lng: 21.0650909 },
    { lat: 49.0890216, lng: 21.064599 },
    { lat: 49.0889535, lng: 21.0644468 },
    { lat: 49.0889517, lng: 21.0641425 },
    { lat: 49.0890234, lng: 21.0638585 },
    { lat: 49.0889466, lng: 21.0631536 },
    { lat: 49.0881888, lng: 21.063033 },
    { lat: 49.0880728, lng: 21.0627857 },
    { lat: 49.0881881, lng: 21.062422 },
    { lat: 49.087872, lng: 21.0620396 },
    { lat: 49.0870506, lng: 21.060135 },
    { lat: 49.0869026, lng: 21.0601806 },
    { lat: 49.0868431, lng: 21.06005 },
    { lat: 49.0869625, lng: 21.0598472 },
    { lat: 49.0871243, lng: 21.059839 },
    { lat: 49.087567, lng: 21.0594345 },
    { lat: 49.0877566, lng: 21.0589614 },
    { lat: 49.0877512, lng: 21.0586559 },
    { lat: 49.0874954, lng: 21.058227 },
    { lat: 49.0874061, lng: 21.0579012 },
    { lat: 49.0870814, lng: 21.0575393 },
    { lat: 49.0868175, lng: 21.0575239 },
    { lat: 49.0865968, lng: 21.0573422 },
    { lat: 49.086367, lng: 21.0573031 },
    { lat: 49.086115, lng: 21.0571296 },
    { lat: 49.086062, lng: 21.0569232 },
    { lat: 49.0859626, lng: 21.0570132 },
    { lat: 49.0858012, lng: 21.0569575 },
    { lat: 49.0857184, lng: 21.05722 },
    { lat: 49.0852786, lng: 21.0570893 },
    { lat: 49.0842499, lng: 21.0571906 },
    { lat: 49.0840473, lng: 21.0574294 },
    { lat: 49.0837416, lng: 21.0574793 },
    { lat: 49.083511, lng: 21.0576919 },
    { lat: 49.0831716, lng: 21.0577774 },
    { lat: 49.0830272, lng: 21.0579716 },
    { lat: 49.0828627, lng: 21.057896 },
    { lat: 49.0828187, lng: 21.0576868 },
    { lat: 49.0825461, lng: 21.0574046 },
    { lat: 49.0814417, lng: 21.0564499 },
    { lat: 49.0811849, lng: 21.0563392 },
    { lat: 49.0809621, lng: 21.0559553 },
    { lat: 49.0809323, lng: 21.0557992 },
    { lat: 49.080901, lng: 21.0556705 },
    { lat: 49.0807361, lng: 21.0556553 },
    { lat: 49.0801845, lng: 21.0563091 },
    { lat: 49.0799554, lng: 21.0562556 },
    { lat: 49.0799426, lng: 21.0565168 },
    { lat: 49.0789002, lng: 21.0573443 },
    { lat: 49.0785633, lng: 21.0582656 },
    { lat: 49.0783055, lng: 21.0585416 },
    { lat: 49.0780994, lng: 21.058579 },
    { lat: 49.0777223, lng: 21.0589555 },
    { lat: 49.0755428, lng: 21.0623737 },
    { lat: 49.0745389, lng: 21.0632789 },
    { lat: 49.0743977, lng: 21.0632948 },
    { lat: 49.0732963, lng: 21.0650159 },
    { lat: 49.0729661, lng: 21.0662169 },
    { lat: 49.0730203, lng: 21.0666109 },
    { lat: 49.0729168, lng: 21.0669532 },
    { lat: 49.0727385, lng: 21.0683331 },
    { lat: 49.0728013, lng: 21.0687824 },
    { lat: 49.0727941, lng: 21.069012 },
    { lat: 49.072748, lng: 21.0691177 },
    { lat: 49.073035, lng: 21.0699585 },
    { lat: 49.072609, lng: 21.0704577 },
    { lat: 49.0722165, lng: 21.0706762 },
    { lat: 49.0715322, lng: 21.0709187 },
    { lat: 49.0712976, lng: 21.0711103 },
    { lat: 49.0709557, lng: 21.0711541 },
    { lat: 49.0701782, lng: 21.0716451 },
    { lat: 49.0697957, lng: 21.0719684 },
    { lat: 49.0696188, lng: 21.0722723 },
    { lat: 49.0694207, lng: 21.0724494 },
    { lat: 49.0692132, lng: 21.0727491 },
    { lat: 49.0689135, lng: 21.0728727 },
    { lat: 49.0685626, lng: 21.0732335 },
    { lat: 49.0674792, lng: 21.0735538 },
    { lat: 49.0664082, lng: 21.0740917 },
    { lat: 49.0660156, lng: 21.0742015 },
    { lat: 49.0654387, lng: 21.0744943 },
    { lat: 49.0646205, lng: 21.074737 },
    { lat: 49.0644646, lng: 21.0748244 },
    { lat: 49.0643405, lng: 21.0750569 },
    { lat: 49.064071, lng: 21.0752862 },
    { lat: 49.0638874, lng: 21.0753843 },
    { lat: 49.063673, lng: 21.0752675 },
    { lat: 49.0629641, lng: 21.075932 },
    { lat: 49.0628349, lng: 21.0759685 },
    { lat: 49.0624964, lng: 21.0759066 },
    { lat: 49.0623079, lng: 21.0760653 },
    { lat: 49.0620461, lng: 21.0764311 },
    { lat: 49.0617918, lng: 21.0769561 },
    { lat: 49.0613218, lng: 21.0771387 },
    { lat: 49.0608079, lng: 21.0774289 },
    { lat: 49.0603698, lng: 21.0774914 },
    { lat: 49.0603471, lng: 21.0776646 },
    { lat: 49.0602186, lng: 21.0777959 },
    { lat: 49.0599783, lng: 21.0777079 },
    { lat: 49.0596026, lng: 21.0777439 },
    { lat: 49.0594076, lng: 21.0779446 },
    { lat: 49.0592566, lng: 21.0779782 },
    { lat: 49.0581285, lng: 21.0779575 },
    { lat: 49.0578644, lng: 21.078012 },
    { lat: 49.0577594, lng: 21.0781031 },
    { lat: 49.0577513, lng: 21.0782333 },
    { lat: 49.0569693, lng: 21.0782044 },
    { lat: 49.0568592, lng: 21.0782783 },
    { lat: 49.0567884, lng: 21.0783212 },
    { lat: 49.0568247, lng: 21.0785679 },
    { lat: 49.0570688, lng: 21.0787758 },
    { lat: 49.0574067, lng: 21.0794535 },
    { lat: 49.0583708, lng: 21.0817116 },
    { lat: 49.0591132, lng: 21.0833464 },
    { lat: 49.0594219, lng: 21.0839101 },
    { lat: 49.0596185, lng: 21.0840096 },
    { lat: 49.0597634, lng: 21.084386 },
    { lat: 49.0610345, lng: 21.0861172 },
    { lat: 49.0615301, lng: 21.0869 },
    { lat: 49.0624608, lng: 21.0880186 },
    { lat: 49.0631655, lng: 21.0889685 },
    { lat: 49.0635434, lng: 21.0898095 },
    { lat: 49.0638923, lng: 21.0902975 },
    { lat: 49.0639356, lng: 21.0905215 },
    { lat: 49.064072, lng: 21.0908095 },
    { lat: 49.0645116, lng: 21.0911501 },
    { lat: 49.0646085, lng: 21.0912965 },
    { lat: 49.0646003, lng: 21.0920533 },
    { lat: 49.0647265, lng: 21.0928259 },
    { lat: 49.064966, lng: 21.0931031 },
    { lat: 49.0650124, lng: 21.0933584 },
    { lat: 49.0650777, lng: 21.09343 },
    { lat: 49.0655465, lng: 21.0937993 },
    { lat: 49.065704, lng: 21.094009 },
    { lat: 49.0659097, lng: 21.0939679 },
    { lat: 49.0660463, lng: 21.0943683 },
    { lat: 49.0661097, lng: 21.0944103 },
    { lat: 49.0662873, lng: 21.0947563 },
    { lat: 49.0664362, lng: 21.0952219 },
    { lat: 49.0665645, lng: 21.0962093 },
    { lat: 49.0665345, lng: 21.096957 },
    { lat: 49.0666229, lng: 21.0980609 },
    { lat: 49.0666169, lng: 21.0988968 },
    { lat: 49.0665027, lng: 21.0995789 },
    { lat: 49.0664674, lng: 21.1000948 },
    { lat: 49.0663097, lng: 21.1005827 },
    { lat: 49.0663045, lng: 21.1007367 },
    { lat: 49.0662197, lng: 21.1009687 },
    { lat: 49.0662347, lng: 21.1010487 },
    { lat: 49.0661104, lng: 21.1012087 },
    { lat: 49.0659706, lng: 21.1022207 },
    { lat: 49.0659905, lng: 21.1025239 },
    { lat: 49.0659152, lng: 21.1040121 },
    { lat: 49.0658783, lng: 21.1041189 },
    { lat: 49.0659534, lng: 21.1042906 },
    { lat: 49.0659383, lng: 21.1047608 },
    { lat: 49.0660055, lng: 21.1048585 },
    { lat: 49.0660293, lng: 21.105357 },
    { lat: 49.0659925, lng: 21.1056533 },
    { lat: 49.0658917, lng: 21.105804 },
    { lat: 49.0658964, lng: 21.1069761 },
    { lat: 49.0658038, lng: 21.1078787 },
    { lat: 49.0658595, lng: 21.1094828 },
    { lat: 49.0658924, lng: 21.1097007 },
    { lat: 49.0660702, lng: 21.1099819 },
    { lat: 49.0662454, lng: 21.1094395 },
    { lat: 49.0664851, lng: 21.1089861 },
    { lat: 49.0665702, lng: 21.1091119 },
    { lat: 49.0668961, lng: 21.1106838 },
    { lat: 49.067244, lng: 21.1113934 },
    { lat: 49.0673851, lng: 21.1118334 },
    { lat: 49.0674232, lng: 21.1122139 },
    { lat: 49.0674914, lng: 21.1129755 },
    { lat: 49.0674692, lng: 21.1133371 },
    { lat: 49.0678429, lng: 21.1137446 },
    { lat: 49.0679402, lng: 21.1138482 },
    { lat: 49.0680214, lng: 21.1139895 },
    { lat: 49.0681128, lng: 21.1141441 },
    { lat: 49.0681892, lng: 21.1141926 },
    { lat: 49.0690795, lng: 21.1148489 },
  ],
  Ratvaj: [
    { lat: 49.11382, lng: 21.21246 },
    { lat: 49.114053, lng: 21.212719 },
    { lat: 49.114072, lng: 21.212748 },
    { lat: 49.114411, lng: 21.212351 },
    { lat: 49.114827, lng: 21.211735 },
    { lat: 49.115143, lng: 21.211432 },
    { lat: 49.115512, lng: 21.211286 },
    { lat: 49.115811, lng: 21.211258 },
    { lat: 49.115897, lng: 21.21126 },
    { lat: 49.11616, lng: 21.211042 },
    { lat: 49.116188, lng: 21.211027 },
    { lat: 49.116462, lng: 21.210784 },
    { lat: 49.117041, lng: 21.209815 },
    { lat: 49.117121, lng: 21.20969 },
    { lat: 49.117265, lng: 21.209513 },
    { lat: 49.117489, lng: 21.209345 },
    { lat: 49.118096, lng: 21.209017 },
    { lat: 49.119012, lng: 21.20842 },
    { lat: 49.119621, lng: 21.208409 },
    { lat: 49.119905, lng: 21.20834 },
    { lat: 49.120311, lng: 21.208183 },
    { lat: 49.120762, lng: 21.208123 },
    { lat: 49.120959, lng: 21.208103 },
    { lat: 49.121089, lng: 21.208055 },
    { lat: 49.121281, lng: 21.208029 },
    { lat: 49.121784, lng: 21.208095 },
    { lat: 49.122192, lng: 21.208106 },
    { lat: 49.122583, lng: 21.207814 },
    { lat: 49.122976, lng: 21.207797 },
    { lat: 49.123053, lng: 21.207759 },
    { lat: 49.123257, lng: 21.207651 },
    { lat: 49.123392, lng: 21.207529 },
    { lat: 49.123553, lng: 21.207293 },
    { lat: 49.123652, lng: 21.207186 },
    { lat: 49.123803, lng: 21.207109 },
    { lat: 49.1241, lng: 21.2071 },
    { lat: 49.124357, lng: 21.206978 },
    { lat: 49.124515, lng: 21.206839 },
    { lat: 49.124649, lng: 21.206352 },
    { lat: 49.124737, lng: 21.205857 },
    { lat: 49.12501, lng: 21.205115 },
    { lat: 49.125173, lng: 21.204984 },
    { lat: 49.125529, lng: 21.204852 },
    { lat: 49.125659, lng: 21.204774 },
    { lat: 49.125994, lng: 21.204585 },
    { lat: 49.126193, lng: 21.204547 },
    { lat: 49.126469, lng: 21.204449 },
    { lat: 49.126933, lng: 21.204336 },
    { lat: 49.127107, lng: 21.204249 },
    { lat: 49.127335, lng: 21.204162 },
    { lat: 49.127528, lng: 21.204093 },
    { lat: 49.127826, lng: 21.203921 },
    { lat: 49.128302, lng: 21.203206 },
    { lat: 49.128498, lng: 21.202713 },
    { lat: 49.128699, lng: 21.202366 },
    { lat: 49.128917, lng: 21.202092 },
    { lat: 49.129385, lng: 21.20161 },
    { lat: 49.12978, lng: 21.20125 },
    { lat: 49.130034, lng: 21.201297 },
    { lat: 49.130172, lng: 21.201207 },
    { lat: 49.130425, lng: 21.201135 },
    { lat: 49.130565, lng: 21.201051 },
    { lat: 49.130717, lng: 21.201085 },
    { lat: 49.130841, lng: 21.201013 },
    { lat: 49.130985, lng: 21.200913 },
    { lat: 49.131228, lng: 21.200603 },
    { lat: 49.131459, lng: 21.200442 },
    { lat: 49.131937, lng: 21.199194 },
    { lat: 49.132217, lng: 21.198244 },
    { lat: 49.132376, lng: 21.197745 },
    { lat: 49.132424, lng: 21.197613 },
    { lat: 49.132754, lng: 21.196603 },
    { lat: 49.132841, lng: 21.196278 },
    { lat: 49.132958, lng: 21.195824 },
    { lat: 49.133146, lng: 21.195437 },
    { lat: 49.133536, lng: 21.194217 },
    { lat: 49.13379, lng: 21.193616 },
    { lat: 49.133736, lng: 21.193404 },
    { lat: 49.133677, lng: 21.193044 },
    { lat: 49.133344, lng: 21.192523 },
    { lat: 49.133385, lng: 21.192542 },
    { lat: 49.133481, lng: 21.192316 },
    { lat: 49.133605, lng: 21.192126 },
    { lat: 49.133968, lng: 21.191732 },
    { lat: 49.13415, lng: 21.191627 },
    { lat: 49.1348, lng: 21.191816 },
    { lat: 49.135181, lng: 21.192238 },
    { lat: 49.135527, lng: 21.192797 },
    { lat: 49.135794, lng: 21.193152 },
    { lat: 49.136035, lng: 21.193236 },
    { lat: 49.136534, lng: 21.193255 },
    { lat: 49.136533, lng: 21.193282 },
    { lat: 49.136743, lng: 21.193386 },
    { lat: 49.136911, lng: 21.193395 },
    { lat: 49.137211, lng: 21.193178 },
    { lat: 49.137221, lng: 21.193186 },
    { lat: 49.137348, lng: 21.192897 },
    { lat: 49.137479, lng: 21.192385 },
    { lat: 49.137648, lng: 21.19206 },
    { lat: 49.138225, lng: 21.191237 },
    { lat: 49.138507, lng: 21.191038 },
    { lat: 49.138746, lng: 21.191045 },
    { lat: 49.139055, lng: 21.19129 },
    { lat: 49.139197, lng: 21.191315 },
    { lat: 49.139607, lng: 21.191254 },
    { lat: 49.139729, lng: 21.191212 },
    { lat: 49.140145, lng: 21.191191 },
    { lat: 49.14035, lng: 21.191252 },
    { lat: 49.140654, lng: 21.191347 },
    { lat: 49.140971, lng: 21.191532 },
    { lat: 49.141223, lng: 21.191438 },
    { lat: 49.141501, lng: 21.191367 },
    { lat: 49.141639, lng: 21.191366 },
    { lat: 49.141716, lng: 21.191279 },
    { lat: 49.141839, lng: 21.191127 },
    { lat: 49.142127, lng: 21.190952 },
    { lat: 49.142691, lng: 21.190806 },
    { lat: 49.143208, lng: 21.190466 },
    { lat: 49.143397, lng: 21.190244 },
    { lat: 49.143665, lng: 21.190091 },
    { lat: 49.143879, lng: 21.18997 },
    { lat: 49.144331, lng: 21.190006 },
    { lat: 49.144655, lng: 21.189873 },
    { lat: 49.14508, lng: 21.189476 },
    { lat: 49.145376, lng: 21.189102 },
    { lat: 49.145505, lng: 21.188717 },
    { lat: 49.14558, lng: 21.188568 },
    { lat: 49.145606, lng: 21.188458 },
    { lat: 49.145644, lng: 21.187737 },
    { lat: 49.145661, lng: 21.187738 },
    { lat: 49.145735, lng: 21.187764 },
    { lat: 49.145771, lng: 21.187604 },
    { lat: 49.145837, lng: 21.187605 },
    { lat: 49.145888, lng: 21.187518 },
    { lat: 49.145931, lng: 21.187392 },
    { lat: 49.145842, lng: 21.187157 },
    { lat: 49.145909, lng: 21.18703 },
    { lat: 49.145918, lng: 21.186902 },
    { lat: 49.145943, lng: 21.18651 },
    { lat: 49.145872, lng: 21.186404 },
    { lat: 49.145834, lng: 21.186056 },
    { lat: 49.1454161, lng: 21.186079 },
    { lat: 49.1450753, lng: 21.1857209 },
    { lat: 49.1448103, lng: 21.1856697 },
    { lat: 49.1446355, lng: 21.1855113 },
    { lat: 49.1440639, lng: 21.1853676 },
    { lat: 49.1437772, lng: 21.185162 },
    { lat: 49.1435786, lng: 21.1851401 },
    { lat: 49.1427496, lng: 21.1854422 },
    { lat: 49.1426163, lng: 21.1854267 },
    { lat: 49.1422423, lng: 21.1848385 },
    { lat: 49.1419611, lng: 21.1845832 },
    { lat: 49.1415559, lng: 21.1840843 },
    { lat: 49.1412404, lng: 21.1839563 },
    { lat: 49.1408567, lng: 21.1834173 },
    { lat: 49.1401885, lng: 21.183353 },
    { lat: 49.1400229, lng: 21.1832073 },
    { lat: 49.1398427, lng: 21.1828426 },
    { lat: 49.1397514, lng: 21.1827961 },
    { lat: 49.1391802, lng: 21.1828145 },
    { lat: 49.1373325, lng: 21.1825616 },
    { lat: 49.1370857, lng: 21.1823683 },
    { lat: 49.1369891, lng: 21.1819102 },
    { lat: 49.1368048, lng: 21.1814068 },
    { lat: 49.1366746, lng: 21.1812797 },
    { lat: 49.1362053, lng: 21.1810598 },
    { lat: 49.1358408, lng: 21.1810302 },
    { lat: 49.1354828, lng: 21.1808384 },
    { lat: 49.1350955, lng: 21.1804752 },
    { lat: 49.1346955, lng: 21.1802092 },
    { lat: 49.1343128, lng: 21.1802385 },
    { lat: 49.1338915, lng: 21.1806245 },
    { lat: 49.1335122, lng: 21.1807261 },
    { lat: 49.1330636, lng: 21.1806325 },
    { lat: 49.1327972, lng: 21.1804992 },
    { lat: 49.1325191, lng: 21.1802028 },
    { lat: 49.1323049, lng: 21.1798295 },
    { lat: 49.1322081, lng: 21.1795775 },
    { lat: 49.1321948, lng: 21.1793334 },
    { lat: 49.1320156, lng: 21.1784613 },
    { lat: 49.1317416, lng: 21.1782254 },
    { lat: 49.1314161, lng: 21.1783292 },
    { lat: 49.1310022, lng: 21.1783345 },
    { lat: 49.1302764, lng: 21.1788026 },
    { lat: 49.1298051, lng: 21.1786996 },
    { lat: 49.1291699, lng: 21.1787914 },
    { lat: 49.1287086, lng: 21.1787574 },
    { lat: 49.1284854, lng: 21.1787093 },
    { lat: 49.1276641, lng: 21.1782811 },
    { lat: 49.1271907, lng: 21.177792 },
    { lat: 49.1269914, lng: 21.1777551 },
    { lat: 49.1267316, lng: 21.1779367 },
    { lat: 49.1265557, lng: 21.1779229 },
    { lat: 49.1264058, lng: 21.1777366 },
    { lat: 49.1260675, lng: 21.1778585 },
    { lat: 49.1259489, lng: 21.1779868 },
    { lat: 49.1253421, lng: 21.1794153 },
    { lat: 49.1252581, lng: 21.1800383 },
    { lat: 49.1251042, lng: 21.1803725 },
    { lat: 49.1249941, lng: 21.1804879 },
    { lat: 49.1247453, lng: 21.1805153 },
    { lat: 49.1245584, lng: 21.1803378 },
    { lat: 49.1243972, lng: 21.1800024 },
    { lat: 49.1242533, lng: 21.1798652 },
    { lat: 49.123666, lng: 21.1800472 },
    { lat: 49.1235196, lng: 21.1801847 },
    { lat: 49.1232594, lng: 21.1806488 },
    { lat: 49.1229826, lng: 21.1809365 },
    { lat: 49.1219224, lng: 21.1831982 },
    { lat: 49.1217617, lng: 21.1834204 },
    { lat: 49.1214035, lng: 21.1836922 },
    { lat: 49.1204315, lng: 21.1846713 },
    { lat: 49.1199782, lng: 21.1850085 },
    { lat: 49.119791, lng: 21.1850616 },
    { lat: 49.1193902, lng: 21.184989 },
    { lat: 49.1186027, lng: 21.1850655 },
    { lat: 49.1183989, lng: 21.185025 },
    { lat: 49.1180657, lng: 21.1846942 },
    { lat: 49.1179739, lng: 21.1848895 },
    { lat: 49.1174589, lng: 21.1846168 },
    { lat: 49.1171567, lng: 21.1846341 },
    { lat: 49.1171768, lng: 21.1848086 },
    { lat: 49.1165438, lng: 21.185078 },
    { lat: 49.1157186, lng: 21.1858574 },
    { lat: 49.1151047, lng: 21.1862697 },
    { lat: 49.1143546, lng: 21.1868957 },
    { lat: 49.113262, lng: 21.187513 },
    { lat: 49.1133024, lng: 21.1880488 },
    { lat: 49.1108095, lng: 21.1899066 },
    { lat: 49.1105756, lng: 21.1894469 },
    { lat: 49.1100456, lng: 21.189769 },
    { lat: 49.1094406, lng: 21.190464 },
    { lat: 49.1093413, lng: 21.1906752 },
    { lat: 49.1091227, lng: 21.1914597 },
    { lat: 49.1083987, lng: 21.1925613 },
    { lat: 49.1069064, lng: 21.1926316 },
    { lat: 49.1077301, lng: 21.1929911 },
    { lat: 49.1075863, lng: 21.1936872 },
    { lat: 49.1074572, lng: 21.1947029 },
    { lat: 49.1065501, lng: 21.1943422 },
    { lat: 49.1064578, lng: 21.1944458 },
    { lat: 49.1064897, lng: 21.1947872 },
    { lat: 49.1067229, lng: 21.1952631 },
    { lat: 49.1065686, lng: 21.1954054 },
    { lat: 49.1061841, lng: 21.1955197 },
    { lat: 49.1053784, lng: 21.1961354 },
    { lat: 49.1054671, lng: 21.1964537 },
    { lat: 49.1058905, lng: 21.1961027 },
    { lat: 49.1063326, lng: 21.1961328 },
    { lat: 49.1066882, lng: 21.1962461 },
    { lat: 49.1070892, lng: 21.1961432 },
    { lat: 49.1083227, lng: 21.1952896 },
    { lat: 49.1086816, lng: 21.1959339 },
    { lat: 49.1088713, lng: 21.1959514 },
    { lat: 49.1091494, lng: 21.1958182 },
    { lat: 49.1094198, lng: 21.1963306 },
    { lat: 49.1095928, lng: 21.1961381 },
    { lat: 49.1099485, lng: 21.1965064 },
    { lat: 49.1101447, lng: 21.1966276 },
    { lat: 49.1099057, lng: 21.1970091 },
    { lat: 49.109199, lng: 21.197697 },
    { lat: 49.1090563, lng: 21.1979537 },
    { lat: 49.1085579, lng: 21.1985705 },
    { lat: 49.1086657, lng: 21.1993964 },
    { lat: 49.1087319, lng: 21.1993564 },
    { lat: 49.108773, lng: 21.1994791 },
    { lat: 49.109253, lng: 21.1992879 },
    { lat: 49.1093041, lng: 21.1995489 },
    { lat: 49.1096815, lng: 21.1996003 },
    { lat: 49.1097133, lng: 21.1997183 },
    { lat: 49.1100311, lng: 21.199913 },
    { lat: 49.1101551, lng: 21.1997072 },
    { lat: 49.1103271, lng: 21.1995544 },
    { lat: 49.110538, lng: 21.1994637 },
    { lat: 49.110851, lng: 21.1995743 },
    { lat: 49.1111326, lng: 21.2000913 },
    { lat: 49.1114454, lng: 21.2004502 },
    { lat: 49.1118424, lng: 21.2010609 },
    { lat: 49.1110268, lng: 21.2024057 },
    { lat: 49.1114111, lng: 21.2036901 },
    { lat: 49.1118525, lng: 21.2054358 },
    { lat: 49.1120093, lng: 21.2070801 },
    { lat: 49.1120604, lng: 21.2084366 },
    { lat: 49.1124003, lng: 21.2088374 },
    { lat: 49.1127719, lng: 21.2087377 },
    { lat: 49.1130203, lng: 21.2099936 },
    { lat: 49.11382, lng: 21.21246 },
  ],
  Lúčka: [
    { lat: 49.1771321, lng: 20.9812963 },
    { lat: 49.1769098, lng: 20.9817206 },
    { lat: 49.1763989, lng: 20.981863 },
    { lat: 49.1763606, lng: 20.9824375 },
    { lat: 49.1759197, lng: 20.9837443 },
    { lat: 49.1750315, lng: 20.9839441 },
    { lat: 49.1743162, lng: 20.9844363 },
    { lat: 49.1739484, lng: 20.9841397 },
    { lat: 49.1725108, lng: 20.9839902 },
    { lat: 49.1723488, lng: 20.9837177 },
    { lat: 49.1714923, lng: 20.9833032 },
    { lat: 49.1713072, lng: 20.9829113 },
    { lat: 49.1709767, lng: 20.9828867 },
    { lat: 49.1703714, lng: 20.9824803 },
    { lat: 49.1698938, lng: 20.9825093 },
    { lat: 49.1694412, lng: 20.9820574 },
    { lat: 49.1688706, lng: 20.9823324 },
    { lat: 49.1686875, lng: 20.9822008 },
    { lat: 49.1685235, lng: 20.9824198 },
    { lat: 49.1683601, lng: 20.9825118 },
    { lat: 49.16819, lng: 20.9823026 },
    { lat: 49.1675905, lng: 20.9826539 },
    { lat: 49.1671359, lng: 20.9831351 },
    { lat: 49.167035, lng: 20.9831504 },
    { lat: 49.1669187, lng: 20.9834427 },
    { lat: 49.1662069, lng: 20.986433 },
    { lat: 49.1663606, lng: 20.9890825 },
    { lat: 49.1663315, lng: 20.9901471 },
    { lat: 49.1661051, lng: 20.9911023 },
    { lat: 49.165602, lng: 20.9913803 },
    { lat: 49.1649781, lng: 20.9915327 },
    { lat: 49.1643181, lng: 20.9914149 },
    { lat: 49.164159, lng: 20.9912663 },
    { lat: 49.1640427, lng: 20.9912572 },
    { lat: 49.1639108, lng: 20.9910745 },
    { lat: 49.1638508, lng: 20.9908943 },
    { lat: 49.1635388, lng: 20.9905139 },
    { lat: 49.1634396, lng: 20.9904367 },
    { lat: 49.1633823, lng: 20.9905443 },
    { lat: 49.16291, lng: 20.9891218 },
    { lat: 49.1624739, lng: 20.9895126 },
    { lat: 49.1621646, lng: 20.9901347 },
    { lat: 49.1620234, lng: 20.9905587 },
    { lat: 49.1617753, lng: 20.9919492 },
    { lat: 49.1614974, lng: 20.9931798 },
    { lat: 49.16092, lng: 20.9929409 },
    { lat: 49.1609084, lng: 20.9929691 },
    { lat: 49.1606792, lng: 20.993694 },
    { lat: 49.1613948, lng: 20.9938611 },
    { lat: 49.1613048, lng: 20.994274 },
    { lat: 49.1614317, lng: 20.9955358 },
    { lat: 49.1620605, lng: 20.9955906 },
    { lat: 49.1622677, lng: 20.9957014 },
    { lat: 49.1630824, lng: 20.9956636 },
    { lat: 49.1633837, lng: 20.9957898 },
    { lat: 49.1653419, lng: 20.995619 },
    { lat: 49.1650106, lng: 20.9965984 },
    { lat: 49.1650133, lng: 20.9972624 },
    { lat: 49.1652805, lng: 20.9979951 },
    { lat: 49.1652715, lng: 20.998583 },
    { lat: 49.1653947, lng: 20.9992109 },
    { lat: 49.165433, lng: 20.9999326 },
    { lat: 49.165411, lng: 21.0005327 },
    { lat: 49.1652918, lng: 21.0008104 },
    { lat: 49.1653236, lng: 21.0008519 },
    { lat: 49.1652749, lng: 21.0012886 },
    { lat: 49.1663424, lng: 21.0015858 },
    { lat: 49.1673471, lng: 21.0023302 },
    { lat: 49.168876, lng: 21.0032419 },
    { lat: 49.1701732, lng: 21.0037087 },
    { lat: 49.1715093, lng: 21.0031299 },
    { lat: 49.1735237, lng: 21.0027525 },
    { lat: 49.174571, lng: 21.0027374 },
    { lat: 49.1746171, lng: 21.0020879 },
    { lat: 49.1763766, lng: 21.0015824 },
    { lat: 49.1764283, lng: 21.0012044 },
    { lat: 49.1772699, lng: 21.0012657 },
    { lat: 49.1790332, lng: 20.9977943 },
    { lat: 49.1803519, lng: 20.997894 },
    { lat: 49.180367, lng: 20.9992443 },
    { lat: 49.18242, lng: 20.9977756 },
    { lat: 49.183928, lng: 20.997328 },
    { lat: 49.1852922, lng: 20.9968229 },
    { lat: 49.1851213, lng: 20.9960057 },
    { lat: 49.1861825, lng: 20.9948711 },
    { lat: 49.1884416, lng: 20.9942343 },
    { lat: 49.1887184, lng: 20.9931693 },
    { lat: 49.1911771, lng: 20.9932605 },
    { lat: 49.1920819, lng: 20.991105 },
    { lat: 49.1919902, lng: 20.989545 },
    { lat: 49.1924059, lng: 20.9869603 },
    { lat: 49.1935881, lng: 20.986802 },
    { lat: 49.1945783, lng: 20.9859136 },
    { lat: 49.1952437, lng: 20.9860883 },
    { lat: 49.1955427, lng: 20.9886098 },
    { lat: 49.196647, lng: 20.9882417 },
    { lat: 49.1964791, lng: 20.9868029 },
    { lat: 49.1965927, lng: 20.9867647 },
    { lat: 49.1965269, lng: 20.9862324 },
    { lat: 49.1970783, lng: 20.986472 },
    { lat: 49.1971105, lng: 20.9868326 },
    { lat: 49.1983709, lng: 20.9870743 },
    { lat: 49.1983941, lng: 20.9869559 },
    { lat: 49.1989485, lng: 20.9870473 },
    { lat: 49.2002868, lng: 20.9870538 },
    { lat: 49.2003839, lng: 20.9869585 },
    { lat: 49.2005054, lng: 20.9866804 },
    { lat: 49.2004735, lng: 20.9865345 },
    { lat: 49.2004665, lng: 20.9865304 },
    { lat: 49.1996999, lng: 20.9861087 },
    { lat: 49.1992585, lng: 20.9860051 },
    { lat: 49.1991053, lng: 20.9856908 },
    { lat: 49.198733, lng: 20.9857643 },
    { lat: 49.1982488, lng: 20.9855142 },
    { lat: 49.1978252, lng: 20.985569 },
    { lat: 49.1975589, lng: 20.9853113 },
    { lat: 49.1969733, lng: 20.985575 },
    { lat: 49.196741, lng: 20.985388 },
    { lat: 49.1962871, lng: 20.9855113 },
    { lat: 49.1959619, lng: 20.9854819 },
    { lat: 49.1955886, lng: 20.9855566 },
    { lat: 49.1952807, lng: 20.985265 },
    { lat: 49.194508, lng: 20.9857553 },
    { lat: 49.1941307, lng: 20.9858483 },
    { lat: 49.1938594, lng: 20.9861245 },
    { lat: 49.1934893, lng: 20.9863303 },
    { lat: 49.1934348, lng: 20.9861794 },
    { lat: 49.1933304, lng: 20.9861518 },
    { lat: 49.1925524, lng: 20.9863179 },
    { lat: 49.1921159, lng: 20.986147 },
    { lat: 49.1919967, lng: 20.9857427 },
    { lat: 49.1916599, lng: 20.9854245 },
    { lat: 49.1909917, lng: 20.9853286 },
    { lat: 49.1906333, lng: 20.9851441 },
    { lat: 49.1897713, lng: 20.9845003 },
    { lat: 49.1896009, lng: 20.9841431 },
    { lat: 49.1893753, lng: 20.9838891 },
    { lat: 49.1892993, lng: 20.9835938 },
    { lat: 49.1887889, lng: 20.9830331 },
    { lat: 49.1884985, lng: 20.9828604 },
    { lat: 49.1880897, lng: 20.982442 },
    { lat: 49.1875181, lng: 20.9813817 },
    { lat: 49.1875224, lng: 20.9812421 },
    { lat: 49.187157, lng: 20.980502 },
    { lat: 49.1864889, lng: 20.9794214 },
    { lat: 49.1859053, lng: 20.9787534 },
    { lat: 49.1857928, lng: 20.9785509 },
    { lat: 49.1854886, lng: 20.9784383 },
    { lat: 49.1851092, lng: 20.9780961 },
    { lat: 49.1845838, lng: 20.978062 },
    { lat: 49.1843365, lng: 20.9778709 },
    { lat: 49.1840878, lng: 20.9778399 },
    { lat: 49.1835266, lng: 20.977469 },
    { lat: 49.183094, lng: 20.9773583 },
    { lat: 49.1827895, lng: 20.9771658 },
    { lat: 49.1823906, lng: 20.9772205 },
    { lat: 49.1821933, lng: 20.9774156 },
    { lat: 49.181867, lng: 20.9775881 },
    { lat: 49.1816611, lng: 20.9775162 },
    { lat: 49.1813918, lng: 20.9773048 },
    { lat: 49.1807154, lng: 20.9776449 },
    { lat: 49.1805353, lng: 20.977812 },
    { lat: 49.1801506, lng: 20.9778887 },
    { lat: 49.179931, lng: 20.9777523 },
    { lat: 49.1797881, lng: 20.9777514 },
    { lat: 49.1796461, lng: 20.9782224 },
    { lat: 49.1792015, lng: 20.9781856 },
    { lat: 49.1790548, lng: 20.9785156 },
    { lat: 49.1787811, lng: 20.9788467 },
    { lat: 49.1782379, lng: 20.9792715 },
    { lat: 49.1779193, lng: 20.9796442 },
    { lat: 49.1771321, lng: 20.9812963 },
  ],
  ČervenáVoda: [
    { lat: 49.122995, lng: 21.0718726 },
    { lat: 49.1230206, lng: 21.0718966 },
    { lat: 49.123255, lng: 21.0724615 },
    { lat: 49.1235548, lng: 21.0737013 },
    { lat: 49.1249387, lng: 21.0727445 },
    { lat: 49.1255668, lng: 21.0724036 },
    { lat: 49.1252127, lng: 21.0729705 },
    { lat: 49.1249923, lng: 21.0736772 },
    { lat: 49.1250459, lng: 21.0741162 },
    { lat: 49.1248668, lng: 21.0739762 },
    { lat: 49.1245485, lng: 21.0739461 },
    { lat: 49.1243838, lng: 21.0741776 },
    { lat: 49.124311, lng: 21.0744306 },
    { lat: 49.1239882, lng: 21.0747544 },
    { lat: 49.1238092, lng: 21.0750308 },
    { lat: 49.1237438, lng: 21.0753069 },
    { lat: 49.1233969, lng: 21.0751899 },
    { lat: 49.1231773, lng: 21.0754679 },
    { lat: 49.1229722, lng: 21.0759445 },
    { lat: 49.1228058, lng: 21.0759836 },
    { lat: 49.122549, lng: 21.0758734 },
    { lat: 49.1222048, lng: 21.0763037 },
    { lat: 49.1219217, lng: 21.0764531 },
    { lat: 49.1217824, lng: 21.0764247 },
    { lat: 49.1217368, lng: 21.0762679 },
    { lat: 49.1215733, lng: 21.0762952 },
    { lat: 49.1214686, lng: 21.0765002 },
    { lat: 49.121273, lng: 21.0764401 },
    { lat: 49.1211536, lng: 21.0764728 },
    { lat: 49.1210309, lng: 21.0767514 },
    { lat: 49.1209363, lng: 21.0768519 },
    { lat: 49.1209022, lng: 21.0767373 },
    { lat: 49.1207341, lng: 21.0769715 },
    { lat: 49.1204498, lng: 21.0770194 },
    { lat: 49.1203523, lng: 21.0772361 },
    { lat: 49.1201785, lng: 21.0772944 },
    { lat: 49.1201128, lng: 21.0774581 },
    { lat: 49.1197955, lng: 21.0775648 },
    { lat: 49.119794, lng: 21.0777226 },
    { lat: 49.1197449, lng: 21.0778089 },
    { lat: 49.1196453, lng: 21.0778224 },
    { lat: 49.1195048, lng: 21.0776917 },
    { lat: 49.1195551, lng: 21.0776125 },
    { lat: 49.1193649, lng: 21.0774334 },
    { lat: 49.119128, lng: 21.0779412 },
    { lat: 49.1191913, lng: 21.0780191 },
    { lat: 49.1193745, lng: 21.077732 },
    { lat: 49.1195988, lng: 21.0779545 },
    { lat: 49.1197297, lng: 21.0779692 },
    { lat: 49.1199246, lng: 21.0777581 },
    { lat: 49.1205318, lng: 21.0773998 },
    { lat: 49.120718, lng: 21.077382 },
    { lat: 49.1208063, lng: 21.0772169 },
    { lat: 49.1210776, lng: 21.077358 },
    { lat: 49.1211807, lng: 21.0774908 },
    { lat: 49.1212049, lng: 21.0774361 },
    { lat: 49.1211667, lng: 21.0773344 },
    { lat: 49.1212359, lng: 21.0769646 },
    { lat: 49.1215416, lng: 21.0767768 },
    { lat: 49.121747, lng: 21.0765484 },
    { lat: 49.1219622, lng: 21.0766261 },
    { lat: 49.1221386, lng: 21.076554 },
    { lat: 49.1226021, lng: 21.0761608 },
    { lat: 49.1227324, lng: 21.076187 },
    { lat: 49.1228004, lng: 21.0763077 },
    { lat: 49.1224918, lng: 21.0765431 },
    { lat: 49.1228115, lng: 21.0769292 },
    { lat: 49.1229724, lng: 21.0772103 },
    { lat: 49.123171, lng: 21.0778221 },
    { lat: 49.1234171, lng: 21.0782001 },
    { lat: 49.1231407, lng: 21.0785297 },
    { lat: 49.1237371, lng: 21.0795757 },
    { lat: 49.1242787, lng: 21.0802125 },
    { lat: 49.1232061, lng: 21.0815735 },
    { lat: 49.1236815, lng: 21.0824316 },
    { lat: 49.1225084, lng: 21.0838137 },
    { lat: 49.1227284, lng: 21.0843571 },
    { lat: 49.1228782, lng: 21.0840128 },
    { lat: 49.1227791, lng: 21.0844665 },
    { lat: 49.1227655, lng: 21.0849921 },
    { lat: 49.1227128, lng: 21.0852098 },
    { lat: 49.1229193, lng: 21.0854703 },
    { lat: 49.1230437, lng: 21.0859062 },
    { lat: 49.124212, lng: 21.089031 },
    { lat: 49.1236161, lng: 21.0898935 },
    { lat: 49.1228216, lng: 21.0907675 },
    { lat: 49.1228388, lng: 21.0908187 },
    { lat: 49.1226684, lng: 21.0912554 },
    { lat: 49.1224623, lng: 21.091358 },
    { lat: 49.1224202, lng: 21.0914876 },
    { lat: 49.12271, lng: 21.0919561 },
    { lat: 49.1229176, lng: 21.0920772 },
    { lat: 49.1232616, lng: 21.0920028 },
    { lat: 49.1234234, lng: 21.0920985 },
    { lat: 49.1232782, lng: 21.0939906 },
    { lat: 49.1233087, lng: 21.0945525 },
    { lat: 49.1246072, lng: 21.0949385 },
    { lat: 49.1253283, lng: 21.0954238 },
    { lat: 49.1260592, lng: 21.0954058 },
    { lat: 49.1271442, lng: 21.0955806 },
    { lat: 49.1280064, lng: 21.0958043 },
    { lat: 49.1291198, lng: 21.0963375 },
    { lat: 49.1297105, lng: 21.0964114 },
    { lat: 49.1317182, lng: 21.0960926 },
    { lat: 49.1320706, lng: 21.0959927 },
    { lat: 49.1325972, lng: 21.0957146 },
    { lat: 49.1326502, lng: 21.0961031 },
    { lat: 49.1327839, lng: 21.0965157 },
    { lat: 49.1327743, lng: 21.0973063 },
    { lat: 49.1329544, lng: 21.0976581 },
    { lat: 49.132967, lng: 21.0978753 },
    { lat: 49.1326258, lng: 21.0980807 },
    { lat: 49.1324944, lng: 21.0985156 },
    { lat: 49.1316679, lng: 21.0987089 },
    { lat: 49.1313984, lng: 21.0991497 },
    { lat: 49.1309378, lng: 21.0992464 },
    { lat: 49.1307433, lng: 21.0989001 },
    { lat: 49.1305309, lng: 21.0987998 },
    { lat: 49.130605, lng: 21.0990746 },
    { lat: 49.1306707, lng: 21.0990941 },
    { lat: 49.1308912, lng: 21.0993742 },
    { lat: 49.1314405, lng: 21.0993032 },
    { lat: 49.1317195, lng: 21.0988757 },
    { lat: 49.1320339, lng: 21.0989663 },
    { lat: 49.1323453, lng: 21.0989539 },
    { lat: 49.1325745, lng: 21.0987619 },
    { lat: 49.1326674, lng: 21.0984682 },
    { lat: 49.1328789, lng: 21.0985025 },
    { lat: 49.1330992, lng: 21.0986669 },
    { lat: 49.1333491, lng: 21.0984945 },
    { lat: 49.1337142, lng: 21.0983725 },
    { lat: 49.1340293, lng: 21.0986161 },
    { lat: 49.1344341, lng: 21.098429 },
    { lat: 49.1345477, lng: 21.0985319 },
    { lat: 49.1346471, lng: 21.0985113 },
    { lat: 49.1349627, lng: 21.0982808 },
    { lat: 49.1355876, lng: 21.0981179 },
    { lat: 49.1357834, lng: 21.0981853 },
    { lat: 49.1359741, lng: 21.0978154 },
    { lat: 49.1360686, lng: 21.097739 },
    { lat: 49.1363265, lng: 21.0978909 },
    { lat: 49.137025, lng: 21.0974929 },
    { lat: 49.1371397, lng: 21.0973478 },
    { lat: 49.1372336, lng: 21.097366 },
    { lat: 49.1373459, lng: 21.0975298 },
    { lat: 49.1376567, lng: 21.097397 },
    { lat: 49.1377747, lng: 21.0974285 },
    { lat: 49.1378775, lng: 21.0976121 },
    { lat: 49.138005, lng: 21.097663 },
    { lat: 49.1381477, lng: 21.097551 },
    { lat: 49.1382612, lng: 21.0973338 },
    { lat: 49.1383701, lng: 21.0974281 },
    { lat: 49.1387941, lng: 21.0973923 },
    { lat: 49.1390117, lng: 21.0975742 },
    { lat: 49.1390773, lng: 21.0971624 },
    { lat: 49.1393386, lng: 21.0972436 },
    { lat: 49.1394667, lng: 21.0968851 },
    { lat: 49.1402676, lng: 21.0969453 },
    { lat: 49.1402858, lng: 21.0970877 },
    { lat: 49.1403866, lng: 21.0970396 },
    { lat: 49.1407193, lng: 21.097237 },
    { lat: 49.1408804, lng: 21.0972459 },
    { lat: 49.1410698, lng: 21.0974316 },
    { lat: 49.1414122, lng: 21.0974011 },
    { lat: 49.1415146, lng: 21.0974751 },
    { lat: 49.1416181, lng: 21.0976831 },
    { lat: 49.1418345, lng: 21.0977684 },
    { lat: 49.1420805, lng: 21.0976067 },
    { lat: 49.1423505, lng: 21.0976067 },
    { lat: 49.1424394, lng: 21.0973647 },
    { lat: 49.1426871, lng: 21.0975048 },
    { lat: 49.1428211, lng: 21.0973679 },
    { lat: 49.1429314, lng: 21.0975797 },
    { lat: 49.1430777, lng: 21.0976134 },
    { lat: 49.1430608, lng: 21.0977552 },
    { lat: 49.1433089, lng: 21.0979097 },
    { lat: 49.143456, lng: 21.098219 },
    { lat: 49.1435736, lng: 21.0981639 },
    { lat: 49.1436377, lng: 21.0980225 },
    { lat: 49.1438058, lng: 21.0981754 },
    { lat: 49.143896, lng: 21.0983948 },
    { lat: 49.1440875, lng: 21.09858 },
    { lat: 49.1441993, lng: 21.0985886 },
    { lat: 49.1444989, lng: 21.0987763 },
    { lat: 49.1445319, lng: 21.0988943 },
    { lat: 49.1448012, lng: 21.0991794 },
    { lat: 49.1449881, lng: 21.0992867 },
    { lat: 49.1452195, lng: 21.0992752 },
    { lat: 49.1453576, lng: 21.0994048 },
    { lat: 49.1456276, lng: 21.0993373 },
    { lat: 49.1458315, lng: 21.0992021 },
    { lat: 49.1459349, lng: 21.0992652 },
    { lat: 49.1461136, lng: 21.0990506 },
    { lat: 49.1465308, lng: 21.0990373 },
    { lat: 49.1468508, lng: 21.0992284 },
    { lat: 49.1469826, lng: 21.0991989 },
    { lat: 49.1471215, lng: 21.0993155 },
    { lat: 49.1472203, lng: 21.0992434 },
    { lat: 49.1473442, lng: 21.0993122 },
    { lat: 49.1473526, lng: 21.099258 },
    { lat: 49.147361, lng: 21.0992042 },
    { lat: 49.1466006, lng: 21.098898 },
    { lat: 49.1456672, lng: 21.0986374 },
    { lat: 49.1453949, lng: 21.0979304 },
    { lat: 49.1453846, lng: 21.0977583 },
    { lat: 49.1456213, lng: 21.0972036 },
    { lat: 49.1456004, lng: 21.0970064 },
    { lat: 49.1454703, lng: 21.096808 },
    { lat: 49.1448658, lng: 21.096519 },
    { lat: 49.1445732, lng: 21.096278 },
    { lat: 49.1444599, lng: 21.096111 },
    { lat: 49.1449465, lng: 21.0959529 },
    { lat: 49.1449816, lng: 21.0956728 },
    { lat: 49.1451736, lng: 21.0952017 },
    { lat: 49.1452486, lng: 21.0947669 },
    { lat: 49.1454665, lng: 21.0948643 },
    { lat: 49.1458133, lng: 21.0943087 },
    { lat: 49.1461464, lng: 21.0939226 },
    { lat: 49.1469572, lng: 21.0933403 },
    { lat: 49.1470081, lng: 21.092994 },
    { lat: 49.1469486, lng: 21.0926461 },
    { lat: 49.1467768, lng: 21.0927301 },
    { lat: 49.1469219, lng: 21.0925191 },
    { lat: 49.1467191, lng: 21.0926165 },
    { lat: 49.1468415, lng: 21.0924309 },
    { lat: 49.1469461, lng: 21.0921129 },
    { lat: 49.1470209, lng: 21.0920987 },
    { lat: 49.1471537, lng: 21.091812 },
    { lat: 49.1473769, lng: 21.0920377 },
    { lat: 49.1476006, lng: 21.0916446 },
    { lat: 49.1475571, lng: 21.0910209 },
    { lat: 49.1474746, lng: 21.0909052 },
    { lat: 49.147962, lng: 21.0907356 },
    { lat: 49.1483718, lng: 21.0907047 },
    { lat: 49.1483425, lng: 21.0901065 },
    { lat: 49.1484648, lng: 21.0900974 },
    { lat: 49.1488659, lng: 21.0902495 },
    { lat: 49.1489212, lng: 21.0901145 },
    { lat: 49.1488414, lng: 21.0896614 },
    { lat: 49.1485755, lng: 21.0896154 },
    { lat: 49.148598, lng: 21.0887042 },
    { lat: 49.1482514, lng: 21.0887372 },
    { lat: 49.1485678, lng: 21.0871323 },
    { lat: 49.1485483, lng: 21.0866625 },
    { lat: 49.1483322, lng: 21.0867699 },
    { lat: 49.1483149, lng: 21.0866871 },
    { lat: 49.1486228, lng: 21.0865293 },
    { lat: 49.1486757, lng: 21.0856867 },
    { lat: 49.1486352, lng: 21.0854769 },
    { lat: 49.148525, lng: 21.0853054 },
    { lat: 49.1486611, lng: 21.084953 },
    { lat: 49.1486534, lng: 21.0846775 },
    { lat: 49.1494499, lng: 21.084879 },
    { lat: 49.1494689, lng: 21.0857221 },
    { lat: 49.1494091, lng: 21.086452 },
    { lat: 49.149465, lng: 21.0864748 },
    { lat: 49.1496543, lng: 21.0859861 },
    { lat: 49.1506981, lng: 21.0847119 },
    { lat: 49.1510285, lng: 21.084483 },
    { lat: 49.1513404, lng: 21.0844213 },
    { lat: 49.151543, lng: 21.084211 },
    { lat: 49.1516571, lng: 21.0834522 },
    { lat: 49.1517412, lng: 21.0831626 },
    { lat: 49.1518043, lng: 21.0832028 },
    { lat: 49.1521763, lng: 21.0824891 },
    { lat: 49.1523837, lng: 21.0821846 },
    { lat: 49.1524318, lng: 21.0822411 },
    { lat: 49.1531167, lng: 21.0810035 },
    { lat: 49.1529498, lng: 21.0809542 },
    { lat: 49.152371, lng: 21.0806997 },
    { lat: 49.1519903, lng: 21.0814267 },
    { lat: 49.1507645, lng: 21.0805073 },
    { lat: 49.1505752, lng: 21.0808823 },
    { lat: 49.1497894, lng: 21.0803155 },
    { lat: 49.1491596, lng: 21.0803262 },
    { lat: 49.1484446, lng: 21.0798566 },
    { lat: 49.1483442, lng: 21.0795718 },
    { lat: 49.1478453, lng: 21.0791701 },
    { lat: 49.1470916, lng: 21.0792211 },
    { lat: 49.1471339, lng: 21.0789986 },
    { lat: 49.1471538, lng: 21.0781042 },
    { lat: 49.147118, lng: 21.0758774 },
    { lat: 49.1473465, lng: 21.0753491 },
    { lat: 49.1476067, lng: 21.0743116 },
    { lat: 49.1476985, lng: 21.0739101 },
    { lat: 49.1478432, lng: 21.0728082 },
    { lat: 49.1474799, lng: 21.0724306 },
    { lat: 49.1474094, lng: 21.0715005 },
    { lat: 49.1471706, lng: 21.0712492 },
    { lat: 49.1465817, lng: 21.0703694 },
    { lat: 49.1465756, lng: 21.069863 },
    { lat: 49.1465115, lng: 21.0697258 },
    { lat: 49.1460938, lng: 21.069967 },
    { lat: 49.1456826, lng: 21.0699831 },
    { lat: 49.1451343, lng: 21.0701219 },
    { lat: 49.1442462, lng: 21.0707082 },
    { lat: 49.1437276, lng: 21.0708167 },
    { lat: 49.1430816, lng: 21.0712382 },
    { lat: 49.1428969, lng: 21.0712657 },
    { lat: 49.1424038, lng: 21.0712958 },
    { lat: 49.1420293, lng: 21.0711973 },
    { lat: 49.1414523, lng: 21.0711725 },
    { lat: 49.1406023, lng: 21.0712347 },
    { lat: 49.1404366, lng: 21.0711548 },
    { lat: 49.1403189, lng: 21.0709576 },
    { lat: 49.1401943, lng: 21.070858 },
    { lat: 49.1399623, lng: 21.0709275 },
    { lat: 49.1395607, lng: 21.0712121 },
    { lat: 49.1389468, lng: 21.0712979 },
    { lat: 49.1383888, lng: 21.0712685 },
    { lat: 49.137108, lng: 21.0715814 },
    { lat: 49.1369317, lng: 21.0714146 },
    { lat: 49.1366823, lng: 21.0712948 },
    { lat: 49.1360096, lng: 21.0711928 },
    { lat: 49.1349571, lng: 21.0703163 },
    { lat: 49.1342743, lng: 21.0699387 },
    { lat: 49.133713, lng: 21.0692497 },
    { lat: 49.1330276, lng: 21.068721 },
    { lat: 49.1328101, lng: 21.0684681 },
    { lat: 49.1320792, lng: 21.0679619 },
    { lat: 49.1317215, lng: 21.0678313 },
    { lat: 49.1309964, lng: 21.0678558 },
    { lat: 49.1305592, lng: 21.0680085 },
    { lat: 49.1299739, lng: 21.0681102 },
    { lat: 49.1291707, lng: 21.0684701 },
    { lat: 49.1287555, lng: 21.0685748 },
    { lat: 49.128097, lng: 21.068332 },
    { lat: 49.1272841, lng: 21.0683145 },
    { lat: 49.1267547, lng: 21.0683732 },
    { lat: 49.1265139, lng: 21.0684753 },
    { lat: 49.125875, lng: 21.0689051 },
    { lat: 49.12546, lng: 21.069016 },
    { lat: 49.1252352, lng: 21.0691683 },
    { lat: 49.1241074, lng: 21.070211 },
    { lat: 49.123668, lng: 21.0707494 },
    { lat: 49.122995, lng: 21.0718726 },
  ],
  Jakovany: [
    { lat: 49.1734394, lng: 21.0703631 },
    { lat: 49.1692196, lng: 21.0682384 },
    { lat: 49.1686236, lng: 21.0677311 },
    { lat: 49.1678691, lng: 21.0685525 },
    { lat: 49.1669752, lng: 21.0672839 },
    { lat: 49.1661575, lng: 21.0654795 },
    { lat: 49.1653025, lng: 21.0657382 },
    { lat: 49.165255, lng: 21.0656238 },
    { lat: 49.1651453, lng: 21.0656371 },
    { lat: 49.1648246, lng: 21.0653623 },
    { lat: 49.1646452, lng: 21.0650345 },
    { lat: 49.1641834, lng: 21.0657204 },
    { lat: 49.1641284, lng: 21.0659363 },
    { lat: 49.1636022, lng: 21.0652832 },
    { lat: 49.1632672, lng: 21.0647754 },
    { lat: 49.1627742, lng: 21.0637246 },
    { lat: 49.1625163, lng: 21.063406 },
    { lat: 49.1618589, lng: 21.0628375 },
    { lat: 49.1615219, lng: 21.0624428 },
    { lat: 49.1613213, lng: 21.0624298 },
    { lat: 49.1609078, lng: 21.0620693 },
    { lat: 49.1605587, lng: 21.0619589 },
    { lat: 49.1603059, lng: 21.06155 },
    { lat: 49.1601258, lng: 21.0615135 },
    { lat: 49.1598101, lng: 21.0611809 },
    { lat: 49.1594769, lng: 21.0610797 },
    { lat: 49.1593355, lng: 21.0609141 },
    { lat: 49.1591398, lng: 21.0609148 },
    { lat: 49.1588874, lng: 21.060663 },
    { lat: 49.158517, lng: 21.0604179 },
    { lat: 49.1582546, lng: 21.0603754 },
    { lat: 49.1576579, lng: 21.0599129 },
    { lat: 49.1573603, lng: 21.0599 },
    { lat: 49.1573382, lng: 21.0599462 },
    { lat: 49.1572173, lng: 21.0598597 },
    { lat: 49.1570327, lng: 21.0597221 },
    { lat: 49.1562287, lng: 21.0587632 },
    { lat: 49.15576, lng: 21.0583289 },
    { lat: 49.1556586, lng: 21.0582919 },
    { lat: 49.1543922, lng: 21.0569191 },
    { lat: 49.1542568, lng: 21.0570137 },
    { lat: 49.1541625, lng: 21.0569845 },
    { lat: 49.1541049, lng: 21.0568087 },
    { lat: 49.1540012, lng: 21.0568021 },
    { lat: 49.1538991, lng: 21.056533 },
    { lat: 49.153823, lng: 21.056653 },
    { lat: 49.1534963, lng: 21.0564687 },
    { lat: 49.1534043, lng: 21.0565119 },
    { lat: 49.1530456, lng: 21.0556117 },
    { lat: 49.1519703, lng: 21.0503842 },
    { lat: 49.1518641, lng: 21.0493241 },
    { lat: 49.1503931, lng: 21.049743 },
    { lat: 49.1480757, lng: 21.0497284 },
    { lat: 49.1461722, lng: 21.0494755 },
    { lat: 49.1459388, lng: 21.0510216 },
    { lat: 49.1457574, lng: 21.0518484 },
    { lat: 49.1457628, lng: 21.0526057 },
    { lat: 49.1455714, lng: 21.0536144 },
    { lat: 49.1445179, lng: 21.0608099 },
    { lat: 49.1435149, lng: 21.0681598 },
    { lat: 49.1433671, lng: 21.0689221 },
    { lat: 49.1431374, lng: 21.0710809 },
    { lat: 49.1430816, lng: 21.0712382 },
    { lat: 49.1437276, lng: 21.0708167 },
    { lat: 49.1442462, lng: 21.0707082 },
    { lat: 49.1451343, lng: 21.0701219 },
    { lat: 49.1456826, lng: 21.0699831 },
    { lat: 49.1460938, lng: 21.069967 },
    { lat: 49.1465115, lng: 21.0697258 },
    { lat: 49.1465756, lng: 21.069863 },
    { lat: 49.1465817, lng: 21.0703694 },
    { lat: 49.1471706, lng: 21.0712492 },
    { lat: 49.1474094, lng: 21.0715005 },
    { lat: 49.1474799, lng: 21.0724306 },
    { lat: 49.1478432, lng: 21.0728082 },
    { lat: 49.1476985, lng: 21.0739101 },
    { lat: 49.1476067, lng: 21.0743116 },
    { lat: 49.1473465, lng: 21.0753491 },
    { lat: 49.147118, lng: 21.0758774 },
    { lat: 49.1471538, lng: 21.0781042 },
    { lat: 49.1471339, lng: 21.0789986 },
    { lat: 49.1470916, lng: 21.0792211 },
    { lat: 49.1478453, lng: 21.0791701 },
    { lat: 49.1483442, lng: 21.0795718 },
    { lat: 49.1484446, lng: 21.0798566 },
    { lat: 49.1491596, lng: 21.0803262 },
    { lat: 49.1497894, lng: 21.0803155 },
    { lat: 49.1505752, lng: 21.0808823 },
    { lat: 49.1507645, lng: 21.0805073 },
    { lat: 49.1519903, lng: 21.0814267 },
    { lat: 49.152371, lng: 21.0806997 },
    { lat: 49.1529498, lng: 21.0809542 },
    { lat: 49.1531167, lng: 21.0810035 },
    { lat: 49.1564103, lng: 21.0821846 },
    { lat: 49.1579776, lng: 21.0829424 },
    { lat: 49.1581794, lng: 21.0837992 },
    { lat: 49.1583168, lng: 21.0839429 },
    { lat: 49.1585469, lng: 21.0839933 },
    { lat: 49.158679, lng: 21.0836562 },
    { lat: 49.1587882, lng: 21.0837959 },
    { lat: 49.1589997, lng: 21.0837225 },
    { lat: 49.1591857, lng: 21.0834192 },
    { lat: 49.1598042, lng: 21.0836535 },
    { lat: 49.1602966, lng: 21.0839174 },
    { lat: 49.1605714, lng: 21.0841338 },
    { lat: 49.1605114, lng: 21.0844022 },
    { lat: 49.1605321, lng: 21.0847315 },
    { lat: 49.1608387, lng: 21.0849621 },
    { lat: 49.1610874, lng: 21.0847166 },
    { lat: 49.1616522, lng: 21.0853401 },
    { lat: 49.1628879, lng: 21.0861487 },
    { lat: 49.1642811, lng: 21.0868197 },
    { lat: 49.1663213, lng: 21.0882221 },
    { lat: 49.1671008, lng: 21.0888735 },
    { lat: 49.1678257, lng: 21.0857559 },
    { lat: 49.1695778, lng: 21.0808089 },
    { lat: 49.1696811, lng: 21.0788793 },
    { lat: 49.169855, lng: 21.0769005 },
    { lat: 49.170572, lng: 21.07442 },
    { lat: 49.17073, lng: 21.0735623 },
    { lat: 49.1710992, lng: 21.0730013 },
    { lat: 49.171476, lng: 21.072297 },
    { lat: 49.1719479, lng: 21.0717121 },
    { lat: 49.1724223, lng: 21.0712425 },
    { lat: 49.1725407, lng: 21.0710081 },
    { lat: 49.1734394, lng: 21.0703631 },
  ],
  JakubovaVoľa: [
    { lat: 49.1039061, lng: 20.9992527 },
    { lat: 49.1040826, lng: 21.0001401 },
    { lat: 49.1052435, lng: 21.0008397 },
    { lat: 49.1056281, lng: 21.001384 },
    { lat: 49.1065678, lng: 21.0014461 },
    { lat: 49.1078759, lng: 21.001766 },
    { lat: 49.1088952, lng: 21.0032326 },
    { lat: 49.1090091, lng: 21.0058778 },
    { lat: 49.1091627, lng: 21.0072323 },
    { lat: 49.109358, lng: 21.0079003 },
    { lat: 49.1093991, lng: 21.0082348 },
    { lat: 49.1092386, lng: 21.0097906 },
    { lat: 49.1091501, lng: 21.0109321 },
    { lat: 49.1093607, lng: 21.011285 },
    { lat: 49.1095517, lng: 21.0113445 },
    { lat: 49.1098884, lng: 21.0111379 },
    { lat: 49.1102293, lng: 21.0108271 },
    { lat: 49.1106355, lng: 21.0118603 },
    { lat: 49.1113379, lng: 21.0119994 },
    { lat: 49.1116779, lng: 21.0119678 },
    { lat: 49.1120751, lng: 21.0120699 },
    { lat: 49.1126188, lng: 21.0147523 },
    { lat: 49.1134342, lng: 21.0168046 },
    { lat: 49.1136511, lng: 21.0176177 },
    { lat: 49.1135294, lng: 21.0179283 },
    { lat: 49.1133531, lng: 21.0178662 },
    { lat: 49.1130536, lng: 21.0181404 },
    { lat: 49.1127589, lng: 21.0191435 },
    { lat: 49.1126928, lng: 21.0198063 },
    { lat: 49.1127052, lng: 21.0209083 },
    { lat: 49.1127947, lng: 21.021375 },
    { lat: 49.1127693, lng: 21.0215917 },
    { lat: 49.1128713, lng: 21.0222033 },
    { lat: 49.1128466, lng: 21.02248 },
    { lat: 49.1126134, lng: 21.0233031 },
    { lat: 49.112361, lng: 21.0246008 },
    { lat: 49.1129698, lng: 21.0248431 },
    { lat: 49.1135212, lng: 21.0251498 },
    { lat: 49.1136751, lng: 21.0251385 },
    { lat: 49.1135642, lng: 21.0235023 },
    { lat: 49.1148782, lng: 21.0234865 },
    { lat: 49.1151029, lng: 21.0235452 },
    { lat: 49.1153643, lng: 21.0238646 },
    { lat: 49.1157394, lng: 21.0237672 },
    { lat: 49.1163035, lng: 21.0238484 },
    { lat: 49.1170203, lng: 21.0243283 },
    { lat: 49.1173912, lng: 21.0243124 },
    { lat: 49.1189203, lng: 21.0251638 },
    { lat: 49.1190337, lng: 21.0250528 },
    { lat: 49.1194095, lng: 21.0253599 },
    { lat: 49.1199009, lng: 21.0256565 },
    { lat: 49.1207598, lng: 21.026046 },
    { lat: 49.121397, lng: 21.0267045 },
    { lat: 49.1217293, lng: 21.0276164 },
    { lat: 49.1219883, lng: 21.0277905 },
    { lat: 49.1221364, lng: 21.0280879 },
    { lat: 49.1222147, lng: 21.028558 },
    { lat: 49.1223393, lng: 21.0287556 },
    { lat: 49.1224832, lng: 21.0288496 },
    { lat: 49.1229253, lng: 21.0289143 },
    { lat: 49.1234388, lng: 21.0293069 },
    { lat: 49.1237257, lng: 21.0298537 },
    { lat: 49.1239179, lng: 21.0299839 },
    { lat: 49.1240629, lng: 21.0304609 },
    { lat: 49.1246463, lng: 21.0315156 },
    { lat: 49.1255668, lng: 21.0326836 },
    { lat: 49.1258338, lng: 21.0325186 },
    { lat: 49.1259608, lng: 21.0323796 },
    { lat: 49.1259872, lng: 21.0322561 },
    { lat: 49.1258743, lng: 21.031656 },
    { lat: 49.1258774, lng: 21.030817 },
    { lat: 49.1259717, lng: 21.0300212 },
    { lat: 49.1262736, lng: 21.0290234 },
    { lat: 49.1263085, lng: 21.0287182 },
    { lat: 49.1262864, lng: 21.028279 },
    { lat: 49.1263604, lng: 21.0279579 },
    { lat: 49.1265607, lng: 21.0277164 },
    { lat: 49.126759, lng: 21.0275838 },
    { lat: 49.1277047, lng: 21.0271511 },
    { lat: 49.1284135, lng: 21.0264535 },
    { lat: 49.1285325, lng: 21.0263065 },
    { lat: 49.1288737, lng: 21.0254153 },
    { lat: 49.129153, lng: 21.0252159 },
    { lat: 49.1293351, lng: 21.0250012 },
    { lat: 49.1294946, lng: 21.0247509 },
    { lat: 49.1299079, lng: 21.0237935 },
    { lat: 49.1306346, lng: 21.022951 },
    { lat: 49.131075, lng: 21.0229313 },
    { lat: 49.1315359, lng: 21.0226373 },
    { lat: 49.1319281, lng: 21.0222431 },
    { lat: 49.1326163, lng: 21.0201205 },
    { lat: 49.1327223, lng: 21.0198839 },
    { lat: 49.1330752, lng: 21.0193906 },
    { lat: 49.1339555, lng: 21.0175053 },
    { lat: 49.1352814, lng: 21.0139306 },
    { lat: 49.1361673, lng: 21.011944 },
    { lat: 49.1363145, lng: 21.0110099 },
    { lat: 49.1371368, lng: 21.0099647 },
    { lat: 49.1375254, lng: 21.0096968 },
    { lat: 49.13783, lng: 21.0093775 },
    { lat: 49.13819, lng: 21.0087883 },
    { lat: 49.1384435, lng: 21.0078021 },
    { lat: 49.1384277, lng: 21.0073573 },
    { lat: 49.1383533, lng: 21.0070438 },
    { lat: 49.1382275, lng: 21.0070106 },
    { lat: 49.1373637, lng: 21.0067902 },
    { lat: 49.135758, lng: 21.0061814 },
    { lat: 49.1357133, lng: 21.0059476 },
    { lat: 49.1350889, lng: 21.0059934 },
    { lat: 49.1349036, lng: 21.0062113 },
    { lat: 49.1347479, lng: 21.006092 },
    { lat: 49.1346486, lng: 21.0061491 },
    { lat: 49.1339658, lng: 21.0059531 },
    { lat: 49.1337833, lng: 21.0058128 },
    { lat: 49.1337468, lng: 21.0055971 },
    { lat: 49.1333282, lng: 21.0051161 },
    { lat: 49.1327449, lng: 21.0047958 },
    { lat: 49.1325551, lng: 21.0042956 },
    { lat: 49.1320622, lng: 21.0037462 },
    { lat: 49.1315765, lng: 21.0023845 },
    { lat: 49.1315136, lng: 21.0015024 },
    { lat: 49.131299, lng: 21.0011388 },
    { lat: 49.1311524, lng: 21.0007446 },
    { lat: 49.1310806, lng: 21.0007199 },
    { lat: 49.1309554, lng: 21.0008139 },
    { lat: 49.1307234, lng: 21.0005355 },
    { lat: 49.1307472, lng: 21.0002979 },
    { lat: 49.1307066, lng: 20.9999887 },
    { lat: 49.1306061, lng: 21.0000154 },
    { lat: 49.1304078, lng: 20.9999389 },
    { lat: 49.1303058, lng: 21.0001206 },
    { lat: 49.1300456, lng: 20.9999098 },
    { lat: 49.1298643, lng: 20.9999423 },
    { lat: 49.1297519, lng: 20.9996727 },
    { lat: 49.1291904, lng: 20.9995703 },
    { lat: 49.1289948, lng: 20.9991655 },
    { lat: 49.128836, lng: 20.9992628 },
    { lat: 49.128435, lng: 20.9991169 },
    { lat: 49.1283898, lng: 20.9990489 },
    { lat: 49.1283684, lng: 20.9987713 },
    { lat: 49.1280936, lng: 20.9986614 },
    { lat: 49.1279103, lng: 20.9984289 },
    { lat: 49.127835, lng: 20.9982276 },
    { lat: 49.1277341, lng: 20.9981706 },
    { lat: 49.1275154, lng: 20.9982265 },
    { lat: 49.1272712, lng: 20.997986 },
    { lat: 49.126701, lng: 20.9977579 },
    { lat: 49.1265499, lng: 20.997888 },
    { lat: 49.1263364, lng: 20.9977289 },
    { lat: 49.1260256, lng: 20.9977857 },
    { lat: 49.125754, lng: 20.9977464 },
    { lat: 49.1255429, lng: 20.9975498 },
    { lat: 49.1253232, lng: 20.9971033 },
    { lat: 49.1251966, lng: 20.9970455 },
    { lat: 49.1249554, lng: 20.9970685 },
    { lat: 49.1248441, lng: 20.9967676 },
    { lat: 49.1246672, lng: 20.9966241 },
    { lat: 49.1246387, lng: 20.9963726 },
    { lat: 49.1243807, lng: 20.9962949 },
    { lat: 49.1240367, lng: 20.995725 },
    { lat: 49.1239123, lng: 20.9956055 },
    { lat: 49.1233389, lng: 20.9954517 },
    { lat: 49.1229442, lng: 20.9951897 },
    { lat: 49.1225823, lng: 20.9951357 },
    { lat: 49.1222988, lng: 20.9947966 },
    { lat: 49.1220173, lng: 20.9947933 },
    { lat: 49.1219539, lng: 20.994437 },
    { lat: 49.1210801, lng: 20.9942188 },
    { lat: 49.1208052, lng: 20.9939355 },
    { lat: 49.1206171, lng: 20.9939948 },
    { lat: 49.120422, lng: 20.993885 },
    { lat: 49.1198503, lng: 20.9937842 },
    { lat: 49.1193961, lng: 20.9938275 },
    { lat: 49.1189982, lng: 20.9937756 },
    { lat: 49.1187343, lng: 20.9938274 },
    { lat: 49.118401, lng: 20.9937889 },
    { lat: 49.1181719, lng: 20.9939422 },
    { lat: 49.1180316, lng: 20.9938164 },
    { lat: 49.1178056, lng: 20.9937923 },
    { lat: 49.1173636, lng: 20.9935904 },
    { lat: 49.1171511, lng: 20.9935976 },
    { lat: 49.1164976, lng: 20.9938185 },
    { lat: 49.1164294, lng: 20.993795 },
    { lat: 49.1162722, lng: 20.9935699 },
    { lat: 49.1155781, lng: 20.9936114 },
    { lat: 49.1147336, lng: 20.9940071 },
    { lat: 49.1145395, lng: 20.9940277 },
    { lat: 49.1143723, lng: 20.9942154 },
    { lat: 49.113984, lng: 20.994484 },
    { lat: 49.1119067, lng: 20.9950413 },
    { lat: 49.1105854, lng: 20.9951425 },
    { lat: 49.1096133, lng: 20.9955943 },
    { lat: 49.1089863, lng: 20.9956633 },
    { lat: 49.1078397, lng: 20.9968956 },
    { lat: 49.1072846, lng: 20.9972656 },
    { lat: 49.106539, lng: 20.9974539 },
    { lat: 49.1061582, lng: 20.9976158 },
    { lat: 49.1053272, lng: 20.9980463 },
    { lat: 49.1049706, lng: 20.9983253 },
    { lat: 49.104372, lng: 20.9982952 },
    { lat: 49.1039061, lng: 20.9992527 },
  ],
  UzovskéPekľany: [
    { lat: 49.0987596, lng: 20.9716908 },
    { lat: 49.0982278, lng: 20.9720466 },
    { lat: 49.0986605, lng: 20.9737003 },
    { lat: 49.098896, lng: 20.9749989 },
    { lat: 49.0986414, lng: 20.9776133 },
    { lat: 49.0971771, lng: 20.9819702 },
    { lat: 49.0962786, lng: 20.9837999 },
    { lat: 49.0958027, lng: 20.9831568 },
    { lat: 49.0957303, lng: 20.9829433 },
    { lat: 49.0954492, lng: 20.9825091 },
    { lat: 49.0942192, lng: 20.9819615 },
    { lat: 49.0912539, lng: 20.9811532 },
    { lat: 49.089201, lng: 20.9806777 },
    { lat: 49.0892856, lng: 20.9800026 },
    { lat: 49.088298, lng: 20.979282 },
    { lat: 49.087716, lng: 20.980495 },
    { lat: 49.087007, lng: 20.980382 },
    { lat: 49.086397, lng: 20.980528 },
    { lat: 49.086466, lng: 20.981182 },
    { lat: 49.086603, lng: 20.982258 },
    { lat: 49.086698, lng: 20.983006 },
    { lat: 49.086988, lng: 20.986646 },
    { lat: 49.087315, lng: 20.99006 },
    { lat: 49.087283, lng: 20.990462 },
    { lat: 49.087109, lng: 20.992638 },
    { lat: 49.086865, lng: 20.992576 },
    { lat: 49.086853, lng: 20.992597 },
    { lat: 49.08661, lng: 20.992237 },
    { lat: 49.0866878, lng: 20.9927566 },
    { lat: 49.0849208, lng: 20.9965513 },
    { lat: 49.0841306, lng: 20.9979813 },
    { lat: 49.0839149, lng: 20.9985066 },
    { lat: 49.083489, lng: 20.9998272 },
    { lat: 49.0835344, lng: 21.0007982 },
    { lat: 49.0835874, lng: 21.0011051 },
    { lat: 49.083278, lng: 21.0024133 },
    { lat: 49.0824156, lng: 21.0038074 },
    { lat: 49.0820386, lng: 21.0045272 },
    { lat: 49.0818176, lng: 21.0051314 },
    { lat: 49.0817357, lng: 21.0055527 },
    { lat: 49.0815074, lng: 21.0061427 },
    { lat: 49.0792053, lng: 21.0093794 },
    { lat: 49.0774571, lng: 21.0136905 },
    { lat: 49.076864, lng: 21.0146201 },
    { lat: 49.0763386, lng: 21.0158956 },
    { lat: 49.0757736, lng: 21.0199931 },
    { lat: 49.0767508, lng: 21.0213805 },
    { lat: 49.080093, lng: 21.0288087 },
    { lat: 49.0808255, lng: 21.0307685 },
    { lat: 49.0807249, lng: 21.0315889 },
    { lat: 49.0812113, lng: 21.0322874 },
    { lat: 49.0812811, lng: 21.032388 },
    { lat: 49.0825354, lng: 21.0307531 },
    { lat: 49.0828472, lng: 21.030955 },
    { lat: 49.0830307, lng: 21.030791 },
    { lat: 49.0836336, lng: 21.0309874 },
    { lat: 49.0838009, lng: 21.0311425 },
    { lat: 49.0838253, lng: 21.0312702 },
    { lat: 49.0835423, lng: 21.031502 },
    { lat: 49.0841015, lng: 21.031631 },
    { lat: 49.0846193, lng: 21.0320504 },
    { lat: 49.0852067, lng: 21.0321478 },
    { lat: 49.0859975, lng: 21.0336259 },
    { lat: 49.08592, lng: 21.0348734 },
    { lat: 49.0861734, lng: 21.0351885 },
    { lat: 49.0861063, lng: 21.0361698 },
    { lat: 49.0858346, lng: 21.0368427 },
    { lat: 49.0851168, lng: 21.0378903 },
    { lat: 49.0856289, lng: 21.0387928 },
    { lat: 49.0861884, lng: 21.039106 },
    { lat: 49.086198, lng: 21.0388148 },
    { lat: 49.086985, lng: 21.0370241 },
    { lat: 49.0869863, lng: 21.0369267 },
    { lat: 49.0867758, lng: 21.0365473 },
    { lat: 49.0882397, lng: 21.0362961 },
    { lat: 49.0886366, lng: 21.0361455 },
    { lat: 49.0883046, lng: 21.0354229 },
    { lat: 49.0887527, lng: 21.0352204 },
    { lat: 49.0914446, lng: 21.0330077 },
    { lat: 49.0918463, lng: 21.0331773 },
    { lat: 49.0928105, lng: 21.0330643 },
    { lat: 49.0936282, lng: 21.0315266 },
    { lat: 49.0965549, lng: 21.027679 },
    { lat: 49.0968065, lng: 21.0271507 },
    { lat: 49.0973378, lng: 21.0262902 },
    { lat: 49.0971824, lng: 21.0259809 },
    { lat: 49.0982148, lng: 21.0247869 },
    { lat: 49.0993922, lng: 21.0233097 },
    { lat: 49.0997743, lng: 21.0223294 },
    { lat: 49.1000281, lng: 21.0221356 },
    { lat: 49.1006183, lng: 21.0212594 },
    { lat: 49.1007549, lng: 21.0193057 },
    { lat: 49.1014033, lng: 21.0184143 },
    { lat: 49.1025763, lng: 21.0174266 },
    { lat: 49.1025671, lng: 21.0171141 },
    { lat: 49.1032809, lng: 21.0165359 },
    { lat: 49.1046506, lng: 21.0155789 },
    { lat: 49.1059141, lng: 21.0158415 },
    { lat: 49.1063772, lng: 21.0134827 },
    { lat: 49.107242, lng: 21.0122586 },
    { lat: 49.1080741, lng: 21.0112391 },
    { lat: 49.1091501, lng: 21.0109321 },
    { lat: 49.1092386, lng: 21.0097906 },
    { lat: 49.1093991, lng: 21.0082348 },
    { lat: 49.109358, lng: 21.0079003 },
    { lat: 49.1091627, lng: 21.0072323 },
    { lat: 49.1090091, lng: 21.0058778 },
    { lat: 49.1088952, lng: 21.0032326 },
    { lat: 49.1078759, lng: 21.001766 },
    { lat: 49.1065678, lng: 21.0014461 },
    { lat: 49.1056281, lng: 21.001384 },
    { lat: 49.1052435, lng: 21.0008397 },
    { lat: 49.1040826, lng: 21.0001401 },
    { lat: 49.1039061, lng: 20.9992527 },
    { lat: 49.1037363, lng: 20.9983702 },
    { lat: 49.1036745, lng: 20.9972733 },
    { lat: 49.1040612, lng: 20.9952431 },
    { lat: 49.1041498, lng: 20.9945151 },
    { lat: 49.1044605, lng: 20.9936958 },
    { lat: 49.1045502, lng: 20.9926308 },
    { lat: 49.1050596, lng: 20.9926145 },
    { lat: 49.105393, lng: 20.9918405 },
    { lat: 49.1058511, lng: 20.9911553 },
    { lat: 49.1064238, lng: 20.9900559 },
    { lat: 49.1067713, lng: 20.9900807 },
    { lat: 49.1074248, lng: 20.9890142 },
    { lat: 49.107621, lng: 20.9883774 },
    { lat: 49.1080117, lng: 20.9881744 },
    { lat: 49.1080651, lng: 20.9876767 },
    { lat: 49.1080453, lng: 20.9869379 },
    { lat: 49.1078487, lng: 20.9858253 },
    { lat: 49.1075589, lng: 20.9849186 },
    { lat: 49.1074421, lng: 20.9842802 },
    { lat: 49.1074933, lng: 20.9825966 },
    { lat: 49.1077814, lng: 20.9802073 },
    { lat: 49.107753, lng: 20.9794813 },
    { lat: 49.1066387, lng: 20.9770655 },
    { lat: 49.1063707, lng: 20.9767183 },
    { lat: 49.105623, lng: 20.9759882 },
    { lat: 49.104304, lng: 20.9750696 },
    { lat: 49.1041311, lng: 20.9748114 },
    { lat: 49.1022017, lng: 20.9744917 },
    { lat: 49.102313, lng: 20.9733999 },
    { lat: 49.1016889, lng: 20.9722287 },
    { lat: 49.1005931, lng: 20.9715586 },
    { lat: 49.0995816, lng: 20.9707886 },
    { lat: 49.0987596, lng: 20.9716908 },
  ],
  Hubošovce: [
    { lat: 49.1069064, lng: 21.1926316 },
    { lat: 49.1056253, lng: 21.1947427 },
    { lat: 49.1047482, lng: 21.1957374 },
    { lat: 49.1039705, lng: 21.1963101 },
    { lat: 49.1039016, lng: 21.1962641 },
    { lat: 49.1030778, lng: 21.1969489 },
    { lat: 49.1027363, lng: 21.1973252 },
    { lat: 49.1020027, lng: 21.1983087 },
    { lat: 49.1016638, lng: 21.1985188 },
    { lat: 49.0998596, lng: 21.1988327 },
    { lat: 49.0983427, lng: 21.1992971 },
    { lat: 49.0970359, lng: 21.1995401 },
    { lat: 49.0963516, lng: 21.1997906 },
    { lat: 49.0959039, lng: 21.1980962 },
    { lat: 49.0955829, lng: 21.1966102 },
    { lat: 49.0954917, lng: 21.1957726 },
    { lat: 49.0954548, lng: 21.1956416 },
    { lat: 49.0953162, lng: 21.1957034 },
    { lat: 49.0953559, lng: 21.1953188 },
    { lat: 49.095634, lng: 21.1947784 },
    { lat: 49.0958803, lng: 21.1927562 },
    { lat: 49.0958708, lng: 21.1923464 },
    { lat: 49.0959453, lng: 21.1916656 },
    { lat: 49.0961485, lng: 21.1910746 },
    { lat: 49.0962725, lng: 21.1903955 },
    { lat: 49.096521, lng: 21.1894554 },
    { lat: 49.096696, lng: 21.189079 },
    { lat: 49.0969398, lng: 21.1881966 },
    { lat: 49.0972331, lng: 21.1877563 },
    { lat: 49.0976547, lng: 21.1878841 },
    { lat: 49.0984823, lng: 21.1878288 },
    { lat: 49.0999839, lng: 21.1871289 },
    { lat: 49.1007049, lng: 21.1853892 },
    { lat: 49.099514, lng: 21.1830354 },
    { lat: 49.0980092, lng: 21.1806567 },
    { lat: 49.0978392, lng: 21.1805441 },
    { lat: 49.097033, lng: 21.181217 },
    { lat: 49.0969249, lng: 21.1813922 },
    { lat: 49.0967662, lng: 21.1813858 },
    { lat: 49.0966119, lng: 21.1816009 },
    { lat: 49.0964166, lng: 21.1817261 },
    { lat: 49.0961556, lng: 21.1820528 },
    { lat: 49.0960715, lng: 21.1823792 },
    { lat: 49.0958534, lng: 21.1824643 },
    { lat: 49.0957447, lng: 21.1826201 },
    { lat: 49.0956398, lng: 21.182938 },
    { lat: 49.0951195, lng: 21.1832806 },
    { lat: 49.0946578, lng: 21.183704 },
    { lat: 49.0945994, lng: 21.1836688 },
    { lat: 49.094548, lng: 21.1837522 },
    { lat: 49.0942908, lng: 21.1838449 },
    { lat: 49.0942629, lng: 21.1839567 },
    { lat: 49.0940165, lng: 21.1841366 },
    { lat: 49.0938325, lng: 21.1844806 },
    { lat: 49.0935496, lng: 21.1846792 },
    { lat: 49.0934149, lng: 21.1846549 },
    { lat: 49.093318, lng: 21.1847222 },
    { lat: 49.0930992, lng: 21.1851399 },
    { lat: 49.0930257, lng: 21.1851269 },
    { lat: 49.0928561, lng: 21.1856081 },
    { lat: 49.0927013, lng: 21.1858377 },
    { lat: 49.0924247, lng: 21.1860247 },
    { lat: 49.0917769, lng: 21.1869761 },
    { lat: 49.0911423, lng: 21.1874102 },
    { lat: 49.0911176, lng: 21.1879506 },
    { lat: 49.0910113, lng: 21.1884024 },
    { lat: 49.0908375, lng: 21.1887798 },
    { lat: 49.0905983, lng: 21.1890478 },
    { lat: 49.0905733, lng: 21.1895458 },
    { lat: 49.0905235, lng: 21.1896811 },
    { lat: 49.09043, lng: 21.1896779 },
    { lat: 49.0903602, lng: 21.1895727 },
    { lat: 49.0902402, lng: 21.1897602 },
    { lat: 49.090266, lng: 21.1898707 },
    { lat: 49.0900801, lng: 21.1898587 },
    { lat: 49.0900357, lng: 21.1900619 },
    { lat: 49.0898875, lng: 21.1899983 },
    { lat: 49.0899477, lng: 21.1903013 },
    { lat: 49.0899323, lng: 21.1904216 },
    { lat: 49.0898681, lng: 21.190493 },
    { lat: 49.0898884, lng: 21.1906768 },
    { lat: 49.0897811, lng: 21.1908792 },
    { lat: 49.08971, lng: 21.1908757 },
    { lat: 49.0896211, lng: 21.1911259 },
    { lat: 49.089538, lng: 21.1912034 },
    { lat: 49.0895455, lng: 21.1913691 },
    { lat: 49.089448, lng: 21.1915617 },
    { lat: 49.0893689, lng: 21.1915129 },
    { lat: 49.0892857, lng: 21.1916266 },
    { lat: 49.089309, lng: 21.1917998 },
    { lat: 49.0892293, lng: 21.1918756 },
    { lat: 49.0892127, lng: 21.1919931 },
    { lat: 49.0892852, lng: 21.1923688 },
    { lat: 49.0893821, lng: 21.1924128 },
    { lat: 49.089379, lng: 21.1926008 },
    { lat: 49.0893041, lng: 21.1926493 },
    { lat: 49.0893005, lng: 21.1927892 },
    { lat: 49.0892239, lng: 21.1928206 },
    { lat: 49.0892111, lng: 21.1929468 },
    { lat: 49.0893361, lng: 21.1932045 },
    { lat: 49.0891061, lng: 21.1934427 },
    { lat: 49.0891487, lng: 21.193585 },
    { lat: 49.0891139, lng: 21.1936949 },
    { lat: 49.0890302, lng: 21.1937562 },
    { lat: 49.0889519, lng: 21.1936926 },
    { lat: 49.0890016, lng: 21.1939877 },
    { lat: 49.0888592, lng: 21.1940795 },
    { lat: 49.0886602, lng: 21.1939446 },
    { lat: 49.0885019, lng: 21.1941301 },
    { lat: 49.0883051, lng: 21.1949633 },
    { lat: 49.0883426, lng: 21.1953042 },
    { lat: 49.0884217, lng: 21.1955279 },
    { lat: 49.088439, lng: 21.1958322 },
    { lat: 49.088352, lng: 21.1961124 },
    { lat: 49.0884096, lng: 21.1962583 },
    { lat: 49.0883563, lng: 21.1963559 },
    { lat: 49.0883594, lng: 21.1964942 },
    { lat: 49.0882319, lng: 21.1966248 },
    { lat: 49.0882183, lng: 21.1967331 },
    { lat: 49.0882799, lng: 21.1969768 },
    { lat: 49.0881577, lng: 21.1972771 },
    { lat: 49.0880612, lng: 21.1973204 },
    { lat: 49.0880447, lng: 21.1974063 },
    { lat: 49.0881033, lng: 21.1974836 },
    { lat: 49.088126, lng: 21.1977067 },
    { lat: 49.0879618, lng: 21.1979986 },
    { lat: 49.0878146, lng: 21.1979668 },
    { lat: 49.0877931, lng: 21.1980703 },
    { lat: 49.087841, lng: 21.1983451 },
    { lat: 49.0876782, lng: 21.1984312 },
    { lat: 49.0876964, lng: 21.1985107 },
    { lat: 49.0875048, lng: 21.198461 },
    { lat: 49.0873893, lng: 21.1985077 },
    { lat: 49.0869638, lng: 21.199087 },
    { lat: 49.0867732, lng: 21.1990294 },
    { lat: 49.0868227, lng: 21.1992103 },
    { lat: 49.0867715, lng: 21.1993756 },
    { lat: 49.0860796, lng: 21.1992012 },
    { lat: 49.0859958, lng: 21.199288 },
    { lat: 49.0859271, lng: 21.1994376 },
    { lat: 49.08602, lng: 21.1995652 },
    { lat: 49.0860411, lng: 21.1998412 },
    { lat: 49.0858827, lng: 21.2001384 },
    { lat: 49.0858057, lng: 21.2001282 },
    { lat: 49.0857332, lng: 21.1999657 },
    { lat: 49.0855456, lng: 21.1997965 },
    { lat: 49.0854566, lng: 21.2001182 },
    { lat: 49.0854928, lng: 21.2005212 },
    { lat: 49.0854636, lng: 21.200667 },
    { lat: 49.0856056, lng: 21.2009637 },
    { lat: 49.0854743, lng: 21.2011544 },
    { lat: 49.085315, lng: 21.2011724 },
    { lat: 49.0851151, lng: 21.2010393 },
    { lat: 49.0849678, lng: 21.2005713 },
    { lat: 49.0848951, lng: 21.2006243 },
    { lat: 49.0849487, lng: 21.201187 },
    { lat: 49.0848933, lng: 21.2013981 },
    { lat: 49.0846782, lng: 21.2013214 },
    { lat: 49.0845779, lng: 21.2016751 },
    { lat: 49.0844493, lng: 21.2017026 },
    { lat: 49.0843545, lng: 21.2018376 },
    { lat: 49.0843651, lng: 21.2021388 },
    { lat: 49.0842904, lng: 21.2023079 },
    { lat: 49.0844099, lng: 21.202314 },
    { lat: 49.0845627, lng: 21.2026706 },
    { lat: 49.0844663, lng: 21.2031184 },
    { lat: 49.0845172, lng: 21.2034123 },
    { lat: 49.084383, lng: 21.203595 },
    { lat: 49.084435, lng: 21.203734 },
    { lat: 49.084829, lng: 21.204067 },
    { lat: 49.084994, lng: 21.20365 },
    { lat: 49.085111, lng: 21.203572 },
    { lat: 49.085435, lng: 21.20377 },
    { lat: 49.085528, lng: 21.203995 },
    { lat: 49.085731, lng: 21.204012 },
    { lat: 49.085887, lng: 21.203876 },
    { lat: 49.085881, lng: 21.203808 },
    { lat: 49.086294, lng: 21.203515 },
    { lat: 49.086455, lng: 21.20338 },
    { lat: 49.086611, lng: 21.203538 },
    { lat: 49.0871, lng: 21.203594 },
    { lat: 49.087387, lng: 21.203644 },
    { lat: 49.087524, lng: 21.203765 },
    { lat: 49.087592, lng: 21.203804 },
    { lat: 49.087618, lng: 21.203895 },
    { lat: 49.087348, lng: 21.204141 },
    { lat: 49.087311, lng: 21.204303 },
    { lat: 49.08703, lng: 21.204571 },
    { lat: 49.087056, lng: 21.204721 },
    { lat: 49.087075, lng: 21.204961 },
    { lat: 49.087221, lng: 21.205197 },
    { lat: 49.087251, lng: 21.205371 },
    { lat: 49.087178, lng: 21.205544 },
    { lat: 49.087254, lng: 21.205829 },
    { lat: 49.087336, lng: 21.206141 },
    { lat: 49.087492, lng: 21.20633 },
    { lat: 49.086101, lng: 21.208799 },
    { lat: 49.086064, lng: 21.208879 },
    { lat: 49.086014, lng: 21.208989 },
    { lat: 49.085985, lng: 21.209051 },
    { lat: 49.085691, lng: 21.2096 },
    { lat: 49.085111, lng: 21.210704 },
    { lat: 49.085111, lng: 21.21083 },
    { lat: 49.085185, lng: 21.210806 },
    { lat: 49.085986, lng: 21.211632 },
    { lat: 49.086601, lng: 21.212167 },
    { lat: 49.086668, lng: 21.212275 },
    { lat: 49.086751, lng: 21.212464 },
    { lat: 49.086974, lng: 21.212733 },
    { lat: 49.087336, lng: 21.213271 },
    { lat: 49.087472, lng: 21.213446 },
    { lat: 49.088137, lng: 21.21404 },
    { lat: 49.087413, lng: 21.21764 },
    { lat: 49.086781, lng: 21.218146 },
    { lat: 49.086471, lng: 21.218393 },
    { lat: 49.086282, lng: 21.218603 },
    { lat: 49.086259, lng: 21.218509 },
    { lat: 49.086221, lng: 21.218518 },
    { lat: 49.085811, lng: 21.218879 },
    { lat: 49.085639, lng: 21.219166 },
    { lat: 49.084942, lng: 21.219878 },
    { lat: 49.08532, lng: 21.221006 },
    { lat: 49.085485, lng: 21.221322 },
    { lat: 49.085916, lng: 21.223101 },
    { lat: 49.085918, lng: 21.223181 },
    { lat: 49.086377, lng: 21.22396 },
    { lat: 49.086379, lng: 21.224115 },
    { lat: 49.087123, lng: 21.225468 },
    { lat: 49.087105, lng: 21.225782 },
    { lat: 49.08705, lng: 21.226086 },
    { lat: 49.087058, lng: 21.22623 },
    { lat: 49.087165, lng: 21.226308 },
    { lat: 49.087956, lng: 21.225676 },
    { lat: 49.088043, lng: 21.225738 },
    { lat: 49.088239, lng: 21.225444 },
    { lat: 49.088306, lng: 21.225488 },
    { lat: 49.088836, lng: 21.225077 },
    { lat: 49.088894, lng: 21.225078 },
    { lat: 49.089014, lng: 21.225658 },
    { lat: 49.089171, lng: 21.226736 },
    { lat: 49.092029, lng: 21.227048 },
    { lat: 49.092605, lng: 21.227111 },
    { lat: 49.092611, lng: 21.225687 },
    { lat: 49.092551, lng: 21.22502 },
    { lat: 49.092613, lng: 21.224919 },
    { lat: 49.092727, lng: 21.224528 },
    { lat: 49.093054, lng: 21.223626 },
    { lat: 49.093329, lng: 21.222926 },
    { lat: 49.093417, lng: 21.222627 },
    { lat: 49.093569, lng: 21.222257 },
    { lat: 49.093879, lng: 21.221262 },
    { lat: 49.094397, lng: 21.220296 },
    { lat: 49.094956, lng: 21.219468 },
    { lat: 49.0951, lng: 21.219225 },
    { lat: 49.095245, lng: 21.218973 },
    { lat: 49.095302, lng: 21.219052 },
    { lat: 49.095568, lng: 21.219034 },
    { lat: 49.095773, lng: 21.219057 },
    { lat: 49.095995, lng: 21.219047 },
    { lat: 49.096549, lng: 21.2189 },
    { lat: 49.097056, lng: 21.218747 },
    { lat: 49.097334, lng: 21.218707 },
    { lat: 49.098221, lng: 21.218773 },
    { lat: 49.098583, lng: 21.218848 },
    { lat: 49.099105, lng: 21.218994 },
    { lat: 49.099337, lng: 21.219099 },
    { lat: 49.099788, lng: 21.219254 },
    { lat: 49.099841, lng: 21.219246 },
    { lat: 49.099887, lng: 21.219143 },
    { lat: 49.099922, lng: 21.219102 },
    { lat: 49.100125, lng: 21.219397 },
    { lat: 49.100579, lng: 21.220014 },
    { lat: 49.100766, lng: 21.220304 },
    { lat: 49.101, lng: 21.220714 },
    { lat: 49.101448, lng: 21.221769 },
    { lat: 49.101609, lng: 21.221653 },
    { lat: 49.102186, lng: 21.22445 },
    { lat: 49.101858, lng: 21.224753 },
    { lat: 49.101946, lng: 21.224913 },
    { lat: 49.102193, lng: 21.225138 },
    { lat: 49.102279, lng: 21.225303 },
    { lat: 49.102652, lng: 21.224927 },
    { lat: 49.103857, lng: 21.224087 },
    { lat: 49.104164, lng: 21.223869 },
    { lat: 49.1045, lng: 21.223579 },
    { lat: 49.104262, lng: 21.222909 },
    { lat: 49.104481, lng: 21.22271 },
    { lat: 49.104855, lng: 21.223613 },
    { lat: 49.105028, lng: 21.223926 },
    { lat: 49.105451, lng: 21.224103 },
    { lat: 49.105456, lng: 21.224042 },
    { lat: 49.105783, lng: 21.222032 },
    { lat: 49.106006, lng: 21.221223 },
    { lat: 49.106935, lng: 21.218957 },
    { lat: 49.107824, lng: 21.217811 },
    { lat: 49.108069, lng: 21.217717 },
    { lat: 49.108346, lng: 21.217592 },
    { lat: 49.109173, lng: 21.21582 },
    { lat: 49.109228, lng: 21.215792 },
    { lat: 49.110406, lng: 21.215224 },
    { lat: 49.11134, lng: 21.214739 },
    { lat: 49.111289, lng: 21.214436 },
    { lat: 49.1117, lng: 21.213891 },
    { lat: 49.112572, lng: 21.213043 },
    { lat: 49.113739, lng: 21.212462 },
    { lat: 49.113798, lng: 21.212457 },
    { lat: 49.11382, lng: 21.21246 },
    { lat: 49.1130203, lng: 21.2099936 },
    { lat: 49.1127719, lng: 21.2087377 },
    { lat: 49.1124003, lng: 21.2088374 },
    { lat: 49.1120604, lng: 21.2084366 },
    { lat: 49.1120093, lng: 21.2070801 },
    { lat: 49.1118525, lng: 21.2054358 },
    { lat: 49.1114111, lng: 21.2036901 },
    { lat: 49.1110268, lng: 21.2024057 },
    { lat: 49.1118424, lng: 21.2010609 },
    { lat: 49.1114454, lng: 21.2004502 },
    { lat: 49.1111326, lng: 21.2000913 },
    { lat: 49.110851, lng: 21.1995743 },
    { lat: 49.110538, lng: 21.1994637 },
    { lat: 49.1103271, lng: 21.1995544 },
    { lat: 49.1101551, lng: 21.1997072 },
    { lat: 49.1100311, lng: 21.199913 },
    { lat: 49.1097133, lng: 21.1997183 },
    { lat: 49.1096815, lng: 21.1996003 },
    { lat: 49.1093041, lng: 21.1995489 },
    { lat: 49.109253, lng: 21.1992879 },
    { lat: 49.108773, lng: 21.1994791 },
    { lat: 49.1087319, lng: 21.1993564 },
    { lat: 49.1086657, lng: 21.1993964 },
    { lat: 49.1085579, lng: 21.1985705 },
    { lat: 49.1090563, lng: 21.1979537 },
    { lat: 49.109199, lng: 21.197697 },
    { lat: 49.1099057, lng: 21.1970091 },
    { lat: 49.1101447, lng: 21.1966276 },
    { lat: 49.1099485, lng: 21.1965064 },
    { lat: 49.1095928, lng: 21.1961381 },
    { lat: 49.1094198, lng: 21.1963306 },
    { lat: 49.1091494, lng: 21.1958182 },
    { lat: 49.1088713, lng: 21.1959514 },
    { lat: 49.1086816, lng: 21.1959339 },
    { lat: 49.1083227, lng: 21.1952896 },
    { lat: 49.1070892, lng: 21.1961432 },
    { lat: 49.1066882, lng: 21.1962461 },
    { lat: 49.1063326, lng: 21.1961328 },
    { lat: 49.1058905, lng: 21.1961027 },
    { lat: 49.1054671, lng: 21.1964537 },
    { lat: 49.1053784, lng: 21.1961354 },
    { lat: 49.1061841, lng: 21.1955197 },
    { lat: 49.1065686, lng: 21.1954054 },
    { lat: 49.1067229, lng: 21.1952631 },
    { lat: 49.1064897, lng: 21.1947872 },
    { lat: 49.1064578, lng: 21.1944458 },
    { lat: 49.1065501, lng: 21.1943422 },
    { lat: 49.1074572, lng: 21.1947029 },
    { lat: 49.1075863, lng: 21.1936872 },
    { lat: 49.1077301, lng: 21.1929911 },
    { lat: 49.1069064, lng: 21.1926316 },
  ],
  Milpoš: [
    { lat: 49.2004735, lng: 20.9865345 },
    { lat: 49.2005054, lng: 20.9866804 },
    { lat: 49.2003839, lng: 20.9869585 },
    { lat: 49.2002868, lng: 20.9870538 },
    { lat: 49.1989485, lng: 20.9870473 },
    { lat: 49.1983941, lng: 20.9869559 },
    { lat: 49.1983709, lng: 20.9870743 },
    { lat: 49.1971105, lng: 20.9868326 },
    { lat: 49.1970783, lng: 20.986472 },
    { lat: 49.1965269, lng: 20.9862324 },
    { lat: 49.1965927, lng: 20.9867647 },
    { lat: 49.1964791, lng: 20.9868029 },
    { lat: 49.196647, lng: 20.9882417 },
    { lat: 49.1955427, lng: 20.9886098 },
    { lat: 49.1952437, lng: 20.9860883 },
    { lat: 49.1945783, lng: 20.9859136 },
    { lat: 49.1935881, lng: 20.986802 },
    { lat: 49.1924059, lng: 20.9869603 },
    { lat: 49.1919902, lng: 20.989545 },
    { lat: 49.1920819, lng: 20.991105 },
    { lat: 49.1911771, lng: 20.9932605 },
    { lat: 49.1887184, lng: 20.9931693 },
    { lat: 49.1884416, lng: 20.9942343 },
    { lat: 49.1861825, lng: 20.9948711 },
    { lat: 49.1851213, lng: 20.9960057 },
    { lat: 49.1852922, lng: 20.9968229 },
    { lat: 49.183928, lng: 20.997328 },
    { lat: 49.18242, lng: 20.9977756 },
    { lat: 49.180367, lng: 20.9992443 },
    { lat: 49.1803519, lng: 20.997894 },
    { lat: 49.1790332, lng: 20.9977943 },
    { lat: 49.1772699, lng: 21.0012657 },
    { lat: 49.1764283, lng: 21.0012044 },
    { lat: 49.1763766, lng: 21.0015824 },
    { lat: 49.1746171, lng: 21.0020879 },
    { lat: 49.174571, lng: 21.0027374 },
    { lat: 49.1735237, lng: 21.0027525 },
    { lat: 49.1715093, lng: 21.0031299 },
    { lat: 49.1701732, lng: 21.0037087 },
    { lat: 49.168876, lng: 21.0032419 },
    { lat: 49.1673471, lng: 21.0023302 },
    { lat: 49.1663424, lng: 21.0015858 },
    { lat: 49.1652749, lng: 21.0012886 },
    { lat: 49.1653236, lng: 21.0008519 },
    { lat: 49.1652918, lng: 21.0008104 },
    { lat: 49.165411, lng: 21.0005327 },
    { lat: 49.165433, lng: 20.9999326 },
    { lat: 49.1653947, lng: 20.9992109 },
    { lat: 49.1652715, lng: 20.998583 },
    { lat: 49.1652805, lng: 20.9979951 },
    { lat: 49.1650133, lng: 20.9972624 },
    { lat: 49.1650106, lng: 20.9965984 },
    { lat: 49.1653419, lng: 20.995619 },
    { lat: 49.1633837, lng: 20.9957898 },
    { lat: 49.1630824, lng: 20.9956636 },
    { lat: 49.1622677, lng: 20.9957014 },
    { lat: 49.1620605, lng: 20.9955906 },
    { lat: 49.1614317, lng: 20.9955358 },
    { lat: 49.1613048, lng: 20.994274 },
    { lat: 49.1613948, lng: 20.9938611 },
    { lat: 49.1606792, lng: 20.993694 },
    { lat: 49.1609084, lng: 20.9929691 },
    { lat: 49.16092, lng: 20.9929409 },
    { lat: 49.1600652, lng: 20.9922403 },
    { lat: 49.1590185, lng: 20.991694 },
    { lat: 49.1571415, lng: 20.990839 },
    { lat: 49.1570763, lng: 20.9910802 },
    { lat: 49.1567422, lng: 20.9910191 },
    { lat: 49.1567461, lng: 20.9921989 },
    { lat: 49.1565263, lng: 20.9951307 },
    { lat: 49.1565233, lng: 20.9964483 },
    { lat: 49.1565446, lng: 20.9965783 },
    { lat: 49.1566218, lng: 20.9967037 },
    { lat: 49.1573422, lng: 20.9971887 },
    { lat: 49.1573166, lng: 20.9980195 },
    { lat: 49.1574729, lng: 20.9984628 },
    { lat: 49.1574285, lng: 20.9984111 },
    { lat: 49.1573439, lng: 20.9984399 },
    { lat: 49.1572619, lng: 20.99865 },
    { lat: 49.1572767, lng: 20.999066 },
    { lat: 49.157214, lng: 20.9994825 },
    { lat: 49.1569913, lng: 20.999561 },
    { lat: 49.1569139, lng: 20.9998743 },
    { lat: 49.1569677, lng: 20.9998698 },
    { lat: 49.1569461, lng: 21.0001735 },
    { lat: 49.1568799, lng: 21.0004206 },
    { lat: 49.1567882, lng: 21.0005707 },
    { lat: 49.1559773, lng: 21.0004164 },
    { lat: 49.154643, lng: 20.9999857 },
    { lat: 49.1529537, lng: 20.9998148 },
    { lat: 49.1521051, lng: 20.9996034 },
    { lat: 49.149266, lng: 20.9985204 },
    { lat: 49.1491427, lng: 20.9991176 },
    { lat: 49.1483429, lng: 21.0065348 },
    { lat: 49.1485824, lng: 21.006532 },
    { lat: 49.1486359, lng: 21.0068241 },
    { lat: 49.1486508, lng: 21.0068701 },
    { lat: 49.1487509, lng: 21.0067945 },
    { lat: 49.1492069, lng: 21.0064966 },
    { lat: 49.1502703, lng: 21.0062808 },
    { lat: 49.1517727, lng: 21.0057332 },
    { lat: 49.1522624, lng: 21.0057074 },
    { lat: 49.1528273, lng: 21.0054662 },
    { lat: 49.1529641, lng: 21.0063079 },
    { lat: 49.1530109, lng: 21.0098688 },
    { lat: 49.1530833, lng: 21.01146 },
    { lat: 49.152972, lng: 21.0127635 },
    { lat: 49.1525852, lng: 21.0137431 },
    { lat: 49.1531338, lng: 21.0147926 },
    { lat: 49.1538857, lng: 21.0136252 },
    { lat: 49.1540256, lng: 21.0147669 },
    { lat: 49.1542359, lng: 21.0145913 },
    { lat: 49.1547454, lng: 21.014206 },
    { lat: 49.1550436, lng: 21.0141006 },
    { lat: 49.1556496, lng: 21.0139758 },
    { lat: 49.1557739, lng: 21.0140983 },
    { lat: 49.1563348, lng: 21.0123585 },
    { lat: 49.1569116, lng: 21.0110019 },
    { lat: 49.1574129, lng: 21.0101562 },
    { lat: 49.1579041, lng: 21.0099315 },
    { lat: 49.1595823, lng: 21.0122025 },
    { lat: 49.1606275, lng: 21.0121306 },
    { lat: 49.1613317, lng: 21.0110209 },
    { lat: 49.1618992, lng: 21.0106134 },
    { lat: 49.1627259, lng: 21.0101859 },
    { lat: 49.1629467, lng: 21.0106405 },
    { lat: 49.1628559, lng: 21.0107193 },
    { lat: 49.1630376, lng: 21.0111493 },
    { lat: 49.1633787, lng: 21.0116001 },
    { lat: 49.1639098, lng: 21.0117007 },
    { lat: 49.1645131, lng: 21.0117026 },
    { lat: 49.1645215, lng: 21.0117513 },
    { lat: 49.1647212, lng: 21.0118069 },
    { lat: 49.1647356, lng: 21.011899 },
    { lat: 49.1649722, lng: 21.0120453 },
    { lat: 49.1652725, lng: 21.0121422 },
    { lat: 49.1659856, lng: 21.012025 },
    { lat: 49.1662476, lng: 21.011917 },
    { lat: 49.166458, lng: 21.0119837 },
    { lat: 49.1669302, lng: 21.0105966 },
    { lat: 49.166826, lng: 21.010264 },
    { lat: 49.166714, lng: 21.0101067 },
    { lat: 49.1665695, lng: 21.0096124 },
    { lat: 49.1674932, lng: 21.007818 },
    { lat: 49.1675474, lng: 21.0076218 },
    { lat: 49.1683335, lng: 21.0096979 },
    { lat: 49.1684569, lng: 21.009449 },
    { lat: 49.1686782, lng: 21.0092929 },
    { lat: 49.1686858, lng: 21.0082566 },
    { lat: 49.1688868, lng: 21.0079636 },
    { lat: 49.1692614, lng: 21.0077204 },
    { lat: 49.1694488, lng: 21.0077705 },
    { lat: 49.1694467, lng: 21.0082964 },
    { lat: 49.1737817, lng: 21.0080879 },
    { lat: 49.1739272, lng: 21.0077207 },
    { lat: 49.1741518, lng: 21.0074928 },
    { lat: 49.1744286, lng: 21.0067419 },
    { lat: 49.1744917, lng: 21.0067371 },
    { lat: 49.1759495, lng: 21.0079234 },
    { lat: 49.1769394, lng: 21.0081808 },
    { lat: 49.1768439, lng: 21.0084251 },
    { lat: 49.1791074, lng: 21.0091631 },
    { lat: 49.1791162, lng: 21.0090837 },
    { lat: 49.1792464, lng: 21.0089691 },
    { lat: 49.1792379, lng: 21.0088482 },
    { lat: 49.179542, lng: 21.0085487 },
    { lat: 49.1795052, lng: 21.0083548 },
    { lat: 49.1797035, lng: 21.0081148 },
    { lat: 49.1797082, lng: 21.0078112 },
    { lat: 49.1799849, lng: 21.0073977 },
    { lat: 49.1825297, lng: 21.0087795 },
    { lat: 49.1826837, lng: 21.0091541 },
    { lat: 49.1825736, lng: 21.0091254 },
    { lat: 49.1823114, lng: 21.0104671 },
    { lat: 49.1818093, lng: 21.0123195 },
    { lat: 49.1814649, lng: 21.0119061 },
    { lat: 49.1810741, lng: 21.0128589 },
    { lat: 49.1816017, lng: 21.0135437 },
    { lat: 49.181333, lng: 21.0142775 },
    { lat: 49.1811208, lng: 21.014221 },
    { lat: 49.1809571, lng: 21.0147906 },
    { lat: 49.1808657, lng: 21.0149228 },
    { lat: 49.1804995, lng: 21.0163885 },
    { lat: 49.1814453, lng: 21.018403 },
    { lat: 49.181727, lng: 21.0192062 },
    { lat: 49.1825829, lng: 21.0201211 },
    { lat: 49.1830313, lng: 21.0201388 },
    { lat: 49.1839157, lng: 21.0198224 },
    { lat: 49.1842702, lng: 21.0197886 },
    { lat: 49.1850608, lng: 21.0204287 },
    { lat: 49.1850892, lng: 21.0212615 },
    { lat: 49.1852336, lng: 21.021511 },
    { lat: 49.1856458, lng: 21.0219007 },
    { lat: 49.185973, lng: 21.0219607 },
    { lat: 49.1864043, lng: 21.0218657 },
    { lat: 49.1867245, lng: 21.0219893 },
    { lat: 49.187142, lng: 21.0219896 },
    { lat: 49.187794, lng: 21.0223805 },
    { lat: 49.1881598, lng: 21.0236911 },
    { lat: 49.188287, lng: 21.0243165 },
    { lat: 49.1881952, lng: 21.0250211 },
    { lat: 49.1882251, lng: 21.0255553 },
    { lat: 49.1882911, lng: 21.0257888 },
    { lat: 49.1892199, lng: 21.0278771 },
    { lat: 49.1894131, lng: 21.0303511 },
    { lat: 49.1898542, lng: 21.031251 },
    { lat: 49.1901146, lng: 21.031508 },
    { lat: 49.1907827, lng: 21.0319072 },
    { lat: 49.1912312, lng: 21.0320276 },
    { lat: 49.1916856, lng: 21.0319183 },
    { lat: 49.1921549, lng: 21.0316072 },
    { lat: 49.1922881, lng: 21.0317946 },
    { lat: 49.1924692, lng: 21.0323102 },
    { lat: 49.1927878, lng: 21.0327664 },
    { lat: 49.1931129, lng: 21.033039 },
    { lat: 49.1934224, lng: 21.0330724 },
    { lat: 49.193858, lng: 21.0329337 },
    { lat: 49.1940335, lng: 21.0330028 },
    { lat: 49.1942059, lng: 21.0333586 },
    { lat: 49.1945398, lng: 21.033728 },
    { lat: 49.1950261, lng: 21.0334231 },
    { lat: 49.1954166, lng: 21.032901 },
    { lat: 49.1957882, lng: 21.0322183 },
    { lat: 49.1966278, lng: 21.031805 },
    { lat: 49.1968702, lng: 21.0318201 },
    { lat: 49.1971819, lng: 21.0317097 },
    { lat: 49.1976664, lng: 21.0316608 },
    { lat: 49.1979424, lng: 21.0317007 },
    { lat: 49.1981208, lng: 21.0316185 },
    { lat: 49.1985898, lng: 21.0316089 },
    { lat: 49.1988367, lng: 21.0318629 },
    { lat: 49.1994802, lng: 21.0321377 },
    { lat: 49.1997981, lng: 21.0321154 },
    { lat: 49.2001038, lng: 21.0323874 },
    { lat: 49.201205, lng: 21.0323328 },
    { lat: 49.2016444, lng: 21.0322106 },
    { lat: 49.2020222, lng: 21.0318869 },
    { lat: 49.2025471, lng: 21.0316233 },
    { lat: 49.2029705, lng: 21.0310449 },
    { lat: 49.2032558, lng: 21.0309211 },
    { lat: 49.2033922, lng: 21.0309982 },
    { lat: 49.2035991, lng: 21.0314126 },
    { lat: 49.2040821, lng: 21.0316229 },
    { lat: 49.2043087, lng: 21.0316396 },
    { lat: 49.2050115, lng: 21.0319304 },
    { lat: 49.2066295, lng: 21.032098 },
    { lat: 49.206742, lng: 21.0320625 },
    { lat: 49.2067456, lng: 21.0317519 },
    { lat: 49.2066927, lng: 21.0313356 },
    { lat: 49.2065982, lng: 21.0312362 },
    { lat: 49.2065335, lng: 21.0305961 },
    { lat: 49.2065578, lng: 21.0300317 },
    { lat: 49.206641, lng: 21.0300589 },
    { lat: 49.2068693, lng: 21.0306815 },
    { lat: 49.2070105, lng: 21.030695 },
    { lat: 49.207158, lng: 21.0310113 },
    { lat: 49.2072007, lng: 21.0310048 },
    { lat: 49.2072104, lng: 21.0314752 },
    { lat: 49.207277, lng: 21.0316613 },
    { lat: 49.207332, lng: 21.0316553 },
    { lat: 49.2074927, lng: 21.0318534 },
    { lat: 49.2080117, lng: 21.0317943 },
    { lat: 49.208724, lng: 21.0319656 },
    { lat: 49.209385, lng: 21.0320077 },
    { lat: 49.2095225, lng: 21.0318778 },
    { lat: 49.2094079, lng: 21.0316021 },
    { lat: 49.2094879, lng: 21.031438 },
    { lat: 49.209404, lng: 21.0313469 },
    { lat: 49.2094045, lng: 21.0311877 },
    { lat: 49.2093167, lng: 21.0309453 },
    { lat: 49.2090893, lng: 21.0307052 },
    { lat: 49.2090624, lng: 21.0302897 },
    { lat: 49.2089662, lng: 21.029864 },
    { lat: 49.2090111, lng: 21.0297857 },
    { lat: 49.2089779, lng: 21.0288679 },
    { lat: 49.2087259, lng: 21.0278702 },
    { lat: 49.2086281, lng: 21.0268709 },
    { lat: 49.2083548, lng: 21.0259756 },
    { lat: 49.2078703, lng: 21.0252313 },
    { lat: 49.2078404, lng: 21.0250072 },
    { lat: 49.2080155, lng: 21.0249899 },
    { lat: 49.2081175, lng: 21.0250815 },
    { lat: 49.2084402, lng: 21.0250783 },
    { lat: 49.2091338, lng: 21.0248745 },
    { lat: 49.2096823, lng: 21.0248883 },
    { lat: 49.2097433, lng: 21.0250024 },
    { lat: 49.2099178, lng: 21.0251051 },
    { lat: 49.2100774, lng: 21.0254137 },
    { lat: 49.2101609, lng: 21.025385 },
    { lat: 49.2101315, lng: 21.0257587 },
    { lat: 49.2102171, lng: 21.0260409 },
    { lat: 49.2104446, lng: 21.0261453 },
    { lat: 49.2104524, lng: 21.0263211 },
    { lat: 49.2105342, lng: 21.0265154 },
    { lat: 49.2107743, lng: 21.0266685 },
    { lat: 49.2109049, lng: 21.0262911 },
    { lat: 49.2113781, lng: 21.0260626 },
    { lat: 49.2116666, lng: 21.0260738 },
    { lat: 49.2116734, lng: 21.0259707 },
    { lat: 49.2122071, lng: 21.0257755 },
    { lat: 49.2120763, lng: 21.0256641 },
    { lat: 49.2113374, lng: 21.0239521 },
    { lat: 49.2109838, lng: 21.0234923 },
    { lat: 49.2107305, lng: 21.0229348 },
    { lat: 49.2104282, lng: 21.0227102 },
    { lat: 49.2101678, lng: 21.0223185 },
    { lat: 49.2094522, lng: 21.0201808 },
    { lat: 49.2095341, lng: 21.0193424 },
    { lat: 49.2091091, lng: 21.0190087 },
    { lat: 49.2087917, lng: 21.0189007 },
    { lat: 49.2086786, lng: 21.0185796 },
    { lat: 49.2086665, lng: 21.0162726 },
    { lat: 49.2087306, lng: 21.0159971 },
    { lat: 49.208703, lng: 21.0157361 },
    { lat: 49.2087598, lng: 21.0151703 },
    { lat: 49.2087738, lng: 21.0135999 },
    { lat: 49.2086406, lng: 21.0130661 },
    { lat: 49.208649, lng: 21.0128114 },
    { lat: 49.2085217, lng: 21.0124298 },
    { lat: 49.2084447, lng: 21.0114804 },
    { lat: 49.2077408, lng: 21.0093986 },
    { lat: 49.2077013, lng: 21.0091554 },
    { lat: 49.2076287, lng: 21.0090335 },
    { lat: 49.2076394, lng: 21.0087772 },
    { lat: 49.2075865, lng: 21.0084011 },
    { lat: 49.2075843, lng: 21.0080244 },
    { lat: 49.2076709, lng: 21.0071972 },
    { lat: 49.2080709, lng: 21.0056625 },
    { lat: 49.2080834, lng: 21.0053596 },
    { lat: 49.2080126, lng: 21.004503 },
    { lat: 49.2080948, lng: 21.0041594 },
    { lat: 49.2079662, lng: 21.0034958 },
    { lat: 49.207788, lng: 21.0029015 },
    { lat: 49.2076539, lng: 21.0020688 },
    { lat: 49.207679, lng: 21.001703 },
    { lat: 49.2076184, lng: 21.001438 },
    { lat: 49.2076459, lng: 21.0013783 },
    { lat: 49.2076034, lng: 21.0009439 },
    { lat: 49.2074207, lng: 21.0005871 },
    { lat: 49.2074165, lng: 21.0003923 },
    { lat: 49.2073019, lng: 21.0000556 },
    { lat: 49.2072376, lng: 21.0000796 },
    { lat: 49.2072729, lng: 20.9997907 },
    { lat: 49.2073569, lng: 20.9996688 },
    { lat: 49.2072985, lng: 20.9990894 },
    { lat: 49.2073214, lng: 20.9990034 },
    { lat: 49.2072416, lng: 20.9985251 },
    { lat: 49.2074156, lng: 20.9982674 },
    { lat: 49.2074176, lng: 20.9980894 },
    { lat: 49.2072529, lng: 20.9975967 },
    { lat: 49.2072944, lng: 20.9969428 },
    { lat: 49.2073945, lng: 20.9968034 },
    { lat: 49.2074403, lng: 20.9961437 },
    { lat: 49.2072418, lng: 20.9957158 },
    { lat: 49.2071286, lng: 20.995592 },
    { lat: 49.2070889, lng: 20.9951725 },
    { lat: 49.206995, lng: 20.9948475 },
    { lat: 49.2068789, lng: 20.9946728 },
    { lat: 49.2069335, lng: 20.9944476 },
    { lat: 49.2068951, lng: 20.9942037 },
    { lat: 49.2068239, lng: 20.9940896 },
    { lat: 49.2066799, lng: 20.994056 },
    { lat: 49.2063882, lng: 20.9935409 },
    { lat: 49.2063125, lng: 20.9931541 },
    { lat: 49.2063628, lng: 20.9928036 },
    { lat: 49.2062655, lng: 20.9922406 },
    { lat: 49.2061395, lng: 20.9919641 },
    { lat: 49.2057773, lng: 20.9916646 },
    { lat: 49.205623, lng: 20.991454 },
    { lat: 49.2052379, lng: 20.9905916 },
    { lat: 49.2048886, lng: 20.9902315 },
    { lat: 49.2045236, lng: 20.9895738 },
    { lat: 49.2041027, lng: 20.9890251 },
    { lat: 49.2004735, lng: 20.9865345 },
  ],
  Ostrovany: [
    { lat: 49.0594219, lng: 21.0839101 },
    { lat: 49.0592998, lng: 21.0841015 },
    { lat: 49.0587431, lng: 21.0852701 },
    { lat: 49.0584618, lng: 21.0857263 },
    { lat: 49.0582656, lng: 21.0862049 },
    { lat: 49.0578261, lng: 21.0868146 },
    { lat: 49.0575797, lng: 21.087345 },
    { lat: 49.056544, lng: 21.088768 },
    { lat: 49.0561393, lng: 21.0895535 },
    { lat: 49.0556957, lng: 21.0910221 },
    { lat: 49.0556941, lng: 21.0916326 },
    { lat: 49.0556006, lng: 21.0919147 },
    { lat: 49.0552989, lng: 21.0923318 },
    { lat: 49.0547655, lng: 21.0927156 },
    { lat: 49.0538709, lng: 21.0941968 },
    { lat: 49.0533221, lng: 21.0947309 },
    { lat: 49.0526559, lng: 21.0957165 },
    { lat: 49.052437, lng: 21.0962943 },
    { lat: 49.0516538, lng: 21.0988262 },
    { lat: 49.0515632, lng: 21.1000679 },
    { lat: 49.0514877, lng: 21.1002425 },
    { lat: 49.0510864, lng: 21.1007466 },
    { lat: 49.0507245, lng: 21.101435 },
    { lat: 49.0507091, lng: 21.1020708 },
    { lat: 49.0507866, lng: 21.1024881 },
    { lat: 49.0510231, lng: 21.1029538 },
    { lat: 49.0507964, lng: 21.1038561 },
    { lat: 49.0507407, lng: 21.1043256 },
    { lat: 49.050534, lng: 21.1046189 },
    { lat: 49.0503783, lng: 21.1047108 },
    { lat: 49.0503412, lng: 21.1046056 },
    { lat: 49.0485892, lng: 21.1057764 },
    { lat: 49.0481838, lng: 21.1058423 },
    { lat: 49.0478188, lng: 21.106109 },
    { lat: 49.047787, lng: 21.10624 },
    { lat: 49.048065, lng: 21.10642 },
    { lat: 49.048185, lng: 21.106546 },
    { lat: 49.048323, lng: 21.106784 },
    { lat: 49.048447, lng: 21.107105 },
    { lat: 49.048681, lng: 21.107466 },
    { lat: 49.048964, lng: 21.107697 },
    { lat: 49.049882, lng: 21.108589 },
    { lat: 49.05025, lng: 21.108862 },
    { lat: 49.050593, lng: 21.109052 },
    { lat: 49.050781, lng: 21.109213 },
    { lat: 49.051224, lng: 21.109557 },
    { lat: 49.051354, lng: 21.109809 },
    { lat: 49.051452, lng: 21.11 },
    { lat: 49.051457, lng: 21.110033 },
    { lat: 49.051081, lng: 21.110727 },
    { lat: 49.050766, lng: 21.111307 },
    { lat: 49.050571, lng: 21.111462 },
    { lat: 49.050181, lng: 21.111959 },
    { lat: 49.049972, lng: 21.112109 },
    { lat: 49.049487, lng: 21.112499 },
    { lat: 49.049323, lng: 21.112621 },
    { lat: 49.049147, lng: 21.112703 },
    { lat: 49.048775, lng: 21.112962 },
    { lat: 49.048639, lng: 21.113078 },
    { lat: 49.048365, lng: 21.113507 },
    { lat: 49.048279, lng: 21.114019 },
    { lat: 49.048084, lng: 21.114489 },
    { lat: 49.047963, lng: 21.114673 },
    { lat: 49.047842, lng: 21.114975 },
    { lat: 49.047681, lng: 21.115957 },
    { lat: 49.047596, lng: 21.116226 },
    { lat: 49.047484, lng: 21.116497 },
    { lat: 49.047327, lng: 21.117242 },
    { lat: 49.047288, lng: 21.117492 },
    { lat: 49.047197, lng: 21.117907 },
    { lat: 49.047122, lng: 21.118257 },
    { lat: 49.046985, lng: 21.119076 },
    { lat: 49.046878, lng: 21.11929 },
    { lat: 49.046657, lng: 21.119732 },
    { lat: 49.046411, lng: 21.120364 },
    { lat: 49.046122, lng: 21.121101 },
    { lat: 49.046122, lng: 21.121537 },
    { lat: 49.046068, lng: 21.121821 },
    { lat: 49.04596, lng: 21.122035 },
    { lat: 49.045894, lng: 21.122132 },
    { lat: 49.045862, lng: 21.122262 },
    { lat: 49.045795, lng: 21.122389 },
    { lat: 49.04569, lng: 21.122577 },
    { lat: 49.045529, lng: 21.122701 },
    { lat: 49.045448, lng: 21.122803 },
    { lat: 49.045269, lng: 21.12297 },
    { lat: 49.045262, lng: 21.123128 },
    { lat: 49.045489, lng: 21.123536 },
    { lat: 49.04561, lng: 21.12361 },
    { lat: 49.045794, lng: 21.123586 },
    { lat: 49.046202, lng: 21.12334 },
    { lat: 49.046585, lng: 21.123415 },
    { lat: 49.046579, lng: 21.123446 },
    { lat: 49.047335, lng: 21.124618 },
    { lat: 49.047718, lng: 21.125258 },
    { lat: 49.0481, lng: 21.125894 },
    { lat: 49.048406, lng: 21.126148 },
    { lat: 49.048458, lng: 21.126196 },
    { lat: 49.048568, lng: 21.1264 },
    { lat: 49.048682, lng: 21.126544 },
    { lat: 49.04876, lng: 21.126728 },
    { lat: 49.048922, lng: 21.12693 },
    { lat: 49.04906, lng: 21.127232 },
    { lat: 49.049081, lng: 21.12741 },
    { lat: 49.04912, lng: 21.12757 },
    { lat: 49.049109, lng: 21.127789 },
    { lat: 49.049187, lng: 21.12783 },
    { lat: 49.049337, lng: 21.127854 },
    { lat: 49.049547, lng: 21.127971 },
    { lat: 49.049653, lng: 21.128004 },
    { lat: 49.049719, lng: 21.128071 },
    { lat: 49.049781, lng: 21.128265 },
    { lat: 49.049949, lng: 21.128398 },
    { lat: 49.050051, lng: 21.128526 },
    { lat: 49.050127, lng: 21.128543 },
    { lat: 49.05013, lng: 21.128694 },
    { lat: 49.050263, lng: 21.128809 },
    { lat: 49.050316, lng: 21.129044 },
    { lat: 49.050434, lng: 21.129144 },
    { lat: 49.050464, lng: 21.129268 },
    { lat: 49.050446, lng: 21.129464 },
    { lat: 49.05049, lng: 21.129748 },
    { lat: 49.050584, lng: 21.129907 },
    { lat: 49.05061, lng: 21.130204 },
    { lat: 49.050655, lng: 21.130586 },
    { lat: 49.05069, lng: 21.130623 },
    { lat: 49.050758, lng: 21.130755 },
    { lat: 49.050866, lng: 21.130889 },
    { lat: 49.050975, lng: 21.130952 },
    { lat: 49.051062, lng: 21.13108 },
    { lat: 49.05106, lng: 21.131178 },
    { lat: 49.051067, lng: 21.131221 },
    { lat: 49.051093, lng: 21.131365 },
    { lat: 49.051107, lng: 21.131501 },
    { lat: 49.051225, lng: 21.131602 },
    { lat: 49.051237, lng: 21.131694 },
    { lat: 49.051282, lng: 21.131785 },
    { lat: 49.051302, lng: 21.131893 },
    { lat: 49.051356, lng: 21.131986 },
    { lat: 49.051412, lng: 21.132419 },
    { lat: 49.051406, lng: 21.13253 },
    { lat: 49.051431, lng: 21.132786 },
    { lat: 49.051528, lng: 21.132974 },
    { lat: 49.051553, lng: 21.133086 },
    { lat: 49.051574, lng: 21.133211 },
    { lat: 49.051595, lng: 21.133419 },
    { lat: 49.051588, lng: 21.133584 },
    { lat: 49.05149, lng: 21.134031 },
    { lat: 49.05144, lng: 21.13419 },
    { lat: 49.051423, lng: 21.134315 },
    { lat: 49.051527, lng: 21.134527 },
    { lat: 49.051523, lng: 21.134715 },
    { lat: 49.051483, lng: 21.134976 },
    { lat: 49.051529, lng: 21.135288 },
    { lat: 49.051516, lng: 21.13546 },
    { lat: 49.051473, lng: 21.135602 },
    { lat: 49.051499, lng: 21.135855 },
    { lat: 49.051499, lng: 21.136002 },
    { lat: 49.051434, lng: 21.136421 },
    { lat: 49.051464, lng: 21.136555 },
    { lat: 49.05137, lng: 21.136937 },
    { lat: 49.051289, lng: 21.137098 },
    { lat: 49.051292, lng: 21.13719 },
    { lat: 49.051513, lng: 21.137293 },
    { lat: 49.051773, lng: 21.137381 },
    { lat: 49.052048, lng: 21.137442 },
    { lat: 49.052263, lng: 21.137413 },
    { lat: 49.052421, lng: 21.137375 },
    { lat: 49.052664, lng: 21.137384 },
    { lat: 49.052766, lng: 21.137413 },
    { lat: 49.053024, lng: 21.137403 },
    { lat: 49.053109, lng: 21.137406 },
    { lat: 49.05328, lng: 21.137444 },
    { lat: 49.053414, lng: 21.137501 },
    { lat: 49.053737, lng: 21.137714 },
    { lat: 49.053976, lng: 21.137829 },
    { lat: 49.054136, lng: 21.137888 },
    { lat: 49.054183, lng: 21.137905 },
    { lat: 49.054238, lng: 21.137944 },
    { lat: 49.05426, lng: 21.13796 },
    { lat: 49.054496, lng: 21.138125 },
    { lat: 49.054708, lng: 21.138277 },
    { lat: 49.055048, lng: 21.138614 },
    { lat: 49.055296, lng: 21.138813 },
    { lat: 49.055588, lng: 21.139046 },
    { lat: 49.055705, lng: 21.139082 },
    { lat: 49.05575, lng: 21.139028 },
    { lat: 49.05583, lng: 21.139037 },
    { lat: 49.055999, lng: 21.138987 },
    { lat: 49.056063, lng: 21.138978 },
    { lat: 49.056138, lng: 21.13897 },
    { lat: 49.056159, lng: 21.138947 },
    { lat: 49.056165, lng: 21.138929 },
    { lat: 49.056191, lng: 21.13901 },
    { lat: 49.0565923, lng: 21.1371722 },
    { lat: 49.0570637, lng: 21.1360719 },
    { lat: 49.0577785, lng: 21.1362135 },
    { lat: 49.0587947, lng: 21.1341951 },
    { lat: 49.0590329, lng: 21.134498 },
    { lat: 49.0598191, lng: 21.133102 },
    { lat: 49.0603732, lng: 21.1319106 },
    { lat: 49.0606271, lng: 21.1311596 },
    { lat: 49.0602498, lng: 21.1307208 },
    { lat: 49.060222, lng: 21.1306267 },
    { lat: 49.0604493, lng: 21.1301643 },
    { lat: 49.0607645, lng: 21.130234 },
    { lat: 49.0608434, lng: 21.129602 },
    { lat: 49.0608037, lng: 21.1292052 },
    { lat: 49.0608301, lng: 21.1290788 },
    { lat: 49.0609155, lng: 21.1289307 },
    { lat: 49.0610958, lng: 21.128803 },
    { lat: 49.061149, lng: 21.1286667 },
    { lat: 49.0611369, lng: 21.1283597 },
    { lat: 49.0610359, lng: 21.1282239 },
    { lat: 49.0616092, lng: 21.126719 },
    { lat: 49.0623808, lng: 21.1268158 },
    { lat: 49.0630454, lng: 21.1270464 },
    { lat: 49.0630668, lng: 21.1271188 },
    { lat: 49.0633419, lng: 21.1273469 },
    { lat: 49.0635602, lng: 21.1276627 },
    { lat: 49.0637888, lng: 21.1269601 },
    { lat: 49.0640049, lng: 21.1265042 },
    { lat: 49.0640957, lng: 21.1260299 },
    { lat: 49.0643578, lng: 21.1238323 },
    { lat: 49.0644552, lng: 21.1234307 },
    { lat: 49.0646232, lng: 21.1231226 },
    { lat: 49.0656638, lng: 21.1224028 },
    { lat: 49.0659839, lng: 21.1219596 },
    { lat: 49.06675, lng: 21.1201431 },
    { lat: 49.066839, lng: 21.1194428 },
    { lat: 49.06697, lng: 21.1194533 },
    { lat: 49.0676659, lng: 21.118308 },
    { lat: 49.0690795, lng: 21.1148489 },
    { lat: 49.0681892, lng: 21.1141926 },
    { lat: 49.0681128, lng: 21.1141441 },
    { lat: 49.0680214, lng: 21.1139895 },
    { lat: 49.0679402, lng: 21.1138482 },
    { lat: 49.0678429, lng: 21.1137446 },
    { lat: 49.0674692, lng: 21.1133371 },
    { lat: 49.0674914, lng: 21.1129755 },
    { lat: 49.0674232, lng: 21.1122139 },
    { lat: 49.0673851, lng: 21.1118334 },
    { lat: 49.067244, lng: 21.1113934 },
    { lat: 49.0668961, lng: 21.1106838 },
    { lat: 49.0665702, lng: 21.1091119 },
    { lat: 49.0664851, lng: 21.1089861 },
    { lat: 49.0662454, lng: 21.1094395 },
    { lat: 49.0660702, lng: 21.1099819 },
    { lat: 49.0658924, lng: 21.1097007 },
    { lat: 49.0658595, lng: 21.1094828 },
    { lat: 49.0658038, lng: 21.1078787 },
    { lat: 49.0658964, lng: 21.1069761 },
    { lat: 49.0658917, lng: 21.105804 },
    { lat: 49.0659925, lng: 21.1056533 },
    { lat: 49.0660293, lng: 21.105357 },
    { lat: 49.0660055, lng: 21.1048585 },
    { lat: 49.0659383, lng: 21.1047608 },
    { lat: 49.0659534, lng: 21.1042906 },
    { lat: 49.0658783, lng: 21.1041189 },
    { lat: 49.0659152, lng: 21.1040121 },
    { lat: 49.0659905, lng: 21.1025239 },
    { lat: 49.0659706, lng: 21.1022207 },
    { lat: 49.0661104, lng: 21.1012087 },
    { lat: 49.0662347, lng: 21.1010487 },
    { lat: 49.0662197, lng: 21.1009687 },
    { lat: 49.0663045, lng: 21.1007367 },
    { lat: 49.0663097, lng: 21.1005827 },
    { lat: 49.0664674, lng: 21.1000948 },
    { lat: 49.0665027, lng: 21.0995789 },
    { lat: 49.0666169, lng: 21.0988968 },
    { lat: 49.0666229, lng: 21.0980609 },
    { lat: 49.0665345, lng: 21.096957 },
    { lat: 49.0665645, lng: 21.0962093 },
    { lat: 49.0664362, lng: 21.0952219 },
    { lat: 49.0662873, lng: 21.0947563 },
    { lat: 49.0661097, lng: 21.0944103 },
    { lat: 49.0660463, lng: 21.0943683 },
    { lat: 49.0659097, lng: 21.0939679 },
    { lat: 49.065704, lng: 21.094009 },
    { lat: 49.0655465, lng: 21.0937993 },
    { lat: 49.0650777, lng: 21.09343 },
    { lat: 49.0650124, lng: 21.0933584 },
    { lat: 49.064966, lng: 21.0931031 },
    { lat: 49.0647265, lng: 21.0928259 },
    { lat: 49.0646003, lng: 21.0920533 },
    { lat: 49.0646085, lng: 21.0912965 },
    { lat: 49.0645116, lng: 21.0911501 },
    { lat: 49.064072, lng: 21.0908095 },
    { lat: 49.0639356, lng: 21.0905215 },
    { lat: 49.0638923, lng: 21.0902975 },
    { lat: 49.0635434, lng: 21.0898095 },
    { lat: 49.0631655, lng: 21.0889685 },
    { lat: 49.0624608, lng: 21.0880186 },
    { lat: 49.0615301, lng: 21.0869 },
    { lat: 49.0610345, lng: 21.0861172 },
    { lat: 49.0597634, lng: 21.084386 },
    { lat: 49.0596185, lng: 21.0840096 },
    { lat: 49.0594219, lng: 21.0839101 },
  ],
  UzovskýŠalgov: [
    { lat: 49.1136751, lng: 21.0251385 },
    { lat: 49.1135212, lng: 21.0251498 },
    { lat: 49.1129698, lng: 21.0248431 },
    { lat: 49.112361, lng: 21.0246008 },
    { lat: 49.1126134, lng: 21.0233031 },
    { lat: 49.1128466, lng: 21.02248 },
    { lat: 49.1128713, lng: 21.0222033 },
    { lat: 49.1127693, lng: 21.0215917 },
    { lat: 49.1127947, lng: 21.021375 },
    { lat: 49.1127052, lng: 21.0209083 },
    { lat: 49.1126928, lng: 21.0198063 },
    { lat: 49.1127589, lng: 21.0191435 },
    { lat: 49.1130536, lng: 21.0181404 },
    { lat: 49.1133531, lng: 21.0178662 },
    { lat: 49.1135294, lng: 21.0179283 },
    { lat: 49.1136511, lng: 21.0176177 },
    { lat: 49.1134342, lng: 21.0168046 },
    { lat: 49.1126188, lng: 21.0147523 },
    { lat: 49.1120751, lng: 21.0120699 },
    { lat: 49.1116779, lng: 21.0119678 },
    { lat: 49.1113379, lng: 21.0119994 },
    { lat: 49.1106355, lng: 21.0118603 },
    { lat: 49.1102293, lng: 21.0108271 },
    { lat: 49.1098884, lng: 21.0111379 },
    { lat: 49.1095517, lng: 21.0113445 },
    { lat: 49.1093607, lng: 21.011285 },
    { lat: 49.1091501, lng: 21.0109321 },
    { lat: 49.1080741, lng: 21.0112391 },
    { lat: 49.107242, lng: 21.0122586 },
    { lat: 49.1063772, lng: 21.0134827 },
    { lat: 49.1059141, lng: 21.0158415 },
    { lat: 49.1046506, lng: 21.0155789 },
    { lat: 49.1032809, lng: 21.0165359 },
    { lat: 49.1025671, lng: 21.0171141 },
    { lat: 49.1025763, lng: 21.0174266 },
    { lat: 49.1014033, lng: 21.0184143 },
    { lat: 49.1007549, lng: 21.0193057 },
    { lat: 49.1006183, lng: 21.0212594 },
    { lat: 49.1000281, lng: 21.0221356 },
    { lat: 49.0997743, lng: 21.0223294 },
    { lat: 49.0993922, lng: 21.0233097 },
    { lat: 49.0982148, lng: 21.0247869 },
    { lat: 49.0971824, lng: 21.0259809 },
    { lat: 49.0973378, lng: 21.0262902 },
    { lat: 49.0968065, lng: 21.0271507 },
    { lat: 49.0965549, lng: 21.027679 },
    { lat: 49.0936282, lng: 21.0315266 },
    { lat: 49.0928105, lng: 21.0330643 },
    { lat: 49.0918463, lng: 21.0331773 },
    { lat: 49.0914446, lng: 21.0330077 },
    { lat: 49.0887527, lng: 21.0352204 },
    { lat: 49.0883046, lng: 21.0354229 },
    { lat: 49.0886366, lng: 21.0361455 },
    { lat: 49.0882397, lng: 21.0362961 },
    { lat: 49.0867758, lng: 21.0365473 },
    { lat: 49.0869863, lng: 21.0369267 },
    { lat: 49.086985, lng: 21.0370241 },
    { lat: 49.086198, lng: 21.0388148 },
    { lat: 49.0861884, lng: 21.039106 },
    { lat: 49.0863023, lng: 21.0391491 },
    { lat: 49.0865516, lng: 21.0394474 },
    { lat: 49.0865586, lng: 21.0395916 },
    { lat: 49.0866738, lng: 21.0398112 },
    { lat: 49.0874427, lng: 21.0385027 },
    { lat: 49.087649, lng: 21.0384437 },
    { lat: 49.087723, lng: 21.0392327 },
    { lat: 49.0878104, lng: 21.0393582 },
    { lat: 49.087992, lng: 21.0401643 },
    { lat: 49.0880073, lng: 21.0406331 },
    { lat: 49.0879412, lng: 21.0418593 },
    { lat: 49.0879561, lng: 21.0421411 },
    { lat: 49.0880073, lng: 21.0422941 },
    { lat: 49.0880422, lng: 21.0430297 },
    { lat: 49.0879495, lng: 21.0432518 },
    { lat: 49.087897, lng: 21.0447225 },
    { lat: 49.0880034, lng: 21.0449479 },
    { lat: 49.0883055, lng: 21.0449334 },
    { lat: 49.088442, lng: 21.0451145 },
    { lat: 49.0889168, lng: 21.0454942 },
    { lat: 49.0891371, lng: 21.0455878 },
    { lat: 49.0895222, lng: 21.0460102 },
    { lat: 49.0897096, lng: 21.0463412 },
    { lat: 49.0898225, lng: 21.0463836 },
    { lat: 49.0899672, lng: 21.0463365 },
    { lat: 49.0902097, lng: 21.0465287 },
    { lat: 49.0902706, lng: 21.049131 },
    { lat: 49.0895836, lng: 21.0496674 },
    { lat: 49.0897278, lng: 21.049882 },
    { lat: 49.0897522, lng: 21.050045 },
    { lat: 49.0897108, lng: 21.050186 },
    { lat: 49.0897515, lng: 21.0503711 },
    { lat: 49.0895664, lng: 21.0505997 },
    { lat: 49.0894477, lng: 21.0511333 },
    { lat: 49.0897786, lng: 21.0513102 },
    { lat: 49.0897789, lng: 21.0515967 },
    { lat: 49.0898495, lng: 21.0518298 },
    { lat: 49.0896612, lng: 21.0521991 },
    { lat: 49.0892412, lng: 21.0525036 },
    { lat: 49.0887847, lng: 21.0530736 },
    { lat: 49.0882884, lng: 21.0533166 },
    { lat: 49.087599, lng: 21.0543059 },
    { lat: 49.0870199, lng: 21.0546629 },
    { lat: 49.0864747, lng: 21.0553433 },
    { lat: 49.0853964, lng: 21.0538111 },
    { lat: 49.0854654, lng: 21.0535089 },
    { lat: 49.0854465, lng: 21.0532756 },
    { lat: 49.0853725, lng: 21.0531098 },
    { lat: 49.0852681, lng: 21.0530096 },
    { lat: 49.0849386, lng: 21.052885 },
    { lat: 49.0846149, lng: 21.0524785 },
    { lat: 49.0835973, lng: 21.0532719 },
    { lat: 49.0825944, lng: 21.0543069 },
    { lat: 49.0823688, lng: 21.0544646 },
    { lat: 49.0824012, lng: 21.0545986 },
    { lat: 49.0822766, lng: 21.0547763 },
    { lat: 49.0817475, lng: 21.0551575 },
    { lat: 49.0814581, lng: 21.0555354 },
    { lat: 49.0809888, lng: 21.055702 },
    { lat: 49.080901, lng: 21.0556705 },
    { lat: 49.0809323, lng: 21.0557992 },
    { lat: 49.0809621, lng: 21.0559553 },
    { lat: 49.0811849, lng: 21.0563392 },
    { lat: 49.0814417, lng: 21.0564499 },
    { lat: 49.0825461, lng: 21.0574046 },
    { lat: 49.0828187, lng: 21.0576868 },
    { lat: 49.0828627, lng: 21.057896 },
    { lat: 49.0830272, lng: 21.0579716 },
    { lat: 49.0831716, lng: 21.0577774 },
    { lat: 49.083511, lng: 21.0576919 },
    { lat: 49.0837416, lng: 21.0574793 },
    { lat: 49.0840473, lng: 21.0574294 },
    { lat: 49.0842499, lng: 21.0571906 },
    { lat: 49.0852786, lng: 21.0570893 },
    { lat: 49.0857184, lng: 21.05722 },
    { lat: 49.0858012, lng: 21.0569575 },
    { lat: 49.0859626, lng: 21.0570132 },
    { lat: 49.086062, lng: 21.0569232 },
    { lat: 49.086115, lng: 21.0571296 },
    { lat: 49.086367, lng: 21.0573031 },
    { lat: 49.0865968, lng: 21.0573422 },
    { lat: 49.0868175, lng: 21.0575239 },
    { lat: 49.0870814, lng: 21.0575393 },
    { lat: 49.0874061, lng: 21.0579012 },
    { lat: 49.0874954, lng: 21.058227 },
    { lat: 49.0877512, lng: 21.0586559 },
    { lat: 49.0877566, lng: 21.0589614 },
    { lat: 49.087567, lng: 21.0594345 },
    { lat: 49.0871243, lng: 21.059839 },
    { lat: 49.0869625, lng: 21.0598472 },
    { lat: 49.0868431, lng: 21.06005 },
    { lat: 49.0869026, lng: 21.0601806 },
    { lat: 49.0870506, lng: 21.060135 },
    { lat: 49.087872, lng: 21.0620396 },
    { lat: 49.0881881, lng: 21.062422 },
    { lat: 49.0880728, lng: 21.0627857 },
    { lat: 49.0881888, lng: 21.063033 },
    { lat: 49.0889466, lng: 21.0631536 },
    { lat: 49.0890234, lng: 21.0638585 },
    { lat: 49.0889517, lng: 21.0641425 },
    { lat: 49.0889535, lng: 21.0644468 },
    { lat: 49.0890216, lng: 21.064599 },
    { lat: 49.0890746, lng: 21.0650909 },
    { lat: 49.0890761, lng: 21.0655899 },
    { lat: 49.0889803, lng: 21.066071 },
    { lat: 49.0889615, lng: 21.0677909 },
    { lat: 49.0893347, lng: 21.0677407 },
    { lat: 49.0894428, lng: 21.0679204 },
    { lat: 49.0894822, lng: 21.0682715 },
    { lat: 49.0894276, lng: 21.0684278 },
    { lat: 49.0894983, lng: 21.0688372 },
    { lat: 49.0899423, lng: 21.0686162 },
    { lat: 49.0913596, lng: 21.068103 },
    { lat: 49.0916297, lng: 21.0693934 },
    { lat: 49.0919404, lng: 21.0692563 },
    { lat: 49.0923949, lng: 21.0688338 },
    { lat: 49.0949087, lng: 21.0662763 },
    { lat: 49.0951929, lng: 21.0658823 },
    { lat: 49.0956065, lng: 21.065267 },
    { lat: 49.0967881, lng: 21.0631125 },
    { lat: 49.096879, lng: 21.0630651 },
    { lat: 49.097143, lng: 21.0626015 },
    { lat: 49.0973613, lng: 21.0621096 },
    { lat: 49.097475, lng: 21.0616224 },
    { lat: 49.0974204, lng: 21.0612964 },
    { lat: 49.0975929, lng: 21.0609563 },
    { lat: 49.0978372, lng: 21.0607164 },
    { lat: 49.098055, lng: 21.0606251 },
    { lat: 49.0980455, lng: 21.060165 },
    { lat: 49.0982276, lng: 21.0600131 },
    { lat: 49.0982587, lng: 21.0598297 },
    { lat: 49.0983728, lng: 21.0596039 },
    { lat: 49.0984171, lng: 21.059296 },
    { lat: 49.0987432, lng: 21.0589814 },
    { lat: 49.0989361, lng: 21.0588847 },
    { lat: 49.0989507, lng: 21.0585726 },
    { lat: 49.0991082, lng: 21.0584688 },
    { lat: 49.0992045, lng: 21.0582458 },
    { lat: 49.0993355, lng: 21.0581526 },
    { lat: 49.0994893, lng: 21.0575506 },
    { lat: 49.0994861, lng: 21.056963 },
    { lat: 49.0995883, lng: 21.0562291 },
    { lat: 49.0995763, lng: 21.0559607 },
    { lat: 49.0996813, lng: 21.0556299 },
    { lat: 49.0996272, lng: 21.0553779 },
    { lat: 49.0996294, lng: 21.0549586 },
    { lat: 49.0998432, lng: 21.0544387 },
    { lat: 49.0998067, lng: 21.0541487 },
    { lat: 49.099927, lng: 21.0537924 },
    { lat: 49.1003097, lng: 21.0513308 },
    { lat: 49.1005272, lng: 21.0510278 },
    { lat: 49.1007283, lng: 21.0502556 },
    { lat: 49.1009551, lng: 21.0500292 },
    { lat: 49.1015175, lng: 21.0484576 },
    { lat: 49.1018414, lng: 21.0480029 },
    { lat: 49.102031, lng: 21.0476029 },
    { lat: 49.1020444, lng: 21.0474277 },
    { lat: 49.1021433, lng: 21.0471459 },
    { lat: 49.1023164, lng: 21.0468621 },
    { lat: 49.1024262, lng: 21.0464594 },
    { lat: 49.1027183, lng: 21.0458236 },
    { lat: 49.1029359, lng: 21.0454897 },
    { lat: 49.1039631, lng: 21.0447152 },
    { lat: 49.1044611, lng: 21.0441106 },
    { lat: 49.1049167, lng: 21.0436901 },
    { lat: 49.1055902, lng: 21.0432525 },
    { lat: 49.1059964, lng: 21.0433214 },
    { lat: 49.106563, lng: 21.0431968 },
    { lat: 49.1069231, lng: 21.0432311 },
    { lat: 49.1072393, lng: 21.0434404 },
    { lat: 49.1075647, lng: 21.0434479 },
    { lat: 49.1079214, lng: 21.0436178 },
    { lat: 49.1080633, lng: 21.0436147 },
    { lat: 49.1084795, lng: 21.0439271 },
    { lat: 49.1085695, lng: 21.0433725 },
    { lat: 49.1087404, lng: 21.0429131 },
    { lat: 49.108834, lng: 21.0424707 },
    { lat: 49.1088985, lng: 21.0416824 },
    { lat: 49.1092726, lng: 21.0406908 },
    { lat: 49.1092499, lng: 21.0401375 },
    { lat: 49.109333, lng: 21.0398848 },
    { lat: 49.1096608, lng: 21.0395153 },
    { lat: 49.1097538, lng: 21.0390553 },
    { lat: 49.1101205, lng: 21.0384945 },
    { lat: 49.1103194, lng: 21.0385557 },
    { lat: 49.1107505, lng: 21.0379699 },
    { lat: 49.1107828, lng: 21.0375048 },
    { lat: 49.1110668, lng: 21.0364775 },
    { lat: 49.111331, lng: 21.0358501 },
    { lat: 49.1116858, lng: 21.0353373 },
    { lat: 49.1117197, lng: 21.0343458 },
    { lat: 49.1116142, lng: 21.0338354 },
    { lat: 49.1117254, lng: 21.0332748 },
    { lat: 49.1118352, lng: 21.0321554 },
    { lat: 49.1120778, lng: 21.0310666 },
    { lat: 49.1123273, lng: 21.0304029 },
    { lat: 49.1123998, lng: 21.0298262 },
    { lat: 49.113486, lng: 21.0270691 },
    { lat: 49.1137199, lng: 21.0267575 },
    { lat: 49.1139128, lng: 21.0262497 },
    { lat: 49.1137883, lng: 21.0254066 },
    { lat: 49.1136751, lng: 21.0251385 },
  ],
  Drienica: [
    { lat: 49.1619212, lng: 21.1221121 },
    { lat: 49.161854, lng: 21.1221203 },
    { lat: 49.1616782, lng: 21.1219363 },
    { lat: 49.1615102, lng: 21.1213924 },
    { lat: 49.1613955, lng: 21.120795 },
    { lat: 49.1609277, lng: 21.1193492 },
    { lat: 49.1609017, lng: 21.1187856 },
    { lat: 49.1608128, lng: 21.1183912 },
    { lat: 49.1606139, lng: 21.1181514 },
    { lat: 49.1603358, lng: 21.1180457 },
    { lat: 49.1595003, lng: 21.1180658 },
    { lat: 49.158424, lng: 21.1183971 },
    { lat: 49.1578742, lng: 21.1181353 },
    { lat: 49.1575691, lng: 21.1176685 },
    { lat: 49.1571954, lng: 21.1169225 },
    { lat: 49.1570676, lng: 21.1154923 },
    { lat: 49.1568308, lng: 21.1151553 },
    { lat: 49.1564458, lng: 21.1148707 },
    { lat: 49.1562111, lng: 21.1144239 },
    { lat: 49.1561441, lng: 21.1140909 },
    { lat: 49.156166, lng: 21.1134651 },
    { lat: 49.1560231, lng: 21.1130816 },
    { lat: 49.1555989, lng: 21.1127269 },
    { lat: 49.1553613, lng: 21.1120488 },
    { lat: 49.1550132, lng: 21.1116166 },
    { lat: 49.1544147, lng: 21.1112334 },
    { lat: 49.1540989, lng: 21.1111051 },
    { lat: 49.1537462, lng: 21.1110568 },
    { lat: 49.1528471, lng: 21.1113406 },
    { lat: 49.1524459, lng: 21.1116399 },
    { lat: 49.1521027, lng: 21.1117778 },
    { lat: 49.151823, lng: 21.1117903 },
    { lat: 49.1508302, lng: 21.1113627 },
    { lat: 49.1503721, lng: 21.1112542 },
    { lat: 49.1502097, lng: 21.1111753 },
    { lat: 49.1499413, lng: 21.1108691 },
    { lat: 49.1497977, lng: 21.1106026 },
    { lat: 49.1495923, lng: 21.1100189 },
    { lat: 49.1491766, lng: 21.1095076 },
    { lat: 49.1490122, lng: 21.1094698 },
    { lat: 49.1481703, lng: 21.1099934 },
    { lat: 49.1477059, lng: 21.1100077 },
    { lat: 49.1471857, lng: 21.1092227 },
    { lat: 49.1469231, lng: 21.109034 },
    { lat: 49.1463033, lng: 21.1087927 },
    { lat: 49.1458482, lng: 21.1087136 },
    { lat: 49.1451559, lng: 21.108138 },
    { lat: 49.1447919, lng: 21.1076319 },
    { lat: 49.1445612, lng: 21.1072357 },
    { lat: 49.1446552, lng: 21.1067981 },
    { lat: 49.1448422, lng: 21.1065617 },
    { lat: 49.1450907, lng: 21.1064491 },
    { lat: 49.1452459, lng: 21.1066191 },
    { lat: 49.1455593, lng: 21.1066369 },
    { lat: 49.146066, lng: 21.1055536 },
    { lat: 49.1461683, lng: 21.1044971 },
    { lat: 49.1460341, lng: 21.1042368 },
    { lat: 49.1459548, lng: 21.1039033 },
    { lat: 49.1461331, lng: 21.1033958 },
    { lat: 49.1460014, lng: 21.1031101 },
    { lat: 49.1466861, lng: 21.1024216 },
    { lat: 49.1475177, lng: 21.1013253 },
    { lat: 49.147962, lng: 21.1006542 },
    { lat: 49.1473321, lng: 21.0993925 },
    { lat: 49.1473432, lng: 21.0993166 },
    { lat: 49.1473442, lng: 21.0993122 },
    { lat: 49.1472203, lng: 21.0992434 },
    { lat: 49.1471215, lng: 21.0993155 },
    { lat: 49.1469826, lng: 21.0991989 },
    { lat: 49.1468508, lng: 21.0992284 },
    { lat: 49.1465308, lng: 21.0990373 },
    { lat: 49.1461136, lng: 21.0990506 },
    { lat: 49.1459349, lng: 21.0992652 },
    { lat: 49.1458315, lng: 21.0992021 },
    { lat: 49.1456276, lng: 21.0993373 },
    { lat: 49.1453576, lng: 21.0994048 },
    { lat: 49.1452195, lng: 21.0992752 },
    { lat: 49.1449881, lng: 21.0992867 },
    { lat: 49.1448012, lng: 21.0991794 },
    { lat: 49.1445319, lng: 21.0988943 },
    { lat: 49.1444989, lng: 21.0987763 },
    { lat: 49.1441993, lng: 21.0985886 },
    { lat: 49.1440875, lng: 21.09858 },
    { lat: 49.143896, lng: 21.0983948 },
    { lat: 49.1438058, lng: 21.0981754 },
    { lat: 49.1436377, lng: 21.0980225 },
    { lat: 49.1435736, lng: 21.0981639 },
    { lat: 49.143456, lng: 21.098219 },
    { lat: 49.1433089, lng: 21.0979097 },
    { lat: 49.1430608, lng: 21.0977552 },
    { lat: 49.1430777, lng: 21.0976134 },
    { lat: 49.1429314, lng: 21.0975797 },
    { lat: 49.1428211, lng: 21.0973679 },
    { lat: 49.1426871, lng: 21.0975048 },
    { lat: 49.1424394, lng: 21.0973647 },
    { lat: 49.1423505, lng: 21.0976067 },
    { lat: 49.1420805, lng: 21.0976067 },
    { lat: 49.1418345, lng: 21.0977684 },
    { lat: 49.1416181, lng: 21.0976831 },
    { lat: 49.1415146, lng: 21.0974751 },
    { lat: 49.1414122, lng: 21.0974011 },
    { lat: 49.1410698, lng: 21.0974316 },
    { lat: 49.1408804, lng: 21.0972459 },
    { lat: 49.1407193, lng: 21.097237 },
    { lat: 49.1403866, lng: 21.0970396 },
    { lat: 49.1402858, lng: 21.0970877 },
    { lat: 49.1402676, lng: 21.0969453 },
    { lat: 49.1394667, lng: 21.0968851 },
    { lat: 49.1393386, lng: 21.0972436 },
    { lat: 49.1390773, lng: 21.0971624 },
    { lat: 49.1390117, lng: 21.0975742 },
    { lat: 49.1387941, lng: 21.0973923 },
    { lat: 49.1383701, lng: 21.0974281 },
    { lat: 49.1382612, lng: 21.0973338 },
    { lat: 49.1381477, lng: 21.097551 },
    { lat: 49.138005, lng: 21.097663 },
    { lat: 49.1378775, lng: 21.0976121 },
    { lat: 49.1377747, lng: 21.0974285 },
    { lat: 49.1376567, lng: 21.097397 },
    { lat: 49.1373459, lng: 21.0975298 },
    { lat: 49.1372336, lng: 21.097366 },
    { lat: 49.1371397, lng: 21.0973478 },
    { lat: 49.137025, lng: 21.0974929 },
    { lat: 49.1363265, lng: 21.0978909 },
    { lat: 49.1360686, lng: 21.097739 },
    { lat: 49.1359741, lng: 21.0978154 },
    { lat: 49.1357834, lng: 21.0981853 },
    { lat: 49.1355876, lng: 21.0981179 },
    { lat: 49.1349627, lng: 21.0982808 },
    { lat: 49.1346471, lng: 21.0985113 },
    { lat: 49.1345477, lng: 21.0985319 },
    { lat: 49.1344341, lng: 21.098429 },
    { lat: 49.1340293, lng: 21.0986161 },
    { lat: 49.1337142, lng: 21.0983725 },
    { lat: 49.1333491, lng: 21.0984945 },
    { lat: 49.1330992, lng: 21.0986669 },
    { lat: 49.1328789, lng: 21.0985025 },
    { lat: 49.1326674, lng: 21.0984682 },
    { lat: 49.1325745, lng: 21.0987619 },
    { lat: 49.1323453, lng: 21.0989539 },
    { lat: 49.1320339, lng: 21.0989663 },
    { lat: 49.1317195, lng: 21.0988757 },
    { lat: 49.1314405, lng: 21.0993032 },
    { lat: 49.1308912, lng: 21.0993742 },
    { lat: 49.1306707, lng: 21.0990941 },
    { lat: 49.130605, lng: 21.0990746 },
    { lat: 49.1305633, lng: 21.0990755 },
    { lat: 49.1306152, lng: 21.099325 },
    { lat: 49.1307553, lng: 21.0994163 },
    { lat: 49.1308022, lng: 21.0996493 },
    { lat: 49.1309244, lng: 21.0997401 },
    { lat: 49.1310437, lng: 21.1000572 },
    { lat: 49.1309506, lng: 21.1001388 },
    { lat: 49.1310254, lng: 21.1005756 },
    { lat: 49.1309352, lng: 21.1007789 },
    { lat: 49.1309629, lng: 21.1008699 },
    { lat: 49.1307947, lng: 21.1009927 },
    { lat: 49.1307758, lng: 21.1014947 },
    { lat: 49.1305087, lng: 21.1017999 },
    { lat: 49.1303019, lng: 21.1019464 },
    { lat: 49.1292067, lng: 21.1038114 },
    { lat: 49.1286948, lng: 21.1043744 },
    { lat: 49.128414, lng: 21.1052411 },
    { lat: 49.1278844, lng: 21.1062743 },
    { lat: 49.1278377, lng: 21.106609 },
    { lat: 49.1277147, lng: 21.1070256 },
    { lat: 49.1272947, lng: 21.1066974 },
    { lat: 49.1271285, lng: 21.1062502 },
    { lat: 49.1266153, lng: 21.1056878 },
    { lat: 49.1263092, lng: 21.105367 },
    { lat: 49.1258506, lng: 21.1050305 },
    { lat: 49.1253787, lng: 21.1045269 },
    { lat: 49.1253205, lng: 21.1041834 },
    { lat: 49.1257751, lng: 21.1037235 },
    { lat: 49.1258692, lng: 21.1038197 },
    { lat: 49.1262244, lng: 21.1035271 },
    { lat: 49.1264643, lng: 21.1031816 },
    { lat: 49.1264111, lng: 21.1030652 },
    { lat: 49.1260791, lng: 21.1034495 },
    { lat: 49.1251967, lng: 21.1028103 },
    { lat: 49.1244803, lng: 21.1029659 },
    { lat: 49.1240566, lng: 21.1028406 },
    { lat: 49.1240348, lng: 21.1028954 },
    { lat: 49.1234351, lng: 21.1027649 },
    { lat: 49.1234131, lng: 21.1029897 },
    { lat: 49.122506, lng: 21.1028806 },
    { lat: 49.1225044, lng: 21.1029353 },
    { lat: 49.1216039, lng: 21.1029662 },
    { lat: 49.1210012, lng: 21.1031593 },
    { lat: 49.1201664, lng: 21.1036531 },
    { lat: 49.1200953, lng: 21.1034058 },
    { lat: 49.1198195, lng: 21.1036838 },
    { lat: 49.1192088, lng: 21.1039745 },
    { lat: 49.1192212, lng: 21.1040451 },
    { lat: 49.1185036, lng: 21.1042718 },
    { lat: 49.1174447, lng: 21.1044093 },
    { lat: 49.1174343, lng: 21.1042236 },
    { lat: 49.1168087, lng: 21.1040559 },
    { lat: 49.1155821, lng: 21.1035652 },
    { lat: 49.1151155, lng: 21.1044893 },
    { lat: 49.114986, lng: 21.1049444 },
    { lat: 49.1156179, lng: 21.1055273 },
    { lat: 49.1151691, lng: 21.1071182 },
    { lat: 49.1151477, lng: 21.1077862 },
    { lat: 49.1152103, lng: 21.1084454 },
    { lat: 49.1155343, lng: 21.1084749 },
    { lat: 49.1164458, lng: 21.1088328 },
    { lat: 49.1180512, lng: 21.109099 },
    { lat: 49.1185526, lng: 21.1093284 },
    { lat: 49.1189832, lng: 21.1093519 },
    { lat: 49.1199022, lng: 21.1092637 },
    { lat: 49.1202287, lng: 21.1091285 },
    { lat: 49.1213679, lng: 21.1084703 },
    { lat: 49.122265, lng: 21.107805 },
    { lat: 49.1224518, lng: 21.1083771 },
    { lat: 49.1226815, lng: 21.1088347 },
    { lat: 49.1231258, lng: 21.1076675 },
    { lat: 49.1231963, lng: 21.1077564 },
    { lat: 49.1240592, lng: 21.1065179 },
    { lat: 49.1243782, lng: 21.1061476 },
    { lat: 49.1244268, lng: 21.1058378 },
    { lat: 49.1246874, lng: 21.1057869 },
    { lat: 49.1249471, lng: 21.1059225 },
    { lat: 49.1251187, lng: 21.1061099 },
    { lat: 49.1252593, lng: 21.1063553 },
    { lat: 49.12524, lng: 21.1064541 },
    { lat: 49.1259843, lng: 21.1073202 },
    { lat: 49.1260729, lng: 21.1075537 },
    { lat: 49.1262535, lng: 21.1077441 },
    { lat: 49.1263587, lng: 21.1081527 },
    { lat: 49.1259845, lng: 21.1091578 },
    { lat: 49.1262188, lng: 21.1095554 },
    { lat: 49.1252458, lng: 21.1110482 },
    { lat: 49.1243871, lng: 21.1121002 },
    { lat: 49.1244284, lng: 21.1123383 },
    { lat: 49.1239653, lng: 21.1126719 },
    { lat: 49.1239242, lng: 21.1136116 },
    { lat: 49.1236708, lng: 21.1149831 },
    { lat: 49.1234847, lng: 21.1148628 },
    { lat: 49.1233007, lng: 21.1150177 },
    { lat: 49.1232303, lng: 21.115288 },
    { lat: 49.1230891, lng: 21.1155108 },
    { lat: 49.1224048, lng: 21.1155783 },
    { lat: 49.1222339, lng: 21.1156549 },
    { lat: 49.1222386, lng: 21.1154174 },
    { lat: 49.1221009, lng: 21.115336 },
    { lat: 49.1220911, lng: 21.1150564 },
    { lat: 49.1219304, lng: 21.1148912 },
    { lat: 49.1218816, lng: 21.1146322 },
    { lat: 49.1218603, lng: 21.1146007 },
    { lat: 49.1217497, lng: 21.1147271 },
    { lat: 49.1215629, lng: 21.1146209 },
    { lat: 49.1214279, lng: 21.1143442 },
    { lat: 49.1203189, lng: 21.1144643 },
    { lat: 49.1202158, lng: 21.1145146 },
    { lat: 49.120118, lng: 21.1147292 },
    { lat: 49.1198579, lng: 21.1146572 },
    { lat: 49.1197967, lng: 21.1147853 },
    { lat: 49.1196252, lng: 21.1146956 },
    { lat: 49.1195475, lng: 21.114559 },
    { lat: 49.1194269, lng: 21.1146215 },
    { lat: 49.1193408, lng: 21.1148488 },
    { lat: 49.1191355, lng: 21.1149714 },
    { lat: 49.1189261, lng: 21.1152908 },
    { lat: 49.1187623, lng: 21.1152332 },
    { lat: 49.1185314, lng: 21.1154037 },
    { lat: 49.1184001, lng: 21.1153459 },
    { lat: 49.1181461, lng: 21.1155346 },
    { lat: 49.117507, lng: 21.1156088 },
    { lat: 49.117094, lng: 21.1160643 },
    { lat: 49.1168337, lng: 21.1160498 },
    { lat: 49.1165957, lng: 21.1164277 },
    { lat: 49.1163064, lng: 21.1168504 },
    { lat: 49.1163223, lng: 21.1168916 },
    { lat: 49.1172041, lng: 21.1193455 },
    { lat: 49.1181746, lng: 21.1222636 },
    { lat: 49.1183333, lng: 21.1242134 },
    { lat: 49.1183342, lng: 21.1258677 },
    { lat: 49.1184302, lng: 21.1264477 },
    { lat: 49.118385, lng: 21.1270808 },
    { lat: 49.1191918, lng: 21.1273583 },
    { lat: 49.1191437, lng: 21.1276496 },
    { lat: 49.1193098, lng: 21.1276008 },
    { lat: 49.1193858, lng: 21.1276232 },
    { lat: 49.1193693, lng: 21.1277056 },
    { lat: 49.1194587, lng: 21.1276828 },
    { lat: 49.1199528, lng: 21.1279008 },
    { lat: 49.1201292, lng: 21.1278576 },
    { lat: 49.1204439, lng: 21.1280177 },
    { lat: 49.1205899, lng: 21.1282264 },
    { lat: 49.1208267, lng: 21.1280933 },
    { lat: 49.1210308, lng: 21.1282184 },
    { lat: 49.1211061, lng: 21.1283896 },
    { lat: 49.1213662, lng: 21.1283604 },
    { lat: 49.1216897, lng: 21.128874 },
    { lat: 49.1221359, lng: 21.1288875 },
    { lat: 49.1224169, lng: 21.1283135 },
    { lat: 49.1225804, lng: 21.1282878 },
    { lat: 49.1227044, lng: 21.1284346 },
    { lat: 49.1229044, lng: 21.128464 },
    { lat: 49.122957, lng: 21.1283854 },
    { lat: 49.1231585, lng: 21.1283145 },
    { lat: 49.1234212, lng: 21.1284033 },
    { lat: 49.1239321, lng: 21.128398 },
    { lat: 49.1240838, lng: 21.1282162 },
    { lat: 49.1249204, lng: 21.1277021 },
    { lat: 49.1253071, lng: 21.1280039 },
    { lat: 49.1261592, lng: 21.127443 },
    { lat: 49.1267992, lng: 21.1271555 },
    { lat: 49.1270162, lng: 21.1268969 },
    { lat: 49.1272747, lng: 21.1270701 },
    { lat: 49.1273716, lng: 21.1274245 },
    { lat: 49.127474, lng: 21.1274575 },
    { lat: 49.1276244, lng: 21.1272308 },
    { lat: 49.1277849, lng: 21.1272585 },
    { lat: 49.1279632, lng: 21.1270974 },
    { lat: 49.1283034, lng: 21.1269666 },
    { lat: 49.1285072, lng: 21.126934 },
    { lat: 49.1288373, lng: 21.1270886 },
    { lat: 49.1291049, lng: 21.126974 },
    { lat: 49.1293343, lng: 21.1269654 },
    { lat: 49.1294251, lng: 21.1271682 },
    { lat: 49.1297636, lng: 21.1271644 },
    { lat: 49.1299204, lng: 21.1273174 },
    { lat: 49.1299918, lng: 21.1276158 },
    { lat: 49.1302597, lng: 21.1279342 },
    { lat: 49.1306031, lng: 21.1278033 },
    { lat: 49.131175, lng: 21.1282369 },
    { lat: 49.1313741, lng: 21.1285507 },
    { lat: 49.1314471, lng: 21.1291479 },
    { lat: 49.1315502, lng: 21.1292754 },
    { lat: 49.1317153, lng: 21.1292335 },
    { lat: 49.1318404, lng: 21.1293777 },
    { lat: 49.1319756, lng: 21.1298503 },
    { lat: 49.1321685, lng: 21.1298552 },
    { lat: 49.1322647, lng: 21.1302351 },
    { lat: 49.1323778, lng: 21.1303896 },
    { lat: 49.132716, lng: 21.13048 },
    { lat: 49.1327866, lng: 21.1305783 },
    { lat: 49.1327971, lng: 21.1309099 },
    { lat: 49.1330653, lng: 21.1308844 },
    { lat: 49.1331453, lng: 21.131174 },
    { lat: 49.13329, lng: 21.13127 },
    { lat: 49.1333771, lng: 21.1314958 },
    { lat: 49.1335613, lng: 21.1315192 },
    { lat: 49.133649, lng: 21.1317251 },
    { lat: 49.1340045, lng: 21.1321546 },
    { lat: 49.134511, lng: 21.1324724 },
    { lat: 49.1350837, lng: 21.1330056 },
    { lat: 49.1355477, lng: 21.1329075 },
    { lat: 49.135643, lng: 21.133363 },
    { lat: 49.1357219, lng: 21.1334337 },
    { lat: 49.1359247, lng: 21.1333365 },
    { lat: 49.1359913, lng: 21.1334517 },
    { lat: 49.1362103, lng: 21.1334711 },
    { lat: 49.1366503, lng: 21.1332928 },
    { lat: 49.1368074, lng: 21.133383 },
    { lat: 49.1368058, lng: 21.1334391 },
    { lat: 49.1371431, lng: 21.1333306 },
    { lat: 49.138594, lng: 21.1339742 },
    { lat: 49.1387596, lng: 21.1341266 },
    { lat: 49.1391634, lng: 21.1347234 },
    { lat: 49.1394622, lng: 21.1350608 },
    { lat: 49.1396077, lng: 21.1354623 },
    { lat: 49.1398277, lng: 21.135655 },
    { lat: 49.1400391, lng: 21.1360768 },
    { lat: 49.1400555, lng: 21.136246 },
    { lat: 49.1402355, lng: 21.1367399 },
    { lat: 49.1405301, lng: 21.1370471 },
    { lat: 49.1419236, lng: 21.1371322 },
    { lat: 49.1421818, lng: 21.1368638 },
    { lat: 49.1429617, lng: 21.1355179 },
    { lat: 49.1431795, lng: 21.1348606 },
    { lat: 49.1433, lng: 21.1346533 },
    { lat: 49.1433318, lng: 21.1346956 },
    { lat: 49.1435116, lng: 21.1344379 },
    { lat: 49.1439564, lng: 21.1341284 },
    { lat: 49.1440493, lng: 21.133972 },
    { lat: 49.1445659, lng: 21.1335462 },
    { lat: 49.1447175, lng: 21.1338201 },
    { lat: 49.1449868, lng: 21.1338631 },
    { lat: 49.1450679, lng: 21.133938 },
    { lat: 49.1453203, lng: 21.135124 },
    { lat: 49.1453973, lng: 21.1353142 },
    { lat: 49.145773, lng: 21.1357337 },
    { lat: 49.1460807, lng: 21.1358592 },
    { lat: 49.1463397, lng: 21.1358628 },
    { lat: 49.14705, lng: 21.1356265 },
    { lat: 49.1474745, lng: 21.1358436 },
    { lat: 49.1479832, lng: 21.1358813 },
    { lat: 49.148267, lng: 21.1360345 },
    { lat: 49.1488696, lng: 21.1361965 },
    { lat: 49.1490703, lng: 21.1365973 },
    { lat: 49.1491967, lng: 21.1366045 },
    { lat: 49.1492818, lng: 21.1367018 },
    { lat: 49.1494582, lng: 21.1370828 },
    { lat: 49.149557, lng: 21.1370083 },
    { lat: 49.1497413, lng: 21.1371773 },
    { lat: 49.1525429, lng: 21.13851 },
    { lat: 49.1528871, lng: 21.1381482 },
    { lat: 49.1534758, lng: 21.1398963 },
    { lat: 49.1583322, lng: 21.1410307 },
    { lat: 49.1584221, lng: 21.140913 },
    { lat: 49.159194, lng: 21.1417368 },
    { lat: 49.1618426, lng: 21.1424688 },
    { lat: 49.1629811, lng: 21.1423482 },
    { lat: 49.163488, lng: 21.1405562 },
    { lat: 49.1638418, lng: 21.1395345 },
    { lat: 49.1641319, lng: 21.1378163 },
    { lat: 49.164572, lng: 21.1366462 },
    { lat: 49.1645673, lng: 21.1362535 },
    { lat: 49.1644153, lng: 21.1358537 },
    { lat: 49.1641079, lng: 21.1353204 },
    { lat: 49.1640598, lng: 21.1351135 },
    { lat: 49.1639917, lng: 21.1343465 },
    { lat: 49.1638483, lng: 21.1309572 },
    { lat: 49.1639416, lng: 21.1292143 },
    { lat: 49.1636786, lng: 21.1282304 },
    { lat: 49.1631849, lng: 21.127712 },
    { lat: 49.1627051, lng: 21.1269125 },
    { lat: 49.162161, lng: 21.1259433 },
    { lat: 49.161957, lng: 21.1253248 },
    { lat: 49.1619305, lng: 21.1250621 },
    { lat: 49.162, lng: 21.1239548 },
    { lat: 49.1619091, lng: 21.1231126 },
    { lat: 49.1619598, lng: 21.1224066 },
    { lat: 49.1619269, lng: 21.1221491 },
    { lat: 49.1619212, lng: 21.1221121 },
  ],
  Renčišov: [
    { lat: 49.1009467, lng: 20.9496674 },
    { lat: 49.1009126, lng: 20.9486425 },
    { lat: 49.10085, lng: 20.9468981 },
    { lat: 49.1009587, lng: 20.9452989 },
    { lat: 49.1010409, lng: 20.9447922 },
    { lat: 49.1011698, lng: 20.9443365 },
    { lat: 49.1011035, lng: 20.9438341 },
    { lat: 49.1011005, lng: 20.9410439 },
    { lat: 49.1011663, lng: 20.9404562 },
    { lat: 49.1018931, lng: 20.9383565 },
    { lat: 49.1017312, lng: 20.9379578 },
    { lat: 49.1014678, lng: 20.9365386 },
    { lat: 49.1008904, lng: 20.9352942 },
    { lat: 49.1005824, lng: 20.9348382 },
    { lat: 49.0990412, lng: 20.9339772 },
    { lat: 49.09786, lng: 20.9340687 },
    { lat: 49.0974788, lng: 20.9327615 },
    { lat: 49.0984941, lng: 20.931082 },
    { lat: 49.0985979, lng: 20.9306178 },
    { lat: 49.0985088, lng: 20.9301757 },
    { lat: 49.0976829, lng: 20.9280974 },
    { lat: 49.0976048, lng: 20.9262387 },
    { lat: 49.098684, lng: 20.923779 },
    { lat: 49.0963009, lng: 20.9193746 },
    { lat: 49.0957881, lng: 20.9183141 },
    { lat: 49.0951503, lng: 20.9166808 },
    { lat: 49.0947729, lng: 20.9176084 },
    { lat: 49.093732, lng: 20.920124 },
    { lat: 49.093612, lng: 20.92034 },
    { lat: 49.09341, lng: 20.920704 },
    { lat: 49.093379, lng: 20.920779 },
    { lat: 49.093326, lng: 20.920808 },
    { lat: 49.091578, lng: 20.921781 },
    { lat: 49.091083, lng: 20.922236 },
    { lat: 49.090448, lng: 20.922796 },
    { lat: 49.089115, lng: 20.924279 },
    { lat: 49.088119, lng: 20.923686 },
    { lat: 49.087062, lng: 20.923101 },
    { lat: 49.086559, lng: 20.923996 },
    { lat: 49.085995, lng: 20.925171 },
    { lat: 49.085652, lng: 20.925727 },
    { lat: 49.085626, lng: 20.925768 },
    { lat: 49.084995, lng: 20.926788 },
    { lat: 49.083707, lng: 20.929283 },
    { lat: 49.082745, lng: 20.93044 },
    { lat: 49.082609, lng: 20.930828 },
    { lat: 49.082237, lng: 20.931891 },
    { lat: 49.081922, lng: 20.933369 },
    { lat: 49.081273, lng: 20.932838 },
    { lat: 49.080589, lng: 20.932743 },
    { lat: 49.079571, lng: 20.932507 },
    { lat: 49.078951, lng: 20.932359 },
    { lat: 49.078533, lng: 20.934198 },
    { lat: 49.078439, lng: 20.934537 },
    { lat: 49.078246, lng: 20.935238 },
    { lat: 49.078688, lng: 20.935523 },
    { lat: 49.077535, lng: 20.936502 },
    { lat: 49.077444, lng: 20.93928 },
    { lat: 49.077433, lng: 20.939643 },
    { lat: 49.077421, lng: 20.940021 },
    { lat: 49.078265, lng: 20.941332 },
    { lat: 49.079392, lng: 20.944402 },
    { lat: 49.078626, lng: 20.945649 },
    { lat: 49.079553, lng: 20.94935 },
    { lat: 49.080154, lng: 20.950638 },
    { lat: 49.079866, lng: 20.951623 },
    { lat: 49.079835, lng: 20.95173 },
    { lat: 49.07981, lng: 20.951817 },
    { lat: 49.079681, lng: 20.952256 },
    { lat: 49.079545, lng: 20.952722 },
    { lat: 49.079514, lng: 20.952827 },
    { lat: 49.079497, lng: 20.952885 },
    { lat: 49.079432, lng: 20.952951 },
    { lat: 49.07932, lng: 20.953066 },
    { lat: 49.078602, lng: 20.953798 },
    { lat: 49.077008, lng: 20.95394 },
    { lat: 49.077219, lng: 20.955745 },
    { lat: 49.07718, lng: 20.956353 },
    { lat: 49.07703, lng: 20.958774 },
    { lat: 49.07707, lng: 20.960948 },
    { lat: 49.077101, lng: 20.962582 },
    { lat: 49.077697, lng: 20.962471 },
    { lat: 49.077317, lng: 20.963381 },
    { lat: 49.076793, lng: 20.964431 },
    { lat: 49.076705, lng: 20.96553 },
    { lat: 49.077314, lng: 20.967144 },
    { lat: 49.077752, lng: 20.968632 },
    { lat: 49.078709, lng: 20.969213 },
    { lat: 49.079986, lng: 20.969965 },
    { lat: 49.081203, lng: 20.970193 },
    { lat: 49.081987, lng: 20.970985 },
    { lat: 49.082159, lng: 20.971159 },
    { lat: 49.08345, lng: 20.972777 },
    { lat: 49.085085, lng: 20.973447 },
    { lat: 49.086661, lng: 20.973708 },
    { lat: 49.087736, lng: 20.972766 },
    { lat: 49.088089, lng: 20.975015 },
    { lat: 49.088734, lng: 20.976843 },
    { lat: 49.088854, lng: 20.977426 },
    { lat: 49.088524, lng: 20.978352 },
    { lat: 49.088292, lng: 20.978536 },
    { lat: 49.088298, lng: 20.979282 },
    { lat: 49.0892856, lng: 20.9800026 },
    { lat: 49.089201, lng: 20.9806777 },
    { lat: 49.0912539, lng: 20.9811532 },
    { lat: 49.0942192, lng: 20.9819615 },
    { lat: 49.0954492, lng: 20.9825091 },
    { lat: 49.0957303, lng: 20.9829433 },
    { lat: 49.0958027, lng: 20.9831568 },
    { lat: 49.0962786, lng: 20.9837999 },
    { lat: 49.0971771, lng: 20.9819702 },
    { lat: 49.0986414, lng: 20.9776133 },
    { lat: 49.098896, lng: 20.9749989 },
    { lat: 49.0986605, lng: 20.9737003 },
    { lat: 49.0982278, lng: 20.9720466 },
    { lat: 49.0987596, lng: 20.9716908 },
    { lat: 49.0981858, lng: 20.9716325 },
    { lat: 49.0981703, lng: 20.9703461 },
    { lat: 49.0978593, lng: 20.9681271 },
    { lat: 49.0974957, lng: 20.967376 },
    { lat: 49.097469, lng: 20.9664705 },
    { lat: 49.0979215, lng: 20.9649808 },
    { lat: 49.0977085, lng: 20.9648036 },
    { lat: 49.0980533, lng: 20.9638641 },
    { lat: 49.0979593, lng: 20.9636411 },
    { lat: 49.0979606, lng: 20.9632104 },
    { lat: 49.0981008, lng: 20.9628157 },
    { lat: 49.0979438, lng: 20.962363 },
    { lat: 49.0981888, lng: 20.9614653 },
    { lat: 49.0980921, lng: 20.9613668 },
    { lat: 49.0981781, lng: 20.9606379 },
    { lat: 49.0983213, lng: 20.9600713 },
    { lat: 49.0985353, lng: 20.9585126 },
    { lat: 49.0985433, lng: 20.9580799 },
    { lat: 49.0986834, lng: 20.9575548 },
    { lat: 49.0987059, lng: 20.9568746 },
    { lat: 49.0991357, lng: 20.9559914 },
    { lat: 49.0991814, lng: 20.9554735 },
    { lat: 49.099076, lng: 20.9548714 },
    { lat: 49.0991558, lng: 20.9544264 },
    { lat: 49.0994273, lng: 20.9542 },
    { lat: 49.0997434, lng: 20.953147 },
    { lat: 49.0998658, lng: 20.9524932 },
    { lat: 49.1001531, lng: 20.9521969 },
    { lat: 49.1001596, lng: 20.9522579 },
    { lat: 49.1002967, lng: 20.9520487 },
    { lat: 49.1002159, lng: 20.9511088 },
    { lat: 49.1008109, lng: 20.9505895 },
    { lat: 49.1009467, lng: 20.9496674 },
  ],
  Torysa: [
    { lat: 49.1372008, lng: 20.878652 },
    { lat: 49.1365198, lng: 20.8807383 },
    { lat: 49.1361598, lng: 20.8816415 },
    { lat: 49.1360439, lng: 20.8821377 },
    { lat: 49.1362344, lng: 20.88448 },
    { lat: 49.1360328, lng: 20.885673 },
    { lat: 49.1358489, lng: 20.8863222 },
    { lat: 49.1357964, lng: 20.8871006 },
    { lat: 49.1360435, lng: 20.8890866 },
    { lat: 49.1365586, lng: 20.8917061 },
    { lat: 49.1366471, lng: 20.8919583 },
    { lat: 49.1373033, lng: 20.8925269 },
    { lat: 49.1376479, lng: 20.8924977 },
    { lat: 49.1378423, lng: 20.8928218 },
    { lat: 49.1379776, lng: 20.8934607 },
    { lat: 49.1381998, lng: 20.8940436 },
    { lat: 49.1385325, lng: 20.8939999 },
    { lat: 49.1386539, lng: 20.8937941 },
    { lat: 49.139306, lng: 20.893771 },
    { lat: 49.1393497, lng: 20.8941389 },
    { lat: 49.1400164, lng: 20.8964502 },
    { lat: 49.1402946, lng: 20.8982599 },
    { lat: 49.1414506, lng: 20.8979602 },
    { lat: 49.1420066, lng: 20.9001958 },
    { lat: 49.1419873, lng: 20.9011654 },
    { lat: 49.141869, lng: 20.9018246 },
    { lat: 49.1424829, lng: 20.9022446 },
    { lat: 49.1429907, lng: 20.9028473 },
    { lat: 49.143406, lng: 20.9034507 },
    { lat: 49.1440793, lng: 20.9036929 },
    { lat: 49.1443024, lng: 20.9034964 },
    { lat: 49.1445536, lng: 20.9033718 },
    { lat: 49.1451981, lng: 20.9035313 },
    { lat: 49.1457016, lng: 20.9031026 },
    { lat: 49.145992, lng: 20.9030062 },
    { lat: 49.1466195, lng: 20.9032202 },
    { lat: 49.1470189, lng: 20.903518 },
    { lat: 49.1474191, lng: 20.9034417 },
    { lat: 49.1476558, lng: 20.903613 },
    { lat: 49.1479186, lng: 20.9035303 },
    { lat: 49.1482265, lng: 20.9037852 },
    { lat: 49.1487095, lng: 20.9038923 },
    { lat: 49.1489186, lng: 20.9037138 },
    { lat: 49.1493537, lng: 20.9037326 },
    { lat: 49.1501475, lng: 20.9034077 },
    { lat: 49.1507535, lng: 20.9035246 },
    { lat: 49.1515417, lng: 20.9033004 },
    { lat: 49.1518752, lng: 20.9033491 },
    { lat: 49.1521677, lng: 20.9033795 },
    { lat: 49.1528024, lng: 20.9032396 },
    { lat: 49.1530065, lng: 20.9033545 },
    { lat: 49.1538242, lng: 20.9032988 },
    { lat: 49.1542067, lng: 20.9030952 },
    { lat: 49.1554193, lng: 20.9036439 },
    { lat: 49.1556228, lng: 20.9034396 },
    { lat: 49.1558404, lng: 20.9035862 },
    { lat: 49.1559484, lng: 20.9037809 },
    { lat: 49.1564848, lng: 20.9042388 },
    { lat: 49.1568709, lng: 20.904675 },
    { lat: 49.1575819, lng: 20.9048973 },
    { lat: 49.1576688, lng: 20.9050761 },
    { lat: 49.1576567, lng: 20.9053823 },
    { lat: 49.1576922, lng: 20.9054986 },
    { lat: 49.1579278, lng: 20.9054492 },
    { lat: 49.1581238, lng: 20.9054835 },
    { lat: 49.1582613, lng: 20.9055886 },
    { lat: 49.1583587, lng: 20.9057428 },
    { lat: 49.158452, lng: 20.9061037 },
    { lat: 49.1586094, lng: 20.9061971 },
    { lat: 49.1586808, lng: 20.906147 },
    { lat: 49.1588422, lng: 20.9054921 },
    { lat: 49.1589102, lng: 20.9054098 },
    { lat: 49.1590175, lng: 20.9054848 },
    { lat: 49.1590441, lng: 20.9057719 },
    { lat: 49.1589367, lng: 20.90626 },
    { lat: 49.1589912, lng: 20.9064424 },
    { lat: 49.1590838, lng: 20.9064531 },
    { lat: 49.1594526, lng: 20.906243 },
    { lat: 49.159517, lng: 20.9063124 },
    { lat: 49.1595821, lng: 20.9066411 },
    { lat: 49.1596884, lng: 20.9068928 },
    { lat: 49.1598712, lng: 20.9066771 },
    { lat: 49.1599439, lng: 20.9066912 },
    { lat: 49.160049, lng: 20.9069405 },
    { lat: 49.1600308, lng: 20.9071019 },
    { lat: 49.1598911, lng: 20.9074293 },
    { lat: 49.1599329, lng: 20.9076421 },
    { lat: 49.1600095, lng: 20.9076754 },
    { lat: 49.1603247, lng: 20.9074055 },
    { lat: 49.1604044, lng: 20.9075021 },
    { lat: 49.1603902, lng: 20.907747 },
    { lat: 49.1604827, lng: 20.9079595 },
    { lat: 49.1609454, lng: 20.9080909 },
    { lat: 49.1610114, lng: 20.9083483 },
    { lat: 49.1614937, lng: 20.9089676 },
    { lat: 49.1616539, lng: 20.9089825 },
    { lat: 49.1619251, lng: 20.9088501 },
    { lat: 49.1625586, lng: 20.908942 },
    { lat: 49.1633122, lng: 20.908193 },
    { lat: 49.1634669, lng: 20.9081417 },
    { lat: 49.1635838, lng: 20.9081681 },
    { lat: 49.16372, lng: 20.9084031 },
    { lat: 49.1638548, lng: 20.9084278 },
    { lat: 49.1638484, lng: 20.9072343 },
    { lat: 49.1638023, lng: 20.9066697 },
    { lat: 49.1635075, lng: 20.9049323 },
    { lat: 49.1634255, lng: 20.9036286 },
    { lat: 49.1636248, lng: 20.9026631 },
    { lat: 49.1635399, lng: 20.9019138 },
    { lat: 49.1631063, lng: 20.9002283 },
    { lat: 49.1632346, lng: 20.8999011 },
    { lat: 49.1629678, lng: 20.8993947 },
    { lat: 49.163928, lng: 20.8978975 },
    { lat: 49.1640064, lng: 20.8979162 },
    { lat: 49.1641357, lng: 20.8976791 },
    { lat: 49.1641704, lng: 20.8973434 },
    { lat: 49.1641209, lng: 20.8968761 },
    { lat: 49.1641623, lng: 20.8957335 },
    { lat: 49.164898, lng: 20.8923498 },
    { lat: 49.165553, lng: 20.8906486 },
    { lat: 49.1660122, lng: 20.8942538 },
    { lat: 49.1660749, lng: 20.8945409 },
    { lat: 49.1667627, lng: 20.8960461 },
    { lat: 49.1668337, lng: 20.897754 },
    { lat: 49.1673578, lng: 20.89928 },
    { lat: 49.1692656, lng: 20.8972807 },
    { lat: 49.1690648, lng: 20.8966968 },
    { lat: 49.1709717, lng: 20.8951668 },
    { lat: 49.1713995, lng: 20.8949423 },
    { lat: 49.1712729, lng: 20.8945868 },
    { lat: 49.173105, lng: 20.8923521 },
    { lat: 49.1737596, lng: 20.8917676 },
    { lat: 49.1739889, lng: 20.8917564 },
    { lat: 49.1742729, lng: 20.8915064 },
    { lat: 49.1729728, lng: 20.8913617 },
    { lat: 49.1716736, lng: 20.891217 },
    { lat: 49.170827, lng: 20.8883152 },
    { lat: 49.171375, lng: 20.8874093 },
    { lat: 49.1699466, lng: 20.8818114 },
    { lat: 49.1710166, lng: 20.8802428 },
    { lat: 49.1716907, lng: 20.8786771 },
    { lat: 49.1711284, lng: 20.8779222 },
    { lat: 49.1706414, lng: 20.8767412 },
    { lat: 49.1697147, lng: 20.8768901 },
    { lat: 49.1694018, lng: 20.8766554 },
    { lat: 49.1679159, lng: 20.8750273 },
    { lat: 49.1678599, lng: 20.8724086 },
    { lat: 49.1673627, lng: 20.8721835 },
    { lat: 49.166597, lng: 20.8723759 },
    { lat: 49.1665593, lng: 20.8712988 },
    { lat: 49.1664137, lng: 20.870419 },
    { lat: 49.1661775, lng: 20.869596 },
    { lat: 49.1661321, lng: 20.8692518 },
    { lat: 49.1649437, lng: 20.8643162 },
    { lat: 49.1650857, lng: 20.863235 },
    { lat: 49.1676541, lng: 20.8571127 },
    { lat: 49.1697189, lng: 20.8519718 },
    { lat: 49.1696913, lng: 20.8519585 },
    { lat: 49.1687206, lng: 20.8512478 },
    { lat: 49.166239, lng: 20.848447 },
    { lat: 49.1660588, lng: 20.8479837 },
    { lat: 49.1645924, lng: 20.8466693 },
    { lat: 49.1643041, lng: 20.8475849 },
    { lat: 49.1644139, lng: 20.8483958 },
    { lat: 49.1636458, lng: 20.8489452 },
    { lat: 49.1631155, lng: 20.8497299 },
    { lat: 49.1621662, lng: 20.8513348 },
    { lat: 49.1622671, lng: 20.8518454 },
    { lat: 49.1621211, lng: 20.8521936 },
    { lat: 49.1617032, lng: 20.8520814 },
    { lat: 49.1613943, lng: 20.8525961 },
    { lat: 49.1612168, lng: 20.8533592 },
    { lat: 49.160769, lng: 20.8540608 },
    { lat: 49.1602313, lng: 20.8546306 },
    { lat: 49.1597644, lng: 20.8546406 },
    { lat: 49.1593447, lng: 20.8555988 },
    { lat: 49.1587719, lng: 20.8557693 },
    { lat: 49.1586056, lng: 20.8562719 },
    { lat: 49.1566032, lng: 20.8597107 },
    { lat: 49.1568678, lng: 20.8602705 },
    { lat: 49.1565885, lng: 20.8612243 },
    { lat: 49.1556639, lng: 20.8628318 },
    { lat: 49.15565, lng: 20.8640476 },
    { lat: 49.1548388, lng: 20.8656475 },
    { lat: 49.1549773, lng: 20.867205 },
    { lat: 49.1544065, lng: 20.8695511 },
    { lat: 49.1543685, lng: 20.8695275 },
    { lat: 49.1542638, lng: 20.8696623 },
    { lat: 49.1540357, lng: 20.8692711 },
    { lat: 49.151967, lng: 20.8669861 },
    { lat: 49.1515669, lng: 20.8663381 },
    { lat: 49.1512238, lng: 20.8651865 },
    { lat: 49.1508342, lng: 20.8647488 },
    { lat: 49.1502781, lng: 20.8632254 },
    { lat: 49.1499645, lng: 20.862708 },
    { lat: 49.1492461, lng: 20.8623334 },
    { lat: 49.1487701, lng: 20.8640234 },
    { lat: 49.1480092, lng: 20.8635313 },
    { lat: 49.1471626, lng: 20.8631926 },
    { lat: 49.145181, lng: 20.8626737 },
    { lat: 49.1446128, lng: 20.8621811 },
    { lat: 49.1442422, lng: 20.8628047 },
    { lat: 49.1436563, lng: 20.8641409 },
    { lat: 49.1434684, lng: 20.8651361 },
    { lat: 49.142692, lng: 20.8660034 },
    { lat: 49.1421263, lng: 20.8667768 },
    { lat: 49.14166, lng: 20.8672683 },
    { lat: 49.1415376, lng: 20.8674427 },
    { lat: 49.1415482, lng: 20.8675888 },
    { lat: 49.1414042, lng: 20.86762 },
    { lat: 49.1412923, lng: 20.8680415 },
    { lat: 49.1408555, lng: 20.8679717 },
    { lat: 49.1406621, lng: 20.868171 },
    { lat: 49.1399796, lng: 20.8693889 },
    { lat: 49.1394531, lng: 20.8698596 },
    { lat: 49.1394612, lng: 20.8703281 },
    { lat: 49.1396644, lng: 20.8706428 },
    { lat: 49.1396841, lng: 20.8717229 },
    { lat: 49.1399869, lng: 20.8746439 },
    { lat: 49.1390218, lng: 20.8754575 },
    { lat: 49.1381017, lng: 20.8768338 },
    { lat: 49.1373872, lng: 20.8786683 },
    { lat: 49.1372008, lng: 20.878652 },
  ],
  Uzovce: [
    { lat: 49.0914334, lng: 21.1547011 },
    { lat: 49.0913514, lng: 21.1543523 },
    { lat: 49.0908388, lng: 21.1528358 },
    { lat: 49.0904775, lng: 21.1522964 },
    { lat: 49.0904783, lng: 21.1521135 },
    { lat: 49.0904172, lng: 21.1518868 },
    { lat: 49.0903026, lng: 21.1517156 },
    { lat: 49.0901284, lng: 21.1516814 },
    { lat: 49.0900141, lng: 21.1515572 },
    { lat: 49.0899703, lng: 21.1516707 },
    { lat: 49.0891355, lng: 21.1538519 },
    { lat: 49.0892075, lng: 21.1547374 },
    { lat: 49.0891878, lng: 21.1549345 },
    { lat: 49.0884865, lng: 21.1557509 },
    { lat: 49.0883564, lng: 21.1561184 },
    { lat: 49.0882758, lng: 21.1566653 },
    { lat: 49.0880769, lng: 21.1573592 },
    { lat: 49.0878385, lng: 21.1577561 },
    { lat: 49.0876134, lng: 21.158291 },
    { lat: 49.0876283, lng: 21.160154 },
    { lat: 49.0873989, lng: 21.1606576 },
    { lat: 49.0872074, lng: 21.1609796 },
    { lat: 49.0869209, lng: 21.1613182 },
    { lat: 49.0865686, lng: 21.1620954 },
    { lat: 49.0862498, lng: 21.162622 },
    { lat: 49.0849282, lng: 21.1615681 },
    { lat: 49.0834072, lng: 21.1646251 },
    { lat: 49.0842661, lng: 21.1648812 },
    { lat: 49.0849767, lng: 21.1647214 },
    { lat: 49.085004, lng: 21.164995 },
    { lat: 49.084767, lng: 21.165187 },
    { lat: 49.084541, lng: 21.165498 },
    { lat: 49.083948, lng: 21.166051 },
    { lat: 49.084076, lng: 21.166127 },
    { lat: 49.084225, lng: 21.166192 },
    { lat: 49.08437, lng: 21.166276 },
    { lat: 49.083546, lng: 21.168646 },
    { lat: 49.083234, lng: 21.169088 },
    { lat: 49.083216, lng: 21.169187 },
    { lat: 49.082745, lng: 21.170512 },
    { lat: 49.082532, lng: 21.170932 },
    { lat: 49.082282, lng: 21.171023 },
    { lat: 49.082107, lng: 21.171244 },
    { lat: 49.081672, lng: 21.17121 },
    { lat: 49.081701, lng: 21.171325 },
    { lat: 49.081472, lng: 21.17132 },
    { lat: 49.080906, lng: 21.172378 },
    { lat: 49.080555, lng: 21.17302 },
    { lat: 49.080354, lng: 21.173294 },
    { lat: 49.080334, lng: 21.173351 },
    { lat: 49.080332, lng: 21.173354 },
    { lat: 49.080265, lng: 21.173542 },
    { lat: 49.080047, lng: 21.173579 },
    { lat: 49.079677, lng: 21.174335 },
    { lat: 49.079436, lng: 21.174864 },
    { lat: 49.079384, lng: 21.17505 },
    { lat: 49.07902, lng: 21.175695 },
    { lat: 49.078821, lng: 21.17625 },
    { lat: 49.078463, lng: 21.176805 },
    { lat: 49.07822, lng: 21.177388 },
    { lat: 49.078, lng: 21.177703 },
    { lat: 49.077841, lng: 21.177953 },
    { lat: 49.077624, lng: 21.178142 },
    { lat: 49.077363, lng: 21.178695 },
    { lat: 49.076946, lng: 21.179304 },
    { lat: 49.076811, lng: 21.179502 },
    { lat: 49.076394, lng: 21.180302 },
    { lat: 49.075873, lng: 21.181129 },
    { lat: 49.075564, lng: 21.181618 },
    { lat: 49.074994, lng: 21.182471 },
    { lat: 49.074776, lng: 21.182873 },
    { lat: 49.074711, lng: 21.182981 },
    { lat: 49.074862, lng: 21.183215 },
    { lat: 49.075138, lng: 21.183684 },
    { lat: 49.07555, lng: 21.184204 },
    { lat: 49.075876, lng: 21.18455 },
    { lat: 49.076141, lng: 21.1848 },
    { lat: 49.07639, lng: 21.184948 },
    { lat: 49.076555, lng: 21.185106 },
    { lat: 49.076332, lng: 21.18584 },
    { lat: 49.076164, lng: 21.186497 },
    { lat: 49.076377, lng: 21.186661 },
    { lat: 49.076399, lng: 21.186734 },
    { lat: 49.076344, lng: 21.186969 },
    { lat: 49.076273, lng: 21.187268 },
    { lat: 49.076279, lng: 21.18754 },
    { lat: 49.076257, lng: 21.187944 },
    { lat: 49.076223, lng: 21.188225 },
    { lat: 49.076256, lng: 21.188758 },
    { lat: 49.076181, lng: 21.189151 },
    { lat: 49.076542, lng: 21.18979 },
    { lat: 49.076879, lng: 21.189557 },
    { lat: 49.077086, lng: 21.190063 },
    { lat: 49.076652, lng: 21.190612 },
    { lat: 49.076556, lng: 21.190756 },
    { lat: 49.076463, lng: 21.190959 },
    { lat: 49.076368, lng: 21.191182 },
    { lat: 49.076237, lng: 21.191528 },
    { lat: 49.076048, lng: 21.192086 },
    { lat: 49.075895, lng: 21.192559 },
    { lat: 49.075718, lng: 21.193071 },
    { lat: 49.075627, lng: 21.193374 },
    { lat: 49.075503, lng: 21.193674 },
    { lat: 49.076035, lng: 21.194244 },
    { lat: 49.076061, lng: 21.194359 },
    { lat: 49.075986, lng: 21.1944 },
    { lat: 49.07576, lng: 21.194292 },
    { lat: 49.075991, lng: 21.194597 },
    { lat: 49.076308, lng: 21.194907 },
    { lat: 49.076575, lng: 21.195109 },
    { lat: 49.076569, lng: 21.195408 },
    { lat: 49.07632, lng: 21.195966 },
    { lat: 49.076092, lng: 21.196688 },
    { lat: 49.075502, lng: 21.196256 },
    { lat: 49.075656, lng: 21.19708 },
    { lat: 49.075825, lng: 21.198595 },
    { lat: 49.075947, lng: 21.199078 },
    { lat: 49.075692, lng: 21.199402 },
    { lat: 49.075818, lng: 21.199555 },
    { lat: 49.076257, lng: 21.199918 },
    { lat: 49.076049, lng: 21.200086 },
    { lat: 49.075667, lng: 21.199916 },
    { lat: 49.07528, lng: 21.200301 },
    { lat: 49.075137, lng: 21.200353 },
    { lat: 49.075195, lng: 21.200555 },
    { lat: 49.075013, lng: 21.200946 },
    { lat: 49.074058, lng: 21.202164 },
    { lat: 49.074557, lng: 21.201863 },
    { lat: 49.074835, lng: 21.201806 },
    { lat: 49.07518, lng: 21.20154 },
    { lat: 49.075337, lng: 21.201519 },
    { lat: 49.075514, lng: 21.201569 },
    { lat: 49.075593, lng: 21.201741 },
    { lat: 49.075789, lng: 21.201906 },
    { lat: 49.075821, lng: 21.202281 },
    { lat: 49.075731, lng: 21.202393 },
    { lat: 49.075552, lng: 21.202472 },
    { lat: 49.07521, lng: 21.202484 },
    { lat: 49.074965, lng: 21.202474 },
    { lat: 49.074648, lng: 21.202396 },
    { lat: 49.074541, lng: 21.202312 },
    { lat: 49.074254, lng: 21.202524 },
    { lat: 49.074119, lng: 21.202686 },
    { lat: 49.074102, lng: 21.203705 },
    { lat: 49.074241, lng: 21.203626 },
    { lat: 49.074289, lng: 21.203619 },
    { lat: 49.074392, lng: 21.203606 },
    { lat: 49.074417, lng: 21.203604 },
    { lat: 49.074477, lng: 21.203622 },
    { lat: 49.074704, lng: 21.203672 },
    { lat: 49.074838, lng: 21.203727 },
    { lat: 49.074977, lng: 21.203784 },
    { lat: 49.075028, lng: 21.203846 },
    { lat: 49.075084, lng: 21.203914 },
    { lat: 49.075166, lng: 21.203967 },
    { lat: 49.075239, lng: 21.20401 },
    { lat: 49.075356, lng: 21.204173 },
    { lat: 49.075471, lng: 21.204231 },
    { lat: 49.075595, lng: 21.204294 },
    { lat: 49.075894, lng: 21.204449 },
    { lat: 49.075894, lng: 21.204436 },
    { lat: 49.076122, lng: 21.204453 },
    { lat: 49.076121, lng: 21.204497 },
    { lat: 49.076281, lng: 21.204525 },
    { lat: 49.07643, lng: 21.204569 },
    { lat: 49.076571, lng: 21.204604 },
    { lat: 49.076709, lng: 21.204639 },
    { lat: 49.07672, lng: 21.204641 },
    { lat: 49.076959, lng: 21.204726 },
    { lat: 49.077124, lng: 21.204793 },
    { lat: 49.077233, lng: 21.204802 },
    { lat: 49.077331, lng: 21.204794 },
    { lat: 49.077312, lng: 21.205713 },
    { lat: 49.077563, lng: 21.205875 },
    { lat: 49.077962, lng: 21.206344 },
    { lat: 49.078084, lng: 21.206523 },
    { lat: 49.078037, lng: 21.206571 },
    { lat: 49.078089, lng: 21.206677 },
    { lat: 49.078274, lng: 21.206495 },
    { lat: 49.078417, lng: 21.206343 },
    { lat: 49.07861, lng: 21.206082 },
    { lat: 49.078818, lng: 21.205839 },
    { lat: 49.079122, lng: 21.205643 },
    { lat: 49.079382, lng: 21.205556 },
    { lat: 49.079534, lng: 21.20543 },
    { lat: 49.079677, lng: 21.20522 },
    { lat: 49.079869, lng: 21.205006 },
    { lat: 49.080106, lng: 21.205031 },
    { lat: 49.08028, lng: 21.205161 },
    { lat: 49.080457, lng: 21.205327 },
    { lat: 49.080593, lng: 21.205396 },
    { lat: 49.08071, lng: 21.205256 },
    { lat: 49.080722, lng: 21.204813 },
    { lat: 49.080697, lng: 21.204516 },
    { lat: 49.080693, lng: 21.204254 },
    { lat: 49.080808, lng: 21.203993 },
    { lat: 49.08093, lng: 21.203795 },
    { lat: 49.081135, lng: 21.203665 },
    { lat: 49.081293, lng: 21.203537 },
    { lat: 49.08147, lng: 21.203372 },
    { lat: 49.081614, lng: 21.203278 },
    { lat: 49.08176, lng: 21.203157 },
    { lat: 49.081859, lng: 21.202934 },
    { lat: 49.081878, lng: 21.202778 },
    { lat: 49.081744, lng: 21.202557 },
    { lat: 49.081635, lng: 21.202365 },
    { lat: 49.081663, lng: 21.202121 },
    { lat: 49.081735, lng: 21.202 },
    { lat: 49.081905, lng: 21.201914 },
    { lat: 49.082049, lng: 21.201704 },
    { lat: 49.082171, lng: 21.20149 },
    { lat: 49.082362, lng: 21.201261 },
    { lat: 49.082457, lng: 21.201249 },
    { lat: 49.082572, lng: 21.201257 },
    { lat: 49.082687, lng: 21.201359 },
    { lat: 49.082743, lng: 21.201507 },
    { lat: 49.082806, lng: 21.201697 },
    { lat: 49.082903, lng: 21.201743 },
    { lat: 49.082978, lng: 21.201751 },
    { lat: 49.082992, lng: 21.201753 },
    { lat: 49.082949, lng: 21.201838 },
    { lat: 49.083031, lng: 21.201911 },
    { lat: 49.083287, lng: 21.202958 },
    { lat: 49.083365, lng: 21.203119 },
    { lat: 49.083371, lng: 21.203127 },
    { lat: 49.083411, lng: 21.203184 },
    { lat: 49.083465, lng: 21.203264 },
    { lat: 49.083486, lng: 21.203294 },
    { lat: 49.083781, lng: 21.203295 },
    { lat: 49.08378, lng: 21.203427 },
    { lat: 49.084138, lng: 21.203365 },
    { lat: 49.084383, lng: 21.203595 },
    { lat: 49.0845172, lng: 21.2034123 },
    { lat: 49.0844663, lng: 21.2031184 },
    { lat: 49.0845627, lng: 21.2026706 },
    { lat: 49.0844099, lng: 21.202314 },
    { lat: 49.0842904, lng: 21.2023079 },
    { lat: 49.0843651, lng: 21.2021388 },
    { lat: 49.0843545, lng: 21.2018376 },
    { lat: 49.0844493, lng: 21.2017026 },
    { lat: 49.0845779, lng: 21.2016751 },
    { lat: 49.0846782, lng: 21.2013214 },
    { lat: 49.0848933, lng: 21.2013981 },
    { lat: 49.0849487, lng: 21.201187 },
    { lat: 49.0848951, lng: 21.2006243 },
    { lat: 49.0849678, lng: 21.2005713 },
    { lat: 49.0851151, lng: 21.2010393 },
    { lat: 49.085315, lng: 21.2011724 },
    { lat: 49.0854743, lng: 21.2011544 },
    { lat: 49.0856056, lng: 21.2009637 },
    { lat: 49.0854636, lng: 21.200667 },
    { lat: 49.0854928, lng: 21.2005212 },
    { lat: 49.0854566, lng: 21.2001182 },
    { lat: 49.0855456, lng: 21.1997965 },
    { lat: 49.0857332, lng: 21.1999657 },
    { lat: 49.0858057, lng: 21.2001282 },
    { lat: 49.0858827, lng: 21.2001384 },
    { lat: 49.0860411, lng: 21.1998412 },
    { lat: 49.08602, lng: 21.1995652 },
    { lat: 49.0859271, lng: 21.1994376 },
    { lat: 49.0859958, lng: 21.199288 },
    { lat: 49.0860796, lng: 21.1992012 },
    { lat: 49.0867715, lng: 21.1993756 },
    { lat: 49.0868227, lng: 21.1992103 },
    { lat: 49.0867732, lng: 21.1990294 },
    { lat: 49.0869638, lng: 21.199087 },
    { lat: 49.0873893, lng: 21.1985077 },
    { lat: 49.0875048, lng: 21.198461 },
    { lat: 49.0876964, lng: 21.1985107 },
    { lat: 49.0876782, lng: 21.1984312 },
    { lat: 49.087841, lng: 21.1983451 },
    { lat: 49.0877931, lng: 21.1980703 },
    { lat: 49.0878146, lng: 21.1979668 },
    { lat: 49.0879618, lng: 21.1979986 },
    { lat: 49.088126, lng: 21.1977067 },
    { lat: 49.0881033, lng: 21.1974836 },
    { lat: 49.0880447, lng: 21.1974063 },
    { lat: 49.0880612, lng: 21.1973204 },
    { lat: 49.0881577, lng: 21.1972771 },
    { lat: 49.0882799, lng: 21.1969768 },
    { lat: 49.0882183, lng: 21.1967331 },
    { lat: 49.0882319, lng: 21.1966248 },
    { lat: 49.0883594, lng: 21.1964942 },
    { lat: 49.0883563, lng: 21.1963559 },
    { lat: 49.0884096, lng: 21.1962583 },
    { lat: 49.088352, lng: 21.1961124 },
    { lat: 49.088439, lng: 21.1958322 },
    { lat: 49.0884217, lng: 21.1955279 },
    { lat: 49.0883426, lng: 21.1953042 },
    { lat: 49.0883051, lng: 21.1949633 },
    { lat: 49.0885019, lng: 21.1941301 },
    { lat: 49.0886602, lng: 21.1939446 },
    { lat: 49.0888592, lng: 21.1940795 },
    { lat: 49.0890016, lng: 21.1939877 },
    { lat: 49.0889519, lng: 21.1936926 },
    { lat: 49.0890302, lng: 21.1937562 },
    { lat: 49.0891139, lng: 21.1936949 },
    { lat: 49.0891487, lng: 21.193585 },
    { lat: 49.0891061, lng: 21.1934427 },
    { lat: 49.0893361, lng: 21.1932045 },
    { lat: 49.0892111, lng: 21.1929468 },
    { lat: 49.0892239, lng: 21.1928206 },
    { lat: 49.0893005, lng: 21.1927892 },
    { lat: 49.0893041, lng: 21.1926493 },
    { lat: 49.089379, lng: 21.1926008 },
    { lat: 49.0893821, lng: 21.1924128 },
    { lat: 49.0892852, lng: 21.1923688 },
    { lat: 49.0892127, lng: 21.1919931 },
    { lat: 49.0892293, lng: 21.1918756 },
    { lat: 49.089309, lng: 21.1917998 },
    { lat: 49.0892857, lng: 21.1916266 },
    { lat: 49.0893689, lng: 21.1915129 },
    { lat: 49.089448, lng: 21.1915617 },
    { lat: 49.0895455, lng: 21.1913691 },
    { lat: 49.089538, lng: 21.1912034 },
    { lat: 49.0896211, lng: 21.1911259 },
    { lat: 49.08971, lng: 21.1908757 },
    { lat: 49.0897811, lng: 21.1908792 },
    { lat: 49.0898884, lng: 21.1906768 },
    { lat: 49.0898681, lng: 21.190493 },
    { lat: 49.0899323, lng: 21.1904216 },
    { lat: 49.0899477, lng: 21.1903013 },
    { lat: 49.0898875, lng: 21.1899983 },
    { lat: 49.0900357, lng: 21.1900619 },
    { lat: 49.0900801, lng: 21.1898587 },
    { lat: 49.090266, lng: 21.1898707 },
    { lat: 49.0902402, lng: 21.1897602 },
    { lat: 49.0903602, lng: 21.1895727 },
    { lat: 49.09043, lng: 21.1896779 },
    { lat: 49.0905235, lng: 21.1896811 },
    { lat: 49.0905733, lng: 21.1895458 },
    { lat: 49.0905983, lng: 21.1890478 },
    { lat: 49.0908375, lng: 21.1887798 },
    { lat: 49.0910113, lng: 21.1884024 },
    { lat: 49.0911176, lng: 21.1879506 },
    { lat: 49.0911423, lng: 21.1874102 },
    { lat: 49.0917769, lng: 21.1869761 },
    { lat: 49.0924247, lng: 21.1860247 },
    { lat: 49.0927013, lng: 21.1858377 },
    { lat: 49.0928561, lng: 21.1856081 },
    { lat: 49.0930257, lng: 21.1851269 },
    { lat: 49.0930992, lng: 21.1851399 },
    { lat: 49.093318, lng: 21.1847222 },
    { lat: 49.0934149, lng: 21.1846549 },
    { lat: 49.0935496, lng: 21.1846792 },
    { lat: 49.0938325, lng: 21.1844806 },
    { lat: 49.0940165, lng: 21.1841366 },
    { lat: 49.0942629, lng: 21.1839567 },
    { lat: 49.0942908, lng: 21.1838449 },
    { lat: 49.094548, lng: 21.1837522 },
    { lat: 49.0945994, lng: 21.1836688 },
    { lat: 49.0946578, lng: 21.183704 },
    { lat: 49.0951195, lng: 21.1832806 },
    { lat: 49.0956398, lng: 21.182938 },
    { lat: 49.0957447, lng: 21.1826201 },
    { lat: 49.0958534, lng: 21.1824643 },
    { lat: 49.0960715, lng: 21.1823792 },
    { lat: 49.0961556, lng: 21.1820528 },
    { lat: 49.0964166, lng: 21.1817261 },
    { lat: 49.0966119, lng: 21.1816009 },
    { lat: 49.0967662, lng: 21.1813858 },
    { lat: 49.0969249, lng: 21.1813922 },
    { lat: 49.097033, lng: 21.181217 },
    { lat: 49.0978392, lng: 21.1805441 },
    { lat: 49.0978489, lng: 21.1805305 },
    { lat: 49.0979408, lng: 21.1804071 },
    { lat: 49.0980154, lng: 21.1801011 },
    { lat: 49.0985006, lng: 21.1794206 },
    { lat: 49.0986561, lng: 21.1792818 },
    { lat: 49.0987642, lng: 21.1789593 },
    { lat: 49.0989805, lng: 21.1785418 },
    { lat: 49.1001175, lng: 21.1767563 },
    { lat: 49.1004775, lng: 21.1758197 },
    { lat: 49.1005362, lng: 21.1753235 },
    { lat: 49.1006737, lng: 21.1727138 },
    { lat: 49.0997381, lng: 21.1723046 },
    { lat: 49.0990318, lng: 21.1734599 },
    { lat: 49.0986998, lng: 21.1732122 },
    { lat: 49.0984104, lng: 21.173136 },
    { lat: 49.0972662, lng: 21.1723125 },
    { lat: 49.0964331, lng: 21.1715468 },
    { lat: 49.0975122, lng: 21.1684317 },
    { lat: 49.0983622, lng: 21.167012 },
    { lat: 49.0984422, lng: 21.1666227 },
    { lat: 49.098448, lng: 21.1663425 },
    { lat: 49.0983057, lng: 21.1655729 },
    { lat: 49.0982929, lng: 21.1651979 },
    { lat: 49.0981895, lng: 21.1652381 },
    { lat: 49.0969236, lng: 21.1649562 },
    { lat: 49.0970752, lng: 21.1640862 },
    { lat: 49.0973009, lng: 21.1633145 },
    { lat: 49.0971522, lng: 21.1632809 },
    { lat: 49.0969166, lng: 21.1630183 },
    { lat: 49.096706, lng: 21.1624685 },
    { lat: 49.0965429, lng: 21.1623303 },
    { lat: 49.096141, lng: 21.1624709 },
    { lat: 49.0959648, lng: 21.162417 },
    { lat: 49.095783, lng: 21.1621476 },
    { lat: 49.0952636, lng: 21.1617654 },
    { lat: 49.0950517, lng: 21.1617159 },
    { lat: 49.0948014, lng: 21.161774 },
    { lat: 49.0943558, lng: 21.1617013 },
    { lat: 49.0940831, lng: 21.1617654 },
    { lat: 49.0935646, lng: 21.161414 },
    { lat: 49.0931131, lng: 21.1612674 },
    { lat: 49.0929145, lng: 21.1611386 },
    { lat: 49.0927669, lng: 21.1609203 },
    { lat: 49.0926845, lng: 21.1605518 },
    { lat: 49.0925233, lng: 21.1602261 },
    { lat: 49.0923029, lng: 21.1599815 },
    { lat: 49.0916813, lng: 21.1584969 },
    { lat: 49.0914688, lng: 21.1578593 },
    { lat: 49.091399, lng: 21.1573581 },
    { lat: 49.0913382, lng: 21.1556455 },
    { lat: 49.0914334, lng: 21.1547011 },
  ],
  Rožkovany: [
    { lat: 49.1424102, lng: 20.9740705 },
    { lat: 49.1422613, lng: 20.9739266 },
    { lat: 49.1405999, lng: 20.972317 },
    { lat: 49.1392854, lng: 20.9713605 },
    { lat: 49.1377107, lng: 20.9699273 },
    { lat: 49.13697, lng: 20.9698239 },
    { lat: 49.135155, lng: 20.9686408 },
    { lat: 49.1338203, lng: 20.9679319 },
    { lat: 49.1335428, lng: 20.9677433 },
    { lat: 49.132904, lng: 20.9671106 },
    { lat: 49.1319484, lng: 20.9665998 },
    { lat: 49.131166, lng: 20.9657766 },
    { lat: 49.1305411, lng: 20.9648845 },
    { lat: 49.1295425, lng: 20.9641617 },
    { lat: 49.128937, lng: 20.9655901 },
    { lat: 49.1280266, lng: 20.9647779 },
    { lat: 49.1276577, lng: 20.9643783 },
    { lat: 49.1261812, lng: 20.9639731 },
    { lat: 49.1251474, lng: 20.9634098 },
    { lat: 49.1251253, lng: 20.9625731 },
    { lat: 49.1247345, lng: 20.9614127 },
    { lat: 49.1229877, lng: 20.961406 },
    { lat: 49.1228715, lng: 20.9609255 },
    { lat: 49.1229721, lng: 20.9600288 },
    { lat: 49.1224001, lng: 20.9600183 },
    { lat: 49.1211466, lng: 20.9616869 },
    { lat: 49.1192252, lng: 20.9598831 },
    { lat: 49.1188697, lng: 20.960255 },
    { lat: 49.1187361, lng: 20.9605359 },
    { lat: 49.1186065, lng: 20.9606048 },
    { lat: 49.1181642, lng: 20.9606004 },
    { lat: 49.1180036, lng: 20.9604816 },
    { lat: 49.117547, lng: 20.9603185 },
    { lat: 49.1174157, lng: 20.960362 },
    { lat: 49.1169977, lng: 20.9602462 },
    { lat: 49.1164017, lng: 20.960536 },
    { lat: 49.1159556, lng: 20.9602837 },
    { lat: 49.1154161, lng: 20.959293 },
    { lat: 49.1149804, lng: 20.9577627 },
    { lat: 49.1137902, lng: 20.9576715 },
    { lat: 49.1128319, lng: 20.9579344 },
    { lat: 49.1112573, lng: 20.9577227 },
    { lat: 49.1110814, lng: 20.9561196 },
    { lat: 49.1105986, lng: 20.953552 },
    { lat: 49.1089005, lng: 20.95292 },
    { lat: 49.108361, lng: 20.9534462 },
    { lat: 49.1076117, lng: 20.9532849 },
    { lat: 49.1057945, lng: 20.9510629 },
    { lat: 49.1038368, lng: 20.9502715 },
    { lat: 49.103132, lng: 20.9496241 },
    { lat: 49.1024973, lng: 20.9488803 },
    { lat: 49.1023123, lng: 20.9487973 },
    { lat: 49.1019047, lng: 20.9488676 },
    { lat: 49.1009467, lng: 20.9496674 },
    { lat: 49.1008109, lng: 20.9505895 },
    { lat: 49.1002159, lng: 20.9511088 },
    { lat: 49.1002967, lng: 20.9520487 },
    { lat: 49.1001596, lng: 20.9522579 },
    { lat: 49.1001531, lng: 20.9521969 },
    { lat: 49.0998658, lng: 20.9524932 },
    { lat: 49.0997434, lng: 20.953147 },
    { lat: 49.0994273, lng: 20.9542 },
    { lat: 49.0991558, lng: 20.9544264 },
    { lat: 49.099076, lng: 20.9548714 },
    { lat: 49.0991814, lng: 20.9554735 },
    { lat: 49.0991357, lng: 20.9559914 },
    { lat: 49.0987059, lng: 20.9568746 },
    { lat: 49.0986834, lng: 20.9575548 },
    { lat: 49.0985433, lng: 20.9580799 },
    { lat: 49.0985353, lng: 20.9585126 },
    { lat: 49.0983213, lng: 20.9600713 },
    { lat: 49.0981781, lng: 20.9606379 },
    { lat: 49.0980921, lng: 20.9613668 },
    { lat: 49.0981888, lng: 20.9614653 },
    { lat: 49.0979438, lng: 20.962363 },
    { lat: 49.0981008, lng: 20.9628157 },
    { lat: 49.0979606, lng: 20.9632104 },
    { lat: 49.0979593, lng: 20.9636411 },
    { lat: 49.0980533, lng: 20.9638641 },
    { lat: 49.0977085, lng: 20.9648036 },
    { lat: 49.0979215, lng: 20.9649808 },
    { lat: 49.097469, lng: 20.9664705 },
    { lat: 49.0974957, lng: 20.967376 },
    { lat: 49.0978593, lng: 20.9681271 },
    { lat: 49.0981703, lng: 20.9703461 },
    { lat: 49.0981858, lng: 20.9716325 },
    { lat: 49.0987596, lng: 20.9716908 },
    { lat: 49.0995816, lng: 20.9707886 },
    { lat: 49.1005931, lng: 20.9715586 },
    { lat: 49.1016889, lng: 20.9722287 },
    { lat: 49.102313, lng: 20.9733999 },
    { lat: 49.1022017, lng: 20.9744917 },
    { lat: 49.1041311, lng: 20.9748114 },
    { lat: 49.104304, lng: 20.9750696 },
    { lat: 49.105623, lng: 20.9759882 },
    { lat: 49.1063707, lng: 20.9767183 },
    { lat: 49.1066387, lng: 20.9770655 },
    { lat: 49.107753, lng: 20.9794813 },
    { lat: 49.1077814, lng: 20.9802073 },
    { lat: 49.1074933, lng: 20.9825966 },
    { lat: 49.1074421, lng: 20.9842802 },
    { lat: 49.1075589, lng: 20.9849186 },
    { lat: 49.1078487, lng: 20.9858253 },
    { lat: 49.1080453, lng: 20.9869379 },
    { lat: 49.1080651, lng: 20.9876767 },
    { lat: 49.1080117, lng: 20.9881744 },
    { lat: 49.107621, lng: 20.9883774 },
    { lat: 49.1074248, lng: 20.9890142 },
    { lat: 49.1067713, lng: 20.9900807 },
    { lat: 49.1064238, lng: 20.9900559 },
    { lat: 49.1058511, lng: 20.9911553 },
    { lat: 49.105393, lng: 20.9918405 },
    { lat: 49.1050596, lng: 20.9926145 },
    { lat: 49.1045502, lng: 20.9926308 },
    { lat: 49.1044605, lng: 20.9936958 },
    { lat: 49.1041498, lng: 20.9945151 },
    { lat: 49.1040612, lng: 20.9952431 },
    { lat: 49.1036745, lng: 20.9972733 },
    { lat: 49.1037363, lng: 20.9983702 },
    { lat: 49.1039061, lng: 20.9992527 },
    { lat: 49.104372, lng: 20.9982952 },
    { lat: 49.1049706, lng: 20.9983253 },
    { lat: 49.1053272, lng: 20.9980463 },
    { lat: 49.1061582, lng: 20.9976158 },
    { lat: 49.106539, lng: 20.9974539 },
    { lat: 49.1072846, lng: 20.9972656 },
    { lat: 49.1078397, lng: 20.9968956 },
    { lat: 49.1089863, lng: 20.9956633 },
    { lat: 49.1096133, lng: 20.9955943 },
    { lat: 49.1105854, lng: 20.9951425 },
    { lat: 49.1119067, lng: 20.9950413 },
    { lat: 49.113984, lng: 20.994484 },
    { lat: 49.1143723, lng: 20.9942154 },
    { lat: 49.1145395, lng: 20.9940277 },
    { lat: 49.1147336, lng: 20.9940071 },
    { lat: 49.1155781, lng: 20.9936114 },
    { lat: 49.1162722, lng: 20.9935699 },
    { lat: 49.1164294, lng: 20.993795 },
    { lat: 49.1164976, lng: 20.9938185 },
    { lat: 49.1171511, lng: 20.9935976 },
    { lat: 49.1173636, lng: 20.9935904 },
    { lat: 49.1178056, lng: 20.9937923 },
    { lat: 49.1180316, lng: 20.9938164 },
    { lat: 49.1181719, lng: 20.9939422 },
    { lat: 49.118401, lng: 20.9937889 },
    { lat: 49.1187343, lng: 20.9938274 },
    { lat: 49.1189982, lng: 20.9937756 },
    { lat: 49.1193961, lng: 20.9938275 },
    { lat: 49.1198503, lng: 20.9937842 },
    { lat: 49.120422, lng: 20.993885 },
    { lat: 49.1206171, lng: 20.9939948 },
    { lat: 49.1208052, lng: 20.9939355 },
    { lat: 49.1210801, lng: 20.9942188 },
    { lat: 49.1219539, lng: 20.994437 },
    { lat: 49.1220173, lng: 20.9947933 },
    { lat: 49.1222988, lng: 20.9947966 },
    { lat: 49.1225823, lng: 20.9951357 },
    { lat: 49.1229442, lng: 20.9951897 },
    { lat: 49.1233389, lng: 20.9954517 },
    { lat: 49.1239123, lng: 20.9956055 },
    { lat: 49.1240367, lng: 20.995725 },
    { lat: 49.1243807, lng: 20.9962949 },
    { lat: 49.1246387, lng: 20.9963726 },
    { lat: 49.1246672, lng: 20.9966241 },
    { lat: 49.1248441, lng: 20.9967676 },
    { lat: 49.1249554, lng: 20.9970685 },
    { lat: 49.1251966, lng: 20.9970455 },
    { lat: 49.1253232, lng: 20.9971033 },
    { lat: 49.1255429, lng: 20.9975498 },
    { lat: 49.125754, lng: 20.9977464 },
    { lat: 49.1260256, lng: 20.9977857 },
    { lat: 49.1263364, lng: 20.9977289 },
    { lat: 49.1265499, lng: 20.997888 },
    { lat: 49.126701, lng: 20.9977579 },
    { lat: 49.1272712, lng: 20.997986 },
    { lat: 49.1275154, lng: 20.9982265 },
    { lat: 49.1277341, lng: 20.9981706 },
    { lat: 49.127835, lng: 20.9982276 },
    { lat: 49.1279103, lng: 20.9984289 },
    { lat: 49.1280936, lng: 20.9986614 },
    { lat: 49.1283684, lng: 20.9987713 },
    { lat: 49.1283898, lng: 20.9990489 },
    { lat: 49.128435, lng: 20.9991169 },
    { lat: 49.128836, lng: 20.9992628 },
    { lat: 49.1289948, lng: 20.9991655 },
    { lat: 49.1291904, lng: 20.9995703 },
    { lat: 49.1297519, lng: 20.9996727 },
    { lat: 49.1298643, lng: 20.9999423 },
    { lat: 49.1300456, lng: 20.9999098 },
    { lat: 49.1303058, lng: 21.0001206 },
    { lat: 49.1304078, lng: 20.9999389 },
    { lat: 49.1306061, lng: 21.0000154 },
    { lat: 49.1307066, lng: 20.9999887 },
    { lat: 49.1307472, lng: 21.0002979 },
    { lat: 49.1307234, lng: 21.0005355 },
    { lat: 49.1309554, lng: 21.0008139 },
    { lat: 49.1310806, lng: 21.0007199 },
    { lat: 49.1311524, lng: 21.0007446 },
    { lat: 49.131299, lng: 21.0011388 },
    { lat: 49.1315136, lng: 21.0015024 },
    { lat: 49.1315765, lng: 21.0023845 },
    { lat: 49.1320622, lng: 21.0037462 },
    { lat: 49.1325551, lng: 21.0042956 },
    { lat: 49.1327449, lng: 21.0047958 },
    { lat: 49.1333282, lng: 21.0051161 },
    { lat: 49.1337468, lng: 21.0055971 },
    { lat: 49.1337833, lng: 21.0058128 },
    { lat: 49.1339658, lng: 21.0059531 },
    { lat: 49.1346486, lng: 21.0061491 },
    { lat: 49.1347479, lng: 21.006092 },
    { lat: 49.1349036, lng: 21.0062113 },
    { lat: 49.1350889, lng: 21.0059934 },
    { lat: 49.1357133, lng: 21.0059476 },
    { lat: 49.135758, lng: 21.0061814 },
    { lat: 49.1373637, lng: 21.0067902 },
    { lat: 49.1382275, lng: 21.0070106 },
    { lat: 49.1383533, lng: 21.0070438 },
    { lat: 49.1384523, lng: 21.0070697 },
    { lat: 49.1398335, lng: 21.0075288 },
    { lat: 49.1403082, lng: 21.0077659 },
    { lat: 49.1417407, lng: 21.0081 },
    { lat: 49.1439152, lng: 21.0077282 },
    { lat: 49.1439123, lng: 21.007677 },
    { lat: 49.1448722, lng: 21.0071884 },
    { lat: 49.1448085, lng: 21.0068927 },
    { lat: 49.1450934, lng: 21.0067276 },
    { lat: 49.14527, lng: 21.0067271 },
    { lat: 49.1455353, lng: 21.0069589 },
    { lat: 49.1462359, lng: 21.0073513 },
    { lat: 49.1470984, lng: 21.0075416 },
    { lat: 49.1481678, lng: 21.0072365 },
    { lat: 49.1484887, lng: 21.0070403 },
    { lat: 49.1486508, lng: 21.0068701 },
    { lat: 49.1486359, lng: 21.0068241 },
    { lat: 49.1485824, lng: 21.006532 },
    { lat: 49.1483429, lng: 21.0065348 },
    { lat: 49.1491427, lng: 20.9991176 },
    { lat: 49.149266, lng: 20.9985204 },
    { lat: 49.1521051, lng: 20.9996034 },
    { lat: 49.1529537, lng: 20.9998148 },
    { lat: 49.154643, lng: 20.9999857 },
    { lat: 49.1559773, lng: 21.0004164 },
    { lat: 49.1567882, lng: 21.0005707 },
    { lat: 49.1568799, lng: 21.0004206 },
    { lat: 49.1569461, lng: 21.0001735 },
    { lat: 49.1569677, lng: 20.9998698 },
    { lat: 49.1569139, lng: 20.9998743 },
    { lat: 49.1569913, lng: 20.999561 },
    { lat: 49.157214, lng: 20.9994825 },
    { lat: 49.1572767, lng: 20.999066 },
    { lat: 49.1572619, lng: 20.99865 },
    { lat: 49.1573439, lng: 20.9984399 },
    { lat: 49.1574285, lng: 20.9984111 },
    { lat: 49.1574729, lng: 20.9984628 },
    { lat: 49.1573166, lng: 20.9980195 },
    { lat: 49.1573422, lng: 20.9971887 },
    { lat: 49.1566218, lng: 20.9967037 },
    { lat: 49.1565446, lng: 20.9965783 },
    { lat: 49.1564915, lng: 20.9965403 },
    { lat: 49.1548099, lng: 20.995132 },
    { lat: 49.154401, lng: 20.9949083 },
    { lat: 49.1537724, lng: 20.9943825 },
    { lat: 49.1540369, lng: 20.9921543 },
    { lat: 49.1527172, lng: 20.9919971 },
    { lat: 49.1523052, lng: 20.9919901 },
    { lat: 49.1519634, lng: 20.9921025 },
    { lat: 49.1517361, lng: 20.9921037 },
    { lat: 49.1514317, lng: 20.9919925 },
    { lat: 49.1518515, lng: 20.9901779 },
    { lat: 49.1509767, lng: 20.9898594 },
    { lat: 49.1514791, lng: 20.9863522 },
    { lat: 49.1509348, lng: 20.9857816 },
    { lat: 49.1502232, lng: 20.9853971 },
    { lat: 49.1497272, lng: 20.9844159 },
    { lat: 49.1492822, lng: 20.9844018 },
    { lat: 49.1486697, lng: 20.9848036 },
    { lat: 49.1478938, lng: 20.9852027 },
    { lat: 49.1456765, lng: 20.9859775 },
    { lat: 49.1453478, lng: 20.9860153 },
    { lat: 49.1450398, lng: 20.9858861 },
    { lat: 49.145626, lng: 20.9838647 },
    { lat: 49.1450658, lng: 20.9841841 },
    { lat: 49.1449025, lng: 20.983973 },
    { lat: 49.1453678, lng: 20.9832018 },
    { lat: 49.145718, lng: 20.9822835 },
    { lat: 49.1458832, lng: 20.9815666 },
    { lat: 49.1459675, lng: 20.9800959 },
    { lat: 49.1464015, lng: 20.9789198 },
    { lat: 49.1465046, lng: 20.977984 },
    { lat: 49.1465161, lng: 20.9774471 },
    { lat: 49.1464345, lng: 20.9768084 },
    { lat: 49.1450417, lng: 20.9753251 },
    { lat: 49.1424102, lng: 20.9740705 },
  ],
};

export default UnitsSabinov;
