const UnitsBánovcenadBebravou = {
  Miezgovce: [
    { lat: 48.7224821, lng: 18.2928946 },
    { lat: 48.7223704, lng: 18.293074 },
    { lat: 48.7223249, lng: 18.2931474 },
    { lat: 48.7223218, lng: 18.2931516 },
    { lat: 48.7222681, lng: 18.29324 },
    { lat: 48.7222186, lng: 18.2932827 },
    { lat: 48.7222068, lng: 18.2932935 },
    { lat: 48.7221725, lng: 18.293324 },
    { lat: 48.7220843, lng: 18.2934016 },
    { lat: 48.7220488, lng: 18.2934326 },
    { lat: 48.7219918, lng: 18.2934828 },
    { lat: 48.7219457, lng: 18.2935235 },
    { lat: 48.7218275, lng: 18.2936278 },
    { lat: 48.7217888, lng: 18.2936617 },
    { lat: 48.7217268, lng: 18.2936435 },
    { lat: 48.721674, lng: 18.2936269 },
    { lat: 48.7216039, lng: 18.2936053 },
    { lat: 48.7215251, lng: 18.2935076 },
    { lat: 48.7214973, lng: 18.2934761 },
    { lat: 48.7214113, lng: 18.2933722 },
    { lat: 48.7212297, lng: 18.2931536 },
    { lat: 48.7211739, lng: 18.2930853 },
    { lat: 48.7211376, lng: 18.2930428 },
    { lat: 48.7211364, lng: 18.2930405 },
    { lat: 48.7210879, lng: 18.2929817 },
    { lat: 48.7210359, lng: 18.2929195 },
    { lat: 48.721008, lng: 18.2928856 },
    { lat: 48.720968, lng: 18.2928372 },
    { lat: 48.720956, lng: 18.2928241 },
    { lat: 48.7208924, lng: 18.2927565 },
    { lat: 48.7208443, lng: 18.292706 },
    { lat: 48.7208227, lng: 18.2926832 },
    { lat: 48.7207855, lng: 18.2926437 },
    { lat: 48.7207111, lng: 18.2925638 },
    { lat: 48.7206871, lng: 18.2925387 },
    { lat: 48.7206201, lng: 18.2924913 },
    { lat: 48.7205248, lng: 18.2924229 },
    { lat: 48.720493, lng: 18.2923998 },
    { lat: 48.7204754, lng: 18.2923871 },
    { lat: 48.7203013, lng: 18.2922632 },
    { lat: 48.7202614, lng: 18.2922346 },
    { lat: 48.7202578, lng: 18.292232 },
    { lat: 48.7201967, lng: 18.2921879 },
    { lat: 48.7194535, lng: 18.2921955 },
    { lat: 48.7194234, lng: 18.292202 },
    { lat: 48.7190364, lng: 18.2922807 },
    { lat: 48.718955, lng: 18.2922971 },
    { lat: 48.7186705, lng: 18.292355 },
    { lat: 48.7186158, lng: 18.2923664 },
    { lat: 48.7184576, lng: 18.2924201 },
    { lat: 48.7180568, lng: 18.2925567 },
    { lat: 48.7178797, lng: 18.2926168 },
    { lat: 48.7176938, lng: 18.2926802 },
    { lat: 48.7174292, lng: 18.2927702 },
    { lat: 48.717241, lng: 18.2928342 },
    { lat: 48.7171079, lng: 18.2929133 },
    { lat: 48.7169681, lng: 18.292996 },
    { lat: 48.7168808, lng: 18.2930474 },
    { lat: 48.7167312, lng: 18.2931364 },
    { lat: 48.7165403, lng: 18.2932498 },
    { lat: 48.7164682, lng: 18.2932927 },
    { lat: 48.7163907, lng: 18.2933386 },
    { lat: 48.7162302, lng: 18.2934335 },
    { lat: 48.7160687, lng: 18.2935295 },
    { lat: 48.7158711, lng: 18.2936467 },
    { lat: 48.71578, lng: 18.2937126 },
    { lat: 48.7156359, lng: 18.2938174 },
    { lat: 48.7155396, lng: 18.2938883 },
    { lat: 48.7154741, lng: 18.2938498 },
    { lat: 48.7153458, lng: 18.2937749 },
    { lat: 48.715249, lng: 18.2937189 },
    { lat: 48.7151205, lng: 18.293643 },
    { lat: 48.7148393, lng: 18.293478 },
    { lat: 48.7147763, lng: 18.2934414 },
    { lat: 48.7147272, lng: 18.293412 },
    { lat: 48.7146829, lng: 18.2933866 },
    { lat: 48.7146805, lng: 18.2933848 },
    { lat: 48.7144962, lng: 18.293277 },
    { lat: 48.7139939, lng: 18.293155 },
    { lat: 48.7139194, lng: 18.2931339 },
    { lat: 48.7138632, lng: 18.2931176 },
    { lat: 48.7134813, lng: 18.2930095 },
    { lat: 48.7134629, lng: 18.293004 },
    { lat: 48.7133011, lng: 18.2930932 },
    { lat: 48.7132977, lng: 18.2930946 },
    { lat: 48.71308, lng: 18.2932144 },
    { lat: 48.7125576, lng: 18.2940105 },
    { lat: 48.7118035, lng: 18.2952385 },
    { lat: 48.711649, lng: 18.2959963 },
    { lat: 48.7115725, lng: 18.2967755 },
    { lat: 48.7115513, lng: 18.2986102 },
    { lat: 48.7115532, lng: 18.2986446 },
    { lat: 48.711555, lng: 18.2987154 },
    { lat: 48.7115578, lng: 18.2987867 },
    { lat: 48.7115679, lng: 18.2990432 },
    { lat: 48.7116109, lng: 18.3001826 },
    { lat: 48.7116135, lng: 18.3002466 },
    { lat: 48.7116137, lng: 18.3003118 },
    { lat: 48.7116128, lng: 18.300374 },
    { lat: 48.7116124, lng: 18.3004279 },
    { lat: 48.7116071, lng: 18.3017086 },
    { lat: 48.7116055, lng: 18.3020997 },
    { lat: 48.7115999, lng: 18.3024975 },
    { lat: 48.7114345, lng: 18.3034822 },
    { lat: 48.7114539, lng: 18.3037848 },
    { lat: 48.711468, lng: 18.3039956 },
    { lat: 48.711433, lng: 18.3047103 },
    { lat: 48.7113206, lng: 18.3052968 },
    { lat: 48.711286, lng: 18.3059612 },
    { lat: 48.711203, lng: 18.3066666 },
    { lat: 48.7111733, lng: 18.306921 },
    { lat: 48.7110588, lng: 18.3078875 },
    { lat: 48.7110613, lng: 18.3088879 },
    { lat: 48.711041, lng: 18.3090098 },
    { lat: 48.7105695, lng: 18.3096088 },
    { lat: 48.7102426, lng: 18.3099527 },
    { lat: 48.7098058, lng: 18.3104115 },
    { lat: 48.7093455, lng: 18.3106307 },
    { lat: 48.7090126, lng: 18.3107901 },
    { lat: 48.7087365, lng: 18.310874 },
    { lat: 48.7076221, lng: 18.3110997 },
    { lat: 48.7068649, lng: 18.3117568 },
    { lat: 48.7059536, lng: 18.3126203 },
    { lat: 48.7049485, lng: 18.3136202 },
    { lat: 48.7048104, lng: 18.3137727 },
    { lat: 48.7043227, lng: 18.3142679 },
    { lat: 48.7041896, lng: 18.3145662 },
    { lat: 48.7031633, lng: 18.3155123 },
    { lat: 48.7028793, lng: 18.3158386 },
    { lat: 48.7021493, lng: 18.3166777 },
    { lat: 48.7019322, lng: 18.3171281 },
    { lat: 48.7016133, lng: 18.3175139 },
    { lat: 48.7013734, lng: 18.3177217 },
    { lat: 48.7015883, lng: 18.3179706 },
    { lat: 48.7016619, lng: 18.3180556 },
    { lat: 48.702248, lng: 18.3182429 },
    { lat: 48.7023988, lng: 18.3184554 },
    { lat: 48.7027765, lng: 18.3188516 },
    { lat: 48.7028609, lng: 18.3200262 },
    { lat: 48.7028215, lng: 18.3210872 },
    { lat: 48.7026933, lng: 18.3218138 },
    { lat: 48.7025528, lng: 18.3226027 },
    { lat: 48.7026042, lng: 18.3229328 },
    { lat: 48.7026916, lng: 18.3235816 },
    { lat: 48.7026925, lng: 18.3240371 },
    { lat: 48.7025588, lng: 18.3249262 },
    { lat: 48.7024603, lng: 18.3257192 },
    { lat: 48.7023522, lng: 18.3260433 },
    { lat: 48.7022059, lng: 18.3266088 },
    { lat: 48.702183, lng: 18.3277653 },
    { lat: 48.7019207, lng: 18.3284745 },
    { lat: 48.701835, lng: 18.3288149 },
    { lat: 48.701681, lng: 18.3292651 },
    { lat: 48.701562, lng: 18.3297357 },
    { lat: 48.7014451, lng: 18.3305245 },
    { lat: 48.7014563, lng: 18.3309824 },
    { lat: 48.7015871, lng: 18.3313828 },
    { lat: 48.7006656, lng: 18.3328688 },
    { lat: 48.7004225, lng: 18.3333389 },
    { lat: 48.7004287, lng: 18.3333881 },
    { lat: 48.7004538, lng: 18.3335736 },
    { lat: 48.7006429, lng: 18.333845 },
    { lat: 48.7000669, lng: 18.3351758 },
    { lat: 48.7002766, lng: 18.3358348 },
    { lat: 48.7006816, lng: 18.3362758 },
    { lat: 48.7009937, lng: 18.3365271 },
    { lat: 48.7011565, lng: 18.3367344 },
    { lat: 48.7013748, lng: 18.3371228 },
    { lat: 48.7014791, lng: 18.3372521 },
    { lat: 48.7013149, lng: 18.3378041 },
    { lat: 48.7012867, lng: 18.3379037 },
    { lat: 48.7017643, lng: 18.3380092 },
    { lat: 48.7022908, lng: 18.3384827 },
    { lat: 48.7023213, lng: 18.3392263 },
    { lat: 48.7026992, lng: 18.3396639 },
    { lat: 48.7031792, lng: 18.339933 },
    { lat: 48.7036618, lng: 18.3400299 },
    { lat: 48.7039735, lng: 18.3404152 },
    { lat: 48.7041918, lng: 18.3405847 },
    { lat: 48.7043269, lng: 18.3408397 },
    { lat: 48.7044957, lng: 18.3408525 },
    { lat: 48.7045882, lng: 18.3410317 },
    { lat: 48.7048184, lng: 18.3410945 },
    { lat: 48.7050537, lng: 18.3411651 },
    { lat: 48.7053806, lng: 18.3412192 },
    { lat: 48.7056769, lng: 18.3415339 },
    { lat: 48.7062009, lng: 18.3416025 },
    { lat: 48.7064329, lng: 18.3418749 },
    { lat: 48.7067508, lng: 18.3422325 },
    { lat: 48.7070095, lng: 18.3425384 },
    { lat: 48.7072632, lng: 18.3428366 },
    { lat: 48.7075217, lng: 18.3430181 },
    { lat: 48.7079304, lng: 18.3431835 },
    { lat: 48.7081486, lng: 18.3432714 },
    { lat: 48.7083839, lng: 18.3432424 },
    { lat: 48.7086096, lng: 18.3431852 },
    { lat: 48.7088178, lng: 18.3432752 },
    { lat: 48.7089558, lng: 18.34328 },
    { lat: 48.7091479, lng: 18.3431467 },
    { lat: 48.7094038, lng: 18.3430213 },
    { lat: 48.7097509, lng: 18.3428753 },
    { lat: 48.7101547, lng: 18.3427716 },
    { lat: 48.7103924, lng: 18.3426464 },
    { lat: 48.7105457, lng: 18.3426037 },
    { lat: 48.7106375, lng: 18.3425492 },
    { lat: 48.7109952, lng: 18.342231 },
    { lat: 48.7111983, lng: 18.3421903 },
    { lat: 48.7114941, lng: 18.3419733 },
    { lat: 48.7116775, lng: 18.3418432 },
    { lat: 48.7119733, lng: 18.3420272 },
    { lat: 48.7120661, lng: 18.3420523 },
    { lat: 48.7124087, lng: 18.3420463 },
    { lat: 48.7129226, lng: 18.341996 },
    { lat: 48.7134374, lng: 18.3421805 },
    { lat: 48.7139943, lng: 18.3424351 },
    { lat: 48.7141314, lng: 18.3424816 },
    { lat: 48.7146212, lng: 18.3426477 },
    { lat: 48.7154429, lng: 18.3427748 },
    { lat: 48.7162817, lng: 18.342905 },
    { lat: 48.7173319, lng: 18.3432397 },
    { lat: 48.7183246, lng: 18.3438802 },
    { lat: 48.7191341, lng: 18.34357 },
    { lat: 48.7196919, lng: 18.3433972 },
    { lat: 48.7200634, lng: 18.3431808 },
    { lat: 48.7202662, lng: 18.3425941 },
    { lat: 48.720367, lng: 18.34244 },
    { lat: 48.7204744, lng: 18.3423248 },
    { lat: 48.7205469, lng: 18.3422694 },
    { lat: 48.7206544, lng: 18.3421759 },
    { lat: 48.7207708, lng: 18.3419994 },
    { lat: 48.720911, lng: 18.3418436 },
    { lat: 48.7210388, lng: 18.3417288 },
    { lat: 48.721145, lng: 18.3416921 },
    { lat: 48.7212094, lng: 18.3416936 },
    { lat: 48.7212756, lng: 18.3417451 },
    { lat: 48.7212847, lng: 18.3419866 },
    { lat: 48.7212101, lng: 18.3423678 },
    { lat: 48.7211539, lng: 18.3425908 },
    { lat: 48.7211607, lng: 18.3427523 },
    { lat: 48.7211841, lng: 18.3428278 },
    { lat: 48.7212775, lng: 18.342904 },
    { lat: 48.7216124, lng: 18.3430409 },
    { lat: 48.7217871, lng: 18.3430769 },
    { lat: 48.7217814, lng: 18.3430345 },
    { lat: 48.7216819, lng: 18.3425112 },
    { lat: 48.7220357, lng: 18.3422417 },
    { lat: 48.7220562, lng: 18.3422263 },
    { lat: 48.7221, lng: 18.3415604 },
    { lat: 48.7225739, lng: 18.3408775 },
    { lat: 48.7231126, lng: 18.3394252 },
    { lat: 48.7235989, lng: 18.3390047 },
    { lat: 48.7238552, lng: 18.3388075 },
    { lat: 48.7242977, lng: 18.3386112 },
    { lat: 48.7248587, lng: 18.3382943 },
    { lat: 48.7251986, lng: 18.3380798 },
    { lat: 48.725396, lng: 18.3380181 },
    { lat: 48.7258818, lng: 18.3379719 },
    { lat: 48.7266122, lng: 18.3379589 },
    { lat: 48.7268135, lng: 18.3377459 },
    { lat: 48.7268011, lng: 18.3377257 },
    { lat: 48.7265719, lng: 18.3374025 },
    { lat: 48.7262413, lng: 18.3371602 },
    { lat: 48.7259288, lng: 18.3368014 },
    { lat: 48.725877, lng: 18.3367438 },
    { lat: 48.7256459, lng: 18.3364853 },
    { lat: 48.725447, lng: 18.33618 },
    { lat: 48.7251851, lng: 18.3356745 },
    { lat: 48.7252012, lng: 18.3356613 },
    { lat: 48.7251366, lng: 18.335236 },
    { lat: 48.725122, lng: 18.3347556 },
    { lat: 48.7252362, lng: 18.3342992 },
    { lat: 48.7258458, lng: 18.332944 },
    { lat: 48.726497, lng: 18.3316464 },
    { lat: 48.7268916, lng: 18.3308017 },
    { lat: 48.7272537, lng: 18.3302973 },
    { lat: 48.7275029, lng: 18.3295553 },
    { lat: 48.7276152, lng: 18.3291661 },
    { lat: 48.7278622, lng: 18.3287434 },
    { lat: 48.7280099, lng: 18.3280822 },
    { lat: 48.7281747, lng: 18.3271639 },
    { lat: 48.728822, lng: 18.325998 },
    { lat: 48.7297206, lng: 18.3251053 },
    { lat: 48.7304558, lng: 18.3246571 },
    { lat: 48.7308523, lng: 18.3242464 },
    { lat: 48.7313583, lng: 18.3235754 },
    { lat: 48.731618, lng: 18.3233202 },
    { lat: 48.7319335, lng: 18.3231331 },
    { lat: 48.7320727, lng: 18.3229394 },
    { lat: 48.7320839, lng: 18.3229579 },
    { lat: 48.7321956, lng: 18.3223785 },
    { lat: 48.7322774, lng: 18.3217287 },
    { lat: 48.7320658, lng: 18.3210375 },
    { lat: 48.731993, lng: 18.3207266 },
    { lat: 48.731704, lng: 18.3202857 },
    { lat: 48.7315906, lng: 18.3202349 },
    { lat: 48.7314697, lng: 18.3186129 },
    { lat: 48.7310223, lng: 18.3169049 },
    { lat: 48.7306622, lng: 18.3155822 },
    { lat: 48.7302534, lng: 18.3141588 },
    { lat: 48.7296194, lng: 18.3133215 },
    { lat: 48.7294867, lng: 18.3129691 },
    { lat: 48.7293977, lng: 18.3124121 },
    { lat: 48.7290656, lng: 18.311769 },
    { lat: 48.7286662, lng: 18.3105712 },
    { lat: 48.728569, lng: 18.3100291 },
    { lat: 48.7284496, lng: 18.3096915 },
    { lat: 48.7281979, lng: 18.3093505 },
    { lat: 48.7276252, lng: 18.3082814 },
    { lat: 48.7272493, lng: 18.3071044 },
    { lat: 48.7270525, lng: 18.3063167 },
    { lat: 48.7270702, lng: 18.3053925 },
    { lat: 48.7270935, lng: 18.3044886 },
    { lat: 48.7269055, lng: 18.3034193 },
    { lat: 48.7264297, lng: 18.3025492 },
    { lat: 48.7262075, lng: 18.3013543 },
    { lat: 48.7261531, lng: 18.300712 },
    { lat: 48.7253859, lng: 18.2990395 },
    { lat: 48.7253632, lng: 18.2990569 },
    { lat: 48.7250889, lng: 18.2980995 },
    { lat: 48.7250549, lng: 18.2980377 },
    { lat: 48.7245124, lng: 18.2970438 },
    { lat: 48.7244998, lng: 18.2970225 },
    { lat: 48.7241244, lng: 18.2959903 },
    { lat: 48.7238634, lng: 18.2952274 },
    { lat: 48.7238378, lng: 18.2951556 },
    { lat: 48.7237092, lng: 18.2947783 },
    { lat: 48.7234545, lng: 18.2941257 },
    { lat: 48.7230546, lng: 18.2934996 },
    { lat: 48.7224821, lng: 18.2928946 },
  ],
  BánovcenadBebravou: [
    { lat: 48.7015299, lng: 18.2801056 },
    { lat: 48.7015491, lng: 18.2807796 },
    { lat: 48.7016269, lng: 18.281336 },
    { lat: 48.7017019, lng: 18.2817621 },
    { lat: 48.7017341, lng: 18.2820521 },
    { lat: 48.701746, lng: 18.2821609 },
    { lat: 48.7016558, lng: 18.2822028 },
    { lat: 48.7018045, lng: 18.2828543 },
    { lat: 48.7019715, lng: 18.2832102 },
    { lat: 48.7021484, lng: 18.2836049 },
    { lat: 48.7024183, lng: 18.2840042 },
    { lat: 48.7027678, lng: 18.2845363 },
    { lat: 48.7035564, lng: 18.2840378 },
    { lat: 48.7040565, lng: 18.2841823 },
    { lat: 48.7046506, lng: 18.2844111 },
    { lat: 48.7048441, lng: 18.2844606 },
    { lat: 48.7048877, lng: 18.2844725 },
    { lat: 48.7054962, lng: 18.2847576 },
    { lat: 48.7060037, lng: 18.2849708 },
    { lat: 48.7065153, lng: 18.2851381 },
    { lat: 48.7073993, lng: 18.2855862 },
    { lat: 48.7078218, lng: 18.2858328 },
    { lat: 48.708234, lng: 18.2860576 },
    { lat: 48.7086446, lng: 18.2863529 },
    { lat: 48.7091179, lng: 18.2865824 },
    { lat: 48.7095319, lng: 18.2868195 },
    { lat: 48.7096754, lng: 18.2869206 },
    { lat: 48.7097552, lng: 18.2876514 },
    { lat: 48.7098093, lng: 18.2881286 },
    { lat: 48.709868, lng: 18.2886069 },
    { lat: 48.7099608, lng: 18.2892277 },
    { lat: 48.71008, lng: 18.2901953 },
    { lat: 48.7101507, lng: 18.290764 },
    { lat: 48.7102344, lng: 18.2913242 },
    { lat: 48.7103034, lng: 18.2917863 },
    { lat: 48.7104794, lng: 18.2926211 },
    { lat: 48.7117109, lng: 18.29271 },
    { lat: 48.71308, lng: 18.2932144 },
    { lat: 48.7132977, lng: 18.2930946 },
    { lat: 48.7133011, lng: 18.2930932 },
    { lat: 48.7134629, lng: 18.293004 },
    { lat: 48.7134813, lng: 18.2930095 },
    { lat: 48.7138632, lng: 18.2931176 },
    { lat: 48.7139194, lng: 18.2931339 },
    { lat: 48.7139939, lng: 18.293155 },
    { lat: 48.7144962, lng: 18.293277 },
    { lat: 48.7146805, lng: 18.2933848 },
    { lat: 48.7146829, lng: 18.2933866 },
    { lat: 48.7147272, lng: 18.293412 },
    { lat: 48.7147763, lng: 18.2934414 },
    { lat: 48.7148393, lng: 18.293478 },
    { lat: 48.7151205, lng: 18.293643 },
    { lat: 48.715249, lng: 18.2937189 },
    { lat: 48.7153458, lng: 18.2937749 },
    { lat: 48.7154741, lng: 18.2938498 },
    { lat: 48.7155396, lng: 18.2938883 },
    { lat: 48.7156359, lng: 18.2938174 },
    { lat: 48.71578, lng: 18.2937126 },
    { lat: 48.7158711, lng: 18.2936467 },
    { lat: 48.7160687, lng: 18.2935295 },
    { lat: 48.7162302, lng: 18.2934335 },
    { lat: 48.7163907, lng: 18.2933386 },
    { lat: 48.7164682, lng: 18.2932927 },
    { lat: 48.7165403, lng: 18.2932498 },
    { lat: 48.7167312, lng: 18.2931364 },
    { lat: 48.7168808, lng: 18.2930474 },
    { lat: 48.7169681, lng: 18.292996 },
    { lat: 48.7171079, lng: 18.2929133 },
    { lat: 48.717241, lng: 18.2928342 },
    { lat: 48.7174292, lng: 18.2927702 },
    { lat: 48.7176938, lng: 18.2926802 },
    { lat: 48.7178797, lng: 18.2926168 },
    { lat: 48.7180568, lng: 18.2925567 },
    { lat: 48.7184576, lng: 18.2924201 },
    { lat: 48.7186158, lng: 18.2923664 },
    { lat: 48.7186705, lng: 18.292355 },
    { lat: 48.718955, lng: 18.2922971 },
    { lat: 48.7190364, lng: 18.2922807 },
    { lat: 48.7194234, lng: 18.292202 },
    { lat: 48.7194535, lng: 18.2921955 },
    { lat: 48.7201967, lng: 18.2921879 },
    { lat: 48.7202578, lng: 18.292232 },
    { lat: 48.7202614, lng: 18.2922346 },
    { lat: 48.7203013, lng: 18.2922632 },
    { lat: 48.7204754, lng: 18.2923871 },
    { lat: 48.720493, lng: 18.2923998 },
    { lat: 48.7205248, lng: 18.2924229 },
    { lat: 48.7206201, lng: 18.2924913 },
    { lat: 48.7206871, lng: 18.2925387 },
    { lat: 48.7207111, lng: 18.2925638 },
    { lat: 48.7207855, lng: 18.2926437 },
    { lat: 48.7208227, lng: 18.2926832 },
    { lat: 48.7208443, lng: 18.292706 },
    { lat: 48.7208924, lng: 18.2927565 },
    { lat: 48.720956, lng: 18.2928241 },
    { lat: 48.720968, lng: 18.2928372 },
    { lat: 48.721008, lng: 18.2928856 },
    { lat: 48.7210359, lng: 18.2929195 },
    { lat: 48.7210879, lng: 18.2929817 },
    { lat: 48.7211364, lng: 18.2930405 },
    { lat: 48.7211376, lng: 18.2930428 },
    { lat: 48.7211739, lng: 18.2930853 },
    { lat: 48.7212297, lng: 18.2931536 },
    { lat: 48.7214113, lng: 18.2933722 },
    { lat: 48.7214973, lng: 18.2934761 },
    { lat: 48.7215251, lng: 18.2935076 },
    { lat: 48.7216039, lng: 18.2936053 },
    { lat: 48.721674, lng: 18.2936269 },
    { lat: 48.7217268, lng: 18.2936435 },
    { lat: 48.7217888, lng: 18.2936617 },
    { lat: 48.7218275, lng: 18.2936278 },
    { lat: 48.7219457, lng: 18.2935235 },
    { lat: 48.7219918, lng: 18.2934828 },
    { lat: 48.7220488, lng: 18.2934326 },
    { lat: 48.7220843, lng: 18.2934016 },
    { lat: 48.7221725, lng: 18.293324 },
    { lat: 48.7222068, lng: 18.2932935 },
    { lat: 48.7222186, lng: 18.2932827 },
    { lat: 48.7222681, lng: 18.29324 },
    { lat: 48.7223218, lng: 18.2931516 },
    { lat: 48.7223249, lng: 18.2931474 },
    { lat: 48.7223704, lng: 18.293074 },
    { lat: 48.7224821, lng: 18.2928946 },
    { lat: 48.7225625, lng: 18.2927196 },
    { lat: 48.7225855, lng: 18.2926689 },
    { lat: 48.7226085, lng: 18.292619 },
    { lat: 48.7226407, lng: 18.292548 },
    { lat: 48.7231377, lng: 18.2917802 },
    { lat: 48.7237048, lng: 18.2906395 },
    { lat: 48.7242585, lng: 18.2894819 },
    { lat: 48.7243158, lng: 18.289376 },
    { lat: 48.7243565, lng: 18.2893004 },
    { lat: 48.7244342, lng: 18.2891565 },
    { lat: 48.7243895, lng: 18.2891038 },
    { lat: 48.7244733, lng: 18.2890111 },
    { lat: 48.7246587, lng: 18.2886398 },
    { lat: 48.7248816, lng: 18.2882935 },
    { lat: 48.7251865, lng: 18.2878788 },
    { lat: 48.7252746, lng: 18.2878018 },
    { lat: 48.7255613, lng: 18.2874053 },
    { lat: 48.7257822, lng: 18.2870824 },
    { lat: 48.7258694, lng: 18.2869492 },
    { lat: 48.7260251, lng: 18.2867119 },
    { lat: 48.7261099, lng: 18.2865739 },
    { lat: 48.726117, lng: 18.286561 },
    { lat: 48.7261521, lng: 18.2865035 },
    { lat: 48.7261625, lng: 18.2864868 },
    { lat: 48.726178, lng: 18.2864614 },
    { lat: 48.7261903, lng: 18.2864416 },
    { lat: 48.7262028, lng: 18.2864216 },
    { lat: 48.7262636, lng: 18.2863215 },
    { lat: 48.7263018, lng: 18.286259 },
    { lat: 48.7263224, lng: 18.2862241 },
    { lat: 48.7263339, lng: 18.2862068 },
    { lat: 48.7263431, lng: 18.2861911 },
    { lat: 48.726401, lng: 18.2861378 },
    { lat: 48.7264269, lng: 18.286097 },
    { lat: 48.7264529, lng: 18.2860582 },
    { lat: 48.7264726, lng: 18.2860267 },
    { lat: 48.7265099, lng: 18.2859695 },
    { lat: 48.7265421, lng: 18.2859205 },
    { lat: 48.726798, lng: 18.2855787 },
    { lat: 48.7269743, lng: 18.2853427 },
    { lat: 48.7269616, lng: 18.28528 },
    { lat: 48.726967, lng: 18.285196 },
    { lat: 48.7272854, lng: 18.2852805 },
    { lat: 48.727347, lng: 18.2852295 },
    { lat: 48.7276239, lng: 18.2850998 },
    { lat: 48.7283294, lng: 18.2847681 },
    { lat: 48.728335, lng: 18.2847876 },
    { lat: 48.7284806, lng: 18.2847481 },
    { lat: 48.7287715, lng: 18.2846039 },
    { lat: 48.7288805, lng: 18.2845769 },
    { lat: 48.7289341, lng: 18.2845471 },
    { lat: 48.7291319, lng: 18.2844748 },
    { lat: 48.7293253, lng: 18.2843827 },
    { lat: 48.7298359, lng: 18.2841964 },
    { lat: 48.7301152, lng: 18.2841044 },
    { lat: 48.7301174, lng: 18.2841045 },
    { lat: 48.7301298, lng: 18.284105 },
    { lat: 48.7303324, lng: 18.2840766 },
    { lat: 48.7304557, lng: 18.2840416 },
    { lat: 48.7305079, lng: 18.284027 },
    { lat: 48.7306361, lng: 18.284 },
    { lat: 48.7307742, lng: 18.2840086 },
    { lat: 48.7308493, lng: 18.2839795 },
    { lat: 48.7308744, lng: 18.2839627 },
    { lat: 48.730882, lng: 18.2839571 },
    { lat: 48.7308917, lng: 18.2839708 },
    { lat: 48.7308953, lng: 18.2839741 },
    { lat: 48.7309352, lng: 18.2839625 },
    { lat: 48.7309738, lng: 18.2839469 },
    { lat: 48.7311181, lng: 18.2838642 },
    { lat: 48.7311574, lng: 18.2838413 },
    { lat: 48.7312164, lng: 18.2838075 },
    { lat: 48.7313402, lng: 18.2837822 },
    { lat: 48.731348, lng: 18.2837806 },
    { lat: 48.7315109, lng: 18.2837472 },
    { lat: 48.7315477, lng: 18.2837399 },
    { lat: 48.7315723, lng: 18.2837349 },
    { lat: 48.7316582, lng: 18.2837173 },
    { lat: 48.7318358, lng: 18.2835678 },
    { lat: 48.7318691, lng: 18.2835391 },
    { lat: 48.7319047, lng: 18.2835097 },
    { lat: 48.7319629, lng: 18.28346 },
    { lat: 48.731993, lng: 18.2834351 },
    { lat: 48.7320901, lng: 18.2834354 },
    { lat: 48.732187, lng: 18.2834352 },
    { lat: 48.7322818, lng: 18.2834349 },
    { lat: 48.7323811, lng: 18.2834347 },
    { lat: 48.732512, lng: 18.2834343 },
    { lat: 48.7326157, lng: 18.2834341 },
    { lat: 48.7326631, lng: 18.283434 },
    { lat: 48.7327113, lng: 18.2834081 },
    { lat: 48.7328526, lng: 18.2833326 },
    { lat: 48.7329829, lng: 18.2832631 },
    { lat: 48.7331767, lng: 18.2831592 },
    { lat: 48.7332109, lng: 18.2831252 },
    { lat: 48.7333018, lng: 18.2830363 },
    { lat: 48.7333594, lng: 18.2829791 },
    { lat: 48.733383, lng: 18.2829555 },
    { lat: 48.7334705, lng: 18.2828691 },
    { lat: 48.7335658, lng: 18.2827979 },
    { lat: 48.7336316, lng: 18.2827629 },
    { lat: 48.73368, lng: 18.2827416 },
    { lat: 48.7337934, lng: 18.282693 },
    { lat: 48.7339916, lng: 18.2826061 },
    { lat: 48.7341151, lng: 18.2825151 },
    { lat: 48.734176, lng: 18.2824761 },
    { lat: 48.7342315, lng: 18.2824403 },
    { lat: 48.7342914, lng: 18.2824021 },
    { lat: 48.7343709, lng: 18.282351 },
    { lat: 48.7344471, lng: 18.2823018 },
    { lat: 48.7344862, lng: 18.2822764 },
    { lat: 48.7345276, lng: 18.2822505 },
    { lat: 48.7345679, lng: 18.2822243 },
    { lat: 48.7346071, lng: 18.2821989 },
    { lat: 48.7346474, lng: 18.2821731 },
    { lat: 48.734691, lng: 18.2821453 },
    { lat: 48.734741, lng: 18.2821132 },
    { lat: 48.73479, lng: 18.2820817 },
    { lat: 48.73484, lng: 18.2820496 },
    { lat: 48.7348901, lng: 18.2820174 },
    { lat: 48.7349598, lng: 18.2819726 },
    { lat: 48.7350317, lng: 18.2819266 },
    { lat: 48.7351395, lng: 18.2818578 },
    { lat: 48.7351666, lng: 18.2818383 },
    { lat: 48.7352675, lng: 18.2817677 },
    { lat: 48.7352903, lng: 18.2817515 },
    { lat: 48.7353651, lng: 18.2816991 },
    { lat: 48.7354401, lng: 18.2816468 },
    { lat: 48.7354922, lng: 18.2816106 },
    { lat: 48.735543, lng: 18.2815738 },
    { lat: 48.7356363, lng: 18.281507 },
    { lat: 48.7357837, lng: 18.2814014 },
    { lat: 48.7360544, lng: 18.2812195 },
    { lat: 48.7362848, lng: 18.2810647 },
    { lat: 48.7365132, lng: 18.2819664 },
    { lat: 48.736703, lng: 18.2825278 },
    { lat: 48.7367785, lng: 18.2827248 },
    { lat: 48.7368375, lng: 18.2826519 },
    { lat: 48.7370331, lng: 18.2824601 },
    { lat: 48.7373216, lng: 18.2822534 },
    { lat: 48.7376334, lng: 18.2820419 },
    { lat: 48.7380107, lng: 18.2818314 },
    { lat: 48.7381083, lng: 18.2817828 },
    { lat: 48.7383307, lng: 18.2816453 },
    { lat: 48.7385224, lng: 18.2815239 },
    { lat: 48.7389159, lng: 18.2813395 },
    { lat: 48.7391083, lng: 18.2812914 },
    { lat: 48.739428, lng: 18.2812179 },
    { lat: 48.7396686, lng: 18.2811628 },
    { lat: 48.7399911, lng: 18.2810996 },
    { lat: 48.7404435, lng: 18.2809415 },
    { lat: 48.7406153, lng: 18.2809066 },
    { lat: 48.7408962, lng: 18.2809049 },
    { lat: 48.7416493, lng: 18.2810533 },
    { lat: 48.7416538, lng: 18.2810928 },
    { lat: 48.7420586, lng: 18.2810088 },
    { lat: 48.742202, lng: 18.2809133 },
    { lat: 48.7423369, lng: 18.2808238 },
    { lat: 48.7424369, lng: 18.280757 },
    { lat: 48.7426544, lng: 18.2806129 },
    { lat: 48.742686, lng: 18.2805917 },
    { lat: 48.7428692, lng: 18.2805597 },
    { lat: 48.7431854, lng: 18.2806455 },
    { lat: 48.7433799, lng: 18.2806718 },
    { lat: 48.7436613, lng: 18.2805789 },
    { lat: 48.7438297, lng: 18.280426 },
    { lat: 48.7438436, lng: 18.2803913 },
    { lat: 48.7438685, lng: 18.2803345 },
    { lat: 48.7441748, lng: 18.2796085 },
    { lat: 48.744342, lng: 18.2792945 },
    { lat: 48.744621, lng: 18.2789015 },
    { lat: 48.7451929, lng: 18.2780666 },
    { lat: 48.7455055, lng: 18.2775118 },
    { lat: 48.7458537, lng: 18.2770057 },
    { lat: 48.7462487, lng: 18.2765109 },
    { lat: 48.7467715, lng: 18.2760996 },
    { lat: 48.7471808, lng: 18.2758374 },
    { lat: 48.7475917, lng: 18.2755815 },
    { lat: 48.7480184, lng: 18.2752679 },
    { lat: 48.7484191, lng: 18.2748737 },
    { lat: 48.7485624, lng: 18.2746956 },
    { lat: 48.7485772, lng: 18.2746766 },
    { lat: 48.7487029, lng: 18.2745285 },
    { lat: 48.748721, lng: 18.274507 },
    { lat: 48.7487077, lng: 18.2741742 },
    { lat: 48.7484018, lng: 18.2742324 },
    { lat: 48.7483069, lng: 18.2742506 },
    { lat: 48.7482528, lng: 18.2742503 },
    { lat: 48.7481128, lng: 18.2742503 },
    { lat: 48.7479752, lng: 18.2742499 },
    { lat: 48.7479539, lng: 18.2742116 },
    { lat: 48.7478618, lng: 18.2741807 },
    { lat: 48.747046, lng: 18.273903 },
    { lat: 48.7466796, lng: 18.2725175 },
    { lat: 48.7459381, lng: 18.2711461 },
    { lat: 48.7457007, lng: 18.271236 },
    { lat: 48.7457179, lng: 18.2707248 },
    { lat: 48.7457446, lng: 18.269945 },
    { lat: 48.7457273, lng: 18.2699183 },
    { lat: 48.7456047, lng: 18.2697269 },
    { lat: 48.7455785, lng: 18.2696631 },
    { lat: 48.745506, lng: 18.2697372 },
    { lat: 48.7454862, lng: 18.2697466 },
    { lat: 48.7454056, lng: 18.2697174 },
    { lat: 48.7453823, lng: 18.2697261 },
    { lat: 48.7453359, lng: 18.2697829 },
    { lat: 48.7452995, lng: 18.2697766 },
    { lat: 48.7452036, lng: 18.2696786 },
    { lat: 48.7451458, lng: 18.269712 },
    { lat: 48.7451491, lng: 18.2698094 },
    { lat: 48.7451141, lng: 18.2698309 },
    { lat: 48.7449907, lng: 18.2698042 },
    { lat: 48.744866, lng: 18.2697552 },
    { lat: 48.7446704, lng: 18.2697862 },
    { lat: 48.7446001, lng: 18.2698393 },
    { lat: 48.7444, lng: 18.269893 },
    { lat: 48.7443214, lng: 18.2698802 },
    { lat: 48.744213, lng: 18.2698572 },
    { lat: 48.744113, lng: 18.2699244 },
    { lat: 48.7440302, lng: 18.2699177 },
    { lat: 48.7440367, lng: 18.2698528 },
    { lat: 48.7441427, lng: 18.2691383 },
    { lat: 48.7441599, lng: 18.2690453 },
    { lat: 48.7442751, lng: 18.2684109 },
    { lat: 48.7443739, lng: 18.2679067 },
    { lat: 48.7444368, lng: 18.2675842 },
    { lat: 48.7444515, lng: 18.267366 },
    { lat: 48.7444236, lng: 18.2673318 },
    { lat: 48.7446426, lng: 18.2665802 },
    { lat: 48.7447515, lng: 18.2661158 },
    { lat: 48.7448984, lng: 18.2652465 },
    { lat: 48.7450833, lng: 18.2641531 },
    { lat: 48.7452248, lng: 18.2632893 },
    { lat: 48.7451706, lng: 18.2632883 },
    { lat: 48.7451854, lng: 18.2632129 },
    { lat: 48.7449531, lng: 18.263193 },
    { lat: 48.7447029, lng: 18.2631807 },
    { lat: 48.7444654, lng: 18.2632103 },
    { lat: 48.7442857, lng: 18.2632055 },
    { lat: 48.7441219, lng: 18.2631812 },
    { lat: 48.7439144, lng: 18.2631433 },
    { lat: 48.7436705, lng: 18.2631021 },
    { lat: 48.7434679, lng: 18.2630305 },
    { lat: 48.7432624, lng: 18.2629476 },
    { lat: 48.7430525, lng: 18.262829 },
    { lat: 48.7428863, lng: 18.2627257 },
    { lat: 48.7427444, lng: 18.2626106 },
    { lat: 48.7425126, lng: 18.2624029 },
    { lat: 48.7422001, lng: 18.2620873 },
    { lat: 48.742049, lng: 18.2619309 },
    { lat: 48.741802, lng: 18.2616144 },
    { lat: 48.7416505, lng: 18.2614132 },
    { lat: 48.7412732, lng: 18.26101 },
    { lat: 48.7408687, lng: 18.2606434 },
    { lat: 48.7404294, lng: 18.2601007 },
    { lat: 48.7399755, lng: 18.2595805 },
    { lat: 48.739714, lng: 18.2593467 },
    { lat: 48.7393195, lng: 18.2589776 },
    { lat: 48.738989, lng: 18.2586428 },
    { lat: 48.7387227, lng: 18.2583479 },
    { lat: 48.7384527, lng: 18.2580639 },
    { lat: 48.7382006, lng: 18.2578575 },
    { lat: 48.7381067, lng: 18.2577944 },
    { lat: 48.7379211, lng: 18.2577242 },
    { lat: 48.7379148, lng: 18.2576917 },
    { lat: 48.7378886, lng: 18.2575314 },
    { lat: 48.7378629, lng: 18.2573957 },
    { lat: 48.7379049, lng: 18.2572823 },
    { lat: 48.7379079, lng: 18.2572172 },
    { lat: 48.7379341, lng: 18.2566849 },
    { lat: 48.7379924, lng: 18.2559647 },
    { lat: 48.7380323, lng: 18.2555577 },
    { lat: 48.7380673, lng: 18.2552799 },
    { lat: 48.7381224, lng: 18.2545848 },
    { lat: 48.7381253, lng: 18.2543981 },
    { lat: 48.7381242, lng: 18.2541999 },
    { lat: 48.7380816, lng: 18.2530386 },
    { lat: 48.7380276, lng: 18.2513599 },
    { lat: 48.7380239, lng: 18.2513346 },
    { lat: 48.7381821, lng: 18.2512393 },
    { lat: 48.7386118, lng: 18.2509191 },
    { lat: 48.7388032, lng: 18.2508326 },
    { lat: 48.7388025, lng: 18.2508016 },
    { lat: 48.7393228, lng: 18.2506651 },
    { lat: 48.7404788, lng: 18.2504175 },
    { lat: 48.7408267, lng: 18.2504032 },
    { lat: 48.7411568, lng: 18.250278 },
    { lat: 48.7411737, lng: 18.2502565 },
    { lat: 48.7411863, lng: 18.2502394 },
    { lat: 48.7412156, lng: 18.2502006 },
    { lat: 48.7412471, lng: 18.2501589 },
    { lat: 48.7412597, lng: 18.2501419 },
    { lat: 48.7412755, lng: 18.2501216 },
    { lat: 48.7413856, lng: 18.2499764 },
    { lat: 48.7414009, lng: 18.2499666 },
    { lat: 48.741365, lng: 18.249672 },
    { lat: 48.7412, lng: 18.2493723 },
    { lat: 48.7410991, lng: 18.2491671 },
    { lat: 48.7407013, lng: 18.2489764 },
    { lat: 48.7407077, lng: 18.2489523 },
    { lat: 48.7406825, lng: 18.2487468 },
    { lat: 48.7406739, lng: 18.2486754 },
    { lat: 48.7406494, lng: 18.2484832 },
    { lat: 48.7405564, lng: 18.2483951 },
    { lat: 48.7404526, lng: 18.2482974 },
    { lat: 48.7404132, lng: 18.2482604 },
    { lat: 48.7403476, lng: 18.2481989 },
    { lat: 48.7402976, lng: 18.2481504 },
    { lat: 48.7402426, lng: 18.2480991 },
    { lat: 48.7401449, lng: 18.2480069 },
    { lat: 48.7401389, lng: 18.2480009 },
    { lat: 48.7400559, lng: 18.2479299 },
    { lat: 48.7399799, lng: 18.2478635 },
    { lat: 48.739949, lng: 18.2478362 },
    { lat: 48.7399395, lng: 18.2478281 },
    { lat: 48.7399087, lng: 18.247802 },
    { lat: 48.7398375, lng: 18.2477414 },
    { lat: 48.7398019, lng: 18.2477101 },
    { lat: 48.7397722, lng: 18.2476845 },
    { lat: 48.7397705, lng: 18.2476757 },
    { lat: 48.7397563, lng: 18.2476047 },
    { lat: 48.7397467, lng: 18.2475544 },
    { lat: 48.7397369, lng: 18.2475003 },
    { lat: 48.7397244, lng: 18.2474396 },
    { lat: 48.7397262, lng: 18.2473741 },
    { lat: 48.7397271, lng: 18.2473087 },
    { lat: 48.7397294, lng: 18.2471721 },
    { lat: 48.7397285, lng: 18.2470974 },
    { lat: 48.7397285, lng: 18.2470184 },
    { lat: 48.7397346, lng: 18.2468675 },
    { lat: 48.7397405, lng: 18.2467731 },
    { lat: 48.7397529, lng: 18.2465555 },
    { lat: 48.7397594, lng: 18.2464335 },
    { lat: 48.7397577, lng: 18.2463819 },
    { lat: 48.7397589, lng: 18.2463637 },
    { lat: 48.739764, lng: 18.2462756 },
    { lat: 48.7397651, lng: 18.2461757 },
    { lat: 48.7397674, lng: 18.2460778 },
    { lat: 48.7397681, lng: 18.2460323 },
    { lat: 48.73977, lng: 18.2459856 },
    { lat: 48.739774, lng: 18.2457983 },
    { lat: 48.7397736, lng: 18.2457728 },
    { lat: 48.7397782, lng: 18.2456753 },
    { lat: 48.7397858, lng: 18.245531 },
    { lat: 48.7397926, lng: 18.2453737 },
    { lat: 48.7397964, lng: 18.2453203 },
    { lat: 48.7398079, lng: 18.2451474 },
    { lat: 48.7398149, lng: 18.2450314 },
    { lat: 48.7398227, lng: 18.2449122 },
    { lat: 48.7398376, lng: 18.2446995 },
    { lat: 48.7398442, lng: 18.2446168 },
    { lat: 48.7398512, lng: 18.2445415 },
    { lat: 48.7398582, lng: 18.244467 },
    { lat: 48.7398691, lng: 18.2443422 },
    { lat: 48.7401598, lng: 18.2442556 },
    { lat: 48.740242, lng: 18.2442313 },
    { lat: 48.7408664, lng: 18.2440427 },
    { lat: 48.7409628, lng: 18.2439317 },
    { lat: 48.7410747, lng: 18.2437598 },
    { lat: 48.7411763, lng: 18.2435613 },
    { lat: 48.7413435, lng: 18.2434853 },
    { lat: 48.7414485, lng: 18.2435258 },
    { lat: 48.7415401, lng: 18.2436298 },
    { lat: 48.7418794, lng: 18.2437625 },
    { lat: 48.7418968, lng: 18.2437688 },
    { lat: 48.7419634, lng: 18.2437515 },
    { lat: 48.7422047, lng: 18.2436889 },
    { lat: 48.74227, lng: 18.2438626 },
    { lat: 48.7423508, lng: 18.2439135 },
    { lat: 48.7423715, lng: 18.243923 },
    { lat: 48.7425247, lng: 18.243997 },
    { lat: 48.7426662, lng: 18.2440225 },
    { lat: 48.742689, lng: 18.2440267 },
    { lat: 48.7427254, lng: 18.244033 },
    { lat: 48.742865, lng: 18.2439299 },
    { lat: 48.7429139, lng: 18.2438564 },
    { lat: 48.7429452, lng: 18.2437329 },
    { lat: 48.7431379, lng: 18.2435905 },
    { lat: 48.7432017, lng: 18.2436001 },
    { lat: 48.7432779, lng: 18.2436107 },
    { lat: 48.7434849, lng: 18.2436582 },
    { lat: 48.7435245, lng: 18.2436616 },
    { lat: 48.7435936, lng: 18.2436667 },
    { lat: 48.7436276, lng: 18.2436691 },
    { lat: 48.7437648, lng: 18.2436209 },
    { lat: 48.7440196, lng: 18.2434779 },
    { lat: 48.744268, lng: 18.2432059 },
    { lat: 48.7444446, lng: 18.242958 },
    { lat: 48.7445916, lng: 18.2426886 },
    { lat: 48.7445928, lng: 18.2425711 },
    { lat: 48.744593, lng: 18.2425527 },
    { lat: 48.7445923, lng: 18.2424618 },
    { lat: 48.744593, lng: 18.2424358 },
    { lat: 48.7446067, lng: 18.2423589 },
    { lat: 48.7446235, lng: 18.242317 },
    { lat: 48.7446434, lng: 18.2422701 },
    { lat: 48.7446493, lng: 18.2422549 },
    { lat: 48.7446761, lng: 18.2421902 },
    { lat: 48.7446824, lng: 18.242184 },
    { lat: 48.7446888, lng: 18.2421768 },
    { lat: 48.7447166, lng: 18.2421481 },
    { lat: 48.7447261, lng: 18.2421383 },
    { lat: 48.7447411, lng: 18.2421235 },
    { lat: 48.7447847, lng: 18.2420781 },
    { lat: 48.7450935, lng: 18.2418334 },
    { lat: 48.7451391, lng: 18.2415272 },
    { lat: 48.7453296, lng: 18.2410895 },
    { lat: 48.7454346, lng: 18.2409503 },
    { lat: 48.7454876, lng: 18.2409126 },
    { lat: 48.7455485, lng: 18.2408711 },
    { lat: 48.745632, lng: 18.2408119 },
    { lat: 48.745806, lng: 18.2410141 },
    { lat: 48.7459039, lng: 18.2409103 },
    { lat: 48.7459177, lng: 18.2408958 },
    { lat: 48.7460657, lng: 18.2407399 },
    { lat: 48.7461157, lng: 18.2406869 },
    { lat: 48.7461317, lng: 18.2406698 },
    { lat: 48.7461477, lng: 18.2406537 },
    { lat: 48.7461626, lng: 18.2406377 },
    { lat: 48.746186, lng: 18.2406124 },
    { lat: 48.7462296, lng: 18.2405665 },
    { lat: 48.7463314, lng: 18.2405316 },
    { lat: 48.7464288, lng: 18.240498 },
    { lat: 48.7466046, lng: 18.2404371 },
    { lat: 48.7467153, lng: 18.2403987 },
    { lat: 48.7464771, lng: 18.2393894 },
    { lat: 48.7463127, lng: 18.238526 },
    { lat: 48.7468247, lng: 18.2383622 },
    { lat: 48.7470162, lng: 18.2382974 },
    { lat: 48.7473344, lng: 18.2381599 },
    { lat: 48.7473653, lng: 18.2381494 },
    { lat: 48.7473886, lng: 18.2381412 },
    { lat: 48.7474671, lng: 18.2381139 },
    { lat: 48.7475767, lng: 18.2380765 },
    { lat: 48.7476719, lng: 18.2380437 },
    { lat: 48.7476795, lng: 18.2380384 },
    { lat: 48.7477512, lng: 18.2379894 },
    { lat: 48.7478229, lng: 18.2379407 },
    { lat: 48.7478511, lng: 18.2379209 },
    { lat: 48.7478793, lng: 18.2379022 },
    { lat: 48.747757, lng: 18.2365917 },
    { lat: 48.7474226, lng: 18.2366038 },
    { lat: 48.747393, lng: 18.2362442 },
    { lat: 48.7473855, lng: 18.2361522 },
    { lat: 48.7473709, lng: 18.2359753 },
    { lat: 48.7473621, lng: 18.2358617 },
    { lat: 48.7473555, lng: 18.235785 },
    { lat: 48.7473413, lng: 18.2356163 },
    { lat: 48.7473394, lng: 18.235404 },
    { lat: 48.7473407, lng: 18.2353876 },
    { lat: 48.7473891, lng: 18.2349125 },
    { lat: 48.7474196, lng: 18.2346176 },
    { lat: 48.747479, lng: 18.234215 },
    { lat: 48.7474835, lng: 18.2341763 },
    { lat: 48.7475425, lng: 18.2339651 },
    { lat: 48.747633, lng: 18.2334951 },
    { lat: 48.7481898, lng: 18.2334645 },
    { lat: 48.7482652, lng: 18.2334604 },
    { lat: 48.7483697, lng: 18.2334547 },
    { lat: 48.7491177, lng: 18.2338073 },
    { lat: 48.750041, lng: 18.2338901 },
    { lat: 48.7515236, lng: 18.2341062 },
    { lat: 48.7523265, lng: 18.2342937 },
    { lat: 48.7525642, lng: 18.2343272 },
    { lat: 48.7530431, lng: 18.2343951 },
    { lat: 48.7530439, lng: 18.2339743 },
    { lat: 48.753045, lng: 18.2338953 },
    { lat: 48.7528465, lng: 18.2337187 },
    { lat: 48.7528221, lng: 18.2334111 },
    { lat: 48.7526905, lng: 18.233339 },
    { lat: 48.7523335, lng: 18.2328006 },
    { lat: 48.752074, lng: 18.2328979 },
    { lat: 48.7520298, lng: 18.2329147 },
    { lat: 48.7519336, lng: 18.2329502 },
    { lat: 48.7516279, lng: 18.2326941 },
    { lat: 48.7516019, lng: 18.2326335 },
    { lat: 48.7515133, lng: 18.2324266 },
    { lat: 48.751274, lng: 18.2323855 },
    { lat: 48.7510941, lng: 18.2322998 },
    { lat: 48.7509908, lng: 18.2320702 },
    { lat: 48.7509276, lng: 18.2319322 },
    { lat: 48.7508713, lng: 18.2317949 },
    { lat: 48.7507671, lng: 18.231948 },
    { lat: 48.7507578, lng: 18.2319625 },
    { lat: 48.7505607, lng: 18.2320871 },
    { lat: 48.7502082, lng: 18.2320799 },
    { lat: 48.7499573, lng: 18.2322293 },
    { lat: 48.7499016, lng: 18.2322622 },
    { lat: 48.7498525, lng: 18.2322332 },
    { lat: 48.7497042, lng: 18.2321451 },
    { lat: 48.749658, lng: 18.231989 },
    { lat: 48.7496207, lng: 18.2319099 },
    { lat: 48.7495579, lng: 18.2317769 },
    { lat: 48.749452, lng: 18.2318606 },
    { lat: 48.7492868, lng: 18.2319898 },
    { lat: 48.7491608, lng: 18.2320149 },
    { lat: 48.7490685, lng: 18.2317817 },
    { lat: 48.7490432, lng: 18.2317158 },
    { lat: 48.7488568, lng: 18.2315525 },
    { lat: 48.7487675, lng: 18.2317092 },
    { lat: 48.7486693, lng: 18.2318471 },
    { lat: 48.7486536, lng: 18.2318678 },
    { lat: 48.7486443, lng: 18.2318822 },
    { lat: 48.7484789, lng: 18.2319688 },
    { lat: 48.7484084, lng: 18.232178 },
    { lat: 48.7484017, lng: 18.2321982 },
    { lat: 48.7483102, lng: 18.2322366 },
    { lat: 48.7482697, lng: 18.232218 },
    { lat: 48.7482245, lng: 18.2321971 },
    { lat: 48.7482337, lng: 18.2319445 },
    { lat: 48.748235, lng: 18.2319077 },
    { lat: 48.7481263, lng: 18.2317819 },
    { lat: 48.7480491, lng: 18.2318346 },
    { lat: 48.7479835, lng: 18.2319483 },
    { lat: 48.7478546, lng: 18.2320234 },
    { lat: 48.7477255, lng: 18.2319171 },
    { lat: 48.7476582, lng: 18.2319038 },
    { lat: 48.747579, lng: 18.2320173 },
    { lat: 48.7475291, lng: 18.2319155 },
    { lat: 48.747533, lng: 18.2318853 },
    { lat: 48.7476588, lng: 18.2309866 },
    { lat: 48.7476753, lng: 18.230862 },
    { lat: 48.7476948, lng: 18.2307286 },
    { lat: 48.7477179, lng: 18.23056 },
    { lat: 48.7477649, lng: 18.2302181 },
    { lat: 48.7478136, lng: 18.2298667 },
    { lat: 48.7478386, lng: 18.2296918 },
    { lat: 48.7478871, lng: 18.2293379 },
    { lat: 48.7479525, lng: 18.2288638 },
    { lat: 48.7480021, lng: 18.2285073 },
    { lat: 48.7480376, lng: 18.2282617 },
    { lat: 48.7481398, lng: 18.2275418 },
    { lat: 48.7482275, lng: 18.2269253 },
    { lat: 48.7482629, lng: 18.2266575 },
    { lat: 48.7483551, lng: 18.2263354 },
    { lat: 48.7485051, lng: 18.2259407 },
    { lat: 48.7487847, lng: 18.2260364 },
    { lat: 48.7488156, lng: 18.225805 },
    { lat: 48.7488413, lng: 18.225605 },
    { lat: 48.7488904, lng: 18.2253986 },
    { lat: 48.7490035, lng: 18.2251275 },
    { lat: 48.7490364, lng: 18.22505 },
    { lat: 48.7490531, lng: 18.2250087 },
    { lat: 48.749068, lng: 18.2249735 },
    { lat: 48.7490839, lng: 18.2249351 },
    { lat: 48.7491854, lng: 18.2247166 },
    { lat: 48.7492416, lng: 18.2245962 },
    { lat: 48.749436, lng: 18.2244228 },
    { lat: 48.7496314, lng: 18.2242491 },
    { lat: 48.7497538, lng: 18.2241405 },
    { lat: 48.749834, lng: 18.2240826 },
    { lat: 48.7499111, lng: 18.2239694 },
    { lat: 48.7499443, lng: 18.223921 },
    { lat: 48.7499749, lng: 18.2238428 },
    { lat: 48.7499856, lng: 18.2236759 },
    { lat: 48.7499608, lng: 18.2234982 },
    { lat: 48.7499664, lng: 18.2234592 },
    { lat: 48.7499997, lng: 18.2232314 },
    { lat: 48.7500886, lng: 18.2230703 },
    { lat: 48.7502982, lng: 18.2228111 },
    { lat: 48.7505871, lng: 18.2225533 },
    { lat: 48.7507312, lng: 18.2224115 },
    { lat: 48.7499622, lng: 18.2216916 },
    { lat: 48.7499884, lng: 18.221578 },
    { lat: 48.749882, lng: 18.2214341 },
    { lat: 48.7498772, lng: 18.2214279 },
    { lat: 48.7498697, lng: 18.2214173 },
    { lat: 48.7497487, lng: 18.2212554 },
    { lat: 48.7497353, lng: 18.2212379 },
    { lat: 48.7493699, lng: 18.2207469 },
    { lat: 48.7498217, lng: 18.2201857 },
    { lat: 48.7502332, lng: 18.2196873 },
    { lat: 48.7502157, lng: 18.2196563 },
    { lat: 48.7501719, lng: 18.2195802 },
    { lat: 48.7501506, lng: 18.2195433 },
    { lat: 48.749462, lng: 18.218674 },
    { lat: 48.7483035, lng: 18.2172049 },
    { lat: 48.748673, lng: 18.2163648 },
    { lat: 48.7487447, lng: 18.2161995 },
    { lat: 48.7488157, lng: 18.2160389 },
    { lat: 48.7492347, lng: 18.215006 },
    { lat: 48.7496655, lng: 18.2142914 },
    { lat: 48.750513, lng: 18.2128975 },
    { lat: 48.7504909, lng: 18.2128659 },
    { lat: 48.750438, lng: 18.2127897 },
    { lat: 48.7504184, lng: 18.2127622 },
    { lat: 48.7503938, lng: 18.2127275 },
    { lat: 48.750009, lng: 18.2121957 },
    { lat: 48.7496657, lng: 18.2117378 },
    { lat: 48.7497274, lng: 18.2116337 },
    { lat: 48.7497901, lng: 18.2115274 },
    { lat: 48.7498498, lng: 18.2114258 },
    { lat: 48.74994, lng: 18.2112885 },
    { lat: 48.7501144, lng: 18.2110834 },
    { lat: 48.7502382, lng: 18.2109392 },
    { lat: 48.7504224, lng: 18.2107078 },
    { lat: 48.7505161, lng: 18.2105893 },
    { lat: 48.7506103, lng: 18.2104432 },
    { lat: 48.7508257, lng: 18.210107 },
    { lat: 48.7508745, lng: 18.2100136 },
    { lat: 48.7509692, lng: 18.2098343 },
    { lat: 48.751018, lng: 18.209741 },
    { lat: 48.7507281, lng: 18.2093895 },
    { lat: 48.7505975, lng: 18.2092373 },
    { lat: 48.7504205, lng: 18.2090233 },
    { lat: 48.7502634, lng: 18.2088217 },
    { lat: 48.750183, lng: 18.2087182 },
    { lat: 48.749946, lng: 18.2090536 },
    { lat: 48.7496527, lng: 18.2094854 },
    { lat: 48.7492736, lng: 18.2091133 },
    { lat: 48.7490212, lng: 18.2089229 },
    { lat: 48.7488869, lng: 18.208824 },
    { lat: 48.7488365, lng: 18.208753 },
    { lat: 48.7487901, lng: 18.2085932 },
    { lat: 48.748733, lng: 18.2081677 },
    { lat: 48.7486992, lng: 18.207915 },
    { lat: 48.748722, lng: 18.2075646 },
    { lat: 48.7486728, lng: 18.207395 },
    { lat: 48.7485649, lng: 18.2071288 },
    { lat: 48.7487615, lng: 18.2069546 },
    { lat: 48.7492392, lng: 18.2067643 },
    { lat: 48.7492906, lng: 18.2066595 },
    { lat: 48.7493494, lng: 18.2065429 },
    { lat: 48.7492486, lng: 18.2061628 },
    { lat: 48.7492447, lng: 18.2056826 },
    { lat: 48.7492962, lng: 18.2052049 },
    { lat: 48.7493192, lng: 18.2048576 },
    { lat: 48.7493303, lng: 18.2048157 },
    { lat: 48.7493944, lng: 18.2045547 },
    { lat: 48.7494017, lng: 18.2045263 },
    { lat: 48.7494415, lng: 18.2044145 },
    { lat: 48.7494454, lng: 18.2044046 },
    { lat: 48.7495284, lng: 18.204177 },
    { lat: 48.7495098, lng: 18.2041474 },
    { lat: 48.7492839, lng: 18.2037909 },
    { lat: 48.7493411, lng: 18.2032949 },
    { lat: 48.7494572, lng: 18.202959 },
    { lat: 48.7495397, lng: 18.202784 },
    { lat: 48.7496151, lng: 18.2026225 },
    { lat: 48.7496889, lng: 18.2025318 },
    { lat: 48.749807, lng: 18.2024092 },
    { lat: 48.7499414, lng: 18.2023111 },
    { lat: 48.7502417, lng: 18.2021776 },
    { lat: 48.7504107, lng: 18.2021128 },
    { lat: 48.7506292, lng: 18.2020287 },
    { lat: 48.750786, lng: 18.2019491 },
    { lat: 48.750944, lng: 18.2018504 },
    { lat: 48.7510744, lng: 18.2017445 },
    { lat: 48.7511902, lng: 18.2016371 },
    { lat: 48.7515939, lng: 18.2012414 },
    { lat: 48.7515337, lng: 18.201176 },
    { lat: 48.7514904, lng: 18.2011288 },
    { lat: 48.7512305, lng: 18.2008472 },
    { lat: 48.7508646, lng: 18.2007836 },
    { lat: 48.7504008, lng: 18.2006048 },
    { lat: 48.7501269, lng: 18.2005528 },
    { lat: 48.7498711, lng: 18.2006175 },
    { lat: 48.749509, lng: 18.2005028 },
    { lat: 48.7493803, lng: 18.2004621 },
    { lat: 48.7493653, lng: 18.2004562 },
    { lat: 48.7490586, lng: 18.2003431 },
    { lat: 48.7490432, lng: 18.2003302 },
    { lat: 48.7488413, lng: 18.2001539 },
    { lat: 48.7486363, lng: 18.1997497 },
    { lat: 48.7486495, lng: 18.1996839 },
    { lat: 48.7485783, lng: 18.1994451 },
    { lat: 48.7484983, lng: 18.1992526 },
    { lat: 48.7484308, lng: 18.1991381 },
    { lat: 48.7482223, lng: 18.199023 },
    { lat: 48.748098, lng: 18.1989426 },
    { lat: 48.747975, lng: 18.198864 },
    { lat: 48.7479486, lng: 18.1988371 },
    { lat: 48.7479521, lng: 18.1989968 },
    { lat: 48.7479545, lng: 18.1991556 },
    { lat: 48.7479204, lng: 18.1993861 },
    { lat: 48.7478717, lng: 18.1995593 },
    { lat: 48.7478033, lng: 18.1997431 },
    { lat: 48.7477765, lng: 18.1998477 },
    { lat: 48.7476986, lng: 18.2001629 },
    { lat: 48.7476772, lng: 18.2003774 },
    { lat: 48.7476058, lng: 18.200432 },
    { lat: 48.7474934, lng: 18.2005167 },
    { lat: 48.7472874, lng: 18.2014879 },
    { lat: 48.7471884, lng: 18.2019048 },
    { lat: 48.7470011, lng: 18.2028086 },
    { lat: 48.7470609, lng: 18.2028106 },
    { lat: 48.747052, lng: 18.2028498 },
    { lat: 48.7467047, lng: 18.2044651 },
    { lat: 48.7466512, lng: 18.2047128 },
    { lat: 48.7465832, lng: 18.2047864 },
    { lat: 48.746332, lng: 18.2050101 },
    { lat: 48.7461449, lng: 18.2058383 },
    { lat: 48.7461097, lng: 18.2058345 },
    { lat: 48.7461007, lng: 18.2058752 },
    { lat: 48.7459772, lng: 18.2064549 },
    { lat: 48.7459669, lng: 18.2064531 },
    { lat: 48.7451596, lng: 18.2062873 },
    { lat: 48.7448204, lng: 18.2062175 },
    { lat: 48.7444859, lng: 18.2061484 },
    { lat: 48.7443448, lng: 18.2061285 },
    { lat: 48.7443345, lng: 18.2061264 },
    { lat: 48.7443129, lng: 18.2061225 },
    { lat: 48.7443379, lng: 18.2062642 },
    { lat: 48.7443404, lng: 18.2063069 },
    { lat: 48.7443347, lng: 18.2063461 },
    { lat: 48.7443234, lng: 18.2063835 },
    { lat: 48.744295, lng: 18.2064391 },
    { lat: 48.7441058, lng: 18.2067593 },
    { lat: 48.744044, lng: 18.2068632 },
    { lat: 48.7437776, lng: 18.2070578 },
    { lat: 48.7436639, lng: 18.2071614 },
    { lat: 48.7436309, lng: 18.207431 },
    { lat: 48.7436853, lng: 18.2077122 },
    { lat: 48.7437712, lng: 18.2079687 },
    { lat: 48.7438935, lng: 18.2080557 },
    { lat: 48.7440253, lng: 18.2081099 },
    { lat: 48.7441073, lng: 18.2082993 },
    { lat: 48.744016, lng: 18.2085578 },
    { lat: 48.7439826, lng: 18.2088202 },
    { lat: 48.7440837, lng: 18.2093042 },
    { lat: 48.7440923, lng: 18.2096714 },
    { lat: 48.7440869, lng: 18.2097134 },
    { lat: 48.7440577, lng: 18.2096963 },
    { lat: 48.744046, lng: 18.2096889 },
    { lat: 48.7440437, lng: 18.2096879 },
    { lat: 48.7440004, lng: 18.2096616 },
    { lat: 48.7437695, lng: 18.2094327 },
    { lat: 48.7434085, lng: 18.2091789 },
    { lat: 48.7433346, lng: 18.2091297 },
    { lat: 48.743053, lng: 18.2089422 },
    { lat: 48.7428043, lng: 18.2087769 },
    { lat: 48.7427214, lng: 18.208729 },
    { lat: 48.7425732, lng: 18.2086441 },
    { lat: 48.7417981, lng: 18.2086062 },
    { lat: 48.7417973, lng: 18.2085927 },
    { lat: 48.7417929, lng: 18.208516 },
    { lat: 48.7417899, lng: 18.2084426 },
    { lat: 48.7417842, lng: 18.2083261 },
    { lat: 48.7413378, lng: 18.2079589 },
    { lat: 48.7412896, lng: 18.2079245 },
    { lat: 48.7405309, lng: 18.2073684 },
    { lat: 48.7397342, lng: 18.2063654 },
    { lat: 48.7394256, lng: 18.2053533 },
    { lat: 48.7393253, lng: 18.205316 },
    { lat: 48.7392791, lng: 18.2052987 },
    { lat: 48.7392358, lng: 18.2053099 },
    { lat: 48.7391608, lng: 18.2053219 },
    { lat: 48.7391027, lng: 18.2053308 },
    { lat: 48.7386769, lng: 18.2053445 },
    { lat: 48.7386389, lng: 18.2053316 },
    { lat: 48.7383063, lng: 18.2052178 },
    { lat: 48.7381647, lng: 18.205169 },
    { lat: 48.7378209, lng: 18.2049984 },
    { lat: 48.7374042, lng: 18.2047972 },
    { lat: 48.7368997, lng: 18.2043027 },
    { lat: 48.7368743, lng: 18.204275 },
    { lat: 48.7367898, lng: 18.2041981 },
    { lat: 48.7367404, lng: 18.2042016 },
    { lat: 48.7367182, lng: 18.2042082 },
    { lat: 48.7366404, lng: 18.2035421 },
    { lat: 48.7366375, lng: 18.2034914 },
    { lat: 48.7366284, lng: 18.2033935 },
    { lat: 48.736517, lng: 18.2019044 },
    { lat: 48.7365277, lng: 18.2011489 },
    { lat: 48.7365156, lng: 18.2007612 },
    { lat: 48.7364713, lng: 18.2004825 },
    { lat: 48.7363098, lng: 18.199891 },
    { lat: 48.7361698, lng: 18.1994386 },
    { lat: 48.7361648, lng: 18.1994113 },
    { lat: 48.7361213, lng: 18.1994013 },
    { lat: 48.7350652, lng: 18.1992059 },
    { lat: 48.7345863, lng: 18.1991182 },
    { lat: 48.7344114, lng: 18.1990806 },
    { lat: 48.7344074, lng: 18.1991072 },
    { lat: 48.7343117, lng: 18.1990919 },
    { lat: 48.73383, lng: 18.1990163 },
    { lat: 48.7308225, lng: 18.1985417 },
    { lat: 48.7307738, lng: 18.1985368 },
    { lat: 48.7308045, lng: 18.1985827 },
    { lat: 48.7306196, lng: 18.1988193 },
    { lat: 48.7304671, lng: 18.1990899 },
    { lat: 48.7304408, lng: 18.1993217 },
    { lat: 48.7302669, lng: 18.1994168 },
    { lat: 48.7294091, lng: 18.2018078 },
    { lat: 48.7293781, lng: 18.201898 },
    { lat: 48.7296055, lng: 18.2020651 },
    { lat: 48.7304632, lng: 18.2026937 },
    { lat: 48.7307966, lng: 18.202938 },
    { lat: 48.7308013, lng: 18.203001 },
    { lat: 48.7308193, lng: 18.203275 },
    { lat: 48.7308419, lng: 18.2036091 },
    { lat: 48.7308402, lng: 18.2041494 },
    { lat: 48.7309637, lng: 18.2045491 },
    { lat: 48.7306656, lng: 18.204879 },
    { lat: 48.7304515, lng: 18.2050422 },
    { lat: 48.7303727, lng: 18.2050842 },
    { lat: 48.7298657, lng: 18.2057848 },
    { lat: 48.7294157, lng: 18.2065748 },
    { lat: 48.7292653, lng: 18.2069037 },
    { lat: 48.7289889, lng: 18.2074156 },
    { lat: 48.7288067, lng: 18.2078005 },
    { lat: 48.7287322, lng: 18.207917 },
    { lat: 48.7284196, lng: 18.2082505 },
    { lat: 48.7283305, lng: 18.2082254 },
    { lat: 48.7282534, lng: 18.2082641 },
    { lat: 48.7282294, lng: 18.2083373 },
    { lat: 48.7281868, lng: 18.2084608 },
    { lat: 48.7279254, lng: 18.2088018 },
    { lat: 48.7278164, lng: 18.2090068 },
    { lat: 48.7277215, lng: 18.2093374 },
    { lat: 48.7276213, lng: 18.2099508 },
    { lat: 48.7275736, lng: 18.2103566 },
    { lat: 48.7275622, lng: 18.2104737 },
    { lat: 48.7274928, lng: 18.2109557 },
    { lat: 48.7274533, lng: 18.2117424 },
    { lat: 48.7274567, lng: 18.2120357 },
    { lat: 48.7274721, lng: 18.2122656 },
    { lat: 48.7274759, lng: 18.2123318 },
    { lat: 48.727472, lng: 18.2124009 },
    { lat: 48.7274554, lng: 18.2125062 },
    { lat: 48.727187, lng: 18.2128229 },
    { lat: 48.7271047, lng: 18.2129019 },
    { lat: 48.7270169, lng: 18.2129858 },
    { lat: 48.726987, lng: 18.2130151 },
    { lat: 48.7269612, lng: 18.2130371 },
    { lat: 48.7268409, lng: 18.2131436 },
    { lat: 48.7268132, lng: 18.2131709 },
    { lat: 48.7267852, lng: 18.2131949 },
    { lat: 48.7265834, lng: 18.2133761 },
    { lat: 48.7265277, lng: 18.2134458 },
    { lat: 48.7264902, lng: 18.2134806 },
    { lat: 48.7264503, lng: 18.213514 },
    { lat: 48.7263741, lng: 18.213581 },
    { lat: 48.7262936, lng: 18.2136529 },
    { lat: 48.726254, lng: 18.2136902 },
    { lat: 48.7262154, lng: 18.2137271 },
    { lat: 48.7261507, lng: 18.2137785 },
    { lat: 48.7256929, lng: 18.2140988 },
    { lat: 48.7254054, lng: 18.2143379 },
    { lat: 48.7253419, lng: 18.2143938 },
    { lat: 48.724609, lng: 18.215072 },
    { lat: 48.724151, lng: 18.2154859 },
    { lat: 48.72379, lng: 18.2157237 },
    { lat: 48.7233846, lng: 18.2158958 },
    { lat: 48.7228565, lng: 18.2160494 },
    { lat: 48.7226087, lng: 18.216113 },
    { lat: 48.7223863, lng: 18.2161299 },
    { lat: 48.7221805, lng: 18.2160926 },
    { lat: 48.721981, lng: 18.2161135 },
    { lat: 48.7217842, lng: 18.2161456 },
    { lat: 48.7215783, lng: 18.216255 },
    { lat: 48.7211995, lng: 18.2164893 },
    { lat: 48.720938, lng: 18.2166923 },
    { lat: 48.7208133, lng: 18.2168646 },
    { lat: 48.7207437, lng: 18.2168525 },
    { lat: 48.7206195, lng: 18.2168303 },
    { lat: 48.7201599, lng: 18.2168244 },
    { lat: 48.7197672, lng: 18.2168241 },
    { lat: 48.7196285, lng: 18.216779 },
    { lat: 48.7191236, lng: 18.2168171 },
    { lat: 48.7189099, lng: 18.2168313 },
    { lat: 48.718698, lng: 18.2168527 },
    { lat: 48.718361, lng: 18.2169379 },
    { lat: 48.7179831, lng: 18.2171486 },
    { lat: 48.7178048, lng: 18.2174124 },
    { lat: 48.7177887, lng: 18.2174278 },
    { lat: 48.7177659, lng: 18.2174425 },
    { lat: 48.7174467, lng: 18.2177981 },
    { lat: 48.7173201, lng: 18.217933 },
    { lat: 48.7170946, lng: 18.2181708 },
    { lat: 48.7167916, lng: 18.2184374 },
    { lat: 48.7165481, lng: 18.2186385 },
    { lat: 48.7160587, lng: 18.2189976 },
    { lat: 48.715649, lng: 18.2192721 },
    { lat: 48.7150667, lng: 18.2197611 },
    { lat: 48.7149987, lng: 18.2198177 },
    { lat: 48.7145758, lng: 18.2201544 },
    { lat: 48.7142755, lng: 18.2203485 },
    { lat: 48.7136203, lng: 18.2207901 },
    { lat: 48.7134585, lng: 18.2209196 },
    { lat: 48.7134458, lng: 18.2209345 },
    { lat: 48.7132102, lng: 18.2212129 },
    { lat: 48.7129477, lng: 18.2214972 },
    { lat: 48.712424, lng: 18.2221255 },
    { lat: 48.7120398, lng: 18.2224289 },
    { lat: 48.7118888, lng: 18.2225707 },
    { lat: 48.710878, lng: 18.2232043 },
    { lat: 48.7100815, lng: 18.2236392 },
    { lat: 48.7099227, lng: 18.2237245 },
    { lat: 48.7098306, lng: 18.2237723 },
    { lat: 48.7096872, lng: 18.2237901 },
    { lat: 48.7096975, lng: 18.2238506 },
    { lat: 48.7097284, lng: 18.2242728 },
    { lat: 48.7097333, lng: 18.2243967 },
    { lat: 48.7097523, lng: 18.2248486 },
    { lat: 48.7097756, lng: 18.2249385 },
    { lat: 48.7097713, lng: 18.2250012 },
    { lat: 48.7097707, lng: 18.2250702 },
    { lat: 48.7097704, lng: 18.2251224 },
    { lat: 48.7097863, lng: 18.2258151 },
    { lat: 48.7097772, lng: 18.2260106 },
    { lat: 48.7096891, lng: 18.2278196 },
    { lat: 48.7096885, lng: 18.2278299 },
    { lat: 48.7096858, lng: 18.2278806 },
    { lat: 48.7096807, lng: 18.2280083 },
    { lat: 48.7096774, lng: 18.2280705 },
    { lat: 48.7097262, lng: 18.2281337 },
    { lat: 48.7096709, lng: 18.2283117 },
    { lat: 48.7096164, lng: 18.228836 },
    { lat: 48.7095153, lng: 18.2298299 },
    { lat: 48.7095058, lng: 18.2300775 },
    { lat: 48.7094933, lng: 18.2301931 },
    { lat: 48.7094805, lng: 18.2302859 },
    { lat: 48.7093658, lng: 18.2314156 },
    { lat: 48.7082964, lng: 18.2318149 },
    { lat: 48.7082208, lng: 18.2320511 },
    { lat: 48.7081581, lng: 18.2321197 },
    { lat: 48.7080816, lng: 18.232204 },
    { lat: 48.7077499, lng: 18.2326775 },
    { lat: 48.7075906, lng: 18.232338 },
    { lat: 48.7074114, lng: 18.2322633 },
    { lat: 48.7072741, lng: 18.2322098 },
    { lat: 48.7071668, lng: 18.2322074 },
    { lat: 48.7070533, lng: 18.2322165 },
    { lat: 48.7070268, lng: 18.2322268 },
    { lat: 48.706665, lng: 18.2323519 },
    { lat: 48.7063692, lng: 18.232486 },
    { lat: 48.7063411, lng: 18.2325272 },
    { lat: 48.7063306, lng: 18.2325432 },
    { lat: 48.7062898, lng: 18.2325784 },
    { lat: 48.7062005, lng: 18.2326343 },
    { lat: 48.7061009, lng: 18.2327079 },
    { lat: 48.7053243, lng: 18.2332563 },
    { lat: 48.7052235, lng: 18.2333277 },
    { lat: 48.7047918, lng: 18.2336317 },
    { lat: 48.7046671, lng: 18.2337203 },
    { lat: 48.7045543, lng: 18.2338002 },
    { lat: 48.7045186, lng: 18.2338254 },
    { lat: 48.7043916, lng: 18.233914 },
    { lat: 48.7043634, lng: 18.2339334 },
    { lat: 48.704333, lng: 18.2339542 },
    { lat: 48.7042255, lng: 18.2340282 },
    { lat: 48.7040205, lng: 18.2341722 },
    { lat: 48.7039358, lng: 18.2342306 },
    { lat: 48.7038924, lng: 18.2342605 },
    { lat: 48.7038555, lng: 18.234287 },
    { lat: 48.7038012, lng: 18.2343244 },
    { lat: 48.7037406, lng: 18.2343674 },
    { lat: 48.7036917, lng: 18.2344018 },
    { lat: 48.7035931, lng: 18.2344718 },
    { lat: 48.703504, lng: 18.2345337 },
    { lat: 48.703401, lng: 18.2346068 },
    { lat: 48.7033522, lng: 18.234641 },
    { lat: 48.703298, lng: 18.2346795 },
    { lat: 48.7032567, lng: 18.2347074 },
    { lat: 48.703209, lng: 18.2347412 },
    { lat: 48.7031895, lng: 18.2347551 },
    { lat: 48.7030919, lng: 18.234824 },
    { lat: 48.7029781, lng: 18.2349055 },
    { lat: 48.7028826, lng: 18.2349733 },
    { lat: 48.702822, lng: 18.2350166 },
    { lat: 48.702771, lng: 18.2350531 },
    { lat: 48.7027125, lng: 18.2350948 },
    { lat: 48.702655, lng: 18.2351361 },
    { lat: 48.7025508, lng: 18.2352097 },
    { lat: 48.702462, lng: 18.2352724 },
    { lat: 48.702436, lng: 18.2352912 },
    { lat: 48.7023882, lng: 18.2353253 },
    { lat: 48.7023319, lng: 18.2353653 },
    { lat: 48.7020413, lng: 18.2355721 },
    { lat: 48.7019578, lng: 18.2356312 },
    { lat: 48.7019187, lng: 18.2356588 },
    { lat: 48.7018819, lng: 18.2356854 },
    { lat: 48.701806, lng: 18.2357392 },
    { lat: 48.7017822, lng: 18.2357559 },
    { lat: 48.7017257, lng: 18.2357962 },
    { lat: 48.7015892, lng: 18.2358949 },
    { lat: 48.7014603, lng: 18.2359877 },
    { lat: 48.7013898, lng: 18.2360376 },
    { lat: 48.7013204, lng: 18.2360877 },
    { lat: 48.7012348, lng: 18.2361496 },
    { lat: 48.7010538, lng: 18.236279 },
    { lat: 48.7010245, lng: 18.2363001 },
    { lat: 48.7009714, lng: 18.236339 },
    { lat: 48.7009302, lng: 18.2363669 },
    { lat: 48.7008782, lng: 18.236405 },
    { lat: 48.7008262, lng: 18.2364423 },
    { lat: 48.7007926, lng: 18.236466 },
    { lat: 48.7007078, lng: 18.2365231 },
    { lat: 48.7006611, lng: 18.2365551 },
    { lat: 48.7005101, lng: 18.2366579 },
    { lat: 48.7003734, lng: 18.2367522 },
    { lat: 48.7002615, lng: 18.236829 },
    { lat: 48.7002236, lng: 18.2368557 },
    { lat: 48.7001953, lng: 18.2368748 },
    { lat: 48.700116, lng: 18.236928 },
    { lat: 48.7001008, lng: 18.2369385 },
    { lat: 48.6998537, lng: 18.2371165 },
    { lat: 48.6997942, lng: 18.2371602 },
    { lat: 48.6996761, lng: 18.2372458 },
    { lat: 48.6995471, lng: 18.2373391 },
    { lat: 48.6993346, lng: 18.2374908 },
    { lat: 48.6992263, lng: 18.2375692 },
    { lat: 48.6991698, lng: 18.2376091 },
    { lat: 48.6989237, lng: 18.2377834 },
    { lat: 48.6988825, lng: 18.2378124 },
    { lat: 48.6987068, lng: 18.2379367 },
    { lat: 48.698568, lng: 18.2380352 },
    { lat: 48.6985301, lng: 18.2380423 },
    { lat: 48.6985729, lng: 18.2380435 },
    { lat: 48.6992313, lng: 18.2398656 },
    { lat: 48.6992383, lng: 18.2398895 },
    { lat: 48.6992399, lng: 18.2398982 },
    { lat: 48.6992682, lng: 18.2400384 },
    { lat: 48.6992409, lng: 18.240054 },
    { lat: 48.6990547, lng: 18.2401908 },
    { lat: 48.698954, lng: 18.2403659 },
    { lat: 48.6988693, lng: 18.240423 },
    { lat: 48.6988334, lng: 18.2404463 },
    { lat: 48.6986708, lng: 18.240485 },
    { lat: 48.6981776, lng: 18.2406982 },
    { lat: 48.698215, lng: 18.2408199 },
    { lat: 48.6982163, lng: 18.2408226 },
    { lat: 48.6983731, lng: 18.2414929 },
    { lat: 48.6984272, lng: 18.2417275 },
    { lat: 48.6986436, lng: 18.2426535 },
    { lat: 48.6988315, lng: 18.2439471 },
    { lat: 48.6988858, lng: 18.244324 },
    { lat: 48.6988914, lng: 18.2443615 },
    { lat: 48.6990521, lng: 18.2454353 },
    { lat: 48.6991214, lng: 18.2456224 },
    { lat: 48.6991435, lng: 18.2456527 },
    { lat: 48.6991627, lng: 18.2456727 },
    { lat: 48.6991791, lng: 18.2456638 },
    { lat: 48.6991878, lng: 18.2456587 },
    { lat: 48.6993528, lng: 18.2455649 },
    { lat: 48.6994023, lng: 18.2457595 },
    { lat: 48.6994283, lng: 18.2459774 },
    { lat: 48.6995018, lng: 18.2461979 },
    { lat: 48.6995302, lng: 18.2462802 },
    { lat: 48.699653, lng: 18.2464948 },
    { lat: 48.6996942, lng: 18.2466214 },
    { lat: 48.6996959, lng: 18.2467119 },
    { lat: 48.6997007, lng: 18.2467361 },
    { lat: 48.6997138, lng: 18.2468073 },
    { lat: 48.6997387, lng: 18.2469482 },
    { lat: 48.6997765, lng: 18.2473319 },
    { lat: 48.7000304, lng: 18.2477292 },
    { lat: 48.7001108, lng: 18.24795 },
    { lat: 48.7001262, lng: 18.2479425 },
    { lat: 48.700667, lng: 18.2483042 },
    { lat: 48.7007107, lng: 18.2483773 },
    { lat: 48.7007554, lng: 18.2485295 },
    { lat: 48.7006892, lng: 18.2487128 },
    { lat: 48.7007247, lng: 18.24888 },
    { lat: 48.7006937, lng: 18.2488522 },
    { lat: 48.7009028, lng: 18.2504583 },
    { lat: 48.7008995, lng: 18.2504784 },
    { lat: 48.7008832, lng: 18.2505675 },
    { lat: 48.7008823, lng: 18.2505723 },
    { lat: 48.700891, lng: 18.2506069 },
    { lat: 48.7009494, lng: 18.2509175 },
    { lat: 48.700967, lng: 18.2510083 },
    { lat: 48.7011156, lng: 18.2517935 },
    { lat: 48.7011186, lng: 18.2518267 },
    { lat: 48.7011195, lng: 18.2518612 },
    { lat: 48.7011142, lng: 18.2520648 },
    { lat: 48.701095, lng: 18.2520652 },
    { lat: 48.7010939, lng: 18.2520653 },
    { lat: 48.7010399, lng: 18.2520684 },
    { lat: 48.7006756, lng: 18.2520895 },
    { lat: 48.7003685, lng: 18.252107 },
    { lat: 48.6996293, lng: 18.2521854 },
    { lat: 48.6996694, lng: 18.2529071 },
    { lat: 48.6996499, lng: 18.2535526 },
    { lat: 48.6996149, lng: 18.2539082 },
    { lat: 48.699518, lng: 18.2546245 },
    { lat: 48.6994048, lng: 18.2552483 },
    { lat: 48.6993956, lng: 18.255287 },
    { lat: 48.6992407, lng: 18.2559723 },
    { lat: 48.6991706, lng: 18.2566224 },
    { lat: 48.6989034, lng: 18.256727 },
    { lat: 48.6989899, lng: 18.2574112 },
    { lat: 48.6988278, lng: 18.2574787 },
    { lat: 48.6987762, lng: 18.2577408 },
    { lat: 48.69874, lng: 18.2582334 },
    { lat: 48.6987205, lng: 18.2585049 },
    { lat: 48.6987172, lng: 18.2585461 },
    { lat: 48.6986004, lng: 18.2596437 },
    { lat: 48.6985167, lng: 18.2605308 },
    { lat: 48.6983937, lng: 18.2612649 },
    { lat: 48.6982196, lng: 18.2619914 },
    { lat: 48.6982094, lng: 18.262029 },
    { lat: 48.6980124, lng: 18.2620553 },
    { lat: 48.6978526, lng: 18.2623226 },
    { lat: 48.6977412, lng: 18.2626826 },
    { lat: 48.6976252, lng: 18.2631813 },
    { lat: 48.697541, lng: 18.2641004 },
    { lat: 48.6974874, lng: 18.2650607 },
    { lat: 48.6974881, lng: 18.2659046 },
    { lat: 48.6974669, lng: 18.2666412 },
    { lat: 48.697481, lng: 18.2666316 },
    { lat: 48.6976928, lng: 18.2664863 },
    { lat: 48.6981029, lng: 18.2661008 },
    { lat: 48.6985009, lng: 18.2656793 },
    { lat: 48.6988047, lng: 18.2652673 },
    { lat: 48.6988861, lng: 18.2654093 },
    { lat: 48.6989075, lng: 18.2654879 },
    { lat: 48.6989475, lng: 18.2656356 },
    { lat: 48.6989575, lng: 18.2656732 },
    { lat: 48.6989589, lng: 18.2656759 },
    { lat: 48.699157, lng: 18.2661838 },
    { lat: 48.6993127, lng: 18.2669954 },
    { lat: 48.6993351, lng: 18.2678251 },
    { lat: 48.6993505, lng: 18.2682146 },
    { lat: 48.6993983, lng: 18.2688167 },
    { lat: 48.6995439, lng: 18.2697488 },
    { lat: 48.699657, lng: 18.2705269 },
    { lat: 48.699795, lng: 18.2712848 },
    { lat: 48.6998933, lng: 18.2718231 },
    { lat: 48.7004786, lng: 18.2715612 },
    { lat: 48.700645, lng: 18.2715101 },
    { lat: 48.7006229, lng: 18.2719934 },
    { lat: 48.7005774, lng: 18.2722455 },
    { lat: 48.7004079, lng: 18.2728156 },
    { lat: 48.700562, lng: 18.2737591 },
    { lat: 48.7005622, lng: 18.2737637 },
    { lat: 48.700605, lng: 18.2740787 },
    { lat: 48.700662, lng: 18.2744062 },
    { lat: 48.7007168, lng: 18.2747358 },
    { lat: 48.7008146, lng: 18.2751851 },
    { lat: 48.7008111, lng: 18.2752631 },
    { lat: 48.7010789, lng: 18.2770936 },
    { lat: 48.70116, lng: 18.2777257 },
    { lat: 48.7011909, lng: 18.2781914 },
    { lat: 48.7012483, lng: 18.2785277 },
    { lat: 48.7012985, lng: 18.2789729 },
    { lat: 48.7014103, lng: 18.2796088 },
    { lat: 48.7015299, lng: 18.2801056 },
  ],
  Borčany: [
    { lat: 48.653133, lng: 18.213217 },
    { lat: 48.653157, lng: 18.213411 },
    { lat: 48.653098, lng: 18.213602 },
    { lat: 48.653071, lng: 18.213726 },
    { lat: 48.653085, lng: 18.213832 },
    { lat: 48.653223, lng: 18.214193 },
    { lat: 48.653058, lng: 18.214335 },
    { lat: 48.652971, lng: 18.214449 },
    { lat: 48.652929, lng: 18.21454 },
    { lat: 48.652925, lng: 18.214712 },
    { lat: 48.652949, lng: 18.215044 },
    { lat: 48.652953, lng: 18.215105 },
    { lat: 48.652962, lng: 18.215168 },
    { lat: 48.652971, lng: 18.215206 },
    { lat: 48.653031, lng: 18.215346 },
    { lat: 48.653138, lng: 18.215538 },
    { lat: 48.65348, lng: 18.215484 },
    { lat: 48.653458, lng: 18.215665 },
    { lat: 48.653327, lng: 18.216083 },
    { lat: 48.653267, lng: 18.216212 },
    { lat: 48.653186, lng: 18.216425 },
    { lat: 48.653166, lng: 18.216576 },
    { lat: 48.653185, lng: 18.216765 },
    { lat: 48.653272, lng: 18.216762 },
    { lat: 48.653374, lng: 18.216751 },
    { lat: 48.65341, lng: 18.216955 },
    { lat: 48.653525, lng: 18.217359 },
    { lat: 48.653605, lng: 18.21735 },
    { lat: 48.65367, lng: 18.217328 },
    { lat: 48.653657, lng: 18.217391 },
    { lat: 48.653696, lng: 18.217503 },
    { lat: 48.653807, lng: 18.217571 },
    { lat: 48.65383, lng: 18.217639 },
    { lat: 48.653824, lng: 18.217993 },
    { lat: 48.653849, lng: 18.218281 },
    { lat: 48.653831, lng: 18.218357 },
    { lat: 48.653807, lng: 18.218425 },
    { lat: 48.65367, lng: 18.218496 },
    { lat: 48.653489, lng: 18.218679 },
    { lat: 48.6534, lng: 18.218776 },
    { lat: 48.653386, lng: 18.21888 },
    { lat: 48.653409, lng: 18.219437 },
    { lat: 48.653421, lng: 18.219599 },
    { lat: 48.653401, lng: 18.219851 },
    { lat: 48.653412, lng: 18.21998 },
    { lat: 48.653424, lng: 18.220028 },
    { lat: 48.653548, lng: 18.220265 },
    { lat: 48.653655, lng: 18.220445 },
    { lat: 48.653682, lng: 18.220504 },
    { lat: 48.653717, lng: 18.220745 },
    { lat: 48.653733, lng: 18.220845 },
    { lat: 48.653759, lng: 18.221207 },
    { lat: 48.653776, lng: 18.221341 },
    { lat: 48.653777, lng: 18.221453 },
    { lat: 48.653721, lng: 18.22183 },
    { lat: 48.653745, lng: 18.221889 },
    { lat: 48.65383, lng: 18.221907 },
    { lat: 48.653889, lng: 18.221884 },
    { lat: 48.654044, lng: 18.221899 },
    { lat: 48.654116, lng: 18.222015 },
    { lat: 48.654186, lng: 18.222207 },
    { lat: 48.654211, lng: 18.222369 },
    { lat: 48.654209, lng: 18.22242 },
    { lat: 48.654148, lng: 18.222574 },
    { lat: 48.654111, lng: 18.222656 },
    { lat: 48.654078, lng: 18.222958 },
    { lat: 48.654053, lng: 18.223039 },
    { lat: 48.654048, lng: 18.223139 },
    { lat: 48.654076, lng: 18.223195 },
    { lat: 48.654219, lng: 18.2232 },
    { lat: 48.654343, lng: 18.223295 },
    { lat: 48.654363, lng: 18.22334 },
    { lat: 48.654373, lng: 18.223422 },
    { lat: 48.654431, lng: 18.223604 },
    { lat: 48.654468, lng: 18.223561 },
    { lat: 48.654738, lng: 18.223358 },
    { lat: 48.654772, lng: 18.223406 },
    { lat: 48.655764, lng: 18.224797 },
    { lat: 48.656129, lng: 18.225306 },
    { lat: 48.656447, lng: 18.225047 },
    { lat: 48.656604, lng: 18.225296 },
    { lat: 48.6566, lng: 18.225479 },
    { lat: 48.656579, lng: 18.225618 },
    { lat: 48.656513, lng: 18.225867 },
    { lat: 48.656477, lng: 18.22601 },
    { lat: 48.656442, lng: 18.226183 },
    { lat: 48.656455, lng: 18.226382 },
    { lat: 48.656449, lng: 18.226452 },
    { lat: 48.656406, lng: 18.226614 },
    { lat: 48.656358, lng: 18.226731 },
    { lat: 48.656308, lng: 18.226809 },
    { lat: 48.656211, lng: 18.226935 },
    { lat: 48.656152, lng: 18.227025 },
    { lat: 48.656097, lng: 18.22714 },
    { lat: 48.656056, lng: 18.227306 },
    { lat: 48.656041, lng: 18.227359 },
    { lat: 48.656025, lng: 18.227386 },
    { lat: 48.655966, lng: 18.227471 },
    { lat: 48.655929, lng: 18.227516 },
    { lat: 48.655875, lng: 18.227597 },
    { lat: 48.655836, lng: 18.227698 },
    { lat: 48.65584, lng: 18.227856 },
    { lat: 48.655879, lng: 18.22788 },
    { lat: 48.656073, lng: 18.227913 },
    { lat: 48.656117, lng: 18.227921 },
    { lat: 48.656151, lng: 18.227938 },
    { lat: 48.656158, lng: 18.227951 },
    { lat: 48.656185, lng: 18.227994 },
    { lat: 48.656195, lng: 18.228024 },
    { lat: 48.656203, lng: 18.228119 },
    { lat: 48.656201, lng: 18.22817 },
    { lat: 48.656181, lng: 18.228253 },
    { lat: 48.656103, lng: 18.228394 },
    { lat: 48.655938, lng: 18.22858 },
    { lat: 48.655918, lng: 18.228642 },
    { lat: 48.655918, lng: 18.228667 },
    { lat: 48.655935, lng: 18.228678 },
    { lat: 48.656067, lng: 18.2287 },
    { lat: 48.656106, lng: 18.228894 },
    { lat: 48.656099, lng: 18.228971 },
    { lat: 48.656065, lng: 18.229013 },
    { lat: 48.65569, lng: 18.229303 },
    { lat: 48.655658, lng: 18.229368 },
    { lat: 48.655579, lng: 18.229523 },
    { lat: 48.655518, lng: 18.229659 },
    { lat: 48.655401, lng: 18.229901 },
    { lat: 48.655378, lng: 18.230005 },
    { lat: 48.655376, lng: 18.230102 },
    { lat: 48.655377, lng: 18.230224 },
    { lat: 48.655352, lng: 18.230346 },
    { lat: 48.655261, lng: 18.230431 },
    { lat: 48.655123, lng: 18.23054 },
    { lat: 48.655026, lng: 18.230583 },
    { lat: 48.655002, lng: 18.230601 },
    { lat: 48.654981, lng: 18.230644 },
    { lat: 48.654908, lng: 18.230902 },
    { lat: 48.654788, lng: 18.231153 },
    { lat: 48.65458, lng: 18.231434 },
    { lat: 48.654499, lng: 18.231505 },
    { lat: 48.654417, lng: 18.231582 },
    { lat: 48.654405, lng: 18.231602 },
    { lat: 48.654364, lng: 18.231706 },
    { lat: 48.654349, lng: 18.23175 },
    { lat: 48.654318, lng: 18.23191 },
    { lat: 48.654258, lng: 18.232168 },
    { lat: 48.654198, lng: 18.23238 },
    { lat: 48.654139, lng: 18.232558 },
    { lat: 48.654121, lng: 18.232637 },
    { lat: 48.654075, lng: 18.2328 },
    { lat: 48.654061, lng: 18.232876 },
    { lat: 48.654036, lng: 18.233002 },
    { lat: 48.653986, lng: 18.233251 },
    { lat: 48.653967, lng: 18.233616 },
    { lat: 48.653959, lng: 18.234037 },
    { lat: 48.653952, lng: 18.234185 },
    { lat: 48.653908, lng: 18.23455 },
    { lat: 48.653893, lng: 18.234713 },
    { lat: 48.653893, lng: 18.234718 },
    { lat: 48.653878, lng: 18.234752 },
    { lat: 48.653929, lng: 18.234848 },
    { lat: 48.653943, lng: 18.234899 },
    { lat: 48.653935, lng: 18.234948 },
    { lat: 48.653914, lng: 18.235035 },
    { lat: 48.65386, lng: 18.235169 },
    { lat: 48.653799, lng: 18.235376 },
    { lat: 48.65371, lng: 18.235638 },
    { lat: 48.653565, lng: 18.235807 },
    { lat: 48.65347, lng: 18.236015 },
    { lat: 48.65335, lng: 18.236149 },
    { lat: 48.65329, lng: 18.236169 },
    { lat: 48.653215, lng: 18.236134 },
    { lat: 48.653152, lng: 18.23603 },
    { lat: 48.653038, lng: 18.235959 },
    { lat: 48.65298, lng: 18.235857 },
    { lat: 48.652934, lng: 18.23577 },
    { lat: 48.652885, lng: 18.235709 },
    { lat: 48.652761, lng: 18.235656 },
    { lat: 48.652661, lng: 18.235536 },
    { lat: 48.652623, lng: 18.23553 },
    { lat: 48.652599, lng: 18.235545 },
    { lat: 48.652585, lng: 18.235584 },
    { lat: 48.652545, lng: 18.235694 },
    { lat: 48.652486, lng: 18.235804 },
    { lat: 48.652412, lng: 18.235905 },
    { lat: 48.652399, lng: 18.236088 },
    { lat: 48.652361, lng: 18.236243 },
    { lat: 48.652339, lng: 18.236269 },
    { lat: 48.65233, lng: 18.236302 },
    { lat: 48.652368, lng: 18.236428 },
    { lat: 48.652432, lng: 18.236518 },
    { lat: 48.652529, lng: 18.236555 },
    { lat: 48.652599, lng: 18.236539 },
    { lat: 48.652665, lng: 18.23649 },
    { lat: 48.652738, lng: 18.236519 },
    { lat: 48.652793, lng: 18.236574 },
    { lat: 48.652839, lng: 18.236732 },
    { lat: 48.652818, lng: 18.236886 },
    { lat: 48.652727, lng: 18.237145 },
    { lat: 48.652691, lng: 18.237248 },
    { lat: 48.652656, lng: 18.237417 },
    { lat: 48.652595, lng: 18.237552 },
    { lat: 48.652576, lng: 18.237647 },
    { lat: 48.65258, lng: 18.237754 },
    { lat: 48.65262, lng: 18.237871 },
    { lat: 48.652626, lng: 18.237966 },
    { lat: 48.652625, lng: 18.238056 },
    { lat: 48.652691, lng: 18.238124 },
    { lat: 48.652798, lng: 18.238176 },
    { lat: 48.652971, lng: 18.238339 },
    { lat: 48.653063, lng: 18.238375 },
    { lat: 48.653102, lng: 18.238424 },
    { lat: 48.653139, lng: 18.238546 },
    { lat: 48.653155, lng: 18.238659 },
    { lat: 48.653152, lng: 18.238729 },
    { lat: 48.653118, lng: 18.238896 },
    { lat: 48.653103, lng: 18.238938 },
    { lat: 48.653058, lng: 18.239032 },
    { lat: 48.653053, lng: 18.239076 },
    { lat: 48.653073, lng: 18.239274 },
    { lat: 48.653077, lng: 18.239456 },
    { lat: 48.653161, lng: 18.239721 },
    { lat: 48.653162, lng: 18.239761 },
    { lat: 48.653118, lng: 18.239966 },
    { lat: 48.652991, lng: 18.240333 },
    { lat: 48.652991, lng: 18.240533 },
    { lat: 48.6531058, lng: 18.2405133 },
    { lat: 48.6533564, lng: 18.2400624 },
    { lat: 48.6535421, lng: 18.2397962 },
    { lat: 48.6536583, lng: 18.2395409 },
    { lat: 48.6537121, lng: 18.2392794 },
    { lat: 48.6542362, lng: 18.2407271 },
    { lat: 48.6544808, lng: 18.2405428 },
    { lat: 48.6547316, lng: 18.240372 },
    { lat: 48.6551808, lng: 18.2400789 },
    { lat: 48.6556123, lng: 18.2398089 },
    { lat: 48.6561918, lng: 18.2394657 },
    { lat: 48.6563462, lng: 18.239366 },
    { lat: 48.6568284, lng: 18.2389958 },
    { lat: 48.6572311, lng: 18.2386213 },
    { lat: 48.6575804, lng: 18.2382588 },
    { lat: 48.657833, lng: 18.2379793 },
    { lat: 48.6581445, lng: 18.2376246 },
    { lat: 48.6586596, lng: 18.2370238 },
    { lat: 48.6590599, lng: 18.2365457 },
    { lat: 48.6593301, lng: 18.2362178 },
    { lat: 48.6595197, lng: 18.2359811 },
    { lat: 48.6598248, lng: 18.2355586 },
    { lat: 48.6600885, lng: 18.2352166 },
    { lat: 48.6604403, lng: 18.2348187 },
    { lat: 48.6608062, lng: 18.2344497 },
    { lat: 48.6612062, lng: 18.2340634 },
    { lat: 48.6614329, lng: 18.2338486 },
    { lat: 48.6617053, lng: 18.2335803 },
    { lat: 48.6615621, lng: 18.2327516 },
    { lat: 48.6616243, lng: 18.2326775 },
    { lat: 48.6622715, lng: 18.2319398 },
    { lat: 48.662489, lng: 18.2316994 },
    { lat: 48.662745, lng: 18.2314386 },
    { lat: 48.6630888, lng: 18.2310999 },
    { lat: 48.6633417, lng: 18.2308641 },
    { lat: 48.663718, lng: 18.2305194 },
    { lat: 48.6640526, lng: 18.2301969 },
    { lat: 48.6643134, lng: 18.2299048 },
    { lat: 48.6646996, lng: 18.2294349 },
    { lat: 48.6648936, lng: 18.2292386 },
    { lat: 48.6644635, lng: 18.2283685 },
    { lat: 48.6640214, lng: 18.2274086 },
    { lat: 48.6649908, lng: 18.2263281 },
    { lat: 48.6652042, lng: 18.2260746 },
    { lat: 48.6654965, lng: 18.2256987 },
    { lat: 48.6657615, lng: 18.2253239 },
    { lat: 48.6662419, lng: 18.224571 },
    { lat: 48.6667447, lng: 18.2237781 },
    { lat: 48.6672405, lng: 18.2229565 },
    { lat: 48.6672904, lng: 18.2228629 },
    { lat: 48.6674672, lng: 18.2229565 },
    { lat: 48.6677626, lng: 18.2231664 },
    { lat: 48.6679361, lng: 18.22334 },
    { lat: 48.6680474, lng: 18.2234897 },
    { lat: 48.6681455, lng: 18.2237039 },
    { lat: 48.6688292, lng: 18.2227795 },
    { lat: 48.6690922, lng: 18.2223885 },
    { lat: 48.6692375, lng: 18.2221478 },
    { lat: 48.6696032, lng: 18.2214995 },
    { lat: 48.6698163, lng: 18.2210828 },
    { lat: 48.6701161, lng: 18.2204899 },
    { lat: 48.6704168, lng: 18.2198668 },
    { lat: 48.6706762, lng: 18.2193763 },
    { lat: 48.67078, lng: 18.2192156 },
    { lat: 48.6707257, lng: 18.2191178 },
    { lat: 48.6709598, lng: 18.218537 },
    { lat: 48.671039, lng: 18.2183017 },
    { lat: 48.6712059, lng: 18.2176512 },
    { lat: 48.6712964, lng: 18.2173609 },
    { lat: 48.6713706, lng: 18.2171427 },
    { lat: 48.671543, lng: 18.2167431 },
    { lat: 48.6717331, lng: 18.2163194 },
    { lat: 48.6716603, lng: 18.2158591 },
    { lat: 48.671637, lng: 18.2156708 },
    { lat: 48.6716294, lng: 18.2151441 },
    { lat: 48.6716531, lng: 18.2147129 },
    { lat: 48.6716529, lng: 18.2142199 },
    { lat: 48.6716389, lng: 18.2140129 },
    { lat: 48.6715293, lng: 18.2133639 },
    { lat: 48.6712762, lng: 18.2120986 },
    { lat: 48.671265, lng: 18.2117681 },
    { lat: 48.6712296, lng: 18.2113671 },
    { lat: 48.6712435, lng: 18.2112182 },
    { lat: 48.6713371, lng: 18.210764 },
    { lat: 48.6714105, lng: 18.2105476 },
    { lat: 48.6714514, lng: 18.2102813 },
    { lat: 48.6714492, lng: 18.2100449 },
    { lat: 48.6713928, lng: 18.2094553 },
    { lat: 48.6712249, lng: 18.2095977 },
    { lat: 48.6710286, lng: 18.2098155 },
    { lat: 48.6708566, lng: 18.2100394 },
    { lat: 48.6706859, lng: 18.2098382 },
    { lat: 48.6705952, lng: 18.2097127 },
    { lat: 48.6703406, lng: 18.2092912 },
    { lat: 48.6710371, lng: 18.2082735 },
    { lat: 48.6707257, lng: 18.2077424 },
    { lat: 48.6706769, lng: 18.2076634 },
    { lat: 48.6706758, lng: 18.2076596 },
    { lat: 48.6703295, lng: 18.2070953 },
    { lat: 48.6699154, lng: 18.2063495 },
    { lat: 48.6695582, lng: 18.2057883 },
    { lat: 48.6692888, lng: 18.205286 },
    { lat: 48.6691167, lng: 18.2049983 },
    { lat: 48.6686953, lng: 18.2043646 },
    { lat: 48.668663, lng: 18.2044113 },
    { lat: 48.6686002, lng: 18.2045527 },
    { lat: 48.6685155, lng: 18.2047702 },
    { lat: 48.6684961, lng: 18.2047873 },
    { lat: 48.6683911, lng: 18.2048229 },
    { lat: 48.668324, lng: 18.2048324 },
    { lat: 48.6682625, lng: 18.204824 },
    { lat: 48.6682189, lng: 18.2048505 },
    { lat: 48.6681607, lng: 18.2049366 },
    { lat: 48.6681529, lng: 18.2050555 },
    { lat: 48.6681621, lng: 18.2051578 },
    { lat: 48.6681473, lng: 18.2052918 },
    { lat: 48.6681563, lng: 18.2053909 },
    { lat: 48.6681474, lng: 18.2054703 },
    { lat: 48.6680949, lng: 18.2055611 },
    { lat: 48.6680125, lng: 18.2056169 },
    { lat: 48.6679391, lng: 18.2056343 },
    { lat: 48.6678625, lng: 18.2056762 },
    { lat: 48.6678227, lng: 18.2056717 },
    { lat: 48.6677209, lng: 18.2056105 },
    { lat: 48.6676794, lng: 18.205633 },
    { lat: 48.6676231, lng: 18.2057522 },
    { lat: 48.667584, lng: 18.2058587 },
    { lat: 48.6675823, lng: 18.2059485 },
    { lat: 48.6675978, lng: 18.2060204 },
    { lat: 48.6676452, lng: 18.2061155 },
    { lat: 48.6676969, lng: 18.2062498 },
    { lat: 48.6677079, lng: 18.2063629 },
    { lat: 48.66768, lng: 18.2064084 },
    { lat: 48.6675922, lng: 18.2064727 },
    { lat: 48.6675207, lng: 18.2064642 },
    { lat: 48.6673341, lng: 18.2063802 },
    { lat: 48.6672728, lng: 18.2064132 },
    { lat: 48.6672276, lng: 18.2064694 },
    { lat: 48.6671999, lng: 18.2064781 },
    { lat: 48.6671579, lng: 18.2064539 },
    { lat: 48.6671508, lng: 18.2064299 },
    { lat: 48.6670489, lng: 18.206344 },
    { lat: 48.6669904, lng: 18.206346 },
    { lat: 48.6669561, lng: 18.2063576 },
    { lat: 48.6669269, lng: 18.2064218 },
    { lat: 48.6669048, lng: 18.2065471 },
    { lat: 48.666865, lng: 18.2066405 },
    { lat: 48.6668071, lng: 18.2066734 },
    { lat: 48.6667938, lng: 18.206677 },
    { lat: 48.6667399, lng: 18.2066615 },
    { lat: 48.6666014, lng: 18.2066461 },
    { lat: 48.6665414, lng: 18.2066251 },
    { lat: 48.6665071, lng: 18.2066385 },
    { lat: 48.6664029, lng: 18.2067261 },
    { lat: 48.6663861, lng: 18.2068275 },
    { lat: 48.6662319, lng: 18.206993 },
    { lat: 48.6662108, lng: 18.2072128 },
    { lat: 48.6661444, lng: 18.2072347 },
    { lat: 48.6660031, lng: 18.2073671 },
    { lat: 48.6659213, lng: 18.2074171 },
    { lat: 48.6658462, lng: 18.2074477 },
    { lat: 48.6657739, lng: 18.2075018 },
    { lat: 48.6656776, lng: 18.207674 },
    { lat: 48.6655949, lng: 18.2078069 },
    { lat: 48.6655496, lng: 18.207841 },
    { lat: 48.6653952, lng: 18.2078241 },
    { lat: 48.6653039, lng: 18.207865 },
    { lat: 48.6652453, lng: 18.2079073 },
    { lat: 48.6652191, lng: 18.2079036 },
    { lat: 48.6651475, lng: 18.207933 },
    { lat: 48.6651135, lng: 18.2080859 },
    { lat: 48.6650951, lng: 18.208375 },
    { lat: 48.6650416, lng: 18.2085055 },
    { lat: 48.6650045, lng: 18.2085261 },
    { lat: 48.6649358, lng: 18.2085087 },
    { lat: 48.6647907, lng: 18.2084973 },
    { lat: 48.6647577, lng: 18.2085117 },
    { lat: 48.6646508, lng: 18.2086174 },
    { lat: 48.6646388, lng: 18.2086429 },
    { lat: 48.6645934, lng: 18.2087966 },
    { lat: 48.6645565, lng: 18.2088818 },
    { lat: 48.6645327, lng: 18.2090559 },
    { lat: 48.6645338, lng: 18.2091336 },
    { lat: 48.6645104, lng: 18.2091984 },
    { lat: 48.6645701, lng: 18.2094679 },
    { lat: 48.664557, lng: 18.2095157 },
    { lat: 48.6643801, lng: 18.2095021 },
    { lat: 48.6643303, lng: 18.2095204 },
    { lat: 48.6643131, lng: 18.2096337 },
    { lat: 48.6642798, lng: 18.2096627 },
    { lat: 48.6641869, lng: 18.2096959 },
    { lat: 48.6641602, lng: 18.2097573 },
    { lat: 48.6641411, lng: 18.2097797 },
    { lat: 48.664082, lng: 18.2098121 },
    { lat: 48.6640352, lng: 18.2099193 },
    { lat: 48.6639845, lng: 18.2099998 },
    { lat: 48.663935, lng: 18.2100445 },
    { lat: 48.6639125, lng: 18.2100442 },
    { lat: 48.663898, lng: 18.210009 },
    { lat: 48.663885, lng: 18.2099397 },
    { lat: 48.6638126, lng: 18.2097217 },
    { lat: 48.6637211, lng: 18.2096184 },
    { lat: 48.6637031, lng: 18.2096226 },
    { lat: 48.6636613, lng: 18.209541 },
    { lat: 48.6634029, lng: 18.2091515 },
    { lat: 48.6627773, lng: 18.2081783 },
    { lat: 48.6622057, lng: 18.2073044 },
    { lat: 48.6620277, lng: 18.2070172 },
    { lat: 48.6624135, lng: 18.2064651 },
    { lat: 48.6626144, lng: 18.2062089 },
    { lat: 48.6627077, lng: 18.2061049 },
    { lat: 48.6628872, lng: 18.2058122 },
    { lat: 48.6629561, lng: 18.2057361 },
    { lat: 48.662931, lng: 18.2056898 },
    { lat: 48.6627313, lng: 18.2053797 },
    { lat: 48.661713, lng: 18.2065087 },
    { lat: 48.6607761, lng: 18.2075442 },
    { lat: 48.6597564, lng: 18.2086687 },
    { lat: 48.6591753, lng: 18.2093156 },
    { lat: 48.6585848, lng: 18.2099762 },
    { lat: 48.657354, lng: 18.2113374 },
    { lat: 48.656202, lng: 18.2126132 },
    { lat: 48.6561526, lng: 18.2126179 },
    { lat: 48.6561244, lng: 18.2121883 },
    { lat: 48.6560609, lng: 18.2122023 },
    { lat: 48.6547243, lng: 18.2128217 },
    { lat: 48.6537997, lng: 18.2133632 },
    { lat: 48.6535816, lng: 18.2128642 },
    { lat: 48.653133, lng: 18.213217 },
  ],
  Brezolupy: [
    { lat: 48.7015299, lng: 18.2801056 },
    { lat: 48.701483, lng: 18.2801881 },
    { lat: 48.7006473, lng: 18.2805723 },
    { lat: 48.7003912, lng: 18.2806923 },
    { lat: 48.7003067, lng: 18.2807161 },
    { lat: 48.6995524, lng: 18.2811607 },
    { lat: 48.6994231, lng: 18.2812057 },
    { lat: 48.6989008, lng: 18.2814103 },
    { lat: 48.6987681, lng: 18.2814543 },
    { lat: 48.6985359, lng: 18.2815219 },
    { lat: 48.698166, lng: 18.2816027 },
    { lat: 48.6979368, lng: 18.2816803 },
    { lat: 48.697787, lng: 18.2817826 },
    { lat: 48.6976745, lng: 18.2818907 },
    { lat: 48.6973823, lng: 18.282049 },
    { lat: 48.6973546, lng: 18.2820576 },
    { lat: 48.6965441, lng: 18.2822112 },
    { lat: 48.6962372, lng: 18.2822914 },
    { lat: 48.6956504, lng: 18.2823489 },
    { lat: 48.695387, lng: 18.2824377 },
    { lat: 48.6951804, lng: 18.2825959 },
    { lat: 48.6949076, lng: 18.2826381 },
    { lat: 48.6946317, lng: 18.2826681 },
    { lat: 48.6936316, lng: 18.282761 },
    { lat: 48.692791, lng: 18.282901 },
    { lat: 48.6928752, lng: 18.2837676 },
    { lat: 48.6929622, lng: 18.284882 },
    { lat: 48.6932194, lng: 18.2877793 },
    { lat: 48.6932242, lng: 18.2878262 },
    { lat: 48.6932221, lng: 18.2879057 },
    { lat: 48.6932855, lng: 18.2883693 },
    { lat: 48.6930842, lng: 18.28848 },
    { lat: 48.6928164, lng: 18.2885947 },
    { lat: 48.6923348, lng: 18.2886739 },
    { lat: 48.6924191, lng: 18.2893629 },
    { lat: 48.6924736, lng: 18.2898908 },
    { lat: 48.6925502, lng: 18.2908601 },
    { lat: 48.6926329, lng: 18.291723 },
    { lat: 48.6927242, lng: 18.2925365 },
    { lat: 48.6928047, lng: 18.2931964 },
    { lat: 48.6928228, lng: 18.2932671 },
    { lat: 48.6928236, lng: 18.2932707 },
    { lat: 48.6928435, lng: 18.2933467 },
    { lat: 48.6922735, lng: 18.293619 },
    { lat: 48.6919368, lng: 18.2937733 },
    { lat: 48.6907487, lng: 18.2942106 },
    { lat: 48.6907742, lng: 18.2944816 },
    { lat: 48.690853, lng: 18.2948942 },
    { lat: 48.690966, lng: 18.295632 },
    { lat: 48.6912343, lng: 18.2966647 },
    { lat: 48.6912904, lng: 18.2972178 },
    { lat: 48.6912349, lng: 18.2979699 },
    { lat: 48.6911326, lng: 18.2985716 },
    { lat: 48.6910506, lng: 18.2988283 },
    { lat: 48.6908662, lng: 18.2995267 },
    { lat: 48.6907822, lng: 18.2998929 },
    { lat: 48.690778, lng: 18.3001413 },
    { lat: 48.6907665, lng: 18.3004549 },
    { lat: 48.6909175, lng: 18.3013471 },
    { lat: 48.6908788, lng: 18.3016185 },
    { lat: 48.6908053, lng: 18.3019736 },
    { lat: 48.6905827, lng: 18.3029441 },
    { lat: 48.6905955, lng: 18.3031699 },
    { lat: 48.6903842, lng: 18.3036443 },
    { lat: 48.6900978, lng: 18.3044862 },
    { lat: 48.6899374, lng: 18.3050608 },
    { lat: 48.6906979, lng: 18.3062156 },
    { lat: 48.6907746, lng: 18.3064132 },
    { lat: 48.6910377, lng: 18.3071349 },
    { lat: 48.6910816, lng: 18.3072717 },
    { lat: 48.6914376, lng: 18.3081006 },
    { lat: 48.6916571, lng: 18.3086083 },
    { lat: 48.691648, lng: 18.308658 },
    { lat: 48.6916381, lng: 18.3087115 },
    { lat: 48.6921263, lng: 18.3090245 },
    { lat: 48.6925411, lng: 18.3098155 },
    { lat: 48.6926251, lng: 18.3101254 },
    { lat: 48.6927327, lng: 18.3104318 },
    { lat: 48.6928327, lng: 18.3108028 },
    { lat: 48.6928677, lng: 18.3110822 },
    { lat: 48.6928791, lng: 18.3117407 },
    { lat: 48.6929246, lng: 18.3132258 },
    { lat: 48.692727, lng: 18.3133238 },
    { lat: 48.6925704, lng: 18.3132675 },
    { lat: 48.6924688, lng: 18.3132442 },
    { lat: 48.6924797, lng: 18.3135367 },
    { lat: 48.6924579, lng: 18.3137906 },
    { lat: 48.6921592, lng: 18.3139559 },
    { lat: 48.6923125, lng: 18.3145511 },
    { lat: 48.6922433, lng: 18.3149832 },
    { lat: 48.6923048, lng: 18.3155109 },
    { lat: 48.6922554, lng: 18.3155362 },
    { lat: 48.692365, lng: 18.3161367 },
    { lat: 48.6925301, lng: 18.3160675 },
    { lat: 48.6925656, lng: 18.3162144 },
    { lat: 48.6927391, lng: 18.3164477 },
    { lat: 48.6927671, lng: 18.316683 },
    { lat: 48.6927344, lng: 18.3170636 },
    { lat: 48.6927156, lng: 18.3175296 },
    { lat: 48.6926094, lng: 18.3175286 },
    { lat: 48.692591, lng: 18.318362 },
    { lat: 48.692482, lng: 18.3187889 },
    { lat: 48.6925352, lng: 18.3189902 },
    { lat: 48.6923619, lng: 18.3191775 },
    { lat: 48.6926631, lng: 18.3204547 },
    { lat: 48.6928759, lng: 18.3204873 },
    { lat: 48.6930657, lng: 18.3204707 },
    { lat: 48.6930457, lng: 18.3212543 },
    { lat: 48.6930172, lng: 18.322189 },
    { lat: 48.6930065, lng: 18.3223014 },
    { lat: 48.6928578, lng: 18.3250394 },
    { lat: 48.6928916, lng: 18.3251618 },
    { lat: 48.6928376, lng: 18.3260233 },
    { lat: 48.6928511, lng: 18.3260813 },
    { lat: 48.6928667, lng: 18.3264174 },
    { lat: 48.6928031, lng: 18.3266713 },
    { lat: 48.692757, lng: 18.3272119 },
    { lat: 48.6926879, lng: 18.3285057 },
    { lat: 48.6926552, lng: 18.3290877 },
    { lat: 48.6926074, lng: 18.3291999 },
    { lat: 48.6925864, lng: 18.3298883 },
    { lat: 48.692558, lng: 18.3302852 },
    { lat: 48.6925608, lng: 18.3304573 },
    { lat: 48.6925413, lng: 18.3310701 },
    { lat: 48.6924662, lng: 18.331919 },
    { lat: 48.692926, lng: 18.3320902 },
    { lat: 48.6929556, lng: 18.3321335 },
    { lat: 48.6930293, lng: 18.3323419 },
    { lat: 48.6931037, lng: 18.3324198 },
    { lat: 48.6933233, lng: 18.3328933 },
    { lat: 48.6934694, lng: 18.3334038 },
    { lat: 48.693505, lng: 18.3334735 },
    { lat: 48.6934266, lng: 18.3336843 },
    { lat: 48.6934006, lng: 18.3343015 },
    { lat: 48.6934355, lng: 18.334803 },
    { lat: 48.6935564, lng: 18.335225 },
    { lat: 48.6935116, lng: 18.3354907 },
    { lat: 48.69394, lng: 18.3354439 },
    { lat: 48.6953402, lng: 18.3351277 },
    { lat: 48.6956161, lng: 18.3351558 },
    { lat: 48.6961128, lng: 18.3353026 },
    { lat: 48.6965016, lng: 18.3354355 },
    { lat: 48.6970169, lng: 18.3354878 },
    { lat: 48.6979658, lng: 18.3359515 },
    { lat: 48.6983612, lng: 18.3362171 },
    { lat: 48.6987205, lng: 18.3364095 },
    { lat: 48.6987425, lng: 18.3363388 },
    { lat: 48.699023, lng: 18.3364706 },
    { lat: 48.6992203, lng: 18.3365099 },
    { lat: 48.6993641, lng: 18.3365567 },
    { lat: 48.6998494, lng: 18.3371597 },
    { lat: 48.7000229, lng: 18.337436 },
    { lat: 48.7001047, lng: 18.3374066 },
    { lat: 48.7004619, lng: 18.3374992 },
    { lat: 48.7006851, lng: 18.3378763 },
    { lat: 48.7009579, lng: 18.338053 },
    { lat: 48.7012492, lng: 18.3380145 },
    { lat: 48.7012867, lng: 18.3379037 },
    { lat: 48.7013149, lng: 18.3378041 },
    { lat: 48.7014791, lng: 18.3372521 },
    { lat: 48.7013748, lng: 18.3371228 },
    { lat: 48.7011565, lng: 18.3367344 },
    { lat: 48.7009937, lng: 18.3365271 },
    { lat: 48.7006816, lng: 18.3362758 },
    { lat: 48.7002766, lng: 18.3358348 },
    { lat: 48.7000669, lng: 18.3351758 },
    { lat: 48.7006429, lng: 18.333845 },
    { lat: 48.7004538, lng: 18.3335736 },
    { lat: 48.7004287, lng: 18.3333881 },
    { lat: 48.7004225, lng: 18.3333389 },
    { lat: 48.7006656, lng: 18.3328688 },
    { lat: 48.7015871, lng: 18.3313828 },
    { lat: 48.7014563, lng: 18.3309824 },
    { lat: 48.7014451, lng: 18.3305245 },
    { lat: 48.701562, lng: 18.3297357 },
    { lat: 48.701681, lng: 18.3292651 },
    { lat: 48.701835, lng: 18.3288149 },
    { lat: 48.7019207, lng: 18.3284745 },
    { lat: 48.702183, lng: 18.3277653 },
    { lat: 48.7022059, lng: 18.3266088 },
    { lat: 48.7023522, lng: 18.3260433 },
    { lat: 48.7024603, lng: 18.3257192 },
    { lat: 48.7025588, lng: 18.3249262 },
    { lat: 48.7026925, lng: 18.3240371 },
    { lat: 48.7026916, lng: 18.3235816 },
    { lat: 48.7026042, lng: 18.3229328 },
    { lat: 48.7025528, lng: 18.3226027 },
    { lat: 48.7026933, lng: 18.3218138 },
    { lat: 48.7028215, lng: 18.3210872 },
    { lat: 48.7028609, lng: 18.3200262 },
    { lat: 48.7027765, lng: 18.3188516 },
    { lat: 48.7023988, lng: 18.3184554 },
    { lat: 48.702248, lng: 18.3182429 },
    { lat: 48.7016619, lng: 18.3180556 },
    { lat: 48.7015883, lng: 18.3179706 },
    { lat: 48.7013734, lng: 18.3177217 },
    { lat: 48.7016133, lng: 18.3175139 },
    { lat: 48.7019322, lng: 18.3171281 },
    { lat: 48.7021493, lng: 18.3166777 },
    { lat: 48.7028793, lng: 18.3158386 },
    { lat: 48.7031633, lng: 18.3155123 },
    { lat: 48.7041896, lng: 18.3145662 },
    { lat: 48.7043227, lng: 18.3142679 },
    { lat: 48.7048104, lng: 18.3137727 },
    { lat: 48.7049485, lng: 18.3136202 },
    { lat: 48.7059536, lng: 18.3126203 },
    { lat: 48.7068649, lng: 18.3117568 },
    { lat: 48.7076221, lng: 18.3110997 },
    { lat: 48.7087365, lng: 18.310874 },
    { lat: 48.7090126, lng: 18.3107901 },
    { lat: 48.7093455, lng: 18.3106307 },
    { lat: 48.7098058, lng: 18.3104115 },
    { lat: 48.7102426, lng: 18.3099527 },
    { lat: 48.7105695, lng: 18.3096088 },
    { lat: 48.711041, lng: 18.3090098 },
    { lat: 48.7110613, lng: 18.3088879 },
    { lat: 48.7110588, lng: 18.3078875 },
    { lat: 48.7111733, lng: 18.306921 },
    { lat: 48.711203, lng: 18.3066666 },
    { lat: 48.711286, lng: 18.3059612 },
    { lat: 48.7113206, lng: 18.3052968 },
    { lat: 48.711433, lng: 18.3047103 },
    { lat: 48.711468, lng: 18.3039956 },
    { lat: 48.7114539, lng: 18.3037848 },
    { lat: 48.7114345, lng: 18.3034822 },
    { lat: 48.7115999, lng: 18.3024975 },
    { lat: 48.7116055, lng: 18.3020997 },
    { lat: 48.7116071, lng: 18.3017086 },
    { lat: 48.7116124, lng: 18.3004279 },
    { lat: 48.7116128, lng: 18.300374 },
    { lat: 48.7116137, lng: 18.3003118 },
    { lat: 48.7116135, lng: 18.3002466 },
    { lat: 48.7116109, lng: 18.3001826 },
    { lat: 48.7115679, lng: 18.2990432 },
    { lat: 48.7115578, lng: 18.2987867 },
    { lat: 48.711555, lng: 18.2987154 },
    { lat: 48.7115532, lng: 18.2986446 },
    { lat: 48.7115513, lng: 18.2986102 },
    { lat: 48.7115725, lng: 18.2967755 },
    { lat: 48.711649, lng: 18.2959963 },
    { lat: 48.7118035, lng: 18.2952385 },
    { lat: 48.7125576, lng: 18.2940105 },
    { lat: 48.71308, lng: 18.2932144 },
    { lat: 48.7117109, lng: 18.29271 },
    { lat: 48.7104794, lng: 18.2926211 },
    { lat: 48.7103034, lng: 18.2917863 },
    { lat: 48.7102344, lng: 18.2913242 },
    { lat: 48.7101507, lng: 18.290764 },
    { lat: 48.71008, lng: 18.2901953 },
    { lat: 48.7099608, lng: 18.2892277 },
    { lat: 48.709868, lng: 18.2886069 },
    { lat: 48.7098093, lng: 18.2881286 },
    { lat: 48.7097552, lng: 18.2876514 },
    { lat: 48.7096754, lng: 18.2869206 },
    { lat: 48.7095319, lng: 18.2868195 },
    { lat: 48.7091179, lng: 18.2865824 },
    { lat: 48.7086446, lng: 18.2863529 },
    { lat: 48.708234, lng: 18.2860576 },
    { lat: 48.7078218, lng: 18.2858328 },
    { lat: 48.7073993, lng: 18.2855862 },
    { lat: 48.7065153, lng: 18.2851381 },
    { lat: 48.7060037, lng: 18.2849708 },
    { lat: 48.7054962, lng: 18.2847576 },
    { lat: 48.7048877, lng: 18.2844725 },
    { lat: 48.7048441, lng: 18.2844606 },
    { lat: 48.7046506, lng: 18.2844111 },
    { lat: 48.7040565, lng: 18.2841823 },
    { lat: 48.7035564, lng: 18.2840378 },
    { lat: 48.7027678, lng: 18.2845363 },
    { lat: 48.7024183, lng: 18.2840042 },
    { lat: 48.7021484, lng: 18.2836049 },
    { lat: 48.7019715, lng: 18.2832102 },
    { lat: 48.7018045, lng: 18.2828543 },
    { lat: 48.7016558, lng: 18.2822028 },
    { lat: 48.701746, lng: 18.2821609 },
    { lat: 48.7017341, lng: 18.2820521 },
    { lat: 48.7017019, lng: 18.2817621 },
    { lat: 48.7016269, lng: 18.281336 },
    { lat: 48.7015491, lng: 18.2807796 },
    { lat: 48.7015299, lng: 18.2801056 },
  ],
  ChudáLehota: [
    { lat: 48.630633, lng: 18.209574 },
    { lat: 48.630649, lng: 18.209588 },
    { lat: 48.631972, lng: 18.210823 },
    { lat: 48.632651, lng: 18.211462 },
    { lat: 48.63324, lng: 18.212004 },
    { lat: 48.633259, lng: 18.212022 },
    { lat: 48.634191, lng: 18.21288 },
    { lat: 48.63439, lng: 18.212601 },
    { lat: 48.634411, lng: 18.212571 },
    { lat: 48.635147, lng: 18.213926 },
    { lat: 48.63509, lng: 18.213988 },
    { lat: 48.634934, lng: 18.21416 },
    { lat: 48.635426, lng: 18.215121 },
    { lat: 48.635549, lng: 18.215453 },
    { lat: 48.635577, lng: 18.215307 },
    { lat: 48.635574, lng: 18.215214 },
    { lat: 48.635644, lng: 18.215178 },
    { lat: 48.635726, lng: 18.215189 },
    { lat: 48.635915, lng: 18.215041 },
    { lat: 48.635996, lng: 18.215 },
    { lat: 48.636088, lng: 18.214855 },
    { lat: 48.636153, lng: 18.214643 },
    { lat: 48.636172, lng: 18.214367 },
    { lat: 48.636217, lng: 18.214214 },
    { lat: 48.636254, lng: 18.214063 },
    { lat: 48.636316, lng: 18.213924 },
    { lat: 48.636363, lng: 18.213796 },
    { lat: 48.636418, lng: 18.213671 },
    { lat: 48.6365, lng: 18.213502 },
    { lat: 48.636623, lng: 18.213338 },
    { lat: 48.636672, lng: 18.213198 },
    { lat: 48.636736, lng: 18.21307 },
    { lat: 48.63677, lng: 18.212939 },
    { lat: 48.636885, lng: 18.212541 },
    { lat: 48.636958, lng: 18.212286 },
    { lat: 48.63703, lng: 18.212046 },
    { lat: 48.637103, lng: 18.2118 },
    { lat: 48.637158, lng: 18.211626 },
    { lat: 48.63723, lng: 18.211377 },
    { lat: 48.637304, lng: 18.211124 },
    { lat: 48.637374, lng: 18.210897 },
    { lat: 48.637465, lng: 18.210649 },
    { lat: 48.638924, lng: 18.211576 },
    { lat: 48.640005, lng: 18.212255 },
    { lat: 48.641021, lng: 18.212915 },
    { lat: 48.64226, lng: 18.213722 },
    { lat: 48.642289, lng: 18.213672 },
    { lat: 48.642316, lng: 18.213628 },
    { lat: 48.642378, lng: 18.213525 },
    { lat: 48.642651, lng: 18.213068 },
    { lat: 48.643029, lng: 18.212438 },
    { lat: 48.643047, lng: 18.212445 },
    { lat: 48.643118, lng: 18.212473 },
    { lat: 48.643137, lng: 18.212481 },
    { lat: 48.643314, lng: 18.212657 },
    { lat: 48.644052, lng: 18.213296 },
    { lat: 48.644719, lng: 18.213845 },
    { lat: 48.644875, lng: 18.213462 },
    { lat: 48.645153, lng: 18.213618 },
    { lat: 48.645364, lng: 18.213702 },
    { lat: 48.645406, lng: 18.213613 },
    { lat: 48.645578, lng: 18.213302 },
    { lat: 48.645899, lng: 18.212805 },
    { lat: 48.645958, lng: 18.212717 },
    { lat: 48.646091, lng: 18.212509 },
    { lat: 48.646131, lng: 18.212456 },
    { lat: 48.646528, lng: 18.211912 },
    { lat: 48.647355, lng: 18.210783 },
    { lat: 48.647641, lng: 18.210386 },
    { lat: 48.647888, lng: 18.21012 },
    { lat: 48.648298, lng: 18.209582 },
    { lat: 48.648385, lng: 18.209467 },
    { lat: 48.648422, lng: 18.209418 },
    { lat: 48.648934, lng: 18.208978 },
    { lat: 48.649511, lng: 18.208645 },
    { lat: 48.649658, lng: 18.208389 },
    { lat: 48.649705, lng: 18.208309 },
    { lat: 48.649777, lng: 18.20838 },
    { lat: 48.649865, lng: 18.208414 },
    { lat: 48.649971, lng: 18.208345 },
    { lat: 48.649998, lng: 18.208324 },
    { lat: 48.650031, lng: 18.208293 },
    { lat: 48.650157, lng: 18.208144 },
    { lat: 48.650201, lng: 18.208174 },
    { lat: 48.650284, lng: 18.208267 },
    { lat: 48.650347, lng: 18.208536 },
    { lat: 48.650393, lng: 18.208472 },
    { lat: 48.650467, lng: 18.20837 },
    { lat: 48.650569, lng: 18.208227 },
    { lat: 48.650629, lng: 18.208173 },
    { lat: 48.650686, lng: 18.208159 },
    { lat: 48.650768, lng: 18.208156 },
    { lat: 48.65095, lng: 18.208256 },
    { lat: 48.650978, lng: 18.20827 },
    { lat: 48.651021, lng: 18.208294 },
    { lat: 48.6510128, lng: 18.2082443 },
    { lat: 48.6510131, lng: 18.2077968 },
    { lat: 48.650985, lng: 18.2070749 },
    { lat: 48.6509445, lng: 18.2066256 },
    { lat: 48.6508938, lng: 18.2062157 },
    { lat: 48.6506858, lng: 18.2053055 },
    { lat: 48.650636, lng: 18.2051107 },
    { lat: 48.650575, lng: 18.2043219 },
    { lat: 48.6504818, lng: 18.204313 },
    { lat: 48.650572, lng: 18.2040928 },
    { lat: 48.6507924, lng: 18.203884 },
    { lat: 48.6508072, lng: 18.2038489 },
    { lat: 48.6508016, lng: 18.2037893 },
    { lat: 48.6507501, lng: 18.2036415 },
    { lat: 48.6508498, lng: 18.2034718 },
    { lat: 48.6508852, lng: 18.2033019 },
    { lat: 48.6510562, lng: 18.2029009 },
    { lat: 48.6510634, lng: 18.2023967 },
    { lat: 48.6509734, lng: 18.202128 },
    { lat: 48.6509013, lng: 18.2019765 },
    { lat: 48.6508552, lng: 18.2016839 },
    { lat: 48.6508579, lng: 18.2015922 },
    { lat: 48.6508994, lng: 18.2015699 },
    { lat: 48.6510633, lng: 18.2015734 },
    { lat: 48.6511521, lng: 18.2014904 },
    { lat: 48.6512259, lng: 18.2014001 },
    { lat: 48.6513707, lng: 18.2011516 },
    { lat: 48.6513997, lng: 18.2010675 },
    { lat: 48.6514668, lng: 18.2007008 },
    { lat: 48.6514883, lng: 18.200663 },
    { lat: 48.651553, lng: 18.2006124 },
    { lat: 48.6517202, lng: 18.2005394 },
    { lat: 48.6518601, lng: 18.2003986 },
    { lat: 48.6519515, lng: 18.2003431 },
    { lat: 48.6520762, lng: 18.2003696 },
    { lat: 48.6524293, lng: 18.2002719 },
    { lat: 48.6526423, lng: 18.2000908 },
    { lat: 48.652738, lng: 18.1999862 },
    { lat: 48.6527915, lng: 18.1997618 },
    { lat: 48.6528933, lng: 18.1995304 },
    { lat: 48.6529841, lng: 18.1994435 },
    { lat: 48.653046, lng: 18.1993398 },
    { lat: 48.653107, lng: 18.1992615 },
    { lat: 48.6532029, lng: 18.1992021 },
    { lat: 48.653368, lng: 18.1991905 },
    { lat: 48.6534112, lng: 18.1991562 },
    { lat: 48.6535177, lng: 18.1989688 },
    { lat: 48.6535976, lng: 18.1988466 },
    { lat: 48.6536133, lng: 18.1987496 },
    { lat: 48.6535943, lng: 18.1984202 },
    { lat: 48.6538151, lng: 18.198156 },
    { lat: 48.6539581, lng: 18.1979374 },
    { lat: 48.6539572, lng: 18.1977652 },
    { lat: 48.653925, lng: 18.1974781 },
    { lat: 48.6539449, lng: 18.1973546 },
    { lat: 48.6541354, lng: 18.1969798 },
    { lat: 48.6542558, lng: 18.1968369 },
    { lat: 48.6543095, lng: 18.1967881 },
    { lat: 48.6543822, lng: 18.1966594 },
    { lat: 48.6544166, lng: 18.1965499 },
    { lat: 48.6544447, lng: 18.1963935 },
    { lat: 48.6544553, lng: 18.1962818 },
    { lat: 48.6544447, lng: 18.1959427 },
    { lat: 48.6544751, lng: 18.1958045 },
    { lat: 48.654486, lng: 18.1957756 },
    { lat: 48.6545748, lng: 18.1956148 },
    { lat: 48.6545327, lng: 18.1951792 },
    { lat: 48.6545981, lng: 18.1950421 },
    { lat: 48.6545981, lng: 18.1949436 },
    { lat: 48.6545553, lng: 18.194845 },
    { lat: 48.6544869, lng: 18.1947166 },
    { lat: 48.6544451, lng: 18.1945589 },
    { lat: 48.6544348, lng: 18.1943629 },
    { lat: 48.6544788, lng: 18.1942037 },
    { lat: 48.65455, lng: 18.1941297 },
    { lat: 48.6546105, lng: 18.1941204 },
    { lat: 48.6547136, lng: 18.1941464 },
    { lat: 48.6548349, lng: 18.1942151 },
    { lat: 48.655014, lng: 18.1941934 },
    { lat: 48.6550474, lng: 18.1940462 },
    { lat: 48.6551056, lng: 18.193898 },
    { lat: 48.6553587, lng: 18.1937116 },
    { lat: 48.654905, lng: 18.1923014 },
    { lat: 48.6545534, lng: 18.1912323 },
    { lat: 48.6541932, lng: 18.1901118 },
    { lat: 48.6529853, lng: 18.1888119 },
    { lat: 48.6520876, lng: 18.187847 },
    { lat: 48.651695, lng: 18.18853 },
    { lat: 48.6513444, lng: 18.1881838 },
    { lat: 48.6512669, lng: 18.1880937 },
    { lat: 48.6499287, lng: 18.1865691 },
    { lat: 48.6496123, lng: 18.1861717 },
    { lat: 48.6488063, lng: 18.184994 },
    { lat: 48.6481744, lng: 18.1841735 },
    { lat: 48.6477661, lng: 18.1835717 },
    { lat: 48.6477183, lng: 18.1835269 },
    { lat: 48.6471968, lng: 18.1839723 },
    { lat: 48.6467714, lng: 18.1843441 },
    { lat: 48.646372, lng: 18.184497 },
    { lat: 48.645952, lng: 18.184729 },
    { lat: 48.645519, lng: 18.184908 },
    { lat: 48.644496, lng: 18.185286 },
    { lat: 48.643933, lng: 18.185482 },
    { lat: 48.64354, lng: 18.185656 },
    { lat: 48.643258, lng: 18.185863 },
    { lat: 48.64314, lng: 18.185977 },
    { lat: 48.643062, lng: 18.186065 },
    { lat: 48.642939, lng: 18.186301 },
    { lat: 48.642826, lng: 18.186502 },
    { lat: 48.642675, lng: 18.186832 },
    { lat: 48.642562, lng: 18.18711 },
    { lat: 48.642311, lng: 18.187537 },
    { lat: 48.642119, lng: 18.187792 },
    { lat: 48.641874, lng: 18.188066 },
    { lat: 48.641761, lng: 18.18817 },
    { lat: 48.641593, lng: 18.188281 },
    { lat: 48.641162, lng: 18.18866 },
    { lat: 48.640008, lng: 18.189693 },
    { lat: 48.639949, lng: 18.189802 },
    { lat: 48.639801, lng: 18.190146 },
    { lat: 48.639764, lng: 18.190227 },
    { lat: 48.639122, lng: 18.191627 },
    { lat: 48.639132, lng: 18.191637 },
    { lat: 48.639113, lng: 18.191924 },
    { lat: 48.639001, lng: 18.192167 },
    { lat: 48.638873, lng: 18.192493 },
    { lat: 48.638798, lng: 18.192642 },
    { lat: 48.638641, lng: 18.192768 },
    { lat: 48.638582, lng: 18.193065 },
    { lat: 48.638461, lng: 18.193386 },
    { lat: 48.638362, lng: 18.193549 },
    { lat: 48.638216, lng: 18.193821 },
    { lat: 48.638167, lng: 18.194037 },
    { lat: 48.638005, lng: 18.19434 },
    { lat: 48.638009, lng: 18.194401 },
    { lat: 48.638022, lng: 18.194478 },
    { lat: 48.638018, lng: 18.194588 },
    { lat: 48.638015, lng: 18.194698 },
    { lat: 48.638012, lng: 18.194769 },
    { lat: 48.637961, lng: 18.19508 },
    { lat: 48.637945, lng: 18.195308 },
    { lat: 48.637913, lng: 18.195548 },
    { lat: 48.637886, lng: 18.195741 },
    { lat: 48.637875, lng: 18.19616 },
    { lat: 48.637868, lng: 18.196705 },
    { lat: 48.637896, lng: 18.19727 },
    { lat: 48.637948, lng: 18.197508 },
    { lat: 48.637975, lng: 18.197914 },
    { lat: 48.638094, lng: 18.198398 },
    { lat: 48.638094, lng: 18.19863 },
    { lat: 48.63607, lng: 18.19911 },
    { lat: 48.636067, lng: 18.199099 },
    { lat: 48.635983, lng: 18.199102 },
    { lat: 48.633266, lng: 18.199217 },
    { lat: 48.633185, lng: 18.19922 },
    { lat: 48.632719, lng: 18.199209 },
    { lat: 48.632483, lng: 18.199728 },
    { lat: 48.632437, lng: 18.199831 },
    { lat: 48.632385, lng: 18.199951 },
    { lat: 48.632281, lng: 18.200184 },
    { lat: 48.632005, lng: 18.200807 },
    { lat: 48.631982, lng: 18.2009 },
    { lat: 48.631945, lng: 18.201049 },
    { lat: 48.631648, lng: 18.202247 },
    { lat: 48.631307, lng: 18.203873 },
    { lat: 48.631064, lng: 18.204999 },
    { lat: 48.630946, lng: 18.205741 },
    { lat: 48.630917, lng: 18.205928 },
    { lat: 48.630848, lng: 18.206403 },
    { lat: 48.630835, lng: 18.206399 },
    { lat: 48.630783, lng: 18.206708 },
    { lat: 48.630753, lng: 18.207539 },
    { lat: 48.630811, lng: 18.208041 },
    { lat: 48.630829, lng: 18.208249 },
    { lat: 48.630823, lng: 18.208438 },
    { lat: 48.630788, lng: 18.208592 },
    { lat: 48.630633, lng: 18.209574 },
  ],
  Cimenná: [
    { lat: 48.741512, lng: 18.111637 },
    { lat: 48.741347, lng: 18.111261 },
    { lat: 48.741223, lng: 18.11109 },
    { lat: 48.741199, lng: 18.110688 },
    { lat: 48.741315, lng: 18.110274 },
    { lat: 48.741278, lng: 18.109738 },
    { lat: 48.741243, lng: 18.109214 },
    { lat: 48.741156, lng: 18.109182 },
    { lat: 48.741146, lng: 18.108873 },
    { lat: 48.741147, lng: 18.108689 },
    { lat: 48.741204, lng: 18.107456 },
    { lat: 48.741296, lng: 18.107054 },
    { lat: 48.741337, lng: 18.106868 },
    { lat: 48.741311, lng: 18.106751 },
    { lat: 48.741286, lng: 18.106628 },
    { lat: 48.741206, lng: 18.106276 },
    { lat: 48.741202, lng: 18.106251 },
    { lat: 48.741189, lng: 18.106194 },
    { lat: 48.741186, lng: 18.106182 },
    { lat: 48.741158, lng: 18.106065 },
    { lat: 48.740755, lng: 18.104863 },
    { lat: 48.740438, lng: 18.104313 },
    { lat: 48.739128, lng: 18.103309 },
    { lat: 48.738357, lng: 18.10365 },
    { lat: 48.737935, lng: 18.103791 },
    { lat: 48.737461, lng: 18.104063 },
    { lat: 48.737455, lng: 18.104067 },
    { lat: 48.737432, lng: 18.10408 },
    { lat: 48.737426, lng: 18.104083 },
    { lat: 48.73742, lng: 18.104091 },
    { lat: 48.737063, lng: 18.104738 },
    { lat: 48.736693, lng: 18.105532 },
    { lat: 48.7367457, lng: 18.1062658 },
    { lat: 48.736759, lng: 18.1067248 },
    { lat: 48.7367659, lng: 18.107095 },
    { lat: 48.7367483, lng: 18.1073162 },
    { lat: 48.7366907, lng: 18.1075859 },
    { lat: 48.7365263, lng: 18.1082299 },
    { lat: 48.7364653, lng: 18.1081898 },
    { lat: 48.7361371, lng: 18.1088313 },
    { lat: 48.73569, lng: 18.1095794 },
    { lat: 48.7353852, lng: 18.1104101 },
    { lat: 48.7350128, lng: 18.1112241 },
    { lat: 48.7344867, lng: 18.112633 },
    { lat: 48.7342256, lng: 18.1144678 },
    { lat: 48.7338134, lng: 18.1158401 },
    { lat: 48.7335942, lng: 18.1165903 },
    { lat: 48.733318, lng: 18.1177768 },
    { lat: 48.7331174, lng: 18.1189249 },
    { lat: 48.7330647, lng: 18.1190846 },
    { lat: 48.7328478, lng: 18.1193562 },
    { lat: 48.7327374, lng: 18.119511 },
    { lat: 48.7317439, lng: 18.1207649 },
    { lat: 48.7315173, lng: 18.1211751 },
    { lat: 48.7312759, lng: 18.1215657 },
    { lat: 48.7309341, lng: 18.122073 },
    { lat: 48.7304629, lng: 18.1226429 },
    { lat: 48.7299713, lng: 18.1229814 },
    { lat: 48.7299035, lng: 18.1233479 },
    { lat: 48.7293631, lng: 18.1239895 },
    { lat: 48.7291776, lng: 18.1241378 },
    { lat: 48.7288636, lng: 18.1244855 },
    { lat: 48.7286578, lng: 18.1246698 },
    { lat: 48.7282443, lng: 18.1251691 },
    { lat: 48.7280588, lng: 18.1256627 },
    { lat: 48.7275492, lng: 18.1264675 },
    { lat: 48.7265866, lng: 18.1274779 },
    { lat: 48.7256935, lng: 18.1283053 },
    { lat: 48.7243421, lng: 18.1297709 },
    { lat: 48.7240024, lng: 18.1302731 },
    { lat: 48.7239682, lng: 18.1303277 },
    { lat: 48.7231301, lng: 18.1315763 },
    { lat: 48.7223688, lng: 18.1331574 },
    { lat: 48.7216854, lng: 18.1351284 },
    { lat: 48.7217256, lng: 18.1354504 },
    { lat: 48.7216724, lng: 18.1359303 },
    { lat: 48.7214596, lng: 18.1363671 },
    { lat: 48.7210918, lng: 18.1368372 },
    { lat: 48.720888, lng: 18.1373682 },
    { lat: 48.7206933, lng: 18.1382343 },
    { lat: 48.7203968, lng: 18.1398318 },
    { lat: 48.7201215, lng: 18.1411753 },
    { lat: 48.7200774, lng: 18.141659 },
    { lat: 48.7201013, lng: 18.1420132 },
    { lat: 48.7201666, lng: 18.1424988 },
    { lat: 48.7200349, lng: 18.1433965 },
    { lat: 48.7197666, lng: 18.143907 },
    { lat: 48.7195387, lng: 18.1441242 },
    { lat: 48.7192451, lng: 18.1443903 },
    { lat: 48.7188409, lng: 18.1447405 },
    { lat: 48.7183381, lng: 18.1452942 },
    { lat: 48.7183179, lng: 18.1452915 },
    { lat: 48.7182361, lng: 18.1454017 },
    { lat: 48.7181497, lng: 18.1455851 },
    { lat: 48.7180625, lng: 18.1458336 },
    { lat: 48.7180634, lng: 18.1459063 },
    { lat: 48.7180183, lng: 18.146042 },
    { lat: 48.7178443, lng: 18.1464464 },
    { lat: 48.7176237, lng: 18.1468877 },
    { lat: 48.717473, lng: 18.1472664 },
    { lat: 48.7174777, lng: 18.1473844 },
    { lat: 48.7173416, lng: 18.1477809 },
    { lat: 48.7170736, lng: 18.1485308 },
    { lat: 48.7165112, lng: 18.1503167 },
    { lat: 48.716189, lng: 18.1511634 },
    { lat: 48.7159282, lng: 18.1517622 },
    { lat: 48.7157503, lng: 18.1522592 },
    { lat: 48.7166409, lng: 18.1526895 },
    { lat: 48.7177089, lng: 18.1532412 },
    { lat: 48.7193465, lng: 18.1540266 },
    { lat: 48.7206388, lng: 18.1546341 },
    { lat: 48.7212551, lng: 18.1549657 },
    { lat: 48.7219057, lng: 18.1552689 },
    { lat: 48.7219745, lng: 18.155347 },
    { lat: 48.7225165, lng: 18.1556087 },
    { lat: 48.7227211, lng: 18.1557282 },
    { lat: 48.7228674, lng: 18.1558575 },
    { lat: 48.7230593, lng: 18.1559572 },
    { lat: 48.7232224, lng: 18.1559893 },
    { lat: 48.7234077, lng: 18.1560119 },
    { lat: 48.7233782, lng: 18.156359 },
    { lat: 48.723524, lng: 18.1564019 },
    { lat: 48.7236591, lng: 18.1561431 },
    { lat: 48.7239465, lng: 18.1559232 },
    { lat: 48.7242345, lng: 18.1558504 },
    { lat: 48.7243681, lng: 18.1557391 },
    { lat: 48.724552, lng: 18.1556976 },
    { lat: 48.725118, lng: 18.155458 },
    { lat: 48.7254384, lng: 18.1554535 },
    { lat: 48.7255878, lng: 18.1556214 },
    { lat: 48.725763, lng: 18.155624 },
    { lat: 48.7260563, lng: 18.1545315 },
    { lat: 48.7261367, lng: 18.1542256 },
    { lat: 48.7262284, lng: 18.1542849 },
    { lat: 48.7264342, lng: 18.1543306 },
    { lat: 48.7264965, lng: 18.1543177 },
    { lat: 48.7267907, lng: 18.1541955 },
    { lat: 48.7269912, lng: 18.1541897 },
    { lat: 48.7273497, lng: 18.1542227 },
    { lat: 48.7278275, lng: 18.1542703 },
    { lat: 48.7280434, lng: 18.1542947 },
    { lat: 48.7284979, lng: 18.1543889 },
    { lat: 48.7287308, lng: 18.1544532 },
    { lat: 48.7289901, lng: 18.1545458 },
    { lat: 48.7294769, lng: 18.1547895 },
    { lat: 48.7298961, lng: 18.1549307 },
    { lat: 48.730033, lng: 18.1537094 },
    { lat: 48.7300754, lng: 18.1529441 },
    { lat: 48.7300567, lng: 18.152212 },
    { lat: 48.7299493, lng: 18.1518566 },
    { lat: 48.7299162, lng: 18.1515997 },
    { lat: 48.7298352, lng: 18.151194 },
    { lat: 48.7297558, lng: 18.1507781 },
    { lat: 48.7296939, lng: 18.1504885 },
    { lat: 48.7296685, lng: 18.1500665 },
    { lat: 48.7295344, lng: 18.1493318 },
    { lat: 48.7295168, lng: 18.1483833 },
    { lat: 48.729567, lng: 18.1477725 },
    { lat: 48.7296719, lng: 18.1467803 },
    { lat: 48.7299476, lng: 18.1457726 },
    { lat: 48.7300679, lng: 18.1452008 },
    { lat: 48.7301862, lng: 18.1446707 },
    { lat: 48.7302361, lng: 18.1444232 },
    { lat: 48.7302609, lng: 18.1442676 },
    { lat: 48.7303356, lng: 18.143724 },
    { lat: 48.730419, lng: 18.1434338 },
    { lat: 48.7305884, lng: 18.1430067 },
    { lat: 48.7309767, lng: 18.1421709 },
    { lat: 48.7316032, lng: 18.1408718 },
    { lat: 48.7318131, lng: 18.1403091 },
    { lat: 48.732064, lng: 18.1392824 },
    { lat: 48.7321736, lng: 18.13882 },
    { lat: 48.7322038, lng: 18.1384839 },
    { lat: 48.7322251, lng: 18.1381293 },
    { lat: 48.732261, lng: 18.1366714 },
    { lat: 48.7323132, lng: 18.1363241 },
    { lat: 48.7323885, lng: 18.1356771 },
    { lat: 48.7326124, lng: 18.1352409 },
    { lat: 48.7328778, lng: 18.1342123 },
    { lat: 48.7329739, lng: 18.1339822 },
    { lat: 48.7331341, lng: 18.1337103 },
    { lat: 48.7334513, lng: 18.1329143 },
    { lat: 48.7335882, lng: 18.1325522 },
    { lat: 48.7336986, lng: 18.1322944 },
    { lat: 48.7338223, lng: 18.1320953 },
    { lat: 48.7339257, lng: 18.1319504 },
    { lat: 48.7339711, lng: 18.1318403 },
    { lat: 48.7339995, lng: 18.1317061 },
    { lat: 48.7340589, lng: 18.1313496 },
    { lat: 48.7341281, lng: 18.1311034 },
    { lat: 48.7341814, lng: 18.1309909 },
    { lat: 48.7343196, lng: 18.130728 },
    { lat: 48.7345047, lng: 18.1303599 },
    { lat: 48.7345916, lng: 18.1301471 },
    { lat: 48.7347253, lng: 18.1296313 },
    { lat: 48.7348008, lng: 18.1291812 },
    { lat: 48.7350163, lng: 18.1287747 },
    { lat: 48.7353658, lng: 18.1280712 },
    { lat: 48.7355051, lng: 18.1274163 },
    { lat: 48.7356977, lng: 18.1266939 },
    { lat: 48.7358412, lng: 18.1254336 },
    { lat: 48.7358752, lng: 18.1251845 },
    { lat: 48.7363589, lng: 18.1237796 },
    { lat: 48.73652, lng: 18.1233084 },
    { lat: 48.7365207, lng: 18.1233044 },
    { lat: 48.7368126, lng: 18.1224637 },
    { lat: 48.7369371, lng: 18.1219097 },
    { lat: 48.7370065, lng: 18.121415 },
    { lat: 48.7370861, lng: 18.1200483 },
    { lat: 48.737089, lng: 18.1194977 },
    { lat: 48.7370965, lng: 18.11885 },
    { lat: 48.7371811, lng: 18.1179796 },
    { lat: 48.737259, lng: 18.1174365 },
    { lat: 48.7375058, lng: 18.1167122 },
    { lat: 48.7375038, lng: 18.1161748 },
    { lat: 48.7375955, lng: 18.1159102 },
    { lat: 48.7379398, lng: 18.1153488 },
    { lat: 48.7383374, lng: 18.1149476 },
    { lat: 48.7388227, lng: 18.1143271 },
    { lat: 48.7392626, lng: 18.113626 },
    { lat: 48.7394354, lng: 18.1132222 },
    { lat: 48.7397209, lng: 18.1128545 },
    { lat: 48.7398078, lng: 18.1126613 },
    { lat: 48.7401738, lng: 18.1123763 },
    { lat: 48.7410833, lng: 18.1117352 },
    { lat: 48.7413848, lng: 18.1116976 },
    { lat: 48.741512, lng: 18.111637 },
  ],
  DolnéNaštice: [
    { lat: 48.700645, lng: 18.2715101 },
    { lat: 48.7004786, lng: 18.2715612 },
    { lat: 48.6998933, lng: 18.2718231 },
    { lat: 48.699795, lng: 18.2712848 },
    { lat: 48.699657, lng: 18.2705269 },
    { lat: 48.6995439, lng: 18.2697488 },
    { lat: 48.6993983, lng: 18.2688167 },
    { lat: 48.6993505, lng: 18.2682146 },
    { lat: 48.6993351, lng: 18.2678251 },
    { lat: 48.6993127, lng: 18.2669954 },
    { lat: 48.699157, lng: 18.2661838 },
    { lat: 48.6989589, lng: 18.2656759 },
    { lat: 48.6989575, lng: 18.2656732 },
    { lat: 48.6989475, lng: 18.2656356 },
    { lat: 48.6989075, lng: 18.2654879 },
    { lat: 48.6988861, lng: 18.2654093 },
    { lat: 48.6988047, lng: 18.2652673 },
    { lat: 48.6985009, lng: 18.2656793 },
    { lat: 48.6981029, lng: 18.2661008 },
    { lat: 48.6976928, lng: 18.2664863 },
    { lat: 48.697481, lng: 18.2666316 },
    { lat: 48.6974669, lng: 18.2666412 },
    { lat: 48.6974881, lng: 18.2659046 },
    { lat: 48.6974874, lng: 18.2650607 },
    { lat: 48.697541, lng: 18.2641004 },
    { lat: 48.6976252, lng: 18.2631813 },
    { lat: 48.6977412, lng: 18.2626826 },
    { lat: 48.6978526, lng: 18.2623226 },
    { lat: 48.6980124, lng: 18.2620553 },
    { lat: 48.6982094, lng: 18.262029 },
    { lat: 48.6982196, lng: 18.2619914 },
    { lat: 48.6983937, lng: 18.2612649 },
    { lat: 48.6985167, lng: 18.2605308 },
    { lat: 48.6986004, lng: 18.2596437 },
    { lat: 48.6987172, lng: 18.2585461 },
    { lat: 48.6987205, lng: 18.2585049 },
    { lat: 48.69874, lng: 18.2582334 },
    { lat: 48.6987762, lng: 18.2577408 },
    { lat: 48.6988278, lng: 18.2574787 },
    { lat: 48.6989899, lng: 18.2574112 },
    { lat: 48.6989034, lng: 18.256727 },
    { lat: 48.6991706, lng: 18.2566224 },
    { lat: 48.6992407, lng: 18.2559723 },
    { lat: 48.6993956, lng: 18.255287 },
    { lat: 48.6994048, lng: 18.2552483 },
    { lat: 48.699518, lng: 18.2546245 },
    { lat: 48.6996149, lng: 18.2539082 },
    { lat: 48.6996499, lng: 18.2535526 },
    { lat: 48.6996694, lng: 18.2529071 },
    { lat: 48.6996293, lng: 18.2521854 },
    { lat: 48.7003685, lng: 18.252107 },
    { lat: 48.7006756, lng: 18.2520895 },
    { lat: 48.7010399, lng: 18.2520684 },
    { lat: 48.7010939, lng: 18.2520653 },
    { lat: 48.701095, lng: 18.2520652 },
    { lat: 48.7011142, lng: 18.2520648 },
    { lat: 48.7011195, lng: 18.2518612 },
    { lat: 48.7011186, lng: 18.2518267 },
    { lat: 48.7011156, lng: 18.2517935 },
    { lat: 48.700967, lng: 18.2510083 },
    { lat: 48.7009494, lng: 18.2509175 },
    { lat: 48.700891, lng: 18.2506069 },
    { lat: 48.7008823, lng: 18.2505723 },
    { lat: 48.7008832, lng: 18.2505675 },
    { lat: 48.7008995, lng: 18.2504784 },
    { lat: 48.7009028, lng: 18.2504583 },
    { lat: 48.7006937, lng: 18.2488522 },
    { lat: 48.7007247, lng: 18.24888 },
    { lat: 48.7006892, lng: 18.2487128 },
    { lat: 48.7007554, lng: 18.2485295 },
    { lat: 48.7007107, lng: 18.2483773 },
    { lat: 48.700667, lng: 18.2483042 },
    { lat: 48.7001262, lng: 18.2479425 },
    { lat: 48.7001108, lng: 18.24795 },
    { lat: 48.7000304, lng: 18.2477292 },
    { lat: 48.6997765, lng: 18.2473319 },
    { lat: 48.6997387, lng: 18.2469482 },
    { lat: 48.6997138, lng: 18.2468073 },
    { lat: 48.6997007, lng: 18.2467361 },
    { lat: 48.6996959, lng: 18.2467119 },
    { lat: 48.6996942, lng: 18.2466214 },
    { lat: 48.699653, lng: 18.2464948 },
    { lat: 48.6995302, lng: 18.2462802 },
    { lat: 48.6995018, lng: 18.2461979 },
    { lat: 48.6994283, lng: 18.2459774 },
    { lat: 48.6994023, lng: 18.2457595 },
    { lat: 48.6993528, lng: 18.2455649 },
    { lat: 48.6991878, lng: 18.2456587 },
    { lat: 48.6991791, lng: 18.2456638 },
    { lat: 48.6991627, lng: 18.2456727 },
    { lat: 48.6991435, lng: 18.2456527 },
    { lat: 48.6991214, lng: 18.2456224 },
    { lat: 48.6990521, lng: 18.2454353 },
    { lat: 48.6988914, lng: 18.2443615 },
    { lat: 48.6988858, lng: 18.244324 },
    { lat: 48.6988315, lng: 18.2439471 },
    { lat: 48.6986436, lng: 18.2426535 },
    { lat: 48.6984272, lng: 18.2417275 },
    { lat: 48.6983731, lng: 18.2414929 },
    { lat: 48.6982163, lng: 18.2408226 },
    { lat: 48.698215, lng: 18.2408199 },
    { lat: 48.6981776, lng: 18.2406982 },
    { lat: 48.6986708, lng: 18.240485 },
    { lat: 48.6988334, lng: 18.2404463 },
    { lat: 48.6988693, lng: 18.240423 },
    { lat: 48.698954, lng: 18.2403659 },
    { lat: 48.6990547, lng: 18.2401908 },
    { lat: 48.6992409, lng: 18.240054 },
    { lat: 48.6992682, lng: 18.2400384 },
    { lat: 48.6992399, lng: 18.2398982 },
    { lat: 48.6992383, lng: 18.2398895 },
    { lat: 48.6992313, lng: 18.2398656 },
    { lat: 48.6985729, lng: 18.2380435 },
    { lat: 48.6985301, lng: 18.2380423 },
    { lat: 48.6978553, lng: 18.2374445 },
    { lat: 48.6975876, lng: 18.237283 },
    { lat: 48.6972487, lng: 18.2371948 },
    { lat: 48.6968779, lng: 18.2370649 },
    { lat: 48.696523, lng: 18.2368966 },
    { lat: 48.6963022, lng: 18.2368226 },
    { lat: 48.6954822, lng: 18.2367114 },
    { lat: 48.6950911, lng: 18.2358113 },
    { lat: 48.6946675, lng: 18.2359234 },
    { lat: 48.6939729, lng: 18.2362477 },
    { lat: 48.6934278, lng: 18.2364828 },
    { lat: 48.6930048, lng: 18.2367223 },
    { lat: 48.6929762, lng: 18.236735 },
    { lat: 48.6924498, lng: 18.2371752 },
    { lat: 48.6922156, lng: 18.2373222 },
    { lat: 48.6920513, lng: 18.2373886 },
    { lat: 48.6917605, lng: 18.2374559 },
    { lat: 48.6914096, lng: 18.237497 },
    { lat: 48.6912806, lng: 18.2375081 },
    { lat: 48.6911696, lng: 18.2375019 },
    { lat: 48.6908714, lng: 18.2374387 },
    { lat: 48.6907304, lng: 18.2373996 },
    { lat: 48.6903794, lng: 18.2373197 },
    { lat: 48.6902498, lng: 18.237302 },
    { lat: 48.690106, lng: 18.2373111 },
    { lat: 48.6899127, lng: 18.2373667 },
    { lat: 48.6896762, lng: 18.2374526 },
    { lat: 48.6895748, lng: 18.2375151 },
    { lat: 48.689254, lng: 18.2377845 },
    { lat: 48.6890264, lng: 18.2380045 },
    { lat: 48.6888528, lng: 18.2381664 },
    { lat: 48.6886381, lng: 18.2383388 },
    { lat: 48.6884479, lng: 18.2385075 },
    { lat: 48.6882616, lng: 18.2387025 },
    { lat: 48.6880456, lng: 18.2388518 },
    { lat: 48.6872231, lng: 18.2390277 },
    { lat: 48.6864248, lng: 18.2391597 },
    { lat: 48.686309, lng: 18.2388884 },
    { lat: 48.6862275, lng: 18.238864 },
    { lat: 48.6854066, lng: 18.2386005 },
    { lat: 48.6852179, lng: 18.2385755 },
    { lat: 48.6849825, lng: 18.238523 },
    { lat: 48.6844521, lng: 18.2383819 },
    { lat: 48.6839454, lng: 18.238283 },
    { lat: 48.683353, lng: 18.2381464 },
    { lat: 48.6824562, lng: 18.2360392 },
    { lat: 48.6818968, lng: 18.2363311 },
    { lat: 48.6809861, lng: 18.2367942 },
    { lat: 48.6804228, lng: 18.2370859 },
    { lat: 48.680241, lng: 18.237476 },
    { lat: 48.6801341, lng: 18.2377188 },
    { lat: 48.6797142, lng: 18.2383271 },
    { lat: 48.6794431, lng: 18.2394861 },
    { lat: 48.6795362, lng: 18.240303 },
    { lat: 48.6796831, lng: 18.24161 },
    { lat: 48.6797624, lng: 18.2418525 },
    { lat: 48.6798662, lng: 18.2421492 },
    { lat: 48.67997, lng: 18.242542 },
    { lat: 48.680057, lng: 18.2429795 },
    { lat: 48.6806033, lng: 18.24328 },
    { lat: 48.6811693, lng: 18.2437246 },
    { lat: 48.6816053, lng: 18.2444812 },
    { lat: 48.6820321, lng: 18.2454336 },
    { lat: 48.6824269, lng: 18.2460038 },
    { lat: 48.682915, lng: 18.2466664 },
    { lat: 48.6830956, lng: 18.247025 },
    { lat: 48.6832762, lng: 18.2474014 },
    { lat: 48.6835869, lng: 18.2482747 },
    { lat: 48.6838825, lng: 18.2485739 },
    { lat: 48.6840124, lng: 18.2488309 },
    { lat: 48.684145, lng: 18.2491172 },
    { lat: 48.6843304, lng: 18.2494976 },
    { lat: 48.6844852, lng: 18.2498395 },
    { lat: 48.684635, lng: 18.2499918 },
    { lat: 48.6846699, lng: 18.2500511 },
    { lat: 48.6848335, lng: 18.2501091 },
    { lat: 48.6848956, lng: 18.2501699 },
    { lat: 48.6850356, lng: 18.2502481 },
    { lat: 48.6851592, lng: 18.250182 },
    { lat: 48.685201, lng: 18.2499862 },
    { lat: 48.6851625, lng: 18.2498463 },
    { lat: 48.6850449, lng: 18.2497416 },
    { lat: 48.6849796, lng: 18.2493305 },
    { lat: 48.685059, lng: 18.2491391 },
    { lat: 48.6851203, lng: 18.2490658 },
    { lat: 48.6854645, lng: 18.2493052 },
    { lat: 48.6854993, lng: 18.2493385 },
    { lat: 48.6856591, lng: 18.2491766 },
    { lat: 48.6857053, lng: 18.249016 },
    { lat: 48.6856936, lng: 18.2489482 },
    { lat: 48.6856691, lng: 18.2484826 },
    { lat: 48.6857276, lng: 18.2483603 },
    { lat: 48.6858554, lng: 18.2482676 },
    { lat: 48.6860036, lng: 18.2481915 },
    { lat: 48.6860729, lng: 18.2482418 },
    { lat: 48.6860906, lng: 18.2482937 },
    { lat: 48.6860601, lng: 18.2485677 },
    { lat: 48.6860897, lng: 18.2489288 },
    { lat: 48.6861734, lng: 18.2490321 },
    { lat: 48.6862538, lng: 18.2490367 },
    { lat: 48.6864393, lng: 18.2489268 },
    { lat: 48.6867241, lng: 18.2489954 },
    { lat: 48.6873815, lng: 18.2509003 },
    { lat: 48.687511, lng: 18.2513144 },
    { lat: 48.6876063, lng: 18.2515988 },
    { lat: 48.6876076, lng: 18.2516007 },
    { lat: 48.6879305, lng: 18.252515 },
    { lat: 48.6882658, lng: 18.2534477 },
    { lat: 48.6883003, lng: 18.2535389 },
    { lat: 48.6883029, lng: 18.2535432 },
    { lat: 48.6884413, lng: 18.253912 },
    { lat: 48.6888469, lng: 18.255128 },
    { lat: 48.6887346, lng: 18.2555926 },
    { lat: 48.6889325, lng: 18.2562304 },
    { lat: 48.6890091, lng: 18.2564257 },
    { lat: 48.6892393, lng: 18.2568415 },
    { lat: 48.6894601, lng: 18.2571141 },
    { lat: 48.6895824, lng: 18.2572986 },
    { lat: 48.6897143, lng: 18.257576 },
    { lat: 48.6897836, lng: 18.2577788 },
    { lat: 48.6901428, lng: 18.2593873 },
    { lat: 48.6901086, lng: 18.2605888 },
    { lat: 48.6902675, lng: 18.2618535 },
    { lat: 48.6903755, lng: 18.2626205 },
    { lat: 48.6904073, lng: 18.2641287 },
    { lat: 48.6904198, lng: 18.2649814 },
    { lat: 48.6902219, lng: 18.2656096 },
    { lat: 48.690072, lng: 18.2662033 },
    { lat: 48.6898765, lng: 18.2668558 },
    { lat: 48.689622, lng: 18.2676924 },
    { lat: 48.6894218, lng: 18.2683573 },
    { lat: 48.6891276, lng: 18.269274 },
    { lat: 48.6887657, lng: 18.2702302 },
    { lat: 48.6881053, lng: 18.2718128 },
    { lat: 48.6877121, lng: 18.2726782 },
    { lat: 48.6873769, lng: 18.273664 },
    { lat: 48.6873894, lng: 18.2736872 },
    { lat: 48.6873098, lng: 18.2746292 },
    { lat: 48.6873263, lng: 18.2746402 },
    { lat: 48.6873611, lng: 18.2749735 },
    { lat: 48.6874163, lng: 18.2752114 },
    { lat: 48.6875928, lng: 18.2759126 },
    { lat: 48.6878138, lng: 18.2765853 },
    { lat: 48.687906, lng: 18.2768376 },
    { lat: 48.6879327, lng: 18.2769288 },
    { lat: 48.6883424, lng: 18.2782626 },
    { lat: 48.6883794, lng: 18.2783767 },
    { lat: 48.6885565, lng: 18.2789712 },
    { lat: 48.6887111, lng: 18.2793082 },
    { lat: 48.68898, lng: 18.2797333 },
    { lat: 48.6892708, lng: 18.2801252 },
    { lat: 48.6895351, lng: 18.2805648 },
    { lat: 48.6896756, lng: 18.2808533 },
    { lat: 48.689684, lng: 18.2808423 },
    { lat: 48.6902032, lng: 18.2819563 },
    { lat: 48.6912042, lng: 18.2834289 },
    { lat: 48.6912885, lng: 18.2834437 },
    { lat: 48.6913145, lng: 18.2834257 },
    { lat: 48.6916854, lng: 18.2833008 },
    { lat: 48.692791, lng: 18.282901 },
    { lat: 48.6936316, lng: 18.282761 },
    { lat: 48.6946317, lng: 18.2826681 },
    { lat: 48.6949076, lng: 18.2826381 },
    { lat: 48.6951804, lng: 18.2825959 },
    { lat: 48.695387, lng: 18.2824377 },
    { lat: 48.6956504, lng: 18.2823489 },
    { lat: 48.6962372, lng: 18.2822914 },
    { lat: 48.6965441, lng: 18.2822112 },
    { lat: 48.6973546, lng: 18.2820576 },
    { lat: 48.6973823, lng: 18.282049 },
    { lat: 48.6976745, lng: 18.2818907 },
    { lat: 48.697787, lng: 18.2817826 },
    { lat: 48.6979368, lng: 18.2816803 },
    { lat: 48.698166, lng: 18.2816027 },
    { lat: 48.6985359, lng: 18.2815219 },
    { lat: 48.6987681, lng: 18.2814543 },
    { lat: 48.6989008, lng: 18.2814103 },
    { lat: 48.6994231, lng: 18.2812057 },
    { lat: 48.6995524, lng: 18.2811607 },
    { lat: 48.7003067, lng: 18.2807161 },
    { lat: 48.7003912, lng: 18.2806923 },
    { lat: 48.7006473, lng: 18.2805723 },
    { lat: 48.701483, lng: 18.2801881 },
    { lat: 48.7015299, lng: 18.2801056 },
    { lat: 48.7014103, lng: 18.2796088 },
    { lat: 48.7012985, lng: 18.2789729 },
    { lat: 48.7012483, lng: 18.2785277 },
    { lat: 48.7011909, lng: 18.2781914 },
    { lat: 48.70116, lng: 18.2777257 },
    { lat: 48.7010789, lng: 18.2770936 },
    { lat: 48.7008111, lng: 18.2752631 },
    { lat: 48.7008146, lng: 18.2751851 },
    { lat: 48.7007168, lng: 18.2747358 },
    { lat: 48.700662, lng: 18.2744062 },
    { lat: 48.700605, lng: 18.2740787 },
    { lat: 48.7005622, lng: 18.2737637 },
    { lat: 48.700562, lng: 18.2737591 },
    { lat: 48.7004079, lng: 18.2728156 },
    { lat: 48.7005774, lng: 18.2722455 },
    { lat: 48.7006229, lng: 18.2719934 },
    { lat: 48.700645, lng: 18.2715101 },
  ],
  Dubnička: [
    { lat: 48.8029561, lng: 18.3165383 },
    { lat: 48.8023335, lng: 18.3151611 },
    { lat: 48.8018239, lng: 18.3141876 },
    { lat: 48.8010901, lng: 18.3134586 },
    { lat: 48.8005047, lng: 18.3126031 },
    { lat: 48.7997116, lng: 18.3120232 },
    { lat: 48.799657, lng: 18.3119769 },
    { lat: 48.7995289, lng: 18.3119023 },
    { lat: 48.7975676, lng: 18.3096 },
    { lat: 48.7970551, lng: 18.3090744 },
    { lat: 48.7965252, lng: 18.3087221 },
    { lat: 48.7962545, lng: 18.3081853 },
    { lat: 48.7961231, lng: 18.3077768 },
    { lat: 48.7956024, lng: 18.3071843 },
    { lat: 48.7950849, lng: 18.3071318 },
    { lat: 48.7944448, lng: 18.3070085 },
    { lat: 48.794085, lng: 18.3066492 },
    { lat: 48.7937702, lng: 18.3055496 },
    { lat: 48.7938301, lng: 18.304372 },
    { lat: 48.7934581, lng: 18.3034207 },
    { lat: 48.7932545, lng: 18.3030939 },
    { lat: 48.7924317, lng: 18.3025868 },
    { lat: 48.7915456, lng: 18.3024203 },
    { lat: 48.7912508, lng: 18.3021142 },
    { lat: 48.7906861, lng: 18.30113 },
    { lat: 48.790535, lng: 18.3010086 },
    { lat: 48.7904539, lng: 18.3009325 },
    { lat: 48.7902287, lng: 18.3007005 },
    { lat: 48.7901902, lng: 18.3006603 },
    { lat: 48.7901248, lng: 18.3005983 },
    { lat: 48.7897491, lng: 18.3005032 },
    { lat: 48.7886616, lng: 18.300224 },
    { lat: 48.7881067, lng: 18.2999896 },
    { lat: 48.7879011, lng: 18.2998441 },
    { lat: 48.7871701, lng: 18.2998247 },
    { lat: 48.7865639, lng: 18.2994227 },
    { lat: 48.7864569, lng: 18.299404 },
    { lat: 48.786285, lng: 18.2993794 },
    { lat: 48.7861782, lng: 18.2993639 },
    { lat: 48.7849789, lng: 18.2975514 },
    { lat: 48.7833322, lng: 18.2965659 },
    { lat: 48.7827961, lng: 18.2963789 },
    { lat: 48.7813069, lng: 18.2957662 },
    { lat: 48.7806735, lng: 18.2958165 },
    { lat: 48.7800119, lng: 18.295869 },
    { lat: 48.7789488, lng: 18.2956848 },
    { lat: 48.777871, lng: 18.2957393 },
    { lat: 48.7769041, lng: 18.2951442 },
    { lat: 48.776504, lng: 18.2949936 },
    { lat: 48.7760341, lng: 18.2950037 },
    { lat: 48.7754437, lng: 18.2948359 },
    { lat: 48.7752823, lng: 18.2949165 },
    { lat: 48.7746426, lng: 18.295595 },
    { lat: 48.774105, lng: 18.2957828 },
    { lat: 48.7738695, lng: 18.295829 },
    { lat: 48.7736359, lng: 18.2957484 },
    { lat: 48.7736533, lng: 18.2956767 },
    { lat: 48.7735228, lng: 18.2955424 },
    { lat: 48.7728355, lng: 18.2941266 },
    { lat: 48.7724642, lng: 18.2936071 },
    { lat: 48.7720219, lng: 18.2932459 },
    { lat: 48.7719509, lng: 18.2931904 },
    { lat: 48.7711095, lng: 18.2925365 },
    { lat: 48.7703424, lng: 18.2918616 },
    { lat: 48.7691538, lng: 18.2904369 },
    { lat: 48.7690007, lng: 18.2900261 },
    { lat: 48.7689744, lng: 18.2899208 },
    { lat: 48.7689743, lng: 18.2898772 },
    { lat: 48.7689605, lng: 18.2896962 },
    { lat: 48.7688975, lng: 18.2889433 },
    { lat: 48.7686834, lng: 18.2872571 },
    { lat: 48.7686098, lng: 18.2868141 },
    { lat: 48.7685742, lng: 18.2866017 },
    { lat: 48.7685255, lng: 18.2865018 },
    { lat: 48.7679901, lng: 18.2855326 },
    { lat: 48.767259, lng: 18.2846611 },
    { lat: 48.7667601, lng: 18.2845763 },
    { lat: 48.7661044, lng: 18.2838957 },
    { lat: 48.7660661, lng: 18.2839164 },
    { lat: 48.7658817, lng: 18.2837915 },
    { lat: 48.7657692, lng: 18.2837765 },
    { lat: 48.7655262, lng: 18.2838273 },
    { lat: 48.7645399, lng: 18.2842633 },
    { lat: 48.7638406, lng: 18.2845064 },
    { lat: 48.7631048, lng: 18.2846427 },
    { lat: 48.7628928, lng: 18.2847063 },
    { lat: 48.7626707, lng: 18.2848256 },
    { lat: 48.7624929, lng: 18.2848169 },
    { lat: 48.7622879, lng: 18.2848624 },
    { lat: 48.7618618, lng: 18.2852088 },
    { lat: 48.7613363, lng: 18.2854894 },
    { lat: 48.7611413, lng: 18.2856321 },
    { lat: 48.7609481, lng: 18.2857276 },
    { lat: 48.7606968, lng: 18.2858117 },
    { lat: 48.760512, lng: 18.2858385 },
    { lat: 48.7605132, lng: 18.2858173 },
    { lat: 48.7603778, lng: 18.2857969 },
    { lat: 48.7600761, lng: 18.2858314 },
    { lat: 48.7597105, lng: 18.2859497 },
    { lat: 48.7590178, lng: 18.2862105 },
    { lat: 48.7585414, lng: 18.2864439 },
    { lat: 48.7580215, lng: 18.2866855 },
    { lat: 48.7573614, lng: 18.2869439 },
    { lat: 48.7569895, lng: 18.2870526 },
    { lat: 48.7561905, lng: 18.2872892 },
    { lat: 48.7559627, lng: 18.2874082 },
    { lat: 48.7556313, lng: 18.287714 },
    { lat: 48.755603, lng: 18.2877699 },
    { lat: 48.7555714, lng: 18.2878294 },
    { lat: 48.7555397, lng: 18.2878664 },
    { lat: 48.7553127, lng: 18.2881236 },
    { lat: 48.7553254, lng: 18.288206 },
    { lat: 48.7553411, lng: 18.2882465 },
    { lat: 48.7554165, lng: 18.2882042 },
    { lat: 48.7554559, lng: 18.2882406 },
    { lat: 48.7554791, lng: 18.2883899 },
    { lat: 48.7554313, lng: 18.288523 },
    { lat: 48.7554435, lng: 18.2886376 },
    { lat: 48.7554236, lng: 18.288666 },
    { lat: 48.7553923, lng: 18.2887119 },
    { lat: 48.7553837, lng: 18.2887974 },
    { lat: 48.7553799, lng: 18.2888297 },
    { lat: 48.7554229, lng: 18.288951 },
    { lat: 48.7554091, lng: 18.2890075 },
    { lat: 48.7554838, lng: 18.2890704 },
    { lat: 48.7554915, lng: 18.2891095 },
    { lat: 48.755561, lng: 18.2891005 },
    { lat: 48.7556053, lng: 18.2889642 },
    { lat: 48.7556216, lng: 18.2889153 },
    { lat: 48.7556838, lng: 18.2889358 },
    { lat: 48.7557031, lng: 18.2890971 },
    { lat: 48.7556971, lng: 18.2891114 },
    { lat: 48.7556652, lng: 18.2892043 },
    { lat: 48.7556847, lng: 18.2892886 },
    { lat: 48.755704, lng: 18.2893716 },
    { lat: 48.755733, lng: 18.2893653 },
    { lat: 48.7557942, lng: 18.2893524 },
    { lat: 48.7557725, lng: 18.2892486 },
    { lat: 48.7557765, lng: 18.2892413 },
    { lat: 48.755797, lng: 18.2892018 },
    { lat: 48.7558845, lng: 18.2892312 },
    { lat: 48.7559875, lng: 18.2892014 },
    { lat: 48.7560075, lng: 18.2893115 },
    { lat: 48.7559368, lng: 18.2894998 },
    { lat: 48.755928, lng: 18.2895231 },
    { lat: 48.7560129, lng: 18.2896283 },
    { lat: 48.756093, lng: 18.2897263 },
    { lat: 48.7560913, lng: 18.2897548 },
    { lat: 48.7560797, lng: 18.2899104 },
    { lat: 48.7561635, lng: 18.2899156 },
    { lat: 48.7561733, lng: 18.2898875 },
    { lat: 48.7562083, lng: 18.2897886 },
    { lat: 48.756321, lng: 18.2897278 },
    { lat: 48.7564198, lng: 18.2897622 },
    { lat: 48.7564495, lng: 18.2899429 },
    { lat: 48.7563734, lng: 18.289957 },
    { lat: 48.7563809, lng: 18.2901477 },
    { lat: 48.7564785, lng: 18.290217 },
    { lat: 48.7565504, lng: 18.2903517 },
    { lat: 48.7565388, lng: 18.2904265 },
    { lat: 48.7565742, lng: 18.2904541 },
    { lat: 48.7565727, lng: 18.2905269 },
    { lat: 48.7565711, lng: 18.2905783 },
    { lat: 48.7566258, lng: 18.2907678 },
    { lat: 48.756677, lng: 18.290891 },
    { lat: 48.7567032, lng: 18.2909573 },
    { lat: 48.7567156, lng: 18.291117 },
    { lat: 48.7567857, lng: 18.2910775 },
    { lat: 48.7568785, lng: 18.2910262 },
    { lat: 48.7569223, lng: 18.2910019 },
    { lat: 48.7569785, lng: 18.2910758 },
    { lat: 48.7569728, lng: 18.2911759 },
    { lat: 48.7569689, lng: 18.2912279 },
    { lat: 48.7570392, lng: 18.2913114 },
    { lat: 48.757078, lng: 18.2912992 },
    { lat: 48.7570903, lng: 18.2912955 },
    { lat: 48.7571529, lng: 18.2911654 },
    { lat: 48.7572034, lng: 18.2912233 },
    { lat: 48.757155, lng: 18.291286 },
    { lat: 48.7572353, lng: 18.2913475 },
    { lat: 48.7572354, lng: 18.2914076 },
    { lat: 48.7572359, lng: 18.2914951 },
    { lat: 48.7572578, lng: 18.2915642 },
    { lat: 48.7572616, lng: 18.291574 },
    { lat: 48.7574749, lng: 18.2916905 },
    { lat: 48.7575208, lng: 18.2917879 },
    { lat: 48.7577871, lng: 18.2919064 },
    { lat: 48.7578403, lng: 18.2919106 },
    { lat: 48.75788, lng: 18.2919137 },
    { lat: 48.757942, lng: 18.2919739 },
    { lat: 48.7580281, lng: 18.2920568 },
    { lat: 48.7580213, lng: 18.2920963 },
    { lat: 48.7581183, lng: 18.2926352 },
    { lat: 48.7582855, lng: 18.2932529 },
    { lat: 48.758425, lng: 18.2938044 },
    { lat: 48.7586277, lng: 18.2946958 },
    { lat: 48.7589418, lng: 18.295917 },
    { lat: 48.7592117, lng: 18.2970582 },
    { lat: 48.7594335, lng: 18.2979646 },
    { lat: 48.7597445, lng: 18.2991742 },
    { lat: 48.7598874, lng: 18.2997861 },
    { lat: 48.7598437, lng: 18.3007097 },
    { lat: 48.7599242, lng: 18.301373 },
    { lat: 48.7599581, lng: 18.3014745 },
    { lat: 48.7593835, lng: 18.3028648 },
    { lat: 48.7591128, lng: 18.3034674 },
    { lat: 48.7586119, lng: 18.3044054 },
    { lat: 48.7581577, lng: 18.3052491 },
    { lat: 48.7581413, lng: 18.3052809 },
    { lat: 48.7585872, lng: 18.3057828 },
    { lat: 48.7585729, lng: 18.3058097 },
    { lat: 48.7587461, lng: 18.3062761 },
    { lat: 48.7593114, lng: 18.3071178 },
    { lat: 48.7597979, lng: 18.3076603 },
    { lat: 48.7602171, lng: 18.3077523 },
    { lat: 48.7607784, lng: 18.3084804 },
    { lat: 48.7608758, lng: 18.3086062 },
    { lat: 48.7609853, lng: 18.3087487 },
    { lat: 48.7615609, lng: 18.3095497 },
    { lat: 48.7615684, lng: 18.3096041 },
    { lat: 48.7615898, lng: 18.3097391 },
    { lat: 48.7617977, lng: 18.3110418 },
    { lat: 48.7618047, lng: 18.3120279 },
    { lat: 48.7619539, lng: 18.312511 },
    { lat: 48.7623652, lng: 18.3133004 },
    { lat: 48.7626194, lng: 18.3149251 },
    { lat: 48.7630567, lng: 18.3158964 },
    { lat: 48.7630759, lng: 18.3158754 },
    { lat: 48.7638594, lng: 18.317224 },
    { lat: 48.7642812, lng: 18.3176809 },
    { lat: 48.764615, lng: 18.3181789 },
    { lat: 48.7653481, lng: 18.3189336 },
    { lat: 48.7658853, lng: 18.3196961 },
    { lat: 48.7664849, lng: 18.3202875 },
    { lat: 48.7670738, lng: 18.320925 },
    { lat: 48.7670989, lng: 18.3208924 },
    { lat: 48.7671387, lng: 18.3209349 },
    { lat: 48.7677378, lng: 18.3216773 },
    { lat: 48.7676281, lng: 18.3223735 },
    { lat: 48.7678143, lng: 18.3230129 },
    { lat: 48.7683086, lng: 18.3238551 },
    { lat: 48.7685426, lng: 18.3237834 },
    { lat: 48.7691858, lng: 18.3230845 },
    { lat: 48.7695587, lng: 18.3228543 },
    { lat: 48.7697993, lng: 18.3223594 },
    { lat: 48.7701723, lng: 18.3219933 },
    { lat: 48.7705719, lng: 18.322078 },
    { lat: 48.7706533, lng: 18.3229595 },
    { lat: 48.7708188, lng: 18.323274 },
    { lat: 48.7713257, lng: 18.3235391 },
    { lat: 48.7712472, lng: 18.323987 },
    { lat: 48.7713783, lng: 18.3241536 },
    { lat: 48.7714237, lng: 18.3245176 },
    { lat: 48.7715113, lng: 18.3246293 },
    { lat: 48.7719718, lng: 18.3246948 },
    { lat: 48.7720642, lng: 18.3243931 },
    { lat: 48.772364, lng: 18.3244471 },
    { lat: 48.772646, lng: 18.3243255 },
    { lat: 48.7731524, lng: 18.3242831 },
    { lat: 48.7736785, lng: 18.3244045 },
    { lat: 48.7742163, lng: 18.3243626 },
    { lat: 48.7749318, lng: 18.3242245 },
    { lat: 48.7751179, lng: 18.3233406 },
    { lat: 48.7751391, lng: 18.3226561 },
    { lat: 48.7752596, lng: 18.3225336 },
    { lat: 48.7756163, lng: 18.3225562 },
    { lat: 48.7758496, lng: 18.3226105 },
    { lat: 48.7764176, lng: 18.3227808 },
    { lat: 48.7768876, lng: 18.3229945 },
    { lat: 48.7771993, lng: 18.3231593 },
    { lat: 48.7777235, lng: 18.3236293 },
    { lat: 48.778138, lng: 18.32374 },
    { lat: 48.7790188, lng: 18.3233312 },
    { lat: 48.7795887, lng: 18.3234336 },
    { lat: 48.7797309, lng: 18.3228365 },
    { lat: 48.7798693, lng: 18.3223267 },
    { lat: 48.7799562, lng: 18.3220468 },
    { lat: 48.7800908, lng: 18.3216538 },
    { lat: 48.7804414, lng: 18.3214283 },
    { lat: 48.7809564, lng: 18.3209593 },
    { lat: 48.7813237, lng: 18.3207266 },
    { lat: 48.7821112, lng: 18.3202278 },
    { lat: 48.7825086, lng: 18.3203318 },
    { lat: 48.7831854, lng: 18.3205495 },
    { lat: 48.7837365, lng: 18.3207578 },
    { lat: 48.7839293, lng: 18.3207773 },
    { lat: 48.7842736, lng: 18.3206824 },
    { lat: 48.7849742, lng: 18.3207586 },
    { lat: 48.7856063, lng: 18.3207858 },
    { lat: 48.785859, lng: 18.3207224 },
    { lat: 48.786397, lng: 18.3204404 },
    { lat: 48.7867988, lng: 18.3202246 },
    { lat: 48.7868139, lng: 18.3202316 },
    { lat: 48.7877431, lng: 18.3207832 },
    { lat: 48.7880797, lng: 18.3208689 },
    { lat: 48.7885638, lng: 18.3211531 },
    { lat: 48.7894248, lng: 18.3213153 },
    { lat: 48.7898106, lng: 18.3216333 },
    { lat: 48.7898949, lng: 18.3215054 },
    { lat: 48.7901298, lng: 18.3213109 },
    { lat: 48.7904865, lng: 18.3208945 },
    { lat: 48.7909132, lng: 18.320459 },
    { lat: 48.7911599, lng: 18.3203137 },
    { lat: 48.7921219, lng: 18.3201254 },
    { lat: 48.7926964, lng: 18.31981 },
    { lat: 48.7931454, lng: 18.3194883 },
    { lat: 48.7938293, lng: 18.3187111 },
    { lat: 48.7940895, lng: 18.3183025 },
    { lat: 48.7943981, lng: 18.3179728 },
    { lat: 48.7947823, lng: 18.3172848 },
    { lat: 48.7950719, lng: 18.3170366 },
    { lat: 48.7955476, lng: 18.316891 },
    { lat: 48.7957858, lng: 18.3166119 },
    { lat: 48.7958721, lng: 18.3166004 },
    { lat: 48.7959192, lng: 18.3165964 },
    { lat: 48.7969241, lng: 18.3163882 },
    { lat: 48.7977429, lng: 18.3162634 },
    { lat: 48.7982167, lng: 18.316423 },
    { lat: 48.7992318, lng: 18.3167781 },
    { lat: 48.7994945, lng: 18.3161959 },
    { lat: 48.7998568, lng: 18.315959 },
    { lat: 48.8004829, lng: 18.3159346 },
    { lat: 48.80052, lng: 18.3159545 },
    { lat: 48.8005398, lng: 18.3159617 },
    { lat: 48.8009323, lng: 18.3157194 },
    { lat: 48.8015773, lng: 18.3161149 },
    { lat: 48.8021646, lng: 18.3163457 },
    { lat: 48.8029561, lng: 18.3165383 },
  ],
  Dvorec: [
    { lat: 48.7307738, lng: 18.1985368 },
    { lat: 48.7307163, lng: 18.1984754 },
    { lat: 48.7304632, lng: 18.1986635 },
    { lat: 48.7301659, lng: 18.1989084 },
    { lat: 48.7300222, lng: 18.1989423 },
    { lat: 48.7298283, lng: 18.198926 },
    { lat: 48.729136, lng: 18.1983103 },
    { lat: 48.7281898, lng: 18.1975318 },
    { lat: 48.7276134, lng: 18.1970067 },
    { lat: 48.7272203, lng: 18.1966674 },
    { lat: 48.7266123, lng: 18.1961197 },
    { lat: 48.7265735, lng: 18.1960962 },
    { lat: 48.7260517, lng: 18.1956369 },
    { lat: 48.7258383, lng: 18.1954369 },
    { lat: 48.725817, lng: 18.1954783 },
    { lat: 48.7256501, lng: 18.1959299 },
    { lat: 48.7255684, lng: 18.1962987 },
    { lat: 48.7257041, lng: 18.1967123 },
    { lat: 48.7255625, lng: 18.1970747 },
    { lat: 48.7255115, lng: 18.1971922 },
    { lat: 48.7252036, lng: 18.1977827 },
    { lat: 48.7249979, lng: 18.1981493 },
    { lat: 48.7248307, lng: 18.19842 },
    { lat: 48.724638, lng: 18.1987198 },
    { lat: 48.7245935, lng: 18.19879 },
    { lat: 48.7243833, lng: 18.1991146 },
    { lat: 48.7241741, lng: 18.1994606 },
    { lat: 48.7240043, lng: 18.1996705 },
    { lat: 48.723562, lng: 18.2002758 },
    { lat: 48.7232736, lng: 18.2006396 },
    { lat: 48.7230587, lng: 18.200903 },
    { lat: 48.7226392, lng: 18.201438 },
    { lat: 48.722501, lng: 18.2016256 },
    { lat: 48.7223182, lng: 18.2018637 },
    { lat: 48.7222945, lng: 18.2018982 },
    { lat: 48.7222225, lng: 18.2020035 },
    { lat: 48.7221946, lng: 18.2020443 },
    { lat: 48.7219467, lng: 18.2024253 },
    { lat: 48.7218136, lng: 18.2026236 },
    { lat: 48.7214349, lng: 18.2030243 },
    { lat: 48.7212005, lng: 18.2033242 },
    { lat: 48.7210664, lng: 18.203484 },
    { lat: 48.7208738, lng: 18.2036876 },
    { lat: 48.7206641, lng: 18.2038477 },
    { lat: 48.7205574, lng: 18.2039332 },
    { lat: 48.7203702, lng: 18.204074 },
    { lat: 48.7195794, lng: 18.2045298 },
    { lat: 48.7191602, lng: 18.2047738 },
    { lat: 48.7191319, lng: 18.2047911 },
    { lat: 48.7191198, lng: 18.2047984 },
    { lat: 48.7190903, lng: 18.2048162 },
    { lat: 48.7190437, lng: 18.2048488 },
    { lat: 48.718893, lng: 18.2049544 },
    { lat: 48.7184657, lng: 18.2052742 },
    { lat: 48.718428, lng: 18.2053056 },
    { lat: 48.7184334, lng: 18.2053216 },
    { lat: 48.7184418, lng: 18.2053484 },
    { lat: 48.7185292, lng: 18.205615 },
    { lat: 48.7186123, lng: 18.2058424 },
    { lat: 48.7186286, lng: 18.20595 },
    { lat: 48.7186319, lng: 18.2059692 },
    { lat: 48.7186342, lng: 18.2059893 },
    { lat: 48.718489, lng: 18.2060133 },
    { lat: 48.7184595, lng: 18.2060515 },
    { lat: 48.7184877, lng: 18.2063448 },
    { lat: 48.7184663, lng: 18.2063672 },
    { lat: 48.7184269, lng: 18.2063849 },
    { lat: 48.7183989, lng: 18.2064082 },
    { lat: 48.7183548, lng: 18.2064265 },
    { lat: 48.7182221, lng: 18.2064343 },
    { lat: 48.7181969, lng: 18.2064483 },
    { lat: 48.7181788, lng: 18.2064661 },
    { lat: 48.7181663, lng: 18.2065256 },
    { lat: 48.7182421, lng: 18.206606 },
    { lat: 48.7182602, lng: 18.2066283 },
    { lat: 48.7182871, lng: 18.2067016 },
    { lat: 48.7181314, lng: 18.2069783 },
    { lat: 48.7179941, lng: 18.2068855 },
    { lat: 48.7179758, lng: 18.2068804 },
    { lat: 48.7178659, lng: 18.2069342 },
    { lat: 48.7178547, lng: 18.2070728 },
    { lat: 48.7178348, lng: 18.2071966 },
    { lat: 48.7178049, lng: 18.2072263 },
    { lat: 48.7177184, lng: 18.2071529 },
    { lat: 48.7175796, lng: 18.2071543 },
    { lat: 48.717363, lng: 18.207216 },
    { lat: 48.7171815, lng: 18.2072741 },
    { lat: 48.7170596, lng: 18.2072561 },
    { lat: 48.7169548, lng: 18.2072013 },
    { lat: 48.7169191, lng: 18.207107 },
    { lat: 48.7167317, lng: 18.2071091 },
    { lat: 48.7166251, lng: 18.2072949 },
    { lat: 48.7167229, lng: 18.2074447 },
    { lat: 48.7167263, lng: 18.2075411 },
    { lat: 48.7166321, lng: 18.2077289 },
    { lat: 48.716253, lng: 18.2080091 },
    { lat: 48.716176, lng: 18.2079817 },
    { lat: 48.71608, lng: 18.2079239 },
    { lat: 48.7159874, lng: 18.2078996 },
    { lat: 48.7158335, lng: 18.2078712 },
    { lat: 48.7158019, lng: 18.2079143 },
    { lat: 48.7157997, lng: 18.2079914 },
    { lat: 48.7158834, lng: 18.2081144 },
    { lat: 48.7158534, lng: 18.2082191 },
    { lat: 48.7157758, lng: 18.2083036 },
    { lat: 48.715568, lng: 18.2084574 },
    { lat: 48.7154915, lng: 18.2084996 },
    { lat: 48.7153763, lng: 18.2084993 },
    { lat: 48.7152141, lng: 18.2084256 },
    { lat: 48.7150039, lng: 18.2083388 },
    { lat: 48.7148356, lng: 18.2083566 },
    { lat: 48.7146842, lng: 18.2082156 },
    { lat: 48.7144747, lng: 18.2083017 },
    { lat: 48.7144031, lng: 18.2084719 },
    { lat: 48.7143388, lng: 18.2086862 },
    { lat: 48.7142646, lng: 18.2090454 },
    { lat: 48.7140184, lng: 18.2092163 },
    { lat: 48.7137781, lng: 18.2093357 },
    { lat: 48.7135824, lng: 18.2093664 },
    { lat: 48.713477, lng: 18.2093995 },
    { lat: 48.7130793, lng: 18.2097254 },
    { lat: 48.7122836, lng: 18.210211 },
    { lat: 48.7120741, lng: 18.2103746 },
    { lat: 48.7119039, lng: 18.2104376 },
    { lat: 48.711869, lng: 18.2104032 },
    { lat: 48.711862, lng: 18.2103963 },
    { lat: 48.7116059, lng: 18.2102802 },
    { lat: 48.7114183, lng: 18.2101551 },
    { lat: 48.7111604, lng: 18.2099309 },
    { lat: 48.7110476, lng: 18.2099307 },
    { lat: 48.7108853, lng: 18.2101306 },
    { lat: 48.7106455, lng: 18.2102 },
    { lat: 48.7105767, lng: 18.2103555 },
    { lat: 48.7105115, lng: 18.2104809 },
    { lat: 48.7102122, lng: 18.2108477 },
    { lat: 48.7101551, lng: 18.2111093 },
    { lat: 48.7101209, lng: 18.2113578 },
    { lat: 48.7100696, lng: 18.2114699 },
    { lat: 48.710018, lng: 18.211551 },
    { lat: 48.7098622, lng: 18.2116082 },
    { lat: 48.7094909, lng: 18.2115715 },
    { lat: 48.7091045, lng: 18.2118744 },
    { lat: 48.7090617, lng: 18.2119936 },
    { lat: 48.7089712, lng: 18.2121102 },
    { lat: 48.708802, lng: 18.2123471 },
    { lat: 48.7087054, lng: 18.2124723 },
    { lat: 48.7085036, lng: 18.2127874 },
    { lat: 48.7084527, lng: 18.2129231 },
    { lat: 48.7084224, lng: 18.2130453 },
    { lat: 48.7085343, lng: 18.2132068 },
    { lat: 48.7085974, lng: 18.2133639 },
    { lat: 48.7085805, lng: 18.213518 },
    { lat: 48.7084279, lng: 18.2137309 },
    { lat: 48.7082642, lng: 18.2138649 },
    { lat: 48.7082882, lng: 18.2139322 },
    { lat: 48.7084595, lng: 18.2141455 },
    { lat: 48.7084499, lng: 18.2141914 },
    { lat: 48.7084419, lng: 18.2142293 },
    { lat: 48.7084625, lng: 18.2144106 },
    { lat: 48.7084822, lng: 18.2145191 },
    { lat: 48.7084908, lng: 18.2146491 },
    { lat: 48.7084943, lng: 18.2146887 },
    { lat: 48.7084249, lng: 18.214856 },
    { lat: 48.7083881, lng: 18.2149448 },
    { lat: 48.7083764, lng: 18.2149749 },
    { lat: 48.7083625, lng: 18.2150082 },
    { lat: 48.7083175, lng: 18.2151112 },
    { lat: 48.7083006, lng: 18.2151503 },
    { lat: 48.708258, lng: 18.215273 },
    { lat: 48.7082552, lng: 18.2152813 },
    { lat: 48.7082389, lng: 18.2153734 },
    { lat: 48.7081954, lng: 18.215636 },
    { lat: 48.7081879, lng: 18.2156831 },
    { lat: 48.7081564, lng: 18.2158019 },
    { lat: 48.7081423, lng: 18.2158527 },
    { lat: 48.7081229, lng: 18.215926 },
    { lat: 48.7081181, lng: 18.215942 },
    { lat: 48.7080991, lng: 18.2159855 },
    { lat: 48.7080575, lng: 18.2160842 },
    { lat: 48.7080272, lng: 18.216125 },
    { lat: 48.7080061, lng: 18.2161538 },
    { lat: 48.7079695, lng: 18.216204 },
    { lat: 48.707954, lng: 18.2162253 },
    { lat: 48.7079193, lng: 18.2162724 },
    { lat: 48.7079037, lng: 18.2162937 },
    { lat: 48.7078243, lng: 18.2163681 },
    { lat: 48.707817, lng: 18.2163766 },
    { lat: 48.7077885, lng: 18.2164124 },
    { lat: 48.7077338, lng: 18.2164796 },
    { lat: 48.7077265, lng: 18.2164899 },
    { lat: 48.7076665, lng: 18.2165638 },
    { lat: 48.7075537, lng: 18.2167044 },
    { lat: 48.7075442, lng: 18.2167156 },
    { lat: 48.7075062, lng: 18.2167631 },
    { lat: 48.7075709, lng: 18.2170612 },
    { lat: 48.7075767, lng: 18.2170847 },
    { lat: 48.7076008, lng: 18.2172277 },
    { lat: 48.7075956, lng: 18.2172359 },
    { lat: 48.7075523, lng: 18.2173112 },
    { lat: 48.7075114, lng: 18.2173844 },
    { lat: 48.7074703, lng: 18.2174552 },
    { lat: 48.7074314, lng: 18.2175242 },
    { lat: 48.7073926, lng: 18.2175922 },
    { lat: 48.7073545, lng: 18.2176579 },
    { lat: 48.7073187, lng: 18.2177223 },
    { lat: 48.7072817, lng: 18.2177861 },
    { lat: 48.7072469, lng: 18.2178478 },
    { lat: 48.7072121, lng: 18.2179084 },
    { lat: 48.7071781, lng: 18.2179683 },
    { lat: 48.7071456, lng: 18.2180268 },
    { lat: 48.7071127, lng: 18.2180844 },
    { lat: 48.7070994, lng: 18.2181076 },
    { lat: 48.7071147, lng: 18.2181767 },
    { lat: 48.7071346, lng: 18.2182677 },
    { lat: 48.7071354, lng: 18.218344 },
    { lat: 48.7070744, lng: 18.2183037 },
    { lat: 48.7070316, lng: 18.2184015 },
    { lat: 48.7069886, lng: 18.2184604 },
    { lat: 48.7068975, lng: 18.2185041 },
    { lat: 48.7068515, lng: 18.2185677 },
    { lat: 48.7066893, lng: 18.2188644 },
    { lat: 48.7066128, lng: 18.2189294 },
    { lat: 48.7065535, lng: 18.2188596 },
    { lat: 48.7065186, lng: 18.2188403 },
    { lat: 48.706458, lng: 18.2188682 },
    { lat: 48.7063643, lng: 18.2189462 },
    { lat: 48.7063177, lng: 18.2189984 },
    { lat: 48.7063005, lng: 18.2190532 },
    { lat: 48.7062711, lng: 18.2192496 },
    { lat: 48.7061838, lng: 18.2195175 },
    { lat: 48.7061858, lng: 18.2195884 },
    { lat: 48.7062257, lng: 18.2196374 },
    { lat: 48.7062916, lng: 18.2196863 },
    { lat: 48.7063479, lng: 18.2196815 },
    { lat: 48.7064104, lng: 18.2196918 },
    { lat: 48.7064282, lng: 18.2197056 },
    { lat: 48.7063713, lng: 18.2199338 },
    { lat: 48.7063715, lng: 18.219957 },
    { lat: 48.7065062, lng: 18.2202195 },
    { lat: 48.7065634, lng: 18.2202942 },
    { lat: 48.7065764, lng: 18.2203225 },
    { lat: 48.7065822, lng: 18.220366 },
    { lat: 48.7065836, lng: 18.2205055 },
    { lat: 48.7065893, lng: 18.2205676 },
    { lat: 48.7066072, lng: 18.2206414 },
    { lat: 48.7067254, lng: 18.2207774 },
    { lat: 48.7064436, lng: 18.2211162 },
    { lat: 48.7065113, lng: 18.2215464 },
    { lat: 48.7065202, lng: 18.2217221 },
    { lat: 48.7065329, lng: 18.2218279 },
    { lat: 48.7065428, lng: 18.222195 },
    { lat: 48.7065193, lng: 18.222551 },
    { lat: 48.7064957, lng: 18.2227499 },
    { lat: 48.7064671, lng: 18.2228451 },
    { lat: 48.7064456, lng: 18.2229601 },
    { lat: 48.7064298, lng: 18.2230821 },
    { lat: 48.706408, lng: 18.2232497 },
    { lat: 48.706453, lng: 18.2236289 },
    { lat: 48.7064671, lng: 18.2237479 },
    { lat: 48.7066735, lng: 18.2241807 },
    { lat: 48.7067074, lng: 18.2242977 },
    { lat: 48.7067036, lng: 18.22431 },
    { lat: 48.706688, lng: 18.2243556 },
    { lat: 48.7066852, lng: 18.2243649 },
    { lat: 48.7066504, lng: 18.2244666 },
    { lat: 48.7066192, lng: 18.2245339 },
    { lat: 48.7065842, lng: 18.2245863 },
    { lat: 48.7065506, lng: 18.2246365 },
    { lat: 48.7064955, lng: 18.224677 },
    { lat: 48.7062758, lng: 18.2248615 },
    { lat: 48.706218, lng: 18.2249137 },
    { lat: 48.706182, lng: 18.2249331 },
    { lat: 48.7058634, lng: 18.2251051 },
    { lat: 48.7057461, lng: 18.225208 },
    { lat: 48.7057054, lng: 18.2252362 },
    { lat: 48.7054966, lng: 18.2253807 },
    { lat: 48.7052263, lng: 18.2256479 },
    { lat: 48.7049949, lng: 18.2259593 },
    { lat: 48.7049049, lng: 18.2261211 },
    { lat: 48.7047847, lng: 18.226292 },
    { lat: 48.7047013, lng: 18.2264107 },
    { lat: 48.7046313, lng: 18.226587 },
    { lat: 48.7045954, lng: 18.2267286 },
    { lat: 48.7045917, lng: 18.2267423 },
    { lat: 48.7045798, lng: 18.2267906 },
    { lat: 48.7045761, lng: 18.226804 },
    { lat: 48.7045493, lng: 18.2269479 },
    { lat: 48.7045398, lng: 18.2270602 },
    { lat: 48.7045345, lng: 18.227122 },
    { lat: 48.7045364, lng: 18.2272539 },
    { lat: 48.7045553, lng: 18.2274233 },
    { lat: 48.7046185, lng: 18.2276801 },
    { lat: 48.7048243, lng: 18.2281615 },
    { lat: 48.7048489, lng: 18.2282769 },
    { lat: 48.7048984, lng: 18.228394 },
    { lat: 48.7049963, lng: 18.2285838 },
    { lat: 48.7050277, lng: 18.2287394 },
    { lat: 48.7050981, lng: 18.2288617 },
    { lat: 48.7051142, lng: 18.2289088 },
    { lat: 48.7051182, lng: 18.2289401 },
    { lat: 48.7051209, lng: 18.2289676 },
    { lat: 48.7051341, lng: 18.2289991 },
    { lat: 48.7050898, lng: 18.2291128 },
    { lat: 48.7051105, lng: 18.2292161 },
    { lat: 48.7051483, lng: 18.2294049 },
    { lat: 48.7051968, lng: 18.2297015 },
    { lat: 48.7051618, lng: 18.2298965 },
    { lat: 48.7051004, lng: 18.2300256 },
    { lat: 48.7051164, lng: 18.230092 },
    { lat: 48.7052122, lng: 18.2306405 },
    { lat: 48.7052277, lng: 18.2307613 },
    { lat: 48.7052918, lng: 18.2312613 },
    { lat: 48.7053547, lng: 18.2314161 },
    { lat: 48.7054609, lng: 18.2315506 },
    { lat: 48.7056954, lng: 18.2316876 },
    { lat: 48.7058073, lng: 18.2319022 },
    { lat: 48.7062025, lng: 18.2322891 },
    { lat: 48.7062941, lng: 18.2325089 },
    { lat: 48.7063306, lng: 18.2325432 },
    { lat: 48.7063411, lng: 18.2325272 },
    { lat: 48.7063692, lng: 18.232486 },
    { lat: 48.706665, lng: 18.2323519 },
    { lat: 48.7070268, lng: 18.2322268 },
    { lat: 48.7070533, lng: 18.2322165 },
    { lat: 48.7071668, lng: 18.2322074 },
    { lat: 48.7072741, lng: 18.2322098 },
    { lat: 48.7074114, lng: 18.2322633 },
    { lat: 48.7075906, lng: 18.232338 },
    { lat: 48.7077499, lng: 18.2326775 },
    { lat: 48.7080816, lng: 18.232204 },
    { lat: 48.7081581, lng: 18.2321197 },
    { lat: 48.7082208, lng: 18.2320511 },
    { lat: 48.7082964, lng: 18.2318149 },
    { lat: 48.7093658, lng: 18.2314156 },
    { lat: 48.7094805, lng: 18.2302859 },
    { lat: 48.7094933, lng: 18.2301931 },
    { lat: 48.7095058, lng: 18.2300775 },
    { lat: 48.7095153, lng: 18.2298299 },
    { lat: 48.7096164, lng: 18.228836 },
    { lat: 48.7096709, lng: 18.2283117 },
    { lat: 48.7097262, lng: 18.2281337 },
    { lat: 48.7096774, lng: 18.2280705 },
    { lat: 48.7096807, lng: 18.2280083 },
    { lat: 48.7096858, lng: 18.2278806 },
    { lat: 48.7096885, lng: 18.2278299 },
    { lat: 48.7096891, lng: 18.2278196 },
    { lat: 48.7097772, lng: 18.2260106 },
    { lat: 48.7097863, lng: 18.2258151 },
    { lat: 48.7097704, lng: 18.2251224 },
    { lat: 48.7097707, lng: 18.2250702 },
    { lat: 48.7097713, lng: 18.2250012 },
    { lat: 48.7097756, lng: 18.2249385 },
    { lat: 48.7097523, lng: 18.2248486 },
    { lat: 48.7097333, lng: 18.2243967 },
    { lat: 48.7097284, lng: 18.2242728 },
    { lat: 48.7096975, lng: 18.2238506 },
    { lat: 48.7096872, lng: 18.2237901 },
    { lat: 48.7098306, lng: 18.2237723 },
    { lat: 48.7099227, lng: 18.2237245 },
    { lat: 48.7100815, lng: 18.2236392 },
    { lat: 48.710878, lng: 18.2232043 },
    { lat: 48.7118888, lng: 18.2225707 },
    { lat: 48.7120398, lng: 18.2224289 },
    { lat: 48.712424, lng: 18.2221255 },
    { lat: 48.7129477, lng: 18.2214972 },
    { lat: 48.7132102, lng: 18.2212129 },
    { lat: 48.7134458, lng: 18.2209345 },
    { lat: 48.7134585, lng: 18.2209196 },
    { lat: 48.7136203, lng: 18.2207901 },
    { lat: 48.7142755, lng: 18.2203485 },
    { lat: 48.7145758, lng: 18.2201544 },
    { lat: 48.7149987, lng: 18.2198177 },
    { lat: 48.7150667, lng: 18.2197611 },
    { lat: 48.715649, lng: 18.2192721 },
    { lat: 48.7160587, lng: 18.2189976 },
    { lat: 48.7165481, lng: 18.2186385 },
    { lat: 48.7167916, lng: 18.2184374 },
    { lat: 48.7170946, lng: 18.2181708 },
    { lat: 48.7173201, lng: 18.217933 },
    { lat: 48.7174467, lng: 18.2177981 },
    { lat: 48.7177659, lng: 18.2174425 },
    { lat: 48.7177887, lng: 18.2174278 },
    { lat: 48.7178048, lng: 18.2174124 },
    { lat: 48.7179831, lng: 18.2171486 },
    { lat: 48.718361, lng: 18.2169379 },
    { lat: 48.718698, lng: 18.2168527 },
    { lat: 48.7189099, lng: 18.2168313 },
    { lat: 48.7191236, lng: 18.2168171 },
    { lat: 48.7196285, lng: 18.216779 },
    { lat: 48.7197672, lng: 18.2168241 },
    { lat: 48.7201599, lng: 18.2168244 },
    { lat: 48.7206195, lng: 18.2168303 },
    { lat: 48.7207437, lng: 18.2168525 },
    { lat: 48.7208133, lng: 18.2168646 },
    { lat: 48.720938, lng: 18.2166923 },
    { lat: 48.7211995, lng: 18.2164893 },
    { lat: 48.7215783, lng: 18.216255 },
    { lat: 48.7217842, lng: 18.2161456 },
    { lat: 48.721981, lng: 18.2161135 },
    { lat: 48.7221805, lng: 18.2160926 },
    { lat: 48.7223863, lng: 18.2161299 },
    { lat: 48.7226087, lng: 18.216113 },
    { lat: 48.7228565, lng: 18.2160494 },
    { lat: 48.7233846, lng: 18.2158958 },
    { lat: 48.72379, lng: 18.2157237 },
    { lat: 48.724151, lng: 18.2154859 },
    { lat: 48.724609, lng: 18.215072 },
    { lat: 48.7253419, lng: 18.2143938 },
    { lat: 48.7254054, lng: 18.2143379 },
    { lat: 48.7256929, lng: 18.2140988 },
    { lat: 48.7261507, lng: 18.2137785 },
    { lat: 48.7262154, lng: 18.2137271 },
    { lat: 48.726254, lng: 18.2136902 },
    { lat: 48.7262936, lng: 18.2136529 },
    { lat: 48.7263741, lng: 18.213581 },
    { lat: 48.7264503, lng: 18.213514 },
    { lat: 48.7264902, lng: 18.2134806 },
    { lat: 48.7265277, lng: 18.2134458 },
    { lat: 48.7265834, lng: 18.2133761 },
    { lat: 48.7267852, lng: 18.2131949 },
    { lat: 48.7268132, lng: 18.2131709 },
    { lat: 48.7268409, lng: 18.2131436 },
    { lat: 48.7269612, lng: 18.2130371 },
    { lat: 48.726987, lng: 18.2130151 },
    { lat: 48.7270169, lng: 18.2129858 },
    { lat: 48.7271047, lng: 18.2129019 },
    { lat: 48.727187, lng: 18.2128229 },
    { lat: 48.7274554, lng: 18.2125062 },
    { lat: 48.727472, lng: 18.2124009 },
    { lat: 48.7274759, lng: 18.2123318 },
    { lat: 48.7274721, lng: 18.2122656 },
    { lat: 48.7274567, lng: 18.2120357 },
    { lat: 48.7274533, lng: 18.2117424 },
    { lat: 48.7274928, lng: 18.2109557 },
    { lat: 48.7275622, lng: 18.2104737 },
    { lat: 48.7275736, lng: 18.2103566 },
    { lat: 48.7276213, lng: 18.2099508 },
    { lat: 48.7277215, lng: 18.2093374 },
    { lat: 48.7278164, lng: 18.2090068 },
    { lat: 48.7279254, lng: 18.2088018 },
    { lat: 48.7281868, lng: 18.2084608 },
    { lat: 48.7282294, lng: 18.2083373 },
    { lat: 48.7282534, lng: 18.2082641 },
    { lat: 48.7283305, lng: 18.2082254 },
    { lat: 48.7284196, lng: 18.2082505 },
    { lat: 48.7287322, lng: 18.207917 },
    { lat: 48.7288067, lng: 18.2078005 },
    { lat: 48.7289889, lng: 18.2074156 },
    { lat: 48.7292653, lng: 18.2069037 },
    { lat: 48.7294157, lng: 18.2065748 },
    { lat: 48.7298657, lng: 18.2057848 },
    { lat: 48.7303727, lng: 18.2050842 },
    { lat: 48.7304515, lng: 18.2050422 },
    { lat: 48.7306656, lng: 18.204879 },
    { lat: 48.7309637, lng: 18.2045491 },
    { lat: 48.7308402, lng: 18.2041494 },
    { lat: 48.7308419, lng: 18.2036091 },
    { lat: 48.7308193, lng: 18.203275 },
    { lat: 48.7308013, lng: 18.203001 },
    { lat: 48.7307966, lng: 18.202938 },
    { lat: 48.7304632, lng: 18.2026937 },
    { lat: 48.7296055, lng: 18.2020651 },
    { lat: 48.7293781, lng: 18.201898 },
    { lat: 48.7294091, lng: 18.2018078 },
    { lat: 48.7302669, lng: 18.1994168 },
    { lat: 48.7304408, lng: 18.1993217 },
    { lat: 48.7304671, lng: 18.1990899 },
    { lat: 48.7306196, lng: 18.1988193 },
    { lat: 48.7308045, lng: 18.1985827 },
    { lat: 48.7307738, lng: 18.1985368 },
  ],
  Haláčovce: [
    { lat: 48.7194645, lng: 18.1881618 },
    { lat: 48.7192091, lng: 18.1877023 },
    { lat: 48.7193849, lng: 18.1871124 },
    { lat: 48.7196279, lng: 18.1864921 },
    { lat: 48.7198328, lng: 18.1858941 },
    { lat: 48.7198853, lng: 18.1852214 },
    { lat: 48.7198284, lng: 18.1845874 },
    { lat: 48.7199566, lng: 18.1842646 },
    { lat: 48.7202207, lng: 18.1837767 },
    { lat: 48.7203368, lng: 18.1835416 },
    { lat: 48.7209773, lng: 18.1823401 },
    { lat: 48.7212152, lng: 18.1814335 },
    { lat: 48.7212987, lng: 18.1810037 },
    { lat: 48.7213938, lng: 18.1806595 },
    { lat: 48.7215268, lng: 18.1801459 },
    { lat: 48.721633, lng: 18.1797563 },
    { lat: 48.7215348, lng: 18.1796978 },
    { lat: 48.7213326, lng: 18.1797341 },
    { lat: 48.7212457, lng: 18.1797948 },
    { lat: 48.7208362, lng: 18.1794639 },
    { lat: 48.7206505, lng: 18.1792371 },
    { lat: 48.7203075, lng: 18.1791601 },
    { lat: 48.7195756, lng: 18.1792244 },
    { lat: 48.7190444, lng: 18.1796421 },
    { lat: 48.7186195, lng: 18.1798671 },
    { lat: 48.718136, lng: 18.180117 },
    { lat: 48.7175421, lng: 18.1802024 },
    { lat: 48.7173386, lng: 18.1802539 },
    { lat: 48.7172855, lng: 18.1802923 },
    { lat: 48.7171717, lng: 18.1803148 },
    { lat: 48.7166341, lng: 18.1803253 },
    { lat: 48.7165545, lng: 18.1803157 },
    { lat: 48.7161252, lng: 18.1804445 },
    { lat: 48.7157799, lng: 18.1804632 },
    { lat: 48.715669, lng: 18.1804592 },
    { lat: 48.7155405, lng: 18.1804047 },
    { lat: 48.7151171, lng: 18.1799469 },
    { lat: 48.7142577, lng: 18.1796628 },
    { lat: 48.7139669, lng: 18.1790254 },
    { lat: 48.7140203, lng: 18.1787364 },
    { lat: 48.7141446, lng: 18.1779986 },
    { lat: 48.7144266, lng: 18.1771367 },
    { lat: 48.7145785, lng: 18.1767368 },
    { lat: 48.7149385, lng: 18.1757799 },
    { lat: 48.7145443, lng: 18.1756354 },
    { lat: 48.7145498, lng: 18.1747367 },
    { lat: 48.7144892, lng: 18.1737645 },
    { lat: 48.7134906, lng: 18.173278 },
    { lat: 48.713122, lng: 18.1731683 },
    { lat: 48.7128317, lng: 18.1732012 },
    { lat: 48.7126685, lng: 18.173128 },
    { lat: 48.7124481, lng: 18.1728116 },
    { lat: 48.7123469, lng: 18.1724283 },
    { lat: 48.7121956, lng: 18.1721331 },
    { lat: 48.7120533, lng: 18.1720118 },
    { lat: 48.7119927, lng: 18.1718418 },
    { lat: 48.711954, lng: 18.1717787 },
    { lat: 48.7114681, lng: 18.1714351 },
    { lat: 48.7111369, lng: 18.1713673 },
    { lat: 48.7107747, lng: 18.1710949 },
    { lat: 48.7105741, lng: 18.1708227 },
    { lat: 48.7101538, lng: 18.1702692 },
    { lat: 48.7096146, lng: 18.1696665 },
    { lat: 48.7095969, lng: 18.1697121 },
    { lat: 48.7095481, lng: 18.1699815 },
    { lat: 48.7094303, lng: 18.1704015 },
    { lat: 48.7093363, lng: 18.1706694 },
    { lat: 48.7092274, lng: 18.1708767 },
    { lat: 48.709094, lng: 18.1714367 },
    { lat: 48.7089829, lng: 18.1718011 },
    { lat: 48.7089596, lng: 18.1718478 },
    { lat: 48.7086912, lng: 18.1722769 },
    { lat: 48.7085812, lng: 18.1724851 },
    { lat: 48.7082356, lng: 18.1728312 },
    { lat: 48.7081815, lng: 18.1728533 },
    { lat: 48.7077156, lng: 18.1731091 },
    { lat: 48.7076177, lng: 18.1732278 },
    { lat: 48.7075515, lng: 18.1733341 },
    { lat: 48.707248, lng: 18.1737249 },
    { lat: 48.7071052, lng: 18.1739671 },
    { lat: 48.7069564, lng: 18.1741678 },
    { lat: 48.7068375, lng: 18.1743554 },
    { lat: 48.706659, lng: 18.1746837 },
    { lat: 48.7064726, lng: 18.1751287 },
    { lat: 48.7063656, lng: 18.1752704 },
    { lat: 48.7062918, lng: 18.1754791 },
    { lat: 48.7060353, lng: 18.1762704 },
    { lat: 48.7059328, lng: 18.1765111 },
    { lat: 48.7056875, lng: 18.1769139 },
    { lat: 48.7055547, lng: 18.1771336 },
    { lat: 48.7054951, lng: 18.1772358 },
    { lat: 48.7054755, lng: 18.1772691 },
    { lat: 48.7054383, lng: 18.1773291 },
    { lat: 48.7053817, lng: 18.1774212 },
    { lat: 48.7053239, lng: 18.1775131 },
    { lat: 48.7052566, lng: 18.177622 },
    { lat: 48.7052443, lng: 18.1776422 },
    { lat: 48.7052263, lng: 18.1776818 },
    { lat: 48.7051984, lng: 18.1777447 },
    { lat: 48.7051473, lng: 18.1778583 },
    { lat: 48.7051001, lng: 18.1780156 },
    { lat: 48.7050147, lng: 18.1782984 },
    { lat: 48.7049606, lng: 18.1784749 },
    { lat: 48.7049138, lng: 18.1785439 },
    { lat: 48.7048858, lng: 18.1785841 },
    { lat: 48.7048659, lng: 18.1786128 },
    { lat: 48.7048205, lng: 18.1786645 },
    { lat: 48.7047727, lng: 18.1787179 },
    { lat: 48.7046921, lng: 18.1788093 },
    { lat: 48.7046826, lng: 18.1788202 },
    { lat: 48.7046478, lng: 18.1788812 },
    { lat: 48.7046292, lng: 18.1789312 },
    { lat: 48.7045731, lng: 18.1790897 },
    { lat: 48.7045561, lng: 18.179112 },
    { lat: 48.7045553, lng: 18.1791135 },
    { lat: 48.7044832, lng: 18.1790412 },
    { lat: 48.7044692, lng: 18.1790312 },
    { lat: 48.7044196, lng: 18.1789752 },
    { lat: 48.7043598, lng: 18.1788974 },
    { lat: 48.7042564, lng: 18.1787652 },
    { lat: 48.7042514, lng: 18.1787777 },
    { lat: 48.7042346, lng: 18.1788181 },
    { lat: 48.7041484, lng: 18.1790242 },
    { lat: 48.7040059, lng: 18.1786904 },
    { lat: 48.7038248, lng: 18.178642 },
    { lat: 48.7036198, lng: 18.1785887 },
    { lat: 48.7035991, lng: 18.1785828 },
    { lat: 48.7035363, lng: 18.1785472 },
    { lat: 48.7034974, lng: 18.1785212 },
    { lat: 48.7034005, lng: 18.1784229 },
    { lat: 48.7033137, lng: 18.1782871 },
    { lat: 48.7030605, lng: 18.1777924 },
    { lat: 48.7027856, lng: 18.1773521 },
    { lat: 48.7027733, lng: 18.1773319 },
    { lat: 48.7026145, lng: 18.1771053 },
    { lat: 48.7022647, lng: 18.1766162 },
    { lat: 48.7021709, lng: 18.1764371 },
    { lat: 48.7020496, lng: 18.1762901 },
    { lat: 48.7019428, lng: 18.1761806 },
    { lat: 48.7018264, lng: 18.1760809 },
    { lat: 48.7016579, lng: 18.1759967 },
    { lat: 48.7016079, lng: 18.1759715 },
    { lat: 48.7015882, lng: 18.1759619 },
    { lat: 48.7013259, lng: 18.1757603 },
    { lat: 48.7011745, lng: 18.1755781 },
    { lat: 48.7010291, lng: 18.175424 },
    { lat: 48.7010193, lng: 18.1754131 },
    { lat: 48.700844, lng: 18.1752276 },
    { lat: 48.700702, lng: 18.1751156 },
    { lat: 48.7005659, lng: 18.1750063 },
    { lat: 48.7005447, lng: 18.1749909 },
    { lat: 48.7005193, lng: 18.1749614 },
    { lat: 48.7002236, lng: 18.1747835 },
    { lat: 48.700065, lng: 18.174579 },
    { lat: 48.6991301, lng: 18.175506 },
    { lat: 48.6982595, lng: 18.1762191 },
    { lat: 48.6974704, lng: 18.1768589 },
    { lat: 48.6969166, lng: 18.1773909 },
    { lat: 48.6968275, lng: 18.1774688 },
    { lat: 48.6966937, lng: 18.1775189 },
    { lat: 48.6965806, lng: 18.1775917 },
    { lat: 48.6961056, lng: 18.1779039 },
    { lat: 48.6952333, lng: 18.1784332 },
    { lat: 48.6943162, lng: 18.1788287 },
    { lat: 48.6936033, lng: 18.1792262 },
    { lat: 48.6931111, lng: 18.1793603 },
    { lat: 48.6929813, lng: 18.1794956 },
    { lat: 48.6928451, lng: 18.1795821 },
    { lat: 48.6925769, lng: 18.1797614 },
    { lat: 48.6923485, lng: 18.1796383 },
    { lat: 48.6922117, lng: 18.1797667 },
    { lat: 48.6920964, lng: 18.1798836 },
    { lat: 48.6919076, lng: 18.1800331 },
    { lat: 48.691711, lng: 18.1801469 },
    { lat: 48.6915892, lng: 18.1802644 },
    { lat: 48.6913407, lng: 18.18022 },
    { lat: 48.6912468, lng: 18.1802777 },
    { lat: 48.6909611, lng: 18.1804478 },
    { lat: 48.6908684, lng: 18.180485 },
    { lat: 48.6904317, lng: 18.1805432 },
    { lat: 48.690209, lng: 18.18036 },
    { lat: 48.6900292, lng: 18.1805109 },
    { lat: 48.6898663, lng: 18.1806209 },
    { lat: 48.6897115, lng: 18.1806349 },
    { lat: 48.6895618, lng: 18.1807413 },
    { lat: 48.6892009, lng: 18.1807435 },
    { lat: 48.6889853, lng: 18.180783 },
    { lat: 48.6889876, lng: 18.1807852 },
    { lat: 48.6890554, lng: 18.180902 },
    { lat: 48.6895235, lng: 18.1817786 },
    { lat: 48.689816, lng: 18.1823315 },
    { lat: 48.6901097, lng: 18.1828633 },
    { lat: 48.690401, lng: 18.1833764 },
    { lat: 48.6904417, lng: 18.1834532 },
    { lat: 48.6906413, lng: 18.1838237 },
    { lat: 48.6906778, lng: 18.1838903 },
    { lat: 48.6908555, lng: 18.1842123 },
    { lat: 48.6908946, lng: 18.1842833 },
    { lat: 48.691045, lng: 18.1845614 },
    { lat: 48.6911976, lng: 18.1848416 },
    { lat: 48.6912356, lng: 18.1849102 },
    { lat: 48.6914317, lng: 18.1852598 },
    { lat: 48.6916183, lng: 18.185601 },
    { lat: 48.6916648, lng: 18.1856806 },
    { lat: 48.6917125, lng: 18.1857672 },
    { lat: 48.6917606, lng: 18.1858537 },
    { lat: 48.6918045, lng: 18.1859315 },
    { lat: 48.6919092, lng: 18.1861224 },
    { lat: 48.6919591, lng: 18.1862048 },
    { lat: 48.6920365, lng: 18.1863371 },
    { lat: 48.6922461, lng: 18.1867035 },
    { lat: 48.6925217, lng: 18.1871562 },
    { lat: 48.6926559, lng: 18.1873718 },
    { lat: 48.6927838, lng: 18.1875925 },
    { lat: 48.6930928, lng: 18.1880966 },
    { lat: 48.693437, lng: 18.1886864 },
    { lat: 48.6936017, lng: 18.1889222 },
    { lat: 48.6941393, lng: 18.1895713 },
    { lat: 48.6943229, lng: 18.1897625 },
    { lat: 48.6946654, lng: 18.190145 },
    { lat: 48.6949881, lng: 18.1905387 },
    { lat: 48.6950092, lng: 18.1906085 },
    { lat: 48.69525, lng: 18.191303 },
    { lat: 48.6954628, lng: 18.1919394 },
    { lat: 48.6956072, lng: 18.1923533 },
    { lat: 48.6957051, lng: 18.1928162 },
    { lat: 48.6957234, lng: 18.1929946 },
    { lat: 48.6957252, lng: 18.1930258 },
    { lat: 48.6957328, lng: 18.1931212 },
    { lat: 48.6957583, lng: 18.1933865 },
    { lat: 48.6957607, lng: 18.1934083 },
    { lat: 48.695784, lng: 18.1935378 },
    { lat: 48.6958019, lng: 18.1938119 },
    { lat: 48.6958376, lng: 18.1942923 },
    { lat: 48.6958421, lng: 18.1943699 },
    { lat: 48.6958657, lng: 18.1944472 },
    { lat: 48.6959225, lng: 18.1945554 },
    { lat: 48.6960474, lng: 18.1948384 },
    { lat: 48.696087, lng: 18.1949232 },
    { lat: 48.6962955, lng: 18.1954841 },
    { lat: 48.6963553, lng: 18.1956241 },
    { lat: 48.6964464, lng: 18.1958347 },
    { lat: 48.6965053, lng: 18.1959538 },
    { lat: 48.6968618, lng: 18.1965802 },
    { lat: 48.6968944, lng: 18.1966367 },
    { lat: 48.6969081, lng: 18.1966581 },
    { lat: 48.6973315, lng: 18.1974283 },
    { lat: 48.6973492, lng: 18.1974607 },
    { lat: 48.697365, lng: 18.1974793 },
    { lat: 48.6974824, lng: 18.1973835 },
    { lat: 48.6976195, lng: 18.1972134 },
    { lat: 48.6976496, lng: 18.1972487 },
    { lat: 48.698105, lng: 18.1977897 },
    { lat: 48.6988257, lng: 18.1988613 },
    { lat: 48.6988465, lng: 18.1990061 },
    { lat: 48.6989515, lng: 18.199166 },
    { lat: 48.6993423, lng: 18.1995633 },
    { lat: 48.6994317, lng: 18.1996666 },
    { lat: 48.6995074, lng: 18.1997649 },
    { lat: 48.6998307, lng: 18.2002058 },
    { lat: 48.6999789, lng: 18.2003899 },
    { lat: 48.7000883, lng: 18.2005077 },
    { lat: 48.7001554, lng: 18.2005725 },
    { lat: 48.7004542, lng: 18.2008258 },
    { lat: 48.7006985, lng: 18.2010488 },
    { lat: 48.7008366, lng: 18.2011975 },
    { lat: 48.7009403, lng: 18.20133 },
    { lat: 48.7010009, lng: 18.2014006 },
    { lat: 48.7010609, lng: 18.2014667 },
    { lat: 48.7012077, lng: 18.201606 },
    { lat: 48.7013267, lng: 18.2017294 },
    { lat: 48.7014437, lng: 18.2018648 },
    { lat: 48.7015531, lng: 18.2019994 },
    { lat: 48.7016325, lng: 18.2021051 },
    { lat: 48.7017512, lng: 18.2022652 },
    { lat: 48.7018382, lng: 18.2023474 },
    { lat: 48.7019215, lng: 18.2024215 },
    { lat: 48.7020961, lng: 18.2026129 },
    { lat: 48.7022646, lng: 18.2027916 },
    { lat: 48.7024982, lng: 18.2030299 },
    { lat: 48.7026827, lng: 18.2032947 },
    { lat: 48.7027505, lng: 18.2033962 },
    { lat: 48.7027673, lng: 18.2034129 },
    { lat: 48.7028769, lng: 18.2035497 },
    { lat: 48.7032073, lng: 18.204 },
    { lat: 48.7034394, lng: 18.2042854 },
    { lat: 48.7036153, lng: 18.2045205 },
    { lat: 48.7044371, lng: 18.2045256 },
    { lat: 48.7046666, lng: 18.2044951 },
    { lat: 48.7048139, lng: 18.2044862 },
    { lat: 48.7050192, lng: 18.2044421 },
    { lat: 48.7051343, lng: 18.2044052 },
    { lat: 48.7052403, lng: 18.2043641 },
    { lat: 48.7061096, lng: 18.2051757 },
    { lat: 48.7064421, lng: 18.2038359 },
    { lat: 48.7067799, lng: 18.203136 },
    { lat: 48.7071645, lng: 18.2024049 },
    { lat: 48.7077856, lng: 18.2013765 },
    { lat: 48.7087165, lng: 18.2002157 },
    { lat: 48.7091547, lng: 18.1995768 },
    { lat: 48.7097719, lng: 18.1988694 },
    { lat: 48.7102947, lng: 18.1984417 },
    { lat: 48.7104815, lng: 18.1983346 },
    { lat: 48.7106464, lng: 18.1982022 },
    { lat: 48.7109198, lng: 18.1980137 },
    { lat: 48.7110446, lng: 18.1979047 },
    { lat: 48.7112607, lng: 18.197663 },
    { lat: 48.7114084, lng: 18.1975011 },
    { lat: 48.7116187, lng: 18.1972723 },
    { lat: 48.7117779, lng: 18.1971002 },
    { lat: 48.711878, lng: 18.1969741 },
    { lat: 48.7119471, lng: 18.1968436 },
    { lat: 48.7120159, lng: 18.1966684 },
    { lat: 48.712075, lng: 18.1964366 },
    { lat: 48.7122211, lng: 18.1960333 },
    { lat: 48.712389, lng: 18.1957171 },
    { lat: 48.7129152, lng: 18.1948033 },
    { lat: 48.7129675, lng: 18.1947306 },
    { lat: 48.7130867, lng: 18.1945667 },
    { lat: 48.7132759, lng: 18.1943853 },
    { lat: 48.7133691, lng: 18.1942791 },
    { lat: 48.7139031, lng: 18.193595 },
    { lat: 48.7142954, lng: 18.1930625 },
    { lat: 48.7146454, lng: 18.192554 },
    { lat: 48.7148146, lng: 18.1923167 },
    { lat: 48.7148685, lng: 18.1922142 },
    { lat: 48.7150096, lng: 18.1920198 },
    { lat: 48.7151827, lng: 18.1918309 },
    { lat: 48.7153286, lng: 18.1915651 },
    { lat: 48.7155212, lng: 18.1913822 },
    { lat: 48.7159138, lng: 18.1908496 },
    { lat: 48.7159221, lng: 18.1908378 },
    { lat: 48.7160218, lng: 18.1907464 },
    { lat: 48.7160602, lng: 18.1907103 },
    { lat: 48.7162243, lng: 18.1905593 },
    { lat: 48.7164286, lng: 18.190402 },
    { lat: 48.7168003, lng: 18.1899636 },
    { lat: 48.7170069, lng: 18.1898064 },
    { lat: 48.7171695, lng: 18.1896892 },
    { lat: 48.7176298, lng: 18.1893188 },
    { lat: 48.7177709, lng: 18.189183 },
    { lat: 48.7178759, lng: 18.1890835 },
    { lat: 48.7179857, lng: 18.1890115 },
    { lat: 48.7180914, lng: 18.1889668 },
    { lat: 48.7183652, lng: 18.1888997 },
    { lat: 48.718521, lng: 18.1888213 },
    { lat: 48.7186989, lng: 18.1887365 },
    { lat: 48.7187955, lng: 18.1886902 },
    { lat: 48.7190031, lng: 18.18867 },
    { lat: 48.7190488, lng: 18.1886622 },
    { lat: 48.7191079, lng: 18.1886297 },
    { lat: 48.7191845, lng: 18.1885503 },
    { lat: 48.7193129, lng: 18.1883705 },
    { lat: 48.7194645, lng: 18.1881618 },
  ],
  HornéNaštice: [
    { lat: 48.7306622, lng: 18.3155822 },
    { lat: 48.7313551, lng: 18.3148829 },
    { lat: 48.7322956, lng: 18.3144717 },
    { lat: 48.7336758, lng: 18.3141194 },
    { lat: 48.7348098, lng: 18.3137239 },
    { lat: 48.7350583, lng: 18.3136868 },
    { lat: 48.735345, lng: 18.3136884 },
    { lat: 48.7357503, lng: 18.3137154 },
    { lat: 48.7357918, lng: 18.3136868 },
    { lat: 48.7359469, lng: 18.3136167 },
    { lat: 48.7360777, lng: 18.3133793 },
    { lat: 48.7361694, lng: 18.3131434 },
    { lat: 48.7363612, lng: 18.3129824 },
    { lat: 48.736621, lng: 18.3126079 },
    { lat: 48.7365, lng: 18.3118443 },
    { lat: 48.7363202, lng: 18.3106787 },
    { lat: 48.7365557, lng: 18.3105539 },
    { lat: 48.7371467, lng: 18.3101927 },
    { lat: 48.7374631, lng: 18.3099599 },
    { lat: 48.7375471, lng: 18.3096506 },
    { lat: 48.7375262, lng: 18.3094978 },
    { lat: 48.7374843, lng: 18.3094553 },
    { lat: 48.737266, lng: 18.3094671 },
    { lat: 48.7372162, lng: 18.3093048 },
    { lat: 48.7373835, lng: 18.3090112 },
    { lat: 48.7373663, lng: 18.3089458 },
    { lat: 48.7372496, lng: 18.308779 },
    { lat: 48.7371818, lng: 18.3087345 },
    { lat: 48.7370442, lng: 18.3087183 },
    { lat: 48.7367922, lng: 18.3089106 },
    { lat: 48.7367485, lng: 18.3089749 },
    { lat: 48.736682, lng: 18.3089363 },
    { lat: 48.7364296, lng: 18.3086826 },
    { lat: 48.7364119, lng: 18.3086703 },
    { lat: 48.7361109, lng: 18.3085737 },
    { lat: 48.7360341, lng: 18.3082952 },
    { lat: 48.7361068, lng: 18.3080624 },
    { lat: 48.7362098, lng: 18.3079686 },
    { lat: 48.7371508, lng: 18.3070914 },
    { lat: 48.7373857, lng: 18.3069134 },
    { lat: 48.7377627, lng: 18.3066978 },
    { lat: 48.7380099, lng: 18.307717 },
    { lat: 48.7380976, lng: 18.3080928 },
    { lat: 48.7382543, lng: 18.3087278 },
    { lat: 48.7386484, lng: 18.3084917 },
    { lat: 48.7387767, lng: 18.3083904 },
    { lat: 48.7389083, lng: 18.3082422 },
    { lat: 48.7391103, lng: 18.307943 },
    { lat: 48.7392434, lng: 18.3077835 },
    { lat: 48.7394688, lng: 18.3076396 },
    { lat: 48.7399094, lng: 18.3074276 },
    { lat: 48.7402633, lng: 18.307301 },
    { lat: 48.7402903, lng: 18.3077422 },
    { lat: 48.740272, lng: 18.3082336 },
    { lat: 48.7402211, lng: 18.3087131 },
    { lat: 48.7403228, lng: 18.3087146 },
    { lat: 48.7403227, lng: 18.3087325 },
    { lat: 48.7406725, lng: 18.3087309 },
    { lat: 48.74099, lng: 18.3086223 },
    { lat: 48.7413626, lng: 18.308726 },
    { lat: 48.7417504, lng: 18.3088189 },
    { lat: 48.7418205, lng: 18.3089807 },
    { lat: 48.7421455, lng: 18.3092848 },
    { lat: 48.7422532, lng: 18.3092898 },
    { lat: 48.7422944, lng: 18.3101206 },
    { lat: 48.7423266, lng: 18.3106898 },
    { lat: 48.743599, lng: 18.3105254 },
    { lat: 48.7440476, lng: 18.3104385 },
    { lat: 48.7443766, lng: 18.3109472 },
    { lat: 48.7445734, lng: 18.3112753 },
    { lat: 48.7446649, lng: 18.3116771 },
    { lat: 48.7449666, lng: 18.3115263 },
    { lat: 48.7454193, lng: 18.3113287 },
    { lat: 48.7459266, lng: 18.311064 },
    { lat: 48.7466054, lng: 18.3108973 },
    { lat: 48.7471096, lng: 18.3107532 },
    { lat: 48.7482457, lng: 18.3103783 },
    { lat: 48.748372, lng: 18.3111949 },
    { lat: 48.7489562, lng: 18.3120097 },
    { lat: 48.7501421, lng: 18.3127088 },
    { lat: 48.7512297, lng: 18.3133288 },
    { lat: 48.7522531, lng: 18.3138091 },
    { lat: 48.7534175, lng: 18.3140298 },
    { lat: 48.7538477, lng: 18.3143346 },
    { lat: 48.7538395, lng: 18.3143689 },
    { lat: 48.7544154, lng: 18.3150604 },
    { lat: 48.7547284, lng: 18.3153654 },
    { lat: 48.755155, lng: 18.3159064 },
    { lat: 48.755606, lng: 18.3163616 },
    { lat: 48.7561218, lng: 18.3167083 },
    { lat: 48.7563829, lng: 18.3168352 },
    { lat: 48.7572536, lng: 18.3172269 },
    { lat: 48.757848, lng: 18.3180445 },
    { lat: 48.7585354, lng: 18.3186885 },
    { lat: 48.7596248, lng: 18.3199185 },
    { lat: 48.7608405, lng: 18.3212892 },
    { lat: 48.7608899, lng: 18.321348 },
    { lat: 48.7610774, lng: 18.3210513 },
    { lat: 48.7612666, lng: 18.3208037 },
    { lat: 48.7614685, lng: 18.3204222 },
    { lat: 48.7616091, lng: 18.3203171 },
    { lat: 48.7617835, lng: 18.3202481 },
    { lat: 48.7621225, lng: 18.3202562 },
    { lat: 48.7626778, lng: 18.3201377 },
    { lat: 48.7627483, lng: 18.3200507 },
    { lat: 48.762795, lng: 18.3197553 },
    { lat: 48.7627977, lng: 18.3191234 },
    { lat: 48.7628218, lng: 18.3189315 },
    { lat: 48.7630937, lng: 18.3186722 },
    { lat: 48.7632382, lng: 18.3183921 },
    { lat: 48.7635909, lng: 18.3179839 },
    { lat: 48.7637322, lng: 18.3176759 },
    { lat: 48.7638594, lng: 18.317224 },
    { lat: 48.7630759, lng: 18.3158754 },
    { lat: 48.7630567, lng: 18.3158964 },
    { lat: 48.7626194, lng: 18.3149251 },
    { lat: 48.7623652, lng: 18.3133004 },
    { lat: 48.7619539, lng: 18.312511 },
    { lat: 48.7618047, lng: 18.3120279 },
    { lat: 48.7617977, lng: 18.3110418 },
    { lat: 48.7615898, lng: 18.3097391 },
    { lat: 48.7615684, lng: 18.3096041 },
    { lat: 48.7615609, lng: 18.3095497 },
    { lat: 48.7609853, lng: 18.3087487 },
    { lat: 48.7608758, lng: 18.3086062 },
    { lat: 48.7607784, lng: 18.3084804 },
    { lat: 48.7602171, lng: 18.3077523 },
    { lat: 48.7597979, lng: 18.3076603 },
    { lat: 48.7593114, lng: 18.3071178 },
    { lat: 48.7587461, lng: 18.3062761 },
    { lat: 48.7585729, lng: 18.3058097 },
    { lat: 48.7585872, lng: 18.3057828 },
    { lat: 48.7581413, lng: 18.3052809 },
    { lat: 48.7581577, lng: 18.3052491 },
    { lat: 48.7586119, lng: 18.3044054 },
    { lat: 48.7591128, lng: 18.3034674 },
    { lat: 48.7593835, lng: 18.3028648 },
    { lat: 48.7599581, lng: 18.3014745 },
    { lat: 48.7599242, lng: 18.301373 },
    { lat: 48.7598437, lng: 18.3007097 },
    { lat: 48.7598874, lng: 18.2997861 },
    { lat: 48.7597445, lng: 18.2991742 },
    { lat: 48.7594335, lng: 18.2979646 },
    { lat: 48.7592117, lng: 18.2970582 },
    { lat: 48.7589418, lng: 18.295917 },
    { lat: 48.7586277, lng: 18.2946958 },
    { lat: 48.758425, lng: 18.2938044 },
    { lat: 48.7582855, lng: 18.2932529 },
    { lat: 48.7581183, lng: 18.2926352 },
    { lat: 48.7580213, lng: 18.2920963 },
    { lat: 48.7580281, lng: 18.2920568 },
    { lat: 48.757942, lng: 18.2919739 },
    { lat: 48.75788, lng: 18.2919137 },
    { lat: 48.7578403, lng: 18.2919106 },
    { lat: 48.7577871, lng: 18.2919064 },
    { lat: 48.7575208, lng: 18.2917879 },
    { lat: 48.7574749, lng: 18.2916905 },
    { lat: 48.7572616, lng: 18.291574 },
    { lat: 48.7572578, lng: 18.2915642 },
    { lat: 48.7572359, lng: 18.2914951 },
    { lat: 48.7572354, lng: 18.2914076 },
    { lat: 48.7572353, lng: 18.2913475 },
    { lat: 48.757155, lng: 18.291286 },
    { lat: 48.7572034, lng: 18.2912233 },
    { lat: 48.7571529, lng: 18.2911654 },
    { lat: 48.7570903, lng: 18.2912955 },
    { lat: 48.757078, lng: 18.2912992 },
    { lat: 48.7570392, lng: 18.2913114 },
    { lat: 48.7569689, lng: 18.2912279 },
    { lat: 48.7569728, lng: 18.2911759 },
    { lat: 48.7569785, lng: 18.2910758 },
    { lat: 48.7569223, lng: 18.2910019 },
    { lat: 48.7568785, lng: 18.2910262 },
    { lat: 48.7567857, lng: 18.2910775 },
    { lat: 48.7567156, lng: 18.291117 },
    { lat: 48.7567032, lng: 18.2909573 },
    { lat: 48.756677, lng: 18.290891 },
    { lat: 48.7566258, lng: 18.2907678 },
    { lat: 48.7565711, lng: 18.2905783 },
    { lat: 48.7565727, lng: 18.2905269 },
    { lat: 48.7565742, lng: 18.2904541 },
    { lat: 48.7565388, lng: 18.2904265 },
    { lat: 48.7565504, lng: 18.2903517 },
    { lat: 48.7564785, lng: 18.290217 },
    { lat: 48.7563809, lng: 18.2901477 },
    { lat: 48.7563734, lng: 18.289957 },
    { lat: 48.7564495, lng: 18.2899429 },
    { lat: 48.7564198, lng: 18.2897622 },
    { lat: 48.756321, lng: 18.2897278 },
    { lat: 48.7562083, lng: 18.2897886 },
    { lat: 48.7561733, lng: 18.2898875 },
    { lat: 48.7561635, lng: 18.2899156 },
    { lat: 48.7560797, lng: 18.2899104 },
    { lat: 48.7560913, lng: 18.2897548 },
    { lat: 48.756093, lng: 18.2897263 },
    { lat: 48.7560129, lng: 18.2896283 },
    { lat: 48.755928, lng: 18.2895231 },
    { lat: 48.7559368, lng: 18.2894998 },
    { lat: 48.7560075, lng: 18.2893115 },
    { lat: 48.7559875, lng: 18.2892014 },
    { lat: 48.7558845, lng: 18.2892312 },
    { lat: 48.755797, lng: 18.2892018 },
    { lat: 48.7557765, lng: 18.2892413 },
    { lat: 48.7557725, lng: 18.2892486 },
    { lat: 48.7557942, lng: 18.2893524 },
    { lat: 48.755733, lng: 18.2893653 },
    { lat: 48.755704, lng: 18.2893716 },
    { lat: 48.7556847, lng: 18.2892886 },
    { lat: 48.7556652, lng: 18.2892043 },
    { lat: 48.7556971, lng: 18.2891114 },
    { lat: 48.7557031, lng: 18.2890971 },
    { lat: 48.7556838, lng: 18.2889358 },
    { lat: 48.7556216, lng: 18.2889153 },
    { lat: 48.7556053, lng: 18.2889642 },
    { lat: 48.755561, lng: 18.2891005 },
    { lat: 48.7554915, lng: 18.2891095 },
    { lat: 48.7554838, lng: 18.2890704 },
    { lat: 48.7554091, lng: 18.2890075 },
    { lat: 48.7554229, lng: 18.288951 },
    { lat: 48.7553799, lng: 18.2888297 },
    { lat: 48.7553837, lng: 18.2887974 },
    { lat: 48.7553923, lng: 18.2887119 },
    { lat: 48.7554236, lng: 18.288666 },
    { lat: 48.7554435, lng: 18.2886376 },
    { lat: 48.7554313, lng: 18.288523 },
    { lat: 48.7554791, lng: 18.2883899 },
    { lat: 48.7554559, lng: 18.2882406 },
    { lat: 48.7554165, lng: 18.2882042 },
    { lat: 48.7553411, lng: 18.2882465 },
    { lat: 48.7552168, lng: 18.2884014 },
    { lat: 48.7551298, lng: 18.288497 },
    { lat: 48.7550429, lng: 18.2883413 },
    { lat: 48.7549826, lng: 18.2883119 },
    { lat: 48.7549629, lng: 18.2882809 },
    { lat: 48.7550275, lng: 18.2882067 },
    { lat: 48.7551245, lng: 18.2881663 },
    { lat: 48.7550564, lng: 18.2879816 },
    { lat: 48.7550798, lng: 18.2878949 },
    { lat: 48.7550773, lng: 18.2877343 },
    { lat: 48.7550481, lng: 18.2876734 },
    { lat: 48.7549835, lng: 18.2877315 },
    { lat: 48.7549396, lng: 18.2876911 },
    { lat: 48.754885, lng: 18.2877035 },
    { lat: 48.7548045, lng: 18.2876385 },
    { lat: 48.7547609, lng: 18.2874865 },
    { lat: 48.7547012, lng: 18.2874477 },
    { lat: 48.7547186, lng: 18.287296 },
    { lat: 48.7546998, lng: 18.287164 },
    { lat: 48.754621, lng: 18.2871249 },
    { lat: 48.7545874, lng: 18.2869547 },
    { lat: 48.7546545, lng: 18.2868624 },
    { lat: 48.7547006, lng: 18.286841 },
    { lat: 48.7547438, lng: 18.2868858 },
    { lat: 48.7547975, lng: 18.286857 },
    { lat: 48.7547803, lng: 18.286712 },
    { lat: 48.7547112, lng: 18.2866487 },
    { lat: 48.7546195, lng: 18.2866452 },
    { lat: 48.7545861, lng: 18.2865902 },
    { lat: 48.7546013, lng: 18.2865196 },
    { lat: 48.7545469, lng: 18.2864358 },
    { lat: 48.7544399, lng: 18.2866209 },
    { lat: 48.754346, lng: 18.2866532 },
    { lat: 48.7543034, lng: 18.2865822 },
    { lat: 48.7542102, lng: 18.2863318 },
    { lat: 48.7541342, lng: 18.2859623 },
    { lat: 48.7541552, lng: 18.2858569 },
    { lat: 48.7541954, lng: 18.2857491 },
    { lat: 48.7541821, lng: 18.285695 },
    { lat: 48.7540688, lng: 18.2855889 },
    { lat: 48.7540245, lng: 18.2855213 },
    { lat: 48.7539425, lng: 18.2855094 },
    { lat: 48.7538297, lng: 18.2855512 },
    { lat: 48.7537893, lng: 18.2853763 },
    { lat: 48.7538018, lng: 18.2852538 },
    { lat: 48.7537716, lng: 18.2852016 },
    { lat: 48.753764, lng: 18.285126 },
    { lat: 48.7537793, lng: 18.2850794 },
    { lat: 48.7537232, lng: 18.2849243 },
    { lat: 48.7536628, lng: 18.2848134 },
    { lat: 48.7536139, lng: 18.2847897 },
    { lat: 48.7535855, lng: 18.2847845 },
    { lat: 48.7535363, lng: 18.2848506 },
    { lat: 48.7534295, lng: 18.2848609 },
    { lat: 48.7533679, lng: 18.2847501 },
    { lat: 48.7533779, lng: 18.284668 },
    { lat: 48.753399, lng: 18.2846385 },
    { lat: 48.7534239, lng: 18.284283 },
    { lat: 48.7533307, lng: 18.2842097 },
    { lat: 48.753321, lng: 18.2841401 },
    { lat: 48.7533837, lng: 18.2838939 },
    { lat: 48.7534696, lng: 18.2839174 },
    { lat: 48.7534955, lng: 18.2838726 },
    { lat: 48.7534855, lng: 18.2837766 },
    { lat: 48.7534191, lng: 18.2837395 },
    { lat: 48.7533633, lng: 18.2835933 },
    { lat: 48.7534072, lng: 18.2835708 },
    { lat: 48.7534365, lng: 18.283513 },
    { lat: 48.7534667, lng: 18.2835445 },
    { lat: 48.7536008, lng: 18.2835407 },
    { lat: 48.7536371, lng: 18.2834855 },
    { lat: 48.7536007, lng: 18.2833608 },
    { lat: 48.7534505, lng: 18.2831804 },
    { lat: 48.7533788, lng: 18.2831908 },
    { lat: 48.7533486, lng: 18.2830975 },
    { lat: 48.7532668, lng: 18.2831263 },
    { lat: 48.7532115, lng: 18.2831056 },
    { lat: 48.7531919, lng: 18.2830567 },
    { lat: 48.7532003, lng: 18.2829294 },
    { lat: 48.7530917, lng: 18.2828026 },
    { lat: 48.753011, lng: 18.2827923 },
    { lat: 48.7528939, lng: 18.282696 },
    { lat: 48.7528592, lng: 18.2826229 },
    { lat: 48.752791, lng: 18.2825534 },
    { lat: 48.7527843, lng: 18.2824982 },
    { lat: 48.7526766, lng: 18.2824295 },
    { lat: 48.7526402, lng: 18.2823635 },
    { lat: 48.7526251, lng: 18.2824344 },
    { lat: 48.7525545, lng: 18.2825434 },
    { lat: 48.7524866, lng: 18.2824603 },
    { lat: 48.7525348, lng: 18.2823557 },
    { lat: 48.75252, lng: 18.2823312 },
    { lat: 48.7524358, lng: 18.2822981 },
    { lat: 48.7523971, lng: 18.2823325 },
    { lat: 48.7523347, lng: 18.282308 },
    { lat: 48.7522883, lng: 18.2823233 },
    { lat: 48.752288, lng: 18.282219 },
    { lat: 48.7522254, lng: 18.2820898 },
    { lat: 48.7521263, lng: 18.2820948 },
    { lat: 48.752112, lng: 18.2820005 },
    { lat: 48.7520509, lng: 18.2818818 },
    { lat: 48.7520295, lng: 18.2818006 },
    { lat: 48.7519579, lng: 18.2817934 },
    { lat: 48.7519233, lng: 18.2817575 },
    { lat: 48.7519089, lng: 18.2817251 },
    { lat: 48.7519014, lng: 18.2815937 },
    { lat: 48.7519179, lng: 18.281543 },
    { lat: 48.7519784, lng: 18.2814379 },
    { lat: 48.7519901, lng: 18.2813859 },
    { lat: 48.75195, lng: 18.2813157 },
    { lat: 48.7519391, lng: 18.2812831 },
    { lat: 48.751891, lng: 18.2812472 },
    { lat: 48.751878, lng: 18.2812188 },
    { lat: 48.7519019, lng: 18.2811224 },
    { lat: 48.7519354, lng: 18.2810762 },
    { lat: 48.7519689, lng: 18.28097 },
    { lat: 48.7520197, lng: 18.2808954 },
    { lat: 48.7519971, lng: 18.2808126 },
    { lat: 48.7520312, lng: 18.2807763 },
    { lat: 48.7521025, lng: 18.2808 },
    { lat: 48.7521143, lng: 18.2807702 },
    { lat: 48.7520869, lng: 18.2805867 },
    { lat: 48.7520276, lng: 18.2805337 },
    { lat: 48.7520237, lng: 18.2803486 },
    { lat: 48.7520055, lng: 18.2802655 },
    { lat: 48.752028, lng: 18.280225 },
    { lat: 48.7520196, lng: 18.2799973 },
    { lat: 48.7520053, lng: 18.2799627 },
    { lat: 48.7518884, lng: 18.28001 },
    { lat: 48.7518193, lng: 18.2799475 },
    { lat: 48.7517614, lng: 18.2798016 },
    { lat: 48.7516809, lng: 18.2797541 },
    { lat: 48.7516549, lng: 18.2796756 },
    { lat: 48.7516151, lng: 18.2796697 },
    { lat: 48.7515692, lng: 18.2796153 },
    { lat: 48.7515507, lng: 18.2796098 },
    { lat: 48.7513374, lng: 18.2796665 },
    { lat: 48.7511682, lng: 18.2794274 },
    { lat: 48.7511218, lng: 18.2791689 },
    { lat: 48.7511466, lng: 18.279067 },
    { lat: 48.7510267, lng: 18.2785476 },
    { lat: 48.7509937, lng: 18.2785022 },
    { lat: 48.7509954, lng: 18.2784319 },
    { lat: 48.7509296, lng: 18.2782277 },
    { lat: 48.7509406, lng: 18.2781423 },
    { lat: 48.7508896, lng: 18.2780803 },
    { lat: 48.7509042, lng: 18.2778184 },
    { lat: 48.7508365, lng: 18.2777203 },
    { lat: 48.7508423, lng: 18.2775436 },
    { lat: 48.7508339, lng: 18.2775172 },
    { lat: 48.7507973, lng: 18.2775051 },
    { lat: 48.750779, lng: 18.2774028 },
    { lat: 48.7507885, lng: 18.2771752 },
    { lat: 48.750695, lng: 18.2770361 },
    { lat: 48.7507229, lng: 18.2767328 },
    { lat: 48.7506905, lng: 18.2766995 },
    { lat: 48.7505687, lng: 18.2761036 },
    { lat: 48.7503366, lng: 18.275198 },
    { lat: 48.7498261, lng: 18.2740938 },
    { lat: 48.7493603, lng: 18.2735454 },
    { lat: 48.7491269, lng: 18.2736069 },
    { lat: 48.7489958, lng: 18.2737215 },
    { lat: 48.7487304, lng: 18.2740935 },
    { lat: 48.7487077, lng: 18.2741742 },
    { lat: 48.748721, lng: 18.274507 },
    { lat: 48.7487029, lng: 18.2745285 },
    { lat: 48.7485772, lng: 18.2746766 },
    { lat: 48.7485624, lng: 18.2746956 },
    { lat: 48.7484191, lng: 18.2748737 },
    { lat: 48.7480184, lng: 18.2752679 },
    { lat: 48.7475917, lng: 18.2755815 },
    { lat: 48.7471808, lng: 18.2758374 },
    { lat: 48.7467715, lng: 18.2760996 },
    { lat: 48.7462487, lng: 18.2765109 },
    { lat: 48.7458537, lng: 18.2770057 },
    { lat: 48.7455055, lng: 18.2775118 },
    { lat: 48.7451929, lng: 18.2780666 },
    { lat: 48.744621, lng: 18.2789015 },
    { lat: 48.744342, lng: 18.2792945 },
    { lat: 48.7441748, lng: 18.2796085 },
    { lat: 48.7438685, lng: 18.2803345 },
    { lat: 48.7438436, lng: 18.2803913 },
    { lat: 48.7438297, lng: 18.280426 },
    { lat: 48.7436613, lng: 18.2805789 },
    { lat: 48.7433799, lng: 18.2806718 },
    { lat: 48.7431854, lng: 18.2806455 },
    { lat: 48.7428692, lng: 18.2805597 },
    { lat: 48.742686, lng: 18.2805917 },
    { lat: 48.7426544, lng: 18.2806129 },
    { lat: 48.7424369, lng: 18.280757 },
    { lat: 48.7423369, lng: 18.2808238 },
    { lat: 48.742202, lng: 18.2809133 },
    { lat: 48.7420586, lng: 18.2810088 },
    { lat: 48.7416538, lng: 18.2810928 },
    { lat: 48.7416493, lng: 18.2810533 },
    { lat: 48.7408962, lng: 18.2809049 },
    { lat: 48.7406153, lng: 18.2809066 },
    { lat: 48.7404435, lng: 18.2809415 },
    { lat: 48.7399911, lng: 18.2810996 },
    { lat: 48.7396686, lng: 18.2811628 },
    { lat: 48.739428, lng: 18.2812179 },
    { lat: 48.7391083, lng: 18.2812914 },
    { lat: 48.7389159, lng: 18.2813395 },
    { lat: 48.7385224, lng: 18.2815239 },
    { lat: 48.7383307, lng: 18.2816453 },
    { lat: 48.7381083, lng: 18.2817828 },
    { lat: 48.7380107, lng: 18.2818314 },
    { lat: 48.7376334, lng: 18.2820419 },
    { lat: 48.7373216, lng: 18.2822534 },
    { lat: 48.7370331, lng: 18.2824601 },
    { lat: 48.7368375, lng: 18.2826519 },
    { lat: 48.7367785, lng: 18.2827248 },
    { lat: 48.736703, lng: 18.2825278 },
    { lat: 48.7365132, lng: 18.2819664 },
    { lat: 48.7362848, lng: 18.2810647 },
    { lat: 48.7360544, lng: 18.2812195 },
    { lat: 48.7357837, lng: 18.2814014 },
    { lat: 48.7356363, lng: 18.281507 },
    { lat: 48.735543, lng: 18.2815738 },
    { lat: 48.7354922, lng: 18.2816106 },
    { lat: 48.7354401, lng: 18.2816468 },
    { lat: 48.7353651, lng: 18.2816991 },
    { lat: 48.7352903, lng: 18.2817515 },
    { lat: 48.7352675, lng: 18.2817677 },
    { lat: 48.7351666, lng: 18.2818383 },
    { lat: 48.7351395, lng: 18.2818578 },
    { lat: 48.7350317, lng: 18.2819266 },
    { lat: 48.7349598, lng: 18.2819726 },
    { lat: 48.7348901, lng: 18.2820174 },
    { lat: 48.73484, lng: 18.2820496 },
    { lat: 48.73479, lng: 18.2820817 },
    { lat: 48.734741, lng: 18.2821132 },
    { lat: 48.734691, lng: 18.2821453 },
    { lat: 48.7346474, lng: 18.2821731 },
    { lat: 48.7346071, lng: 18.2821989 },
    { lat: 48.7345679, lng: 18.2822243 },
    { lat: 48.7345276, lng: 18.2822505 },
    { lat: 48.7344862, lng: 18.2822764 },
    { lat: 48.7344471, lng: 18.2823018 },
    { lat: 48.7343709, lng: 18.282351 },
    { lat: 48.7342914, lng: 18.2824021 },
    { lat: 48.7342315, lng: 18.2824403 },
    { lat: 48.734176, lng: 18.2824761 },
    { lat: 48.7341151, lng: 18.2825151 },
    { lat: 48.7339916, lng: 18.2826061 },
    { lat: 48.7337934, lng: 18.282693 },
    { lat: 48.73368, lng: 18.2827416 },
    { lat: 48.7336316, lng: 18.2827629 },
    { lat: 48.7335658, lng: 18.2827979 },
    { lat: 48.7334705, lng: 18.2828691 },
    { lat: 48.733383, lng: 18.2829555 },
    { lat: 48.7333594, lng: 18.2829791 },
    { lat: 48.7333018, lng: 18.2830363 },
    { lat: 48.7332109, lng: 18.2831252 },
    { lat: 48.7331767, lng: 18.2831592 },
    { lat: 48.7329829, lng: 18.2832631 },
    { lat: 48.7328526, lng: 18.2833326 },
    { lat: 48.7327113, lng: 18.2834081 },
    { lat: 48.7326631, lng: 18.283434 },
    { lat: 48.7326157, lng: 18.2834341 },
    { lat: 48.732512, lng: 18.2834343 },
    { lat: 48.7323811, lng: 18.2834347 },
    { lat: 48.7322818, lng: 18.2834349 },
    { lat: 48.732187, lng: 18.2834352 },
    { lat: 48.7320901, lng: 18.2834354 },
    { lat: 48.731993, lng: 18.2834351 },
    { lat: 48.7319629, lng: 18.28346 },
    { lat: 48.7319047, lng: 18.2835097 },
    { lat: 48.7318691, lng: 18.2835391 },
    { lat: 48.7318358, lng: 18.2835678 },
    { lat: 48.7316582, lng: 18.2837173 },
    { lat: 48.7315723, lng: 18.2837349 },
    { lat: 48.7315477, lng: 18.2837399 },
    { lat: 48.7315109, lng: 18.2837472 },
    { lat: 48.731348, lng: 18.2837806 },
    { lat: 48.7313402, lng: 18.2837822 },
    { lat: 48.7312164, lng: 18.2838075 },
    { lat: 48.7311574, lng: 18.2838413 },
    { lat: 48.7311181, lng: 18.2838642 },
    { lat: 48.7309738, lng: 18.2839469 },
    { lat: 48.7309352, lng: 18.2839625 },
    { lat: 48.7308953, lng: 18.2839741 },
    { lat: 48.7308917, lng: 18.2839708 },
    { lat: 48.730882, lng: 18.2839571 },
    { lat: 48.7308744, lng: 18.2839627 },
    { lat: 48.7308493, lng: 18.2839795 },
    { lat: 48.7307742, lng: 18.2840086 },
    { lat: 48.7306361, lng: 18.284 },
    { lat: 48.7305079, lng: 18.284027 },
    { lat: 48.7304557, lng: 18.2840416 },
    { lat: 48.7303324, lng: 18.2840766 },
    { lat: 48.7301298, lng: 18.284105 },
    { lat: 48.7301174, lng: 18.2841045 },
    { lat: 48.7301152, lng: 18.2841044 },
    { lat: 48.7298359, lng: 18.2841964 },
    { lat: 48.7293253, lng: 18.2843827 },
    { lat: 48.7291319, lng: 18.2844748 },
    { lat: 48.7289341, lng: 18.2845471 },
    { lat: 48.7288805, lng: 18.2845769 },
    { lat: 48.7287715, lng: 18.2846039 },
    { lat: 48.7284806, lng: 18.2847481 },
    { lat: 48.728335, lng: 18.2847876 },
    { lat: 48.7283294, lng: 18.2847681 },
    { lat: 48.7276239, lng: 18.2850998 },
    { lat: 48.727347, lng: 18.2852295 },
    { lat: 48.7272854, lng: 18.2852805 },
    { lat: 48.726967, lng: 18.285196 },
    { lat: 48.7269616, lng: 18.28528 },
    { lat: 48.7269743, lng: 18.2853427 },
    { lat: 48.726798, lng: 18.2855787 },
    { lat: 48.7265421, lng: 18.2859205 },
    { lat: 48.7265099, lng: 18.2859695 },
    { lat: 48.7264726, lng: 18.2860267 },
    { lat: 48.7264529, lng: 18.2860582 },
    { lat: 48.7264269, lng: 18.286097 },
    { lat: 48.726401, lng: 18.2861378 },
    { lat: 48.7263431, lng: 18.2861911 },
    { lat: 48.7263339, lng: 18.2862068 },
    { lat: 48.7263224, lng: 18.2862241 },
    { lat: 48.7263018, lng: 18.286259 },
    { lat: 48.7262636, lng: 18.2863215 },
    { lat: 48.7262028, lng: 18.2864216 },
    { lat: 48.7261903, lng: 18.2864416 },
    { lat: 48.726178, lng: 18.2864614 },
    { lat: 48.7261625, lng: 18.2864868 },
    { lat: 48.7261521, lng: 18.2865035 },
    { lat: 48.726117, lng: 18.286561 },
    { lat: 48.7261099, lng: 18.2865739 },
    { lat: 48.7260251, lng: 18.2867119 },
    { lat: 48.7258694, lng: 18.2869492 },
    { lat: 48.7257822, lng: 18.2870824 },
    { lat: 48.7255613, lng: 18.2874053 },
    { lat: 48.7252746, lng: 18.2878018 },
    { lat: 48.7251865, lng: 18.2878788 },
    { lat: 48.7248816, lng: 18.2882935 },
    { lat: 48.7246587, lng: 18.2886398 },
    { lat: 48.7244733, lng: 18.2890111 },
    { lat: 48.7243895, lng: 18.2891038 },
    { lat: 48.7244342, lng: 18.2891565 },
    { lat: 48.7243565, lng: 18.2893004 },
    { lat: 48.7243158, lng: 18.289376 },
    { lat: 48.7242585, lng: 18.2894819 },
    { lat: 48.7237048, lng: 18.2906395 },
    { lat: 48.7231377, lng: 18.2917802 },
    { lat: 48.7226407, lng: 18.292548 },
    { lat: 48.7226085, lng: 18.292619 },
    { lat: 48.7225855, lng: 18.2926689 },
    { lat: 48.7225625, lng: 18.2927196 },
    { lat: 48.7224821, lng: 18.2928946 },
    { lat: 48.7230546, lng: 18.2934996 },
    { lat: 48.7234545, lng: 18.2941257 },
    { lat: 48.7237092, lng: 18.2947783 },
    { lat: 48.7238378, lng: 18.2951556 },
    { lat: 48.7238634, lng: 18.2952274 },
    { lat: 48.7241244, lng: 18.2959903 },
    { lat: 48.7244998, lng: 18.2970225 },
    { lat: 48.7245124, lng: 18.2970438 },
    { lat: 48.7250549, lng: 18.2980377 },
    { lat: 48.7250889, lng: 18.2980995 },
    { lat: 48.7253632, lng: 18.2990569 },
    { lat: 48.7253859, lng: 18.2990395 },
    { lat: 48.7261531, lng: 18.300712 },
    { lat: 48.7262075, lng: 18.3013543 },
    { lat: 48.7264297, lng: 18.3025492 },
    { lat: 48.7269055, lng: 18.3034193 },
    { lat: 48.7270935, lng: 18.3044886 },
    { lat: 48.7270702, lng: 18.3053925 },
    { lat: 48.7270525, lng: 18.3063167 },
    { lat: 48.7272493, lng: 18.3071044 },
    { lat: 48.7276252, lng: 18.3082814 },
    { lat: 48.7281979, lng: 18.3093505 },
    { lat: 48.7284496, lng: 18.3096915 },
    { lat: 48.728569, lng: 18.3100291 },
    { lat: 48.7286662, lng: 18.3105712 },
    { lat: 48.7290656, lng: 18.311769 },
    { lat: 48.7293977, lng: 18.3124121 },
    { lat: 48.7294867, lng: 18.3129691 },
    { lat: 48.7296194, lng: 18.3133215 },
    { lat: 48.7302534, lng: 18.3141588 },
    { lat: 48.7306622, lng: 18.3155822 },
  ],
  KrásnaVes: [
    { lat: 48.824226, lng: 18.211786 },
    { lat: 48.823546, lng: 18.211963 },
    { lat: 48.82275, lng: 18.212027 },
    { lat: 48.822503, lng: 18.212074 },
    { lat: 48.822299, lng: 18.212185 },
    { lat: 48.822307, lng: 18.212206 },
    { lat: 48.8224214, lng: 18.2125231 },
    { lat: 48.8227111, lng: 18.2130483 },
    { lat: 48.8228576, lng: 18.2133399 },
    { lat: 48.8229907, lng: 18.2134803 },
    { lat: 48.8230682, lng: 18.2138254 },
    { lat: 48.8232433, lng: 18.2149715 },
    { lat: 48.8233036, lng: 18.2153965 },
    { lat: 48.8233165, lng: 18.2156584 },
    { lat: 48.8232836, lng: 18.2159714 },
    { lat: 48.8233003, lng: 18.2164412 },
    { lat: 48.8232445, lng: 18.2173162 },
    { lat: 48.8232457, lng: 18.2179695 },
    { lat: 48.8232605, lng: 18.2185466 },
    { lat: 48.8233135, lng: 18.2195298 },
    { lat: 48.8229496, lng: 18.2195181 },
    { lat: 48.822729, lng: 18.2207903 },
    { lat: 48.8222973, lng: 18.2206013 },
    { lat: 48.8222817, lng: 18.2217507 },
    { lat: 48.8221442, lng: 18.2217526 },
    { lat: 48.8221608, lng: 18.222555 },
    { lat: 48.8214763, lng: 18.2224844 },
    { lat: 48.8208713, lng: 18.2224997 },
    { lat: 48.8208651, lng: 18.2227673 },
    { lat: 48.8208305, lng: 18.2239388 },
    { lat: 48.8205058, lng: 18.2240207 },
    { lat: 48.8203147, lng: 18.2242105 },
    { lat: 48.8199862, lng: 18.2250184 },
    { lat: 48.8200009, lng: 18.2260255 },
    { lat: 48.8200117, lng: 18.2269615 },
    { lat: 48.8193207, lng: 18.2271925 },
    { lat: 48.8194146, lng: 18.2280442 },
    { lat: 48.8195002, lng: 18.2287516 },
    { lat: 48.8195893, lng: 18.2293855 },
    { lat: 48.819645, lng: 18.2297255 },
    { lat: 48.8197764, lng: 18.2305866 },
    { lat: 48.8199146, lng: 18.231565 },
    { lat: 48.8199107, lng: 18.2316904 },
    { lat: 48.8198911, lng: 18.231761 },
    { lat: 48.8198448, lng: 18.2318613 },
    { lat: 48.8197303, lng: 18.2320098 },
    { lat: 48.8192949, lng: 18.2325463 },
    { lat: 48.8192941, lng: 18.2326309 },
    { lat: 48.8193154, lng: 18.2327058 },
    { lat: 48.8190806, lng: 18.2327061 },
    { lat: 48.8190631, lng: 18.2327346 },
    { lat: 48.8190405, lng: 18.2327952 },
    { lat: 48.8190266, lng: 18.2329452 },
    { lat: 48.8190392, lng: 18.2332272 },
    { lat: 48.8190212, lng: 18.2333258 },
    { lat: 48.8189611, lng: 18.2334214 },
    { lat: 48.8189172, lng: 18.233459 },
    { lat: 48.8187883, lng: 18.2334956 },
    { lat: 48.8187603, lng: 18.2334771 },
    { lat: 48.8187436, lng: 18.2334424 },
    { lat: 48.8187309, lng: 18.2333982 },
    { lat: 48.818728, lng: 18.2333474 },
    { lat: 48.8187752, lng: 18.2329108 },
    { lat: 48.8187671, lng: 18.2328686 },
    { lat: 48.8187498, lng: 18.2328422 },
    { lat: 48.8187272, lng: 18.232819 },
    { lat: 48.8184172, lng: 18.2328274 },
    { lat: 48.8183795, lng: 18.2328377 },
    { lat: 48.8183642, lng: 18.2328479 },
    { lat: 48.8183466, lng: 18.2329111 },
    { lat: 48.8183354, lng: 18.2329344 },
    { lat: 48.8183201, lng: 18.232945 },
    { lat: 48.8182993, lng: 18.2329524 },
    { lat: 48.8182022, lng: 18.2329329 },
    { lat: 48.8181347, lng: 18.2328984 },
    { lat: 48.8181088, lng: 18.2328596 },
    { lat: 48.8181029, lng: 18.2328342 },
    { lat: 48.8181013, lng: 18.2328072 },
    { lat: 48.8181284, lng: 18.2325319 },
    { lat: 48.8181246, lng: 18.2324035 },
    { lat: 48.8180754, lng: 18.2322739 },
    { lat: 48.8180537, lng: 18.2322509 },
    { lat: 48.8180318, lng: 18.2322438 },
    { lat: 48.818007, lng: 18.2322445 },
    { lat: 48.8179588, lng: 18.2322662 },
    { lat: 48.8178796, lng: 18.2323808 },
    { lat: 48.8178091, lng: 18.2325089 },
    { lat: 48.81763, lng: 18.2326144 },
    { lat: 48.8175061, lng: 18.232719 },
    { lat: 48.8174289, lng: 18.2327707 },
    { lat: 48.8174168, lng: 18.2327733 },
    { lat: 48.8173955, lng: 18.2327564 },
    { lat: 48.8173794, lng: 18.232731 },
    { lat: 48.8173774, lng: 18.2327169 },
    { lat: 48.8173965, lng: 18.2326542 },
    { lat: 48.8173606, lng: 18.2326206 },
    { lat: 48.8170918, lng: 18.2327758 },
    { lat: 48.8169844, lng: 18.2328496 },
    { lat: 48.817251, lng: 18.2340177 },
    { lat: 48.8175821, lng: 18.2353532 },
    { lat: 48.8174487, lng: 18.2354269 },
    { lat: 48.8176973, lng: 18.2366178 },
    { lat: 48.8180515, lng: 18.2383369 },
    { lat: 48.8185631, lng: 18.2380294 },
    { lat: 48.8191644, lng: 18.2376531 },
    { lat: 48.8197922, lng: 18.2372645 },
    { lat: 48.8202263, lng: 18.2369248 },
    { lat: 48.820576, lng: 18.2365843 },
    { lat: 48.8208248, lng: 18.2364576 },
    { lat: 48.8209187, lng: 18.2366404 },
    { lat: 48.8217153, lng: 18.236876 },
    { lat: 48.8222199, lng: 18.2371175 },
    { lat: 48.82264, lng: 18.2374385 },
    { lat: 48.8231473, lng: 18.2380839 },
    { lat: 48.8234527, lng: 18.2393624 },
    { lat: 48.8236823, lng: 18.2403028 },
    { lat: 48.8237769, lng: 18.2409138 },
    { lat: 48.8238654, lng: 18.241515 },
    { lat: 48.8240338, lng: 18.2425081 },
    { lat: 48.8248787, lng: 18.2439112 },
    { lat: 48.8255274, lng: 18.2450747 },
    { lat: 48.8268643, lng: 18.2512505 },
    { lat: 48.8275616, lng: 18.2526183 },
    { lat: 48.8286254, lng: 18.2512285 },
    { lat: 48.8302771, lng: 18.2499774 },
    { lat: 48.8303698, lng: 18.2497246 },
    { lat: 48.8310243, lng: 18.2489107 },
    { lat: 48.8312688, lng: 18.2484296 },
    { lat: 48.8316211, lng: 18.2480774 },
    { lat: 48.8332135, lng: 18.2473291 },
    { lat: 48.8340231, lng: 18.2473436 },
    { lat: 48.8345968, lng: 18.2470537 },
    { lat: 48.8352096, lng: 18.2466796 },
    { lat: 48.8357987, lng: 18.2463662 },
    { lat: 48.8362385, lng: 18.2459272 },
    { lat: 48.836549, lng: 18.245671 },
    { lat: 48.8369732, lng: 18.245156 },
    { lat: 48.8373486, lng: 18.244715 },
    { lat: 48.8377081, lng: 18.2441905 },
    { lat: 48.8384253, lng: 18.2434119 },
    { lat: 48.8389894, lng: 18.2427976 },
    { lat: 48.8390184, lng: 18.2422572 },
    { lat: 48.8390828, lng: 18.2414289 },
    { lat: 48.8393965, lng: 18.2405984 },
    { lat: 48.840065, lng: 18.239754 },
    { lat: 48.840561, lng: 18.2392728 },
    { lat: 48.8410856, lng: 18.2389348 },
    { lat: 48.8412854, lng: 18.2387575 },
    { lat: 48.8414103, lng: 18.2406328 },
    { lat: 48.8416363, lng: 18.2408547 },
    { lat: 48.8417011, lng: 18.2406073 },
    { lat: 48.8416905, lng: 18.2403003 },
    { lat: 48.8417519, lng: 18.2400716 },
    { lat: 48.8418278, lng: 18.2399599 },
    { lat: 48.8419123, lng: 18.2398783 },
    { lat: 48.8420505, lng: 18.2396102 },
    { lat: 48.8421105, lng: 18.239555 },
    { lat: 48.842164, lng: 18.2395444 },
    { lat: 48.8421909, lng: 18.2394603 },
    { lat: 48.842209, lng: 18.2392233 },
    { lat: 48.8422582, lng: 18.2390983 },
    { lat: 48.8422805, lng: 18.2387977 },
    { lat: 48.8421841, lng: 18.2385907 },
    { lat: 48.8422391, lng: 18.2379928 },
    { lat: 48.8425399, lng: 18.2381215 },
    { lat: 48.8427393, lng: 18.2382139 },
    { lat: 48.8427949, lng: 18.2382808 },
    { lat: 48.8429114, lng: 18.2382838 },
    { lat: 48.8428905, lng: 18.2379763 },
    { lat: 48.8428159, lng: 18.2373603 },
    { lat: 48.8427229, lng: 18.2368985 },
    { lat: 48.8424958, lng: 18.2360016 },
    { lat: 48.8430581, lng: 18.2357753 },
    { lat: 48.842915, lng: 18.2342942 },
    { lat: 48.8434766, lng: 18.2341507 },
    { lat: 48.843481, lng: 18.2341072 },
    { lat: 48.843492, lng: 18.2332313 },
    { lat: 48.8434845, lng: 18.2329851 },
    { lat: 48.8446338, lng: 18.2326611 },
    { lat: 48.845592, lng: 18.2324836 },
    { lat: 48.8469256, lng: 18.232858 },
    { lat: 48.8481334, lng: 18.232469 },
    { lat: 48.8487408, lng: 18.2322998 },
    { lat: 48.8495579, lng: 18.23236 },
    { lat: 48.8500164, lng: 18.2325271 },
    { lat: 48.8504023, lng: 18.2324865 },
    { lat: 48.8507978, lng: 18.2324773 },
    { lat: 48.8512367, lng: 18.2325191 },
    { lat: 48.8514608, lng: 18.2326101 },
    { lat: 48.8519504, lng: 18.2326448 },
    { lat: 48.8526593, lng: 18.2325719 },
    { lat: 48.8529916, lng: 18.2338075 },
    { lat: 48.8535352, lng: 18.2341182 },
    { lat: 48.8546526, lng: 18.2346615 },
    { lat: 48.8553115, lng: 18.2346594 },
    { lat: 48.8556438, lng: 18.2345119 },
    { lat: 48.8559446, lng: 18.2342855 },
    { lat: 48.8565292, lng: 18.2343078 },
    { lat: 48.8567023, lng: 18.2342969 },
    { lat: 48.8568195, lng: 18.2341748 },
    { lat: 48.8570677, lng: 18.234153 },
    { lat: 48.857421, lng: 18.2341576 },
    { lat: 48.8578583, lng: 18.2339892 },
    { lat: 48.8589534, lng: 18.2333454 },
    { lat: 48.8605586, lng: 18.2343484 },
    { lat: 48.8600618, lng: 18.2351929 },
    { lat: 48.860213, lng: 18.2364388 },
    { lat: 48.8604231, lng: 18.2366521 },
    { lat: 48.8607067, lng: 18.2370866 },
    { lat: 48.8610004, lng: 18.2379637 },
    { lat: 48.8610818, lng: 18.2381933 },
    { lat: 48.8616923, lng: 18.2392048 },
    { lat: 48.8616809, lng: 18.239882 },
    { lat: 48.8617735, lng: 18.2401791 },
    { lat: 48.8620107, lng: 18.2404204 },
    { lat: 48.8621403, lng: 18.2406367 },
    { lat: 48.8623638, lng: 18.2408372 },
    { lat: 48.8625876, lng: 18.2409799 },
    { lat: 48.8629725, lng: 18.241262 },
    { lat: 48.8632414, lng: 18.2412293 },
    { lat: 48.863513, lng: 18.2413618 },
    { lat: 48.8637223, lng: 18.2416072 },
    { lat: 48.8638651, lng: 18.2418354 },
    { lat: 48.8641594, lng: 18.2421115 },
    { lat: 48.8642926, lng: 18.2423872 },
    { lat: 48.8644129, lng: 18.2425614 },
    { lat: 48.8645743, lng: 18.2427378 },
    { lat: 48.8647708, lng: 18.2429005 },
    { lat: 48.8648853, lng: 18.2429669 },
    { lat: 48.8651463, lng: 18.2430324 },
    { lat: 48.8656238, lng: 18.2430605 },
    { lat: 48.8657202, lng: 18.2432042 },
    { lat: 48.8660652, lng: 18.2436367 },
    { lat: 48.8664029, lng: 18.2437423 },
    { lat: 48.8669661, lng: 18.2442392 },
    { lat: 48.8670773, lng: 18.2444106 },
    { lat: 48.8671796, lng: 18.2446596 },
    { lat: 48.8672272, lng: 18.2449017 },
    { lat: 48.8672697, lng: 18.2452686 },
    { lat: 48.8672672, lng: 18.2455828 },
    { lat: 48.8673356, lng: 18.2458531 },
    { lat: 48.8674787, lng: 18.2466802 },
    { lat: 48.867514, lng: 18.2475995 },
    { lat: 48.8676883, lng: 18.2481659 },
    { lat: 48.8677664, lng: 18.2483449 },
    { lat: 48.8679623, lng: 18.2486547 },
    { lat: 48.8680257, lng: 18.2488378 },
    { lat: 48.868072, lng: 18.249017 },
    { lat: 48.8680844, lng: 18.2491975 },
    { lat: 48.8681269, lng: 18.2493866 },
    { lat: 48.8682555, lng: 18.2496651 },
    { lat: 48.8685623, lng: 18.2501579 },
    { lat: 48.8689667, lng: 18.2505458 },
    { lat: 48.86917, lng: 18.2506454 },
    { lat: 48.8694805, lng: 18.2507281 },
    { lat: 48.8697155, lng: 18.2508332 },
    { lat: 48.8701669, lng: 18.2508541 },
    { lat: 48.8704412, lng: 18.2507795 },
    { lat: 48.8706885, lng: 18.2507827 },
    { lat: 48.8709973, lng: 18.2509541 },
    { lat: 48.8711796, lng: 18.2511615 },
    { lat: 48.8714826, lng: 18.2514143 },
    { lat: 48.8719526, lng: 18.2509468 },
    { lat: 48.8725394, lng: 18.2517038 },
    { lat: 48.8729642, lng: 18.2519303 },
    { lat: 48.8732709, lng: 18.2523426 },
    { lat: 48.8739264, lng: 18.2524816 },
    { lat: 48.8742329, lng: 18.2526742 },
    { lat: 48.8745375, lng: 18.2530078 },
    { lat: 48.8746912, lng: 18.2532906 },
    { lat: 48.8765254, lng: 18.25507 },
    { lat: 48.8776627, lng: 18.2557029 },
    { lat: 48.8794467, lng: 18.2571342 },
    { lat: 48.8798788, lng: 18.2572741 },
    { lat: 48.880101, lng: 18.258156 },
    { lat: 48.879614, lng: 18.255471 },
    { lat: 48.879284, lng: 18.253764 },
    { lat: 48.878813, lng: 18.251312 },
    { lat: 48.878402, lng: 18.249268 },
    { lat: 48.877583, lng: 18.247052 },
    { lat: 48.876738, lng: 18.244845 },
    { lat: 48.876326, lng: 18.243715 },
    { lat: 48.875897, lng: 18.242636 },
    { lat: 48.875426, lng: 18.241387 },
    { lat: 48.874336, lng: 18.239805 },
    { lat: 48.874013, lng: 18.238314 },
    { lat: 48.87386, lng: 18.237307 },
    { lat: 48.873728, lng: 18.235239 },
    { lat: 48.872937, lng: 18.231403 },
    { lat: 48.872807, lng: 18.230988 },
    { lat: 48.872775, lng: 18.230888 },
    { lat: 48.872121, lng: 18.228797 },
    { lat: 48.872111, lng: 18.228761 },
    { lat: 48.871979, lng: 18.228361 },
    { lat: 48.870991, lng: 18.228181 },
    { lat: 48.870306, lng: 18.228063 },
    { lat: 48.87023, lng: 18.227983 },
    { lat: 48.869972, lng: 18.227472 },
    { lat: 48.869647, lng: 18.227055 },
    { lat: 48.869525, lng: 18.227113 },
    { lat: 48.868578, lng: 18.227654 },
    { lat: 48.86846, lng: 18.227696 },
    { lat: 48.868202, lng: 18.227753 },
    { lat: 48.867901, lng: 18.227783 },
    { lat: 48.867561, lng: 18.227719 },
    { lat: 48.867406, lng: 18.227725 },
    { lat: 48.867095, lng: 18.227709 },
    { lat: 48.867062, lng: 18.227705 },
    { lat: 48.866791, lng: 18.22768 },
    { lat: 48.865947, lng: 18.227802 },
    { lat: 48.865476, lng: 18.227836 },
    { lat: 48.865201, lng: 18.227951 },
    { lat: 48.864984, lng: 18.227937 },
    { lat: 48.864429, lng: 18.228477 },
    { lat: 48.864149, lng: 18.228818 },
    { lat: 48.863184, lng: 18.229019 },
    { lat: 48.862823, lng: 18.229329 },
    { lat: 48.86209, lng: 18.230046 },
    { lat: 48.861919, lng: 18.229872 },
    { lat: 48.86181, lng: 18.229797 },
    { lat: 48.861519, lng: 18.229689 },
    { lat: 48.861392, lng: 18.229591 },
    { lat: 48.861296, lng: 18.229436 },
    { lat: 48.861208, lng: 18.229167 },
    { lat: 48.860943, lng: 18.228843 },
    { lat: 48.860616, lng: 18.228079 },
    { lat: 48.860424, lng: 18.227926 },
    { lat: 48.860122, lng: 18.227038 },
    { lat: 48.859963, lng: 18.226745 },
    { lat: 48.859731, lng: 18.226352 },
    { lat: 48.85961, lng: 18.226184 },
    { lat: 48.858991, lng: 18.225045 },
    { lat: 48.85864, lng: 18.224379 },
    { lat: 48.858376, lng: 18.224102 },
    { lat: 48.857554, lng: 18.223014 },
    { lat: 48.856905, lng: 18.22291 },
    { lat: 48.856507, lng: 18.222144 },
    { lat: 48.856012, lng: 18.221184 },
    { lat: 48.855603, lng: 18.220768 },
    { lat: 48.855401, lng: 18.220548 },
    { lat: 48.855126, lng: 18.220112 },
    { lat: 48.854764, lng: 18.22006 },
    { lat: 48.854261, lng: 18.219921 },
    { lat: 48.853641, lng: 18.219936 },
    { lat: 48.853018, lng: 18.219584 },
    { lat: 48.852548, lng: 18.219227 },
    { lat: 48.851628, lng: 18.218887 },
    { lat: 48.851019, lng: 18.218821 },
    { lat: 48.850593, lng: 18.218906 },
    { lat: 48.850141, lng: 18.218261 },
    { lat: 48.849498, lng: 18.218194 },
    { lat: 48.848791, lng: 18.218306 },
    { lat: 48.848247, lng: 18.217803 },
    { lat: 48.848016, lng: 18.217751 },
    { lat: 48.847783, lng: 18.217638 },
    { lat: 48.847013, lng: 18.216883 },
    { lat: 48.846915, lng: 18.216777 },
    { lat: 48.846221, lng: 18.216342 },
    { lat: 48.846116, lng: 18.216229 },
    { lat: 48.845886, lng: 18.215796 },
    { lat: 48.845444, lng: 18.214288 },
    { lat: 48.844405, lng: 18.214367 },
    { lat: 48.844177, lng: 18.214198 },
    { lat: 48.843887, lng: 18.213857 },
    { lat: 48.843862, lng: 18.213829 },
    { lat: 48.843826, lng: 18.213787 },
    { lat: 48.8438, lng: 18.21376 },
    { lat: 48.843785, lng: 18.213742 },
    { lat: 48.842991, lng: 18.213362 },
    { lat: 48.84232, lng: 18.213128 },
    { lat: 48.841857, lng: 18.212709 },
    { lat: 48.8417, lng: 18.212702 },
    { lat: 48.841268, lng: 18.212657 },
    { lat: 48.840252, lng: 18.212682 },
    { lat: 48.839762, lng: 18.211953 },
    { lat: 48.83945, lng: 18.211575 },
    { lat: 48.839236, lng: 18.211394 },
    { lat: 48.839044, lng: 18.211273 },
    { lat: 48.838866, lng: 18.211275 },
    { lat: 48.838522, lng: 18.211147 },
    { lat: 48.838267, lng: 18.211097 },
    { lat: 48.837823, lng: 18.21134 },
    { lat: 48.837527, lng: 18.211384 },
    { lat: 48.836971, lng: 18.21121 },
    { lat: 48.836674, lng: 18.210963 },
    { lat: 48.836561, lng: 18.211053 },
    { lat: 48.836275, lng: 18.211592 },
    { lat: 48.835818, lng: 18.212133 },
    { lat: 48.834406, lng: 18.212655 },
    { lat: 48.833522, lng: 18.21304 },
    { lat: 48.833135, lng: 18.213087 },
    { lat: 48.83271, lng: 18.212742 },
    { lat: 48.8322, lng: 18.212372 },
    { lat: 48.831282, lng: 18.211975 },
    { lat: 48.830817, lng: 18.212044 },
    { lat: 48.830674, lng: 18.212127 },
    { lat: 48.830395, lng: 18.212179 },
    { lat: 48.830213, lng: 18.212241 },
    { lat: 48.829856, lng: 18.212463 },
    { lat: 48.829436, lng: 18.212617 },
    { lat: 48.829045, lng: 18.212757 },
    { lat: 48.828553, lng: 18.21301 },
    { lat: 48.827895, lng: 18.213196 },
    { lat: 48.827462, lng: 18.213034 },
    { lat: 48.826822, lng: 18.212934 },
    { lat: 48.826705, lng: 18.212961 },
    { lat: 48.826466, lng: 18.212925 },
    { lat: 48.826263, lng: 18.212808 },
    { lat: 48.826098, lng: 18.212624 },
    { lat: 48.825806, lng: 18.212067 },
    { lat: 48.825692, lng: 18.211886 },
    { lat: 48.825608, lng: 18.211867 },
    { lat: 48.825419, lng: 18.21177 },
    { lat: 48.824993, lng: 18.211739 },
    { lat: 48.824226, lng: 18.211786 },
  ],
  Libichava: [
    { lat: 48.6686613, lng: 18.1599138 },
    { lat: 48.6682075, lng: 18.1601268 },
    { lat: 48.6678509, lng: 18.160245 },
    { lat: 48.6674752, lng: 18.160381 },
    { lat: 48.6668082, lng: 18.1602059 },
    { lat: 48.6657574, lng: 18.1599873 },
    { lat: 48.6653404, lng: 18.159761 },
    { lat: 48.665026, lng: 18.1595351 },
    { lat: 48.664969, lng: 18.1594895 },
    { lat: 48.6648895, lng: 18.1595739 },
    { lat: 48.6648195, lng: 18.1598487 },
    { lat: 48.6646851, lng: 18.1599653 },
    { lat: 48.6643741, lng: 18.1599721 },
    { lat: 48.6642998, lng: 18.1600326 },
    { lat: 48.6642274, lng: 18.1601661 },
    { lat: 48.6641791, lng: 18.1602103 },
    { lat: 48.6641078, lng: 18.1602036 },
    { lat: 48.6636851, lng: 18.1601363 },
    { lat: 48.6634785, lng: 18.1606201 },
    { lat: 48.6633088, lng: 18.1610045 },
    { lat: 48.6631517, lng: 18.1613719 },
    { lat: 48.6626955, lng: 18.1624148 },
    { lat: 48.6622701, lng: 18.1632563 },
    { lat: 48.6620382, lng: 18.1638495 },
    { lat: 48.6613977, lng: 18.165163 },
    { lat: 48.6612273, lng: 18.1655153 },
    { lat: 48.660895, lng: 18.1663645 },
    { lat: 48.660403, lng: 18.1675145 },
    { lat: 48.6603068, lng: 18.1677446 },
    { lat: 48.6606051, lng: 18.1680408 },
    { lat: 48.6608608, lng: 18.1682859 },
    { lat: 48.6611289, lng: 18.1685504 },
    { lat: 48.661157, lng: 18.1685881 },
    { lat: 48.6619833, lng: 18.1698028 },
    { lat: 48.6620894, lng: 18.1699612 },
    { lat: 48.6620548, lng: 18.1700243 },
    { lat: 48.6620716, lng: 18.170041 },
    { lat: 48.6620633, lng: 18.1701314 },
    { lat: 48.6620475, lng: 18.1701524 },
    { lat: 48.6619877, lng: 18.1701517 },
    { lat: 48.662012, lng: 18.1702627 },
    { lat: 48.662003, lng: 18.1702982 },
    { lat: 48.6617992, lng: 18.1704828 },
    { lat: 48.6616541, lng: 18.1706847 },
    { lat: 48.6616606, lng: 18.1706999 },
    { lat: 48.6616631, lng: 18.1707267 },
    { lat: 48.6616283, lng: 18.170807 },
    { lat: 48.6613865, lng: 18.1712094 },
    { lat: 48.6610266, lng: 18.1716384 },
    { lat: 48.6608461, lng: 18.1718956 },
    { lat: 48.6608106, lng: 18.1720623 },
    { lat: 48.6607703, lng: 18.1721669 },
    { lat: 48.6610082, lng: 18.1725521 },
    { lat: 48.6609299, lng: 18.172702 },
    { lat: 48.6610528, lng: 18.172795 },
    { lat: 48.6612206, lng: 18.1729089 },
    { lat: 48.6612774, lng: 18.1729136 },
    { lat: 48.6613172, lng: 18.1729398 },
    { lat: 48.6620074, lng: 18.1733425 },
    { lat: 48.6627261, lng: 18.1737697 },
    { lat: 48.6629559, lng: 18.1739154 },
    { lat: 48.6633846, lng: 18.1742474 },
    { lat: 48.6639777, lng: 18.1747265 },
    { lat: 48.6645181, lng: 18.175194 },
    { lat: 48.6646193, lng: 18.1753049 },
    { lat: 48.6649972, lng: 18.1756931 },
    { lat: 48.6654629, lng: 18.1761933 },
    { lat: 48.665696, lng: 18.1764593 },
    { lat: 48.666405, lng: 18.1773441 },
    { lat: 48.6664262, lng: 18.177457 },
    { lat: 48.6665048, lng: 18.1777408 },
    { lat: 48.6668034, lng: 18.1782802 },
    { lat: 48.6670019, lng: 18.1786307 },
    { lat: 48.6672287, lng: 18.1790239 },
    { lat: 48.6677836, lng: 18.1799354 },
    { lat: 48.6679833, lng: 18.1802483 },
    { lat: 48.6681765, lng: 18.1805638 },
    { lat: 48.668563, lng: 18.1811599 },
    { lat: 48.6688488, lng: 18.1815756 },
    { lat: 48.6689471, lng: 18.1817555 },
    { lat: 48.6691482, lng: 18.1820889 },
    { lat: 48.6693672, lng: 18.1824617 },
    { lat: 48.6696772, lng: 18.182965 },
    { lat: 48.669814, lng: 18.1831637 },
    { lat: 48.6700649, lng: 18.1835613 },
    { lat: 48.670406, lng: 18.1841748 },
    { lat: 48.6705631, lng: 18.1844708 },
    { lat: 48.670721, lng: 18.1847071 },
    { lat: 48.6712321, lng: 18.1853489 },
    { lat: 48.6713021, lng: 18.185525 },
    { lat: 48.6712532, lng: 18.1855993 },
    { lat: 48.6713639, lng: 18.1859124 },
    { lat: 48.6714776, lng: 18.1864148 },
    { lat: 48.6717143, lng: 18.1868414 },
    { lat: 48.67175, lng: 18.1872066 },
    { lat: 48.6719445, lng: 18.1872124 },
    { lat: 48.6725608, lng: 18.1866897 },
    { lat: 48.6729679, lng: 18.1874456 },
    { lat: 48.6733224, lng: 18.1880945 },
    { lat: 48.6736292, lng: 18.1886615 },
    { lat: 48.673685, lng: 18.1887481 },
    { lat: 48.6740384, lng: 18.1893207 },
    { lat: 48.6741727, lng: 18.1895337 },
    { lat: 48.6746899, lng: 18.1904812 },
    { lat: 48.6747109, lng: 18.1905138 },
    { lat: 48.6748352, lng: 18.1903761 },
    { lat: 48.6752331, lng: 18.1912117 },
    { lat: 48.6753681, lng: 18.1914382 },
    { lat: 48.6760357, lng: 18.1921935 },
    { lat: 48.6765423, lng: 18.1927532 },
    { lat: 48.6767801, lng: 18.193108 },
    { lat: 48.6770775, lng: 18.1934145 },
    { lat: 48.6770873, lng: 18.1933304 },
    { lat: 48.6773571, lng: 18.1924694 },
    { lat: 48.6776386, lng: 18.1915401 },
    { lat: 48.6778221, lng: 18.1909247 },
    { lat: 48.6779568, lng: 18.1908127 },
    { lat: 48.6779977, lng: 18.1907581 },
    { lat: 48.6781516, lng: 18.19061 },
    { lat: 48.6782238, lng: 18.1905909 },
    { lat: 48.6782872, lng: 18.1906336 },
    { lat: 48.678333, lng: 18.1906844 },
    { lat: 48.6784419, lng: 18.190655 },
    { lat: 48.6785287, lng: 18.1905758 },
    { lat: 48.6785923, lng: 18.190466 },
    { lat: 48.6786342, lng: 18.1904669 },
    { lat: 48.6787051, lng: 18.1905437 },
    { lat: 48.678794, lng: 18.1906173 },
    { lat: 48.6788923, lng: 18.1906358 },
    { lat: 48.6789736, lng: 18.1905777 },
    { lat: 48.6789616, lng: 18.1905093 },
    { lat: 48.6789884, lng: 18.1904457 },
    { lat: 48.6789894, lng: 18.1903649 },
    { lat: 48.6790994, lng: 18.1903165 },
    { lat: 48.6791084, lng: 18.1902761 },
    { lat: 48.6791548, lng: 18.19028 },
    { lat: 48.6792468, lng: 18.1903461 },
    { lat: 48.6792953, lng: 18.1903465 },
    { lat: 48.6793418, lng: 18.1903118 },
    { lat: 48.6793534, lng: 18.1901817 },
    { lat: 48.6793827, lng: 18.1900415 },
    { lat: 48.6794201, lng: 18.1899276 },
    { lat: 48.6794781, lng: 18.189859 },
    { lat: 48.6795663, lng: 18.189527 },
    { lat: 48.6796983, lng: 18.1894497 },
    { lat: 48.6798476, lng: 18.1894751 },
    { lat: 48.6799492, lng: 18.1896349 },
    { lat: 48.6800218, lng: 18.1895204 },
    { lat: 48.6800884, lng: 18.1893452 },
    { lat: 48.6801309, lng: 18.1892793 },
    { lat: 48.6801577, lng: 18.1892758 },
    { lat: 48.6801965, lng: 18.1893624 },
    { lat: 48.6802029, lng: 18.1894119 },
    { lat: 48.6802357, lng: 18.1894516 },
    { lat: 48.6803433, lng: 18.1893049 },
    { lat: 48.6805449, lng: 18.1888271 },
    { lat: 48.6805828, lng: 18.1888003 },
    { lat: 48.6806391, lng: 18.18864 },
    { lat: 48.6806563, lng: 18.1884921 },
    { lat: 48.680704, lng: 18.1883587 },
    { lat: 48.680798, lng: 18.1883042 },
    { lat: 48.6808846, lng: 18.1881607 },
    { lat: 48.6808883, lng: 18.1878777 },
    { lat: 48.6809152, lng: 18.1877942 },
    { lat: 48.6810403, lng: 18.1877121 },
    { lat: 48.6811636, lng: 18.1877958 },
    { lat: 48.681274, lng: 18.1878327 },
    { lat: 48.6814763, lng: 18.1876833 },
    { lat: 48.6815322, lng: 18.1876724 },
    { lat: 48.6815807, lng: 18.1876317 },
    { lat: 48.681632, lng: 18.1876232 },
    { lat: 48.6817849, lng: 18.1877902 },
    { lat: 48.6818344, lng: 18.1877658 },
    { lat: 48.6818598, lng: 18.1876782 },
    { lat: 48.6818562, lng: 18.1874808 },
    { lat: 48.6818189, lng: 18.187419 },
    { lat: 48.6817928, lng: 18.1872792 },
    { lat: 48.6818017, lng: 18.1872191 },
    { lat: 48.681737, lng: 18.1871358 },
    { lat: 48.6815817, lng: 18.1869067 },
    { lat: 48.6813575, lng: 18.1865408 },
    { lat: 48.6808179, lng: 18.1856969 },
    { lat: 48.6803395, lng: 18.1849543 },
    { lat: 48.6802171, lng: 18.1848079 },
    { lat: 48.6798256, lng: 18.1841824 },
    { lat: 48.679744, lng: 18.1840388 },
    { lat: 48.6797284, lng: 18.1839855 },
    { lat: 48.6795499, lng: 18.1835109 },
    { lat: 48.6793893, lng: 18.183056 },
    { lat: 48.6791303, lng: 18.1822845 },
    { lat: 48.6789047, lng: 18.1816204 },
    { lat: 48.6788366, lng: 18.1814562 },
    { lat: 48.6786781, lng: 18.1810175 },
    { lat: 48.6784895, lng: 18.1805067 },
    { lat: 48.6785112, lng: 18.1804153 },
    { lat: 48.6785946, lng: 18.1802557 },
    { lat: 48.678684, lng: 18.1801033 },
    { lat: 48.6786636, lng: 18.1799671 },
    { lat: 48.6786109, lng: 18.1798172 },
    { lat: 48.6785484, lng: 18.1797287 },
    { lat: 48.6784156, lng: 18.1794027 },
    { lat: 48.6782874, lng: 18.1791568 },
    { lat: 48.6781064, lng: 18.1787385 },
    { lat: 48.6779477, lng: 18.1782577 },
    { lat: 48.6777887, lng: 18.1776913 },
    { lat: 48.6777245, lng: 18.1774405 },
    { lat: 48.6776134, lng: 18.1770438 },
    { lat: 48.6775746, lng: 18.1768582 },
    { lat: 48.6774211, lng: 18.1763874 },
    { lat: 48.6773782, lng: 18.1762145 },
    { lat: 48.6772042, lng: 18.1755437 },
    { lat: 48.6771633, lng: 18.175347 },
    { lat: 48.6771247, lng: 18.1752035 },
    { lat: 48.6767955, lng: 18.174331 },
    { lat: 48.6766536, lng: 18.173983 },
    { lat: 48.6764253, lng: 18.1732564 },
    { lat: 48.6762711, lng: 18.1727572 },
    { lat: 48.6761801, lng: 18.172566 },
    { lat: 48.6760641, lng: 18.1721996 },
    { lat: 48.6759409, lng: 18.1716874 },
    { lat: 48.6757869, lng: 18.1711557 },
    { lat: 48.6755521, lng: 18.1706449 },
    { lat: 48.6750888, lng: 18.1700669 },
    { lat: 48.6750125, lng: 18.1698806 },
    { lat: 48.6747964, lng: 18.1694599 },
    { lat: 48.6746384, lng: 18.169029 },
    { lat: 48.6744959, lng: 18.1687893 },
    { lat: 48.6743753, lng: 18.168405 },
    { lat: 48.6742955, lng: 18.1680188 },
    { lat: 48.6739779, lng: 18.1675419 },
    { lat: 48.6736037, lng: 18.1671217 },
    { lat: 48.6733933, lng: 18.1668774 },
    { lat: 48.6729108, lng: 18.1663822 },
    { lat: 48.6728643, lng: 18.1663384 },
    { lat: 48.6726458, lng: 18.1666168 },
    { lat: 48.671941, lng: 18.1661199 },
    { lat: 48.6712255, lng: 18.1656095 },
    { lat: 48.6708255, lng: 18.1653088 },
    { lat: 48.6703989, lng: 18.1649381 },
    { lat: 48.670208, lng: 18.1647605 },
    { lat: 48.6700444, lng: 18.164566 },
    { lat: 48.6699409, lng: 18.1643951 },
    { lat: 48.669776, lng: 18.1640427 },
    { lat: 48.6693245, lng: 18.1629869 },
    { lat: 48.6692658, lng: 18.1625984 },
    { lat: 48.6692656, lng: 18.1625545 },
    { lat: 48.6690518, lng: 18.1619608 },
    { lat: 48.6689696, lng: 18.1615182 },
    { lat: 48.6688538, lng: 18.1606452 },
    { lat: 48.6687454, lng: 18.1601986 },
    { lat: 48.6686613, lng: 18.1599138 },
  ],
  MaláHradná: [
    { lat: 48.774662, lng: 18.153388 },
    { lat: 48.774131, lng: 18.152517 },
    { lat: 48.773595, lng: 18.151652 },
    { lat: 48.773386, lng: 18.151131 },
    { lat: 48.77334, lng: 18.151047 },
    { lat: 48.772903, lng: 18.150233 },
    { lat: 48.772767, lng: 18.150269 },
    { lat: 48.77256, lng: 18.150507 },
    { lat: 48.772336, lng: 18.150651 },
    { lat: 48.772216, lng: 18.150727 },
    { lat: 48.772181, lng: 18.1508 },
    { lat: 48.772079, lng: 18.151 },
    { lat: 48.771985, lng: 18.151169 },
    { lat: 48.771847, lng: 18.151408 },
    { lat: 48.771822, lng: 18.151452 },
    { lat: 48.771518, lng: 18.151849 },
    { lat: 48.771425, lng: 18.151916 },
    { lat: 48.771323, lng: 18.151989 },
    { lat: 48.771162, lng: 18.152179 },
    { lat: 48.770947, lng: 18.152244 },
    { lat: 48.770888, lng: 18.152262 },
    { lat: 48.77068, lng: 18.152263 },
    { lat: 48.770535, lng: 18.152513 },
    { lat: 48.770415, lng: 18.152642 },
    { lat: 48.770252, lng: 18.152815 },
    { lat: 48.770184, lng: 18.152886 },
    { lat: 48.770167, lng: 18.152904 },
    { lat: 48.769968, lng: 18.153294 },
    { lat: 48.769956, lng: 18.153323 },
    { lat: 48.76986, lng: 18.153562 },
    { lat: 48.769805, lng: 18.153738 },
    { lat: 48.769733, lng: 18.15384 },
    { lat: 48.769667, lng: 18.153758 },
    { lat: 48.769601, lng: 18.153927 },
    { lat: 48.769506, lng: 18.154209 },
    { lat: 48.769332, lng: 18.154753 },
    { lat: 48.769063, lng: 18.155236 },
    { lat: 48.768706, lng: 18.155791 },
    { lat: 48.768606, lng: 18.155932 },
    { lat: 48.76853, lng: 18.156116 },
    { lat: 48.768486, lng: 18.156217 },
    { lat: 48.768451, lng: 18.156264 },
    { lat: 48.768432, lng: 18.156303 },
    { lat: 48.768241, lng: 18.156549 },
    { lat: 48.768041, lng: 18.156865 },
    { lat: 48.768009, lng: 18.157239 },
    { lat: 48.767843, lng: 18.157529 },
    { lat: 48.767629, lng: 18.157687 },
    { lat: 48.767617, lng: 18.157695 },
    { lat: 48.767563, lng: 18.15772 },
    { lat: 48.767535, lng: 18.157727 },
    { lat: 48.767466, lng: 18.157739 },
    { lat: 48.767138, lng: 18.157674 },
    { lat: 48.766829, lng: 18.157614 },
    { lat: 48.766737, lng: 18.157626 },
    { lat: 48.766671, lng: 18.157663 },
    { lat: 48.766637, lng: 18.157693 },
    { lat: 48.766331, lng: 18.157983 },
    { lat: 48.766241, lng: 18.158068 },
    { lat: 48.765853, lng: 18.157693 },
    { lat: 48.765845, lng: 18.157628 },
    { lat: 48.765835, lng: 18.157561 },
    { lat: 48.765824, lng: 18.15751 },
    { lat: 48.76566, lng: 18.157323 },
    { lat: 48.765543, lng: 18.157217 },
    { lat: 48.76538, lng: 18.157105 },
    { lat: 48.765028, lng: 18.157012 },
    { lat: 48.76477, lng: 18.156708 },
    { lat: 48.764418, lng: 18.15753 },
    { lat: 48.764143, lng: 18.157071 },
    { lat: 48.76403, lng: 18.156849 },
    { lat: 48.76367, lng: 18.156047 },
    { lat: 48.763407, lng: 18.155622 },
    { lat: 48.762806, lng: 18.154976 },
    { lat: 48.762081, lng: 18.154204 },
    { lat: 48.76166, lng: 18.153749 },
    { lat: 48.761457, lng: 18.153488 },
    { lat: 48.761424, lng: 18.153445 },
    { lat: 48.761366, lng: 18.15337 },
    { lat: 48.761255, lng: 18.153201 },
    { lat: 48.761178, lng: 18.152972 },
    { lat: 48.760992, lng: 18.152202 },
    { lat: 48.760981, lng: 18.152144 },
    { lat: 48.760859, lng: 18.151275 },
    { lat: 48.760656, lng: 18.150492 },
    { lat: 48.760498, lng: 18.150147 },
    { lat: 48.760247, lng: 18.149674 },
    { lat: 48.76017, lng: 18.149518 },
    { lat: 48.760141, lng: 18.14944 },
    { lat: 48.760023, lng: 18.14912 },
    { lat: 48.759865, lng: 18.148683 },
    { lat: 48.75983, lng: 18.148371 },
    { lat: 48.759779, lng: 18.148151 },
    { lat: 48.759733, lng: 18.147922 },
    { lat: 48.75972, lng: 18.147876 },
    { lat: 48.759558, lng: 18.147657 },
    { lat: 48.759344, lng: 18.14739 },
    { lat: 48.759173, lng: 18.146846 },
    { lat: 48.759016, lng: 18.146351 },
    { lat: 48.758939, lng: 18.145932 },
    { lat: 48.758759, lng: 18.145156 },
    { lat: 48.758724, lng: 18.145017 },
    { lat: 48.758561, lng: 18.144119 },
    { lat: 48.7584, lng: 18.143269 },
    { lat: 48.758376, lng: 18.14229 },
    { lat: 48.758345, lng: 18.141318 },
    { lat: 48.758421, lng: 18.140919 },
    { lat: 48.758511, lng: 18.140511 },
    { lat: 48.758595, lng: 18.140103 },
    { lat: 48.758718, lng: 18.139589 },
    { lat: 48.75896, lng: 18.138593 },
    { lat: 48.759123, lng: 18.137892 },
    { lat: 48.75916, lng: 18.137734 },
    { lat: 48.759336, lng: 18.136376 },
    { lat: 48.759458, lng: 18.135751 },
    { lat: 48.759576, lng: 18.135118 },
    { lat: 48.759596, lng: 18.134344 },
    { lat: 48.759615, lng: 18.133577 },
    { lat: 48.759593, lng: 18.133547 },
    { lat: 48.75957, lng: 18.133514 },
    { lat: 48.759357, lng: 18.13319 },
    { lat: 48.759255, lng: 18.13307 },
    { lat: 48.75911, lng: 18.132895 },
    { lat: 48.758896, lng: 18.132697 },
    { lat: 48.758648, lng: 18.132685 },
    { lat: 48.758322, lng: 18.132692 },
    { lat: 48.758039, lng: 18.132744 },
    { lat: 48.757974, lng: 18.132774 },
    { lat: 48.757931, lng: 18.132794 },
    { lat: 48.757883, lng: 18.132816 },
    { lat: 48.757854, lng: 18.132825 },
    { lat: 48.757779, lng: 18.132792 },
    { lat: 48.757645, lng: 18.132739 },
    { lat: 48.757626, lng: 18.132731 },
    { lat: 48.757586, lng: 18.132716 },
    { lat: 48.757547, lng: 18.1327 },
    { lat: 48.757509, lng: 18.132686 },
    { lat: 48.757482, lng: 18.132675 },
    { lat: 48.757419, lng: 18.132651 },
    { lat: 48.757332, lng: 18.132617 },
    { lat: 48.757271, lng: 18.132594 },
    { lat: 48.757226, lng: 18.132577 },
    { lat: 48.75713, lng: 18.13254 },
    { lat: 48.756603, lng: 18.132809 },
    { lat: 48.756431, lng: 18.132774 },
    { lat: 48.756049, lng: 18.13288 },
    { lat: 48.755672, lng: 18.133106 },
    { lat: 48.755458, lng: 18.133292 },
    { lat: 48.755073, lng: 18.133628 },
    { lat: 48.754973, lng: 18.133676 },
    { lat: 48.75491, lng: 18.133706 },
    { lat: 48.754663, lng: 18.133825 },
    { lat: 48.754475, lng: 18.133939 },
    { lat: 48.754242, lng: 18.133963 },
    { lat: 48.754059, lng: 18.133874 },
    { lat: 48.754018, lng: 18.133855 },
    { lat: 48.753823, lng: 18.133778 },
    { lat: 48.753624, lng: 18.133695 },
    { lat: 48.75355, lng: 18.133665 },
    { lat: 48.753427, lng: 18.133564 },
    { lat: 48.753235, lng: 18.133348 },
    { lat: 48.752943, lng: 18.133094 },
    { lat: 48.752545, lng: 18.132829 },
    { lat: 48.75249, lng: 18.132782 },
    { lat: 48.752158, lng: 18.132315 },
    { lat: 48.751937, lng: 18.131985 },
    { lat: 48.7519255, lng: 18.132096 },
    { lat: 48.7519303, lng: 18.133307 },
    { lat: 48.7518701, lng: 18.13359 },
    { lat: 48.7518324, lng: 18.1338364 },
    { lat: 48.7511884, lng: 18.1352809 },
    { lat: 48.7510983, lng: 18.1355532 },
    { lat: 48.7510372, lng: 18.1358624 },
    { lat: 48.7509879, lng: 18.136218 },
    { lat: 48.7509249, lng: 18.1364577 },
    { lat: 48.7507681, lng: 18.1368275 },
    { lat: 48.7505927, lng: 18.137207 },
    { lat: 48.7504778, lng: 18.1375627 },
    { lat: 48.7503037, lng: 18.1379241 },
    { lat: 48.7502504, lng: 18.1380767 },
    { lat: 48.7500986, lng: 18.1385907 },
    { lat: 48.7499996, lng: 18.1389679 },
    { lat: 48.7498922, lng: 18.139412 },
    { lat: 48.7498049, lng: 18.1396546 },
    { lat: 48.7496865, lng: 18.1398891 },
    { lat: 48.7496074, lng: 18.1400255 },
    { lat: 48.7494347, lng: 18.140219 },
    { lat: 48.7486495, lng: 18.1408452 },
    { lat: 48.7485815, lng: 18.1409172 },
    { lat: 48.7482693, lng: 18.1414696 },
    { lat: 48.748205, lng: 18.1416047 },
    { lat: 48.7481103, lng: 18.1419015 },
    { lat: 48.748034, lng: 18.1423543 },
    { lat: 48.7480063, lng: 18.1423462 },
    { lat: 48.7479674, lng: 18.1424712 },
    { lat: 48.7477762, lng: 18.1429496 },
    { lat: 48.7472525, lng: 18.144154 },
    { lat: 48.7470788, lng: 18.1448364 },
    { lat: 48.7469742, lng: 18.1453097 },
    { lat: 48.7468823, lng: 18.1461025 },
    { lat: 48.7468516, lng: 18.1467763 },
    { lat: 48.746826, lng: 18.1470554 },
    { lat: 48.7467532, lng: 18.1474559 },
    { lat: 48.746649, lng: 18.1478788 },
    { lat: 48.7465285, lng: 18.1482516 },
    { lat: 48.7463823, lng: 18.1485524 },
    { lat: 48.7462802, lng: 18.1488352 },
    { lat: 48.7461814, lng: 18.1492927 },
    { lat: 48.745712, lng: 18.1509879 },
    { lat: 48.7455598, lng: 18.1512823 },
    { lat: 48.7453631, lng: 18.1515283 },
    { lat: 48.7451893, lng: 18.1519564 },
    { lat: 48.7450253, lng: 18.1524566 },
    { lat: 48.7449336, lng: 18.1528048 },
    { lat: 48.7448741, lng: 18.1531416 },
    { lat: 48.7448384, lng: 18.1535579 },
    { lat: 48.744847, lng: 18.1539193 },
    { lat: 48.7448602, lng: 18.1546946 },
    { lat: 48.7447704, lng: 18.1552671 },
    { lat: 48.7447291, lng: 18.1557428 },
    { lat: 48.7447517, lng: 18.1563305 },
    { lat: 48.7447613, lng: 18.1570014 },
    { lat: 48.7447403, lng: 18.1574773 },
    { lat: 48.7446101, lng: 18.1583494 },
    { lat: 48.7444638, lng: 18.1590946 },
    { lat: 48.7443949, lng: 18.1593465 },
    { lat: 48.7443374, lng: 18.1594649 },
    { lat: 48.7441702, lng: 18.1599143 },
    { lat: 48.7440526, lng: 18.1602599 },
    { lat: 48.743956, lng: 18.160577 },
    { lat: 48.743725, lng: 18.1613044 },
    { lat: 48.7436531, lng: 18.161857 },
    { lat: 48.7436515, lng: 18.1623968 },
    { lat: 48.7436868, lng: 18.1628519 },
    { lat: 48.7438298, lng: 18.1635881 },
    { lat: 48.7438604, lng: 18.1639771 },
    { lat: 48.7438985, lng: 18.1639735 },
    { lat: 48.7439315, lng: 18.1643685 },
    { lat: 48.7438998, lng: 18.1653842 },
    { lat: 48.7439015, lng: 18.1655881 },
    { lat: 48.7437774, lng: 18.1666453 },
    { lat: 48.7437035, lng: 18.167101 },
    { lat: 48.7435591, lng: 18.1676885 },
    { lat: 48.7433831, lng: 18.1680797 },
    { lat: 48.7433746, lng: 18.1681098 },
    { lat: 48.7433415, lng: 18.1681824 },
    { lat: 48.7431231, lng: 18.1684834 },
    { lat: 48.7427957, lng: 18.1687758 },
    { lat: 48.7425889, lng: 18.169023 },
    { lat: 48.7424649, lng: 18.1692797 },
    { lat: 48.7423642, lng: 18.1695293 },
    { lat: 48.7421471, lng: 18.1702823 },
    { lat: 48.742001, lng: 18.170799 },
    { lat: 48.7419182, lng: 18.1712604 },
    { lat: 48.741779, lng: 18.1719373 },
    { lat: 48.7416656, lng: 18.1725951 },
    { lat: 48.7419521, lng: 18.1727486 },
    { lat: 48.742149, lng: 18.1729321 },
    { lat: 48.7423106, lng: 18.173115 },
    { lat: 48.7425464, lng: 18.1734049 },
    { lat: 48.7427427, lng: 18.1736404 },
    { lat: 48.7432591, lng: 18.1743349 },
    { lat: 48.7436851, lng: 18.1749155 },
    { lat: 48.743952, lng: 18.1752757 },
    { lat: 48.7445105, lng: 18.1760154 },
    { lat: 48.7447995, lng: 18.1764672 },
    { lat: 48.7450926, lng: 18.1769119 },
    { lat: 48.7454849, lng: 18.1775142 },
    { lat: 48.7458033, lng: 18.178026 },
    { lat: 48.7459772, lng: 18.1782806 },
    { lat: 48.7462193, lng: 18.1787431 },
    { lat: 48.7463439, lng: 18.1790835 },
    { lat: 48.746566, lng: 18.1789245 },
    { lat: 48.7467806, lng: 18.1786119 },
    { lat: 48.7468485, lng: 18.1783412 },
    { lat: 48.7473248, lng: 18.1777571 },
    { lat: 48.747369, lng: 18.1776457 },
    { lat: 48.7473442, lng: 18.1772932 },
    { lat: 48.7473953, lng: 18.1771413 },
    { lat: 48.7476584, lng: 18.1769475 },
    { lat: 48.7474826, lng: 18.1766013 },
    { lat: 48.7475666, lng: 18.1763528 },
    { lat: 48.7477619, lng: 18.1762991 },
    { lat: 48.7479026, lng: 18.1760786 },
    { lat: 48.7487648, lng: 18.1774649 },
    { lat: 48.7496684, lng: 18.1789083 },
    { lat: 48.7497157, lng: 18.1789872 },
    { lat: 48.7507134, lng: 18.1806114 },
    { lat: 48.7515603, lng: 18.181992 },
    { lat: 48.7516019, lng: 18.1821267 },
    { lat: 48.7516839, lng: 18.1820437 },
    { lat: 48.7518712, lng: 18.1823562 },
    { lat: 48.7519948, lng: 18.1825438 },
    { lat: 48.7521462, lng: 18.1828002 },
    { lat: 48.7524748, lng: 18.1834503 },
    { lat: 48.7527408, lng: 18.1828967 },
    { lat: 48.7528993, lng: 18.1827299 },
    { lat: 48.7534603, lng: 18.1818539 },
    { lat: 48.7536298, lng: 18.1816391 },
    { lat: 48.7538437, lng: 18.1820801 },
    { lat: 48.7540794, lng: 18.1825315 },
    { lat: 48.7545844, lng: 18.1833418 },
    { lat: 48.7546959, lng: 18.183517 },
    { lat: 48.7548768, lng: 18.1831877 },
    { lat: 48.7550949, lng: 18.1830946 },
    { lat: 48.7554471, lng: 18.1832939 },
    { lat: 48.755523, lng: 18.1832606 },
    { lat: 48.7556634, lng: 18.1830143 },
    { lat: 48.7557861, lng: 18.1833011 },
    { lat: 48.7559787, lng: 18.1835769 },
    { lat: 48.756007, lng: 18.183552 },
    { lat: 48.7567221, lng: 18.1829781 },
    { lat: 48.757187, lng: 18.1825839 },
    { lat: 48.7572076, lng: 18.1825161 },
    { lat: 48.7574151, lng: 18.1823732 },
    { lat: 48.7576429, lng: 18.1821737 },
    { lat: 48.7578823, lng: 18.1820606 },
    { lat: 48.758202, lng: 18.1819866 },
    { lat: 48.7586283, lng: 18.1817484 },
    { lat: 48.758826, lng: 18.1816168 },
    { lat: 48.7594846, lng: 18.1812567 },
    { lat: 48.7600581, lng: 18.1808889 },
    { lat: 48.7603477, lng: 18.1805474 },
    { lat: 48.760652, lng: 18.1803105 },
    { lat: 48.7609065, lng: 18.1801432 },
    { lat: 48.7609887, lng: 18.1801199 },
    { lat: 48.7618365, lng: 18.1796379 },
    { lat: 48.762108, lng: 18.1795108 },
    { lat: 48.7627591, lng: 18.1790219 },
    { lat: 48.7628804, lng: 18.1789726 },
    { lat: 48.7633835, lng: 18.1786183 },
    { lat: 48.7638119, lng: 18.1783372 },
    { lat: 48.7641432, lng: 18.1781543 },
    { lat: 48.7642909, lng: 18.1780332 },
    { lat: 48.7644077, lng: 18.1780411 },
    { lat: 48.764482, lng: 18.1780788 },
    { lat: 48.7647913, lng: 18.1779229 },
    { lat: 48.7647743, lng: 18.1778453 },
    { lat: 48.7649276, lng: 18.1777443 },
    { lat: 48.7650336, lng: 18.177721 },
    { lat: 48.7650504, lng: 18.1777985 },
    { lat: 48.7660786, lng: 18.1772962 },
    { lat: 48.7666158, lng: 18.1769227 },
    { lat: 48.7670361, lng: 18.1766977 },
    { lat: 48.7679535, lng: 18.1762862 },
    { lat: 48.7697411, lng: 18.1754654 },
    { lat: 48.7704593, lng: 18.1751069 },
    { lat: 48.7704952, lng: 18.1752775 },
    { lat: 48.7705574, lng: 18.1756299 },
    { lat: 48.7708378, lng: 18.1756014 },
    { lat: 48.7709067, lng: 18.1756023 },
    { lat: 48.771116, lng: 18.175682 },
    { lat: 48.770996, lng: 18.175446 },
    { lat: 48.770951, lng: 18.175359 },
    { lat: 48.770923, lng: 18.175275 },
    { lat: 48.770856, lng: 18.175109 },
    { lat: 48.770798, lng: 18.175024 },
    { lat: 48.770756, lng: 18.174974 },
    { lat: 48.770734, lng: 18.174947 },
    { lat: 48.77078, lng: 18.174908 },
    { lat: 48.770789, lng: 18.174897 },
    { lat: 48.770799, lng: 18.174884 },
    { lat: 48.770841, lng: 18.174834 },
    { lat: 48.770861, lng: 18.174808 },
    { lat: 48.770729, lng: 18.174724 },
    { lat: 48.770718, lng: 18.174716 },
    { lat: 48.770621, lng: 18.174588 },
    { lat: 48.770501, lng: 18.174427 },
    { lat: 48.770484, lng: 18.174406 },
    { lat: 48.770398, lng: 18.174237 },
    { lat: 48.770393, lng: 18.174228 },
    { lat: 48.770339, lng: 18.174147 },
    { lat: 48.770159, lng: 18.173975 },
    { lat: 48.770083, lng: 18.173938 },
    { lat: 48.769881, lng: 18.173842 },
    { lat: 48.769707, lng: 18.173393 },
    { lat: 48.769483, lng: 18.173415 },
    { lat: 48.769282, lng: 18.173444 },
    { lat: 48.76927, lng: 18.173227 },
    { lat: 48.769243, lng: 18.173063 },
    { lat: 48.769229, lng: 18.172916 },
    { lat: 48.769217, lng: 18.172784 },
    { lat: 48.769172, lng: 18.172271 },
    { lat: 48.769113, lng: 18.171254 },
    { lat: 48.769109, lng: 18.171196 },
    { lat: 48.76908, lng: 18.170429 },
    { lat: 48.768999, lng: 18.169608 },
    { lat: 48.769187, lng: 18.169369 },
    { lat: 48.769698, lng: 18.168689 },
    { lat: 48.770537, lng: 18.16762 },
    { lat: 48.770731, lng: 18.168829 },
    { lat: 48.770777, lng: 18.168895 },
    { lat: 48.77085, lng: 18.168999 },
    { lat: 48.770974, lng: 18.169042 },
    { lat: 48.771059, lng: 18.1692 },
    { lat: 48.771126, lng: 18.169326 },
    { lat: 48.771064, lng: 18.169416 },
    { lat: 48.770951, lng: 18.169583 },
    { lat: 48.770931, lng: 18.170486 },
    { lat: 48.770834, lng: 18.170689 },
    { lat: 48.770758, lng: 18.170844 },
    { lat: 48.770848, lng: 18.171122 },
    { lat: 48.771035, lng: 18.171707 },
    { lat: 48.771126, lng: 18.171583 },
    { lat: 48.771442, lng: 18.171147 },
    { lat: 48.771504, lng: 18.171059 },
    { lat: 48.771948, lng: 18.170709 },
    { lat: 48.772286, lng: 18.170443 },
    { lat: 48.773007, lng: 18.169701 },
    { lat: 48.773239, lng: 18.169227 },
    { lat: 48.773041, lng: 18.168822 },
    { lat: 48.772602, lng: 18.167732 },
    { lat: 48.772061, lng: 18.168252 },
    { lat: 48.771946, lng: 18.168064 },
    { lat: 48.771873, lng: 18.168039 },
    { lat: 48.771807, lng: 18.168016 },
    { lat: 48.771699, lng: 18.167772 },
    { lat: 48.771833, lng: 18.167589 },
    { lat: 48.771852, lng: 18.167428 },
    { lat: 48.771948, lng: 18.167443 },
    { lat: 48.77199, lng: 18.167448 },
    { lat: 48.7724, lng: 18.166937 },
    { lat: 48.772662, lng: 18.167326 },
    { lat: 48.772858, lng: 18.167334 },
    { lat: 48.772965, lng: 18.16728 },
    { lat: 48.773224, lng: 18.167468 },
    { lat: 48.773301, lng: 18.167525 },
    { lat: 48.773369, lng: 18.167477 },
    { lat: 48.773378, lng: 18.16725 },
    { lat: 48.773382, lng: 18.167143 },
    { lat: 48.773427, lng: 18.166976 },
    { lat: 48.773446, lng: 18.166906 },
    { lat: 48.77344, lng: 18.16679 },
    { lat: 48.773435, lng: 18.166667 },
    { lat: 48.773432, lng: 18.166582 },
    { lat: 48.773444, lng: 18.166536 },
    { lat: 48.773493, lng: 18.166348 },
    { lat: 48.773566, lng: 18.166459 },
    { lat: 48.773593, lng: 18.166789 },
    { lat: 48.773594, lng: 18.166816 },
    { lat: 48.77358, lng: 18.166909 },
    { lat: 48.773566, lng: 18.166998 },
    { lat: 48.77354, lng: 18.167162 },
    { lat: 48.773527, lng: 18.167248 },
    { lat: 48.773749, lng: 18.167722 },
    { lat: 48.774055, lng: 18.168846 },
    { lat: 48.774143, lng: 18.169049 },
    { lat: 48.774735, lng: 18.169992 },
    { lat: 48.774781, lng: 18.170064 },
    { lat: 48.774841, lng: 18.170158 },
    { lat: 48.775016, lng: 18.169964 },
    { lat: 48.775142, lng: 18.169825 },
    { lat: 48.77519, lng: 18.169716 },
    { lat: 48.775219, lng: 18.169649 },
    { lat: 48.775249, lng: 18.169582 },
    { lat: 48.775336, lng: 18.169387 },
    { lat: 48.775541, lng: 18.168921 },
    { lat: 48.7757, lng: 18.16856 },
    { lat: 48.775724, lng: 18.168506 },
    { lat: 48.775734, lng: 18.168481 },
    { lat: 48.775867, lng: 18.168141 },
    { lat: 48.775879, lng: 18.168107 },
    { lat: 48.775919, lng: 18.16801 },
    { lat: 48.775951, lng: 18.167904 },
    { lat: 48.776422, lng: 18.166348 },
    { lat: 48.774932, lng: 18.163931 },
    { lat: 48.774335, lng: 18.162904 },
    { lat: 48.77433, lng: 18.162888 },
    { lat: 48.774316, lng: 18.162872 },
    { lat: 48.774231, lng: 18.162701 },
    { lat: 48.774088, lng: 18.162441 },
    { lat: 48.77401, lng: 18.162297 },
    { lat: 48.773909, lng: 18.162116 },
    { lat: 48.773693, lng: 18.161697 },
    { lat: 48.773598, lng: 18.16151 },
    { lat: 48.772955, lng: 18.160248 },
    { lat: 48.77292, lng: 18.160179 },
    { lat: 48.772723, lng: 18.159797 },
    { lat: 48.772857, lng: 18.159545 },
    { lat: 48.773126, lng: 18.159199 },
    { lat: 48.773611, lng: 18.158882 },
    { lat: 48.773306, lng: 18.158147 },
    { lat: 48.773545, lng: 18.157871 },
    { lat: 48.773662, lng: 18.158037 },
    { lat: 48.773768, lng: 18.158184 },
    { lat: 48.773928, lng: 18.15841 },
    { lat: 48.774253, lng: 18.158022 },
    { lat: 48.774473, lng: 18.157893 },
    { lat: 48.774639, lng: 18.157859 },
    { lat: 48.774657, lng: 18.157855 },
    { lat: 48.774741, lng: 18.15773 },
    { lat: 48.774818, lng: 18.157615 },
    { lat: 48.774945, lng: 18.157418 },
    { lat: 48.775011, lng: 18.157315 },
    { lat: 48.775078, lng: 18.15721 },
    { lat: 48.77521, lng: 18.15716 },
    { lat: 48.775378, lng: 18.157096 },
    { lat: 48.775161, lng: 18.15666 },
    { lat: 48.774959, lng: 18.156249 },
    { lat: 48.774865, lng: 18.156052 },
    { lat: 48.77485, lng: 18.156024 },
    { lat: 48.774843, lng: 18.156008 },
    { lat: 48.774809, lng: 18.155931 },
    { lat: 48.774598, lng: 18.15545 },
    { lat: 48.774349, lng: 18.154885 },
    { lat: 48.774108, lng: 18.154338 },
    { lat: 48.774176, lng: 18.154306 },
    { lat: 48.774269, lng: 18.154261 },
    { lat: 48.774342, lng: 18.154226 },
    { lat: 48.774424, lng: 18.154187 },
    { lat: 48.774505, lng: 18.154148 },
    { lat: 48.774569, lng: 18.154118 },
    { lat: 48.774578, lng: 18.154101 },
    { lat: 48.77463, lng: 18.154007 },
    { lat: 48.774631, lng: 18.153988 },
    { lat: 48.774597, lng: 18.153847 },
    { lat: 48.774535, lng: 18.153591 },
    { lat: 48.774662, lng: 18.153388 },
  ],
  MaléHoste: [
    { lat: 48.7110542, lng: 18.1649958 },
    { lat: 48.7111548, lng: 18.1647474 },
    { lat: 48.7112356, lng: 18.1645048 },
    { lat: 48.7113481, lng: 18.1640876 },
    { lat: 48.7115872, lng: 18.163033 },
    { lat: 48.7117245, lng: 18.16252 },
    { lat: 48.71183, lng: 18.1622191 },
    { lat: 48.7119077, lng: 18.1620366 },
    { lat: 48.7120246, lng: 18.1617982 },
    { lat: 48.7124297, lng: 18.1612105 },
    { lat: 48.7128187, lng: 18.160621 },
    { lat: 48.7129282, lng: 18.1604468 },
    { lat: 48.7131958, lng: 18.1601963 },
    { lat: 48.7135196, lng: 18.1597465 },
    { lat: 48.7138253, lng: 18.1592759 },
    { lat: 48.7139514, lng: 18.1590366 },
    { lat: 48.7140026, lng: 18.158929 },
    { lat: 48.714056, lng: 18.158699 },
    { lat: 48.7141313, lng: 18.1581855 },
    { lat: 48.7142265, lng: 18.157846 },
    { lat: 48.7144806, lng: 18.1571449 },
    { lat: 48.7146431, lng: 18.1568729 },
    { lat: 48.7148182, lng: 18.1566621 },
    { lat: 48.7149704, lng: 18.1564076 },
    { lat: 48.7149942, lng: 18.1563476 },
    { lat: 48.7151008, lng: 18.1560255 },
    { lat: 48.7151727, lng: 18.1557883 },
    { lat: 48.7152252, lng: 18.1555062 },
    { lat: 48.7152888, lng: 18.1552205 },
    { lat: 48.715358, lng: 18.1549741 },
    { lat: 48.7154477, lng: 18.1545341 },
    { lat: 48.7155528, lng: 18.1542836 },
    { lat: 48.7156237, lng: 18.1537364 },
    { lat: 48.7155893, lng: 18.1532186 },
    { lat: 48.7155872, lng: 18.1529094 },
    { lat: 48.7156613, lng: 18.1525722 },
    { lat: 48.7157503, lng: 18.1522592 },
    { lat: 48.7159282, lng: 18.1517622 },
    { lat: 48.716189, lng: 18.1511634 },
    { lat: 48.7165112, lng: 18.1503167 },
    { lat: 48.7170736, lng: 18.1485308 },
    { lat: 48.7173416, lng: 18.1477809 },
    { lat: 48.7174777, lng: 18.1473844 },
    { lat: 48.717473, lng: 18.1472664 },
    { lat: 48.7176237, lng: 18.1468877 },
    { lat: 48.7178443, lng: 18.1464464 },
    { lat: 48.7180183, lng: 18.146042 },
    { lat: 48.7180634, lng: 18.1459063 },
    { lat: 48.7180625, lng: 18.1458336 },
    { lat: 48.7181497, lng: 18.1455851 },
    { lat: 48.7182361, lng: 18.1454017 },
    { lat: 48.7183179, lng: 18.1452915 },
    { lat: 48.7183381, lng: 18.1452942 },
    { lat: 48.7188409, lng: 18.1447405 },
    { lat: 48.7192451, lng: 18.1443903 },
    { lat: 48.7195387, lng: 18.1441242 },
    { lat: 48.7192955, lng: 18.1439959 },
    { lat: 48.7187283, lng: 18.1434957 },
    { lat: 48.7179737, lng: 18.1429985 },
    { lat: 48.7172778, lng: 18.1428116 },
    { lat: 48.7162917, lng: 18.1423476 },
    { lat: 48.7153698, lng: 18.1414727 },
    { lat: 48.7148487, lng: 18.1409543 },
    { lat: 48.7143207, lng: 18.1402177 },
    { lat: 48.713702, lng: 18.1392373 },
    { lat: 48.7130374, lng: 18.1381319 },
    { lat: 48.7128146, lng: 18.1377165 },
    { lat: 48.7126482, lng: 18.137824 },
    { lat: 48.7124567, lng: 18.137849 },
    { lat: 48.7121778, lng: 18.1380214 },
    { lat: 48.7119093, lng: 18.137809 },
    { lat: 48.7114544, lng: 18.1378465 },
    { lat: 48.7113798, lng: 18.1376298 },
    { lat: 48.710958, lng: 18.136429 },
    { lat: 48.7106448, lng: 18.1355279 },
    { lat: 48.7102279, lng: 18.134587 },
    { lat: 48.7102113, lng: 18.1345549 },
    { lat: 48.7098795, lng: 18.1346111 },
    { lat: 48.7098673, lng: 18.1345752 },
    { lat: 48.7093982, lng: 18.1334168 },
    { lat: 48.709212, lng: 18.1330291 },
    { lat: 48.708116, lng: 18.1314888 },
    { lat: 48.7080454, lng: 18.1314002 },
    { lat: 48.7077771, lng: 18.1308827 },
    { lat: 48.7076853, lng: 18.1308774 },
    { lat: 48.7075097, lng: 18.1308057 },
    { lat: 48.7072607, lng: 18.1307726 },
    { lat: 48.707193, lng: 18.1307348 },
    { lat: 48.7069926, lng: 18.1306485 },
    { lat: 48.706592, lng: 18.1303354 },
    { lat: 48.7065435, lng: 18.1302772 },
    { lat: 48.70646, lng: 18.1300456 },
    { lat: 48.706326, lng: 18.1299327 },
    { lat: 48.7061503, lng: 18.129454 },
    { lat: 48.7060267, lng: 18.1293485 },
    { lat: 48.7059437, lng: 18.129182 },
    { lat: 48.7058768, lng: 18.129019 },
    { lat: 48.7051697, lng: 18.1276894 },
    { lat: 48.7051532, lng: 18.1276384 },
    { lat: 48.7045069, lng: 18.1267349 },
    { lat: 48.7034669, lng: 18.124626 },
    { lat: 48.7031505, lng: 18.1236516 },
    { lat: 48.7033446, lng: 18.1222167 },
    { lat: 48.7033631, lng: 18.1214704 },
    { lat: 48.7033003, lng: 18.121379 },
    { lat: 48.7032011, lng: 18.1212839 },
    { lat: 48.7029113, lng: 18.1206679 },
    { lat: 48.7021716, lng: 18.1195361 },
    { lat: 48.7019218, lng: 18.119141 },
    { lat: 48.7016468, lng: 18.1188564 },
    { lat: 48.7015306, lng: 18.11868 },
    { lat: 48.7013085, lng: 18.1180825 },
    { lat: 48.7011878, lng: 18.1172336 },
    { lat: 48.7013899, lng: 18.1159573 },
    { lat: 48.701341, lng: 18.1159131 },
    { lat: 48.7010641, lng: 18.1157316 },
    { lat: 48.7009742, lng: 18.1156614 },
    { lat: 48.7008982, lng: 18.1155744 },
    { lat: 48.7008115, lng: 18.1155398 },
    { lat: 48.7006176, lng: 18.1153494 },
    { lat: 48.7004074, lng: 18.1152288 },
    { lat: 48.7002754, lng: 18.1151131 },
    { lat: 48.7001315, lng: 18.1150843 },
    { lat: 48.7000012, lng: 18.1149984 },
    { lat: 48.6998544, lng: 18.1148818 },
    { lat: 48.6996756, lng: 18.1148327 },
    { lat: 48.6995232, lng: 18.114734 },
    { lat: 48.6994403, lng: 18.1146493 },
    { lat: 48.6993621, lng: 18.1146226 },
    { lat: 48.6991105, lng: 18.1143559 },
    { lat: 48.6990365, lng: 18.1142836 },
    { lat: 48.6989159, lng: 18.11419 },
    { lat: 48.698821, lng: 18.1140701 },
    { lat: 48.6986514, lng: 18.1137947 },
    { lat: 48.6985282, lng: 18.113676 },
    { lat: 48.698418, lng: 18.1135464 },
    { lat: 48.6981724, lng: 18.1133211 },
    { lat: 48.6979562, lng: 18.1131584 },
    { lat: 48.6978347, lng: 18.1130452 },
    { lat: 48.6977382, lng: 18.1129402 },
    { lat: 48.6976999, lng: 18.1128842 },
    { lat: 48.697673, lng: 18.1128106 },
    { lat: 48.697585, lng: 18.1126926 },
    { lat: 48.697447, lng: 18.1125738 },
    { lat: 48.6973945, lng: 18.1126015 },
    { lat: 48.6973127, lng: 18.1126099 },
    { lat: 48.6971847, lng: 18.1126977 },
    { lat: 48.6970107, lng: 18.1127926 },
    { lat: 48.6969029, lng: 18.1128413 },
    { lat: 48.6966563, lng: 18.1130452 },
    { lat: 48.6965763, lng: 18.1131212 },
    { lat: 48.696434, lng: 18.1133137 },
    { lat: 48.6963338, lng: 18.113415 },
    { lat: 48.6959787, lng: 18.1136723 },
    { lat: 48.695867, lng: 18.1137332 },
    { lat: 48.6955548, lng: 18.113873 },
    { lat: 48.6953703, lng: 18.1139829 },
    { lat: 48.6952086, lng: 18.1140726 },
    { lat: 48.6951203, lng: 18.1141444 },
    { lat: 48.6950201, lng: 18.1141702 },
    { lat: 48.694871, lng: 18.1142454 },
    { lat: 48.6945615, lng: 18.1143559 },
    { lat: 48.6945425, lng: 18.1141059 },
    { lat: 48.6945057, lng: 18.1134188 },
    { lat: 48.6944774, lng: 18.1132622 },
    { lat: 48.6944784, lng: 18.1129698 },
    { lat: 48.694501, lng: 18.1127947 },
    { lat: 48.6945173, lng: 18.112611 },
    { lat: 48.6945204, lng: 18.112372 },
    { lat: 48.6944802, lng: 18.1121094 },
    { lat: 48.6944016, lng: 18.1118841 },
    { lat: 48.6943881, lng: 18.1117877 },
    { lat: 48.6943998, lng: 18.1116221 },
    { lat: 48.694453, lng: 18.1113139 },
    { lat: 48.6945239, lng: 18.1110991 },
    { lat: 48.6946242, lng: 18.110885 },
    { lat: 48.6946775, lng: 18.1107321 },
    { lat: 48.6946989, lng: 18.1104785 },
    { lat: 48.6947536, lng: 18.1101801 },
    { lat: 48.694837, lng: 18.1100608 },
    { lat: 48.694873, lng: 18.1099231 },
    { lat: 48.6949411, lng: 18.1097175 },
    { lat: 48.6950288, lng: 18.1094047 },
    { lat: 48.6950902, lng: 18.1091597 },
    { lat: 48.6952634, lng: 18.1087026 },
    { lat: 48.6952918, lng: 18.1085542 },
    { lat: 48.6953969, lng: 18.108149 },
    { lat: 48.695471, lng: 18.1079684 },
    { lat: 48.6955501, lng: 18.1078168 },
    { lat: 48.6956848, lng: 18.1074548 },
    { lat: 48.6957477, lng: 18.1072971 },
    { lat: 48.6958051, lng: 18.1071977 },
    { lat: 48.6958688, lng: 18.107146 },
    { lat: 48.696007, lng: 18.1069821 },
    { lat: 48.6962166, lng: 18.1067803 },
    { lat: 48.6963346, lng: 18.1066381 },
    { lat: 48.6964524, lng: 18.1064116 },
    { lat: 48.6968323, lng: 18.1060016 },
    { lat: 48.6969704, lng: 18.1057786 },
    { lat: 48.6969652, lng: 18.1054342 },
    { lat: 48.6969175, lng: 18.1053344 },
    { lat: 48.696904, lng: 18.105268 },
    { lat: 48.696612, lng: 18.105552 },
    { lat: 48.696458, lng: 18.105639 },
    { lat: 48.696336, lng: 18.10573 },
    { lat: 48.695974, lng: 18.105894 },
    { lat: 48.695719, lng: 18.106047 },
    { lat: 48.695855, lng: 18.106133 },
    { lat: 48.695603, lng: 18.106406 },
    { lat: 48.695425, lng: 18.106644 },
    { lat: 48.695099, lng: 18.106952 },
    { lat: 48.695016, lng: 18.107138 },
    { lat: 48.694888, lng: 18.107357 },
    { lat: 48.694776, lng: 18.107533 },
    { lat: 48.694602, lng: 18.107778 },
    { lat: 48.694433, lng: 18.107991 },
    { lat: 48.694325, lng: 18.108139 },
    { lat: 48.694213, lng: 18.108335 },
    { lat: 48.694114, lng: 18.108456 },
    { lat: 48.694019, lng: 18.108565 },
    { lat: 48.693806, lng: 18.108864 },
    { lat: 48.693574, lng: 18.109145 },
    { lat: 48.693558, lng: 18.109183 },
    { lat: 48.69343, lng: 18.109441 },
    { lat: 48.693401, lng: 18.109486 },
    { lat: 48.693323, lng: 18.109592 },
    { lat: 48.693012, lng: 18.109965 },
    { lat: 48.692948, lng: 18.110109 },
    { lat: 48.69261, lng: 18.110632 },
    { lat: 48.69243, lng: 18.110885 },
    { lat: 48.692295, lng: 18.111043 },
    { lat: 48.692134, lng: 18.111273 },
    { lat: 48.691949, lng: 18.111547 },
    { lat: 48.691824, lng: 18.111904 },
    { lat: 48.691658, lng: 18.112257 },
    { lat: 48.691554, lng: 18.112563 },
    { lat: 48.691258, lng: 18.113071 },
    { lat: 48.691065, lng: 18.113581 },
    { lat: 48.690893, lng: 18.113871 },
    { lat: 48.690553, lng: 18.114479 },
    { lat: 48.690376, lng: 18.114721 },
    { lat: 48.690182, lng: 18.115113 },
    { lat: 48.690093, lng: 18.115242 },
    { lat: 48.690022, lng: 18.11532 },
    { lat: 48.689821, lng: 18.115538 },
    { lat: 48.689676, lng: 18.115705 },
    { lat: 48.689541, lng: 18.115885 },
    { lat: 48.689429, lng: 18.116059 },
    { lat: 48.689252, lng: 18.116368 },
    { lat: 48.689031, lng: 18.116767 },
    { lat: 48.688932, lng: 18.117043 },
    { lat: 48.688604, lng: 18.117808 },
    { lat: 48.688576, lng: 18.117933 },
    { lat: 48.68843, lng: 18.118779 },
    { lat: 48.688395, lng: 18.118867 },
    { lat: 48.688406, lng: 18.118987 },
    { lat: 48.688394, lng: 18.119049 },
    { lat: 48.688417, lng: 18.119453 },
    { lat: 48.688242, lng: 18.119707 },
    { lat: 48.687966, lng: 18.119622 },
    { lat: 48.687471, lng: 18.119588 },
    { lat: 48.687234, lng: 18.119611 },
    { lat: 48.687091, lng: 18.119643 },
    { lat: 48.686896, lng: 18.119692 },
    { lat: 48.686553, lng: 18.119693 },
    { lat: 48.686319, lng: 18.119874 },
    { lat: 48.686258, lng: 18.119928 },
    { lat: 48.686196, lng: 18.119939 },
    { lat: 48.685993, lng: 18.119977 },
    { lat: 48.685872, lng: 18.119933 },
    { lat: 48.685718, lng: 18.119945 },
    { lat: 48.685476, lng: 18.120053 },
    { lat: 48.685413, lng: 18.120087 },
    { lat: 48.68536, lng: 18.120101 },
    { lat: 48.685292, lng: 18.120114 },
    { lat: 48.685113, lng: 18.120121 },
    { lat: 48.684868, lng: 18.120254 },
    { lat: 48.684689, lng: 18.120283 },
    { lat: 48.68457, lng: 18.120188 },
    { lat: 48.684389, lng: 18.120115 },
    { lat: 48.684073, lng: 18.120169 },
    { lat: 48.684072, lng: 18.120201 },
    { lat: 48.684069, lng: 18.120233 },
    { lat: 48.6843648, lng: 18.1207009 },
    { lat: 48.6844779, lng: 18.1207837 },
    { lat: 48.6848775, lng: 18.1209574 },
    { lat: 48.6850833, lng: 18.1210997 },
    { lat: 48.6851923, lng: 18.1212466 },
    { lat: 48.6852936, lng: 18.121455 },
    { lat: 48.6853294, lng: 18.1215452 },
    { lat: 48.6853698, lng: 18.1219302 },
    { lat: 48.6853395, lng: 18.1222064 },
    { lat: 48.6853626, lng: 18.1226234 },
    { lat: 48.6854087, lng: 18.1229276 },
    { lat: 48.6855503, lng: 18.1233445 },
    { lat: 48.6856662, lng: 18.1235323 },
    { lat: 48.686201, lng: 18.1239363 },
    { lat: 48.6864358, lng: 18.1240938 },
    { lat: 48.6866927, lng: 18.1243186 },
    { lat: 48.6869142, lng: 18.1244235 },
    { lat: 48.6872188, lng: 18.124963 },
    { lat: 48.6872223, lng: 18.1252555 },
    { lat: 48.6871757, lng: 18.1258692 },
    { lat: 48.6871294, lng: 18.1261993 },
    { lat: 48.6871048, lng: 18.1272501 },
    { lat: 48.6871889, lng: 18.1278633 },
    { lat: 48.6872012, lng: 18.1279191 },
    { lat: 48.6874616, lng: 18.1286311 },
    { lat: 48.6877666, lng: 18.129741 },
    { lat: 48.6878442, lng: 18.1299882 },
    { lat: 48.6879499, lng: 18.1306235 },
    { lat: 48.6879856, lng: 18.131216 },
    { lat: 48.6879514, lng: 18.1314466 },
    { lat: 48.6878605, lng: 18.1317826 },
    { lat: 48.6878603, lng: 18.1318184 },
    { lat: 48.6878712, lng: 18.1318516 },
    { lat: 48.687992, lng: 18.1320673 },
    { lat: 48.688049, lng: 18.1322087 },
    { lat: 48.6880921, lng: 18.1323487 },
    { lat: 48.6881868, lng: 18.132428 },
    { lat: 48.6884001, lng: 18.1325254 },
    { lat: 48.6885793, lng: 18.1326188 },
    { lat: 48.6887352, lng: 18.1327528 },
    { lat: 48.688942, lng: 18.1330722 },
    { lat: 48.6890573, lng: 18.1332869 },
    { lat: 48.6893057, lng: 18.1334825 },
    { lat: 48.6894572, lng: 18.1336657 },
    { lat: 48.6895972, lng: 18.1344422 },
    { lat: 48.6898181, lng: 18.1349594 },
    { lat: 48.6902815, lng: 18.1355759 },
    { lat: 48.6907958, lng: 18.1367557 },
    { lat: 48.6909465, lng: 18.1373669 },
    { lat: 48.6913294, lng: 18.1387117 },
    { lat: 48.6913113, lng: 18.1387498 },
    { lat: 48.6915177, lng: 18.1391364 },
    { lat: 48.6919568, lng: 18.1400723 },
    { lat: 48.6921924, lng: 18.1404765 },
    { lat: 48.69239, lng: 18.1408279 },
    { lat: 48.6926258, lng: 18.1412932 },
    { lat: 48.6930333, lng: 18.1419546 },
    { lat: 48.6934362, lng: 18.1422467 },
    { lat: 48.6933522, lng: 18.14253 },
    { lat: 48.6935585, lng: 18.1428385 },
    { lat: 48.6936623, lng: 18.1430126 },
    { lat: 48.694061, lng: 18.1437378 },
    { lat: 48.6942628, lng: 18.1441637 },
    { lat: 48.694495, lng: 18.1446023 },
    { lat: 48.6948058, lng: 18.1450402 },
    { lat: 48.6949069, lng: 18.1451118 },
    { lat: 48.6949697, lng: 18.1449677 },
    { lat: 48.6957815, lng: 18.1463152 },
    { lat: 48.6963851, lng: 18.1472877 },
    { lat: 48.6975014, lng: 18.1490804 },
    { lat: 48.6975158, lng: 18.1490963 },
    { lat: 48.6978439, lng: 18.1489918 },
    { lat: 48.698563, lng: 18.1487438 },
    { lat: 48.6989724, lng: 18.1484678 },
    { lat: 48.699747, lng: 18.1496806 },
    { lat: 48.7005173, lng: 18.1509023 },
    { lat: 48.7008507, lng: 18.1514366 },
    { lat: 48.7005579, lng: 18.1519905 },
    { lat: 48.7010776, lng: 18.1527595 },
    { lat: 48.70168, lng: 18.1537705 },
    { lat: 48.7022928, lng: 18.1547223 },
    { lat: 48.7024841, lng: 18.1550079 },
    { lat: 48.7030927, lng: 18.155852 },
    { lat: 48.7030992, lng: 18.1558454 },
    { lat: 48.7034863, lng: 18.1566828 },
    { lat: 48.7037241, lng: 18.1572037 },
    { lat: 48.7037995, lng: 18.1572602 },
    { lat: 48.7038835, lng: 18.1574041 },
    { lat: 48.7041219, lng: 18.1570418 },
    { lat: 48.7043778, lng: 18.1568411 },
    { lat: 48.7046923, lng: 18.1575766 },
    { lat: 48.7051306, lng: 18.1585748 },
    { lat: 48.7056362, lng: 18.1598835 },
    { lat: 48.705754, lng: 18.1597933 },
    { lat: 48.7064814, lng: 18.1592581 },
    { lat: 48.7068807, lng: 18.1589423 },
    { lat: 48.7073586, lng: 18.1598689 },
    { lat: 48.7075711, lng: 18.1601291 },
    { lat: 48.7076165, lng: 18.1602521 },
    { lat: 48.7080413, lng: 18.1612757 },
    { lat: 48.7082013, lng: 18.1616776 },
    { lat: 48.7079704, lng: 18.1619583 },
    { lat: 48.7085151, lng: 18.1628882 },
    { lat: 48.7090403, lng: 18.1623525 },
    { lat: 48.709654, lng: 18.1632441 },
    { lat: 48.7100959, lng: 18.1638246 },
    { lat: 48.7104297, lng: 18.1643047 },
    { lat: 48.7106552, lng: 18.1645772 },
    { lat: 48.7110542, lng: 18.1649958 },
  ],
  Nedašovce: [
    { lat: 48.646643, lng: 18.327684 },
    { lat: 48.646707, lng: 18.327801 },
    { lat: 48.646384, lng: 18.328295 },
    { lat: 48.645972, lng: 18.328877 },
    { lat: 48.645754, lng: 18.329342 },
    { lat: 48.645509, lng: 18.329957 },
    { lat: 48.646125, lng: 18.330875 },
    { lat: 48.647013, lng: 18.332035 },
    { lat: 48.647549, lng: 18.332757 },
    { lat: 48.647698, lng: 18.332893 },
    { lat: 48.64799, lng: 18.333169 },
    { lat: 48.648408, lng: 18.333623 },
    { lat: 48.6486947, lng: 18.3339505 },
    { lat: 48.6489052, lng: 18.3341909 },
    { lat: 48.64902, lng: 18.334322 },
    { lat: 48.64916, lng: 18.334452 },
    { lat: 48.649501, lng: 18.334742 },
    { lat: 48.649647, lng: 18.33485 },
    { lat: 48.649912, lng: 18.334944 },
    { lat: 48.650846, lng: 18.335145 },
    { lat: 48.650905, lng: 18.335157 },
    { lat: 48.652419, lng: 18.335604 },
    { lat: 48.653184, lng: 18.33576 },
    { lat: 48.653447, lng: 18.335846 },
    { lat: 48.654474, lng: 18.336398 },
    { lat: 48.655175, lng: 18.337142 },
    { lat: 48.655876, lng: 18.338341 },
    { lat: 48.65626, lng: 18.339089 },
    { lat: 48.656424, lng: 18.339503 },
    { lat: 48.656615, lng: 18.340055 },
    { lat: 48.656892, lng: 18.34072 },
    { lat: 48.657039, lng: 18.34112 },
    { lat: 48.657604, lng: 18.34243 },
    { lat: 48.657987, lng: 18.343419 },
    { lat: 48.658009, lng: 18.343409 },
    { lat: 48.658324, lng: 18.343253 },
    { lat: 48.65864, lng: 18.343162 },
    { lat: 48.659046, lng: 18.343101 },
    { lat: 48.659441, lng: 18.343052 },
    { lat: 48.659667, lng: 18.343023 },
    { lat: 48.659856, lng: 18.342978 },
    { lat: 48.660095, lng: 18.342996 },
    { lat: 48.660304, lng: 18.342973 },
    { lat: 48.660575, lng: 18.342909 },
    { lat: 48.660823, lng: 18.34291 },
    { lat: 48.661032, lng: 18.342927 },
    { lat: 48.661489, lng: 18.342846 },
    { lat: 48.661533, lng: 18.342796 },
    { lat: 48.66164, lng: 18.342782 },
    { lat: 48.661681, lng: 18.342776 },
    { lat: 48.661699, lng: 18.342768 },
    { lat: 48.661825, lng: 18.342708 },
    { lat: 48.662038, lng: 18.34266 },
    { lat: 48.662307, lng: 18.34255 },
    { lat: 48.66276, lng: 18.342507 },
    { lat: 48.663112, lng: 18.342473 },
    { lat: 48.663518, lng: 18.342416 },
    { lat: 48.663903, lng: 18.342317 },
    { lat: 48.664173, lng: 18.342242 },
    { lat: 48.665088, lng: 18.34229 },
    { lat: 48.665289, lng: 18.342326 },
    { lat: 48.666056, lng: 18.342783 },
    { lat: 48.666417, lng: 18.342816 },
    { lat: 48.66709, lng: 18.342875 },
    { lat: 48.668154, lng: 18.343168 },
    { lat: 48.668187, lng: 18.343177 },
    { lat: 48.66919, lng: 18.343746 },
    { lat: 48.670174, lng: 18.344348 },
    { lat: 48.670374, lng: 18.344514 },
    { lat: 48.670425, lng: 18.344544 },
    { lat: 48.67122, lng: 18.3448 },
    { lat: 48.671915, lng: 18.344894 },
    { lat: 48.672063, lng: 18.344952 },
    { lat: 48.672112, lng: 18.344971 },
    { lat: 48.672267, lng: 18.345032 },
    { lat: 48.672355, lng: 18.344985 },
    { lat: 48.672508, lng: 18.345004 },
    { lat: 48.67262, lng: 18.345009 },
    { lat: 48.672783, lng: 18.344984 },
    { lat: 48.672966, lng: 18.344942 },
    { lat: 48.673095, lng: 18.344934 },
    { lat: 48.67359, lng: 18.34503 },
    { lat: 48.673722, lng: 18.345042 },
    { lat: 48.67427, lng: 18.344996 },
    { lat: 48.675001, lng: 18.34477 },
    { lat: 48.6752373, lng: 18.3447342 },
    { lat: 48.675384, lng: 18.344712 },
    { lat: 48.675543, lng: 18.344711 },
    { lat: 48.675751, lng: 18.344706 },
    { lat: 48.676016, lng: 18.344726 },
    { lat: 48.676134, lng: 18.344759 },
    { lat: 48.676415, lng: 18.344762 },
    { lat: 48.676917, lng: 18.34471 },
    { lat: 48.677455, lng: 18.344701 },
    { lat: 48.677669, lng: 18.344649 },
    { lat: 48.677889, lng: 18.344578 },
    { lat: 48.678113, lng: 18.344578 },
    { lat: 48.678281, lng: 18.344633 },
    { lat: 48.678415, lng: 18.344731 },
    { lat: 48.678768, lng: 18.345082 },
    { lat: 48.679113, lng: 18.345276 },
    { lat: 48.67934, lng: 18.345351 },
    { lat: 48.679497, lng: 18.345372 },
    { lat: 48.67959, lng: 18.345343 },
    { lat: 48.679748, lng: 18.345257 },
    { lat: 48.679923, lng: 18.345188 },
    { lat: 48.680289, lng: 18.34509 },
    { lat: 48.680924, lng: 18.344953 },
    { lat: 48.681142, lng: 18.34476 },
    { lat: 48.681228, lng: 18.344556 },
    { lat: 48.681115, lng: 18.343888 },
    { lat: 48.681097, lng: 18.343543 },
    { lat: 48.6810407, lng: 18.3431946 },
    { lat: 48.6809962, lng: 18.3423453 },
    { lat: 48.681077, lng: 18.3405733 },
    { lat: 48.6811847, lng: 18.3396436 },
    { lat: 48.6811274, lng: 18.3379647 },
    { lat: 48.6813322, lng: 18.3371129 },
    { lat: 48.6816775, lng: 18.336453 },
    { lat: 48.6817634, lng: 18.3360763 },
    { lat: 48.6818084, lng: 18.3355329 },
    { lat: 48.6818589, lng: 18.3350069 },
    { lat: 48.6818729, lng: 18.3345559 },
    { lat: 48.6817355, lng: 18.3340019 },
    { lat: 48.6814624, lng: 18.3335601 },
    { lat: 48.6813925, lng: 18.3333816 },
    { lat: 48.6812907, lng: 18.333178 },
    { lat: 48.6811427, lng: 18.3327136 },
    { lat: 48.6810254, lng: 18.332171 },
    { lat: 48.6809539, lng: 18.3319477 },
    { lat: 48.6806195, lng: 18.3311792 },
    { lat: 48.6802944, lng: 18.3307137 },
    { lat: 48.6800242, lng: 18.3304039 },
    { lat: 48.6798938, lng: 18.330154 },
    { lat: 48.6798525, lng: 18.3301436 },
    { lat: 48.6787461, lng: 18.329789 },
    { lat: 48.6783743, lng: 18.3294005 },
    { lat: 48.6780088, lng: 18.3288014 },
    { lat: 48.6777202, lng: 18.3286061 },
    { lat: 48.6776262, lng: 18.3286171 },
    { lat: 48.6774789, lng: 18.3285872 },
    { lat: 48.6771357, lng: 18.3283043 },
    { lat: 48.6764057, lng: 18.3277228 },
    { lat: 48.6760437, lng: 18.3274256 },
    { lat: 48.6759046, lng: 18.3273018 },
    { lat: 48.6758527, lng: 18.3272207 },
    { lat: 48.6756467, lng: 18.3270898 },
    { lat: 48.6753981, lng: 18.3269026 },
    { lat: 48.6750509, lng: 18.3266081 },
    { lat: 48.675051, lng: 18.3265089 },
    { lat: 48.6749374, lng: 18.3259373 },
    { lat: 48.6749056, lng: 18.3255955 },
    { lat: 48.6748515, lng: 18.3251947 },
    { lat: 48.6746974, lng: 18.3250243 },
    { lat: 48.6746951, lng: 18.3248618 },
    { lat: 48.6746461, lng: 18.3246928 },
    { lat: 48.6745865, lng: 18.3243789 },
    { lat: 48.6745659, lng: 18.3240145 },
    { lat: 48.6744932, lng: 18.3231457 },
    { lat: 48.6744084, lng: 18.3227243 },
    { lat: 48.6742798, lng: 18.3222054 },
    { lat: 48.6741209, lng: 18.3216914 },
    { lat: 48.6740837, lng: 18.3216894 },
    { lat: 48.6740189, lng: 18.320902 },
    { lat: 48.6740101, lng: 18.3203291 },
    { lat: 48.6740359, lng: 18.3198646 },
    { lat: 48.6741176, lng: 18.3186592 },
    { lat: 48.6741774, lng: 18.3177179 },
    { lat: 48.6742643, lng: 18.3161021 },
    { lat: 48.6741697, lng: 18.316105 },
    { lat: 48.6741978, lng: 18.3153071 },
    { lat: 48.6742964, lng: 18.3138565 },
    { lat: 48.6743344, lng: 18.3135538 },
    { lat: 48.6743474, lng: 18.313202 },
    { lat: 48.6743666, lng: 18.312886 },
    { lat: 48.6743767, lng: 18.3119655 },
    { lat: 48.6743969, lng: 18.3109069 },
    { lat: 48.6743707, lng: 18.3102845 },
    { lat: 48.6743545, lng: 18.3101199 },
    { lat: 48.6743268, lng: 18.3091921 },
    { lat: 48.6742778, lng: 18.308226 },
    { lat: 48.674217, lng: 18.3082092 },
    { lat: 48.674344, lng: 18.3062686 },
    { lat: 48.6743982, lng: 18.3054513 },
    { lat: 48.6743133, lng: 18.3052869 },
    { lat: 48.6732636, lng: 18.3049792 },
    { lat: 48.6709173, lng: 18.3042927 },
    { lat: 48.6707452, lng: 18.3042639 },
    { lat: 48.6705755, lng: 18.304314 },
    { lat: 48.6686226, lng: 18.3054707 },
    { lat: 48.6683379, lng: 18.3056042 },
    { lat: 48.6667263, lng: 18.3060772 },
    { lat: 48.6654488, lng: 18.3064488 },
    { lat: 48.6653301, lng: 18.3064266 },
    { lat: 48.665261, lng: 18.306354 },
    { lat: 48.665206, lng: 18.306314 },
    { lat: 48.665227, lng: 18.306514 },
    { lat: 48.665515, lng: 18.307416 },
    { lat: 48.665486, lng: 18.307514 },
    { lat: 48.664451, lng: 18.30822 },
    { lat: 48.664471, lng: 18.308295 },
    { lat: 48.664558, lng: 18.308593 },
    { lat: 48.664579, lng: 18.308643 },
    { lat: 48.664593, lng: 18.308676 },
    { lat: 48.664706, lng: 18.308943 },
    { lat: 48.664891, lng: 18.309391 },
    { lat: 48.665049, lng: 18.309287 },
    { lat: 48.665194, lng: 18.309192 },
    { lat: 48.665743, lng: 18.308833 },
    { lat: 48.665947, lng: 18.308695 },
    { lat: 48.665998, lng: 18.308658 },
    { lat: 48.666053, lng: 18.308792 },
    { lat: 48.666481, lng: 18.30984 },
    { lat: 48.6666, lng: 18.310147 },
    { lat: 48.666212, lng: 18.31052 },
    { lat: 48.665666, lng: 18.311045 },
    { lat: 48.665417, lng: 18.311229 },
    { lat: 48.665351, lng: 18.311286 },
    { lat: 48.665212, lng: 18.31132 },
    { lat: 48.664566, lng: 18.311508 },
    { lat: 48.663933, lng: 18.311631 },
    { lat: 48.663166, lng: 18.311825 },
    { lat: 48.663072, lng: 18.311868 },
    { lat: 48.662955, lng: 18.311867 },
    { lat: 48.662245, lng: 18.311728 },
    { lat: 48.661797, lng: 18.311574 },
    { lat: 48.661247, lng: 18.311455 },
    { lat: 48.660472, lng: 18.311214 },
    { lat: 48.65987, lng: 18.311005 },
    { lat: 48.659278, lng: 18.31065 },
    { lat: 48.658816, lng: 18.31029 },
    { lat: 48.658391, lng: 18.30997 },
    { lat: 48.658075, lng: 18.309659 },
    { lat: 48.657611, lng: 18.309321 },
    { lat: 48.657432, lng: 18.309222 },
    { lat: 48.657432, lng: 18.309332 },
    { lat: 48.657429, lng: 18.309659 },
    { lat: 48.657415, lng: 18.309855 },
    { lat: 48.657537, lng: 18.311162 },
    { lat: 48.657593, lng: 18.311443 },
    { lat: 48.657765, lng: 18.31206 },
    { lat: 48.657797, lng: 18.312138 },
    { lat: 48.657835, lng: 18.312184 },
    { lat: 48.657845, lng: 18.312252 },
    { lat: 48.657957, lng: 18.312996 },
    { lat: 48.658053, lng: 18.313917 },
    { lat: 48.65807, lng: 18.314231 },
    { lat: 48.658102, lng: 18.314865 },
    { lat: 48.658209, lng: 18.315201 },
    { lat: 48.658216, lng: 18.315336 },
    { lat: 48.658115, lng: 18.316216 },
    { lat: 48.658014, lng: 18.317246 },
    { lat: 48.657951, lng: 18.317438 },
    { lat: 48.657944, lng: 18.317462 },
    { lat: 48.657868, lng: 18.317517 },
    { lat: 48.65784, lng: 18.317538 },
    { lat: 48.657347, lng: 18.318087 },
    { lat: 48.657036, lng: 18.318471 },
    { lat: 48.6568, lng: 18.318754 },
    { lat: 48.655909, lng: 18.319959 },
    { lat: 48.655672, lng: 18.320288 },
    { lat: 48.65485, lng: 18.32143 },
    { lat: 48.654681, lng: 18.321669 },
    { lat: 48.654672, lng: 18.321681 },
    { lat: 48.65419, lng: 18.322071 },
    { lat: 48.653946, lng: 18.322233 },
    { lat: 48.653608, lng: 18.322606 },
    { lat: 48.653401, lng: 18.322987 },
    { lat: 48.653196, lng: 18.323168 },
    { lat: 48.653005, lng: 18.323268 },
    { lat: 48.652951, lng: 18.323302 },
    { lat: 48.652914, lng: 18.323325 },
    { lat: 48.652879, lng: 18.323346 },
    { lat: 48.652843, lng: 18.323369 },
    { lat: 48.652566, lng: 18.323541 },
    { lat: 48.652295, lng: 18.323746 },
    { lat: 48.652163, lng: 18.323845 },
    { lat: 48.651485, lng: 18.32424 },
    { lat: 48.650588, lng: 18.324692 },
    { lat: 48.649927, lng: 18.324913 },
    { lat: 48.649779, lng: 18.324918 },
    { lat: 48.649462, lng: 18.324659 },
    { lat: 48.648581, lng: 18.324359 },
    { lat: 48.648139, lng: 18.325047 },
    { lat: 48.647682, lng: 18.325758 },
    { lat: 48.647079, lng: 18.326823 },
    { lat: 48.646643, lng: 18.327684 },
  ],
  Omastiná: [
    { lat: 48.773421, lng: 18.433476 },
    { lat: 48.773502, lng: 18.433629 },
    { lat: 48.773805, lng: 18.433877 },
    { lat: 48.774113, lng: 18.433925 },
    { lat: 48.77474, lng: 18.433853 },
    { lat: 48.774849, lng: 18.433872 },
    { lat: 48.775455, lng: 18.433735 },
    { lat: 48.77572, lng: 18.433915 },
    { lat: 48.776201, lng: 18.434222 },
    { lat: 48.776395, lng: 18.434297 },
    { lat: 48.776646, lng: 18.43436 },
    { lat: 48.777028, lng: 18.434778 },
    { lat: 48.777243, lng: 18.434906 },
    { lat: 48.777546, lng: 18.434947 },
    { lat: 48.777882, lng: 18.435023 },
    { lat: 48.77818, lng: 18.435142 },
    { lat: 48.778351, lng: 18.435329 },
    { lat: 48.778518, lng: 18.435435 },
    { lat: 48.778758, lng: 18.435694 },
    { lat: 48.778979, lng: 18.43591 },
    { lat: 48.779274, lng: 18.436372 },
    { lat: 48.779517, lng: 18.436536 },
    { lat: 48.779917, lng: 18.436505 },
    { lat: 48.780263, lng: 18.436834 },
    { lat: 48.780832, lng: 18.437304 },
    { lat: 48.78123, lng: 18.437501 },
    { lat: 48.781393, lng: 18.437733 },
    { lat: 48.781724, lng: 18.437994 },
    { lat: 48.781866, lng: 18.438245 },
    { lat: 48.782047, lng: 18.438642 },
    { lat: 48.782112, lng: 18.43868 },
    { lat: 48.782529, lng: 18.438468 },
    { lat: 48.782957, lng: 18.438325 },
    { lat: 48.783403, lng: 18.438308 },
    { lat: 48.78385, lng: 18.437623 },
    { lat: 48.784473, lng: 18.436849 },
    { lat: 48.785025, lng: 18.435498 },
    { lat: 48.785194, lng: 18.435308 },
    { lat: 48.785192, lng: 18.433423 },
    { lat: 48.785191, lng: 18.432594 },
    { lat: 48.785205, lng: 18.431887 },
    { lat: 48.785684, lng: 18.430523 },
    { lat: 48.786137, lng: 18.4293 },
    { lat: 48.786252, lng: 18.429051 },
    { lat: 48.786516, lng: 18.429 },
    { lat: 48.786643, lng: 18.428871 },
    { lat: 48.786905, lng: 18.428437 },
    { lat: 48.786938, lng: 18.428257 },
    { lat: 48.787222, lng: 18.428064 },
    { lat: 48.787723, lng: 18.427923 },
    { lat: 48.788, lng: 18.427883 },
    { lat: 48.788183, lng: 18.427563 },
    { lat: 48.788483, lng: 18.427468 },
    { lat: 48.789069, lng: 18.427234 },
    { lat: 48.789255, lng: 18.427052 },
    { lat: 48.789316, lng: 18.427024 },
    { lat: 48.789355, lng: 18.427006 },
    { lat: 48.790069, lng: 18.426679 },
    { lat: 48.790073, lng: 18.426704 },
    { lat: 48.790417, lng: 18.426299 },
    { lat: 48.790718, lng: 18.426056 },
    { lat: 48.790972, lng: 18.426041 },
    { lat: 48.791267, lng: 18.425935 },
    { lat: 48.791262, lng: 18.425914 },
    { lat: 48.791253, lng: 18.425862 },
    { lat: 48.791484, lng: 18.425456 },
    { lat: 48.791687, lng: 18.425636 },
    { lat: 48.792121, lng: 18.426034 },
    { lat: 48.792503, lng: 18.426167 },
    { lat: 48.7925, lng: 18.426191 },
    { lat: 48.792865, lng: 18.426336 },
    { lat: 48.7934, lng: 18.426332 },
    { lat: 48.7934, lng: 18.426311 },
    { lat: 48.79398, lng: 18.426208 },
    { lat: 48.794227, lng: 18.426347 },
    { lat: 48.79459, lng: 18.426331 },
    { lat: 48.795043, lng: 18.425853 },
    { lat: 48.795321, lng: 18.425526 },
    { lat: 48.795777, lng: 18.425407 },
    { lat: 48.796026, lng: 18.425307 },
    { lat: 48.796333, lng: 18.425128 },
    { lat: 48.796635, lng: 18.42492 },
    { lat: 48.796862, lng: 18.424555 },
    { lat: 48.79708, lng: 18.424421 },
    { lat: 48.797397, lng: 18.424206 },
    { lat: 48.797858, lng: 18.423791 },
    { lat: 48.79833, lng: 18.423781 },
    { lat: 48.798498, lng: 18.423618 },
    { lat: 48.798733, lng: 18.42344 },
    { lat: 48.799087, lng: 18.423427 },
    { lat: 48.799353, lng: 18.42347 },
    { lat: 48.799579, lng: 18.423354 },
    { lat: 48.79983, lng: 18.423342 },
    { lat: 48.800259, lng: 18.423145 },
    { lat: 48.800553, lng: 18.422917 },
    { lat: 48.80104, lng: 18.422734 },
    { lat: 48.801316, lng: 18.422324 },
    { lat: 48.801419, lng: 18.422308 },
    { lat: 48.801738, lng: 18.420624 },
    { lat: 48.801751, lng: 18.420039 },
    { lat: 48.801796, lng: 18.419675 },
    { lat: 48.801887, lng: 18.418956 },
    { lat: 48.802077, lng: 18.4174 },
    { lat: 48.8021791, lng: 18.4169642 },
    { lat: 48.802311, lng: 18.416504 },
    { lat: 48.802428, lng: 18.415752 },
    { lat: 48.802804, lng: 18.414433 },
    { lat: 48.803041, lng: 18.413703 },
    { lat: 48.803348, lng: 18.413211 },
    { lat: 48.80369, lng: 18.412807 },
    { lat: 48.803977, lng: 18.412218 },
    { lat: 48.804248, lng: 18.411827 },
    { lat: 48.804526, lng: 18.411813 },
    { lat: 48.804687, lng: 18.411738 },
    { lat: 48.805007, lng: 18.411449 },
    { lat: 48.805304, lng: 18.411007 },
    { lat: 48.805916, lng: 18.410611 },
    { lat: 48.806431, lng: 18.409992 },
    { lat: 48.806779, lng: 18.409749 },
    { lat: 48.806933, lng: 18.409452 },
    { lat: 48.807311, lng: 18.408696 },
    { lat: 48.807528, lng: 18.408631 },
    { lat: 48.8072597, lng: 18.4077366 },
    { lat: 48.8069897, lng: 18.4073032 },
    { lat: 48.8068619, lng: 18.4072372 },
    { lat: 48.806683, lng: 18.4061488 },
    { lat: 48.8067414, lng: 18.4054946 },
    { lat: 48.8068169, lng: 18.4042978 },
    { lat: 48.8067891, lng: 18.4038368 },
    { lat: 48.8066546, lng: 18.4029181 },
    { lat: 48.8063164, lng: 18.4021763 },
    { lat: 48.8062674, lng: 18.4015602 },
    { lat: 48.8066216, lng: 18.4007079 },
    { lat: 48.8064921, lng: 18.4001647 },
    { lat: 48.8064071, lng: 18.3992661 },
    { lat: 48.8059875, lng: 18.3986966 },
    { lat: 48.8056487, lng: 18.3985913 },
    { lat: 48.8054194, lng: 18.3982587 },
    { lat: 48.8050848, lng: 18.3979049 },
    { lat: 48.8048358, lng: 18.3979503 },
    { lat: 48.8044485, lng: 18.3978222 },
    { lat: 48.8040712, lng: 18.3979165 },
    { lat: 48.8037486, lng: 18.3977129 },
    { lat: 48.8032633, lng: 18.3971826 },
    { lat: 48.8031349, lng: 18.3965376 },
    { lat: 48.8030547, lng: 18.3956664 },
    { lat: 48.8032558, lng: 18.3947406 },
    { lat: 48.8026813, lng: 18.3937418 },
    { lat: 48.8023645, lng: 18.3935257 },
    { lat: 48.8022271, lng: 18.3924342 },
    { lat: 48.8022755, lng: 18.39217 },
    { lat: 48.802201, lng: 18.3916012 },
    { lat: 48.8013474, lng: 18.3909834 },
    { lat: 48.8008438, lng: 18.3905718 },
    { lat: 48.800556, lng: 18.3904092 },
    { lat: 48.8002833, lng: 18.3902283 },
    { lat: 48.7999553, lng: 18.3900526 },
    { lat: 48.7995532, lng: 18.3899644 },
    { lat: 48.7993798, lng: 18.3897883 },
    { lat: 48.7990194, lng: 18.3894933 },
    { lat: 48.7988391, lng: 18.3891768 },
    { lat: 48.7984423, lng: 18.3886954 },
    { lat: 48.7981859, lng: 18.388527 },
    { lat: 48.7977839, lng: 18.3879325 },
    { lat: 48.7973431, lng: 18.3875576 },
    { lat: 48.7969117, lng: 18.3874471 },
    { lat: 48.7964041, lng: 18.387045 },
    { lat: 48.796062, lng: 18.3867549 },
    { lat: 48.7958123, lng: 18.3866068 },
    { lat: 48.7955158, lng: 18.3864479 },
    { lat: 48.7948583, lng: 18.3865534 },
    { lat: 48.7944277, lng: 18.3866196 },
    { lat: 48.7937053, lng: 18.3864929 },
    { lat: 48.7933278, lng: 18.3860567 },
    { lat: 48.792957, lng: 18.3853141 },
    { lat: 48.7926798, lng: 18.385016 },
    { lat: 48.7920845, lng: 18.3844986 },
    { lat: 48.7919813, lng: 18.3842452 },
    { lat: 48.7918636, lng: 18.3839958 },
    { lat: 48.7915177, lng: 18.3834783 },
    { lat: 48.7910811, lng: 18.3831578 },
    { lat: 48.7906739, lng: 18.3829576 },
    { lat: 48.7901055, lng: 18.3825952 },
    { lat: 48.789838, lng: 18.3824115 },
    { lat: 48.7895335, lng: 18.3821707 },
    { lat: 48.7892657, lng: 18.3818783 },
    { lat: 48.7891161, lng: 18.3817654 },
    { lat: 48.7887577, lng: 18.3817726 },
    { lat: 48.7885099, lng: 18.3818025 },
    { lat: 48.7882847, lng: 18.3817494 },
    { lat: 48.7880269, lng: 18.3817763 },
    { lat: 48.787991, lng: 18.3819036 },
    { lat: 48.7877886, lng: 18.3819749 },
    { lat: 48.7875618, lng: 18.381995 },
    { lat: 48.7871603, lng: 18.3820778 },
    { lat: 48.7867827, lng: 18.3821444 },
    { lat: 48.7865671, lng: 18.3821408 },
    { lat: 48.7864325, lng: 18.3819319 },
    { lat: 48.7863651, lng: 18.3817953 },
    { lat: 48.7863267, lng: 18.3814346 },
    { lat: 48.7862533, lng: 18.3812297 },
    { lat: 48.785898, lng: 18.3812712 },
    { lat: 48.7855558, lng: 18.3814845 },
    { lat: 48.7852884, lng: 18.381425 },
    { lat: 48.7851609, lng: 18.3810599 },
    { lat: 48.7849644, lng: 18.3808772 },
    { lat: 48.784351, lng: 18.3808616 },
    { lat: 48.7840167, lng: 18.3808353 },
    { lat: 48.7835006, lng: 18.3806824 },
    { lat: 48.7830203, lng: 18.3805077 },
    { lat: 48.7825688, lng: 18.3804435 },
    { lat: 48.7819905, lng: 18.3805107 },
    { lat: 48.7818195, lng: 18.3806409 },
    { lat: 48.7813589, lng: 18.3809184 },
    { lat: 48.7808332, lng: 18.3811609 },
    { lat: 48.780234, lng: 18.3813817 },
    { lat: 48.779835, lng: 18.3815483 },
    { lat: 48.779442, lng: 18.3817037 },
    { lat: 48.7788714, lng: 18.3818912 },
    { lat: 48.7786725, lng: 18.3819874 },
    { lat: 48.778305, lng: 18.3824333 },
    { lat: 48.7780469, lng: 18.3827006 },
    { lat: 48.7772872, lng: 18.3833006 },
    { lat: 48.7769863, lng: 18.383509 },
    { lat: 48.7762148, lng: 18.3836938 },
    { lat: 48.7755731, lng: 18.3838996 },
    { lat: 48.7754764, lng: 18.3842672 },
    { lat: 48.7752389, lng: 18.3843998 },
    { lat: 48.7748914, lng: 18.3840744 },
    { lat: 48.7746184, lng: 18.3838339 },
    { lat: 48.774307, lng: 18.3836509 },
    { lat: 48.7741344, lng: 18.3835317 },
    { lat: 48.7737954, lng: 18.3834806 },
    { lat: 48.7735122, lng: 18.3835622 },
    { lat: 48.773255, lng: 18.3836419 },
    { lat: 48.7729583, lng: 18.3837446 },
    { lat: 48.7726802, lng: 18.3838942 },
    { lat: 48.7725903, lng: 18.3840001 },
    { lat: 48.772242, lng: 18.3840661 },
    { lat: 48.7721346, lng: 18.3840641 },
    { lat: 48.7718361, lng: 18.3840118 },
    { lat: 48.7716661, lng: 18.3839563 },
    { lat: 48.7715485, lng: 18.3838107 },
    { lat: 48.7713884, lng: 18.3835504 },
    { lat: 48.7713314, lng: 18.3834182 },
    { lat: 48.7712089, lng: 18.3829013 },
    { lat: 48.7710876, lng: 18.3825084 },
    { lat: 48.771023, lng: 18.3821172 },
    { lat: 48.7709142, lng: 18.381845 },
    { lat: 48.7707802, lng: 18.3816099 },
    { lat: 48.7705153, lng: 18.3812314 },
    { lat: 48.7702381, lng: 18.3808903 },
    { lat: 48.769856, lng: 18.3804749 },
    { lat: 48.7697086, lng: 18.3802771 },
    { lat: 48.7694742, lng: 18.3799405 },
    { lat: 48.7692765, lng: 18.3795934 },
    { lat: 48.7691278, lng: 18.3793138 },
    { lat: 48.7690247, lng: 18.3790437 },
    { lat: 48.7689399, lng: 18.3787771 },
    { lat: 48.7689035, lng: 18.3786122 },
    { lat: 48.7688924, lng: 18.3784516 },
    { lat: 48.7688385, lng: 18.3785375 },
    { lat: 48.768478, lng: 18.3791716 },
    { lat: 48.7683889, lng: 18.3794348 },
    { lat: 48.7682064, lng: 18.3802095 },
    { lat: 48.7681454, lng: 18.3806315 },
    { lat: 48.7681541, lng: 18.3811092 },
    { lat: 48.7682074, lng: 18.3818223 },
    { lat: 48.7683867, lng: 18.3825848 },
    { lat: 48.7687217, lng: 18.3837572 },
    { lat: 48.7687475, lng: 18.3837539 },
    { lat: 48.7690999, lng: 18.3849767 },
    { lat: 48.7690295, lng: 18.3850071 },
    { lat: 48.7688838, lng: 18.3849835 },
    { lat: 48.7689633, lng: 18.3851547 },
    { lat: 48.7689028, lng: 18.3854847 },
    { lat: 48.7686712, lng: 18.3862066 },
    { lat: 48.7684465, lng: 18.3868319 },
    { lat: 48.7684261, lng: 18.3873337 },
    { lat: 48.768457, lng: 18.3880901 },
    { lat: 48.7684016, lng: 18.3887112 },
    { lat: 48.7684269, lng: 18.3893689 },
    { lat: 48.768244, lng: 18.3899123 },
    { lat: 48.7681055, lng: 18.3905444 },
    { lat: 48.7678533, lng: 18.3912832 },
    { lat: 48.767498, lng: 18.3919694 },
    { lat: 48.7673785, lng: 18.3925555 },
    { lat: 48.7672745, lng: 18.3932818 },
    { lat: 48.7670253, lng: 18.3941548 },
    { lat: 48.7671447, lng: 18.394455 },
    { lat: 48.7673327, lng: 18.3948723 },
    { lat: 48.7675331, lng: 18.3954733 },
    { lat: 48.7677884, lng: 18.3964896 },
    { lat: 48.7678972, lng: 18.3969839 },
    { lat: 48.768074, lng: 18.3971827 },
    { lat: 48.7683095, lng: 18.3978454 },
    { lat: 48.7685747, lng: 18.3984553 },
    { lat: 48.768824, lng: 18.3989371 },
    { lat: 48.7691137, lng: 18.3992781 },
    { lat: 48.7694229, lng: 18.3995241 },
    { lat: 48.7692745, lng: 18.3998349 },
    { lat: 48.7692468, lng: 18.400411 },
    { lat: 48.7693102, lng: 18.4010652 },
    { lat: 48.7695149, lng: 18.4017823 },
    { lat: 48.7692121, lng: 18.4029292 },
    { lat: 48.7688981, lng: 18.4044022 },
    { lat: 48.7694966, lng: 18.4057887 },
    { lat: 48.7699657, lng: 18.4069424 },
    { lat: 48.7702777, lng: 18.4076215 },
    { lat: 48.7706716, lng: 18.4087011 },
    { lat: 48.770857, lng: 18.4093993 },
    { lat: 48.7713352, lng: 18.4104095 },
    { lat: 48.7718073, lng: 18.4116985 },
    { lat: 48.7721471, lng: 18.4127557 },
    { lat: 48.772281, lng: 18.4130541 },
    { lat: 48.7723142, lng: 18.4136722 },
    { lat: 48.7722738, lng: 18.4150346 },
    { lat: 48.7726233, lng: 18.4163514 },
    { lat: 48.7727755, lng: 18.4176908 },
    { lat: 48.7736032, lng: 18.4199178 },
    { lat: 48.7737183, lng: 18.4206925 },
    { lat: 48.7736594, lng: 18.4221099 },
    { lat: 48.773799, lng: 18.4232027 },
    { lat: 48.7738623, lng: 18.423935 },
    { lat: 48.7737563, lng: 18.4245686 },
    { lat: 48.7735769, lng: 18.4256257 },
    { lat: 48.7735601, lng: 18.4262391 },
    { lat: 48.773491, lng: 18.4275115 },
    { lat: 48.7733178, lng: 18.4283364 },
    { lat: 48.7734815, lng: 18.4291314 },
    { lat: 48.7734903, lng: 18.4298392 },
    { lat: 48.7731779, lng: 18.4306556 },
    { lat: 48.772868, lng: 18.4313516 },
    { lat: 48.7727981, lng: 18.4322485 },
    { lat: 48.773033, lng: 18.4326007 },
    { lat: 48.773421, lng: 18.433476 },
  ],
  Otrhánky: [
    { lat: 48.6818017, lng: 18.1872191 },
    { lat: 48.6817928, lng: 18.1872792 },
    { lat: 48.6818189, lng: 18.187419 },
    { lat: 48.6818562, lng: 18.1874808 },
    { lat: 48.6818598, lng: 18.1876782 },
    { lat: 48.6818344, lng: 18.1877658 },
    { lat: 48.6817849, lng: 18.1877902 },
    { lat: 48.681632, lng: 18.1876232 },
    { lat: 48.6815807, lng: 18.1876317 },
    { lat: 48.6815322, lng: 18.1876724 },
    { lat: 48.6814763, lng: 18.1876833 },
    { lat: 48.681274, lng: 18.1878327 },
    { lat: 48.6811636, lng: 18.1877958 },
    { lat: 48.6810403, lng: 18.1877121 },
    { lat: 48.6809152, lng: 18.1877942 },
    { lat: 48.6808883, lng: 18.1878777 },
    { lat: 48.6808846, lng: 18.1881607 },
    { lat: 48.680798, lng: 18.1883042 },
    { lat: 48.680704, lng: 18.1883587 },
    { lat: 48.6806563, lng: 18.1884921 },
    { lat: 48.6806391, lng: 18.18864 },
    { lat: 48.6805828, lng: 18.1888003 },
    { lat: 48.6805449, lng: 18.1888271 },
    { lat: 48.6803433, lng: 18.1893049 },
    { lat: 48.6802357, lng: 18.1894516 },
    { lat: 48.6802029, lng: 18.1894119 },
    { lat: 48.6801965, lng: 18.1893624 },
    { lat: 48.6801577, lng: 18.1892758 },
    { lat: 48.6801309, lng: 18.1892793 },
    { lat: 48.6800884, lng: 18.1893452 },
    { lat: 48.6800218, lng: 18.1895204 },
    { lat: 48.6799492, lng: 18.1896349 },
    { lat: 48.6798476, lng: 18.1894751 },
    { lat: 48.6796983, lng: 18.1894497 },
    { lat: 48.6795663, lng: 18.189527 },
    { lat: 48.6794781, lng: 18.189859 },
    { lat: 48.6794201, lng: 18.1899276 },
    { lat: 48.6793827, lng: 18.1900415 },
    { lat: 48.6793534, lng: 18.1901817 },
    { lat: 48.6793418, lng: 18.1903118 },
    { lat: 48.6792953, lng: 18.1903465 },
    { lat: 48.6792468, lng: 18.1903461 },
    { lat: 48.6791548, lng: 18.19028 },
    { lat: 48.6791084, lng: 18.1902761 },
    { lat: 48.6790994, lng: 18.1903165 },
    { lat: 48.6789894, lng: 18.1903649 },
    { lat: 48.6789884, lng: 18.1904457 },
    { lat: 48.6789616, lng: 18.1905093 },
    { lat: 48.6789736, lng: 18.1905777 },
    { lat: 48.6788923, lng: 18.1906358 },
    { lat: 48.678794, lng: 18.1906173 },
    { lat: 48.6787051, lng: 18.1905437 },
    { lat: 48.6786342, lng: 18.1904669 },
    { lat: 48.6785923, lng: 18.190466 },
    { lat: 48.6785287, lng: 18.1905758 },
    { lat: 48.6784419, lng: 18.190655 },
    { lat: 48.678333, lng: 18.1906844 },
    { lat: 48.6782872, lng: 18.1906336 },
    { lat: 48.6782238, lng: 18.1905909 },
    { lat: 48.6781516, lng: 18.19061 },
    { lat: 48.6779977, lng: 18.1907581 },
    { lat: 48.6779568, lng: 18.1908127 },
    { lat: 48.6778221, lng: 18.1909247 },
    { lat: 48.6776386, lng: 18.1915401 },
    { lat: 48.6773571, lng: 18.1924694 },
    { lat: 48.6770873, lng: 18.1933304 },
    { lat: 48.6770775, lng: 18.1934145 },
    { lat: 48.6771099, lng: 18.193993 },
    { lat: 48.6770298, lng: 18.1943892 },
    { lat: 48.677029, lng: 18.1945671 },
    { lat: 48.6769215, lng: 18.1946805 },
    { lat: 48.6773628, lng: 18.1956248 },
    { lat: 48.6777562, lng: 18.1964404 },
    { lat: 48.677957, lng: 18.197045 },
    { lat: 48.6783228, lng: 18.1986749 },
    { lat: 48.678409, lng: 18.199076 },
    { lat: 48.6786296, lng: 18.2001636 },
    { lat: 48.678686, lng: 18.200494 },
    { lat: 48.6790512, lng: 18.2023135 },
    { lat: 48.6792424, lng: 18.2029694 },
    { lat: 48.6795345, lng: 18.2036513 },
    { lat: 48.6799444, lng: 18.2045013 },
    { lat: 48.6803313, lng: 18.2053822 },
    { lat: 48.6804462, lng: 18.2056317 },
    { lat: 48.6807898, lng: 18.2061912 },
    { lat: 48.6808134, lng: 18.2063059 },
    { lat: 48.6811573, lng: 18.2069275 },
    { lat: 48.6813432, lng: 18.2072206 },
    { lat: 48.6815093, lng: 18.2074198 },
    { lat: 48.6816541, lng: 18.2075615 },
    { lat: 48.6820279, lng: 18.2079018 },
    { lat: 48.6822617, lng: 18.2081604 },
    { lat: 48.6826581, lng: 18.2086765 },
    { lat: 48.6829609, lng: 18.2090946 },
    { lat: 48.6833091, lng: 18.2095564 },
    { lat: 48.6836643, lng: 18.210183 },
    { lat: 48.6838276, lng: 18.2105684 },
    { lat: 48.6839955, lng: 18.2108813 },
    { lat: 48.6840629, lng: 18.2109521 },
    { lat: 48.6842987, lng: 18.2114262 },
    { lat: 48.6845193, lng: 18.2118858 },
    { lat: 48.6847132, lng: 18.2123136 },
    { lat: 48.6850417, lng: 18.2128883 },
    { lat: 48.6853664, lng: 18.2134689 },
    { lat: 48.6857632, lng: 18.2141323 },
    { lat: 48.6859884, lng: 18.214537 },
    { lat: 48.6865661, lng: 18.2154195 },
    { lat: 48.6872669, lng: 18.216658 },
    { lat: 48.6879942, lng: 18.2178885 },
    { lat: 48.6884387, lng: 18.2186703 },
    { lat: 48.6890446, lng: 18.2197145 },
    { lat: 48.6894357, lng: 18.2194094 },
    { lat: 48.6898279, lng: 18.2190873 },
    { lat: 48.6901146, lng: 18.2188759 },
    { lat: 48.6907396, lng: 18.2183408 },
    { lat: 48.691662, lng: 18.2175604 },
    { lat: 48.6918977, lng: 18.2173631 },
    { lat: 48.6929511, lng: 18.216468 },
    { lat: 48.6939042, lng: 18.2156567 },
    { lat: 48.6951086, lng: 18.2144059 },
    { lat: 48.6954579, lng: 18.2140416 },
    { lat: 48.6961872, lng: 18.2132852 },
    { lat: 48.6974335, lng: 18.2119953 },
    { lat: 48.6975377, lng: 18.2118655 },
    { lat: 48.6975909, lng: 18.2117289 },
    { lat: 48.6977134, lng: 18.211525 },
    { lat: 48.6983324, lng: 18.2107705 },
    { lat: 48.6984816, lng: 18.2106184 },
    { lat: 48.6990075, lng: 18.2101655 },
    { lat: 48.699131, lng: 18.2100757 },
    { lat: 48.6992971, lng: 18.2099217 },
    { lat: 48.6995396, lng: 18.2097256 },
    { lat: 48.6996876, lng: 18.2096519 },
    { lat: 48.699874, lng: 18.2095164 },
    { lat: 48.6999887, lng: 18.2093895 },
    { lat: 48.7001128, lng: 18.2091339 },
    { lat: 48.7001723, lng: 18.2089922 },
    { lat: 48.7002959, lng: 18.2087698 },
    { lat: 48.700366, lng: 18.2086731 },
    { lat: 48.7004621, lng: 18.2085962 },
    { lat: 48.7006381, lng: 18.2085185 },
    { lat: 48.7013966, lng: 18.2079332 },
    { lat: 48.701777, lng: 18.2075625 },
    { lat: 48.7018811, lng: 18.2074892 },
    { lat: 48.7019975, lng: 18.20747 },
    { lat: 48.7020721, lng: 18.207472 },
    { lat: 48.7022506, lng: 18.2074964 },
    { lat: 48.7031606, lng: 18.2076836 },
    { lat: 48.7032603, lng: 18.207707 },
    { lat: 48.7033472, lng: 18.2077081 },
    { lat: 48.7035153, lng: 18.2076499 },
    { lat: 48.7036563, lng: 18.2075896 },
    { lat: 48.7037392, lng: 18.2075795 },
    { lat: 48.7040181, lng: 18.207504 },
    { lat: 48.7041777, lng: 18.2073762 },
    { lat: 48.7043131, lng: 18.2072763 },
    { lat: 48.7044321, lng: 18.2072067 },
    { lat: 48.7046415, lng: 18.2070992 },
    { lat: 48.7047552, lng: 18.2070561 },
    { lat: 48.7049037, lng: 18.2070484 },
    { lat: 48.7050271, lng: 18.2070147 },
    { lat: 48.7050884, lng: 18.2069827 },
    { lat: 48.7052132, lng: 18.206896 },
    { lat: 48.705362, lng: 18.2066792 },
    { lat: 48.7054395, lng: 18.2065333 },
    { lat: 48.7055224, lng: 18.2064049 },
    { lat: 48.7056071, lng: 18.206228 },
    { lat: 48.7056791, lng: 18.2060492 },
    { lat: 48.7057794, lng: 18.2058692 },
    { lat: 48.7059234, lng: 18.2055281 },
    { lat: 48.7059819, lng: 18.2054261 },
    { lat: 48.7061138, lng: 18.2053277 },
    { lat: 48.7061096, lng: 18.2051757 },
    { lat: 48.7052403, lng: 18.2043641 },
    { lat: 48.7051343, lng: 18.2044052 },
    { lat: 48.7050192, lng: 18.2044421 },
    { lat: 48.7048139, lng: 18.2044862 },
    { lat: 48.7046666, lng: 18.2044951 },
    { lat: 48.7044371, lng: 18.2045256 },
    { lat: 48.7036153, lng: 18.2045205 },
    { lat: 48.7034394, lng: 18.2042854 },
    { lat: 48.7032073, lng: 18.204 },
    { lat: 48.7028769, lng: 18.2035497 },
    { lat: 48.7027673, lng: 18.2034129 },
    { lat: 48.7027505, lng: 18.2033962 },
    { lat: 48.7026827, lng: 18.2032947 },
    { lat: 48.7024982, lng: 18.2030299 },
    { lat: 48.7022646, lng: 18.2027916 },
    { lat: 48.7020961, lng: 18.2026129 },
    { lat: 48.7019215, lng: 18.2024215 },
    { lat: 48.7018382, lng: 18.2023474 },
    { lat: 48.7017512, lng: 18.2022652 },
    { lat: 48.7016325, lng: 18.2021051 },
    { lat: 48.7015531, lng: 18.2019994 },
    { lat: 48.7014437, lng: 18.2018648 },
    { lat: 48.7013267, lng: 18.2017294 },
    { lat: 48.7012077, lng: 18.201606 },
    { lat: 48.7010609, lng: 18.2014667 },
    { lat: 48.7010009, lng: 18.2014006 },
    { lat: 48.7009403, lng: 18.20133 },
    { lat: 48.7008366, lng: 18.2011975 },
    { lat: 48.7006985, lng: 18.2010488 },
    { lat: 48.7004542, lng: 18.2008258 },
    { lat: 48.7001554, lng: 18.2005725 },
    { lat: 48.7000883, lng: 18.2005077 },
    { lat: 48.6999789, lng: 18.2003899 },
    { lat: 48.6998307, lng: 18.2002058 },
    { lat: 48.6995074, lng: 18.1997649 },
    { lat: 48.6994317, lng: 18.1996666 },
    { lat: 48.6993423, lng: 18.1995633 },
    { lat: 48.6989515, lng: 18.199166 },
    { lat: 48.6988465, lng: 18.1990061 },
    { lat: 48.6988257, lng: 18.1988613 },
    { lat: 48.698105, lng: 18.1977897 },
    { lat: 48.6976496, lng: 18.1972487 },
    { lat: 48.6976195, lng: 18.1972134 },
    { lat: 48.6974824, lng: 18.1973835 },
    { lat: 48.697365, lng: 18.1974793 },
    { lat: 48.6973492, lng: 18.1974607 },
    { lat: 48.6973315, lng: 18.1974283 },
    { lat: 48.6969081, lng: 18.1966581 },
    { lat: 48.6968944, lng: 18.1966367 },
    { lat: 48.6968618, lng: 18.1965802 },
    { lat: 48.6965053, lng: 18.1959538 },
    { lat: 48.6964464, lng: 18.1958347 },
    { lat: 48.6963553, lng: 18.1956241 },
    { lat: 48.6962955, lng: 18.1954841 },
    { lat: 48.696087, lng: 18.1949232 },
    { lat: 48.6960474, lng: 18.1948384 },
    { lat: 48.6959225, lng: 18.1945554 },
    { lat: 48.6958657, lng: 18.1944472 },
    { lat: 48.6958421, lng: 18.1943699 },
    { lat: 48.6958376, lng: 18.1942923 },
    { lat: 48.6958019, lng: 18.1938119 },
    { lat: 48.695784, lng: 18.1935378 },
    { lat: 48.6957607, lng: 18.1934083 },
    { lat: 48.6957583, lng: 18.1933865 },
    { lat: 48.6957328, lng: 18.1931212 },
    { lat: 48.6957252, lng: 18.1930258 },
    { lat: 48.6957234, lng: 18.1929946 },
    { lat: 48.6957051, lng: 18.1928162 },
    { lat: 48.6956072, lng: 18.1923533 },
    { lat: 48.6954628, lng: 18.1919394 },
    { lat: 48.69525, lng: 18.191303 },
    { lat: 48.6950092, lng: 18.1906085 },
    { lat: 48.6949881, lng: 18.1905387 },
    { lat: 48.6946654, lng: 18.190145 },
    { lat: 48.6943229, lng: 18.1897625 },
    { lat: 48.6941393, lng: 18.1895713 },
    { lat: 48.6936017, lng: 18.1889222 },
    { lat: 48.693437, lng: 18.1886864 },
    { lat: 48.6930928, lng: 18.1880966 },
    { lat: 48.6927838, lng: 18.1875925 },
    { lat: 48.6926559, lng: 18.1873718 },
    { lat: 48.6925217, lng: 18.1871562 },
    { lat: 48.6922461, lng: 18.1867035 },
    { lat: 48.6920365, lng: 18.1863371 },
    { lat: 48.6919591, lng: 18.1862048 },
    { lat: 48.6919092, lng: 18.1861224 },
    { lat: 48.6918045, lng: 18.1859315 },
    { lat: 48.6917606, lng: 18.1858537 },
    { lat: 48.6917125, lng: 18.1857672 },
    { lat: 48.6916648, lng: 18.1856806 },
    { lat: 48.6916183, lng: 18.185601 },
    { lat: 48.6914317, lng: 18.1852598 },
    { lat: 48.6912356, lng: 18.1849102 },
    { lat: 48.6911976, lng: 18.1848416 },
    { lat: 48.691045, lng: 18.1845614 },
    { lat: 48.6908946, lng: 18.1842833 },
    { lat: 48.6908555, lng: 18.1842123 },
    { lat: 48.6906778, lng: 18.1838903 },
    { lat: 48.6906413, lng: 18.1838237 },
    { lat: 48.6904417, lng: 18.1834532 },
    { lat: 48.690401, lng: 18.1833764 },
    { lat: 48.6901097, lng: 18.1828633 },
    { lat: 48.689816, lng: 18.1823315 },
    { lat: 48.6895235, lng: 18.1817786 },
    { lat: 48.6890554, lng: 18.180902 },
    { lat: 48.6889876, lng: 18.1807852 },
    { lat: 48.6889853, lng: 18.180783 },
    { lat: 48.6889564, lng: 18.1807325 },
    { lat: 48.6888829, lng: 18.1807693 },
    { lat: 48.68888, lng: 18.1808343 },
    { lat: 48.6888236, lng: 18.1809339 },
    { lat: 48.6887597, lng: 18.1809416 },
    { lat: 48.6886375, lng: 18.1808973 },
    { lat: 48.6885702, lng: 18.1808251 },
    { lat: 48.6884673, lng: 18.180743 },
    { lat: 48.6883117, lng: 18.1809231 },
    { lat: 48.6882052, lng: 18.1809707 },
    { lat: 48.6880964, lng: 18.1809257 },
    { lat: 48.6880418, lng: 18.180819 },
    { lat: 48.6880764, lng: 18.1807337 },
    { lat: 48.6879176, lng: 18.1807193 },
    { lat: 48.6877837, lng: 18.1806897 },
    { lat: 48.6876633, lng: 18.1805177 },
    { lat: 48.6875559, lng: 18.180418 },
    { lat: 48.6874373, lng: 18.1804161 },
    { lat: 48.6873428, lng: 18.180556 },
    { lat: 48.6873129, lng: 18.1807032 },
    { lat: 48.6872516, lng: 18.1808159 },
    { lat: 48.687232, lng: 18.1808285 },
    { lat: 48.687186, lng: 18.1808309 },
    { lat: 48.6870329, lng: 18.180759 },
    { lat: 48.6869459, lng: 18.1807393 },
    { lat: 48.6868706, lng: 18.1809017 },
    { lat: 48.6868198, lng: 18.181037 },
    { lat: 48.6867543, lng: 18.1811717 },
    { lat: 48.6866168, lng: 18.1811173 },
    { lat: 48.6863662, lng: 18.1812128 },
    { lat: 48.6862323, lng: 18.1814529 },
    { lat: 48.6861901, lng: 18.1815653 },
    { lat: 48.6860651, lng: 18.181706 },
    { lat: 48.6859408, lng: 18.1817609 },
    { lat: 48.6858248, lng: 18.1819387 },
    { lat: 48.6857313, lng: 18.1822935 },
    { lat: 48.685602, lng: 18.1824209 },
    { lat: 48.68549, lng: 18.182476 },
    { lat: 48.6854169, lng: 18.1827138 },
    { lat: 48.6854132, lng: 18.1828832 },
    { lat: 48.6853044, lng: 18.1829364 },
    { lat: 48.6851134, lng: 18.1829491 },
    { lat: 48.6849811, lng: 18.1828497 },
    { lat: 48.6849073, lng: 18.1828405 },
    { lat: 48.6849253, lng: 18.1829178 },
    { lat: 48.6849001, lng: 18.18299 },
    { lat: 48.6847417, lng: 18.1831176 },
    { lat: 48.6846421, lng: 18.1831329 },
    { lat: 48.6845732, lng: 18.1832509 },
    { lat: 48.684545, lng: 18.1833482 },
    { lat: 48.6845388, lng: 18.1833954 },
    { lat: 48.6845523, lng: 18.1835525 },
    { lat: 48.6846501, lng: 18.1837997 },
    { lat: 48.6846321, lng: 18.1839192 },
    { lat: 48.684571, lng: 18.183974 },
    { lat: 48.6845048, lng: 18.1839588 },
    { lat: 48.6844089, lng: 18.1841371 },
    { lat: 48.6843017, lng: 18.184213 },
    { lat: 48.6842896, lng: 18.1840639 },
    { lat: 48.6842259, lng: 18.1839969 },
    { lat: 48.6841556, lng: 18.1841061 },
    { lat: 48.684143, lng: 18.1841842 },
    { lat: 48.6841748, lng: 18.1843254 },
    { lat: 48.6841109, lng: 18.1843695 },
    { lat: 48.6839666, lng: 18.1843728 },
    { lat: 48.6838749, lng: 18.1844841 },
    { lat: 48.6837893, lng: 18.1845661 },
    { lat: 48.6836897, lng: 18.1845396 },
    { lat: 48.6834509, lng: 18.1845485 },
    { lat: 48.6834116, lng: 18.1848265 },
    { lat: 48.6834133, lng: 18.184992 },
    { lat: 48.6832929, lng: 18.1851342 },
    { lat: 48.683192, lng: 18.1852832 },
    { lat: 48.6831113, lng: 18.1853724 },
    { lat: 48.6828646, lng: 18.1854766 },
    { lat: 48.6826758, lng: 18.1854718 },
    { lat: 48.6824935, lng: 18.1856351 },
    { lat: 48.6824432, lng: 18.1857595 },
    { lat: 48.6824622, lng: 18.1858152 },
    { lat: 48.6824881, lng: 18.1858536 },
    { lat: 48.6825498, lng: 18.1859066 },
    { lat: 48.6826758, lng: 18.1861146 },
    { lat: 48.682598, lng: 18.1861763 },
    { lat: 48.6825057, lng: 18.1862163 },
    { lat: 48.6823907, lng: 18.1862182 },
    { lat: 48.6823987, lng: 18.1864971 },
    { lat: 48.6823228, lng: 18.1867067 },
    { lat: 48.6822621, lng: 18.1868268 },
    { lat: 48.6821913, lng: 18.1868533 },
    { lat: 48.6821243, lng: 18.1870206 },
    { lat: 48.6818966, lng: 18.1870249 },
    { lat: 48.6818017, lng: 18.1872191 },
  ],
  Pečeňany: [
    { lat: 48.680057, lng: 18.2429795 },
    { lat: 48.67997, lng: 18.242542 },
    { lat: 48.6798662, lng: 18.2421492 },
    { lat: 48.6797624, lng: 18.2418525 },
    { lat: 48.6796831, lng: 18.24161 },
    { lat: 48.6795362, lng: 18.240303 },
    { lat: 48.6794431, lng: 18.2394861 },
    { lat: 48.6797142, lng: 18.2383271 },
    { lat: 48.6801341, lng: 18.2377188 },
    { lat: 48.680241, lng: 18.237476 },
    { lat: 48.6804228, lng: 18.2370859 },
    { lat: 48.6809861, lng: 18.2367942 },
    { lat: 48.6818968, lng: 18.2363311 },
    { lat: 48.6824562, lng: 18.2360392 },
    { lat: 48.683353, lng: 18.2381464 },
    { lat: 48.6839454, lng: 18.238283 },
    { lat: 48.6844521, lng: 18.2383819 },
    { lat: 48.6849825, lng: 18.238523 },
    { lat: 48.6852179, lng: 18.2385755 },
    { lat: 48.6854066, lng: 18.2386005 },
    { lat: 48.6862275, lng: 18.238864 },
    { lat: 48.686309, lng: 18.2388884 },
    { lat: 48.6864248, lng: 18.2391597 },
    { lat: 48.6872231, lng: 18.2390277 },
    { lat: 48.6880456, lng: 18.2388518 },
    { lat: 48.6882616, lng: 18.2387025 },
    { lat: 48.6884479, lng: 18.2385075 },
    { lat: 48.6886381, lng: 18.2383388 },
    { lat: 48.6888528, lng: 18.2381664 },
    { lat: 48.6890264, lng: 18.2380045 },
    { lat: 48.689254, lng: 18.2377845 },
    { lat: 48.6895748, lng: 18.2375151 },
    { lat: 48.6896762, lng: 18.2374526 },
    { lat: 48.6899127, lng: 18.2373667 },
    { lat: 48.690106, lng: 18.2373111 },
    { lat: 48.6902498, lng: 18.237302 },
    { lat: 48.6903794, lng: 18.2373197 },
    { lat: 48.6907304, lng: 18.2373996 },
    { lat: 48.6908714, lng: 18.2374387 },
    { lat: 48.6911696, lng: 18.2375019 },
    { lat: 48.6912806, lng: 18.2375081 },
    { lat: 48.6914096, lng: 18.237497 },
    { lat: 48.6917605, lng: 18.2374559 },
    { lat: 48.6920513, lng: 18.2373886 },
    { lat: 48.6922156, lng: 18.2373222 },
    { lat: 48.6924498, lng: 18.2371752 },
    { lat: 48.6929762, lng: 18.236735 },
    { lat: 48.6928708, lng: 18.2361527 },
    { lat: 48.6927101, lng: 18.2353943 },
    { lat: 48.6926432, lng: 18.2350923 },
    { lat: 48.6925173, lng: 18.234492 },
    { lat: 48.6924881, lng: 18.2343383 },
    { lat: 48.6922813, lng: 18.2333849 },
    { lat: 48.6919553, lng: 18.2318714 },
    { lat: 48.6919157, lng: 18.2317116 },
    { lat: 48.6914976, lng: 18.229964 },
    { lat: 48.6913281, lng: 18.2293711 },
    { lat: 48.6913499, lng: 18.2293176 },
    { lat: 48.6913472, lng: 18.2293119 },
    { lat: 48.6911858, lng: 18.2287569 },
    { lat: 48.6910102, lng: 18.2280359 },
    { lat: 48.6908722, lng: 18.2274022 },
    { lat: 48.6906486, lng: 18.2263139 },
    { lat: 48.6902916, lng: 18.2244959 },
    { lat: 48.6901728, lng: 18.2239007 },
    { lat: 48.6898571, lng: 18.2222338 },
    { lat: 48.6904338, lng: 18.2218005 },
    { lat: 48.6905778, lng: 18.2218553 },
    { lat: 48.6904905, lng: 18.2209802 },
    { lat: 48.6903274, lng: 18.2202423 },
    { lat: 48.6902104, lng: 18.219914 },
    { lat: 48.6900112, lng: 18.2194512 },
    { lat: 48.6898279, lng: 18.2190873 },
    { lat: 48.6894357, lng: 18.2194094 },
    { lat: 48.6890446, lng: 18.2197145 },
    { lat: 48.6884387, lng: 18.2186703 },
    { lat: 48.6879942, lng: 18.2178885 },
    { lat: 48.6872669, lng: 18.216658 },
    { lat: 48.6865661, lng: 18.2154195 },
    { lat: 48.6859884, lng: 18.214537 },
    { lat: 48.6857632, lng: 18.2141323 },
    { lat: 48.6853664, lng: 18.2134689 },
    { lat: 48.6850417, lng: 18.2128883 },
    { lat: 48.6847132, lng: 18.2123136 },
    { lat: 48.6845193, lng: 18.2118858 },
    { lat: 48.6842987, lng: 18.2114262 },
    { lat: 48.6840629, lng: 18.2109521 },
    { lat: 48.6839955, lng: 18.2108813 },
    { lat: 48.6838276, lng: 18.2105684 },
    { lat: 48.6836643, lng: 18.210183 },
    { lat: 48.6833091, lng: 18.2095564 },
    { lat: 48.6829609, lng: 18.2090946 },
    { lat: 48.6826581, lng: 18.2086765 },
    { lat: 48.6822617, lng: 18.2081604 },
    { lat: 48.6820279, lng: 18.2079018 },
    { lat: 48.6816541, lng: 18.2075615 },
    { lat: 48.6815093, lng: 18.2074198 },
    { lat: 48.6813432, lng: 18.2072206 },
    { lat: 48.6811573, lng: 18.2069275 },
    { lat: 48.6808134, lng: 18.2063059 },
    { lat: 48.6807898, lng: 18.2061912 },
    { lat: 48.6804462, lng: 18.2056317 },
    { lat: 48.6803313, lng: 18.2053822 },
    { lat: 48.6799444, lng: 18.2045013 },
    { lat: 48.6795345, lng: 18.2036513 },
    { lat: 48.6792424, lng: 18.2029694 },
    { lat: 48.6790512, lng: 18.2023135 },
    { lat: 48.678686, lng: 18.200494 },
    { lat: 48.6786296, lng: 18.2001636 },
    { lat: 48.678409, lng: 18.199076 },
    { lat: 48.6783228, lng: 18.1986749 },
    { lat: 48.677957, lng: 18.197045 },
    { lat: 48.6777562, lng: 18.1964404 },
    { lat: 48.6773628, lng: 18.1956248 },
    { lat: 48.6769215, lng: 18.1946805 },
    { lat: 48.6768175, lng: 18.1948366 },
    { lat: 48.6767714, lng: 18.1948776 },
    { lat: 48.6766912, lng: 18.1950339 },
    { lat: 48.6766405, lng: 18.195073 },
    { lat: 48.6765494, lng: 18.1951953 },
    { lat: 48.6765342, lng: 18.1952476 },
    { lat: 48.676402, lng: 18.1954564 },
    { lat: 48.6762432, lng: 18.1956215 },
    { lat: 48.6762389, lng: 18.1956828 },
    { lat: 48.6762173, lng: 18.1957181 },
    { lat: 48.6761905, lng: 18.195742 },
    { lat: 48.6760984, lng: 18.1957891 },
    { lat: 48.6760643, lng: 18.1957832 },
    { lat: 48.6760239, lng: 18.1957881 },
    { lat: 48.6759229, lng: 18.1959149 },
    { lat: 48.6758027, lng: 18.196005 },
    { lat: 48.6757477, lng: 18.1960676 },
    { lat: 48.6756832, lng: 18.1962039 },
    { lat: 48.6756862, lng: 18.1962352 },
    { lat: 48.675559, lng: 18.1964155 },
    { lat: 48.6755306, lng: 18.1964331 },
    { lat: 48.6753578, lng: 18.1966268 },
    { lat: 48.675337, lng: 18.1966749 },
    { lat: 48.6752148, lng: 18.1968288 },
    { lat: 48.6751818, lng: 18.1968428 },
    { lat: 48.6751282, lng: 18.1968923 },
    { lat: 48.6750877, lng: 18.1969126 },
    { lat: 48.6749769, lng: 18.1970089 },
    { lat: 48.6749087, lng: 18.1971171 },
    { lat: 48.6747894, lng: 18.1972385 },
    { lat: 48.6747131, lng: 18.1973421 },
    { lat: 48.6745552, lng: 18.1974831 },
    { lat: 48.6744843, lng: 18.1975656 },
    { lat: 48.6744852, lng: 18.1975816 },
    { lat: 48.6743641, lng: 18.1977331 },
    { lat: 48.6742514, lng: 18.1978133 },
    { lat: 48.674181, lng: 18.19792 },
    { lat: 48.6740128, lng: 18.1979981 },
    { lat: 48.6739059, lng: 18.1982017 },
    { lat: 48.6738605, lng: 18.1983137 },
    { lat: 48.6738036, lng: 18.1983433 },
    { lat: 48.6736934, lng: 18.1983107 },
    { lat: 48.6736545, lng: 18.1983983 },
    { lat: 48.6736365, lng: 18.1984228 },
    { lat: 48.6736096, lng: 18.1985427 },
    { lat: 48.6735382, lng: 18.1986335 },
    { lat: 48.673511, lng: 18.1986545 },
    { lat: 48.6733621, lng: 18.1986502 },
    { lat: 48.6733102, lng: 18.1986334 },
    { lat: 48.6731167, lng: 18.1987995 },
    { lat: 48.6730217, lng: 18.1989726 },
    { lat: 48.673017, lng: 18.1990111 },
    { lat: 48.6730557, lng: 18.1991727 },
    { lat: 48.6730366, lng: 18.1992316 },
    { lat: 48.6729905, lng: 18.199293 },
    { lat: 48.6729449, lng: 18.1993062 },
    { lat: 48.67285, lng: 18.1993622 },
    { lat: 48.6727449, lng: 18.1994752 },
    { lat: 48.672649, lng: 18.1995149 },
    { lat: 48.6726165, lng: 18.1995585 },
    { lat: 48.6725405, lng: 18.1997488 },
    { lat: 48.6724361, lng: 18.1998753 },
    { lat: 48.6723878, lng: 18.1999959 },
    { lat: 48.6723763, lng: 18.2000524 },
    { lat: 48.6723623, lng: 18.2000842 },
    { lat: 48.6722789, lng: 18.2001623 },
    { lat: 48.6722321, lng: 18.2001555 },
    { lat: 48.6722192, lng: 18.2001851 },
    { lat: 48.6722277, lng: 18.2003528 },
    { lat: 48.6721194, lng: 18.2006284 },
    { lat: 48.6719812, lng: 18.2006551 },
    { lat: 48.6718548, lng: 18.2007352 },
    { lat: 48.671831, lng: 18.2008522 },
    { lat: 48.6717781, lng: 18.2010091 },
    { lat: 48.6717215, lng: 18.2012047 },
    { lat: 48.6716932, lng: 18.201358 },
    { lat: 48.6715297, lng: 18.2014962 },
    { lat: 48.6714984, lng: 18.2015607 },
    { lat: 48.6714801, lng: 18.2016566 },
    { lat: 48.6714385, lng: 18.2017349 },
    { lat: 48.6713478, lng: 18.2017704 },
    { lat: 48.6712299, lng: 18.2018754 },
    { lat: 48.6711939, lng: 18.2019559 },
    { lat: 48.6711335, lng: 18.2020238 },
    { lat: 48.6710371, lng: 18.2019968 },
    { lat: 48.6709705, lng: 18.2020766 },
    { lat: 48.6709504, lng: 18.2021952 },
    { lat: 48.6709258, lng: 18.2022402 },
    { lat: 48.6708521, lng: 18.202332 },
    { lat: 48.6708143, lng: 18.2023609 },
    { lat: 48.6706228, lng: 18.202346 },
    { lat: 48.6705858, lng: 18.202388 },
    { lat: 48.6705695, lng: 18.2024423 },
    { lat: 48.670566, lng: 18.2024959 },
    { lat: 48.6705344, lng: 18.2026543 },
    { lat: 48.670404, lng: 18.2028199 },
    { lat: 48.670257, lng: 18.2030477 },
    { lat: 48.670199, lng: 18.2031581 },
    { lat: 48.6701703, lng: 18.2031894 },
    { lat: 48.6700168, lng: 18.2032866 },
    { lat: 48.6699875, lng: 18.2032508 },
    { lat: 48.6699236, lng: 18.2032174 },
    { lat: 48.6698494, lng: 18.2032215 },
    { lat: 48.6696764, lng: 18.2032566 },
    { lat: 48.6696559, lng: 18.203269 },
    { lat: 48.6695972, lng: 18.2033309 },
    { lat: 48.6695524, lng: 18.2034354 },
    { lat: 48.6695033, lng: 18.2034811 },
    { lat: 48.6693829, lng: 18.2035679 },
    { lat: 48.6692878, lng: 18.2035832 },
    { lat: 48.6692705, lng: 18.2035941 },
    { lat: 48.6692588, lng: 18.2036259 },
    { lat: 48.6692762, lng: 18.2037932 },
    { lat: 48.6692393, lng: 18.2038577 },
    { lat: 48.6691447, lng: 18.2039194 },
    { lat: 48.6690943, lng: 18.2040424 },
    { lat: 48.6690747, lng: 18.2040749 },
    { lat: 48.6689137, lng: 18.2041575 },
    { lat: 48.6687617, lng: 18.2042241 },
    { lat: 48.6686953, lng: 18.2043646 },
    { lat: 48.6691167, lng: 18.2049983 },
    { lat: 48.6692888, lng: 18.205286 },
    { lat: 48.6695582, lng: 18.2057883 },
    { lat: 48.6699154, lng: 18.2063495 },
    { lat: 48.6703295, lng: 18.2070953 },
    { lat: 48.6706758, lng: 18.2076596 },
    { lat: 48.6706769, lng: 18.2076634 },
    { lat: 48.6707257, lng: 18.2077424 },
    { lat: 48.6710371, lng: 18.2082735 },
    { lat: 48.6703406, lng: 18.2092912 },
    { lat: 48.6705952, lng: 18.2097127 },
    { lat: 48.6706859, lng: 18.2098382 },
    { lat: 48.6708566, lng: 18.2100394 },
    { lat: 48.6710286, lng: 18.2098155 },
    { lat: 48.6712249, lng: 18.2095977 },
    { lat: 48.6713928, lng: 18.2094553 },
    { lat: 48.6714492, lng: 18.2100449 },
    { lat: 48.6714514, lng: 18.2102813 },
    { lat: 48.6714105, lng: 18.2105476 },
    { lat: 48.6713371, lng: 18.210764 },
    { lat: 48.6712435, lng: 18.2112182 },
    { lat: 48.6712296, lng: 18.2113671 },
    { lat: 48.671265, lng: 18.2117681 },
    { lat: 48.6712762, lng: 18.2120986 },
    { lat: 48.6715293, lng: 18.2133639 },
    { lat: 48.6716389, lng: 18.2140129 },
    { lat: 48.6716529, lng: 18.2142199 },
    { lat: 48.6716531, lng: 18.2147129 },
    { lat: 48.6716294, lng: 18.2151441 },
    { lat: 48.671637, lng: 18.2156708 },
    { lat: 48.6716603, lng: 18.2158591 },
    { lat: 48.6717331, lng: 18.2163194 },
    { lat: 48.671543, lng: 18.2167431 },
    { lat: 48.6713706, lng: 18.2171427 },
    { lat: 48.6712964, lng: 18.2173609 },
    { lat: 48.6712059, lng: 18.2176512 },
    { lat: 48.671039, lng: 18.2183017 },
    { lat: 48.6709598, lng: 18.218537 },
    { lat: 48.6707257, lng: 18.2191178 },
    { lat: 48.67078, lng: 18.2192156 },
    { lat: 48.6706762, lng: 18.2193763 },
    { lat: 48.6704168, lng: 18.2198668 },
    { lat: 48.6701161, lng: 18.2204899 },
    { lat: 48.6698163, lng: 18.2210828 },
    { lat: 48.6696032, lng: 18.2214995 },
    { lat: 48.6692375, lng: 18.2221478 },
    { lat: 48.6690922, lng: 18.2223885 },
    { lat: 48.6688292, lng: 18.2227795 },
    { lat: 48.6681455, lng: 18.2237039 },
    { lat: 48.6680474, lng: 18.2234897 },
    { lat: 48.6679361, lng: 18.22334 },
    { lat: 48.6677626, lng: 18.2231664 },
    { lat: 48.6674672, lng: 18.2229565 },
    { lat: 48.6672904, lng: 18.2228629 },
    { lat: 48.6672405, lng: 18.2229565 },
    { lat: 48.6667447, lng: 18.2237781 },
    { lat: 48.6662419, lng: 18.224571 },
    { lat: 48.6657615, lng: 18.2253239 },
    { lat: 48.6654965, lng: 18.2256987 },
    { lat: 48.6652042, lng: 18.2260746 },
    { lat: 48.6649908, lng: 18.2263281 },
    { lat: 48.6640214, lng: 18.2274086 },
    { lat: 48.6644635, lng: 18.2283685 },
    { lat: 48.6648936, lng: 18.2292386 },
    { lat: 48.6646996, lng: 18.2294349 },
    { lat: 48.6643134, lng: 18.2299048 },
    { lat: 48.6640526, lng: 18.2301969 },
    { lat: 48.663718, lng: 18.2305194 },
    { lat: 48.6633417, lng: 18.2308641 },
    { lat: 48.6630888, lng: 18.2310999 },
    { lat: 48.662745, lng: 18.2314386 },
    { lat: 48.662489, lng: 18.2316994 },
    { lat: 48.6622715, lng: 18.2319398 },
    { lat: 48.6616243, lng: 18.2326775 },
    { lat: 48.6615621, lng: 18.2327516 },
    { lat: 48.6617053, lng: 18.2335803 },
    { lat: 48.6614329, lng: 18.2338486 },
    { lat: 48.6612062, lng: 18.2340634 },
    { lat: 48.6608062, lng: 18.2344497 },
    { lat: 48.6604403, lng: 18.2348187 },
    { lat: 48.6600885, lng: 18.2352166 },
    { lat: 48.6598248, lng: 18.2355586 },
    { lat: 48.6595197, lng: 18.2359811 },
    { lat: 48.6593301, lng: 18.2362178 },
    { lat: 48.6590599, lng: 18.2365457 },
    { lat: 48.6586596, lng: 18.2370238 },
    { lat: 48.6581445, lng: 18.2376246 },
    { lat: 48.657833, lng: 18.2379793 },
    { lat: 48.6575804, lng: 18.2382588 },
    { lat: 48.6572311, lng: 18.2386213 },
    { lat: 48.6568284, lng: 18.2389958 },
    { lat: 48.6563462, lng: 18.239366 },
    { lat: 48.6561918, lng: 18.2394657 },
    { lat: 48.6556123, lng: 18.2398089 },
    { lat: 48.6551808, lng: 18.2400789 },
    { lat: 48.6547316, lng: 18.240372 },
    { lat: 48.6544808, lng: 18.2405428 },
    { lat: 48.6542362, lng: 18.2407271 },
    { lat: 48.6548365, lng: 18.2411185 },
    { lat: 48.6550393, lng: 18.2412134 },
    { lat: 48.6551886, lng: 18.24124 },
    { lat: 48.6557078, lng: 18.2412222 },
    { lat: 48.6560808, lng: 18.2412328 },
    { lat: 48.6569591, lng: 18.2412418 },
    { lat: 48.6572325, lng: 18.2412265 },
    { lat: 48.6575702, lng: 18.2411743 },
    { lat: 48.6580585, lng: 18.2410503 },
    { lat: 48.6585856, lng: 18.2408738 },
    { lat: 48.6586904, lng: 18.2408334 },
    { lat: 48.6589036, lng: 18.2407749 },
    { lat: 48.659283, lng: 18.2406619 },
    { lat: 48.6594724, lng: 18.240599 },
    { lat: 48.6598289, lng: 18.2404576 },
    { lat: 48.6599209, lng: 18.2404284 },
    { lat: 48.660166, lng: 18.2403165 },
    { lat: 48.6603179, lng: 18.2402318 },
    { lat: 48.6605986, lng: 18.2400874 },
    { lat: 48.6607322, lng: 18.2399957 },
    { lat: 48.6608693, lng: 18.2399246 },
    { lat: 48.6609951, lng: 18.2398379 },
    { lat: 48.6613799, lng: 18.2396 },
    { lat: 48.661868, lng: 18.2393582 },
    { lat: 48.6620329, lng: 18.239281 },
    { lat: 48.6621356, lng: 18.2393601 },
    { lat: 48.6628528, lng: 18.2391762 },
    { lat: 48.6639347, lng: 18.2390908 },
    { lat: 48.6644643, lng: 18.2390578 },
    { lat: 48.6649162, lng: 18.2389888 },
    { lat: 48.6653679, lng: 18.2389149 },
    { lat: 48.665538, lng: 18.2388713 },
    { lat: 48.6656769, lng: 18.2388161 },
    { lat: 48.6659583, lng: 18.2386666 },
    { lat: 48.666333, lng: 18.238451 },
    { lat: 48.666968, lng: 18.2382675 },
    { lat: 48.6670565, lng: 18.2384744 },
    { lat: 48.6673826, lng: 18.2384711 },
    { lat: 48.6678343, lng: 18.2384404 },
    { lat: 48.6689856, lng: 18.2383288 },
    { lat: 48.6695114, lng: 18.2382691 },
    { lat: 48.6697537, lng: 18.2382803 },
    { lat: 48.6704317, lng: 18.2381619 },
    { lat: 48.6708312, lng: 18.2380662 },
    { lat: 48.6712491, lng: 18.2380135 },
    { lat: 48.6721658, lng: 18.2378603 },
    { lat: 48.6724947, lng: 18.2395678 },
    { lat: 48.6725525, lng: 18.2396717 },
    { lat: 48.672891, lng: 18.2400473 },
    { lat: 48.6730198, lng: 18.240244 },
    { lat: 48.6733687, lng: 18.2414331 },
    { lat: 48.6734099, lng: 18.2415045 },
    { lat: 48.6736961, lng: 18.2413365 },
    { lat: 48.6737887, lng: 18.2412016 },
    { lat: 48.6737314, lng: 18.2409277 },
    { lat: 48.6739226, lng: 18.2408603 },
    { lat: 48.6740206, lng: 18.2412085 },
    { lat: 48.6742274, lng: 18.2411162 },
    { lat: 48.6744137, lng: 18.2411569 },
    { lat: 48.6747851, lng: 18.241458 },
    { lat: 48.6749743, lng: 18.242022 },
    { lat: 48.6756247, lng: 18.2420122 },
    { lat: 48.6757347, lng: 18.2427322 },
    { lat: 48.6760086, lng: 18.2426872 },
    { lat: 48.6761984, lng: 18.2427121 },
    { lat: 48.6766256, lng: 18.2430381 },
    { lat: 48.6769578, lng: 18.2432226 },
    { lat: 48.6771056, lng: 18.2435204 },
    { lat: 48.677379, lng: 18.2437828 },
    { lat: 48.6777268, lng: 18.2436297 },
    { lat: 48.677593, lng: 18.2428852 },
    { lat: 48.6777307, lng: 18.2427501 },
    { lat: 48.6778988, lng: 18.2427093 },
    { lat: 48.678059, lng: 18.2427284 },
    { lat: 48.6783202, lng: 18.2430731 },
    { lat: 48.6784496, lng: 18.2431084 },
    { lat: 48.678705, lng: 18.2433486 },
    { lat: 48.6788703, lng: 18.2435388 },
    { lat: 48.6790118, lng: 18.2436024 },
    { lat: 48.6791821, lng: 18.243426 },
    { lat: 48.679931, lng: 18.2429641 },
    { lat: 48.680057, lng: 18.2429795 },
  ],
  Pochabany: [
    { lat: 48.7110542, lng: 18.1649958 },
    { lat: 48.7106552, lng: 18.1645772 },
    { lat: 48.7104297, lng: 18.1643047 },
    { lat: 48.7100959, lng: 18.1638246 },
    { lat: 48.709654, lng: 18.1632441 },
    { lat: 48.7090403, lng: 18.1623525 },
    { lat: 48.7085151, lng: 18.1628882 },
    { lat: 48.7079704, lng: 18.1619583 },
    { lat: 48.7082013, lng: 18.1616776 },
    { lat: 48.7080413, lng: 18.1612757 },
    { lat: 48.7076165, lng: 18.1602521 },
    { lat: 48.7075711, lng: 18.1601291 },
    { lat: 48.7073586, lng: 18.1598689 },
    { lat: 48.7068807, lng: 18.1589423 },
    { lat: 48.7064814, lng: 18.1592581 },
    { lat: 48.705754, lng: 18.1597933 },
    { lat: 48.7056362, lng: 18.1598835 },
    { lat: 48.7051306, lng: 18.1585748 },
    { lat: 48.7046923, lng: 18.1575766 },
    { lat: 48.7043778, lng: 18.1568411 },
    { lat: 48.7041219, lng: 18.1570418 },
    { lat: 48.7038835, lng: 18.1574041 },
    { lat: 48.7037995, lng: 18.1572602 },
    { lat: 48.7037241, lng: 18.1572037 },
    { lat: 48.7034863, lng: 18.1566828 },
    { lat: 48.7030992, lng: 18.1558454 },
    { lat: 48.7030927, lng: 18.155852 },
    { lat: 48.7024841, lng: 18.1550079 },
    { lat: 48.7022928, lng: 18.1547223 },
    { lat: 48.70168, lng: 18.1537705 },
    { lat: 48.7010776, lng: 18.1527595 },
    { lat: 48.7005579, lng: 18.1519905 },
    { lat: 48.7008507, lng: 18.1514366 },
    { lat: 48.7005173, lng: 18.1509023 },
    { lat: 48.699747, lng: 18.1496806 },
    { lat: 48.6989724, lng: 18.1484678 },
    { lat: 48.698563, lng: 18.1487438 },
    { lat: 48.6978439, lng: 18.1489918 },
    { lat: 48.6975158, lng: 18.1490963 },
    { lat: 48.6975014, lng: 18.1490804 },
    { lat: 48.6963851, lng: 18.1472877 },
    { lat: 48.6957815, lng: 18.1463152 },
    { lat: 48.6949697, lng: 18.1449677 },
    { lat: 48.6949069, lng: 18.1451118 },
    { lat: 48.6948058, lng: 18.1450402 },
    { lat: 48.694495, lng: 18.1446023 },
    { lat: 48.6942628, lng: 18.1441637 },
    { lat: 48.694061, lng: 18.1437378 },
    { lat: 48.6936623, lng: 18.1430126 },
    { lat: 48.6935585, lng: 18.1428385 },
    { lat: 48.6933522, lng: 18.14253 },
    { lat: 48.6934362, lng: 18.1422467 },
    { lat: 48.6930333, lng: 18.1419546 },
    { lat: 48.6926258, lng: 18.1412932 },
    { lat: 48.69239, lng: 18.1408279 },
    { lat: 48.6921924, lng: 18.1404765 },
    { lat: 48.6919568, lng: 18.1400723 },
    { lat: 48.6915177, lng: 18.1391364 },
    { lat: 48.6913113, lng: 18.1387498 },
    { lat: 48.6913294, lng: 18.1387117 },
    { lat: 48.6909465, lng: 18.1373669 },
    { lat: 48.6907958, lng: 18.1367557 },
    { lat: 48.6902815, lng: 18.1355759 },
    { lat: 48.6898181, lng: 18.1349594 },
    { lat: 48.6895972, lng: 18.1344422 },
    { lat: 48.6894572, lng: 18.1336657 },
    { lat: 48.6893057, lng: 18.1334825 },
    { lat: 48.6890573, lng: 18.1332869 },
    { lat: 48.688942, lng: 18.1330722 },
    { lat: 48.6887352, lng: 18.1327528 },
    { lat: 48.6885793, lng: 18.1326188 },
    { lat: 48.6884001, lng: 18.1325254 },
    { lat: 48.6881868, lng: 18.132428 },
    { lat: 48.6880921, lng: 18.1323487 },
    { lat: 48.688049, lng: 18.1322087 },
    { lat: 48.687992, lng: 18.1320673 },
    { lat: 48.6878712, lng: 18.1318516 },
    { lat: 48.6878603, lng: 18.1318184 },
    { lat: 48.6878605, lng: 18.1317826 },
    { lat: 48.6879514, lng: 18.1314466 },
    { lat: 48.6879856, lng: 18.131216 },
    { lat: 48.6879499, lng: 18.1306235 },
    { lat: 48.6878442, lng: 18.1299882 },
    { lat: 48.6877666, lng: 18.129741 },
    { lat: 48.6874616, lng: 18.1286311 },
    { lat: 48.6872012, lng: 18.1279191 },
    { lat: 48.6871889, lng: 18.1278633 },
    { lat: 48.6871048, lng: 18.1272501 },
    { lat: 48.6871294, lng: 18.1261993 },
    { lat: 48.6871757, lng: 18.1258692 },
    { lat: 48.6872223, lng: 18.1252555 },
    { lat: 48.6872188, lng: 18.124963 },
    { lat: 48.6869142, lng: 18.1244235 },
    { lat: 48.6866927, lng: 18.1243186 },
    { lat: 48.6864358, lng: 18.1240938 },
    { lat: 48.686201, lng: 18.1239363 },
    { lat: 48.6856662, lng: 18.1235323 },
    { lat: 48.6855503, lng: 18.1233445 },
    { lat: 48.6854087, lng: 18.1229276 },
    { lat: 48.6853626, lng: 18.1226234 },
    { lat: 48.6853395, lng: 18.1222064 },
    { lat: 48.6853698, lng: 18.1219302 },
    { lat: 48.6853294, lng: 18.1215452 },
    { lat: 48.6852936, lng: 18.121455 },
    { lat: 48.6851923, lng: 18.1212466 },
    { lat: 48.6850833, lng: 18.1210997 },
    { lat: 48.6848775, lng: 18.1209574 },
    { lat: 48.6844779, lng: 18.1207837 },
    { lat: 48.6843648, lng: 18.1207009 },
    { lat: 48.684069, lng: 18.120233 },
    { lat: 48.684058, lng: 18.120276 },
    { lat: 48.683736, lng: 18.121372 },
    { lat: 48.683436, lng: 18.122189 },
    { lat: 48.683395, lng: 18.122349 },
    { lat: 48.683388, lng: 18.122465 },
    { lat: 48.683392, lng: 18.122549 },
    { lat: 48.683126, lng: 18.12287 },
    { lat: 48.682826, lng: 18.123131 },
    { lat: 48.682748, lng: 18.12324 },
    { lat: 48.682508, lng: 18.123508 },
    { lat: 48.682158, lng: 18.123911 },
    { lat: 48.681781, lng: 18.124198 },
    { lat: 48.681741, lng: 18.124259 },
    { lat: 48.681644, lng: 18.124299 },
    { lat: 48.681528, lng: 18.124445 },
    { lat: 48.681528, lng: 18.124544 },
    { lat: 48.681442, lng: 18.124579 },
    { lat: 48.680996, lng: 18.124964 },
    { lat: 48.680861, lng: 18.125048 },
    { lat: 48.680844, lng: 18.125076 },
    { lat: 48.680819, lng: 18.125074 },
    { lat: 48.680791, lng: 18.125021 },
    { lat: 48.680641, lng: 18.125049 },
    { lat: 48.680614, lng: 18.125125 },
    { lat: 48.680625, lng: 18.125165 },
    { lat: 48.680436, lng: 18.125341 },
    { lat: 48.680352, lng: 18.125302 },
    { lat: 48.680359, lng: 18.125354 },
    { lat: 48.680271, lng: 18.125396 },
    { lat: 48.680027, lng: 18.125592 },
    { lat: 48.679998, lng: 18.125551 },
    { lat: 48.679924, lng: 18.125592 },
    { lat: 48.679887, lng: 18.125721 },
    { lat: 48.679761, lng: 18.125812 },
    { lat: 48.67969, lng: 18.12582 },
    { lat: 48.679607, lng: 18.125917 },
    { lat: 48.679537, lng: 18.125898 },
    { lat: 48.679429, lng: 18.125969 },
    { lat: 48.67941, lng: 18.12608 },
    { lat: 48.67932, lng: 18.126151 },
    { lat: 48.679324, lng: 18.12623 },
    { lat: 48.679242, lng: 18.126351 },
    { lat: 48.6793416, lng: 18.1264541 },
    { lat: 48.6800474, lng: 18.1271397 },
    { lat: 48.6806683, lng: 18.1278597 },
    { lat: 48.6807401, lng: 18.1280087 },
    { lat: 48.6812414, lng: 18.1289636 },
    { lat: 48.6814801, lng: 18.1294569 },
    { lat: 48.6820284, lng: 18.1306023 },
    { lat: 48.6821835, lng: 18.1309181 },
    { lat: 48.6822737, lng: 18.1311319 },
    { lat: 48.6824343, lng: 18.1314084 },
    { lat: 48.683044, lng: 18.1324429 },
    { lat: 48.6833478, lng: 18.1332022 },
    { lat: 48.683155, lng: 18.133616 },
    { lat: 48.6829945, lng: 18.1338601 },
    { lat: 48.6831069, lng: 18.1352894 },
    { lat: 48.6831963, lng: 18.1359726 },
    { lat: 48.6832585, lng: 18.1366357 },
    { lat: 48.6834196, lng: 18.1378386 },
    { lat: 48.683701, lng: 18.1399588 },
    { lat: 48.6836922, lng: 18.1401575 },
    { lat: 48.683672, lng: 18.1402376 },
    { lat: 48.6835841, lng: 18.140495 },
    { lat: 48.6832873, lng: 18.1410761 },
    { lat: 48.6833052, lng: 18.1411316 },
    { lat: 48.6837788, lng: 18.1423117 },
    { lat: 48.6840541, lng: 18.1427563 },
    { lat: 48.6841785, lng: 18.1430537 },
    { lat: 48.6842767, lng: 18.1433032 },
    { lat: 48.6846357, lng: 18.1439314 },
    { lat: 48.6847029, lng: 18.1441364 },
    { lat: 48.6849662, lng: 18.1445901 },
    { lat: 48.6850871, lng: 18.1448845 },
    { lat: 48.6851523, lng: 18.145013 },
    { lat: 48.685266, lng: 18.1452826 },
    { lat: 48.6853487, lng: 18.1454431 },
    { lat: 48.6856307, lng: 18.1458693 },
    { lat: 48.6858088, lng: 18.1460779 },
    { lat: 48.6859463, lng: 18.1462143 },
    { lat: 48.6861625, lng: 18.1463786 },
    { lat: 48.6865779, lng: 18.1469068 },
    { lat: 48.6870233, lng: 18.1477154 },
    { lat: 48.6871221, lng: 18.1479234 },
    { lat: 48.6871963, lng: 18.1480154 },
    { lat: 48.687244, lng: 18.1481773 },
    { lat: 48.6874404, lng: 18.1483917 },
    { lat: 48.687723, lng: 18.1488847 },
    { lat: 48.6879388, lng: 18.1493369 },
    { lat: 48.6881237, lng: 18.1497796 },
    { lat: 48.6883612, lng: 18.1503564 },
    { lat: 48.6884628, lng: 18.1505712 },
    { lat: 48.6889154, lng: 18.1514853 },
    { lat: 48.6900687, lng: 18.1539198 },
    { lat: 48.6915455, lng: 18.1566963 },
    { lat: 48.6916999, lng: 18.1572389 },
    { lat: 48.6924772, lng: 18.1583663 },
    { lat: 48.6926915, lng: 18.158713 },
    { lat: 48.6935107, lng: 18.1602918 },
    { lat: 48.6934535, lng: 18.1605687 },
    { lat: 48.6942121, lng: 18.1618434 },
    { lat: 48.6944076, lng: 18.1621976 },
    { lat: 48.6952538, lng: 18.1639891 },
    { lat: 48.695807, lng: 18.1650456 },
    { lat: 48.6958232, lng: 18.1650727 },
    { lat: 48.6959169, lng: 18.1649392 },
    { lat: 48.6961523, lng: 18.1653979 },
    { lat: 48.6962986, lng: 18.1656637 },
    { lat: 48.6968218, lng: 18.1666512 },
    { lat: 48.6968544, lng: 18.166728 },
    { lat: 48.6972337, lng: 18.1676846 },
    { lat: 48.6973174, lng: 18.1679054 },
    { lat: 48.6974812, lng: 18.1680435 },
    { lat: 48.697641, lng: 18.1683478 },
    { lat: 48.6979773, lng: 18.1689347 },
    { lat: 48.6980662, lng: 18.1693013 },
    { lat: 48.6982927, lng: 18.1699962 },
    { lat: 48.6984352, lng: 18.1703339 },
    { lat: 48.6986412, lng: 18.1707575 },
    { lat: 48.699107, lng: 18.1716678 },
    { lat: 48.6992413, lng: 18.1722738 },
    { lat: 48.6993082, lng: 18.1726136 },
    { lat: 48.6994244, lng: 18.1733353 },
    { lat: 48.6995118, lng: 18.1735949 },
    { lat: 48.6998906, lng: 18.1743527 },
    { lat: 48.700065, lng: 18.174579 },
    { lat: 48.7002236, lng: 18.1747835 },
    { lat: 48.7005193, lng: 18.1749614 },
    { lat: 48.7005447, lng: 18.1749909 },
    { lat: 48.7005659, lng: 18.1750063 },
    { lat: 48.700702, lng: 18.1751156 },
    { lat: 48.700844, lng: 18.1752276 },
    { lat: 48.7010193, lng: 18.1754131 },
    { lat: 48.7010291, lng: 18.175424 },
    { lat: 48.7011745, lng: 18.1755781 },
    { lat: 48.7013259, lng: 18.1757603 },
    { lat: 48.7015882, lng: 18.1759619 },
    { lat: 48.7016079, lng: 18.1759715 },
    { lat: 48.7016579, lng: 18.1759967 },
    { lat: 48.7018264, lng: 18.1760809 },
    { lat: 48.7019428, lng: 18.1761806 },
    { lat: 48.7020496, lng: 18.1762901 },
    { lat: 48.7021709, lng: 18.1764371 },
    { lat: 48.7022647, lng: 18.1766162 },
    { lat: 48.7026145, lng: 18.1771053 },
    { lat: 48.7027733, lng: 18.1773319 },
    { lat: 48.7027856, lng: 18.1773521 },
    { lat: 48.7030605, lng: 18.1777924 },
    { lat: 48.7033137, lng: 18.1782871 },
    { lat: 48.7034005, lng: 18.1784229 },
    { lat: 48.7034974, lng: 18.1785212 },
    { lat: 48.7035363, lng: 18.1785472 },
    { lat: 48.7035991, lng: 18.1785828 },
    { lat: 48.7036198, lng: 18.1785887 },
    { lat: 48.7038248, lng: 18.178642 },
    { lat: 48.7040059, lng: 18.1786904 },
    { lat: 48.7041484, lng: 18.1790242 },
    { lat: 48.7042346, lng: 18.1788181 },
    { lat: 48.7042514, lng: 18.1787777 },
    { lat: 48.7042564, lng: 18.1787652 },
    { lat: 48.7043598, lng: 18.1788974 },
    { lat: 48.7044196, lng: 18.1789752 },
    { lat: 48.7044692, lng: 18.1790312 },
    { lat: 48.7044832, lng: 18.1790412 },
    { lat: 48.7045553, lng: 18.1791135 },
    { lat: 48.7045561, lng: 18.179112 },
    { lat: 48.7045731, lng: 18.1790897 },
    { lat: 48.7046292, lng: 18.1789312 },
    { lat: 48.7046478, lng: 18.1788812 },
    { lat: 48.7046826, lng: 18.1788202 },
    { lat: 48.7046921, lng: 18.1788093 },
    { lat: 48.7047727, lng: 18.1787179 },
    { lat: 48.7048205, lng: 18.1786645 },
    { lat: 48.7048659, lng: 18.1786128 },
    { lat: 48.7048858, lng: 18.1785841 },
    { lat: 48.7049138, lng: 18.1785439 },
    { lat: 48.7049606, lng: 18.1784749 },
    { lat: 48.7050147, lng: 18.1782984 },
    { lat: 48.7051001, lng: 18.1780156 },
    { lat: 48.7051473, lng: 18.1778583 },
    { lat: 48.7051984, lng: 18.1777447 },
    { lat: 48.7052263, lng: 18.1776818 },
    { lat: 48.7052443, lng: 18.1776422 },
    { lat: 48.7052566, lng: 18.177622 },
    { lat: 48.7053239, lng: 18.1775131 },
    { lat: 48.7053817, lng: 18.1774212 },
    { lat: 48.7054383, lng: 18.1773291 },
    { lat: 48.7054755, lng: 18.1772691 },
    { lat: 48.7054951, lng: 18.1772358 },
    { lat: 48.7055547, lng: 18.1771336 },
    { lat: 48.7056875, lng: 18.1769139 },
    { lat: 48.7059328, lng: 18.1765111 },
    { lat: 48.7060353, lng: 18.1762704 },
    { lat: 48.7062918, lng: 18.1754791 },
    { lat: 48.7063656, lng: 18.1752704 },
    { lat: 48.7064726, lng: 18.1751287 },
    { lat: 48.706659, lng: 18.1746837 },
    { lat: 48.7068375, lng: 18.1743554 },
    { lat: 48.7069564, lng: 18.1741678 },
    { lat: 48.7071052, lng: 18.1739671 },
    { lat: 48.707248, lng: 18.1737249 },
    { lat: 48.7075515, lng: 18.1733341 },
    { lat: 48.7076177, lng: 18.1732278 },
    { lat: 48.7077156, lng: 18.1731091 },
    { lat: 48.7081815, lng: 18.1728533 },
    { lat: 48.7082356, lng: 18.1728312 },
    { lat: 48.7085812, lng: 18.1724851 },
    { lat: 48.7086912, lng: 18.1722769 },
    { lat: 48.7089596, lng: 18.1718478 },
    { lat: 48.7089829, lng: 18.1718011 },
    { lat: 48.709094, lng: 18.1714367 },
    { lat: 48.7092274, lng: 18.1708767 },
    { lat: 48.7093363, lng: 18.1706694 },
    { lat: 48.7094303, lng: 18.1704015 },
    { lat: 48.7095481, lng: 18.1699815 },
    { lat: 48.7095969, lng: 18.1697121 },
    { lat: 48.7096146, lng: 18.1696665 },
    { lat: 48.7096303, lng: 18.1696276 },
    { lat: 48.7096098, lng: 18.1696046 },
    { lat: 48.7098682, lng: 18.1685672 },
    { lat: 48.7100308, lng: 18.1679858 },
    { lat: 48.7102008, lng: 18.1674903 },
    { lat: 48.7103219, lng: 18.1670077 },
    { lat: 48.7105791, lng: 18.1664017 },
    { lat: 48.7108079, lng: 18.1656965 },
    { lat: 48.7108781, lng: 18.1654471 },
    { lat: 48.7110542, lng: 18.1649958 },
  ],
  Podlužany: [
    { lat: 48.7983025, lng: 18.240162 },
    { lat: 48.7981974, lng: 18.2404585 },
    { lat: 48.7978498, lng: 18.2402176 },
    { lat: 48.7977743, lng: 18.2402418 },
    { lat: 48.7975892, lng: 18.2404367 },
    { lat: 48.797427, lng: 18.2401623 },
    { lat: 48.7971926, lng: 18.2400326 },
    { lat: 48.7970202, lng: 18.2398379 },
    { lat: 48.7968514, lng: 18.2398836 },
    { lat: 48.7966833, lng: 18.2397055 },
    { lat: 48.7966633, lng: 18.2395339 },
    { lat: 48.7962084, lng: 18.2392144 },
    { lat: 48.7959028, lng: 18.2392175 },
    { lat: 48.7958349, lng: 18.2388974 },
    { lat: 48.7955379, lng: 18.2388357 },
    { lat: 48.7953811, lng: 18.2391529 },
    { lat: 48.7951694, lng: 18.2388446 },
    { lat: 48.794921, lng: 18.2388223 },
    { lat: 48.7949215, lng: 18.238416 },
    { lat: 48.7946779, lng: 18.2383155 },
    { lat: 48.7944393, lng: 18.2383492 },
    { lat: 48.7944087, lng: 18.2380121 },
    { lat: 48.7941018, lng: 18.2379911 },
    { lat: 48.7939417, lng: 18.2384082 },
    { lat: 48.7939903, lng: 18.2386849 },
    { lat: 48.793887, lng: 18.2390695 },
    { lat: 48.7938134, lng: 18.2391275 },
    { lat: 48.7936833, lng: 18.2390013 },
    { lat: 48.7934149, lng: 18.239001 },
    { lat: 48.7933343, lng: 18.2388351 },
    { lat: 48.7933432, lng: 18.2387359 },
    { lat: 48.7933173, lng: 18.2376113 },
    { lat: 48.7932909, lng: 18.2365723 },
    { lat: 48.7932172, lng: 18.2358948 },
    { lat: 48.7931715, lng: 18.2357283 },
    { lat: 48.7930967, lng: 18.2356035 },
    { lat: 48.79231, lng: 18.235383 },
    { lat: 48.7921743, lng: 18.2353574 },
    { lat: 48.7919553, lng: 18.2355153 },
    { lat: 48.7916183, lng: 18.2357204 },
    { lat: 48.791587, lng: 18.2359793 },
    { lat: 48.7915073, lng: 18.2360463 },
    { lat: 48.7911517, lng: 18.2361028 },
    { lat: 48.7904473, lng: 18.2363345 },
    { lat: 48.7901962, lng: 18.2363638 },
    { lat: 48.7901144, lng: 18.2363555 },
    { lat: 48.789943, lng: 18.2364342 },
    { lat: 48.7896636, lng: 18.2367011 },
    { lat: 48.7895839, lng: 18.2368254 },
    { lat: 48.7895156, lng: 18.2371492 },
    { lat: 48.7894606, lng: 18.2372949 },
    { lat: 48.7892949, lng: 18.2375531 },
    { lat: 48.7892163, lng: 18.2375802 },
    { lat: 48.7889548, lng: 18.2375049 },
    { lat: 48.7887235, lng: 18.2375666 },
    { lat: 48.7885071, lng: 18.2374325 },
    { lat: 48.7883163, lng: 18.2374714 },
    { lat: 48.7879869, lng: 18.2373921 },
    { lat: 48.7876275, lng: 18.2373629 },
    { lat: 48.7873464, lng: 18.2373585 },
    { lat: 48.7872401, lng: 18.2367026 },
    { lat: 48.7871838, lng: 18.2363868 },
    { lat: 48.7870731, lng: 18.2360506 },
    { lat: 48.787006, lng: 18.2355477 },
    { lat: 48.7869889, lng: 18.23517 },
    { lat: 48.7863148, lng: 18.234963 },
    { lat: 48.7861416, lng: 18.2348561 },
    { lat: 48.7858434, lng: 18.2345538 },
    { lat: 48.7856174, lng: 18.2342105 },
    { lat: 48.7856067, lng: 18.2337464 },
    { lat: 48.7854051, lng: 18.2336575 },
    { lat: 48.7851923, lng: 18.233505 },
    { lat: 48.785055, lng: 18.2334722 },
    { lat: 48.7847477, lng: 18.2333282 },
    { lat: 48.7846616, lng: 18.2332614 },
    { lat: 48.7843399, lng: 18.233082 },
    { lat: 48.7837868, lng: 18.2328034 },
    { lat: 48.7834115, lng: 18.2327903 },
    { lat: 48.782932, lng: 18.2327536 },
    { lat: 48.7826631, lng: 18.2327276 },
    { lat: 48.7824055, lng: 18.23282 },
    { lat: 48.7821536, lng: 18.2328515 },
    { lat: 48.7819339, lng: 18.232818 },
    { lat: 48.7812825, lng: 18.2324017 },
    { lat: 48.7810046, lng: 18.2321969 },
    { lat: 48.7807658, lng: 18.232007 },
    { lat: 48.7806664, lng: 18.2318279 },
    { lat: 48.7806341, lng: 18.2315978 },
    { lat: 48.7804564, lng: 18.2312314 },
    { lat: 48.7800941, lng: 18.2308588 },
    { lat: 48.7800972, lng: 18.2307135 },
    { lat: 48.7797828, lng: 18.2306409 },
    { lat: 48.7797553, lng: 18.2310066 },
    { lat: 48.7792569, lng: 18.2309173 },
    { lat: 48.7789484, lng: 18.2308307 },
    { lat: 48.7784937, lng: 18.2307321 },
    { lat: 48.7781909, lng: 18.230469 },
    { lat: 48.7773209, lng: 18.2307028 },
    { lat: 48.777002, lng: 18.2308104 },
    { lat: 48.7766612, lng: 18.2308882 },
    { lat: 48.7763765, lng: 18.2310407 },
    { lat: 48.7761272, lng: 18.2313581 },
    { lat: 48.7758231, lng: 18.2316626 },
    { lat: 48.7757461, lng: 18.2316981 },
    { lat: 48.7755704, lng: 18.2316431 },
    { lat: 48.7754325, lng: 18.2315402 },
    { lat: 48.7752069, lng: 18.2314646 },
    { lat: 48.7748909, lng: 18.2315417 },
    { lat: 48.7747198, lng: 18.231489 },
    { lat: 48.7746061, lng: 18.2315347 },
    { lat: 48.7744265, lng: 18.2315278 },
    { lat: 48.7743069, lng: 18.2314915 },
    { lat: 48.7741244, lng: 18.2314557 },
    { lat: 48.7740458, lng: 18.2315035 },
    { lat: 48.7738954, lng: 18.2314574 },
    { lat: 48.7737796, lng: 18.2313051 },
    { lat: 48.7737155, lng: 18.2312885 },
    { lat: 48.7735545, lng: 18.2313754 },
    { lat: 48.7734835, lng: 18.231301 },
    { lat: 48.7732242, lng: 18.2313424 },
    { lat: 48.7731242, lng: 18.231426 },
    { lat: 48.7729766, lng: 18.2313348 },
    { lat: 48.7728706, lng: 18.2313529 },
    { lat: 48.7727528, lng: 18.231129 },
    { lat: 48.7725074, lng: 18.2310603 },
    { lat: 48.7724067, lng: 18.2310774 },
    { lat: 48.7722638, lng: 18.2310042 },
    { lat: 48.7721179, lng: 18.2306816 },
    { lat: 48.7719247, lng: 18.2304801 },
    { lat: 48.7717962, lng: 18.2305606 },
    { lat: 48.7715688, lng: 18.2304695 },
    { lat: 48.7714954, lng: 18.230312 },
    { lat: 48.7713302, lng: 18.2300686 },
    { lat: 48.7711883, lng: 18.2299358 },
    { lat: 48.771031, lng: 18.2298488 },
    { lat: 48.7708289, lng: 18.2300219 },
    { lat: 48.7707209, lng: 18.2300893 },
    { lat: 48.7705101, lng: 18.2301126 },
    { lat: 48.7703326, lng: 18.2299488 },
    { lat: 48.7694721, lng: 18.2297205 },
    { lat: 48.769378, lng: 18.2297325 },
    { lat: 48.7692754, lng: 18.2298532 },
    { lat: 48.7690818, lng: 18.2302127 },
    { lat: 48.769064, lng: 18.2305559 },
    { lat: 48.7690247, lng: 18.2306369 },
    { lat: 48.7689751, lng: 18.2306752 },
    { lat: 48.7688882, lng: 18.2306766 },
    { lat: 48.7688292, lng: 18.2307494 },
    { lat: 48.7688326, lng: 18.2308659 },
    { lat: 48.7687827, lng: 18.2311017 },
    { lat: 48.7685827, lng: 18.2310152 },
    { lat: 48.7685821, lng: 18.2314016 },
    { lat: 48.7685027, lng: 18.2313948 },
    { lat: 48.7683298, lng: 18.231191 },
    { lat: 48.7682801, lng: 18.2312487 },
    { lat: 48.7682767, lng: 18.2313507 },
    { lat: 48.7681844, lng: 18.2314322 },
    { lat: 48.7680845, lng: 18.2314002 },
    { lat: 48.7680369, lng: 18.2314776 },
    { lat: 48.7679846, lng: 18.2317448 },
    { lat: 48.7679337, lng: 18.2318023 },
    { lat: 48.7677611, lng: 18.2317247 },
    { lat: 48.7675298, lng: 18.2316656 },
    { lat: 48.7670047, lng: 18.2313426 },
    { lat: 48.7665384, lng: 18.2310993 },
    { lat: 48.766644, lng: 18.2324543 },
    { lat: 48.766666, lng: 18.232719 },
    { lat: 48.7665904, lng: 18.2323844 },
    { lat: 48.7664945, lng: 18.2322868 },
    { lat: 48.7662403, lng: 18.2322821 },
    { lat: 48.7661782, lng: 18.2322216 },
    { lat: 48.7659287, lng: 18.231526 },
    { lat: 48.7656888, lng: 18.2315935 },
    { lat: 48.7655758, lng: 18.2315543 },
    { lat: 48.7653936, lng: 18.2315048 },
    { lat: 48.7652043, lng: 18.2312921 },
    { lat: 48.7647487, lng: 18.2312977 },
    { lat: 48.7646249, lng: 18.2312238 },
    { lat: 48.7645152, lng: 18.2310797 },
    { lat: 48.764421, lng: 18.2310488 },
    { lat: 48.7642308, lng: 18.2307832 },
    { lat: 48.7640419, lng: 18.2306971 },
    { lat: 48.7639382, lng: 18.2307975 },
    { lat: 48.7638135, lng: 18.2310807 },
    { lat: 48.763562, lng: 18.2310245 },
    { lat: 48.7634917, lng: 18.23094 },
    { lat: 48.7632934, lng: 18.2308261 },
    { lat: 48.7631621, lng: 18.2308998 },
    { lat: 48.7630873, lng: 18.230993 },
    { lat: 48.7627881, lng: 18.2309509 },
    { lat: 48.7626833, lng: 18.2310118 },
    { lat: 48.7625577, lng: 18.2308656 },
    { lat: 48.7622573, lng: 18.2308222 },
    { lat: 48.7621765, lng: 18.2306908 },
    { lat: 48.7621309, lng: 18.2306832 },
    { lat: 48.7619386, lng: 18.2308929 },
    { lat: 48.7617152, lng: 18.2309311 },
    { lat: 48.761441, lng: 18.2311894 },
    { lat: 48.7613542, lng: 18.2311711 },
    { lat: 48.7610119, lng: 18.2311823 },
    { lat: 48.760722, lng: 18.2306727 },
    { lat: 48.7606875, lng: 18.2306411 },
    { lat: 48.7606163, lng: 18.23064 },
    { lat: 48.7604578, lng: 18.2309068 },
    { lat: 48.7604597, lng: 18.2311344 },
    { lat: 48.7604097, lng: 18.231211 },
    { lat: 48.7602874, lng: 18.231222 },
    { lat: 48.760209, lng: 18.2311704 },
    { lat: 48.7600796, lng: 18.2311398 },
    { lat: 48.7599873, lng: 18.2312799 },
    { lat: 48.7597794, lng: 18.2314941 },
    { lat: 48.7595723, lng: 18.2315799 },
    { lat: 48.7594416, lng: 18.2314654 },
    { lat: 48.7591819, lng: 18.2313863 },
    { lat: 48.759045, lng: 18.2314357 },
    { lat: 48.7587872, lng: 18.231409 },
    { lat: 48.7585975, lng: 18.2314671 },
    { lat: 48.7584097, lng: 18.2316551 },
    { lat: 48.7583428, lng: 18.2318838 },
    { lat: 48.7582139, lng: 18.2318785 },
    { lat: 48.7579902, lng: 18.2317161 },
    { lat: 48.757951, lng: 18.2317219 },
    { lat: 48.7579316, lng: 18.2317788 },
    { lat: 48.7579502, lng: 18.2319262 },
    { lat: 48.7579014, lng: 18.2320377 },
    { lat: 48.7576851, lng: 18.2320453 },
    { lat: 48.7575441, lng: 18.2320079 },
    { lat: 48.7574331, lng: 18.2319982 },
    { lat: 48.7573934, lng: 18.2319765 },
    { lat: 48.7572934, lng: 18.2318033 },
    { lat: 48.7572125, lng: 18.2316916 },
    { lat: 48.757136, lng: 18.2316562 },
    { lat: 48.757105, lng: 18.2316688 },
    { lat: 48.7570611, lng: 18.2317686 },
    { lat: 48.7568652, lng: 18.2318529 },
    { lat: 48.7567571, lng: 18.2318774 },
    { lat: 48.7566883, lng: 18.2322122 },
    { lat: 48.7562238, lng: 18.2322229 },
    { lat: 48.7559309, lng: 18.2319933 },
    { lat: 48.7558425, lng: 18.2321261 },
    { lat: 48.755706, lng: 18.2322642 },
    { lat: 48.7555524, lng: 18.2323772 },
    { lat: 48.7552973, lng: 18.232279 },
    { lat: 48.7552407, lng: 18.2322946 },
    { lat: 48.7552066, lng: 18.2327067 },
    { lat: 48.7551267, lng: 18.2328678 },
    { lat: 48.7550435, lng: 18.2329509 },
    { lat: 48.7548056, lng: 18.2328139 },
    { lat: 48.7547111, lng: 18.2327036 },
    { lat: 48.7545802, lng: 18.232665 },
    { lat: 48.7544861, lng: 18.2327924 },
    { lat: 48.7544933, lng: 18.2328819 },
    { lat: 48.7546161, lng: 18.2330937 },
    { lat: 48.7546146, lng: 18.2331665 },
    { lat: 48.7545481, lng: 18.2334226 },
    { lat: 48.7538885, lng: 18.2339441 },
    { lat: 48.7538626, lng: 18.2339474 },
    { lat: 48.7537486, lng: 18.2340625 },
    { lat: 48.7535283, lng: 18.2340802 },
    { lat: 48.7534121, lng: 18.2341776 },
    { lat: 48.7531748, lng: 18.2345062 },
    { lat: 48.7531406, lng: 18.2345028 },
    { lat: 48.7530732, lng: 18.2343865 },
    { lat: 48.7530431, lng: 18.2343951 },
    { lat: 48.7525642, lng: 18.2343272 },
    { lat: 48.7523265, lng: 18.2342937 },
    { lat: 48.7515236, lng: 18.2341062 },
    { lat: 48.750041, lng: 18.2338901 },
    { lat: 48.7491177, lng: 18.2338073 },
    { lat: 48.7483697, lng: 18.2334547 },
    { lat: 48.7482652, lng: 18.2334604 },
    { lat: 48.7481898, lng: 18.2334645 },
    { lat: 48.747633, lng: 18.2334951 },
    { lat: 48.7475425, lng: 18.2339651 },
    { lat: 48.7474835, lng: 18.2341763 },
    { lat: 48.747479, lng: 18.234215 },
    { lat: 48.7474196, lng: 18.2346176 },
    { lat: 48.7473891, lng: 18.2349125 },
    { lat: 48.7473407, lng: 18.2353876 },
    { lat: 48.7473394, lng: 18.235404 },
    { lat: 48.7473413, lng: 18.2356163 },
    { lat: 48.7473555, lng: 18.235785 },
    { lat: 48.7473621, lng: 18.2358617 },
    { lat: 48.7473709, lng: 18.2359753 },
    { lat: 48.7473855, lng: 18.2361522 },
    { lat: 48.747393, lng: 18.2362442 },
    { lat: 48.7474226, lng: 18.2366038 },
    { lat: 48.747757, lng: 18.2365917 },
    { lat: 48.7478793, lng: 18.2379022 },
    { lat: 48.7478511, lng: 18.2379209 },
    { lat: 48.7478229, lng: 18.2379407 },
    { lat: 48.7477512, lng: 18.2379894 },
    { lat: 48.7476795, lng: 18.2380384 },
    { lat: 48.7476719, lng: 18.2380437 },
    { lat: 48.7475767, lng: 18.2380765 },
    { lat: 48.7474671, lng: 18.2381139 },
    { lat: 48.7473886, lng: 18.2381412 },
    { lat: 48.7473653, lng: 18.2381494 },
    { lat: 48.7473344, lng: 18.2381599 },
    { lat: 48.7470162, lng: 18.2382974 },
    { lat: 48.7468247, lng: 18.2383622 },
    { lat: 48.7463127, lng: 18.238526 },
    { lat: 48.7464771, lng: 18.2393894 },
    { lat: 48.7467153, lng: 18.2403987 },
    { lat: 48.7466046, lng: 18.2404371 },
    { lat: 48.7464288, lng: 18.240498 },
    { lat: 48.7463314, lng: 18.2405316 },
    { lat: 48.7462296, lng: 18.2405665 },
    { lat: 48.746186, lng: 18.2406124 },
    { lat: 48.7461626, lng: 18.2406377 },
    { lat: 48.7461477, lng: 18.2406537 },
    { lat: 48.7461317, lng: 18.2406698 },
    { lat: 48.7461157, lng: 18.2406869 },
    { lat: 48.7460657, lng: 18.2407399 },
    { lat: 48.7459177, lng: 18.2408958 },
    { lat: 48.7459039, lng: 18.2409103 },
    { lat: 48.745806, lng: 18.2410141 },
    { lat: 48.745632, lng: 18.2408119 },
    { lat: 48.7455485, lng: 18.2408711 },
    { lat: 48.7454876, lng: 18.2409126 },
    { lat: 48.7454346, lng: 18.2409503 },
    { lat: 48.7453296, lng: 18.2410895 },
    { lat: 48.7451391, lng: 18.2415272 },
    { lat: 48.7450935, lng: 18.2418334 },
    { lat: 48.7447847, lng: 18.2420781 },
    { lat: 48.7447411, lng: 18.2421235 },
    { lat: 48.7447261, lng: 18.2421383 },
    { lat: 48.7447166, lng: 18.2421481 },
    { lat: 48.744758, lng: 18.2422433 },
    { lat: 48.7448659, lng: 18.2425703 },
    { lat: 48.7451216, lng: 18.2433724 },
    { lat: 48.7453182, lng: 18.2440693 },
    { lat: 48.7454322, lng: 18.2443837 },
    { lat: 48.7458973, lng: 18.2455361 },
    { lat: 48.7463145, lng: 18.2466154 },
    { lat: 48.7463858, lng: 18.2467784 },
    { lat: 48.7466457, lng: 18.2475371 },
    { lat: 48.7468039, lng: 18.2479763 },
    { lat: 48.7471532, lng: 18.2488778 },
    { lat: 48.7474226, lng: 18.249542 },
    { lat: 48.7475772, lng: 18.2499981 },
    { lat: 48.7476283, lng: 18.2501845 },
    { lat: 48.7477415, lng: 18.2506853 },
    { lat: 48.7478898, lng: 18.2513656 },
    { lat: 48.7482062, lng: 18.2526812 },
    { lat: 48.7483037, lng: 18.2531416 },
    { lat: 48.7484244, lng: 18.2536174 },
    { lat: 48.7484902, lng: 18.2537211 },
    { lat: 48.7485702, lng: 18.25374 },
    { lat: 48.7487326, lng: 18.2536766 },
    { lat: 48.7490458, lng: 18.2535296 },
    { lat: 48.7493788, lng: 18.2534151 },
    { lat: 48.7496343, lng: 18.2533435 },
    { lat: 48.7498897, lng: 18.2532952 },
    { lat: 48.7500487, lng: 18.2532916 },
    { lat: 48.7502585, lng: 18.2533111 },
    { lat: 48.7505061, lng: 18.2533571 },
    { lat: 48.75077, lng: 18.2534364 },
    { lat: 48.7510813, lng: 18.2535911 },
    { lat: 48.7512998, lng: 18.2537417 },
    { lat: 48.7513306, lng: 18.2536891 },
    { lat: 48.751241, lng: 18.2529466 },
    { lat: 48.7511636, lng: 18.2525012 },
    { lat: 48.7510537, lng: 18.2519585 },
    { lat: 48.7515012, lng: 18.2517335 },
    { lat: 48.7520946, lng: 18.2515127 },
    { lat: 48.7524378, lng: 18.2514354 },
    { lat: 48.752637, lng: 18.2514305 },
    { lat: 48.7532445, lng: 18.2512976 },
    { lat: 48.753456, lng: 18.252454 },
    { lat: 48.7536054, lng: 18.2532534 },
    { lat: 48.7537472, lng: 18.2540405 },
    { lat: 48.753748, lng: 18.254054 },
    { lat: 48.7542498, lng: 18.2540468 },
    { lat: 48.7549287, lng: 18.2541228 },
    { lat: 48.7551798, lng: 18.2541309 },
    { lat: 48.7557965, lng: 18.2541448 },
    { lat: 48.7565757, lng: 18.2542342 },
    { lat: 48.7567724, lng: 18.2542794 },
    { lat: 48.7572139, lng: 18.2543631 },
    { lat: 48.7577637, lng: 18.2543475 },
    { lat: 48.7578798, lng: 18.2543678 },
    { lat: 48.7581923, lng: 18.2543878 },
    { lat: 48.7585538, lng: 18.2543911 },
    { lat: 48.7589576, lng: 18.25437 },
    { lat: 48.7589623, lng: 18.2543951 },
    { lat: 48.7593135, lng: 18.254359 },
    { lat: 48.7597126, lng: 18.2543749 },
    { lat: 48.7600872, lng: 18.2544351 },
    { lat: 48.7608644, lng: 18.2543684 },
    { lat: 48.761497, lng: 18.2543625 },
    { lat: 48.7622794, lng: 18.25445 },
    { lat: 48.76228, lng: 18.2544231 },
    { lat: 48.7624986, lng: 18.2543746 },
    { lat: 48.7628891, lng: 18.2543401 },
    { lat: 48.7632394, lng: 18.254268 },
    { lat: 48.7637233, lng: 18.2541877 },
    { lat: 48.7645154, lng: 18.2540458 },
    { lat: 48.7649865, lng: 18.2539411 },
    { lat: 48.7654221, lng: 18.2538796 },
    { lat: 48.7661726, lng: 18.2538422 },
    { lat: 48.7664591, lng: 18.2538052 },
    { lat: 48.766699, lng: 18.253736 },
    { lat: 48.7673895, lng: 18.2534557 },
    { lat: 48.7673939, lng: 18.2534755 },
    { lat: 48.7676503, lng: 18.2533404 },
    { lat: 48.7680407, lng: 18.2532241 },
    { lat: 48.7685174, lng: 18.2529977 },
    { lat: 48.7685526, lng: 18.2531577 },
    { lat: 48.7686078, lng: 18.2540271 },
    { lat: 48.7685968, lng: 18.255655 },
    { lat: 48.7686263, lng: 18.2559376 },
    { lat: 48.7687881, lng: 18.2575286 },
    { lat: 48.7687816, lng: 18.2575928 },
    { lat: 48.7689709, lng: 18.2593104 },
    { lat: 48.769337, lng: 18.2592194 },
    { lat: 48.7695284, lng: 18.2599692 },
    { lat: 48.7695911, lng: 18.2598999 },
    { lat: 48.7696328, lng: 18.259917 },
    { lat: 48.769686, lng: 18.2599802 },
    { lat: 48.7697332, lng: 18.2599568 },
    { lat: 48.7697571, lng: 18.2598632 },
    { lat: 48.7698735, lng: 18.2598055 },
    { lat: 48.7699286, lng: 18.2598802 },
    { lat: 48.7699388, lng: 18.2598238 },
    { lat: 48.7700684, lng: 18.2597819 },
    { lat: 48.7700852, lng: 18.2597407 },
    { lat: 48.7700895, lng: 18.2596378 },
    { lat: 48.7701844, lng: 18.2595969 },
    { lat: 48.7702927, lng: 18.2595194 },
    { lat: 48.7703655, lng: 18.2595307 },
    { lat: 48.7705059, lng: 18.2594602 },
    { lat: 48.7705061, lng: 18.259463 },
    { lat: 48.7705283, lng: 18.2595324 },
    { lat: 48.770596, lng: 18.2595343 },
    { lat: 48.7706422, lng: 18.2594535 },
    { lat: 48.7707025, lng: 18.2594235 },
    { lat: 48.7707055, lng: 18.259319 },
    { lat: 48.7707902, lng: 18.25922 },
    { lat: 48.7708798, lng: 18.2592501 },
    { lat: 48.770922, lng: 18.2591359 },
    { lat: 48.7710226, lng: 18.2591002 },
    { lat: 48.771073, lng: 18.2590554 },
    { lat: 48.7711738, lng: 18.2591013 },
    { lat: 48.7713089, lng: 18.2589957 },
    { lat: 48.7714181, lng: 18.2589528 },
    { lat: 48.7714969, lng: 18.2587916 },
    { lat: 48.7716639, lng: 18.2586321 },
    { lat: 48.7717938, lng: 18.2586173 },
    { lat: 48.7718477, lng: 18.2586504 },
    { lat: 48.7721406, lng: 18.2586609 },
    { lat: 48.7722202, lng: 18.2584144 },
    { lat: 48.7723505, lng: 18.2583448 },
    { lat: 48.772414, lng: 18.2584503 },
    { lat: 48.7725804, lng: 18.2583424 },
    { lat: 48.7726486, lng: 18.2582702 },
    { lat: 48.7728066, lng: 18.25827 },
    { lat: 48.7728861, lng: 18.2582589 },
    { lat: 48.7730269, lng: 18.2583357 },
    { lat: 48.7730183, lng: 18.2585579 },
    { lat: 48.7732334, lng: 18.2585943 },
    { lat: 48.7733052, lng: 18.2584279 },
    { lat: 48.7734656, lng: 18.2584703 },
    { lat: 48.7735899, lng: 18.2585321 },
    { lat: 48.7736712, lng: 18.2584943 },
    { lat: 48.7737111, lng: 18.2586801 },
    { lat: 48.7737763, lng: 18.2587163 },
    { lat: 48.7738654, lng: 18.2586943 },
    { lat: 48.7739861, lng: 18.2588338 },
    { lat: 48.7741489, lng: 18.2587779 },
    { lat: 48.7742514, lng: 18.258854 },
    { lat: 48.7742908, lng: 18.2589311 },
    { lat: 48.7743609, lng: 18.2589335 },
    { lat: 48.774417, lng: 18.2590463 },
    { lat: 48.7746051, lng: 18.2589406 },
    { lat: 48.7747646, lng: 18.2590894 },
    { lat: 48.7747902, lng: 18.2592238 },
    { lat: 48.7749825, lng: 18.2594692 },
    { lat: 48.7751922, lng: 18.259445 },
    { lat: 48.7753007, lng: 18.2594892 },
    { lat: 48.7753732, lng: 18.2594125 },
    { lat: 48.7756394, lng: 18.2598282 },
    { lat: 48.7757634, lng: 18.2600825 },
    { lat: 48.7759731, lng: 18.2603195 },
    { lat: 48.7760341, lng: 18.2604186 },
    { lat: 48.7760383, lng: 18.2605518 },
    { lat: 48.7761537, lng: 18.2606387 },
    { lat: 48.7762517, lng: 18.2608309 },
    { lat: 48.7763396, lng: 18.2608724 },
    { lat: 48.7764144, lng: 18.2610351 },
    { lat: 48.7764058, lng: 18.261121 },
    { lat: 48.7764694, lng: 18.2612662 },
    { lat: 48.7765788, lng: 18.2612287 },
    { lat: 48.7766842, lng: 18.2612775 },
    { lat: 48.7766798, lng: 18.2613579 },
    { lat: 48.7767292, lng: 18.2614323 },
    { lat: 48.7768569, lng: 18.2613363 },
    { lat: 48.7769083, lng: 18.2614465 },
    { lat: 48.7770921, lng: 18.261565 },
    { lat: 48.7770878, lng: 18.2616467 },
    { lat: 48.7771671, lng: 18.2616522 },
    { lat: 48.7772816, lng: 18.2618404 },
    { lat: 48.7773556, lng: 18.2618118 },
    { lat: 48.7773715, lng: 18.261892 },
    { lat: 48.7774555, lng: 18.2618793 },
    { lat: 48.7775401, lng: 18.2619212 },
    { lat: 48.7775799, lng: 18.2617683 },
    { lat: 48.7776363, lng: 18.2617456 },
    { lat: 48.7776962, lng: 18.2616898 },
    { lat: 48.7778059, lng: 18.2616727 },
    { lat: 48.7778235, lng: 18.2617662 },
    { lat: 48.7779022, lng: 18.2617975 },
    { lat: 48.7779928, lng: 18.2617868 },
    { lat: 48.7781327, lng: 18.2618272 },
    { lat: 48.7782731, lng: 18.2619348 },
    { lat: 48.7783001, lng: 18.2620505 },
    { lat: 48.7783922, lng: 18.2620445 },
    { lat: 48.7784392, lng: 18.262135 },
    { lat: 48.7785481, lng: 18.262246 },
    { lat: 48.7786013, lng: 18.2622456 },
    { lat: 48.7787048, lng: 18.2623036 },
    { lat: 48.7787138, lng: 18.2623193 },
    { lat: 48.7787449, lng: 18.2622934 },
    { lat: 48.7788934, lng: 18.2625842 },
    { lat: 48.7792401, lng: 18.2632213 },
    { lat: 48.7792994, lng: 18.263811 },
    { lat: 48.7792524, lng: 18.264826 },
    { lat: 48.779413, lng: 18.2652877 },
    { lat: 48.7793904, lng: 18.2659436 },
    { lat: 48.7794588, lng: 18.2663718 },
    { lat: 48.7795313, lng: 18.2666579 },
    { lat: 48.779521, lng: 18.2671508 },
    { lat: 48.7796101, lng: 18.2673285 },
    { lat: 48.7796144, lng: 18.2675036 },
    { lat: 48.7796653, lng: 18.2677216 },
    { lat: 48.7797017, lng: 18.2680664 },
    { lat: 48.7796342, lng: 18.2682884 },
    { lat: 48.7796772, lng: 18.2686452 },
    { lat: 48.7796264, lng: 18.2691824 },
    { lat: 48.7795603, lng: 18.269431 },
    { lat: 48.7793624, lng: 18.2700954 },
    { lat: 48.7795475, lng: 18.2701783 },
    { lat: 48.779727, lng: 18.270117 },
    { lat: 48.7798361, lng: 18.2701311 },
    { lat: 48.7800062, lng: 18.2700495 },
    { lat: 48.7802557, lng: 18.2698715 },
    { lat: 48.780475, lng: 18.2696823 },
    { lat: 48.7809041, lng: 18.2694082 },
    { lat: 48.7810119, lng: 18.2693788 },
    { lat: 48.7811117, lng: 18.2693486 },
    { lat: 48.7812779, lng: 18.2693159 },
    { lat: 48.7813859, lng: 18.2692689 },
    { lat: 48.7815353, lng: 18.2692194 },
    { lat: 48.7815866, lng: 18.269289 },
    { lat: 48.781667, lng: 18.2694524 },
    { lat: 48.7818043, lng: 18.2696832 },
    { lat: 48.7819658, lng: 18.2698049 },
    { lat: 48.7820356, lng: 18.26996 },
    { lat: 48.7821692, lng: 18.2700299 },
    { lat: 48.7823403, lng: 18.2700045 },
    { lat: 48.7824662, lng: 18.2700138 },
    { lat: 48.7826572, lng: 18.2698591 },
    { lat: 48.782737, lng: 18.2700738 },
    { lat: 48.7828614, lng: 18.2704744 },
    { lat: 48.7829408, lng: 18.2705807 },
    { lat: 48.7829783, lng: 18.2708839 },
    { lat: 48.7829799, lng: 18.2708901 },
    { lat: 48.7826336, lng: 18.2709123 },
    { lat: 48.7820053, lng: 18.2711571 },
    { lat: 48.7818326, lng: 18.2716912 },
    { lat: 48.7816589, lng: 18.2722262 },
    { lat: 48.7817459, lng: 18.272647 },
    { lat: 48.781799, lng: 18.2730061 },
    { lat: 48.7818595, lng: 18.2731553 },
    { lat: 48.7819496, lng: 18.273315 },
    { lat: 48.7822935, lng: 18.2736248 },
    { lat: 48.782311, lng: 18.2736168 },
    { lat: 48.7824968, lng: 18.2740073 },
    { lat: 48.7826163, lng: 18.2742053 },
    { lat: 48.7827227, lng: 18.2743085 },
    { lat: 48.7829676, lng: 18.2743496 },
    { lat: 48.7832488, lng: 18.274297 },
    { lat: 48.7838138, lng: 18.273812 },
    { lat: 48.7838966, lng: 18.2737633 },
    { lat: 48.7840402, lng: 18.2736695 },
    { lat: 48.7844301, lng: 18.2732399 },
    { lat: 48.7849301, lng: 18.2728275 },
    { lat: 48.7854928, lng: 18.2723868 },
    { lat: 48.7856299, lng: 18.2724734 },
    { lat: 48.7858522, lng: 18.2724577 },
    { lat: 48.7861687, lng: 18.2724262 },
    { lat: 48.7865852, lng: 18.2723703 },
    { lat: 48.7869788, lng: 18.2723287 },
    { lat: 48.7870983, lng: 18.2723257 },
    { lat: 48.7874688, lng: 18.2723126 },
    { lat: 48.7883409, lng: 18.2722485 },
    { lat: 48.7886057, lng: 18.2722441 },
    { lat: 48.7889856, lng: 18.2721982 },
    { lat: 48.7895, lng: 18.272175 },
    { lat: 48.7899077, lng: 18.2721434 },
    { lat: 48.7902265, lng: 18.2721166 },
    { lat: 48.7906401, lng: 18.2720678 },
    { lat: 48.7911263, lng: 18.272027 },
    { lat: 48.791965, lng: 18.2719122 },
    { lat: 48.7928451, lng: 18.2718349 },
    { lat: 48.7938831, lng: 18.2717352 },
    { lat: 48.7942782, lng: 18.2716978 },
    { lat: 48.7943207, lng: 18.2716937 },
    { lat: 48.7944926, lng: 18.2716763 },
    { lat: 48.7953389, lng: 18.2716002 },
    { lat: 48.7959172, lng: 18.2715528 },
    { lat: 48.796807, lng: 18.2714435 },
    { lat: 48.7975719, lng: 18.2713851 },
    { lat: 48.7977811, lng: 18.2714509 },
    { lat: 48.7978704, lng: 18.2716537 },
    { lat: 48.7978738, lng: 18.2718719 },
    { lat: 48.7977616, lng: 18.2722197 },
    { lat: 48.7973631, lng: 18.2730907 },
    { lat: 48.7972914, lng: 18.2736562 },
    { lat: 48.7973212, lng: 18.2741405 },
    { lat: 48.7980433, lng: 18.2745408 },
    { lat: 48.7984004, lng: 18.2751446 },
    { lat: 48.799277, lng: 18.2758978 },
    { lat: 48.7999851, lng: 18.2761686 },
    { lat: 48.8001369, lng: 18.2764187 },
    { lat: 48.8003719, lng: 18.2765803 },
    { lat: 48.8008441, lng: 18.276773 },
    { lat: 48.8014913, lng: 18.2774412 },
    { lat: 48.8022512, lng: 18.2779651 },
    { lat: 48.8032914, lng: 18.2783431 },
    { lat: 48.8041244, lng: 18.2783895 },
    { lat: 48.8046868, lng: 18.2786352 },
    { lat: 48.8051369, lng: 18.278712 },
    { lat: 48.8052796, lng: 18.2788023 },
    { lat: 48.8052808, lng: 18.2788032 },
    { lat: 48.8049604, lng: 18.2792225 },
    { lat: 48.8048015, lng: 18.2793441 },
    { lat: 48.8046882, lng: 18.279614 },
    { lat: 48.8046191, lng: 18.2800665 },
    { lat: 48.80516, lng: 18.2805542 },
    { lat: 48.8055087, lng: 18.2808739 },
    { lat: 48.8061416, lng: 18.2815076 },
    { lat: 48.8062047, lng: 18.2804731 },
    { lat: 48.8070104, lng: 18.279063 },
    { lat: 48.8072734, lng: 18.2774316 },
    { lat: 48.8070838, lng: 18.276081 },
    { lat: 48.8069978, lng: 18.27542 },
    { lat: 48.8069453, lng: 18.2745949 },
    { lat: 48.8066417, lng: 18.2740742 },
    { lat: 48.8058059, lng: 18.2736031 },
    { lat: 48.8053459, lng: 18.2729384 },
    { lat: 48.8053364, lng: 18.2729247 },
    { lat: 48.8051822, lng: 18.2724326 },
    { lat: 48.8052974, lng: 18.2720586 },
    { lat: 48.8054709, lng: 18.271518 },
    { lat: 48.8055841, lng: 18.2708388 },
    { lat: 48.8053311, lng: 18.2701052 },
    { lat: 48.8052383, lng: 18.269749 },
    { lat: 48.8052618, lng: 18.2688679 },
    { lat: 48.8052886, lng: 18.2675547 },
    { lat: 48.8059949, lng: 18.2665034 },
    { lat: 48.8060309, lng: 18.2663203 },
    { lat: 48.8061455, lng: 18.2657382 },
    { lat: 48.8061595, lng: 18.2652273 },
    { lat: 48.8062246, lng: 18.2644883 },
    { lat: 48.8053195, lng: 18.264346 },
    { lat: 48.8050118, lng: 18.2633806 },
    { lat: 48.8049893, lng: 18.2626061 },
    { lat: 48.8051501, lng: 18.2619047 },
    { lat: 48.8053409, lng: 18.2613739 },
    { lat: 48.8055486, lng: 18.2607958 },
    { lat: 48.8060364, lng: 18.2595323 },
    { lat: 48.8061667, lng: 18.258948 },
    { lat: 48.8063225, lng: 18.2582361 },
    { lat: 48.8065298, lng: 18.2569397 },
    { lat: 48.8065956, lng: 18.2567089 },
    { lat: 48.8069165, lng: 18.2554682 },
    { lat: 48.8070477, lng: 18.254961 },
    { lat: 48.8066845, lng: 18.2541296 },
    { lat: 48.8066324, lng: 18.253273 },
    { lat: 48.8062607, lng: 18.2516828 },
    { lat: 48.8051199, lng: 18.2512433 },
    { lat: 48.8040712, lng: 18.2512958 },
    { lat: 48.8036286, lng: 18.2513693 },
    { lat: 48.8031589, lng: 18.2516 },
    { lat: 48.8031462, lng: 18.2511621 },
    { lat: 48.8032546, lng: 18.2502087 },
    { lat: 48.8034372, lng: 18.2489196 },
    { lat: 48.8036084, lng: 18.2478456 },
    { lat: 48.8038696, lng: 18.2466868 },
    { lat: 48.8038226, lng: 18.2461414 },
    { lat: 48.8035001, lng: 18.2451963 },
    { lat: 48.8030232, lng: 18.2439719 },
    { lat: 48.8030221, lng: 18.2432619 },
    { lat: 48.8030009, lng: 18.2431089 },
    { lat: 48.8028163, lng: 18.2432354 },
    { lat: 48.8025667, lng: 18.2433887 },
    { lat: 48.8022122, lng: 18.2435256 },
    { lat: 48.802043, lng: 18.2435434 },
    { lat: 48.8017661, lng: 18.2435377 },
    { lat: 48.8015317, lng: 18.243641 },
    { lat: 48.8012751, lng: 18.243909 },
    { lat: 48.801168, lng: 18.2432587 },
    { lat: 48.8004431, lng: 18.2439021 },
    { lat: 48.8004416, lng: 18.243859 },
    { lat: 48.7998596, lng: 18.2425349 },
    { lat: 48.7993163, lng: 18.2409438 },
    { lat: 48.79834, lng: 18.2401682 },
    { lat: 48.7983025, lng: 18.240162 },
  ],
  Pravotice: [
    { lat: 48.665261, lng: 18.306354 },
    { lat: 48.6653301, lng: 18.3064266 },
    { lat: 48.6654488, lng: 18.3064488 },
    { lat: 48.6667263, lng: 18.3060772 },
    { lat: 48.6683379, lng: 18.3056042 },
    { lat: 48.6686226, lng: 18.3054707 },
    { lat: 48.6705755, lng: 18.304314 },
    { lat: 48.6707452, lng: 18.3042639 },
    { lat: 48.6709173, lng: 18.3042927 },
    { lat: 48.6732636, lng: 18.3049792 },
    { lat: 48.6743133, lng: 18.3052869 },
    { lat: 48.6743982, lng: 18.3054513 },
    { lat: 48.674344, lng: 18.3062686 },
    { lat: 48.674217, lng: 18.3082092 },
    { lat: 48.6742778, lng: 18.308226 },
    { lat: 48.6754538, lng: 18.3086525 },
    { lat: 48.6756004, lng: 18.3086485 },
    { lat: 48.6790349, lng: 18.307615 },
    { lat: 48.6807136, lng: 18.3071101 },
    { lat: 48.6826405, lng: 18.30655 },
    { lat: 48.6828487, lng: 18.3065388 },
    { lat: 48.6832255, lng: 18.3065387 },
    { lat: 48.6843793, lng: 18.3066932 },
    { lat: 48.6848217, lng: 18.3066714 },
    { lat: 48.685371, lng: 18.3065312 },
    { lat: 48.6859811, lng: 18.3066838 },
    { lat: 48.6859629, lng: 18.3070379 },
    { lat: 48.6861316, lng: 18.3070486 },
    { lat: 48.6862147, lng: 18.307106 },
    { lat: 48.686875, lng: 18.3071661 },
    { lat: 48.687054, lng: 18.3071587 },
    { lat: 48.6871839, lng: 18.3071233 },
    { lat: 48.6872193, lng: 18.3071888 },
    { lat: 48.6873118, lng: 18.307189 },
    { lat: 48.6873559, lng: 18.3071497 },
    { lat: 48.6874223, lng: 18.3072083 },
    { lat: 48.68773, lng: 18.3072642 },
    { lat: 48.6879633, lng: 18.3072594 },
    { lat: 48.6881241, lng: 18.3072308 },
    { lat: 48.6882141, lng: 18.3072235 },
    { lat: 48.6884459, lng: 18.3072714 },
    { lat: 48.6887521, lng: 18.3073011 },
    { lat: 48.6890302, lng: 18.3073895 },
    { lat: 48.6895283, lng: 18.3076191 },
    { lat: 48.6899775, lng: 18.3078814 },
    { lat: 48.6900312, lng: 18.3078326 },
    { lat: 48.6901146, lng: 18.3079108 },
    { lat: 48.6903, lng: 18.3079943 },
    { lat: 48.6903875, lng: 18.308008 },
    { lat: 48.6904567, lng: 18.3080734 },
    { lat: 48.6905656, lng: 18.3081429 },
    { lat: 48.6911734, lng: 18.3084369 },
    { lat: 48.6912365, lng: 18.3084959 },
    { lat: 48.691648, lng: 18.308658 },
    { lat: 48.6916571, lng: 18.3086083 },
    { lat: 48.6914376, lng: 18.3081006 },
    { lat: 48.6910816, lng: 18.3072717 },
    { lat: 48.6910377, lng: 18.3071349 },
    { lat: 48.6907746, lng: 18.3064132 },
    { lat: 48.6906979, lng: 18.3062156 },
    { lat: 48.6899374, lng: 18.3050608 },
    { lat: 48.6900978, lng: 18.3044862 },
    { lat: 48.6903842, lng: 18.3036443 },
    { lat: 48.6905955, lng: 18.3031699 },
    { lat: 48.6905827, lng: 18.3029441 },
    { lat: 48.6908053, lng: 18.3019736 },
    { lat: 48.6908788, lng: 18.3016185 },
    { lat: 48.6909175, lng: 18.3013471 },
    { lat: 48.6907665, lng: 18.3004549 },
    { lat: 48.690778, lng: 18.3001413 },
    { lat: 48.6907822, lng: 18.2998929 },
    { lat: 48.6908662, lng: 18.2995267 },
    { lat: 48.6910506, lng: 18.2988283 },
    { lat: 48.6911326, lng: 18.2985716 },
    { lat: 48.6912349, lng: 18.2979699 },
    { lat: 48.6912904, lng: 18.2972178 },
    { lat: 48.6912343, lng: 18.2966647 },
    { lat: 48.690966, lng: 18.295632 },
    { lat: 48.690853, lng: 18.2948942 },
    { lat: 48.6907742, lng: 18.2944816 },
    { lat: 48.6907487, lng: 18.2942106 },
    { lat: 48.6919368, lng: 18.2937733 },
    { lat: 48.6922735, lng: 18.293619 },
    { lat: 48.6928435, lng: 18.2933467 },
    { lat: 48.6928236, lng: 18.2932707 },
    { lat: 48.6928228, lng: 18.2932671 },
    { lat: 48.6928047, lng: 18.2931964 },
    { lat: 48.6927242, lng: 18.2925365 },
    { lat: 48.6926329, lng: 18.291723 },
    { lat: 48.6925502, lng: 18.2908601 },
    { lat: 48.6924736, lng: 18.2898908 },
    { lat: 48.6924191, lng: 18.2893629 },
    { lat: 48.6923348, lng: 18.2886739 },
    { lat: 48.6928164, lng: 18.2885947 },
    { lat: 48.6930842, lng: 18.28848 },
    { lat: 48.6932855, lng: 18.2883693 },
    { lat: 48.6932221, lng: 18.2879057 },
    { lat: 48.6932242, lng: 18.2878262 },
    { lat: 48.6932194, lng: 18.2877793 },
    { lat: 48.6929622, lng: 18.284882 },
    { lat: 48.6928752, lng: 18.2837676 },
    { lat: 48.692791, lng: 18.282901 },
    { lat: 48.6916854, lng: 18.2833008 },
    { lat: 48.6913145, lng: 18.2834257 },
    { lat: 48.6912885, lng: 18.2834437 },
    { lat: 48.6912042, lng: 18.2834289 },
    { lat: 48.6902032, lng: 18.2819563 },
    { lat: 48.689684, lng: 18.2808423 },
    { lat: 48.6896756, lng: 18.2808533 },
    { lat: 48.6895351, lng: 18.2805648 },
    { lat: 48.6892708, lng: 18.2801252 },
    { lat: 48.68898, lng: 18.2797333 },
    { lat: 48.6887111, lng: 18.2793082 },
    { lat: 48.6885565, lng: 18.2789712 },
    { lat: 48.6883794, lng: 18.2783767 },
    { lat: 48.6883424, lng: 18.2782626 },
    { lat: 48.6879327, lng: 18.2769288 },
    { lat: 48.687906, lng: 18.2768376 },
    { lat: 48.6878138, lng: 18.2765853 },
    { lat: 48.6875928, lng: 18.2759126 },
    { lat: 48.6874163, lng: 18.2752114 },
    { lat: 48.6873611, lng: 18.2749735 },
    { lat: 48.6873263, lng: 18.2746402 },
    { lat: 48.6873098, lng: 18.2746292 },
    { lat: 48.6873894, lng: 18.2736872 },
    { lat: 48.6873769, lng: 18.273664 },
    { lat: 48.6873542, lng: 18.2736214 },
    { lat: 48.6870091, lng: 18.2737233 },
    { lat: 48.6864398, lng: 18.2734962 },
    { lat: 48.6862433, lng: 18.2735913 },
    { lat: 48.6860604, lng: 18.2736715 },
    { lat: 48.6858655, lng: 18.2737746 },
    { lat: 48.6857555, lng: 18.2738228 },
    { lat: 48.6856439, lng: 18.2738852 },
    { lat: 48.6855382, lng: 18.2739321 },
    { lat: 48.6854565, lng: 18.2740034 },
    { lat: 48.6848247, lng: 18.2745803 },
    { lat: 48.6839824, lng: 18.2751246 },
    { lat: 48.6839024, lng: 18.2751685 },
    { lat: 48.6837228, lng: 18.2753236 },
    { lat: 48.6834197, lng: 18.2755647 },
    { lat: 48.6826985, lng: 18.2761578 },
    { lat: 48.6822512, lng: 18.2765293 },
    { lat: 48.6822322, lng: 18.2765486 },
    { lat: 48.6815613, lng: 18.2771119 },
    { lat: 48.6813666, lng: 18.2772797 },
    { lat: 48.6808752, lng: 18.2776865 },
    { lat: 48.6803527, lng: 18.2781449 },
    { lat: 48.6800621, lng: 18.2783693 },
    { lat: 48.679642, lng: 18.2787598 },
    { lat: 48.6793975, lng: 18.2790061 },
    { lat: 48.6790208, lng: 18.2793463 },
    { lat: 48.6789944, lng: 18.2793768 },
    { lat: 48.6787293, lng: 18.2796165 },
    { lat: 48.6782018, lng: 18.2800401 },
    { lat: 48.6779274, lng: 18.2802382 },
    { lat: 48.6774483, lng: 18.2806023 },
    { lat: 48.6767245, lng: 18.2809297 },
    { lat: 48.6759875, lng: 18.2814625 },
    { lat: 48.675503, lng: 18.2817517 },
    { lat: 48.6754519, lng: 18.2817851 },
    { lat: 48.6750372, lng: 18.2820309 },
    { lat: 48.6743664, lng: 18.282518 },
    { lat: 48.6733543, lng: 18.2828191 },
    { lat: 48.6728755, lng: 18.2832053 },
    { lat: 48.6721155, lng: 18.2837893 },
    { lat: 48.6718621, lng: 18.2840995 },
    { lat: 48.6718495, lng: 18.2841347 },
    { lat: 48.6716926, lng: 18.2840918 },
    { lat: 48.6715349, lng: 18.2839202 },
    { lat: 48.671403, lng: 18.2837631 },
    { lat: 48.6711216, lng: 18.2833598 },
    { lat: 48.6710209, lng: 18.2832721 },
    { lat: 48.67098, lng: 18.2831682 },
    { lat: 48.6707598, lng: 18.282667 },
    { lat: 48.670694, lng: 18.2826034 },
    { lat: 48.6706639, lng: 18.282512 },
    { lat: 48.6706206, lng: 18.2823027 },
    { lat: 48.6705097, lng: 18.2821614 },
    { lat: 48.6704916, lng: 18.2820006 },
    { lat: 48.6702384, lng: 18.2818721 },
    { lat: 48.6701893, lng: 18.2818231 },
    { lat: 48.6700596, lng: 18.2817264 },
    { lat: 48.6696756, lng: 18.2815999 },
    { lat: 48.6695709, lng: 18.2816056 },
    { lat: 48.6695523, lng: 18.2816144 },
    { lat: 48.6694424, lng: 18.2815876 },
    { lat: 48.6692701, lng: 18.2815717 },
    { lat: 48.6691397, lng: 18.2815404 },
    { lat: 48.6689971, lng: 18.2815518 },
    { lat: 48.6688018, lng: 18.2814518 },
    { lat: 48.6687313, lng: 18.2815427 },
    { lat: 48.6686658, lng: 18.2815226 },
    { lat: 48.6685237, lng: 18.2815221 },
    { lat: 48.6683637, lng: 18.2814854 },
    { lat: 48.6682818, lng: 18.2814553 },
    { lat: 48.6682122, lng: 18.2814822 },
    { lat: 48.6681528, lng: 18.2814481 },
    { lat: 48.6679476, lng: 18.2814486 },
    { lat: 48.6678272, lng: 18.2814571 },
    { lat: 48.6676125, lng: 18.2813721 },
    { lat: 48.6674819, lng: 18.2813966 },
    { lat: 48.6672962, lng: 18.2814656 },
    { lat: 48.6672322, lng: 18.2814493 },
    { lat: 48.6670834, lng: 18.2815742 },
    { lat: 48.6670125, lng: 18.2815738 },
    { lat: 48.666877, lng: 18.281637 },
    { lat: 48.666852, lng: 18.281889 },
    { lat: 48.66682, lng: 18.282257 },
    { lat: 48.666769, lng: 18.282699 },
    { lat: 48.666741, lng: 18.283099 },
    { lat: 48.666665, lng: 18.283656 },
    { lat: 48.66662, lng: 18.283799 },
    { lat: 48.666467, lng: 18.284223 },
    { lat: 48.666048, lng: 18.285139 },
    { lat: 48.665786, lng: 18.285767 },
    { lat: 48.665402, lng: 18.286648 },
    { lat: 48.664845, lng: 18.287988 },
    { lat: 48.664663, lng: 18.288312 },
    { lat: 48.664449, lng: 18.288943 },
    { lat: 48.664336, lng: 18.289418 },
    { lat: 48.664201, lng: 18.290065 },
    { lat: 48.664004, lng: 18.290626 },
    { lat: 48.663829, lng: 18.291087 },
    { lat: 48.663253, lng: 18.292566 },
    { lat: 48.663153, lng: 18.292858 },
    { lat: 48.662723, lng: 18.294093 },
    { lat: 48.662468, lng: 18.294827 },
    { lat: 48.66204, lng: 18.296059 },
    { lat: 48.662038, lng: 18.296064 },
    { lat: 48.662026, lng: 18.296103 },
    { lat: 48.662014, lng: 18.29614 },
    { lat: 48.66204, lng: 18.296233 },
    { lat: 48.662422, lng: 18.297433 },
    { lat: 48.66301, lng: 18.299289 },
    { lat: 48.663231, lng: 18.3 },
    { lat: 48.663492, lng: 18.300792 },
    { lat: 48.663566, lng: 18.30104 },
    { lat: 48.663951, lng: 18.302276 },
    { lat: 48.664836, lng: 18.305094 },
    { lat: 48.665143, lng: 18.306071 },
    { lat: 48.665154, lng: 18.306106 },
    { lat: 48.665237, lng: 18.306252 },
    { lat: 48.665262, lng: 18.306283 },
    { lat: 48.665261, lng: 18.306326 },
    { lat: 48.665261, lng: 18.306354 },
  ],
  Prusy: [
    { lat: 48.7661044, lng: 18.2838957 },
    { lat: 48.7664011, lng: 18.2831626 },
    { lat: 48.7665348, lng: 18.2825941 },
    { lat: 48.7665516, lng: 18.2818358 },
    { lat: 48.7663049, lng: 18.2807509 },
    { lat: 48.7665124, lng: 18.2801528 },
    { lat: 48.76671, lng: 18.2797569 },
    { lat: 48.76699, lng: 18.2790653 },
    { lat: 48.766796, lng: 18.2782307 },
    { lat: 48.7667675, lng: 18.2777877 },
    { lat: 48.7671742, lng: 18.2771214 },
    { lat: 48.7675171, lng: 18.2768229 },
    { lat: 48.7681561, lng: 18.2763929 },
    { lat: 48.7683941, lng: 18.2761335 },
    { lat: 48.7687294, lng: 18.2758763 },
    { lat: 48.7686897, lng: 18.2756351 },
    { lat: 48.7686895, lng: 18.2756104 },
    { lat: 48.7688195, lng: 18.2754976 },
    { lat: 48.7688816, lng: 18.2755161 },
    { lat: 48.7688953, lng: 18.2754996 },
    { lat: 48.7692928, lng: 18.2750888 },
    { lat: 48.7693654, lng: 18.2749968 },
    { lat: 48.7693927, lng: 18.2749617 },
    { lat: 48.7694285, lng: 18.2749174 },
    { lat: 48.7695685, lng: 18.2747386 },
    { lat: 48.7697287, lng: 18.2748165 },
    { lat: 48.7697902, lng: 18.2748467 },
    { lat: 48.7698527, lng: 18.2748769 },
    { lat: 48.7698912, lng: 18.2748959 },
    { lat: 48.769952, lng: 18.274855 },
    { lat: 48.7701529, lng: 18.2747799 },
    { lat: 48.7702488, lng: 18.2747363 },
    { lat: 48.7704199, lng: 18.2748679 },
    { lat: 48.7706899, lng: 18.2747562 },
    { lat: 48.7708718, lng: 18.2746242 },
    { lat: 48.7710091, lng: 18.2745763 },
    { lat: 48.7711094, lng: 18.2744731 },
    { lat: 48.7712717, lng: 18.274334 },
    { lat: 48.7713981, lng: 18.2743122 },
    { lat: 48.7715723, lng: 18.2742223 },
    { lat: 48.7717334, lng: 18.2741159 },
    { lat: 48.7719998, lng: 18.274141 },
    { lat: 48.7724731, lng: 18.2741685 },
    { lat: 48.7726564, lng: 18.2740989 },
    { lat: 48.7729469, lng: 18.2739098 },
    { lat: 48.7732868, lng: 18.2736181 },
    { lat: 48.7734623, lng: 18.2736067 },
    { lat: 48.7735625, lng: 18.2735422 },
    { lat: 48.7740663, lng: 18.2733754 },
    { lat: 48.7745767, lng: 18.2729824 },
    { lat: 48.7748225, lng: 18.2728238 },
    { lat: 48.7749791, lng: 18.2728371 },
    { lat: 48.7750526, lng: 18.2727624 },
    { lat: 48.7751084, lng: 18.2725906 },
    { lat: 48.7757999, lng: 18.2719158 },
    { lat: 48.7759789, lng: 18.2717297 },
    { lat: 48.7761664, lng: 18.2713544 },
    { lat: 48.7764155, lng: 18.2711531 },
    { lat: 48.7767894, lng: 18.2708795 },
    { lat: 48.7764199, lng: 18.2692255 },
    { lat: 48.7767914, lng: 18.2692884 },
    { lat: 48.7777278, lng: 18.2694452 },
    { lat: 48.7784817, lng: 18.2701832 },
    { lat: 48.7786974, lng: 18.2701268 },
    { lat: 48.7789101, lng: 18.2701782 },
    { lat: 48.7793624, lng: 18.2700954 },
    { lat: 48.7795603, lng: 18.269431 },
    { lat: 48.7796264, lng: 18.2691824 },
    { lat: 48.7796772, lng: 18.2686452 },
    { lat: 48.7796342, lng: 18.2682884 },
    { lat: 48.7797017, lng: 18.2680664 },
    { lat: 48.7796653, lng: 18.2677216 },
    { lat: 48.7796144, lng: 18.2675036 },
    { lat: 48.7796101, lng: 18.2673285 },
    { lat: 48.779521, lng: 18.2671508 },
    { lat: 48.7795313, lng: 18.2666579 },
    { lat: 48.7794588, lng: 18.2663718 },
    { lat: 48.7793904, lng: 18.2659436 },
    { lat: 48.779413, lng: 18.2652877 },
    { lat: 48.7792524, lng: 18.264826 },
    { lat: 48.7792994, lng: 18.263811 },
    { lat: 48.7792401, lng: 18.2632213 },
    { lat: 48.7788934, lng: 18.2625842 },
    { lat: 48.7787449, lng: 18.2622934 },
    { lat: 48.7787138, lng: 18.2623193 },
    { lat: 48.7787048, lng: 18.2623036 },
    { lat: 48.7786013, lng: 18.2622456 },
    { lat: 48.7785481, lng: 18.262246 },
    { lat: 48.7784392, lng: 18.262135 },
    { lat: 48.7783922, lng: 18.2620445 },
    { lat: 48.7783001, lng: 18.2620505 },
    { lat: 48.7782731, lng: 18.2619348 },
    { lat: 48.7781327, lng: 18.2618272 },
    { lat: 48.7779928, lng: 18.2617868 },
    { lat: 48.7779022, lng: 18.2617975 },
    { lat: 48.7778235, lng: 18.2617662 },
    { lat: 48.7778059, lng: 18.2616727 },
    { lat: 48.7776962, lng: 18.2616898 },
    { lat: 48.7776363, lng: 18.2617456 },
    { lat: 48.7775799, lng: 18.2617683 },
    { lat: 48.7775401, lng: 18.2619212 },
    { lat: 48.7774555, lng: 18.2618793 },
    { lat: 48.7773715, lng: 18.261892 },
    { lat: 48.7773556, lng: 18.2618118 },
    { lat: 48.7772816, lng: 18.2618404 },
    { lat: 48.7771671, lng: 18.2616522 },
    { lat: 48.7770878, lng: 18.2616467 },
    { lat: 48.7770921, lng: 18.261565 },
    { lat: 48.7769083, lng: 18.2614465 },
    { lat: 48.7768569, lng: 18.2613363 },
    { lat: 48.7767292, lng: 18.2614323 },
    { lat: 48.7766798, lng: 18.2613579 },
    { lat: 48.7766842, lng: 18.2612775 },
    { lat: 48.7765788, lng: 18.2612287 },
    { lat: 48.7764694, lng: 18.2612662 },
    { lat: 48.7764058, lng: 18.261121 },
    { lat: 48.7764144, lng: 18.2610351 },
    { lat: 48.7763396, lng: 18.2608724 },
    { lat: 48.7762517, lng: 18.2608309 },
    { lat: 48.7761537, lng: 18.2606387 },
    { lat: 48.7760383, lng: 18.2605518 },
    { lat: 48.7760341, lng: 18.2604186 },
    { lat: 48.7759731, lng: 18.2603195 },
    { lat: 48.7757634, lng: 18.2600825 },
    { lat: 48.7756394, lng: 18.2598282 },
    { lat: 48.7753732, lng: 18.2594125 },
    { lat: 48.7753007, lng: 18.2594892 },
    { lat: 48.7751922, lng: 18.259445 },
    { lat: 48.7749825, lng: 18.2594692 },
    { lat: 48.7747902, lng: 18.2592238 },
    { lat: 48.7747646, lng: 18.2590894 },
    { lat: 48.7746051, lng: 18.2589406 },
    { lat: 48.774417, lng: 18.2590463 },
    { lat: 48.7743609, lng: 18.2589335 },
    { lat: 48.7742908, lng: 18.2589311 },
    { lat: 48.7742514, lng: 18.258854 },
    { lat: 48.7741489, lng: 18.2587779 },
    { lat: 48.7739861, lng: 18.2588338 },
    { lat: 48.7738654, lng: 18.2586943 },
    { lat: 48.7737763, lng: 18.2587163 },
    { lat: 48.7737111, lng: 18.2586801 },
    { lat: 48.7736712, lng: 18.2584943 },
    { lat: 48.7735899, lng: 18.2585321 },
    { lat: 48.7734656, lng: 18.2584703 },
    { lat: 48.7733052, lng: 18.2584279 },
    { lat: 48.7732334, lng: 18.2585943 },
    { lat: 48.7730183, lng: 18.2585579 },
    { lat: 48.7730269, lng: 18.2583357 },
    { lat: 48.7728861, lng: 18.2582589 },
    { lat: 48.7728066, lng: 18.25827 },
    { lat: 48.7726486, lng: 18.2582702 },
    { lat: 48.7725804, lng: 18.2583424 },
    { lat: 48.772414, lng: 18.2584503 },
    { lat: 48.7723505, lng: 18.2583448 },
    { lat: 48.7722202, lng: 18.2584144 },
    { lat: 48.7721406, lng: 18.2586609 },
    { lat: 48.7718477, lng: 18.2586504 },
    { lat: 48.7717938, lng: 18.2586173 },
    { lat: 48.7716639, lng: 18.2586321 },
    { lat: 48.7714969, lng: 18.2587916 },
    { lat: 48.7714181, lng: 18.2589528 },
    { lat: 48.7713089, lng: 18.2589957 },
    { lat: 48.7711738, lng: 18.2591013 },
    { lat: 48.771073, lng: 18.2590554 },
    { lat: 48.7710226, lng: 18.2591002 },
    { lat: 48.770922, lng: 18.2591359 },
    { lat: 48.7708798, lng: 18.2592501 },
    { lat: 48.7707902, lng: 18.25922 },
    { lat: 48.7707055, lng: 18.259319 },
    { lat: 48.7707025, lng: 18.2594235 },
    { lat: 48.7706422, lng: 18.2594535 },
    { lat: 48.770596, lng: 18.2595343 },
    { lat: 48.7705283, lng: 18.2595324 },
    { lat: 48.7705061, lng: 18.259463 },
    { lat: 48.7705059, lng: 18.2594602 },
    { lat: 48.7703655, lng: 18.2595307 },
    { lat: 48.7702927, lng: 18.2595194 },
    { lat: 48.7701844, lng: 18.2595969 },
    { lat: 48.7700895, lng: 18.2596378 },
    { lat: 48.7700852, lng: 18.2597407 },
    { lat: 48.7700684, lng: 18.2597819 },
    { lat: 48.7699388, lng: 18.2598238 },
    { lat: 48.7699286, lng: 18.2598802 },
    { lat: 48.7698735, lng: 18.2598055 },
    { lat: 48.7697571, lng: 18.2598632 },
    { lat: 48.7697332, lng: 18.2599568 },
    { lat: 48.769686, lng: 18.2599802 },
    { lat: 48.7696328, lng: 18.259917 },
    { lat: 48.7695911, lng: 18.2598999 },
    { lat: 48.7695284, lng: 18.2599692 },
    { lat: 48.769337, lng: 18.2592194 },
    { lat: 48.7689709, lng: 18.2593104 },
    { lat: 48.7687816, lng: 18.2575928 },
    { lat: 48.7687881, lng: 18.2575286 },
    { lat: 48.7686263, lng: 18.2559376 },
    { lat: 48.7685968, lng: 18.255655 },
    { lat: 48.7686078, lng: 18.2540271 },
    { lat: 48.7685526, lng: 18.2531577 },
    { lat: 48.7685174, lng: 18.2529977 },
    { lat: 48.7680407, lng: 18.2532241 },
    { lat: 48.7676503, lng: 18.2533404 },
    { lat: 48.7673939, lng: 18.2534755 },
    { lat: 48.7673895, lng: 18.2534557 },
    { lat: 48.766699, lng: 18.253736 },
    { lat: 48.7664591, lng: 18.2538052 },
    { lat: 48.7661726, lng: 18.2538422 },
    { lat: 48.7654221, lng: 18.2538796 },
    { lat: 48.7649865, lng: 18.2539411 },
    { lat: 48.7645154, lng: 18.2540458 },
    { lat: 48.7637233, lng: 18.2541877 },
    { lat: 48.7632394, lng: 18.254268 },
    { lat: 48.7628891, lng: 18.2543401 },
    { lat: 48.7624986, lng: 18.2543746 },
    { lat: 48.76228, lng: 18.2544231 },
    { lat: 48.7622794, lng: 18.25445 },
    { lat: 48.761497, lng: 18.2543625 },
    { lat: 48.7608644, lng: 18.2543684 },
    { lat: 48.7600872, lng: 18.2544351 },
    { lat: 48.7597126, lng: 18.2543749 },
    { lat: 48.7593135, lng: 18.254359 },
    { lat: 48.7589623, lng: 18.2543951 },
    { lat: 48.7589576, lng: 18.25437 },
    { lat: 48.7585538, lng: 18.2543911 },
    { lat: 48.7581923, lng: 18.2543878 },
    { lat: 48.7578798, lng: 18.2543678 },
    { lat: 48.7577637, lng: 18.2543475 },
    { lat: 48.7572139, lng: 18.2543631 },
    { lat: 48.7567724, lng: 18.2542794 },
    { lat: 48.7565757, lng: 18.2542342 },
    { lat: 48.7557965, lng: 18.2541448 },
    { lat: 48.7551798, lng: 18.2541309 },
    { lat: 48.7549287, lng: 18.2541228 },
    { lat: 48.7542498, lng: 18.2540468 },
    { lat: 48.753748, lng: 18.254054 },
    { lat: 48.7537472, lng: 18.2540405 },
    { lat: 48.7536054, lng: 18.2532534 },
    { lat: 48.753456, lng: 18.252454 },
    { lat: 48.7532445, lng: 18.2512976 },
    { lat: 48.752637, lng: 18.2514305 },
    { lat: 48.7524378, lng: 18.2514354 },
    { lat: 48.7520946, lng: 18.2515127 },
    { lat: 48.7515012, lng: 18.2517335 },
    { lat: 48.7510537, lng: 18.2519585 },
    { lat: 48.7511636, lng: 18.2525012 },
    { lat: 48.751241, lng: 18.2529466 },
    { lat: 48.7513306, lng: 18.2536891 },
    { lat: 48.7512998, lng: 18.2537417 },
    { lat: 48.7510813, lng: 18.2535911 },
    { lat: 48.75077, lng: 18.2534364 },
    { lat: 48.7505061, lng: 18.2533571 },
    { lat: 48.7502585, lng: 18.2533111 },
    { lat: 48.7500487, lng: 18.2532916 },
    { lat: 48.7498897, lng: 18.2532952 },
    { lat: 48.7496343, lng: 18.2533435 },
    { lat: 48.7493788, lng: 18.2534151 },
    { lat: 48.7490458, lng: 18.2535296 },
    { lat: 48.7487326, lng: 18.2536766 },
    { lat: 48.7485702, lng: 18.25374 },
    { lat: 48.7484902, lng: 18.2537211 },
    { lat: 48.7484244, lng: 18.2536174 },
    { lat: 48.7483037, lng: 18.2531416 },
    { lat: 48.7482062, lng: 18.2526812 },
    { lat: 48.7478898, lng: 18.2513656 },
    { lat: 48.7477415, lng: 18.2506853 },
    { lat: 48.7476283, lng: 18.2501845 },
    { lat: 48.7475772, lng: 18.2499981 },
    { lat: 48.7474226, lng: 18.249542 },
    { lat: 48.7471532, lng: 18.2488778 },
    { lat: 48.7468039, lng: 18.2479763 },
    { lat: 48.7466457, lng: 18.2475371 },
    { lat: 48.7463858, lng: 18.2467784 },
    { lat: 48.7463145, lng: 18.2466154 },
    { lat: 48.7458973, lng: 18.2455361 },
    { lat: 48.7454322, lng: 18.2443837 },
    { lat: 48.7453182, lng: 18.2440693 },
    { lat: 48.7451216, lng: 18.2433724 },
    { lat: 48.7448659, lng: 18.2425703 },
    { lat: 48.744758, lng: 18.2422433 },
    { lat: 48.7447166, lng: 18.2421481 },
    { lat: 48.7446888, lng: 18.2421768 },
    { lat: 48.7446824, lng: 18.242184 },
    { lat: 48.7446761, lng: 18.2421902 },
    { lat: 48.7446493, lng: 18.2422549 },
    { lat: 48.7446434, lng: 18.2422701 },
    { lat: 48.7446235, lng: 18.242317 },
    { lat: 48.7446067, lng: 18.2423589 },
    { lat: 48.744593, lng: 18.2424358 },
    { lat: 48.7445923, lng: 18.2424618 },
    { lat: 48.744593, lng: 18.2425527 },
    { lat: 48.7445928, lng: 18.2425711 },
    { lat: 48.7445916, lng: 18.2426886 },
    { lat: 48.7444446, lng: 18.242958 },
    { lat: 48.744268, lng: 18.2432059 },
    { lat: 48.7440196, lng: 18.2434779 },
    { lat: 48.7437648, lng: 18.2436209 },
    { lat: 48.7436276, lng: 18.2436691 },
    { lat: 48.7435936, lng: 18.2436667 },
    { lat: 48.7435245, lng: 18.2436616 },
    { lat: 48.7434849, lng: 18.2436582 },
    { lat: 48.7432779, lng: 18.2436107 },
    { lat: 48.7432017, lng: 18.2436001 },
    { lat: 48.7431379, lng: 18.2435905 },
    { lat: 48.7429452, lng: 18.2437329 },
    { lat: 48.7429139, lng: 18.2438564 },
    { lat: 48.742865, lng: 18.2439299 },
    { lat: 48.7427254, lng: 18.244033 },
    { lat: 48.742689, lng: 18.2440267 },
    { lat: 48.7426662, lng: 18.2440225 },
    { lat: 48.7425247, lng: 18.243997 },
    { lat: 48.7423715, lng: 18.243923 },
    { lat: 48.7423508, lng: 18.2439135 },
    { lat: 48.74227, lng: 18.2438626 },
    { lat: 48.7422047, lng: 18.2436889 },
    { lat: 48.7419634, lng: 18.2437515 },
    { lat: 48.7418968, lng: 18.2437688 },
    { lat: 48.7418794, lng: 18.2437625 },
    { lat: 48.7415401, lng: 18.2436298 },
    { lat: 48.7414485, lng: 18.2435258 },
    { lat: 48.7413435, lng: 18.2434853 },
    { lat: 48.7411763, lng: 18.2435613 },
    { lat: 48.7410747, lng: 18.2437598 },
    { lat: 48.7409628, lng: 18.2439317 },
    { lat: 48.7408664, lng: 18.2440427 },
    { lat: 48.740242, lng: 18.2442313 },
    { lat: 48.7401598, lng: 18.2442556 },
    { lat: 48.7398691, lng: 18.2443422 },
    { lat: 48.7398582, lng: 18.244467 },
    { lat: 48.7398512, lng: 18.2445415 },
    { lat: 48.7398442, lng: 18.2446168 },
    { lat: 48.7398376, lng: 18.2446995 },
    { lat: 48.7398227, lng: 18.2449122 },
    { lat: 48.7398149, lng: 18.2450314 },
    { lat: 48.7398079, lng: 18.2451474 },
    { lat: 48.7397964, lng: 18.2453203 },
    { lat: 48.7397926, lng: 18.2453737 },
    { lat: 48.7397858, lng: 18.245531 },
    { lat: 48.7397782, lng: 18.2456753 },
    { lat: 48.7397736, lng: 18.2457728 },
    { lat: 48.739774, lng: 18.2457983 },
    { lat: 48.73977, lng: 18.2459856 },
    { lat: 48.7397681, lng: 18.2460323 },
    { lat: 48.7397674, lng: 18.2460778 },
    { lat: 48.7397651, lng: 18.2461757 },
    { lat: 48.739764, lng: 18.2462756 },
    { lat: 48.7397589, lng: 18.2463637 },
    { lat: 48.7397577, lng: 18.2463819 },
    { lat: 48.7397594, lng: 18.2464335 },
    { lat: 48.7397529, lng: 18.2465555 },
    { lat: 48.7397405, lng: 18.2467731 },
    { lat: 48.7397346, lng: 18.2468675 },
    { lat: 48.7397285, lng: 18.2470184 },
    { lat: 48.7397285, lng: 18.2470974 },
    { lat: 48.7397294, lng: 18.2471721 },
    { lat: 48.7397271, lng: 18.2473087 },
    { lat: 48.7397262, lng: 18.2473741 },
    { lat: 48.7397244, lng: 18.2474396 },
    { lat: 48.7397369, lng: 18.2475003 },
    { lat: 48.7397467, lng: 18.2475544 },
    { lat: 48.7397563, lng: 18.2476047 },
    { lat: 48.7397705, lng: 18.2476757 },
    { lat: 48.7397722, lng: 18.2476845 },
    { lat: 48.7398019, lng: 18.2477101 },
    { lat: 48.7398375, lng: 18.2477414 },
    { lat: 48.7399087, lng: 18.247802 },
    { lat: 48.7399395, lng: 18.2478281 },
    { lat: 48.739949, lng: 18.2478362 },
    { lat: 48.7399799, lng: 18.2478635 },
    { lat: 48.7400559, lng: 18.2479299 },
    { lat: 48.7401389, lng: 18.2480009 },
    { lat: 48.7401449, lng: 18.2480069 },
    { lat: 48.7402426, lng: 18.2480991 },
    { lat: 48.7402976, lng: 18.2481504 },
    { lat: 48.7403476, lng: 18.2481989 },
    { lat: 48.7404132, lng: 18.2482604 },
    { lat: 48.7404526, lng: 18.2482974 },
    { lat: 48.7405564, lng: 18.2483951 },
    { lat: 48.7406494, lng: 18.2484832 },
    { lat: 48.7406739, lng: 18.2486754 },
    { lat: 48.7406825, lng: 18.2487468 },
    { lat: 48.7407077, lng: 18.2489523 },
    { lat: 48.7407013, lng: 18.2489764 },
    { lat: 48.7410991, lng: 18.2491671 },
    { lat: 48.7412, lng: 18.2493723 },
    { lat: 48.741365, lng: 18.249672 },
    { lat: 48.7414009, lng: 18.2499666 },
    { lat: 48.7413856, lng: 18.2499764 },
    { lat: 48.7412755, lng: 18.2501216 },
    { lat: 48.7412597, lng: 18.2501419 },
    { lat: 48.7412471, lng: 18.2501589 },
    { lat: 48.7412156, lng: 18.2502006 },
    { lat: 48.7411863, lng: 18.2502394 },
    { lat: 48.7411737, lng: 18.2502565 },
    { lat: 48.7411568, lng: 18.250278 },
    { lat: 48.7408267, lng: 18.2504032 },
    { lat: 48.7404788, lng: 18.2504175 },
    { lat: 48.7393228, lng: 18.2506651 },
    { lat: 48.7388025, lng: 18.2508016 },
    { lat: 48.7388032, lng: 18.2508326 },
    { lat: 48.7386118, lng: 18.2509191 },
    { lat: 48.7381821, lng: 18.2512393 },
    { lat: 48.7380239, lng: 18.2513346 },
    { lat: 48.7380276, lng: 18.2513599 },
    { lat: 48.7380816, lng: 18.2530386 },
    { lat: 48.7381242, lng: 18.2541999 },
    { lat: 48.7381253, lng: 18.2543981 },
    { lat: 48.7381224, lng: 18.2545848 },
    { lat: 48.7380673, lng: 18.2552799 },
    { lat: 48.7380323, lng: 18.2555577 },
    { lat: 48.7379924, lng: 18.2559647 },
    { lat: 48.7379341, lng: 18.2566849 },
    { lat: 48.7379079, lng: 18.2572172 },
    { lat: 48.7379049, lng: 18.2572823 },
    { lat: 48.7378629, lng: 18.2573957 },
    { lat: 48.7378886, lng: 18.2575314 },
    { lat: 48.7379148, lng: 18.2576917 },
    { lat: 48.7379211, lng: 18.2577242 },
    { lat: 48.7381067, lng: 18.2577944 },
    { lat: 48.7382006, lng: 18.2578575 },
    { lat: 48.7384527, lng: 18.2580639 },
    { lat: 48.7387227, lng: 18.2583479 },
    { lat: 48.738989, lng: 18.2586428 },
    { lat: 48.7393195, lng: 18.2589776 },
    { lat: 48.739714, lng: 18.2593467 },
    { lat: 48.7399755, lng: 18.2595805 },
    { lat: 48.7404294, lng: 18.2601007 },
    { lat: 48.7408687, lng: 18.2606434 },
    { lat: 48.7412732, lng: 18.26101 },
    { lat: 48.7416505, lng: 18.2614132 },
    { lat: 48.741802, lng: 18.2616144 },
    { lat: 48.742049, lng: 18.2619309 },
    { lat: 48.7422001, lng: 18.2620873 },
    { lat: 48.7425126, lng: 18.2624029 },
    { lat: 48.7427444, lng: 18.2626106 },
    { lat: 48.7428863, lng: 18.2627257 },
    { lat: 48.7430525, lng: 18.262829 },
    { lat: 48.7432624, lng: 18.2629476 },
    { lat: 48.7434679, lng: 18.2630305 },
    { lat: 48.7436705, lng: 18.2631021 },
    { lat: 48.7439144, lng: 18.2631433 },
    { lat: 48.7441219, lng: 18.2631812 },
    { lat: 48.7442857, lng: 18.2632055 },
    { lat: 48.7444654, lng: 18.2632103 },
    { lat: 48.7447029, lng: 18.2631807 },
    { lat: 48.7449531, lng: 18.263193 },
    { lat: 48.7451854, lng: 18.2632129 },
    { lat: 48.7451706, lng: 18.2632883 },
    { lat: 48.7452248, lng: 18.2632893 },
    { lat: 48.7450833, lng: 18.2641531 },
    { lat: 48.7448984, lng: 18.2652465 },
    { lat: 48.7447515, lng: 18.2661158 },
    { lat: 48.7446426, lng: 18.2665802 },
    { lat: 48.7444236, lng: 18.2673318 },
    { lat: 48.7444515, lng: 18.267366 },
    { lat: 48.7444368, lng: 18.2675842 },
    { lat: 48.7443739, lng: 18.2679067 },
    { lat: 48.7442751, lng: 18.2684109 },
    { lat: 48.7441599, lng: 18.2690453 },
    { lat: 48.7441427, lng: 18.2691383 },
    { lat: 48.7440367, lng: 18.2698528 },
    { lat: 48.7440302, lng: 18.2699177 },
    { lat: 48.744113, lng: 18.2699244 },
    { lat: 48.744213, lng: 18.2698572 },
    { lat: 48.7443214, lng: 18.2698802 },
    { lat: 48.7444, lng: 18.269893 },
    { lat: 48.7446001, lng: 18.2698393 },
    { lat: 48.7446704, lng: 18.2697862 },
    { lat: 48.744866, lng: 18.2697552 },
    { lat: 48.7449907, lng: 18.2698042 },
    { lat: 48.7451141, lng: 18.2698309 },
    { lat: 48.7451491, lng: 18.2698094 },
    { lat: 48.7451458, lng: 18.269712 },
    { lat: 48.7452036, lng: 18.2696786 },
    { lat: 48.7452995, lng: 18.2697766 },
    { lat: 48.7453359, lng: 18.2697829 },
    { lat: 48.7453823, lng: 18.2697261 },
    { lat: 48.7454056, lng: 18.2697174 },
    { lat: 48.7454862, lng: 18.2697466 },
    { lat: 48.745506, lng: 18.2697372 },
    { lat: 48.7455785, lng: 18.2696631 },
    { lat: 48.7456047, lng: 18.2697269 },
    { lat: 48.7457273, lng: 18.2699183 },
    { lat: 48.7457446, lng: 18.269945 },
    { lat: 48.7457179, lng: 18.2707248 },
    { lat: 48.7457007, lng: 18.271236 },
    { lat: 48.7459381, lng: 18.2711461 },
    { lat: 48.7466796, lng: 18.2725175 },
    { lat: 48.747046, lng: 18.273903 },
    { lat: 48.7478618, lng: 18.2741807 },
    { lat: 48.7479539, lng: 18.2742116 },
    { lat: 48.7479752, lng: 18.2742499 },
    { lat: 48.7481128, lng: 18.2742503 },
    { lat: 48.7482528, lng: 18.2742503 },
    { lat: 48.7483069, lng: 18.2742506 },
    { lat: 48.7484018, lng: 18.2742324 },
    { lat: 48.7487077, lng: 18.2741742 },
    { lat: 48.7487304, lng: 18.2740935 },
    { lat: 48.7489958, lng: 18.2737215 },
    { lat: 48.7491269, lng: 18.2736069 },
    { lat: 48.7493603, lng: 18.2735454 },
    { lat: 48.7498261, lng: 18.2740938 },
    { lat: 48.7503366, lng: 18.275198 },
    { lat: 48.7505687, lng: 18.2761036 },
    { lat: 48.7506905, lng: 18.2766995 },
    { lat: 48.7507229, lng: 18.2767328 },
    { lat: 48.750695, lng: 18.2770361 },
    { lat: 48.7507885, lng: 18.2771752 },
    { lat: 48.750779, lng: 18.2774028 },
    { lat: 48.7507973, lng: 18.2775051 },
    { lat: 48.7508339, lng: 18.2775172 },
    { lat: 48.7508423, lng: 18.2775436 },
    { lat: 48.7508365, lng: 18.2777203 },
    { lat: 48.7509042, lng: 18.2778184 },
    { lat: 48.7508896, lng: 18.2780803 },
    { lat: 48.7509406, lng: 18.2781423 },
    { lat: 48.7509296, lng: 18.2782277 },
    { lat: 48.7509954, lng: 18.2784319 },
    { lat: 48.7509937, lng: 18.2785022 },
    { lat: 48.7510267, lng: 18.2785476 },
    { lat: 48.7511466, lng: 18.279067 },
    { lat: 48.7511218, lng: 18.2791689 },
    { lat: 48.7511682, lng: 18.2794274 },
    { lat: 48.7513374, lng: 18.2796665 },
    { lat: 48.7515507, lng: 18.2796098 },
    { lat: 48.7515692, lng: 18.2796153 },
    { lat: 48.7516151, lng: 18.2796697 },
    { lat: 48.7516549, lng: 18.2796756 },
    { lat: 48.7516809, lng: 18.2797541 },
    { lat: 48.7517614, lng: 18.2798016 },
    { lat: 48.7518193, lng: 18.2799475 },
    { lat: 48.7518884, lng: 18.28001 },
    { lat: 48.7520053, lng: 18.2799627 },
    { lat: 48.7520196, lng: 18.2799973 },
    { lat: 48.752028, lng: 18.280225 },
    { lat: 48.7520055, lng: 18.2802655 },
    { lat: 48.7520237, lng: 18.2803486 },
    { lat: 48.7520276, lng: 18.2805337 },
    { lat: 48.7520869, lng: 18.2805867 },
    { lat: 48.7521143, lng: 18.2807702 },
    { lat: 48.7521025, lng: 18.2808 },
    { lat: 48.7520312, lng: 18.2807763 },
    { lat: 48.7519971, lng: 18.2808126 },
    { lat: 48.7520197, lng: 18.2808954 },
    { lat: 48.7519689, lng: 18.28097 },
    { lat: 48.7519354, lng: 18.2810762 },
    { lat: 48.7519019, lng: 18.2811224 },
    { lat: 48.751878, lng: 18.2812188 },
    { lat: 48.751891, lng: 18.2812472 },
    { lat: 48.7519391, lng: 18.2812831 },
    { lat: 48.75195, lng: 18.2813157 },
    { lat: 48.7519901, lng: 18.2813859 },
    { lat: 48.7519784, lng: 18.2814379 },
    { lat: 48.7519179, lng: 18.281543 },
    { lat: 48.7519014, lng: 18.2815937 },
    { lat: 48.7519089, lng: 18.2817251 },
    { lat: 48.7519233, lng: 18.2817575 },
    { lat: 48.7519579, lng: 18.2817934 },
    { lat: 48.7520295, lng: 18.2818006 },
    { lat: 48.7520509, lng: 18.2818818 },
    { lat: 48.752112, lng: 18.2820005 },
    { lat: 48.7521263, lng: 18.2820948 },
    { lat: 48.7522254, lng: 18.2820898 },
    { lat: 48.752288, lng: 18.282219 },
    { lat: 48.7522883, lng: 18.2823233 },
    { lat: 48.7523347, lng: 18.282308 },
    { lat: 48.7523971, lng: 18.2823325 },
    { lat: 48.7524358, lng: 18.2822981 },
    { lat: 48.75252, lng: 18.2823312 },
    { lat: 48.7525348, lng: 18.2823557 },
    { lat: 48.7524866, lng: 18.2824603 },
    { lat: 48.7525545, lng: 18.2825434 },
    { lat: 48.7526251, lng: 18.2824344 },
    { lat: 48.7526402, lng: 18.2823635 },
    { lat: 48.7526766, lng: 18.2824295 },
    { lat: 48.7527843, lng: 18.2824982 },
    { lat: 48.752791, lng: 18.2825534 },
    { lat: 48.7528592, lng: 18.2826229 },
    { lat: 48.7528939, lng: 18.282696 },
    { lat: 48.753011, lng: 18.2827923 },
    { lat: 48.7530917, lng: 18.2828026 },
    { lat: 48.7532003, lng: 18.2829294 },
    { lat: 48.7531919, lng: 18.2830567 },
    { lat: 48.7532115, lng: 18.2831056 },
    { lat: 48.7532668, lng: 18.2831263 },
    { lat: 48.7533486, lng: 18.2830975 },
    { lat: 48.7533788, lng: 18.2831908 },
    { lat: 48.7534505, lng: 18.2831804 },
    { lat: 48.7536007, lng: 18.2833608 },
    { lat: 48.7536371, lng: 18.2834855 },
    { lat: 48.7536008, lng: 18.2835407 },
    { lat: 48.7534667, lng: 18.2835445 },
    { lat: 48.7534365, lng: 18.283513 },
    { lat: 48.7534072, lng: 18.2835708 },
    { lat: 48.7533633, lng: 18.2835933 },
    { lat: 48.7534191, lng: 18.2837395 },
    { lat: 48.7534855, lng: 18.2837766 },
    { lat: 48.7534955, lng: 18.2838726 },
    { lat: 48.7534696, lng: 18.2839174 },
    { lat: 48.7533837, lng: 18.2838939 },
    { lat: 48.753321, lng: 18.2841401 },
    { lat: 48.7533307, lng: 18.2842097 },
    { lat: 48.7534239, lng: 18.284283 },
    { lat: 48.753399, lng: 18.2846385 },
    { lat: 48.7533779, lng: 18.284668 },
    { lat: 48.7533679, lng: 18.2847501 },
    { lat: 48.7534295, lng: 18.2848609 },
    { lat: 48.7535363, lng: 18.2848506 },
    { lat: 48.7535855, lng: 18.2847845 },
    { lat: 48.7536139, lng: 18.2847897 },
    { lat: 48.7536628, lng: 18.2848134 },
    { lat: 48.7537232, lng: 18.2849243 },
    { lat: 48.7537793, lng: 18.2850794 },
    { lat: 48.753764, lng: 18.285126 },
    { lat: 48.7537716, lng: 18.2852016 },
    { lat: 48.7538018, lng: 18.2852538 },
    { lat: 48.7537893, lng: 18.2853763 },
    { lat: 48.7538297, lng: 18.2855512 },
    { lat: 48.7539425, lng: 18.2855094 },
    { lat: 48.7540245, lng: 18.2855213 },
    { lat: 48.7540688, lng: 18.2855889 },
    { lat: 48.7541821, lng: 18.285695 },
    { lat: 48.7541954, lng: 18.2857491 },
    { lat: 48.7541552, lng: 18.2858569 },
    { lat: 48.7541342, lng: 18.2859623 },
    { lat: 48.7542102, lng: 18.2863318 },
    { lat: 48.7543034, lng: 18.2865822 },
    { lat: 48.754346, lng: 18.2866532 },
    { lat: 48.7544399, lng: 18.2866209 },
    { lat: 48.7545469, lng: 18.2864358 },
    { lat: 48.7546013, lng: 18.2865196 },
    { lat: 48.7545861, lng: 18.2865902 },
    { lat: 48.7546195, lng: 18.2866452 },
    { lat: 48.7547112, lng: 18.2866487 },
    { lat: 48.7547803, lng: 18.286712 },
    { lat: 48.7547975, lng: 18.286857 },
    { lat: 48.7547438, lng: 18.2868858 },
    { lat: 48.7547006, lng: 18.286841 },
    { lat: 48.7546545, lng: 18.2868624 },
    { lat: 48.7545874, lng: 18.2869547 },
    { lat: 48.754621, lng: 18.2871249 },
    { lat: 48.7546998, lng: 18.287164 },
    { lat: 48.7547186, lng: 18.287296 },
    { lat: 48.7547012, lng: 18.2874477 },
    { lat: 48.7547609, lng: 18.2874865 },
    { lat: 48.7548045, lng: 18.2876385 },
    { lat: 48.754885, lng: 18.2877035 },
    { lat: 48.7549396, lng: 18.2876911 },
    { lat: 48.7549835, lng: 18.2877315 },
    { lat: 48.7550481, lng: 18.2876734 },
    { lat: 48.7550773, lng: 18.2877343 },
    { lat: 48.7550798, lng: 18.2878949 },
    { lat: 48.7550564, lng: 18.2879816 },
    { lat: 48.7551245, lng: 18.2881663 },
    { lat: 48.7550275, lng: 18.2882067 },
    { lat: 48.7549629, lng: 18.2882809 },
    { lat: 48.7549826, lng: 18.2883119 },
    { lat: 48.7550429, lng: 18.2883413 },
    { lat: 48.7551298, lng: 18.288497 },
    { lat: 48.7552168, lng: 18.2884014 },
    { lat: 48.7553411, lng: 18.2882465 },
    { lat: 48.7553254, lng: 18.288206 },
    { lat: 48.7553127, lng: 18.2881236 },
    { lat: 48.7555397, lng: 18.2878664 },
    { lat: 48.7555714, lng: 18.2878294 },
    { lat: 48.755603, lng: 18.2877699 },
    { lat: 48.7556313, lng: 18.287714 },
    { lat: 48.7559627, lng: 18.2874082 },
    { lat: 48.7561905, lng: 18.2872892 },
    { lat: 48.7569895, lng: 18.2870526 },
    { lat: 48.7573614, lng: 18.2869439 },
    { lat: 48.7580215, lng: 18.2866855 },
    { lat: 48.7585414, lng: 18.2864439 },
    { lat: 48.7590178, lng: 18.2862105 },
    { lat: 48.7597105, lng: 18.2859497 },
    { lat: 48.7600761, lng: 18.2858314 },
    { lat: 48.7603778, lng: 18.2857969 },
    { lat: 48.7605132, lng: 18.2858173 },
    { lat: 48.760512, lng: 18.2858385 },
    { lat: 48.7606968, lng: 18.2858117 },
    { lat: 48.7609481, lng: 18.2857276 },
    { lat: 48.7611413, lng: 18.2856321 },
    { lat: 48.7613363, lng: 18.2854894 },
    { lat: 48.7618618, lng: 18.2852088 },
    { lat: 48.7622879, lng: 18.2848624 },
    { lat: 48.7624929, lng: 18.2848169 },
    { lat: 48.7626707, lng: 18.2848256 },
    { lat: 48.7628928, lng: 18.2847063 },
    { lat: 48.7631048, lng: 18.2846427 },
    { lat: 48.7638406, lng: 18.2845064 },
    { lat: 48.7645399, lng: 18.2842633 },
    { lat: 48.7655262, lng: 18.2838273 },
    { lat: 48.7657692, lng: 18.2837765 },
    { lat: 48.7658817, lng: 18.2837915 },
    { lat: 48.7660661, lng: 18.2839164 },
    { lat: 48.7661044, lng: 18.2838957 },
  ],
  Ruskovce: [
    { lat: 48.7367404, lng: 18.2042016 },
    { lat: 48.7367898, lng: 18.2041981 },
    { lat: 48.7368743, lng: 18.204275 },
    { lat: 48.7368997, lng: 18.2043027 },
    { lat: 48.7374042, lng: 18.2047972 },
    { lat: 48.7378209, lng: 18.2049984 },
    { lat: 48.7381647, lng: 18.205169 },
    { lat: 48.7383063, lng: 18.2052178 },
    { lat: 48.7386389, lng: 18.2053316 },
    { lat: 48.7386769, lng: 18.2053445 },
    { lat: 48.7391027, lng: 18.2053308 },
    { lat: 48.7391608, lng: 18.2053219 },
    { lat: 48.7392358, lng: 18.2053099 },
    { lat: 48.7392791, lng: 18.2052987 },
    { lat: 48.7393253, lng: 18.205316 },
    { lat: 48.7394256, lng: 18.2053533 },
    { lat: 48.7397342, lng: 18.2063654 },
    { lat: 48.7405309, lng: 18.2073684 },
    { lat: 48.7412896, lng: 18.2079245 },
    { lat: 48.7413378, lng: 18.2079589 },
    { lat: 48.7417842, lng: 18.2083261 },
    { lat: 48.7417899, lng: 18.2084426 },
    { lat: 48.7417929, lng: 18.208516 },
    { lat: 48.7417973, lng: 18.2085927 },
    { lat: 48.7417981, lng: 18.2086062 },
    { lat: 48.7425732, lng: 18.2086441 },
    { lat: 48.7427214, lng: 18.208729 },
    { lat: 48.7428043, lng: 18.2087769 },
    { lat: 48.743053, lng: 18.2089422 },
    { lat: 48.7433346, lng: 18.2091297 },
    { lat: 48.7434085, lng: 18.2091789 },
    { lat: 48.7437695, lng: 18.2094327 },
    { lat: 48.7440004, lng: 18.2096616 },
    { lat: 48.7440437, lng: 18.2096879 },
    { lat: 48.744046, lng: 18.2096889 },
    { lat: 48.7440577, lng: 18.2096963 },
    { lat: 48.7440869, lng: 18.2097134 },
    { lat: 48.7440923, lng: 18.2096714 },
    { lat: 48.7440837, lng: 18.2093042 },
    { lat: 48.7439826, lng: 18.2088202 },
    { lat: 48.744016, lng: 18.2085578 },
    { lat: 48.7441073, lng: 18.2082993 },
    { lat: 48.7440253, lng: 18.2081099 },
    { lat: 48.7438935, lng: 18.2080557 },
    { lat: 48.7437712, lng: 18.2079687 },
    { lat: 48.7436853, lng: 18.2077122 },
    { lat: 48.7436309, lng: 18.207431 },
    { lat: 48.7436639, lng: 18.2071614 },
    { lat: 48.7437776, lng: 18.2070578 },
    { lat: 48.744044, lng: 18.2068632 },
    { lat: 48.7441058, lng: 18.2067593 },
    { lat: 48.744295, lng: 18.2064391 },
    { lat: 48.7443234, lng: 18.2063835 },
    { lat: 48.7443347, lng: 18.2063461 },
    { lat: 48.7443404, lng: 18.2063069 },
    { lat: 48.7443379, lng: 18.2062642 },
    { lat: 48.7443129, lng: 18.2061225 },
    { lat: 48.7443345, lng: 18.2061264 },
    { lat: 48.7443448, lng: 18.2061285 },
    { lat: 48.7444859, lng: 18.2061484 },
    { lat: 48.7448204, lng: 18.2062175 },
    { lat: 48.7451596, lng: 18.2062873 },
    { lat: 48.7459669, lng: 18.2064531 },
    { lat: 48.7459772, lng: 18.2064549 },
    { lat: 48.7461007, lng: 18.2058752 },
    { lat: 48.7461097, lng: 18.2058345 },
    { lat: 48.7461449, lng: 18.2058383 },
    { lat: 48.746332, lng: 18.2050101 },
    { lat: 48.7465832, lng: 18.2047864 },
    { lat: 48.7466512, lng: 18.2047128 },
    { lat: 48.7467047, lng: 18.2044651 },
    { lat: 48.747052, lng: 18.2028498 },
    { lat: 48.7470609, lng: 18.2028106 },
    { lat: 48.7470011, lng: 18.2028086 },
    { lat: 48.7471884, lng: 18.2019048 },
    { lat: 48.7472874, lng: 18.2014879 },
    { lat: 48.7474934, lng: 18.2005167 },
    { lat: 48.7476058, lng: 18.200432 },
    { lat: 48.7476772, lng: 18.2003774 },
    { lat: 48.7476986, lng: 18.2001629 },
    { lat: 48.7477765, lng: 18.1998477 },
    { lat: 48.7478033, lng: 18.1997431 },
    { lat: 48.7478717, lng: 18.1995593 },
    { lat: 48.7479204, lng: 18.1993861 },
    { lat: 48.7479545, lng: 18.1991556 },
    { lat: 48.7479521, lng: 18.1989968 },
    { lat: 48.7479486, lng: 18.1988371 },
    { lat: 48.747975, lng: 18.198864 },
    { lat: 48.748098, lng: 18.1989426 },
    { lat: 48.7482223, lng: 18.199023 },
    { lat: 48.7484308, lng: 18.1991381 },
    { lat: 48.7484983, lng: 18.1992526 },
    { lat: 48.7485783, lng: 18.1994451 },
    { lat: 48.7486495, lng: 18.1996839 },
    { lat: 48.7486363, lng: 18.1997497 },
    { lat: 48.7488014, lng: 18.1995172 },
    { lat: 48.7491129, lng: 18.1996385 },
    { lat: 48.7491764, lng: 18.1995458 },
    { lat: 48.7492969, lng: 18.1993469 },
    { lat: 48.7493792, lng: 18.1991855 },
    { lat: 48.7495219, lng: 18.1988632 },
    { lat: 48.7495236, lng: 18.1987332 },
    { lat: 48.7496375, lng: 18.1981847 },
    { lat: 48.749638, lng: 18.1975263 },
    { lat: 48.7492678, lng: 18.1974055 },
    { lat: 48.7493514, lng: 18.1967012 },
    { lat: 48.7500385, lng: 18.1964245 },
    { lat: 48.7500278, lng: 18.1952766 },
    { lat: 48.7500132, lng: 18.1947676 },
    { lat: 48.7499372, lng: 18.194371 },
    { lat: 48.7498109, lng: 18.1943708 },
    { lat: 48.7496465, lng: 18.1944565 },
    { lat: 48.7495212, lng: 18.194218 },
    { lat: 48.7494502, lng: 18.194045 },
    { lat: 48.7494522, lng: 18.1940218 },
    { lat: 48.7495767, lng: 18.1937934 },
    { lat: 48.7494728, lng: 18.1936726 },
    { lat: 48.7501921, lng: 18.1922295 },
    { lat: 48.7509209, lng: 18.1908541 },
    { lat: 48.7514039, lng: 18.1898955 },
    { lat: 48.752186, lng: 18.1883864 },
    { lat: 48.7527483, lng: 18.1872786 },
    { lat: 48.7529012, lng: 18.186854 },
    { lat: 48.7531782, lng: 18.1864953 },
    { lat: 48.7535804, lng: 18.186031 },
    { lat: 48.7539431, lng: 18.1856274 },
    { lat: 48.7544066, lng: 18.185095 },
    { lat: 48.7548232, lng: 18.1846096 },
    { lat: 48.7551139, lng: 18.1843277 },
    { lat: 48.755229, lng: 18.1842285 },
    { lat: 48.7553066, lng: 18.1840688 },
    { lat: 48.7554149, lng: 18.1839701 },
    { lat: 48.7554754, lng: 18.1840197 },
    { lat: 48.7559787, lng: 18.1835769 },
    { lat: 48.7557861, lng: 18.1833011 },
    { lat: 48.7556634, lng: 18.1830143 },
    { lat: 48.755523, lng: 18.1832606 },
    { lat: 48.7554471, lng: 18.1832939 },
    { lat: 48.7550949, lng: 18.1830946 },
    { lat: 48.7548768, lng: 18.1831877 },
    { lat: 48.7546959, lng: 18.183517 },
    { lat: 48.7545844, lng: 18.1833418 },
    { lat: 48.7540794, lng: 18.1825315 },
    { lat: 48.7538437, lng: 18.1820801 },
    { lat: 48.7536298, lng: 18.1816391 },
    { lat: 48.7534603, lng: 18.1818539 },
    { lat: 48.7528993, lng: 18.1827299 },
    { lat: 48.7527408, lng: 18.1828967 },
    { lat: 48.7524748, lng: 18.1834503 },
    { lat: 48.7521462, lng: 18.1828002 },
    { lat: 48.7519948, lng: 18.1825438 },
    { lat: 48.7518712, lng: 18.1823562 },
    { lat: 48.7516839, lng: 18.1820437 },
    { lat: 48.7516019, lng: 18.1821267 },
    { lat: 48.7515603, lng: 18.181992 },
    { lat: 48.7507134, lng: 18.1806114 },
    { lat: 48.7497157, lng: 18.1789872 },
    { lat: 48.7496684, lng: 18.1789083 },
    { lat: 48.7487648, lng: 18.1774649 },
    { lat: 48.7479026, lng: 18.1760786 },
    { lat: 48.7477619, lng: 18.1762991 },
    { lat: 48.7475666, lng: 18.1763528 },
    { lat: 48.7474826, lng: 18.1766013 },
    { lat: 48.7476584, lng: 18.1769475 },
    { lat: 48.7473953, lng: 18.1771413 },
    { lat: 48.7473442, lng: 18.1772932 },
    { lat: 48.747369, lng: 18.1776457 },
    { lat: 48.7473248, lng: 18.1777571 },
    { lat: 48.7468485, lng: 18.1783412 },
    { lat: 48.7467806, lng: 18.1786119 },
    { lat: 48.746566, lng: 18.1789245 },
    { lat: 48.7463439, lng: 18.1790835 },
    { lat: 48.7462193, lng: 18.1787431 },
    { lat: 48.7459772, lng: 18.1782806 },
    { lat: 48.7458033, lng: 18.178026 },
    { lat: 48.7454849, lng: 18.1775142 },
    { lat: 48.7450926, lng: 18.1769119 },
    { lat: 48.7447995, lng: 18.1764672 },
    { lat: 48.7445105, lng: 18.1760154 },
    { lat: 48.743952, lng: 18.1752757 },
    { lat: 48.7436851, lng: 18.1749155 },
    { lat: 48.7432591, lng: 18.1743349 },
    { lat: 48.7427427, lng: 18.1736404 },
    { lat: 48.7425464, lng: 18.1734049 },
    { lat: 48.7423106, lng: 18.173115 },
    { lat: 48.742149, lng: 18.1729321 },
    { lat: 48.7419521, lng: 18.1727486 },
    { lat: 48.7416656, lng: 18.1725951 },
    { lat: 48.7415364, lng: 18.1733062 },
    { lat: 48.7414338, lng: 18.1737405 },
    { lat: 48.7412959, lng: 18.1742646 },
    { lat: 48.7410307, lng: 18.1748899 },
    { lat: 48.7408122, lng: 18.1752859 },
    { lat: 48.7405747, lng: 18.1757073 },
    { lat: 48.7402427, lng: 18.1761129 },
    { lat: 48.7399109, lng: 18.1765224 },
    { lat: 48.7397302, lng: 18.17675 },
    { lat: 48.7394307, lng: 18.1771753 },
    { lat: 48.7392834, lng: 18.1774006 },
    { lat: 48.7389998, lng: 18.1779199 },
    { lat: 48.7388586, lng: 18.1785052 },
    { lat: 48.7387754, lng: 18.1790385 },
    { lat: 48.7386735, lng: 18.1793458 },
    { lat: 48.7384883, lng: 18.1798332 },
    { lat: 48.7382834, lng: 18.1804665 },
    { lat: 48.7381616, lng: 18.1808598 },
    { lat: 48.7380566, lng: 18.1811721 },
    { lat: 48.7377493, lng: 18.1819694 },
    { lat: 48.7374727, lng: 18.1826916 },
    { lat: 48.7371101, lng: 18.1836216 },
    { lat: 48.7369924, lng: 18.1836747 },
    { lat: 48.7367849, lng: 18.1846944 },
    { lat: 48.7365924, lng: 18.1858394 },
    { lat: 48.7362238, lng: 18.1871778 },
    { lat: 48.7361625, lng: 18.1875048 },
    { lat: 48.7361375, lng: 18.1878344 },
    { lat: 48.7361675, lng: 18.1882781 },
    { lat: 48.7361817, lng: 18.1885443 },
    { lat: 48.736208, lng: 18.1892548 },
    { lat: 48.7362192, lng: 18.1897616 },
    { lat: 48.7362152, lng: 18.1898687 },
    { lat: 48.7361893, lng: 18.1902028 },
    { lat: 48.7361542, lng: 18.1904941 },
    { lat: 48.7359942, lng: 18.1911264 },
    { lat: 48.7357442, lng: 18.1920741 },
    { lat: 48.735698, lng: 18.1923326 },
    { lat: 48.7356328, lng: 18.1926118 },
    { lat: 48.7354536, lng: 18.1933972 },
    { lat: 48.7354177, lng: 18.1936775 },
    { lat: 48.7353645, lng: 18.1943805 },
    { lat: 48.7353565, lng: 18.1947719 },
    { lat: 48.7353832, lng: 18.1950587 },
    { lat: 48.7354355, lng: 18.1953592 },
    { lat: 48.7355647, lng: 18.1959939 },
    { lat: 48.7357659, lng: 18.1968433 },
    { lat: 48.7359519, lng: 18.1976489 },
    { lat: 48.7360754, lng: 18.1984184 },
    { lat: 48.7361648, lng: 18.1994113 },
    { lat: 48.7361698, lng: 18.1994386 },
    { lat: 48.7363098, lng: 18.199891 },
    { lat: 48.7364713, lng: 18.2004825 },
    { lat: 48.7365156, lng: 18.2007612 },
    { lat: 48.7365277, lng: 18.2011489 },
    { lat: 48.736517, lng: 18.2019044 },
    { lat: 48.7366284, lng: 18.2033935 },
    { lat: 48.7366375, lng: 18.2034914 },
    { lat: 48.7366404, lng: 18.2035421 },
    { lat: 48.7367182, lng: 18.2042082 },
    { lat: 48.7367404, lng: 18.2042016 },
  ],
  Rybany: [
    { lat: 48.652991, lng: 18.240533 },
    { lat: 48.652985, lng: 18.240749 },
    { lat: 48.652986, lng: 18.240825 },
    { lat: 48.652955, lng: 18.240998 },
    { lat: 48.652924, lng: 18.241174 },
    { lat: 48.652905, lng: 18.241235 },
    { lat: 48.652812, lng: 18.241353 },
    { lat: 48.652696, lng: 18.241432 },
    { lat: 48.65267, lng: 18.241486 },
    { lat: 48.65266, lng: 18.241636 },
    { lat: 48.652647, lng: 18.241793 },
    { lat: 48.652669, lng: 18.241979 },
    { lat: 48.65271, lng: 18.242118 },
    { lat: 48.652712, lng: 18.242321 },
    { lat: 48.652782, lng: 18.242349 },
    { lat: 48.65281, lng: 18.242396 },
    { lat: 48.652855, lng: 18.242473 },
    { lat: 48.652861, lng: 18.242645 },
    { lat: 48.652794, lng: 18.242703 },
    { lat: 48.652798, lng: 18.242777 },
    { lat: 48.652781, lng: 18.242806 },
    { lat: 48.65269, lng: 18.242972 },
    { lat: 48.652658, lng: 18.243044 },
    { lat: 48.652565, lng: 18.243155 },
    { lat: 48.652542, lng: 18.24322 },
    { lat: 48.652548, lng: 18.243259 },
    { lat: 48.65253, lng: 18.243373 },
    { lat: 48.652558, lng: 18.243448 },
    { lat: 48.652578, lng: 18.243545 },
    { lat: 48.65256, lng: 18.2436 },
    { lat: 48.652532, lng: 18.243782 },
    { lat: 48.6525, lng: 18.244014 },
    { lat: 48.652496, lng: 18.244052 },
    { lat: 48.652493, lng: 18.2441 },
    { lat: 48.652488, lng: 18.244182 },
    { lat: 48.652479, lng: 18.244316 },
    { lat: 48.652445, lng: 18.244509 },
    { lat: 48.6525, lng: 18.244685 },
    { lat: 48.652501, lng: 18.244759 },
    { lat: 48.652487, lng: 18.244945 },
    { lat: 48.652481, lng: 18.245135 },
    { lat: 48.652428, lng: 18.245244 },
    { lat: 48.652392, lng: 18.245347 },
    { lat: 48.652383, lng: 18.245392 },
    { lat: 48.652364, lng: 18.245455 },
    { lat: 48.652339, lng: 18.245596 },
    { lat: 48.652346, lng: 18.245634 },
    { lat: 48.652357, lng: 18.245736 },
    { lat: 48.652343, lng: 18.245807 },
    { lat: 48.652329, lng: 18.24587 },
    { lat: 48.652302, lng: 18.245973 },
    { lat: 48.652284, lng: 18.24605 },
    { lat: 48.652264, lng: 18.246116 },
    { lat: 48.652194, lng: 18.24635 },
    { lat: 48.652113, lng: 18.246544 },
    { lat: 48.652085, lng: 18.246618 },
    { lat: 48.652033, lng: 18.24679 },
    { lat: 48.65202, lng: 18.246849 },
    { lat: 48.652002, lng: 18.247023 },
    { lat: 48.652001, lng: 18.247151 },
    { lat: 48.651995, lng: 18.247198 },
    { lat: 48.651975, lng: 18.247302 },
    { lat: 48.651943, lng: 18.247297 },
    { lat: 48.651893, lng: 18.247284 },
    { lat: 48.65111, lng: 18.247095 },
    { lat: 48.650412, lng: 18.246937 },
    { lat: 48.650411, lng: 18.246986 },
    { lat: 48.650417, lng: 18.247029 },
    { lat: 48.650495, lng: 18.247314 },
    { lat: 48.650534, lng: 18.247499 },
    { lat: 48.650556, lng: 18.247666 },
    { lat: 48.650558, lng: 18.247888 },
    { lat: 48.650531, lng: 18.248095 },
    { lat: 48.650488, lng: 18.248252 },
    { lat: 48.6504828, lng: 18.2482681 },
    { lat: 48.650433, lng: 18.248423 },
    { lat: 48.650384, lng: 18.248592 },
    { lat: 48.650381, lng: 18.248606 },
    { lat: 48.650354, lng: 18.248764 },
    { lat: 48.650341, lng: 18.248898 },
    { lat: 48.650332, lng: 18.248999 },
    { lat: 48.650327, lng: 18.249161 },
    { lat: 48.650331, lng: 18.249332 },
    { lat: 48.650354, lng: 18.249618 },
    { lat: 48.650367, lng: 18.249774 },
    { lat: 48.650365, lng: 18.249835 },
    { lat: 48.650363, lng: 18.249877 },
    { lat: 48.65035, lng: 18.249985 },
    { lat: 48.650321, lng: 18.250107 },
    { lat: 48.650308, lng: 18.250172 },
    { lat: 48.650258, lng: 18.250372 },
    { lat: 48.650113, lng: 18.250851 },
    { lat: 48.649959, lng: 18.251354 },
    { lat: 48.649914, lng: 18.251499 },
    { lat: 48.649872, lng: 18.25163 },
    { lat: 48.649826, lng: 18.251802 },
    { lat: 48.649782, lng: 18.251969 },
    { lat: 48.649773, lng: 18.252003 },
    { lat: 48.649757, lng: 18.252069 },
    { lat: 48.649738, lng: 18.252156 },
    { lat: 48.649713, lng: 18.252286 },
    { lat: 48.649703, lng: 18.25236 },
    { lat: 48.649695, lng: 18.252473 },
    { lat: 48.649692, lng: 18.252809 },
    { lat: 48.649695, lng: 18.25283 },
    { lat: 48.649665, lng: 18.252896 },
    { lat: 48.649561, lng: 18.253497 },
    { lat: 48.649458, lng: 18.253859 },
    { lat: 48.649179, lng: 18.25449 },
    { lat: 48.648943, lng: 18.254858 },
    { lat: 48.648924, lng: 18.254887 },
    { lat: 48.648912, lng: 18.255207 },
    { lat: 48.648898, lng: 18.255576 },
    { lat: 48.648889, lng: 18.256057 },
    { lat: 48.648884, lng: 18.256897 },
    { lat: 48.648928, lng: 18.257206 },
    { lat: 48.648998, lng: 18.257611 },
    { lat: 48.649006, lng: 18.257624 },
    { lat: 48.649124, lng: 18.258012 },
    { lat: 48.649184, lng: 18.258187 },
    { lat: 48.649329, lng: 18.258378 },
    { lat: 48.649384, lng: 18.258495 },
    { lat: 48.649547, lng: 18.259005 },
    { lat: 48.649604, lng: 18.259333 },
    { lat: 48.649733, lng: 18.259826 },
    { lat: 48.649773, lng: 18.260141 },
    { lat: 48.649879, lng: 18.260395 },
    { lat: 48.649967, lng: 18.260618 },
    { lat: 48.650026, lng: 18.260902 },
    { lat: 48.650042, lng: 18.26105 },
    { lat: 48.650072, lng: 18.261294 },
    { lat: 48.650041, lng: 18.261539 },
    { lat: 48.650054, lng: 18.261728 },
    { lat: 48.650108, lng: 18.262033 },
    { lat: 48.650151, lng: 18.262292 },
    { lat: 48.650155, lng: 18.26243 },
    { lat: 48.650122, lng: 18.262699 },
    { lat: 48.650117, lng: 18.262744 },
    { lat: 48.650144, lng: 18.262797 },
    { lat: 48.65026, lng: 18.263022 },
    { lat: 48.650287, lng: 18.263165 },
    { lat: 48.650264, lng: 18.263272 },
    { lat: 48.650256, lng: 18.263284 },
    { lat: 48.650215, lng: 18.263363 },
    { lat: 48.650166, lng: 18.263394 },
    { lat: 48.650145, lng: 18.263407 },
    { lat: 48.649952, lng: 18.263449 },
    { lat: 48.649852, lng: 18.263536 },
    { lat: 48.64977, lng: 18.263636 },
    { lat: 48.649737, lng: 18.263685 },
    { lat: 48.649719, lng: 18.263793 },
    { lat: 48.64969, lng: 18.263903 },
    { lat: 48.649692, lng: 18.264008 },
    { lat: 48.649715, lng: 18.264094 },
    { lat: 48.64975, lng: 18.264184 },
    { lat: 48.649802, lng: 18.264268 },
    { lat: 48.649979, lng: 18.264414 },
    { lat: 48.650051, lng: 18.264506 },
    { lat: 48.650132, lng: 18.264696 },
    { lat: 48.650244, lng: 18.26484 },
    { lat: 48.650406, lng: 18.265215 },
    { lat: 48.650485, lng: 18.265303 },
    { lat: 48.650586, lng: 18.265445 },
    { lat: 48.650595, lng: 18.265467 },
    { lat: 48.650607, lng: 18.265455 },
    { lat: 48.650672, lng: 18.265374 },
    { lat: 48.650687, lng: 18.265338 },
    { lat: 48.650758, lng: 18.265175 },
    { lat: 48.650897, lng: 18.265146 },
    { lat: 48.650997, lng: 18.265137 },
    { lat: 48.651116, lng: 18.265065 },
    { lat: 48.651158, lng: 18.264946 },
    { lat: 48.651258, lng: 18.26478 },
    { lat: 48.65142, lng: 18.264775 },
    { lat: 48.651505, lng: 18.264804 },
    { lat: 48.651635, lng: 18.264836 },
    { lat: 48.65177, lng: 18.264879 },
    { lat: 48.651872, lng: 18.264891 },
    { lat: 48.651984, lng: 18.264956 },
    { lat: 48.652203, lng: 18.26612 },
    { lat: 48.65233, lng: 18.266645 },
    { lat: 48.652367, lng: 18.266799 },
    { lat: 48.652549, lng: 18.267523 },
    { lat: 48.652649, lng: 18.268006 },
    { lat: 48.65278, lng: 18.268503 },
    { lat: 48.652791, lng: 18.268549 },
    { lat: 48.652802, lng: 18.268588 },
    { lat: 48.652835, lng: 18.268704 },
    { lat: 48.652951, lng: 18.269105 },
    { lat: 48.65322, lng: 18.269998 },
    { lat: 48.653274, lng: 18.270187 },
    { lat: 48.653374, lng: 18.270528 },
    { lat: 48.653384, lng: 18.270562 },
    { lat: 48.653472, lng: 18.270871 },
    { lat: 48.653496, lng: 18.270973 },
    { lat: 48.653507, lng: 18.270982 },
    { lat: 48.653602, lng: 18.271073 },
    { lat: 48.653794, lng: 18.271625 },
    { lat: 48.654116, lng: 18.27229 },
    { lat: 48.654393, lng: 18.27271 },
    { lat: 48.65472, lng: 18.273066 },
    { lat: 48.655087, lng: 18.273164 },
    { lat: 48.655436, lng: 18.273099 },
    { lat: 48.655446, lng: 18.273145 },
    { lat: 48.655572, lng: 18.27311 },
    { lat: 48.655834, lng: 18.273036 },
    { lat: 48.656086, lng: 18.272964 },
    { lat: 48.656293, lng: 18.272913 },
    { lat: 48.656459, lng: 18.27286 },
    { lat: 48.656538, lng: 18.273064 },
    { lat: 48.656602, lng: 18.273228 },
    { lat: 48.656657, lng: 18.27337 },
    { lat: 48.656705, lng: 18.273468 },
    { lat: 48.65673, lng: 18.273524 },
    { lat: 48.656808, lng: 18.273668 },
    { lat: 48.656874, lng: 18.273789 },
    { lat: 48.657026, lng: 18.27407 },
    { lat: 48.657053, lng: 18.274121 },
    { lat: 48.657077, lng: 18.274172 },
    { lat: 48.657134, lng: 18.274274 },
    { lat: 48.657655, lng: 18.274179 },
    { lat: 48.657723, lng: 18.274555 },
    { lat: 48.657725, lng: 18.274569 },
    { lat: 48.657743, lng: 18.274669 },
    { lat: 48.657747, lng: 18.274717 },
    { lat: 48.658027, lng: 18.274821 },
    { lat: 48.658216, lng: 18.274896 },
    { lat: 48.658492, lng: 18.275033 },
    { lat: 48.6588, lng: 18.275126 },
    { lat: 48.658947, lng: 18.27518 },
    { lat: 48.658951, lng: 18.275177 },
    { lat: 48.659276, lng: 18.275328 },
    { lat: 48.659561, lng: 18.27549 },
    { lat: 48.65967, lng: 18.275534 },
    { lat: 48.659798, lng: 18.275591 },
    { lat: 48.659887, lng: 18.275648 },
    { lat: 48.659974, lng: 18.275663 },
    { lat: 48.659981, lng: 18.275674 },
    { lat: 48.660153, lng: 18.275722 },
    { lat: 48.660271, lng: 18.275754 },
    { lat: 48.660601, lng: 18.275899 },
    { lat: 48.660658, lng: 18.275953 },
    { lat: 48.660755, lng: 18.276042 },
    { lat: 48.660864, lng: 18.2761 },
    { lat: 48.661014, lng: 18.27614 },
    { lat: 48.661102, lng: 18.276192 },
    { lat: 48.661278, lng: 18.276239 },
    { lat: 48.661502, lng: 18.276371 },
    { lat: 48.661691, lng: 18.276496 },
    { lat: 48.661896, lng: 18.276584 },
    { lat: 48.662211, lng: 18.276786 },
    { lat: 48.662328, lng: 18.27683 },
    { lat: 48.662336, lng: 18.27684 },
    { lat: 48.662634, lng: 18.276949 },
    { lat: 48.662983, lng: 18.277184 },
    { lat: 48.663122, lng: 18.277293 },
    { lat: 48.663165, lng: 18.277308 },
    { lat: 48.663324, lng: 18.277429 },
    { lat: 48.66337, lng: 18.277461 },
    { lat: 48.663664, lng: 18.27759 },
    { lat: 48.663829, lng: 18.277702 },
    { lat: 48.663981, lng: 18.27777 },
    { lat: 48.664145, lng: 18.277889 },
    { lat: 48.664309, lng: 18.278025 },
    { lat: 48.664591, lng: 18.278326 },
    { lat: 48.664657, lng: 18.278388 },
    { lat: 48.664708, lng: 18.278434 },
    { lat: 48.664727, lng: 18.278452 },
    { lat: 48.664936, lng: 18.278646 },
    { lat: 48.664958, lng: 18.278667 },
    { lat: 48.664978, lng: 18.278685 },
    { lat: 48.665164, lng: 18.278913 },
    { lat: 48.665309, lng: 18.279148 },
    { lat: 48.665461, lng: 18.279332 },
    { lat: 48.665567, lng: 18.279489 },
    { lat: 48.665639, lng: 18.279641 },
    { lat: 48.665742, lng: 18.279796 },
    { lat: 48.665987, lng: 18.280104 },
    { lat: 48.665989, lng: 18.280108 },
    { lat: 48.666189, lng: 18.280409 },
    { lat: 48.666542, lng: 18.280868 },
    { lat: 48.666674, lng: 18.281058 },
    { lat: 48.666813, lng: 18.281254 },
    { lat: 48.666838, lng: 18.281343 },
    { lat: 48.666822, lng: 18.281476 },
    { lat: 48.666819, lng: 18.281494 },
    { lat: 48.666809, lng: 18.281572 },
    { lat: 48.666818, lng: 18.281599 },
    { lat: 48.666829, lng: 18.281632 },
    { lat: 48.666877, lng: 18.281637 },
    { lat: 48.6670125, lng: 18.2815738 },
    { lat: 48.6670834, lng: 18.2815742 },
    { lat: 48.6672322, lng: 18.2814493 },
    { lat: 48.6672962, lng: 18.2814656 },
    { lat: 48.6674819, lng: 18.2813966 },
    { lat: 48.6676125, lng: 18.2813721 },
    { lat: 48.6678272, lng: 18.2814571 },
    { lat: 48.6679476, lng: 18.2814486 },
    { lat: 48.6681528, lng: 18.2814481 },
    { lat: 48.6682122, lng: 18.2814822 },
    { lat: 48.6682818, lng: 18.2814553 },
    { lat: 48.6683637, lng: 18.2814854 },
    { lat: 48.6685237, lng: 18.2815221 },
    { lat: 48.6686658, lng: 18.2815226 },
    { lat: 48.6687313, lng: 18.2815427 },
    { lat: 48.6688018, lng: 18.2814518 },
    { lat: 48.6689971, lng: 18.2815518 },
    { lat: 48.6691397, lng: 18.2815404 },
    { lat: 48.6692701, lng: 18.2815717 },
    { lat: 48.6694424, lng: 18.2815876 },
    { lat: 48.6695523, lng: 18.2816144 },
    { lat: 48.6695709, lng: 18.2816056 },
    { lat: 48.6696756, lng: 18.2815999 },
    { lat: 48.6700596, lng: 18.2817264 },
    { lat: 48.6701893, lng: 18.2818231 },
    { lat: 48.6702384, lng: 18.2818721 },
    { lat: 48.6704916, lng: 18.2820006 },
    { lat: 48.6705097, lng: 18.2821614 },
    { lat: 48.6706206, lng: 18.2823027 },
    { lat: 48.6706639, lng: 18.282512 },
    { lat: 48.670694, lng: 18.2826034 },
    { lat: 48.6707598, lng: 18.282667 },
    { lat: 48.67098, lng: 18.2831682 },
    { lat: 48.6710209, lng: 18.2832721 },
    { lat: 48.6711216, lng: 18.2833598 },
    { lat: 48.671403, lng: 18.2837631 },
    { lat: 48.6715349, lng: 18.2839202 },
    { lat: 48.6716926, lng: 18.2840918 },
    { lat: 48.6718495, lng: 18.2841347 },
    { lat: 48.6718621, lng: 18.2840995 },
    { lat: 48.6721155, lng: 18.2837893 },
    { lat: 48.6728755, lng: 18.2832053 },
    { lat: 48.6733543, lng: 18.2828191 },
    { lat: 48.6743664, lng: 18.282518 },
    { lat: 48.6750372, lng: 18.2820309 },
    { lat: 48.6754519, lng: 18.2817851 },
    { lat: 48.675503, lng: 18.2817517 },
    { lat: 48.6759875, lng: 18.2814625 },
    { lat: 48.6767245, lng: 18.2809297 },
    { lat: 48.6774483, lng: 18.2806023 },
    { lat: 48.6779274, lng: 18.2802382 },
    { lat: 48.6782018, lng: 18.2800401 },
    { lat: 48.6787293, lng: 18.2796165 },
    { lat: 48.6789944, lng: 18.2793768 },
    { lat: 48.6790208, lng: 18.2793463 },
    { lat: 48.6793975, lng: 18.2790061 },
    { lat: 48.679642, lng: 18.2787598 },
    { lat: 48.6800621, lng: 18.2783693 },
    { lat: 48.6803527, lng: 18.2781449 },
    { lat: 48.6808752, lng: 18.2776865 },
    { lat: 48.6813666, lng: 18.2772797 },
    { lat: 48.6815613, lng: 18.2771119 },
    { lat: 48.6822322, lng: 18.2765486 },
    { lat: 48.6822512, lng: 18.2765293 },
    { lat: 48.6826985, lng: 18.2761578 },
    { lat: 48.6834197, lng: 18.2755647 },
    { lat: 48.6837228, lng: 18.2753236 },
    { lat: 48.6839024, lng: 18.2751685 },
    { lat: 48.6839824, lng: 18.2751246 },
    { lat: 48.6848247, lng: 18.2745803 },
    { lat: 48.6854565, lng: 18.2740034 },
    { lat: 48.6855382, lng: 18.2739321 },
    { lat: 48.6856439, lng: 18.2738852 },
    { lat: 48.6857555, lng: 18.2738228 },
    { lat: 48.6858655, lng: 18.2737746 },
    { lat: 48.6860604, lng: 18.2736715 },
    { lat: 48.6862433, lng: 18.2735913 },
    { lat: 48.6864398, lng: 18.2734962 },
    { lat: 48.6870091, lng: 18.2737233 },
    { lat: 48.6873542, lng: 18.2736214 },
    { lat: 48.6873769, lng: 18.273664 },
    { lat: 48.6877121, lng: 18.2726782 },
    { lat: 48.6881053, lng: 18.2718128 },
    { lat: 48.6887657, lng: 18.2702302 },
    { lat: 48.6891276, lng: 18.269274 },
    { lat: 48.6894218, lng: 18.2683573 },
    { lat: 48.689622, lng: 18.2676924 },
    { lat: 48.6898765, lng: 18.2668558 },
    { lat: 48.690072, lng: 18.2662033 },
    { lat: 48.6902219, lng: 18.2656096 },
    { lat: 48.6904198, lng: 18.2649814 },
    { lat: 48.6904073, lng: 18.2641287 },
    { lat: 48.6903755, lng: 18.2626205 },
    { lat: 48.6902675, lng: 18.2618535 },
    { lat: 48.6901086, lng: 18.2605888 },
    { lat: 48.6901428, lng: 18.2593873 },
    { lat: 48.6897836, lng: 18.2577788 },
    { lat: 48.6897143, lng: 18.257576 },
    { lat: 48.6895824, lng: 18.2572986 },
    { lat: 48.6894601, lng: 18.2571141 },
    { lat: 48.6892393, lng: 18.2568415 },
    { lat: 48.6890091, lng: 18.2564257 },
    { lat: 48.6889325, lng: 18.2562304 },
    { lat: 48.6887346, lng: 18.2555926 },
    { lat: 48.6888469, lng: 18.255128 },
    { lat: 48.6884413, lng: 18.253912 },
    { lat: 48.6883029, lng: 18.2535432 },
    { lat: 48.6883003, lng: 18.2535389 },
    { lat: 48.6882658, lng: 18.2534477 },
    { lat: 48.6879305, lng: 18.252515 },
    { lat: 48.6876076, lng: 18.2516007 },
    { lat: 48.6876063, lng: 18.2515988 },
    { lat: 48.687511, lng: 18.2513144 },
    { lat: 48.6873815, lng: 18.2509003 },
    { lat: 48.6867241, lng: 18.2489954 },
    { lat: 48.6864393, lng: 18.2489268 },
    { lat: 48.6862538, lng: 18.2490367 },
    { lat: 48.6861734, lng: 18.2490321 },
    { lat: 48.6860897, lng: 18.2489288 },
    { lat: 48.6860601, lng: 18.2485677 },
    { lat: 48.6860906, lng: 18.2482937 },
    { lat: 48.6860729, lng: 18.2482418 },
    { lat: 48.6860036, lng: 18.2481915 },
    { lat: 48.6858554, lng: 18.2482676 },
    { lat: 48.6857276, lng: 18.2483603 },
    { lat: 48.6856691, lng: 18.2484826 },
    { lat: 48.6856936, lng: 18.2489482 },
    { lat: 48.6857053, lng: 18.249016 },
    { lat: 48.6856591, lng: 18.2491766 },
    { lat: 48.6854993, lng: 18.2493385 },
    { lat: 48.6854645, lng: 18.2493052 },
    { lat: 48.6851203, lng: 18.2490658 },
    { lat: 48.685059, lng: 18.2491391 },
    { lat: 48.6849796, lng: 18.2493305 },
    { lat: 48.6850449, lng: 18.2497416 },
    { lat: 48.6851625, lng: 18.2498463 },
    { lat: 48.685201, lng: 18.2499862 },
    { lat: 48.6851592, lng: 18.250182 },
    { lat: 48.6850356, lng: 18.2502481 },
    { lat: 48.6848956, lng: 18.2501699 },
    { lat: 48.6848335, lng: 18.2501091 },
    { lat: 48.6846699, lng: 18.2500511 },
    { lat: 48.684635, lng: 18.2499918 },
    { lat: 48.6844852, lng: 18.2498395 },
    { lat: 48.6843304, lng: 18.2494976 },
    { lat: 48.684145, lng: 18.2491172 },
    { lat: 48.6840124, lng: 18.2488309 },
    { lat: 48.6838825, lng: 18.2485739 },
    { lat: 48.6835869, lng: 18.2482747 },
    { lat: 48.6832762, lng: 18.2474014 },
    { lat: 48.6830956, lng: 18.247025 },
    { lat: 48.682915, lng: 18.2466664 },
    { lat: 48.6824269, lng: 18.2460038 },
    { lat: 48.6820321, lng: 18.2454336 },
    { lat: 48.6816053, lng: 18.2444812 },
    { lat: 48.6811693, lng: 18.2437246 },
    { lat: 48.6806033, lng: 18.24328 },
    { lat: 48.680057, lng: 18.2429795 },
    { lat: 48.679931, lng: 18.2429641 },
    { lat: 48.6791821, lng: 18.243426 },
    { lat: 48.6790118, lng: 18.2436024 },
    { lat: 48.6788703, lng: 18.2435388 },
    { lat: 48.678705, lng: 18.2433486 },
    { lat: 48.6784496, lng: 18.2431084 },
    { lat: 48.6783202, lng: 18.2430731 },
    { lat: 48.678059, lng: 18.2427284 },
    { lat: 48.6778988, lng: 18.2427093 },
    { lat: 48.6777307, lng: 18.2427501 },
    { lat: 48.677593, lng: 18.2428852 },
    { lat: 48.6777268, lng: 18.2436297 },
    { lat: 48.677379, lng: 18.2437828 },
    { lat: 48.6771056, lng: 18.2435204 },
    { lat: 48.6769578, lng: 18.2432226 },
    { lat: 48.6766256, lng: 18.2430381 },
    { lat: 48.6761984, lng: 18.2427121 },
    { lat: 48.6760086, lng: 18.2426872 },
    { lat: 48.6757347, lng: 18.2427322 },
    { lat: 48.6756247, lng: 18.2420122 },
    { lat: 48.6749743, lng: 18.242022 },
    { lat: 48.6747851, lng: 18.241458 },
    { lat: 48.6744137, lng: 18.2411569 },
    { lat: 48.6742274, lng: 18.2411162 },
    { lat: 48.6740206, lng: 18.2412085 },
    { lat: 48.6739226, lng: 18.2408603 },
    { lat: 48.6737314, lng: 18.2409277 },
    { lat: 48.6737887, lng: 18.2412016 },
    { lat: 48.6736961, lng: 18.2413365 },
    { lat: 48.6734099, lng: 18.2415045 },
    { lat: 48.6733687, lng: 18.2414331 },
    { lat: 48.6730198, lng: 18.240244 },
    { lat: 48.672891, lng: 18.2400473 },
    { lat: 48.6725525, lng: 18.2396717 },
    { lat: 48.6724947, lng: 18.2395678 },
    { lat: 48.6721658, lng: 18.2378603 },
    { lat: 48.6712491, lng: 18.2380135 },
    { lat: 48.6708312, lng: 18.2380662 },
    { lat: 48.6704317, lng: 18.2381619 },
    { lat: 48.6697537, lng: 18.2382803 },
    { lat: 48.6695114, lng: 18.2382691 },
    { lat: 48.6689856, lng: 18.2383288 },
    { lat: 48.6678343, lng: 18.2384404 },
    { lat: 48.6673826, lng: 18.2384711 },
    { lat: 48.6670565, lng: 18.2384744 },
    { lat: 48.666968, lng: 18.2382675 },
    { lat: 48.666333, lng: 18.238451 },
    { lat: 48.6659583, lng: 18.2386666 },
    { lat: 48.6656769, lng: 18.2388161 },
    { lat: 48.665538, lng: 18.2388713 },
    { lat: 48.6653679, lng: 18.2389149 },
    { lat: 48.6649162, lng: 18.2389888 },
    { lat: 48.6644643, lng: 18.2390578 },
    { lat: 48.6639347, lng: 18.2390908 },
    { lat: 48.6628528, lng: 18.2391762 },
    { lat: 48.6621356, lng: 18.2393601 },
    { lat: 48.6620329, lng: 18.239281 },
    { lat: 48.661868, lng: 18.2393582 },
    { lat: 48.6613799, lng: 18.2396 },
    { lat: 48.6609951, lng: 18.2398379 },
    { lat: 48.6608693, lng: 18.2399246 },
    { lat: 48.6607322, lng: 18.2399957 },
    { lat: 48.6605986, lng: 18.2400874 },
    { lat: 48.6603179, lng: 18.2402318 },
    { lat: 48.660166, lng: 18.2403165 },
    { lat: 48.6599209, lng: 18.2404284 },
    { lat: 48.6598289, lng: 18.2404576 },
    { lat: 48.6594724, lng: 18.240599 },
    { lat: 48.659283, lng: 18.2406619 },
    { lat: 48.6589036, lng: 18.2407749 },
    { lat: 48.6586904, lng: 18.2408334 },
    { lat: 48.6585856, lng: 18.2408738 },
    { lat: 48.6580585, lng: 18.2410503 },
    { lat: 48.6575702, lng: 18.2411743 },
    { lat: 48.6572325, lng: 18.2412265 },
    { lat: 48.6569591, lng: 18.2412418 },
    { lat: 48.6560808, lng: 18.2412328 },
    { lat: 48.6557078, lng: 18.2412222 },
    { lat: 48.6551886, lng: 18.24124 },
    { lat: 48.6550393, lng: 18.2412134 },
    { lat: 48.6548365, lng: 18.2411185 },
    { lat: 48.6542362, lng: 18.2407271 },
    { lat: 48.6537121, lng: 18.2392794 },
    { lat: 48.6536583, lng: 18.2395409 },
    { lat: 48.6535421, lng: 18.2397962 },
    { lat: 48.6533564, lng: 18.2400624 },
    { lat: 48.6531058, lng: 18.2405133 },
    { lat: 48.652991, lng: 18.240533 },
  ],
  Timoradza: [
    { lat: 48.822307, lng: 18.212206 },
    { lat: 48.821847, lng: 18.212641 },
    { lat: 48.821252, lng: 18.212958 },
    { lat: 48.820973, lng: 18.213168 },
    { lat: 48.820638, lng: 18.213289 },
    { lat: 48.819836, lng: 18.213361 },
    { lat: 48.818625, lng: 18.213434 },
    { lat: 48.817588, lng: 18.213313 },
    { lat: 48.816943, lng: 18.213215 },
    { lat: 48.816463, lng: 18.213048 },
    { lat: 48.815977, lng: 18.212871 },
    { lat: 48.81471, lng: 18.212691 },
    { lat: 48.813697, lng: 18.212422 },
    { lat: 48.81343, lng: 18.212277 },
    { lat: 48.81276, lng: 18.212079 },
    { lat: 48.812213, lng: 18.212048 },
    { lat: 48.811477, lng: 18.21213 },
    { lat: 48.811165, lng: 18.212096 },
    { lat: 48.810334, lng: 18.212084 },
    { lat: 48.809657, lng: 18.21205 },
    { lat: 48.808791, lng: 18.21182 },
    { lat: 48.808078, lng: 18.211611 },
    { lat: 48.807622, lng: 18.211575 },
    { lat: 48.806956, lng: 18.211643 },
    { lat: 48.806739, lng: 18.21178 },
    { lat: 48.806106, lng: 18.212643 },
    { lat: 48.805959, lng: 18.213144 },
    { lat: 48.805353, lng: 18.214159 },
    { lat: 48.804819, lng: 18.214753 },
    { lat: 48.804637, lng: 18.215616 },
    { lat: 48.80461, lng: 18.215905 },
    { lat: 48.804486, lng: 18.216574 },
    { lat: 48.804143, lng: 18.217372 },
    { lat: 48.803808, lng: 18.217778 },
    { lat: 48.803274, lng: 18.218288 },
    { lat: 48.802773, lng: 18.218687 },
    { lat: 48.80237, lng: 18.218967 },
    { lat: 48.801676, lng: 18.219448 },
    { lat: 48.801149, lng: 18.219549 },
    { lat: 48.8011427, lng: 18.2196173 },
    { lat: 48.80121, lng: 18.2199632 },
    { lat: 48.8013082, lng: 18.2205388 },
    { lat: 48.8014172, lng: 18.2211201 },
    { lat: 48.8016353, lng: 18.2218528 },
    { lat: 48.8017617, lng: 18.2225249 },
    { lat: 48.8018269, lng: 18.2232934 },
    { lat: 48.801851, lng: 18.2238096 },
    { lat: 48.8019183, lng: 18.2243366 },
    { lat: 48.8019735, lng: 18.2246898 },
    { lat: 48.8020175, lng: 18.2250656 },
    { lat: 48.8020544, lng: 18.225413 },
    { lat: 48.8020863, lng: 18.2257528 },
    { lat: 48.802122, lng: 18.2260824 },
    { lat: 48.8021722, lng: 18.2264066 },
    { lat: 48.8021702, lng: 18.2267694 },
    { lat: 48.8021149, lng: 18.2271822 },
    { lat: 48.8020679, lng: 18.2276014 },
    { lat: 48.802039, lng: 18.228005 },
    { lat: 48.8020568, lng: 18.2284161 },
    { lat: 48.8021059, lng: 18.2292337 },
    { lat: 48.8021483, lng: 18.2298387 },
    { lat: 48.8022017, lng: 18.2304381 },
    { lat: 48.8022718, lng: 18.2310524 },
    { lat: 48.8023561, lng: 18.2316789 },
    { lat: 48.8024557, lng: 18.2323341 },
    { lat: 48.802576, lng: 18.2330153 },
    { lat: 48.8027224, lng: 18.2339811 },
    { lat: 48.802921, lng: 18.2351267 },
    { lat: 48.8032449, lng: 18.236472 },
    { lat: 48.8035304, lng: 18.2380307 },
    { lat: 48.8035491, lng: 18.238102 },
    { lat: 48.8034517, lng: 18.2381362 },
    { lat: 48.8032719, lng: 18.2381497 },
    { lat: 48.803275, lng: 18.2385978 },
    { lat: 48.8031903, lng: 18.2386386 },
    { lat: 48.8031323, lng: 18.2386086 },
    { lat: 48.803089, lng: 18.238542 },
    { lat: 48.8030256, lng: 18.2381851 },
    { lat: 48.8027621, lng: 18.2380958 },
    { lat: 48.8025651, lng: 18.2382404 },
    { lat: 48.8023755, lng: 18.2381206 },
    { lat: 48.8021889, lng: 18.2381156 },
    { lat: 48.8017908, lng: 18.2381953 },
    { lat: 48.8015882, lng: 18.2379483 },
    { lat: 48.8014736, lng: 18.2378781 },
    { lat: 48.8009935, lng: 18.2379259 },
    { lat: 48.8008902, lng: 18.2378557 },
    { lat: 48.8004797, lng: 18.2378604 },
    { lat: 48.8002651, lng: 18.237994 },
    { lat: 48.8001322, lng: 18.2379602 },
    { lat: 48.7999489, lng: 18.2380093 },
    { lat: 48.7997068, lng: 18.2379604 },
    { lat: 48.7995315, lng: 18.2380699 },
    { lat: 48.7994245, lng: 18.2378978 },
    { lat: 48.7994003, lng: 18.2377686 },
    { lat: 48.7991707, lng: 18.237722 },
    { lat: 48.7990459, lng: 18.2376113 },
    { lat: 48.7988779, lng: 18.2375502 },
    { lat: 48.7988609, lng: 18.2375303 },
    { lat: 48.7985284, lng: 18.2376579 },
    { lat: 48.7984791, lng: 18.238058 },
    { lat: 48.7984254, lng: 18.2382053 },
    { lat: 48.7983482, lng: 18.2382366 },
    { lat: 48.7982019, lng: 18.2382006 },
    { lat: 48.7981508, lng: 18.2382753 },
    { lat: 48.7980574, lng: 18.2385366 },
    { lat: 48.798187, lng: 18.239186 },
    { lat: 48.7983618, lng: 18.2393031 },
    { lat: 48.798196, lng: 18.2397582 },
    { lat: 48.7982935, lng: 18.239947 },
    { lat: 48.7983025, lng: 18.240162 },
    { lat: 48.79834, lng: 18.2401682 },
    { lat: 48.7993163, lng: 18.2409438 },
    { lat: 48.7998596, lng: 18.2425349 },
    { lat: 48.8004416, lng: 18.243859 },
    { lat: 48.8004431, lng: 18.2439021 },
    { lat: 48.801168, lng: 18.2432587 },
    { lat: 48.8012751, lng: 18.243909 },
    { lat: 48.8015317, lng: 18.243641 },
    { lat: 48.8017661, lng: 18.2435377 },
    { lat: 48.802043, lng: 18.2435434 },
    { lat: 48.8022122, lng: 18.2435256 },
    { lat: 48.8025667, lng: 18.2433887 },
    { lat: 48.8028163, lng: 18.2432354 },
    { lat: 48.8030009, lng: 18.2431089 },
    { lat: 48.8030221, lng: 18.2432619 },
    { lat: 48.8030232, lng: 18.2439719 },
    { lat: 48.8035001, lng: 18.2451963 },
    { lat: 48.8038226, lng: 18.2461414 },
    { lat: 48.8038696, lng: 18.2466868 },
    { lat: 48.8036084, lng: 18.2478456 },
    { lat: 48.8034372, lng: 18.2489196 },
    { lat: 48.8032546, lng: 18.2502087 },
    { lat: 48.8031462, lng: 18.2511621 },
    { lat: 48.8031589, lng: 18.2516 },
    { lat: 48.8036286, lng: 18.2513693 },
    { lat: 48.8040712, lng: 18.2512958 },
    { lat: 48.8051199, lng: 18.2512433 },
    { lat: 48.8062607, lng: 18.2516828 },
    { lat: 48.8066324, lng: 18.253273 },
    { lat: 48.8066845, lng: 18.2541296 },
    { lat: 48.8070477, lng: 18.254961 },
    { lat: 48.8069165, lng: 18.2554682 },
    { lat: 48.8065956, lng: 18.2567089 },
    { lat: 48.8065298, lng: 18.2569397 },
    { lat: 48.8063225, lng: 18.2582361 },
    { lat: 48.8061667, lng: 18.258948 },
    { lat: 48.8060364, lng: 18.2595323 },
    { lat: 48.8055486, lng: 18.2607958 },
    { lat: 48.8053409, lng: 18.2613739 },
    { lat: 48.8051501, lng: 18.2619047 },
    { lat: 48.8049893, lng: 18.2626061 },
    { lat: 48.8050118, lng: 18.2633806 },
    { lat: 48.8053195, lng: 18.264346 },
    { lat: 48.8062246, lng: 18.2644883 },
    { lat: 48.8061595, lng: 18.2652273 },
    { lat: 48.8061455, lng: 18.2657382 },
    { lat: 48.8060309, lng: 18.2663203 },
    { lat: 48.8059949, lng: 18.2665034 },
    { lat: 48.8052886, lng: 18.2675547 },
    { lat: 48.8052618, lng: 18.2688679 },
    { lat: 48.8052383, lng: 18.269749 },
    { lat: 48.8053311, lng: 18.2701052 },
    { lat: 48.8055841, lng: 18.2708388 },
    { lat: 48.8054709, lng: 18.271518 },
    { lat: 48.8052974, lng: 18.2720586 },
    { lat: 48.8051822, lng: 18.2724326 },
    { lat: 48.8053364, lng: 18.2729247 },
    { lat: 48.8053459, lng: 18.2729384 },
    { lat: 48.8058059, lng: 18.2736031 },
    { lat: 48.8066417, lng: 18.2740742 },
    { lat: 48.8069453, lng: 18.2745949 },
    { lat: 48.8069978, lng: 18.27542 },
    { lat: 48.8070838, lng: 18.276081 },
    { lat: 48.8072734, lng: 18.2774316 },
    { lat: 48.8070104, lng: 18.279063 },
    { lat: 48.8062047, lng: 18.2804731 },
    { lat: 48.8061416, lng: 18.2815076 },
    { lat: 48.8061579, lng: 18.2815785 },
    { lat: 48.806291, lng: 18.282174 },
    { lat: 48.8062449, lng: 18.2827541 },
    { lat: 48.8062499, lng: 18.2833974 },
    { lat: 48.8061438, lng: 18.2841739 },
    { lat: 48.8060475, lng: 18.2846854 },
    { lat: 48.8060973, lng: 18.284985 },
    { lat: 48.8061598, lng: 18.2852324 },
    { lat: 48.8062822, lng: 18.2854438 },
    { lat: 48.8063644, lng: 18.2856962 },
    { lat: 48.8063132, lng: 18.2864284 },
    { lat: 48.8062597, lng: 18.2870582 },
    { lat: 48.8062559, lng: 18.2876035 },
    { lat: 48.806122, lng: 18.2884495 },
    { lat: 48.8058379, lng: 18.288752 },
    { lat: 48.8057429, lng: 18.2894462 },
    { lat: 48.805678, lng: 18.2899324 },
    { lat: 48.8059324, lng: 18.2915574 },
    { lat: 48.8059957, lng: 18.2915197 },
    { lat: 48.8064721, lng: 18.2912357 },
    { lat: 48.8070388, lng: 18.2908979 },
    { lat: 48.8071592, lng: 18.2908262 },
    { lat: 48.808457, lng: 18.2899322 },
    { lat: 48.8094893, lng: 18.2895955 },
    { lat: 48.810631, lng: 18.2890736 },
    { lat: 48.8111976, lng: 18.2889367 },
    { lat: 48.8116693, lng: 18.2887588 },
    { lat: 48.8122533, lng: 18.2884123 },
    { lat: 48.8128636, lng: 18.2875158 },
    { lat: 48.8132667, lng: 18.2869805 },
    { lat: 48.8134141, lng: 18.2866581 },
    { lat: 48.8136156, lng: 18.2858913 },
    { lat: 48.8136316, lng: 18.2852011 },
    { lat: 48.8138293, lng: 18.284584 },
    { lat: 48.8145135, lng: 18.2839581 },
    { lat: 48.8145259, lng: 18.283639 },
    { lat: 48.8148939, lng: 18.283482 },
    { lat: 48.8152041, lng: 18.2831818 },
    { lat: 48.8155423, lng: 18.282821 },
    { lat: 48.8159348, lng: 18.2819609 },
    { lat: 48.8161873, lng: 18.2814193 },
    { lat: 48.8167039, lng: 18.2810772 },
    { lat: 48.8173314, lng: 18.2801858 },
    { lat: 48.8169632, lng: 18.2792448 },
    { lat: 48.8166546, lng: 18.2785155 },
    { lat: 48.8166982, lng: 18.2782507 },
    { lat: 48.8167661, lng: 18.2778759 },
    { lat: 48.8167181, lng: 18.2768919 },
    { lat: 48.8164501, lng: 18.2766429 },
    { lat: 48.8158895, lng: 18.2761895 },
    { lat: 48.8154563, lng: 18.2756125 },
    { lat: 48.8150238, lng: 18.2746331 },
    { lat: 48.8156153, lng: 18.2739826 },
    { lat: 48.8158424, lng: 18.2737257 },
    { lat: 48.8161903, lng: 18.2733775 },
    { lat: 48.816691, lng: 18.2724191 },
    { lat: 48.816856, lng: 18.2719268 },
    { lat: 48.8170636, lng: 18.2712904 },
    { lat: 48.8171778, lng: 18.270141 },
    { lat: 48.8176412, lng: 18.2695196 },
    { lat: 48.8180021, lng: 18.2692965 },
    { lat: 48.8182166, lng: 18.2686241 },
    { lat: 48.818475, lng: 18.2680906 },
    { lat: 48.818619, lng: 18.2667926 },
    { lat: 48.8189927, lng: 18.2664163 },
    { lat: 48.8191822, lng: 18.2657231 },
    { lat: 48.8192076, lng: 18.2650961 },
    { lat: 48.8194549, lng: 18.264781 },
    { lat: 48.8196396, lng: 18.2643978 },
    { lat: 48.8198276, lng: 18.2640948 },
    { lat: 48.820072, lng: 18.2640858 },
    { lat: 48.8201721, lng: 18.2639002 },
    { lat: 48.8202174, lng: 18.2634485 },
    { lat: 48.8202372, lng: 18.2634613 },
    { lat: 48.820375, lng: 18.2633223 },
    { lat: 48.8205592, lng: 18.2633474 },
    { lat: 48.8208467, lng: 18.2636601 },
    { lat: 48.8212066, lng: 18.2638026 },
    { lat: 48.8216066, lng: 18.2636931 },
    { lat: 48.8221956, lng: 18.2637105 },
    { lat: 48.8228081, lng: 18.2640238 },
    { lat: 48.8228097, lng: 18.2639939 },
    { lat: 48.8230536, lng: 18.2636223 },
    { lat: 48.8237185, lng: 18.2631911 },
    { lat: 48.8240853, lng: 18.263014 },
    { lat: 48.8242353, lng: 18.2628115 },
    { lat: 48.8247638, lng: 18.2624257 },
    { lat: 48.8253516, lng: 18.2611735 },
    { lat: 48.8259166, lng: 18.2610271 },
    { lat: 48.8262155, lng: 18.26037 },
    { lat: 48.8264428, lng: 18.2598652 },
    { lat: 48.8265891, lng: 18.2588076 },
    { lat: 48.8272026, lng: 18.25791 },
    { lat: 48.8276326, lng: 18.257277 },
    { lat: 48.8273359, lng: 18.2556305 },
    { lat: 48.8275217, lng: 18.2543174 },
    { lat: 48.8275302, lng: 18.2540305 },
    { lat: 48.8275616, lng: 18.2526183 },
    { lat: 48.8268643, lng: 18.2512505 },
    { lat: 48.8255274, lng: 18.2450747 },
    { lat: 48.8248787, lng: 18.2439112 },
    { lat: 48.8240338, lng: 18.2425081 },
    { lat: 48.8238654, lng: 18.241515 },
    { lat: 48.8237769, lng: 18.2409138 },
    { lat: 48.8236823, lng: 18.2403028 },
    { lat: 48.8234527, lng: 18.2393624 },
    { lat: 48.8231473, lng: 18.2380839 },
    { lat: 48.82264, lng: 18.2374385 },
    { lat: 48.8222199, lng: 18.2371175 },
    { lat: 48.8217153, lng: 18.236876 },
    { lat: 48.8209187, lng: 18.2366404 },
    { lat: 48.8208248, lng: 18.2364576 },
    { lat: 48.820576, lng: 18.2365843 },
    { lat: 48.8202263, lng: 18.2369248 },
    { lat: 48.8197922, lng: 18.2372645 },
    { lat: 48.8191644, lng: 18.2376531 },
    { lat: 48.8185631, lng: 18.2380294 },
    { lat: 48.8180515, lng: 18.2383369 },
    { lat: 48.8176973, lng: 18.2366178 },
    { lat: 48.8174487, lng: 18.2354269 },
    { lat: 48.8175821, lng: 18.2353532 },
    { lat: 48.817251, lng: 18.2340177 },
    { lat: 48.8169844, lng: 18.2328496 },
    { lat: 48.8170918, lng: 18.2327758 },
    { lat: 48.8173606, lng: 18.2326206 },
    { lat: 48.8173965, lng: 18.2326542 },
    { lat: 48.8173774, lng: 18.2327169 },
    { lat: 48.8173794, lng: 18.232731 },
    { lat: 48.8173955, lng: 18.2327564 },
    { lat: 48.8174168, lng: 18.2327733 },
    { lat: 48.8174289, lng: 18.2327707 },
    { lat: 48.8175061, lng: 18.232719 },
    { lat: 48.81763, lng: 18.2326144 },
    { lat: 48.8178091, lng: 18.2325089 },
    { lat: 48.8178796, lng: 18.2323808 },
    { lat: 48.8179588, lng: 18.2322662 },
    { lat: 48.818007, lng: 18.2322445 },
    { lat: 48.8180318, lng: 18.2322438 },
    { lat: 48.8180537, lng: 18.2322509 },
    { lat: 48.8180754, lng: 18.2322739 },
    { lat: 48.8181246, lng: 18.2324035 },
    { lat: 48.8181284, lng: 18.2325319 },
    { lat: 48.8181013, lng: 18.2328072 },
    { lat: 48.8181029, lng: 18.2328342 },
    { lat: 48.8181088, lng: 18.2328596 },
    { lat: 48.8181347, lng: 18.2328984 },
    { lat: 48.8182022, lng: 18.2329329 },
    { lat: 48.8182993, lng: 18.2329524 },
    { lat: 48.8183201, lng: 18.232945 },
    { lat: 48.8183354, lng: 18.2329344 },
    { lat: 48.8183466, lng: 18.2329111 },
    { lat: 48.8183642, lng: 18.2328479 },
    { lat: 48.8183795, lng: 18.2328377 },
    { lat: 48.8184172, lng: 18.2328274 },
    { lat: 48.8187272, lng: 18.232819 },
    { lat: 48.8187498, lng: 18.2328422 },
    { lat: 48.8187671, lng: 18.2328686 },
    { lat: 48.8187752, lng: 18.2329108 },
    { lat: 48.818728, lng: 18.2333474 },
    { lat: 48.8187309, lng: 18.2333982 },
    { lat: 48.8187436, lng: 18.2334424 },
    { lat: 48.8187603, lng: 18.2334771 },
    { lat: 48.8187883, lng: 18.2334956 },
    { lat: 48.8189172, lng: 18.233459 },
    { lat: 48.8189611, lng: 18.2334214 },
    { lat: 48.8190212, lng: 18.2333258 },
    { lat: 48.8190392, lng: 18.2332272 },
    { lat: 48.8190266, lng: 18.2329452 },
    { lat: 48.8190405, lng: 18.2327952 },
    { lat: 48.8190631, lng: 18.2327346 },
    { lat: 48.8190806, lng: 18.2327061 },
    { lat: 48.8193154, lng: 18.2327058 },
    { lat: 48.8192941, lng: 18.2326309 },
    { lat: 48.8192949, lng: 18.2325463 },
    { lat: 48.8197303, lng: 18.2320098 },
    { lat: 48.8198448, lng: 18.2318613 },
    { lat: 48.8198911, lng: 18.231761 },
    { lat: 48.8199107, lng: 18.2316904 },
    { lat: 48.8199146, lng: 18.231565 },
    { lat: 48.8197764, lng: 18.2305866 },
    { lat: 48.819645, lng: 18.2297255 },
    { lat: 48.8195893, lng: 18.2293855 },
    { lat: 48.8195002, lng: 18.2287516 },
    { lat: 48.8194146, lng: 18.2280442 },
    { lat: 48.8193207, lng: 18.2271925 },
    { lat: 48.8200117, lng: 18.2269615 },
    { lat: 48.8200009, lng: 18.2260255 },
    { lat: 48.8199862, lng: 18.2250184 },
    { lat: 48.8203147, lng: 18.2242105 },
    { lat: 48.8205058, lng: 18.2240207 },
    { lat: 48.8208305, lng: 18.2239388 },
    { lat: 48.8208651, lng: 18.2227673 },
    { lat: 48.8208713, lng: 18.2224997 },
    { lat: 48.8214763, lng: 18.2224844 },
    { lat: 48.8221608, lng: 18.222555 },
    { lat: 48.8221442, lng: 18.2217526 },
    { lat: 48.8222817, lng: 18.2217507 },
    { lat: 48.8222973, lng: 18.2206013 },
    { lat: 48.822729, lng: 18.2207903 },
    { lat: 48.8229496, lng: 18.2195181 },
    { lat: 48.8233135, lng: 18.2195298 },
    { lat: 48.8232605, lng: 18.2185466 },
    { lat: 48.8232457, lng: 18.2179695 },
    { lat: 48.8232445, lng: 18.2173162 },
    { lat: 48.8233003, lng: 18.2164412 },
    { lat: 48.8232836, lng: 18.2159714 },
    { lat: 48.8233165, lng: 18.2156584 },
    { lat: 48.8233036, lng: 18.2153965 },
    { lat: 48.8232433, lng: 18.2149715 },
    { lat: 48.8230682, lng: 18.2138254 },
    { lat: 48.8229907, lng: 18.2134803 },
    { lat: 48.8228576, lng: 18.2133399 },
    { lat: 48.8227111, lng: 18.2130483 },
    { lat: 48.8224214, lng: 18.2125231 },
    { lat: 48.822307, lng: 18.212206 },
  ],
  Trebichava: [
    { lat: 48.8405467, lng: 18.3050149 },
    { lat: 48.8395653, lng: 18.3046962 },
    { lat: 48.838875, lng: 18.3040406 },
    { lat: 48.8380789, lng: 18.304407 },
    { lat: 48.8377249, lng: 18.3042939 },
    { lat: 48.8373334, lng: 18.3038325 },
    { lat: 48.8370732, lng: 18.3035413 },
    { lat: 48.836554, lng: 18.3030768 },
    { lat: 48.8358792, lng: 18.3031564 },
    { lat: 48.8358582, lng: 18.3031229 },
    { lat: 48.8356268, lng: 18.3033033 },
    { lat: 48.8347567, lng: 18.3024617 },
    { lat: 48.8343597, lng: 18.3021417 },
    { lat: 48.8337851, lng: 18.3018563 },
    { lat: 48.8323796, lng: 18.3005064 },
    { lat: 48.8315704, lng: 18.2995023 },
    { lat: 48.8309187, lng: 18.2984713 },
    { lat: 48.8308318, lng: 18.2982322 },
    { lat: 48.8307218, lng: 18.2975446 },
    { lat: 48.8306605, lng: 18.2960417 },
    { lat: 48.8304949, lng: 18.2951074 },
    { lat: 48.8300757, lng: 18.2939793 },
    { lat: 48.8300054, lng: 18.2940529 },
    { lat: 48.8293159, lng: 18.2928525 },
    { lat: 48.8288014, lng: 18.2924169 },
    { lat: 48.827879, lng: 18.2919271 },
    { lat: 48.8272687, lng: 18.2912483 },
    { lat: 48.8268868, lng: 18.2905827 },
    { lat: 48.8261961, lng: 18.2900214 },
    { lat: 48.8258134, lng: 18.2898387 },
    { lat: 48.8255422, lng: 18.289551 },
    { lat: 48.8252191, lng: 18.2887038 },
    { lat: 48.8251823, lng: 18.2886144 },
    { lat: 48.8247578, lng: 18.2874553 },
    { lat: 48.8247492, lng: 18.2874411 },
    { lat: 48.8247408, lng: 18.2874146 },
    { lat: 48.8247298, lng: 18.2873988 },
    { lat: 48.8242132, lng: 18.287062 },
    { lat: 48.8237375, lng: 18.2864148 },
    { lat: 48.8237089, lng: 18.2863649 },
    { lat: 48.8231784, lng: 18.2865032 },
    { lat: 48.8227391, lng: 18.2866946 },
    { lat: 48.8221795, lng: 18.2864361 },
    { lat: 48.8220451, lng: 18.2865144 },
    { lat: 48.821708, lng: 18.2864357 },
    { lat: 48.8212803, lng: 18.2864942 },
    { lat: 48.8209266, lng: 18.2865057 },
    { lat: 48.8203278, lng: 18.2862319 },
    { lat: 48.8198321, lng: 18.2850908 },
    { lat: 48.8196665, lng: 18.2846541 },
    { lat: 48.8191242, lng: 18.2839082 },
    { lat: 48.8187226, lng: 18.2836106 },
    { lat: 48.8183687, lng: 18.2831761 },
    { lat: 48.8181821, lng: 18.282634 },
    { lat: 48.8179714, lng: 18.2816603 },
    { lat: 48.8176156, lng: 18.2809601 },
    { lat: 48.8173314, lng: 18.2801858 },
    { lat: 48.8167039, lng: 18.2810772 },
    { lat: 48.8161873, lng: 18.2814193 },
    { lat: 48.8159348, lng: 18.2819609 },
    { lat: 48.8155423, lng: 18.282821 },
    { lat: 48.8152041, lng: 18.2831818 },
    { lat: 48.8148939, lng: 18.283482 },
    { lat: 48.8145259, lng: 18.283639 },
    { lat: 48.8145135, lng: 18.2839581 },
    { lat: 48.8138293, lng: 18.284584 },
    { lat: 48.8136316, lng: 18.2852011 },
    { lat: 48.8136156, lng: 18.2858913 },
    { lat: 48.8134141, lng: 18.2866581 },
    { lat: 48.8132667, lng: 18.2869805 },
    { lat: 48.8128636, lng: 18.2875158 },
    { lat: 48.8122533, lng: 18.2884123 },
    { lat: 48.8116693, lng: 18.2887588 },
    { lat: 48.8111976, lng: 18.2889367 },
    { lat: 48.810631, lng: 18.2890736 },
    { lat: 48.8094893, lng: 18.2895955 },
    { lat: 48.808457, lng: 18.2899322 },
    { lat: 48.8071592, lng: 18.2908262 },
    { lat: 48.8070388, lng: 18.2908979 },
    { lat: 48.8064721, lng: 18.2912357 },
    { lat: 48.8059957, lng: 18.2915197 },
    { lat: 48.8059324, lng: 18.2915574 },
    { lat: 48.8060599, lng: 18.2924982 },
    { lat: 48.8062714, lng: 18.294059 },
    { lat: 48.806459, lng: 18.2958992 },
    { lat: 48.8073918, lng: 18.2982445 },
    { lat: 48.8075442, lng: 18.2992849 },
    { lat: 48.8063986, lng: 18.2993797 },
    { lat: 48.8055607, lng: 18.2997049 },
    { lat: 48.8052307, lng: 18.2996717 },
    { lat: 48.8045572, lng: 18.3001123 },
    { lat: 48.804024, lng: 18.3010816 },
    { lat: 48.8035123, lng: 18.303247 },
    { lat: 48.8030412, lng: 18.3052909 },
    { lat: 48.8025406, lng: 18.307337 },
    { lat: 48.802263, lng: 18.3090147 },
    { lat: 48.8027203, lng: 18.3096774 },
    { lat: 48.8032471, lng: 18.3102975 },
    { lat: 48.8034032, lng: 18.3104827 },
    { lat: 48.8037174, lng: 18.3108526 },
    { lat: 48.8038401, lng: 18.3109684 },
    { lat: 48.8039757, lng: 18.3110926 },
    { lat: 48.8043672, lng: 18.3116497 },
    { lat: 48.804383, lng: 18.3120718 },
    { lat: 48.8041988, lng: 18.3142463 },
    { lat: 48.805108, lng: 18.3169449 },
    { lat: 48.8059692, lng: 18.3180491 },
    { lat: 48.8059133, lng: 18.3170792 },
    { lat: 48.8059173, lng: 18.316991 },
    { lat: 48.8059052, lng: 18.316936 },
    { lat: 48.8061334, lng: 18.3166012 },
    { lat: 48.8063893, lng: 18.3165151 },
    { lat: 48.8066285, lng: 18.3166177 },
    { lat: 48.8074115, lng: 18.3173555 },
    { lat: 48.807618, lng: 18.3175965 },
    { lat: 48.8082046, lng: 18.3180537 },
    { lat: 48.8089487, lng: 18.3181487 },
    { lat: 48.8094711, lng: 18.3185494 },
    { lat: 48.8100449, lng: 18.318817 },
    { lat: 48.8104013, lng: 18.318698 },
    { lat: 48.8103923, lng: 18.3181779 },
    { lat: 48.8107664, lng: 18.3180286 },
    { lat: 48.811072, lng: 18.318365 },
    { lat: 48.8112113, lng: 18.3184347 },
    { lat: 48.8117248, lng: 18.3180566 },
    { lat: 48.8121612, lng: 18.3181151 },
    { lat: 48.8125407, lng: 18.318182 },
    { lat: 48.8130036, lng: 18.3190887 },
    { lat: 48.8134211, lng: 18.3202912 },
    { lat: 48.8139407, lng: 18.3204632 },
    { lat: 48.8143761, lng: 18.3202971 },
    { lat: 48.8148205, lng: 18.3200775 },
    { lat: 48.81519, lng: 18.3202657 },
    { lat: 48.8156232, lng: 18.3208251 },
    { lat: 48.8157895, lng: 18.3209536 },
    { lat: 48.8165364, lng: 18.3213955 },
    { lat: 48.8171032, lng: 18.3218464 },
    { lat: 48.8177256, lng: 18.3219941 },
    { lat: 48.8182673, lng: 18.3226795 },
    { lat: 48.8186594, lng: 18.3234129 },
    { lat: 48.8189611, lng: 18.3237624 },
    { lat: 48.8196405, lng: 18.323366 },
    { lat: 48.8201952, lng: 18.3233366 },
    { lat: 48.8205647, lng: 18.3234468 },
    { lat: 48.8213287, lng: 18.3238099 },
    { lat: 48.8216065, lng: 18.3239778 },
    { lat: 48.821907, lng: 18.3241825 },
    { lat: 48.822344, lng: 18.3245496 },
    { lat: 48.8230125, lng: 18.3250229 },
    { lat: 48.8239872, lng: 18.325564 },
    { lat: 48.8241657, lng: 18.3261694 },
    { lat: 48.8246016, lng: 18.3271554 },
    { lat: 48.8248296, lng: 18.3279584 },
    { lat: 48.8249483, lng: 18.3283882 },
    { lat: 48.825141, lng: 18.3290228 },
    { lat: 48.8254779, lng: 18.3304384 },
    { lat: 48.8262757, lng: 18.3302807 },
    { lat: 48.8266037, lng: 18.33031 },
    { lat: 48.8268105, lng: 18.3303023 },
    { lat: 48.8268634, lng: 18.3304681 },
    { lat: 48.8269694, lng: 18.3304794 },
    { lat: 48.8270984, lng: 18.3307056 },
    { lat: 48.8273228, lng: 18.3311267 },
    { lat: 48.8274627, lng: 18.3314653 },
    { lat: 48.8277221, lng: 18.3316635 },
    { lat: 48.8278355, lng: 18.3316333 },
    { lat: 48.8279046, lng: 18.3317019 },
    { lat: 48.8280815, lng: 18.331678 },
    { lat: 48.8282728, lng: 18.331729 },
    { lat: 48.8284727, lng: 18.3317314 },
    { lat: 48.8286327, lng: 18.3318103 },
    { lat: 48.8288604, lng: 18.3319657 },
    { lat: 48.8289789, lng: 18.3320675 },
    { lat: 48.8291593, lng: 18.3321055 },
    { lat: 48.8292712, lng: 18.3321319 },
    { lat: 48.8294085, lng: 18.3320801 },
    { lat: 48.8296265, lng: 18.3323345 },
    { lat: 48.829745, lng: 18.3323085 },
    { lat: 48.8300709, lng: 18.332665 },
    { lat: 48.8305092, lng: 18.3322346 },
    { lat: 48.8316301, lng: 18.3319632 },
    { lat: 48.8322518, lng: 18.3312048 },
    { lat: 48.8326167, lng: 18.3318531 },
    { lat: 48.8332974, lng: 18.3333063 },
    { lat: 48.8339482, lng: 18.3343858 },
    { lat: 48.8345041, lng: 18.3352624 },
    { lat: 48.8352528, lng: 18.3360766 },
    { lat: 48.835513, lng: 18.3363543 },
    { lat: 48.8358461, lng: 18.3368367 },
    { lat: 48.8370065, lng: 18.3375531 },
    { lat: 48.8372383, lng: 18.3376399 },
    { lat: 48.8375999, lng: 18.3376134 },
    { lat: 48.8381241, lng: 18.3375418 },
    { lat: 48.838462, lng: 18.3372741 },
    { lat: 48.8391116, lng: 18.3370277 },
    { lat: 48.8399261, lng: 18.3374667 },
    { lat: 48.8406304, lng: 18.3375554 },
    { lat: 48.8420312, lng: 18.3373662 },
    { lat: 48.8428828, lng: 18.3380666 },
    { lat: 48.8431242, lng: 18.3382858 },
    { lat: 48.8436173, lng: 18.3391295 },
    { lat: 48.8440425, lng: 18.3399329 },
    { lat: 48.8451293, lng: 18.3405386 },
    { lat: 48.8460161, lng: 18.3410229 },
    { lat: 48.8473539, lng: 18.3419226 },
    { lat: 48.8480582, lng: 18.3408614 },
    { lat: 48.8486638, lng: 18.3387093 },
    { lat: 48.8482191, lng: 18.3366968 },
    { lat: 48.8482252, lng: 18.3358617 },
    { lat: 48.8483993, lng: 18.3353639 },
    { lat: 48.8484141, lng: 18.3348866 },
    { lat: 48.84842, lng: 18.3340659 },
    { lat: 48.8479351, lng: 18.3319838 },
    { lat: 48.8479353, lng: 18.3309808 },
    { lat: 48.8479703, lng: 18.3303802 },
    { lat: 48.8475602, lng: 18.3293889 },
    { lat: 48.8473811, lng: 18.3289718 },
    { lat: 48.8472003, lng: 18.3286488 },
    { lat: 48.8468576, lng: 18.3272684 },
    { lat: 48.8462608, lng: 18.3245016 },
    { lat: 48.8462747, lng: 18.323345 },
    { lat: 48.8462593, lng: 18.3224876 },
    { lat: 48.8462539, lng: 18.3215104 },
    { lat: 48.8462548, lng: 18.3212653 },
    { lat: 48.8461786, lng: 18.3200343 },
    { lat: 48.8464074, lng: 18.3195096 },
    { lat: 48.8465452, lng: 18.3184301 },
    { lat: 48.8466666, lng: 18.3180253 },
    { lat: 48.8469524, lng: 18.3177298 },
    { lat: 48.8470551, lng: 18.317408 },
    { lat: 48.8484393, lng: 18.3162034 },
    { lat: 48.8474649, lng: 18.3152906 },
    { lat: 48.8463781, lng: 18.3138246 },
    { lat: 48.8449336, lng: 18.3123996 },
    { lat: 48.8438265, lng: 18.3104919 },
    { lat: 48.8432759, lng: 18.3094908 },
    { lat: 48.8429443, lng: 18.3090734 },
    { lat: 48.8418884, lng: 18.3076363 },
    { lat: 48.8414698, lng: 18.3070773 },
    { lat: 48.8409757, lng: 18.3062187 },
    { lat: 48.8405467, lng: 18.3050149 },
  ],
  VeľkéChlievany: [
    { lat: 48.6985301, lng: 18.2380423 },
    { lat: 48.698568, lng: 18.2380352 },
    { lat: 48.6987068, lng: 18.2379367 },
    { lat: 48.6988825, lng: 18.2378124 },
    { lat: 48.6989237, lng: 18.2377834 },
    { lat: 48.6991698, lng: 18.2376091 },
    { lat: 48.6992263, lng: 18.2375692 },
    { lat: 48.6993346, lng: 18.2374908 },
    { lat: 48.6995471, lng: 18.2373391 },
    { lat: 48.6996761, lng: 18.2372458 },
    { lat: 48.6997942, lng: 18.2371602 },
    { lat: 48.6998537, lng: 18.2371165 },
    { lat: 48.7001008, lng: 18.2369385 },
    { lat: 48.700116, lng: 18.236928 },
    { lat: 48.7001953, lng: 18.2368748 },
    { lat: 48.7002236, lng: 18.2368557 },
    { lat: 48.7002615, lng: 18.236829 },
    { lat: 48.7003734, lng: 18.2367522 },
    { lat: 48.7005101, lng: 18.2366579 },
    { lat: 48.7006611, lng: 18.2365551 },
    { lat: 48.7007078, lng: 18.2365231 },
    { lat: 48.7007926, lng: 18.236466 },
    { lat: 48.7008262, lng: 18.2364423 },
    { lat: 48.7008782, lng: 18.236405 },
    { lat: 48.7009302, lng: 18.2363669 },
    { lat: 48.7009714, lng: 18.236339 },
    { lat: 48.7010245, lng: 18.2363001 },
    { lat: 48.7010538, lng: 18.236279 },
    { lat: 48.7012348, lng: 18.2361496 },
    { lat: 48.7013204, lng: 18.2360877 },
    { lat: 48.7013898, lng: 18.2360376 },
    { lat: 48.7014603, lng: 18.2359877 },
    { lat: 48.7015892, lng: 18.2358949 },
    { lat: 48.7017257, lng: 18.2357962 },
    { lat: 48.7017822, lng: 18.2357559 },
    { lat: 48.701806, lng: 18.2357392 },
    { lat: 48.7018819, lng: 18.2356854 },
    { lat: 48.7019187, lng: 18.2356588 },
    { lat: 48.7019578, lng: 18.2356312 },
    { lat: 48.7020413, lng: 18.2355721 },
    { lat: 48.7023319, lng: 18.2353653 },
    { lat: 48.7023882, lng: 18.2353253 },
    { lat: 48.702436, lng: 18.2352912 },
    { lat: 48.702462, lng: 18.2352724 },
    { lat: 48.7025508, lng: 18.2352097 },
    { lat: 48.702655, lng: 18.2351361 },
    { lat: 48.7027125, lng: 18.2350948 },
    { lat: 48.702771, lng: 18.2350531 },
    { lat: 48.702822, lng: 18.2350166 },
    { lat: 48.7028826, lng: 18.2349733 },
    { lat: 48.7029781, lng: 18.2349055 },
    { lat: 48.7030919, lng: 18.234824 },
    { lat: 48.7031895, lng: 18.2347551 },
    { lat: 48.703209, lng: 18.2347412 },
    { lat: 48.7032567, lng: 18.2347074 },
    { lat: 48.703298, lng: 18.2346795 },
    { lat: 48.7033522, lng: 18.234641 },
    { lat: 48.703401, lng: 18.2346068 },
    { lat: 48.703504, lng: 18.2345337 },
    { lat: 48.7035931, lng: 18.2344718 },
    { lat: 48.7036917, lng: 18.2344018 },
    { lat: 48.7037406, lng: 18.2343674 },
    { lat: 48.7038012, lng: 18.2343244 },
    { lat: 48.7038555, lng: 18.234287 },
    { lat: 48.7038924, lng: 18.2342605 },
    { lat: 48.7039358, lng: 18.2342306 },
    { lat: 48.7040205, lng: 18.2341722 },
    { lat: 48.7042255, lng: 18.2340282 },
    { lat: 48.704333, lng: 18.2339542 },
    { lat: 48.7043634, lng: 18.2339334 },
    { lat: 48.7043916, lng: 18.233914 },
    { lat: 48.7045186, lng: 18.2338254 },
    { lat: 48.7045543, lng: 18.2338002 },
    { lat: 48.7046671, lng: 18.2337203 },
    { lat: 48.7047918, lng: 18.2336317 },
    { lat: 48.7052235, lng: 18.2333277 },
    { lat: 48.7053243, lng: 18.2332563 },
    { lat: 48.7061009, lng: 18.2327079 },
    { lat: 48.7062005, lng: 18.2326343 },
    { lat: 48.7062898, lng: 18.2325784 },
    { lat: 48.7063306, lng: 18.2325432 },
    { lat: 48.7062941, lng: 18.2325089 },
    { lat: 48.7062025, lng: 18.2322891 },
    { lat: 48.7058073, lng: 18.2319022 },
    { lat: 48.7056954, lng: 18.2316876 },
    { lat: 48.7054609, lng: 18.2315506 },
    { lat: 48.7053547, lng: 18.2314161 },
    { lat: 48.7052918, lng: 18.2312613 },
    { lat: 48.7052277, lng: 18.2307613 },
    { lat: 48.7052122, lng: 18.2306405 },
    { lat: 48.7051164, lng: 18.230092 },
    { lat: 48.7051004, lng: 18.2300256 },
    { lat: 48.7051618, lng: 18.2298965 },
    { lat: 48.7051968, lng: 18.2297015 },
    { lat: 48.7051483, lng: 18.2294049 },
    { lat: 48.7051105, lng: 18.2292161 },
    { lat: 48.7050898, lng: 18.2291128 },
    { lat: 48.7051341, lng: 18.2289991 },
    { lat: 48.7051209, lng: 18.2289676 },
    { lat: 48.7051182, lng: 18.2289401 },
    { lat: 48.7051142, lng: 18.2289088 },
    { lat: 48.7050981, lng: 18.2288617 },
    { lat: 48.7050277, lng: 18.2287394 },
    { lat: 48.7049963, lng: 18.2285838 },
    { lat: 48.7048984, lng: 18.228394 },
    { lat: 48.7048489, lng: 18.2282769 },
    { lat: 48.7048243, lng: 18.2281615 },
    { lat: 48.7046185, lng: 18.2276801 },
    { lat: 48.7045553, lng: 18.2274233 },
    { lat: 48.7045364, lng: 18.2272539 },
    { lat: 48.7045345, lng: 18.227122 },
    { lat: 48.7045398, lng: 18.2270602 },
    { lat: 48.7045493, lng: 18.2269479 },
    { lat: 48.7045761, lng: 18.226804 },
    { lat: 48.7045798, lng: 18.2267906 },
    { lat: 48.7045917, lng: 18.2267423 },
    { lat: 48.7045954, lng: 18.2267286 },
    { lat: 48.7046313, lng: 18.226587 },
    { lat: 48.7047013, lng: 18.2264107 },
    { lat: 48.7047847, lng: 18.226292 },
    { lat: 48.7049049, lng: 18.2261211 },
    { lat: 48.7049949, lng: 18.2259593 },
    { lat: 48.7052263, lng: 18.2256479 },
    { lat: 48.7054966, lng: 18.2253807 },
    { lat: 48.7057054, lng: 18.2252362 },
    { lat: 48.7057461, lng: 18.225208 },
    { lat: 48.7058634, lng: 18.2251051 },
    { lat: 48.706182, lng: 18.2249331 },
    { lat: 48.706218, lng: 18.2249137 },
    { lat: 48.7062758, lng: 18.2248615 },
    { lat: 48.7064955, lng: 18.224677 },
    { lat: 48.7065506, lng: 18.2246365 },
    { lat: 48.7065842, lng: 18.2245863 },
    { lat: 48.7066192, lng: 18.2245339 },
    { lat: 48.7066504, lng: 18.2244666 },
    { lat: 48.7066852, lng: 18.2243649 },
    { lat: 48.706688, lng: 18.2243556 },
    { lat: 48.7067036, lng: 18.22431 },
    { lat: 48.7067074, lng: 18.2242977 },
    { lat: 48.7066735, lng: 18.2241807 },
    { lat: 48.7064671, lng: 18.2237479 },
    { lat: 48.706453, lng: 18.2236289 },
    { lat: 48.706408, lng: 18.2232497 },
    { lat: 48.7064298, lng: 18.2230821 },
    { lat: 48.7064456, lng: 18.2229601 },
    { lat: 48.7064671, lng: 18.2228451 },
    { lat: 48.7064957, lng: 18.2227499 },
    { lat: 48.7065193, lng: 18.222551 },
    { lat: 48.7065428, lng: 18.222195 },
    { lat: 48.7065329, lng: 18.2218279 },
    { lat: 48.7065202, lng: 18.2217221 },
    { lat: 48.7065113, lng: 18.2215464 },
    { lat: 48.7064436, lng: 18.2211162 },
    { lat: 48.7067254, lng: 18.2207774 },
    { lat: 48.7066072, lng: 18.2206414 },
    { lat: 48.7065893, lng: 18.2205676 },
    { lat: 48.7065836, lng: 18.2205055 },
    { lat: 48.7065822, lng: 18.220366 },
    { lat: 48.7065764, lng: 18.2203225 },
    { lat: 48.7065634, lng: 18.2202942 },
    { lat: 48.7065062, lng: 18.2202195 },
    { lat: 48.7063715, lng: 18.219957 },
    { lat: 48.7063713, lng: 18.2199338 },
    { lat: 48.7064282, lng: 18.2197056 },
    { lat: 48.7064104, lng: 18.2196918 },
    { lat: 48.7063479, lng: 18.2196815 },
    { lat: 48.7062916, lng: 18.2196863 },
    { lat: 48.7062257, lng: 18.2196374 },
    { lat: 48.7061858, lng: 18.2195884 },
    { lat: 48.7061838, lng: 18.2195175 },
    { lat: 48.7062711, lng: 18.2192496 },
    { lat: 48.7063005, lng: 18.2190532 },
    { lat: 48.7063177, lng: 18.2189984 },
    { lat: 48.7063643, lng: 18.2189462 },
    { lat: 48.706458, lng: 18.2188682 },
    { lat: 48.7065186, lng: 18.2188403 },
    { lat: 48.7065535, lng: 18.2188596 },
    { lat: 48.7066128, lng: 18.2189294 },
    { lat: 48.7066893, lng: 18.2188644 },
    { lat: 48.7068515, lng: 18.2185677 },
    { lat: 48.7068975, lng: 18.2185041 },
    { lat: 48.7069886, lng: 18.2184604 },
    { lat: 48.7070316, lng: 18.2184015 },
    { lat: 48.7070744, lng: 18.2183037 },
    { lat: 48.7071354, lng: 18.218344 },
    { lat: 48.7071346, lng: 18.2182677 },
    { lat: 48.7071147, lng: 18.2181767 },
    { lat: 48.7070994, lng: 18.2181076 },
    { lat: 48.7071127, lng: 18.2180844 },
    { lat: 48.7071456, lng: 18.2180268 },
    { lat: 48.7071781, lng: 18.2179683 },
    { lat: 48.7072121, lng: 18.2179084 },
    { lat: 48.7072469, lng: 18.2178478 },
    { lat: 48.7072817, lng: 18.2177861 },
    { lat: 48.7073187, lng: 18.2177223 },
    { lat: 48.7073545, lng: 18.2176579 },
    { lat: 48.7073926, lng: 18.2175922 },
    { lat: 48.7074314, lng: 18.2175242 },
    { lat: 48.7074703, lng: 18.2174552 },
    { lat: 48.7075114, lng: 18.2173844 },
    { lat: 48.7075523, lng: 18.2173112 },
    { lat: 48.7075956, lng: 18.2172359 },
    { lat: 48.7076008, lng: 18.2172277 },
    { lat: 48.7075767, lng: 18.2170847 },
    { lat: 48.7075709, lng: 18.2170612 },
    { lat: 48.7075062, lng: 18.2167631 },
    { lat: 48.7075442, lng: 18.2167156 },
    { lat: 48.7075537, lng: 18.2167044 },
    { lat: 48.7076665, lng: 18.2165638 },
    { lat: 48.7077265, lng: 18.2164899 },
    { lat: 48.7077338, lng: 18.2164796 },
    { lat: 48.7077885, lng: 18.2164124 },
    { lat: 48.707817, lng: 18.2163766 },
    { lat: 48.7078243, lng: 18.2163681 },
    { lat: 48.7079037, lng: 18.2162937 },
    { lat: 48.7079193, lng: 18.2162724 },
    { lat: 48.707954, lng: 18.2162253 },
    { lat: 48.7079695, lng: 18.216204 },
    { lat: 48.7080061, lng: 18.2161538 },
    { lat: 48.7080272, lng: 18.216125 },
    { lat: 48.7080575, lng: 18.2160842 },
    { lat: 48.7080991, lng: 18.2159855 },
    { lat: 48.7081181, lng: 18.215942 },
    { lat: 48.7081229, lng: 18.215926 },
    { lat: 48.7081423, lng: 18.2158527 },
    { lat: 48.7081564, lng: 18.2158019 },
    { lat: 48.7081879, lng: 18.2156831 },
    { lat: 48.7081954, lng: 18.215636 },
    { lat: 48.7082389, lng: 18.2153734 },
    { lat: 48.7082552, lng: 18.2152813 },
    { lat: 48.708258, lng: 18.215273 },
    { lat: 48.7083006, lng: 18.2151503 },
    { lat: 48.7083175, lng: 18.2151112 },
    { lat: 48.7083625, lng: 18.2150082 },
    { lat: 48.7083764, lng: 18.2149749 },
    { lat: 48.7083881, lng: 18.2149448 },
    { lat: 48.7084249, lng: 18.214856 },
    { lat: 48.7084943, lng: 18.2146887 },
    { lat: 48.7084908, lng: 18.2146491 },
    { lat: 48.7084822, lng: 18.2145191 },
    { lat: 48.7084625, lng: 18.2144106 },
    { lat: 48.7084419, lng: 18.2142293 },
    { lat: 48.7084499, lng: 18.2141914 },
    { lat: 48.7084595, lng: 18.2141455 },
    { lat: 48.7082882, lng: 18.2139322 },
    { lat: 48.7082642, lng: 18.2138649 },
    { lat: 48.7084279, lng: 18.2137309 },
    { lat: 48.7085805, lng: 18.213518 },
    { lat: 48.7085974, lng: 18.2133639 },
    { lat: 48.7085343, lng: 18.2132068 },
    { lat: 48.7084224, lng: 18.2130453 },
    { lat: 48.7084527, lng: 18.2129231 },
    { lat: 48.7085036, lng: 18.2127874 },
    { lat: 48.7087054, lng: 18.2124723 },
    { lat: 48.708802, lng: 18.2123471 },
    { lat: 48.7089712, lng: 18.2121102 },
    { lat: 48.7090617, lng: 18.2119936 },
    { lat: 48.7091045, lng: 18.2118744 },
    { lat: 48.7094909, lng: 18.2115715 },
    { lat: 48.7098622, lng: 18.2116082 },
    { lat: 48.710018, lng: 18.211551 },
    { lat: 48.7100696, lng: 18.2114699 },
    { lat: 48.7101209, lng: 18.2113578 },
    { lat: 48.7101551, lng: 18.2111093 },
    { lat: 48.7102122, lng: 18.2108477 },
    { lat: 48.7105115, lng: 18.2104809 },
    { lat: 48.7105767, lng: 18.2103555 },
    { lat: 48.7106455, lng: 18.2102 },
    { lat: 48.7108853, lng: 18.2101306 },
    { lat: 48.7110476, lng: 18.2099307 },
    { lat: 48.7111604, lng: 18.2099309 },
    { lat: 48.7114183, lng: 18.2101551 },
    { lat: 48.7116059, lng: 18.2102802 },
    { lat: 48.711862, lng: 18.2103963 },
    { lat: 48.711869, lng: 18.2104032 },
    { lat: 48.7119039, lng: 18.2104376 },
    { lat: 48.7120741, lng: 18.2103746 },
    { lat: 48.7122836, lng: 18.210211 },
    { lat: 48.7130793, lng: 18.2097254 },
    { lat: 48.713477, lng: 18.2093995 },
    { lat: 48.7135824, lng: 18.2093664 },
    { lat: 48.7137781, lng: 18.2093357 },
    { lat: 48.7140184, lng: 18.2092163 },
    { lat: 48.7142646, lng: 18.2090454 },
    { lat: 48.7143388, lng: 18.2086862 },
    { lat: 48.7144031, lng: 18.2084719 },
    { lat: 48.7144747, lng: 18.2083017 },
    { lat: 48.7146842, lng: 18.2082156 },
    { lat: 48.7148356, lng: 18.2083566 },
    { lat: 48.7150039, lng: 18.2083388 },
    { lat: 48.7152141, lng: 18.2084256 },
    { lat: 48.7153763, lng: 18.2084993 },
    { lat: 48.7154915, lng: 18.2084996 },
    { lat: 48.715568, lng: 18.2084574 },
    { lat: 48.7157758, lng: 18.2083036 },
    { lat: 48.7158534, lng: 18.2082191 },
    { lat: 48.7158834, lng: 18.2081144 },
    { lat: 48.7157997, lng: 18.2079914 },
    { lat: 48.7158019, lng: 18.2079143 },
    { lat: 48.7158335, lng: 18.2078712 },
    { lat: 48.7159874, lng: 18.2078996 },
    { lat: 48.71608, lng: 18.2079239 },
    { lat: 48.716176, lng: 18.2079817 },
    { lat: 48.716253, lng: 18.2080091 },
    { lat: 48.7166321, lng: 18.2077289 },
    { lat: 48.7167263, lng: 18.2075411 },
    { lat: 48.7167229, lng: 18.2074447 },
    { lat: 48.7166251, lng: 18.2072949 },
    { lat: 48.7167317, lng: 18.2071091 },
    { lat: 48.7169191, lng: 18.207107 },
    { lat: 48.7169548, lng: 18.2072013 },
    { lat: 48.7170596, lng: 18.2072561 },
    { lat: 48.7171815, lng: 18.2072741 },
    { lat: 48.717363, lng: 18.207216 },
    { lat: 48.7175796, lng: 18.2071543 },
    { lat: 48.7177184, lng: 18.2071529 },
    { lat: 48.7178049, lng: 18.2072263 },
    { lat: 48.7178348, lng: 18.2071966 },
    { lat: 48.7178547, lng: 18.2070728 },
    { lat: 48.7178659, lng: 18.2069342 },
    { lat: 48.7179758, lng: 18.2068804 },
    { lat: 48.7179941, lng: 18.2068855 },
    { lat: 48.7181314, lng: 18.2069783 },
    { lat: 48.7182871, lng: 18.2067016 },
    { lat: 48.7182602, lng: 18.2066283 },
    { lat: 48.7182421, lng: 18.206606 },
    { lat: 48.7181663, lng: 18.2065256 },
    { lat: 48.7181788, lng: 18.2064661 },
    { lat: 48.7181969, lng: 18.2064483 },
    { lat: 48.7182221, lng: 18.2064343 },
    { lat: 48.7183548, lng: 18.2064265 },
    { lat: 48.7183989, lng: 18.2064082 },
    { lat: 48.7184269, lng: 18.2063849 },
    { lat: 48.7184663, lng: 18.2063672 },
    { lat: 48.7184877, lng: 18.2063448 },
    { lat: 48.7184595, lng: 18.2060515 },
    { lat: 48.718489, lng: 18.2060133 },
    { lat: 48.7186342, lng: 18.2059893 },
    { lat: 48.7186319, lng: 18.2059692 },
    { lat: 48.7186286, lng: 18.20595 },
    { lat: 48.7186123, lng: 18.2058424 },
    { lat: 48.7185292, lng: 18.205615 },
    { lat: 48.7184418, lng: 18.2053484 },
    { lat: 48.7184334, lng: 18.2053216 },
    { lat: 48.718428, lng: 18.2053056 },
    { lat: 48.7184657, lng: 18.2052742 },
    { lat: 48.718893, lng: 18.2049544 },
    { lat: 48.7190437, lng: 18.2048488 },
    { lat: 48.7190903, lng: 18.2048162 },
    { lat: 48.7191198, lng: 18.2047984 },
    { lat: 48.7191319, lng: 18.2047911 },
    { lat: 48.7191602, lng: 18.2047738 },
    { lat: 48.7195794, lng: 18.2045298 },
    { lat: 48.7203702, lng: 18.204074 },
    { lat: 48.7205574, lng: 18.2039332 },
    { lat: 48.7206641, lng: 18.2038477 },
    { lat: 48.7208738, lng: 18.2036876 },
    { lat: 48.7210664, lng: 18.203484 },
    { lat: 48.7212005, lng: 18.2033242 },
    { lat: 48.7214349, lng: 18.2030243 },
    { lat: 48.7218136, lng: 18.2026236 },
    { lat: 48.7219467, lng: 18.2024253 },
    { lat: 48.7221946, lng: 18.2020443 },
    { lat: 48.7222225, lng: 18.2020035 },
    { lat: 48.7222945, lng: 18.2018982 },
    { lat: 48.7223182, lng: 18.2018637 },
    { lat: 48.722501, lng: 18.2016256 },
    { lat: 48.7226392, lng: 18.201438 },
    { lat: 48.7230587, lng: 18.200903 },
    { lat: 48.7232736, lng: 18.2006396 },
    { lat: 48.723562, lng: 18.2002758 },
    { lat: 48.7240043, lng: 18.1996705 },
    { lat: 48.7241741, lng: 18.1994606 },
    { lat: 48.7243833, lng: 18.1991146 },
    { lat: 48.7245935, lng: 18.19879 },
    { lat: 48.724638, lng: 18.1987198 },
    { lat: 48.7248307, lng: 18.19842 },
    { lat: 48.7249979, lng: 18.1981493 },
    { lat: 48.7252036, lng: 18.1977827 },
    { lat: 48.7255115, lng: 18.1971922 },
    { lat: 48.7255625, lng: 18.1970747 },
    { lat: 48.7257041, lng: 18.1967123 },
    { lat: 48.7255684, lng: 18.1962987 },
    { lat: 48.7256501, lng: 18.1959299 },
    { lat: 48.725817, lng: 18.1954783 },
    { lat: 48.7258383, lng: 18.1954369 },
    { lat: 48.7258373, lng: 18.1952219 },
    { lat: 48.7257663, lng: 18.1950496 },
    { lat: 48.7256375, lng: 18.1949272 },
    { lat: 48.7253996, lng: 18.1947547 },
    { lat: 48.7253119, lng: 18.1946644 },
    { lat: 48.7250504, lng: 18.1943153 },
    { lat: 48.7243494, lng: 18.1934879 },
    { lat: 48.7240657, lng: 18.1932057 },
    { lat: 48.7234652, lng: 18.1924398 },
    { lat: 48.7229249, lng: 18.1919508 },
    { lat: 48.7228131, lng: 18.1918522 },
    { lat: 48.7224717, lng: 18.1914883 },
    { lat: 48.7222199, lng: 18.1912301 },
    { lat: 48.7218193, lng: 18.190839 },
    { lat: 48.7196186, lng: 18.1885218 },
    { lat: 48.7195902, lng: 18.1884805 },
    { lat: 48.7194645, lng: 18.1881618 },
    { lat: 48.7193129, lng: 18.1883705 },
    { lat: 48.7191845, lng: 18.1885503 },
    { lat: 48.7191079, lng: 18.1886297 },
    { lat: 48.7190488, lng: 18.1886622 },
    { lat: 48.7190031, lng: 18.18867 },
    { lat: 48.7187955, lng: 18.1886902 },
    { lat: 48.7186989, lng: 18.1887365 },
    { lat: 48.718521, lng: 18.1888213 },
    { lat: 48.7183652, lng: 18.1888997 },
    { lat: 48.7180914, lng: 18.1889668 },
    { lat: 48.7179857, lng: 18.1890115 },
    { lat: 48.7178759, lng: 18.1890835 },
    { lat: 48.7177709, lng: 18.189183 },
    { lat: 48.7176298, lng: 18.1893188 },
    { lat: 48.7171695, lng: 18.1896892 },
    { lat: 48.7170069, lng: 18.1898064 },
    { lat: 48.7168003, lng: 18.1899636 },
    { lat: 48.7164286, lng: 18.190402 },
    { lat: 48.7162243, lng: 18.1905593 },
    { lat: 48.7160602, lng: 18.1907103 },
    { lat: 48.7160218, lng: 18.1907464 },
    { lat: 48.7159221, lng: 18.1908378 },
    { lat: 48.7159138, lng: 18.1908496 },
    { lat: 48.7155212, lng: 18.1913822 },
    { lat: 48.7153286, lng: 18.1915651 },
    { lat: 48.7151827, lng: 18.1918309 },
    { lat: 48.7150096, lng: 18.1920198 },
    { lat: 48.7148685, lng: 18.1922142 },
    { lat: 48.7148146, lng: 18.1923167 },
    { lat: 48.7146454, lng: 18.192554 },
    { lat: 48.7142954, lng: 18.1930625 },
    { lat: 48.7139031, lng: 18.193595 },
    { lat: 48.7133691, lng: 18.1942791 },
    { lat: 48.7132759, lng: 18.1943853 },
    { lat: 48.7130867, lng: 18.1945667 },
    { lat: 48.7129675, lng: 18.1947306 },
    { lat: 48.7129152, lng: 18.1948033 },
    { lat: 48.712389, lng: 18.1957171 },
    { lat: 48.7122211, lng: 18.1960333 },
    { lat: 48.712075, lng: 18.1964366 },
    { lat: 48.7120159, lng: 18.1966684 },
    { lat: 48.7119471, lng: 18.1968436 },
    { lat: 48.711878, lng: 18.1969741 },
    { lat: 48.7117779, lng: 18.1971002 },
    { lat: 48.7116187, lng: 18.1972723 },
    { lat: 48.7114084, lng: 18.1975011 },
    { lat: 48.7112607, lng: 18.197663 },
    { lat: 48.7110446, lng: 18.1979047 },
    { lat: 48.7109198, lng: 18.1980137 },
    { lat: 48.7106464, lng: 18.1982022 },
    { lat: 48.7104815, lng: 18.1983346 },
    { lat: 48.7102947, lng: 18.1984417 },
    { lat: 48.7097719, lng: 18.1988694 },
    { lat: 48.7091547, lng: 18.1995768 },
    { lat: 48.7087165, lng: 18.2002157 },
    { lat: 48.7077856, lng: 18.2013765 },
    { lat: 48.7071645, lng: 18.2024049 },
    { lat: 48.7067799, lng: 18.203136 },
    { lat: 48.7064421, lng: 18.2038359 },
    { lat: 48.7061096, lng: 18.2051757 },
    { lat: 48.7061138, lng: 18.2053277 },
    { lat: 48.7059819, lng: 18.2054261 },
    { lat: 48.7059234, lng: 18.2055281 },
    { lat: 48.7057794, lng: 18.2058692 },
    { lat: 48.7056791, lng: 18.2060492 },
    { lat: 48.7056071, lng: 18.206228 },
    { lat: 48.7055224, lng: 18.2064049 },
    { lat: 48.7054395, lng: 18.2065333 },
    { lat: 48.705362, lng: 18.2066792 },
    { lat: 48.7052132, lng: 18.206896 },
    { lat: 48.7050884, lng: 18.2069827 },
    { lat: 48.7050271, lng: 18.2070147 },
    { lat: 48.7049037, lng: 18.2070484 },
    { lat: 48.7047552, lng: 18.2070561 },
    { lat: 48.7046415, lng: 18.2070992 },
    { lat: 48.7044321, lng: 18.2072067 },
    { lat: 48.7043131, lng: 18.2072763 },
    { lat: 48.7041777, lng: 18.2073762 },
    { lat: 48.7040181, lng: 18.207504 },
    { lat: 48.7037392, lng: 18.2075795 },
    { lat: 48.7036563, lng: 18.2075896 },
    { lat: 48.7035153, lng: 18.2076499 },
    { lat: 48.7033472, lng: 18.2077081 },
    { lat: 48.7032603, lng: 18.207707 },
    { lat: 48.7031606, lng: 18.2076836 },
    { lat: 48.7022506, lng: 18.2074964 },
    { lat: 48.7020721, lng: 18.207472 },
    { lat: 48.7019975, lng: 18.20747 },
    { lat: 48.7018811, lng: 18.2074892 },
    { lat: 48.701777, lng: 18.2075625 },
    { lat: 48.7013966, lng: 18.2079332 },
    { lat: 48.7006381, lng: 18.2085185 },
    { lat: 48.7004621, lng: 18.2085962 },
    { lat: 48.700366, lng: 18.2086731 },
    { lat: 48.7002959, lng: 18.2087698 },
    { lat: 48.7001723, lng: 18.2089922 },
    { lat: 48.7001128, lng: 18.2091339 },
    { lat: 48.6999887, lng: 18.2093895 },
    { lat: 48.699874, lng: 18.2095164 },
    { lat: 48.6996876, lng: 18.2096519 },
    { lat: 48.6995396, lng: 18.2097256 },
    { lat: 48.6992971, lng: 18.2099217 },
    { lat: 48.699131, lng: 18.2100757 },
    { lat: 48.6990075, lng: 18.2101655 },
    { lat: 48.6984816, lng: 18.2106184 },
    { lat: 48.6983324, lng: 18.2107705 },
    { lat: 48.6977134, lng: 18.211525 },
    { lat: 48.6975909, lng: 18.2117289 },
    { lat: 48.6975377, lng: 18.2118655 },
    { lat: 48.6974335, lng: 18.2119953 },
    { lat: 48.6961872, lng: 18.2132852 },
    { lat: 48.6954579, lng: 18.2140416 },
    { lat: 48.6951086, lng: 18.2144059 },
    { lat: 48.6939042, lng: 18.2156567 },
    { lat: 48.6929511, lng: 18.216468 },
    { lat: 48.6918977, lng: 18.2173631 },
    { lat: 48.691662, lng: 18.2175604 },
    { lat: 48.6907396, lng: 18.2183408 },
    { lat: 48.6901146, lng: 18.2188759 },
    { lat: 48.6898279, lng: 18.2190873 },
    { lat: 48.6900112, lng: 18.2194512 },
    { lat: 48.6902104, lng: 18.219914 },
    { lat: 48.6903274, lng: 18.2202423 },
    { lat: 48.6904905, lng: 18.2209802 },
    { lat: 48.6905778, lng: 18.2218553 },
    { lat: 48.6904338, lng: 18.2218005 },
    { lat: 48.6898571, lng: 18.2222338 },
    { lat: 48.6901728, lng: 18.2239007 },
    { lat: 48.6902916, lng: 18.2244959 },
    { lat: 48.6906486, lng: 18.2263139 },
    { lat: 48.6908722, lng: 18.2274022 },
    { lat: 48.6910102, lng: 18.2280359 },
    { lat: 48.6911858, lng: 18.2287569 },
    { lat: 48.6913472, lng: 18.2293119 },
    { lat: 48.6913499, lng: 18.2293176 },
    { lat: 48.6913281, lng: 18.2293711 },
    { lat: 48.6914976, lng: 18.229964 },
    { lat: 48.6919157, lng: 18.2317116 },
    { lat: 48.6919553, lng: 18.2318714 },
    { lat: 48.6922813, lng: 18.2333849 },
    { lat: 48.6924881, lng: 18.2343383 },
    { lat: 48.6925173, lng: 18.234492 },
    { lat: 48.6926432, lng: 18.2350923 },
    { lat: 48.6927101, lng: 18.2353943 },
    { lat: 48.6928708, lng: 18.2361527 },
    { lat: 48.6929762, lng: 18.236735 },
    { lat: 48.6930048, lng: 18.2367223 },
    { lat: 48.6934278, lng: 18.2364828 },
    { lat: 48.6939729, lng: 18.2362477 },
    { lat: 48.6946675, lng: 18.2359234 },
    { lat: 48.6950911, lng: 18.2358113 },
    { lat: 48.6954822, lng: 18.2367114 },
    { lat: 48.6963022, lng: 18.2368226 },
    { lat: 48.696523, lng: 18.2368966 },
    { lat: 48.6968779, lng: 18.2370649 },
    { lat: 48.6972487, lng: 18.2371948 },
    { lat: 48.6975876, lng: 18.237283 },
    { lat: 48.6978553, lng: 18.2374445 },
    { lat: 48.6985301, lng: 18.2380423 },
  ],
  VeľkéHoste: [
    { lat: 48.6889853, lng: 18.180783 },
    { lat: 48.6892009, lng: 18.1807435 },
    { lat: 48.6895618, lng: 18.1807413 },
    { lat: 48.6897115, lng: 18.1806349 },
    { lat: 48.6898663, lng: 18.1806209 },
    { lat: 48.6900292, lng: 18.1805109 },
    { lat: 48.690209, lng: 18.18036 },
    { lat: 48.6904317, lng: 18.1805432 },
    { lat: 48.6908684, lng: 18.180485 },
    { lat: 48.6909611, lng: 18.1804478 },
    { lat: 48.6912468, lng: 18.1802777 },
    { lat: 48.6913407, lng: 18.18022 },
    { lat: 48.6915892, lng: 18.1802644 },
    { lat: 48.691711, lng: 18.1801469 },
    { lat: 48.6919076, lng: 18.1800331 },
    { lat: 48.6920964, lng: 18.1798836 },
    { lat: 48.6922117, lng: 18.1797667 },
    { lat: 48.6923485, lng: 18.1796383 },
    { lat: 48.6925769, lng: 18.1797614 },
    { lat: 48.6928451, lng: 18.1795821 },
    { lat: 48.6929813, lng: 18.1794956 },
    { lat: 48.6931111, lng: 18.1793603 },
    { lat: 48.6936033, lng: 18.1792262 },
    { lat: 48.6943162, lng: 18.1788287 },
    { lat: 48.6952333, lng: 18.1784332 },
    { lat: 48.6961056, lng: 18.1779039 },
    { lat: 48.6965806, lng: 18.1775917 },
    { lat: 48.6966937, lng: 18.1775189 },
    { lat: 48.6968275, lng: 18.1774688 },
    { lat: 48.6969166, lng: 18.1773909 },
    { lat: 48.6974704, lng: 18.1768589 },
    { lat: 48.6982595, lng: 18.1762191 },
    { lat: 48.6991301, lng: 18.175506 },
    { lat: 48.700065, lng: 18.174579 },
    { lat: 48.6998906, lng: 18.1743527 },
    { lat: 48.6995118, lng: 18.1735949 },
    { lat: 48.6994244, lng: 18.1733353 },
    { lat: 48.6993082, lng: 18.1726136 },
    { lat: 48.6992413, lng: 18.1722738 },
    { lat: 48.699107, lng: 18.1716678 },
    { lat: 48.6986412, lng: 18.1707575 },
    { lat: 48.6984352, lng: 18.1703339 },
    { lat: 48.6982927, lng: 18.1699962 },
    { lat: 48.6980662, lng: 18.1693013 },
    { lat: 48.6979773, lng: 18.1689347 },
    { lat: 48.697641, lng: 18.1683478 },
    { lat: 48.6974812, lng: 18.1680435 },
    { lat: 48.6973174, lng: 18.1679054 },
    { lat: 48.6972337, lng: 18.1676846 },
    { lat: 48.6968544, lng: 18.166728 },
    { lat: 48.6968218, lng: 18.1666512 },
    { lat: 48.6962986, lng: 18.1656637 },
    { lat: 48.6961523, lng: 18.1653979 },
    { lat: 48.6959169, lng: 18.1649392 },
    { lat: 48.6958232, lng: 18.1650727 },
    { lat: 48.695807, lng: 18.1650456 },
    { lat: 48.6952538, lng: 18.1639891 },
    { lat: 48.6944076, lng: 18.1621976 },
    { lat: 48.6942121, lng: 18.1618434 },
    { lat: 48.6934535, lng: 18.1605687 },
    { lat: 48.6935107, lng: 18.1602918 },
    { lat: 48.6926915, lng: 18.158713 },
    { lat: 48.6924772, lng: 18.1583663 },
    { lat: 48.6916999, lng: 18.1572389 },
    { lat: 48.6915455, lng: 18.1566963 },
    { lat: 48.6900687, lng: 18.1539198 },
    { lat: 48.6889154, lng: 18.1514853 },
    { lat: 48.6884628, lng: 18.1505712 },
    { lat: 48.6883612, lng: 18.1503564 },
    { lat: 48.6881237, lng: 18.1497796 },
    { lat: 48.6879388, lng: 18.1493369 },
    { lat: 48.687723, lng: 18.1488847 },
    { lat: 48.6874404, lng: 18.1483917 },
    { lat: 48.687244, lng: 18.1481773 },
    { lat: 48.6871963, lng: 18.1480154 },
    { lat: 48.6871221, lng: 18.1479234 },
    { lat: 48.6870233, lng: 18.1477154 },
    { lat: 48.6865779, lng: 18.1469068 },
    { lat: 48.6861625, lng: 18.1463786 },
    { lat: 48.6859463, lng: 18.1462143 },
    { lat: 48.6858088, lng: 18.1460779 },
    { lat: 48.6856307, lng: 18.1458693 },
    { lat: 48.6853487, lng: 18.1454431 },
    { lat: 48.685266, lng: 18.1452826 },
    { lat: 48.6851523, lng: 18.145013 },
    { lat: 48.6850871, lng: 18.1448845 },
    { lat: 48.6849662, lng: 18.1445901 },
    { lat: 48.6847029, lng: 18.1441364 },
    { lat: 48.6846357, lng: 18.1439314 },
    { lat: 48.6842767, lng: 18.1433032 },
    { lat: 48.6841785, lng: 18.1430537 },
    { lat: 48.6840541, lng: 18.1427563 },
    { lat: 48.6837788, lng: 18.1423117 },
    { lat: 48.6833052, lng: 18.1411316 },
    { lat: 48.6832873, lng: 18.1410761 },
    { lat: 48.6835841, lng: 18.140495 },
    { lat: 48.683672, lng: 18.1402376 },
    { lat: 48.6836922, lng: 18.1401575 },
    { lat: 48.683701, lng: 18.1399588 },
    { lat: 48.6834196, lng: 18.1378386 },
    { lat: 48.6832585, lng: 18.1366357 },
    { lat: 48.6831963, lng: 18.1359726 },
    { lat: 48.6831069, lng: 18.1352894 },
    { lat: 48.6829945, lng: 18.1338601 },
    { lat: 48.683155, lng: 18.133616 },
    { lat: 48.6833478, lng: 18.1332022 },
    { lat: 48.683044, lng: 18.1324429 },
    { lat: 48.6824343, lng: 18.1314084 },
    { lat: 48.6822737, lng: 18.1311319 },
    { lat: 48.6821835, lng: 18.1309181 },
    { lat: 48.6820284, lng: 18.1306023 },
    { lat: 48.6814801, lng: 18.1294569 },
    { lat: 48.6812414, lng: 18.1289636 },
    { lat: 48.6807401, lng: 18.1280087 },
    { lat: 48.6806683, lng: 18.1278597 },
    { lat: 48.6800474, lng: 18.1271397 },
    { lat: 48.6793416, lng: 18.1264541 },
    { lat: 48.679242, lng: 18.126351 },
    { lat: 48.679216, lng: 18.126421 },
    { lat: 48.679184, lng: 18.126549 },
    { lat: 48.679116, lng: 18.126625 },
    { lat: 48.679074, lng: 18.126675 },
    { lat: 48.679008, lng: 18.126718 },
    { lat: 48.679025, lng: 18.126774 },
    { lat: 48.679018, lng: 18.126849 },
    { lat: 48.678949, lng: 18.126882 },
    { lat: 48.678895, lng: 18.126971 },
    { lat: 48.678877, lng: 18.126986 },
    { lat: 48.678773, lng: 18.127258 },
    { lat: 48.678721, lng: 18.127409 },
    { lat: 48.678657, lng: 18.127616 },
    { lat: 48.678554, lng: 18.12773 },
    { lat: 48.678503, lng: 18.127785 },
    { lat: 48.678513, lng: 18.127926 },
    { lat: 48.678519, lng: 18.128079 },
    { lat: 48.678404, lng: 18.128158 },
    { lat: 48.678406, lng: 18.128183 },
    { lat: 48.678436, lng: 18.128356 },
    { lat: 48.678395, lng: 18.128502 },
    { lat: 48.678324, lng: 18.128701 },
    { lat: 48.678268, lng: 18.12876 },
    { lat: 48.678215, lng: 18.128815 },
    { lat: 48.678062, lng: 18.129143 },
    { lat: 48.67814, lng: 18.129359 },
    { lat: 48.678123, lng: 18.129442 },
    { lat: 48.678104, lng: 18.129475 },
    { lat: 48.678032, lng: 18.129568 },
    { lat: 48.677971, lng: 18.129656 },
    { lat: 48.67796, lng: 18.129724 },
    { lat: 48.677946, lng: 18.129822 },
    { lat: 48.677934, lng: 18.129898 },
    { lat: 48.677923, lng: 18.129968 },
    { lat: 48.677614, lng: 18.130541 },
    { lat: 48.677561, lng: 18.130637 },
    { lat: 48.677438, lng: 18.130882 },
    { lat: 48.677398, lng: 18.130927 },
    { lat: 48.677352, lng: 18.130968 },
    { lat: 48.677311, lng: 18.131003 },
    { lat: 48.677292, lng: 18.131061 },
    { lat: 48.677257, lng: 18.131154 },
    { lat: 48.677225, lng: 18.131247 },
    { lat: 48.677181, lng: 18.131311 },
    { lat: 48.677048, lng: 18.131513 },
    { lat: 48.677028, lng: 18.131551 },
    { lat: 48.677008, lng: 18.131594 },
    { lat: 48.676965, lng: 18.131674 },
    { lat: 48.676944, lng: 18.131712 },
    { lat: 48.676924, lng: 18.131742 },
    { lat: 48.67691, lng: 18.13177 },
    { lat: 48.676886, lng: 18.131828 },
    { lat: 48.676866, lng: 18.131876 },
    { lat: 48.676849, lng: 18.131926 },
    { lat: 48.676826, lng: 18.131972 },
    { lat: 48.676771, lng: 18.132095 },
    { lat: 48.676702, lng: 18.132224 },
    { lat: 48.676627, lng: 18.132347 },
    { lat: 48.676631, lng: 18.132498 },
    { lat: 48.676561, lng: 18.132672 },
    { lat: 48.676564, lng: 18.132747 },
    { lat: 48.676507, lng: 18.132888 },
    { lat: 48.67648, lng: 18.132919 },
    { lat: 48.676439, lng: 18.132975 },
    { lat: 48.676407, lng: 18.13302 },
    { lat: 48.676379, lng: 18.133068 },
    { lat: 48.67625, lng: 18.133267 },
    { lat: 48.676153, lng: 18.133404 },
    { lat: 48.676137, lng: 18.133444 },
    { lat: 48.676131, lng: 18.133474 },
    { lat: 48.676111, lng: 18.133527 },
    { lat: 48.676088, lng: 18.13359 },
    { lat: 48.676045, lng: 18.133654 },
    { lat: 48.676009, lng: 18.133704 },
    { lat: 48.675975, lng: 18.133764 },
    { lat: 48.675936, lng: 18.133825 },
    { lat: 48.675919, lng: 18.133833 },
    { lat: 48.675856, lng: 18.133886 },
    { lat: 48.675841, lng: 18.133906 },
    { lat: 48.675669, lng: 18.133895 },
    { lat: 48.675518, lng: 18.133788 },
    { lat: 48.675228, lng: 18.133368 },
    { lat: 48.675082, lng: 18.133109 },
    { lat: 48.675001, lng: 18.133019 },
    { lat: 48.674846, lng: 18.132942 },
    { lat: 48.674602, lng: 18.132967 },
    { lat: 48.674228, lng: 18.133181 },
    { lat: 48.674121, lng: 18.133283 },
    { lat: 48.674013, lng: 18.133416 },
    { lat: 48.673735, lng: 18.133917 },
    { lat: 48.673334, lng: 18.134297 },
    { lat: 48.673277, lng: 18.134381 },
    { lat: 48.673166, lng: 18.134347 },
    { lat: 48.673009, lng: 18.134224 },
    { lat: 48.672745, lng: 18.133952 },
    { lat: 48.672575, lng: 18.133824 },
    { lat: 48.67244, lng: 18.13375 },
    { lat: 48.672194, lng: 18.133735 },
    { lat: 48.672128, lng: 18.133722 },
    { lat: 48.672059, lng: 18.133676 },
    { lat: 48.671795, lng: 18.1334 },
    { lat: 48.671619, lng: 18.133127 },
    { lat: 48.671331, lng: 18.132849 },
    { lat: 48.671193, lng: 18.132418 },
    { lat: 48.670988, lng: 18.131286 },
    { lat: 48.6709, lng: 18.131109 },
    { lat: 48.670887, lng: 18.131132 },
    { lat: 48.670578, lng: 18.132261 },
    { lat: 48.670455, lng: 18.132732 },
    { lat: 48.670216, lng: 18.133781 },
    { lat: 48.670193, lng: 18.134236 },
    { lat: 48.670149, lng: 18.134616 },
    { lat: 48.67012, lng: 18.134771 },
    { lat: 48.669885, lng: 18.135818 },
    { lat: 48.669789, lng: 18.136157 },
    { lat: 48.669767, lng: 18.136384 },
    { lat: 48.669746, lng: 18.137149 },
    { lat: 48.669829, lng: 18.137804 },
    { lat: 48.669803, lng: 18.138163 },
    { lat: 48.669763, lng: 18.138473 },
    { lat: 48.669633, lng: 18.139021 },
    { lat: 48.669653, lng: 18.139426 },
    { lat: 48.669552, lng: 18.13971 },
    { lat: 48.669418, lng: 18.140033 },
    { lat: 48.669357, lng: 18.140237 },
    { lat: 48.669339, lng: 18.140377 },
    { lat: 48.669132, lng: 18.141139 },
    { lat: 48.669073, lng: 18.141268 },
    { lat: 48.668985, lng: 18.141527 },
    { lat: 48.668921, lng: 18.141673 },
    { lat: 48.668862, lng: 18.14185 },
    { lat: 48.668761, lng: 18.14219 },
    { lat: 48.6689374, lng: 18.1425327 },
    { lat: 48.6684103, lng: 18.1432347 },
    { lat: 48.6684058, lng: 18.1437952 },
    { lat: 48.6684239, lng: 18.1442626 },
    { lat: 48.668305, lng: 18.1448003 },
    { lat: 48.6681602, lng: 18.1451254 },
    { lat: 48.6678191, lng: 18.1457636 },
    { lat: 48.6679426, lng: 18.1462935 },
    { lat: 48.6676562, lng: 18.1470183 },
    { lat: 48.6677804, lng: 18.1477205 },
    { lat: 48.6677714, lng: 18.147952 },
    { lat: 48.6677244, lng: 18.1480742 },
    { lat: 48.6676373, lng: 18.1483474 },
    { lat: 48.6674451, lng: 18.1488074 },
    { lat: 48.6672927, lng: 18.1492909 },
    { lat: 48.6671892, lng: 18.1497045 },
    { lat: 48.6670674, lng: 18.1501161 },
    { lat: 48.6670178, lng: 18.1503119 },
    { lat: 48.6669654, lng: 18.1506316 },
    { lat: 48.666977, lng: 18.1508717 },
    { lat: 48.6671211, lng: 18.1515053 },
    { lat: 48.6671286, lng: 18.1516753 },
    { lat: 48.6670465, lng: 18.1517979 },
    { lat: 48.6671759, lng: 18.1522392 },
    { lat: 48.6671824, lng: 18.1523912 },
    { lat: 48.6671776, lng: 18.1526192 },
    { lat: 48.667206, lng: 18.1529332 },
    { lat: 48.6673436, lng: 18.1532431 },
    { lat: 48.6674354, lng: 18.1535198 },
    { lat: 48.6674002, lng: 18.1537537 },
    { lat: 48.6674578, lng: 18.1537153 },
    { lat: 48.6674261, lng: 18.1538498 },
    { lat: 48.66744, lng: 18.1542646 },
    { lat: 48.6675183, lng: 18.1545063 },
    { lat: 48.6676751, lng: 18.1548316 },
    { lat: 48.6677601, lng: 18.1551531 },
    { lat: 48.6678051, lng: 18.1555213 },
    { lat: 48.6678989, lng: 18.1561065 },
    { lat: 48.6680582, lng: 18.1567338 },
    { lat: 48.6680609, lng: 18.1569359 },
    { lat: 48.6680292, lng: 18.1570486 },
    { lat: 48.6679698, lng: 18.1575227 },
    { lat: 48.6679663, lng: 18.1577153 },
    { lat: 48.6679454, lng: 18.1581329 },
    { lat: 48.6679555, lng: 18.1581319 },
    { lat: 48.6681641, lng: 18.1586967 },
    { lat: 48.6684503, lng: 18.1593711 },
    { lat: 48.6686613, lng: 18.1599138 },
    { lat: 48.6687454, lng: 18.1601986 },
    { lat: 48.6688538, lng: 18.1606452 },
    { lat: 48.6689696, lng: 18.1615182 },
    { lat: 48.6690518, lng: 18.1619608 },
    { lat: 48.6692656, lng: 18.1625545 },
    { lat: 48.6692658, lng: 18.1625984 },
    { lat: 48.6693245, lng: 18.1629869 },
    { lat: 48.669776, lng: 18.1640427 },
    { lat: 48.6699409, lng: 18.1643951 },
    { lat: 48.6700444, lng: 18.164566 },
    { lat: 48.670208, lng: 18.1647605 },
    { lat: 48.6703989, lng: 18.1649381 },
    { lat: 48.6708255, lng: 18.1653088 },
    { lat: 48.6712255, lng: 18.1656095 },
    { lat: 48.671941, lng: 18.1661199 },
    { lat: 48.6726458, lng: 18.1666168 },
    { lat: 48.6728643, lng: 18.1663384 },
    { lat: 48.6729108, lng: 18.1663822 },
    { lat: 48.6733933, lng: 18.1668774 },
    { lat: 48.6736037, lng: 18.1671217 },
    { lat: 48.6739779, lng: 18.1675419 },
    { lat: 48.6742955, lng: 18.1680188 },
    { lat: 48.6743753, lng: 18.168405 },
    { lat: 48.6744959, lng: 18.1687893 },
    { lat: 48.6746384, lng: 18.169029 },
    { lat: 48.6747964, lng: 18.1694599 },
    { lat: 48.6750125, lng: 18.1698806 },
    { lat: 48.6750888, lng: 18.1700669 },
    { lat: 48.6755521, lng: 18.1706449 },
    { lat: 48.6757869, lng: 18.1711557 },
    { lat: 48.6759409, lng: 18.1716874 },
    { lat: 48.6760641, lng: 18.1721996 },
    { lat: 48.6761801, lng: 18.172566 },
    { lat: 48.6762711, lng: 18.1727572 },
    { lat: 48.6764253, lng: 18.1732564 },
    { lat: 48.6766536, lng: 18.173983 },
    { lat: 48.6767955, lng: 18.174331 },
    { lat: 48.6771247, lng: 18.1752035 },
    { lat: 48.6771633, lng: 18.175347 },
    { lat: 48.6772042, lng: 18.1755437 },
    { lat: 48.6773782, lng: 18.1762145 },
    { lat: 48.6774211, lng: 18.1763874 },
    { lat: 48.6775746, lng: 18.1768582 },
    { lat: 48.6776134, lng: 18.1770438 },
    { lat: 48.6777245, lng: 18.1774405 },
    { lat: 48.6777887, lng: 18.1776913 },
    { lat: 48.6779477, lng: 18.1782577 },
    { lat: 48.6781064, lng: 18.1787385 },
    { lat: 48.6782874, lng: 18.1791568 },
    { lat: 48.6784156, lng: 18.1794027 },
    { lat: 48.6785484, lng: 18.1797287 },
    { lat: 48.6786109, lng: 18.1798172 },
    { lat: 48.6786636, lng: 18.1799671 },
    { lat: 48.678684, lng: 18.1801033 },
    { lat: 48.6785946, lng: 18.1802557 },
    { lat: 48.6785112, lng: 18.1804153 },
    { lat: 48.6784895, lng: 18.1805067 },
    { lat: 48.6786781, lng: 18.1810175 },
    { lat: 48.6788366, lng: 18.1814562 },
    { lat: 48.6789047, lng: 18.1816204 },
    { lat: 48.6791303, lng: 18.1822845 },
    { lat: 48.6793893, lng: 18.183056 },
    { lat: 48.6795499, lng: 18.1835109 },
    { lat: 48.6797284, lng: 18.1839855 },
    { lat: 48.679744, lng: 18.1840388 },
    { lat: 48.6798256, lng: 18.1841824 },
    { lat: 48.6802171, lng: 18.1848079 },
    { lat: 48.6803395, lng: 18.1849543 },
    { lat: 48.6808179, lng: 18.1856969 },
    { lat: 48.6813575, lng: 18.1865408 },
    { lat: 48.6815817, lng: 18.1869067 },
    { lat: 48.681737, lng: 18.1871358 },
    { lat: 48.6818017, lng: 18.1872191 },
    { lat: 48.6818966, lng: 18.1870249 },
    { lat: 48.6821243, lng: 18.1870206 },
    { lat: 48.6821913, lng: 18.1868533 },
    { lat: 48.6822621, lng: 18.1868268 },
    { lat: 48.6823228, lng: 18.1867067 },
    { lat: 48.6823987, lng: 18.1864971 },
    { lat: 48.6823907, lng: 18.1862182 },
    { lat: 48.6825057, lng: 18.1862163 },
    { lat: 48.682598, lng: 18.1861763 },
    { lat: 48.6826758, lng: 18.1861146 },
    { lat: 48.6825498, lng: 18.1859066 },
    { lat: 48.6824881, lng: 18.1858536 },
    { lat: 48.6824622, lng: 18.1858152 },
    { lat: 48.6824432, lng: 18.1857595 },
    { lat: 48.6824935, lng: 18.1856351 },
    { lat: 48.6826758, lng: 18.1854718 },
    { lat: 48.6828646, lng: 18.1854766 },
    { lat: 48.6831113, lng: 18.1853724 },
    { lat: 48.683192, lng: 18.1852832 },
    { lat: 48.6832929, lng: 18.1851342 },
    { lat: 48.6834133, lng: 18.184992 },
    { lat: 48.6834116, lng: 18.1848265 },
    { lat: 48.6834509, lng: 18.1845485 },
    { lat: 48.6836897, lng: 18.1845396 },
    { lat: 48.6837893, lng: 18.1845661 },
    { lat: 48.6838749, lng: 18.1844841 },
    { lat: 48.6839666, lng: 18.1843728 },
    { lat: 48.6841109, lng: 18.1843695 },
    { lat: 48.6841748, lng: 18.1843254 },
    { lat: 48.684143, lng: 18.1841842 },
    { lat: 48.6841556, lng: 18.1841061 },
    { lat: 48.6842259, lng: 18.1839969 },
    { lat: 48.6842896, lng: 18.1840639 },
    { lat: 48.6843017, lng: 18.184213 },
    { lat: 48.6844089, lng: 18.1841371 },
    { lat: 48.6845048, lng: 18.1839588 },
    { lat: 48.684571, lng: 18.183974 },
    { lat: 48.6846321, lng: 18.1839192 },
    { lat: 48.6846501, lng: 18.1837997 },
    { lat: 48.6845523, lng: 18.1835525 },
    { lat: 48.6845388, lng: 18.1833954 },
    { lat: 48.684545, lng: 18.1833482 },
    { lat: 48.6845732, lng: 18.1832509 },
    { lat: 48.6846421, lng: 18.1831329 },
    { lat: 48.6847417, lng: 18.1831176 },
    { lat: 48.6849001, lng: 18.18299 },
    { lat: 48.6849253, lng: 18.1829178 },
    { lat: 48.6849073, lng: 18.1828405 },
    { lat: 48.6849811, lng: 18.1828497 },
    { lat: 48.6851134, lng: 18.1829491 },
    { lat: 48.6853044, lng: 18.1829364 },
    { lat: 48.6854132, lng: 18.1828832 },
    { lat: 48.6854169, lng: 18.1827138 },
    { lat: 48.68549, lng: 18.182476 },
    { lat: 48.685602, lng: 18.1824209 },
    { lat: 48.6857313, lng: 18.1822935 },
    { lat: 48.6858248, lng: 18.1819387 },
    { lat: 48.6859408, lng: 18.1817609 },
    { lat: 48.6860651, lng: 18.181706 },
    { lat: 48.6861901, lng: 18.1815653 },
    { lat: 48.6862323, lng: 18.1814529 },
    { lat: 48.6863662, lng: 18.1812128 },
    { lat: 48.6866168, lng: 18.1811173 },
    { lat: 48.6867543, lng: 18.1811717 },
    { lat: 48.6868198, lng: 18.181037 },
    { lat: 48.6868706, lng: 18.1809017 },
    { lat: 48.6869459, lng: 18.1807393 },
    { lat: 48.6870329, lng: 18.180759 },
    { lat: 48.687186, lng: 18.1808309 },
    { lat: 48.687232, lng: 18.1808285 },
    { lat: 48.6872516, lng: 18.1808159 },
    { lat: 48.6873129, lng: 18.1807032 },
    { lat: 48.6873428, lng: 18.180556 },
    { lat: 48.6874373, lng: 18.1804161 },
    { lat: 48.6875559, lng: 18.180418 },
    { lat: 48.6876633, lng: 18.1805177 },
    { lat: 48.6877837, lng: 18.1806897 },
    { lat: 48.6879176, lng: 18.1807193 },
    { lat: 48.6880764, lng: 18.1807337 },
    { lat: 48.6880418, lng: 18.180819 },
    { lat: 48.6880964, lng: 18.1809257 },
    { lat: 48.6882052, lng: 18.1809707 },
    { lat: 48.6883117, lng: 18.1809231 },
    { lat: 48.6884673, lng: 18.180743 },
    { lat: 48.6885702, lng: 18.1808251 },
    { lat: 48.6886375, lng: 18.1808973 },
    { lat: 48.6887597, lng: 18.1809416 },
    { lat: 48.6888236, lng: 18.1809339 },
    { lat: 48.68888, lng: 18.1808343 },
    { lat: 48.6888829, lng: 18.1807693 },
    { lat: 48.6889564, lng: 18.1807325 },
    { lat: 48.6889853, lng: 18.180783 },
  ],
  Vysočany: [
    { lat: 48.692343, lng: 18.339358 },
    { lat: 48.692964, lng: 18.3385277 },
    { lat: 48.6930647, lng: 18.3375331 },
    { lat: 48.693227, lng: 18.336188 },
    { lat: 48.6935116, lng: 18.3354907 },
    { lat: 48.6935564, lng: 18.335225 },
    { lat: 48.6934355, lng: 18.334803 },
    { lat: 48.6934006, lng: 18.3343015 },
    { lat: 48.6934266, lng: 18.3336843 },
    { lat: 48.693505, lng: 18.3334735 },
    { lat: 48.6934694, lng: 18.3334038 },
    { lat: 48.6933233, lng: 18.3328933 },
    { lat: 48.6931037, lng: 18.3324198 },
    { lat: 48.6930293, lng: 18.3323419 },
    { lat: 48.6929556, lng: 18.3321335 },
    { lat: 48.692926, lng: 18.3320902 },
    { lat: 48.6924662, lng: 18.331919 },
    { lat: 48.6925413, lng: 18.3310701 },
    { lat: 48.6925608, lng: 18.3304573 },
    { lat: 48.692558, lng: 18.3302852 },
    { lat: 48.6925864, lng: 18.3298883 },
    { lat: 48.6926074, lng: 18.3291999 },
    { lat: 48.6926552, lng: 18.3290877 },
    { lat: 48.6926879, lng: 18.3285057 },
    { lat: 48.692757, lng: 18.3272119 },
    { lat: 48.6928031, lng: 18.3266713 },
    { lat: 48.6928667, lng: 18.3264174 },
    { lat: 48.6928511, lng: 18.3260813 },
    { lat: 48.6928376, lng: 18.3260233 },
    { lat: 48.6928916, lng: 18.3251618 },
    { lat: 48.6928578, lng: 18.3250394 },
    { lat: 48.6930065, lng: 18.3223014 },
    { lat: 48.6930172, lng: 18.322189 },
    { lat: 48.6930457, lng: 18.3212543 },
    { lat: 48.6930657, lng: 18.3204707 },
    { lat: 48.6928759, lng: 18.3204873 },
    { lat: 48.6926631, lng: 18.3204547 },
    { lat: 48.6923619, lng: 18.3191775 },
    { lat: 48.6925352, lng: 18.3189902 },
    { lat: 48.692482, lng: 18.3187889 },
    { lat: 48.692591, lng: 18.318362 },
    { lat: 48.6926094, lng: 18.3175286 },
    { lat: 48.6927156, lng: 18.3175296 },
    { lat: 48.6927344, lng: 18.3170636 },
    { lat: 48.6927671, lng: 18.316683 },
    { lat: 48.6927391, lng: 18.3164477 },
    { lat: 48.6925656, lng: 18.3162144 },
    { lat: 48.6925301, lng: 18.3160675 },
    { lat: 48.692365, lng: 18.3161367 },
    { lat: 48.6922554, lng: 18.3155362 },
    { lat: 48.6923048, lng: 18.3155109 },
    { lat: 48.6922433, lng: 18.3149832 },
    { lat: 48.6923125, lng: 18.3145511 },
    { lat: 48.6921592, lng: 18.3139559 },
    { lat: 48.6924579, lng: 18.3137906 },
    { lat: 48.6924797, lng: 18.3135367 },
    { lat: 48.6924688, lng: 18.3132442 },
    { lat: 48.6925704, lng: 18.3132675 },
    { lat: 48.692727, lng: 18.3133238 },
    { lat: 48.6929246, lng: 18.3132258 },
    { lat: 48.6928791, lng: 18.3117407 },
    { lat: 48.6928677, lng: 18.3110822 },
    { lat: 48.6928327, lng: 18.3108028 },
    { lat: 48.6927327, lng: 18.3104318 },
    { lat: 48.6926251, lng: 18.3101254 },
    { lat: 48.6925411, lng: 18.3098155 },
    { lat: 48.6921263, lng: 18.3090245 },
    { lat: 48.6916381, lng: 18.3087115 },
    { lat: 48.691648, lng: 18.308658 },
    { lat: 48.6912365, lng: 18.3084959 },
    { lat: 48.6911734, lng: 18.3084369 },
    { lat: 48.6905656, lng: 18.3081429 },
    { lat: 48.6904567, lng: 18.3080734 },
    { lat: 48.6903875, lng: 18.308008 },
    { lat: 48.6903, lng: 18.3079943 },
    { lat: 48.6901146, lng: 18.3079108 },
    { lat: 48.6900312, lng: 18.3078326 },
    { lat: 48.6899775, lng: 18.3078814 },
    { lat: 48.6895283, lng: 18.3076191 },
    { lat: 48.6890302, lng: 18.3073895 },
    { lat: 48.6887521, lng: 18.3073011 },
    { lat: 48.6884459, lng: 18.3072714 },
    { lat: 48.6882141, lng: 18.3072235 },
    { lat: 48.6881241, lng: 18.3072308 },
    { lat: 48.6879633, lng: 18.3072594 },
    { lat: 48.68773, lng: 18.3072642 },
    { lat: 48.6874223, lng: 18.3072083 },
    { lat: 48.6873559, lng: 18.3071497 },
    { lat: 48.6873118, lng: 18.307189 },
    { lat: 48.6872193, lng: 18.3071888 },
    { lat: 48.6871839, lng: 18.3071233 },
    { lat: 48.687054, lng: 18.3071587 },
    { lat: 48.686875, lng: 18.3071661 },
    { lat: 48.6862147, lng: 18.307106 },
    { lat: 48.6861316, lng: 18.3070486 },
    { lat: 48.6859629, lng: 18.3070379 },
    { lat: 48.6859811, lng: 18.3066838 },
    { lat: 48.685371, lng: 18.3065312 },
    { lat: 48.6848217, lng: 18.3066714 },
    { lat: 48.6843793, lng: 18.3066932 },
    { lat: 48.6832255, lng: 18.3065387 },
    { lat: 48.6828487, lng: 18.3065388 },
    { lat: 48.6826405, lng: 18.30655 },
    { lat: 48.6807136, lng: 18.3071101 },
    { lat: 48.6790349, lng: 18.307615 },
    { lat: 48.6756004, lng: 18.3086485 },
    { lat: 48.6754538, lng: 18.3086525 },
    { lat: 48.6742778, lng: 18.308226 },
    { lat: 48.6743268, lng: 18.3091921 },
    { lat: 48.6743545, lng: 18.3101199 },
    { lat: 48.6743707, lng: 18.3102845 },
    { lat: 48.6743969, lng: 18.3109069 },
    { lat: 48.6743767, lng: 18.3119655 },
    { lat: 48.6743666, lng: 18.312886 },
    { lat: 48.6743474, lng: 18.313202 },
    { lat: 48.6743344, lng: 18.3135538 },
    { lat: 48.6742964, lng: 18.3138565 },
    { lat: 48.6741978, lng: 18.3153071 },
    { lat: 48.6741697, lng: 18.316105 },
    { lat: 48.6742643, lng: 18.3161021 },
    { lat: 48.6741774, lng: 18.3177179 },
    { lat: 48.6741176, lng: 18.3186592 },
    { lat: 48.6740359, lng: 18.3198646 },
    { lat: 48.6740101, lng: 18.3203291 },
    { lat: 48.6740189, lng: 18.320902 },
    { lat: 48.6740837, lng: 18.3216894 },
    { lat: 48.6741209, lng: 18.3216914 },
    { lat: 48.6742798, lng: 18.3222054 },
    { lat: 48.6744084, lng: 18.3227243 },
    { lat: 48.6744932, lng: 18.3231457 },
    { lat: 48.6745659, lng: 18.3240145 },
    { lat: 48.6745865, lng: 18.3243789 },
    { lat: 48.6746461, lng: 18.3246928 },
    { lat: 48.6746951, lng: 18.3248618 },
    { lat: 48.6746974, lng: 18.3250243 },
    { lat: 48.6748515, lng: 18.3251947 },
    { lat: 48.6749056, lng: 18.3255955 },
    { lat: 48.6749374, lng: 18.3259373 },
    { lat: 48.675051, lng: 18.3265089 },
    { lat: 48.6750509, lng: 18.3266081 },
    { lat: 48.6753981, lng: 18.3269026 },
    { lat: 48.6756467, lng: 18.3270898 },
    { lat: 48.6758527, lng: 18.3272207 },
    { lat: 48.6759046, lng: 18.3273018 },
    { lat: 48.6760437, lng: 18.3274256 },
    { lat: 48.6764057, lng: 18.3277228 },
    { lat: 48.6771357, lng: 18.3283043 },
    { lat: 48.6774789, lng: 18.3285872 },
    { lat: 48.6776262, lng: 18.3286171 },
    { lat: 48.6777202, lng: 18.3286061 },
    { lat: 48.6780088, lng: 18.3288014 },
    { lat: 48.6783743, lng: 18.3294005 },
    { lat: 48.6787461, lng: 18.329789 },
    { lat: 48.6798525, lng: 18.3301436 },
    { lat: 48.6798938, lng: 18.330154 },
    { lat: 48.6800242, lng: 18.3304039 },
    { lat: 48.6802944, lng: 18.3307137 },
    { lat: 48.6806195, lng: 18.3311792 },
    { lat: 48.6809539, lng: 18.3319477 },
    { lat: 48.6810254, lng: 18.332171 },
    { lat: 48.6811427, lng: 18.3327136 },
    { lat: 48.6812907, lng: 18.333178 },
    { lat: 48.6813925, lng: 18.3333816 },
    { lat: 48.6814624, lng: 18.3335601 },
    { lat: 48.6817355, lng: 18.3340019 },
    { lat: 48.6818729, lng: 18.3345559 },
    { lat: 48.6818589, lng: 18.3350069 },
    { lat: 48.6818084, lng: 18.3355329 },
    { lat: 48.6817634, lng: 18.3360763 },
    { lat: 48.6816775, lng: 18.336453 },
    { lat: 48.6813322, lng: 18.3371129 },
    { lat: 48.6811274, lng: 18.3379647 },
    { lat: 48.6811847, lng: 18.3396436 },
    { lat: 48.681077, lng: 18.3405733 },
    { lat: 48.6809962, lng: 18.3423453 },
    { lat: 48.6810407, lng: 18.3431946 },
    { lat: 48.681097, lng: 18.343543 },
    { lat: 48.681377, lng: 18.343455 },
    { lat: 48.681421, lng: 18.34344 },
    { lat: 48.681917, lng: 18.343324 },
    { lat: 48.682357, lng: 18.343045 },
    { lat: 48.682565, lng: 18.343027 },
    { lat: 48.682664, lng: 18.343059 },
    { lat: 48.682964, lng: 18.342876 },
    { lat: 48.683342, lng: 18.342601 },
    { lat: 48.683665, lng: 18.342411 },
    { lat: 48.683705, lng: 18.34221 },
    { lat: 48.683846, lng: 18.342105 },
    { lat: 48.68401, lng: 18.341804 },
    { lat: 48.684314, lng: 18.341667 },
    { lat: 48.684738, lng: 18.341334 },
    { lat: 48.685154, lng: 18.340743 },
    { lat: 48.685238, lng: 18.340524 },
    { lat: 48.685566, lng: 18.340183 },
    { lat: 48.68677, lng: 18.338858 },
    { lat: 48.687546, lng: 18.339086 },
    { lat: 48.687818, lng: 18.339394 },
    { lat: 48.688041, lng: 18.33961 },
    { lat: 48.688208, lng: 18.339717 },
    { lat: 48.688448, lng: 18.339622 },
    { lat: 48.688665, lng: 18.339765 },
    { lat: 48.688953, lng: 18.339889 },
    { lat: 48.68936, lng: 18.339736 },
    { lat: 48.689744, lng: 18.339522 },
    { lat: 48.690353, lng: 18.339375 },
    { lat: 48.690826, lng: 18.339347 },
    { lat: 48.690961, lng: 18.339537 },
    { lat: 48.6912, lng: 18.339641 },
    { lat: 48.691536, lng: 18.339814 },
    { lat: 48.691613, lng: 18.33967 },
    { lat: 48.691908, lng: 18.339385 },
    { lat: 48.69196, lng: 18.339369 },
    { lat: 48.692019, lng: 18.339326 },
    { lat: 48.692061, lng: 18.339302 },
    { lat: 48.692178, lng: 18.339285 },
    { lat: 48.692343, lng: 18.339358 },
  ],
  Zlatníky: [
    { lat: 48.696904, lng: 18.105268 },
    { lat: 48.6969175, lng: 18.1053344 },
    { lat: 48.6969652, lng: 18.1054342 },
    { lat: 48.6969704, lng: 18.1057786 },
    { lat: 48.6968323, lng: 18.1060016 },
    { lat: 48.6964524, lng: 18.1064116 },
    { lat: 48.6963346, lng: 18.1066381 },
    { lat: 48.6962166, lng: 18.1067803 },
    { lat: 48.696007, lng: 18.1069821 },
    { lat: 48.6958688, lng: 18.107146 },
    { lat: 48.6958051, lng: 18.1071977 },
    { lat: 48.6957477, lng: 18.1072971 },
    { lat: 48.6956848, lng: 18.1074548 },
    { lat: 48.6955501, lng: 18.1078168 },
    { lat: 48.695471, lng: 18.1079684 },
    { lat: 48.6953969, lng: 18.108149 },
    { lat: 48.6952918, lng: 18.1085542 },
    { lat: 48.6952634, lng: 18.1087026 },
    { lat: 48.6950902, lng: 18.1091597 },
    { lat: 48.6950288, lng: 18.1094047 },
    { lat: 48.6949411, lng: 18.1097175 },
    { lat: 48.694873, lng: 18.1099231 },
    { lat: 48.694837, lng: 18.1100608 },
    { lat: 48.6947536, lng: 18.1101801 },
    { lat: 48.6946989, lng: 18.1104785 },
    { lat: 48.6946775, lng: 18.1107321 },
    { lat: 48.6946242, lng: 18.110885 },
    { lat: 48.6945239, lng: 18.1110991 },
    { lat: 48.694453, lng: 18.1113139 },
    { lat: 48.6943998, lng: 18.1116221 },
    { lat: 48.6943881, lng: 18.1117877 },
    { lat: 48.6944016, lng: 18.1118841 },
    { lat: 48.6944802, lng: 18.1121094 },
    { lat: 48.6945204, lng: 18.112372 },
    { lat: 48.6945173, lng: 18.112611 },
    { lat: 48.694501, lng: 18.1127947 },
    { lat: 48.6944784, lng: 18.1129698 },
    { lat: 48.6944774, lng: 18.1132622 },
    { lat: 48.6945057, lng: 18.1134188 },
    { lat: 48.6945425, lng: 18.1141059 },
    { lat: 48.6945615, lng: 18.1143559 },
    { lat: 48.694871, lng: 18.1142454 },
    { lat: 48.6950201, lng: 18.1141702 },
    { lat: 48.6951203, lng: 18.1141444 },
    { lat: 48.6952086, lng: 18.1140726 },
    { lat: 48.6953703, lng: 18.1139829 },
    { lat: 48.6955548, lng: 18.113873 },
    { lat: 48.695867, lng: 18.1137332 },
    { lat: 48.6959787, lng: 18.1136723 },
    { lat: 48.6963338, lng: 18.113415 },
    { lat: 48.696434, lng: 18.1133137 },
    { lat: 48.6965763, lng: 18.1131212 },
    { lat: 48.6966563, lng: 18.1130452 },
    { lat: 48.6969029, lng: 18.1128413 },
    { lat: 48.6970107, lng: 18.1127926 },
    { lat: 48.6971847, lng: 18.1126977 },
    { lat: 48.6973127, lng: 18.1126099 },
    { lat: 48.6973945, lng: 18.1126015 },
    { lat: 48.697447, lng: 18.1125738 },
    { lat: 48.697585, lng: 18.1126926 },
    { lat: 48.697673, lng: 18.1128106 },
    { lat: 48.6976999, lng: 18.1128842 },
    { lat: 48.6977382, lng: 18.1129402 },
    { lat: 48.6978347, lng: 18.1130452 },
    { lat: 48.6979562, lng: 18.1131584 },
    { lat: 48.6981724, lng: 18.1133211 },
    { lat: 48.698418, lng: 18.1135464 },
    { lat: 48.6985282, lng: 18.113676 },
    { lat: 48.6986514, lng: 18.1137947 },
    { lat: 48.698821, lng: 18.1140701 },
    { lat: 48.6989159, lng: 18.11419 },
    { lat: 48.6990365, lng: 18.1142836 },
    { lat: 48.6991105, lng: 18.1143559 },
    { lat: 48.6993621, lng: 18.1146226 },
    { lat: 48.6994403, lng: 18.1146493 },
    { lat: 48.6995232, lng: 18.114734 },
    { lat: 48.6996756, lng: 18.1148327 },
    { lat: 48.6998544, lng: 18.1148818 },
    { lat: 48.7000012, lng: 18.1149984 },
    { lat: 48.7001315, lng: 18.1150843 },
    { lat: 48.7002754, lng: 18.1151131 },
    { lat: 48.7004074, lng: 18.1152288 },
    { lat: 48.7006176, lng: 18.1153494 },
    { lat: 48.7008115, lng: 18.1155398 },
    { lat: 48.7008982, lng: 18.1155744 },
    { lat: 48.7009742, lng: 18.1156614 },
    { lat: 48.7010641, lng: 18.1157316 },
    { lat: 48.701341, lng: 18.1159131 },
    { lat: 48.7013899, lng: 18.1159573 },
    { lat: 48.7011878, lng: 18.1172336 },
    { lat: 48.7013085, lng: 18.1180825 },
    { lat: 48.7015306, lng: 18.11868 },
    { lat: 48.7016468, lng: 18.1188564 },
    { lat: 48.7019218, lng: 18.119141 },
    { lat: 48.7021716, lng: 18.1195361 },
    { lat: 48.7029113, lng: 18.1206679 },
    { lat: 48.7032011, lng: 18.1212839 },
    { lat: 48.7033003, lng: 18.121379 },
    { lat: 48.7033631, lng: 18.1214704 },
    { lat: 48.7033446, lng: 18.1222167 },
    { lat: 48.7031505, lng: 18.1236516 },
    { lat: 48.7034669, lng: 18.124626 },
    { lat: 48.7045069, lng: 18.1267349 },
    { lat: 48.7051532, lng: 18.1276384 },
    { lat: 48.7051697, lng: 18.1276894 },
    { lat: 48.7058768, lng: 18.129019 },
    { lat: 48.7059437, lng: 18.129182 },
    { lat: 48.7060267, lng: 18.1293485 },
    { lat: 48.7061503, lng: 18.129454 },
    { lat: 48.706326, lng: 18.1299327 },
    { lat: 48.70646, lng: 18.1300456 },
    { lat: 48.7065435, lng: 18.1302772 },
    { lat: 48.706592, lng: 18.1303354 },
    { lat: 48.7069926, lng: 18.1306485 },
    { lat: 48.707193, lng: 18.1307348 },
    { lat: 48.7072607, lng: 18.1307726 },
    { lat: 48.7075097, lng: 18.1308057 },
    { lat: 48.7076853, lng: 18.1308774 },
    { lat: 48.7077771, lng: 18.1308827 },
    { lat: 48.7080454, lng: 18.1314002 },
    { lat: 48.708116, lng: 18.1314888 },
    { lat: 48.709212, lng: 18.1330291 },
    { lat: 48.7093982, lng: 18.1334168 },
    { lat: 48.7098673, lng: 18.1345752 },
    { lat: 48.7098795, lng: 18.1346111 },
    { lat: 48.7102113, lng: 18.1345549 },
    { lat: 48.7102279, lng: 18.134587 },
    { lat: 48.7106448, lng: 18.1355279 },
    { lat: 48.710958, lng: 18.136429 },
    { lat: 48.7113798, lng: 18.1376298 },
    { lat: 48.7114544, lng: 18.1378465 },
    { lat: 48.7119093, lng: 18.137809 },
    { lat: 48.7121778, lng: 18.1380214 },
    { lat: 48.7124567, lng: 18.137849 },
    { lat: 48.7126482, lng: 18.137824 },
    { lat: 48.7128146, lng: 18.1377165 },
    { lat: 48.7130374, lng: 18.1381319 },
    { lat: 48.713702, lng: 18.1392373 },
    { lat: 48.7143207, lng: 18.1402177 },
    { lat: 48.7148487, lng: 18.1409543 },
    { lat: 48.7153698, lng: 18.1414727 },
    { lat: 48.7162917, lng: 18.1423476 },
    { lat: 48.7172778, lng: 18.1428116 },
    { lat: 48.7179737, lng: 18.1429985 },
    { lat: 48.7187283, lng: 18.1434957 },
    { lat: 48.7192955, lng: 18.1439959 },
    { lat: 48.7195387, lng: 18.1441242 },
    { lat: 48.7197666, lng: 18.143907 },
    { lat: 48.7200349, lng: 18.1433965 },
    { lat: 48.7201666, lng: 18.1424988 },
    { lat: 48.7201013, lng: 18.1420132 },
    { lat: 48.7200774, lng: 18.141659 },
    { lat: 48.7201215, lng: 18.1411753 },
    { lat: 48.7203968, lng: 18.1398318 },
    { lat: 48.7206933, lng: 18.1382343 },
    { lat: 48.720888, lng: 18.1373682 },
    { lat: 48.7210918, lng: 18.1368372 },
    { lat: 48.7214596, lng: 18.1363671 },
    { lat: 48.7216724, lng: 18.1359303 },
    { lat: 48.7217256, lng: 18.1354504 },
    { lat: 48.7216854, lng: 18.1351284 },
    { lat: 48.7223688, lng: 18.1331574 },
    { lat: 48.7231301, lng: 18.1315763 },
    { lat: 48.7239682, lng: 18.1303277 },
    { lat: 48.7240024, lng: 18.1302731 },
    { lat: 48.7243421, lng: 18.1297709 },
    { lat: 48.7256935, lng: 18.1283053 },
    { lat: 48.7265866, lng: 18.1274779 },
    { lat: 48.7275492, lng: 18.1264675 },
    { lat: 48.7280588, lng: 18.1256627 },
    { lat: 48.7282443, lng: 18.1251691 },
    { lat: 48.7286578, lng: 18.1246698 },
    { lat: 48.7288636, lng: 18.1244855 },
    { lat: 48.7291776, lng: 18.1241378 },
    { lat: 48.7293631, lng: 18.1239895 },
    { lat: 48.7299035, lng: 18.1233479 },
    { lat: 48.7299713, lng: 18.1229814 },
    { lat: 48.7304629, lng: 18.1226429 },
    { lat: 48.7309341, lng: 18.122073 },
    { lat: 48.7312759, lng: 18.1215657 },
    { lat: 48.7315173, lng: 18.1211751 },
    { lat: 48.7317439, lng: 18.1207649 },
    { lat: 48.7327374, lng: 18.119511 },
    { lat: 48.7328478, lng: 18.1193562 },
    { lat: 48.7330647, lng: 18.1190846 },
    { lat: 48.7331174, lng: 18.1189249 },
    { lat: 48.733318, lng: 18.1177768 },
    { lat: 48.7335942, lng: 18.1165903 },
    { lat: 48.7338134, lng: 18.1158401 },
    { lat: 48.7342256, lng: 18.1144678 },
    { lat: 48.7344867, lng: 18.112633 },
    { lat: 48.7350128, lng: 18.1112241 },
    { lat: 48.7353852, lng: 18.1104101 },
    { lat: 48.73569, lng: 18.1095794 },
    { lat: 48.7361371, lng: 18.1088313 },
    { lat: 48.7364653, lng: 18.1081898 },
    { lat: 48.7365263, lng: 18.1082299 },
    { lat: 48.7366907, lng: 18.1075859 },
    { lat: 48.7367483, lng: 18.1073162 },
    { lat: 48.7367659, lng: 18.107095 },
    { lat: 48.736759, lng: 18.1067248 },
    { lat: 48.7367457, lng: 18.1062658 },
    { lat: 48.736693, lng: 18.105532 },
    { lat: 48.736672, lng: 18.105471 },
    { lat: 48.736629, lng: 18.105343 },
    { lat: 48.736589, lng: 18.105228 },
    { lat: 48.736368, lng: 18.104614 },
    { lat: 48.736215, lng: 18.104215 },
    { lat: 48.736171, lng: 18.104042 },
    { lat: 48.736061, lng: 18.10375 },
    { lat: 48.735996, lng: 18.103325 },
    { lat: 48.735978, lng: 18.103039 },
    { lat: 48.73593, lng: 18.102585 },
    { lat: 48.736063, lng: 18.102084 },
    { lat: 48.736184, lng: 18.101824 },
    { lat: 48.736322, lng: 18.101529 },
    { lat: 48.736353, lng: 18.101462 },
    { lat: 48.736397, lng: 18.101365 },
    { lat: 48.73667, lng: 18.100932 },
    { lat: 48.73677, lng: 18.10073 },
    { lat: 48.736908, lng: 18.100494 },
    { lat: 48.737693, lng: 18.099405 },
    { lat: 48.737885, lng: 18.099258 },
    { lat: 48.737983, lng: 18.099084 },
    { lat: 48.738021, lng: 18.099048 },
    { lat: 48.738125, lng: 18.098945 },
    { lat: 48.73821, lng: 18.098862 },
    { lat: 48.738702, lng: 18.098302 },
    { lat: 48.738675, lng: 18.098204 },
    { lat: 48.738648, lng: 18.098166 },
    { lat: 48.738618, lng: 18.098121 },
    { lat: 48.738597, lng: 18.098091 },
    { lat: 48.738444, lng: 18.097864 },
    { lat: 48.738271, lng: 18.097498 },
    { lat: 48.738148, lng: 18.097167 },
    { lat: 48.738119, lng: 18.097016 },
    { lat: 48.737961, lng: 18.096704 },
    { lat: 48.737943, lng: 18.096666 },
    { lat: 48.737876, lng: 18.096563 },
    { lat: 48.73778, lng: 18.096414 },
    { lat: 48.737686, lng: 18.096231 },
    { lat: 48.737531, lng: 18.096027 },
    { lat: 48.737255, lng: 18.095524 },
    { lat: 48.737229, lng: 18.095474 },
    { lat: 48.737217, lng: 18.095451 },
    { lat: 48.73724, lng: 18.095415 },
    { lat: 48.737372, lng: 18.095401 },
    { lat: 48.737454, lng: 18.095299 },
    { lat: 48.737401, lng: 18.095128 },
    { lat: 48.737399, lng: 18.095067 },
    { lat: 48.737564, lng: 18.09496 },
    { lat: 48.737558, lng: 18.094875 },
    { lat: 48.737698, lng: 18.094821 },
    { lat: 48.737798, lng: 18.094706 },
    { lat: 48.737815, lng: 18.094694 },
    { lat: 48.737941, lng: 18.094727 },
    { lat: 48.738112, lng: 18.094624 },
    { lat: 48.738175, lng: 18.09423 },
    { lat: 48.738154, lng: 18.094134 },
    { lat: 48.738127, lng: 18.094081 },
    { lat: 48.738141, lng: 18.094065 },
    { lat: 48.738184, lng: 18.09401 },
    { lat: 48.738237, lng: 18.093968 },
    { lat: 48.738426, lng: 18.093615 },
    { lat: 48.73845, lng: 18.093486 },
    { lat: 48.738511, lng: 18.093335 },
    { lat: 48.738537, lng: 18.09332 },
    { lat: 48.738611, lng: 18.093417 },
    { lat: 48.738711, lng: 18.093162 },
    { lat: 48.738909, lng: 18.093044 },
    { lat: 48.738959, lng: 18.092982 },
    { lat: 48.738974, lng: 18.092791 },
    { lat: 48.739055, lng: 18.092694 },
    { lat: 48.739155, lng: 18.092629 },
    { lat: 48.739239, lng: 18.092544 },
    { lat: 48.739282, lng: 18.092297 },
    { lat: 48.739276, lng: 18.092135 },
    { lat: 48.73936, lng: 18.092055 },
    { lat: 48.73946, lng: 18.0919 },
    { lat: 48.739576, lng: 18.091901 },
    { lat: 48.739648, lng: 18.091813 },
    { lat: 48.739689, lng: 18.091634 },
    { lat: 48.739761, lng: 18.09159 },
    { lat: 48.739833, lng: 18.091473 },
    { lat: 48.739952, lng: 18.091465 },
    { lat: 48.740052, lng: 18.091285 },
    { lat: 48.740026, lng: 18.09119 },
    { lat: 48.740146, lng: 18.090924 },
    { lat: 48.74017, lng: 18.090925 },
    { lat: 48.740206, lng: 18.090948 },
    { lat: 48.740254, lng: 18.090942 },
    { lat: 48.740306, lng: 18.09066 },
    { lat: 48.740453, lng: 18.090411 },
    { lat: 48.740621, lng: 18.090414 },
    { lat: 48.740721, lng: 18.090267 },
    { lat: 48.740816, lng: 18.089987 },
    { lat: 48.740717, lng: 18.08975 },
    { lat: 48.740688, lng: 18.089591 },
    { lat: 48.740703, lng: 18.089523 },
    { lat: 48.740818, lng: 18.089476 },
    { lat: 48.740873, lng: 18.089336 },
    { lat: 48.740891, lng: 18.089168 },
    { lat: 48.740957, lng: 18.089108 },
    { lat: 48.741037, lng: 18.089135 },
    { lat: 48.741134, lng: 18.089145 },
    { lat: 48.74123, lng: 18.089002 },
    { lat: 48.74141, lng: 18.088816 },
    { lat: 48.741435, lng: 18.088654 },
    { lat: 48.741499, lng: 18.088556 },
    { lat: 48.741571, lng: 18.088456 },
    { lat: 48.741715, lng: 18.088449 },
    { lat: 48.741805, lng: 18.088332 },
    { lat: 48.741905, lng: 18.088144 },
    { lat: 48.742017, lng: 18.088012 },
    { lat: 48.742062, lng: 18.087802 },
    { lat: 48.742149, lng: 18.087677 },
    { lat: 48.742169, lng: 18.087644 },
    { lat: 48.742265, lng: 18.087771 },
    { lat: 48.742286, lng: 18.08776 },
    { lat: 48.742316, lng: 18.087736 },
    { lat: 48.742338, lng: 18.0877 },
    { lat: 48.742335, lng: 18.087479 },
    { lat: 48.742648, lng: 18.087126 },
    { lat: 48.742706, lng: 18.087104 },
    { lat: 48.742822, lng: 18.08702 },
    { lat: 48.742976, lng: 18.086576 },
    { lat: 48.743079, lng: 18.086551 },
    { lat: 48.743087, lng: 18.08646 },
    { lat: 48.74325, lng: 18.08622 },
    { lat: 48.74334, lng: 18.086212 },
    { lat: 48.743531, lng: 18.085873 },
    { lat: 48.74373, lng: 18.085823 },
    { lat: 48.743873, lng: 18.085654 },
    { lat: 48.744076, lng: 18.0856 },
    { lat: 48.744118, lng: 18.085402 },
    { lat: 48.744334, lng: 18.085163 },
    { lat: 48.744793, lng: 18.084485 },
    { lat: 48.744891, lng: 18.084539 },
    { lat: 48.745046, lng: 18.084369 },
    { lat: 48.74519, lng: 18.084365 },
    { lat: 48.745437, lng: 18.084242 },
    { lat: 48.745578, lng: 18.084256 },
    { lat: 48.745702, lng: 18.084152 },
    { lat: 48.745741, lng: 18.083948 },
    { lat: 48.745933, lng: 18.083793 },
    { lat: 48.746041, lng: 18.083672 },
    { lat: 48.74621, lng: 18.083544 },
    { lat: 48.746437, lng: 18.083432 },
    { lat: 48.746492, lng: 18.083329 },
    { lat: 48.746518, lng: 18.083167 },
    { lat: 48.746604, lng: 18.083082 },
    { lat: 48.746715, lng: 18.083056 },
    { lat: 48.746906, lng: 18.082892 },
    { lat: 48.747042, lng: 18.082779 },
    { lat: 48.747216, lng: 18.082696 },
    { lat: 48.747275, lng: 18.082567 },
    { lat: 48.747365, lng: 18.082568 },
    { lat: 48.747536, lng: 18.082476 },
    { lat: 48.747649, lng: 18.082383 },
    { lat: 48.747718, lng: 18.082449 },
    { lat: 48.747827, lng: 18.082297 },
    { lat: 48.747916, lng: 18.082266 },
    { lat: 48.748043, lng: 18.082052 },
    { lat: 48.748166, lng: 18.081996 },
    { lat: 48.748218, lng: 18.081911 },
    { lat: 48.748256, lng: 18.081785 },
    { lat: 48.748436, lng: 18.081633 },
    { lat: 48.748559, lng: 18.081492 },
    { lat: 48.748861, lng: 18.08128 },
    { lat: 48.748936, lng: 18.081347 },
    { lat: 48.749023, lng: 18.081247 },
    { lat: 48.749119, lng: 18.081126 },
    { lat: 48.749123, lng: 18.081094 },
    { lat: 48.749339, lng: 18.080892 },
    { lat: 48.749501, lng: 18.080832 },
    { lat: 48.749649, lng: 18.080648 },
    { lat: 48.749942, lng: 18.080487 },
    { lat: 48.750131, lng: 18.080069 },
    { lat: 48.750201, lng: 18.079973 },
    { lat: 48.750237, lng: 18.079866 },
    { lat: 48.750348, lng: 18.079719 },
    { lat: 48.750324, lng: 18.079563 },
    { lat: 48.750397, lng: 18.079392 },
    { lat: 48.750459, lng: 18.079406 },
    { lat: 48.750619, lng: 18.079239 },
    { lat: 48.750669, lng: 18.079232 },
    { lat: 48.750796, lng: 18.079038 },
    { lat: 48.750811, lng: 18.078919 },
    { lat: 48.751115, lng: 18.078596 },
    { lat: 48.751191, lng: 18.078411 },
    { lat: 48.751346, lng: 18.078348 },
    { lat: 48.7514, lng: 18.078205 },
    { lat: 48.751454, lng: 18.077992 },
    { lat: 48.751531, lng: 18.077879 },
    { lat: 48.751637, lng: 18.077872 },
    { lat: 48.751808, lng: 18.077706 },
    { lat: 48.751917, lng: 18.077528 },
    { lat: 48.752131, lng: 18.077275 },
    { lat: 48.752274, lng: 18.076971 },
    { lat: 48.752363, lng: 18.076819 },
    { lat: 48.752389, lng: 18.07682 },
    { lat: 48.752539, lng: 18.076478 },
    { lat: 48.752607, lng: 18.076015 },
    { lat: 48.752598, lng: 18.07584 },
    { lat: 48.752671, lng: 18.075421 },
    { lat: 48.752748, lng: 18.075382 },
    { lat: 48.752837, lng: 18.075048 },
    { lat: 48.75284, lng: 18.074969 },
    { lat: 48.752796, lng: 18.074865 },
    { lat: 48.752799, lng: 18.074711 },
    { lat: 48.752772, lng: 18.074625 },
    { lat: 48.752802, lng: 18.074382 },
    { lat: 48.752792, lng: 18.074366 },
    { lat: 48.752803, lng: 18.074077 },
    { lat: 48.752804, lng: 18.074043 },
    { lat: 48.752903, lng: 18.073486 },
    { lat: 48.752992, lng: 18.073174 },
    { lat: 48.75299, lng: 18.072975 },
    { lat: 48.752987, lng: 18.072872 },
    { lat: 48.752919, lng: 18.072848 },
    { lat: 48.752811, lng: 18.072464 },
    { lat: 48.752813, lng: 18.072176 },
    { lat: 48.752759, lng: 18.071689 },
    { lat: 48.752847, lng: 18.071457 },
    { lat: 48.752843, lng: 18.071322 },
    { lat: 48.752814, lng: 18.07112 },
    { lat: 48.752752, lng: 18.070918 },
    { lat: 48.752708, lng: 18.070558 },
    { lat: 48.752742, lng: 18.070446 },
    { lat: 48.752829, lng: 18.070424 },
    { lat: 48.752931, lng: 18.070503 },
    { lat: 48.752993, lng: 18.070222 },
    { lat: 48.752998, lng: 18.070046 },
    { lat: 48.753117, lng: 18.069501 },
    { lat: 48.753131, lng: 18.069471 },
    { lat: 48.753187, lng: 18.069425 },
    { lat: 48.753237, lng: 18.069043 },
    { lat: 48.753212, lng: 18.06875 },
    { lat: 48.753374, lng: 18.068387 },
    { lat: 48.753511, lng: 18.068067 },
    { lat: 48.753887, lng: 18.067443 },
    { lat: 48.753905, lng: 18.067317 },
    { lat: 48.753991, lng: 18.067114 },
    { lat: 48.75408, lng: 18.06709 },
    { lat: 48.754201, lng: 18.066974 },
    { lat: 48.75423, lng: 18.066945 },
    { lat: 48.754352, lng: 18.066936 },
    { lat: 48.754351, lng: 18.06691 },
    { lat: 48.754425, lng: 18.066791 },
    { lat: 48.754646, lng: 18.066436 },
    { lat: 48.754748, lng: 18.066161 },
    { lat: 48.754916, lng: 18.065894 },
    { lat: 48.755022, lng: 18.065682 },
    { lat: 48.755176, lng: 18.065166 },
    { lat: 48.755387, lng: 18.064607 },
    { lat: 48.755496, lng: 18.064001 },
    { lat: 48.755677, lng: 18.063551 },
    { lat: 48.75577, lng: 18.063083 },
    { lat: 48.755876, lng: 18.062478 },
    { lat: 48.755973, lng: 18.061787 },
    { lat: 48.755994, lng: 18.061155 },
    { lat: 48.755936, lng: 18.060301 },
    { lat: 48.755951, lng: 18.059564 },
    { lat: 48.756017, lng: 18.058944 },
    { lat: 48.755978, lng: 18.058253 },
    { lat: 48.756004, lng: 18.057745 },
    { lat: 48.75609, lng: 18.057358 },
    { lat: 48.756262, lng: 18.056742 },
    { lat: 48.756625, lng: 18.05605 },
    { lat: 48.756887, lng: 18.055531 },
    { lat: 48.756983, lng: 18.055371 },
    { lat: 48.757228, lng: 18.054963 },
    { lat: 48.757584, lng: 18.054496 },
    { lat: 48.757922, lng: 18.05386 },
    { lat: 48.758018, lng: 18.053371 },
    { lat: 48.758275, lng: 18.05268 },
    { lat: 48.758532, lng: 18.051985 },
    { lat: 48.758468, lng: 18.051555 },
    { lat: 48.75845, lng: 18.051186 },
    { lat: 48.758483, lng: 18.050791 },
    { lat: 48.758649, lng: 18.050275 },
    { lat: 48.7587, lng: 18.049911 },
    { lat: 48.758764, lng: 18.049577 },
    { lat: 48.758936, lng: 18.049118 },
    { lat: 48.759061, lng: 18.048266 },
    { lat: 48.759102, lng: 18.047386 },
    { lat: 48.759223, lng: 18.04697 },
    { lat: 48.759413, lng: 18.046458 },
    { lat: 48.759525, lng: 18.046147 },
    { lat: 48.759744, lng: 18.045766 },
    { lat: 48.759899, lng: 18.04507 },
    { lat: 48.760456, lng: 18.044302 },
    { lat: 48.760859, lng: 18.043563 },
    { lat: 48.761029, lng: 18.043239 },
    { lat: 48.761284, lng: 18.042919 },
    { lat: 48.76164, lng: 18.042367 },
    { lat: 48.761766, lng: 18.042047 },
    { lat: 48.761871, lng: 18.04178 },
    { lat: 48.762126, lng: 18.041236 },
    { lat: 48.762322, lng: 18.040938 },
    { lat: 48.762564, lng: 18.04051 },
    { lat: 48.762842, lng: 18.040115 },
    { lat: 48.76303, lng: 18.039682 },
    { lat: 48.763321, lng: 18.039181 },
    { lat: 48.763688, lng: 18.038742 },
    { lat: 48.764276, lng: 18.038442 },
    { lat: 48.765231, lng: 18.037872 },
    { lat: 48.765772, lng: 18.037621 },
    { lat: 48.766239, lng: 18.037385 },
    { lat: 48.766342, lng: 18.037375 },
    { lat: 48.766344, lng: 18.037363 },
    { lat: 48.765866, lng: 18.037347 },
    { lat: 48.765646, lng: 18.0373 },
    { lat: 48.764967, lng: 18.037153 },
    { lat: 48.764551, lng: 18.036991 },
    { lat: 48.764108, lng: 18.036782 },
    { lat: 48.763571, lng: 18.036406 },
    { lat: 48.763329, lng: 18.036124 },
    { lat: 48.762623, lng: 18.034407 },
    { lat: 48.762285, lng: 18.034094 },
    { lat: 48.761991, lng: 18.033979 },
    { lat: 48.760897, lng: 18.033779 },
    { lat: 48.7604, lng: 18.033502 },
    { lat: 48.760023, lng: 18.033212 },
    { lat: 48.759981, lng: 18.033179 },
    { lat: 48.759561, lng: 18.032846 },
    { lat: 48.758819, lng: 18.032202 },
    { lat: 48.758032, lng: 18.031754 },
    { lat: 48.757547, lng: 18.031528 },
    { lat: 48.756822, lng: 18.030991 },
    { lat: 48.756156, lng: 18.030306 },
    { lat: 48.755665, lng: 18.029952 },
    { lat: 48.75536, lng: 18.029463 },
    { lat: 48.754794, lng: 18.02837 },
    { lat: 48.754585, lng: 18.027859 },
    { lat: 48.754437, lng: 18.027375 },
    { lat: 48.754362, lng: 18.026573 },
    { lat: 48.754298, lng: 18.026363 },
    { lat: 48.753947, lng: 18.025215 },
    { lat: 48.753786, lng: 18.024832 },
    { lat: 48.753451, lng: 18.024245 },
    { lat: 48.753045, lng: 18.023021 },
    { lat: 48.753112, lng: 18.022304 },
    { lat: 48.753008, lng: 18.021815 },
    { lat: 48.752768, lng: 18.021494 },
    { lat: 48.752841, lng: 18.021213 },
    { lat: 48.753177, lng: 18.020716 },
    { lat: 48.753218, lng: 18.019543 },
    { lat: 48.753149, lng: 18.019042 },
    { lat: 48.752904, lng: 18.018407 },
    { lat: 48.752633, lng: 18.01763 },
    { lat: 48.752479, lng: 18.016703 },
    { lat: 48.752205, lng: 18.015492 },
    { lat: 48.751761, lng: 18.01423 },
    { lat: 48.751672, lng: 18.013523 },
    { lat: 48.751622, lng: 18.012964 },
    { lat: 48.751236, lng: 18.01192 },
    { lat: 48.751022, lng: 18.011211 },
    { lat: 48.750515, lng: 18.01005 },
    { lat: 48.750421, lng: 18.009808 },
    { lat: 48.750222, lng: 18.009146 },
    { lat: 48.749979, lng: 18.00769 },
    { lat: 48.749715, lng: 18.007003 },
    { lat: 48.749544, lng: 18.006855 },
    { lat: 48.749154, lng: 18.006209 },
    { lat: 48.748931, lng: 18.005673 },
    { lat: 48.748431, lng: 18.004918 },
    { lat: 48.748026, lng: 18.004208 },
    { lat: 48.747978, lng: 18.003499 },
    { lat: 48.747977, lng: 18.003482 },
    { lat: 48.747213, lng: 18.003117 },
    { lat: 48.746393, lng: 18.002908 },
    { lat: 48.74559, lng: 18.0029 },
    { lat: 48.744798, lng: 18.002522 },
    { lat: 48.744285, lng: 18.001574 },
    { lat: 48.743703, lng: 18.000804 },
    { lat: 48.742925, lng: 18.000061 },
    { lat: 48.742224, lng: 17.999396 },
    { lat: 48.741524, lng: 17.999373 },
    { lat: 48.74105, lng: 17.999404 },
    { lat: 48.74094, lng: 17.999987 },
    { lat: 48.740298, lng: 18.00007 },
    { lat: 48.739099, lng: 18.00011 },
    { lat: 48.738064, lng: 18.000225 },
    { lat: 48.737436, lng: 17.999852 },
    { lat: 48.736744, lng: 17.998942 },
    { lat: 48.736033, lng: 17.998338 },
    { lat: 48.735382, lng: 17.997874 },
    { lat: 48.73383, lng: 17.998448 },
    { lat: 48.733163, lng: 17.998968 },
    { lat: 48.73255, lng: 17.999074 },
    { lat: 48.731669, lng: 17.998508 },
    { lat: 48.730991, lng: 17.998134 },
    { lat: 48.730528, lng: 17.997816 },
    { lat: 48.729587, lng: 17.997665 },
    { lat: 48.729173, lng: 17.997495 },
    { lat: 48.72845, lng: 17.997159 },
    { lat: 48.727432, lng: 17.997234 },
    { lat: 48.726629, lng: 17.997391 },
    { lat: 48.725569, lng: 17.998163 },
    { lat: 48.724721, lng: 17.998304 },
    { lat: 48.724352, lng: 17.998409 },
    { lat: 48.723645, lng: 17.998441 },
    { lat: 48.722806, lng: 17.998215 },
    { lat: 48.722774, lng: 17.998237 },
    { lat: 48.722541, lng: 17.998359 },
    { lat: 48.722003, lng: 17.998621 },
    { lat: 48.721868, lng: 17.998878 },
    { lat: 48.721823, lng: 17.998965 },
    { lat: 48.72168, lng: 17.999099 },
    { lat: 48.721256, lng: 17.999309 },
    { lat: 48.721046, lng: 17.999504 },
    { lat: 48.720817, lng: 17.999868 },
    { lat: 48.720589, lng: 18.000144 },
    { lat: 48.72029, lng: 18.000684 },
    { lat: 48.71996, lng: 18.001128 },
    { lat: 48.719831, lng: 18.001369 },
    { lat: 48.719628, lng: 18.001674 },
    { lat: 48.719416, lng: 18.002076 },
    { lat: 48.719324, lng: 18.002301 },
    { lat: 48.719036, lng: 18.002761 },
    { lat: 48.718745, lng: 18.003229 },
    { lat: 48.718503, lng: 18.003533 },
    { lat: 48.718364, lng: 18.003747 },
    { lat: 48.717961, lng: 18.004147 },
    { lat: 48.717949, lng: 18.004117 },
    { lat: 48.717677, lng: 18.004579 },
    { lat: 48.717463, lng: 18.00477 },
    { lat: 48.717318, lng: 18.004865 },
    { lat: 48.717111, lng: 18.005238 },
    { lat: 48.716965, lng: 18.005338 },
    { lat: 48.716711, lng: 18.005397 },
    { lat: 48.716521, lng: 18.005462 },
    { lat: 48.716382, lng: 18.005491 },
    { lat: 48.716151, lng: 18.005591 },
    { lat: 48.716071, lng: 18.005645 },
    { lat: 48.715762, lng: 18.005935 },
    { lat: 48.715304, lng: 18.006045 },
    { lat: 48.715179, lng: 18.005964 },
    { lat: 48.714946, lng: 18.005929 },
    { lat: 48.714938, lng: 18.005928 },
    { lat: 48.713475, lng: 18.005524 },
    { lat: 48.713902, lng: 18.006722 },
    { lat: 48.713062, lng: 18.010064 },
    { lat: 48.712691, lng: 18.011623 },
    { lat: 48.712217, lng: 18.012449 },
    { lat: 48.713116, lng: 18.014468 },
    { lat: 48.713759, lng: 18.016464 },
    { lat: 48.713881, lng: 18.016667 },
    { lat: 48.713863, lng: 18.01677 },
    { lat: 48.713894, lng: 18.016936 },
    { lat: 48.713845, lng: 18.017128 },
    { lat: 48.713744, lng: 18.017284 },
    { lat: 48.713859, lng: 18.017302 },
    { lat: 48.714023, lng: 18.017478 },
    { lat: 48.713924, lng: 18.017805 },
    { lat: 48.713897, lng: 18.018018 },
    { lat: 48.713841, lng: 18.018159 },
    { lat: 48.713879, lng: 18.018363 },
    { lat: 48.713831, lng: 18.018548 },
    { lat: 48.713928, lng: 18.018646 },
    { lat: 48.713774, lng: 18.019029 },
    { lat: 48.71361, lng: 18.019395 },
    { lat: 48.713495, lng: 18.019526 },
    { lat: 48.713399, lng: 18.019818 },
    { lat: 48.713746, lng: 18.02023 },
    { lat: 48.713822, lng: 18.020467 },
    { lat: 48.714179, lng: 18.020912 },
    { lat: 48.714299, lng: 18.02115 },
    { lat: 48.714406, lng: 18.021423 },
    { lat: 48.714431, lng: 18.021794 },
    { lat: 48.714348, lng: 18.022059 },
    { lat: 48.71406, lng: 18.022476 },
    { lat: 48.713766, lng: 18.022651 },
    { lat: 48.713672, lng: 18.022627 },
    { lat: 48.713595, lng: 18.022638 },
    { lat: 48.713568, lng: 18.022763 },
    { lat: 48.713593, lng: 18.022944 },
    { lat: 48.713503, lng: 18.023341 },
    { lat: 48.713504, lng: 18.023552 },
    { lat: 48.713456, lng: 18.023712 },
    { lat: 48.713433, lng: 18.023833 },
    { lat: 48.713515, lng: 18.023976 },
    { lat: 48.71355, lng: 18.02422 },
    { lat: 48.71362, lng: 18.02424 },
    { lat: 48.713671, lng: 18.024393 },
    { lat: 48.71368, lng: 18.024539 },
    { lat: 48.71366, lng: 18.024563 },
    { lat: 48.713606, lng: 18.024603 },
    { lat: 48.713588, lng: 18.02462 },
    { lat: 48.713567, lng: 18.024753 },
    { lat: 48.713374, lng: 18.025093 },
    { lat: 48.713227, lng: 18.02495 },
    { lat: 48.713135, lng: 18.024765 },
    { lat: 48.712972, lng: 18.024778 },
    { lat: 48.713015, lng: 18.024989 },
    { lat: 48.712973, lng: 18.025107 },
    { lat: 48.71288, lng: 18.025125 },
    { lat: 48.712862, lng: 18.025252 },
    { lat: 48.712867, lng: 18.02543 },
    { lat: 48.713045, lng: 18.025538 },
    { lat: 48.712961, lng: 18.02572 },
    { lat: 48.712946, lng: 18.025885 },
    { lat: 48.71282, lng: 18.025854 },
    { lat: 48.712735, lng: 18.025754 },
    { lat: 48.712629, lng: 18.025575 },
    { lat: 48.712479, lng: 18.025547 },
    { lat: 48.712109, lng: 18.024976 },
    { lat: 48.712034, lng: 18.025013 },
    { lat: 48.711895, lng: 18.024965 },
    { lat: 48.711747, lng: 18.02497 },
    { lat: 48.711587, lng: 18.025081 },
    { lat: 48.71146, lng: 18.025241 },
    { lat: 48.711576, lng: 18.025574 },
    { lat: 48.711389, lng: 18.025549 },
    { lat: 48.711305, lng: 18.025487 },
    { lat: 48.711146, lng: 18.025238 },
    { lat: 48.711167, lng: 18.025136 },
    { lat: 48.711104, lng: 18.02505 },
    { lat: 48.711034, lng: 18.024735 },
    { lat: 48.710919, lng: 18.024542 },
    { lat: 48.710964, lng: 18.024129 },
    { lat: 48.711144, lng: 18.023744 },
    { lat: 48.710682, lng: 18.022866 },
    { lat: 48.708958, lng: 18.022044 },
    { lat: 48.707514, lng: 18.021533 },
    { lat: 48.706713, lng: 18.02128 },
    { lat: 48.706396, lng: 18.021771 },
    { lat: 48.70544, lng: 18.02325 },
    { lat: 48.704494, lng: 18.024652 },
    { lat: 48.703983, lng: 18.025321 },
    { lat: 48.703956, lng: 18.02536 },
    { lat: 48.703535, lng: 18.025896 },
    { lat: 48.703479, lng: 18.025988 },
    { lat: 48.703464, lng: 18.026018 },
    { lat: 48.702881, lng: 18.027089 },
    { lat: 48.702508, lng: 18.028133 },
    { lat: 48.702616, lng: 18.028566 },
    { lat: 48.70266, lng: 18.028748 },
    { lat: 48.702881, lng: 18.030151 },
    { lat: 48.70338, lng: 18.031339 },
    { lat: 48.703862, lng: 18.031983 },
    { lat: 48.704223, lng: 18.032794 },
    { lat: 48.704485, lng: 18.033335 },
    { lat: 48.704789, lng: 18.033853 },
    { lat: 48.704825, lng: 18.03414 },
    { lat: 48.704884, lng: 18.034375 },
    { lat: 48.704916, lng: 18.034614 },
    { lat: 48.704956, lng: 18.034768 },
    { lat: 48.705074, lng: 18.035724 },
    { lat: 48.705125, lng: 18.036326 },
    { lat: 48.705182, lng: 18.036612 },
    { lat: 48.705253, lng: 18.03738 },
    { lat: 48.70527, lng: 18.037501 },
    { lat: 48.70532, lng: 18.038145 },
    { lat: 48.705379, lng: 18.038832 },
    { lat: 48.705497, lng: 18.039417 },
    { lat: 48.705488, lng: 18.039668 },
    { lat: 48.705405, lng: 18.040495 },
    { lat: 48.705409, lng: 18.040643 },
    { lat: 48.705486, lng: 18.041179 },
    { lat: 48.705504, lng: 18.041365 },
    { lat: 48.705521, lng: 18.041483 },
    { lat: 48.705533, lng: 18.041903 },
    { lat: 48.705504, lng: 18.042042 },
    { lat: 48.705448, lng: 18.042166 },
    { lat: 48.705294, lng: 18.042381 },
    { lat: 48.705204, lng: 18.042579 },
    { lat: 48.705104, lng: 18.042904 },
    { lat: 48.705055, lng: 18.043125 },
    { lat: 48.705014, lng: 18.04324 },
    { lat: 48.704821, lng: 18.043536 },
    { lat: 48.704682, lng: 18.043697 },
    { lat: 48.704535, lng: 18.043799 },
    { lat: 48.704295, lng: 18.044102 },
    { lat: 48.704169, lng: 18.044281 },
    { lat: 48.704012, lng: 18.044565 },
    { lat: 48.703961, lng: 18.044786 },
    { lat: 48.703948, lng: 18.045074 },
    { lat: 48.70395, lng: 18.045225 },
    { lat: 48.704014, lng: 18.045429 },
    { lat: 48.704035, lng: 18.045491 },
    { lat: 48.704137, lng: 18.045764 },
    { lat: 48.702787, lng: 18.046187 },
    { lat: 48.702054, lng: 18.046338 },
    { lat: 48.701532, lng: 18.046468 },
    { lat: 48.701022, lng: 18.046636 },
    { lat: 48.700561, lng: 18.04689 },
    { lat: 48.699947, lng: 18.048873 },
    { lat: 48.699698, lng: 18.051248 },
    { lat: 48.699427, lng: 18.052412 },
    { lat: 48.699239, lng: 18.053264 },
    { lat: 48.699227, lng: 18.053319 },
    { lat: 48.699151, lng: 18.053637 },
    { lat: 48.698431, lng: 18.056363 },
    { lat: 48.69808, lng: 18.057054 },
    { lat: 48.697267, lng: 18.058601 },
    { lat: 48.697435, lng: 18.058839 },
    { lat: 48.697638, lng: 18.059006 },
    { lat: 48.697811, lng: 18.059104 },
    { lat: 48.698023, lng: 18.059185 },
    { lat: 48.698352, lng: 18.059366 },
    { lat: 48.698561, lng: 18.05958 },
    { lat: 48.698648, lng: 18.059716 },
    { lat: 48.698799, lng: 18.059875 },
    { lat: 48.698891, lng: 18.059985 },
    { lat: 48.699111, lng: 18.060295 },
    { lat: 48.699224, lng: 18.06037 },
    { lat: 48.699173, lng: 18.060478 },
    { lat: 48.698568, lng: 18.061935 },
    { lat: 48.698415, lng: 18.062284 },
    { lat: 48.698125, lng: 18.062982 },
    { lat: 48.697994, lng: 18.063288 },
    { lat: 48.697876, lng: 18.063557 },
    { lat: 48.6978, lng: 18.064416 },
    { lat: 48.697789, lng: 18.064519 },
    { lat: 48.697617, lng: 18.066367 },
    { lat: 48.697406, lng: 18.066944 },
    { lat: 48.697188, lng: 18.067731 },
    { lat: 48.697098, lng: 18.067892 },
    { lat: 48.697023, lng: 18.068005 },
    { lat: 48.696944, lng: 18.068083 },
    { lat: 48.696789, lng: 18.068451 },
    { lat: 48.696583, lng: 18.069061 },
    { lat: 48.696571, lng: 18.069368 },
    { lat: 48.696491, lng: 18.069794 },
    { lat: 48.696419, lng: 18.070012 },
    { lat: 48.696361, lng: 18.070277 },
    { lat: 48.696315, lng: 18.070447 },
    { lat: 48.696344, lng: 18.070718 },
    { lat: 48.69634, lng: 18.071111 },
    { lat: 48.696329, lng: 18.071444 },
    { lat: 48.696411, lng: 18.071564 },
    { lat: 48.696392, lng: 18.07176 },
    { lat: 48.696402, lng: 18.072017 },
    { lat: 48.696349, lng: 18.072372 },
    { lat: 48.696256, lng: 18.072403 },
    { lat: 48.69613, lng: 18.072971 },
    { lat: 48.696143, lng: 18.073097 },
    { lat: 48.696313, lng: 18.073591 },
    { lat: 48.696444, lng: 18.074251 },
    { lat: 48.696697, lng: 18.074961 },
    { lat: 48.696787, lng: 18.075443 },
    { lat: 48.696859, lng: 18.075627 },
    { lat: 48.696898, lng: 18.075729 },
    { lat: 48.69709, lng: 18.076265 },
    { lat: 48.697055, lng: 18.076591 },
    { lat: 48.696957, lng: 18.077149 },
    { lat: 48.696907, lng: 18.077541 },
    { lat: 48.696891, lng: 18.077613 },
    { lat: 48.696877, lng: 18.077728 },
    { lat: 48.696784, lng: 18.07786 },
    { lat: 48.696614, lng: 18.078244 },
    { lat: 48.696477, lng: 18.078808 },
    { lat: 48.696628, lng: 18.079261 },
    { lat: 48.696588, lng: 18.079539 },
    { lat: 48.696509, lng: 18.080187 },
    { lat: 48.696373, lng: 18.080449 },
    { lat: 48.696352, lng: 18.080556 },
    { lat: 48.696337, lng: 18.080628 },
    { lat: 48.696321, lng: 18.080702 },
    { lat: 48.696042, lng: 18.081372 },
    { lat: 48.695683, lng: 18.082275 },
    { lat: 48.695634, lng: 18.083125 },
    { lat: 48.695535, lng: 18.083738 },
    { lat: 48.695468, lng: 18.083973 },
    { lat: 48.695328, lng: 18.084038 },
    { lat: 48.695243, lng: 18.084349 },
    { lat: 48.695154, lng: 18.084291 },
    { lat: 48.695052, lng: 18.084754 },
    { lat: 48.694792, lng: 18.084885 },
    { lat: 48.694685, lng: 18.085041 },
    { lat: 48.694242, lng: 18.085256 },
    { lat: 48.694004, lng: 18.085528 },
    { lat: 48.69385, lng: 18.085714 },
    { lat: 48.693751, lng: 18.085915 },
    { lat: 48.693621, lng: 18.086071 },
    { lat: 48.693527, lng: 18.086318 },
    { lat: 48.693409, lng: 18.086424 },
    { lat: 48.693335, lng: 18.08641 },
    { lat: 48.693131, lng: 18.086566 },
    { lat: 48.693005, lng: 18.086614 },
    { lat: 48.692951, lng: 18.086605 },
    { lat: 48.692874, lng: 18.086675 },
    { lat: 48.692754, lng: 18.08687 },
    { lat: 48.692724, lng: 18.087026 },
    { lat: 48.692632, lng: 18.087225 },
    { lat: 48.692545, lng: 18.087274 },
    { lat: 48.692507, lng: 18.087494 },
    { lat: 48.692249, lng: 18.08788 },
    { lat: 48.692118, lng: 18.088013 },
    { lat: 48.692176, lng: 18.088301 },
    { lat: 48.692043, lng: 18.088668 },
    { lat: 48.692181, lng: 18.089049 },
    { lat: 48.692077, lng: 18.089408 },
    { lat: 48.692148, lng: 18.08951 },
    { lat: 48.692139, lng: 18.089631 },
    { lat: 48.69211, lng: 18.089748 },
    { lat: 48.691989, lng: 18.089923 },
    { lat: 48.69193, lng: 18.090417 },
    { lat: 48.691945, lng: 18.090668 },
    { lat: 48.692073, lng: 18.090707 },
    { lat: 48.692076, lng: 18.090728 },
    { lat: 48.692077, lng: 18.090755 },
    { lat: 48.692081, lng: 18.090783 },
    { lat: 48.692001, lng: 18.090897 },
    { lat: 48.691949, lng: 18.090969 },
    { lat: 48.691945, lng: 18.090979 },
    { lat: 48.692041, lng: 18.091116 },
    { lat: 48.691935, lng: 18.091483 },
    { lat: 48.691956, lng: 18.091589 },
    { lat: 48.691909, lng: 18.091754 },
    { lat: 48.691968, lng: 18.091915 },
    { lat: 48.691892, lng: 18.092069 },
    { lat: 48.69198, lng: 18.09239 },
    { lat: 48.692073, lng: 18.092489 },
    { lat: 48.69194, lng: 18.092922 },
    { lat: 48.69191, lng: 18.09302 },
    { lat: 48.691802, lng: 18.093198 },
    { lat: 48.691657, lng: 18.093346 },
    { lat: 48.691604, lng: 18.093736 },
    { lat: 48.691549, lng: 18.093919 },
    { lat: 48.691487, lng: 18.09431 },
    { lat: 48.691472, lng: 18.094663 },
    { lat: 48.691568, lng: 18.095059 },
    { lat: 48.691525, lng: 18.09546 },
    { lat: 48.691401, lng: 18.095862 },
    { lat: 48.6913, lng: 18.096234 },
    { lat: 48.691215, lng: 18.096463 },
    { lat: 48.691206, lng: 18.096487 },
    { lat: 48.691663, lng: 18.097069 },
    { lat: 48.691797, lng: 18.096762 },
    { lat: 48.691944, lng: 18.096772 },
    { lat: 48.692122, lng: 18.096936 },
    { lat: 48.692349, lng: 18.097293 },
    { lat: 48.692498, lng: 18.098118 },
    { lat: 48.692653, lng: 18.09877 },
    { lat: 48.692764, lng: 18.099078 },
    { lat: 48.69282, lng: 18.099465 },
    { lat: 48.692906, lng: 18.099962 },
    { lat: 48.692943, lng: 18.100147 },
    { lat: 48.692944, lng: 18.100152 },
    { lat: 48.693059, lng: 18.100322 },
    { lat: 48.693399, lng: 18.100403 },
    { lat: 48.693659, lng: 18.100649 },
    { lat: 48.693733, lng: 18.100811 },
    { lat: 48.693856, lng: 18.101035 },
    { lat: 48.694134, lng: 18.101511 },
    { lat: 48.694223, lng: 18.101695 },
    { lat: 48.694374, lng: 18.102192 },
    { lat: 48.694426, lng: 18.102418 },
    { lat: 48.6946, lng: 18.102665 },
    { lat: 48.695486, lng: 18.103428 },
    { lat: 48.695579, lng: 18.103475 },
    { lat: 48.695645, lng: 18.103514 },
    { lat: 48.69613, lng: 18.103178 },
    { lat: 48.696263, lng: 18.103151 },
    { lat: 48.696308, lng: 18.103141 },
    { lat: 48.696477, lng: 18.10314 },
    { lat: 48.696512, lng: 18.103221 },
    { lat: 48.696586, lng: 18.103392 },
    { lat: 48.696784, lng: 18.104471 },
    { lat: 48.696873, lng: 18.104574 },
    { lat: 48.696902, lng: 18.104928 },
    { lat: 48.696904, lng: 18.105268 },
  ],
  ČiernaLehota: [
    { lat: 48.895262, lng: 18.332517 },
    { lat: 48.895238, lng: 18.332317 },
    { lat: 48.895024, lng: 18.332125 },
    { lat: 48.894815, lng: 18.33139 },
    { lat: 48.894475, lng: 18.330922 },
    { lat: 48.894393, lng: 18.330812 },
    { lat: 48.89413, lng: 18.330584 },
    { lat: 48.893792, lng: 18.330298 },
    { lat: 48.893634, lng: 18.330166 },
    { lat: 48.893331, lng: 18.329697 },
    { lat: 48.892875, lng: 18.328991 },
    { lat: 48.891748, lng: 18.327117 },
    { lat: 48.890885, lng: 18.326335 },
    { lat: 48.890782, lng: 18.326109 },
    { lat: 48.890645, lng: 18.325009 },
    { lat: 48.890469, lng: 18.324298 },
    { lat: 48.89033, lng: 18.324034 },
    { lat: 48.889863, lng: 18.323158 },
    { lat: 48.889477, lng: 18.322092 },
    { lat: 48.889253, lng: 18.320465 },
    { lat: 48.889229, lng: 18.320302 },
    { lat: 48.8889927, lng: 18.3200131 },
    { lat: 48.888672, lng: 18.319621 },
    { lat: 48.887797, lng: 18.318954 },
    { lat: 48.8877315, lng: 18.3189214 },
    { lat: 48.886861, lng: 18.318488 },
    { lat: 48.885986, lng: 18.317778 },
    { lat: 48.885191, lng: 18.317122 },
    { lat: 48.884945, lng: 18.316917 },
    { lat: 48.884695, lng: 18.316536 },
    { lat: 48.88468, lng: 18.316517 },
    { lat: 48.884572, lng: 18.316352 },
    { lat: 48.884564, lng: 18.315768 },
    { lat: 48.883517, lng: 18.314886 },
    { lat: 48.882026, lng: 18.314316 },
    { lat: 48.880155, lng: 18.314647 },
    { lat: 48.880424, lng: 18.314168 },
    { lat: 48.880532, lng: 18.313862 },
    { lat: 48.881066, lng: 18.312656 },
    { lat: 48.880496, lng: 18.311459 },
    { lat: 48.880237, lng: 18.31096 },
    { lat: 48.879805, lng: 18.310841 },
    { lat: 48.8796643, lng: 18.3095254 },
    { lat: 48.87961, lng: 18.309017 },
    { lat: 48.880254, lng: 18.308536 },
    { lat: 48.880238, lng: 18.308385 },
    { lat: 48.880202, lng: 18.308054 },
    { lat: 48.880063, lng: 18.307489 },
    { lat: 48.879608, lng: 18.306578 },
    { lat: 48.879475, lng: 18.306445 },
    { lat: 48.87926, lng: 18.306235 },
    { lat: 48.879232, lng: 18.306205 },
    { lat: 48.879185, lng: 18.306157 },
    { lat: 48.879118, lng: 18.305955 },
    { lat: 48.879109, lng: 18.305927 },
    { lat: 48.878996, lng: 18.305583 },
    { lat: 48.878835, lng: 18.304638 },
    { lat: 48.87864, lng: 18.303461 },
    { lat: 48.878412, lng: 18.302063 },
    { lat: 48.8766706, lng: 18.3031083 },
    { lat: 48.8743913, lng: 18.3037204 },
    { lat: 48.8738216, lng: 18.3044223 },
    { lat: 48.8737013, lng: 18.3050934 },
    { lat: 48.8735492, lng: 18.3062722 },
    { lat: 48.8735494, lng: 18.30696 },
    { lat: 48.8735479, lng: 18.3110128 },
    { lat: 48.8727184, lng: 18.3130907 },
    { lat: 48.8715578, lng: 18.3159996 },
    { lat: 48.8683677, lng: 18.3194361 },
    { lat: 48.8677438, lng: 18.3197558 },
    { lat: 48.8662575, lng: 18.3194503 },
    { lat: 48.8658443, lng: 18.3201058 },
    { lat: 48.8655372, lng: 18.3206232 },
    { lat: 48.8650061, lng: 18.3209703 },
    { lat: 48.8648494, lng: 18.3211504 },
    { lat: 48.8646, lng: 18.3208118 },
    { lat: 48.8644057, lng: 18.3210053 },
    { lat: 48.8629535, lng: 18.3204829 },
    { lat: 48.8626748, lng: 18.320701 },
    { lat: 48.8625625, lng: 18.3203117 },
    { lat: 48.8623476, lng: 18.319879 },
    { lat: 48.8621163, lng: 18.3194406 },
    { lat: 48.86208, lng: 18.3194754 },
    { lat: 48.8620514, lng: 18.3195268 },
    { lat: 48.8620417, lng: 18.319556 },
    { lat: 48.8620126, lng: 18.3195802 },
    { lat: 48.861923, lng: 18.3197319 },
    { lat: 48.8615455, lng: 18.3195825 },
    { lat: 48.8613191, lng: 18.3192273 },
    { lat: 48.8613074, lng: 18.3193206 },
    { lat: 48.861343, lng: 18.3194292 },
    { lat: 48.8613186, lng: 18.3195209 },
    { lat: 48.8610043, lng: 18.3201857 },
    { lat: 48.8604391, lng: 18.3203294 },
    { lat: 48.8599158, lng: 18.3213147 },
    { lat: 48.8592284, lng: 18.3214301 },
    { lat: 48.8578502, lng: 18.3214797 },
    { lat: 48.8571411, lng: 18.3215706 },
    { lat: 48.856258, lng: 18.3211316 },
    { lat: 48.8555526, lng: 18.3208065 },
    { lat: 48.8553974, lng: 18.3204182 },
    { lat: 48.8542396, lng: 18.3202913 },
    { lat: 48.8529772, lng: 18.3209719 },
    { lat: 48.8514698, lng: 18.3213713 },
    { lat: 48.8502384, lng: 18.3192618 },
    { lat: 48.8487877, lng: 18.3182407 },
    { lat: 48.8470551, lng: 18.317408 },
    { lat: 48.8469524, lng: 18.3177298 },
    { lat: 48.8466666, lng: 18.3180253 },
    { lat: 48.8465452, lng: 18.3184301 },
    { lat: 48.8464074, lng: 18.3195096 },
    { lat: 48.8461786, lng: 18.3200343 },
    { lat: 48.8462548, lng: 18.3212653 },
    { lat: 48.8462539, lng: 18.3215104 },
    { lat: 48.8462593, lng: 18.3224876 },
    { lat: 48.8462747, lng: 18.323345 },
    { lat: 48.8462608, lng: 18.3245016 },
    { lat: 48.8468576, lng: 18.3272684 },
    { lat: 48.8472003, lng: 18.3286488 },
    { lat: 48.8473811, lng: 18.3289718 },
    { lat: 48.8475602, lng: 18.3293889 },
    { lat: 48.8479703, lng: 18.3303802 },
    { lat: 48.8479353, lng: 18.3309808 },
    { lat: 48.8479351, lng: 18.3319838 },
    { lat: 48.84842, lng: 18.3340659 },
    { lat: 48.8484141, lng: 18.3348866 },
    { lat: 48.8483993, lng: 18.3353639 },
    { lat: 48.8482252, lng: 18.3358617 },
    { lat: 48.8482191, lng: 18.3366968 },
    { lat: 48.8486638, lng: 18.3387093 },
    { lat: 48.8480582, lng: 18.3408614 },
    { lat: 48.8473539, lng: 18.3419226 },
    { lat: 48.847692, lng: 18.3431421 },
    { lat: 48.8481511, lng: 18.3438649 },
    { lat: 48.8485821, lng: 18.3445702 },
    { lat: 48.8487808, lng: 18.3451364 },
    { lat: 48.8489804, lng: 18.3462216 },
    { lat: 48.8493078, lng: 18.3474515 },
    { lat: 48.8493921, lng: 18.3478917 },
    { lat: 48.8494148, lng: 18.3480101 },
    { lat: 48.849511, lng: 18.3486882 },
    { lat: 48.8495212, lng: 18.3487605 },
    { lat: 48.8495995, lng: 18.3491115 },
    { lat: 48.8498867, lng: 18.3503506 },
    { lat: 48.8506361, lng: 18.3507983 },
    { lat: 48.8514158, lng: 18.3517895 },
    { lat: 48.8524082, lng: 18.3536536 },
    { lat: 48.853145, lng: 18.3554282 },
    { lat: 48.8535841, lng: 18.356359 },
    { lat: 48.8539366, lng: 18.3567905 },
    { lat: 48.8552142, lng: 18.3579472 },
    { lat: 48.8555067, lng: 18.3581714 },
    { lat: 48.8560787, lng: 18.3583347 },
    { lat: 48.8567408, lng: 18.3583933 },
    { lat: 48.857524, lng: 18.3587587 },
    { lat: 48.8577371, lng: 18.3588581 },
    { lat: 48.8583998, lng: 18.3593702 },
    { lat: 48.8593724, lng: 18.3605401 },
    { lat: 48.8595747, lng: 18.3606491 },
    { lat: 48.8602988, lng: 18.3610495 },
    { lat: 48.8607076, lng: 18.3610979 },
    { lat: 48.8609767, lng: 18.3612672 },
    { lat: 48.8614634, lng: 18.3618027 },
    { lat: 48.8619225, lng: 18.362264 },
    { lat: 48.8622096, lng: 18.3627173 },
    { lat: 48.8625579, lng: 18.3637209 },
    { lat: 48.8632437, lng: 18.3649511 },
    { lat: 48.8634075, lng: 18.3658803 },
    { lat: 48.863545, lng: 18.3675972 },
    { lat: 48.8631325, lng: 18.3685313 },
    { lat: 48.8625953, lng: 18.3689317 },
    { lat: 48.8625967, lng: 18.3696804 },
    { lat: 48.8632703, lng: 18.3710164 },
    { lat: 48.86345, lng: 18.372433 },
    { lat: 48.863469, lng: 18.372399 },
    { lat: 48.863582, lng: 18.372156 },
    { lat: 48.863924, lng: 18.371462 },
    { lat: 48.864096, lng: 18.371355 },
    { lat: 48.864192, lng: 18.370954 },
    { lat: 48.864333, lng: 18.370361 },
    { lat: 48.864538, lng: 18.369533 },
    { lat: 48.86471, lng: 18.368686 },
    { lat: 48.864673, lng: 18.367411 },
    { lat: 48.864692, lng: 18.366684 },
    { lat: 48.864717, lng: 18.365995 },
    { lat: 48.86472, lng: 18.365873 },
    { lat: 48.864713, lng: 18.365859 },
    { lat: 48.864375, lng: 18.36555 },
    { lat: 48.864365, lng: 18.365529 },
    { lat: 48.864479, lng: 18.365414 },
    { lat: 48.864715, lng: 18.365097 },
    { lat: 48.865302, lng: 18.364716 },
    { lat: 48.86556, lng: 18.364648 },
    { lat: 48.865777, lng: 18.364532 },
    { lat: 48.865982, lng: 18.364536 },
    { lat: 48.86707, lng: 18.364137 },
    { lat: 48.867334, lng: 18.363997 },
    { lat: 48.867375, lng: 18.363976 },
    { lat: 48.868747, lng: 18.36325 },
    { lat: 48.869189, lng: 18.363122 },
    { lat: 48.869813, lng: 18.363136 },
    { lat: 48.870146, lng: 18.363274 },
    { lat: 48.870563, lng: 18.363553 },
    { lat: 48.870678, lng: 18.363585 },
    { lat: 48.870802, lng: 18.363575 },
    { lat: 48.870865, lng: 18.363583 },
    { lat: 48.871532, lng: 18.363696 },
    { lat: 48.871782, lng: 18.363755 },
    { lat: 48.871919, lng: 18.363801 },
    { lat: 48.87212, lng: 18.363809 },
    { lat: 48.872193, lng: 18.363747 },
    { lat: 48.872356, lng: 18.36369 },
    { lat: 48.872415, lng: 18.363739 },
    { lat: 48.872549, lng: 18.363778 },
    { lat: 48.872624, lng: 18.363777 },
    { lat: 48.872756, lng: 18.363741 },
    { lat: 48.873002, lng: 18.363772 },
    { lat: 48.873093, lng: 18.363747 },
    { lat: 48.873174, lng: 18.36375 },
    { lat: 48.873411, lng: 18.363774 },
    { lat: 48.873477, lng: 18.363759 },
    { lat: 48.873596, lng: 18.363683 },
    { lat: 48.873724, lng: 18.363654 },
    { lat: 48.873836, lng: 18.363563 },
    { lat: 48.874451, lng: 18.362936 },
    { lat: 48.874627, lng: 18.362887 },
    { lat: 48.875173, lng: 18.36295 },
    { lat: 48.87544, lng: 18.36302 },
    { lat: 48.875591, lng: 18.362983 },
    { lat: 48.875773, lng: 18.362975 },
    { lat: 48.87597, lng: 18.362838 },
    { lat: 48.876145, lng: 18.362795 },
    { lat: 48.876329, lng: 18.36281 },
    { lat: 48.876676, lng: 18.362873 },
    { lat: 48.876797, lng: 18.362854 },
    { lat: 48.876968, lng: 18.362911 },
    { lat: 48.877136, lng: 18.36301 },
    { lat: 48.877274, lng: 18.362793 },
    { lat: 48.877969, lng: 18.361233 },
    { lat: 48.8781181, lng: 18.3609404 },
    { lat: 48.878397, lng: 18.360393 },
    { lat: 48.878588, lng: 18.360005 },
    { lat: 48.8786464, lng: 18.3599876 },
    { lat: 48.879179, lng: 18.359829 },
    { lat: 48.879604, lng: 18.359348 },
    { lat: 48.880677, lng: 18.356665 },
    { lat: 48.880893, lng: 18.356133 },
    { lat: 48.881336, lng: 18.355267 },
    { lat: 48.88149, lng: 18.354805 },
    { lat: 48.881599, lng: 18.354284 },
    { lat: 48.881576, lng: 18.354113 },
    { lat: 48.881596, lng: 18.353903 },
    { lat: 48.882121, lng: 18.35329 },
    { lat: 48.883165, lng: 18.35249 },
    { lat: 48.883423, lng: 18.352254 },
    { lat: 48.883514, lng: 18.352198 },
    { lat: 48.883764, lng: 18.352262 },
    { lat: 48.883965, lng: 18.352247 },
    { lat: 48.88409, lng: 18.352179 },
    { lat: 48.884237, lng: 18.352186 },
    { lat: 48.884678, lng: 18.352029 },
    { lat: 48.886796, lng: 18.351829 },
    { lat: 48.886958, lng: 18.351765 },
    { lat: 48.887053, lng: 18.351774 },
    { lat: 48.887123, lng: 18.351744 },
    { lat: 48.88723, lng: 18.35159 },
    { lat: 48.887466, lng: 18.351554 },
    { lat: 48.887729, lng: 18.351345 },
    { lat: 48.887754, lng: 18.351207 },
    { lat: 48.887836, lng: 18.351034 },
    { lat: 48.888351, lng: 18.3513 },
    { lat: 48.88839, lng: 18.351405 },
    { lat: 48.888473, lng: 18.351453 },
    { lat: 48.888568, lng: 18.351481 },
    { lat: 48.888865, lng: 18.35144 },
    { lat: 48.888947, lng: 18.351496 },
    { lat: 48.888991, lng: 18.351576 },
    { lat: 48.889049, lng: 18.351644 },
    { lat: 48.889127, lng: 18.351653 },
    { lat: 48.889428, lng: 18.351541 },
    { lat: 48.889614, lng: 18.351426 },
    { lat: 48.889712, lng: 18.351359 },
    { lat: 48.890135, lng: 18.351221 },
    { lat: 48.890177, lng: 18.351205 },
    { lat: 48.890256, lng: 18.351206 },
    { lat: 48.890421, lng: 18.351183 },
    { lat: 48.890525, lng: 18.351225 },
    { lat: 48.890545, lng: 18.351215 },
    { lat: 48.890734, lng: 18.351171 },
    { lat: 48.890942, lng: 18.351201 },
    { lat: 48.891059, lng: 18.351246 },
    { lat: 48.891408, lng: 18.351345 },
    { lat: 48.891496, lng: 18.351351 },
    { lat: 48.891565, lng: 18.351314 },
    { lat: 48.891681, lng: 18.35129 },
    { lat: 48.8922292, lng: 18.349687 },
    { lat: 48.892377, lng: 18.3492549 },
    { lat: 48.892444, lng: 18.349059 },
    { lat: 48.892588, lng: 18.348844 },
    { lat: 48.892702, lng: 18.348673 },
    { lat: 48.892662, lng: 18.348261 },
    { lat: 48.892658, lng: 18.347784 },
    { lat: 48.892657, lng: 18.347462 },
    { lat: 48.892574, lng: 18.347173 },
    { lat: 48.892546, lng: 18.346867 },
    { lat: 48.892514, lng: 18.346432 },
    { lat: 48.892489, lng: 18.346248 },
    { lat: 48.8926, lng: 18.345895 },
    { lat: 48.89268, lng: 18.344796 },
    { lat: 48.892886, lng: 18.343719 },
    { lat: 48.893169, lng: 18.343141 },
    { lat: 48.893575, lng: 18.342157 },
    { lat: 48.893785, lng: 18.341646 },
    { lat: 48.893983, lng: 18.340672 },
    { lat: 48.894082, lng: 18.340441 },
    { lat: 48.8945221, lng: 18.3394052 },
    { lat: 48.894574, lng: 18.339283 },
    { lat: 48.894414, lng: 18.337159 },
    { lat: 48.89381, lng: 18.33646 },
    { lat: 48.893415, lng: 18.335048 },
    { lat: 48.893797, lng: 18.334126 },
    { lat: 48.894394, lng: 18.332967 },
    { lat: 48.894886, lng: 18.332954 },
    { lat: 48.895262, lng: 18.332517 },
  ],
  Ľutov: [
    { lat: 48.8056419, lng: 18.3185674 },
    { lat: 48.8059692, lng: 18.3180491 },
    { lat: 48.805108, lng: 18.3169449 },
    { lat: 48.8041988, lng: 18.3142463 },
    { lat: 48.804383, lng: 18.3120718 },
    { lat: 48.8043672, lng: 18.3116497 },
    { lat: 48.8039757, lng: 18.3110926 },
    { lat: 48.8038401, lng: 18.3109684 },
    { lat: 48.8037174, lng: 18.3108526 },
    { lat: 48.8034032, lng: 18.3104827 },
    { lat: 48.8032471, lng: 18.3102975 },
    { lat: 48.8027203, lng: 18.3096774 },
    { lat: 48.802263, lng: 18.3090147 },
    { lat: 48.8025406, lng: 18.307337 },
    { lat: 48.8030412, lng: 18.3052909 },
    { lat: 48.8035123, lng: 18.303247 },
    { lat: 48.804024, lng: 18.3010816 },
    { lat: 48.8045572, lng: 18.3001123 },
    { lat: 48.8052307, lng: 18.2996717 },
    { lat: 48.8055607, lng: 18.2997049 },
    { lat: 48.8063986, lng: 18.2993797 },
    { lat: 48.8075442, lng: 18.2992849 },
    { lat: 48.8073918, lng: 18.2982445 },
    { lat: 48.806459, lng: 18.2958992 },
    { lat: 48.8062714, lng: 18.294059 },
    { lat: 48.8060599, lng: 18.2924982 },
    { lat: 48.8059324, lng: 18.2915574 },
    { lat: 48.805678, lng: 18.2899324 },
    { lat: 48.8057429, lng: 18.2894462 },
    { lat: 48.8058379, lng: 18.288752 },
    { lat: 48.806122, lng: 18.2884495 },
    { lat: 48.8062559, lng: 18.2876035 },
    { lat: 48.8062597, lng: 18.2870582 },
    { lat: 48.8063132, lng: 18.2864284 },
    { lat: 48.8063644, lng: 18.2856962 },
    { lat: 48.8062822, lng: 18.2854438 },
    { lat: 48.8061598, lng: 18.2852324 },
    { lat: 48.8060973, lng: 18.284985 },
    { lat: 48.8060475, lng: 18.2846854 },
    { lat: 48.8061438, lng: 18.2841739 },
    { lat: 48.8062499, lng: 18.2833974 },
    { lat: 48.8062449, lng: 18.2827541 },
    { lat: 48.806291, lng: 18.282174 },
    { lat: 48.8061579, lng: 18.2815785 },
    { lat: 48.8061416, lng: 18.2815076 },
    { lat: 48.8055087, lng: 18.2808739 },
    { lat: 48.80516, lng: 18.2805542 },
    { lat: 48.8046191, lng: 18.2800665 },
    { lat: 48.8046882, lng: 18.279614 },
    { lat: 48.8048015, lng: 18.2793441 },
    { lat: 48.8049604, lng: 18.2792225 },
    { lat: 48.8052808, lng: 18.2788032 },
    { lat: 48.8052796, lng: 18.2788023 },
    { lat: 48.8051369, lng: 18.278712 },
    { lat: 48.8046868, lng: 18.2786352 },
    { lat: 48.8041244, lng: 18.2783895 },
    { lat: 48.8032914, lng: 18.2783431 },
    { lat: 48.8022512, lng: 18.2779651 },
    { lat: 48.8014913, lng: 18.2774412 },
    { lat: 48.8008441, lng: 18.276773 },
    { lat: 48.8003719, lng: 18.2765803 },
    { lat: 48.8001369, lng: 18.2764187 },
    { lat: 48.7999851, lng: 18.2761686 },
    { lat: 48.799277, lng: 18.2758978 },
    { lat: 48.7984004, lng: 18.2751446 },
    { lat: 48.7980433, lng: 18.2745408 },
    { lat: 48.7973212, lng: 18.2741405 },
    { lat: 48.7972914, lng: 18.2736562 },
    { lat: 48.7973631, lng: 18.2730907 },
    { lat: 48.7977616, lng: 18.2722197 },
    { lat: 48.7978738, lng: 18.2718719 },
    { lat: 48.7978704, lng: 18.2716537 },
    { lat: 48.7977811, lng: 18.2714509 },
    { lat: 48.7975719, lng: 18.2713851 },
    { lat: 48.796807, lng: 18.2714435 },
    { lat: 48.7959172, lng: 18.2715528 },
    { lat: 48.7953389, lng: 18.2716002 },
    { lat: 48.7944926, lng: 18.2716763 },
    { lat: 48.7943207, lng: 18.2716937 },
    { lat: 48.7942782, lng: 18.2716978 },
    { lat: 48.7938831, lng: 18.2717352 },
    { lat: 48.7928451, lng: 18.2718349 },
    { lat: 48.791965, lng: 18.2719122 },
    { lat: 48.7911263, lng: 18.272027 },
    { lat: 48.7906401, lng: 18.2720678 },
    { lat: 48.7902265, lng: 18.2721166 },
    { lat: 48.7899077, lng: 18.2721434 },
    { lat: 48.7895, lng: 18.272175 },
    { lat: 48.7889856, lng: 18.2721982 },
    { lat: 48.7886057, lng: 18.2722441 },
    { lat: 48.7883409, lng: 18.2722485 },
    { lat: 48.7874688, lng: 18.2723126 },
    { lat: 48.7870983, lng: 18.2723257 },
    { lat: 48.7869788, lng: 18.2723287 },
    { lat: 48.7865852, lng: 18.2723703 },
    { lat: 48.7861687, lng: 18.2724262 },
    { lat: 48.7858522, lng: 18.2724577 },
    { lat: 48.7856299, lng: 18.2724734 },
    { lat: 48.7854928, lng: 18.2723868 },
    { lat: 48.7849301, lng: 18.2728275 },
    { lat: 48.7844301, lng: 18.2732399 },
    { lat: 48.7840402, lng: 18.2736695 },
    { lat: 48.7838966, lng: 18.2737633 },
    { lat: 48.7838138, lng: 18.273812 },
    { lat: 48.7832488, lng: 18.274297 },
    { lat: 48.7829676, lng: 18.2743496 },
    { lat: 48.7827227, lng: 18.2743085 },
    { lat: 48.7826163, lng: 18.2742053 },
    { lat: 48.7824968, lng: 18.2740073 },
    { lat: 48.782311, lng: 18.2736168 },
    { lat: 48.7822935, lng: 18.2736248 },
    { lat: 48.7819496, lng: 18.273315 },
    { lat: 48.7818595, lng: 18.2731553 },
    { lat: 48.781799, lng: 18.2730061 },
    { lat: 48.7817459, lng: 18.272647 },
    { lat: 48.7816589, lng: 18.2722262 },
    { lat: 48.7818326, lng: 18.2716912 },
    { lat: 48.7820053, lng: 18.2711571 },
    { lat: 48.7826336, lng: 18.2709123 },
    { lat: 48.7829799, lng: 18.2708901 },
    { lat: 48.7829783, lng: 18.2708839 },
    { lat: 48.7829408, lng: 18.2705807 },
    { lat: 48.7828614, lng: 18.2704744 },
    { lat: 48.782737, lng: 18.2700738 },
    { lat: 48.7826572, lng: 18.2698591 },
    { lat: 48.7824662, lng: 18.2700138 },
    { lat: 48.7823403, lng: 18.2700045 },
    { lat: 48.7821692, lng: 18.2700299 },
    { lat: 48.7820356, lng: 18.26996 },
    { lat: 48.7819658, lng: 18.2698049 },
    { lat: 48.7818043, lng: 18.2696832 },
    { lat: 48.781667, lng: 18.2694524 },
    { lat: 48.7815866, lng: 18.269289 },
    { lat: 48.7815353, lng: 18.2692194 },
    { lat: 48.7813859, lng: 18.2692689 },
    { lat: 48.7812779, lng: 18.2693159 },
    { lat: 48.7811117, lng: 18.2693486 },
    { lat: 48.7810119, lng: 18.2693788 },
    { lat: 48.7809041, lng: 18.2694082 },
    { lat: 48.780475, lng: 18.2696823 },
    { lat: 48.7802557, lng: 18.2698715 },
    { lat: 48.7800062, lng: 18.2700495 },
    { lat: 48.7798361, lng: 18.2701311 },
    { lat: 48.779727, lng: 18.270117 },
    { lat: 48.7795475, lng: 18.2701783 },
    { lat: 48.7793624, lng: 18.2700954 },
    { lat: 48.7789101, lng: 18.2701782 },
    { lat: 48.7786974, lng: 18.2701268 },
    { lat: 48.7784817, lng: 18.2701832 },
    { lat: 48.7777278, lng: 18.2694452 },
    { lat: 48.7767914, lng: 18.2692884 },
    { lat: 48.7764199, lng: 18.2692255 },
    { lat: 48.7767894, lng: 18.2708795 },
    { lat: 48.7764155, lng: 18.2711531 },
    { lat: 48.7761664, lng: 18.2713544 },
    { lat: 48.7759789, lng: 18.2717297 },
    { lat: 48.7757999, lng: 18.2719158 },
    { lat: 48.7751084, lng: 18.2725906 },
    { lat: 48.7750526, lng: 18.2727624 },
    { lat: 48.7749791, lng: 18.2728371 },
    { lat: 48.7748225, lng: 18.2728238 },
    { lat: 48.7745767, lng: 18.2729824 },
    { lat: 48.7740663, lng: 18.2733754 },
    { lat: 48.7735625, lng: 18.2735422 },
    { lat: 48.7734623, lng: 18.2736067 },
    { lat: 48.7732868, lng: 18.2736181 },
    { lat: 48.7729469, lng: 18.2739098 },
    { lat: 48.7726564, lng: 18.2740989 },
    { lat: 48.7724731, lng: 18.2741685 },
    { lat: 48.7719998, lng: 18.274141 },
    { lat: 48.7717334, lng: 18.2741159 },
    { lat: 48.7715723, lng: 18.2742223 },
    { lat: 48.7713981, lng: 18.2743122 },
    { lat: 48.7712717, lng: 18.274334 },
    { lat: 48.7711094, lng: 18.2744731 },
    { lat: 48.7710091, lng: 18.2745763 },
    { lat: 48.7708718, lng: 18.2746242 },
    { lat: 48.7706899, lng: 18.2747562 },
    { lat: 48.7704199, lng: 18.2748679 },
    { lat: 48.7702488, lng: 18.2747363 },
    { lat: 48.7701529, lng: 18.2747799 },
    { lat: 48.769952, lng: 18.274855 },
    { lat: 48.7698912, lng: 18.2748959 },
    { lat: 48.7698527, lng: 18.2748769 },
    { lat: 48.7697902, lng: 18.2748467 },
    { lat: 48.7697287, lng: 18.2748165 },
    { lat: 48.7695685, lng: 18.2747386 },
    { lat: 48.7694285, lng: 18.2749174 },
    { lat: 48.7693927, lng: 18.2749617 },
    { lat: 48.7693654, lng: 18.2749968 },
    { lat: 48.7692928, lng: 18.2750888 },
    { lat: 48.7688953, lng: 18.2754996 },
    { lat: 48.7688816, lng: 18.2755161 },
    { lat: 48.7688195, lng: 18.2754976 },
    { lat: 48.7686895, lng: 18.2756104 },
    { lat: 48.7686897, lng: 18.2756351 },
    { lat: 48.7687294, lng: 18.2758763 },
    { lat: 48.7683941, lng: 18.2761335 },
    { lat: 48.7681561, lng: 18.2763929 },
    { lat: 48.7675171, lng: 18.2768229 },
    { lat: 48.7671742, lng: 18.2771214 },
    { lat: 48.7667675, lng: 18.2777877 },
    { lat: 48.766796, lng: 18.2782307 },
    { lat: 48.76699, lng: 18.2790653 },
    { lat: 48.76671, lng: 18.2797569 },
    { lat: 48.7665124, lng: 18.2801528 },
    { lat: 48.7663049, lng: 18.2807509 },
    { lat: 48.7665516, lng: 18.2818358 },
    { lat: 48.7665348, lng: 18.2825941 },
    { lat: 48.7664011, lng: 18.2831626 },
    { lat: 48.7661044, lng: 18.2838957 },
    { lat: 48.7667601, lng: 18.2845763 },
    { lat: 48.767259, lng: 18.2846611 },
    { lat: 48.7679901, lng: 18.2855326 },
    { lat: 48.7685255, lng: 18.2865018 },
    { lat: 48.7685742, lng: 18.2866017 },
    { lat: 48.7686098, lng: 18.2868141 },
    { lat: 48.7686834, lng: 18.2872571 },
    { lat: 48.7688975, lng: 18.2889433 },
    { lat: 48.7689605, lng: 18.2896962 },
    { lat: 48.7689743, lng: 18.2898772 },
    { lat: 48.7689744, lng: 18.2899208 },
    { lat: 48.7690007, lng: 18.2900261 },
    { lat: 48.7691538, lng: 18.2904369 },
    { lat: 48.7703424, lng: 18.2918616 },
    { lat: 48.7711095, lng: 18.2925365 },
    { lat: 48.7719509, lng: 18.2931904 },
    { lat: 48.7720219, lng: 18.2932459 },
    { lat: 48.7724642, lng: 18.2936071 },
    { lat: 48.7728355, lng: 18.2941266 },
    { lat: 48.7735228, lng: 18.2955424 },
    { lat: 48.7736533, lng: 18.2956767 },
    { lat: 48.7736359, lng: 18.2957484 },
    { lat: 48.7738695, lng: 18.295829 },
    { lat: 48.774105, lng: 18.2957828 },
    { lat: 48.7746426, lng: 18.295595 },
    { lat: 48.7752823, lng: 18.2949165 },
    { lat: 48.7754437, lng: 18.2948359 },
    { lat: 48.7760341, lng: 18.2950037 },
    { lat: 48.776504, lng: 18.2949936 },
    { lat: 48.7769041, lng: 18.2951442 },
    { lat: 48.777871, lng: 18.2957393 },
    { lat: 48.7789488, lng: 18.2956848 },
    { lat: 48.7800119, lng: 18.295869 },
    { lat: 48.7806735, lng: 18.2958165 },
    { lat: 48.7813069, lng: 18.2957662 },
    { lat: 48.7827961, lng: 18.2963789 },
    { lat: 48.7833322, lng: 18.2965659 },
    { lat: 48.7849789, lng: 18.2975514 },
    { lat: 48.7861782, lng: 18.2993639 },
    { lat: 48.786285, lng: 18.2993794 },
    { lat: 48.7864569, lng: 18.299404 },
    { lat: 48.7865639, lng: 18.2994227 },
    { lat: 48.7871701, lng: 18.2998247 },
    { lat: 48.7879011, lng: 18.2998441 },
    { lat: 48.7881067, lng: 18.2999896 },
    { lat: 48.7886616, lng: 18.300224 },
    { lat: 48.7897491, lng: 18.3005032 },
    { lat: 48.7901248, lng: 18.3005983 },
    { lat: 48.7901902, lng: 18.3006603 },
    { lat: 48.7902287, lng: 18.3007005 },
    { lat: 48.7904539, lng: 18.3009325 },
    { lat: 48.790535, lng: 18.3010086 },
    { lat: 48.7906861, lng: 18.30113 },
    { lat: 48.7912508, lng: 18.3021142 },
    { lat: 48.7915456, lng: 18.3024203 },
    { lat: 48.7924317, lng: 18.3025868 },
    { lat: 48.7932545, lng: 18.3030939 },
    { lat: 48.7934581, lng: 18.3034207 },
    { lat: 48.7938301, lng: 18.304372 },
    { lat: 48.7937702, lng: 18.3055496 },
    { lat: 48.794085, lng: 18.3066492 },
    { lat: 48.7944448, lng: 18.3070085 },
    { lat: 48.7950849, lng: 18.3071318 },
    { lat: 48.7956024, lng: 18.3071843 },
    { lat: 48.7961231, lng: 18.3077768 },
    { lat: 48.7962545, lng: 18.3081853 },
    { lat: 48.7965252, lng: 18.3087221 },
    { lat: 48.7970551, lng: 18.3090744 },
    { lat: 48.7975676, lng: 18.3096 },
    { lat: 48.7995289, lng: 18.3119023 },
    { lat: 48.799657, lng: 18.3119769 },
    { lat: 48.7997116, lng: 18.3120232 },
    { lat: 48.8005047, lng: 18.3126031 },
    { lat: 48.8010901, lng: 18.3134586 },
    { lat: 48.8018239, lng: 18.3141876 },
    { lat: 48.8023335, lng: 18.3151611 },
    { lat: 48.8029561, lng: 18.3165383 },
    { lat: 48.8033041, lng: 18.3169865 },
    { lat: 48.8035789, lng: 18.3173193 },
    { lat: 48.8037511, lng: 18.3174918 },
    { lat: 48.804426, lng: 18.3180738 },
    { lat: 48.8046155, lng: 18.3182147 },
    { lat: 48.8053389, lng: 18.3184179 },
    { lat: 48.8056419, lng: 18.3185674 },
  ],
  Šišov: [
    { lat: 48.668761, lng: 18.14219 },
    { lat: 48.668556, lng: 18.142032 },
    { lat: 48.667863, lng: 18.142833 },
    { lat: 48.667558, lng: 18.142996 },
    { lat: 48.666946, lng: 18.143325 },
    { lat: 48.666561, lng: 18.143535 },
    { lat: 48.666458, lng: 18.143608 },
    { lat: 48.666235, lng: 18.142504 },
    { lat: 48.666231, lng: 18.142482 },
    { lat: 48.665282, lng: 18.142639 },
    { lat: 48.664999, lng: 18.142682 },
    { lat: 48.664617, lng: 18.142772 },
    { lat: 48.664142, lng: 18.142899 },
    { lat: 48.664023, lng: 18.14292 },
    { lat: 48.663859, lng: 18.142955 },
    { lat: 48.663616, lng: 18.143045 },
    { lat: 48.663406, lng: 18.14316 },
    { lat: 48.663382, lng: 18.143214 },
    { lat: 48.663341, lng: 18.143194 },
    { lat: 48.66327, lng: 18.143272 },
    { lat: 48.663231, lng: 18.143269 },
    { lat: 48.663194, lng: 18.143312 },
    { lat: 48.663089, lng: 18.143361 },
    { lat: 48.663015, lng: 18.143407 },
    { lat: 48.662963, lng: 18.143498 },
    { lat: 48.662884, lng: 18.143534 },
    { lat: 48.662799, lng: 18.143551 },
    { lat: 48.662706, lng: 18.143642 },
    { lat: 48.662532, lng: 18.143769 },
    { lat: 48.662321, lng: 18.14396 },
    { lat: 48.662187, lng: 18.143991 },
    { lat: 48.662136, lng: 18.14407 },
    { lat: 48.662077, lng: 18.144062 },
    { lat: 48.662075, lng: 18.144122 },
    { lat: 48.662042, lng: 18.144117 },
    { lat: 48.661968, lng: 18.14422 },
    { lat: 48.66195, lng: 18.144289 },
    { lat: 48.661886, lng: 18.144389 },
    { lat: 48.661812, lng: 18.144478 },
    { lat: 48.661645, lng: 18.14465 },
    { lat: 48.661614, lng: 18.144755 },
    { lat: 48.661548, lng: 18.144747 },
    { lat: 48.661499, lng: 18.144856 },
    { lat: 48.661436, lng: 18.144854 },
    { lat: 48.661426, lng: 18.144898 },
    { lat: 48.661289, lng: 18.144953 },
    { lat: 48.661268, lng: 18.144923 },
    { lat: 48.66119, lng: 18.144968 },
    { lat: 48.661101, lng: 18.145047 },
    { lat: 48.661024, lng: 18.145209 },
    { lat: 48.660911, lng: 18.145279 },
    { lat: 48.660906, lng: 18.145314 },
    { lat: 48.660681, lng: 18.145462 },
    { lat: 48.660134, lng: 18.145823 },
    { lat: 48.658907, lng: 18.146913 },
    { lat: 48.657554, lng: 18.148283 },
    { lat: 48.65726, lng: 18.147915 },
    { lat: 48.657108, lng: 18.147727 },
    { lat: 48.656085, lng: 18.146478 },
    { lat: 48.656059, lng: 18.146447 },
    { lat: 48.655905, lng: 18.146627 },
    { lat: 48.655668, lng: 18.146959 },
    { lat: 48.655188, lng: 18.147449 },
    { lat: 48.655023, lng: 18.147696 },
    { lat: 48.6547, lng: 18.148429 },
    { lat: 48.654502, lng: 18.148864 },
    { lat: 48.654395, lng: 18.149185 },
    { lat: 48.654181, lng: 18.150267 },
    { lat: 48.65411, lng: 18.150637 },
    { lat: 48.654045, lng: 18.150978 },
    { lat: 48.653599, lng: 18.152351 },
    { lat: 48.653483, lng: 18.152804 },
    { lat: 48.653369, lng: 18.153067 },
    { lat: 48.653174, lng: 18.153462 },
    { lat: 48.65303, lng: 18.153789 },
    { lat: 48.652982, lng: 18.153919 },
    { lat: 48.652947, lng: 18.153998 },
    { lat: 48.652842, lng: 18.154215 },
    { lat: 48.652607, lng: 18.15507 },
    { lat: 48.6525, lng: 18.155502 },
    { lat: 48.652432, lng: 18.155656 },
    { lat: 48.652133, lng: 18.156325 },
    { lat: 48.652049, lng: 18.156587 },
    { lat: 48.651988, lng: 18.156829 },
    { lat: 48.651559, lng: 18.15785 },
    { lat: 48.651424, lng: 18.158128 },
    { lat: 48.651233, lng: 18.15859 },
    { lat: 48.651082, lng: 18.158858 },
    { lat: 48.651032, lng: 18.158991 },
    { lat: 48.650979, lng: 18.159093 },
    { lat: 48.650907, lng: 18.159334 },
    { lat: 48.650782, lng: 18.159564 },
    { lat: 48.650577, lng: 18.15977 },
    { lat: 48.650418, lng: 18.159878 },
    { lat: 48.65013, lng: 18.160116 },
    { lat: 48.649944, lng: 18.160213 },
    { lat: 48.649585, lng: 18.160514 },
    { lat: 48.649308, lng: 18.160715 },
    { lat: 48.649204, lng: 18.160841 },
    { lat: 48.649158, lng: 18.160874 },
    { lat: 48.648987, lng: 18.160935 },
    { lat: 48.648899, lng: 18.160985 },
    { lat: 48.648546, lng: 18.161229 },
    { lat: 48.64839, lng: 18.161349 },
    { lat: 48.648318, lng: 18.161396 },
    { lat: 48.648064, lng: 18.161559 },
    { lat: 48.647917, lng: 18.161627 },
    { lat: 48.646916, lng: 18.162374 },
    { lat: 48.646573, lng: 18.162696 },
    { lat: 48.646234, lng: 18.163088 },
    { lat: 48.646131, lng: 18.163156 },
    { lat: 48.645705, lng: 18.163279 },
    { lat: 48.645558, lng: 18.163335 },
    { lat: 48.645282, lng: 18.163471 },
    { lat: 48.645005, lng: 18.16357 },
    { lat: 48.644743, lng: 18.163748 },
    { lat: 48.644401, lng: 18.16398 },
    { lat: 48.644187, lng: 18.164106 },
    { lat: 48.644108, lng: 18.164146 },
    { lat: 48.643898, lng: 18.164229 },
    { lat: 48.643214, lng: 18.164573 },
    { lat: 48.642879, lng: 18.164657 },
    { lat: 48.6429, lng: 18.164702 },
    { lat: 48.642927, lng: 18.164693 },
    { lat: 48.64304, lng: 18.164912 },
    { lat: 48.643275, lng: 18.165302 },
    { lat: 48.643455, lng: 18.165651 },
    { lat: 48.643554, lng: 18.165918 },
    { lat: 48.643641, lng: 18.166175 },
    { lat: 48.643743, lng: 18.166572 },
    { lat: 48.643898, lng: 18.167117 },
    { lat: 48.64413, lng: 18.167856 },
    { lat: 48.644168, lng: 18.168097 },
    { lat: 48.644242, lng: 18.168743 },
    { lat: 48.644297, lng: 18.169 },
    { lat: 48.644358, lng: 18.16918 },
    { lat: 48.644999, lng: 18.170707 },
    { lat: 48.645514, lng: 18.172209 },
    { lat: 48.645578, lng: 18.172347 },
    { lat: 48.645591, lng: 18.172927 },
    { lat: 48.64557, lng: 18.173156 },
    { lat: 48.645531, lng: 18.173457 },
    { lat: 48.645267, lng: 18.174955 },
    { lat: 48.645275, lng: 18.175072 },
    { lat: 48.645306, lng: 18.175218 },
    { lat: 48.646092, lng: 18.178214 },
    { lat: 48.646101, lng: 18.178513 },
    { lat: 48.646079, lng: 18.179165 },
    { lat: 48.646086, lng: 18.179288 },
    { lat: 48.646238, lng: 18.180117 },
    { lat: 48.646439, lng: 18.180813 },
    { lat: 48.646516, lng: 18.181221 },
    { lat: 48.646501, lng: 18.181248 },
    { lat: 48.646462, lng: 18.181321 },
    { lat: 48.646462, lng: 18.181564 },
    { lat: 48.646384, lng: 18.182051 },
    { lat: 48.646299, lng: 18.182494 },
    { lat: 48.646211, lng: 18.182771 },
    { lat: 48.646182, lng: 18.182924 },
    { lat: 48.646183, lng: 18.183208 },
    { lat: 48.646222, lng: 18.183767 },
    { lat: 48.64627, lng: 18.184215 },
    { lat: 48.646354, lng: 18.184473 },
    { lat: 48.646372, lng: 18.184497 },
    { lat: 48.6467714, lng: 18.1843441 },
    { lat: 48.6471968, lng: 18.1839723 },
    { lat: 48.6477183, lng: 18.1835269 },
    { lat: 48.6477661, lng: 18.1835717 },
    { lat: 48.6481744, lng: 18.1841735 },
    { lat: 48.6488063, lng: 18.184994 },
    { lat: 48.6496123, lng: 18.1861717 },
    { lat: 48.6499287, lng: 18.1865691 },
    { lat: 48.6512669, lng: 18.1880937 },
    { lat: 48.6513444, lng: 18.1881838 },
    { lat: 48.651695, lng: 18.18853 },
    { lat: 48.6520876, lng: 18.187847 },
    { lat: 48.6529853, lng: 18.1888119 },
    { lat: 48.6541932, lng: 18.1901118 },
    { lat: 48.6545534, lng: 18.1912323 },
    { lat: 48.654905, lng: 18.1923014 },
    { lat: 48.6553587, lng: 18.1937116 },
    { lat: 48.6551056, lng: 18.193898 },
    { lat: 48.6550474, lng: 18.1940462 },
    { lat: 48.655014, lng: 18.1941934 },
    { lat: 48.6548349, lng: 18.1942151 },
    { lat: 48.6547136, lng: 18.1941464 },
    { lat: 48.6546105, lng: 18.1941204 },
    { lat: 48.65455, lng: 18.1941297 },
    { lat: 48.6544788, lng: 18.1942037 },
    { lat: 48.6544348, lng: 18.1943629 },
    { lat: 48.6544451, lng: 18.1945589 },
    { lat: 48.6544869, lng: 18.1947166 },
    { lat: 48.6545553, lng: 18.194845 },
    { lat: 48.6545981, lng: 18.1949436 },
    { lat: 48.6545981, lng: 18.1950421 },
    { lat: 48.6545327, lng: 18.1951792 },
    { lat: 48.6545748, lng: 18.1956148 },
    { lat: 48.654486, lng: 18.1957756 },
    { lat: 48.6544751, lng: 18.1958045 },
    { lat: 48.6544447, lng: 18.1959427 },
    { lat: 48.6544553, lng: 18.1962818 },
    { lat: 48.6544447, lng: 18.1963935 },
    { lat: 48.6544166, lng: 18.1965499 },
    { lat: 48.6543822, lng: 18.1966594 },
    { lat: 48.6543095, lng: 18.1967881 },
    { lat: 48.6542558, lng: 18.1968369 },
    { lat: 48.6541354, lng: 18.1969798 },
    { lat: 48.6539449, lng: 18.1973546 },
    { lat: 48.653925, lng: 18.1974781 },
    { lat: 48.6539572, lng: 18.1977652 },
    { lat: 48.6539581, lng: 18.1979374 },
    { lat: 48.6538151, lng: 18.198156 },
    { lat: 48.6535943, lng: 18.1984202 },
    { lat: 48.6536133, lng: 18.1987496 },
    { lat: 48.6535976, lng: 18.1988466 },
    { lat: 48.6535177, lng: 18.1989688 },
    { lat: 48.6534112, lng: 18.1991562 },
    { lat: 48.653368, lng: 18.1991905 },
    { lat: 48.6532029, lng: 18.1992021 },
    { lat: 48.653107, lng: 18.1992615 },
    { lat: 48.653046, lng: 18.1993398 },
    { lat: 48.6529841, lng: 18.1994435 },
    { lat: 48.6528933, lng: 18.1995304 },
    { lat: 48.6527915, lng: 18.1997618 },
    { lat: 48.652738, lng: 18.1999862 },
    { lat: 48.6526423, lng: 18.2000908 },
    { lat: 48.6524293, lng: 18.2002719 },
    { lat: 48.6520762, lng: 18.2003696 },
    { lat: 48.6519515, lng: 18.2003431 },
    { lat: 48.6518601, lng: 18.2003986 },
    { lat: 48.6517202, lng: 18.2005394 },
    { lat: 48.651553, lng: 18.2006124 },
    { lat: 48.6514883, lng: 18.200663 },
    { lat: 48.6514668, lng: 18.2007008 },
    { lat: 48.6513997, lng: 18.2010675 },
    { lat: 48.6513707, lng: 18.2011516 },
    { lat: 48.6512259, lng: 18.2014001 },
    { lat: 48.6511521, lng: 18.2014904 },
    { lat: 48.6510633, lng: 18.2015734 },
    { lat: 48.6508994, lng: 18.2015699 },
    { lat: 48.6508579, lng: 18.2015922 },
    { lat: 48.6508552, lng: 18.2016839 },
    { lat: 48.6509013, lng: 18.2019765 },
    { lat: 48.6509734, lng: 18.202128 },
    { lat: 48.6510634, lng: 18.2023967 },
    { lat: 48.6510562, lng: 18.2029009 },
    { lat: 48.6508852, lng: 18.2033019 },
    { lat: 48.6508498, lng: 18.2034718 },
    { lat: 48.6507501, lng: 18.2036415 },
    { lat: 48.6508016, lng: 18.2037893 },
    { lat: 48.6508072, lng: 18.2038489 },
    { lat: 48.6507924, lng: 18.203884 },
    { lat: 48.650572, lng: 18.2040928 },
    { lat: 48.6504818, lng: 18.204313 },
    { lat: 48.650575, lng: 18.2043219 },
    { lat: 48.650636, lng: 18.2051107 },
    { lat: 48.6506858, lng: 18.2053055 },
    { lat: 48.6508938, lng: 18.2062157 },
    { lat: 48.6509445, lng: 18.2066256 },
    { lat: 48.650985, lng: 18.2070749 },
    { lat: 48.6510131, lng: 18.2077968 },
    { lat: 48.6510128, lng: 18.2082443 },
    { lat: 48.651021, lng: 18.208294 },
    { lat: 48.651033, lng: 18.20842 },
    { lat: 48.651065, lng: 18.208918 },
    { lat: 48.651064, lng: 18.208925 },
    { lat: 48.651044, lng: 18.209169 },
    { lat: 48.651045, lng: 18.209371 },
    { lat: 48.651034, lng: 18.209532 },
    { lat: 48.651065, lng: 18.209551 },
    { lat: 48.651136, lng: 18.209717 },
    { lat: 48.651225, lng: 18.209778 },
    { lat: 48.651288, lng: 18.209778 },
    { lat: 48.651422, lng: 18.20971 },
    { lat: 48.651495, lng: 18.20966 },
    { lat: 48.651564, lng: 18.209637 },
    { lat: 48.651714, lng: 18.209625 },
    { lat: 48.651818, lng: 18.209639 },
    { lat: 48.651877, lng: 18.209681 },
    { lat: 48.651889, lng: 18.209764 },
    { lat: 48.651881, lng: 18.209811 },
    { lat: 48.651807, lng: 18.209935 },
    { lat: 48.651794, lng: 18.210016 },
    { lat: 48.651753, lng: 18.210073 },
    { lat: 48.651716, lng: 18.210113 },
    { lat: 48.651618, lng: 18.210323 },
    { lat: 48.65168, lng: 18.210518 },
    { lat: 48.651802, lng: 18.210586 },
    { lat: 48.651932, lng: 18.210836 },
    { lat: 48.651984, lng: 18.210852 },
    { lat: 48.652112, lng: 18.210968 },
    { lat: 48.652192, lng: 18.210985 },
    { lat: 48.652239, lng: 18.211066 },
    { lat: 48.652265, lng: 18.211186 },
    { lat: 48.65234, lng: 18.211312 },
    { lat: 48.652372, lng: 18.211336 },
    { lat: 48.652479, lng: 18.211354 },
    { lat: 48.652529, lng: 18.21139 },
    { lat: 48.652517, lng: 18.21147 },
    { lat: 48.652443, lng: 18.211687 },
    { lat: 48.652452, lng: 18.211822 },
    { lat: 48.652542, lng: 18.211948 },
    { lat: 48.652572, lng: 18.211961 },
    { lat: 48.652698, lng: 18.211947 },
    { lat: 48.652755, lng: 18.212021 },
    { lat: 48.652783, lng: 18.212137 },
    { lat: 48.652719, lng: 18.212305 },
    { lat: 48.652766, lng: 18.212528 },
    { lat: 48.652808, lng: 18.212568 },
    { lat: 48.652841, lng: 18.212574 },
    { lat: 48.652972, lng: 18.212705 },
    { lat: 48.653016, lng: 18.212878 },
    { lat: 48.653036, lng: 18.213033 },
    { lat: 48.653133, lng: 18.213217 },
    { lat: 48.6535816, lng: 18.2128642 },
    { lat: 48.6537997, lng: 18.2133632 },
    { lat: 48.6547243, lng: 18.2128217 },
    { lat: 48.6560609, lng: 18.2122023 },
    { lat: 48.6561244, lng: 18.2121883 },
    { lat: 48.6561526, lng: 18.2126179 },
    { lat: 48.656202, lng: 18.2126132 },
    { lat: 48.657354, lng: 18.2113374 },
    { lat: 48.6585848, lng: 18.2099762 },
    { lat: 48.6591753, lng: 18.2093156 },
    { lat: 48.6597564, lng: 18.2086687 },
    { lat: 48.6607761, lng: 18.2075442 },
    { lat: 48.661713, lng: 18.2065087 },
    { lat: 48.6627313, lng: 18.2053797 },
    { lat: 48.662931, lng: 18.2056898 },
    { lat: 48.6629561, lng: 18.2057361 },
    { lat: 48.6628872, lng: 18.2058122 },
    { lat: 48.6627077, lng: 18.2061049 },
    { lat: 48.6626144, lng: 18.2062089 },
    { lat: 48.6624135, lng: 18.2064651 },
    { lat: 48.6620277, lng: 18.2070172 },
    { lat: 48.6622057, lng: 18.2073044 },
    { lat: 48.6627773, lng: 18.2081783 },
    { lat: 48.6634029, lng: 18.2091515 },
    { lat: 48.6636613, lng: 18.209541 },
    { lat: 48.6637031, lng: 18.2096226 },
    { lat: 48.6637211, lng: 18.2096184 },
    { lat: 48.6638126, lng: 18.2097217 },
    { lat: 48.663885, lng: 18.2099397 },
    { lat: 48.663898, lng: 18.210009 },
    { lat: 48.6639125, lng: 18.2100442 },
    { lat: 48.663935, lng: 18.2100445 },
    { lat: 48.6639845, lng: 18.2099998 },
    { lat: 48.6640352, lng: 18.2099193 },
    { lat: 48.664082, lng: 18.2098121 },
    { lat: 48.6641411, lng: 18.2097797 },
    { lat: 48.6641602, lng: 18.2097573 },
    { lat: 48.6641869, lng: 18.2096959 },
    { lat: 48.6642798, lng: 18.2096627 },
    { lat: 48.6643131, lng: 18.2096337 },
    { lat: 48.6643303, lng: 18.2095204 },
    { lat: 48.6643801, lng: 18.2095021 },
    { lat: 48.664557, lng: 18.2095157 },
    { lat: 48.6645701, lng: 18.2094679 },
    { lat: 48.6645104, lng: 18.2091984 },
    { lat: 48.6645338, lng: 18.2091336 },
    { lat: 48.6645327, lng: 18.2090559 },
    { lat: 48.6645565, lng: 18.2088818 },
    { lat: 48.6645934, lng: 18.2087966 },
    { lat: 48.6646388, lng: 18.2086429 },
    { lat: 48.6646508, lng: 18.2086174 },
    { lat: 48.6647577, lng: 18.2085117 },
    { lat: 48.6647907, lng: 18.2084973 },
    { lat: 48.6649358, lng: 18.2085087 },
    { lat: 48.6650045, lng: 18.2085261 },
    { lat: 48.6650416, lng: 18.2085055 },
    { lat: 48.6650951, lng: 18.208375 },
    { lat: 48.6651135, lng: 18.2080859 },
    { lat: 48.6651475, lng: 18.207933 },
    { lat: 48.6652191, lng: 18.2079036 },
    { lat: 48.6652453, lng: 18.2079073 },
    { lat: 48.6653039, lng: 18.207865 },
    { lat: 48.6653952, lng: 18.2078241 },
    { lat: 48.6655496, lng: 18.207841 },
    { lat: 48.6655949, lng: 18.2078069 },
    { lat: 48.6656776, lng: 18.207674 },
    { lat: 48.6657739, lng: 18.2075018 },
    { lat: 48.6658462, lng: 18.2074477 },
    { lat: 48.6659213, lng: 18.2074171 },
    { lat: 48.6660031, lng: 18.2073671 },
    { lat: 48.6661444, lng: 18.2072347 },
    { lat: 48.6662108, lng: 18.2072128 },
    { lat: 48.6662319, lng: 18.206993 },
    { lat: 48.6663861, lng: 18.2068275 },
    { lat: 48.6664029, lng: 18.2067261 },
    { lat: 48.6665071, lng: 18.2066385 },
    { lat: 48.6665414, lng: 18.2066251 },
    { lat: 48.6666014, lng: 18.2066461 },
    { lat: 48.6667399, lng: 18.2066615 },
    { lat: 48.6667938, lng: 18.206677 },
    { lat: 48.6668071, lng: 18.2066734 },
    { lat: 48.666865, lng: 18.2066405 },
    { lat: 48.6669048, lng: 18.2065471 },
    { lat: 48.6669269, lng: 18.2064218 },
    { lat: 48.6669561, lng: 18.2063576 },
    { lat: 48.6669904, lng: 18.206346 },
    { lat: 48.6670489, lng: 18.206344 },
    { lat: 48.6671508, lng: 18.2064299 },
    { lat: 48.6671579, lng: 18.2064539 },
    { lat: 48.6671999, lng: 18.2064781 },
    { lat: 48.6672276, lng: 18.2064694 },
    { lat: 48.6672728, lng: 18.2064132 },
    { lat: 48.6673341, lng: 18.2063802 },
    { lat: 48.6675207, lng: 18.2064642 },
    { lat: 48.6675922, lng: 18.2064727 },
    { lat: 48.66768, lng: 18.2064084 },
    { lat: 48.6677079, lng: 18.2063629 },
    { lat: 48.6676969, lng: 18.2062498 },
    { lat: 48.6676452, lng: 18.2061155 },
    { lat: 48.6675978, lng: 18.2060204 },
    { lat: 48.6675823, lng: 18.2059485 },
    { lat: 48.667584, lng: 18.2058587 },
    { lat: 48.6676231, lng: 18.2057522 },
    { lat: 48.6676794, lng: 18.205633 },
    { lat: 48.6677209, lng: 18.2056105 },
    { lat: 48.6678227, lng: 18.2056717 },
    { lat: 48.6678625, lng: 18.2056762 },
    { lat: 48.6679391, lng: 18.2056343 },
    { lat: 48.6680125, lng: 18.2056169 },
    { lat: 48.6680949, lng: 18.2055611 },
    { lat: 48.6681474, lng: 18.2054703 },
    { lat: 48.6681563, lng: 18.2053909 },
    { lat: 48.6681473, lng: 18.2052918 },
    { lat: 48.6681621, lng: 18.2051578 },
    { lat: 48.6681529, lng: 18.2050555 },
    { lat: 48.6681607, lng: 18.2049366 },
    { lat: 48.6682189, lng: 18.2048505 },
    { lat: 48.6682625, lng: 18.204824 },
    { lat: 48.668324, lng: 18.2048324 },
    { lat: 48.6683911, lng: 18.2048229 },
    { lat: 48.6684961, lng: 18.2047873 },
    { lat: 48.6685155, lng: 18.2047702 },
    { lat: 48.6686002, lng: 18.2045527 },
    { lat: 48.668663, lng: 18.2044113 },
    { lat: 48.6686953, lng: 18.2043646 },
    { lat: 48.6687617, lng: 18.2042241 },
    { lat: 48.6689137, lng: 18.2041575 },
    { lat: 48.6690747, lng: 18.2040749 },
    { lat: 48.6690943, lng: 18.2040424 },
    { lat: 48.6691447, lng: 18.2039194 },
    { lat: 48.6692393, lng: 18.2038577 },
    { lat: 48.6692762, lng: 18.2037932 },
    { lat: 48.6692588, lng: 18.2036259 },
    { lat: 48.6692705, lng: 18.2035941 },
    { lat: 48.6692878, lng: 18.2035832 },
    { lat: 48.6693829, lng: 18.2035679 },
    { lat: 48.6695033, lng: 18.2034811 },
    { lat: 48.6695524, lng: 18.2034354 },
    { lat: 48.6695972, lng: 18.2033309 },
    { lat: 48.6696559, lng: 18.203269 },
    { lat: 48.6696764, lng: 18.2032566 },
    { lat: 48.6698494, lng: 18.2032215 },
    { lat: 48.6699236, lng: 18.2032174 },
    { lat: 48.6699875, lng: 18.2032508 },
    { lat: 48.6700168, lng: 18.2032866 },
    { lat: 48.6701703, lng: 18.2031894 },
    { lat: 48.670199, lng: 18.2031581 },
    { lat: 48.670257, lng: 18.2030477 },
    { lat: 48.670404, lng: 18.2028199 },
    { lat: 48.6705344, lng: 18.2026543 },
    { lat: 48.670566, lng: 18.2024959 },
    { lat: 48.6705695, lng: 18.2024423 },
    { lat: 48.6705858, lng: 18.202388 },
    { lat: 48.6706228, lng: 18.202346 },
    { lat: 48.6708143, lng: 18.2023609 },
    { lat: 48.6708521, lng: 18.202332 },
    { lat: 48.6709258, lng: 18.2022402 },
    { lat: 48.6709504, lng: 18.2021952 },
    { lat: 48.6709705, lng: 18.2020766 },
    { lat: 48.6710371, lng: 18.2019968 },
    { lat: 48.6711335, lng: 18.2020238 },
    { lat: 48.6711939, lng: 18.2019559 },
    { lat: 48.6712299, lng: 18.2018754 },
    { lat: 48.6713478, lng: 18.2017704 },
    { lat: 48.6714385, lng: 18.2017349 },
    { lat: 48.6714801, lng: 18.2016566 },
    { lat: 48.6714984, lng: 18.2015607 },
    { lat: 48.6715297, lng: 18.2014962 },
    { lat: 48.6716932, lng: 18.201358 },
    { lat: 48.6717215, lng: 18.2012047 },
    { lat: 48.6717781, lng: 18.2010091 },
    { lat: 48.671831, lng: 18.2008522 },
    { lat: 48.6718548, lng: 18.2007352 },
    { lat: 48.6719812, lng: 18.2006551 },
    { lat: 48.6721194, lng: 18.2006284 },
    { lat: 48.6722277, lng: 18.2003528 },
    { lat: 48.6722192, lng: 18.2001851 },
    { lat: 48.6722321, lng: 18.2001555 },
    { lat: 48.6722789, lng: 18.2001623 },
    { lat: 48.6723623, lng: 18.2000842 },
    { lat: 48.6723763, lng: 18.2000524 },
    { lat: 48.6723878, lng: 18.1999959 },
    { lat: 48.6724361, lng: 18.1998753 },
    { lat: 48.6725405, lng: 18.1997488 },
    { lat: 48.6726165, lng: 18.1995585 },
    { lat: 48.672649, lng: 18.1995149 },
    { lat: 48.6727449, lng: 18.1994752 },
    { lat: 48.67285, lng: 18.1993622 },
    { lat: 48.6729449, lng: 18.1993062 },
    { lat: 48.6729905, lng: 18.199293 },
    { lat: 48.6730366, lng: 18.1992316 },
    { lat: 48.6730557, lng: 18.1991727 },
    { lat: 48.673017, lng: 18.1990111 },
    { lat: 48.6730217, lng: 18.1989726 },
    { lat: 48.6731167, lng: 18.1987995 },
    { lat: 48.6733102, lng: 18.1986334 },
    { lat: 48.6733621, lng: 18.1986502 },
    { lat: 48.673511, lng: 18.1986545 },
    { lat: 48.6735382, lng: 18.1986335 },
    { lat: 48.6736096, lng: 18.1985427 },
    { lat: 48.6736365, lng: 18.1984228 },
    { lat: 48.6736545, lng: 18.1983983 },
    { lat: 48.6736934, lng: 18.1983107 },
    { lat: 48.6738036, lng: 18.1983433 },
    { lat: 48.6738605, lng: 18.1983137 },
    { lat: 48.6739059, lng: 18.1982017 },
    { lat: 48.6740128, lng: 18.1979981 },
    { lat: 48.674181, lng: 18.19792 },
    { lat: 48.6742514, lng: 18.1978133 },
    { lat: 48.6743641, lng: 18.1977331 },
    { lat: 48.6744852, lng: 18.1975816 },
    { lat: 48.6744843, lng: 18.1975656 },
    { lat: 48.6745552, lng: 18.1974831 },
    { lat: 48.6747131, lng: 18.1973421 },
    { lat: 48.6747894, lng: 18.1972385 },
    { lat: 48.6749087, lng: 18.1971171 },
    { lat: 48.6749769, lng: 18.1970089 },
    { lat: 48.6750877, lng: 18.1969126 },
    { lat: 48.6751282, lng: 18.1968923 },
    { lat: 48.6751818, lng: 18.1968428 },
    { lat: 48.6752148, lng: 18.1968288 },
    { lat: 48.675337, lng: 18.1966749 },
    { lat: 48.6753578, lng: 18.1966268 },
    { lat: 48.6755306, lng: 18.1964331 },
    { lat: 48.675559, lng: 18.1964155 },
    { lat: 48.6756862, lng: 18.1962352 },
    { lat: 48.6756832, lng: 18.1962039 },
    { lat: 48.6757477, lng: 18.1960676 },
    { lat: 48.6758027, lng: 18.196005 },
    { lat: 48.6759229, lng: 18.1959149 },
    { lat: 48.6760239, lng: 18.1957881 },
    { lat: 48.6760643, lng: 18.1957832 },
    { lat: 48.6760984, lng: 18.1957891 },
    { lat: 48.6761905, lng: 18.195742 },
    { lat: 48.6762173, lng: 18.1957181 },
    { lat: 48.6762389, lng: 18.1956828 },
    { lat: 48.6762432, lng: 18.1956215 },
    { lat: 48.676402, lng: 18.1954564 },
    { lat: 48.6765342, lng: 18.1952476 },
    { lat: 48.6765494, lng: 18.1951953 },
    { lat: 48.6766405, lng: 18.195073 },
    { lat: 48.6766912, lng: 18.1950339 },
    { lat: 48.6767714, lng: 18.1948776 },
    { lat: 48.6768175, lng: 18.1948366 },
    { lat: 48.6769215, lng: 18.1946805 },
    { lat: 48.677029, lng: 18.1945671 },
    { lat: 48.6770298, lng: 18.1943892 },
    { lat: 48.6771099, lng: 18.193993 },
    { lat: 48.6770775, lng: 18.1934145 },
    { lat: 48.6767801, lng: 18.193108 },
    { lat: 48.6765423, lng: 18.1927532 },
    { lat: 48.6760357, lng: 18.1921935 },
    { lat: 48.6753681, lng: 18.1914382 },
    { lat: 48.6752331, lng: 18.1912117 },
    { lat: 48.6748352, lng: 18.1903761 },
    { lat: 48.6747109, lng: 18.1905138 },
    { lat: 48.6746899, lng: 18.1904812 },
    { lat: 48.6741727, lng: 18.1895337 },
    { lat: 48.6740384, lng: 18.1893207 },
    { lat: 48.673685, lng: 18.1887481 },
    { lat: 48.6736292, lng: 18.1886615 },
    { lat: 48.6733224, lng: 18.1880945 },
    { lat: 48.6729679, lng: 18.1874456 },
    { lat: 48.6725608, lng: 18.1866897 },
    { lat: 48.6719445, lng: 18.1872124 },
    { lat: 48.67175, lng: 18.1872066 },
    { lat: 48.6717143, lng: 18.1868414 },
    { lat: 48.6714776, lng: 18.1864148 },
    { lat: 48.6713639, lng: 18.1859124 },
    { lat: 48.6712532, lng: 18.1855993 },
    { lat: 48.6713021, lng: 18.185525 },
    { lat: 48.6712321, lng: 18.1853489 },
    { lat: 48.670721, lng: 18.1847071 },
    { lat: 48.6705631, lng: 18.1844708 },
    { lat: 48.670406, lng: 18.1841748 },
    { lat: 48.6700649, lng: 18.1835613 },
    { lat: 48.669814, lng: 18.1831637 },
    { lat: 48.6696772, lng: 18.182965 },
    { lat: 48.6693672, lng: 18.1824617 },
    { lat: 48.6691482, lng: 18.1820889 },
    { lat: 48.6689471, lng: 18.1817555 },
    { lat: 48.6688488, lng: 18.1815756 },
    { lat: 48.668563, lng: 18.1811599 },
    { lat: 48.6681765, lng: 18.1805638 },
    { lat: 48.6679833, lng: 18.1802483 },
    { lat: 48.6677836, lng: 18.1799354 },
    { lat: 48.6672287, lng: 18.1790239 },
    { lat: 48.6670019, lng: 18.1786307 },
    { lat: 48.6668034, lng: 18.1782802 },
    { lat: 48.6665048, lng: 18.1777408 },
    { lat: 48.6664262, lng: 18.177457 },
    { lat: 48.666405, lng: 18.1773441 },
    { lat: 48.665696, lng: 18.1764593 },
    { lat: 48.6654629, lng: 18.1761933 },
    { lat: 48.6649972, lng: 18.1756931 },
    { lat: 48.6646193, lng: 18.1753049 },
    { lat: 48.6645181, lng: 18.175194 },
    { lat: 48.6639777, lng: 18.1747265 },
    { lat: 48.6633846, lng: 18.1742474 },
    { lat: 48.6629559, lng: 18.1739154 },
    { lat: 48.6627261, lng: 18.1737697 },
    { lat: 48.6620074, lng: 18.1733425 },
    { lat: 48.6613172, lng: 18.1729398 },
    { lat: 48.6612774, lng: 18.1729136 },
    { lat: 48.6612206, lng: 18.1729089 },
    { lat: 48.6610528, lng: 18.172795 },
    { lat: 48.6609299, lng: 18.172702 },
    { lat: 48.6610082, lng: 18.1725521 },
    { lat: 48.6607703, lng: 18.1721669 },
    { lat: 48.6608106, lng: 18.1720623 },
    { lat: 48.6608461, lng: 18.1718956 },
    { lat: 48.6610266, lng: 18.1716384 },
    { lat: 48.6613865, lng: 18.1712094 },
    { lat: 48.6616283, lng: 18.170807 },
    { lat: 48.6616631, lng: 18.1707267 },
    { lat: 48.6616606, lng: 18.1706999 },
    { lat: 48.6616541, lng: 18.1706847 },
    { lat: 48.6617992, lng: 18.1704828 },
    { lat: 48.662003, lng: 18.1702982 },
    { lat: 48.662012, lng: 18.1702627 },
    { lat: 48.6619877, lng: 18.1701517 },
    { lat: 48.6620475, lng: 18.1701524 },
    { lat: 48.6620633, lng: 18.1701314 },
    { lat: 48.6620716, lng: 18.170041 },
    { lat: 48.6620548, lng: 18.1700243 },
    { lat: 48.6620894, lng: 18.1699612 },
    { lat: 48.6619833, lng: 18.1698028 },
    { lat: 48.661157, lng: 18.1685881 },
    { lat: 48.6611289, lng: 18.1685504 },
    { lat: 48.6608608, lng: 18.1682859 },
    { lat: 48.6606051, lng: 18.1680408 },
    { lat: 48.6603068, lng: 18.1677446 },
    { lat: 48.660403, lng: 18.1675145 },
    { lat: 48.660895, lng: 18.1663645 },
    { lat: 48.6612273, lng: 18.1655153 },
    { lat: 48.6613977, lng: 18.165163 },
    { lat: 48.6620382, lng: 18.1638495 },
    { lat: 48.6622701, lng: 18.1632563 },
    { lat: 48.6626955, lng: 18.1624148 },
    { lat: 48.6631517, lng: 18.1613719 },
    { lat: 48.6633088, lng: 18.1610045 },
    { lat: 48.6634785, lng: 18.1606201 },
    { lat: 48.6636851, lng: 18.1601363 },
    { lat: 48.6641078, lng: 18.1602036 },
    { lat: 48.6641791, lng: 18.1602103 },
    { lat: 48.6642274, lng: 18.1601661 },
    { lat: 48.6642998, lng: 18.1600326 },
    { lat: 48.6643741, lng: 18.1599721 },
    { lat: 48.6646851, lng: 18.1599653 },
    { lat: 48.6648195, lng: 18.1598487 },
    { lat: 48.6648895, lng: 18.1595739 },
    { lat: 48.664969, lng: 18.1594895 },
    { lat: 48.665026, lng: 18.1595351 },
    { lat: 48.6653404, lng: 18.159761 },
    { lat: 48.6657574, lng: 18.1599873 },
    { lat: 48.6668082, lng: 18.1602059 },
    { lat: 48.6674752, lng: 18.160381 },
    { lat: 48.6678509, lng: 18.160245 },
    { lat: 48.6682075, lng: 18.1601268 },
    { lat: 48.6686613, lng: 18.1599138 },
    { lat: 48.6684503, lng: 18.1593711 },
    { lat: 48.6681641, lng: 18.1586967 },
    { lat: 48.6679555, lng: 18.1581319 },
    { lat: 48.6679454, lng: 18.1581329 },
    { lat: 48.6679663, lng: 18.1577153 },
    { lat: 48.6679698, lng: 18.1575227 },
    { lat: 48.6680292, lng: 18.1570486 },
    { lat: 48.6680609, lng: 18.1569359 },
    { lat: 48.6680582, lng: 18.1567338 },
    { lat: 48.6678989, lng: 18.1561065 },
    { lat: 48.6678051, lng: 18.1555213 },
    { lat: 48.6677601, lng: 18.1551531 },
    { lat: 48.6676751, lng: 18.1548316 },
    { lat: 48.6675183, lng: 18.1545063 },
    { lat: 48.66744, lng: 18.1542646 },
    { lat: 48.6674261, lng: 18.1538498 },
    { lat: 48.6674578, lng: 18.1537153 },
    { lat: 48.6674002, lng: 18.1537537 },
    { lat: 48.6674354, lng: 18.1535198 },
    { lat: 48.6673436, lng: 18.1532431 },
    { lat: 48.667206, lng: 18.1529332 },
    { lat: 48.6671776, lng: 18.1526192 },
    { lat: 48.6671824, lng: 18.1523912 },
    { lat: 48.6671759, lng: 18.1522392 },
    { lat: 48.6670465, lng: 18.1517979 },
    { lat: 48.6671286, lng: 18.1516753 },
    { lat: 48.6671211, lng: 18.1515053 },
    { lat: 48.666977, lng: 18.1508717 },
    { lat: 48.6669654, lng: 18.1506316 },
    { lat: 48.6670178, lng: 18.1503119 },
    { lat: 48.6670674, lng: 18.1501161 },
    { lat: 48.6671892, lng: 18.1497045 },
    { lat: 48.6672927, lng: 18.1492909 },
    { lat: 48.6674451, lng: 18.1488074 },
    { lat: 48.6676373, lng: 18.1483474 },
    { lat: 48.6677244, lng: 18.1480742 },
    { lat: 48.6677714, lng: 18.147952 },
    { lat: 48.6677804, lng: 18.1477205 },
    { lat: 48.6676562, lng: 18.1470183 },
    { lat: 48.6679426, lng: 18.1462935 },
    { lat: 48.6678191, lng: 18.1457636 },
    { lat: 48.6681602, lng: 18.1451254 },
    { lat: 48.668305, lng: 18.1448003 },
    { lat: 48.6684239, lng: 18.1442626 },
    { lat: 48.6684058, lng: 18.1437952 },
    { lat: 48.6684103, lng: 18.1432347 },
    { lat: 48.6689374, lng: 18.1425327 },
    { lat: 48.668761, lng: 18.14219 },
  ],
  Šípkov: [
    { lat: 48.88471, lng: 18.289503 },
    { lat: 48.8840185, lng: 18.2879795 },
    { lat: 48.8826307, lng: 18.2865837 },
    { lat: 48.8816025, lng: 18.2872957 },
    { lat: 48.8814086, lng: 18.2870822 },
    { lat: 48.8805266, lng: 18.2861093 },
    { lat: 48.8801341, lng: 18.2843386 },
    { lat: 48.8795692, lng: 18.2829882 },
    { lat: 48.8794198, lng: 18.2823409 },
    { lat: 48.8787256, lng: 18.2796464 },
    { lat: 48.8779813, lng: 18.2809287 },
    { lat: 48.8770121, lng: 18.2807265 },
    { lat: 48.8761245, lng: 18.2815314 },
    { lat: 48.8750745, lng: 18.2825756 },
    { lat: 48.8737765, lng: 18.2830262 },
    { lat: 48.8721917, lng: 18.2834306 },
    { lat: 48.8713931, lng: 18.2817614 },
    { lat: 48.8717571, lng: 18.2811958 },
    { lat: 48.8717184, lng: 18.280989 },
    { lat: 48.8703307, lng: 18.2805729 },
    { lat: 48.8704118, lng: 18.28019 },
    { lat: 48.8703223, lng: 18.2797658 },
    { lat: 48.8693469, lng: 18.2785033 },
    { lat: 48.8682462, lng: 18.2794268 },
    { lat: 48.8676014, lng: 18.2785001 },
    { lat: 48.8667489, lng: 18.2792647 },
    { lat: 48.8662656, lng: 18.2796774 },
    { lat: 48.8658566, lng: 18.2793906 },
    { lat: 48.8654641, lng: 18.2800075 },
    { lat: 48.8651723, lng: 18.2799362 },
    { lat: 48.8648619, lng: 18.2801314 },
    { lat: 48.864503, lng: 18.2798151 },
    { lat: 48.8641677, lng: 18.279314 },
    { lat: 48.8637527, lng: 18.2786191 },
    { lat: 48.8630522, lng: 18.2787203 },
    { lat: 48.862676, lng: 18.279291 },
    { lat: 48.8624111, lng: 18.2798903 },
    { lat: 48.8612922, lng: 18.2800972 },
    { lat: 48.8606169, lng: 18.2798292 },
    { lat: 48.8598925, lng: 18.2788112 },
    { lat: 48.8591763, lng: 18.2782598 },
    { lat: 48.8591024, lng: 18.2781079 },
    { lat: 48.8585423, lng: 18.2773853 },
    { lat: 48.8576502, lng: 18.2767923 },
    { lat: 48.8574738, lng: 18.2767888 },
    { lat: 48.8568728, lng: 18.2765338 },
    { lat: 48.8562604, lng: 18.2768952 },
    { lat: 48.8550472, lng: 18.2769535 },
    { lat: 48.8538094, lng: 18.2769104 },
    { lat: 48.8527585, lng: 18.2776567 },
    { lat: 48.8520868, lng: 18.2779483 },
    { lat: 48.8507316, lng: 18.2790426 },
    { lat: 48.8496602, lng: 18.2801088 },
    { lat: 48.8496339, lng: 18.2801409 },
    { lat: 48.8496328, lng: 18.279983 },
    { lat: 48.8495477, lng: 18.2798434 },
    { lat: 48.8494685, lng: 18.2797135 },
    { lat: 48.8492307, lng: 18.2795173 },
    { lat: 48.849057, lng: 18.2789961 },
    { lat: 48.8489074, lng: 18.278547 },
    { lat: 48.8487232, lng: 18.2780268 },
    { lat: 48.8486813, lng: 18.2777882 },
    { lat: 48.8484236, lng: 18.2782556 },
    { lat: 48.8481879, lng: 18.278598 },
    { lat: 48.8480405, lng: 18.2790205 },
    { lat: 48.8478955, lng: 18.2790698 },
    { lat: 48.8473421, lng: 18.2790011 },
    { lat: 48.8459381, lng: 18.2793335 },
    { lat: 48.8456359, lng: 18.2790562 },
    { lat: 48.8455014, lng: 18.2789741 },
    { lat: 48.8451776, lng: 18.2788554 },
    { lat: 48.8449268, lng: 18.2787476 },
    { lat: 48.8447002, lng: 18.2787706 },
    { lat: 48.8431585, lng: 18.2794802 },
    { lat: 48.8426678, lng: 18.2802795 },
    { lat: 48.8424321, lng: 18.2801016 },
    { lat: 48.8420984, lng: 18.2799864 },
    { lat: 48.8418795, lng: 18.2798666 },
    { lat: 48.8414522, lng: 18.2794945 },
    { lat: 48.8415251, lng: 18.2800624 },
    { lat: 48.841606, lng: 18.2803556 },
    { lat: 48.8416817, lng: 18.2805388 },
    { lat: 48.8419513, lng: 18.2809322 },
    { lat: 48.8424871, lng: 18.2816476 },
    { lat: 48.8434501, lng: 18.2829746 },
    { lat: 48.8437561, lng: 18.2834968 },
    { lat: 48.8437043, lng: 18.2835967 },
    { lat: 48.8430083, lng: 18.2845383 },
    { lat: 48.8428058, lng: 18.2852237 },
    { lat: 48.8435065, lng: 18.2863795 },
    { lat: 48.8445352, lng: 18.287612 },
    { lat: 48.8443942, lng: 18.2884879 },
    { lat: 48.8440783, lng: 18.2890888 },
    { lat: 48.8442584, lng: 18.290014 },
    { lat: 48.8428687, lng: 18.2911176 },
    { lat: 48.8416894, lng: 18.2921336 },
    { lat: 48.84033, lng: 18.2931708 },
    { lat: 48.8403269, lng: 18.2938743 },
    { lat: 48.8402673, lng: 18.2941371 },
    { lat: 48.8405718, lng: 18.2951739 },
    { lat: 48.8409347, lng: 18.2963235 },
    { lat: 48.840807, lng: 18.2970786 },
    { lat: 48.8390795, lng: 18.2971978 },
    { lat: 48.8382246, lng: 18.2986572 },
    { lat: 48.8384593, lng: 18.2994608 },
    { lat: 48.838588, lng: 18.2999013 },
    { lat: 48.8395075, lng: 18.3019146 },
    { lat: 48.8402471, lng: 18.3043026 },
    { lat: 48.8405467, lng: 18.3050149 },
    { lat: 48.8409757, lng: 18.3062187 },
    { lat: 48.8414698, lng: 18.3070773 },
    { lat: 48.8418884, lng: 18.3076363 },
    { lat: 48.8429443, lng: 18.3090734 },
    { lat: 48.8432759, lng: 18.3094908 },
    { lat: 48.8438265, lng: 18.3104919 },
    { lat: 48.8449336, lng: 18.3123996 },
    { lat: 48.8463781, lng: 18.3138246 },
    { lat: 48.8474649, lng: 18.3152906 },
    { lat: 48.8484393, lng: 18.3162034 },
    { lat: 48.8470551, lng: 18.317408 },
    { lat: 48.8487877, lng: 18.3182407 },
    { lat: 48.8502384, lng: 18.3192618 },
    { lat: 48.8514698, lng: 18.3213713 },
    { lat: 48.8529772, lng: 18.3209719 },
    { lat: 48.8542396, lng: 18.3202913 },
    { lat: 48.8553974, lng: 18.3204182 },
    { lat: 48.8555526, lng: 18.3208065 },
    { lat: 48.856258, lng: 18.3211316 },
    { lat: 48.8571411, lng: 18.3215706 },
    { lat: 48.8578502, lng: 18.3214797 },
    { lat: 48.8592284, lng: 18.3214301 },
    { lat: 48.8599158, lng: 18.3213147 },
    { lat: 48.8604391, lng: 18.3203294 },
    { lat: 48.8610043, lng: 18.3201857 },
    { lat: 48.8613186, lng: 18.3195209 },
    { lat: 48.861343, lng: 18.3194292 },
    { lat: 48.8613074, lng: 18.3193206 },
    { lat: 48.8613191, lng: 18.3192273 },
    { lat: 48.8615455, lng: 18.3195825 },
    { lat: 48.861923, lng: 18.3197319 },
    { lat: 48.8620126, lng: 18.3195802 },
    { lat: 48.8620417, lng: 18.319556 },
    { lat: 48.8620514, lng: 18.3195268 },
    { lat: 48.86208, lng: 18.3194754 },
    { lat: 48.8621163, lng: 18.3194406 },
    { lat: 48.8623476, lng: 18.319879 },
    { lat: 48.8625625, lng: 18.3203117 },
    { lat: 48.8626748, lng: 18.320701 },
    { lat: 48.8629535, lng: 18.3204829 },
    { lat: 48.8644057, lng: 18.3210053 },
    { lat: 48.8646, lng: 18.3208118 },
    { lat: 48.8648494, lng: 18.3211504 },
    { lat: 48.8650061, lng: 18.3209703 },
    { lat: 48.8655372, lng: 18.3206232 },
    { lat: 48.8658443, lng: 18.3201058 },
    { lat: 48.8662575, lng: 18.3194503 },
    { lat: 48.8677438, lng: 18.3197558 },
    { lat: 48.8683677, lng: 18.3194361 },
    { lat: 48.8715578, lng: 18.3159996 },
    { lat: 48.8727184, lng: 18.3130907 },
    { lat: 48.8735479, lng: 18.3110128 },
    { lat: 48.8735494, lng: 18.30696 },
    { lat: 48.8735492, lng: 18.3062722 },
    { lat: 48.8737013, lng: 18.3050934 },
    { lat: 48.8738216, lng: 18.3044223 },
    { lat: 48.8743913, lng: 18.3037204 },
    { lat: 48.8766706, lng: 18.3031083 },
    { lat: 48.878412, lng: 18.302063 },
    { lat: 48.879105, lng: 18.301792 },
    { lat: 48.879956, lng: 18.301348 },
    { lat: 48.880167, lng: 18.299801 },
    { lat: 48.880444, lng: 18.299233 },
    { lat: 48.880765, lng: 18.298683 },
    { lat: 48.880857, lng: 18.298158 },
    { lat: 48.880819, lng: 18.297281 },
    { lat: 48.88072, lng: 18.296085 },
    { lat: 48.88097, lng: 18.295437 },
    { lat: 48.880992, lng: 18.295416 },
    { lat: 48.881139, lng: 18.295266 },
    { lat: 48.881451, lng: 18.2951 },
    { lat: 48.88154, lng: 18.295052 },
    { lat: 48.881763, lng: 18.29462 },
    { lat: 48.881872, lng: 18.294321 },
    { lat: 48.88211, lng: 18.293889 },
    { lat: 48.882164, lng: 18.293791 },
    { lat: 48.882599, lng: 18.293045 },
    { lat: 48.882694, lng: 18.292955 },
    { lat: 48.882902, lng: 18.292761 },
    { lat: 48.882914, lng: 18.292749 },
    { lat: 48.883124, lng: 18.29252 },
    { lat: 48.88325, lng: 18.292378 },
    { lat: 48.88329, lng: 18.291555 },
    { lat: 48.88346, lng: 18.291666 },
    { lat: 48.883748, lng: 18.290882 },
    { lat: 48.884399, lng: 18.28989 },
    { lat: 48.884518, lng: 18.28973 },
    { lat: 48.884579, lng: 18.289657 },
    { lat: 48.88471, lng: 18.289503 },
  ],
  ŽitnáRadiša: [
    { lat: 48.790252, lng: 18.3671124 },
    { lat: 48.7903485, lng: 18.3670843 },
    { lat: 48.7903209, lng: 18.3668324 },
    { lat: 48.7908449, lng: 18.3656936 },
    { lat: 48.7910256, lng: 18.3651931 },
    { lat: 48.7910205, lng: 18.3651021 },
    { lat: 48.7906612, lng: 18.364608 },
    { lat: 48.7905873, lng: 18.3641537 },
    { lat: 48.7905084, lng: 18.3640587 },
    { lat: 48.7904798, lng: 18.3640079 },
    { lat: 48.7903187, lng: 18.3631874 },
    { lat: 48.7904632, lng: 18.3629893 },
    { lat: 48.790985, lng: 18.3623534 },
    { lat: 48.791176, lng: 18.3620577 },
    { lat: 48.7912761, lng: 18.3618531 },
    { lat: 48.7914104, lng: 18.3615281 },
    { lat: 48.7914722, lng: 18.3613019 },
    { lat: 48.7916409, lng: 18.3611143 },
    { lat: 48.7921243, lng: 18.3605177 },
    { lat: 48.7923892, lng: 18.3603134 },
    { lat: 48.7925662, lng: 18.3603114 },
    { lat: 48.7927444, lng: 18.3604084 },
    { lat: 48.7927811, lng: 18.3600802 },
    { lat: 48.7928159, lng: 18.3599956 },
    { lat: 48.7927799, lng: 18.3595952 },
    { lat: 48.7927879, lng: 18.3592149 },
    { lat: 48.7928625, lng: 18.3587319 },
    { lat: 48.7928699, lng: 18.3584833 },
    { lat: 48.7927631, lng: 18.3581861 },
    { lat: 48.7927979, lng: 18.3581434 },
    { lat: 48.7929747, lng: 18.3575957 },
    { lat: 48.7929616, lng: 18.3572006 },
    { lat: 48.792984, lng: 18.3569931 },
    { lat: 48.793039, lng: 18.356747 },
    { lat: 48.7932168, lng: 18.3563971 },
    { lat: 48.7934147, lng: 18.3561666 },
    { lat: 48.7933486, lng: 18.3557128 },
    { lat: 48.7933714, lng: 18.355511 },
    { lat: 48.7933787, lng: 18.3552628 },
    { lat: 48.7934344, lng: 18.3546455 },
    { lat: 48.7934204, lng: 18.3541764 },
    { lat: 48.7933565, lng: 18.3540186 },
    { lat: 48.7931575, lng: 18.3537719 },
    { lat: 48.7930978, lng: 18.353533 },
    { lat: 48.7931203, lng: 18.3532675 },
    { lat: 48.7930633, lng: 18.3530562 },
    { lat: 48.7930372, lng: 18.3528323 },
    { lat: 48.7929316, lng: 18.3525811 },
    { lat: 48.7927539, lng: 18.3520464 },
    { lat: 48.7926859, lng: 18.3519421 },
    { lat: 48.7925217, lng: 18.3517296 },
    { lat: 48.7924512, lng: 18.3515984 },
    { lat: 48.7923779, lng: 18.3514382 },
    { lat: 48.7922841, lng: 18.3511937 },
    { lat: 48.7922393, lng: 18.3511 },
    { lat: 48.7919344, lng: 18.3508533 },
    { lat: 48.7918445, lng: 18.3506194 },
    { lat: 48.7915921, lng: 18.3502418 },
    { lat: 48.7915767, lng: 18.35017 },
    { lat: 48.7913989, lng: 18.3497946 },
    { lat: 48.7912169, lng: 18.3495883 },
    { lat: 48.7911301, lng: 18.3495079 },
    { lat: 48.7910628, lng: 18.3494933 },
    { lat: 48.7907274, lng: 18.3495493 },
    { lat: 48.790203, lng: 18.3495137 },
    { lat: 48.7901217, lng: 18.3494515 },
    { lat: 48.7900961, lng: 18.349215 },
    { lat: 48.7900393, lng: 18.3490066 },
    { lat: 48.7898703, lng: 18.3490537 },
    { lat: 48.7897233, lng: 18.348845 },
    { lat: 48.7896335, lng: 18.3486348 },
    { lat: 48.7894919, lng: 18.3485843 },
    { lat: 48.7893332, lng: 18.3486512 },
    { lat: 48.7891333, lng: 18.349069 },
    { lat: 48.7890958, lng: 18.3490706 },
    { lat: 48.7891227, lng: 18.3489387 },
    { lat: 48.7891178, lng: 18.3480481 },
    { lat: 48.7897836, lng: 18.3463005 },
    { lat: 48.7903701, lng: 18.3458154 },
    { lat: 48.7905069, lng: 18.3452385 },
    { lat: 48.7907952, lng: 18.344668 },
    { lat: 48.7907908, lng: 18.3440463 },
    { lat: 48.7908606, lng: 18.3437446 },
    { lat: 48.7911887, lng: 18.3425725 },
    { lat: 48.7914665, lng: 18.3420175 },
    { lat: 48.791858, lng: 18.341454 },
    { lat: 48.7920598, lng: 18.341213 },
    { lat: 48.7924084, lng: 18.3407098 },
    { lat: 48.7926964, lng: 18.3404923 },
    { lat: 48.7929737, lng: 18.3401475 },
    { lat: 48.7929484, lng: 18.3400588 },
    { lat: 48.7940537, lng: 18.3392684 },
    { lat: 48.7944807, lng: 18.3390374 },
    { lat: 48.7946456, lng: 18.3389428 },
    { lat: 48.7947088, lng: 18.338881 },
    { lat: 48.7950571, lng: 18.3386554 },
    { lat: 48.7955788, lng: 18.3382418 },
    { lat: 48.795821, lng: 18.3378138 },
    { lat: 48.7960913, lng: 18.3369631 },
    { lat: 48.7961485, lng: 18.3359144 },
    { lat: 48.7961403, lng: 18.3358074 },
    { lat: 48.7959412, lng: 18.3344142 },
    { lat: 48.7963532, lng: 18.3339982 },
    { lat: 48.7973623, lng: 18.3335239 },
    { lat: 48.7975486, lng: 18.3327627 },
    { lat: 48.7975417, lng: 18.3322998 },
    { lat: 48.7977788, lng: 18.3315818 },
    { lat: 48.7984358, lng: 18.3304252 },
    { lat: 48.7982676, lng: 18.3296206 },
    { lat: 48.7986721, lng: 18.3293516 },
    { lat: 48.7995195, lng: 18.3286317 },
    { lat: 48.8002205, lng: 18.3281784 },
    { lat: 48.8007458, lng: 18.327849 },
    { lat: 48.8012515, lng: 18.3275522 },
    { lat: 48.8017068, lng: 18.3269846 },
    { lat: 48.8021324, lng: 18.3264272 },
    { lat: 48.8022731, lng: 18.3256202 },
    { lat: 48.8023654, lng: 18.3250353 },
    { lat: 48.8024628, lng: 18.3237805 },
    { lat: 48.8025824, lng: 18.3231362 },
    { lat: 48.8029375, lng: 18.3223907 },
    { lat: 48.8030642, lng: 18.3222759 },
    { lat: 48.8035905, lng: 18.3216063 },
    { lat: 48.8037802, lng: 18.3214103 },
    { lat: 48.8039104, lng: 18.3209354 },
    { lat: 48.804147, lng: 18.3205512 },
    { lat: 48.8046248, lng: 18.3199974 },
    { lat: 48.8048155, lng: 18.3196184 },
    { lat: 48.8050862, lng: 18.3191578 },
    { lat: 48.8056419, lng: 18.3185674 },
    { lat: 48.8053389, lng: 18.3184179 },
    { lat: 48.8046155, lng: 18.3182147 },
    { lat: 48.804426, lng: 18.3180738 },
    { lat: 48.8037511, lng: 18.3174918 },
    { lat: 48.8035789, lng: 18.3173193 },
    { lat: 48.8033041, lng: 18.3169865 },
    { lat: 48.8029561, lng: 18.3165383 },
    { lat: 48.8021646, lng: 18.3163457 },
    { lat: 48.8015773, lng: 18.3161149 },
    { lat: 48.8009323, lng: 18.3157194 },
    { lat: 48.8005398, lng: 18.3159617 },
    { lat: 48.80052, lng: 18.3159545 },
    { lat: 48.8004829, lng: 18.3159346 },
    { lat: 48.7998568, lng: 18.315959 },
    { lat: 48.7994945, lng: 18.3161959 },
    { lat: 48.7992318, lng: 18.3167781 },
    { lat: 48.7982167, lng: 18.316423 },
    { lat: 48.7977429, lng: 18.3162634 },
    { lat: 48.7969241, lng: 18.3163882 },
    { lat: 48.7959192, lng: 18.3165964 },
    { lat: 48.7958721, lng: 18.3166004 },
    { lat: 48.7957858, lng: 18.3166119 },
    { lat: 48.7955476, lng: 18.316891 },
    { lat: 48.7950719, lng: 18.3170366 },
    { lat: 48.7947823, lng: 18.3172848 },
    { lat: 48.7943981, lng: 18.3179728 },
    { lat: 48.7940895, lng: 18.3183025 },
    { lat: 48.7938293, lng: 18.3187111 },
    { lat: 48.7931454, lng: 18.3194883 },
    { lat: 48.7926964, lng: 18.31981 },
    { lat: 48.7921219, lng: 18.3201254 },
    { lat: 48.7911599, lng: 18.3203137 },
    { lat: 48.7909132, lng: 18.320459 },
    { lat: 48.7904865, lng: 18.3208945 },
    { lat: 48.7901298, lng: 18.3213109 },
    { lat: 48.7898949, lng: 18.3215054 },
    { lat: 48.7898106, lng: 18.3216333 },
    { lat: 48.7894248, lng: 18.3213153 },
    { lat: 48.7885638, lng: 18.3211531 },
    { lat: 48.7880797, lng: 18.3208689 },
    { lat: 48.7877431, lng: 18.3207832 },
    { lat: 48.7868139, lng: 18.3202316 },
    { lat: 48.7867988, lng: 18.3202246 },
    { lat: 48.786397, lng: 18.3204404 },
    { lat: 48.785859, lng: 18.3207224 },
    { lat: 48.7856063, lng: 18.3207858 },
    { lat: 48.7849742, lng: 18.3207586 },
    { lat: 48.7842736, lng: 18.3206824 },
    { lat: 48.7839293, lng: 18.3207773 },
    { lat: 48.7837365, lng: 18.3207578 },
    { lat: 48.7831854, lng: 18.3205495 },
    { lat: 48.7825086, lng: 18.3203318 },
    { lat: 48.7821112, lng: 18.3202278 },
    { lat: 48.7813237, lng: 18.3207266 },
    { lat: 48.7809564, lng: 18.3209593 },
    { lat: 48.7804414, lng: 18.3214283 },
    { lat: 48.7800908, lng: 18.3216538 },
    { lat: 48.7799562, lng: 18.3220468 },
    { lat: 48.7798693, lng: 18.3223267 },
    { lat: 48.7797309, lng: 18.3228365 },
    { lat: 48.7795887, lng: 18.3234336 },
    { lat: 48.7790188, lng: 18.3233312 },
    { lat: 48.778138, lng: 18.32374 },
    { lat: 48.7777235, lng: 18.3236293 },
    { lat: 48.7771993, lng: 18.3231593 },
    { lat: 48.7768876, lng: 18.3229945 },
    { lat: 48.7764176, lng: 18.3227808 },
    { lat: 48.7758496, lng: 18.3226105 },
    { lat: 48.7756163, lng: 18.3225562 },
    { lat: 48.7752596, lng: 18.3225336 },
    { lat: 48.7751391, lng: 18.3226561 },
    { lat: 48.7751179, lng: 18.3233406 },
    { lat: 48.7749318, lng: 18.3242245 },
    { lat: 48.7742163, lng: 18.3243626 },
    { lat: 48.7736785, lng: 18.3244045 },
    { lat: 48.7731524, lng: 18.3242831 },
    { lat: 48.772646, lng: 18.3243255 },
    { lat: 48.772364, lng: 18.3244471 },
    { lat: 48.7720642, lng: 18.3243931 },
    { lat: 48.7719718, lng: 18.3246948 },
    { lat: 48.7715113, lng: 18.3246293 },
    { lat: 48.7714237, lng: 18.3245176 },
    { lat: 48.7713783, lng: 18.3241536 },
    { lat: 48.7712472, lng: 18.323987 },
    { lat: 48.7713257, lng: 18.3235391 },
    { lat: 48.7708188, lng: 18.323274 },
    { lat: 48.7706533, lng: 18.3229595 },
    { lat: 48.7705719, lng: 18.322078 },
    { lat: 48.7701723, lng: 18.3219933 },
    { lat: 48.7697993, lng: 18.3223594 },
    { lat: 48.7695587, lng: 18.3228543 },
    { lat: 48.7691858, lng: 18.3230845 },
    { lat: 48.7685426, lng: 18.3237834 },
    { lat: 48.7683086, lng: 18.3238551 },
    { lat: 48.7678143, lng: 18.3230129 },
    { lat: 48.7676281, lng: 18.3223735 },
    { lat: 48.7677378, lng: 18.3216773 },
    { lat: 48.7671387, lng: 18.3209349 },
    { lat: 48.7670989, lng: 18.3208924 },
    { lat: 48.7670738, lng: 18.320925 },
    { lat: 48.7664849, lng: 18.3202875 },
    { lat: 48.7658853, lng: 18.3196961 },
    { lat: 48.7653481, lng: 18.3189336 },
    { lat: 48.764615, lng: 18.3181789 },
    { lat: 48.7642812, lng: 18.3176809 },
    { lat: 48.7638594, lng: 18.317224 },
    { lat: 48.7637322, lng: 18.3176759 },
    { lat: 48.7635909, lng: 18.3179839 },
    { lat: 48.7632382, lng: 18.3183921 },
    { lat: 48.7630937, lng: 18.3186722 },
    { lat: 48.7628218, lng: 18.3189315 },
    { lat: 48.7627977, lng: 18.3191234 },
    { lat: 48.762795, lng: 18.3197553 },
    { lat: 48.7627483, lng: 18.3200507 },
    { lat: 48.7626778, lng: 18.3201377 },
    { lat: 48.7621225, lng: 18.3202562 },
    { lat: 48.7617835, lng: 18.3202481 },
    { lat: 48.7616091, lng: 18.3203171 },
    { lat: 48.7614685, lng: 18.3204222 },
    { lat: 48.7612666, lng: 18.3208037 },
    { lat: 48.7610774, lng: 18.3210513 },
    { lat: 48.7608899, lng: 18.321348 },
    { lat: 48.7606751, lng: 18.3218195 },
    { lat: 48.7604362, lng: 18.322224 },
    { lat: 48.7603144, lng: 18.3227432 },
    { lat: 48.7601413, lng: 18.3235344 },
    { lat: 48.7600939, lng: 18.3247584 },
    { lat: 48.7603945, lng: 18.324705 },
    { lat: 48.7607152, lng: 18.3247108 },
    { lat: 48.760962, lng: 18.3247856 },
    { lat: 48.7609223, lng: 18.3257641 },
    { lat: 48.761667, lng: 18.3260017 },
    { lat: 48.7616198, lng: 18.326109 },
    { lat: 48.7615193, lng: 18.3270853 },
    { lat: 48.7613896, lng: 18.3270663 },
    { lat: 48.7609932, lng: 18.3267401 },
    { lat: 48.7605142, lng: 18.3271888 },
    { lat: 48.7602467, lng: 18.3277704 },
    { lat: 48.7602312, lng: 18.327874 },
    { lat: 48.7600356, lng: 18.3279485 },
    { lat: 48.7599449, lng: 18.3281798 },
    { lat: 48.7599413, lng: 18.3291565 },
    { lat: 48.7596006, lng: 18.3295387 },
    { lat: 48.7595115, lng: 18.3295813 },
    { lat: 48.7594991, lng: 18.3296801 },
    { lat: 48.7594341, lng: 18.3298273 },
    { lat: 48.759467, lng: 18.3299332 },
    { lat: 48.7594041, lng: 18.3301751 },
    { lat: 48.7593658, lng: 18.3303989 },
    { lat: 48.7593063, lng: 18.3305643 },
    { lat: 48.7591939, lng: 18.3307933 },
    { lat: 48.759054, lng: 18.3312136 },
    { lat: 48.7589961, lng: 18.3313648 },
    { lat: 48.7589364, lng: 18.3316486 },
    { lat: 48.7586863, lng: 18.3328758 },
    { lat: 48.7586121, lng: 18.3330824 },
    { lat: 48.7585603, lng: 18.3332668 },
    { lat: 48.7584422, lng: 18.3336299 },
    { lat: 48.7583314, lng: 18.3339074 },
    { lat: 48.7582235, lng: 18.3342553 },
    { lat: 48.758181, lng: 18.3345407 },
    { lat: 48.7580792, lng: 18.3348377 },
    { lat: 48.7579637, lng: 18.3350704 },
    { lat: 48.7578665, lng: 18.3352299 },
    { lat: 48.7577427, lng: 18.335617 },
    { lat: 48.7576265, lng: 18.3358375 },
    { lat: 48.7575203, lng: 18.3360761 },
    { lat: 48.7574864, lng: 18.3361924 },
    { lat: 48.7573697, lng: 18.3361642 },
    { lat: 48.7566683, lng: 18.3361697 },
    { lat: 48.7567698, lng: 18.3385366 },
    { lat: 48.7568436, lng: 18.3403117 },
    { lat: 48.7568437, lng: 18.3403725 },
    { lat: 48.7566864, lng: 18.3403241 },
    { lat: 48.756536, lng: 18.3403195 },
    { lat: 48.7565142, lng: 18.3403309 },
    { lat: 48.7564711, lng: 18.3404058 },
    { lat: 48.7564636, lng: 18.3406338 },
    { lat: 48.7564387, lng: 18.3407339 },
    { lat: 48.7564005, lng: 18.3407181 },
    { lat: 48.7563947, lng: 18.3406941 },
    { lat: 48.7563052, lng: 18.3405873 },
    { lat: 48.7562436, lng: 18.3406732 },
    { lat: 48.7564047, lng: 18.3411541 },
    { lat: 48.7563133, lng: 18.3417158 },
    { lat: 48.7562601, lng: 18.342013 },
    { lat: 48.7560954, lng: 18.3430378 },
    { lat: 48.75602, lng: 18.3434409 },
    { lat: 48.7568182, lng: 18.3442125 },
    { lat: 48.7573393, lng: 18.3447129 },
    { lat: 48.7577499, lng: 18.3451117 },
    { lat: 48.7576304, lng: 18.3455762 },
    { lat: 48.7573503, lng: 18.3468773 },
    { lat: 48.7574718, lng: 18.3471291 },
    { lat: 48.7571366, lng: 18.3478706 },
    { lat: 48.7569667, lng: 18.3480783 },
    { lat: 48.7569384, lng: 18.348128 },
    { lat: 48.7567037, lng: 18.3485405 },
    { lat: 48.7566738, lng: 18.3487907 },
    { lat: 48.7566553, lng: 18.3490838 },
    { lat: 48.7566609, lng: 18.3491835 },
    { lat: 48.7567156, lng: 18.3493749 },
    { lat: 48.7568153, lng: 18.3495413 },
    { lat: 48.7566987, lng: 18.3498183 },
    { lat: 48.7567127, lng: 18.3502663 },
    { lat: 48.7567359, lng: 18.3507432 },
    { lat: 48.7567725, lng: 18.3508898 },
    { lat: 48.7568032, lng: 18.3510953 },
    { lat: 48.7568202, lng: 18.3514403 },
    { lat: 48.7568492, lng: 18.3516744 },
    { lat: 48.7569778, lng: 18.3521166 },
    { lat: 48.75661, lng: 18.3524237 },
    { lat: 48.7565284, lng: 18.3524919 },
    { lat: 48.7561446, lng: 18.3526663 },
    { lat: 48.7558261, lng: 18.3526028 },
    { lat: 48.7557105, lng: 18.3527335 },
    { lat: 48.75549, lng: 18.3528447 },
    { lat: 48.7555022, lng: 18.3530613 },
    { lat: 48.7553523, lng: 18.3531824 },
    { lat: 48.7554637, lng: 18.3535003 },
    { lat: 48.7554906, lng: 18.3536608 },
    { lat: 48.7555298, lng: 18.3538948 },
    { lat: 48.7555883, lng: 18.3540346 },
    { lat: 48.7556831, lng: 18.3541354 },
    { lat: 48.7557684, lng: 18.3540054 },
    { lat: 48.7562621, lng: 18.3545624 },
    { lat: 48.7563575, lng: 18.3548934 },
    { lat: 48.7563889, lng: 18.35517 },
    { lat: 48.7565874, lng: 18.3554928 },
    { lat: 48.7567603, lng: 18.3558189 },
    { lat: 48.7566539, lng: 18.3558518 },
    { lat: 48.7559239, lng: 18.3560514 },
    { lat: 48.7552503, lng: 18.3562519 },
    { lat: 48.754972, lng: 18.3563412 },
    { lat: 48.7552488, lng: 18.3571102 },
    { lat: 48.7555745, lng: 18.3579902 },
    { lat: 48.7559381, lng: 18.358959 },
    { lat: 48.7562081, lng: 18.3596516 },
    { lat: 48.7562203, lng: 18.3603322 },
    { lat: 48.7562315, lng: 18.3613763 },
    { lat: 48.7562573, lng: 18.3626423 },
    { lat: 48.7562636, lng: 18.3631381 },
    { lat: 48.7562827, lng: 18.3640614 },
    { lat: 48.7563689, lng: 18.3652367 },
    { lat: 48.7563353, lng: 18.3658049 },
    { lat: 48.7563272, lng: 18.3659826 },
    { lat: 48.7565424, lng: 18.3668235 },
    { lat: 48.7566128, lng: 18.3672357 },
    { lat: 48.7569919, lng: 18.3679598 },
    { lat: 48.7574109, lng: 18.3687929 },
    { lat: 48.7568644, lng: 18.369411 },
    { lat: 48.7564811, lng: 18.3702631 },
    { lat: 48.7564166, lng: 18.3717113 },
    { lat: 48.7562203, lng: 18.3727579 },
    { lat: 48.756139, lng: 18.3730208 },
    { lat: 48.7564725, lng: 18.3732731 },
    { lat: 48.756904, lng: 18.3735647 },
    { lat: 48.7579353, lng: 18.3742926 },
    { lat: 48.7587936, lng: 18.3748954 },
    { lat: 48.7591286, lng: 18.3741266 },
    { lat: 48.7595475, lng: 18.3733271 },
    { lat: 48.759889, lng: 18.3726358 },
    { lat: 48.7604295, lng: 18.3715064 },
    { lat: 48.7605795, lng: 18.3705396 },
    { lat: 48.7607014, lng: 18.3702558 },
    { lat: 48.7608, lng: 18.3702264 },
    { lat: 48.7609992, lng: 18.3699541 },
    { lat: 48.7611346, lng: 18.3696915 },
    { lat: 48.7612258, lng: 18.3685795 },
    { lat: 48.7612356, lng: 18.3685357 },
    { lat: 48.7615651, lng: 18.3680526 },
    { lat: 48.7617911, lng: 18.3681213 },
    { lat: 48.7619821, lng: 18.3683857 },
    { lat: 48.7622273, lng: 18.3686568 },
    { lat: 48.7622247, lng: 18.3692948 },
    { lat: 48.7623653, lng: 18.3695095 },
    { lat: 48.7626821, lng: 18.3694457 },
    { lat: 48.7626301, lng: 18.3693612 },
    { lat: 48.7630573, lng: 18.3693147 },
    { lat: 48.7633875, lng: 18.3693893 },
    { lat: 48.7638909, lng: 18.3694765 },
    { lat: 48.7640668, lng: 18.3694514 },
    { lat: 48.7640741, lng: 18.3694208 },
    { lat: 48.7640814, lng: 18.3692093 },
    { lat: 48.7641053, lng: 18.3691121 },
    { lat: 48.7643196, lng: 18.3686084 },
    { lat: 48.7646766, lng: 18.3688366 },
    { lat: 48.7647721, lng: 18.3688687 },
    { lat: 48.7647989, lng: 18.3693695 },
    { lat: 48.7647887, lng: 18.3696722 },
    { lat: 48.7650747, lng: 18.3696596 },
    { lat: 48.7653426, lng: 18.369631 },
    { lat: 48.7655397, lng: 18.3695835 },
    { lat: 48.7655544, lng: 18.3696253 },
    { lat: 48.7656475, lng: 18.3697342 },
    { lat: 48.7657652, lng: 18.3699037 },
    { lat: 48.7660107, lng: 18.3701798 },
    { lat: 48.7662257, lng: 18.3704934 },
    { lat: 48.7664071, lng: 18.3707488 },
    { lat: 48.7665944, lng: 18.3709308 },
    { lat: 48.7667035, lng: 18.3710052 },
    { lat: 48.7669732, lng: 18.3711691 },
    { lat: 48.7669512, lng: 18.3713808 },
    { lat: 48.766941, lng: 18.3716628 },
    { lat: 48.7669844, lng: 18.3716898 },
    { lat: 48.767179, lng: 18.3718634 },
    { lat: 48.7674156, lng: 18.3717569 },
    { lat: 48.767524, lng: 18.3717394 },
    { lat: 48.7676278, lng: 18.371959 },
    { lat: 48.7675787, lng: 18.3719903 },
    { lat: 48.7675227, lng: 18.3720572 },
    { lat: 48.767432, lng: 18.3720895 },
    { lat: 48.7673429, lng: 18.37219 },
    { lat: 48.7672655, lng: 18.3722403 },
    { lat: 48.7672368, lng: 18.3723102 },
    { lat: 48.7673098, lng: 18.3725286 },
    { lat: 48.7675513, lng: 18.3724258 },
    { lat: 48.767732, lng: 18.3724306 },
    { lat: 48.7679025, lng: 18.3725332 },
    { lat: 48.7681673, lng: 18.3726993 },
    { lat: 48.768155, lng: 18.3728721 },
    { lat: 48.7682214, lng: 18.3728711 },
    { lat: 48.7682036, lng: 18.3729546 },
    { lat: 48.7681753, lng: 18.3729503 },
    { lat: 48.7680763, lng: 18.3732613 },
    { lat: 48.7680566, lng: 18.3733739 },
    { lat: 48.7679491, lng: 18.3736284 },
    { lat: 48.7678522, lng: 18.3739337 },
    { lat: 48.7678564, lng: 18.3745737 },
    { lat: 48.767898, lng: 18.3745701 },
    { lat: 48.7681281, lng: 18.3743257 },
    { lat: 48.7682031, lng: 18.374319 },
    { lat: 48.76828, lng: 18.3746457 },
    { lat: 48.7683619, lng: 18.3748584 },
    { lat: 48.7685228, lng: 18.3751536 },
    { lat: 48.7686328, lng: 18.3754038 },
    { lat: 48.7687184, lng: 18.3757455 },
    { lat: 48.7688983, lng: 18.3766806 },
    { lat: 48.7689299, lng: 18.3771872 },
    { lat: 48.7689374, lng: 18.3778876 },
    { lat: 48.7688924, lng: 18.3784516 },
    { lat: 48.7689035, lng: 18.3786122 },
    { lat: 48.7689399, lng: 18.3787771 },
    { lat: 48.7690247, lng: 18.3790437 },
    { lat: 48.7691278, lng: 18.3793138 },
    { lat: 48.7692765, lng: 18.3795934 },
    { lat: 48.7694742, lng: 18.3799405 },
    { lat: 48.7697086, lng: 18.3802771 },
    { lat: 48.769856, lng: 18.3804749 },
    { lat: 48.7702381, lng: 18.3808903 },
    { lat: 48.7705153, lng: 18.3812314 },
    { lat: 48.7707802, lng: 18.3816099 },
    { lat: 48.7709142, lng: 18.381845 },
    { lat: 48.771023, lng: 18.3821172 },
    { lat: 48.7710876, lng: 18.3825084 },
    { lat: 48.7712089, lng: 18.3829013 },
    { lat: 48.7713314, lng: 18.3834182 },
    { lat: 48.7713884, lng: 18.3835504 },
    { lat: 48.7715485, lng: 18.3838107 },
    { lat: 48.7716661, lng: 18.3839563 },
    { lat: 48.7718361, lng: 18.3840118 },
    { lat: 48.7721346, lng: 18.3840641 },
    { lat: 48.772242, lng: 18.3840661 },
    { lat: 48.7725903, lng: 18.3840001 },
    { lat: 48.7726802, lng: 18.3838942 },
    { lat: 48.7729583, lng: 18.3837446 },
    { lat: 48.773255, lng: 18.3836419 },
    { lat: 48.7735122, lng: 18.3835622 },
    { lat: 48.7737954, lng: 18.3834806 },
    { lat: 48.7741344, lng: 18.3835317 },
    { lat: 48.774307, lng: 18.3836509 },
    { lat: 48.7746184, lng: 18.3838339 },
    { lat: 48.7748914, lng: 18.3840744 },
    { lat: 48.7752389, lng: 18.3843998 },
    { lat: 48.7754764, lng: 18.3842672 },
    { lat: 48.7755731, lng: 18.3838996 },
    { lat: 48.7762148, lng: 18.3836938 },
    { lat: 48.7769863, lng: 18.383509 },
    { lat: 48.7772872, lng: 18.3833006 },
    { lat: 48.7780469, lng: 18.3827006 },
    { lat: 48.778305, lng: 18.3824333 },
    { lat: 48.7786725, lng: 18.3819874 },
    { lat: 48.7788714, lng: 18.3818912 },
    { lat: 48.779442, lng: 18.3817037 },
    { lat: 48.779835, lng: 18.3815483 },
    { lat: 48.780234, lng: 18.3813817 },
    { lat: 48.7808332, lng: 18.3811609 },
    { lat: 48.7813589, lng: 18.3809184 },
    { lat: 48.7818195, lng: 18.3806409 },
    { lat: 48.7819905, lng: 18.3805107 },
    { lat: 48.7825688, lng: 18.3804435 },
    { lat: 48.7830203, lng: 18.3805077 },
    { lat: 48.7835006, lng: 18.3806824 },
    { lat: 48.7840167, lng: 18.3808353 },
    { lat: 48.784351, lng: 18.3808616 },
    { lat: 48.7849644, lng: 18.3808772 },
    { lat: 48.7851609, lng: 18.3810599 },
    { lat: 48.7852884, lng: 18.381425 },
    { lat: 48.7855558, lng: 18.3814845 },
    { lat: 48.785898, lng: 18.3812712 },
    { lat: 48.7862533, lng: 18.3812297 },
    { lat: 48.7863267, lng: 18.3814346 },
    { lat: 48.7863651, lng: 18.3817953 },
    { lat: 48.7864325, lng: 18.3819319 },
    { lat: 48.7865671, lng: 18.3821408 },
    { lat: 48.7867827, lng: 18.3821444 },
    { lat: 48.7871603, lng: 18.3820778 },
    { lat: 48.7875618, lng: 18.381995 },
    { lat: 48.7877886, lng: 18.3819749 },
    { lat: 48.787991, lng: 18.3819036 },
    { lat: 48.7880269, lng: 18.3817763 },
    { lat: 48.7882847, lng: 18.3817494 },
    { lat: 48.7885099, lng: 18.3818025 },
    { lat: 48.7887577, lng: 18.3817726 },
    { lat: 48.7891161, lng: 18.3817654 },
    { lat: 48.7892657, lng: 18.3818783 },
    { lat: 48.7895335, lng: 18.3821707 },
    { lat: 48.789838, lng: 18.3824115 },
    { lat: 48.7901055, lng: 18.3825952 },
    { lat: 48.7906739, lng: 18.3829576 },
    { lat: 48.7910811, lng: 18.3831578 },
    { lat: 48.7915177, lng: 18.3834783 },
    { lat: 48.7918636, lng: 18.3839958 },
    { lat: 48.7919813, lng: 18.3842452 },
    { lat: 48.7920845, lng: 18.3844986 },
    { lat: 48.7926798, lng: 18.385016 },
    { lat: 48.792957, lng: 18.3853141 },
    { lat: 48.7933278, lng: 18.3860567 },
    { lat: 48.7937053, lng: 18.3864929 },
    { lat: 48.7944277, lng: 18.3866196 },
    { lat: 48.7948583, lng: 18.3865534 },
    { lat: 48.7955158, lng: 18.3864479 },
    { lat: 48.7956461, lng: 18.3859325 },
    { lat: 48.795697, lng: 18.3854726 },
    { lat: 48.7959244, lng: 18.3843321 },
    { lat: 48.7960426, lng: 18.3839008 },
    { lat: 48.7960871, lng: 18.3834432 },
    { lat: 48.7961582, lng: 18.3823552 },
    { lat: 48.7956935, lng: 18.381445 },
    { lat: 48.7954819, lng: 18.3811318 },
    { lat: 48.7951884, lng: 18.3804018 },
    { lat: 48.7950137, lng: 18.3799411 },
    { lat: 48.7949961, lng: 18.3792227 },
    { lat: 48.7948425, lng: 18.3780476 },
    { lat: 48.7945457, lng: 18.3774411 },
    { lat: 48.7943211, lng: 18.3770555 },
    { lat: 48.794197, lng: 18.3762058 },
    { lat: 48.7943827, lng: 18.3754339 },
    { lat: 48.7944011, lng: 18.3741061 },
    { lat: 48.7942127, lng: 18.3732597 },
    { lat: 48.7941019, lng: 18.3731941 },
    { lat: 48.7939012, lng: 18.373033 },
    { lat: 48.7931647, lng: 18.3728576 },
    { lat: 48.7928838, lng: 18.3727991 },
    { lat: 48.7922751, lng: 18.3725411 },
    { lat: 48.7920089, lng: 18.3722617 },
    { lat: 48.7917021, lng: 18.3719003 },
    { lat: 48.7915154, lng: 18.3716888 },
    { lat: 48.7909717, lng: 18.3713484 },
    { lat: 48.790526, lng: 18.3708817 },
    { lat: 48.790013, lng: 18.3703624 },
    { lat: 48.7895953, lng: 18.3699537 },
    { lat: 48.7891607, lng: 18.3697486 },
    { lat: 48.7890961, lng: 18.3697436 },
    { lat: 48.7891884, lng: 18.3694162 },
    { lat: 48.7892079, lng: 18.3691601 },
    { lat: 48.7893036, lng: 18.3690369 },
    { lat: 48.7894358, lng: 18.3689988 },
    { lat: 48.7894898, lng: 18.3688359 },
    { lat: 48.7896735, lng: 18.368529 },
    { lat: 48.7899879, lng: 18.3678386 },
    { lat: 48.7899802, lng: 18.3677823 },
    { lat: 48.7902185, lng: 18.3673217 },
    { lat: 48.790252, lng: 18.3671124 },
  ],
  Dežerice: [
    { lat: 48.7983025, lng: 18.240162 },
    { lat: 48.7982935, lng: 18.239947 },
    { lat: 48.798196, lng: 18.2397582 },
    { lat: 48.7983618, lng: 18.2393031 },
    { lat: 48.798187, lng: 18.239186 },
    { lat: 48.7980574, lng: 18.2385366 },
    { lat: 48.7981508, lng: 18.2382753 },
    { lat: 48.7982019, lng: 18.2382006 },
    { lat: 48.7983482, lng: 18.2382366 },
    { lat: 48.7984254, lng: 18.2382053 },
    { lat: 48.7984791, lng: 18.238058 },
    { lat: 48.7985284, lng: 18.2376579 },
    { lat: 48.7988609, lng: 18.2375303 },
    { lat: 48.7988779, lng: 18.2375502 },
    { lat: 48.7990459, lng: 18.2376113 },
    { lat: 48.7991707, lng: 18.237722 },
    { lat: 48.7994003, lng: 18.2377686 },
    { lat: 48.7994245, lng: 18.2378978 },
    { lat: 48.7995315, lng: 18.2380699 },
    { lat: 48.7997068, lng: 18.2379604 },
    { lat: 48.7999489, lng: 18.2380093 },
    { lat: 48.8001322, lng: 18.2379602 },
    { lat: 48.8002651, lng: 18.237994 },
    { lat: 48.8004797, lng: 18.2378604 },
    { lat: 48.8008902, lng: 18.2378557 },
    { lat: 48.8009935, lng: 18.2379259 },
    { lat: 48.8014736, lng: 18.2378781 },
    { lat: 48.8015882, lng: 18.2379483 },
    { lat: 48.8017908, lng: 18.2381953 },
    { lat: 48.8021889, lng: 18.2381156 },
    { lat: 48.8023755, lng: 18.2381206 },
    { lat: 48.8025651, lng: 18.2382404 },
    { lat: 48.8027621, lng: 18.2380958 },
    { lat: 48.8030256, lng: 18.2381851 },
    { lat: 48.803089, lng: 18.238542 },
    { lat: 48.8031323, lng: 18.2386086 },
    { lat: 48.8031903, lng: 18.2386386 },
    { lat: 48.803275, lng: 18.2385978 },
    { lat: 48.8032719, lng: 18.2381497 },
    { lat: 48.8034517, lng: 18.2381362 },
    { lat: 48.8035491, lng: 18.238102 },
    { lat: 48.8035304, lng: 18.2380307 },
    { lat: 48.8032449, lng: 18.236472 },
    { lat: 48.802921, lng: 18.2351267 },
    { lat: 48.8027224, lng: 18.2339811 },
    { lat: 48.802576, lng: 18.2330153 },
    { lat: 48.8024557, lng: 18.2323341 },
    { lat: 48.8023561, lng: 18.2316789 },
    { lat: 48.8022718, lng: 18.2310524 },
    { lat: 48.8022017, lng: 18.2304381 },
    { lat: 48.8021483, lng: 18.2298387 },
    { lat: 48.8021059, lng: 18.2292337 },
    { lat: 48.8020568, lng: 18.2284161 },
    { lat: 48.802039, lng: 18.228005 },
    { lat: 48.8020679, lng: 18.2276014 },
    { lat: 48.8021149, lng: 18.2271822 },
    { lat: 48.8021702, lng: 18.2267694 },
    { lat: 48.8021722, lng: 18.2264066 },
    { lat: 48.802122, lng: 18.2260824 },
    { lat: 48.8020863, lng: 18.2257528 },
    { lat: 48.8020544, lng: 18.225413 },
    { lat: 48.8020175, lng: 18.2250656 },
    { lat: 48.8019735, lng: 18.2246898 },
    { lat: 48.8019183, lng: 18.2243366 },
    { lat: 48.801851, lng: 18.2238096 },
    { lat: 48.8018269, lng: 18.2232934 },
    { lat: 48.8017617, lng: 18.2225249 },
    { lat: 48.8016353, lng: 18.2218528 },
    { lat: 48.8014172, lng: 18.2211201 },
    { lat: 48.8013082, lng: 18.2205388 },
    { lat: 48.80121, lng: 18.2199632 },
    { lat: 48.8011427, lng: 18.2196173 },
    { lat: 48.801149, lng: 18.219549 },
    { lat: 48.800082, lng: 18.219742 },
    { lat: 48.799613, lng: 18.22004 },
    { lat: 48.799165, lng: 18.220411 },
    { lat: 48.798841, lng: 18.220737 },
    { lat: 48.798501, lng: 18.221057 },
    { lat: 48.798301, lng: 18.221059 },
    { lat: 48.798092, lng: 18.221182 },
    { lat: 48.797804, lng: 18.221598 },
    { lat: 48.797119, lng: 18.221871 },
    { lat: 48.796617, lng: 18.222087 },
    { lat: 48.796289, lng: 18.222206 },
    { lat: 48.79583, lng: 18.222553 },
    { lat: 48.794684, lng: 18.222957 },
    { lat: 48.793697, lng: 18.223348 },
    { lat: 48.792962, lng: 18.223635 },
    { lat: 48.792665, lng: 18.223521 },
    { lat: 48.792324, lng: 18.223463 },
    { lat: 48.791963, lng: 18.223116 },
    { lat: 48.790982, lng: 18.222837 },
    { lat: 48.790064, lng: 18.222455 },
    { lat: 48.789263, lng: 18.221994 },
    { lat: 48.788604, lng: 18.221551 },
    { lat: 48.787519, lng: 18.220878 },
    { lat: 48.786962, lng: 18.220566 },
    { lat: 48.786358, lng: 18.220483 },
    { lat: 48.785846, lng: 18.220533 },
    { lat: 48.78482, lng: 18.220829 },
    { lat: 48.783986, lng: 18.221068 },
    { lat: 48.783632, lng: 18.221192 },
    { lat: 48.783504, lng: 18.221433 },
    { lat: 48.783387, lng: 18.221655 },
    { lat: 48.78313, lng: 18.221898 },
    { lat: 48.782829, lng: 18.222043 },
    { lat: 48.781639, lng: 18.222746 },
    { lat: 48.781329, lng: 18.22291 },
    { lat: 48.78081, lng: 18.223073 },
    { lat: 48.779682, lng: 18.223122 },
    { lat: 48.77961, lng: 18.223125 },
    { lat: 48.778525, lng: 18.22318 },
    { lat: 48.778472, lng: 18.222189 },
    { lat: 48.778406, lng: 18.221152 },
    { lat: 48.778351, lng: 18.220523 },
    { lat: 48.778288, lng: 18.219991 },
    { lat: 48.778286, lng: 18.219984 },
    { lat: 48.778222, lng: 18.219484 },
    { lat: 48.778152, lng: 18.218998 },
    { lat: 48.778113, lng: 18.218755 },
    { lat: 48.778072, lng: 18.218519 },
    { lat: 48.778, lng: 18.218143 },
    { lat: 48.77799, lng: 18.218102 },
    { lat: 48.777917, lng: 18.218165 },
    { lat: 48.777826, lng: 18.218242 },
    { lat: 48.777677, lng: 18.218368 },
    { lat: 48.777358, lng: 18.218607 },
    { lat: 48.777305, lng: 18.218646 },
    { lat: 48.777253, lng: 18.218686 },
    { lat: 48.777234, lng: 18.218617 },
    { lat: 48.777107, lng: 18.218149 },
    { lat: 48.777098, lng: 18.218115 },
    { lat: 48.777095, lng: 18.21811 },
    { lat: 48.776859, lng: 18.217393 },
    { lat: 48.776767, lng: 18.216975 },
    { lat: 48.776763, lng: 18.216954 },
    { lat: 48.776737, lng: 18.216875 },
    { lat: 48.77668, lng: 18.216703 },
    { lat: 48.776647, lng: 18.216603 },
    { lat: 48.776669, lng: 18.216267 },
    { lat: 48.776704, lng: 18.215811 },
    { lat: 48.776715, lng: 18.215673 },
    { lat: 48.776726, lng: 18.21553 },
    { lat: 48.776732, lng: 18.215438 },
    { lat: 48.77674, lng: 18.21534 },
    { lat: 48.776742, lng: 18.214624 },
    { lat: 48.776716, lng: 18.214115 },
    { lat: 48.776664, lng: 18.21369 },
    { lat: 48.776663, lng: 18.213682 },
    { lat: 48.77661, lng: 18.212968 },
    { lat: 48.776605, lng: 18.212901 },
    { lat: 48.77629, lng: 18.212748 },
    { lat: 48.775571, lng: 18.212319 },
    { lat: 48.775485, lng: 18.211932 },
    { lat: 48.775033, lng: 18.211838 },
    { lat: 48.774933, lng: 18.211414 },
    { lat: 48.774905, lng: 18.211295 },
    { lat: 48.774866, lng: 18.211134 },
    { lat: 48.774507, lng: 18.211162 },
    { lat: 48.774098, lng: 18.211051 },
    { lat: 48.773847, lng: 18.21103 },
    { lat: 48.773688, lng: 18.210605 },
    { lat: 48.773369, lng: 18.209842 },
    { lat: 48.773129, lng: 18.209393 },
    { lat: 48.772695, lng: 18.208746 },
    { lat: 48.772469, lng: 18.20841 },
    { lat: 48.771775, lng: 18.20748 },
    { lat: 48.771112, lng: 18.206633 },
    { lat: 48.770215, lng: 18.20553 },
    { lat: 48.770193, lng: 18.205503 },
    { lat: 48.770154, lng: 18.205454 },
    { lat: 48.770131, lng: 18.205427 },
    { lat: 48.769934, lng: 18.205132 },
    { lat: 48.76993, lng: 18.205127 },
    { lat: 48.769929, lng: 18.205123 },
    { lat: 48.769331, lng: 18.204052 },
    { lat: 48.768399, lng: 18.202325 },
    { lat: 48.768324, lng: 18.20209 },
    { lat: 48.768145, lng: 18.201289 },
    { lat: 48.76807, lng: 18.200861 },
    { lat: 48.768055, lng: 18.200607 },
    { lat: 48.76807, lng: 18.20061 },
    { lat: 48.76818, lng: 18.200316 },
    { lat: 48.768265, lng: 18.200056 },
    { lat: 48.768273, lng: 18.20003 },
    { lat: 48.768446, lng: 18.199626 },
    { lat: 48.768698, lng: 18.199333 },
    { lat: 48.770585, lng: 18.197149 },
    { lat: 48.77085, lng: 18.196042 },
    { lat: 48.770861, lng: 18.195994 },
    { lat: 48.770395, lng: 18.194589 },
    { lat: 48.7703825, lng: 18.1945538 },
    { lat: 48.77109, lng: 18.193671 },
    { lat: 48.771288, lng: 18.193462 },
    { lat: 48.771503, lng: 18.193313 },
    { lat: 48.771505, lng: 18.193312 },
    { lat: 48.77152, lng: 18.193298 },
    { lat: 48.771538, lng: 18.193282 },
    { lat: 48.771959, lng: 18.192894 },
    { lat: 48.772054, lng: 18.192807 },
    { lat: 48.772245, lng: 18.192622 },
    { lat: 48.772378, lng: 18.192492 },
    { lat: 48.772722, lng: 18.19202 },
    { lat: 48.772466, lng: 18.190844 },
    { lat: 48.772386, lng: 18.19058 },
    { lat: 48.772373, lng: 18.190539 },
    { lat: 48.772267, lng: 18.190189 },
    { lat: 48.77199, lng: 18.189538 },
    { lat: 48.771663, lng: 18.188735 },
    { lat: 48.771482, lng: 18.187935 },
    { lat: 48.771828, lng: 18.186935 },
    { lat: 48.771974, lng: 18.186668 },
    { lat: 48.772366, lng: 18.186598 },
    { lat: 48.772745, lng: 18.186421 },
    { lat: 48.772721, lng: 18.186246 },
    { lat: 48.77317, lng: 18.185568 },
    { lat: 48.773308, lng: 18.185485 },
    { lat: 48.773328, lng: 18.185449 },
    { lat: 48.773464, lng: 18.185224 },
    { lat: 48.773518, lng: 18.184957 },
    { lat: 48.773634, lng: 18.184759 },
    { lat: 48.77384, lng: 18.184406 },
    { lat: 48.774334, lng: 18.184118 },
    { lat: 48.774335, lng: 18.184117 },
    { lat: 48.774684, lng: 18.183911 },
    { lat: 48.775074, lng: 18.183518 },
    { lat: 48.775479, lng: 18.18304 },
    { lat: 48.775818, lng: 18.18281 },
    { lat: 48.77611, lng: 18.182583 },
    { lat: 48.776447, lng: 18.182132 },
    { lat: 48.776687, lng: 18.18191 },
    { lat: 48.776724, lng: 18.181788 },
    { lat: 48.777713, lng: 18.180661 },
    { lat: 48.778346, lng: 18.179922 },
    { lat: 48.778617, lng: 18.179529 },
    { lat: 48.779045, lng: 18.179027 },
    { lat: 48.779216, lng: 18.178735 },
    { lat: 48.779361, lng: 18.178464 },
    { lat: 48.779422, lng: 18.178367 },
    { lat: 48.77946, lng: 18.178307 },
    { lat: 48.779461, lng: 18.178305 },
    { lat: 48.779494, lng: 18.178252 },
    { lat: 48.779515, lng: 18.17822 },
    { lat: 48.779566, lng: 18.178141 },
    { lat: 48.779579, lng: 18.178126 },
    { lat: 48.779745, lng: 18.177936 },
    { lat: 48.779747, lng: 18.177934 },
    { lat: 48.779749, lng: 18.17793 },
    { lat: 48.779771, lng: 18.177901 },
    { lat: 48.779633, lng: 18.177788 },
    { lat: 48.779569, lng: 18.177789 },
    { lat: 48.779533, lng: 18.177824 },
    { lat: 48.77944, lng: 18.177821 },
    { lat: 48.779391, lng: 18.177818 },
    { lat: 48.779307, lng: 18.177759 },
    { lat: 48.779205, lng: 18.177689 },
    { lat: 48.779172, lng: 18.177666 },
    { lat: 48.77909, lng: 18.177653 },
    { lat: 48.778819, lng: 18.17774 },
    { lat: 48.778464, lng: 18.177744 },
    { lat: 48.778423, lng: 18.177757 },
    { lat: 48.778152, lng: 18.177901 },
    { lat: 48.778009, lng: 18.177869 },
    { lat: 48.777755, lng: 18.177809 },
    { lat: 48.777584, lng: 18.177768 },
    { lat: 48.777536, lng: 18.177756 },
    { lat: 48.777414, lng: 18.177783 },
    { lat: 48.777351, lng: 18.177836 },
    { lat: 48.777293, lng: 18.177983 },
    { lat: 48.777126, lng: 18.178396 },
    { lat: 48.776527, lng: 18.17883 },
    { lat: 48.776465, lng: 18.178749 },
    { lat: 48.776306, lng: 18.178659 },
    { lat: 48.776196, lng: 18.178622 },
    { lat: 48.775993, lng: 18.178554 },
    { lat: 48.77571, lng: 18.178595 },
    { lat: 48.775597, lng: 18.178522 },
    { lat: 48.77546, lng: 18.178455 },
    { lat: 48.77541, lng: 18.178489 },
    { lat: 48.775156, lng: 18.17851 },
    { lat: 48.774975, lng: 18.178639 },
    { lat: 48.774805, lng: 18.17883 },
    { lat: 48.774653, lng: 18.17886 },
    { lat: 48.77456, lng: 18.178794 },
    { lat: 48.774464, lng: 18.178555 },
    { lat: 48.774211, lng: 18.17836 },
    { lat: 48.774062, lng: 18.178344 },
    { lat: 48.773867, lng: 18.178031 },
    { lat: 48.773523, lng: 18.178163 },
    { lat: 48.773416, lng: 18.178149 },
    { lat: 48.773351, lng: 18.178048 },
    { lat: 48.773268, lng: 18.17784 },
    { lat: 48.773134, lng: 18.177692 },
    { lat: 48.772968, lng: 18.177678 },
    { lat: 48.772705, lng: 18.17721 },
    { lat: 48.772718, lng: 18.17706 },
    { lat: 48.772723, lng: 18.177009 },
    { lat: 48.772616, lng: 18.176877 },
    { lat: 48.772514, lng: 18.176566 },
    { lat: 48.772342, lng: 18.176306 },
    { lat: 48.772318, lng: 18.176237 },
    { lat: 48.77217, lng: 18.176243 },
    { lat: 48.771902, lng: 18.176119 },
    { lat: 48.771826, lng: 18.176065 },
    { lat: 48.771677, lng: 18.176133 },
    { lat: 48.771548, lng: 18.176163 },
    { lat: 48.771463, lng: 18.176109 },
    { lat: 48.771394, lng: 18.175936 },
    { lat: 48.771252, lng: 18.175721 },
    { lat: 48.771116, lng: 18.175682 },
    { lat: 48.7709067, lng: 18.1756023 },
    { lat: 48.7708378, lng: 18.1756014 },
    { lat: 48.7705574, lng: 18.1756299 },
    { lat: 48.7704952, lng: 18.1752775 },
    { lat: 48.7704593, lng: 18.1751069 },
    { lat: 48.7697411, lng: 18.1754654 },
    { lat: 48.7679535, lng: 18.1762862 },
    { lat: 48.7670361, lng: 18.1766977 },
    { lat: 48.7666158, lng: 18.1769227 },
    { lat: 48.7660786, lng: 18.1772962 },
    { lat: 48.7650504, lng: 18.1777985 },
    { lat: 48.7650336, lng: 18.177721 },
    { lat: 48.7649276, lng: 18.1777443 },
    { lat: 48.7647743, lng: 18.1778453 },
    { lat: 48.7647913, lng: 18.1779229 },
    { lat: 48.764482, lng: 18.1780788 },
    { lat: 48.7644077, lng: 18.1780411 },
    { lat: 48.7642909, lng: 18.1780332 },
    { lat: 48.7641432, lng: 18.1781543 },
    { lat: 48.7638119, lng: 18.1783372 },
    { lat: 48.7633835, lng: 18.1786183 },
    { lat: 48.7628804, lng: 18.1789726 },
    { lat: 48.7627591, lng: 18.1790219 },
    { lat: 48.762108, lng: 18.1795108 },
    { lat: 48.7618365, lng: 18.1796379 },
    { lat: 48.7609887, lng: 18.1801199 },
    { lat: 48.7609065, lng: 18.1801432 },
    { lat: 48.760652, lng: 18.1803105 },
    { lat: 48.7603477, lng: 18.1805474 },
    { lat: 48.7600581, lng: 18.1808889 },
    { lat: 48.7594846, lng: 18.1812567 },
    { lat: 48.758826, lng: 18.1816168 },
    { lat: 48.7586283, lng: 18.1817484 },
    { lat: 48.758202, lng: 18.1819866 },
    { lat: 48.7578823, lng: 18.1820606 },
    { lat: 48.7576429, lng: 18.1821737 },
    { lat: 48.7574151, lng: 18.1823732 },
    { lat: 48.7572076, lng: 18.1825161 },
    { lat: 48.757187, lng: 18.1825839 },
    { lat: 48.7567221, lng: 18.1829781 },
    { lat: 48.756007, lng: 18.183552 },
    { lat: 48.7559787, lng: 18.1835769 },
    { lat: 48.7554754, lng: 18.1840197 },
    { lat: 48.7554149, lng: 18.1839701 },
    { lat: 48.7553066, lng: 18.1840688 },
    { lat: 48.755229, lng: 18.1842285 },
    { lat: 48.7551139, lng: 18.1843277 },
    { lat: 48.7548232, lng: 18.1846096 },
    { lat: 48.7544066, lng: 18.185095 },
    { lat: 48.7539431, lng: 18.1856274 },
    { lat: 48.7535804, lng: 18.186031 },
    { lat: 48.7531782, lng: 18.1864953 },
    { lat: 48.7529012, lng: 18.186854 },
    { lat: 48.7527483, lng: 18.1872786 },
    { lat: 48.752186, lng: 18.1883864 },
    { lat: 48.7514039, lng: 18.1898955 },
    { lat: 48.7509209, lng: 18.1908541 },
    { lat: 48.7501921, lng: 18.1922295 },
    { lat: 48.7494728, lng: 18.1936726 },
    { lat: 48.7495767, lng: 18.1937934 },
    { lat: 48.7494522, lng: 18.1940218 },
    { lat: 48.7494502, lng: 18.194045 },
    { lat: 48.7495212, lng: 18.194218 },
    { lat: 48.7496465, lng: 18.1944565 },
    { lat: 48.7498109, lng: 18.1943708 },
    { lat: 48.7499372, lng: 18.194371 },
    { lat: 48.7500132, lng: 18.1947676 },
    { lat: 48.7500278, lng: 18.1952766 },
    { lat: 48.7500385, lng: 18.1964245 },
    { lat: 48.7493514, lng: 18.1967012 },
    { lat: 48.7492678, lng: 18.1974055 },
    { lat: 48.749638, lng: 18.1975263 },
    { lat: 48.7496375, lng: 18.1981847 },
    { lat: 48.7495236, lng: 18.1987332 },
    { lat: 48.7495219, lng: 18.1988632 },
    { lat: 48.7493792, lng: 18.1991855 },
    { lat: 48.7492969, lng: 18.1993469 },
    { lat: 48.7491764, lng: 18.1995458 },
    { lat: 48.7491129, lng: 18.1996385 },
    { lat: 48.7488014, lng: 18.1995172 },
    { lat: 48.7486363, lng: 18.1997497 },
    { lat: 48.7488413, lng: 18.2001539 },
    { lat: 48.7490432, lng: 18.2003302 },
    { lat: 48.7490586, lng: 18.2003431 },
    { lat: 48.7493653, lng: 18.2004562 },
    { lat: 48.7493803, lng: 18.2004621 },
    { lat: 48.749509, lng: 18.2005028 },
    { lat: 48.7498711, lng: 18.2006175 },
    { lat: 48.7501269, lng: 18.2005528 },
    { lat: 48.7504008, lng: 18.2006048 },
    { lat: 48.7508646, lng: 18.2007836 },
    { lat: 48.7512305, lng: 18.2008472 },
    { lat: 48.7514904, lng: 18.2011288 },
    { lat: 48.7515337, lng: 18.201176 },
    { lat: 48.7515939, lng: 18.2012414 },
    { lat: 48.7511902, lng: 18.2016371 },
    { lat: 48.7510744, lng: 18.2017445 },
    { lat: 48.750944, lng: 18.2018504 },
    { lat: 48.750786, lng: 18.2019491 },
    { lat: 48.7506292, lng: 18.2020287 },
    { lat: 48.7504107, lng: 18.2021128 },
    { lat: 48.7502417, lng: 18.2021776 },
    { lat: 48.7499414, lng: 18.2023111 },
    { lat: 48.749807, lng: 18.2024092 },
    { lat: 48.7496889, lng: 18.2025318 },
    { lat: 48.7496151, lng: 18.2026225 },
    { lat: 48.7495397, lng: 18.202784 },
    { lat: 48.7494572, lng: 18.202959 },
    { lat: 48.7493411, lng: 18.2032949 },
    { lat: 48.7492839, lng: 18.2037909 },
    { lat: 48.7495098, lng: 18.2041474 },
    { lat: 48.7495284, lng: 18.204177 },
    { lat: 48.7494454, lng: 18.2044046 },
    { lat: 48.7494415, lng: 18.2044145 },
    { lat: 48.7494017, lng: 18.2045263 },
    { lat: 48.7493944, lng: 18.2045547 },
    { lat: 48.7493303, lng: 18.2048157 },
    { lat: 48.7493192, lng: 18.2048576 },
    { lat: 48.7492962, lng: 18.2052049 },
    { lat: 48.7492447, lng: 18.2056826 },
    { lat: 48.7492486, lng: 18.2061628 },
    { lat: 48.7493494, lng: 18.2065429 },
    { lat: 48.7492906, lng: 18.2066595 },
    { lat: 48.7492392, lng: 18.2067643 },
    { lat: 48.7487615, lng: 18.2069546 },
    { lat: 48.7485649, lng: 18.2071288 },
    { lat: 48.7486728, lng: 18.207395 },
    { lat: 48.748722, lng: 18.2075646 },
    { lat: 48.7486992, lng: 18.207915 },
    { lat: 48.748733, lng: 18.2081677 },
    { lat: 48.7487901, lng: 18.2085932 },
    { lat: 48.7488365, lng: 18.208753 },
    { lat: 48.7488869, lng: 18.208824 },
    { lat: 48.7490212, lng: 18.2089229 },
    { lat: 48.7492736, lng: 18.2091133 },
    { lat: 48.7496527, lng: 18.2094854 },
    { lat: 48.749946, lng: 18.2090536 },
    { lat: 48.750183, lng: 18.2087182 },
    { lat: 48.7502634, lng: 18.2088217 },
    { lat: 48.7504205, lng: 18.2090233 },
    { lat: 48.7505975, lng: 18.2092373 },
    { lat: 48.7507281, lng: 18.2093895 },
    { lat: 48.751018, lng: 18.209741 },
    { lat: 48.7509692, lng: 18.2098343 },
    { lat: 48.7508745, lng: 18.2100136 },
    { lat: 48.7508257, lng: 18.210107 },
    { lat: 48.7506103, lng: 18.2104432 },
    { lat: 48.7505161, lng: 18.2105893 },
    { lat: 48.7504224, lng: 18.2107078 },
    { lat: 48.7502382, lng: 18.2109392 },
    { lat: 48.7501144, lng: 18.2110834 },
    { lat: 48.74994, lng: 18.2112885 },
    { lat: 48.7498498, lng: 18.2114258 },
    { lat: 48.7497901, lng: 18.2115274 },
    { lat: 48.7497274, lng: 18.2116337 },
    { lat: 48.7496657, lng: 18.2117378 },
    { lat: 48.750009, lng: 18.2121957 },
    { lat: 48.7503938, lng: 18.2127275 },
    { lat: 48.7504184, lng: 18.2127622 },
    { lat: 48.750438, lng: 18.2127897 },
    { lat: 48.7504909, lng: 18.2128659 },
    { lat: 48.750513, lng: 18.2128975 },
    { lat: 48.7496655, lng: 18.2142914 },
    { lat: 48.7492347, lng: 18.215006 },
    { lat: 48.7488157, lng: 18.2160389 },
    { lat: 48.7487447, lng: 18.2161995 },
    { lat: 48.748673, lng: 18.2163648 },
    { lat: 48.7483035, lng: 18.2172049 },
    { lat: 48.749462, lng: 18.218674 },
    { lat: 48.7501506, lng: 18.2195433 },
    { lat: 48.7501719, lng: 18.2195802 },
    { lat: 48.7502157, lng: 18.2196563 },
    { lat: 48.7502332, lng: 18.2196873 },
    { lat: 48.7498217, lng: 18.2201857 },
    { lat: 48.7493699, lng: 18.2207469 },
    { lat: 48.7497353, lng: 18.2212379 },
    { lat: 48.7497487, lng: 18.2212554 },
    { lat: 48.7498697, lng: 18.2214173 },
    { lat: 48.7498772, lng: 18.2214279 },
    { lat: 48.749882, lng: 18.2214341 },
    { lat: 48.7499884, lng: 18.221578 },
    { lat: 48.7499622, lng: 18.2216916 },
    { lat: 48.7507312, lng: 18.2224115 },
    { lat: 48.7505871, lng: 18.2225533 },
    { lat: 48.7502982, lng: 18.2228111 },
    { lat: 48.7500886, lng: 18.2230703 },
    { lat: 48.7499997, lng: 18.2232314 },
    { lat: 48.7499664, lng: 18.2234592 },
    { lat: 48.7499608, lng: 18.2234982 },
    { lat: 48.7499856, lng: 18.2236759 },
    { lat: 48.7499749, lng: 18.2238428 },
    { lat: 48.7499443, lng: 18.223921 },
    { lat: 48.7499111, lng: 18.2239694 },
    { lat: 48.749834, lng: 18.2240826 },
    { lat: 48.7497538, lng: 18.2241405 },
    { lat: 48.7496314, lng: 18.2242491 },
    { lat: 48.749436, lng: 18.2244228 },
    { lat: 48.7492416, lng: 18.2245962 },
    { lat: 48.7491854, lng: 18.2247166 },
    { lat: 48.7490839, lng: 18.2249351 },
    { lat: 48.749068, lng: 18.2249735 },
    { lat: 48.7490531, lng: 18.2250087 },
    { lat: 48.7490364, lng: 18.22505 },
    { lat: 48.7490035, lng: 18.2251275 },
    { lat: 48.7488904, lng: 18.2253986 },
    { lat: 48.7488413, lng: 18.225605 },
    { lat: 48.7488156, lng: 18.225805 },
    { lat: 48.7487847, lng: 18.2260364 },
    { lat: 48.7485051, lng: 18.2259407 },
    { lat: 48.7483551, lng: 18.2263354 },
    { lat: 48.7482629, lng: 18.2266575 },
    { lat: 48.7482275, lng: 18.2269253 },
    { lat: 48.7481398, lng: 18.2275418 },
    { lat: 48.7480376, lng: 18.2282617 },
    { lat: 48.7480021, lng: 18.2285073 },
    { lat: 48.7479525, lng: 18.2288638 },
    { lat: 48.7478871, lng: 18.2293379 },
    { lat: 48.7478386, lng: 18.2296918 },
    { lat: 48.7478136, lng: 18.2298667 },
    { lat: 48.7477649, lng: 18.2302181 },
    { lat: 48.7477179, lng: 18.23056 },
    { lat: 48.7476948, lng: 18.2307286 },
    { lat: 48.7476753, lng: 18.230862 },
    { lat: 48.7476588, lng: 18.2309866 },
    { lat: 48.747533, lng: 18.2318853 },
    { lat: 48.7475291, lng: 18.2319155 },
    { lat: 48.747579, lng: 18.2320173 },
    { lat: 48.7476582, lng: 18.2319038 },
    { lat: 48.7477255, lng: 18.2319171 },
    { lat: 48.7478546, lng: 18.2320234 },
    { lat: 48.7479835, lng: 18.2319483 },
    { lat: 48.7480491, lng: 18.2318346 },
    { lat: 48.7481263, lng: 18.2317819 },
    { lat: 48.748235, lng: 18.2319077 },
    { lat: 48.7482337, lng: 18.2319445 },
    { lat: 48.7482245, lng: 18.2321971 },
    { lat: 48.7482697, lng: 18.232218 },
    { lat: 48.7483102, lng: 18.2322366 },
    { lat: 48.7484017, lng: 18.2321982 },
    { lat: 48.7484084, lng: 18.232178 },
    { lat: 48.7484789, lng: 18.2319688 },
    { lat: 48.7486443, lng: 18.2318822 },
    { lat: 48.7486536, lng: 18.2318678 },
    { lat: 48.7486693, lng: 18.2318471 },
    { lat: 48.7487675, lng: 18.2317092 },
    { lat: 48.7488568, lng: 18.2315525 },
    { lat: 48.7490432, lng: 18.2317158 },
    { lat: 48.7490685, lng: 18.2317817 },
    { lat: 48.7491608, lng: 18.2320149 },
    { lat: 48.7492868, lng: 18.2319898 },
    { lat: 48.749452, lng: 18.2318606 },
    { lat: 48.7495579, lng: 18.2317769 },
    { lat: 48.7496207, lng: 18.2319099 },
    { lat: 48.749658, lng: 18.231989 },
    { lat: 48.7497042, lng: 18.2321451 },
    { lat: 48.7498525, lng: 18.2322332 },
    { lat: 48.7499016, lng: 18.2322622 },
    { lat: 48.7499573, lng: 18.2322293 },
    { lat: 48.7502082, lng: 18.2320799 },
    { lat: 48.7505607, lng: 18.2320871 },
    { lat: 48.7507578, lng: 18.2319625 },
    { lat: 48.7507671, lng: 18.231948 },
    { lat: 48.7508713, lng: 18.2317949 },
    { lat: 48.7509276, lng: 18.2319322 },
    { lat: 48.7509908, lng: 18.2320702 },
    { lat: 48.7510941, lng: 18.2322998 },
    { lat: 48.751274, lng: 18.2323855 },
    { lat: 48.7515133, lng: 18.2324266 },
    { lat: 48.7516019, lng: 18.2326335 },
    { lat: 48.7516279, lng: 18.2326941 },
    { lat: 48.7519336, lng: 18.2329502 },
    { lat: 48.7520298, lng: 18.2329147 },
    { lat: 48.752074, lng: 18.2328979 },
    { lat: 48.7523335, lng: 18.2328006 },
    { lat: 48.7526905, lng: 18.233339 },
    { lat: 48.7528221, lng: 18.2334111 },
    { lat: 48.7528465, lng: 18.2337187 },
    { lat: 48.753045, lng: 18.2338953 },
    { lat: 48.7530439, lng: 18.2339743 },
    { lat: 48.7530431, lng: 18.2343951 },
    { lat: 48.7530732, lng: 18.2343865 },
    { lat: 48.7531406, lng: 18.2345028 },
    { lat: 48.7531748, lng: 18.2345062 },
    { lat: 48.7534121, lng: 18.2341776 },
    { lat: 48.7535283, lng: 18.2340802 },
    { lat: 48.7537486, lng: 18.2340625 },
    { lat: 48.7538626, lng: 18.2339474 },
    { lat: 48.7538885, lng: 18.2339441 },
    { lat: 48.7545481, lng: 18.2334226 },
    { lat: 48.7546146, lng: 18.2331665 },
    { lat: 48.7546161, lng: 18.2330937 },
    { lat: 48.7544933, lng: 18.2328819 },
    { lat: 48.7544861, lng: 18.2327924 },
    { lat: 48.7545802, lng: 18.232665 },
    { lat: 48.7547111, lng: 18.2327036 },
    { lat: 48.7548056, lng: 18.2328139 },
    { lat: 48.7550435, lng: 18.2329509 },
    { lat: 48.7551267, lng: 18.2328678 },
    { lat: 48.7552066, lng: 18.2327067 },
    { lat: 48.7552407, lng: 18.2322946 },
    { lat: 48.7552973, lng: 18.232279 },
    { lat: 48.7555524, lng: 18.2323772 },
    { lat: 48.755706, lng: 18.2322642 },
    { lat: 48.7558425, lng: 18.2321261 },
    { lat: 48.7559309, lng: 18.2319933 },
    { lat: 48.7562238, lng: 18.2322229 },
    { lat: 48.7566883, lng: 18.2322122 },
    { lat: 48.7567571, lng: 18.2318774 },
    { lat: 48.7568652, lng: 18.2318529 },
    { lat: 48.7570611, lng: 18.2317686 },
    { lat: 48.757105, lng: 18.2316688 },
    { lat: 48.757136, lng: 18.2316562 },
    { lat: 48.7572125, lng: 18.2316916 },
    { lat: 48.7572934, lng: 18.2318033 },
    { lat: 48.7573934, lng: 18.2319765 },
    { lat: 48.7574331, lng: 18.2319982 },
    { lat: 48.7575441, lng: 18.2320079 },
    { lat: 48.7576851, lng: 18.2320453 },
    { lat: 48.7579014, lng: 18.2320377 },
    { lat: 48.7579502, lng: 18.2319262 },
    { lat: 48.7579316, lng: 18.2317788 },
    { lat: 48.757951, lng: 18.2317219 },
    { lat: 48.7579902, lng: 18.2317161 },
    { lat: 48.7582139, lng: 18.2318785 },
    { lat: 48.7583428, lng: 18.2318838 },
    { lat: 48.7584097, lng: 18.2316551 },
    { lat: 48.7585975, lng: 18.2314671 },
    { lat: 48.7587872, lng: 18.231409 },
    { lat: 48.759045, lng: 18.2314357 },
    { lat: 48.7591819, lng: 18.2313863 },
    { lat: 48.7594416, lng: 18.2314654 },
    { lat: 48.7595723, lng: 18.2315799 },
    { lat: 48.7597794, lng: 18.2314941 },
    { lat: 48.7599873, lng: 18.2312799 },
    { lat: 48.7600796, lng: 18.2311398 },
    { lat: 48.760209, lng: 18.2311704 },
    { lat: 48.7602874, lng: 18.231222 },
    { lat: 48.7604097, lng: 18.231211 },
    { lat: 48.7604597, lng: 18.2311344 },
    { lat: 48.7604578, lng: 18.2309068 },
    { lat: 48.7606163, lng: 18.23064 },
    { lat: 48.7606875, lng: 18.2306411 },
    { lat: 48.760722, lng: 18.2306727 },
    { lat: 48.7610119, lng: 18.2311823 },
    { lat: 48.7613542, lng: 18.2311711 },
    { lat: 48.761441, lng: 18.2311894 },
    { lat: 48.7617152, lng: 18.2309311 },
    { lat: 48.7619386, lng: 18.2308929 },
    { lat: 48.7621309, lng: 18.2306832 },
    { lat: 48.7621765, lng: 18.2306908 },
    { lat: 48.7622573, lng: 18.2308222 },
    { lat: 48.7625577, lng: 18.2308656 },
    { lat: 48.7626833, lng: 18.2310118 },
    { lat: 48.7627881, lng: 18.2309509 },
    { lat: 48.7630873, lng: 18.230993 },
    { lat: 48.7631621, lng: 18.2308998 },
    { lat: 48.7632934, lng: 18.2308261 },
    { lat: 48.7634917, lng: 18.23094 },
    { lat: 48.763562, lng: 18.2310245 },
    { lat: 48.7638135, lng: 18.2310807 },
    { lat: 48.7639382, lng: 18.2307975 },
    { lat: 48.7640419, lng: 18.2306971 },
    { lat: 48.7642308, lng: 18.2307832 },
    { lat: 48.764421, lng: 18.2310488 },
    { lat: 48.7645152, lng: 18.2310797 },
    { lat: 48.7646249, lng: 18.2312238 },
    { lat: 48.7647487, lng: 18.2312977 },
    { lat: 48.7652043, lng: 18.2312921 },
    { lat: 48.7653936, lng: 18.2315048 },
    { lat: 48.7655758, lng: 18.2315543 },
    { lat: 48.7656888, lng: 18.2315935 },
    { lat: 48.7659287, lng: 18.231526 },
    { lat: 48.7661782, lng: 18.2322216 },
    { lat: 48.7662403, lng: 18.2322821 },
    { lat: 48.7664945, lng: 18.2322868 },
    { lat: 48.7665904, lng: 18.2323844 },
    { lat: 48.766666, lng: 18.232719 },
    { lat: 48.766644, lng: 18.2324543 },
    { lat: 48.7665384, lng: 18.2310993 },
    { lat: 48.7670047, lng: 18.2313426 },
    { lat: 48.7675298, lng: 18.2316656 },
    { lat: 48.7677611, lng: 18.2317247 },
    { lat: 48.7679337, lng: 18.2318023 },
    { lat: 48.7679846, lng: 18.2317448 },
    { lat: 48.7680369, lng: 18.2314776 },
    { lat: 48.7680845, lng: 18.2314002 },
    { lat: 48.7681844, lng: 18.2314322 },
    { lat: 48.7682767, lng: 18.2313507 },
    { lat: 48.7682801, lng: 18.2312487 },
    { lat: 48.7683298, lng: 18.231191 },
    { lat: 48.7685027, lng: 18.2313948 },
    { lat: 48.7685821, lng: 18.2314016 },
    { lat: 48.7685827, lng: 18.2310152 },
    { lat: 48.7687827, lng: 18.2311017 },
    { lat: 48.7688326, lng: 18.2308659 },
    { lat: 48.7688292, lng: 18.2307494 },
    { lat: 48.7688882, lng: 18.2306766 },
    { lat: 48.7689751, lng: 18.2306752 },
    { lat: 48.7690247, lng: 18.2306369 },
    { lat: 48.769064, lng: 18.2305559 },
    { lat: 48.7690818, lng: 18.2302127 },
    { lat: 48.7692754, lng: 18.2298532 },
    { lat: 48.769378, lng: 18.2297325 },
    { lat: 48.7694721, lng: 18.2297205 },
    { lat: 48.7703326, lng: 18.2299488 },
    { lat: 48.7705101, lng: 18.2301126 },
    { lat: 48.7707209, lng: 18.2300893 },
    { lat: 48.7708289, lng: 18.2300219 },
    { lat: 48.771031, lng: 18.2298488 },
    { lat: 48.7711883, lng: 18.2299358 },
    { lat: 48.7713302, lng: 18.2300686 },
    { lat: 48.7714954, lng: 18.230312 },
    { lat: 48.7715688, lng: 18.2304695 },
    { lat: 48.7717962, lng: 18.2305606 },
    { lat: 48.7719247, lng: 18.2304801 },
    { lat: 48.7721179, lng: 18.2306816 },
    { lat: 48.7722638, lng: 18.2310042 },
    { lat: 48.7724067, lng: 18.2310774 },
    { lat: 48.7725074, lng: 18.2310603 },
    { lat: 48.7727528, lng: 18.231129 },
    { lat: 48.7728706, lng: 18.2313529 },
    { lat: 48.7729766, lng: 18.2313348 },
    { lat: 48.7731242, lng: 18.231426 },
    { lat: 48.7732242, lng: 18.2313424 },
    { lat: 48.7734835, lng: 18.231301 },
    { lat: 48.7735545, lng: 18.2313754 },
    { lat: 48.7737155, lng: 18.2312885 },
    { lat: 48.7737796, lng: 18.2313051 },
    { lat: 48.7738954, lng: 18.2314574 },
    { lat: 48.7740458, lng: 18.2315035 },
    { lat: 48.7741244, lng: 18.2314557 },
    { lat: 48.7743069, lng: 18.2314915 },
    { lat: 48.7744265, lng: 18.2315278 },
    { lat: 48.7746061, lng: 18.2315347 },
    { lat: 48.7747198, lng: 18.231489 },
    { lat: 48.7748909, lng: 18.2315417 },
    { lat: 48.7752069, lng: 18.2314646 },
    { lat: 48.7754325, lng: 18.2315402 },
    { lat: 48.7755704, lng: 18.2316431 },
    { lat: 48.7757461, lng: 18.2316981 },
    { lat: 48.7758231, lng: 18.2316626 },
    { lat: 48.7761272, lng: 18.2313581 },
    { lat: 48.7763765, lng: 18.2310407 },
    { lat: 48.7766612, lng: 18.2308882 },
    { lat: 48.777002, lng: 18.2308104 },
    { lat: 48.7773209, lng: 18.2307028 },
    { lat: 48.7781909, lng: 18.230469 },
    { lat: 48.7784937, lng: 18.2307321 },
    { lat: 48.7789484, lng: 18.2308307 },
    { lat: 48.7792569, lng: 18.2309173 },
    { lat: 48.7797553, lng: 18.2310066 },
    { lat: 48.7797828, lng: 18.2306409 },
    { lat: 48.7800972, lng: 18.2307135 },
    { lat: 48.7800941, lng: 18.2308588 },
    { lat: 48.7804564, lng: 18.2312314 },
    { lat: 48.7806341, lng: 18.2315978 },
    { lat: 48.7806664, lng: 18.2318279 },
    { lat: 48.7807658, lng: 18.232007 },
    { lat: 48.7810046, lng: 18.2321969 },
    { lat: 48.7812825, lng: 18.2324017 },
    { lat: 48.7819339, lng: 18.232818 },
    { lat: 48.7821536, lng: 18.2328515 },
    { lat: 48.7824055, lng: 18.23282 },
    { lat: 48.7826631, lng: 18.2327276 },
    { lat: 48.782932, lng: 18.2327536 },
    { lat: 48.7834115, lng: 18.2327903 },
    { lat: 48.7837868, lng: 18.2328034 },
    { lat: 48.7843399, lng: 18.233082 },
    { lat: 48.7846616, lng: 18.2332614 },
    { lat: 48.7847477, lng: 18.2333282 },
    { lat: 48.785055, lng: 18.2334722 },
    { lat: 48.7851923, lng: 18.233505 },
    { lat: 48.7854051, lng: 18.2336575 },
    { lat: 48.7856067, lng: 18.2337464 },
    { lat: 48.7856174, lng: 18.2342105 },
    { lat: 48.7858434, lng: 18.2345538 },
    { lat: 48.7861416, lng: 18.2348561 },
    { lat: 48.7863148, lng: 18.234963 },
    { lat: 48.7869889, lng: 18.23517 },
    { lat: 48.787006, lng: 18.2355477 },
    { lat: 48.7870731, lng: 18.2360506 },
    { lat: 48.7871838, lng: 18.2363868 },
    { lat: 48.7872401, lng: 18.2367026 },
    { lat: 48.7873464, lng: 18.2373585 },
    { lat: 48.7876275, lng: 18.2373629 },
    { lat: 48.7879869, lng: 18.2373921 },
    { lat: 48.7883163, lng: 18.2374714 },
    { lat: 48.7885071, lng: 18.2374325 },
    { lat: 48.7887235, lng: 18.2375666 },
    { lat: 48.7889548, lng: 18.2375049 },
    { lat: 48.7892163, lng: 18.2375802 },
    { lat: 48.7892949, lng: 18.2375531 },
    { lat: 48.7894606, lng: 18.2372949 },
    { lat: 48.7895156, lng: 18.2371492 },
    { lat: 48.7895839, lng: 18.2368254 },
    { lat: 48.7896636, lng: 18.2367011 },
    { lat: 48.789943, lng: 18.2364342 },
    { lat: 48.7901144, lng: 18.2363555 },
    { lat: 48.7901962, lng: 18.2363638 },
    { lat: 48.7904473, lng: 18.2363345 },
    { lat: 48.7911517, lng: 18.2361028 },
    { lat: 48.7915073, lng: 18.2360463 },
    { lat: 48.791587, lng: 18.2359793 },
    { lat: 48.7916183, lng: 18.2357204 },
    { lat: 48.7919553, lng: 18.2355153 },
    { lat: 48.7921743, lng: 18.2353574 },
    { lat: 48.79231, lng: 18.235383 },
    { lat: 48.7930967, lng: 18.2356035 },
    { lat: 48.7931715, lng: 18.2357283 },
    { lat: 48.7932172, lng: 18.2358948 },
    { lat: 48.7932909, lng: 18.2365723 },
    { lat: 48.7933173, lng: 18.2376113 },
    { lat: 48.7933432, lng: 18.2387359 },
    { lat: 48.7933343, lng: 18.2388351 },
    { lat: 48.7934149, lng: 18.239001 },
    { lat: 48.7936833, lng: 18.2390013 },
    { lat: 48.7938134, lng: 18.2391275 },
    { lat: 48.793887, lng: 18.2390695 },
    { lat: 48.7939903, lng: 18.2386849 },
    { lat: 48.7939417, lng: 18.2384082 },
    { lat: 48.7941018, lng: 18.2379911 },
    { lat: 48.7944087, lng: 18.2380121 },
    { lat: 48.7944393, lng: 18.2383492 },
    { lat: 48.7946779, lng: 18.2383155 },
    { lat: 48.7949215, lng: 18.238416 },
    { lat: 48.794921, lng: 18.2388223 },
    { lat: 48.7951694, lng: 18.2388446 },
    { lat: 48.7953811, lng: 18.2391529 },
    { lat: 48.7955379, lng: 18.2388357 },
    { lat: 48.7958349, lng: 18.2388974 },
    { lat: 48.7959028, lng: 18.2392175 },
    { lat: 48.7962084, lng: 18.2392144 },
    { lat: 48.7966633, lng: 18.2395339 },
    { lat: 48.7966833, lng: 18.2397055 },
    { lat: 48.7968514, lng: 18.2398836 },
    { lat: 48.7970202, lng: 18.2398379 },
    { lat: 48.7971926, lng: 18.2400326 },
    { lat: 48.797427, lng: 18.2401623 },
    { lat: 48.7975892, lng: 18.2404367 },
    { lat: 48.7977743, lng: 18.2402418 },
    { lat: 48.7978498, lng: 18.2402176 },
    { lat: 48.7981974, lng: 18.2404585 },
    { lat: 48.7983025, lng: 18.240162 },
  ],
  Uhrovec: [
    { lat: 48.7608899, lng: 18.321348 },
    { lat: 48.7608405, lng: 18.3212892 },
    { lat: 48.7596248, lng: 18.3199185 },
    { lat: 48.7585354, lng: 18.3186885 },
    { lat: 48.757848, lng: 18.3180445 },
    { lat: 48.7572536, lng: 18.3172269 },
    { lat: 48.7563829, lng: 18.3168352 },
    { lat: 48.7561218, lng: 18.3167083 },
    { lat: 48.755606, lng: 18.3163616 },
    { lat: 48.755155, lng: 18.3159064 },
    { lat: 48.7547284, lng: 18.3153654 },
    { lat: 48.7544154, lng: 18.3150604 },
    { lat: 48.7538395, lng: 18.3143689 },
    { lat: 48.7538477, lng: 18.3143346 },
    { lat: 48.7534175, lng: 18.3140298 },
    { lat: 48.7522531, lng: 18.3138091 },
    { lat: 48.7512297, lng: 18.3133288 },
    { lat: 48.7501421, lng: 18.3127088 },
    { lat: 48.7489562, lng: 18.3120097 },
    { lat: 48.748372, lng: 18.3111949 },
    { lat: 48.7482457, lng: 18.3103783 },
    { lat: 48.7471096, lng: 18.3107532 },
    { lat: 48.7466054, lng: 18.3108973 },
    { lat: 48.7459266, lng: 18.311064 },
    { lat: 48.7454193, lng: 18.3113287 },
    { lat: 48.7449666, lng: 18.3115263 },
    { lat: 48.7446649, lng: 18.3116771 },
    { lat: 48.7445734, lng: 18.3112753 },
    { lat: 48.7443766, lng: 18.3109472 },
    { lat: 48.7440476, lng: 18.3104385 },
    { lat: 48.743599, lng: 18.3105254 },
    { lat: 48.7423266, lng: 18.3106898 },
    { lat: 48.7422944, lng: 18.3101206 },
    { lat: 48.7422532, lng: 18.3092898 },
    { lat: 48.7421455, lng: 18.3092848 },
    { lat: 48.7418205, lng: 18.3089807 },
    { lat: 48.7417504, lng: 18.3088189 },
    { lat: 48.7413626, lng: 18.308726 },
    { lat: 48.74099, lng: 18.3086223 },
    { lat: 48.7406725, lng: 18.3087309 },
    { lat: 48.7403227, lng: 18.3087325 },
    { lat: 48.7403228, lng: 18.3087146 },
    { lat: 48.7402211, lng: 18.3087131 },
    { lat: 48.740272, lng: 18.3082336 },
    { lat: 48.7402903, lng: 18.3077422 },
    { lat: 48.7402633, lng: 18.307301 },
    { lat: 48.7399094, lng: 18.3074276 },
    { lat: 48.7394688, lng: 18.3076396 },
    { lat: 48.7392434, lng: 18.3077835 },
    { lat: 48.7391103, lng: 18.307943 },
    { lat: 48.7389083, lng: 18.3082422 },
    { lat: 48.7387767, lng: 18.3083904 },
    { lat: 48.7386484, lng: 18.3084917 },
    { lat: 48.7382543, lng: 18.3087278 },
    { lat: 48.7380976, lng: 18.3080928 },
    { lat: 48.7380099, lng: 18.307717 },
    { lat: 48.7377627, lng: 18.3066978 },
    { lat: 48.7373857, lng: 18.3069134 },
    { lat: 48.7371508, lng: 18.3070914 },
    { lat: 48.7362098, lng: 18.3079686 },
    { lat: 48.7361068, lng: 18.3080624 },
    { lat: 48.7360341, lng: 18.3082952 },
    { lat: 48.7361109, lng: 18.3085737 },
    { lat: 48.7364119, lng: 18.3086703 },
    { lat: 48.7364296, lng: 18.3086826 },
    { lat: 48.736682, lng: 18.3089363 },
    { lat: 48.7367485, lng: 18.3089749 },
    { lat: 48.7367922, lng: 18.3089106 },
    { lat: 48.7370442, lng: 18.3087183 },
    { lat: 48.7371818, lng: 18.3087345 },
    { lat: 48.7372496, lng: 18.308779 },
    { lat: 48.7373663, lng: 18.3089458 },
    { lat: 48.7373835, lng: 18.3090112 },
    { lat: 48.7372162, lng: 18.3093048 },
    { lat: 48.737266, lng: 18.3094671 },
    { lat: 48.7374843, lng: 18.3094553 },
    { lat: 48.7375262, lng: 18.3094978 },
    { lat: 48.7375471, lng: 18.3096506 },
    { lat: 48.7374631, lng: 18.3099599 },
    { lat: 48.7371467, lng: 18.3101927 },
    { lat: 48.7365557, lng: 18.3105539 },
    { lat: 48.7363202, lng: 18.3106787 },
    { lat: 48.7365, lng: 18.3118443 },
    { lat: 48.736621, lng: 18.3126079 },
    { lat: 48.7363612, lng: 18.3129824 },
    { lat: 48.7361694, lng: 18.3131434 },
    { lat: 48.7360777, lng: 18.3133793 },
    { lat: 48.7359469, lng: 18.3136167 },
    { lat: 48.7357918, lng: 18.3136868 },
    { lat: 48.7357503, lng: 18.3137154 },
    { lat: 48.735345, lng: 18.3136884 },
    { lat: 48.7350583, lng: 18.3136868 },
    { lat: 48.7348098, lng: 18.3137239 },
    { lat: 48.7336758, lng: 18.3141194 },
    { lat: 48.7322956, lng: 18.3144717 },
    { lat: 48.7313551, lng: 18.3148829 },
    { lat: 48.7306622, lng: 18.3155822 },
    { lat: 48.7310223, lng: 18.3169049 },
    { lat: 48.7314697, lng: 18.3186129 },
    { lat: 48.7315906, lng: 18.3202349 },
    { lat: 48.731704, lng: 18.3202857 },
    { lat: 48.731993, lng: 18.3207266 },
    { lat: 48.7320658, lng: 18.3210375 },
    { lat: 48.7322774, lng: 18.3217287 },
    { lat: 48.7321956, lng: 18.3223785 },
    { lat: 48.7320839, lng: 18.3229579 },
    { lat: 48.7320727, lng: 18.3229394 },
    { lat: 48.7319335, lng: 18.3231331 },
    { lat: 48.731618, lng: 18.3233202 },
    { lat: 48.7313583, lng: 18.3235754 },
    { lat: 48.7308523, lng: 18.3242464 },
    { lat: 48.7304558, lng: 18.3246571 },
    { lat: 48.7297206, lng: 18.3251053 },
    { lat: 48.728822, lng: 18.325998 },
    { lat: 48.7281747, lng: 18.3271639 },
    { lat: 48.7280099, lng: 18.3280822 },
    { lat: 48.7278622, lng: 18.3287434 },
    { lat: 48.7276152, lng: 18.3291661 },
    { lat: 48.7275029, lng: 18.3295553 },
    { lat: 48.7272537, lng: 18.3302973 },
    { lat: 48.7268916, lng: 18.3308017 },
    { lat: 48.726497, lng: 18.3316464 },
    { lat: 48.7258458, lng: 18.332944 },
    { lat: 48.7252362, lng: 18.3342992 },
    { lat: 48.725122, lng: 18.3347556 },
    { lat: 48.7251366, lng: 18.335236 },
    { lat: 48.7252012, lng: 18.3356613 },
    { lat: 48.7251851, lng: 18.3356745 },
    { lat: 48.725447, lng: 18.33618 },
    { lat: 48.7256459, lng: 18.3364853 },
    { lat: 48.725877, lng: 18.3367438 },
    { lat: 48.7259288, lng: 18.3368014 },
    { lat: 48.7262413, lng: 18.3371602 },
    { lat: 48.7265719, lng: 18.3374025 },
    { lat: 48.7268011, lng: 18.3377257 },
    { lat: 48.7268135, lng: 18.3377459 },
    { lat: 48.7266122, lng: 18.3379589 },
    { lat: 48.7258818, lng: 18.3379719 },
    { lat: 48.725396, lng: 18.3380181 },
    { lat: 48.7251986, lng: 18.3380798 },
    { lat: 48.7248587, lng: 18.3382943 },
    { lat: 48.7242977, lng: 18.3386112 },
    { lat: 48.7238552, lng: 18.3388075 },
    { lat: 48.7235989, lng: 18.3390047 },
    { lat: 48.7231126, lng: 18.3394252 },
    { lat: 48.7225739, lng: 18.3408775 },
    { lat: 48.7221, lng: 18.3415604 },
    { lat: 48.7220562, lng: 18.3422263 },
    { lat: 48.7220357, lng: 18.3422417 },
    { lat: 48.7216819, lng: 18.3425112 },
    { lat: 48.7217814, lng: 18.3430345 },
    { lat: 48.7217871, lng: 18.3430769 },
    { lat: 48.7216124, lng: 18.3430409 },
    { lat: 48.7212775, lng: 18.342904 },
    { lat: 48.7211841, lng: 18.3428278 },
    { lat: 48.7211607, lng: 18.3427523 },
    { lat: 48.7211539, lng: 18.3425908 },
    { lat: 48.7212101, lng: 18.3423678 },
    { lat: 48.7212847, lng: 18.3419866 },
    { lat: 48.7212756, lng: 18.3417451 },
    { lat: 48.7212094, lng: 18.3416936 },
    { lat: 48.721145, lng: 18.3416921 },
    { lat: 48.7210388, lng: 18.3417288 },
    { lat: 48.720911, lng: 18.3418436 },
    { lat: 48.7207708, lng: 18.3419994 },
    { lat: 48.7206544, lng: 18.3421759 },
    { lat: 48.7205469, lng: 18.3422694 },
    { lat: 48.7204744, lng: 18.3423248 },
    { lat: 48.720367, lng: 18.34244 },
    { lat: 48.7202662, lng: 18.3425941 },
    { lat: 48.7200634, lng: 18.3431808 },
    { lat: 48.7196919, lng: 18.3433972 },
    { lat: 48.7191341, lng: 18.34357 },
    { lat: 48.7183246, lng: 18.3438802 },
    { lat: 48.7173319, lng: 18.3432397 },
    { lat: 48.7162817, lng: 18.342905 },
    { lat: 48.7154429, lng: 18.3427748 },
    { lat: 48.7146212, lng: 18.3426477 },
    { lat: 48.7141314, lng: 18.3424816 },
    { lat: 48.7139943, lng: 18.3424351 },
    { lat: 48.7134374, lng: 18.3421805 },
    { lat: 48.7129226, lng: 18.341996 },
    { lat: 48.7124087, lng: 18.3420463 },
    { lat: 48.7120661, lng: 18.3420523 },
    { lat: 48.7119733, lng: 18.3420272 },
    { lat: 48.7116775, lng: 18.3418432 },
    { lat: 48.7114941, lng: 18.3419733 },
    { lat: 48.7111983, lng: 18.3421903 },
    { lat: 48.7109952, lng: 18.342231 },
    { lat: 48.7106375, lng: 18.3425492 },
    { lat: 48.7105457, lng: 18.3426037 },
    { lat: 48.7103924, lng: 18.3426464 },
    { lat: 48.7101547, lng: 18.3427716 },
    { lat: 48.7097509, lng: 18.3428753 },
    { lat: 48.7094038, lng: 18.3430213 },
    { lat: 48.7091479, lng: 18.3431467 },
    { lat: 48.7089558, lng: 18.34328 },
    { lat: 48.7088178, lng: 18.3432752 },
    { lat: 48.7086096, lng: 18.3431852 },
    { lat: 48.7083839, lng: 18.3432424 },
    { lat: 48.7081486, lng: 18.3432714 },
    { lat: 48.7079304, lng: 18.3431835 },
    { lat: 48.7075217, lng: 18.3430181 },
    { lat: 48.7072632, lng: 18.3428366 },
    { lat: 48.7070095, lng: 18.3425384 },
    { lat: 48.7067508, lng: 18.3422325 },
    { lat: 48.7064329, lng: 18.3418749 },
    { lat: 48.7062009, lng: 18.3416025 },
    { lat: 48.7056769, lng: 18.3415339 },
    { lat: 48.7053806, lng: 18.3412192 },
    { lat: 48.7050537, lng: 18.3411651 },
    { lat: 48.7048184, lng: 18.3410945 },
    { lat: 48.7045882, lng: 18.3410317 },
    { lat: 48.7044957, lng: 18.3408525 },
    { lat: 48.7043269, lng: 18.3408397 },
    { lat: 48.7041918, lng: 18.3405847 },
    { lat: 48.7039735, lng: 18.3404152 },
    { lat: 48.7036618, lng: 18.3400299 },
    { lat: 48.7031792, lng: 18.339933 },
    { lat: 48.7026992, lng: 18.3396639 },
    { lat: 48.7023213, lng: 18.3392263 },
    { lat: 48.7022908, lng: 18.3384827 },
    { lat: 48.7017643, lng: 18.3380092 },
    { lat: 48.7012867, lng: 18.3379037 },
    { lat: 48.7012492, lng: 18.3380145 },
    { lat: 48.7009579, lng: 18.338053 },
    { lat: 48.7006851, lng: 18.3378763 },
    { lat: 48.7004619, lng: 18.3374992 },
    { lat: 48.7001047, lng: 18.3374066 },
    { lat: 48.7000229, lng: 18.337436 },
    { lat: 48.6998494, lng: 18.3371597 },
    { lat: 48.6993641, lng: 18.3365567 },
    { lat: 48.6992203, lng: 18.3365099 },
    { lat: 48.699023, lng: 18.3364706 },
    { lat: 48.6987425, lng: 18.3363388 },
    { lat: 48.6987205, lng: 18.3364095 },
    { lat: 48.6983612, lng: 18.3362171 },
    { lat: 48.6979658, lng: 18.3359515 },
    { lat: 48.6970169, lng: 18.3354878 },
    { lat: 48.6965016, lng: 18.3354355 },
    { lat: 48.6961128, lng: 18.3353026 },
    { lat: 48.6956161, lng: 18.3351558 },
    { lat: 48.6953402, lng: 18.3351277 },
    { lat: 48.69394, lng: 18.3354439 },
    { lat: 48.6935116, lng: 18.3354907 },
    { lat: 48.693227, lng: 18.336188 },
    { lat: 48.6930647, lng: 18.3375331 },
    { lat: 48.692964, lng: 18.3385277 },
    { lat: 48.692343, lng: 18.339358 },
    { lat: 48.692597, lng: 18.33941 },
    { lat: 48.692632, lng: 18.339469 },
    { lat: 48.692671, lng: 18.339462 },
    { lat: 48.692678, lng: 18.339537 },
    { lat: 48.692676, lng: 18.339541 },
    { lat: 48.692648, lng: 18.339539 },
    { lat: 48.692608, lng: 18.339598 },
    { lat: 48.692505, lng: 18.339681 },
    { lat: 48.692403, lng: 18.339876 },
    { lat: 48.692315, lng: 18.339913 },
    { lat: 48.692348, lng: 18.340117 },
    { lat: 48.692251, lng: 18.34038 },
    { lat: 48.6922, lng: 18.340492 },
    { lat: 48.692187, lng: 18.340692 },
    { lat: 48.692197, lng: 18.340896 },
    { lat: 48.692156, lng: 18.340995 },
    { lat: 48.692124, lng: 18.341233 },
    { lat: 48.692076, lng: 18.341439 },
    { lat: 48.692037, lng: 18.34152 },
    { lat: 48.691893, lng: 18.341916 },
    { lat: 48.691891, lng: 18.342147 },
    { lat: 48.691941, lng: 18.342363 },
    { lat: 48.691885, lng: 18.342779 },
    { lat: 48.691907, lng: 18.342938 },
    { lat: 48.691905, lng: 18.343161 },
    { lat: 48.691919, lng: 18.343332 },
    { lat: 48.691929, lng: 18.343547 },
    { lat: 48.691998, lng: 18.343651 },
    { lat: 48.692032, lng: 18.343749 },
    { lat: 48.691995, lng: 18.343979 },
    { lat: 48.691972, lng: 18.344076 },
    { lat: 48.692053, lng: 18.344496 },
    { lat: 48.692132, lng: 18.344739 },
    { lat: 48.69219, lng: 18.345076 },
    { lat: 48.692225, lng: 18.345146 },
    { lat: 48.692279, lng: 18.345194 },
    { lat: 48.692353, lng: 18.345219 },
    { lat: 48.692412, lng: 18.345565 },
    { lat: 48.692428, lng: 18.345807 },
    { lat: 48.692433, lng: 18.346193 },
    { lat: 48.6925, lng: 18.347289 },
    { lat: 48.692466, lng: 18.347474 },
    { lat: 48.692418, lng: 18.347672 },
    { lat: 48.692432, lng: 18.347843 },
    { lat: 48.692475, lng: 18.348093 },
    { lat: 48.692483, lng: 18.348149 },
    { lat: 48.692518, lng: 18.348359 },
    { lat: 48.692657, lng: 18.348738 },
    { lat: 48.692774, lng: 18.34888 },
    { lat: 48.692926, lng: 18.34899 },
    { lat: 48.692945, lng: 18.349279 },
    { lat: 48.692994, lng: 18.349599 },
    { lat: 48.692828, lng: 18.350018 },
    { lat: 48.692801, lng: 18.350198 },
    { lat: 48.692873, lng: 18.350513 },
    { lat: 48.692979, lng: 18.350995 },
    { lat: 48.693034, lng: 18.351383 },
    { lat: 48.693076, lng: 18.351469 },
    { lat: 48.693046, lng: 18.351746 },
    { lat: 48.693026, lng: 18.352188 },
    { lat: 48.693076, lng: 18.352267 },
    { lat: 48.693127, lng: 18.352348 },
    { lat: 48.693164, lng: 18.352408 },
    { lat: 48.693239, lng: 18.352442 },
    { lat: 48.693367, lng: 18.352582 },
    { lat: 48.69356, lng: 18.352861 },
    { lat: 48.693635, lng: 18.353262 },
    { lat: 48.693662, lng: 18.353402 },
    { lat: 48.693595, lng: 18.353516 },
    { lat: 48.693597, lng: 18.353774 },
    { lat: 48.693703, lng: 18.353936 },
    { lat: 48.693845, lng: 18.354096 },
    { lat: 48.69395, lng: 18.35416 },
    { lat: 48.694149, lng: 18.354185 },
    { lat: 48.694309, lng: 18.354311 },
    { lat: 48.694416, lng: 18.354396 },
    { lat: 48.694491, lng: 18.354429 },
    { lat: 48.694664, lng: 18.354461 },
    { lat: 48.695216, lng: 18.354761 },
    { lat: 48.695242, lng: 18.354776 },
    { lat: 48.695352, lng: 18.354858 },
    { lat: 48.695797, lng: 18.355353 },
    { lat: 48.695959, lng: 18.355302 },
    { lat: 48.696103, lng: 18.355226 },
    { lat: 48.696282, lng: 18.355073 },
    { lat: 48.69635, lng: 18.355072 },
    { lat: 48.696519, lng: 18.355201 },
    { lat: 48.696907, lng: 18.355178 },
    { lat: 48.697051, lng: 18.355344 },
    { lat: 48.697218, lng: 18.355342 },
    { lat: 48.697492, lng: 18.355266 },
    { lat: 48.697619, lng: 18.355116 },
    { lat: 48.697972, lng: 18.355135 },
    { lat: 48.69803, lng: 18.355103 },
    { lat: 48.698136, lng: 18.355043 },
    { lat: 48.698314, lng: 18.354966 },
    { lat: 48.698346, lng: 18.354958 },
    { lat: 48.698532, lng: 18.354907 },
    { lat: 48.698612, lng: 18.354896 },
    { lat: 48.698871, lng: 18.354861 },
    { lat: 48.699036, lng: 18.354804 },
    { lat: 48.699171, lng: 18.354857 },
    { lat: 48.699196, lng: 18.354892 },
    { lat: 48.699232, lng: 18.354939 },
    { lat: 48.699276, lng: 18.354973 },
    { lat: 48.699553, lng: 18.35504 },
    { lat: 48.699695, lng: 18.355122 },
    { lat: 48.699761, lng: 18.355209 },
    { lat: 48.699859, lng: 18.355245 },
    { lat: 48.699961, lng: 18.355427 },
    { lat: 48.700053, lng: 18.355577 },
    { lat: 48.700129, lng: 18.355661 },
    { lat: 48.700226, lng: 18.355722 },
    { lat: 48.700289, lng: 18.355684 },
    { lat: 48.700425, lng: 18.355718 },
    { lat: 48.700492, lng: 18.355776 },
    { lat: 48.700545, lng: 18.35593 },
    { lat: 48.700572, lng: 18.356004 },
    { lat: 48.700647, lng: 18.356121 },
    { lat: 48.700739, lng: 18.356179 },
    { lat: 48.700832, lng: 18.356301 },
    { lat: 48.700978, lng: 18.356361 },
    { lat: 48.701415, lng: 18.356683 },
    { lat: 48.701423, lng: 18.356689 },
    { lat: 48.701425, lng: 18.356727 },
    { lat: 48.701286, lng: 18.35708 },
    { lat: 48.701148, lng: 18.357526 },
    { lat: 48.701129, lng: 18.357701 },
    { lat: 48.701089, lng: 18.357884 },
    { lat: 48.700986, lng: 18.35803 },
    { lat: 48.700859, lng: 18.358125 },
    { lat: 48.700863, lng: 18.358329 },
    { lat: 48.700892, lng: 18.358386 },
    { lat: 48.700916, lng: 18.35849 },
    { lat: 48.700879, lng: 18.358978 },
    { lat: 48.700944, lng: 18.359319 },
    { lat: 48.701064, lng: 18.359265 },
    { lat: 48.701187, lng: 18.359296 },
    { lat: 48.701355, lng: 18.359439 },
    { lat: 48.701841, lng: 18.359894 },
    { lat: 48.701989, lng: 18.359943 },
    { lat: 48.702267, lng: 18.360111 },
    { lat: 48.70242, lng: 18.360304 },
    { lat: 48.701811, lng: 18.361993 },
    { lat: 48.702321, lng: 18.361955 },
    { lat: 48.702686, lng: 18.361963 },
    { lat: 48.703352, lng: 18.362182 },
    { lat: 48.703572, lng: 18.36247 },
    { lat: 48.703781, lng: 18.362866 },
    { lat: 48.703886, lng: 18.363248 },
    { lat: 48.704002, lng: 18.363563 },
    { lat: 48.703972, lng: 18.363831 },
    { lat: 48.70398, lng: 18.364042 },
    { lat: 48.703943, lng: 18.364422 },
    { lat: 48.7038, lng: 18.364836 },
    { lat: 48.703872, lng: 18.364968 },
    { lat: 48.703978, lng: 18.365183 },
    { lat: 48.70412, lng: 18.365516 },
    { lat: 48.704383, lng: 18.366209 },
    { lat: 48.704463, lng: 18.366545 },
    { lat: 48.704513, lng: 18.366658 },
    { lat: 48.704295, lng: 18.367206 },
    { lat: 48.704241, lng: 18.367887 },
    { lat: 48.704146, lng: 18.36832 },
    { lat: 48.703912, lng: 18.369207 },
    { lat: 48.70359, lng: 18.370326 },
    { lat: 48.703436, lng: 18.371316 },
    { lat: 48.703328, lng: 18.371758 },
    { lat: 48.703317, lng: 18.372479 },
    { lat: 48.703267, lng: 18.373654 },
    { lat: 48.703255, lng: 18.374065 },
    { lat: 48.703303, lng: 18.374387 },
    { lat: 48.70341, lng: 18.374845 },
    { lat: 48.703497, lng: 18.375361 },
    { lat: 48.703539, lng: 18.375644 },
    { lat: 48.703582, lng: 18.375876 },
    { lat: 48.703615, lng: 18.376157 },
    { lat: 48.703654, lng: 18.376363 },
    { lat: 48.703674, lng: 18.376425 },
    { lat: 48.703827, lng: 18.376917 },
    { lat: 48.703988, lng: 18.376713 },
    { lat: 48.70419, lng: 18.376641 },
    { lat: 48.70442, lng: 18.376533 },
    { lat: 48.704565, lng: 18.376382 },
    { lat: 48.704657, lng: 18.376279 },
    { lat: 48.704757, lng: 18.376292 },
    { lat: 48.704896, lng: 18.376125 },
    { lat: 48.705216, lng: 18.376031 },
    { lat: 48.70543, lng: 18.376042 },
    { lat: 48.70547, lng: 18.376056 },
    { lat: 48.705641, lng: 18.376177 },
    { lat: 48.705818, lng: 18.376139 },
    { lat: 48.705897, lng: 18.376285 },
    { lat: 48.706039, lng: 18.376273 },
    { lat: 48.70655, lng: 18.37589 },
    { lat: 48.706846, lng: 18.375423 },
    { lat: 48.707401, lng: 18.374797 },
    { lat: 48.707504, lng: 18.374763 },
    { lat: 48.707482, lng: 18.374708 },
    { lat: 48.708683, lng: 18.37286 },
    { lat: 48.709021, lng: 18.372338 },
    { lat: 48.709192, lng: 18.372177 },
    { lat: 48.709374, lng: 18.372042 },
    { lat: 48.709549, lng: 18.371945 },
    { lat: 48.709716, lng: 18.371895 },
    { lat: 48.709894, lng: 18.371789 },
    { lat: 48.709989, lng: 18.371729 },
    { lat: 48.710118, lng: 18.371583 },
    { lat: 48.710237, lng: 18.371476 },
    { lat: 48.710325, lng: 18.371429 },
    { lat: 48.710487, lng: 18.37113 },
    { lat: 48.71057, lng: 18.370766 },
    { lat: 48.710667, lng: 18.370705 },
    { lat: 48.710687, lng: 18.370549 },
    { lat: 48.710863, lng: 18.370279 },
    { lat: 48.711231, lng: 18.370037 },
    { lat: 48.711286, lng: 18.370051 },
    { lat: 48.711319, lng: 18.369994 },
    { lat: 48.711311, lng: 18.369789 },
    { lat: 48.711362, lng: 18.369738 },
    { lat: 48.711403, lng: 18.369489 },
    { lat: 48.711438, lng: 18.369437 },
    { lat: 48.711467, lng: 18.369307 },
    { lat: 48.711545, lng: 18.36921 },
    { lat: 48.711551, lng: 18.369037 },
    { lat: 48.711563, lng: 18.368876 },
    { lat: 48.711722, lng: 18.368228 },
    { lat: 48.711964, lng: 18.368286 },
    { lat: 48.712373, lng: 18.368308 },
    { lat: 48.712715, lng: 18.368311 },
    { lat: 48.712852, lng: 18.368304 },
    { lat: 48.712997, lng: 18.368294 },
    { lat: 48.713268, lng: 18.368394 },
    { lat: 48.713389, lng: 18.3684 },
    { lat: 48.713611, lng: 18.368438 },
    { lat: 48.713744, lng: 18.36846 },
    { lat: 48.713714, lng: 18.368183 },
    { lat: 48.71366, lng: 18.367677 },
    { lat: 48.713864, lng: 18.367479 },
    { lat: 48.714091, lng: 18.367252 },
    { lat: 48.714154, lng: 18.367188 },
    { lat: 48.714374, lng: 18.367016 },
    { lat: 48.714565, lng: 18.366819 },
    { lat: 48.714872, lng: 18.366539 },
    { lat: 48.715185, lng: 18.366329 },
    { lat: 48.715707, lng: 18.36611 },
    { lat: 48.71601, lng: 18.365988 },
    { lat: 48.716346, lng: 18.365889 },
    { lat: 48.716584, lng: 18.365841 },
    { lat: 48.716992, lng: 18.365816 },
    { lat: 48.717238, lng: 18.365839 },
    { lat: 48.717485, lng: 18.365831 },
    { lat: 48.717895, lng: 18.3658 },
    { lat: 48.71805, lng: 18.365754 },
    { lat: 48.718305, lng: 18.365651 },
    { lat: 48.718584, lng: 18.365495 },
    { lat: 48.718646, lng: 18.365537 },
    { lat: 48.718698, lng: 18.365471 },
    { lat: 48.718733, lng: 18.365407 },
    { lat: 48.718749, lng: 18.365376 },
    { lat: 48.718838, lng: 18.365214 },
    { lat: 48.719309, lng: 18.364543 },
    { lat: 48.719398, lng: 18.364445 },
    { lat: 48.71951, lng: 18.364312 },
    { lat: 48.719841, lng: 18.364046 },
    { lat: 48.720533, lng: 18.363582 },
    { lat: 48.720783, lng: 18.36344 },
    { lat: 48.721013, lng: 18.36336 },
    { lat: 48.721035, lng: 18.363314 },
    { lat: 48.721295, lng: 18.363243 },
    { lat: 48.721733, lng: 18.363156 },
    { lat: 48.721749, lng: 18.363159 },
    { lat: 48.721763, lng: 18.363189 },
    { lat: 48.722552, lng: 18.362917 },
    { lat: 48.722784, lng: 18.362869 },
    { lat: 48.723128, lng: 18.362852 },
    { lat: 48.723345, lng: 18.362835 },
    { lat: 48.723705, lng: 18.362754 },
    { lat: 48.723995, lng: 18.362671 },
    { lat: 48.724186, lng: 18.362649 },
    { lat: 48.724477, lng: 18.362673 },
    { lat: 48.72467, lng: 18.362686 },
    { lat: 48.725281, lng: 18.362628 },
    { lat: 48.725415, lng: 18.362666 },
    { lat: 48.725608, lng: 18.362765 },
    { lat: 48.725823, lng: 18.362813 },
    { lat: 48.726056, lng: 18.362891 },
    { lat: 48.72627, lng: 18.363014 },
    { lat: 48.72652, lng: 18.363108 },
    { lat: 48.726722, lng: 18.363236 },
    { lat: 48.727124, lng: 18.363431 },
    { lat: 48.727539, lng: 18.363662 },
    { lat: 48.727985, lng: 18.364033 },
    { lat: 48.728146, lng: 18.364255 },
    { lat: 48.728329, lng: 18.364844 },
    { lat: 48.728375, lng: 18.365193 },
    { lat: 48.728394, lng: 18.365806 },
    { lat: 48.728403, lng: 18.365997 },
    { lat: 48.728452, lng: 18.366286 },
    { lat: 48.728544, lng: 18.366469 },
    { lat: 48.728625, lng: 18.366566 },
    { lat: 48.728714, lng: 18.366644 },
    { lat: 48.728983, lng: 18.366796 },
    { lat: 48.72946, lng: 18.366986 },
    { lat: 48.729816, lng: 18.367068 },
    { lat: 48.730083, lng: 18.367088 },
    { lat: 48.730441, lng: 18.367262 },
    { lat: 48.730787, lng: 18.367498 },
    { lat: 48.731167, lng: 18.367601 },
    { lat: 48.731428, lng: 18.367559 },
    { lat: 48.731649, lng: 18.367542 },
    { lat: 48.731904, lng: 18.367558 },
    { lat: 48.732156, lng: 18.367588 },
    { lat: 48.732487, lng: 18.3676 },
    { lat: 48.732967, lng: 18.367392 },
    { lat: 48.733271, lng: 18.367392 },
    { lat: 48.73359, lng: 18.367499 },
    { lat: 48.733772, lng: 18.36753 },
    { lat: 48.73392, lng: 18.367601 },
    { lat: 48.734045, lng: 18.367664 },
    { lat: 48.734404, lng: 18.368054 },
    { lat: 48.734658, lng: 18.368494 },
    { lat: 48.734947, lng: 18.369297 },
    { lat: 48.735077, lng: 18.369842 },
    { lat: 48.73507, lng: 18.370573 },
    { lat: 48.735137, lng: 18.371407 },
    { lat: 48.735117, lng: 18.372027 },
    { lat: 48.735131, lng: 18.372371 },
    { lat: 48.735103, lng: 18.373033 },
    { lat: 48.735122, lng: 18.373248 },
    { lat: 48.735018, lng: 18.373937 },
    { lat: 48.734974, lng: 18.374132 },
    { lat: 48.734956, lng: 18.374478 },
    { lat: 48.734917, lng: 18.37477 },
    { lat: 48.735001, lng: 18.375011 },
    { lat: 48.734967, lng: 18.375891 },
    { lat: 48.734998, lng: 18.376051 },
    { lat: 48.735074, lng: 18.376252 },
    { lat: 48.735185, lng: 18.376434 },
    { lat: 48.7353, lng: 18.376567 },
    { lat: 48.735521, lng: 18.376824 },
    { lat: 48.735665, lng: 18.376934 },
    { lat: 48.735747, lng: 18.376958 },
    { lat: 48.736136, lng: 18.376922 },
    { lat: 48.736267, lng: 18.376984 },
    { lat: 48.736388, lng: 18.377066 },
    { lat: 48.736629, lng: 18.377233 },
    { lat: 48.736977, lng: 18.37758 },
    { lat: 48.737334, lng: 18.378007 },
    { lat: 48.737508, lng: 18.378254 },
    { lat: 48.737718, lng: 18.378616 },
    { lat: 48.737755, lng: 18.378856 },
    { lat: 48.737751, lng: 18.379147 },
    { lat: 48.737713, lng: 18.379462 },
    { lat: 48.73762, lng: 18.37984 },
    { lat: 48.737481, lng: 18.380196 },
    { lat: 48.737458, lng: 18.380448 },
    { lat: 48.737486, lng: 18.380873 },
    { lat: 48.737476, lng: 18.38115 },
    { lat: 48.737356, lng: 18.381485 },
    { lat: 48.737313, lng: 18.381697 },
    { lat: 48.737212, lng: 18.382044 },
    { lat: 48.737191, lng: 18.382373 },
    { lat: 48.73718, lng: 18.382622 },
    { lat: 48.737205, lng: 18.382848 },
    { lat: 48.737183, lng: 18.383178 },
    { lat: 48.737257, lng: 18.383435 },
    { lat: 48.737268, lng: 18.383899 },
    { lat: 48.737629, lng: 18.384726 },
    { lat: 48.737792, lng: 18.385248 },
    { lat: 48.737775, lng: 18.385861 },
    { lat: 48.737812, lng: 18.386015 },
    { lat: 48.737886, lng: 18.386186 },
    { lat: 48.738092, lng: 18.386984 },
    { lat: 48.738197, lng: 18.387271 },
    { lat: 48.73853, lng: 18.387899 },
    { lat: 48.738772, lng: 18.388317 },
    { lat: 48.738878, lng: 18.388617 },
    { lat: 48.738949, lng: 18.389217 },
    { lat: 48.739033, lng: 18.389902 },
    { lat: 48.739021, lng: 18.390495 },
    { lat: 48.739512, lng: 18.390655 },
    { lat: 48.739985, lng: 18.390482 },
    { lat: 48.740743, lng: 18.391399 },
    { lat: 48.740908, lng: 18.392965 },
    { lat: 48.740984, lng: 18.39338 },
    { lat: 48.741205, lng: 18.394346 },
    { lat: 48.741297, lng: 18.394741 },
    { lat: 48.741411, lng: 18.395086 },
    { lat: 48.7415162, lng: 18.3949956 },
    { lat: 48.7418469, lng: 18.3946178 },
    { lat: 48.7424145, lng: 18.3940111 },
    { lat: 48.743159, lng: 18.3932613 },
    { lat: 48.7437256, lng: 18.3923547 },
    { lat: 48.7441967, lng: 18.3914803 },
    { lat: 48.7442589, lng: 18.3906934 },
    { lat: 48.7444829, lng: 18.3895088 },
    { lat: 48.7444551, lng: 18.3883885 },
    { lat: 48.7447158, lng: 18.3867913 },
    { lat: 48.7449482, lng: 18.3855016 },
    { lat: 48.7456992, lng: 18.3848051 },
    { lat: 48.7465957, lng: 18.3839902 },
    { lat: 48.7473459, lng: 18.3832427 },
    { lat: 48.7476431, lng: 18.3824851 },
    { lat: 48.7481954, lng: 18.3819541 },
    { lat: 48.7487937, lng: 18.3818983 },
    { lat: 48.7496827, lng: 18.3808677 },
    { lat: 48.7500534, lng: 18.380578 },
    { lat: 48.7504198, lng: 18.3802916 },
    { lat: 48.7509178, lng: 18.379913 },
    { lat: 48.7513162, lng: 18.3797952 },
    { lat: 48.7522812, lng: 18.3796253 },
    { lat: 48.7529072, lng: 18.3790583 },
    { lat: 48.7534505, lng: 18.3771529 },
    { lat: 48.7538134, lng: 18.3762784 },
    { lat: 48.7539397, lng: 18.376017 },
    { lat: 48.7544709, lng: 18.3756702 },
    { lat: 48.7549243, lng: 18.3750019 },
    { lat: 48.7552864, lng: 18.3745193 },
    { lat: 48.7557962, lng: 18.373912 },
    { lat: 48.756139, lng: 18.3730208 },
    { lat: 48.7562203, lng: 18.3727579 },
    { lat: 48.7564166, lng: 18.3717113 },
    { lat: 48.7564811, lng: 18.3702631 },
    { lat: 48.7568644, lng: 18.369411 },
    { lat: 48.7574109, lng: 18.3687929 },
    { lat: 48.7569919, lng: 18.3679598 },
    { lat: 48.7566128, lng: 18.3672357 },
    { lat: 48.7565424, lng: 18.3668235 },
    { lat: 48.7563272, lng: 18.3659826 },
    { lat: 48.7563353, lng: 18.3658049 },
    { lat: 48.7563689, lng: 18.3652367 },
    { lat: 48.7562827, lng: 18.3640614 },
    { lat: 48.7562636, lng: 18.3631381 },
    { lat: 48.7562573, lng: 18.3626423 },
    { lat: 48.7562315, lng: 18.3613763 },
    { lat: 48.7562203, lng: 18.3603322 },
    { lat: 48.7562081, lng: 18.3596516 },
    { lat: 48.7559381, lng: 18.358959 },
    { lat: 48.7555745, lng: 18.3579902 },
    { lat: 48.7552488, lng: 18.3571102 },
    { lat: 48.754972, lng: 18.3563412 },
    { lat: 48.7552503, lng: 18.3562519 },
    { lat: 48.7559239, lng: 18.3560514 },
    { lat: 48.7566539, lng: 18.3558518 },
    { lat: 48.7567603, lng: 18.3558189 },
    { lat: 48.7565874, lng: 18.3554928 },
    { lat: 48.7563889, lng: 18.35517 },
    { lat: 48.7563575, lng: 18.3548934 },
    { lat: 48.7562621, lng: 18.3545624 },
    { lat: 48.7557684, lng: 18.3540054 },
    { lat: 48.7556831, lng: 18.3541354 },
    { lat: 48.7555883, lng: 18.3540346 },
    { lat: 48.7555298, lng: 18.3538948 },
    { lat: 48.7554906, lng: 18.3536608 },
    { lat: 48.7554637, lng: 18.3535003 },
    { lat: 48.7553523, lng: 18.3531824 },
    { lat: 48.7555022, lng: 18.3530613 },
    { lat: 48.75549, lng: 18.3528447 },
    { lat: 48.7557105, lng: 18.3527335 },
    { lat: 48.7558261, lng: 18.3526028 },
    { lat: 48.7561446, lng: 18.3526663 },
    { lat: 48.7565284, lng: 18.3524919 },
    { lat: 48.75661, lng: 18.3524237 },
    { lat: 48.7569778, lng: 18.3521166 },
    { lat: 48.7568492, lng: 18.3516744 },
    { lat: 48.7568202, lng: 18.3514403 },
    { lat: 48.7568032, lng: 18.3510953 },
    { lat: 48.7567725, lng: 18.3508898 },
    { lat: 48.7567359, lng: 18.3507432 },
    { lat: 48.7567127, lng: 18.3502663 },
    { lat: 48.7566987, lng: 18.3498183 },
    { lat: 48.7568153, lng: 18.3495413 },
    { lat: 48.7567156, lng: 18.3493749 },
    { lat: 48.7566609, lng: 18.3491835 },
    { lat: 48.7566553, lng: 18.3490838 },
    { lat: 48.7566738, lng: 18.3487907 },
    { lat: 48.7567037, lng: 18.3485405 },
    { lat: 48.7569384, lng: 18.348128 },
    { lat: 48.7569667, lng: 18.3480783 },
    { lat: 48.7571366, lng: 18.3478706 },
    { lat: 48.7574718, lng: 18.3471291 },
    { lat: 48.7573503, lng: 18.3468773 },
    { lat: 48.7576304, lng: 18.3455762 },
    { lat: 48.7577499, lng: 18.3451117 },
    { lat: 48.7573393, lng: 18.3447129 },
    { lat: 48.7568182, lng: 18.3442125 },
    { lat: 48.75602, lng: 18.3434409 },
    { lat: 48.7560954, lng: 18.3430378 },
    { lat: 48.7562601, lng: 18.342013 },
    { lat: 48.7563133, lng: 18.3417158 },
    { lat: 48.7564047, lng: 18.3411541 },
    { lat: 48.7562436, lng: 18.3406732 },
    { lat: 48.7563052, lng: 18.3405873 },
    { lat: 48.7563947, lng: 18.3406941 },
    { lat: 48.7564005, lng: 18.3407181 },
    { lat: 48.7564387, lng: 18.3407339 },
    { lat: 48.7564636, lng: 18.3406338 },
    { lat: 48.7564711, lng: 18.3404058 },
    { lat: 48.7565142, lng: 18.3403309 },
    { lat: 48.756536, lng: 18.3403195 },
    { lat: 48.7566864, lng: 18.3403241 },
    { lat: 48.7568437, lng: 18.3403725 },
    { lat: 48.7568436, lng: 18.3403117 },
    { lat: 48.7567698, lng: 18.3385366 },
    { lat: 48.7566683, lng: 18.3361697 },
    { lat: 48.7573697, lng: 18.3361642 },
    { lat: 48.7574864, lng: 18.3361924 },
    { lat: 48.7575203, lng: 18.3360761 },
    { lat: 48.7576265, lng: 18.3358375 },
    { lat: 48.7577427, lng: 18.335617 },
    { lat: 48.7578665, lng: 18.3352299 },
    { lat: 48.7579637, lng: 18.3350704 },
    { lat: 48.7580792, lng: 18.3348377 },
    { lat: 48.758181, lng: 18.3345407 },
    { lat: 48.7582235, lng: 18.3342553 },
    { lat: 48.7583314, lng: 18.3339074 },
    { lat: 48.7584422, lng: 18.3336299 },
    { lat: 48.7585603, lng: 18.3332668 },
    { lat: 48.7586121, lng: 18.3330824 },
    { lat: 48.7586863, lng: 18.3328758 },
    { lat: 48.7589364, lng: 18.3316486 },
    { lat: 48.7589961, lng: 18.3313648 },
    { lat: 48.759054, lng: 18.3312136 },
    { lat: 48.7591939, lng: 18.3307933 },
    { lat: 48.7593063, lng: 18.3305643 },
    { lat: 48.7593658, lng: 18.3303989 },
    { lat: 48.7594041, lng: 18.3301751 },
    { lat: 48.759467, lng: 18.3299332 },
    { lat: 48.7594341, lng: 18.3298273 },
    { lat: 48.7594991, lng: 18.3296801 },
    { lat: 48.7595115, lng: 18.3295813 },
    { lat: 48.7596006, lng: 18.3295387 },
    { lat: 48.7599413, lng: 18.3291565 },
    { lat: 48.7599449, lng: 18.3281798 },
    { lat: 48.7600356, lng: 18.3279485 },
    { lat: 48.7602312, lng: 18.327874 },
    { lat: 48.7602467, lng: 18.3277704 },
    { lat: 48.7605142, lng: 18.3271888 },
    { lat: 48.7609932, lng: 18.3267401 },
    { lat: 48.7613896, lng: 18.3270663 },
    { lat: 48.7615193, lng: 18.3270853 },
    { lat: 48.7616198, lng: 18.326109 },
    { lat: 48.761667, lng: 18.3260017 },
    { lat: 48.7609223, lng: 18.3257641 },
    { lat: 48.760962, lng: 18.3247856 },
    { lat: 48.7607152, lng: 18.3247108 },
    { lat: 48.7603945, lng: 18.324705 },
    { lat: 48.7600939, lng: 18.3247584 },
    { lat: 48.7601413, lng: 18.3235344 },
    { lat: 48.7603144, lng: 18.3227432 },
    { lat: 48.7604362, lng: 18.322224 },
    { lat: 48.7606751, lng: 18.3218195 },
    { lat: 48.7608899, lng: 18.321348 },
  ],
  UhrovskéPodhradie: [
    { lat: 48.740845, lng: 18.411824 },
    { lat: 48.741333, lng: 18.41235 },
    { lat: 48.741694, lng: 18.412311 },
    { lat: 48.741955, lng: 18.412303 },
    { lat: 48.742321, lng: 18.41201 },
    { lat: 48.742314, lng: 18.411968 },
    { lat: 48.742427, lng: 18.411918 },
    { lat: 48.742595, lng: 18.412087 },
    { lat: 48.742879, lng: 18.41266 },
    { lat: 48.743247, lng: 18.41333 },
    { lat: 48.743449, lng: 18.41364 },
    { lat: 48.743672, lng: 18.413861 },
    { lat: 48.743856, lng: 18.414244 },
    { lat: 48.743876, lng: 18.414212 },
    { lat: 48.744028, lng: 18.414447 },
    { lat: 48.744569, lng: 18.415269 },
    { lat: 48.744607, lng: 18.415326 },
    { lat: 48.745049, lng: 18.415974 },
    { lat: 48.745361, lng: 18.416414 },
    { lat: 48.745552, lng: 18.416823 },
    { lat: 48.74565, lng: 18.417034 },
    { lat: 48.746112, lng: 18.417681 },
    { lat: 48.746067, lng: 18.41951 },
    { lat: 48.745852, lng: 18.420769 },
    { lat: 48.745807, lng: 18.422126 },
    { lat: 48.745687, lng: 18.422811 },
    { lat: 48.745884, lng: 18.423367 },
    { lat: 48.745973, lng: 18.423705 },
    { lat: 48.746044, lng: 18.424143 },
    { lat: 48.746388, lng: 18.424995 },
    { lat: 48.746519, lng: 18.425646 },
    { lat: 48.746597, lng: 18.426077 },
    { lat: 48.7469, lng: 18.426438 },
    { lat: 48.74723, lng: 18.426405 },
    { lat: 48.747598, lng: 18.426569 },
    { lat: 48.748134, lng: 18.426369 },
    { lat: 48.748773, lng: 18.426409 },
    { lat: 48.749133, lng: 18.42643 },
    { lat: 48.749445, lng: 18.426732 },
    { lat: 48.749723, lng: 18.427279 },
    { lat: 48.749849, lng: 18.427699 },
    { lat: 48.750258, lng: 18.428075 },
    { lat: 48.750806, lng: 18.428656 },
    { lat: 48.7509406, lng: 18.4288849 },
    { lat: 48.751354, lng: 18.429588 },
    { lat: 48.751414, lng: 18.430235 },
    { lat: 48.751491, lng: 18.430974 },
    { lat: 48.751739, lng: 18.431911 },
    { lat: 48.752279, lng: 18.431434 },
    { lat: 48.752636, lng: 18.431262 },
    { lat: 48.75303, lng: 18.431394 },
    { lat: 48.753452, lng: 18.431414 },
    { lat: 48.75375, lng: 18.431566 },
    { lat: 48.753787, lng: 18.431585 },
    { lat: 48.75432, lng: 18.431754 },
    { lat: 48.754569, lng: 18.431784 },
    { lat: 48.755241, lng: 18.431499 },
    { lat: 48.755808, lng: 18.431451 },
    { lat: 48.756678, lng: 18.431334 },
    { lat: 48.757296, lng: 18.431424 },
    { lat: 48.757468, lng: 18.431536 },
    { lat: 48.757846, lng: 18.431818 },
    { lat: 48.758219, lng: 18.431922 },
    { lat: 48.759116, lng: 18.431854 },
    { lat: 48.759377, lng: 18.431796 },
    { lat: 48.759662, lng: 18.431751 },
    { lat: 48.760293, lng: 18.431723 },
    { lat: 48.760849, lng: 18.431753 },
    { lat: 48.761407, lng: 18.431791 },
    { lat: 48.761841, lng: 18.432657 },
    { lat: 48.762384, lng: 18.433008 },
    { lat: 48.762623, lng: 18.433287 },
    { lat: 48.762727, lng: 18.433356 },
    { lat: 48.763007, lng: 18.433589 },
    { lat: 48.763452, lng: 18.434093 },
    { lat: 48.763964, lng: 18.434037 },
    { lat: 48.764091, lng: 18.4342 },
    { lat: 48.764409, lng: 18.43436 },
    { lat: 48.764689, lng: 18.434588 },
    { lat: 48.765048, lng: 18.434666 },
    { lat: 48.765198, lng: 18.43467 },
    { lat: 48.765667, lng: 18.43519 },
    { lat: 48.765798, lng: 18.43559 },
    { lat: 48.765922, lng: 18.436645 },
    { lat: 48.765963, lng: 18.437301 },
    { lat: 48.76606, lng: 18.438671 },
    { lat: 48.7661822, lng: 18.43954 },
    { lat: 48.76695, lng: 18.439521 },
    { lat: 48.767126, lng: 18.439382 },
    { lat: 48.76765, lng: 18.439276 },
    { lat: 48.767737, lng: 18.439118 },
    { lat: 48.768457, lng: 18.438916 },
    { lat: 48.769155, lng: 18.438956 },
    { lat: 48.769467, lng: 18.43865 },
    { lat: 48.769784, lng: 18.438225 },
    { lat: 48.769765, lng: 18.437695 },
    { lat: 48.769825, lng: 18.437459 },
    { lat: 48.770015, lng: 18.436806 },
    { lat: 48.770081, lng: 18.436576 },
    { lat: 48.77022, lng: 18.43624 },
    { lat: 48.770282, lng: 18.436091 },
    { lat: 48.771014, lng: 18.434484 },
    { lat: 48.771836, lng: 18.434119 },
    { lat: 48.772049, lng: 18.434137 },
    { lat: 48.772231, lng: 18.434118 },
    { lat: 48.772645, lng: 18.433953 },
    { lat: 48.773185, lng: 18.433719 },
    { lat: 48.773421, lng: 18.433476 },
    { lat: 48.773033, lng: 18.4326007 },
    { lat: 48.7727981, lng: 18.4322485 },
    { lat: 48.772868, lng: 18.4313516 },
    { lat: 48.7731779, lng: 18.4306556 },
    { lat: 48.7734903, lng: 18.4298392 },
    { lat: 48.7734815, lng: 18.4291314 },
    { lat: 48.7733178, lng: 18.4283364 },
    { lat: 48.773491, lng: 18.4275115 },
    { lat: 48.7735601, lng: 18.4262391 },
    { lat: 48.7735769, lng: 18.4256257 },
    { lat: 48.7737563, lng: 18.4245686 },
    { lat: 48.7738623, lng: 18.423935 },
    { lat: 48.773799, lng: 18.4232027 },
    { lat: 48.7736594, lng: 18.4221099 },
    { lat: 48.7737183, lng: 18.4206925 },
    { lat: 48.7736032, lng: 18.4199178 },
    { lat: 48.7727755, lng: 18.4176908 },
    { lat: 48.7726233, lng: 18.4163514 },
    { lat: 48.7722738, lng: 18.4150346 },
    { lat: 48.7723142, lng: 18.4136722 },
    { lat: 48.772281, lng: 18.4130541 },
    { lat: 48.7721471, lng: 18.4127557 },
    { lat: 48.7718073, lng: 18.4116985 },
    { lat: 48.7713352, lng: 18.4104095 },
    { lat: 48.770857, lng: 18.4093993 },
    { lat: 48.7706716, lng: 18.4087011 },
    { lat: 48.7702777, lng: 18.4076215 },
    { lat: 48.7699657, lng: 18.4069424 },
    { lat: 48.7694966, lng: 18.4057887 },
    { lat: 48.7688981, lng: 18.4044022 },
    { lat: 48.7692121, lng: 18.4029292 },
    { lat: 48.7695149, lng: 18.4017823 },
    { lat: 48.7693102, lng: 18.4010652 },
    { lat: 48.7692468, lng: 18.400411 },
    { lat: 48.7692745, lng: 18.3998349 },
    { lat: 48.7694229, lng: 18.3995241 },
    { lat: 48.7691137, lng: 18.3992781 },
    { lat: 48.768824, lng: 18.3989371 },
    { lat: 48.7685747, lng: 18.3984553 },
    { lat: 48.7683095, lng: 18.3978454 },
    { lat: 48.768074, lng: 18.3971827 },
    { lat: 48.7678972, lng: 18.3969839 },
    { lat: 48.7677884, lng: 18.3964896 },
    { lat: 48.7675331, lng: 18.3954733 },
    { lat: 48.7673327, lng: 18.3948723 },
    { lat: 48.7671447, lng: 18.394455 },
    { lat: 48.7670253, lng: 18.3941548 },
    { lat: 48.7672745, lng: 18.3932818 },
    { lat: 48.7673785, lng: 18.3925555 },
    { lat: 48.767498, lng: 18.3919694 },
    { lat: 48.7678533, lng: 18.3912832 },
    { lat: 48.7681055, lng: 18.3905444 },
    { lat: 48.768244, lng: 18.3899123 },
    { lat: 48.7684269, lng: 18.3893689 },
    { lat: 48.7684016, lng: 18.3887112 },
    { lat: 48.768457, lng: 18.3880901 },
    { lat: 48.7684261, lng: 18.3873337 },
    { lat: 48.7684465, lng: 18.3868319 },
    { lat: 48.7686712, lng: 18.3862066 },
    { lat: 48.7689028, lng: 18.3854847 },
    { lat: 48.7689633, lng: 18.3851547 },
    { lat: 48.7688838, lng: 18.3849835 },
    { lat: 48.7690295, lng: 18.3850071 },
    { lat: 48.7690999, lng: 18.3849767 },
    { lat: 48.7687475, lng: 18.3837539 },
    { lat: 48.7687217, lng: 18.3837572 },
    { lat: 48.7683867, lng: 18.3825848 },
    { lat: 48.7682074, lng: 18.3818223 },
    { lat: 48.7681541, lng: 18.3811092 },
    { lat: 48.7681454, lng: 18.3806315 },
    { lat: 48.7682064, lng: 18.3802095 },
    { lat: 48.7683889, lng: 18.3794348 },
    { lat: 48.768478, lng: 18.3791716 },
    { lat: 48.7688385, lng: 18.3785375 },
    { lat: 48.7688924, lng: 18.3784516 },
    { lat: 48.7689374, lng: 18.3778876 },
    { lat: 48.7689299, lng: 18.3771872 },
    { lat: 48.7688983, lng: 18.3766806 },
    { lat: 48.7687184, lng: 18.3757455 },
    { lat: 48.7686328, lng: 18.3754038 },
    { lat: 48.7685228, lng: 18.3751536 },
    { lat: 48.7683619, lng: 18.3748584 },
    { lat: 48.76828, lng: 18.3746457 },
    { lat: 48.7682031, lng: 18.374319 },
    { lat: 48.7681281, lng: 18.3743257 },
    { lat: 48.767898, lng: 18.3745701 },
    { lat: 48.7678564, lng: 18.3745737 },
    { lat: 48.7678522, lng: 18.3739337 },
    { lat: 48.7679491, lng: 18.3736284 },
    { lat: 48.7680566, lng: 18.3733739 },
    { lat: 48.7680763, lng: 18.3732613 },
    { lat: 48.7681753, lng: 18.3729503 },
    { lat: 48.7682036, lng: 18.3729546 },
    { lat: 48.7682214, lng: 18.3728711 },
    { lat: 48.768155, lng: 18.3728721 },
    { lat: 48.7681673, lng: 18.3726993 },
    { lat: 48.7679025, lng: 18.3725332 },
    { lat: 48.767732, lng: 18.3724306 },
    { lat: 48.7675513, lng: 18.3724258 },
    { lat: 48.7673098, lng: 18.3725286 },
    { lat: 48.7672368, lng: 18.3723102 },
    { lat: 48.7672655, lng: 18.3722403 },
    { lat: 48.7673429, lng: 18.37219 },
    { lat: 48.767432, lng: 18.3720895 },
    { lat: 48.7675227, lng: 18.3720572 },
    { lat: 48.7675787, lng: 18.3719903 },
    { lat: 48.7676278, lng: 18.371959 },
    { lat: 48.767524, lng: 18.3717394 },
    { lat: 48.7674156, lng: 18.3717569 },
    { lat: 48.767179, lng: 18.3718634 },
    { lat: 48.7669844, lng: 18.3716898 },
    { lat: 48.766941, lng: 18.3716628 },
    { lat: 48.7669512, lng: 18.3713808 },
    { lat: 48.7669732, lng: 18.3711691 },
    { lat: 48.7667035, lng: 18.3710052 },
    { lat: 48.7665944, lng: 18.3709308 },
    { lat: 48.7664071, lng: 18.3707488 },
    { lat: 48.7662257, lng: 18.3704934 },
    { lat: 48.7660107, lng: 18.3701798 },
    { lat: 48.7657652, lng: 18.3699037 },
    { lat: 48.7656475, lng: 18.3697342 },
    { lat: 48.7655544, lng: 18.3696253 },
    { lat: 48.7655397, lng: 18.3695835 },
    { lat: 48.7653426, lng: 18.369631 },
    { lat: 48.7650747, lng: 18.3696596 },
    { lat: 48.7647887, lng: 18.3696722 },
    { lat: 48.7647989, lng: 18.3693695 },
    { lat: 48.7647721, lng: 18.3688687 },
    { lat: 48.7646766, lng: 18.3688366 },
    { lat: 48.7643196, lng: 18.3686084 },
    { lat: 48.7641053, lng: 18.3691121 },
    { lat: 48.7640814, lng: 18.3692093 },
    { lat: 48.7640741, lng: 18.3694208 },
    { lat: 48.7640668, lng: 18.3694514 },
    { lat: 48.7638909, lng: 18.3694765 },
    { lat: 48.7633875, lng: 18.3693893 },
    { lat: 48.7630573, lng: 18.3693147 },
    { lat: 48.7626301, lng: 18.3693612 },
    { lat: 48.7626821, lng: 18.3694457 },
    { lat: 48.7623653, lng: 18.3695095 },
    { lat: 48.7622247, lng: 18.3692948 },
    { lat: 48.7622273, lng: 18.3686568 },
    { lat: 48.7619821, lng: 18.3683857 },
    { lat: 48.7617911, lng: 18.3681213 },
    { lat: 48.7615651, lng: 18.3680526 },
    { lat: 48.7612356, lng: 18.3685357 },
    { lat: 48.7612258, lng: 18.3685795 },
    { lat: 48.7611346, lng: 18.3696915 },
    { lat: 48.7609992, lng: 18.3699541 },
    { lat: 48.7608, lng: 18.3702264 },
    { lat: 48.7607014, lng: 18.3702558 },
    { lat: 48.7605795, lng: 18.3705396 },
    { lat: 48.7604295, lng: 18.3715064 },
    { lat: 48.759889, lng: 18.3726358 },
    { lat: 48.7595475, lng: 18.3733271 },
    { lat: 48.7591286, lng: 18.3741266 },
    { lat: 48.7587936, lng: 18.3748954 },
    { lat: 48.7579353, lng: 18.3742926 },
    { lat: 48.756904, lng: 18.3735647 },
    { lat: 48.7564725, lng: 18.3732731 },
    { lat: 48.756139, lng: 18.3730208 },
    { lat: 48.7557962, lng: 18.373912 },
    { lat: 48.7552864, lng: 18.3745193 },
    { lat: 48.7549243, lng: 18.3750019 },
    { lat: 48.7544709, lng: 18.3756702 },
    { lat: 48.7539397, lng: 18.376017 },
    { lat: 48.7538134, lng: 18.3762784 },
    { lat: 48.7534505, lng: 18.3771529 },
    { lat: 48.7529072, lng: 18.3790583 },
    { lat: 48.7522812, lng: 18.3796253 },
    { lat: 48.7513162, lng: 18.3797952 },
    { lat: 48.7509178, lng: 18.379913 },
    { lat: 48.7504198, lng: 18.3802916 },
    { lat: 48.7500534, lng: 18.380578 },
    { lat: 48.7496827, lng: 18.3808677 },
    { lat: 48.7487937, lng: 18.3818983 },
    { lat: 48.7481954, lng: 18.3819541 },
    { lat: 48.7476431, lng: 18.3824851 },
    { lat: 48.7473459, lng: 18.3832427 },
    { lat: 48.7465957, lng: 18.3839902 },
    { lat: 48.7456992, lng: 18.3848051 },
    { lat: 48.7449482, lng: 18.3855016 },
    { lat: 48.7447158, lng: 18.3867913 },
    { lat: 48.7444551, lng: 18.3883885 },
    { lat: 48.7444829, lng: 18.3895088 },
    { lat: 48.7442589, lng: 18.3906934 },
    { lat: 48.7441967, lng: 18.3914803 },
    { lat: 48.7437256, lng: 18.3923547 },
    { lat: 48.743159, lng: 18.3932613 },
    { lat: 48.7424145, lng: 18.3940111 },
    { lat: 48.7418469, lng: 18.3946178 },
    { lat: 48.7415162, lng: 18.3949956 },
    { lat: 48.741411, lng: 18.395086 },
    { lat: 48.741543, lng: 18.395396 },
    { lat: 48.741582, lng: 18.395676 },
    { lat: 48.741573, lng: 18.396138 },
    { lat: 48.741405, lng: 18.396933 },
    { lat: 48.741301, lng: 18.397259 },
    { lat: 48.741299, lng: 18.39775 },
    { lat: 48.741289, lng: 18.398433 },
    { lat: 48.741036, lng: 18.399457 },
    { lat: 48.740676, lng: 18.400176 },
    { lat: 48.740489, lng: 18.40051 },
    { lat: 48.739964, lng: 18.40101 },
    { lat: 48.7399573, lng: 18.4012742 },
    { lat: 48.739952, lng: 18.401481 },
    { lat: 48.739603, lng: 18.402695 },
    { lat: 48.739579, lng: 18.40299 },
    { lat: 48.7396, lng: 18.403152 },
    { lat: 48.739804, lng: 18.404048 },
    { lat: 48.739633, lng: 18.40552 },
    { lat: 48.739455, lng: 18.406067 },
    { lat: 48.739101, lng: 18.40657 },
    { lat: 48.738917, lng: 18.407203 },
    { lat: 48.739288, lng: 18.409027 },
    { lat: 48.739367, lng: 18.409376 },
    { lat: 48.739369, lng: 18.409954 },
    { lat: 48.739713, lng: 18.410541 },
    { lat: 48.740038, lng: 18.411194 },
    { lat: 48.740294, lng: 18.411531 },
    { lat: 48.740845, lng: 18.411824 },
  ],
  Kšinná: [
    { lat: 48.841043, lng: 18.419551 },
    { lat: 48.841232, lng: 18.41931 },
    { lat: 48.841549, lng: 18.419192 },
    { lat: 48.841904, lng: 18.419032 },
    { lat: 48.842356, lng: 18.418772 },
    { lat: 48.84302, lng: 18.418509 },
    { lat: 48.843335, lng: 18.418236 },
    { lat: 48.843958, lng: 18.41787 },
    { lat: 48.844451, lng: 18.417495 },
    { lat: 48.844764, lng: 18.417293 },
    { lat: 48.845095, lng: 18.417207 },
    { lat: 48.845604, lng: 18.417449 },
    { lat: 48.846249, lng: 18.417823 },
    { lat: 48.846359, lng: 18.41784 },
    { lat: 48.846887, lng: 18.417904 },
    { lat: 48.847257, lng: 18.417808 },
    { lat: 48.847682, lng: 18.417762 },
    { lat: 48.847912, lng: 18.417635 },
    { lat: 48.848083, lng: 18.417362 },
    { lat: 48.848594, lng: 18.417307 },
    { lat: 48.84867, lng: 18.41742 },
    { lat: 48.848628, lng: 18.416783 },
    { lat: 48.849054, lng: 18.416037 },
    { lat: 48.849191, lng: 18.415463 },
    { lat: 48.849644, lng: 18.41413 },
    { lat: 48.849807, lng: 18.413576 },
    { lat: 48.850164, lng: 18.412568 },
    { lat: 48.850406, lng: 18.412155 },
    { lat: 48.850486, lng: 18.412046 },
    { lat: 48.85121, lng: 18.411428 },
    { lat: 48.851664, lng: 18.411154 },
    { lat: 48.85221, lng: 18.410865 },
    { lat: 48.852761, lng: 18.410696 },
    { lat: 48.853515, lng: 18.410216 },
    { lat: 48.854159, lng: 18.409638 },
    { lat: 48.854683, lng: 18.409325 },
    { lat: 48.854961, lng: 18.409031 },
    { lat: 48.855019, lng: 18.408824 },
    { lat: 48.855056, lng: 18.408499 },
    { lat: 48.855002, lng: 18.407902 },
    { lat: 48.855049, lng: 18.407118 },
    { lat: 48.855122, lng: 18.406632 },
    { lat: 48.855413, lng: 18.404355 },
    { lat: 48.856064, lng: 18.402728 },
    { lat: 48.855961, lng: 18.402045 },
    { lat: 48.855635, lng: 18.400902 },
    { lat: 48.855483, lng: 18.399773 },
    { lat: 48.855715, lng: 18.398436 },
    { lat: 48.855956, lng: 18.397581 },
    { lat: 48.856117, lng: 18.396789 },
    { lat: 48.856542, lng: 18.396156 },
    { lat: 48.856954, lng: 18.395702 },
    { lat: 48.857193, lng: 18.395369 },
    { lat: 48.85778, lng: 18.394965 },
    { lat: 48.858091, lng: 18.394775 },
    { lat: 48.858626, lng: 18.394658 },
    { lat: 48.859435, lng: 18.394408 },
    { lat: 48.8600172, lng: 18.3945316 },
    { lat: 48.86058, lng: 18.394651 },
    { lat: 48.862013, lng: 18.392607 },
    { lat: 48.862543, lng: 18.391137 },
    { lat: 48.863386, lng: 18.388544 },
    { lat: 48.863705, lng: 18.386135 },
    { lat: 48.863805, lng: 18.384813 },
    { lat: 48.864114, lng: 18.382429 },
    { lat: 48.862963, lng: 18.378696 },
    { lat: 48.863002, lng: 18.37752 },
    { lat: 48.863331, lng: 18.376109 },
    { lat: 48.863317, lng: 18.375882 },
    { lat: 48.863248, lng: 18.374968 },
    { lat: 48.863232, lng: 18.37475 },
    { lat: 48.863213, lng: 18.374491 },
    { lat: 48.863197, lng: 18.374251 },
    { lat: 48.863194, lng: 18.374218 },
    { lat: 48.863203, lng: 18.374027 },
    { lat: 48.863252, lng: 18.373712 },
    { lat: 48.863343, lng: 18.373131 },
    { lat: 48.863401, lng: 18.372738 },
    { lat: 48.863436, lng: 18.372523 },
    { lat: 48.86345, lng: 18.372433 },
    { lat: 48.8632703, lng: 18.3710164 },
    { lat: 48.8625967, lng: 18.3696804 },
    { lat: 48.8625953, lng: 18.3689317 },
    { lat: 48.8631325, lng: 18.3685313 },
    { lat: 48.863545, lng: 18.3675972 },
    { lat: 48.8634075, lng: 18.3658803 },
    { lat: 48.8632437, lng: 18.3649511 },
    { lat: 48.8625579, lng: 18.3637209 },
    { lat: 48.8622096, lng: 18.3627173 },
    { lat: 48.8619225, lng: 18.362264 },
    { lat: 48.8614634, lng: 18.3618027 },
    { lat: 48.8609767, lng: 18.3612672 },
    { lat: 48.8607076, lng: 18.3610979 },
    { lat: 48.8602988, lng: 18.3610495 },
    { lat: 48.8595747, lng: 18.3606491 },
    { lat: 48.8593724, lng: 18.3605401 },
    { lat: 48.8583998, lng: 18.3593702 },
    { lat: 48.8577371, lng: 18.3588581 },
    { lat: 48.857524, lng: 18.3587587 },
    { lat: 48.8567408, lng: 18.3583933 },
    { lat: 48.8560787, lng: 18.3583347 },
    { lat: 48.8555067, lng: 18.3581714 },
    { lat: 48.8552142, lng: 18.3579472 },
    { lat: 48.8539366, lng: 18.3567905 },
    { lat: 48.8535841, lng: 18.356359 },
    { lat: 48.853145, lng: 18.3554282 },
    { lat: 48.8524082, lng: 18.3536536 },
    { lat: 48.8514158, lng: 18.3517895 },
    { lat: 48.8506361, lng: 18.3507983 },
    { lat: 48.8498867, lng: 18.3503506 },
    { lat: 48.8495995, lng: 18.3491115 },
    { lat: 48.8495212, lng: 18.3487605 },
    { lat: 48.849511, lng: 18.3486882 },
    { lat: 48.8494148, lng: 18.3480101 },
    { lat: 48.8493921, lng: 18.3478917 },
    { lat: 48.8493078, lng: 18.3474515 },
    { lat: 48.8489804, lng: 18.3462216 },
    { lat: 48.8487808, lng: 18.3451364 },
    { lat: 48.8485821, lng: 18.3445702 },
    { lat: 48.8481511, lng: 18.3438649 },
    { lat: 48.847692, lng: 18.3431421 },
    { lat: 48.8473539, lng: 18.3419226 },
    { lat: 48.8460161, lng: 18.3410229 },
    { lat: 48.8451293, lng: 18.3405386 },
    { lat: 48.8440425, lng: 18.3399329 },
    { lat: 48.8436173, lng: 18.3391295 },
    { lat: 48.8431242, lng: 18.3382858 },
    { lat: 48.8428828, lng: 18.3380666 },
    { lat: 48.8420312, lng: 18.3373662 },
    { lat: 48.8406304, lng: 18.3375554 },
    { lat: 48.8399261, lng: 18.3374667 },
    { lat: 48.8391116, lng: 18.3370277 },
    { lat: 48.838462, lng: 18.3372741 },
    { lat: 48.8381241, lng: 18.3375418 },
    { lat: 48.8375999, lng: 18.3376134 },
    { lat: 48.8372383, lng: 18.3376399 },
    { lat: 48.8370065, lng: 18.3375531 },
    { lat: 48.8358461, lng: 18.3368367 },
    { lat: 48.835513, lng: 18.3363543 },
    { lat: 48.8352528, lng: 18.3360766 },
    { lat: 48.8345041, lng: 18.3352624 },
    { lat: 48.8339482, lng: 18.3343858 },
    { lat: 48.8332974, lng: 18.3333063 },
    { lat: 48.8326167, lng: 18.3318531 },
    { lat: 48.8322518, lng: 18.3312048 },
    { lat: 48.8316301, lng: 18.3319632 },
    { lat: 48.8305092, lng: 18.3322346 },
    { lat: 48.8300709, lng: 18.332665 },
    { lat: 48.829745, lng: 18.3323085 },
    { lat: 48.8296265, lng: 18.3323345 },
    { lat: 48.8294085, lng: 18.3320801 },
    { lat: 48.8292712, lng: 18.3321319 },
    { lat: 48.8291593, lng: 18.3321055 },
    { lat: 48.8289789, lng: 18.3320675 },
    { lat: 48.8288604, lng: 18.3319657 },
    { lat: 48.8286327, lng: 18.3318103 },
    { lat: 48.8284727, lng: 18.3317314 },
    { lat: 48.8282728, lng: 18.331729 },
    { lat: 48.8280815, lng: 18.331678 },
    { lat: 48.8279046, lng: 18.3317019 },
    { lat: 48.8278355, lng: 18.3316333 },
    { lat: 48.8277221, lng: 18.3316635 },
    { lat: 48.8274627, lng: 18.3314653 },
    { lat: 48.8273228, lng: 18.3311267 },
    { lat: 48.8270984, lng: 18.3307056 },
    { lat: 48.8269694, lng: 18.3304794 },
    { lat: 48.8268634, lng: 18.3304681 },
    { lat: 48.8268105, lng: 18.3303023 },
    { lat: 48.8266037, lng: 18.33031 },
    { lat: 48.8262757, lng: 18.3302807 },
    { lat: 48.8254779, lng: 18.3304384 },
    { lat: 48.825141, lng: 18.3290228 },
    { lat: 48.8249483, lng: 18.3283882 },
    { lat: 48.8248296, lng: 18.3279584 },
    { lat: 48.8246016, lng: 18.3271554 },
    { lat: 48.8241657, lng: 18.3261694 },
    { lat: 48.8239872, lng: 18.325564 },
    { lat: 48.8230125, lng: 18.3250229 },
    { lat: 48.822344, lng: 18.3245496 },
    { lat: 48.821907, lng: 18.3241825 },
    { lat: 48.8216065, lng: 18.3239778 },
    { lat: 48.8213287, lng: 18.3238099 },
    { lat: 48.8205647, lng: 18.3234468 },
    { lat: 48.8201952, lng: 18.3233366 },
    { lat: 48.8196405, lng: 18.323366 },
    { lat: 48.8189611, lng: 18.3237624 },
    { lat: 48.8186594, lng: 18.3234129 },
    { lat: 48.8182673, lng: 18.3226795 },
    { lat: 48.8177256, lng: 18.3219941 },
    { lat: 48.8171032, lng: 18.3218464 },
    { lat: 48.8165364, lng: 18.3213955 },
    { lat: 48.8157895, lng: 18.3209536 },
    { lat: 48.8156232, lng: 18.3208251 },
    { lat: 48.81519, lng: 18.3202657 },
    { lat: 48.8148205, lng: 18.3200775 },
    { lat: 48.8143761, lng: 18.3202971 },
    { lat: 48.8139407, lng: 18.3204632 },
    { lat: 48.8134211, lng: 18.3202912 },
    { lat: 48.8130036, lng: 18.3190887 },
    { lat: 48.8125407, lng: 18.318182 },
    { lat: 48.8121612, lng: 18.3181151 },
    { lat: 48.8117248, lng: 18.3180566 },
    { lat: 48.8112113, lng: 18.3184347 },
    { lat: 48.811072, lng: 18.318365 },
    { lat: 48.8107664, lng: 18.3180286 },
    { lat: 48.8103923, lng: 18.3181779 },
    { lat: 48.8104013, lng: 18.318698 },
    { lat: 48.8100449, lng: 18.318817 },
    { lat: 48.8094711, lng: 18.3185494 },
    { lat: 48.8089487, lng: 18.3181487 },
    { lat: 48.8082046, lng: 18.3180537 },
    { lat: 48.807618, lng: 18.3175965 },
    { lat: 48.8074115, lng: 18.3173555 },
    { lat: 48.8066285, lng: 18.3166177 },
    { lat: 48.8063893, lng: 18.3165151 },
    { lat: 48.8061334, lng: 18.3166012 },
    { lat: 48.8059052, lng: 18.316936 },
    { lat: 48.8059173, lng: 18.316991 },
    { lat: 48.8059133, lng: 18.3170792 },
    { lat: 48.8059692, lng: 18.3180491 },
    { lat: 48.8056419, lng: 18.3185674 },
    { lat: 48.8050862, lng: 18.3191578 },
    { lat: 48.8048155, lng: 18.3196184 },
    { lat: 48.8046248, lng: 18.3199974 },
    { lat: 48.804147, lng: 18.3205512 },
    { lat: 48.8039104, lng: 18.3209354 },
    { lat: 48.8037802, lng: 18.3214103 },
    { lat: 48.8035905, lng: 18.3216063 },
    { lat: 48.8030642, lng: 18.3222759 },
    { lat: 48.8029375, lng: 18.3223907 },
    { lat: 48.8025824, lng: 18.3231362 },
    { lat: 48.8024628, lng: 18.3237805 },
    { lat: 48.8023654, lng: 18.3250353 },
    { lat: 48.8022731, lng: 18.3256202 },
    { lat: 48.8021324, lng: 18.3264272 },
    { lat: 48.8017068, lng: 18.3269846 },
    { lat: 48.8012515, lng: 18.3275522 },
    { lat: 48.8007458, lng: 18.327849 },
    { lat: 48.8002205, lng: 18.3281784 },
    { lat: 48.7995195, lng: 18.3286317 },
    { lat: 48.7986721, lng: 18.3293516 },
    { lat: 48.7982676, lng: 18.3296206 },
    { lat: 48.7984358, lng: 18.3304252 },
    { lat: 48.7977788, lng: 18.3315818 },
    { lat: 48.7975417, lng: 18.3322998 },
    { lat: 48.7975486, lng: 18.3327627 },
    { lat: 48.7973623, lng: 18.3335239 },
    { lat: 48.7963532, lng: 18.3339982 },
    { lat: 48.7959412, lng: 18.3344142 },
    { lat: 48.7961403, lng: 18.3358074 },
    { lat: 48.7961485, lng: 18.3359144 },
    { lat: 48.7960913, lng: 18.3369631 },
    { lat: 48.795821, lng: 18.3378138 },
    { lat: 48.7955788, lng: 18.3382418 },
    { lat: 48.7950571, lng: 18.3386554 },
    { lat: 48.7947088, lng: 18.338881 },
    { lat: 48.7946456, lng: 18.3389428 },
    { lat: 48.7944807, lng: 18.3390374 },
    { lat: 48.7940537, lng: 18.3392684 },
    { lat: 48.7929484, lng: 18.3400588 },
    { lat: 48.7929737, lng: 18.3401475 },
    { lat: 48.7926964, lng: 18.3404923 },
    { lat: 48.7924084, lng: 18.3407098 },
    { lat: 48.7920598, lng: 18.341213 },
    { lat: 48.791858, lng: 18.341454 },
    { lat: 48.7914665, lng: 18.3420175 },
    { lat: 48.7911887, lng: 18.3425725 },
    { lat: 48.7908606, lng: 18.3437446 },
    { lat: 48.7907908, lng: 18.3440463 },
    { lat: 48.7907952, lng: 18.344668 },
    { lat: 48.7905069, lng: 18.3452385 },
    { lat: 48.7903701, lng: 18.3458154 },
    { lat: 48.7897836, lng: 18.3463005 },
    { lat: 48.7891178, lng: 18.3480481 },
    { lat: 48.7891227, lng: 18.3489387 },
    { lat: 48.7890958, lng: 18.3490706 },
    { lat: 48.7891333, lng: 18.349069 },
    { lat: 48.7893332, lng: 18.3486512 },
    { lat: 48.7894919, lng: 18.3485843 },
    { lat: 48.7896335, lng: 18.3486348 },
    { lat: 48.7897233, lng: 18.348845 },
    { lat: 48.7898703, lng: 18.3490537 },
    { lat: 48.7900393, lng: 18.3490066 },
    { lat: 48.7900961, lng: 18.349215 },
    { lat: 48.7901217, lng: 18.3494515 },
    { lat: 48.790203, lng: 18.3495137 },
    { lat: 48.7907274, lng: 18.3495493 },
    { lat: 48.7910628, lng: 18.3494933 },
    { lat: 48.7911301, lng: 18.3495079 },
    { lat: 48.7912169, lng: 18.3495883 },
    { lat: 48.7913989, lng: 18.3497946 },
    { lat: 48.7915767, lng: 18.35017 },
    { lat: 48.7915921, lng: 18.3502418 },
    { lat: 48.7918445, lng: 18.3506194 },
    { lat: 48.7919344, lng: 18.3508533 },
    { lat: 48.7922393, lng: 18.3511 },
    { lat: 48.7922841, lng: 18.3511937 },
    { lat: 48.7923779, lng: 18.3514382 },
    { lat: 48.7924512, lng: 18.3515984 },
    { lat: 48.7925217, lng: 18.3517296 },
    { lat: 48.7926859, lng: 18.3519421 },
    { lat: 48.7927539, lng: 18.3520464 },
    { lat: 48.7929316, lng: 18.3525811 },
    { lat: 48.7930372, lng: 18.3528323 },
    { lat: 48.7930633, lng: 18.3530562 },
    { lat: 48.7931203, lng: 18.3532675 },
    { lat: 48.7930978, lng: 18.353533 },
    { lat: 48.7931575, lng: 18.3537719 },
    { lat: 48.7933565, lng: 18.3540186 },
    { lat: 48.7934204, lng: 18.3541764 },
    { lat: 48.7934344, lng: 18.3546455 },
    { lat: 48.7933787, lng: 18.3552628 },
    { lat: 48.7933714, lng: 18.355511 },
    { lat: 48.7933486, lng: 18.3557128 },
    { lat: 48.7934147, lng: 18.3561666 },
    { lat: 48.7932168, lng: 18.3563971 },
    { lat: 48.793039, lng: 18.356747 },
    { lat: 48.792984, lng: 18.3569931 },
    { lat: 48.7929616, lng: 18.3572006 },
    { lat: 48.7929747, lng: 18.3575957 },
    { lat: 48.7927979, lng: 18.3581434 },
    { lat: 48.7927631, lng: 18.3581861 },
    { lat: 48.7928699, lng: 18.3584833 },
    { lat: 48.7928625, lng: 18.3587319 },
    { lat: 48.7927879, lng: 18.3592149 },
    { lat: 48.7927799, lng: 18.3595952 },
    { lat: 48.7928159, lng: 18.3599956 },
    { lat: 48.7927811, lng: 18.3600802 },
    { lat: 48.7927444, lng: 18.3604084 },
    { lat: 48.7925662, lng: 18.3603114 },
    { lat: 48.7923892, lng: 18.3603134 },
    { lat: 48.7921243, lng: 18.3605177 },
    { lat: 48.7916409, lng: 18.3611143 },
    { lat: 48.7914722, lng: 18.3613019 },
    { lat: 48.7914104, lng: 18.3615281 },
    { lat: 48.7912761, lng: 18.3618531 },
    { lat: 48.791176, lng: 18.3620577 },
    { lat: 48.790985, lng: 18.3623534 },
    { lat: 48.7904632, lng: 18.3629893 },
    { lat: 48.7903187, lng: 18.3631874 },
    { lat: 48.7904798, lng: 18.3640079 },
    { lat: 48.7905084, lng: 18.3640587 },
    { lat: 48.7905873, lng: 18.3641537 },
    { lat: 48.7906612, lng: 18.364608 },
    { lat: 48.7910205, lng: 18.3651021 },
    { lat: 48.7910256, lng: 18.3651931 },
    { lat: 48.7908449, lng: 18.3656936 },
    { lat: 48.7903209, lng: 18.3668324 },
    { lat: 48.7903485, lng: 18.3670843 },
    { lat: 48.790252, lng: 18.3671124 },
    { lat: 48.7902185, lng: 18.3673217 },
    { lat: 48.7899802, lng: 18.3677823 },
    { lat: 48.7899879, lng: 18.3678386 },
    { lat: 48.7896735, lng: 18.368529 },
    { lat: 48.7894898, lng: 18.3688359 },
    { lat: 48.7894358, lng: 18.3689988 },
    { lat: 48.7893036, lng: 18.3690369 },
    { lat: 48.7892079, lng: 18.3691601 },
    { lat: 48.7891884, lng: 18.3694162 },
    { lat: 48.7890961, lng: 18.3697436 },
    { lat: 48.7891607, lng: 18.3697486 },
    { lat: 48.7895953, lng: 18.3699537 },
    { lat: 48.790013, lng: 18.3703624 },
    { lat: 48.790526, lng: 18.3708817 },
    { lat: 48.7909717, lng: 18.3713484 },
    { lat: 48.7915154, lng: 18.3716888 },
    { lat: 48.7917021, lng: 18.3719003 },
    { lat: 48.7920089, lng: 18.3722617 },
    { lat: 48.7922751, lng: 18.3725411 },
    { lat: 48.7928838, lng: 18.3727991 },
    { lat: 48.7931647, lng: 18.3728576 },
    { lat: 48.7939012, lng: 18.373033 },
    { lat: 48.7941019, lng: 18.3731941 },
    { lat: 48.7942127, lng: 18.3732597 },
    { lat: 48.7944011, lng: 18.3741061 },
    { lat: 48.7943827, lng: 18.3754339 },
    { lat: 48.794197, lng: 18.3762058 },
    { lat: 48.7943211, lng: 18.3770555 },
    { lat: 48.7945457, lng: 18.3774411 },
    { lat: 48.7948425, lng: 18.3780476 },
    { lat: 48.7949961, lng: 18.3792227 },
    { lat: 48.7950137, lng: 18.3799411 },
    { lat: 48.7951884, lng: 18.3804018 },
    { lat: 48.7954819, lng: 18.3811318 },
    { lat: 48.7956935, lng: 18.381445 },
    { lat: 48.7961582, lng: 18.3823552 },
    { lat: 48.7960871, lng: 18.3834432 },
    { lat: 48.7960426, lng: 18.3839008 },
    { lat: 48.7959244, lng: 18.3843321 },
    { lat: 48.795697, lng: 18.3854726 },
    { lat: 48.7956461, lng: 18.3859325 },
    { lat: 48.7955158, lng: 18.3864479 },
    { lat: 48.7958123, lng: 18.3866068 },
    { lat: 48.796062, lng: 18.3867549 },
    { lat: 48.7964041, lng: 18.387045 },
    { lat: 48.7969117, lng: 18.3874471 },
    { lat: 48.7973431, lng: 18.3875576 },
    { lat: 48.7977839, lng: 18.3879325 },
    { lat: 48.7981859, lng: 18.388527 },
    { lat: 48.7984423, lng: 18.3886954 },
    { lat: 48.7988391, lng: 18.3891768 },
    { lat: 48.7990194, lng: 18.3894933 },
    { lat: 48.7993798, lng: 18.3897883 },
    { lat: 48.7995532, lng: 18.3899644 },
    { lat: 48.7999553, lng: 18.3900526 },
    { lat: 48.8002833, lng: 18.3902283 },
    { lat: 48.800556, lng: 18.3904092 },
    { lat: 48.8008438, lng: 18.3905718 },
    { lat: 48.8013474, lng: 18.3909834 },
    { lat: 48.802201, lng: 18.3916012 },
    { lat: 48.8022755, lng: 18.39217 },
    { lat: 48.8022271, lng: 18.3924342 },
    { lat: 48.8023645, lng: 18.3935257 },
    { lat: 48.8026813, lng: 18.3937418 },
    { lat: 48.8032558, lng: 18.3947406 },
    { lat: 48.8030547, lng: 18.3956664 },
    { lat: 48.8031349, lng: 18.3965376 },
    { lat: 48.8032633, lng: 18.3971826 },
    { lat: 48.8037486, lng: 18.3977129 },
    { lat: 48.8040712, lng: 18.3979165 },
    { lat: 48.8044485, lng: 18.3978222 },
    { lat: 48.8048358, lng: 18.3979503 },
    { lat: 48.8050848, lng: 18.3979049 },
    { lat: 48.8054194, lng: 18.3982587 },
    { lat: 48.8056487, lng: 18.3985913 },
    { lat: 48.8059875, lng: 18.3986966 },
    { lat: 48.8064071, lng: 18.3992661 },
    { lat: 48.8064921, lng: 18.4001647 },
    { lat: 48.8066216, lng: 18.4007079 },
    { lat: 48.8062674, lng: 18.4015602 },
    { lat: 48.8063164, lng: 18.4021763 },
    { lat: 48.8066546, lng: 18.4029181 },
    { lat: 48.8067891, lng: 18.4038368 },
    { lat: 48.8068169, lng: 18.4042978 },
    { lat: 48.8067414, lng: 18.4054946 },
    { lat: 48.806683, lng: 18.4061488 },
    { lat: 48.8068619, lng: 18.4072372 },
    { lat: 48.8069897, lng: 18.4073032 },
    { lat: 48.8072597, lng: 18.4077366 },
    { lat: 48.807528, lng: 18.408631 },
    { lat: 48.807894, lng: 18.409226 },
    { lat: 48.80807, lng: 18.409562 },
    { lat: 48.80837, lng: 18.410176 },
    { lat: 48.808814, lng: 18.410998 },
    { lat: 48.809086, lng: 18.411259 },
    { lat: 48.809225, lng: 18.411528 },
    { lat: 48.809495, lng: 18.411749 },
    { lat: 48.809585, lng: 18.411886 },
    { lat: 48.809845, lng: 18.411954 },
    { lat: 48.810005, lng: 18.412067 },
    { lat: 48.810957, lng: 18.411557 },
    { lat: 48.811694, lng: 18.411298 },
    { lat: 48.811958, lng: 18.411133 },
    { lat: 48.8122, lng: 18.41098 },
    { lat: 48.812389, lng: 18.411046 },
    { lat: 48.812587, lng: 18.410975 },
    { lat: 48.812694, lng: 18.410937 },
    { lat: 48.812935, lng: 18.410763 },
    { lat: 48.813104, lng: 18.410526 },
    { lat: 48.814211, lng: 18.409667 },
    { lat: 48.814503, lng: 18.40935 },
    { lat: 48.814701, lng: 18.409185 },
    { lat: 48.814792, lng: 18.409144 },
    { lat: 48.815007, lng: 18.408899 },
    { lat: 48.815205, lng: 18.408633 },
    { lat: 48.815392, lng: 18.408374 },
    { lat: 48.8155, lng: 18.40825 },
    { lat: 48.815568, lng: 18.408173 },
    { lat: 48.815791, lng: 18.40809 },
    { lat: 48.815894, lng: 18.40798 },
    { lat: 48.816074, lng: 18.407848 },
    { lat: 48.816664, lng: 18.407692 },
    { lat: 48.816849, lng: 18.407517 },
    { lat: 48.816959, lng: 18.407358 },
    { lat: 48.817084, lng: 18.4073 },
    { lat: 48.817193, lng: 18.407218 },
    { lat: 48.818344, lng: 18.407964 },
    { lat: 48.818398, lng: 18.408007 },
    { lat: 48.818444, lng: 18.408045 },
    { lat: 48.818769, lng: 18.408307 },
    { lat: 48.819409, lng: 18.408828 },
    { lat: 48.821332, lng: 18.410361 },
    { lat: 48.82204, lng: 18.410393 },
    { lat: 48.822778, lng: 18.409756 },
    { lat: 48.82311, lng: 18.409687 },
    { lat: 48.823579, lng: 18.409678 },
    { lat: 48.824206, lng: 18.409658 },
    { lat: 48.824691, lng: 18.41016 },
    { lat: 48.825324, lng: 18.410635 },
    { lat: 48.82551, lng: 18.41078 },
    { lat: 48.825579, lng: 18.410833 },
    { lat: 48.826048, lng: 18.411195 },
    { lat: 48.826404, lng: 18.411433 },
    { lat: 48.827045, lng: 18.411846 },
    { lat: 48.8276, lng: 18.412376 },
    { lat: 48.828469, lng: 18.413234 },
    { lat: 48.828779, lng: 18.412754 },
    { lat: 48.830558, lng: 18.413263 },
    { lat: 48.832428, lng: 18.413561 },
    { lat: 48.833223, lng: 18.413738 },
    { lat: 48.833892, lng: 18.413888 },
    { lat: 48.834067, lng: 18.414187 },
    { lat: 48.834185, lng: 18.414446 },
    { lat: 48.834391, lng: 18.414774 },
    { lat: 48.834531, lng: 18.415029 },
    { lat: 48.835029, lng: 18.415679 },
    { lat: 48.835748, lng: 18.416358 },
    { lat: 48.836192, lng: 18.416499 },
    { lat: 48.836601, lng: 18.416597 },
    { lat: 48.837096, lng: 18.416833 },
    { lat: 48.837701, lng: 18.417263 },
    { lat: 48.838244, lng: 18.41745 },
    { lat: 48.838757, lng: 18.417728 },
    { lat: 48.839086, lng: 18.418114 },
    { lat: 48.839181, lng: 18.418253 },
    { lat: 48.839423, lng: 18.418608 },
    { lat: 48.839784, lng: 18.419029 },
    { lat: 48.839958, lng: 18.419191 },
    { lat: 48.840359, lng: 18.41975 },
    { lat: 48.841043, lng: 18.419551 },
  ],
  VeľkéDržkovce: [
    { lat: 48.7194645, lng: 18.1881618 },
    { lat: 48.7195902, lng: 18.1884805 },
    { lat: 48.7196186, lng: 18.1885218 },
    { lat: 48.7218193, lng: 18.190839 },
    { lat: 48.7222199, lng: 18.1912301 },
    { lat: 48.7224717, lng: 18.1914883 },
    { lat: 48.7228131, lng: 18.1918522 },
    { lat: 48.7229249, lng: 18.1919508 },
    { lat: 48.7234652, lng: 18.1924398 },
    { lat: 48.7240657, lng: 18.1932057 },
    { lat: 48.7243494, lng: 18.1934879 },
    { lat: 48.7250504, lng: 18.1943153 },
    { lat: 48.7253119, lng: 18.1946644 },
    { lat: 48.7253996, lng: 18.1947547 },
    { lat: 48.7256375, lng: 18.1949272 },
    { lat: 48.7257663, lng: 18.1950496 },
    { lat: 48.7258373, lng: 18.1952219 },
    { lat: 48.7258383, lng: 18.1954369 },
    { lat: 48.7260517, lng: 18.1956369 },
    { lat: 48.7265735, lng: 18.1960962 },
    { lat: 48.7266123, lng: 18.1961197 },
    { lat: 48.7272203, lng: 18.1966674 },
    { lat: 48.7276134, lng: 18.1970067 },
    { lat: 48.7281898, lng: 18.1975318 },
    { lat: 48.729136, lng: 18.1983103 },
    { lat: 48.7298283, lng: 18.198926 },
    { lat: 48.7300222, lng: 18.1989423 },
    { lat: 48.7301659, lng: 18.1989084 },
    { lat: 48.7304632, lng: 18.1986635 },
    { lat: 48.7307163, lng: 18.1984754 },
    { lat: 48.7307738, lng: 18.1985368 },
    { lat: 48.7308225, lng: 18.1985417 },
    { lat: 48.73383, lng: 18.1990163 },
    { lat: 48.7343117, lng: 18.1990919 },
    { lat: 48.7344074, lng: 18.1991072 },
    { lat: 48.7344114, lng: 18.1990806 },
    { lat: 48.7345863, lng: 18.1991182 },
    { lat: 48.7350652, lng: 18.1992059 },
    { lat: 48.7361213, lng: 18.1994013 },
    { lat: 48.7361648, lng: 18.1994113 },
    { lat: 48.7360754, lng: 18.1984184 },
    { lat: 48.7359519, lng: 18.1976489 },
    { lat: 48.7357659, lng: 18.1968433 },
    { lat: 48.7355647, lng: 18.1959939 },
    { lat: 48.7354355, lng: 18.1953592 },
    { lat: 48.7353832, lng: 18.1950587 },
    { lat: 48.7353565, lng: 18.1947719 },
    { lat: 48.7353645, lng: 18.1943805 },
    { lat: 48.7354177, lng: 18.1936775 },
    { lat: 48.7354536, lng: 18.1933972 },
    { lat: 48.7356328, lng: 18.1926118 },
    { lat: 48.735698, lng: 18.1923326 },
    { lat: 48.7357442, lng: 18.1920741 },
    { lat: 48.7359942, lng: 18.1911264 },
    { lat: 48.7361542, lng: 18.1904941 },
    { lat: 48.7361893, lng: 18.1902028 },
    { lat: 48.7362152, lng: 18.1898687 },
    { lat: 48.7362192, lng: 18.1897616 },
    { lat: 48.736208, lng: 18.1892548 },
    { lat: 48.7361817, lng: 18.1885443 },
    { lat: 48.7361675, lng: 18.1882781 },
    { lat: 48.7361375, lng: 18.1878344 },
    { lat: 48.7361625, lng: 18.1875048 },
    { lat: 48.7362238, lng: 18.1871778 },
    { lat: 48.7365924, lng: 18.1858394 },
    { lat: 48.7367849, lng: 18.1846944 },
    { lat: 48.7369924, lng: 18.1836747 },
    { lat: 48.7371101, lng: 18.1836216 },
    { lat: 48.7374727, lng: 18.1826916 },
    { lat: 48.7377493, lng: 18.1819694 },
    { lat: 48.7380566, lng: 18.1811721 },
    { lat: 48.7381616, lng: 18.1808598 },
    { lat: 48.7382834, lng: 18.1804665 },
    { lat: 48.7384883, lng: 18.1798332 },
    { lat: 48.7386735, lng: 18.1793458 },
    { lat: 48.7387754, lng: 18.1790385 },
    { lat: 48.7388586, lng: 18.1785052 },
    { lat: 48.7389998, lng: 18.1779199 },
    { lat: 48.7392834, lng: 18.1774006 },
    { lat: 48.7394307, lng: 18.1771753 },
    { lat: 48.7397302, lng: 18.17675 },
    { lat: 48.7399109, lng: 18.1765224 },
    { lat: 48.7402427, lng: 18.1761129 },
    { lat: 48.7405747, lng: 18.1757073 },
    { lat: 48.7408122, lng: 18.1752859 },
    { lat: 48.7410307, lng: 18.1748899 },
    { lat: 48.7412959, lng: 18.1742646 },
    { lat: 48.7414338, lng: 18.1737405 },
    { lat: 48.7415364, lng: 18.1733062 },
    { lat: 48.7416656, lng: 18.1725951 },
    { lat: 48.741779, lng: 18.1719373 },
    { lat: 48.7419182, lng: 18.1712604 },
    { lat: 48.742001, lng: 18.170799 },
    { lat: 48.7421471, lng: 18.1702823 },
    { lat: 48.7423642, lng: 18.1695293 },
    { lat: 48.7424649, lng: 18.1692797 },
    { lat: 48.7425889, lng: 18.169023 },
    { lat: 48.7427957, lng: 18.1687758 },
    { lat: 48.7431231, lng: 18.1684834 },
    { lat: 48.7433415, lng: 18.1681824 },
    { lat: 48.7433746, lng: 18.1681098 },
    { lat: 48.7433831, lng: 18.1680797 },
    { lat: 48.7435591, lng: 18.1676885 },
    { lat: 48.7437035, lng: 18.167101 },
    { lat: 48.7437774, lng: 18.1666453 },
    { lat: 48.7439015, lng: 18.1655881 },
    { lat: 48.7438998, lng: 18.1653842 },
    { lat: 48.7439315, lng: 18.1643685 },
    { lat: 48.7438985, lng: 18.1639735 },
    { lat: 48.7438604, lng: 18.1639771 },
    { lat: 48.7438298, lng: 18.1635881 },
    { lat: 48.7436868, lng: 18.1628519 },
    { lat: 48.7436515, lng: 18.1623968 },
    { lat: 48.7436531, lng: 18.161857 },
    { lat: 48.743725, lng: 18.1613044 },
    { lat: 48.743956, lng: 18.160577 },
    { lat: 48.7440526, lng: 18.1602599 },
    { lat: 48.7441702, lng: 18.1599143 },
    { lat: 48.7443374, lng: 18.1594649 },
    { lat: 48.7443949, lng: 18.1593465 },
    { lat: 48.7444638, lng: 18.1590946 },
    { lat: 48.7446101, lng: 18.1583494 },
    { lat: 48.7447403, lng: 18.1574773 },
    { lat: 48.7447613, lng: 18.1570014 },
    { lat: 48.7447517, lng: 18.1563305 },
    { lat: 48.7447291, lng: 18.1557428 },
    { lat: 48.7447704, lng: 18.1552671 },
    { lat: 48.7448602, lng: 18.1546946 },
    { lat: 48.744847, lng: 18.1539193 },
    { lat: 48.7448384, lng: 18.1535579 },
    { lat: 48.7448741, lng: 18.1531416 },
    { lat: 48.7449336, lng: 18.1528048 },
    { lat: 48.7450253, lng: 18.1524566 },
    { lat: 48.7451893, lng: 18.1519564 },
    { lat: 48.7453631, lng: 18.1515283 },
    { lat: 48.7455598, lng: 18.1512823 },
    { lat: 48.745712, lng: 18.1509879 },
    { lat: 48.7461814, lng: 18.1492927 },
    { lat: 48.7462802, lng: 18.1488352 },
    { lat: 48.7463823, lng: 18.1485524 },
    { lat: 48.7465285, lng: 18.1482516 },
    { lat: 48.746649, lng: 18.1478788 },
    { lat: 48.7467532, lng: 18.1474559 },
    { lat: 48.746826, lng: 18.1470554 },
    { lat: 48.7468516, lng: 18.1467763 },
    { lat: 48.7468823, lng: 18.1461025 },
    { lat: 48.7469742, lng: 18.1453097 },
    { lat: 48.7470788, lng: 18.1448364 },
    { lat: 48.7472525, lng: 18.144154 },
    { lat: 48.7477762, lng: 18.1429496 },
    { lat: 48.7479674, lng: 18.1424712 },
    { lat: 48.7480063, lng: 18.1423462 },
    { lat: 48.748034, lng: 18.1423543 },
    { lat: 48.7481103, lng: 18.1419015 },
    { lat: 48.748205, lng: 18.1416047 },
    { lat: 48.7482693, lng: 18.1414696 },
    { lat: 48.7485815, lng: 18.1409172 },
    { lat: 48.7486495, lng: 18.1408452 },
    { lat: 48.7494347, lng: 18.140219 },
    { lat: 48.7496074, lng: 18.1400255 },
    { lat: 48.7496865, lng: 18.1398891 },
    { lat: 48.7498049, lng: 18.1396546 },
    { lat: 48.7498922, lng: 18.139412 },
    { lat: 48.7499996, lng: 18.1389679 },
    { lat: 48.7500986, lng: 18.1385907 },
    { lat: 48.7502504, lng: 18.1380767 },
    { lat: 48.7503037, lng: 18.1379241 },
    { lat: 48.7504778, lng: 18.1375627 },
    { lat: 48.7505927, lng: 18.137207 },
    { lat: 48.7507681, lng: 18.1368275 },
    { lat: 48.7509249, lng: 18.1364577 },
    { lat: 48.7509879, lng: 18.136218 },
    { lat: 48.7510372, lng: 18.1358624 },
    { lat: 48.7510983, lng: 18.1355532 },
    { lat: 48.7511884, lng: 18.1352809 },
    { lat: 48.7518324, lng: 18.1338364 },
    { lat: 48.7518701, lng: 18.13359 },
    { lat: 48.7519303, lng: 18.133307 },
    { lat: 48.7519255, lng: 18.132096 },
    { lat: 48.751937, lng: 18.131985 },
    { lat: 48.751783, lng: 18.131386 },
    { lat: 48.751745, lng: 18.131334 },
    { lat: 48.751344, lng: 18.130981 },
    { lat: 48.751165, lng: 18.13082 },
    { lat: 48.750695, lng: 18.130357 },
    { lat: 48.750495, lng: 18.130156 },
    { lat: 48.750003, lng: 18.129596 },
    { lat: 48.749647, lng: 18.129006 },
    { lat: 48.749281, lng: 18.128387 },
    { lat: 48.74928, lng: 18.12832 },
    { lat: 48.748899, lng: 18.127606 },
    { lat: 48.748667, lng: 18.127358 },
    { lat: 48.74815, lng: 18.127252 },
    { lat: 48.748022, lng: 18.12725 },
    { lat: 48.747937, lng: 18.127216 },
    { lat: 48.747758, lng: 18.127204 },
    { lat: 48.747448, lng: 18.127248 },
    { lat: 48.747155, lng: 18.127093 },
    { lat: 48.746932, lng: 18.12688 },
    { lat: 48.746598, lng: 18.126772 },
    { lat: 48.745789, lng: 18.126727 },
    { lat: 48.745132, lng: 18.126739 },
    { lat: 48.745104, lng: 18.126729 },
    { lat: 48.745068, lng: 18.126713 },
    { lat: 48.745052, lng: 18.126706 },
    { lat: 48.744614, lng: 18.126572 },
    { lat: 48.744429, lng: 18.126509 },
    { lat: 48.744309, lng: 18.126349 },
    { lat: 48.744232, lng: 18.126306 },
    { lat: 48.744319, lng: 18.125945 },
    { lat: 48.744106, lng: 18.12562 },
    { lat: 48.744114, lng: 18.125357 },
    { lat: 48.744112, lng: 18.125254 },
    { lat: 48.743823, lng: 18.124701 },
    { lat: 48.743887, lng: 18.124383 },
    { lat: 48.743689, lng: 18.124324 },
    { lat: 48.743663, lng: 18.12367 },
    { lat: 48.743653, lng: 18.123509 },
    { lat: 48.743608, lng: 18.122607 },
    { lat: 48.743874, lng: 18.121865 },
    { lat: 48.743856, lng: 18.121775 },
    { lat: 48.743515, lng: 18.121155 },
    { lat: 48.743302, lng: 18.120901 },
    { lat: 48.743274, lng: 18.120822 },
    { lat: 48.743286, lng: 18.12062 },
    { lat: 48.742833, lng: 18.120271 },
    { lat: 48.742592, lng: 18.119725 },
    { lat: 48.742553, lng: 18.119508 },
    { lat: 48.742457, lng: 18.119519 },
    { lat: 48.742044, lng: 18.119262 },
    { lat: 48.742072, lng: 18.119098 },
    { lat: 48.741862, lng: 18.118825 },
    { lat: 48.741669, lng: 18.118712 },
    { lat: 48.741541, lng: 18.118314 },
    { lat: 48.741546, lng: 18.118005 },
    { lat: 48.741419, lng: 18.117611 },
    { lat: 48.741523, lng: 18.117171 },
    { lat: 48.741738, lng: 18.117012 },
    { lat: 48.741816, lng: 18.116835 },
    { lat: 48.74184, lng: 18.116607 },
    { lat: 48.74156, lng: 18.116127 },
    { lat: 48.741454, lng: 18.116061 },
    { lat: 48.741431, lng: 18.115613 },
    { lat: 48.741404, lng: 18.115309 },
    { lat: 48.741414, lng: 18.115128 },
    { lat: 48.741308, lng: 18.114929 },
    { lat: 48.741294, lng: 18.114532 },
    { lat: 48.741354, lng: 18.114351 },
    { lat: 48.741335, lng: 18.114343 },
    { lat: 48.741416, lng: 18.112998 },
    { lat: 48.741512, lng: 18.111637 },
    { lat: 48.7413848, lng: 18.1116976 },
    { lat: 48.7410833, lng: 18.1117352 },
    { lat: 48.7401738, lng: 18.1123763 },
    { lat: 48.7398078, lng: 18.1126613 },
    { lat: 48.7397209, lng: 18.1128545 },
    { lat: 48.7394354, lng: 18.1132222 },
    { lat: 48.7392626, lng: 18.113626 },
    { lat: 48.7388227, lng: 18.1143271 },
    { lat: 48.7383374, lng: 18.1149476 },
    { lat: 48.7379398, lng: 18.1153488 },
    { lat: 48.7375955, lng: 18.1159102 },
    { lat: 48.7375038, lng: 18.1161748 },
    { lat: 48.7375058, lng: 18.1167122 },
    { lat: 48.737259, lng: 18.1174365 },
    { lat: 48.7371811, lng: 18.1179796 },
    { lat: 48.7370965, lng: 18.11885 },
    { lat: 48.737089, lng: 18.1194977 },
    { lat: 48.7370861, lng: 18.1200483 },
    { lat: 48.7370065, lng: 18.121415 },
    { lat: 48.7369371, lng: 18.1219097 },
    { lat: 48.7368126, lng: 18.1224637 },
    { lat: 48.7365207, lng: 18.1233044 },
    { lat: 48.73652, lng: 18.1233084 },
    { lat: 48.7363589, lng: 18.1237796 },
    { lat: 48.7358752, lng: 18.1251845 },
    { lat: 48.7358412, lng: 18.1254336 },
    { lat: 48.7356977, lng: 18.1266939 },
    { lat: 48.7355051, lng: 18.1274163 },
    { lat: 48.7353658, lng: 18.1280712 },
    { lat: 48.7350163, lng: 18.1287747 },
    { lat: 48.7348008, lng: 18.1291812 },
    { lat: 48.7347253, lng: 18.1296313 },
    { lat: 48.7345916, lng: 18.1301471 },
    { lat: 48.7345047, lng: 18.1303599 },
    { lat: 48.7343196, lng: 18.130728 },
    { lat: 48.7341814, lng: 18.1309909 },
    { lat: 48.7341281, lng: 18.1311034 },
    { lat: 48.7340589, lng: 18.1313496 },
    { lat: 48.7339995, lng: 18.1317061 },
    { lat: 48.7339711, lng: 18.1318403 },
    { lat: 48.7339257, lng: 18.1319504 },
    { lat: 48.7338223, lng: 18.1320953 },
    { lat: 48.7336986, lng: 18.1322944 },
    { lat: 48.7335882, lng: 18.1325522 },
    { lat: 48.7334513, lng: 18.1329143 },
    { lat: 48.7331341, lng: 18.1337103 },
    { lat: 48.7329739, lng: 18.1339822 },
    { lat: 48.7328778, lng: 18.1342123 },
    { lat: 48.7326124, lng: 18.1352409 },
    { lat: 48.7323885, lng: 18.1356771 },
    { lat: 48.7323132, lng: 18.1363241 },
    { lat: 48.732261, lng: 18.1366714 },
    { lat: 48.7322251, lng: 18.1381293 },
    { lat: 48.7322038, lng: 18.1384839 },
    { lat: 48.7321736, lng: 18.13882 },
    { lat: 48.732064, lng: 18.1392824 },
    { lat: 48.7318131, lng: 18.1403091 },
    { lat: 48.7316032, lng: 18.1408718 },
    { lat: 48.7309767, lng: 18.1421709 },
    { lat: 48.7305884, lng: 18.1430067 },
    { lat: 48.730419, lng: 18.1434338 },
    { lat: 48.7303356, lng: 18.143724 },
    { lat: 48.7302609, lng: 18.1442676 },
    { lat: 48.7302361, lng: 18.1444232 },
    { lat: 48.7301862, lng: 18.1446707 },
    { lat: 48.7300679, lng: 18.1452008 },
    { lat: 48.7299476, lng: 18.1457726 },
    { lat: 48.7296719, lng: 18.1467803 },
    { lat: 48.729567, lng: 18.1477725 },
    { lat: 48.7295168, lng: 18.1483833 },
    { lat: 48.7295344, lng: 18.1493318 },
    { lat: 48.7296685, lng: 18.1500665 },
    { lat: 48.7296939, lng: 18.1504885 },
    { lat: 48.7297558, lng: 18.1507781 },
    { lat: 48.7298352, lng: 18.151194 },
    { lat: 48.7299162, lng: 18.1515997 },
    { lat: 48.7299493, lng: 18.1518566 },
    { lat: 48.7300567, lng: 18.152212 },
    { lat: 48.7300754, lng: 18.1529441 },
    { lat: 48.730033, lng: 18.1537094 },
    { lat: 48.7298961, lng: 18.1549307 },
    { lat: 48.7294769, lng: 18.1547895 },
    { lat: 48.7289901, lng: 18.1545458 },
    { lat: 48.7287308, lng: 18.1544532 },
    { lat: 48.7284979, lng: 18.1543889 },
    { lat: 48.7280434, lng: 18.1542947 },
    { lat: 48.7278275, lng: 18.1542703 },
    { lat: 48.7273497, lng: 18.1542227 },
    { lat: 48.7269912, lng: 18.1541897 },
    { lat: 48.7267907, lng: 18.1541955 },
    { lat: 48.7264965, lng: 18.1543177 },
    { lat: 48.7264342, lng: 18.1543306 },
    { lat: 48.7262284, lng: 18.1542849 },
    { lat: 48.7261367, lng: 18.1542256 },
    { lat: 48.7260563, lng: 18.1545315 },
    { lat: 48.725763, lng: 18.155624 },
    { lat: 48.7255878, lng: 18.1556214 },
    { lat: 48.7254384, lng: 18.1554535 },
    { lat: 48.725118, lng: 18.155458 },
    { lat: 48.724552, lng: 18.1556976 },
    { lat: 48.7243681, lng: 18.1557391 },
    { lat: 48.7242345, lng: 18.1558504 },
    { lat: 48.7239465, lng: 18.1559232 },
    { lat: 48.7236591, lng: 18.1561431 },
    { lat: 48.723524, lng: 18.1564019 },
    { lat: 48.7233782, lng: 18.156359 },
    { lat: 48.7234077, lng: 18.1560119 },
    { lat: 48.7232224, lng: 18.1559893 },
    { lat: 48.7230593, lng: 18.1559572 },
    { lat: 48.7228674, lng: 18.1558575 },
    { lat: 48.7227211, lng: 18.1557282 },
    { lat: 48.7225165, lng: 18.1556087 },
    { lat: 48.7219745, lng: 18.155347 },
    { lat: 48.7219057, lng: 18.1552689 },
    { lat: 48.7212551, lng: 18.1549657 },
    { lat: 48.7206388, lng: 18.1546341 },
    { lat: 48.7193465, lng: 18.1540266 },
    { lat: 48.7177089, lng: 18.1532412 },
    { lat: 48.7166409, lng: 18.1526895 },
    { lat: 48.7157503, lng: 18.1522592 },
    { lat: 48.7156613, lng: 18.1525722 },
    { lat: 48.7155872, lng: 18.1529094 },
    { lat: 48.7155893, lng: 18.1532186 },
    { lat: 48.7156237, lng: 18.1537364 },
    { lat: 48.7155528, lng: 18.1542836 },
    { lat: 48.7154477, lng: 18.1545341 },
    { lat: 48.715358, lng: 18.1549741 },
    { lat: 48.7152888, lng: 18.1552205 },
    { lat: 48.7152252, lng: 18.1555062 },
    { lat: 48.7151727, lng: 18.1557883 },
    { lat: 48.7151008, lng: 18.1560255 },
    { lat: 48.7149942, lng: 18.1563476 },
    { lat: 48.7149704, lng: 18.1564076 },
    { lat: 48.7148182, lng: 18.1566621 },
    { lat: 48.7146431, lng: 18.1568729 },
    { lat: 48.7144806, lng: 18.1571449 },
    { lat: 48.7142265, lng: 18.157846 },
    { lat: 48.7141313, lng: 18.1581855 },
    { lat: 48.714056, lng: 18.158699 },
    { lat: 48.7140026, lng: 18.158929 },
    { lat: 48.7139514, lng: 18.1590366 },
    { lat: 48.7138253, lng: 18.1592759 },
    { lat: 48.7135196, lng: 18.1597465 },
    { lat: 48.7131958, lng: 18.1601963 },
    { lat: 48.7129282, lng: 18.1604468 },
    { lat: 48.7128187, lng: 18.160621 },
    { lat: 48.7124297, lng: 18.1612105 },
    { lat: 48.7120246, lng: 18.1617982 },
    { lat: 48.7119077, lng: 18.1620366 },
    { lat: 48.71183, lng: 18.1622191 },
    { lat: 48.7117245, lng: 18.16252 },
    { lat: 48.7115872, lng: 18.163033 },
    { lat: 48.7113481, lng: 18.1640876 },
    { lat: 48.7112356, lng: 18.1645048 },
    { lat: 48.7111548, lng: 18.1647474 },
    { lat: 48.7110542, lng: 18.1649958 },
    { lat: 48.7108781, lng: 18.1654471 },
    { lat: 48.7108079, lng: 18.1656965 },
    { lat: 48.7105791, lng: 18.1664017 },
    { lat: 48.7103219, lng: 18.1670077 },
    { lat: 48.7102008, lng: 18.1674903 },
    { lat: 48.7100308, lng: 18.1679858 },
    { lat: 48.7098682, lng: 18.1685672 },
    { lat: 48.7096098, lng: 18.1696046 },
    { lat: 48.7096303, lng: 18.1696276 },
    { lat: 48.7096146, lng: 18.1696665 },
    { lat: 48.7101538, lng: 18.1702692 },
    { lat: 48.7105741, lng: 18.1708227 },
    { lat: 48.7107747, lng: 18.1710949 },
    { lat: 48.7111369, lng: 18.1713673 },
    { lat: 48.7114681, lng: 18.1714351 },
    { lat: 48.711954, lng: 18.1717787 },
    { lat: 48.7119927, lng: 18.1718418 },
    { lat: 48.7120533, lng: 18.1720118 },
    { lat: 48.7121956, lng: 18.1721331 },
    { lat: 48.7123469, lng: 18.1724283 },
    { lat: 48.7124481, lng: 18.1728116 },
    { lat: 48.7126685, lng: 18.173128 },
    { lat: 48.7128317, lng: 18.1732012 },
    { lat: 48.713122, lng: 18.1731683 },
    { lat: 48.7134906, lng: 18.173278 },
    { lat: 48.7144892, lng: 18.1737645 },
    { lat: 48.7145498, lng: 18.1747367 },
    { lat: 48.7145443, lng: 18.1756354 },
    { lat: 48.7149385, lng: 18.1757799 },
    { lat: 48.7145785, lng: 18.1767368 },
    { lat: 48.7144266, lng: 18.1771367 },
    { lat: 48.7141446, lng: 18.1779986 },
    { lat: 48.7140203, lng: 18.1787364 },
    { lat: 48.7139669, lng: 18.1790254 },
    { lat: 48.7142577, lng: 18.1796628 },
    { lat: 48.7151171, lng: 18.1799469 },
    { lat: 48.7155405, lng: 18.1804047 },
    { lat: 48.715669, lng: 18.1804592 },
    { lat: 48.7157799, lng: 18.1804632 },
    { lat: 48.7161252, lng: 18.1804445 },
    { lat: 48.7165545, lng: 18.1803157 },
    { lat: 48.7166341, lng: 18.1803253 },
    { lat: 48.7171717, lng: 18.1803148 },
    { lat: 48.7172855, lng: 18.1802923 },
    { lat: 48.7173386, lng: 18.1802539 },
    { lat: 48.7175421, lng: 18.1802024 },
    { lat: 48.718136, lng: 18.180117 },
    { lat: 48.7186195, lng: 18.1798671 },
    { lat: 48.7190444, lng: 18.1796421 },
    { lat: 48.7195756, lng: 18.1792244 },
    { lat: 48.7203075, lng: 18.1791601 },
    { lat: 48.7206505, lng: 18.1792371 },
    { lat: 48.7208362, lng: 18.1794639 },
    { lat: 48.7212457, lng: 18.1797948 },
    { lat: 48.7213326, lng: 18.1797341 },
    { lat: 48.7215348, lng: 18.1796978 },
    { lat: 48.721633, lng: 18.1797563 },
    { lat: 48.7215268, lng: 18.1801459 },
    { lat: 48.7213938, lng: 18.1806595 },
    { lat: 48.7212987, lng: 18.1810037 },
    { lat: 48.7212152, lng: 18.1814335 },
    { lat: 48.7209773, lng: 18.1823401 },
    { lat: 48.7203368, lng: 18.1835416 },
    { lat: 48.7202207, lng: 18.1837767 },
    { lat: 48.7199566, lng: 18.1842646 },
    { lat: 48.7198284, lng: 18.1845874 },
    { lat: 48.7198853, lng: 18.1852214 },
    { lat: 48.7198328, lng: 18.1858941 },
    { lat: 48.7196279, lng: 18.1864921 },
    { lat: 48.7193849, lng: 18.1871124 },
    { lat: 48.7192091, lng: 18.1877023 },
    { lat: 48.7194645, lng: 18.1881618 },
  ],
  SlatinanadBebravou: [
    { lat: 48.8173314, lng: 18.2801858 },
    { lat: 48.8176156, lng: 18.2809601 },
    { lat: 48.8179714, lng: 18.2816603 },
    { lat: 48.8181821, lng: 18.282634 },
    { lat: 48.8183687, lng: 18.2831761 },
    { lat: 48.8187226, lng: 18.2836106 },
    { lat: 48.8191242, lng: 18.2839082 },
    { lat: 48.8196665, lng: 18.2846541 },
    { lat: 48.8198321, lng: 18.2850908 },
    { lat: 48.8203278, lng: 18.2862319 },
    { lat: 48.8209266, lng: 18.2865057 },
    { lat: 48.8212803, lng: 18.2864942 },
    { lat: 48.821708, lng: 18.2864357 },
    { lat: 48.8220451, lng: 18.2865144 },
    { lat: 48.8221795, lng: 18.2864361 },
    { lat: 48.8227391, lng: 18.2866946 },
    { lat: 48.8231784, lng: 18.2865032 },
    { lat: 48.8237089, lng: 18.2863649 },
    { lat: 48.8237375, lng: 18.2864148 },
    { lat: 48.8242132, lng: 18.287062 },
    { lat: 48.8247298, lng: 18.2873988 },
    { lat: 48.8247408, lng: 18.2874146 },
    { lat: 48.8247492, lng: 18.2874411 },
    { lat: 48.8247578, lng: 18.2874553 },
    { lat: 48.8251823, lng: 18.2886144 },
    { lat: 48.8252191, lng: 18.2887038 },
    { lat: 48.8255422, lng: 18.289551 },
    { lat: 48.8258134, lng: 18.2898387 },
    { lat: 48.8261961, lng: 18.2900214 },
    { lat: 48.8268868, lng: 18.2905827 },
    { lat: 48.8272687, lng: 18.2912483 },
    { lat: 48.827879, lng: 18.2919271 },
    { lat: 48.8288014, lng: 18.2924169 },
    { lat: 48.8293159, lng: 18.2928525 },
    { lat: 48.8300054, lng: 18.2940529 },
    { lat: 48.8300757, lng: 18.2939793 },
    { lat: 48.8304949, lng: 18.2951074 },
    { lat: 48.8306605, lng: 18.2960417 },
    { lat: 48.8307218, lng: 18.2975446 },
    { lat: 48.8308318, lng: 18.2982322 },
    { lat: 48.8309187, lng: 18.2984713 },
    { lat: 48.8315704, lng: 18.2995023 },
    { lat: 48.8323796, lng: 18.3005064 },
    { lat: 48.8337851, lng: 18.3018563 },
    { lat: 48.8343597, lng: 18.3021417 },
    { lat: 48.8347567, lng: 18.3024617 },
    { lat: 48.8356268, lng: 18.3033033 },
    { lat: 48.8358582, lng: 18.3031229 },
    { lat: 48.8358792, lng: 18.3031564 },
    { lat: 48.836554, lng: 18.3030768 },
    { lat: 48.8370732, lng: 18.3035413 },
    { lat: 48.8373334, lng: 18.3038325 },
    { lat: 48.8377249, lng: 18.3042939 },
    { lat: 48.8380789, lng: 18.304407 },
    { lat: 48.838875, lng: 18.3040406 },
    { lat: 48.8395653, lng: 18.3046962 },
    { lat: 48.8405467, lng: 18.3050149 },
    { lat: 48.8402471, lng: 18.3043026 },
    { lat: 48.8395075, lng: 18.3019146 },
    { lat: 48.838588, lng: 18.2999013 },
    { lat: 48.8384593, lng: 18.2994608 },
    { lat: 48.8382246, lng: 18.2986572 },
    { lat: 48.8390795, lng: 18.2971978 },
    { lat: 48.840807, lng: 18.2970786 },
    { lat: 48.8409347, lng: 18.2963235 },
    { lat: 48.8405718, lng: 18.2951739 },
    { lat: 48.8402673, lng: 18.2941371 },
    { lat: 48.8403269, lng: 18.2938743 },
    { lat: 48.84033, lng: 18.2931708 },
    { lat: 48.8416894, lng: 18.2921336 },
    { lat: 48.8428687, lng: 18.2911176 },
    { lat: 48.8442584, lng: 18.290014 },
    { lat: 48.8440783, lng: 18.2890888 },
    { lat: 48.8443942, lng: 18.2884879 },
    { lat: 48.8445352, lng: 18.287612 },
    { lat: 48.8435065, lng: 18.2863795 },
    { lat: 48.8428058, lng: 18.2852237 },
    { lat: 48.8430083, lng: 18.2845383 },
    { lat: 48.8437043, lng: 18.2835967 },
    { lat: 48.8437561, lng: 18.2834968 },
    { lat: 48.8434501, lng: 18.2829746 },
    { lat: 48.8424871, lng: 18.2816476 },
    { lat: 48.8419513, lng: 18.2809322 },
    { lat: 48.8416817, lng: 18.2805388 },
    { lat: 48.841606, lng: 18.2803556 },
    { lat: 48.8415251, lng: 18.2800624 },
    { lat: 48.8414522, lng: 18.2794945 },
    { lat: 48.8418795, lng: 18.2798666 },
    { lat: 48.8420984, lng: 18.2799864 },
    { lat: 48.8424321, lng: 18.2801016 },
    { lat: 48.8426678, lng: 18.2802795 },
    { lat: 48.8431585, lng: 18.2794802 },
    { lat: 48.8447002, lng: 18.2787706 },
    { lat: 48.8449268, lng: 18.2787476 },
    { lat: 48.8451776, lng: 18.2788554 },
    { lat: 48.8455014, lng: 18.2789741 },
    { lat: 48.8456359, lng: 18.2790562 },
    { lat: 48.8459381, lng: 18.2793335 },
    { lat: 48.8473421, lng: 18.2790011 },
    { lat: 48.8478955, lng: 18.2790698 },
    { lat: 48.8480405, lng: 18.2790205 },
    { lat: 48.8481879, lng: 18.278598 },
    { lat: 48.8484236, lng: 18.2782556 },
    { lat: 48.8486813, lng: 18.2777882 },
    { lat: 48.8487232, lng: 18.2780268 },
    { lat: 48.8489074, lng: 18.278547 },
    { lat: 48.849057, lng: 18.2789961 },
    { lat: 48.8492307, lng: 18.2795173 },
    { lat: 48.8494685, lng: 18.2797135 },
    { lat: 48.8495477, lng: 18.2798434 },
    { lat: 48.8496328, lng: 18.279983 },
    { lat: 48.8496339, lng: 18.2801409 },
    { lat: 48.8496602, lng: 18.2801088 },
    { lat: 48.8507316, lng: 18.2790426 },
    { lat: 48.8520868, lng: 18.2779483 },
    { lat: 48.8527585, lng: 18.2776567 },
    { lat: 48.8538094, lng: 18.2769104 },
    { lat: 48.8550472, lng: 18.2769535 },
    { lat: 48.8562604, lng: 18.2768952 },
    { lat: 48.8568728, lng: 18.2765338 },
    { lat: 48.8574738, lng: 18.2767888 },
    { lat: 48.8576502, lng: 18.2767923 },
    { lat: 48.8585423, lng: 18.2773853 },
    { lat: 48.8591024, lng: 18.2781079 },
    { lat: 48.8591763, lng: 18.2782598 },
    { lat: 48.8598925, lng: 18.2788112 },
    { lat: 48.8606169, lng: 18.2798292 },
    { lat: 48.8612922, lng: 18.2800972 },
    { lat: 48.8624111, lng: 18.2798903 },
    { lat: 48.862676, lng: 18.279291 },
    { lat: 48.8630522, lng: 18.2787203 },
    { lat: 48.862292, lng: 18.2778302 },
    { lat: 48.8605943, lng: 18.2771404 },
    { lat: 48.8603713, lng: 18.2747786 },
    { lat: 48.8590091, lng: 18.2746145 },
    { lat: 48.8591977, lng: 18.2730825 },
    { lat: 48.8591354, lng: 18.2722221 },
    { lat: 48.8593829, lng: 18.2717924 },
    { lat: 48.8594048, lng: 18.2717616 },
    { lat: 48.8594907, lng: 18.271783 },
    { lat: 48.8595238, lng: 18.2714114 },
    { lat: 48.8596417, lng: 18.2710069 },
    { lat: 48.859541, lng: 18.270302 },
    { lat: 48.8594523, lng: 18.2701935 },
    { lat: 48.8592204, lng: 18.270261 },
    { lat: 48.8591487, lng: 18.2702105 },
    { lat: 48.8590791, lng: 18.270116 },
    { lat: 48.8588005, lng: 18.2699982 },
    { lat: 48.858558, lng: 18.2696633 },
    { lat: 48.8582116, lng: 18.2693831 },
    { lat: 48.8580485, lng: 18.2692123 },
    { lat: 48.8580367, lng: 18.2691665 },
    { lat: 48.8580603, lng: 18.2688255 },
    { lat: 48.858194, lng: 18.2685566 },
    { lat: 48.8582586, lng: 18.2682443 },
    { lat: 48.8582547, lng: 18.2679184 },
    { lat: 48.8584877, lng: 18.2671115 },
    { lat: 48.8584799, lng: 18.2668373 },
    { lat: 48.8584425, lng: 18.2666154 },
    { lat: 48.8582361, lng: 18.2662779 },
    { lat: 48.8577124, lng: 18.2660775 },
    { lat: 48.8574451, lng: 18.2658805 },
    { lat: 48.8571708, lng: 18.2655743 },
    { lat: 48.8570849, lng: 18.2654785 },
    { lat: 48.8569396, lng: 18.2653219 },
    { lat: 48.8567007, lng: 18.2653659 },
    { lat: 48.8568425, lng: 18.2651618 },
    { lat: 48.8572771, lng: 18.264649 },
    { lat: 48.8569268, lng: 18.2624155 },
    { lat: 48.8569454, lng: 18.2604004 },
    { lat: 48.8568131, lng: 18.2598573 },
    { lat: 48.8567166, lng: 18.2597092 },
    { lat: 48.8563137, lng: 18.2590912 },
    { lat: 48.8560661, lng: 18.2585459 },
    { lat: 48.85616, lng: 18.2579342 },
    { lat: 48.8557608, lng: 18.2574629 },
    { lat: 48.8546956, lng: 18.2569466 },
    { lat: 48.8539571, lng: 18.2569374 },
    { lat: 48.8531124, lng: 18.2566056 },
    { lat: 48.8522478, lng: 18.25575 },
    { lat: 48.8520619, lng: 18.2556948 },
    { lat: 48.8512271, lng: 18.2560747 },
    { lat: 48.8509306, lng: 18.2560156 },
    { lat: 48.8504509, lng: 18.2565114 },
    { lat: 48.8495549, lng: 18.2566079 },
    { lat: 48.8488665, lng: 18.2564865 },
    { lat: 48.8483038, lng: 18.2557965 },
    { lat: 48.8475989, lng: 18.255051 },
    { lat: 48.8471518, lng: 18.2542521 },
    { lat: 48.8467372, lng: 18.2530334 },
    { lat: 48.8463089, lng: 18.252624 },
    { lat: 48.8457572, lng: 18.2522694 },
    { lat: 48.8445385, lng: 18.2508381 },
    { lat: 48.84436, lng: 18.2511924 },
    { lat: 48.8441249, lng: 18.251202 },
    { lat: 48.843611, lng: 18.250955 },
    { lat: 48.8434724, lng: 18.2509799 },
    { lat: 48.8429273, lng: 18.2507779 },
    { lat: 48.8428267, lng: 18.2507186 },
    { lat: 48.8427338, lng: 18.2505896 },
    { lat: 48.84284, lng: 18.2504364 },
    { lat: 48.8430063, lng: 18.2493913 },
    { lat: 48.8430251, lng: 18.2486347 },
    { lat: 48.8430781, lng: 18.2480998 },
    { lat: 48.8431827, lng: 18.2474994 },
    { lat: 48.8428065, lng: 18.2474705 },
    { lat: 48.8428163, lng: 18.246948 },
    { lat: 48.8428955, lng: 18.2461015 },
    { lat: 48.8428975, lng: 18.2458176 },
    { lat: 48.8428857, lng: 18.2453355 },
    { lat: 48.8427669, lng: 18.245288 },
    { lat: 48.8427513, lng: 18.2452922 },
    { lat: 48.8427029, lng: 18.2452566 },
    { lat: 48.8426595, lng: 18.2452458 },
    { lat: 48.8426526, lng: 18.2449469 },
    { lat: 48.8426765, lng: 18.2445945 },
    { lat: 48.8427327, lng: 18.243879 },
    { lat: 48.8425769, lng: 18.2438549 },
    { lat: 48.8424031, lng: 18.2437387 },
    { lat: 48.8422522, lng: 18.2438404 },
    { lat: 48.842171, lng: 18.2438597 },
    { lat: 48.8421261, lng: 18.2438444 },
    { lat: 48.8419208, lng: 18.2436474 },
    { lat: 48.8417799, lng: 18.2434125 },
    { lat: 48.8416766, lng: 18.2432258 },
    { lat: 48.8415226, lng: 18.2431528 },
    { lat: 48.8415016, lng: 18.243042 },
    { lat: 48.8414971, lng: 18.2427478 },
    { lat: 48.8414344, lng: 18.2422996 },
    { lat: 48.8414782, lng: 18.242022 },
    { lat: 48.8414281, lng: 18.2416156 },
    { lat: 48.8415167, lng: 18.2413704 },
    { lat: 48.8416363, lng: 18.2408547 },
    { lat: 48.8414103, lng: 18.2406328 },
    { lat: 48.8412854, lng: 18.2387575 },
    { lat: 48.8410856, lng: 18.2389348 },
    { lat: 48.840561, lng: 18.2392728 },
    { lat: 48.840065, lng: 18.239754 },
    { lat: 48.8393965, lng: 18.2405984 },
    { lat: 48.8390828, lng: 18.2414289 },
    { lat: 48.8390184, lng: 18.2422572 },
    { lat: 48.8389894, lng: 18.2427976 },
    { lat: 48.8384253, lng: 18.2434119 },
    { lat: 48.8377081, lng: 18.2441905 },
    { lat: 48.8373486, lng: 18.244715 },
    { lat: 48.8369732, lng: 18.245156 },
    { lat: 48.836549, lng: 18.245671 },
    { lat: 48.8362385, lng: 18.2459272 },
    { lat: 48.8357987, lng: 18.2463662 },
    { lat: 48.8352096, lng: 18.2466796 },
    { lat: 48.8345968, lng: 18.2470537 },
    { lat: 48.8340231, lng: 18.2473436 },
    { lat: 48.8332135, lng: 18.2473291 },
    { lat: 48.8316211, lng: 18.2480774 },
    { lat: 48.8312688, lng: 18.2484296 },
    { lat: 48.8310243, lng: 18.2489107 },
    { lat: 48.8303698, lng: 18.2497246 },
    { lat: 48.8302771, lng: 18.2499774 },
    { lat: 48.8286254, lng: 18.2512285 },
    { lat: 48.8275616, lng: 18.2526183 },
    { lat: 48.8275302, lng: 18.2540305 },
    { lat: 48.8275217, lng: 18.2543174 },
    { lat: 48.8273359, lng: 18.2556305 },
    { lat: 48.8276326, lng: 18.257277 },
    { lat: 48.8272026, lng: 18.25791 },
    { lat: 48.8265891, lng: 18.2588076 },
    { lat: 48.8264428, lng: 18.2598652 },
    { lat: 48.8262155, lng: 18.26037 },
    { lat: 48.8259166, lng: 18.2610271 },
    { lat: 48.8253516, lng: 18.2611735 },
    { lat: 48.8247638, lng: 18.2624257 },
    { lat: 48.8242353, lng: 18.2628115 },
    { lat: 48.8240853, lng: 18.263014 },
    { lat: 48.8237185, lng: 18.2631911 },
    { lat: 48.8230536, lng: 18.2636223 },
    { lat: 48.8228097, lng: 18.2639939 },
    { lat: 48.8228081, lng: 18.2640238 },
    { lat: 48.8221956, lng: 18.2637105 },
    { lat: 48.8216066, lng: 18.2636931 },
    { lat: 48.8212066, lng: 18.2638026 },
    { lat: 48.8208467, lng: 18.2636601 },
    { lat: 48.8205592, lng: 18.2633474 },
    { lat: 48.820375, lng: 18.2633223 },
    { lat: 48.8202372, lng: 18.2634613 },
    { lat: 48.8202174, lng: 18.2634485 },
    { lat: 48.8201721, lng: 18.2639002 },
    { lat: 48.820072, lng: 18.2640858 },
    { lat: 48.8198276, lng: 18.2640948 },
    { lat: 48.8196396, lng: 18.2643978 },
    { lat: 48.8194549, lng: 18.264781 },
    { lat: 48.8192076, lng: 18.2650961 },
    { lat: 48.8191822, lng: 18.2657231 },
    { lat: 48.8189927, lng: 18.2664163 },
    { lat: 48.818619, lng: 18.2667926 },
    { lat: 48.818475, lng: 18.2680906 },
    { lat: 48.8182166, lng: 18.2686241 },
    { lat: 48.8180021, lng: 18.2692965 },
    { lat: 48.8176412, lng: 18.2695196 },
    { lat: 48.8171778, lng: 18.270141 },
    { lat: 48.8170636, lng: 18.2712904 },
    { lat: 48.816856, lng: 18.2719268 },
    { lat: 48.816691, lng: 18.2724191 },
    { lat: 48.8161903, lng: 18.2733775 },
    { lat: 48.8158424, lng: 18.2737257 },
    { lat: 48.8156153, lng: 18.2739826 },
    { lat: 48.8150238, lng: 18.2746331 },
    { lat: 48.8154563, lng: 18.2756125 },
    { lat: 48.8158895, lng: 18.2761895 },
    { lat: 48.8164501, lng: 18.2766429 },
    { lat: 48.8167181, lng: 18.2768919 },
    { lat: 48.8167661, lng: 18.2778759 },
    { lat: 48.8166982, lng: 18.2782507 },
    { lat: 48.8166546, lng: 18.2785155 },
    { lat: 48.8169632, lng: 18.2792448 },
    { lat: 48.8173314, lng: 18.2801858 },
  ],
  SlatinkanadBebravou: [
    { lat: 48.8416363, lng: 18.2408547 },
    { lat: 48.8415167, lng: 18.2413704 },
    { lat: 48.8414281, lng: 18.2416156 },
    { lat: 48.8414782, lng: 18.242022 },
    { lat: 48.8414344, lng: 18.2422996 },
    { lat: 48.8414971, lng: 18.2427478 },
    { lat: 48.8415016, lng: 18.243042 },
    { lat: 48.8415226, lng: 18.2431528 },
    { lat: 48.8416766, lng: 18.2432258 },
    { lat: 48.8417799, lng: 18.2434125 },
    { lat: 48.8419208, lng: 18.2436474 },
    { lat: 48.8421261, lng: 18.2438444 },
    { lat: 48.842171, lng: 18.2438597 },
    { lat: 48.8422522, lng: 18.2438404 },
    { lat: 48.8424031, lng: 18.2437387 },
    { lat: 48.8425769, lng: 18.2438549 },
    { lat: 48.8427327, lng: 18.243879 },
    { lat: 48.8426765, lng: 18.2445945 },
    { lat: 48.8426526, lng: 18.2449469 },
    { lat: 48.8426595, lng: 18.2452458 },
    { lat: 48.8427029, lng: 18.2452566 },
    { lat: 48.8427513, lng: 18.2452922 },
    { lat: 48.8427669, lng: 18.245288 },
    { lat: 48.8428857, lng: 18.2453355 },
    { lat: 48.8428975, lng: 18.2458176 },
    { lat: 48.8428955, lng: 18.2461015 },
    { lat: 48.8428163, lng: 18.246948 },
    { lat: 48.8428065, lng: 18.2474705 },
    { lat: 48.8431827, lng: 18.2474994 },
    { lat: 48.8430781, lng: 18.2480998 },
    { lat: 48.8430251, lng: 18.2486347 },
    { lat: 48.8430063, lng: 18.2493913 },
    { lat: 48.84284, lng: 18.2504364 },
    { lat: 48.8427338, lng: 18.2505896 },
    { lat: 48.8428267, lng: 18.2507186 },
    { lat: 48.8429273, lng: 18.2507779 },
    { lat: 48.8434724, lng: 18.2509799 },
    { lat: 48.843611, lng: 18.250955 },
    { lat: 48.8441249, lng: 18.251202 },
    { lat: 48.84436, lng: 18.2511924 },
    { lat: 48.8445385, lng: 18.2508381 },
    { lat: 48.8457572, lng: 18.2522694 },
    { lat: 48.8463089, lng: 18.252624 },
    { lat: 48.8467372, lng: 18.2530334 },
    { lat: 48.8471518, lng: 18.2542521 },
    { lat: 48.8475989, lng: 18.255051 },
    { lat: 48.8483038, lng: 18.2557965 },
    { lat: 48.8488665, lng: 18.2564865 },
    { lat: 48.8495549, lng: 18.2566079 },
    { lat: 48.8504509, lng: 18.2565114 },
    { lat: 48.8509306, lng: 18.2560156 },
    { lat: 48.8512271, lng: 18.2560747 },
    { lat: 48.8520619, lng: 18.2556948 },
    { lat: 48.8522478, lng: 18.25575 },
    { lat: 48.8531124, lng: 18.2566056 },
    { lat: 48.8539571, lng: 18.2569374 },
    { lat: 48.8546956, lng: 18.2569466 },
    { lat: 48.8557608, lng: 18.2574629 },
    { lat: 48.85616, lng: 18.2579342 },
    { lat: 48.8560661, lng: 18.2585459 },
    { lat: 48.8563137, lng: 18.2590912 },
    { lat: 48.8567166, lng: 18.2597092 },
    { lat: 48.8568131, lng: 18.2598573 },
    { lat: 48.8569454, lng: 18.2604004 },
    { lat: 48.8569268, lng: 18.2624155 },
    { lat: 48.8572771, lng: 18.264649 },
    { lat: 48.8568425, lng: 18.2651618 },
    { lat: 48.8567007, lng: 18.2653659 },
    { lat: 48.8569396, lng: 18.2653219 },
    { lat: 48.8570849, lng: 18.2654785 },
    { lat: 48.8571708, lng: 18.2655743 },
    { lat: 48.8574451, lng: 18.2658805 },
    { lat: 48.8577124, lng: 18.2660775 },
    { lat: 48.8582361, lng: 18.2662779 },
    { lat: 48.8584425, lng: 18.2666154 },
    { lat: 48.8584799, lng: 18.2668373 },
    { lat: 48.8584877, lng: 18.2671115 },
    { lat: 48.8582547, lng: 18.2679184 },
    { lat: 48.8582586, lng: 18.2682443 },
    { lat: 48.858194, lng: 18.2685566 },
    { lat: 48.8580603, lng: 18.2688255 },
    { lat: 48.8580367, lng: 18.2691665 },
    { lat: 48.8580485, lng: 18.2692123 },
    { lat: 48.8582116, lng: 18.2693831 },
    { lat: 48.858558, lng: 18.2696633 },
    { lat: 48.8588005, lng: 18.2699982 },
    { lat: 48.8590791, lng: 18.270116 },
    { lat: 48.8591487, lng: 18.2702105 },
    { lat: 48.8592204, lng: 18.270261 },
    { lat: 48.8594523, lng: 18.2701935 },
    { lat: 48.859541, lng: 18.270302 },
    { lat: 48.8596417, lng: 18.2710069 },
    { lat: 48.8595238, lng: 18.2714114 },
    { lat: 48.8594907, lng: 18.271783 },
    { lat: 48.8594048, lng: 18.2717616 },
    { lat: 48.8593829, lng: 18.2717924 },
    { lat: 48.8591354, lng: 18.2722221 },
    { lat: 48.8591977, lng: 18.2730825 },
    { lat: 48.8590091, lng: 18.2746145 },
    { lat: 48.8603713, lng: 18.2747786 },
    { lat: 48.8605943, lng: 18.2771404 },
    { lat: 48.862292, lng: 18.2778302 },
    { lat: 48.8630522, lng: 18.2787203 },
    { lat: 48.8637527, lng: 18.2786191 },
    { lat: 48.8641677, lng: 18.279314 },
    { lat: 48.864503, lng: 18.2798151 },
    { lat: 48.8648619, lng: 18.2801314 },
    { lat: 48.8651723, lng: 18.2799362 },
    { lat: 48.8654641, lng: 18.2800075 },
    { lat: 48.8658566, lng: 18.2793906 },
    { lat: 48.8662656, lng: 18.2796774 },
    { lat: 48.8667489, lng: 18.2792647 },
    { lat: 48.8676014, lng: 18.2785001 },
    { lat: 48.8682462, lng: 18.2794268 },
    { lat: 48.8693469, lng: 18.2785033 },
    { lat: 48.8703223, lng: 18.2797658 },
    { lat: 48.8704118, lng: 18.28019 },
    { lat: 48.8703307, lng: 18.2805729 },
    { lat: 48.8717184, lng: 18.280989 },
    { lat: 48.8717571, lng: 18.2811958 },
    { lat: 48.8713931, lng: 18.2817614 },
    { lat: 48.8721917, lng: 18.2834306 },
    { lat: 48.8737765, lng: 18.2830262 },
    { lat: 48.8750745, lng: 18.2825756 },
    { lat: 48.8761245, lng: 18.2815314 },
    { lat: 48.8770121, lng: 18.2807265 },
    { lat: 48.8779813, lng: 18.2809287 },
    { lat: 48.8787256, lng: 18.2796464 },
    { lat: 48.8794198, lng: 18.2823409 },
    { lat: 48.8795692, lng: 18.2829882 },
    { lat: 48.8801341, lng: 18.2843386 },
    { lat: 48.8805266, lng: 18.2861093 },
    { lat: 48.8814086, lng: 18.2870822 },
    { lat: 48.8816025, lng: 18.2872957 },
    { lat: 48.8826307, lng: 18.2865837 },
    { lat: 48.8840185, lng: 18.2879795 },
    { lat: 48.88471, lng: 18.289503 },
    { lat: 48.885299, lng: 18.289226 },
    { lat: 48.886388, lng: 18.289257 },
    { lat: 48.886521, lng: 18.287418 },
    { lat: 48.886523, lng: 18.286922 },
    { lat: 48.886254, lng: 18.28603 },
    { lat: 48.886106, lng: 18.284456 },
    { lat: 48.886127, lng: 18.283925 },
    { lat: 48.886025, lng: 18.282501 },
    { lat: 48.885874, lng: 18.281556 },
    { lat: 48.88584, lng: 18.280969 },
    { lat: 48.885863, lng: 18.280346 },
    { lat: 48.885655, lng: 18.279371 },
    { lat: 48.885452, lng: 18.278651 },
    { lat: 48.884975, lng: 18.277993 },
    { lat: 48.884452, lng: 18.276724 },
    { lat: 48.884118, lng: 18.276142 },
    { lat: 48.884129, lng: 18.275331 },
    { lat: 48.884363, lng: 18.274736 },
    { lat: 48.884395, lng: 18.273634 },
    { lat: 48.884295, lng: 18.27279 },
    { lat: 48.884203, lng: 18.272289 },
    { lat: 48.884082, lng: 18.27157 },
    { lat: 48.883875, lng: 18.270488 },
    { lat: 48.883724, lng: 18.269402 },
    { lat: 48.883301, lng: 18.268505 },
    { lat: 48.883142, lng: 18.267309 },
    { lat: 48.882608, lng: 18.266619 },
    { lat: 48.881843, lng: 18.263612 },
    { lat: 48.881443, lng: 18.2625 },
    { lat: 48.880577, lng: 18.260617 },
    { lat: 48.880346, lng: 18.260141 },
    { lat: 48.880244, lng: 18.259815 },
    { lat: 48.880173, lng: 18.259237 },
    { lat: 48.880107, lng: 18.258228 },
    { lat: 48.880101, lng: 18.258156 },
    { lat: 48.8798788, lng: 18.2572741 },
    { lat: 48.8794467, lng: 18.2571342 },
    { lat: 48.8776627, lng: 18.2557029 },
    { lat: 48.8765254, lng: 18.25507 },
    { lat: 48.8746912, lng: 18.2532906 },
    { lat: 48.8745375, lng: 18.2530078 },
    { lat: 48.8742329, lng: 18.2526742 },
    { lat: 48.8739264, lng: 18.2524816 },
    { lat: 48.8732709, lng: 18.2523426 },
    { lat: 48.8729642, lng: 18.2519303 },
    { lat: 48.8725394, lng: 18.2517038 },
    { lat: 48.8719526, lng: 18.2509468 },
    { lat: 48.8714826, lng: 18.2514143 },
    { lat: 48.8711796, lng: 18.2511615 },
    { lat: 48.8709973, lng: 18.2509541 },
    { lat: 48.8706885, lng: 18.2507827 },
    { lat: 48.8704412, lng: 18.2507795 },
    { lat: 48.8701669, lng: 18.2508541 },
    { lat: 48.8697155, lng: 18.2508332 },
    { lat: 48.8694805, lng: 18.2507281 },
    { lat: 48.86917, lng: 18.2506454 },
    { lat: 48.8689667, lng: 18.2505458 },
    { lat: 48.8685623, lng: 18.2501579 },
    { lat: 48.8682555, lng: 18.2496651 },
    { lat: 48.8681269, lng: 18.2493866 },
    { lat: 48.8680844, lng: 18.2491975 },
    { lat: 48.868072, lng: 18.249017 },
    { lat: 48.8680257, lng: 18.2488378 },
    { lat: 48.8679623, lng: 18.2486547 },
    { lat: 48.8677664, lng: 18.2483449 },
    { lat: 48.8676883, lng: 18.2481659 },
    { lat: 48.867514, lng: 18.2475995 },
    { lat: 48.8674787, lng: 18.2466802 },
    { lat: 48.8673356, lng: 18.2458531 },
    { lat: 48.8672672, lng: 18.2455828 },
    { lat: 48.8672697, lng: 18.2452686 },
    { lat: 48.8672272, lng: 18.2449017 },
    { lat: 48.8671796, lng: 18.2446596 },
    { lat: 48.8670773, lng: 18.2444106 },
    { lat: 48.8669661, lng: 18.2442392 },
    { lat: 48.8664029, lng: 18.2437423 },
    { lat: 48.8660652, lng: 18.2436367 },
    { lat: 48.8657202, lng: 18.2432042 },
    { lat: 48.8656238, lng: 18.2430605 },
    { lat: 48.8651463, lng: 18.2430324 },
    { lat: 48.8648853, lng: 18.2429669 },
    { lat: 48.8647708, lng: 18.2429005 },
    { lat: 48.8645743, lng: 18.2427378 },
    { lat: 48.8644129, lng: 18.2425614 },
    { lat: 48.8642926, lng: 18.2423872 },
    { lat: 48.8641594, lng: 18.2421115 },
    { lat: 48.8638651, lng: 18.2418354 },
    { lat: 48.8637223, lng: 18.2416072 },
    { lat: 48.863513, lng: 18.2413618 },
    { lat: 48.8632414, lng: 18.2412293 },
    { lat: 48.8629725, lng: 18.241262 },
    { lat: 48.8625876, lng: 18.2409799 },
    { lat: 48.8623638, lng: 18.2408372 },
    { lat: 48.8621403, lng: 18.2406367 },
    { lat: 48.8620107, lng: 18.2404204 },
    { lat: 48.8617735, lng: 18.2401791 },
    { lat: 48.8616809, lng: 18.239882 },
    { lat: 48.8616923, lng: 18.2392048 },
    { lat: 48.8610818, lng: 18.2381933 },
    { lat: 48.8610004, lng: 18.2379637 },
    { lat: 48.8607067, lng: 18.2370866 },
    { lat: 48.8604231, lng: 18.2366521 },
    { lat: 48.860213, lng: 18.2364388 },
    { lat: 48.8600618, lng: 18.2351929 },
    { lat: 48.8605586, lng: 18.2343484 },
    { lat: 48.8589534, lng: 18.2333454 },
    { lat: 48.8578583, lng: 18.2339892 },
    { lat: 48.857421, lng: 18.2341576 },
    { lat: 48.8570677, lng: 18.234153 },
    { lat: 48.8568195, lng: 18.2341748 },
    { lat: 48.8567023, lng: 18.2342969 },
    { lat: 48.8565292, lng: 18.2343078 },
    { lat: 48.8559446, lng: 18.2342855 },
    { lat: 48.8556438, lng: 18.2345119 },
    { lat: 48.8553115, lng: 18.2346594 },
    { lat: 48.8546526, lng: 18.2346615 },
    { lat: 48.8535352, lng: 18.2341182 },
    { lat: 48.8529916, lng: 18.2338075 },
    { lat: 48.8526593, lng: 18.2325719 },
    { lat: 48.8519504, lng: 18.2326448 },
    { lat: 48.8514608, lng: 18.2326101 },
    { lat: 48.8512367, lng: 18.2325191 },
    { lat: 48.8507978, lng: 18.2324773 },
    { lat: 48.8504023, lng: 18.2324865 },
    { lat: 48.8500164, lng: 18.2325271 },
    { lat: 48.8495579, lng: 18.23236 },
    { lat: 48.8487408, lng: 18.2322998 },
    { lat: 48.8481334, lng: 18.232469 },
    { lat: 48.8469256, lng: 18.232858 },
    { lat: 48.845592, lng: 18.2324836 },
    { lat: 48.8446338, lng: 18.2326611 },
    { lat: 48.8434845, lng: 18.2329851 },
    { lat: 48.843492, lng: 18.2332313 },
    { lat: 48.843481, lng: 18.2341072 },
    { lat: 48.8434766, lng: 18.2341507 },
    { lat: 48.842915, lng: 18.2342942 },
    { lat: 48.8430581, lng: 18.2357753 },
    { lat: 48.8424958, lng: 18.2360016 },
    { lat: 48.8427229, lng: 18.2368985 },
    { lat: 48.8428159, lng: 18.2373603 },
    { lat: 48.8428905, lng: 18.2379763 },
    { lat: 48.8429114, lng: 18.2382838 },
    { lat: 48.8427949, lng: 18.2382808 },
    { lat: 48.8427393, lng: 18.2382139 },
    { lat: 48.8425399, lng: 18.2381215 },
    { lat: 48.8422391, lng: 18.2379928 },
    { lat: 48.8421841, lng: 18.2385907 },
    { lat: 48.8422805, lng: 18.2387977 },
    { lat: 48.8422582, lng: 18.2390983 },
    { lat: 48.842209, lng: 18.2392233 },
    { lat: 48.8421909, lng: 18.2394603 },
    { lat: 48.842164, lng: 18.2395444 },
    { lat: 48.8421105, lng: 18.239555 },
    { lat: 48.8420505, lng: 18.2396102 },
    { lat: 48.8419123, lng: 18.2398783 },
    { lat: 48.8418278, lng: 18.2399599 },
    { lat: 48.8417519, lng: 18.2400716 },
    { lat: 48.8416905, lng: 18.2403003 },
    { lat: 48.8417011, lng: 18.2406073 },
    { lat: 48.8416363, lng: 18.2408547 },
  ],
};

export default UnitsBánovcenadBebravou;
