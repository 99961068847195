const UnitsPrešov = {
  Chmeľovec: [
    { lat: 49.0723895, lng: 21.3878907 },
    { lat: 49.0725806, lng: 21.3870116 },
    { lat: 49.0729483, lng: 21.386011 },
    { lat: 49.0733079, lng: 21.3854671 },
    { lat: 49.0744432, lng: 21.3844724 },
    { lat: 49.0746542, lng: 21.3837725 },
    { lat: 49.0750692, lng: 21.3833482 },
    { lat: 49.0753335, lng: 21.383269 },
    { lat: 49.0756348, lng: 21.3835251 },
    { lat: 49.0757952, lng: 21.3833576 },
    { lat: 49.0758845, lng: 21.3830299 },
    { lat: 49.0765034, lng: 21.3828066 },
    { lat: 49.0768301, lng: 21.3829299 },
    { lat: 49.0770892, lng: 21.3832506 },
    { lat: 49.0774946, lng: 21.3833707 },
    { lat: 49.0775749, lng: 21.3832342 },
    { lat: 49.0777961, lng: 21.383107 },
    { lat: 49.0779077, lng: 21.3827674 },
    { lat: 49.0785708, lng: 21.3828982 },
    { lat: 49.0785701, lng: 21.383064 },
    { lat: 49.0789839, lng: 21.3827869 },
    { lat: 49.0794151, lng: 21.3827208 },
    { lat: 49.0797027, lng: 21.3825923 },
    { lat: 49.0799579, lng: 21.3829233 },
    { lat: 49.080028, lng: 21.3829415 },
    { lat: 49.080797, lng: 21.3828798 },
    { lat: 49.0811213, lng: 21.3826935 },
    { lat: 49.0814859, lng: 21.3827374 },
    { lat: 49.081685, lng: 21.3825091 },
    { lat: 49.0818427, lng: 21.3824366 },
    { lat: 49.0824447, lng: 21.3824782 },
    { lat: 49.0826655, lng: 21.3824082 },
    { lat: 49.0834074, lng: 21.3825332 },
    { lat: 49.0834654, lng: 21.3826369 },
    { lat: 49.0838096, lng: 21.3823638 },
    { lat: 49.0840416, lng: 21.3822937 },
    { lat: 49.084141, lng: 21.3823874 },
    { lat: 49.0843943, lng: 21.3824694 },
    { lat: 49.0844166, lng: 21.3826883 },
    { lat: 49.0857491, lng: 21.3824695 },
    { lat: 49.0859569, lng: 21.3822646 },
    { lat: 49.0860479, lng: 21.3820333 },
    { lat: 49.0862473, lng: 21.3817346 },
    { lat: 49.0860829, lng: 21.3801711 },
    { lat: 49.086084, lng: 21.3795637 },
    { lat: 49.0863587, lng: 21.3794206 },
    { lat: 49.0865251, lng: 21.3801752 },
    { lat: 49.0884131, lng: 21.3797172 },
    { lat: 49.0905719, lng: 21.3788682 },
    { lat: 49.0920397, lng: 21.3778258 },
    { lat: 49.0937806, lng: 21.3762407 },
    { lat: 49.0946527, lng: 21.3772255 },
    { lat: 49.0964126, lng: 21.3776195 },
    { lat: 49.097681, lng: 21.3783481 },
    { lat: 49.0988455, lng: 21.377735 },
    { lat: 49.1016414, lng: 21.3760265 },
    { lat: 49.1039585, lng: 21.374859 },
    { lat: 49.1036909, lng: 21.3753995 },
    { lat: 49.1035817, lng: 21.3766466 },
    { lat: 49.1038463, lng: 21.3773732 },
    { lat: 49.1041029, lng: 21.3777602 },
    { lat: 49.1050565, lng: 21.3770929 },
    { lat: 49.1063398, lng: 21.3759798 },
    { lat: 49.1067633, lng: 21.3760268 },
    { lat: 49.1073806, lng: 21.3759025 },
    { lat: 49.1090728, lng: 21.3736178 },
    { lat: 49.1109426, lng: 21.3728908 },
    { lat: 49.1115209, lng: 21.372776 },
    { lat: 49.1122767, lng: 21.3725034 },
    { lat: 49.1124756, lng: 21.3721881 },
    { lat: 49.1127875, lng: 21.3720046 },
    { lat: 49.1134735, lng: 21.3711967 },
    { lat: 49.1145396, lng: 21.3704958 },
    { lat: 49.1153425, lng: 21.3716902 },
    { lat: 49.1156722, lng: 21.3715287 },
    { lat: 49.115452, lng: 21.371053 },
    { lat: 49.115258, lng: 21.369822 },
    { lat: 49.115255, lng: 21.36975 },
    { lat: 49.1151649, lng: 21.3696103 },
    { lat: 49.115179, lng: 21.369361 },
    { lat: 49.115176, lng: 21.369345 },
    { lat: 49.1150858, lng: 21.3692311 },
    { lat: 49.1152048, lng: 21.3690381 },
    { lat: 49.1151559, lng: 21.3685966 },
    { lat: 49.1150933, lng: 21.3684507 },
    { lat: 49.115052, lng: 21.3681853 },
    { lat: 49.1150036, lng: 21.3677817 },
    { lat: 49.1149505, lng: 21.3676106 },
    { lat: 49.1149443, lng: 21.3673522 },
    { lat: 49.114909, lng: 21.367286 },
    { lat: 49.114932, lng: 21.36699 },
    { lat: 49.114818, lng: 21.366707 },
    { lat: 49.114896, lng: 21.366458 },
    { lat: 49.115033, lng: 21.366312 },
    { lat: 49.114998, lng: 21.365964 },
    { lat: 49.114899, lng: 21.365817 },
    { lat: 49.114845, lng: 21.365375 },
    { lat: 49.114809, lng: 21.365148 },
    { lat: 49.114875, lng: 21.364856 },
    { lat: 49.11492, lng: 21.364719 },
    { lat: 49.114866, lng: 21.364633 },
    { lat: 49.114736, lng: 21.364506 },
    { lat: 49.114759, lng: 21.36437 },
    { lat: 49.114703, lng: 21.364292 },
    { lat: 49.114583, lng: 21.364344 },
    { lat: 49.114438, lng: 21.364094 },
    { lat: 49.114321, lng: 21.363852 },
    { lat: 49.114181, lng: 21.363903 },
    { lat: 49.114151, lng: 21.363603 },
    { lat: 49.114033, lng: 21.363447 },
    { lat: 49.113872, lng: 21.36317 },
    { lat: 49.11406, lng: 21.362907 },
    { lat: 49.113913, lng: 21.36266 },
    { lat: 49.113967, lng: 21.362441 },
    { lat: 49.1139527, lng: 21.3624057 },
    { lat: 49.1138017, lng: 21.3624747 },
    { lat: 49.1137037, lng: 21.3629221 },
    { lat: 49.1135704, lng: 21.363131 },
    { lat: 49.1133978, lng: 21.3630434 },
    { lat: 49.1124885, lng: 21.3631899 },
    { lat: 49.112353, lng: 21.3631382 },
    { lat: 49.1115824, lng: 21.3635569 },
    { lat: 49.1108497, lng: 21.3638133 },
    { lat: 49.1103571, lng: 21.3612513 },
    { lat: 49.1105866, lng: 21.3612039 },
    { lat: 49.1103286, lng: 21.3597621 },
    { lat: 49.1097032, lng: 21.3601871 },
    { lat: 49.1093412, lng: 21.3605331 },
    { lat: 49.1091459, lng: 21.3610455 },
    { lat: 49.1090026, lng: 21.3612504 },
    { lat: 49.1085978, lng: 21.3616008 },
    { lat: 49.1080947, lng: 21.3614122 },
    { lat: 49.1075114, lng: 21.3616201 },
    { lat: 49.1070905, lng: 21.3618478 },
    { lat: 49.1068556, lng: 21.3618144 },
    { lat: 49.1064023, lng: 21.3619362 },
    { lat: 49.1061079, lng: 21.3617583 },
    { lat: 49.1054079, lng: 21.3619427 },
    { lat: 49.1049906, lng: 21.3621712 },
    { lat: 49.1041262, lng: 21.3625059 },
    { lat: 49.103834, lng: 21.3625576 },
    { lat: 49.1035583, lng: 21.3624445 },
    { lat: 49.1035286, lng: 21.362499 },
    { lat: 49.1032138, lng: 21.3618632 },
    { lat: 49.1030813, lng: 21.3616906 },
    { lat: 49.102261, lng: 21.3609695 },
    { lat: 49.1019491, lng: 21.3605813 },
    { lat: 49.1015512, lng: 21.3603333 },
    { lat: 49.1011633, lng: 21.3603396 },
    { lat: 49.1010592, lng: 21.3601687 },
    { lat: 49.1002644, lng: 21.3600038 },
    { lat: 49.1000745, lng: 21.3605074 },
    { lat: 49.0998394, lng: 21.360654 },
    { lat: 49.0997219, lng: 21.3606449 },
    { lat: 49.0993066, lng: 21.3600714 },
    { lat: 49.0988838, lng: 21.3596277 },
    { lat: 49.0987747, lng: 21.3598191 },
    { lat: 49.0984137, lng: 21.3600881 },
    { lat: 49.09808, lng: 21.3604412 },
    { lat: 49.0977937, lng: 21.3608825 },
    { lat: 49.0974539, lng: 21.3607713 },
    { lat: 49.0970562, lng: 21.3610511 },
    { lat: 49.0969032, lng: 21.3602176 },
    { lat: 49.0967002, lng: 21.3596804 },
    { lat: 49.0961345, lng: 21.3595371 },
    { lat: 49.0956742, lng: 21.3587747 },
    { lat: 49.0954232, lng: 21.3585859 },
    { lat: 49.0952722, lng: 21.3585748 },
    { lat: 49.094756, lng: 21.3588097 },
    { lat: 49.0942956, lng: 21.3594063 },
    { lat: 49.0938198, lng: 21.3597949 },
    { lat: 49.0912733, lng: 21.3585426 },
    { lat: 49.0886899, lng: 21.3608394 },
    { lat: 49.0844446, lng: 21.3612156 },
    { lat: 49.0840015, lng: 21.3610423 },
    { lat: 49.0838207, lng: 21.3612012 },
    { lat: 49.0837694, lng: 21.3613315 },
    { lat: 49.0817065, lng: 21.3610908 },
    { lat: 49.0815712, lng: 21.3611174 },
    { lat: 49.081373, lng: 21.3619426 },
    { lat: 49.0812154, lng: 21.3618279 },
    { lat: 49.0812935, lng: 21.361536 },
    { lat: 49.0809573, lng: 21.3613568 },
    { lat: 49.0810783, lng: 21.3608912 },
    { lat: 49.080979, lng: 21.3608029 },
    { lat: 49.0802753, lng: 21.3604487 },
    { lat: 49.0799453, lng: 21.3603958 },
    { lat: 49.0794916, lng: 21.3604676 },
    { lat: 49.0796268, lng: 21.35979 },
    { lat: 49.0785773, lng: 21.3600638 },
    { lat: 49.0774346, lng: 21.3595526 },
    { lat: 49.0774246, lng: 21.3594495 },
    { lat: 49.0751551, lng: 21.3604273 },
    { lat: 49.0748627, lng: 21.3606539 },
    { lat: 49.0746413, lng: 21.360671 },
    { lat: 49.0744414, lng: 21.3605677 },
    { lat: 49.0743056, lng: 21.3606166 },
    { lat: 49.0740579, lng: 21.361102 },
    { lat: 49.0736885, lng: 21.3613181 },
    { lat: 49.0734346, lng: 21.3612897 },
    { lat: 49.0729315, lng: 21.3615914 },
    { lat: 49.0726023, lng: 21.3617164 },
    { lat: 49.072157, lng: 21.3619563 },
    { lat: 49.072015, lng: 21.3621767 },
    { lat: 49.071698, lng: 21.3623411 },
    { lat: 49.0706738, lng: 21.3627455 },
    { lat: 49.0702102, lng: 21.3628524 },
    { lat: 49.0698978, lng: 21.3630929 },
    { lat: 49.0693255, lng: 21.3631836 },
    { lat: 49.0689462, lng: 21.36341 },
    { lat: 49.067861, lng: 21.3638071 },
    { lat: 49.0674349, lng: 21.3641565 },
    { lat: 49.0665173, lng: 21.3645528 },
    { lat: 49.0661355, lng: 21.3648439 },
    { lat: 49.0656556, lng: 21.3650876 },
    { lat: 49.0652715, lng: 21.366174 },
    { lat: 49.064739, lng: 21.3663528 },
    { lat: 49.0645997, lng: 21.3660613 },
    { lat: 49.0643351, lng: 21.3652596 },
    { lat: 49.0641763, lng: 21.3649832 },
    { lat: 49.0637112, lng: 21.3650507 },
    { lat: 49.0632816, lng: 21.3652081 },
    { lat: 49.0630654, lng: 21.3654314 },
    { lat: 49.0630092, lng: 21.3656194 },
    { lat: 49.0630438, lng: 21.3659887 },
    { lat: 49.0625696, lng: 21.3663942 },
    { lat: 49.0625019, lng: 21.3660433 },
    { lat: 49.0623618, lng: 21.3659851 },
    { lat: 49.0622674, lng: 21.3658409 },
    { lat: 49.0617303, lng: 21.366166 },
    { lat: 49.0616135, lng: 21.3659792 },
    { lat: 49.0615, lng: 21.3660251 },
    { lat: 49.0613976, lng: 21.3662528 },
    { lat: 49.0611704, lng: 21.3663345 },
    { lat: 49.0607406, lng: 21.3662557 },
    { lat: 49.0606082, lng: 21.3663793 },
    { lat: 49.0603901, lng: 21.3661647 },
    { lat: 49.0601177, lng: 21.3662347 },
    { lat: 49.0597679, lng: 21.3661964 },
    { lat: 49.0597166, lng: 21.3668579 },
    { lat: 49.0595638, lng: 21.3672459 },
    { lat: 49.0595025, lng: 21.367986 },
    { lat: 49.0595943, lng: 21.3683449 },
    { lat: 49.0595642, lng: 21.3692285 },
    { lat: 49.0596775, lng: 21.3697481 },
    { lat: 49.059666, lng: 21.3701134 },
    { lat: 49.0594596, lng: 21.3705169 },
    { lat: 49.0594487, lng: 21.3708728 },
    { lat: 49.059639, lng: 21.3712928 },
    { lat: 49.0595661, lng: 21.3723569 },
    { lat: 49.0596754, lng: 21.3742596 },
    { lat: 49.0617617, lng: 21.3745335 },
    { lat: 49.0622566, lng: 21.3730482 },
    { lat: 49.0646748, lng: 21.3730987 },
    { lat: 49.0652165, lng: 21.3728483 },
    { lat: 49.0658186, lng: 21.3727011 },
    { lat: 49.0670215, lng: 21.37289 },
    { lat: 49.0671956, lng: 21.3728447 },
    { lat: 49.0674718, lng: 21.3725146 },
    { lat: 49.0683108, lng: 21.3722003 },
    { lat: 49.0693208, lng: 21.3719213 },
    { lat: 49.0698811, lng: 21.3750687 },
    { lat: 49.0701681, lng: 21.3764011 },
    { lat: 49.0701979, lng: 21.3771749 },
    { lat: 49.0700314, lng: 21.3775219 },
    { lat: 49.0699927, lng: 21.3780384 },
    { lat: 49.0689102, lng: 21.3789844 },
    { lat: 49.0693047, lng: 21.3798767 },
    { lat: 49.0696701, lng: 21.3804003 },
    { lat: 49.06971, lng: 21.3807607 },
    { lat: 49.0696005, lng: 21.3812444 },
    { lat: 49.070216, lng: 21.3839822 },
    { lat: 49.0702426, lng: 21.3845058 },
    { lat: 49.0701856, lng: 21.3850903 },
    { lat: 49.0702883, lng: 21.3855889 },
    { lat: 49.0718267, lng: 21.3873489 },
    { lat: 49.0723895, lng: 21.3878907 },
  ],
  Nemcovce: [
    { lat: 49.0723895, lng: 21.3878907 },
    { lat: 49.0718267, lng: 21.3873489 },
    { lat: 49.0702883, lng: 21.3855889 },
    { lat: 49.0701856, lng: 21.3850903 },
    { lat: 49.0702426, lng: 21.3845058 },
    { lat: 49.070216, lng: 21.3839822 },
    { lat: 49.0696005, lng: 21.3812444 },
    { lat: 49.06971, lng: 21.3807607 },
    { lat: 49.0696701, lng: 21.3804003 },
    { lat: 49.0693047, lng: 21.3798767 },
    { lat: 49.0689102, lng: 21.3789844 },
    { lat: 49.0699927, lng: 21.3780384 },
    { lat: 49.0700314, lng: 21.3775219 },
    { lat: 49.0701979, lng: 21.3771749 },
    { lat: 49.0701681, lng: 21.3764011 },
    { lat: 49.0698811, lng: 21.3750687 },
    { lat: 49.0693208, lng: 21.3719213 },
    { lat: 49.0683108, lng: 21.3722003 },
    { lat: 49.0674718, lng: 21.3725146 },
    { lat: 49.0671956, lng: 21.3728447 },
    { lat: 49.0670215, lng: 21.37289 },
    { lat: 49.0658186, lng: 21.3727011 },
    { lat: 49.0652165, lng: 21.3728483 },
    { lat: 49.0646748, lng: 21.3730987 },
    { lat: 49.0622566, lng: 21.3730482 },
    { lat: 49.0617617, lng: 21.3745335 },
    { lat: 49.0596754, lng: 21.3742596 },
    { lat: 49.0595661, lng: 21.3723569 },
    { lat: 49.059639, lng: 21.3712928 },
    { lat: 49.0594487, lng: 21.3708728 },
    { lat: 49.0594596, lng: 21.3705169 },
    { lat: 49.059666, lng: 21.3701134 },
    { lat: 49.0596775, lng: 21.3697481 },
    { lat: 49.0595642, lng: 21.3692285 },
    { lat: 49.0595943, lng: 21.3683449 },
    { lat: 49.0595025, lng: 21.367986 },
    { lat: 49.0595638, lng: 21.3672459 },
    { lat: 49.0597166, lng: 21.3668579 },
    { lat: 49.0597679, lng: 21.3661964 },
    { lat: 49.0597126, lng: 21.3663455 },
    { lat: 49.0596687, lng: 21.3661591 },
    { lat: 49.0595369, lng: 21.3663027 },
    { lat: 49.0593132, lng: 21.3661208 },
    { lat: 49.059203, lng: 21.3658579 },
    { lat: 49.0591926, lng: 21.3657387 },
    { lat: 49.0592649, lng: 21.365592 },
    { lat: 49.0592136, lng: 21.3654607 },
    { lat: 49.0592917, lng: 21.3653193 },
    { lat: 49.0591097, lng: 21.3649562 },
    { lat: 49.0590416, lng: 21.3650456 },
    { lat: 49.0589804, lng: 21.3649526 },
    { lat: 49.0585356, lng: 21.3639593 },
    { lat: 49.0582187, lng: 21.3624624 },
    { lat: 49.0576985, lng: 21.3624918 },
    { lat: 49.0568577, lng: 21.3626649 },
    { lat: 49.0566528, lng: 21.3628147 },
    { lat: 49.0565985, lng: 21.3634846 },
    { lat: 49.0563156, lng: 21.3637323 },
    { lat: 49.0563804, lng: 21.3645465 },
    { lat: 49.0551641, lng: 21.3652316 },
    { lat: 49.0551133, lng: 21.3645779 },
    { lat: 49.0548951, lng: 21.3633007 },
    { lat: 49.0545146, lng: 21.363333 },
    { lat: 49.0541262, lng: 21.3632827 },
    { lat: 49.0541186, lng: 21.3631461 },
    { lat: 49.0540197, lng: 21.3630712 },
    { lat: 49.0534871, lng: 21.3632485 },
    { lat: 49.051735, lng: 21.3632405 },
    { lat: 49.0518823, lng: 21.3629358 },
    { lat: 49.0519998, lng: 21.3623448 },
    { lat: 49.0521132, lng: 21.3620374 },
    { lat: 49.0520739, lng: 21.3619271 },
    { lat: 49.0521211, lng: 21.3616218 },
    { lat: 49.0520718, lng: 21.3612557 },
    { lat: 49.0520283, lng: 21.3611152 },
    { lat: 49.0518787, lng: 21.3610007 },
    { lat: 49.0515524, lng: 21.3603237 },
    { lat: 49.0513808, lng: 21.3601906 },
    { lat: 49.0513036, lng: 21.3602013 },
    { lat: 49.0500565, lng: 21.361135 },
    { lat: 49.0495585, lng: 21.3613784 },
    { lat: 49.049558, lng: 21.3612841 },
    { lat: 49.0487749, lng: 21.3614784 },
    { lat: 49.0483575, lng: 21.3613647 },
    { lat: 49.0480049, lng: 21.361464 },
    { lat: 49.0477729, lng: 21.3613477 },
    { lat: 49.0475219, lng: 21.361412 },
    { lat: 49.0473889, lng: 21.3615241 },
    { lat: 49.0474835, lng: 21.3627669 },
    { lat: 49.0463467, lng: 21.3628366 },
    { lat: 49.0463211, lng: 21.3631476 },
    { lat: 49.0463741, lng: 21.3664041 },
    { lat: 49.0462979, lng: 21.3672425 },
    { lat: 49.0460969, lng: 21.3683952 },
    { lat: 49.0456958, lng: 21.3698141 },
    { lat: 49.0453665, lng: 21.3707722 },
    { lat: 49.0451076, lng: 21.3722864 },
    { lat: 49.0450076, lng: 21.3735419 },
    { lat: 49.045039, lng: 21.3743374 },
    { lat: 49.0453888, lng: 21.3776703 },
    { lat: 49.0453627, lng: 21.3787635 },
    { lat: 49.0441623, lng: 21.3868639 },
    { lat: 49.0442613, lng: 21.3876275 },
    { lat: 49.0443832, lng: 21.3878652 },
    { lat: 49.0443044, lng: 21.3880568 },
    { lat: 49.0443374, lng: 21.3882159 },
    { lat: 49.0444378, lng: 21.3883012 },
    { lat: 49.0444906, lng: 21.3887158 },
    { lat: 49.0455343, lng: 21.3885128 },
    { lat: 49.0461865, lng: 21.3911633 },
    { lat: 49.0468294, lng: 21.3910268 },
    { lat: 49.0468154, lng: 21.3912405 },
    { lat: 49.0470133, lng: 21.3917277 },
    { lat: 49.0471259, lng: 21.3923391 },
    { lat: 49.0471997, lng: 21.3936253 },
    { lat: 49.0471757, lng: 21.3941808 },
    { lat: 49.0472967, lng: 21.3943562 },
    { lat: 49.0473901, lng: 21.3943979 },
    { lat: 49.0470593, lng: 21.3945482 },
    { lat: 49.0468688, lng: 21.3948051 },
    { lat: 49.0478111, lng: 21.3986713 },
    { lat: 49.0487686, lng: 21.3983674 },
    { lat: 49.0489243, lng: 21.398803 },
    { lat: 49.0489596, lng: 21.3992013 },
    { lat: 49.0489995, lng: 21.4000204 },
    { lat: 49.0490532, lng: 21.4000095 },
    { lat: 49.0490616, lng: 21.4001438 },
    { lat: 49.0494361, lng: 21.3999465 },
    { lat: 49.0501656, lng: 21.3998006 },
    { lat: 49.0527073, lng: 21.3990009 },
    { lat: 49.0528436, lng: 21.4002289 },
    { lat: 49.0537773, lng: 21.3999956 },
    { lat: 49.0542643, lng: 21.3990734 },
    { lat: 49.054868, lng: 21.3974918 },
    { lat: 49.0557845, lng: 21.3977733 },
    { lat: 49.0559845, lng: 21.3976542 },
    { lat: 49.0567083, lng: 21.3959409 },
    { lat: 49.057001, lng: 21.3958014 },
    { lat: 49.0572308, lng: 21.395573 },
    { lat: 49.0573031, lng: 21.3955472 },
    { lat: 49.0570882, lng: 21.3942485 },
    { lat: 49.0569568, lng: 21.3936863 },
    { lat: 49.056811, lng: 21.3932855 },
    { lat: 49.057816, lng: 21.3928021 },
    { lat: 49.0586066, lng: 21.39309 },
    { lat: 49.0587022, lng: 21.3932036 },
    { lat: 49.0587677, lng: 21.3934352 },
    { lat: 49.0594159, lng: 21.3930218 },
    { lat: 49.05951, lng: 21.392743 },
    { lat: 49.0597064, lng: 21.3926062 },
    { lat: 49.0599745, lng: 21.3922786 },
    { lat: 49.0600054, lng: 21.3921061 },
    { lat: 49.0604101, lng: 21.3920135 },
    { lat: 49.0606324, lng: 21.3917324 },
    { lat: 49.0609067, lng: 21.3915335 },
    { lat: 49.0610776, lng: 21.3911491 },
    { lat: 49.0613361, lng: 21.3910636 },
    { lat: 49.0618579, lng: 21.3910896 },
    { lat: 49.0619958, lng: 21.3913037 },
    { lat: 49.0623198, lng: 21.3913376 },
    { lat: 49.062542, lng: 21.3916611 },
    { lat: 49.0625738, lng: 21.3918993 },
    { lat: 49.0628695, lng: 21.3918913 },
    { lat: 49.062984, lng: 21.3921927 },
    { lat: 49.0631895, lng: 21.3924062 },
    { lat: 49.0634306, lng: 21.3931055 },
    { lat: 49.0635908, lng: 21.3932697 },
    { lat: 49.0637893, lng: 21.393898 },
    { lat: 49.0639662, lng: 21.3942447 },
    { lat: 49.0639056, lng: 21.3946687 },
    { lat: 49.0640435, lng: 21.3947708 },
    { lat: 49.0641474, lng: 21.3947458 },
    { lat: 49.0642248, lng: 21.3949619 },
    { lat: 49.064206, lng: 21.395317 },
    { lat: 49.0643414, lng: 21.3953343 },
    { lat: 49.0644855, lng: 21.3952236 },
    { lat: 49.0646073, lng: 21.3952705 },
    { lat: 49.0646651, lng: 21.3954385 },
    { lat: 49.0647301, lng: 21.395429 },
    { lat: 49.0647963, lng: 21.3952325 },
    { lat: 49.0651291, lng: 21.3951822 },
    { lat: 49.0654334, lng: 21.3953541 },
    { lat: 49.0655012, lng: 21.3953606 },
    { lat: 49.065593, lng: 21.3951953 },
    { lat: 49.0659161, lng: 21.3953168 },
    { lat: 49.0661158, lng: 21.3949494 },
    { lat: 49.0668837, lng: 21.3946698 },
    { lat: 49.0670963, lng: 21.3944328 },
    { lat: 49.0674242, lng: 21.3943347 },
    { lat: 49.0677234, lng: 21.3943368 },
    { lat: 49.0680978, lng: 21.394097 },
    { lat: 49.0683294, lng: 21.3940496 },
    { lat: 49.0687347, lng: 21.3936437 },
    { lat: 49.068906, lng: 21.3936474 },
    { lat: 49.0690605, lng: 21.3934327 },
    { lat: 49.0694281, lng: 21.3933846 },
    { lat: 49.0697639, lng: 21.3934747 },
    { lat: 49.0711027, lng: 21.3917188 },
    { lat: 49.0711459, lng: 21.391577 },
    { lat: 49.0711299, lng: 21.3913771 },
    { lat: 49.0712596, lng: 21.3912369 },
    { lat: 49.0713121, lng: 21.3913044 },
    { lat: 49.0714947, lng: 21.3908537 },
    { lat: 49.0716916, lng: 21.3908144 },
    { lat: 49.0718052, lng: 21.3905892 },
    { lat: 49.071824, lng: 21.3903891 },
    { lat: 49.0720092, lng: 21.3902558 },
    { lat: 49.0721675, lng: 21.3900501 },
    { lat: 49.0721505, lng: 21.3897785 },
    { lat: 49.0725064, lng: 21.3894488 },
    { lat: 49.0727333, lng: 21.3887568 },
    { lat: 49.0724916, lng: 21.3882993 },
    { lat: 49.0723895, lng: 21.3878907 },
  ],
  Klenov: [
    { lat: 48.9536376, lng: 21.0532892 },
    { lat: 48.9536265, lng: 21.0531518 },
    { lat: 48.9529785, lng: 21.0539219 },
    { lat: 48.9523296, lng: 21.0544583 },
    { lat: 48.9519065, lng: 21.0543922 },
    { lat: 48.9506, lng: 21.0537754 },
    { lat: 48.9503117, lng: 21.0544913 },
    { lat: 48.9501636, lng: 21.054651 },
    { lat: 48.9500213, lng: 21.0550849 },
    { lat: 48.9493704, lng: 21.0543076 },
    { lat: 48.947815, lng: 21.0537918 },
    { lat: 48.9476104, lng: 21.0535912 },
    { lat: 48.9472484, lng: 21.0520375 },
    { lat: 48.9470403, lng: 21.0517323 },
    { lat: 48.9469888, lng: 21.051744 },
    { lat: 48.9470106, lng: 21.0518917 },
    { lat: 48.9468509, lng: 21.0517952 },
    { lat: 48.9462802, lng: 21.0512021 },
    { lat: 48.9460317, lng: 21.0514151 },
    { lat: 48.9454991, lng: 21.0504425 },
    { lat: 48.9440191, lng: 21.0487139 },
    { lat: 48.9436654, lng: 21.0478755 },
    { lat: 48.9437057, lng: 21.0476555 },
    { lat: 48.9441542, lng: 21.0465022 },
    { lat: 48.9442292, lng: 21.0455915 },
    { lat: 48.9447037, lng: 21.0442941 },
    { lat: 48.9446364, lng: 21.0439612 },
    { lat: 48.9447517, lng: 21.0426643 },
    { lat: 48.9448124, lng: 21.0423859 },
    { lat: 48.9442174, lng: 21.0424299 },
    { lat: 48.9438664, lng: 21.0421601 },
    { lat: 48.9436346, lng: 21.0420888 },
    { lat: 48.9431671, lng: 21.0417326 },
    { lat: 48.9430021, lng: 21.041885 },
    { lat: 48.9424244, lng: 21.0414927 },
    { lat: 48.9419288, lng: 21.0408243 },
    { lat: 48.9417095, lng: 21.0404197 },
    { lat: 48.9409946, lng: 21.0387912 },
    { lat: 48.9407581, lng: 21.0384374 },
    { lat: 48.940272, lng: 21.0371307 },
    { lat: 48.9402812, lng: 21.0366907 },
    { lat: 48.9401237, lng: 21.0364881 },
    { lat: 48.9403962, lng: 21.0352872 },
    { lat: 48.9402404, lng: 21.0347548 },
    { lat: 48.9403407, lng: 21.0336538 },
    { lat: 48.9405566, lng: 21.0325464 },
    { lat: 48.9408374, lng: 21.031599 },
    { lat: 48.9414461, lng: 21.0303949 },
    { lat: 48.9415338, lng: 21.0301127 },
    { lat: 48.9415153, lng: 21.0295817 },
    { lat: 48.9415913, lng: 21.0279849 },
    { lat: 48.9413963, lng: 21.0267043 },
    { lat: 48.9415171, lng: 21.0265473 },
    { lat: 48.9415464, lng: 21.0256203 },
    { lat: 48.9416345, lng: 21.0247301 },
    { lat: 48.9416038, lng: 21.0244123 },
    { lat: 48.941656, lng: 21.0240468 },
    { lat: 48.9415709, lng: 21.0234789 },
    { lat: 48.9411104, lng: 21.0217941 },
    { lat: 48.9402429, lng: 21.0197069 },
    { lat: 48.9377554, lng: 21.01826 },
    { lat: 48.937107, lng: 21.018493 },
    { lat: 48.9368844, lng: 21.0176109 },
    { lat: 48.9362651, lng: 21.0172253 },
    { lat: 48.9355901, lng: 21.0177861 },
    { lat: 48.9347802, lng: 21.019236 },
    { lat: 48.9343101, lng: 21.0166419 },
    { lat: 48.9331283, lng: 21.0150986 },
    { lat: 48.9312167, lng: 21.012792 },
    { lat: 48.9301426, lng: 21.0117486 },
    { lat: 48.9291919, lng: 21.0115905 },
    { lat: 48.9281645, lng: 21.0111532 },
    { lat: 48.9277862, lng: 21.0107373 },
    { lat: 48.9269377, lng: 21.0091974 },
    { lat: 48.9271328, lng: 21.007602 },
    { lat: 48.926428, lng: 21.006447 },
    { lat: 48.925769, lng: 21.007744 },
    { lat: 48.925509, lng: 21.008448 },
    { lat: 48.925253, lng: 21.009044 },
    { lat: 48.924898, lng: 21.009873 },
    { lat: 48.924937, lng: 21.010151 },
    { lat: 48.924284, lng: 21.011193 },
    { lat: 48.924118, lng: 21.01159 },
    { lat: 48.924026, lng: 21.011847 },
    { lat: 48.923881, lng: 21.012701 },
    { lat: 48.923854, lng: 21.012915 },
    { lat: 48.923802, lng: 21.013514 },
    { lat: 48.922714, lng: 21.013119 },
    { lat: 48.922295, lng: 21.013103 },
    { lat: 48.921876, lng: 21.01322 },
    { lat: 48.921508, lng: 21.013357 },
    { lat: 48.921117, lng: 21.0134 },
    { lat: 48.920838, lng: 21.013466 },
    { lat: 48.920257, lng: 21.013589 },
    { lat: 48.920079, lng: 21.013626 },
    { lat: 48.919838, lng: 21.013677 },
    { lat: 48.919461, lng: 21.013724 },
    { lat: 48.919079, lng: 21.013775 },
    { lat: 48.918678, lng: 21.013905 },
    { lat: 48.917513, lng: 21.014318 },
    { lat: 48.916869, lng: 21.014577 },
    { lat: 48.915631, lng: 21.014824 },
    { lat: 48.915002, lng: 21.015267 },
    { lat: 48.914106, lng: 21.015248 },
    { lat: 48.913749, lng: 21.01509 },
    { lat: 48.912967, lng: 21.015597 },
    { lat: 48.912531, lng: 21.015973 },
    { lat: 48.911983, lng: 21.016529 },
    { lat: 48.911768, lng: 21.016783 },
    { lat: 48.911422, lng: 21.017118 },
    { lat: 48.911383, lng: 21.017156 },
    { lat: 48.910847, lng: 21.017674 },
    { lat: 48.909974, lng: 21.018572 },
    { lat: 48.908975, lng: 21.019673 },
    { lat: 48.908869, lng: 21.019512 },
    { lat: 48.90781, lng: 21.018426 },
    { lat: 48.906992, lng: 21.017949 },
    { lat: 48.906648, lng: 21.017747 },
    { lat: 48.906531, lng: 21.017679 },
    { lat: 48.906582, lng: 21.018904 },
    { lat: 48.906556, lng: 21.019876 },
    { lat: 48.906383, lng: 21.020579 },
    { lat: 48.906172, lng: 21.021283 },
    { lat: 48.905832, lng: 21.021637 },
    { lat: 48.905474, lng: 21.022438 },
    { lat: 48.905093, lng: 21.023307 },
    { lat: 48.904893, lng: 21.023765 },
    { lat: 48.904692, lng: 21.024077 },
    { lat: 48.904676, lng: 21.024091 },
    { lat: 48.904561, lng: 21.024193 },
    { lat: 48.904399, lng: 21.024337 },
    { lat: 48.904341, lng: 21.024431 },
    { lat: 48.904332, lng: 21.024445 },
    { lat: 48.904259, lng: 21.024561 },
    { lat: 48.904233, lng: 21.024608 },
    { lat: 48.904212, lng: 21.024642 },
    { lat: 48.904196, lng: 21.02468 },
    { lat: 48.904183, lng: 21.024728 },
    { lat: 48.904183, lng: 21.024773 },
    { lat: 48.904145, lng: 21.024876 },
    { lat: 48.903905, lng: 21.025381 },
    { lat: 48.903812, lng: 21.027046 },
    { lat: 48.904497, lng: 21.027968 },
    { lat: 48.904503, lng: 21.02805 },
    { lat: 48.904503, lng: 21.028181 },
    { lat: 48.903901, lng: 21.029937 },
    { lat: 48.903798, lng: 21.031496 },
    { lat: 48.903781, lng: 21.032491 },
    { lat: 48.903748, lng: 21.033113 },
    { lat: 48.903481, lng: 21.033331 },
    { lat: 48.903299, lng: 21.033966 },
    { lat: 48.903408, lng: 21.034322 },
    { lat: 48.903197, lng: 21.034768 },
    { lat: 48.903189, lng: 21.03477 },
    { lat: 48.903121, lng: 21.035434 },
    { lat: 48.903081, lng: 21.035999 },
    { lat: 48.903091, lng: 21.035999 },
    { lat: 48.903578, lng: 21.037483 },
    { lat: 48.903597, lng: 21.037542 },
    { lat: 48.903723, lng: 21.037688 },
    { lat: 48.903797, lng: 21.03781 },
    { lat: 48.904137, lng: 21.039077 },
    { lat: 48.904307, lng: 21.039526 },
    { lat: 48.904443, lng: 21.039828 },
    { lat: 48.904593, lng: 21.040313 },
    { lat: 48.90465, lng: 21.040837 },
    { lat: 48.904724, lng: 21.041308 },
    { lat: 48.904857, lng: 21.042068 },
    { lat: 48.904792, lng: 21.042595 },
    { lat: 48.904802, lng: 21.042602 },
    { lat: 48.904979, lng: 21.044093 },
    { lat: 48.904963, lng: 21.044093 },
    { lat: 48.9051262, lng: 21.0444628 },
    { lat: 48.9057243, lng: 21.0453016 },
    { lat: 48.9061206, lng: 21.0457523 },
    { lat: 48.9063536, lng: 21.0459617 },
    { lat: 48.9065302, lng: 21.0460318 },
    { lat: 48.9068528, lng: 21.045945 },
    { lat: 48.9076797, lng: 21.0467352 },
    { lat: 48.9079425, lng: 21.0470893 },
    { lat: 48.9088223, lng: 21.0479103 },
    { lat: 48.9092757, lng: 21.0481815 },
    { lat: 48.9093815, lng: 21.0481879 },
    { lat: 48.9095058, lng: 21.0481313 },
    { lat: 48.9099161, lng: 21.0477013 },
    { lat: 48.9100253, lng: 21.0476645 },
    { lat: 48.91073, lng: 21.048192 },
    { lat: 48.91107, lng: 21.0482985 },
    { lat: 48.9116356, lng: 21.0482128 },
    { lat: 48.9118611, lng: 21.0482571 },
    { lat: 48.9122336, lng: 21.0485383 },
    { lat: 48.914384, lng: 21.0510428 },
    { lat: 48.9162658, lng: 21.0536396 },
    { lat: 48.9165838, lng: 21.0539758 },
    { lat: 48.9171623, lng: 21.0547645 },
    { lat: 48.9174239, lng: 21.0552018 },
    { lat: 48.917692, lng: 21.0554108 },
    { lat: 48.9179371, lng: 21.0558186 },
    { lat: 48.9179735, lng: 21.0557633 },
    { lat: 48.9182293, lng: 21.0561872 },
    { lat: 48.9195294, lng: 21.0578903 },
    { lat: 48.9191581, lng: 21.0585554 },
    { lat: 48.9190212, lng: 21.0589442 },
    { lat: 48.9200153, lng: 21.0603099 },
    { lat: 48.9201732, lng: 21.0604599 },
    { lat: 48.9207663, lng: 21.061565 },
    { lat: 48.9208388, lng: 21.0619604 },
    { lat: 48.9210529, lng: 21.0624883 },
    { lat: 48.9211775, lng: 21.0627245 },
    { lat: 48.9216485, lng: 21.0633278 },
    { lat: 48.9217938, lng: 21.0636845 },
    { lat: 48.9219073, lng: 21.0642328 },
    { lat: 48.9220043, lng: 21.0651707 },
    { lat: 48.9221438, lng: 21.0653786 },
    { lat: 48.9222306, lng: 21.0656277 },
    { lat: 48.9221998, lng: 21.0658565 },
    { lat: 48.9218439, lng: 21.066198 },
    { lat: 48.9219221, lng: 21.0663543 },
    { lat: 48.922237, lng: 21.0667634 },
    { lat: 48.9227077, lng: 21.0671932 },
    { lat: 48.9237677, lng: 21.0677701 },
    { lat: 48.9240091, lng: 21.0683787 },
    { lat: 48.9244441, lng: 21.0689518 },
    { lat: 48.9250522, lng: 21.0695596 },
    { lat: 48.9259023, lng: 21.0702256 },
    { lat: 48.9264676, lng: 21.0710714 },
    { lat: 48.9266192, lng: 21.0711339 },
    { lat: 48.927774, lng: 21.0720732 },
    { lat: 48.9282537, lng: 21.0723936 },
    { lat: 48.9284553, lng: 21.0724 },
    { lat: 48.9287883, lng: 21.0726386 },
    { lat: 48.9291765, lng: 21.0727418 },
    { lat: 48.9291869, lng: 21.0728895 },
    { lat: 48.9306197, lng: 21.0738701 },
    { lat: 48.930674, lng: 21.074985 },
    { lat: 48.9305977, lng: 21.0754499 },
    { lat: 48.9305188, lng: 21.0757231 },
    { lat: 48.9312853, lng: 21.0762137 },
    { lat: 48.9317405, lng: 21.0763652 },
    { lat: 48.9330342, lng: 21.0763491 },
    { lat: 48.9336557, lng: 21.0764596 },
    { lat: 48.9344688, lng: 21.0758156 },
    { lat: 48.9348634, lng: 21.0752493 },
    { lat: 48.9350712, lng: 21.0750605 },
    { lat: 48.9358239, lng: 21.0748083 },
    { lat: 48.9365185, lng: 21.0747458 },
    { lat: 48.9371851, lng: 21.0749314 },
    { lat: 48.9377672, lng: 21.0748687 },
    { lat: 48.9384359, lng: 21.0747025 },
    { lat: 48.9390223, lng: 21.0743216 },
    { lat: 48.9388767, lng: 21.073648 },
    { lat: 48.939041, lng: 21.0735051 },
    { lat: 48.9391962, lng: 21.0737477 },
    { lat: 48.9397072, lng: 21.0741306 },
    { lat: 48.9399233, lng: 21.0732224 },
    { lat: 48.9399478, lng: 21.0729377 },
    { lat: 48.940051, lng: 21.0727235 },
    { lat: 48.9401014, lng: 21.0722608 },
    { lat: 48.940435, lng: 21.0711272 },
    { lat: 48.9403692, lng: 21.0702757 },
    { lat: 48.9405277, lng: 21.0700584 },
    { lat: 48.9404778, lng: 21.0697045 },
    { lat: 48.9405268, lng: 21.068987 },
    { lat: 48.9406644, lng: 21.0684447 },
    { lat: 48.9405345, lng: 21.0681183 },
    { lat: 48.9405884, lng: 21.0676946 },
    { lat: 48.9408125, lng: 21.0669659 },
    { lat: 48.9408661, lng: 21.0666425 },
    { lat: 48.9409334, lng: 21.0654571 },
    { lat: 48.9410592, lng: 21.0645789 },
    { lat: 48.9409707, lng: 21.0638655 },
    { lat: 48.9410313, lng: 21.063648 },
    { lat: 48.9411985, lng: 21.0634937 },
    { lat: 48.9411808, lng: 21.0632967 },
    { lat: 48.9413763, lng: 21.0630426 },
    { lat: 48.9415979, lng: 21.062973 },
    { lat: 48.9417647, lng: 21.0631854 },
    { lat: 48.9429877, lng: 21.0625165 },
    { lat: 48.9430558, lng: 21.0632264 },
    { lat: 48.9432934, lng: 21.0633311 },
    { lat: 48.9438634, lng: 21.0633902 },
    { lat: 48.9462678, lng: 21.0647508 },
    { lat: 48.9466131, lng: 21.063706 },
    { lat: 48.9466813, lng: 21.0637653 },
    { lat: 48.9467386, lng: 21.0641069 },
    { lat: 48.9472518, lng: 21.0650747 },
    { lat: 48.9475146, lng: 21.0652663 },
    { lat: 48.9478899, lng: 21.0646586 },
    { lat: 48.9480411, lng: 21.064083 },
    { lat: 48.948058, lng: 21.0631433 },
    { lat: 48.9487769, lng: 21.0616483 },
    { lat: 48.9494692, lng: 21.0605019 },
    { lat: 48.9496587, lng: 21.0600703 },
    { lat: 48.9498227, lng: 21.0599574 },
    { lat: 48.9505234, lng: 21.059732 },
    { lat: 48.9510554, lng: 21.0597896 },
    { lat: 48.9509617, lng: 21.0602306 },
    { lat: 48.9509429, lng: 21.0609002 },
    { lat: 48.9506852, lng: 21.0613885 },
    { lat: 48.9509649, lng: 21.0613386 },
    { lat: 48.9512202, lng: 21.0610879 },
    { lat: 48.9519496, lng: 21.059741 },
    { lat: 48.9519741, lng: 21.0597352 },
    { lat: 48.9520675, lng: 21.0584629 },
    { lat: 48.9520265, lng: 21.0571495 },
    { lat: 48.9522596, lng: 21.0571213 },
    { lat: 48.9523977, lng: 21.0569029 },
    { lat: 48.9528343, lng: 21.0564473 },
    { lat: 48.953016, lng: 21.0561146 },
    { lat: 48.9530321, lng: 21.0558481 },
    { lat: 48.9531838, lng: 21.0553572 },
    { lat: 48.9531996, lng: 21.0548063 },
    { lat: 48.9536467, lng: 21.0535707 },
    { lat: 48.9536376, lng: 21.0532892 },
  ],
  Terňa: [
    { lat: 49.165942, lng: 21.163848 },
    { lat: 49.165736, lng: 21.164154 },
    { lat: 49.165664, lng: 21.164263 },
    { lat: 49.165376, lng: 21.164949 },
    { lat: 49.164998, lng: 21.165909 },
    { lat: 49.164975, lng: 21.16595 },
    { lat: 49.164875, lng: 21.166358 },
    { lat: 49.164762, lng: 21.166577 },
    { lat: 49.164587, lng: 21.167016 },
    { lat: 49.16448, lng: 21.167272 },
    { lat: 49.164341, lng: 21.167433 },
    { lat: 49.164264, lng: 21.167674 },
    { lat: 49.164074, lng: 21.167999 },
    { lat: 49.164052, lng: 21.168036 },
    { lat: 49.163902, lng: 21.168179 },
    { lat: 49.163695, lng: 21.168221 },
    { lat: 49.163379, lng: 21.168397 },
    { lat: 49.163001, lng: 21.168805 },
    { lat: 49.162777, lng: 21.168919 },
    { lat: 49.162375, lng: 21.169618 },
    { lat: 49.162302, lng: 21.169894 },
    { lat: 49.162219, lng: 21.170295 },
    { lat: 49.162114, lng: 21.170649 },
    { lat: 49.161969, lng: 21.170939 },
    { lat: 49.161798, lng: 21.171353 },
    { lat: 49.161551, lng: 21.172014 },
    { lat: 49.161413, lng: 21.172226 },
    { lat: 49.161148, lng: 21.172499 },
    { lat: 49.160899, lng: 21.172834 },
    { lat: 49.160752, lng: 21.172946 },
    { lat: 49.160487, lng: 21.173032 },
    { lat: 49.160385, lng: 21.173186 },
    { lat: 49.160002, lng: 21.17349 },
    { lat: 49.159768, lng: 21.173574 },
    { lat: 49.159511, lng: 21.173725 },
    { lat: 49.158996, lng: 21.173881 },
    { lat: 49.158633, lng: 21.173754 },
    { lat: 49.158376, lng: 21.173799 },
    { lat: 49.158076, lng: 21.174037 },
    { lat: 49.15791, lng: 21.17415 },
    { lat: 49.157766, lng: 21.174152 },
    { lat: 49.157622, lng: 21.174243 },
    { lat: 49.157358, lng: 21.174594 },
    { lat: 49.15692, lng: 21.175705 },
    { lat: 49.156855, lng: 21.176027 },
    { lat: 49.156651, lng: 21.176445 },
    { lat: 49.156373, lng: 21.176736 },
    { lat: 49.15611, lng: 21.176949 },
    { lat: 49.155473, lng: 21.177088 },
    { lat: 49.154851, lng: 21.177118 },
    { lat: 49.154235, lng: 21.177094 },
    { lat: 49.154012, lng: 21.177182 },
    { lat: 49.153759, lng: 21.177244 },
    { lat: 49.153525, lng: 21.177526 },
    { lat: 49.153173, lng: 21.177667 },
    { lat: 49.152971, lng: 21.177936 },
    { lat: 49.152607, lng: 21.17821 },
    { lat: 49.15254, lng: 21.178258 },
    { lat: 49.152522, lng: 21.178262 },
    { lat: 49.152324, lng: 21.178248 },
    { lat: 49.152023, lng: 21.178356 },
    { lat: 49.151558, lng: 21.178401 },
    { lat: 49.151465, lng: 21.178342 },
    { lat: 49.151221, lng: 21.178095 },
    { lat: 49.151035, lng: 21.177998 },
    { lat: 49.150525, lng: 21.177871 },
    { lat: 49.150124, lng: 21.178157 },
    { lat: 49.149165, lng: 21.178898 },
    { lat: 49.14888, lng: 21.179374 },
    { lat: 49.148738, lng: 21.179685 },
    { lat: 49.148095, lng: 21.180055 },
    { lat: 49.147575, lng: 21.180274 },
    { lat: 49.14732, lng: 21.180746 },
    { lat: 49.146926, lng: 21.18137 },
    { lat: 49.146007, lng: 21.18316 },
    { lat: 49.145913, lng: 21.183504 },
    { lat: 49.145854, lng: 21.185252 },
    { lat: 49.145841, lng: 21.185821 },
    { lat: 49.145823, lng: 21.186026 },
    { lat: 49.145834, lng: 21.186056 },
    { lat: 49.145872, lng: 21.186404 },
    { lat: 49.145943, lng: 21.18651 },
    { lat: 49.145918, lng: 21.186902 },
    { lat: 49.145909, lng: 21.18703 },
    { lat: 49.145842, lng: 21.187157 },
    { lat: 49.145931, lng: 21.187392 },
    { lat: 49.145888, lng: 21.187518 },
    { lat: 49.145837, lng: 21.187605 },
    { lat: 49.145771, lng: 21.187604 },
    { lat: 49.145735, lng: 21.187764 },
    { lat: 49.145661, lng: 21.187738 },
    { lat: 49.145644, lng: 21.187737 },
    { lat: 49.145606, lng: 21.188458 },
    { lat: 49.14558, lng: 21.188568 },
    { lat: 49.145505, lng: 21.188717 },
    { lat: 49.145376, lng: 21.189102 },
    { lat: 49.14508, lng: 21.189476 },
    { lat: 49.144655, lng: 21.189873 },
    { lat: 49.144331, lng: 21.190006 },
    { lat: 49.143879, lng: 21.18997 },
    { lat: 49.143665, lng: 21.190091 },
    { lat: 49.143397, lng: 21.190244 },
    { lat: 49.143208, lng: 21.190466 },
    { lat: 49.142691, lng: 21.190806 },
    { lat: 49.142127, lng: 21.190952 },
    { lat: 49.141839, lng: 21.191127 },
    { lat: 49.141716, lng: 21.191279 },
    { lat: 49.141639, lng: 21.191366 },
    { lat: 49.141501, lng: 21.191367 },
    { lat: 49.141223, lng: 21.191438 },
    { lat: 49.140971, lng: 21.191532 },
    { lat: 49.140654, lng: 21.191347 },
    { lat: 49.14035, lng: 21.191252 },
    { lat: 49.140145, lng: 21.191191 },
    { lat: 49.139729, lng: 21.191212 },
    { lat: 49.139607, lng: 21.191254 },
    { lat: 49.139197, lng: 21.191315 },
    { lat: 49.139055, lng: 21.19129 },
    { lat: 49.138746, lng: 21.191045 },
    { lat: 49.138507, lng: 21.191038 },
    { lat: 49.138225, lng: 21.191237 },
    { lat: 49.137648, lng: 21.19206 },
    { lat: 49.137479, lng: 21.192385 },
    { lat: 49.137348, lng: 21.192897 },
    { lat: 49.137221, lng: 21.193186 },
    { lat: 49.137211, lng: 21.193178 },
    { lat: 49.136911, lng: 21.193395 },
    { lat: 49.136743, lng: 21.193386 },
    { lat: 49.136533, lng: 21.193282 },
    { lat: 49.136534, lng: 21.193255 },
    { lat: 49.136035, lng: 21.193236 },
    { lat: 49.135794, lng: 21.193152 },
    { lat: 49.135527, lng: 21.192797 },
    { lat: 49.135181, lng: 21.192238 },
    { lat: 49.1348, lng: 21.191816 },
    { lat: 49.13415, lng: 21.191627 },
    { lat: 49.133968, lng: 21.191732 },
    { lat: 49.133605, lng: 21.192126 },
    { lat: 49.133481, lng: 21.192316 },
    { lat: 49.133385, lng: 21.192542 },
    { lat: 49.133344, lng: 21.192523 },
    { lat: 49.133677, lng: 21.193044 },
    { lat: 49.133736, lng: 21.193404 },
    { lat: 49.13379, lng: 21.193616 },
    { lat: 49.133536, lng: 21.194217 },
    { lat: 49.133146, lng: 21.195437 },
    { lat: 49.132958, lng: 21.195824 },
    { lat: 49.132841, lng: 21.196278 },
    { lat: 49.132754, lng: 21.196603 },
    { lat: 49.132424, lng: 21.197613 },
    { lat: 49.132376, lng: 21.197745 },
    { lat: 49.132217, lng: 21.198244 },
    { lat: 49.131937, lng: 21.199194 },
    { lat: 49.131459, lng: 21.200442 },
    { lat: 49.131228, lng: 21.200603 },
    { lat: 49.130985, lng: 21.200913 },
    { lat: 49.130841, lng: 21.201013 },
    { lat: 49.130717, lng: 21.201085 },
    { lat: 49.130565, lng: 21.201051 },
    { lat: 49.130425, lng: 21.201135 },
    { lat: 49.130172, lng: 21.201207 },
    { lat: 49.130034, lng: 21.201297 },
    { lat: 49.12978, lng: 21.20125 },
    { lat: 49.129385, lng: 21.20161 },
    { lat: 49.128917, lng: 21.202092 },
    { lat: 49.128699, lng: 21.202366 },
    { lat: 49.128498, lng: 21.202713 },
    { lat: 49.128302, lng: 21.203206 },
    { lat: 49.127826, lng: 21.203921 },
    { lat: 49.127528, lng: 21.204093 },
    { lat: 49.127335, lng: 21.204162 },
    { lat: 49.127107, lng: 21.204249 },
    { lat: 49.126933, lng: 21.204336 },
    { lat: 49.126469, lng: 21.204449 },
    { lat: 49.126193, lng: 21.204547 },
    { lat: 49.125994, lng: 21.204585 },
    { lat: 49.125659, lng: 21.204774 },
    { lat: 49.125529, lng: 21.204852 },
    { lat: 49.125173, lng: 21.204984 },
    { lat: 49.12501, lng: 21.205115 },
    { lat: 49.124737, lng: 21.205857 },
    { lat: 49.124649, lng: 21.206352 },
    { lat: 49.124515, lng: 21.206839 },
    { lat: 49.124357, lng: 21.206978 },
    { lat: 49.1241, lng: 21.2071 },
    { lat: 49.123803, lng: 21.207109 },
    { lat: 49.123652, lng: 21.207186 },
    { lat: 49.123553, lng: 21.207293 },
    { lat: 49.123392, lng: 21.207529 },
    { lat: 49.123257, lng: 21.207651 },
    { lat: 49.123053, lng: 21.207759 },
    { lat: 49.122976, lng: 21.207797 },
    { lat: 49.122583, lng: 21.207814 },
    { lat: 49.122192, lng: 21.208106 },
    { lat: 49.121784, lng: 21.208095 },
    { lat: 49.121281, lng: 21.208029 },
    { lat: 49.121089, lng: 21.208055 },
    { lat: 49.120959, lng: 21.208103 },
    { lat: 49.120762, lng: 21.208123 },
    { lat: 49.120311, lng: 21.208183 },
    { lat: 49.119905, lng: 21.20834 },
    { lat: 49.119621, lng: 21.208409 },
    { lat: 49.119012, lng: 21.20842 },
    { lat: 49.118096, lng: 21.209017 },
    { lat: 49.117489, lng: 21.209345 },
    { lat: 49.117265, lng: 21.209513 },
    { lat: 49.117121, lng: 21.20969 },
    { lat: 49.117041, lng: 21.209815 },
    { lat: 49.116462, lng: 21.210784 },
    { lat: 49.116188, lng: 21.211027 },
    { lat: 49.11616, lng: 21.211042 },
    { lat: 49.115897, lng: 21.21126 },
    { lat: 49.115811, lng: 21.211258 },
    { lat: 49.115512, lng: 21.211286 },
    { lat: 49.115143, lng: 21.211432 },
    { lat: 49.114827, lng: 21.211735 },
    { lat: 49.114411, lng: 21.212351 },
    { lat: 49.114072, lng: 21.212748 },
    { lat: 49.114053, lng: 21.212719 },
    { lat: 49.11382, lng: 21.21246 },
    { lat: 49.113798, lng: 21.212457 },
    { lat: 49.113739, lng: 21.212462 },
    { lat: 49.112572, lng: 21.213043 },
    { lat: 49.1117, lng: 21.213891 },
    { lat: 49.111289, lng: 21.214436 },
    { lat: 49.11134, lng: 21.214739 },
    { lat: 49.110406, lng: 21.215224 },
    { lat: 49.109228, lng: 21.215792 },
    { lat: 49.109173, lng: 21.21582 },
    { lat: 49.108346, lng: 21.217592 },
    { lat: 49.108069, lng: 21.217717 },
    { lat: 49.107824, lng: 21.217811 },
    { lat: 49.106935, lng: 21.218957 },
    { lat: 49.106006, lng: 21.221223 },
    { lat: 49.105783, lng: 21.222032 },
    { lat: 49.105456, lng: 21.224042 },
    { lat: 49.105451, lng: 21.224103 },
    { lat: 49.105028, lng: 21.223926 },
    { lat: 49.104855, lng: 21.223613 },
    { lat: 49.104481, lng: 21.22271 },
    { lat: 49.104262, lng: 21.222909 },
    { lat: 49.1045, lng: 21.223579 },
    { lat: 49.104164, lng: 21.223869 },
    { lat: 49.103857, lng: 21.224087 },
    { lat: 49.102652, lng: 21.224927 },
    { lat: 49.102279, lng: 21.225303 },
    { lat: 49.102193, lng: 21.225138 },
    { lat: 49.101946, lng: 21.224913 },
    { lat: 49.101858, lng: 21.224753 },
    { lat: 49.102186, lng: 21.22445 },
    { lat: 49.101609, lng: 21.221653 },
    { lat: 49.101448, lng: 21.221769 },
    { lat: 49.101, lng: 21.220714 },
    { lat: 49.100766, lng: 21.220304 },
    { lat: 49.100579, lng: 21.220014 },
    { lat: 49.100125, lng: 21.219397 },
    { lat: 49.099922, lng: 21.219102 },
    { lat: 49.099887, lng: 21.219143 },
    { lat: 49.099841, lng: 21.219246 },
    { lat: 49.099788, lng: 21.219254 },
    { lat: 49.099337, lng: 21.219099 },
    { lat: 49.099105, lng: 21.218994 },
    { lat: 49.098583, lng: 21.218848 },
    { lat: 49.098221, lng: 21.218773 },
    { lat: 49.097334, lng: 21.218707 },
    { lat: 49.097056, lng: 21.218747 },
    { lat: 49.096549, lng: 21.2189 },
    { lat: 49.095995, lng: 21.219047 },
    { lat: 49.095773, lng: 21.219057 },
    { lat: 49.095568, lng: 21.219034 },
    { lat: 49.095302, lng: 21.219052 },
    { lat: 49.095245, lng: 21.218973 },
    { lat: 49.0951, lng: 21.219225 },
    { lat: 49.094956, lng: 21.219468 },
    { lat: 49.094397, lng: 21.220296 },
    { lat: 49.093879, lng: 21.221262 },
    { lat: 49.093569, lng: 21.222257 },
    { lat: 49.093417, lng: 21.222627 },
    { lat: 49.093329, lng: 21.222926 },
    { lat: 49.093054, lng: 21.223626 },
    { lat: 49.092727, lng: 21.224528 },
    { lat: 49.092613, lng: 21.224919 },
    { lat: 49.092551, lng: 21.22502 },
    { lat: 49.092611, lng: 21.225687 },
    { lat: 49.092605, lng: 21.227111 },
    { lat: 49.092029, lng: 21.227048 },
    { lat: 49.089171, lng: 21.226736 },
    { lat: 49.089014, lng: 21.225658 },
    { lat: 49.088894, lng: 21.225078 },
    { lat: 49.088836, lng: 21.225077 },
    { lat: 49.088306, lng: 21.225488 },
    { lat: 49.088239, lng: 21.225444 },
    { lat: 49.088043, lng: 21.225738 },
    { lat: 49.087956, lng: 21.225676 },
    { lat: 49.087165, lng: 21.226308 },
    { lat: 49.087058, lng: 21.22623 },
    { lat: 49.08705, lng: 21.226086 },
    { lat: 49.087105, lng: 21.225782 },
    { lat: 49.087123, lng: 21.225468 },
    { lat: 49.0834118, lng: 21.2304463 },
    { lat: 49.080424, lng: 21.2327299 },
    { lat: 49.0799887, lng: 21.2332859 },
    { lat: 49.0786619, lng: 21.2352807 },
    { lat: 49.0779559, lng: 21.2362522 },
    { lat: 49.077016, lng: 21.2366812 },
    { lat: 49.0769128, lng: 21.2369897 },
    { lat: 49.0761537, lng: 21.236343 },
    { lat: 49.0761278, lng: 21.2364518 },
    { lat: 49.076237, lng: 21.2368219 },
    { lat: 49.076062, lng: 21.2370548 },
    { lat: 49.0756842, lng: 21.23736 },
    { lat: 49.0748682, lng: 21.2384807 },
    { lat: 49.0748753, lng: 21.2384974 },
    { lat: 49.0750473, lng: 21.238638 },
    { lat: 49.0756548, lng: 21.2386639 },
    { lat: 49.0758588, lng: 21.2387879 },
    { lat: 49.0763261, lng: 21.2393439 },
    { lat: 49.0764112, lng: 21.2395866 },
    { lat: 49.0763679, lng: 21.2400423 },
    { lat: 49.0763818, lng: 21.2404555 },
    { lat: 49.0761991, lng: 21.2410992 },
    { lat: 49.0756879, lng: 21.2418194 },
    { lat: 49.0754801, lng: 21.242308 },
    { lat: 49.0752839, lng: 21.2432118 },
    { lat: 49.0752789, lng: 21.2438133 },
    { lat: 49.07513, lng: 21.2440619 },
    { lat: 49.0748651, lng: 21.244191 },
    { lat: 49.0745525, lng: 21.2446763 },
    { lat: 49.0744346, lng: 21.2450806 },
    { lat: 49.074391, lng: 21.2456404 },
    { lat: 49.0740019, lng: 21.2467829 },
    { lat: 49.0739805, lng: 21.2469685 },
    { lat: 49.0740703, lng: 21.2483472 },
    { lat: 49.0741572, lng: 21.2486504 },
    { lat: 49.0740894, lng: 21.2490461 },
    { lat: 49.0738737, lng: 21.2493841 },
    { lat: 49.0732298, lng: 21.2500003 },
    { lat: 49.0731359, lng: 21.2501993 },
    { lat: 49.0730472, lng: 21.2505344 },
    { lat: 49.0730247, lng: 21.2518901 },
    { lat: 49.0729595, lng: 21.252225 },
    { lat: 49.0729464, lng: 21.2527517 },
    { lat: 49.0728618, lng: 21.2531514 },
    { lat: 49.0731728, lng: 21.2537118 },
    { lat: 49.0736399, lng: 21.25375 },
    { lat: 49.0744611, lng: 21.2539626 },
    { lat: 49.0747273, lng: 21.2538381 },
    { lat: 49.075007, lng: 21.2539335 },
    { lat: 49.0757695, lng: 21.2544689 },
    { lat: 49.0766586, lng: 21.2548813 },
    { lat: 49.0769696, lng: 21.2549267 },
    { lat: 49.077162, lng: 21.2548577 },
    { lat: 49.0772249, lng: 21.2549247 },
    { lat: 49.0781769, lng: 21.254488 },
    { lat: 49.0784271, lng: 21.2546439 },
    { lat: 49.0786559, lng: 21.2549848 },
    { lat: 49.0792908, lng: 21.2552889 },
    { lat: 49.0795283, lng: 21.2558449 },
    { lat: 49.0795034, lng: 21.2566454 },
    { lat: 49.0796089, lng: 21.2568558 },
    { lat: 49.0796503, lng: 21.2573216 },
    { lat: 49.0803186, lng: 21.2583862 },
    { lat: 49.080575, lng: 21.2583044 },
    { lat: 49.0815543, lng: 21.2589438 },
    { lat: 49.0817379, lng: 21.2591347 },
    { lat: 49.0838045, lng: 21.2596279 },
    { lat: 49.0840717, lng: 21.2599374 },
    { lat: 49.0840965, lng: 21.2597242 },
    { lat: 49.0849692, lng: 21.2601498 },
    { lat: 49.0849228, lng: 21.2582264 },
    { lat: 49.084853, lng: 21.2573805 },
    { lat: 49.0849935, lng: 21.2569419 },
    { lat: 49.0853, lng: 21.2569169 },
    { lat: 49.0853829, lng: 21.2563524 },
    { lat: 49.085907, lng: 21.2551732 },
    { lat: 49.0862572, lng: 21.2545208 },
    { lat: 49.0876231, lng: 21.2538517 },
    { lat: 49.0879351, lng: 21.2532382 },
    { lat: 49.0881535, lng: 21.2535393 },
    { lat: 49.0888938, lng: 21.2530605 },
    { lat: 49.0895876, lng: 21.2524557 },
    { lat: 49.0903777, lng: 21.2511382 },
    { lat: 49.0906098, lng: 21.2505652 },
    { lat: 49.0910615, lng: 21.2496947 },
    { lat: 49.0909535, lng: 21.2495494 },
    { lat: 49.0910437, lng: 21.2489693 },
    { lat: 49.0912043, lng: 21.2483819 },
    { lat: 49.0916877, lng: 21.2477066 },
    { lat: 49.092001, lng: 21.2471371 },
    { lat: 49.0924098, lng: 21.2475862 },
    { lat: 49.0928451, lng: 21.2462605 },
    { lat: 49.0931888, lng: 21.2469027 },
    { lat: 49.0938654, lng: 21.2488135 },
    { lat: 49.0941111, lng: 21.2493382 },
    { lat: 49.0945139, lng: 21.2499605 },
    { lat: 49.0947897, lng: 21.2506557 },
    { lat: 49.0955066, lng: 21.2496783 },
    { lat: 49.0960575, lng: 21.2490728 },
    { lat: 49.0962456, lng: 21.248933 },
    { lat: 49.0971392, lng: 21.2484628 },
    { lat: 49.0981999, lng: 21.2481194 },
    { lat: 49.0985447, lng: 21.2481673 },
    { lat: 49.0985888, lng: 21.2496429 },
    { lat: 49.0987048, lng: 21.2508943 },
    { lat: 49.0993202, lng: 21.2533038 },
    { lat: 49.0995351, lng: 21.2564978 },
    { lat: 49.0998017, lng: 21.2577058 },
    { lat: 49.0998112, lng: 21.257907 },
    { lat: 49.1003304, lng: 21.2577738 },
    { lat: 49.1013058, lng: 21.2570344 },
    { lat: 49.1017013, lng: 21.2571991 },
    { lat: 49.1018413, lng: 21.2575411 },
    { lat: 49.1016215, lng: 21.2579036 },
    { lat: 49.1019804, lng: 21.2580487 },
    { lat: 49.1022866, lng: 21.2583771 },
    { lat: 49.1024348, lng: 21.258335 },
    { lat: 49.1026714, lng: 21.258014 },
    { lat: 49.1027125, lng: 21.2575557 },
    { lat: 49.1027134, lng: 21.2573606 },
    { lat: 49.1026981, lng: 21.2571236 },
    { lat: 49.1021948, lng: 21.257112 },
    { lat: 49.1022512, lng: 21.2569295 },
    { lat: 49.1023589, lng: 21.2568802 },
    { lat: 49.1023311, lng: 21.2561984 },
    { lat: 49.1035713, lng: 21.2527582 },
    { lat: 49.1039884, lng: 21.2517499 },
    { lat: 49.1039368, lng: 21.2516131 },
    { lat: 49.1055782, lng: 21.2499871 },
    { lat: 49.1068514, lng: 21.2484315 },
    { lat: 49.1077296, lng: 21.2471323 },
    { lat: 49.1079846, lng: 21.2466357 },
    { lat: 49.1083554, lng: 21.2455975 },
    { lat: 49.1085572, lng: 21.2439228 },
    { lat: 49.1105248, lng: 21.2442047 },
    { lat: 49.1109376, lng: 21.2440844 },
    { lat: 49.1113767, lng: 21.2438735 },
    { lat: 49.1119871, lng: 21.2429441 },
    { lat: 49.1168562, lng: 21.2407861 },
    { lat: 49.1169937, lng: 21.2407565 },
    { lat: 49.117208, lng: 21.2409154 },
    { lat: 49.1170538, lng: 21.2405873 },
    { lat: 49.1172229, lng: 21.2407099 },
    { lat: 49.1169639, lng: 21.2399723 },
    { lat: 49.1168448, lng: 21.2392817 },
    { lat: 49.1163708, lng: 21.2383633 },
    { lat: 49.1163976, lng: 21.2380615 },
    { lat: 49.1164758, lng: 21.2378978 },
    { lat: 49.1166589, lng: 21.2378172 },
    { lat: 49.117575, lng: 21.237053 },
    { lat: 49.1184268, lng: 21.2361382 },
    { lat: 49.1190815, lng: 21.2356617 },
    { lat: 49.1194112, lng: 21.2355472 },
    { lat: 49.1198672, lng: 21.2352247 },
    { lat: 49.1202716, lng: 21.234515 },
    { lat: 49.1214867, lng: 21.233629 },
    { lat: 49.1216541, lng: 21.233764 },
    { lat: 49.1217735, lng: 21.2337752 },
    { lat: 49.1226043, lng: 21.2329828 },
    { lat: 49.1233969, lng: 21.232412 },
    { lat: 49.1245132, lng: 21.2310997 },
    { lat: 49.1244706, lng: 21.2309195 },
    { lat: 49.1246293, lng: 21.230813 },
    { lat: 49.124852, lng: 21.2303048 },
    { lat: 49.1253071, lng: 21.2302424 },
    { lat: 49.125575, lng: 21.2301366 },
    { lat: 49.1259915, lng: 21.2301075 },
    { lat: 49.1260939, lng: 21.2300336 },
    { lat: 49.1263652, lng: 21.2292672 },
    { lat: 49.1265854, lng: 21.2294034 },
    { lat: 49.1268486, lng: 21.2293282 },
    { lat: 49.1278011, lng: 21.2285844 },
    { lat: 49.1279511, lng: 21.2310367 },
    { lat: 49.1279733, lng: 21.2314964 },
    { lat: 49.1278786, lng: 21.2342691 },
    { lat: 49.1277191, lng: 21.234822 },
    { lat: 49.1286609, lng: 21.2360261 },
    { lat: 49.1290954, lng: 21.2369109 },
    { lat: 49.128899, lng: 21.2377309 },
    { lat: 49.1286084, lng: 21.2381577 },
    { lat: 49.1285422, lng: 21.2384197 },
    { lat: 49.1292956, lng: 21.2387345 },
    { lat: 49.1300045, lng: 21.2393194 },
    { lat: 49.1304646, lng: 21.2393515 },
    { lat: 49.1305854, lng: 21.2392542 },
    { lat: 49.1307606, lng: 21.2389477 },
    { lat: 49.131541, lng: 21.2392602 },
    { lat: 49.1321157, lng: 21.2389252 },
    { lat: 49.1324391, lng: 21.2390458 },
    { lat: 49.1328726, lng: 21.2386562 },
    { lat: 49.133024, lng: 21.2386357 },
    { lat: 49.1331599, lng: 21.2386978 },
    { lat: 49.1334489, lng: 21.2389876 },
    { lat: 49.1335861, lng: 21.2386192 },
    { lat: 49.1338094, lng: 21.2385644 },
    { lat: 49.1342489, lng: 21.2390224 },
    { lat: 49.1348051, lng: 21.2389223 },
    { lat: 49.1351157, lng: 21.2391074 },
    { lat: 49.1352684, lng: 21.2388755 },
    { lat: 49.1357338, lng: 21.2391204 },
    { lat: 49.1359808, lng: 21.2391009 },
    { lat: 49.1362288, lng: 21.2389682 },
    { lat: 49.1365716, lng: 21.2391047 },
    { lat: 49.137172, lng: 21.2388296 },
    { lat: 49.1375993, lng: 21.2387868 },
    { lat: 49.1379637, lng: 21.238599 },
    { lat: 49.1380873, lng: 21.2386984 },
    { lat: 49.138479, lng: 21.2385241 },
    { lat: 49.1386455, lng: 21.2385927 },
    { lat: 49.1390034, lng: 21.2381937 },
    { lat: 49.1393055, lng: 21.2376585 },
    { lat: 49.1395737, lng: 21.2375632 },
    { lat: 49.1399075, lng: 21.237264 },
    { lat: 49.1400385, lng: 21.2370893 },
    { lat: 49.1401671, lng: 21.2366665 },
    { lat: 49.1404809, lng: 21.2362577 },
    { lat: 49.1405256, lng: 21.236322 },
    { lat: 49.14066, lng: 21.2361927 },
    { lat: 49.140728, lng: 21.235767 },
    { lat: 49.1408476, lng: 21.2355633 },
    { lat: 49.1410264, lng: 21.2355196 },
    { lat: 49.1410983, lng: 21.2352589 },
    { lat: 49.1413733, lng: 21.2351304 },
    { lat: 49.1415019, lng: 21.2349584 },
    { lat: 49.1415037, lng: 21.2345809 },
    { lat: 49.1415823, lng: 21.2343912 },
    { lat: 49.1417468, lng: 21.2342501 },
    { lat: 49.1420324, lng: 21.2341376 },
    { lat: 49.14219, lng: 21.2337357 },
    { lat: 49.1426828, lng: 21.2332903 },
    { lat: 49.142713, lng: 21.232923 },
    { lat: 49.1430254, lng: 21.2329092 },
    { lat: 49.1431163, lng: 21.2323517 },
    { lat: 49.1434665, lng: 21.2319263 },
    { lat: 49.1435281, lng: 21.2317285 },
    { lat: 49.1442185, lng: 21.23088 },
    { lat: 49.1446143, lng: 21.2306863 },
    { lat: 49.1448431, lng: 21.230331 },
    { lat: 49.1451122, lng: 21.2301565 },
    { lat: 49.145204, lng: 21.2302491 },
    { lat: 49.1452959, lng: 21.2301246 },
    { lat: 49.1452697, lng: 21.2300474 },
    { lat: 49.1460946, lng: 21.2295386 },
    { lat: 49.1465303, lng: 21.2289273 },
    { lat: 49.1474343, lng: 21.2279929 },
    { lat: 49.1475648, lng: 21.2279516 },
    { lat: 49.1477183, lng: 21.227675 },
    { lat: 49.1489139, lng: 21.2268246 },
    { lat: 49.1490176, lng: 21.2266479 },
    { lat: 49.1493247, lng: 21.2263908 },
    { lat: 49.1496446, lng: 21.2259979 },
    { lat: 49.1498918, lng: 21.2258803 },
    { lat: 49.1502856, lng: 21.2253692 },
    { lat: 49.1506956, lng: 21.2250526 },
    { lat: 49.1507876, lng: 21.2248267 },
    { lat: 49.1516418, lng: 21.2242043 },
    { lat: 49.151916, lng: 21.2240897 },
    { lat: 49.1519917, lng: 21.2238768 },
    { lat: 49.1523715, lng: 21.2237917 },
    { lat: 49.1526166, lng: 21.2234904 },
    { lat: 49.1532233, lng: 21.2235734 },
    { lat: 49.1535236, lng: 21.2233872 },
    { lat: 49.154008, lng: 21.223512 },
    { lat: 49.1558356, lng: 21.2228609 },
    { lat: 49.1562503, lng: 21.2224072 },
    { lat: 49.1565454, lng: 21.2222692 },
    { lat: 49.1570311, lng: 21.2218935 },
    { lat: 49.157866, lng: 21.2209074 },
    { lat: 49.1580434, lng: 21.2205347 },
    { lat: 49.1581915, lng: 21.2204076 },
    { lat: 49.1586941, lng: 21.2194881 },
    { lat: 49.1588411, lng: 21.2193999 },
    { lat: 49.1592564, lng: 21.2185338 },
    { lat: 49.1593621, lng: 21.2184226 },
    { lat: 49.1600827, lng: 21.2169043 },
    { lat: 49.160273, lng: 21.2167298 },
    { lat: 49.1610082, lng: 21.2152902 },
    { lat: 49.1617129, lng: 21.2147152 },
    { lat: 49.1620318, lng: 21.2145538 },
    { lat: 49.163199, lng: 21.214604 },
    { lat: 49.163294, lng: 21.21414 },
    { lat: 49.163293, lng: 21.213938 },
    { lat: 49.163277, lng: 21.213195 },
    { lat: 49.163452, lng: 21.212568 },
    { lat: 49.163425, lng: 21.212364 },
    { lat: 49.163426, lng: 21.211663 },
    { lat: 49.163401, lng: 21.211172 },
    { lat: 49.163617, lng: 21.210327 },
    { lat: 49.163753, lng: 21.209966 },
    { lat: 49.163973, lng: 21.2096 },
    { lat: 49.164007, lng: 21.20947 },
    { lat: 49.164054, lng: 21.20906 },
    { lat: 49.164273, lng: 21.208613 },
    { lat: 49.164444, lng: 21.208395 },
    { lat: 49.164714, lng: 21.208163 },
    { lat: 49.164799, lng: 21.208022 },
    { lat: 49.165025, lng: 21.207843 },
    { lat: 49.165153, lng: 21.207645 },
    { lat: 49.165208, lng: 21.2075 },
    { lat: 49.165896, lng: 21.206716 },
    { lat: 49.166353, lng: 21.206299 },
    { lat: 49.166626, lng: 21.205996 },
    { lat: 49.167674, lng: 21.204838 },
    { lat: 49.167943, lng: 21.204659 },
    { lat: 49.168114, lng: 21.204515 },
    { lat: 49.168601, lng: 21.204079 },
    { lat: 49.168906, lng: 21.203773 },
    { lat: 49.169242, lng: 21.203521 },
    { lat: 49.169464, lng: 21.20329 },
    { lat: 49.169561, lng: 21.203239 },
    { lat: 49.169654, lng: 21.203245 },
    { lat: 49.169995, lng: 21.203384 },
    { lat: 49.17017, lng: 21.203453 },
    { lat: 49.170396, lng: 21.203477 },
    { lat: 49.170566, lng: 21.203426 },
    { lat: 49.170807, lng: 21.203208 },
    { lat: 49.171189, lng: 21.203032 },
    { lat: 49.171501, lng: 21.203006 },
    { lat: 49.171937, lng: 21.202799 },
    { lat: 49.172112, lng: 21.202771 },
    { lat: 49.172369, lng: 21.202822 },
    { lat: 49.17295, lng: 21.202981 },
    { lat: 49.173149, lng: 21.202814 },
    { lat: 49.173375, lng: 21.202406 },
    { lat: 49.173721, lng: 21.202133 },
    { lat: 49.174148, lng: 21.201566 },
    { lat: 49.174466, lng: 21.20088 },
    { lat: 49.175097, lng: 21.20015 },
    { lat: 49.175377, lng: 21.199809 },
    { lat: 49.175776, lng: 21.199403 },
    { lat: 49.176043, lng: 21.199074 },
    { lat: 49.17626, lng: 21.198516 },
    { lat: 49.176339, lng: 21.198261 },
    { lat: 49.17642, lng: 21.197874 },
    { lat: 49.176611, lng: 21.197425 },
    { lat: 49.177045, lng: 21.1962762 },
    { lat: 49.177137, lng: 21.196065 },
    { lat: 49.177434, lng: 21.195595 },
    { lat: 49.177542, lng: 21.195263 },
    { lat: 49.177816, lng: 21.194817 },
    { lat: 49.177503, lng: 21.194079 },
    { lat: 49.177501, lng: 21.193886 },
    { lat: 49.177412, lng: 21.193623 },
    { lat: 49.177355, lng: 21.193236 },
    { lat: 49.177072, lng: 21.192694 },
    { lat: 49.177019, lng: 21.19224 },
    { lat: 49.177051, lng: 21.191681 },
    { lat: 49.176997, lng: 21.191081 },
    { lat: 49.176901, lng: 21.190596 },
    { lat: 49.176834, lng: 21.189774 },
    { lat: 49.176815, lng: 21.189287 },
    { lat: 49.176854, lng: 21.188777 },
    { lat: 49.176867, lng: 21.188159 },
    { lat: 49.176873, lng: 21.188123 },
    { lat: 49.176981, lng: 21.187699 },
    { lat: 49.177097, lng: 21.187231 },
    { lat: 49.177216, lng: 21.186902 },
    { lat: 49.177413, lng: 21.185709 },
    { lat: 49.177751, lng: 21.184712 },
    { lat: 49.178034, lng: 21.184024 },
    { lat: 49.178164, lng: 21.183722 },
    { lat: 49.178646, lng: 21.183208 },
    { lat: 49.178898, lng: 21.182884 },
    { lat: 49.179014, lng: 21.182679 },
    { lat: 49.179354, lng: 21.182227 },
    { lat: 49.179733, lng: 21.181934 },
    { lat: 49.179952, lng: 21.181635 },
    { lat: 49.180095, lng: 21.181306 },
    { lat: 49.180199, lng: 21.181206 },
    { lat: 49.180513, lng: 21.181119 },
    { lat: 49.180857, lng: 21.181082 },
    { lat: 49.181824, lng: 21.180717 },
    { lat: 49.182083, lng: 21.18073 },
    { lat: 49.183235, lng: 21.180123 },
    { lat: 49.183711, lng: 21.179776 },
    { lat: 49.184125, lng: 21.179342 },
    { lat: 49.18459, lng: 21.178261 },
    { lat: 49.184905, lng: 21.177412 },
    { lat: 49.185032, lng: 21.176786 },
    { lat: 49.185075, lng: 21.176469 },
    { lat: 49.18517, lng: 21.175582 },
    { lat: 49.185153, lng: 21.175236 },
    { lat: 49.185185, lng: 21.174651 },
    { lat: 49.185159, lng: 21.174651 },
    { lat: 49.184304, lng: 21.174886 },
    { lat: 49.184011, lng: 21.173906 },
    { lat: 49.182942, lng: 21.172208 },
    { lat: 49.182636, lng: 21.171849 },
    { lat: 49.182473, lng: 21.171658 },
    { lat: 49.182277, lng: 21.171393 },
    { lat: 49.181682, lng: 21.17071 },
    { lat: 49.181582, lng: 21.170503 },
    { lat: 49.181299, lng: 21.170254 },
    { lat: 49.180178, lng: 21.169578 },
    { lat: 49.179831, lng: 21.169459 },
    { lat: 49.179707, lng: 21.169444 },
    { lat: 49.179293, lng: 21.16927 },
    { lat: 49.179049, lng: 21.169078 },
    { lat: 49.178561, lng: 21.169001 },
    { lat: 49.178366, lng: 21.168901 },
    { lat: 49.178345, lng: 21.168896 },
    { lat: 49.178194, lng: 21.168856 },
    { lat: 49.178177, lng: 21.168781 },
    { lat: 49.178147, lng: 21.168424 },
    { lat: 49.178157, lng: 21.168366 },
    { lat: 49.178159, lng: 21.168171 },
    { lat: 49.178268, lng: 21.167948 },
    { lat: 49.178192, lng: 21.167532 },
    { lat: 49.17815, lng: 21.167502 },
    { lat: 49.17805, lng: 21.167169 },
    { lat: 49.178076, lng: 21.167008 },
    { lat: 49.178188, lng: 21.166656 },
    { lat: 49.178172, lng: 21.16644 },
    { lat: 49.17814, lng: 21.166263 },
    { lat: 49.178177, lng: 21.166154 },
    { lat: 49.178202, lng: 21.16603 },
    { lat: 49.178196, lng: 21.165766 },
    { lat: 49.17827, lng: 21.165661 },
    { lat: 49.178179, lng: 21.165468 },
    { lat: 49.178121, lng: 21.165415 },
    { lat: 49.17811, lng: 21.165275 },
    { lat: 49.178217, lng: 21.165129 },
    { lat: 49.178227, lng: 21.165015 },
    { lat: 49.178174, lng: 21.164788 },
    { lat: 49.178244, lng: 21.164677 },
    { lat: 49.178252, lng: 21.164515 },
    { lat: 49.178194, lng: 21.164411 },
    { lat: 49.178308, lng: 21.163745 },
    { lat: 49.178307, lng: 21.163464 },
    { lat: 49.178295, lng: 21.163471 },
    { lat: 49.178191, lng: 21.163542 },
    { lat: 49.176407, lng: 21.164977 },
    { lat: 49.176336, lng: 21.165034 },
    { lat: 49.176172, lng: 21.165166 },
    { lat: 49.175085, lng: 21.165926 },
    { lat: 49.175069, lng: 21.165937 },
    { lat: 49.17466, lng: 21.166222 },
    { lat: 49.174052, lng: 21.166627 },
    { lat: 49.171704, lng: 21.168419 },
    { lat: 49.171463, lng: 21.168111 },
    { lat: 49.171422, lng: 21.168058 },
    { lat: 49.171403, lng: 21.168036 },
    { lat: 49.171374, lng: 21.167998 },
    { lat: 49.171312, lng: 21.16772 },
    { lat: 49.17107, lng: 21.167389 },
    { lat: 49.170657, lng: 21.166981 },
    { lat: 49.169779, lng: 21.166114 },
    { lat: 49.169557, lng: 21.165912 },
    { lat: 49.168692, lng: 21.165395 },
    { lat: 49.16794, lng: 21.165015 },
    { lat: 49.16737, lng: 21.164558 },
    { lat: 49.166365, lng: 21.164059 },
    { lat: 49.166337, lng: 21.164043 },
    { lat: 49.165942, lng: 21.163848 },
  ],
  Podhorany: [
    { lat: 49.0622674, lng: 21.3658409 },
    { lat: 49.0623618, lng: 21.3659851 },
    { lat: 49.0625019, lng: 21.3660433 },
    { lat: 49.0625696, lng: 21.3663942 },
    { lat: 49.0630438, lng: 21.3659887 },
    { lat: 49.0630092, lng: 21.3656194 },
    { lat: 49.0630654, lng: 21.3654314 },
    { lat: 49.0632816, lng: 21.3652081 },
    { lat: 49.0637112, lng: 21.3650507 },
    { lat: 49.0641763, lng: 21.3649832 },
    { lat: 49.0643351, lng: 21.3652596 },
    { lat: 49.0645997, lng: 21.3660613 },
    { lat: 49.064739, lng: 21.3663528 },
    { lat: 49.0652715, lng: 21.366174 },
    { lat: 49.0656556, lng: 21.3650876 },
    { lat: 49.0661355, lng: 21.3648439 },
    { lat: 49.0665173, lng: 21.3645528 },
    { lat: 49.0674349, lng: 21.3641565 },
    { lat: 49.067861, lng: 21.3638071 },
    { lat: 49.0689462, lng: 21.36341 },
    { lat: 49.0693255, lng: 21.3631836 },
    { lat: 49.0698978, lng: 21.3630929 },
    { lat: 49.0702102, lng: 21.3628524 },
    { lat: 49.0706738, lng: 21.3627455 },
    { lat: 49.071698, lng: 21.3623411 },
    { lat: 49.072015, lng: 21.3621767 },
    { lat: 49.072157, lng: 21.3619563 },
    { lat: 49.0726023, lng: 21.3617164 },
    { lat: 49.0729315, lng: 21.3615914 },
    { lat: 49.0734346, lng: 21.3612897 },
    { lat: 49.0736885, lng: 21.3613181 },
    { lat: 49.0740579, lng: 21.361102 },
    { lat: 49.0743056, lng: 21.3606166 },
    { lat: 49.0744414, lng: 21.3605677 },
    { lat: 49.0746413, lng: 21.360671 },
    { lat: 49.0748627, lng: 21.3606539 },
    { lat: 49.0751551, lng: 21.3604273 },
    { lat: 49.0774246, lng: 21.3594495 },
    { lat: 49.0774346, lng: 21.3595526 },
    { lat: 49.0785773, lng: 21.3600638 },
    { lat: 49.0796268, lng: 21.35979 },
    { lat: 49.0794916, lng: 21.3604676 },
    { lat: 49.0799453, lng: 21.3603958 },
    { lat: 49.0802753, lng: 21.3604487 },
    { lat: 49.080979, lng: 21.3608029 },
    { lat: 49.0810783, lng: 21.3608912 },
    { lat: 49.0809573, lng: 21.3613568 },
    { lat: 49.0812935, lng: 21.361536 },
    { lat: 49.0812154, lng: 21.3618279 },
    { lat: 49.081373, lng: 21.3619426 },
    { lat: 49.0815712, lng: 21.3611174 },
    { lat: 49.0817065, lng: 21.3610908 },
    { lat: 49.0837694, lng: 21.3613315 },
    { lat: 49.0838207, lng: 21.3612012 },
    { lat: 49.0840015, lng: 21.3610423 },
    { lat: 49.0844446, lng: 21.3612156 },
    { lat: 49.0886899, lng: 21.3608394 },
    { lat: 49.0912733, lng: 21.3585426 },
    { lat: 49.0938198, lng: 21.3597949 },
    { lat: 49.0942956, lng: 21.3594063 },
    { lat: 49.094756, lng: 21.3588097 },
    { lat: 49.0952722, lng: 21.3585748 },
    { lat: 49.0954232, lng: 21.3585859 },
    { lat: 49.0956742, lng: 21.3587747 },
    { lat: 49.0961345, lng: 21.3595371 },
    { lat: 49.0967002, lng: 21.3596804 },
    { lat: 49.0969032, lng: 21.3602176 },
    { lat: 49.0970562, lng: 21.3610511 },
    { lat: 49.0974539, lng: 21.3607713 },
    { lat: 49.0977937, lng: 21.3608825 },
    { lat: 49.09808, lng: 21.3604412 },
    { lat: 49.0984137, lng: 21.3600881 },
    { lat: 49.0987747, lng: 21.3598191 },
    { lat: 49.0988838, lng: 21.3596277 },
    { lat: 49.0993066, lng: 21.3600714 },
    { lat: 49.0997219, lng: 21.3606449 },
    { lat: 49.0998394, lng: 21.360654 },
    { lat: 49.1000745, lng: 21.3605074 },
    { lat: 49.1002644, lng: 21.3600038 },
    { lat: 49.1010592, lng: 21.3601687 },
    { lat: 49.1011633, lng: 21.3603396 },
    { lat: 49.1015512, lng: 21.3603333 },
    { lat: 49.1019491, lng: 21.3605813 },
    { lat: 49.102261, lng: 21.3609695 },
    { lat: 49.1030813, lng: 21.3616906 },
    { lat: 49.1032138, lng: 21.3618632 },
    { lat: 49.1035286, lng: 21.362499 },
    { lat: 49.1035583, lng: 21.3624445 },
    { lat: 49.103834, lng: 21.3625576 },
    { lat: 49.1041262, lng: 21.3625059 },
    { lat: 49.1049906, lng: 21.3621712 },
    { lat: 49.1054079, lng: 21.3619427 },
    { lat: 49.1061079, lng: 21.3617583 },
    { lat: 49.1064023, lng: 21.3619362 },
    { lat: 49.1068556, lng: 21.3618144 },
    { lat: 49.1070905, lng: 21.3618478 },
    { lat: 49.1075114, lng: 21.3616201 },
    { lat: 49.1080947, lng: 21.3614122 },
    { lat: 49.1085978, lng: 21.3616008 },
    { lat: 49.1090026, lng: 21.3612504 },
    { lat: 49.1091459, lng: 21.3610455 },
    { lat: 49.1093412, lng: 21.3605331 },
    { lat: 49.1097032, lng: 21.3601871 },
    { lat: 49.1103286, lng: 21.3597621 },
    { lat: 49.1105866, lng: 21.3612039 },
    { lat: 49.1103571, lng: 21.3612513 },
    { lat: 49.1108497, lng: 21.3638133 },
    { lat: 49.1115824, lng: 21.3635569 },
    { lat: 49.112353, lng: 21.3631382 },
    { lat: 49.1124885, lng: 21.3631899 },
    { lat: 49.1133978, lng: 21.3630434 },
    { lat: 49.1135704, lng: 21.363131 },
    { lat: 49.1137037, lng: 21.3629221 },
    { lat: 49.1138017, lng: 21.3624747 },
    { lat: 49.1139527, lng: 21.3624057 },
    { lat: 49.113967, lng: 21.362441 },
    { lat: 49.114025, lng: 21.362114 },
    { lat: 49.11399, lng: 21.362004 },
    { lat: 49.113954, lng: 21.361845 },
    { lat: 49.114039, lng: 21.361593 },
    { lat: 49.114107, lng: 21.361475 },
    { lat: 49.114102, lng: 21.361208 },
    { lat: 49.114161, lng: 21.360998 },
    { lat: 49.114162, lng: 21.360731 },
    { lat: 49.114162, lng: 21.360375 },
    { lat: 49.114116, lng: 21.360183 },
    { lat: 49.114013, lng: 21.36006 },
    { lat: 49.113986, lng: 21.359753 },
    { lat: 49.11379, lng: 21.359448 },
    { lat: 49.11375, lng: 21.359211 },
    { lat: 49.113777, lng: 21.359068 },
    { lat: 49.113769, lng: 21.358795 },
    { lat: 49.11367, lng: 21.358596 },
    { lat: 49.113524, lng: 21.358435 },
    { lat: 49.113452, lng: 21.35821 },
    { lat: 49.11347, lng: 21.357897 },
    { lat: 49.113425, lng: 21.357619 },
    { lat: 49.113387, lng: 21.357365 },
    { lat: 49.113298, lng: 21.357145 },
    { lat: 49.113091, lng: 21.35707 },
    { lat: 49.113018, lng: 21.356903 },
    { lat: 49.113012, lng: 21.356616 },
    { lat: 49.112928, lng: 21.356447 },
    { lat: 49.112731, lng: 21.35577 },
    { lat: 49.1126736, lng: 21.3556117 },
    { lat: 49.1125733, lng: 21.3555285 },
    { lat: 49.1124074, lng: 21.3555478 },
    { lat: 49.1117294, lng: 21.3551878 },
    { lat: 49.1112284, lng: 21.3551808 },
    { lat: 49.1110454, lng: 21.3553461 },
    { lat: 49.1106528, lng: 21.355463 },
    { lat: 49.110393, lng: 21.3553136 },
    { lat: 49.1101696, lng: 21.3554846 },
    { lat: 49.1092279, lng: 21.3549937 },
    { lat: 49.1087749, lng: 21.3546286 },
    { lat: 49.107814, lng: 21.3532312 },
    { lat: 49.1075566, lng: 21.352942 },
    { lat: 49.1072078, lng: 21.3526799 },
    { lat: 49.107095, lng: 21.3524395 },
    { lat: 49.1071382, lng: 21.3519654 },
    { lat: 49.1070559, lng: 21.3516586 },
    { lat: 49.1066643, lng: 21.3512095 },
    { lat: 49.1062085, lng: 21.350827 },
    { lat: 49.105755, lng: 21.3505955 },
    { lat: 49.1056063, lng: 21.3504392 },
    { lat: 49.1053697, lng: 21.350016 },
    { lat: 49.1032779, lng: 21.3482745 },
    { lat: 49.102481, lng: 21.3478561 },
    { lat: 49.1020563, lng: 21.347349 },
    { lat: 49.1014945, lng: 21.3474874 },
    { lat: 49.1010882, lng: 21.3477055 },
    { lat: 49.1008415, lng: 21.347584 },
    { lat: 49.1005067, lng: 21.3472295 },
    { lat: 49.0999258, lng: 21.3470665 },
    { lat: 49.0999608, lng: 21.347031 },
    { lat: 49.0987469, lng: 21.3450778 },
    { lat: 49.0969193, lng: 21.3428143 },
    { lat: 49.0959839, lng: 21.3413701 },
    { lat: 49.0956736, lng: 21.3404811 },
    { lat: 49.0944759, lng: 21.3408375 },
    { lat: 49.094205, lng: 21.3402459 },
    { lat: 49.0939809, lng: 21.3393761 },
    { lat: 49.0934436, lng: 21.3393542 },
    { lat: 49.0928552, lng: 21.3386813 },
    { lat: 49.0922872, lng: 21.3379043 },
    { lat: 49.0911153, lng: 21.3382561 },
    { lat: 49.0908063, lng: 21.3379684 },
    { lat: 49.0901725, lng: 21.3376219 },
    { lat: 49.0850991, lng: 21.3363467 },
    { lat: 49.085087, lng: 21.3356418 },
    { lat: 49.084593, lng: 21.3352734 },
    { lat: 49.0841208, lng: 21.3352028 },
    { lat: 49.0832023, lng: 21.335191 },
    { lat: 49.081441, lng: 21.3354835 },
    { lat: 49.0805553, lng: 21.3355131 },
    { lat: 49.0803102, lng: 21.3353744 },
    { lat: 49.0796115, lng: 21.3346243 },
    { lat: 49.0793678, lng: 21.3341474 },
    { lat: 49.0792006, lng: 21.3339767 },
    { lat: 49.0783277, lng: 21.333504 },
    { lat: 49.0765514, lng: 21.3347857 },
    { lat: 49.0742359, lng: 21.336482 },
    { lat: 49.0742793, lng: 21.3369933 },
    { lat: 49.0744277, lng: 21.3373268 },
    { lat: 49.0744977, lng: 21.3378208 },
    { lat: 49.0747547, lng: 21.338098 },
    { lat: 49.075266, lng: 21.3383381 },
    { lat: 49.0755686, lng: 21.3383695 },
    { lat: 49.0760685, lng: 21.3381995 },
    { lat: 49.0760172, lng: 21.3418158 },
    { lat: 49.0732358, lng: 21.3411051 },
    { lat: 49.0730955, lng: 21.3420569 },
    { lat: 49.0723502, lng: 21.3420404 },
    { lat: 49.0720896, lng: 21.3440883 },
    { lat: 49.0708938, lng: 21.344549 },
    { lat: 49.0687769, lng: 21.3456661 },
    { lat: 49.0683735, lng: 21.3457909 },
    { lat: 49.0681802, lng: 21.3457627 },
    { lat: 49.0671575, lng: 21.3477609 },
    { lat: 49.0668175, lng: 21.3487318 },
    { lat: 49.0667421, lng: 21.3494529 },
    { lat: 49.0667703, lng: 21.3500958 },
    { lat: 49.0664885, lng: 21.3516572 },
    { lat: 49.0663507, lng: 21.3532581 },
    { lat: 49.0660922, lng: 21.3532717 },
    { lat: 49.065998, lng: 21.3533539 },
    { lat: 49.0659078, lng: 21.3534291 },
    { lat: 49.0660838, lng: 21.35401 },
    { lat: 49.0663525, lng: 21.354637 },
    { lat: 49.065799, lng: 21.3547599 },
    { lat: 49.0660473, lng: 21.3561429 },
    { lat: 49.0661976, lng: 21.3567243 },
    { lat: 49.0663194, lng: 21.3579068 },
    { lat: 49.0663046, lng: 21.3588107 },
    { lat: 49.0659221, lng: 21.3589993 },
    { lat: 49.0651706, lng: 21.3588147 },
    { lat: 49.065083, lng: 21.3579506 },
    { lat: 49.0640287, lng: 21.3577643 },
    { lat: 49.0634386, lng: 21.3574082 },
    { lat: 49.0634178, lng: 21.3589335 },
    { lat: 49.0632133, lng: 21.3606799 },
    { lat: 49.0627616, lng: 21.3633508 },
    { lat: 49.0626068, lng: 21.3648053 },
    { lat: 49.062758, lng: 21.3650447 },
    { lat: 49.0626997, lng: 21.3652797 },
    { lat: 49.0624921, lng: 21.3653723 },
    { lat: 49.0622674, lng: 21.3658409 },
  ],
  MalýŠariš: [
    { lat: 49.0363617, lng: 21.1672021 },
    { lat: 49.0366669, lng: 21.1656373 },
    { lat: 49.0367983, lng: 21.1654455 },
    { lat: 49.0367111, lng: 21.1649651 },
    { lat: 49.0364707, lng: 21.1644766 },
    { lat: 49.036328, lng: 21.1643094 },
    { lat: 49.0360464, lng: 21.1642274 },
    { lat: 49.0357001, lng: 21.1636307 },
    { lat: 49.0353935, lng: 21.1628274 },
    { lat: 49.0353143, lng: 21.1627765 },
    { lat: 49.03513, lng: 21.1622508 },
    { lat: 49.0349885, lng: 21.1620097 },
    { lat: 49.0350687, lng: 21.1617722 },
    { lat: 49.0350201, lng: 21.1611201 },
    { lat: 49.0346928, lng: 21.160729 },
    { lat: 49.0345397, lng: 21.1606233 },
    { lat: 49.0345229, lng: 21.1605162 },
    { lat: 49.0345959, lng: 21.1596236 },
    { lat: 49.0345043, lng: 21.1594631 },
    { lat: 49.034464, lng: 21.1591519 },
    { lat: 49.0345481, lng: 21.1589219 },
    { lat: 49.0345365, lng: 21.1586678 },
    { lat: 49.0345977, lng: 21.1585363 },
    { lat: 49.0344801, lng: 21.1581956 },
    { lat: 49.0345879, lng: 21.1580106 },
    { lat: 49.0345665, lng: 21.1578306 },
    { lat: 49.0346122, lng: 21.1573216 },
    { lat: 49.0347274, lng: 21.1569079 },
    { lat: 49.0347605, lng: 21.1564532 },
    { lat: 49.0347852, lng: 21.1563489 },
    { lat: 49.0349046, lng: 21.1562447 },
    { lat: 49.0349241, lng: 21.1559668 },
    { lat: 49.0348821, lng: 21.1554954 },
    { lat: 49.0350511, lng: 21.1554332 },
    { lat: 49.0350901, lng: 21.1552051 },
    { lat: 49.035019, lng: 21.1548036 },
    { lat: 49.0350396, lng: 21.154141 },
    { lat: 49.0352705, lng: 21.1535396 },
    { lat: 49.0351973, lng: 21.1533272 },
    { lat: 49.0352792, lng: 21.1530662 },
    { lat: 49.0350315, lng: 21.1527425 },
    { lat: 49.0350181, lng: 21.1527435 },
    { lat: 49.0338801, lng: 21.1532689 },
    { lat: 49.0337987, lng: 21.1531565 },
    { lat: 49.0325315, lng: 21.1523295 },
    { lat: 49.0324264, lng: 21.1523189 },
    { lat: 49.0315186, lng: 21.1529712 },
    { lat: 49.0294821, lng: 21.153947 },
    { lat: 49.0285954, lng: 21.1545149 },
    { lat: 49.028412, lng: 21.1539111 },
    { lat: 49.0282137, lng: 21.1540457 },
    { lat: 49.0281516, lng: 21.1538957 },
    { lat: 49.0270608, lng: 21.1548464 },
    { lat: 49.0259728, lng: 21.1555351 },
    { lat: 49.0256257, lng: 21.1558739 },
    { lat: 49.0241916, lng: 21.1575366 },
    { lat: 49.0215486, lng: 21.1608708 },
    { lat: 49.0214511, lng: 21.160742 },
    { lat: 49.0196871, lng: 21.162616 },
    { lat: 49.0196664, lng: 21.1625655 },
    { lat: 49.0181133, lng: 21.1644204 },
    { lat: 49.0183387, lng: 21.1648909 },
    { lat: 49.0166906, lng: 21.1661929 },
    { lat: 49.0152643, lng: 21.1636082 },
    { lat: 49.0153293, lng: 21.1629539 },
    { lat: 49.0151409, lng: 21.162693 },
    { lat: 49.0147879, lng: 21.1634877 },
    { lat: 49.0129264, lng: 21.1671258 },
    { lat: 49.0113152, lng: 21.1684963 },
    { lat: 49.0110012, lng: 21.1686037 },
    { lat: 49.0101812, lng: 21.1685777 },
    { lat: 49.0087413, lng: 21.1690276 },
    { lat: 49.0073504, lng: 21.1692445 },
    { lat: 49.0070235, lng: 21.1694375 },
    { lat: 49.0067813, lng: 21.1696447 },
    { lat: 49.0063618, lng: 21.1703674 },
    { lat: 49.0063257, lng: 21.1703277 },
    { lat: 49.0059317, lng: 21.1719338 },
    { lat: 49.0052207, lng: 21.170365 },
    { lat: 49.0051495, lng: 21.170111 },
    { lat: 49.0050657, lng: 21.1700956 },
    { lat: 49.0050269, lng: 21.1701805 },
    { lat: 49.004873, lng: 21.1715161 },
    { lat: 49.004814, lng: 21.1708939 },
    { lat: 49.0039616, lng: 21.1717745 },
    { lat: 49.0024048, lng: 21.1690768 },
    { lat: 49.0019247, lng: 21.16973 },
    { lat: 49.0023492, lng: 21.1707414 },
    { lat: 49.0019182, lng: 21.1726716 },
    { lat: 49.0013467, lng: 21.1731407 },
    { lat: 49.0009755, lng: 21.1739741 },
    { lat: 49.0010663, lng: 21.174317 },
    { lat: 49.0007811, lng: 21.1748036 },
    { lat: 49.0006477, lng: 21.1748215 },
    { lat: 49.0003023, lng: 21.1750398 },
    { lat: 48.9995617, lng: 21.1761096 },
    { lat: 48.9982575, lng: 21.177545 },
    { lat: 48.9978175, lng: 21.1777274 },
    { lat: 48.9968126, lng: 21.1776856 },
    { lat: 48.995976, lng: 21.1781011 },
    { lat: 48.9953404, lng: 21.1786467 },
    { lat: 48.9956781, lng: 21.1792265 },
    { lat: 48.9958373, lng: 21.1801754 },
    { lat: 48.9968215, lng: 21.1798406 },
    { lat: 48.9972519, lng: 21.1798578 },
    { lat: 48.9975006, lng: 21.1813937 },
    { lat: 48.9974007, lng: 21.1840612 },
    { lat: 48.9968662, lng: 21.1843874 },
    { lat: 48.9966958, lng: 21.1848319 },
    { lat: 48.9965959, lng: 21.1849198 },
    { lat: 48.996434, lng: 21.1849222 },
    { lat: 48.9962269, lng: 21.1847777 },
    { lat: 48.9958416, lng: 21.1843648 },
    { lat: 48.9957996, lng: 21.1874129 },
    { lat: 48.9961598, lng: 21.1876997 },
    { lat: 48.9959089, lng: 21.1895695 },
    { lat: 48.9978636, lng: 21.1925917 },
    { lat: 48.9980529, lng: 21.1924547 },
    { lat: 49.0006307, lng: 21.1948204 },
    { lat: 49.0005493, lng: 21.1950212 },
    { lat: 49.0009233, lng: 21.1953927 },
    { lat: 49.00215, lng: 21.1975984 },
    { lat: 49.0024811, lng: 21.1970996 },
    { lat: 49.0028378, lng: 21.1975653 },
    { lat: 49.0028803, lng: 21.1975203 },
    { lat: 49.003056, lng: 21.1976359 },
    { lat: 49.003594, lng: 21.1989 },
    { lat: 49.0037559, lng: 21.198822 },
    { lat: 49.0046502, lng: 21.1978055 },
    { lat: 49.0049105, lng: 21.1984624 },
    { lat: 49.0059387, lng: 21.1976237 },
    { lat: 49.0066765, lng: 21.1998803 },
    { lat: 49.0069848, lng: 21.1996641 },
    { lat: 49.0074661, lng: 21.1994692 },
    { lat: 49.0077031, lng: 21.1996722 },
    { lat: 49.0079251, lng: 21.1997601 },
    { lat: 49.0081706, lng: 21.2022541 },
    { lat: 49.0084435, lng: 21.2023391 },
    { lat: 49.0092074, lng: 21.2035226 },
    { lat: 49.0103292, lng: 21.2051818 },
    { lat: 49.0120753, lng: 21.2031453 },
    { lat: 49.0124248, lng: 21.2025925 },
    { lat: 49.0126158, lng: 21.2026533 },
    { lat: 49.0132246, lng: 21.2001269 },
    { lat: 49.0142716, lng: 21.2008659 },
    { lat: 49.0149239, lng: 21.2010358 },
    { lat: 49.0149331, lng: 21.1993792 },
    { lat: 49.015987, lng: 21.1994738 },
    { lat: 49.017553, lng: 21.1997814 },
    { lat: 49.0181595, lng: 21.1996678 },
    { lat: 49.0185173, lng: 21.1997395 },
    { lat: 49.0185099, lng: 21.1996163 },
    { lat: 49.0184286, lng: 21.1996014 },
    { lat: 49.0181554, lng: 21.1988556 },
    { lat: 49.0180402, lng: 21.1980824 },
    { lat: 49.0179851, lng: 21.1971488 },
    { lat: 49.0183311, lng: 21.1958295 },
    { lat: 49.0186764, lng: 21.1950668 },
    { lat: 49.0192945, lng: 21.1944653 },
    { lat: 49.0197756, lng: 21.1934776 },
    { lat: 49.0217975, lng: 21.1897911 },
    { lat: 49.0229458, lng: 21.1886586 },
    { lat: 49.0233798, lng: 21.1884274 },
    { lat: 49.0235325, lng: 21.188529 },
    { lat: 49.0242114, lng: 21.1879284 },
    { lat: 49.025389, lng: 21.1860033 },
    { lat: 49.0261483, lng: 21.1846646 },
    { lat: 49.0268771, lng: 21.1826352 },
    { lat: 49.027789, lng: 21.1812524 },
    { lat: 49.0285283, lng: 21.1796371 },
    { lat: 49.0295155, lng: 21.1779158 },
    { lat: 49.0296316, lng: 21.1772429 },
    { lat: 49.0295631, lng: 21.1763148 },
    { lat: 49.0296844, lng: 21.1755593 },
    { lat: 49.0300433, lng: 21.1748773 },
    { lat: 49.0309219, lng: 21.1735749 },
    { lat: 49.0317285, lng: 21.1724909 },
    { lat: 49.0316813, lng: 21.1722803 },
    { lat: 49.0326017, lng: 21.1710962 },
    { lat: 49.0345656, lng: 21.1694177 },
    { lat: 49.0347226, lng: 21.1693607 },
    { lat: 49.0352302, lng: 21.1688633 },
    { lat: 49.0354561, lng: 21.1685703 },
    { lat: 49.0355094, lng: 21.1683702 },
    { lat: 49.0359402, lng: 21.1677528 },
    { lat: 49.0360592, lng: 21.1673536 },
    { lat: 49.0363223, lng: 21.167201 },
    { lat: 49.0363617, lng: 21.1672021 },
  ],
  Široké: [
    { lat: 49.023831, lng: 20.883737 },
    { lat: 49.023751, lng: 20.883609 },
    { lat: 49.023519, lng: 20.883642 },
    { lat: 49.022961, lng: 20.883398 },
    { lat: 49.022763, lng: 20.883283 },
    { lat: 49.022159, lng: 20.882692 },
    { lat: 49.021295, lng: 20.880931 },
    { lat: 49.021065, lng: 20.880439 },
    { lat: 49.020921, lng: 20.880529 },
    { lat: 49.0206, lng: 20.880882 },
    { lat: 49.019953, lng: 20.880203 },
    { lat: 49.018996, lng: 20.878843 },
    { lat: 49.018957, lng: 20.878794 },
    { lat: 49.018192, lng: 20.877733 },
    { lat: 49.018124, lng: 20.877616 },
    { lat: 49.017721, lng: 20.877087 },
    { lat: 49.017563, lng: 20.876921 },
    { lat: 49.017367, lng: 20.876613 },
    { lat: 49.017245, lng: 20.87652 },
    { lat: 49.01719, lng: 20.876332 },
    { lat: 49.016952, lng: 20.875966 },
    { lat: 49.016874, lng: 20.875743 },
    { lat: 49.016721, lng: 20.875512 },
    { lat: 49.016636, lng: 20.875439 },
    { lat: 49.016515, lng: 20.875251 },
    { lat: 49.01631, lng: 20.87472 },
    { lat: 49.016119, lng: 20.874491 },
    { lat: 49.015951, lng: 20.874318 },
    { lat: 49.015869, lng: 20.874181 },
    { lat: 49.015793, lng: 20.874128 },
    { lat: 49.015654, lng: 20.874062 },
    { lat: 49.015451, lng: 20.873879 },
    { lat: 49.015334, lng: 20.873772 },
    { lat: 49.015229, lng: 20.87364 },
    { lat: 49.015155, lng: 20.873616 },
    { lat: 49.01501, lng: 20.873686 },
    { lat: 49.014822, lng: 20.87366 },
    { lat: 49.014535, lng: 20.873758 },
    { lat: 49.013907, lng: 20.874157 },
    { lat: 49.013735, lng: 20.874135 },
    { lat: 49.013653, lng: 20.874118 },
    { lat: 49.013592, lng: 20.874136 },
    { lat: 49.013526, lng: 20.87409 },
    { lat: 49.013474, lng: 20.87408 },
    { lat: 49.0134, lng: 20.874049 },
    { lat: 49.013157, lng: 20.874092 },
    { lat: 49.013081, lng: 20.874075 },
    { lat: 49.012807, lng: 20.873954 },
    { lat: 49.012463, lng: 20.873866 },
    { lat: 49.011605, lng: 20.873813 },
    { lat: 49.0111782, lng: 20.8739025 },
    { lat: 49.011076, lng: 20.87397 },
    { lat: 49.01081, lng: 20.874041 },
    { lat: 49.010504, lng: 20.87391 },
    { lat: 49.0103943, lng: 20.8738601 },
    { lat: 49.01016, lng: 20.873714 },
    { lat: 49.010051, lng: 20.873594 },
    { lat: 49.010049, lng: 20.873512 },
    { lat: 49.009882, lng: 20.873479 },
    { lat: 49.009823, lng: 20.873271 },
    { lat: 49.009684, lng: 20.873082 },
    { lat: 49.009588, lng: 20.873045 },
    { lat: 49.009551, lng: 20.872944 },
    { lat: 49.009016, lng: 20.872601 },
    { lat: 49.008813, lng: 20.872289 },
    { lat: 49.008693, lng: 20.872243 },
    { lat: 49.008588, lng: 20.872119 },
    { lat: 49.008495, lng: 20.8721 },
    { lat: 49.008394, lng: 20.871938 },
    { lat: 49.008345, lng: 20.871792 },
    { lat: 49.0081206, lng: 20.8716746 },
    { lat: 49.008034, lng: 20.871581 },
    { lat: 49.0079, lng: 20.871477 },
    { lat: 49.007823, lng: 20.871469 },
    { lat: 49.007717, lng: 20.87128 },
    { lat: 49.007423, lng: 20.871154 },
    { lat: 49.00734, lng: 20.871029 },
    { lat: 49.007069, lng: 20.870916 },
    { lat: 49.007036, lng: 20.870902 },
    { lat: 49.006968, lng: 20.870785 },
    { lat: 49.006865, lng: 20.870573 },
    { lat: 49.006899, lng: 20.870532 },
    { lat: 49.006901, lng: 20.870515 },
    { lat: 49.0068673, lng: 20.870494 },
    { lat: 49.006744, lng: 20.870472 },
    { lat: 49.006587, lng: 20.870461 },
    { lat: 49.0065062, lng: 20.8704795 },
    { lat: 49.006441, lng: 20.8705129 },
    { lat: 49.006432, lng: 20.870558 },
    { lat: 49.006394, lng: 20.870668 },
    { lat: 49.006343, lng: 20.870764 },
    { lat: 49.006323, lng: 20.870882 },
    { lat: 49.006286, lng: 20.870983 },
    { lat: 49.006265, lng: 20.871102 },
    { lat: 49.006218, lng: 20.871212 },
    { lat: 49.006211, lng: 20.871339 },
    { lat: 49.006131, lng: 20.871454 },
    { lat: 49.005977, lng: 20.87157 },
    { lat: 49.005866, lng: 20.871759 },
    { lat: 49.005843, lng: 20.871933 },
    { lat: 49.005773, lng: 20.872043 },
    { lat: 49.005719, lng: 20.872241 },
    { lat: 49.005698, lng: 20.872429 },
    { lat: 49.005662, lng: 20.872536 },
    { lat: 49.00568, lng: 20.872814 },
    { lat: 49.005615, lng: 20.87307 },
    { lat: 49.005556, lng: 20.873193 },
    { lat: 49.005483, lng: 20.873247 },
    { lat: 49.00533, lng: 20.873303 },
    { lat: 49.005259, lng: 20.873376 },
    { lat: 49.005225, lng: 20.873525 },
    { lat: 49.005119, lng: 20.873738 },
    { lat: 49.005052, lng: 20.8738 },
    { lat: 49.004886, lng: 20.873813 },
    { lat: 49.004776, lng: 20.873911 },
    { lat: 49.004659, lng: 20.873863 },
    { lat: 49.004582, lng: 20.873913 },
    { lat: 49.004389, lng: 20.873917 },
    { lat: 49.004296, lng: 20.873964 },
    { lat: 49.004271, lng: 20.874002 },
    { lat: 49.004225, lng: 20.874139 },
    { lat: 49.004219, lng: 20.874223 },
    { lat: 49.004177, lng: 20.874272 },
    { lat: 49.003912, lng: 20.874939 },
    { lat: 49.003887, lng: 20.875088 },
    { lat: 49.003831, lng: 20.875181 },
    { lat: 49.003672, lng: 20.875192 },
    { lat: 49.00365, lng: 20.875207 },
    { lat: 49.003524, lng: 20.875396 },
    { lat: 49.003492, lng: 20.875507 },
    { lat: 49.003447, lng: 20.875565 },
    { lat: 49.00337, lng: 20.875581 },
    { lat: 49.00322, lng: 20.875901 },
    { lat: 49.003192, lng: 20.876078 },
    { lat: 49.003152, lng: 20.876182 },
    { lat: 49.003087, lng: 20.876457 },
    { lat: 49.002969, lng: 20.876613 },
    { lat: 49.00295, lng: 20.876753 },
    { lat: 49.002916, lng: 20.876852 },
    { lat: 49.002833, lng: 20.876906 },
    { lat: 49.002698, lng: 20.87706 },
    { lat: 49.002625, lng: 20.87713 },
    { lat: 49.002409, lng: 20.877368 },
    { lat: 49.002254, lng: 20.877482 },
    { lat: 49.002201, lng: 20.87757 },
    { lat: 49.002155, lng: 20.877689 },
    { lat: 49.002051, lng: 20.877787 },
    { lat: 49.001957, lng: 20.877973 },
    { lat: 49.001901, lng: 20.878047 },
    { lat: 49.001815, lng: 20.878045 },
    { lat: 49.00175, lng: 20.878079 },
    { lat: 49.00169, lng: 20.87813 },
    { lat: 49.001632, lng: 20.878261 },
    { lat: 49.001551, lng: 20.878392 },
    { lat: 49.001469, lng: 20.878598 },
    { lat: 49.001391, lng: 20.878672 },
    { lat: 49.001292, lng: 20.87873 },
    { lat: 49.001227, lng: 20.878799 },
    { lat: 49.001182, lng: 20.878891 },
    { lat: 49.001127, lng: 20.878951 },
    { lat: 49.001112, lng: 20.879095 },
    { lat: 49.000953, lng: 20.879221 },
    { lat: 49.000869, lng: 20.879274 },
    { lat: 49.000783, lng: 20.879327 },
    { lat: 49.000719, lng: 20.879322 },
    { lat: 49.0005736, lng: 20.879416 },
    { lat: 49.0005274, lng: 20.8794802 },
    { lat: 49.000474, lng: 20.8795842 },
    { lat: 49.000463, lng: 20.879803 },
    { lat: 49.000398, lng: 20.879931 },
    { lat: 49.000313, lng: 20.879987 },
    { lat: 49.000194, lng: 20.880124 },
    { lat: 49.000145, lng: 20.880108 },
    { lat: 49.000052, lng: 20.880108 },
    { lat: 48.999952, lng: 20.880195 },
    { lat: 48.999908, lng: 20.880277 },
    { lat: 48.999828, lng: 20.880508 },
    { lat: 48.999795, lng: 20.880568 },
    { lat: 48.999684, lng: 20.880574 },
    { lat: 48.9996, lng: 20.880671 },
    { lat: 48.999554, lng: 20.880763 },
    { lat: 48.999491, lng: 20.880844 },
    { lat: 48.999435, lng: 20.881012 },
    { lat: 48.999369, lng: 20.881065 },
    { lat: 48.999286, lng: 20.881091 },
    { lat: 48.999244, lng: 20.881194 },
    { lat: 48.99911, lng: 20.881283 },
    { lat: 48.999002, lng: 20.881317 },
    { lat: 48.998958, lng: 20.881379 },
    { lat: 48.998831, lng: 20.881459 },
    { lat: 48.998725, lng: 20.881574 },
    { lat: 48.998576, lng: 20.881583 },
    { lat: 48.998513, lng: 20.881675 },
    { lat: 48.998512, lng: 20.881705 },
    { lat: 48.998528, lng: 20.881771 },
    { lat: 48.998514, lng: 20.881858 },
    { lat: 48.9984894, lng: 20.8819009 },
    { lat: 48.9984734, lng: 20.8820931 },
    { lat: 48.998511, lng: 20.882249 },
    { lat: 48.998487, lng: 20.882315 },
    { lat: 48.998475, lng: 20.882575 },
    { lat: 48.998444, lng: 20.882739 },
    { lat: 48.998444, lng: 20.883021 },
    { lat: 48.998433, lng: 20.883116 },
    { lat: 48.998435, lng: 20.883126 },
    { lat: 48.998481, lng: 20.883338 },
    { lat: 48.998577, lng: 20.883576 },
    { lat: 48.998618, lng: 20.883755 },
    { lat: 48.998685, lng: 20.883972 },
    { lat: 48.998678, lng: 20.884206 },
    { lat: 48.998693, lng: 20.88439 },
    { lat: 48.998667, lng: 20.884578 },
    { lat: 48.998662, lng: 20.885152 },
    { lat: 48.99847, lng: 20.885389 },
    { lat: 48.998398, lng: 20.885534 },
    { lat: 48.998403, lng: 20.885596 },
    { lat: 48.998475, lng: 20.885782 },
    { lat: 48.998502, lng: 20.885947 },
    { lat: 48.99855, lng: 20.886106 },
    { lat: 48.99856, lng: 20.886296 },
    { lat: 48.998495, lng: 20.886476 },
    { lat: 48.998464, lng: 20.8866187 },
    { lat: 48.99842, lng: 20.886756 },
    { lat: 48.998406, lng: 20.88673 },
    { lat: 48.998393, lng: 20.886433 },
    { lat: 48.998247, lng: 20.88641 },
    { lat: 48.998093, lng: 20.886313 },
    { lat: 48.998071, lng: 20.886259 },
    { lat: 48.997999, lng: 20.886126 },
    { lat: 48.997643, lng: 20.886015 },
    { lat: 48.997286, lng: 20.885774 },
    { lat: 48.99725, lng: 20.885702 },
    { lat: 48.99712, lng: 20.885684 },
    { lat: 48.997043, lng: 20.885706 },
    { lat: 48.99701, lng: 20.885647 },
    { lat: 48.996949, lng: 20.885635 },
    { lat: 48.99684, lng: 20.885496 },
    { lat: 48.99673, lng: 20.885449 },
    { lat: 48.99658, lng: 20.885222 },
    { lat: 48.99646, lng: 20.88516 },
    { lat: 48.996443, lng: 20.885109 },
    { lat: 48.996407, lng: 20.885004 },
    { lat: 48.996352, lng: 20.884845 },
    { lat: 48.996289, lng: 20.884831 },
    { lat: 48.996202, lng: 20.88469 },
    { lat: 48.996157, lng: 20.884684 },
    { lat: 48.996069, lng: 20.884523 },
    { lat: 48.995941, lng: 20.884506 },
    { lat: 48.995954, lng: 20.884435 },
    { lat: 48.995913, lng: 20.884233 },
    { lat: 48.995855, lng: 20.884199 },
    { lat: 48.995881, lng: 20.884093 },
    { lat: 48.995808, lng: 20.883858 },
    { lat: 48.99578, lng: 20.883639 },
    { lat: 48.995669, lng: 20.883345 },
    { lat: 48.995602, lng: 20.883309 },
    { lat: 48.995577, lng: 20.883214 },
    { lat: 48.995539, lng: 20.883174 },
    { lat: 48.995503, lng: 20.883011 },
    { lat: 48.995454, lng: 20.882962 },
    { lat: 48.995412, lng: 20.882789 },
    { lat: 48.995319, lng: 20.882702 },
    { lat: 48.995193, lng: 20.882519 },
    { lat: 48.995163, lng: 20.882512 },
    { lat: 48.995052, lng: 20.882322 },
    { lat: 48.994428, lng: 20.881611 },
    { lat: 48.994291, lng: 20.8815 },
    { lat: 48.994226, lng: 20.881519 },
    { lat: 48.99417, lng: 20.881377 },
    { lat: 48.994097, lng: 20.881305 },
    { lat: 48.993915, lng: 20.880912 },
    { lat: 48.993882, lng: 20.880906 },
    { lat: 48.993817, lng: 20.880786 },
    { lat: 48.993763, lng: 20.88077 },
    { lat: 48.993706, lng: 20.88057 },
    { lat: 48.993637, lng: 20.880501 },
    { lat: 48.993618, lng: 20.880426 },
    { lat: 48.993543, lng: 20.880411 },
    { lat: 48.993505, lng: 20.880339 },
    { lat: 48.993461, lng: 20.880327 },
    { lat: 48.993435, lng: 20.880179 },
    { lat: 48.993374, lng: 20.880161 },
    { lat: 48.993358, lng: 20.880057 },
    { lat: 48.993201, lng: 20.879852 },
    { lat: 48.993059, lng: 20.87986 },
    { lat: 48.992957, lng: 20.879746 },
    { lat: 48.992893, lng: 20.879674 },
    { lat: 48.992747, lng: 20.879659 },
    { lat: 48.99261, lng: 20.879579 },
    { lat: 48.992534, lng: 20.879594 },
    { lat: 48.9919861, lng: 20.8899693 },
    { lat: 48.9920133, lng: 20.8906044 },
    { lat: 48.9919787, lng: 20.8907128 },
    { lat: 48.9916989, lng: 20.8960138 },
    { lat: 48.9913401, lng: 20.9007485 },
    { lat: 48.9917437, lng: 20.9017693 },
    { lat: 48.9913348, lng: 20.9018624 },
    { lat: 48.991243, lng: 20.9030402 },
    { lat: 48.9909074, lng: 20.9044656 },
    { lat: 48.9905224, lng: 20.9044977 },
    { lat: 48.990353, lng: 20.9036784 },
    { lat: 48.9895596, lng: 20.9041216 },
    { lat: 48.9890635, lng: 20.9047251 },
    { lat: 48.9883259, lng: 20.9052119 },
    { lat: 48.9882149, lng: 20.9054958 },
    { lat: 48.9877812, lng: 20.9057203 },
    { lat: 48.9871532, lng: 20.9056187 },
    { lat: 48.9865308, lng: 20.9057887 },
    { lat: 48.9860799, lng: 20.9038678 },
    { lat: 48.9857995, lng: 20.9036327 },
    { lat: 48.9857225, lng: 20.9022816 },
    { lat: 48.9861692, lng: 20.9012125 },
    { lat: 48.9855082, lng: 20.9008373 },
    { lat: 48.9854286, lng: 20.9001794 },
    { lat: 48.9847005, lng: 20.8998177 },
    { lat: 48.9841727, lng: 20.8998882 },
    { lat: 48.9836756, lng: 20.9001012 },
    { lat: 48.9837501, lng: 20.9013693 },
    { lat: 48.9835334, lng: 20.9018528 },
    { lat: 48.9829846, lng: 20.9024686 },
    { lat: 48.9824005, lng: 20.9032688 },
    { lat: 48.982182, lng: 20.9030992 },
    { lat: 48.9820819, lng: 20.9017342 },
    { lat: 48.9812604, lng: 20.90531 },
    { lat: 48.9811375, lng: 20.9061322 },
    { lat: 48.9812026, lng: 20.906631 },
    { lat: 48.9810881, lng: 20.9099767 },
    { lat: 48.9804714, lng: 20.9137847 },
    { lat: 48.9806198, lng: 20.9140451 },
    { lat: 48.9807319, lng: 20.9147699 },
    { lat: 48.9810256, lng: 20.915134 },
    { lat: 48.9812909, lng: 20.9153585 },
    { lat: 48.981656, lng: 20.9155036 },
    { lat: 48.9821707, lng: 20.9155116 },
    { lat: 48.9827394, lng: 20.9157239 },
    { lat: 48.9827648, lng: 20.9165422 },
    { lat: 48.9829459, lng: 20.9174452 },
    { lat: 48.9843298, lng: 20.922351 },
    { lat: 48.9832647, lng: 20.9247525 },
    { lat: 48.9828497, lng: 20.9255268 },
    { lat: 48.9823304, lng: 20.9260927 },
    { lat: 48.981872, lng: 20.9268191 },
    { lat: 48.9804144, lng: 20.9286589 },
    { lat: 48.9797237, lng: 20.9293728 },
    { lat: 48.978841, lng: 20.9312112 },
    { lat: 48.9778917, lng: 20.9340898 },
    { lat: 48.9775776, lng: 20.9349225 },
    { lat: 48.9768501, lng: 20.9373345 },
    { lat: 48.9760572, lng: 20.9392023 },
    { lat: 48.9784112, lng: 20.9427468 },
    { lat: 48.9799537, lng: 20.9453861 },
    { lat: 48.9838005, lng: 20.9515818 },
    { lat: 48.9841921, lng: 20.9510399 },
    { lat: 48.9846137, lng: 20.9505914 },
    { lat: 48.9849112, lng: 20.9505316 },
    { lat: 48.9863015, lng: 20.9507782 },
    { lat: 48.9867171, lng: 20.9538252 },
    { lat: 48.9860761, lng: 20.9552223 },
    { lat: 48.9867198, lng: 20.9558946 },
    { lat: 48.9862112, lng: 20.956139 },
    { lat: 48.9858761, lng: 20.9565181 },
    { lat: 48.9852301, lng: 20.9590508 },
    { lat: 48.985804, lng: 20.9598976 },
    { lat: 48.986681, lng: 20.9609497 },
    { lat: 48.9868621, lng: 20.9612891 },
    { lat: 48.987646, lng: 20.9622854 },
    { lat: 48.9879885, lng: 20.9628339 },
    { lat: 48.9880179, lng: 20.9633136 },
    { lat: 48.9879029, lng: 20.9638833 },
    { lat: 48.9874694, lng: 20.9654013 },
    { lat: 48.9868102, lng: 20.967105 },
    { lat: 48.9877149, lng: 20.9670962 },
    { lat: 48.9877578, lng: 20.9717774 },
    { lat: 48.9890509, lng: 20.9712992 },
    { lat: 48.9898712, lng: 20.9721093 },
    { lat: 48.9928337, lng: 20.969942 },
    { lat: 48.9931365, lng: 20.9697779 },
    { lat: 48.9936893, lng: 20.9692707 },
    { lat: 48.9940518, lng: 20.9691092 },
    { lat: 48.9947778, lng: 20.9690026 },
    { lat: 48.9954129, lng: 20.969422 },
    { lat: 48.9951669, lng: 20.9699122 },
    { lat: 48.9955579, lng: 20.97077 },
    { lat: 48.995754, lng: 20.971084 },
    { lat: 48.9961535, lng: 20.9721996 },
    { lat: 48.9976649, lng: 20.9714214 },
    { lat: 48.9984436, lng: 20.9699538 },
    { lat: 48.9982072, lng: 20.969783 },
    { lat: 48.9982247, lng: 20.9684121 },
    { lat: 48.9980607, lng: 20.9679831 },
    { lat: 48.9993377, lng: 20.9648845 },
    { lat: 49.0005474, lng: 20.963046 },
    { lat: 49.0020014, lng: 20.9611179 },
    { lat: 49.0054708, lng: 20.9570086 },
    { lat: 49.006349, lng: 20.9558308 },
    { lat: 49.0081354, lng: 20.9536784 },
    { lat: 49.0096421, lng: 20.9515726 },
    { lat: 49.0104933, lng: 20.9508373 },
    { lat: 49.010806, lng: 20.9512742 },
    { lat: 49.0112819, lng: 20.950893 },
    { lat: 49.012224, lng: 20.9497132 },
    { lat: 49.0122286, lng: 20.9490463 },
    { lat: 49.0130708, lng: 20.947738 },
    { lat: 49.0138839, lng: 20.947212 },
    { lat: 49.0149597, lng: 20.9460913 },
    { lat: 49.0147509, lng: 20.9454656 },
    { lat: 49.0143094, lng: 20.9445495 },
    { lat: 49.0148366, lng: 20.9441638 },
    { lat: 49.0162523, lng: 20.9427389 },
    { lat: 49.0171606, lng: 20.9414557 },
    { lat: 49.0181582, lng: 20.9397194 },
    { lat: 49.0192095, lng: 20.9369935 },
    { lat: 49.0200721, lng: 20.9343187 },
    { lat: 49.0193372, lng: 20.9336512 },
    { lat: 49.0202243, lng: 20.9312724 },
    { lat: 49.0217939, lng: 20.9250471 },
    { lat: 49.0222027, lng: 20.9245162 },
    { lat: 49.0224061, lng: 20.9248049 },
    { lat: 49.0231584, lng: 20.9256266 },
    { lat: 49.0232114, lng: 20.9265309 },
    { lat: 49.0237882, lng: 20.9259779 },
    { lat: 49.0243754, lng: 20.924628 },
    { lat: 49.0255204, lng: 20.9205173 },
    { lat: 49.0270001, lng: 20.9171192 },
    { lat: 49.0280284, lng: 20.9126237 },
    { lat: 49.0292572, lng: 20.9100467 },
    { lat: 49.0294009, lng: 20.9084588 },
    { lat: 49.0293356, lng: 20.9074581 },
    { lat: 49.0282664, lng: 20.9031567 },
    { lat: 49.0280272, lng: 20.9009039 },
    { lat: 49.0278828, lng: 20.8999945 },
    { lat: 49.0274825, lng: 20.8983049 },
    { lat: 49.0272802, lng: 20.8963175 },
    { lat: 49.0271754, lng: 20.8962174 },
    { lat: 49.0271072, lng: 20.8954968 },
    { lat: 49.0268819, lng: 20.8948251 },
    { lat: 49.026325, lng: 20.8940287 },
    { lat: 49.0257276, lng: 20.8930396 },
    { lat: 49.0257435, lng: 20.8920122 },
    { lat: 49.0258695, lng: 20.8916168 },
    { lat: 49.0258872, lng: 20.8912058 },
    { lat: 49.026073, lng: 20.8903111 },
    { lat: 49.0260726, lng: 20.8899306 },
    { lat: 49.0262575, lng: 20.8891077 },
    { lat: 49.0262421, lng: 20.8889895 },
    { lat: 49.0265445, lng: 20.8878124 },
    { lat: 49.0266267, lng: 20.8870488 },
    { lat: 49.0262208, lng: 20.8863331 },
    { lat: 49.02468, lng: 20.8847421 },
    { lat: 49.0241968, lng: 20.8840365 },
    { lat: 49.023831, lng: 20.883737 },
  ],
  Seniakovce: [
    { lat: 48.827222, lng: 21.306324 },
    { lat: 48.827223, lng: 21.30627 },
    { lat: 48.827235, lng: 21.305104 },
    { lat: 48.827318, lng: 21.304989 },
    { lat: 48.827431, lng: 21.304763 },
    { lat: 48.827504, lng: 21.304673 },
    { lat: 48.827575, lng: 21.304571 },
    { lat: 48.827776, lng: 21.304121 },
    { lat: 48.827963, lng: 21.30344 },
    { lat: 48.827947, lng: 21.303204 },
    { lat: 48.827931, lng: 21.302973 },
    { lat: 48.827905, lng: 21.302584 },
    { lat: 48.82788, lng: 21.302237 },
    { lat: 48.827775, lng: 21.30152 },
    { lat: 48.827283, lng: 21.3014871 },
    { lat: 48.827032, lng: 21.3009746 },
    { lat: 48.8268673, lng: 21.3004746 },
    { lat: 48.8258565, lng: 21.3013538 },
    { lat: 48.825718, lng: 21.3007238 },
    { lat: 48.8255355, lng: 21.300423 },
    { lat: 48.8248741, lng: 21.3000985 },
    { lat: 48.8246173, lng: 21.2997568 },
    { lat: 48.824659, lng: 21.2986897 },
    { lat: 48.8242269, lng: 21.2989091 },
    { lat: 48.8241105, lng: 21.298673 },
    { lat: 48.8239183, lng: 21.2977972 },
    { lat: 48.8235541, lng: 21.2965566 },
    { lat: 48.8229517, lng: 21.2961341 },
    { lat: 48.8225171, lng: 21.2961645 },
    { lat: 48.822328, lng: 21.2960874 },
    { lat: 48.822119, lng: 21.2957706 },
    { lat: 48.8221335, lng: 21.2956589 },
    { lat: 48.8219844, lng: 21.2952686 },
    { lat: 48.8220028, lng: 21.2950981 },
    { lat: 48.8222295, lng: 21.2946687 },
    { lat: 48.8224416, lng: 21.2943935 },
    { lat: 48.8224777, lng: 21.2936603 },
    { lat: 48.8226444, lng: 21.2934054 },
    { lat: 48.8227354, lng: 21.2925855 },
    { lat: 48.8226724, lng: 21.2924071 },
    { lat: 48.8225651, lng: 21.2923528 },
    { lat: 48.8225352, lng: 21.2922596 },
    { lat: 48.8226119, lng: 21.2920774 },
    { lat: 48.8226077, lng: 21.2918695 },
    { lat: 48.8224345, lng: 21.291767 },
    { lat: 48.8223753, lng: 21.2916262 },
    { lat: 48.8224757, lng: 21.2912688 },
    { lat: 48.8223361, lng: 21.2909682 },
    { lat: 48.8221697, lng: 21.2908328 },
    { lat: 48.8221148, lng: 21.2904699 },
    { lat: 48.8221579, lng: 21.2900751 },
    { lat: 48.8221231, lng: 21.2891908 },
    { lat: 48.8219769, lng: 21.2881254 },
    { lat: 48.8220348, lng: 21.2876292 },
    { lat: 48.8218637, lng: 21.2870388 },
    { lat: 48.82191, lng: 21.286787 },
    { lat: 48.8218332, lng: 21.2864824 },
    { lat: 48.8219121, lng: 21.2863086 },
    { lat: 48.8219018, lng: 21.2861172 },
    { lat: 48.821968, lng: 21.2858848 },
    { lat: 48.8219734, lng: 21.285728 },
    { lat: 48.8218892, lng: 21.2856285 },
    { lat: 48.8218677, lng: 21.2854415 },
    { lat: 48.8219407, lng: 21.2851771 },
    { lat: 48.8218614, lng: 21.2851276 },
    { lat: 48.8218544, lng: 21.2846778 },
    { lat: 48.8216955, lng: 21.2843754 },
    { lat: 48.8217298, lng: 21.2840937 },
    { lat: 48.8215909, lng: 21.2837174 },
    { lat: 48.8215847, lng: 21.283144 },
    { lat: 48.8216326, lng: 21.2829839 },
    { lat: 48.8217918, lng: 21.2827759 },
    { lat: 48.8216344, lng: 21.2824124 },
    { lat: 48.8209822, lng: 21.2820271 },
    { lat: 48.8197453, lng: 21.281579 },
    { lat: 48.8196495, lng: 21.2818689 },
    { lat: 48.8188818, lng: 21.2816057 },
    { lat: 48.8183583, lng: 21.2815593 },
    { lat: 48.8182249, lng: 21.2813601 },
    { lat: 48.8178552, lng: 21.2810479 },
    { lat: 48.8175973, lng: 21.2801576 },
    { lat: 48.8175991, lng: 21.2795915 },
    { lat: 48.8179829, lng: 21.2788185 },
    { lat: 48.8182552, lng: 21.2778313 },
    { lat: 48.818011, lng: 21.2777899 },
    { lat: 48.818021, lng: 21.2769086 },
    { lat: 48.8177477, lng: 21.2761394 },
    { lat: 48.8174712, lng: 21.2761874 },
    { lat: 48.8173797, lng: 21.2752968 },
    { lat: 48.817356, lng: 21.275301 },
    { lat: 48.81739, lng: 21.275632 },
    { lat: 48.81681, lng: 21.275592 },
    { lat: 48.81669, lng: 21.274226 },
    { lat: 48.81661, lng: 21.274061 },
    { lat: 48.816011, lng: 21.273865 },
    { lat: 48.81559, lng: 21.273555 },
    { lat: 48.815225, lng: 21.273431 },
    { lat: 48.815171, lng: 21.273412 },
    { lat: 48.815002, lng: 21.273388 },
    { lat: 48.814939, lng: 21.273441 },
    { lat: 48.814768, lng: 21.273582 },
    { lat: 48.814437, lng: 21.273739 },
    { lat: 48.813627, lng: 21.273864 },
    { lat: 48.813054, lng: 21.273957 },
    { lat: 48.812763, lng: 21.274099 },
    { lat: 48.812673, lng: 21.27417 },
    { lat: 48.812631, lng: 21.274209 },
    { lat: 48.812347, lng: 21.274451 },
    { lat: 48.811822, lng: 21.275061 },
    { lat: 48.811894, lng: 21.276005 },
    { lat: 48.81204, lng: 21.276536 },
    { lat: 48.812236, lng: 21.27702 },
    { lat: 48.8121, lng: 21.277761 },
    { lat: 48.812046, lng: 21.277909 },
    { lat: 48.811527, lng: 21.27843 },
    { lat: 48.81128, lng: 21.278762 },
    { lat: 48.811108, lng: 21.279013 },
    { lat: 48.810912, lng: 21.279384 },
    { lat: 48.810789, lng: 21.279754 },
    { lat: 48.810739, lng: 21.280298 },
    { lat: 48.810744, lng: 21.280341 },
    { lat: 48.810875, lng: 21.281254 },
    { lat: 48.810921, lng: 21.281639 },
    { lat: 48.8114, lng: 21.285314 },
    { lat: 48.811492, lng: 21.285492 },
    { lat: 48.811752, lng: 21.286291 },
    { lat: 48.811825, lng: 21.286489 },
    { lat: 48.811973, lng: 21.286885 },
    { lat: 48.812171, lng: 21.287348 },
    { lat: 48.812365, lng: 21.28773 },
    { lat: 48.812799, lng: 21.288393 },
    { lat: 48.813047, lng: 21.288756 },
    { lat: 48.813164, lng: 21.288944 },
    { lat: 48.813311, lng: 21.289204 },
    { lat: 48.813356, lng: 21.28939 },
    { lat: 48.813398, lng: 21.289696 },
    { lat: 48.813447, lng: 21.29039 },
    { lat: 48.813522, lng: 21.290893 },
    { lat: 48.813591, lng: 21.291092 },
    { lat: 48.813729, lng: 21.291375 },
    { lat: 48.814003, lng: 21.291809 },
    { lat: 48.814102, lng: 21.291954 },
    { lat: 48.814178, lng: 21.292089 },
    { lat: 48.814314, lng: 21.292508 },
    { lat: 48.814576, lng: 21.293713 },
    { lat: 48.81477, lng: 21.294689 },
    { lat: 48.815111, lng: 21.296635 },
    { lat: 48.815534, lng: 21.298977 },
    { lat: 48.815625, lng: 21.299646 },
    { lat: 48.81563, lng: 21.299673 },
    { lat: 48.815654, lng: 21.299811 },
    { lat: 48.815661, lng: 21.299842 },
    { lat: 48.815685, lng: 21.300225 },
    { lat: 48.815769, lng: 21.301056 },
    { lat: 48.815812, lng: 21.301489 },
    { lat: 48.815869, lng: 21.302018 },
    { lat: 48.816116, lng: 21.303395 },
    { lat: 48.816157, lng: 21.303388 },
    { lat: 48.816489, lng: 21.305254 },
    { lat: 48.816519, lng: 21.3055 },
    { lat: 48.81652, lng: 21.305522 },
    { lat: 48.816515, lng: 21.305766 },
    { lat: 48.816345, lng: 21.306932 },
    { lat: 48.816154, lng: 21.307616 },
    { lat: 48.815791, lng: 21.308747 },
    { lat: 48.815672, lng: 21.309264 },
    { lat: 48.815537, lng: 21.309707 },
    { lat: 48.815512, lng: 21.309817 },
    { lat: 48.815511, lng: 21.309847 },
    { lat: 48.815505, lng: 21.309884 },
    { lat: 48.815436, lng: 21.310366 },
    { lat: 48.815434, lng: 21.310382 },
    { lat: 48.815427, lng: 21.310427 },
    { lat: 48.815354, lng: 21.310993 },
    { lat: 48.815322, lng: 21.311334 },
    { lat: 48.815314, lng: 21.311416 },
    { lat: 48.815307, lng: 21.311526 },
    { lat: 48.815297, lng: 21.311679 },
    { lat: 48.815275, lng: 21.311901 },
    { lat: 48.815135, lng: 21.313223 },
    { lat: 48.815103, lng: 21.313419 },
    { lat: 48.815092, lng: 21.31348 },
    { lat: 48.815064, lng: 21.313646 },
    { lat: 48.815173, lng: 21.313584 },
    { lat: 48.815212, lng: 21.313564 },
    { lat: 48.815382, lng: 21.313474 },
    { lat: 48.815415, lng: 21.313457 },
    { lat: 48.815505, lng: 21.313409 },
    { lat: 48.815572, lng: 21.313374 },
    { lat: 48.815676, lng: 21.313319 },
    { lat: 48.815692, lng: 21.31331 },
    { lat: 48.815707, lng: 21.313303 },
    { lat: 48.815768, lng: 21.313267 },
    { lat: 48.815845, lng: 21.313224 },
    { lat: 48.816003, lng: 21.313133 },
    { lat: 48.816663, lng: 21.312755 },
    { lat: 48.816768, lng: 21.312694 },
    { lat: 48.816816, lng: 21.312666 },
    { lat: 48.817404, lng: 21.312329 },
    { lat: 48.818027, lng: 21.31197 },
    { lat: 48.818395, lng: 21.311762 },
    { lat: 48.81894, lng: 21.311453 },
    { lat: 48.818855, lng: 21.310936 },
    { lat: 48.818803, lng: 21.310769 },
    { lat: 48.81879, lng: 21.310716 },
    { lat: 48.818788, lng: 21.310703 },
    { lat: 48.818759, lng: 21.310582 },
    { lat: 48.818748, lng: 21.310548 },
    { lat: 48.818737, lng: 21.310511 },
    { lat: 48.818713, lng: 21.310437 },
    { lat: 48.818708, lng: 21.310423 },
    { lat: 48.818671, lng: 21.310327 },
    { lat: 48.818636, lng: 21.310241 },
    { lat: 48.818605, lng: 21.310158 },
    { lat: 48.818569, lng: 21.310069 },
    { lat: 48.818551, lng: 21.310019 },
    { lat: 48.818539, lng: 21.309989 },
    { lat: 48.81849, lng: 21.309859 },
    { lat: 48.818364, lng: 21.309556 },
    { lat: 48.818272, lng: 21.309339 },
    { lat: 48.818228, lng: 21.309235 },
    { lat: 48.81819, lng: 21.308486 },
    { lat: 48.818142, lng: 21.307434 },
    { lat: 48.818331, lng: 21.306703 },
    { lat: 48.818656, lng: 21.306804 },
    { lat: 48.818946, lng: 21.30672 },
    { lat: 48.819369, lng: 21.306712 },
    { lat: 48.81961, lng: 21.306922 },
    { lat: 48.819741, lng: 21.306996 },
    { lat: 48.81978, lng: 21.307078 },
    { lat: 48.819992, lng: 21.307345 },
    { lat: 48.820088, lng: 21.307303 },
    { lat: 48.820179, lng: 21.307457 },
    { lat: 48.820254, lng: 21.307726 },
    { lat: 48.820372, lng: 21.30814 },
    { lat: 48.820401, lng: 21.308475 },
    { lat: 48.820268, lng: 21.308793 },
    { lat: 48.820202, lng: 21.309119 },
    { lat: 48.820168, lng: 21.309605 },
    { lat: 48.820094, lng: 21.30989 },
    { lat: 48.8201, lng: 21.309897 },
    { lat: 48.820194, lng: 21.310014 },
    { lat: 48.820214, lng: 21.310038 },
    { lat: 48.820338, lng: 21.310199 },
    { lat: 48.820513, lng: 21.310286 },
    { lat: 48.820627, lng: 21.310272 },
    { lat: 48.820815, lng: 21.31017 },
    { lat: 48.820937, lng: 21.310049 },
    { lat: 48.820944, lng: 21.310039 },
    { lat: 48.821001, lng: 21.309948 },
    { lat: 48.82105, lng: 21.309869 },
    { lat: 48.821108, lng: 21.309756 },
    { lat: 48.821194, lng: 21.309791 },
    { lat: 48.821286, lng: 21.309828 },
    { lat: 48.821358, lng: 21.309915 },
    { lat: 48.821361, lng: 21.30992 },
    { lat: 48.821782, lng: 21.310482 },
    { lat: 48.821819, lng: 21.310531 },
    { lat: 48.821956, lng: 21.310706 },
    { lat: 48.822294, lng: 21.31089 },
    { lat: 48.822516, lng: 21.310903 },
    { lat: 48.822882, lng: 21.31068 },
    { lat: 48.822949, lng: 21.310351 },
    { lat: 48.822946, lng: 21.310289 },
    { lat: 48.822932, lng: 21.309938 },
    { lat: 48.82289, lng: 21.309755 },
    { lat: 48.822873, lng: 21.30968 },
    { lat: 48.822834, lng: 21.309509 },
    { lat: 48.822606, lng: 21.308971 },
    { lat: 48.822308, lng: 21.308403 },
    { lat: 48.821934, lng: 21.307741 },
    { lat: 48.821864, lng: 21.307629 },
    { lat: 48.822127, lng: 21.307301 },
    { lat: 48.823604, lng: 21.305869 },
    { lat: 48.824176, lng: 21.306192 },
    { lat: 48.824503, lng: 21.306582 },
    { lat: 48.82466, lng: 21.306853 },
    { lat: 48.824756, lng: 21.307102 },
    { lat: 48.825065, lng: 21.307712 },
    { lat: 48.825459, lng: 21.308071 },
    { lat: 48.825594, lng: 21.308129 },
    { lat: 48.825922, lng: 21.308128 },
    { lat: 48.826186, lng: 21.307976 },
    { lat: 48.826339, lng: 21.307833 },
    { lat: 48.826537, lng: 21.307728 },
    { lat: 48.826698, lng: 21.307549 },
    { lat: 48.826833, lng: 21.307207 },
    { lat: 48.827021, lng: 21.306577 },
    { lat: 48.827222, lng: 21.306324 },
  ],
  Radatice: [
    { lat: 48.9643048, lng: 21.171083 },
    { lat: 48.963906, lng: 21.171069 },
    { lat: 48.963167, lng: 21.1710396 },
    { lat: 48.9626638, lng: 21.1711094 },
    { lat: 48.9624472, lng: 21.1710582 },
    { lat: 48.9620215, lng: 21.1711564 },
    { lat: 48.9602415, lng: 21.1713032 },
    { lat: 48.9597353, lng: 21.1714588 },
    { lat: 48.9594104, lng: 21.1717152 },
    { lat: 48.9581926, lng: 21.1718931 },
    { lat: 48.957642, lng: 21.1718482 },
    { lat: 48.9567791, lng: 21.1721408 },
    { lat: 48.9561413, lng: 21.1730432 },
    { lat: 48.9554176, lng: 21.1725046 },
    { lat: 48.9548561, lng: 21.1721815 },
    { lat: 48.9545189, lng: 21.1717691 },
    { lat: 48.9534856, lng: 21.1712622 },
    { lat: 48.953169, lng: 21.170998 },
    { lat: 48.9526877, lng: 21.1707562 },
    { lat: 48.9518753, lng: 21.1700468 },
    { lat: 48.9500171, lng: 21.1697198 },
    { lat: 48.9495988, lng: 21.1698401 },
    { lat: 48.9487348, lng: 21.1690267 },
    { lat: 48.9485164, lng: 21.1689212 },
    { lat: 48.9483711, lng: 21.1687681 },
    { lat: 48.9481024, lng: 21.1686702 },
    { lat: 48.9478443, lng: 21.1686939 },
    { lat: 48.9476518, lng: 21.1688354 },
    { lat: 48.9470353, lng: 21.1698425 },
    { lat: 48.9468045, lng: 21.1701239 },
    { lat: 48.9467849, lng: 21.1700766 },
    { lat: 48.9465108, lng: 21.170486 },
    { lat: 48.9462046, lng: 21.1700192 },
    { lat: 48.9456818, lng: 21.1711423 },
    { lat: 48.9449588, lng: 21.1720873 },
    { lat: 48.9444733, lng: 21.1712866 },
    { lat: 48.9442018, lng: 21.1716357 },
    { lat: 48.9438066, lng: 21.1718391 },
    { lat: 48.943553, lng: 21.1717927 },
    { lat: 48.9433158, lng: 21.1715536 },
    { lat: 48.9423366, lng: 21.1711914 },
    { lat: 48.9420236, lng: 21.1715063 },
    { lat: 48.9418986, lng: 21.171723 },
    { lat: 48.9417154, lng: 21.172763 },
    { lat: 48.9419077, lng: 21.1726159 },
    { lat: 48.9432662, lng: 21.1737982 },
    { lat: 48.9431371, lng: 21.1742061 },
    { lat: 48.9428603, lng: 21.1757455 },
    { lat: 48.9429896, lng: 21.175779 },
    { lat: 48.942971, lng: 21.1758702 },
    { lat: 48.9432528, lng: 21.1761688 },
    { lat: 48.9436751, lng: 21.1761357 },
    { lat: 48.9440385, lng: 21.1763173 },
    { lat: 48.9426211, lng: 21.180663 },
    { lat: 48.943139, lng: 21.1812449 },
    { lat: 48.9431017, lng: 21.1813467 },
    { lat: 48.9433669, lng: 21.1814808 },
    { lat: 48.9433573, lng: 21.1815649 },
    { lat: 48.9423473, lng: 21.1815499 },
    { lat: 48.9418397, lng: 21.1816221 },
    { lat: 48.9416692, lng: 21.1815986 },
    { lat: 48.9415668, lng: 21.1814908 },
    { lat: 48.9416903, lng: 21.181881 },
    { lat: 48.9423684, lng: 21.1818301 },
    { lat: 48.9425505, lng: 21.181929 },
    { lat: 48.9426013, lng: 21.1818952 },
    { lat: 48.9425587, lng: 21.1825144 },
    { lat: 48.9419108, lng: 21.1828119 },
    { lat: 48.9415807, lng: 21.1830456 },
    { lat: 48.9415247, lng: 21.1834849 },
    { lat: 48.9413451, lng: 21.1826413 },
    { lat: 48.9412404, lng: 21.1823209 },
    { lat: 48.9411644, lng: 21.1821195 },
    { lat: 48.9401672, lng: 21.1826867 },
    { lat: 48.9389723, lng: 21.1836613 },
    { lat: 48.9390638, lng: 21.1830973 },
    { lat: 48.9388259, lng: 21.182763 },
    { lat: 48.9381088, lng: 21.1828994 },
    { lat: 48.937481, lng: 21.1831603 },
    { lat: 48.9371066, lng: 21.1832364 },
    { lat: 48.9368555, lng: 21.1834496 },
    { lat: 48.9365624, lng: 21.1839 },
    { lat: 48.9360579, lng: 21.1841377 },
    { lat: 48.9356579, lng: 21.1841222 },
    { lat: 48.9349638, lng: 21.1842758 },
    { lat: 48.9347317, lng: 21.1845894 },
    { lat: 48.9346277, lng: 21.1846123 },
    { lat: 48.9342087, lng: 21.184494 },
    { lat: 48.9332614, lng: 21.1846448 },
    { lat: 48.9330137, lng: 21.1841767 },
    { lat: 48.9326699, lng: 21.1827956 },
    { lat: 48.9319313, lng: 21.1804141 },
    { lat: 48.9310105, lng: 21.1816243 },
    { lat: 48.9307288, lng: 21.1820803 },
    { lat: 48.9303193, lng: 21.1825487 },
    { lat: 48.9298398, lng: 21.1834098 },
    { lat: 48.9292934, lng: 21.1841443 },
    { lat: 48.9293377, lng: 21.1843029 },
    { lat: 48.9289624, lng: 21.1846747 },
    { lat: 48.9287772, lng: 21.1843337 },
    { lat: 48.9284005, lng: 21.1834075 },
    { lat: 48.9281759, lng: 21.1833891 },
    { lat: 48.9281131, lng: 21.1832512 },
    { lat: 48.9284292, lng: 21.1819263 },
    { lat: 48.9286144, lng: 21.1813331 },
    { lat: 48.9288885, lng: 21.1808555 },
    { lat: 48.9290538, lng: 21.1802795 },
    { lat: 48.9292363, lng: 21.1798511 },
    { lat: 48.9293053, lng: 21.1797939 },
    { lat: 48.9295514, lng: 21.1797832 },
    { lat: 48.9296024, lng: 21.1795376 },
    { lat: 48.9298419, lng: 21.1792121 },
    { lat: 48.9297653, lng: 21.1787406 },
    { lat: 48.9300646, lng: 21.1787067 },
    { lat: 48.9301312, lng: 21.1786024 },
    { lat: 48.9301661, lng: 21.1783945 },
    { lat: 48.9300645, lng: 21.1780289 },
    { lat: 48.9295984, lng: 21.1773042 },
    { lat: 48.9295409, lng: 21.1771176 },
    { lat: 48.9291541, lng: 21.1744109 },
    { lat: 48.9293862, lng: 21.1731318 },
    { lat: 48.9294035, lng: 21.1720769 },
    { lat: 48.9294594, lng: 21.1714572 },
    { lat: 48.929596, lng: 21.1710397 },
    { lat: 48.929585, lng: 21.1705101 },
    { lat: 48.9302729, lng: 21.1688413 },
    { lat: 48.9303494, lng: 21.1683429 },
    { lat: 48.9306957, lng: 21.1679749 },
    { lat: 48.9308394, lng: 21.1674315 },
    { lat: 48.9312561, lng: 21.1665469 },
    { lat: 48.9313308, lng: 21.1659574 },
    { lat: 48.9315543, lng: 21.1655134 },
    { lat: 48.9318594, lng: 21.1651598 },
    { lat: 48.9320762, lng: 21.1646841 },
    { lat: 48.9322933, lng: 21.1644647 },
    { lat: 48.9324316, lng: 21.1639664 },
    { lat: 48.9328193, lng: 21.163765 },
    { lat: 48.9329161, lng: 21.1634097 },
    { lat: 48.9335517, lng: 21.1619131 },
    { lat: 48.93377, lng: 21.1612673 },
    { lat: 48.9338895, lng: 21.160736 },
    { lat: 48.9342351, lng: 21.1598124 },
    { lat: 48.9342973, lng: 21.1592513 },
    { lat: 48.9348684, lng: 21.1585275 },
    { lat: 48.9350788, lng: 21.1581682 },
    { lat: 48.9350431, lng: 21.1576829 },
    { lat: 48.9359635, lng: 21.1541473 },
    { lat: 48.9358837, lng: 21.1538016 },
    { lat: 48.9359106, lng: 21.1529069 },
    { lat: 48.9360203, lng: 21.1521046 },
    { lat: 48.9360534, lng: 21.1515194 },
    { lat: 48.9359178, lng: 21.1514288 },
    { lat: 48.9358448, lng: 21.1510751 },
    { lat: 48.9355155, lng: 21.150521 },
    { lat: 48.9354722, lng: 21.150183 },
    { lat: 48.9348364, lng: 21.1511132 },
    { lat: 48.9333901, lng: 21.1517456 },
    { lat: 48.9328235, lng: 21.1523285 },
    { lat: 48.9324547, lng: 21.1524853 },
    { lat: 48.9322549, lng: 21.1527526 },
    { lat: 48.9321268, lng: 21.1527598 },
    { lat: 48.9319753, lng: 21.152375 },
    { lat: 48.9317412, lng: 21.1522356 },
    { lat: 48.9315619, lng: 21.1526517 },
    { lat: 48.9310722, lng: 21.1530353 },
    { lat: 48.930821, lng: 21.153564 },
    { lat: 48.9306419, lng: 21.1537378 },
    { lat: 48.9303184, lng: 21.1539335 },
    { lat: 48.9297905, lng: 21.1540035 },
    { lat: 48.9294772, lng: 21.1541649 },
    { lat: 48.9289731, lng: 21.1538094 },
    { lat: 48.9288476, lng: 21.1533303 },
    { lat: 48.927914, lng: 21.1516408 },
    { lat: 48.9276229, lng: 21.1515421 },
    { lat: 48.9271042, lng: 21.1507643 },
    { lat: 48.9259149, lng: 21.1517014 },
    { lat: 48.925581, lng: 21.151851 },
    { lat: 48.9253352, lng: 21.1515118 },
    { lat: 48.9251394, lng: 21.1517272 },
    { lat: 48.9248771, lng: 21.1517242 },
    { lat: 48.9247533, lng: 21.1517994 },
    { lat: 48.9246242, lng: 21.1519888 },
    { lat: 48.9243414, lng: 21.1520116 },
    { lat: 48.9240561, lng: 21.1522049 },
    { lat: 48.9236639, lng: 21.1530405 },
    { lat: 48.9237694, lng: 21.1533184 },
    { lat: 48.9236889, lng: 21.1537243 },
    { lat: 48.9238251, lng: 21.1540501 },
    { lat: 48.9238903, lng: 21.1545063 },
    { lat: 48.9242199, lng: 21.1550709 },
    { lat: 48.9244932, lng: 21.1558097 },
    { lat: 48.9244157, lng: 21.1559982 },
    { lat: 48.9244171, lng: 21.1566079 },
    { lat: 48.924512, lng: 21.1587518 },
    { lat: 48.9244577, lng: 21.1598834 },
    { lat: 48.9245902, lng: 21.161584 },
    { lat: 48.924574, lng: 21.1624967 },
    { lat: 48.924833, lng: 21.162932 },
    { lat: 48.9248435, lng: 21.1634734 },
    { lat: 48.9247129, lng: 21.1637592 },
    { lat: 48.9239186, lng: 21.1649764 },
    { lat: 48.9232481, lng: 21.1658021 },
    { lat: 48.9224191, lng: 21.1671026 },
    { lat: 48.9223148, lng: 21.1673979 },
    { lat: 48.9223667, lng: 21.1675108 },
    { lat: 48.9222422, lng: 21.1679897 },
    { lat: 48.9221878, lng: 21.1691502 },
    { lat: 48.9222329, lng: 21.1698709 },
    { lat: 48.9219288, lng: 21.1710789 },
    { lat: 48.9213473, lng: 21.1719427 },
    { lat: 48.9208306, lng: 21.1733721 },
    { lat: 48.9207111, lng: 21.1735751 },
    { lat: 48.9204946, lng: 21.1741755 },
    { lat: 48.9202478, lng: 21.1745913 },
    { lat: 48.920061, lng: 21.1748725 },
    { lat: 48.9194193, lng: 21.1755102 },
    { lat: 48.9187227, lng: 21.1764402 },
    { lat: 48.9174753, lng: 21.177897 },
    { lat: 48.9172427, lng: 21.1783658 },
    { lat: 48.91742, lng: 21.1784925 },
    { lat: 48.9173924, lng: 21.1791579 },
    { lat: 48.9172697, lng: 21.1794802 },
    { lat: 48.9172864, lng: 21.1797946 },
    { lat: 48.9168543, lng: 21.1807658 },
    { lat: 48.9165717, lng: 21.1804331 },
    { lat: 48.9164574, lng: 21.180952 },
    { lat: 48.9163621, lng: 21.1820033 },
    { lat: 48.9159082, lng: 21.1823124 },
    { lat: 48.9154738, lng: 21.1827771 },
    { lat: 48.9151556, lng: 21.1833593 },
    { lat: 48.9124316, lng: 21.1868511 },
    { lat: 48.9120652, lng: 21.1875144 },
    { lat: 48.9118448, lng: 21.1879553 },
    { lat: 48.9117212, lng: 21.1883503 },
    { lat: 48.9115449, lng: 21.189476 },
    { lat: 48.9115586, lng: 21.1898754 },
    { lat: 48.9091322, lng: 21.1907674 },
    { lat: 48.9086878, lng: 21.191095 },
    { lat: 48.9087622, lng: 21.1913154 },
    { lat: 48.9080553, lng: 21.1919602 },
    { lat: 48.9082267, lng: 21.1922573 },
    { lat: 48.9083151, lng: 21.1924223 },
    { lat: 48.9083702, lng: 21.1928494 },
    { lat: 48.9085363, lng: 21.1931608 },
    { lat: 48.9086742, lng: 21.1936095 },
    { lat: 48.9088502, lng: 21.1946665 },
    { lat: 48.9093205, lng: 21.1960615 },
    { lat: 48.9094633, lng: 21.1969928 },
    { lat: 48.9094591, lng: 21.1979752 },
    { lat: 48.9093959, lng: 21.1989381 },
    { lat: 48.9096015, lng: 21.1996099 },
    { lat: 48.9096203, lng: 21.1999616 },
    { lat: 48.9097859, lng: 21.2001837 },
    { lat: 48.9097941, lng: 21.2007 },
    { lat: 48.9099614, lng: 21.2008619 },
    { lat: 48.9099044, lng: 21.2015289 },
    { lat: 48.9100057, lng: 21.2017828 },
    { lat: 48.9100905, lng: 21.2018611 },
    { lat: 48.910086, lng: 21.20208 },
    { lat: 48.9097098, lng: 21.202032 },
    { lat: 48.9096071, lng: 21.2021635 },
    { lat: 48.9095405, lng: 21.2023046 },
    { lat: 48.9094272, lng: 21.2032905 },
    { lat: 48.9093414, lng: 21.2033879 },
    { lat: 48.9090899, lng: 21.2034453 },
    { lat: 48.9087528, lng: 21.2032463 },
    { lat: 48.9086325, lng: 21.2032832 },
    { lat: 48.9084549, lng: 21.2035772 },
    { lat: 48.9081773, lng: 21.2036959 },
    { lat: 48.9080322, lng: 21.2038458 },
    { lat: 48.9078932, lng: 21.2044361 },
    { lat: 48.9079385, lng: 21.2046657 },
    { lat: 48.9078342, lng: 21.2049962 },
    { lat: 48.9076307, lng: 21.2051822 },
    { lat: 48.9075029, lng: 21.2051613 },
    { lat: 48.9069887, lng: 21.2044467 },
    { lat: 48.9063914, lng: 21.2038443 },
    { lat: 48.9061178, lng: 21.2036915 },
    { lat: 48.9058361, lng: 21.2033558 },
    { lat: 48.9055313, lng: 21.2031112 },
    { lat: 48.9051902, lng: 21.2030381 },
    { lat: 48.9049516, lng: 21.2028231 },
    { lat: 48.9046504, lng: 21.2026544 },
    { lat: 48.904144, lng: 21.2027262 },
    { lat: 48.9040293, lng: 21.2028379 },
    { lat: 48.9037931, lng: 21.2036322 },
    { lat: 48.9034945, lng: 21.2041594 },
    { lat: 48.9032468, lng: 21.2044802 },
    { lat: 48.903126, lng: 21.2048995 },
    { lat: 48.903049, lng: 21.2062179 },
    { lat: 48.9030873, lng: 21.2069777 },
    { lat: 48.9032474, lng: 21.2079975 },
    { lat: 48.9032294, lng: 21.2081769 },
    { lat: 48.9030786, lng: 21.2084661 },
    { lat: 48.902818, lng: 21.2086254 },
    { lat: 48.9025266, lng: 21.2089135 },
    { lat: 48.9025793, lng: 21.2092341 },
    { lat: 48.9025903, lng: 21.2097688 },
    { lat: 48.9029867, lng: 21.2101403 },
    { lat: 48.9037213, lng: 21.2104889 },
    { lat: 48.9036796, lng: 21.2115697 },
    { lat: 48.9034769, lng: 21.212216 },
    { lat: 48.9034114, lng: 21.2129973 },
    { lat: 48.9032861, lng: 21.2135632 },
    { lat: 48.9027188, lng: 21.2141036 },
    { lat: 48.902503, lng: 21.2144367 },
    { lat: 48.9019968, lng: 21.2148414 },
    { lat: 48.9019788, lng: 21.215351 },
    { lat: 48.902196, lng: 21.2158229 },
    { lat: 48.9023224, lng: 21.2159359 },
    { lat: 48.9026292, lng: 21.2160738 },
    { lat: 48.9030592, lng: 21.216112 },
    { lat: 48.9036739, lng: 21.2164815 },
    { lat: 48.9042823, lng: 21.2164275 },
    { lat: 48.9044325, lng: 21.2165581 },
    { lat: 48.9047274, lng: 21.216992 },
    { lat: 48.9058657, lng: 21.2170103 },
    { lat: 48.9065633, lng: 21.2167922 },
    { lat: 48.9077288, lng: 21.2162701 },
    { lat: 48.9081802, lng: 21.2162059 },
    { lat: 48.9089941, lng: 21.2157072 },
    { lat: 48.9092768, lng: 21.2156759 },
    { lat: 48.9096796, lng: 21.2154953 },
    { lat: 48.9103086, lng: 21.2148779 },
    { lat: 48.9106025, lng: 21.2149173 },
    { lat: 48.9109794, lng: 21.2150255 },
    { lat: 48.9115563, lng: 21.2154442 },
    { lat: 48.9117549, lng: 21.2154615 },
    { lat: 48.9120678, lng: 21.2157943 },
    { lat: 48.9123455, lng: 21.2158903 },
    { lat: 48.9129473, lng: 21.2166776 },
    { lat: 48.9133002, lng: 21.2170223 },
    { lat: 48.9142708, lng: 21.2174011 },
    { lat: 48.9147808, lng: 21.2174819 },
    { lat: 48.915625, lng: 21.2174883 },
    { lat: 48.9160125, lng: 21.2175726 },
    { lat: 48.9176465, lng: 21.2192739 },
    { lat: 48.9182088, lng: 21.2196255 },
    { lat: 48.9187756, lng: 21.2198261 },
    { lat: 48.9192129, lng: 21.219778 },
    { lat: 48.9199061, lng: 21.2197452 },
    { lat: 48.9214694, lng: 21.2198605 },
    { lat: 48.9226616, lng: 21.2197963 },
    { lat: 48.9235398, lng: 21.2194485 },
    { lat: 48.9239767, lng: 21.2193875 },
    { lat: 48.9268055, lng: 21.2183615 },
    { lat: 48.926818, lng: 21.2184082 },
    { lat: 48.9275578, lng: 21.2184154 },
    { lat: 48.9277401, lng: 21.2184907 },
    { lat: 48.928303, lng: 21.2184236 },
    { lat: 48.9290888, lng: 21.2190494 },
    { lat: 48.9294298, lng: 21.2191918 },
    { lat: 48.9295758, lng: 21.2193288 },
    { lat: 48.9299243, lng: 21.219428 },
    { lat: 48.9304295, lng: 21.2200436 },
    { lat: 48.9307934, lng: 21.2200909 },
    { lat: 48.9310388, lng: 21.2199141 },
    { lat: 48.9316596, lng: 21.2199709 },
    { lat: 48.9323432, lng: 21.219879 },
    { lat: 48.9327677, lng: 21.2199578 },
    { lat: 48.9330682, lng: 21.2199302 },
    { lat: 48.9339179, lng: 21.2196732 },
    { lat: 48.9343329, lng: 21.2196643 },
    { lat: 48.9345149, lng: 21.2194422 },
    { lat: 48.9347421, lng: 21.2192867 },
    { lat: 48.9350298, lng: 21.2192435 },
    { lat: 48.9353054, lng: 21.2190946 },
    { lat: 48.9358209, lng: 21.2191353 },
    { lat: 48.9362074, lng: 21.218929 },
    { lat: 48.9366251, lng: 21.2188326 },
    { lat: 48.9376917, lng: 21.2182432 },
    { lat: 48.9388105, lng: 21.2179644 },
    { lat: 48.9395276, lng: 21.217685 },
    { lat: 48.9397461, lng: 21.2178995 },
    { lat: 48.9398538, lng: 21.2183597 },
    { lat: 48.9398298, lng: 21.218932 },
    { lat: 48.9406253, lng: 21.2189638 },
    { lat: 48.9411848, lng: 21.2190766 },
    { lat: 48.9415341, lng: 21.2191042 },
    { lat: 48.9424209, lng: 21.2197749 },
    { lat: 48.9435051, lng: 21.220158 },
    { lat: 48.9440227, lng: 21.2207345 },
    { lat: 48.9442501, lng: 21.2211588 },
    { lat: 48.9448341, lng: 21.2213183 },
    { lat: 48.9451056, lng: 21.2213264 },
    { lat: 48.9456638, lng: 21.2212521 },
    { lat: 48.9466084, lng: 21.2208347 },
    { lat: 48.9466582, lng: 21.2202669 },
    { lat: 48.9465693, lng: 21.2189594 },
    { lat: 48.9462042, lng: 21.2183296 },
    { lat: 48.9457164, lng: 21.2177699 },
    { lat: 48.9452618, lng: 21.2174832 },
    { lat: 48.9451739, lng: 21.2173713 },
    { lat: 48.9450444, lng: 21.2170065 },
    { lat: 48.9449997, lng: 21.2166173 },
    { lat: 48.9450205, lng: 21.2163835 },
    { lat: 48.9453056, lng: 21.2158504 },
    { lat: 48.9454424, lng: 21.2157629 },
    { lat: 48.9455897, lng: 21.2157212 },
    { lat: 48.9467183, lng: 21.2157591 },
    { lat: 48.9471974, lng: 21.2155289 },
    { lat: 48.9477387, lng: 21.2151704 },
    { lat: 48.9483963, lng: 21.2150767 },
    { lat: 48.9488916, lng: 21.2146477 },
    { lat: 48.9488521, lng: 21.2145653 },
    { lat: 48.9495888, lng: 21.2136872 },
    { lat: 48.9497909, lng: 21.2129856 },
    { lat: 48.9499509, lng: 21.2127375 },
    { lat: 48.9500347, lng: 21.212469 },
    { lat: 48.9501865, lng: 21.2122827 },
    { lat: 48.950227, lng: 21.2121398 },
    { lat: 48.9505027, lng: 21.2118125 },
    { lat: 48.9505956, lng: 21.2115788 },
    { lat: 48.9506652, lng: 21.2110353 },
    { lat: 48.9508395, lng: 21.2107023 },
    { lat: 48.9510515, lng: 21.2106796 },
    { lat: 48.9517044, lng: 21.2111154 },
    { lat: 48.9520289, lng: 21.2112389 },
    { lat: 48.9525916, lng: 21.2108089 },
    { lat: 48.9533399, lng: 21.210772 },
    { lat: 48.9541636, lng: 21.2100468 },
    { lat: 48.9547531, lng: 21.2098244 },
    { lat: 48.9552746, lng: 21.2099119 },
    { lat: 48.9558385, lng: 21.2098452 },
    { lat: 48.9563224, lng: 21.2099537 },
    { lat: 48.9567076, lng: 21.20982 },
    { lat: 48.9568026, lng: 21.2097602 },
    { lat: 48.9571861, lng: 21.2091766 },
    { lat: 48.9572816, lng: 21.2091701 },
    { lat: 48.9573784, lng: 21.2092043 },
    { lat: 48.9576786, lng: 21.2098112 },
    { lat: 48.9585003, lng: 21.2106839 },
    { lat: 48.9590463, lng: 21.2114916 },
    { lat: 48.959177, lng: 21.2115979 },
    { lat: 48.9597217, lng: 21.2115667 },
    { lat: 48.9603139, lng: 21.2116459 },
    { lat: 48.9607558, lng: 21.2113488 },
    { lat: 48.9611788, lng: 21.2106194 },
    { lat: 48.9616225, lng: 21.2096926 },
    { lat: 48.9617235, lng: 21.209575 },
    { lat: 48.9623342, lng: 21.2092697 },
    { lat: 48.9629495, lng: 21.2087195 },
    { lat: 48.9632917, lng: 21.208548 },
    { lat: 48.9634453, lng: 21.2083834 },
    { lat: 48.9636919, lng: 21.208313 },
    { lat: 48.9649338, lng: 21.2076503 },
    { lat: 48.9653001, lng: 21.2076674 },
    { lat: 48.9655018, lng: 21.2077846 },
    { lat: 48.9662266, lng: 21.2078948 },
    { lat: 48.9667161, lng: 21.2082564 },
    { lat: 48.9672336, lng: 21.2083983 },
    { lat: 48.9673683, lng: 21.2083453 },
    { lat: 48.9676501, lng: 21.2080367 },
    { lat: 48.9674565, lng: 21.207825 },
    { lat: 48.9668914, lng: 21.207458 },
    { lat: 48.9664796, lng: 21.2070242 },
    { lat: 48.9662437, lng: 21.2066723 },
    { lat: 48.9657258, lng: 21.2060892 },
    { lat: 48.9651227, lng: 21.2051924 },
    { lat: 48.9649796, lng: 21.2048628 },
    { lat: 48.9647663, lng: 21.2040111 },
    { lat: 48.9647744, lng: 21.2035539 },
    { lat: 48.9648824, lng: 21.2030755 },
    { lat: 48.9649098, lng: 21.2020438 },
    { lat: 48.9648475, lng: 21.2019269 },
    { lat: 48.9646434, lng: 21.2016974 },
    { lat: 48.9642993, lng: 21.2015588 },
    { lat: 48.9640967, lng: 21.2013388 },
    { lat: 48.963571, lng: 21.2009756 },
    { lat: 48.9627875, lng: 21.201076 },
    { lat: 48.9625449, lng: 21.2009811 },
    { lat: 48.9623694, lng: 21.2008057 },
    { lat: 48.9620347, lng: 21.2007198 },
    { lat: 48.9617156, lng: 21.2003697 },
    { lat: 48.9613729, lng: 21.2002117 },
    { lat: 48.9612156, lng: 21.1997109 },
    { lat: 48.9608188, lng: 21.1993663 },
    { lat: 48.9604929, lng: 21.1984075 },
    { lat: 48.9602813, lng: 21.1982623 },
    { lat: 48.960302, lng: 21.1980954 },
    { lat: 48.9602377, lng: 21.1978035 },
    { lat: 48.9600896, lng: 21.1976741 },
    { lat: 48.9600015, lng: 21.1974148 },
    { lat: 48.9598779, lng: 21.197346 },
    { lat: 48.9597345, lng: 21.1970054 },
    { lat: 48.9594156, lng: 21.1959522 },
    { lat: 48.9594251, lng: 21.1958205 },
    { lat: 48.959273, lng: 21.1955636 },
    { lat: 48.9593654, lng: 21.1953514 },
    { lat: 48.9592033, lng: 21.1952051 },
    { lat: 48.959124, lng: 21.1949021 },
    { lat: 48.9591644, lng: 21.1948294 },
    { lat: 48.959104, lng: 21.1946626 },
    { lat: 48.9589946, lng: 21.1946547 },
    { lat: 48.9586697, lng: 21.1944461 },
    { lat: 48.958662, lng: 21.1943031 },
    { lat: 48.9587858, lng: 21.1938755 },
    { lat: 48.9587339, lng: 21.1937976 },
    { lat: 48.9583867, lng: 21.1936393 },
    { lat: 48.9583495, lng: 21.1935643 },
    { lat: 48.9582883, lng: 21.1935845 },
    { lat: 48.9582664, lng: 21.1937121 },
    { lat: 48.9581219, lng: 21.1936616 },
    { lat: 48.9579572, lng: 21.1939002 },
    { lat: 48.9579132, lng: 21.1936477 },
    { lat: 48.9578417, lng: 21.1936285 },
    { lat: 48.9578089, lng: 21.1937261 },
    { lat: 48.9576894, lng: 21.1937208 },
    { lat: 48.9576498, lng: 21.1936771 },
    { lat: 48.9577027, lng: 21.193467 },
    { lat: 48.957406, lng: 21.1930838 },
    { lat: 48.9574283, lng: 21.1930041 },
    { lat: 48.9573501, lng: 21.1928776 },
    { lat: 48.9573759, lng: 21.1927687 },
    { lat: 48.957326, lng: 21.1926426 },
    { lat: 48.957154, lng: 21.1925809 },
    { lat: 48.9570961, lng: 21.1926377 },
    { lat: 48.9570257, lng: 21.1925801 },
    { lat: 48.9569797, lng: 21.1922261 },
    { lat: 48.9568207, lng: 21.1921398 },
    { lat: 48.9567521, lng: 21.1919693 },
    { lat: 48.9574212, lng: 21.1907962 },
    { lat: 48.9579341, lng: 21.1895689 },
    { lat: 48.9583714, lng: 21.1881872 },
    { lat: 48.9587229, lng: 21.1873793 },
    { lat: 48.959303, lng: 21.1864622 },
    { lat: 48.9596923, lng: 21.1853841 },
    { lat: 48.9600173, lng: 21.1846948 },
    { lat: 48.9598888, lng: 21.1841462 },
    { lat: 48.9601236, lng: 21.1833829 },
    { lat: 48.9603956, lng: 21.1828697 },
    { lat: 48.9604373, lng: 21.1826561 },
    { lat: 48.9607924, lng: 21.1822433 },
    { lat: 48.9614391, lng: 21.1818408 },
    { lat: 48.9618185, lng: 21.1818768 },
    { lat: 48.9620775, lng: 21.1817728 },
    { lat: 48.9621896, lng: 21.1816554 },
    { lat: 48.9624558, lng: 21.1810674 },
    { lat: 48.9625375, lng: 21.1805462 },
    { lat: 48.9627951, lng: 21.179933 },
    { lat: 48.9628939, lng: 21.1793564 },
    { lat: 48.962899, lng: 21.1788736 },
    { lat: 48.9633026, lng: 21.1781088 },
    { lat: 48.9636108, lng: 21.1771703 },
    { lat: 48.963872, lng: 21.175173 },
    { lat: 48.964088, lng: 21.1740936 },
    { lat: 48.9640848, lng: 21.172995 },
    { lat: 48.9641692, lng: 21.1727096 },
    { lat: 48.9641337, lng: 21.1721878 },
    { lat: 48.9642078, lng: 21.1718604 },
    { lat: 48.9643048, lng: 21.171083 },
  ],
  Mirkovce: [
    { lat: 48.9037475, lng: 21.3002456 },
    { lat: 48.9032671, lng: 21.3010979 },
    { lat: 48.9028742, lng: 21.3013911 },
    { lat: 48.9017009, lng: 21.3028048 },
    { lat: 48.9006281, lng: 21.3039028 },
    { lat: 48.8992545, lng: 21.3069208 },
    { lat: 48.8988572, lng: 21.3071417 },
    { lat: 48.8986262, lng: 21.3076106 },
    { lat: 48.8982818, lng: 21.3079079 },
    { lat: 48.8971792, lng: 21.3081737 },
    { lat: 48.8968669, lng: 21.3084498 },
    { lat: 48.8965765, lng: 21.3088191 },
    { lat: 48.8963652, lng: 21.3092694 },
    { lat: 48.8950341, lng: 21.3102785 },
    { lat: 48.8947161, lng: 21.3106655 },
    { lat: 48.8940634, lng: 21.3120703 },
    { lat: 48.8936466, lng: 21.3136164 },
    { lat: 48.8933742, lng: 21.3143542 },
    { lat: 48.8925055, lng: 21.3162156 },
    { lat: 48.8913256, lng: 21.3157488 },
    { lat: 48.8902554, lng: 21.3154504 },
    { lat: 48.8883441, lng: 21.3146109 },
    { lat: 48.8864766, lng: 21.3142947 },
    { lat: 48.8855367, lng: 21.3139529 },
    { lat: 48.8815968, lng: 21.3121597 },
    { lat: 48.8788192, lng: 21.3112232 },
    { lat: 48.8774817, lng: 21.3114877 },
    { lat: 48.8766243, lng: 21.311458 },
    { lat: 48.8759325, lng: 21.3110919 },
    { lat: 48.8761076, lng: 21.3113371 },
    { lat: 48.8757127, lng: 21.3162036 },
    { lat: 48.8753286, lng: 21.3209474 },
    { lat: 48.8750113, lng: 21.3211007 },
    { lat: 48.8754513, lng: 21.3295925 },
    { lat: 48.881623, lng: 21.3323469 },
    { lat: 48.883485, lng: 21.3355842 },
    { lat: 48.8835572, lng: 21.3355938 },
    { lat: 48.8838827, lng: 21.3361988 },
    { lat: 48.8839246, lng: 21.3365094 },
    { lat: 48.8841872, lng: 21.3370476 },
    { lat: 48.8851958, lng: 21.3388542 },
    { lat: 48.8863615, lng: 21.3407556 },
    { lat: 48.8869257, lng: 21.3421161 },
    { lat: 48.8875244, lng: 21.3431703 },
    { lat: 48.8904709, lng: 21.3457352 },
    { lat: 48.8899554, lng: 21.347382 },
    { lat: 48.8896246, lng: 21.349742 },
    { lat: 48.8896524, lng: 21.3499242 },
    { lat: 48.8901228, lng: 21.3507883 },
    { lat: 48.8905637, lng: 21.3519036 },
    { lat: 48.8906783, lng: 21.3522336 },
    { lat: 48.8908824, lng: 21.3532998 },
    { lat: 48.890959, lng: 21.3534966 },
    { lat: 48.8909171, lng: 21.35394 },
    { lat: 48.890992, lng: 21.3544802 },
    { lat: 48.890938, lng: 21.3547882 },
    { lat: 48.8907134, lng: 21.3553958 },
    { lat: 48.8906194, lng: 21.3559054 },
    { lat: 48.8905171, lng: 21.357409 },
    { lat: 48.8905238, lng: 21.3583919 },
    { lat: 48.8904306, lng: 21.3592643 },
    { lat: 48.8904851, lng: 21.3598806 },
    { lat: 48.8904275, lng: 21.3628118 },
    { lat: 48.8897588, lng: 21.363097 },
    { lat: 48.8895702, lng: 21.364921 },
    { lat: 48.8890282, lng: 21.3668201 },
    { lat: 48.8888486, lng: 21.3676692 },
    { lat: 48.8893223, lng: 21.3679714 },
    { lat: 48.8936407, lng: 21.3698866 },
    { lat: 48.8941699, lng: 21.3702669 },
    { lat: 48.8954572, lng: 21.3669497 },
    { lat: 48.8960235, lng: 21.3651156 },
    { lat: 48.8987417, lng: 21.3578698 },
    { lat: 48.899674, lng: 21.3559351 },
    { lat: 48.9001407, lng: 21.3536308 },
    { lat: 48.8998886, lng: 21.3503249 },
    { lat: 48.9001044, lng: 21.3477635 },
    { lat: 48.9013641, lng: 21.3468067 },
    { lat: 48.9039504, lng: 21.3455505 },
    { lat: 48.9039201, lng: 21.3452868 },
    { lat: 48.9033915, lng: 21.3431236 },
    { lat: 48.9031439, lng: 21.3416241 },
    { lat: 48.9030316, lng: 21.3403972 },
    { lat: 48.902386, lng: 21.3405942 },
    { lat: 48.9022155, lng: 21.3399346 },
    { lat: 48.9018275, lng: 21.339381 },
    { lat: 48.9013382, lng: 21.3390135 },
    { lat: 48.9015095, lng: 21.3370399 },
    { lat: 48.9018381, lng: 21.3370761 },
    { lat: 48.9017684, lng: 21.3345029 },
    { lat: 48.9010971, lng: 21.3336177 },
    { lat: 48.902634, lng: 21.33223 },
    { lat: 48.9025114, lng: 21.3315288 },
    { lat: 48.902382, lng: 21.3294712 },
    { lat: 48.9024412, lng: 21.3278951 },
    { lat: 48.9041785, lng: 21.3275853 },
    { lat: 48.9042093, lng: 21.3235384 },
    { lat: 48.907249, lng: 21.3244417 },
    { lat: 48.9077867, lng: 21.3245467 },
    { lat: 48.9121873, lng: 21.3249096 },
    { lat: 48.9126194, lng: 21.3248366 },
    { lat: 48.9154594, lng: 21.3238604 },
    { lat: 48.9152436, lng: 21.3237173 },
    { lat: 48.9150862, lng: 21.3239096 },
    { lat: 48.9146837, lng: 21.3237369 },
    { lat: 48.9144543, lng: 21.3240054 },
    { lat: 48.9140053, lng: 21.3241128 },
    { lat: 48.9139618, lng: 21.3237195 },
    { lat: 48.9136616, lng: 21.323389 },
    { lat: 48.913777, lng: 21.323112 },
    { lat: 48.9134283, lng: 21.3226278 },
    { lat: 48.9134332, lng: 21.3220114 },
    { lat: 48.913114, lng: 21.3218712 },
    { lat: 48.9125414, lng: 21.3218477 },
    { lat: 48.9120975, lng: 21.3211155 },
    { lat: 48.9113817, lng: 21.3210032 },
    { lat: 48.9112815, lng: 21.320787 },
    { lat: 48.9108226, lng: 21.3205974 },
    { lat: 48.9107233, lng: 21.3204019 },
    { lat: 48.9108258, lng: 21.3200696 },
    { lat: 48.9103989, lng: 21.3195358 },
    { lat: 48.90995, lng: 21.3195392 },
    { lat: 48.909592, lng: 21.3191638 },
    { lat: 48.9095704, lng: 21.3186348 },
    { lat: 48.9088423, lng: 21.318224 },
    { lat: 48.908937, lng: 21.3173724 },
    { lat: 48.9099771, lng: 21.313189 },
    { lat: 48.9103488, lng: 21.3113386 },
    { lat: 48.9104459, lng: 21.3110192 },
    { lat: 48.910894, lng: 21.3100249 },
    { lat: 48.9110957, lng: 21.3079144 },
    { lat: 48.9115972, lng: 21.3060163 },
    { lat: 48.9123289, lng: 21.3039804 },
    { lat: 48.9109716, lng: 21.3037561 },
    { lat: 48.9098154, lng: 21.3034742 },
    { lat: 48.9059553, lng: 21.3020227 },
    { lat: 48.9039187, lng: 21.3011006 },
    { lat: 48.9037475, lng: 21.3002456 },
  ],
  Geraltov: [
    { lat: 49.1258757, lng: 21.2778252 },
    { lat: 49.1258038, lng: 21.2793831 },
    { lat: 49.1260351, lng: 21.2800303 },
    { lat: 49.1261976, lng: 21.2809716 },
    { lat: 49.1260792, lng: 21.2816968 },
    { lat: 49.1266761, lng: 21.2833457 },
    { lat: 49.1271255, lng: 21.2841752 },
    { lat: 49.1272815, lng: 21.2846744 },
    { lat: 49.1273617, lng: 21.2852817 },
    { lat: 49.1274662, lng: 21.2879053 },
    { lat: 49.1273788, lng: 21.2882119 },
    { lat: 49.1272347, lng: 21.2883201 },
    { lat: 49.1269883, lng: 21.288769 },
    { lat: 49.127009, lng: 21.2889687 },
    { lat: 49.1267644, lng: 21.2890298 },
    { lat: 49.1264974, lng: 21.2896878 },
    { lat: 49.1265204, lng: 21.2899236 },
    { lat: 49.1264241, lng: 21.2899038 },
    { lat: 49.1263581, lng: 21.2900209 },
    { lat: 49.1263543, lng: 21.2903076 },
    { lat: 49.1264251, lng: 21.2907542 },
    { lat: 49.1265564, lng: 21.2908132 },
    { lat: 49.1266116, lng: 21.2911503 },
    { lat: 49.1265053, lng: 21.2914312 },
    { lat: 49.1265321, lng: 21.2916053 },
    { lat: 49.1266789, lng: 21.2917372 },
    { lat: 49.126566, lng: 21.2920182 },
    { lat: 49.1267623, lng: 21.2923314 },
    { lat: 49.1268064, lng: 21.2925252 },
    { lat: 49.1266574, lng: 21.2928457 },
    { lat: 49.1269596, lng: 21.2932009 },
    { lat: 49.1269458, lng: 21.2933374 },
    { lat: 49.1267911, lng: 21.2933917 },
    { lat: 49.1267903, lng: 21.2934791 },
    { lat: 49.1269541, lng: 21.293718 },
    { lat: 49.1272297, lng: 21.2939059 },
    { lat: 49.1272705, lng: 21.29399 },
    { lat: 49.1270307, lng: 21.2942788 },
    { lat: 49.127156, lng: 21.294887 },
    { lat: 49.127181, lng: 21.294895 },
    { lat: 49.127328, lng: 21.294913 },
    { lat: 49.127481, lng: 21.295033 },
    { lat: 49.127729, lng: 21.295001 },
    { lat: 49.127869, lng: 21.295084 },
    { lat: 49.127985, lng: 21.295277 },
    { lat: 49.128055, lng: 21.295451 },
    { lat: 49.128375, lng: 21.295447 },
    { lat: 49.128542, lng: 21.295616 },
    { lat: 49.128815, lng: 21.295454 },
    { lat: 49.129016, lng: 21.295384 },
    { lat: 49.129171, lng: 21.295269 },
    { lat: 49.129364, lng: 21.295043 },
    { lat: 49.129488, lng: 21.295187 },
    { lat: 49.129511, lng: 21.295174 },
    { lat: 49.129555, lng: 21.295024 },
    { lat: 49.12966, lng: 21.29497 },
    { lat: 49.130079, lng: 21.294942 },
    { lat: 49.130322, lng: 21.294997 },
    { lat: 49.130642, lng: 21.295006 },
    { lat: 49.130958, lng: 21.294929 },
    { lat: 49.131545, lng: 21.294578 },
    { lat: 49.131887, lng: 21.294481 },
    { lat: 49.132096, lng: 21.294389 },
    { lat: 49.132122, lng: 21.294437 },
    { lat: 49.132799, lng: 21.294866 },
    { lat: 49.132951, lng: 21.294876 },
    { lat: 49.132989, lng: 21.294878 },
    { lat: 49.133822, lng: 21.29493 },
    { lat: 49.134299, lng: 21.294883 },
    { lat: 49.13433, lng: 21.29446 },
    { lat: 49.135236, lng: 21.293833 },
    { lat: 49.135604, lng: 21.293855 },
    { lat: 49.135976, lng: 21.293833 },
    { lat: 49.136392, lng: 21.293964 },
    { lat: 49.136587, lng: 21.294145 },
    { lat: 49.137032, lng: 21.2943 },
    { lat: 49.137316, lng: 21.294244 },
    { lat: 49.137494, lng: 21.294415 },
    { lat: 49.137731, lng: 21.294478 },
    { lat: 49.137971, lng: 21.294647 },
    { lat: 49.138126, lng: 21.294685 },
    { lat: 49.138186, lng: 21.2947 },
    { lat: 49.138245, lng: 21.294714 },
    { lat: 49.13835, lng: 21.294754 },
    { lat: 49.13844, lng: 21.294471 },
    { lat: 49.13848, lng: 21.294395 },
    { lat: 49.138627, lng: 21.294305 },
    { lat: 49.138701, lng: 21.294232 },
    { lat: 49.139046, lng: 21.294019 },
    { lat: 49.139501, lng: 21.293704 },
    { lat: 49.139689, lng: 21.293648 },
    { lat: 49.139921, lng: 21.294019 },
    { lat: 49.140091, lng: 21.294323 },
    { lat: 49.14028, lng: 21.294517 },
    { lat: 49.140469, lng: 21.294527 },
    { lat: 49.140779, lng: 21.294087 },
    { lat: 49.140971, lng: 21.294073 },
    { lat: 49.141197, lng: 21.2944 },
    { lat: 49.141451, lng: 21.294956 },
    { lat: 49.141517, lng: 21.294975 },
    { lat: 49.141547, lng: 21.295003 },
    { lat: 49.14163, lng: 21.294915 },
    { lat: 49.141688, lng: 21.294827 },
    { lat: 49.14206, lng: 21.294834 },
    { lat: 49.142282, lng: 21.295124 },
    { lat: 49.142515, lng: 21.295224 },
    { lat: 49.142676, lng: 21.294995 },
    { lat: 49.142717, lng: 21.294863 },
    { lat: 49.14282, lng: 21.294646 },
    { lat: 49.142883, lng: 21.294518 },
    { lat: 49.142986, lng: 21.294456 },
    { lat: 49.143134, lng: 21.294406 },
    { lat: 49.143261, lng: 21.294395 },
    { lat: 49.14373, lng: 21.294564 },
    { lat: 49.144368, lng: 21.294474 },
    { lat: 49.144642, lng: 21.294325 },
    { lat: 49.144999, lng: 21.293945 },
    { lat: 49.145218, lng: 21.293409 },
    { lat: 49.145279, lng: 21.293314 },
    { lat: 49.145525, lng: 21.293114 },
    { lat: 49.14574, lng: 21.293062 },
    { lat: 49.145948, lng: 21.293324 },
    { lat: 49.14605, lng: 21.293126 },
    { lat: 49.146098, lng: 21.292848 },
    { lat: 49.146155, lng: 21.292773 },
    { lat: 49.146199, lng: 21.292634 },
    { lat: 49.146148, lng: 21.29255 },
    { lat: 49.146278, lng: 21.292323 },
    { lat: 49.146442, lng: 21.29216 },
    { lat: 49.146634, lng: 21.29187 },
    { lat: 49.146655, lng: 21.291689 },
    { lat: 49.146709, lng: 21.291378 },
    { lat: 49.146773, lng: 21.291173 },
    { lat: 49.147215, lng: 21.290964 },
    { lat: 49.147403, lng: 21.290722 },
    { lat: 49.14747, lng: 21.290572 },
    { lat: 49.147469, lng: 21.290226 },
    { lat: 49.147316, lng: 21.289787 },
    { lat: 49.147246, lng: 21.289256 },
    { lat: 49.147384, lng: 21.288852 },
    { lat: 49.14738, lng: 21.28807 },
    { lat: 49.14754, lng: 21.287469 },
    { lat: 49.147663, lng: 21.286758 },
    { lat: 49.147676, lng: 21.285814 },
    { lat: 49.147707, lng: 21.28575 },
    { lat: 49.147823, lng: 21.285726 },
    { lat: 49.147925, lng: 21.285562 },
    { lat: 49.148257, lng: 21.284078 },
    { lat: 49.148447, lng: 21.283768 },
    { lat: 49.148594, lng: 21.283521 },
    { lat: 49.148901, lng: 21.282955 },
    { lat: 49.149482, lng: 21.282323 },
    { lat: 49.149623, lng: 21.282141 },
    { lat: 49.149773, lng: 21.282133 },
    { lat: 49.149917, lng: 21.282128 },
    { lat: 49.150092, lng: 21.28198 },
    { lat: 49.150154, lng: 21.281883 },
    { lat: 49.150394, lng: 21.281251 },
    { lat: 49.150751, lng: 21.280503 },
    { lat: 49.150815, lng: 21.28064 },
    { lat: 49.150967, lng: 21.280662 },
    { lat: 49.15105, lng: 21.28049 },
    { lat: 49.151088, lng: 21.280221 },
    { lat: 49.151215, lng: 21.279962 },
    { lat: 49.151295, lng: 21.27987 },
    { lat: 49.151234, lng: 21.279783 },
    { lat: 49.151473, lng: 21.279132 },
    { lat: 49.151624, lng: 21.27904 },
    { lat: 49.151675, lng: 21.278886 },
    { lat: 49.151787, lng: 21.27853 },
    { lat: 49.151919, lng: 21.278313 },
    { lat: 49.151952, lng: 21.278118 },
    { lat: 49.151739, lng: 21.278005 },
    { lat: 49.1519, lng: 21.277202 },
    { lat: 49.152007, lng: 21.2771 },
    { lat: 49.152333, lng: 21.276762 },
    { lat: 49.152319, lng: 21.276703 },
    { lat: 49.152328, lng: 21.276605 },
    { lat: 49.152352, lng: 21.276367 },
    { lat: 49.152684, lng: 21.275488 },
    { lat: 49.152522, lng: 21.275469 },
    { lat: 49.152581, lng: 21.275332 },
    { lat: 49.152578, lng: 21.274803 },
    { lat: 49.152822, lng: 21.274388 },
    { lat: 49.153187, lng: 21.274226 },
    { lat: 49.153821, lng: 21.272757 },
    { lat: 49.153961, lng: 21.272655 },
    { lat: 49.153996, lng: 21.272574 },
    { lat: 49.153992, lng: 21.272332 },
    { lat: 49.153945, lng: 21.271789 },
    { lat: 49.153912, lng: 21.271631 },
    { lat: 49.153986, lng: 21.271137 },
    { lat: 49.154283, lng: 21.270952 },
    { lat: 49.154314, lng: 21.270835 },
    { lat: 49.154144, lng: 21.270451 },
    { lat: 49.154112, lng: 21.270309 },
    { lat: 49.154117, lng: 21.2701 },
    { lat: 49.154176, lng: 21.269727 },
    { lat: 49.154225, lng: 21.269493 },
    { lat: 49.154322, lng: 21.269487 },
    { lat: 49.154428, lng: 21.269374 },
    { lat: 49.154456, lng: 21.269522 },
    { lat: 49.154463, lng: 21.269636 },
    { lat: 49.154497, lng: 21.269596 },
    { lat: 49.154463, lng: 21.26922 },
    { lat: 49.154362, lng: 21.268854 },
    { lat: 49.154439, lng: 21.268453 },
    { lat: 49.154747, lng: 21.268166 },
    { lat: 49.154798, lng: 21.26794 },
    { lat: 49.154751, lng: 21.267255 },
    { lat: 49.154854, lng: 21.266675 },
    { lat: 49.155178, lng: 21.266541 },
    { lat: 49.155235, lng: 21.266477 },
    { lat: 49.155236, lng: 21.26637 },
    { lat: 49.155226, lng: 21.26627 },
    { lat: 49.155204, lng: 21.266197 },
    { lat: 49.155129, lng: 21.266165 },
    { lat: 49.155075, lng: 21.266136 },
    { lat: 49.155053, lng: 21.266071 },
    { lat: 49.155172, lng: 21.265643 },
    { lat: 49.155206, lng: 21.265035 },
    { lat: 49.155032, lng: 21.264768 },
    { lat: 49.154754, lng: 21.264679 },
    { lat: 49.154691, lng: 21.2646 },
    { lat: 49.154633, lng: 21.264481 },
    { lat: 49.154621, lng: 21.264433 },
    { lat: 49.154632, lng: 21.264375 },
    { lat: 49.15459, lng: 21.264222 },
    { lat: 49.154431, lng: 21.26404 },
    { lat: 49.153826, lng: 21.263992 },
    { lat: 49.153773, lng: 21.263926 },
    { lat: 49.153775, lng: 21.263636 },
    { lat: 49.153702, lng: 21.26345 },
    { lat: 49.153669, lng: 21.263378 },
    { lat: 49.153291, lng: 21.263403 },
    { lat: 49.153109, lng: 21.263403 },
    { lat: 49.152941, lng: 21.263389 },
    { lat: 49.152255, lng: 21.263259 },
    { lat: 49.151827, lng: 21.263122 },
    { lat: 49.151605, lng: 21.26335 },
    { lat: 49.151504, lng: 21.263256 },
    { lat: 49.151404, lng: 21.263175 },
    { lat: 49.151352, lng: 21.263189 },
    { lat: 49.151423, lng: 21.262173 },
    { lat: 49.151197, lng: 21.261754 },
    { lat: 49.151332, lng: 21.261434 },
    { lat: 49.151291, lng: 21.260957 },
    { lat: 49.15146, lng: 21.260355 },
    { lat: 49.151377, lng: 21.260028 },
    { lat: 49.151358, lng: 21.259348 },
    { lat: 49.151509, lng: 21.258908 },
    { lat: 49.151773, lng: 21.257814 },
    { lat: 49.151673, lng: 21.257528 },
    { lat: 49.151688, lng: 21.257117 },
    { lat: 49.151773, lng: 21.256916 },
    { lat: 49.151687, lng: 21.256599 },
    { lat: 49.151731, lng: 21.256341 },
    { lat: 49.151695, lng: 21.256085 },
    { lat: 49.151733, lng: 21.255864 },
    { lat: 49.151775, lng: 21.255806 },
    { lat: 49.151768, lng: 21.255561 },
    { lat: 49.151843, lng: 21.255338 },
    { lat: 49.151859, lng: 21.255271 },
    { lat: 49.15187, lng: 21.254989 },
    { lat: 49.151932, lng: 21.254809 },
    { lat: 49.15213, lng: 21.254747 },
    { lat: 49.152161, lng: 21.25465 },
    { lat: 49.152166, lng: 21.254462 },
    { lat: 49.152144, lng: 21.254307 },
    { lat: 49.152231, lng: 21.25415 },
    { lat: 49.152221, lng: 21.254112 },
    { lat: 49.152125, lng: 21.254003 },
    { lat: 49.152058, lng: 21.253874 },
    { lat: 49.152064, lng: 21.253596 },
    { lat: 49.152135, lng: 21.25341 },
    { lat: 49.152146, lng: 21.253131 },
    { lat: 49.15211, lng: 21.25304 },
    { lat: 49.152204, lng: 21.252887 },
    { lat: 49.151959, lng: 21.252396 },
    { lat: 49.151929, lng: 21.252128 },
    { lat: 49.151715, lng: 21.252084 },
    { lat: 49.151713, lng: 21.251881 },
    { lat: 49.151684, lng: 21.251832 },
    { lat: 49.151589, lng: 21.251785 },
    { lat: 49.151595, lng: 21.251549 },
    { lat: 49.151597, lng: 21.251332 },
    { lat: 49.151627, lng: 21.251144 },
    { lat: 49.151732, lng: 21.2511 },
    { lat: 49.151935, lng: 21.250623 },
    { lat: 49.152042, lng: 21.250373 },
    { lat: 49.152049, lng: 21.250335 },
    { lat: 49.153019, lng: 21.247999 },
    { lat: 49.153293, lng: 21.247648 },
    { lat: 49.153584, lng: 21.247486 },
    { lat: 49.15365, lng: 21.247389 },
    { lat: 49.153864, lng: 21.247269 },
    { lat: 49.15394, lng: 21.247163 },
    { lat: 49.154059, lng: 21.247033 },
    { lat: 49.154134, lng: 21.246931 },
    { lat: 49.154259, lng: 21.246785 },
    { lat: 49.15441, lng: 21.246723 },
    { lat: 49.154596, lng: 21.246588 },
    { lat: 49.154642, lng: 21.246583 },
    { lat: 49.154667, lng: 21.24658 },
    { lat: 49.154743, lng: 21.24653 },
    { lat: 49.154827, lng: 21.24651 },
    { lat: 49.154876, lng: 21.246427 },
    { lat: 49.15498, lng: 21.246389 },
    { lat: 49.155045, lng: 21.246342 },
    { lat: 49.15514, lng: 21.246335 },
    { lat: 49.155184, lng: 21.246313 },
    { lat: 49.155233, lng: 21.246286 },
    { lat: 49.155282, lng: 21.246284 },
    { lat: 49.15534, lng: 21.246236 },
    { lat: 49.155363, lng: 21.246223 },
    { lat: 49.155356, lng: 21.246188 },
    { lat: 49.155361, lng: 21.246152 },
    { lat: 49.15554, lng: 21.245968 },
    { lat: 49.155562, lng: 21.245994 },
    { lat: 49.155633, lng: 21.245974 },
    { lat: 49.155601, lng: 21.245966 },
    { lat: 49.155579, lng: 21.24594 },
    { lat: 49.155541, lng: 21.245909 },
    { lat: 49.155404, lng: 21.245858 },
    { lat: 49.155387, lng: 21.245609 },
    { lat: 49.155426, lng: 21.245404 },
    { lat: 49.155187, lng: 21.244844 },
    { lat: 49.155035, lng: 21.244648 },
    { lat: 49.154986, lng: 21.244563 },
    { lat: 49.15494, lng: 21.244436 },
    { lat: 49.154906, lng: 21.243852 },
    { lat: 49.154776, lng: 21.243798 },
    { lat: 49.154741, lng: 21.243901 },
    { lat: 49.154715, lng: 21.243904 },
    { lat: 49.1546, lng: 21.243594 },
    { lat: 49.154509, lng: 21.243347 },
    { lat: 49.154421, lng: 21.24341 },
    { lat: 49.154107, lng: 21.243003 },
    { lat: 49.154047, lng: 21.242866 },
    { lat: 49.15395, lng: 21.242808 },
    { lat: 49.153986, lng: 21.242449 },
    { lat: 49.153992, lng: 21.242274 },
    { lat: 49.153997, lng: 21.242004 },
    { lat: 49.153991, lng: 21.241957 },
    { lat: 49.153896, lng: 21.24172 },
    { lat: 49.153899, lng: 21.241653 },
    { lat: 49.154017, lng: 21.241578 },
    { lat: 49.154046, lng: 21.241592 },
    { lat: 49.154105, lng: 21.241522 },
    { lat: 49.1541, lng: 21.241261 },
    { lat: 49.154049, lng: 21.241164 },
    { lat: 49.154048, lng: 21.240997 },
    { lat: 49.154299, lng: 21.240626 },
    { lat: 49.154493, lng: 21.240205 },
    { lat: 49.154612, lng: 21.240074 },
    { lat: 49.154646, lng: 21.239942 },
    { lat: 49.154851, lng: 21.2397 },
    { lat: 49.154882, lng: 21.239336 },
    { lat: 49.155104, lng: 21.239023 },
    { lat: 49.155087, lng: 21.238889 },
    { lat: 49.155175, lng: 21.238239 },
    { lat: 49.155098, lng: 21.238072 },
    { lat: 49.155125, lng: 21.237121 },
    { lat: 49.155341, lng: 21.236315 },
    { lat: 49.155604, lng: 21.235559 },
    { lat: 49.156, lng: 21.234776 },
    { lat: 49.156055, lng: 21.234423 },
    { lat: 49.156218, lng: 21.234232 },
    { lat: 49.156262, lng: 21.234025 },
    { lat: 49.156637, lng: 21.233091 },
    { lat: 49.156666, lng: 21.232892 },
    { lat: 49.156791, lng: 21.232712 },
    { lat: 49.156969, lng: 21.2322 },
    { lat: 49.157338, lng: 21.231554 },
    { lat: 49.157368, lng: 21.231299 },
    { lat: 49.157592, lng: 21.231067 },
    { lat: 49.157571, lng: 21.231 },
    { lat: 49.157564, lng: 21.230983 },
    { lat: 49.15752, lng: 21.230806 },
    { lat: 49.157551, lng: 21.230552 },
    { lat: 49.157473, lng: 21.230272 },
    { lat: 49.157353, lng: 21.230081 },
    { lat: 49.157311, lng: 21.22987 },
    { lat: 49.157311, lng: 21.229383 },
    { lat: 49.15739, lng: 21.228735 },
    { lat: 49.157353, lng: 21.228529 },
    { lat: 49.157383, lng: 21.228264 },
    { lat: 49.157453, lng: 21.228058 },
    { lat: 49.157562, lng: 21.227847 },
    { lat: 49.157788, lng: 21.227418 },
    { lat: 49.158273, lng: 21.226144 },
    { lat: 49.158607, lng: 21.225698 },
    { lat: 49.158637, lng: 21.225632 },
    { lat: 49.158668, lng: 21.225565 },
    { lat: 49.158836, lng: 21.225192 },
    { lat: 49.158835, lng: 21.225046 },
    { lat: 49.158945, lng: 21.224728 },
    { lat: 49.15913, lng: 21.224408 },
    { lat: 49.159155, lng: 21.224338 },
    { lat: 49.159292, lng: 21.223694 },
    { lat: 49.159353, lng: 21.222573 },
    { lat: 49.15935, lng: 21.222502 },
    { lat: 49.1599, lng: 21.221902 },
    { lat: 49.160449, lng: 21.221121 },
    { lat: 49.160932, lng: 21.220666 },
    { lat: 49.161277, lng: 21.220106 },
    { lat: 49.161376, lng: 21.219937 },
    { lat: 49.161498, lng: 21.219575 },
    { lat: 49.161729, lng: 21.2192 },
    { lat: 49.161825, lng: 21.218943 },
    { lat: 49.161912, lng: 21.218684 },
    { lat: 49.16204, lng: 21.218389 },
    { lat: 49.162175, lng: 21.218155 },
    { lat: 49.162289, lng: 21.218037 },
    { lat: 49.162324, lng: 21.217924 },
    { lat: 49.162311, lng: 21.21768 },
    { lat: 49.162606, lng: 21.216814 },
    { lat: 49.162697, lng: 21.216321 },
    { lat: 49.163132, lng: 21.214783 },
    { lat: 49.163199, lng: 21.214604 },
    { lat: 49.1620318, lng: 21.2145538 },
    { lat: 49.1617129, lng: 21.2147152 },
    { lat: 49.1610082, lng: 21.2152902 },
    { lat: 49.160273, lng: 21.2167298 },
    { lat: 49.1600827, lng: 21.2169043 },
    { lat: 49.1593621, lng: 21.2184226 },
    { lat: 49.1592564, lng: 21.2185338 },
    { lat: 49.1588411, lng: 21.2193999 },
    { lat: 49.1586941, lng: 21.2194881 },
    { lat: 49.1581915, lng: 21.2204076 },
    { lat: 49.1580434, lng: 21.2205347 },
    { lat: 49.157866, lng: 21.2209074 },
    { lat: 49.1570311, lng: 21.2218935 },
    { lat: 49.1565454, lng: 21.2222692 },
    { lat: 49.1562503, lng: 21.2224072 },
    { lat: 49.1558356, lng: 21.2228609 },
    { lat: 49.154008, lng: 21.223512 },
    { lat: 49.1535236, lng: 21.2233872 },
    { lat: 49.1532233, lng: 21.2235734 },
    { lat: 49.1526166, lng: 21.2234904 },
    { lat: 49.1523715, lng: 21.2237917 },
    { lat: 49.1519917, lng: 21.2238768 },
    { lat: 49.151916, lng: 21.2240897 },
    { lat: 49.1516418, lng: 21.2242043 },
    { lat: 49.1507876, lng: 21.2248267 },
    { lat: 49.1506956, lng: 21.2250526 },
    { lat: 49.1502856, lng: 21.2253692 },
    { lat: 49.1498918, lng: 21.2258803 },
    { lat: 49.1496446, lng: 21.2259979 },
    { lat: 49.1493247, lng: 21.2263908 },
    { lat: 49.1490176, lng: 21.2266479 },
    { lat: 49.1489139, lng: 21.2268246 },
    { lat: 49.1477183, lng: 21.227675 },
    { lat: 49.1475648, lng: 21.2279516 },
    { lat: 49.1474343, lng: 21.2279929 },
    { lat: 49.1465303, lng: 21.2289273 },
    { lat: 49.1460946, lng: 21.2295386 },
    { lat: 49.1452697, lng: 21.2300474 },
    { lat: 49.1452959, lng: 21.2301246 },
    { lat: 49.145204, lng: 21.2302491 },
    { lat: 49.1451122, lng: 21.2301565 },
    { lat: 49.1448431, lng: 21.230331 },
    { lat: 49.1446143, lng: 21.2306863 },
    { lat: 49.1442185, lng: 21.23088 },
    { lat: 49.1435281, lng: 21.2317285 },
    { lat: 49.1434665, lng: 21.2319263 },
    { lat: 49.1431163, lng: 21.2323517 },
    { lat: 49.1430254, lng: 21.2329092 },
    { lat: 49.142713, lng: 21.232923 },
    { lat: 49.1426828, lng: 21.2332903 },
    { lat: 49.14219, lng: 21.2337357 },
    { lat: 49.1420324, lng: 21.2341376 },
    { lat: 49.1417468, lng: 21.2342501 },
    { lat: 49.1415823, lng: 21.2343912 },
    { lat: 49.1415037, lng: 21.2345809 },
    { lat: 49.1415019, lng: 21.2349584 },
    { lat: 49.1413733, lng: 21.2351304 },
    { lat: 49.1410983, lng: 21.2352589 },
    { lat: 49.1410264, lng: 21.2355196 },
    { lat: 49.1408476, lng: 21.2355633 },
    { lat: 49.140728, lng: 21.235767 },
    { lat: 49.14066, lng: 21.2361927 },
    { lat: 49.1405256, lng: 21.236322 },
    { lat: 49.1406123, lng: 21.2364675 },
    { lat: 49.1411035, lng: 21.2382077 },
    { lat: 49.1402668, lng: 21.2393213 },
    { lat: 49.139595, lng: 21.2403771 },
    { lat: 49.1399026, lng: 21.2411935 },
    { lat: 49.1402603, lng: 21.2408643 },
    { lat: 49.1404248, lng: 21.2413452 },
    { lat: 49.1408564, lng: 21.2430503 },
    { lat: 49.1412355, lng: 21.2438537 },
    { lat: 49.141466, lng: 21.2447933 },
    { lat: 49.1414255, lng: 21.2452346 },
    { lat: 49.1412915, lng: 21.2456377 },
    { lat: 49.1413375, lng: 21.2459622 },
    { lat: 49.1411069, lng: 21.2464771 },
    { lat: 49.1408528, lng: 21.2464489 },
    { lat: 49.1406906, lng: 21.2467315 },
    { lat: 49.1404737, lng: 21.2468878 },
    { lat: 49.1403143, lng: 21.2472331 },
    { lat: 49.1400295, lng: 21.24745 },
    { lat: 49.1398754, lng: 21.2478516 },
    { lat: 49.139546, lng: 21.2478977 },
    { lat: 49.1390746, lng: 21.2481204 },
    { lat: 49.1388487, lng: 21.2480582 },
    { lat: 49.1386846, lng: 21.2483203 },
    { lat: 49.1384967, lng: 21.2482493 },
    { lat: 49.1382958, lng: 21.2486334 },
    { lat: 49.1375745, lng: 21.248848 },
    { lat: 49.1373386, lng: 21.2487541 },
    { lat: 49.1371697, lng: 21.2488988 },
    { lat: 49.1368582, lng: 21.2494209 },
    { lat: 49.1361378, lng: 21.2495179 },
    { lat: 49.1360207, lng: 21.2497343 },
    { lat: 49.1358268, lng: 21.2498318 },
    { lat: 49.1349985, lng: 21.2505639 },
    { lat: 49.1363999, lng: 21.2525257 },
    { lat: 49.1367249, lng: 21.2530682 },
    { lat: 49.1368774, lng: 21.2534196 },
    { lat: 49.1370159, lng: 21.2541913 },
    { lat: 49.1369573, lng: 21.2547722 },
    { lat: 49.1369796, lng: 21.2549529 },
    { lat: 49.1367763, lng: 21.2554083 },
    { lat: 49.1367225, lng: 21.2556669 },
    { lat: 49.1362688, lng: 21.2562515 },
    { lat: 49.1363279, lng: 21.2565258 },
    { lat: 49.1362396, lng: 21.2570226 },
    { lat: 49.1353022, lng: 21.2573494 },
    { lat: 49.1348307, lng: 21.2576845 },
    { lat: 49.1346805, lng: 21.2593927 },
    { lat: 49.1348555, lng: 21.2601791 },
    { lat: 49.1346399, lng: 21.2606781 },
    { lat: 49.1344194, lng: 21.2605691 },
    { lat: 49.1341347, lng: 21.2613759 },
    { lat: 49.1333369, lng: 21.2610772 },
    { lat: 49.1333308, lng: 21.2619799 },
    { lat: 49.1332047, lng: 21.2620514 },
    { lat: 49.1332623, lng: 21.262725 },
    { lat: 49.1328814, lng: 21.2628915 },
    { lat: 49.1328707, lng: 21.2634988 },
    { lat: 49.132654, lng: 21.2636971 },
    { lat: 49.1325971, lng: 21.2655542 },
    { lat: 49.1326491, lng: 21.2666616 },
    { lat: 49.131647, lng: 21.2667176 },
    { lat: 49.1312924, lng: 21.2671894 },
    { lat: 49.1312427, lng: 21.2673287 },
    { lat: 49.1310255, lng: 21.2675133 },
    { lat: 49.1309347, lng: 21.2674914 },
    { lat: 49.1307921, lng: 21.2673143 },
    { lat: 49.1304208, lng: 21.2675415 },
    { lat: 49.1301617, lng: 21.2675653 },
    { lat: 49.1294648, lng: 21.267513 },
    { lat: 49.1292908, lng: 21.2673841 },
    { lat: 49.1291177, lng: 21.2674288 },
    { lat: 49.1289709, lng: 21.2675567 },
    { lat: 49.1287169, lng: 21.2676013 },
    { lat: 49.1280774, lng: 21.2680314 },
    { lat: 49.1270939, lng: 21.2683257 },
    { lat: 49.1267283, lng: 21.2686588 },
    { lat: 49.1271937, lng: 21.2707137 },
    { lat: 49.1271934, lng: 21.2719946 },
    { lat: 49.127077, lng: 21.2722672 },
    { lat: 49.1271281, lng: 21.272504 },
    { lat: 49.1264831, lng: 21.2742739 },
    { lat: 49.1261917, lng: 21.2757837 },
    { lat: 49.1261299, lng: 21.2777479 },
    { lat: 49.1258757, lng: 21.2778252 },
  ],
  Demjata: [
    { lat: 49.113967, lng: 21.350231 },
    { lat: 49.11414, lng: 21.3504 },
    { lat: 49.114294, lng: 21.350432 },
    { lat: 49.114623, lng: 21.350306 },
    { lat: 49.114813, lng: 21.350191 },
    { lat: 49.114894, lng: 21.350188 },
    { lat: 49.11504, lng: 21.35016 },
    { lat: 49.115228, lng: 21.350244 },
    { lat: 49.115764, lng: 21.350263 },
    { lat: 49.116113, lng: 21.350047 },
    { lat: 49.116291, lng: 21.350064 },
    { lat: 49.116334, lng: 21.350023 },
    { lat: 49.116583, lng: 21.349888 },
    { lat: 49.116712, lng: 21.349867 },
    { lat: 49.116795, lng: 21.349795 },
    { lat: 49.116928, lng: 21.349617 },
    { lat: 49.117024, lng: 21.34959 },
    { lat: 49.117106, lng: 21.349603 },
    { lat: 49.117138, lng: 21.349601 },
    { lat: 49.117187, lng: 21.349593 },
    { lat: 49.117206, lng: 21.349582 },
    { lat: 49.117243, lng: 21.349543 },
    { lat: 49.117675, lng: 21.349087 },
    { lat: 49.118215, lng: 21.348662 },
    { lat: 49.118478, lng: 21.34841 },
    { lat: 49.118697, lng: 21.348029 },
    { lat: 49.119327, lng: 21.347792 },
    { lat: 49.119586, lng: 21.347655 },
    { lat: 49.119947, lng: 21.347458 },
    { lat: 49.120496, lng: 21.347221 },
    { lat: 49.12079, lng: 21.347093 },
    { lat: 49.121076, lng: 21.346933 },
    { lat: 49.121069, lng: 21.346905 },
    { lat: 49.121567, lng: 21.346594 },
    { lat: 49.121596, lng: 21.345868 },
    { lat: 49.121599, lng: 21.345708 },
    { lat: 49.121792, lng: 21.345427 },
    { lat: 49.121972, lng: 21.344918 },
    { lat: 49.122234, lng: 21.344485 },
    { lat: 49.122483, lng: 21.344204 },
    { lat: 49.122808, lng: 21.343852 },
    { lat: 49.123459, lng: 21.343417 },
    { lat: 49.123556, lng: 21.343224 },
    { lat: 49.1239, lng: 21.34294 },
    { lat: 49.124079, lng: 21.342473 },
    { lat: 49.124458, lng: 21.341972 },
    { lat: 49.124527, lng: 21.341817 },
    { lat: 49.124731, lng: 21.340606 },
    { lat: 49.124592, lng: 21.340409 },
    { lat: 49.124705, lng: 21.339978 },
    { lat: 49.124729, lng: 21.339424 },
    { lat: 49.124796, lng: 21.339272 },
    { lat: 49.124812, lng: 21.339023 },
    { lat: 49.124745, lng: 21.338707 },
    { lat: 49.124762, lng: 21.338722 },
    { lat: 49.124911, lng: 21.338389 },
    { lat: 49.125052, lng: 21.338146 },
    { lat: 49.125231, lng: 21.338006 },
    { lat: 49.125357, lng: 21.337896 },
    { lat: 49.125403, lng: 21.337812 },
    { lat: 49.125469, lng: 21.337601 },
    { lat: 49.125552, lng: 21.337393 },
    { lat: 49.125577, lng: 21.337131 },
    { lat: 49.125558, lng: 21.337128 },
    { lat: 49.12553, lng: 21.336861 },
    { lat: 49.125427, lng: 21.336485 },
    { lat: 49.125401, lng: 21.336083 },
    { lat: 49.125327, lng: 21.335705 },
    { lat: 49.125212, lng: 21.335266 },
    { lat: 49.124513, lng: 21.334209 },
    { lat: 49.124316, lng: 21.333879 },
    { lat: 49.124004, lng: 21.33344 },
    { lat: 49.123986, lng: 21.333351 },
    { lat: 49.124079, lng: 21.332888 },
    { lat: 49.124213, lng: 21.332791 },
    { lat: 49.124666, lng: 21.332672 },
    { lat: 49.124965, lng: 21.332584 },
    { lat: 49.125273, lng: 21.332489 },
    { lat: 49.125645, lng: 21.332303 },
    { lat: 49.125849, lng: 21.331952 },
    { lat: 49.125944, lng: 21.331795 },
    { lat: 49.126015, lng: 21.331568 },
    { lat: 49.126249, lng: 21.331393 },
    { lat: 49.126371, lng: 21.331169 },
    { lat: 49.126522, lng: 21.330832 },
    { lat: 49.126658, lng: 21.330711 },
    { lat: 49.126963, lng: 21.330132 },
    { lat: 49.127183, lng: 21.329866 },
    { lat: 49.127559, lng: 21.329471 },
    { lat: 49.127782, lng: 21.329417 },
    { lat: 49.127869, lng: 21.329258 },
    { lat: 49.128212, lng: 21.329089 },
    { lat: 49.12845, lng: 21.328789 },
    { lat: 49.128491, lng: 21.3286 },
    { lat: 49.128598, lng: 21.328516 },
    { lat: 49.128645, lng: 21.328366 },
    { lat: 49.128638, lng: 21.328135 },
    { lat: 49.128737, lng: 21.327755 },
    { lat: 49.128859, lng: 21.327533 },
    { lat: 49.128987, lng: 21.327459 },
    { lat: 49.129019, lng: 21.327224 },
    { lat: 49.129136, lng: 21.326841 },
    { lat: 49.12915, lng: 21.326468 },
    { lat: 49.129189, lng: 21.32615 },
    { lat: 49.129185, lng: 21.325719 },
    { lat: 49.129256, lng: 21.325538 },
    { lat: 49.129234, lng: 21.325247 },
    { lat: 49.12929, lng: 21.325074 },
    { lat: 49.129347, lng: 21.325054 },
    { lat: 49.129366, lng: 21.324715 },
    { lat: 49.12948, lng: 21.324395 },
    { lat: 49.129531, lng: 21.324358 },
    { lat: 49.129538, lng: 21.324088 },
    { lat: 49.12958, lng: 21.323921 },
    { lat: 49.129569, lng: 21.323695 },
    { lat: 49.12957, lng: 21.323119 },
    { lat: 49.129686, lng: 21.322861 },
    { lat: 49.129898, lng: 21.322678 },
    { lat: 49.129984, lng: 21.322384 },
    { lat: 49.130082, lng: 21.322305 },
    { lat: 49.130223, lng: 21.321887 },
    { lat: 49.130417, lng: 21.321548 },
    { lat: 49.130445, lng: 21.321133 },
    { lat: 49.130624, lng: 21.32109 },
    { lat: 49.130718, lng: 21.32081 },
    { lat: 49.130697, lng: 21.320463 },
    { lat: 49.130939, lng: 21.320381 },
    { lat: 49.131076, lng: 21.320013 },
    { lat: 49.13106, lng: 21.319929 },
    { lat: 49.130952, lng: 21.319782 },
    { lat: 49.131165, lng: 21.319633 },
    { lat: 49.131301, lng: 21.319409 },
    { lat: 49.131423, lng: 21.319626 },
    { lat: 49.131646, lng: 21.319337 },
    { lat: 49.131672, lng: 21.318974 },
    { lat: 49.131764, lng: 21.31886 },
    { lat: 49.131812, lng: 21.318583 },
    { lat: 49.131851, lng: 21.318306 },
    { lat: 49.131811, lng: 21.318175 },
    { lat: 49.131847, lng: 21.318149 },
    { lat: 49.131881, lng: 21.318048 },
    { lat: 49.132252, lng: 21.317739 },
    { lat: 49.132263, lng: 21.317675 },
    { lat: 49.132302, lng: 21.317626 },
    { lat: 49.132349, lng: 21.317498 },
    { lat: 49.132453, lng: 21.317243 },
    { lat: 49.132471, lng: 21.317183 },
    { lat: 49.132373, lng: 21.317152 },
    { lat: 49.132143, lng: 21.317055 },
    { lat: 49.131785, lng: 21.316927 },
    { lat: 49.13163, lng: 21.316903 },
    { lat: 49.131321, lng: 21.316775 },
    { lat: 49.131066, lng: 21.316937 },
    { lat: 49.130806, lng: 21.317155 },
    { lat: 49.130657, lng: 21.317281 },
    { lat: 49.130576, lng: 21.317455 },
    { lat: 49.130335, lng: 21.317344 },
    { lat: 49.13016, lng: 21.316966 },
    { lat: 49.130238, lng: 21.316148 },
    { lat: 49.130282, lng: 21.316085 },
    { lat: 49.13033, lng: 21.316024 },
    { lat: 49.130405, lng: 21.315926 },
    { lat: 49.130097, lng: 21.315431 },
    { lat: 49.12989, lng: 21.315128 },
    { lat: 49.129458, lng: 21.314495 },
    { lat: 49.12955, lng: 21.314295 },
    { lat: 49.129583, lng: 21.314254 },
    { lat: 49.129728, lng: 21.314077 },
    { lat: 49.129651, lng: 21.313997 },
    { lat: 49.129509, lng: 21.313959 },
    { lat: 49.129478, lng: 21.313975 },
    { lat: 49.129396, lng: 21.31402 },
    { lat: 49.129246, lng: 21.314155 },
    { lat: 49.12889, lng: 21.31362 },
    { lat: 49.128865, lng: 21.313447 },
    { lat: 49.128895, lng: 21.313255 },
    { lat: 49.128715, lng: 21.312424 },
    { lat: 49.128702, lng: 21.312151 },
    { lat: 49.128716, lng: 21.311913 },
    { lat: 49.128723, lng: 21.311863 },
    { lat: 49.128749, lng: 21.311709 },
    { lat: 49.128385, lng: 21.311161 },
    { lat: 49.128194, lng: 21.311024 },
    { lat: 49.127719, lng: 21.310861 },
    { lat: 49.127494, lng: 21.310688 },
    { lat: 49.127285, lng: 21.310477 },
    { lat: 49.12709, lng: 21.310417 },
    { lat: 49.126915, lng: 21.310326 },
    { lat: 49.126652, lng: 21.310332 },
    { lat: 49.126448, lng: 21.310459 },
    { lat: 49.126173, lng: 21.310536 },
    { lat: 49.125277, lng: 21.311018 },
    { lat: 49.124868, lng: 21.311238 },
    { lat: 49.124449, lng: 21.311287 },
    { lat: 49.124304, lng: 21.311396 },
    { lat: 49.124102, lng: 21.311555 },
    { lat: 49.123831, lng: 21.31177 },
    { lat: 49.123612, lng: 21.311762 },
    { lat: 49.123359, lng: 21.311754 },
    { lat: 49.123193, lng: 21.311668 },
    { lat: 49.122788, lng: 21.311143 },
    { lat: 49.122563, lng: 21.310707 },
    { lat: 49.122388, lng: 21.310533 },
    { lat: 49.122208, lng: 21.310352 },
    { lat: 49.122189, lng: 21.310182 },
    { lat: 49.122196, lng: 21.310169 },
    { lat: 49.122206, lng: 21.310121 },
    { lat: 49.122214, lng: 21.310086 },
    { lat: 49.122221, lng: 21.310028 },
    { lat: 49.12192, lng: 21.310021 },
    { lat: 49.121451, lng: 21.310177 },
    { lat: 49.121013, lng: 21.310377 },
    { lat: 49.120678, lng: 21.310631 },
    { lat: 49.120213, lng: 21.310944 },
    { lat: 49.11988, lng: 21.311087 },
    { lat: 49.119678, lng: 21.311122 },
    { lat: 49.11966, lng: 21.311049 },
    { lat: 49.119456, lng: 21.310923 },
    { lat: 49.119311, lng: 21.310933 },
    { lat: 49.119197, lng: 21.310815 },
    { lat: 49.119239, lng: 21.310623 },
    { lat: 49.119264, lng: 21.310416 },
    { lat: 49.119284, lng: 21.310405 },
    { lat: 49.119502, lng: 21.310243 },
    { lat: 49.119582, lng: 21.310118 },
    { lat: 49.11987, lng: 21.309776 },
    { lat: 49.12012, lng: 21.309533 },
    { lat: 49.120489, lng: 21.309269 },
    { lat: 49.120484, lng: 21.309211 },
    { lat: 49.120624, lng: 21.309113 },
    { lat: 49.120769, lng: 21.308765 },
    { lat: 49.121107, lng: 21.308423 },
    { lat: 49.121278, lng: 21.308158 },
    { lat: 49.121643, lng: 21.308113 },
    { lat: 49.121774, lng: 21.307939 },
    { lat: 49.12187, lng: 21.307628 },
    { lat: 49.122203, lng: 21.307487 },
    { lat: 49.122564, lng: 21.306945 },
    { lat: 49.12273, lng: 21.306555 },
    { lat: 49.122967, lng: 21.305919 },
    { lat: 49.123302, lng: 21.305308 },
    { lat: 49.12376, lng: 21.30432 },
    { lat: 49.1189899, lng: 21.300857 },
    { lat: 49.1193852, lng: 21.3021321 },
    { lat: 49.11945, lng: 21.3026348 },
    { lat: 49.1184462, lng: 21.3030778 },
    { lat: 49.1184074, lng: 21.3034318 },
    { lat: 49.1183609, lng: 21.3034943 },
    { lat: 49.1178909, lng: 21.3037307 },
    { lat: 49.1173736, lng: 21.2995412 },
    { lat: 49.1171585, lng: 21.2990883 },
    { lat: 49.1170459, lng: 21.2986378 },
    { lat: 49.1168291, lng: 21.2975374 },
    { lat: 49.116911, lng: 21.2973466 },
    { lat: 49.1167448, lng: 21.2964194 },
    { lat: 49.1163406, lng: 21.2964507 },
    { lat: 49.1161329, lng: 21.2968773 },
    { lat: 49.1151611, lng: 21.2967807 },
    { lat: 49.1143266, lng: 21.2964623 },
    { lat: 49.1133468, lng: 21.2967291 },
    { lat: 49.1134236, lng: 21.295996 },
    { lat: 49.1135297, lng: 21.2958951 },
    { lat: 49.1139047, lng: 21.2951216 },
    { lat: 49.1134544, lng: 21.2950064 },
    { lat: 49.1126467, lng: 21.2949777 },
    { lat: 49.1124637, lng: 21.2950233 },
    { lat: 49.1121667, lng: 21.294434 },
    { lat: 49.1124948, lng: 21.2930787 },
    { lat: 49.1132869, lng: 21.2890723 },
    { lat: 49.1132112, lng: 21.2888793 },
    { lat: 49.112445, lng: 21.2877337 },
    { lat: 49.1114116, lng: 21.2886452 },
    { lat: 49.1106188, lng: 21.2894804 },
    { lat: 49.1101758, lng: 21.2898604 },
    { lat: 49.1076632, lng: 21.2913741 },
    { lat: 49.107523, lng: 21.2903206 },
    { lat: 49.1075734, lng: 21.2896056 },
    { lat: 49.1074304, lng: 21.2894946 },
    { lat: 49.1062031, lng: 21.2893142 },
    { lat: 49.1060133, lng: 21.2887408 },
    { lat: 49.1059131, lng: 21.2886239 },
    { lat: 49.105204, lng: 21.2888779 },
    { lat: 49.104345, lng: 21.2871931 },
    { lat: 49.104334, lng: 21.2870542 },
    { lat: 49.1039532, lng: 21.2866271 },
    { lat: 49.1038959, lng: 21.2864128 },
    { lat: 49.1035495, lng: 21.2868225 },
    { lat: 49.1033171, lng: 21.2868389 },
    { lat: 49.1030722, lng: 21.2867406 },
    { lat: 49.1027131, lng: 21.2869192 },
    { lat: 49.1025772, lng: 21.2868933 },
    { lat: 49.1024039, lng: 21.2869715 },
    { lat: 49.1019988, lng: 21.2873739 },
    { lat: 49.1001748, lng: 21.2883794 },
    { lat: 49.0999928, lng: 21.2886048 },
    { lat: 49.0999413, lng: 21.2890196 },
    { lat: 49.0997115, lng: 21.289351 },
    { lat: 49.0994031, lng: 21.2892715 },
    { lat: 49.0991926, lng: 21.2894127 },
    { lat: 49.0988205, lng: 21.2898713 },
    { lat: 49.0983682, lng: 21.290096 },
    { lat: 49.0981179, lng: 21.2905646 },
    { lat: 49.0974957, lng: 21.2911567 },
    { lat: 49.0972374, lng: 21.2915792 },
    { lat: 49.0970366, lng: 21.2920799 },
    { lat: 49.0970328, lng: 21.2922166 },
    { lat: 49.0966015, lng: 21.292317 },
    { lat: 49.0964051, lng: 21.292552 },
    { lat: 49.0963042, lng: 21.2931267 },
    { lat: 49.0961357, lng: 21.2932786 },
    { lat: 49.0958241, lng: 21.2940266 },
    { lat: 49.0955257, lng: 21.2941316 },
    { lat: 49.095465, lng: 21.2942066 },
    { lat: 49.0952792, lng: 21.2942746 },
    { lat: 49.0955027, lng: 21.2943382 },
    { lat: 49.0956443, lng: 21.2944819 },
    { lat: 49.0957415, lng: 21.2948263 },
    { lat: 49.0964593, lng: 21.29597 },
    { lat: 49.0966971, lng: 21.2962086 },
    { lat: 49.0971528, lng: 21.2964293 },
    { lat: 49.0975466, lng: 21.2967291 },
    { lat: 49.0981001, lng: 21.2977969 },
    { lat: 49.0987833, lng: 21.2982146 },
    { lat: 49.0988815, lng: 21.2984497 },
    { lat: 49.0994499, lng: 21.2988985 },
    { lat: 49.0997505, lng: 21.2992806 },
    { lat: 49.1003569, lng: 21.3003928 },
    { lat: 49.1017034, lng: 21.3034271 },
    { lat: 49.1009127, lng: 21.3046241 },
    { lat: 49.1007897, lng: 21.3047286 },
    { lat: 49.1001703, lng: 21.305078 },
    { lat: 49.0998253, lng: 21.3054663 },
    { lat: 49.0992112, lng: 21.3056279 },
    { lat: 49.0984156, lng: 21.3057339 },
    { lat: 49.0980485, lng: 21.3059069 },
    { lat: 49.0974242, lng: 21.3068846 },
    { lat: 49.0976435, lng: 21.3069558 },
    { lat: 49.0975613, lng: 21.3072158 },
    { lat: 49.0974728, lng: 21.3078999 },
    { lat: 49.0969316, lng: 21.3097223 },
    { lat: 49.0973037, lng: 21.3101918 },
    { lat: 49.0973955, lng: 21.3105527 },
    { lat: 49.0974135, lng: 21.3108143 },
    { lat: 49.0976782, lng: 21.3114919 },
    { lat: 49.0975124, lng: 21.3125208 },
    { lat: 49.0974622, lng: 21.3132485 },
    { lat: 49.0972506, lng: 21.3142544 },
    { lat: 49.0972352, lng: 21.3141499 },
    { lat: 49.0969385, lng: 21.3147573 },
    { lat: 49.0970068, lng: 21.3157543 },
    { lat: 49.0971929, lng: 21.3162957 },
    { lat: 49.0971846, lng: 21.3165771 },
    { lat: 49.0972598, lng: 21.3171697 },
    { lat: 49.0973084, lng: 21.3174751 },
    { lat: 49.0974452, lng: 21.317798 },
    { lat: 49.0974448, lng: 21.3184178 },
    { lat: 49.0976032, lng: 21.3192717 },
    { lat: 49.0980056, lng: 21.3196971 },
    { lat: 49.098274, lng: 21.3202812 },
    { lat: 49.0982306, lng: 21.3211169 },
    { lat: 49.0982892, lng: 21.3211225 },
    { lat: 49.098224, lng: 21.3212743 },
    { lat: 49.0983091, lng: 21.3241829 },
    { lat: 49.0985362, lng: 21.3245434 },
    { lat: 49.1006515, lng: 21.3293727 },
    { lat: 49.1008936, lng: 21.3299825 },
    { lat: 49.1010091, lng: 21.3304517 },
    { lat: 49.1010162, lng: 21.3310275 },
    { lat: 49.100953, lng: 21.3320298 },
    { lat: 49.1006085, lng: 21.3351735 },
    { lat: 49.100698, lng: 21.3351256 },
    { lat: 49.1008061, lng: 21.3359867 },
    { lat: 49.1013058, lng: 21.337003 },
    { lat: 49.1014983, lng: 21.337683 },
    { lat: 49.1020838, lng: 21.3427343 },
    { lat: 49.1023267, lng: 21.3432151 },
    { lat: 49.1027439, lng: 21.3435887 },
    { lat: 49.1029752, lng: 21.3439142 },
    { lat: 49.1036393, lng: 21.3438789 },
    { lat: 49.1052599, lng: 21.3443055 },
    { lat: 49.1059439, lng: 21.3442965 },
    { lat: 49.1065873, lng: 21.3441068 },
    { lat: 49.107343, lng: 21.3437121 },
    { lat: 49.1078189, lng: 21.3436784 },
    { lat: 49.108184, lng: 21.3437662 },
    { lat: 49.1085687, lng: 21.3439916 },
    { lat: 49.1089102, lng: 21.3444233 },
    { lat: 49.109083, lng: 21.3448627 },
    { lat: 49.1092794, lng: 21.3454449 },
    { lat: 49.1095365, lng: 21.3464823 },
    { lat: 49.1099981, lng: 21.3473172 },
    { lat: 49.1102879, lng: 21.3476807 },
    { lat: 49.1110287, lng: 21.3492366 },
    { lat: 49.1116566, lng: 21.3496158 },
    { lat: 49.1124027, lng: 21.3499183 },
    { lat: 49.1129383, lng: 21.3499624 },
    { lat: 49.1134265, lng: 21.3498003 },
    { lat: 49.113967, lng: 21.350231 },
  ],
  SucháDolina: [
    { lat: 48.9360534, lng: 21.1515194 },
    { lat: 48.936072, lng: 21.1515321 },
    { lat: 48.936057, lng: 21.1512006 },
    { lat: 48.936283, lng: 21.1508763 },
    { lat: 48.9362274, lng: 21.1503996 },
    { lat: 48.9362767, lng: 21.1501769 },
    { lat: 48.9365398, lng: 21.149745 },
    { lat: 48.9366393, lng: 21.149654 },
    { lat: 48.936724, lng: 21.1496983 },
    { lat: 48.9368532, lng: 21.1493348 },
    { lat: 48.9370363, lng: 21.1491142 },
    { lat: 48.9371846, lng: 21.1486427 },
    { lat: 48.9373689, lng: 21.148462 },
    { lat: 48.9374537, lng: 21.1482565 },
    { lat: 48.9374673, lng: 21.1481209 },
    { lat: 48.9373847, lng: 21.1479623 },
    { lat: 48.9374089, lng: 21.1478703 },
    { lat: 48.937572, lng: 21.1476962 },
    { lat: 48.9376552, lng: 21.1474811 },
    { lat: 48.9376363, lng: 21.1470253 },
    { lat: 48.9377442, lng: 21.1469834 },
    { lat: 48.93781, lng: 21.1460111 },
    { lat: 48.9379115, lng: 21.1458358 },
    { lat: 48.9380201, lng: 21.145429 },
    { lat: 48.9378815, lng: 21.1451703 },
    { lat: 48.9379511, lng: 21.1446383 },
    { lat: 48.9380835, lng: 21.1444092 },
    { lat: 48.9381458, lng: 21.143712 },
    { lat: 48.938229, lng: 21.1436461 },
    { lat: 48.9383647, lng: 21.1436634 },
    { lat: 48.9384581, lng: 21.1434468 },
    { lat: 48.9386293, lng: 21.1432764 },
    { lat: 48.9390329, lng: 21.1425524 },
    { lat: 48.9391434, lng: 21.1424509 },
    { lat: 48.9394518, lng: 21.1414994 },
    { lat: 48.9396069, lng: 21.1404284 },
    { lat: 48.9399594, lng: 21.1389116 },
    { lat: 48.9399454, lng: 21.138366 },
    { lat: 48.9400857, lng: 21.1372249 },
    { lat: 48.9400364, lng: 21.136311 },
    { lat: 48.9399199, lng: 21.1360424 },
    { lat: 48.9398382, lng: 21.13553 },
    { lat: 48.9395082, lng: 21.1350577 },
    { lat: 48.9394232, lng: 21.1346473 },
    { lat: 48.9391513, lng: 21.1342398 },
    { lat: 48.9390328, lng: 21.1336957 },
    { lat: 48.9388713, lng: 21.1335404 },
    { lat: 48.93815, lng: 21.1332933 },
    { lat: 48.9377085, lng: 21.1329663 },
    { lat: 48.9369895, lng: 21.1350945 },
    { lat: 48.9367476, lng: 21.1353033 },
    { lat: 48.9361196, lng: 21.1350229 },
    { lat: 48.9355535, lng: 21.1343821 },
    { lat: 48.934746, lng: 21.1338006 },
    { lat: 48.9344414, lng: 21.1332481 },
    { lat: 48.9343066, lng: 21.1328653 },
    { lat: 48.9336688, lng: 21.1319213 },
    { lat: 48.9330088, lng: 21.131237 },
    { lat: 48.9325388, lng: 21.1315242 },
    { lat: 48.9320867, lng: 21.1321766 },
    { lat: 48.9320637, lng: 21.1326901 },
    { lat: 48.9311419, lng: 21.133782 },
    { lat: 48.9304274, lng: 21.1354515 },
    { lat: 48.9302631, lng: 21.1348451 },
    { lat: 48.9299846, lng: 21.1346539 },
    { lat: 48.9297798, lng: 21.1358207 },
    { lat: 48.9276292, lng: 21.1343058 },
    { lat: 48.9266686, lng: 21.1330073 },
    { lat: 48.9218641, lng: 21.1314518 },
    { lat: 48.9201218, lng: 21.1317645 },
    { lat: 48.9195513, lng: 21.1312009 },
    { lat: 48.9188313, lng: 21.1303638 },
    { lat: 48.9171228, lng: 21.1293308 },
    { lat: 48.9159675, lng: 21.1293816 },
    { lat: 48.9158701, lng: 21.1289365 },
    { lat: 48.9157332, lng: 21.1289204 },
    { lat: 48.9098172, lng: 21.1316442 },
    { lat: 48.9082936, lng: 21.1333108 },
    { lat: 48.9081932, lng: 21.1345427 },
    { lat: 48.9081654, lng: 21.1365013 },
    { lat: 48.9079322, lng: 21.1363821 },
    { lat: 48.9079247, lng: 21.1380598 },
    { lat: 48.9078145, lng: 21.1383469 },
    { lat: 48.9077023, lng: 21.1389562 },
    { lat: 48.9076562, lng: 21.1397781 },
    { lat: 48.9086773, lng: 21.1405996 },
    { lat: 48.9086466, lng: 21.1410898 },
    { lat: 48.9085849, lng: 21.141305 },
    { lat: 48.9044829, lng: 21.140638 },
    { lat: 48.9037174, lng: 21.1397789 },
    { lat: 48.9030004, lng: 21.1393513 },
    { lat: 48.9025919, lng: 21.1392753 },
    { lat: 48.9019815, lng: 21.1388074 },
    { lat: 48.9016353, lng: 21.1387458 },
    { lat: 48.9013564, lng: 21.1382622 },
    { lat: 48.9008224, lng: 21.1378867 },
    { lat: 48.900572, lng: 21.13727 },
    { lat: 48.900397, lng: 21.137195 },
    { lat: 48.900316, lng: 21.137313 },
    { lat: 48.900287, lng: 21.13759 },
    { lat: 48.900177, lng: 21.137715 },
    { lat: 48.89998, lng: 21.137563 },
    { lat: 48.899912, lng: 21.137794 },
    { lat: 48.899882, lng: 21.137931 },
    { lat: 48.899839, lng: 21.138036 },
    { lat: 48.899687, lng: 21.137986 },
    { lat: 48.899629, lng: 21.138055 },
    { lat: 48.899511, lng: 21.138218 },
    { lat: 48.899413, lng: 21.138514 },
    { lat: 48.899552, lng: 21.138641 },
    { lat: 48.899662, lng: 21.138728 },
    { lat: 48.899694, lng: 21.138817 },
    { lat: 48.899487, lng: 21.13918 },
    { lat: 48.899393, lng: 21.13917 },
    { lat: 48.899242, lng: 21.139202 },
    { lat: 48.899237, lng: 21.139316 },
    { lat: 48.899198, lng: 21.139405 },
    { lat: 48.899152, lng: 21.139483 },
    { lat: 48.899117, lng: 21.139574 },
    { lat: 48.898946, lng: 21.139854 },
    { lat: 48.89904, lng: 21.140068 },
    { lat: 48.898932, lng: 21.140068 },
    { lat: 48.898912, lng: 21.140064 },
    { lat: 48.898894, lng: 21.140132 },
    { lat: 48.898846, lng: 21.140277 },
    { lat: 48.898653, lng: 21.14052 },
    { lat: 48.898625, lng: 21.140627 },
    { lat: 48.898479, lng: 21.140496 },
    { lat: 48.898447, lng: 21.140578 },
    { lat: 48.898416, lng: 21.140808 },
    { lat: 48.898278, lng: 21.141049 },
    { lat: 48.898107, lng: 21.141218 },
    { lat: 48.898149, lng: 21.141383 },
    { lat: 48.89811, lng: 21.141554 },
    { lat: 48.898037, lng: 21.141714 },
    { lat: 48.897947, lng: 21.141704 },
    { lat: 48.897833, lng: 21.141656 },
    { lat: 48.897798, lng: 21.1418 },
    { lat: 48.897785, lng: 21.141867 },
    { lat: 48.897841, lng: 21.141978 },
    { lat: 48.898023, lng: 21.142188 },
    { lat: 48.898073, lng: 21.142207 },
    { lat: 48.898113, lng: 21.142389 },
    { lat: 48.8981, lng: 21.142418 },
    { lat: 48.897983, lng: 21.142408 },
    { lat: 48.897766, lng: 21.142465 },
    { lat: 48.897769, lng: 21.14329 },
    { lat: 48.897779, lng: 21.143329 },
    { lat: 48.897803, lng: 21.143408 },
    { lat: 48.897847, lng: 21.143516 },
    { lat: 48.897941, lng: 21.14389 },
    { lat: 48.897973, lng: 21.143959 },
    { lat: 48.89801, lng: 21.144047 },
    { lat: 48.89806, lng: 21.144116 },
    { lat: 48.898041, lng: 21.144144 },
    { lat: 48.898008, lng: 21.144443 },
    { lat: 48.897955, lng: 21.14456 },
    { lat: 48.89799, lng: 21.14463 },
    { lat: 48.898045, lng: 21.144669 },
    { lat: 48.89794, lng: 21.145047 },
    { lat: 48.897751, lng: 21.144926 },
    { lat: 48.897724, lng: 21.144909 },
    { lat: 48.897665, lng: 21.144966 },
    { lat: 48.89763, lng: 21.145121 },
    { lat: 48.897723, lng: 21.145355 },
    { lat: 48.897518, lng: 21.145525 },
    { lat: 48.897462, lng: 21.14557 },
    { lat: 48.897261, lng: 21.145736 },
    { lat: 48.897272, lng: 21.14579 },
    { lat: 48.897199, lng: 21.145822 },
    { lat: 48.897047, lng: 21.14599 },
    { lat: 48.897041, lng: 21.146022 },
    { lat: 48.897093, lng: 21.146255 },
    { lat: 48.897101, lng: 21.146325 },
    { lat: 48.896865, lng: 21.146667 },
    { lat: 48.896858, lng: 21.146692 },
    { lat: 48.896897, lng: 21.146888 },
    { lat: 48.896966, lng: 21.146962 },
    { lat: 48.896877, lng: 21.14725 },
    { lat: 48.896915, lng: 21.147443 },
    { lat: 48.896888, lng: 21.147501 },
    { lat: 48.896796, lng: 21.147578 },
    { lat: 48.896648, lng: 21.147512 },
    { lat: 48.896585, lng: 21.147408 },
    { lat: 48.896561, lng: 21.147392 },
    { lat: 48.896501, lng: 21.147402 },
    { lat: 48.896459, lng: 21.147415 },
    { lat: 48.896387, lng: 21.147708 },
    { lat: 48.896375, lng: 21.147866 },
    { lat: 48.896371, lng: 21.148027 },
    { lat: 48.896361, lng: 21.148187 },
    { lat: 48.896347, lng: 21.148371 },
    { lat: 48.896361, lng: 21.148504 },
    { lat: 48.896357, lng: 21.148687 },
    { lat: 48.89638, lng: 21.149051 },
    { lat: 48.89642, lng: 21.14906 },
    { lat: 48.896552, lng: 21.149181 },
    { lat: 48.89665, lng: 21.149317 },
    { lat: 48.89676, lng: 21.149568 },
    { lat: 48.896661, lng: 21.14971 },
    { lat: 48.896484, lng: 21.149699 },
    { lat: 48.896342, lng: 21.149729 },
    { lat: 48.896309, lng: 21.149747 },
    { lat: 48.896388, lng: 21.149982 },
    { lat: 48.896596, lng: 21.15029 },
    { lat: 48.896659, lng: 21.150448 },
    { lat: 48.896634, lng: 21.1507 },
    { lat: 48.8964917, lng: 21.1508873 },
    { lat: 48.896402, lng: 21.150986 },
    { lat: 48.8962817, lng: 21.1510431 },
    { lat: 48.896192, lng: 21.151104 },
    { lat: 48.896181, lng: 21.151126 },
    { lat: 48.8961547, lng: 21.1512631 },
    { lat: 48.896082, lng: 21.151351 },
    { lat: 48.896026, lng: 21.15136 },
    { lat: 48.895904, lng: 21.151297 },
    { lat: 48.8957338, lng: 21.1514393 },
    { lat: 48.895687, lng: 21.151633 },
    { lat: 48.895803, lng: 21.151885 },
    { lat: 48.89586, lng: 21.151895 },
    { lat: 48.895932, lng: 21.151887 },
    { lat: 48.896009, lng: 21.151744 },
    { lat: 48.89611, lng: 21.151777 },
    { lat: 48.8961141, lng: 21.1517922 },
    { lat: 48.8960331, lng: 21.1521125 },
    { lat: 48.895976, lng: 21.152342 },
    { lat: 48.895786, lng: 21.152509 },
    { lat: 48.895697, lng: 21.152636 },
    { lat: 48.895587, lng: 21.152791 },
    { lat: 48.895577, lng: 21.152821 },
    { lat: 48.895542, lng: 21.153048 },
    { lat: 48.895516, lng: 21.153197 },
    { lat: 48.895442, lng: 21.153557 },
    { lat: 48.895445, lng: 21.153637 },
    { lat: 48.895472, lng: 21.153866 },
    { lat: 48.895464, lng: 21.153897 },
    { lat: 48.895446, lng: 21.153926 },
    { lat: 48.895326, lng: 21.154014 },
    { lat: 48.89532, lng: 21.154063 },
    { lat: 48.895331, lng: 21.154173 },
    { lat: 48.895463, lng: 21.154185 },
    { lat: 48.89552, lng: 21.154283 },
    { lat: 48.89547, lng: 21.154505 },
    { lat: 48.8963023, lng: 21.1546056 },
    { lat: 48.897575, lng: 21.1544267 },
    { lat: 48.8980384, lng: 21.1541733 },
    { lat: 48.8987474, lng: 21.1542794 },
    { lat: 48.8996771, lng: 21.154178 },
    { lat: 48.9001279, lng: 21.154377 },
    { lat: 48.9008857, lng: 21.1545661 },
    { lat: 48.9011512, lng: 21.1548089 },
    { lat: 48.9018662, lng: 21.1549282 },
    { lat: 48.9020208, lng: 21.1552961 },
    { lat: 48.9022365, lng: 21.1552875 },
    { lat: 48.9026299, lng: 21.1560197 },
    { lat: 48.9029847, lng: 21.1563133 },
    { lat: 48.9040431, lng: 21.1559351 },
    { lat: 48.9048122, lng: 21.1542084 },
    { lat: 48.9058638, lng: 21.1526225 },
    { lat: 48.9079283, lng: 21.1564112 },
    { lat: 48.9082493, lng: 21.1555994 },
    { lat: 48.9082778, lng: 21.1544739 },
    { lat: 48.9083745, lng: 21.1538991 },
    { lat: 48.9085522, lng: 21.1534354 },
    { lat: 48.9087983, lng: 21.1531041 },
    { lat: 48.9097776, lng: 21.1524005 },
    { lat: 48.9101195, lng: 21.1520708 },
    { lat: 48.9107842, lng: 21.1512062 },
    { lat: 48.9109012, lng: 21.1513111 },
    { lat: 48.9117875, lng: 21.1514025 },
    { lat: 48.9123565, lng: 21.1511774 },
    { lat: 48.9127618, lng: 21.1512874 },
    { lat: 48.9128322, lng: 21.1514154 },
    { lat: 48.9132835, lng: 21.151093 },
    { lat: 48.9150885, lng: 21.150171 },
    { lat: 48.9152904, lng: 21.1508947 },
    { lat: 48.9157206, lng: 21.1506887 },
    { lat: 48.9174245, lng: 21.1496245 },
    { lat: 48.9180858, lng: 21.1496796 },
    { lat: 48.9186488, lng: 21.1502254 },
    { lat: 48.9188345, lng: 21.1501911 },
    { lat: 48.9190137, lng: 21.1503004 },
    { lat: 48.9190344, lng: 21.1503897 },
    { lat: 48.919143, lng: 21.1502673 },
    { lat: 48.9192323, lng: 21.150278 },
    { lat: 48.919777, lng: 21.1497129 },
    { lat: 48.9201909, lng: 21.1493861 },
    { lat: 48.9204937, lng: 21.149384 },
    { lat: 48.9210558, lng: 21.1491515 },
    { lat: 48.9213577, lng: 21.1490919 },
    { lat: 48.9219321, lng: 21.1485683 },
    { lat: 48.9220833, lng: 21.1480209 },
    { lat: 48.9221924, lng: 21.1479796 },
    { lat: 48.9227595, lng: 21.1480438 },
    { lat: 48.9231514, lng: 21.1486618 },
    { lat: 48.9231754, lng: 21.1490613 },
    { lat: 48.9230928, lng: 21.1494001 },
    { lat: 48.9230286, lng: 21.1506423 },
    { lat: 48.9230561, lng: 21.1509745 },
    { lat: 48.9232046, lng: 21.1513298 },
    { lat: 48.9236639, lng: 21.1530405 },
    { lat: 48.9240561, lng: 21.1522049 },
    { lat: 48.9243414, lng: 21.1520116 },
    { lat: 48.9246242, lng: 21.1519888 },
    { lat: 48.9247533, lng: 21.1517994 },
    { lat: 48.9248771, lng: 21.1517242 },
    { lat: 48.9251394, lng: 21.1517272 },
    { lat: 48.9253352, lng: 21.1515118 },
    { lat: 48.925581, lng: 21.151851 },
    { lat: 48.9259149, lng: 21.1517014 },
    { lat: 48.9271042, lng: 21.1507643 },
    { lat: 48.9276229, lng: 21.1515421 },
    { lat: 48.927914, lng: 21.1516408 },
    { lat: 48.9288476, lng: 21.1533303 },
    { lat: 48.9289731, lng: 21.1538094 },
    { lat: 48.9294772, lng: 21.1541649 },
    { lat: 48.9297905, lng: 21.1540035 },
    { lat: 48.9303184, lng: 21.1539335 },
    { lat: 48.9306419, lng: 21.1537378 },
    { lat: 48.930821, lng: 21.153564 },
    { lat: 48.9310722, lng: 21.1530353 },
    { lat: 48.9315619, lng: 21.1526517 },
    { lat: 48.9317412, lng: 21.1522356 },
    { lat: 48.9319753, lng: 21.152375 },
    { lat: 48.9321268, lng: 21.1527598 },
    { lat: 48.9322549, lng: 21.1527526 },
    { lat: 48.9324547, lng: 21.1524853 },
    { lat: 48.9328235, lng: 21.1523285 },
    { lat: 48.9333901, lng: 21.1517456 },
    { lat: 48.9348364, lng: 21.1511132 },
    { lat: 48.9354722, lng: 21.150183 },
    { lat: 48.9355155, lng: 21.150521 },
    { lat: 48.9358448, lng: 21.1510751 },
    { lat: 48.9359178, lng: 21.1514288 },
    { lat: 48.9360534, lng: 21.1515194 },
  ],
  Kvačany: [
    { lat: 48.9519741, lng: 21.0597352 },
    { lat: 48.9519496, lng: 21.059741 },
    { lat: 48.9512202, lng: 21.0610879 },
    { lat: 48.9509649, lng: 21.0613386 },
    { lat: 48.9506852, lng: 21.0613885 },
    { lat: 48.9509429, lng: 21.0609002 },
    { lat: 48.9509617, lng: 21.0602306 },
    { lat: 48.9510554, lng: 21.0597896 },
    { lat: 48.9505234, lng: 21.059732 },
    { lat: 48.9498227, lng: 21.0599574 },
    { lat: 48.9496587, lng: 21.0600703 },
    { lat: 48.9494692, lng: 21.0605019 },
    { lat: 48.9487769, lng: 21.0616483 },
    { lat: 48.948058, lng: 21.0631433 },
    { lat: 48.9480411, lng: 21.064083 },
    { lat: 48.9478899, lng: 21.0646586 },
    { lat: 48.9475146, lng: 21.0652663 },
    { lat: 48.9472518, lng: 21.0650747 },
    { lat: 48.9467386, lng: 21.0641069 },
    { lat: 48.9466813, lng: 21.0637653 },
    { lat: 48.9466131, lng: 21.063706 },
    { lat: 48.9462678, lng: 21.0647508 },
    { lat: 48.9438634, lng: 21.0633902 },
    { lat: 48.9432934, lng: 21.0633311 },
    { lat: 48.9430558, lng: 21.0632264 },
    { lat: 48.9429877, lng: 21.0625165 },
    { lat: 48.9417647, lng: 21.0631854 },
    { lat: 48.9415979, lng: 21.062973 },
    { lat: 48.9413763, lng: 21.0630426 },
    { lat: 48.9411808, lng: 21.0632967 },
    { lat: 48.9411985, lng: 21.0634937 },
    { lat: 48.9410313, lng: 21.063648 },
    { lat: 48.9409707, lng: 21.0638655 },
    { lat: 48.9410592, lng: 21.0645789 },
    { lat: 48.9409334, lng: 21.0654571 },
    { lat: 48.9408661, lng: 21.0666425 },
    { lat: 48.9408125, lng: 21.0669659 },
    { lat: 48.9405884, lng: 21.0676946 },
    { lat: 48.9405345, lng: 21.0681183 },
    { lat: 48.9406644, lng: 21.0684447 },
    { lat: 48.9405268, lng: 21.068987 },
    { lat: 48.9404778, lng: 21.0697045 },
    { lat: 48.9405277, lng: 21.0700584 },
    { lat: 48.9403692, lng: 21.0702757 },
    { lat: 48.940435, lng: 21.0711272 },
    { lat: 48.9401014, lng: 21.0722608 },
    { lat: 48.940051, lng: 21.0727235 },
    { lat: 48.9399478, lng: 21.0729377 },
    { lat: 48.9399233, lng: 21.0732224 },
    { lat: 48.9397072, lng: 21.0741306 },
    { lat: 48.9391962, lng: 21.0737477 },
    { lat: 48.939041, lng: 21.0735051 },
    { lat: 48.9388767, lng: 21.073648 },
    { lat: 48.9390223, lng: 21.0743216 },
    { lat: 48.9384359, lng: 21.0747025 },
    { lat: 48.9377672, lng: 21.0748687 },
    { lat: 48.9371851, lng: 21.0749314 },
    { lat: 48.9365185, lng: 21.0747458 },
    { lat: 48.9358239, lng: 21.0748083 },
    { lat: 48.9350712, lng: 21.0750605 },
    { lat: 48.9348634, lng: 21.0752493 },
    { lat: 48.9344688, lng: 21.0758156 },
    { lat: 48.9336557, lng: 21.0764596 },
    { lat: 48.9330342, lng: 21.0763491 },
    { lat: 48.9317405, lng: 21.0763652 },
    { lat: 48.9312853, lng: 21.0762137 },
    { lat: 48.9305188, lng: 21.0757231 },
    { lat: 48.9304708, lng: 21.076294 },
    { lat: 48.9305419, lng: 21.0777283 },
    { lat: 48.9305543, lng: 21.0791194 },
    { lat: 48.9307367, lng: 21.0805547 },
    { lat: 48.9307749, lng: 21.0817005 },
    { lat: 48.9308844, lng: 21.0822675 },
    { lat: 48.9311328, lng: 21.0828182 },
    { lat: 48.9312176, lng: 21.0831075 },
    { lat: 48.9313175, lng: 21.0833804 },
    { lat: 48.9314822, lng: 21.0836316 },
    { lat: 48.9316038, lng: 21.0843309 },
    { lat: 48.9317115, lng: 21.0843601 },
    { lat: 48.931738, lng: 21.0848668 },
    { lat: 48.9321143, lng: 21.0863476 },
    { lat: 48.9327777, lng: 21.0880375 },
    { lat: 48.9330616, lng: 21.0883232 },
    { lat: 48.933374, lng: 21.0882399 },
    { lat: 48.934164, lng: 21.0904056 },
    { lat: 48.9345809, lng: 21.0920682 },
    { lat: 48.9355361, lng: 21.0936665 },
    { lat: 48.9361446, lng: 21.0948793 },
    { lat: 48.9364067, lng: 21.0949494 },
    { lat: 48.9367731, lng: 21.0956994 },
    { lat: 48.9373473, lng: 21.0978412 },
    { lat: 48.937735, lng: 21.0984873 },
    { lat: 48.9382508, lng: 21.0999358 },
    { lat: 48.9383795, lng: 21.101321 },
    { lat: 48.938997, lng: 21.1016931 },
    { lat: 48.9393065, lng: 21.1015846 },
    { lat: 48.9395788, lng: 21.1017284 },
    { lat: 48.9397927, lng: 21.1027143 },
    { lat: 48.9407851, lng: 21.1023162 },
    { lat: 48.9414863, lng: 21.1019017 },
    { lat: 48.94227, lng: 21.1021963 },
    { lat: 48.9427793, lng: 21.1022804 },
    { lat: 48.9436347, lng: 21.1017907 },
    { lat: 48.944632, lng: 21.1014499 },
    { lat: 48.9451885, lng: 21.1013969 },
    { lat: 48.9460935, lng: 21.1011875 },
    { lat: 48.9465125, lng: 21.1011622 },
    { lat: 48.9474831, lng: 21.1012479 },
    { lat: 48.9480022, lng: 21.101014 },
    { lat: 48.9488512, lng: 21.1009559 },
    { lat: 48.949818, lng: 21.1006434 },
    { lat: 48.9498485, lng: 21.100611 },
    { lat: 48.9495233, lng: 21.1001929 },
    { lat: 48.9494751, lng: 21.0999548 },
    { lat: 48.9495106, lng: 21.0996557 },
    { lat: 48.9493859, lng: 21.0995267 },
    { lat: 48.9497795, lng: 21.0990543 },
    { lat: 48.9498171, lng: 21.0991761 },
    { lat: 48.9498778, lng: 21.0991043 },
    { lat: 48.9507258, lng: 21.0974363 },
    { lat: 48.9507991, lng: 21.0958371 },
    { lat: 48.9509478, lng: 21.0948582 },
    { lat: 48.9510597, lng: 21.0943826 },
    { lat: 48.9515445, lng: 21.0936055 },
    { lat: 48.9517195, lng: 21.0928187 },
    { lat: 48.9520348, lng: 21.0926152 },
    { lat: 48.9524958, lng: 21.0920421 },
    { lat: 48.9533078, lng: 21.0913226 },
    { lat: 48.9533385, lng: 21.0910525 },
    { lat: 48.9536048, lng: 21.0908634 },
    { lat: 48.9541212, lng: 21.0902321 },
    { lat: 48.9544817, lng: 21.0899661 },
    { lat: 48.954904, lng: 21.0890241 },
    { lat: 48.9549895, lng: 21.0886052 },
    { lat: 48.9549609, lng: 21.0885214 },
    { lat: 48.9547785, lng: 21.0884458 },
    { lat: 48.9534365, lng: 21.0868226 },
    { lat: 48.9527126, lng: 21.0874497 },
    { lat: 48.952519, lng: 21.0874125 },
    { lat: 48.9520657, lng: 21.0867164 },
    { lat: 48.9510014, lng: 21.0859271 },
    { lat: 48.9508589, lng: 21.0856967 },
    { lat: 48.9506439, lng: 21.0855176 },
    { lat: 48.9506353, lng: 21.0851107 },
    { lat: 48.950892, lng: 21.0835777 },
    { lat: 48.951446, lng: 21.0823008 },
    { lat: 48.9519487, lng: 21.0806811 },
    { lat: 48.9526288, lng: 21.0793714 },
    { lat: 48.9529149, lng: 21.0785025 },
    { lat: 48.9531393, lng: 21.0773679 },
    { lat: 48.9531223, lng: 21.0755221 },
    { lat: 48.953015, lng: 21.0748794 },
    { lat: 48.952761, lng: 21.0741285 },
    { lat: 48.952499, lng: 21.0735378 },
    { lat: 48.952545, lng: 21.0732884 },
    { lat: 48.9526102, lng: 21.0732552 },
    { lat: 48.9523037, lng: 21.0702217 },
    { lat: 48.9523538, lng: 21.0693581 },
    { lat: 48.9524858, lng: 21.0686405 },
    { lat: 48.9525698, lng: 21.06797 },
    { lat: 48.9526056, lng: 21.0677634 },
    { lat: 48.9525806, lng: 21.0674151 },
    { lat: 48.9524858, lng: 21.0670996 },
    { lat: 48.952358, lng: 21.0668849 },
    { lat: 48.9522274, lng: 21.0667426 },
    { lat: 48.9518945, lng: 21.0665466 },
    { lat: 48.9521189, lng: 21.0648908 },
    { lat: 48.9529331, lng: 21.0637579 },
    { lat: 48.9525431, lng: 21.0632567 },
    { lat: 48.9520947, lng: 21.0618156 },
    { lat: 48.9521322, lng: 21.0614143 },
    { lat: 48.9520144, lng: 21.0603439 },
    { lat: 48.9519741, lng: 21.0597352 },
  ],
  Víťaz: [
    { lat: 48.9786934, lng: 20.9888177 },
    { lat: 48.9792796, lng: 20.9885447 },
    { lat: 48.9796479, lng: 20.9881773 },
    { lat: 48.9799075, lng: 20.988026 },
    { lat: 48.9802257, lng: 20.9873364 },
    { lat: 48.9803506, lng: 20.9864544 },
    { lat: 48.9816487, lng: 20.9864617 },
    { lat: 48.9832172, lng: 20.9863499 },
    { lat: 48.9832928, lng: 20.9862821 },
    { lat: 48.9829862, lng: 20.9844638 },
    { lat: 48.9827424, lng: 20.9836962 },
    { lat: 48.9827333, lng: 20.9831519 },
    { lat: 48.9829269, lng: 20.9820958 },
    { lat: 48.9830431, lng: 20.981696 },
    { lat: 48.9835981, lng: 20.9809194 },
    { lat: 48.9837075, lng: 20.9803491 },
    { lat: 48.9842064, lng: 20.9788275 },
    { lat: 48.9850565, lng: 20.9771732 },
    { lat: 48.9852203, lng: 20.9764766 },
    { lat: 48.9852958, lng: 20.9759376 },
    { lat: 48.9856879, lng: 20.9749655 },
    { lat: 48.985645, lng: 20.9744549 },
    { lat: 48.98574, lng: 20.9739277 },
    { lat: 48.9856642, lng: 20.9721822 },
    { lat: 48.9857283, lng: 20.9707525 },
    { lat: 48.9859232, lng: 20.9698478 },
    { lat: 48.9867049, lng: 20.9670149 },
    { lat: 48.9868102, lng: 20.967105 },
    { lat: 48.9874694, lng: 20.9654013 },
    { lat: 48.9879029, lng: 20.9638833 },
    { lat: 48.9880179, lng: 20.9633136 },
    { lat: 48.9879885, lng: 20.9628339 },
    { lat: 48.987646, lng: 20.9622854 },
    { lat: 48.9868621, lng: 20.9612891 },
    { lat: 48.986681, lng: 20.9609497 },
    { lat: 48.985804, lng: 20.9598976 },
    { lat: 48.9852301, lng: 20.9590508 },
    { lat: 48.9858761, lng: 20.9565181 },
    { lat: 48.9862112, lng: 20.956139 },
    { lat: 48.9867198, lng: 20.9558946 },
    { lat: 48.9860761, lng: 20.9552223 },
    { lat: 48.9867171, lng: 20.9538252 },
    { lat: 48.9863015, lng: 20.9507782 },
    { lat: 48.9849112, lng: 20.9505316 },
    { lat: 48.9846137, lng: 20.9505914 },
    { lat: 48.9841921, lng: 20.9510399 },
    { lat: 48.9838005, lng: 20.9515818 },
    { lat: 48.9799537, lng: 20.9453861 },
    { lat: 48.9784112, lng: 20.9427468 },
    { lat: 48.9760572, lng: 20.9392023 },
    { lat: 48.9768501, lng: 20.9373345 },
    { lat: 48.9775776, lng: 20.9349225 },
    { lat: 48.9778917, lng: 20.9340898 },
    { lat: 48.978841, lng: 20.9312112 },
    { lat: 48.9797237, lng: 20.9293728 },
    { lat: 48.9804144, lng: 20.9286589 },
    { lat: 48.981872, lng: 20.9268191 },
    { lat: 48.9823304, lng: 20.9260927 },
    { lat: 48.9828497, lng: 20.9255268 },
    { lat: 48.9832647, lng: 20.9247525 },
    { lat: 48.9843298, lng: 20.922351 },
    { lat: 48.9829459, lng: 20.9174452 },
    { lat: 48.9827648, lng: 20.9165422 },
    { lat: 48.9827394, lng: 20.9157239 },
    { lat: 48.9821707, lng: 20.9155116 },
    { lat: 48.981656, lng: 20.9155036 },
    { lat: 48.9812909, lng: 20.9153585 },
    { lat: 48.9810256, lng: 20.915134 },
    { lat: 48.9807319, lng: 20.9147699 },
    { lat: 48.9806198, lng: 20.9140451 },
    { lat: 48.9804714, lng: 20.9137847 },
    { lat: 48.9810881, lng: 20.9099767 },
    { lat: 48.9812026, lng: 20.906631 },
    { lat: 48.9811375, lng: 20.9061322 },
    { lat: 48.9812604, lng: 20.90531 },
    { lat: 48.9820819, lng: 20.9017342 },
    { lat: 48.982182, lng: 20.9030992 },
    { lat: 48.9824005, lng: 20.9032688 },
    { lat: 48.9829846, lng: 20.9024686 },
    { lat: 48.9835334, lng: 20.9018528 },
    { lat: 48.9837501, lng: 20.9013693 },
    { lat: 48.9836756, lng: 20.9001012 },
    { lat: 48.9841727, lng: 20.8998882 },
    { lat: 48.9847005, lng: 20.8998177 },
    { lat: 48.9854286, lng: 20.9001794 },
    { lat: 48.9855082, lng: 20.9008373 },
    { lat: 48.9861692, lng: 20.9012125 },
    { lat: 48.9857225, lng: 20.9022816 },
    { lat: 48.9857995, lng: 20.9036327 },
    { lat: 48.9860799, lng: 20.9038678 },
    { lat: 48.9865308, lng: 20.9057887 },
    { lat: 48.9871532, lng: 20.9056187 },
    { lat: 48.9877812, lng: 20.9057203 },
    { lat: 48.9882149, lng: 20.9054958 },
    { lat: 48.9883259, lng: 20.9052119 },
    { lat: 48.9890635, lng: 20.9047251 },
    { lat: 48.9895596, lng: 20.9041216 },
    { lat: 48.990353, lng: 20.9036784 },
    { lat: 48.9905224, lng: 20.9044977 },
    { lat: 48.9909074, lng: 20.9044656 },
    { lat: 48.991243, lng: 20.9030402 },
    { lat: 48.9913348, lng: 20.9018624 },
    { lat: 48.9917437, lng: 20.9017693 },
    { lat: 48.9913401, lng: 20.9007485 },
    { lat: 48.9916989, lng: 20.8960138 },
    { lat: 48.9919787, lng: 20.8907128 },
    { lat: 48.9920133, lng: 20.8906044 },
    { lat: 48.9919861, lng: 20.8899693 },
    { lat: 48.992534, lng: 20.879594 },
    { lat: 48.992294, lng: 20.879481 },
    { lat: 48.991462, lng: 20.878628 },
    { lat: 48.991097, lng: 20.877922 },
    { lat: 48.990103, lng: 20.87745 },
    { lat: 48.988645, lng: 20.876996 },
    { lat: 48.988589, lng: 20.877161 },
    { lat: 48.988255, lng: 20.877592 },
    { lat: 48.98785, lng: 20.8784 },
    { lat: 48.987018, lng: 20.879929 },
    { lat: 48.986546, lng: 20.881151 },
    { lat: 48.985783, lng: 20.882727 },
    { lat: 48.985022, lng: 20.884378 },
    { lat: 48.983857, lng: 20.883732 },
    { lat: 48.983236, lng: 20.883576 },
    { lat: 48.982838, lng: 20.883475 },
    { lat: 48.982793, lng: 20.883464 },
    { lat: 48.982247, lng: 20.883236 },
    { lat: 48.98203, lng: 20.883145 },
    { lat: 48.981683, lng: 20.883001 },
    { lat: 48.98129, lng: 20.883071 },
    { lat: 48.980959, lng: 20.883 },
    { lat: 48.980826, lng: 20.882961 },
    { lat: 48.980374, lng: 20.884611 },
    { lat: 48.980033, lng: 20.885812 },
    { lat: 48.979737, lng: 20.886057 },
    { lat: 48.978509, lng: 20.886877 },
    { lat: 48.978283, lng: 20.886111 },
    { lat: 48.977867, lng: 20.883148 },
    { lat: 48.9771, lng: 20.88194 },
    { lat: 48.975779, lng: 20.878037 },
    { lat: 48.97538, lng: 20.877237 },
    { lat: 48.973128, lng: 20.880254 },
    { lat: 48.972522, lng: 20.881272 },
    { lat: 48.971456, lng: 20.881966 },
    { lat: 48.970065, lng: 20.884409 },
    { lat: 48.970031, lng: 20.884467 },
    { lat: 48.969241, lng: 20.885852 },
    { lat: 48.969059, lng: 20.886149 },
    { lat: 48.969, lng: 20.886243 },
    { lat: 48.968943, lng: 20.886338 },
    { lat: 48.968785, lng: 20.886419 },
    { lat: 48.967482, lng: 20.888456 },
    { lat: 48.966863, lng: 20.890081 },
    { lat: 48.967154, lng: 20.890551 },
    { lat: 48.967117, lng: 20.89239 },
    { lat: 48.968249, lng: 20.893117 },
    { lat: 48.96781, lng: 20.89396 },
    { lat: 48.967972, lng: 20.89505 },
    { lat: 48.967516, lng: 20.895661 },
    { lat: 48.966716, lng: 20.895764 },
    { lat: 48.966116, lng: 20.897141 },
    { lat: 48.966374, lng: 20.897719 },
    { lat: 48.965048, lng: 20.898526 },
    { lat: 48.964943, lng: 20.898612 },
    { lat: 48.9649111, lng: 20.8986381 },
    { lat: 48.964871, lng: 20.898671 },
    { lat: 48.9646, lng: 20.898655 },
    { lat: 48.96435, lng: 20.898697 },
    { lat: 48.963717, lng: 20.898804 },
    { lat: 48.96315, lng: 20.899107 },
    { lat: 48.963086, lng: 20.900257 },
    { lat: 48.96318, lng: 20.900987 },
    { lat: 48.963477, lng: 20.903271 },
    { lat: 48.963652, lng: 20.904104 },
    { lat: 48.963955, lng: 20.9047 },
    { lat: 48.964104, lng: 20.905225 },
    { lat: 48.964169, lng: 20.905293 },
    { lat: 48.964253, lng: 20.905466 },
    { lat: 48.964466, lng: 20.905809 },
    { lat: 48.964694, lng: 20.906967 },
    { lat: 48.964683, lng: 20.907576 },
    { lat: 48.964589, lng: 20.908577 },
    { lat: 48.964547, lng: 20.909215 },
    { lat: 48.964242, lng: 20.911594 },
    { lat: 48.964223, lng: 20.91229 },
    { lat: 48.964287, lng: 20.91296 },
    { lat: 48.964363, lng: 20.913901 },
    { lat: 48.964393, lng: 20.914201 },
    { lat: 48.964264, lng: 20.915269 },
    { lat: 48.964085, lng: 20.915749 },
    { lat: 48.964073, lng: 20.916119 },
    { lat: 48.96397, lng: 20.916463 },
    { lat: 48.964021, lng: 20.916789 },
    { lat: 48.964079, lng: 20.917387 },
    { lat: 48.963763, lng: 20.918947 },
    { lat: 48.963711, lng: 20.91969 },
    { lat: 48.963413, lng: 20.920588 },
    { lat: 48.962751, lng: 20.922074 },
    { lat: 48.962544, lng: 20.922495 },
    { lat: 48.962178, lng: 20.923051 },
    { lat: 48.962059, lng: 20.923446 },
    { lat: 48.961893, lng: 20.92375 },
    { lat: 48.961865, lng: 20.923782 },
    { lat: 48.961717, lng: 20.924005 },
    { lat: 48.961385, lng: 20.924602 },
    { lat: 48.961153, lng: 20.92505 },
    { lat: 48.961026, lng: 20.925294 },
    { lat: 48.959819, lng: 20.927288 },
    { lat: 48.959154, lng: 20.92836 },
    { lat: 48.958735, lng: 20.929229 },
    { lat: 48.957632, lng: 20.93121 },
    { lat: 48.957373, lng: 20.931836 },
    { lat: 48.95595, lng: 20.933557 },
    { lat: 48.955769, lng: 20.933777 },
    { lat: 48.955495, lng: 20.934107 },
    { lat: 48.954731, lng: 20.934534 },
    { lat: 48.953874, lng: 20.935155 },
    { lat: 48.952988, lng: 20.936562 },
    { lat: 48.952058, lng: 20.938351 },
    { lat: 48.951331, lng: 20.939527 },
    { lat: 48.951068, lng: 20.939952 },
    { lat: 48.951142, lng: 20.941381 },
    { lat: 48.950992, lng: 20.943036 },
    { lat: 48.950896, lng: 20.943667 },
    { lat: 48.950644, lng: 20.94507 },
    { lat: 48.950631, lng: 20.945079 },
    { lat: 48.950385, lng: 20.945656 },
    { lat: 48.950263, lng: 20.946003 },
    { lat: 48.94985, lng: 20.946588 },
    { lat: 48.948961, lng: 20.947898 },
    { lat: 48.947448, lng: 20.949857 },
    { lat: 48.947419, lng: 20.949921 },
    { lat: 48.947396, lng: 20.949972 },
    { lat: 48.947515, lng: 20.950082 },
    { lat: 48.947851, lng: 20.950393 },
    { lat: 48.947934, lng: 20.950415 },
    { lat: 48.94823, lng: 20.950493 },
    { lat: 48.948343, lng: 20.950546 },
    { lat: 48.948523, lng: 20.95063 },
    { lat: 48.948935, lng: 20.950991 },
    { lat: 48.949101, lng: 20.951137 },
    { lat: 48.949144, lng: 20.951173 },
    { lat: 48.949154, lng: 20.951178 },
    { lat: 48.949613, lng: 20.9514 },
    { lat: 48.949815, lng: 20.951408 },
    { lat: 48.950279, lng: 20.951364 },
    { lat: 48.950679, lng: 20.951526 },
    { lat: 48.950873, lng: 20.951642 },
    { lat: 48.951, lng: 20.951883 },
    { lat: 48.950999, lng: 20.952246 },
    { lat: 48.950998, lng: 20.952255 },
    { lat: 48.950988, lng: 20.952372 },
    { lat: 48.951021, lng: 20.952529 },
    { lat: 48.951044, lng: 20.952647 },
    { lat: 48.951229, lng: 20.952919 },
    { lat: 48.951296, lng: 20.953018 },
    { lat: 48.951683, lng: 20.953495 },
    { lat: 48.951921, lng: 20.953659 },
    { lat: 48.951943, lng: 20.953653 },
    { lat: 48.952176, lng: 20.953597 },
    { lat: 48.952668, lng: 20.953586 },
    { lat: 48.952819, lng: 20.953593 },
    { lat: 48.953051, lng: 20.953763 },
    { lat: 48.953207, lng: 20.953877 },
    { lat: 48.953244, lng: 20.953931 },
    { lat: 48.953325, lng: 20.954051 },
    { lat: 48.953506, lng: 20.954334 },
    { lat: 48.953682, lng: 20.954473 },
    { lat: 48.953941, lng: 20.954607 },
    { lat: 48.954049, lng: 20.954645 },
    { lat: 48.95423, lng: 20.954707 },
    { lat: 48.954316, lng: 20.954775 },
    { lat: 48.954498, lng: 20.954922 },
    { lat: 48.954602, lng: 20.955034 },
    { lat: 48.954776, lng: 20.954915 },
    { lat: 48.955011, lng: 20.954801 },
    { lat: 48.955267, lng: 20.954738 },
    { lat: 48.955341, lng: 20.95467 },
    { lat: 48.955359, lng: 20.954728 },
    { lat: 48.955419, lng: 20.955097 },
    { lat: 48.955406, lng: 20.955441 },
    { lat: 48.955392, lng: 20.955718 },
    { lat: 48.955496, lng: 20.956001 },
    { lat: 48.955532, lng: 20.956283 },
    { lat: 48.955481, lng: 20.956587 },
    { lat: 48.955509, lng: 20.957011 },
    { lat: 48.955623, lng: 20.95722 },
    { lat: 48.955592, lng: 20.957637 },
    { lat: 48.955569, lng: 20.957772 },
    { lat: 48.955494, lng: 20.957941 },
    { lat: 48.955406, lng: 20.958073 },
    { lat: 48.955275, lng: 20.958229 },
    { lat: 48.954726, lng: 20.95867 },
    { lat: 48.954402, lng: 20.959058 },
    { lat: 48.954347, lng: 20.959174 },
    { lat: 48.954166, lng: 20.959562 },
    { lat: 48.954157, lng: 20.959951 },
    { lat: 48.954155, lng: 20.959996 },
    { lat: 48.954224, lng: 20.960603 },
    { lat: 48.954351, lng: 20.960959 },
    { lat: 48.954476, lng: 20.961217 },
    { lat: 48.954498, lng: 20.961261 },
    { lat: 48.954623, lng: 20.961441 },
    { lat: 48.954669, lng: 20.961506 },
    { lat: 48.954695, lng: 20.961572 },
    { lat: 48.954735, lng: 20.96169 },
    { lat: 48.954749, lng: 20.96173 },
    { lat: 48.954776, lng: 20.961812 },
    { lat: 48.95486, lng: 20.962059 },
    { lat: 48.954865, lng: 20.962075 },
    { lat: 48.954817, lng: 20.962425 },
    { lat: 48.954835, lng: 20.962445 },
    { lat: 48.954855, lng: 20.962469 },
    { lat: 48.95487, lng: 20.962488 },
    { lat: 48.955051, lng: 20.962795 },
    { lat: 48.95516, lng: 20.962728 },
    { lat: 48.955344, lng: 20.96279 },
    { lat: 48.955595, lng: 20.962998 },
    { lat: 48.955613, lng: 20.963013 },
    { lat: 48.95566, lng: 20.963064 },
    { lat: 48.955707, lng: 20.963115 },
    { lat: 48.955968, lng: 20.9634 },
    { lat: 48.956032, lng: 20.96347 },
    { lat: 48.956067, lng: 20.963509 },
    { lat: 48.956148, lng: 20.963596 },
    { lat: 48.956167, lng: 20.963634 },
    { lat: 48.956182, lng: 20.963663 },
    { lat: 48.956349, lng: 20.963992 },
    { lat: 48.956367, lng: 20.964029 },
    { lat: 48.956392, lng: 20.964076 },
    { lat: 48.956423, lng: 20.964139 },
    { lat: 48.956447, lng: 20.964188 },
    { lat: 48.956451, lng: 20.964193 },
    { lat: 48.956501, lng: 20.964311 },
    { lat: 48.956607, lng: 20.96456 },
    { lat: 48.956611, lng: 20.964568 },
    { lat: 48.956633, lng: 20.96463 },
    { lat: 48.956754, lng: 20.964961 },
    { lat: 48.956796, lng: 20.965123 },
    { lat: 48.956814, lng: 20.965191 },
    { lat: 48.956965, lng: 20.965778 },
    { lat: 48.95698, lng: 20.965848 },
    { lat: 48.956991, lng: 20.965895 },
    { lat: 48.95702, lng: 20.966021 },
    { lat: 48.957032, lng: 20.966069 },
    { lat: 48.957043, lng: 20.966118 },
    { lat: 48.957094, lng: 20.966339 },
    { lat: 48.957365, lng: 20.967518 },
    { lat: 48.957462, lng: 20.967865 },
    { lat: 48.957539, lng: 20.96809 },
    { lat: 48.95756, lng: 20.968152 },
    { lat: 48.957606, lng: 20.968284 },
    { lat: 48.9597512, lng: 20.9668193 },
    { lat: 48.9597371, lng: 20.9667713 },
    { lat: 48.959782, lng: 20.966732 },
    { lat: 48.9620314, lng: 20.9651229 },
    { lat: 48.9642284, lng: 20.9682028 },
    { lat: 48.9645826, lng: 20.9686012 },
    { lat: 48.9646587, lng: 20.9680172 },
    { lat: 48.964823, lng: 20.9676375 },
    { lat: 48.9651107, lng: 20.9670462 },
    { lat: 48.9657981, lng: 20.9659938 },
    { lat: 48.966136, lng: 20.9651909 },
    { lat: 48.9662235, lng: 20.9652823 },
    { lat: 48.9663944, lng: 20.9648684 },
    { lat: 48.9666233, lng: 20.9654544 },
    { lat: 48.9674689, lng: 20.967257 },
    { lat: 48.9684501, lng: 20.9679985 },
    { lat: 48.968867, lng: 20.9686547 },
    { lat: 48.9693632, lng: 20.9696688 },
    { lat: 48.9703721, lng: 20.9708313 },
    { lat: 48.9719056, lng: 20.9721748 },
    { lat: 48.9709542, lng: 20.9739242 },
    { lat: 48.9701321, lng: 20.9783566 },
    { lat: 48.9701478, lng: 20.9785241 },
    { lat: 48.9704405, lng: 20.9785605 },
    { lat: 48.9711882, lng: 20.97929 },
    { lat: 48.9713801, lng: 20.9798489 },
    { lat: 48.9717826, lng: 20.9804451 },
    { lat: 48.9719654, lng: 20.9805923 },
    { lat: 48.972169, lng: 20.9811371 },
    { lat: 48.9733843, lng: 20.9815698 },
    { lat: 48.9739715, lng: 20.9819033 },
    { lat: 48.9749099, lng: 20.9828415 },
    { lat: 48.9763955, lng: 20.9834912 },
    { lat: 48.9774135, lng: 20.98368 },
    { lat: 48.9777966, lng: 20.9848424 },
    { lat: 48.9785878, lng: 20.9877 },
    { lat: 48.9786934, lng: 20.9888177 },
  ],
  Žipov: [
    { lat: 48.9536376, lng: 21.0532892 },
    { lat: 48.9536467, lng: 21.0535707 },
    { lat: 48.9531996, lng: 21.0548063 },
    { lat: 48.9531838, lng: 21.0553572 },
    { lat: 48.9530321, lng: 21.0558481 },
    { lat: 48.953016, lng: 21.0561146 },
    { lat: 48.9528343, lng: 21.0564473 },
    { lat: 48.9523977, lng: 21.0569029 },
    { lat: 48.9522596, lng: 21.0571213 },
    { lat: 48.9520265, lng: 21.0571495 },
    { lat: 48.9520675, lng: 21.0584629 },
    { lat: 48.9519741, lng: 21.0597352 },
    { lat: 48.9520144, lng: 21.0603439 },
    { lat: 48.9521322, lng: 21.0614143 },
    { lat: 48.9520947, lng: 21.0618156 },
    { lat: 48.9525431, lng: 21.0632567 },
    { lat: 48.9529331, lng: 21.0637579 },
    { lat: 48.9521189, lng: 21.0648908 },
    { lat: 48.9518945, lng: 21.0665466 },
    { lat: 48.9522274, lng: 21.0667426 },
    { lat: 48.952358, lng: 21.0668849 },
    { lat: 48.9524858, lng: 21.0670996 },
    { lat: 48.9525806, lng: 21.0674151 },
    { lat: 48.9526056, lng: 21.0677634 },
    { lat: 48.9525698, lng: 21.06797 },
    { lat: 48.9524858, lng: 21.0686405 },
    { lat: 48.9523538, lng: 21.0693581 },
    { lat: 48.9523037, lng: 21.0702217 },
    { lat: 48.9526102, lng: 21.0732552 },
    { lat: 48.952545, lng: 21.0732884 },
    { lat: 48.952499, lng: 21.0735378 },
    { lat: 48.952761, lng: 21.0741285 },
    { lat: 48.953015, lng: 21.0748794 },
    { lat: 48.9531223, lng: 21.0755221 },
    { lat: 48.9531393, lng: 21.0773679 },
    { lat: 48.9529149, lng: 21.0785025 },
    { lat: 48.9526288, lng: 21.0793714 },
    { lat: 48.9519487, lng: 21.0806811 },
    { lat: 48.951446, lng: 21.0823008 },
    { lat: 48.950892, lng: 21.0835777 },
    { lat: 48.9506353, lng: 21.0851107 },
    { lat: 48.9506439, lng: 21.0855176 },
    { lat: 48.9508589, lng: 21.0856967 },
    { lat: 48.9510014, lng: 21.0859271 },
    { lat: 48.9520657, lng: 21.0867164 },
    { lat: 48.952519, lng: 21.0874125 },
    { lat: 48.9527126, lng: 21.0874497 },
    { lat: 48.9534365, lng: 21.0868226 },
    { lat: 48.9547785, lng: 21.0884458 },
    { lat: 48.9549609, lng: 21.0885214 },
    { lat: 48.9549895, lng: 21.0886052 },
    { lat: 48.954904, lng: 21.0890241 },
    { lat: 48.9544817, lng: 21.0899661 },
    { lat: 48.9545124, lng: 21.0900039 },
    { lat: 48.9556821, lng: 21.0907486 },
    { lat: 48.9558061, lng: 21.0903756 },
    { lat: 48.9563706, lng: 21.0901811 },
    { lat: 48.9573318, lng: 21.089631 },
    { lat: 48.9576887, lng: 21.0895293 },
    { lat: 48.9581124, lng: 21.0912584 },
    { lat: 48.9587908, lng: 21.0934166 },
    { lat: 48.9573979, lng: 21.094272 },
    { lat: 48.9568952, lng: 21.0954713 },
    { lat: 48.9569035, lng: 21.0959416 },
    { lat: 48.9564592, lng: 21.097036 },
    { lat: 48.9572426, lng: 21.097461 },
    { lat: 48.957362, lng: 21.0976019 },
    { lat: 48.9576429, lng: 21.097691 },
    { lat: 48.9578237, lng: 21.097924 },
    { lat: 48.9579009, lng: 21.0983026 },
    { lat: 48.9579879, lng: 21.0984473 },
    { lat: 48.9584126, lng: 21.098534 },
    { lat: 48.9585697, lng: 21.0986237 },
    { lat: 48.9587775, lng: 21.0990298 },
    { lat: 48.95931, lng: 21.0995645 },
    { lat: 48.959425, lng: 21.0998124 },
    { lat: 48.9595884, lng: 21.0998923 },
    { lat: 48.9597857, lng: 21.1001832 },
    { lat: 48.9600088, lng: 21.1006824 },
    { lat: 48.960101, lng: 21.1010298 },
    { lat: 48.9602234, lng: 21.1011987 },
    { lat: 48.9603919, lng: 21.1016414 },
    { lat: 48.9604089, lng: 21.1019947 },
    { lat: 48.9607314, lng: 21.1028612 },
    { lat: 48.9608811, lng: 21.102858 },
    { lat: 48.9609435, lng: 21.1029447 },
    { lat: 48.9614657, lng: 21.1031593 },
    { lat: 48.9615268, lng: 21.1032694 },
    { lat: 48.9635435, lng: 21.1033409 },
    { lat: 48.9647515, lng: 21.1029009 },
    { lat: 48.9673004, lng: 21.1010998 },
    { lat: 48.9679843, lng: 21.1001082 },
    { lat: 48.9681818, lng: 21.0990014 },
    { lat: 48.9693829, lng: 21.098944 },
    { lat: 48.9702229, lng: 21.0985694 },
    { lat: 48.9705532, lng: 21.0983444 },
    { lat: 48.9716056, lng: 21.0973648 },
    { lat: 48.9713909, lng: 21.0962179 },
    { lat: 48.9719026, lng: 21.0954729 },
    { lat: 48.9722852, lng: 21.0951164 },
    { lat: 48.9725788, lng: 21.0949409 },
    { lat: 48.9730241, lng: 21.094823 },
    { lat: 48.9732893, lng: 21.0932372 },
    { lat: 48.9732421, lng: 21.0923988 },
    { lat: 48.9731075, lng: 21.0915734 },
    { lat: 48.9733023, lng: 21.0915045 },
    { lat: 48.9734235, lng: 21.0912483 },
    { lat: 48.9735936, lng: 21.0910444 },
    { lat: 48.974276, lng: 21.0908816 },
    { lat: 48.9744602, lng: 21.0897162 },
    { lat: 48.974941, lng: 21.0892008 },
    { lat: 48.9752188, lng: 21.0886371 },
    { lat: 48.9750662, lng: 21.0879477 },
    { lat: 48.9750244, lng: 21.0874554 },
    { lat: 48.9749257, lng: 21.0871886 },
    { lat: 48.9749413, lng: 21.0869399 },
    { lat: 48.9747273, lng: 21.0862712 },
    { lat: 48.9745409, lng: 21.0854045 },
    { lat: 48.9739709, lng: 21.0853516 },
    { lat: 48.9730479, lng: 21.0854181 },
    { lat: 48.973034, lng: 21.085231 },
    { lat: 48.9732144, lng: 21.0848934 },
    { lat: 48.9734638, lng: 21.0846026 },
    { lat: 48.9735815, lng: 21.0843402 },
    { lat: 48.9736061, lng: 21.0841586 },
    { lat: 48.9738623, lng: 21.0838336 },
    { lat: 48.974359, lng: 21.0835344 },
    { lat: 48.9748228, lng: 21.0829751 },
    { lat: 48.9749698, lng: 21.0821291 },
    { lat: 48.9750278, lng: 21.0820048 },
    { lat: 48.9760129, lng: 21.0814947 },
    { lat: 48.9761936, lng: 21.0810575 },
    { lat: 48.9762255, lng: 21.0809329 },
    { lat: 48.9759823, lng: 21.0805448 },
    { lat: 48.9758745, lng: 21.0802361 },
    { lat: 48.9753273, lng: 21.0795715 },
    { lat: 48.9750442, lng: 21.0790978 },
    { lat: 48.9751907, lng: 21.0786487 },
    { lat: 48.9753256, lng: 21.0784352 },
    { lat: 48.9759578, lng: 21.0769938 },
    { lat: 48.9761193, lng: 21.0767661 },
    { lat: 48.976187, lng: 21.0760453 },
    { lat: 48.9761767, lng: 21.0753739 },
    { lat: 48.9762487, lng: 21.0748889 },
    { lat: 48.9764194, lng: 21.0744566 },
    { lat: 48.9766226, lng: 21.0741982 },
    { lat: 48.9768543, lng: 21.0737399 },
    { lat: 48.9768135, lng: 21.0732439 },
    { lat: 48.9761315, lng: 21.0719262 },
    { lat: 48.974495, lng: 21.0680883 },
    { lat: 48.9741673, lng: 21.066832 },
    { lat: 48.9739381, lng: 21.0652095 },
    { lat: 48.9730048, lng: 21.0642662 },
    { lat: 48.9727152, lng: 21.0638733 },
    { lat: 48.972262, lng: 21.0636067 },
    { lat: 48.9719054, lng: 21.0629129 },
    { lat: 48.9707742, lng: 21.0601303 },
    { lat: 48.9700996, lng: 21.0590435 },
    { lat: 48.9696264, lng: 21.0584019 },
    { lat: 48.9690819, lng: 21.0583391 },
    { lat: 48.9684499, lng: 21.0585652 },
    { lat: 48.9677089, lng: 21.058549 },
    { lat: 48.9668045, lng: 21.0573895 },
    { lat: 48.9662505, lng: 21.0566459 },
    { lat: 48.9658639, lng: 21.0559398 },
    { lat: 48.9654651, lng: 21.0544023 },
    { lat: 48.9648959, lng: 21.0535108 },
    { lat: 48.9645386, lng: 21.05325 },
    { lat: 48.9634666, lng: 21.0531667 },
    { lat: 48.962448, lng: 21.0528165 },
    { lat: 48.961634, lng: 21.0515598 },
    { lat: 48.9612322, lng: 21.0519374 },
    { lat: 48.9604966, lng: 21.0521988 },
    { lat: 48.959363, lng: 21.0523608 },
    { lat: 48.9588618, lng: 21.0524166 },
    { lat: 48.9582845, lng: 21.051628 },
    { lat: 48.9576919, lng: 21.0512609 },
    { lat: 48.9573536, lng: 21.0509583 },
    { lat: 48.9570097, lng: 21.0509429 },
    { lat: 48.9565308, lng: 21.0510243 },
    { lat: 48.9562437, lng: 21.0511593 },
    { lat: 48.9553774, lng: 21.0512393 },
    { lat: 48.9550402, lng: 21.0513914 },
    { lat: 48.9543211, lng: 21.051986 },
    { lat: 48.9540268, lng: 21.0524146 },
    { lat: 48.9536376, lng: 21.0532892 },
  ],
  Janov: [
    { lat: 48.9423176, lng: 21.1273245 },
    { lat: 48.9412879, lng: 21.1293798 },
    { lat: 48.9406271, lng: 21.1300483 },
    { lat: 48.9402783, lng: 21.1310677 },
    { lat: 48.9402727, lng: 21.1313903 },
    { lat: 48.9398451, lng: 21.1328114 },
    { lat: 48.9396751, lng: 21.1330848 },
    { lat: 48.9391045, lng: 21.1336139 },
    { lat: 48.9390328, lng: 21.1336957 },
    { lat: 48.9391513, lng: 21.1342398 },
    { lat: 48.9394232, lng: 21.1346473 },
    { lat: 48.9395082, lng: 21.1350577 },
    { lat: 48.9398382, lng: 21.13553 },
    { lat: 48.9399199, lng: 21.1360424 },
    { lat: 48.9400364, lng: 21.136311 },
    { lat: 48.9400857, lng: 21.1372249 },
    { lat: 48.9399454, lng: 21.138366 },
    { lat: 48.9399594, lng: 21.1389116 },
    { lat: 48.9396069, lng: 21.1404284 },
    { lat: 48.9394518, lng: 21.1414994 },
    { lat: 48.9391434, lng: 21.1424509 },
    { lat: 48.9390329, lng: 21.1425524 },
    { lat: 48.9386293, lng: 21.1432764 },
    { lat: 48.9384581, lng: 21.1434468 },
    { lat: 48.9383647, lng: 21.1436634 },
    { lat: 48.938229, lng: 21.1436461 },
    { lat: 48.9381458, lng: 21.143712 },
    { lat: 48.9380835, lng: 21.1444092 },
    { lat: 48.9379511, lng: 21.1446383 },
    { lat: 48.9378815, lng: 21.1451703 },
    { lat: 48.9380201, lng: 21.145429 },
    { lat: 48.9379115, lng: 21.1458358 },
    { lat: 48.93781, lng: 21.1460111 },
    { lat: 48.9377442, lng: 21.1469834 },
    { lat: 48.9376363, lng: 21.1470253 },
    { lat: 48.9376552, lng: 21.1474811 },
    { lat: 48.937572, lng: 21.1476962 },
    { lat: 48.9374089, lng: 21.1478703 },
    { lat: 48.9373847, lng: 21.1479623 },
    { lat: 48.9374673, lng: 21.1481209 },
    { lat: 48.9374537, lng: 21.1482565 },
    { lat: 48.9373689, lng: 21.148462 },
    { lat: 48.9371846, lng: 21.1486427 },
    { lat: 48.9370363, lng: 21.1491142 },
    { lat: 48.9368532, lng: 21.1493348 },
    { lat: 48.936724, lng: 21.1496983 },
    { lat: 48.9366393, lng: 21.149654 },
    { lat: 48.9365398, lng: 21.149745 },
    { lat: 48.9362767, lng: 21.1501769 },
    { lat: 48.9362274, lng: 21.1503996 },
    { lat: 48.936283, lng: 21.1508763 },
    { lat: 48.936057, lng: 21.1512006 },
    { lat: 48.936072, lng: 21.1515321 },
    { lat: 48.9360534, lng: 21.1515194 },
    { lat: 48.9360203, lng: 21.1521046 },
    { lat: 48.9359106, lng: 21.1529069 },
    { lat: 48.9358837, lng: 21.1538016 },
    { lat: 48.9359635, lng: 21.1541473 },
    { lat: 48.9350431, lng: 21.1576829 },
    { lat: 48.9350788, lng: 21.1581682 },
    { lat: 48.9348684, lng: 21.1585275 },
    { lat: 48.9342973, lng: 21.1592513 },
    { lat: 48.9342351, lng: 21.1598124 },
    { lat: 48.9338895, lng: 21.160736 },
    { lat: 48.93377, lng: 21.1612673 },
    { lat: 48.9335517, lng: 21.1619131 },
    { lat: 48.9329161, lng: 21.1634097 },
    { lat: 48.9328193, lng: 21.163765 },
    { lat: 48.9324316, lng: 21.1639664 },
    { lat: 48.9322933, lng: 21.1644647 },
    { lat: 48.9320762, lng: 21.1646841 },
    { lat: 48.9318594, lng: 21.1651598 },
    { lat: 48.9315543, lng: 21.1655134 },
    { lat: 48.9313308, lng: 21.1659574 },
    { lat: 48.9312561, lng: 21.1665469 },
    { lat: 48.9308394, lng: 21.1674315 },
    { lat: 48.9306957, lng: 21.1679749 },
    { lat: 48.9303494, lng: 21.1683429 },
    { lat: 48.9302729, lng: 21.1688413 },
    { lat: 48.929585, lng: 21.1705101 },
    { lat: 48.929596, lng: 21.1710397 },
    { lat: 48.9294594, lng: 21.1714572 },
    { lat: 48.9294035, lng: 21.1720769 },
    { lat: 48.9293862, lng: 21.1731318 },
    { lat: 48.9291541, lng: 21.1744109 },
    { lat: 48.9295409, lng: 21.1771176 },
    { lat: 48.9295984, lng: 21.1773042 },
    { lat: 48.9300645, lng: 21.1780289 },
    { lat: 48.9301661, lng: 21.1783945 },
    { lat: 48.9301312, lng: 21.1786024 },
    { lat: 48.9300646, lng: 21.1787067 },
    { lat: 48.9297653, lng: 21.1787406 },
    { lat: 48.9298419, lng: 21.1792121 },
    { lat: 48.9296024, lng: 21.1795376 },
    { lat: 48.9295514, lng: 21.1797832 },
    { lat: 48.9293053, lng: 21.1797939 },
    { lat: 48.9292363, lng: 21.1798511 },
    { lat: 48.9290538, lng: 21.1802795 },
    { lat: 48.9288885, lng: 21.1808555 },
    { lat: 48.9286144, lng: 21.1813331 },
    { lat: 48.9284292, lng: 21.1819263 },
    { lat: 48.9281131, lng: 21.1832512 },
    { lat: 48.9281759, lng: 21.1833891 },
    { lat: 48.9284005, lng: 21.1834075 },
    { lat: 48.9287772, lng: 21.1843337 },
    { lat: 48.9289624, lng: 21.1846747 },
    { lat: 48.9293377, lng: 21.1843029 },
    { lat: 48.9292934, lng: 21.1841443 },
    { lat: 48.9298398, lng: 21.1834098 },
    { lat: 48.9303193, lng: 21.1825487 },
    { lat: 48.9307288, lng: 21.1820803 },
    { lat: 48.9310105, lng: 21.1816243 },
    { lat: 48.9319313, lng: 21.1804141 },
    { lat: 48.9326699, lng: 21.1827956 },
    { lat: 48.9330137, lng: 21.1841767 },
    { lat: 48.9332614, lng: 21.1846448 },
    { lat: 48.9342087, lng: 21.184494 },
    { lat: 48.9346277, lng: 21.1846123 },
    { lat: 48.9347317, lng: 21.1845894 },
    { lat: 48.9349638, lng: 21.1842758 },
    { lat: 48.9356579, lng: 21.1841222 },
    { lat: 48.9360579, lng: 21.1841377 },
    { lat: 48.9365624, lng: 21.1839 },
    { lat: 48.9368555, lng: 21.1834496 },
    { lat: 48.9371066, lng: 21.1832364 },
    { lat: 48.937481, lng: 21.1831603 },
    { lat: 48.9381088, lng: 21.1828994 },
    { lat: 48.9388259, lng: 21.182763 },
    { lat: 48.9390638, lng: 21.1830973 },
    { lat: 48.9389723, lng: 21.1836613 },
    { lat: 48.9401672, lng: 21.1826867 },
    { lat: 48.9411644, lng: 21.1821195 },
    { lat: 48.9412404, lng: 21.1823209 },
    { lat: 48.9413451, lng: 21.1826413 },
    { lat: 48.9415247, lng: 21.1834849 },
    { lat: 48.9415807, lng: 21.1830456 },
    { lat: 48.9419108, lng: 21.1828119 },
    { lat: 48.9425587, lng: 21.1825144 },
    { lat: 48.9426013, lng: 21.1818952 },
    { lat: 48.9425505, lng: 21.181929 },
    { lat: 48.9423684, lng: 21.1818301 },
    { lat: 48.9416903, lng: 21.181881 },
    { lat: 48.9415668, lng: 21.1814908 },
    { lat: 48.9416692, lng: 21.1815986 },
    { lat: 48.9418397, lng: 21.1816221 },
    { lat: 48.9423473, lng: 21.1815499 },
    { lat: 48.9433573, lng: 21.1815649 },
    { lat: 48.9433669, lng: 21.1814808 },
    { lat: 48.9431017, lng: 21.1813467 },
    { lat: 48.943139, lng: 21.1812449 },
    { lat: 48.9426211, lng: 21.180663 },
    { lat: 48.9440385, lng: 21.1763173 },
    { lat: 48.9436751, lng: 21.1761357 },
    { lat: 48.9432528, lng: 21.1761688 },
    { lat: 48.942971, lng: 21.1758702 },
    { lat: 48.9429896, lng: 21.175779 },
    { lat: 48.9428603, lng: 21.1757455 },
    { lat: 48.9431371, lng: 21.1742061 },
    { lat: 48.9432662, lng: 21.1737982 },
    { lat: 48.9419077, lng: 21.1726159 },
    { lat: 48.9417154, lng: 21.172763 },
    { lat: 48.9418986, lng: 21.171723 },
    { lat: 48.9420236, lng: 21.1715063 },
    { lat: 48.9423366, lng: 21.1711914 },
    { lat: 48.9433158, lng: 21.1715536 },
    { lat: 48.943553, lng: 21.1717927 },
    { lat: 48.9438066, lng: 21.1718391 },
    { lat: 48.9442018, lng: 21.1716357 },
    { lat: 48.9444733, lng: 21.1712866 },
    { lat: 48.9442085, lng: 21.171207 },
    { lat: 48.9445241, lng: 21.1703618 },
    { lat: 48.944953, lng: 21.1697614 },
    { lat: 48.9450567, lng: 21.1694818 },
    { lat: 48.9450049, lng: 21.1687298 },
    { lat: 48.9450969, lng: 21.1681477 },
    { lat: 48.9456909, lng: 21.1663585 },
    { lat: 48.9455054, lng: 21.1661526 },
    { lat: 48.9451954, lng: 21.1659914 },
    { lat: 48.9451209, lng: 21.1654817 },
    { lat: 48.9444912, lng: 21.1648615 },
    { lat: 48.9449299, lng: 21.1643538 },
    { lat: 48.9452456, lng: 21.1636936 },
    { lat: 48.9453294, lng: 21.164565 },
    { lat: 48.9456931, lng: 21.1646789 },
    { lat: 48.9455761, lng: 21.1642499 },
    { lat: 48.9456482, lng: 21.1638612 },
    { lat: 48.9457448, lng: 21.1637164 },
    { lat: 48.9456245, lng: 21.1637509 },
    { lat: 48.9458789, lng: 21.1632566 },
    { lat: 48.9459371, lng: 21.1628893 },
    { lat: 48.9459886, lng: 21.162916 },
    { lat: 48.9459571, lng: 21.1620966 },
    { lat: 48.9460868, lng: 21.1618574 },
    { lat: 48.9462196, lng: 21.1618201 },
    { lat: 48.9462419, lng: 21.161743 },
    { lat: 48.9462258, lng: 21.1615154 },
    { lat: 48.9460992, lng: 21.1615321 },
    { lat: 48.9460572, lng: 21.1614492 },
    { lat: 48.9460664, lng: 21.160928 },
    { lat: 48.9461545, lng: 21.1608654 },
    { lat: 48.9461961, lng: 21.1606124 },
    { lat: 48.9461703, lng: 21.1599398 },
    { lat: 48.9462477, lng: 21.159613 },
    { lat: 48.9461176, lng: 21.1592351 },
    { lat: 48.9461758, lng: 21.1590085 },
    { lat: 48.9461854, lng: 21.1584919 },
    { lat: 48.9461168, lng: 21.1580676 },
    { lat: 48.9461745, lng: 21.1576166 },
    { lat: 48.9460295, lng: 21.157335 },
    { lat: 48.94602, lng: 21.157108 },
    { lat: 48.9462173, lng: 21.1567614 },
    { lat: 48.9463614, lng: 21.1560177 },
    { lat: 48.9462629, lng: 21.1554295 },
    { lat: 48.9463355, lng: 21.1551979 },
    { lat: 48.9462773, lng: 21.1547081 },
    { lat: 48.9464047, lng: 21.1544639 },
    { lat: 48.9463287, lng: 21.1543055 },
    { lat: 48.9464189, lng: 21.154025 },
    { lat: 48.9462416, lng: 21.1538983 },
    { lat: 48.9462338, lng: 21.1538312 },
    { lat: 48.946246, lng: 21.1537544 },
    { lat: 48.9463714, lng: 21.1537002 },
    { lat: 48.9463663, lng: 21.1535706 },
    { lat: 48.9461308, lng: 21.1534531 },
    { lat: 48.9461949, lng: 21.1529594 },
    { lat: 48.9463321, lng: 21.152422 },
    { lat: 48.9463123, lng: 21.1520042 },
    { lat: 48.9464224, lng: 21.1518619 },
    { lat: 48.9464173, lng: 21.15138 },
    { lat: 48.9465922, lng: 21.1509323 },
    { lat: 48.9464974, lng: 21.1506427 },
    { lat: 48.9465688, lng: 21.1502308 },
    { lat: 48.9466664, lng: 21.1500046 },
    { lat: 48.9466903, lng: 21.1496974 },
    { lat: 48.9468011, lng: 21.1496424 },
    { lat: 48.9467965, lng: 21.148747 },
    { lat: 48.9468526, lng: 21.1484908 },
    { lat: 48.946851, lng: 21.14798 },
    { lat: 48.946756, lng: 21.1476454 },
    { lat: 48.9469255, lng: 21.1473842 },
    { lat: 48.946897, lng: 21.1471238 },
    { lat: 48.9469539, lng: 21.1470746 },
    { lat: 48.946854, lng: 21.1467263 },
    { lat: 48.9467361, lng: 21.1466992 },
    { lat: 48.946652, lng: 21.1458604 },
    { lat: 48.946753, lng: 21.1455658 },
    { lat: 48.946597, lng: 21.1452572 },
    { lat: 48.9466489, lng: 21.1449455 },
    { lat: 48.9465303, lng: 21.1447139 },
    { lat: 48.9465196, lng: 21.1443484 },
    { lat: 48.9467693, lng: 21.1438117 },
    { lat: 48.9467348, lng: 21.1435063 },
    { lat: 48.9467555, lng: 21.1431976 },
    { lat: 48.9469137, lng: 21.1428617 },
    { lat: 48.9470766, lng: 21.1421491 },
    { lat: 48.946912, lng: 21.1413241 },
    { lat: 48.947039, lng: 21.140748 },
    { lat: 48.947041, lng: 21.1404929 },
    { lat: 48.9469652, lng: 21.1400517 },
    { lat: 48.9469989, lng: 21.1399457 },
    { lat: 48.9469471, lng: 21.1393763 },
    { lat: 48.9467811, lng: 21.1390433 },
    { lat: 48.9468309, lng: 21.1386959 },
    { lat: 48.9467125, lng: 21.1381912 },
    { lat: 48.9468102, lng: 21.137903 },
    { lat: 48.9465374, lng: 21.1373934 },
    { lat: 48.946489, lng: 21.1371847 },
    { lat: 48.946574, lng: 21.1366679 },
    { lat: 48.9465359, lng: 21.136492 },
    { lat: 48.9466662, lng: 21.1361696 },
    { lat: 48.9466378, lng: 21.1358043 },
    { lat: 48.9465472, lng: 21.1356094 },
    { lat: 48.946579, lng: 21.1354066 },
    { lat: 48.9464889, lng: 21.1350891 },
    { lat: 48.9461483, lng: 21.1341453 },
    { lat: 48.9460458, lng: 21.1327991 },
    { lat: 48.9460742, lng: 21.1326312 },
    { lat: 48.9459019, lng: 21.1323825 },
    { lat: 48.9457755, lng: 21.1319826 },
    { lat: 48.9447745, lng: 21.1304796 },
    { lat: 48.9446708, lng: 21.1301869 },
    { lat: 48.9445356, lng: 21.1300801 },
    { lat: 48.9439686, lng: 21.1286359 },
    { lat: 48.9436139, lng: 21.1281672 },
    { lat: 48.9430595, lng: 21.1279022 },
    { lat: 48.9427312, lng: 21.1275246 },
    { lat: 48.9423176, lng: 21.1273245 },
  ],
  Bretejovce: [
    { lat: 48.8444988, lng: 21.2971162 },
    { lat: 48.8442626, lng: 21.2970509 },
    { lat: 48.8437348, lng: 21.2969688 },
    { lat: 48.8428841, lng: 21.2965639 },
    { lat: 48.8372973, lng: 21.2947674 },
    { lat: 48.835947, lng: 21.2932612 },
    { lat: 48.8351614, lng: 21.2908218 },
    { lat: 48.8343627, lng: 21.2914617 },
    { lat: 48.8319586, lng: 21.2859598 },
    { lat: 48.8312554, lng: 21.2839024 },
    { lat: 48.8305641, lng: 21.28161 },
    { lat: 48.8296495, lng: 21.2781343 },
    { lat: 48.829449, lng: 21.2771701 },
    { lat: 48.828483, lng: 21.2716163 },
    { lat: 48.8282583, lng: 21.2699049 },
    { lat: 48.8279666, lng: 21.26843 },
    { lat: 48.8276349, lng: 21.2659561 },
    { lat: 48.8268526, lng: 21.2616697 },
    { lat: 48.8264687, lng: 21.2605282 },
    { lat: 48.8261007, lng: 21.2598731 },
    { lat: 48.8259765, lng: 21.2594944 },
    { lat: 48.8255803, lng: 21.2577799 },
    { lat: 48.8250073, lng: 21.2560218 },
    { lat: 48.8247862, lng: 21.2557399 },
    { lat: 48.8245715, lng: 21.255753 },
    { lat: 48.823113, lng: 21.255017 },
    { lat: 48.823158, lng: 21.255171 },
    { lat: 48.823179, lng: 21.256137 },
    { lat: 48.823127, lng: 21.256179 },
    { lat: 48.822647, lng: 21.256479 },
    { lat: 48.821703, lng: 21.257177 },
    { lat: 48.821155, lng: 21.25725 },
    { lat: 48.820708, lng: 21.257466 },
    { lat: 48.820178, lng: 21.257842 },
    { lat: 48.819858, lng: 21.258391 },
    { lat: 48.819344, lng: 21.259074 },
    { lat: 48.819133, lng: 21.259622 },
    { lat: 48.818835, lng: 21.260411 },
    { lat: 48.818746, lng: 21.261033 },
    { lat: 48.81878, lng: 21.261491 },
    { lat: 48.818414, lng: 21.261806 },
    { lat: 48.817837, lng: 21.262439 },
    { lat: 48.817716, lng: 21.263057 },
    { lat: 48.817625, lng: 21.26304 },
    { lat: 48.8174949, lng: 21.2636841 },
    { lat: 48.817403, lng: 21.26391 },
    { lat: 48.81708, lng: 21.265084 },
    { lat: 48.817239, lng: 21.265807 },
    { lat: 48.817233, lng: 21.265968 },
    { lat: 48.817025, lng: 21.26666 },
    { lat: 48.816932, lng: 21.267139 },
    { lat: 48.816879, lng: 21.267417 },
    { lat: 48.816849, lng: 21.267706 },
    { lat: 48.816834, lng: 21.269488 },
    { lat: 48.816942, lng: 21.27066 },
    { lat: 48.81679, lng: 21.271071 },
    { lat: 48.816909, lng: 21.271564 },
    { lat: 48.816798, lng: 21.271913 },
    { lat: 48.816893, lng: 21.272165 },
    { lat: 48.817067, lng: 21.272625 },
    { lat: 48.817162, lng: 21.272876 },
    { lat: 48.817288, lng: 21.274493 },
    { lat: 48.817356, lng: 21.275301 },
    { lat: 48.8173797, lng: 21.2752968 },
    { lat: 48.8174712, lng: 21.2761874 },
    { lat: 48.8177477, lng: 21.2761394 },
    { lat: 48.818021, lng: 21.2769086 },
    { lat: 48.818011, lng: 21.2777899 },
    { lat: 48.8182552, lng: 21.2778313 },
    { lat: 48.8179829, lng: 21.2788185 },
    { lat: 48.8175991, lng: 21.2795915 },
    { lat: 48.8175973, lng: 21.2801576 },
    { lat: 48.8178552, lng: 21.2810479 },
    { lat: 48.8182249, lng: 21.2813601 },
    { lat: 48.8183583, lng: 21.2815593 },
    { lat: 48.8188818, lng: 21.2816057 },
    { lat: 48.8196495, lng: 21.2818689 },
    { lat: 48.8197453, lng: 21.281579 },
    { lat: 48.8209822, lng: 21.2820271 },
    { lat: 48.8216344, lng: 21.2824124 },
    { lat: 48.8217918, lng: 21.2827759 },
    { lat: 48.8216326, lng: 21.2829839 },
    { lat: 48.8215847, lng: 21.283144 },
    { lat: 48.8215909, lng: 21.2837174 },
    { lat: 48.8217298, lng: 21.2840937 },
    { lat: 48.8216955, lng: 21.2843754 },
    { lat: 48.8218544, lng: 21.2846778 },
    { lat: 48.8218614, lng: 21.2851276 },
    { lat: 48.8219407, lng: 21.2851771 },
    { lat: 48.8218677, lng: 21.2854415 },
    { lat: 48.8218892, lng: 21.2856285 },
    { lat: 48.8219734, lng: 21.285728 },
    { lat: 48.821968, lng: 21.2858848 },
    { lat: 48.8219018, lng: 21.2861172 },
    { lat: 48.8219121, lng: 21.2863086 },
    { lat: 48.8218332, lng: 21.2864824 },
    { lat: 48.82191, lng: 21.286787 },
    { lat: 48.8218637, lng: 21.2870388 },
    { lat: 48.8220348, lng: 21.2876292 },
    { lat: 48.8219769, lng: 21.2881254 },
    { lat: 48.8221231, lng: 21.2891908 },
    { lat: 48.8221579, lng: 21.2900751 },
    { lat: 48.8221148, lng: 21.2904699 },
    { lat: 48.8221697, lng: 21.2908328 },
    { lat: 48.8223361, lng: 21.2909682 },
    { lat: 48.8224757, lng: 21.2912688 },
    { lat: 48.8223753, lng: 21.2916262 },
    { lat: 48.8224345, lng: 21.291767 },
    { lat: 48.8226077, lng: 21.2918695 },
    { lat: 48.8226119, lng: 21.2920774 },
    { lat: 48.8225352, lng: 21.2922596 },
    { lat: 48.8225651, lng: 21.2923528 },
    { lat: 48.8226724, lng: 21.2924071 },
    { lat: 48.8227354, lng: 21.2925855 },
    { lat: 48.8226444, lng: 21.2934054 },
    { lat: 48.8224777, lng: 21.2936603 },
    { lat: 48.8224416, lng: 21.2943935 },
    { lat: 48.8222295, lng: 21.2946687 },
    { lat: 48.8220028, lng: 21.2950981 },
    { lat: 48.8219844, lng: 21.2952686 },
    { lat: 48.8221335, lng: 21.2956589 },
    { lat: 48.822119, lng: 21.2957706 },
    { lat: 48.822328, lng: 21.2960874 },
    { lat: 48.8225171, lng: 21.2961645 },
    { lat: 48.8229517, lng: 21.2961341 },
    { lat: 48.8235541, lng: 21.2965566 },
    { lat: 48.8239183, lng: 21.2977972 },
    { lat: 48.8241105, lng: 21.298673 },
    { lat: 48.8242269, lng: 21.2989091 },
    { lat: 48.824659, lng: 21.2986897 },
    { lat: 48.8246173, lng: 21.2997568 },
    { lat: 48.8248741, lng: 21.3000985 },
    { lat: 48.8255355, lng: 21.300423 },
    { lat: 48.825718, lng: 21.3007238 },
    { lat: 48.8258565, lng: 21.3013538 },
    { lat: 48.8268673, lng: 21.3004746 },
    { lat: 48.827032, lng: 21.3009746 },
    { lat: 48.827283, lng: 21.3014871 },
    { lat: 48.827775, lng: 21.30152 },
    { lat: 48.828099, lng: 21.30123 },
    { lat: 48.828214, lng: 21.301204 },
    { lat: 48.828577, lng: 21.301307 },
    { lat: 48.828624, lng: 21.30132 },
    { lat: 48.828689, lng: 21.301397 },
    { lat: 48.828703, lng: 21.301448 },
    { lat: 48.828686, lng: 21.301569 },
    { lat: 48.828157, lng: 21.302487 },
    { lat: 48.828155, lng: 21.302883 },
    { lat: 48.828174, lng: 21.303024 },
    { lat: 48.828224, lng: 21.303176 },
    { lat: 48.828348, lng: 21.30341 },
    { lat: 48.828314, lng: 21.303519 },
    { lat: 48.828499, lng: 21.303632 },
    { lat: 48.82857, lng: 21.303658 },
    { lat: 48.828653, lng: 21.303666 },
    { lat: 48.828705, lng: 21.303662 },
    { lat: 48.828798, lng: 21.303601 },
    { lat: 48.828996, lng: 21.303383 },
    { lat: 48.829116, lng: 21.303265 },
    { lat: 48.829204, lng: 21.303211 },
    { lat: 48.829358, lng: 21.303144 },
    { lat: 48.829372, lng: 21.30327 },
    { lat: 48.8303194, lng: 21.3024223 },
    { lat: 48.8313748, lng: 21.3017524 },
    { lat: 48.8328266, lng: 21.3006877 },
    { lat: 48.8329406, lng: 21.3006601 },
    { lat: 48.8330466, lng: 21.3007824 },
    { lat: 48.8330878, lng: 21.3009488 },
    { lat: 48.8334339, lng: 21.3011171 },
    { lat: 48.8341089, lng: 21.3009282 },
    { lat: 48.8341797, lng: 21.3008839 },
    { lat: 48.8341869, lng: 21.300787 },
    { lat: 48.8343332, lng: 21.3008975 },
    { lat: 48.8342496, lng: 21.3014842 },
    { lat: 48.8343021, lng: 21.3023942 },
    { lat: 48.8343864, lng: 21.3026473 },
    { lat: 48.8346766, lng: 21.3027906 },
    { lat: 48.834969, lng: 21.3027842 },
    { lat: 48.8351389, lng: 21.3025233 },
    { lat: 48.8351772, lng: 21.3019377 },
    { lat: 48.8353753, lng: 21.3009762 },
    { lat: 48.8354354, lng: 21.3007366 },
    { lat: 48.8357756, lng: 21.300007 },
    { lat: 48.8358861, lng: 21.299198 },
    { lat: 48.8359557, lng: 21.2990431 },
    { lat: 48.8365705, lng: 21.2986901 },
    { lat: 48.8368514, lng: 21.2987523 },
    { lat: 48.8372182, lng: 21.2989652 },
    { lat: 48.8373749, lng: 21.2991622 },
    { lat: 48.8375357, lng: 21.2996421 },
    { lat: 48.8376482, lng: 21.3009671 },
    { lat: 48.837597, lng: 21.3015776 },
    { lat: 48.8374985, lng: 21.3021164 },
    { lat: 48.8376117, lng: 21.3026507 },
    { lat: 48.8377752, lng: 21.302779 },
    { lat: 48.8380778, lng: 21.3028089 },
    { lat: 48.8383074, lng: 21.3027386 },
    { lat: 48.8386816, lng: 21.3023823 },
    { lat: 48.8389825, lng: 21.3019556 },
    { lat: 48.8390994, lng: 21.3018749 },
    { lat: 48.8394283, lng: 21.3019266 },
    { lat: 48.8398413, lng: 21.3021496 },
    { lat: 48.8401134, lng: 21.3024725 },
    { lat: 48.8402021, lng: 21.3027221 },
    { lat: 48.8403932, lng: 21.3037554 },
    { lat: 48.8404638, lng: 21.3039281 },
    { lat: 48.8406466, lng: 21.304055 },
    { lat: 48.8412448, lng: 21.3040779 },
    { lat: 48.8416099, lng: 21.3038716 },
    { lat: 48.8417624, lng: 21.3034895 },
    { lat: 48.8418431, lng: 21.3031019 },
    { lat: 48.8418647, lng: 21.3027049 },
    { lat: 48.841782, lng: 21.3023585 },
    { lat: 48.8415621, lng: 21.3018569 },
    { lat: 48.8411586, lng: 21.3016171 },
    { lat: 48.8409173, lng: 21.3013046 },
    { lat: 48.8408163, lng: 21.3006522 },
    { lat: 48.8408334, lng: 21.3003602 },
    { lat: 48.8409314, lng: 21.2999557 },
    { lat: 48.8410842, lng: 21.2997303 },
    { lat: 48.8412628, lng: 21.2996007 },
    { lat: 48.8414962, lng: 21.2996244 },
    { lat: 48.8419615, lng: 21.3003385 },
    { lat: 48.8423401, lng: 21.3006497 },
    { lat: 48.8430086, lng: 21.3005808 },
    { lat: 48.8433901, lng: 21.3006874 },
    { lat: 48.8437535, lng: 21.3010618 },
    { lat: 48.8441117, lng: 21.3012551 },
    { lat: 48.8447718, lng: 21.3012793 },
    { lat: 48.8451817, lng: 21.3008095 },
    { lat: 48.8451542, lng: 21.3002701 },
    { lat: 48.8448622, lng: 21.2993698 },
    { lat: 48.8444128, lng: 21.2986117 },
    { lat: 48.8443116, lng: 21.2979542 },
    { lat: 48.844323, lng: 21.2975529 },
    { lat: 48.8443946, lng: 21.2973178 },
    { lat: 48.8444988, lng: 21.2971162 },
  ],
  Svinia: [
    { lat: 49.041199, lng: 21.102192 },
    { lat: 49.0412009, lng: 21.1025737 },
    { lat: 49.0411216, lng: 21.1029749 },
    { lat: 49.0408353, lng: 21.1035926 },
    { lat: 49.0406361, lng: 21.1043711 },
    { lat: 49.0405224, lng: 21.1051439 },
    { lat: 49.0402913, lng: 21.1056216 },
    { lat: 49.0400795, lng: 21.106209 },
    { lat: 49.0397787, lng: 21.1066947 },
    { lat: 49.0384794, lng: 21.1038672 },
    { lat: 49.0374777, lng: 21.1020658 },
    { lat: 49.0354715, lng: 21.1033069 },
    { lat: 49.035407, lng: 21.1019638 },
    { lat: 49.034012, lng: 21.1018713 },
    { lat: 49.0327118, lng: 21.1019268 },
    { lat: 49.032456, lng: 21.1020992 },
    { lat: 49.0318881, lng: 21.1021793 },
    { lat: 49.0308268, lng: 21.102178 },
    { lat: 49.0304221, lng: 21.1023665 },
    { lat: 49.0301093, lng: 21.1012104 },
    { lat: 49.0298471, lng: 21.099451 },
    { lat: 49.0299875, lng: 21.0986728 },
    { lat: 49.0301073, lng: 21.0973947 },
    { lat: 49.0301327, lng: 21.0974154 },
    { lat: 49.0303472, lng: 21.0956099 },
    { lat: 49.0304471, lng: 21.0955641 },
    { lat: 49.0304773, lng: 21.0939853 },
    { lat: 49.0317578, lng: 21.0931321 },
    { lat: 49.0322397, lng: 21.0935234 },
    { lat: 49.0326913, lng: 21.0932586 },
    { lat: 49.0329201, lng: 21.0932688 },
    { lat: 49.0331057, lng: 21.093056 },
    { lat: 49.0330172, lng: 21.0923334 },
    { lat: 49.0332691, lng: 21.0922896 },
    { lat: 49.0334195, lng: 21.0921339 },
    { lat: 49.0336067, lng: 21.0917283 },
    { lat: 49.033542, lng: 21.0914613 },
    { lat: 49.0337743, lng: 21.0912363 },
    { lat: 49.033917, lng: 21.0914409 },
    { lat: 49.0343197, lng: 21.0910488 },
    { lat: 49.0347266, lng: 21.090789 },
    { lat: 49.035177, lng: 21.0913154 },
    { lat: 49.035364, lng: 21.0913589 },
    { lat: 49.0355999, lng: 21.0909303 },
    { lat: 49.0356607, lng: 21.0906109 },
    { lat: 49.0354883, lng: 21.0904264 },
    { lat: 49.0355671, lng: 21.0902244 },
    { lat: 49.0357961, lng: 21.0901353 },
    { lat: 49.0365677, lng: 21.0892095 },
    { lat: 49.0368446, lng: 21.0895921 },
    { lat: 49.0376354, lng: 21.0882542 },
    { lat: 49.0382043, lng: 21.0870465 },
    { lat: 49.0385458, lng: 21.0862234 },
    { lat: 49.0382066, lng: 21.0856873 },
    { lat: 49.0380749, lng: 21.0855808 },
    { lat: 49.0387216, lng: 21.0846227 },
    { lat: 49.0389235, lng: 21.0852975 },
    { lat: 49.0393431, lng: 21.085961 },
    { lat: 49.0395264, lng: 21.0861658 },
    { lat: 49.0396108, lng: 21.0861671 },
    { lat: 49.0399942, lng: 21.0853523 },
    { lat: 49.0403292, lng: 21.0848122 },
    { lat: 49.0405476, lng: 21.0842199 },
    { lat: 49.0407822, lng: 21.0837773 },
    { lat: 49.0408657, lng: 21.0837202 },
    { lat: 49.0408235, lng: 21.0834572 },
    { lat: 49.0411699, lng: 21.0829223 },
    { lat: 49.04161, lng: 21.082048 },
    { lat: 49.041501, lng: 21.081847 },
    { lat: 49.042698, lng: 21.079043 },
    { lat: 49.044196, lng: 21.076389 },
    { lat: 49.043592, lng: 21.076203 },
    { lat: 49.043081, lng: 21.076662 },
    { lat: 49.042594, lng: 21.076656 },
    { lat: 49.042213, lng: 21.076827 },
    { lat: 49.041937, lng: 21.076951 },
    { lat: 49.041289, lng: 21.077621 },
    { lat: 49.040476, lng: 21.076821 },
    { lat: 49.040071, lng: 21.075684 },
    { lat: 49.039839, lng: 21.07503 },
    { lat: 49.04061, lng: 21.073921 },
    { lat: 49.04128, lng: 21.073164 },
    { lat: 49.038476, lng: 21.073011 },
    { lat: 49.03829, lng: 21.074621 },
    { lat: 49.038459, lng: 21.074893 },
    { lat: 49.037945, lng: 21.075406 },
    { lat: 49.037527, lng: 21.075979 },
    { lat: 49.036942, lng: 21.075057 },
    { lat: 49.035718, lng: 21.076953 },
    { lat: 49.035643, lng: 21.07743 },
    { lat: 49.034078, lng: 21.07951 },
    { lat: 49.033812, lng: 21.079193 },
    { lat: 49.033902, lng: 21.0782 },
    { lat: 49.034098, lng: 21.077773 },
    { lat: 49.03308, lng: 21.075784 },
    { lat: 49.032584, lng: 21.076383 },
    { lat: 49.032526, lng: 21.076043 },
    { lat: 49.032066, lng: 21.075709 },
    { lat: 49.031883, lng: 21.076112 },
    { lat: 49.03201, lng: 21.076482 },
    { lat: 49.031911, lng: 21.076963 },
    { lat: 49.032026, lng: 21.077532 },
    { lat: 49.031822, lng: 21.078107 },
    { lat: 49.032031, lng: 21.078894 },
    { lat: 49.031371, lng: 21.08021 },
    { lat: 49.030535, lng: 21.080357 },
    { lat: 49.030028, lng: 21.079172 },
    { lat: 49.029717, lng: 21.078808 },
    { lat: 49.029399, lng: 21.079047 },
    { lat: 49.029101, lng: 21.080061 },
    { lat: 49.02852, lng: 21.081285 },
    { lat: 49.027777, lng: 21.080284 },
    { lat: 49.027351, lng: 21.080341 },
    { lat: 49.026092, lng: 21.081481 },
    { lat: 49.025919, lng: 21.081875 },
    { lat: 49.02502, lng: 21.082894 },
    { lat: 49.024816, lng: 21.082707 },
    { lat: 49.024412, lng: 21.083144 },
    { lat: 49.023999, lng: 21.082581 },
    { lat: 49.023447, lng: 21.083039 },
    { lat: 49.023111, lng: 21.082385 },
    { lat: 49.022216, lng: 21.083591 },
    { lat: 49.021684, lng: 21.083844 },
    { lat: 49.021472, lng: 21.084627 },
    { lat: 49.020832, lng: 21.084828 },
    { lat: 49.0195177, lng: 21.0861933 },
    { lat: 49.0191409, lng: 21.0865153 },
    { lat: 49.017037, lng: 21.0889866 },
    { lat: 49.0167362, lng: 21.0899196 },
    { lat: 49.0172438, lng: 21.0907951 },
    { lat: 49.0173569, lng: 21.090852 },
    { lat: 49.0175414, lng: 21.0907074 },
    { lat: 49.0176409, lng: 21.0910005 },
    { lat: 49.0168957, lng: 21.0918762 },
    { lat: 49.0164986, lng: 21.0921265 },
    { lat: 49.0151657, lng: 21.0931982 },
    { lat: 49.0144032, lng: 21.0939449 },
    { lat: 49.0143244, lng: 21.0942968 },
    { lat: 49.0149239, lng: 21.0959721 },
    { lat: 49.015202, lng: 21.096988 },
    { lat: 49.0151745, lng: 21.0979896 },
    { lat: 49.0151175, lng: 21.0984245 },
    { lat: 49.0140888, lng: 21.0994393 },
    { lat: 49.0137215, lng: 21.0999505 },
    { lat: 49.0139935, lng: 21.1006438 },
    { lat: 49.0136406, lng: 21.100907 },
    { lat: 49.0134724, lng: 21.1009259 },
    { lat: 49.0132682, lng: 21.1008388 },
    { lat: 49.0129925, lng: 21.1009141 },
    { lat: 49.0126622, lng: 21.10121 },
    { lat: 49.0123568, lng: 21.1013713 },
    { lat: 49.011728, lng: 21.1014132 },
    { lat: 49.0109464, lng: 21.1012184 },
    { lat: 49.0107212, lng: 21.1013229 },
    { lat: 49.0100322, lng: 21.1012463 },
    { lat: 49.0096421, lng: 21.1013308 },
    { lat: 49.0093135, lng: 21.1012562 },
    { lat: 49.0088031, lng: 21.101313 },
    { lat: 49.0085646, lng: 21.1011711 },
    { lat: 49.0085642, lng: 21.1007754 },
    { lat: 49.0084617, lng: 21.1007349 },
    { lat: 49.0083995, lng: 21.1008302 },
    { lat: 49.0081315, lng: 21.1008927 },
    { lat: 49.007007, lng: 21.1006426 },
    { lat: 49.0063997, lng: 21.1007937 },
    { lat: 49.0062, lng: 21.1009218 },
    { lat: 49.0059442, lng: 21.1008784 },
    { lat: 49.0045226, lng: 21.1011124 },
    { lat: 49.0041916, lng: 21.1013978 },
    { lat: 49.003348, lng: 21.1018657 },
    { lat: 49.0032877, lng: 21.1019849 },
    { lat: 49.0030094, lng: 21.1020062 },
    { lat: 49.0038399, lng: 21.099827 },
    { lat: 49.003891, lng: 21.0995977 },
    { lat: 49.0040975, lng: 21.0978959 },
    { lat: 49.0046629, lng: 21.0955262 },
    { lat: 49.0047534, lng: 21.0943133 },
    { lat: 49.0047369, lng: 21.0936607 },
    { lat: 49.0026632, lng: 21.093497 },
    { lat: 49.0022263, lng: 21.0941248 },
    { lat: 49.0021942, lng: 21.0946673 },
    { lat: 49.0021108, lng: 21.0950434 },
    { lat: 49.0021209, lng: 21.0954316 },
    { lat: 49.0025487, lng: 21.0964512 },
    { lat: 49.0026699, lng: 21.0968641 },
    { lat: 49.0025207, lng: 21.0976519 },
    { lat: 49.0021433, lng: 21.0979863 },
    { lat: 49.002007, lng: 21.0985817 },
    { lat: 49.0019122, lng: 21.0994497 },
    { lat: 49.0019038, lng: 21.0999607 },
    { lat: 49.0018078, lng: 21.1002252 },
    { lat: 49.0018296, lng: 21.1015342 },
    { lat: 49.0017092, lng: 21.1019961 },
    { lat: 49.0016107, lng: 21.1023242 },
    { lat: 49.0011713, lng: 21.1029828 },
    { lat: 49.0005428, lng: 21.1035949 },
    { lat: 49.0002292, lng: 21.1041704 },
    { lat: 48.9999823, lng: 21.1051428 },
    { lat: 48.9999871, lng: 21.1059625 },
    { lat: 48.9998509, lng: 21.107122 },
    { lat: 48.9998647, lng: 21.1077611 },
    { lat: 48.9999695, lng: 21.1083674 },
    { lat: 49.0001193, lng: 21.1088262 },
    { lat: 49.0005286, lng: 21.1093092 },
    { lat: 49.0007069, lng: 21.1098462 },
    { lat: 48.9992347, lng: 21.1104765 },
    { lat: 48.9981637, lng: 21.113978 },
    { lat: 48.9980242, lng: 21.1146807 },
    { lat: 48.9977324, lng: 21.1155122 },
    { lat: 48.9983196, lng: 21.1174114 },
    { lat: 48.9985443, lng: 21.1178246 },
    { lat: 49.0000479, lng: 21.1199453 },
    { lat: 49.0007576, lng: 21.1219073 },
    { lat: 49.0024421, lng: 21.1246536 },
    { lat: 49.0035161, lng: 21.1254771 },
    { lat: 49.0057357, lng: 21.1290564 },
    { lat: 49.0070011, lng: 21.1392391 },
    { lat: 49.0083606, lng: 21.1406952 },
    { lat: 49.0083279, lng: 21.1414055 },
    { lat: 49.0083301, lng: 21.1415405 },
    { lat: 49.0109136, lng: 21.1415283 },
    { lat: 49.0126926, lng: 21.1417719 },
    { lat: 49.0132441, lng: 21.1417032 },
    { lat: 49.0143561, lng: 21.1417457 },
    { lat: 49.014362, lng: 21.1416094 },
    { lat: 49.0124089, lng: 21.1371265 },
    { lat: 49.0154743, lng: 21.1346013 },
    { lat: 49.0165049, lng: 21.1332141 },
    { lat: 49.0178346, lng: 21.1319563 },
    { lat: 49.0203331, lng: 21.1307598 },
    { lat: 49.0207244, lng: 21.1307102 },
    { lat: 49.0237094, lng: 21.1312212 },
    { lat: 49.0272725, lng: 21.1298904 },
    { lat: 49.028857, lng: 21.1290376 },
    { lat: 49.0300837, lng: 21.1293541 },
    { lat: 49.0304929, lng: 21.1296217 },
    { lat: 49.0313586, lng: 21.1304478 },
    { lat: 49.0325025, lng: 21.1309924 },
    { lat: 49.0328894, lng: 21.1316605 },
    { lat: 49.03289, lng: 21.1316769 },
    { lat: 49.033, lng: 21.1317036 },
    { lat: 49.0332954, lng: 21.1319764 },
    { lat: 49.033691, lng: 21.1321664 },
    { lat: 49.0339886, lng: 21.1321876 },
    { lat: 49.0345064, lng: 21.1317615 },
    { lat: 49.0349227, lng: 21.1312976 },
    { lat: 49.0354834, lng: 21.1308486 },
    { lat: 49.036451, lng: 21.1302423 },
    { lat: 49.0371162, lng: 21.129994 },
    { lat: 49.0377841, lng: 21.1299381 },
    { lat: 49.0388162, lng: 21.1296975 },
    { lat: 49.0394324, lng: 21.1293649 },
    { lat: 49.0397444, lng: 21.1290887 },
    { lat: 49.040318, lng: 21.1284382 },
    { lat: 49.0425199, lng: 21.1253863 },
    { lat: 49.0430439, lng: 21.1250177 },
    { lat: 49.0440443, lng: 21.1240623 },
    { lat: 49.0449197, lng: 21.1234917 },
    { lat: 49.045262, lng: 21.123128 },
    { lat: 49.045269, lng: 21.12297 },
    { lat: 49.045448, lng: 21.122803 },
    { lat: 49.045529, lng: 21.122701 },
    { lat: 49.04569, lng: 21.122577 },
    { lat: 49.045795, lng: 21.122389 },
    { lat: 49.045862, lng: 21.122262 },
    { lat: 49.045894, lng: 21.122132 },
    { lat: 49.04596, lng: 21.122035 },
    { lat: 49.046068, lng: 21.121821 },
    { lat: 49.046122, lng: 21.121537 },
    { lat: 49.046122, lng: 21.121101 },
    { lat: 49.046411, lng: 21.120364 },
    { lat: 49.046657, lng: 21.119732 },
    { lat: 49.046878, lng: 21.11929 },
    { lat: 49.046985, lng: 21.119076 },
    { lat: 49.047122, lng: 21.118257 },
    { lat: 49.047197, lng: 21.117907 },
    { lat: 49.047288, lng: 21.117492 },
    { lat: 49.047327, lng: 21.117242 },
    { lat: 49.047484, lng: 21.116497 },
    { lat: 49.047596, lng: 21.116226 },
    { lat: 49.047681, lng: 21.115957 },
    { lat: 49.047842, lng: 21.114975 },
    { lat: 49.047963, lng: 21.114673 },
    { lat: 49.048084, lng: 21.114489 },
    { lat: 49.048279, lng: 21.114019 },
    { lat: 49.048365, lng: 21.113507 },
    { lat: 49.048639, lng: 21.113078 },
    { lat: 49.048775, lng: 21.112962 },
    { lat: 49.049147, lng: 21.112703 },
    { lat: 49.049323, lng: 21.112621 },
    { lat: 49.049487, lng: 21.112499 },
    { lat: 49.049972, lng: 21.112109 },
    { lat: 49.050181, lng: 21.111959 },
    { lat: 49.050571, lng: 21.111462 },
    { lat: 49.050766, lng: 21.111307 },
    { lat: 49.051081, lng: 21.110727 },
    { lat: 49.051457, lng: 21.110033 },
    { lat: 49.051452, lng: 21.11 },
    { lat: 49.051354, lng: 21.109809 },
    { lat: 49.051224, lng: 21.109557 },
    { lat: 49.050781, lng: 21.109213 },
    { lat: 49.050593, lng: 21.109052 },
    { lat: 49.05025, lng: 21.108862 },
    { lat: 49.049882, lng: 21.108589 },
    { lat: 49.048964, lng: 21.107697 },
    { lat: 49.048681, lng: 21.107466 },
    { lat: 49.048447, lng: 21.107105 },
    { lat: 49.048323, lng: 21.106784 },
    { lat: 49.048185, lng: 21.106546 },
    { lat: 49.048065, lng: 21.10642 },
    { lat: 49.047787, lng: 21.10624 },
    { lat: 49.047614, lng: 21.106363 },
    { lat: 49.047431, lng: 21.106227 },
    { lat: 49.047275, lng: 21.106383 },
    { lat: 49.04695, lng: 21.106467 },
    { lat: 49.046628, lng: 21.106313 },
    { lat: 49.046396, lng: 21.106528 },
    { lat: 49.046281, lng: 21.106626 },
    { lat: 49.045931, lng: 21.106667 },
    { lat: 49.04572, lng: 21.106619 },
    { lat: 49.045566, lng: 21.106419 },
    { lat: 49.045315, lng: 21.106014 },
    { lat: 49.04519, lng: 21.105658 },
    { lat: 49.044942, lng: 21.105192 },
    { lat: 49.044932, lng: 21.105048 },
    { lat: 49.044828, lng: 21.104949 },
    { lat: 49.044786, lng: 21.104667 },
    { lat: 49.044624, lng: 21.104588 },
    { lat: 49.044549, lng: 21.104455 },
    { lat: 49.044457, lng: 21.104402 },
    { lat: 49.044409, lng: 21.10418 },
    { lat: 49.04432, lng: 21.104024 },
    { lat: 49.044225, lng: 21.103969 },
    { lat: 49.044224, lng: 21.103625 },
    { lat: 49.044107, lng: 21.10346 },
    { lat: 49.043982, lng: 21.103459 },
    { lat: 49.043745, lng: 21.103178 },
    { lat: 49.043431, lng: 21.103299 },
    { lat: 49.043415, lng: 21.103244 },
    { lat: 49.043288, lng: 21.103172 },
    { lat: 49.043194, lng: 21.103212 },
    { lat: 49.043002, lng: 21.103019 },
    { lat: 49.042869, lng: 21.103026 },
    { lat: 49.042804, lng: 21.102935 },
    { lat: 49.042684, lng: 21.102875 },
    { lat: 49.042549, lng: 21.1027 },
    { lat: 49.042476, lng: 21.102757 },
    { lat: 49.042366, lng: 21.10272 },
    { lat: 49.04222, lng: 21.102679 },
    { lat: 49.042066, lng: 21.102513 },
    { lat: 49.04198, lng: 21.102364 },
    { lat: 49.041912, lng: 21.102351 },
    { lat: 49.041829, lng: 21.102406 },
    { lat: 49.041753, lng: 21.102349 },
    { lat: 49.041736, lng: 21.102273 },
    { lat: 49.041737, lng: 21.102165 },
    { lat: 49.041403, lng: 21.102267 },
    { lat: 49.041199, lng: 21.102192 },
  ],
  Haniska: [
    { lat: 48.9560227, lng: 21.2272458 },
    { lat: 48.9559366, lng: 21.2277777 },
    { lat: 48.9560508, lng: 21.2284101 },
    { lat: 48.9561114, lng: 21.2301442 },
    { lat: 48.955974, lng: 21.2307957 },
    { lat: 48.9556405, lng: 21.2314021 },
    { lat: 48.9556091, lng: 21.2315848 },
    { lat: 48.9557727, lng: 21.2323985 },
    { lat: 48.9554592, lng: 21.2334661 },
    { lat: 48.9556268, lng: 21.235357 },
    { lat: 48.9554759, lng: 21.2354616 },
    { lat: 48.9551841, lng: 21.2353083 },
    { lat: 48.9547972, lng: 21.2353166 },
    { lat: 48.9545115, lng: 21.2352432 },
    { lat: 48.9541689, lng: 21.2354824 },
    { lat: 48.9539844, lng: 21.23541 },
    { lat: 48.9529176, lng: 21.2359587 },
    { lat: 48.9525117, lng: 21.2360768 },
    { lat: 48.9516612, lng: 21.2366667 },
    { lat: 48.9510705, lng: 21.2367066 },
    { lat: 48.9501824, lng: 21.2360095 },
    { lat: 48.9502595, lng: 21.2358815 },
    { lat: 48.9492638, lng: 21.2354191 },
    { lat: 48.9494618, lng: 21.2326123 },
    { lat: 48.9493704, lng: 21.2318781 },
    { lat: 48.9489121, lng: 21.2323085 },
    { lat: 48.9487974, lng: 21.2329963 },
    { lat: 48.9485779, lng: 21.2338357 },
    { lat: 48.9483595, lng: 21.2345591 },
    { lat: 48.9482668, lng: 21.2346878 },
    { lat: 48.948356, lng: 21.2351029 },
    { lat: 48.9486802, lng: 21.2376862 },
    { lat: 48.9484353, lng: 21.2382446 },
    { lat: 48.9487229, lng: 21.2388521 },
    { lat: 48.9486401, lng: 21.2390513 },
    { lat: 48.9486132, lng: 21.2390099 },
    { lat: 48.9485689, lng: 21.2390746 },
    { lat: 48.9495285, lng: 21.2405966 },
    { lat: 48.9502081, lng: 21.2411737 },
    { lat: 48.9506864, lng: 21.2414676 },
    { lat: 48.9511937, lng: 21.2416392 },
    { lat: 48.9526119, lng: 21.2418691 },
    { lat: 48.9525944, lng: 21.2420658 },
    { lat: 48.9528968, lng: 21.2424954 },
    { lat: 48.9527274, lng: 21.2427685 },
    { lat: 48.9526337, lng: 21.2433774 },
    { lat: 48.9524985, lng: 21.2435589 },
    { lat: 48.9522284, lng: 21.2431889 },
    { lat: 48.952016, lng: 21.2436312 },
    { lat: 48.9516656, lng: 21.2441663 },
    { lat: 48.9518415, lng: 21.2445122 },
    { lat: 48.9515712, lng: 21.2448698 },
    { lat: 48.9505906, lng: 21.2450706 },
    { lat: 48.9503709, lng: 21.2450579 },
    { lat: 48.9500458, lng: 21.2448866 },
    { lat: 48.9498287, lng: 21.2447435 },
    { lat: 48.9497851, lng: 21.2446374 },
    { lat: 48.9497087, lng: 21.2443528 },
    { lat: 48.9497609, lng: 21.2439964 },
    { lat: 48.9496524, lng: 21.2434764 },
    { lat: 48.9492967, lng: 21.2433968 },
    { lat: 48.9491534, lng: 21.243499 },
    { lat: 48.9490393, lng: 21.2440642 },
    { lat: 48.9484329, lng: 21.2444747 },
    { lat: 48.9480438, lng: 21.2445872 },
    { lat: 48.9479611, lng: 21.2446812 },
    { lat: 48.9478709, lng: 21.2450764 },
    { lat: 48.9475469, lng: 21.2457013 },
    { lat: 48.9474086, lng: 21.2457738 },
    { lat: 48.9472503, lng: 21.2459716 },
    { lat: 48.9468554, lng: 21.2465921 },
    { lat: 48.9467709, lng: 21.2469583 },
    { lat: 48.9466508, lng: 21.2471469 },
    { lat: 48.9466363, lng: 21.247333 },
    { lat: 48.9467256, lng: 21.2476378 },
    { lat: 48.9469534, lng: 21.2481268 },
    { lat: 48.9471609, lng: 21.2483236 },
    { lat: 48.9477552, lng: 21.2482599 },
    { lat: 48.9479137, lng: 21.2483961 },
    { lat: 48.9480321, lng: 21.2484056 },
    { lat: 48.9482297, lng: 21.2481378 },
    { lat: 48.9482047, lng: 21.2479079 },
    { lat: 48.9483597, lng: 21.2476062 },
    { lat: 48.9485971, lng: 21.2476025 },
    { lat: 48.9487439, lng: 21.2477278 },
    { lat: 48.9487794, lng: 21.2480769 },
    { lat: 48.9487375, lng: 21.2480716 },
    { lat: 48.9487388, lng: 21.2481517 },
    { lat: 48.9488022, lng: 21.2485643 },
    { lat: 48.9488605, lng: 21.2485909 },
    { lat: 48.9491696, lng: 21.2489095 },
    { lat: 48.9493784, lng: 21.2497328 },
    { lat: 48.9501331, lng: 21.2531199 },
    { lat: 48.9503138, lng: 21.2540863 },
    { lat: 48.9503717, lng: 21.2547653 },
    { lat: 48.9506926, lng: 21.2549573 },
    { lat: 48.9510154, lng: 21.255208 },
    { lat: 48.9522678, lng: 21.2565202 },
    { lat: 48.9523831, lng: 21.2561669 },
    { lat: 48.95276, lng: 21.2556553 },
    { lat: 48.9529718, lng: 21.2556271 },
    { lat: 48.9531914, lng: 21.2557039 },
    { lat: 48.9533865, lng: 21.2558988 },
    { lat: 48.9534692, lng: 21.2561034 },
    { lat: 48.953536, lng: 21.257064 },
    { lat: 48.9536869, lng: 21.2572961 },
    { lat: 48.9546212, lng: 21.2574483 },
    { lat: 48.955139, lng: 21.2572734 },
    { lat: 48.9551584, lng: 21.2574578 },
    { lat: 48.9563311, lng: 21.257176 },
    { lat: 48.9563038, lng: 21.2569487 },
    { lat: 48.956642, lng: 21.256856 },
    { lat: 48.9571276, lng: 21.2565462 },
    { lat: 48.9572151, lng: 21.2563914 },
    { lat: 48.9575688, lng: 21.2552652 },
    { lat: 48.9577698, lng: 21.2540126 },
    { lat: 48.9596551, lng: 21.2466088 },
    { lat: 48.9599524, lng: 21.2445736 },
    { lat: 48.9600345, lng: 21.2431837 },
    { lat: 48.9621824, lng: 21.2438617 },
    { lat: 48.9622215, lng: 21.2440756 },
    { lat: 48.9625948, lng: 21.2444268 },
    { lat: 48.9627156, lng: 21.2439706 },
    { lat: 48.9630508, lng: 21.243272 },
    { lat: 48.9632137, lng: 21.2424962 },
    { lat: 48.9638099, lng: 21.2412855 },
    { lat: 48.9636856, lng: 21.2411618 },
    { lat: 48.9633894, lng: 21.240934 },
    { lat: 48.9617574, lng: 21.2401256 },
    { lat: 48.9605838, lng: 21.2389562 },
    { lat: 48.9598718, lng: 21.2384275 },
    { lat: 48.9593318, lng: 21.2382812 },
    { lat: 48.9587817, lng: 21.2382524 },
    { lat: 48.9588859, lng: 21.2379861 },
    { lat: 48.9585155, lng: 21.237866 },
    { lat: 48.9587632, lng: 21.2374355 },
    { lat: 48.9586897, lng: 21.2369783 },
    { lat: 48.958642, lng: 21.2364105 },
    { lat: 48.958637, lng: 21.2363329 },
    { lat: 48.9586814, lng: 21.2359971 },
    { lat: 48.9587599, lng: 21.2356191 },
    { lat: 48.9588433, lng: 21.2354803 },
    { lat: 48.9588675, lng: 21.2353156 },
    { lat: 48.9590909, lng: 21.2352578 },
    { lat: 48.9592264, lng: 21.2350938 },
    { lat: 48.9593168, lng: 21.2345251 },
    { lat: 48.9596219, lng: 21.233549 },
    { lat: 48.9597049, lng: 21.2328867 },
    { lat: 48.9596742, lng: 21.232838 },
    { lat: 48.9597286, lng: 21.2327761 },
    { lat: 48.9595474, lng: 21.2323017 },
    { lat: 48.9595316, lng: 21.2320507 },
    { lat: 48.9592381, lng: 21.231436 },
    { lat: 48.959134, lng: 21.2313084 },
    { lat: 48.958971, lng: 21.2307002 },
    { lat: 48.9589381, lng: 21.2301772 },
    { lat: 48.9588554, lng: 21.2298662 },
    { lat: 48.958728, lng: 21.2296433 },
    { lat: 48.9587044, lng: 21.2293542 },
    { lat: 48.9586213, lng: 21.2292921 },
    { lat: 48.9585081, lng: 21.2293349 },
    { lat: 48.9581825, lng: 21.229148 },
    { lat: 48.9578188, lng: 21.2293201 },
    { lat: 48.9576533, lng: 21.229208 },
    { lat: 48.9576868, lng: 21.229057 },
    { lat: 48.9571805, lng: 21.2287655 },
    { lat: 48.9570228, lng: 21.2287979 },
    { lat: 48.9567771, lng: 21.2285336 },
    { lat: 48.9566407, lng: 21.2284915 },
    { lat: 48.956525, lng: 21.2283125 },
    { lat: 48.9563499, lng: 21.2277152 },
    { lat: 48.9563761, lng: 21.2273581 },
    { lat: 48.9560227, lng: 21.2272458 },
  ],
  Čelovce: [
    { lat: 49.0876316, lng: 21.3902006 },
    { lat: 49.0865196, lng: 21.3909501 },
    { lat: 49.0828137, lng: 21.3910399 },
    { lat: 49.0824872, lng: 21.3914158 },
    { lat: 49.0821482, lng: 21.3914807 },
    { lat: 49.0816605, lng: 21.3917671 },
    { lat: 49.0814264, lng: 21.391656 },
    { lat: 49.0803292, lng: 21.3918728 },
    { lat: 49.0798959, lng: 21.3920209 },
    { lat: 49.0795338, lng: 21.3917269 },
    { lat: 49.0789868, lng: 21.3920224 },
    { lat: 49.0785463, lng: 21.3923475 },
    { lat: 49.0783658, lng: 21.3926373 },
    { lat: 49.0781817, lng: 21.3927666 },
    { lat: 49.0780153, lng: 21.3927309 },
    { lat: 49.0777559, lng: 21.3931678 },
    { lat: 49.0769248, lng: 21.3937203 },
    { lat: 49.0759276, lng: 21.3943142 },
    { lat: 49.0757612, lng: 21.3942466 },
    { lat: 49.0753143, lng: 21.3942736 },
    { lat: 49.0750833, lng: 21.3949885 },
    { lat: 49.0747127, lng: 21.3952758 },
    { lat: 49.0739162, lng: 21.3955139 },
    { lat: 49.0733974, lng: 21.39524 },
    { lat: 49.0729496, lng: 21.3952466 },
    { lat: 49.0728579, lng: 21.3951107 },
    { lat: 49.0725363, lng: 21.3952314 },
    { lat: 49.072246, lng: 21.3950047 },
    { lat: 49.0720045, lng: 21.3950499 },
    { lat: 49.0718645, lng: 21.3948854 },
    { lat: 49.0717152, lng: 21.3949002 },
    { lat: 49.0712005, lng: 21.394611 },
    { lat: 49.0705184, lng: 21.3940683 },
    { lat: 49.0696758, lng: 21.3936555 },
    { lat: 49.0697639, lng: 21.3934747 },
    { lat: 49.0694281, lng: 21.3933846 },
    { lat: 49.0690605, lng: 21.3934327 },
    { lat: 49.068906, lng: 21.3936474 },
    { lat: 49.0687347, lng: 21.3936437 },
    { lat: 49.0683294, lng: 21.3940496 },
    { lat: 49.0680978, lng: 21.394097 },
    { lat: 49.0677234, lng: 21.3943368 },
    { lat: 49.0674242, lng: 21.3943347 },
    { lat: 49.0670963, lng: 21.3944328 },
    { lat: 49.0668837, lng: 21.3946698 },
    { lat: 49.0661158, lng: 21.3949494 },
    { lat: 49.0659161, lng: 21.3953168 },
    { lat: 49.065593, lng: 21.3951953 },
    { lat: 49.0655012, lng: 21.3953606 },
    { lat: 49.0654334, lng: 21.3953541 },
    { lat: 49.0651291, lng: 21.3951822 },
    { lat: 49.0647963, lng: 21.3952325 },
    { lat: 49.0647301, lng: 21.395429 },
    { lat: 49.0646651, lng: 21.3954385 },
    { lat: 49.0646073, lng: 21.3952705 },
    { lat: 49.0644855, lng: 21.3952236 },
    { lat: 49.0643414, lng: 21.3953343 },
    { lat: 49.064206, lng: 21.395317 },
    { lat: 49.0642248, lng: 21.3949619 },
    { lat: 49.0641474, lng: 21.3947458 },
    { lat: 49.0640435, lng: 21.3947708 },
    { lat: 49.0639056, lng: 21.3946687 },
    { lat: 49.0639662, lng: 21.3942447 },
    { lat: 49.0637893, lng: 21.393898 },
    { lat: 49.0635908, lng: 21.3932697 },
    { lat: 49.0634306, lng: 21.3931055 },
    { lat: 49.0631895, lng: 21.3924062 },
    { lat: 49.062984, lng: 21.3921927 },
    { lat: 49.0628695, lng: 21.3918913 },
    { lat: 49.0625738, lng: 21.3918993 },
    { lat: 49.062542, lng: 21.3916611 },
    { lat: 49.0623198, lng: 21.3913376 },
    { lat: 49.0619958, lng: 21.3913037 },
    { lat: 49.0618579, lng: 21.3910896 },
    { lat: 49.0613361, lng: 21.3910636 },
    { lat: 49.0610776, lng: 21.3911491 },
    { lat: 49.0609067, lng: 21.3915335 },
    { lat: 49.0606324, lng: 21.3917324 },
    { lat: 49.0604101, lng: 21.3920135 },
    { lat: 49.0600054, lng: 21.3921061 },
    { lat: 49.0599745, lng: 21.3922786 },
    { lat: 49.0597064, lng: 21.3926062 },
    { lat: 49.05951, lng: 21.392743 },
    { lat: 49.0594159, lng: 21.3930218 },
    { lat: 49.0587677, lng: 21.3934352 },
    { lat: 49.0587022, lng: 21.3932036 },
    { lat: 49.0586066, lng: 21.39309 },
    { lat: 49.057816, lng: 21.3928021 },
    { lat: 49.056811, lng: 21.3932855 },
    { lat: 49.0569568, lng: 21.3936863 },
    { lat: 49.0570882, lng: 21.3942485 },
    { lat: 49.0573031, lng: 21.3955472 },
    { lat: 49.0574556, lng: 21.3959135 },
    { lat: 49.0578256, lng: 21.3990343 },
    { lat: 49.0576089, lng: 21.3992837 },
    { lat: 49.057854, lng: 21.4008394 },
    { lat: 49.0587906, lng: 21.4037268 },
    { lat: 49.0588577, lng: 21.4062343 },
    { lat: 49.0587959, lng: 21.406852 },
    { lat: 49.0593579, lng: 21.409473 },
    { lat: 49.0593684, lng: 21.4111707 },
    { lat: 49.0592693, lng: 21.4117863 },
    { lat: 49.0588206, lng: 21.4119072 },
    { lat: 49.0585689, lng: 21.4118385 },
    { lat: 49.0583632, lng: 21.4118902 },
    { lat: 49.0586287, lng: 21.4135731 },
    { lat: 49.0593773, lng: 21.4155037 },
    { lat: 49.0594237, lng: 21.4155892 },
    { lat: 49.0600553, lng: 21.4147609 },
    { lat: 49.0604522, lng: 21.4147135 },
    { lat: 49.0613238, lng: 21.4136282 },
    { lat: 49.0614988, lng: 21.4135324 },
    { lat: 49.0621718, lng: 21.4126905 },
    { lat: 49.0627277, lng: 21.4121974 },
    { lat: 49.0632116, lng: 21.4131168 },
    { lat: 49.0640686, lng: 21.4120733 },
    { lat: 49.0643142, lng: 21.4115058 },
    { lat: 49.0646044, lng: 21.4112741 },
    { lat: 49.0651156, lng: 21.4112497 },
    { lat: 49.0651887, lng: 21.410905 },
    { lat: 49.0657737, lng: 21.4099007 },
    { lat: 49.0662344, lng: 21.4095392 },
    { lat: 49.0666257, lng: 21.4096429 },
    { lat: 49.0667402, lng: 21.4102501 },
    { lat: 49.0669562, lng: 21.4102835 },
    { lat: 49.0670761, lng: 21.4104555 },
    { lat: 49.0674336, lng: 21.4102977 },
    { lat: 49.0675178, lng: 21.4100577 },
    { lat: 49.0677083, lng: 21.4099213 },
    { lat: 49.0680526, lng: 21.410074 },
    { lat: 49.068333, lng: 21.4098131 },
    { lat: 49.0684613, lng: 21.4099271 },
    { lat: 49.0684958, lng: 21.4101038 },
    { lat: 49.0687618, lng: 21.4101245 },
    { lat: 49.069101, lng: 21.4097598 },
    { lat: 49.0694239, lng: 21.409642 },
    { lat: 49.0697504, lng: 21.4096094 },
    { lat: 49.0698382, lng: 21.4093404 },
    { lat: 49.0700212, lng: 21.4092128 },
    { lat: 49.070101, lng: 21.4094388 },
    { lat: 49.0702424, lng: 21.409389 },
    { lat: 49.0703924, lng: 21.4096734 },
    { lat: 49.0706243, lng: 21.4097484 },
    { lat: 49.0708226, lng: 21.4096836 },
    { lat: 49.0708194, lng: 21.4093436 },
    { lat: 49.0709289, lng: 21.409315 },
    { lat: 49.0709729, lng: 21.4098162 },
    { lat: 49.0710903, lng: 21.4099439 },
    { lat: 49.0714809, lng: 21.4096859 },
    { lat: 49.0716162, lng: 21.4097746 },
    { lat: 49.0715746, lng: 21.4101539 },
    { lat: 49.072017, lng: 21.4106646 },
    { lat: 49.0721624, lng: 21.4103644 },
    { lat: 49.0723256, lng: 21.4102543 },
    { lat: 49.07244, lng: 21.4103498 },
    { lat: 49.072462, lng: 21.4105303 },
    { lat: 49.0723872, lng: 21.4108892 },
    { lat: 49.0722782, lng: 21.4110875 },
    { lat: 49.0722084, lng: 21.4113535 },
    { lat: 49.0723531, lng: 21.4118407 },
    { lat: 49.0725939, lng: 21.411538 },
    { lat: 49.0727309, lng: 21.4115226 },
    { lat: 49.0728613, lng: 21.4115966 },
    { lat: 49.0730021, lng: 21.4118289 },
    { lat: 49.0733942, lng: 21.4115094 },
    { lat: 49.0740469, lng: 21.4114313 },
    { lat: 49.0744462, lng: 21.4106645 },
    { lat: 49.0745304, lng: 21.410385 },
    { lat: 49.0746735, lng: 21.4103609 },
    { lat: 49.0747468, lng: 21.4107453 },
    { lat: 49.0751523, lng: 21.4107985 },
    { lat: 49.0752452, lng: 21.4110495 },
    { lat: 49.0756541, lng: 21.4114513 },
    { lat: 49.0766557, lng: 21.4117665 },
    { lat: 49.0771787, lng: 21.4115281 },
    { lat: 49.0773135, lng: 21.4110998 },
    { lat: 49.0776897, lng: 21.4114571 },
    { lat: 49.0784423, lng: 21.4119494 },
    { lat: 49.0788403, lng: 21.411787 },
    { lat: 49.0788596, lng: 21.4113356 },
    { lat: 49.0787923, lng: 21.4111489 },
    { lat: 49.0792433, lng: 21.4103344 },
    { lat: 49.0797352, lng: 21.410073 },
    { lat: 49.0801568, lng: 21.4096825 },
    { lat: 49.0805518, lng: 21.4091438 },
    { lat: 49.0800088, lng: 21.4073233 },
    { lat: 49.0799499, lng: 21.4070025 },
    { lat: 49.0800525, lng: 21.4071244 },
    { lat: 49.083228, lng: 21.4065665 },
    { lat: 49.0836715, lng: 21.4064135 },
    { lat: 49.0851462, lng: 21.4053673 },
    { lat: 49.0850786, lng: 21.4053313 },
    { lat: 49.084977, lng: 21.405008 },
    { lat: 49.0863355, lng: 21.4038777 },
    { lat: 49.0865169, lng: 21.4034643 },
    { lat: 49.0867174, lng: 21.4028968 },
    { lat: 49.0868764, lng: 21.4021073 },
    { lat: 49.0870696, lng: 21.4002993 },
    { lat: 49.0872521, lng: 21.3990814 },
    { lat: 49.0873373, lng: 21.3986115 },
    { lat: 49.0873672, lng: 21.3981374 },
    { lat: 49.0871523, lng: 21.3964225 },
    { lat: 49.0870639, lng: 21.3952141 },
    { lat: 49.0874742, lng: 21.3939749 },
    { lat: 49.0875193, lng: 21.3931063 },
    { lat: 49.0874759, lng: 21.3914429 },
    { lat: 49.0875585, lng: 21.3904713 },
    { lat: 49.0876316, lng: 21.3902006 },
  ],
  Lažany: [
    { lat: 49.041199, lng: 21.102192 },
    { lat: 49.040733, lng: 21.101416 },
    { lat: 49.04049, lng: 21.101143 },
    { lat: 49.040386, lng: 21.100921 },
    { lat: 49.040129, lng: 21.100741 },
    { lat: 49.039798, lng: 21.100689 },
    { lat: 49.039646, lng: 21.100437 },
    { lat: 49.039584, lng: 21.100144 },
    { lat: 49.039404, lng: 21.099847 },
    { lat: 49.039425, lng: 21.099785 },
    { lat: 49.039582, lng: 21.099652 },
    { lat: 49.03993, lng: 21.099392 },
    { lat: 49.040653, lng: 21.09888 },
    { lat: 49.040513, lng: 21.098814 },
    { lat: 49.040411, lng: 21.09862 },
    { lat: 49.040295, lng: 21.098398 },
    { lat: 49.040242, lng: 21.098296 },
    { lat: 49.040187, lng: 21.09819 },
    { lat: 49.040132, lng: 21.098085 },
    { lat: 49.040077, lng: 21.097979 },
    { lat: 49.039538, lng: 21.096848 },
    { lat: 49.039535, lng: 21.09677 },
    { lat: 49.039622, lng: 21.096745 },
    { lat: 49.040057, lng: 21.096218 },
    { lat: 49.040426, lng: 21.095904 },
    { lat: 49.040664, lng: 21.095813 },
    { lat: 49.040863, lng: 21.095566 },
    { lat: 49.040899, lng: 21.095675 },
    { lat: 49.040919, lng: 21.095962 },
    { lat: 49.040964, lng: 21.096224 },
    { lat: 49.041019, lng: 21.096317 },
    { lat: 49.041321, lng: 21.096407 },
    { lat: 49.041409, lng: 21.096357 },
    { lat: 49.041498, lng: 21.096285 },
    { lat: 49.041578, lng: 21.096084 },
    { lat: 49.041581, lng: 21.095938 },
    { lat: 49.041654, lng: 21.095632 },
    { lat: 49.041706, lng: 21.095472 },
    { lat: 49.041749, lng: 21.095261 },
    { lat: 49.04183, lng: 21.095225 },
    { lat: 49.042042, lng: 21.094971 },
    { lat: 49.042177, lng: 21.094871 },
    { lat: 49.042213, lng: 21.094894 },
    { lat: 49.042241, lng: 21.095068 },
    { lat: 49.042351, lng: 21.095431 },
    { lat: 49.042401, lng: 21.095608 },
    { lat: 49.042452, lng: 21.095669 },
    { lat: 49.042489, lng: 21.095609 },
    { lat: 49.042696, lng: 21.094893 },
    { lat: 49.042745, lng: 21.094854 },
    { lat: 49.04281, lng: 21.094727 },
    { lat: 49.042851, lng: 21.094563 },
    { lat: 49.042861, lng: 21.094467 },
    { lat: 49.042914, lng: 21.094405 },
    { lat: 49.043026, lng: 21.094462 },
    { lat: 49.043075, lng: 21.094503 },
    { lat: 49.043178, lng: 21.094558 },
    { lat: 49.043452, lng: 21.094611 },
    { lat: 49.043619, lng: 21.094621 },
    { lat: 49.043659, lng: 21.094681 },
    { lat: 49.04371, lng: 21.094703 },
    { lat: 49.043717, lng: 21.094627 },
    { lat: 49.043785, lng: 21.094154 },
    { lat: 49.043792, lng: 21.094061 },
    { lat: 49.043929, lng: 21.093933 },
    { lat: 49.044037, lng: 21.09408 },
    { lat: 49.044211, lng: 21.094277 },
    { lat: 49.044308, lng: 21.094365 },
    { lat: 49.044373, lng: 21.094319 },
    { lat: 49.044551, lng: 21.093787 },
    { lat: 49.044577, lng: 21.093666 },
    { lat: 49.04467, lng: 21.093607 },
    { lat: 49.044809, lng: 21.093592 },
    { lat: 49.044907, lng: 21.093563 },
    { lat: 49.04506, lng: 21.093505 },
    { lat: 49.045373, lng: 21.093368 },
    { lat: 49.045408, lng: 21.093328 },
    { lat: 49.045445, lng: 21.093345 },
    { lat: 49.045471, lng: 21.093308 },
    { lat: 49.045391, lng: 21.093103 },
    { lat: 49.045262, lng: 21.092732 },
    { lat: 49.045063, lng: 21.092215 },
    { lat: 49.044958, lng: 21.091946 },
    { lat: 49.044756, lng: 21.091417 },
    { lat: 49.044484, lng: 21.090738 },
    { lat: 49.044404, lng: 21.090564 },
    { lat: 49.044361, lng: 21.090589 },
    { lat: 49.04359, lng: 21.091033 },
    { lat: 49.043386, lng: 21.091267 },
    { lat: 49.043094, lng: 21.091484 },
    { lat: 49.04283, lng: 21.091638 },
    { lat: 49.042534, lng: 21.091777 },
    { lat: 49.042432, lng: 21.091699 },
    { lat: 49.042249, lng: 21.091485 },
    { lat: 49.042243, lng: 21.091462 },
    { lat: 49.042095, lng: 21.091471 },
    { lat: 49.041723, lng: 21.091399 },
    { lat: 49.041447, lng: 21.09121 },
    { lat: 49.040975, lng: 21.090713 },
    { lat: 49.04085, lng: 21.090516 },
    { lat: 49.040795, lng: 21.090244 },
    { lat: 49.040567, lng: 21.089699 },
    { lat: 49.040554, lng: 21.08946 },
    { lat: 49.040735, lng: 21.089027 },
    { lat: 49.041341, lng: 21.087058 },
    { lat: 49.041459, lng: 21.087011 },
    { lat: 49.042105, lng: 21.085533 },
    { lat: 49.04236, lng: 21.085224 },
    { lat: 49.042697, lng: 21.084557 },
    { lat: 49.043188, lng: 21.083611 },
    { lat: 49.043529, lng: 21.083081 },
    { lat: 49.043727, lng: 21.082664 },
    { lat: 49.043846, lng: 21.082497 },
    { lat: 49.043483, lng: 21.082209 },
    { lat: 49.043869, lng: 21.08104 },
    { lat: 49.043831, lng: 21.080882 },
    { lat: 49.043807, lng: 21.080758 },
    { lat: 49.043589, lng: 21.080966 },
    { lat: 49.043419, lng: 21.081058 },
    { lat: 49.043303, lng: 21.081025 },
    { lat: 49.042943, lng: 21.081208 },
    { lat: 49.042572, lng: 21.081171 },
    { lat: 49.042464, lng: 21.081469 },
    { lat: 49.04227, lng: 21.081417 },
    { lat: 49.042077, lng: 21.081572 },
    { lat: 49.041756, lng: 21.081912 },
    { lat: 49.04161, lng: 21.082048 },
    { lat: 49.0411699, lng: 21.0829223 },
    { lat: 49.0408235, lng: 21.0834572 },
    { lat: 49.0408657, lng: 21.0837202 },
    { lat: 49.0407822, lng: 21.0837773 },
    { lat: 49.0405476, lng: 21.0842199 },
    { lat: 49.0403292, lng: 21.0848122 },
    { lat: 49.0399942, lng: 21.0853523 },
    { lat: 49.0396108, lng: 21.0861671 },
    { lat: 49.0395264, lng: 21.0861658 },
    { lat: 49.0393431, lng: 21.085961 },
    { lat: 49.0389235, lng: 21.0852975 },
    { lat: 49.0387216, lng: 21.0846227 },
    { lat: 49.0380749, lng: 21.0855808 },
    { lat: 49.0382066, lng: 21.0856873 },
    { lat: 49.0385458, lng: 21.0862234 },
    { lat: 49.0382043, lng: 21.0870465 },
    { lat: 49.0376354, lng: 21.0882542 },
    { lat: 49.0368446, lng: 21.0895921 },
    { lat: 49.0365677, lng: 21.0892095 },
    { lat: 49.0357961, lng: 21.0901353 },
    { lat: 49.0355671, lng: 21.0902244 },
    { lat: 49.0354883, lng: 21.0904264 },
    { lat: 49.0356607, lng: 21.0906109 },
    { lat: 49.0355999, lng: 21.0909303 },
    { lat: 49.035364, lng: 21.0913589 },
    { lat: 49.035177, lng: 21.0913154 },
    { lat: 49.0347266, lng: 21.090789 },
    { lat: 49.0343197, lng: 21.0910488 },
    { lat: 49.033917, lng: 21.0914409 },
    { lat: 49.0337743, lng: 21.0912363 },
    { lat: 49.033542, lng: 21.0914613 },
    { lat: 49.0336067, lng: 21.0917283 },
    { lat: 49.0334195, lng: 21.0921339 },
    { lat: 49.0332691, lng: 21.0922896 },
    { lat: 49.0330172, lng: 21.0923334 },
    { lat: 49.0331057, lng: 21.093056 },
    { lat: 49.0329201, lng: 21.0932688 },
    { lat: 49.0326913, lng: 21.0932586 },
    { lat: 49.0322397, lng: 21.0935234 },
    { lat: 49.0317578, lng: 21.0931321 },
    { lat: 49.0304773, lng: 21.0939853 },
    { lat: 49.0304471, lng: 21.0955641 },
    { lat: 49.0303472, lng: 21.0956099 },
    { lat: 49.0301327, lng: 21.0974154 },
    { lat: 49.0301073, lng: 21.0973947 },
    { lat: 49.0299875, lng: 21.0986728 },
    { lat: 49.0298471, lng: 21.099451 },
    { lat: 49.0301093, lng: 21.1012104 },
    { lat: 49.0304221, lng: 21.1023665 },
    { lat: 49.0308268, lng: 21.102178 },
    { lat: 49.0318881, lng: 21.1021793 },
    { lat: 49.032456, lng: 21.1020992 },
    { lat: 49.0327118, lng: 21.1019268 },
    { lat: 49.034012, lng: 21.1018713 },
    { lat: 49.035407, lng: 21.1019638 },
    { lat: 49.0354715, lng: 21.1033069 },
    { lat: 49.0374777, lng: 21.1020658 },
    { lat: 49.0384794, lng: 21.1038672 },
    { lat: 49.0397787, lng: 21.1066947 },
    { lat: 49.0400795, lng: 21.106209 },
    { lat: 49.0402913, lng: 21.1056216 },
    { lat: 49.0405224, lng: 21.1051439 },
    { lat: 49.0406361, lng: 21.1043711 },
    { lat: 49.0408353, lng: 21.1035926 },
    { lat: 49.0411216, lng: 21.1029749 },
    { lat: 49.0412009, lng: 21.1025737 },
    { lat: 49.041199, lng: 21.102192 },
  ],
  Štefanovce: [
    { lat: 49.0319657, lng: 20.9619233 },
    { lat: 49.0333396, lng: 20.9645566 },
    { lat: 49.0334765, lng: 20.9650171 },
    { lat: 49.0338064, lng: 20.9652629 },
    { lat: 49.0335547, lng: 20.9656789 },
    { lat: 49.0328259, lng: 20.9665448 },
    { lat: 49.0324439, lng: 20.9671376 },
    { lat: 49.0321589, lng: 20.9677399 },
    { lat: 49.0306555, lng: 20.9698886 },
    { lat: 49.0308587, lng: 20.9718417 },
    { lat: 49.0314066, lng: 20.9746877 },
    { lat: 49.0319167, lng: 20.9782978 },
    { lat: 49.0334212, lng: 20.9772325 },
    { lat: 49.0342722, lng: 20.9803974 },
    { lat: 49.0347467, lng: 20.9805888 },
    { lat: 49.0354447, lng: 20.9819286 },
    { lat: 49.0338261, lng: 20.9844377 },
    { lat: 49.0343021, lng: 20.9853537 },
    { lat: 49.0351482, lng: 20.984158 },
    { lat: 49.0354765, lng: 20.9846584 },
    { lat: 49.0361947, lng: 20.9840117 },
    { lat: 49.0365663, lng: 20.9875284 },
    { lat: 49.0367547, lng: 20.9897936 },
    { lat: 49.0368957, lng: 20.9901419 },
    { lat: 49.0368962, lng: 20.9904643 },
    { lat: 49.0369671, lng: 20.9908409 },
    { lat: 49.0370536, lng: 20.9909742 },
    { lat: 49.037211, lng: 20.9904561 },
    { lat: 49.0378438, lng: 20.9902923 },
    { lat: 49.0382649, lng: 20.9903333 },
    { lat: 49.0393608, lng: 20.9901869 },
    { lat: 49.0401768, lng: 20.9897074 },
    { lat: 49.0407656, lng: 20.9899897 },
    { lat: 49.0410402, lng: 20.989342 },
    { lat: 49.0410293, lng: 20.9891491 },
    { lat: 49.0413403, lng: 20.9891601 },
    { lat: 49.0416103, lng: 20.989395 },
    { lat: 49.042427, lng: 20.9896225 },
    { lat: 49.0427687, lng: 20.9892276 },
    { lat: 49.0431483, lng: 20.9892765 },
    { lat: 49.0434691, lng: 20.9894841 },
    { lat: 49.0437481, lng: 20.9894126 },
    { lat: 49.043891, lng: 20.9892682 },
    { lat: 49.0453538, lng: 20.9892638 },
    { lat: 49.0460796, lng: 20.9888485 },
    { lat: 49.0464568, lng: 20.9887501 },
    { lat: 49.0464876, lng: 20.9885953 },
    { lat: 49.0468344, lng: 20.988392 },
    { lat: 49.0471521, lng: 20.9879931 },
    { lat: 49.0471604, lng: 20.9878009 },
    { lat: 49.0472995, lng: 20.9874512 },
    { lat: 49.0477293, lng: 20.9870682 },
    { lat: 49.0479674, lng: 20.9869844 },
    { lat: 49.0490003, lng: 20.9863605 },
    { lat: 49.049133, lng: 20.986153 },
    { lat: 49.0497818, lng: 20.9858318 },
    { lat: 49.0504318, lng: 20.985611 },
    { lat: 49.0502102, lng: 20.985413 },
    { lat: 49.0499876, lng: 20.9852151 },
    { lat: 49.0496895, lng: 20.9840563 },
    { lat: 49.0491703, lng: 20.9825474 },
    { lat: 49.0484359, lng: 20.9799453 },
    { lat: 49.0478512, lng: 20.9765934 },
    { lat: 49.0478553, lng: 20.9746089 },
    { lat: 49.0468517, lng: 20.9704066 },
    { lat: 49.0463532, lng: 20.968815 },
    { lat: 49.0460511, lng: 20.9681543 },
    { lat: 49.0458454, lng: 20.9673151 },
    { lat: 49.045853, lng: 20.9668991 },
    { lat: 49.0457753, lng: 20.9659489 },
    { lat: 49.0449696, lng: 20.9650733 },
    { lat: 49.0443096, lng: 20.9632943 },
    { lat: 49.0436965, lng: 20.9621568 },
    { lat: 49.0428701, lng: 20.9602486 },
    { lat: 49.0417452, lng: 20.9583358 },
    { lat: 49.0416079, lng: 20.9582072 },
    { lat: 49.040471, lng: 20.9564026 },
    { lat: 49.0404213, lng: 20.9564671 },
    { lat: 49.0397126, lng: 20.9549586 },
    { lat: 49.0398385, lng: 20.95478 },
    { lat: 49.0397332, lng: 20.9545312 },
    { lat: 49.0394033, lng: 20.9543184 },
    { lat: 49.0388509, lng: 20.9542268 },
    { lat: 49.0380151, lng: 20.9537036 },
    { lat: 49.0374843, lng: 20.9537851 },
    { lat: 49.0373478, lng: 20.953872 },
    { lat: 49.0374197, lng: 20.9540666 },
    { lat: 49.0372042, lng: 20.9543964 },
    { lat: 49.0369222, lng: 20.9551829 },
    { lat: 49.036815, lng: 20.9555499 },
    { lat: 49.036756, lng: 20.9562152 },
    { lat: 49.0362773, lng: 20.9570082 },
    { lat: 49.0358369, lng: 20.957611 },
    { lat: 49.0344096, lng: 20.9588597 },
    { lat: 49.0340374, lng: 20.9590724 },
    { lat: 49.0336531, lng: 20.9590879 },
    { lat: 49.0332918, lng: 20.9593234 },
    { lat: 49.0331366, lng: 20.9595014 },
    { lat: 49.0329056, lng: 20.9600009 },
    { lat: 49.0328774, lng: 20.9604378 },
    { lat: 49.0329361, lng: 20.9606499 },
    { lat: 49.0319657, lng: 20.9619233 },
  ],
  Mošurov: [
    { lat: 49.1405256, lng: 21.236322 },
    { lat: 49.1404809, lng: 21.2362577 },
    { lat: 49.1401671, lng: 21.2366665 },
    { lat: 49.1400385, lng: 21.2370893 },
    { lat: 49.1399075, lng: 21.237264 },
    { lat: 49.1395737, lng: 21.2375632 },
    { lat: 49.1393055, lng: 21.2376585 },
    { lat: 49.1390034, lng: 21.2381937 },
    { lat: 49.1386455, lng: 21.2385927 },
    { lat: 49.138479, lng: 21.2385241 },
    { lat: 49.1380873, lng: 21.2386984 },
    { lat: 49.1379637, lng: 21.238599 },
    { lat: 49.1375993, lng: 21.2387868 },
    { lat: 49.137172, lng: 21.2388296 },
    { lat: 49.1365716, lng: 21.2391047 },
    { lat: 49.1362288, lng: 21.2389682 },
    { lat: 49.1359808, lng: 21.2391009 },
    { lat: 49.1357338, lng: 21.2391204 },
    { lat: 49.1352684, lng: 21.2388755 },
    { lat: 49.1351157, lng: 21.2391074 },
    { lat: 49.1348051, lng: 21.2389223 },
    { lat: 49.1342489, lng: 21.2390224 },
    { lat: 49.1338094, lng: 21.2385644 },
    { lat: 49.1335861, lng: 21.2386192 },
    { lat: 49.1334489, lng: 21.2389876 },
    { lat: 49.1331599, lng: 21.2386978 },
    { lat: 49.133024, lng: 21.2386357 },
    { lat: 49.1328726, lng: 21.2386562 },
    { lat: 49.1324391, lng: 21.2390458 },
    { lat: 49.1321157, lng: 21.2389252 },
    { lat: 49.131541, lng: 21.2392602 },
    { lat: 49.1307606, lng: 21.2389477 },
    { lat: 49.1305854, lng: 21.2392542 },
    { lat: 49.1304646, lng: 21.2393515 },
    { lat: 49.1300045, lng: 21.2393194 },
    { lat: 49.1292956, lng: 21.2387345 },
    { lat: 49.1285422, lng: 21.2384197 },
    { lat: 49.1286084, lng: 21.2381577 },
    { lat: 49.128899, lng: 21.2377309 },
    { lat: 49.1290954, lng: 21.2369109 },
    { lat: 49.1286609, lng: 21.2360261 },
    { lat: 49.1277191, lng: 21.234822 },
    { lat: 49.1278786, lng: 21.2342691 },
    { lat: 49.1279733, lng: 21.2314964 },
    { lat: 49.1279511, lng: 21.2310367 },
    { lat: 49.1278011, lng: 21.2285844 },
    { lat: 49.1268486, lng: 21.2293282 },
    { lat: 49.1265854, lng: 21.2294034 },
    { lat: 49.1263652, lng: 21.2292672 },
    { lat: 49.1260939, lng: 21.2300336 },
    { lat: 49.1259915, lng: 21.2301075 },
    { lat: 49.125575, lng: 21.2301366 },
    { lat: 49.1253071, lng: 21.2302424 },
    { lat: 49.124852, lng: 21.2303048 },
    { lat: 49.1246293, lng: 21.230813 },
    { lat: 49.1244706, lng: 21.2309195 },
    { lat: 49.1245132, lng: 21.2310997 },
    { lat: 49.1233969, lng: 21.232412 },
    { lat: 49.1226043, lng: 21.2329828 },
    { lat: 49.1217735, lng: 21.2337752 },
    { lat: 49.1216541, lng: 21.233764 },
    { lat: 49.1214867, lng: 21.233629 },
    { lat: 49.1202716, lng: 21.234515 },
    { lat: 49.1198672, lng: 21.2352247 },
    { lat: 49.1194112, lng: 21.2355472 },
    { lat: 49.1190815, lng: 21.2356617 },
    { lat: 49.1184268, lng: 21.2361382 },
    { lat: 49.117575, lng: 21.237053 },
    { lat: 49.1166589, lng: 21.2378172 },
    { lat: 49.1164758, lng: 21.2378978 },
    { lat: 49.1163976, lng: 21.2380615 },
    { lat: 49.1163708, lng: 21.2383633 },
    { lat: 49.1168448, lng: 21.2392817 },
    { lat: 49.1169639, lng: 21.2399723 },
    { lat: 49.1172229, lng: 21.2407099 },
    { lat: 49.1170538, lng: 21.2405873 },
    { lat: 49.117208, lng: 21.2409154 },
    { lat: 49.1169937, lng: 21.2407565 },
    { lat: 49.1168562, lng: 21.2407861 },
    { lat: 49.1119871, lng: 21.2429441 },
    { lat: 49.1113767, lng: 21.2438735 },
    { lat: 49.1109376, lng: 21.2440844 },
    { lat: 49.1105248, lng: 21.2442047 },
    { lat: 49.1085572, lng: 21.2439228 },
    { lat: 49.1083554, lng: 21.2455975 },
    { lat: 49.1079846, lng: 21.2466357 },
    { lat: 49.1077296, lng: 21.2471323 },
    { lat: 49.1068514, lng: 21.2484315 },
    { lat: 49.1055782, lng: 21.2499871 },
    { lat: 49.1039368, lng: 21.2516131 },
    { lat: 49.1039884, lng: 21.2517499 },
    { lat: 49.1035713, lng: 21.2527582 },
    { lat: 49.1023311, lng: 21.2561984 },
    { lat: 49.1023589, lng: 21.2568802 },
    { lat: 49.1022512, lng: 21.2569295 },
    { lat: 49.1021948, lng: 21.257112 },
    { lat: 49.1026981, lng: 21.2571236 },
    { lat: 49.1027134, lng: 21.2573606 },
    { lat: 49.1029825, lng: 21.2573709 },
    { lat: 49.1032582, lng: 21.2572562 },
    { lat: 49.1037981, lng: 21.2578775 },
    { lat: 49.1046527, lng: 21.2582962 },
    { lat: 49.1048607, lng: 21.2582915 },
    { lat: 49.1048557, lng: 21.2584955 },
    { lat: 49.1049322, lng: 21.2586775 },
    { lat: 49.1052177, lng: 21.2589309 },
    { lat: 49.1054482, lng: 21.2592481 },
    { lat: 49.1056519, lng: 21.2591417 },
    { lat: 49.1061561, lng: 21.2592574 },
    { lat: 49.1062587, lng: 21.2594067 },
    { lat: 49.1061244, lng: 21.2597646 },
    { lat: 49.1061822, lng: 21.2601405 },
    { lat: 49.1062647, lng: 21.2603404 },
    { lat: 49.1064576, lng: 21.2605426 },
    { lat: 49.1066959, lng: 21.2606381 },
    { lat: 49.107064, lng: 21.2607161 },
    { lat: 49.1072024, lng: 21.2605705 },
    { lat: 49.107363, lng: 21.260155 },
    { lat: 49.1074466, lng: 21.2600593 },
    { lat: 49.1079281, lng: 21.2596534 },
    { lat: 49.1082641, lng: 21.2594521 },
    { lat: 49.1085912, lng: 21.2592234 },
    { lat: 49.1088194, lng: 21.2591066 },
    { lat: 49.1093538, lng: 21.2591764 },
    { lat: 49.109682, lng: 21.2590913 },
    { lat: 49.1101321, lng: 21.2591599 },
    { lat: 49.1105111, lng: 21.2590877 },
    { lat: 49.1109786, lng: 21.2584751 },
    { lat: 49.1113694, lng: 21.2582318 },
    { lat: 49.1117355, lng: 21.2585768 },
    { lat: 49.1119996, lng: 21.2584902 },
    { lat: 49.1124506, lng: 21.2580436 },
    { lat: 49.1128822, lng: 21.2582468 },
    { lat: 49.1131063, lng: 21.25821 },
    { lat: 49.113725, lng: 21.2567407 },
    { lat: 49.113821, lng: 21.2566024 },
    { lat: 49.1147374, lng: 21.2561734 },
    { lat: 49.1151579, lng: 21.2555393 },
    { lat: 49.1152706, lng: 21.2555446 },
    { lat: 49.1152558, lng: 21.2556861 },
    { lat: 49.1156676, lng: 21.2556137 },
    { lat: 49.1158148, lng: 21.2558295 },
    { lat: 49.116277, lng: 21.2560793 },
    { lat: 49.1168252, lng: 21.2562685 },
    { lat: 49.1175131, lng: 21.2561646 },
    { lat: 49.1177189, lng: 21.2557954 },
    { lat: 49.1179132, lng: 21.2557862 },
    { lat: 49.1183059, lng: 21.2559663 },
    { lat: 49.1192027, lng: 21.2551244 },
    { lat: 49.1196198, lng: 21.2545605 },
    { lat: 49.1196459, lng: 21.2548575 },
    { lat: 49.1216676, lng: 21.2530507 },
    { lat: 49.1218814, lng: 21.2530487 },
    { lat: 49.122535, lng: 21.2524583 },
    { lat: 49.122812, lng: 21.2523168 },
    { lat: 49.1226412, lng: 21.2506372 },
    { lat: 49.1233831, lng: 21.2500663 },
    { lat: 49.1246332, lng: 21.2489267 },
    { lat: 49.1251983, lng: 21.2486381 },
    { lat: 49.1254694, lng: 21.248636 },
    { lat: 49.1261676, lng: 21.2484361 },
    { lat: 49.1269246, lng: 21.2483559 },
    { lat: 49.1270263, lng: 21.2485509 },
    { lat: 49.127153, lng: 21.2486443 },
    { lat: 49.1275681, lng: 21.2491924 },
    { lat: 49.1277286, lng: 21.2492923 },
    { lat: 49.1281278, lng: 21.2534634 },
    { lat: 49.1281826, lng: 21.2563162 },
    { lat: 49.128378, lng: 21.2575121 },
    { lat: 49.1281018, lng: 21.2588964 },
    { lat: 49.1279442, lng: 21.2601044 },
    { lat: 49.1270623, lng: 21.2594151 },
    { lat: 49.1261716, lng: 21.2595043 },
    { lat: 49.1259726, lng: 21.2615311 },
    { lat: 49.1258927, lng: 21.2615723 },
    { lat: 49.125183, lng: 21.2613953 },
    { lat: 49.125449, lng: 21.2626203 },
    { lat: 49.1242964, lng: 21.2628681 },
    { lat: 49.1238824, lng: 21.2628393 },
    { lat: 49.1240122, lng: 21.2635138 },
    { lat: 49.1238064, lng: 21.2635517 },
    { lat: 49.1237061, lng: 21.2648368 },
    { lat: 49.1246618, lng: 21.2645911 },
    { lat: 49.1255943, lng: 21.2682255 },
    { lat: 49.1258684, lng: 21.2680979 },
    { lat: 49.1264359, lng: 21.2682969 },
    { lat: 49.126647, lng: 21.268508 },
    { lat: 49.1267283, lng: 21.2686588 },
    { lat: 49.1270939, lng: 21.2683257 },
    { lat: 49.1280774, lng: 21.2680314 },
    { lat: 49.1287169, lng: 21.2676013 },
    { lat: 49.1289709, lng: 21.2675567 },
    { lat: 49.1291177, lng: 21.2674288 },
    { lat: 49.1292908, lng: 21.2673841 },
    { lat: 49.1294648, lng: 21.267513 },
    { lat: 49.1301617, lng: 21.2675653 },
    { lat: 49.1304208, lng: 21.2675415 },
    { lat: 49.1307921, lng: 21.2673143 },
    { lat: 49.1309347, lng: 21.2674914 },
    { lat: 49.1310255, lng: 21.2675133 },
    { lat: 49.1312427, lng: 21.2673287 },
    { lat: 49.1312924, lng: 21.2671894 },
    { lat: 49.131647, lng: 21.2667176 },
    { lat: 49.1326491, lng: 21.2666616 },
    { lat: 49.1325971, lng: 21.2655542 },
    { lat: 49.132654, lng: 21.2636971 },
    { lat: 49.1328707, lng: 21.2634988 },
    { lat: 49.1328814, lng: 21.2628915 },
    { lat: 49.1332623, lng: 21.262725 },
    { lat: 49.1332047, lng: 21.2620514 },
    { lat: 49.1333308, lng: 21.2619799 },
    { lat: 49.1333369, lng: 21.2610772 },
    { lat: 49.1341347, lng: 21.2613759 },
    { lat: 49.1344194, lng: 21.2605691 },
    { lat: 49.1346399, lng: 21.2606781 },
    { lat: 49.1348555, lng: 21.2601791 },
    { lat: 49.1346805, lng: 21.2593927 },
    { lat: 49.1348307, lng: 21.2576845 },
    { lat: 49.1353022, lng: 21.2573494 },
    { lat: 49.1362396, lng: 21.2570226 },
    { lat: 49.1363279, lng: 21.2565258 },
    { lat: 49.1362688, lng: 21.2562515 },
    { lat: 49.1367225, lng: 21.2556669 },
    { lat: 49.1367763, lng: 21.2554083 },
    { lat: 49.1369796, lng: 21.2549529 },
    { lat: 49.1369573, lng: 21.2547722 },
    { lat: 49.1370159, lng: 21.2541913 },
    { lat: 49.1368774, lng: 21.2534196 },
    { lat: 49.1367249, lng: 21.2530682 },
    { lat: 49.1363999, lng: 21.2525257 },
    { lat: 49.1349985, lng: 21.2505639 },
    { lat: 49.1358268, lng: 21.2498318 },
    { lat: 49.1360207, lng: 21.2497343 },
    { lat: 49.1361378, lng: 21.2495179 },
    { lat: 49.1368582, lng: 21.2494209 },
    { lat: 49.1371697, lng: 21.2488988 },
    { lat: 49.1373386, lng: 21.2487541 },
    { lat: 49.1375745, lng: 21.248848 },
    { lat: 49.1382958, lng: 21.2486334 },
    { lat: 49.1384967, lng: 21.2482493 },
    { lat: 49.1386846, lng: 21.2483203 },
    { lat: 49.1388487, lng: 21.2480582 },
    { lat: 49.1390746, lng: 21.2481204 },
    { lat: 49.139546, lng: 21.2478977 },
    { lat: 49.1398754, lng: 21.2478516 },
    { lat: 49.1400295, lng: 21.24745 },
    { lat: 49.1403143, lng: 21.2472331 },
    { lat: 49.1404737, lng: 21.2468878 },
    { lat: 49.1406906, lng: 21.2467315 },
    { lat: 49.1408528, lng: 21.2464489 },
    { lat: 49.1411069, lng: 21.2464771 },
    { lat: 49.1413375, lng: 21.2459622 },
    { lat: 49.1412915, lng: 21.2456377 },
    { lat: 49.1414255, lng: 21.2452346 },
    { lat: 49.141466, lng: 21.2447933 },
    { lat: 49.1412355, lng: 21.2438537 },
    { lat: 49.1408564, lng: 21.2430503 },
    { lat: 49.1404248, lng: 21.2413452 },
    { lat: 49.1402603, lng: 21.2408643 },
    { lat: 49.1399026, lng: 21.2411935 },
    { lat: 49.139595, lng: 21.2403771 },
    { lat: 49.1402668, lng: 21.2393213 },
    { lat: 49.1411035, lng: 21.2382077 },
    { lat: 49.1406123, lng: 21.2364675 },
    { lat: 49.1405256, lng: 21.236322 },
  ],
  Fintice: [
    { lat: 49.0468381, lng: 21.2504856 },
    { lat: 49.0466723, lng: 21.2513504 },
    { lat: 49.0464938, lng: 21.2516587 },
    { lat: 49.046401, lng: 21.2521539 },
    { lat: 49.0464378, lng: 21.2532733 },
    { lat: 49.0463961, lng: 21.2536052 },
    { lat: 49.0461506, lng: 21.2541123 },
    { lat: 49.0460394, lng: 21.2542303 },
    { lat: 49.0460019, lng: 21.2544741 },
    { lat: 49.0460068, lng: 21.2546333 },
    { lat: 49.0462099, lng: 21.255421 },
    { lat: 49.0461331, lng: 21.2561815 },
    { lat: 49.0457558, lng: 21.2572332 },
    { lat: 49.0458979, lng: 21.257646 },
    { lat: 49.045529, lng: 21.2582357 },
    { lat: 49.0454368, lng: 21.2586795 },
    { lat: 49.0448613, lng: 21.2603123 },
    { lat: 49.0446858, lng: 21.2610127 },
    { lat: 49.0444529, lng: 21.2620451 },
    { lat: 49.0443187, lng: 21.262913 },
    { lat: 49.0442808, lng: 21.2632532 },
    { lat: 49.044342, lng: 21.263642 },
    { lat: 49.0442279, lng: 21.2638431 },
    { lat: 49.0441576, lng: 21.2641985 },
    { lat: 49.0441257, lng: 21.2646951 },
    { lat: 49.0439886, lng: 21.2652173 },
    { lat: 49.0439914, lng: 21.2656375 },
    { lat: 49.0438799, lng: 21.2658556 },
    { lat: 49.0440533, lng: 21.266706 },
    { lat: 49.0442418, lng: 21.2669481 },
    { lat: 49.0447053, lng: 21.2701435 },
    { lat: 49.0446895, lng: 21.2706547 },
    { lat: 49.044486, lng: 21.2717653 },
    { lat: 49.0439418, lng: 21.2735843 },
    { lat: 49.0434753, lng: 21.2745539 },
    { lat: 49.0428251, lng: 21.2749309 },
    { lat: 49.0418585, lng: 21.2758542 },
    { lat: 49.041607, lng: 21.2759125 },
    { lat: 49.0414304, lng: 21.2760581 },
    { lat: 49.0408607, lng: 21.2762658 },
    { lat: 49.0406168, lng: 21.2765721 },
    { lat: 49.0405455, lng: 21.2770011 },
    { lat: 49.0405561, lng: 21.2775362 },
    { lat: 49.0404332, lng: 21.2780427 },
    { lat: 49.0404132, lng: 21.2786417 },
    { lat: 49.0403597, lng: 21.2788807 },
    { lat: 49.0402732, lng: 21.2792534 },
    { lat: 49.0402605, lng: 21.2794623 },
    { lat: 49.040107, lng: 21.2795962 },
    { lat: 49.039934, lng: 21.279645 },
    { lat: 49.0396818, lng: 21.2794135 },
    { lat: 49.0394236, lng: 21.2793618 },
    { lat: 49.0393907, lng: 21.279542 },
    { lat: 49.0397453, lng: 21.2800603 },
    { lat: 49.0398809, lng: 21.2805558 },
    { lat: 49.0401186, lng: 21.2810106 },
    { lat: 49.0402271, lng: 21.28139 },
    { lat: 49.040383, lng: 21.2816702 },
    { lat: 49.0405852, lng: 21.2818457 },
    { lat: 49.0407438, lng: 21.2819593 },
    { lat: 49.0409924, lng: 21.2823561 },
    { lat: 49.0412774, lng: 21.2825537 },
    { lat: 49.0413688, lng: 21.2825282 },
    { lat: 49.0415504, lng: 21.2826144 },
    { lat: 49.0418238, lng: 21.2832074 },
    { lat: 49.0422427, lng: 21.2836592 },
    { lat: 49.0425276, lng: 21.2841116 },
    { lat: 49.0430863, lng: 21.2853071 },
    { lat: 49.0432267, lng: 21.2855182 },
    { lat: 49.0437519, lng: 21.2857703 },
    { lat: 49.0438538, lng: 21.2859791 },
    { lat: 49.043711, lng: 21.2867571 },
    { lat: 49.0436718, lng: 21.2871372 },
    { lat: 49.0436898, lng: 21.2875018 },
    { lat: 49.0438881, lng: 21.2878809 },
    { lat: 49.0441637, lng: 21.2882141 },
    { lat: 49.0443651, lng: 21.2883542 },
    { lat: 49.0447484, lng: 21.288786 },
    { lat: 49.0449777, lng: 21.2888186 },
    { lat: 49.0451827, lng: 21.2892357 },
    { lat: 49.0454086, lng: 21.2899623 },
    { lat: 49.0453521, lng: 21.2901073 },
    { lat: 49.0453901, lng: 21.2902075 },
    { lat: 49.0454944, lng: 21.2902343 },
    { lat: 49.0456788, lng: 21.2904512 },
    { lat: 49.0457859, lng: 21.291013 },
    { lat: 49.045751, lng: 21.2911603 },
    { lat: 49.0461783, lng: 21.2919592 },
    { lat: 49.0461248, lng: 21.2920925 },
    { lat: 49.0461609, lng: 21.2923479 },
    { lat: 49.0464046, lng: 21.2924473 },
    { lat: 49.0466289, lng: 21.2926826 },
    { lat: 49.0464584, lng: 21.2939985 },
    { lat: 49.0472849, lng: 21.296502 },
    { lat: 49.0473646, lng: 21.2966047 },
    { lat: 49.0473613, lng: 21.2969074 },
    { lat: 49.0474777, lng: 21.2973322 },
    { lat: 49.0469787, lng: 21.2974221 },
    { lat: 49.0469841, lng: 21.2977087 },
    { lat: 49.0470544, lng: 21.2980298 },
    { lat: 49.0476144, lng: 21.2987524 },
    { lat: 49.0479148, lng: 21.3000883 },
    { lat: 49.0481148, lng: 21.3005955 },
    { lat: 49.0482766, lng: 21.3019287 },
    { lat: 49.0483818, lng: 21.303246 },
    { lat: 49.0488625, lng: 21.3049238 },
    { lat: 49.0487338, lng: 21.3058443 },
    { lat: 49.0485184, lng: 21.306052 },
    { lat: 49.0484617, lng: 21.3064449 },
    { lat: 49.0480847, lng: 21.3073738 },
    { lat: 49.0479499, lng: 21.3074553 },
    { lat: 49.0477914, lng: 21.3076856 },
    { lat: 49.047688, lng: 21.3077226 },
    { lat: 49.047318, lng: 21.3082105 },
    { lat: 49.0471948, lng: 21.3084968 },
    { lat: 49.04745, lng: 21.3087483 },
    { lat: 49.0476141, lng: 21.308702 },
    { lat: 49.0482316, lng: 21.3088802 },
    { lat: 49.0485447, lng: 21.3088923 },
    { lat: 49.0509223, lng: 21.3096005 },
    { lat: 49.05128, lng: 21.3096709 },
    { lat: 49.0518703, lng: 21.309649 },
    { lat: 49.0535231, lng: 21.310131 },
    { lat: 49.0538612, lng: 21.3101529 },
    { lat: 49.0541664, lng: 21.3111231 },
    { lat: 49.0546678, lng: 21.311299 },
    { lat: 49.0554518, lng: 21.3109866 },
    { lat: 49.0556827, lng: 21.3108101 },
    { lat: 49.0559173, lng: 21.3104969 },
    { lat: 49.0561225, lng: 21.3100635 },
    { lat: 49.0563128, lng: 21.3099925 },
    { lat: 49.056808, lng: 21.3095517 },
    { lat: 49.0570126, lng: 21.3092432 },
    { lat: 49.0572312, lng: 21.3091048 },
    { lat: 49.0579629, lng: 21.3080379 },
    { lat: 49.058299, lng: 21.3078382 },
    { lat: 49.0588386, lng: 21.3076382 },
    { lat: 49.0590579, lng: 21.3071496 },
    { lat: 49.0588269, lng: 21.3057618 },
    { lat: 49.0589561, lng: 21.3053488 },
    { lat: 49.0592226, lng: 21.3036682 },
    { lat: 49.0592921, lng: 21.302842 },
    { lat: 49.0595219, lng: 21.3019608 },
    { lat: 49.0598228, lng: 21.3015619 },
    { lat: 49.059891, lng: 21.3012134 },
    { lat: 49.0600839, lng: 21.3006789 },
    { lat: 49.0605802, lng: 21.2998601 },
    { lat: 49.060957, lng: 21.2990044 },
    { lat: 49.0611308, lng: 21.2984999 },
    { lat: 49.0613147, lng: 21.2981433 },
    { lat: 49.0613485, lng: 21.2978894 },
    { lat: 49.0615436, lng: 21.2972312 },
    { lat: 49.0618658, lng: 21.2964332 },
    { lat: 49.0623093, lng: 21.2957339 },
    { lat: 49.0624424, lng: 21.295224 },
    { lat: 49.0626031, lng: 21.2949192 },
    { lat: 49.0628251, lng: 21.2941526 },
    { lat: 49.0630393, lng: 21.2936438 },
    { lat: 49.0633662, lng: 21.2932584 },
    { lat: 49.063584, lng: 21.2929068 },
    { lat: 49.0637873, lng: 21.2922628 },
    { lat: 49.0638162, lng: 21.2918478 },
    { lat: 49.064028, lng: 21.2910061 },
    { lat: 49.0650734, lng: 21.2886747 },
    { lat: 49.0663371, lng: 21.2861958 },
    { lat: 49.0669686, lng: 21.2847241 },
    { lat: 49.0670907, lng: 21.2846301 },
    { lat: 49.0673847, lng: 21.2839391 },
    { lat: 49.0674444, lng: 21.2831665 },
    { lat: 49.0674048, lng: 21.2828262 },
    { lat: 49.0670075, lng: 21.2824947 },
    { lat: 49.067142, lng: 21.2815533 },
    { lat: 49.0673635, lng: 21.2809914 },
    { lat: 49.0675699, lng: 21.280196 },
    { lat: 49.0676674, lng: 21.2791473 },
    { lat: 49.068072, lng: 21.277547 },
    { lat: 49.0686341, lng: 21.2760165 },
    { lat: 49.0683495, lng: 21.275944 },
    { lat: 49.0683663, lng: 21.2753525 },
    { lat: 49.0687164, lng: 21.2748806 },
    { lat: 49.0686919, lng: 21.2735204 },
    { lat: 49.0691112, lng: 21.2714847 },
    { lat: 49.0691644, lng: 21.2709017 },
    { lat: 49.0697716, lng: 21.2688953 },
    { lat: 49.0699955, lng: 21.2679054 },
    { lat: 49.070486, lng: 21.2670894 },
    { lat: 49.0706843, lng: 21.2668428 },
    { lat: 49.0708971, lng: 21.2662549 },
    { lat: 49.0709191, lng: 21.2659085 },
    { lat: 49.0705883, lng: 21.2632295 },
    { lat: 49.070371, lng: 21.2626011 },
    { lat: 49.070343, lng: 21.2615394 },
    { lat: 49.070517, lng: 21.2599084 },
    { lat: 49.0709287, lng: 21.2585079 },
    { lat: 49.0710982, lng: 21.258128 },
    { lat: 49.0716278, lng: 21.2575603 },
    { lat: 49.0719208, lng: 21.2571023 },
    { lat: 49.0721477, lng: 21.2568684 },
    { lat: 49.072445, lng: 21.2566886 },
    { lat: 49.0725929, lng: 21.2563766 },
    { lat: 49.0720238, lng: 21.2549955 },
    { lat: 49.0719083, lng: 21.2545303 },
    { lat: 49.0721797, lng: 21.2534009 },
    { lat: 49.0722214, lng: 21.2529217 },
    { lat: 49.0724041, lng: 21.2524996 },
    { lat: 49.0727165, lng: 21.2524806 },
    { lat: 49.0729595, lng: 21.252225 },
    { lat: 49.0730247, lng: 21.2518901 },
    { lat: 49.0730472, lng: 21.2505344 },
    { lat: 49.0731359, lng: 21.2501993 },
    { lat: 49.0732298, lng: 21.2500003 },
    { lat: 49.0738737, lng: 21.2493841 },
    { lat: 49.0740894, lng: 21.2490461 },
    { lat: 49.0741572, lng: 21.2486504 },
    { lat: 49.0740703, lng: 21.2483472 },
    { lat: 49.0739805, lng: 21.2469685 },
    { lat: 49.0740019, lng: 21.2467829 },
    { lat: 49.074391, lng: 21.2456404 },
    { lat: 49.0744346, lng: 21.2450806 },
    { lat: 49.0745525, lng: 21.2446763 },
    { lat: 49.0748651, lng: 21.244191 },
    { lat: 49.07513, lng: 21.2440619 },
    { lat: 49.0752789, lng: 21.2438133 },
    { lat: 49.0752839, lng: 21.2432118 },
    { lat: 49.0754801, lng: 21.242308 },
    { lat: 49.0756879, lng: 21.2418194 },
    { lat: 49.0761991, lng: 21.2410992 },
    { lat: 49.0763818, lng: 21.2404555 },
    { lat: 49.0763679, lng: 21.2400423 },
    { lat: 49.0764112, lng: 21.2395866 },
    { lat: 49.0763261, lng: 21.2393439 },
    { lat: 49.0758588, lng: 21.2387879 },
    { lat: 49.0756548, lng: 21.2386639 },
    { lat: 49.0750473, lng: 21.238638 },
    { lat: 49.0748753, lng: 21.2384974 },
    { lat: 49.0736724, lng: 21.239589 },
    { lat: 49.0733284, lng: 21.2400396 },
    { lat: 49.0723701, lng: 21.2407817 },
    { lat: 49.0717198, lng: 21.240822 },
    { lat: 49.0711301, lng: 21.2416274 },
    { lat: 49.0704192, lng: 21.2419933 },
    { lat: 49.0690228, lng: 21.243097 },
    { lat: 49.0677047, lng: 21.2429366 },
    { lat: 49.0675355, lng: 21.242995 },
    { lat: 49.0669187, lng: 21.2436853 },
    { lat: 49.0665188, lng: 21.2438538 },
    { lat: 49.0650221, lng: 21.2439583 },
    { lat: 49.063892, lng: 21.244973 },
    { lat: 49.0633914, lng: 21.2457844 },
    { lat: 49.0630759, lng: 21.2460592 },
    { lat: 49.062668, lng: 21.246774 },
    { lat: 49.0620793, lng: 21.2475357 },
    { lat: 49.0611575, lng: 21.2479728 },
    { lat: 49.0599055, lng: 21.2482037 },
    { lat: 49.0593341, lng: 21.248648 },
    { lat: 49.0582679, lng: 21.2496166 },
    { lat: 49.057738, lng: 21.2490013 },
    { lat: 49.0576698, lng: 21.2486144 },
    { lat: 49.0570051, lng: 21.2482186 },
    { lat: 49.056805, lng: 21.2479372 },
    { lat: 49.0554622, lng: 21.2490198 },
    { lat: 49.0553473, lng: 21.248398 },
    { lat: 49.0543268, lng: 21.248805 },
    { lat: 49.0543923, lng: 21.2492076 },
    { lat: 49.053775, lng: 21.2498467 },
    { lat: 49.0532348, lng: 21.2502163 },
    { lat: 49.0530582, lng: 21.2505207 },
    { lat: 49.0529547, lng: 21.2506929 },
    { lat: 49.0521314, lng: 21.2511404 },
    { lat: 49.051535, lng: 21.2510684 },
    { lat: 49.0510862, lng: 21.2511505 },
    { lat: 49.0503288, lng: 21.2508909 },
    { lat: 49.0499127, lng: 21.2503095 },
    { lat: 49.0499437, lng: 21.2500067 },
    { lat: 49.0497985, lng: 21.2496694 },
    { lat: 49.0496943, lng: 21.2492506 },
    { lat: 49.0491235, lng: 21.2492364 },
    { lat: 49.0487219, lng: 21.2496804 },
    { lat: 49.048261, lng: 21.2505738 },
    { lat: 49.0475662, lng: 21.2512908 },
    { lat: 49.0468381, lng: 21.2504856 },
  ],
  Chmeľov: [
    { lat: 49.067178, lng: 21.462822 },
    { lat: 49.067525, lng: 21.462651 },
    { lat: 49.067943, lng: 21.462241 },
    { lat: 49.068048, lng: 21.462084 },
    { lat: 49.068347, lng: 21.461585 },
    { lat: 49.068479, lng: 21.461375 },
    { lat: 49.068723, lng: 21.460863 },
    { lat: 49.06909, lng: 21.46052 },
    { lat: 49.069138, lng: 21.460477 },
    { lat: 49.069356, lng: 21.460049 },
    { lat: 49.069387, lng: 21.460003 },
    { lat: 49.069807, lng: 21.459331 },
    { lat: 49.0702, lng: 21.458422 },
    { lat: 49.070361, lng: 21.458107 },
    { lat: 49.070652, lng: 21.457531 },
    { lat: 49.07086, lng: 21.456874 },
    { lat: 49.070898, lng: 21.456754 },
    { lat: 49.071129, lng: 21.456318 },
    { lat: 49.071142, lng: 21.456295 },
    { lat: 49.071246, lng: 21.456407 },
    { lat: 49.071497, lng: 21.456773 },
    { lat: 49.071591, lng: 21.45705 },
    { lat: 49.071849, lng: 21.457226 },
    { lat: 49.072125, lng: 21.457528 },
    { lat: 49.072219, lng: 21.457658 },
    { lat: 49.072499, lng: 21.457995 },
    { lat: 49.072539, lng: 21.458017 },
    { lat: 49.072735, lng: 21.458405 },
    { lat: 49.073043, lng: 21.458806 },
    { lat: 49.073384, lng: 21.459735 },
    { lat: 49.073697, lng: 21.460428 },
    { lat: 49.073707, lng: 21.460445 },
    { lat: 49.074117, lng: 21.461439 },
    { lat: 49.074134, lng: 21.46147 },
    { lat: 49.074828, lng: 21.462788 },
    { lat: 49.074845, lng: 21.462824 },
    { lat: 49.075291, lng: 21.463654 },
    { lat: 49.075693, lng: 21.463754 },
    { lat: 49.076482, lng: 21.464006 },
    { lat: 49.076822, lng: 21.464574 },
    { lat: 49.077183, lng: 21.46483 },
    { lat: 49.07737, lng: 21.465048 },
    { lat: 49.077414, lng: 21.465121 },
    { lat: 49.077569, lng: 21.465371 },
    { lat: 49.077611, lng: 21.465332 },
    { lat: 49.077623, lng: 21.46532 },
    { lat: 49.077619, lng: 21.464841 },
    { lat: 49.077634, lng: 21.464248 },
    { lat: 49.077622, lng: 21.46345 },
    { lat: 49.077627, lng: 21.463434 },
    { lat: 49.077746, lng: 21.463077 },
    { lat: 49.077819, lng: 21.462735 },
    { lat: 49.078066, lng: 21.462112 },
    { lat: 49.078321, lng: 21.461416 },
    { lat: 49.078381, lng: 21.460863 },
    { lat: 49.078399, lng: 21.460686 },
    { lat: 49.078629, lng: 21.460126 },
    { lat: 49.078702, lng: 21.459951 },
    { lat: 49.07896, lng: 21.459424 },
    { lat: 49.078985, lng: 21.459353 },
    { lat: 49.079186, lng: 21.458765 },
    { lat: 49.079207, lng: 21.458706 },
    { lat: 49.079248, lng: 21.458594 },
    { lat: 49.079377, lng: 21.458235 },
    { lat: 49.079378, lng: 21.45823 },
    { lat: 49.079488, lng: 21.457924 },
    { lat: 49.079593, lng: 21.457383 },
    { lat: 49.079668, lng: 21.456999 },
    { lat: 49.079674, lng: 21.456964 },
    { lat: 49.079825, lng: 21.456141 },
    { lat: 49.079838, lng: 21.456066 },
    { lat: 49.079851, lng: 21.455997 },
    { lat: 49.079863, lng: 21.45591 },
    { lat: 49.079913, lng: 21.455569 },
    { lat: 49.079976, lng: 21.455138 },
    { lat: 49.08003, lng: 21.454874 },
    { lat: 49.080221, lng: 21.453936 },
    { lat: 49.080297, lng: 21.453698 },
    { lat: 49.080371, lng: 21.453345 },
    { lat: 49.080427, lng: 21.452952 },
    { lat: 49.080636, lng: 21.452223 },
    { lat: 49.080514, lng: 21.451689 },
    { lat: 49.080568, lng: 21.451425 },
    { lat: 49.080602, lng: 21.451259 },
    { lat: 49.080559, lng: 21.450194 },
    { lat: 49.080552, lng: 21.449278 },
    { lat: 49.080552, lng: 21.449249 },
    { lat: 49.080565, lng: 21.449199 },
    { lat: 49.08078, lng: 21.448385 },
    { lat: 49.080853, lng: 21.448107 },
    { lat: 49.080905, lng: 21.447872 },
    { lat: 49.080922, lng: 21.447795 },
    { lat: 49.080916, lng: 21.447788 },
    { lat: 49.080782, lng: 21.447634 },
    { lat: 49.080754, lng: 21.447626 },
    { lat: 49.080875, lng: 21.447315 },
    { lat: 49.080897, lng: 21.44714 },
    { lat: 49.080912, lng: 21.447024 },
    { lat: 49.080957, lng: 21.446995 },
    { lat: 49.08105, lng: 21.446937 },
    { lat: 49.081218, lng: 21.446831 },
    { lat: 49.081366, lng: 21.446737 },
    { lat: 49.081377, lng: 21.446549 },
    { lat: 49.081402, lng: 21.446159 },
    { lat: 49.081425, lng: 21.445778 },
    { lat: 49.081569, lng: 21.44579 },
    { lat: 49.081706, lng: 21.445695 },
    { lat: 49.081679, lng: 21.445128 },
    { lat: 49.081677, lng: 21.445076 },
    { lat: 49.081737, lng: 21.444847 },
    { lat: 49.081818, lng: 21.444851 },
    { lat: 49.082343, lng: 21.444875 },
    { lat: 49.08293, lng: 21.444587 },
    { lat: 49.083136, lng: 21.444682 },
    { lat: 49.083269, lng: 21.444463 },
    { lat: 49.083296, lng: 21.444418 },
    { lat: 49.083389, lng: 21.444431 },
    { lat: 49.083729, lng: 21.444479 },
    { lat: 49.08405, lng: 21.444397 },
    { lat: 49.084205, lng: 21.444319 },
    { lat: 49.084344, lng: 21.444249 },
    { lat: 49.084471, lng: 21.444334 },
    { lat: 49.084474, lng: 21.444346 },
    { lat: 49.084507, lng: 21.444481 },
    { lat: 49.084529, lng: 21.444568 },
    { lat: 49.084837, lng: 21.444546 },
    { lat: 49.08529, lng: 21.444514 },
    { lat: 49.08532, lng: 21.444512 },
    { lat: 49.085364, lng: 21.444535 },
    { lat: 49.085673, lng: 21.444703 },
    { lat: 49.085805, lng: 21.444674 },
    { lat: 49.085844, lng: 21.444666 },
    { lat: 49.085887, lng: 21.444704 },
    { lat: 49.086026, lng: 21.444826 },
    { lat: 49.086908, lng: 21.445025 },
    { lat: 49.086954, lng: 21.445027 },
    { lat: 49.087087, lng: 21.445036 },
    { lat: 49.087095, lng: 21.445021 },
    { lat: 49.087165, lng: 21.444884 },
    { lat: 49.087512, lng: 21.444725 },
    { lat: 49.088108, lng: 21.444405 },
    { lat: 49.088295, lng: 21.444384 },
    { lat: 49.088348, lng: 21.444422 },
    { lat: 49.088419, lng: 21.44447 },
    { lat: 49.088459, lng: 21.444466 },
    { lat: 49.088569, lng: 21.444363 },
    { lat: 49.088733, lng: 21.444207 },
    { lat: 49.088859, lng: 21.444087 },
    { lat: 49.088793, lng: 21.443924 },
    { lat: 49.088757, lng: 21.443831 },
    { lat: 49.088746, lng: 21.443802 },
    { lat: 49.08873, lng: 21.443764 },
    { lat: 49.088709, lng: 21.443713 },
    { lat: 49.088724, lng: 21.443707 },
    { lat: 49.088775, lng: 21.443685 },
    { lat: 49.0885475, lng: 21.4433301 },
    { lat: 49.0882841, lng: 21.4427107 },
    { lat: 49.0875719, lng: 21.442007 },
    { lat: 49.0874286, lng: 21.4416113 },
    { lat: 49.0871415, lng: 21.4412994 },
    { lat: 49.086396, lng: 21.4393373 },
    { lat: 49.086137, lng: 21.4392101 },
    { lat: 49.0861436, lng: 21.4389035 },
    { lat: 49.0859376, lng: 21.4385878 },
    { lat: 49.0859241, lng: 21.4383199 },
    { lat: 49.0857147, lng: 21.4380433 },
    { lat: 49.0855981, lng: 21.4380953 },
    { lat: 49.0854402, lng: 21.437999 },
    { lat: 49.0851547, lng: 21.4374321 },
    { lat: 49.0852582, lng: 21.4365819 },
    { lat: 49.0851281, lng: 21.4360598 },
    { lat: 49.0851009, lng: 21.4357009 },
    { lat: 49.0851954, lng: 21.4355437 },
    { lat: 49.0851343, lng: 21.4352672 },
    { lat: 49.0851592, lng: 21.4343028 },
    { lat: 49.0850719, lng: 21.4338553 },
    { lat: 49.0849466, lng: 21.4322984 },
    { lat: 49.0850267, lng: 21.4314965 },
    { lat: 49.0849957, lng: 21.431168 },
    { lat: 49.0848747, lng: 21.4307619 },
    { lat: 49.0849636, lng: 21.4305693 },
    { lat: 49.0849853, lng: 21.4303812 },
    { lat: 49.0850138, lng: 21.4303979 },
    { lat: 49.0843349, lng: 21.4278418 },
    { lat: 49.084093, lng: 21.425912 },
    { lat: 49.0839956, lng: 21.4258148 },
    { lat: 49.0842166, lng: 21.4251398 },
    { lat: 49.0845333, lng: 21.4248426 },
    { lat: 49.0847397, lng: 21.4247073 },
    { lat: 49.0858548, lng: 21.4244771 },
    { lat: 49.0859782, lng: 21.424435 },
    { lat: 49.0860233, lng: 21.4243566 },
    { lat: 49.0860443, lng: 21.4242252 },
    { lat: 49.0857962, lng: 21.4233195 },
    { lat: 49.0856943, lng: 21.4226831 },
    { lat: 49.0856829, lng: 21.422218 },
    { lat: 49.0857525, lng: 21.4212523 },
    { lat: 49.0859834, lng: 21.4199918 },
    { lat: 49.0868176, lng: 21.4186236 },
    { lat: 49.087127, lng: 21.4176581 },
    { lat: 49.0871805, lng: 21.4170715 },
    { lat: 49.0862105, lng: 21.4169066 },
    { lat: 49.0849831, lng: 21.4162253 },
    { lat: 49.0839212, lng: 21.4164995 },
    { lat: 49.0837333, lng: 21.4164613 },
    { lat: 49.0831523, lng: 21.4157182 },
    { lat: 49.0821219, lng: 21.414958 },
    { lat: 49.081687, lng: 21.4145134 },
    { lat: 49.0811307, lng: 21.4132698 },
    { lat: 49.0798643, lng: 21.4118688 },
    { lat: 49.0788596, lng: 21.4113356 },
    { lat: 49.0788403, lng: 21.411787 },
    { lat: 49.0784423, lng: 21.4119494 },
    { lat: 49.0776897, lng: 21.4114571 },
    { lat: 49.0773135, lng: 21.4110998 },
    { lat: 49.0771787, lng: 21.4115281 },
    { lat: 49.0766557, lng: 21.4117665 },
    { lat: 49.0756541, lng: 21.4114513 },
    { lat: 49.0752452, lng: 21.4110495 },
    { lat: 49.0751523, lng: 21.4107985 },
    { lat: 49.0747468, lng: 21.4107453 },
    { lat: 49.0746735, lng: 21.4103609 },
    { lat: 49.0745304, lng: 21.410385 },
    { lat: 49.0744462, lng: 21.4106645 },
    { lat: 49.0740469, lng: 21.4114313 },
    { lat: 49.0733942, lng: 21.4115094 },
    { lat: 49.0730021, lng: 21.4118289 },
    { lat: 49.0728613, lng: 21.4115966 },
    { lat: 49.0727309, lng: 21.4115226 },
    { lat: 49.0725939, lng: 21.411538 },
    { lat: 49.0723531, lng: 21.4118407 },
    { lat: 49.0722084, lng: 21.4113535 },
    { lat: 49.0722782, lng: 21.4110875 },
    { lat: 49.0723872, lng: 21.4108892 },
    { lat: 49.072462, lng: 21.4105303 },
    { lat: 49.07244, lng: 21.4103498 },
    { lat: 49.0723256, lng: 21.4102543 },
    { lat: 49.0721624, lng: 21.4103644 },
    { lat: 49.072017, lng: 21.4106646 },
    { lat: 49.0715746, lng: 21.4101539 },
    { lat: 49.0716162, lng: 21.4097746 },
    { lat: 49.0714809, lng: 21.4096859 },
    { lat: 49.0710903, lng: 21.4099439 },
    { lat: 49.0709729, lng: 21.4098162 },
    { lat: 49.0709289, lng: 21.409315 },
    { lat: 49.0708194, lng: 21.4093436 },
    { lat: 49.0708226, lng: 21.4096836 },
    { lat: 49.0706243, lng: 21.4097484 },
    { lat: 49.0703924, lng: 21.4096734 },
    { lat: 49.0702424, lng: 21.409389 },
    { lat: 49.070101, lng: 21.4094388 },
    { lat: 49.0700212, lng: 21.4092128 },
    { lat: 49.0698382, lng: 21.4093404 },
    { lat: 49.0697504, lng: 21.4096094 },
    { lat: 49.0694239, lng: 21.409642 },
    { lat: 49.069101, lng: 21.4097598 },
    { lat: 49.0687618, lng: 21.4101245 },
    { lat: 49.0684958, lng: 21.4101038 },
    { lat: 49.0684613, lng: 21.4099271 },
    { lat: 49.068333, lng: 21.4098131 },
    { lat: 49.0680526, lng: 21.410074 },
    { lat: 49.0677083, lng: 21.4099213 },
    { lat: 49.0675178, lng: 21.4100577 },
    { lat: 49.0674336, lng: 21.4102977 },
    { lat: 49.0670761, lng: 21.4104555 },
    { lat: 49.0669562, lng: 21.4102835 },
    { lat: 49.0667402, lng: 21.4102501 },
    { lat: 49.0666257, lng: 21.4096429 },
    { lat: 49.0662344, lng: 21.4095392 },
    { lat: 49.0657737, lng: 21.4099007 },
    { lat: 49.0651887, lng: 21.410905 },
    { lat: 49.0651156, lng: 21.4112497 },
    { lat: 49.0646044, lng: 21.4112741 },
    { lat: 49.0643142, lng: 21.4115058 },
    { lat: 49.0640686, lng: 21.4120733 },
    { lat: 49.0632116, lng: 21.4131168 },
    { lat: 49.0627277, lng: 21.4121974 },
    { lat: 49.0621718, lng: 21.4126905 },
    { lat: 49.0614988, lng: 21.4135324 },
    { lat: 49.0613238, lng: 21.4136282 },
    { lat: 49.0604522, lng: 21.4147135 },
    { lat: 49.0600553, lng: 21.4147609 },
    { lat: 49.0594237, lng: 21.4155892 },
    { lat: 49.0589832, lng: 21.4163427 },
    { lat: 49.0589508, lng: 21.4168077 },
    { lat: 49.0587125, lng: 21.4173161 },
    { lat: 49.0582445, lng: 21.4174365 },
    { lat: 49.0579289, lng: 21.4176093 },
    { lat: 49.0572596, lng: 21.4177225 },
    { lat: 49.0569528, lng: 21.41789 },
    { lat: 49.0566535, lng: 21.4178142 },
    { lat: 49.0564035, lng: 21.4179244 },
    { lat: 49.0561079, lng: 21.4178221 },
    { lat: 49.0556219, lng: 21.4178601 },
    { lat: 49.0552641, lng: 21.4177025 },
    { lat: 49.0551539, lng: 21.4175952 },
    { lat: 49.0542116, lng: 21.4174231 },
    { lat: 49.0534871, lng: 21.417464 },
    { lat: 49.0531306, lng: 21.4175843 },
    { lat: 49.0529819, lng: 21.4174236 },
    { lat: 49.0528406, lng: 21.4173988 },
    { lat: 49.0514954, lng: 21.4174179 },
    { lat: 49.0524065, lng: 21.4189811 },
    { lat: 49.0530694, lng: 21.4209511 },
    { lat: 49.0531839, lng: 21.4210955 },
    { lat: 49.053189, lng: 21.4212275 },
    { lat: 49.0533164, lng: 21.4213914 },
    { lat: 49.0532651, lng: 21.4212586 },
    { lat: 49.053126, lng: 21.4199235 },
    { lat: 49.0539096, lng: 21.4193229 },
    { lat: 49.0543727, lng: 21.4213449 },
    { lat: 49.0559078, lng: 21.4232459 },
    { lat: 49.0559615, lng: 21.425661 },
    { lat: 49.0560381, lng: 21.4263557 },
    { lat: 49.0555742, lng: 21.4284247 },
    { lat: 49.0543614, lng: 21.4280795 },
    { lat: 49.0528126, lng: 21.4259408 },
    { lat: 49.0521894, lng: 21.4264887 },
    { lat: 49.0510232, lng: 21.4254269 },
    { lat: 49.0505616, lng: 21.4254937 },
    { lat: 49.0504804, lng: 21.4251057 },
    { lat: 49.0502775, lng: 21.4250929 },
    { lat: 49.0499844, lng: 21.4247996 },
    { lat: 49.0496515, lng: 21.4247295 },
    { lat: 49.0487916, lng: 21.4249115 },
    { lat: 49.0485833, lng: 21.4248696 },
    { lat: 49.0483903, lng: 21.4247364 },
    { lat: 49.0482262, lng: 21.4245086 },
    { lat: 49.048119, lng: 21.4241094 },
    { lat: 49.0478391, lng: 21.422656 },
    { lat: 49.0470997, lng: 21.4231548 },
    { lat: 49.0467053, lng: 21.4235208 },
    { lat: 49.0460194, lng: 21.4239546 },
    { lat: 49.0457112, lng: 21.4240752 },
    { lat: 49.0453688, lng: 21.4244107 },
    { lat: 49.042666, lng: 21.428278 },
    { lat: 49.043199, lng: 21.428883 },
    { lat: 49.043527, lng: 21.429491 },
    { lat: 49.043779, lng: 21.430058 },
    { lat: 49.043959, lng: 21.430327 },
    { lat: 49.044139, lng: 21.430663 },
    { lat: 49.044704, lng: 21.43057 },
    { lat: 49.045241, lng: 21.43065 },
    { lat: 49.045723, lng: 21.431138 },
    { lat: 49.046131, lng: 21.431844 },
    { lat: 49.0464, lng: 21.432545 },
    { lat: 49.04663, lng: 21.433352 },
    { lat: 49.04663, lng: 21.434 },
    { lat: 49.046414, lng: 21.434657 },
    { lat: 49.046446, lng: 21.43502 },
    { lat: 49.046493, lng: 21.435376 },
    { lat: 49.046445, lng: 21.435738 },
    { lat: 49.046328, lng: 21.435906 },
    { lat: 49.046195, lng: 21.436013 },
    { lat: 49.046135, lng: 21.436427 },
    { lat: 49.046186, lng: 21.437591 },
    { lat: 49.046065, lng: 21.438018 },
    { lat: 49.046081, lng: 21.438039 },
    { lat: 49.046052, lng: 21.438781 },
    { lat: 49.046124, lng: 21.439102 },
    { lat: 49.04613, lng: 21.439116 },
    { lat: 49.046067, lng: 21.439214 },
    { lat: 49.045958, lng: 21.439526 },
    { lat: 49.045927, lng: 21.439784 },
    { lat: 49.045717, lng: 21.439928 },
    { lat: 49.045721, lng: 21.439946 },
    { lat: 49.045734, lng: 21.440005 },
    { lat: 49.045779, lng: 21.440199 },
    { lat: 49.045984, lng: 21.440022 },
    { lat: 49.046173, lng: 21.439973 },
    { lat: 49.046384, lng: 21.440175 },
    { lat: 49.046469, lng: 21.440291 },
    { lat: 49.046986, lng: 21.440652 },
    { lat: 49.047555, lng: 21.441223 },
    { lat: 49.047784, lng: 21.441463 },
    { lat: 49.047976, lng: 21.441709 },
    { lat: 49.048758, lng: 21.442631 },
    { lat: 49.049062, lng: 21.443039 },
    { lat: 49.049674, lng: 21.443679 },
    { lat: 49.050021, lng: 21.442955 },
    { lat: 49.050134, lng: 21.442664 },
    { lat: 49.050709, lng: 21.443631 },
    { lat: 49.051127, lng: 21.444458 },
    { lat: 49.051454, lng: 21.445169 },
    { lat: 49.051681, lng: 21.445611 },
    { lat: 49.051858, lng: 21.445933 },
    { lat: 49.052122, lng: 21.44646 },
    { lat: 49.052371, lng: 21.446959 },
    { lat: 49.052872, lng: 21.448066 },
    { lat: 49.05253, lng: 21.448447 },
    { lat: 49.053059, lng: 21.450237 },
    { lat: 49.053001, lng: 21.450341 },
    { lat: 49.052994, lng: 21.450354 },
    { lat: 49.053168, lng: 21.450804 },
    { lat: 49.053183, lng: 21.450842 },
    { lat: 49.053609, lng: 21.451733 },
    { lat: 49.054077, lng: 21.452549 },
    { lat: 49.05496, lng: 21.453922 },
    { lat: 49.055119, lng: 21.454171 },
    { lat: 49.055955, lng: 21.455087 },
    { lat: 49.056648, lng: 21.456031 },
    { lat: 49.0569, lng: 21.455655 },
    { lat: 49.057481, lng: 21.4567 },
    { lat: 49.0575, lng: 21.456737 },
    { lat: 49.057955, lng: 21.456224 },
    { lat: 49.057992, lng: 21.456252 },
    { lat: 49.058644, lng: 21.456954 },
    { lat: 49.059298, lng: 21.457514 },
    { lat: 49.060206, lng: 21.458169 },
    { lat: 49.060555, lng: 21.458404 },
    { lat: 49.060867, lng: 21.458417 },
    { lat: 49.061293, lng: 21.458437 },
    { lat: 49.061359, lng: 21.458544 },
    { lat: 49.061412, lng: 21.458847 },
    { lat: 49.061499, lng: 21.459054 },
    { lat: 49.061731, lng: 21.45934 },
    { lat: 49.061943, lng: 21.459472 },
    { lat: 49.062355, lng: 21.459587 },
    { lat: 49.063143, lng: 21.460069 },
    { lat: 49.064017, lng: 21.460615 },
    { lat: 49.06443, lng: 21.460984 },
    { lat: 49.064927, lng: 21.461199 },
    { lat: 49.064989, lng: 21.461066 },
    { lat: 49.065138, lng: 21.461191 },
    { lat: 49.065447, lng: 21.461552 },
    { lat: 49.065726, lng: 21.461759 },
    { lat: 49.065657, lng: 21.461931 },
    { lat: 49.066036, lng: 21.462229 },
    { lat: 49.066626, lng: 21.462587 },
    { lat: 49.066889, lng: 21.462698 },
    { lat: 49.066925, lng: 21.462714 },
    { lat: 49.067178, lng: 21.462822 },
  ],
  Pušovce: [
    { lat: 49.0872521, lng: 21.3990814 },
    { lat: 49.0870696, lng: 21.4002993 },
    { lat: 49.0868764, lng: 21.4021073 },
    { lat: 49.0867174, lng: 21.4028968 },
    { lat: 49.0865169, lng: 21.4034643 },
    { lat: 49.0863355, lng: 21.4038777 },
    { lat: 49.084977, lng: 21.405008 },
    { lat: 49.0850786, lng: 21.4053313 },
    { lat: 49.0851462, lng: 21.4053673 },
    { lat: 49.0836715, lng: 21.4064135 },
    { lat: 49.083228, lng: 21.4065665 },
    { lat: 49.0800525, lng: 21.4071244 },
    { lat: 49.0799499, lng: 21.4070025 },
    { lat: 49.0800088, lng: 21.4073233 },
    { lat: 49.0805518, lng: 21.4091438 },
    { lat: 49.0801568, lng: 21.4096825 },
    { lat: 49.0797352, lng: 21.410073 },
    { lat: 49.0792433, lng: 21.4103344 },
    { lat: 49.0787923, lng: 21.4111489 },
    { lat: 49.0788596, lng: 21.4113356 },
    { lat: 49.0798643, lng: 21.4118688 },
    { lat: 49.0811307, lng: 21.4132698 },
    { lat: 49.081687, lng: 21.4145134 },
    { lat: 49.0821219, lng: 21.414958 },
    { lat: 49.0831523, lng: 21.4157182 },
    { lat: 49.0837333, lng: 21.4164613 },
    { lat: 49.0839212, lng: 21.4164995 },
    { lat: 49.0849831, lng: 21.4162253 },
    { lat: 49.0862105, lng: 21.4169066 },
    { lat: 49.0871805, lng: 21.4170715 },
    { lat: 49.087127, lng: 21.4176581 },
    { lat: 49.0868176, lng: 21.4186236 },
    { lat: 49.0859834, lng: 21.4199918 },
    { lat: 49.0857525, lng: 21.4212523 },
    { lat: 49.0856829, lng: 21.422218 },
    { lat: 49.0856943, lng: 21.4226831 },
    { lat: 49.0857962, lng: 21.4233195 },
    { lat: 49.0860443, lng: 21.4242252 },
    { lat: 49.0860233, lng: 21.4243566 },
    { lat: 49.0859782, lng: 21.424435 },
    { lat: 49.0858548, lng: 21.4244771 },
    { lat: 49.0847397, lng: 21.4247073 },
    { lat: 49.0845333, lng: 21.4248426 },
    { lat: 49.0842166, lng: 21.4251398 },
    { lat: 49.0839956, lng: 21.4258148 },
    { lat: 49.084093, lng: 21.425912 },
    { lat: 49.0843349, lng: 21.4278418 },
    { lat: 49.0850138, lng: 21.4303979 },
    { lat: 49.0849853, lng: 21.4303812 },
    { lat: 49.0849636, lng: 21.4305693 },
    { lat: 49.0848747, lng: 21.4307619 },
    { lat: 49.0849957, lng: 21.431168 },
    { lat: 49.0850267, lng: 21.4314965 },
    { lat: 49.0849466, lng: 21.4322984 },
    { lat: 49.0850719, lng: 21.4338553 },
    { lat: 49.0851592, lng: 21.4343028 },
    { lat: 49.0851343, lng: 21.4352672 },
    { lat: 49.0851954, lng: 21.4355437 },
    { lat: 49.0851009, lng: 21.4357009 },
    { lat: 49.0851281, lng: 21.4360598 },
    { lat: 49.0852582, lng: 21.4365819 },
    { lat: 49.0851547, lng: 21.4374321 },
    { lat: 49.0854402, lng: 21.437999 },
    { lat: 49.0855981, lng: 21.4380953 },
    { lat: 49.0857147, lng: 21.4380433 },
    { lat: 49.0859241, lng: 21.4383199 },
    { lat: 49.0859376, lng: 21.4385878 },
    { lat: 49.0861436, lng: 21.4389035 },
    { lat: 49.086137, lng: 21.4392101 },
    { lat: 49.086396, lng: 21.4393373 },
    { lat: 49.0871415, lng: 21.4412994 },
    { lat: 49.0874286, lng: 21.4416113 },
    { lat: 49.0875719, lng: 21.442007 },
    { lat: 49.0882841, lng: 21.4427107 },
    { lat: 49.0885475, lng: 21.4433301 },
    { lat: 49.088775, lng: 21.443685 },
    { lat: 49.088842, lng: 21.443655 },
    { lat: 49.088926, lng: 21.443577 },
    { lat: 49.089373, lng: 21.443762 },
    { lat: 49.089726, lng: 21.443849 },
    { lat: 49.090771, lng: 21.444455 },
    { lat: 49.091924, lng: 21.445048 },
    { lat: 49.09245, lng: 21.445351 },
    { lat: 49.093008, lng: 21.445716 },
    { lat: 49.093024, lng: 21.445727 },
    { lat: 49.093149, lng: 21.445363 },
    { lat: 49.09314, lng: 21.445131 },
    { lat: 49.093309, lng: 21.444381 },
    { lat: 49.093409, lng: 21.444045 },
    { lat: 49.093584, lng: 21.443654 },
    { lat: 49.093614, lng: 21.443467 },
    { lat: 49.093765, lng: 21.443204 },
    { lat: 49.093807, lng: 21.442989 },
    { lat: 49.093905, lng: 21.44262 },
    { lat: 49.093988, lng: 21.442146 },
    { lat: 49.094064, lng: 21.441911 },
    { lat: 49.094098, lng: 21.441745 },
    { lat: 49.094192, lng: 21.441529 },
    { lat: 49.094212, lng: 21.44122 },
    { lat: 49.094268, lng: 21.441059 },
    { lat: 49.09431, lng: 21.440793 },
    { lat: 49.094375, lng: 21.440491 },
    { lat: 49.094422, lng: 21.440326 },
    { lat: 49.094465, lng: 21.440006 },
    { lat: 49.094455, lng: 21.4399 },
    { lat: 49.094567, lng: 21.439333 },
    { lat: 49.094671, lng: 21.439079 },
    { lat: 49.094725, lng: 21.43884 },
    { lat: 49.094862, lng: 21.43863 },
    { lat: 49.09499, lng: 21.438291 },
    { lat: 49.095213, lng: 21.437981 },
    { lat: 49.095227, lng: 21.437962 },
    { lat: 49.095214, lng: 21.437944 },
    { lat: 49.094513, lng: 21.436865 },
    { lat: 49.094487, lng: 21.435979 },
    { lat: 49.094504, lng: 21.435794 },
    { lat: 49.094495, lng: 21.434701 },
    { lat: 49.094495, lng: 21.434616 },
    { lat: 49.09447, lng: 21.434624 },
    { lat: 49.094504, lng: 21.434277 },
    { lat: 49.094564, lng: 21.433349 },
    { lat: 49.094696, lng: 21.43292 },
    { lat: 49.094681, lng: 21.432627 },
    { lat: 49.094766, lng: 21.432306 },
    { lat: 49.094843, lng: 21.43202 },
    { lat: 49.095133, lng: 21.431383 },
    { lat: 49.095134, lng: 21.431357 },
    { lat: 49.095255, lng: 21.430909 },
    { lat: 49.095341, lng: 21.430412 },
    { lat: 49.095555, lng: 21.429784 },
    { lat: 49.095722, lng: 21.42946 },
    { lat: 49.095737, lng: 21.429359 },
    { lat: 49.095819, lng: 21.429128 },
    { lat: 49.095916, lng: 21.429039 },
    { lat: 49.096212, lng: 21.428999 },
    { lat: 49.096596, lng: 21.428732 },
    { lat: 49.096873, lng: 21.428479 },
    { lat: 49.096974, lng: 21.428422 },
    { lat: 49.097235, lng: 21.428047 },
    { lat: 49.097568, lng: 21.427638 },
    { lat: 49.097645, lng: 21.427447 },
    { lat: 49.097773, lng: 21.427515 },
    { lat: 49.098365, lng: 21.426665 },
    { lat: 49.098518, lng: 21.426822 },
    { lat: 49.098658, lng: 21.426911 },
    { lat: 49.098819, lng: 21.42695 },
    { lat: 49.098893, lng: 21.426719 },
    { lat: 49.09898, lng: 21.426644 },
    { lat: 49.099228, lng: 21.426547 },
    { lat: 49.099379, lng: 21.426444 },
    { lat: 49.099476, lng: 21.426286 },
    { lat: 49.099525, lng: 21.426141 },
    { lat: 49.099523, lng: 21.425896 },
    { lat: 49.099693, lng: 21.425636 },
    { lat: 49.099654, lng: 21.425526 },
    { lat: 49.099871, lng: 21.425271 },
    { lat: 49.10001, lng: 21.425211 },
    { lat: 49.101439, lng: 21.424571 },
    { lat: 49.101078, lng: 21.423703 },
    { lat: 49.101164, lng: 21.422184 },
    { lat: 49.100745, lng: 21.421679 },
    { lat: 49.101076, lng: 21.421185 },
    { lat: 49.100766, lng: 21.420482 },
    { lat: 49.101198, lng: 21.420232 },
    { lat: 49.101696, lng: 21.42118 },
    { lat: 49.101791, lng: 21.42135 },
    { lat: 49.101918, lng: 21.421579 },
    { lat: 49.102812, lng: 21.421204 },
    { lat: 49.102984, lng: 21.420801 },
    { lat: 49.103191, lng: 21.420371 },
    { lat: 49.103277, lng: 21.4201 },
    { lat: 49.103372, lng: 21.419642 },
    { lat: 49.10352, lng: 21.419105 },
    { lat: 49.103648, lng: 21.418616 },
    { lat: 49.103811, lng: 21.418051 },
    { lat: 49.103879, lng: 21.41724 },
    { lat: 49.103875, lng: 21.417055 },
    { lat: 49.103837, lng: 21.416912 },
    { lat: 49.103856, lng: 21.416238 },
    { lat: 49.103764, lng: 21.415727 },
    { lat: 49.105332, lng: 21.414618 },
    { lat: 49.105303, lng: 21.414332 },
    { lat: 49.1044827, lng: 21.413561 },
    { lat: 49.1043166, lng: 21.4139566 },
    { lat: 49.1037015, lng: 21.4141697 },
    { lat: 49.1033395, lng: 21.4143677 },
    { lat: 49.1007428, lng: 21.416714 },
    { lat: 49.1002101, lng: 21.4152367 },
    { lat: 49.1002003, lng: 21.4150133 },
    { lat: 49.1000764, lng: 21.4146655 },
    { lat: 49.0997663, lng: 21.4131818 },
    { lat: 49.099147, lng: 21.4114814 },
    { lat: 49.0986545, lng: 21.4109508 },
    { lat: 49.0985928, lng: 21.4108074 },
    { lat: 49.0982662, lng: 21.4105647 },
    { lat: 49.0977393, lng: 21.4098695 },
    { lat: 49.0973228, lng: 21.4095094 },
    { lat: 49.0969423, lng: 21.4097356 },
    { lat: 49.0959799, lng: 21.4098341 },
    { lat: 49.094317, lng: 21.4053948 },
    { lat: 49.0935523, lng: 21.4060971 },
    { lat: 49.0933451, lng: 21.406397 },
    { lat: 49.0927818, lng: 21.4051106 },
    { lat: 49.0927017, lng: 21.403726 },
    { lat: 49.0880016, lng: 21.3996258 },
    { lat: 49.0877941, lng: 21.3993768 },
    { lat: 49.0873634, lng: 21.3991144 },
    { lat: 49.0872521, lng: 21.3990814 },
  ],
  ZlatáBaňa: [
    { lat: 48.9251743, lng: 21.4197956 },
    { lat: 48.9248094, lng: 21.4237756 },
    { lat: 48.9247376, lng: 21.4250421 },
    { lat: 48.9247901, lng: 21.4254579 },
    { lat: 48.9246975, lng: 21.4256781 },
    { lat: 48.9247562, lng: 21.4258323 },
    { lat: 48.9246486, lng: 21.4273499 },
    { lat: 48.9246639, lng: 21.4285647 },
    { lat: 48.9245833, lng: 21.4293777 },
    { lat: 48.9246027, lng: 21.4318879 },
    { lat: 48.924342, lng: 21.4316711 },
    { lat: 48.9239601, lng: 21.4311572 },
    { lat: 48.9237287, lng: 21.4310172 },
    { lat: 48.9232563, lng: 21.4308698 },
    { lat: 48.9227097, lng: 21.4297223 },
    { lat: 48.9226004, lng: 21.4296388 },
    { lat: 48.9218452, lng: 21.4294428 },
    { lat: 48.9215084, lng: 21.4292008 },
    { lat: 48.9211082, lng: 21.428592 },
    { lat: 48.9204269, lng: 21.4281134 },
    { lat: 48.9201557, lng: 21.4280798 },
    { lat: 48.9198332, lng: 21.4282844 },
    { lat: 48.919293, lng: 21.4279691 },
    { lat: 48.9190035, lng: 21.4281153 },
    { lat: 48.9183648, lng: 21.4282042 },
    { lat: 48.9178279, lng: 21.4284304 },
    { lat: 48.9176182, lng: 21.4282263 },
    { lat: 48.9173668, lng: 21.4281266 },
    { lat: 48.9171375, lng: 21.4281388 },
    { lat: 48.9167117, lng: 21.4280105 },
    { lat: 48.9159882, lng: 21.428006 },
    { lat: 48.9157845, lng: 21.4278852 },
    { lat: 48.9152945, lng: 21.4279508 },
    { lat: 48.9151803, lng: 21.4278916 },
    { lat: 48.9149858, lng: 21.4275935 },
    { lat: 48.9148579, lng: 21.4271804 },
    { lat: 48.9146437, lng: 21.4269752 },
    { lat: 48.9144493, lng: 21.4266008 },
    { lat: 48.9142973, lng: 21.426556 },
    { lat: 48.9141612, lng: 21.4266739 },
    { lat: 48.9139485, lng: 21.4266336 },
    { lat: 48.9137237, lng: 21.4259547 },
    { lat: 48.9136472, lng: 21.4255646 },
    { lat: 48.9134676, lng: 21.4254687 },
    { lat: 48.9128603, lng: 21.4253294 },
    { lat: 48.9119854, lng: 21.4253777 },
    { lat: 48.9114397, lng: 21.4251505 },
    { lat: 48.9113275, lng: 21.4263518 },
    { lat: 48.911306, lng: 21.4271148 },
    { lat: 48.9126123, lng: 21.4304974 },
    { lat: 48.9129574, lng: 21.4313298 },
    { lat: 48.9143731, lng: 21.4333325 },
    { lat: 48.9148086, lng: 21.4351456 },
    { lat: 48.9155819, lng: 21.4371606 },
    { lat: 48.9161783, lng: 21.4384717 },
    { lat: 48.9168557, lng: 21.440515 },
    { lat: 48.9178706, lng: 21.443057 },
    { lat: 48.9170166, lng: 21.4444059 },
    { lat: 48.9193184, lng: 21.4500762 },
    { lat: 48.9201937, lng: 21.4521238 },
    { lat: 48.9208446, lng: 21.4540748 },
    { lat: 48.9217332, lng: 21.4559627 },
    { lat: 48.922048, lng: 21.4572726 },
    { lat: 48.9229074, lng: 21.4590903 },
    { lat: 48.9231639, lng: 21.4599412 },
    { lat: 48.9234469, lng: 21.4614325 },
    { lat: 48.9236166, lng: 21.4620495 },
    { lat: 48.921145, lng: 21.4643125 },
    { lat: 48.9204635, lng: 21.465568 },
    { lat: 48.9204466, lng: 21.466768 },
    { lat: 48.9204857, lng: 21.46711 },
    { lat: 48.9204224, lng: 21.4681156 },
    { lat: 48.9206054, lng: 21.4698429 },
    { lat: 48.9208642, lng: 21.4735014 },
    { lat: 48.9212522, lng: 21.476268 },
    { lat: 48.9213767, lng: 21.4768722 },
    { lat: 48.921851, lng: 21.481382 },
    { lat: 48.922739, lng: 21.481138 },
    { lat: 48.92323, lng: 21.480685 },
    { lat: 48.923422, lng: 21.479956 },
    { lat: 48.923718, lng: 21.479326 },
    { lat: 48.924028, lng: 21.479023 },
    { lat: 48.924646, lng: 21.478173 },
    { lat: 48.925129, lng: 21.47715 },
    { lat: 48.925258, lng: 21.476951 },
    { lat: 48.925222, lng: 21.476164 },
    { lat: 48.925051, lng: 21.475618 },
    { lat: 48.924843, lng: 21.475196 },
    { lat: 48.924784, lng: 21.474701 },
    { lat: 48.924818, lng: 21.474299 },
    { lat: 48.925033, lng: 21.473739 },
    { lat: 48.925633, lng: 21.472888 },
    { lat: 48.926027, lng: 21.471766 },
    { lat: 48.926292, lng: 21.470819 },
    { lat: 48.926504, lng: 21.470536 },
    { lat: 48.926885, lng: 21.470055 },
    { lat: 48.927349, lng: 21.469332 },
    { lat: 48.927792, lng: 21.46887 },
    { lat: 48.928453, lng: 21.468045 },
    { lat: 48.92958, lng: 21.467242 },
    { lat: 48.930185, lng: 21.466843 },
    { lat: 48.931135, lng: 21.466301 },
    { lat: 48.931394, lng: 21.466068 },
    { lat: 48.931576, lng: 21.465673 },
    { lat: 48.931885, lng: 21.465299 },
    { lat: 48.932074, lng: 21.464618 },
    { lat: 48.93262, lng: 21.465072 },
    { lat: 48.933298, lng: 21.465484 },
    { lat: 48.933703, lng: 21.465598 },
    { lat: 48.934231, lng: 21.465679 },
    { lat: 48.934651, lng: 21.465627 },
    { lat: 48.934882, lng: 21.465503 },
    { lat: 48.935118, lng: 21.465213 },
    { lat: 48.935446, lng: 21.464737 },
    { lat: 48.935765, lng: 21.464181 },
    { lat: 48.93629, lng: 21.463628 },
    { lat: 48.936879, lng: 21.463419 },
    { lat: 48.937764, lng: 21.464409 },
    { lat: 48.93816, lng: 21.464944 },
    { lat: 48.938769, lng: 21.465809 },
    { lat: 48.939212, lng: 21.46622 },
    { lat: 48.939609, lng: 21.466875 },
    { lat: 48.939909, lng: 21.467446 },
    { lat: 48.940322, lng: 21.467887 },
    { lat: 48.94066, lng: 21.468302 },
    { lat: 48.9414381, lng: 21.4689019 },
    { lat: 48.941529, lng: 21.468972 },
    { lat: 48.941983, lng: 21.469178 },
    { lat: 48.943117, lng: 21.469084 },
    { lat: 48.943303, lng: 21.469062 },
    { lat: 48.943769, lng: 21.468601 },
    { lat: 48.944654, lng: 21.467724 },
    { lat: 48.944884, lng: 21.467458 },
    { lat: 48.945237, lng: 21.467262 },
    { lat: 48.945305, lng: 21.467197 },
    { lat: 48.945328, lng: 21.466282 },
    { lat: 48.945321, lng: 21.465198 },
    { lat: 48.945351, lng: 21.464257 },
    { lat: 48.945346, lng: 21.463174 },
    { lat: 48.945385, lng: 21.462293 },
    { lat: 48.945367, lng: 21.461971 },
    { lat: 48.945319, lng: 21.461737 },
    { lat: 48.944984, lng: 21.46117 },
    { lat: 48.944968, lng: 21.460904 },
    { lat: 48.945174, lng: 21.460537 },
    { lat: 48.945277, lng: 21.460122 },
    { lat: 48.945299, lng: 21.460022 },
    { lat: 48.945521, lng: 21.459439 },
    { lat: 48.945556, lng: 21.459281 },
    { lat: 48.94594, lng: 21.458728 },
    { lat: 48.946096, lng: 21.458469 },
    { lat: 48.946264, lng: 21.458273 },
    { lat: 48.946551, lng: 21.457865 },
    { lat: 48.946595, lng: 21.457835 },
    { lat: 48.946849, lng: 21.457694 },
    { lat: 48.947329, lng: 21.457343 },
    { lat: 48.947386, lng: 21.457292 },
    { lat: 48.947492, lng: 21.456994 },
    { lat: 48.947821, lng: 21.456212 },
    { lat: 48.948618, lng: 21.455206 },
    { lat: 48.949211, lng: 21.454625 },
    { lat: 48.949483, lng: 21.454476 },
    { lat: 48.950023, lng: 21.453873 },
    { lat: 48.950396, lng: 21.453656 },
    { lat: 48.950952, lng: 21.453273 },
    { lat: 48.951348, lng: 21.453235 },
    { lat: 48.951727, lng: 21.453375 },
    { lat: 48.95182, lng: 21.45341 },
    { lat: 48.952212, lng: 21.452805 },
    { lat: 48.952572, lng: 21.452596 },
    { lat: 48.95276, lng: 21.45233 },
    { lat: 48.95303, lng: 21.451852 },
    { lat: 48.953559, lng: 21.451091 },
    { lat: 48.954239, lng: 21.450665 },
    { lat: 48.955179, lng: 21.450039 },
    { lat: 48.955799, lng: 21.450194 },
    { lat: 48.955892, lng: 21.450217 },
    { lat: 48.956105, lng: 21.450073 },
    { lat: 48.956596, lng: 21.449396 },
    { lat: 48.956941, lng: 21.449182 },
    { lat: 48.957087, lng: 21.449044 },
    { lat: 48.957605, lng: 21.448896 },
    { lat: 48.958182, lng: 21.449056 },
    { lat: 48.95897, lng: 21.449717 },
    { lat: 48.959716, lng: 21.449447 },
    { lat: 48.960059, lng: 21.4487 },
    { lat: 48.960575, lng: 21.448291 },
    { lat: 48.960964, lng: 21.448388 },
    { lat: 48.961128, lng: 21.448477 },
    { lat: 48.961472, lng: 21.448515 },
    { lat: 48.961766, lng: 21.447498 },
    { lat: 48.962079, lng: 21.446652 },
    { lat: 48.963147, lng: 21.443448 },
    { lat: 48.963371, lng: 21.442627 },
    { lat: 48.963615, lng: 21.442093 },
    { lat: 48.963822, lng: 21.441503 },
    { lat: 48.964376, lng: 21.440101 },
    { lat: 48.96487, lng: 21.439824 },
    { lat: 48.96533, lng: 21.439814 },
    { lat: 48.965764, lng: 21.439764 },
    { lat: 48.966238, lng: 21.439408 },
    { lat: 48.966869, lng: 21.439291 },
    { lat: 48.96717, lng: 21.439078 },
    { lat: 48.967356, lng: 21.438475 },
    { lat: 48.967603, lng: 21.437478 },
    { lat: 48.967758, lng: 21.437179 },
    { lat: 48.96793, lng: 21.436528 },
    { lat: 48.968225, lng: 21.435434 },
    { lat: 48.968429, lng: 21.434934 },
    { lat: 48.968768, lng: 21.433866 },
    { lat: 48.96893, lng: 21.433313 },
    { lat: 48.969149, lng: 21.432795 },
    { lat: 48.969351, lng: 21.432393 },
    { lat: 48.969795, lng: 21.432136 },
    { lat: 48.96986, lng: 21.432085 },
    { lat: 48.970623, lng: 21.432597 },
    { lat: 48.970963, lng: 21.432923 },
    { lat: 48.971359, lng: 21.432996 },
    { lat: 48.972248, lng: 21.432729 },
    { lat: 48.973268, lng: 21.432089 },
    { lat: 48.973576, lng: 21.431918 },
    { lat: 48.974252, lng: 21.431539 },
    { lat: 48.974853, lng: 21.431143 },
    { lat: 48.974971, lng: 21.431102 },
    { lat: 48.975366, lng: 21.430783 },
    { lat: 48.975774, lng: 21.430569 },
    { lat: 48.976444, lng: 21.430214 },
    { lat: 48.976829, lng: 21.430019 },
    { lat: 48.977191, lng: 21.429817 },
    { lat: 48.97747, lng: 21.42973 },
    { lat: 48.977758, lng: 21.429742 },
    { lat: 48.977853, lng: 21.429764 },
    { lat: 48.978209, lng: 21.430034 },
    { lat: 48.978654, lng: 21.430242 },
    { lat: 48.978827, lng: 21.430265 },
    { lat: 48.979171, lng: 21.430249 },
    { lat: 48.97923, lng: 21.430145 },
    { lat: 48.979476, lng: 21.42963 },
    { lat: 48.979534, lng: 21.429459 },
    { lat: 48.979819, lng: 21.428522 },
    { lat: 48.979993, lng: 21.4275 },
    { lat: 48.980103, lng: 21.427124 },
    { lat: 48.980157, lng: 21.426814 },
    { lat: 48.980279, lng: 21.426236 },
    { lat: 48.980424, lng: 21.425899 },
    { lat: 48.980652, lng: 21.425464 },
    { lat: 48.980951, lng: 21.424749 },
    { lat: 48.981399, lng: 21.424417 },
    { lat: 48.981546, lng: 21.424366 },
    { lat: 48.981682, lng: 21.424396 },
    { lat: 48.982035, lng: 21.424465 },
    { lat: 48.982713, lng: 21.42424 },
    { lat: 48.983328, lng: 21.423652 },
    { lat: 48.98361, lng: 21.423311 },
    { lat: 48.983827, lng: 21.423094 },
    { lat: 48.984254, lng: 21.423066 },
    { lat: 48.984519, lng: 21.422994 },
    { lat: 48.985089, lng: 21.422962 },
    { lat: 48.98541, lng: 21.422563 },
    { lat: 48.985612, lng: 21.422292 },
    { lat: 48.985856, lng: 21.421997 },
    { lat: 48.986083, lng: 21.421588 },
    { lat: 48.986506, lng: 21.420701 },
    { lat: 48.987143, lng: 21.419975 },
    { lat: 48.987239, lng: 21.419903 },
    { lat: 48.987557, lng: 21.419384 },
    { lat: 48.988088, lng: 21.419201 },
    { lat: 48.988285, lng: 21.418969 },
    { lat: 48.988361, lng: 21.4188 },
    { lat: 48.98878, lng: 21.417858 },
    { lat: 48.98919, lng: 21.416131 },
    { lat: 48.989211, lng: 21.416044 },
    { lat: 48.989335, lng: 21.415522 },
    { lat: 48.989482, lng: 21.414516 },
    { lat: 48.989546, lng: 21.414036 },
    { lat: 48.98956, lng: 21.413459 },
    { lat: 48.989588, lng: 21.412862 },
    { lat: 48.989674, lng: 21.412202 },
    { lat: 48.989773, lng: 21.411094 },
    { lat: 48.989866, lng: 21.410604 },
    { lat: 48.98982, lng: 21.41015 },
    { lat: 48.989925, lng: 21.40978 },
    { lat: 48.9897351, lng: 21.4095997 },
    { lat: 48.9894236, lng: 21.4090767 },
    { lat: 48.9891202, lng: 21.4086588 },
    { lat: 48.9884305, lng: 21.4078711 },
    { lat: 48.9879589, lng: 21.406948 },
    { lat: 48.9878928, lng: 21.4068578 },
    { lat: 48.9859043, lng: 21.4048365 },
    { lat: 48.9856168, lng: 21.404323 },
    { lat: 48.985023, lng: 21.4039084 },
    { lat: 48.9841942, lng: 21.4035488 },
    { lat: 48.9819873, lng: 21.4022302 },
    { lat: 48.980914, lng: 21.4019307 },
    { lat: 48.9800892, lng: 21.4012837 },
    { lat: 48.9791165, lng: 21.4000801 },
    { lat: 48.9785529, lng: 21.3999832 },
    { lat: 48.9784073, lng: 21.399878 },
    { lat: 48.9794324, lng: 21.3989685 },
    { lat: 48.9795571, lng: 21.3985363 },
    { lat: 48.9800734, lng: 21.3973502 },
    { lat: 48.9803536, lng: 21.3961318 },
    { lat: 48.9806907, lng: 21.3951628 },
    { lat: 48.9809638, lng: 21.39329 },
    { lat: 48.9807565, lng: 21.3912638 },
    { lat: 48.9806262, lng: 21.3892871 },
    { lat: 48.9804311, lng: 21.3886628 },
    { lat: 48.9805598, lng: 21.3844955 },
    { lat: 48.9802741, lng: 21.380522 },
    { lat: 48.9800626, lng: 21.3805533 },
    { lat: 48.9800065, lng: 21.379657 },
    { lat: 48.9796128, lng: 21.3787444 },
    { lat: 48.9791466, lng: 21.3779023 },
    { lat: 48.9787432, lng: 21.3770451 },
    { lat: 48.9784485, lng: 21.3758797 },
    { lat: 48.9779162, lng: 21.3746979 },
    { lat: 48.9776711, lng: 21.3746331 },
    { lat: 48.977127, lng: 21.3741904 },
    { lat: 48.9761678, lng: 21.3744765 },
    { lat: 48.9755351, lng: 21.3742749 },
    { lat: 48.974883, lng: 21.37361 },
    { lat: 48.9742061, lng: 21.3730644 },
    { lat: 48.9741057, lng: 21.3732826 },
    { lat: 48.9734406, lng: 21.3733945 },
    { lat: 48.9730927, lng: 21.3739123 },
    { lat: 48.9730766, lng: 21.3740545 },
    { lat: 48.972939, lng: 21.3743111 },
    { lat: 48.9720515, lng: 21.3748111 },
    { lat: 48.972009, lng: 21.3750081 },
    { lat: 48.9718397, lng: 21.3753349 },
    { lat: 48.9718561, lng: 21.3760766 },
    { lat: 48.97214, lng: 21.3769185 },
    { lat: 48.9717644, lng: 21.3776498 },
    { lat: 48.971687, lng: 21.3781082 },
    { lat: 48.9713378, lng: 21.3791141 },
    { lat: 48.9711255, lng: 21.3794313 },
    { lat: 48.9709246, lng: 21.379868 },
    { lat: 48.9695517, lng: 21.3795606 },
    { lat: 48.9690961, lng: 21.3800577 },
    { lat: 48.9687179, lng: 21.3805825 },
    { lat: 48.9680122, lng: 21.3822853 },
    { lat: 48.9676085, lng: 21.3826736 },
    { lat: 48.9670863, lng: 21.3833507 },
    { lat: 48.9667312, lng: 21.3833667 },
    { lat: 48.9659502, lng: 21.384271 },
    { lat: 48.9653595, lng: 21.3847304 },
    { lat: 48.9633, lng: 21.3867705 },
    { lat: 48.9627602, lng: 21.3876518 },
    { lat: 48.9625477, lng: 21.3876975 },
    { lat: 48.9620191, lng: 21.387518 },
    { lat: 48.9617704, lng: 21.3872482 },
    { lat: 48.9615929, lng: 21.3871844 },
    { lat: 48.9612702, lng: 21.3874596 },
    { lat: 48.9608522, lng: 21.387134 },
    { lat: 48.9605018, lng: 21.3872285 },
    { lat: 48.9603459, lng: 21.3868682 },
    { lat: 48.9594805, lng: 21.3862956 },
    { lat: 48.9594218, lng: 21.3860225 },
    { lat: 48.9593625, lng: 21.3859564 },
    { lat: 48.9588272, lng: 21.3859711 },
    { lat: 48.9581232, lng: 21.3857876 },
    { lat: 48.9577518, lng: 21.3854846 },
    { lat: 48.9574322, lng: 21.38537 },
    { lat: 48.9572274, lng: 21.3854044 },
    { lat: 48.9569014, lng: 21.3851865 },
    { lat: 48.956562, lng: 21.3850876 },
    { lat: 48.9563303, lng: 21.385208 },
    { lat: 48.9561584, lng: 21.3851764 },
    { lat: 48.9560283, lng: 21.3852658 },
    { lat: 48.9559135, lng: 21.3851172 },
    { lat: 48.9554392, lng: 21.3853974 },
    { lat: 48.9551699, lng: 21.3853492 },
    { lat: 48.95492, lng: 21.3848171 },
    { lat: 48.9543454, lng: 21.3845241 },
    { lat: 48.9541831, lng: 21.3843218 },
    { lat: 48.9539965, lng: 21.3839121 },
    { lat: 48.9536969, lng: 21.3837095 },
    { lat: 48.9536972, lng: 21.3830754 },
    { lat: 48.9527678, lng: 21.3836806 },
    { lat: 48.9524741, lng: 21.3835736 },
    { lat: 48.9522945, lng: 21.3833926 },
    { lat: 48.9511506, lng: 21.383566 },
    { lat: 48.9503547, lng: 21.383474 },
    { lat: 48.9492506, lng: 21.3834697 },
    { lat: 48.9476427, lng: 21.3832359 },
    { lat: 48.9474363, lng: 21.3831466 },
    { lat: 48.9462916, lng: 21.3822662 },
    { lat: 48.9448424, lng: 21.3823442 },
    { lat: 48.9438527, lng: 21.3822841 },
    { lat: 48.9428372, lng: 21.3820324 },
    { lat: 48.9427141, lng: 21.3820953 },
    { lat: 48.941856, lng: 21.3819184 },
    { lat: 48.9412075, lng: 21.3820991 },
    { lat: 48.9390577, lng: 21.3831041 },
    { lat: 48.9389058, lng: 21.3830218 },
    { lat: 48.9385542, lng: 21.3835777 },
    { lat: 48.9380042, lng: 21.3859342 },
    { lat: 48.93728, lng: 21.3880409 },
    { lat: 48.9371077, lng: 21.3880327 },
    { lat: 48.9369153, lng: 21.3887179 },
    { lat: 48.9368205, lng: 21.3896585 },
    { lat: 48.9354914, lng: 21.3965356 },
    { lat: 48.9355456, lng: 21.3969571 },
    { lat: 48.9354493, lng: 21.3976244 },
    { lat: 48.9357852, lng: 21.3986358 },
    { lat: 48.9359851, lng: 21.3995782 },
    { lat: 48.9363866, lng: 21.4008615 },
    { lat: 48.9377666, lng: 21.4041202 },
    { lat: 48.9378185, lng: 21.4044319 },
    { lat: 48.937953, lng: 21.4047557 },
    { lat: 48.938007, lng: 21.4059399 },
    { lat: 48.9382026, lng: 21.4065841 },
    { lat: 48.9382513, lng: 21.4070149 },
    { lat: 48.9384347, lng: 21.4073966 },
    { lat: 48.9384953, lng: 21.4077325 },
    { lat: 48.9390513, lng: 21.4089252 },
    { lat: 48.9391246, lng: 21.4092782 },
    { lat: 48.9393094, lng: 21.4095885 },
    { lat: 48.9393945, lng: 21.4099972 },
    { lat: 48.9395717, lng: 21.4105135 },
    { lat: 48.9395956, lng: 21.4111729 },
    { lat: 48.9396621, lng: 21.4114773 },
    { lat: 48.9398336, lng: 21.4118028 },
    { lat: 48.9408033, lng: 21.413016 },
    { lat: 48.9409739, lng: 21.4133494 },
    { lat: 48.9407531, lng: 21.4139578 },
    { lat: 48.9411195, lng: 21.4150462 },
    { lat: 48.9410598, lng: 21.4156938 },
    { lat: 48.9412507, lng: 21.4165678 },
    { lat: 48.9399498, lng: 21.4169412 },
    { lat: 48.9395686, lng: 21.4166055 },
    { lat: 48.939254, lng: 21.4160426 },
    { lat: 48.9380827, lng: 21.4151291 },
    { lat: 48.9373914, lng: 21.4147311 },
    { lat: 48.9368858, lng: 21.4149843 },
    { lat: 48.9357662, lng: 21.4151743 },
    { lat: 48.9355035, lng: 21.4154301 },
    { lat: 48.9353038, lng: 21.4154447 },
    { lat: 48.9347198, lng: 21.4151403 },
    { lat: 48.9344637, lng: 21.4148123 },
    { lat: 48.9336014, lng: 21.4147952 },
    { lat: 48.9327718, lng: 21.4146689 },
    { lat: 48.9317336, lng: 21.415718 },
    { lat: 48.9306503, lng: 21.4171046 },
    { lat: 48.930361, lng: 21.4177862 },
    { lat: 48.930236, lng: 21.4184757 },
    { lat: 48.9297381, lng: 21.4187996 },
    { lat: 48.9293726, lng: 21.4187349 },
    { lat: 48.9289572, lng: 21.4188413 },
    { lat: 48.928235, lng: 21.4192262 },
    { lat: 48.9277449, lng: 21.4191634 },
    { lat: 48.9265203, lng: 21.4185796 },
    { lat: 48.9255189, lng: 21.4196138 },
    { lat: 48.9251743, lng: 21.4197956 },
  ],
  Žehňa: [
    { lat: 48.9211461, lng: 21.374191 },
    { lat: 48.9214462, lng: 21.3730065 },
    { lat: 48.924706, lng: 21.3625872 },
    { lat: 48.9262473, lng: 21.3578752 },
    { lat: 48.9258239, lng: 21.3528563 },
    { lat: 48.9260952, lng: 21.3523392 },
    { lat: 48.926108, lng: 21.3511456 },
    { lat: 48.9260175, lng: 21.3506817 },
    { lat: 48.9257748, lng: 21.3476521 },
    { lat: 48.9262153, lng: 21.3465049 },
    { lat: 48.9261039, lng: 21.3459443 },
    { lat: 48.9258365, lng: 21.3436315 },
    { lat: 48.9251171, lng: 21.3412995 },
    { lat: 48.9250824, lng: 21.340067 },
    { lat: 48.9248363, lng: 21.3384318 },
    { lat: 48.9247045, lng: 21.3377115 },
    { lat: 48.9243268, lng: 21.3363467 },
    { lat: 48.9242993, lng: 21.3346815 },
    { lat: 48.9244729, lng: 21.3339828 },
    { lat: 48.9245521, lng: 21.3326529 },
    { lat: 48.9244466, lng: 21.3319532 },
    { lat: 48.9240903, lng: 21.3315524 },
    { lat: 48.9245702, lng: 21.3316601 },
    { lat: 48.9258713, lng: 21.331952 },
    { lat: 48.9273744, lng: 21.332464 },
    { lat: 48.9279555, lng: 21.3303557 },
    { lat: 48.9285935, lng: 21.328521 },
    { lat: 48.9292564, lng: 21.3271867 },
    { lat: 48.9331463, lng: 21.3197263 },
    { lat: 48.9351296, lng: 21.3162075 },
    { lat: 48.9361462, lng: 21.3145117 },
    { lat: 48.9406155, lng: 21.3058852 },
    { lat: 48.9416497, lng: 21.3042604 },
    { lat: 48.9414517, lng: 21.3040402 },
    { lat: 48.9393743, lng: 21.3021839 },
    { lat: 48.9389026, lng: 21.3020243 },
    { lat: 48.9387076, lng: 21.3018683 },
    { lat: 48.9371899, lng: 21.3003903 },
    { lat: 48.9367234, lng: 21.3014119 },
    { lat: 48.9363646, lng: 21.3020054 },
    { lat: 48.9360594, lng: 21.3022608 },
    { lat: 48.9356486, lng: 21.3027792 },
    { lat: 48.9353926, lng: 21.3043504 },
    { lat: 48.9351758, lng: 21.3050309 },
    { lat: 48.9347937, lng: 21.3056045 },
    { lat: 48.9341117, lng: 21.3052281 },
    { lat: 48.933763, lng: 21.3048269 },
    { lat: 48.9328344, lng: 21.3040801 },
    { lat: 48.932611, lng: 21.3037962 },
    { lat: 48.9324811, lng: 21.3037126 },
    { lat: 48.9322346, lng: 21.303708 },
    { lat: 48.9319235, lng: 21.3033951 },
    { lat: 48.9316895, lng: 21.3029516 },
    { lat: 48.9313396, lng: 21.3025484 },
    { lat: 48.9312498, lng: 21.302734 },
    { lat: 48.930728, lng: 21.3033034 },
    { lat: 48.9304886, lng: 21.3039067 },
    { lat: 48.9303213, lng: 21.304774 },
    { lat: 48.9300164, lng: 21.3050283 },
    { lat: 48.9290703, lng: 21.3051499 },
    { lat: 48.9288145, lng: 21.3053614 },
    { lat: 48.9281957, lng: 21.3056248 },
    { lat: 48.9276021, lng: 21.3055664 },
    { lat: 48.9270419, lng: 21.3052756 },
    { lat: 48.9267212, lng: 21.3047219 },
    { lat: 48.9250004, lng: 21.3053771 },
    { lat: 48.9247689, lng: 21.3053951 },
    { lat: 48.9243431, lng: 21.3055591 },
    { lat: 48.9240043, lng: 21.3054138 },
    { lat: 48.923687, lng: 21.305378 },
    { lat: 48.9230367, lng: 21.3050857 },
    { lat: 48.92237, lng: 21.3049484 },
    { lat: 48.9203549, lng: 21.304999 },
    { lat: 48.9200677, lng: 21.3049511 },
    { lat: 48.9196606, lng: 21.3046693 },
    { lat: 48.9188702, lng: 21.3044673 },
    { lat: 48.9169301, lng: 21.3042355 },
    { lat: 48.9150215, lng: 21.3037939 },
    { lat: 48.9130411, lng: 21.3039739 },
    { lat: 48.9123289, lng: 21.3039804 },
    { lat: 48.9115972, lng: 21.3060163 },
    { lat: 48.9110957, lng: 21.3079144 },
    { lat: 48.910894, lng: 21.3100249 },
    { lat: 48.9104459, lng: 21.3110192 },
    { lat: 48.9103488, lng: 21.3113386 },
    { lat: 48.9099771, lng: 21.313189 },
    { lat: 48.908937, lng: 21.3173724 },
    { lat: 48.9088423, lng: 21.318224 },
    { lat: 48.9095704, lng: 21.3186348 },
    { lat: 48.909592, lng: 21.3191638 },
    { lat: 48.90995, lng: 21.3195392 },
    { lat: 48.9103989, lng: 21.3195358 },
    { lat: 48.9108258, lng: 21.3200696 },
    { lat: 48.9107233, lng: 21.3204019 },
    { lat: 48.9108226, lng: 21.3205974 },
    { lat: 48.9112815, lng: 21.320787 },
    { lat: 48.9113817, lng: 21.3210032 },
    { lat: 48.9120975, lng: 21.3211155 },
    { lat: 48.9125414, lng: 21.3218477 },
    { lat: 48.913114, lng: 21.3218712 },
    { lat: 48.9134332, lng: 21.3220114 },
    { lat: 48.9134283, lng: 21.3226278 },
    { lat: 48.913777, lng: 21.323112 },
    { lat: 48.9136616, lng: 21.323389 },
    { lat: 48.9139618, lng: 21.3237195 },
    { lat: 48.9140053, lng: 21.3241128 },
    { lat: 48.9144543, lng: 21.3240054 },
    { lat: 48.9146837, lng: 21.3237369 },
    { lat: 48.9150862, lng: 21.3239096 },
    { lat: 48.9152436, lng: 21.3237173 },
    { lat: 48.9154594, lng: 21.3238604 },
    { lat: 48.9126194, lng: 21.3248366 },
    { lat: 48.9121873, lng: 21.3249096 },
    { lat: 48.9077867, lng: 21.3245467 },
    { lat: 48.907249, lng: 21.3244417 },
    { lat: 48.9042093, lng: 21.3235384 },
    { lat: 48.9041785, lng: 21.3275853 },
    { lat: 48.9024412, lng: 21.3278951 },
    { lat: 48.902382, lng: 21.3294712 },
    { lat: 48.9025114, lng: 21.3315288 },
    { lat: 48.902634, lng: 21.33223 },
    { lat: 48.9010971, lng: 21.3336177 },
    { lat: 48.9017684, lng: 21.3345029 },
    { lat: 48.9018381, lng: 21.3370761 },
    { lat: 48.9015095, lng: 21.3370399 },
    { lat: 48.9013382, lng: 21.3390135 },
    { lat: 48.9018275, lng: 21.339381 },
    { lat: 48.9022155, lng: 21.3399346 },
    { lat: 48.902386, lng: 21.3405942 },
    { lat: 48.9030316, lng: 21.3403972 },
    { lat: 48.9031439, lng: 21.3416241 },
    { lat: 48.9033915, lng: 21.3431236 },
    { lat: 48.9039201, lng: 21.3452868 },
    { lat: 48.9039504, lng: 21.3455505 },
    { lat: 48.9013641, lng: 21.3468067 },
    { lat: 48.9001044, lng: 21.3477635 },
    { lat: 48.8998886, lng: 21.3503249 },
    { lat: 48.9001407, lng: 21.3536308 },
    { lat: 48.899674, lng: 21.3559351 },
    { lat: 48.8987417, lng: 21.3578698 },
    { lat: 48.8960235, lng: 21.3651156 },
    { lat: 48.8954572, lng: 21.3669497 },
    { lat: 48.8941699, lng: 21.3702669 },
    { lat: 48.8936407, lng: 21.3698866 },
    { lat: 48.8893223, lng: 21.3679714 },
    { lat: 48.8888486, lng: 21.3676692 },
    { lat: 48.8887304, lng: 21.3683803 },
    { lat: 48.8882764, lng: 21.3717218 },
    { lat: 48.8880366, lng: 21.3722842 },
    { lat: 48.8863314, lng: 21.3746536 },
    { lat: 48.8860957, lng: 21.3756948 },
    { lat: 48.885823, lng: 21.3761733 },
    { lat: 48.885208, lng: 21.3762994 },
    { lat: 48.8841804, lng: 21.3768989 },
    { lat: 48.8834424, lng: 21.3775833 },
    { lat: 48.8829774, lng: 21.3775346 },
    { lat: 48.8827377, lng: 21.3773855 },
    { lat: 48.881906, lng: 21.377199 },
    { lat: 48.882583, lng: 21.380838 },
    { lat: 48.882644, lng: 21.381213 },
    { lat: 48.882911, lng: 21.382425 },
    { lat: 48.883179, lng: 21.383715 },
    { lat: 48.883469, lng: 21.385115 },
    { lat: 48.883664, lng: 21.385986 },
    { lat: 48.883834, lng: 21.386877 },
    { lat: 48.884065, lng: 21.388014 },
    { lat: 48.884269, lng: 21.389078 },
    { lat: 48.884401, lng: 21.389707 },
    { lat: 48.8848, lng: 21.392376 },
    { lat: 48.884916, lng: 21.393231 },
    { lat: 48.885109, lng: 21.394337 },
    { lat: 48.8851415, lng: 21.3948732 },
    { lat: 48.8852591, lng: 21.3949668 },
    { lat: 48.8854217, lng: 21.3947233 },
    { lat: 48.8858727, lng: 21.3946397 },
    { lat: 48.8859499, lng: 21.3943963 },
    { lat: 48.8860979, lng: 21.3941478 },
    { lat: 48.8865122, lng: 21.3940459 },
    { lat: 48.887521, lng: 21.3936429 },
    { lat: 48.8878342, lng: 21.3930152 },
    { lat: 48.888665, lng: 21.3917368 },
    { lat: 48.8890093, lng: 21.3919229 },
    { lat: 48.8891739, lng: 21.3918977 },
    { lat: 48.8894458, lng: 21.3911601 },
    { lat: 48.8897351, lng: 21.3910138 },
    { lat: 48.890117, lng: 21.3915227 },
    { lat: 48.8905326, lng: 21.3915742 },
    { lat: 48.8908604, lng: 21.3912811 },
    { lat: 48.8909767, lng: 21.3913304 },
    { lat: 48.8911667, lng: 21.3910986 },
    { lat: 48.8912314, lng: 21.3907742 },
    { lat: 48.8911364, lng: 21.3901885 },
    { lat: 48.8911541, lng: 21.3899481 },
    { lat: 48.8912824, lng: 21.3893847 },
    { lat: 48.8913677, lng: 21.3891854 },
    { lat: 48.8917369, lng: 21.3891524 },
    { lat: 48.8923052, lng: 21.3885884 },
    { lat: 48.8924262, lng: 21.3881506 },
    { lat: 48.8927257, lng: 21.3879639 },
    { lat: 48.892859, lng: 21.3877174 },
    { lat: 48.8927618, lng: 21.3874769 },
    { lat: 48.8926218, lng: 21.3873842 },
    { lat: 48.8926582, lng: 21.3870148 },
    { lat: 48.8925141, lng: 21.3869006 },
    { lat: 48.8929921, lng: 21.3864417 },
    { lat: 48.8931351, lng: 21.3864398 },
    { lat: 48.8932244, lng: 21.3868339 },
    { lat: 48.8939297, lng: 21.3867171 },
    { lat: 48.8939732, lng: 21.3862843 },
    { lat: 48.8940408, lng: 21.3861359 },
    { lat: 48.8941717, lng: 21.3860351 },
    { lat: 48.8945184, lng: 21.3859285 },
    { lat: 48.8948381, lng: 21.3855823 },
    { lat: 48.8951551, lng: 21.3855406 },
    { lat: 48.8953455, lng: 21.3852018 },
    { lat: 48.8955077, lng: 21.3850804 },
    { lat: 48.8960818, lng: 21.3846509 },
    { lat: 48.8962469, lng: 21.3847541 },
    { lat: 48.8964826, lng: 21.3847688 },
    { lat: 48.8966228, lng: 21.3847173 },
    { lat: 48.896933, lng: 21.3842838 },
    { lat: 48.8970588, lng: 21.3844723 },
    { lat: 48.8977684, lng: 21.3841188 },
    { lat: 48.8978356, lng: 21.38365 },
    { lat: 48.8981145, lng: 21.3830759 },
    { lat: 48.8981021, lng: 21.3825785 },
    { lat: 48.8982986, lng: 21.3823048 },
    { lat: 48.8987846, lng: 21.3826381 },
    { lat: 48.8997888, lng: 21.3814866 },
    { lat: 48.8996755, lng: 21.3806646 },
    { lat: 48.899774, lng: 21.3802655 },
    { lat: 48.9000465, lng: 21.3797408 },
    { lat: 48.9012038, lng: 21.3787404 },
    { lat: 48.9014475, lng: 21.3784597 },
    { lat: 48.9019045, lng: 21.3774399 },
    { lat: 48.9026677, lng: 21.3766208 },
    { lat: 48.9028951, lng: 21.3764729 },
    { lat: 48.9031888, lng: 21.3766603 },
    { lat: 48.9037645, lng: 21.376006 },
    { lat: 48.9040123, lng: 21.376052 },
    { lat: 48.9044873, lng: 21.3754858 },
    { lat: 48.9046916, lng: 21.3749028 },
    { lat: 48.905115, lng: 21.3746155 },
    { lat: 48.9058575, lng: 21.3746062 },
    { lat: 48.906232, lng: 21.3740322 },
    { lat: 48.9066295, lng: 21.3735539 },
    { lat: 48.9072786, lng: 21.3734747 },
    { lat: 48.908314, lng: 21.3736252 },
    { lat: 48.9085465, lng: 21.3740292 },
    { lat: 48.908726, lng: 21.3742384 },
    { lat: 48.9090572, lng: 21.3742894 },
    { lat: 48.9090724, lng: 21.3743109 },
    { lat: 48.9093706, lng: 21.3739252 },
    { lat: 48.9098511, lng: 21.3740036 },
    { lat: 48.9099527, lng: 21.3739011 },
    { lat: 48.9103592, lng: 21.373948 },
    { lat: 48.910507, lng: 21.3737408 },
    { lat: 48.9106531, lng: 21.3737694 },
    { lat: 48.9108594, lng: 21.3739735 },
    { lat: 48.9111975, lng: 21.3741399 },
    { lat: 48.9115515, lng: 21.3741271 },
    { lat: 48.9117353, lng: 21.3740576 },
    { lat: 48.912246, lng: 21.3726972 },
    { lat: 48.9124866, lng: 21.3725419 },
    { lat: 48.9129166, lng: 21.3727672 },
    { lat: 48.9132417, lng: 21.3726948 },
    { lat: 48.9135588, lng: 21.3721941 },
    { lat: 48.9148848, lng: 21.3722466 },
    { lat: 48.915141, lng: 21.3726541 },
    { lat: 48.9154057, lng: 21.3726578 },
    { lat: 48.9158919, lng: 21.3723991 },
    { lat: 48.9162587, lng: 21.3727041 },
    { lat: 48.916908, lng: 21.3727015 },
    { lat: 48.9172206, lng: 21.3728822 },
    { lat: 48.9174708, lng: 21.3728575 },
    { lat: 48.917758, lng: 21.3730951 },
    { lat: 48.9178303, lng: 21.3730676 },
    { lat: 48.9179307, lng: 21.3732271 },
    { lat: 48.9182066, lng: 21.3733459 },
    { lat: 48.9185012, lng: 21.3733 },
    { lat: 48.9187445, lng: 21.3728951 },
    { lat: 48.9193568, lng: 21.3734646 },
    { lat: 48.919851, lng: 21.3735513 },
    { lat: 48.9199921, lng: 21.374171 },
    { lat: 48.9209874, lng: 21.3748309 },
    { lat: 48.9211461, lng: 21.374191 },
  ],
  VyšnáŠebastová: [
    { lat: 49.0145196, lng: 21.3026378 },
    { lat: 49.0143544, lng: 21.3029429 },
    { lat: 49.0139552, lng: 21.3034731 },
    { lat: 49.0135558, lng: 21.3046263 },
    { lat: 49.0135336, lng: 21.3048573 },
    { lat: 49.0132653, lng: 21.3058393 },
    { lat: 49.0130243, lng: 21.3063924 },
    { lat: 49.0120963, lng: 21.3080364 },
    { lat: 49.0116703, lng: 21.309062 },
    { lat: 49.0115345, lng: 21.3096992 },
    { lat: 49.0106478, lng: 21.3094077 },
    { lat: 49.0107012, lng: 21.3097176 },
    { lat: 49.0106576, lng: 21.31003 },
    { lat: 49.0107283, lng: 21.3101269 },
    { lat: 49.0105428, lng: 21.3104721 },
    { lat: 49.0102597, lng: 21.3113809 },
    { lat: 49.0103407, lng: 21.3115634 },
    { lat: 49.0101839, lng: 21.3123361 },
    { lat: 49.0102681, lng: 21.3128815 },
    { lat: 49.0101109, lng: 21.31301 },
    { lat: 49.0100497, lng: 21.3133649 },
    { lat: 49.0101973, lng: 21.3136298 },
    { lat: 49.009635, lng: 21.315357 },
    { lat: 49.0091611, lng: 21.3159116 },
    { lat: 49.009113, lng: 21.3162573 },
    { lat: 49.0085563, lng: 21.3177568 },
    { lat: 49.0085177, lng: 21.318077 },
    { lat: 49.0083457, lng: 21.3183095 },
    { lat: 49.0083276, lng: 21.3187161 },
    { lat: 49.0081859, lng: 21.3191633 },
    { lat: 49.0078028, lng: 21.3201183 },
    { lat: 49.0072299, lng: 21.3208945 },
    { lat: 49.0071684, lng: 21.3212482 },
    { lat: 49.0069432, lng: 21.3215359 },
    { lat: 49.0067993, lng: 21.3224012 },
    { lat: 49.0066929, lng: 21.3222663 },
    { lat: 49.0065601, lng: 21.3224927 },
    { lat: 49.0065316, lng: 21.3236366 },
    { lat: 49.0060435, lng: 21.3236043 },
    { lat: 49.0055806, lng: 21.3240806 },
    { lat: 49.0053653, lng: 21.324549 },
    { lat: 49.0055398, lng: 21.3250408 },
    { lat: 49.0052643, lng: 21.3254209 },
    { lat: 49.0049973, lng: 21.3260771 },
    { lat: 49.0049516, lng: 21.3263512 },
    { lat: 49.0044794, lng: 21.3264085 },
    { lat: 49.0033781, lng: 21.3260844 },
    { lat: 49.0026875, lng: 21.3259755 },
    { lat: 49.0022011, lng: 21.3257814 },
    { lat: 49.0017393, lng: 21.3257311 },
    { lat: 48.999369, lng: 21.3251843 },
    { lat: 48.9992352, lng: 21.3250399 },
    { lat: 48.9992189, lng: 21.3251331 },
    { lat: 48.9986341, lng: 21.3250308 },
    { lat: 48.9979682, lng: 21.325156 },
    { lat: 48.9972999, lng: 21.3249732 },
    { lat: 48.9960771, lng: 21.3250232 },
    { lat: 48.9960048, lng: 21.3250483 },
    { lat: 48.9957765, lng: 21.325649 },
    { lat: 48.9957614, lng: 21.32597 },
    { lat: 48.9956652, lng: 21.3261032 },
    { lat: 48.9956658, lng: 21.3266399 },
    { lat: 48.9958728, lng: 21.326942 },
    { lat: 48.9959531, lng: 21.3276291 },
    { lat: 48.9960478, lng: 21.3277056 },
    { lat: 48.9960811, lng: 21.327957 },
    { lat: 48.9962262, lng: 21.3282117 },
    { lat: 48.9962275, lng: 21.3283704 },
    { lat: 48.9963064, lng: 21.3285247 },
    { lat: 48.9963484, lng: 21.3291278 },
    { lat: 48.9965378, lng: 21.3298922 },
    { lat: 48.9965076, lng: 21.330047 },
    { lat: 48.9963731, lng: 21.3302538 },
    { lat: 48.9963074, lng: 21.3305697 },
    { lat: 48.9962083, lng: 21.3307164 },
    { lat: 48.9962234, lng: 21.3312987 },
    { lat: 48.996337, lng: 21.3315237 },
    { lat: 48.9963177, lng: 21.3317361 },
    { lat: 48.9961905, lng: 21.3319199 },
    { lat: 48.996167, lng: 21.3323017 },
    { lat: 48.9959836, lng: 21.3328269 },
    { lat: 48.9958886, lng: 21.3329237 },
    { lat: 48.9957705, lng: 21.3332602 },
    { lat: 48.9956935, lng: 21.3333193 },
    { lat: 48.9956336, lng: 21.3346206 },
    { lat: 48.9981597, lng: 21.3352588 },
    { lat: 49.0011777, lng: 21.3368018 },
    { lat: 49.0027802, lng: 21.3378934 },
    { lat: 49.0067304, lng: 21.3410247 },
    { lat: 49.0076054, lng: 21.3421806 },
    { lat: 49.0076745, lng: 21.3420378 },
    { lat: 49.0075602, lng: 21.3417572 },
    { lat: 49.0075517, lng: 21.3413241 },
    { lat: 49.007713, lng: 21.3409237 },
    { lat: 49.0079717, lng: 21.3405462 },
    { lat: 49.0085443, lng: 21.3398678 },
    { lat: 49.0091366, lng: 21.3399909 },
    { lat: 49.0094552, lng: 21.3402993 },
    { lat: 49.0100589, lng: 21.3410661 },
    { lat: 49.0139158, lng: 21.3444369 },
    { lat: 49.0138673, lng: 21.3449596 },
    { lat: 49.0139177, lng: 21.3452099 },
    { lat: 49.0137704, lng: 21.3456686 },
    { lat: 49.0136387, lng: 21.3458913 },
    { lat: 49.0132746, lng: 21.3482774 },
    { lat: 49.0131123, lng: 21.3485262 },
    { lat: 49.012949, lng: 21.3490508 },
    { lat: 49.0128536, lng: 21.3490603 },
    { lat: 49.0128484, lng: 21.3491463 },
    { lat: 49.0130008, lng: 21.3492752 },
    { lat: 49.0130296, lng: 21.3496414 },
    { lat: 49.0129991, lng: 21.3500475 },
    { lat: 49.0131867, lng: 21.3510945 },
    { lat: 49.0129808, lng: 21.3516897 },
    { lat: 49.0129483, lng: 21.3522981 },
    { lat: 49.0127382, lng: 21.3534031 },
    { lat: 49.0120741, lng: 21.356038 },
    { lat: 49.0120998, lng: 21.3567392 },
    { lat: 49.0119029, lng: 21.3573481 },
    { lat: 49.0119031, lng: 21.3580725 },
    { lat: 49.0123801, lng: 21.3593842 },
    { lat: 49.0125878, lng: 21.359557 },
    { lat: 49.0126366, lng: 21.3598031 },
    { lat: 49.0128355, lng: 21.3595614 },
    { lat: 49.0130471, lng: 21.3595647 },
    { lat: 49.013227, lng: 21.3593749 },
    { lat: 49.0135166, lng: 21.3593538 },
    { lat: 49.0138202, lng: 21.3594939 },
    { lat: 49.0142204, lng: 21.359258 },
    { lat: 49.0144096, lng: 21.3590331 },
    { lat: 49.0146113, lng: 21.358893 },
    { lat: 49.0147405, lng: 21.3586633 },
    { lat: 49.0149408, lng: 21.3584713 },
    { lat: 49.0153752, lng: 21.3582847 },
    { lat: 49.0154812, lng: 21.3583685 },
    { lat: 49.0160355, lng: 21.3581277 },
    { lat: 49.0161045, lng: 21.3582194 },
    { lat: 49.0166093, lng: 21.3580527 },
    { lat: 49.0170273, lng: 21.3576187 },
    { lat: 49.017239, lng: 21.3571342 },
    { lat: 49.0174617, lng: 21.3568309 },
    { lat: 49.0175863, lng: 21.3569693 },
    { lat: 49.0177237, lng: 21.356863 },
    { lat: 49.0179075, lng: 21.3570669 },
    { lat: 49.0180736, lng: 21.3568568 },
    { lat: 49.0182487, lng: 21.355448 },
    { lat: 49.0181989, lng: 21.3550665 },
    { lat: 49.0183808, lng: 21.3550847 },
    { lat: 49.0185244, lng: 21.3550003 },
    { lat: 49.0186533, lng: 21.3547587 },
    { lat: 49.0189113, lng: 21.3546186 },
    { lat: 49.019265, lng: 21.3547054 },
    { lat: 49.0195401, lng: 21.3550997 },
    { lat: 49.0197845, lng: 21.3553324 },
    { lat: 49.0200578, lng: 21.3554053 },
    { lat: 49.0205214, lng: 21.3558548 },
    { lat: 49.0207578, lng: 21.3558209 },
    { lat: 49.0209857, lng: 21.3556929 },
    { lat: 49.0211479, lng: 21.3553992 },
    { lat: 49.0213461, lng: 21.3553246 },
    { lat: 49.0215025, lng: 21.3551378 },
    { lat: 49.0217082, lng: 21.3550931 },
    { lat: 49.0219513, lng: 21.3549049 },
    { lat: 49.0220635, lng: 21.3544458 },
    { lat: 49.0220042, lng: 21.3540767 },
    { lat: 49.0218785, lng: 21.353862 },
    { lat: 49.0218019, lng: 21.3534062 },
    { lat: 49.0219734, lng: 21.3521478 },
    { lat: 49.0219087, lng: 21.35175 },
    { lat: 49.0219586, lng: 21.3513128 },
    { lat: 49.0219018, lng: 21.3509901 },
    { lat: 49.0218976, lng: 21.3499105 },
    { lat: 49.0215421, lng: 21.3499849 },
    { lat: 49.0212679, lng: 21.3495837 },
    { lat: 49.0212864, lng: 21.3491121 },
    { lat: 49.0214389, lng: 21.3483784 },
    { lat: 49.0215692, lng: 21.347847 },
    { lat: 49.0217917, lng: 21.3472684 },
    { lat: 49.0238964, lng: 21.3474461 },
    { lat: 49.0251104, lng: 21.3485317 },
    { lat: 49.0256537, lng: 21.3481854 },
    { lat: 49.0260874, lng: 21.3477162 },
    { lat: 49.0266793, lng: 21.3472991 },
    { lat: 49.0272303, lng: 21.3471021 },
    { lat: 49.0273218, lng: 21.3471112 },
    { lat: 49.0277712, lng: 21.3468252 },
    { lat: 49.0281894, lng: 21.3464366 },
    { lat: 49.0283727, lng: 21.3463938 },
    { lat: 49.0285031, lng: 21.3464342 },
    { lat: 49.028713, lng: 21.3460006 },
    { lat: 49.0289268, lng: 21.3457394 },
    { lat: 49.0289806, lng: 21.3455037 },
    { lat: 49.0290979, lng: 21.3455881 },
    { lat: 49.0293523, lng: 21.3453654 },
    { lat: 49.029492, lng: 21.3447701 },
    { lat: 49.0300245, lng: 21.3442542 },
    { lat: 49.0301321, lng: 21.3437863 },
    { lat: 49.0301117, lng: 21.3436325 },
    { lat: 49.0306957, lng: 21.3424324 },
    { lat: 49.030823, lng: 21.3424741 },
    { lat: 49.0310273, lng: 21.3420831 },
    { lat: 49.0314218, lng: 21.3416896 },
    { lat: 49.0316703, lng: 21.3404844 },
    { lat: 49.0318335, lng: 21.3402604 },
    { lat: 49.0325625, lng: 21.3397318 },
    { lat: 49.0328295, lng: 21.3397015 },
    { lat: 49.032896, lng: 21.3394835 },
    { lat: 49.0330052, lng: 21.3393741 },
    { lat: 49.0334191, lng: 21.3391008 },
    { lat: 49.0336128, lng: 21.339072 },
    { lat: 49.0339783, lng: 21.3388382 },
    { lat: 49.0342235, lng: 21.3385006 },
    { lat: 49.034503, lng: 21.3383306 },
    { lat: 49.03492, lng: 21.3376748 },
    { lat: 49.035359, lng: 21.3371549 },
    { lat: 49.0352832, lng: 21.3366566 },
    { lat: 49.0351652, lng: 21.3366189 },
    { lat: 49.0345764, lng: 21.3359372 },
    { lat: 49.0344161, lng: 21.3359598 },
    { lat: 49.0344261, lng: 21.3346437 },
    { lat: 49.0358574, lng: 21.3343797 },
    { lat: 49.0358406, lng: 21.3335621 },
    { lat: 49.0365516, lng: 21.3345301 },
    { lat: 49.0369415, lng: 21.3344715 },
    { lat: 49.0369444, lng: 21.334042 },
    { lat: 49.0373437, lng: 21.332688 },
    { lat: 49.0375222, lng: 21.3318873 },
    { lat: 49.0377573, lng: 21.3320783 },
    { lat: 49.0381503, lng: 21.3321628 },
    { lat: 49.0383906, lng: 21.3321081 },
    { lat: 49.0384566, lng: 21.331605 },
    { lat: 49.0386041, lng: 21.3316471 },
    { lat: 49.0389253, lng: 21.3319652 },
    { lat: 49.0389983, lng: 21.3319224 },
    { lat: 49.0385162, lng: 21.3294702 },
    { lat: 49.038865, lng: 21.3293624 },
    { lat: 49.0382718, lng: 21.3248263 },
    { lat: 49.0384273, lng: 21.3247208 },
    { lat: 49.0381719, lng: 21.3234905 },
    { lat: 49.0381605, lng: 21.3232607 },
    { lat: 49.0384541, lng: 21.3231057 },
    { lat: 49.0381973, lng: 21.3220947 },
    { lat: 49.0383285, lng: 21.3217057 },
    { lat: 49.0384766, lng: 21.3215028 },
    { lat: 49.0383245, lng: 21.3209701 },
    { lat: 49.038637, lng: 21.3207765 },
    { lat: 49.0386993, lng: 21.3206771 },
    { lat: 49.0384665, lng: 21.3199011 },
    { lat: 49.0383692, lng: 21.319319 },
    { lat: 49.0383482, lng: 21.3188865 },
    { lat: 49.0381503, lng: 21.3183691 },
    { lat: 49.0380705, lng: 21.318374 },
    { lat: 49.0375475, lng: 21.3166234 },
    { lat: 49.0379542, lng: 21.3161915 },
    { lat: 49.0385712, lng: 21.3156797 },
    { lat: 49.0384069, lng: 21.315386 },
    { lat: 49.0376392, lng: 21.3158233 },
    { lat: 49.0367449, lng: 21.31639 },
    { lat: 49.0365219, lng: 21.316345 },
    { lat: 49.0346352, lng: 21.3173223 },
    { lat: 49.0336032, lng: 21.3177691 },
    { lat: 49.0279823, lng: 21.3043911 },
    { lat: 49.02177, lng: 21.3038438 },
    { lat: 49.018737, lng: 21.3034146 },
    { lat: 49.0185079, lng: 21.303317 },
    { lat: 49.0183873, lng: 21.3033924 },
    { lat: 49.0175932, lng: 21.3024679 },
    { lat: 49.0159606, lng: 21.3025156 },
    { lat: 49.0145196, lng: 21.3026378 },
  ],
  Petrovany: [
    { lat: 48.9071097, lng: 21.2894094 },
    { lat: 48.9071585, lng: 21.2900499 },
    { lat: 48.9070665, lng: 21.2915358 },
    { lat: 48.9069066, lng: 21.2923845 },
    { lat: 48.9067654, lng: 21.2928447 },
    { lat: 48.9065122, lng: 21.2932867 },
    { lat: 48.9060252, lng: 21.294663 },
    { lat: 48.9057521, lng: 21.2953035 },
    { lat: 48.9052955, lng: 21.2971218 },
    { lat: 48.9050917, lng: 21.2972259 },
    { lat: 48.9048273, lng: 21.2975266 },
    { lat: 48.9047737, lng: 21.2978366 },
    { lat: 48.904349, lng: 21.2987494 },
    { lat: 48.9037475, lng: 21.3002456 },
    { lat: 48.9039187, lng: 21.3011006 },
    { lat: 48.9059553, lng: 21.3020227 },
    { lat: 48.9098154, lng: 21.3034742 },
    { lat: 48.9109716, lng: 21.3037561 },
    { lat: 48.9123289, lng: 21.3039804 },
    { lat: 48.9130411, lng: 21.3039739 },
    { lat: 48.9150215, lng: 21.3037939 },
    { lat: 48.9169301, lng: 21.3042355 },
    { lat: 48.9188702, lng: 21.3044673 },
    { lat: 48.9196606, lng: 21.3046693 },
    { lat: 48.9200677, lng: 21.3049511 },
    { lat: 48.9203549, lng: 21.304999 },
    { lat: 48.92237, lng: 21.3049484 },
    { lat: 48.9230367, lng: 21.3050857 },
    { lat: 48.923687, lng: 21.305378 },
    { lat: 48.9240043, lng: 21.3054138 },
    { lat: 48.9243431, lng: 21.3055591 },
    { lat: 48.9247689, lng: 21.3053951 },
    { lat: 48.9250004, lng: 21.3053771 },
    { lat: 48.9267212, lng: 21.3047219 },
    { lat: 48.9270419, lng: 21.3052756 },
    { lat: 48.9276021, lng: 21.3055664 },
    { lat: 48.9281957, lng: 21.3056248 },
    { lat: 48.9288145, lng: 21.3053614 },
    { lat: 48.9290703, lng: 21.3051499 },
    { lat: 48.9300164, lng: 21.3050283 },
    { lat: 48.9303213, lng: 21.304774 },
    { lat: 48.9304886, lng: 21.3039067 },
    { lat: 48.930728, lng: 21.3033034 },
    { lat: 48.9312498, lng: 21.302734 },
    { lat: 48.9313396, lng: 21.3025484 },
    { lat: 48.9314582, lng: 21.3022592 },
    { lat: 48.9320534, lng: 21.2992953 },
    { lat: 48.9322298, lng: 21.298769 },
    { lat: 48.9324173, lng: 21.2983504 },
    { lat: 48.9327706, lng: 21.2978286 },
    { lat: 48.9333791, lng: 21.2973042 },
    { lat: 48.9341734, lng: 21.2964082 },
    { lat: 48.934397, lng: 21.2955493 },
    { lat: 48.934522, lng: 21.2952939 },
    { lat: 48.9347852, lng: 21.2949945 },
    { lat: 48.9350484, lng: 21.2945469 },
    { lat: 48.9351997, lng: 21.2940785 },
    { lat: 48.9355916, lng: 21.2932436 },
    { lat: 48.9359859, lng: 21.2920351 },
    { lat: 48.9362828, lng: 21.2906291 },
    { lat: 48.9363363, lng: 21.2898383 },
    { lat: 48.9363061, lng: 21.2896915 },
    { lat: 48.936313, lng: 21.2882626 },
    { lat: 48.9364294, lng: 21.2868742 },
    { lat: 48.9366461, lng: 21.2855683 },
    { lat: 48.9367533, lng: 21.2836555 },
    { lat: 48.9375992, lng: 21.2812487 },
    { lat: 48.9378079, lng: 21.2804566 },
    { lat: 48.9379603, lng: 21.2801094 },
    { lat: 48.938262, lng: 21.2797828 },
    { lat: 48.9385543, lng: 21.2787385 },
    { lat: 48.938686, lng: 21.2785932 },
    { lat: 48.9387501, lng: 21.2784097 },
    { lat: 48.9389318, lng: 21.2783901 },
    { lat: 48.9390205, lng: 21.2781985 },
    { lat: 48.93928, lng: 21.2780696 },
    { lat: 48.939345, lng: 21.2779146 },
    { lat: 48.9393803, lng: 21.2776015 },
    { lat: 48.9395224, lng: 21.2774218 },
    { lat: 48.9397684, lng: 21.2772609 },
    { lat: 48.9402209, lng: 21.2771245 },
    { lat: 48.9403637, lng: 21.2770042 },
    { lat: 48.9406508, lng: 21.2769786 },
    { lat: 48.9428503, lng: 21.2758187 },
    { lat: 48.9429844, lng: 21.2758225 },
    { lat: 48.9439393, lng: 21.2754441 },
    { lat: 48.9441731, lng: 21.2752536 },
    { lat: 48.9443679, lng: 21.2754386 },
    { lat: 48.9444926, lng: 21.2757248 },
    { lat: 48.9446478, lng: 21.2758713 },
    { lat: 48.9447923, lng: 21.275961 },
    { lat: 48.94496, lng: 21.275849 },
    { lat: 48.9449144, lng: 21.2761994 },
    { lat: 48.9448657, lng: 21.2762251 },
    { lat: 48.9448881, lng: 21.2763652 },
    { lat: 48.9450794, lng: 21.2764069 },
    { lat: 48.9450829, lng: 21.2767442 },
    { lat: 48.9451263, lng: 21.2767995 },
    { lat: 48.945443, lng: 21.276629 },
    { lat: 48.9455773, lng: 21.2769016 },
    { lat: 48.9457509, lng: 21.277097 },
    { lat: 48.9458481, lng: 21.2774005 },
    { lat: 48.945976, lng: 21.2776113 },
    { lat: 48.9461092, lng: 21.2775497 },
    { lat: 48.9466209, lng: 21.2770235 },
    { lat: 48.9471405, lng: 21.2766507 },
    { lat: 48.9495478, lng: 21.2750779 },
    { lat: 48.9503951, lng: 21.2745943 },
    { lat: 48.9503755, lng: 21.2729662 },
    { lat: 48.9508687, lng: 21.2664374 },
    { lat: 48.9508741, lng: 21.263132 },
    { lat: 48.9510806, lng: 21.2620096 },
    { lat: 48.951168, lng: 21.2601728 },
    { lat: 48.9511156, lng: 21.2593011 },
    { lat: 48.9510482, lng: 21.2593113 },
    { lat: 48.9509344, lng: 21.2588628 },
    { lat: 48.9505833, lng: 21.2559035 },
    { lat: 48.9503717, lng: 21.2547653 },
    { lat: 48.9503138, lng: 21.2540863 },
    { lat: 48.9501331, lng: 21.2531199 },
    { lat: 48.9493784, lng: 21.2497328 },
    { lat: 48.9491696, lng: 21.2489095 },
    { lat: 48.9488605, lng: 21.2485909 },
    { lat: 48.9488022, lng: 21.2485643 },
    { lat: 48.9486929, lng: 21.2492877 },
    { lat: 48.9486285, lng: 21.2494613 },
    { lat: 48.9420852, lng: 21.2527286 },
    { lat: 48.9417, lng: 21.2497923 },
    { lat: 48.941583, lng: 21.2499069 },
    { lat: 48.9404908, lng: 21.2504273 },
    { lat: 48.9397587, lng: 21.2505537 },
    { lat: 48.9388388, lng: 21.2510905 },
    { lat: 48.9387876, lng: 21.2511041 },
    { lat: 48.9384237, lng: 21.2512005 },
    { lat: 48.9379128, lng: 21.2507231 },
    { lat: 48.9375292, lng: 21.249815 },
    { lat: 48.9345691, lng: 21.2518102 },
    { lat: 48.9343775, lng: 21.2513631 },
    { lat: 48.9338506, lng: 21.2520118 },
    { lat: 48.9333805, lng: 21.2527102 },
    { lat: 48.9328733, lng: 21.2534938 },
    { lat: 48.932462, lng: 21.254241 },
    { lat: 48.9323107, lng: 21.2527589 },
    { lat: 48.9327253, lng: 21.25263 },
    { lat: 48.9327658, lng: 21.2521554 },
    { lat: 48.9328536, lng: 21.2519025 },
    { lat: 48.9327831, lng: 21.2509305 },
    { lat: 48.932794, lng: 21.2504809 },
    { lat: 48.9323545, lng: 21.2504917 },
    { lat: 48.9311895, lng: 21.2508163 },
    { lat: 48.9312321, lng: 21.2513186 },
    { lat: 48.9308709, lng: 21.2515429 },
    { lat: 48.9305728, lng: 21.2518325 },
    { lat: 48.9299253, lng: 21.2520795 },
    { lat: 48.9299429, lng: 21.2526516 },
    { lat: 48.9302454, lng: 21.2525636 },
    { lat: 48.9303616, lng: 21.2536029 },
    { lat: 48.930255, lng: 21.2535743 },
    { lat: 48.9296624, lng: 21.253995 },
    { lat: 48.9295477, lng: 21.2544347 },
    { lat: 48.9294535, lng: 21.2545534 },
    { lat: 48.9289479, lng: 21.2548681 },
    { lat: 48.9283295, lng: 21.255141 },
    { lat: 48.9277483, lng: 21.2558911 },
    { lat: 48.9277288, lng: 21.2557715 },
    { lat: 48.9276371, lng: 21.2561191 },
    { lat: 48.9275008, lng: 21.2563707 },
    { lat: 48.9274201, lng: 21.2569282 },
    { lat: 48.9272718, lng: 21.2574891 },
    { lat: 48.9271723, lng: 21.2576898 },
    { lat: 48.9260227, lng: 21.257228 },
    { lat: 48.9249475, lng: 21.2567019 },
    { lat: 48.9241082, lng: 21.2552061 },
    { lat: 48.9233681, lng: 21.2542685 },
    { lat: 48.9232528, lng: 21.2539621 },
    { lat: 48.9231082, lng: 21.2529185 },
    { lat: 48.9230546, lng: 21.2529327 },
    { lat: 48.9230582, lng: 21.25206 },
    { lat: 48.9231357, lng: 21.2508575 },
    { lat: 48.9230157, lng: 21.2505376 },
    { lat: 48.9226883, lng: 21.2501368 },
    { lat: 48.9223978, lng: 21.2498772 },
    { lat: 48.9216697, lng: 21.2495499 },
    { lat: 48.9203863, lng: 21.2494224 },
    { lat: 48.9202445, lng: 21.2493838 },
    { lat: 48.9199647, lng: 21.2491425 },
    { lat: 48.9198435, lng: 21.2488584 },
    { lat: 48.9196939, lng: 21.2481387 },
    { lat: 48.9197554, lng: 21.2475393 },
    { lat: 48.9197233, lng: 21.2467504 },
    { lat: 48.9196162, lng: 21.2461619 },
    { lat: 48.9193419, lng: 21.2459545 },
    { lat: 48.9188523, lng: 21.2458763 },
    { lat: 48.9186978, lng: 21.245942 },
    { lat: 48.9183974, lng: 21.2461258 },
    { lat: 48.9173838, lng: 21.2477381 },
    { lat: 48.9172567, lng: 21.2478527 },
    { lat: 48.9160805, lng: 21.2480969 },
    { lat: 48.9161007, lng: 21.247621 },
    { lat: 48.9159638, lng: 21.2465774 },
    { lat: 48.9157404, lng: 21.2462688 },
    { lat: 48.9151583, lng: 21.2461116 },
    { lat: 48.9149556, lng: 21.2461753 },
    { lat: 48.9150719, lng: 21.2458268 },
    { lat: 48.9146131, lng: 21.2458379 },
    { lat: 48.9143377, lng: 21.2457373 },
    { lat: 48.9143419, lng: 21.2453324 },
    { lat: 48.9143275, lng: 21.2453377 },
    { lat: 48.9139384, lng: 21.2453456 },
    { lat: 48.9138389, lng: 21.2452554 },
    { lat: 48.9138252, lng: 21.2458342 },
    { lat: 48.9132056, lng: 21.2459229 },
    { lat: 48.9131201, lng: 21.2459952 },
    { lat: 48.9130238, lng: 21.2466335 },
    { lat: 48.9127869, lng: 21.246719 },
    { lat: 48.9127237, lng: 21.246711 },
    { lat: 48.9122544, lng: 21.2459086 },
    { lat: 48.9120539, lng: 21.245832 },
    { lat: 48.9118852, lng: 21.245981 },
    { lat: 48.91187, lng: 21.2462894 },
    { lat: 48.9119769, lng: 21.246583 },
    { lat: 48.9120871, lng: 21.2466846 },
    { lat: 48.912328, lng: 21.2467659 },
    { lat: 48.912294, lng: 21.2475969 },
    { lat: 48.9117236, lng: 21.2477437 },
    { lat: 48.9113766, lng: 21.2480975 },
    { lat: 48.9114458, lng: 21.2483698 },
    { lat: 48.91155, lng: 21.248537 },
    { lat: 48.9120421, lng: 21.2486588 },
    { lat: 48.9118289, lng: 21.2492978 },
    { lat: 48.9116568, lng: 21.249299 },
    { lat: 48.9113551, lng: 21.2491143 },
    { lat: 48.9107868, lng: 21.2482427 },
    { lat: 48.9106353, lng: 21.2483601 },
    { lat: 48.9102927, lng: 21.2488209 },
    { lat: 48.9099425, lng: 21.2484454 },
    { lat: 48.9099307, lng: 21.248828 },
    { lat: 48.9101844, lng: 21.2493934 },
    { lat: 48.9100549, lng: 21.2494996 },
    { lat: 48.9097857, lng: 21.2495706 },
    { lat: 48.9091144, lng: 21.2494723 },
    { lat: 48.9090718, lng: 21.2483523 },
    { lat: 48.9090125, lng: 21.2479963 },
    { lat: 48.9089116, lng: 21.2480049 },
    { lat: 48.9087395, lng: 21.2477159 },
    { lat: 48.908575, lng: 21.2477108 },
    { lat: 48.9083247, lng: 21.2479521 },
    { lat: 48.908209, lng: 21.2479539 },
    { lat: 48.9080256, lng: 21.2476296 },
    { lat: 48.9078608, lng: 21.2475068 },
    { lat: 48.9077158, lng: 21.2475457 },
    { lat: 48.9077504, lng: 21.2478262 },
    { lat: 48.9075901, lng: 21.2478837 },
    { lat: 48.907221, lng: 21.2473751 },
    { lat: 48.9071884, lng: 21.2472297 },
    { lat: 48.906879, lng: 21.2471966 },
    { lat: 48.9064853, lng: 21.2477808 },
    { lat: 48.9059352, lng: 21.2479755 },
    { lat: 48.9058146, lng: 21.2478939 },
    { lat: 48.9057883, lng: 21.2478063 },
    { lat: 48.905792, lng: 21.2472698 },
    { lat: 48.9057228, lng: 21.2471048 },
    { lat: 48.905563, lng: 21.2471083 },
    { lat: 48.9058113, lng: 21.2490513 },
    { lat: 48.9052492, lng: 21.2489862 },
    { lat: 48.9047817, lng: 21.2498829 },
    { lat: 48.902394, lng: 21.2504292 },
    { lat: 48.9035669, lng: 21.2535031 },
    { lat: 48.9032367, lng: 21.2559348 },
    { lat: 48.9026984, lng: 21.2554067 },
    { lat: 48.9023143, lng: 21.2559068 },
    { lat: 48.9022182, lng: 21.2555266 },
    { lat: 48.9018862, lng: 21.2555984 },
    { lat: 48.9017121, lng: 21.256231 },
    { lat: 48.9013609, lng: 21.2566343 },
    { lat: 48.9012251, lng: 21.2569326 },
    { lat: 48.9011965, lng: 21.257283 },
    { lat: 48.9013394, lng: 21.2577214 },
    { lat: 48.90113, lng: 21.2585263 },
    { lat: 48.9010883, lng: 21.2595462 },
    { lat: 48.9009491, lng: 21.2611291 },
    { lat: 48.9004542, lng: 21.2623824 },
    { lat: 48.9005318, lng: 21.2631505 },
    { lat: 48.9006303, lng: 21.2635395 },
    { lat: 48.9005412, lng: 21.2638252 },
    { lat: 48.9006409, lng: 21.2645446 },
    { lat: 48.9005307, lng: 21.2650198 },
    { lat: 48.9003849, lng: 21.2651457 },
    { lat: 48.9004287, lng: 21.2654373 },
    { lat: 48.9011043, lng: 21.2662208 },
    { lat: 48.9011766, lng: 21.2668162 },
    { lat: 48.9011143, lng: 21.2673139 },
    { lat: 48.9009086, lng: 21.2679476 },
    { lat: 48.9008011, lng: 21.2691341 },
    { lat: 48.9009204, lng: 21.2695777 },
    { lat: 48.900981, lng: 21.2709695 },
    { lat: 48.9011077, lng: 21.2716126 },
    { lat: 48.9012352, lng: 21.272687 },
    { lat: 48.9016946, lng: 21.2741662 },
    { lat: 48.9017233, lng: 21.275062 },
    { lat: 48.9018148, lng: 21.2760728 },
    { lat: 48.9019916, lng: 21.2771781 },
    { lat: 48.9021365, lng: 21.2774226 },
    { lat: 48.9021667, lng: 21.2783769 },
    { lat: 48.9021029, lng: 21.2787197 },
    { lat: 48.9021381, lng: 21.2792084 },
    { lat: 48.9020903, lng: 21.2796653 },
    { lat: 48.9021245, lng: 21.2803067 },
    { lat: 48.9019126, lng: 21.2807709 },
    { lat: 48.901974, lng: 21.2810906 },
    { lat: 48.9021345, lng: 21.2812571 },
    { lat: 48.9021246, lng: 21.2815597 },
    { lat: 48.9022282, lng: 21.2818929 },
    { lat: 48.9027021, lng: 21.282493 },
    { lat: 48.9027907, lng: 21.2830316 },
    { lat: 48.9028583, lng: 21.2831828 },
    { lat: 48.9028423, lng: 21.2834307 },
    { lat: 48.9029575, lng: 21.2835181 },
    { lat: 48.90298, lng: 21.2837376 },
    { lat: 48.9031708, lng: 21.2840175 },
    { lat: 48.9033056, lng: 21.2840845 },
    { lat: 48.9033479, lng: 21.2847286 },
    { lat: 48.903431, lng: 21.284946 },
    { lat: 48.9036136, lng: 21.2850287 },
    { lat: 48.9037732, lng: 21.2855422 },
    { lat: 48.9045271, lng: 21.2861993 },
    { lat: 48.9054381, lng: 21.2867366 },
    { lat: 48.9062395, lng: 21.2873743 },
    { lat: 48.9062863, lng: 21.2876879 },
    { lat: 48.9066392, lng: 21.2881922 },
    { lat: 48.9071097, lng: 21.2894094 },
  ],
  Ličartovce: [
    { lat: 48.8883655, lng: 21.252719 },
    { lat: 48.8882019, lng: 21.2502232 },
    { lat: 48.8876696, lng: 21.2446811 },
    { lat: 48.8876474, lng: 21.2440712 },
    { lat: 48.8878568, lng: 21.243673 },
    { lat: 48.887823, lng: 21.2425418 },
    { lat: 48.8877602, lng: 21.242478 },
    { lat: 48.8877846, lng: 21.2422152 },
    { lat: 48.8877484, lng: 21.24199 },
    { lat: 48.8875192, lng: 21.2416691 },
    { lat: 48.8874874, lng: 21.2414125 },
    { lat: 48.8873873, lng: 21.2413034 },
    { lat: 48.8872261, lng: 21.2407477 },
    { lat: 48.8870557, lng: 21.239569 },
    { lat: 48.8870676, lng: 21.239369 },
    { lat: 48.8869852, lng: 21.2392208 },
    { lat: 48.8869233, lng: 21.2389269 },
    { lat: 48.8869645, lng: 21.2388035 },
    { lat: 48.8869126, lng: 21.2385798 },
    { lat: 48.887103, lng: 21.2376776 },
    { lat: 48.8873363, lng: 21.2371093 },
    { lat: 48.887393, lng: 21.236793 },
    { lat: 48.8874682, lng: 21.2366775 },
    { lat: 48.8874455, lng: 21.2365683 },
    { lat: 48.88761, lng: 21.236101 },
    { lat: 48.8877663, lng: 21.2358013 },
    { lat: 48.8877663, lng: 21.23559 },
    { lat: 48.8878996, lng: 21.2352418 },
    { lat: 48.8890853, lng: 21.2338114 },
    { lat: 48.8892949, lng: 21.2334535 },
    { lat: 48.8894418, lng: 21.2333683 },
    { lat: 48.8895563, lng: 21.233176 },
    { lat: 48.889803, lng: 21.2329613 },
    { lat: 48.8911809, lng: 21.2297848 },
    { lat: 48.8913553, lng: 21.2292795 },
    { lat: 48.8918781, lng: 21.2290051 },
    { lat: 48.8944186, lng: 21.2272924 },
    { lat: 48.8961708, lng: 21.2265274 },
    { lat: 48.8964551, lng: 21.2262951 },
    { lat: 48.8970059, lng: 21.22555 },
    { lat: 48.8982011, lng: 21.2251574 },
    { lat: 48.8983813, lng: 21.2246875 },
    { lat: 48.898471, lng: 21.2242443 },
    { lat: 48.8984919, lng: 21.2239742 },
    { lat: 48.898402, lng: 21.2233662 },
    { lat: 48.8984254, lng: 21.2230347 },
    { lat: 48.8987156, lng: 21.2225537 },
    { lat: 48.8992155, lng: 21.2219846 },
    { lat: 48.8994364, lng: 21.2218919 },
    { lat: 48.899586, lng: 21.2217432 },
    { lat: 48.8998194, lng: 21.2212851 },
    { lat: 48.9001093, lng: 21.220907 },
    { lat: 48.9005417, lng: 21.2205932 },
    { lat: 48.9007422, lng: 21.2203774 },
    { lat: 48.9009328, lng: 21.2199246 },
    { lat: 48.9017248, lng: 21.2195195 },
    { lat: 48.9020494, lng: 21.2190981 },
    { lat: 48.9026907, lng: 21.2186988 },
    { lat: 48.9030579, lng: 21.2183066 },
    { lat: 48.9037711, lng: 21.2176872 },
    { lat: 48.9043613, lng: 21.2174131 },
    { lat: 48.9047727, lng: 21.2170395 },
    { lat: 48.9047274, lng: 21.216992 },
    { lat: 48.9044325, lng: 21.2165581 },
    { lat: 48.9042823, lng: 21.2164275 },
    { lat: 48.9036739, lng: 21.2164815 },
    { lat: 48.9030592, lng: 21.216112 },
    { lat: 48.9026292, lng: 21.2160738 },
    { lat: 48.9023224, lng: 21.2159359 },
    { lat: 48.902196, lng: 21.2158229 },
    { lat: 48.8997636, lng: 21.2134934 },
    { lat: 48.884542, lng: 21.196982 },
    { lat: 48.884485, lng: 21.197083 },
    { lat: 48.884433, lng: 21.197176 },
    { lat: 48.884117, lng: 21.197773 },
    { lat: 48.88363, lng: 21.198737 },
    { lat: 48.883398, lng: 21.199191 },
    { lat: 48.883087, lng: 21.199771 },
    { lat: 48.882821, lng: 21.200282 },
    { lat: 48.882445, lng: 21.201017 },
    { lat: 48.881827, lng: 21.202257 },
    { lat: 48.881352, lng: 21.203194 },
    { lat: 48.880649, lng: 21.204575 },
    { lat: 48.880141, lng: 21.205575 },
    { lat: 48.879487, lng: 21.206833 },
    { lat: 48.878882, lng: 21.208072 },
    { lat: 48.878226, lng: 21.209372 },
    { lat: 48.87755, lng: 21.210584 },
    { lat: 48.877467, lng: 21.210835 },
    { lat: 48.877103, lng: 21.211558 },
    { lat: 48.876664, lng: 21.212399 },
    { lat: 48.876092, lng: 21.213527 },
    { lat: 48.875448, lng: 21.214802 },
    { lat: 48.874829, lng: 21.216047 },
    { lat: 48.874164, lng: 21.21738 },
    { lat: 48.873888, lng: 21.217958 },
    { lat: 48.873496, lng: 21.21874 },
    { lat: 48.87339, lng: 21.218947 },
    { lat: 48.872735, lng: 21.220288 },
    { lat: 48.872708, lng: 21.220342 },
    { lat: 48.872575, lng: 21.220614 },
    { lat: 48.872443, lng: 21.220876 },
    { lat: 48.871078, lng: 21.223526 },
    { lat: 48.871003, lng: 21.223719 },
    { lat: 48.871111, lng: 21.223772 },
    { lat: 48.871201, lng: 21.223831 },
    { lat: 48.871277, lng: 21.223883 },
    { lat: 48.872009, lng: 21.224122 },
    { lat: 48.872679, lng: 21.224485 },
    { lat: 48.872936, lng: 21.224445 },
    { lat: 48.873013, lng: 21.22438 },
    { lat: 48.873381, lng: 21.224153 },
    { lat: 48.873779, lng: 21.224125 },
    { lat: 48.87401, lng: 21.224219 },
    { lat: 48.874463, lng: 21.224832 },
    { lat: 48.874692, lng: 21.224903 },
    { lat: 48.874831, lng: 21.224874 },
    { lat: 48.875272, lng: 21.224607 },
    { lat: 48.875575, lng: 21.224469 },
    { lat: 48.875519, lng: 21.22433 },
    { lat: 48.875509, lng: 21.2243 },
    { lat: 48.875504, lng: 21.224285 },
    { lat: 48.875497, lng: 21.224266 },
    { lat: 48.875539, lng: 21.224232 },
    { lat: 48.875702, lng: 21.224255 },
    { lat: 48.875895, lng: 21.224282 },
    { lat: 48.876242, lng: 21.224193 },
    { lat: 48.876613, lng: 21.224275 },
    { lat: 48.876693, lng: 21.224293 },
    { lat: 48.876794, lng: 21.224315 },
    { lat: 48.876791, lng: 21.224325 },
    { lat: 48.877297, lng: 21.224659 },
    { lat: 48.877475, lng: 21.224785 },
    { lat: 48.877556, lng: 21.224834 },
    { lat: 48.877792, lng: 21.224889 },
    { lat: 48.878106, lng: 21.224789 },
    { lat: 48.878174, lng: 21.224773 },
    { lat: 48.878271, lng: 21.224751 },
    { lat: 48.878293, lng: 21.224747 },
    { lat: 48.878328, lng: 21.225052 },
    { lat: 48.87836, lng: 21.225311 },
    { lat: 48.878434, lng: 21.225416 },
    { lat: 48.878492, lng: 21.225499 },
    { lat: 48.878677, lng: 21.22576 },
    { lat: 48.878701, lng: 21.225737 },
    { lat: 48.878858, lng: 21.225854 },
    { lat: 48.878834, lng: 21.225877 },
    { lat: 48.878702, lng: 21.226438 },
    { lat: 48.87862, lng: 21.226849 },
    { lat: 48.878607, lng: 21.227184 },
    { lat: 48.878596, lng: 21.227614 },
    { lat: 48.878575, lng: 21.228121 },
    { lat: 48.878575, lng: 21.228389 },
    { lat: 48.878582, lng: 21.228669 },
    { lat: 48.878565, lng: 21.229099 },
    { lat: 48.878495, lng: 21.229523 },
    { lat: 48.878337, lng: 21.229884 },
    { lat: 48.878258, lng: 21.229983 },
    { lat: 48.877935, lng: 21.230163 },
    { lat: 48.877574, lng: 21.230385 },
    { lat: 48.877636, lng: 21.230482 },
    { lat: 48.877398, lng: 21.230528 },
    { lat: 48.877241, lng: 21.23137 },
    { lat: 48.877143, lng: 21.231824 },
    { lat: 48.877046, lng: 21.232305 },
    { lat: 48.876779, lng: 21.23347 },
    { lat: 48.876502, lng: 21.234688 },
    { lat: 48.876196, lng: 21.236004 },
    { lat: 48.875726, lng: 21.235735 },
    { lat: 48.875113, lng: 21.235519 },
    { lat: 48.874481, lng: 21.235359 },
    { lat: 48.873731, lng: 21.234835 },
    { lat: 48.873245, lng: 21.234261 },
    { lat: 48.872939, lng: 21.234458 },
    { lat: 48.872497, lng: 21.234675 },
    { lat: 48.871899, lng: 21.234939 },
    { lat: 48.871086, lng: 21.235359 },
    { lat: 48.870372, lng: 21.235648 },
    { lat: 48.87001, lng: 21.23589 },
    { lat: 48.869666, lng: 21.236037 },
    { lat: 48.868942, lng: 21.236349 },
    { lat: 48.870138, lng: 21.242059 },
    { lat: 48.870166, lng: 21.242226 },
    { lat: 48.870219, lng: 21.24254 },
    { lat: 48.869799, lng: 21.242813 },
    { lat: 48.869725, lng: 21.242907 },
    { lat: 48.869503, lng: 21.243131 },
    { lat: 48.869087, lng: 21.24367 },
    { lat: 48.868969, lng: 21.24376 },
    { lat: 48.869028, lng: 21.243818 },
    { lat: 48.869207, lng: 21.244632 },
    { lat: 48.869109, lng: 21.245588 },
    { lat: 48.869062, lng: 21.245923 },
    { lat: 48.868965, lng: 21.246112 },
    { lat: 48.869035, lng: 21.2468 },
    { lat: 48.868669, lng: 21.247974 },
    { lat: 48.868367, lng: 21.249015 },
    { lat: 48.868322, lng: 21.249297 },
    { lat: 48.86824, lng: 21.249552 },
    { lat: 48.868058, lng: 21.250324 },
    { lat: 48.867967, lng: 21.250776 },
    { lat: 48.867894, lng: 21.251483 },
    { lat: 48.867902, lng: 21.252762 },
    { lat: 48.868106, lng: 21.253437 },
    { lat: 48.868157, lng: 21.25373 },
    { lat: 48.868194, lng: 21.253969 },
    { lat: 48.868268, lng: 21.253966 },
    { lat: 48.868268, lng: 21.253977 },
    { lat: 48.868253, lng: 21.254259 },
    { lat: 48.868318, lng: 21.254258 },
    { lat: 48.868623, lng: 21.25412 },
    { lat: 48.868718, lng: 21.254044 },
    { lat: 48.868772, lng: 21.254001 },
    { lat: 48.868852, lng: 21.253936 },
    { lat: 48.868968, lng: 21.253835 },
    { lat: 48.869272, lng: 21.253414 },
    { lat: 48.869376, lng: 21.253271 },
    { lat: 48.869504, lng: 21.25305 },
    { lat: 48.869976, lng: 21.252235 },
    { lat: 48.87021, lng: 21.251981 },
    { lat: 48.870665, lng: 21.25298 },
    { lat: 48.870721, lng: 21.253104 },
    { lat: 48.871104, lng: 21.253944 },
    { lat: 48.871121, lng: 21.253982 },
    { lat: 48.871285, lng: 21.254341 },
    { lat: 48.871347, lng: 21.25448 },
    { lat: 48.871399, lng: 21.254593 },
    { lat: 48.871577, lng: 21.254982 },
    { lat: 48.871593, lng: 21.255016 },
    { lat: 48.871627, lng: 21.25509 },
    { lat: 48.871684, lng: 21.255211 },
    { lat: 48.871688, lng: 21.255218 },
    { lat: 48.872219, lng: 21.256347 },
    { lat: 48.872924, lng: 21.25785 },
    { lat: 48.873657, lng: 21.25956 },
    { lat: 48.8748374, lng: 21.2582826 },
    { lat: 48.8775835, lng: 21.2548642 },
    { lat: 48.8780152, lng: 21.2527747 },
    { lat: 48.8780136, lng: 21.2522081 },
    { lat: 48.8779797, lng: 21.2520975 },
    { lat: 48.8791023, lng: 21.2517491 },
    { lat: 48.883419, lng: 21.2508742 },
    { lat: 48.8839998, lng: 21.2560595 },
    { lat: 48.8882877, lng: 21.254319 },
    { lat: 48.8883655, lng: 21.252719 },
  ],
  Abranovce: [
    { lat: 48.9285935, lng: 21.328521 },
    { lat: 48.9279555, lng: 21.3303557 },
    { lat: 48.9273744, lng: 21.332464 },
    { lat: 48.9258713, lng: 21.331952 },
    { lat: 48.9245702, lng: 21.3316601 },
    { lat: 48.9240903, lng: 21.3315524 },
    { lat: 48.9244466, lng: 21.3319532 },
    { lat: 48.9245521, lng: 21.3326529 },
    { lat: 48.9244729, lng: 21.3339828 },
    { lat: 48.9242993, lng: 21.3346815 },
    { lat: 48.9243268, lng: 21.3363467 },
    { lat: 48.9247045, lng: 21.3377115 },
    { lat: 48.9248363, lng: 21.3384318 },
    { lat: 48.9250824, lng: 21.340067 },
    { lat: 48.9251171, lng: 21.3412995 },
    { lat: 48.9258365, lng: 21.3436315 },
    { lat: 48.9261039, lng: 21.3459443 },
    { lat: 48.9262153, lng: 21.3465049 },
    { lat: 48.9257748, lng: 21.3476521 },
    { lat: 48.9260175, lng: 21.3506817 },
    { lat: 48.926108, lng: 21.3511456 },
    { lat: 48.9260952, lng: 21.3523392 },
    { lat: 48.9258239, lng: 21.3528563 },
    { lat: 48.9262473, lng: 21.3578752 },
    { lat: 48.924706, lng: 21.3625872 },
    { lat: 48.9214462, lng: 21.3730065 },
    { lat: 48.9211461, lng: 21.374191 },
    { lat: 48.9214327, lng: 21.3744859 },
    { lat: 48.9220003, lng: 21.3744953 },
    { lat: 48.9225418, lng: 21.3746997 },
    { lat: 48.9239118, lng: 21.3747565 },
    { lat: 48.9244631, lng: 21.374035 },
    { lat: 48.9250494, lng: 21.3737686 },
    { lat: 48.9257614, lng: 21.3737288 },
    { lat: 48.9257545, lng: 21.3729259 },
    { lat: 48.9266255, lng: 21.3733457 },
    { lat: 48.9277496, lng: 21.3739905 },
    { lat: 48.9291513, lng: 21.3739792 },
    { lat: 48.9303006, lng: 21.3733075 },
    { lat: 48.9315347, lng: 21.3732272 },
    { lat: 48.9319794, lng: 21.373274 },
    { lat: 48.9332703, lng: 21.3738415 },
    { lat: 48.9327629, lng: 21.374835 },
    { lat: 48.9321783, lng: 21.3753403 },
    { lat: 48.9308362, lng: 21.3769035 },
    { lat: 48.9302151, lng: 21.3774713 },
    { lat: 48.9293999, lng: 21.3789714 },
    { lat: 48.9308869, lng: 21.3802342 },
    { lat: 48.9331596, lng: 21.3806446 },
    { lat: 48.9345304, lng: 21.3809627 },
    { lat: 48.9354161, lng: 21.3813066 },
    { lat: 48.9359237, lng: 21.3816537 },
    { lat: 48.9366638, lng: 21.3816844 },
    { lat: 48.9375434, lng: 21.3821235 },
    { lat: 48.938651, lng: 21.3831208 },
    { lat: 48.9389058, lng: 21.3830218 },
    { lat: 48.9390577, lng: 21.3831041 },
    { lat: 48.9412075, lng: 21.3820991 },
    { lat: 48.941856, lng: 21.3819184 },
    { lat: 48.9427141, lng: 21.3820953 },
    { lat: 48.9428372, lng: 21.3820324 },
    { lat: 48.9438527, lng: 21.3822841 },
    { lat: 48.9448424, lng: 21.3823442 },
    { lat: 48.9462916, lng: 21.3822662 },
    { lat: 48.9474363, lng: 21.3831466 },
    { lat: 48.9476427, lng: 21.3832359 },
    { lat: 48.9492506, lng: 21.3834697 },
    { lat: 48.9503547, lng: 21.383474 },
    { lat: 48.9511506, lng: 21.383566 },
    { lat: 48.9522945, lng: 21.3833926 },
    { lat: 48.9524741, lng: 21.3835736 },
    { lat: 48.9527678, lng: 21.3836806 },
    { lat: 48.9536972, lng: 21.3830754 },
    { lat: 48.9539157, lng: 21.3823254 },
    { lat: 48.9537939, lng: 21.3814459 },
    { lat: 48.9534525, lng: 21.3806701 },
    { lat: 48.9535065, lng: 21.3797957 },
    { lat: 48.9532712, lng: 21.3797549 },
    { lat: 48.9532129, lng: 21.3796515 },
    { lat: 48.9531305, lng: 21.3789575 },
    { lat: 48.9531164, lng: 21.3781404 },
    { lat: 48.9533159, lng: 21.3773935 },
    { lat: 48.9533892, lng: 21.3766477 },
    { lat: 48.9538639, lng: 21.3760699 },
    { lat: 48.9539258, lng: 21.3757349 },
    { lat: 48.9537119, lng: 21.3751215 },
    { lat: 48.9539411, lng: 21.3744313 },
    { lat: 48.9539457, lng: 21.3729938 },
    { lat: 48.9541446, lng: 21.3718868 },
    { lat: 48.9540025, lng: 21.3707397 },
    { lat: 48.9541601, lng: 21.369571 },
    { lat: 48.9540608, lng: 21.3690333 },
    { lat: 48.9533418, lng: 21.366584 },
    { lat: 48.9529175, lng: 21.3657882 },
    { lat: 48.9526538, lng: 21.3636596 },
    { lat: 48.952395, lng: 21.3630935 },
    { lat: 48.9517913, lng: 21.3621662 },
    { lat: 48.9512668, lng: 21.3606539 },
    { lat: 48.9512752, lng: 21.3604457 },
    { lat: 48.9515859, lng: 21.3600724 },
    { lat: 48.9516753, lng: 21.3596774 },
    { lat: 48.951662, lng: 21.3595333 },
    { lat: 48.9510703, lng: 21.3582988 },
    { lat: 48.9508946, lng: 21.3575397 },
    { lat: 48.9505265, lng: 21.3567019 },
    { lat: 48.9501136, lng: 21.3562911 },
    { lat: 48.9499998, lng: 21.3558736 },
    { lat: 48.9498689, lng: 21.3555966 },
    { lat: 48.9495112, lng: 21.3550775 },
    { lat: 48.9494407, lng: 21.3552345 },
    { lat: 48.9482738, lng: 21.35415 },
    { lat: 48.9476022, lng: 21.354415 },
    { lat: 48.9472278, lng: 21.3539357 },
    { lat: 48.9468407, lng: 21.3528795 },
    { lat: 48.9466693, lng: 21.3522262 },
    { lat: 48.9461702, lng: 21.3496559 },
    { lat: 48.946024, lng: 21.3492834 },
    { lat: 48.9458818, lng: 21.3479135 },
    { lat: 48.945545, lng: 21.3474163 },
    { lat: 48.9453493, lng: 21.3467393 },
    { lat: 48.9452296, lng: 21.3465077 },
    { lat: 48.9453091, lng: 21.346005 },
    { lat: 48.9448605, lng: 21.3454507 },
    { lat: 48.9446824, lng: 21.3443536 },
    { lat: 48.9446074, lng: 21.3445203 },
    { lat: 48.9438478, lng: 21.3445549 },
    { lat: 48.9438727, lng: 21.3439163 },
    { lat: 48.9437152, lng: 21.343129 },
    { lat: 48.9430805, lng: 21.3436579 },
    { lat: 48.9421975, lng: 21.3422062 },
    { lat: 48.9426235, lng: 21.3413255 },
    { lat: 48.9419924, lng: 21.3381874 },
    { lat: 48.9415352, lng: 21.3381345 },
    { lat: 48.9409131, lng: 21.3398274 },
    { lat: 48.9407414, lng: 21.3397699 },
    { lat: 48.9404926, lng: 21.3393912 },
    { lat: 48.9408666, lng: 21.3374539 },
    { lat: 48.9410856, lng: 21.3375174 },
    { lat: 48.9412103, lng: 21.3366515 },
    { lat: 48.9409098, lng: 21.3352602 },
    { lat: 48.9414378, lng: 21.3337027 },
    { lat: 48.9416411, lng: 21.3333135 },
    { lat: 48.9416202, lng: 21.3309352 },
    { lat: 48.9417876, lng: 21.3291729 },
    { lat: 48.9418978, lng: 21.3284958 },
    { lat: 48.9418376, lng: 21.3276252 },
    { lat: 48.9422339, lng: 21.3274022 },
    { lat: 48.9424355, lng: 21.3267775 },
    { lat: 48.9429451, lng: 21.3261386 },
    { lat: 48.9433166, lng: 21.3254684 },
    { lat: 48.9434907, lng: 21.3244562 },
    { lat: 48.9427389, lng: 21.3239615 },
    { lat: 48.9422078, lng: 21.3251943 },
    { lat: 48.9417241, lng: 21.3268785 },
    { lat: 48.9411357, lng: 21.3285978 },
    { lat: 48.9398851, lng: 21.332622 },
    { lat: 48.930405, lng: 21.329267 },
    { lat: 48.9285935, lng: 21.328521 },
  ],
  ŠarišskáTrstená: [
    { lat: 49.097681, lng: 21.3783481 },
    { lat: 49.0964126, lng: 21.3776195 },
    { lat: 49.0946527, lng: 21.3772255 },
    { lat: 49.0937806, lng: 21.3762407 },
    { lat: 49.0920397, lng: 21.3778258 },
    { lat: 49.0905719, lng: 21.3788682 },
    { lat: 49.0884131, lng: 21.3797172 },
    { lat: 49.0865251, lng: 21.3801752 },
    { lat: 49.0863587, lng: 21.3794206 },
    { lat: 49.086084, lng: 21.3795637 },
    { lat: 49.0860829, lng: 21.3801711 },
    { lat: 49.0862473, lng: 21.3817346 },
    { lat: 49.0860479, lng: 21.3820333 },
    { lat: 49.0859569, lng: 21.3822646 },
    { lat: 49.0857491, lng: 21.3824695 },
    { lat: 49.0844166, lng: 21.3826883 },
    { lat: 49.0843943, lng: 21.3824694 },
    { lat: 49.084141, lng: 21.3823874 },
    { lat: 49.0840416, lng: 21.3822937 },
    { lat: 49.0838096, lng: 21.3823638 },
    { lat: 49.0834654, lng: 21.3826369 },
    { lat: 49.0834074, lng: 21.3825332 },
    { lat: 49.0826655, lng: 21.3824082 },
    { lat: 49.0824447, lng: 21.3824782 },
    { lat: 49.0818427, lng: 21.3824366 },
    { lat: 49.081685, lng: 21.3825091 },
    { lat: 49.0814859, lng: 21.3827374 },
    { lat: 49.0811213, lng: 21.3826935 },
    { lat: 49.080797, lng: 21.3828798 },
    { lat: 49.080028, lng: 21.3829415 },
    { lat: 49.0799579, lng: 21.3829233 },
    { lat: 49.0797027, lng: 21.3825923 },
    { lat: 49.0794151, lng: 21.3827208 },
    { lat: 49.0789839, lng: 21.3827869 },
    { lat: 49.0785701, lng: 21.383064 },
    { lat: 49.0785708, lng: 21.3828982 },
    { lat: 49.0779077, lng: 21.3827674 },
    { lat: 49.0777961, lng: 21.383107 },
    { lat: 49.0775749, lng: 21.3832342 },
    { lat: 49.0774946, lng: 21.3833707 },
    { lat: 49.0770892, lng: 21.3832506 },
    { lat: 49.0768301, lng: 21.3829299 },
    { lat: 49.0765034, lng: 21.3828066 },
    { lat: 49.0758845, lng: 21.3830299 },
    { lat: 49.0757952, lng: 21.3833576 },
    { lat: 49.0756348, lng: 21.3835251 },
    { lat: 49.0753335, lng: 21.383269 },
    { lat: 49.0750692, lng: 21.3833482 },
    { lat: 49.0746542, lng: 21.3837725 },
    { lat: 49.0744432, lng: 21.3844724 },
    { lat: 49.0733079, lng: 21.3854671 },
    { lat: 49.0729483, lng: 21.386011 },
    { lat: 49.0725806, lng: 21.3870116 },
    { lat: 49.0723895, lng: 21.3878907 },
    { lat: 49.0724916, lng: 21.3882993 },
    { lat: 49.0727333, lng: 21.3887568 },
    { lat: 49.0725064, lng: 21.3894488 },
    { lat: 49.0721505, lng: 21.3897785 },
    { lat: 49.0721675, lng: 21.3900501 },
    { lat: 49.0720092, lng: 21.3902558 },
    { lat: 49.071824, lng: 21.3903891 },
    { lat: 49.0718052, lng: 21.3905892 },
    { lat: 49.0716916, lng: 21.3908144 },
    { lat: 49.0714947, lng: 21.3908537 },
    { lat: 49.0713121, lng: 21.3913044 },
    { lat: 49.0712596, lng: 21.3912369 },
    { lat: 49.0711299, lng: 21.3913771 },
    { lat: 49.0711459, lng: 21.391577 },
    { lat: 49.0711027, lng: 21.3917188 },
    { lat: 49.0697639, lng: 21.3934747 },
    { lat: 49.0696758, lng: 21.3936555 },
    { lat: 49.0705184, lng: 21.3940683 },
    { lat: 49.0712005, lng: 21.394611 },
    { lat: 49.0717152, lng: 21.3949002 },
    { lat: 49.0718645, lng: 21.3948854 },
    { lat: 49.0720045, lng: 21.3950499 },
    { lat: 49.072246, lng: 21.3950047 },
    { lat: 49.0725363, lng: 21.3952314 },
    { lat: 49.0728579, lng: 21.3951107 },
    { lat: 49.0729496, lng: 21.3952466 },
    { lat: 49.0733974, lng: 21.39524 },
    { lat: 49.0739162, lng: 21.3955139 },
    { lat: 49.0747127, lng: 21.3952758 },
    { lat: 49.0750833, lng: 21.3949885 },
    { lat: 49.0753143, lng: 21.3942736 },
    { lat: 49.0757612, lng: 21.3942466 },
    { lat: 49.0759276, lng: 21.3943142 },
    { lat: 49.0769248, lng: 21.3937203 },
    { lat: 49.0777559, lng: 21.3931678 },
    { lat: 49.0780153, lng: 21.3927309 },
    { lat: 49.0781817, lng: 21.3927666 },
    { lat: 49.0783658, lng: 21.3926373 },
    { lat: 49.0785463, lng: 21.3923475 },
    { lat: 49.0789868, lng: 21.3920224 },
    { lat: 49.0795338, lng: 21.3917269 },
    { lat: 49.0798959, lng: 21.3920209 },
    { lat: 49.0803292, lng: 21.3918728 },
    { lat: 49.0814264, lng: 21.391656 },
    { lat: 49.0816605, lng: 21.3917671 },
    { lat: 49.0821482, lng: 21.3914807 },
    { lat: 49.0824872, lng: 21.3914158 },
    { lat: 49.0828137, lng: 21.3910399 },
    { lat: 49.0865196, lng: 21.3909501 },
    { lat: 49.0876316, lng: 21.3902006 },
    { lat: 49.0878248, lng: 21.3899559 },
    { lat: 49.0878338, lng: 21.3894232 },
    { lat: 49.0876225, lng: 21.3877221 },
    { lat: 49.0873914, lng: 21.3867544 },
    { lat: 49.0877498, lng: 21.3865094 },
    { lat: 49.0886274, lng: 21.3898607 },
    { lat: 49.0891361, lng: 21.389673 },
    { lat: 49.0895451, lng: 21.3893844 },
    { lat: 49.0901381, lng: 21.3888893 },
    { lat: 49.0909379, lng: 21.3870963 },
    { lat: 49.0912378, lng: 21.3868068 },
    { lat: 49.0920686, lng: 21.3869822 },
    { lat: 49.0926333, lng: 21.3864064 },
    { lat: 49.093049, lng: 21.3853142 },
    { lat: 49.0939542, lng: 21.3843427 },
    { lat: 49.0951512, lng: 21.3829326 },
    { lat: 49.0968524, lng: 21.3806139 },
    { lat: 49.0971805, lng: 21.3794166 },
    { lat: 49.0974537, lng: 21.3789217 },
    { lat: 49.097681, lng: 21.3783481 },
  ],
  Kojatice: [
    { lat: 48.9992347, lng: 21.1104765 },
    { lat: 48.9981452, lng: 21.1095918 },
    { lat: 48.9975641, lng: 21.1091935 },
    { lat: 48.9965854, lng: 21.1081161 },
    { lat: 48.995618, lng: 21.1075697 },
    { lat: 48.9951358, lng: 21.107447 },
    { lat: 48.9941069, lng: 21.1070116 },
    { lat: 48.9932043, lng: 21.1069056 },
    { lat: 48.9927418, lng: 21.1066621 },
    { lat: 48.9924768, lng: 21.1066494 },
    { lat: 48.9919021, lng: 21.1062393 },
    { lat: 48.9912853, lng: 21.10599 },
    { lat: 48.9909097, lng: 21.1059274 },
    { lat: 48.99088, lng: 21.1058107 },
    { lat: 48.9904758, lng: 21.1055589 },
    { lat: 48.9903541, lng: 21.106009 },
    { lat: 48.9901765, lng: 21.1068904 },
    { lat: 48.9899372, lng: 21.1076822 },
    { lat: 48.9897557, lng: 21.1087613 },
    { lat: 48.9894412, lng: 21.1101183 },
    { lat: 48.9892088, lng: 21.1107349 },
    { lat: 48.989193, lng: 21.1109425 },
    { lat: 48.9888006, lng: 21.1118706 },
    { lat: 48.9881383, lng: 21.1127673 },
    { lat: 48.9877359, lng: 21.1129582 },
    { lat: 48.9872957, lng: 21.1130574 },
    { lat: 48.9867679, lng: 21.1133719 },
    { lat: 48.9862449, lng: 21.1139128 },
    { lat: 48.9860522, lng: 21.1141857 },
    { lat: 48.9854763, lng: 21.1145109 },
    { lat: 48.9845376, lng: 21.1154577 },
    { lat: 48.9840115, lng: 21.1158304 },
    { lat: 48.9838134, lng: 21.1161467 },
    { lat: 48.9836395, lng: 21.1163071 },
    { lat: 48.9830896, lng: 21.1166035 },
    { lat: 48.9823931, lng: 21.1168498 },
    { lat: 48.9822976, lng: 21.1169625 },
    { lat: 48.9820267, lng: 21.1176718 },
    { lat: 48.9816803, lng: 21.1179997 },
    { lat: 48.9812063, lng: 21.1182726 },
    { lat: 48.9809787, lng: 21.118552 },
    { lat: 48.9807284, lng: 21.1187495 },
    { lat: 48.9803718, lng: 21.1189256 },
    { lat: 48.9799978, lng: 21.1189839 },
    { lat: 48.97918, lng: 21.1195999 },
    { lat: 48.9788981, lng: 21.1196181 },
    { lat: 48.9763372, lng: 21.1212874 },
    { lat: 48.9758271, lng: 21.1218419 },
    { lat: 48.9758784, lng: 21.1220369 },
    { lat: 48.9763787, lng: 21.124741 },
    { lat: 48.9761672, lng: 21.1254878 },
    { lat: 48.9762328, lng: 21.1261335 },
    { lat: 48.9762274, lng: 21.1272688 },
    { lat: 48.9763523, lng: 21.1286138 },
    { lat: 48.9766058, lng: 21.1298938 },
    { lat: 48.9765537, lng: 21.1307678 },
    { lat: 48.9768734, lng: 21.1312645 },
    { lat: 48.9764432, lng: 21.1323232 },
    { lat: 48.9762141, lng: 21.132896 },
    { lat: 48.9759124, lng: 21.1331467 },
    { lat: 48.9757123, lng: 21.1329046 },
    { lat: 48.9747737, lng: 21.1329709 },
    { lat: 48.9740851, lng: 21.1328388 },
    { lat: 48.9735457, lng: 21.1331463 },
    { lat: 48.9732972, lng: 21.1329707 },
    { lat: 48.9730544, lng: 21.1332619 },
    { lat: 48.9729508, lng: 21.1343957 },
    { lat: 48.9714779, lng: 21.1345471 },
    { lat: 48.9711623, lng: 21.1342461 },
    { lat: 48.9709429, lng: 21.1337166 },
    { lat: 48.9699088, lng: 21.1339343 },
    { lat: 48.9694852, lng: 21.134132 },
    { lat: 48.9690429, lng: 21.1347361 },
    { lat: 48.9690608, lng: 21.1352248 },
    { lat: 48.9681907, lng: 21.1354354 },
    { lat: 48.9678886, lng: 21.1352423 },
    { lat: 48.9671619, lng: 21.1353576 },
    { lat: 48.9668408, lng: 21.1355602 },
    { lat: 48.9667009, lng: 21.1358986 },
    { lat: 48.9667565, lng: 21.1369432 },
    { lat: 48.9668224, lng: 21.1372453 },
    { lat: 48.9664266, lng: 21.1374345 },
    { lat: 48.9658839, lng: 21.1380645 },
    { lat: 48.9655308, lng: 21.1389217 },
    { lat: 48.9659666, lng: 21.1394281 },
    { lat: 48.9649578, lng: 21.1410399 },
    { lat: 48.9665362, lng: 21.1432933 },
    { lat: 48.9667155, lng: 21.1436609 },
    { lat: 48.966703, lng: 21.1438029 },
    { lat: 48.9668294, lng: 21.1438832 },
    { lat: 48.9671084, lng: 21.1439133 },
    { lat: 48.9674225, lng: 21.1438481 },
    { lat: 48.9677671, lng: 21.1439977 },
    { lat: 48.9680204, lng: 21.1438932 },
    { lat: 48.9688668, lng: 21.1439326 },
    { lat: 48.9690835, lng: 21.1438433 },
    { lat: 48.9695833, lng: 21.1439583 },
    { lat: 48.9714177, lng: 21.1456216 },
    { lat: 48.9717694, lng: 21.1461729 },
    { lat: 48.9722847, lng: 21.146636 },
    { lat: 48.9727356, lng: 21.1478658 },
    { lat: 48.9725832, lng: 21.1483487 },
    { lat: 48.9726016, lng: 21.1483634 },
    { lat: 48.9727113, lng: 21.1485209 },
    { lat: 48.9726387, lng: 21.1499215 },
    { lat: 48.9724918, lng: 21.1501525 },
    { lat: 48.9723589, lng: 21.1505047 },
    { lat: 48.9716881, lng: 21.1503975 },
    { lat: 48.9718281, lng: 21.1518326 },
    { lat: 48.9718927, lng: 21.1519891 },
    { lat: 48.9718953, lng: 21.1522798 },
    { lat: 48.9719698, lng: 21.1526082 },
    { lat: 48.9720238, lng: 21.1532504 },
    { lat: 48.9719458, lng: 21.1542355 },
    { lat: 48.9719709, lng: 21.1545277 },
    { lat: 48.9721355, lng: 21.1550355 },
    { lat: 48.9722977, lng: 21.1552224 },
    { lat: 48.9723641, lng: 21.1554712 },
    { lat: 48.9725785, lng: 21.1558836 },
    { lat: 48.9727436, lng: 21.1571139 },
    { lat: 48.9731945, lng: 21.1564677 },
    { lat: 48.9734572, lng: 21.156085 },
    { lat: 48.9737144, lng: 21.1558581 },
    { lat: 48.9737352, lng: 21.1556607 },
    { lat: 48.974086, lng: 21.1553639 },
    { lat: 48.9741146, lng: 21.1554858 },
    { lat: 48.9743122, lng: 21.1554405 },
    { lat: 48.9744573, lng: 21.1552942 },
    { lat: 48.97445, lng: 21.1550991 },
    { lat: 48.9749772, lng: 21.1546215 },
    { lat: 48.9753073, lng: 21.1545598 },
    { lat: 48.975747, lng: 21.1541906 },
    { lat: 48.9762085, lng: 21.154048 },
    { lat: 48.9763872, lng: 21.1540758 },
    { lat: 48.9764545, lng: 21.153967 },
    { lat: 48.9764001, lng: 21.1538793 },
    { lat: 48.9764451, lng: 21.1538789 },
    { lat: 48.976452, lng: 21.1537773 },
    { lat: 48.9766465, lng: 21.1535586 },
    { lat: 48.9768038, lng: 21.1537298 },
    { lat: 48.9768306, lng: 21.1536913 },
    { lat: 48.9767579, lng: 21.1534183 },
    { lat: 48.9768288, lng: 21.1532759 },
    { lat: 48.9771127, lng: 21.1531792 },
    { lat: 48.9772808, lng: 21.1529087 },
    { lat: 48.9773643, lng: 21.1530563 },
    { lat: 48.9775646, lng: 21.1529496 },
    { lat: 48.9775658, lng: 21.1527044 },
    { lat: 48.9783715, lng: 21.1523128 },
    { lat: 48.9784008, lng: 21.1523816 },
    { lat: 48.9791571, lng: 21.1523812 },
    { lat: 48.9791569, lng: 21.1522333 },
    { lat: 48.9792102, lng: 21.1522125 },
    { lat: 48.9794031, lng: 21.1523303 },
    { lat: 48.9793772, lng: 21.152152 },
    { lat: 48.9795943, lng: 21.1520476 },
    { lat: 48.9796641, lng: 21.1521504 },
    { lat: 48.979753, lng: 21.1521496 },
    { lat: 48.9799067, lng: 21.1520291 },
    { lat: 48.9799539, lng: 21.1521715 },
    { lat: 48.9801121, lng: 21.1520507 },
    { lat: 48.9802455, lng: 21.1520341 },
    { lat: 48.9802572, lng: 21.152226 },
    { lat: 48.9805496, lng: 21.1523598 },
    { lat: 48.980544, lng: 21.1526443 },
    { lat: 48.9808625, lng: 21.1528271 },
    { lat: 48.9808799, lng: 21.1527362 },
    { lat: 48.9810325, lng: 21.1528627 },
    { lat: 48.9817327, lng: 21.1531197 },
    { lat: 48.9818203, lng: 21.1533608 },
    { lat: 48.9819798, lng: 21.1535266 },
    { lat: 48.9821102, lng: 21.1535643 },
    { lat: 48.9824519, lng: 21.1534394 },
    { lat: 48.9826075, lng: 21.1535191 },
    { lat: 48.9829501, lng: 21.1533235 },
    { lat: 48.9831023, lng: 21.1534375 },
    { lat: 48.9833676, lng: 21.1534299 },
    { lat: 48.9835005, lng: 21.1533299 },
    { lat: 48.9837054, lng: 21.1532537 },
    { lat: 48.9837273, lng: 21.1533144 },
    { lat: 48.9838818, lng: 21.1532111 },
    { lat: 48.9840887, lng: 21.1532026 },
    { lat: 48.9842301, lng: 21.1531968 },
    { lat: 48.9842677, lng: 21.1533191 },
    { lat: 48.9846252, lng: 21.1531303 },
    { lat: 48.9846541, lng: 21.1532239 },
    { lat: 48.9847044, lng: 21.1532112 },
    { lat: 48.9847191, lng: 21.1531098 },
    { lat: 48.9848197, lng: 21.1530864 },
    { lat: 48.9849894, lng: 21.1531858 },
    { lat: 48.9853059, lng: 21.1528728 },
    { lat: 48.9854591, lng: 21.1528032 },
    { lat: 48.9855441, lng: 21.1529342 },
    { lat: 48.9858852, lng: 21.152617 },
    { lat: 48.9859677, lng: 21.1529452 },
    { lat: 48.986176, lng: 21.1530963 },
    { lat: 48.986477, lng: 21.1536422 },
    { lat: 48.9868054, lng: 21.1545631 },
    { lat: 48.9869935, lng: 21.1548902 },
    { lat: 48.987104, lng: 21.15532 },
    { lat: 48.9875053, lng: 21.155983 },
    { lat: 48.9882881, lng: 21.1565085 },
    { lat: 48.9889751, lng: 21.1570908 },
    { lat: 48.9892357, lng: 21.1571826 },
    { lat: 48.9903897, lng: 21.1565896 },
    { lat: 48.9909636, lng: 21.1559908 },
    { lat: 48.9914748, lng: 21.1553161 },
    { lat: 48.9923557, lng: 21.1549526 },
    { lat: 48.9935249, lng: 21.1542075 },
    { lat: 48.9937425, lng: 21.154145 },
    { lat: 48.9950116, lng: 21.1540983 },
    { lat: 48.9951708, lng: 21.1542551 },
    { lat: 48.9958245, lng: 21.154293 },
    { lat: 48.996768, lng: 21.1549126 },
    { lat: 48.9979279, lng: 21.1548276 },
    { lat: 49.0001195, lng: 21.1540626 },
    { lat: 49.0014646, lng: 21.1534645 },
    { lat: 49.0028912, lng: 21.153171 },
    { lat: 49.0047367, lng: 21.1517086 },
    { lat: 49.0051249, lng: 21.1517014 },
    { lat: 49.0062586, lng: 21.1522143 },
    { lat: 49.0071156, lng: 21.1527658 },
    { lat: 49.0078926, lng: 21.1461305 },
    { lat: 49.0083301, lng: 21.1415405 },
    { lat: 49.0083279, lng: 21.1414055 },
    { lat: 49.0083606, lng: 21.1406952 },
    { lat: 49.0070011, lng: 21.1392391 },
    { lat: 49.0057357, lng: 21.1290564 },
    { lat: 49.0035161, lng: 21.1254771 },
    { lat: 49.0024421, lng: 21.1246536 },
    { lat: 49.0007576, lng: 21.1219073 },
    { lat: 49.0000479, lng: 21.1199453 },
    { lat: 48.9985443, lng: 21.1178246 },
    { lat: 48.9983196, lng: 21.1174114 },
    { lat: 48.9977324, lng: 21.1155122 },
    { lat: 48.9980242, lng: 21.1146807 },
    { lat: 48.9981637, lng: 21.113978 },
    { lat: 48.9992347, lng: 21.1104765 },
  ],
  Kokošovce: [
    { lat: 48.9742061, lng: 21.3730644 },
    { lat: 48.9731505, lng: 21.3729775 },
    { lat: 48.9724879, lng: 21.372423 },
    { lat: 48.9709225, lng: 21.3673046 },
    { lat: 48.9703565, lng: 21.3662067 },
    { lat: 48.9699509, lng: 21.3650975 },
    { lat: 48.9694367, lng: 21.3639302 },
    { lat: 48.96886, lng: 21.3599082 },
    { lat: 48.9686389, lng: 21.3586875 },
    { lat: 48.9682706, lng: 21.3575103 },
    { lat: 48.9681054, lng: 21.356685 },
    { lat: 48.9680146, lng: 21.3556803 },
    { lat: 48.9680716, lng: 21.3547638 },
    { lat: 48.9678781, lng: 21.35322 },
    { lat: 48.9672487, lng: 21.3525647 },
    { lat: 48.9671954, lng: 21.3522873 },
    { lat: 48.9672279, lng: 21.3520279 },
    { lat: 48.9669547, lng: 21.351651 },
    { lat: 48.9672491, lng: 21.3507013 },
    { lat: 48.9671571, lng: 21.3499592 },
    { lat: 48.9669043, lng: 21.3492962 },
    { lat: 48.9665497, lng: 21.3486138 },
    { lat: 48.9664585, lng: 21.3482379 },
    { lat: 48.9666985, lng: 21.3477157 },
    { lat: 48.9672131, lng: 21.3472808 },
    { lat: 48.9676942, lng: 21.3469986 },
    { lat: 48.9680174, lng: 21.346375 },
    { lat: 48.9681237, lng: 21.3451546 },
    { lat: 48.9684282, lng: 21.3434507 },
    { lat: 48.9682788, lng: 21.3420295 },
    { lat: 48.9675239, lng: 21.341022 },
    { lat: 48.967192, lng: 21.3396021 },
    { lat: 48.9673047, lng: 21.3369101 },
    { lat: 48.9674075, lng: 21.3354271 },
    { lat: 48.9672349, lng: 21.3353703 },
    { lat: 48.9671519, lng: 21.3351923 },
    { lat: 48.9659898, lng: 21.3345667 },
    { lat: 48.9658673, lng: 21.3343445 },
    { lat: 48.9655829, lng: 21.3332004 },
    { lat: 48.9651604, lng: 21.3319071 },
    { lat: 48.9651352, lng: 21.3303256 },
    { lat: 48.964968, lng: 21.3298864 },
    { lat: 48.9649962, lng: 21.3293673 },
    { lat: 48.9646752, lng: 21.3292872 },
    { lat: 48.9644117, lng: 21.3290143 },
    { lat: 48.9635943, lng: 21.3278055 },
    { lat: 48.9633526, lng: 21.3279226 },
    { lat: 48.96301, lng: 21.3272338 },
    { lat: 48.9626515, lng: 21.3268003 },
    { lat: 48.9630579, lng: 21.326063 },
    { lat: 48.9634348, lng: 21.3239 },
    { lat: 48.9650892, lng: 21.319285 },
    { lat: 48.9628484, lng: 21.3192281 },
    { lat: 48.9626675, lng: 21.3192038 },
    { lat: 48.9626162, lng: 21.3191064 },
    { lat: 48.9625799, lng: 21.3188024 },
    { lat: 48.9626289, lng: 21.3185178 },
    { lat: 48.962503, lng: 21.3176288 },
    { lat: 48.9622872, lng: 21.3173091 },
    { lat: 48.9617272, lng: 21.3169413 },
    { lat: 48.9617482, lng: 21.3165989 },
    { lat: 48.9616894, lng: 21.3163233 },
    { lat: 48.9617403, lng: 21.3160047 },
    { lat: 48.9614863, lng: 21.3153373 },
    { lat: 48.9613756, lng: 21.3154056 },
    { lat: 48.9612481, lng: 21.3150947 },
    { lat: 48.9612571, lng: 21.3150185 },
    { lat: 48.9609259, lng: 21.3147483 },
    { lat: 48.9577479, lng: 21.312693 },
    { lat: 48.9523855, lng: 21.3218727 },
    { lat: 48.9519792, lng: 21.3201931 },
    { lat: 48.9517084, lng: 21.3197211 },
    { lat: 48.9515527, lng: 21.3191484 },
    { lat: 48.9514499, lng: 21.3184993 },
    { lat: 48.9512591, lng: 21.3179931 },
    { lat: 48.9512733, lng: 21.3175739 },
    { lat: 48.9513553, lng: 21.3171185 },
    { lat: 48.9510725, lng: 21.317029 },
    { lat: 48.9510378, lng: 21.3167003 },
    { lat: 48.950259, lng: 21.3161796 },
    { lat: 48.9507624, lng: 21.3143705 },
    { lat: 48.9513021, lng: 21.3128653 },
    { lat: 48.9512012, lng: 21.3128033 },
    { lat: 48.9509092, lng: 21.3123034 },
    { lat: 48.9509257, lng: 21.3121759 },
    { lat: 48.9502002, lng: 21.3118212 },
    { lat: 48.9502189, lng: 21.3119879 },
    { lat: 48.9500101, lng: 21.3118979 },
    { lat: 48.949939, lng: 21.3121502 },
    { lat: 48.9496729, lng: 21.3125347 },
    { lat: 48.9491479, lng: 21.3135598 },
    { lat: 48.9489148, lng: 21.3145226 },
    { lat: 48.9487014, lng: 21.3147566 },
    { lat: 48.9485396, lng: 21.314615 },
    { lat: 48.947879, lng: 21.3150617 },
    { lat: 48.9477276, lng: 21.3160059 },
    { lat: 48.947834, lng: 21.3162187 },
    { lat: 48.9474426, lng: 21.3165127 },
    { lat: 48.9473743, lng: 21.3167505 },
    { lat: 48.9473439, lng: 21.3172348 },
    { lat: 48.9472425, lng: 21.3177177 },
    { lat: 48.9467363, lng: 21.3187668 },
    { lat: 48.9464808, lng: 21.3191368 },
    { lat: 48.9462976, lng: 21.3190264 },
    { lat: 48.9459846, lng: 21.3186111 },
    { lat: 48.94433, lng: 21.3192669 },
    { lat: 48.9436148, lng: 21.321374 },
    { lat: 48.9427389, lng: 21.3239615 },
    { lat: 48.9434907, lng: 21.3244562 },
    { lat: 48.9433166, lng: 21.3254684 },
    { lat: 48.9429451, lng: 21.3261386 },
    { lat: 48.9424355, lng: 21.3267775 },
    { lat: 48.9422339, lng: 21.3274022 },
    { lat: 48.9418376, lng: 21.3276252 },
    { lat: 48.9418978, lng: 21.3284958 },
    { lat: 48.9417876, lng: 21.3291729 },
    { lat: 48.9416202, lng: 21.3309352 },
    { lat: 48.9416411, lng: 21.3333135 },
    { lat: 48.9414378, lng: 21.3337027 },
    { lat: 48.9409098, lng: 21.3352602 },
    { lat: 48.9412103, lng: 21.3366515 },
    { lat: 48.9410856, lng: 21.3375174 },
    { lat: 48.9408666, lng: 21.3374539 },
    { lat: 48.9404926, lng: 21.3393912 },
    { lat: 48.9407414, lng: 21.3397699 },
    { lat: 48.9409131, lng: 21.3398274 },
    { lat: 48.9415352, lng: 21.3381345 },
    { lat: 48.9419924, lng: 21.3381874 },
    { lat: 48.9426235, lng: 21.3413255 },
    { lat: 48.9421975, lng: 21.3422062 },
    { lat: 48.9430805, lng: 21.3436579 },
    { lat: 48.9437152, lng: 21.343129 },
    { lat: 48.9438727, lng: 21.3439163 },
    { lat: 48.9438478, lng: 21.3445549 },
    { lat: 48.9446074, lng: 21.3445203 },
    { lat: 48.9446824, lng: 21.3443536 },
    { lat: 48.9448605, lng: 21.3454507 },
    { lat: 48.9453091, lng: 21.346005 },
    { lat: 48.9452296, lng: 21.3465077 },
    { lat: 48.9453493, lng: 21.3467393 },
    { lat: 48.945545, lng: 21.3474163 },
    { lat: 48.9458818, lng: 21.3479135 },
    { lat: 48.946024, lng: 21.3492834 },
    { lat: 48.9461702, lng: 21.3496559 },
    { lat: 48.9466693, lng: 21.3522262 },
    { lat: 48.9468407, lng: 21.3528795 },
    { lat: 48.9472278, lng: 21.3539357 },
    { lat: 48.9476022, lng: 21.354415 },
    { lat: 48.9482738, lng: 21.35415 },
    { lat: 48.9494407, lng: 21.3552345 },
    { lat: 48.9495112, lng: 21.3550775 },
    { lat: 48.9498689, lng: 21.3555966 },
    { lat: 48.9499998, lng: 21.3558736 },
    { lat: 48.9501136, lng: 21.3562911 },
    { lat: 48.9505265, lng: 21.3567019 },
    { lat: 48.9508946, lng: 21.3575397 },
    { lat: 48.9510703, lng: 21.3582988 },
    { lat: 48.951662, lng: 21.3595333 },
    { lat: 48.9516753, lng: 21.3596774 },
    { lat: 48.9515859, lng: 21.3600724 },
    { lat: 48.9512752, lng: 21.3604457 },
    { lat: 48.9512668, lng: 21.3606539 },
    { lat: 48.9517913, lng: 21.3621662 },
    { lat: 48.952395, lng: 21.3630935 },
    { lat: 48.9526538, lng: 21.3636596 },
    { lat: 48.9529175, lng: 21.3657882 },
    { lat: 48.9533418, lng: 21.366584 },
    { lat: 48.9540608, lng: 21.3690333 },
    { lat: 48.9541601, lng: 21.369571 },
    { lat: 48.9540025, lng: 21.3707397 },
    { lat: 48.9541446, lng: 21.3718868 },
    { lat: 48.9539457, lng: 21.3729938 },
    { lat: 48.9539411, lng: 21.3744313 },
    { lat: 48.9537119, lng: 21.3751215 },
    { lat: 48.9539258, lng: 21.3757349 },
    { lat: 48.9538639, lng: 21.3760699 },
    { lat: 48.9533892, lng: 21.3766477 },
    { lat: 48.9533159, lng: 21.3773935 },
    { lat: 48.9531164, lng: 21.3781404 },
    { lat: 48.9531305, lng: 21.3789575 },
    { lat: 48.9532129, lng: 21.3796515 },
    { lat: 48.9532712, lng: 21.3797549 },
    { lat: 48.9535065, lng: 21.3797957 },
    { lat: 48.9534525, lng: 21.3806701 },
    { lat: 48.9537939, lng: 21.3814459 },
    { lat: 48.9539157, lng: 21.3823254 },
    { lat: 48.9536972, lng: 21.3830754 },
    { lat: 48.9536969, lng: 21.3837095 },
    { lat: 48.9539965, lng: 21.3839121 },
    { lat: 48.9541831, lng: 21.3843218 },
    { lat: 48.9543454, lng: 21.3845241 },
    { lat: 48.95492, lng: 21.3848171 },
    { lat: 48.9551699, lng: 21.3853492 },
    { lat: 48.9554392, lng: 21.3853974 },
    { lat: 48.9559135, lng: 21.3851172 },
    { lat: 48.9560283, lng: 21.3852658 },
    { lat: 48.9561584, lng: 21.3851764 },
    { lat: 48.9563303, lng: 21.385208 },
    { lat: 48.956562, lng: 21.3850876 },
    { lat: 48.9569014, lng: 21.3851865 },
    { lat: 48.9572274, lng: 21.3854044 },
    { lat: 48.9574322, lng: 21.38537 },
    { lat: 48.9577518, lng: 21.3854846 },
    { lat: 48.9581232, lng: 21.3857876 },
    { lat: 48.9588272, lng: 21.3859711 },
    { lat: 48.9593625, lng: 21.3859564 },
    { lat: 48.9594218, lng: 21.3860225 },
    { lat: 48.9594805, lng: 21.3862956 },
    { lat: 48.9603459, lng: 21.3868682 },
    { lat: 48.9605018, lng: 21.3872285 },
    { lat: 48.9608522, lng: 21.387134 },
    { lat: 48.9612702, lng: 21.3874596 },
    { lat: 48.9615929, lng: 21.3871844 },
    { lat: 48.9617704, lng: 21.3872482 },
    { lat: 48.9620191, lng: 21.387518 },
    { lat: 48.9625477, lng: 21.3876975 },
    { lat: 48.9627602, lng: 21.3876518 },
    { lat: 48.9633, lng: 21.3867705 },
    { lat: 48.9653595, lng: 21.3847304 },
    { lat: 48.9659502, lng: 21.384271 },
    { lat: 48.9667312, lng: 21.3833667 },
    { lat: 48.9670863, lng: 21.3833507 },
    { lat: 48.9676085, lng: 21.3826736 },
    { lat: 48.9680122, lng: 21.3822853 },
    { lat: 48.9687179, lng: 21.3805825 },
    { lat: 48.9690961, lng: 21.3800577 },
    { lat: 48.9695517, lng: 21.3795606 },
    { lat: 48.9709246, lng: 21.379868 },
    { lat: 48.9711255, lng: 21.3794313 },
    { lat: 48.9713378, lng: 21.3791141 },
    { lat: 48.971687, lng: 21.3781082 },
    { lat: 48.9717644, lng: 21.3776498 },
    { lat: 48.97214, lng: 21.3769185 },
    { lat: 48.9718561, lng: 21.3760766 },
    { lat: 48.9718397, lng: 21.3753349 },
    { lat: 48.972009, lng: 21.3750081 },
    { lat: 48.9720515, lng: 21.3748111 },
    { lat: 48.972939, lng: 21.3743111 },
    { lat: 48.9730766, lng: 21.3740545 },
    { lat: 48.9730927, lng: 21.3739123 },
    { lat: 48.9734406, lng: 21.3733945 },
    { lat: 48.9741057, lng: 21.3732826 },
    { lat: 48.9742061, lng: 21.3730644 },
  ],
  Gregorovce: [
    { lat: 49.084383, lng: 21.203595 },
    { lat: 49.084138, lng: 21.203365 },
    { lat: 49.08378, lng: 21.203427 },
    { lat: 49.083781, lng: 21.203295 },
    { lat: 49.083486, lng: 21.203294 },
    { lat: 49.083465, lng: 21.203264 },
    { lat: 49.083411, lng: 21.203184 },
    { lat: 49.083371, lng: 21.203127 },
    { lat: 49.083365, lng: 21.203119 },
    { lat: 49.083287, lng: 21.202958 },
    { lat: 49.083031, lng: 21.201911 },
    { lat: 49.082949, lng: 21.201838 },
    { lat: 49.082992, lng: 21.201753 },
    { lat: 49.082978, lng: 21.201751 },
    { lat: 49.082903, lng: 21.201743 },
    { lat: 49.082806, lng: 21.201697 },
    { lat: 49.082743, lng: 21.201507 },
    { lat: 49.082687, lng: 21.201359 },
    { lat: 49.082572, lng: 21.201257 },
    { lat: 49.082457, lng: 21.201249 },
    { lat: 49.082362, lng: 21.201261 },
    { lat: 49.082171, lng: 21.20149 },
    { lat: 49.082049, lng: 21.201704 },
    { lat: 49.081905, lng: 21.201914 },
    { lat: 49.081735, lng: 21.202 },
    { lat: 49.081663, lng: 21.202121 },
    { lat: 49.081635, lng: 21.202365 },
    { lat: 49.081744, lng: 21.202557 },
    { lat: 49.081878, lng: 21.202778 },
    { lat: 49.081859, lng: 21.202934 },
    { lat: 49.08176, lng: 21.203157 },
    { lat: 49.081614, lng: 21.203278 },
    { lat: 49.08147, lng: 21.203372 },
    { lat: 49.081293, lng: 21.203537 },
    { lat: 49.081135, lng: 21.203665 },
    { lat: 49.08093, lng: 21.203795 },
    { lat: 49.080808, lng: 21.203993 },
    { lat: 49.080693, lng: 21.204254 },
    { lat: 49.080697, lng: 21.204516 },
    { lat: 49.080722, lng: 21.204813 },
    { lat: 49.08071, lng: 21.205256 },
    { lat: 49.080593, lng: 21.205396 },
    { lat: 49.080457, lng: 21.205327 },
    { lat: 49.08028, lng: 21.205161 },
    { lat: 49.080106, lng: 21.205031 },
    { lat: 49.079869, lng: 21.205006 },
    { lat: 49.079677, lng: 21.20522 },
    { lat: 49.079534, lng: 21.20543 },
    { lat: 49.079382, lng: 21.205556 },
    { lat: 49.079122, lng: 21.205643 },
    { lat: 49.078818, lng: 21.205839 },
    { lat: 49.07861, lng: 21.206082 },
    { lat: 49.078417, lng: 21.206343 },
    { lat: 49.078274, lng: 21.206495 },
    { lat: 49.078089, lng: 21.206677 },
    { lat: 49.078037, lng: 21.206571 },
    { lat: 49.078084, lng: 21.206523 },
    { lat: 49.077962, lng: 21.206344 },
    { lat: 49.077563, lng: 21.205875 },
    { lat: 49.077312, lng: 21.205713 },
    { lat: 49.077331, lng: 21.204794 },
    { lat: 49.077233, lng: 21.204802 },
    { lat: 49.077124, lng: 21.204793 },
    { lat: 49.076959, lng: 21.204726 },
    { lat: 49.07672, lng: 21.204641 },
    { lat: 49.076709, lng: 21.204639 },
    { lat: 49.076571, lng: 21.204604 },
    { lat: 49.07643, lng: 21.204569 },
    { lat: 49.076281, lng: 21.204525 },
    { lat: 49.076121, lng: 21.204497 },
    { lat: 49.076122, lng: 21.204453 },
    { lat: 49.075894, lng: 21.204436 },
    { lat: 49.075894, lng: 21.204449 },
    { lat: 49.075595, lng: 21.204294 },
    { lat: 49.075471, lng: 21.204231 },
    { lat: 49.075356, lng: 21.204173 },
    { lat: 49.075239, lng: 21.20401 },
    { lat: 49.075166, lng: 21.203967 },
    { lat: 49.075084, lng: 21.203914 },
    { lat: 49.075028, lng: 21.203846 },
    { lat: 49.074977, lng: 21.203784 },
    { lat: 49.074838, lng: 21.203727 },
    { lat: 49.074704, lng: 21.203672 },
    { lat: 49.074477, lng: 21.203622 },
    { lat: 49.074417, lng: 21.203604 },
    { lat: 49.074392, lng: 21.203606 },
    { lat: 49.074289, lng: 21.203619 },
    { lat: 49.074241, lng: 21.203626 },
    { lat: 49.074102, lng: 21.203705 },
    { lat: 49.074119, lng: 21.202686 },
    { lat: 49.074254, lng: 21.202524 },
    { lat: 49.074541, lng: 21.202312 },
    { lat: 49.074648, lng: 21.202396 },
    { lat: 49.074965, lng: 21.202474 },
    { lat: 49.07521, lng: 21.202484 },
    { lat: 49.075552, lng: 21.202472 },
    { lat: 49.075731, lng: 21.202393 },
    { lat: 49.075821, lng: 21.202281 },
    { lat: 49.075789, lng: 21.201906 },
    { lat: 49.075593, lng: 21.201741 },
    { lat: 49.075514, lng: 21.201569 },
    { lat: 49.075337, lng: 21.201519 },
    { lat: 49.07518, lng: 21.20154 },
    { lat: 49.074835, lng: 21.201806 },
    { lat: 49.074557, lng: 21.201863 },
    { lat: 49.074058, lng: 21.202164 },
    { lat: 49.075013, lng: 21.200946 },
    { lat: 49.075195, lng: 21.200555 },
    { lat: 49.075137, lng: 21.200353 },
    { lat: 49.07528, lng: 21.200301 },
    { lat: 49.075667, lng: 21.199916 },
    { lat: 49.076049, lng: 21.200086 },
    { lat: 49.076257, lng: 21.199918 },
    { lat: 49.075818, lng: 21.199555 },
    { lat: 49.075692, lng: 21.199402 },
    { lat: 49.075947, lng: 21.199078 },
    { lat: 49.075825, lng: 21.198595 },
    { lat: 49.075656, lng: 21.19708 },
    { lat: 49.075502, lng: 21.196256 },
    { lat: 49.076092, lng: 21.196688 },
    { lat: 49.07632, lng: 21.195966 },
    { lat: 49.076569, lng: 21.195408 },
    { lat: 49.076575, lng: 21.195109 },
    { lat: 49.076308, lng: 21.194907 },
    { lat: 49.075991, lng: 21.194597 },
    { lat: 49.07576, lng: 21.194292 },
    { lat: 49.075986, lng: 21.1944 },
    { lat: 49.076061, lng: 21.194359 },
    { lat: 49.076035, lng: 21.194244 },
    { lat: 49.075503, lng: 21.193674 },
    { lat: 49.075627, lng: 21.193374 },
    { lat: 49.075718, lng: 21.193071 },
    { lat: 49.075895, lng: 21.192559 },
    { lat: 49.076048, lng: 21.192086 },
    { lat: 49.076237, lng: 21.191528 },
    { lat: 49.076368, lng: 21.191182 },
    { lat: 49.076463, lng: 21.190959 },
    { lat: 49.076556, lng: 21.190756 },
    { lat: 49.076652, lng: 21.190612 },
    { lat: 49.077086, lng: 21.190063 },
    { lat: 49.076879, lng: 21.189557 },
    { lat: 49.076542, lng: 21.18979 },
    { lat: 49.076181, lng: 21.189151 },
    { lat: 49.076256, lng: 21.188758 },
    { lat: 49.076223, lng: 21.188225 },
    { lat: 49.076257, lng: 21.187944 },
    { lat: 49.076279, lng: 21.18754 },
    { lat: 49.076273, lng: 21.187268 },
    { lat: 49.076344, lng: 21.186969 },
    { lat: 49.076399, lng: 21.186734 },
    { lat: 49.076377, lng: 21.186661 },
    { lat: 49.076164, lng: 21.186497 },
    { lat: 49.076332, lng: 21.18584 },
    { lat: 49.076555, lng: 21.185106 },
    { lat: 49.07639, lng: 21.184948 },
    { lat: 49.076141, lng: 21.1848 },
    { lat: 49.075876, lng: 21.18455 },
    { lat: 49.07555, lng: 21.184204 },
    { lat: 49.075138, lng: 21.183684 },
    { lat: 49.074862, lng: 21.183215 },
    { lat: 49.074711, lng: 21.182981 },
    { lat: 49.074776, lng: 21.182873 },
    { lat: 49.074994, lng: 21.182471 },
    { lat: 49.075564, lng: 21.181618 },
    { lat: 49.075873, lng: 21.181129 },
    { lat: 49.076394, lng: 21.180302 },
    { lat: 49.076811, lng: 21.179502 },
    { lat: 49.076946, lng: 21.179304 },
    { lat: 49.077363, lng: 21.178695 },
    { lat: 49.077624, lng: 21.178142 },
    { lat: 49.077841, lng: 21.177953 },
    { lat: 49.078, lng: 21.177703 },
    { lat: 49.07822, lng: 21.177388 },
    { lat: 49.078463, lng: 21.176805 },
    { lat: 49.078821, lng: 21.17625 },
    { lat: 49.07902, lng: 21.175695 },
    { lat: 49.079384, lng: 21.17505 },
    { lat: 49.079436, lng: 21.174864 },
    { lat: 49.079677, lng: 21.174335 },
    { lat: 49.080047, lng: 21.173579 },
    { lat: 49.080265, lng: 21.173542 },
    { lat: 49.080332, lng: 21.173354 },
    { lat: 49.080334, lng: 21.173351 },
    { lat: 49.080354, lng: 21.173294 },
    { lat: 49.080555, lng: 21.17302 },
    { lat: 49.080906, lng: 21.172378 },
    { lat: 49.081472, lng: 21.17132 },
    { lat: 49.081701, lng: 21.171325 },
    { lat: 49.081672, lng: 21.17121 },
    { lat: 49.082107, lng: 21.171244 },
    { lat: 49.082282, lng: 21.171023 },
    { lat: 49.082532, lng: 21.170932 },
    { lat: 49.082745, lng: 21.170512 },
    { lat: 49.083216, lng: 21.169187 },
    { lat: 49.083234, lng: 21.169088 },
    { lat: 49.083546, lng: 21.168646 },
    { lat: 49.08437, lng: 21.166276 },
    { lat: 49.084225, lng: 21.166192 },
    { lat: 49.084076, lng: 21.166127 },
    { lat: 49.083948, lng: 21.166051 },
    { lat: 49.084541, lng: 21.165498 },
    { lat: 49.084767, lng: 21.165187 },
    { lat: 49.085004, lng: 21.164995 },
    { lat: 49.084249, lng: 21.165271 },
    { lat: 49.083062, lng: 21.165303 },
    { lat: 49.083057, lng: 21.165504 },
    { lat: 49.08301, lng: 21.165665 },
    { lat: 49.082895, lng: 21.16574 },
    { lat: 49.082852, lng: 21.165784 },
    { lat: 49.081954, lng: 21.165576 },
    { lat: 49.08178, lng: 21.165649 },
    { lat: 49.081319, lng: 21.166006 },
    { lat: 49.080608, lng: 21.166304 },
    { lat: 49.079901, lng: 21.167288 },
    { lat: 49.079633, lng: 21.16791 },
    { lat: 49.07916, lng: 21.168915 },
    { lat: 49.078919, lng: 21.169339 },
    { lat: 49.078845, lng: 21.169471 },
    { lat: 49.078766, lng: 21.169525 },
    { lat: 49.078663, lng: 21.169685 },
    { lat: 49.078623, lng: 21.169866 },
    { lat: 49.078303, lng: 21.170251 },
    { lat: 49.078149, lng: 21.170481 },
    { lat: 49.078002, lng: 21.170639 },
    { lat: 49.077927, lng: 21.170724 },
    { lat: 49.077863, lng: 21.17081 },
    { lat: 49.077645, lng: 21.170989 },
    { lat: 49.077544, lng: 21.171108 },
    { lat: 49.077445, lng: 21.171247 },
    { lat: 49.077397, lng: 21.17137 },
    { lat: 49.077374, lng: 21.17145 },
    { lat: 49.077358, lng: 21.171544 },
    { lat: 49.077319, lng: 21.171697 },
    { lat: 49.077301, lng: 21.171792 },
    { lat: 49.077277, lng: 21.171894 },
    { lat: 49.077209, lng: 21.172029 },
    { lat: 49.077154, lng: 21.172129 },
    { lat: 49.077105, lng: 21.1722 },
    { lat: 49.077034, lng: 21.172316 },
    { lat: 49.076967, lng: 21.172424 },
    { lat: 49.076923, lng: 21.172496 },
    { lat: 49.076894, lng: 21.172609 },
    { lat: 49.076873, lng: 21.172732 },
    { lat: 49.076848, lng: 21.172805 },
    { lat: 49.0768, lng: 21.172885 },
    { lat: 49.076716, lng: 21.172998 },
    { lat: 49.076659, lng: 21.173046 },
    { lat: 49.076578, lng: 21.173128 },
    { lat: 49.076458, lng: 21.173279 },
    { lat: 49.076413, lng: 21.173373 },
    { lat: 49.076235, lng: 21.173539 },
    { lat: 49.076172, lng: 21.173625 },
    { lat: 49.076002, lng: 21.173891 },
    { lat: 49.07593, lng: 21.17399 },
    { lat: 49.075392, lng: 21.174727 },
    { lat: 49.075365, lng: 21.174647 },
    { lat: 49.075048, lng: 21.174036 },
    { lat: 49.07389, lng: 21.171878 },
    { lat: 49.073836, lng: 21.171698 },
    { lat: 49.073813, lng: 21.171639 },
    { lat: 49.072845, lng: 21.16945 },
    { lat: 49.072822, lng: 21.169415 },
    { lat: 49.072762, lng: 21.169347 },
    { lat: 49.072751, lng: 21.169338 },
    { lat: 49.072715, lng: 21.169397 },
    { lat: 49.072602, lng: 21.169467 },
    { lat: 49.0725, lng: 21.169489 },
    { lat: 49.072394, lng: 21.169544 },
    { lat: 49.072321, lng: 21.169592 },
    { lat: 49.072276, lng: 21.169649 },
    { lat: 49.072249, lng: 21.169707 },
    { lat: 49.072243, lng: 21.16977 },
    { lat: 49.072202, lng: 21.169853 },
    { lat: 49.072156, lng: 21.169883 },
    { lat: 49.072109, lng: 21.169891 },
    { lat: 49.072041, lng: 21.16988 },
    { lat: 49.071788, lng: 21.170079 },
    { lat: 49.071615, lng: 21.17032 },
    { lat: 49.071552, lng: 21.170395 },
    { lat: 49.071436, lng: 21.170464 },
    { lat: 49.071397, lng: 21.170447 },
    { lat: 49.071361, lng: 21.170407 },
    { lat: 49.071306, lng: 21.170341 },
    { lat: 49.071156, lng: 21.17038 },
    { lat: 49.071089, lng: 21.170367 },
    { lat: 49.070929, lng: 21.170512 },
    { lat: 49.070886, lng: 21.170505 },
    { lat: 49.070791, lng: 21.170454 },
    { lat: 49.070439, lng: 21.17024 },
    { lat: 49.070304, lng: 21.170142 },
    { lat: 49.070264, lng: 21.170148 },
    { lat: 49.070218, lng: 21.170198 },
    { lat: 49.070105, lng: 21.170402 },
    { lat: 49.0700898, lng: 21.1704106 },
    { lat: 49.070068, lng: 21.170423 },
    { lat: 49.069998, lng: 21.170395 },
    { lat: 49.069975, lng: 21.170411 },
    { lat: 49.069966, lng: 21.17045 },
    { lat: 49.069964, lng: 21.1705 },
    { lat: 49.069941, lng: 21.170558 },
    { lat: 49.069907, lng: 21.170575 },
    { lat: 49.069855, lng: 21.170557 },
    { lat: 49.069715, lng: 21.170449 },
    { lat: 49.06958, lng: 21.170398 },
    { lat: 49.069461, lng: 21.17038 },
    { lat: 49.069415, lng: 21.170393 },
    { lat: 49.069395, lng: 21.170426 },
    { lat: 49.069381, lng: 21.170465 },
    { lat: 49.069365, lng: 21.170608 },
    { lat: 49.069214, lng: 21.17089 },
    { lat: 49.068924, lng: 21.171118 },
    { lat: 49.06889, lng: 21.17113 },
    { lat: 49.068425, lng: 21.171014 },
    { lat: 49.068213, lng: 21.170915 },
    { lat: 49.068171, lng: 21.170911 },
    { lat: 49.068128, lng: 21.170936 },
    { lat: 49.068104, lng: 21.170989 },
    { lat: 49.068093, lng: 21.17106 },
    { lat: 49.068118, lng: 21.171213 },
    { lat: 49.068151, lng: 21.171335 },
    { lat: 49.068179, lng: 21.171375 },
    { lat: 49.068221, lng: 21.171401 },
    { lat: 49.068317, lng: 21.171602 },
    { lat: 49.068333, lng: 21.171708 },
    { lat: 49.06833, lng: 21.171791 },
    { lat: 49.068307, lng: 21.171895 },
    { lat: 49.068291, lng: 21.17201 },
    { lat: 49.068277, lng: 21.172058 },
    { lat: 49.068262, lng: 21.172081 },
    { lat: 49.068226, lng: 21.172083 },
    { lat: 49.068172, lng: 21.172052 },
    { lat: 49.068106, lng: 21.171995 },
    { lat: 49.068069, lng: 21.171977 },
    { lat: 49.068032, lng: 21.171979 },
    { lat: 49.06786, lng: 21.172079 },
    { lat: 49.067625, lng: 21.172185 },
    { lat: 49.067583, lng: 21.172228 },
    { lat: 49.06757, lng: 21.172286 },
    { lat: 49.067559, lng: 21.172494 },
    { lat: 49.067586, lng: 21.172793 },
    { lat: 49.067585, lng: 21.172845 },
    { lat: 49.067564, lng: 21.172975 },
    { lat: 49.067479, lng: 21.173214 },
    { lat: 49.067439, lng: 21.173315 },
    { lat: 49.067422, lng: 21.173385 },
    { lat: 49.06741, lng: 21.173528 },
    { lat: 49.067384, lng: 21.17369 },
    { lat: 49.066906, lng: 21.175149 },
    { lat: 49.066844, lng: 21.175254 },
    { lat: 49.06679, lng: 21.175312 },
    { lat: 49.06636, lng: 21.175604 },
    { lat: 49.0644461, lng: 21.1925041 },
    { lat: 49.0642289, lng: 21.1929713 },
    { lat: 49.063964, lng: 21.1932797 },
    { lat: 49.0594614, lng: 21.1969487 },
    { lat: 49.05972, lng: 21.1979871 },
    { lat: 49.060232, lng: 21.1995419 },
    { lat: 49.0605885, lng: 21.2004689 },
    { lat: 49.0604259, lng: 21.2008157 },
    { lat: 49.0603042, lng: 21.2009196 },
    { lat: 49.0602312, lng: 21.201536 },
    { lat: 49.060816, lng: 21.2019592 },
    { lat: 49.0613556, lng: 21.2037496 },
    { lat: 49.0615035, lng: 21.2039869 },
    { lat: 49.0615396, lng: 21.2041691 },
    { lat: 49.0615218, lng: 21.2046024 },
    { lat: 49.0613223, lng: 21.2052849 },
    { lat: 49.0608976, lng: 21.2060641 },
    { lat: 49.0607178, lng: 21.2062905 },
    { lat: 49.0604372, lng: 21.2064553 },
    { lat: 49.0601778, lng: 21.2065081 },
    { lat: 49.0599279, lng: 21.2064719 },
    { lat: 49.0596185, lng: 21.2063277 },
    { lat: 49.0583241, lng: 21.2066822 },
    { lat: 49.0568785, lng: 21.2078171 },
    { lat: 49.0552351, lng: 21.2088138 },
    { lat: 49.0542898, lng: 21.2091334 },
    { lat: 49.053876, lng: 21.2091821 },
    { lat: 49.0537717, lng: 21.2091258 },
    { lat: 49.0536094, lng: 21.2091838 },
    { lat: 49.053452, lng: 21.2093307 },
    { lat: 49.0525717, lng: 21.2104467 },
    { lat: 49.0525388, lng: 21.2104031 },
    { lat: 49.052105, lng: 21.2109294 },
    { lat: 49.0516726, lng: 21.2118273 },
    { lat: 49.051415, lng: 21.2124766 },
    { lat: 49.0511498, lng: 21.2137638 },
    { lat: 49.0511505, lng: 21.2139998 },
    { lat: 49.0510493, lng: 21.2145442 },
    { lat: 49.0506925, lng: 21.2158129 },
    { lat: 49.0509248, lng: 21.2162224 },
    { lat: 49.0513867, lng: 21.2164562 },
    { lat: 49.051489, lng: 21.2165998 },
    { lat: 49.0515799, lng: 21.2169241 },
    { lat: 49.0516524, lng: 21.2175205 },
    { lat: 49.0511886, lng: 21.2181964 },
    { lat: 49.0512114, lng: 21.218392 },
    { lat: 49.0513292, lng: 21.2185935 },
    { lat: 49.0513648, lng: 21.2188768 },
    { lat: 49.0517668, lng: 21.2200079 },
    { lat: 49.0517505, lng: 21.2204259 },
    { lat: 49.0520631, lng: 21.2215472 },
    { lat: 49.0521871, lng: 21.2218443 },
    { lat: 49.0525048, lng: 21.22354 },
    { lat: 49.0526524, lng: 21.2243281 },
    { lat: 49.052589, lng: 21.2245353 },
    { lat: 49.0527072, lng: 21.2250478 },
    { lat: 49.0528195, lng: 21.2253307 },
    { lat: 49.0531702, lng: 21.2253163 },
    { lat: 49.053204, lng: 21.2252142 },
    { lat: 49.0531275, lng: 21.2249603 },
    { lat: 49.053105, lng: 21.2247874 },
    { lat: 49.0530857, lng: 21.2245543 },
    { lat: 49.0532195, lng: 21.224441 },
    { lat: 49.0534827, lng: 21.2243583 },
    { lat: 49.053851, lng: 21.22448 },
    { lat: 49.0539251, lng: 21.2244363 },
    { lat: 49.0540215, lng: 21.2233775 },
    { lat: 49.0543487, lng: 21.2230756 },
    { lat: 49.0544798, lng: 21.2226949 },
    { lat: 49.0546444, lng: 21.2224822 },
    { lat: 49.0548437, lng: 21.2223463 },
    { lat: 49.0549189, lng: 21.2221557 },
    { lat: 49.0549094, lng: 21.2218887 },
    { lat: 49.0547312, lng: 21.2216533 },
    { lat: 49.0547, lng: 21.2213405 },
    { lat: 49.0548641, lng: 21.2206757 },
    { lat: 49.0548152, lng: 21.2200819 },
    { lat: 49.0549361, lng: 21.2196983 },
    { lat: 49.0550876, lng: 21.2196099 },
    { lat: 49.0553239, lng: 21.2201218 },
    { lat: 49.0554173, lng: 21.220189 },
    { lat: 49.055601, lng: 21.2199853 },
    { lat: 49.0556087, lng: 21.2198297 },
    { lat: 49.0555384, lng: 21.2195248 },
    { lat: 49.055684, lng: 21.2195362 },
    { lat: 49.0579446, lng: 21.2176119 },
    { lat: 49.0582626, lng: 21.218467 },
    { lat: 49.0592261, lng: 21.2175324 },
    { lat: 49.0594515, lng: 21.217401 },
    { lat: 49.0597699, lng: 21.2169612 },
    { lat: 49.0600906, lng: 21.2175414 },
    { lat: 49.0600863, lng: 21.2200857 },
    { lat: 49.0600395, lng: 21.2203585 },
    { lat: 49.0601537, lng: 21.2211365 },
    { lat: 49.0598433, lng: 21.2228836 },
    { lat: 49.060145, lng: 21.2237641 },
    { lat: 49.0607554, lng: 21.2242844 },
    { lat: 49.0608602, lng: 21.2244721 },
    { lat: 49.0609378, lng: 21.2247604 },
    { lat: 49.0616914, lng: 21.224678 },
    { lat: 49.0619738, lng: 21.2247508 },
    { lat: 49.0620698, lng: 21.2248744 },
    { lat: 49.0627845, lng: 21.2252771 },
    { lat: 49.0629703, lng: 21.225641 },
    { lat: 49.0629921, lng: 21.2260571 },
    { lat: 49.0631797, lng: 21.2263038 },
    { lat: 49.0630227, lng: 21.2264683 },
    { lat: 49.06287, lng: 21.2265148 },
    { lat: 49.0631005, lng: 21.2266493 },
    { lat: 49.0629811, lng: 21.2267235 },
    { lat: 49.0634671, lng: 21.2271897 },
    { lat: 49.0640043, lng: 21.2278613 },
    { lat: 49.0644685, lng: 21.2264935 },
    { lat: 49.064628, lng: 21.2266343 },
    { lat: 49.0650376, lng: 21.2257573 },
    { lat: 49.065514, lng: 21.2250424 },
    { lat: 49.0657578, lng: 21.2252492 },
    { lat: 49.0659636, lng: 21.2255352 },
    { lat: 49.066661, lng: 21.2256004 },
    { lat: 49.066815, lng: 21.2257394 },
    { lat: 49.0671601, lng: 21.2263829 },
    { lat: 49.0672767, lng: 21.2264804 },
    { lat: 49.0674396, lng: 21.2265473 },
    { lat: 49.0678696, lng: 21.2265495 },
    { lat: 49.0686833, lng: 21.2263753 },
    { lat: 49.0691516, lng: 21.2265954 },
    { lat: 49.0697557, lng: 21.2271354 },
    { lat: 49.0700509, lng: 21.227219 },
    { lat: 49.0702633, lng: 21.2273542 },
    { lat: 49.0705189, lng: 21.2273979 },
    { lat: 49.0719371, lng: 21.2292344 },
    { lat: 49.0728309, lng: 21.2297475 },
    { lat: 49.0732229, lng: 21.2300576 },
    { lat: 49.0728325, lng: 21.2320972 },
    { lat: 49.0727732, lng: 21.2327651 },
    { lat: 49.0726244, lng: 21.2334447 },
    { lat: 49.072809, lng: 21.2338482 },
    { lat: 49.0728658, lng: 21.2345577 },
    { lat: 49.0730424, lng: 21.2352155 },
    { lat: 49.0737572, lng: 21.2359917 },
    { lat: 49.0749413, lng: 21.2369806 },
    { lat: 49.0745893, lng: 21.2381593 },
    { lat: 49.0748682, lng: 21.2384807 },
    { lat: 49.0756842, lng: 21.23736 },
    { lat: 49.076062, lng: 21.2370548 },
    { lat: 49.076237, lng: 21.2368219 },
    { lat: 49.0761278, lng: 21.2364518 },
    { lat: 49.0761537, lng: 21.236343 },
    { lat: 49.0769128, lng: 21.2369897 },
    { lat: 49.077016, lng: 21.2366812 },
    { lat: 49.0779559, lng: 21.2362522 },
    { lat: 49.0786619, lng: 21.2352807 },
    { lat: 49.0799887, lng: 21.2332859 },
    { lat: 49.080424, lng: 21.2327299 },
    { lat: 49.0834118, lng: 21.2304463 },
    { lat: 49.087123, lng: 21.225468 },
    { lat: 49.086379, lng: 21.224115 },
    { lat: 49.086377, lng: 21.22396 },
    { lat: 49.085918, lng: 21.223181 },
    { lat: 49.085916, lng: 21.223101 },
    { lat: 49.085485, lng: 21.221322 },
    { lat: 49.08532, lng: 21.221006 },
    { lat: 49.084942, lng: 21.219878 },
    { lat: 49.085639, lng: 21.219166 },
    { lat: 49.085811, lng: 21.218879 },
    { lat: 49.086221, lng: 21.218518 },
    { lat: 49.086259, lng: 21.218509 },
    { lat: 49.086282, lng: 21.218603 },
    { lat: 49.086471, lng: 21.218393 },
    { lat: 49.086781, lng: 21.218146 },
    { lat: 49.087413, lng: 21.21764 },
    { lat: 49.088137, lng: 21.21404 },
    { lat: 49.087472, lng: 21.213446 },
    { lat: 49.087336, lng: 21.213271 },
    { lat: 49.086974, lng: 21.212733 },
    { lat: 49.086751, lng: 21.212464 },
    { lat: 49.086668, lng: 21.212275 },
    { lat: 49.086601, lng: 21.212167 },
    { lat: 49.085986, lng: 21.211632 },
    { lat: 49.085185, lng: 21.210806 },
    { lat: 49.085111, lng: 21.21083 },
    { lat: 49.085111, lng: 21.210704 },
    { lat: 49.085691, lng: 21.2096 },
    { lat: 49.085985, lng: 21.209051 },
    { lat: 49.086014, lng: 21.208989 },
    { lat: 49.086064, lng: 21.208879 },
    { lat: 49.086101, lng: 21.208799 },
    { lat: 49.087492, lng: 21.20633 },
    { lat: 49.087336, lng: 21.206141 },
    { lat: 49.087254, lng: 21.205829 },
    { lat: 49.087178, lng: 21.205544 },
    { lat: 49.087251, lng: 21.205371 },
    { lat: 49.087221, lng: 21.205197 },
    { lat: 49.087075, lng: 21.204961 },
    { lat: 49.087056, lng: 21.204721 },
    { lat: 49.08703, lng: 21.204571 },
    { lat: 49.087311, lng: 21.204303 },
    { lat: 49.087348, lng: 21.204141 },
    { lat: 49.087618, lng: 21.203895 },
    { lat: 49.087592, lng: 21.203804 },
    { lat: 49.087524, lng: 21.203765 },
    { lat: 49.087387, lng: 21.203644 },
    { lat: 49.0871, lng: 21.203594 },
    { lat: 49.086611, lng: 21.203538 },
    { lat: 49.086455, lng: 21.20338 },
    { lat: 49.086294, lng: 21.203515 },
    { lat: 49.085881, lng: 21.203808 },
    { lat: 49.085887, lng: 21.203876 },
    { lat: 49.085731, lng: 21.204012 },
    { lat: 49.085528, lng: 21.203995 },
    { lat: 49.085435, lng: 21.20377 },
    { lat: 49.085111, lng: 21.203572 },
    { lat: 49.084994, lng: 21.20365 },
    { lat: 49.084829, lng: 21.204067 },
    { lat: 49.084435, lng: 21.203734 },
    { lat: 49.084383, lng: 21.203595 },
  ],
  RuskáNováVes: [
    { lat: 48.9876902, lng: 21.2980419 },
    { lat: 48.9876294, lng: 21.2978892 },
    { lat: 48.9854977, lng: 21.2991744 },
    { lat: 48.9851824, lng: 21.2970522 },
    { lat: 48.9850014, lng: 21.2962807 },
    { lat: 48.9845891, lng: 21.2950421 },
    { lat: 48.9842252, lng: 21.2949945 },
    { lat: 48.9843034, lng: 21.2936423 },
    { lat: 48.9842115, lng: 21.2930619 },
    { lat: 48.984063, lng: 21.2926521 },
    { lat: 48.981361, lng: 21.295275 },
    { lat: 48.9812443, lng: 21.2948007 },
    { lat: 48.9809279, lng: 21.2940208 },
    { lat: 48.9804194, lng: 21.292544 },
    { lat: 48.9803156, lng: 21.2925323 },
    { lat: 48.9802039, lng: 21.2931165 },
    { lat: 48.9797108, lng: 21.2941065 },
    { lat: 48.9795441, lng: 21.2945829 },
    { lat: 48.9794796, lng: 21.2950517 },
    { lat: 48.9787722, lng: 21.294027 },
    { lat: 48.9781536, lng: 21.2930083 },
    { lat: 48.9763644, lng: 21.295295 },
    { lat: 48.9759804, lng: 21.2953248 },
    { lat: 48.9758921, lng: 21.2955678 },
    { lat: 48.9758962, lng: 21.2958891 },
    { lat: 48.9758716, lng: 21.2959629 },
    { lat: 48.9757572, lng: 21.2959394 },
    { lat: 48.9757493, lng: 21.2961602 },
    { lat: 48.9756552, lng: 21.2961772 },
    { lat: 48.9755728, lng: 21.2966136 },
    { lat: 48.9754114, lng: 21.296781 },
    { lat: 48.9751457, lng: 21.2968907 },
    { lat: 48.9749307, lng: 21.2975136 },
    { lat: 48.9749277, lng: 21.2978588 },
    { lat: 48.9749603, lng: 21.2978984 },
    { lat: 48.9747598, lng: 21.2984856 },
    { lat: 48.9747627, lng: 21.2989156 },
    { lat: 48.9745185, lng: 21.2993518 },
    { lat: 48.9743704, lng: 21.2992575 },
    { lat: 48.9744053, lng: 21.2997028 },
    { lat: 48.9744604, lng: 21.2998842 },
    { lat: 48.9744396, lng: 21.2999838 },
    { lat: 48.9744867, lng: 21.3001234 },
    { lat: 48.9745429, lng: 21.3001556 },
    { lat: 48.9744286, lng: 21.3004384 },
    { lat: 48.9746388, lng: 21.3009448 },
    { lat: 48.9744626, lng: 21.3011843 },
    { lat: 48.9744898, lng: 21.301638 },
    { lat: 48.9743306, lng: 21.301988 },
    { lat: 48.973957, lng: 21.3023186 },
    { lat: 48.9736574, lng: 21.3027569 },
    { lat: 48.9728046, lng: 21.3030945 },
    { lat: 48.9725384, lng: 21.3032579 },
    { lat: 48.9728367, lng: 21.3036708 },
    { lat: 48.9725629, lng: 21.3043998 },
    { lat: 48.9726733, lng: 21.3050393 },
    { lat: 48.9725914, lng: 21.3055258 },
    { lat: 48.9724122, lng: 21.3061078 },
    { lat: 48.9719007, lng: 21.3061303 },
    { lat: 48.9717587, lng: 21.3054923 },
    { lat: 48.9714411, lng: 21.3058183 },
    { lat: 48.9711706, lng: 21.3059103 },
    { lat: 48.9712752, lng: 21.3068417 },
    { lat: 48.9713076, lng: 21.3076471 },
    { lat: 48.9715227, lng: 21.3088055 },
    { lat: 48.9717711, lng: 21.3095023 },
    { lat: 48.9719069, lng: 21.3100874 },
    { lat: 48.9722536, lng: 21.3098629 },
    { lat: 48.9725824, lng: 21.3105746 },
    { lat: 48.9726837, lng: 21.3109164 },
    { lat: 48.9727418, lng: 21.3115739 },
    { lat: 48.9727236, lng: 21.3125311 },
    { lat: 48.9726275, lng: 21.3135934 },
    { lat: 48.9727239, lng: 21.314139 },
    { lat: 48.9730415, lng: 21.3146348 },
    { lat: 48.9734426, lng: 21.3155406 },
    { lat: 48.973654, lng: 21.3166147 },
    { lat: 48.9737994, lng: 21.3177053 },
    { lat: 48.9741466, lng: 21.3184723 },
    { lat: 48.9742953, lng: 21.3189215 },
    { lat: 48.9746209, lng: 21.320242 },
    { lat: 48.9743404, lng: 21.3210478 },
    { lat: 48.9744641, lng: 21.3211248 },
    { lat: 48.9741941, lng: 21.3223571 },
    { lat: 48.9743675, lng: 21.3225783 },
    { lat: 48.9743511, lng: 21.3228577 },
    { lat: 48.9742394, lng: 21.3233361 },
    { lat: 48.9738667, lng: 21.3238865 },
    { lat: 48.9736868, lng: 21.3240752 },
    { lat: 48.9730882, lng: 21.3244521 },
    { lat: 48.9723326, lng: 21.3253339 },
    { lat: 48.9721415, lng: 21.3252963 },
    { lat: 48.9719503, lng: 21.3251488 },
    { lat: 48.9715653, lng: 21.3255592 },
    { lat: 48.9712948, lng: 21.32565 },
    { lat: 48.970569, lng: 21.325502 },
    { lat: 48.9703617, lng: 21.3255716 },
    { lat: 48.9703229, lng: 21.3253619 },
    { lat: 48.9700705, lng: 21.3254314 },
    { lat: 48.9700234, lng: 21.3255099 },
    { lat: 48.9696713, lng: 21.3250641 },
    { lat: 48.968282, lng: 21.3264236 },
    { lat: 48.9675394, lng: 21.327695 },
    { lat: 48.9676565, lng: 21.3291194 },
    { lat: 48.9676404, lng: 21.3334014 },
    { lat: 48.9674075, lng: 21.3354271 },
    { lat: 48.9673047, lng: 21.3369101 },
    { lat: 48.967192, lng: 21.3396021 },
    { lat: 48.9675239, lng: 21.341022 },
    { lat: 48.9682788, lng: 21.3420295 },
    { lat: 48.9684282, lng: 21.3434507 },
    { lat: 48.9681237, lng: 21.3451546 },
    { lat: 48.9680174, lng: 21.346375 },
    { lat: 48.9676942, lng: 21.3469986 },
    { lat: 48.9672131, lng: 21.3472808 },
    { lat: 48.9666985, lng: 21.3477157 },
    { lat: 48.9664585, lng: 21.3482379 },
    { lat: 48.9665497, lng: 21.3486138 },
    { lat: 48.9669043, lng: 21.3492962 },
    { lat: 48.9671571, lng: 21.3499592 },
    { lat: 48.9672491, lng: 21.3507013 },
    { lat: 48.9669547, lng: 21.351651 },
    { lat: 48.9672279, lng: 21.3520279 },
    { lat: 48.9671954, lng: 21.3522873 },
    { lat: 48.9672487, lng: 21.3525647 },
    { lat: 48.9678781, lng: 21.35322 },
    { lat: 48.9680716, lng: 21.3547638 },
    { lat: 48.9680146, lng: 21.3556803 },
    { lat: 48.9681054, lng: 21.356685 },
    { lat: 48.9682706, lng: 21.3575103 },
    { lat: 48.9686389, lng: 21.3586875 },
    { lat: 48.96886, lng: 21.3599082 },
    { lat: 48.9694367, lng: 21.3639302 },
    { lat: 48.9699509, lng: 21.3650975 },
    { lat: 48.9703565, lng: 21.3662067 },
    { lat: 48.9709225, lng: 21.3673046 },
    { lat: 48.9724879, lng: 21.372423 },
    { lat: 48.9731505, lng: 21.3729775 },
    { lat: 48.9742061, lng: 21.3730644 },
    { lat: 48.974883, lng: 21.37361 },
    { lat: 48.9755351, lng: 21.3742749 },
    { lat: 48.9761678, lng: 21.3744765 },
    { lat: 48.977127, lng: 21.3741904 },
    { lat: 48.9776711, lng: 21.3746331 },
    { lat: 48.9779162, lng: 21.3746979 },
    { lat: 48.9784485, lng: 21.3758797 },
    { lat: 48.9787432, lng: 21.3770451 },
    { lat: 48.9791466, lng: 21.3779023 },
    { lat: 48.9796128, lng: 21.3787444 },
    { lat: 48.9800065, lng: 21.379657 },
    { lat: 48.9800626, lng: 21.3805533 },
    { lat: 48.9802741, lng: 21.380522 },
    { lat: 48.9805598, lng: 21.3844955 },
    { lat: 48.9804311, lng: 21.3886628 },
    { lat: 48.9806262, lng: 21.3892871 },
    { lat: 48.9807565, lng: 21.3912638 },
    { lat: 48.9809638, lng: 21.39329 },
    { lat: 48.9806907, lng: 21.3951628 },
    { lat: 48.9803536, lng: 21.3961318 },
    { lat: 48.9800734, lng: 21.3973502 },
    { lat: 48.9795571, lng: 21.3985363 },
    { lat: 48.9794324, lng: 21.3989685 },
    { lat: 48.9784073, lng: 21.399878 },
    { lat: 48.9785529, lng: 21.3999832 },
    { lat: 48.9791165, lng: 21.4000801 },
    { lat: 48.9800892, lng: 21.4012837 },
    { lat: 48.980914, lng: 21.4019307 },
    { lat: 48.9819873, lng: 21.4022302 },
    { lat: 48.9841942, lng: 21.4035488 },
    { lat: 48.985023, lng: 21.4039084 },
    { lat: 48.9856168, lng: 21.404323 },
    { lat: 48.9859043, lng: 21.4048365 },
    { lat: 48.9878928, lng: 21.4068578 },
    { lat: 48.9879589, lng: 21.406948 },
    { lat: 48.9884305, lng: 21.4078711 },
    { lat: 48.9891202, lng: 21.4086588 },
    { lat: 48.9894236, lng: 21.4090767 },
    { lat: 48.9889955, lng: 21.408132 },
    { lat: 48.9886387, lng: 21.4070248 },
    { lat: 48.9881894, lng: 21.4059777 },
    { lat: 48.9874365, lng: 21.4035271 },
    { lat: 48.9872251, lng: 21.4021601 },
    { lat: 48.9867965, lng: 21.4008601 },
    { lat: 48.9862462, lng: 21.3983421 },
    { lat: 48.9861464, lng: 21.397132 },
    { lat: 48.985914, lng: 21.3960802 },
    { lat: 48.9850822, lng: 21.3944385 },
    { lat: 48.9839951, lng: 21.392716 },
    { lat: 48.9835355, lng: 21.3924372 },
    { lat: 48.9833499, lng: 21.3921321 },
    { lat: 48.9828279, lng: 21.3917178 },
    { lat: 48.9827442, lng: 21.3915827 },
    { lat: 48.9827084, lng: 21.3913598 },
    { lat: 48.9827554, lng: 21.3912054 },
    { lat: 48.9827595, lng: 21.3909204 },
    { lat: 48.9830407, lng: 21.3905734 },
    { lat: 48.9832388, lng: 21.3900494 },
    { lat: 48.983698, lng: 21.3891765 },
    { lat: 48.9837398, lng: 21.3887638 },
    { lat: 48.9833846, lng: 21.3862264 },
    { lat: 48.9833724, lng: 21.3857751 },
    { lat: 48.9834278, lng: 21.3854804 },
    { lat: 48.9836336, lng: 21.3853693 },
    { lat: 48.9839632, lng: 21.384905 },
    { lat: 48.9842048, lng: 21.3844024 },
    { lat: 48.9841954, lng: 21.3841239 },
    { lat: 48.9843445, lng: 21.3836477 },
    { lat: 48.9845154, lng: 21.3834174 },
    { lat: 48.9845685, lng: 21.3828539 },
    { lat: 48.9847411, lng: 21.382606 },
    { lat: 48.9848153, lng: 21.3822219 },
    { lat: 48.9849655, lng: 21.3818562 },
    { lat: 48.9850866, lng: 21.3812024 },
    { lat: 48.9854257, lng: 21.3804562 },
    { lat: 48.9855505, lng: 21.3802993 },
    { lat: 48.9856106, lng: 21.380014 },
    { lat: 48.9859009, lng: 21.3799024 },
    { lat: 48.9869309, lng: 21.3790408 },
    { lat: 48.9868822, lng: 21.3786927 },
    { lat: 48.9871378, lng: 21.3782417 },
    { lat: 48.986953, lng: 21.3774393 },
    { lat: 48.9866636, lng: 21.3769357 },
    { lat: 48.9866676, lng: 21.3768405 },
    { lat: 48.9868261, lng: 21.3765724 },
    { lat: 48.9867531, lng: 21.3761231 },
    { lat: 48.9868034, lng: 21.3758094 },
    { lat: 48.9867791, lng: 21.3756728 },
    { lat: 48.9871674, lng: 21.3751485 },
    { lat: 48.9873207, lng: 21.3746261 },
    { lat: 48.9875835, lng: 21.3742254 },
    { lat: 48.9876384, lng: 21.3735796 },
    { lat: 48.9877577, lng: 21.3732404 },
    { lat: 48.9879809, lng: 21.3728002 },
    { lat: 48.9883869, lng: 21.3723381 },
    { lat: 48.9884399, lng: 21.3719959 },
    { lat: 48.9886183, lng: 21.3716035 },
    { lat: 48.9884965, lng: 21.3705678 },
    { lat: 48.9886338, lng: 21.3694778 },
    { lat: 48.9888935, lng: 21.3685618 },
    { lat: 48.9888241, lng: 21.3684576 },
    { lat: 48.9890662, lng: 21.367067 },
    { lat: 48.9891121, lng: 21.3663756 },
    { lat: 48.9894287, lng: 21.3657888 },
    { lat: 48.9893823, lng: 21.3654025 },
    { lat: 48.9895178, lng: 21.3648536 },
    { lat: 48.9897888, lng: 21.3644294 },
    { lat: 48.989953, lng: 21.3643927 },
    { lat: 48.9900886, lng: 21.3636507 },
    { lat: 48.9903264, lng: 21.3633989 },
    { lat: 48.9903968, lng: 21.3630096 },
    { lat: 48.9902818, lng: 21.362775 },
    { lat: 48.9904085, lng: 21.3625323 },
    { lat: 48.9904976, lng: 21.3620478 },
    { lat: 48.9905868, lng: 21.3620198 },
    { lat: 48.9907621, lng: 21.3606925 },
    { lat: 48.9910448, lng: 21.3605103 },
    { lat: 48.9909374, lng: 21.3603099 },
    { lat: 48.9899812, lng: 21.3595578 },
    { lat: 48.9891233, lng: 21.3577363 },
    { lat: 48.9887645, lng: 21.3573271 },
    { lat: 48.9880895, lng: 21.3554425 },
    { lat: 48.9878103, lng: 21.3553235 },
    { lat: 48.9872841, lng: 21.354286 },
    { lat: 48.9871546, lng: 21.3535333 },
    { lat: 48.9875972, lng: 21.3527557 },
    { lat: 48.9879364, lng: 21.3522784 },
    { lat: 48.9881908, lng: 21.3520233 },
    { lat: 48.9888408, lng: 21.3499036 },
    { lat: 48.9893934, lng: 21.3487798 },
    { lat: 48.9897096, lng: 21.347874 },
    { lat: 48.9899814, lng: 21.3468395 },
    { lat: 48.9910449, lng: 21.3420833 },
    { lat: 48.9911669, lng: 21.3398476 },
    { lat: 48.991015, lng: 21.3390606 },
    { lat: 48.9910072, lng: 21.338691 },
    { lat: 48.9911106, lng: 21.3375247 },
    { lat: 48.9914582, lng: 21.3354593 },
    { lat: 48.9907961, lng: 21.3352964 },
    { lat: 48.9880597, lng: 21.3351147 },
    { lat: 48.9867722, lng: 21.3355906 },
    { lat: 48.9866912, lng: 21.3352168 },
    { lat: 48.9866506, lng: 21.3351773 },
    { lat: 48.9865676, lng: 21.3352941 },
    { lat: 48.9865036, lng: 21.3348507 },
    { lat: 48.9863964, lng: 21.3345798 },
    { lat: 48.9863972, lng: 21.3340803 },
    { lat: 48.9865235, lng: 21.3333794 },
    { lat: 48.9865332, lng: 21.3329892 },
    { lat: 48.9863984, lng: 21.3328883 },
    { lat: 48.9864451, lng: 21.3325285 },
    { lat: 48.986299, lng: 21.332424 },
    { lat: 48.9863455, lng: 21.332064 },
    { lat: 48.9860645, lng: 21.3311405 },
    { lat: 48.9859307, lng: 21.3308073 },
    { lat: 48.9858115, lng: 21.3307733 },
    { lat: 48.9857263, lng: 21.3305979 },
    { lat: 48.9858, lng: 21.3305387 },
    { lat: 48.9858156, lng: 21.329787 },
    { lat: 48.9858696, lng: 21.329334 },
    { lat: 48.9856308, lng: 21.3285445 },
    { lat: 48.9857454, lng: 21.328538 },
    { lat: 48.9857773, lng: 21.3284766 },
    { lat: 48.9856886, lng: 21.3284061 },
    { lat: 48.9856465, lng: 21.3282428 },
    { lat: 48.9856806, lng: 21.3278041 },
    { lat: 48.9856451, lng: 21.3276357 },
    { lat: 48.9857116, lng: 21.3275264 },
    { lat: 48.9856397, lng: 21.3273079 },
    { lat: 48.9857366, lng: 21.3269393 },
    { lat: 48.985686, lng: 21.3266766 },
    { lat: 48.9856954, lng: 21.3263517 },
    { lat: 48.9854665, lng: 21.3260029 },
    { lat: 48.9855165, lng: 21.3254556 },
    { lat: 48.9854326, lng: 21.3248767 },
    { lat: 48.9852105, lng: 21.3245267 },
    { lat: 48.9852201, lng: 21.3239833 },
    { lat: 48.9852933, lng: 21.3237987 },
    { lat: 48.9851041, lng: 21.3233529 },
    { lat: 48.9850168, lng: 21.3229585 },
    { lat: 48.9851577, lng: 21.3227061 },
    { lat: 48.9849571, lng: 21.3217209 },
    { lat: 48.9849811, lng: 21.3212922 },
    { lat: 48.984839, lng: 21.3209793 },
    { lat: 48.984983, lng: 21.3203759 },
    { lat: 48.9852193, lng: 21.3202291 },
    { lat: 48.9851247, lng: 21.3197839 },
    { lat: 48.985287, lng: 21.3197138 },
    { lat: 48.985307, lng: 21.319486 },
    { lat: 48.9852694, lng: 21.3193905 },
    { lat: 48.9856734, lng: 21.3190255 },
    { lat: 48.9857136, lng: 21.3183406 },
    { lat: 48.9865279, lng: 21.3160198 },
    { lat: 48.9873752, lng: 21.3130418 },
    { lat: 48.9874151, lng: 21.3126069 },
    { lat: 48.9873672, lng: 21.3118124 },
    { lat: 48.9876225, lng: 21.311691 },
    { lat: 48.9876118, lng: 21.3111919 },
    { lat: 48.9877522, lng: 21.31096 },
    { lat: 48.9877717, lng: 21.3108257 },
    { lat: 48.987724, lng: 21.3101361 },
    { lat: 48.9876534, lng: 21.310002 },
    { lat: 48.9875383, lng: 21.3090577 },
    { lat: 48.9876189, lng: 21.3088625 },
    { lat: 48.9876257, lng: 21.3086743 },
    { lat: 48.9875086, lng: 21.3086008 },
    { lat: 48.9875071, lng: 21.308511 },
    { lat: 48.9876023, lng: 21.3083463 },
    { lat: 48.9876324, lng: 21.3081167 },
    { lat: 48.9875982, lng: 21.3078837 },
    { lat: 48.9878471, lng: 21.307326 },
    { lat: 48.9878577, lng: 21.3071945 },
    { lat: 48.9876263, lng: 21.306842 },
    { lat: 48.9876397, lng: 21.3066827 },
    { lat: 48.9874483, lng: 21.3063518 },
    { lat: 48.9874581, lng: 21.3062637 },
    { lat: 48.9875788, lng: 21.3061986 },
    { lat: 48.9876653, lng: 21.3060432 },
    { lat: 48.9874788, lng: 21.305536 },
    { lat: 48.9876456, lng: 21.3054688 },
    { lat: 48.9877893, lng: 21.305233 },
    { lat: 48.9877419, lng: 21.3049378 },
    { lat: 48.987291, lng: 21.3046097 },
    { lat: 48.9870694, lng: 21.3041272 },
    { lat: 48.9872357, lng: 21.3036387 },
    { lat: 48.9869868, lng: 21.3030058 },
    { lat: 48.9868216, lng: 21.3021238 },
    { lat: 48.9870139, lng: 21.3020405 },
    { lat: 48.9866997, lng: 21.3011435 },
    { lat: 48.9874727, lng: 21.2995841 },
    { lat: 48.9877273, lng: 21.2988522 },
    { lat: 48.987331, lng: 21.2984364 },
    { lat: 48.9876902, lng: 21.2980419 },
  ],
  Trnkov: [
    { lat: 49.0268893, lng: 21.3530451 },
    { lat: 49.0269151, lng: 21.3535331 },
    { lat: 49.027129, lng: 21.3539135 },
    { lat: 49.0272907, lng: 21.3543534 },
    { lat: 49.027254, lng: 21.3547123 },
    { lat: 49.0272639, lng: 21.355494 },
    { lat: 49.0271425, lng: 21.356284 },
    { lat: 49.0271395, lng: 21.3566026 },
    { lat: 49.0266047, lng: 21.3567421 },
    { lat: 49.0264541, lng: 21.356973 },
    { lat: 49.0265521, lng: 21.3572412 },
    { lat: 49.0256943, lng: 21.3576041 },
    { lat: 49.0255354, lng: 21.3580453 },
    { lat: 49.0258968, lng: 21.3605435 },
    { lat: 49.0257227, lng: 21.3624791 },
    { lat: 49.0265129, lng: 21.3659663 },
    { lat: 49.0266344, lng: 21.3659669 },
    { lat: 49.0264182, lng: 21.3663816 },
    { lat: 49.0268637, lng: 21.3669063 },
    { lat: 49.0270503, lng: 21.3673166 },
    { lat: 49.0272071, lng: 21.3679347 },
    { lat: 49.0272619, lng: 21.3686817 },
    { lat: 49.0275116, lng: 21.3700385 },
    { lat: 49.0278878, lng: 21.3706617 },
    { lat: 49.0279786, lng: 21.3707264 },
    { lat: 49.0282236, lng: 21.3718129 },
    { lat: 49.0286124, lng: 21.3725122 },
    { lat: 49.0299083, lng: 21.3718961 },
    { lat: 49.0324496, lng: 21.370555 },
    { lat: 49.032718, lng: 21.3705398 },
    { lat: 49.0329056, lng: 21.3706416 },
    { lat: 49.0329589, lng: 21.371032 },
    { lat: 49.0331531, lng: 21.3710933 },
    { lat: 49.0337023, lng: 21.3710939 },
    { lat: 49.0340829, lng: 21.3716336 },
    { lat: 49.0342031, lng: 21.3715952 },
    { lat: 49.0343657, lng: 21.3713449 },
    { lat: 49.0344516, lng: 21.3710926 },
    { lat: 49.0347585, lng: 21.3715036 },
    { lat: 49.0351483, lng: 21.3715919 },
    { lat: 49.0351032, lng: 21.371779 },
    { lat: 49.034808, lng: 21.3717312 },
    { lat: 49.0348113, lng: 21.372025 },
    { lat: 49.0348812, lng: 21.3722587 },
    { lat: 49.0349911, lng: 21.3723651 },
    { lat: 49.0353118, lng: 21.3723664 },
    { lat: 49.035532, lng: 21.3726199 },
    { lat: 49.0358516, lng: 21.3724645 },
    { lat: 49.0359216, lng: 21.372511 },
    { lat: 49.0359596, lng: 21.3726543 },
    { lat: 49.0360751, lng: 21.3726077 },
    { lat: 49.0362766, lng: 21.3685576 },
    { lat: 49.0366539, lng: 21.3685671 },
    { lat: 49.0367817, lng: 21.3669636 },
    { lat: 49.0368551, lng: 21.3665945 },
    { lat: 49.0374811, lng: 21.3653188 },
    { lat: 49.0376439, lng: 21.3647833 },
    { lat: 49.0381906, lng: 21.363829 },
    { lat: 49.0385711, lng: 21.3633459 },
    { lat: 49.0387521, lng: 21.3629224 },
    { lat: 49.0389765, lng: 21.3627524 },
    { lat: 49.0379718, lng: 21.358749 },
    { lat: 49.0376534, lng: 21.3587925 },
    { lat: 49.0371099, lng: 21.3571635 },
    { lat: 49.0368053, lng: 21.3554821 },
    { lat: 49.0367135, lng: 21.3553038 },
    { lat: 49.0367327, lng: 21.3550478 },
    { lat: 49.0368883, lng: 21.3548663 },
    { lat: 49.0371448, lng: 21.3549437 },
    { lat: 49.0375949, lng: 21.3545321 },
    { lat: 49.0377076, lng: 21.3542711 },
    { lat: 49.0378988, lng: 21.3542708 },
    { lat: 49.0381712, lng: 21.3538223 },
    { lat: 49.0387522, lng: 21.3533418 },
    { lat: 49.0390919, lng: 21.3533782 },
    { lat: 49.039175, lng: 21.3531871 },
    { lat: 49.038921, lng: 21.3526301 },
    { lat: 49.0373287, lng: 21.3487479 },
    { lat: 49.0356395, lng: 21.350244 },
    { lat: 49.0309819, lng: 21.3533569 },
    { lat: 49.0306603, lng: 21.3526472 },
    { lat: 49.0301685, lng: 21.3520006 },
    { lat: 49.0297749, lng: 21.3518561 },
    { lat: 49.0294309, lng: 21.3513753 },
    { lat: 49.0288318, lng: 21.3515107 },
    { lat: 49.0288125, lng: 21.3518111 },
    { lat: 49.0285012, lng: 21.352127 },
    { lat: 49.0268893, lng: 21.3530451 },
  ],
  Lesíček: [
    { lat: 48.9389058, lng: 21.3830218 },
    { lat: 48.938651, lng: 21.3831208 },
    { lat: 48.9375434, lng: 21.3821235 },
    { lat: 48.9366638, lng: 21.3816844 },
    { lat: 48.9359237, lng: 21.3816537 },
    { lat: 48.9354161, lng: 21.3813066 },
    { lat: 48.9345304, lng: 21.3809627 },
    { lat: 48.9331596, lng: 21.3806446 },
    { lat: 48.9308869, lng: 21.3802342 },
    { lat: 48.9293999, lng: 21.3789714 },
    { lat: 48.9302151, lng: 21.3774713 },
    { lat: 48.9308362, lng: 21.3769035 },
    { lat: 48.9321783, lng: 21.3753403 },
    { lat: 48.9327629, lng: 21.374835 },
    { lat: 48.9332703, lng: 21.3738415 },
    { lat: 48.9319794, lng: 21.373274 },
    { lat: 48.9315347, lng: 21.3732272 },
    { lat: 48.9303006, lng: 21.3733075 },
    { lat: 48.9291513, lng: 21.3739792 },
    { lat: 48.9277496, lng: 21.3739905 },
    { lat: 48.9266255, lng: 21.3733457 },
    { lat: 48.9257545, lng: 21.3729259 },
    { lat: 48.9257614, lng: 21.3737288 },
    { lat: 48.9250494, lng: 21.3737686 },
    { lat: 48.9244631, lng: 21.374035 },
    { lat: 48.9239118, lng: 21.3747565 },
    { lat: 48.9225418, lng: 21.3746997 },
    { lat: 48.9220003, lng: 21.3744953 },
    { lat: 48.9214327, lng: 21.3744859 },
    { lat: 48.9211461, lng: 21.374191 },
    { lat: 48.9209874, lng: 21.3748309 },
    { lat: 48.9199921, lng: 21.374171 },
    { lat: 48.919851, lng: 21.3735513 },
    { lat: 48.9193568, lng: 21.3734646 },
    { lat: 48.9187445, lng: 21.3728951 },
    { lat: 48.9185012, lng: 21.3733 },
    { lat: 48.9182066, lng: 21.3733459 },
    { lat: 48.9179307, lng: 21.3732271 },
    { lat: 48.9178303, lng: 21.3730676 },
    { lat: 48.917758, lng: 21.3730951 },
    { lat: 48.9174708, lng: 21.3728575 },
    { lat: 48.9172206, lng: 21.3728822 },
    { lat: 48.916908, lng: 21.3727015 },
    { lat: 48.9162587, lng: 21.3727041 },
    { lat: 48.9158919, lng: 21.3723991 },
    { lat: 48.9154057, lng: 21.3726578 },
    { lat: 48.915141, lng: 21.3726541 },
    { lat: 48.9148848, lng: 21.3722466 },
    { lat: 48.9135588, lng: 21.3721941 },
    { lat: 48.9132417, lng: 21.3726948 },
    { lat: 48.9129166, lng: 21.3727672 },
    { lat: 48.9124866, lng: 21.3725419 },
    { lat: 48.912246, lng: 21.3726972 },
    { lat: 48.9117353, lng: 21.3740576 },
    { lat: 48.9115515, lng: 21.3741271 },
    { lat: 48.9111975, lng: 21.3741399 },
    { lat: 48.9108594, lng: 21.3739735 },
    { lat: 48.9106531, lng: 21.3737694 },
    { lat: 48.910507, lng: 21.3737408 },
    { lat: 48.9103592, lng: 21.373948 },
    { lat: 48.9099527, lng: 21.3739011 },
    { lat: 48.9098511, lng: 21.3740036 },
    { lat: 48.9093706, lng: 21.3739252 },
    { lat: 48.9090724, lng: 21.3743109 },
    { lat: 48.9090572, lng: 21.3742894 },
    { lat: 48.9092763, lng: 21.3758279 },
    { lat: 48.9093351, lng: 21.3759112 },
    { lat: 48.9094672, lng: 21.3766512 },
    { lat: 48.909571, lng: 21.3768433 },
    { lat: 48.9095549, lng: 21.377474 },
    { lat: 48.9094915, lng: 21.377498 },
    { lat: 48.9094702, lng: 21.378147 },
    { lat: 48.9099057, lng: 21.3792797 },
    { lat: 48.9101187, lng: 21.3795628 },
    { lat: 48.9100883, lng: 21.3806159 },
    { lat: 48.9102834, lng: 21.3810171 },
    { lat: 48.9103273, lng: 21.3813926 },
    { lat: 48.9108371, lng: 21.3827196 },
    { lat: 48.9110949, lng: 21.3829509 },
    { lat: 48.9114124, lng: 21.3835281 },
    { lat: 48.9115545, lng: 21.3835406 },
    { lat: 48.9118016, lng: 21.3838281 },
    { lat: 48.9119337, lng: 21.3838066 },
    { lat: 48.9121723, lng: 21.384075 },
    { lat: 48.912338, lng: 21.384611 },
    { lat: 48.9124004, lng: 21.3847062 },
    { lat: 48.9125272, lng: 21.3847348 },
    { lat: 48.9127943, lng: 21.3852413 },
    { lat: 48.9130278, lng: 21.3853713 },
    { lat: 48.9130636, lng: 21.3852913 },
    { lat: 48.9131956, lng: 21.3852966 },
    { lat: 48.9133514, lng: 21.3854297 },
    { lat: 48.9134628, lng: 21.3860789 },
    { lat: 48.9136905, lng: 21.3862011 },
    { lat: 48.9137912, lng: 21.3863767 },
    { lat: 48.9138504, lng: 21.3863644 },
    { lat: 48.913858, lng: 21.3870708 },
    { lat: 48.9139882, lng: 21.3874003 },
    { lat: 48.9140599, lng: 21.3874656 },
    { lat: 48.9141681, lng: 21.3885375 },
    { lat: 48.914275, lng: 21.3887628 },
    { lat: 48.9142198, lng: 21.3888413 },
    { lat: 48.9142294, lng: 21.3890478 },
    { lat: 48.9145099, lng: 21.3899759 },
    { lat: 48.9144817, lng: 21.3907656 },
    { lat: 48.9146147, lng: 21.3911884 },
    { lat: 48.9148646, lng: 21.3914586 },
    { lat: 48.9148924, lng: 21.3917923 },
    { lat: 48.9151073, lng: 21.3923994 },
    { lat: 48.9150014, lng: 21.3926231 },
    { lat: 48.9150787, lng: 21.3928425 },
    { lat: 48.9150119, lng: 21.3931668 },
    { lat: 48.9147973, lng: 21.3937099 },
    { lat: 48.9148063, lng: 21.3941314 },
    { lat: 48.9146512, lng: 21.3945949 },
    { lat: 48.914609, lng: 21.3949963 },
    { lat: 48.9148919, lng: 21.3957662 },
    { lat: 48.9150453, lng: 21.3955865 },
    { lat: 48.9153965, lng: 21.3958234 },
    { lat: 48.9158108, lng: 21.3958314 },
    { lat: 48.9160028, lng: 21.3959303 },
    { lat: 48.9161199, lng: 21.3958232 },
    { lat: 48.9163532, lng: 21.3958349 },
    { lat: 48.916455, lng: 21.3959685 },
    { lat: 48.9166664, lng: 21.3960354 },
    { lat: 48.9169431, lng: 21.3963346 },
    { lat: 48.91723, lng: 21.3963322 },
    { lat: 48.9173239, lng: 21.396431 },
    { lat: 48.9175701, lng: 21.3964972 },
    { lat: 48.917731, lng: 21.396873 },
    { lat: 48.9178565, lng: 21.3968999 },
    { lat: 48.9180516, lng: 21.3971045 },
    { lat: 48.9182304, lng: 21.3974408 },
    { lat: 48.9185385, lng: 21.3977401 },
    { lat: 48.9186225, lng: 21.39788 },
    { lat: 48.9186311, lng: 21.3981685 },
    { lat: 48.9187294, lng: 21.3982963 },
    { lat: 48.9188616, lng: 21.3982773 },
    { lat: 48.9190397, lng: 21.3985199 },
    { lat: 48.9193891, lng: 21.3985741 },
    { lat: 48.9194403, lng: 21.3986311 },
    { lat: 48.9194747, lng: 21.3986943 },
    { lat: 48.9193911, lng: 21.3989869 },
    { lat: 48.9194136, lng: 21.3990995 },
    { lat: 48.9195822, lng: 21.3991728 },
    { lat: 48.9197182, lng: 21.3990913 },
    { lat: 48.919791, lng: 21.3989615 },
    { lat: 48.9201835, lng: 21.3989895 },
    { lat: 48.9209735, lng: 21.3991157 },
    { lat: 48.9210948, lng: 21.3992227 },
    { lat: 48.9211541, lng: 21.3996663 },
    { lat: 48.9214508, lng: 21.4002257 },
    { lat: 48.9215082, lng: 21.4005345 },
    { lat: 48.921764, lng: 21.4008842 },
    { lat: 48.9218788, lng: 21.401226 },
    { lat: 48.9220198, lng: 21.4021921 },
    { lat: 48.9219307, lng: 21.4024579 },
    { lat: 48.9219516, lng: 21.402893 },
    { lat: 48.9225857, lng: 21.4045198 },
    { lat: 48.9229533, lng: 21.4051915 },
    { lat: 48.923372, lng: 21.405732 },
    { lat: 48.9252136, lng: 21.4078147 },
    { lat: 48.9252597, lng: 21.4080835 },
    { lat: 48.9254239, lng: 21.4084244 },
    { lat: 48.9256573, lng: 21.4092785 },
    { lat: 48.9256127, lng: 21.4113211 },
    { lat: 48.9256747, lng: 21.4122408 },
    { lat: 48.9258932, lng: 21.4135535 },
    { lat: 48.9255796, lng: 21.415094 },
    { lat: 48.9254064, lng: 21.4166694 },
    { lat: 48.9252648, lng: 21.4186209 },
    { lat: 48.9251743, lng: 21.4197956 },
    { lat: 48.9255189, lng: 21.4196138 },
    { lat: 48.9265203, lng: 21.4185796 },
    { lat: 48.9277449, lng: 21.4191634 },
    { lat: 48.928235, lng: 21.4192262 },
    { lat: 48.9289572, lng: 21.4188413 },
    { lat: 48.9293726, lng: 21.4187349 },
    { lat: 48.9297381, lng: 21.4187996 },
    { lat: 48.930236, lng: 21.4184757 },
    { lat: 48.930361, lng: 21.4177862 },
    { lat: 48.9306503, lng: 21.4171046 },
    { lat: 48.9317336, lng: 21.415718 },
    { lat: 48.9327718, lng: 21.4146689 },
    { lat: 48.9336014, lng: 21.4147952 },
    { lat: 48.9344637, lng: 21.4148123 },
    { lat: 48.9347198, lng: 21.4151403 },
    { lat: 48.9353038, lng: 21.4154447 },
    { lat: 48.9355035, lng: 21.4154301 },
    { lat: 48.9357662, lng: 21.4151743 },
    { lat: 48.9368858, lng: 21.4149843 },
    { lat: 48.9373914, lng: 21.4147311 },
    { lat: 48.9380827, lng: 21.4151291 },
    { lat: 48.939254, lng: 21.4160426 },
    { lat: 48.9395686, lng: 21.4166055 },
    { lat: 48.9399498, lng: 21.4169412 },
    { lat: 48.9412507, lng: 21.4165678 },
    { lat: 48.9410598, lng: 21.4156938 },
    { lat: 48.9411195, lng: 21.4150462 },
    { lat: 48.9407531, lng: 21.4139578 },
    { lat: 48.9409739, lng: 21.4133494 },
    { lat: 48.9408033, lng: 21.413016 },
    { lat: 48.9398336, lng: 21.4118028 },
    { lat: 48.9396621, lng: 21.4114773 },
    { lat: 48.9395956, lng: 21.4111729 },
    { lat: 48.9395717, lng: 21.4105135 },
    { lat: 48.9393945, lng: 21.4099972 },
    { lat: 48.9393094, lng: 21.4095885 },
    { lat: 48.9391246, lng: 21.4092782 },
    { lat: 48.9390513, lng: 21.4089252 },
    { lat: 48.9384953, lng: 21.4077325 },
    { lat: 48.9384347, lng: 21.4073966 },
    { lat: 48.9382513, lng: 21.4070149 },
    { lat: 48.9382026, lng: 21.4065841 },
    { lat: 48.938007, lng: 21.4059399 },
    { lat: 48.937953, lng: 21.4047557 },
    { lat: 48.9378185, lng: 21.4044319 },
    { lat: 48.9377666, lng: 21.4041202 },
    { lat: 48.9363866, lng: 21.4008615 },
    { lat: 48.9359851, lng: 21.3995782 },
    { lat: 48.9357852, lng: 21.3986358 },
    { lat: 48.9354493, lng: 21.3976244 },
    { lat: 48.9355456, lng: 21.3969571 },
    { lat: 48.9354914, lng: 21.3965356 },
    { lat: 48.9368205, lng: 21.3896585 },
    { lat: 48.9369153, lng: 21.3887179 },
    { lat: 48.9371077, lng: 21.3880327 },
    { lat: 48.93728, lng: 21.3880409 },
    { lat: 48.9380042, lng: 21.3859342 },
    { lat: 48.9385542, lng: 21.3835777 },
    { lat: 48.9389058, lng: 21.3830218 },
  ],
  ChminianskeJakubovany: [
    { lat: 49.01346, lng: 21.0215468 },
    { lat: 49.01329, lng: 21.0205841 },
    { lat: 49.0126787, lng: 21.0177256 },
    { lat: 49.0127405, lng: 21.0169629 },
    { lat: 49.0126714, lng: 21.0160937 },
    { lat: 49.0126572, lng: 21.0146653 },
    { lat: 49.0131453, lng: 21.0135578 },
    { lat: 49.0126762, lng: 21.0129085 },
    { lat: 49.012056, lng: 21.0118474 },
    { lat: 49.0116459, lng: 21.0074837 },
    { lat: 49.0114348, lng: 21.0059261 },
    { lat: 49.0111296, lng: 21.0046841 },
    { lat: 49.0106029, lng: 21.0031111 },
    { lat: 49.0104045, lng: 21.002687 },
    { lat: 49.0093457, lng: 21.0009864 },
    { lat: 49.0087485, lng: 20.9996663 },
    { lat: 49.0083568, lng: 20.9983711 },
    { lat: 49.0083697, lng: 20.9972946 },
    { lat: 49.0084632, lng: 20.9963034 },
    { lat: 49.008121, lng: 20.995445 },
    { lat: 49.0077497, lng: 20.9946979 },
    { lat: 49.007419, lng: 20.9943323 },
    { lat: 49.0071002, lng: 20.9935421 },
    { lat: 49.0069233, lng: 20.9930544 },
    { lat: 49.0063927, lng: 20.9910988 },
    { lat: 49.0068567, lng: 20.9906579 },
    { lat: 49.0073181, lng: 20.9903539 },
    { lat: 49.006824, lng: 20.9885471 },
    { lat: 49.0062314, lng: 20.9866565 },
    { lat: 49.0060141, lng: 20.9868892 },
    { lat: 49.004733, lng: 20.9875982 },
    { lat: 49.0044983, lng: 20.9873731 },
    { lat: 49.0045852, lng: 20.9870069 },
    { lat: 49.0049621, lng: 20.9869365 },
    { lat: 49.0052188, lng: 20.986766 },
    { lat: 49.0051189, lng: 20.9864987 },
    { lat: 49.0043522, lng: 20.9855721 },
    { lat: 49.0030167, lng: 20.9843618 },
    { lat: 49.0034645, lng: 20.9835755 },
    { lat: 49.002067, lng: 20.9806303 },
    { lat: 49.0018741, lng: 20.9807501 },
    { lat: 49.001488, lng: 20.9805448 },
    { lat: 49.0010871, lng: 20.979827 },
    { lat: 49.0006797, lng: 20.9794517 },
    { lat: 49.0006768, lng: 20.9791594 },
    { lat: 49.0000148, lng: 20.9775931 },
    { lat: 48.9992987, lng: 20.976089 },
    { lat: 48.9991774, lng: 20.9759219 },
    { lat: 48.9990023, lng: 20.9758411 },
    { lat: 48.9987782, lng: 20.9761034 },
    { lat: 48.9977349, lng: 20.9746115 },
    { lat: 48.9971508, lng: 20.9736623 },
    { lat: 48.9966305, lng: 20.9733488 },
    { lat: 48.996238, lng: 20.9725075 },
    { lat: 48.9961535, lng: 20.9721996 },
    { lat: 48.995754, lng: 20.971084 },
    { lat: 48.9955579, lng: 20.97077 },
    { lat: 48.9951669, lng: 20.9699122 },
    { lat: 48.9954129, lng: 20.969422 },
    { lat: 48.9947778, lng: 20.9690026 },
    { lat: 48.9940518, lng: 20.9691092 },
    { lat: 48.9936893, lng: 20.9692707 },
    { lat: 48.9931365, lng: 20.9697779 },
    { lat: 48.9928337, lng: 20.969942 },
    { lat: 48.9898712, lng: 20.9721093 },
    { lat: 48.9890509, lng: 20.9712992 },
    { lat: 48.9877578, lng: 20.9717774 },
    { lat: 48.9877149, lng: 20.9670962 },
    { lat: 48.9868102, lng: 20.967105 },
    { lat: 48.9867049, lng: 20.9670149 },
    { lat: 48.9859232, lng: 20.9698478 },
    { lat: 48.9857283, lng: 20.9707525 },
    { lat: 48.9856642, lng: 20.9721822 },
    { lat: 48.98574, lng: 20.9739277 },
    { lat: 48.985645, lng: 20.9744549 },
    { lat: 48.9856879, lng: 20.9749655 },
    { lat: 48.9852958, lng: 20.9759376 },
    { lat: 48.9852203, lng: 20.9764766 },
    { lat: 48.9850565, lng: 20.9771732 },
    { lat: 48.9842064, lng: 20.9788275 },
    { lat: 48.9837075, lng: 20.9803491 },
    { lat: 48.9835981, lng: 20.9809194 },
    { lat: 48.9830431, lng: 20.981696 },
    { lat: 48.9829269, lng: 20.9820958 },
    { lat: 48.9827333, lng: 20.9831519 },
    { lat: 48.9827424, lng: 20.9836962 },
    { lat: 48.9829862, lng: 20.9844638 },
    { lat: 48.9832928, lng: 20.9862821 },
    { lat: 48.9832172, lng: 20.9863499 },
    { lat: 48.9816487, lng: 20.9864617 },
    { lat: 48.9803506, lng: 20.9864544 },
    { lat: 48.9802257, lng: 20.9873364 },
    { lat: 48.9799075, lng: 20.988026 },
    { lat: 48.9796479, lng: 20.9881773 },
    { lat: 48.9792796, lng: 20.9885447 },
    { lat: 48.9786934, lng: 20.9888177 },
    { lat: 48.9791043, lng: 20.9899494 },
    { lat: 48.9793297, lng: 20.991623 },
    { lat: 48.9794015, lng: 20.9917569 },
    { lat: 48.9783758, lng: 20.9917136 },
    { lat: 48.9782716, lng: 20.9914503 },
    { lat: 48.9779417, lng: 20.9912075 },
    { lat: 48.9774068, lng: 20.9909319 },
    { lat: 48.9770724, lng: 20.9909248 },
    { lat: 48.9764016, lng: 20.9911289 },
    { lat: 48.9756507, lng: 20.9914964 },
    { lat: 48.9754914, lng: 20.9917195 },
    { lat: 48.9752472, lng: 20.9918893 },
    { lat: 48.9730727, lng: 20.9927038 },
    { lat: 48.9720331, lng: 20.9929173 },
    { lat: 48.971692, lng: 20.9930221 },
    { lat: 48.9717471, lng: 20.9943857 },
    { lat: 48.9718605, lng: 20.9950549 },
    { lat: 48.972266, lng: 20.9961597 },
    { lat: 48.9725533, lng: 20.9974973 },
    { lat: 48.9727655, lng: 20.9981665 },
    { lat: 48.9735258, lng: 21.0024511 },
    { lat: 48.9750615, lng: 21.0057881 },
    { lat: 48.9756322, lng: 21.0072269 },
    { lat: 48.9756566, lng: 21.0073505 },
    { lat: 48.9756251, lng: 21.0075202 },
    { lat: 48.975201, lng: 21.0087225 },
    { lat: 48.9755775, lng: 21.0105257 },
    { lat: 48.9759066, lng: 21.013344 },
    { lat: 48.9761862, lng: 21.0150794 },
    { lat: 48.9762639, lng: 21.0153494 },
    { lat: 48.9766599, lng: 21.0162699 },
    { lat: 48.9772298, lng: 21.0176584 },
    { lat: 48.9778381, lng: 21.0188398 },
    { lat: 48.9794823, lng: 21.021753 },
    { lat: 48.9798095, lng: 21.0220203 },
    { lat: 48.9801117, lng: 21.0221752 },
    { lat: 48.981001, lng: 21.0223885 },
    { lat: 48.980938, lng: 21.0232446 },
    { lat: 48.9809614, lng: 21.0239952 },
    { lat: 48.9814631, lng: 21.024847 },
    { lat: 48.9822069, lng: 21.0253587 },
    { lat: 48.9828588, lng: 21.0259509 },
    { lat: 48.9830008, lng: 21.0255471 },
    { lat: 48.9834049, lng: 21.0249963 },
    { lat: 48.9837348, lng: 21.0246918 },
    { lat: 48.9846318, lng: 21.0241034 },
    { lat: 48.9859018, lng: 21.0249097 },
    { lat: 48.9863318, lng: 21.0252592 },
    { lat: 48.9870292, lng: 21.0260737 },
    { lat: 48.9872639, lng: 21.0264706 },
    { lat: 48.9877281, lng: 21.0274834 },
    { lat: 48.988301, lng: 21.0281414 },
    { lat: 48.9886991, lng: 21.0290571 },
    { lat: 48.9888583, lng: 21.0296274 },
    { lat: 48.9889828, lng: 21.0293664 },
    { lat: 48.9893066, lng: 21.0299429 },
    { lat: 48.9896056, lng: 21.0303454 },
    { lat: 48.9916786, lng: 21.0323729 },
    { lat: 48.9922654, lng: 21.0331469 },
    { lat: 48.9934417, lng: 21.0336359 },
    { lat: 48.9945121, lng: 21.0342906 },
    { lat: 48.9952786, lng: 21.0346393 },
    { lat: 48.9974692, lng: 21.0360659 },
    { lat: 48.998088, lng: 21.0362024 },
    { lat: 48.9983144, lng: 21.0364133 },
    { lat: 48.998514, lng: 21.0366685 },
    { lat: 48.9989446, lng: 21.0366171 },
    { lat: 48.9992172, lng: 21.0362818 },
    { lat: 48.9994829, lng: 21.0363145 },
    { lat: 48.9999096, lng: 21.0365644 },
    { lat: 49.001139, lng: 21.0368514 },
    { lat: 49.0017728, lng: 21.0366531 },
    { lat: 49.0024053, lng: 21.0366897 },
    { lat: 49.002584, lng: 21.0363321 },
    { lat: 49.0028198, lng: 21.0360426 },
    { lat: 49.0030458, lng: 21.0359284 },
    { lat: 49.0030188, lng: 21.0355139 },
    { lat: 49.0029566, lng: 21.0353347 },
    { lat: 49.0030403, lng: 21.0338968 },
    { lat: 49.0029585, lng: 21.0327057 },
    { lat: 49.0032344, lng: 21.0322637 },
    { lat: 49.0037233, lng: 21.0320318 },
    { lat: 49.0040883, lng: 21.0317333 },
    { lat: 49.0053762, lng: 21.0309528 },
    { lat: 49.005991, lng: 21.0298902 },
    { lat: 49.0063941, lng: 21.0293163 },
    { lat: 49.00685, lng: 21.0282491 },
    { lat: 49.0068927, lng: 21.0278379 },
    { lat: 49.0085255, lng: 21.0258626 },
    { lat: 49.0099624, lng: 21.0248222 },
    { lat: 49.0106707, lng: 21.024185 },
    { lat: 49.0112349, lng: 21.0235375 },
    { lat: 49.0121367, lng: 21.0222736 },
    { lat: 49.0130656, lng: 21.0218413 },
    { lat: 49.01346, lng: 21.0215468 },
  ],
  ŠarišskáPoruba: [
    { lat: 49.0489995, lng: 21.4000204 },
    { lat: 49.0489596, lng: 21.3992013 },
    { lat: 49.0489243, lng: 21.398803 },
    { lat: 49.0487686, lng: 21.3983674 },
    { lat: 49.0478111, lng: 21.3986713 },
    { lat: 49.0468688, lng: 21.3948051 },
    { lat: 49.0470593, lng: 21.3945482 },
    { lat: 49.0473901, lng: 21.3943979 },
    { lat: 49.0472967, lng: 21.3943562 },
    { lat: 49.0471757, lng: 21.3941808 },
    { lat: 49.0471997, lng: 21.3936253 },
    { lat: 49.0471259, lng: 21.3923391 },
    { lat: 49.0470133, lng: 21.3917277 },
    { lat: 49.0468154, lng: 21.3912405 },
    { lat: 49.0468294, lng: 21.3910268 },
    { lat: 49.0461865, lng: 21.3911633 },
    { lat: 49.0455343, lng: 21.3885128 },
    { lat: 49.0444906, lng: 21.3887158 },
    { lat: 49.0444378, lng: 21.3883012 },
    { lat: 49.0443374, lng: 21.3882159 },
    { lat: 49.0443044, lng: 21.3880568 },
    { lat: 49.0443832, lng: 21.3878652 },
    { lat: 49.0442613, lng: 21.3876275 },
    { lat: 49.0441623, lng: 21.3868639 },
    { lat: 49.0453627, lng: 21.3787635 },
    { lat: 49.0453888, lng: 21.3776703 },
    { lat: 49.045039, lng: 21.3743374 },
    { lat: 49.0450076, lng: 21.3735419 },
    { lat: 49.0451076, lng: 21.3722864 },
    { lat: 49.0453665, lng: 21.3707722 },
    { lat: 49.0452771, lng: 21.3706805 },
    { lat: 49.0447132, lng: 21.3708684 },
    { lat: 49.0445335, lng: 21.3715569 },
    { lat: 49.0442537, lng: 21.3722117 },
    { lat: 49.0441859, lng: 21.3725779 },
    { lat: 49.0422788, lng: 21.3725831 },
    { lat: 49.0422365, lng: 21.3726746 },
    { lat: 49.0400782, lng: 21.372711 },
    { lat: 49.0397324, lng: 21.3733781 },
    { lat: 49.0382857, lng: 21.3736522 },
    { lat: 49.037574, lng: 21.3742447 },
    { lat: 49.0374223, lng: 21.3744707 },
    { lat: 49.0373218, lng: 21.3744223 },
    { lat: 49.0368185, lng: 21.3752107 },
    { lat: 49.0362341, lng: 21.3758727 },
    { lat: 49.0356925, lng: 21.376811 },
    { lat: 49.0353966, lng: 21.3776863 },
    { lat: 49.0350716, lng: 21.3778427 },
    { lat: 49.0348002, lng: 21.3782551 },
    { lat: 49.0332257, lng: 21.3799907 },
    { lat: 49.0336157, lng: 21.3805388 },
    { lat: 49.0331135, lng: 21.3812904 },
    { lat: 49.0332823, lng: 21.3818619 },
    { lat: 49.0312241, lng: 21.3811667 },
    { lat: 49.0302999, lng: 21.3807388 },
    { lat: 49.0297996, lng: 21.3808283 },
    { lat: 49.0298481, lng: 21.3811344 },
    { lat: 49.0297938, lng: 21.3812755 },
    { lat: 49.0296514, lng: 21.3812935 },
    { lat: 49.0295391, lng: 21.3812259 },
    { lat: 49.029444, lng: 21.3813923 },
    { lat: 49.0294503, lng: 21.3815715 },
    { lat: 49.0291584, lng: 21.3830681 },
    { lat: 49.0290252, lng: 21.3833956 },
    { lat: 49.0289205, lng: 21.3844613 },
    { lat: 49.0289772, lng: 21.386228 },
    { lat: 49.0285229, lng: 21.3875287 },
    { lat: 49.0286168, lng: 21.3879296 },
    { lat: 49.0285652, lng: 21.3884634 },
    { lat: 49.0283278, lng: 21.3891129 },
    { lat: 49.0282755, lng: 21.3905406 },
    { lat: 49.0285755, lng: 21.3927001 },
    { lat: 49.0279314, lng: 21.3934436 },
    { lat: 49.0274015, lng: 21.3942084 },
    { lat: 49.0270768, lng: 21.3944873 },
    { lat: 49.0269216, lng: 21.3947553 },
    { lat: 49.0265301, lng: 21.395104 },
    { lat: 49.0262602, lng: 21.3955297 },
    { lat: 49.0258396, lng: 21.396036 },
    { lat: 49.0252752, lng: 21.3970364 },
    { lat: 49.024958, lng: 21.3974643 },
    { lat: 49.024492, lng: 21.3984139 },
    { lat: 49.0248628, lng: 21.3988095 },
    { lat: 49.0249038, lng: 21.3994413 },
    { lat: 49.0247319, lng: 21.3997133 },
    { lat: 49.0243572, lng: 21.4000949 },
    { lat: 49.024315, lng: 21.4002731 },
    { lat: 49.0243758, lng: 21.4006959 },
    { lat: 49.0243578, lng: 21.4008854 },
    { lat: 49.0222464, lng: 21.4005556 },
    { lat: 49.0217312, lng: 21.4014357 },
    { lat: 49.0215885, lng: 21.401563 },
    { lat: 49.0213642, lng: 21.4020082 },
    { lat: 49.0210842, lng: 21.4023961 },
    { lat: 49.0206086, lng: 21.4028673 },
    { lat: 49.0201709, lng: 21.4032083 },
    { lat: 49.0200372, lng: 21.4034411 },
    { lat: 49.0198863, lng: 21.4035205 },
    { lat: 49.0193902, lng: 21.4035586 },
    { lat: 49.0193135, lng: 21.4036696 },
    { lat: 49.0193326, lng: 21.4039755 },
    { lat: 49.0193495, lng: 21.4041647 },
    { lat: 49.01947, lng: 21.404373 },
    { lat: 49.019528, lng: 21.40457 },
    { lat: 49.019628, lng: 21.404774 },
    { lat: 49.019731, lng: 21.405247 },
    { lat: 49.019775, lng: 21.405429 },
    { lat: 49.020141, lng: 21.40584 },
    { lat: 49.020288, lng: 21.40612 },
    { lat: 49.020437, lng: 21.406295 },
    { lat: 49.020318, lng: 21.406832 },
    { lat: 49.020362, lng: 21.407453 },
    { lat: 49.020438, lng: 21.407765 },
    { lat: 49.020773, lng: 21.408003 },
    { lat: 49.020637, lng: 21.408587 },
    { lat: 49.020234, lng: 21.408424 },
    { lat: 49.019557, lng: 21.408315 },
    { lat: 49.019596, lng: 21.409107 },
    { lat: 49.02, lng: 21.409614 },
    { lat: 49.020484, lng: 21.410052 },
    { lat: 49.02056, lng: 21.410203 },
    { lat: 49.020966, lng: 21.410718 },
    { lat: 49.021279, lng: 21.411283 },
    { lat: 49.021548, lng: 21.4115 },
    { lat: 49.021666, lng: 21.411489 },
    { lat: 49.021715, lng: 21.411583 },
    { lat: 49.021755, lng: 21.411747 },
    { lat: 49.021847, lng: 21.411759 },
    { lat: 49.021874, lng: 21.411832 },
    { lat: 49.02201, lng: 21.411885 },
    { lat: 49.022039, lng: 21.411962 },
    { lat: 49.022114, lng: 21.412015 },
    { lat: 49.022135, lng: 21.412082 },
    { lat: 49.022194, lng: 21.412178 },
    { lat: 49.022278, lng: 21.412096 },
    { lat: 49.022367, lng: 21.411921 },
    { lat: 49.02245, lng: 21.411888 },
    { lat: 49.022516, lng: 21.411747 },
    { lat: 49.023127, lng: 21.411732 },
    { lat: 49.023649, lng: 21.411598 },
    { lat: 49.024031, lng: 21.411376 },
    { lat: 49.023853, lng: 21.412737 },
    { lat: 49.023844, lng: 21.412828 },
    { lat: 49.023891, lng: 21.412882 },
    { lat: 49.024002, lng: 21.412931 },
    { lat: 49.024435, lng: 21.412883 },
    { lat: 49.024535, lng: 21.412989 },
    { lat: 49.024719, lng: 21.413047 },
    { lat: 49.024748, lng: 21.413175 },
    { lat: 49.024966, lng: 21.413168 },
    { lat: 49.025066, lng: 21.413066 },
    { lat: 49.025069, lng: 21.413089 },
    { lat: 49.025177, lng: 21.413094 },
    { lat: 49.025306, lng: 21.413226 },
    { lat: 49.025309, lng: 21.413252 },
    { lat: 49.025378, lng: 21.413244 },
    { lat: 49.025601, lng: 21.413457 },
    { lat: 49.025695, lng: 21.413494 },
    { lat: 49.025874, lng: 21.413424 },
    { lat: 49.025922, lng: 21.413372 },
    { lat: 49.026122, lng: 21.413445 },
    { lat: 49.026268, lng: 21.413415 },
    { lat: 49.026333, lng: 21.413496 },
    { lat: 49.026433, lng: 21.413421 },
    { lat: 49.026572, lng: 21.413412 },
    { lat: 49.026598, lng: 21.413445 },
    { lat: 49.026907, lng: 21.413535 },
    { lat: 49.026967, lng: 21.413628 },
    { lat: 49.026954, lng: 21.413732 },
    { lat: 49.027021, lng: 21.413834 },
    { lat: 49.027104, lng: 21.413893 },
    { lat: 49.027136, lng: 21.413854 },
    { lat: 49.027333, lng: 21.413867 },
    { lat: 49.027341, lng: 21.41384 },
    { lat: 49.027309, lng: 21.413748 },
    { lat: 49.027322, lng: 21.413726 },
    { lat: 49.027401, lng: 21.413769 },
    { lat: 49.027521, lng: 21.413693 },
    { lat: 49.027611, lng: 21.413749 },
    { lat: 49.027709, lng: 21.413727 },
    { lat: 49.027787, lng: 21.413726 },
    { lat: 49.027799, lng: 21.413772 },
    { lat: 49.027829, lng: 21.413776 },
    { lat: 49.027843, lng: 21.413794 },
    { lat: 49.027829, lng: 21.41395 },
    { lat: 49.027849, lng: 21.413962 },
    { lat: 49.027876, lng: 21.413923 },
    { lat: 49.02796, lng: 21.413981 },
    { lat: 49.028121, lng: 21.414126 },
    { lat: 49.028263, lng: 21.414128 },
    { lat: 49.028363, lng: 21.414214 },
    { lat: 49.028543, lng: 21.414314 },
    { lat: 49.028597, lng: 21.414255 },
    { lat: 49.028645, lng: 21.414279 },
    { lat: 49.028652, lng: 21.414444 },
    { lat: 49.028696, lng: 21.414462 },
    { lat: 49.028753, lng: 21.414518 },
    { lat: 49.028893, lng: 21.414421 },
    { lat: 49.028913, lng: 21.414499 },
    { lat: 49.02896, lng: 21.414523 },
    { lat: 49.028984, lng: 21.414778 },
    { lat: 49.029061, lng: 21.414784 },
    { lat: 49.029136, lng: 21.414809 },
    { lat: 49.029148, lng: 21.414801 },
    { lat: 49.029152, lng: 21.414777 },
    { lat: 49.029139, lng: 21.414652 },
    { lat: 49.029333, lng: 21.414532 },
    { lat: 49.029446, lng: 21.414559 },
    { lat: 49.029476, lng: 21.414594 },
    { lat: 49.029483, lng: 21.414694 },
    { lat: 49.029506, lng: 21.414716 },
    { lat: 49.029609, lng: 21.414661 },
    { lat: 49.029716, lng: 21.414643 },
    { lat: 49.029728, lng: 21.414611 },
    { lat: 49.02978, lng: 21.414602 },
    { lat: 49.029853, lng: 21.414742 },
    { lat: 49.029985, lng: 21.414938 },
    { lat: 49.030029, lng: 21.414933 },
    { lat: 49.030148, lng: 21.415137 },
    { lat: 49.030238, lng: 21.415244 },
    { lat: 49.030383, lng: 21.415317 },
    { lat: 49.030701, lng: 21.415402 },
    { lat: 49.030914, lng: 21.415371 },
    { lat: 49.031221, lng: 21.415416 },
    { lat: 49.031411, lng: 21.41561 },
    { lat: 49.031465, lng: 21.41577 },
    { lat: 49.031577, lng: 21.415877 },
    { lat: 49.03174, lng: 21.415884 },
    { lat: 49.031919, lng: 21.416077 },
    { lat: 49.032201, lng: 21.415794 },
    { lat: 49.032256, lng: 21.41578 },
    { lat: 49.032335, lng: 21.415897 },
    { lat: 49.032796, lng: 21.415839 },
    { lat: 49.03291, lng: 21.416012 },
    { lat: 49.033069, lng: 21.415934 },
    { lat: 49.033169, lng: 21.415972 },
    { lat: 49.033199, lng: 21.416077 },
    { lat: 49.033345, lng: 21.41629 },
    { lat: 49.033384, lng: 21.416442 },
    { lat: 49.033537, lng: 21.416742 },
    { lat: 49.033559, lng: 21.416747 },
    { lat: 49.033712, lng: 21.416631 },
    { lat: 49.033781, lng: 21.417032 },
    { lat: 49.033799, lng: 21.417053 },
    { lat: 49.033933, lng: 21.417059 },
    { lat: 49.034311, lng: 21.417188 },
    { lat: 49.034461, lng: 21.417325 },
    { lat: 49.034761, lng: 21.417391 },
    { lat: 49.03479, lng: 21.417363 },
    { lat: 49.034877, lng: 21.417086 },
    { lat: 49.035022, lng: 21.417141 },
    { lat: 49.035153, lng: 21.41699 },
    { lat: 49.03521, lng: 21.417004 },
    { lat: 49.035246, lng: 21.417118 },
    { lat: 49.035469, lng: 21.417292 },
    { lat: 49.035651, lng: 21.417264 },
    { lat: 49.035747, lng: 21.417334 },
    { lat: 49.03577, lng: 21.417324 },
    { lat: 49.035839, lng: 21.417157 },
    { lat: 49.035889, lng: 21.417128 },
    { lat: 49.035985, lng: 21.417258 },
    { lat: 49.036009, lng: 21.417266 },
    { lat: 49.036122, lng: 21.417189 },
    { lat: 49.036319, lng: 21.417183 },
    { lat: 49.036511, lng: 21.417063 },
    { lat: 49.036589, lng: 21.417034 },
    { lat: 49.03659, lng: 21.417102 },
    { lat: 49.0373429, lng: 21.4174507 },
    { lat: 49.0378196, lng: 21.416903 },
    { lat: 49.0384434, lng: 21.41488 },
    { lat: 49.038249, lng: 21.4143151 },
    { lat: 49.0380622, lng: 21.4139649 },
    { lat: 49.0381232, lng: 21.4135132 },
    { lat: 49.0385375, lng: 21.4124465 },
    { lat: 49.038374, lng: 21.4115912 },
    { lat: 49.0386461, lng: 21.4108214 },
    { lat: 49.039095, lng: 21.4106291 },
    { lat: 49.0395216, lng: 21.4098277 },
    { lat: 49.0394605, lng: 21.4097066 },
    { lat: 49.0395245, lng: 21.409509 },
    { lat: 49.0394773, lng: 21.4092848 },
    { lat: 49.0399199, lng: 21.4088344 },
    { lat: 49.0400315, lng: 21.4086461 },
    { lat: 49.0401737, lng: 21.4082039 },
    { lat: 49.0403113, lng: 21.4081368 },
    { lat: 49.0404076, lng: 21.4080062 },
    { lat: 49.0404037, lng: 21.407759 },
    { lat: 49.0404735, lng: 21.407609 },
    { lat: 49.0409558, lng: 21.4075156 },
    { lat: 49.0410783, lng: 21.407249 },
    { lat: 49.0411782, lng: 21.4072361 },
    { lat: 49.0414164, lng: 21.4068781 },
    { lat: 49.0417997, lng: 21.406483 },
    { lat: 49.0417566, lng: 21.4058608 },
    { lat: 49.04209, lng: 21.4060264 },
    { lat: 49.0430654, lng: 21.4059876 },
    { lat: 49.0446588, lng: 21.4037742 },
    { lat: 49.0445404, lng: 21.4034526 },
    { lat: 49.0442837, lng: 21.4030297 },
    { lat: 49.0450472, lng: 21.4026721 },
    { lat: 49.0449915, lng: 21.4022688 },
    { lat: 49.045538, lng: 21.4020289 },
    { lat: 49.045389, lng: 21.4009089 },
    { lat: 49.0451001, lng: 21.4010268 },
    { lat: 49.0450745, lng: 21.4007721 },
    { lat: 49.0455359, lng: 21.4005782 },
    { lat: 49.0455368, lng: 21.3992005 },
    { lat: 49.0454764, lng: 21.3986411 },
    { lat: 49.0466402, lng: 21.3981191 },
    { lat: 49.0469803, lng: 21.4006849 },
    { lat: 49.0469846, lng: 21.4021667 },
    { lat: 49.049016, lng: 21.4020717 },
    { lat: 49.0490158, lng: 21.4011513 },
    { lat: 49.0489458, lng: 21.4000312 },
    { lat: 49.0489995, lng: 21.4000204 },
  ],
  Hrabkov: [
    { lat: 48.9766599, lng: 21.0162699 },
    { lat: 48.9762639, lng: 21.0153494 },
    { lat: 48.9761862, lng: 21.0150794 },
    { lat: 48.9759066, lng: 21.013344 },
    { lat: 48.9755775, lng: 21.0105257 },
    { lat: 48.975201, lng: 21.0087225 },
    { lat: 48.9756251, lng: 21.0075202 },
    { lat: 48.9756566, lng: 21.0073505 },
    { lat: 48.9756322, lng: 21.0072269 },
    { lat: 48.9750615, lng: 21.0057881 },
    { lat: 48.9735258, lng: 21.0024511 },
    { lat: 48.9727655, lng: 20.9981665 },
    { lat: 48.9725533, lng: 20.9974973 },
    { lat: 48.972266, lng: 20.9961597 },
    { lat: 48.9718605, lng: 20.9950549 },
    { lat: 48.9717471, lng: 20.9943857 },
    { lat: 48.971692, lng: 20.9930221 },
    { lat: 48.970423, lng: 20.9930386 },
    { lat: 48.9696457, lng: 20.9932572 },
    { lat: 48.9690559, lng: 20.9939014 },
    { lat: 48.9685851, lng: 20.9940988 },
    { lat: 48.9681239, lng: 20.9946455 },
    { lat: 48.9673926, lng: 20.9950318 },
    { lat: 48.9670162, lng: 20.9958331 },
    { lat: 48.9665515, lng: 20.9961446 },
    { lat: 48.9664246, lng: 20.9965673 },
    { lat: 48.9662359, lng: 20.996745 },
    { lat: 48.9660854, lng: 20.9967586 },
    { lat: 48.9654528, lng: 20.9965843 },
    { lat: 48.9651548, lng: 20.9963888 },
    { lat: 48.9645969, lng: 20.995577 },
    { lat: 48.9640201, lng: 20.9942648 },
    { lat: 48.9637495, lng: 20.9938774 },
    { lat: 48.9623849, lng: 20.9944777 },
    { lat: 48.961877, lng: 20.994366 },
    { lat: 48.9605463, lng: 20.9934988 },
    { lat: 48.9603109, lng: 20.9931578 },
    { lat: 48.9601124, lng: 20.9923603 },
    { lat: 48.9597893, lng: 20.9914669 },
    { lat: 48.9595085, lng: 20.9897849 },
    { lat: 48.9594686, lng: 20.9892923 },
    { lat: 48.9588203, lng: 20.9877956 },
    { lat: 48.9587053, lng: 20.987376 },
    { lat: 48.958626, lng: 20.9867846 },
    { lat: 48.9586456, lng: 20.9865646 },
    { lat: 48.959052, lng: 20.985604 },
    { lat: 48.958929, lng: 20.985674 },
    { lat: 48.957658, lng: 20.986162 },
    { lat: 48.957639, lng: 20.98617 },
    { lat: 48.957518, lng: 20.986216 },
    { lat: 48.957497, lng: 20.986224 },
    { lat: 48.957163, lng: 20.986353 },
    { lat: 48.957013, lng: 20.986415 },
    { lat: 48.956993, lng: 20.986423 },
    { lat: 48.956588, lng: 20.986592 },
    { lat: 48.95656, lng: 20.986604 },
    { lat: 48.956148, lng: 20.986775 },
    { lat: 48.956107, lng: 20.986792 },
    { lat: 48.955928, lng: 20.986867 },
    { lat: 48.955729, lng: 20.98695 },
    { lat: 48.955691, lng: 20.986965 },
    { lat: 48.95556, lng: 20.986954 },
    { lat: 48.954939, lng: 20.9869 },
    { lat: 48.95464, lng: 20.986874 },
    { lat: 48.954614, lng: 20.986872 },
    { lat: 48.954438, lng: 20.986856 },
    { lat: 48.954402, lng: 20.986862 },
    { lat: 48.953701, lng: 20.986971 },
    { lat: 48.953669, lng: 20.986976 },
    { lat: 48.953514, lng: 20.987 },
    { lat: 48.953383, lng: 20.987086 },
    { lat: 48.953352, lng: 20.987106 },
    { lat: 48.952547, lng: 20.987632 },
    { lat: 48.951464, lng: 20.988846 },
    { lat: 48.951419, lng: 20.988897 },
    { lat: 48.951253, lng: 20.989083 },
    { lat: 48.950842, lng: 20.989207 },
    { lat: 48.949786, lng: 20.990618 },
    { lat: 48.949704, lng: 20.990728 },
    { lat: 48.94952, lng: 20.990821 },
    { lat: 48.949492, lng: 20.990835 },
    { lat: 48.949083, lng: 20.990774 },
    { lat: 48.948945, lng: 20.990647 },
    { lat: 48.948914, lng: 20.990619 },
    { lat: 48.94873, lng: 20.990451 },
    { lat: 48.948691, lng: 20.990415 },
    { lat: 48.948632, lng: 20.990361 },
    { lat: 48.948415, lng: 20.990301 },
    { lat: 48.948347, lng: 20.990321 },
    { lat: 48.948136, lng: 20.990253 },
    { lat: 48.947275, lng: 20.989397 },
    { lat: 48.94699, lng: 20.989423 },
    { lat: 48.946956, lng: 20.989426 },
    { lat: 48.946666, lng: 20.989452 },
    { lat: 48.945616, lng: 20.989081 },
    { lat: 48.945485, lng: 20.989074 },
    { lat: 48.945382, lng: 20.989069 },
    { lat: 48.945238, lng: 20.989062 },
    { lat: 48.945167, lng: 20.989059 },
    { lat: 48.944625, lng: 20.989033 },
    { lat: 48.944554, lng: 20.989029 },
    { lat: 48.944316, lng: 20.989018 },
    { lat: 48.942769, lng: 20.989064 },
    { lat: 48.942529, lng: 20.989072 },
    { lat: 48.941385, lng: 20.989513 },
    { lat: 48.94048, lng: 20.989592 },
    { lat: 48.940162, lng: 20.99055 },
    { lat: 48.939298, lng: 20.990491 },
    { lat: 48.939093, lng: 20.989785 },
    { lat: 48.93869, lng: 20.990122 },
    { lat: 48.938505, lng: 20.990572 },
    { lat: 48.938164, lng: 20.990485 },
    { lat: 48.937862, lng: 20.989858 },
    { lat: 48.936031, lng: 20.990168 },
    { lat: 48.935267, lng: 20.991156 },
    { lat: 48.934953, lng: 20.991384 },
    { lat: 48.934498, lng: 20.992198 },
    { lat: 48.933882, lng: 20.992963 },
    { lat: 48.933717, lng: 20.993228 },
    { lat: 48.933728, lng: 20.993817 },
    { lat: 48.93355, lng: 20.994572 },
    { lat: 48.933531, lng: 20.995067 },
    { lat: 48.932406, lng: 20.996336 },
    { lat: 48.930781, lng: 20.999089 },
    { lat: 48.929484, lng: 21.000261 },
    { lat: 48.928111, lng: 21.003125 },
    { lat: 48.927854, lng: 21.003636 },
    { lat: 48.927366, lng: 21.004599 },
    { lat: 48.926428, lng: 21.006447 },
    { lat: 48.9271328, lng: 21.007602 },
    { lat: 48.9269377, lng: 21.0091974 },
    { lat: 48.9277862, lng: 21.0107373 },
    { lat: 48.9281645, lng: 21.0111532 },
    { lat: 48.9291919, lng: 21.0115905 },
    { lat: 48.9301426, lng: 21.0117486 },
    { lat: 48.9312167, lng: 21.012792 },
    { lat: 48.9331283, lng: 21.0150986 },
    { lat: 48.9343101, lng: 21.0166419 },
    { lat: 48.9347802, lng: 21.019236 },
    { lat: 48.9355901, lng: 21.0177861 },
    { lat: 48.9362651, lng: 21.0172253 },
    { lat: 48.9368844, lng: 21.0176109 },
    { lat: 48.937107, lng: 21.018493 },
    { lat: 48.9377554, lng: 21.01826 },
    { lat: 48.9402429, lng: 21.0197069 },
    { lat: 48.9411104, lng: 21.0217941 },
    { lat: 48.9415709, lng: 21.0234789 },
    { lat: 48.941656, lng: 21.0240468 },
    { lat: 48.9416038, lng: 21.0244123 },
    { lat: 48.9416345, lng: 21.0247301 },
    { lat: 48.9415464, lng: 21.0256203 },
    { lat: 48.9415171, lng: 21.0265473 },
    { lat: 48.9413963, lng: 21.0267043 },
    { lat: 48.9415913, lng: 21.0279849 },
    { lat: 48.9415153, lng: 21.0295817 },
    { lat: 48.9415338, lng: 21.0301127 },
    { lat: 48.9414461, lng: 21.0303949 },
    { lat: 48.9408374, lng: 21.031599 },
    { lat: 48.9405566, lng: 21.0325464 },
    { lat: 48.9403407, lng: 21.0336538 },
    { lat: 48.9402404, lng: 21.0347548 },
    { lat: 48.9403962, lng: 21.0352872 },
    { lat: 48.9401237, lng: 21.0364881 },
    { lat: 48.9402812, lng: 21.0366907 },
    { lat: 48.940272, lng: 21.0371307 },
    { lat: 48.9407581, lng: 21.0384374 },
    { lat: 48.9409946, lng: 21.0387912 },
    { lat: 48.9417095, lng: 21.0404197 },
    { lat: 48.9419288, lng: 21.0408243 },
    { lat: 48.9424244, lng: 21.0414927 },
    { lat: 48.9430021, lng: 21.041885 },
    { lat: 48.9431671, lng: 21.0417326 },
    { lat: 48.9436346, lng: 21.0420888 },
    { lat: 48.9438664, lng: 21.0421601 },
    { lat: 48.9442174, lng: 21.0424299 },
    { lat: 48.9448124, lng: 21.0423859 },
    { lat: 48.9447517, lng: 21.0426643 },
    { lat: 48.9446364, lng: 21.0439612 },
    { lat: 48.9447037, lng: 21.0442941 },
    { lat: 48.9442292, lng: 21.0455915 },
    { lat: 48.9441542, lng: 21.0465022 },
    { lat: 48.9437057, lng: 21.0476555 },
    { lat: 48.9436654, lng: 21.0478755 },
    { lat: 48.9440191, lng: 21.0487139 },
    { lat: 48.9454991, lng: 21.0504425 },
    { lat: 48.9460317, lng: 21.0514151 },
    { lat: 48.9462802, lng: 21.0512021 },
    { lat: 48.9468509, lng: 21.0517952 },
    { lat: 48.9470106, lng: 21.0518917 },
    { lat: 48.9469888, lng: 21.051744 },
    { lat: 48.9470403, lng: 21.0517323 },
    { lat: 48.9472484, lng: 21.0520375 },
    { lat: 48.9476104, lng: 21.0535912 },
    { lat: 48.947815, lng: 21.0537918 },
    { lat: 48.9493704, lng: 21.0543076 },
    { lat: 48.9500213, lng: 21.0550849 },
    { lat: 48.9501636, lng: 21.054651 },
    { lat: 48.9503117, lng: 21.0544913 },
    { lat: 48.9506, lng: 21.0537754 },
    { lat: 48.9519065, lng: 21.0543922 },
    { lat: 48.9523296, lng: 21.0544583 },
    { lat: 48.9529785, lng: 21.0539219 },
    { lat: 48.9536265, lng: 21.0531518 },
    { lat: 48.9536376, lng: 21.0532892 },
    { lat: 48.9540268, lng: 21.0524146 },
    { lat: 48.9543211, lng: 21.051986 },
    { lat: 48.9550402, lng: 21.0513914 },
    { lat: 48.9553774, lng: 21.0512393 },
    { lat: 48.9562437, lng: 21.0511593 },
    { lat: 48.9565308, lng: 21.0510243 },
    { lat: 48.9570097, lng: 21.0509429 },
    { lat: 48.9573536, lng: 21.0509583 },
    { lat: 48.9576919, lng: 21.0512609 },
    { lat: 48.9582845, lng: 21.051628 },
    { lat: 48.9588618, lng: 21.0524166 },
    { lat: 48.959363, lng: 21.0523608 },
    { lat: 48.9601143, lng: 21.0511323 },
    { lat: 48.9603759, lng: 21.0493129 },
    { lat: 48.9605927, lng: 21.049403 },
    { lat: 48.9608102, lng: 21.0483409 },
    { lat: 48.9614676, lng: 21.0475548 },
    { lat: 48.961508, lng: 21.0466127 },
    { lat: 48.9614973, lng: 21.0452146 },
    { lat: 48.9613116, lng: 21.044524 },
    { lat: 48.9614161, lng: 21.044341 },
    { lat: 48.9612462, lng: 21.0433116 },
    { lat: 48.9612815, lng: 21.0428386 },
    { lat: 48.9618056, lng: 21.0423139 },
    { lat: 48.9619394, lng: 21.0419611 },
    { lat: 48.9623129, lng: 21.0418568 },
    { lat: 48.9625937, lng: 21.040613 },
    { lat: 48.9633935, lng: 21.0380956 },
    { lat: 48.96382, lng: 21.0373329 },
    { lat: 48.9639706, lng: 21.0374028 },
    { lat: 48.9640313, lng: 21.0372621 },
    { lat: 48.9641599, lng: 21.037409 },
    { lat: 48.9642322, lng: 21.0377578 },
    { lat: 48.9646568, lng: 21.0384038 },
    { lat: 48.964756, lng: 21.0385325 },
    { lat: 48.9650725, lng: 21.0388511 },
    { lat: 48.9652692, lng: 21.0388793 },
    { lat: 48.9656869, lng: 21.0391545 },
    { lat: 48.9656133, lng: 21.0389025 },
    { lat: 48.965448, lng: 21.0387011 },
    { lat: 48.9653649, lng: 21.0381908 },
    { lat: 48.9655033, lng: 21.0387034 },
    { lat: 48.9656594, lng: 21.0387955 },
    { lat: 48.9657473, lng: 21.0389758 },
    { lat: 48.9661442, lng: 21.0391699 },
    { lat: 48.9663571, lng: 21.0393838 },
    { lat: 48.9664516, lng: 21.0398667 },
    { lat: 48.9667559, lng: 21.039977 },
    { lat: 48.9675401, lng: 21.0380049 },
    { lat: 48.9677948, lng: 21.0375 },
    { lat: 48.9681927, lng: 21.0370663 },
    { lat: 48.9684036, lng: 21.0366715 },
    { lat: 48.9685945, lng: 21.0365135 },
    { lat: 48.9687152, lng: 21.0362529 },
    { lat: 48.9690682, lng: 21.0358978 },
    { lat: 48.9692277, lng: 21.0354377 },
    { lat: 48.9695519, lng: 21.0350242 },
    { lat: 48.9696323, lng: 21.0341431 },
    { lat: 48.969843, lng: 21.0339413 },
    { lat: 48.9714594, lng: 21.0290987 },
    { lat: 48.9714651, lng: 21.026721 },
    { lat: 48.9717583, lng: 21.0264341 },
    { lat: 48.9724706, lng: 21.0249893 },
    { lat: 48.9736601, lng: 21.0231265 },
    { lat: 48.9741418, lng: 21.0220945 },
    { lat: 48.9746227, lng: 21.0205903 },
    { lat: 48.9744395, lng: 21.020463 },
    { lat: 48.9750251, lng: 21.0191677 },
    { lat: 48.9753635, lng: 21.018542 },
    { lat: 48.975815, lng: 21.0181693 },
    { lat: 48.9760458, lng: 21.0178942 },
    { lat: 48.9763718, lng: 21.0173726 },
    { lat: 48.9764466, lng: 21.0171488 },
    { lat: 48.9766599, lng: 21.0162699 },
  ],
  Tulčík: [
    { lat: 49.0765514, lng: 21.3347857 },
    { lat: 49.0783277, lng: 21.333504 },
    { lat: 49.0792006, lng: 21.3339767 },
    { lat: 49.0793678, lng: 21.3341474 },
    { lat: 49.0796115, lng: 21.3346243 },
    { lat: 49.0803102, lng: 21.3353744 },
    { lat: 49.0805553, lng: 21.3355131 },
    { lat: 49.081441, lng: 21.3354835 },
    { lat: 49.0832023, lng: 21.335191 },
    { lat: 49.0841208, lng: 21.3352028 },
    { lat: 49.084593, lng: 21.3352734 },
    { lat: 49.085087, lng: 21.3356418 },
    { lat: 49.0850991, lng: 21.3363467 },
    { lat: 49.0901725, lng: 21.3376219 },
    { lat: 49.0908063, lng: 21.3379684 },
    { lat: 49.0911153, lng: 21.3382561 },
    { lat: 49.0922872, lng: 21.3379043 },
    { lat: 49.0928552, lng: 21.3386813 },
    { lat: 49.0934436, lng: 21.3393542 },
    { lat: 49.0939809, lng: 21.3393761 },
    { lat: 49.094205, lng: 21.3402459 },
    { lat: 49.0944759, lng: 21.3408375 },
    { lat: 49.0956736, lng: 21.3404811 },
    { lat: 49.0959839, lng: 21.3413701 },
    { lat: 49.0969193, lng: 21.3428143 },
    { lat: 49.0987469, lng: 21.3450778 },
    { lat: 49.0999608, lng: 21.347031 },
    { lat: 49.0999258, lng: 21.3470665 },
    { lat: 49.1005067, lng: 21.3472295 },
    { lat: 49.1008415, lng: 21.347584 },
    { lat: 49.1010882, lng: 21.3477055 },
    { lat: 49.1014945, lng: 21.3474874 },
    { lat: 49.1020563, lng: 21.347349 },
    { lat: 49.102481, lng: 21.3478561 },
    { lat: 49.1032779, lng: 21.3482745 },
    { lat: 49.1053697, lng: 21.350016 },
    { lat: 49.1056063, lng: 21.3504392 },
    { lat: 49.105755, lng: 21.3505955 },
    { lat: 49.1062085, lng: 21.350827 },
    { lat: 49.1066643, lng: 21.3512095 },
    { lat: 49.1070559, lng: 21.3516586 },
    { lat: 49.1071382, lng: 21.3519654 },
    { lat: 49.107095, lng: 21.3524395 },
    { lat: 49.1072078, lng: 21.3526799 },
    { lat: 49.1075566, lng: 21.352942 },
    { lat: 49.107814, lng: 21.3532312 },
    { lat: 49.1087749, lng: 21.3546286 },
    { lat: 49.1092279, lng: 21.3549937 },
    { lat: 49.1101696, lng: 21.3554846 },
    { lat: 49.110393, lng: 21.3553136 },
    { lat: 49.1106528, lng: 21.355463 },
    { lat: 49.1110454, lng: 21.3553461 },
    { lat: 49.1112284, lng: 21.3551808 },
    { lat: 49.1117294, lng: 21.3551878 },
    { lat: 49.1124074, lng: 21.3555478 },
    { lat: 49.1125733, lng: 21.3555285 },
    { lat: 49.1126736, lng: 21.3556117 },
    { lat: 49.112731, lng: 21.35577 },
    { lat: 49.112748, lng: 21.355766 },
    { lat: 49.112883, lng: 21.355742 },
    { lat: 49.113052, lng: 21.355881 },
    { lat: 49.113072, lng: 21.355811 },
    { lat: 49.113177, lng: 21.355614 },
    { lat: 49.113272, lng: 21.355213 },
    { lat: 49.113261, lng: 21.354834 },
    { lat: 49.113431, lng: 21.354556 },
    { lat: 49.11344, lng: 21.35435 },
    { lat: 49.113335, lng: 21.354072 },
    { lat: 49.113191, lng: 21.353717 },
    { lat: 49.11312, lng: 21.353395 },
    { lat: 49.113558, lng: 21.352369 },
    { lat: 49.113598, lng: 21.35209 },
    { lat: 49.113746, lng: 21.35156 },
    { lat: 49.113467, lng: 21.351133 },
    { lat: 49.113929, lng: 21.350388 },
    { lat: 49.113967, lng: 21.350231 },
    { lat: 49.1134265, lng: 21.3498003 },
    { lat: 49.1129383, lng: 21.3499624 },
    { lat: 49.1124027, lng: 21.3499183 },
    { lat: 49.1116566, lng: 21.3496158 },
    { lat: 49.1110287, lng: 21.3492366 },
    { lat: 49.1102879, lng: 21.3476807 },
    { lat: 49.1099981, lng: 21.3473172 },
    { lat: 49.1095365, lng: 21.3464823 },
    { lat: 49.1092794, lng: 21.3454449 },
    { lat: 49.109083, lng: 21.3448627 },
    { lat: 49.1089102, lng: 21.3444233 },
    { lat: 49.1085687, lng: 21.3439916 },
    { lat: 49.108184, lng: 21.3437662 },
    { lat: 49.1078189, lng: 21.3436784 },
    { lat: 49.107343, lng: 21.3437121 },
    { lat: 49.1065873, lng: 21.3441068 },
    { lat: 49.1059439, lng: 21.3442965 },
    { lat: 49.1052599, lng: 21.3443055 },
    { lat: 49.1036393, lng: 21.3438789 },
    { lat: 49.1029752, lng: 21.3439142 },
    { lat: 49.1027439, lng: 21.3435887 },
    { lat: 49.1023267, lng: 21.3432151 },
    { lat: 49.1020838, lng: 21.3427343 },
    { lat: 49.1014983, lng: 21.337683 },
    { lat: 49.1013058, lng: 21.337003 },
    { lat: 49.1008061, lng: 21.3359867 },
    { lat: 49.100698, lng: 21.3351256 },
    { lat: 49.1006085, lng: 21.3351735 },
    { lat: 49.100953, lng: 21.3320298 },
    { lat: 49.1010162, lng: 21.3310275 },
    { lat: 49.1010091, lng: 21.3304517 },
    { lat: 49.1008936, lng: 21.3299825 },
    { lat: 49.1006515, lng: 21.3293727 },
    { lat: 49.0985362, lng: 21.3245434 },
    { lat: 49.0983091, lng: 21.3241829 },
    { lat: 49.098224, lng: 21.3212743 },
    { lat: 49.0982892, lng: 21.3211225 },
    { lat: 49.0982306, lng: 21.3211169 },
    { lat: 49.098274, lng: 21.3202812 },
    { lat: 49.0980056, lng: 21.3196971 },
    { lat: 49.0976032, lng: 21.3192717 },
    { lat: 49.0974448, lng: 21.3184178 },
    { lat: 49.0974452, lng: 21.317798 },
    { lat: 49.0973084, lng: 21.3174751 },
    { lat: 49.0972598, lng: 21.3171697 },
    { lat: 49.0971846, lng: 21.3165771 },
    { lat: 49.0971929, lng: 21.3162957 },
    { lat: 49.0970068, lng: 21.3157543 },
    { lat: 49.0969385, lng: 21.3147573 },
    { lat: 49.0972352, lng: 21.3141499 },
    { lat: 49.0972506, lng: 21.3142544 },
    { lat: 49.0974622, lng: 21.3132485 },
    { lat: 49.0975124, lng: 21.3125208 },
    { lat: 49.0976782, lng: 21.3114919 },
    { lat: 49.0974135, lng: 21.3108143 },
    { lat: 49.0973955, lng: 21.3105527 },
    { lat: 49.0973037, lng: 21.3101918 },
    { lat: 49.0969316, lng: 21.3097223 },
    { lat: 49.0974728, lng: 21.3078999 },
    { lat: 49.0975613, lng: 21.3072158 },
    { lat: 49.0976435, lng: 21.3069558 },
    { lat: 49.0974242, lng: 21.3068846 },
    { lat: 49.0980485, lng: 21.3059069 },
    { lat: 49.0984156, lng: 21.3057339 },
    { lat: 49.0992112, lng: 21.3056279 },
    { lat: 49.0998253, lng: 21.3054663 },
    { lat: 49.1001703, lng: 21.305078 },
    { lat: 49.1007897, lng: 21.3047286 },
    { lat: 49.1009127, lng: 21.3046241 },
    { lat: 49.1017034, lng: 21.3034271 },
    { lat: 49.1003569, lng: 21.3003928 },
    { lat: 49.0997505, lng: 21.2992806 },
    { lat: 49.0994499, lng: 21.2988985 },
    { lat: 49.0988815, lng: 21.2984497 },
    { lat: 49.0987833, lng: 21.2982146 },
    { lat: 49.0981001, lng: 21.2977969 },
    { lat: 49.0975466, lng: 21.2967291 },
    { lat: 49.0971528, lng: 21.2964293 },
    { lat: 49.0966971, lng: 21.2962086 },
    { lat: 49.0964593, lng: 21.29597 },
    { lat: 49.0957415, lng: 21.2948263 },
    { lat: 49.0956443, lng: 21.2944819 },
    { lat: 49.0955027, lng: 21.2943382 },
    { lat: 49.0952792, lng: 21.2942746 },
    { lat: 49.0950556, lng: 21.2942113 },
    { lat: 49.0949119, lng: 21.2934466 },
    { lat: 49.0942425, lng: 21.2916687 },
    { lat: 49.0939291, lng: 21.2906502 },
    { lat: 49.0934446, lng: 21.2869241 },
    { lat: 49.0931567, lng: 21.286297 },
    { lat: 49.0911884, lng: 21.2881774 },
    { lat: 49.0909821, lng: 21.2880928 },
    { lat: 49.0904445, lng: 21.2883264 },
    { lat: 49.0892951, lng: 21.2890407 },
    { lat: 49.0883471, lng: 21.2904332 },
    { lat: 49.0881379, lng: 21.2906185 },
    { lat: 49.0878535, lng: 21.2907024 },
    { lat: 49.0864002, lng: 21.2904274 },
    { lat: 49.0861888, lng: 21.2904703 },
    { lat: 49.0858548, lng: 21.290846 },
    { lat: 49.0847893, lng: 21.2926265 },
    { lat: 49.0844353, lng: 21.2942697 },
    { lat: 49.0830928, lng: 21.295712 },
    { lat: 49.0816946, lng: 21.2966919 },
    { lat: 49.0812971, lng: 21.2956035 },
    { lat: 49.0810065, lng: 21.2950787 },
    { lat: 49.0804727, lng: 21.295323 },
    { lat: 49.0797139, lng: 21.2954614 },
    { lat: 49.0791194, lng: 21.2954106 },
    { lat: 49.0786055, lng: 21.2952371 },
    { lat: 49.0782402, lng: 21.2950233 },
    { lat: 49.0770301, lng: 21.293415 },
    { lat: 49.0767047, lng: 21.2931138 },
    { lat: 49.0764549, lng: 21.293 },
    { lat: 49.0762723, lng: 21.2927343 },
    { lat: 49.0760038, lng: 21.2920357 },
    { lat: 49.0759411, lng: 21.2913847 },
    { lat: 49.0758445, lng: 21.2909052 },
    { lat: 49.0751362, lng: 21.2900373 },
    { lat: 49.0748423, lng: 21.290035 },
    { lat: 49.0742315, lng: 21.2893696 },
    { lat: 49.0742508, lng: 21.2897487 },
    { lat: 49.0741772, lng: 21.2897721 },
    { lat: 49.0737028, lng: 21.2891118 },
    { lat: 49.0736374, lng: 21.2887054 },
    { lat: 49.0731922, lng: 21.2882311 },
    { lat: 49.0725786, lng: 21.2872235 },
    { lat: 49.0723878, lng: 21.2865385 },
    { lat: 49.0721272, lng: 21.2864442 },
    { lat: 49.0720645, lng: 21.2863431 },
    { lat: 49.0718449, lng: 21.2857441 },
    { lat: 49.0718346, lng: 21.2852202 },
    { lat: 49.0717472, lng: 21.2847174 },
    { lat: 49.0712618, lng: 21.2836291 },
    { lat: 49.0710964, lng: 21.2834082 },
    { lat: 49.0707394, lng: 21.2832837 },
    { lat: 49.0706826, lng: 21.2832697 },
    { lat: 49.0705642, lng: 21.2834478 },
    { lat: 49.0704928, lng: 21.283426 },
    { lat: 49.0700721, lng: 21.2829898 },
    { lat: 49.0697939, lng: 21.2828372 },
    { lat: 49.0697141, lng: 21.2823529 },
    { lat: 49.069764, lng: 21.281964 },
    { lat: 49.0698384, lng: 21.28171 },
    { lat: 49.070028, lng: 21.2814652 },
    { lat: 49.0700605, lng: 21.2808366 },
    { lat: 49.0700029, lng: 21.280198 },
    { lat: 49.070039, lng: 21.2797583 },
    { lat: 49.0699691, lng: 21.2790914 },
    { lat: 49.0691097, lng: 21.2764778 },
    { lat: 49.0687212, lng: 21.2760635 },
    { lat: 49.0686341, lng: 21.2760165 },
    { lat: 49.068072, lng: 21.277547 },
    { lat: 49.0676674, lng: 21.2791473 },
    { lat: 49.0675699, lng: 21.280196 },
    { lat: 49.0673635, lng: 21.2809914 },
    { lat: 49.067142, lng: 21.2815533 },
    { lat: 49.0670075, lng: 21.2824947 },
    { lat: 49.0674048, lng: 21.2828262 },
    { lat: 49.0674444, lng: 21.2831665 },
    { lat: 49.0673847, lng: 21.2839391 },
    { lat: 49.0670907, lng: 21.2846301 },
    { lat: 49.0669686, lng: 21.2847241 },
    { lat: 49.0663371, lng: 21.2861958 },
    { lat: 49.0650734, lng: 21.2886747 },
    { lat: 49.064028, lng: 21.2910061 },
    { lat: 49.0638162, lng: 21.2918478 },
    { lat: 49.0637873, lng: 21.2922628 },
    { lat: 49.063584, lng: 21.2929068 },
    { lat: 49.0633662, lng: 21.2932584 },
    { lat: 49.0630393, lng: 21.2936438 },
    { lat: 49.0628251, lng: 21.2941526 },
    { lat: 49.0626031, lng: 21.2949192 },
    { lat: 49.0624424, lng: 21.295224 },
    { lat: 49.0623093, lng: 21.2957339 },
    { lat: 49.0618658, lng: 21.2964332 },
    { lat: 49.0615436, lng: 21.2972312 },
    { lat: 49.0613485, lng: 21.2978894 },
    { lat: 49.0613147, lng: 21.2981433 },
    { lat: 49.0611308, lng: 21.2984999 },
    { lat: 49.060957, lng: 21.2990044 },
    { lat: 49.0605802, lng: 21.2998601 },
    { lat: 49.0600839, lng: 21.3006789 },
    { lat: 49.059891, lng: 21.3012134 },
    { lat: 49.0598228, lng: 21.3015619 },
    { lat: 49.0626233, lng: 21.305911 },
    { lat: 49.0630193, lng: 21.3066149 },
    { lat: 49.0631505, lng: 21.3067442 },
    { lat: 49.0634051, lng: 21.3072807 },
    { lat: 49.0637355, lng: 21.3083412 },
    { lat: 49.0630735, lng: 21.3093736 },
    { lat: 49.0625506, lng: 21.3100152 },
    { lat: 49.0621521, lng: 21.3104231 },
    { lat: 49.0614973, lng: 21.3108679 },
    { lat: 49.0611465, lng: 21.3113582 },
    { lat: 49.0603748, lng: 21.3120795 },
    { lat: 49.0596792, lng: 21.313375 },
    { lat: 49.0586309, lng: 21.3138358 },
    { lat: 49.0586111, lng: 21.3138515 },
    { lat: 49.0586136, lng: 21.3141229 },
    { lat: 49.0588245, lng: 21.3145466 },
    { lat: 49.0593736, lng: 21.315031 },
    { lat: 49.0595186, lng: 21.3152882 },
    { lat: 49.059741, lng: 21.3154926 },
    { lat: 49.0599026, lng: 21.3155566 },
    { lat: 49.0599491, lng: 21.3157941 },
    { lat: 49.060181, lng: 21.3159835 },
    { lat: 49.0608886, lng: 21.3158993 },
    { lat: 49.0611593, lng: 21.3160002 },
    { lat: 49.0614944, lng: 21.3157307 },
    { lat: 49.0618059, lng: 21.3155729 },
    { lat: 49.0622215, lng: 21.3154771 },
    { lat: 49.0626098, lng: 21.3152175 },
    { lat: 49.0628765, lng: 21.3151806 },
    { lat: 49.0647342, lng: 21.3153654 },
    { lat: 49.0649386, lng: 21.3153544 },
    { lat: 49.0652767, lng: 21.3152213 },
    { lat: 49.0656625, lng: 21.3152553 },
    { lat: 49.066068, lng: 21.3151976 },
    { lat: 49.0663914, lng: 21.315321 },
    { lat: 49.0669309, lng: 21.3152668 },
    { lat: 49.0676531, lng: 21.3149598 },
    { lat: 49.0681104, lng: 21.3146052 },
    { lat: 49.0689959, lng: 21.3144134 },
    { lat: 49.0690942, lng: 21.3142016 },
    { lat: 49.068702, lng: 21.3138468 },
    { lat: 49.0688169, lng: 21.3136307 },
    { lat: 49.0687251, lng: 21.3131993 },
    { lat: 49.0688026, lng: 21.3130779 },
    { lat: 49.0688844, lng: 21.3126258 },
    { lat: 49.0689456, lng: 21.3121144 },
    { lat: 49.0688844, lng: 21.3118768 },
    { lat: 49.0689623, lng: 21.311552 },
    { lat: 49.0691647, lng: 21.3116161 },
    { lat: 49.0691824, lng: 21.3114211 },
    { lat: 49.0692498, lng: 21.3113826 },
    { lat: 49.0694662, lng: 21.3114658 },
    { lat: 49.0698637, lng: 21.3114401 },
    { lat: 49.0700774, lng: 21.3106945 },
    { lat: 49.0701489, lng: 21.3100407 },
    { lat: 49.0704676, lng: 21.3099053 },
    { lat: 49.0706121, lng: 21.3096225 },
    { lat: 49.0707733, lng: 21.3091123 },
    { lat: 49.0709404, lng: 21.3081582 },
    { lat: 49.0711818, lng: 21.3082188 },
    { lat: 49.0713759, lng: 21.3083851 },
    { lat: 49.0714289, lng: 21.3082425 },
    { lat: 49.0716951, lng: 21.308259 },
    { lat: 49.0719645, lng: 21.3084651 },
    { lat: 49.0724047, lng: 21.3086615 },
    { lat: 49.0726268, lng: 21.3086373 },
    { lat: 49.072747, lng: 21.3082898 },
    { lat: 49.0727909, lng: 21.3079967 },
    { lat: 49.0727862, lng: 21.3073962 },
    { lat: 49.0729544, lng: 21.3068145 },
    { lat: 49.0735661, lng: 21.3070283 },
    { lat: 49.0738316, lng: 21.3070269 },
    { lat: 49.0742286, lng: 21.3066111 },
    { lat: 49.0741874, lng: 21.306918 },
    { lat: 49.0739382, lng: 21.307682 },
    { lat: 49.0740349, lng: 21.3080513 },
    { lat: 49.0740108, lng: 21.3084087 },
    { lat: 49.0739207, lng: 21.3087014 },
    { lat: 49.0738994, lng: 21.3093012 },
    { lat: 49.0737984, lng: 21.3098685 },
    { lat: 49.0739014, lng: 21.3100773 },
    { lat: 49.0739015, lng: 21.3101911 },
    { lat: 49.0738265, lng: 21.3105807 },
    { lat: 49.0737986, lng: 21.3115122 },
    { lat: 49.0739292, lng: 21.3117773 },
    { lat: 49.073925, lng: 21.3123493 },
    { lat: 49.0741185, lng: 21.3142142 },
    { lat: 49.074, lng: 21.3159144 },
    { lat: 49.0738991, lng: 21.3164835 },
    { lat: 49.0737127, lng: 21.3169878 },
    { lat: 49.072955, lng: 21.3183151 },
    { lat: 49.0726176, lng: 21.3186197 },
    { lat: 49.0720921, lng: 21.3195134 },
    { lat: 49.0719832, lng: 21.3196323 },
    { lat: 49.0718362, lng: 21.3196849 },
    { lat: 49.0735519, lng: 21.3223254 },
    { lat: 49.0729039, lng: 21.3233428 },
    { lat: 49.0726516, lng: 21.3241158 },
    { lat: 49.0725191, lng: 21.3243519 },
    { lat: 49.0721821, lng: 21.324782 },
    { lat: 49.0717946, lng: 21.3255164 },
    { lat: 49.0713252, lng: 21.3261125 },
    { lat: 49.0701172, lng: 21.3265333 },
    { lat: 49.0695151, lng: 21.3268336 },
    { lat: 49.0708017, lng: 21.3293185 },
    { lat: 49.0708959, lng: 21.3295732 },
    { lat: 49.0709646, lng: 21.3296061 },
    { lat: 49.07167, lng: 21.3309154 },
    { lat: 49.0720659, lng: 21.3305401 },
    { lat: 49.0721888, lng: 21.3303212 },
    { lat: 49.0722409, lng: 21.3300721 },
    { lat: 49.0724122, lng: 21.3297767 },
    { lat: 49.0736985, lng: 21.3283589 },
    { lat: 49.0740283, lng: 21.3278396 },
    { lat: 49.0742858, lng: 21.3277219 },
    { lat: 49.0746535, lng: 21.327262 },
    { lat: 49.0749537, lng: 21.3279693 },
    { lat: 49.0745586, lng: 21.3284121 },
    { lat: 49.0746846, lng: 21.3292018 },
    { lat: 49.0753657, lng: 21.3316477 },
    { lat: 49.0756249, lng: 21.3322628 },
    { lat: 49.0765514, lng: 21.3347857 },
  ],
  Sedlice: [
    { lat: 48.9423176, lng: 21.1273245 },
    { lat: 48.9421982, lng: 21.1272577 },
    { lat: 48.9421272, lng: 21.1270796 },
    { lat: 48.9417468, lng: 21.12693 },
    { lat: 48.9413516, lng: 21.1266798 },
    { lat: 48.9413027, lng: 21.1264567 },
    { lat: 48.9411201, lng: 21.1264462 },
    { lat: 48.9410174, lng: 21.1262586 },
    { lat: 48.9408674, lng: 21.1262489 },
    { lat: 48.9407828, lng: 21.1259941 },
    { lat: 48.9404575, lng: 21.1257821 },
    { lat: 48.9401594, lng: 21.1252946 },
    { lat: 48.9395834, lng: 21.1249804 },
    { lat: 48.9389709, lng: 21.1249047 },
    { lat: 48.9383168, lng: 21.1246482 },
    { lat: 48.9380132, lng: 21.1246909 },
    { lat: 48.9377378, lng: 21.1245228 },
    { lat: 48.9373745, lng: 21.1245617 },
    { lat: 48.9369642, lng: 21.1247197 },
    { lat: 48.9371511, lng: 21.1237691 },
    { lat: 48.9369717, lng: 21.1229425 },
    { lat: 48.9372258, lng: 21.1224084 },
    { lat: 48.9373212, lng: 21.121946 },
    { lat: 48.9371373, lng: 21.1214366 },
    { lat: 48.9384193, lng: 21.1196451 },
    { lat: 48.9384923, lng: 21.1189338 },
    { lat: 48.9387206, lng: 21.1182236 },
    { lat: 48.9391266, lng: 21.1173367 },
    { lat: 48.9395025, lng: 21.1159619 },
    { lat: 48.9395033, lng: 21.1149709 },
    { lat: 48.9393845, lng: 21.1135059 },
    { lat: 48.9395463, lng: 21.1125098 },
    { lat: 48.9392399, lng: 21.1123996 },
    { lat: 48.9392642, lng: 21.1112551 },
    { lat: 48.9387434, lng: 21.1111962 },
    { lat: 48.9388041, lng: 21.1096441 },
    { lat: 48.9390798, lng: 21.1088706 },
    { lat: 48.9394359, lng: 21.1089224 },
    { lat: 48.9396069, lng: 21.1083563 },
    { lat: 48.939731, lng: 21.1073125 },
    { lat: 48.939477, lng: 21.1070113 },
    { lat: 48.9394117, lng: 21.1064823 },
    { lat: 48.9394084, lng: 21.1056069 },
    { lat: 48.9396007, lng: 21.1048302 },
    { lat: 48.9396244, lng: 21.1044816 },
    { lat: 48.9396226, lng: 21.1041455 },
    { lat: 48.9395324, lng: 21.1038247 },
    { lat: 48.9394855, lng: 21.1032392 },
    { lat: 48.9397927, lng: 21.1027143 },
    { lat: 48.9395788, lng: 21.1017284 },
    { lat: 48.9393065, lng: 21.1015846 },
    { lat: 48.938997, lng: 21.1016931 },
    { lat: 48.9383795, lng: 21.101321 },
    { lat: 48.9382508, lng: 21.0999358 },
    { lat: 48.937735, lng: 21.0984873 },
    { lat: 48.9373473, lng: 21.0978412 },
    { lat: 48.9367731, lng: 21.0956994 },
    { lat: 48.9364067, lng: 21.0949494 },
    { lat: 48.9361446, lng: 21.0948793 },
    { lat: 48.9355361, lng: 21.0936665 },
    { lat: 48.9345809, lng: 21.0920682 },
    { lat: 48.934164, lng: 21.0904056 },
    { lat: 48.933374, lng: 21.0882399 },
    { lat: 48.9330616, lng: 21.0883232 },
    { lat: 48.9327777, lng: 21.0880375 },
    { lat: 48.9321143, lng: 21.0863476 },
    { lat: 48.931738, lng: 21.0848668 },
    { lat: 48.9317115, lng: 21.0843601 },
    { lat: 48.9316038, lng: 21.0843309 },
    { lat: 48.9314822, lng: 21.0836316 },
    { lat: 48.9313175, lng: 21.0833804 },
    { lat: 48.9312176, lng: 21.0831075 },
    { lat: 48.9311328, lng: 21.0828182 },
    { lat: 48.9307877, lng: 21.0828292 },
    { lat: 48.9310236, lng: 21.0834738 },
    { lat: 48.9312656, lng: 21.0839645 },
    { lat: 48.930839, lng: 21.084106 },
    { lat: 48.9304566, lng: 21.0843567 },
    { lat: 48.9301244, lng: 21.0839662 },
    { lat: 48.9299428, lng: 21.083848 },
    { lat: 48.9292768, lng: 21.0838262 },
    { lat: 48.9287671, lng: 21.0839622 },
    { lat: 48.9281966, lng: 21.0838696 },
    { lat: 48.9270258, lng: 21.0831553 },
    { lat: 48.926224, lng: 21.0843272 },
    { lat: 48.9260594, lng: 21.0840413 },
    { lat: 48.9257193, lng: 21.0843851 },
    { lat: 48.925473, lng: 21.0848425 },
    { lat: 48.925779, lng: 21.0853964 },
    { lat: 48.9252099, lng: 21.0865284 },
    { lat: 48.9251554, lng: 21.0869304 },
    { lat: 48.9252861, lng: 21.0872822 },
    { lat: 48.9254481, lng: 21.0879484 },
    { lat: 48.9255574, lng: 21.0890336 },
    { lat: 48.9221179, lng: 21.0922769 },
    { lat: 48.9198256, lng: 21.0936091 },
    { lat: 48.9172151, lng: 21.094539 },
    { lat: 48.9160065, lng: 21.0944013 },
    { lat: 48.9146806, lng: 21.0944655 },
    { lat: 48.9141537, lng: 21.0944294 },
    { lat: 48.9141079, lng: 21.0942964 },
    { lat: 48.9138378, lng: 21.0944392 },
    { lat: 48.9130372, lng: 21.0935192 },
    { lat: 48.9125443, lng: 21.093268 },
    { lat: 48.9119865, lng: 21.0932807 },
    { lat: 48.9117318, lng: 21.0931647 },
    { lat: 48.9103181, lng: 21.0919363 },
    { lat: 48.9096069, lng: 21.092672 },
    { lat: 48.909453, lng: 21.0924423 },
    { lat: 48.9092758, lng: 21.0923592 },
    { lat: 48.9089147, lng: 21.0918382 },
    { lat: 48.9085605, lng: 21.0911489 },
    { lat: 48.9084682, lng: 21.0907937 },
    { lat: 48.9084304, lng: 21.0902147 },
    { lat: 48.9084706, lng: 21.0897191 },
    { lat: 48.9086027, lng: 21.0885805 },
    { lat: 48.9087525, lng: 21.088338 },
    { lat: 48.9087286, lng: 21.0874864 },
    { lat: 48.908619, lng: 21.0865672 },
    { lat: 48.9085101, lng: 21.0862619 },
    { lat: 48.908175, lng: 21.0857519 },
    { lat: 48.9080834, lng: 21.0854575 },
    { lat: 48.9073777, lng: 21.0844451 },
    { lat: 48.9072543, lng: 21.0839695 },
    { lat: 48.9069515, lng: 21.0839695 },
    { lat: 48.9067618, lng: 21.0838401 },
    { lat: 48.9065372, lng: 21.0837892 },
    { lat: 48.89868, lng: 21.073523 },
    { lat: 48.898577, lng: 21.073872 },
    { lat: 48.898179, lng: 21.074891 },
    { lat: 48.89804, lng: 21.075147 },
    { lat: 48.897794, lng: 21.075551 },
    { lat: 48.897672, lng: 21.075663 },
    { lat: 48.897566, lng: 21.075725 },
    { lat: 48.897447, lng: 21.075921 },
    { lat: 48.89723, lng: 21.076143 },
    { lat: 48.89713, lng: 21.076289 },
    { lat: 48.897063, lng: 21.076395 },
    { lat: 48.896925, lng: 21.076752 },
    { lat: 48.896657, lng: 21.077534 },
    { lat: 48.896579, lng: 21.077828 },
    { lat: 48.896548, lng: 21.078091 },
    { lat: 48.896519, lng: 21.078303 },
    { lat: 48.896577, lng: 21.07884 },
    { lat: 48.896693, lng: 21.079448 },
    { lat: 48.896779, lng: 21.079773 },
    { lat: 48.896896, lng: 21.080089 },
    { lat: 48.896975, lng: 21.080584 },
    { lat: 48.897055, lng: 21.080881 },
    { lat: 48.897141, lng: 21.081075 },
    { lat: 48.897212, lng: 21.081191 },
    { lat: 48.897198, lng: 21.081217 },
    { lat: 48.897253, lng: 21.081825 },
    { lat: 48.897426, lng: 21.08192 },
    { lat: 48.897602, lng: 21.081945 },
    { lat: 48.897684, lng: 21.082012 },
    { lat: 48.897813, lng: 21.082191 },
    { lat: 48.898148, lng: 21.082926 },
    { lat: 48.898225, lng: 21.083347 },
    { lat: 48.898259, lng: 21.083745 },
    { lat: 48.898288, lng: 21.084026 },
    { lat: 48.898392, lng: 21.084488 },
    { lat: 48.898443, lng: 21.084829 },
    { lat: 48.898465, lng: 21.084834 },
    { lat: 48.898326, lng: 21.085598 },
    { lat: 48.898379, lng: 21.08586 },
    { lat: 48.898377, lng: 21.086533 },
    { lat: 48.898425, lng: 21.087038 },
    { lat: 48.898382, lng: 21.087367 },
    { lat: 48.898362, lng: 21.087991 },
    { lat: 48.898385, lng: 21.08823 },
    { lat: 48.898455, lng: 21.088506 },
    { lat: 48.898462, lng: 21.08872 },
    { lat: 48.898343, lng: 21.089184 },
    { lat: 48.898145, lng: 21.089772 },
    { lat: 48.897929, lng: 21.09048 },
    { lat: 48.897913, lng: 21.090475 },
    { lat: 48.897754, lng: 21.090635 },
    { lat: 48.897557, lng: 21.090961 },
    { lat: 48.897402, lng: 21.091271 },
    { lat: 48.897331, lng: 21.091506 },
    { lat: 48.897193, lng: 21.091693 },
    { lat: 48.896997, lng: 21.091851 },
    { lat: 48.897004, lng: 21.091878 },
    { lat: 48.89682, lng: 21.09206 },
    { lat: 48.896603, lng: 21.09244 },
    { lat: 48.896588, lng: 21.092415 },
    { lat: 48.896285, lng: 21.092762 },
    { lat: 48.896066, lng: 21.093195 },
    { lat: 48.895938, lng: 21.093612 },
    { lat: 48.895764, lng: 21.094037 },
    { lat: 48.895518, lng: 21.094508 },
    { lat: 48.895427, lng: 21.094689 },
    { lat: 48.895358, lng: 21.094825 },
    { lat: 48.895353, lng: 21.094837 },
    { lat: 48.895372, lng: 21.094868 },
    { lat: 48.895234, lng: 21.095134 },
    { lat: 48.895149, lng: 21.095356 },
    { lat: 48.894952, lng: 21.096037 },
    { lat: 48.894658, lng: 21.096964 },
    { lat: 48.894511, lng: 21.097356 },
    { lat: 48.894344, lng: 21.097733 },
    { lat: 48.894245, lng: 21.098088 },
    { lat: 48.894217, lng: 21.098143 },
    { lat: 48.894049, lng: 21.09838 },
    { lat: 48.893926, lng: 21.098652 },
    { lat: 48.893938, lng: 21.098667 },
    { lat: 48.893963, lng: 21.098703 },
    { lat: 48.894212, lng: 21.099252 },
    { lat: 48.894549, lng: 21.099845 },
    { lat: 48.894733, lng: 21.100166 },
    { lat: 48.894937, lng: 21.1006 },
    { lat: 48.895107, lng: 21.101042 },
    { lat: 48.89529, lng: 21.101438 },
    { lat: 48.895613, lng: 21.101962 },
    { lat: 48.895731, lng: 21.102152 },
    { lat: 48.895991, lng: 21.102993 },
    { lat: 48.896135, lng: 21.103353 },
    { lat: 48.896359, lng: 21.104108 },
    { lat: 48.897273, lng: 21.105791 },
    { lat: 48.897232, lng: 21.106164 },
    { lat: 48.896824, lng: 21.107989 },
    { lat: 48.896695, lng: 21.109606 },
    { lat: 48.897043, lng: 21.109925 },
    { lat: 48.896753, lng: 21.11229 },
    { lat: 48.896071, lng: 21.112765 },
    { lat: 48.896021, lng: 21.112807 },
    { lat: 48.896003, lng: 21.112821 },
    { lat: 48.895982, lng: 21.112832 },
    { lat: 48.895981, lng: 21.113092 },
    { lat: 48.895894, lng: 21.113481 },
    { lat: 48.895587, lng: 21.114555 },
    { lat: 48.89552, lng: 21.114798 },
    { lat: 48.895452, lng: 21.116613 },
    { lat: 48.8953, lng: 21.117204 },
    { lat: 48.89491, lng: 21.117893 },
    { lat: 48.894866, lng: 21.118104 },
    { lat: 48.894886, lng: 21.11813 },
    { lat: 48.894922, lng: 21.118161 },
    { lat: 48.895043, lng: 21.118328 },
    { lat: 48.895542, lng: 21.118427 },
    { lat: 48.895961, lng: 21.118258 },
    { lat: 48.897282, lng: 21.118276 },
    { lat: 48.897701, lng: 21.11877 },
    { lat: 48.898031, lng: 21.119447 },
    { lat: 48.89811, lng: 21.119758 },
    { lat: 48.898277, lng: 21.120138 },
    { lat: 48.898546, lng: 21.120646 },
    { lat: 48.899219, lng: 21.12142 },
    { lat: 48.899438, lng: 21.122402 },
    { lat: 48.899671, lng: 21.123157 },
    { lat: 48.89988, lng: 21.124395 },
    { lat: 48.899894, lng: 21.124476 },
    { lat: 48.900074, lng: 21.125537 },
    { lat: 48.90022, lng: 21.126053 },
    { lat: 48.900282, lng: 21.127675 },
    { lat: 48.900416, lng: 21.129221 },
    { lat: 48.900588, lng: 21.130577 },
    { lat: 48.900561, lng: 21.131342 },
    { lat: 48.900627, lng: 21.13129 },
    { lat: 48.900689, lng: 21.131341 },
    { lat: 48.900771, lng: 21.13137 },
    { lat: 48.900983, lng: 21.131433 },
    { lat: 48.901044, lng: 21.131451 },
    { lat: 48.901108, lng: 21.131515 },
    { lat: 48.9012, lng: 21.131568 },
    { lat: 48.901296, lng: 21.131552 },
    { lat: 48.901384, lng: 21.131657 },
    { lat: 48.901478, lng: 21.131807 },
    { lat: 48.901572, lng: 21.131943 },
    { lat: 48.901579, lng: 21.131954 },
    { lat: 48.901584, lng: 21.131976 },
    { lat: 48.901632, lng: 21.132202 },
    { lat: 48.901617, lng: 21.132496 },
    { lat: 48.901593, lng: 21.132965 },
    { lat: 48.901589, lng: 21.133073 },
    { lat: 48.901478, lng: 21.133242 },
    { lat: 48.9014, lng: 21.1334 },
    { lat: 48.901428, lng: 21.133649 },
    { lat: 48.901223, lng: 21.134347 },
    { lat: 48.901098, lng: 21.13446 },
    { lat: 48.901183, lng: 21.134527 },
    { lat: 48.901235, lng: 21.134703 },
    { lat: 48.901324, lng: 21.134683 },
    { lat: 48.901397, lng: 21.134706 },
    { lat: 48.901412, lng: 21.134764 },
    { lat: 48.901436, lng: 21.134934 },
    { lat: 48.901394, lng: 21.135073 },
    { lat: 48.901324, lng: 21.135196 },
    { lat: 48.901383, lng: 21.135309 },
    { lat: 48.901366, lng: 21.13541 },
    { lat: 48.901271, lng: 21.135538 },
    { lat: 48.90125, lng: 21.135691 },
    { lat: 48.901128, lng: 21.13588 },
    { lat: 48.900931, lng: 21.135871 },
    { lat: 48.90094, lng: 21.136044 },
    { lat: 48.900886, lng: 21.136365 },
    { lat: 48.900725, lng: 21.136347 },
    { lat: 48.9006, lng: 21.136486 },
    { lat: 48.900697, lng: 21.136829 },
    { lat: 48.900594, lng: 21.13719 },
    { lat: 48.900572, lng: 21.13727 },
    { lat: 48.9008224, lng: 21.1378867 },
    { lat: 48.9013564, lng: 21.1382622 },
    { lat: 48.9016353, lng: 21.1387458 },
    { lat: 48.9019815, lng: 21.1388074 },
    { lat: 48.9025919, lng: 21.1392753 },
    { lat: 48.9030004, lng: 21.1393513 },
    { lat: 48.9037174, lng: 21.1397789 },
    { lat: 48.9044829, lng: 21.140638 },
    { lat: 48.9085849, lng: 21.141305 },
    { lat: 48.9086466, lng: 21.1410898 },
    { lat: 48.9086773, lng: 21.1405996 },
    { lat: 48.9076562, lng: 21.1397781 },
    { lat: 48.9077023, lng: 21.1389562 },
    { lat: 48.9078145, lng: 21.1383469 },
    { lat: 48.9079247, lng: 21.1380598 },
    { lat: 48.9079322, lng: 21.1363821 },
    { lat: 48.9081654, lng: 21.1365013 },
    { lat: 48.9081932, lng: 21.1345427 },
    { lat: 48.9082936, lng: 21.1333108 },
    { lat: 48.9098172, lng: 21.1316442 },
    { lat: 48.9157332, lng: 21.1289204 },
    { lat: 48.9158701, lng: 21.1289365 },
    { lat: 48.9159675, lng: 21.1293816 },
    { lat: 48.9171228, lng: 21.1293308 },
    { lat: 48.9188313, lng: 21.1303638 },
    { lat: 48.9195513, lng: 21.1312009 },
    { lat: 48.9201218, lng: 21.1317645 },
    { lat: 48.9218641, lng: 21.1314518 },
    { lat: 48.9266686, lng: 21.1330073 },
    { lat: 48.9276292, lng: 21.1343058 },
    { lat: 48.9297798, lng: 21.1358207 },
    { lat: 48.9299846, lng: 21.1346539 },
    { lat: 48.9302631, lng: 21.1348451 },
    { lat: 48.9304274, lng: 21.1354515 },
    { lat: 48.9311419, lng: 21.133782 },
    { lat: 48.9320637, lng: 21.1326901 },
    { lat: 48.9320867, lng: 21.1321766 },
    { lat: 48.9325388, lng: 21.1315242 },
    { lat: 48.9330088, lng: 21.131237 },
    { lat: 48.9336688, lng: 21.1319213 },
    { lat: 48.9343066, lng: 21.1328653 },
    { lat: 48.9344414, lng: 21.1332481 },
    { lat: 48.934746, lng: 21.1338006 },
    { lat: 48.9355535, lng: 21.1343821 },
    { lat: 48.9361196, lng: 21.1350229 },
    { lat: 48.9367476, lng: 21.1353033 },
    { lat: 48.9369895, lng: 21.1350945 },
    { lat: 48.9377085, lng: 21.1329663 },
    { lat: 48.93815, lng: 21.1332933 },
    { lat: 48.9388713, lng: 21.1335404 },
    { lat: 48.9390328, lng: 21.1336957 },
    { lat: 48.9391045, lng: 21.1336139 },
    { lat: 48.9396751, lng: 21.1330848 },
    { lat: 48.9398451, lng: 21.1328114 },
    { lat: 48.9402727, lng: 21.1313903 },
    { lat: 48.9402783, lng: 21.1310677 },
    { lat: 48.9406271, lng: 21.1300483 },
    { lat: 48.9412879, lng: 21.1293798 },
    { lat: 48.9423176, lng: 21.1273245 },
  ],
  Brežany: [
    { lat: 48.9909744, lng: 21.1014859 },
    { lat: 48.9900256, lng: 21.1014099 },
    { lat: 48.9891122, lng: 21.1012181 },
    { lat: 48.9890228, lng: 21.1012681 },
    { lat: 48.988523, lng: 21.1012295 },
    { lat: 48.9880876, lng: 21.1010564 },
    { lat: 48.9879384, lng: 21.1008632 },
    { lat: 48.9871101, lng: 21.1006543 },
    { lat: 48.9870125, lng: 21.1007662 },
    { lat: 48.9866579, lng: 21.1007982 },
    { lat: 48.9865843, lng: 21.1007527 },
    { lat: 48.9859996, lng: 21.100978 },
    { lat: 48.9857401, lng: 21.1009887 },
    { lat: 48.9854946, lng: 21.1009176 },
    { lat: 48.9852375, lng: 21.1009721 },
    { lat: 48.9845807, lng: 21.1007377 },
    { lat: 48.9845218, lng: 21.1006575 },
    { lat: 48.9841844, lng: 21.1005961 },
    { lat: 48.9839438, lng: 21.1004978 },
    { lat: 48.9839442, lng: 21.1004082 },
    { lat: 48.9830939, lng: 21.100071 },
    { lat: 48.9832786, lng: 21.0994055 },
    { lat: 48.9830493, lng: 21.0992228 },
    { lat: 48.9829355, lng: 21.0991321 },
    { lat: 48.9812944, lng: 21.0973333 },
    { lat: 48.9809075, lng: 21.0971699 },
    { lat: 48.9804493, lng: 21.0971623 },
    { lat: 48.9801647, lng: 21.0968828 },
    { lat: 48.9797722, lng: 21.0969986 },
    { lat: 48.9793452, lng: 21.0970173 },
    { lat: 48.9790885, lng: 21.096456 },
    { lat: 48.9783591, lng: 21.096457 },
    { lat: 48.9769617, lng: 21.0960784 },
    { lat: 48.975556, lng: 21.0964609 },
    { lat: 48.9750305, lng: 21.0968495 },
    { lat: 48.9744516, lng: 21.0975347 },
    { lat: 48.9742976, lng: 21.0978936 },
    { lat: 48.974076, lng: 21.0981081 },
    { lat: 48.9739609, lng: 21.0981324 },
    { lat: 48.9737566, lng: 21.0983922 },
    { lat: 48.9735591, lng: 21.0984491 },
    { lat: 48.9734305, lng: 21.0986808 },
    { lat: 48.9729846, lng: 21.0989191 },
    { lat: 48.9728613, lng: 21.0990439 },
    { lat: 48.9725079, lng: 21.0994971 },
    { lat: 48.972291, lng: 21.0999598 },
    { lat: 48.9720484, lng: 21.100221 },
    { lat: 48.9717966, lng: 21.1002665 },
    { lat: 48.9717855, lng: 21.1003404 },
    { lat: 48.9716282, lng: 21.100384 },
    { lat: 48.9715016, lng: 21.100509 },
    { lat: 48.9708625, lng: 21.1016353 },
    { lat: 48.9707552, lng: 21.1019359 },
    { lat: 48.9702491, lng: 21.1024444 },
    { lat: 48.9696887, lng: 21.103352 },
    { lat: 48.9694508, lng: 21.1035884 },
    { lat: 48.9698925, lng: 21.1048328 },
    { lat: 48.9699327, lng: 21.1051755 },
    { lat: 48.9698521, lng: 21.1055006 },
    { lat: 48.9697052, lng: 21.1054505 },
    { lat: 48.9695575, lng: 21.1055421 },
    { lat: 48.9694919, lng: 21.1056737 },
    { lat: 48.9694961, lng: 21.1059829 },
    { lat: 48.9689519, lng: 21.1064931 },
    { lat: 48.9689403, lng: 21.106653 },
    { lat: 48.9686012, lng: 21.1074872 },
    { lat: 48.9685512, lng: 21.1077878 },
    { lat: 48.9686675, lng: 21.1087467 },
    { lat: 48.9685892, lng: 21.1090767 },
    { lat: 48.9684534, lng: 21.1093367 },
    { lat: 48.9684621, lng: 21.1094675 },
    { lat: 48.9682973, lng: 21.1099124 },
    { lat: 48.9680756, lng: 21.1102268 },
    { lat: 48.9679144, lng: 21.1108218 },
    { lat: 48.9677135, lng: 21.1119939 },
    { lat: 48.9675249, lng: 21.1127468 },
    { lat: 48.9667235, lng: 21.1148565 },
    { lat: 48.9666834, lng: 21.1152259 },
    { lat: 48.9684176, lng: 21.1158559 },
    { lat: 48.9692065, lng: 21.1170916 },
    { lat: 48.9695632, lng: 21.1173691 },
    { lat: 48.9698484, lng: 21.1178717 },
    { lat: 48.9704307, lng: 21.1187011 },
    { lat: 48.9709468, lng: 21.1197821 },
    { lat: 48.9711278, lng: 21.1200546 },
    { lat: 48.9717669, lng: 21.1207566 },
    { lat: 48.971945, lng: 21.1210838 },
    { lat: 48.9723072, lng: 21.1214669 },
    { lat: 48.9737321, lng: 21.1235888 },
    { lat: 48.9753494, lng: 21.1224273 },
    { lat: 48.9758784, lng: 21.1220369 },
    { lat: 48.9758271, lng: 21.1218419 },
    { lat: 48.9763372, lng: 21.1212874 },
    { lat: 48.9788981, lng: 21.1196181 },
    { lat: 48.97918, lng: 21.1195999 },
    { lat: 48.9799978, lng: 21.1189839 },
    { lat: 48.9803718, lng: 21.1189256 },
    { lat: 48.9807284, lng: 21.1187495 },
    { lat: 48.9809787, lng: 21.118552 },
    { lat: 48.9812063, lng: 21.1182726 },
    { lat: 48.9816803, lng: 21.1179997 },
    { lat: 48.9820267, lng: 21.1176718 },
    { lat: 48.9822976, lng: 21.1169625 },
    { lat: 48.9823931, lng: 21.1168498 },
    { lat: 48.9830896, lng: 21.1166035 },
    { lat: 48.9836395, lng: 21.1163071 },
    { lat: 48.9838134, lng: 21.1161467 },
    { lat: 48.9840115, lng: 21.1158304 },
    { lat: 48.9845376, lng: 21.1154577 },
    { lat: 48.9854763, lng: 21.1145109 },
    { lat: 48.9860522, lng: 21.1141857 },
    { lat: 48.9862449, lng: 21.1139128 },
    { lat: 48.9867679, lng: 21.1133719 },
    { lat: 48.9872957, lng: 21.1130574 },
    { lat: 48.9877359, lng: 21.1129582 },
    { lat: 48.9881383, lng: 21.1127673 },
    { lat: 48.9888006, lng: 21.1118706 },
    { lat: 48.989193, lng: 21.1109425 },
    { lat: 48.9892088, lng: 21.1107349 },
    { lat: 48.9894412, lng: 21.1101183 },
    { lat: 48.9897557, lng: 21.1087613 },
    { lat: 48.9899372, lng: 21.1076822 },
    { lat: 48.9901765, lng: 21.1068904 },
    { lat: 48.9903541, lng: 21.106009 },
    { lat: 48.9904758, lng: 21.1055589 },
    { lat: 48.9906676, lng: 21.104518 },
    { lat: 48.9907405, lng: 21.1032064 },
    { lat: 48.9909401, lng: 21.102313 },
    { lat: 48.99088, lng: 21.1019472 },
    { lat: 48.9909744, lng: 21.1014859 },
  ],
  Tuhrina: [
    { lat: 48.9113275, lng: 21.4263518 },
    { lat: 48.9114397, lng: 21.4251505 },
    { lat: 48.9119854, lng: 21.4253777 },
    { lat: 48.9128603, lng: 21.4253294 },
    { lat: 48.9134676, lng: 21.4254687 },
    { lat: 48.9136472, lng: 21.4255646 },
    { lat: 48.9137237, lng: 21.4259547 },
    { lat: 48.9139485, lng: 21.4266336 },
    { lat: 48.9141612, lng: 21.4266739 },
    { lat: 48.9142973, lng: 21.426556 },
    { lat: 48.9144493, lng: 21.4266008 },
    { lat: 48.9146437, lng: 21.4269752 },
    { lat: 48.9148579, lng: 21.4271804 },
    { lat: 48.9149858, lng: 21.4275935 },
    { lat: 48.9151803, lng: 21.4278916 },
    { lat: 48.9152945, lng: 21.4279508 },
    { lat: 48.9157845, lng: 21.4278852 },
    { lat: 48.9159882, lng: 21.428006 },
    { lat: 48.9167117, lng: 21.4280105 },
    { lat: 48.9171375, lng: 21.4281388 },
    { lat: 48.9173668, lng: 21.4281266 },
    { lat: 48.9176182, lng: 21.4282263 },
    { lat: 48.9178279, lng: 21.4284304 },
    { lat: 48.9183648, lng: 21.4282042 },
    { lat: 48.9190035, lng: 21.4281153 },
    { lat: 48.919293, lng: 21.4279691 },
    { lat: 48.9198332, lng: 21.4282844 },
    { lat: 48.9201557, lng: 21.4280798 },
    { lat: 48.9204269, lng: 21.4281134 },
    { lat: 48.9211082, lng: 21.428592 },
    { lat: 48.9215084, lng: 21.4292008 },
    { lat: 48.9218452, lng: 21.4294428 },
    { lat: 48.9226004, lng: 21.4296388 },
    { lat: 48.9227097, lng: 21.4297223 },
    { lat: 48.9232563, lng: 21.4308698 },
    { lat: 48.9237287, lng: 21.4310172 },
    { lat: 48.9239601, lng: 21.4311572 },
    { lat: 48.924342, lng: 21.4316711 },
    { lat: 48.9246027, lng: 21.4318879 },
    { lat: 48.9245833, lng: 21.4293777 },
    { lat: 48.9246639, lng: 21.4285647 },
    { lat: 48.9246486, lng: 21.4273499 },
    { lat: 48.9247562, lng: 21.4258323 },
    { lat: 48.9246975, lng: 21.4256781 },
    { lat: 48.9247901, lng: 21.4254579 },
    { lat: 48.9247376, lng: 21.4250421 },
    { lat: 48.9248094, lng: 21.4237756 },
    { lat: 48.9251743, lng: 21.4197956 },
    { lat: 48.9252648, lng: 21.4186209 },
    { lat: 48.9254064, lng: 21.4166694 },
    { lat: 48.9255796, lng: 21.415094 },
    { lat: 48.9258932, lng: 21.4135535 },
    { lat: 48.9256747, lng: 21.4122408 },
    { lat: 48.9256127, lng: 21.4113211 },
    { lat: 48.9256573, lng: 21.4092785 },
    { lat: 48.9254239, lng: 21.4084244 },
    { lat: 48.9252597, lng: 21.4080835 },
    { lat: 48.9252136, lng: 21.4078147 },
    { lat: 48.923372, lng: 21.405732 },
    { lat: 48.9229533, lng: 21.4051915 },
    { lat: 48.9225857, lng: 21.4045198 },
    { lat: 48.9219516, lng: 21.402893 },
    { lat: 48.9219307, lng: 21.4024579 },
    { lat: 48.9220198, lng: 21.4021921 },
    { lat: 48.9218788, lng: 21.401226 },
    { lat: 48.921764, lng: 21.4008842 },
    { lat: 48.9215082, lng: 21.4005345 },
    { lat: 48.9214508, lng: 21.4002257 },
    { lat: 48.9211541, lng: 21.3996663 },
    { lat: 48.9210948, lng: 21.3992227 },
    { lat: 48.9209735, lng: 21.3991157 },
    { lat: 48.9201835, lng: 21.3989895 },
    { lat: 48.919791, lng: 21.3989615 },
    { lat: 48.9197182, lng: 21.3990913 },
    { lat: 48.9195822, lng: 21.3991728 },
    { lat: 48.9194136, lng: 21.3990995 },
    { lat: 48.9193911, lng: 21.3989869 },
    { lat: 48.9194747, lng: 21.3986943 },
    { lat: 48.9194403, lng: 21.3986311 },
    { lat: 48.9193891, lng: 21.3985741 },
    { lat: 48.9190397, lng: 21.3985199 },
    { lat: 48.9188616, lng: 21.3982773 },
    { lat: 48.9187294, lng: 21.3982963 },
    { lat: 48.9186311, lng: 21.3981685 },
    { lat: 48.9186225, lng: 21.39788 },
    { lat: 48.9185385, lng: 21.3977401 },
    { lat: 48.9182304, lng: 21.3974408 },
    { lat: 48.9180516, lng: 21.3971045 },
    { lat: 48.9178565, lng: 21.3968999 },
    { lat: 48.917731, lng: 21.396873 },
    { lat: 48.9175701, lng: 21.3964972 },
    { lat: 48.9173239, lng: 21.396431 },
    { lat: 48.91723, lng: 21.3963322 },
    { lat: 48.9169431, lng: 21.3963346 },
    { lat: 48.9166664, lng: 21.3960354 },
    { lat: 48.916455, lng: 21.3959685 },
    { lat: 48.9163532, lng: 21.3958349 },
    { lat: 48.9161199, lng: 21.3958232 },
    { lat: 48.9160028, lng: 21.3959303 },
    { lat: 48.9158108, lng: 21.3958314 },
    { lat: 48.9153965, lng: 21.3958234 },
    { lat: 48.9150453, lng: 21.3955865 },
    { lat: 48.9148919, lng: 21.3957662 },
    { lat: 48.914609, lng: 21.3949963 },
    { lat: 48.9146512, lng: 21.3945949 },
    { lat: 48.9148063, lng: 21.3941314 },
    { lat: 48.9147973, lng: 21.3937099 },
    { lat: 48.9150119, lng: 21.3931668 },
    { lat: 48.9150787, lng: 21.3928425 },
    { lat: 48.9150014, lng: 21.3926231 },
    { lat: 48.9151073, lng: 21.3923994 },
    { lat: 48.9148924, lng: 21.3917923 },
    { lat: 48.9148646, lng: 21.3914586 },
    { lat: 48.9146147, lng: 21.3911884 },
    { lat: 48.9144817, lng: 21.3907656 },
    { lat: 48.9145099, lng: 21.3899759 },
    { lat: 48.9142294, lng: 21.3890478 },
    { lat: 48.9142198, lng: 21.3888413 },
    { lat: 48.914275, lng: 21.3887628 },
    { lat: 48.9141681, lng: 21.3885375 },
    { lat: 48.9140599, lng: 21.3874656 },
    { lat: 48.9139882, lng: 21.3874003 },
    { lat: 48.913858, lng: 21.3870708 },
    { lat: 48.9138504, lng: 21.3863644 },
    { lat: 48.9137912, lng: 21.3863767 },
    { lat: 48.9136905, lng: 21.3862011 },
    { lat: 48.9134628, lng: 21.3860789 },
    { lat: 48.9133514, lng: 21.3854297 },
    { lat: 48.9131956, lng: 21.3852966 },
    { lat: 48.9130636, lng: 21.3852913 },
    { lat: 48.9130278, lng: 21.3853713 },
    { lat: 48.9127943, lng: 21.3852413 },
    { lat: 48.9125272, lng: 21.3847348 },
    { lat: 48.9124004, lng: 21.3847062 },
    { lat: 48.912338, lng: 21.384611 },
    { lat: 48.9121723, lng: 21.384075 },
    { lat: 48.9119337, lng: 21.3838066 },
    { lat: 48.9118016, lng: 21.3838281 },
    { lat: 48.9115545, lng: 21.3835406 },
    { lat: 48.9114124, lng: 21.3835281 },
    { lat: 48.9110949, lng: 21.3829509 },
    { lat: 48.9108371, lng: 21.3827196 },
    { lat: 48.9103273, lng: 21.3813926 },
    { lat: 48.9102834, lng: 21.3810171 },
    { lat: 48.9100883, lng: 21.3806159 },
    { lat: 48.9101187, lng: 21.3795628 },
    { lat: 48.9099057, lng: 21.3792797 },
    { lat: 48.9094702, lng: 21.378147 },
    { lat: 48.9094915, lng: 21.377498 },
    { lat: 48.9095549, lng: 21.377474 },
    { lat: 48.909571, lng: 21.3768433 },
    { lat: 48.9094672, lng: 21.3766512 },
    { lat: 48.9093351, lng: 21.3759112 },
    { lat: 48.9092763, lng: 21.3758279 },
    { lat: 48.9090572, lng: 21.3742894 },
    { lat: 48.908726, lng: 21.3742384 },
    { lat: 48.9085465, lng: 21.3740292 },
    { lat: 48.908314, lng: 21.3736252 },
    { lat: 48.9072786, lng: 21.3734747 },
    { lat: 48.9066295, lng: 21.3735539 },
    { lat: 48.906232, lng: 21.3740322 },
    { lat: 48.9058575, lng: 21.3746062 },
    { lat: 48.905115, lng: 21.3746155 },
    { lat: 48.9046916, lng: 21.3749028 },
    { lat: 48.9044873, lng: 21.3754858 },
    { lat: 48.9040123, lng: 21.376052 },
    { lat: 48.9037645, lng: 21.376006 },
    { lat: 48.9031888, lng: 21.3766603 },
    { lat: 48.9028951, lng: 21.3764729 },
    { lat: 48.9026677, lng: 21.3766208 },
    { lat: 48.9019045, lng: 21.3774399 },
    { lat: 48.9014475, lng: 21.3784597 },
    { lat: 48.9012038, lng: 21.3787404 },
    { lat: 48.9000465, lng: 21.3797408 },
    { lat: 48.899774, lng: 21.3802655 },
    { lat: 48.8996755, lng: 21.3806646 },
    { lat: 48.8997888, lng: 21.3814866 },
    { lat: 48.8987846, lng: 21.3826381 },
    { lat: 48.8982986, lng: 21.3823048 },
    { lat: 48.8981021, lng: 21.3825785 },
    { lat: 48.8981145, lng: 21.3830759 },
    { lat: 48.8978356, lng: 21.38365 },
    { lat: 48.8977684, lng: 21.3841188 },
    { lat: 48.8970588, lng: 21.3844723 },
    { lat: 48.896933, lng: 21.3842838 },
    { lat: 48.8966228, lng: 21.3847173 },
    { lat: 48.8964826, lng: 21.3847688 },
    { lat: 48.8962469, lng: 21.3847541 },
    { lat: 48.8960818, lng: 21.3846509 },
    { lat: 48.8955077, lng: 21.3850804 },
    { lat: 48.8953455, lng: 21.3852018 },
    { lat: 48.8951551, lng: 21.3855406 },
    { lat: 48.8948381, lng: 21.3855823 },
    { lat: 48.8945184, lng: 21.3859285 },
    { lat: 48.8941717, lng: 21.3860351 },
    { lat: 48.8940408, lng: 21.3861359 },
    { lat: 48.8939732, lng: 21.3862843 },
    { lat: 48.8939297, lng: 21.3867171 },
    { lat: 48.8932244, lng: 21.3868339 },
    { lat: 48.8931351, lng: 21.3864398 },
    { lat: 48.8929921, lng: 21.3864417 },
    { lat: 48.8925141, lng: 21.3869006 },
    { lat: 48.8926582, lng: 21.3870148 },
    { lat: 48.8926218, lng: 21.3873842 },
    { lat: 48.8927618, lng: 21.3874769 },
    { lat: 48.892859, lng: 21.3877174 },
    { lat: 48.8927257, lng: 21.3879639 },
    { lat: 48.8924262, lng: 21.3881506 },
    { lat: 48.8923052, lng: 21.3885884 },
    { lat: 48.8917369, lng: 21.3891524 },
    { lat: 48.8913677, lng: 21.3891854 },
    { lat: 48.8912824, lng: 21.3893847 },
    { lat: 48.8911541, lng: 21.3899481 },
    { lat: 48.8911364, lng: 21.3901885 },
    { lat: 48.8912314, lng: 21.3907742 },
    { lat: 48.8911667, lng: 21.3910986 },
    { lat: 48.8909767, lng: 21.3913304 },
    { lat: 48.8908604, lng: 21.3912811 },
    { lat: 48.8905326, lng: 21.3915742 },
    { lat: 48.890117, lng: 21.3915227 },
    { lat: 48.8897351, lng: 21.3910138 },
    { lat: 48.8894458, lng: 21.3911601 },
    { lat: 48.8891739, lng: 21.3918977 },
    { lat: 48.8890093, lng: 21.3919229 },
    { lat: 48.888665, lng: 21.3917368 },
    { lat: 48.8878342, lng: 21.3930152 },
    { lat: 48.887521, lng: 21.3936429 },
    { lat: 48.8865122, lng: 21.3940459 },
    { lat: 48.8860979, lng: 21.3941478 },
    { lat: 48.8859499, lng: 21.3943963 },
    { lat: 48.8858727, lng: 21.3946397 },
    { lat: 48.8854217, lng: 21.3947233 },
    { lat: 48.8852591, lng: 21.3949668 },
    { lat: 48.8851415, lng: 21.3948732 },
    { lat: 48.885109, lng: 21.394337 },
    { lat: 48.884519, lng: 21.394513 },
    { lat: 48.884389, lng: 21.3947 },
    { lat: 48.884183, lng: 21.394806 },
    { lat: 48.88399, lng: 21.394735 },
    { lat: 48.883582, lng: 21.394943 },
    { lat: 48.883518, lng: 21.395924 },
    { lat: 48.883511, lng: 21.396029 },
    { lat: 48.883499, lng: 21.396221 },
    { lat: 48.883145, lng: 21.396204 },
    { lat: 48.882748, lng: 21.396346 },
    { lat: 48.88227, lng: 21.396459 },
    { lat: 48.882151, lng: 21.396704 },
    { lat: 48.881998, lng: 21.396773 },
    { lat: 48.881859, lng: 21.397015 },
    { lat: 48.881655, lng: 21.397173 },
    { lat: 48.881494, lng: 21.397353 },
    { lat: 48.881401, lng: 21.397778 },
    { lat: 48.88119, lng: 21.397932 },
    { lat: 48.881023, lng: 21.398213 },
    { lat: 48.881101, lng: 21.398328 },
    { lat: 48.881145, lng: 21.398915 },
    { lat: 48.881058, lng: 21.399189 },
    { lat: 48.881109, lng: 21.399388 },
    { lat: 48.881051, lng: 21.399729 },
    { lat: 48.880698, lng: 21.401726 },
    { lat: 48.880432, lng: 21.402929 },
    { lat: 48.880519, lng: 21.403132 },
    { lat: 48.880325, lng: 21.403885 },
    { lat: 48.880047, lng: 21.405285 },
    { lat: 48.879456, lng: 21.406655 },
    { lat: 48.879012, lng: 21.406931 },
    { lat: 48.878306, lng: 21.407287 },
    { lat: 48.878177, lng: 21.407651 },
    { lat: 48.87802, lng: 21.407817 },
    { lat: 48.877737, lng: 21.407887 },
    { lat: 48.877595, lng: 21.408217 },
    { lat: 48.877451, lng: 21.40866 },
    { lat: 48.87741, lng: 21.409038 },
    { lat: 48.877492, lng: 21.409069 },
    { lat: 48.877515, lng: 21.409294 },
    { lat: 48.877515, lng: 21.409423 },
    { lat: 48.877405, lng: 21.409613 },
    { lat: 48.877012, lng: 21.410009 },
    { lat: 48.876575, lng: 21.410485 },
    { lat: 48.876231, lng: 21.410706 },
    { lat: 48.875853, lng: 21.410873 },
    { lat: 48.875281, lng: 21.411361 },
    { lat: 48.875131, lng: 21.411643 },
    { lat: 48.874951, lng: 21.412003 },
    { lat: 48.874669, lng: 21.412254 },
    { lat: 48.874458, lng: 21.412441 },
    { lat: 48.874275, lng: 21.412748 },
    { lat: 48.874169, lng: 21.413052 },
    { lat: 48.873998, lng: 21.413237 },
    { lat: 48.873745, lng: 21.413527 },
    { lat: 48.873418, lng: 21.413663 },
    { lat: 48.873198, lng: 21.413875 },
    { lat: 48.872868, lng: 21.414086 },
    { lat: 48.872857, lng: 21.414106 },
    { lat: 48.872824, lng: 21.414169 },
    { lat: 48.872713, lng: 21.41438 },
    { lat: 48.872698, lng: 21.414409 },
    { lat: 48.872448, lng: 21.414483 },
    { lat: 48.872204, lng: 21.414398 },
    { lat: 48.871963, lng: 21.414314 },
    { lat: 48.871857, lng: 21.414258 },
    { lat: 48.871899, lng: 21.414368 },
    { lat: 48.872033, lng: 21.414453 },
    { lat: 48.872539, lng: 21.415316 },
    { lat: 48.872462, lng: 21.41556 },
    { lat: 48.872564, lng: 21.415802 },
    { lat: 48.872807, lng: 21.415833 },
    { lat: 48.873189, lng: 21.4164 },
    { lat: 48.873662, lng: 21.416385 },
    { lat: 48.873837, lng: 21.416309 },
    { lat: 48.874414, lng: 21.416497 },
    { lat: 48.874492, lng: 21.416489 },
    { lat: 48.874735, lng: 21.416466 },
    { lat: 48.875066, lng: 21.416406 },
    { lat: 48.875313, lng: 21.416641 },
    { lat: 48.875518, lng: 21.416709 },
    { lat: 48.875816, lng: 21.416502 },
    { lat: 48.876018, lng: 21.416504 },
    { lat: 48.876267, lng: 21.416698 },
    { lat: 48.876432, lng: 21.416899 },
    { lat: 48.876832, lng: 21.417124 },
    { lat: 48.877195, lng: 21.417253 },
    { lat: 48.877732, lng: 21.417498 },
    { lat: 48.878031, lng: 21.417369 },
    { lat: 48.878285, lng: 21.417537 },
    { lat: 48.878617, lng: 21.417802 },
    { lat: 48.878643, lng: 21.417844 },
    { lat: 48.878666, lng: 21.417884 },
    { lat: 48.878701, lng: 21.417941 },
    { lat: 48.879346, lng: 21.417727 },
    { lat: 48.879508, lng: 21.417854 },
    { lat: 48.879694, lng: 21.417893 },
    { lat: 48.879789, lng: 21.417716 },
    { lat: 48.879815, lng: 21.417669 },
    { lat: 48.88001, lng: 21.41753 },
    { lat: 48.880186, lng: 21.417611 },
    { lat: 48.880527, lng: 21.417159 },
    { lat: 48.880814, lng: 21.417214 },
    { lat: 48.880836, lng: 21.417154 },
    { lat: 48.880896, lng: 21.416981 },
    { lat: 48.881186, lng: 21.416869 },
    { lat: 48.881421, lng: 21.416995 },
    { lat: 48.881603, lng: 21.417208 },
    { lat: 48.881898, lng: 21.41711 },
    { lat: 48.882194, lng: 21.417016 },
    { lat: 48.882284, lng: 21.416607 },
    { lat: 48.882386, lng: 21.416461 },
    { lat: 48.882559, lng: 21.416245 },
    { lat: 48.88322, lng: 21.415768 },
    { lat: 48.883352, lng: 21.415672 },
    { lat: 48.883357, lng: 21.415901 },
    { lat: 48.883359, lng: 21.415993 },
    { lat: 48.88336, lng: 21.416028 },
    { lat: 48.883391, lng: 21.416461 },
    { lat: 48.88344, lng: 21.416538 },
    { lat: 48.884219, lng: 21.417734 },
    { lat: 48.884256, lng: 21.417789 },
    { lat: 48.884319, lng: 21.417966 },
    { lat: 48.884499, lng: 21.418689 },
    { lat: 48.884552, lng: 21.418659 },
    { lat: 48.884652, lng: 21.418631 },
    { lat: 48.884725, lng: 21.418609 },
    { lat: 48.885299, lng: 21.418398 },
    { lat: 48.886062, lng: 21.418291 },
    { lat: 48.886194, lng: 21.418701 },
    { lat: 48.886455, lng: 21.418717 },
    { lat: 48.887394, lng: 21.418777 },
    { lat: 48.887724, lng: 21.418884 },
    { lat: 48.887828, lng: 21.418995 },
    { lat: 48.887892, lng: 21.419067 },
    { lat: 48.887911, lng: 21.419094 },
    { lat: 48.887927, lng: 21.419117 },
    { lat: 48.887991, lng: 21.419189 },
    { lat: 48.888071, lng: 21.419277 },
    { lat: 48.888186, lng: 21.419401 },
    { lat: 48.888687, lng: 21.419684 },
    { lat: 48.888866, lng: 21.41973 },
    { lat: 48.889034, lng: 21.419812 },
    { lat: 48.889164, lng: 21.419882 },
    { lat: 48.889187, lng: 21.419895 },
    { lat: 48.889486, lng: 21.420056 },
    { lat: 48.889842, lng: 21.420248 },
    { lat: 48.890155, lng: 21.419956 },
    { lat: 48.890831, lng: 21.420018 },
    { lat: 48.891616, lng: 21.419781 },
    { lat: 48.891915, lng: 21.419784 },
    { lat: 48.89198, lng: 21.419899 },
    { lat: 48.891853, lng: 21.42 },
    { lat: 48.891724, lng: 21.420263 },
    { lat: 48.891802, lng: 21.420427 },
    { lat: 48.892553, lng: 21.420247 },
    { lat: 48.893356, lng: 21.419951 },
    { lat: 48.893465, lng: 21.419646 },
    { lat: 48.894033, lng: 21.419529 },
    { lat: 48.894072, lng: 21.419679 },
    { lat: 48.893992, lng: 21.419938 },
    { lat: 48.893928, lng: 21.420151 },
    { lat: 48.893897, lng: 21.420249 },
    { lat: 48.893989, lng: 21.420385 },
    { lat: 48.89414, lng: 21.420335 },
    { lat: 48.8941125, lng: 21.4202008 },
    { lat: 48.894363, lng: 21.4201088 },
    { lat: 48.8945476, lng: 21.4201474 },
    { lat: 48.8949316, lng: 21.4206579 },
    { lat: 48.8950728, lng: 21.420977 },
    { lat: 48.8954659, lng: 21.4211875 },
    { lat: 48.8954997, lng: 21.4209986 },
    { lat: 48.8957745, lng: 21.4210032 },
    { lat: 48.8962424, lng: 21.4212309 },
    { lat: 48.8966202, lng: 21.421529 },
    { lat: 48.8975651, lng: 21.422625 },
    { lat: 48.8977944, lng: 21.4230808 },
    { lat: 48.8985281, lng: 21.4236553 },
    { lat: 48.8985296, lng: 21.4237869 },
    { lat: 48.8991792, lng: 21.423988 },
    { lat: 48.8994362, lng: 21.4241974 },
    { lat: 48.9002731, lng: 21.4244989 },
    { lat: 48.9011373, lng: 21.4250307 },
    { lat: 48.9014018, lng: 21.425271 },
    { lat: 48.9028021, lng: 21.4258962 },
    { lat: 48.9036029, lng: 21.426002 },
    { lat: 48.904585, lng: 21.4263013 },
    { lat: 48.9059658, lng: 21.4264975 },
    { lat: 48.9063995, lng: 21.4260562 },
    { lat: 48.9069919, lng: 21.4256499 },
    { lat: 48.9085585, lng: 21.4254348 },
    { lat: 48.9101098, lng: 21.4260322 },
    { lat: 48.9102996, lng: 21.4264848 },
    { lat: 48.9103276, lng: 21.4265541 },
    { lat: 48.9104169, lng: 21.4265345 },
    { lat: 48.9104399, lng: 21.4265887 },
    { lat: 48.9109373, lng: 21.4264411 },
    { lat: 48.9113275, lng: 21.4263518 },
  ],
  Brestov: [
    { lat: 48.8554564, lng: 21.3377725 },
    { lat: 48.8552864, lng: 21.3388924 },
    { lat: 48.8551212, lng: 21.3406987 },
    { lat: 48.8553248, lng: 21.3407336 },
    { lat: 48.855367, lng: 21.3429198 },
    { lat: 48.8555492, lng: 21.3444849 },
    { lat: 48.8568372, lng: 21.3441333 },
    { lat: 48.8573806, lng: 21.3478041 },
    { lat: 48.857744, lng: 21.3479856 },
    { lat: 48.8582757, lng: 21.3480097 },
    { lat: 48.8588827, lng: 21.350311 },
    { lat: 48.8593919, lng: 21.3529173 },
    { lat: 48.8615794, lng: 21.352322 },
    { lat: 48.8616681, lng: 21.3525329 },
    { lat: 48.8617588, lng: 21.3530502 },
    { lat: 48.8619803, lng: 21.3537133 },
    { lat: 48.8622463, lng: 21.354786 },
    { lat: 48.8625397, lng: 21.3570299 },
    { lat: 48.8637367, lng: 21.3568282 },
    { lat: 48.8646372, lng: 21.356956 },
    { lat: 48.864572, lng: 21.357784 },
    { lat: 48.8646345, lng: 21.3584409 },
    { lat: 48.8646162, lng: 21.3586566 },
    { lat: 48.8648068, lng: 21.3587857 },
    { lat: 48.8650192, lng: 21.3598312 },
    { lat: 48.8649725, lng: 21.3605224 },
    { lat: 48.8648486, lng: 21.3613086 },
    { lat: 48.8647493, lng: 21.3631067 },
    { lat: 48.8648888, lng: 21.364877 },
    { lat: 48.8650795, lng: 21.3653504 },
    { lat: 48.865862, lng: 21.3657413 },
    { lat: 48.8660861, lng: 21.3660054 },
    { lat: 48.8667693, lng: 21.3663875 },
    { lat: 48.8675044, lng: 21.3670379 },
    { lat: 48.8676315, lng: 21.3672304 },
    { lat: 48.8679824, lng: 21.3681317 },
    { lat: 48.8685911, lng: 21.3692581 },
    { lat: 48.8696023, lng: 21.3701835 },
    { lat: 48.8697339, lng: 21.3705273 },
    { lat: 48.8700969, lng: 21.3707365 },
    { lat: 48.8706468, lng: 21.3708379 },
    { lat: 48.8719436, lng: 21.3706297 },
    { lat: 48.872339, lng: 21.3708267 },
    { lat: 48.872606, lng: 21.3712166 },
    { lat: 48.8731899, lng: 21.3717017 },
    { lat: 48.8737214, lng: 21.3719481 },
    { lat: 48.8756683, lng: 21.3723248 },
    { lat: 48.8763148, lng: 21.3721983 },
    { lat: 48.8778686, lng: 21.3734036 },
    { lat: 48.8777564, lng: 21.3736354 },
    { lat: 48.8775888, lng: 21.373565 },
    { lat: 48.8774532, lng: 21.3736225 },
    { lat: 48.8772038, lng: 21.3739392 },
    { lat: 48.8770845, lng: 21.3739402 },
    { lat: 48.876822, lng: 21.3740808 },
    { lat: 48.8763201, lng: 21.3745295 },
    { lat: 48.8762089, lng: 21.3747612 },
    { lat: 48.8761041, lng: 21.3747956 },
    { lat: 48.8755848, lng: 21.3758699 },
    { lat: 48.8753659, lng: 21.376153 },
    { lat: 48.8753268, lng: 21.376353 },
    { lat: 48.8751465, lng: 21.3766081 },
    { lat: 48.8751202, lng: 21.3767886 },
    { lat: 48.8749848, lng: 21.3770402 },
    { lat: 48.874793, lng: 21.3770609 },
    { lat: 48.874754, lng: 21.377169 },
    { lat: 48.874834, lng: 21.377315 },
    { lat: 48.875494, lng: 21.37699 },
    { lat: 48.875919, lng: 21.376764 },
    { lat: 48.87635, lng: 21.376472 },
    { lat: 48.877336, lng: 21.375923 },
    { lat: 48.877414, lng: 21.375834 },
    { lat: 48.877754, lng: 21.375587 },
    { lat: 48.878556, lng: 21.375079 },
    { lat: 48.879207, lng: 21.374554 },
    { lat: 48.879413, lng: 21.374426 },
    { lat: 48.879428, lng: 21.374416 },
    { lat: 48.880049, lng: 21.374013 },
    { lat: 48.880155, lng: 21.373978 },
    { lat: 48.880513, lng: 21.37386 },
    { lat: 48.881014, lng: 21.37554 },
    { lat: 48.881405, lng: 21.376388 },
    { lat: 48.881906, lng: 21.377199 },
    { lat: 48.8827377, lng: 21.3773855 },
    { lat: 48.8829774, lng: 21.3775346 },
    { lat: 48.8834424, lng: 21.3775833 },
    { lat: 48.8841804, lng: 21.3768989 },
    { lat: 48.885208, lng: 21.3762994 },
    { lat: 48.885823, lng: 21.3761733 },
    { lat: 48.8860957, lng: 21.3756948 },
    { lat: 48.8863314, lng: 21.3746536 },
    { lat: 48.8880366, lng: 21.3722842 },
    { lat: 48.8882764, lng: 21.3717218 },
    { lat: 48.8887304, lng: 21.3683803 },
    { lat: 48.8888486, lng: 21.3676692 },
    { lat: 48.8890282, lng: 21.3668201 },
    { lat: 48.8895702, lng: 21.364921 },
    { lat: 48.8897588, lng: 21.363097 },
    { lat: 48.8904275, lng: 21.3628118 },
    { lat: 48.8904851, lng: 21.3598806 },
    { lat: 48.8904306, lng: 21.3592643 },
    { lat: 48.8905238, lng: 21.3583919 },
    { lat: 48.8905171, lng: 21.357409 },
    { lat: 48.8906194, lng: 21.3559054 },
    { lat: 48.8907134, lng: 21.3553958 },
    { lat: 48.890938, lng: 21.3547882 },
    { lat: 48.890992, lng: 21.3544802 },
    { lat: 48.8909171, lng: 21.35394 },
    { lat: 48.890959, lng: 21.3534966 },
    { lat: 48.8908824, lng: 21.3532998 },
    { lat: 48.8906783, lng: 21.3522336 },
    { lat: 48.8905637, lng: 21.3519036 },
    { lat: 48.8901228, lng: 21.3507883 },
    { lat: 48.8896524, lng: 21.3499242 },
    { lat: 48.8896246, lng: 21.349742 },
    { lat: 48.8899554, lng: 21.347382 },
    { lat: 48.8904709, lng: 21.3457352 },
    { lat: 48.8875244, lng: 21.3431703 },
    { lat: 48.8869257, lng: 21.3421161 },
    { lat: 48.8863615, lng: 21.3407556 },
    { lat: 48.8851958, lng: 21.3388542 },
    { lat: 48.8841872, lng: 21.3370476 },
    { lat: 48.8839246, lng: 21.3365094 },
    { lat: 48.8838827, lng: 21.3361988 },
    { lat: 48.8835572, lng: 21.3355938 },
    { lat: 48.883485, lng: 21.3355842 },
    { lat: 48.881623, lng: 21.3323469 },
    { lat: 48.8754513, lng: 21.3295925 },
    { lat: 48.8750113, lng: 21.3211007 },
    { lat: 48.8753286, lng: 21.3209474 },
    { lat: 48.8740638, lng: 21.3211368 },
    { lat: 48.8691691, lng: 21.3216328 },
    { lat: 48.8687944, lng: 21.3215159 },
    { lat: 48.8680868, lng: 21.321485 },
    { lat: 48.8678437, lng: 21.3210808 },
    { lat: 48.8677467, lng: 21.3210708 },
    { lat: 48.8677002, lng: 21.3211705 },
    { lat: 48.8676676, lng: 21.3216206 },
    { lat: 48.8672799, lng: 21.3218613 },
    { lat: 48.8671923, lng: 21.3219721 },
    { lat: 48.8671056, lng: 21.3222727 },
    { lat: 48.8670231, lng: 21.3229658 },
    { lat: 48.8668021, lng: 21.3231766 },
    { lat: 48.866308, lng: 21.3228771 },
    { lat: 48.8661232, lng: 21.322456 },
    { lat: 48.8660688, lng: 21.3224845 },
    { lat: 48.866021, lng: 21.3228738 },
    { lat: 48.8658786, lng: 21.3230009 },
    { lat: 48.8654432, lng: 21.3228943 },
    { lat: 48.8653267, lng: 21.3227247 },
    { lat: 48.865222, lng: 21.3222027 },
    { lat: 48.8650697, lng: 21.3219688 },
    { lat: 48.8647927, lng: 21.3216992 },
    { lat: 48.8652021, lng: 21.3282862 },
    { lat: 48.8587069, lng: 21.3288773 },
    { lat: 48.8575661, lng: 21.3321291 },
    { lat: 48.8554564, lng: 21.3377725 },
  ],
  Okružná: [
    { lat: 49.0272303, lng: 21.3471021 },
    { lat: 49.0266793, lng: 21.3472991 },
    { lat: 49.0260874, lng: 21.3477162 },
    { lat: 49.0256537, lng: 21.3481854 },
    { lat: 49.0251104, lng: 21.3485317 },
    { lat: 49.0238964, lng: 21.3474461 },
    { lat: 49.0217917, lng: 21.3472684 },
    { lat: 49.0215692, lng: 21.347847 },
    { lat: 49.0214389, lng: 21.3483784 },
    { lat: 49.0212864, lng: 21.3491121 },
    { lat: 49.0212679, lng: 21.3495837 },
    { lat: 49.0215421, lng: 21.3499849 },
    { lat: 49.0218976, lng: 21.3499105 },
    { lat: 49.0219018, lng: 21.3509901 },
    { lat: 49.0219586, lng: 21.3513128 },
    { lat: 49.0219087, lng: 21.35175 },
    { lat: 49.0219734, lng: 21.3521478 },
    { lat: 49.0218019, lng: 21.3534062 },
    { lat: 49.0218785, lng: 21.353862 },
    { lat: 49.0220042, lng: 21.3540767 },
    { lat: 49.0220635, lng: 21.3544458 },
    { lat: 49.0219513, lng: 21.3549049 },
    { lat: 49.0217082, lng: 21.3550931 },
    { lat: 49.0215025, lng: 21.3551378 },
    { lat: 49.0213461, lng: 21.3553246 },
    { lat: 49.0211479, lng: 21.3553992 },
    { lat: 49.0209857, lng: 21.3556929 },
    { lat: 49.0207578, lng: 21.3558209 },
    { lat: 49.0205214, lng: 21.3558548 },
    { lat: 49.0200578, lng: 21.3554053 },
    { lat: 49.0197845, lng: 21.3553324 },
    { lat: 49.0195401, lng: 21.3550997 },
    { lat: 49.019265, lng: 21.3547054 },
    { lat: 49.0189113, lng: 21.3546186 },
    { lat: 49.0186533, lng: 21.3547587 },
    { lat: 49.0185244, lng: 21.3550003 },
    { lat: 49.0183808, lng: 21.3550847 },
    { lat: 49.0181989, lng: 21.3550665 },
    { lat: 49.0182487, lng: 21.355448 },
    { lat: 49.0180736, lng: 21.3568568 },
    { lat: 49.0179075, lng: 21.3570669 },
    { lat: 49.0177237, lng: 21.356863 },
    { lat: 49.0175863, lng: 21.3569693 },
    { lat: 49.0174617, lng: 21.3568309 },
    { lat: 49.017239, lng: 21.3571342 },
    { lat: 49.0170273, lng: 21.3576187 },
    { lat: 49.0166093, lng: 21.3580527 },
    { lat: 49.0161045, lng: 21.3582194 },
    { lat: 49.0160355, lng: 21.3581277 },
    { lat: 49.0154812, lng: 21.3583685 },
    { lat: 49.0153752, lng: 21.3582847 },
    { lat: 49.0149408, lng: 21.3584713 },
    { lat: 49.0147405, lng: 21.3586633 },
    { lat: 49.0146113, lng: 21.358893 },
    { lat: 49.0144096, lng: 21.3590331 },
    { lat: 49.0142204, lng: 21.359258 },
    { lat: 49.0138202, lng: 21.3594939 },
    { lat: 49.0135166, lng: 21.3593538 },
    { lat: 49.013227, lng: 21.3593749 },
    { lat: 49.0130471, lng: 21.3595647 },
    { lat: 49.0128355, lng: 21.3595614 },
    { lat: 49.0126366, lng: 21.3598031 },
    { lat: 49.0125249, lng: 21.3598674 },
    { lat: 49.0123815, lng: 21.360982 },
    { lat: 49.012158, lng: 21.3618305 },
    { lat: 49.0114502, lng: 21.3625416 },
    { lat: 49.0111241, lng: 21.3634197 },
    { lat: 49.0110067, lng: 21.3639693 },
    { lat: 49.0109055, lng: 21.3648068 },
    { lat: 49.0109084, lng: 21.3651317 },
    { lat: 49.0105692, lng: 21.3658637 },
    { lat: 49.0099371, lng: 21.3667068 },
    { lat: 49.0096321, lng: 21.3660289 },
    { lat: 49.0090799, lng: 21.3650525 },
    { lat: 49.0093435, lng: 21.3645319 },
    { lat: 49.0090714, lng: 21.3641581 },
    { lat: 49.0087249, lng: 21.3639727 },
    { lat: 49.008546, lng: 21.3637517 },
    { lat: 49.0072409, lng: 21.3629598 },
    { lat: 49.0071932, lng: 21.3625319 },
    { lat: 49.0071106, lng: 21.3624412 },
    { lat: 49.0067971, lng: 21.3624108 },
    { lat: 49.0065808, lng: 21.3622913 },
    { lat: 49.0064332, lng: 21.3623624 },
    { lat: 49.0056854, lng: 21.3623014 },
    { lat: 49.0046788, lng: 21.362018 },
    { lat: 49.004413, lng: 21.3616263 },
    { lat: 49.0041344, lng: 21.361605 },
    { lat: 49.0039019, lng: 21.361472 },
    { lat: 49.0036291, lng: 21.3611861 },
    { lat: 49.0031941, lng: 21.3608649 },
    { lat: 49.0031987, lng: 21.360756 },
    { lat: 49.0024982, lng: 21.360395 },
    { lat: 49.0002852, lng: 21.3601357 },
    { lat: 48.9996321, lng: 21.3603118 },
    { lat: 48.9995966, lng: 21.3605203 },
    { lat: 48.9996815, lng: 21.3613287 },
    { lat: 48.9996111, lng: 21.3618381 },
    { lat: 48.9994756, lng: 21.362123 },
    { lat: 48.9995196, lng: 21.3625403 },
    { lat: 48.9995258, lng: 21.3641475 },
    { lat: 48.9996838, lng: 21.3646881 },
    { lat: 48.9995722, lng: 21.3657404 },
    { lat: 48.9996378, lng: 21.3659394 },
    { lat: 48.9996178, lng: 21.3661766 },
    { lat: 48.9996848, lng: 21.3666139 },
    { lat: 48.9998269, lng: 21.3669986 },
    { lat: 49.0003098, lng: 21.3677617 },
    { lat: 49.000505, lng: 21.3686159 },
    { lat: 49.0006977, lng: 21.3690058 },
    { lat: 49.0009172, lng: 21.3692684 },
    { lat: 49.0009113, lng: 21.3695186 },
    { lat: 49.000844, lng: 21.3697644 },
    { lat: 49.0009295, lng: 21.3701416 },
    { lat: 49.0016259, lng: 21.3714226 },
    { lat: 49.0016969, lng: 21.3718026 },
    { lat: 49.0018983, lng: 21.3722178 },
    { lat: 49.0019011, lng: 21.3725028 },
    { lat: 49.0022461, lng: 21.3731296 },
    { lat: 49.0023772, lng: 21.3735281 },
    { lat: 49.002251, lng: 21.373755 },
    { lat: 49.0023347, lng: 21.3740331 },
    { lat: 49.0024334, lng: 21.374175 },
    { lat: 49.002317, lng: 21.3745722 },
    { lat: 49.0024347, lng: 21.3754307 },
    { lat: 49.0026159, lng: 21.3757379 },
    { lat: 49.0025551, lng: 21.3761503 },
    { lat: 49.002705, lng: 21.3768417 },
    { lat: 49.0028728, lng: 21.3771864 },
    { lat: 49.0028917, lng: 21.3777467 },
    { lat: 49.0029981, lng: 21.3781458 },
    { lat: 49.0030526, lng: 21.3787359 },
    { lat: 49.0029715, lng: 21.3791475 },
    { lat: 49.0031658, lng: 21.3808418 },
    { lat: 49.0033065, lng: 21.381111 },
    { lat: 49.0032665, lng: 21.3813753 },
    { lat: 49.0032631, lng: 21.3813978 },
    { lat: 49.0034795, lng: 21.3822768 },
    { lat: 49.0036921, lng: 21.3827672 },
    { lat: 49.0038066, lng: 21.3827991 },
    { lat: 49.003876, lng: 21.3829761 },
    { lat: 49.0038744, lng: 21.3833443 },
    { lat: 49.0037422, lng: 21.3836246 },
    { lat: 49.0038896, lng: 21.3839288 },
    { lat: 49.0038981, lng: 21.3841429 },
    { lat: 49.0038419, lng: 21.3844431 },
    { lat: 49.0037704, lng: 21.3845752 },
    { lat: 49.0038134, lng: 21.3847379 },
    { lat: 49.003665, lng: 21.3848957 },
    { lat: 49.0035449, lng: 21.3854726 },
    { lat: 49.0035, lng: 21.3857423 },
    { lat: 49.0035446, lng: 21.3862597 },
    { lat: 49.0034808, lng: 21.3864629 },
    { lat: 49.0036137, lng: 21.3867706 },
    { lat: 49.0037654, lng: 21.3869154 },
    { lat: 49.0038494, lng: 21.3871355 },
    { lat: 49.0038649, lng: 21.387467 },
    { lat: 49.0040304, lng: 21.3876979 },
    { lat: 49.0040928, lng: 21.3881338 },
    { lat: 49.0040177, lng: 21.3883744 },
    { lat: 49.0041605, lng: 21.3889004 },
    { lat: 49.0041279, lng: 21.3891678 },
    { lat: 49.0042833, lng: 21.3894787 },
    { lat: 49.0042966, lng: 21.3896985 },
    { lat: 49.0044948, lng: 21.3900462 },
    { lat: 49.0044296, lng: 21.3902797 },
    { lat: 49.0044715, lng: 21.3905485 },
    { lat: 49.0044366, lng: 21.3907017 },
    { lat: 49.0046203, lng: 21.3911343 },
    { lat: 49.0046611, lng: 21.3913742 },
    { lat: 49.0046435, lng: 21.3920316 },
    { lat: 49.0045016, lng: 21.3922961 },
    { lat: 49.0043987, lng: 21.3927715 },
    { lat: 49.0036603, lng: 21.3935243 },
    { lat: 49.0028985, lng: 21.3947045 },
    { lat: 49.0028755, lng: 21.395564 },
    { lat: 49.0027794, lng: 21.3963503 },
    { lat: 49.002011, lng: 21.4013557 },
    { lat: 49.0019713, lng: 21.4019117 },
    { lat: 49.0015966, lng: 21.403672 },
    { lat: 49.0013902, lng: 21.4041881 },
    { lat: 49.0013987, lng: 21.4044775 },
    { lat: 49.0009365, lng: 21.407431 },
    { lat: 49.0003545, lng: 21.4096414 },
    { lat: 49.000152, lng: 21.4102525 },
    { lat: 49.0001595, lng: 21.411044 },
    { lat: 48.9997468, lng: 21.4129297 },
    { lat: 48.9994142, lng: 21.4142068 },
    { lat: 48.999192, lng: 21.414474 },
    { lat: 48.999338, lng: 21.414958 },
    { lat: 48.999598, lng: 21.415718 },
    { lat: 48.999637, lng: 21.416208 },
    { lat: 48.999589, lng: 21.416895 },
    { lat: 48.999622, lng: 21.417389 },
    { lat: 48.999746, lng: 21.417999 },
    { lat: 48.999831, lng: 21.418233 },
    { lat: 48.999783, lng: 21.41834 },
    { lat: 48.999853, lng: 21.41871 },
    { lat: 49.000157, lng: 21.419124 },
    { lat: 49.000196, lng: 21.419231 },
    { lat: 49.000388, lng: 21.419411 },
    { lat: 49.000405, lng: 21.419486 },
    { lat: 49.000572, lng: 21.419517 },
    { lat: 49.000742, lng: 21.419833 },
    { lat: 49.000984, lng: 21.420213 },
    { lat: 49.001049, lng: 21.420393 },
    { lat: 49.001011, lng: 21.420495 },
    { lat: 49.001018, lng: 21.420544 },
    { lat: 49.000982, lng: 21.42087 },
    { lat: 49.001077, lng: 21.421214 },
    { lat: 49.001293, lng: 21.421493 },
    { lat: 49.001504, lng: 21.421745 },
    { lat: 49.001629, lng: 21.422168 },
    { lat: 49.001678, lng: 21.422349 },
    { lat: 49.001757, lng: 21.422432 },
    { lat: 49.002242, lng: 21.422859 },
    { lat: 49.002421, lng: 21.423251 },
    { lat: 49.00247, lng: 21.423721 },
    { lat: 49.002426, lng: 21.423838 },
    { lat: 49.002565, lng: 21.42448 },
    { lat: 49.002623, lng: 21.424897 },
    { lat: 49.002633, lng: 21.424894 },
    { lat: 49.002826, lng: 21.424841 },
    { lat: 49.003031, lng: 21.424473 },
    { lat: 49.003558, lng: 21.424681 },
    { lat: 49.00399, lng: 21.424812 },
    { lat: 49.004382, lng: 21.42476 },
    { lat: 49.005126, lng: 21.424499 },
    { lat: 49.006008, lng: 21.423939 },
    { lat: 49.00673, lng: 21.423469 },
    { lat: 49.006985, lng: 21.423287 },
    { lat: 49.007068, lng: 21.423253 },
    { lat: 49.007084, lng: 21.423184 },
    { lat: 49.007177, lng: 21.423168 },
    { lat: 49.00754, lng: 21.422839 },
    { lat: 49.007814, lng: 21.422582 },
    { lat: 49.008165, lng: 21.421936 },
    { lat: 49.008481, lng: 21.421456 },
    { lat: 49.008864, lng: 21.421019 },
    { lat: 49.009353, lng: 21.42054 },
    { lat: 49.009994, lng: 21.419989 },
    { lat: 49.010554, lng: 21.419089 },
    { lat: 49.010886, lng: 21.418717 },
    { lat: 49.011122, lng: 21.418342 },
    { lat: 49.01185, lng: 21.417493 },
    { lat: 49.011959, lng: 21.417288 },
    { lat: 49.012164, lng: 21.41697 },
    { lat: 49.012285, lng: 21.416851 },
    { lat: 49.012415, lng: 21.416506 },
    { lat: 49.012624, lng: 21.416265 },
    { lat: 49.013142, lng: 21.41568 },
    { lat: 49.013439, lng: 21.415086 },
    { lat: 49.013613, lng: 21.414766 },
    { lat: 49.013843, lng: 21.414354 },
    { lat: 49.013905, lng: 21.414292 },
    { lat: 49.014376, lng: 21.41384 },
    { lat: 49.014712, lng: 21.413486 },
    { lat: 49.014761, lng: 21.41339 },
    { lat: 49.01527, lng: 21.413085 },
    { lat: 49.015468, lng: 21.41285 },
    { lat: 49.015652, lng: 21.412484 },
    { lat: 49.016139, lng: 21.411442 },
    { lat: 49.016484, lng: 21.410461 },
    { lat: 49.016835, lng: 21.409622 },
    { lat: 49.016905, lng: 21.409456 },
    { lat: 49.017201, lng: 21.408937 },
    { lat: 49.017303, lng: 21.408747 },
    { lat: 49.017483, lng: 21.408411 },
    { lat: 49.017883, lng: 21.407686 },
    { lat: 49.018189, lng: 21.407253 },
    { lat: 49.018475, lng: 21.406798 },
    { lat: 49.018778, lng: 21.406195 },
    { lat: 49.018908, lng: 21.405813 },
    { lat: 49.018974, lng: 21.405572 },
    { lat: 49.019194, lng: 21.405479 },
    { lat: 49.019453, lng: 21.40489 },
    { lat: 49.01945, lng: 21.404385 },
    { lat: 49.01947, lng: 21.404373 },
    { lat: 49.0193495, lng: 21.4041647 },
    { lat: 49.0193326, lng: 21.4039755 },
    { lat: 49.0193135, lng: 21.4036696 },
    { lat: 49.0193902, lng: 21.4035586 },
    { lat: 49.0198863, lng: 21.4035205 },
    { lat: 49.0200372, lng: 21.4034411 },
    { lat: 49.0201709, lng: 21.4032083 },
    { lat: 49.0206086, lng: 21.4028673 },
    { lat: 49.0210842, lng: 21.4023961 },
    { lat: 49.0213642, lng: 21.4020082 },
    { lat: 49.0215885, lng: 21.401563 },
    { lat: 49.0217312, lng: 21.4014357 },
    { lat: 49.0222464, lng: 21.4005556 },
    { lat: 49.0243578, lng: 21.4008854 },
    { lat: 49.0243758, lng: 21.4006959 },
    { lat: 49.024315, lng: 21.4002731 },
    { lat: 49.0243572, lng: 21.4000949 },
    { lat: 49.0247319, lng: 21.3997133 },
    { lat: 49.0249038, lng: 21.3994413 },
    { lat: 49.0248628, lng: 21.3988095 },
    { lat: 49.024492, lng: 21.3984139 },
    { lat: 49.024958, lng: 21.3974643 },
    { lat: 49.0252752, lng: 21.3970364 },
    { lat: 49.0258396, lng: 21.396036 },
    { lat: 49.0262602, lng: 21.3955297 },
    { lat: 49.0265301, lng: 21.395104 },
    { lat: 49.0269216, lng: 21.3947553 },
    { lat: 49.0270768, lng: 21.3944873 },
    { lat: 49.0274015, lng: 21.3942084 },
    { lat: 49.0279314, lng: 21.3934436 },
    { lat: 49.0285755, lng: 21.3927001 },
    { lat: 49.0282755, lng: 21.3905406 },
    { lat: 49.0283278, lng: 21.3891129 },
    { lat: 49.0285652, lng: 21.3884634 },
    { lat: 49.0286168, lng: 21.3879296 },
    { lat: 49.0285229, lng: 21.3875287 },
    { lat: 49.0289772, lng: 21.386228 },
    { lat: 49.0289205, lng: 21.3844613 },
    { lat: 49.0290252, lng: 21.3833956 },
    { lat: 49.0291584, lng: 21.3830681 },
    { lat: 49.0294503, lng: 21.3815715 },
    { lat: 49.029444, lng: 21.3813923 },
    { lat: 49.0295391, lng: 21.3812259 },
    { lat: 49.0296514, lng: 21.3812935 },
    { lat: 49.0297938, lng: 21.3812755 },
    { lat: 49.0298481, lng: 21.3811344 },
    { lat: 49.0297996, lng: 21.3808283 },
    { lat: 49.0294949, lng: 21.3799177 },
    { lat: 49.0289355, lng: 21.3791177 },
    { lat: 49.0288585, lng: 21.3784532 },
    { lat: 49.0289447, lng: 21.3779897 },
    { lat: 49.0288724, lng: 21.3775626 },
    { lat: 49.028632, lng: 21.3770825 },
    { lat: 49.0284975, lng: 21.3769433 },
    { lat: 49.0284497, lng: 21.3767372 },
    { lat: 49.0285639, lng: 21.3763364 },
    { lat: 49.0285373, lng: 21.3754063 },
    { lat: 49.0282497, lng: 21.3742797 },
    { lat: 49.0286124, lng: 21.3725122 },
    { lat: 49.0282236, lng: 21.3718129 },
    { lat: 49.0279786, lng: 21.3707264 },
    { lat: 49.0278878, lng: 21.3706617 },
    { lat: 49.0275116, lng: 21.3700385 },
    { lat: 49.0272619, lng: 21.3686817 },
    { lat: 49.0272071, lng: 21.3679347 },
    { lat: 49.0270503, lng: 21.3673166 },
    { lat: 49.0268637, lng: 21.3669063 },
    { lat: 49.0264182, lng: 21.3663816 },
    { lat: 49.0266344, lng: 21.3659669 },
    { lat: 49.0265129, lng: 21.3659663 },
    { lat: 49.0257227, lng: 21.3624791 },
    { lat: 49.0258968, lng: 21.3605435 },
    { lat: 49.0255354, lng: 21.3580453 },
    { lat: 49.0256943, lng: 21.3576041 },
    { lat: 49.0265521, lng: 21.3572412 },
    { lat: 49.0264541, lng: 21.356973 },
    { lat: 49.0266047, lng: 21.3567421 },
    { lat: 49.0271395, lng: 21.3566026 },
    { lat: 49.0271425, lng: 21.356284 },
    { lat: 49.0272639, lng: 21.355494 },
    { lat: 49.027254, lng: 21.3547123 },
    { lat: 49.0272907, lng: 21.3543534 },
    { lat: 49.027129, lng: 21.3539135 },
    { lat: 49.0269151, lng: 21.3535331 },
    { lat: 49.0268893, lng: 21.3530451 },
    { lat: 49.0270029, lng: 21.35101 },
    { lat: 49.026777, lng: 21.34887 },
    { lat: 49.0265033, lng: 21.3485275 },
    { lat: 49.0266671, lng: 21.348319 },
    { lat: 49.0267655, lng: 21.3480361 },
    { lat: 49.026922, lng: 21.347856 },
    { lat: 49.0273747, lng: 21.3475629 },
    { lat: 49.0273995, lng: 21.3473429 },
    { lat: 49.0272303, lng: 21.3471021 },
  ],
  Miklušovce: [
    { lat: 48.9305188, lng: 21.0757231 },
    { lat: 48.9305977, lng: 21.0754499 },
    { lat: 48.930674, lng: 21.074985 },
    { lat: 48.9306197, lng: 21.0738701 },
    { lat: 48.9291869, lng: 21.0728895 },
    { lat: 48.9291765, lng: 21.0727418 },
    { lat: 48.9287883, lng: 21.0726386 },
    { lat: 48.9284553, lng: 21.0724 },
    { lat: 48.9282537, lng: 21.0723936 },
    { lat: 48.927774, lng: 21.0720732 },
    { lat: 48.9266192, lng: 21.0711339 },
    { lat: 48.9264676, lng: 21.0710714 },
    { lat: 48.9259023, lng: 21.0702256 },
    { lat: 48.9250522, lng: 21.0695596 },
    { lat: 48.9244441, lng: 21.0689518 },
    { lat: 48.9240091, lng: 21.0683787 },
    { lat: 48.9237677, lng: 21.0677701 },
    { lat: 48.9227077, lng: 21.0671932 },
    { lat: 48.922237, lng: 21.0667634 },
    { lat: 48.9219221, lng: 21.0663543 },
    { lat: 48.9218439, lng: 21.066198 },
    { lat: 48.9221998, lng: 21.0658565 },
    { lat: 48.9222306, lng: 21.0656277 },
    { lat: 48.9221438, lng: 21.0653786 },
    { lat: 48.9220043, lng: 21.0651707 },
    { lat: 48.9219073, lng: 21.0642328 },
    { lat: 48.9217938, lng: 21.0636845 },
    { lat: 48.9216485, lng: 21.0633278 },
    { lat: 48.9211775, lng: 21.0627245 },
    { lat: 48.9210529, lng: 21.0624883 },
    { lat: 48.9208388, lng: 21.0619604 },
    { lat: 48.9207663, lng: 21.061565 },
    { lat: 48.9201732, lng: 21.0604599 },
    { lat: 48.9200153, lng: 21.0603099 },
    { lat: 48.9190212, lng: 21.0589442 },
    { lat: 48.9191581, lng: 21.0585554 },
    { lat: 48.9195294, lng: 21.0578903 },
    { lat: 48.9182293, lng: 21.0561872 },
    { lat: 48.9179735, lng: 21.0557633 },
    { lat: 48.9179371, lng: 21.0558186 },
    { lat: 48.917692, lng: 21.0554108 },
    { lat: 48.9174239, lng: 21.0552018 },
    { lat: 48.9171623, lng: 21.0547645 },
    { lat: 48.9165838, lng: 21.0539758 },
    { lat: 48.9162658, lng: 21.0536396 },
    { lat: 48.914384, lng: 21.0510428 },
    { lat: 48.9122336, lng: 21.0485383 },
    { lat: 48.9118611, lng: 21.0482571 },
    { lat: 48.9116356, lng: 21.0482128 },
    { lat: 48.91107, lng: 21.0482985 },
    { lat: 48.91073, lng: 21.048192 },
    { lat: 48.9100253, lng: 21.0476645 },
    { lat: 48.9099161, lng: 21.0477013 },
    { lat: 48.9095058, lng: 21.0481313 },
    { lat: 48.9093815, lng: 21.0481879 },
    { lat: 48.9092757, lng: 21.0481815 },
    { lat: 48.9088223, lng: 21.0479103 },
    { lat: 48.9079425, lng: 21.0470893 },
    { lat: 48.9076797, lng: 21.0467352 },
    { lat: 48.9068528, lng: 21.045945 },
    { lat: 48.9065302, lng: 21.0460318 },
    { lat: 48.9063536, lng: 21.0459617 },
    { lat: 48.9061206, lng: 21.0457523 },
    { lat: 48.9057243, lng: 21.0453016 },
    { lat: 48.9051262, lng: 21.0444628 },
    { lat: 48.904963, lng: 21.044093 },
    { lat: 48.90485, lng: 21.04367 },
    { lat: 48.904839, lng: 21.043675 },
    { lat: 48.904585, lng: 21.043524 },
    { lat: 48.90445, lng: 21.044124 },
    { lat: 48.904459, lng: 21.044257 },
    { lat: 48.904442, lng: 21.04446 },
    { lat: 48.904401, lng: 21.045089 },
    { lat: 48.904398, lng: 21.04516 },
    { lat: 48.904389, lng: 21.045418 },
    { lat: 48.904391, lng: 21.045606 },
    { lat: 48.904274, lng: 21.04602 },
    { lat: 48.904257, lng: 21.0464 },
    { lat: 48.904264, lng: 21.046502 },
    { lat: 48.904249, lng: 21.046606 },
    { lat: 48.904139, lng: 21.04691 },
    { lat: 48.904093, lng: 21.047097 },
    { lat: 48.904014, lng: 21.047594 },
    { lat: 48.903952, lng: 21.048307 },
    { lat: 48.903926, lng: 21.048713 },
    { lat: 48.903923, lng: 21.049249 },
    { lat: 48.903951, lng: 21.049581 },
    { lat: 48.904065, lng: 21.050152 },
    { lat: 48.904198, lng: 21.050653 },
    { lat: 48.904254, lng: 21.050846 },
    { lat: 48.9043, lng: 21.051106 },
    { lat: 48.904369, lng: 21.051295 },
    { lat: 48.904465, lng: 21.051882 },
    { lat: 48.904523, lng: 21.052451 },
    { lat: 48.904672, lng: 21.05345 },
    { lat: 48.904815, lng: 21.0539 },
    { lat: 48.904916, lng: 21.054297 },
    { lat: 48.904985, lng: 21.054344 },
    { lat: 48.904981, lng: 21.054378 },
    { lat: 48.904967, lng: 21.05448 },
    { lat: 48.905325, lng: 21.055475 },
    { lat: 48.905502, lng: 21.055939 },
    { lat: 48.905614, lng: 21.056464 },
    { lat: 48.905704, lng: 21.056985 },
    { lat: 48.905722, lng: 21.05782 },
    { lat: 48.905709, lng: 21.058046 },
    { lat: 48.905686, lng: 21.058186 },
    { lat: 48.905685, lng: 21.058221 },
    { lat: 48.905665, lng: 21.058278 },
    { lat: 48.905532, lng: 21.058663 },
    { lat: 48.905519, lng: 21.058622 },
    { lat: 48.90538, lng: 21.058996 },
    { lat: 48.905069, lng: 21.05942 },
    { lat: 48.904887, lng: 21.060007 },
    { lat: 48.904645, lng: 21.060408 },
    { lat: 48.904367, lng: 21.060758 },
    { lat: 48.904225, lng: 21.061001 },
    { lat: 48.904113, lng: 21.061393 },
    { lat: 48.903968, lng: 21.06168 },
    { lat: 48.90386, lng: 21.062201 },
    { lat: 48.903518, lng: 21.063074 },
    { lat: 48.903388, lng: 21.063819 },
    { lat: 48.903283, lng: 21.064535 },
    { lat: 48.903284, lng: 21.065129 },
    { lat: 48.903232, lng: 21.065665 },
    { lat: 48.903155, lng: 21.066008 },
    { lat: 48.902981, lng: 21.066452 },
    { lat: 48.902684, lng: 21.067154 },
    { lat: 48.902303, lng: 21.067876 },
    { lat: 48.902075, lng: 21.068221 },
    { lat: 48.901998, lng: 21.068328 },
    { lat: 48.901809, lng: 21.068485 },
    { lat: 48.901322, lng: 21.068734 },
    { lat: 48.900987, lng: 21.069093 },
    { lat: 48.900558, lng: 21.069453 },
    { lat: 48.90016, lng: 21.069838 },
    { lat: 48.899952, lng: 21.070096 },
    { lat: 48.89965, lng: 21.070525 },
    { lat: 48.899432, lng: 21.070989 },
    { lat: 48.899234, lng: 21.071529 },
    { lat: 48.899089, lng: 21.071985 },
    { lat: 48.89891, lng: 21.072581 },
    { lat: 48.898865, lng: 21.072754 },
    { lat: 48.898767, lng: 21.073137 },
    { lat: 48.89868, lng: 21.073523 },
    { lat: 48.9065372, lng: 21.0837892 },
    { lat: 48.9067618, lng: 21.0838401 },
    { lat: 48.9069515, lng: 21.0839695 },
    { lat: 48.9072543, lng: 21.0839695 },
    { lat: 48.9073777, lng: 21.0844451 },
    { lat: 48.9080834, lng: 21.0854575 },
    { lat: 48.908175, lng: 21.0857519 },
    { lat: 48.9085101, lng: 21.0862619 },
    { lat: 48.908619, lng: 21.0865672 },
    { lat: 48.9087286, lng: 21.0874864 },
    { lat: 48.9087525, lng: 21.088338 },
    { lat: 48.9086027, lng: 21.0885805 },
    { lat: 48.9084706, lng: 21.0897191 },
    { lat: 48.9084304, lng: 21.0902147 },
    { lat: 48.9084682, lng: 21.0907937 },
    { lat: 48.9085605, lng: 21.0911489 },
    { lat: 48.9089147, lng: 21.0918382 },
    { lat: 48.9092758, lng: 21.0923592 },
    { lat: 48.909453, lng: 21.0924423 },
    { lat: 48.9096069, lng: 21.092672 },
    { lat: 48.9103181, lng: 21.0919363 },
    { lat: 48.9117318, lng: 21.0931647 },
    { lat: 48.9119865, lng: 21.0932807 },
    { lat: 48.9125443, lng: 21.093268 },
    { lat: 48.9130372, lng: 21.0935192 },
    { lat: 48.9138378, lng: 21.0944392 },
    { lat: 48.9141079, lng: 21.0942964 },
    { lat: 48.9141537, lng: 21.0944294 },
    { lat: 48.9146806, lng: 21.0944655 },
    { lat: 48.9160065, lng: 21.0944013 },
    { lat: 48.9172151, lng: 21.094539 },
    { lat: 48.9198256, lng: 21.0936091 },
    { lat: 48.9221179, lng: 21.0922769 },
    { lat: 48.9255574, lng: 21.0890336 },
    { lat: 48.9254481, lng: 21.0879484 },
    { lat: 48.9252861, lng: 21.0872822 },
    { lat: 48.9251554, lng: 21.0869304 },
    { lat: 48.9252099, lng: 21.0865284 },
    { lat: 48.925779, lng: 21.0853964 },
    { lat: 48.925473, lng: 21.0848425 },
    { lat: 48.9257193, lng: 21.0843851 },
    { lat: 48.9260594, lng: 21.0840413 },
    { lat: 48.926224, lng: 21.0843272 },
    { lat: 48.9270258, lng: 21.0831553 },
    { lat: 48.9281966, lng: 21.0838696 },
    { lat: 48.9287671, lng: 21.0839622 },
    { lat: 48.9292768, lng: 21.0838262 },
    { lat: 48.9299428, lng: 21.083848 },
    { lat: 48.9301244, lng: 21.0839662 },
    { lat: 48.9304566, lng: 21.0843567 },
    { lat: 48.930839, lng: 21.084106 },
    { lat: 48.9312656, lng: 21.0839645 },
    { lat: 48.9310236, lng: 21.0834738 },
    { lat: 48.9307877, lng: 21.0828292 },
    { lat: 48.9311328, lng: 21.0828182 },
    { lat: 48.9308844, lng: 21.0822675 },
    { lat: 48.9307749, lng: 21.0817005 },
    { lat: 48.9307367, lng: 21.0805547 },
    { lat: 48.9305543, lng: 21.0791194 },
    { lat: 48.9305419, lng: 21.0777283 },
    { lat: 48.9304708, lng: 21.076294 },
    { lat: 48.9305188, lng: 21.0757231 },
  ],
  Ovčie: [
    { lat: 48.9786934, lng: 20.9888177 },
    { lat: 48.9785878, lng: 20.9877 },
    { lat: 48.9777966, lng: 20.9848424 },
    { lat: 48.9774135, lng: 20.98368 },
    { lat: 48.9763955, lng: 20.9834912 },
    { lat: 48.9749099, lng: 20.9828415 },
    { lat: 48.9739715, lng: 20.9819033 },
    { lat: 48.9733843, lng: 20.9815698 },
    { lat: 48.972169, lng: 20.9811371 },
    { lat: 48.9719654, lng: 20.9805923 },
    { lat: 48.9717826, lng: 20.9804451 },
    { lat: 48.9713801, lng: 20.9798489 },
    { lat: 48.9711882, lng: 20.97929 },
    { lat: 48.9704405, lng: 20.9785605 },
    { lat: 48.9701478, lng: 20.9785241 },
    { lat: 48.9701321, lng: 20.9783566 },
    { lat: 48.9709542, lng: 20.9739242 },
    { lat: 48.9719056, lng: 20.9721748 },
    { lat: 48.9703721, lng: 20.9708313 },
    { lat: 48.9693632, lng: 20.9696688 },
    { lat: 48.968867, lng: 20.9686547 },
    { lat: 48.9684501, lng: 20.9679985 },
    { lat: 48.9674689, lng: 20.967257 },
    { lat: 48.9666233, lng: 20.9654544 },
    { lat: 48.9663944, lng: 20.9648684 },
    { lat: 48.9662235, lng: 20.9652823 },
    { lat: 48.966136, lng: 20.9651909 },
    { lat: 48.9657981, lng: 20.9659938 },
    { lat: 48.9651107, lng: 20.9670462 },
    { lat: 48.964823, lng: 20.9676375 },
    { lat: 48.9646587, lng: 20.9680172 },
    { lat: 48.9645826, lng: 20.9686012 },
    { lat: 48.9642284, lng: 20.9682028 },
    { lat: 48.9620314, lng: 20.9651229 },
    { lat: 48.959782, lng: 20.966732 },
    { lat: 48.9597371, lng: 20.9667713 },
    { lat: 48.9597512, lng: 20.9668193 },
    { lat: 48.957606, lng: 20.968284 },
    { lat: 48.957968, lng: 20.969092 },
    { lat: 48.958398, lng: 20.969715 },
    { lat: 48.958578, lng: 20.96996 },
    { lat: 48.958889, lng: 20.970384 },
    { lat: 48.958955, lng: 20.970473 },
    { lat: 48.959789, lng: 20.971605 },
    { lat: 48.96001, lng: 20.972008 },
    { lat: 48.960138, lng: 20.972399 },
    { lat: 48.960182, lng: 20.972676 },
    { lat: 48.960185, lng: 20.972721 },
    { lat: 48.960188, lng: 20.972771 },
    { lat: 48.960196, lng: 20.972875 },
    { lat: 48.960189, lng: 20.973217 },
    { lat: 48.960186, lng: 20.97334 },
    { lat: 48.960182, lng: 20.973564 },
    { lat: 48.960168, lng: 20.974295 },
    { lat: 48.960164, lng: 20.974517 },
    { lat: 48.960133, lng: 20.974914 },
    { lat: 48.960047, lng: 20.975198 },
    { lat: 48.959789, lng: 20.975791 },
    { lat: 48.959764, lng: 20.97585 },
    { lat: 48.959386, lng: 20.976721 },
    { lat: 48.959303, lng: 20.977151 },
    { lat: 48.959301, lng: 20.977505 },
    { lat: 48.959297, lng: 20.978856 },
    { lat: 48.959295, lng: 20.979094 },
    { lat: 48.959295, lng: 20.979285 },
    { lat: 48.959294, lng: 20.979328 },
    { lat: 48.959292, lng: 20.979687 },
    { lat: 48.959293, lng: 20.979771 },
    { lat: 48.959293, lng: 20.979961 },
    { lat: 48.959292, lng: 20.980187 },
    { lat: 48.959292, lng: 20.980309 },
    { lat: 48.959293, lng: 20.980403 },
    { lat: 48.95929, lng: 20.981892 },
    { lat: 48.95929, lng: 20.981915 },
    { lat: 48.959291, lng: 20.981964 },
    { lat: 48.959292, lng: 20.98199 },
    { lat: 48.959296, lng: 20.982583 },
    { lat: 48.959305, lng: 20.982654 },
    { lat: 48.959324, lng: 20.982823 },
    { lat: 48.959359, lng: 20.983115 },
    { lat: 48.959367, lng: 20.983183 },
    { lat: 48.959418, lng: 20.983613 },
    { lat: 48.959434, lng: 20.983744 },
    { lat: 48.959451, lng: 20.9839 },
    { lat: 48.959454, lng: 20.983925 },
    { lat: 48.959503, lng: 20.984328 },
    { lat: 48.959482, lng: 20.984652 },
    { lat: 48.959419, lng: 20.984932 },
    { lat: 48.959405, lng: 20.984991 },
    { lat: 48.959397, lng: 20.985026 },
    { lat: 48.959319, lng: 20.98522 },
    { lat: 48.959313, lng: 20.985236 },
    { lat: 48.959305, lng: 20.985253 },
    { lat: 48.95919, lng: 20.985465 },
    { lat: 48.959187, lng: 20.985472 },
    { lat: 48.959052, lng: 20.985604 },
    { lat: 48.9586456, lng: 20.9865646 },
    { lat: 48.958626, lng: 20.9867846 },
    { lat: 48.9587053, lng: 20.987376 },
    { lat: 48.9588203, lng: 20.9877956 },
    { lat: 48.9594686, lng: 20.9892923 },
    { lat: 48.9595085, lng: 20.9897849 },
    { lat: 48.9597893, lng: 20.9914669 },
    { lat: 48.9601124, lng: 20.9923603 },
    { lat: 48.9603109, lng: 20.9931578 },
    { lat: 48.9605463, lng: 20.9934988 },
    { lat: 48.961877, lng: 20.994366 },
    { lat: 48.9623849, lng: 20.9944777 },
    { lat: 48.9637495, lng: 20.9938774 },
    { lat: 48.9640201, lng: 20.9942648 },
    { lat: 48.9645969, lng: 20.995577 },
    { lat: 48.9651548, lng: 20.9963888 },
    { lat: 48.9654528, lng: 20.9965843 },
    { lat: 48.9660854, lng: 20.9967586 },
    { lat: 48.9662359, lng: 20.996745 },
    { lat: 48.9664246, lng: 20.9965673 },
    { lat: 48.9665515, lng: 20.9961446 },
    { lat: 48.9670162, lng: 20.9958331 },
    { lat: 48.9673926, lng: 20.9950318 },
    { lat: 48.9681239, lng: 20.9946455 },
    { lat: 48.9685851, lng: 20.9940988 },
    { lat: 48.9690559, lng: 20.9939014 },
    { lat: 48.9696457, lng: 20.9932572 },
    { lat: 48.970423, lng: 20.9930386 },
    { lat: 48.971692, lng: 20.9930221 },
    { lat: 48.9720331, lng: 20.9929173 },
    { lat: 48.9730727, lng: 20.9927038 },
    { lat: 48.9752472, lng: 20.9918893 },
    { lat: 48.9754914, lng: 20.9917195 },
    { lat: 48.9756507, lng: 20.9914964 },
    { lat: 48.9764016, lng: 20.9911289 },
    { lat: 48.9770724, lng: 20.9909248 },
    { lat: 48.9774068, lng: 20.9909319 },
    { lat: 48.9779417, lng: 20.9912075 },
    { lat: 48.9782716, lng: 20.9914503 },
    { lat: 48.9783758, lng: 20.9917136 },
    { lat: 48.9794015, lng: 20.9917569 },
    { lat: 48.9793297, lng: 20.991623 },
    { lat: 48.9791043, lng: 20.9899494 },
    { lat: 48.9786934, lng: 20.9888177 },
  ],
  DulovaVes: [
    { lat: 48.9562056, lng: 21.2854477 },
    { lat: 48.9560455, lng: 21.2854418 },
    { lat: 48.9558025, lng: 21.2863952 },
    { lat: 48.9558467, lng: 21.2869629 },
    { lat: 48.9553908, lng: 21.2882127 },
    { lat: 48.9553312, lng: 21.2889108 },
    { lat: 48.9551684, lng: 21.2895206 },
    { lat: 48.9551942, lng: 21.2903681 },
    { lat: 48.9550507, lng: 21.2919336 },
    { lat: 48.95515, lng: 21.2920899 },
    { lat: 48.9551071, lng: 21.292602 },
    { lat: 48.9554451, lng: 21.2940603 },
    { lat: 48.9551255, lng: 21.2945006 },
    { lat: 48.955013, lng: 21.2947634 },
    { lat: 48.9548762, lng: 21.2948865 },
    { lat: 48.9546892, lng: 21.2954371 },
    { lat: 48.9546594, lng: 21.2960833 },
    { lat: 48.9548538, lng: 21.2963343 },
    { lat: 48.9549881, lng: 21.2967212 },
    { lat: 48.954901, lng: 21.2972211 },
    { lat: 48.9547475, lng: 21.2977256 },
    { lat: 48.9548895, lng: 21.2978807 },
    { lat: 48.9548344, lng: 21.2983669 },
    { lat: 48.9546541, lng: 21.298952 },
    { lat: 48.9540228, lng: 21.2997944 },
    { lat: 48.9527179, lng: 21.299406 },
    { lat: 48.9512371, lng: 21.2992211 },
    { lat: 48.9504614, lng: 21.2989851 },
    { lat: 48.9503439, lng: 21.2990059 },
    { lat: 48.9491799, lng: 21.3047705 },
    { lat: 48.9443215, lng: 21.3043052 },
    { lat: 48.9416497, lng: 21.3042604 },
    { lat: 48.9406155, lng: 21.3058852 },
    { lat: 48.9361462, lng: 21.3145117 },
    { lat: 48.9351296, lng: 21.3162075 },
    { lat: 48.9331463, lng: 21.3197263 },
    { lat: 48.9292564, lng: 21.3271867 },
    { lat: 48.9285935, lng: 21.328521 },
    { lat: 48.930405, lng: 21.329267 },
    { lat: 48.9398851, lng: 21.332622 },
    { lat: 48.9411357, lng: 21.3285978 },
    { lat: 48.9417241, lng: 21.3268785 },
    { lat: 48.9422078, lng: 21.3251943 },
    { lat: 48.9427389, lng: 21.3239615 },
    { lat: 48.9436148, lng: 21.321374 },
    { lat: 48.94433, lng: 21.3192669 },
    { lat: 48.9459846, lng: 21.3186111 },
    { lat: 48.9462976, lng: 21.3190264 },
    { lat: 48.9464808, lng: 21.3191368 },
    { lat: 48.9467363, lng: 21.3187668 },
    { lat: 48.9472425, lng: 21.3177177 },
    { lat: 48.9473439, lng: 21.3172348 },
    { lat: 48.9473743, lng: 21.3167505 },
    { lat: 48.9474426, lng: 21.3165127 },
    { lat: 48.947834, lng: 21.3162187 },
    { lat: 48.9477276, lng: 21.3160059 },
    { lat: 48.947879, lng: 21.3150617 },
    { lat: 48.9485396, lng: 21.314615 },
    { lat: 48.9487014, lng: 21.3147566 },
    { lat: 48.9489148, lng: 21.3145226 },
    { lat: 48.9491479, lng: 21.3135598 },
    { lat: 48.9496729, lng: 21.3125347 },
    { lat: 48.949939, lng: 21.3121502 },
    { lat: 48.9500101, lng: 21.3118979 },
    { lat: 48.9502189, lng: 21.3119879 },
    { lat: 48.9502002, lng: 21.3118212 },
    { lat: 48.9509257, lng: 21.3121759 },
    { lat: 48.9509092, lng: 21.3123034 },
    { lat: 48.9512012, lng: 21.3128033 },
    { lat: 48.9513021, lng: 21.3128653 },
    { lat: 48.9507624, lng: 21.3143705 },
    { lat: 48.950259, lng: 21.3161796 },
    { lat: 48.9510378, lng: 21.3167003 },
    { lat: 48.9510725, lng: 21.317029 },
    { lat: 48.9513553, lng: 21.3171185 },
    { lat: 48.9512733, lng: 21.3175739 },
    { lat: 48.9512591, lng: 21.3179931 },
    { lat: 48.9514499, lng: 21.3184993 },
    { lat: 48.9515527, lng: 21.3191484 },
    { lat: 48.9517084, lng: 21.3197211 },
    { lat: 48.9519792, lng: 21.3201931 },
    { lat: 48.9523855, lng: 21.3218727 },
    { lat: 48.9577479, lng: 21.312693 },
    { lat: 48.9609259, lng: 21.3147483 },
    { lat: 48.9612571, lng: 21.3150185 },
    { lat: 48.9612481, lng: 21.3150947 },
    { lat: 48.9613756, lng: 21.3154056 },
    { lat: 48.9614863, lng: 21.3153373 },
    { lat: 48.9617403, lng: 21.3160047 },
    { lat: 48.9616894, lng: 21.3163233 },
    { lat: 48.9617482, lng: 21.3165989 },
    { lat: 48.9617272, lng: 21.3169413 },
    { lat: 48.9622872, lng: 21.3173091 },
    { lat: 48.962503, lng: 21.3176288 },
    { lat: 48.9626289, lng: 21.3185178 },
    { lat: 48.9625799, lng: 21.3188024 },
    { lat: 48.9626162, lng: 21.3191064 },
    { lat: 48.9626675, lng: 21.3192038 },
    { lat: 48.9627411, lng: 21.3188842 },
    { lat: 48.9631116, lng: 21.3178478 },
    { lat: 48.9666478, lng: 21.3081695 },
    { lat: 48.9650933, lng: 21.2980231 },
    { lat: 48.9651229, lng: 21.2932981 },
    { lat: 48.9650901, lng: 21.2915531 },
    { lat: 48.965147, lng: 21.2898358 },
    { lat: 48.9650832, lng: 21.2894442 },
    { lat: 48.9650631, lng: 21.2881581 },
    { lat: 48.9650752, lng: 21.2881057 },
    { lat: 48.9652271, lng: 21.288109 },
    { lat: 48.9652185, lng: 21.2879738 },
    { lat: 48.9657067, lng: 21.2878994 },
    { lat: 48.9655502, lng: 21.2871562 },
    { lat: 48.9655818, lng: 21.2871189 },
    { lat: 48.9652632, lng: 21.2828333 },
    { lat: 48.9652228, lng: 21.2820973 },
    { lat: 48.9652576, lng: 21.2819878 },
    { lat: 48.9650059, lng: 21.2819984 },
    { lat: 48.9629884, lng: 21.2812645 },
    { lat: 48.9604407, lng: 21.2805988 },
    { lat: 48.9603363, lng: 21.2807565 },
    { lat: 48.9588405, lng: 21.280304 },
    { lat: 48.9582194, lng: 21.2811914 },
    { lat: 48.9581139, lng: 21.2805027 },
    { lat: 48.9580221, lng: 21.2810703 },
    { lat: 48.9578672, lng: 21.2815581 },
    { lat: 48.9575253, lng: 21.2822289 },
    { lat: 48.9574668, lng: 21.2826702 },
    { lat: 48.9568857, lng: 21.2835089 },
    { lat: 48.9567535, lng: 21.2844077 },
    { lat: 48.9565389, lng: 21.2849026 },
    { lat: 48.9563656, lng: 21.2854533 },
    { lat: 48.9562056, lng: 21.2854477 },
  ],
  Medzany: [
    { lat: 49.058544, lng: 21.146862 },
    { lat: 49.059011, lng: 21.14493 },
    { lat: 49.058892, lng: 21.144358 },
    { lat: 49.058812, lng: 21.143932 },
    { lat: 49.058713, lng: 21.143428 },
    { lat: 49.05826, lng: 21.141241 },
    { lat: 49.058041, lng: 21.140562 },
    { lat: 49.057996, lng: 21.14057 },
    { lat: 49.056899, lng: 21.140777 },
    { lat: 49.056875, lng: 21.14078 },
    { lat: 49.056844, lng: 21.140783 },
    { lat: 49.056179, lng: 21.139046 },
    { lat: 49.056191, lng: 21.13901 },
    { lat: 49.056165, lng: 21.138929 },
    { lat: 49.056159, lng: 21.138947 },
    { lat: 49.056138, lng: 21.13897 },
    { lat: 49.056063, lng: 21.138978 },
    { lat: 49.055999, lng: 21.138987 },
    { lat: 49.05583, lng: 21.139037 },
    { lat: 49.05575, lng: 21.139028 },
    { lat: 49.055705, lng: 21.139082 },
    { lat: 49.055588, lng: 21.139046 },
    { lat: 49.055296, lng: 21.138813 },
    { lat: 49.055048, lng: 21.138614 },
    { lat: 49.054708, lng: 21.138277 },
    { lat: 49.054496, lng: 21.138125 },
    { lat: 49.05426, lng: 21.13796 },
    { lat: 49.054238, lng: 21.137944 },
    { lat: 49.054183, lng: 21.137905 },
    { lat: 49.054136, lng: 21.137888 },
    { lat: 49.053976, lng: 21.137829 },
    { lat: 49.053737, lng: 21.137714 },
    { lat: 49.053414, lng: 21.137501 },
    { lat: 49.05328, lng: 21.137444 },
    { lat: 49.053109, lng: 21.137406 },
    { lat: 49.053024, lng: 21.137403 },
    { lat: 49.052766, lng: 21.137413 },
    { lat: 49.052664, lng: 21.137384 },
    { lat: 49.052421, lng: 21.137375 },
    { lat: 49.052263, lng: 21.137413 },
    { lat: 49.052048, lng: 21.137442 },
    { lat: 49.051773, lng: 21.137381 },
    { lat: 49.051513, lng: 21.137293 },
    { lat: 49.051292, lng: 21.13719 },
    { lat: 49.051289, lng: 21.137098 },
    { lat: 49.05137, lng: 21.136937 },
    { lat: 49.051464, lng: 21.136555 },
    { lat: 49.051434, lng: 21.136421 },
    { lat: 49.051499, lng: 21.136002 },
    { lat: 49.051499, lng: 21.135855 },
    { lat: 49.051473, lng: 21.135602 },
    { lat: 49.051516, lng: 21.13546 },
    { lat: 49.051529, lng: 21.135288 },
    { lat: 49.051483, lng: 21.134976 },
    { lat: 49.051523, lng: 21.134715 },
    { lat: 49.051527, lng: 21.134527 },
    { lat: 49.051423, lng: 21.134315 },
    { lat: 49.05144, lng: 21.13419 },
    { lat: 49.05149, lng: 21.134031 },
    { lat: 49.051588, lng: 21.133584 },
    { lat: 49.051595, lng: 21.133419 },
    { lat: 49.051574, lng: 21.133211 },
    { lat: 49.051553, lng: 21.133086 },
    { lat: 49.051528, lng: 21.132974 },
    { lat: 49.051431, lng: 21.132786 },
    { lat: 49.051406, lng: 21.13253 },
    { lat: 49.051412, lng: 21.132419 },
    { lat: 49.051356, lng: 21.131986 },
    { lat: 49.051302, lng: 21.131893 },
    { lat: 49.051282, lng: 21.131785 },
    { lat: 49.051237, lng: 21.131694 },
    { lat: 49.051225, lng: 21.131602 },
    { lat: 49.051107, lng: 21.131501 },
    { lat: 49.051093, lng: 21.131365 },
    { lat: 49.051067, lng: 21.131221 },
    { lat: 49.05106, lng: 21.131178 },
    { lat: 49.051062, lng: 21.13108 },
    { lat: 49.050975, lng: 21.130952 },
    { lat: 49.050866, lng: 21.130889 },
    { lat: 49.050758, lng: 21.130755 },
    { lat: 49.05069, lng: 21.130623 },
    { lat: 49.050655, lng: 21.130586 },
    { lat: 49.05061, lng: 21.130204 },
    { lat: 49.050584, lng: 21.129907 },
    { lat: 49.05049, lng: 21.129748 },
    { lat: 49.050446, lng: 21.129464 },
    { lat: 49.050464, lng: 21.129268 },
    { lat: 49.050434, lng: 21.129144 },
    { lat: 49.050316, lng: 21.129044 },
    { lat: 49.050263, lng: 21.128809 },
    { lat: 49.05013, lng: 21.128694 },
    { lat: 49.050127, lng: 21.128543 },
    { lat: 49.050051, lng: 21.128526 },
    { lat: 49.049949, lng: 21.128398 },
    { lat: 49.049781, lng: 21.128265 },
    { lat: 49.049719, lng: 21.128071 },
    { lat: 49.049653, lng: 21.128004 },
    { lat: 49.049547, lng: 21.127971 },
    { lat: 49.049337, lng: 21.127854 },
    { lat: 49.049187, lng: 21.12783 },
    { lat: 49.049109, lng: 21.127789 },
    { lat: 49.04912, lng: 21.12757 },
    { lat: 49.049081, lng: 21.12741 },
    { lat: 49.04906, lng: 21.127232 },
    { lat: 49.048922, lng: 21.12693 },
    { lat: 49.04876, lng: 21.126728 },
    { lat: 49.048682, lng: 21.126544 },
    { lat: 49.048568, lng: 21.1264 },
    { lat: 49.048458, lng: 21.126196 },
    { lat: 49.048406, lng: 21.126148 },
    { lat: 49.0481, lng: 21.125894 },
    { lat: 49.047718, lng: 21.125258 },
    { lat: 49.047335, lng: 21.124618 },
    { lat: 49.046579, lng: 21.123446 },
    { lat: 49.046585, lng: 21.123415 },
    { lat: 49.046202, lng: 21.12334 },
    { lat: 49.045794, lng: 21.123586 },
    { lat: 49.04561, lng: 21.12361 },
    { lat: 49.045489, lng: 21.123536 },
    { lat: 49.045262, lng: 21.123128 },
    { lat: 49.0449197, lng: 21.1234917 },
    { lat: 49.0440443, lng: 21.1240623 },
    { lat: 49.0430439, lng: 21.1250177 },
    { lat: 49.0425199, lng: 21.1253863 },
    { lat: 49.040318, lng: 21.1284382 },
    { lat: 49.0397444, lng: 21.1290887 },
    { lat: 49.0394324, lng: 21.1293649 },
    { lat: 49.0388162, lng: 21.1296975 },
    { lat: 49.0377841, lng: 21.1299381 },
    { lat: 49.0371162, lng: 21.129994 },
    { lat: 49.036451, lng: 21.1302423 },
    { lat: 49.0354834, lng: 21.1308486 },
    { lat: 49.0349227, lng: 21.1312976 },
    { lat: 49.0345064, lng: 21.1317615 },
    { lat: 49.0339886, lng: 21.1321876 },
    { lat: 49.033691, lng: 21.1321664 },
    { lat: 49.0332954, lng: 21.1319764 },
    { lat: 49.033, lng: 21.1317036 },
    { lat: 49.03289, lng: 21.1316769 },
    { lat: 49.0326565, lng: 21.1316241 },
    { lat: 49.0324439, lng: 21.1317036 },
    { lat: 49.0322666, lng: 21.1321416 },
    { lat: 49.0318192, lng: 21.1328301 },
    { lat: 49.0314277, lng: 21.1338602 },
    { lat: 49.031258, lng: 21.1341918 },
    { lat: 49.0313346, lng: 21.1347611 },
    { lat: 49.0311924, lng: 21.1355946 },
    { lat: 49.0311446, lng: 21.1365402 },
    { lat: 49.030742, lng: 21.138283 },
    { lat: 49.0305351, lng: 21.1386822 },
    { lat: 49.0303883, lng: 21.1393396 },
    { lat: 49.0305327, lng: 21.1399527 },
    { lat: 49.0304575, lng: 21.140423 },
    { lat: 49.0300707, lng: 21.1420278 },
    { lat: 49.0297383, lng: 21.144078 },
    { lat: 49.029269, lng: 21.1449631 },
    { lat: 49.0309425, lng: 21.1487707 },
    { lat: 49.0319041, lng: 21.1503935 },
    { lat: 49.0322789, lng: 21.1508585 },
    { lat: 49.0334194, lng: 21.1515487 },
    { lat: 49.0338995, lng: 21.1519663 },
    { lat: 49.0344368, lng: 21.1522286 },
    { lat: 49.0350315, lng: 21.1527425 },
    { lat: 49.0352792, lng: 21.1530662 },
    { lat: 49.0351973, lng: 21.1533272 },
    { lat: 49.0352705, lng: 21.1535396 },
    { lat: 49.0350396, lng: 21.154141 },
    { lat: 49.035019, lng: 21.1548036 },
    { lat: 49.0350901, lng: 21.1552051 },
    { lat: 49.0350511, lng: 21.1554332 },
    { lat: 49.0348821, lng: 21.1554954 },
    { lat: 49.0349241, lng: 21.1559668 },
    { lat: 49.0349046, lng: 21.1562447 },
    { lat: 49.0347852, lng: 21.1563489 },
    { lat: 49.0347605, lng: 21.1564532 },
    { lat: 49.0347274, lng: 21.1569079 },
    { lat: 49.0346122, lng: 21.1573216 },
    { lat: 49.0345665, lng: 21.1578306 },
    { lat: 49.0345879, lng: 21.1580106 },
    { lat: 49.0344801, lng: 21.1581956 },
    { lat: 49.0345977, lng: 21.1585363 },
    { lat: 49.0345365, lng: 21.1586678 },
    { lat: 49.0345481, lng: 21.1589219 },
    { lat: 49.034464, lng: 21.1591519 },
    { lat: 49.0345043, lng: 21.1594631 },
    { lat: 49.0345959, lng: 21.1596236 },
    { lat: 49.0345229, lng: 21.1605162 },
    { lat: 49.0345397, lng: 21.1606233 },
    { lat: 49.0346928, lng: 21.160729 },
    { lat: 49.0350201, lng: 21.1611201 },
    { lat: 49.0350687, lng: 21.1617722 },
    { lat: 49.0349885, lng: 21.1620097 },
    { lat: 49.03513, lng: 21.1622508 },
    { lat: 49.0353143, lng: 21.1627765 },
    { lat: 49.0353935, lng: 21.1628274 },
    { lat: 49.0357001, lng: 21.1636307 },
    { lat: 49.0360464, lng: 21.1642274 },
    { lat: 49.036328, lng: 21.1643094 },
    { lat: 49.0364707, lng: 21.1644766 },
    { lat: 49.0367111, lng: 21.1649651 },
    { lat: 49.0367983, lng: 21.1654455 },
    { lat: 49.0366669, lng: 21.1656373 },
    { lat: 49.0363617, lng: 21.1672021 },
    { lat: 49.0363018, lng: 21.1681232 },
    { lat: 49.0366506, lng: 21.1695493 },
    { lat: 49.0365456, lng: 21.1701481 },
    { lat: 49.0366068, lng: 21.1703455 },
    { lat: 49.0359893, lng: 21.1721038 },
    { lat: 49.0358172, lng: 21.172722 },
    { lat: 49.035663, lng: 21.1736457 },
    { lat: 49.0358868, lng: 21.1742169 },
    { lat: 49.0359108, lng: 21.1749798 },
    { lat: 49.0360905, lng: 21.1754707 },
    { lat: 49.0362483, lng: 21.1755546 },
    { lat: 49.036946, lng: 21.1755147 },
    { lat: 49.0375403, lng: 21.1749457 },
    { lat: 49.0379331, lng: 21.1748376 },
    { lat: 49.038158, lng: 21.1746928 },
    { lat: 49.0382372, lng: 21.1744213 },
    { lat: 49.0384346, lng: 21.1741448 },
    { lat: 49.0388638, lng: 21.1739127 },
    { lat: 49.0400902, lng: 21.1735428 },
    { lat: 49.0409312, lng: 21.1736269 },
    { lat: 49.0419183, lng: 21.1728102 },
    { lat: 49.042321, lng: 21.1725503 },
    { lat: 49.0426784, lng: 21.17236 },
    { lat: 49.0433275, lng: 21.1722443 },
    { lat: 49.0436717, lng: 21.1720248 },
    { lat: 49.0437592, lng: 21.1718761 },
    { lat: 49.0441017, lng: 21.1704902 },
    { lat: 49.0442578, lng: 21.1679387 },
    { lat: 49.0441407, lng: 21.1662548 },
    { lat: 49.0441921, lng: 21.1659186 },
    { lat: 49.0443102, lng: 21.1656337 },
    { lat: 49.0444663, lng: 21.1654462 },
    { lat: 49.0456139, lng: 21.1649038 },
    { lat: 49.0461555, lng: 21.1649817 },
    { lat: 49.0464876, lng: 21.1643785 },
    { lat: 49.0471783, lng: 21.1635458 },
    { lat: 49.0474799, lng: 21.1633383 },
    { lat: 49.0493689, lng: 21.1628164 },
    { lat: 49.0495525, lng: 21.1626857 },
    { lat: 49.0511367, lng: 21.1610318 },
    { lat: 49.0538997, lng: 21.1595792 },
    { lat: 49.0540843, lng: 21.1595793 },
    { lat: 49.0554223, lng: 21.1601085 },
    { lat: 49.055252, lng: 21.159563 },
    { lat: 49.0548372, lng: 21.1576554 },
    { lat: 49.0546585, lng: 21.157126 },
    { lat: 49.0546058, lng: 21.1566282 },
    { lat: 49.0540796, lng: 21.1558604 },
    { lat: 49.0538063, lng: 21.1552583 },
    { lat: 49.0536848, lng: 21.1541556 },
    { lat: 49.0539449, lng: 21.1539088 },
    { lat: 49.0539633, lng: 21.1533506 },
    { lat: 49.054531, lng: 21.1529427 },
    { lat: 49.0545477, lng: 21.1526505 },
    { lat: 49.0546213, lng: 21.1524855 },
    { lat: 49.0546729, lng: 21.1524771 },
    { lat: 49.0551366, lng: 21.1529814 },
    { lat: 49.0552893, lng: 21.1528654 },
    { lat: 49.0553976, lng: 21.1524775 },
    { lat: 49.0552469, lng: 21.1517301 },
    { lat: 49.0552783, lng: 21.1513072 },
    { lat: 49.0552088, lng: 21.1511656 },
    { lat: 49.0553815, lng: 21.1510055 },
    { lat: 49.0555221, lng: 21.1510056 },
    { lat: 49.0557429, lng: 21.1506895 },
    { lat: 49.0557883, lng: 21.1503492 },
    { lat: 49.0557516, lng: 21.149508 },
    { lat: 49.0557828, lng: 21.1493902 },
    { lat: 49.0561823, lng: 21.1493929 },
    { lat: 49.0563638, lng: 21.1492931 },
    { lat: 49.0576471, lng: 21.1481289 },
    { lat: 49.058544, lng: 21.146862 },
  ],
  Bertotovce: [
    { lat: 49.0369671, lng: 20.9908409 },
    { lat: 49.0356243, lng: 20.9917953 },
    { lat: 49.0348891, lng: 20.9922277 },
    { lat: 49.0341681, lng: 20.9927859 },
    { lat: 49.0335622, lng: 20.9932869 },
    { lat: 49.0326535, lng: 20.9943003 },
    { lat: 49.0319458, lng: 20.9945124 },
    { lat: 49.0313031, lng: 20.9948838 },
    { lat: 49.030882, lng: 20.9954593 },
    { lat: 49.0302486, lng: 20.9958755 },
    { lat: 49.0298014, lng: 20.9959315 },
    { lat: 49.0293429, lng: 20.9957875 },
    { lat: 49.0289537, lng: 20.9962726 },
    { lat: 49.0266931, lng: 20.9977893 },
    { lat: 49.0275623, lng: 20.9989967 },
    { lat: 49.0268348, lng: 20.9999375 },
    { lat: 49.0255799, lng: 21.0011713 },
    { lat: 49.0245663, lng: 21.0016318 },
    { lat: 49.024327, lng: 21.0016396 },
    { lat: 49.0236183, lng: 21.0018553 },
    { lat: 49.0227042, lng: 21.0024018 },
    { lat: 49.022969, lng: 21.003196 },
    { lat: 49.0218159, lng: 21.0040321 },
    { lat: 49.0212892, lng: 21.0029694 },
    { lat: 49.0209363, lng: 21.003087 },
    { lat: 49.0206413, lng: 21.0030524 },
    { lat: 49.0202292, lng: 21.0035607 },
    { lat: 49.0200643, lng: 21.0035076 },
    { lat: 49.0196075, lng: 21.0036467 },
    { lat: 49.0190408, lng: 21.0040057 },
    { lat: 49.0187351, lng: 21.0042896 },
    { lat: 49.0184861, lng: 21.0043207 },
    { lat: 49.0176137, lng: 21.0051389 },
    { lat: 49.0174066, lng: 21.0052375 },
    { lat: 49.016605, lng: 21.0058485 },
    { lat: 49.0162318, lng: 21.0062024 },
    { lat: 49.0162931, lng: 21.0040596 },
    { lat: 49.015847, lng: 21.0042922 },
    { lat: 49.0156606, lng: 21.0043024 },
    { lat: 49.0150041, lng: 21.0050092 },
    { lat: 49.0134441, lng: 21.0057286 },
    { lat: 49.0116459, lng: 21.0074837 },
    { lat: 49.012056, lng: 21.0118474 },
    { lat: 49.0126762, lng: 21.0129085 },
    { lat: 49.0131453, lng: 21.0135578 },
    { lat: 49.0126572, lng: 21.0146653 },
    { lat: 49.0126714, lng: 21.0160937 },
    { lat: 49.0127405, lng: 21.0169629 },
    { lat: 49.0126787, lng: 21.0177256 },
    { lat: 49.01329, lng: 21.0205841 },
    { lat: 49.01346, lng: 21.0215468 },
    { lat: 49.0130609, lng: 21.022554 },
    { lat: 49.0130112, lng: 21.0231669 },
    { lat: 49.0128517, lng: 21.024175 },
    { lat: 49.0120404, lng: 21.026072 },
    { lat: 49.0118968, lng: 21.0266052 },
    { lat: 49.0113782, lng: 21.0280211 },
    { lat: 49.010326, lng: 21.0299874 },
    { lat: 49.0099431, lng: 21.0304949 },
    { lat: 49.0095686, lng: 21.0311964 },
    { lat: 49.0093421, lng: 21.0317703 },
    { lat: 49.0089818, lng: 21.0323212 },
    { lat: 49.0084011, lng: 21.0329397 },
    { lat: 49.0091803, lng: 21.0346086 },
    { lat: 49.0088189, lng: 21.0356751 },
    { lat: 49.0084109, lng: 21.0363777 },
    { lat: 49.0085405, lng: 21.0369759 },
    { lat: 49.00879, lng: 21.0370686 },
    { lat: 49.0098502, lng: 21.0395879 },
    { lat: 49.0107186, lng: 21.041124 },
    { lat: 49.0114166, lng: 21.0426224 },
    { lat: 49.0123106, lng: 21.0435335 },
    { lat: 49.0125442, lng: 21.0436529 },
    { lat: 49.012651, lng: 21.043861 },
    { lat: 49.0131686, lng: 21.0434399 },
    { lat: 49.0151556, lng: 21.0412113 },
    { lat: 49.0159378, lng: 21.0422807 },
    { lat: 49.0164171, lng: 21.041349 },
    { lat: 49.0164912, lng: 21.0410609 },
    { lat: 49.0169838, lng: 21.0402565 },
    { lat: 49.0175153, lng: 21.0395807 },
    { lat: 49.0176443, lng: 21.0397406 },
    { lat: 49.0191024, lng: 21.0381668 },
    { lat: 49.0194635, lng: 21.0382328 },
    { lat: 49.0197652, lng: 21.040477 },
    { lat: 49.0206493, lng: 21.0421201 },
    { lat: 49.0210132, lng: 21.0429623 },
    { lat: 49.0220943, lng: 21.0440199 },
    { lat: 49.0235874, lng: 21.0446014 },
    { lat: 49.0239146, lng: 21.0444531 },
    { lat: 49.0260933, lng: 21.0445854 },
    { lat: 49.0274057, lng: 21.0454536 },
    { lat: 49.0282207, lng: 21.0452574 },
    { lat: 49.0282921, lng: 21.045719 },
    { lat: 49.0289389, lng: 21.0453598 },
    { lat: 49.0295692, lng: 21.0454673 },
    { lat: 49.0309294, lng: 21.0443482 },
    { lat: 49.0326592, lng: 21.0431726 },
    { lat: 49.0330517, lng: 21.0433324 },
    { lat: 49.0344433, lng: 21.0427679 },
    { lat: 49.036727, lng: 21.0418366 },
    { lat: 49.0366408, lng: 21.040535 },
    { lat: 49.0365137, lng: 21.0402974 },
    { lat: 49.0365219, lng: 21.039961 },
    { lat: 49.0366258, lng: 21.039662 },
    { lat: 49.0365692, lng: 21.0392717 },
    { lat: 49.0357398, lng: 21.039446 },
    { lat: 49.0351604, lng: 21.0393168 },
    { lat: 49.0351587, lng: 21.037946 },
    { lat: 49.0349846, lng: 21.0363376 },
    { lat: 49.0347664, lng: 21.036344 },
    { lat: 49.0345216, lng: 21.0289796 },
    { lat: 49.0323942, lng: 21.0286547 },
    { lat: 49.0322821, lng: 21.0287034 },
    { lat: 49.0318372, lng: 21.0286225 },
    { lat: 49.0319767, lng: 21.0266623 },
    { lat: 49.0333652, lng: 21.0267148 },
    { lat: 49.033392, lng: 21.0248154 },
    { lat: 49.0351627, lng: 21.0246574 },
    { lat: 49.035252, lng: 21.0225348 },
    { lat: 49.03464, lng: 21.0223684 },
    { lat: 49.0345242, lng: 21.0189395 },
    { lat: 49.0354334, lng: 21.0187605 },
    { lat: 49.0368708, lng: 21.0180476 },
    { lat: 49.0366572, lng: 21.0165723 },
    { lat: 49.0366114, lng: 21.0145563 },
    { lat: 49.0363602, lng: 21.0093716 },
    { lat: 49.0364194, lng: 21.0074402 },
    { lat: 49.0368263, lng: 21.0037236 },
    { lat: 49.0372165, lng: 21.0015897 },
    { lat: 49.0374486, lng: 21.0015286 },
    { lat: 49.0376594, lng: 21.0013698 },
    { lat: 49.0382774, lng: 21.0012722 },
    { lat: 49.03814, lng: 20.9991092 },
    { lat: 49.0377465, lng: 20.9954322 },
    { lat: 49.0370982, lng: 20.9933498 },
    { lat: 49.0369671, lng: 20.9908409 },
  ],
  Bajerov: [
    { lat: 48.9716056, lng: 21.0973648 },
    { lat: 48.9705532, lng: 21.0983444 },
    { lat: 48.9702229, lng: 21.0985694 },
    { lat: 48.9693829, lng: 21.098944 },
    { lat: 48.9681818, lng: 21.0990014 },
    { lat: 48.9679843, lng: 21.1001082 },
    { lat: 48.9673004, lng: 21.1010998 },
    { lat: 48.9647515, lng: 21.1029009 },
    { lat: 48.9635435, lng: 21.1033409 },
    { lat: 48.9615268, lng: 21.1032694 },
    { lat: 48.9614657, lng: 21.1031593 },
    { lat: 48.9609435, lng: 21.1029447 },
    { lat: 48.9608811, lng: 21.102858 },
    { lat: 48.9607314, lng: 21.1028612 },
    { lat: 48.9604089, lng: 21.1019947 },
    { lat: 48.9603919, lng: 21.1016414 },
    { lat: 48.9602234, lng: 21.1011987 },
    { lat: 48.960101, lng: 21.1010298 },
    { lat: 48.9600088, lng: 21.1006824 },
    { lat: 48.9597857, lng: 21.1001832 },
    { lat: 48.9595884, lng: 21.0998923 },
    { lat: 48.959425, lng: 21.0998124 },
    { lat: 48.95931, lng: 21.0995645 },
    { lat: 48.9587775, lng: 21.0990298 },
    { lat: 48.9585697, lng: 21.0986237 },
    { lat: 48.9584126, lng: 21.098534 },
    { lat: 48.9579879, lng: 21.0984473 },
    { lat: 48.9579009, lng: 21.0983026 },
    { lat: 48.9578237, lng: 21.097924 },
    { lat: 48.9576429, lng: 21.097691 },
    { lat: 48.957362, lng: 21.0976019 },
    { lat: 48.9572426, lng: 21.097461 },
    { lat: 48.9564592, lng: 21.097036 },
    { lat: 48.9569035, lng: 21.0959416 },
    { lat: 48.9568952, lng: 21.0954713 },
    { lat: 48.9573979, lng: 21.094272 },
    { lat: 48.9587908, lng: 21.0934166 },
    { lat: 48.9581124, lng: 21.0912584 },
    { lat: 48.9576887, lng: 21.0895293 },
    { lat: 48.9573318, lng: 21.089631 },
    { lat: 48.9563706, lng: 21.0901811 },
    { lat: 48.9558061, lng: 21.0903756 },
    { lat: 48.9556821, lng: 21.0907486 },
    { lat: 48.9545124, lng: 21.0900039 },
    { lat: 48.9544817, lng: 21.0899661 },
    { lat: 48.9541212, lng: 21.0902321 },
    { lat: 48.9536048, lng: 21.0908634 },
    { lat: 48.9533385, lng: 21.0910525 },
    { lat: 48.9533078, lng: 21.0913226 },
    { lat: 48.9524958, lng: 21.0920421 },
    { lat: 48.9520348, lng: 21.0926152 },
    { lat: 48.9517195, lng: 21.0928187 },
    { lat: 48.9515445, lng: 21.0936055 },
    { lat: 48.9510597, lng: 21.0943826 },
    { lat: 48.9509478, lng: 21.0948582 },
    { lat: 48.9507991, lng: 21.0958371 },
    { lat: 48.9507258, lng: 21.0974363 },
    { lat: 48.9498778, lng: 21.0991043 },
    { lat: 48.9498171, lng: 21.0991761 },
    { lat: 48.9497795, lng: 21.0990543 },
    { lat: 48.9493859, lng: 21.0995267 },
    { lat: 48.9495106, lng: 21.0996557 },
    { lat: 48.9494751, lng: 21.0999548 },
    { lat: 48.9495233, lng: 21.1001929 },
    { lat: 48.9498485, lng: 21.100611 },
    { lat: 48.949818, lng: 21.1006434 },
    { lat: 48.9488512, lng: 21.1009559 },
    { lat: 48.9480022, lng: 21.101014 },
    { lat: 48.9474831, lng: 21.1012479 },
    { lat: 48.9465125, lng: 21.1011622 },
    { lat: 48.9460935, lng: 21.1011875 },
    { lat: 48.9451885, lng: 21.1013969 },
    { lat: 48.944632, lng: 21.1014499 },
    { lat: 48.9436347, lng: 21.1017907 },
    { lat: 48.9427793, lng: 21.1022804 },
    { lat: 48.94227, lng: 21.1021963 },
    { lat: 48.9414863, lng: 21.1019017 },
    { lat: 48.9407851, lng: 21.1023162 },
    { lat: 48.9397927, lng: 21.1027143 },
    { lat: 48.9394855, lng: 21.1032392 },
    { lat: 48.9395324, lng: 21.1038247 },
    { lat: 48.9396226, lng: 21.1041455 },
    { lat: 48.9396244, lng: 21.1044816 },
    { lat: 48.9396007, lng: 21.1048302 },
    { lat: 48.9394084, lng: 21.1056069 },
    { lat: 48.9394117, lng: 21.1064823 },
    { lat: 48.939477, lng: 21.1070113 },
    { lat: 48.939731, lng: 21.1073125 },
    { lat: 48.9396069, lng: 21.1083563 },
    { lat: 48.9394359, lng: 21.1089224 },
    { lat: 48.9390798, lng: 21.1088706 },
    { lat: 48.9388041, lng: 21.1096441 },
    { lat: 48.9387434, lng: 21.1111962 },
    { lat: 48.9392642, lng: 21.1112551 },
    { lat: 48.9392399, lng: 21.1123996 },
    { lat: 48.9395463, lng: 21.1125098 },
    { lat: 48.9393845, lng: 21.1135059 },
    { lat: 48.9395033, lng: 21.1149709 },
    { lat: 48.9395025, lng: 21.1159619 },
    { lat: 48.9391266, lng: 21.1173367 },
    { lat: 48.9387206, lng: 21.1182236 },
    { lat: 48.9384923, lng: 21.1189338 },
    { lat: 48.9384193, lng: 21.1196451 },
    { lat: 48.9371373, lng: 21.1214366 },
    { lat: 48.9373212, lng: 21.121946 },
    { lat: 48.9372258, lng: 21.1224084 },
    { lat: 48.9369717, lng: 21.1229425 },
    { lat: 48.9371511, lng: 21.1237691 },
    { lat: 48.9369642, lng: 21.1247197 },
    { lat: 48.9373745, lng: 21.1245617 },
    { lat: 48.9377378, lng: 21.1245228 },
    { lat: 48.9380132, lng: 21.1246909 },
    { lat: 48.9383168, lng: 21.1246482 },
    { lat: 48.9389709, lng: 21.1249047 },
    { lat: 48.9395834, lng: 21.1249804 },
    { lat: 48.9401594, lng: 21.1252946 },
    { lat: 48.9404575, lng: 21.1257821 },
    { lat: 48.9407828, lng: 21.1259941 },
    { lat: 48.9408674, lng: 21.1262489 },
    { lat: 48.9410174, lng: 21.1262586 },
    { lat: 48.9411201, lng: 21.1264462 },
    { lat: 48.9413027, lng: 21.1264567 },
    { lat: 48.9413516, lng: 21.1266798 },
    { lat: 48.9417468, lng: 21.12693 },
    { lat: 48.9421272, lng: 21.1270796 },
    { lat: 48.9421982, lng: 21.1272577 },
    { lat: 48.9423176, lng: 21.1273245 },
    { lat: 48.9427312, lng: 21.1275246 },
    { lat: 48.9430595, lng: 21.1279022 },
    { lat: 48.9436139, lng: 21.1281672 },
    { lat: 48.9439686, lng: 21.1286359 },
    { lat: 48.9445356, lng: 21.1300801 },
    { lat: 48.9446708, lng: 21.1301869 },
    { lat: 48.9447745, lng: 21.1304796 },
    { lat: 48.9457755, lng: 21.1319826 },
    { lat: 48.9459019, lng: 21.1323825 },
    { lat: 48.9460742, lng: 21.1326312 },
    { lat: 48.9460458, lng: 21.1327991 },
    { lat: 48.9461483, lng: 21.1341453 },
    { lat: 48.9464889, lng: 21.1350891 },
    { lat: 48.9469585, lng: 21.1347042 },
    { lat: 48.9474506, lng: 21.1339016 },
    { lat: 48.9478759, lng: 21.1337261 },
    { lat: 48.9485557, lng: 21.1332656 },
    { lat: 48.9491137, lng: 21.1326202 },
    { lat: 48.9496982, lng: 21.1321423 },
    { lat: 48.9502048, lng: 21.1318635 },
    { lat: 48.9504908, lng: 21.1315879 },
    { lat: 48.9511433, lng: 21.1313653 },
    { lat: 48.951667, lng: 21.1317698 },
    { lat: 48.9517303, lng: 21.131951 },
    { lat: 48.9523384, lng: 21.1322508 },
    { lat: 48.9531802, lng: 21.131329 },
    { lat: 48.9537973, lng: 21.1309865 },
    { lat: 48.9548789, lng: 21.1293318 },
    { lat: 48.9588285, lng: 21.1255315 },
    { lat: 48.959844, lng: 21.1247657 },
    { lat: 48.9605852, lng: 21.1240096 },
    { lat: 48.9608755, lng: 21.1238328 },
    { lat: 48.9613537, lng: 21.1232303 },
    { lat: 48.9619797, lng: 21.122785 },
    { lat: 48.9623297, lng: 21.1226088 },
    { lat: 48.9624505, lng: 21.1224753 },
    { lat: 48.9614919, lng: 21.1204009 },
    { lat: 48.9615967, lng: 21.1201235 },
    { lat: 48.9618727, lng: 21.1196738 },
    { lat: 48.9626776, lng: 21.1187657 },
    { lat: 48.9629549, lng: 21.1183223 },
    { lat: 48.963182, lng: 21.1176444 },
    { lat: 48.963431, lng: 21.1171995 },
    { lat: 48.9643259, lng: 21.1160762 },
    { lat: 48.9653145, lng: 21.1152788 },
    { lat: 48.9659022, lng: 21.115108 },
    { lat: 48.9666834, lng: 21.1152259 },
    { lat: 48.9667235, lng: 21.1148565 },
    { lat: 48.9675249, lng: 21.1127468 },
    { lat: 48.9677135, lng: 21.1119939 },
    { lat: 48.9679144, lng: 21.1108218 },
    { lat: 48.9680756, lng: 21.1102268 },
    { lat: 48.9682973, lng: 21.1099124 },
    { lat: 48.9684621, lng: 21.1094675 },
    { lat: 48.9684534, lng: 21.1093367 },
    { lat: 48.9685892, lng: 21.1090767 },
    { lat: 48.9686675, lng: 21.1087467 },
    { lat: 48.9685512, lng: 21.1077878 },
    { lat: 48.9686012, lng: 21.1074872 },
    { lat: 48.9689403, lng: 21.106653 },
    { lat: 48.9689519, lng: 21.1064931 },
    { lat: 48.9694961, lng: 21.1059829 },
    { lat: 48.9694919, lng: 21.1056737 },
    { lat: 48.9695575, lng: 21.1055421 },
    { lat: 48.9697052, lng: 21.1054505 },
    { lat: 48.9698521, lng: 21.1055006 },
    { lat: 48.9699327, lng: 21.1051755 },
    { lat: 48.9698925, lng: 21.1048328 },
    { lat: 48.9694508, lng: 21.1035884 },
    { lat: 48.9696887, lng: 21.103352 },
    { lat: 48.9702491, lng: 21.1024444 },
    { lat: 48.9707552, lng: 21.1019359 },
    { lat: 48.9708625, lng: 21.1016353 },
    { lat: 48.9715016, lng: 21.100509 },
    { lat: 48.9716282, lng: 21.100384 },
    { lat: 48.9717855, lng: 21.1003404 },
    { lat: 48.9717966, lng: 21.1002665 },
    { lat: 48.971985, lng: 21.0992958 },
    { lat: 48.9718914, lng: 21.0992604 },
    { lat: 48.9717225, lng: 21.0979955 },
    { lat: 48.9716056, lng: 21.0973648 },
  ],
  Bzenov: [
    { lat: 48.9725832, lng: 21.1483487 },
    { lat: 48.9724463, lng: 21.1483221 },
    { lat: 48.9721321, lng: 21.148281 },
    { lat: 48.9706124, lng: 21.1485163 },
    { lat: 48.9682966, lng: 21.1485258 },
    { lat: 48.9681359, lng: 21.1486708 },
    { lat: 48.9678044, lng: 21.1485069 },
    { lat: 48.9672006, lng: 21.1487441 },
    { lat: 48.9668573, lng: 21.1492724 },
    { lat: 48.9667684, lng: 21.1503026 },
    { lat: 48.966823, lng: 21.1507169 },
    { lat: 48.9665838, lng: 21.1510116 },
    { lat: 48.9659606, lng: 21.1504565 },
    { lat: 48.9657248, lng: 21.1504712 },
    { lat: 48.9650296, lng: 21.1498737 },
    { lat: 48.9643588, lng: 21.1498711 },
    { lat: 48.9643947, lng: 21.1502983 },
    { lat: 48.9638, lng: 21.1502754 },
    { lat: 48.9633201, lng: 21.1497614 },
    { lat: 48.9626228, lng: 21.1493898 },
    { lat: 48.9602556, lng: 21.1483397 },
    { lat: 48.9600892, lng: 21.1494469 },
    { lat: 48.9595818, lng: 21.1490987 },
    { lat: 48.9594363, lng: 21.1501557 },
    { lat: 48.958887, lng: 21.1514664 },
    { lat: 48.9587535, lng: 21.1521885 },
    { lat: 48.9584802, lng: 21.152121 },
    { lat: 48.9583775, lng: 21.1521837 },
    { lat: 48.9582269, lng: 21.1519042 },
    { lat: 48.9580641, lng: 21.1517453 },
    { lat: 48.9565822, lng: 21.1527068 },
    { lat: 48.9556342, lng: 21.1531226 },
    { lat: 48.9555128, lng: 21.1534079 },
    { lat: 48.955384, lng: 21.1539287 },
    { lat: 48.9554631, lng: 21.1546466 },
    { lat: 48.9548722, lng: 21.1553575 },
    { lat: 48.95443, lng: 21.1555355 },
    { lat: 48.9544921, lng: 21.155368 },
    { lat: 48.9543084, lng: 21.1552865 },
    { lat: 48.9533999, lng: 21.1552457 },
    { lat: 48.9530458, lng: 21.1550402 },
    { lat: 48.9526487, lng: 21.1554058 },
    { lat: 48.9517989, lng: 21.1566769 },
    { lat: 48.9510651, lng: 21.1587477 },
    { lat: 48.9505519, lng: 21.1593869 },
    { lat: 48.9502103, lng: 21.1595765 },
    { lat: 48.9491818, lng: 21.1605816 },
    { lat: 48.9486197, lng: 21.1608474 },
    { lat: 48.9480729, lng: 21.1612821 },
    { lat: 48.9473459, lng: 21.1620303 },
    { lat: 48.9468999, lng: 21.1627294 },
    { lat: 48.9467557, lng: 21.1624725 },
    { lat: 48.9465651, lng: 21.1612834 },
    { lat: 48.9463104, lng: 21.1607018 },
    { lat: 48.9461961, lng: 21.1606124 },
    { lat: 48.9461545, lng: 21.1608654 },
    { lat: 48.9460664, lng: 21.160928 },
    { lat: 48.9460572, lng: 21.1614492 },
    { lat: 48.9460992, lng: 21.1615321 },
    { lat: 48.9462258, lng: 21.1615154 },
    { lat: 48.9462419, lng: 21.161743 },
    { lat: 48.9462196, lng: 21.1618201 },
    { lat: 48.9460868, lng: 21.1618574 },
    { lat: 48.9459571, lng: 21.1620966 },
    { lat: 48.9459886, lng: 21.162916 },
    { lat: 48.9459371, lng: 21.1628893 },
    { lat: 48.9458789, lng: 21.1632566 },
    { lat: 48.9456245, lng: 21.1637509 },
    { lat: 48.9457448, lng: 21.1637164 },
    { lat: 48.9456482, lng: 21.1638612 },
    { lat: 48.9455761, lng: 21.1642499 },
    { lat: 48.9456931, lng: 21.1646789 },
    { lat: 48.9453294, lng: 21.164565 },
    { lat: 48.9452456, lng: 21.1636936 },
    { lat: 48.9449299, lng: 21.1643538 },
    { lat: 48.9444912, lng: 21.1648615 },
    { lat: 48.9451209, lng: 21.1654817 },
    { lat: 48.9451954, lng: 21.1659914 },
    { lat: 48.9455054, lng: 21.1661526 },
    { lat: 48.9456909, lng: 21.1663585 },
    { lat: 48.9450969, lng: 21.1681477 },
    { lat: 48.9450049, lng: 21.1687298 },
    { lat: 48.9450567, lng: 21.1694818 },
    { lat: 48.944953, lng: 21.1697614 },
    { lat: 48.9445241, lng: 21.1703618 },
    { lat: 48.9442085, lng: 21.171207 },
    { lat: 48.9444733, lng: 21.1712866 },
    { lat: 48.9449588, lng: 21.1720873 },
    { lat: 48.9456818, lng: 21.1711423 },
    { lat: 48.9462046, lng: 21.1700192 },
    { lat: 48.9465108, lng: 21.170486 },
    { lat: 48.9467849, lng: 21.1700766 },
    { lat: 48.9468045, lng: 21.1701239 },
    { lat: 48.9470353, lng: 21.1698425 },
    { lat: 48.9476518, lng: 21.1688354 },
    { lat: 48.9478443, lng: 21.1686939 },
    { lat: 48.9481024, lng: 21.1686702 },
    { lat: 48.9483711, lng: 21.1687681 },
    { lat: 48.9485164, lng: 21.1689212 },
    { lat: 48.9487348, lng: 21.1690267 },
    { lat: 48.9495988, lng: 21.1698401 },
    { lat: 48.9500171, lng: 21.1697198 },
    { lat: 48.9518753, lng: 21.1700468 },
    { lat: 48.9526877, lng: 21.1707562 },
    { lat: 48.953169, lng: 21.170998 },
    { lat: 48.9534856, lng: 21.1712622 },
    { lat: 48.9545189, lng: 21.1717691 },
    { lat: 48.9548561, lng: 21.1721815 },
    { lat: 48.9554176, lng: 21.1725046 },
    { lat: 48.9561413, lng: 21.1730432 },
    { lat: 48.9567791, lng: 21.1721408 },
    { lat: 48.957642, lng: 21.1718482 },
    { lat: 48.9581926, lng: 21.1718931 },
    { lat: 48.9594104, lng: 21.1717152 },
    { lat: 48.9597353, lng: 21.1714588 },
    { lat: 48.9602415, lng: 21.1713032 },
    { lat: 48.9620215, lng: 21.1711564 },
    { lat: 48.9624472, lng: 21.1710582 },
    { lat: 48.9626638, lng: 21.1711094 },
    { lat: 48.963167, lng: 21.1710396 },
    { lat: 48.963906, lng: 21.171069 },
    { lat: 48.9643048, lng: 21.171083 },
    { lat: 48.9644448, lng: 21.1706309 },
    { lat: 48.9645862, lng: 21.1694387 },
    { lat: 48.964979, lng: 21.16794 },
    { lat: 48.9650454, lng: 21.1679724 },
    { lat: 48.9650083, lng: 21.1672957 },
    { lat: 48.9649325, lng: 21.1670304 },
    { lat: 48.964847, lng: 21.1659267 },
    { lat: 48.9649843, lng: 21.1649271 },
    { lat: 48.9649336, lng: 21.1645372 },
    { lat: 48.9651584, lng: 21.1641365 },
    { lat: 48.9653333, lng: 21.1628996 },
    { lat: 48.9658008, lng: 21.1618438 },
    { lat: 48.9658758, lng: 21.1611191 },
    { lat: 48.9662886, lng: 21.1604716 },
    { lat: 48.9665725, lng: 21.1602317 },
    { lat: 48.9667239, lng: 21.1599334 },
    { lat: 48.9670849, lng: 21.1595651 },
    { lat: 48.9676148, lng: 21.1591697 },
    { lat: 48.9677402, lng: 21.159109 },
    { lat: 48.968429, lng: 21.1592566 },
    { lat: 48.9686555, lng: 21.1590903 },
    { lat: 48.9688396, lng: 21.1591826 },
    { lat: 48.9692097, lng: 21.1591424 },
    { lat: 48.9692367, lng: 21.1590713 },
    { lat: 48.9693892, lng: 21.1590154 },
    { lat: 48.9694462, lng: 21.1588271 },
    { lat: 48.9695557, lng: 21.1589055 },
    { lat: 48.9696936, lng: 21.1588886 },
    { lat: 48.9698728, lng: 21.1587595 },
    { lat: 48.9701182, lng: 21.1587574 },
    { lat: 48.9708514, lng: 21.1584235 },
    { lat: 48.9712252, lng: 21.1581405 },
    { lat: 48.9713889, lng: 21.1580895 },
    { lat: 48.97146, lng: 21.1579869 },
    { lat: 48.9716122, lng: 21.1579597 },
    { lat: 48.9717209, lng: 21.1577297 },
    { lat: 48.9719535, lng: 21.157433 },
    { lat: 48.9719961, lng: 21.1572504 },
    { lat: 48.9722576, lng: 21.1573696 },
    { lat: 48.9722505, lng: 21.1572856 },
    { lat: 48.9724794, lng: 21.1571657 },
    { lat: 48.9725624, lng: 21.1572625 },
    { lat: 48.9727436, lng: 21.1571139 },
    { lat: 48.9725785, lng: 21.1558836 },
    { lat: 48.9723641, lng: 21.1554712 },
    { lat: 48.9722977, lng: 21.1552224 },
    { lat: 48.9721355, lng: 21.1550355 },
    { lat: 48.9719709, lng: 21.1545277 },
    { lat: 48.9719458, lng: 21.1542355 },
    { lat: 48.9720238, lng: 21.1532504 },
    { lat: 48.9719698, lng: 21.1526082 },
    { lat: 48.9718953, lng: 21.1522798 },
    { lat: 48.9718927, lng: 21.1519891 },
    { lat: 48.9718281, lng: 21.1518326 },
    { lat: 48.9716881, lng: 21.1503975 },
    { lat: 48.9723589, lng: 21.1505047 },
    { lat: 48.9724918, lng: 21.1501525 },
    { lat: 48.9726387, lng: 21.1499215 },
    { lat: 48.9727113, lng: 21.1485209 },
    { lat: 48.9726016, lng: 21.1483634 },
    { lat: 48.9725832, lng: 21.1483487 },
  ],
  ChminianskaNováVes: [
    { lat: 49.036727, lng: 21.0418366 },
    { lat: 49.0344433, lng: 21.0427679 },
    { lat: 49.0330517, lng: 21.0433324 },
    { lat: 49.0326592, lng: 21.0431726 },
    { lat: 49.0309294, lng: 21.0443482 },
    { lat: 49.0295692, lng: 21.0454673 },
    { lat: 49.0289389, lng: 21.0453598 },
    { lat: 49.0282921, lng: 21.045719 },
    { lat: 49.0282207, lng: 21.0452574 },
    { lat: 49.0274057, lng: 21.0454536 },
    { lat: 49.0260933, lng: 21.0445854 },
    { lat: 49.0239146, lng: 21.0444531 },
    { lat: 49.0235874, lng: 21.0446014 },
    { lat: 49.0220943, lng: 21.0440199 },
    { lat: 49.0210132, lng: 21.0429623 },
    { lat: 49.0206493, lng: 21.0421201 },
    { lat: 49.0197652, lng: 21.040477 },
    { lat: 49.0194635, lng: 21.0382328 },
    { lat: 49.0191024, lng: 21.0381668 },
    { lat: 49.0176443, lng: 21.0397406 },
    { lat: 49.0175153, lng: 21.0395807 },
    { lat: 49.0169838, lng: 21.0402565 },
    { lat: 49.0164912, lng: 21.0410609 },
    { lat: 49.0164171, lng: 21.041349 },
    { lat: 49.0159378, lng: 21.0422807 },
    { lat: 49.0151556, lng: 21.0412113 },
    { lat: 49.0131686, lng: 21.0434399 },
    { lat: 49.012651, lng: 21.043861 },
    { lat: 49.0125442, lng: 21.0436529 },
    { lat: 49.0123106, lng: 21.0435335 },
    { lat: 49.0120873, lng: 21.043693 },
    { lat: 49.0117009, lng: 21.0445445 },
    { lat: 49.0114633, lng: 21.0448173 },
    { lat: 49.0111579, lng: 21.0450435 },
    { lat: 49.0106427, lng: 21.0456394 },
    { lat: 49.0105114, lng: 21.0460708 },
    { lat: 49.0101432, lng: 21.0465825 },
    { lat: 49.0100983, lng: 21.0473853 },
    { lat: 49.0098377, lng: 21.0479871 },
    { lat: 49.0096687, lng: 21.0487086 },
    { lat: 49.0096758, lng: 21.0492748 },
    { lat: 49.0095753, lng: 21.0500202 },
    { lat: 49.009714, lng: 21.0504794 },
    { lat: 49.010293, lng: 21.0509805 },
    { lat: 49.0104964, lng: 21.0512509 },
    { lat: 49.0106171, lng: 21.052297 },
    { lat: 49.010565, lng: 21.0523916 },
    { lat: 49.01058, lng: 21.0526794 },
    { lat: 49.0104345, lng: 21.0532962 },
    { lat: 49.0104489, lng: 21.0536325 },
    { lat: 49.010528, lng: 21.0540252 },
    { lat: 49.0106346, lng: 21.0542588 },
    { lat: 49.0104499, lng: 21.0549488 },
    { lat: 49.0102525, lng: 21.0553487 },
    { lat: 49.0098158, lng: 21.0558672 },
    { lat: 49.0096611, lng: 21.0561649 },
    { lat: 49.0094763, lng: 21.056786 },
    { lat: 49.0093316, lng: 21.0576687 },
    { lat: 49.0093138, lng: 21.0582228 },
    { lat: 49.009445, lng: 21.0586981 },
    { lat: 49.0095404, lng: 21.0601134 },
    { lat: 49.0094632, lng: 21.0606874 },
    { lat: 49.0092592, lng: 21.0613333 },
    { lat: 49.0092782, lng: 21.0621966 },
    { lat: 49.0091283, lng: 21.06212 },
    { lat: 49.0087392, lng: 21.0627535 },
    { lat: 49.0086298, lng: 21.0634751 },
    { lat: 49.0081703, lng: 21.0641644 },
    { lat: 49.00764, lng: 21.06454 },
    { lat: 49.0068727, lng: 21.0654541 },
    { lat: 49.0063238, lng: 21.0656669 },
    { lat: 49.0060931, lng: 21.0661247 },
    { lat: 49.0060901, lng: 21.0662378 },
    { lat: 49.0061794, lng: 21.0665276 },
    { lat: 49.0063901, lng: 21.0665742 },
    { lat: 49.0065088, lng: 21.066765 },
    { lat: 49.0066217, lng: 21.0671566 },
    { lat: 49.006612, lng: 21.0673072 },
    { lat: 49.0063305, lng: 21.0677573 },
    { lat: 49.0060894, lng: 21.0683012 },
    { lat: 49.0056118, lng: 21.0685316 },
    { lat: 49.0053468, lng: 21.0684105 },
    { lat: 49.0049339, lng: 21.0683474 },
    { lat: 49.004483, lng: 21.0671437 },
    { lat: 49.0041876, lng: 21.0674031 },
    { lat: 49.0041031, lng: 21.0671947 },
    { lat: 49.0035211, lng: 21.0698377 },
    { lat: 49.0034413, lng: 21.0708469 },
    { lat: 49.0036351, lng: 21.0721086 },
    { lat: 49.003449, lng: 21.0730347 },
    { lat: 49.0030799, lng: 21.0731074 },
    { lat: 49.0028556, lng: 21.0733389 },
    { lat: 49.00282, lng: 21.0734953 },
    { lat: 49.0021125, lng: 21.0732599 },
    { lat: 49.0017584, lng: 21.0732698 },
    { lat: 49.0013363, lng: 21.0736533 },
    { lat: 49.0005897, lng: 21.0746839 },
    { lat: 49.0005266, lng: 21.0745772 },
    { lat: 49.0000092, lng: 21.0754914 },
    { lat: 48.9998804, lng: 21.0765924 },
    { lat: 49.0004613, lng: 21.0790058 },
    { lat: 49.0005443, lng: 21.0804623 },
    { lat: 49.0003793, lng: 21.081904 },
    { lat: 49.0006995, lng: 21.083111 },
    { lat: 49.001122, lng: 21.0841768 },
    { lat: 49.001092, lng: 21.085412 },
    { lat: 49.0012791, lng: 21.0868211 },
    { lat: 49.0010598, lng: 21.0902532 },
    { lat: 49.0013617, lng: 21.0914125 },
    { lat: 49.0015092, lng: 21.0933102 },
    { lat: 49.0019086, lng: 21.0933738 },
    { lat: 49.0026632, lng: 21.093497 },
    { lat: 49.0047369, lng: 21.0936607 },
    { lat: 49.0047534, lng: 21.0943133 },
    { lat: 49.0046629, lng: 21.0955262 },
    { lat: 49.0040975, lng: 21.0978959 },
    { lat: 49.003891, lng: 21.0995977 },
    { lat: 49.0038399, lng: 21.099827 },
    { lat: 49.0030094, lng: 21.1020062 },
    { lat: 49.0032877, lng: 21.1019849 },
    { lat: 49.003348, lng: 21.1018657 },
    { lat: 49.0041916, lng: 21.1013978 },
    { lat: 49.0045226, lng: 21.1011124 },
    { lat: 49.0059442, lng: 21.1008784 },
    { lat: 49.0062, lng: 21.1009218 },
    { lat: 49.0063997, lng: 21.1007937 },
    { lat: 49.007007, lng: 21.1006426 },
    { lat: 49.0081315, lng: 21.1008927 },
    { lat: 49.0083995, lng: 21.1008302 },
    { lat: 49.0084617, lng: 21.1007349 },
    { lat: 49.0085642, lng: 21.1007754 },
    { lat: 49.0085646, lng: 21.1011711 },
    { lat: 49.0088031, lng: 21.101313 },
    { lat: 49.0093135, lng: 21.1012562 },
    { lat: 49.0096421, lng: 21.1013308 },
    { lat: 49.0100322, lng: 21.1012463 },
    { lat: 49.0107212, lng: 21.1013229 },
    { lat: 49.0109464, lng: 21.1012184 },
    { lat: 49.011728, lng: 21.1014132 },
    { lat: 49.0123568, lng: 21.1013713 },
    { lat: 49.0126622, lng: 21.10121 },
    { lat: 49.0129925, lng: 21.1009141 },
    { lat: 49.0132682, lng: 21.1008388 },
    { lat: 49.0134724, lng: 21.1009259 },
    { lat: 49.0136406, lng: 21.100907 },
    { lat: 49.0139935, lng: 21.1006438 },
    { lat: 49.0137215, lng: 21.0999505 },
    { lat: 49.0140888, lng: 21.0994393 },
    { lat: 49.0151175, lng: 21.0984245 },
    { lat: 49.0151745, lng: 21.0979896 },
    { lat: 49.015202, lng: 21.096988 },
    { lat: 49.0149239, lng: 21.0959721 },
    { lat: 49.0143244, lng: 21.0942968 },
    { lat: 49.0144032, lng: 21.0939449 },
    { lat: 49.0151657, lng: 21.0931982 },
    { lat: 49.0164986, lng: 21.0921265 },
    { lat: 49.0168957, lng: 21.0918762 },
    { lat: 49.0176409, lng: 21.0910005 },
    { lat: 49.0175414, lng: 21.0907074 },
    { lat: 49.0173569, lng: 21.090852 },
    { lat: 49.0172438, lng: 21.0907951 },
    { lat: 49.0167362, lng: 21.0899196 },
    { lat: 49.017037, lng: 21.0889866 },
    { lat: 49.0191409, lng: 21.0865153 },
    { lat: 49.0195177, lng: 21.0861933 },
    { lat: 49.020832, lng: 21.084828 },
    { lat: 49.020786, lng: 21.084744 },
    { lat: 49.020756, lng: 21.084482 },
    { lat: 49.02075, lng: 21.084161 },
    { lat: 49.02106, lng: 21.083836 },
    { lat: 49.021665, lng: 21.083459 },
    { lat: 49.021998, lng: 21.083232 },
    { lat: 49.022284, lng: 21.082813 },
    { lat: 49.022752, lng: 21.082157 },
    { lat: 49.023258, lng: 21.081319 },
    { lat: 49.023416, lng: 21.08109 },
    { lat: 49.023688, lng: 21.080734 },
    { lat: 49.02376, lng: 21.080234 },
    { lat: 49.023922, lng: 21.080205 },
    { lat: 49.024181, lng: 21.079876 },
    { lat: 49.024201, lng: 21.079631 },
    { lat: 49.024194, lng: 21.079177 },
    { lat: 49.024419, lng: 21.078992 },
    { lat: 49.02463, lng: 21.078345 },
    { lat: 49.024803, lng: 21.07806 },
    { lat: 49.02494, lng: 21.07757 },
    { lat: 49.025463, lng: 21.077222 },
    { lat: 49.025677, lng: 21.076624 },
    { lat: 49.025617, lng: 21.07615 },
    { lat: 49.025956, lng: 21.075482 },
    { lat: 49.026103, lng: 21.075035 },
    { lat: 49.02626, lng: 21.074054 },
    { lat: 49.026685, lng: 21.073024 },
    { lat: 49.027161, lng: 21.072117 },
    { lat: 49.027306, lng: 21.071795 },
    { lat: 49.027626, lng: 21.071459 },
    { lat: 49.028063, lng: 21.071095 },
    { lat: 49.028673, lng: 21.070695 },
    { lat: 49.02897, lng: 21.070199 },
    { lat: 49.029592, lng: 21.069156 },
    { lat: 49.029925, lng: 21.068365 },
    { lat: 49.030298, lng: 21.066521 },
    { lat: 49.030361, lng: 21.06602 },
    { lat: 49.030519, lng: 21.065898 },
    { lat: 49.030504, lng: 21.06554 },
    { lat: 49.030721, lng: 21.065201 },
    { lat: 49.030729, lng: 21.064881 },
    { lat: 49.030964, lng: 21.064645 },
    { lat: 49.031126, lng: 21.064273 },
    { lat: 49.031416, lng: 21.063851 },
    { lat: 49.031751, lng: 21.063527 },
    { lat: 49.031755, lng: 21.063332 },
    { lat: 49.030696, lng: 21.062103 },
    { lat: 49.030742, lng: 21.061921 },
    { lat: 49.030289, lng: 21.061443 },
    { lat: 49.030339, lng: 21.061276 },
    { lat: 49.032879, lng: 21.059612 },
    { lat: 49.035936, lng: 21.057547 },
    { lat: 49.039088, lng: 21.055511 },
    { lat: 49.040922, lng: 21.054381 },
    { lat: 49.040961, lng: 21.054354 },
    { lat: 49.040785, lng: 21.052388 },
    { lat: 49.040867, lng: 21.051484 },
    { lat: 49.040987, lng: 21.050715 },
    { lat: 49.041007, lng: 21.050588 },
    { lat: 49.041077, lng: 21.050139 },
    { lat: 49.040648, lng: 21.049641 },
    { lat: 49.040109, lng: 21.049188 },
    { lat: 49.039947, lng: 21.048949 },
    { lat: 49.039794, lng: 21.047786 },
    { lat: 49.039687, lng: 21.046297 },
    { lat: 49.03955, lng: 21.045459 },
    { lat: 49.039398, lng: 21.044844 },
    { lat: 49.038925, lng: 21.04124 },
    { lat: 49.0380785, lng: 21.0412097 },
    { lat: 49.0375554, lng: 21.0413919 },
    { lat: 49.036727, lng: 21.0418366 },
  ],
  Podhradík: [
    { lat: 48.9956336, lng: 21.3346206 },
    { lat: 48.9943035, lng: 21.336229 },
    { lat: 48.9914582, lng: 21.3354593 },
    { lat: 48.9911106, lng: 21.3375247 },
    { lat: 48.9910072, lng: 21.338691 },
    { lat: 48.991015, lng: 21.3390606 },
    { lat: 48.9911669, lng: 21.3398476 },
    { lat: 48.9910449, lng: 21.3420833 },
    { lat: 48.9899814, lng: 21.3468395 },
    { lat: 48.9897096, lng: 21.347874 },
    { lat: 48.9893934, lng: 21.3487798 },
    { lat: 48.9888408, lng: 21.3499036 },
    { lat: 48.9881908, lng: 21.3520233 },
    { lat: 48.9879364, lng: 21.3522784 },
    { lat: 48.9875972, lng: 21.3527557 },
    { lat: 48.9871546, lng: 21.3535333 },
    { lat: 48.9872841, lng: 21.354286 },
    { lat: 48.9878103, lng: 21.3553235 },
    { lat: 48.9880895, lng: 21.3554425 },
    { lat: 48.9887645, lng: 21.3573271 },
    { lat: 48.9891233, lng: 21.3577363 },
    { lat: 48.9899812, lng: 21.3595578 },
    { lat: 48.9909374, lng: 21.3603099 },
    { lat: 48.9910448, lng: 21.3605103 },
    { lat: 48.9907621, lng: 21.3606925 },
    { lat: 48.9905868, lng: 21.3620198 },
    { lat: 48.9904976, lng: 21.3620478 },
    { lat: 48.9904085, lng: 21.3625323 },
    { lat: 48.9902818, lng: 21.362775 },
    { lat: 48.9903968, lng: 21.3630096 },
    { lat: 48.9903264, lng: 21.3633989 },
    { lat: 48.9900886, lng: 21.3636507 },
    { lat: 48.989953, lng: 21.3643927 },
    { lat: 48.9897888, lng: 21.3644294 },
    { lat: 48.9895178, lng: 21.3648536 },
    { lat: 48.9893823, lng: 21.3654025 },
    { lat: 48.9894287, lng: 21.3657888 },
    { lat: 48.9891121, lng: 21.3663756 },
    { lat: 48.9890662, lng: 21.367067 },
    { lat: 48.9888241, lng: 21.3684576 },
    { lat: 48.9888935, lng: 21.3685618 },
    { lat: 48.9886338, lng: 21.3694778 },
    { lat: 48.9884965, lng: 21.3705678 },
    { lat: 48.9886183, lng: 21.3716035 },
    { lat: 48.9884399, lng: 21.3719959 },
    { lat: 48.9883869, lng: 21.3723381 },
    { lat: 48.9879809, lng: 21.3728002 },
    { lat: 48.9877577, lng: 21.3732404 },
    { lat: 48.9876384, lng: 21.3735796 },
    { lat: 48.9875835, lng: 21.3742254 },
    { lat: 48.9873207, lng: 21.3746261 },
    { lat: 48.9871674, lng: 21.3751485 },
    { lat: 48.9867791, lng: 21.3756728 },
    { lat: 48.9868034, lng: 21.3758094 },
    { lat: 48.9867531, lng: 21.3761231 },
    { lat: 48.9868261, lng: 21.3765724 },
    { lat: 48.9866676, lng: 21.3768405 },
    { lat: 48.9866636, lng: 21.3769357 },
    { lat: 48.986953, lng: 21.3774393 },
    { lat: 48.9871378, lng: 21.3782417 },
    { lat: 48.9868822, lng: 21.3786927 },
    { lat: 48.9869309, lng: 21.3790408 },
    { lat: 48.9859009, lng: 21.3799024 },
    { lat: 48.9856106, lng: 21.380014 },
    { lat: 48.9855505, lng: 21.3802993 },
    { lat: 48.9854257, lng: 21.3804562 },
    { lat: 48.9850866, lng: 21.3812024 },
    { lat: 48.9849655, lng: 21.3818562 },
    { lat: 48.9848153, lng: 21.3822219 },
    { lat: 48.9847411, lng: 21.382606 },
    { lat: 48.9845685, lng: 21.3828539 },
    { lat: 48.9845154, lng: 21.3834174 },
    { lat: 48.9843445, lng: 21.3836477 },
    { lat: 48.9841954, lng: 21.3841239 },
    { lat: 48.9842048, lng: 21.3844024 },
    { lat: 48.9839632, lng: 21.384905 },
    { lat: 48.9836336, lng: 21.3853693 },
    { lat: 48.9834278, lng: 21.3854804 },
    { lat: 48.9833724, lng: 21.3857751 },
    { lat: 48.9833846, lng: 21.3862264 },
    { lat: 48.9837398, lng: 21.3887638 },
    { lat: 48.983698, lng: 21.3891765 },
    { lat: 48.9832388, lng: 21.3900494 },
    { lat: 48.9830407, lng: 21.3905734 },
    { lat: 48.9827595, lng: 21.3909204 },
    { lat: 48.9827554, lng: 21.3912054 },
    { lat: 48.9827084, lng: 21.3913598 },
    { lat: 48.9827442, lng: 21.3915827 },
    { lat: 48.9828279, lng: 21.3917178 },
    { lat: 48.9833499, lng: 21.3921321 },
    { lat: 48.9835355, lng: 21.3924372 },
    { lat: 48.9839951, lng: 21.392716 },
    { lat: 48.9850822, lng: 21.3944385 },
    { lat: 48.985914, lng: 21.3960802 },
    { lat: 48.9861464, lng: 21.397132 },
    { lat: 48.9862462, lng: 21.3983421 },
    { lat: 48.9867965, lng: 21.4008601 },
    { lat: 48.9872251, lng: 21.4021601 },
    { lat: 48.9874365, lng: 21.4035271 },
    { lat: 48.9881894, lng: 21.4059777 },
    { lat: 48.9886387, lng: 21.4070248 },
    { lat: 48.9889955, lng: 21.408132 },
    { lat: 48.9894236, lng: 21.4090767 },
    { lat: 48.9897351, lng: 21.4095997 },
    { lat: 48.989925, lng: 21.40978 },
    { lat: 48.990217, lng: 21.409519 },
    { lat: 48.990449, lng: 21.408961 },
    { lat: 48.990809, lng: 21.408443 },
    { lat: 48.991201, lng: 21.408198 },
    { lat: 48.991965, lng: 21.407798 },
    { lat: 48.992307, lng: 21.407683 },
    { lat: 48.993158, lng: 21.407925 },
    { lat: 48.993392, lng: 21.408031 },
    { lat: 48.994029, lng: 21.408332 },
    { lat: 48.994266, lng: 21.408581 },
    { lat: 48.994661, lng: 21.407173 },
    { lat: 48.994927, lng: 21.40721 },
    { lat: 48.995361, lng: 21.407172 },
    { lat: 48.995898, lng: 21.407671 },
    { lat: 48.996573, lng: 21.409088 },
    { lat: 48.996915, lng: 21.40946 },
    { lat: 48.996978, lng: 21.409487 },
    { lat: 48.997358, lng: 21.409737 },
    { lat: 48.997393, lng: 21.409686 },
    { lat: 48.997504, lng: 21.409822 },
    { lat: 48.997638, lng: 21.41024 },
    { lat: 48.997897, lng: 21.410595 },
    { lat: 48.998119, lng: 21.411129 },
    { lat: 48.998167, lng: 21.411449 },
    { lat: 48.998123, lng: 21.412453 },
    { lat: 48.998333, lng: 21.41313 },
    { lat: 48.998411, lng: 21.413241 },
    { lat: 48.998693, lng: 21.413627 },
    { lat: 48.999114, lng: 21.414243 },
    { lat: 48.999192, lng: 21.414474 },
    { lat: 48.9994142, lng: 21.4142068 },
    { lat: 48.9997468, lng: 21.4129297 },
    { lat: 49.0001595, lng: 21.411044 },
    { lat: 49.000152, lng: 21.4102525 },
    { lat: 49.0003545, lng: 21.4096414 },
    { lat: 49.0009365, lng: 21.407431 },
    { lat: 49.0013987, lng: 21.4044775 },
    { lat: 49.0013902, lng: 21.4041881 },
    { lat: 49.0015966, lng: 21.403672 },
    { lat: 49.0019713, lng: 21.4019117 },
    { lat: 49.002011, lng: 21.4013557 },
    { lat: 49.0027794, lng: 21.3963503 },
    { lat: 49.0028755, lng: 21.395564 },
    { lat: 49.0028985, lng: 21.3947045 },
    { lat: 49.0036603, lng: 21.3935243 },
    { lat: 49.0043987, lng: 21.3927715 },
    { lat: 49.0045016, lng: 21.3922961 },
    { lat: 49.0046435, lng: 21.3920316 },
    { lat: 49.0046611, lng: 21.3913742 },
    { lat: 49.0046203, lng: 21.3911343 },
    { lat: 49.0044366, lng: 21.3907017 },
    { lat: 49.0044715, lng: 21.3905485 },
    { lat: 49.0044296, lng: 21.3902797 },
    { lat: 49.0044948, lng: 21.3900462 },
    { lat: 49.0042966, lng: 21.3896985 },
    { lat: 49.0042833, lng: 21.3894787 },
    { lat: 49.0041279, lng: 21.3891678 },
    { lat: 49.0041605, lng: 21.3889004 },
    { lat: 49.0040177, lng: 21.3883744 },
    { lat: 49.0040928, lng: 21.3881338 },
    { lat: 49.0040304, lng: 21.3876979 },
    { lat: 49.0038649, lng: 21.387467 },
    { lat: 49.0038494, lng: 21.3871355 },
    { lat: 49.0037654, lng: 21.3869154 },
    { lat: 49.0036137, lng: 21.3867706 },
    { lat: 49.0034808, lng: 21.3864629 },
    { lat: 49.0035446, lng: 21.3862597 },
    { lat: 49.0035, lng: 21.3857423 },
    { lat: 49.0035449, lng: 21.3854726 },
    { lat: 49.003665, lng: 21.3848957 },
    { lat: 49.0038134, lng: 21.3847379 },
    { lat: 49.0037704, lng: 21.3845752 },
    { lat: 49.0038419, lng: 21.3844431 },
    { lat: 49.0038981, lng: 21.3841429 },
    { lat: 49.0038896, lng: 21.3839288 },
    { lat: 49.0037422, lng: 21.3836246 },
    { lat: 49.0038744, lng: 21.3833443 },
    { lat: 49.003876, lng: 21.3829761 },
    { lat: 49.0038066, lng: 21.3827991 },
    { lat: 49.0036921, lng: 21.3827672 },
    { lat: 49.0034795, lng: 21.3822768 },
    { lat: 49.0032631, lng: 21.3813978 },
    { lat: 49.0032665, lng: 21.3813753 },
    { lat: 49.0033065, lng: 21.381111 },
    { lat: 49.0031658, lng: 21.3808418 },
    { lat: 49.0029715, lng: 21.3791475 },
    { lat: 49.0030526, lng: 21.3787359 },
    { lat: 49.0029981, lng: 21.3781458 },
    { lat: 49.0028917, lng: 21.3777467 },
    { lat: 49.0028728, lng: 21.3771864 },
    { lat: 49.002705, lng: 21.3768417 },
    { lat: 49.0025551, lng: 21.3761503 },
    { lat: 49.0026159, lng: 21.3757379 },
    { lat: 49.0024347, lng: 21.3754307 },
    { lat: 49.002317, lng: 21.3745722 },
    { lat: 49.0024334, lng: 21.374175 },
    { lat: 49.0023347, lng: 21.3740331 },
    { lat: 49.002251, lng: 21.373755 },
    { lat: 49.0023772, lng: 21.3735281 },
    { lat: 49.0022461, lng: 21.3731296 },
    { lat: 49.0019011, lng: 21.3725028 },
    { lat: 49.0018983, lng: 21.3722178 },
    { lat: 49.0016969, lng: 21.3718026 },
    { lat: 49.0016259, lng: 21.3714226 },
    { lat: 49.0009295, lng: 21.3701416 },
    { lat: 49.000844, lng: 21.3697644 },
    { lat: 49.0009113, lng: 21.3695186 },
    { lat: 49.0009172, lng: 21.3692684 },
    { lat: 49.0006977, lng: 21.3690058 },
    { lat: 49.000505, lng: 21.3686159 },
    { lat: 49.0003098, lng: 21.3677617 },
    { lat: 48.9998269, lng: 21.3669986 },
    { lat: 48.9996848, lng: 21.3666139 },
    { lat: 48.9996178, lng: 21.3661766 },
    { lat: 48.9996378, lng: 21.3659394 },
    { lat: 48.9995722, lng: 21.3657404 },
    { lat: 48.9996838, lng: 21.3646881 },
    { lat: 48.9995258, lng: 21.3641475 },
    { lat: 48.9995196, lng: 21.3625403 },
    { lat: 48.9994756, lng: 21.362123 },
    { lat: 48.9996111, lng: 21.3618381 },
    { lat: 48.9996815, lng: 21.3613287 },
    { lat: 48.9995966, lng: 21.3605203 },
    { lat: 48.9996321, lng: 21.3603118 },
    { lat: 49.0002852, lng: 21.3601357 },
    { lat: 49.0024982, lng: 21.360395 },
    { lat: 49.0031987, lng: 21.360756 },
    { lat: 49.0031941, lng: 21.3608649 },
    { lat: 49.0036291, lng: 21.3611861 },
    { lat: 49.0039019, lng: 21.361472 },
    { lat: 49.0041344, lng: 21.361605 },
    { lat: 49.004413, lng: 21.3616263 },
    { lat: 49.0046788, lng: 21.362018 },
    { lat: 49.0056854, lng: 21.3623014 },
    { lat: 49.0064332, lng: 21.3623624 },
    { lat: 49.0065808, lng: 21.3622913 },
    { lat: 49.0067971, lng: 21.3624108 },
    { lat: 49.0071106, lng: 21.3624412 },
    { lat: 49.0071932, lng: 21.3625319 },
    { lat: 49.0072409, lng: 21.3629598 },
    { lat: 49.008546, lng: 21.3637517 },
    { lat: 49.0087249, lng: 21.3639727 },
    { lat: 49.0090714, lng: 21.3641581 },
    { lat: 49.0093435, lng: 21.3645319 },
    { lat: 49.0090799, lng: 21.3650525 },
    { lat: 49.0096321, lng: 21.3660289 },
    { lat: 49.0099371, lng: 21.3667068 },
    { lat: 49.0105692, lng: 21.3658637 },
    { lat: 49.0109084, lng: 21.3651317 },
    { lat: 49.0109055, lng: 21.3648068 },
    { lat: 49.0110067, lng: 21.3639693 },
    { lat: 49.0111241, lng: 21.3634197 },
    { lat: 49.0114502, lng: 21.3625416 },
    { lat: 49.012158, lng: 21.3618305 },
    { lat: 49.0123815, lng: 21.360982 },
    { lat: 49.0125249, lng: 21.3598674 },
    { lat: 49.0126366, lng: 21.3598031 },
    { lat: 49.0125878, lng: 21.359557 },
    { lat: 49.0123801, lng: 21.3593842 },
    { lat: 49.0119031, lng: 21.3580725 },
    { lat: 49.0119029, lng: 21.3573481 },
    { lat: 49.0120998, lng: 21.3567392 },
    { lat: 49.0120741, lng: 21.356038 },
    { lat: 49.0127382, lng: 21.3534031 },
    { lat: 49.0129483, lng: 21.3522981 },
    { lat: 49.0129808, lng: 21.3516897 },
    { lat: 49.0131867, lng: 21.3510945 },
    { lat: 49.0129991, lng: 21.3500475 },
    { lat: 49.0130296, lng: 21.3496414 },
    { lat: 49.0130008, lng: 21.3492752 },
    { lat: 49.0128484, lng: 21.3491463 },
    { lat: 49.0128536, lng: 21.3490603 },
    { lat: 49.012949, lng: 21.3490508 },
    { lat: 49.0131123, lng: 21.3485262 },
    { lat: 49.0132746, lng: 21.3482774 },
    { lat: 49.0136387, lng: 21.3458913 },
    { lat: 49.0137704, lng: 21.3456686 },
    { lat: 49.0139177, lng: 21.3452099 },
    { lat: 49.0138673, lng: 21.3449596 },
    { lat: 49.0139158, lng: 21.3444369 },
    { lat: 49.0100589, lng: 21.3410661 },
    { lat: 49.0094552, lng: 21.3402993 },
    { lat: 49.0091366, lng: 21.3399909 },
    { lat: 49.0085443, lng: 21.3398678 },
    { lat: 49.0079717, lng: 21.3405462 },
    { lat: 49.007713, lng: 21.3409237 },
    { lat: 49.0075517, lng: 21.3413241 },
    { lat: 49.0075602, lng: 21.3417572 },
    { lat: 49.0076745, lng: 21.3420378 },
    { lat: 49.0076054, lng: 21.3421806 },
    { lat: 49.0067304, lng: 21.3410247 },
    { lat: 49.0027802, lng: 21.3378934 },
    { lat: 49.0011777, lng: 21.3368018 },
    { lat: 48.9981597, lng: 21.3352588 },
    { lat: 48.9956336, lng: 21.3346206 },
  ],
  Lipníky: [
    { lat: 49.0573031, lng: 21.3955472 },
    { lat: 49.0572308, lng: 21.395573 },
    { lat: 49.057001, lng: 21.3958014 },
    { lat: 49.0567083, lng: 21.3959409 },
    { lat: 49.0559845, lng: 21.3976542 },
    { lat: 49.0557845, lng: 21.3977733 },
    { lat: 49.054868, lng: 21.3974918 },
    { lat: 49.0542643, lng: 21.3990734 },
    { lat: 49.0537773, lng: 21.3999956 },
    { lat: 49.0528436, lng: 21.4002289 },
    { lat: 49.0527073, lng: 21.3990009 },
    { lat: 49.0501656, lng: 21.3998006 },
    { lat: 49.0494361, lng: 21.3999465 },
    { lat: 49.0490616, lng: 21.4001438 },
    { lat: 49.0490532, lng: 21.4000095 },
    { lat: 49.0489995, lng: 21.4000204 },
    { lat: 49.0489458, lng: 21.4000312 },
    { lat: 49.0490158, lng: 21.4011513 },
    { lat: 49.049016, lng: 21.4020717 },
    { lat: 49.0469846, lng: 21.4021667 },
    { lat: 49.0469803, lng: 21.4006849 },
    { lat: 49.0466402, lng: 21.3981191 },
    { lat: 49.0454764, lng: 21.3986411 },
    { lat: 49.0455368, lng: 21.3992005 },
    { lat: 49.0455359, lng: 21.4005782 },
    { lat: 49.0450745, lng: 21.4007721 },
    { lat: 49.0451001, lng: 21.4010268 },
    { lat: 49.045389, lng: 21.4009089 },
    { lat: 49.045538, lng: 21.4020289 },
    { lat: 49.0449915, lng: 21.4022688 },
    { lat: 49.0450472, lng: 21.4026721 },
    { lat: 49.0442837, lng: 21.4030297 },
    { lat: 49.0445404, lng: 21.4034526 },
    { lat: 49.0446588, lng: 21.4037742 },
    { lat: 49.0430654, lng: 21.4059876 },
    { lat: 49.04209, lng: 21.4060264 },
    { lat: 49.0417566, lng: 21.4058608 },
    { lat: 49.0417997, lng: 21.406483 },
    { lat: 49.0414164, lng: 21.4068781 },
    { lat: 49.0411782, lng: 21.4072361 },
    { lat: 49.0410783, lng: 21.407249 },
    { lat: 49.0409558, lng: 21.4075156 },
    { lat: 49.0404735, lng: 21.407609 },
    { lat: 49.0404037, lng: 21.407759 },
    { lat: 49.0404076, lng: 21.4080062 },
    { lat: 49.0403113, lng: 21.4081368 },
    { lat: 49.0401737, lng: 21.4082039 },
    { lat: 49.0400315, lng: 21.4086461 },
    { lat: 49.0399199, lng: 21.4088344 },
    { lat: 49.0394773, lng: 21.4092848 },
    { lat: 49.0395245, lng: 21.409509 },
    { lat: 49.0394605, lng: 21.4097066 },
    { lat: 49.0395216, lng: 21.4098277 },
    { lat: 49.039095, lng: 21.4106291 },
    { lat: 49.0386461, lng: 21.4108214 },
    { lat: 49.038374, lng: 21.4115912 },
    { lat: 49.0385375, lng: 21.4124465 },
    { lat: 49.0381232, lng: 21.4135132 },
    { lat: 49.0380622, lng: 21.4139649 },
    { lat: 49.038249, lng: 21.4143151 },
    { lat: 49.0384434, lng: 21.41488 },
    { lat: 49.0378196, lng: 21.416903 },
    { lat: 49.0373429, lng: 21.4174507 },
    { lat: 49.03659, lng: 21.417102 },
    { lat: 49.036587, lng: 21.417278 },
    { lat: 49.036609, lng: 21.418938 },
    { lat: 49.036564, lng: 21.419229 },
    { lat: 49.036287, lng: 21.419375 },
    { lat: 49.036167, lng: 21.419439 },
    { lat: 49.03581, lng: 21.419822 },
    { lat: 49.035772, lng: 21.419862 },
    { lat: 49.0358, lng: 21.419969 },
    { lat: 49.035656, lng: 21.420114 },
    { lat: 49.035659, lng: 21.420489 },
    { lat: 49.035469, lng: 21.4207 },
    { lat: 49.035438, lng: 21.420887 },
    { lat: 49.035396, lng: 21.421153 },
    { lat: 49.035382, lng: 21.421233 },
    { lat: 49.035372, lng: 21.421299 },
    { lat: 49.035707, lng: 21.421352 },
    { lat: 49.036262, lng: 21.42144 },
    { lat: 49.036335, lng: 21.421485 },
    { lat: 49.036567, lng: 21.421393 },
    { lat: 49.03683, lng: 21.42129 },
    { lat: 49.03693, lng: 21.421173 },
    { lat: 49.036923, lng: 21.421278 },
    { lat: 49.036971, lng: 21.421336 },
    { lat: 49.037074, lng: 21.421142 },
    { lat: 49.037211, lng: 21.421168 },
    { lat: 49.037231, lng: 21.421324 },
    { lat: 49.037229, lng: 21.421556 },
    { lat: 49.037326, lng: 21.421958 },
    { lat: 49.037452, lng: 21.422287 },
    { lat: 49.037554, lng: 21.422622 },
    { lat: 49.037593, lng: 21.423026 },
    { lat: 49.037738, lng: 21.423541 },
    { lat: 49.037953, lng: 21.424137 },
    { lat: 49.037969, lng: 21.424127 },
    { lat: 49.038012, lng: 21.42443 },
    { lat: 49.038039, lng: 21.424516 },
    { lat: 49.038118, lng: 21.424669 },
    { lat: 49.038428, lng: 21.425058 },
    { lat: 49.038589, lng: 21.425165 },
    { lat: 49.039098, lng: 21.425365 },
    { lat: 49.039482, lng: 21.425602 },
    { lat: 49.039688, lng: 21.425731 },
    { lat: 49.039943, lng: 21.425857 },
    { lat: 49.040292, lng: 21.426112 },
    { lat: 49.040478, lng: 21.426252 },
    { lat: 49.04069, lng: 21.426369 },
    { lat: 49.040981, lng: 21.426423 },
    { lat: 49.041335, lng: 21.426434 },
    { lat: 49.041566, lng: 21.426493 },
    { lat: 49.041874, lng: 21.426394 },
    { lat: 49.041886, lng: 21.426413 },
    { lat: 49.041837, lng: 21.426694 },
    { lat: 49.041959, lng: 21.427007 },
    { lat: 49.042079, lng: 21.427659 },
    { lat: 49.042646, lng: 21.428257 },
    { lat: 49.042666, lng: 21.428278 },
    { lat: 49.0453688, lng: 21.4244107 },
    { lat: 49.0457112, lng: 21.4240752 },
    { lat: 49.0460194, lng: 21.4239546 },
    { lat: 49.0467053, lng: 21.4235208 },
    { lat: 49.0470997, lng: 21.4231548 },
    { lat: 49.0478391, lng: 21.422656 },
    { lat: 49.048119, lng: 21.4241094 },
    { lat: 49.0482262, lng: 21.4245086 },
    { lat: 49.0483903, lng: 21.4247364 },
    { lat: 49.0485833, lng: 21.4248696 },
    { lat: 49.0487916, lng: 21.4249115 },
    { lat: 49.0496515, lng: 21.4247295 },
    { lat: 49.0499844, lng: 21.4247996 },
    { lat: 49.0502775, lng: 21.4250929 },
    { lat: 49.0504804, lng: 21.4251057 },
    { lat: 49.0505616, lng: 21.4254937 },
    { lat: 49.0510232, lng: 21.4254269 },
    { lat: 49.0521894, lng: 21.4264887 },
    { lat: 49.0528126, lng: 21.4259408 },
    { lat: 49.0543614, lng: 21.4280795 },
    { lat: 49.0555742, lng: 21.4284247 },
    { lat: 49.0560381, lng: 21.4263557 },
    { lat: 49.0559615, lng: 21.425661 },
    { lat: 49.0559078, lng: 21.4232459 },
    { lat: 49.0543727, lng: 21.4213449 },
    { lat: 49.0539096, lng: 21.4193229 },
    { lat: 49.053126, lng: 21.4199235 },
    { lat: 49.0532651, lng: 21.4212586 },
    { lat: 49.0533164, lng: 21.4213914 },
    { lat: 49.053189, lng: 21.4212275 },
    { lat: 49.0531839, lng: 21.4210955 },
    { lat: 49.0530694, lng: 21.4209511 },
    { lat: 49.0524065, lng: 21.4189811 },
    { lat: 49.0514954, lng: 21.4174179 },
    { lat: 49.0528406, lng: 21.4173988 },
    { lat: 49.0529819, lng: 21.4174236 },
    { lat: 49.0531306, lng: 21.4175843 },
    { lat: 49.0534871, lng: 21.417464 },
    { lat: 49.0542116, lng: 21.4174231 },
    { lat: 49.0551539, lng: 21.4175952 },
    { lat: 49.0552641, lng: 21.4177025 },
    { lat: 49.0556219, lng: 21.4178601 },
    { lat: 49.0561079, lng: 21.4178221 },
    { lat: 49.0564035, lng: 21.4179244 },
    { lat: 49.0566535, lng: 21.4178142 },
    { lat: 49.0569528, lng: 21.41789 },
    { lat: 49.0572596, lng: 21.4177225 },
    { lat: 49.0579289, lng: 21.4176093 },
    { lat: 49.0582445, lng: 21.4174365 },
    { lat: 49.0587125, lng: 21.4173161 },
    { lat: 49.0589508, lng: 21.4168077 },
    { lat: 49.0589832, lng: 21.4163427 },
    { lat: 49.0594237, lng: 21.4155892 },
    { lat: 49.0593773, lng: 21.4155037 },
    { lat: 49.0586287, lng: 21.4135731 },
    { lat: 49.0583632, lng: 21.4118902 },
    { lat: 49.0585689, lng: 21.4118385 },
    { lat: 49.0588206, lng: 21.4119072 },
    { lat: 49.0592693, lng: 21.4117863 },
    { lat: 49.0593684, lng: 21.4111707 },
    { lat: 49.0593579, lng: 21.409473 },
    { lat: 49.0587959, lng: 21.406852 },
    { lat: 49.0588577, lng: 21.4062343 },
    { lat: 49.0587906, lng: 21.4037268 },
    { lat: 49.057854, lng: 21.4008394 },
    { lat: 49.0576089, lng: 21.3992837 },
    { lat: 49.0578256, lng: 21.3990343 },
    { lat: 49.0574556, lng: 21.3959135 },
    { lat: 49.0573031, lng: 21.3955472 },
  ],
  Ľubovec: [
    { lat: 48.884542, lng: 21.196982 },
    { lat: 48.8997636, lng: 21.2134934 },
    { lat: 48.902196, lng: 21.2158229 },
    { lat: 48.9019788, lng: 21.215351 },
    { lat: 48.9019968, lng: 21.2148414 },
    { lat: 48.902503, lng: 21.2144367 },
    { lat: 48.9027188, lng: 21.2141036 },
    { lat: 48.9032861, lng: 21.2135632 },
    { lat: 48.9034114, lng: 21.2129973 },
    { lat: 48.9034769, lng: 21.212216 },
    { lat: 48.9036796, lng: 21.2115697 },
    { lat: 48.9037213, lng: 21.2104889 },
    { lat: 48.9029867, lng: 21.2101403 },
    { lat: 48.9025903, lng: 21.2097688 },
    { lat: 48.9025793, lng: 21.2092341 },
    { lat: 48.9025266, lng: 21.2089135 },
    { lat: 48.902818, lng: 21.2086254 },
    { lat: 48.9030786, lng: 21.2084661 },
    { lat: 48.9032294, lng: 21.2081769 },
    { lat: 48.9032474, lng: 21.2079975 },
    { lat: 48.9030873, lng: 21.2069777 },
    { lat: 48.903049, lng: 21.2062179 },
    { lat: 48.903126, lng: 21.2048995 },
    { lat: 48.9032468, lng: 21.2044802 },
    { lat: 48.9034945, lng: 21.2041594 },
    { lat: 48.9037931, lng: 21.2036322 },
    { lat: 48.9040293, lng: 21.2028379 },
    { lat: 48.904144, lng: 21.2027262 },
    { lat: 48.9046504, lng: 21.2026544 },
    { lat: 48.9049516, lng: 21.2028231 },
    { lat: 48.9051902, lng: 21.2030381 },
    { lat: 48.9055313, lng: 21.2031112 },
    { lat: 48.9058361, lng: 21.2033558 },
    { lat: 48.9061178, lng: 21.2036915 },
    { lat: 48.9063914, lng: 21.2038443 },
    { lat: 48.9069887, lng: 21.2044467 },
    { lat: 48.9075029, lng: 21.2051613 },
    { lat: 48.9076307, lng: 21.2051822 },
    { lat: 48.9078342, lng: 21.2049962 },
    { lat: 48.9079385, lng: 21.2046657 },
    { lat: 48.9078932, lng: 21.2044361 },
    { lat: 48.9080322, lng: 21.2038458 },
    { lat: 48.9081773, lng: 21.2036959 },
    { lat: 48.9084549, lng: 21.2035772 },
    { lat: 48.9086325, lng: 21.2032832 },
    { lat: 48.9087528, lng: 21.2032463 },
    { lat: 48.9090899, lng: 21.2034453 },
    { lat: 48.9093414, lng: 21.2033879 },
    { lat: 48.9094272, lng: 21.2032905 },
    { lat: 48.9095405, lng: 21.2023046 },
    { lat: 48.9096071, lng: 21.2021635 },
    { lat: 48.9097098, lng: 21.202032 },
    { lat: 48.910086, lng: 21.20208 },
    { lat: 48.9100905, lng: 21.2018611 },
    { lat: 48.9100057, lng: 21.2017828 },
    { lat: 48.9099044, lng: 21.2015289 },
    { lat: 48.9099614, lng: 21.2008619 },
    { lat: 48.9097941, lng: 21.2007 },
    { lat: 48.9097859, lng: 21.2001837 },
    { lat: 48.9096203, lng: 21.1999616 },
    { lat: 48.9096015, lng: 21.1996099 },
    { lat: 48.9093959, lng: 21.1989381 },
    { lat: 48.9094591, lng: 21.1979752 },
    { lat: 48.9094633, lng: 21.1969928 },
    { lat: 48.9093205, lng: 21.1960615 },
    { lat: 48.9088502, lng: 21.1946665 },
    { lat: 48.9086742, lng: 21.1936095 },
    { lat: 48.9085363, lng: 21.1931608 },
    { lat: 48.9083702, lng: 21.1928494 },
    { lat: 48.9083151, lng: 21.1924223 },
    { lat: 48.9082267, lng: 21.1922573 },
    { lat: 48.9080553, lng: 21.1919602 },
    { lat: 48.9087622, lng: 21.1913154 },
    { lat: 48.9086878, lng: 21.191095 },
    { lat: 48.9091322, lng: 21.1907674 },
    { lat: 48.9115586, lng: 21.1898754 },
    { lat: 48.9115449, lng: 21.189476 },
    { lat: 48.9117212, lng: 21.1883503 },
    { lat: 48.9118448, lng: 21.1879553 },
    { lat: 48.9120652, lng: 21.1875144 },
    { lat: 48.9124316, lng: 21.1868511 },
    { lat: 48.9151556, lng: 21.1833593 },
    { lat: 48.9154738, lng: 21.1827771 },
    { lat: 48.9159082, lng: 21.1823124 },
    { lat: 48.9163621, lng: 21.1820033 },
    { lat: 48.9164574, lng: 21.180952 },
    { lat: 48.9165717, lng: 21.1804331 },
    { lat: 48.9168543, lng: 21.1807658 },
    { lat: 48.9172864, lng: 21.1797946 },
    { lat: 48.9172697, lng: 21.1794802 },
    { lat: 48.9173924, lng: 21.1791579 },
    { lat: 48.91742, lng: 21.1784925 },
    { lat: 48.9172427, lng: 21.1783658 },
    { lat: 48.9174753, lng: 21.177897 },
    { lat: 48.9187227, lng: 21.1764402 },
    { lat: 48.9194193, lng: 21.1755102 },
    { lat: 48.920061, lng: 21.1748725 },
    { lat: 48.9202478, lng: 21.1745913 },
    { lat: 48.9204946, lng: 21.1741755 },
    { lat: 48.9207111, lng: 21.1735751 },
    { lat: 48.9208306, lng: 21.1733721 },
    { lat: 48.9213473, lng: 21.1719427 },
    { lat: 48.9219288, lng: 21.1710789 },
    { lat: 48.9222329, lng: 21.1698709 },
    { lat: 48.9221878, lng: 21.1691502 },
    { lat: 48.9222422, lng: 21.1679897 },
    { lat: 48.9223667, lng: 21.1675108 },
    { lat: 48.9223148, lng: 21.1673979 },
    { lat: 48.9224191, lng: 21.1671026 },
    { lat: 48.9232481, lng: 21.1658021 },
    { lat: 48.9239186, lng: 21.1649764 },
    { lat: 48.9247129, lng: 21.1637592 },
    { lat: 48.9248435, lng: 21.1634734 },
    { lat: 48.924833, lng: 21.162932 },
    { lat: 48.924574, lng: 21.1624967 },
    { lat: 48.9245902, lng: 21.161584 },
    { lat: 48.9244577, lng: 21.1598834 },
    { lat: 48.924512, lng: 21.1587518 },
    { lat: 48.9244171, lng: 21.1566079 },
    { lat: 48.9244157, lng: 21.1559982 },
    { lat: 48.9244932, lng: 21.1558097 },
    { lat: 48.9242199, lng: 21.1550709 },
    { lat: 48.9238903, lng: 21.1545063 },
    { lat: 48.9238251, lng: 21.1540501 },
    { lat: 48.9236889, lng: 21.1537243 },
    { lat: 48.9237694, lng: 21.1533184 },
    { lat: 48.9236639, lng: 21.1530405 },
    { lat: 48.9232046, lng: 21.1513298 },
    { lat: 48.9230561, lng: 21.1509745 },
    { lat: 48.9230286, lng: 21.1506423 },
    { lat: 48.9230928, lng: 21.1494001 },
    { lat: 48.9231754, lng: 21.1490613 },
    { lat: 48.9231514, lng: 21.1486618 },
    { lat: 48.9227595, lng: 21.1480438 },
    { lat: 48.9221924, lng: 21.1479796 },
    { lat: 48.9220833, lng: 21.1480209 },
    { lat: 48.9219321, lng: 21.1485683 },
    { lat: 48.9213577, lng: 21.1490919 },
    { lat: 48.9210558, lng: 21.1491515 },
    { lat: 48.9204937, lng: 21.149384 },
    { lat: 48.9201909, lng: 21.1493861 },
    { lat: 48.919777, lng: 21.1497129 },
    { lat: 48.9192323, lng: 21.150278 },
    { lat: 48.919143, lng: 21.1502673 },
    { lat: 48.9190344, lng: 21.1503897 },
    { lat: 48.9190137, lng: 21.1503004 },
    { lat: 48.9188345, lng: 21.1501911 },
    { lat: 48.9186488, lng: 21.1502254 },
    { lat: 48.9180858, lng: 21.1496796 },
    { lat: 48.9174245, lng: 21.1496245 },
    { lat: 48.9157206, lng: 21.1506887 },
    { lat: 48.9152904, lng: 21.1508947 },
    { lat: 48.9150885, lng: 21.150171 },
    { lat: 48.9132835, lng: 21.151093 },
    { lat: 48.9128322, lng: 21.1514154 },
    { lat: 48.9127618, lng: 21.1512874 },
    { lat: 48.9123565, lng: 21.1511774 },
    { lat: 48.9117875, lng: 21.1514025 },
    { lat: 48.9109012, lng: 21.1513111 },
    { lat: 48.9107842, lng: 21.1512062 },
    { lat: 48.9101195, lng: 21.1520708 },
    { lat: 48.9097776, lng: 21.1524005 },
    { lat: 48.9087983, lng: 21.1531041 },
    { lat: 48.9085522, lng: 21.1534354 },
    { lat: 48.9083745, lng: 21.1538991 },
    { lat: 48.9082778, lng: 21.1544739 },
    { lat: 48.9082493, lng: 21.1555994 },
    { lat: 48.9079283, lng: 21.1564112 },
    { lat: 48.9058638, lng: 21.1526225 },
    { lat: 48.9048122, lng: 21.1542084 },
    { lat: 48.9040431, lng: 21.1559351 },
    { lat: 48.9029847, lng: 21.1563133 },
    { lat: 48.9026299, lng: 21.1560197 },
    { lat: 48.9022365, lng: 21.1552875 },
    { lat: 48.9020208, lng: 21.1552961 },
    { lat: 48.9018662, lng: 21.1549282 },
    { lat: 48.9011512, lng: 21.1548089 },
    { lat: 48.9008857, lng: 21.1545661 },
    { lat: 48.9001279, lng: 21.154377 },
    { lat: 48.8996771, lng: 21.154178 },
    { lat: 48.8987474, lng: 21.1542794 },
    { lat: 48.8980384, lng: 21.1541733 },
    { lat: 48.897575, lng: 21.1544267 },
    { lat: 48.8963023, lng: 21.1546056 },
    { lat: 48.89547, lng: 21.154505 },
    { lat: 48.895534, lng: 21.154613 },
    { lat: 48.895568, lng: 21.154775 },
    { lat: 48.895436, lng: 21.155148 },
    { lat: 48.895361, lng: 21.155341 },
    { lat: 48.895331, lng: 21.155471 },
    { lat: 48.895259, lng: 21.155712 },
    { lat: 48.895211, lng: 21.155912 },
    { lat: 48.895189, lng: 21.156241 },
    { lat: 48.89516, lng: 21.156307 },
    { lat: 48.895053, lng: 21.156347 },
    { lat: 48.894939, lng: 21.156378 },
    { lat: 48.894839, lng: 21.156476 },
    { lat: 48.894622, lng: 21.156619 },
    { lat: 48.894521, lng: 21.156699 },
    { lat: 48.894362, lng: 21.156662 },
    { lat: 48.89424, lng: 21.156692 },
    { lat: 48.894148, lng: 21.156758 },
    { lat: 48.894022, lng: 21.156783 },
    { lat: 48.893975, lng: 21.156787 },
    { lat: 48.893781, lng: 21.156951 },
    { lat: 48.893654, lng: 21.156954 },
    { lat: 48.893615, lng: 21.156925 },
    { lat: 48.893564, lng: 21.156819 },
    { lat: 48.893387, lng: 21.156719 },
    { lat: 48.892685, lng: 21.157024 },
    { lat: 48.892438, lng: 21.156953 },
    { lat: 48.892366, lng: 21.156989 },
    { lat: 48.892237, lng: 21.157153 },
    { lat: 48.892031, lng: 21.157464 },
    { lat: 48.891911, lng: 21.15757 },
    { lat: 48.891804, lng: 21.157754 },
    { lat: 48.891759, lng: 21.157943 },
    { lat: 48.891765, lng: 21.158097 },
    { lat: 48.891782, lng: 21.158224 },
    { lat: 48.891808, lng: 21.158399 },
    { lat: 48.891806, lng: 21.158577 },
    { lat: 48.891763, lng: 21.158782 },
    { lat: 48.89167, lng: 21.159046 },
    { lat: 48.8915437, lng: 21.1591071 },
    { lat: 48.8914213, lng: 21.1591185 },
    { lat: 48.89137, lng: 21.159111 },
    { lat: 48.8912991, lng: 21.1590237 },
    { lat: 48.891255, lng: 21.159046 },
    { lat: 48.891242, lng: 21.159082 },
    { lat: 48.891176, lng: 21.159337 },
    { lat: 48.891172, lng: 21.159468 },
    { lat: 48.891174, lng: 21.159562 },
    { lat: 48.891179, lng: 21.1597 },
    { lat: 48.891189, lng: 21.159875 },
    { lat: 48.891214, lng: 21.160126 },
    { lat: 48.891383, lng: 21.160436 },
    { lat: 48.891405, lng: 21.160515 },
    { lat: 48.89144, lng: 21.160753 },
    { lat: 48.891447, lng: 21.160961 },
    { lat: 48.89142, lng: 21.161009 },
    { lat: 48.891333, lng: 21.160956 },
    { lat: 48.891232, lng: 21.160962 },
    { lat: 48.891196, lng: 21.160954 },
    { lat: 48.891089, lng: 21.160845 },
    { lat: 48.891037, lng: 21.160777 },
    { lat: 48.890962, lng: 21.160763 },
    { lat: 48.890946, lng: 21.161096 },
    { lat: 48.890877, lng: 21.161444 },
    { lat: 48.890847, lng: 21.161533 },
    { lat: 48.890724, lng: 21.161841 },
    { lat: 48.890639, lng: 21.162103 },
    { lat: 48.890602, lng: 21.162229 },
    { lat: 48.890558, lng: 21.162349 },
    { lat: 48.890526, lng: 21.162378 },
    { lat: 48.890407, lng: 21.162376 },
    { lat: 48.890077, lng: 21.162285 },
    { lat: 48.890004, lng: 21.162316 },
    { lat: 48.88992, lng: 21.162464 },
    { lat: 48.889857, lng: 21.162648 },
    { lat: 48.889724, lng: 21.162854 },
    { lat: 48.889565, lng: 21.162874 },
    { lat: 48.889449, lng: 21.16287 },
    { lat: 48.889304, lng: 21.162821 },
    { lat: 48.888722, lng: 21.162341 },
    { lat: 48.887907, lng: 21.161881 },
    { lat: 48.887829, lng: 21.161906 },
    { lat: 48.887755, lng: 21.161998 },
    { lat: 48.887704, lng: 21.162149 },
    { lat: 48.887686, lng: 21.162341 },
    { lat: 48.887677, lng: 21.162519 },
    { lat: 48.887655, lng: 21.162636 },
    { lat: 48.887611, lng: 21.162718 },
    { lat: 48.887477, lng: 21.162879 },
    { lat: 48.887405, lng: 21.162919 },
    { lat: 48.887303, lng: 21.162914 },
    { lat: 48.887094, lng: 21.162935 },
    { lat: 48.887041, lng: 21.163034 },
    { lat: 48.886972, lng: 21.163198 },
    { lat: 48.886842, lng: 21.163292 },
    { lat: 48.886733, lng: 21.163228 },
    { lat: 48.886349, lng: 21.163115 },
    { lat: 48.886186, lng: 21.163071 },
    { lat: 48.885873, lng: 21.163138 },
    { lat: 48.885643, lng: 21.163208 },
    { lat: 48.885296, lng: 21.163333 },
    { lat: 48.88507, lng: 21.163393 },
    { lat: 48.88496, lng: 21.163423 },
    { lat: 48.883887, lng: 21.163901 },
    { lat: 48.883478, lng: 21.164269 },
    { lat: 48.883064, lng: 21.164425 },
    { lat: 48.882736, lng: 21.164468 },
    { lat: 48.882586, lng: 21.16443 },
    { lat: 48.882234, lng: 21.164586 },
    { lat: 48.88197, lng: 21.164728 },
    { lat: 48.881883, lng: 21.164834 },
    { lat: 48.881844, lng: 21.164897 },
    { lat: 48.881718, lng: 21.165142 },
    { lat: 48.88137, lng: 21.165616 },
    { lat: 48.881175, lng: 21.165769 },
    { lat: 48.881028, lng: 21.165921 },
    { lat: 48.880932, lng: 21.166091 },
    { lat: 48.880733, lng: 21.166504 },
    { lat: 48.880608, lng: 21.166715 },
    { lat: 48.880555, lng: 21.16675 },
    { lat: 48.88038, lng: 21.166706 },
    { lat: 48.880135, lng: 21.166788 },
    { lat: 48.87987, lng: 21.166953 },
    { lat: 48.879717, lng: 21.167271 },
    { lat: 48.879616, lng: 21.167445 },
    { lat: 48.879567, lng: 21.167559 },
    { lat: 48.879471, lng: 21.16783 },
    { lat: 48.879321, lng: 21.168366 },
    { lat: 48.879257, lng: 21.168756 },
    { lat: 48.879206, lng: 21.169025 },
    { lat: 48.87904, lng: 21.16979 },
    { lat: 48.8789463, lng: 21.1699799 },
    { lat: 48.878671, lng: 21.170279 },
    { lat: 48.877889, lng: 21.170281 },
    { lat: 48.877175, lng: 21.170476 },
    { lat: 48.876934, lng: 21.170643 },
    { lat: 48.876782, lng: 21.170872 },
    { lat: 48.876586, lng: 21.171301 },
    { lat: 48.876495, lng: 21.171666 },
    { lat: 48.876448, lng: 21.171767 },
    { lat: 48.875862, lng: 21.172415 },
    { lat: 48.875571, lng: 21.172688 },
    { lat: 48.875045, lng: 21.173431 },
    { lat: 48.874955, lng: 21.174024 },
    { lat: 48.874956, lng: 21.174342 },
    { lat: 48.874907, lng: 21.174608 },
    { lat: 48.8749, lng: 21.175012 },
    { lat: 48.874799, lng: 21.175249 },
    { lat: 48.874234, lng: 21.175836 },
    { lat: 48.873243, lng: 21.177622 },
    { lat: 48.873074, lng: 21.178018 },
    { lat: 48.872992, lng: 21.1784103 },
    { lat: 48.872961, lng: 21.178578 },
    { lat: 48.872961, lng: 21.178674 },
    { lat: 48.872992, lng: 21.178886 },
    { lat: 48.873034, lng: 21.179164 },
    { lat: 48.873022, lng: 21.179299 },
    { lat: 48.872872, lng: 21.179565 },
    { lat: 48.872796, lng: 21.179627 },
    { lat: 48.872665, lng: 21.179674 },
    { lat: 48.872508, lng: 21.179781 },
    { lat: 48.872339, lng: 21.179872 },
    { lat: 48.872197, lng: 21.1800773 },
    { lat: 48.87192, lng: 21.180496 },
    { lat: 48.871667, lng: 21.180857 },
    { lat: 48.8713699, lng: 21.1811811 },
    { lat: 48.871025, lng: 21.181415 },
    { lat: 48.870619, lng: 21.181519 },
    { lat: 48.870621, lng: 21.181595 },
    { lat: 48.872706, lng: 21.183932 },
    { lat: 48.873529, lng: 21.184835 },
    { lat: 48.873599, lng: 21.184913 },
    { lat: 48.873714, lng: 21.185051 },
    { lat: 48.87378, lng: 21.185129 },
    { lat: 48.876035, lng: 21.187835 },
    { lat: 48.876534, lng: 21.188147 },
    { lat: 48.882115, lng: 21.194293 },
    { lat: 48.882315, lng: 21.194512 },
    { lat: 48.882775, lng: 21.19502 },
    { lat: 48.882813, lng: 21.19506 },
    { lat: 48.88453, lng: 21.196968 },
    { lat: 48.884542, lng: 21.196982 },
  ],
  Ľubotice: [
    { lat: 49.0087687, lng: 21.2514757 },
    { lat: 49.008656, lng: 21.2515599 },
    { lat: 49.008853, lng: 21.2520831 },
    { lat: 49.0076977, lng: 21.2530466 },
    { lat: 49.0077289, lng: 21.2534028 },
    { lat: 49.0063714, lng: 21.2544917 },
    { lat: 49.0065185, lng: 21.2549884 },
    { lat: 49.0056342, lng: 21.2555769 },
    { lat: 49.0049899, lng: 21.2560056 },
    { lat: 49.0049208, lng: 21.2558435 },
    { lat: 49.0045698, lng: 21.2561709 },
    { lat: 49.0046025, lng: 21.256251 },
    { lat: 49.004448, lng: 21.256348 },
    { lat: 49.0039005, lng: 21.2580094 },
    { lat: 49.0025212, lng: 21.2604791 },
    { lat: 49.0015877, lng: 21.2625086 },
    { lat: 49.0014201, lng: 21.2624416 },
    { lat: 49.0011045, lng: 21.2646627 },
    { lat: 49.0008024, lng: 21.2655033 },
    { lat: 49.0004927, lng: 21.2660634 },
    { lat: 48.9998866, lng: 21.2668415 },
    { lat: 48.9998394, lng: 21.2668945 },
    { lat: 49.0003648, lng: 21.2691638 },
    { lat: 49.0015182, lng: 21.274907 },
    { lat: 49.0020542, lng: 21.276838 },
    { lat: 49.0018785, lng: 21.277218 },
    { lat: 49.0012967, lng: 21.2776433 },
    { lat: 49.0003894, lng: 21.2777685 },
    { lat: 48.9994959, lng: 21.2782544 },
    { lat: 48.9988551, lng: 21.278295 },
    { lat: 48.9988574, lng: 21.2784133 },
    { lat: 48.9988152, lng: 21.2786836 },
    { lat: 48.9987423, lng: 21.2789971 },
    { lat: 48.9987935, lng: 21.2829284 },
    { lat: 48.9993239, lng: 21.2926227 },
    { lat: 48.9992303, lng: 21.2966111 },
    { lat: 48.9989176, lng: 21.297916 },
    { lat: 48.9989831, lng: 21.298254 },
    { lat: 48.9988498, lng: 21.2986113 },
    { lat: 48.9987927, lng: 21.2989508 },
    { lat: 48.9987463, lng: 21.2998288 },
    { lat: 48.9988945, lng: 21.3006746 },
    { lat: 48.9988277, lng: 21.3025517 },
    { lat: 48.9987735, lng: 21.3028418 },
    { lat: 48.9986269, lng: 21.3029843 },
    { lat: 48.9978079, lng: 21.3033628 },
    { lat: 48.9980232, lng: 21.3045203 },
    { lat: 48.9980749, lng: 21.3066407 },
    { lat: 48.9981306, lng: 21.3071381 },
    { lat: 48.9981971, lng: 21.3072177 },
    { lat: 48.9982129, lng: 21.3074062 },
    { lat: 48.998587, lng: 21.3086486 },
    { lat: 48.9987815, lng: 21.3090113 },
    { lat: 48.999324, lng: 21.3096471 },
    { lat: 48.9995607, lng: 21.3098414 },
    { lat: 49.0004359, lng: 21.3106592 },
    { lat: 49.0026252, lng: 21.3105334 },
    { lat: 49.0029444, lng: 21.3196459 },
    { lat: 49.0040832, lng: 21.3195651 },
    { lat: 49.0041098, lng: 21.3195948 },
    { lat: 49.0041369, lng: 21.3209459 },
    { lat: 49.0043778, lng: 21.3211312 },
    { lat: 49.0043749, lng: 21.3217464 },
    { lat: 49.0041717, lng: 21.3220201 },
    { lat: 49.004207, lng: 21.3233745 },
    { lat: 49.0041686, lng: 21.3233733 },
    { lat: 49.0044794, lng: 21.3264085 },
    { lat: 49.0049516, lng: 21.3263512 },
    { lat: 49.0049973, lng: 21.3260771 },
    { lat: 49.0052643, lng: 21.3254209 },
    { lat: 49.0055398, lng: 21.3250408 },
    { lat: 49.0053653, lng: 21.324549 },
    { lat: 49.0055806, lng: 21.3240806 },
    { lat: 49.0060435, lng: 21.3236043 },
    { lat: 49.0065316, lng: 21.3236366 },
    { lat: 49.0065601, lng: 21.3224927 },
    { lat: 49.0066929, lng: 21.3222663 },
    { lat: 49.0067993, lng: 21.3224012 },
    { lat: 49.0069432, lng: 21.3215359 },
    { lat: 49.0071684, lng: 21.3212482 },
    { lat: 49.0072299, lng: 21.3208945 },
    { lat: 49.0078028, lng: 21.3201183 },
    { lat: 49.0081859, lng: 21.3191633 },
    { lat: 49.0083276, lng: 21.3187161 },
    { lat: 49.0083457, lng: 21.3183095 },
    { lat: 49.0085177, lng: 21.318077 },
    { lat: 49.0085563, lng: 21.3177568 },
    { lat: 49.009113, lng: 21.3162573 },
    { lat: 49.0091611, lng: 21.3159116 },
    { lat: 49.009635, lng: 21.315357 },
    { lat: 49.0101973, lng: 21.3136298 },
    { lat: 49.0100497, lng: 21.3133649 },
    { lat: 49.0101109, lng: 21.31301 },
    { lat: 49.0102681, lng: 21.3128815 },
    { lat: 49.0101839, lng: 21.3123361 },
    { lat: 49.0103407, lng: 21.3115634 },
    { lat: 49.0102597, lng: 21.3113809 },
    { lat: 49.0105428, lng: 21.3104721 },
    { lat: 49.0107283, lng: 21.3101269 },
    { lat: 49.0106576, lng: 21.31003 },
    { lat: 49.0107012, lng: 21.3097176 },
    { lat: 49.0106478, lng: 21.3094077 },
    { lat: 49.0115345, lng: 21.3096992 },
    { lat: 49.0116703, lng: 21.309062 },
    { lat: 49.0120963, lng: 21.3080364 },
    { lat: 49.0130243, lng: 21.3063924 },
    { lat: 49.0132653, lng: 21.3058393 },
    { lat: 49.0135336, lng: 21.3048573 },
    { lat: 49.0135558, lng: 21.3046263 },
    { lat: 49.0139552, lng: 21.3034731 },
    { lat: 49.0143544, lng: 21.3029429 },
    { lat: 49.0145196, lng: 21.3026378 },
    { lat: 49.0145187, lng: 21.3026084 },
    { lat: 49.0142142, lng: 21.2988023 },
    { lat: 49.0142284, lng: 21.2981904 },
    { lat: 49.0143078, lng: 21.2980651 },
    { lat: 49.014394, lng: 21.2976114 },
    { lat: 49.0141751, lng: 21.2970342 },
    { lat: 49.0143339, lng: 21.2963301 },
    { lat: 49.0144504, lng: 21.2961297 },
    { lat: 49.0146649, lng: 21.2959634 },
    { lat: 49.0148387, lng: 21.2957257 },
    { lat: 49.0148573, lng: 21.2954509 },
    { lat: 49.0147762, lng: 21.2953372 },
    { lat: 49.01478, lng: 21.2951663 },
    { lat: 49.014917, lng: 21.2948946 },
    { lat: 49.0148956, lng: 21.2946022 },
    { lat: 49.0149515, lng: 21.2945076 },
    { lat: 49.0148808, lng: 21.2941462 },
    { lat: 49.0149721, lng: 21.2931919 },
    { lat: 49.0149196, lng: 21.2931329 },
    { lat: 49.0149426, lng: 21.292438 },
    { lat: 49.014868, lng: 21.2916535 },
    { lat: 49.0148943, lng: 21.2911142 },
    { lat: 49.0146912, lng: 21.2892827 },
    { lat: 49.014728, lng: 21.287867 },
    { lat: 49.0144247, lng: 21.285851 },
    { lat: 49.0144622, lng: 21.2850852 },
    { lat: 49.0146605, lng: 21.2850612 },
    { lat: 49.0145879, lng: 21.2833431 },
    { lat: 49.0146781, lng: 21.2833475 },
    { lat: 49.0147339, lng: 21.2820763 },
    { lat: 49.0148437, lng: 21.2809544 },
    { lat: 49.0150882, lng: 21.2809687 },
    { lat: 49.0151055, lng: 21.2807528 },
    { lat: 49.0163025, lng: 21.2808953 },
    { lat: 49.0162854, lng: 21.2810711 },
    { lat: 49.0175345, lng: 21.2813666 },
    { lat: 49.0177005, lng: 21.2808713 },
    { lat: 49.0176935, lng: 21.2795371 },
    { lat: 49.0177962, lng: 21.2787361 },
    { lat: 49.0180019, lng: 21.2762597 },
    { lat: 49.0180501, lng: 21.2763273 },
    { lat: 49.0181059, lng: 21.2754669 },
    { lat: 49.0179938, lng: 21.2744842 },
    { lat: 49.0179962, lng: 21.2740073 },
    { lat: 49.017929, lng: 21.2739833 },
    { lat: 49.017929, lng: 21.2736506 },
    { lat: 49.0182895, lng: 21.2735567 },
    { lat: 49.018279, lng: 21.2730655 },
    { lat: 49.0184791, lng: 21.2730948 },
    { lat: 49.0184129, lng: 21.2728044 },
    { lat: 49.0183952, lng: 21.2724084 },
    { lat: 49.0184513, lng: 21.2720001 },
    { lat: 49.0190061, lng: 21.2702038 },
    { lat: 49.0190569, lng: 21.2698739 },
    { lat: 49.0190136, lng: 21.2694152 },
    { lat: 49.0186466, lng: 21.2687109 },
    { lat: 49.0185525, lng: 21.2683945 },
    { lat: 49.0185327, lng: 21.2677474 },
    { lat: 49.0188497, lng: 21.2650563 },
    { lat: 49.0188025, lng: 21.2650548 },
    { lat: 49.0186025, lng: 21.2613612 },
    { lat: 49.0183165, lng: 21.2606924 },
    { lat: 49.0182225, lng: 21.2603015 },
    { lat: 49.0183328, lng: 21.2598645 },
    { lat: 49.0182787, lng: 21.2592388 },
    { lat: 49.0183023, lng: 21.2591317 },
    { lat: 49.0185848, lng: 21.2587987 },
    { lat: 49.0186352, lng: 21.2585768 },
    { lat: 49.018637, lng: 21.2582278 },
    { lat: 49.0187321, lng: 21.2580619 },
    { lat: 49.01916, lng: 21.2580348 },
    { lat: 49.0194436, lng: 21.2581853 },
    { lat: 49.0196015, lng: 21.2578313 },
    { lat: 49.0200053, lng: 21.2564668 },
    { lat: 49.0200319, lng: 21.2559798 },
    { lat: 49.0198905, lng: 21.255807 },
    { lat: 49.0198737, lng: 21.2556602 },
    { lat: 49.0200085, lng: 21.2555484 },
    { lat: 49.0201644, lng: 21.2556044 },
    { lat: 49.0201943, lng: 21.2552183 },
    { lat: 49.0202735, lng: 21.2549412 },
    { lat: 49.0202668, lng: 21.2552002 },
    { lat: 49.0203103, lng: 21.2553341 },
    { lat: 49.0203692, lng: 21.254942 },
    { lat: 49.0204619, lng: 21.2548504 },
    { lat: 49.0205427, lng: 21.2550935 },
    { lat: 49.0208045, lng: 21.2551954 },
    { lat: 49.0208423, lng: 21.2551439 },
    { lat: 49.0209569, lng: 21.2552821 },
    { lat: 49.0209985, lng: 21.2551006 },
    { lat: 49.0209673, lng: 21.2548525 },
    { lat: 49.020531, lng: 21.2544199 },
    { lat: 49.0205534, lng: 21.2543137 },
    { lat: 49.0206546, lng: 21.2542287 },
    { lat: 49.0206087, lng: 21.2540545 },
    { lat: 49.0205348, lng: 21.2540665 },
    { lat: 49.0201596, lng: 21.2529538 },
    { lat: 49.019843, lng: 21.2536392 },
    { lat: 49.0192375, lng: 21.2538927 },
    { lat: 49.0184767, lng: 21.2534139 },
    { lat: 49.0183007, lng: 21.2530665 },
    { lat: 49.0182757, lng: 21.2524369 },
    { lat: 49.0179507, lng: 21.2520456 },
    { lat: 49.0179177, lng: 21.2514083 },
    { lat: 49.017209, lng: 21.2507897 },
    { lat: 49.0168379, lng: 21.2507638 },
    { lat: 49.016665, lng: 21.2506302 },
    { lat: 49.0165036, lng: 21.2502139 },
    { lat: 49.0163727, lng: 21.249545 },
    { lat: 49.0161277, lng: 21.249301 },
    { lat: 49.0159787, lng: 21.2493145 },
    { lat: 49.0148671, lng: 21.2501267 },
    { lat: 49.0145126, lng: 21.2497952 },
    { lat: 49.0142553, lng: 21.2492274 },
    { lat: 49.0137068, lng: 21.248444 },
    { lat: 49.0133316, lng: 21.2487177 },
    { lat: 49.0131308, lng: 21.2492887 },
    { lat: 49.01274, lng: 21.2496395 },
    { lat: 49.0119233, lng: 21.250602 },
    { lat: 49.0116924, lng: 21.2507464 },
    { lat: 49.0112276, lng: 21.2507733 },
    { lat: 49.0104375, lng: 21.2505176 },
    { lat: 49.010062, lng: 21.2504891 },
    { lat: 49.0087687, lng: 21.2514757 },
  ],
  Lúčina: [
    { lat: 48.878621, lng: 21.427351 },
    { lat: 48.8786165, lng: 21.4274867 },
    { lat: 48.8785049, lng: 21.4277062 },
    { lat: 48.8785086, lng: 21.4279158 },
    { lat: 48.8779681, lng: 21.4281703 },
    { lat: 48.8778524, lng: 21.4284054 },
    { lat: 48.877923, lng: 21.4283603 },
    { lat: 48.8779621, lng: 21.4284234 },
    { lat: 48.8771733, lng: 21.4299247 },
    { lat: 48.8770701, lng: 21.4298952 },
    { lat: 48.8769337, lng: 21.4306147 },
    { lat: 48.8771881, lng: 21.4306154 },
    { lat: 48.8786213, lng: 21.4302622 },
    { lat: 48.8787452, lng: 21.4307272 },
    { lat: 48.8790159, lng: 21.4304434 },
    { lat: 48.8794723, lng: 21.4305074 },
    { lat: 48.8795941, lng: 21.4303587 },
    { lat: 48.8797656, lng: 21.430419 },
    { lat: 48.87986, lng: 21.4302907 },
    { lat: 48.8800532, lng: 21.4302856 },
    { lat: 48.8805937, lng: 21.4300654 },
    { lat: 48.8809967, lng: 21.4301462 },
    { lat: 48.8810995, lng: 21.4300485 },
    { lat: 48.8814973, lng: 21.4301951 },
    { lat: 48.8816997, lng: 21.4301085 },
    { lat: 48.8821007, lng: 21.4301244 },
    { lat: 48.8823038, lng: 21.4300689 },
    { lat: 48.8825869, lng: 21.4297463 },
    { lat: 48.8828776, lng: 21.4298011 },
    { lat: 48.8829037, lng: 21.4296846 },
    { lat: 48.8830082, lng: 21.4296071 },
    { lat: 48.8833001, lng: 21.4296622 },
    { lat: 48.8834686, lng: 21.4298063 },
    { lat: 48.8837603, lng: 21.4295096 },
    { lat: 48.8840596, lng: 21.4294701 },
    { lat: 48.8842515, lng: 21.4290268 },
    { lat: 48.8846893, lng: 21.4289922 },
    { lat: 48.8849915, lng: 21.4287885 },
    { lat: 48.8851946, lng: 21.429034 },
    { lat: 48.8857838, lng: 21.4289857 },
    { lat: 48.8859852, lng: 21.4294055 },
    { lat: 48.8860285, lng: 21.4296496 },
    { lat: 48.8864801, lng: 21.4303611 },
    { lat: 48.8866403, lng: 21.4305652 },
    { lat: 48.8871005, lng: 21.4309507 },
    { lat: 48.8875577, lng: 21.4310383 },
    { lat: 48.888049, lng: 21.4313363 },
    { lat: 48.8881266, lng: 21.4314805 },
    { lat: 48.8885457, lng: 21.4316536 },
    { lat: 48.8889978, lng: 21.4316496 },
    { lat: 48.8892125, lng: 21.4317578 },
    { lat: 48.8892743, lng: 21.4319038 },
    { lat: 48.8894338, lng: 21.4318198 },
    { lat: 48.8894812, lng: 21.4318382 },
    { lat: 48.8895795, lng: 21.4318765 },
    { lat: 48.8895898, lng: 21.4321146 },
    { lat: 48.8898596, lng: 21.4323003 },
    { lat: 48.8899945, lng: 21.4322143 },
    { lat: 48.8904794, lng: 21.4326362 },
    { lat: 48.8907515, lng: 21.4327416 },
    { lat: 48.8910464, lng: 21.4327081 },
    { lat: 48.8911847, lng: 21.4328247 },
    { lat: 48.8913728, lng: 21.4327923 },
    { lat: 48.891459, lng: 21.4328931 },
    { lat: 48.8914375, lng: 21.4331173 },
    { lat: 48.8921795, lng: 21.4331307 },
    { lat: 48.892511, lng: 21.4333494 },
    { lat: 48.8928499, lng: 21.433205 },
    { lat: 48.8930686, lng: 21.4328716 },
    { lat: 48.8935506, lng: 21.4327291 },
    { lat: 48.8941639, lng: 21.4327382 },
    { lat: 48.8944879, lng: 21.4325362 },
    { lat: 48.8946821, lng: 21.4322191 },
    { lat: 48.894823, lng: 21.4321521 },
    { lat: 48.8953002, lng: 21.4323062 },
    { lat: 48.8959926, lng: 21.4318988 },
    { lat: 48.8967158, lng: 21.4316584 },
    { lat: 48.896878, lng: 21.4317802 },
    { lat: 48.8971995, lng: 21.4317682 },
    { lat: 48.897631, lng: 21.4318654 },
    { lat: 48.8977406, lng: 21.4317271 },
    { lat: 48.8979891, lng: 21.4319507 },
    { lat: 48.8986823, lng: 21.4320721 },
    { lat: 48.8986463, lng: 21.4310681 },
    { lat: 48.900932, lng: 21.4321223 },
    { lat: 48.9020324, lng: 21.4327334 },
    { lat: 48.9028808, lng: 21.4330436 },
    { lat: 48.9035311, lng: 21.4331168 },
    { lat: 48.904039, lng: 21.4333652 },
    { lat: 48.9051534, lng: 21.4333767 },
    { lat: 48.9062383, lng: 21.4331119 },
    { lat: 48.9069248, lng: 21.433046 },
    { lat: 48.9071645, lng: 21.4305443 },
    { lat: 48.9090498, lng: 21.4295291 },
    { lat: 48.9095164, lng: 21.4295216 },
    { lat: 48.9100746, lng: 21.4293309 },
    { lat: 48.910545, lng: 21.429565 },
    { lat: 48.9107919, lng: 21.4291148 },
    { lat: 48.9105116, lng: 21.4280384 },
    { lat: 48.9100752, lng: 21.4274264 },
    { lat: 48.9102996, lng: 21.4264848 },
    { lat: 48.9101098, lng: 21.4260322 },
    { lat: 48.9085585, lng: 21.4254348 },
    { lat: 48.9069919, lng: 21.4256499 },
    { lat: 48.9063995, lng: 21.4260562 },
    { lat: 48.9059658, lng: 21.4264975 },
    { lat: 48.904585, lng: 21.4263013 },
    { lat: 48.9036029, lng: 21.426002 },
    { lat: 48.9028021, lng: 21.4258962 },
    { lat: 48.9014018, lng: 21.425271 },
    { lat: 48.9011373, lng: 21.4250307 },
    { lat: 48.9002731, lng: 21.4244989 },
    { lat: 48.8994362, lng: 21.4241974 },
    { lat: 48.8991792, lng: 21.423988 },
    { lat: 48.8985296, lng: 21.4237869 },
    { lat: 48.8985281, lng: 21.4236553 },
    { lat: 48.8977944, lng: 21.4230808 },
    { lat: 48.8975651, lng: 21.422625 },
    { lat: 48.8966202, lng: 21.421529 },
    { lat: 48.8962424, lng: 21.4212309 },
    { lat: 48.8957745, lng: 21.4210032 },
    { lat: 48.8954997, lng: 21.4209986 },
    { lat: 48.8954659, lng: 21.4211875 },
    { lat: 48.8950728, lng: 21.420977 },
    { lat: 48.8949316, lng: 21.4206579 },
    { lat: 48.8945476, lng: 21.4201474 },
    { lat: 48.894363, lng: 21.4201088 },
    { lat: 48.8941125, lng: 21.4202008 },
    { lat: 48.89414, lng: 21.420335 },
    { lat: 48.894163, lng: 21.420533 },
    { lat: 48.893771, lng: 21.420691 },
    { lat: 48.89352, lng: 21.420767 },
    { lat: 48.892446, lng: 21.420955 },
    { lat: 48.892531, lng: 21.423553 },
    { lat: 48.892531, lng: 21.42356 },
    { lat: 48.892532, lng: 21.423572 },
    { lat: 48.892002, lng: 21.423683 },
    { lat: 48.891487, lng: 21.423791 },
    { lat: 48.890719, lng: 21.423987 },
    { lat: 48.889676, lng: 21.424146 },
    { lat: 48.88895, lng: 21.42415 },
    { lat: 48.888768, lng: 21.424118 },
    { lat: 48.888454, lng: 21.423944 },
    { lat: 48.88826, lng: 21.42388 },
    { lat: 48.888119, lng: 21.423889 },
    { lat: 48.887968, lng: 21.423919 },
    { lat: 48.887831, lng: 21.424028 },
    { lat: 48.887099, lng: 21.424243 },
    { lat: 48.886987, lng: 21.424253 },
    { lat: 48.886999, lng: 21.424273 },
    { lat: 48.886931, lng: 21.424275 },
    { lat: 48.886875, lng: 21.424275 },
    { lat: 48.88683, lng: 21.424276 },
    { lat: 48.886822, lng: 21.424253 },
    { lat: 48.886739, lng: 21.424258 },
    { lat: 48.886145, lng: 21.423908 },
    { lat: 48.88598, lng: 21.42386 },
    { lat: 48.884922, lng: 21.424031 },
    { lat: 48.88422, lng: 21.424114 },
    { lat: 48.883659, lng: 21.42425 },
    { lat: 48.883295, lng: 21.424385 },
    { lat: 48.882665, lng: 21.424547 },
    { lat: 48.882179, lng: 21.42471 },
    { lat: 48.881398, lng: 21.424808 },
    { lat: 48.881393, lng: 21.424838 },
    { lat: 48.880934, lng: 21.424933 },
    { lat: 48.880695, lng: 21.425057 },
    { lat: 48.88019, lng: 21.425231 },
    { lat: 48.879845, lng: 21.425383 },
    { lat: 48.879876, lng: 21.425629 },
    { lat: 48.878834, lng: 21.425875 },
    { lat: 48.87878, lng: 21.42594 },
    { lat: 48.878621, lng: 21.427351 },
  ],
  Šindliar: [
    { lat: 49.0425708, lng: 20.9024814 },
    { lat: 49.0420918, lng: 20.9001993 },
    { lat: 49.0399317, lng: 20.8998956 },
    { lat: 49.0393903, lng: 20.9001575 },
    { lat: 49.0393598, lng: 20.8990219 },
    { lat: 49.0389753, lng: 20.8989038 },
    { lat: 49.0388904, lng: 20.8985527 },
    { lat: 49.03882, lng: 20.8967692 },
    { lat: 49.0389703, lng: 20.8963247 },
    { lat: 49.0395279, lng: 20.8955719 },
    { lat: 49.0397661, lng: 20.8951586 },
    { lat: 49.0400978, lng: 20.8942842 },
    { lat: 49.0403727, lng: 20.8924958 },
    { lat: 49.0405211, lng: 20.8906273 },
    { lat: 49.0406452, lng: 20.8899309 },
    { lat: 49.0406397, lng: 20.8884453 },
    { lat: 49.0410962, lng: 20.8870982 },
    { lat: 49.0412538, lng: 20.8863691 },
    { lat: 49.0414573, lng: 20.8859283 },
    { lat: 49.0414655, lng: 20.8854136 },
    { lat: 49.041895, lng: 20.884226 },
    { lat: 49.041744, lng: 20.883969 },
    { lat: 49.041577, lng: 20.883724 },
    { lat: 49.041471, lng: 20.883545 },
    { lat: 49.041226, lng: 20.883226 },
    { lat: 49.040935, lng: 20.882946 },
    { lat: 49.04059, lng: 20.882719 },
    { lat: 49.040447, lng: 20.882629 },
    { lat: 49.04009, lng: 20.882553 },
    { lat: 49.039957, lng: 20.882579 },
    { lat: 49.039811, lng: 20.882614 },
    { lat: 49.039634, lng: 20.882771 },
    { lat: 49.039506, lng: 20.882856 },
    { lat: 49.039277, lng: 20.882932 },
    { lat: 49.03916, lng: 20.883009 },
    { lat: 49.039026, lng: 20.883111 },
    { lat: 49.038816, lng: 20.883307 },
    { lat: 49.038565, lng: 20.883415 },
    { lat: 49.038475, lng: 20.88343 },
    { lat: 49.038347, lng: 20.883441 },
    { lat: 49.038205, lng: 20.883488 },
    { lat: 49.038118, lng: 20.883527 },
    { lat: 49.038049, lng: 20.883554 },
    { lat: 49.037928, lng: 20.883567 },
    { lat: 49.037797, lng: 20.883569 },
    { lat: 49.037206, lng: 20.883505 },
    { lat: 49.037064, lng: 20.883596 },
    { lat: 49.036729, lng: 20.883649 },
    { lat: 49.036092, lng: 20.883844 },
    { lat: 49.035973, lng: 20.883817 },
    { lat: 49.035827, lng: 20.883767 },
    { lat: 49.035719, lng: 20.883679 },
    { lat: 49.035648, lng: 20.883547 },
    { lat: 49.035614, lng: 20.883469 },
    { lat: 49.035556, lng: 20.883412 },
    { lat: 49.035309, lng: 20.883163 },
    { lat: 49.035026, lng: 20.882945 },
    { lat: 49.034793, lng: 20.882884 },
    { lat: 49.034576, lng: 20.882795 },
    { lat: 49.034349, lng: 20.882856 },
    { lat: 49.034055, lng: 20.882767 },
    { lat: 49.033937, lng: 20.882688 },
    { lat: 49.033798, lng: 20.882645 },
    { lat: 49.033683, lng: 20.882589 },
    { lat: 49.033598, lng: 20.882582 },
    { lat: 49.033542, lng: 20.882609 },
    { lat: 49.033373, lng: 20.882788 },
    { lat: 49.033281, lng: 20.882827 },
    { lat: 49.033176, lng: 20.882778 },
    { lat: 49.032995, lng: 20.882579 },
    { lat: 49.032917, lng: 20.882535 },
    { lat: 49.032806, lng: 20.882508 },
    { lat: 49.032685, lng: 20.882523 },
    { lat: 49.032556, lng: 20.882608 },
    { lat: 49.032303, lng: 20.882812 },
    { lat: 49.032074, lng: 20.882904 },
    { lat: 49.031852, lng: 20.882958 },
    { lat: 49.031648, lng: 20.883065 },
    { lat: 49.03131, lng: 20.883292 },
    { lat: 49.031094, lng: 20.883336 },
    { lat: 49.030966, lng: 20.883381 },
    { lat: 49.030694, lng: 20.883426 },
    { lat: 49.030547, lng: 20.883455 },
    { lat: 49.030306, lng: 20.883567 },
    { lat: 49.03019, lng: 20.883591 },
    { lat: 49.029932, lng: 20.883746 },
    { lat: 49.029835, lng: 20.883749 },
    { lat: 49.029674, lng: 20.883689 },
    { lat: 49.029586, lng: 20.883597 },
    { lat: 49.029376, lng: 20.883466 },
    { lat: 49.029029, lng: 20.88318 },
    { lat: 49.029038, lng: 20.883151 },
    { lat: 49.028888, lng: 20.883099 },
    { lat: 49.028103, lng: 20.882897 },
    { lat: 49.027412, lng: 20.882432 },
    { lat: 49.026997, lng: 20.882059 },
    { lat: 49.026689, lng: 20.882003 },
    { lat: 49.026621, lng: 20.882005 },
    { lat: 49.025712, lng: 20.882488 },
    { lat: 49.0248, lng: 20.883253 },
    { lat: 49.024793, lng: 20.883254 },
    { lat: 49.024371, lng: 20.883568 },
    { lat: 49.023831, lng: 20.883737 },
    { lat: 49.0241968, lng: 20.8840365 },
    { lat: 49.02468, lng: 20.8847421 },
    { lat: 49.0262208, lng: 20.8863331 },
    { lat: 49.0266267, lng: 20.8870488 },
    { lat: 49.0265445, lng: 20.8878124 },
    { lat: 49.0262421, lng: 20.8889895 },
    { lat: 49.0262575, lng: 20.8891077 },
    { lat: 49.0260726, lng: 20.8899306 },
    { lat: 49.026073, lng: 20.8903111 },
    { lat: 49.0258872, lng: 20.8912058 },
    { lat: 49.0258695, lng: 20.8916168 },
    { lat: 49.0257435, lng: 20.8920122 },
    { lat: 49.0257276, lng: 20.8930396 },
    { lat: 49.026325, lng: 20.8940287 },
    { lat: 49.0268819, lng: 20.8948251 },
    { lat: 49.0271072, lng: 20.8954968 },
    { lat: 49.0271754, lng: 20.8962174 },
    { lat: 49.0272802, lng: 20.8963175 },
    { lat: 49.0274825, lng: 20.8983049 },
    { lat: 49.0278828, lng: 20.8999945 },
    { lat: 49.0280272, lng: 20.9009039 },
    { lat: 49.0282664, lng: 20.9031567 },
    { lat: 49.0293356, lng: 20.9074581 },
    { lat: 49.0294009, lng: 20.9084588 },
    { lat: 49.0292572, lng: 20.9100467 },
    { lat: 49.0280284, lng: 20.9126237 },
    { lat: 49.0270001, lng: 20.9171192 },
    { lat: 49.0255204, lng: 20.9205173 },
    { lat: 49.0243754, lng: 20.924628 },
    { lat: 49.0237882, lng: 20.9259779 },
    { lat: 49.0238045, lng: 20.9259928 },
    { lat: 49.02377, lng: 20.9261034 },
    { lat: 49.0238074, lng: 20.9262849 },
    { lat: 49.0237132, lng: 20.9266967 },
    { lat: 49.0237282, lng: 20.9270101 },
    { lat: 49.0236484, lng: 20.9271468 },
    { lat: 49.0236443, lng: 20.9274968 },
    { lat: 49.0234969, lng: 20.9279956 },
    { lat: 49.0233825, lng: 20.9281092 },
    { lat: 49.0233734, lng: 20.928211 },
    { lat: 49.023264, lng: 20.928302 },
    { lat: 49.0232001, lng: 20.9284443 },
    { lat: 49.0231513, lng: 20.9288049 },
    { lat: 49.0231885, lng: 20.928872 },
    { lat: 49.02316, lng: 20.9291607 },
    { lat: 49.023077, lng: 20.9292389 },
    { lat: 49.0229493, lng: 20.9296239 },
    { lat: 49.0228598, lng: 20.9302466 },
    { lat: 49.0229159, lng: 20.9303782 },
    { lat: 49.022727, lng: 20.9307184 },
    { lat: 49.0228022, lng: 20.9309762 },
    { lat: 49.0227252, lng: 20.9313049 },
    { lat: 49.0228156, lng: 20.9315181 },
    { lat: 49.02275, lng: 20.9318065 },
    { lat: 49.0226453, lng: 20.9319039 },
    { lat: 49.0225398, lng: 20.9321491 },
    { lat: 49.022445, lng: 20.9322128 },
    { lat: 49.0224188, lng: 20.9324357 },
    { lat: 49.0221824, lng: 20.9327033 },
    { lat: 49.0222783, lng: 20.9329469 },
    { lat: 49.0221599, lng: 20.9331791 },
    { lat: 49.022133, lng: 20.9334792 },
    { lat: 49.0220716, lng: 20.93366 },
    { lat: 49.0218058, lng: 20.9339923 },
    { lat: 49.0215679, lng: 20.9339795 },
    { lat: 49.0214655, lng: 20.9342836 },
    { lat: 49.0213161, lng: 20.9344641 },
    { lat: 49.0212471, lng: 20.9350938 },
    { lat: 49.0208088, lng: 20.9362516 },
    { lat: 49.0207065, lng: 20.9369943 },
    { lat: 49.0205821, lng: 20.9373155 },
    { lat: 49.0206596, lng: 20.9378474 },
    { lat: 49.0204863, lng: 20.9380186 },
    { lat: 49.0203045, lng: 20.9384006 },
    { lat: 49.020331, lng: 20.9385592 },
    { lat: 49.0202344, lng: 20.9389013 },
    { lat: 49.0203003, lng: 20.939088 },
    { lat: 49.0202188, lng: 20.9392094 },
    { lat: 49.02164, lng: 20.9400058 },
    { lat: 49.0214596, lng: 20.9410095 },
    { lat: 49.0217623, lng: 20.941174 },
    { lat: 49.0216726, lng: 20.9416504 },
    { lat: 49.0220387, lng: 20.9421059 },
    { lat: 49.0230529, lng: 20.9437575 },
    { lat: 49.0231942, lng: 20.9447181 },
    { lat: 49.0234132, lng: 20.9453431 },
    { lat: 49.0229451, lng: 20.9467305 },
    { lat: 49.0232911, lng: 20.9475161 },
    { lat: 49.0238213, lng: 20.948938 },
    { lat: 49.0270082, lng: 20.9564222 },
    { lat: 49.0271767, lng: 20.9564817 },
    { lat: 49.0275565, lng: 20.9568315 },
    { lat: 49.0274358, lng: 20.9574624 },
    { lat: 49.0280967, lng: 20.9581507 },
    { lat: 49.0278175, lng: 20.9587575 },
    { lat: 49.0279452, lng: 20.9596174 },
    { lat: 49.0282481, lng: 20.9601306 },
    { lat: 49.0277184, lng: 20.9606136 },
    { lat: 49.0272516, lng: 20.9615393 },
    { lat: 49.0271992, lng: 20.9618582 },
    { lat: 49.0262912, lng: 20.9634555 },
    { lat: 49.0263311, lng: 20.9635048 },
    { lat: 49.0267321, lng: 20.9630393 },
    { lat: 49.0270895, lng: 20.9627238 },
    { lat: 49.0271962, lng: 20.9628185 },
    { lat: 49.0278936, lng: 20.9623009 },
    { lat: 49.0282911, lng: 20.9617621 },
    { lat: 49.0290171, lng: 20.9606095 },
    { lat: 49.0297636, lng: 20.9591623 },
    { lat: 49.0305543, lng: 20.9600975 },
    { lat: 49.0304196, lng: 20.9610891 },
    { lat: 49.030508, lng: 20.9612068 },
    { lat: 49.0306908, lng: 20.9619036 },
    { lat: 49.0309601, lng: 20.9625849 },
    { lat: 49.0319657, lng: 20.9619233 },
    { lat: 49.0329361, lng: 20.9606499 },
    { lat: 49.0328774, lng: 20.9604378 },
    { lat: 49.0329056, lng: 20.9600009 },
    { lat: 49.0331366, lng: 20.9595014 },
    { lat: 49.0332918, lng: 20.9593234 },
    { lat: 49.0336531, lng: 20.9590879 },
    { lat: 49.0340374, lng: 20.9590724 },
    { lat: 49.0344096, lng: 20.9588597 },
    { lat: 49.0358369, lng: 20.957611 },
    { lat: 49.0362773, lng: 20.9570082 },
    { lat: 49.036756, lng: 20.9562152 },
    { lat: 49.036815, lng: 20.9555499 },
    { lat: 49.0369222, lng: 20.9551829 },
    { lat: 49.0372042, lng: 20.9543964 },
    { lat: 49.0374197, lng: 20.9540666 },
    { lat: 49.0373478, lng: 20.953872 },
    { lat: 49.0374843, lng: 20.9537851 },
    { lat: 49.0380151, lng: 20.9537036 },
    { lat: 49.0388509, lng: 20.9542268 },
    { lat: 49.0394033, lng: 20.9543184 },
    { lat: 49.0397332, lng: 20.9545312 },
    { lat: 49.0398385, lng: 20.95478 },
    { lat: 49.0397126, lng: 20.9549586 },
    { lat: 49.0404213, lng: 20.9564671 },
    { lat: 49.040471, lng: 20.9564026 },
    { lat: 49.0416079, lng: 20.9582072 },
    { lat: 49.0417452, lng: 20.9583358 },
    { lat: 49.0424246, lng: 20.9583976 },
    { lat: 49.0427859, lng: 20.9582905 },
    { lat: 49.0435875, lng: 20.9577287 },
    { lat: 49.0442715, lng: 20.9570432 },
    { lat: 49.0445314, lng: 20.9568657 },
    { lat: 49.0453786, lng: 20.956456 },
    { lat: 49.0453558, lng: 20.9526874 },
    { lat: 49.0459741, lng: 20.9497623 },
    { lat: 49.0461363, lng: 20.9482464 },
    { lat: 49.0463776, lng: 20.9468483 },
    { lat: 49.0465482, lng: 20.9451811 },
    { lat: 49.0463867, lng: 20.9451265 },
    { lat: 49.0460125, lng: 20.945178 },
    { lat: 49.0459297, lng: 20.9420899 },
    { lat: 49.0458654, lng: 20.9406034 },
    { lat: 49.0457816, lng: 20.9396845 },
    { lat: 49.046122, lng: 20.9381847 },
    { lat: 49.0466533, lng: 20.9370019 },
    { lat: 49.0467133, lng: 20.9367109 },
    { lat: 49.0474354, lng: 20.9359898 },
    { lat: 49.047481, lng: 20.9352655 },
    { lat: 49.0475967, lng: 20.9350208 },
    { lat: 49.0476596, lng: 20.9338474 },
    { lat: 49.0467144, lng: 20.9329955 },
    { lat: 49.0462997, lng: 20.9321228 },
    { lat: 49.0462064, lng: 20.9318254 },
    { lat: 49.0461302, lng: 20.9310057 },
    { lat: 49.0462263, lng: 20.9306018 },
    { lat: 49.046063, lng: 20.9299555 },
    { lat: 49.0457, lng: 20.9291166 },
    { lat: 49.0454459, lng: 20.9281521 },
    { lat: 49.0454279, lng: 20.9271822 },
    { lat: 49.0452905, lng: 20.926858 },
    { lat: 49.0454966, lng: 20.9256917 },
    { lat: 49.0459108, lng: 20.9249811 },
    { lat: 49.0459289, lng: 20.9248541 },
    { lat: 49.046091, lng: 20.9247848 },
    { lat: 49.0461509, lng: 20.9246596 },
    { lat: 49.0461828, lng: 20.9244002 },
    { lat: 49.046426, lng: 20.9241334 },
    { lat: 49.0464104, lng: 20.9238368 },
    { lat: 49.0463208, lng: 20.9234807 },
    { lat: 49.046264, lng: 20.923404 },
    { lat: 49.0463012, lng: 20.9231346 },
    { lat: 49.0462695, lng: 20.9228923 },
    { lat: 49.0463371, lng: 20.9226599 },
    { lat: 49.0463002, lng: 20.9225354 },
    { lat: 49.046194, lng: 20.9224906 },
    { lat: 49.0461401, lng: 20.9223904 },
    { lat: 49.0459863, lng: 20.9220023 },
    { lat: 49.0458988, lng: 20.9215387 },
    { lat: 49.0458446, lng: 20.9214708 },
    { lat: 49.0458152, lng: 20.921031 },
    { lat: 49.0457849, lng: 20.9209301 },
    { lat: 49.0456218, lng: 20.9207644 },
    { lat: 49.0454057, lng: 20.9199273 },
    { lat: 49.0454875, lng: 20.9194829 },
    { lat: 49.0454805, lng: 20.9192728 },
    { lat: 49.0455479, lng: 20.9190684 },
    { lat: 49.0455491, lng: 20.9188014 },
    { lat: 49.0455234, lng: 20.9186775 },
    { lat: 49.0452718, lng: 20.9182216 },
    { lat: 49.0451818, lng: 20.9178881 },
    { lat: 49.0451943, lng: 20.917725 },
    { lat: 49.0451394, lng: 20.9175326 },
    { lat: 49.0453043, lng: 20.916978 },
    { lat: 49.0452589, lng: 20.9168973 },
    { lat: 49.0452771, lng: 20.9164021 },
    { lat: 49.0452122, lng: 20.9160147 },
    { lat: 49.0450344, lng: 20.9156791 },
    { lat: 49.0447978, lng: 20.9155005 },
    { lat: 49.0447473, lng: 20.915339 },
    { lat: 49.0444803, lng: 20.9150315 },
    { lat: 49.0445059, lng: 20.9149581 },
    { lat: 49.0443383, lng: 20.9148259 },
    { lat: 49.044071, lng: 20.9147286 },
    { lat: 49.0438083, lng: 20.9147283 },
    { lat: 49.0436529, lng: 20.9148975 },
    { lat: 49.0432858, lng: 20.9147894 },
    { lat: 49.0430541, lng: 20.9147916 },
    { lat: 49.0423631, lng: 20.9148981 },
    { lat: 49.0421678, lng: 20.9150095 },
    { lat: 49.0419716, lng: 20.9150047 },
    { lat: 49.0417174, lng: 20.9144038 },
    { lat: 49.0415444, lng: 20.9142809 },
    { lat: 49.0412455, lng: 20.9132889 },
    { lat: 49.0412261, lng: 20.9131186 },
    { lat: 49.0412763, lng: 20.9130361 },
    { lat: 49.0412454, lng: 20.912453 },
    { lat: 49.0411668, lng: 20.9121574 },
    { lat: 49.0412229, lng: 20.9118811 },
    { lat: 49.041206, lng: 20.9116532 },
    { lat: 49.0413262, lng: 20.911379 },
    { lat: 49.0414254, lng: 20.9109487 },
    { lat: 49.0416528, lng: 20.910409 },
    { lat: 49.0416238, lng: 20.9102193 },
    { lat: 49.0417796, lng: 20.9098677 },
    { lat: 49.0420646, lng: 20.90964 },
    { lat: 49.0421443, lng: 20.9093019 },
    { lat: 49.04233, lng: 20.9089979 },
    { lat: 49.0424268, lng: 20.9086287 },
    { lat: 49.0422599, lng: 20.9077487 },
    { lat: 49.0422973, lng: 20.9075578 },
    { lat: 49.0426006, lng: 20.9068712 },
    { lat: 49.0425951, lng: 20.9067432 },
    { lat: 49.0427274, lng: 20.9061617 },
    { lat: 49.0428539, lng: 20.9060722 },
    { lat: 49.0428994, lng: 20.9059186 },
    { lat: 49.0428754, lng: 20.9057444 },
    { lat: 49.0430054, lng: 20.905125 },
    { lat: 49.0428905, lng: 20.9040526 },
    { lat: 49.0425708, lng: 20.9024814 },
  ],
  Záborské: [
    { lat: 48.9511156, lng: 21.2593011 },
    { lat: 48.951168, lng: 21.2601728 },
    { lat: 48.9510806, lng: 21.2620096 },
    { lat: 48.9508741, lng: 21.263132 },
    { lat: 48.9508687, lng: 21.2664374 },
    { lat: 48.9503755, lng: 21.2729662 },
    { lat: 48.9503951, lng: 21.2745943 },
    { lat: 48.9495478, lng: 21.2750779 },
    { lat: 48.9471405, lng: 21.2766507 },
    { lat: 48.9466209, lng: 21.2770235 },
    { lat: 48.9461092, lng: 21.2775497 },
    { lat: 48.945976, lng: 21.2776113 },
    { lat: 48.9458481, lng: 21.2774005 },
    { lat: 48.9457509, lng: 21.277097 },
    { lat: 48.9455773, lng: 21.2769016 },
    { lat: 48.945443, lng: 21.276629 },
    { lat: 48.9451263, lng: 21.2767995 },
    { lat: 48.9450829, lng: 21.2767442 },
    { lat: 48.9450794, lng: 21.2764069 },
    { lat: 48.9448881, lng: 21.2763652 },
    { lat: 48.9448657, lng: 21.2762251 },
    { lat: 48.9449144, lng: 21.2761994 },
    { lat: 48.94496, lng: 21.275849 },
    { lat: 48.9447923, lng: 21.275961 },
    { lat: 48.9446478, lng: 21.2758713 },
    { lat: 48.9444926, lng: 21.2757248 },
    { lat: 48.9443679, lng: 21.2754386 },
    { lat: 48.9441731, lng: 21.2752536 },
    { lat: 48.9439393, lng: 21.2754441 },
    { lat: 48.9429844, lng: 21.2758225 },
    { lat: 48.9428503, lng: 21.2758187 },
    { lat: 48.9406508, lng: 21.2769786 },
    { lat: 48.9403637, lng: 21.2770042 },
    { lat: 48.9402209, lng: 21.2771245 },
    { lat: 48.9397684, lng: 21.2772609 },
    { lat: 48.9395224, lng: 21.2774218 },
    { lat: 48.9393803, lng: 21.2776015 },
    { lat: 48.939345, lng: 21.2779146 },
    { lat: 48.93928, lng: 21.2780696 },
    { lat: 48.9390205, lng: 21.2781985 },
    { lat: 48.9389318, lng: 21.2783901 },
    { lat: 48.9387501, lng: 21.2784097 },
    { lat: 48.938686, lng: 21.2785932 },
    { lat: 48.9385543, lng: 21.2787385 },
    { lat: 48.938262, lng: 21.2797828 },
    { lat: 48.9379603, lng: 21.2801094 },
    { lat: 48.9378079, lng: 21.2804566 },
    { lat: 48.9375992, lng: 21.2812487 },
    { lat: 48.9367533, lng: 21.2836555 },
    { lat: 48.9366461, lng: 21.2855683 },
    { lat: 48.9364294, lng: 21.2868742 },
    { lat: 48.936313, lng: 21.2882626 },
    { lat: 48.9363061, lng: 21.2896915 },
    { lat: 48.9363363, lng: 21.2898383 },
    { lat: 48.9362828, lng: 21.2906291 },
    { lat: 48.9359859, lng: 21.2920351 },
    { lat: 48.9355916, lng: 21.2932436 },
    { lat: 48.9351997, lng: 21.2940785 },
    { lat: 48.9350484, lng: 21.2945469 },
    { lat: 48.9347852, lng: 21.2949945 },
    { lat: 48.934522, lng: 21.2952939 },
    { lat: 48.934397, lng: 21.2955493 },
    { lat: 48.9341734, lng: 21.2964082 },
    { lat: 48.9333791, lng: 21.2973042 },
    { lat: 48.9327706, lng: 21.2978286 },
    { lat: 48.9324173, lng: 21.2983504 },
    { lat: 48.9322298, lng: 21.298769 },
    { lat: 48.9320534, lng: 21.2992953 },
    { lat: 48.9314582, lng: 21.3022592 },
    { lat: 48.9313396, lng: 21.3025484 },
    { lat: 48.9316895, lng: 21.3029516 },
    { lat: 48.9319235, lng: 21.3033951 },
    { lat: 48.9322346, lng: 21.303708 },
    { lat: 48.9324811, lng: 21.3037126 },
    { lat: 48.932611, lng: 21.3037962 },
    { lat: 48.9328344, lng: 21.3040801 },
    { lat: 48.933763, lng: 21.3048269 },
    { lat: 48.9341117, lng: 21.3052281 },
    { lat: 48.9347937, lng: 21.3056045 },
    { lat: 48.9351758, lng: 21.3050309 },
    { lat: 48.9353926, lng: 21.3043504 },
    { lat: 48.9356486, lng: 21.3027792 },
    { lat: 48.9360594, lng: 21.3022608 },
    { lat: 48.9363646, lng: 21.3020054 },
    { lat: 48.9367234, lng: 21.3014119 },
    { lat: 48.9371899, lng: 21.3003903 },
    { lat: 48.9387076, lng: 21.3018683 },
    { lat: 48.9389026, lng: 21.3020243 },
    { lat: 48.9393743, lng: 21.3021839 },
    { lat: 48.9414517, lng: 21.3040402 },
    { lat: 48.9416497, lng: 21.3042604 },
    { lat: 48.9443215, lng: 21.3043052 },
    { lat: 48.9491799, lng: 21.3047705 },
    { lat: 48.9503439, lng: 21.2990059 },
    { lat: 48.9504614, lng: 21.2989851 },
    { lat: 48.9512371, lng: 21.2992211 },
    { lat: 48.9527179, lng: 21.299406 },
    { lat: 48.9540228, lng: 21.2997944 },
    { lat: 48.9546541, lng: 21.298952 },
    { lat: 48.9548344, lng: 21.2983669 },
    { lat: 48.9548895, lng: 21.2978807 },
    { lat: 48.9547475, lng: 21.2977256 },
    { lat: 48.954901, lng: 21.2972211 },
    { lat: 48.9549881, lng: 21.2967212 },
    { lat: 48.9548538, lng: 21.2963343 },
    { lat: 48.9546594, lng: 21.2960833 },
    { lat: 48.9546892, lng: 21.2954371 },
    { lat: 48.9548762, lng: 21.2948865 },
    { lat: 48.955013, lng: 21.2947634 },
    { lat: 48.9551255, lng: 21.2945006 },
    { lat: 48.9554451, lng: 21.2940603 },
    { lat: 48.9551071, lng: 21.292602 },
    { lat: 48.95515, lng: 21.2920899 },
    { lat: 48.9550507, lng: 21.2919336 },
    { lat: 48.9551942, lng: 21.2903681 },
    { lat: 48.9551684, lng: 21.2895206 },
    { lat: 48.9553312, lng: 21.2889108 },
    { lat: 48.9553908, lng: 21.2882127 },
    { lat: 48.9558467, lng: 21.2869629 },
    { lat: 48.9558025, lng: 21.2863952 },
    { lat: 48.9560455, lng: 21.2854418 },
    { lat: 48.9562056, lng: 21.2854477 },
    { lat: 48.9565295, lng: 21.2845165 },
    { lat: 48.9567337, lng: 21.2835418 },
    { lat: 48.956716, lng: 21.2832584 },
    { lat: 48.9572158, lng: 21.2825289 },
    { lat: 48.9573924, lng: 21.2819704 },
    { lat: 48.9577635, lng: 21.281188 },
    { lat: 48.9578024, lng: 21.2808738 },
    { lat: 48.9573984, lng: 21.2813548 },
    { lat: 48.957601, lng: 21.2802524 },
    { lat: 48.9575911, lng: 21.2799304 },
    { lat: 48.9576718, lng: 21.2793994 },
    { lat: 48.9577037, lng: 21.2785289 },
    { lat: 48.9577583, lng: 21.2781791 },
    { lat: 48.9577526, lng: 21.276966 },
    { lat: 48.9578833, lng: 21.2761199 },
    { lat: 48.9576423, lng: 21.2743838 },
    { lat: 48.957705, lng: 21.2727218 },
    { lat: 48.9584427, lng: 21.2712742 },
    { lat: 48.9585034, lng: 21.2705008 },
    { lat: 48.9584532, lng: 21.2701856 },
    { lat: 48.9584835, lng: 21.2686738 },
    { lat: 48.9584306, lng: 21.2686711 },
    { lat: 48.9584219, lng: 21.2679501 },
    { lat: 48.9583136, lng: 21.2675704 },
    { lat: 48.9582878, lng: 21.2672828 },
    { lat: 48.9583994, lng: 21.2668478 },
    { lat: 48.9584297, lng: 21.2661513 },
    { lat: 48.9588665, lng: 21.2642093 },
    { lat: 48.9588868, lng: 21.2639568 },
    { lat: 48.9587659, lng: 21.2631665 },
    { lat: 48.9567587, lng: 21.2626282 },
    { lat: 48.9559322, lng: 21.2625048 },
    { lat: 48.9559642, lng: 21.2617871 },
    { lat: 48.9564889, lng: 21.2581348 },
    { lat: 48.9516405, lng: 21.2587716 },
    { lat: 48.9511839, lng: 21.2592909 },
    { lat: 48.9511156, lng: 21.2593011 },
  ],
  VeľkýSlivník: [
    { lat: 49.0952792, lng: 21.2942746 },
    { lat: 49.095465, lng: 21.2942066 },
    { lat: 49.0955257, lng: 21.2941316 },
    { lat: 49.0958241, lng: 21.2940266 },
    { lat: 49.0961357, lng: 21.2932786 },
    { lat: 49.0963042, lng: 21.2931267 },
    { lat: 49.0964051, lng: 21.292552 },
    { lat: 49.0966015, lng: 21.292317 },
    { lat: 49.0970328, lng: 21.2922166 },
    { lat: 49.0970366, lng: 21.2920799 },
    { lat: 49.0972374, lng: 21.2915792 },
    { lat: 49.0974957, lng: 21.2911567 },
    { lat: 49.0981179, lng: 21.2905646 },
    { lat: 49.0983682, lng: 21.290096 },
    { lat: 49.0988205, lng: 21.2898713 },
    { lat: 49.0991926, lng: 21.2894127 },
    { lat: 49.0994031, lng: 21.2892715 },
    { lat: 49.0997115, lng: 21.289351 },
    { lat: 49.0999413, lng: 21.2890196 },
    { lat: 49.0999928, lng: 21.2886048 },
    { lat: 49.1001748, lng: 21.2883794 },
    { lat: 49.1019988, lng: 21.2873739 },
    { lat: 49.1024039, lng: 21.2869715 },
    { lat: 49.1025772, lng: 21.2868933 },
    { lat: 49.1027131, lng: 21.2869192 },
    { lat: 49.1030722, lng: 21.2867406 },
    { lat: 49.1033171, lng: 21.2868389 },
    { lat: 49.1035495, lng: 21.2868225 },
    { lat: 49.1038959, lng: 21.2864128 },
    { lat: 49.1039532, lng: 21.2866271 },
    { lat: 49.104334, lng: 21.2870542 },
    { lat: 49.104345, lng: 21.2871931 },
    { lat: 49.105204, lng: 21.2888779 },
    { lat: 49.1059131, lng: 21.2886239 },
    { lat: 49.1060133, lng: 21.2887408 },
    { lat: 49.1062031, lng: 21.2893142 },
    { lat: 49.1074304, lng: 21.2894946 },
    { lat: 49.1075734, lng: 21.2896056 },
    { lat: 49.107523, lng: 21.2903206 },
    { lat: 49.1076632, lng: 21.2913741 },
    { lat: 49.1101758, lng: 21.2898604 },
    { lat: 49.1106188, lng: 21.2894804 },
    { lat: 49.1114116, lng: 21.2886452 },
    { lat: 49.112445, lng: 21.2877337 },
    { lat: 49.1132112, lng: 21.2888793 },
    { lat: 49.1132869, lng: 21.2890723 },
    { lat: 49.1124948, lng: 21.2930787 },
    { lat: 49.1121667, lng: 21.294434 },
    { lat: 49.1124637, lng: 21.2950233 },
    { lat: 49.1126467, lng: 21.2949777 },
    { lat: 49.1134544, lng: 21.2950064 },
    { lat: 49.1139047, lng: 21.2951216 },
    { lat: 49.1135297, lng: 21.2958951 },
    { lat: 49.1134236, lng: 21.295996 },
    { lat: 49.1133468, lng: 21.2967291 },
    { lat: 49.1143266, lng: 21.2964623 },
    { lat: 49.1151611, lng: 21.2967807 },
    { lat: 49.1161329, lng: 21.2968773 },
    { lat: 49.1163406, lng: 21.2964507 },
    { lat: 49.1167448, lng: 21.2964194 },
    { lat: 49.116911, lng: 21.2973466 },
    { lat: 49.1168291, lng: 21.2975374 },
    { lat: 49.1170459, lng: 21.2986378 },
    { lat: 49.1171585, lng: 21.2990883 },
    { lat: 49.1173736, lng: 21.2995412 },
    { lat: 49.1178909, lng: 21.3037307 },
    { lat: 49.1183609, lng: 21.3034943 },
    { lat: 49.1184074, lng: 21.3034318 },
    { lat: 49.1184462, lng: 21.3030778 },
    { lat: 49.11945, lng: 21.3026348 },
    { lat: 49.1193852, lng: 21.3021321 },
    { lat: 49.1189899, lng: 21.300857 },
    { lat: 49.12376, lng: 21.30432 },
    { lat: 49.123842, lng: 21.303945 },
    { lat: 49.123791, lng: 21.303541 },
    { lat: 49.12383, lng: 21.303364 },
    { lat: 49.123772, lng: 21.303252 },
    { lat: 49.123722, lng: 21.302991 },
    { lat: 49.123798, lng: 21.302319 },
    { lat: 49.123916, lng: 21.302014 },
    { lat: 49.1239, lng: 21.301814 },
    { lat: 49.123916, lng: 21.301716 },
    { lat: 49.124007, lng: 21.301561 },
    { lat: 49.123949, lng: 21.301215 },
    { lat: 49.124297, lng: 21.301251 },
    { lat: 49.124341, lng: 21.301216 },
    { lat: 49.124458, lng: 21.300765 },
    { lat: 49.124543, lng: 21.300265 },
    { lat: 49.124696, lng: 21.30005 },
    { lat: 49.124713, lng: 21.299939 },
    { lat: 49.124541, lng: 21.299648 },
    { lat: 49.124952, lng: 21.299198 },
    { lat: 49.125142, lng: 21.298898 },
    { lat: 49.125334, lng: 21.298893 },
    { lat: 49.125381, lng: 21.298332 },
    { lat: 49.125517, lng: 21.298017 },
    { lat: 49.125839, lng: 21.297601 },
    { lat: 49.125961, lng: 21.297231 },
    { lat: 49.126088, lng: 21.297102 },
    { lat: 49.126218, lng: 21.297047 },
    { lat: 49.126377, lng: 21.297242 },
    { lat: 49.126666, lng: 21.296762 },
    { lat: 49.126839, lng: 21.296746 },
    { lat: 49.126871, lng: 21.296649 },
    { lat: 49.126775, lng: 21.296477 },
    { lat: 49.126769, lng: 21.29641 },
    { lat: 49.126881, lng: 21.296296 },
    { lat: 49.126855, lng: 21.296129 },
    { lat: 49.126993, lng: 21.295685 },
    { lat: 49.127154, lng: 21.295084 },
    { lat: 49.12715, lng: 21.294943 },
    { lat: 49.127156, lng: 21.294887 },
    { lat: 49.1270307, lng: 21.2942788 },
    { lat: 49.1272705, lng: 21.29399 },
    { lat: 49.1272297, lng: 21.2939059 },
    { lat: 49.1269541, lng: 21.293718 },
    { lat: 49.1267903, lng: 21.2934791 },
    { lat: 49.1267911, lng: 21.2933917 },
    { lat: 49.1269458, lng: 21.2933374 },
    { lat: 49.1269596, lng: 21.2932009 },
    { lat: 49.1266574, lng: 21.2928457 },
    { lat: 49.1268064, lng: 21.2925252 },
    { lat: 49.1267623, lng: 21.2923314 },
    { lat: 49.126566, lng: 21.2920182 },
    { lat: 49.1266789, lng: 21.2917372 },
    { lat: 49.1265321, lng: 21.2916053 },
    { lat: 49.1265053, lng: 21.2914312 },
    { lat: 49.1266116, lng: 21.2911503 },
    { lat: 49.1265564, lng: 21.2908132 },
    { lat: 49.1264251, lng: 21.2907542 },
    { lat: 49.1263543, lng: 21.2903076 },
    { lat: 49.1263581, lng: 21.2900209 },
    { lat: 49.1264241, lng: 21.2899038 },
    { lat: 49.1265204, lng: 21.2899236 },
    { lat: 49.1264974, lng: 21.2896878 },
    { lat: 49.1267644, lng: 21.2890298 },
    { lat: 49.127009, lng: 21.2889687 },
    { lat: 49.1269883, lng: 21.288769 },
    { lat: 49.1272347, lng: 21.2883201 },
    { lat: 49.1273788, lng: 21.2882119 },
    { lat: 49.1274662, lng: 21.2879053 },
    { lat: 49.1273617, lng: 21.2852817 },
    { lat: 49.1272815, lng: 21.2846744 },
    { lat: 49.1271255, lng: 21.2841752 },
    { lat: 49.1266761, lng: 21.2833457 },
    { lat: 49.1260792, lng: 21.2816968 },
    { lat: 49.1261976, lng: 21.2809716 },
    { lat: 49.1260351, lng: 21.2800303 },
    { lat: 49.1258038, lng: 21.2793831 },
    { lat: 49.1258757, lng: 21.2778252 },
    { lat: 49.1246112, lng: 21.2779434 },
    { lat: 49.1242708, lng: 21.2771462 },
    { lat: 49.1229343, lng: 21.2764586 },
    { lat: 49.1212637, lng: 21.2763569 },
    { lat: 49.120038, lng: 21.2765312 },
    { lat: 49.118314, lng: 21.2755672 },
    { lat: 49.1164203, lng: 21.275273 },
    { lat: 49.1152015, lng: 21.275448 },
    { lat: 49.1151299, lng: 21.2752429 },
    { lat: 49.1153909, lng: 21.2708896 },
    { lat: 49.1152765, lng: 21.2704555 },
    { lat: 49.1150368, lng: 21.27005 },
    { lat: 49.1143393, lng: 21.2703487 },
    { lat: 49.1137643, lng: 21.2656356 },
    { lat: 49.110696, lng: 21.2664189 },
    { lat: 49.109431, lng: 21.2615539 },
    { lat: 49.1092624, lng: 21.2603063 },
    { lat: 49.1089375, lng: 21.2603506 },
    { lat: 49.1086467, lng: 21.2594188 },
    { lat: 49.1085912, lng: 21.2592234 },
    { lat: 49.1082641, lng: 21.2594521 },
    { lat: 49.1079281, lng: 21.2596534 },
    { lat: 49.1074466, lng: 21.2600593 },
    { lat: 49.107363, lng: 21.260155 },
    { lat: 49.1072024, lng: 21.2605705 },
    { lat: 49.107064, lng: 21.2607161 },
    { lat: 49.1066959, lng: 21.2606381 },
    { lat: 49.1064576, lng: 21.2605426 },
    { lat: 49.1062647, lng: 21.2603404 },
    { lat: 49.1061822, lng: 21.2601405 },
    { lat: 49.1061244, lng: 21.2597646 },
    { lat: 49.1062587, lng: 21.2594067 },
    { lat: 49.1061561, lng: 21.2592574 },
    { lat: 49.1056519, lng: 21.2591417 },
    { lat: 49.1054482, lng: 21.2592481 },
    { lat: 49.1052177, lng: 21.2589309 },
    { lat: 49.1049322, lng: 21.2586775 },
    { lat: 49.1048557, lng: 21.2584955 },
    { lat: 49.1048607, lng: 21.2582915 },
    { lat: 49.1046527, lng: 21.2582962 },
    { lat: 49.1037981, lng: 21.2578775 },
    { lat: 49.1032582, lng: 21.2572562 },
    { lat: 49.1029825, lng: 21.2573709 },
    { lat: 49.1027134, lng: 21.2573606 },
    { lat: 49.1027125, lng: 21.2575557 },
    { lat: 49.1026714, lng: 21.258014 },
    { lat: 49.1024348, lng: 21.258335 },
    { lat: 49.1022866, lng: 21.2583771 },
    { lat: 49.1019804, lng: 21.2580487 },
    { lat: 49.1016215, lng: 21.2579036 },
    { lat: 49.1016813, lng: 21.2583842 },
    { lat: 49.1023241, lng: 21.2623573 },
    { lat: 49.1025326, lng: 21.2629218 },
    { lat: 49.1022674, lng: 21.2635576 },
    { lat: 49.1018785, lng: 21.2649351 },
    { lat: 49.1022511, lng: 21.2655313 },
    { lat: 49.1028367, lng: 21.2662827 },
    { lat: 49.1026459, lng: 21.2668123 },
    { lat: 49.1028937, lng: 21.2683734 },
    { lat: 49.1029156, lng: 21.2687873 },
    { lat: 49.1028673, lng: 21.269607 },
    { lat: 49.1026971, lng: 21.2709902 },
    { lat: 49.1023098, lng: 21.2712094 },
    { lat: 49.1019904, lng: 21.2712912 },
    { lat: 49.1012846, lng: 21.272346 },
    { lat: 49.1009085, lng: 21.2731127 },
    { lat: 49.100676, lng: 21.2729798 },
    { lat: 49.1003559, lng: 21.2740646 },
    { lat: 49.099919, lng: 21.2751653 },
    { lat: 49.0994477, lng: 21.2752805 },
    { lat: 49.0992047, lng: 21.2754715 },
    { lat: 49.0989997, lng: 21.2754993 },
    { lat: 49.0989001, lng: 21.2756255 },
    { lat: 49.0981534, lng: 21.2754205 },
    { lat: 49.0979275, lng: 21.2760922 },
    { lat: 49.0975972, lng: 21.2764785 },
    { lat: 49.0972477, lng: 21.2763456 },
    { lat: 49.0972407, lng: 21.2774086 },
    { lat: 49.097109, lng: 21.2776301 },
    { lat: 49.0964276, lng: 21.2794873 },
    { lat: 49.0961757, lng: 21.2804914 },
    { lat: 49.09616, lng: 21.2807884 },
    { lat: 49.0963262, lng: 21.2815558 },
    { lat: 49.0962451, lng: 21.2818093 },
    { lat: 49.0957708, lng: 21.2827523 },
    { lat: 49.0950303, lng: 21.284012 },
    { lat: 49.0948351, lng: 21.2846161 },
    { lat: 49.0944761, lng: 21.2851703 },
    { lat: 49.0937356, lng: 21.2859101 },
    { lat: 49.0931567, lng: 21.286297 },
    { lat: 49.0934446, lng: 21.2869241 },
    { lat: 49.0939291, lng: 21.2906502 },
    { lat: 49.0942425, lng: 21.2916687 },
    { lat: 49.0949119, lng: 21.2934466 },
    { lat: 49.0950556, lng: 21.2942113 },
    { lat: 49.0952792, lng: 21.2942746 },
  ],
  Teriakovce: [
    { lat: 48.9876902, lng: 21.2980419 },
    { lat: 48.987331, lng: 21.2984364 },
    { lat: 48.9877273, lng: 21.2988522 },
    { lat: 48.9874727, lng: 21.2995841 },
    { lat: 48.9866997, lng: 21.3011435 },
    { lat: 48.9870139, lng: 21.3020405 },
    { lat: 48.9868216, lng: 21.3021238 },
    { lat: 48.9869868, lng: 21.3030058 },
    { lat: 48.9872357, lng: 21.3036387 },
    { lat: 48.9870694, lng: 21.3041272 },
    { lat: 48.987291, lng: 21.3046097 },
    { lat: 48.9877419, lng: 21.3049378 },
    { lat: 48.9877893, lng: 21.305233 },
    { lat: 48.9876456, lng: 21.3054688 },
    { lat: 48.9874788, lng: 21.305536 },
    { lat: 48.9876653, lng: 21.3060432 },
    { lat: 48.9875788, lng: 21.3061986 },
    { lat: 48.9874581, lng: 21.3062637 },
    { lat: 48.9874483, lng: 21.3063518 },
    { lat: 48.9876397, lng: 21.3066827 },
    { lat: 48.9876263, lng: 21.306842 },
    { lat: 48.9878577, lng: 21.3071945 },
    { lat: 48.9878471, lng: 21.307326 },
    { lat: 48.9875982, lng: 21.3078837 },
    { lat: 48.9876324, lng: 21.3081167 },
    { lat: 48.9876023, lng: 21.3083463 },
    { lat: 48.9875071, lng: 21.308511 },
    { lat: 48.9875086, lng: 21.3086008 },
    { lat: 48.9876257, lng: 21.3086743 },
    { lat: 48.9876189, lng: 21.3088625 },
    { lat: 48.9875383, lng: 21.3090577 },
    { lat: 48.9876534, lng: 21.310002 },
    { lat: 48.987724, lng: 21.3101361 },
    { lat: 48.9877717, lng: 21.3108257 },
    { lat: 48.9877522, lng: 21.31096 },
    { lat: 48.9876118, lng: 21.3111919 },
    { lat: 48.9876225, lng: 21.311691 },
    { lat: 48.9873672, lng: 21.3118124 },
    { lat: 48.9874151, lng: 21.3126069 },
    { lat: 48.9873752, lng: 21.3130418 },
    { lat: 48.9865279, lng: 21.3160198 },
    { lat: 48.9857136, lng: 21.3183406 },
    { lat: 48.9856734, lng: 21.3190255 },
    { lat: 48.9852694, lng: 21.3193905 },
    { lat: 48.985307, lng: 21.319486 },
    { lat: 48.985287, lng: 21.3197138 },
    { lat: 48.9851247, lng: 21.3197839 },
    { lat: 48.9852193, lng: 21.3202291 },
    { lat: 48.984983, lng: 21.3203759 },
    { lat: 48.984839, lng: 21.3209793 },
    { lat: 48.9849811, lng: 21.3212922 },
    { lat: 48.9849571, lng: 21.3217209 },
    { lat: 48.9851577, lng: 21.3227061 },
    { lat: 48.9850168, lng: 21.3229585 },
    { lat: 48.9851041, lng: 21.3233529 },
    { lat: 48.9852933, lng: 21.3237987 },
    { lat: 48.9852201, lng: 21.3239833 },
    { lat: 48.9852105, lng: 21.3245267 },
    { lat: 48.9854326, lng: 21.3248767 },
    { lat: 48.9855165, lng: 21.3254556 },
    { lat: 48.9854665, lng: 21.3260029 },
    { lat: 48.9856954, lng: 21.3263517 },
    { lat: 48.985686, lng: 21.3266766 },
    { lat: 48.9857366, lng: 21.3269393 },
    { lat: 48.9856397, lng: 21.3273079 },
    { lat: 48.9857116, lng: 21.3275264 },
    { lat: 48.9856451, lng: 21.3276357 },
    { lat: 48.9856806, lng: 21.3278041 },
    { lat: 48.9856465, lng: 21.3282428 },
    { lat: 48.9856886, lng: 21.3284061 },
    { lat: 48.9857773, lng: 21.3284766 },
    { lat: 48.9857454, lng: 21.328538 },
    { lat: 48.9856308, lng: 21.3285445 },
    { lat: 48.9858696, lng: 21.329334 },
    { lat: 48.9858156, lng: 21.329787 },
    { lat: 48.9858, lng: 21.3305387 },
    { lat: 48.9857263, lng: 21.3305979 },
    { lat: 48.9858115, lng: 21.3307733 },
    { lat: 48.9859307, lng: 21.3308073 },
    { lat: 48.9860645, lng: 21.3311405 },
    { lat: 48.9863455, lng: 21.332064 },
    { lat: 48.986299, lng: 21.332424 },
    { lat: 48.9864451, lng: 21.3325285 },
    { lat: 48.9863984, lng: 21.3328883 },
    { lat: 48.9865332, lng: 21.3329892 },
    { lat: 48.9865235, lng: 21.3333794 },
    { lat: 48.9863972, lng: 21.3340803 },
    { lat: 48.9863964, lng: 21.3345798 },
    { lat: 48.9865036, lng: 21.3348507 },
    { lat: 48.9865676, lng: 21.3352941 },
    { lat: 48.9866506, lng: 21.3351773 },
    { lat: 48.9866912, lng: 21.3352168 },
    { lat: 48.9867722, lng: 21.3355906 },
    { lat: 48.9880597, lng: 21.3351147 },
    { lat: 48.9907961, lng: 21.3352964 },
    { lat: 48.9914582, lng: 21.3354593 },
    { lat: 48.9943035, lng: 21.336229 },
    { lat: 48.9956336, lng: 21.3346206 },
    { lat: 48.9956935, lng: 21.3333193 },
    { lat: 48.9957705, lng: 21.3332602 },
    { lat: 48.9958886, lng: 21.3329237 },
    { lat: 48.9959836, lng: 21.3328269 },
    { lat: 48.996167, lng: 21.3323017 },
    { lat: 48.9961905, lng: 21.3319199 },
    { lat: 48.9963177, lng: 21.3317361 },
    { lat: 48.996337, lng: 21.3315237 },
    { lat: 48.9962234, lng: 21.3312987 },
    { lat: 48.9962083, lng: 21.3307164 },
    { lat: 48.9963074, lng: 21.3305697 },
    { lat: 48.9963731, lng: 21.3302538 },
    { lat: 48.9965076, lng: 21.330047 },
    { lat: 48.9965378, lng: 21.3298922 },
    { lat: 48.9963484, lng: 21.3291278 },
    { lat: 48.9963064, lng: 21.3285247 },
    { lat: 48.9962275, lng: 21.3283704 },
    { lat: 48.9962262, lng: 21.3282117 },
    { lat: 48.9960811, lng: 21.327957 },
    { lat: 48.9960478, lng: 21.3277056 },
    { lat: 48.9959531, lng: 21.3276291 },
    { lat: 48.9958728, lng: 21.326942 },
    { lat: 48.9956658, lng: 21.3266399 },
    { lat: 48.9956652, lng: 21.3261032 },
    { lat: 48.9957614, lng: 21.32597 },
    { lat: 48.9957765, lng: 21.325649 },
    { lat: 48.9960048, lng: 21.3250483 },
    { lat: 48.9960771, lng: 21.3250232 },
    { lat: 48.9961061, lng: 21.3250133 },
    { lat: 48.9960817, lng: 21.3249458 },
    { lat: 48.9961467, lng: 21.3247166 },
    { lat: 48.996162, lng: 21.3244349 },
    { lat: 48.9961245, lng: 21.324014 },
    { lat: 48.9962369, lng: 21.3237535 },
    { lat: 48.996209, lng: 21.3235684 },
    { lat: 48.9963075, lng: 21.3232157 },
    { lat: 48.9962891, lng: 21.3229805 },
    { lat: 48.9963747, lng: 21.3227617 },
    { lat: 48.9962985, lng: 21.3222442 },
    { lat: 48.996376, lng: 21.3220141 },
    { lat: 48.9963071, lng: 21.3216778 },
    { lat: 48.9963503, lng: 21.3214698 },
    { lat: 48.9963142, lng: 21.3213515 },
    { lat: 48.9965764, lng: 21.3209441 },
    { lat: 48.9965668, lng: 21.3207745 },
    { lat: 48.996673, lng: 21.3203798 },
    { lat: 48.9965825, lng: 21.3199507 },
    { lat: 48.9966006, lng: 21.3198394 },
    { lat: 48.996504, lng: 21.319701 },
    { lat: 48.9965771, lng: 21.3192465 },
    { lat: 48.996719, lng: 21.3189578 },
    { lat: 48.9966719, lng: 21.3187006 },
    { lat: 48.9968046, lng: 21.318581 },
    { lat: 48.9967914, lng: 21.3180745 },
    { lat: 48.996703, lng: 21.317866 },
    { lat: 48.9967538, lng: 21.3176102 },
    { lat: 48.9968475, lng: 21.3175051 },
    { lat: 48.9969188, lng: 21.317226 },
    { lat: 48.9970799, lng: 21.3171201 },
    { lat: 48.9971207, lng: 21.3167514 },
    { lat: 48.9972261, lng: 21.3167057 },
    { lat: 48.9971767, lng: 21.316263 },
    { lat: 48.9973042, lng: 21.3151536 },
    { lat: 48.9972317, lng: 21.3151429 },
    { lat: 48.9971067, lng: 21.3148384 },
    { lat: 48.9971433, lng: 21.3147076 },
    { lat: 48.9973926, lng: 21.314468 },
    { lat: 48.9974099, lng: 21.3142987 },
    { lat: 48.9972386, lng: 21.3140272 },
    { lat: 48.9973895, lng: 21.313849 },
    { lat: 48.9975919, lng: 21.3138449 },
    { lat: 48.9976212, lng: 21.3137307 },
    { lat: 48.9974515, lng: 21.3135927 },
    { lat: 48.9973981, lng: 21.3134251 },
    { lat: 48.997401, lng: 21.3131447 },
    { lat: 48.9970222, lng: 21.3124541 },
    { lat: 48.9970105, lng: 21.312256 },
    { lat: 48.9971557, lng: 21.3117704 },
    { lat: 48.9970943, lng: 21.3115636 },
    { lat: 48.9971019, lng: 21.3113702 },
    { lat: 48.9970118, lng: 21.3111766 },
    { lat: 48.9969584, lng: 21.3111656 },
    { lat: 48.9969625, lng: 21.3109661 },
    { lat: 48.9967903, lng: 21.3107054 },
    { lat: 48.9967432, lng: 21.3100776 },
    { lat: 48.9965642, lng: 21.3098121 },
    { lat: 48.9965837, lng: 21.3087478 },
    { lat: 48.9963346, lng: 21.3081381 },
    { lat: 48.9961291, lng: 21.3079999 },
    { lat: 48.9961099, lng: 21.3078905 },
    { lat: 48.9961807, lng: 21.3077354 },
    { lat: 48.9962973, lng: 21.3077641 },
    { lat: 48.9963953, lng: 21.3076104 },
    { lat: 48.9964338, lng: 21.3073654 },
    { lat: 48.9965338, lng: 21.3073577 },
    { lat: 48.9965913, lng: 21.3069129 },
    { lat: 48.9966232, lng: 21.3063008 },
    { lat: 48.9964371, lng: 21.305839 },
    { lat: 48.9963616, lng: 21.3057604 },
    { lat: 48.9964535, lng: 21.3056006 },
    { lat: 48.9964786, lng: 21.304904 },
    { lat: 48.9962015, lng: 21.3045235 },
    { lat: 48.9962237, lng: 21.304437 },
    { lat: 48.9961317, lng: 21.3040406 },
    { lat: 48.9961839, lng: 21.3037546 },
    { lat: 48.9957071, lng: 21.3021385 },
    { lat: 48.9958343, lng: 21.3019153 },
    { lat: 48.9957147, lng: 21.3016793 },
    { lat: 48.9956627, lng: 21.3014127 },
    { lat: 48.9957106, lng: 21.3010629 },
    { lat: 48.9956255, lng: 21.300784 },
    { lat: 48.9954033, lng: 21.3003559 },
    { lat: 48.9954095, lng: 21.2999294 },
    { lat: 48.9953685, lng: 21.2997269 },
    { lat: 48.9952527, lng: 21.299658 },
    { lat: 48.9951782, lng: 21.2989813 },
    { lat: 48.9952374, lng: 21.2987123 },
    { lat: 48.9951131, lng: 21.2985064 },
    { lat: 48.9949776, lng: 21.2972674 },
    { lat: 48.9949133, lng: 21.2971856 },
    { lat: 48.9948002, lng: 21.2967241 },
    { lat: 48.9946531, lng: 21.296513 },
    { lat: 48.9945239, lng: 21.2960734 },
    { lat: 48.9943379, lng: 21.2958033 },
    { lat: 48.9941678, lng: 21.2954254 },
    { lat: 48.9940579, lng: 21.2952722 },
    { lat: 48.9937731, lng: 21.2951009 },
    { lat: 48.9936641, lng: 21.2948505 },
    { lat: 48.9934146, lng: 21.2946007 },
    { lat: 48.9932908, lng: 21.2942694 },
    { lat: 48.993278, lng: 21.293956 },
    { lat: 48.9932154, lng: 21.2938583 },
    { lat: 48.9931116, lng: 21.2938889 },
    { lat: 48.9929184, lng: 21.293676 },
    { lat: 48.9929435, lng: 21.2934312 },
    { lat: 48.9928391, lng: 21.2932565 },
    { lat: 48.9927076, lng: 21.2933658 },
    { lat: 48.9925526, lng: 21.2931925 },
    { lat: 48.9924639, lng: 21.2929747 },
    { lat: 48.9922746, lng: 21.2927136 },
    { lat: 48.9922483, lng: 21.2925857 },
    { lat: 48.9923034, lng: 21.2924736 },
    { lat: 48.992321, lng: 21.2920918 },
    { lat: 48.992283, lng: 21.2919888 },
    { lat: 48.9921739, lng: 21.2919155 },
    { lat: 48.9921359, lng: 21.2917745 },
    { lat: 48.9921686, lng: 21.2916303 },
    { lat: 48.9921395, lng: 21.2914514 },
    { lat: 48.992001, lng: 21.2914077 },
    { lat: 48.9920378, lng: 21.2911081 },
    { lat: 48.9921863, lng: 21.2909869 },
    { lat: 48.9920911, lng: 21.2906346 },
    { lat: 48.9919474, lng: 21.2906844 },
    { lat: 48.9919075, lng: 21.2906299 },
    { lat: 48.9917474, lng: 21.2906232 },
    { lat: 48.9916751, lng: 21.2913536 },
    { lat: 48.9916661, lng: 21.2920485 },
    { lat: 48.9914893, lng: 21.292295 },
    { lat: 48.9911687, lng: 21.2919334 },
    { lat: 48.9911229, lng: 21.2919524 },
    { lat: 48.9902623, lng: 21.2924812 },
    { lat: 48.9893555, lng: 21.2928146 },
    { lat: 48.9896594, lng: 21.2932243 },
    { lat: 48.9900871, lng: 21.2931925 },
    { lat: 48.990174, lng: 21.2939822 },
    { lat: 48.9897392, lng: 21.2941425 },
    { lat: 48.9896962, lng: 21.294397 },
    { lat: 48.9897989, lng: 21.2944073 },
    { lat: 48.9899071, lng: 21.2943001 },
    { lat: 48.9899785, lng: 21.2943912 },
    { lat: 48.9899183, lng: 21.2945204 },
    { lat: 48.9898802, lng: 21.2954945 },
    { lat: 48.9897315, lng: 21.2958546 },
    { lat: 48.9897234, lng: 21.2959985 },
    { lat: 48.9895756, lng: 21.2960605 },
    { lat: 48.9893838, lng: 21.2968572 },
    { lat: 48.9891376, lng: 21.2971983 },
    { lat: 48.9889769, lng: 21.2975353 },
    { lat: 48.9888698, lng: 21.2974192 },
    { lat: 48.9887728, lng: 21.2974443 },
    { lat: 48.9886898, lng: 21.2971291 },
    { lat: 48.9881545, lng: 21.2976159 },
    { lat: 48.9876902, lng: 21.2980419 },
  ],
  Kendice: [
    { lat: 48.9192129, lng: 21.219778 },
    { lat: 48.9192124, lng: 21.2198002 },
    { lat: 48.9191225, lng: 21.2203806 },
    { lat: 48.9195831, lng: 21.2230703 },
    { lat: 48.9195054, lng: 21.2232182 },
    { lat: 48.919415, lng: 21.2232361 },
    { lat: 48.919271, lng: 21.2234236 },
    { lat: 48.9191143, lng: 21.2239189 },
    { lat: 48.9191028, lng: 21.2244525 },
    { lat: 48.9189147, lng: 21.224851 },
    { lat: 48.9188171, lng: 21.2252605 },
    { lat: 48.9186525, lng: 21.225571 },
    { lat: 48.9185935, lng: 21.2259191 },
    { lat: 48.918526, lng: 21.2260631 },
    { lat: 48.9182154, lng: 21.2263215 },
    { lat: 48.9179281, lng: 21.2268511 },
    { lat: 48.9178378, lng: 21.2271696 },
    { lat: 48.9175158, lng: 21.2275984 },
    { lat: 48.9174241, lng: 21.2277963 },
    { lat: 48.9171771, lng: 21.2280387 },
    { lat: 48.9169537, lng: 21.2284536 },
    { lat: 48.9163044, lng: 21.2290717 },
    { lat: 48.9162978, lng: 21.2291491 },
    { lat: 48.9155914, lng: 21.2296317 },
    { lat: 48.9148177, lng: 21.230049 },
    { lat: 48.914609, lng: 21.2302468 },
    { lat: 48.9144249, lng: 21.2307361 },
    { lat: 48.9143361, lng: 21.2308473 },
    { lat: 48.9143583, lng: 21.2309838 },
    { lat: 48.9143023, lng: 21.2314583 },
    { lat: 48.9141978, lng: 21.2317258 },
    { lat: 48.9140694, lng: 21.2317207 },
    { lat: 48.9140576, lng: 21.2318407 },
    { lat: 48.9142329, lng: 21.232089 },
    { lat: 48.9142183, lng: 21.232167 },
    { lat: 48.9141521, lng: 21.2322053 },
    { lat: 48.9140899, lng: 21.2324137 },
    { lat: 48.9138971, lng: 21.2325162 },
    { lat: 48.9136053, lng: 21.2330822 },
    { lat: 48.9136583, lng: 21.2333396 },
    { lat: 48.913626, lng: 21.2334217 },
    { lat: 48.9134534, lng: 21.2334837 },
    { lat: 48.9134641, lng: 21.2337245 },
    { lat: 48.9132795, lng: 21.2337935 },
    { lat: 48.913124, lng: 21.234256 },
    { lat: 48.9129318, lng: 21.2343749 },
    { lat: 48.9129295, lng: 21.2344771 },
    { lat: 48.9128307, lng: 21.2346362 },
    { lat: 48.9125771, lng: 21.2348764 },
    { lat: 48.9125934, lng: 21.2352219 },
    { lat: 48.9125052, lng: 21.2353495 },
    { lat: 48.9125058, lng: 21.2355509 },
    { lat: 48.9123788, lng: 21.2357012 },
    { lat: 48.9122994, lng: 21.2359015 },
    { lat: 48.9122034, lng: 21.2358926 },
    { lat: 48.9122163, lng: 21.2361947 },
    { lat: 48.912088, lng: 21.2362336 },
    { lat: 48.9120558, lng: 21.2364645 },
    { lat: 48.9119696, lng: 21.2366287 },
    { lat: 48.9120163, lng: 21.2368261 },
    { lat: 48.911857, lng: 21.2373169 },
    { lat: 48.9119678, lng: 21.2374768 },
    { lat: 48.9119641, lng: 21.2376831 },
    { lat: 48.9120763, lng: 21.2383992 },
    { lat: 48.911948, lng: 21.2384374 },
    { lat: 48.9119781, lng: 21.2392652 },
    { lat: 48.9119473, lng: 21.2393962 },
    { lat: 48.9121242, lng: 21.2402076 },
    { lat: 48.9121821, lng: 21.2410181 },
    { lat: 48.912228, lng: 21.2411937 },
    { lat: 48.912617, lng: 21.2418007 },
    { lat: 48.9131495, lng: 21.242137 },
    { lat: 48.9134361, lng: 21.2429249 },
    { lat: 48.9139668, lng: 21.2440066 },
    { lat: 48.9143419, lng: 21.2453324 },
    { lat: 48.9143377, lng: 21.2457373 },
    { lat: 48.9146131, lng: 21.2458379 },
    { lat: 48.9150719, lng: 21.2458268 },
    { lat: 48.9149556, lng: 21.2461753 },
    { lat: 48.9151583, lng: 21.2461116 },
    { lat: 48.9157404, lng: 21.2462688 },
    { lat: 48.9159638, lng: 21.2465774 },
    { lat: 48.9161007, lng: 21.247621 },
    { lat: 48.9160805, lng: 21.2480969 },
    { lat: 48.9172567, lng: 21.2478527 },
    { lat: 48.9173838, lng: 21.2477381 },
    { lat: 48.9183974, lng: 21.2461258 },
    { lat: 48.9186978, lng: 21.245942 },
    { lat: 48.9188523, lng: 21.2458763 },
    { lat: 48.9193419, lng: 21.2459545 },
    { lat: 48.9196162, lng: 21.2461619 },
    { lat: 48.9197233, lng: 21.2467504 },
    { lat: 48.9197554, lng: 21.2475393 },
    { lat: 48.9196939, lng: 21.2481387 },
    { lat: 48.9198435, lng: 21.2488584 },
    { lat: 48.9199647, lng: 21.2491425 },
    { lat: 48.9202445, lng: 21.2493838 },
    { lat: 48.9203863, lng: 21.2494224 },
    { lat: 48.9216697, lng: 21.2495499 },
    { lat: 48.9223978, lng: 21.2498772 },
    { lat: 48.9226883, lng: 21.2501368 },
    { lat: 48.9230157, lng: 21.2505376 },
    { lat: 48.9231357, lng: 21.2508575 },
    { lat: 48.9230582, lng: 21.25206 },
    { lat: 48.9230546, lng: 21.2529327 },
    { lat: 48.9231082, lng: 21.2529185 },
    { lat: 48.9232528, lng: 21.2539621 },
    { lat: 48.9233681, lng: 21.2542685 },
    { lat: 48.9241082, lng: 21.2552061 },
    { lat: 48.9249475, lng: 21.2567019 },
    { lat: 48.9260227, lng: 21.257228 },
    { lat: 48.9271723, lng: 21.2576898 },
    { lat: 48.9272718, lng: 21.2574891 },
    { lat: 48.9274201, lng: 21.2569282 },
    { lat: 48.9275008, lng: 21.2563707 },
    { lat: 48.9276371, lng: 21.2561191 },
    { lat: 48.9277288, lng: 21.2557715 },
    { lat: 48.9277483, lng: 21.2558911 },
    { lat: 48.9283295, lng: 21.255141 },
    { lat: 48.9289479, lng: 21.2548681 },
    { lat: 48.9294535, lng: 21.2545534 },
    { lat: 48.9295477, lng: 21.2544347 },
    { lat: 48.9296624, lng: 21.253995 },
    { lat: 48.930255, lng: 21.2535743 },
    { lat: 48.9303616, lng: 21.2536029 },
    { lat: 48.9302454, lng: 21.2525636 },
    { lat: 48.9299429, lng: 21.2526516 },
    { lat: 48.9299253, lng: 21.2520795 },
    { lat: 48.9305728, lng: 21.2518325 },
    { lat: 48.9308709, lng: 21.2515429 },
    { lat: 48.9312321, lng: 21.2513186 },
    { lat: 48.9311895, lng: 21.2508163 },
    { lat: 48.9323545, lng: 21.2504917 },
    { lat: 48.932794, lng: 21.2504809 },
    { lat: 48.9327831, lng: 21.2509305 },
    { lat: 48.9328536, lng: 21.2519025 },
    { lat: 48.9327658, lng: 21.2521554 },
    { lat: 48.9327253, lng: 21.25263 },
    { lat: 48.9323107, lng: 21.2527589 },
    { lat: 48.932462, lng: 21.254241 },
    { lat: 48.9328733, lng: 21.2534938 },
    { lat: 48.9333805, lng: 21.2527102 },
    { lat: 48.9338506, lng: 21.2520118 },
    { lat: 48.9343775, lng: 21.2513631 },
    { lat: 48.9345691, lng: 21.2518102 },
    { lat: 48.9375292, lng: 21.249815 },
    { lat: 48.9379128, lng: 21.2507231 },
    { lat: 48.9384237, lng: 21.2512005 },
    { lat: 48.9387876, lng: 21.2511041 },
    { lat: 48.9388388, lng: 21.2510905 },
    { lat: 48.9397587, lng: 21.2505537 },
    { lat: 48.9404908, lng: 21.2504273 },
    { lat: 48.941583, lng: 21.2499069 },
    { lat: 48.9417, lng: 21.2497923 },
    { lat: 48.9420852, lng: 21.2527286 },
    { lat: 48.9486285, lng: 21.2494613 },
    { lat: 48.9486929, lng: 21.2492877 },
    { lat: 48.9488022, lng: 21.2485643 },
    { lat: 48.9487388, lng: 21.2481517 },
    { lat: 48.9487375, lng: 21.2480716 },
    { lat: 48.9487794, lng: 21.2480769 },
    { lat: 48.9487439, lng: 21.2477278 },
    { lat: 48.9485971, lng: 21.2476025 },
    { lat: 48.9483597, lng: 21.2476062 },
    { lat: 48.9482047, lng: 21.2479079 },
    { lat: 48.9482297, lng: 21.2481378 },
    { lat: 48.9480321, lng: 21.2484056 },
    { lat: 48.9479137, lng: 21.2483961 },
    { lat: 48.9477552, lng: 21.2482599 },
    { lat: 48.9471609, lng: 21.2483236 },
    { lat: 48.9469534, lng: 21.2481268 },
    { lat: 48.9467256, lng: 21.2476378 },
    { lat: 48.9466363, lng: 21.247333 },
    { lat: 48.9466508, lng: 21.2471469 },
    { lat: 48.9467709, lng: 21.2469583 },
    { lat: 48.9468554, lng: 21.2465921 },
    { lat: 48.9472503, lng: 21.2459716 },
    { lat: 48.9474086, lng: 21.2457738 },
    { lat: 48.9475469, lng: 21.2457013 },
    { lat: 48.9478709, lng: 21.2450764 },
    { lat: 48.9479611, lng: 21.2446812 },
    { lat: 48.9480438, lng: 21.2445872 },
    { lat: 48.9484329, lng: 21.2444747 },
    { lat: 48.9490393, lng: 21.2440642 },
    { lat: 48.9491534, lng: 21.243499 },
    { lat: 48.9492967, lng: 21.2433968 },
    { lat: 48.9496524, lng: 21.2434764 },
    { lat: 48.9497609, lng: 21.2439964 },
    { lat: 48.9497087, lng: 21.2443528 },
    { lat: 48.9497851, lng: 21.2446374 },
    { lat: 48.9498287, lng: 21.2447435 },
    { lat: 48.9500458, lng: 21.2448866 },
    { lat: 48.9503709, lng: 21.2450579 },
    { lat: 48.9505906, lng: 21.2450706 },
    { lat: 48.9515712, lng: 21.2448698 },
    { lat: 48.9518415, lng: 21.2445122 },
    { lat: 48.9516656, lng: 21.2441663 },
    { lat: 48.952016, lng: 21.2436312 },
    { lat: 48.9522284, lng: 21.2431889 },
    { lat: 48.9524985, lng: 21.2435589 },
    { lat: 48.9526337, lng: 21.2433774 },
    { lat: 48.9527274, lng: 21.2427685 },
    { lat: 48.9528968, lng: 21.2424954 },
    { lat: 48.9525944, lng: 21.2420658 },
    { lat: 48.9526119, lng: 21.2418691 },
    { lat: 48.9511937, lng: 21.2416392 },
    { lat: 48.9506864, lng: 21.2414676 },
    { lat: 48.9502081, lng: 21.2411737 },
    { lat: 48.9495285, lng: 21.2405966 },
    { lat: 48.9485689, lng: 21.2390746 },
    { lat: 48.9486132, lng: 21.2390099 },
    { lat: 48.9486401, lng: 21.2390513 },
    { lat: 48.9487229, lng: 21.2388521 },
    { lat: 48.9484353, lng: 21.2382446 },
    { lat: 48.9486802, lng: 21.2376862 },
    { lat: 48.948356, lng: 21.2351029 },
    { lat: 48.9482668, lng: 21.2346878 },
    { lat: 48.9483595, lng: 21.2345591 },
    { lat: 48.9485779, lng: 21.2338357 },
    { lat: 48.9487974, lng: 21.2329963 },
    { lat: 48.9489121, lng: 21.2323085 },
    { lat: 48.9493704, lng: 21.2318781 },
    { lat: 48.9494618, lng: 21.2326123 },
    { lat: 48.9492638, lng: 21.2354191 },
    { lat: 48.9502595, lng: 21.2358815 },
    { lat: 48.9501824, lng: 21.2360095 },
    { lat: 48.9510705, lng: 21.2367066 },
    { lat: 48.9516612, lng: 21.2366667 },
    { lat: 48.9525117, lng: 21.2360768 },
    { lat: 48.9529176, lng: 21.2359587 },
    { lat: 48.9539844, lng: 21.23541 },
    { lat: 48.9541689, lng: 21.2354824 },
    { lat: 48.9545115, lng: 21.2352432 },
    { lat: 48.9547972, lng: 21.2353166 },
    { lat: 48.9551841, lng: 21.2353083 },
    { lat: 48.9554759, lng: 21.2354616 },
    { lat: 48.9556268, lng: 21.235357 },
    { lat: 48.9554592, lng: 21.2334661 },
    { lat: 48.9557727, lng: 21.2323985 },
    { lat: 48.9556091, lng: 21.2315848 },
    { lat: 48.9556405, lng: 21.2314021 },
    { lat: 48.955974, lng: 21.2307957 },
    { lat: 48.9561114, lng: 21.2301442 },
    { lat: 48.9560508, lng: 21.2284101 },
    { lat: 48.9559366, lng: 21.2277777 },
    { lat: 48.9560227, lng: 21.2272458 },
    { lat: 48.9556827, lng: 21.2271337 },
    { lat: 48.9550426, lng: 21.2275787 },
    { lat: 48.9541743, lng: 21.2277758 },
    { lat: 48.9536985, lng: 21.2275946 },
    { lat: 48.953561, lng: 21.227449 },
    { lat: 48.9531492, lng: 21.226033 },
    { lat: 48.9527764, lng: 21.2251509 },
    { lat: 48.9526409, lng: 21.2250242 },
    { lat: 48.9521816, lng: 21.2240767 },
    { lat: 48.9511591, lng: 21.2225339 },
    { lat: 48.9509246, lng: 21.2228504 },
    { lat: 48.9508471, lng: 21.2235051 },
    { lat: 48.9501341, lng: 21.222757 },
    { lat: 48.9483406, lng: 21.2223658 },
    { lat: 48.9481111, lng: 21.221716 },
    { lat: 48.9478687, lng: 21.2215189 },
    { lat: 48.9473113, lng: 21.2213633 },
    { lat: 48.947006, lng: 21.2210611 },
    { lat: 48.9466271, lng: 21.2208581 },
    { lat: 48.9466084, lng: 21.2208347 },
    { lat: 48.9456638, lng: 21.2212521 },
    { lat: 48.9451056, lng: 21.2213264 },
    { lat: 48.9448341, lng: 21.2213183 },
    { lat: 48.9442501, lng: 21.2211588 },
    { lat: 48.9440227, lng: 21.2207345 },
    { lat: 48.9435051, lng: 21.220158 },
    { lat: 48.9424209, lng: 21.2197749 },
    { lat: 48.9415341, lng: 21.2191042 },
    { lat: 48.9411848, lng: 21.2190766 },
    { lat: 48.9406253, lng: 21.2189638 },
    { lat: 48.9398298, lng: 21.218932 },
    { lat: 48.9398538, lng: 21.2183597 },
    { lat: 48.9397461, lng: 21.2178995 },
    { lat: 48.9395276, lng: 21.217685 },
    { lat: 48.9388105, lng: 21.2179644 },
    { lat: 48.9376917, lng: 21.2182432 },
    { lat: 48.9366251, lng: 21.2188326 },
    { lat: 48.9362074, lng: 21.218929 },
    { lat: 48.9358209, lng: 21.2191353 },
    { lat: 48.9353054, lng: 21.2190946 },
    { lat: 48.9350298, lng: 21.2192435 },
    { lat: 48.9347421, lng: 21.2192867 },
    { lat: 48.9345149, lng: 21.2194422 },
    { lat: 48.9343329, lng: 21.2196643 },
    { lat: 48.9339179, lng: 21.2196732 },
    { lat: 48.9330682, lng: 21.2199302 },
    { lat: 48.9327677, lng: 21.2199578 },
    { lat: 48.9323432, lng: 21.219879 },
    { lat: 48.9316596, lng: 21.2199709 },
    { lat: 48.9310388, lng: 21.2199141 },
    { lat: 48.9307934, lng: 21.2200909 },
    { lat: 48.9304295, lng: 21.2200436 },
    { lat: 48.9299243, lng: 21.219428 },
    { lat: 48.9295758, lng: 21.2193288 },
    { lat: 48.9294298, lng: 21.2191918 },
    { lat: 48.9290888, lng: 21.2190494 },
    { lat: 48.928303, lng: 21.2184236 },
    { lat: 48.9277401, lng: 21.2184907 },
    { lat: 48.9275578, lng: 21.2184154 },
    { lat: 48.926818, lng: 21.2184082 },
    { lat: 48.9268055, lng: 21.2183615 },
    { lat: 48.9239767, lng: 21.2193875 },
    { lat: 48.9235398, lng: 21.2194485 },
    { lat: 48.9226616, lng: 21.2197963 },
    { lat: 48.9214694, lng: 21.2198605 },
    { lat: 48.9199061, lng: 21.2197452 },
    { lat: 48.9192129, lng: 21.219778 },
  ],
  Prešov: [
    { lat: 49.040383, lng: 21.2816702 },
    { lat: 49.0402271, lng: 21.28139 },
    { lat: 49.0401186, lng: 21.2810106 },
    { lat: 49.0398809, lng: 21.2805558 },
    { lat: 49.0397453, lng: 21.2800603 },
    { lat: 49.0393907, lng: 21.279542 },
    { lat: 49.0394236, lng: 21.2793618 },
    { lat: 49.0396818, lng: 21.2794135 },
    { lat: 49.039934, lng: 21.279645 },
    { lat: 49.040107, lng: 21.2795962 },
    { lat: 49.0402605, lng: 21.2794623 },
    { lat: 49.0402732, lng: 21.2792534 },
    { lat: 49.0403597, lng: 21.2788807 },
    { lat: 49.0404132, lng: 21.2786417 },
    { lat: 49.0404332, lng: 21.2780427 },
    { lat: 49.0405561, lng: 21.2775362 },
    { lat: 49.0405455, lng: 21.2770011 },
    { lat: 49.0406168, lng: 21.2765721 },
    { lat: 49.0408607, lng: 21.2762658 },
    { lat: 49.0414304, lng: 21.2760581 },
    { lat: 49.041607, lng: 21.2759125 },
    { lat: 49.0418585, lng: 21.2758542 },
    { lat: 49.0428251, lng: 21.2749309 },
    { lat: 49.0434753, lng: 21.2745539 },
    { lat: 49.0439418, lng: 21.2735843 },
    { lat: 49.044486, lng: 21.2717653 },
    { lat: 49.0446895, lng: 21.2706547 },
    { lat: 49.0447053, lng: 21.2701435 },
    { lat: 49.0442418, lng: 21.2669481 },
    { lat: 49.0440533, lng: 21.266706 },
    { lat: 49.0438799, lng: 21.2658556 },
    { lat: 49.0439914, lng: 21.2656375 },
    { lat: 49.0439886, lng: 21.2652173 },
    { lat: 49.0441257, lng: 21.2646951 },
    { lat: 49.0441576, lng: 21.2641985 },
    { lat: 49.0442279, lng: 21.2638431 },
    { lat: 49.044342, lng: 21.263642 },
    { lat: 49.0442808, lng: 21.2632532 },
    { lat: 49.0443187, lng: 21.262913 },
    { lat: 49.0444529, lng: 21.2620451 },
    { lat: 49.0446858, lng: 21.2610127 },
    { lat: 49.0448613, lng: 21.2603123 },
    { lat: 49.0454368, lng: 21.2586795 },
    { lat: 49.045529, lng: 21.2582357 },
    { lat: 49.0458979, lng: 21.257646 },
    { lat: 49.0457558, lng: 21.2572332 },
    { lat: 49.0461331, lng: 21.2561815 },
    { lat: 49.0462099, lng: 21.255421 },
    { lat: 49.0460068, lng: 21.2546333 },
    { lat: 49.0460019, lng: 21.2544741 },
    { lat: 49.0460394, lng: 21.2542303 },
    { lat: 49.0461506, lng: 21.2541123 },
    { lat: 49.0463961, lng: 21.2536052 },
    { lat: 49.0464378, lng: 21.2532733 },
    { lat: 49.046401, lng: 21.2521539 },
    { lat: 49.0464938, lng: 21.2516587 },
    { lat: 49.0466723, lng: 21.2513504 },
    { lat: 49.0468381, lng: 21.2504856 },
    { lat: 49.0461499, lng: 21.2490025 },
    { lat: 49.0451668, lng: 21.2474744 },
    { lat: 49.0452514, lng: 21.247374 },
    { lat: 49.0452583, lng: 21.2468613 },
    { lat: 49.0451435, lng: 21.2461639 },
    { lat: 49.0450648, lng: 21.2449277 },
    { lat: 49.0447684, lng: 21.2437471 },
    { lat: 49.0445332, lng: 21.2416246 },
    { lat: 49.0444739, lng: 21.2398435 },
    { lat: 49.0436907, lng: 21.2384273 },
    { lat: 49.0433028, lng: 21.2381838 },
    { lat: 49.0429358, lng: 21.2377365 },
    { lat: 49.0422821, lng: 21.2372312 },
    { lat: 49.0417595, lng: 21.2367377 },
    { lat: 49.0412416, lng: 21.2363991 },
    { lat: 49.0410735, lng: 21.2360582 },
    { lat: 49.0405582, lng: 21.2355899 },
    { lat: 49.0401702, lng: 21.2356302 },
    { lat: 49.0398901, lng: 21.2354192 },
    { lat: 49.0394731, lng: 21.2352858 },
    { lat: 49.0387336, lng: 21.2352476 },
    { lat: 49.0385941, lng: 21.2350983 },
    { lat: 49.0384589, lng: 21.2350943 },
    { lat: 49.0378615, lng: 21.2353497 },
    { lat: 49.0376767, lng: 21.2353453 },
    { lat: 49.0375148, lng: 21.2354637 },
    { lat: 49.0370104, lng: 21.235631 },
    { lat: 49.0367808, lng: 21.2355947 },
    { lat: 49.0364483, lng: 21.2354338 },
    { lat: 49.0366119, lng: 21.2345391 },
    { lat: 49.0364211, lng: 21.2336353 },
    { lat: 49.0356615, lng: 21.2337871 },
    { lat: 49.0351433, lng: 21.2336204 },
    { lat: 49.0343037, lng: 21.2336163 },
    { lat: 49.0341894, lng: 21.2320999 },
    { lat: 49.0345557, lng: 21.2316842 },
    { lat: 49.0343065, lng: 21.2306491 },
    { lat: 49.0336311, lng: 21.2312949 },
    { lat: 49.0336213, lng: 21.2314885 },
    { lat: 49.0329929, lng: 21.2320915 },
    { lat: 49.0323958, lng: 21.2326176 },
    { lat: 49.031821, lng: 21.2329864 },
    { lat: 49.0315638, lng: 21.2324224 },
    { lat: 49.031273, lng: 21.2319709 },
    { lat: 49.0311367, lng: 21.231858 },
    { lat: 49.0308416, lng: 21.231953 },
    { lat: 49.0295672, lng: 21.2329546 },
    { lat: 49.02934, lng: 21.2330676 },
    { lat: 49.0292706, lng: 21.2334843 },
    { lat: 49.0289024, lng: 21.2329626 },
    { lat: 49.0285633, lng: 21.2326624 },
    { lat: 49.0277968, lng: 21.2332382 },
    { lat: 49.0274633, lng: 21.2334051 },
    { lat: 49.0260534, lng: 21.2323125 },
    { lat: 49.0251581, lng: 21.2318573 },
    { lat: 49.0252494, lng: 21.2313556 },
    { lat: 49.0269807, lng: 21.2290078 },
    { lat: 49.0269277, lng: 21.2286721 },
    { lat: 49.0268285, lng: 21.2285612 },
    { lat: 49.0266406, lng: 21.2278679 },
    { lat: 49.0264157, lng: 21.2262044 },
    { lat: 49.0265268, lng: 21.2256109 },
    { lat: 49.0268232, lng: 21.2246129 },
    { lat: 49.0269731, lng: 21.2236347 },
    { lat: 49.0263829, lng: 21.2234652 },
    { lat: 49.0258269, lng: 21.2234704 },
    { lat: 49.0241005, lng: 21.2245572 },
    { lat: 49.0235036, lng: 21.2248671 },
    { lat: 49.0233498, lng: 21.2248426 },
    { lat: 49.0232877, lng: 21.2247248 },
    { lat: 49.0234052, lng: 21.2241574 },
    { lat: 49.0231543, lng: 21.2233636 },
    { lat: 49.0231713, lng: 21.2229314 },
    { lat: 49.02295, lng: 21.2206174 },
    { lat: 49.0230082, lng: 21.219952 },
    { lat: 49.0227587, lng: 21.2182263 },
    { lat: 49.0227501, lng: 21.2172957 },
    { lat: 49.022564, lng: 21.2164435 },
    { lat: 49.0225154, lng: 21.2158973 },
    { lat: 49.0222201, lng: 21.2151584 },
    { lat: 49.021815, lng: 21.2144303 },
    { lat: 49.0203655, lng: 21.212912 },
    { lat: 49.0200972, lng: 21.2127846 },
    { lat: 49.0194082, lng: 21.2125201 },
    { lat: 49.0187423, lng: 21.2125303 },
    { lat: 49.0180186, lng: 21.2129619 },
    { lat: 49.0177152, lng: 21.2130106 },
    { lat: 49.0175357, lng: 21.2129536 },
    { lat: 49.0171207, lng: 21.2124259 },
    { lat: 49.0165016, lng: 21.2120872 },
    { lat: 49.0146797, lng: 21.2114489 },
    { lat: 49.0145834, lng: 21.2114152 },
    { lat: 49.0140439, lng: 21.2111784 },
    { lat: 49.0133854, lng: 21.2108894 },
    { lat: 49.0132994, lng: 21.2107342 },
    { lat: 49.012882, lng: 21.2087761 },
    { lat: 49.0123116, lng: 21.2077693 },
    { lat: 49.0103292, lng: 21.2051818 },
    { lat: 49.0092074, lng: 21.2035226 },
    { lat: 49.0084435, lng: 21.2023391 },
    { lat: 49.0081706, lng: 21.2022541 },
    { lat: 49.0079251, lng: 21.1997601 },
    { lat: 49.0077031, lng: 21.1996722 },
    { lat: 49.0074661, lng: 21.1994692 },
    { lat: 49.0069848, lng: 21.1996641 },
    { lat: 49.0066765, lng: 21.1998803 },
    { lat: 49.0059387, lng: 21.1976237 },
    { lat: 49.0049105, lng: 21.1984624 },
    { lat: 49.0046502, lng: 21.1978055 },
    { lat: 49.0037559, lng: 21.198822 },
    { lat: 49.003594, lng: 21.1989 },
    { lat: 49.003056, lng: 21.1976359 },
    { lat: 49.0028803, lng: 21.1975203 },
    { lat: 49.0028378, lng: 21.1975653 },
    { lat: 49.0024811, lng: 21.1970996 },
    { lat: 49.00215, lng: 21.1975984 },
    { lat: 49.0009233, lng: 21.1953927 },
    { lat: 49.0005493, lng: 21.1950212 },
    { lat: 49.0006307, lng: 21.1948204 },
    { lat: 48.9980529, lng: 21.1924547 },
    { lat: 48.9978636, lng: 21.1925917 },
    { lat: 48.9959089, lng: 21.1895695 },
    { lat: 48.9961598, lng: 21.1876997 },
    { lat: 48.9957996, lng: 21.1874129 },
    { lat: 48.9958416, lng: 21.1843648 },
    { lat: 48.9962269, lng: 21.1847777 },
    { lat: 48.996434, lng: 21.1849222 },
    { lat: 48.9965959, lng: 21.1849198 },
    { lat: 48.9966958, lng: 21.1848319 },
    { lat: 48.9968662, lng: 21.1843874 },
    { lat: 48.9974007, lng: 21.1840612 },
    { lat: 48.9975006, lng: 21.1813937 },
    { lat: 48.9972519, lng: 21.1798578 },
    { lat: 48.9968215, lng: 21.1798406 },
    { lat: 48.9958373, lng: 21.1801754 },
    { lat: 48.9956781, lng: 21.1792265 },
    { lat: 48.9953404, lng: 21.1786467 },
    { lat: 48.995976, lng: 21.1781011 },
    { lat: 48.9968126, lng: 21.1776856 },
    { lat: 48.9978175, lng: 21.1777274 },
    { lat: 48.9982575, lng: 21.177545 },
    { lat: 48.9995617, lng: 21.1761096 },
    { lat: 49.0003023, lng: 21.1750398 },
    { lat: 49.0006477, lng: 21.1748215 },
    { lat: 49.0007811, lng: 21.1748036 },
    { lat: 49.0010663, lng: 21.174317 },
    { lat: 49.0009755, lng: 21.1739741 },
    { lat: 49.0013467, lng: 21.1731407 },
    { lat: 49.0019182, lng: 21.1726716 },
    { lat: 49.0023492, lng: 21.1707414 },
    { lat: 49.0019247, lng: 21.16973 },
    { lat: 49.0019013, lng: 21.1697063 },
    { lat: 49.0009938, lng: 21.1705109 },
    { lat: 49.0002093, lng: 21.1703633 },
    { lat: 48.9998251, lng: 21.1704514 },
    { lat: 48.9996254, lng: 21.1703702 },
    { lat: 48.9993871, lng: 21.1701653 },
    { lat: 48.9983285, lng: 21.1690635 },
    { lat: 48.9981798, lng: 21.1690976 },
    { lat: 48.9974047, lng: 21.1689242 },
    { lat: 48.9969697, lng: 21.1695117 },
    { lat: 48.996531, lng: 21.170303 },
    { lat: 48.9957045, lng: 21.1705752 },
    { lat: 48.9945985, lng: 21.1714444 },
    { lat: 48.9945532, lng: 21.1709696 },
    { lat: 48.9945061, lng: 21.1708315 },
    { lat: 48.9943571, lng: 21.1706739 },
    { lat: 48.9940285, lng: 21.1705279 },
    { lat: 48.9930995, lng: 21.1705857 },
    { lat: 48.9930265, lng: 21.1705498 },
    { lat: 48.9928434, lng: 21.1700302 },
    { lat: 48.9927026, lng: 21.1693112 },
    { lat: 48.9925683, lng: 21.1688637 },
    { lat: 48.9925124, lng: 21.16816 },
    { lat: 48.9923682, lng: 21.1677681 },
    { lat: 48.9924882, lng: 21.166499 },
    { lat: 48.9926422, lng: 21.1659621 },
    { lat: 48.9926493, lng: 21.1653653 },
    { lat: 48.992356, lng: 21.1640934 },
    { lat: 48.9922716, lng: 21.1635573 },
    { lat: 48.9922007, lng: 21.1626301 },
    { lat: 48.9921461, lng: 21.162576 },
    { lat: 48.9920133, lng: 21.1625749 },
    { lat: 48.9915476, lng: 21.1629034 },
    { lat: 48.9912319, lng: 21.1634152 },
    { lat: 48.9902896, lng: 21.1645817 },
    { lat: 48.9893144, lng: 21.1656718 },
    { lat: 48.9887637, lng: 21.1660884 },
    { lat: 48.988417, lng: 21.1662598 },
    { lat: 48.9882408, lng: 21.1660265 },
    { lat: 48.9879422, lng: 21.16616 },
    { lat: 48.9876301, lng: 21.1661773 },
    { lat: 48.9872881, lng: 21.1663273 },
    { lat: 48.9869474, lng: 21.1663714 },
    { lat: 48.9859559, lng: 21.1660469 },
    { lat: 48.9857015, lng: 21.1654416 },
    { lat: 48.9855496, lng: 21.1653355 },
    { lat: 48.9853034, lng: 21.1647679 },
    { lat: 48.9851984, lng: 21.1643689 },
    { lat: 48.9851209, lng: 21.1637296 },
    { lat: 48.9850378, lng: 21.1635902 },
    { lat: 48.984931, lng: 21.1635245 },
    { lat: 48.9846058, lng: 21.1637782 },
    { lat: 48.9845228, lng: 21.1636742 },
    { lat: 48.9837461, lng: 21.1635659 },
    { lat: 48.9834929, lng: 21.1634568 },
    { lat: 48.9832966, lng: 21.163331 },
    { lat: 48.9827864, lng: 21.162753 },
    { lat: 48.9826409, lng: 21.1626693 },
    { lat: 48.9826173, lng: 21.1625647 },
    { lat: 48.9822502, lng: 21.1620206 },
    { lat: 48.9820015, lng: 21.1618073 },
    { lat: 48.9816356, lng: 21.1606592 },
    { lat: 48.98157, lng: 21.1601129 },
    { lat: 48.9814507, lng: 21.1597913 },
    { lat: 48.9812706, lng: 21.1595421 },
    { lat: 48.9811656, lng: 21.1591421 },
    { lat: 48.9810825, lng: 21.1590055 },
    { lat: 48.9810462, lng: 21.1586413 },
    { lat: 48.9808925, lng: 21.1584021 },
    { lat: 48.9809216, lng: 21.1580392 },
    { lat: 48.980647, lng: 21.1575454 },
    { lat: 48.9804672, lng: 21.1575471 },
    { lat: 48.9802789, lng: 21.1572541 },
    { lat: 48.9798975, lng: 21.1572572 },
    { lat: 48.9796869, lng: 21.1571099 },
    { lat: 48.9793168, lng: 21.1572265 },
    { lat: 48.9791702, lng: 21.1571369 },
    { lat: 48.9790732, lng: 21.1571733 },
    { lat: 48.978914, lng: 21.1570445 },
    { lat: 48.9787216, lng: 21.1572258 },
    { lat: 48.9785459, lng: 21.1571863 },
    { lat: 48.978563, lng: 21.157266 },
    { lat: 48.9784625, lng: 21.1572863 },
    { lat: 48.9783202, lng: 21.157024 },
    { lat: 48.978229, lng: 21.1569866 },
    { lat: 48.9780569, lng: 21.1570941 },
    { lat: 48.9779827, lng: 21.1570594 },
    { lat: 48.977925, lng: 21.1571599 },
    { lat: 48.9775757, lng: 21.157074 },
    { lat: 48.9772732, lng: 21.1572938 },
    { lat: 48.9771699, lng: 21.1571208 },
    { lat: 48.9771498, lng: 21.1572698 },
    { lat: 48.9770977, lng: 21.15726 },
    { lat: 48.9770357, lng: 21.157008 },
    { lat: 48.976823, lng: 21.1571869 },
    { lat: 48.9766675, lng: 21.1570721 },
    { lat: 48.9764437, lng: 21.1571472 },
    { lat: 48.9763408, lng: 21.157279 },
    { lat: 48.9762306, lng: 21.1572104 },
    { lat: 48.9761061, lng: 21.1574337 },
    { lat: 48.9758646, lng: 21.1575534 },
    { lat: 48.9757042, lng: 21.1574999 },
    { lat: 48.9753753, lng: 21.1576743 },
    { lat: 48.9753757, lng: 21.1577903 },
    { lat: 48.9751194, lng: 21.1576955 },
    { lat: 48.9750721, lng: 21.157796 },
    { lat: 48.9749349, lng: 21.1576623 },
    { lat: 48.9748674, lng: 21.157697 },
    { lat: 48.9748355, lng: 21.157935 },
    { lat: 48.974605, lng: 21.1577957 },
    { lat: 48.9745738, lng: 21.1578775 },
    { lat: 48.9743357, lng: 21.1578565 },
    { lat: 48.9742556, lng: 21.1577781 },
    { lat: 48.9742635, lng: 21.1575655 },
    { lat: 48.9741099, lng: 21.1575853 },
    { lat: 48.9739852, lng: 21.1577721 },
    { lat: 48.973815, lng: 21.1577594 },
    { lat: 48.9738495, lng: 21.1578525 },
    { lat: 48.9738101, lng: 21.1579636 },
    { lat: 48.973663, lng: 21.1579718 },
    { lat: 48.9735504, lng: 21.1579012 },
    { lat: 48.9732917, lng: 21.1579379 },
    { lat: 48.9731001, lng: 21.1578237 },
    { lat: 48.9729652, lng: 21.1575093 },
    { lat: 48.9728589, lng: 21.1575626 },
    { lat: 48.9727266, lng: 21.1575034 },
    { lat: 48.9727436, lng: 21.1571139 },
    { lat: 48.9725624, lng: 21.1572625 },
    { lat: 48.9724794, lng: 21.1571657 },
    { lat: 48.9722505, lng: 21.1572856 },
    { lat: 48.9722576, lng: 21.1573696 },
    { lat: 48.9719961, lng: 21.1572504 },
    { lat: 48.9719535, lng: 21.157433 },
    { lat: 48.9717209, lng: 21.1577297 },
    { lat: 48.9716122, lng: 21.1579597 },
    { lat: 48.97146, lng: 21.1579869 },
    { lat: 48.9713889, lng: 21.1580895 },
    { lat: 48.9712252, lng: 21.1581405 },
    { lat: 48.9708514, lng: 21.1584235 },
    { lat: 48.9701182, lng: 21.1587574 },
    { lat: 48.9698728, lng: 21.1587595 },
    { lat: 48.9696936, lng: 21.1588886 },
    { lat: 48.9695557, lng: 21.1589055 },
    { lat: 48.9694462, lng: 21.1588271 },
    { lat: 48.9693892, lng: 21.1590154 },
    { lat: 48.9692367, lng: 21.1590713 },
    { lat: 48.9692097, lng: 21.1591424 },
    { lat: 48.9688396, lng: 21.1591826 },
    { lat: 48.9686555, lng: 21.1590903 },
    { lat: 48.968429, lng: 21.1592566 },
    { lat: 48.9677402, lng: 21.159109 },
    { lat: 48.9676148, lng: 21.1591697 },
    { lat: 48.9670849, lng: 21.1595651 },
    { lat: 48.9667239, lng: 21.1599334 },
    { lat: 48.9665725, lng: 21.1602317 },
    { lat: 48.9662886, lng: 21.1604716 },
    { lat: 48.9658758, lng: 21.1611191 },
    { lat: 48.9658008, lng: 21.1618438 },
    { lat: 48.9653333, lng: 21.1628996 },
    { lat: 48.9651584, lng: 21.1641365 },
    { lat: 48.9649336, lng: 21.1645372 },
    { lat: 48.9649843, lng: 21.1649271 },
    { lat: 48.964847, lng: 21.1659267 },
    { lat: 48.9649325, lng: 21.1670304 },
    { lat: 48.9650083, lng: 21.1672957 },
    { lat: 48.9650454, lng: 21.1679724 },
    { lat: 48.964979, lng: 21.16794 },
    { lat: 48.9645862, lng: 21.1694387 },
    { lat: 48.9644448, lng: 21.1706309 },
    { lat: 48.9643048, lng: 21.171083 },
    { lat: 48.9642078, lng: 21.1718604 },
    { lat: 48.9641337, lng: 21.1721878 },
    { lat: 48.9641692, lng: 21.1727096 },
    { lat: 48.9640848, lng: 21.172995 },
    { lat: 48.964088, lng: 21.1740936 },
    { lat: 48.963872, lng: 21.175173 },
    { lat: 48.9636108, lng: 21.1771703 },
    { lat: 48.9633026, lng: 21.1781088 },
    { lat: 48.962899, lng: 21.1788736 },
    { lat: 48.9628939, lng: 21.1793564 },
    { lat: 48.9627951, lng: 21.179933 },
    { lat: 48.9625375, lng: 21.1805462 },
    { lat: 48.9624558, lng: 21.1810674 },
    { lat: 48.9621896, lng: 21.1816554 },
    { lat: 48.9620775, lng: 21.1817728 },
    { lat: 48.9618185, lng: 21.1818768 },
    { lat: 48.9614391, lng: 21.1818408 },
    { lat: 48.9607924, lng: 21.1822433 },
    { lat: 48.9604373, lng: 21.1826561 },
    { lat: 48.9603956, lng: 21.1828697 },
    { lat: 48.9601236, lng: 21.1833829 },
    { lat: 48.9598888, lng: 21.1841462 },
    { lat: 48.9600173, lng: 21.1846948 },
    { lat: 48.9596923, lng: 21.1853841 },
    { lat: 48.959303, lng: 21.1864622 },
    { lat: 48.9587229, lng: 21.1873793 },
    { lat: 48.9583714, lng: 21.1881872 },
    { lat: 48.9579341, lng: 21.1895689 },
    { lat: 48.9574212, lng: 21.1907962 },
    { lat: 48.9567521, lng: 21.1919693 },
    { lat: 48.9568207, lng: 21.1921398 },
    { lat: 48.9569797, lng: 21.1922261 },
    { lat: 48.9570257, lng: 21.1925801 },
    { lat: 48.9570961, lng: 21.1926377 },
    { lat: 48.957154, lng: 21.1925809 },
    { lat: 48.957326, lng: 21.1926426 },
    { lat: 48.9573759, lng: 21.1927687 },
    { lat: 48.9573501, lng: 21.1928776 },
    { lat: 48.9574283, lng: 21.1930041 },
    { lat: 48.957406, lng: 21.1930838 },
    { lat: 48.9577027, lng: 21.193467 },
    { lat: 48.9576498, lng: 21.1936771 },
    { lat: 48.9576894, lng: 21.1937208 },
    { lat: 48.9578089, lng: 21.1937261 },
    { lat: 48.9578417, lng: 21.1936285 },
    { lat: 48.9579132, lng: 21.1936477 },
    { lat: 48.9579572, lng: 21.1939002 },
    { lat: 48.9581219, lng: 21.1936616 },
    { lat: 48.9582664, lng: 21.1937121 },
    { lat: 48.9582883, lng: 21.1935845 },
    { lat: 48.9583495, lng: 21.1935643 },
    { lat: 48.9583867, lng: 21.1936393 },
    { lat: 48.9587339, lng: 21.1937976 },
    { lat: 48.9587858, lng: 21.1938755 },
    { lat: 48.958662, lng: 21.1943031 },
    { lat: 48.9586697, lng: 21.1944461 },
    { lat: 48.9589946, lng: 21.1946547 },
    { lat: 48.959104, lng: 21.1946626 },
    { lat: 48.9591644, lng: 21.1948294 },
    { lat: 48.959124, lng: 21.1949021 },
    { lat: 48.9592033, lng: 21.1952051 },
    { lat: 48.9593654, lng: 21.1953514 },
    { lat: 48.959273, lng: 21.1955636 },
    { lat: 48.9594251, lng: 21.1958205 },
    { lat: 48.9594156, lng: 21.1959522 },
    { lat: 48.9597345, lng: 21.1970054 },
    { lat: 48.9598779, lng: 21.197346 },
    { lat: 48.9600015, lng: 21.1974148 },
    { lat: 48.9600896, lng: 21.1976741 },
    { lat: 48.9602377, lng: 21.1978035 },
    { lat: 48.960302, lng: 21.1980954 },
    { lat: 48.9602813, lng: 21.1982623 },
    { lat: 48.9604929, lng: 21.1984075 },
    { lat: 48.9608188, lng: 21.1993663 },
    { lat: 48.9612156, lng: 21.1997109 },
    { lat: 48.9613729, lng: 21.2002117 },
    { lat: 48.9617156, lng: 21.2003697 },
    { lat: 48.9620347, lng: 21.2007198 },
    { lat: 48.9623694, lng: 21.2008057 },
    { lat: 48.9625449, lng: 21.2009811 },
    { lat: 48.9627875, lng: 21.201076 },
    { lat: 48.963571, lng: 21.2009756 },
    { lat: 48.9640967, lng: 21.2013388 },
    { lat: 48.9642993, lng: 21.2015588 },
    { lat: 48.9646434, lng: 21.2016974 },
    { lat: 48.9648475, lng: 21.2019269 },
    { lat: 48.9649098, lng: 21.2020438 },
    { lat: 48.9648824, lng: 21.2030755 },
    { lat: 48.9647744, lng: 21.2035539 },
    { lat: 48.9647663, lng: 21.2040111 },
    { lat: 48.9649796, lng: 21.2048628 },
    { lat: 48.9651227, lng: 21.2051924 },
    { lat: 48.9657258, lng: 21.2060892 },
    { lat: 48.9662437, lng: 21.2066723 },
    { lat: 48.9664796, lng: 21.2070242 },
    { lat: 48.9668914, lng: 21.207458 },
    { lat: 48.9674565, lng: 21.207825 },
    { lat: 48.9676501, lng: 21.2080367 },
    { lat: 48.9673683, lng: 21.2083453 },
    { lat: 48.9672336, lng: 21.2083983 },
    { lat: 48.9667161, lng: 21.2082564 },
    { lat: 48.9662266, lng: 21.2078948 },
    { lat: 48.9655018, lng: 21.2077846 },
    { lat: 48.9653001, lng: 21.2076674 },
    { lat: 48.9649338, lng: 21.2076503 },
    { lat: 48.9636919, lng: 21.208313 },
    { lat: 48.9634453, lng: 21.2083834 },
    { lat: 48.9632917, lng: 21.208548 },
    { lat: 48.9629495, lng: 21.2087195 },
    { lat: 48.9623342, lng: 21.2092697 },
    { lat: 48.9617235, lng: 21.209575 },
    { lat: 48.9616225, lng: 21.2096926 },
    { lat: 48.9611788, lng: 21.2106194 },
    { lat: 48.9607558, lng: 21.2113488 },
    { lat: 48.9603139, lng: 21.2116459 },
    { lat: 48.9597217, lng: 21.2115667 },
    { lat: 48.959177, lng: 21.2115979 },
    { lat: 48.9590463, lng: 21.2114916 },
    { lat: 48.9585003, lng: 21.2106839 },
    { lat: 48.9576786, lng: 21.2098112 },
    { lat: 48.9573784, lng: 21.2092043 },
    { lat: 48.9572816, lng: 21.2091701 },
    { lat: 48.9571861, lng: 21.2091766 },
    { lat: 48.9568026, lng: 21.2097602 },
    { lat: 48.9567076, lng: 21.20982 },
    { lat: 48.9563224, lng: 21.2099537 },
    { lat: 48.9558385, lng: 21.2098452 },
    { lat: 48.9552746, lng: 21.2099119 },
    { lat: 48.9547531, lng: 21.2098244 },
    { lat: 48.9541636, lng: 21.2100468 },
    { lat: 48.9533399, lng: 21.210772 },
    { lat: 48.9525916, lng: 21.2108089 },
    { lat: 48.9520289, lng: 21.2112389 },
    { lat: 48.9517044, lng: 21.2111154 },
    { lat: 48.9510515, lng: 21.2106796 },
    { lat: 48.9508395, lng: 21.2107023 },
    { lat: 48.9506652, lng: 21.2110353 },
    { lat: 48.9505956, lng: 21.2115788 },
    { lat: 48.9505027, lng: 21.2118125 },
    { lat: 48.950227, lng: 21.2121398 },
    { lat: 48.9501865, lng: 21.2122827 },
    { lat: 48.9500347, lng: 21.212469 },
    { lat: 48.9499509, lng: 21.2127375 },
    { lat: 48.9497909, lng: 21.2129856 },
    { lat: 48.9495888, lng: 21.2136872 },
    { lat: 48.9488521, lng: 21.2145653 },
    { lat: 48.9488916, lng: 21.2146477 },
    { lat: 48.9483963, lng: 21.2150767 },
    { lat: 48.9477387, lng: 21.2151704 },
    { lat: 48.9471974, lng: 21.2155289 },
    { lat: 48.9467183, lng: 21.2157591 },
    { lat: 48.9455897, lng: 21.2157212 },
    { lat: 48.9454424, lng: 21.2157629 },
    { lat: 48.9453056, lng: 21.2158504 },
    { lat: 48.9450205, lng: 21.2163835 },
    { lat: 48.9449997, lng: 21.2166173 },
    { lat: 48.9450444, lng: 21.2170065 },
    { lat: 48.9451739, lng: 21.2173713 },
    { lat: 48.9452618, lng: 21.2174832 },
    { lat: 48.9457164, lng: 21.2177699 },
    { lat: 48.9462042, lng: 21.2183296 },
    { lat: 48.9465693, lng: 21.2189594 },
    { lat: 48.9466582, lng: 21.2202669 },
    { lat: 48.9466084, lng: 21.2208347 },
    { lat: 48.9466271, lng: 21.2208581 },
    { lat: 48.947006, lng: 21.2210611 },
    { lat: 48.9473113, lng: 21.2213633 },
    { lat: 48.9478687, lng: 21.2215189 },
    { lat: 48.9481111, lng: 21.221716 },
    { lat: 48.9483406, lng: 21.2223658 },
    { lat: 48.9501341, lng: 21.222757 },
    { lat: 48.9508471, lng: 21.2235051 },
    { lat: 48.9509246, lng: 21.2228504 },
    { lat: 48.9511591, lng: 21.2225339 },
    { lat: 48.9521816, lng: 21.2240767 },
    { lat: 48.9526409, lng: 21.2250242 },
    { lat: 48.9527764, lng: 21.2251509 },
    { lat: 48.9531492, lng: 21.226033 },
    { lat: 48.953561, lng: 21.227449 },
    { lat: 48.9536985, lng: 21.2275946 },
    { lat: 48.9541743, lng: 21.2277758 },
    { lat: 48.9550426, lng: 21.2275787 },
    { lat: 48.9556827, lng: 21.2271337 },
    { lat: 48.9560227, lng: 21.2272458 },
    { lat: 48.9563761, lng: 21.2273581 },
    { lat: 48.9563499, lng: 21.2277152 },
    { lat: 48.956525, lng: 21.2283125 },
    { lat: 48.9566407, lng: 21.2284915 },
    { lat: 48.9567771, lng: 21.2285336 },
    { lat: 48.9570228, lng: 21.2287979 },
    { lat: 48.9571805, lng: 21.2287655 },
    { lat: 48.9576868, lng: 21.229057 },
    { lat: 48.9576533, lng: 21.229208 },
    { lat: 48.9578188, lng: 21.2293201 },
    { lat: 48.9581825, lng: 21.229148 },
    { lat: 48.9585081, lng: 21.2293349 },
    { lat: 48.9586213, lng: 21.2292921 },
    { lat: 48.9587044, lng: 21.2293542 },
    { lat: 48.958728, lng: 21.2296433 },
    { lat: 48.9588554, lng: 21.2298662 },
    { lat: 48.9589381, lng: 21.2301772 },
    { lat: 48.958971, lng: 21.2307002 },
    { lat: 48.959134, lng: 21.2313084 },
    { lat: 48.9592381, lng: 21.231436 },
    { lat: 48.9595316, lng: 21.2320507 },
    { lat: 48.9595474, lng: 21.2323017 },
    { lat: 48.9597286, lng: 21.2327761 },
    { lat: 48.9596742, lng: 21.232838 },
    { lat: 48.9597049, lng: 21.2328867 },
    { lat: 48.9596219, lng: 21.233549 },
    { lat: 48.9593168, lng: 21.2345251 },
    { lat: 48.9592264, lng: 21.2350938 },
    { lat: 48.9590909, lng: 21.2352578 },
    { lat: 48.9588675, lng: 21.2353156 },
    { lat: 48.9588433, lng: 21.2354803 },
    { lat: 48.9587599, lng: 21.2356191 },
    { lat: 48.9586814, lng: 21.2359971 },
    { lat: 48.958637, lng: 21.2363329 },
    { lat: 48.958642, lng: 21.2364105 },
    { lat: 48.9586897, lng: 21.2369783 },
    { lat: 48.9587632, lng: 21.2374355 },
    { lat: 48.9585155, lng: 21.237866 },
    { lat: 48.9588859, lng: 21.2379861 },
    { lat: 48.9587817, lng: 21.2382524 },
    { lat: 48.9593318, lng: 21.2382812 },
    { lat: 48.9598718, lng: 21.2384275 },
    { lat: 48.9605838, lng: 21.2389562 },
    { lat: 48.9617574, lng: 21.2401256 },
    { lat: 48.9633894, lng: 21.240934 },
    { lat: 48.9636856, lng: 21.2411618 },
    { lat: 48.9638099, lng: 21.2412855 },
    { lat: 48.9632137, lng: 21.2424962 },
    { lat: 48.9630508, lng: 21.243272 },
    { lat: 48.9627156, lng: 21.2439706 },
    { lat: 48.9625948, lng: 21.2444268 },
    { lat: 48.9622215, lng: 21.2440756 },
    { lat: 48.9621824, lng: 21.2438617 },
    { lat: 48.9600345, lng: 21.2431837 },
    { lat: 48.9599524, lng: 21.2445736 },
    { lat: 48.9596551, lng: 21.2466088 },
    { lat: 48.9577698, lng: 21.2540126 },
    { lat: 48.9575688, lng: 21.2552652 },
    { lat: 48.9572151, lng: 21.2563914 },
    { lat: 48.9571276, lng: 21.2565462 },
    { lat: 48.956642, lng: 21.256856 },
    { lat: 48.9563038, lng: 21.2569487 },
    { lat: 48.9563311, lng: 21.257176 },
    { lat: 48.9551584, lng: 21.2574578 },
    { lat: 48.955139, lng: 21.2572734 },
    { lat: 48.9546212, lng: 21.2574483 },
    { lat: 48.9536869, lng: 21.2572961 },
    { lat: 48.953536, lng: 21.257064 },
    { lat: 48.9534692, lng: 21.2561034 },
    { lat: 48.9533865, lng: 21.2558988 },
    { lat: 48.9531914, lng: 21.2557039 },
    { lat: 48.9529718, lng: 21.2556271 },
    { lat: 48.95276, lng: 21.2556553 },
    { lat: 48.9523831, lng: 21.2561669 },
    { lat: 48.9522678, lng: 21.2565202 },
    { lat: 48.9510154, lng: 21.255208 },
    { lat: 48.9506926, lng: 21.2549573 },
    { lat: 48.9503717, lng: 21.2547653 },
    { lat: 48.9505833, lng: 21.2559035 },
    { lat: 48.9509344, lng: 21.2588628 },
    { lat: 48.9510482, lng: 21.2593113 },
    { lat: 48.9511156, lng: 21.2593011 },
    { lat: 48.9511839, lng: 21.2592909 },
    { lat: 48.9516405, lng: 21.2587716 },
    { lat: 48.9564889, lng: 21.2581348 },
    { lat: 48.9559642, lng: 21.2617871 },
    { lat: 48.9559322, lng: 21.2625048 },
    { lat: 48.9567587, lng: 21.2626282 },
    { lat: 48.9587659, lng: 21.2631665 },
    { lat: 48.9588868, lng: 21.2639568 },
    { lat: 48.9588665, lng: 21.2642093 },
    { lat: 48.9584297, lng: 21.2661513 },
    { lat: 48.9583994, lng: 21.2668478 },
    { lat: 48.9582878, lng: 21.2672828 },
    { lat: 48.9583136, lng: 21.2675704 },
    { lat: 48.9584219, lng: 21.2679501 },
    { lat: 48.9584306, lng: 21.2686711 },
    { lat: 48.9584835, lng: 21.2686738 },
    { lat: 48.9584532, lng: 21.2701856 },
    { lat: 48.9585034, lng: 21.2705008 },
    { lat: 48.9584427, lng: 21.2712742 },
    { lat: 48.957705, lng: 21.2727218 },
    { lat: 48.9576423, lng: 21.2743838 },
    { lat: 48.9578833, lng: 21.2761199 },
    { lat: 48.9577526, lng: 21.276966 },
    { lat: 48.9577583, lng: 21.2781791 },
    { lat: 48.9577037, lng: 21.2785289 },
    { lat: 48.9576718, lng: 21.2793994 },
    { lat: 48.9575911, lng: 21.2799304 },
    { lat: 48.957601, lng: 21.2802524 },
    { lat: 48.9573984, lng: 21.2813548 },
    { lat: 48.9578024, lng: 21.2808738 },
    { lat: 48.9577635, lng: 21.281188 },
    { lat: 48.9573924, lng: 21.2819704 },
    { lat: 48.9572158, lng: 21.2825289 },
    { lat: 48.956716, lng: 21.2832584 },
    { lat: 48.9567337, lng: 21.2835418 },
    { lat: 48.9565295, lng: 21.2845165 },
    { lat: 48.9562056, lng: 21.2854477 },
    { lat: 48.9563656, lng: 21.2854533 },
    { lat: 48.9565389, lng: 21.2849026 },
    { lat: 48.9567535, lng: 21.2844077 },
    { lat: 48.9568857, lng: 21.2835089 },
    { lat: 48.9574668, lng: 21.2826702 },
    { lat: 48.9575253, lng: 21.2822289 },
    { lat: 48.9578672, lng: 21.2815581 },
    { lat: 48.9580221, lng: 21.2810703 },
    { lat: 48.9581139, lng: 21.2805027 },
    { lat: 48.9582194, lng: 21.2811914 },
    { lat: 48.9588405, lng: 21.280304 },
    { lat: 48.9603363, lng: 21.2807565 },
    { lat: 48.9604407, lng: 21.2805988 },
    { lat: 48.9629884, lng: 21.2812645 },
    { lat: 48.9650059, lng: 21.2819984 },
    { lat: 48.9652576, lng: 21.2819878 },
    { lat: 48.9652228, lng: 21.2820973 },
    { lat: 48.9652632, lng: 21.2828333 },
    { lat: 48.9655818, lng: 21.2871189 },
    { lat: 48.9655502, lng: 21.2871562 },
    { lat: 48.9657067, lng: 21.2878994 },
    { lat: 48.9652185, lng: 21.2879738 },
    { lat: 48.9652271, lng: 21.288109 },
    { lat: 48.9650752, lng: 21.2881057 },
    { lat: 48.9650631, lng: 21.2881581 },
    { lat: 48.9650832, lng: 21.2894442 },
    { lat: 48.965147, lng: 21.2898358 },
    { lat: 48.9650901, lng: 21.2915531 },
    { lat: 48.9651229, lng: 21.2932981 },
    { lat: 48.9650933, lng: 21.2980231 },
    { lat: 48.9666478, lng: 21.3081695 },
    { lat: 48.9631116, lng: 21.3178478 },
    { lat: 48.9627411, lng: 21.3188842 },
    { lat: 48.9626675, lng: 21.3192038 },
    { lat: 48.9628484, lng: 21.3192281 },
    { lat: 48.9650892, lng: 21.319285 },
    { lat: 48.9634348, lng: 21.3239 },
    { lat: 48.9630579, lng: 21.326063 },
    { lat: 48.9626515, lng: 21.3268003 },
    { lat: 48.96301, lng: 21.3272338 },
    { lat: 48.9633526, lng: 21.3279226 },
    { lat: 48.9635943, lng: 21.3278055 },
    { lat: 48.9644117, lng: 21.3290143 },
    { lat: 48.9646752, lng: 21.3292872 },
    { lat: 48.9649962, lng: 21.3293673 },
    { lat: 48.964968, lng: 21.3298864 },
    { lat: 48.9651352, lng: 21.3303256 },
    { lat: 48.9651604, lng: 21.3319071 },
    { lat: 48.9655829, lng: 21.3332004 },
    { lat: 48.9658673, lng: 21.3343445 },
    { lat: 48.9659898, lng: 21.3345667 },
    { lat: 48.9671519, lng: 21.3351923 },
    { lat: 48.9672349, lng: 21.3353703 },
    { lat: 48.9674075, lng: 21.3354271 },
    { lat: 48.9676404, lng: 21.3334014 },
    { lat: 48.9676565, lng: 21.3291194 },
    { lat: 48.9675394, lng: 21.327695 },
    { lat: 48.968282, lng: 21.3264236 },
    { lat: 48.9696713, lng: 21.3250641 },
    { lat: 48.9700234, lng: 21.3255099 },
    { lat: 48.9700705, lng: 21.3254314 },
    { lat: 48.9703229, lng: 21.3253619 },
    { lat: 48.9703617, lng: 21.3255716 },
    { lat: 48.970569, lng: 21.325502 },
    { lat: 48.9712948, lng: 21.32565 },
    { lat: 48.9715653, lng: 21.3255592 },
    { lat: 48.9719503, lng: 21.3251488 },
    { lat: 48.9721415, lng: 21.3252963 },
    { lat: 48.9723326, lng: 21.3253339 },
    { lat: 48.9730882, lng: 21.3244521 },
    { lat: 48.9736868, lng: 21.3240752 },
    { lat: 48.9738667, lng: 21.3238865 },
    { lat: 48.9742394, lng: 21.3233361 },
    { lat: 48.9743511, lng: 21.3228577 },
    { lat: 48.9743675, lng: 21.3225783 },
    { lat: 48.9741941, lng: 21.3223571 },
    { lat: 48.9744641, lng: 21.3211248 },
    { lat: 48.9743404, lng: 21.3210478 },
    { lat: 48.9746209, lng: 21.320242 },
    { lat: 48.9742953, lng: 21.3189215 },
    { lat: 48.9741466, lng: 21.3184723 },
    { lat: 48.9737994, lng: 21.3177053 },
    { lat: 48.973654, lng: 21.3166147 },
    { lat: 48.9734426, lng: 21.3155406 },
    { lat: 48.9730415, lng: 21.3146348 },
    { lat: 48.9727239, lng: 21.314139 },
    { lat: 48.9726275, lng: 21.3135934 },
    { lat: 48.9727236, lng: 21.3125311 },
    { lat: 48.9727418, lng: 21.3115739 },
    { lat: 48.9726837, lng: 21.3109164 },
    { lat: 48.9725824, lng: 21.3105746 },
    { lat: 48.9722536, lng: 21.3098629 },
    { lat: 48.9719069, lng: 21.3100874 },
    { lat: 48.9717711, lng: 21.3095023 },
    { lat: 48.9715227, lng: 21.3088055 },
    { lat: 48.9713076, lng: 21.3076471 },
    { lat: 48.9712752, lng: 21.3068417 },
    { lat: 48.9711706, lng: 21.3059103 },
    { lat: 48.9714411, lng: 21.3058183 },
    { lat: 48.9717587, lng: 21.3054923 },
    { lat: 48.9719007, lng: 21.3061303 },
    { lat: 48.9724122, lng: 21.3061078 },
    { lat: 48.9725914, lng: 21.3055258 },
    { lat: 48.9726733, lng: 21.3050393 },
    { lat: 48.9725629, lng: 21.3043998 },
    { lat: 48.9728367, lng: 21.3036708 },
    { lat: 48.9725384, lng: 21.3032579 },
    { lat: 48.9728046, lng: 21.3030945 },
    { lat: 48.9736574, lng: 21.3027569 },
    { lat: 48.973957, lng: 21.3023186 },
    { lat: 48.9743306, lng: 21.301988 },
    { lat: 48.9744898, lng: 21.301638 },
    { lat: 48.9744626, lng: 21.3011843 },
    { lat: 48.9746388, lng: 21.3009448 },
    { lat: 48.9744286, lng: 21.3004384 },
    { lat: 48.9745429, lng: 21.3001556 },
    { lat: 48.9744867, lng: 21.3001234 },
    { lat: 48.9744396, lng: 21.2999838 },
    { lat: 48.9744604, lng: 21.2998842 },
    { lat: 48.9744053, lng: 21.2997028 },
    { lat: 48.9743704, lng: 21.2992575 },
    { lat: 48.9745185, lng: 21.2993518 },
    { lat: 48.9747627, lng: 21.2989156 },
    { lat: 48.9747598, lng: 21.2984856 },
    { lat: 48.9749603, lng: 21.2978984 },
    { lat: 48.9749277, lng: 21.2978588 },
    { lat: 48.9749307, lng: 21.2975136 },
    { lat: 48.9751457, lng: 21.2968907 },
    { lat: 48.9754114, lng: 21.296781 },
    { lat: 48.9755728, lng: 21.2966136 },
    { lat: 48.9756552, lng: 21.2961772 },
    { lat: 48.9757493, lng: 21.2961602 },
    { lat: 48.9757572, lng: 21.2959394 },
    { lat: 48.9758716, lng: 21.2959629 },
    { lat: 48.9758962, lng: 21.2958891 },
    { lat: 48.9758921, lng: 21.2955678 },
    { lat: 48.9759804, lng: 21.2953248 },
    { lat: 48.9763644, lng: 21.295295 },
    { lat: 48.9781536, lng: 21.2930083 },
    { lat: 48.9787722, lng: 21.294027 },
    { lat: 48.9794796, lng: 21.2950517 },
    { lat: 48.9795441, lng: 21.2945829 },
    { lat: 48.9797108, lng: 21.2941065 },
    { lat: 48.9802039, lng: 21.2931165 },
    { lat: 48.9803156, lng: 21.2925323 },
    { lat: 48.9804194, lng: 21.292544 },
    { lat: 48.9809279, lng: 21.2940208 },
    { lat: 48.9812443, lng: 21.2948007 },
    { lat: 48.981361, lng: 21.295275 },
    { lat: 48.984063, lng: 21.2926521 },
    { lat: 48.9842115, lng: 21.2930619 },
    { lat: 48.9843034, lng: 21.2936423 },
    { lat: 48.9842252, lng: 21.2949945 },
    { lat: 48.9845891, lng: 21.2950421 },
    { lat: 48.9850014, lng: 21.2962807 },
    { lat: 48.9851824, lng: 21.2970522 },
    { lat: 48.9854977, lng: 21.2991744 },
    { lat: 48.9876294, lng: 21.2978892 },
    { lat: 48.9876902, lng: 21.2980419 },
    { lat: 48.9881545, lng: 21.2976159 },
    { lat: 48.9886898, lng: 21.2971291 },
    { lat: 48.9887728, lng: 21.2974443 },
    { lat: 48.9888698, lng: 21.2974192 },
    { lat: 48.9889769, lng: 21.2975353 },
    { lat: 48.9891376, lng: 21.2971983 },
    { lat: 48.9893838, lng: 21.2968572 },
    { lat: 48.9895756, lng: 21.2960605 },
    { lat: 48.9897234, lng: 21.2959985 },
    { lat: 48.9897315, lng: 21.2958546 },
    { lat: 48.9898802, lng: 21.2954945 },
    { lat: 48.9899183, lng: 21.2945204 },
    { lat: 48.9899785, lng: 21.2943912 },
    { lat: 48.9899071, lng: 21.2943001 },
    { lat: 48.9897989, lng: 21.2944073 },
    { lat: 48.9896962, lng: 21.294397 },
    { lat: 48.9897392, lng: 21.2941425 },
    { lat: 48.990174, lng: 21.2939822 },
    { lat: 48.9900871, lng: 21.2931925 },
    { lat: 48.9896594, lng: 21.2932243 },
    { lat: 48.9893555, lng: 21.2928146 },
    { lat: 48.9902623, lng: 21.2924812 },
    { lat: 48.9911229, lng: 21.2919524 },
    { lat: 48.9911687, lng: 21.2919334 },
    { lat: 48.9914893, lng: 21.292295 },
    { lat: 48.9916661, lng: 21.2920485 },
    { lat: 48.9916751, lng: 21.2913536 },
    { lat: 48.9917474, lng: 21.2906232 },
    { lat: 48.9919075, lng: 21.2906299 },
    { lat: 48.9919474, lng: 21.2906844 },
    { lat: 48.9920911, lng: 21.2906346 },
    { lat: 48.9921863, lng: 21.2909869 },
    { lat: 48.9920378, lng: 21.2911081 },
    { lat: 48.992001, lng: 21.2914077 },
    { lat: 48.9921395, lng: 21.2914514 },
    { lat: 48.9921686, lng: 21.2916303 },
    { lat: 48.9921359, lng: 21.2917745 },
    { lat: 48.9921739, lng: 21.2919155 },
    { lat: 48.992283, lng: 21.2919888 },
    { lat: 48.992321, lng: 21.2920918 },
    { lat: 48.9923034, lng: 21.2924736 },
    { lat: 48.9922483, lng: 21.2925857 },
    { lat: 48.9922746, lng: 21.2927136 },
    { lat: 48.9924639, lng: 21.2929747 },
    { lat: 48.9925526, lng: 21.2931925 },
    { lat: 48.9927076, lng: 21.2933658 },
    { lat: 48.9928391, lng: 21.2932565 },
    { lat: 48.9929435, lng: 21.2934312 },
    { lat: 48.9929184, lng: 21.293676 },
    { lat: 48.9931116, lng: 21.2938889 },
    { lat: 48.9932154, lng: 21.2938583 },
    { lat: 48.993278, lng: 21.293956 },
    { lat: 48.9932908, lng: 21.2942694 },
    { lat: 48.9934146, lng: 21.2946007 },
    { lat: 48.9936641, lng: 21.2948505 },
    { lat: 48.9937731, lng: 21.2951009 },
    { lat: 48.9940579, lng: 21.2952722 },
    { lat: 48.9941678, lng: 21.2954254 },
    { lat: 48.9943379, lng: 21.2958033 },
    { lat: 48.9945239, lng: 21.2960734 },
    { lat: 48.9946531, lng: 21.296513 },
    { lat: 48.9948002, lng: 21.2967241 },
    { lat: 48.9949133, lng: 21.2971856 },
    { lat: 48.9949776, lng: 21.2972674 },
    { lat: 48.9951131, lng: 21.2985064 },
    { lat: 48.9952374, lng: 21.2987123 },
    { lat: 48.9951782, lng: 21.2989813 },
    { lat: 48.9952527, lng: 21.299658 },
    { lat: 48.9953685, lng: 21.2997269 },
    { lat: 48.9954095, lng: 21.2999294 },
    { lat: 48.9954033, lng: 21.3003559 },
    { lat: 48.9956255, lng: 21.300784 },
    { lat: 48.9957106, lng: 21.3010629 },
    { lat: 48.9956627, lng: 21.3014127 },
    { lat: 48.9957147, lng: 21.3016793 },
    { lat: 48.9958343, lng: 21.3019153 },
    { lat: 48.9957071, lng: 21.3021385 },
    { lat: 48.9961839, lng: 21.3037546 },
    { lat: 48.9961317, lng: 21.3040406 },
    { lat: 48.9962237, lng: 21.304437 },
    { lat: 48.9962015, lng: 21.3045235 },
    { lat: 48.9964786, lng: 21.304904 },
    { lat: 48.9964535, lng: 21.3056006 },
    { lat: 48.9963616, lng: 21.3057604 },
    { lat: 48.9964371, lng: 21.305839 },
    { lat: 48.9966232, lng: 21.3063008 },
    { lat: 48.9965913, lng: 21.3069129 },
    { lat: 48.9965338, lng: 21.3073577 },
    { lat: 48.9964338, lng: 21.3073654 },
    { lat: 48.9963953, lng: 21.3076104 },
    { lat: 48.9962973, lng: 21.3077641 },
    { lat: 48.9961807, lng: 21.3077354 },
    { lat: 48.9961099, lng: 21.3078905 },
    { lat: 48.9961291, lng: 21.3079999 },
    { lat: 48.9963346, lng: 21.3081381 },
    { lat: 48.9965837, lng: 21.3087478 },
    { lat: 48.9965642, lng: 21.3098121 },
    { lat: 48.9967432, lng: 21.3100776 },
    { lat: 48.9967903, lng: 21.3107054 },
    { lat: 48.9969625, lng: 21.3109661 },
    { lat: 48.9969584, lng: 21.3111656 },
    { lat: 48.9970118, lng: 21.3111766 },
    { lat: 48.9971019, lng: 21.3113702 },
    { lat: 48.9970943, lng: 21.3115636 },
    { lat: 48.9971557, lng: 21.3117704 },
    { lat: 48.9970105, lng: 21.312256 },
    { lat: 48.9970222, lng: 21.3124541 },
    { lat: 48.997401, lng: 21.3131447 },
    { lat: 48.9973981, lng: 21.3134251 },
    { lat: 48.9974515, lng: 21.3135927 },
    { lat: 48.9976212, lng: 21.3137307 },
    { lat: 48.9975919, lng: 21.3138449 },
    { lat: 48.9973895, lng: 21.313849 },
    { lat: 48.9972386, lng: 21.3140272 },
    { lat: 48.9974099, lng: 21.3142987 },
    { lat: 48.9973926, lng: 21.314468 },
    { lat: 48.9971433, lng: 21.3147076 },
    { lat: 48.9971067, lng: 21.3148384 },
    { lat: 48.9972317, lng: 21.3151429 },
    { lat: 48.9973042, lng: 21.3151536 },
    { lat: 48.9971767, lng: 21.316263 },
    { lat: 48.9972261, lng: 21.3167057 },
    { lat: 48.9971207, lng: 21.3167514 },
    { lat: 48.9970799, lng: 21.3171201 },
    { lat: 48.9969188, lng: 21.317226 },
    { lat: 48.9968475, lng: 21.3175051 },
    { lat: 48.9967538, lng: 21.3176102 },
    { lat: 48.996703, lng: 21.317866 },
    { lat: 48.9967914, lng: 21.3180745 },
    { lat: 48.9968046, lng: 21.318581 },
    { lat: 48.9966719, lng: 21.3187006 },
    { lat: 48.996719, lng: 21.3189578 },
    { lat: 48.9965771, lng: 21.3192465 },
    { lat: 48.996504, lng: 21.319701 },
    { lat: 48.9966006, lng: 21.3198394 },
    { lat: 48.9965825, lng: 21.3199507 },
    { lat: 48.996673, lng: 21.3203798 },
    { lat: 48.9965668, lng: 21.3207745 },
    { lat: 48.9965764, lng: 21.3209441 },
    { lat: 48.9963142, lng: 21.3213515 },
    { lat: 48.9963503, lng: 21.3214698 },
    { lat: 48.9963071, lng: 21.3216778 },
    { lat: 48.996376, lng: 21.3220141 },
    { lat: 48.9962985, lng: 21.3222442 },
    { lat: 48.9963747, lng: 21.3227617 },
    { lat: 48.9962891, lng: 21.3229805 },
    { lat: 48.9963075, lng: 21.3232157 },
    { lat: 48.996209, lng: 21.3235684 },
    { lat: 48.9962369, lng: 21.3237535 },
    { lat: 48.9961245, lng: 21.324014 },
    { lat: 48.996162, lng: 21.3244349 },
    { lat: 48.9961467, lng: 21.3247166 },
    { lat: 48.9960817, lng: 21.3249458 },
    { lat: 48.9961061, lng: 21.3250133 },
    { lat: 48.9960771, lng: 21.3250232 },
    { lat: 48.9972999, lng: 21.3249732 },
    { lat: 48.9979682, lng: 21.325156 },
    { lat: 48.9986341, lng: 21.3250308 },
    { lat: 48.9992189, lng: 21.3251331 },
    { lat: 48.9992352, lng: 21.3250399 },
    { lat: 48.999369, lng: 21.3251843 },
    { lat: 49.0017393, lng: 21.3257311 },
    { lat: 49.0022011, lng: 21.3257814 },
    { lat: 49.0026875, lng: 21.3259755 },
    { lat: 49.0033781, lng: 21.3260844 },
    { lat: 49.0044794, lng: 21.3264085 },
    { lat: 49.0041686, lng: 21.3233733 },
    { lat: 49.004207, lng: 21.3233745 },
    { lat: 49.0041717, lng: 21.3220201 },
    { lat: 49.0043749, lng: 21.3217464 },
    { lat: 49.0043778, lng: 21.3211312 },
    { lat: 49.0041369, lng: 21.3209459 },
    { lat: 49.0041098, lng: 21.3195948 },
    { lat: 49.0040832, lng: 21.3195651 },
    { lat: 49.0029444, lng: 21.3196459 },
    { lat: 49.0026252, lng: 21.3105334 },
    { lat: 49.0004359, lng: 21.3106592 },
    { lat: 48.9995607, lng: 21.3098414 },
    { lat: 48.999324, lng: 21.3096471 },
    { lat: 48.9987815, lng: 21.3090113 },
    { lat: 48.998587, lng: 21.3086486 },
    { lat: 48.9982129, lng: 21.3074062 },
    { lat: 48.9981971, lng: 21.3072177 },
    { lat: 48.9981306, lng: 21.3071381 },
    { lat: 48.9980749, lng: 21.3066407 },
    { lat: 48.9980232, lng: 21.3045203 },
    { lat: 48.9978079, lng: 21.3033628 },
    { lat: 48.9986269, lng: 21.3029843 },
    { lat: 48.9987735, lng: 21.3028418 },
    { lat: 48.9988277, lng: 21.3025517 },
    { lat: 48.9988945, lng: 21.3006746 },
    { lat: 48.9987463, lng: 21.2998288 },
    { lat: 48.9987927, lng: 21.2989508 },
    { lat: 48.9988498, lng: 21.2986113 },
    { lat: 48.9989831, lng: 21.298254 },
    { lat: 48.9989176, lng: 21.297916 },
    { lat: 48.9992303, lng: 21.2966111 },
    { lat: 48.9993239, lng: 21.2926227 },
    { lat: 48.9987935, lng: 21.2829284 },
    { lat: 48.9987423, lng: 21.2789971 },
    { lat: 48.9988152, lng: 21.2786836 },
    { lat: 48.9988574, lng: 21.2784133 },
    { lat: 48.9988551, lng: 21.278295 },
    { lat: 48.9994959, lng: 21.2782544 },
    { lat: 49.0003894, lng: 21.2777685 },
    { lat: 49.0012967, lng: 21.2776433 },
    { lat: 49.0018785, lng: 21.277218 },
    { lat: 49.0020542, lng: 21.276838 },
    { lat: 49.0015182, lng: 21.274907 },
    { lat: 49.0003648, lng: 21.2691638 },
    { lat: 48.9998394, lng: 21.2668945 },
    { lat: 48.9998866, lng: 21.2668415 },
    { lat: 49.0004927, lng: 21.2660634 },
    { lat: 49.0008024, lng: 21.2655033 },
    { lat: 49.0011045, lng: 21.2646627 },
    { lat: 49.0014201, lng: 21.2624416 },
    { lat: 49.0015877, lng: 21.2625086 },
    { lat: 49.0025212, lng: 21.2604791 },
    { lat: 49.0039005, lng: 21.2580094 },
    { lat: 49.004448, lng: 21.256348 },
    { lat: 49.0046025, lng: 21.256251 },
    { lat: 49.0045698, lng: 21.2561709 },
    { lat: 49.0049208, lng: 21.2558435 },
    { lat: 49.0049899, lng: 21.2560056 },
    { lat: 49.0056342, lng: 21.2555769 },
    { lat: 49.0065185, lng: 21.2549884 },
    { lat: 49.0063714, lng: 21.2544917 },
    { lat: 49.0077289, lng: 21.2534028 },
    { lat: 49.0076977, lng: 21.2530466 },
    { lat: 49.008853, lng: 21.2520831 },
    { lat: 49.008656, lng: 21.2515599 },
    { lat: 49.0087687, lng: 21.2514757 },
    { lat: 49.010062, lng: 21.2504891 },
    { lat: 49.0104375, lng: 21.2505176 },
    { lat: 49.0112276, lng: 21.2507733 },
    { lat: 49.0116924, lng: 21.2507464 },
    { lat: 49.0119233, lng: 21.250602 },
    { lat: 49.01274, lng: 21.2496395 },
    { lat: 49.0131308, lng: 21.2492887 },
    { lat: 49.0133316, lng: 21.2487177 },
    { lat: 49.0137068, lng: 21.248444 },
    { lat: 49.0142553, lng: 21.2492274 },
    { lat: 49.0145126, lng: 21.2497952 },
    { lat: 49.0148671, lng: 21.2501267 },
    { lat: 49.0159787, lng: 21.2493145 },
    { lat: 49.0161277, lng: 21.249301 },
    { lat: 49.0163727, lng: 21.249545 },
    { lat: 49.0165036, lng: 21.2502139 },
    { lat: 49.016665, lng: 21.2506302 },
    { lat: 49.0168379, lng: 21.2507638 },
    { lat: 49.017209, lng: 21.2507897 },
    { lat: 49.0179177, lng: 21.2514083 },
    { lat: 49.0179507, lng: 21.2520456 },
    { lat: 49.0182757, lng: 21.2524369 },
    { lat: 49.0183007, lng: 21.2530665 },
    { lat: 49.0184767, lng: 21.2534139 },
    { lat: 49.0192375, lng: 21.2538927 },
    { lat: 49.019843, lng: 21.2536392 },
    { lat: 49.0201596, lng: 21.2529538 },
    { lat: 49.0205348, lng: 21.2540665 },
    { lat: 49.0206087, lng: 21.2540545 },
    { lat: 49.0206546, lng: 21.2542287 },
    { lat: 49.0205534, lng: 21.2543137 },
    { lat: 49.020531, lng: 21.2544199 },
    { lat: 49.0209673, lng: 21.2548525 },
    { lat: 49.0209985, lng: 21.2551006 },
    { lat: 49.0209569, lng: 21.2552821 },
    { lat: 49.0208423, lng: 21.2551439 },
    { lat: 49.0208045, lng: 21.2551954 },
    { lat: 49.0205427, lng: 21.2550935 },
    { lat: 49.0204619, lng: 21.2548504 },
    { lat: 49.0203692, lng: 21.254942 },
    { lat: 49.0203103, lng: 21.2553341 },
    { lat: 49.0202668, lng: 21.2552002 },
    { lat: 49.0202735, lng: 21.2549412 },
    { lat: 49.0201943, lng: 21.2552183 },
    { lat: 49.0201644, lng: 21.2556044 },
    { lat: 49.0200085, lng: 21.2555484 },
    { lat: 49.0198737, lng: 21.2556602 },
    { lat: 49.0198905, lng: 21.255807 },
    { lat: 49.0200319, lng: 21.2559798 },
    { lat: 49.0200053, lng: 21.2564668 },
    { lat: 49.0196015, lng: 21.2578313 },
    { lat: 49.0194436, lng: 21.2581853 },
    { lat: 49.01916, lng: 21.2580348 },
    { lat: 49.0187321, lng: 21.2580619 },
    { lat: 49.018637, lng: 21.2582278 },
    { lat: 49.0186352, lng: 21.2585768 },
    { lat: 49.0185848, lng: 21.2587987 },
    { lat: 49.0183023, lng: 21.2591317 },
    { lat: 49.0182787, lng: 21.2592388 },
    { lat: 49.0183328, lng: 21.2598645 },
    { lat: 49.0182225, lng: 21.2603015 },
    { lat: 49.0183165, lng: 21.2606924 },
    { lat: 49.0186025, lng: 21.2613612 },
    { lat: 49.0188025, lng: 21.2650548 },
    { lat: 49.0188497, lng: 21.2650563 },
    { lat: 49.0185327, lng: 21.2677474 },
    { lat: 49.0185525, lng: 21.2683945 },
    { lat: 49.0186466, lng: 21.2687109 },
    { lat: 49.0190136, lng: 21.2694152 },
    { lat: 49.0190569, lng: 21.2698739 },
    { lat: 49.0190061, lng: 21.2702038 },
    { lat: 49.0184513, lng: 21.2720001 },
    { lat: 49.0183952, lng: 21.2724084 },
    { lat: 49.0184129, lng: 21.2728044 },
    { lat: 49.0184791, lng: 21.2730948 },
    { lat: 49.018279, lng: 21.2730655 },
    { lat: 49.0182895, lng: 21.2735567 },
    { lat: 49.017929, lng: 21.2736506 },
    { lat: 49.017929, lng: 21.2739833 },
    { lat: 49.0179962, lng: 21.2740073 },
    { lat: 49.0179938, lng: 21.2744842 },
    { lat: 49.0181059, lng: 21.2754669 },
    { lat: 49.0180501, lng: 21.2763273 },
    { lat: 49.0180019, lng: 21.2762597 },
    { lat: 49.0177962, lng: 21.2787361 },
    { lat: 49.0176935, lng: 21.2795371 },
    { lat: 49.0177005, lng: 21.2808713 },
    { lat: 49.0175345, lng: 21.2813666 },
    { lat: 49.0162854, lng: 21.2810711 },
    { lat: 49.0163025, lng: 21.2808953 },
    { lat: 49.0151055, lng: 21.2807528 },
    { lat: 49.0150882, lng: 21.2809687 },
    { lat: 49.0148437, lng: 21.2809544 },
    { lat: 49.0147339, lng: 21.2820763 },
    { lat: 49.0146781, lng: 21.2833475 },
    { lat: 49.0145879, lng: 21.2833431 },
    { lat: 49.0146605, lng: 21.2850612 },
    { lat: 49.0144622, lng: 21.2850852 },
    { lat: 49.0144247, lng: 21.285851 },
    { lat: 49.014728, lng: 21.287867 },
    { lat: 49.0146912, lng: 21.2892827 },
    { lat: 49.0148943, lng: 21.2911142 },
    { lat: 49.014868, lng: 21.2916535 },
    { lat: 49.0149426, lng: 21.292438 },
    { lat: 49.0149196, lng: 21.2931329 },
    { lat: 49.0149721, lng: 21.2931919 },
    { lat: 49.0148808, lng: 21.2941462 },
    { lat: 49.0149515, lng: 21.2945076 },
    { lat: 49.0148956, lng: 21.2946022 },
    { lat: 49.014917, lng: 21.2948946 },
    { lat: 49.01478, lng: 21.2951663 },
    { lat: 49.0147762, lng: 21.2953372 },
    { lat: 49.0148573, lng: 21.2954509 },
    { lat: 49.0148387, lng: 21.2957257 },
    { lat: 49.0146649, lng: 21.2959634 },
    { lat: 49.0144504, lng: 21.2961297 },
    { lat: 49.0143339, lng: 21.2963301 },
    { lat: 49.0141751, lng: 21.2970342 },
    { lat: 49.014394, lng: 21.2976114 },
    { lat: 49.0143078, lng: 21.2980651 },
    { lat: 49.0142284, lng: 21.2981904 },
    { lat: 49.0142142, lng: 21.2988023 },
    { lat: 49.0145187, lng: 21.3026084 },
    { lat: 49.0145196, lng: 21.3026378 },
    { lat: 49.0159606, lng: 21.3025156 },
    { lat: 49.0175932, lng: 21.3024679 },
    { lat: 49.0183873, lng: 21.3033924 },
    { lat: 49.0185079, lng: 21.303317 },
    { lat: 49.018737, lng: 21.3034146 },
    { lat: 49.02177, lng: 21.3038438 },
    { lat: 49.0279823, lng: 21.3043911 },
    { lat: 49.0336032, lng: 21.3177691 },
    { lat: 49.0346352, lng: 21.3173223 },
    { lat: 49.0365219, lng: 21.316345 },
    { lat: 49.0367449, lng: 21.31639 },
    { lat: 49.0376392, lng: 21.3158233 },
    { lat: 49.0384069, lng: 21.315386 },
    { lat: 49.0380471, lng: 21.3149111 },
    { lat: 49.0375082, lng: 21.3138366 },
    { lat: 49.0375119, lng: 21.3136531 },
    { lat: 49.0377931, lng: 21.3126117 },
    { lat: 49.037534, lng: 21.3117472 },
    { lat: 49.037912, lng: 21.3116709 },
    { lat: 49.0378899, lng: 21.311118 },
    { lat: 49.0371501, lng: 21.3066003 },
    { lat: 49.0367981, lng: 21.303933 },
    { lat: 49.0375604, lng: 21.3028718 },
    { lat: 49.036717, lng: 21.3020419 },
    { lat: 49.0380766, lng: 21.300191 },
    { lat: 49.0386876, lng: 21.2995241 },
    { lat: 49.0392821, lng: 21.2992776 },
    { lat: 49.0396917, lng: 21.2988651 },
    { lat: 49.0401398, lng: 21.2988075 },
    { lat: 49.0406542, lng: 21.2985468 },
    { lat: 49.040267, lng: 21.2973574 },
    { lat: 49.0398266, lng: 21.296638 },
    { lat: 49.0379982, lng: 21.2944919 },
    { lat: 49.0385387, lng: 21.2935454 },
    { lat: 49.0389254, lng: 21.2927142 },
    { lat: 49.0396128, lng: 21.2918241 },
    { lat: 49.039691, lng: 21.2915438 },
    { lat: 49.0401759, lng: 21.2907619 },
    { lat: 49.0403814, lng: 21.2905656 },
    { lat: 49.0404113, lng: 21.290362 },
    { lat: 49.0406519, lng: 21.2896607 },
    { lat: 49.039237, lng: 21.288119 },
    { lat: 49.0383511, lng: 21.2874454 },
    { lat: 49.0380859, lng: 21.2873084 },
    { lat: 49.0390306, lng: 21.2830851 },
    { lat: 49.0391576, lng: 21.2822588 },
    { lat: 49.0392927, lng: 21.2818271 },
    { lat: 49.040377, lng: 21.2818859 },
    { lat: 49.040383, lng: 21.2816702 },
  ],
  Drienov: [
    { lat: 48.8883655, lng: 21.252719 },
    { lat: 48.8882877, lng: 21.254319 },
    { lat: 48.8839998, lng: 21.2560595 },
    { lat: 48.883419, lng: 21.2508742 },
    { lat: 48.8791023, lng: 21.2517491 },
    { lat: 48.8779797, lng: 21.2520975 },
    { lat: 48.8780136, lng: 21.2522081 },
    { lat: 48.8780152, lng: 21.2527747 },
    { lat: 48.8775835, lng: 21.2548642 },
    { lat: 48.8748374, lng: 21.2582826 },
    { lat: 48.873657, lng: 21.25956 },
    { lat: 48.873688, lng: 21.260105 },
    { lat: 48.872588, lng: 21.260841 },
    { lat: 48.872566, lng: 21.260853 },
    { lat: 48.871877, lng: 21.261306 },
    { lat: 48.87167, lng: 21.261412 },
    { lat: 48.870593, lng: 21.261723 },
    { lat: 48.870298, lng: 21.261504 },
    { lat: 48.870132, lng: 21.261483 },
    { lat: 48.870027, lng: 21.26147 },
    { lat: 48.869882, lng: 21.261451 },
    { lat: 48.869015, lng: 21.261426 },
    { lat: 48.868688, lng: 21.261497 },
    { lat: 48.868233, lng: 21.26179 },
    { lat: 48.867986, lng: 21.26218 },
    { lat: 48.867928, lng: 21.262273 },
    { lat: 48.867943, lng: 21.262948 },
    { lat: 48.867778, lng: 21.263838 },
    { lat: 48.867776, lng: 21.264139 },
    { lat: 48.867803, lng: 21.264391 },
    { lat: 48.867565, lng: 21.264866 },
    { lat: 48.867089, lng: 21.265197 },
    { lat: 48.866657, lng: 21.265284 },
    { lat: 48.866027, lng: 21.265184 },
    { lat: 48.865371, lng: 21.26456 },
    { lat: 48.865181, lng: 21.264652 },
    { lat: 48.86469, lng: 21.265384 },
    { lat: 48.864739, lng: 21.266014 },
    { lat: 48.864445, lng: 21.265773 },
    { lat: 48.864144, lng: 21.265744 },
    { lat: 48.863576, lng: 21.265449 },
    { lat: 48.863196, lng: 21.265385 },
    { lat: 48.863069, lng: 21.265457 },
    { lat: 48.862624, lng: 21.264821 },
    { lat: 48.862297, lng: 21.263849 },
    { lat: 48.862005, lng: 21.263272 },
    { lat: 48.861697, lng: 21.263314 },
    { lat: 48.861131, lng: 21.263391 },
    { lat: 48.860861, lng: 21.263652 },
    { lat: 48.860678, lng: 21.264051 },
    { lat: 48.860552, lng: 21.264594 },
    { lat: 48.860569, lng: 21.265121 },
    { lat: 48.860662, lng: 21.266057 },
    { lat: 48.860692, lng: 21.266363 },
    { lat: 48.860633, lng: 21.266611 },
    { lat: 48.860386, lng: 21.266626 },
    { lat: 48.860329, lng: 21.26663 },
    { lat: 48.859716, lng: 21.266667 },
    { lat: 48.8589756, lng: 21.2676039 },
    { lat: 48.8588285, lng: 21.2679799 },
    { lat: 48.8588066, lng: 21.2688413 },
    { lat: 48.8589265, lng: 21.269336 },
    { lat: 48.8588658, lng: 21.2695636 },
    { lat: 48.8585002, lng: 21.2700447 },
    { lat: 48.8583429, lng: 21.2703846 },
    { lat: 48.8582549, lng: 21.2704523 },
    { lat: 48.8581247, lng: 21.2709725 },
    { lat: 48.8584033, lng: 21.2717984 },
    { lat: 48.8585579, lng: 21.2728775 },
    { lat: 48.8583864, lng: 21.2732984 },
    { lat: 48.8577758, lng: 21.2742359 },
    { lat: 48.8576761, lng: 21.2747994 },
    { lat: 48.8577278, lng: 21.2753472 },
    { lat: 48.8579265, lng: 21.2756273 },
    { lat: 48.8582592, lng: 21.2757969 },
    { lat: 48.8588344, lng: 21.2756599 },
    { lat: 48.8596613, lng: 21.2748401 },
    { lat: 48.8599614, lng: 21.2747239 },
    { lat: 48.8607102, lng: 21.2749582 },
    { lat: 48.8609108, lng: 21.2751467 },
    { lat: 48.8609043, lng: 21.2755571 },
    { lat: 48.860755, lng: 21.2760122 },
    { lat: 48.8603644, lng: 21.2764449 },
    { lat: 48.8602551, lng: 21.2768901 },
    { lat: 48.860255, lng: 21.2780545 },
    { lat: 48.8605655, lng: 21.2790862 },
    { lat: 48.861214, lng: 21.2807504 },
    { lat: 48.8611449, lng: 21.2811041 },
    { lat: 48.8610501, lng: 21.2813105 },
    { lat: 48.8608168, lng: 21.2815245 },
    { lat: 48.8599428, lng: 21.281274 },
    { lat: 48.8596422, lng: 21.2812704 },
    { lat: 48.8593891, lng: 21.281498 },
    { lat: 48.8592983, lng: 21.2823549 },
    { lat: 48.8593483, lng: 21.2835476 },
    { lat: 48.860235, lng: 21.2851229 },
    { lat: 48.8608207, lng: 21.2855456 },
    { lat: 48.8607769, lng: 21.2858797 },
    { lat: 48.8605637, lng: 21.2862338 },
    { lat: 48.8597681, lng: 21.2866055 },
    { lat: 48.8591776, lng: 21.2865433 },
    { lat: 48.8589209, lng: 21.2867525 },
    { lat: 48.8587181, lng: 21.2870384 },
    { lat: 48.8590324, lng: 21.287532 },
    { lat: 48.85922, lng: 21.2875214 },
    { lat: 48.8594594, lng: 21.2881035 },
    { lat: 48.8594823, lng: 21.2883743 },
    { lat: 48.8594365, lng: 21.2886795 },
    { lat: 48.8589113, lng: 21.2894271 },
    { lat: 48.858661, lng: 21.2894865 },
    { lat: 48.8584724, lng: 21.2893492 },
    { lat: 48.8583201, lng: 21.289119 },
    { lat: 48.8580991, lng: 21.2884377 },
    { lat: 48.8580552, lng: 21.2878493 },
    { lat: 48.8579707, lng: 21.2877366 },
    { lat: 48.8569609, lng: 21.2882384 },
    { lat: 48.8566696, lng: 21.2889835 },
    { lat: 48.8566376, lng: 21.2893335 },
    { lat: 48.8567168, lng: 21.2894948 },
    { lat: 48.8573826, lng: 21.2895929 },
    { lat: 48.857656, lng: 21.2900751 },
    { lat: 48.8582065, lng: 21.2912991 },
    { lat: 48.8581832, lng: 21.2919733 },
    { lat: 48.8578894, lng: 21.2924821 },
    { lat: 48.8575331, lng: 21.2928605 },
    { lat: 48.8569275, lng: 21.2933006 },
    { lat: 48.8566415, lng: 21.2932931 },
    { lat: 48.8563381, lng: 21.2930514 },
    { lat: 48.8560788, lng: 21.2926671 },
    { lat: 48.855965, lng: 21.2925908 },
    { lat: 48.8558754, lng: 21.2926767 },
    { lat: 48.8560174, lng: 21.292903 },
    { lat: 48.8559992, lng: 21.2931966 },
    { lat: 48.8559046, lng: 21.2935581 },
    { lat: 48.8558478, lng: 21.2943554 },
    { lat: 48.8556905, lng: 21.2949189 },
    { lat: 48.8555139, lng: 21.2953218 },
    { lat: 48.8552308, lng: 21.2955964 },
    { lat: 48.8544351, lng: 21.2954066 },
    { lat: 48.8542349, lng: 21.2951205 },
    { lat: 48.8541578, lng: 21.2948042 },
    { lat: 48.8541431, lng: 21.2945069 },
    { lat: 48.8540181, lng: 21.2944685 },
    { lat: 48.853597, lng: 21.2951597 },
    { lat: 48.8534391, lng: 21.2949986 },
    { lat: 48.8521935, lng: 21.2951147 },
    { lat: 48.8519882, lng: 21.2949154 },
    { lat: 48.8518336, lng: 21.2942776 },
    { lat: 48.8517884, lng: 21.2938238 },
    { lat: 48.8519657, lng: 21.2934029 },
    { lat: 48.8516431, lng: 21.2935981 },
    { lat: 48.8506398, lng: 21.2942426 },
    { lat: 48.8506815, lng: 21.2955403 },
    { lat: 48.8504248, lng: 21.2968267 },
    { lat: 48.8515224, lng: 21.2996496 },
    { lat: 48.8533562, lng: 21.3040321 },
    { lat: 48.853294, lng: 21.304283 },
    { lat: 48.8541732, lng: 21.3095896 },
    { lat: 48.8551401, lng: 21.3096676 },
    { lat: 48.8561281, lng: 21.3094494 },
    { lat: 48.8568837, lng: 21.3092044 },
    { lat: 48.8571909, lng: 21.3093588 },
    { lat: 48.8576566, lng: 21.3093896 },
    { lat: 48.8578687, lng: 21.3095553 },
    { lat: 48.8586128, lng: 21.3095216 },
    { lat: 48.8591637, lng: 21.3093993 },
    { lat: 48.859718, lng: 21.3094568 },
    { lat: 48.8607106, lng: 21.3098668 },
    { lat: 48.862141, lng: 21.309905 },
    { lat: 48.8661075, lng: 21.3094927 },
    { lat: 48.868249, lng: 21.3094596 },
    { lat: 48.8699145, lng: 21.3101284 },
    { lat: 48.8710428, lng: 21.3107478 },
    { lat: 48.8715216, lng: 21.3108757 },
    { lat: 48.8728402, lng: 21.3107212 },
    { lat: 48.8739391, lng: 21.3109628 },
    { lat: 48.8756112, lng: 21.3109638 },
    { lat: 48.8759325, lng: 21.3110919 },
    { lat: 48.8766243, lng: 21.311458 },
    { lat: 48.8774817, lng: 21.3114877 },
    { lat: 48.8788192, lng: 21.3112232 },
    { lat: 48.8815968, lng: 21.3121597 },
    { lat: 48.8855367, lng: 21.3139529 },
    { lat: 48.8864766, lng: 21.3142947 },
    { lat: 48.8883441, lng: 21.3146109 },
    { lat: 48.8902554, lng: 21.3154504 },
    { lat: 48.8913256, lng: 21.3157488 },
    { lat: 48.8925055, lng: 21.3162156 },
    { lat: 48.8933742, lng: 21.3143542 },
    { lat: 48.8936466, lng: 21.3136164 },
    { lat: 48.8940634, lng: 21.3120703 },
    { lat: 48.8947161, lng: 21.3106655 },
    { lat: 48.8950341, lng: 21.3102785 },
    { lat: 48.8963652, lng: 21.3092694 },
    { lat: 48.8965765, lng: 21.3088191 },
    { lat: 48.8968669, lng: 21.3084498 },
    { lat: 48.8971792, lng: 21.3081737 },
    { lat: 48.8982818, lng: 21.3079079 },
    { lat: 48.8986262, lng: 21.3076106 },
    { lat: 48.8988572, lng: 21.3071417 },
    { lat: 48.8992545, lng: 21.3069208 },
    { lat: 48.9006281, lng: 21.3039028 },
    { lat: 48.9017009, lng: 21.3028048 },
    { lat: 48.9028742, lng: 21.3013911 },
    { lat: 48.9032671, lng: 21.3010979 },
    { lat: 48.9037475, lng: 21.3002456 },
    { lat: 48.904349, lng: 21.2987494 },
    { lat: 48.9047737, lng: 21.2978366 },
    { lat: 48.9048273, lng: 21.2975266 },
    { lat: 48.9050917, lng: 21.2972259 },
    { lat: 48.9052955, lng: 21.2971218 },
    { lat: 48.9057521, lng: 21.2953035 },
    { lat: 48.9060252, lng: 21.294663 },
    { lat: 48.9065122, lng: 21.2932867 },
    { lat: 48.9067654, lng: 21.2928447 },
    { lat: 48.9069066, lng: 21.2923845 },
    { lat: 48.9070665, lng: 21.2915358 },
    { lat: 48.9071585, lng: 21.2900499 },
    { lat: 48.9071097, lng: 21.2894094 },
    { lat: 48.9066392, lng: 21.2881922 },
    { lat: 48.9062863, lng: 21.2876879 },
    { lat: 48.9062395, lng: 21.2873743 },
    { lat: 48.9054381, lng: 21.2867366 },
    { lat: 48.9045271, lng: 21.2861993 },
    { lat: 48.9037732, lng: 21.2855422 },
    { lat: 48.9036136, lng: 21.2850287 },
    { lat: 48.903431, lng: 21.284946 },
    { lat: 48.9033479, lng: 21.2847286 },
    { lat: 48.9033056, lng: 21.2840845 },
    { lat: 48.9031708, lng: 21.2840175 },
    { lat: 48.90298, lng: 21.2837376 },
    { lat: 48.9029575, lng: 21.2835181 },
    { lat: 48.9028423, lng: 21.2834307 },
    { lat: 48.9028583, lng: 21.2831828 },
    { lat: 48.9027907, lng: 21.2830316 },
    { lat: 48.9027021, lng: 21.282493 },
    { lat: 48.9022282, lng: 21.2818929 },
    { lat: 48.9021246, lng: 21.2815597 },
    { lat: 48.9021345, lng: 21.2812571 },
    { lat: 48.901974, lng: 21.2810906 },
    { lat: 48.9019126, lng: 21.2807709 },
    { lat: 48.9021245, lng: 21.2803067 },
    { lat: 48.9020903, lng: 21.2796653 },
    { lat: 48.9021381, lng: 21.2792084 },
    { lat: 48.9021029, lng: 21.2787197 },
    { lat: 48.9021667, lng: 21.2783769 },
    { lat: 48.9021365, lng: 21.2774226 },
    { lat: 48.9019916, lng: 21.2771781 },
    { lat: 48.9018148, lng: 21.2760728 },
    { lat: 48.9017233, lng: 21.275062 },
    { lat: 48.9016946, lng: 21.2741662 },
    { lat: 48.9012352, lng: 21.272687 },
    { lat: 48.9011077, lng: 21.2716126 },
    { lat: 48.900981, lng: 21.2709695 },
    { lat: 48.9009204, lng: 21.2695777 },
    { lat: 48.9008011, lng: 21.2691341 },
    { lat: 48.9009086, lng: 21.2679476 },
    { lat: 48.9011143, lng: 21.2673139 },
    { lat: 48.9011766, lng: 21.2668162 },
    { lat: 48.9011043, lng: 21.2662208 },
    { lat: 48.9004287, lng: 21.2654373 },
    { lat: 48.9003849, lng: 21.2651457 },
    { lat: 48.9005307, lng: 21.2650198 },
    { lat: 48.9006409, lng: 21.2645446 },
    { lat: 48.9005412, lng: 21.2638252 },
    { lat: 48.9006303, lng: 21.2635395 },
    { lat: 48.9005318, lng: 21.2631505 },
    { lat: 48.9004542, lng: 21.2623824 },
    { lat: 48.9009491, lng: 21.2611291 },
    { lat: 48.9010883, lng: 21.2595462 },
    { lat: 48.90113, lng: 21.2585263 },
    { lat: 48.9013394, lng: 21.2577214 },
    { lat: 48.9011965, lng: 21.257283 },
    { lat: 48.9012251, lng: 21.2569326 },
    { lat: 48.9013609, lng: 21.2566343 },
    { lat: 48.9017121, lng: 21.256231 },
    { lat: 48.9018862, lng: 21.2555984 },
    { lat: 48.9022182, lng: 21.2555266 },
    { lat: 48.9023143, lng: 21.2559068 },
    { lat: 48.9026984, lng: 21.2554067 },
    { lat: 48.9032367, lng: 21.2559348 },
    { lat: 48.9035669, lng: 21.2535031 },
    { lat: 48.902394, lng: 21.2504292 },
    { lat: 48.9010063, lng: 21.2505291 },
    { lat: 48.9003408, lng: 21.2502973 },
    { lat: 48.8999359, lng: 21.2504137 },
    { lat: 48.8997374, lng: 21.2506527 },
    { lat: 48.8996644, lng: 21.2509523 },
    { lat: 48.8997671, lng: 21.251577 },
    { lat: 48.8987452, lng: 21.2516487 },
    { lat: 48.8985192, lng: 21.2518379 },
    { lat: 48.8985487, lng: 21.2525722 },
    { lat: 48.8987654, lng: 21.2530705 },
    { lat: 48.8988618, lng: 21.2534946 },
    { lat: 48.8990869, lng: 21.2538306 },
    { lat: 48.8990157, lng: 21.2542202 },
    { lat: 48.898752, lng: 21.2545325 },
    { lat: 48.898406, lng: 21.2546296 },
    { lat: 48.8978531, lng: 21.2543244 },
    { lat: 48.8974768, lng: 21.2537995 },
    { lat: 48.8970174, lng: 21.2534619 },
    { lat: 48.8965631, lng: 21.2533968 },
    { lat: 48.8950251, lng: 21.2534482 },
    { lat: 48.8946961, lng: 21.2532098 },
    { lat: 48.8942594, lng: 21.2519325 },
    { lat: 48.8940999, lng: 21.250813 },
    { lat: 48.8938401, lng: 21.2500521 },
    { lat: 48.893519, lng: 21.2495642 },
    { lat: 48.8931527, lng: 21.249509 },
    { lat: 48.8919545, lng: 21.2501419 },
    { lat: 48.8916975, lng: 21.2505635 },
    { lat: 48.8913571, lng: 21.2509789 },
    { lat: 48.8883655, lng: 21.252719 },
  ],
  Kapušany: [
    { lat: 49.0586111, lng: 21.3138515 },
    { lat: 49.0586309, lng: 21.3138358 },
    { lat: 49.0596792, lng: 21.313375 },
    { lat: 49.0603748, lng: 21.3120795 },
    { lat: 49.0611465, lng: 21.3113582 },
    { lat: 49.0614973, lng: 21.3108679 },
    { lat: 49.0621521, lng: 21.3104231 },
    { lat: 49.0625506, lng: 21.3100152 },
    { lat: 49.0630735, lng: 21.3093736 },
    { lat: 49.0637355, lng: 21.3083412 },
    { lat: 49.0634051, lng: 21.3072807 },
    { lat: 49.0631505, lng: 21.3067442 },
    { lat: 49.0630193, lng: 21.3066149 },
    { lat: 49.0626233, lng: 21.305911 },
    { lat: 49.0598228, lng: 21.3015619 },
    { lat: 49.0595219, lng: 21.3019608 },
    { lat: 49.0592921, lng: 21.302842 },
    { lat: 49.0592226, lng: 21.3036682 },
    { lat: 49.0589561, lng: 21.3053488 },
    { lat: 49.0588269, lng: 21.3057618 },
    { lat: 49.0590579, lng: 21.3071496 },
    { lat: 49.0588386, lng: 21.3076382 },
    { lat: 49.058299, lng: 21.3078382 },
    { lat: 49.0579629, lng: 21.3080379 },
    { lat: 49.0572312, lng: 21.3091048 },
    { lat: 49.0570126, lng: 21.3092432 },
    { lat: 49.056808, lng: 21.3095517 },
    { lat: 49.0563128, lng: 21.3099925 },
    { lat: 49.0561225, lng: 21.3100635 },
    { lat: 49.0559173, lng: 21.3104969 },
    { lat: 49.0556827, lng: 21.3108101 },
    { lat: 49.0554518, lng: 21.3109866 },
    { lat: 49.0546678, lng: 21.311299 },
    { lat: 49.0541664, lng: 21.3111231 },
    { lat: 49.0538612, lng: 21.3101529 },
    { lat: 49.0535231, lng: 21.310131 },
    { lat: 49.0518703, lng: 21.309649 },
    { lat: 49.05128, lng: 21.3096709 },
    { lat: 49.0509223, lng: 21.3096005 },
    { lat: 49.0485447, lng: 21.3088923 },
    { lat: 49.0482316, lng: 21.3088802 },
    { lat: 49.0476141, lng: 21.308702 },
    { lat: 49.04745, lng: 21.3087483 },
    { lat: 49.0471948, lng: 21.3084968 },
    { lat: 49.047318, lng: 21.3082105 },
    { lat: 49.047688, lng: 21.3077226 },
    { lat: 49.0477914, lng: 21.3076856 },
    { lat: 49.0479499, lng: 21.3074553 },
    { lat: 49.0480847, lng: 21.3073738 },
    { lat: 49.0484617, lng: 21.3064449 },
    { lat: 49.0485184, lng: 21.306052 },
    { lat: 49.0487338, lng: 21.3058443 },
    { lat: 49.0488625, lng: 21.3049238 },
    { lat: 49.0483818, lng: 21.303246 },
    { lat: 49.0482766, lng: 21.3019287 },
    { lat: 49.0481148, lng: 21.3005955 },
    { lat: 49.0479148, lng: 21.3000883 },
    { lat: 49.0476144, lng: 21.2987524 },
    { lat: 49.0470544, lng: 21.2980298 },
    { lat: 49.0469841, lng: 21.2977087 },
    { lat: 49.0469787, lng: 21.2974221 },
    { lat: 49.0474777, lng: 21.2973322 },
    { lat: 49.0473613, lng: 21.2969074 },
    { lat: 49.0473646, lng: 21.2966047 },
    { lat: 49.0472849, lng: 21.296502 },
    { lat: 49.0464584, lng: 21.2939985 },
    { lat: 49.0466289, lng: 21.2926826 },
    { lat: 49.0464046, lng: 21.2924473 },
    { lat: 49.0461609, lng: 21.2923479 },
    { lat: 49.0461248, lng: 21.2920925 },
    { lat: 49.0461783, lng: 21.2919592 },
    { lat: 49.045751, lng: 21.2911603 },
    { lat: 49.0457859, lng: 21.291013 },
    { lat: 49.0456788, lng: 21.2904512 },
    { lat: 49.0454944, lng: 21.2902343 },
    { lat: 49.0453901, lng: 21.2902075 },
    { lat: 49.0453521, lng: 21.2901073 },
    { lat: 49.0454086, lng: 21.2899623 },
    { lat: 49.0451827, lng: 21.2892357 },
    { lat: 49.0449777, lng: 21.2888186 },
    { lat: 49.0447484, lng: 21.288786 },
    { lat: 49.0443651, lng: 21.2883542 },
    { lat: 49.0441637, lng: 21.2882141 },
    { lat: 49.0438881, lng: 21.2878809 },
    { lat: 49.0436898, lng: 21.2875018 },
    { lat: 49.0436718, lng: 21.2871372 },
    { lat: 49.043711, lng: 21.2867571 },
    { lat: 49.0438538, lng: 21.2859791 },
    { lat: 49.0437519, lng: 21.2857703 },
    { lat: 49.0432267, lng: 21.2855182 },
    { lat: 49.0430863, lng: 21.2853071 },
    { lat: 49.0425276, lng: 21.2841116 },
    { lat: 49.0422427, lng: 21.2836592 },
    { lat: 49.0418238, lng: 21.2832074 },
    { lat: 49.0415504, lng: 21.2826144 },
    { lat: 49.0413688, lng: 21.2825282 },
    { lat: 49.0412774, lng: 21.2825537 },
    { lat: 49.0409924, lng: 21.2823561 },
    { lat: 49.0407438, lng: 21.2819593 },
    { lat: 49.0405852, lng: 21.2818457 },
    { lat: 49.040383, lng: 21.2816702 },
    { lat: 49.040377, lng: 21.2818859 },
    { lat: 49.0392927, lng: 21.2818271 },
    { lat: 49.0391576, lng: 21.2822588 },
    { lat: 49.0390306, lng: 21.2830851 },
    { lat: 49.0380859, lng: 21.2873084 },
    { lat: 49.0383511, lng: 21.2874454 },
    { lat: 49.039237, lng: 21.288119 },
    { lat: 49.0406519, lng: 21.2896607 },
    { lat: 49.0404113, lng: 21.290362 },
    { lat: 49.0403814, lng: 21.2905656 },
    { lat: 49.0401759, lng: 21.2907619 },
    { lat: 49.039691, lng: 21.2915438 },
    { lat: 49.0396128, lng: 21.2918241 },
    { lat: 49.0389254, lng: 21.2927142 },
    { lat: 49.0385387, lng: 21.2935454 },
    { lat: 49.0379982, lng: 21.2944919 },
    { lat: 49.0398266, lng: 21.296638 },
    { lat: 49.040267, lng: 21.2973574 },
    { lat: 49.0406542, lng: 21.2985468 },
    { lat: 49.0401398, lng: 21.2988075 },
    { lat: 49.0396917, lng: 21.2988651 },
    { lat: 49.0392821, lng: 21.2992776 },
    { lat: 49.0386876, lng: 21.2995241 },
    { lat: 49.0380766, lng: 21.300191 },
    { lat: 49.036717, lng: 21.3020419 },
    { lat: 49.0375604, lng: 21.3028718 },
    { lat: 49.0367981, lng: 21.303933 },
    { lat: 49.0371501, lng: 21.3066003 },
    { lat: 49.0378899, lng: 21.311118 },
    { lat: 49.037912, lng: 21.3116709 },
    { lat: 49.037534, lng: 21.3117472 },
    { lat: 49.0377931, lng: 21.3126117 },
    { lat: 49.0375119, lng: 21.3136531 },
    { lat: 49.0375082, lng: 21.3138366 },
    { lat: 49.0380471, lng: 21.3149111 },
    { lat: 49.0384069, lng: 21.315386 },
    { lat: 49.0385712, lng: 21.3156797 },
    { lat: 49.0379542, lng: 21.3161915 },
    { lat: 49.0375475, lng: 21.3166234 },
    { lat: 49.0380705, lng: 21.318374 },
    { lat: 49.0381503, lng: 21.3183691 },
    { lat: 49.0383482, lng: 21.3188865 },
    { lat: 49.0383692, lng: 21.319319 },
    { lat: 49.0384665, lng: 21.3199011 },
    { lat: 49.0386993, lng: 21.3206771 },
    { lat: 49.038637, lng: 21.3207765 },
    { lat: 49.0383245, lng: 21.3209701 },
    { lat: 49.0384766, lng: 21.3215028 },
    { lat: 49.0383285, lng: 21.3217057 },
    { lat: 49.0381973, lng: 21.3220947 },
    { lat: 49.0384541, lng: 21.3231057 },
    { lat: 49.0381605, lng: 21.3232607 },
    { lat: 49.0381719, lng: 21.3234905 },
    { lat: 49.0384273, lng: 21.3247208 },
    { lat: 49.0382718, lng: 21.3248263 },
    { lat: 49.038865, lng: 21.3293624 },
    { lat: 49.0385162, lng: 21.3294702 },
    { lat: 49.0389983, lng: 21.3319224 },
    { lat: 49.0389253, lng: 21.3319652 },
    { lat: 49.0386041, lng: 21.3316471 },
    { lat: 49.0384566, lng: 21.331605 },
    { lat: 49.0383906, lng: 21.3321081 },
    { lat: 49.0381503, lng: 21.3321628 },
    { lat: 49.0377573, lng: 21.3320783 },
    { lat: 49.0375222, lng: 21.3318873 },
    { lat: 49.0373437, lng: 21.332688 },
    { lat: 49.0369444, lng: 21.334042 },
    { lat: 49.0369415, lng: 21.3344715 },
    { lat: 49.0365516, lng: 21.3345301 },
    { lat: 49.0358406, lng: 21.3335621 },
    { lat: 49.0358574, lng: 21.3343797 },
    { lat: 49.0344261, lng: 21.3346437 },
    { lat: 49.0344161, lng: 21.3359598 },
    { lat: 49.0345764, lng: 21.3359372 },
    { lat: 49.0351652, lng: 21.3366189 },
    { lat: 49.0352832, lng: 21.3366566 },
    { lat: 49.035359, lng: 21.3371549 },
    { lat: 49.03492, lng: 21.3376748 },
    { lat: 49.034503, lng: 21.3383306 },
    { lat: 49.0342235, lng: 21.3385006 },
    { lat: 49.0339783, lng: 21.3388382 },
    { lat: 49.0336128, lng: 21.339072 },
    { lat: 49.0334191, lng: 21.3391008 },
    { lat: 49.0330052, lng: 21.3393741 },
    { lat: 49.032896, lng: 21.3394835 },
    { lat: 49.0328295, lng: 21.3397015 },
    { lat: 49.0325625, lng: 21.3397318 },
    { lat: 49.0318335, lng: 21.3402604 },
    { lat: 49.0316703, lng: 21.3404844 },
    { lat: 49.0314218, lng: 21.3416896 },
    { lat: 49.0310273, lng: 21.3420831 },
    { lat: 49.030823, lng: 21.3424741 },
    { lat: 49.0306957, lng: 21.3424324 },
    { lat: 49.0301117, lng: 21.3436325 },
    { lat: 49.0301321, lng: 21.3437863 },
    { lat: 49.0300245, lng: 21.3442542 },
    { lat: 49.029492, lng: 21.3447701 },
    { lat: 49.0293523, lng: 21.3453654 },
    { lat: 49.0290979, lng: 21.3455881 },
    { lat: 49.0289806, lng: 21.3455037 },
    { lat: 49.0289268, lng: 21.3457394 },
    { lat: 49.028713, lng: 21.3460006 },
    { lat: 49.0285031, lng: 21.3464342 },
    { lat: 49.0283727, lng: 21.3463938 },
    { lat: 49.0281894, lng: 21.3464366 },
    { lat: 49.0277712, lng: 21.3468252 },
    { lat: 49.0273218, lng: 21.3471112 },
    { lat: 49.0272303, lng: 21.3471021 },
    { lat: 49.0273995, lng: 21.3473429 },
    { lat: 49.0273747, lng: 21.3475629 },
    { lat: 49.026922, lng: 21.347856 },
    { lat: 49.0267655, lng: 21.3480361 },
    { lat: 49.0266671, lng: 21.348319 },
    { lat: 49.0265033, lng: 21.3485275 },
    { lat: 49.026777, lng: 21.34887 },
    { lat: 49.0270029, lng: 21.35101 },
    { lat: 49.0268893, lng: 21.3530451 },
    { lat: 49.0285012, lng: 21.352127 },
    { lat: 49.0288125, lng: 21.3518111 },
    { lat: 49.0288318, lng: 21.3515107 },
    { lat: 49.0294309, lng: 21.3513753 },
    { lat: 49.0297749, lng: 21.3518561 },
    { lat: 49.0301685, lng: 21.3520006 },
    { lat: 49.0306603, lng: 21.3526472 },
    { lat: 49.0309819, lng: 21.3533569 },
    { lat: 49.0356395, lng: 21.350244 },
    { lat: 49.0373287, lng: 21.3487479 },
    { lat: 49.038921, lng: 21.3526301 },
    { lat: 49.039175, lng: 21.3531871 },
    { lat: 49.0411337, lng: 21.3525715 },
    { lat: 49.0413191, lng: 21.3528259 },
    { lat: 49.0415605, lng: 21.3529189 },
    { lat: 49.0420712, lng: 21.3528413 },
    { lat: 49.0446045, lng: 21.3519194 },
    { lat: 49.0472025, lng: 21.3505963 },
    { lat: 49.0472285, lng: 21.350069 },
    { lat: 49.0491616, lng: 21.3502646 },
    { lat: 49.049166, lng: 21.3506018 },
    { lat: 49.0489217, lng: 21.3524786 },
    { lat: 49.0490444, lng: 21.3525925 },
    { lat: 49.0489394, lng: 21.353291 },
    { lat: 49.049297, lng: 21.3536698 },
    { lat: 49.0491805, lng: 21.3540624 },
    { lat: 49.0492327, lng: 21.3546417 },
    { lat: 49.0506776, lng: 21.3560037 },
    { lat: 49.0513613, lng: 21.3546719 },
    { lat: 49.051721, lng: 21.3537129 },
    { lat: 49.0519815, lng: 21.3532056 },
    { lat: 49.0521912, lng: 21.3526551 },
    { lat: 49.0524218, lng: 21.3518984 },
    { lat: 49.0525859, lng: 21.3506921 },
    { lat: 49.0527681, lng: 21.3502321 },
    { lat: 49.0544152, lng: 21.3518449 },
    { lat: 49.0551362, lng: 21.3528171 },
    { lat: 49.0567291, lng: 21.3538582 },
    { lat: 49.0570544, lng: 21.3542021 },
    { lat: 49.0574886, lng: 21.3549172 },
    { lat: 49.0583365, lng: 21.3567447 },
    { lat: 49.0594545, lng: 21.3601291 },
    { lat: 49.0597677, lng: 21.3606765 },
    { lat: 49.0598572, lng: 21.3609993 },
    { lat: 49.0605004, lng: 21.3623442 },
    { lat: 49.0611322, lng: 21.3632271 },
    { lat: 49.0626068, lng: 21.3648053 },
    { lat: 49.0627616, lng: 21.3633508 },
    { lat: 49.0632133, lng: 21.3606799 },
    { lat: 49.0634178, lng: 21.3589335 },
    { lat: 49.0634386, lng: 21.3574082 },
    { lat: 49.0640287, lng: 21.3577643 },
    { lat: 49.065083, lng: 21.3579506 },
    { lat: 49.0651706, lng: 21.3588147 },
    { lat: 49.0659221, lng: 21.3589993 },
    { lat: 49.0663046, lng: 21.3588107 },
    { lat: 49.0663194, lng: 21.3579068 },
    { lat: 49.0661976, lng: 21.3567243 },
    { lat: 49.0660473, lng: 21.3561429 },
    { lat: 49.065799, lng: 21.3547599 },
    { lat: 49.0663525, lng: 21.354637 },
    { lat: 49.0660838, lng: 21.35401 },
    { lat: 49.0659078, lng: 21.3534291 },
    { lat: 49.065998, lng: 21.3533539 },
    { lat: 49.0660692, lng: 21.3531398 },
    { lat: 49.0659544, lng: 21.3522011 },
    { lat: 49.0658804, lng: 21.3509242 },
    { lat: 49.0656486, lng: 21.3494948 },
    { lat: 49.0656586, lng: 21.3488091 },
    { lat: 49.0658013, lng: 21.3482452 },
    { lat: 49.0661235, lng: 21.3476176 },
    { lat: 49.0663041, lng: 21.3470646 },
    { lat: 49.0669442, lng: 21.3459363 },
    { lat: 49.0654894, lng: 21.3437197 },
    { lat: 49.0653549, lng: 21.34385 },
    { lat: 49.0639524, lng: 21.3415 },
    { lat: 49.0631446, lng: 21.3398872 },
    { lat: 49.0633622, lng: 21.3388577 },
    { lat: 49.0631783, lng: 21.3389835 },
    { lat: 49.0628896, lng: 21.3361872 },
    { lat: 49.0628379, lng: 21.3361553 },
    { lat: 49.0627147, lng: 21.3364725 },
    { lat: 49.0624435, lng: 21.3365803 },
    { lat: 49.0623142, lng: 21.3367748 },
    { lat: 49.062038, lng: 21.3367172 },
    { lat: 49.0616124, lng: 21.3372734 },
    { lat: 49.0613683, lng: 21.3375014 },
    { lat: 49.0609907, lng: 21.3373629 },
    { lat: 49.0608236, lng: 21.3373792 },
    { lat: 49.0601174, lng: 21.3378821 },
    { lat: 49.0596261, lng: 21.3380113 },
    { lat: 49.0588187, lng: 21.3375055 },
    { lat: 49.058743, lng: 21.3366342 },
    { lat: 49.0586871, lng: 21.3364921 },
    { lat: 49.0582084, lng: 21.3359857 },
    { lat: 49.0579789, lng: 21.3356515 },
    { lat: 49.0572844, lng: 21.334289 },
    { lat: 49.0562158, lng: 21.3328037 },
    { lat: 49.0558367, lng: 21.3324365 },
    { lat: 49.0555491, lng: 21.3322603 },
    { lat: 49.055548, lng: 21.3320426 },
    { lat: 49.0556406, lng: 21.3318375 },
    { lat: 49.0560567, lng: 21.3309155 },
    { lat: 49.0562559, lng: 21.3303472 },
    { lat: 49.0569675, lng: 21.3283174 },
    { lat: 49.0577401, lng: 21.3263147 },
    { lat: 49.0578168, lng: 21.3261748 },
    { lat: 49.0581381, lng: 21.32601 },
    { lat: 49.0581607, lng: 21.3257107 },
    { lat: 49.0583628, lng: 21.3253586 },
    { lat: 49.0583625, lng: 21.3252384 },
    { lat: 49.058463, lng: 21.3249896 },
    { lat: 49.0585948, lng: 21.3248107 },
    { lat: 49.0588048, lng: 21.3247911 },
    { lat: 49.0589077, lng: 21.3247017 },
    { lat: 49.0590307, lng: 21.3247825 },
    { lat: 49.0592095, lng: 21.324708 },
    { lat: 49.0593779, lng: 21.3245057 },
    { lat: 49.059476, lng: 21.3242161 },
    { lat: 49.0597282, lng: 21.3237684 },
    { lat: 49.0564836, lng: 21.3183367 },
    { lat: 49.0568101, lng: 21.3176326 },
    { lat: 49.0571616, lng: 21.3170824 },
    { lat: 49.0576279, lng: 21.3156481 },
    { lat: 49.0579483, lng: 21.3150388 },
    { lat: 49.0583353, lng: 21.3140713 },
    { lat: 49.0586111, lng: 21.3138515 },
  ],
  Hendrichovce: [
    { lat: 49.0306555, lng: 20.9698886 },
    { lat: 49.030192, lng: 20.9704707 },
    { lat: 49.0289417, lng: 20.9722028 },
    { lat: 49.0288575, lng: 20.972588 },
    { lat: 49.0281422, lng: 20.973962 },
    { lat: 49.0275776, lng: 20.9745816 },
    { lat: 49.0264605, lng: 20.9763994 },
    { lat: 49.0265042, lng: 20.9766309 },
    { lat: 49.0263467, lng: 20.9768384 },
    { lat: 49.0261341, lng: 20.9773475 },
    { lat: 49.0256229, lng: 20.9780569 },
    { lat: 49.0234093, lng: 20.9804451 },
    { lat: 49.023023, lng: 20.9809799 },
    { lat: 49.0226324, lng: 20.9812849 },
    { lat: 49.0222996, lng: 20.98167 },
    { lat: 49.0219539, lng: 20.9822049 },
    { lat: 49.0208401, lng: 20.9834796 },
    { lat: 49.0201973, lng: 20.9840246 },
    { lat: 49.0201758, lng: 20.9838807 },
    { lat: 49.0200422, lng: 20.9840257 },
    { lat: 49.0193128, lng: 20.9841919 },
    { lat: 49.0189975, lng: 20.983137 },
    { lat: 49.0158923, lng: 20.9856544 },
    { lat: 49.0141996, lng: 20.9878027 },
    { lat: 49.0109097, lng: 20.9901874 },
    { lat: 49.0071002, lng: 20.9935421 },
    { lat: 49.007419, lng: 20.9943323 },
    { lat: 49.0077497, lng: 20.9946979 },
    { lat: 49.008121, lng: 20.995445 },
    { lat: 49.0084632, lng: 20.9963034 },
    { lat: 49.0083697, lng: 20.9972946 },
    { lat: 49.0083568, lng: 20.9983711 },
    { lat: 49.0087485, lng: 20.9996663 },
    { lat: 49.0093457, lng: 21.0009864 },
    { lat: 49.0104045, lng: 21.002687 },
    { lat: 49.0106029, lng: 21.0031111 },
    { lat: 49.0111296, lng: 21.0046841 },
    { lat: 49.0114348, lng: 21.0059261 },
    { lat: 49.0116459, lng: 21.0074837 },
    { lat: 49.0134441, lng: 21.0057286 },
    { lat: 49.0150041, lng: 21.0050092 },
    { lat: 49.0156606, lng: 21.0043024 },
    { lat: 49.015847, lng: 21.0042922 },
    { lat: 49.0162931, lng: 21.0040596 },
    { lat: 49.0162318, lng: 21.0062024 },
    { lat: 49.016605, lng: 21.0058485 },
    { lat: 49.0174066, lng: 21.0052375 },
    { lat: 49.0176137, lng: 21.0051389 },
    { lat: 49.0184861, lng: 21.0043207 },
    { lat: 49.0187351, lng: 21.0042896 },
    { lat: 49.0190408, lng: 21.0040057 },
    { lat: 49.0196075, lng: 21.0036467 },
    { lat: 49.0200643, lng: 21.0035076 },
    { lat: 49.0202292, lng: 21.0035607 },
    { lat: 49.0206413, lng: 21.0030524 },
    { lat: 49.0209363, lng: 21.003087 },
    { lat: 49.0212892, lng: 21.0029694 },
    { lat: 49.0218159, lng: 21.0040321 },
    { lat: 49.022969, lng: 21.003196 },
    { lat: 49.0227042, lng: 21.0024018 },
    { lat: 49.0236183, lng: 21.0018553 },
    { lat: 49.024327, lng: 21.0016396 },
    { lat: 49.0245663, lng: 21.0016318 },
    { lat: 49.0255799, lng: 21.0011713 },
    { lat: 49.0268348, lng: 20.9999375 },
    { lat: 49.0275623, lng: 20.9989967 },
    { lat: 49.0266931, lng: 20.9977893 },
    { lat: 49.0289537, lng: 20.9962726 },
    { lat: 49.0293429, lng: 20.9957875 },
    { lat: 49.0298014, lng: 20.9959315 },
    { lat: 49.0302486, lng: 20.9958755 },
    { lat: 49.030882, lng: 20.9954593 },
    { lat: 49.0313031, lng: 20.9948838 },
    { lat: 49.0319458, lng: 20.9945124 },
    { lat: 49.0326535, lng: 20.9943003 },
    { lat: 49.0335622, lng: 20.9932869 },
    { lat: 49.0341681, lng: 20.9927859 },
    { lat: 49.0348891, lng: 20.9922277 },
    { lat: 49.0356243, lng: 20.9917953 },
    { lat: 49.0369671, lng: 20.9908409 },
    { lat: 49.0368962, lng: 20.9904643 },
    { lat: 49.0368957, lng: 20.9901419 },
    { lat: 49.0367547, lng: 20.9897936 },
    { lat: 49.0365663, lng: 20.9875284 },
    { lat: 49.0361947, lng: 20.9840117 },
    { lat: 49.0354765, lng: 20.9846584 },
    { lat: 49.0351482, lng: 20.984158 },
    { lat: 49.0343021, lng: 20.9853537 },
    { lat: 49.0338261, lng: 20.9844377 },
    { lat: 49.0354447, lng: 20.9819286 },
    { lat: 49.0347467, lng: 20.9805888 },
    { lat: 49.0342722, lng: 20.9803974 },
    { lat: 49.0334212, lng: 20.9772325 },
    { lat: 49.0319167, lng: 20.9782978 },
    { lat: 49.0314066, lng: 20.9746877 },
    { lat: 49.0308587, lng: 20.9718417 },
    { lat: 49.0306555, lng: 20.9698886 },
  ],
  VeľkýŠariš: [
    { lat: 49.0748753, lng: 21.2384974 },
    { lat: 49.0748682, lng: 21.2384807 },
    { lat: 49.0745893, lng: 21.2381593 },
    { lat: 49.0749413, lng: 21.2369806 },
    { lat: 49.0737572, lng: 21.2359917 },
    { lat: 49.0730424, lng: 21.2352155 },
    { lat: 49.0728658, lng: 21.2345577 },
    { lat: 49.072809, lng: 21.2338482 },
    { lat: 49.0726244, lng: 21.2334447 },
    { lat: 49.0727732, lng: 21.2327651 },
    { lat: 49.0728325, lng: 21.2320972 },
    { lat: 49.0732229, lng: 21.2300576 },
    { lat: 49.0728309, lng: 21.2297475 },
    { lat: 49.0719371, lng: 21.2292344 },
    { lat: 49.0705189, lng: 21.2273979 },
    { lat: 49.0702633, lng: 21.2273542 },
    { lat: 49.0700509, lng: 21.227219 },
    { lat: 49.0697557, lng: 21.2271354 },
    { lat: 49.0691516, lng: 21.2265954 },
    { lat: 49.0686833, lng: 21.2263753 },
    { lat: 49.0678696, lng: 21.2265495 },
    { lat: 49.0674396, lng: 21.2265473 },
    { lat: 49.0672767, lng: 21.2264804 },
    { lat: 49.0671601, lng: 21.2263829 },
    { lat: 49.066815, lng: 21.2257394 },
    { lat: 49.066661, lng: 21.2256004 },
    { lat: 49.0659636, lng: 21.2255352 },
    { lat: 49.0657578, lng: 21.2252492 },
    { lat: 49.065514, lng: 21.2250424 },
    { lat: 49.0650376, lng: 21.2257573 },
    { lat: 49.064628, lng: 21.2266343 },
    { lat: 49.0644685, lng: 21.2264935 },
    { lat: 49.0640043, lng: 21.2278613 },
    { lat: 49.0634671, lng: 21.2271897 },
    { lat: 49.0629811, lng: 21.2267235 },
    { lat: 49.0631005, lng: 21.2266493 },
    { lat: 49.06287, lng: 21.2265148 },
    { lat: 49.0630227, lng: 21.2264683 },
    { lat: 49.0631797, lng: 21.2263038 },
    { lat: 49.0629921, lng: 21.2260571 },
    { lat: 49.0629703, lng: 21.225641 },
    { lat: 49.0627845, lng: 21.2252771 },
    { lat: 49.0620698, lng: 21.2248744 },
    { lat: 49.0619738, lng: 21.2247508 },
    { lat: 49.0616914, lng: 21.224678 },
    { lat: 49.0609378, lng: 21.2247604 },
    { lat: 49.0608602, lng: 21.2244721 },
    { lat: 49.0607554, lng: 21.2242844 },
    { lat: 49.060145, lng: 21.2237641 },
    { lat: 49.0598433, lng: 21.2228836 },
    { lat: 49.0601537, lng: 21.2211365 },
    { lat: 49.0600395, lng: 21.2203585 },
    { lat: 49.0600863, lng: 21.2200857 },
    { lat: 49.0600906, lng: 21.2175414 },
    { lat: 49.0597699, lng: 21.2169612 },
    { lat: 49.0594515, lng: 21.217401 },
    { lat: 49.0592261, lng: 21.2175324 },
    { lat: 49.0582626, lng: 21.218467 },
    { lat: 49.0579446, lng: 21.2176119 },
    { lat: 49.055684, lng: 21.2195362 },
    { lat: 49.0555384, lng: 21.2195248 },
    { lat: 49.0556087, lng: 21.2198297 },
    { lat: 49.055601, lng: 21.2199853 },
    { lat: 49.0554173, lng: 21.220189 },
    { lat: 49.0553239, lng: 21.2201218 },
    { lat: 49.0550876, lng: 21.2196099 },
    { lat: 49.0549361, lng: 21.2196983 },
    { lat: 49.0548152, lng: 21.2200819 },
    { lat: 49.0548641, lng: 21.2206757 },
    { lat: 49.0547, lng: 21.2213405 },
    { lat: 49.0547312, lng: 21.2216533 },
    { lat: 49.0549094, lng: 21.2218887 },
    { lat: 49.0549189, lng: 21.2221557 },
    { lat: 49.0548437, lng: 21.2223463 },
    { lat: 49.0546444, lng: 21.2224822 },
    { lat: 49.0544798, lng: 21.2226949 },
    { lat: 49.0543487, lng: 21.2230756 },
    { lat: 49.0540215, lng: 21.2233775 },
    { lat: 49.0539251, lng: 21.2244363 },
    { lat: 49.053851, lng: 21.22448 },
    { lat: 49.0534827, lng: 21.2243583 },
    { lat: 49.0532195, lng: 21.224441 },
    { lat: 49.0530857, lng: 21.2245543 },
    { lat: 49.053105, lng: 21.2247874 },
    { lat: 49.0531275, lng: 21.2249603 },
    { lat: 49.053204, lng: 21.2252142 },
    { lat: 49.0531702, lng: 21.2253163 },
    { lat: 49.0528195, lng: 21.2253307 },
    { lat: 49.0527072, lng: 21.2250478 },
    { lat: 49.052589, lng: 21.2245353 },
    { lat: 49.0526524, lng: 21.2243281 },
    { lat: 49.0525048, lng: 21.22354 },
    { lat: 49.0521871, lng: 21.2218443 },
    { lat: 49.0520631, lng: 21.2215472 },
    { lat: 49.0517505, lng: 21.2204259 },
    { lat: 49.0517668, lng: 21.2200079 },
    { lat: 49.0513648, lng: 21.2188768 },
    { lat: 49.0513292, lng: 21.2185935 },
    { lat: 49.0512114, lng: 21.218392 },
    { lat: 49.0511886, lng: 21.2181964 },
    { lat: 49.0516524, lng: 21.2175205 },
    { lat: 49.0515799, lng: 21.2169241 },
    { lat: 49.051489, lng: 21.2165998 },
    { lat: 49.0513867, lng: 21.2164562 },
    { lat: 49.0509248, lng: 21.2162224 },
    { lat: 49.0506925, lng: 21.2158129 },
    { lat: 49.0510493, lng: 21.2145442 },
    { lat: 49.0511505, lng: 21.2139998 },
    { lat: 49.0511498, lng: 21.2137638 },
    { lat: 49.051415, lng: 21.2124766 },
    { lat: 49.0516726, lng: 21.2118273 },
    { lat: 49.052105, lng: 21.2109294 },
    { lat: 49.0525388, lng: 21.2104031 },
    { lat: 49.0525717, lng: 21.2104467 },
    { lat: 49.053452, lng: 21.2093307 },
    { lat: 49.0536094, lng: 21.2091838 },
    { lat: 49.0537717, lng: 21.2091258 },
    { lat: 49.053876, lng: 21.2091821 },
    { lat: 49.0542898, lng: 21.2091334 },
    { lat: 49.0552351, lng: 21.2088138 },
    { lat: 49.0568785, lng: 21.2078171 },
    { lat: 49.0583241, lng: 21.2066822 },
    { lat: 49.0596185, lng: 21.2063277 },
    { lat: 49.0599279, lng: 21.2064719 },
    { lat: 49.0601778, lng: 21.2065081 },
    { lat: 49.0604372, lng: 21.2064553 },
    { lat: 49.0607178, lng: 21.2062905 },
    { lat: 49.0608976, lng: 21.2060641 },
    { lat: 49.0613223, lng: 21.2052849 },
    { lat: 49.0615218, lng: 21.2046024 },
    { lat: 49.0615396, lng: 21.2041691 },
    { lat: 49.0615035, lng: 21.2039869 },
    { lat: 49.0613556, lng: 21.2037496 },
    { lat: 49.060816, lng: 21.2019592 },
    { lat: 49.0602312, lng: 21.201536 },
    { lat: 49.0603042, lng: 21.2009196 },
    { lat: 49.0604259, lng: 21.2008157 },
    { lat: 49.0605885, lng: 21.2004689 },
    { lat: 49.060232, lng: 21.1995419 },
    { lat: 49.05972, lng: 21.1979871 },
    { lat: 49.0594614, lng: 21.1969487 },
    { lat: 49.063964, lng: 21.1932797 },
    { lat: 49.0642289, lng: 21.1929713 },
    { lat: 49.0644461, lng: 21.1925041 },
    { lat: 49.06636, lng: 21.175604 },
    { lat: 49.066183, lng: 21.17578 },
    { lat: 49.066071, lng: 21.175887 },
    { lat: 49.065815, lng: 21.176126 },
    { lat: 49.065536, lng: 21.176386 },
    { lat: 49.065477, lng: 21.176453 },
    { lat: 49.06522, lng: 21.176726 },
    { lat: 49.064895, lng: 21.177044 },
    { lat: 49.064031, lng: 21.177893 },
    { lat: 49.06371, lng: 21.177931 },
    { lat: 49.06367, lng: 21.177932 },
    { lat: 49.063649, lng: 21.177933 },
    { lat: 49.063628, lng: 21.177934 },
    { lat: 49.063585, lng: 21.177936 },
    { lat: 49.063594, lng: 21.177848 },
    { lat: 49.063598, lng: 21.177812 },
    { lat: 49.06364, lng: 21.176871 },
    { lat: 49.063685, lng: 21.175836 },
    { lat: 49.063703, lng: 21.175348 },
    { lat: 49.063717, lng: 21.174958 },
    { lat: 49.063799, lng: 21.173383 },
    { lat: 49.063878, lng: 21.171561 },
    { lat: 49.063729, lng: 21.171509 },
    { lat: 49.063773, lng: 21.170393 },
    { lat: 49.063933, lng: 21.170326 },
    { lat: 49.06396, lng: 21.169448 },
    { lat: 49.063963, lng: 21.169258 },
    { lat: 49.064005, lng: 21.16876 },
    { lat: 49.064028, lng: 21.168098 },
    { lat: 49.064055, lng: 21.167442 },
    { lat: 49.064059, lng: 21.167385 },
    { lat: 49.064063, lng: 21.167273 },
    { lat: 49.06424, lng: 21.163026 },
    { lat: 49.064302, lng: 21.161718 },
    { lat: 49.064357, lng: 21.160442 },
    { lat: 49.064395, lng: 21.159627 },
    { lat: 49.0644, lng: 21.15894 },
    { lat: 49.064402, lng: 21.158884 },
    { lat: 49.0645, lng: 21.156631 },
    { lat: 49.064517, lng: 21.156267 },
    { lat: 49.064588, lng: 21.155056 },
    { lat: 49.064675, lng: 21.154369 },
    { lat: 49.064772, lng: 21.153441 },
    { lat: 49.064814, lng: 21.153132 },
    { lat: 49.064963, lng: 21.152043 },
    { lat: 49.06517, lng: 21.150787 },
    { lat: 49.06516, lng: 21.150709 },
    { lat: 49.065087, lng: 21.150707 },
    { lat: 49.064856, lng: 21.150695 },
    { lat: 49.064621, lng: 21.150684 },
    { lat: 49.064478, lng: 21.150673 },
    { lat: 49.06443, lng: 21.150676 },
    { lat: 49.064301, lng: 21.150679 },
    { lat: 49.063195, lng: 21.149828 },
    { lat: 49.063172, lng: 21.149827 },
    { lat: 49.063141, lng: 21.149813 },
    { lat: 49.063115, lng: 21.149803 },
    { lat: 49.062161, lng: 21.149542 },
    { lat: 49.061509, lng: 21.149109 },
    { lat: 49.06077, lng: 21.148612 },
    { lat: 49.059682, lng: 21.147775 },
    { lat: 49.058558, lng: 21.146903 },
    { lat: 49.058544, lng: 21.146862 },
    { lat: 49.0576471, lng: 21.1481289 },
    { lat: 49.0563638, lng: 21.1492931 },
    { lat: 49.0561823, lng: 21.1493929 },
    { lat: 49.0557828, lng: 21.1493902 },
    { lat: 49.0557516, lng: 21.149508 },
    { lat: 49.0557883, lng: 21.1503492 },
    { lat: 49.0557429, lng: 21.1506895 },
    { lat: 49.0555221, lng: 21.1510056 },
    { lat: 49.0553815, lng: 21.1510055 },
    { lat: 49.0552088, lng: 21.1511656 },
    { lat: 49.0552783, lng: 21.1513072 },
    { lat: 49.0552469, lng: 21.1517301 },
    { lat: 49.0553976, lng: 21.1524775 },
    { lat: 49.0552893, lng: 21.1528654 },
    { lat: 49.0551366, lng: 21.1529814 },
    { lat: 49.0546729, lng: 21.1524771 },
    { lat: 49.0546213, lng: 21.1524855 },
    { lat: 49.0545477, lng: 21.1526505 },
    { lat: 49.054531, lng: 21.1529427 },
    { lat: 49.0539633, lng: 21.1533506 },
    { lat: 49.0539449, lng: 21.1539088 },
    { lat: 49.0536848, lng: 21.1541556 },
    { lat: 49.0538063, lng: 21.1552583 },
    { lat: 49.0540796, lng: 21.1558604 },
    { lat: 49.0546058, lng: 21.1566282 },
    { lat: 49.0546585, lng: 21.157126 },
    { lat: 49.0548372, lng: 21.1576554 },
    { lat: 49.055252, lng: 21.159563 },
    { lat: 49.0554223, lng: 21.1601085 },
    { lat: 49.0540843, lng: 21.1595793 },
    { lat: 49.0538997, lng: 21.1595792 },
    { lat: 49.0511367, lng: 21.1610318 },
    { lat: 49.0495525, lng: 21.1626857 },
    { lat: 49.0493689, lng: 21.1628164 },
    { lat: 49.0474799, lng: 21.1633383 },
    { lat: 49.0471783, lng: 21.1635458 },
    { lat: 49.0464876, lng: 21.1643785 },
    { lat: 49.0461555, lng: 21.1649817 },
    { lat: 49.0456139, lng: 21.1649038 },
    { lat: 49.0444663, lng: 21.1654462 },
    { lat: 49.0443102, lng: 21.1656337 },
    { lat: 49.0441921, lng: 21.1659186 },
    { lat: 49.0441407, lng: 21.1662548 },
    { lat: 49.0442578, lng: 21.1679387 },
    { lat: 49.0441017, lng: 21.1704902 },
    { lat: 49.0437592, lng: 21.1718761 },
    { lat: 49.0436717, lng: 21.1720248 },
    { lat: 49.0433275, lng: 21.1722443 },
    { lat: 49.0426784, lng: 21.17236 },
    { lat: 49.042321, lng: 21.1725503 },
    { lat: 49.0419183, lng: 21.1728102 },
    { lat: 49.0409312, lng: 21.1736269 },
    { lat: 49.0400902, lng: 21.1735428 },
    { lat: 49.0388638, lng: 21.1739127 },
    { lat: 49.0384346, lng: 21.1741448 },
    { lat: 49.0382372, lng: 21.1744213 },
    { lat: 49.038158, lng: 21.1746928 },
    { lat: 49.0379331, lng: 21.1748376 },
    { lat: 49.0375403, lng: 21.1749457 },
    { lat: 49.036946, lng: 21.1755147 },
    { lat: 49.0362483, lng: 21.1755546 },
    { lat: 49.0360905, lng: 21.1754707 },
    { lat: 49.0359108, lng: 21.1749798 },
    { lat: 49.0358868, lng: 21.1742169 },
    { lat: 49.035663, lng: 21.1736457 },
    { lat: 49.0358172, lng: 21.172722 },
    { lat: 49.0359893, lng: 21.1721038 },
    { lat: 49.0366068, lng: 21.1703455 },
    { lat: 49.0365456, lng: 21.1701481 },
    { lat: 49.0366506, lng: 21.1695493 },
    { lat: 49.0363018, lng: 21.1681232 },
    { lat: 49.0363617, lng: 21.1672021 },
    { lat: 49.0363223, lng: 21.167201 },
    { lat: 49.0360592, lng: 21.1673536 },
    { lat: 49.0359402, lng: 21.1677528 },
    { lat: 49.0355094, lng: 21.1683702 },
    { lat: 49.0354561, lng: 21.1685703 },
    { lat: 49.0352302, lng: 21.1688633 },
    { lat: 49.0347226, lng: 21.1693607 },
    { lat: 49.0345656, lng: 21.1694177 },
    { lat: 49.0326017, lng: 21.1710962 },
    { lat: 49.0316813, lng: 21.1722803 },
    { lat: 49.0317285, lng: 21.1724909 },
    { lat: 49.0309219, lng: 21.1735749 },
    { lat: 49.0300433, lng: 21.1748773 },
    { lat: 49.0296844, lng: 21.1755593 },
    { lat: 49.0295631, lng: 21.1763148 },
    { lat: 49.0296316, lng: 21.1772429 },
    { lat: 49.0295155, lng: 21.1779158 },
    { lat: 49.0285283, lng: 21.1796371 },
    { lat: 49.027789, lng: 21.1812524 },
    { lat: 49.0268771, lng: 21.1826352 },
    { lat: 49.0261483, lng: 21.1846646 },
    { lat: 49.025389, lng: 21.1860033 },
    { lat: 49.0242114, lng: 21.1879284 },
    { lat: 49.0235325, lng: 21.188529 },
    { lat: 49.0233798, lng: 21.1884274 },
    { lat: 49.0229458, lng: 21.1886586 },
    { lat: 49.0217975, lng: 21.1897911 },
    { lat: 49.0197756, lng: 21.1934776 },
    { lat: 49.0192945, lng: 21.1944653 },
    { lat: 49.0186764, lng: 21.1950668 },
    { lat: 49.0183311, lng: 21.1958295 },
    { lat: 49.0179851, lng: 21.1971488 },
    { lat: 49.0180402, lng: 21.1980824 },
    { lat: 49.0181554, lng: 21.1988556 },
    { lat: 49.0184286, lng: 21.1996014 },
    { lat: 49.0185099, lng: 21.1996163 },
    { lat: 49.0185173, lng: 21.1997395 },
    { lat: 49.0181595, lng: 21.1996678 },
    { lat: 49.017553, lng: 21.1997814 },
    { lat: 49.015987, lng: 21.1994738 },
    { lat: 49.0149331, lng: 21.1993792 },
    { lat: 49.0149239, lng: 21.2010358 },
    { lat: 49.0142716, lng: 21.2008659 },
    { lat: 49.0132246, lng: 21.2001269 },
    { lat: 49.0126158, lng: 21.2026533 },
    { lat: 49.0124248, lng: 21.2025925 },
    { lat: 49.0120753, lng: 21.2031453 },
    { lat: 49.0103292, lng: 21.2051818 },
    { lat: 49.0123116, lng: 21.2077693 },
    { lat: 49.012882, lng: 21.2087761 },
    { lat: 49.0132994, lng: 21.2107342 },
    { lat: 49.0133854, lng: 21.2108894 },
    { lat: 49.0140439, lng: 21.2111784 },
    { lat: 49.0145834, lng: 21.2114152 },
    { lat: 49.0146797, lng: 21.2114489 },
    { lat: 49.0165016, lng: 21.2120872 },
    { lat: 49.0171207, lng: 21.2124259 },
    { lat: 49.0175357, lng: 21.2129536 },
    { lat: 49.0177152, lng: 21.2130106 },
    { lat: 49.0180186, lng: 21.2129619 },
    { lat: 49.0187423, lng: 21.2125303 },
    { lat: 49.0194082, lng: 21.2125201 },
    { lat: 49.0200972, lng: 21.2127846 },
    { lat: 49.0203655, lng: 21.212912 },
    { lat: 49.021815, lng: 21.2144303 },
    { lat: 49.0222201, lng: 21.2151584 },
    { lat: 49.0225154, lng: 21.2158973 },
    { lat: 49.022564, lng: 21.2164435 },
    { lat: 49.0227501, lng: 21.2172957 },
    { lat: 49.0227587, lng: 21.2182263 },
    { lat: 49.0230082, lng: 21.219952 },
    { lat: 49.02295, lng: 21.2206174 },
    { lat: 49.0231713, lng: 21.2229314 },
    { lat: 49.0231543, lng: 21.2233636 },
    { lat: 49.0234052, lng: 21.2241574 },
    { lat: 49.0232877, lng: 21.2247248 },
    { lat: 49.0233498, lng: 21.2248426 },
    { lat: 49.0235036, lng: 21.2248671 },
    { lat: 49.0241005, lng: 21.2245572 },
    { lat: 49.0258269, lng: 21.2234704 },
    { lat: 49.0263829, lng: 21.2234652 },
    { lat: 49.0269731, lng: 21.2236347 },
    { lat: 49.0268232, lng: 21.2246129 },
    { lat: 49.0265268, lng: 21.2256109 },
    { lat: 49.0264157, lng: 21.2262044 },
    { lat: 49.0266406, lng: 21.2278679 },
    { lat: 49.0268285, lng: 21.2285612 },
    { lat: 49.0269277, lng: 21.2286721 },
    { lat: 49.0269807, lng: 21.2290078 },
    { lat: 49.0252494, lng: 21.2313556 },
    { lat: 49.0251581, lng: 21.2318573 },
    { lat: 49.0260534, lng: 21.2323125 },
    { lat: 49.0274633, lng: 21.2334051 },
    { lat: 49.0277968, lng: 21.2332382 },
    { lat: 49.0285633, lng: 21.2326624 },
    { lat: 49.0289024, lng: 21.2329626 },
    { lat: 49.0292706, lng: 21.2334843 },
    { lat: 49.02934, lng: 21.2330676 },
    { lat: 49.0295672, lng: 21.2329546 },
    { lat: 49.0308416, lng: 21.231953 },
    { lat: 49.0311367, lng: 21.231858 },
    { lat: 49.031273, lng: 21.2319709 },
    { lat: 49.0315638, lng: 21.2324224 },
    { lat: 49.031821, lng: 21.2329864 },
    { lat: 49.0323958, lng: 21.2326176 },
    { lat: 49.0329929, lng: 21.2320915 },
    { lat: 49.0336213, lng: 21.2314885 },
    { lat: 49.0336311, lng: 21.2312949 },
    { lat: 49.0343065, lng: 21.2306491 },
    { lat: 49.0345557, lng: 21.2316842 },
    { lat: 49.0341894, lng: 21.2320999 },
    { lat: 49.0343037, lng: 21.2336163 },
    { lat: 49.0351433, lng: 21.2336204 },
    { lat: 49.0356615, lng: 21.2337871 },
    { lat: 49.0364211, lng: 21.2336353 },
    { lat: 49.0366119, lng: 21.2345391 },
    { lat: 49.0364483, lng: 21.2354338 },
    { lat: 49.0367808, lng: 21.2355947 },
    { lat: 49.0370104, lng: 21.235631 },
    { lat: 49.0375148, lng: 21.2354637 },
    { lat: 49.0376767, lng: 21.2353453 },
    { lat: 49.0378615, lng: 21.2353497 },
    { lat: 49.0384589, lng: 21.2350943 },
    { lat: 49.0385941, lng: 21.2350983 },
    { lat: 49.0387336, lng: 21.2352476 },
    { lat: 49.0394731, lng: 21.2352858 },
    { lat: 49.0398901, lng: 21.2354192 },
    { lat: 49.0401702, lng: 21.2356302 },
    { lat: 49.0405582, lng: 21.2355899 },
    { lat: 49.0410735, lng: 21.2360582 },
    { lat: 49.0412416, lng: 21.2363991 },
    { lat: 49.0417595, lng: 21.2367377 },
    { lat: 49.0422821, lng: 21.2372312 },
    { lat: 49.0429358, lng: 21.2377365 },
    { lat: 49.0433028, lng: 21.2381838 },
    { lat: 49.0436907, lng: 21.2384273 },
    { lat: 49.0444739, lng: 21.2398435 },
    { lat: 49.0445332, lng: 21.2416246 },
    { lat: 49.0447684, lng: 21.2437471 },
    { lat: 49.0450648, lng: 21.2449277 },
    { lat: 49.0451435, lng: 21.2461639 },
    { lat: 49.0452583, lng: 21.2468613 },
    { lat: 49.0452514, lng: 21.247374 },
    { lat: 49.0451668, lng: 21.2474744 },
    { lat: 49.0461499, lng: 21.2490025 },
    { lat: 49.0468381, lng: 21.2504856 },
    { lat: 49.0475662, lng: 21.2512908 },
    { lat: 49.048261, lng: 21.2505738 },
    { lat: 49.0487219, lng: 21.2496804 },
    { lat: 49.0491235, lng: 21.2492364 },
    { lat: 49.0496943, lng: 21.2492506 },
    { lat: 49.0497985, lng: 21.2496694 },
    { lat: 49.0499437, lng: 21.2500067 },
    { lat: 49.0499127, lng: 21.2503095 },
    { lat: 49.0503288, lng: 21.2508909 },
    { lat: 49.0510862, lng: 21.2511505 },
    { lat: 49.051535, lng: 21.2510684 },
    { lat: 49.0521314, lng: 21.2511404 },
    { lat: 49.0529547, lng: 21.2506929 },
    { lat: 49.0530582, lng: 21.2505207 },
    { lat: 49.0532348, lng: 21.2502163 },
    { lat: 49.053775, lng: 21.2498467 },
    { lat: 49.0543923, lng: 21.2492076 },
    { lat: 49.0543268, lng: 21.248805 },
    { lat: 49.0553473, lng: 21.248398 },
    { lat: 49.0554622, lng: 21.2490198 },
    { lat: 49.056805, lng: 21.2479372 },
    { lat: 49.0570051, lng: 21.2482186 },
    { lat: 49.0576698, lng: 21.2486144 },
    { lat: 49.057738, lng: 21.2490013 },
    { lat: 49.0582679, lng: 21.2496166 },
    { lat: 49.0593341, lng: 21.248648 },
    { lat: 49.0599055, lng: 21.2482037 },
    { lat: 49.0611575, lng: 21.2479728 },
    { lat: 49.0620793, lng: 21.2475357 },
    { lat: 49.062668, lng: 21.246774 },
    { lat: 49.0630759, lng: 21.2460592 },
    { lat: 49.0633914, lng: 21.2457844 },
    { lat: 49.063892, lng: 21.244973 },
    { lat: 49.0650221, lng: 21.2439583 },
    { lat: 49.0665188, lng: 21.2438538 },
    { lat: 49.0669187, lng: 21.2436853 },
    { lat: 49.0675355, lng: 21.242995 },
    { lat: 49.0677047, lng: 21.2429366 },
    { lat: 49.0690228, lng: 21.243097 },
    { lat: 49.0704192, lng: 21.2419933 },
    { lat: 49.0711301, lng: 21.2416274 },
    { lat: 49.0717198, lng: 21.240822 },
    { lat: 49.0723701, lng: 21.2407817 },
    { lat: 49.0733284, lng: 21.2400396 },
    { lat: 49.0736724, lng: 21.239589 },
    { lat: 49.0748753, lng: 21.2384974 },
  ],
  Záhradné: [
    { lat: 49.1016215, lng: 21.2579036 },
    { lat: 49.1018413, lng: 21.2575411 },
    { lat: 49.1017013, lng: 21.2571991 },
    { lat: 49.1013058, lng: 21.2570344 },
    { lat: 49.1003304, lng: 21.2577738 },
    { lat: 49.0998112, lng: 21.257907 },
    { lat: 49.0998017, lng: 21.2577058 },
    { lat: 49.0995351, lng: 21.2564978 },
    { lat: 49.0993202, lng: 21.2533038 },
    { lat: 49.0987048, lng: 21.2508943 },
    { lat: 49.0985888, lng: 21.2496429 },
    { lat: 49.0985447, lng: 21.2481673 },
    { lat: 49.0981999, lng: 21.2481194 },
    { lat: 49.0971392, lng: 21.2484628 },
    { lat: 49.0962456, lng: 21.248933 },
    { lat: 49.0960575, lng: 21.2490728 },
    { lat: 49.0955066, lng: 21.2496783 },
    { lat: 49.0947897, lng: 21.2506557 },
    { lat: 49.0945139, lng: 21.2499605 },
    { lat: 49.0941111, lng: 21.2493382 },
    { lat: 49.0938654, lng: 21.2488135 },
    { lat: 49.0931888, lng: 21.2469027 },
    { lat: 49.0928451, lng: 21.2462605 },
    { lat: 49.0924098, lng: 21.2475862 },
    { lat: 49.092001, lng: 21.2471371 },
    { lat: 49.0916877, lng: 21.2477066 },
    { lat: 49.0912043, lng: 21.2483819 },
    { lat: 49.0910437, lng: 21.2489693 },
    { lat: 49.0909535, lng: 21.2495494 },
    { lat: 49.0910615, lng: 21.2496947 },
    { lat: 49.0906098, lng: 21.2505652 },
    { lat: 49.0903777, lng: 21.2511382 },
    { lat: 49.0895876, lng: 21.2524557 },
    { lat: 49.0888938, lng: 21.2530605 },
    { lat: 49.0881535, lng: 21.2535393 },
    { lat: 49.0879351, lng: 21.2532382 },
    { lat: 49.0876231, lng: 21.2538517 },
    { lat: 49.0862572, lng: 21.2545208 },
    { lat: 49.085907, lng: 21.2551732 },
    { lat: 49.0853829, lng: 21.2563524 },
    { lat: 49.0853, lng: 21.2569169 },
    { lat: 49.0849935, lng: 21.2569419 },
    { lat: 49.084853, lng: 21.2573805 },
    { lat: 49.0849228, lng: 21.2582264 },
    { lat: 49.0849692, lng: 21.2601498 },
    { lat: 49.0840965, lng: 21.2597242 },
    { lat: 49.0840717, lng: 21.2599374 },
    { lat: 49.0838045, lng: 21.2596279 },
    { lat: 49.0817379, lng: 21.2591347 },
    { lat: 49.0815543, lng: 21.2589438 },
    { lat: 49.080575, lng: 21.2583044 },
    { lat: 49.0803186, lng: 21.2583862 },
    { lat: 49.0796503, lng: 21.2573216 },
    { lat: 49.0796089, lng: 21.2568558 },
    { lat: 49.0795034, lng: 21.2566454 },
    { lat: 49.0795283, lng: 21.2558449 },
    { lat: 49.0792908, lng: 21.2552889 },
    { lat: 49.0786559, lng: 21.2549848 },
    { lat: 49.0784271, lng: 21.2546439 },
    { lat: 49.0781769, lng: 21.254488 },
    { lat: 49.0772249, lng: 21.2549247 },
    { lat: 49.077162, lng: 21.2548577 },
    { lat: 49.0769696, lng: 21.2549267 },
    { lat: 49.0766586, lng: 21.2548813 },
    { lat: 49.0757695, lng: 21.2544689 },
    { lat: 49.075007, lng: 21.2539335 },
    { lat: 49.0747273, lng: 21.2538381 },
    { lat: 49.0744611, lng: 21.2539626 },
    { lat: 49.0736399, lng: 21.25375 },
    { lat: 49.0731728, lng: 21.2537118 },
    { lat: 49.0728618, lng: 21.2531514 },
    { lat: 49.0729464, lng: 21.2527517 },
    { lat: 49.0729595, lng: 21.252225 },
    { lat: 49.0727165, lng: 21.2524806 },
    { lat: 49.0724041, lng: 21.2524996 },
    { lat: 49.0722214, lng: 21.2529217 },
    { lat: 49.0721797, lng: 21.2534009 },
    { lat: 49.0719083, lng: 21.2545303 },
    { lat: 49.0720238, lng: 21.2549955 },
    { lat: 49.0725929, lng: 21.2563766 },
    { lat: 49.072445, lng: 21.2566886 },
    { lat: 49.0721477, lng: 21.2568684 },
    { lat: 49.0719208, lng: 21.2571023 },
    { lat: 49.0716278, lng: 21.2575603 },
    { lat: 49.0710982, lng: 21.258128 },
    { lat: 49.0709287, lng: 21.2585079 },
    { lat: 49.070517, lng: 21.2599084 },
    { lat: 49.070343, lng: 21.2615394 },
    { lat: 49.070371, lng: 21.2626011 },
    { lat: 49.0705883, lng: 21.2632295 },
    { lat: 49.0709191, lng: 21.2659085 },
    { lat: 49.0708971, lng: 21.2662549 },
    { lat: 49.0706843, lng: 21.2668428 },
    { lat: 49.070486, lng: 21.2670894 },
    { lat: 49.0699955, lng: 21.2679054 },
    { lat: 49.0697716, lng: 21.2688953 },
    { lat: 49.0691644, lng: 21.2709017 },
    { lat: 49.0691112, lng: 21.2714847 },
    { lat: 49.0686919, lng: 21.2735204 },
    { lat: 49.0687164, lng: 21.2748806 },
    { lat: 49.0683663, lng: 21.2753525 },
    { lat: 49.0683495, lng: 21.275944 },
    { lat: 49.0686341, lng: 21.2760165 },
    { lat: 49.0687212, lng: 21.2760635 },
    { lat: 49.0691097, lng: 21.2764778 },
    { lat: 49.0699691, lng: 21.2790914 },
    { lat: 49.070039, lng: 21.2797583 },
    { lat: 49.0700029, lng: 21.280198 },
    { lat: 49.0700605, lng: 21.2808366 },
    { lat: 49.070028, lng: 21.2814652 },
    { lat: 49.0698384, lng: 21.28171 },
    { lat: 49.069764, lng: 21.281964 },
    { lat: 49.0697141, lng: 21.2823529 },
    { lat: 49.0697939, lng: 21.2828372 },
    { lat: 49.0700721, lng: 21.2829898 },
    { lat: 49.0704928, lng: 21.283426 },
    { lat: 49.0705642, lng: 21.2834478 },
    { lat: 49.0706826, lng: 21.2832697 },
    { lat: 49.0707394, lng: 21.2832837 },
    { lat: 49.0710964, lng: 21.2834082 },
    { lat: 49.0712618, lng: 21.2836291 },
    { lat: 49.0717472, lng: 21.2847174 },
    { lat: 49.0718346, lng: 21.2852202 },
    { lat: 49.0718449, lng: 21.2857441 },
    { lat: 49.0720645, lng: 21.2863431 },
    { lat: 49.0721272, lng: 21.2864442 },
    { lat: 49.0723878, lng: 21.2865385 },
    { lat: 49.0725786, lng: 21.2872235 },
    { lat: 49.0731922, lng: 21.2882311 },
    { lat: 49.0736374, lng: 21.2887054 },
    { lat: 49.0737028, lng: 21.2891118 },
    { lat: 49.0741772, lng: 21.2897721 },
    { lat: 49.0742508, lng: 21.2897487 },
    { lat: 49.0742315, lng: 21.2893696 },
    { lat: 49.0748423, lng: 21.290035 },
    { lat: 49.0751362, lng: 21.2900373 },
    { lat: 49.0758445, lng: 21.2909052 },
    { lat: 49.0759411, lng: 21.2913847 },
    { lat: 49.0760038, lng: 21.2920357 },
    { lat: 49.0762723, lng: 21.2927343 },
    { lat: 49.0764549, lng: 21.293 },
    { lat: 49.0767047, lng: 21.2931138 },
    { lat: 49.0770301, lng: 21.293415 },
    { lat: 49.0782402, lng: 21.2950233 },
    { lat: 49.0786055, lng: 21.2952371 },
    { lat: 49.0791194, lng: 21.2954106 },
    { lat: 49.0797139, lng: 21.2954614 },
    { lat: 49.0804727, lng: 21.295323 },
    { lat: 49.0810065, lng: 21.2950787 },
    { lat: 49.0812971, lng: 21.2956035 },
    { lat: 49.0816946, lng: 21.2966919 },
    { lat: 49.0830928, lng: 21.295712 },
    { lat: 49.0844353, lng: 21.2942697 },
    { lat: 49.0847893, lng: 21.2926265 },
    { lat: 49.0858548, lng: 21.290846 },
    { lat: 49.0861888, lng: 21.2904703 },
    { lat: 49.0864002, lng: 21.2904274 },
    { lat: 49.0878535, lng: 21.2907024 },
    { lat: 49.0881379, lng: 21.2906185 },
    { lat: 49.0883471, lng: 21.2904332 },
    { lat: 49.0892951, lng: 21.2890407 },
    { lat: 49.0904445, lng: 21.2883264 },
    { lat: 49.0909821, lng: 21.2880928 },
    { lat: 49.0911884, lng: 21.2881774 },
    { lat: 49.0931567, lng: 21.286297 },
    { lat: 49.0937356, lng: 21.2859101 },
    { lat: 49.0944761, lng: 21.2851703 },
    { lat: 49.0948351, lng: 21.2846161 },
    { lat: 49.0950303, lng: 21.284012 },
    { lat: 49.0957708, lng: 21.2827523 },
    { lat: 49.0962451, lng: 21.2818093 },
    { lat: 49.0963262, lng: 21.2815558 },
    { lat: 49.09616, lng: 21.2807884 },
    { lat: 49.0961757, lng: 21.2804914 },
    { lat: 49.0964276, lng: 21.2794873 },
    { lat: 49.097109, lng: 21.2776301 },
    { lat: 49.0972407, lng: 21.2774086 },
    { lat: 49.0972477, lng: 21.2763456 },
    { lat: 49.0975972, lng: 21.2764785 },
    { lat: 49.0979275, lng: 21.2760922 },
    { lat: 49.0981534, lng: 21.2754205 },
    { lat: 49.0989001, lng: 21.2756255 },
    { lat: 49.0989997, lng: 21.2754993 },
    { lat: 49.0992047, lng: 21.2754715 },
    { lat: 49.0994477, lng: 21.2752805 },
    { lat: 49.099919, lng: 21.2751653 },
    { lat: 49.1003559, lng: 21.2740646 },
    { lat: 49.100676, lng: 21.2729798 },
    { lat: 49.1009085, lng: 21.2731127 },
    { lat: 49.1012846, lng: 21.272346 },
    { lat: 49.1019904, lng: 21.2712912 },
    { lat: 49.1023098, lng: 21.2712094 },
    { lat: 49.1026971, lng: 21.2709902 },
    { lat: 49.1028673, lng: 21.269607 },
    { lat: 49.1029156, lng: 21.2687873 },
    { lat: 49.1028937, lng: 21.2683734 },
    { lat: 49.1026459, lng: 21.2668123 },
    { lat: 49.1028367, lng: 21.2662827 },
    { lat: 49.1022511, lng: 21.2655313 },
    { lat: 49.1018785, lng: 21.2649351 },
    { lat: 49.1022674, lng: 21.2635576 },
    { lat: 49.1025326, lng: 21.2629218 },
    { lat: 49.1023241, lng: 21.2623573 },
    { lat: 49.1016813, lng: 21.2583842 },
    { lat: 49.1016215, lng: 21.2579036 },
  ],
  Ondrašovce: [
    { lat: 48.9850264, lng: 21.0687367 },
    { lat: 48.9843301, lng: 21.0689578 },
    { lat: 48.9832607, lng: 21.0690861 },
    { lat: 48.9820614, lng: 21.0689958 },
    { lat: 48.9807118, lng: 21.0687791 },
    { lat: 48.9796429, lng: 21.0669123 },
    { lat: 48.9785922, lng: 21.0657799 },
    { lat: 48.9772279, lng: 21.064624 },
    { lat: 48.9765131, lng: 21.0643765 },
    { lat: 48.9759507, lng: 21.0645235 },
    { lat: 48.9756914, lng: 21.0644773 },
    { lat: 48.9753182, lng: 21.0645235 },
    { lat: 48.9743132, lng: 21.0651188 },
    { lat: 48.9739381, lng: 21.0652095 },
    { lat: 48.9741673, lng: 21.066832 },
    { lat: 48.974495, lng: 21.0680883 },
    { lat: 48.9761315, lng: 21.0719262 },
    { lat: 48.9768135, lng: 21.0732439 },
    { lat: 48.9768543, lng: 21.0737399 },
    { lat: 48.9766226, lng: 21.0741982 },
    { lat: 48.9764194, lng: 21.0744566 },
    { lat: 48.9762487, lng: 21.0748889 },
    { lat: 48.9761767, lng: 21.0753739 },
    { lat: 48.976187, lng: 21.0760453 },
    { lat: 48.9761193, lng: 21.0767661 },
    { lat: 48.9759578, lng: 21.0769938 },
    { lat: 48.9753256, lng: 21.0784352 },
    { lat: 48.9751907, lng: 21.0786487 },
    { lat: 48.9750442, lng: 21.0790978 },
    { lat: 48.9753273, lng: 21.0795715 },
    { lat: 48.9758745, lng: 21.0802361 },
    { lat: 48.9759823, lng: 21.0805448 },
    { lat: 48.9762255, lng: 21.0809329 },
    { lat: 48.9761936, lng: 21.0810575 },
    { lat: 48.9760129, lng: 21.0814947 },
    { lat: 48.9750278, lng: 21.0820048 },
    { lat: 48.9749698, lng: 21.0821291 },
    { lat: 48.9748228, lng: 21.0829751 },
    { lat: 48.974359, lng: 21.0835344 },
    { lat: 48.9738623, lng: 21.0838336 },
    { lat: 48.9736061, lng: 21.0841586 },
    { lat: 48.9735815, lng: 21.0843402 },
    { lat: 48.9734638, lng: 21.0846026 },
    { lat: 48.9732144, lng: 21.0848934 },
    { lat: 48.973034, lng: 21.085231 },
    { lat: 48.9730479, lng: 21.0854181 },
    { lat: 48.9739709, lng: 21.0853516 },
    { lat: 48.9745409, lng: 21.0854045 },
    { lat: 48.9747273, lng: 21.0862712 },
    { lat: 48.9749413, lng: 21.0869399 },
    { lat: 48.9749257, lng: 21.0871886 },
    { lat: 48.9750244, lng: 21.0874554 },
    { lat: 48.9750662, lng: 21.0879477 },
    { lat: 48.9752188, lng: 21.0886371 },
    { lat: 48.974941, lng: 21.0892008 },
    { lat: 48.9744602, lng: 21.0897162 },
    { lat: 48.974276, lng: 21.0908816 },
    { lat: 48.9735936, lng: 21.0910444 },
    { lat: 48.9734235, lng: 21.0912483 },
    { lat: 48.9733023, lng: 21.0915045 },
    { lat: 48.9731075, lng: 21.0915734 },
    { lat: 48.9732421, lng: 21.0923988 },
    { lat: 48.9732893, lng: 21.0932372 },
    { lat: 48.9730241, lng: 21.094823 },
    { lat: 48.9725788, lng: 21.0949409 },
    { lat: 48.9722852, lng: 21.0951164 },
    { lat: 48.9719026, lng: 21.0954729 },
    { lat: 48.9713909, lng: 21.0962179 },
    { lat: 48.9716056, lng: 21.0973648 },
    { lat: 48.9717225, lng: 21.0979955 },
    { lat: 48.9718914, lng: 21.0992604 },
    { lat: 48.971985, lng: 21.0992958 },
    { lat: 48.9717966, lng: 21.1002665 },
    { lat: 48.9720484, lng: 21.100221 },
    { lat: 48.972291, lng: 21.0999598 },
    { lat: 48.9725079, lng: 21.0994971 },
    { lat: 48.9728613, lng: 21.0990439 },
    { lat: 48.9729846, lng: 21.0989191 },
    { lat: 48.9734305, lng: 21.0986808 },
    { lat: 48.9735591, lng: 21.0984491 },
    { lat: 48.9737566, lng: 21.0983922 },
    { lat: 48.9739609, lng: 21.0981324 },
    { lat: 48.974076, lng: 21.0981081 },
    { lat: 48.9742976, lng: 21.0978936 },
    { lat: 48.9744516, lng: 21.0975347 },
    { lat: 48.9750305, lng: 21.0968495 },
    { lat: 48.975556, lng: 21.0964609 },
    { lat: 48.9769617, lng: 21.0960784 },
    { lat: 48.9783591, lng: 21.096457 },
    { lat: 48.9790885, lng: 21.096456 },
    { lat: 48.9793452, lng: 21.0970173 },
    { lat: 48.9797722, lng: 21.0969986 },
    { lat: 48.9801647, lng: 21.0968828 },
    { lat: 48.9804493, lng: 21.0971623 },
    { lat: 48.9809075, lng: 21.0971699 },
    { lat: 48.9812944, lng: 21.0973333 },
    { lat: 48.9829355, lng: 21.0991321 },
    { lat: 48.9830493, lng: 21.0992228 },
    { lat: 48.9832786, lng: 21.0994055 },
    { lat: 48.9830939, lng: 21.100071 },
    { lat: 48.9839442, lng: 21.1004082 },
    { lat: 48.9839438, lng: 21.1004978 },
    { lat: 48.9841844, lng: 21.1005961 },
    { lat: 48.9845218, lng: 21.1006575 },
    { lat: 48.9845807, lng: 21.1007377 },
    { lat: 48.9852375, lng: 21.1009721 },
    { lat: 48.9854946, lng: 21.1009176 },
    { lat: 48.9857401, lng: 21.1009887 },
    { lat: 48.9859996, lng: 21.100978 },
    { lat: 48.9865843, lng: 21.1007527 },
    { lat: 48.9866579, lng: 21.1007982 },
    { lat: 48.9870125, lng: 21.1007662 },
    { lat: 48.9871101, lng: 21.1006543 },
    { lat: 48.9879384, lng: 21.1008632 },
    { lat: 48.9880876, lng: 21.1010564 },
    { lat: 48.988523, lng: 21.1012295 },
    { lat: 48.9890228, lng: 21.1012681 },
    { lat: 48.9891122, lng: 21.1012181 },
    { lat: 48.9900256, lng: 21.1014099 },
    { lat: 48.9909744, lng: 21.1014859 },
    { lat: 48.991183, lng: 21.1007247 },
    { lat: 48.9910515, lng: 21.1002051 },
    { lat: 48.9910111, lng: 21.1001744 },
    { lat: 48.9910198, lng: 21.1000258 },
    { lat: 48.9908251, lng: 21.0994625 },
    { lat: 48.9907591, lng: 21.0990134 },
    { lat: 48.9909445, lng: 21.0979565 },
    { lat: 48.9909414, lng: 21.0976475 },
    { lat: 48.9908322, lng: 21.0972284 },
    { lat: 48.9910767, lng: 21.0960818 },
    { lat: 48.9912861, lng: 21.0955893 },
    { lat: 48.9916029, lng: 21.094557 },
    { lat: 48.9919911, lng: 21.0926629 },
    { lat: 48.9922708, lng: 21.091704 },
    { lat: 48.992421, lng: 21.0912621 },
    { lat: 48.992831, lng: 21.0904006 },
    { lat: 48.9930284, lng: 21.0894341 },
    { lat: 48.9935161, lng: 21.0881138 },
    { lat: 48.993456, lng: 21.086217 },
    { lat: 48.9934975, lng: 21.0856897 },
    { lat: 48.9931491, lng: 21.0842007 },
    { lat: 48.9925652, lng: 21.0829493 },
    { lat: 48.9924945, lng: 21.0822189 },
    { lat: 48.9924672, lng: 21.0804283 },
    { lat: 48.9916399, lng: 21.0806702 },
    { lat: 48.9915584, lng: 21.0807615 },
    { lat: 48.9915047, lng: 21.0809447 },
    { lat: 48.9915526, lng: 21.0777223 },
    { lat: 48.9916309, lng: 21.0762393 },
    { lat: 48.9915913, lng: 21.0756782 },
    { lat: 48.9911893, lng: 21.0741013 },
    { lat: 48.9909494, lng: 21.0735024 },
    { lat: 48.9907559, lng: 21.0724629 },
    { lat: 48.9902081, lng: 21.0717702 },
    { lat: 48.9899509, lng: 21.0711601 },
    { lat: 48.9888612, lng: 21.0697615 },
    { lat: 48.9878026, lng: 21.0696334 },
    { lat: 48.9872009, lng: 21.0698195 },
    { lat: 48.9863004, lng: 21.0689859 },
    { lat: 48.9857928, lng: 21.0688432 },
    { lat: 48.9850264, lng: 21.0687367 },
  ],
  ŠarišskéBohdanovce: [
    { lat: 48.8753286, lng: 21.3209474 },
    { lat: 48.8757127, lng: 21.3162036 },
    { lat: 48.8761076, lng: 21.3113371 },
    { lat: 48.8759325, lng: 21.3110919 },
    { lat: 48.8756112, lng: 21.3109638 },
    { lat: 48.8739391, lng: 21.3109628 },
    { lat: 48.8728402, lng: 21.3107212 },
    { lat: 48.8715216, lng: 21.3108757 },
    { lat: 48.8710428, lng: 21.3107478 },
    { lat: 48.8699145, lng: 21.3101284 },
    { lat: 48.868249, lng: 21.3094596 },
    { lat: 48.8661075, lng: 21.3094927 },
    { lat: 48.862141, lng: 21.309905 },
    { lat: 48.8607106, lng: 21.3098668 },
    { lat: 48.859718, lng: 21.3094568 },
    { lat: 48.8591637, lng: 21.3093993 },
    { lat: 48.8586128, lng: 21.3095216 },
    { lat: 48.8578687, lng: 21.3095553 },
    { lat: 48.8576566, lng: 21.3093896 },
    { lat: 48.8571909, lng: 21.3093588 },
    { lat: 48.8568837, lng: 21.3092044 },
    { lat: 48.8561281, lng: 21.3094494 },
    { lat: 48.8551401, lng: 21.3096676 },
    { lat: 48.8541732, lng: 21.3095896 },
    { lat: 48.853294, lng: 21.304283 },
    { lat: 48.8533562, lng: 21.3040321 },
    { lat: 48.8515224, lng: 21.2996496 },
    { lat: 48.8504248, lng: 21.2968267 },
    { lat: 48.8506815, lng: 21.2955403 },
    { lat: 48.8506398, lng: 21.2942426 },
    { lat: 48.8458676, lng: 21.297278 },
    { lat: 48.8452931, lng: 21.2964843 },
    { lat: 48.8448498, lng: 21.2969266 },
    { lat: 48.8446427, lng: 21.2969264 },
    { lat: 48.844563, lng: 21.2969727 },
    { lat: 48.8444988, lng: 21.2971162 },
    { lat: 48.8443946, lng: 21.2973178 },
    { lat: 48.844323, lng: 21.2975529 },
    { lat: 48.8443116, lng: 21.2979542 },
    { lat: 48.8444128, lng: 21.2986117 },
    { lat: 48.8448622, lng: 21.2993698 },
    { lat: 48.8451542, lng: 21.3002701 },
    { lat: 48.8451817, lng: 21.3008095 },
    { lat: 48.8447718, lng: 21.3012793 },
    { lat: 48.8441117, lng: 21.3012551 },
    { lat: 48.8437535, lng: 21.3010618 },
    { lat: 48.8433901, lng: 21.3006874 },
    { lat: 48.8430086, lng: 21.3005808 },
    { lat: 48.8423401, lng: 21.3006497 },
    { lat: 48.8419615, lng: 21.3003385 },
    { lat: 48.8414962, lng: 21.2996244 },
    { lat: 48.8412628, lng: 21.2996007 },
    { lat: 48.8410842, lng: 21.2997303 },
    { lat: 48.8409314, lng: 21.2999557 },
    { lat: 48.8408334, lng: 21.3003602 },
    { lat: 48.8408163, lng: 21.3006522 },
    { lat: 48.8409173, lng: 21.3013046 },
    { lat: 48.8411586, lng: 21.3016171 },
    { lat: 48.8415621, lng: 21.3018569 },
    { lat: 48.841782, lng: 21.3023585 },
    { lat: 48.8418647, lng: 21.3027049 },
    { lat: 48.8418431, lng: 21.3031019 },
    { lat: 48.8417624, lng: 21.3034895 },
    { lat: 48.8416099, lng: 21.3038716 },
    { lat: 48.8412448, lng: 21.3040779 },
    { lat: 48.8406466, lng: 21.304055 },
    { lat: 48.8404638, lng: 21.3039281 },
    { lat: 48.8403932, lng: 21.3037554 },
    { lat: 48.8402021, lng: 21.3027221 },
    { lat: 48.8401134, lng: 21.3024725 },
    { lat: 48.8398413, lng: 21.3021496 },
    { lat: 48.8394283, lng: 21.3019266 },
    { lat: 48.8390994, lng: 21.3018749 },
    { lat: 48.8389825, lng: 21.3019556 },
    { lat: 48.8386816, lng: 21.3023823 },
    { lat: 48.8383074, lng: 21.3027386 },
    { lat: 48.8380778, lng: 21.3028089 },
    { lat: 48.8377752, lng: 21.302779 },
    { lat: 48.8376117, lng: 21.3026507 },
    { lat: 48.8374985, lng: 21.3021164 },
    { lat: 48.837597, lng: 21.3015776 },
    { lat: 48.8376482, lng: 21.3009671 },
    { lat: 48.8375357, lng: 21.2996421 },
    { lat: 48.8373749, lng: 21.2991622 },
    { lat: 48.8372182, lng: 21.2989652 },
    { lat: 48.8368514, lng: 21.2987523 },
    { lat: 48.8365705, lng: 21.2986901 },
    { lat: 48.8359557, lng: 21.2990431 },
    { lat: 48.8358861, lng: 21.299198 },
    { lat: 48.8357756, lng: 21.300007 },
    { lat: 48.8354354, lng: 21.3007366 },
    { lat: 48.8353753, lng: 21.3009762 },
    { lat: 48.8351772, lng: 21.3019377 },
    { lat: 48.8351389, lng: 21.3025233 },
    { lat: 48.834969, lng: 21.3027842 },
    { lat: 48.8346766, lng: 21.3027906 },
    { lat: 48.8343864, lng: 21.3026473 },
    { lat: 48.8343021, lng: 21.3023942 },
    { lat: 48.8342496, lng: 21.3014842 },
    { lat: 48.8343332, lng: 21.3008975 },
    { lat: 48.8341869, lng: 21.300787 },
    { lat: 48.8341797, lng: 21.3008839 },
    { lat: 48.8341089, lng: 21.3009282 },
    { lat: 48.8334339, lng: 21.3011171 },
    { lat: 48.8330878, lng: 21.3009488 },
    { lat: 48.8330466, lng: 21.3007824 },
    { lat: 48.8329406, lng: 21.3006601 },
    { lat: 48.8328266, lng: 21.3006877 },
    { lat: 48.8313748, lng: 21.3017524 },
    { lat: 48.8303194, lng: 21.3024223 },
    { lat: 48.829372, lng: 21.30327 },
    { lat: 48.829756, lng: 21.303997 },
    { lat: 48.830104, lng: 21.304351 },
    { lat: 48.830507, lng: 21.305195 },
    { lat: 48.830638, lng: 21.305403 },
    { lat: 48.830947, lng: 21.305961 },
    { lat: 48.831057, lng: 21.306163 },
    { lat: 48.831153, lng: 21.306299 },
    { lat: 48.831353, lng: 21.306565 },
    { lat: 48.83171, lng: 21.30704 },
    { lat: 48.832244, lng: 21.307751 },
    { lat: 48.83245, lng: 21.308026 },
    { lat: 48.832743, lng: 21.308813 },
    { lat: 48.833181, lng: 21.309783 },
    { lat: 48.833582, lng: 21.310362 },
    { lat: 48.834039, lng: 21.311604 },
    { lat: 48.834749, lng: 21.312841 },
    { lat: 48.834779, lng: 21.313164 },
    { lat: 48.834796, lng: 21.313212 },
    { lat: 48.834835, lng: 21.313337 },
    { lat: 48.835272, lng: 21.31392 },
    { lat: 48.835566, lng: 21.314124 },
    { lat: 48.835602, lng: 21.314284 },
    { lat: 48.835699, lng: 21.314574 },
    { lat: 48.835742, lng: 21.314691 },
    { lat: 48.835787, lng: 21.315002 },
    { lat: 48.835824, lng: 21.315091 },
    { lat: 48.835947, lng: 21.315109 },
    { lat: 48.836147, lng: 21.31533 },
    { lat: 48.836194, lng: 21.31547 },
    { lat: 48.836194, lng: 21.315593 },
    { lat: 48.836207, lng: 21.315844 },
    { lat: 48.836227, lng: 21.315974 },
    { lat: 48.836359, lng: 21.31607 },
    { lat: 48.836575, lng: 21.316074 },
    { lat: 48.836639, lng: 21.316104 },
    { lat: 48.836659, lng: 21.316142 },
    { lat: 48.836628, lng: 21.316225 },
    { lat: 48.836555, lng: 21.316561 },
    { lat: 48.836534, lng: 21.316785 },
    { lat: 48.836548, lng: 21.316844 },
    { lat: 48.836647, lng: 21.316939 },
    { lat: 48.836693, lng: 21.316938 },
    { lat: 48.836769, lng: 21.316904 },
    { lat: 48.836796, lng: 21.316824 },
    { lat: 48.836958, lng: 21.316577 },
    { lat: 48.837027, lng: 21.316574 },
    { lat: 48.837154, lng: 21.316799 },
    { lat: 48.837121, lng: 21.316829 },
    { lat: 48.836365, lng: 21.317496 },
    { lat: 48.836818, lng: 21.318655 },
    { lat: 48.83669, lng: 21.318804 },
    { lat: 48.836688, lng: 21.319136 },
    { lat: 48.836719, lng: 21.319226 },
    { lat: 48.836762, lng: 21.319293 },
    { lat: 48.836881, lng: 21.319355 },
    { lat: 48.835428, lng: 21.320865 },
    { lat: 48.835382, lng: 21.320912 },
    { lat: 48.835348, lng: 21.320948 },
    { lat: 48.835431, lng: 21.321133 },
    { lat: 48.835617, lng: 21.321596 },
    { lat: 48.835657, lng: 21.3217263 },
    { lat: 48.835264, lng: 21.322433 },
    { lat: 48.834971, lng: 21.323034 },
    { lat: 48.834529, lng: 21.324152 },
    { lat: 48.834114, lng: 21.324826 },
    { lat: 48.833994, lng: 21.325064 },
    { lat: 48.833855, lng: 21.325343 },
    { lat: 48.833721, lng: 21.325564 },
    { lat: 48.833523, lng: 21.326015 },
    { lat: 48.83374, lng: 21.328511 },
    { lat: 48.833997, lng: 21.331295 },
    { lat: 48.83418, lng: 21.333146 },
    { lat: 48.834324, lng: 21.333465 },
    { lat: 48.834746, lng: 21.33444 },
    { lat: 48.83511, lng: 21.335273 },
    { lat: 48.835464, lng: 21.336076 },
    { lat: 48.835753, lng: 21.336731 },
    { lat: 48.835864, lng: 21.336987 },
    { lat: 48.83626, lng: 21.337878 },
    { lat: 48.836754, lng: 21.33788 },
    { lat: 48.837474, lng: 21.337882 },
    { lat: 48.83775, lng: 21.337883 },
    { lat: 48.839565, lng: 21.337876 },
    { lat: 48.839396, lng: 21.338722 },
    { lat: 48.839138, lng: 21.339471 },
    { lat: 48.8437734, lng: 21.3431499 },
    { lat: 48.8441258, lng: 21.3449476 },
    { lat: 48.8465289, lng: 21.3444467 },
    { lat: 48.8472882, lng: 21.3443632 },
    { lat: 48.8471758, lng: 21.3428347 },
    { lat: 48.847156, lng: 21.341772 },
    { lat: 48.8475739, lng: 21.341333 },
    { lat: 48.8477066, lng: 21.3405385 },
    { lat: 48.8475278, lng: 21.3387589 },
    { lat: 48.8484041, lng: 21.3364294 },
    { lat: 48.8483885, lng: 21.3362797 },
    { lat: 48.8488007, lng: 21.3361783 },
    { lat: 48.8488569, lng: 21.3362898 },
    { lat: 48.8492842, lng: 21.3361641 },
    { lat: 48.8493984, lng: 21.3361834 },
    { lat: 48.8494262, lng: 21.3360657 },
    { lat: 48.8496505, lng: 21.3358169 },
    { lat: 48.8497172, lng: 21.3358626 },
    { lat: 48.849788, lng: 21.335747 },
    { lat: 48.8499577, lng: 21.335667 },
    { lat: 48.8500632, lng: 21.3358012 },
    { lat: 48.8500986, lng: 21.3357466 },
    { lat: 48.8502169, lng: 21.3357934 },
    { lat: 48.8504646, lng: 21.3357573 },
    { lat: 48.8505761, lng: 21.3356839 },
    { lat: 48.8506922, lng: 21.3357287 },
    { lat: 48.8510595, lng: 21.3356701 },
    { lat: 48.8511172, lng: 21.3357147 },
    { lat: 48.851234, lng: 21.3356005 },
    { lat: 48.851387, lng: 21.3355981 },
    { lat: 48.8514498, lng: 21.3354762 },
    { lat: 48.8515591, lng: 21.3354104 },
    { lat: 48.8518937, lng: 21.3353079 },
    { lat: 48.8521377, lng: 21.3350802 },
    { lat: 48.8523523, lng: 21.3349921 },
    { lat: 48.852272, lng: 21.3356841 },
    { lat: 48.8531942, lng: 21.3355585 },
    { lat: 48.8534709, lng: 21.3355958 },
    { lat: 48.854287, lng: 21.3360885 },
    { lat: 48.8541863, lng: 21.3372293 },
    { lat: 48.8546963, lng: 21.3373838 },
    { lat: 48.8554564, lng: 21.3377725 },
    { lat: 48.8575661, lng: 21.3321291 },
    { lat: 48.8587069, lng: 21.3288773 },
    { lat: 48.8652021, lng: 21.3282862 },
    { lat: 48.8647927, lng: 21.3216992 },
    { lat: 48.8650697, lng: 21.3219688 },
    { lat: 48.865222, lng: 21.3222027 },
    { lat: 48.8653267, lng: 21.3227247 },
    { lat: 48.8654432, lng: 21.3228943 },
    { lat: 48.8658786, lng: 21.3230009 },
    { lat: 48.866021, lng: 21.3228738 },
    { lat: 48.8660688, lng: 21.3224845 },
    { lat: 48.8661232, lng: 21.322456 },
    { lat: 48.866308, lng: 21.3228771 },
    { lat: 48.8668021, lng: 21.3231766 },
    { lat: 48.8670231, lng: 21.3229658 },
    { lat: 48.8671056, lng: 21.3222727 },
    { lat: 48.8671923, lng: 21.3219721 },
    { lat: 48.8672799, lng: 21.3218613 },
    { lat: 48.8676676, lng: 21.3216206 },
    { lat: 48.8677002, lng: 21.3211705 },
    { lat: 48.8677467, lng: 21.3210708 },
    { lat: 48.8678437, lng: 21.3210808 },
    { lat: 48.8680868, lng: 21.321485 },
    { lat: 48.8687944, lng: 21.3215159 },
    { lat: 48.8691691, lng: 21.3216328 },
    { lat: 48.8740638, lng: 21.3211368 },
    { lat: 48.8753286, lng: 21.3209474 },
  ],
  Krížovany: [
    { lat: 48.9766599, lng: 21.0162699 },
    { lat: 48.9764466, lng: 21.0171488 },
    { lat: 48.9763718, lng: 21.0173726 },
    { lat: 48.9760458, lng: 21.0178942 },
    { lat: 48.975815, lng: 21.0181693 },
    { lat: 48.9753635, lng: 21.018542 },
    { lat: 48.9750251, lng: 21.0191677 },
    { lat: 48.9744395, lng: 21.020463 },
    { lat: 48.9746227, lng: 21.0205903 },
    { lat: 48.9741418, lng: 21.0220945 },
    { lat: 48.9736601, lng: 21.0231265 },
    { lat: 48.9724706, lng: 21.0249893 },
    { lat: 48.9717583, lng: 21.0264341 },
    { lat: 48.9714651, lng: 21.026721 },
    { lat: 48.9714594, lng: 21.0290987 },
    { lat: 48.969843, lng: 21.0339413 },
    { lat: 48.9696323, lng: 21.0341431 },
    { lat: 48.9695519, lng: 21.0350242 },
    { lat: 48.9692277, lng: 21.0354377 },
    { lat: 48.9690682, lng: 21.0358978 },
    { lat: 48.9687152, lng: 21.0362529 },
    { lat: 48.9685945, lng: 21.0365135 },
    { lat: 48.9684036, lng: 21.0366715 },
    { lat: 48.9681927, lng: 21.0370663 },
    { lat: 48.9677948, lng: 21.0375 },
    { lat: 48.9675401, lng: 21.0380049 },
    { lat: 48.9667559, lng: 21.039977 },
    { lat: 48.9664516, lng: 21.0398667 },
    { lat: 48.9663571, lng: 21.0393838 },
    { lat: 48.9661442, lng: 21.0391699 },
    { lat: 48.9657473, lng: 21.0389758 },
    { lat: 48.9656594, lng: 21.0387955 },
    { lat: 48.9655033, lng: 21.0387034 },
    { lat: 48.9653649, lng: 21.0381908 },
    { lat: 48.965448, lng: 21.0387011 },
    { lat: 48.9656133, lng: 21.0389025 },
    { lat: 48.9656869, lng: 21.0391545 },
    { lat: 48.9652692, lng: 21.0388793 },
    { lat: 48.9650725, lng: 21.0388511 },
    { lat: 48.964756, lng: 21.0385325 },
    { lat: 48.9646568, lng: 21.0384038 },
    { lat: 48.9642322, lng: 21.0377578 },
    { lat: 48.9641599, lng: 21.037409 },
    { lat: 48.9640313, lng: 21.0372621 },
    { lat: 48.9639706, lng: 21.0374028 },
    { lat: 48.96382, lng: 21.0373329 },
    { lat: 48.9633935, lng: 21.0380956 },
    { lat: 48.9625937, lng: 21.040613 },
    { lat: 48.9623129, lng: 21.0418568 },
    { lat: 48.9619394, lng: 21.0419611 },
    { lat: 48.9618056, lng: 21.0423139 },
    { lat: 48.9612815, lng: 21.0428386 },
    { lat: 48.9612462, lng: 21.0433116 },
    { lat: 48.9614161, lng: 21.044341 },
    { lat: 48.9613116, lng: 21.044524 },
    { lat: 48.9614973, lng: 21.0452146 },
    { lat: 48.961508, lng: 21.0466127 },
    { lat: 48.9614676, lng: 21.0475548 },
    { lat: 48.9608102, lng: 21.0483409 },
    { lat: 48.9605927, lng: 21.049403 },
    { lat: 48.9603759, lng: 21.0493129 },
    { lat: 48.9601143, lng: 21.0511323 },
    { lat: 48.959363, lng: 21.0523608 },
    { lat: 48.9604966, lng: 21.0521988 },
    { lat: 48.9612322, lng: 21.0519374 },
    { lat: 48.961634, lng: 21.0515598 },
    { lat: 48.962448, lng: 21.0528165 },
    { lat: 48.9634666, lng: 21.0531667 },
    { lat: 48.9645386, lng: 21.05325 },
    { lat: 48.9648959, lng: 21.0535108 },
    { lat: 48.9654651, lng: 21.0544023 },
    { lat: 48.9658639, lng: 21.0559398 },
    { lat: 48.9662505, lng: 21.0566459 },
    { lat: 48.9668045, lng: 21.0573895 },
    { lat: 48.9677089, lng: 21.058549 },
    { lat: 48.9684499, lng: 21.0585652 },
    { lat: 48.9690819, lng: 21.0583391 },
    { lat: 48.9696264, lng: 21.0584019 },
    { lat: 48.9700996, lng: 21.0590435 },
    { lat: 48.9707742, lng: 21.0601303 },
    { lat: 48.9719054, lng: 21.0629129 },
    { lat: 48.972262, lng: 21.0636067 },
    { lat: 48.9727152, lng: 21.0638733 },
    { lat: 48.9730048, lng: 21.0642662 },
    { lat: 48.9739381, lng: 21.0652095 },
    { lat: 48.9743132, lng: 21.0651188 },
    { lat: 48.9753182, lng: 21.0645235 },
    { lat: 48.9756914, lng: 21.0644773 },
    { lat: 48.9759507, lng: 21.0645235 },
    { lat: 48.9765131, lng: 21.0643765 },
    { lat: 48.9772279, lng: 21.064624 },
    { lat: 48.9785922, lng: 21.0657799 },
    { lat: 48.9796429, lng: 21.0669123 },
    { lat: 48.9807118, lng: 21.0687791 },
    { lat: 48.9820614, lng: 21.0689958 },
    { lat: 48.9832607, lng: 21.0690861 },
    { lat: 48.9843301, lng: 21.0689578 },
    { lat: 48.9850264, lng: 21.0687367 },
    { lat: 48.9858113, lng: 21.0683739 },
    { lat: 48.9867597, lng: 21.0673854 },
    { lat: 48.9871043, lng: 21.0674589 },
    { lat: 48.9918077, lng: 21.0652794 },
    { lat: 48.9919518, lng: 21.0652808 },
    { lat: 48.9921197, lng: 21.064763 },
    { lat: 48.9923872, lng: 21.0634682 },
    { lat: 48.9923411, lng: 21.0627073 },
    { lat: 48.9927755, lng: 21.0610044 },
    { lat: 48.993823, lng: 21.0589792 },
    { lat: 48.9940467, lng: 21.0581034 },
    { lat: 48.9941985, lng: 21.0576878 },
    { lat: 48.9945624, lng: 21.0571054 },
    { lat: 48.9947586, lng: 21.056495 },
    { lat: 48.9947382, lng: 21.056107 },
    { lat: 48.9945921, lng: 21.0556649 },
    { lat: 48.994952, lng: 21.0545115 },
    { lat: 48.9951755, lng: 21.0531447 },
    { lat: 48.9953497, lng: 21.0529316 },
    { lat: 48.9957734, lng: 21.0527404 },
    { lat: 48.9958205, lng: 21.0525634 },
    { lat: 48.995668, lng: 21.0508455 },
    { lat: 48.995576, lng: 21.0502367 },
    { lat: 48.9956543, lng: 21.0491477 },
    { lat: 48.9958723, lng: 21.0482999 },
    { lat: 48.9958866, lng: 21.0473931 },
    { lat: 48.9961086, lng: 21.0463661 },
    { lat: 48.9965092, lng: 21.0450142 },
    { lat: 48.9964487, lng: 21.0447786 },
    { lat: 48.9966788, lng: 21.0442771 },
    { lat: 48.9980969, lng: 21.0390655 },
    { lat: 48.9987103, lng: 21.0398609 },
    { lat: 48.9990213, lng: 21.038764 },
    { lat: 48.999248, lng: 21.0374663 },
    { lat: 48.9985055, lng: 21.0382738 },
    { lat: 48.9984568, lng: 21.0382635 },
    { lat: 48.9982973, lng: 21.0377533 },
    { lat: 48.9983057, lng: 21.0367666 },
    { lat: 48.998514, lng: 21.0366685 },
    { lat: 48.9983144, lng: 21.0364133 },
    { lat: 48.998088, lng: 21.0362024 },
    { lat: 48.9974692, lng: 21.0360659 },
    { lat: 48.9952786, lng: 21.0346393 },
    { lat: 48.9945121, lng: 21.0342906 },
    { lat: 48.9934417, lng: 21.0336359 },
    { lat: 48.9922654, lng: 21.0331469 },
    { lat: 48.9916786, lng: 21.0323729 },
    { lat: 48.9896056, lng: 21.0303454 },
    { lat: 48.9893066, lng: 21.0299429 },
    { lat: 48.9889828, lng: 21.0293664 },
    { lat: 48.9888583, lng: 21.0296274 },
    { lat: 48.9886991, lng: 21.0290571 },
    { lat: 48.988301, lng: 21.0281414 },
    { lat: 48.9877281, lng: 21.0274834 },
    { lat: 48.9872639, lng: 21.0264706 },
    { lat: 48.9870292, lng: 21.0260737 },
    { lat: 48.9863318, lng: 21.0252592 },
    { lat: 48.9859018, lng: 21.0249097 },
    { lat: 48.9846318, lng: 21.0241034 },
    { lat: 48.9837348, lng: 21.0246918 },
    { lat: 48.9834049, lng: 21.0249963 },
    { lat: 48.9830008, lng: 21.0255471 },
    { lat: 48.9828588, lng: 21.0259509 },
    { lat: 48.9822069, lng: 21.0253587 },
    { lat: 48.9814631, lng: 21.024847 },
    { lat: 48.9809614, lng: 21.0239952 },
    { lat: 48.980938, lng: 21.0232446 },
    { lat: 48.981001, lng: 21.0223885 },
    { lat: 48.9801117, lng: 21.0221752 },
    { lat: 48.9798095, lng: 21.0220203 },
    { lat: 48.9794823, lng: 21.021753 },
    { lat: 48.9778381, lng: 21.0188398 },
    { lat: 48.9772298, lng: 21.0176584 },
    { lat: 48.9766599, lng: 21.0162699 },
  ],
  Lipovce: [
    { lat: 49.093732, lng: 20.920124 },
    { lat: 49.092986, lng: 20.920168 },
    { lat: 49.090482, lng: 20.919932 },
    { lat: 49.088633, lng: 20.920309 },
    { lat: 49.0874, lng: 20.919565 },
    { lat: 49.08672, lng: 20.917387 },
    { lat: 49.086471, lng: 20.91659 },
    { lat: 49.085585, lng: 20.914823 },
    { lat: 49.082865, lng: 20.914686 },
    { lat: 49.080038, lng: 20.913252 },
    { lat: 49.079257, lng: 20.912969 },
    { lat: 49.078398, lng: 20.909815 },
    { lat: 49.07838, lng: 20.909752 },
    { lat: 49.078058, lng: 20.908702 },
    { lat: 49.077997, lng: 20.908506 },
    { lat: 49.07726, lng: 20.907955 },
    { lat: 49.075501, lng: 20.903168 },
    { lat: 49.075558, lng: 20.902754 },
    { lat: 49.073742, lng: 20.902333 },
    { lat: 49.073436, lng: 20.902262 },
    { lat: 49.072721, lng: 20.902096 },
    { lat: 49.072123, lng: 20.902145 },
    { lat: 49.071969, lng: 20.902158 },
    { lat: 49.071965, lng: 20.902158 },
    { lat: 49.071462, lng: 20.902044 },
    { lat: 49.069778, lng: 20.901618 },
    { lat: 49.067496, lng: 20.901064 },
    { lat: 49.065443, lng: 20.900422 },
    { lat: 49.064237, lng: 20.900046 },
    { lat: 49.061526, lng: 20.898176 },
    { lat: 49.060642, lng: 20.897612 },
    { lat: 49.060278, lng: 20.89727 },
    { lat: 49.054136, lng: 20.895353 },
    { lat: 49.053374, lng: 20.895204 },
    { lat: 49.053082, lng: 20.895188 },
    { lat: 49.051781, lng: 20.895438 },
    { lat: 49.051626, lng: 20.895497 },
    { lat: 49.050787, lng: 20.895586 },
    { lat: 49.049931, lng: 20.895421 },
    { lat: 49.049529, lng: 20.895003 },
    { lat: 49.048871, lng: 20.894085 },
    { lat: 49.047363, lng: 20.892744 },
    { lat: 49.046749, lng: 20.892501 },
    { lat: 49.045216, lng: 20.891431 },
    { lat: 49.044988, lng: 20.88946 },
    { lat: 49.044442, lng: 20.88677 },
    { lat: 49.044187, lng: 20.886282 },
    { lat: 49.044073, lng: 20.886074 },
    { lat: 49.043541, lng: 20.885409 },
    { lat: 49.043396, lng: 20.885445 },
    { lat: 49.042869, lng: 20.884823 },
    { lat: 49.042852, lng: 20.884832 },
    { lat: 49.042829, lng: 20.884803 },
    { lat: 49.042813, lng: 20.884788 },
    { lat: 49.042524, lng: 20.884519 },
    { lat: 49.042252, lng: 20.884501 },
    { lat: 49.0421, lng: 20.884406 },
    { lat: 49.041895, lng: 20.884226 },
    { lat: 49.0414655, lng: 20.8854136 },
    { lat: 49.0414573, lng: 20.8859283 },
    { lat: 49.0412538, lng: 20.8863691 },
    { lat: 49.0410962, lng: 20.8870982 },
    { lat: 49.0406397, lng: 20.8884453 },
    { lat: 49.0406452, lng: 20.8899309 },
    { lat: 49.0405211, lng: 20.8906273 },
    { lat: 49.0403727, lng: 20.8924958 },
    { lat: 49.0400978, lng: 20.8942842 },
    { lat: 49.0397661, lng: 20.8951586 },
    { lat: 49.0395279, lng: 20.8955719 },
    { lat: 49.0389703, lng: 20.8963247 },
    { lat: 49.03882, lng: 20.8967692 },
    { lat: 49.0388904, lng: 20.8985527 },
    { lat: 49.0389753, lng: 20.8989038 },
    { lat: 49.0393598, lng: 20.8990219 },
    { lat: 49.0393903, lng: 20.9001575 },
    { lat: 49.0399317, lng: 20.8998956 },
    { lat: 49.0420918, lng: 20.9001993 },
    { lat: 49.0425708, lng: 20.9024814 },
    { lat: 49.0428905, lng: 20.9040526 },
    { lat: 49.0430054, lng: 20.905125 },
    { lat: 49.0428754, lng: 20.9057444 },
    { lat: 49.0428994, lng: 20.9059186 },
    { lat: 49.0428539, lng: 20.9060722 },
    { lat: 49.0427274, lng: 20.9061617 },
    { lat: 49.0425951, lng: 20.9067432 },
    { lat: 49.0426006, lng: 20.9068712 },
    { lat: 49.0422973, lng: 20.9075578 },
    { lat: 49.0422599, lng: 20.9077487 },
    { lat: 49.0424268, lng: 20.9086287 },
    { lat: 49.04233, lng: 20.9089979 },
    { lat: 49.0421443, lng: 20.9093019 },
    { lat: 49.0420646, lng: 20.90964 },
    { lat: 49.0417796, lng: 20.9098677 },
    { lat: 49.0416238, lng: 20.9102193 },
    { lat: 49.0416528, lng: 20.910409 },
    { lat: 49.0414254, lng: 20.9109487 },
    { lat: 49.0413262, lng: 20.911379 },
    { lat: 49.041206, lng: 20.9116532 },
    { lat: 49.0412229, lng: 20.9118811 },
    { lat: 49.0411668, lng: 20.9121574 },
    { lat: 49.0412454, lng: 20.912453 },
    { lat: 49.0412763, lng: 20.9130361 },
    { lat: 49.0412261, lng: 20.9131186 },
    { lat: 49.0412455, lng: 20.9132889 },
    { lat: 49.0415444, lng: 20.9142809 },
    { lat: 49.0417174, lng: 20.9144038 },
    { lat: 49.0419716, lng: 20.9150047 },
    { lat: 49.0421678, lng: 20.9150095 },
    { lat: 49.0423631, lng: 20.9148981 },
    { lat: 49.0430541, lng: 20.9147916 },
    { lat: 49.0432858, lng: 20.9147894 },
    { lat: 49.0436529, lng: 20.9148975 },
    { lat: 49.0438083, lng: 20.9147283 },
    { lat: 49.044071, lng: 20.9147286 },
    { lat: 49.0443383, lng: 20.9148259 },
    { lat: 49.0445059, lng: 20.9149581 },
    { lat: 49.0444803, lng: 20.9150315 },
    { lat: 49.0447473, lng: 20.915339 },
    { lat: 49.0447978, lng: 20.9155005 },
    { lat: 49.0450344, lng: 20.9156791 },
    { lat: 49.0452122, lng: 20.9160147 },
    { lat: 49.0452771, lng: 20.9164021 },
    { lat: 49.0452589, lng: 20.9168973 },
    { lat: 49.0453043, lng: 20.916978 },
    { lat: 49.0451394, lng: 20.9175326 },
    { lat: 49.0451943, lng: 20.917725 },
    { lat: 49.0451818, lng: 20.9178881 },
    { lat: 49.0452718, lng: 20.9182216 },
    { lat: 49.0455234, lng: 20.9186775 },
    { lat: 49.0455491, lng: 20.9188014 },
    { lat: 49.0455479, lng: 20.9190684 },
    { lat: 49.0454805, lng: 20.9192728 },
    { lat: 49.0454875, lng: 20.9194829 },
    { lat: 49.0454057, lng: 20.9199273 },
    { lat: 49.0456218, lng: 20.9207644 },
    { lat: 49.0457849, lng: 20.9209301 },
    { lat: 49.0458152, lng: 20.921031 },
    { lat: 49.0458446, lng: 20.9214708 },
    { lat: 49.0458988, lng: 20.9215387 },
    { lat: 49.0459863, lng: 20.9220023 },
    { lat: 49.0461401, lng: 20.9223904 },
    { lat: 49.046194, lng: 20.9224906 },
    { lat: 49.0463002, lng: 20.9225354 },
    { lat: 49.0463371, lng: 20.9226599 },
    { lat: 49.0462695, lng: 20.9228923 },
    { lat: 49.0463012, lng: 20.9231346 },
    { lat: 49.046264, lng: 20.923404 },
    { lat: 49.0463208, lng: 20.9234807 },
    { lat: 49.0464104, lng: 20.9238368 },
    { lat: 49.046426, lng: 20.9241334 },
    { lat: 49.0461828, lng: 20.9244002 },
    { lat: 49.0461509, lng: 20.9246596 },
    { lat: 49.046091, lng: 20.9247848 },
    { lat: 49.0459289, lng: 20.9248541 },
    { lat: 49.0459108, lng: 20.9249811 },
    { lat: 49.0454966, lng: 20.9256917 },
    { lat: 49.0452905, lng: 20.926858 },
    { lat: 49.0454279, lng: 20.9271822 },
    { lat: 49.0454459, lng: 20.9281521 },
    { lat: 49.0457, lng: 20.9291166 },
    { lat: 49.046063, lng: 20.9299555 },
    { lat: 49.0462263, lng: 20.9306018 },
    { lat: 49.0461302, lng: 20.9310057 },
    { lat: 49.0462064, lng: 20.9318254 },
    { lat: 49.0462997, lng: 20.9321228 },
    { lat: 49.0467144, lng: 20.9329955 },
    { lat: 49.0476596, lng: 20.9338474 },
    { lat: 49.0475967, lng: 20.9350208 },
    { lat: 49.047481, lng: 20.9352655 },
    { lat: 49.0474354, lng: 20.9359898 },
    { lat: 49.0467133, lng: 20.9367109 },
    { lat: 49.0466533, lng: 20.9370019 },
    { lat: 49.046122, lng: 20.9381847 },
    { lat: 49.0457816, lng: 20.9396845 },
    { lat: 49.0458654, lng: 20.9406034 },
    { lat: 49.0459297, lng: 20.9420899 },
    { lat: 49.0460125, lng: 20.945178 },
    { lat: 49.0463867, lng: 20.9451265 },
    { lat: 49.0465482, lng: 20.9451811 },
    { lat: 49.0463776, lng: 20.9468483 },
    { lat: 49.0461363, lng: 20.9482464 },
    { lat: 49.0459741, lng: 20.9497623 },
    { lat: 49.0453558, lng: 20.9526874 },
    { lat: 49.0453786, lng: 20.956456 },
    { lat: 49.0445314, lng: 20.9568657 },
    { lat: 49.0442715, lng: 20.9570432 },
    { lat: 49.0435875, lng: 20.9577287 },
    { lat: 49.0427859, lng: 20.9582905 },
    { lat: 49.0424246, lng: 20.9583976 },
    { lat: 49.0417452, lng: 20.9583358 },
    { lat: 49.0428701, lng: 20.9602486 },
    { lat: 49.0436965, lng: 20.9621568 },
    { lat: 49.0443096, lng: 20.9632943 },
    { lat: 49.0449696, lng: 20.9650733 },
    { lat: 49.0457753, lng: 20.9659489 },
    { lat: 49.045853, lng: 20.9668991 },
    { lat: 49.0458454, lng: 20.9673151 },
    { lat: 49.0460511, lng: 20.9681543 },
    { lat: 49.0463532, lng: 20.968815 },
    { lat: 49.0468517, lng: 20.9704066 },
    { lat: 49.0478553, lng: 20.9746089 },
    { lat: 49.0478512, lng: 20.9765934 },
    { lat: 49.0484359, lng: 20.9799453 },
    { lat: 49.0491703, lng: 20.9825474 },
    { lat: 49.0496895, lng: 20.9840563 },
    { lat: 49.0499876, lng: 20.9852151 },
    { lat: 49.0502102, lng: 20.985413 },
    { lat: 49.0503465, lng: 20.9853462 },
    { lat: 49.050571, lng: 20.9850531 },
    { lat: 49.0509928, lng: 20.9849427 },
    { lat: 49.0514536, lng: 20.9844134 },
    { lat: 49.0517596, lng: 20.9845823 },
    { lat: 49.0519246, lng: 20.9845299 },
    { lat: 49.0520671, lng: 20.9842448 },
    { lat: 49.052158, lng: 20.9835916 },
    { lat: 49.0524132, lng: 20.9833127 },
    { lat: 49.0525475, lng: 20.983423 },
    { lat: 49.0529435, lng: 20.9833174 },
    { lat: 49.0532379, lng: 20.9828922 },
    { lat: 49.0531961, lng: 20.9826823 },
    { lat: 49.0533181, lng: 20.9823931 },
    { lat: 49.053668, lng: 20.9822827 },
    { lat: 49.0538934, lng: 20.981889 },
    { lat: 49.0539428, lng: 20.9819473 },
    { lat: 49.0542178, lng: 20.9813859 },
    { lat: 49.054476, lng: 20.9810891 },
    { lat: 49.0546478, lng: 20.9811158 },
    { lat: 49.0547462, lng: 20.9810605 },
    { lat: 49.0552976, lng: 20.9804733 },
    { lat: 49.0553364, lng: 20.9803221 },
    { lat: 49.0556061, lng: 20.9799394 },
    { lat: 49.0560388, lng: 20.9796805 },
    { lat: 49.0564262, lng: 20.9792429 },
    { lat: 49.0570989, lng: 20.9787267 },
    { lat: 49.0572611, lng: 20.9787318 },
    { lat: 49.0573987, lng: 20.9786355 },
    { lat: 49.0578808, lng: 20.977793 },
    { lat: 49.0581377, lng: 20.9774672 },
    { lat: 49.0585802, lng: 20.9770633 },
    { lat: 49.0587645, lng: 20.9770876 },
    { lat: 49.0589573, lng: 20.9769996 },
    { lat: 49.0592918, lng: 20.9763244 },
    { lat: 49.0596093, lng: 20.9760894 },
    { lat: 49.0599741, lng: 20.9755834 },
    { lat: 49.0602875, lng: 20.975362 },
    { lat: 49.0608319, lng: 20.9748089 },
    { lat: 49.0608706, lng: 20.9748239 },
    { lat: 49.0613671, lng: 20.9739769 },
    { lat: 49.0622488, lng: 20.9735816 },
    { lat: 49.0628851, lng: 20.9730469 },
    { lat: 49.0638926, lng: 20.9726121 },
    { lat: 49.0639487, lng: 20.9725038 },
    { lat: 49.064706, lng: 20.9719575 },
    { lat: 49.0648566, lng: 20.9719194 },
    { lat: 49.0655319, lng: 20.9712381 },
    { lat: 49.0657525, lng: 20.9705909 },
    { lat: 49.0664947, lng: 20.9693549 },
    { lat: 49.0670286, lng: 20.9689244 },
    { lat: 49.0676044, lng: 20.969078 },
    { lat: 49.0684802, lng: 20.968953 },
    { lat: 49.0689193, lng: 20.9689882 },
    { lat: 49.069588, lng: 20.9692315 },
    { lat: 49.0701595, lng: 20.9696676 },
    { lat: 49.0703312, lng: 20.9699591 },
    { lat: 49.0720732, lng: 20.9715786 },
    { lat: 49.0720813, lng: 20.9715873 },
    { lat: 49.0721523, lng: 20.9711827 },
    { lat: 49.072688, lng: 20.9710418 },
    { lat: 49.0736544, lng: 20.970628 },
    { lat: 49.0746382, lng: 20.9704936 },
    { lat: 49.0754117, lng: 20.9701941 },
    { lat: 49.0757228, lng: 20.9701461 },
    { lat: 49.0764398, lng: 20.9697414 },
    { lat: 49.077694, lng: 20.9693123 },
    { lat: 49.077752, lng: 20.968632 },
    { lat: 49.077314, lng: 20.967144 },
    { lat: 49.076705, lng: 20.96553 },
    { lat: 49.076793, lng: 20.964431 },
    { lat: 49.077317, lng: 20.963381 },
    { lat: 49.077697, lng: 20.962471 },
    { lat: 49.077101, lng: 20.962582 },
    { lat: 49.07707, lng: 20.960948 },
    { lat: 49.07703, lng: 20.958774 },
    { lat: 49.07718, lng: 20.956353 },
    { lat: 49.077219, lng: 20.955745 },
    { lat: 49.077008, lng: 20.95394 },
    { lat: 49.078602, lng: 20.953798 },
    { lat: 49.07932, lng: 20.953066 },
    { lat: 49.079432, lng: 20.952951 },
    { lat: 49.079497, lng: 20.952885 },
    { lat: 49.079514, lng: 20.952827 },
    { lat: 49.079545, lng: 20.952722 },
    { lat: 49.079681, lng: 20.952256 },
    { lat: 49.07981, lng: 20.951817 },
    { lat: 49.079835, lng: 20.95173 },
    { lat: 49.079866, lng: 20.951623 },
    { lat: 49.080154, lng: 20.950638 },
    { lat: 49.079553, lng: 20.94935 },
    { lat: 49.078626, lng: 20.945649 },
    { lat: 49.079392, lng: 20.944402 },
    { lat: 49.078265, lng: 20.941332 },
    { lat: 49.077421, lng: 20.940021 },
    { lat: 49.077433, lng: 20.939643 },
    { lat: 49.077444, lng: 20.93928 },
    { lat: 49.077535, lng: 20.936502 },
    { lat: 49.078688, lng: 20.935523 },
    { lat: 49.078246, lng: 20.935238 },
    { lat: 49.078439, lng: 20.934537 },
    { lat: 49.078533, lng: 20.934198 },
    { lat: 49.078951, lng: 20.932359 },
    { lat: 49.079571, lng: 20.932507 },
    { lat: 49.080589, lng: 20.932743 },
    { lat: 49.081273, lng: 20.932838 },
    { lat: 49.081922, lng: 20.933369 },
    { lat: 49.082237, lng: 20.931891 },
    { lat: 49.082609, lng: 20.930828 },
    { lat: 49.082745, lng: 20.93044 },
    { lat: 49.083707, lng: 20.929283 },
    { lat: 49.084995, lng: 20.926788 },
    { lat: 49.085626, lng: 20.925768 },
    { lat: 49.085652, lng: 20.925727 },
    { lat: 49.085995, lng: 20.925171 },
    { lat: 49.086559, lng: 20.923996 },
    { lat: 49.087062, lng: 20.923101 },
    { lat: 49.088119, lng: 20.923686 },
    { lat: 49.089115, lng: 20.924279 },
    { lat: 49.090448, lng: 20.922796 },
    { lat: 49.091083, lng: 20.922236 },
    { lat: 49.091578, lng: 20.921781 },
    { lat: 49.093326, lng: 20.920808 },
    { lat: 49.093379, lng: 20.920779 },
    { lat: 49.09341, lng: 20.920704 },
    { lat: 49.093612, lng: 20.92034 },
    { lat: 49.093732, lng: 20.920124 },
  ],
  Rokycany: [
    { lat: 48.9768734, lng: 21.1312645 },
    { lat: 48.9765537, lng: 21.1307678 },
    { lat: 48.9766058, lng: 21.1298938 },
    { lat: 48.9763523, lng: 21.1286138 },
    { lat: 48.9762274, lng: 21.1272688 },
    { lat: 48.9762328, lng: 21.1261335 },
    { lat: 48.9761672, lng: 21.1254878 },
    { lat: 48.9763787, lng: 21.124741 },
    { lat: 48.9758784, lng: 21.1220369 },
    { lat: 48.9753494, lng: 21.1224273 },
    { lat: 48.9737321, lng: 21.1235888 },
    { lat: 48.9723072, lng: 21.1214669 },
    { lat: 48.971945, lng: 21.1210838 },
    { lat: 48.9717669, lng: 21.1207566 },
    { lat: 48.9711278, lng: 21.1200546 },
    { lat: 48.9709468, lng: 21.1197821 },
    { lat: 48.9704307, lng: 21.1187011 },
    { lat: 48.9698484, lng: 21.1178717 },
    { lat: 48.9695632, lng: 21.1173691 },
    { lat: 48.9692065, lng: 21.1170916 },
    { lat: 48.9684176, lng: 21.1158559 },
    { lat: 48.9666834, lng: 21.1152259 },
    { lat: 48.9659022, lng: 21.115108 },
    { lat: 48.9653145, lng: 21.1152788 },
    { lat: 48.9643259, lng: 21.1160762 },
    { lat: 48.963431, lng: 21.1171995 },
    { lat: 48.963182, lng: 21.1176444 },
    { lat: 48.9629549, lng: 21.1183223 },
    { lat: 48.9626776, lng: 21.1187657 },
    { lat: 48.9618727, lng: 21.1196738 },
    { lat: 48.9615967, lng: 21.1201235 },
    { lat: 48.9614919, lng: 21.1204009 },
    { lat: 48.9624505, lng: 21.1224753 },
    { lat: 48.9623297, lng: 21.1226088 },
    { lat: 48.9619797, lng: 21.122785 },
    { lat: 48.9613537, lng: 21.1232303 },
    { lat: 48.9608755, lng: 21.1238328 },
    { lat: 48.9605852, lng: 21.1240096 },
    { lat: 48.959844, lng: 21.1247657 },
    { lat: 48.9588285, lng: 21.1255315 },
    { lat: 48.9548789, lng: 21.1293318 },
    { lat: 48.9537973, lng: 21.1309865 },
    { lat: 48.9531802, lng: 21.131329 },
    { lat: 48.9523384, lng: 21.1322508 },
    { lat: 48.9517303, lng: 21.131951 },
    { lat: 48.951667, lng: 21.1317698 },
    { lat: 48.9511433, lng: 21.1313653 },
    { lat: 48.9504908, lng: 21.1315879 },
    { lat: 48.9502048, lng: 21.1318635 },
    { lat: 48.9496982, lng: 21.1321423 },
    { lat: 48.9491137, lng: 21.1326202 },
    { lat: 48.9485557, lng: 21.1332656 },
    { lat: 48.9478759, lng: 21.1337261 },
    { lat: 48.9474506, lng: 21.1339016 },
    { lat: 48.9469585, lng: 21.1347042 },
    { lat: 48.9464889, lng: 21.1350891 },
    { lat: 48.946579, lng: 21.1354066 },
    { lat: 48.9465472, lng: 21.1356094 },
    { lat: 48.9466378, lng: 21.1358043 },
    { lat: 48.9466662, lng: 21.1361696 },
    { lat: 48.9465359, lng: 21.136492 },
    { lat: 48.946574, lng: 21.1366679 },
    { lat: 48.946489, lng: 21.1371847 },
    { lat: 48.9465374, lng: 21.1373934 },
    { lat: 48.9468102, lng: 21.137903 },
    { lat: 48.9467125, lng: 21.1381912 },
    { lat: 48.9468309, lng: 21.1386959 },
    { lat: 48.9467811, lng: 21.1390433 },
    { lat: 48.9469471, lng: 21.1393763 },
    { lat: 48.9469989, lng: 21.1399457 },
    { lat: 48.9469652, lng: 21.1400517 },
    { lat: 48.947041, lng: 21.1404929 },
    { lat: 48.947039, lng: 21.140748 },
    { lat: 48.946912, lng: 21.1413241 },
    { lat: 48.9470766, lng: 21.1421491 },
    { lat: 48.9469137, lng: 21.1428617 },
    { lat: 48.9467555, lng: 21.1431976 },
    { lat: 48.9467348, lng: 21.1435063 },
    { lat: 48.9467693, lng: 21.1438117 },
    { lat: 48.9465196, lng: 21.1443484 },
    { lat: 48.9465303, lng: 21.1447139 },
    { lat: 48.9466489, lng: 21.1449455 },
    { lat: 48.946597, lng: 21.1452572 },
    { lat: 48.946753, lng: 21.1455658 },
    { lat: 48.946652, lng: 21.1458604 },
    { lat: 48.9467361, lng: 21.1466992 },
    { lat: 48.946854, lng: 21.1467263 },
    { lat: 48.9469539, lng: 21.1470746 },
    { lat: 48.946897, lng: 21.1471238 },
    { lat: 48.9469255, lng: 21.1473842 },
    { lat: 48.946756, lng: 21.1476454 },
    { lat: 48.946851, lng: 21.14798 },
    { lat: 48.9468526, lng: 21.1484908 },
    { lat: 48.9467965, lng: 21.148747 },
    { lat: 48.9468011, lng: 21.1496424 },
    { lat: 48.9466903, lng: 21.1496974 },
    { lat: 48.9466664, lng: 21.1500046 },
    { lat: 48.9465688, lng: 21.1502308 },
    { lat: 48.9464974, lng: 21.1506427 },
    { lat: 48.9465922, lng: 21.1509323 },
    { lat: 48.9464173, lng: 21.15138 },
    { lat: 48.9464224, lng: 21.1518619 },
    { lat: 48.9463123, lng: 21.1520042 },
    { lat: 48.9463321, lng: 21.152422 },
    { lat: 48.9461949, lng: 21.1529594 },
    { lat: 48.9461308, lng: 21.1534531 },
    { lat: 48.9463663, lng: 21.1535706 },
    { lat: 48.9463714, lng: 21.1537002 },
    { lat: 48.946246, lng: 21.1537544 },
    { lat: 48.9462338, lng: 21.1538312 },
    { lat: 48.9462416, lng: 21.1538983 },
    { lat: 48.9464189, lng: 21.154025 },
    { lat: 48.9463287, lng: 21.1543055 },
    { lat: 48.9464047, lng: 21.1544639 },
    { lat: 48.9462773, lng: 21.1547081 },
    { lat: 48.9463355, lng: 21.1551979 },
    { lat: 48.9462629, lng: 21.1554295 },
    { lat: 48.9463614, lng: 21.1560177 },
    { lat: 48.9462173, lng: 21.1567614 },
    { lat: 48.94602, lng: 21.157108 },
    { lat: 48.9460295, lng: 21.157335 },
    { lat: 48.9461745, lng: 21.1576166 },
    { lat: 48.9461168, lng: 21.1580676 },
    { lat: 48.9461854, lng: 21.1584919 },
    { lat: 48.9461758, lng: 21.1590085 },
    { lat: 48.9461176, lng: 21.1592351 },
    { lat: 48.9462477, lng: 21.159613 },
    { lat: 48.9461703, lng: 21.1599398 },
    { lat: 48.9461961, lng: 21.1606124 },
    { lat: 48.9463104, lng: 21.1607018 },
    { lat: 48.9465651, lng: 21.1612834 },
    { lat: 48.9467557, lng: 21.1624725 },
    { lat: 48.9468999, lng: 21.1627294 },
    { lat: 48.9473459, lng: 21.1620303 },
    { lat: 48.9480729, lng: 21.1612821 },
    { lat: 48.9486197, lng: 21.1608474 },
    { lat: 48.9491818, lng: 21.1605816 },
    { lat: 48.9502103, lng: 21.1595765 },
    { lat: 48.9505519, lng: 21.1593869 },
    { lat: 48.9510651, lng: 21.1587477 },
    { lat: 48.9517989, lng: 21.1566769 },
    { lat: 48.9526487, lng: 21.1554058 },
    { lat: 48.9530458, lng: 21.1550402 },
    { lat: 48.9533999, lng: 21.1552457 },
    { lat: 48.9543084, lng: 21.1552865 },
    { lat: 48.9544921, lng: 21.155368 },
    { lat: 48.95443, lng: 21.1555355 },
    { lat: 48.9548722, lng: 21.1553575 },
    { lat: 48.9554631, lng: 21.1546466 },
    { lat: 48.955384, lng: 21.1539287 },
    { lat: 48.9555128, lng: 21.1534079 },
    { lat: 48.9556342, lng: 21.1531226 },
    { lat: 48.9565822, lng: 21.1527068 },
    { lat: 48.9580641, lng: 21.1517453 },
    { lat: 48.9582269, lng: 21.1519042 },
    { lat: 48.9583775, lng: 21.1521837 },
    { lat: 48.9584802, lng: 21.152121 },
    { lat: 48.9587535, lng: 21.1521885 },
    { lat: 48.958887, lng: 21.1514664 },
    { lat: 48.9594363, lng: 21.1501557 },
    { lat: 48.9595818, lng: 21.1490987 },
    { lat: 48.9600892, lng: 21.1494469 },
    { lat: 48.9602556, lng: 21.1483397 },
    { lat: 48.9626228, lng: 21.1493898 },
    { lat: 48.9633201, lng: 21.1497614 },
    { lat: 48.9638, lng: 21.1502754 },
    { lat: 48.9643947, lng: 21.1502983 },
    { lat: 48.9643588, lng: 21.1498711 },
    { lat: 48.9650296, lng: 21.1498737 },
    { lat: 48.9657248, lng: 21.1504712 },
    { lat: 48.9659606, lng: 21.1504565 },
    { lat: 48.9665838, lng: 21.1510116 },
    { lat: 48.966823, lng: 21.1507169 },
    { lat: 48.9667684, lng: 21.1503026 },
    { lat: 48.9668573, lng: 21.1492724 },
    { lat: 48.9672006, lng: 21.1487441 },
    { lat: 48.9678044, lng: 21.1485069 },
    { lat: 48.9681359, lng: 21.1486708 },
    { lat: 48.9682966, lng: 21.1485258 },
    { lat: 48.9706124, lng: 21.1485163 },
    { lat: 48.9721321, lng: 21.148281 },
    { lat: 48.9724463, lng: 21.1483221 },
    { lat: 48.9725832, lng: 21.1483487 },
    { lat: 48.9727356, lng: 21.1478658 },
    { lat: 48.9722847, lng: 21.146636 },
    { lat: 48.9717694, lng: 21.1461729 },
    { lat: 48.9714177, lng: 21.1456216 },
    { lat: 48.9695833, lng: 21.1439583 },
    { lat: 48.9690835, lng: 21.1438433 },
    { lat: 48.9688668, lng: 21.1439326 },
    { lat: 48.9680204, lng: 21.1438932 },
    { lat: 48.9677671, lng: 21.1439977 },
    { lat: 48.9674225, lng: 21.1438481 },
    { lat: 48.9671084, lng: 21.1439133 },
    { lat: 48.9668294, lng: 21.1438832 },
    { lat: 48.966703, lng: 21.1438029 },
    { lat: 48.9667155, lng: 21.1436609 },
    { lat: 48.9665362, lng: 21.1432933 },
    { lat: 48.9649578, lng: 21.1410399 },
    { lat: 48.9659666, lng: 21.1394281 },
    { lat: 48.9655308, lng: 21.1389217 },
    { lat: 48.9658839, lng: 21.1380645 },
    { lat: 48.9664266, lng: 21.1374345 },
    { lat: 48.9668224, lng: 21.1372453 },
    { lat: 48.9667565, lng: 21.1369432 },
    { lat: 48.9667009, lng: 21.1358986 },
    { lat: 48.9668408, lng: 21.1355602 },
    { lat: 48.9671619, lng: 21.1353576 },
    { lat: 48.9678886, lng: 21.1352423 },
    { lat: 48.9681907, lng: 21.1354354 },
    { lat: 48.9690608, lng: 21.1352248 },
    { lat: 48.9690429, lng: 21.1347361 },
    { lat: 48.9694852, lng: 21.134132 },
    { lat: 48.9699088, lng: 21.1339343 },
    { lat: 48.9709429, lng: 21.1337166 },
    { lat: 48.9711623, lng: 21.1342461 },
    { lat: 48.9714779, lng: 21.1345471 },
    { lat: 48.9729508, lng: 21.1343957 },
    { lat: 48.9730544, lng: 21.1332619 },
    { lat: 48.9732972, lng: 21.1329707 },
    { lat: 48.9735457, lng: 21.1331463 },
    { lat: 48.9740851, lng: 21.1328388 },
    { lat: 48.9747737, lng: 21.1329709 },
    { lat: 48.9757123, lng: 21.1329046 },
    { lat: 48.9759124, lng: 21.1331467 },
    { lat: 48.9762141, lng: 21.132896 },
    { lat: 48.9764432, lng: 21.1323232 },
    { lat: 48.9768734, lng: 21.1312645 },
  ],
  Fulianka: [
    { lat: 49.0586111, lng: 21.3138515 },
    { lat: 49.0583353, lng: 21.3140713 },
    { lat: 49.0579483, lng: 21.3150388 },
    { lat: 49.0576279, lng: 21.3156481 },
    { lat: 49.0571616, lng: 21.3170824 },
    { lat: 49.0568101, lng: 21.3176326 },
    { lat: 49.0564836, lng: 21.3183367 },
    { lat: 49.0597282, lng: 21.3237684 },
    { lat: 49.059476, lng: 21.3242161 },
    { lat: 49.0593779, lng: 21.3245057 },
    { lat: 49.0592095, lng: 21.324708 },
    { lat: 49.0590307, lng: 21.3247825 },
    { lat: 49.0589077, lng: 21.3247017 },
    { lat: 49.0588048, lng: 21.3247911 },
    { lat: 49.0585948, lng: 21.3248107 },
    { lat: 49.058463, lng: 21.3249896 },
    { lat: 49.0583625, lng: 21.3252384 },
    { lat: 49.0583628, lng: 21.3253586 },
    { lat: 49.0581607, lng: 21.3257107 },
    { lat: 49.0581381, lng: 21.32601 },
    { lat: 49.0578168, lng: 21.3261748 },
    { lat: 49.0577401, lng: 21.3263147 },
    { lat: 49.0569675, lng: 21.3283174 },
    { lat: 49.0562559, lng: 21.3303472 },
    { lat: 49.0560567, lng: 21.3309155 },
    { lat: 49.0556406, lng: 21.3318375 },
    { lat: 49.055548, lng: 21.3320426 },
    { lat: 49.0555491, lng: 21.3322603 },
    { lat: 49.0558367, lng: 21.3324365 },
    { lat: 49.0562158, lng: 21.3328037 },
    { lat: 49.0572844, lng: 21.334289 },
    { lat: 49.0579789, lng: 21.3356515 },
    { lat: 49.0582084, lng: 21.3359857 },
    { lat: 49.0586871, lng: 21.3364921 },
    { lat: 49.058743, lng: 21.3366342 },
    { lat: 49.0588187, lng: 21.3375055 },
    { lat: 49.0596261, lng: 21.3380113 },
    { lat: 49.0601174, lng: 21.3378821 },
    { lat: 49.0608236, lng: 21.3373792 },
    { lat: 49.0609907, lng: 21.3373629 },
    { lat: 49.0613683, lng: 21.3375014 },
    { lat: 49.0616124, lng: 21.3372734 },
    { lat: 49.062038, lng: 21.3367172 },
    { lat: 49.0623142, lng: 21.3367748 },
    { lat: 49.0624435, lng: 21.3365803 },
    { lat: 49.0627147, lng: 21.3364725 },
    { lat: 49.0628379, lng: 21.3361553 },
    { lat: 49.0628896, lng: 21.3361872 },
    { lat: 49.0631783, lng: 21.3389835 },
    { lat: 49.0633622, lng: 21.3388577 },
    { lat: 49.0631446, lng: 21.3398872 },
    { lat: 49.0639524, lng: 21.3415 },
    { lat: 49.0653549, lng: 21.34385 },
    { lat: 49.0654894, lng: 21.3437197 },
    { lat: 49.0669442, lng: 21.3459363 },
    { lat: 49.0663041, lng: 21.3470646 },
    { lat: 49.0661235, lng: 21.3476176 },
    { lat: 49.0658013, lng: 21.3482452 },
    { lat: 49.0656586, lng: 21.3488091 },
    { lat: 49.0656486, lng: 21.3494948 },
    { lat: 49.0658804, lng: 21.3509242 },
    { lat: 49.0659544, lng: 21.3522011 },
    { lat: 49.0660692, lng: 21.3531398 },
    { lat: 49.065998, lng: 21.3533539 },
    { lat: 49.0660922, lng: 21.3532717 },
    { lat: 49.0663507, lng: 21.3532581 },
    { lat: 49.0664885, lng: 21.3516572 },
    { lat: 49.0667703, lng: 21.3500958 },
    { lat: 49.0667421, lng: 21.3494529 },
    { lat: 49.0668175, lng: 21.3487318 },
    { lat: 49.0671575, lng: 21.3477609 },
    { lat: 49.0681802, lng: 21.3457627 },
    { lat: 49.0683735, lng: 21.3457909 },
    { lat: 49.0687769, lng: 21.3456661 },
    { lat: 49.0708938, lng: 21.344549 },
    { lat: 49.0720896, lng: 21.3440883 },
    { lat: 49.0723502, lng: 21.3420404 },
    { lat: 49.0730955, lng: 21.3420569 },
    { lat: 49.0732358, lng: 21.3411051 },
    { lat: 49.0760172, lng: 21.3418158 },
    { lat: 49.0760685, lng: 21.3381995 },
    { lat: 49.0755686, lng: 21.3383695 },
    { lat: 49.075266, lng: 21.3383381 },
    { lat: 49.0747547, lng: 21.338098 },
    { lat: 49.0744977, lng: 21.3378208 },
    { lat: 49.0744277, lng: 21.3373268 },
    { lat: 49.0742793, lng: 21.3369933 },
    { lat: 49.0742359, lng: 21.336482 },
    { lat: 49.0765514, lng: 21.3347857 },
    { lat: 49.0756249, lng: 21.3322628 },
    { lat: 49.0753657, lng: 21.3316477 },
    { lat: 49.0746846, lng: 21.3292018 },
    { lat: 49.0745586, lng: 21.3284121 },
    { lat: 49.0749537, lng: 21.3279693 },
    { lat: 49.0746535, lng: 21.327262 },
    { lat: 49.0742858, lng: 21.3277219 },
    { lat: 49.0740283, lng: 21.3278396 },
    { lat: 49.0736985, lng: 21.3283589 },
    { lat: 49.0724122, lng: 21.3297767 },
    { lat: 49.0722409, lng: 21.3300721 },
    { lat: 49.0721888, lng: 21.3303212 },
    { lat: 49.0720659, lng: 21.3305401 },
    { lat: 49.07167, lng: 21.3309154 },
    { lat: 49.0709646, lng: 21.3296061 },
    { lat: 49.0708959, lng: 21.3295732 },
    { lat: 49.0708017, lng: 21.3293185 },
    { lat: 49.0695151, lng: 21.3268336 },
    { lat: 49.0701172, lng: 21.3265333 },
    { lat: 49.0713252, lng: 21.3261125 },
    { lat: 49.0717946, lng: 21.3255164 },
    { lat: 49.0721821, lng: 21.324782 },
    { lat: 49.0725191, lng: 21.3243519 },
    { lat: 49.0726516, lng: 21.3241158 },
    { lat: 49.0729039, lng: 21.3233428 },
    { lat: 49.0735519, lng: 21.3223254 },
    { lat: 49.0718362, lng: 21.3196849 },
    { lat: 49.0719832, lng: 21.3196323 },
    { lat: 49.0720921, lng: 21.3195134 },
    { lat: 49.0726176, lng: 21.3186197 },
    { lat: 49.072955, lng: 21.3183151 },
    { lat: 49.0737127, lng: 21.3169878 },
    { lat: 49.0738991, lng: 21.3164835 },
    { lat: 49.074, lng: 21.3159144 },
    { lat: 49.0741185, lng: 21.3142142 },
    { lat: 49.073925, lng: 21.3123493 },
    { lat: 49.0739292, lng: 21.3117773 },
    { lat: 49.0737986, lng: 21.3115122 },
    { lat: 49.0738265, lng: 21.3105807 },
    { lat: 49.0739015, lng: 21.3101911 },
    { lat: 49.0739014, lng: 21.3100773 },
    { lat: 49.0737984, lng: 21.3098685 },
    { lat: 49.0738994, lng: 21.3093012 },
    { lat: 49.0739207, lng: 21.3087014 },
    { lat: 49.0740108, lng: 21.3084087 },
    { lat: 49.0740349, lng: 21.3080513 },
    { lat: 49.0739382, lng: 21.307682 },
    { lat: 49.0741874, lng: 21.306918 },
    { lat: 49.0742286, lng: 21.3066111 },
    { lat: 49.0738316, lng: 21.3070269 },
    { lat: 49.0735661, lng: 21.3070283 },
    { lat: 49.0729544, lng: 21.3068145 },
    { lat: 49.0727862, lng: 21.3073962 },
    { lat: 49.0727909, lng: 21.3079967 },
    { lat: 49.072747, lng: 21.3082898 },
    { lat: 49.0726268, lng: 21.3086373 },
    { lat: 49.0724047, lng: 21.3086615 },
    { lat: 49.0719645, lng: 21.3084651 },
    { lat: 49.0716951, lng: 21.308259 },
    { lat: 49.0714289, lng: 21.3082425 },
    { lat: 49.0713759, lng: 21.3083851 },
    { lat: 49.0711818, lng: 21.3082188 },
    { lat: 49.0709404, lng: 21.3081582 },
    { lat: 49.0707733, lng: 21.3091123 },
    { lat: 49.0706121, lng: 21.3096225 },
    { lat: 49.0704676, lng: 21.3099053 },
    { lat: 49.0701489, lng: 21.3100407 },
    { lat: 49.0700774, lng: 21.3106945 },
    { lat: 49.0698637, lng: 21.3114401 },
    { lat: 49.0694662, lng: 21.3114658 },
    { lat: 49.0692498, lng: 21.3113826 },
    { lat: 49.0691824, lng: 21.3114211 },
    { lat: 49.0691647, lng: 21.3116161 },
    { lat: 49.0689623, lng: 21.311552 },
    { lat: 49.0688844, lng: 21.3118768 },
    { lat: 49.0689456, lng: 21.3121144 },
    { lat: 49.0688844, lng: 21.3126258 },
    { lat: 49.0688026, lng: 21.3130779 },
    { lat: 49.0687251, lng: 21.3131993 },
    { lat: 49.0688169, lng: 21.3136307 },
    { lat: 49.068702, lng: 21.3138468 },
    { lat: 49.0690942, lng: 21.3142016 },
    { lat: 49.0689959, lng: 21.3144134 },
    { lat: 49.0681104, lng: 21.3146052 },
    { lat: 49.0676531, lng: 21.3149598 },
    { lat: 49.0669309, lng: 21.3152668 },
    { lat: 49.0663914, lng: 21.315321 },
    { lat: 49.066068, lng: 21.3151976 },
    { lat: 49.0656625, lng: 21.3152553 },
    { lat: 49.0652767, lng: 21.3152213 },
    { lat: 49.0649386, lng: 21.3153544 },
    { lat: 49.0647342, lng: 21.3153654 },
    { lat: 49.0628765, lng: 21.3151806 },
    { lat: 49.0626098, lng: 21.3152175 },
    { lat: 49.0622215, lng: 21.3154771 },
    { lat: 49.0618059, lng: 21.3155729 },
    { lat: 49.0614944, lng: 21.3157307 },
    { lat: 49.0611593, lng: 21.3160002 },
    { lat: 49.0608886, lng: 21.3158993 },
    { lat: 49.060181, lng: 21.3159835 },
    { lat: 49.0599491, lng: 21.3157941 },
    { lat: 49.0599026, lng: 21.3155566 },
    { lat: 49.059741, lng: 21.3154926 },
    { lat: 49.0595186, lng: 21.3152882 },
    { lat: 49.0593736, lng: 21.315031 },
    { lat: 49.0588245, lng: 21.3145466 },
    { lat: 49.0586136, lng: 21.3141229 },
    { lat: 49.0586111, lng: 21.3138515 },
  ],
  DrienovskáNováVes: [
    { lat: 48.9047274, lng: 21.216992 },
    { lat: 48.9047727, lng: 21.2170395 },
    { lat: 48.9043613, lng: 21.2174131 },
    { lat: 48.9037711, lng: 21.2176872 },
    { lat: 48.9030579, lng: 21.2183066 },
    { lat: 48.9026907, lng: 21.2186988 },
    { lat: 48.9020494, lng: 21.2190981 },
    { lat: 48.9017248, lng: 21.2195195 },
    { lat: 48.9009328, lng: 21.2199246 },
    { lat: 48.9007422, lng: 21.2203774 },
    { lat: 48.9005417, lng: 21.2205932 },
    { lat: 48.9001093, lng: 21.220907 },
    { lat: 48.8998194, lng: 21.2212851 },
    { lat: 48.899586, lng: 21.2217432 },
    { lat: 48.8994364, lng: 21.2218919 },
    { lat: 48.8992155, lng: 21.2219846 },
    { lat: 48.8987156, lng: 21.2225537 },
    { lat: 48.8984254, lng: 21.2230347 },
    { lat: 48.898402, lng: 21.2233662 },
    { lat: 48.8984919, lng: 21.2239742 },
    { lat: 48.898471, lng: 21.2242443 },
    { lat: 48.8983813, lng: 21.2246875 },
    { lat: 48.8982011, lng: 21.2251574 },
    { lat: 48.8970059, lng: 21.22555 },
    { lat: 48.8964551, lng: 21.2262951 },
    { lat: 48.8961708, lng: 21.2265274 },
    { lat: 48.8944186, lng: 21.2272924 },
    { lat: 48.8918781, lng: 21.2290051 },
    { lat: 48.8913553, lng: 21.2292795 },
    { lat: 48.8911809, lng: 21.2297848 },
    { lat: 48.889803, lng: 21.2329613 },
    { lat: 48.8895563, lng: 21.233176 },
    { lat: 48.8894418, lng: 21.2333683 },
    { lat: 48.8892949, lng: 21.2334535 },
    { lat: 48.8890853, lng: 21.2338114 },
    { lat: 48.8878996, lng: 21.2352418 },
    { lat: 48.8877663, lng: 21.23559 },
    { lat: 48.8877663, lng: 21.2358013 },
    { lat: 48.88761, lng: 21.236101 },
    { lat: 48.8874455, lng: 21.2365683 },
    { lat: 48.8874682, lng: 21.2366775 },
    { lat: 48.887393, lng: 21.236793 },
    { lat: 48.8873363, lng: 21.2371093 },
    { lat: 48.887103, lng: 21.2376776 },
    { lat: 48.8869126, lng: 21.2385798 },
    { lat: 48.8869645, lng: 21.2388035 },
    { lat: 48.8869233, lng: 21.2389269 },
    { lat: 48.8869852, lng: 21.2392208 },
    { lat: 48.8870676, lng: 21.239369 },
    { lat: 48.8870557, lng: 21.239569 },
    { lat: 48.8872261, lng: 21.2407477 },
    { lat: 48.8873873, lng: 21.2413034 },
    { lat: 48.8874874, lng: 21.2414125 },
    { lat: 48.8875192, lng: 21.2416691 },
    { lat: 48.8877484, lng: 21.24199 },
    { lat: 48.8877846, lng: 21.2422152 },
    { lat: 48.8877602, lng: 21.242478 },
    { lat: 48.887823, lng: 21.2425418 },
    { lat: 48.8878568, lng: 21.243673 },
    { lat: 48.8876474, lng: 21.2440712 },
    { lat: 48.8876696, lng: 21.2446811 },
    { lat: 48.8882019, lng: 21.2502232 },
    { lat: 48.8883655, lng: 21.252719 },
    { lat: 48.8913571, lng: 21.2509789 },
    { lat: 48.8916975, lng: 21.2505635 },
    { lat: 48.8919545, lng: 21.2501419 },
    { lat: 48.8931527, lng: 21.249509 },
    { lat: 48.893519, lng: 21.2495642 },
    { lat: 48.8938401, lng: 21.2500521 },
    { lat: 48.8940999, lng: 21.250813 },
    { lat: 48.8942594, lng: 21.2519325 },
    { lat: 48.8946961, lng: 21.2532098 },
    { lat: 48.8950251, lng: 21.2534482 },
    { lat: 48.8965631, lng: 21.2533968 },
    { lat: 48.8970174, lng: 21.2534619 },
    { lat: 48.8974768, lng: 21.2537995 },
    { lat: 48.8978531, lng: 21.2543244 },
    { lat: 48.898406, lng: 21.2546296 },
    { lat: 48.898752, lng: 21.2545325 },
    { lat: 48.8990157, lng: 21.2542202 },
    { lat: 48.8990869, lng: 21.2538306 },
    { lat: 48.8988618, lng: 21.2534946 },
    { lat: 48.8987654, lng: 21.2530705 },
    { lat: 48.8985487, lng: 21.2525722 },
    { lat: 48.8985192, lng: 21.2518379 },
    { lat: 48.8987452, lng: 21.2516487 },
    { lat: 48.8997671, lng: 21.251577 },
    { lat: 48.8996644, lng: 21.2509523 },
    { lat: 48.8997374, lng: 21.2506527 },
    { lat: 48.8999359, lng: 21.2504137 },
    { lat: 48.9003408, lng: 21.2502973 },
    { lat: 48.9010063, lng: 21.2505291 },
    { lat: 48.902394, lng: 21.2504292 },
    { lat: 48.9047817, lng: 21.2498829 },
    { lat: 48.9052492, lng: 21.2489862 },
    { lat: 48.9058113, lng: 21.2490513 },
    { lat: 48.905563, lng: 21.2471083 },
    { lat: 48.9057228, lng: 21.2471048 },
    { lat: 48.905792, lng: 21.2472698 },
    { lat: 48.9057883, lng: 21.2478063 },
    { lat: 48.9058146, lng: 21.2478939 },
    { lat: 48.9059352, lng: 21.2479755 },
    { lat: 48.9064853, lng: 21.2477808 },
    { lat: 48.906879, lng: 21.2471966 },
    { lat: 48.9071884, lng: 21.2472297 },
    { lat: 48.907221, lng: 21.2473751 },
    { lat: 48.9075901, lng: 21.2478837 },
    { lat: 48.9077504, lng: 21.2478262 },
    { lat: 48.9077158, lng: 21.2475457 },
    { lat: 48.9078608, lng: 21.2475068 },
    { lat: 48.9080256, lng: 21.2476296 },
    { lat: 48.908209, lng: 21.2479539 },
    { lat: 48.9083247, lng: 21.2479521 },
    { lat: 48.908575, lng: 21.2477108 },
    { lat: 48.9087395, lng: 21.2477159 },
    { lat: 48.9089116, lng: 21.2480049 },
    { lat: 48.9090125, lng: 21.2479963 },
    { lat: 48.9090718, lng: 21.2483523 },
    { lat: 48.9091144, lng: 21.2494723 },
    { lat: 48.9097857, lng: 21.2495706 },
    { lat: 48.9100549, lng: 21.2494996 },
    { lat: 48.9101844, lng: 21.2493934 },
    { lat: 48.9099307, lng: 21.248828 },
    { lat: 48.9099425, lng: 21.2484454 },
    { lat: 48.9102927, lng: 21.2488209 },
    { lat: 48.9106353, lng: 21.2483601 },
    { lat: 48.9107868, lng: 21.2482427 },
    { lat: 48.9113551, lng: 21.2491143 },
    { lat: 48.9116568, lng: 21.249299 },
    { lat: 48.9118289, lng: 21.2492978 },
    { lat: 48.9120421, lng: 21.2486588 },
    { lat: 48.91155, lng: 21.248537 },
    { lat: 48.9114458, lng: 21.2483698 },
    { lat: 48.9113766, lng: 21.2480975 },
    { lat: 48.9117236, lng: 21.2477437 },
    { lat: 48.912294, lng: 21.2475969 },
    { lat: 48.912328, lng: 21.2467659 },
    { lat: 48.9120871, lng: 21.2466846 },
    { lat: 48.9119769, lng: 21.246583 },
    { lat: 48.91187, lng: 21.2462894 },
    { lat: 48.9118852, lng: 21.245981 },
    { lat: 48.9120539, lng: 21.245832 },
    { lat: 48.9122544, lng: 21.2459086 },
    { lat: 48.9127237, lng: 21.246711 },
    { lat: 48.9127869, lng: 21.246719 },
    { lat: 48.9130238, lng: 21.2466335 },
    { lat: 48.9131201, lng: 21.2459952 },
    { lat: 48.9132056, lng: 21.2459229 },
    { lat: 48.9138252, lng: 21.2458342 },
    { lat: 48.9138389, lng: 21.2452554 },
    { lat: 48.9139384, lng: 21.2453456 },
    { lat: 48.9143275, lng: 21.2453377 },
    { lat: 48.9143419, lng: 21.2453324 },
    { lat: 48.9139668, lng: 21.2440066 },
    { lat: 48.9134361, lng: 21.2429249 },
    { lat: 48.9131495, lng: 21.242137 },
    { lat: 48.912617, lng: 21.2418007 },
    { lat: 48.912228, lng: 21.2411937 },
    { lat: 48.9121821, lng: 21.2410181 },
    { lat: 48.9121242, lng: 21.2402076 },
    { lat: 48.9119473, lng: 21.2393962 },
    { lat: 48.9119781, lng: 21.2392652 },
    { lat: 48.911948, lng: 21.2384374 },
    { lat: 48.9120763, lng: 21.2383992 },
    { lat: 48.9119641, lng: 21.2376831 },
    { lat: 48.9119678, lng: 21.2374768 },
    { lat: 48.911857, lng: 21.2373169 },
    { lat: 48.9120163, lng: 21.2368261 },
    { lat: 48.9119696, lng: 21.2366287 },
    { lat: 48.9120558, lng: 21.2364645 },
    { lat: 48.912088, lng: 21.2362336 },
    { lat: 48.9122163, lng: 21.2361947 },
    { lat: 48.9122034, lng: 21.2358926 },
    { lat: 48.9122994, lng: 21.2359015 },
    { lat: 48.9123788, lng: 21.2357012 },
    { lat: 48.9125058, lng: 21.2355509 },
    { lat: 48.9125052, lng: 21.2353495 },
    { lat: 48.9125934, lng: 21.2352219 },
    { lat: 48.9125771, lng: 21.2348764 },
    { lat: 48.9128307, lng: 21.2346362 },
    { lat: 48.9129295, lng: 21.2344771 },
    { lat: 48.9129318, lng: 21.2343749 },
    { lat: 48.913124, lng: 21.234256 },
    { lat: 48.9132795, lng: 21.2337935 },
    { lat: 48.9134641, lng: 21.2337245 },
    { lat: 48.9134534, lng: 21.2334837 },
    { lat: 48.913626, lng: 21.2334217 },
    { lat: 48.9136583, lng: 21.2333396 },
    { lat: 48.9136053, lng: 21.2330822 },
    { lat: 48.9138971, lng: 21.2325162 },
    { lat: 48.9140899, lng: 21.2324137 },
    { lat: 48.9141521, lng: 21.2322053 },
    { lat: 48.9142183, lng: 21.232167 },
    { lat: 48.9142329, lng: 21.232089 },
    { lat: 48.9140576, lng: 21.2318407 },
    { lat: 48.9140694, lng: 21.2317207 },
    { lat: 48.9141978, lng: 21.2317258 },
    { lat: 48.9143023, lng: 21.2314583 },
    { lat: 48.9143583, lng: 21.2309838 },
    { lat: 48.9143361, lng: 21.2308473 },
    { lat: 48.9144249, lng: 21.2307361 },
    { lat: 48.914609, lng: 21.2302468 },
    { lat: 48.9148177, lng: 21.230049 },
    { lat: 48.9155914, lng: 21.2296317 },
    { lat: 48.9162978, lng: 21.2291491 },
    { lat: 48.9163044, lng: 21.2290717 },
    { lat: 48.9169537, lng: 21.2284536 },
    { lat: 48.9171771, lng: 21.2280387 },
    { lat: 48.9174241, lng: 21.2277963 },
    { lat: 48.9175158, lng: 21.2275984 },
    { lat: 48.9178378, lng: 21.2271696 },
    { lat: 48.9179281, lng: 21.2268511 },
    { lat: 48.9182154, lng: 21.2263215 },
    { lat: 48.918526, lng: 21.2260631 },
    { lat: 48.9185935, lng: 21.2259191 },
    { lat: 48.9186525, lng: 21.225571 },
    { lat: 48.9188171, lng: 21.2252605 },
    { lat: 48.9189147, lng: 21.224851 },
    { lat: 48.9191028, lng: 21.2244525 },
    { lat: 48.9191143, lng: 21.2239189 },
    { lat: 48.919271, lng: 21.2234236 },
    { lat: 48.919415, lng: 21.2232361 },
    { lat: 48.9195054, lng: 21.2232182 },
    { lat: 48.9195831, lng: 21.2230703 },
    { lat: 48.9191225, lng: 21.2203806 },
    { lat: 48.9192124, lng: 21.2198002 },
    { lat: 48.9192129, lng: 21.219778 },
    { lat: 48.9187756, lng: 21.2198261 },
    { lat: 48.9182088, lng: 21.2196255 },
    { lat: 48.9176465, lng: 21.2192739 },
    { lat: 48.9160125, lng: 21.2175726 },
    { lat: 48.915625, lng: 21.2174883 },
    { lat: 48.9147808, lng: 21.2174819 },
    { lat: 48.9142708, lng: 21.2174011 },
    { lat: 48.9133002, lng: 21.2170223 },
    { lat: 48.9129473, lng: 21.2166776 },
    { lat: 48.9123455, lng: 21.2158903 },
    { lat: 48.9120678, lng: 21.2157943 },
    { lat: 48.9117549, lng: 21.2154615 },
    { lat: 48.9115563, lng: 21.2154442 },
    { lat: 48.9109794, lng: 21.2150255 },
    { lat: 48.9106025, lng: 21.2149173 },
    { lat: 48.9103086, lng: 21.2148779 },
    { lat: 48.9096796, lng: 21.2154953 },
    { lat: 48.9092768, lng: 21.2156759 },
    { lat: 48.9089941, lng: 21.2157072 },
    { lat: 48.9081802, lng: 21.2162059 },
    { lat: 48.9077288, lng: 21.2162701 },
    { lat: 48.9065633, lng: 21.2167922 },
    { lat: 48.9058657, lng: 21.2170103 },
    { lat: 48.9047274, lng: 21.216992 },
  ],
  Chmiňany: [
    { lat: 48.998514, lng: 21.0366685 },
    { lat: 48.9983057, lng: 21.0367666 },
    { lat: 48.9982973, lng: 21.0377533 },
    { lat: 48.9984568, lng: 21.0382635 },
    { lat: 48.9985055, lng: 21.0382738 },
    { lat: 48.999248, lng: 21.0374663 },
    { lat: 48.9990213, lng: 21.038764 },
    { lat: 48.9987103, lng: 21.0398609 },
    { lat: 48.9980969, lng: 21.0390655 },
    { lat: 48.9966788, lng: 21.0442771 },
    { lat: 48.9964487, lng: 21.0447786 },
    { lat: 48.9965092, lng: 21.0450142 },
    { lat: 48.9961086, lng: 21.0463661 },
    { lat: 48.9958866, lng: 21.0473931 },
    { lat: 48.9958723, lng: 21.0482999 },
    { lat: 48.9956543, lng: 21.0491477 },
    { lat: 48.995576, lng: 21.0502367 },
    { lat: 48.995668, lng: 21.0508455 },
    { lat: 48.9958205, lng: 21.0525634 },
    { lat: 48.9957734, lng: 21.0527404 },
    { lat: 48.9953497, lng: 21.0529316 },
    { lat: 48.9951755, lng: 21.0531447 },
    { lat: 48.994952, lng: 21.0545115 },
    { lat: 48.9945921, lng: 21.0556649 },
    { lat: 48.9947382, lng: 21.056107 },
    { lat: 48.9947586, lng: 21.056495 },
    { lat: 48.9945624, lng: 21.0571054 },
    { lat: 48.9941985, lng: 21.0576878 },
    { lat: 48.9940467, lng: 21.0581034 },
    { lat: 48.993823, lng: 21.0589792 },
    { lat: 48.9927755, lng: 21.0610044 },
    { lat: 48.9923411, lng: 21.0627073 },
    { lat: 48.9923872, lng: 21.0634682 },
    { lat: 48.9921197, lng: 21.064763 },
    { lat: 48.9919518, lng: 21.0652808 },
    { lat: 48.9918077, lng: 21.0652794 },
    { lat: 48.9871043, lng: 21.0674589 },
    { lat: 48.9867597, lng: 21.0673854 },
    { lat: 48.9858113, lng: 21.0683739 },
    { lat: 48.9850264, lng: 21.0687367 },
    { lat: 48.9857928, lng: 21.0688432 },
    { lat: 48.9863004, lng: 21.0689859 },
    { lat: 48.9872009, lng: 21.0698195 },
    { lat: 48.9878026, lng: 21.0696334 },
    { lat: 48.9888612, lng: 21.0697615 },
    { lat: 48.9899509, lng: 21.0711601 },
    { lat: 48.9902081, lng: 21.0717702 },
    { lat: 48.9907559, lng: 21.0724629 },
    { lat: 48.9909494, lng: 21.0735024 },
    { lat: 48.9911893, lng: 21.0741013 },
    { lat: 48.9915913, lng: 21.0756782 },
    { lat: 48.9916309, lng: 21.0762393 },
    { lat: 48.9915526, lng: 21.0777223 },
    { lat: 48.9915047, lng: 21.0809447 },
    { lat: 48.9915584, lng: 21.0807615 },
    { lat: 48.9916399, lng: 21.0806702 },
    { lat: 48.9924672, lng: 21.0804283 },
    { lat: 48.9924945, lng: 21.0822189 },
    { lat: 48.9925652, lng: 21.0829493 },
    { lat: 48.9931491, lng: 21.0842007 },
    { lat: 48.9934975, lng: 21.0856897 },
    { lat: 48.993456, lng: 21.086217 },
    { lat: 48.9935161, lng: 21.0881138 },
    { lat: 48.9930284, lng: 21.0894341 },
    { lat: 48.992831, lng: 21.0904006 },
    { lat: 48.992421, lng: 21.0912621 },
    { lat: 48.9922708, lng: 21.091704 },
    { lat: 48.9919911, lng: 21.0926629 },
    { lat: 48.9916029, lng: 21.094557 },
    { lat: 48.9912861, lng: 21.0955893 },
    { lat: 48.9910767, lng: 21.0960818 },
    { lat: 48.9908322, lng: 21.0972284 },
    { lat: 48.9909414, lng: 21.0976475 },
    { lat: 48.9909445, lng: 21.0979565 },
    { lat: 48.9907591, lng: 21.0990134 },
    { lat: 48.9908251, lng: 21.0994625 },
    { lat: 48.9910198, lng: 21.1000258 },
    { lat: 48.9910111, lng: 21.1001744 },
    { lat: 48.9910515, lng: 21.1002051 },
    { lat: 48.991183, lng: 21.1007247 },
    { lat: 48.9909744, lng: 21.1014859 },
    { lat: 48.99088, lng: 21.1019472 },
    { lat: 48.9909401, lng: 21.102313 },
    { lat: 48.9907405, lng: 21.1032064 },
    { lat: 48.9906676, lng: 21.104518 },
    { lat: 48.9904758, lng: 21.1055589 },
    { lat: 48.99088, lng: 21.1058107 },
    { lat: 48.9909097, lng: 21.1059274 },
    { lat: 48.9912853, lng: 21.10599 },
    { lat: 48.9919021, lng: 21.1062393 },
    { lat: 48.9924768, lng: 21.1066494 },
    { lat: 48.9927418, lng: 21.1066621 },
    { lat: 48.9932043, lng: 21.1069056 },
    { lat: 48.9941069, lng: 21.1070116 },
    { lat: 48.9951358, lng: 21.107447 },
    { lat: 48.995618, lng: 21.1075697 },
    { lat: 48.9965854, lng: 21.1081161 },
    { lat: 48.9975641, lng: 21.1091935 },
    { lat: 48.9981452, lng: 21.1095918 },
    { lat: 48.9992347, lng: 21.1104765 },
    { lat: 49.0007069, lng: 21.1098462 },
    { lat: 49.0005286, lng: 21.1093092 },
    { lat: 49.0001193, lng: 21.1088262 },
    { lat: 48.9999695, lng: 21.1083674 },
    { lat: 48.9998647, lng: 21.1077611 },
    { lat: 48.9998509, lng: 21.107122 },
    { lat: 48.9999871, lng: 21.1059625 },
    { lat: 48.9999823, lng: 21.1051428 },
    { lat: 49.0002292, lng: 21.1041704 },
    { lat: 49.0005428, lng: 21.1035949 },
    { lat: 49.0011713, lng: 21.1029828 },
    { lat: 49.0016107, lng: 21.1023242 },
    { lat: 49.0017092, lng: 21.1019961 },
    { lat: 49.0018296, lng: 21.1015342 },
    { lat: 49.0018078, lng: 21.1002252 },
    { lat: 49.0019038, lng: 21.0999607 },
    { lat: 49.0019122, lng: 21.0994497 },
    { lat: 49.002007, lng: 21.0985817 },
    { lat: 49.0021433, lng: 21.0979863 },
    { lat: 49.0025207, lng: 21.0976519 },
    { lat: 49.0026699, lng: 21.0968641 },
    { lat: 49.0025487, lng: 21.0964512 },
    { lat: 49.0021209, lng: 21.0954316 },
    { lat: 49.0021108, lng: 21.0950434 },
    { lat: 49.0021942, lng: 21.0946673 },
    { lat: 49.0022263, lng: 21.0941248 },
    { lat: 49.0026632, lng: 21.093497 },
    { lat: 49.0019086, lng: 21.0933738 },
    { lat: 49.0015092, lng: 21.0933102 },
    { lat: 49.0013617, lng: 21.0914125 },
    { lat: 49.0010598, lng: 21.0902532 },
    { lat: 49.0012791, lng: 21.0868211 },
    { lat: 49.001092, lng: 21.085412 },
    { lat: 49.001122, lng: 21.0841768 },
    { lat: 49.0006995, lng: 21.083111 },
    { lat: 49.0003793, lng: 21.081904 },
    { lat: 49.0005443, lng: 21.0804623 },
    { lat: 49.0004613, lng: 21.0790058 },
    { lat: 48.9998804, lng: 21.0765924 },
    { lat: 49.0000092, lng: 21.0754914 },
    { lat: 49.0005266, lng: 21.0745772 },
    { lat: 49.0005897, lng: 21.0746839 },
    { lat: 49.0013363, lng: 21.0736533 },
    { lat: 49.0017584, lng: 21.0732698 },
    { lat: 49.0021125, lng: 21.0732599 },
    { lat: 49.00282, lng: 21.0734953 },
    { lat: 49.0028556, lng: 21.0733389 },
    { lat: 49.0030799, lng: 21.0731074 },
    { lat: 49.003449, lng: 21.0730347 },
    { lat: 49.0036351, lng: 21.0721086 },
    { lat: 49.0034413, lng: 21.0708469 },
    { lat: 49.0035211, lng: 21.0698377 },
    { lat: 49.0041031, lng: 21.0671947 },
    { lat: 49.0041876, lng: 21.0674031 },
    { lat: 49.004483, lng: 21.0671437 },
    { lat: 49.0049339, lng: 21.0683474 },
    { lat: 49.0053468, lng: 21.0684105 },
    { lat: 49.0056118, lng: 21.0685316 },
    { lat: 49.0060894, lng: 21.0683012 },
    { lat: 49.0063305, lng: 21.0677573 },
    { lat: 49.006612, lng: 21.0673072 },
    { lat: 49.0066217, lng: 21.0671566 },
    { lat: 49.0065088, lng: 21.066765 },
    { lat: 49.0063901, lng: 21.0665742 },
    { lat: 49.0061794, lng: 21.0665276 },
    { lat: 49.0060901, lng: 21.0662378 },
    { lat: 49.0060931, lng: 21.0661247 },
    { lat: 49.0063238, lng: 21.0656669 },
    { lat: 49.0068727, lng: 21.0654541 },
    { lat: 49.00764, lng: 21.06454 },
    { lat: 49.0081703, lng: 21.0641644 },
    { lat: 49.0086298, lng: 21.0634751 },
    { lat: 49.0087392, lng: 21.0627535 },
    { lat: 49.0091283, lng: 21.06212 },
    { lat: 49.0092782, lng: 21.0621966 },
    { lat: 49.0092592, lng: 21.0613333 },
    { lat: 49.0094632, lng: 21.0606874 },
    { lat: 49.0095404, lng: 21.0601134 },
    { lat: 49.009445, lng: 21.0586981 },
    { lat: 49.0093138, lng: 21.0582228 },
    { lat: 49.0093316, lng: 21.0576687 },
    { lat: 49.0094763, lng: 21.056786 },
    { lat: 49.0096611, lng: 21.0561649 },
    { lat: 49.0098158, lng: 21.0558672 },
    { lat: 49.0102525, lng: 21.0553487 },
    { lat: 49.0104499, lng: 21.0549488 },
    { lat: 49.0106346, lng: 21.0542588 },
    { lat: 49.010528, lng: 21.0540252 },
    { lat: 49.0104489, lng: 21.0536325 },
    { lat: 49.0104345, lng: 21.0532962 },
    { lat: 49.01058, lng: 21.0526794 },
    { lat: 49.010565, lng: 21.0523916 },
    { lat: 49.0106171, lng: 21.052297 },
    { lat: 49.0104964, lng: 21.0512509 },
    { lat: 49.010293, lng: 21.0509805 },
    { lat: 49.009714, lng: 21.0504794 },
    { lat: 49.0095753, lng: 21.0500202 },
    { lat: 49.0096758, lng: 21.0492748 },
    { lat: 49.0096687, lng: 21.0487086 },
    { lat: 49.0098377, lng: 21.0479871 },
    { lat: 49.0100983, lng: 21.0473853 },
    { lat: 49.0101432, lng: 21.0465825 },
    { lat: 49.0105114, lng: 21.0460708 },
    { lat: 49.0106427, lng: 21.0456394 },
    { lat: 49.0111579, lng: 21.0450435 },
    { lat: 49.0114633, lng: 21.0448173 },
    { lat: 49.0117009, lng: 21.0445445 },
    { lat: 49.0120873, lng: 21.043693 },
    { lat: 49.0123106, lng: 21.0435335 },
    { lat: 49.0114166, lng: 21.0426224 },
    { lat: 49.0107186, lng: 21.041124 },
    { lat: 49.0098502, lng: 21.0395879 },
    { lat: 49.00879, lng: 21.0370686 },
    { lat: 49.0085405, lng: 21.0369759 },
    { lat: 49.0084109, lng: 21.0363777 },
    { lat: 49.0088189, lng: 21.0356751 },
    { lat: 49.0091803, lng: 21.0346086 },
    { lat: 49.0084011, lng: 21.0329397 },
    { lat: 49.0089818, lng: 21.0323212 },
    { lat: 49.0093421, lng: 21.0317703 },
    { lat: 49.0095686, lng: 21.0311964 },
    { lat: 49.0099431, lng: 21.0304949 },
    { lat: 49.010326, lng: 21.0299874 },
    { lat: 49.0113782, lng: 21.0280211 },
    { lat: 49.0118968, lng: 21.0266052 },
    { lat: 49.0120404, lng: 21.026072 },
    { lat: 49.0128517, lng: 21.024175 },
    { lat: 49.0130112, lng: 21.0231669 },
    { lat: 49.0130609, lng: 21.022554 },
    { lat: 49.01346, lng: 21.0215468 },
    { lat: 49.0130656, lng: 21.0218413 },
    { lat: 49.0121367, lng: 21.0222736 },
    { lat: 49.0112349, lng: 21.0235375 },
    { lat: 49.0106707, lng: 21.024185 },
    { lat: 49.0099624, lng: 21.0248222 },
    { lat: 49.0085255, lng: 21.0258626 },
    { lat: 49.0068927, lng: 21.0278379 },
    { lat: 49.00685, lng: 21.0282491 },
    { lat: 49.0063941, lng: 21.0293163 },
    { lat: 49.005991, lng: 21.0298902 },
    { lat: 49.0053762, lng: 21.0309528 },
    { lat: 49.0040883, lng: 21.0317333 },
    { lat: 49.0037233, lng: 21.0320318 },
    { lat: 49.0032344, lng: 21.0322637 },
    { lat: 49.0029585, lng: 21.0327057 },
    { lat: 49.0030403, lng: 21.0338968 },
    { lat: 49.0029566, lng: 21.0353347 },
    { lat: 49.0030188, lng: 21.0355139 },
    { lat: 49.0030458, lng: 21.0359284 },
    { lat: 49.0028198, lng: 21.0360426 },
    { lat: 49.002584, lng: 21.0363321 },
    { lat: 49.0024053, lng: 21.0366897 },
    { lat: 49.0017728, lng: 21.0366531 },
    { lat: 49.001139, lng: 21.0368514 },
    { lat: 48.9999096, lng: 21.0365644 },
    { lat: 48.9994829, lng: 21.0363145 },
    { lat: 48.9992172, lng: 21.0362818 },
    { lat: 48.9989446, lng: 21.0366171 },
    { lat: 48.998514, lng: 21.0366685 },
  ],
  Proč: [
    { lat: 49.0876316, lng: 21.3902006 },
    { lat: 49.0875585, lng: 21.3904713 },
    { lat: 49.0874759, lng: 21.3914429 },
    { lat: 49.0875193, lng: 21.3931063 },
    { lat: 49.0874742, lng: 21.3939749 },
    { lat: 49.0870639, lng: 21.3952141 },
    { lat: 49.0871523, lng: 21.3964225 },
    { lat: 49.0873672, lng: 21.3981374 },
    { lat: 49.0873373, lng: 21.3986115 },
    { lat: 49.0872521, lng: 21.3990814 },
    { lat: 49.0873634, lng: 21.3991144 },
    { lat: 49.0877941, lng: 21.3993768 },
    { lat: 49.0880016, lng: 21.3996258 },
    { lat: 49.0927017, lng: 21.403726 },
    { lat: 49.0927818, lng: 21.4051106 },
    { lat: 49.0933451, lng: 21.406397 },
    { lat: 49.0935523, lng: 21.4060971 },
    { lat: 49.094317, lng: 21.4053948 },
    { lat: 49.0959799, lng: 21.4098341 },
    { lat: 49.0969423, lng: 21.4097356 },
    { lat: 49.0973228, lng: 21.4095094 },
    { lat: 49.0977393, lng: 21.4098695 },
    { lat: 49.0982662, lng: 21.4105647 },
    { lat: 49.0985928, lng: 21.4108074 },
    { lat: 49.0986545, lng: 21.4109508 },
    { lat: 49.099147, lng: 21.4114814 },
    { lat: 49.0997663, lng: 21.4131818 },
    { lat: 49.1000764, lng: 21.4146655 },
    { lat: 49.1002003, lng: 21.4150133 },
    { lat: 49.1002101, lng: 21.4152367 },
    { lat: 49.1007428, lng: 21.416714 },
    { lat: 49.1033395, lng: 21.4143677 },
    { lat: 49.1037015, lng: 21.4141697 },
    { lat: 49.1043166, lng: 21.4139566 },
    { lat: 49.1044827, lng: 21.413561 },
    { lat: 49.105303, lng: 21.414332 },
    { lat: 49.105538, lng: 21.414072 },
    { lat: 49.105664, lng: 21.413737 },
    { lat: 49.106026, lng: 21.413131 },
    { lat: 49.10603, lng: 21.412661 },
    { lat: 49.106597, lng: 21.412602 },
    { lat: 49.106577, lng: 21.412362 },
    { lat: 49.106628, lng: 21.411878 },
    { lat: 49.106986, lng: 21.411813 },
    { lat: 49.107335, lng: 21.410033 },
    { lat: 49.107765, lng: 21.408771 },
    { lat: 49.107875, lng: 21.407242 },
    { lat: 49.108265, lng: 21.40628 },
    { lat: 49.108454, lng: 21.405699 },
    { lat: 49.10876, lng: 21.404454 },
    { lat: 49.10897, lng: 21.40348 },
    { lat: 49.109149, lng: 21.402747 },
    { lat: 49.109215, lng: 21.402381 },
    { lat: 49.109375, lng: 21.401725 },
    { lat: 49.10944, lng: 21.401423 },
    { lat: 49.109628, lng: 21.400763 },
    { lat: 49.109705, lng: 21.400296 },
    { lat: 49.10989, lng: 21.399626 },
    { lat: 49.1101, lng: 21.398756 },
    { lat: 49.110266, lng: 21.398038 },
    { lat: 49.110621, lng: 21.396417 },
    { lat: 49.11091, lng: 21.395284 },
    { lat: 49.111156, lng: 21.394179 },
    { lat: 49.111237, lng: 21.393848 },
    { lat: 49.111573, lng: 21.392559 },
    { lat: 49.111625, lng: 21.392213 },
    { lat: 49.112176, lng: 21.389748 },
    { lat: 49.11237, lng: 21.389074 },
    { lat: 49.112384, lng: 21.389024 },
    { lat: 49.112652, lng: 21.387773 },
    { lat: 49.112911, lng: 21.386819 },
    { lat: 49.113367, lng: 21.385095 },
    { lat: 49.113426, lng: 21.384873 },
    { lat: 49.113869, lng: 21.383139 },
    { lat: 49.114141, lng: 21.381996 },
    { lat: 49.114513, lng: 21.380574 },
    { lat: 49.11487, lng: 21.379191 },
    { lat: 49.11492, lng: 21.378786 },
    { lat: 49.115041, lng: 21.378272 },
    { lat: 49.115351, lng: 21.377061 },
    { lat: 49.115491, lng: 21.376054 },
    { lat: 49.115508, lng: 21.375948 },
    { lat: 49.115639, lng: 21.375539 },
    { lat: 49.115808, lng: 21.374947 },
    { lat: 49.116205, lng: 21.374224 },
    { lat: 49.1163654, lng: 21.3741267 },
    { lat: 49.1162238, lng: 21.3732782 },
    { lat: 49.1160994, lng: 21.372732 },
    { lat: 49.1158521, lng: 21.3721432 },
    { lat: 49.1156722, lng: 21.3715287 },
    { lat: 49.1153425, lng: 21.3716902 },
    { lat: 49.1145396, lng: 21.3704958 },
    { lat: 49.1134735, lng: 21.3711967 },
    { lat: 49.1127875, lng: 21.3720046 },
    { lat: 49.1124756, lng: 21.3721881 },
    { lat: 49.1122767, lng: 21.3725034 },
    { lat: 49.1115209, lng: 21.372776 },
    { lat: 49.1109426, lng: 21.3728908 },
    { lat: 49.1090728, lng: 21.3736178 },
    { lat: 49.1073806, lng: 21.3759025 },
    { lat: 49.1067633, lng: 21.3760268 },
    { lat: 49.1063398, lng: 21.3759798 },
    { lat: 49.1050565, lng: 21.3770929 },
    { lat: 49.1041029, lng: 21.3777602 },
    { lat: 49.1038463, lng: 21.3773732 },
    { lat: 49.1035817, lng: 21.3766466 },
    { lat: 49.1036909, lng: 21.3753995 },
    { lat: 49.1039585, lng: 21.374859 },
    { lat: 49.1016414, lng: 21.3760265 },
    { lat: 49.0988455, lng: 21.377735 },
    { lat: 49.097681, lng: 21.3783481 },
    { lat: 49.0974537, lng: 21.3789217 },
    { lat: 49.0971805, lng: 21.3794166 },
    { lat: 49.0968524, lng: 21.3806139 },
    { lat: 49.0951512, lng: 21.3829326 },
    { lat: 49.0939542, lng: 21.3843427 },
    { lat: 49.093049, lng: 21.3853142 },
    { lat: 49.0926333, lng: 21.3864064 },
    { lat: 49.0920686, lng: 21.3869822 },
    { lat: 49.0912378, lng: 21.3868068 },
    { lat: 49.0909379, lng: 21.3870963 },
    { lat: 49.0901381, lng: 21.3888893 },
    { lat: 49.0895451, lng: 21.3893844 },
    { lat: 49.0891361, lng: 21.389673 },
    { lat: 49.0886274, lng: 21.3898607 },
    { lat: 49.0877498, lng: 21.3865094 },
    { lat: 49.0873914, lng: 21.3867544 },
    { lat: 49.0876225, lng: 21.3877221 },
    { lat: 49.0878338, lng: 21.3894232 },
    { lat: 49.0878248, lng: 21.3899559 },
    { lat: 49.0876316, lng: 21.3902006 },
  ],
  Fričovce: [
    { lat: 49.0319657, lng: 20.9619233 },
    { lat: 49.0309601, lng: 20.9625849 },
    { lat: 49.0306908, lng: 20.9619036 },
    { lat: 49.030508, lng: 20.9612068 },
    { lat: 49.0304196, lng: 20.9610891 },
    { lat: 49.0305543, lng: 20.9600975 },
    { lat: 49.0297636, lng: 20.9591623 },
    { lat: 49.0290171, lng: 20.9606095 },
    { lat: 49.0282911, lng: 20.9617621 },
    { lat: 49.0278936, lng: 20.9623009 },
    { lat: 49.0271962, lng: 20.9628185 },
    { lat: 49.0270895, lng: 20.9627238 },
    { lat: 49.0267321, lng: 20.9630393 },
    { lat: 49.0263311, lng: 20.9635048 },
    { lat: 49.0262912, lng: 20.9634555 },
    { lat: 49.0271992, lng: 20.9618582 },
    { lat: 49.0272516, lng: 20.9615393 },
    { lat: 49.0277184, lng: 20.9606136 },
    { lat: 49.0282481, lng: 20.9601306 },
    { lat: 49.0279452, lng: 20.9596174 },
    { lat: 49.0278175, lng: 20.9587575 },
    { lat: 49.0280967, lng: 20.9581507 },
    { lat: 49.0274358, lng: 20.9574624 },
    { lat: 49.0275565, lng: 20.9568315 },
    { lat: 49.0271767, lng: 20.9564817 },
    { lat: 49.0270082, lng: 20.9564222 },
    { lat: 49.0238213, lng: 20.948938 },
    { lat: 49.0232911, lng: 20.9475161 },
    { lat: 49.0229451, lng: 20.9467305 },
    { lat: 49.0234132, lng: 20.9453431 },
    { lat: 49.0231942, lng: 20.9447181 },
    { lat: 49.0230529, lng: 20.9437575 },
    { lat: 49.0220387, lng: 20.9421059 },
    { lat: 49.0216726, lng: 20.9416504 },
    { lat: 49.0217623, lng: 20.941174 },
    { lat: 49.0214596, lng: 20.9410095 },
    { lat: 49.02164, lng: 20.9400058 },
    { lat: 49.0202188, lng: 20.9392094 },
    { lat: 49.0203003, lng: 20.939088 },
    { lat: 49.0202344, lng: 20.9389013 },
    { lat: 49.020331, lng: 20.9385592 },
    { lat: 49.0203045, lng: 20.9384006 },
    { lat: 49.0204863, lng: 20.9380186 },
    { lat: 49.0206596, lng: 20.9378474 },
    { lat: 49.0205821, lng: 20.9373155 },
    { lat: 49.0207065, lng: 20.9369943 },
    { lat: 49.0208088, lng: 20.9362516 },
    { lat: 49.0212471, lng: 20.9350938 },
    { lat: 49.0213161, lng: 20.9344641 },
    { lat: 49.0214655, lng: 20.9342836 },
    { lat: 49.0215679, lng: 20.9339795 },
    { lat: 49.0218058, lng: 20.9339923 },
    { lat: 49.0220716, lng: 20.93366 },
    { lat: 49.022133, lng: 20.9334792 },
    { lat: 49.0221599, lng: 20.9331791 },
    { lat: 49.0222783, lng: 20.9329469 },
    { lat: 49.0221824, lng: 20.9327033 },
    { lat: 49.0224188, lng: 20.9324357 },
    { lat: 49.022445, lng: 20.9322128 },
    { lat: 49.0225398, lng: 20.9321491 },
    { lat: 49.0226453, lng: 20.9319039 },
    { lat: 49.02275, lng: 20.9318065 },
    { lat: 49.0228156, lng: 20.9315181 },
    { lat: 49.0227252, lng: 20.9313049 },
    { lat: 49.0228022, lng: 20.9309762 },
    { lat: 49.022727, lng: 20.9307184 },
    { lat: 49.0229159, lng: 20.9303782 },
    { lat: 49.0228598, lng: 20.9302466 },
    { lat: 49.0229493, lng: 20.9296239 },
    { lat: 49.023077, lng: 20.9292389 },
    { lat: 49.02316, lng: 20.9291607 },
    { lat: 49.0231885, lng: 20.928872 },
    { lat: 49.0231513, lng: 20.9288049 },
    { lat: 49.0232001, lng: 20.9284443 },
    { lat: 49.023264, lng: 20.928302 },
    { lat: 49.0233734, lng: 20.928211 },
    { lat: 49.0233825, lng: 20.9281092 },
    { lat: 49.0234969, lng: 20.9279956 },
    { lat: 49.0236443, lng: 20.9274968 },
    { lat: 49.0236484, lng: 20.9271468 },
    { lat: 49.0237282, lng: 20.9270101 },
    { lat: 49.0237132, lng: 20.9266967 },
    { lat: 49.0238074, lng: 20.9262849 },
    { lat: 49.02377, lng: 20.9261034 },
    { lat: 49.0238045, lng: 20.9259928 },
    { lat: 49.0237882, lng: 20.9259779 },
    { lat: 49.0232114, lng: 20.9265309 },
    { lat: 49.0231584, lng: 20.9256266 },
    { lat: 49.0224061, lng: 20.9248049 },
    { lat: 49.0222027, lng: 20.9245162 },
    { lat: 49.0217939, lng: 20.9250471 },
    { lat: 49.0202243, lng: 20.9312724 },
    { lat: 49.0193372, lng: 20.9336512 },
    { lat: 49.0200721, lng: 20.9343187 },
    { lat: 49.0192095, lng: 20.9369935 },
    { lat: 49.0181582, lng: 20.9397194 },
    { lat: 49.0171606, lng: 20.9414557 },
    { lat: 49.0162523, lng: 20.9427389 },
    { lat: 49.0148366, lng: 20.9441638 },
    { lat: 49.0143094, lng: 20.9445495 },
    { lat: 49.0147509, lng: 20.9454656 },
    { lat: 49.0149597, lng: 20.9460913 },
    { lat: 49.0138839, lng: 20.947212 },
    { lat: 49.0130708, lng: 20.947738 },
    { lat: 49.0122286, lng: 20.9490463 },
    { lat: 49.012224, lng: 20.9497132 },
    { lat: 49.0112819, lng: 20.950893 },
    { lat: 49.010806, lng: 20.9512742 },
    { lat: 49.0104933, lng: 20.9508373 },
    { lat: 49.0096421, lng: 20.9515726 },
    { lat: 49.0081354, lng: 20.9536784 },
    { lat: 49.006349, lng: 20.9558308 },
    { lat: 49.0054708, lng: 20.9570086 },
    { lat: 49.0020014, lng: 20.9611179 },
    { lat: 49.0005474, lng: 20.963046 },
    { lat: 48.9993377, lng: 20.9648845 },
    { lat: 48.9980607, lng: 20.9679831 },
    { lat: 48.9982247, lng: 20.9684121 },
    { lat: 48.9982072, lng: 20.969783 },
    { lat: 48.9984436, lng: 20.9699538 },
    { lat: 48.9976649, lng: 20.9714214 },
    { lat: 48.9961535, lng: 20.9721996 },
    { lat: 48.996238, lng: 20.9725075 },
    { lat: 48.9966305, lng: 20.9733488 },
    { lat: 48.9971508, lng: 20.9736623 },
    { lat: 48.9977349, lng: 20.9746115 },
    { lat: 48.9987782, lng: 20.9761034 },
    { lat: 48.9990023, lng: 20.9758411 },
    { lat: 48.9991774, lng: 20.9759219 },
    { lat: 48.9992987, lng: 20.976089 },
    { lat: 49.0000148, lng: 20.9775931 },
    { lat: 49.0006768, lng: 20.9791594 },
    { lat: 49.0006797, lng: 20.9794517 },
    { lat: 49.0010871, lng: 20.979827 },
    { lat: 49.001488, lng: 20.9805448 },
    { lat: 49.0018741, lng: 20.9807501 },
    { lat: 49.002067, lng: 20.9806303 },
    { lat: 49.0034645, lng: 20.9835755 },
    { lat: 49.0030167, lng: 20.9843618 },
    { lat: 49.0043522, lng: 20.9855721 },
    { lat: 49.0051189, lng: 20.9864987 },
    { lat: 49.0052188, lng: 20.986766 },
    { lat: 49.0049621, lng: 20.9869365 },
    { lat: 49.0045852, lng: 20.9870069 },
    { lat: 49.0044983, lng: 20.9873731 },
    { lat: 49.004733, lng: 20.9875982 },
    { lat: 49.0060141, lng: 20.9868892 },
    { lat: 49.0062314, lng: 20.9866565 },
    { lat: 49.006824, lng: 20.9885471 },
    { lat: 49.0073181, lng: 20.9903539 },
    { lat: 49.0068567, lng: 20.9906579 },
    { lat: 49.0063927, lng: 20.9910988 },
    { lat: 49.0069233, lng: 20.9930544 },
    { lat: 49.0071002, lng: 20.9935421 },
    { lat: 49.0109097, lng: 20.9901874 },
    { lat: 49.0141996, lng: 20.9878027 },
    { lat: 49.0158923, lng: 20.9856544 },
    { lat: 49.0189975, lng: 20.983137 },
    { lat: 49.0193128, lng: 20.9841919 },
    { lat: 49.0200422, lng: 20.9840257 },
    { lat: 49.0201758, lng: 20.9838807 },
    { lat: 49.0201973, lng: 20.9840246 },
    { lat: 49.0208401, lng: 20.9834796 },
    { lat: 49.0219539, lng: 20.9822049 },
    { lat: 49.0222996, lng: 20.98167 },
    { lat: 49.0226324, lng: 20.9812849 },
    { lat: 49.023023, lng: 20.9809799 },
    { lat: 49.0234093, lng: 20.9804451 },
    { lat: 49.0256229, lng: 20.9780569 },
    { lat: 49.0261341, lng: 20.9773475 },
    { lat: 49.0263467, lng: 20.9768384 },
    { lat: 49.0265042, lng: 20.9766309 },
    { lat: 49.0264605, lng: 20.9763994 },
    { lat: 49.0275776, lng: 20.9745816 },
    { lat: 49.0281422, lng: 20.973962 },
    { lat: 49.0288575, lng: 20.972588 },
    { lat: 49.0289417, lng: 20.9722028 },
    { lat: 49.030192, lng: 20.9704707 },
    { lat: 49.0306555, lng: 20.9698886 },
    { lat: 49.0321589, lng: 20.9677399 },
    { lat: 49.0324439, lng: 20.9671376 },
    { lat: 49.0328259, lng: 20.9665448 },
    { lat: 49.0335547, lng: 20.9656789 },
    { lat: 49.0338064, lng: 20.9652629 },
    { lat: 49.0334765, lng: 20.9650171 },
    { lat: 49.0333396, lng: 20.9645566 },
    { lat: 49.0319657, lng: 20.9619233 },
  ],
  MalýSlivník: [
    { lat: 49.1258757, lng: 21.2778252 },
    { lat: 49.1261299, lng: 21.2777479 },
    { lat: 49.1261917, lng: 21.2757837 },
    { lat: 49.1264831, lng: 21.2742739 },
    { lat: 49.1271281, lng: 21.272504 },
    { lat: 49.127077, lng: 21.2722672 },
    { lat: 49.1271934, lng: 21.2719946 },
    { lat: 49.1271937, lng: 21.2707137 },
    { lat: 49.1267283, lng: 21.2686588 },
    { lat: 49.126647, lng: 21.268508 },
    { lat: 49.1264359, lng: 21.2682969 },
    { lat: 49.1258684, lng: 21.2680979 },
    { lat: 49.1255943, lng: 21.2682255 },
    { lat: 49.1246618, lng: 21.2645911 },
    { lat: 49.1237061, lng: 21.2648368 },
    { lat: 49.1238064, lng: 21.2635517 },
    { lat: 49.1240122, lng: 21.2635138 },
    { lat: 49.1238824, lng: 21.2628393 },
    { lat: 49.1242964, lng: 21.2628681 },
    { lat: 49.125449, lng: 21.2626203 },
    { lat: 49.125183, lng: 21.2613953 },
    { lat: 49.1258927, lng: 21.2615723 },
    { lat: 49.1259726, lng: 21.2615311 },
    { lat: 49.1261716, lng: 21.2595043 },
    { lat: 49.1270623, lng: 21.2594151 },
    { lat: 49.1279442, lng: 21.2601044 },
    { lat: 49.1281018, lng: 21.2588964 },
    { lat: 49.128378, lng: 21.2575121 },
    { lat: 49.1281826, lng: 21.2563162 },
    { lat: 49.1281278, lng: 21.2534634 },
    { lat: 49.1277286, lng: 21.2492923 },
    { lat: 49.1275681, lng: 21.2491924 },
    { lat: 49.127153, lng: 21.2486443 },
    { lat: 49.1270263, lng: 21.2485509 },
    { lat: 49.1269246, lng: 21.2483559 },
    { lat: 49.1261676, lng: 21.2484361 },
    { lat: 49.1254694, lng: 21.248636 },
    { lat: 49.1251983, lng: 21.2486381 },
    { lat: 49.1246332, lng: 21.2489267 },
    { lat: 49.1233831, lng: 21.2500663 },
    { lat: 49.1226412, lng: 21.2506372 },
    { lat: 49.122812, lng: 21.2523168 },
    { lat: 49.122535, lng: 21.2524583 },
    { lat: 49.1218814, lng: 21.2530487 },
    { lat: 49.1216676, lng: 21.2530507 },
    { lat: 49.1196459, lng: 21.2548575 },
    { lat: 49.1196198, lng: 21.2545605 },
    { lat: 49.1192027, lng: 21.2551244 },
    { lat: 49.1183059, lng: 21.2559663 },
    { lat: 49.1179132, lng: 21.2557862 },
    { lat: 49.1177189, lng: 21.2557954 },
    { lat: 49.1175131, lng: 21.2561646 },
    { lat: 49.1168252, lng: 21.2562685 },
    { lat: 49.116277, lng: 21.2560793 },
    { lat: 49.1158148, lng: 21.2558295 },
    { lat: 49.1156676, lng: 21.2556137 },
    { lat: 49.1152558, lng: 21.2556861 },
    { lat: 49.1152706, lng: 21.2555446 },
    { lat: 49.1151579, lng: 21.2555393 },
    { lat: 49.1147374, lng: 21.2561734 },
    { lat: 49.113821, lng: 21.2566024 },
    { lat: 49.113725, lng: 21.2567407 },
    { lat: 49.1131063, lng: 21.25821 },
    { lat: 49.1128822, lng: 21.2582468 },
    { lat: 49.1124506, lng: 21.2580436 },
    { lat: 49.1119996, lng: 21.2584902 },
    { lat: 49.1117355, lng: 21.2585768 },
    { lat: 49.1113694, lng: 21.2582318 },
    { lat: 49.1109786, lng: 21.2584751 },
    { lat: 49.1105111, lng: 21.2590877 },
    { lat: 49.1101321, lng: 21.2591599 },
    { lat: 49.109682, lng: 21.2590913 },
    { lat: 49.1093538, lng: 21.2591764 },
    { lat: 49.1088194, lng: 21.2591066 },
    { lat: 49.1085912, lng: 21.2592234 },
    { lat: 49.1086467, lng: 21.2594188 },
    { lat: 49.1089375, lng: 21.2603506 },
    { lat: 49.1092624, lng: 21.2603063 },
    { lat: 49.109431, lng: 21.2615539 },
    { lat: 49.110696, lng: 21.2664189 },
    { lat: 49.1137643, lng: 21.2656356 },
    { lat: 49.1143393, lng: 21.2703487 },
    { lat: 49.1150368, lng: 21.27005 },
    { lat: 49.1152765, lng: 21.2704555 },
    { lat: 49.1153909, lng: 21.2708896 },
    { lat: 49.1151299, lng: 21.2752429 },
    { lat: 49.1152015, lng: 21.275448 },
    { lat: 49.1164203, lng: 21.275273 },
    { lat: 49.118314, lng: 21.2755672 },
    { lat: 49.120038, lng: 21.2765312 },
    { lat: 49.1212637, lng: 21.2763569 },
    { lat: 49.1229343, lng: 21.2764586 },
    { lat: 49.1242708, lng: 21.2771462 },
    { lat: 49.1246112, lng: 21.2779434 },
    { lat: 49.1258757, lng: 21.2778252 },
  ],
  Lada: [
    { lat: 49.0597679, lng: 21.3661964 },
    { lat: 49.0601177, lng: 21.3662347 },
    { lat: 49.0603901, lng: 21.3661647 },
    { lat: 49.0606082, lng: 21.3663793 },
    { lat: 49.0607406, lng: 21.3662557 },
    { lat: 49.0611704, lng: 21.3663345 },
    { lat: 49.0613976, lng: 21.3662528 },
    { lat: 49.0615, lng: 21.3660251 },
    { lat: 49.0616135, lng: 21.3659792 },
    { lat: 49.0617303, lng: 21.366166 },
    { lat: 49.0622674, lng: 21.3658409 },
    { lat: 49.0624921, lng: 21.3653723 },
    { lat: 49.0626997, lng: 21.3652797 },
    { lat: 49.062758, lng: 21.3650447 },
    { lat: 49.0626068, lng: 21.3648053 },
    { lat: 49.0611322, lng: 21.3632271 },
    { lat: 49.0605004, lng: 21.3623442 },
    { lat: 49.0598572, lng: 21.3609993 },
    { lat: 49.0597677, lng: 21.3606765 },
    { lat: 49.0594545, lng: 21.3601291 },
    { lat: 49.0583365, lng: 21.3567447 },
    { lat: 49.0574886, lng: 21.3549172 },
    { lat: 49.0570544, lng: 21.3542021 },
    { lat: 49.0567291, lng: 21.3538582 },
    { lat: 49.0551362, lng: 21.3528171 },
    { lat: 49.0544152, lng: 21.3518449 },
    { lat: 49.0527681, lng: 21.3502321 },
    { lat: 49.0525859, lng: 21.3506921 },
    { lat: 49.0524218, lng: 21.3518984 },
    { lat: 49.0521912, lng: 21.3526551 },
    { lat: 49.0519815, lng: 21.3532056 },
    { lat: 49.051721, lng: 21.3537129 },
    { lat: 49.0513613, lng: 21.3546719 },
    { lat: 49.0506776, lng: 21.3560037 },
    { lat: 49.0492327, lng: 21.3546417 },
    { lat: 49.0491805, lng: 21.3540624 },
    { lat: 49.049297, lng: 21.3536698 },
    { lat: 49.0489394, lng: 21.353291 },
    { lat: 49.0490444, lng: 21.3525925 },
    { lat: 49.0489217, lng: 21.3524786 },
    { lat: 49.049166, lng: 21.3506018 },
    { lat: 49.0491616, lng: 21.3502646 },
    { lat: 49.0472285, lng: 21.350069 },
    { lat: 49.0472025, lng: 21.3505963 },
    { lat: 49.0446045, lng: 21.3519194 },
    { lat: 49.0420712, lng: 21.3528413 },
    { lat: 49.0415605, lng: 21.3529189 },
    { lat: 49.0413191, lng: 21.3528259 },
    { lat: 49.0411337, lng: 21.3525715 },
    { lat: 49.039175, lng: 21.3531871 },
    { lat: 49.0390919, lng: 21.3533782 },
    { lat: 49.0387522, lng: 21.3533418 },
    { lat: 49.0381712, lng: 21.3538223 },
    { lat: 49.0378988, lng: 21.3542708 },
    { lat: 49.0377076, lng: 21.3542711 },
    { lat: 49.0375949, lng: 21.3545321 },
    { lat: 49.0371448, lng: 21.3549437 },
    { lat: 49.0368883, lng: 21.3548663 },
    { lat: 49.0367327, lng: 21.3550478 },
    { lat: 49.0367135, lng: 21.3553038 },
    { lat: 49.0368053, lng: 21.3554821 },
    { lat: 49.0371099, lng: 21.3571635 },
    { lat: 49.0376534, lng: 21.3587925 },
    { lat: 49.0379718, lng: 21.358749 },
    { lat: 49.0389765, lng: 21.3627524 },
    { lat: 49.0387521, lng: 21.3629224 },
    { lat: 49.0385711, lng: 21.3633459 },
    { lat: 49.0381906, lng: 21.363829 },
    { lat: 49.0376439, lng: 21.3647833 },
    { lat: 49.0374811, lng: 21.3653188 },
    { lat: 49.0368551, lng: 21.3665945 },
    { lat: 49.0367817, lng: 21.3669636 },
    { lat: 49.0366539, lng: 21.3685671 },
    { lat: 49.0362766, lng: 21.3685576 },
    { lat: 49.0360751, lng: 21.3726077 },
    { lat: 49.0359596, lng: 21.3726543 },
    { lat: 49.0359216, lng: 21.372511 },
    { lat: 49.0358516, lng: 21.3724645 },
    { lat: 49.035532, lng: 21.3726199 },
    { lat: 49.0353118, lng: 21.3723664 },
    { lat: 49.0349911, lng: 21.3723651 },
    { lat: 49.0348812, lng: 21.3722587 },
    { lat: 49.0348113, lng: 21.372025 },
    { lat: 49.034808, lng: 21.3717312 },
    { lat: 49.0351032, lng: 21.371779 },
    { lat: 49.0351483, lng: 21.3715919 },
    { lat: 49.0347585, lng: 21.3715036 },
    { lat: 49.0344516, lng: 21.3710926 },
    { lat: 49.0343657, lng: 21.3713449 },
    { lat: 49.0342031, lng: 21.3715952 },
    { lat: 49.0340829, lng: 21.3716336 },
    { lat: 49.0337023, lng: 21.3710939 },
    { lat: 49.0331531, lng: 21.3710933 },
    { lat: 49.0329589, lng: 21.371032 },
    { lat: 49.0329056, lng: 21.3706416 },
    { lat: 49.032718, lng: 21.3705398 },
    { lat: 49.0324496, lng: 21.370555 },
    { lat: 49.0299083, lng: 21.3718961 },
    { lat: 49.0286124, lng: 21.3725122 },
    { lat: 49.0282497, lng: 21.3742797 },
    { lat: 49.0285373, lng: 21.3754063 },
    { lat: 49.0285639, lng: 21.3763364 },
    { lat: 49.0284497, lng: 21.3767372 },
    { lat: 49.0284975, lng: 21.3769433 },
    { lat: 49.028632, lng: 21.3770825 },
    { lat: 49.0288724, lng: 21.3775626 },
    { lat: 49.0289447, lng: 21.3779897 },
    { lat: 49.0288585, lng: 21.3784532 },
    { lat: 49.0289355, lng: 21.3791177 },
    { lat: 49.0294949, lng: 21.3799177 },
    { lat: 49.0297996, lng: 21.3808283 },
    { lat: 49.0302999, lng: 21.3807388 },
    { lat: 49.0312241, lng: 21.3811667 },
    { lat: 49.0332823, lng: 21.3818619 },
    { lat: 49.0331135, lng: 21.3812904 },
    { lat: 49.0336157, lng: 21.3805388 },
    { lat: 49.0332257, lng: 21.3799907 },
    { lat: 49.0348002, lng: 21.3782551 },
    { lat: 49.0350716, lng: 21.3778427 },
    { lat: 49.0353966, lng: 21.3776863 },
    { lat: 49.0356925, lng: 21.376811 },
    { lat: 49.0362341, lng: 21.3758727 },
    { lat: 49.0368185, lng: 21.3752107 },
    { lat: 49.0373218, lng: 21.3744223 },
    { lat: 49.0374223, lng: 21.3744707 },
    { lat: 49.037574, lng: 21.3742447 },
    { lat: 49.0382857, lng: 21.3736522 },
    { lat: 49.0397324, lng: 21.3733781 },
    { lat: 49.0400782, lng: 21.372711 },
    { lat: 49.0422365, lng: 21.3726746 },
    { lat: 49.0422788, lng: 21.3725831 },
    { lat: 49.0441859, lng: 21.3725779 },
    { lat: 49.0442537, lng: 21.3722117 },
    { lat: 49.0445335, lng: 21.3715569 },
    { lat: 49.0447132, lng: 21.3708684 },
    { lat: 49.0452771, lng: 21.3706805 },
    { lat: 49.0453665, lng: 21.3707722 },
    { lat: 49.0456958, lng: 21.3698141 },
    { lat: 49.0460969, lng: 21.3683952 },
    { lat: 49.0462979, lng: 21.3672425 },
    { lat: 49.0463741, lng: 21.3664041 },
    { lat: 49.0463211, lng: 21.3631476 },
    { lat: 49.0463467, lng: 21.3628366 },
    { lat: 49.0474835, lng: 21.3627669 },
    { lat: 49.0473889, lng: 21.3615241 },
    { lat: 49.0475219, lng: 21.361412 },
    { lat: 49.0477729, lng: 21.3613477 },
    { lat: 49.0480049, lng: 21.361464 },
    { lat: 49.0483575, lng: 21.3613647 },
    { lat: 49.0487749, lng: 21.3614784 },
    { lat: 49.049558, lng: 21.3612841 },
    { lat: 49.0495585, lng: 21.3613784 },
    { lat: 49.0500565, lng: 21.361135 },
    { lat: 49.0513036, lng: 21.3602013 },
    { lat: 49.0513808, lng: 21.3601906 },
    { lat: 49.0515524, lng: 21.3603237 },
    { lat: 49.0518787, lng: 21.3610007 },
    { lat: 49.0520283, lng: 21.3611152 },
    { lat: 49.0520718, lng: 21.3612557 },
    { lat: 49.0521211, lng: 21.3616218 },
    { lat: 49.0520739, lng: 21.3619271 },
    { lat: 49.0521132, lng: 21.3620374 },
    { lat: 49.0519998, lng: 21.3623448 },
    { lat: 49.0518823, lng: 21.3629358 },
    { lat: 49.051735, lng: 21.3632405 },
    { lat: 49.0534871, lng: 21.3632485 },
    { lat: 49.0540197, lng: 21.3630712 },
    { lat: 49.0541186, lng: 21.3631461 },
    { lat: 49.0541262, lng: 21.3632827 },
    { lat: 49.0545146, lng: 21.363333 },
    { lat: 49.0548951, lng: 21.3633007 },
    { lat: 49.0551133, lng: 21.3645779 },
    { lat: 49.0551641, lng: 21.3652316 },
    { lat: 49.0563804, lng: 21.3645465 },
    { lat: 49.0563156, lng: 21.3637323 },
    { lat: 49.0565985, lng: 21.3634846 },
    { lat: 49.0566528, lng: 21.3628147 },
    { lat: 49.0568577, lng: 21.3626649 },
    { lat: 49.0576985, lng: 21.3624918 },
    { lat: 49.0582187, lng: 21.3624624 },
    { lat: 49.0585356, lng: 21.3639593 },
    { lat: 49.0589804, lng: 21.3649526 },
    { lat: 49.0590416, lng: 21.3650456 },
    { lat: 49.0591097, lng: 21.3649562 },
    { lat: 49.0592917, lng: 21.3653193 },
    { lat: 49.0592136, lng: 21.3654607 },
    { lat: 49.0592649, lng: 21.365592 },
    { lat: 49.0591926, lng: 21.3657387 },
    { lat: 49.059203, lng: 21.3658579 },
    { lat: 49.0593132, lng: 21.3661208 },
    { lat: 49.0595369, lng: 21.3663027 },
    { lat: 49.0596687, lng: 21.3661591 },
    { lat: 49.0597126, lng: 21.3663455 },
    { lat: 49.0597679, lng: 21.3661964 },
  ],
  Hermanovce: [
    { lat: 49.0502102, lng: 20.985413 },
    { lat: 49.0504318, lng: 20.985611 },
    { lat: 49.0497818, lng: 20.9858318 },
    { lat: 49.049133, lng: 20.986153 },
    { lat: 49.0490003, lng: 20.9863605 },
    { lat: 49.0479674, lng: 20.9869844 },
    { lat: 49.0477293, lng: 20.9870682 },
    { lat: 49.0472995, lng: 20.9874512 },
    { lat: 49.0471604, lng: 20.9878009 },
    { lat: 49.0471521, lng: 20.9879931 },
    { lat: 49.0468344, lng: 20.988392 },
    { lat: 49.0464876, lng: 20.9885953 },
    { lat: 49.0464568, lng: 20.9887501 },
    { lat: 49.0460796, lng: 20.9888485 },
    { lat: 49.0453538, lng: 20.9892638 },
    { lat: 49.043891, lng: 20.9892682 },
    { lat: 49.0437481, lng: 20.9894126 },
    { lat: 49.0434691, lng: 20.9894841 },
    { lat: 49.0431483, lng: 20.9892765 },
    { lat: 49.0427687, lng: 20.9892276 },
    { lat: 49.042427, lng: 20.9896225 },
    { lat: 49.0416103, lng: 20.989395 },
    { lat: 49.0413403, lng: 20.9891601 },
    { lat: 49.0410293, lng: 20.9891491 },
    { lat: 49.0410402, lng: 20.989342 },
    { lat: 49.0407656, lng: 20.9899897 },
    { lat: 49.0401768, lng: 20.9897074 },
    { lat: 49.0393608, lng: 20.9901869 },
    { lat: 49.0382649, lng: 20.9903333 },
    { lat: 49.0378438, lng: 20.9902923 },
    { lat: 49.037211, lng: 20.9904561 },
    { lat: 49.0370536, lng: 20.9909742 },
    { lat: 49.0369671, lng: 20.9908409 },
    { lat: 49.0370982, lng: 20.9933498 },
    { lat: 49.0377465, lng: 20.9954322 },
    { lat: 49.03814, lng: 20.9991092 },
    { lat: 49.0382774, lng: 21.0012722 },
    { lat: 49.0376594, lng: 21.0013698 },
    { lat: 49.0374486, lng: 21.0015286 },
    { lat: 49.0372165, lng: 21.0015897 },
    { lat: 49.0368263, lng: 21.0037236 },
    { lat: 49.0364194, lng: 21.0074402 },
    { lat: 49.0363602, lng: 21.0093716 },
    { lat: 49.0366114, lng: 21.0145563 },
    { lat: 49.0366572, lng: 21.0165723 },
    { lat: 49.0368708, lng: 21.0180476 },
    { lat: 49.0354334, lng: 21.0187605 },
    { lat: 49.0345242, lng: 21.0189395 },
    { lat: 49.03464, lng: 21.0223684 },
    { lat: 49.035252, lng: 21.0225348 },
    { lat: 49.0351627, lng: 21.0246574 },
    { lat: 49.033392, lng: 21.0248154 },
    { lat: 49.0333652, lng: 21.0267148 },
    { lat: 49.0319767, lng: 21.0266623 },
    { lat: 49.0318372, lng: 21.0286225 },
    { lat: 49.0322821, lng: 21.0287034 },
    { lat: 49.0323942, lng: 21.0286547 },
    { lat: 49.0345216, lng: 21.0289796 },
    { lat: 49.0347664, lng: 21.036344 },
    { lat: 49.0349846, lng: 21.0363376 },
    { lat: 49.0351587, lng: 21.037946 },
    { lat: 49.0351604, lng: 21.0393168 },
    { lat: 49.0357398, lng: 21.039446 },
    { lat: 49.0365692, lng: 21.0392717 },
    { lat: 49.0366258, lng: 21.039662 },
    { lat: 49.0365219, lng: 21.039961 },
    { lat: 49.0365137, lng: 21.0402974 },
    { lat: 49.0366408, lng: 21.040535 },
    { lat: 49.036727, lng: 21.0418366 },
    { lat: 49.0375554, lng: 21.0413919 },
    { lat: 49.0380785, lng: 21.0412097 },
    { lat: 49.038925, lng: 21.04124 },
    { lat: 49.039156, lng: 21.041183 },
    { lat: 49.039411, lng: 21.041151 },
    { lat: 49.039697, lng: 21.041153 },
    { lat: 49.039979, lng: 21.041142 },
    { lat: 49.040484, lng: 21.041043 },
    { lat: 49.041088, lng: 21.040846 },
    { lat: 49.041737, lng: 21.040594 },
    { lat: 49.041094, lng: 21.039438 },
    { lat: 49.041232, lng: 21.039316 },
    { lat: 49.04141, lng: 21.039187 },
    { lat: 49.041702, lng: 21.039062 },
    { lat: 49.041864, lng: 21.039666 },
    { lat: 49.042118, lng: 21.039554 },
    { lat: 49.042471, lng: 21.039524 },
    { lat: 49.042592, lng: 21.03954 },
    { lat: 49.042639, lng: 21.039693 },
    { lat: 49.043607, lng: 21.039065 },
    { lat: 49.043679, lng: 21.039319 },
    { lat: 49.043704, lng: 21.039384 },
    { lat: 49.04448, lng: 21.041422 },
    { lat: 49.044666, lng: 21.041826 },
    { lat: 49.044707, lng: 21.041918 },
    { lat: 49.044733, lng: 21.041979 },
    { lat: 49.045171, lng: 21.042591 },
    { lat: 49.045619, lng: 21.042007 },
    { lat: 49.045697, lng: 21.041974 },
    { lat: 49.047137, lng: 21.040443 },
    { lat: 49.047899, lng: 21.039493 },
    { lat: 49.048193, lng: 21.039045 },
    { lat: 49.048604, lng: 21.038351 },
    { lat: 49.04952, lng: 21.036949 },
    { lat: 49.050633, lng: 21.035174 },
    { lat: 49.050828, lng: 21.034865 },
    { lat: 49.051371, lng: 21.03393 },
    { lat: 49.051675, lng: 21.03347 },
    { lat: 49.052021, lng: 21.033002 },
    { lat: 49.05242, lng: 21.032556 },
    { lat: 49.052865, lng: 21.031905 },
    { lat: 49.053285, lng: 21.031459 },
    { lat: 49.053417, lng: 21.031238 },
    { lat: 49.05359, lng: 21.030899 },
    { lat: 49.053892, lng: 21.030751 },
    { lat: 49.054035, lng: 21.030652 },
    { lat: 49.054133, lng: 21.030545 },
    { lat: 49.054608, lng: 21.029763 },
    { lat: 49.054714, lng: 21.029728 },
    { lat: 49.054888, lng: 21.029636 },
    { lat: 49.055119, lng: 21.029453 },
    { lat: 49.055142, lng: 21.029428 },
    { lat: 49.055314, lng: 21.029323 },
    { lat: 49.055472, lng: 21.029052 },
    { lat: 49.055643, lng: 21.028663 },
    { lat: 49.055992, lng: 21.028162 },
    { lat: 49.056368, lng: 21.027591 },
    { lat: 49.056488, lng: 21.027377 },
    { lat: 49.056741, lng: 21.027019 },
    { lat: 49.05685, lng: 21.026851 },
    { lat: 49.056987, lng: 21.026697 },
    { lat: 49.057225, lng: 21.026386 },
    { lat: 49.057386, lng: 21.026119 },
    { lat: 49.057606, lng: 21.025865 },
    { lat: 49.057734, lng: 21.02568 },
    { lat: 49.057839, lng: 21.025404 },
    { lat: 49.057894, lng: 21.024989 },
    { lat: 49.057912, lng: 21.024675 },
    { lat: 49.057922, lng: 21.023893 },
    { lat: 49.057964, lng: 21.023325 },
    { lat: 49.058006, lng: 21.023116 },
    { lat: 49.058203, lng: 21.023255 },
    { lat: 49.058355, lng: 21.022885 },
    { lat: 49.058537, lng: 21.021926 },
    { lat: 49.0589, lng: 21.020457 },
    { lat: 49.058961, lng: 21.020074 },
    { lat: 49.059041, lng: 21.019855 },
    { lat: 49.05941, lng: 21.018959 },
    { lat: 49.059487, lng: 21.018781 },
    { lat: 49.059518, lng: 21.018689 },
    { lat: 49.059732, lng: 21.019201 },
    { lat: 49.060216, lng: 21.019865 },
    { lat: 49.060428, lng: 21.020171 },
    { lat: 49.060562, lng: 21.020422 },
    { lat: 49.060853, lng: 21.020824 },
    { lat: 49.060938, lng: 21.020779 },
    { lat: 49.061972, lng: 21.020473 },
    { lat: 49.062297, lng: 21.020222 },
    { lat: 49.062332, lng: 21.020088 },
    { lat: 49.062409, lng: 21.019966 },
    { lat: 49.062694, lng: 21.019185 },
    { lat: 49.062804, lng: 21.018885 },
    { lat: 49.062913, lng: 21.018653 },
    { lat: 49.063041, lng: 21.018298 },
    { lat: 49.063205, lng: 21.017738 },
    { lat: 49.063452, lng: 21.017233 },
    { lat: 49.063709, lng: 21.016841 },
    { lat: 49.063757, lng: 21.016655 },
    { lat: 49.063907, lng: 21.016482 },
    { lat: 49.064178, lng: 21.016316 },
    { lat: 49.064394, lng: 21.016146 },
    { lat: 49.064727, lng: 21.015853 },
    { lat: 49.065093, lng: 21.015598 },
    { lat: 49.065552, lng: 21.015418 },
    { lat: 49.066291, lng: 21.015317 },
    { lat: 49.066521, lng: 21.015307 },
    { lat: 49.066811, lng: 21.015266 },
    { lat: 49.066988, lng: 21.015248 },
    { lat: 49.067503, lng: 21.015155 },
    { lat: 49.067991, lng: 21.015131 },
    { lat: 49.068561, lng: 21.015016 },
    { lat: 49.068942, lng: 21.014731 },
    { lat: 49.069293, lng: 21.014501 },
    { lat: 49.069541, lng: 21.014127 },
    { lat: 49.069695, lng: 21.014049 },
    { lat: 49.070325, lng: 21.013586 },
    { lat: 49.070694, lng: 21.01339 },
    { lat: 49.070807, lng: 21.013386 },
    { lat: 49.070885, lng: 21.013339 },
    { lat: 49.071887, lng: 21.012354 },
    { lat: 49.072582, lng: 21.011358 },
    { lat: 49.072894, lng: 21.011033 },
    { lat: 49.073359, lng: 21.01063 },
    { lat: 49.073709, lng: 21.010487 },
    { lat: 49.074273, lng: 21.009987 },
    { lat: 49.074606, lng: 21.009844 },
    { lat: 49.074725, lng: 21.009793 },
    { lat: 49.074946, lng: 21.009626 },
    { lat: 49.075261, lng: 21.009474 },
    { lat: 49.075898, lng: 21.008986 },
    { lat: 49.076277, lng: 21.008742 },
    { lat: 49.076514, lng: 21.008413 },
    { lat: 49.076699, lng: 21.008249 },
    { lat: 49.076875, lng: 21.008037 },
    { lat: 49.077105, lng: 21.007649 },
    { lat: 49.077338, lng: 21.007108 },
    { lat: 49.077475, lng: 21.006889 },
    { lat: 49.077736, lng: 21.006593 },
    { lat: 49.077943, lng: 21.006416 },
    { lat: 49.078061, lng: 21.006199 },
    { lat: 49.078274, lng: 21.00601 },
    { lat: 49.078479, lng: 21.00577 },
    { lat: 49.078486, lng: 21.005786 },
    { lat: 49.07876, lng: 21.005522 },
    { lat: 49.07938, lng: 21.004703 },
    { lat: 49.079514, lng: 21.004449 },
    { lat: 49.079948, lng: 21.002962 },
    { lat: 49.079967, lng: 21.002706 },
    { lat: 49.079999, lng: 21.002557 },
    { lat: 49.080043, lng: 21.002327 },
    { lat: 49.080132, lng: 21.002029 },
    { lat: 49.080236, lng: 21.001735 },
    { lat: 49.080267, lng: 21.001602 },
    { lat: 49.080292, lng: 21.001389 },
    { lat: 49.080288, lng: 21.001193 },
    { lat: 49.080253, lng: 21.001047 },
    { lat: 49.080216, lng: 21.000821 },
    { lat: 49.080177, lng: 21.000499 },
    { lat: 49.080101, lng: 20.999573 },
    { lat: 49.080021, lng: 20.999255 },
    { lat: 49.079887, lng: 20.998669 },
    { lat: 49.079913, lng: 20.998132 },
    { lat: 49.080038, lng: 20.997212 },
    { lat: 49.079959, lng: 20.996509 },
    { lat: 49.079999, lng: 20.996208 },
    { lat: 49.079976, lng: 20.995907 },
    { lat: 49.079961, lng: 20.995622 },
    { lat: 49.079793, lng: 20.994575 },
    { lat: 49.079713, lng: 20.994216 },
    { lat: 49.079561, lng: 20.993346 },
    { lat: 49.079539, lng: 20.992905 },
    { lat: 49.079577, lng: 20.992654 },
    { lat: 49.079575, lng: 20.992321 },
    { lat: 49.07959, lng: 20.992065 },
    { lat: 49.079558, lng: 20.99124 },
    { lat: 49.079551, lng: 20.991208 },
    { lat: 49.0790514, lng: 20.9889922 },
    { lat: 49.0789583, lng: 20.9884867 },
    { lat: 49.0789471, lng: 20.9880129 },
    { lat: 49.0787018, lng: 20.9872318 },
    { lat: 49.0785726, lng: 20.9864838 },
    { lat: 49.0785264, lng: 20.9856677 },
    { lat: 49.0785856, lng: 20.9849032 },
    { lat: 49.0782477, lng: 20.9841414 },
    { lat: 49.0780456, lng: 20.9832694 },
    { lat: 49.0779034, lng: 20.9830196 },
    { lat: 49.0774358, lng: 20.9827025 },
    { lat: 49.0772836, lng: 20.9825196 },
    { lat: 49.0758074, lng: 20.9798073 },
    { lat: 49.0755254, lng: 20.9790384 },
    { lat: 49.0753886, lng: 20.9784098 },
    { lat: 49.0750238, lng: 20.9779603 },
    { lat: 49.0748056, lng: 20.9778966 },
    { lat: 49.0745141, lng: 20.9775532 },
    { lat: 49.07439, lng: 20.9774798 },
    { lat: 49.0741819, lng: 20.9767024 },
    { lat: 49.0737142, lng: 20.9757668 },
    { lat: 49.0735191, lng: 20.974189 },
    { lat: 49.0738803, lng: 20.9736394 },
    { lat: 49.0740878, lng: 20.9730103 },
    { lat: 49.0737401, lng: 20.9725653 },
    { lat: 49.0720813, lng: 20.9715873 },
    { lat: 49.0720732, lng: 20.9715786 },
    { lat: 49.0703312, lng: 20.9699591 },
    { lat: 49.0701595, lng: 20.9696676 },
    { lat: 49.069588, lng: 20.9692315 },
    { lat: 49.0689193, lng: 20.9689882 },
    { lat: 49.0684802, lng: 20.968953 },
    { lat: 49.0676044, lng: 20.969078 },
    { lat: 49.0670286, lng: 20.9689244 },
    { lat: 49.0664947, lng: 20.9693549 },
    { lat: 49.0657525, lng: 20.9705909 },
    { lat: 49.0655319, lng: 20.9712381 },
    { lat: 49.0648566, lng: 20.9719194 },
    { lat: 49.064706, lng: 20.9719575 },
    { lat: 49.0639487, lng: 20.9725038 },
    { lat: 49.0638926, lng: 20.9726121 },
    { lat: 49.0628851, lng: 20.9730469 },
    { lat: 49.0622488, lng: 20.9735816 },
    { lat: 49.0613671, lng: 20.9739769 },
    { lat: 49.0608706, lng: 20.9748239 },
    { lat: 49.0608319, lng: 20.9748089 },
    { lat: 49.0602875, lng: 20.975362 },
    { lat: 49.0599741, lng: 20.9755834 },
    { lat: 49.0596093, lng: 20.9760894 },
    { lat: 49.0592918, lng: 20.9763244 },
    { lat: 49.0589573, lng: 20.9769996 },
    { lat: 49.0587645, lng: 20.9770876 },
    { lat: 49.0585802, lng: 20.9770633 },
    { lat: 49.0581377, lng: 20.9774672 },
    { lat: 49.0578808, lng: 20.977793 },
    { lat: 49.0573987, lng: 20.9786355 },
    { lat: 49.0572611, lng: 20.9787318 },
    { lat: 49.0570989, lng: 20.9787267 },
    { lat: 49.0564262, lng: 20.9792429 },
    { lat: 49.0560388, lng: 20.9796805 },
    { lat: 49.0556061, lng: 20.9799394 },
    { lat: 49.0553364, lng: 20.9803221 },
    { lat: 49.0552976, lng: 20.9804733 },
    { lat: 49.0547462, lng: 20.9810605 },
    { lat: 49.0546478, lng: 20.9811158 },
    { lat: 49.054476, lng: 20.9810891 },
    { lat: 49.0542178, lng: 20.9813859 },
    { lat: 49.0539428, lng: 20.9819473 },
    { lat: 49.0538934, lng: 20.981889 },
    { lat: 49.053668, lng: 20.9822827 },
    { lat: 49.0533181, lng: 20.9823931 },
    { lat: 49.0531961, lng: 20.9826823 },
    { lat: 49.0532379, lng: 20.9828922 },
    { lat: 49.0529435, lng: 20.9833174 },
    { lat: 49.0525475, lng: 20.983423 },
    { lat: 49.0524132, lng: 20.9833127 },
    { lat: 49.052158, lng: 20.9835916 },
    { lat: 49.0520671, lng: 20.9842448 },
    { lat: 49.0519246, lng: 20.9845299 },
    { lat: 49.0517596, lng: 20.9845823 },
    { lat: 49.0514536, lng: 20.9844134 },
    { lat: 49.0509928, lng: 20.9849427 },
    { lat: 49.050571, lng: 20.9850531 },
    { lat: 49.0503465, lng: 20.9853462 },
    { lat: 49.0502102, lng: 20.985413 },
  ],
  Janovík: [
    { lat: 48.8279666, lng: 21.26843 },
    { lat: 48.8282583, lng: 21.2699049 },
    { lat: 48.828483, lng: 21.2716163 },
    { lat: 48.829449, lng: 21.2771701 },
    { lat: 48.8296495, lng: 21.2781343 },
    { lat: 48.8305641, lng: 21.28161 },
    { lat: 48.8312554, lng: 21.2839024 },
    { lat: 48.8319586, lng: 21.2859598 },
    { lat: 48.8343627, lng: 21.2914617 },
    { lat: 48.8351614, lng: 21.2908218 },
    { lat: 48.835947, lng: 21.2932612 },
    { lat: 48.8372973, lng: 21.2947674 },
    { lat: 48.8428841, lng: 21.2965639 },
    { lat: 48.8437348, lng: 21.2969688 },
    { lat: 48.8442626, lng: 21.2970509 },
    { lat: 48.8444988, lng: 21.2971162 },
    { lat: 48.844563, lng: 21.2969727 },
    { lat: 48.8446427, lng: 21.2969264 },
    { lat: 48.8448498, lng: 21.2969266 },
    { lat: 48.8452931, lng: 21.2964843 },
    { lat: 48.8455964, lng: 21.2961005 },
    { lat: 48.8457196, lng: 21.2956331 },
    { lat: 48.8457326, lng: 21.295361 },
    { lat: 48.8458283, lng: 21.2951753 },
    { lat: 48.8462591, lng: 21.2946175 },
    { lat: 48.843512, lng: 21.28742 },
    { lat: 48.8446224, lng: 21.2862685 },
    { lat: 48.8442867, lng: 21.2853392 },
    { lat: 48.8439608, lng: 21.2855407 },
    { lat: 48.8440353, lng: 21.2849931 },
    { lat: 48.8439845, lng: 21.2844722 },
    { lat: 48.8439304, lng: 21.2842452 },
    { lat: 48.8435202, lng: 21.2832401 },
    { lat: 48.8435406, lng: 21.2832051 },
    { lat: 48.8434376, lng: 21.2830365 },
    { lat: 48.8430871, lng: 21.2825792 },
    { lat: 48.8411865, lng: 21.2808174 },
    { lat: 48.8411498, lng: 21.2806478 },
    { lat: 48.8406009, lng: 21.2810615 },
    { lat: 48.8399367, lng: 21.2817465 },
    { lat: 48.8383581, lng: 21.277935 },
    { lat: 48.8377864, lng: 21.2763555 },
    { lat: 48.8363949, lng: 21.2711672 },
    { lat: 48.8361213, lng: 21.2698448 },
    { lat: 48.835324, lng: 21.2704122 },
    { lat: 48.8356096, lng: 21.2689767 },
    { lat: 48.8355451, lng: 21.2678314 },
    { lat: 48.8357156, lng: 21.2669635 },
    { lat: 48.8357638, lng: 21.265463 },
    { lat: 48.835839, lng: 21.2646886 },
    { lat: 48.83573, lng: 21.2638876 },
    { lat: 48.83554, lng: 21.2634141 },
    { lat: 48.8333827, lng: 21.2626459 },
    { lat: 48.8332305, lng: 21.2626738 },
    { lat: 48.8332203, lng: 21.2639147 },
    { lat: 48.8329969, lng: 21.264408 },
    { lat: 48.8280085, lng: 21.2684059 },
    { lat: 48.8279666, lng: 21.26843 },
  ],
  Lemešany: [
    { lat: 48.848097, lng: 21.242062 },
    { lat: 48.84786, lng: 21.242131 },
    { lat: 48.847465, lng: 21.242554 },
    { lat: 48.847176, lng: 21.242758 },
    { lat: 48.846903, lng: 21.243077 },
    { lat: 48.846644, lng: 21.243262 },
    { lat: 48.846298, lng: 21.244007 },
    { lat: 48.845957, lng: 21.24432 },
    { lat: 48.845471, lng: 21.244999 },
    { lat: 48.845336, lng: 21.245116 },
    { lat: 48.844354, lng: 21.245517 },
    { lat: 48.843984, lng: 21.24513 },
    { lat: 48.843774, lng: 21.244945 },
    { lat: 48.843621, lng: 21.24481 },
    { lat: 48.843263, lng: 21.244557 },
    { lat: 48.843007, lng: 21.244026 },
    { lat: 48.841358, lng: 21.24473 },
    { lat: 48.838732, lng: 21.244902 },
    { lat: 48.83768, lng: 21.245166 },
    { lat: 48.837644, lng: 21.247177 },
    { lat: 48.837642, lng: 21.247225 },
    { lat: 48.836862, lng: 21.247176 },
    { lat: 48.835978, lng: 21.247264 },
    { lat: 48.83552, lng: 21.247382 },
    { lat: 48.835098, lng: 21.247887 },
    { lat: 48.834399, lng: 21.248887 },
    { lat: 48.833846, lng: 21.249631 },
    { lat: 48.833543, lng: 21.250703 },
    { lat: 48.833415, lng: 21.250647 },
    { lat: 48.833102, lng: 21.250057 },
    { lat: 48.832209, lng: 21.248921 },
    { lat: 48.832141, lng: 21.248852 },
    { lat: 48.831728, lng: 21.248429 },
    { lat: 48.83123, lng: 21.248193 },
    { lat: 48.829935, lng: 21.247153 },
    { lat: 48.829559, lng: 21.246761 },
    { lat: 48.828852, lng: 21.246059 },
    { lat: 48.828548, lng: 21.246798 },
    { lat: 48.82808, lng: 21.247474 },
    { lat: 48.827672, lng: 21.248208 },
    { lat: 48.827501, lng: 21.248373 },
    { lat: 48.827164, lng: 21.24888 },
    { lat: 48.826998, lng: 21.249045 },
    { lat: 48.826526, lng: 21.2493 },
    { lat: 48.82642, lng: 21.249502 },
    { lat: 48.82617, lng: 21.249749 },
    { lat: 48.825905, lng: 21.249932 },
    { lat: 48.825473, lng: 21.250427 },
    { lat: 48.824835, lng: 21.250888 },
    { lat: 48.824414, lng: 21.251532 },
    { lat: 48.824086, lng: 21.252376 },
    { lat: 48.823985, lng: 21.252561 },
    { lat: 48.823842, lng: 21.252822 },
    { lat: 48.82399, lng: 21.252978 },
    { lat: 48.823923, lng: 21.253357 },
    { lat: 48.823869, lng: 21.253568 },
    { lat: 48.823698, lng: 21.254065 },
    { lat: 48.823618, lng: 21.254215 },
    { lat: 48.823577, lng: 21.254273 },
    { lat: 48.823494, lng: 21.254372 },
    { lat: 48.823322, lng: 21.254481 },
    { lat: 48.823158, lng: 21.254915 },
    { lat: 48.823147, lng: 21.254945 },
    { lat: 48.823113, lng: 21.255017 },
    { lat: 48.8245715, lng: 21.255753 },
    { lat: 48.8247862, lng: 21.2557399 },
    { lat: 48.8250073, lng: 21.2560218 },
    { lat: 48.8255803, lng: 21.2577799 },
    { lat: 48.8259765, lng: 21.2594944 },
    { lat: 48.8261007, lng: 21.2598731 },
    { lat: 48.8264687, lng: 21.2605282 },
    { lat: 48.8268526, lng: 21.2616697 },
    { lat: 48.8276349, lng: 21.2659561 },
    { lat: 48.8279666, lng: 21.26843 },
    { lat: 48.8280085, lng: 21.2684059 },
    { lat: 48.8329969, lng: 21.264408 },
    { lat: 48.8332203, lng: 21.2639147 },
    { lat: 48.8332305, lng: 21.2626738 },
    { lat: 48.8333827, lng: 21.2626459 },
    { lat: 48.83554, lng: 21.2634141 },
    { lat: 48.83573, lng: 21.2638876 },
    { lat: 48.835839, lng: 21.2646886 },
    { lat: 48.8357638, lng: 21.265463 },
    { lat: 48.8357156, lng: 21.2669635 },
    { lat: 48.8355451, lng: 21.2678314 },
    { lat: 48.8356096, lng: 21.2689767 },
    { lat: 48.835324, lng: 21.2704122 },
    { lat: 48.8361213, lng: 21.2698448 },
    { lat: 48.8363949, lng: 21.2711672 },
    { lat: 48.8377864, lng: 21.2763555 },
    { lat: 48.8383581, lng: 21.277935 },
    { lat: 48.8399367, lng: 21.2817465 },
    { lat: 48.8406009, lng: 21.2810615 },
    { lat: 48.8411498, lng: 21.2806478 },
    { lat: 48.8411865, lng: 21.2808174 },
    { lat: 48.8430871, lng: 21.2825792 },
    { lat: 48.8434376, lng: 21.2830365 },
    { lat: 48.8435406, lng: 21.2832051 },
    { lat: 48.8435202, lng: 21.2832401 },
    { lat: 48.8439304, lng: 21.2842452 },
    { lat: 48.8439845, lng: 21.2844722 },
    { lat: 48.8440353, lng: 21.2849931 },
    { lat: 48.8439608, lng: 21.2855407 },
    { lat: 48.8442867, lng: 21.2853392 },
    { lat: 48.8446224, lng: 21.2862685 },
    { lat: 48.843512, lng: 21.28742 },
    { lat: 48.8462591, lng: 21.2946175 },
    { lat: 48.8458283, lng: 21.2951753 },
    { lat: 48.8457326, lng: 21.295361 },
    { lat: 48.8457196, lng: 21.2956331 },
    { lat: 48.8455964, lng: 21.2961005 },
    { lat: 48.8452931, lng: 21.2964843 },
    { lat: 48.8458676, lng: 21.297278 },
    { lat: 48.8506398, lng: 21.2942426 },
    { lat: 48.8516431, lng: 21.2935981 },
    { lat: 48.8519657, lng: 21.2934029 },
    { lat: 48.8517884, lng: 21.2938238 },
    { lat: 48.8518336, lng: 21.2942776 },
    { lat: 48.8519882, lng: 21.2949154 },
    { lat: 48.8521935, lng: 21.2951147 },
    { lat: 48.8534391, lng: 21.2949986 },
    { lat: 48.853597, lng: 21.2951597 },
    { lat: 48.8540181, lng: 21.2944685 },
    { lat: 48.8541431, lng: 21.2945069 },
    { lat: 48.8541578, lng: 21.2948042 },
    { lat: 48.8542349, lng: 21.2951205 },
    { lat: 48.8544351, lng: 21.2954066 },
    { lat: 48.8552308, lng: 21.2955964 },
    { lat: 48.8555139, lng: 21.2953218 },
    { lat: 48.8556905, lng: 21.2949189 },
    { lat: 48.8558478, lng: 21.2943554 },
    { lat: 48.8559046, lng: 21.2935581 },
    { lat: 48.8559992, lng: 21.2931966 },
    { lat: 48.8560174, lng: 21.292903 },
    { lat: 48.8558754, lng: 21.2926767 },
    { lat: 48.855965, lng: 21.2925908 },
    { lat: 48.8560788, lng: 21.2926671 },
    { lat: 48.8563381, lng: 21.2930514 },
    { lat: 48.8566415, lng: 21.2932931 },
    { lat: 48.8569275, lng: 21.2933006 },
    { lat: 48.8575331, lng: 21.2928605 },
    { lat: 48.8578894, lng: 21.2924821 },
    { lat: 48.8581832, lng: 21.2919733 },
    { lat: 48.8582065, lng: 21.2912991 },
    { lat: 48.857656, lng: 21.2900751 },
    { lat: 48.8573826, lng: 21.2895929 },
    { lat: 48.8567168, lng: 21.2894948 },
    { lat: 48.8566376, lng: 21.2893335 },
    { lat: 48.8566696, lng: 21.2889835 },
    { lat: 48.8569609, lng: 21.2882384 },
    { lat: 48.8579707, lng: 21.2877366 },
    { lat: 48.8580552, lng: 21.2878493 },
    { lat: 48.8580991, lng: 21.2884377 },
    { lat: 48.8583201, lng: 21.289119 },
    { lat: 48.8584724, lng: 21.2893492 },
    { lat: 48.858661, lng: 21.2894865 },
    { lat: 48.8589113, lng: 21.2894271 },
    { lat: 48.8594365, lng: 21.2886795 },
    { lat: 48.8594823, lng: 21.2883743 },
    { lat: 48.8594594, lng: 21.2881035 },
    { lat: 48.85922, lng: 21.2875214 },
    { lat: 48.8590324, lng: 21.287532 },
    { lat: 48.8587181, lng: 21.2870384 },
    { lat: 48.8589209, lng: 21.2867525 },
    { lat: 48.8591776, lng: 21.2865433 },
    { lat: 48.8597681, lng: 21.2866055 },
    { lat: 48.8605637, lng: 21.2862338 },
    { lat: 48.8607769, lng: 21.2858797 },
    { lat: 48.8608207, lng: 21.2855456 },
    { lat: 48.860235, lng: 21.2851229 },
    { lat: 48.8593483, lng: 21.2835476 },
    { lat: 48.8592983, lng: 21.2823549 },
    { lat: 48.8593891, lng: 21.281498 },
    { lat: 48.8596422, lng: 21.2812704 },
    { lat: 48.8599428, lng: 21.281274 },
    { lat: 48.8608168, lng: 21.2815245 },
    { lat: 48.8610501, lng: 21.2813105 },
    { lat: 48.8611449, lng: 21.2811041 },
    { lat: 48.861214, lng: 21.2807504 },
    { lat: 48.8605655, lng: 21.2790862 },
    { lat: 48.860255, lng: 21.2780545 },
    { lat: 48.8602551, lng: 21.2768901 },
    { lat: 48.8603644, lng: 21.2764449 },
    { lat: 48.860755, lng: 21.2760122 },
    { lat: 48.8609043, lng: 21.2755571 },
    { lat: 48.8609108, lng: 21.2751467 },
    { lat: 48.8607102, lng: 21.2749582 },
    { lat: 48.8599614, lng: 21.2747239 },
    { lat: 48.8596613, lng: 21.2748401 },
    { lat: 48.8588344, lng: 21.2756599 },
    { lat: 48.8582592, lng: 21.2757969 },
    { lat: 48.8579265, lng: 21.2756273 },
    { lat: 48.8577278, lng: 21.2753472 },
    { lat: 48.8576761, lng: 21.2747994 },
    { lat: 48.8577758, lng: 21.2742359 },
    { lat: 48.8583864, lng: 21.2732984 },
    { lat: 48.8585579, lng: 21.2728775 },
    { lat: 48.8584033, lng: 21.2717984 },
    { lat: 48.8581247, lng: 21.2709725 },
    { lat: 48.8582549, lng: 21.2704523 },
    { lat: 48.8583429, lng: 21.2703846 },
    { lat: 48.8585002, lng: 21.2700447 },
    { lat: 48.8588658, lng: 21.2695636 },
    { lat: 48.8589265, lng: 21.269336 },
    { lat: 48.8588066, lng: 21.2688413 },
    { lat: 48.8588285, lng: 21.2679799 },
    { lat: 48.8589756, lng: 21.2676039 },
    { lat: 48.859716, lng: 21.266667 },
    { lat: 48.859541, lng: 21.266274 },
    { lat: 48.859499, lng: 21.266145 },
    { lat: 48.859479, lng: 21.266083 },
    { lat: 48.859465, lng: 21.266042 },
    { lat: 48.859417, lng: 21.265889 },
    { lat: 48.85953, lng: 21.265835 },
    { lat: 48.859599, lng: 21.265661 },
    { lat: 48.85946, lng: 21.265236 },
    { lat: 48.859446, lng: 21.265194 },
    { lat: 48.85885, lng: 21.263371 },
    { lat: 48.858357, lng: 21.262201 },
    { lat: 48.858212, lng: 21.261892 },
    { lat: 48.858132, lng: 21.261838 },
    { lat: 48.858043, lng: 21.26182 },
    { lat: 48.85792, lng: 21.261703 },
    { lat: 48.857834, lng: 21.261376 },
    { lat: 48.857122, lng: 21.258634 },
    { lat: 48.85705, lng: 21.258382 },
    { lat: 48.856996, lng: 21.258139 },
    { lat: 48.856971, lng: 21.258042 },
    { lat: 48.856567, lng: 21.257501 },
    { lat: 48.856141, lng: 21.256929 },
    { lat: 48.856053, lng: 21.256766 },
    { lat: 48.855866, lng: 21.256402 },
    { lat: 48.855542, lng: 21.255771 },
    { lat: 48.855499, lng: 21.255687 },
    { lat: 48.855197, lng: 21.255355 },
    { lat: 48.855021, lng: 21.255238 },
    { lat: 48.854582, lng: 21.254921 },
    { lat: 48.854251, lng: 21.254313 },
    { lat: 48.854112, lng: 21.254196 },
    { lat: 48.854161, lng: 21.254144 },
    { lat: 48.854132, lng: 21.253387 },
    { lat: 48.854201, lng: 21.252932 },
    { lat: 48.854217, lng: 21.252426 },
    { lat: 48.85419, lng: 21.25167 },
    { lat: 48.853357, lng: 21.251618 },
    { lat: 48.853273, lng: 21.251611 },
    { lat: 48.852911, lng: 21.250985 },
    { lat: 48.852451, lng: 21.249962 },
    { lat: 48.852435, lng: 21.249315 },
    { lat: 48.852527, lng: 21.248294 },
    { lat: 48.852674, lng: 21.247558 },
    { lat: 48.85265, lng: 21.247199 },
    { lat: 48.852398, lng: 21.246417 },
    { lat: 48.852401, lng: 21.246058 },
    { lat: 48.852461, lng: 21.245781 },
    { lat: 48.852407, lng: 21.245444 },
    { lat: 48.85235, lng: 21.24552 },
    { lat: 48.85214, lng: 21.245089 },
    { lat: 48.851925, lng: 21.245158 },
    { lat: 48.851907, lng: 21.244921 },
    { lat: 48.851884, lng: 21.244651 },
    { lat: 48.851833, lng: 21.244043 },
    { lat: 48.851816, lng: 21.24384 },
    { lat: 48.851462, lng: 21.243959 },
    { lat: 48.851408, lng: 21.243735 },
    { lat: 48.849669, lng: 21.244508 },
    { lat: 48.849626, lng: 21.244394 },
    { lat: 48.849418, lng: 21.244504 },
    { lat: 48.849005, lng: 21.244781 },
    { lat: 48.848685, lng: 21.243694 },
    { lat: 48.848629, lng: 21.243543 },
    { lat: 48.848629, lng: 21.243518 },
    { lat: 48.848101, lng: 21.242073 },
    { lat: 48.848097, lng: 21.242062 },
  ],
  Varhaňovce: [
    { lat: 48.8554564, lng: 21.3377725 },
    { lat: 48.8546963, lng: 21.3373838 },
    { lat: 48.8541863, lng: 21.3372293 },
    { lat: 48.854287, lng: 21.3360885 },
    { lat: 48.8534709, lng: 21.3355958 },
    { lat: 48.8531942, lng: 21.3355585 },
    { lat: 48.852272, lng: 21.3356841 },
    { lat: 48.8523523, lng: 21.3349921 },
    { lat: 48.8521377, lng: 21.3350802 },
    { lat: 48.8518937, lng: 21.3353079 },
    { lat: 48.8515591, lng: 21.3354104 },
    { lat: 48.8514498, lng: 21.3354762 },
    { lat: 48.851387, lng: 21.3355981 },
    { lat: 48.851234, lng: 21.3356005 },
    { lat: 48.8511172, lng: 21.3357147 },
    { lat: 48.8510595, lng: 21.3356701 },
    { lat: 48.8506922, lng: 21.3357287 },
    { lat: 48.8505761, lng: 21.3356839 },
    { lat: 48.8504646, lng: 21.3357573 },
    { lat: 48.8502169, lng: 21.3357934 },
    { lat: 48.8500986, lng: 21.3357466 },
    { lat: 48.8500632, lng: 21.3358012 },
    { lat: 48.8499577, lng: 21.335667 },
    { lat: 48.849788, lng: 21.335747 },
    { lat: 48.8497172, lng: 21.3358626 },
    { lat: 48.8496505, lng: 21.3358169 },
    { lat: 48.8494262, lng: 21.3360657 },
    { lat: 48.8493984, lng: 21.3361834 },
    { lat: 48.8492842, lng: 21.3361641 },
    { lat: 48.8488569, lng: 21.3362898 },
    { lat: 48.8488007, lng: 21.3361783 },
    { lat: 48.8483885, lng: 21.3362797 },
    { lat: 48.8484041, lng: 21.3364294 },
    { lat: 48.8475278, lng: 21.3387589 },
    { lat: 48.8477066, lng: 21.3405385 },
    { lat: 48.8475739, lng: 21.341333 },
    { lat: 48.847156, lng: 21.341772 },
    { lat: 48.8471758, lng: 21.3428347 },
    { lat: 48.8472882, lng: 21.3443632 },
    { lat: 48.8465289, lng: 21.3444467 },
    { lat: 48.8441258, lng: 21.3449476 },
    { lat: 48.8437734, lng: 21.3431499 },
    { lat: 48.839138, lng: 21.339471 },
    { lat: 48.838677, lng: 21.342652 },
    { lat: 48.838659, lng: 21.342802 },
    { lat: 48.837647, lng: 21.350094 },
    { lat: 48.837619, lng: 21.35029 },
    { lat: 48.837607, lng: 21.350376 },
    { lat: 48.837599, lng: 21.350431 },
    { lat: 48.837558, lng: 21.350913 },
    { lat: 48.83724, lng: 21.354508 },
    { lat: 48.837139, lng: 21.355658 },
    { lat: 48.837358, lng: 21.356893 },
    { lat: 48.838365, lng: 21.357431 },
    { lat: 48.838178, lng: 21.364717 },
    { lat: 48.838157, lng: 21.36529 },
    { lat: 48.838154, lng: 21.365378 },
    { lat: 48.838145, lng: 21.365633 },
    { lat: 48.838128, lng: 21.366094 },
    { lat: 48.838126, lng: 21.366195 },
    { lat: 48.838087, lng: 21.367257 },
    { lat: 48.839776, lng: 21.36834 },
    { lat: 48.84014, lng: 21.36863 },
    { lat: 48.84036, lng: 21.368745 },
    { lat: 48.840777, lng: 21.368876 },
    { lat: 48.840952, lng: 21.368899 },
    { lat: 48.841176, lng: 21.367706 },
    { lat: 48.841311, lng: 21.3670809 },
    { lat: 48.8416762, lng: 21.3672303 },
    { lat: 48.842087, lng: 21.367431 },
    { lat: 48.8425775, lng: 21.3676344 },
    { lat: 48.8428364, lng: 21.3677639 },
    { lat: 48.842796, lng: 21.368675 },
    { lat: 48.842878, lng: 21.369265 },
    { lat: 48.842882, lng: 21.369788 },
    { lat: 48.842766, lng: 21.36986 },
    { lat: 48.842815, lng: 21.370479 },
    { lat: 48.842938, lng: 21.370512 },
    { lat: 48.84313, lng: 21.370497 },
    { lat: 48.84325, lng: 21.370557 },
    { lat: 48.843283, lng: 21.370574 },
    { lat: 48.843416, lng: 21.370564 },
    { lat: 48.843436, lng: 21.370738 },
    { lat: 48.843515, lng: 21.370749 },
    { lat: 48.843855, lng: 21.37092 },
    { lat: 48.843956, lng: 21.370906 },
    { lat: 48.844152, lng: 21.371019 },
    { lat: 48.844271, lng: 21.371667 },
    { lat: 48.844547, lng: 21.371941 },
    { lat: 48.845211, lng: 21.37199 },
    { lat: 48.845843, lng: 21.372143 },
    { lat: 48.845919, lng: 21.372334 },
    { lat: 48.84591, lng: 21.372519 },
    { lat: 48.845793, lng: 21.372557 },
    { lat: 48.845839, lng: 21.372818 },
    { lat: 48.846178, lng: 21.37284 },
    { lat: 48.846298, lng: 21.372925 },
    { lat: 48.846357, lng: 21.373028 },
    { lat: 48.846466, lng: 21.373107 },
    { lat: 48.84652, lng: 21.373177 },
    { lat: 48.846629, lng: 21.373243 },
    { lat: 48.846643, lng: 21.373234 },
    { lat: 48.846779, lng: 21.373456 },
    { lat: 48.846941, lng: 21.373907 },
    { lat: 48.847044, lng: 21.37421 },
    { lat: 48.847536, lng: 21.375049 },
    { lat: 48.847815, lng: 21.375276 },
    { lat: 48.847939, lng: 21.375485 },
    { lat: 48.847978, lng: 21.375551 },
    { lat: 48.848357, lng: 21.376179 },
    { lat: 48.848522, lng: 21.376455 },
    { lat: 48.849346, lng: 21.377055 },
    { lat: 48.849665, lng: 21.37749 },
    { lat: 48.849738, lng: 21.37765 },
    { lat: 48.84988, lng: 21.377966 },
    { lat: 48.849946, lng: 21.378113 },
    { lat: 48.850051, lng: 21.378272 },
    { lat: 48.850894, lng: 21.379254 },
    { lat: 48.8515837, lng: 21.3796668 },
    { lat: 48.851927, lng: 21.3798867 },
    { lat: 48.852243, lng: 21.380188 },
    { lat: 48.8527554, lng: 21.380845 },
    { lat: 48.8531657, lng: 21.381315 },
    { lat: 48.8534831, lng: 21.3814971 },
    { lat: 48.8537845, lng: 21.38161 },
    { lat: 48.854178, lng: 21.3818486 },
    { lat: 48.8548355, lng: 21.382176 },
    { lat: 48.8557911, lng: 21.3822669 },
    { lat: 48.8559067, lng: 21.3822565 },
    { lat: 48.8561904, lng: 21.3823632 },
    { lat: 48.8565179, lng: 21.3826016 },
    { lat: 48.8570542, lng: 21.3829749 },
    { lat: 48.8575812, lng: 21.3833494 },
    { lat: 48.8580693, lng: 21.3835874 },
    { lat: 48.8592849, lng: 21.38362 },
    { lat: 48.8595676, lng: 21.3835385 },
    { lat: 48.8597574, lng: 21.3833721 },
    { lat: 48.8599874, lng: 21.3831019 },
    { lat: 48.8601011, lng: 21.3829566 },
    { lat: 48.8605381, lng: 21.3827875 },
    { lat: 48.8609907, lng: 21.3827017 },
    { lat: 48.8611272, lng: 21.3826889 },
    { lat: 48.8614896, lng: 21.3826813 },
    { lat: 48.8618531, lng: 21.3827326 },
    { lat: 48.8621086, lng: 21.3828371 },
    { lat: 48.8623583, lng: 21.3830187 },
    { lat: 48.8626604, lng: 21.3833641 },
    { lat: 48.863006, lng: 21.383377 },
    { lat: 48.86317, lng: 21.3833334 },
    { lat: 48.863243, lng: 21.383223 },
    { lat: 48.863391, lng: 21.38315 },
    { lat: 48.863684, lng: 21.38301 },
    { lat: 48.86435, lng: 21.382603 },
    { lat: 48.864619, lng: 21.382496 },
    { lat: 48.865082, lng: 21.382375 },
    { lat: 48.865386, lng: 21.382357 },
    { lat: 48.865796, lng: 21.382326 },
    { lat: 48.865907, lng: 21.38232 },
    { lat: 48.866118, lng: 21.382346 },
    { lat: 48.866552, lng: 21.382399 },
    { lat: 48.866659, lng: 21.382413 },
    { lat: 48.866959, lng: 21.382446 },
    { lat: 48.867905, lng: 21.382357 },
    { lat: 48.86832, lng: 21.382286 },
    { lat: 48.868723, lng: 21.382009 },
    { lat: 48.868959, lng: 21.381739 },
    { lat: 48.869365, lng: 21.381304 },
    { lat: 48.869543, lng: 21.381152 },
    { lat: 48.869561, lng: 21.381187 },
    { lat: 48.869696, lng: 21.38105 },
    { lat: 48.870158, lng: 21.380765 },
    { lat: 48.870798, lng: 21.380825 },
    { lat: 48.87087, lng: 21.380984 },
    { lat: 48.871241, lng: 21.381032 },
    { lat: 48.872418, lng: 21.380599 },
    { lat: 48.873001, lng: 21.379988 },
    { lat: 48.873218, lng: 21.379731 },
    { lat: 48.873465, lng: 21.379331 },
    { lat: 48.873717, lng: 21.378964 },
    { lat: 48.873992, lng: 21.37868 },
    { lat: 48.874223, lng: 21.378299 },
    { lat: 48.87453, lng: 21.37763 },
    { lat: 48.874754, lng: 21.377169 },
    { lat: 48.874793, lng: 21.3770609 },
    { lat: 48.8749848, lng: 21.3770402 },
    { lat: 48.8751202, lng: 21.3767886 },
    { lat: 48.8751465, lng: 21.3766081 },
    { lat: 48.8753268, lng: 21.376353 },
    { lat: 48.8753659, lng: 21.376153 },
    { lat: 48.8755848, lng: 21.3758699 },
    { lat: 48.8761041, lng: 21.3747956 },
    { lat: 48.8762089, lng: 21.3747612 },
    { lat: 48.8763201, lng: 21.3745295 },
    { lat: 48.876822, lng: 21.3740808 },
    { lat: 48.8770845, lng: 21.3739402 },
    { lat: 48.8772038, lng: 21.3739392 },
    { lat: 48.8774532, lng: 21.3736225 },
    { lat: 48.8775888, lng: 21.373565 },
    { lat: 48.8777564, lng: 21.3736354 },
    { lat: 48.8778686, lng: 21.3734036 },
    { lat: 48.8763148, lng: 21.3721983 },
    { lat: 48.8756683, lng: 21.3723248 },
    { lat: 48.8737214, lng: 21.3719481 },
    { lat: 48.8731899, lng: 21.3717017 },
    { lat: 48.872606, lng: 21.3712166 },
    { lat: 48.872339, lng: 21.3708267 },
    { lat: 48.8719436, lng: 21.3706297 },
    { lat: 48.8706468, lng: 21.3708379 },
    { lat: 48.8700969, lng: 21.3707365 },
    { lat: 48.8697339, lng: 21.3705273 },
    { lat: 48.8696023, lng: 21.3701835 },
    { lat: 48.8685911, lng: 21.3692581 },
    { lat: 48.8679824, lng: 21.3681317 },
    { lat: 48.8676315, lng: 21.3672304 },
    { lat: 48.8675044, lng: 21.3670379 },
    { lat: 48.8667693, lng: 21.3663875 },
    { lat: 48.8660861, lng: 21.3660054 },
    { lat: 48.865862, lng: 21.3657413 },
    { lat: 48.8650795, lng: 21.3653504 },
    { lat: 48.8648888, lng: 21.364877 },
    { lat: 48.8647493, lng: 21.3631067 },
    { lat: 48.8648486, lng: 21.3613086 },
    { lat: 48.8649725, lng: 21.3605224 },
    { lat: 48.8650192, lng: 21.3598312 },
    { lat: 48.8648068, lng: 21.3587857 },
    { lat: 48.8646162, lng: 21.3586566 },
    { lat: 48.8646345, lng: 21.3584409 },
    { lat: 48.864572, lng: 21.357784 },
    { lat: 48.8646372, lng: 21.356956 },
    { lat: 48.8637367, lng: 21.3568282 },
    { lat: 48.8625397, lng: 21.3570299 },
    { lat: 48.8622463, lng: 21.354786 },
    { lat: 48.8619803, lng: 21.3537133 },
    { lat: 48.8617588, lng: 21.3530502 },
    { lat: 48.8616681, lng: 21.3525329 },
    { lat: 48.8615794, lng: 21.352322 },
    { lat: 48.8593919, lng: 21.3529173 },
    { lat: 48.8588827, lng: 21.350311 },
    { lat: 48.8582757, lng: 21.3480097 },
    { lat: 48.857744, lng: 21.3479856 },
    { lat: 48.8573806, lng: 21.3478041 },
    { lat: 48.8568372, lng: 21.3441333 },
    { lat: 48.8555492, lng: 21.3444849 },
    { lat: 48.855367, lng: 21.3429198 },
    { lat: 48.8553248, lng: 21.3407336 },
    { lat: 48.8551212, lng: 21.3406987 },
    { lat: 48.8552864, lng: 21.3388924 },
    { lat: 48.8554564, lng: 21.3377725 },
  ],
  Župčany: [
    { lat: 49.0350315, lng: 21.1527425 },
    { lat: 49.0344368, lng: 21.1522286 },
    { lat: 49.0338995, lng: 21.1519663 },
    { lat: 49.0334194, lng: 21.1515487 },
    { lat: 49.0322789, lng: 21.1508585 },
    { lat: 49.0319041, lng: 21.1503935 },
    { lat: 49.0309425, lng: 21.1487707 },
    { lat: 49.029269, lng: 21.1449631 },
    { lat: 49.0297383, lng: 21.144078 },
    { lat: 49.0300707, lng: 21.1420278 },
    { lat: 49.0304575, lng: 21.140423 },
    { lat: 49.0305327, lng: 21.1399527 },
    { lat: 49.0303883, lng: 21.1393396 },
    { lat: 49.0305351, lng: 21.1386822 },
    { lat: 49.030742, lng: 21.138283 },
    { lat: 49.0311446, lng: 21.1365402 },
    { lat: 49.0311924, lng: 21.1355946 },
    { lat: 49.0313346, lng: 21.1347611 },
    { lat: 49.031258, lng: 21.1341918 },
    { lat: 49.0314277, lng: 21.1338602 },
    { lat: 49.0318192, lng: 21.1328301 },
    { lat: 49.0322666, lng: 21.1321416 },
    { lat: 49.0324439, lng: 21.1317036 },
    { lat: 49.0326565, lng: 21.1316241 },
    { lat: 49.03289, lng: 21.1316769 },
    { lat: 49.0328894, lng: 21.1316605 },
    { lat: 49.0325025, lng: 21.1309924 },
    { lat: 49.0313586, lng: 21.1304478 },
    { lat: 49.0304929, lng: 21.1296217 },
    { lat: 49.0300837, lng: 21.1293541 },
    { lat: 49.028857, lng: 21.1290376 },
    { lat: 49.0272725, lng: 21.1298904 },
    { lat: 49.0237094, lng: 21.1312212 },
    { lat: 49.0207244, lng: 21.1307102 },
    { lat: 49.0203331, lng: 21.1307598 },
    { lat: 49.0178346, lng: 21.1319563 },
    { lat: 49.0165049, lng: 21.1332141 },
    { lat: 49.0154743, lng: 21.1346013 },
    { lat: 49.0124089, lng: 21.1371265 },
    { lat: 49.014362, lng: 21.1416094 },
    { lat: 49.0143561, lng: 21.1417457 },
    { lat: 49.0132441, lng: 21.1417032 },
    { lat: 49.0126926, lng: 21.1417719 },
    { lat: 49.0109136, lng: 21.1415283 },
    { lat: 49.0083301, lng: 21.1415405 },
    { lat: 49.0078926, lng: 21.1461305 },
    { lat: 49.0071156, lng: 21.1527658 },
    { lat: 49.0062586, lng: 21.1522143 },
    { lat: 49.0051249, lng: 21.1517014 },
    { lat: 49.0047367, lng: 21.1517086 },
    { lat: 49.0028912, lng: 21.153171 },
    { lat: 49.0014646, lng: 21.1534645 },
    { lat: 49.0001195, lng: 21.1540626 },
    { lat: 48.9979279, lng: 21.1548276 },
    { lat: 48.996768, lng: 21.1549126 },
    { lat: 48.9958245, lng: 21.154293 },
    { lat: 48.9951708, lng: 21.1542551 },
    { lat: 48.9950116, lng: 21.1540983 },
    { lat: 48.9937425, lng: 21.154145 },
    { lat: 48.9935249, lng: 21.1542075 },
    { lat: 48.9923557, lng: 21.1549526 },
    { lat: 48.9914748, lng: 21.1553161 },
    { lat: 48.9909636, lng: 21.1559908 },
    { lat: 48.9903897, lng: 21.1565896 },
    { lat: 48.9892357, lng: 21.1571826 },
    { lat: 48.9889751, lng: 21.1570908 },
    { lat: 48.9882881, lng: 21.1565085 },
    { lat: 48.9875053, lng: 21.155983 },
    { lat: 48.987104, lng: 21.15532 },
    { lat: 48.9869935, lng: 21.1548902 },
    { lat: 48.9868054, lng: 21.1545631 },
    { lat: 48.986477, lng: 21.1536422 },
    { lat: 48.986176, lng: 21.1530963 },
    { lat: 48.9859677, lng: 21.1529452 },
    { lat: 48.9858852, lng: 21.152617 },
    { lat: 48.9855441, lng: 21.1529342 },
    { lat: 48.9854591, lng: 21.1528032 },
    { lat: 48.9853059, lng: 21.1528728 },
    { lat: 48.9849894, lng: 21.1531858 },
    { lat: 48.9848197, lng: 21.1530864 },
    { lat: 48.9847191, lng: 21.1531098 },
    { lat: 48.9847044, lng: 21.1532112 },
    { lat: 48.9846541, lng: 21.1532239 },
    { lat: 48.9846252, lng: 21.1531303 },
    { lat: 48.9842677, lng: 21.1533191 },
    { lat: 48.9842301, lng: 21.1531968 },
    { lat: 48.9840887, lng: 21.1532026 },
    { lat: 48.9838818, lng: 21.1532111 },
    { lat: 48.9837273, lng: 21.1533144 },
    { lat: 48.9837054, lng: 21.1532537 },
    { lat: 48.9835005, lng: 21.1533299 },
    { lat: 48.9833676, lng: 21.1534299 },
    { lat: 48.9831023, lng: 21.1534375 },
    { lat: 48.9829501, lng: 21.1533235 },
    { lat: 48.9826075, lng: 21.1535191 },
    { lat: 48.9824519, lng: 21.1534394 },
    { lat: 48.9821102, lng: 21.1535643 },
    { lat: 48.9819798, lng: 21.1535266 },
    { lat: 48.9818203, lng: 21.1533608 },
    { lat: 48.9817327, lng: 21.1531197 },
    { lat: 48.9810325, lng: 21.1528627 },
    { lat: 48.9808799, lng: 21.1527362 },
    { lat: 48.9808625, lng: 21.1528271 },
    { lat: 48.980544, lng: 21.1526443 },
    { lat: 48.9805496, lng: 21.1523598 },
    { lat: 48.9802572, lng: 21.152226 },
    { lat: 48.9802455, lng: 21.1520341 },
    { lat: 48.9801121, lng: 21.1520507 },
    { lat: 48.9799539, lng: 21.1521715 },
    { lat: 48.9799067, lng: 21.1520291 },
    { lat: 48.979753, lng: 21.1521496 },
    { lat: 48.9796641, lng: 21.1521504 },
    { lat: 48.9795943, lng: 21.1520476 },
    { lat: 48.9793772, lng: 21.152152 },
    { lat: 48.9794031, lng: 21.1523303 },
    { lat: 48.9792102, lng: 21.1522125 },
    { lat: 48.9791569, lng: 21.1522333 },
    { lat: 48.9791571, lng: 21.1523812 },
    { lat: 48.9784008, lng: 21.1523816 },
    { lat: 48.9783715, lng: 21.1523128 },
    { lat: 48.9775658, lng: 21.1527044 },
    { lat: 48.9775646, lng: 21.1529496 },
    { lat: 48.9773643, lng: 21.1530563 },
    { lat: 48.9772808, lng: 21.1529087 },
    { lat: 48.9771127, lng: 21.1531792 },
    { lat: 48.9768288, lng: 21.1532759 },
    { lat: 48.9767579, lng: 21.1534183 },
    { lat: 48.9768306, lng: 21.1536913 },
    { lat: 48.9768038, lng: 21.1537298 },
    { lat: 48.9766465, lng: 21.1535586 },
    { lat: 48.976452, lng: 21.1537773 },
    { lat: 48.9764451, lng: 21.1538789 },
    { lat: 48.9764001, lng: 21.1538793 },
    { lat: 48.9764545, lng: 21.153967 },
    { lat: 48.9763872, lng: 21.1540758 },
    { lat: 48.9762085, lng: 21.154048 },
    { lat: 48.975747, lng: 21.1541906 },
    { lat: 48.9753073, lng: 21.1545598 },
    { lat: 48.9749772, lng: 21.1546215 },
    { lat: 48.97445, lng: 21.1550991 },
    { lat: 48.9744573, lng: 21.1552942 },
    { lat: 48.9743122, lng: 21.1554405 },
    { lat: 48.9741146, lng: 21.1554858 },
    { lat: 48.974086, lng: 21.1553639 },
    { lat: 48.9737352, lng: 21.1556607 },
    { lat: 48.9737144, lng: 21.1558581 },
    { lat: 48.9734572, lng: 21.156085 },
    { lat: 48.9731945, lng: 21.1564677 },
    { lat: 48.9727436, lng: 21.1571139 },
    { lat: 48.9727266, lng: 21.1575034 },
    { lat: 48.9728589, lng: 21.1575626 },
    { lat: 48.9729652, lng: 21.1575093 },
    { lat: 48.9731001, lng: 21.1578237 },
    { lat: 48.9732917, lng: 21.1579379 },
    { lat: 48.9735504, lng: 21.1579012 },
    { lat: 48.973663, lng: 21.1579718 },
    { lat: 48.9738101, lng: 21.1579636 },
    { lat: 48.9738495, lng: 21.1578525 },
    { lat: 48.973815, lng: 21.1577594 },
    { lat: 48.9739852, lng: 21.1577721 },
    { lat: 48.9741099, lng: 21.1575853 },
    { lat: 48.9742635, lng: 21.1575655 },
    { lat: 48.9742556, lng: 21.1577781 },
    { lat: 48.9743357, lng: 21.1578565 },
    { lat: 48.9745738, lng: 21.1578775 },
    { lat: 48.974605, lng: 21.1577957 },
    { lat: 48.9748355, lng: 21.157935 },
    { lat: 48.9748674, lng: 21.157697 },
    { lat: 48.9749349, lng: 21.1576623 },
    { lat: 48.9750721, lng: 21.157796 },
    { lat: 48.9751194, lng: 21.1576955 },
    { lat: 48.9753757, lng: 21.1577903 },
    { lat: 48.9753753, lng: 21.1576743 },
    { lat: 48.9757042, lng: 21.1574999 },
    { lat: 48.9758646, lng: 21.1575534 },
    { lat: 48.9761061, lng: 21.1574337 },
    { lat: 48.9762306, lng: 21.1572104 },
    { lat: 48.9763408, lng: 21.157279 },
    { lat: 48.9764437, lng: 21.1571472 },
    { lat: 48.9766675, lng: 21.1570721 },
    { lat: 48.976823, lng: 21.1571869 },
    { lat: 48.9770357, lng: 21.157008 },
    { lat: 48.9770977, lng: 21.15726 },
    { lat: 48.9771498, lng: 21.1572698 },
    { lat: 48.9771699, lng: 21.1571208 },
    { lat: 48.9772732, lng: 21.1572938 },
    { lat: 48.9775757, lng: 21.157074 },
    { lat: 48.977925, lng: 21.1571599 },
    { lat: 48.9779827, lng: 21.1570594 },
    { lat: 48.9780569, lng: 21.1570941 },
    { lat: 48.978229, lng: 21.1569866 },
    { lat: 48.9783202, lng: 21.157024 },
    { lat: 48.9784625, lng: 21.1572863 },
    { lat: 48.978563, lng: 21.157266 },
    { lat: 48.9785459, lng: 21.1571863 },
    { lat: 48.9787216, lng: 21.1572258 },
    { lat: 48.978914, lng: 21.1570445 },
    { lat: 48.9790732, lng: 21.1571733 },
    { lat: 48.9791702, lng: 21.1571369 },
    { lat: 48.9793168, lng: 21.1572265 },
    { lat: 48.9796869, lng: 21.1571099 },
    { lat: 48.9798975, lng: 21.1572572 },
    { lat: 48.9802789, lng: 21.1572541 },
    { lat: 48.9804672, lng: 21.1575471 },
    { lat: 48.980647, lng: 21.1575454 },
    { lat: 48.9809216, lng: 21.1580392 },
    { lat: 48.9808925, lng: 21.1584021 },
    { lat: 48.9810462, lng: 21.1586413 },
    { lat: 48.9810825, lng: 21.1590055 },
    { lat: 48.9811656, lng: 21.1591421 },
    { lat: 48.9812706, lng: 21.1595421 },
    { lat: 48.9814507, lng: 21.1597913 },
    { lat: 48.98157, lng: 21.1601129 },
    { lat: 48.9816356, lng: 21.1606592 },
    { lat: 48.9820015, lng: 21.1618073 },
    { lat: 48.9822502, lng: 21.1620206 },
    { lat: 48.9826173, lng: 21.1625647 },
    { lat: 48.9826409, lng: 21.1626693 },
    { lat: 48.9827864, lng: 21.162753 },
    { lat: 48.9832966, lng: 21.163331 },
    { lat: 48.9834929, lng: 21.1634568 },
    { lat: 48.9837461, lng: 21.1635659 },
    { lat: 48.9845228, lng: 21.1636742 },
    { lat: 48.9846058, lng: 21.1637782 },
    { lat: 48.984931, lng: 21.1635245 },
    { lat: 48.9850378, lng: 21.1635902 },
    { lat: 48.9851209, lng: 21.1637296 },
    { lat: 48.9851984, lng: 21.1643689 },
    { lat: 48.9853034, lng: 21.1647679 },
    { lat: 48.9855496, lng: 21.1653355 },
    { lat: 48.9857015, lng: 21.1654416 },
    { lat: 48.9859559, lng: 21.1660469 },
    { lat: 48.9869474, lng: 21.1663714 },
    { lat: 48.9872881, lng: 21.1663273 },
    { lat: 48.9876301, lng: 21.1661773 },
    { lat: 48.9879422, lng: 21.16616 },
    { lat: 48.9882408, lng: 21.1660265 },
    { lat: 48.988417, lng: 21.1662598 },
    { lat: 48.9887637, lng: 21.1660884 },
    { lat: 48.9893144, lng: 21.1656718 },
    { lat: 48.9902896, lng: 21.1645817 },
    { lat: 48.9912319, lng: 21.1634152 },
    { lat: 48.9915476, lng: 21.1629034 },
    { lat: 48.9920133, lng: 21.1625749 },
    { lat: 48.9921461, lng: 21.162576 },
    { lat: 48.9922007, lng: 21.1626301 },
    { lat: 48.9922716, lng: 21.1635573 },
    { lat: 48.992356, lng: 21.1640934 },
    { lat: 48.9926493, lng: 21.1653653 },
    { lat: 48.9926422, lng: 21.1659621 },
    { lat: 48.9924882, lng: 21.166499 },
    { lat: 48.9923682, lng: 21.1677681 },
    { lat: 48.9925124, lng: 21.16816 },
    { lat: 48.9925683, lng: 21.1688637 },
    { lat: 48.9927026, lng: 21.1693112 },
    { lat: 48.9928434, lng: 21.1700302 },
    { lat: 48.9930265, lng: 21.1705498 },
    { lat: 48.9930995, lng: 21.1705857 },
    { lat: 48.9940285, lng: 21.1705279 },
    { lat: 48.9943571, lng: 21.1706739 },
    { lat: 48.9945061, lng: 21.1708315 },
    { lat: 48.9945532, lng: 21.1709696 },
    { lat: 48.9945985, lng: 21.1714444 },
    { lat: 48.9957045, lng: 21.1705752 },
    { lat: 48.996531, lng: 21.170303 },
    { lat: 48.9969697, lng: 21.1695117 },
    { lat: 48.9974047, lng: 21.1689242 },
    { lat: 48.9981798, lng: 21.1690976 },
    { lat: 48.9983285, lng: 21.1690635 },
    { lat: 48.9993871, lng: 21.1701653 },
    { lat: 48.9996254, lng: 21.1703702 },
    { lat: 48.9998251, lng: 21.1704514 },
    { lat: 49.0002093, lng: 21.1703633 },
    { lat: 49.0009938, lng: 21.1705109 },
    { lat: 49.0019013, lng: 21.1697063 },
    { lat: 49.0019247, lng: 21.16973 },
    { lat: 49.0024048, lng: 21.1690768 },
    { lat: 49.0039616, lng: 21.1717745 },
    { lat: 49.004814, lng: 21.1708939 },
    { lat: 49.004873, lng: 21.1715161 },
    { lat: 49.0050269, lng: 21.1701805 },
    { lat: 49.0050657, lng: 21.1700956 },
    { lat: 49.0051495, lng: 21.170111 },
    { lat: 49.0052207, lng: 21.170365 },
    { lat: 49.0059317, lng: 21.1719338 },
    { lat: 49.0063257, lng: 21.1703277 },
    { lat: 49.0063618, lng: 21.1703674 },
    { lat: 49.0067813, lng: 21.1696447 },
    { lat: 49.0070235, lng: 21.1694375 },
    { lat: 49.0073504, lng: 21.1692445 },
    { lat: 49.0087413, lng: 21.1690276 },
    { lat: 49.0101812, lng: 21.1685777 },
    { lat: 49.0110012, lng: 21.1686037 },
    { lat: 49.0113152, lng: 21.1684963 },
    { lat: 49.0129264, lng: 21.1671258 },
    { lat: 49.0147879, lng: 21.1634877 },
    { lat: 49.0151409, lng: 21.162693 },
    { lat: 49.0153293, lng: 21.1629539 },
    { lat: 49.0152643, lng: 21.1636082 },
    { lat: 49.0166906, lng: 21.1661929 },
    { lat: 49.0183387, lng: 21.1648909 },
    { lat: 49.0181133, lng: 21.1644204 },
    { lat: 49.0196664, lng: 21.1625655 },
    { lat: 49.0196871, lng: 21.162616 },
    { lat: 49.0214511, lng: 21.160742 },
    { lat: 49.0215486, lng: 21.1608708 },
    { lat: 49.0241916, lng: 21.1575366 },
    { lat: 49.0256257, lng: 21.1558739 },
    { lat: 49.0259728, lng: 21.1555351 },
    { lat: 49.0270608, lng: 21.1548464 },
    { lat: 49.0281516, lng: 21.1538957 },
    { lat: 49.0282137, lng: 21.1540457 },
    { lat: 49.028412, lng: 21.1539111 },
    { lat: 49.0285954, lng: 21.1545149 },
    { lat: 49.0294821, lng: 21.153947 },
    { lat: 49.0315186, lng: 21.1529712 },
    { lat: 49.0324264, lng: 21.1523189 },
    { lat: 49.0325315, lng: 21.1523295 },
    { lat: 49.0337987, lng: 21.1531565 },
    { lat: 49.0338801, lng: 21.1532689 },
    { lat: 49.0350181, lng: 21.1527435 },
    { lat: 49.0350315, lng: 21.1527425 },
  ],
  Červenica: [
    { lat: 48.9102996, lng: 21.4264848 },
    { lat: 48.9100752, lng: 21.4274264 },
    { lat: 48.9105116, lng: 21.4280384 },
    { lat: 48.9107919, lng: 21.4291148 },
    { lat: 48.910545, lng: 21.429565 },
    { lat: 48.9100746, lng: 21.4293309 },
    { lat: 48.9095164, lng: 21.4295216 },
    { lat: 48.9090498, lng: 21.4295291 },
    { lat: 48.9071645, lng: 21.4305443 },
    { lat: 48.9069248, lng: 21.433046 },
    { lat: 48.9062383, lng: 21.4331119 },
    { lat: 48.9051534, lng: 21.4333767 },
    { lat: 48.904039, lng: 21.4333652 },
    { lat: 48.9035311, lng: 21.4331168 },
    { lat: 48.9028808, lng: 21.4330436 },
    { lat: 48.9020324, lng: 21.4327334 },
    { lat: 48.900932, lng: 21.4321223 },
    { lat: 48.8986463, lng: 21.4310681 },
    { lat: 48.8986823, lng: 21.4320721 },
    { lat: 48.8979891, lng: 21.4319507 },
    { lat: 48.8977406, lng: 21.4317271 },
    { lat: 48.897631, lng: 21.4318654 },
    { lat: 48.8971995, lng: 21.4317682 },
    { lat: 48.896878, lng: 21.4317802 },
    { lat: 48.8967158, lng: 21.4316584 },
    { lat: 48.8959926, lng: 21.4318988 },
    { lat: 48.8953002, lng: 21.4323062 },
    { lat: 48.894823, lng: 21.4321521 },
    { lat: 48.8946821, lng: 21.4322191 },
    { lat: 48.8944879, lng: 21.4325362 },
    { lat: 48.8941639, lng: 21.4327382 },
    { lat: 48.8935506, lng: 21.4327291 },
    { lat: 48.8930686, lng: 21.4328716 },
    { lat: 48.8928499, lng: 21.433205 },
    { lat: 48.892511, lng: 21.4333494 },
    { lat: 48.8921795, lng: 21.4331307 },
    { lat: 48.8914375, lng: 21.4331173 },
    { lat: 48.891459, lng: 21.4328931 },
    { lat: 48.8913728, lng: 21.4327923 },
    { lat: 48.8911847, lng: 21.4328247 },
    { lat: 48.8910464, lng: 21.4327081 },
    { lat: 48.8907515, lng: 21.4327416 },
    { lat: 48.8904794, lng: 21.4326362 },
    { lat: 48.8899945, lng: 21.4322143 },
    { lat: 48.8898596, lng: 21.4323003 },
    { lat: 48.8895898, lng: 21.4321146 },
    { lat: 48.8895795, lng: 21.4318765 },
    { lat: 48.8894812, lng: 21.4318382 },
    { lat: 48.8894338, lng: 21.4318198 },
    { lat: 48.8892743, lng: 21.4319038 },
    { lat: 48.8892125, lng: 21.4317578 },
    { lat: 48.8889978, lng: 21.4316496 },
    { lat: 48.8885457, lng: 21.4316536 },
    { lat: 48.8881266, lng: 21.4314805 },
    { lat: 48.888049, lng: 21.4313363 },
    { lat: 48.8875577, lng: 21.4310383 },
    { lat: 48.8871005, lng: 21.4309507 },
    { lat: 48.8866403, lng: 21.4305652 },
    { lat: 48.8864801, lng: 21.4303611 },
    { lat: 48.8860285, lng: 21.4296496 },
    { lat: 48.8859852, lng: 21.4294055 },
    { lat: 48.8857838, lng: 21.4289857 },
    { lat: 48.8851946, lng: 21.429034 },
    { lat: 48.8849915, lng: 21.4287885 },
    { lat: 48.8846893, lng: 21.4289922 },
    { lat: 48.8842515, lng: 21.4290268 },
    { lat: 48.8840596, lng: 21.4294701 },
    { lat: 48.8837603, lng: 21.4295096 },
    { lat: 48.8834686, lng: 21.4298063 },
    { lat: 48.8833001, lng: 21.4296622 },
    { lat: 48.8830082, lng: 21.4296071 },
    { lat: 48.8829037, lng: 21.4296846 },
    { lat: 48.8828776, lng: 21.4298011 },
    { lat: 48.8825869, lng: 21.4297463 },
    { lat: 48.8823038, lng: 21.4300689 },
    { lat: 48.8821007, lng: 21.4301244 },
    { lat: 48.8816997, lng: 21.4301085 },
    { lat: 48.8814973, lng: 21.4301951 },
    { lat: 48.8810995, lng: 21.4300485 },
    { lat: 48.8809967, lng: 21.4301462 },
    { lat: 48.8805937, lng: 21.4300654 },
    { lat: 48.8800532, lng: 21.4302856 },
    { lat: 48.87986, lng: 21.4302907 },
    { lat: 48.8797656, lng: 21.430419 },
    { lat: 48.8795941, lng: 21.4303587 },
    { lat: 48.8794723, lng: 21.4305074 },
    { lat: 48.8790159, lng: 21.4304434 },
    { lat: 48.8787452, lng: 21.4307272 },
    { lat: 48.8786213, lng: 21.4302622 },
    { lat: 48.8771881, lng: 21.4306154 },
    { lat: 48.8769337, lng: 21.4306147 },
    { lat: 48.8770701, lng: 21.4298952 },
    { lat: 48.8771733, lng: 21.4299247 },
    { lat: 48.8779621, lng: 21.4284234 },
    { lat: 48.877923, lng: 21.4283603 },
    { lat: 48.8778524, lng: 21.4284054 },
    { lat: 48.8779681, lng: 21.4281703 },
    { lat: 48.8785086, lng: 21.4279158 },
    { lat: 48.8785049, lng: 21.4277062 },
    { lat: 48.8786165, lng: 21.4274867 },
    { lat: 48.878621, lng: 21.427351 },
    { lat: 48.878365, lng: 21.427385 },
    { lat: 48.877594, lng: 21.427359 },
    { lat: 48.877183, lng: 21.427375 },
    { lat: 48.876773, lng: 21.427569 },
    { lat: 48.876124, lng: 21.427831 },
    { lat: 48.875638, lng: 21.42795 },
    { lat: 48.875512, lng: 21.427927 },
    { lat: 48.875473, lng: 21.427919 },
    { lat: 48.874974, lng: 21.427825 },
    { lat: 48.874656, lng: 21.42791 },
    { lat: 48.874447, lng: 21.42832 },
    { lat: 48.874305, lng: 21.428331 },
    { lat: 48.87397, lng: 21.42849 },
    { lat: 48.873796, lng: 21.428687 },
    { lat: 48.873546, lng: 21.429216 },
    { lat: 48.873546, lng: 21.429217 },
    { lat: 48.873406, lng: 21.42958 },
    { lat: 48.873025, lng: 21.429918 },
    { lat: 48.872948, lng: 21.430119 },
    { lat: 48.873063, lng: 21.431148 },
    { lat: 48.87335, lng: 21.431131 },
    { lat: 48.873819, lng: 21.431192 },
    { lat: 48.873802, lng: 21.431594 },
    { lat: 48.874458, lng: 21.43141 },
    { lat: 48.874486, lng: 21.431556 },
    { lat: 48.874675, lng: 21.431496 },
    { lat: 48.874697, lng: 21.432138 },
    { lat: 48.874626, lng: 21.432384 },
    { lat: 48.874601, lng: 21.432742 },
    { lat: 48.87462, lng: 21.433339 },
    { lat: 48.874469, lng: 21.433449 },
    { lat: 48.874421, lng: 21.433485 },
    { lat: 48.874326, lng: 21.433554 },
    { lat: 48.874154, lng: 21.433521 },
    { lat: 48.873716, lng: 21.433671 },
    { lat: 48.873362, lng: 21.433843 },
    { lat: 48.873019, lng: 21.43407 },
    { lat: 48.872739, lng: 21.434297 },
    { lat: 48.872631, lng: 21.434461 },
    { lat: 48.872613, lng: 21.434581 },
    { lat: 48.872612, lng: 21.434587 },
    { lat: 48.872675, lng: 21.434963 },
    { lat: 48.872831, lng: 21.435281 },
    { lat: 48.873089, lng: 21.436173 },
    { lat: 48.873244, lng: 21.436827 },
    { lat: 48.873752, lng: 21.436348 },
    { lat: 48.8742, lng: 21.435982 },
    { lat: 48.87441, lng: 21.436738 },
    { lat: 48.874432, lng: 21.437451 },
    { lat: 48.874479, lng: 21.437623 },
    { lat: 48.874544, lng: 21.437897 },
    { lat: 48.874632, lng: 21.438102 },
    { lat: 48.874846, lng: 21.438668 },
    { lat: 48.874935, lng: 21.438928 },
    { lat: 48.87509, lng: 21.439149 },
    { lat: 48.875297, lng: 21.439354 },
    { lat: 48.87546, lng: 21.439273 },
    { lat: 48.875709, lng: 21.439102 },
    { lat: 48.875809, lng: 21.439089 },
    { lat: 48.876032, lng: 21.439444 },
    { lat: 48.876204, lng: 21.439678 },
    { lat: 48.876883, lng: 21.440301 },
    { lat: 48.876851, lng: 21.440441 },
    { lat: 48.876835, lng: 21.440513 },
    { lat: 48.876775, lng: 21.440725 },
    { lat: 48.876683, lng: 21.440795 },
    { lat: 48.876346, lng: 21.441002 },
    { lat: 48.876146, lng: 21.441034 },
    { lat: 48.875961, lng: 21.440957 },
    { lat: 48.875825, lng: 21.440801 },
    { lat: 48.875762, lng: 21.440684 },
    { lat: 48.875676, lng: 21.440713 },
    { lat: 48.875628, lng: 21.44073 },
    { lat: 48.875472, lng: 21.440785 },
    { lat: 48.875387, lng: 21.440814 },
    { lat: 48.875225, lng: 21.440854 },
    { lat: 48.875106, lng: 21.440565 },
    { lat: 48.87503, lng: 21.44037 },
    { lat: 48.87444, lng: 21.43996 },
    { lat: 48.87392, lng: 21.439606 },
    { lat: 48.873832, lng: 21.439555 },
    { lat: 48.873802, lng: 21.439591 },
    { lat: 48.873587, lng: 21.439849 },
    { lat: 48.873547, lng: 21.439949 },
    { lat: 48.873655, lng: 21.440115 },
    { lat: 48.873834, lng: 21.440603 },
    { lat: 48.873836, lng: 21.44061 },
    { lat: 48.873882, lng: 21.440787 },
    { lat: 48.873895, lng: 21.440838 },
    { lat: 48.873941, lng: 21.44101 },
    { lat: 48.873962, lng: 21.441226 },
    { lat: 48.873857, lng: 21.441585 },
    { lat: 48.873893, lng: 21.441758 },
    { lat: 48.874066, lng: 21.442049 },
    { lat: 48.874137, lng: 21.442558 },
    { lat: 48.874039, lng: 21.442632 },
    { lat: 48.873727, lng: 21.442536 },
    { lat: 48.873578, lng: 21.442374 },
    { lat: 48.873393, lng: 21.442138 },
    { lat: 48.873105, lng: 21.442007 },
    { lat: 48.872912, lng: 21.441954 },
    { lat: 48.872825, lng: 21.441878 },
    { lat: 48.872778, lng: 21.441509 },
    { lat: 48.872643, lng: 21.441198 },
    { lat: 48.8724, lng: 21.440761 },
    { lat: 48.87224, lng: 21.440696 },
    { lat: 48.872109, lng: 21.440765 },
    { lat: 48.871882, lng: 21.440712 },
    { lat: 48.87153, lng: 21.440408 },
    { lat: 48.87151, lng: 21.440785 },
    { lat: 48.871474, lng: 21.441656 },
    { lat: 48.871432, lng: 21.442249 },
    { lat: 48.871462, lng: 21.442715 },
    { lat: 48.871464, lng: 21.442726 },
    { lat: 48.871431, lng: 21.442979 },
    { lat: 48.871476, lng: 21.44393 },
    { lat: 48.871492, lng: 21.445539 },
    { lat: 48.871443, lng: 21.44576 },
    { lat: 48.871434, lng: 21.446102 },
    { lat: 48.871369, lng: 21.44677 },
    { lat: 48.871506, lng: 21.447209 },
    { lat: 48.871419, lng: 21.448432 },
    { lat: 48.871438, lng: 21.448489 },
    { lat: 48.871368, lng: 21.449014 },
    { lat: 48.871441, lng: 21.450174 },
    { lat: 48.871507, lng: 21.450929 },
    { lat: 48.871714, lng: 21.451681 },
    { lat: 48.871741, lng: 21.451782 },
    { lat: 48.871905, lng: 21.452246 },
    { lat: 48.872037, lng: 21.452667 },
    { lat: 48.872098, lng: 21.453018 },
    { lat: 48.872207, lng: 21.453059 },
    { lat: 48.872196, lng: 21.453217 },
    { lat: 48.87235, lng: 21.453317 },
    { lat: 48.872295, lng: 21.453887 },
    { lat: 48.87238, lng: 21.454005 },
    { lat: 48.872976, lng: 21.454445 },
    { lat: 48.873023, lng: 21.454479 },
    { lat: 48.87292, lng: 21.45531 },
    { lat: 48.873437, lng: 21.457069 },
    { lat: 48.873823, lng: 21.457245 },
    { lat: 48.873742, lng: 21.457612 },
    { lat: 48.873897, lng: 21.458457 },
    { lat: 48.873898, lng: 21.458463 },
    { lat: 48.874051, lng: 21.458743 },
    { lat: 48.874514, lng: 21.459834 },
    { lat: 48.874541, lng: 21.460036 },
    { lat: 48.874668, lng: 21.460411 },
    { lat: 48.874777, lng: 21.460686 },
    { lat: 48.875001, lng: 21.461399 },
    { lat: 48.875348, lng: 21.462352 },
    { lat: 48.875482, lng: 21.462804 },
    { lat: 48.87559, lng: 21.463027 },
    { lat: 48.875698, lng: 21.46349 },
    { lat: 48.875979, lng: 21.464334 },
    { lat: 48.876102, lng: 21.464696 },
    { lat: 48.87613, lng: 21.46478 },
    { lat: 48.876392, lng: 21.464584 },
    { lat: 48.876616, lng: 21.464171 },
    { lat: 48.876734, lng: 21.464076 },
    { lat: 48.876745, lng: 21.463985 },
    { lat: 48.876964, lng: 21.463809 },
    { lat: 48.877132, lng: 21.463711 },
    { lat: 48.877599, lng: 21.463251 },
    { lat: 48.87769, lng: 21.463162 },
    { lat: 48.87772, lng: 21.463079 },
    { lat: 48.877781, lng: 21.462909 },
    { lat: 48.878002, lng: 21.462703 },
    { lat: 48.87834, lng: 21.462505 },
    { lat: 48.878513, lng: 21.462263 },
    { lat: 48.878653, lng: 21.462179 },
    { lat: 48.878688, lng: 21.462114 },
    { lat: 48.878762, lng: 21.462043 },
    { lat: 48.878768, lng: 21.461901 },
    { lat: 48.878941, lng: 21.461809 },
    { lat: 48.87896, lng: 21.461735 },
    { lat: 48.879377, lng: 21.461337 },
    { lat: 48.879656, lng: 21.460958 },
    { lat: 48.879717, lng: 21.461009 },
    { lat: 48.87965, lng: 21.461517 },
    { lat: 48.879852, lng: 21.461771 },
    { lat: 48.880092, lng: 21.462036 },
    { lat: 48.880329, lng: 21.462088 },
    { lat: 48.880395, lng: 21.462205 },
    { lat: 48.88046, lng: 21.462292 },
    { lat: 48.880479, lng: 21.462477 },
    { lat: 48.880664, lng: 21.462831 },
    { lat: 48.880672, lng: 21.462925 },
    { lat: 48.8805, lng: 21.463298 },
    { lat: 48.880515, lng: 21.463463 },
    { lat: 48.880564, lng: 21.463519 },
    { lat: 48.88059, lng: 21.464084 },
    { lat: 48.880674, lng: 21.464165 },
    { lat: 48.880678, lng: 21.464656 },
    { lat: 48.880828, lng: 21.465234 },
    { lat: 48.880723, lng: 21.465497 },
    { lat: 48.880772, lng: 21.465799 },
    { lat: 48.880631, lng: 21.466051 },
    { lat: 48.880665, lng: 21.466362 },
    { lat: 48.880627, lng: 21.466704 },
    { lat: 48.88069, lng: 21.466941 },
    { lat: 48.880804, lng: 21.467094 },
    { lat: 48.880831, lng: 21.467301 },
    { lat: 48.880786, lng: 21.467458 },
    { lat: 48.88082, lng: 21.467623 },
    { lat: 48.88082, lng: 21.468097 },
    { lat: 48.880656, lng: 21.468474 },
    { lat: 48.880546, lng: 21.468953 },
    { lat: 48.880485, lng: 21.46902 },
    { lat: 48.880435, lng: 21.469152 },
    { lat: 48.880378, lng: 21.469669 },
    { lat: 48.880169, lng: 21.470164 },
    { lat: 48.880305, lng: 21.470407 },
    { lat: 48.88033, lng: 21.470443 },
    { lat: 48.880878, lng: 21.471244 },
    { lat: 48.881125, lng: 21.471468 },
    { lat: 48.881408, lng: 21.471827 },
    { lat: 48.881643, lng: 21.472061 },
    { lat: 48.881838, lng: 21.472531 },
    { lat: 48.882007, lng: 21.472853 },
    { lat: 48.882201, lng: 21.473156 },
    { lat: 48.882309, lng: 21.473502 },
    { lat: 48.882764, lng: 21.474533 },
    { lat: 48.883075, lng: 21.475547 },
    { lat: 48.882817, lng: 21.476117 },
    { lat: 48.882517, lng: 21.477512 },
    { lat: 48.882514, lng: 21.478042 },
    { lat: 48.882811, lng: 21.479949 },
    { lat: 48.882659, lng: 21.481064 },
    { lat: 48.88252, lng: 21.482456 },
    { lat: 48.882269, lng: 21.483941 },
    { lat: 48.883005, lng: 21.484121 },
    { lat: 48.883626, lng: 21.484233 },
    { lat: 48.883876, lng: 21.484452 },
    { lat: 48.883955, lng: 21.484521 },
    { lat: 48.884264, lng: 21.484882 },
    { lat: 48.884412, lng: 21.484995 },
    { lat: 48.884573, lng: 21.485393 },
    { lat: 48.885158, lng: 21.485129 },
    { lat: 48.885814, lng: 21.484836 },
    { lat: 48.887265, lng: 21.484213 },
    { lat: 48.887671, lng: 21.484587 },
    { lat: 48.888369, lng: 21.485047 },
    { lat: 48.889741, lng: 21.485823 },
    { lat: 48.890253, lng: 21.485864 },
    { lat: 48.890558, lng: 21.485362 },
    { lat: 48.890778, lng: 21.485067 },
    { lat: 48.891003, lng: 21.484589 },
    { lat: 48.891213, lng: 21.484294 },
    { lat: 48.891616, lng: 21.483736 },
    { lat: 48.891781, lng: 21.483505 },
    { lat: 48.892088, lng: 21.48312 },
    { lat: 48.892102, lng: 21.483153 },
    { lat: 48.892384, lng: 21.482803 },
    { lat: 48.892753, lng: 21.482431 },
    { lat: 48.892995, lng: 21.482128 },
    { lat: 48.893266, lng: 21.48161 },
    { lat: 48.893588, lng: 21.481225 },
    { lat: 48.893765, lng: 21.481162 },
    { lat: 48.89411, lng: 21.48104 },
    { lat: 48.894513, lng: 21.480988 },
    { lat: 48.894973, lng: 21.481033 },
    { lat: 48.89577, lng: 21.481343 },
    { lat: 48.896804, lng: 21.48174 },
    { lat: 48.898864, lng: 21.481702 },
    { lat: 48.899618, lng: 21.481493 },
    { lat: 48.899875, lng: 21.481135 },
    { lat: 48.899912, lng: 21.481088 },
    { lat: 48.900511, lng: 21.480706 },
    { lat: 48.901549, lng: 21.480398 },
    { lat: 48.902112, lng: 21.480294 },
    { lat: 48.902838, lng: 21.480215 },
    { lat: 48.903485, lng: 21.479779 },
    { lat: 48.904044, lng: 21.478567 },
    { lat: 48.904795, lng: 21.478161 },
    { lat: 48.904946, lng: 21.478092 },
    { lat: 48.905341, lng: 21.478046 },
    { lat: 48.906551, lng: 21.477906 },
    { lat: 48.907623, lng: 21.477654 },
    { lat: 48.908367, lng: 21.477569 },
    { lat: 48.908821, lng: 21.477506 },
    { lat: 48.909017, lng: 21.477485 },
    { lat: 48.909056, lng: 21.477481 },
    { lat: 48.909108, lng: 21.477476 },
    { lat: 48.909146, lng: 21.47747 },
    { lat: 48.909479, lng: 21.477431 },
    { lat: 48.909838, lng: 21.477385 },
    { lat: 48.911433, lng: 21.478342 },
    { lat: 48.912567, lng: 21.47877 },
    { lat: 48.913065, lng: 21.478978 },
    { lat: 48.91377, lng: 21.479379 },
    { lat: 48.914656, lng: 21.480492 },
    { lat: 48.915148, lng: 21.480963 },
    { lat: 48.916259, lng: 21.481111 },
    { lat: 48.916517, lng: 21.481098 },
    { lat: 48.917347, lng: 21.481391 },
    { lat: 48.917741, lng: 21.481629 },
    { lat: 48.919051, lng: 21.482165 },
    { lat: 48.919393, lng: 21.482058 },
    { lat: 48.919936, lng: 21.482039 },
    { lat: 48.92003, lng: 21.48203 },
    { lat: 48.921277, lng: 21.482139 },
    { lat: 48.921726, lng: 21.48147 },
    { lat: 48.921851, lng: 21.481382 },
    { lat: 48.9213767, lng: 21.4768722 },
    { lat: 48.9212522, lng: 21.476268 },
    { lat: 48.9208642, lng: 21.4735014 },
    { lat: 48.9206054, lng: 21.4698429 },
    { lat: 48.9204224, lng: 21.4681156 },
    { lat: 48.9204857, lng: 21.46711 },
    { lat: 48.9204466, lng: 21.466768 },
    { lat: 48.9204635, lng: 21.465568 },
    { lat: 48.921145, lng: 21.4643125 },
    { lat: 48.9236166, lng: 21.4620495 },
    { lat: 48.9234469, lng: 21.4614325 },
    { lat: 48.9231639, lng: 21.4599412 },
    { lat: 48.9229074, lng: 21.4590903 },
    { lat: 48.922048, lng: 21.4572726 },
    { lat: 48.9217332, lng: 21.4559627 },
    { lat: 48.9208446, lng: 21.4540748 },
    { lat: 48.9201937, lng: 21.4521238 },
    { lat: 48.9193184, lng: 21.4500762 },
    { lat: 48.9170166, lng: 21.4444059 },
    { lat: 48.9178706, lng: 21.443057 },
    { lat: 48.9168557, lng: 21.440515 },
    { lat: 48.9161783, lng: 21.4384717 },
    { lat: 48.9155819, lng: 21.4371606 },
    { lat: 48.9148086, lng: 21.4351456 },
    { lat: 48.9143731, lng: 21.4333325 },
    { lat: 48.9129574, lng: 21.4313298 },
    { lat: 48.9126123, lng: 21.4304974 },
    { lat: 48.911306, lng: 21.4271148 },
    { lat: 48.9113275, lng: 21.4263518 },
    { lat: 48.9109373, lng: 21.4264411 },
    { lat: 48.9104399, lng: 21.4265887 },
    { lat: 48.9104169, lng: 21.4265345 },
    { lat: 48.9103276, lng: 21.4265541 },
    { lat: 48.9102996, lng: 21.4264848 },
  ],
};

export default UnitsPrešov