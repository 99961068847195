const UnitsKošiceIV = {
  KošicemestskáčasťŠebastovce: [
    { lat: 48.6605486, lng: 21.2419065 },
    { lat: 48.6571845, lng: 21.2422937 },
    { lat: 48.6571507, lng: 21.2427511 },
    { lat: 48.6529917, lng: 21.2438802 },
    { lat: 48.6528426, lng: 21.2430712 },
    { lat: 48.647393, lng: 21.24555 },
    { lat: 48.647502, lng: 21.246076 },
    { lat: 48.647656, lng: 21.247117 },
    { lat: 48.6478, lng: 21.248562 },
    { lat: 48.6463077, lng: 21.2486453 },
    { lat: 48.6450071, lng: 21.2487645 },
    { lat: 48.644892, lng: 21.25222 },
    { lat: 48.64489, lng: 21.25226 },
    { lat: 48.644877, lng: 21.25238 },
    { lat: 48.644877, lng: 21.252382 },
    { lat: 48.645227, lng: 21.25994 },
    { lat: 48.645228, lng: 21.259948 },
    { lat: 48.64523, lng: 21.260371 },
    { lat: 48.645229, lng: 21.260381 },
    { lat: 48.645258, lng: 21.260684 },
    { lat: 48.645259, lng: 21.260691 },
    { lat: 48.645258, lng: 21.26073 },
    { lat: 48.645249, lng: 21.261037 },
    { lat: 48.645249, lng: 21.261041 },
    { lat: 48.64537, lng: 21.263472 },
    { lat: 48.645372, lng: 21.263498 },
    { lat: 48.645373, lng: 21.263524 },
    { lat: 48.645411, lng: 21.264345 },
    { lat: 48.645569, lng: 21.268768 },
    { lat: 48.645564, lng: 21.268827 },
    { lat: 48.645564, lng: 21.268837 },
    { lat: 48.645553, lng: 21.268968 },
    { lat: 48.645552, lng: 21.268973 },
    { lat: 48.645545, lng: 21.269066 },
    { lat: 48.644753, lng: 21.268934 },
    { lat: 48.644654, lng: 21.268917 },
    { lat: 48.644573, lng: 21.268903 },
    { lat: 48.6474, lng: 21.275543 },
    { lat: 48.648969, lng: 21.279231 },
    { lat: 48.649006, lng: 21.279316 },
    { lat: 48.649251, lng: 21.279889 },
    { lat: 48.650325, lng: 21.282414 },
    { lat: 48.65033, lng: 21.282426 },
    { lat: 48.650334, lng: 21.282435 },
    { lat: 48.650727, lng: 21.283358 },
    { lat: 48.650882, lng: 21.283724 },
    { lat: 48.651196, lng: 21.284462 },
    { lat: 48.651199, lng: 21.284467 },
    { lat: 48.651229, lng: 21.284538 },
    { lat: 48.651254, lng: 21.284599 },
    { lat: 48.651285, lng: 21.284669 },
    { lat: 48.6513392, lng: 21.2846103 },
    { lat: 48.6513915, lng: 21.2845557 },
    { lat: 48.6515352, lng: 21.2842506 },
    { lat: 48.6515873, lng: 21.284221 },
    { lat: 48.6517081, lng: 21.2845671 },
    { lat: 48.6518598, lng: 21.284781 },
    { lat: 48.652161, lng: 21.2849856 },
    { lat: 48.6521896, lng: 21.2850377 },
    { lat: 48.6525697, lng: 21.2857258 },
    { lat: 48.6526876, lng: 21.2859369 },
    { lat: 48.6527101, lng: 21.2859734 },
    { lat: 48.6529586, lng: 21.2863692 },
    { lat: 48.6530927, lng: 21.2866336 },
    { lat: 48.6535842, lng: 21.2868056 },
    { lat: 48.6539363, lng: 21.2877182 },
    { lat: 48.6543557, lng: 21.2879238 },
    { lat: 48.6554332, lng: 21.2887135 },
    { lat: 48.6558613, lng: 21.2890203 },
    { lat: 48.6560407, lng: 21.2891843 },
    { lat: 48.6571373, lng: 21.2901201 },
    { lat: 48.6573232, lng: 21.2902722 },
    { lat: 48.6573592, lng: 21.2904548 },
    { lat: 48.6574986, lng: 21.291147 },
    { lat: 48.6575792, lng: 21.2916497 },
    { lat: 48.6579085, lng: 21.2915207 },
    { lat: 48.6579826, lng: 21.2914806 },
    { lat: 48.6581921, lng: 21.2915229 },
    { lat: 48.6583483, lng: 21.2915174 },
    { lat: 48.6584293, lng: 21.2914786 },
    { lat: 48.6584465, lng: 21.2909741 },
    { lat: 48.6584741, lng: 21.2908483 },
    { lat: 48.6586404, lng: 21.2906202 },
    { lat: 48.6588518, lng: 21.2905015 },
    { lat: 48.6597152, lng: 21.2897382 },
    { lat: 48.6598762, lng: 21.2897067 },
    { lat: 48.6599791, lng: 21.2896482 },
    { lat: 48.6601554, lng: 21.2893397 },
    { lat: 48.660153, lng: 21.2891541 },
    { lat: 48.6601997, lng: 21.2888774 },
    { lat: 48.6602968, lng: 21.2886684 },
    { lat: 48.6604484, lng: 21.2885083 },
    { lat: 48.6605384, lng: 21.2884711 },
    { lat: 48.6607146, lng: 21.2884577 },
    { lat: 48.6609423, lng: 21.2884681 },
    { lat: 48.6612, lng: 21.2882487 },
    { lat: 48.6614133, lng: 21.2880832 },
    { lat: 48.6615287, lng: 21.288032 },
    { lat: 48.6617092, lng: 21.2879354 },
    { lat: 48.6618976, lng: 21.287876 },
    { lat: 48.6620791, lng: 21.2877394 },
    { lat: 48.6622297, lng: 21.2875526 },
    { lat: 48.662277, lng: 21.2873678 },
    { lat: 48.6623121, lng: 21.2871158 },
    { lat: 48.6624175, lng: 21.2869596 },
    { lat: 48.6624944, lng: 21.2868247 },
    { lat: 48.6625465, lng: 21.2866857 },
    { lat: 48.6625986, lng: 21.2865176 },
    { lat: 48.6626617, lng: 21.2864441 },
    { lat: 48.6627329, lng: 21.2864146 },
    { lat: 48.6628975, lng: 21.286501 },
    { lat: 48.6624808, lng: 21.282349 },
    { lat: 48.6624713, lng: 21.2822574 },
    { lat: 48.6624887, lng: 21.2819116 },
    { lat: 48.6622526, lng: 21.2805633 },
    { lat: 48.6621199, lng: 21.2795 },
    { lat: 48.6618932, lng: 21.2772884 },
    { lat: 48.6618907, lng: 21.2772103 },
    { lat: 48.6618866, lng: 21.2771102 },
    { lat: 48.6618837, lng: 21.2770548 },
    { lat: 48.6614236, lng: 21.2739508 },
    { lat: 48.661267, lng: 21.2728504 },
    { lat: 48.6611535, lng: 21.2720524 },
    { lat: 48.6610756, lng: 21.2715314 },
    { lat: 48.6610692, lng: 21.2714695 },
    { lat: 48.6610527, lng: 21.2713383 },
    { lat: 48.6610436, lng: 21.2712595 },
    { lat: 48.6606246, lng: 21.2683617 },
    { lat: 48.6606579, lng: 21.2681287 },
    { lat: 48.6604665, lng: 21.2667032 },
    { lat: 48.6604508, lng: 21.2664128 },
    { lat: 48.6603225, lng: 21.2651356 },
    { lat: 48.660241, lng: 21.2645013 },
    { lat: 48.660034, lng: 21.2633045 },
    { lat: 48.66022, lng: 21.2632387 },
    { lat: 48.6600495, lng: 21.260232 },
    { lat: 48.6600468, lng: 21.258543 },
    { lat: 48.6600729, lng: 21.2566609 },
    { lat: 48.6600033, lng: 21.255575 },
    { lat: 48.6600871, lng: 21.2549704 },
    { lat: 48.6601606, lng: 21.2528734 },
    { lat: 48.6600193, lng: 21.2506742 },
    { lat: 48.6600305, lng: 21.2492572 },
    { lat: 48.6600441, lng: 21.2491132 },
    { lat: 48.6600476, lng: 21.249083 },
    { lat: 48.660027, lng: 21.2490717 },
    { lat: 48.6600132, lng: 21.2490264 },
    { lat: 48.6600991, lng: 21.2472215 },
    { lat: 48.6601845, lng: 21.2460197 },
    { lat: 48.6602604, lng: 21.2450251 },
    { lat: 48.660327, lng: 21.2442304 },
    { lat: 48.66036, lng: 21.2438429 },
    { lat: 48.660389, lng: 21.2435845 },
    { lat: 48.6604761, lng: 21.242761 },
    { lat: 48.6605486, lng: 21.2419065 },
  ],
  KošicemestskáčasťNadJazerom: [
    { lat: 48.6720709, lng: 21.2913258 },
    { lat: 48.6720628, lng: 21.2914638 },
    { lat: 48.6720561, lng: 21.2916865 },
    { lat: 48.6720528, lng: 21.2917615 },
    { lat: 48.6720635, lng: 21.2922578 },
    { lat: 48.6720799, lng: 21.2922809 },
    { lat: 48.6720964, lng: 21.2923043 },
    { lat: 48.6721315, lng: 21.2923485 },
    { lat: 48.6720896, lng: 21.2924856 },
    { lat: 48.6718439, lng: 21.2929532 },
    { lat: 48.6717456, lng: 21.2931238 },
    { lat: 48.6717439, lng: 21.2931774 },
    { lat: 48.6717359, lng: 21.293393 },
    { lat: 48.671707, lng: 21.2941431 },
    { lat: 48.6717006, lng: 21.294631 },
    { lat: 48.6716957, lng: 21.2948378 },
    { lat: 48.6716843, lng: 21.2952 },
    { lat: 48.6716757, lng: 21.295473 },
    { lat: 48.6716171, lng: 21.295471 },
    { lat: 48.6716163, lng: 21.2955191 },
    { lat: 48.6716159, lng: 21.2956146 },
    { lat: 48.6716139, lng: 21.2956945 },
    { lat: 48.6717546, lng: 21.2957011 },
    { lat: 48.6717556, lng: 21.2956212 },
    { lat: 48.6717614, lng: 21.2954407 },
    { lat: 48.6718166, lng: 21.2954443 },
    { lat: 48.6718616, lng: 21.2954415 },
    { lat: 48.6718773, lng: 21.2954404 },
    { lat: 48.6718377, lng: 21.2963931 },
    { lat: 48.6718014, lng: 21.2972637 },
    { lat: 48.6718602, lng: 21.2973098 },
    { lat: 48.6718695, lng: 21.2973209 },
    { lat: 48.6719023, lng: 21.2973632 },
    { lat: 48.6719665, lng: 21.2973273 },
    { lat: 48.6721494, lng: 21.2970885 },
    { lat: 48.6722046, lng: 21.2969814 },
    { lat: 48.6722959, lng: 21.2967999 },
    { lat: 48.6723922, lng: 21.296639 },
    { lat: 48.6724275, lng: 21.2965792 },
    { lat: 48.6726789, lng: 21.2965923 },
    { lat: 48.6728378, lng: 21.2966004 },
    { lat: 48.6729639, lng: 21.296603 },
    { lat: 48.672968, lng: 21.2967758 },
    { lat: 48.6729501, lng: 21.2974001 },
    { lat: 48.6730316, lng: 21.2974538 },
    { lat: 48.6730222, lng: 21.2978565 },
    { lat: 48.6729825, lng: 21.299567 },
    { lat: 48.6728987, lng: 21.2996733 },
    { lat: 48.6727166, lng: 21.3002179 },
    { lat: 48.6730194, lng: 21.3003311 },
    { lat: 48.6731697, lng: 21.3003872 },
    { lat: 48.6731776, lng: 21.3004269 },
    { lat: 48.6732364, lng: 21.3007315 },
    { lat: 48.6733102, lng: 21.301086 },
    { lat: 48.6733661, lng: 21.3012548 },
    { lat: 48.6734102, lng: 21.3014091 },
    { lat: 48.6734215, lng: 21.3014464 },
    { lat: 48.6734778, lng: 21.3016347 },
    { lat: 48.6735029, lng: 21.3017192 },
    { lat: 48.6735443, lng: 21.3018601 },
    { lat: 48.6735494, lng: 21.3019125 },
    { lat: 48.6731506, lng: 21.3023465 },
    { lat: 48.6731202, lng: 21.3023796 },
    { lat: 48.6731072, lng: 21.3023937 },
    { lat: 48.6730984, lng: 21.3024032 },
    { lat: 48.6730722, lng: 21.3024307 },
    { lat: 48.6730494, lng: 21.302455 },
    { lat: 48.6730211, lng: 21.3024859 },
    { lat: 48.6726097, lng: 21.3029174 },
    { lat: 48.672599, lng: 21.3029286 },
    { lat: 48.6725772, lng: 21.3029515 },
    { lat: 48.6722445, lng: 21.3032958 },
    { lat: 48.6722291, lng: 21.3033094 },
    { lat: 48.672156, lng: 21.3033817 },
    { lat: 48.6721101, lng: 21.303428 },
    { lat: 48.6720871, lng: 21.3034492 },
    { lat: 48.6720696, lng: 21.3034654 },
    { lat: 48.672051, lng: 21.303483 },
    { lat: 48.6719923, lng: 21.3035428 },
    { lat: 48.671967, lng: 21.3035686 },
    { lat: 48.6718884, lng: 21.3036489 },
    { lat: 48.6719268, lng: 21.303873 },
    { lat: 48.6719281, lng: 21.3038797 },
    { lat: 48.6719828, lng: 21.303794 },
    { lat: 48.6719295, lng: 21.3039615 },
    { lat: 48.6719539, lng: 21.3040965 },
    { lat: 48.6720004, lng: 21.3043325 },
    { lat: 48.672007, lng: 21.3043606 },
    { lat: 48.6720668, lng: 21.3047374 },
    { lat: 48.6720785, lng: 21.3047886 },
    { lat: 48.6721793, lng: 21.3053655 },
    { lat: 48.672967, lng: 21.30507 },
    { lat: 48.6729871, lng: 21.3050622 },
    { lat: 48.6731351, lng: 21.3050067 },
    { lat: 48.673078, lng: 21.3046852 },
    { lat: 48.6730459, lng: 21.3045028 },
    { lat: 48.6730373, lng: 21.3044541 },
    { lat: 48.6731582, lng: 21.3043973 },
    { lat: 48.6732824, lng: 21.3043374 },
    { lat: 48.6734023, lng: 21.304282 },
    { lat: 48.6735235, lng: 21.3042351 },
    { lat: 48.673647, lng: 21.3041916 },
    { lat: 48.6737706, lng: 21.3041474 },
    { lat: 48.673892, lng: 21.3041059 },
    { lat: 48.6740333, lng: 21.3040533 },
    { lat: 48.6740013, lng: 21.3038914 },
    { lat: 48.6739616, lng: 21.3036933 },
    { lat: 48.6739574, lng: 21.3036694 },
    { lat: 48.6738833, lng: 21.3033039 },
    { lat: 48.6738999, lng: 21.3032935 },
    { lat: 48.6739142, lng: 21.3032836 },
    { lat: 48.6740698, lng: 21.3031837 },
    { lat: 48.6740944, lng: 21.3031414 },
    { lat: 48.6740873, lng: 21.3031302 },
    { lat: 48.6740908, lng: 21.3030972 },
    { lat: 48.6740914, lng: 21.3030797 },
    { lat: 48.6741533, lng: 21.3030433 },
    { lat: 48.6741394, lng: 21.3029952 },
    { lat: 48.6741714, lng: 21.3029741 },
    { lat: 48.6741555, lng: 21.3029321 },
    { lat: 48.67411, lng: 21.3028024 },
    { lat: 48.6739512, lng: 21.3024692 },
    { lat: 48.6739888, lng: 21.3024303 },
    { lat: 48.6740657, lng: 21.3023508 },
    { lat: 48.6743884, lng: 21.3027065 },
    { lat: 48.6744735, lng: 21.3028017 },
    { lat: 48.6745736, lng: 21.3029165 },
    { lat: 48.6747568, lng: 21.3031256 },
    { lat: 48.6748943, lng: 21.3032816 },
    { lat: 48.6750308, lng: 21.303438 },
    { lat: 48.6750845, lng: 21.3034685 },
    { lat: 48.6751463, lng: 21.3035365 },
    { lat: 48.6753429, lng: 21.3037461 },
    { lat: 48.6753604, lng: 21.303841 },
    { lat: 48.6753737, lng: 21.3039081 },
    { lat: 48.6754194, lng: 21.3041538 },
    { lat: 48.6754258, lng: 21.3042146 },
    { lat: 48.675434, lng: 21.3042991 },
    { lat: 48.675437, lng: 21.3043235 },
    { lat: 48.6754731, lng: 21.3045496 },
    { lat: 48.6755093, lng: 21.3047764 },
    { lat: 48.6755421, lng: 21.3049678 },
    { lat: 48.675588, lng: 21.3051761 },
    { lat: 48.6756196, lng: 21.3051789 },
    { lat: 48.6759408, lng: 21.3062298 },
    { lat: 48.6759804, lng: 21.3063139 },
    { lat: 48.6763249, lng: 21.3069064 },
    { lat: 48.6763436, lng: 21.3069303 },
    { lat: 48.6766234, lng: 21.3065455 },
    { lat: 48.6768848, lng: 21.3061848 },
    { lat: 48.6771933, lng: 21.3057521 },
    { lat: 48.6776792, lng: 21.305078 },
    { lat: 48.6779801, lng: 21.3046486 },
    { lat: 48.6783221, lng: 21.3041291 },
    { lat: 48.6785026, lng: 21.3038452 },
    { lat: 48.6786391, lng: 21.3036377 },
    { lat: 48.6786781, lng: 21.3035895 },
    { lat: 48.6787284, lng: 21.3035389 },
    { lat: 48.6790944, lng: 21.3032179 },
    { lat: 48.6795472, lng: 21.3028239 },
    { lat: 48.679873, lng: 21.3025462 },
    { lat: 48.6801403, lng: 21.3023467 },
    { lat: 48.68047, lng: 21.3021236 },
    { lat: 48.6807004, lng: 21.3019648 },
    { lat: 48.6807661, lng: 21.3019057 },
    { lat: 48.6811356, lng: 21.3015117 },
    { lat: 48.6813889, lng: 21.3012584 },
    { lat: 48.6816214, lng: 21.3009484 },
    { lat: 48.6819451, lng: 21.3004945 },
    { lat: 48.6821233, lng: 21.3002471 },
    { lat: 48.6821717, lng: 21.3001732 },
    { lat: 48.6822359, lng: 21.3000669 },
    { lat: 48.6822877, lng: 21.2999571 },
    { lat: 48.6824468, lng: 21.2994916 },
    { lat: 48.682466, lng: 21.2994201 },
    { lat: 48.6825617, lng: 21.2991264 },
    { lat: 48.6827475, lng: 21.2985761 },
    { lat: 48.6829175, lng: 21.2980635 },
    { lat: 48.6832712, lng: 21.2970082 },
    { lat: 48.6833819, lng: 21.2966913 },
    { lat: 48.6835363, lng: 21.296292 },
    { lat: 48.6836836, lng: 21.2959598 },
    { lat: 48.6838107, lng: 21.2957337 },
    { lat: 48.6840099, lng: 21.295405 },
    { lat: 48.6843517, lng: 21.2949142 },
    { lat: 48.684525, lng: 21.2946941 },
    { lat: 48.6847861, lng: 21.2943646 },
    { lat: 48.6849813, lng: 21.2941231 },
    { lat: 48.6850161, lng: 21.2940843 },
    { lat: 48.6851067, lng: 21.2939904 },
    { lat: 48.6851762, lng: 21.2939093 },
    { lat: 48.6853367, lng: 21.2936716 },
    { lat: 48.6853751, lng: 21.2937181 },
    { lat: 48.6854413, lng: 21.2937476 },
    { lat: 48.6854638, lng: 21.2937454 },
    { lat: 48.6857636, lng: 21.2933554 },
    { lat: 48.6862235, lng: 21.2930869 },
    { lat: 48.6865933, lng: 21.2928879 },
    { lat: 48.6866583, lng: 21.2928428 },
    { lat: 48.6867103, lng: 21.2928153 },
    { lat: 48.6867278, lng: 21.2927974 },
    { lat: 48.6871789, lng: 21.2925343 },
    { lat: 48.687272, lng: 21.2924858 },
    { lat: 48.6874208, lng: 21.2923814 },
    { lat: 48.6875366, lng: 21.2924175 },
    { lat: 48.6875473, lng: 21.2924702 },
    { lat: 48.6875847, lng: 21.2926609 },
    { lat: 48.6876509, lng: 21.2929886 },
    { lat: 48.6875915, lng: 21.2934027 },
    { lat: 48.6874359, lng: 21.2935055 },
    { lat: 48.6873573, lng: 21.2935441 },
    { lat: 48.6878441, lng: 21.2941184 },
    { lat: 48.6879675, lng: 21.2940308 },
    { lat: 48.6879861, lng: 21.2940865 },
    { lat: 48.6880293, lng: 21.2942508 },
    { lat: 48.6880615, lng: 21.2943832 },
    { lat: 48.6881993, lng: 21.2941816 },
    { lat: 48.6881071, lng: 21.2940338 },
    { lat: 48.6881086, lng: 21.2937894 },
    { lat: 48.6882008, lng: 21.293565 },
    { lat: 48.6884482, lng: 21.2934117 },
    { lat: 48.6887888, lng: 21.2933608 },
    { lat: 48.6892615, lng: 21.2932192 },
    { lat: 48.6896139, lng: 21.2929634 },
    { lat: 48.6898585, lng: 21.2930144 },
    { lat: 48.689899, lng: 21.2931611 },
    { lat: 48.6897675, lng: 21.293279 },
    { lat: 48.689652, lng: 21.2933276 },
    { lat: 48.6896353, lng: 21.2933698 },
    { lat: 48.6896447, lng: 21.293383 },
    { lat: 48.6897439, lng: 21.2933896 },
    { lat: 48.6898765, lng: 21.293312 },
    { lat: 48.6899635, lng: 21.2932111 },
    { lat: 48.6900056, lng: 21.2931127 },
    { lat: 48.6900725, lng: 21.2929449 },
    { lat: 48.6903244, lng: 21.2929409 },
    { lat: 48.6903873, lng: 21.2929365 },
    { lat: 48.6905576, lng: 21.2929111 },
    { lat: 48.6907449, lng: 21.2928574 },
    { lat: 48.6908434, lng: 21.292837 },
    { lat: 48.6912461, lng: 21.2926471 },
    { lat: 48.6913679, lng: 21.2925799 },
    { lat: 48.6914784, lng: 21.2925155 },
    { lat: 48.6915844, lng: 21.2924518 },
    { lat: 48.6916714, lng: 21.2923891 },
    { lat: 48.6917969, lng: 21.2922966 },
    { lat: 48.692129, lng: 21.2920063 },
    { lat: 48.693, lng: 21.2909741 },
    { lat: 48.6934019, lng: 21.2905367 },
    { lat: 48.6937259, lng: 21.2901689 },
    { lat: 48.6938108, lng: 21.2900736 },
    { lat: 48.6939412, lng: 21.2899255 },
    { lat: 48.6939913, lng: 21.2898679 },
    { lat: 48.6940283, lng: 21.2898265 },
    { lat: 48.6945336, lng: 21.2892743 },
    { lat: 48.695389, lng: 21.2883275 },
    { lat: 48.6956514, lng: 21.2880469 },
    { lat: 48.6966248, lng: 21.2870064 },
    { lat: 48.6971464, lng: 21.2864373 },
    { lat: 48.6976358, lng: 21.2858855 },
    { lat: 48.6977889, lng: 21.2856999 },
    { lat: 48.698018, lng: 21.2854676 },
    { lat: 48.6982912, lng: 21.285052 },
    { lat: 48.6986092, lng: 21.2844857 },
    { lat: 48.6987691, lng: 21.2841229 },
    { lat: 48.6989873, lng: 21.2836043 },
    { lat: 48.6989411, lng: 21.2835669 },
    { lat: 48.6990597, lng: 21.2831047 },
    { lat: 48.6991374, lng: 21.2827017 },
    { lat: 48.6992474, lng: 21.282104 },
    { lat: 48.699258, lng: 21.2820491 },
    { lat: 48.6992893, lng: 21.2818959 },
    { lat: 48.699305, lng: 21.2818203 },
    { lat: 48.6994576, lng: 21.2812172 },
    { lat: 48.6996286, lng: 21.2807774 },
    { lat: 48.699637, lng: 21.2807579 },
    { lat: 48.6996057, lng: 21.2807629 },
    { lat: 48.6995721, lng: 21.2807688 },
    { lat: 48.6995071, lng: 21.2807784 },
    { lat: 48.6994925, lng: 21.2807801 },
    { lat: 48.6991062, lng: 21.2808439 },
    { lat: 48.6990951, lng: 21.2808464 },
    { lat: 48.6989887, lng: 21.2808639 },
    { lat: 48.6989786, lng: 21.280866 },
    { lat: 48.6989995, lng: 21.2808147 },
    { lat: 48.6990469, lng: 21.2807045 },
    { lat: 48.6990594, lng: 21.2806744 },
    { lat: 48.6990364, lng: 21.2806226 },
    { lat: 48.6987753, lng: 21.2806587 },
    { lat: 48.698651, lng: 21.2806759 },
    { lat: 48.6985534, lng: 21.2806899 },
    { lat: 48.6985187, lng: 21.2806948 },
    { lat: 48.6984952, lng: 21.2806982 },
    { lat: 48.698455, lng: 21.2806384 },
    { lat: 48.6983927, lng: 21.280657 },
    { lat: 48.6982879, lng: 21.2806886 },
    { lat: 48.6982823, lng: 21.2806904 },
    { lat: 48.6982489, lng: 21.2807009 },
    { lat: 48.698211, lng: 21.2807509 },
    { lat: 48.6976245, lng: 21.2807087 },
    { lat: 48.6972768, lng: 21.2807092 },
    { lat: 48.6968874, lng: 21.2807104 },
    { lat: 48.6961172, lng: 21.2806615 },
    { lat: 48.6958907, lng: 21.2806485 },
    { lat: 48.6955444, lng: 21.2806236 },
    { lat: 48.6953348, lng: 21.2806126 },
    { lat: 48.6952152, lng: 21.2806039 },
    { lat: 48.6949637, lng: 21.2805845 },
    { lat: 48.6948553, lng: 21.2805764 },
    { lat: 48.6945977, lng: 21.280541 },
    { lat: 48.6943536, lng: 21.2805068 },
    { lat: 48.6941434, lng: 21.2804802 },
    { lat: 48.6939954, lng: 21.28046 },
    { lat: 48.6939558, lng: 21.2804549 },
    { lat: 48.69374, lng: 21.2804252 },
    { lat: 48.6935071, lng: 21.2803923 },
    { lat: 48.6934904, lng: 21.2803632 },
    { lat: 48.6934712, lng: 21.2803214 },
    { lat: 48.6934598, lng: 21.2802777 },
    { lat: 48.6933163, lng: 21.2802611 },
    { lat: 48.6932745, lng: 21.2802576 },
    { lat: 48.6929927, lng: 21.2802417 },
    { lat: 48.6927288, lng: 21.2802232 },
    { lat: 48.6926002, lng: 21.2802122 },
    { lat: 48.6925267, lng: 21.2801985 },
    { lat: 48.6924653, lng: 21.2801818 },
    { lat: 48.6923604, lng: 21.2801385 },
    { lat: 48.6922118, lng: 21.2800642 },
    { lat: 48.6920902, lng: 21.2799858 },
    { lat: 48.6919726, lng: 21.2798961 },
    { lat: 48.6919032, lng: 21.2798358 },
    { lat: 48.6918218, lng: 21.2797496 },
    { lat: 48.6917657, lng: 21.2796808 },
    { lat: 48.6916881, lng: 21.2795737 },
    { lat: 48.6916621, lng: 21.2795321 },
    { lat: 48.6916148, lng: 21.2794552 },
    { lat: 48.6915348, lng: 21.2793095 },
    { lat: 48.6914616, lng: 21.2791589 },
    { lat: 48.6914067, lng: 21.2790182 },
    { lat: 48.6913805, lng: 21.2789391 },
    { lat: 48.6913433, lng: 21.2788231 },
    { lat: 48.6913026, lng: 21.2786732 },
    { lat: 48.6912762, lng: 21.2785482 },
    { lat: 48.6912157, lng: 21.2781892 },
    { lat: 48.691209, lng: 21.2781925 },
    { lat: 48.6911725, lng: 21.2782118 },
    { lat: 48.6910935, lng: 21.2782041 },
    { lat: 48.6910926, lng: 21.2785786 },
    { lat: 48.691091, lng: 21.2785997 },
    { lat: 48.6910138, lng: 21.2782126 },
    { lat: 48.6910012, lng: 21.2781657 },
    { lat: 48.6908328, lng: 21.2775207 },
    { lat: 48.6907031, lng: 21.2767821 },
    { lat: 48.6905621, lng: 21.275965 },
    { lat: 48.6904965, lng: 21.2756949 },
    { lat: 48.6904153, lng: 21.275364 },
    { lat: 48.6902727, lng: 21.2748975 },
    { lat: 48.6901493, lng: 21.2743252 },
    { lat: 48.6898991, lng: 21.2731413 },
    { lat: 48.6898917, lng: 21.2730848 },
    { lat: 48.6899565, lng: 21.2730657 },
    { lat: 48.6900094, lng: 21.2729601 },
    { lat: 48.689454, lng: 21.2732684 },
    { lat: 48.6893952, lng: 21.273296 },
    { lat: 48.6893762, lng: 21.2733045 },
    { lat: 48.6892753, lng: 21.2733514 },
    { lat: 48.6892209, lng: 21.273377 },
    { lat: 48.6885817, lng: 21.2736317 },
    { lat: 48.688192, lng: 21.2737302 },
    { lat: 48.6878328, lng: 21.2738694 },
    { lat: 48.6871979, lng: 21.2740857 },
    { lat: 48.6869566, lng: 21.2741389 },
    { lat: 48.686804, lng: 21.2741546 },
    { lat: 48.686629, lng: 21.274173 },
    { lat: 48.6865246, lng: 21.2741835 },
    { lat: 48.6863553, lng: 21.2742025 },
    { lat: 48.6862172, lng: 21.2742136 },
    { lat: 48.6858817, lng: 21.2744259 },
    { lat: 48.685617, lng: 21.2746027 },
    { lat: 48.6854481, lng: 21.2747107 },
    { lat: 48.6852362, lng: 21.2748819 },
    { lat: 48.6850445, lng: 21.2749776 },
    { lat: 48.6850145, lng: 21.2749929 },
    { lat: 48.6849957, lng: 21.2750024 },
    { lat: 48.6849513, lng: 21.275024 },
    { lat: 48.6849425, lng: 21.2750289 },
    { lat: 48.6845146, lng: 21.2752427 },
    { lat: 48.684308, lng: 21.2752903 },
    { lat: 48.6841698, lng: 21.2753378 },
    { lat: 48.683995, lng: 21.2753971 },
    { lat: 48.6834286, lng: 21.2757513 },
    { lat: 48.6833446, lng: 21.2758035 },
    { lat: 48.6829704, lng: 21.2759651 },
    { lat: 48.6827452, lng: 21.2761475 },
    { lat: 48.6824344, lng: 21.2763965 },
    { lat: 48.6823003, lng: 21.2764996 },
    { lat: 48.6822972, lng: 21.2763634 },
    { lat: 48.682296, lng: 21.2762852 },
    { lat: 48.6822887, lng: 21.2760154 },
    { lat: 48.6821199, lng: 21.2761615 },
    { lat: 48.6820235, lng: 21.2762451 },
    { lat: 48.6819215, lng: 21.2763328 },
    { lat: 48.681915, lng: 21.2763379 },
    { lat: 48.6817857, lng: 21.276452 },
    { lat: 48.6816191, lng: 21.2765976 },
    { lat: 48.6813128, lng: 21.2768862 },
    { lat: 48.680974, lng: 21.2772101 },
    { lat: 48.6809324, lng: 21.2772497 },
    { lat: 48.6806349, lng: 21.2776046 },
    { lat: 48.6803787, lng: 21.2779104 },
    { lat: 48.6800975, lng: 21.2782456 },
    { lat: 48.6797991, lng: 21.2786034 },
    { lat: 48.6794929, lng: 21.2789689 },
    { lat: 48.6791597, lng: 21.2793675 },
    { lat: 48.6788448, lng: 21.2797422 },
    { lat: 48.6788058, lng: 21.2797869 },
    { lat: 48.678758, lng: 21.2798426 },
    { lat: 48.6786865, lng: 21.2799363 },
    { lat: 48.6786983, lng: 21.2799882 },
    { lat: 48.6783364, lng: 21.2804836 },
    { lat: 48.6780707, lng: 21.2808473 },
    { lat: 48.6772795, lng: 21.2820881 },
    { lat: 48.6772999, lng: 21.2821266 },
    { lat: 48.6768599, lng: 21.2829002 },
    { lat: 48.6765572, lng: 21.2834488 },
    { lat: 48.6764218, lng: 21.2836943 },
    { lat: 48.6759378, lng: 21.2845704 },
    { lat: 48.6757074, lng: 21.2849881 },
    { lat: 48.6751769, lng: 21.2860743 },
    { lat: 48.6751014, lng: 21.2862145 },
    { lat: 48.6748733, lng: 21.2866332 },
    { lat: 48.6747317, lng: 21.2868938 },
    { lat: 48.6746457, lng: 21.2868823 },
    { lat: 48.6742936, lng: 21.2876544 },
    { lat: 48.6743405, lng: 21.2877533 },
    { lat: 48.6735895, lng: 21.289136 },
    { lat: 48.673339, lng: 21.2895548 },
    { lat: 48.6732913, lng: 21.2895016 },
    { lat: 48.6732674, lng: 21.2894609 },
    { lat: 48.6732354, lng: 21.2894047 },
    { lat: 48.6731901, lng: 21.2893245 },
    { lat: 48.6730615, lng: 21.289643 },
    { lat: 48.6727992, lng: 21.2901541 },
    { lat: 48.6726493, lng: 21.290443 },
    { lat: 48.6724798, lng: 21.2907852 },
    { lat: 48.6722576, lng: 21.2912117 },
    { lat: 48.6720796, lng: 21.2913117 },
    { lat: 48.6720709, lng: 21.2913258 },
  ],
  KošicemestskáčasťJuh: [
    { lat: 48.7186872, lng: 21.2696987 },
    { lat: 48.718683, lng: 21.269663 },
    { lat: 48.718617, lng: 21.269273 },
    { lat: 48.718629, lng: 21.269266 },
    { lat: 48.718628, lng: 21.269258 },
    { lat: 48.718611, lng: 21.26915 },
    { lat: 48.718587, lng: 21.268996 },
    { lat: 48.718555, lng: 21.268805 },
    { lat: 48.718543, lng: 21.268823 },
    { lat: 48.718488, lng: 21.268665 },
    { lat: 48.718475, lng: 21.268629 },
    { lat: 48.718439, lng: 21.268406 },
    { lat: 48.7184, lng: 21.268167 },
    { lat: 48.718343, lng: 21.267838 },
    { lat: 48.718303, lng: 21.267606 },
    { lat: 48.718284, lng: 21.26749 },
    { lat: 48.718287, lng: 21.267488 },
    { lat: 48.718279, lng: 21.267439 },
    { lat: 48.718276, lng: 21.267441 },
    { lat: 48.718258, lng: 21.267329 },
    { lat: 48.71824, lng: 21.26722 },
    { lat: 48.718242, lng: 21.267218 },
    { lat: 48.718235, lng: 21.267173 },
    { lat: 48.718231, lng: 21.267174 },
    { lat: 48.718215, lng: 21.267067 },
    { lat: 48.718194, lng: 21.266955 },
    { lat: 48.718198, lng: 21.266953 },
    { lat: 48.718191, lng: 21.26691 },
    { lat: 48.718188, lng: 21.266911 },
    { lat: 48.71817, lng: 21.266801 },
    { lat: 48.718209, lng: 21.266784 },
    { lat: 48.718169, lng: 21.266537 },
    { lat: 48.718147, lng: 21.266404 },
    { lat: 48.718129, lng: 21.266296 },
    { lat: 48.718104, lng: 21.266148 },
    { lat: 48.7181, lng: 21.266134 },
    { lat: 48.718093, lng: 21.266122 },
    { lat: 48.718086, lng: 21.266114 },
    { lat: 48.718089, lng: 21.26607 },
    { lat: 48.718011, lng: 21.265596 },
    { lat: 48.717989, lng: 21.265465 },
    { lat: 48.717976, lng: 21.265395 },
    { lat: 48.717924, lng: 21.26509 },
    { lat: 48.717921, lng: 21.265071 },
    { lat: 48.717872, lng: 21.264787 },
    { lat: 48.71785, lng: 21.264688 },
    { lat: 48.717731, lng: 21.264148 },
    { lat: 48.717711, lng: 21.26406 },
    { lat: 48.717651, lng: 21.263867 },
    { lat: 48.717569, lng: 21.2636467 },
    { lat: 48.7175554, lng: 21.2636099 },
    { lat: 48.7175423, lng: 21.2635755 },
    { lat: 48.717417, lng: 21.263291 },
    { lat: 48.717236, lng: 21.262936 },
    { lat: 48.71719, lng: 21.262999 },
    { lat: 48.717118, lng: 21.262971 },
    { lat: 48.717243, lng: 21.262873 },
    { lat: 48.717209, lng: 21.262725 },
    { lat: 48.717159, lng: 21.262645 },
    { lat: 48.717122, lng: 21.262585 },
    { lat: 48.717137, lng: 21.262558 },
    { lat: 48.717143, lng: 21.262565 },
    { lat: 48.717156, lng: 21.262561 },
    { lat: 48.717165, lng: 21.262554 },
    { lat: 48.717169, lng: 21.26255 },
    { lat: 48.717173, lng: 21.262539 },
    { lat: 48.717179, lng: 21.262523 },
    { lat: 48.717174, lng: 21.262488 },
    { lat: 48.716986, lng: 21.262035 },
    { lat: 48.716976, lng: 21.262011 },
    { lat: 48.716973, lng: 21.262001 },
    { lat: 48.716738, lng: 21.261437 },
    { lat: 48.716724, lng: 21.261404 },
    { lat: 48.716673, lng: 21.26128 },
    { lat: 48.716634, lng: 21.261188 },
    { lat: 48.716606, lng: 21.261111 },
    { lat: 48.716581, lng: 21.261069 },
    { lat: 48.716557, lng: 21.261038 },
    { lat: 48.716527, lng: 21.261019 },
    { lat: 48.716526, lng: 21.261019 },
    { lat: 48.716525, lng: 21.261018 },
    { lat: 48.716524, lng: 21.261018 },
    { lat: 48.716508, lng: 21.261012 },
    { lat: 48.716494, lng: 21.261007 },
    { lat: 48.716474, lng: 21.261008 },
    { lat: 48.716459, lng: 21.26097 },
    { lat: 48.716329, lng: 21.260631 },
    { lat: 48.716326, lng: 21.260568 },
    { lat: 48.716348, lng: 21.260523 },
    { lat: 48.71631, lng: 21.260028 },
    { lat: 48.716295, lng: 21.259831 },
    { lat: 48.716281, lng: 21.259647 },
    { lat: 48.716265, lng: 21.259434 },
    { lat: 48.716263, lng: 21.259403 },
    { lat: 48.716253, lng: 21.259254 },
    { lat: 48.71625, lng: 21.259221 },
    { lat: 48.716248, lng: 21.259204 },
    { lat: 48.716233, lng: 21.259058 },
    { lat: 48.716232, lng: 21.259044 },
    { lat: 48.716227, lng: 21.258992 },
    { lat: 48.71622, lng: 21.258913 },
    { lat: 48.716215, lng: 21.258913 },
    { lat: 48.716194, lng: 21.258676 },
    { lat: 48.716196, lng: 21.258676 },
    { lat: 48.716197, lng: 21.258675 },
    { lat: 48.716192, lng: 21.258615 },
    { lat: 48.716189, lng: 21.258615 },
    { lat: 48.716189, lng: 21.258611 },
    { lat: 48.716189, lng: 21.25861 },
    { lat: 48.716188, lng: 21.258603 },
    { lat: 48.716188, lng: 21.258602 },
    { lat: 48.71619, lng: 21.258601 },
    { lat: 48.716183, lng: 21.258518 },
    { lat: 48.716183, lng: 21.258517 },
    { lat: 48.716181, lng: 21.258518 },
    { lat: 48.716163, lng: 21.258358 },
    { lat: 48.716167, lng: 21.258357 },
    { lat: 48.716159, lng: 21.258278 },
    { lat: 48.716156, lng: 21.258278 },
    { lat: 48.716156, lng: 21.258273 },
    { lat: 48.716148, lng: 21.258196 },
    { lat: 48.71614, lng: 21.25812 },
    { lat: 48.716137, lng: 21.258083 },
    { lat: 48.716135, lng: 21.258065 },
    { lat: 48.716123, lng: 21.257944 },
    { lat: 48.716108, lng: 21.257789 },
    { lat: 48.7161, lng: 21.257709 },
    { lat: 48.716076, lng: 21.25749 },
    { lat: 48.716051, lng: 21.25708 },
    { lat: 48.716036, lng: 21.256843 },
    { lat: 48.716021, lng: 21.256611 },
    { lat: 48.716006, lng: 21.256383 },
    { lat: 48.716004, lng: 21.256352 },
    { lat: 48.715997, lng: 21.256207 },
    { lat: 48.716014, lng: 21.256106 },
    { lat: 48.716017, lng: 21.256089 },
    { lat: 48.716024, lng: 21.256051 },
    { lat: 48.716055, lng: 21.255665 },
    { lat: 48.716091, lng: 21.255227 },
    { lat: 48.716093, lng: 21.255207 },
    { lat: 48.716098, lng: 21.255143 },
    { lat: 48.716116, lng: 21.254922 },
    { lat: 48.71612, lng: 21.254923 },
    { lat: 48.716127, lng: 21.254838 },
    { lat: 48.716122, lng: 21.254837 },
    { lat: 48.716153, lng: 21.25446 },
    { lat: 48.716161, lng: 21.254371 },
    { lat: 48.716164, lng: 21.254336 },
    { lat: 48.716169, lng: 21.254277 },
    { lat: 48.716178, lng: 21.254167 },
    { lat: 48.716184, lng: 21.254101 },
    { lat: 48.716198, lng: 21.253928 },
    { lat: 48.716205, lng: 21.253844 },
    { lat: 48.716209, lng: 21.253793 },
    { lat: 48.716214, lng: 21.253728 },
    { lat: 48.716222, lng: 21.253632 },
    { lat: 48.716234, lng: 21.25348 },
    { lat: 48.71624, lng: 21.253406 },
    { lat: 48.716258, lng: 21.253192 },
    { lat: 48.716262, lng: 21.253138 },
    { lat: 48.716265, lng: 21.253093 },
    { lat: 48.716272, lng: 21.253015 },
    { lat: 48.716277, lng: 21.252962 },
    { lat: 48.716312, lng: 21.252565 },
    { lat: 48.716351, lng: 21.252061 },
    { lat: 48.716369, lng: 21.251832 },
    { lat: 48.716377, lng: 21.251735 },
    { lat: 48.716396, lng: 21.251505 },
    { lat: 48.7164043, lng: 21.2514103 },
    { lat: 48.7164315, lng: 21.2510238 },
    { lat: 48.716464, lng: 21.250681 },
    { lat: 48.716453, lng: 21.25068 },
    { lat: 48.716413, lng: 21.25042 },
    { lat: 48.716405, lng: 21.250368 },
    { lat: 48.716403, lng: 21.250348 },
    { lat: 48.716388, lng: 21.250212 },
    { lat: 48.71638, lng: 21.25017 },
    { lat: 48.716373, lng: 21.250174 },
    { lat: 48.716341, lng: 21.250191 },
    { lat: 48.716363, lng: 21.250168 },
    { lat: 48.716372, lng: 21.25015 },
    { lat: 48.71638, lng: 21.250118 },
    { lat: 48.71638, lng: 21.250075 },
    { lat: 48.716374, lng: 21.250029 },
    { lat: 48.716123, lng: 21.248949 },
    { lat: 48.71612, lng: 21.248909 },
    { lat: 48.716129, lng: 21.248908 },
    { lat: 48.716113, lng: 21.248843 },
    { lat: 48.715991, lng: 21.24832 },
    { lat: 48.71599, lng: 21.248313 },
    { lat: 48.715974, lng: 21.248253 },
    { lat: 48.715957, lng: 21.248204 },
    { lat: 48.715934, lng: 21.248137 },
    { lat: 48.715923, lng: 21.248111 },
    { lat: 48.7159084, lng: 21.2480725 },
    { lat: 48.7158391, lng: 21.247964 },
    { lat: 48.715796, lng: 21.247907 },
    { lat: 48.715778, lng: 21.247886 },
    { lat: 48.715754, lng: 21.247861 },
    { lat: 48.715732, lng: 21.247843 },
    { lat: 48.715717, lng: 21.247832 },
    { lat: 48.715708, lng: 21.247824 },
    { lat: 48.715693, lng: 21.247812 },
    { lat: 48.715671, lng: 21.247798 },
    { lat: 48.715666, lng: 21.247837 },
    { lat: 48.715665, lng: 21.247842 },
    { lat: 48.715665, lng: 21.247841 },
    { lat: 48.715501, lng: 21.247784 },
    { lat: 48.715502, lng: 21.247774 },
    { lat: 48.715482, lng: 21.247767 },
    { lat: 48.71548, lng: 21.247774 },
    { lat: 48.715305, lng: 21.247713 },
    { lat: 48.714863, lng: 21.247556 },
    { lat: 48.714649, lng: 21.247421 },
    { lat: 48.714427, lng: 21.247242 },
    { lat: 48.714401, lng: 21.24722 },
    { lat: 48.714314, lng: 21.247135 },
    { lat: 48.714286, lng: 21.247113 },
    { lat: 48.714283, lng: 21.24711 },
    { lat: 48.714273, lng: 21.247102 },
    { lat: 48.71427, lng: 21.247099 },
    { lat: 48.71382, lng: 21.246723 },
    { lat: 48.713409, lng: 21.246485 },
    { lat: 48.713281, lng: 21.246425 },
    { lat: 48.712699, lng: 21.246445 },
    { lat: 48.712631, lng: 21.24644 },
    { lat: 48.712417, lng: 21.246406 },
    { lat: 48.712281, lng: 21.246391 },
    { lat: 48.712112, lng: 21.246389 },
    { lat: 48.711558, lng: 21.246409 },
    { lat: 48.711301, lng: 21.246512 },
    { lat: 48.711167, lng: 21.246627 },
    { lat: 48.711063, lng: 21.246737 },
    { lat: 48.710594, lng: 21.247048 },
    { lat: 48.710551, lng: 21.247068 },
    { lat: 48.710533, lng: 21.247076 },
    { lat: 48.710537, lng: 21.246994 },
    { lat: 48.710464, lng: 21.247025 },
    { lat: 48.710389, lng: 21.247056 },
    { lat: 48.710364, lng: 21.247043 },
    { lat: 48.71029, lng: 21.247021 },
    { lat: 48.710115, lng: 21.247004 },
    { lat: 48.710033, lng: 21.246986 },
    { lat: 48.709954, lng: 21.246959 },
    { lat: 48.709874, lng: 21.246914 },
    { lat: 48.709752, lng: 21.246837 },
    { lat: 48.709602, lng: 21.246754 },
    { lat: 48.709524, lng: 21.246708 },
    { lat: 48.709288, lng: 21.246564 },
    { lat: 48.708974, lng: 21.246369 },
    { lat: 48.70892, lng: 21.246335 },
    { lat: 48.708833, lng: 21.246281 },
    { lat: 48.708234, lng: 21.245887 },
    { lat: 48.708135, lng: 21.245819 },
    { lat: 48.708064, lng: 21.24577 },
    { lat: 48.7078384, lng: 21.2456211 },
    { lat: 48.7076295, lng: 21.2454833 },
    { lat: 48.70762, lng: 21.245477 },
    { lat: 48.707489, lng: 21.245392 },
    { lat: 48.7074217, lng: 21.2453487 },
    { lat: 48.7070469, lng: 21.2451078 },
    { lat: 48.707041, lng: 21.245104 },
    { lat: 48.706976, lng: 21.245069 },
    { lat: 48.7069687, lng: 21.2450638 },
    { lat: 48.706889, lng: 21.245007 },
    { lat: 48.706829, lng: 21.244966 },
    { lat: 48.706797, lng: 21.244948 },
    { lat: 48.70647, lng: 21.244709 },
    { lat: 48.706161, lng: 21.244458 },
    { lat: 48.705896, lng: 21.24424 },
    { lat: 48.705265, lng: 21.243693 },
    { lat: 48.705235, lng: 21.243667 },
    { lat: 48.705249, lng: 21.243633 },
    { lat: 48.705183, lng: 21.243574 },
    { lat: 48.704964, lng: 21.243378 },
    { lat: 48.704902, lng: 21.243319 },
    { lat: 48.704704, lng: 21.243136 },
    { lat: 48.704626, lng: 21.243066 },
    { lat: 48.704598, lng: 21.243041 },
    { lat: 48.704478, lng: 21.242935 },
    { lat: 48.70432, lng: 21.242779 },
    { lat: 48.704306, lng: 21.242813 },
    { lat: 48.704298, lng: 21.242833 },
    { lat: 48.704225, lng: 21.242768 },
    { lat: 48.70401, lng: 21.242572 },
    { lat: 48.703984, lng: 21.242534 },
    { lat: 48.703947, lng: 21.242493 },
    { lat: 48.703807, lng: 21.242368 },
    { lat: 48.703693, lng: 21.242266 },
    { lat: 48.703359, lng: 21.241985 },
    { lat: 48.703009, lng: 21.241661 },
    { lat: 48.702186, lng: 21.240911 },
    { lat: 48.70211, lng: 21.240839 },
    { lat: 48.70208, lng: 21.240813 },
    { lat: 48.702028, lng: 21.240764 },
    { lat: 48.701953, lng: 21.240695 },
    { lat: 48.701363, lng: 21.240155 },
    { lat: 48.701269, lng: 21.240068 },
    { lat: 48.700666, lng: 21.239516 },
    { lat: 48.700283, lng: 21.239183 },
    { lat: 48.700075, lng: 21.239005 },
    { lat: 48.700031, lng: 21.238967 },
    { lat: 48.699996, lng: 21.238937 },
    { lat: 48.699902, lng: 21.238856 },
    { lat: 48.69978, lng: 21.238754 },
    { lat: 48.699726, lng: 21.238708 },
    { lat: 48.698768, lng: 21.237888 },
    { lat: 48.698722, lng: 21.237849 },
    { lat: 48.698635, lng: 21.237769 },
    { lat: 48.698107, lng: 21.237294 },
    { lat: 48.698064, lng: 21.237257 },
    { lat: 48.698032, lng: 21.237229 },
    { lat: 48.698013, lng: 21.237211 },
    { lat: 48.697994, lng: 21.237194 },
    { lat: 48.6979, lng: 21.237117 },
    { lat: 48.697672, lng: 21.236933 },
    { lat: 48.697422, lng: 21.236696 },
    { lat: 48.697021, lng: 21.236317 },
    { lat: 48.696868, lng: 21.23616 },
    { lat: 48.696726, lng: 21.236034 },
    { lat: 48.696655, lng: 21.235969 },
    { lat: 48.696577, lng: 21.235885 },
    { lat: 48.696296, lng: 21.23559 },
    { lat: 48.6918665, lng: 21.2310757 },
    { lat: 48.6917673, lng: 21.2312603 },
    { lat: 48.6917323, lng: 21.2313308 },
    { lat: 48.6917292, lng: 21.2313364 },
    { lat: 48.6916828, lng: 21.2314038 },
    { lat: 48.6916796, lng: 21.2314083 },
    { lat: 48.6916701, lng: 21.2315001 },
    { lat: 48.6915896, lng: 21.2316633 },
    { lat: 48.6914636, lng: 21.2319526 },
    { lat: 48.6914412, lng: 21.2322815 },
    { lat: 48.6913786, lng: 21.2324028 },
    { lat: 48.6912737, lng: 21.2325766 },
    { lat: 48.6910571, lng: 21.2330647 },
    { lat: 48.6909782, lng: 21.2332819 },
    { lat: 48.6907535, lng: 21.2336533 },
    { lat: 48.6904807, lng: 21.234217 },
    { lat: 48.6903442, lng: 21.2345704 },
    { lat: 48.6901484, lng: 21.2348596 },
    { lat: 48.6900471, lng: 21.2350755 },
    { lat: 48.6900147, lng: 21.2353379 },
    { lat: 48.6900348, lng: 21.235548 },
    { lat: 48.6900344, lng: 21.2357903 },
    { lat: 48.6899646, lng: 21.2360773 },
    { lat: 48.6899386, lng: 21.236149 },
    { lat: 48.6898593, lng: 21.2362736 },
    { lat: 48.6897468, lng: 21.2363856 },
    { lat: 48.6896517, lng: 21.2365832 },
    { lat: 48.68958, lng: 21.236812 },
    { lat: 48.6895405, lng: 21.2371724 },
    { lat: 48.6894183, lng: 21.2375465 },
    { lat: 48.68925, lng: 21.2379243 },
    { lat: 48.689259, lng: 21.2381798 },
    { lat: 48.6890972, lng: 21.2387996 },
    { lat: 48.6890089, lng: 21.2392219 },
    { lat: 48.6890813, lng: 21.2398479 },
    { lat: 48.6889777, lng: 21.2401366 },
    { lat: 48.6886045, lng: 21.2405136 },
    { lat: 48.6884314, lng: 21.2410288 },
    { lat: 48.6884283, lng: 21.2418703 },
    { lat: 48.6883266, lng: 21.2421097 },
    { lat: 48.6882856, lng: 21.2422024 },
    { lat: 48.6882518, lng: 21.2422732 },
    { lat: 48.6879583, lng: 21.242788 },
    { lat: 48.6877603, lng: 21.2430474 },
    { lat: 48.6875298, lng: 21.2435595 },
    { lat: 48.6874607, lng: 21.2439427 },
    { lat: 48.6872519, lng: 21.2444704 },
    { lat: 48.6871935, lng: 21.2445087 },
    { lat: 48.6868816, lng: 21.24494 },
    { lat: 48.6867212, lng: 21.2455708 },
    { lat: 48.6857729, lng: 21.2464232 },
    { lat: 48.6856948, lng: 21.2475335 },
    { lat: 48.6859177, lng: 21.2481168 },
    { lat: 48.6859534, lng: 21.2483252 },
    { lat: 48.6859961, lng: 21.2486096 },
    { lat: 48.685766, lng: 21.2489962 },
    { lat: 48.6857685, lng: 21.2492942 },
    { lat: 48.6858251, lng: 21.2493774 },
    { lat: 48.685996, lng: 21.2495164 },
    { lat: 48.6860623, lng: 21.249729 },
    { lat: 48.686053, lng: 21.2500851 },
    { lat: 48.6859699, lng: 21.2508175 },
    { lat: 48.6859355, lng: 21.251414 },
    { lat: 48.6859358, lng: 21.2516078 },
    { lat: 48.685918, lng: 21.2516852 },
    { lat: 48.6858126, lng: 21.251952 },
    { lat: 48.6857061, lng: 21.2519641 },
    { lat: 48.6857021, lng: 21.2523815 },
    { lat: 48.685686, lng: 21.2524781 },
    { lat: 48.6856554, lng: 21.2525817 },
    { lat: 48.6855261, lng: 21.2527686 },
    { lat: 48.6853412, lng: 21.2530495 },
    { lat: 48.6853529, lng: 21.2532766 },
    { lat: 48.6853471, lng: 21.2537849 },
    { lat: 48.6852934, lng: 21.2539774 },
    { lat: 48.6851646, lng: 21.2543594 },
    { lat: 48.685084, lng: 21.2548452 },
    { lat: 48.6850835, lng: 21.255013 },
    { lat: 48.6850755, lng: 21.2551895 },
    { lat: 48.6860138, lng: 21.2553235 },
    { lat: 48.6859749, lng: 21.2556347 },
    { lat: 48.6860429, lng: 21.2556484 },
    { lat: 48.6863285, lng: 21.2557126 },
    { lat: 48.6864359, lng: 21.2560944 },
    { lat: 48.6865069, lng: 21.2560929 },
    { lat: 48.6866297, lng: 21.2560661 },
    { lat: 48.6866912, lng: 21.2560525 },
    { lat: 48.6867621, lng: 21.2563762 },
    { lat: 48.6867857, lng: 21.256486 },
    { lat: 48.6868051, lng: 21.2565659 },
    { lat: 48.6868407, lng: 21.2567005 },
    { lat: 48.6869278, lng: 21.256824 },
    { lat: 48.6870362, lng: 21.2569453 },
    { lat: 48.6872827, lng: 21.2570925 },
    { lat: 48.6875381, lng: 21.2571264 },
    { lat: 48.6875635, lng: 21.257146 },
    { lat: 48.687597, lng: 21.2571725 },
    { lat: 48.6876016, lng: 21.2571761 },
    { lat: 48.6876247, lng: 21.2571602 },
    { lat: 48.6876469, lng: 21.2570803 },
    { lat: 48.6876697, lng: 21.2570502 },
    { lat: 48.6876738, lng: 21.2570378 },
    { lat: 48.6876665, lng: 21.2570187 },
    { lat: 48.6877163, lng: 21.2567025 },
    { lat: 48.6877269, lng: 21.2566451 },
    { lat: 48.6877508, lng: 21.2566534 },
    { lat: 48.6877658, lng: 21.2563738 },
    { lat: 48.6877817, lng: 21.2563787 },
    { lat: 48.6878013, lng: 21.2563948 },
    { lat: 48.6878285, lng: 21.2564335 },
    { lat: 48.6878561, lng: 21.2564928 },
    { lat: 48.6878523, lng: 21.2565137 },
    { lat: 48.6878723, lng: 21.2565765 },
    { lat: 48.6879083, lng: 21.256684 },
    { lat: 48.6879444, lng: 21.2567616 },
    { lat: 48.6879886, lng: 21.2568468 },
    { lat: 48.6880266, lng: 21.2569111 },
    { lat: 48.688083, lng: 21.2569894 },
    { lat: 48.6881781, lng: 21.2571127 },
    { lat: 48.6882721, lng: 21.2572421 },
    { lat: 48.6884713, lng: 21.257498 },
    { lat: 48.6886194, lng: 21.2577017 },
    { lat: 48.6886922, lng: 21.2578009 },
    { lat: 48.688756, lng: 21.2579004 },
    { lat: 48.688683, lng: 21.258012 },
    { lat: 48.6886315, lng: 21.2580944 },
    { lat: 48.6886004, lng: 21.2581443 },
    { lat: 48.6884982, lng: 21.2583314 },
    { lat: 48.6884173, lng: 21.2585198 },
    { lat: 48.688346, lng: 21.2587214 },
    { lat: 48.6883327, lng: 21.2587658 },
    { lat: 48.6883167, lng: 21.25883 },
    { lat: 48.6883039, lng: 21.258889 },
    { lat: 48.6882989, lng: 21.2589135 },
    { lat: 48.6882891, lng: 21.258957 },
    { lat: 48.6882455, lng: 21.2591477 },
    { lat: 48.6882109, lng: 21.2593407 },
    { lat: 48.6881874, lng: 21.2594909 },
    { lat: 48.6881582, lng: 21.2597861 },
    { lat: 48.6881518, lng: 21.2599073 },
    { lat: 48.6881494, lng: 21.2600076 },
    { lat: 48.688147, lng: 21.2602955 },
    { lat: 48.6881488, lng: 21.2603832 },
    { lat: 48.6881883, lng: 21.2622922 },
    { lat: 48.6881896, lng: 21.2623669 },
    { lat: 48.6880229, lng: 21.2624316 },
    { lat: 48.687883, lng: 21.2624482 },
    { lat: 48.6877943, lng: 21.2624554 },
    { lat: 48.6877461, lng: 21.2624598 },
    { lat: 48.6876717, lng: 21.262468 },
    { lat: 48.6877398, lng: 21.2627811 },
    { lat: 48.6877768, lng: 21.2629954 },
    { lat: 48.6877781, lng: 21.2630035 },
    { lat: 48.6877582, lng: 21.2630128 },
    { lat: 48.6876571, lng: 21.2630562 },
    { lat: 48.687489, lng: 21.2631136 },
    { lat: 48.6873308, lng: 21.2631675 },
    { lat: 48.6871715, lng: 21.2632193 },
    { lat: 48.6870143, lng: 21.2632674 },
    { lat: 48.6870398, lng: 21.2634348 },
    { lat: 48.6867595, lng: 21.2635475 },
    { lat: 48.6866739, lng: 21.2635753 },
    { lat: 48.6864986, lng: 21.2636557 },
    { lat: 48.6864785, lng: 21.2636649 },
    { lat: 48.6863118, lng: 21.2637333 },
    { lat: 48.686114, lng: 21.2638145 },
    { lat: 48.6860885, lng: 21.2638248 },
    { lat: 48.685894, lng: 21.263908 },
    { lat: 48.6857686, lng: 21.2639618 },
    { lat: 48.6857052, lng: 21.2639889 },
    { lat: 48.6857083, lng: 21.2640147 },
    { lat: 48.6856971, lng: 21.2640201 },
    { lat: 48.6855975, lng: 21.2640692 },
    { lat: 48.6855741, lng: 21.2640804 },
    { lat: 48.6854444, lng: 21.2641444 },
    { lat: 48.6853762, lng: 21.2641207 },
    { lat: 48.6853537, lng: 21.2640885 },
    { lat: 48.6853372, lng: 21.2640978 },
    { lat: 48.6852852, lng: 21.264125 },
    { lat: 48.6852475, lng: 21.2641451 },
    { lat: 48.6852711, lng: 21.2640355 },
    { lat: 48.6852529, lng: 21.2639186 },
    { lat: 48.6852432, lng: 21.2638964 },
    { lat: 48.6851795, lng: 21.2635092 },
    { lat: 48.6851157, lng: 21.2631176 },
    { lat: 48.6851099, lng: 21.2630806 },
    { lat: 48.6851074, lng: 21.2630669 },
    { lat: 48.685076, lng: 21.2630737 },
    { lat: 48.6850147, lng: 21.2630948 },
    { lat: 48.6848943, lng: 21.2631307 },
    { lat: 48.6847032, lng: 21.2630968 },
    { lat: 48.6846205, lng: 21.2630805 },
    { lat: 48.6844348, lng: 21.263043 },
    { lat: 48.6838582, lng: 21.2629205 },
    { lat: 48.6835694, lng: 21.2628625 },
    { lat: 48.6835339, lng: 21.2630644 },
    { lat: 48.6834824, lng: 21.2633651 },
    { lat: 48.6834557, lng: 21.2635201 },
    { lat: 48.6834803, lng: 21.2637684 },
    { lat: 48.6834947, lng: 21.2639084 },
    { lat: 48.6835108, lng: 21.2640661 },
    { lat: 48.6835234, lng: 21.2643538 },
    { lat: 48.6832771, lng: 21.2643692 },
    { lat: 48.6833012, lng: 21.2646404 },
    { lat: 48.6832287, lng: 21.2646594 },
    { lat: 48.683034, lng: 21.2646613 },
    { lat: 48.6829908, lng: 21.2646483 },
    { lat: 48.6829644, lng: 21.2646292 },
    { lat: 48.6829542, lng: 21.2649198 },
    { lat: 48.68295, lng: 21.265038 },
    { lat: 48.6828813, lng: 21.2651824 },
    { lat: 48.6828645, lng: 21.2652206 },
    { lat: 48.6828616, lng: 21.2652272 },
    { lat: 48.682786, lng: 21.2653985 },
    { lat: 48.6827814, lng: 21.2654088 },
    { lat: 48.6827519, lng: 21.2654771 },
    { lat: 48.6826501, lng: 21.2657108 },
    { lat: 48.6826027, lng: 21.2658206 },
    { lat: 48.6823487, lng: 21.2659022 },
    { lat: 48.6822606, lng: 21.2659291 },
    { lat: 48.6821725, lng: 21.2659559 },
    { lat: 48.6820275, lng: 21.2659968 },
    { lat: 48.6819617, lng: 21.2660156 },
    { lat: 48.6819259, lng: 21.2660238 },
    { lat: 48.6818213, lng: 21.2660604 },
    { lat: 48.6817358, lng: 21.2660978 },
    { lat: 48.6816855, lng: 21.2661102 },
    { lat: 48.6814553, lng: 21.2661645 },
    { lat: 48.681257, lng: 21.266228 },
    { lat: 48.6812491, lng: 21.2662296 },
    { lat: 48.6812178, lng: 21.2662353 },
    { lat: 48.6810643, lng: 21.2662604 },
    { lat: 48.6809795, lng: 21.266282 },
    { lat: 48.6808245, lng: 21.2663278 },
    { lat: 48.6806638, lng: 21.2663705 },
    { lat: 48.6805209, lng: 21.2664083 },
    { lat: 48.6803769, lng: 21.2664469 },
    { lat: 48.6802027, lng: 21.2664926 },
    { lat: 48.6799438, lng: 21.2665614 },
    { lat: 48.6797908, lng: 21.2666017 },
    { lat: 48.6795721, lng: 21.2666609 },
    { lat: 48.6795747, lng: 21.2666703 },
    { lat: 48.679604, lng: 21.2668179 },
    { lat: 48.6796094, lng: 21.2668499 },
    { lat: 48.6796825, lng: 21.267213 },
    { lat: 48.6796878, lng: 21.2672721 },
    { lat: 48.679713, lng: 21.2675431 },
    { lat: 48.6797264, lng: 21.2676857 },
    { lat: 48.6797383, lng: 21.2678173 },
    { lat: 48.6797488, lng: 21.2679398 },
    { lat: 48.6797611, lng: 21.2680814 },
    { lat: 48.6797743, lng: 21.2681082 },
    { lat: 48.6797944, lng: 21.268142 },
    { lat: 48.6798355, lng: 21.2681958 },
    { lat: 48.6798712, lng: 21.2682218 },
    { lat: 48.6798483, lng: 21.2682462 },
    { lat: 48.6798686, lng: 21.2685001 },
    { lat: 48.6798813, lng: 21.268585 },
    { lat: 48.6798858, lng: 21.2687293 },
    { lat: 48.6798877, lng: 21.2687939 },
    { lat: 48.6798938, lng: 21.2688113 },
    { lat: 48.6798843, lng: 21.2688326 },
    { lat: 48.6798785, lng: 21.2688608 },
    { lat: 48.6802536, lng: 21.2687927 },
    { lat: 48.6803316, lng: 21.2689268 },
    { lat: 48.6802784, lng: 21.2690904 },
    { lat: 48.6804644, lng: 21.2690367 },
    { lat: 48.6804998, lng: 21.2692755 },
    { lat: 48.6804644, lng: 21.2693854 },
    { lat: 48.6807424, lng: 21.2717511 },
    { lat: 48.6807265, lng: 21.2717967 },
    { lat: 48.6807017, lng: 21.2718155 },
    { lat: 48.6805264, lng: 21.2718584 },
    { lat: 48.6806043, lng: 21.2724861 },
    { lat: 48.680661, lng: 21.272655 },
    { lat: 48.6810258, lng: 21.2725639 },
    { lat: 48.6812905, lng: 21.2749161 },
    { lat: 48.6814627, lng: 21.2749168 },
    { lat: 48.6814703, lng: 21.2749725 },
    { lat: 48.6807846, lng: 21.2752234 },
    { lat: 48.6808028, lng: 21.27534 },
    { lat: 48.6809235, lng: 21.2760816 },
    { lat: 48.68095, lng: 21.2762499 },
    { lat: 48.6812557, lng: 21.276127 },
    { lat: 48.6820301, lng: 21.2758031 },
    { lat: 48.6820368, lng: 21.2757998 },
    { lat: 48.6822146, lng: 21.2757267 },
    { lat: 48.6822887, lng: 21.2760154 },
    { lat: 48.682296, lng: 21.2762852 },
    { lat: 48.6822972, lng: 21.2763634 },
    { lat: 48.6823003, lng: 21.2764996 },
    { lat: 48.6824344, lng: 21.2763965 },
    { lat: 48.6827452, lng: 21.2761475 },
    { lat: 48.6829704, lng: 21.2759651 },
    { lat: 48.6833446, lng: 21.2758035 },
    { lat: 48.6834286, lng: 21.2757513 },
    { lat: 48.683995, lng: 21.2753971 },
    { lat: 48.6841698, lng: 21.2753378 },
    { lat: 48.684308, lng: 21.2752903 },
    { lat: 48.6845146, lng: 21.2752427 },
    { lat: 48.6849425, lng: 21.2750289 },
    { lat: 48.6849513, lng: 21.275024 },
    { lat: 48.6849957, lng: 21.2750024 },
    { lat: 48.6850145, lng: 21.2749929 },
    { lat: 48.6850445, lng: 21.2749776 },
    { lat: 48.6852362, lng: 21.2748819 },
    { lat: 48.6854481, lng: 21.2747107 },
    { lat: 48.685617, lng: 21.2746027 },
    { lat: 48.6858817, lng: 21.2744259 },
    { lat: 48.6862172, lng: 21.2742136 },
    { lat: 48.6863553, lng: 21.2742025 },
    { lat: 48.6865246, lng: 21.2741835 },
    { lat: 48.686629, lng: 21.274173 },
    { lat: 48.686804, lng: 21.2741546 },
    { lat: 48.6869566, lng: 21.2741389 },
    { lat: 48.6871979, lng: 21.2740857 },
    { lat: 48.6878328, lng: 21.2738694 },
    { lat: 48.688192, lng: 21.2737302 },
    { lat: 48.6885817, lng: 21.2736317 },
    { lat: 48.6892209, lng: 21.273377 },
    { lat: 48.6892753, lng: 21.2733514 },
    { lat: 48.6893762, lng: 21.2733045 },
    { lat: 48.6893952, lng: 21.273296 },
    { lat: 48.689454, lng: 21.2732684 },
    { lat: 48.6900094, lng: 21.2729601 },
    { lat: 48.6899565, lng: 21.2730657 },
    { lat: 48.6898917, lng: 21.2730848 },
    { lat: 48.6898991, lng: 21.2731413 },
    { lat: 48.6901493, lng: 21.2743252 },
    { lat: 48.6902727, lng: 21.2748975 },
    { lat: 48.6904153, lng: 21.275364 },
    { lat: 48.6904965, lng: 21.2756949 },
    { lat: 48.6905621, lng: 21.275965 },
    { lat: 48.6907031, lng: 21.2767821 },
    { lat: 48.6908328, lng: 21.2775207 },
    { lat: 48.6910012, lng: 21.2781657 },
    { lat: 48.6910138, lng: 21.2782126 },
    { lat: 48.691091, lng: 21.2785997 },
    { lat: 48.6910926, lng: 21.2785786 },
    { lat: 48.6910935, lng: 21.2782041 },
    { lat: 48.6911725, lng: 21.2782118 },
    { lat: 48.691209, lng: 21.2781925 },
    { lat: 48.6912157, lng: 21.2781892 },
    { lat: 48.6912762, lng: 21.2785482 },
    { lat: 48.6913026, lng: 21.2786732 },
    { lat: 48.6913433, lng: 21.2788231 },
    { lat: 48.6913805, lng: 21.2789391 },
    { lat: 48.6914067, lng: 21.2790182 },
    { lat: 48.6914616, lng: 21.2791589 },
    { lat: 48.6915348, lng: 21.2793095 },
    { lat: 48.6916148, lng: 21.2794552 },
    { lat: 48.6916621, lng: 21.2795321 },
    { lat: 48.6916881, lng: 21.2795737 },
    { lat: 48.6917657, lng: 21.2796808 },
    { lat: 48.6918218, lng: 21.2797496 },
    { lat: 48.6919032, lng: 21.2798358 },
    { lat: 48.6919726, lng: 21.2798961 },
    { lat: 48.6920902, lng: 21.2799858 },
    { lat: 48.6922118, lng: 21.2800642 },
    { lat: 48.6923604, lng: 21.2801385 },
    { lat: 48.6924653, lng: 21.2801818 },
    { lat: 48.6925267, lng: 21.2801985 },
    { lat: 48.6926002, lng: 21.2802122 },
    { lat: 48.6927288, lng: 21.2802232 },
    { lat: 48.6929927, lng: 21.2802417 },
    { lat: 48.6932745, lng: 21.2802576 },
    { lat: 48.6933163, lng: 21.2802611 },
    { lat: 48.6934598, lng: 21.2802777 },
    { lat: 48.6934712, lng: 21.2803214 },
    { lat: 48.6934904, lng: 21.2803632 },
    { lat: 48.6935071, lng: 21.2803923 },
    { lat: 48.69374, lng: 21.2804252 },
    { lat: 48.6939558, lng: 21.2804549 },
    { lat: 48.6939954, lng: 21.28046 },
    { lat: 48.6941434, lng: 21.2804802 },
    { lat: 48.6943536, lng: 21.2805068 },
    { lat: 48.6945977, lng: 21.280541 },
    { lat: 48.6948553, lng: 21.2805764 },
    { lat: 48.6949637, lng: 21.2805845 },
    { lat: 48.6952152, lng: 21.2806039 },
    { lat: 48.6953348, lng: 21.2806126 },
    { lat: 48.6955444, lng: 21.2806236 },
    { lat: 48.6958907, lng: 21.2806485 },
    { lat: 48.6961172, lng: 21.2806615 },
    { lat: 48.6968874, lng: 21.2807104 },
    { lat: 48.6972768, lng: 21.2807092 },
    { lat: 48.6976245, lng: 21.2807087 },
    { lat: 48.698211, lng: 21.2807509 },
    { lat: 48.6982489, lng: 21.2807009 },
    { lat: 48.6982823, lng: 21.2806904 },
    { lat: 48.6982879, lng: 21.2806886 },
    { lat: 48.6983927, lng: 21.280657 },
    { lat: 48.698455, lng: 21.2806384 },
    { lat: 48.6984952, lng: 21.2806982 },
    { lat: 48.6985187, lng: 21.2806948 },
    { lat: 48.6985534, lng: 21.2806899 },
    { lat: 48.698651, lng: 21.2806759 },
    { lat: 48.6987753, lng: 21.2806587 },
    { lat: 48.6990364, lng: 21.2806226 },
    { lat: 48.6990774, lng: 21.2804551 },
    { lat: 48.6990924, lng: 21.2804284 },
    { lat: 48.6991886, lng: 21.2801566 },
    { lat: 48.6992295, lng: 21.2800939 },
    { lat: 48.699379, lng: 21.2798661 },
    { lat: 48.6994693, lng: 21.279728 },
    { lat: 48.699719, lng: 21.2793969 },
    { lat: 48.70002, lng: 21.2790456 },
    { lat: 48.7003865, lng: 21.2786326 },
    { lat: 48.7006136, lng: 21.2784022 },
    { lat: 48.7012274, lng: 21.277688 },
    { lat: 48.7018439, lng: 21.277095 },
    { lat: 48.7020853, lng: 21.2768191 },
    { lat: 48.7023997, lng: 21.2764682 },
    { lat: 48.7027794, lng: 21.2761519 },
    { lat: 48.7032239, lng: 21.2757138 },
    { lat: 48.7033294, lng: 21.2757399 },
    { lat: 48.7034111, lng: 21.2757598 },
    { lat: 48.7035845, lng: 21.2758025 },
    { lat: 48.7038528, lng: 21.2757067 },
    { lat: 48.7044691, lng: 21.2756564 },
    { lat: 48.7048232, lng: 21.2757142 },
    { lat: 48.7054946, lng: 21.2758488 },
    { lat: 48.7057957, lng: 21.2760165 },
    { lat: 48.7066533, lng: 21.2766785 },
    { lat: 48.7070593, lng: 21.2769974 },
    { lat: 48.7077519, lng: 21.2774541 },
    { lat: 48.7083369, lng: 21.2777391 },
    { lat: 48.7089374, lng: 21.2779475 },
    { lat: 48.7095154, lng: 21.2780434 },
    { lat: 48.7101914, lng: 21.277955 },
    { lat: 48.7108488, lng: 21.2778911 },
    { lat: 48.7114615, lng: 21.2777569 },
    { lat: 48.7128991, lng: 21.2775163 },
    { lat: 48.7131608, lng: 21.2774659 },
    { lat: 48.7139605, lng: 21.2773378 },
    { lat: 48.7145631, lng: 21.2772503 },
    { lat: 48.7150313, lng: 21.2771771 },
    { lat: 48.7164674, lng: 21.276926 },
    { lat: 48.717175, lng: 21.2767694 },
    { lat: 48.7187323, lng: 21.276504 },
    { lat: 48.7192181, lng: 21.2764225 },
    { lat: 48.7204574, lng: 21.2762083 },
    { lat: 48.7206394, lng: 21.2760886 },
    { lat: 48.7208944, lng: 21.2759631 },
    { lat: 48.7210756, lng: 21.2757836 },
    { lat: 48.7213256, lng: 21.2754952 },
    { lat: 48.7216031, lng: 21.2750409 },
    { lat: 48.721736, lng: 21.274772 },
    { lat: 48.721718, lng: 21.274752 },
    { lat: 48.721633, lng: 21.274656 },
    { lat: 48.721616, lng: 21.274635 },
    { lat: 48.72152, lng: 21.274527 },
    { lat: 48.721509, lng: 21.274516 },
    { lat: 48.721506, lng: 21.274512 },
    { lat: 48.721459, lng: 21.274459 },
    { lat: 48.721332, lng: 21.274311 },
    { lat: 48.721318, lng: 21.274294 },
    { lat: 48.721253, lng: 21.274218 },
    { lat: 48.721064, lng: 21.273998 },
    { lat: 48.721004, lng: 21.273931 },
    { lat: 48.720983, lng: 21.273906 },
    { lat: 48.720836, lng: 21.273737 },
    { lat: 48.7208, lng: 21.273694 },
    { lat: 48.720711, lng: 21.273592 },
    { lat: 48.720693, lng: 21.273572 },
    { lat: 48.720638, lng: 21.273508 },
    { lat: 48.720471, lng: 21.273317 },
    { lat: 48.720341, lng: 21.273163 },
    { lat: 48.720244, lng: 21.273054 },
    { lat: 48.720158, lng: 21.272961 },
    { lat: 48.720127, lng: 21.272928 },
    { lat: 48.720071, lng: 21.272864 },
    { lat: 48.720058, lng: 21.27285 },
    { lat: 48.720005, lng: 21.272789 },
    { lat: 48.719983, lng: 21.272764 },
    { lat: 48.719965, lng: 21.272745 },
    { lat: 48.719836, lng: 21.2726 },
    { lat: 48.719568, lng: 21.272298 },
    { lat: 48.719392, lng: 21.272048 },
    { lat: 48.719314, lng: 21.271928 },
    { lat: 48.719273, lng: 21.271864 },
    { lat: 48.719184, lng: 21.271669 },
    { lat: 48.71908, lng: 21.271509 },
    { lat: 48.719066, lng: 21.271482 },
    { lat: 48.719038, lng: 21.271418 },
    { lat: 48.718936, lng: 21.271296 },
    { lat: 48.718978, lng: 21.271278 },
    { lat: 48.718919, lng: 21.271113 },
    { lat: 48.718878, lng: 21.270983 },
    { lat: 48.718875, lng: 21.270941 },
    { lat: 48.718861, lng: 21.270721 },
    { lat: 48.718798, lng: 21.270357 },
    { lat: 48.71878, lng: 21.270251 },
    { lat: 48.71877, lng: 21.270188 },
    { lat: 48.718762, lng: 21.270143 },
    { lat: 48.7186872, lng: 21.2696987 },
  ],
  KošicemestskáčasťKrásna: [
    { lat: 48.651431, lng: 21.328071 },
    { lat: 48.651435, lng: 21.328159 },
    { lat: 48.651494, lng: 21.328682 },
    { lat: 48.651628, lng: 21.328795 },
    { lat: 48.651616, lng: 21.328979 },
    { lat: 48.651472, lng: 21.329125 },
    { lat: 48.651477, lng: 21.329279 },
    { lat: 48.651503, lng: 21.329986 },
    { lat: 48.651506, lng: 21.330066 },
    { lat: 48.651296, lng: 21.330048 },
    { lat: 48.651156, lng: 21.330035 },
    { lat: 48.650313, lng: 21.329962 },
    { lat: 48.648717, lng: 21.330314 },
    { lat: 48.647599, lng: 21.331256 },
    { lat: 48.647315, lng: 21.331669 },
    { lat: 48.646732, lng: 21.332811 },
    { lat: 48.646638, lng: 21.333052 },
    { lat: 48.646598, lng: 21.333281 },
    { lat: 48.646591, lng: 21.33332 },
    { lat: 48.646493, lng: 21.333954 },
    { lat: 48.64647, lng: 21.33446 },
    { lat: 48.646409, lng: 21.335792 },
    { lat: 48.646441, lng: 21.338183 },
    { lat: 48.646186, lng: 21.33937 },
    { lat: 48.644943, lng: 21.341921 },
    { lat: 48.644599, lng: 21.342626 },
    { lat: 48.644331, lng: 21.343176 },
    { lat: 48.643989, lng: 21.344105 },
    { lat: 48.643861, lng: 21.344452 },
    { lat: 48.643566, lng: 21.345974 },
    { lat: 48.643574, lng: 21.347311 },
    { lat: 48.643634, lng: 21.347863 },
    { lat: 48.643708, lng: 21.348227 },
    { lat: 48.643834, lng: 21.348839 },
    { lat: 48.644381, lng: 21.350412 },
    { lat: 48.644441, lng: 21.350673 },
    { lat: 48.644688, lng: 21.351725 },
    { lat: 48.644739, lng: 21.352776 },
    { lat: 48.644728, lng: 21.352817 },
    { lat: 48.644353, lng: 21.354199 },
    { lat: 48.643835, lng: 21.355067 },
    { lat: 48.643528, lng: 21.355583 },
    { lat: 48.643259, lng: 21.356033 },
    { lat: 48.64286, lng: 21.356648 },
    { lat: 48.641721, lng: 21.358213 },
    { lat: 48.645335, lng: 21.358066 },
    { lat: 48.645427, lng: 21.358062 },
    { lat: 48.645734, lng: 21.35805 },
    { lat: 48.645757, lng: 21.358041 },
    { lat: 48.646186, lng: 21.357868 },
    { lat: 48.646374, lng: 21.357769 },
    { lat: 48.646697, lng: 21.357706 },
    { lat: 48.647218, lng: 21.357163 },
    { lat: 48.647314, lng: 21.357063 },
    { lat: 48.647625, lng: 21.356844 },
    { lat: 48.648356, lng: 21.356328 },
    { lat: 48.648364, lng: 21.356323 },
    { lat: 48.648408, lng: 21.356303 },
    { lat: 48.64849, lng: 21.35627 },
    { lat: 48.649788, lng: 21.355754 },
    { lat: 48.64998, lng: 21.355677 },
    { lat: 48.65009, lng: 21.355633 },
    { lat: 48.650398, lng: 21.355562 },
    { lat: 48.650488, lng: 21.355541 },
    { lat: 48.650735, lng: 21.355761 },
    { lat: 48.651445, lng: 21.355547 },
    { lat: 48.652015, lng: 21.355374 },
    { lat: 48.652947, lng: 21.355092 },
    { lat: 48.653225, lng: 21.355008 },
    { lat: 48.653886, lng: 21.354926 },
    { lat: 48.65406, lng: 21.354904 },
    { lat: 48.654443, lng: 21.354975 },
    { lat: 48.654754, lng: 21.35489 },
    { lat: 48.655628, lng: 21.355097 },
    { lat: 48.65621, lng: 21.355093 },
    { lat: 48.65739, lng: 21.355278 },
    { lat: 48.657418, lng: 21.355175 },
    { lat: 48.658113, lng: 21.352634 },
    { lat: 48.658283, lng: 21.352009 },
    { lat: 48.658345, lng: 21.351827 },
    { lat: 48.659156, lng: 21.349423 },
    { lat: 48.659208, lng: 21.349437 },
    { lat: 48.659283, lng: 21.349457 },
    { lat: 48.659368, lng: 21.349479 },
    { lat: 48.660793, lng: 21.349439 },
    { lat: 48.661898, lng: 21.349408 },
    { lat: 48.664081, lng: 21.349342 },
    { lat: 48.666584, lng: 21.349259 },
    { lat: 48.668134, lng: 21.349207 },
    { lat: 48.668424, lng: 21.349173 },
    { lat: 48.668977, lng: 21.349142 },
    { lat: 48.670644, lng: 21.34904 },
    { lat: 48.670695, lng: 21.349037 },
    { lat: 48.67394, lng: 21.3499 },
    { lat: 48.674188, lng: 21.349933 },
    { lat: 48.67414, lng: 21.347207 },
    { lat: 48.674099, lng: 21.346644 },
    { lat: 48.674158, lng: 21.34677 },
    { lat: 48.674243, lng: 21.34695 },
    { lat: 48.675103, lng: 21.347421 },
    { lat: 48.675446, lng: 21.34737 },
    { lat: 48.675798, lng: 21.347033 },
    { lat: 48.675976, lng: 21.34643 },
    { lat: 48.675943, lng: 21.345555 },
    { lat: 48.676605, lng: 21.344816 },
    { lat: 48.676765, lng: 21.344221 },
    { lat: 48.67663, lng: 21.343564 },
    { lat: 48.676575, lng: 21.34265 },
    { lat: 48.676734, lng: 21.342245 },
    { lat: 48.677142, lng: 21.341816 },
    { lat: 48.677331, lng: 21.342041 },
    { lat: 48.677384, lng: 21.34204 },
    { lat: 48.677784, lng: 21.342036 },
    { lat: 48.678138, lng: 21.34224 },
    { lat: 48.678384, lng: 21.342331 },
    { lat: 48.678425, lng: 21.342346 },
    { lat: 48.678428, lng: 21.342434 },
    { lat: 48.679143, lng: 21.341528 },
    { lat: 48.679263, lng: 21.341024 },
    { lat: 48.679395, lng: 21.340692 },
    { lat: 48.679644, lng: 21.340515 },
    { lat: 48.679781, lng: 21.34058 },
    { lat: 48.679892, lng: 21.340678 },
    { lat: 48.680067, lng: 21.34067 },
    { lat: 48.680374, lng: 21.340336 },
    { lat: 48.680491, lng: 21.339954 },
    { lat: 48.680822, lng: 21.339713 },
    { lat: 48.680757, lng: 21.339351 },
    { lat: 48.680807, lng: 21.339096 },
    { lat: 48.681162, lng: 21.338922 },
    { lat: 48.681274, lng: 21.338816 },
    { lat: 48.681527, lng: 21.338645 },
    { lat: 48.681819, lng: 21.33869 },
    { lat: 48.681899, lng: 21.33869 },
    { lat: 48.681914, lng: 21.33869 },
    { lat: 48.682061, lng: 21.338721 },
    { lat: 48.682263, lng: 21.33856 },
    { lat: 48.682335, lng: 21.338409 },
    { lat: 48.682597, lng: 21.338079 },
    { lat: 48.68271, lng: 21.337693 },
    { lat: 48.682877, lng: 21.337189 },
    { lat: 48.682925, lng: 21.337021 },
    { lat: 48.682999, lng: 21.336763 },
    { lat: 48.683039, lng: 21.336366 },
    { lat: 48.682929, lng: 21.336234 },
    { lat: 48.682942, lng: 21.335863 },
    { lat: 48.683018, lng: 21.335687 },
    { lat: 48.683253, lng: 21.33527 },
    { lat: 48.683426, lng: 21.335095 },
    { lat: 48.683551, lng: 21.335015 },
    { lat: 48.683648, lng: 21.335148 },
    { lat: 48.684309, lng: 21.334626 },
    { lat: 48.684658, lng: 21.333494 },
    { lat: 48.68609, lng: 21.332328 },
    { lat: 48.686918, lng: 21.331268 },
    { lat: 48.686959, lng: 21.3312 },
    { lat: 48.687006, lng: 21.331127 },
    { lat: 48.687022, lng: 21.331101 },
    { lat: 48.687253, lng: 21.330732 },
    { lat: 48.687307, lng: 21.330762 },
    { lat: 48.687865, lng: 21.330584 },
    { lat: 48.688322, lng: 21.33095 },
    { lat: 48.688633, lng: 21.33069 },
    { lat: 48.688912, lng: 21.329634 },
    { lat: 48.688979, lng: 21.329524 },
    { lat: 48.689332, lng: 21.328953 },
    { lat: 48.689594, lng: 21.328836 },
    { lat: 48.690426, lng: 21.329238 },
    { lat: 48.691934, lng: 21.329694 },
    { lat: 48.692689, lng: 21.329798 },
    { lat: 48.693367, lng: 21.329806 },
    { lat: 48.694274, lng: 21.329672 },
    { lat: 48.69431, lng: 21.329665 },
    { lat: 48.694426, lng: 21.329641 },
    { lat: 48.695108, lng: 21.330005 },
    { lat: 48.695504, lng: 21.330308 },
    { lat: 48.696018, lng: 21.330248 },
    { lat: 48.696104, lng: 21.329746 },
    { lat: 48.696938, lng: 21.32979 },
    { lat: 48.699078, lng: 21.329993 },
    { lat: 48.699682, lng: 21.33005 },
    { lat: 48.699759, lng: 21.330058 },
    { lat: 48.700156, lng: 21.330189 },
    { lat: 48.700912, lng: 21.330152 },
    { lat: 48.700941, lng: 21.330087 },
    { lat: 48.701196, lng: 21.329519 },
    { lat: 48.701618, lng: 21.328547 },
    { lat: 48.701855, lng: 21.327799 },
    { lat: 48.70193, lng: 21.327851 },
    { lat: 48.70355, lng: 21.328724 },
    { lat: 48.70417, lng: 21.329055 },
    { lat: 48.704514, lng: 21.329228 },
    { lat: 48.705527, lng: 21.329754 },
    { lat: 48.705798, lng: 21.329895 },
    { lat: 48.705827, lng: 21.329754 },
    { lat: 48.705919, lng: 21.329801 },
    { lat: 48.706818, lng: 21.330264 },
    { lat: 48.706922, lng: 21.329948 },
    { lat: 48.707035, lng: 21.329598 },
    { lat: 48.70712, lng: 21.329341 },
    { lat: 48.707206, lng: 21.329078 },
    { lat: 48.707293, lng: 21.328813 },
    { lat: 48.707353, lng: 21.328629 },
    { lat: 48.707412, lng: 21.328445 },
    { lat: 48.7075, lng: 21.32818 },
    { lat: 48.707524, lng: 21.328107 },
    { lat: 48.707483, lng: 21.328082 },
    { lat: 48.70766, lng: 21.327495 },
    { lat: 48.707666, lng: 21.327475 },
    { lat: 48.708225, lng: 21.327681 },
    { lat: 48.708235, lng: 21.327685 },
    { lat: 48.708464, lng: 21.326641 },
    { lat: 48.708491, lng: 21.326521 },
    { lat: 48.708534, lng: 21.32651 },
    { lat: 48.708557, lng: 21.326504 },
    { lat: 48.708578, lng: 21.326499 },
    { lat: 48.709398, lng: 21.326285 },
    { lat: 48.709964, lng: 21.325828 },
    { lat: 48.710393, lng: 21.325489 },
    { lat: 48.710636, lng: 21.325175 },
    { lat: 48.710812, lng: 21.324846 },
    { lat: 48.710824, lng: 21.324249 },
    { lat: 48.710697, lng: 21.323436 },
    { lat: 48.710703, lng: 21.323401 },
    { lat: 48.710713, lng: 21.323344 },
    { lat: 48.710723, lng: 21.323279 },
    { lat: 48.710727, lng: 21.323259 },
    { lat: 48.710728, lng: 21.323254 },
    { lat: 48.710737, lng: 21.323196 },
    { lat: 48.711076, lng: 21.322566 },
    { lat: 48.711283, lng: 21.321828 },
    { lat: 48.711542, lng: 21.321537 },
    { lat: 48.7116, lng: 21.321213 },
    { lat: 48.71169, lng: 21.321189 },
    { lat: 48.711761, lng: 21.321169 },
    { lat: 48.711823, lng: 21.320915 },
    { lat: 48.712047, lng: 21.320271 },
    { lat: 48.712063, lng: 21.319933 },
    { lat: 48.712255, lng: 21.319361 },
    { lat: 48.712551, lng: 21.31776 },
    { lat: 48.712571, lng: 21.317615 },
    { lat: 48.712567, lng: 21.317345 },
    { lat: 48.712561, lng: 21.31692 },
    { lat: 48.712559, lng: 21.316765 },
    { lat: 48.712552, lng: 21.316678 },
    { lat: 48.712489, lng: 21.31587 },
    { lat: 48.712536, lng: 21.315378 },
    { lat: 48.712503, lng: 21.314184 },
    { lat: 48.712421, lng: 21.313791 },
    { lat: 48.711991, lng: 21.312866 },
    { lat: 48.712065, lng: 21.312512 },
    { lat: 48.712179, lng: 21.311631 },
    { lat: 48.712133, lng: 21.311322 },
    { lat: 48.712193, lng: 21.311224 },
    { lat: 48.712292, lng: 21.310244 },
    { lat: 48.712351, lng: 21.309337 },
    { lat: 48.712566, lng: 21.30876 },
    { lat: 48.712513, lng: 21.308147 },
    { lat: 48.71259, lng: 21.307574 },
    { lat: 48.712537, lng: 21.306859 },
    { lat: 48.712291, lng: 21.306484 },
    { lat: 48.712329, lng: 21.30627 },
    { lat: 48.712136, lng: 21.305485 },
    { lat: 48.712155, lng: 21.304741 },
    { lat: 48.712005, lng: 21.304589 },
    { lat: 48.71191, lng: 21.304316 },
    { lat: 48.711869, lng: 21.303843 },
    { lat: 48.7119204, lng: 21.3035999 },
    { lat: 48.7113219, lng: 21.3029753 },
    { lat: 48.7108339, lng: 21.3022902 },
    { lat: 48.7106547, lng: 21.301983 },
    { lat: 48.710448, lng: 21.301774 },
    { lat: 48.7101308, lng: 21.3012659 },
    { lat: 48.7099241, lng: 21.30002 },
    { lat: 48.7095715, lng: 21.2993458 },
    { lat: 48.7095387, lng: 21.2989383 },
    { lat: 48.7094306, lng: 21.2984549 },
    { lat: 48.7092826, lng: 21.2982151 },
    { lat: 48.709218, lng: 21.2978319 },
    { lat: 48.7090534, lng: 21.2974536 },
    { lat: 48.7092694, lng: 21.2971178 },
    { lat: 48.7093209, lng: 21.296889 },
    { lat: 48.7092964, lng: 21.2967133 },
    { lat: 48.709316, lng: 21.2966175 },
    { lat: 48.7092993, lng: 21.2964034 },
    { lat: 48.7088586, lng: 21.2938783 },
    { lat: 48.7080344, lng: 21.2932386 },
    { lat: 48.7079669, lng: 21.2932404 },
    { lat: 48.7079162, lng: 21.2932507 },
    { lat: 48.7078563, lng: 21.2933739 },
    { lat: 48.7074599, lng: 21.2939183 },
    { lat: 48.7072537, lng: 21.2941322 },
    { lat: 48.7069688, lng: 21.2945308 },
    { lat: 48.7068232, lng: 21.2947013 },
    { lat: 48.7066585, lng: 21.294834 },
    { lat: 48.7064346, lng: 21.2949843 },
    { lat: 48.7061392, lng: 21.2952622 },
    { lat: 48.7059701, lng: 21.2953602 },
    { lat: 48.7057407, lng: 21.2953666 },
    { lat: 48.705671, lng: 21.2953686 },
    { lat: 48.7054828, lng: 21.2952492 },
    { lat: 48.7053092, lng: 21.2949079 },
    { lat: 48.7052415, lng: 21.2948306 },
    { lat: 48.7050436, lng: 21.2947981 },
    { lat: 48.7050176, lng: 21.2947935 },
    { lat: 48.7048977, lng: 21.2947738 },
    { lat: 48.7047818, lng: 21.2947391 },
    { lat: 48.7045058, lng: 21.2946561 },
    { lat: 48.7044695, lng: 21.2946444 },
    { lat: 48.7042757, lng: 21.2945995 },
    { lat: 48.704177, lng: 21.2945779 },
    { lat: 48.7041376, lng: 21.2945742 },
    { lat: 48.7038793, lng: 21.2944818 },
    { lat: 48.7038757, lng: 21.2944742 },
    { lat: 48.7037768, lng: 21.2944419 },
    { lat: 48.7036874, lng: 21.2943908 },
    { lat: 48.7035978, lng: 21.294329 },
    { lat: 48.7035603, lng: 21.2942839 },
    { lat: 48.7035531, lng: 21.2942659 },
    { lat: 48.7032791, lng: 21.2943961 },
    { lat: 48.7031772, lng: 21.2944125 },
    { lat: 48.7030728, lng: 21.294418 },
    { lat: 48.7029671, lng: 21.2944236 },
    { lat: 48.7028659, lng: 21.2944293 },
    { lat: 48.7025601, lng: 21.2944364 },
    { lat: 48.7024132, lng: 21.2944138 },
    { lat: 48.702363, lng: 21.2943917 },
    { lat: 48.7022374, lng: 21.2943345 },
    { lat: 48.7021347, lng: 21.2942886 },
    { lat: 48.7020205, lng: 21.2942367 },
    { lat: 48.7018985, lng: 21.2941819 },
    { lat: 48.7017536, lng: 21.2941171 },
    { lat: 48.7016558, lng: 21.2940848 },
    { lat: 48.7015985, lng: 21.2940517 },
    { lat: 48.7014674, lng: 21.2939953 },
    { lat: 48.7013145, lng: 21.2939283 },
    { lat: 48.7012849, lng: 21.2939155 },
    { lat: 48.7011217, lng: 21.2937302 },
    { lat: 48.7009118, lng: 21.2933786 },
    { lat: 48.7007849, lng: 21.2931338 },
    { lat: 48.7006025, lng: 21.2929453 },
    { lat: 48.7005321, lng: 21.2928511 },
    { lat: 48.7000383, lng: 21.2910933 },
    { lat: 48.6998866, lng: 21.2911367 },
    { lat: 48.6998531, lng: 21.2911468 },
    { lat: 48.6993645, lng: 21.2912886 },
    { lat: 48.6979559, lng: 21.291703 },
    { lat: 48.6979525, lng: 21.2917039 },
    { lat: 48.6976491, lng: 21.2917931 },
    { lat: 48.6969595, lng: 21.292168 },
    { lat: 48.6963973, lng: 21.2924748 },
    { lat: 48.6958372, lng: 21.2927832 },
    { lat: 48.6953404, lng: 21.293058 },
    { lat: 48.695295, lng: 21.2930832 },
    { lat: 48.6950612, lng: 21.2930953 },
    { lat: 48.6947725, lng: 21.2935515 },
    { lat: 48.6943005, lng: 21.294309 },
    { lat: 48.6942681, lng: 21.2943889 },
    { lat: 48.6943008, lng: 21.2944279 },
    { lat: 48.6943323, lng: 21.2944264 },
    { lat: 48.6943724, lng: 21.2944899 },
    { lat: 48.6941319, lng: 21.2947564 },
    { lat: 48.6939941, lng: 21.2949573 },
    { lat: 48.6937978, lng: 21.2951626 },
    { lat: 48.6937161, lng: 21.2951063 },
    { lat: 48.6936697, lng: 21.2951352 },
    { lat: 48.6935847, lng: 21.2951882 },
    { lat: 48.6934166, lng: 21.2952829 },
    { lat: 48.6933687, lng: 21.295303 },
    { lat: 48.6932888, lng: 21.2953385 },
    { lat: 48.6932074, lng: 21.2953235 },
    { lat: 48.6929867, lng: 21.2952848 },
    { lat: 48.6928227, lng: 21.2952556 },
    { lat: 48.6927808, lng: 21.2952856 },
    { lat: 48.6925881, lng: 21.2954251 },
    { lat: 48.6923783, lng: 21.2949667 },
    { lat: 48.6908322, lng: 21.2946408 },
    { lat: 48.690791, lng: 21.2945446 },
    { lat: 48.6908611, lng: 21.2942249 },
    { lat: 48.6907309, lng: 21.2944954 },
    { lat: 48.6907112, lng: 21.2944754 },
    { lat: 48.6906893, lng: 21.2940894 },
    { lat: 48.6904432, lng: 21.294103 },
    { lat: 48.6900949, lng: 21.294267 },
    { lat: 48.6900378, lng: 21.2940195 },
    { lat: 48.6897715, lng: 21.2940341 },
    { lat: 48.6894211, lng: 21.2940201 },
    { lat: 48.6891157, lng: 21.294008 },
    { lat: 48.6889614, lng: 21.2939992 },
    { lat: 48.6887492, lng: 21.2939776 },
    { lat: 48.6885942, lng: 21.2940258 },
    { lat: 48.6885596, lng: 21.2940365 },
    { lat: 48.6881993, lng: 21.2941816 },
    { lat: 48.6880615, lng: 21.2943832 },
    { lat: 48.6880293, lng: 21.2942508 },
    { lat: 48.6879861, lng: 21.2940865 },
    { lat: 48.6879675, lng: 21.2940308 },
    { lat: 48.6878441, lng: 21.2941184 },
    { lat: 48.6873573, lng: 21.2935441 },
    { lat: 48.6874359, lng: 21.2935055 },
    { lat: 48.6875915, lng: 21.2934027 },
    { lat: 48.6876509, lng: 21.2929886 },
    { lat: 48.6875847, lng: 21.2926609 },
    { lat: 48.6875473, lng: 21.2924702 },
    { lat: 48.6875366, lng: 21.2924175 },
    { lat: 48.6874208, lng: 21.2923814 },
    { lat: 48.687272, lng: 21.2924858 },
    { lat: 48.6871789, lng: 21.2925343 },
    { lat: 48.6867278, lng: 21.2927974 },
    { lat: 48.6867103, lng: 21.2928153 },
    { lat: 48.6866583, lng: 21.2928428 },
    { lat: 48.6865933, lng: 21.2928879 },
    { lat: 48.6862235, lng: 21.2930869 },
    { lat: 48.6857636, lng: 21.2933554 },
    { lat: 48.6854638, lng: 21.2937454 },
    { lat: 48.6854413, lng: 21.2937476 },
    { lat: 48.6853751, lng: 21.2937181 },
    { lat: 48.6853367, lng: 21.2936716 },
    { lat: 48.6851762, lng: 21.2939093 },
    { lat: 48.6851067, lng: 21.2939904 },
    { lat: 48.6850161, lng: 21.2940843 },
    { lat: 48.6849813, lng: 21.2941231 },
    { lat: 48.6847861, lng: 21.2943646 },
    { lat: 48.684525, lng: 21.2946941 },
    { lat: 48.6843517, lng: 21.2949142 },
    { lat: 48.6840099, lng: 21.295405 },
    { lat: 48.6838107, lng: 21.2957337 },
    { lat: 48.6836836, lng: 21.2959598 },
    { lat: 48.6835363, lng: 21.296292 },
    { lat: 48.6833819, lng: 21.2966913 },
    { lat: 48.6832712, lng: 21.2970082 },
    { lat: 48.6829175, lng: 21.2980635 },
    { lat: 48.6827475, lng: 21.2985761 },
    { lat: 48.6825617, lng: 21.2991264 },
    { lat: 48.682466, lng: 21.2994201 },
    { lat: 48.6824468, lng: 21.2994916 },
    { lat: 48.6822877, lng: 21.2999571 },
    { lat: 48.6822359, lng: 21.3000669 },
    { lat: 48.6821717, lng: 21.3001732 },
    { lat: 48.6821233, lng: 21.3002471 },
    { lat: 48.6819451, lng: 21.3004945 },
    { lat: 48.6816214, lng: 21.3009484 },
    { lat: 48.6813889, lng: 21.3012584 },
    { lat: 48.6811356, lng: 21.3015117 },
    { lat: 48.6807661, lng: 21.3019057 },
    { lat: 48.6807004, lng: 21.3019648 },
    { lat: 48.68047, lng: 21.3021236 },
    { lat: 48.6801403, lng: 21.3023467 },
    { lat: 48.679873, lng: 21.3025462 },
    { lat: 48.6795472, lng: 21.3028239 },
    { lat: 48.6790944, lng: 21.3032179 },
    { lat: 48.6787284, lng: 21.3035389 },
    { lat: 48.6786781, lng: 21.3035895 },
    { lat: 48.6786391, lng: 21.3036377 },
    { lat: 48.6785026, lng: 21.3038452 },
    { lat: 48.6783221, lng: 21.3041291 },
    { lat: 48.6779801, lng: 21.3046486 },
    { lat: 48.6776792, lng: 21.305078 },
    { lat: 48.6771933, lng: 21.3057521 },
    { lat: 48.6768848, lng: 21.3061848 },
    { lat: 48.6766234, lng: 21.3065455 },
    { lat: 48.6763436, lng: 21.3069303 },
    { lat: 48.6763249, lng: 21.3069064 },
    { lat: 48.6759804, lng: 21.3063139 },
    { lat: 48.6759408, lng: 21.3062298 },
    { lat: 48.6756196, lng: 21.3051789 },
    { lat: 48.675588, lng: 21.3051761 },
    { lat: 48.6755421, lng: 21.3049678 },
    { lat: 48.6755093, lng: 21.3047764 },
    { lat: 48.6754731, lng: 21.3045496 },
    { lat: 48.675437, lng: 21.3043235 },
    { lat: 48.675434, lng: 21.3042991 },
    { lat: 48.6754258, lng: 21.3042146 },
    { lat: 48.6754194, lng: 21.3041538 },
    { lat: 48.6753737, lng: 21.3039081 },
    { lat: 48.6753604, lng: 21.303841 },
    { lat: 48.6753429, lng: 21.3037461 },
    { lat: 48.6751463, lng: 21.3035365 },
    { lat: 48.6750845, lng: 21.3034685 },
    { lat: 48.6750308, lng: 21.303438 },
    { lat: 48.6748943, lng: 21.3032816 },
    { lat: 48.6747568, lng: 21.3031256 },
    { lat: 48.6745736, lng: 21.3029165 },
    { lat: 48.6744735, lng: 21.3028017 },
    { lat: 48.6743884, lng: 21.3027065 },
    { lat: 48.6740657, lng: 21.3023508 },
    { lat: 48.6739888, lng: 21.3024303 },
    { lat: 48.6739512, lng: 21.3024692 },
    { lat: 48.67411, lng: 21.3028024 },
    { lat: 48.6741555, lng: 21.3029321 },
    { lat: 48.6741714, lng: 21.3029741 },
    { lat: 48.6741394, lng: 21.3029952 },
    { lat: 48.6741533, lng: 21.3030433 },
    { lat: 48.6740914, lng: 21.3030797 },
    { lat: 48.6740908, lng: 21.3030972 },
    { lat: 48.6740873, lng: 21.3031302 },
    { lat: 48.6740944, lng: 21.3031414 },
    { lat: 48.6740698, lng: 21.3031837 },
    { lat: 48.6739142, lng: 21.3032836 },
    { lat: 48.6738999, lng: 21.3032935 },
    { lat: 48.6738833, lng: 21.3033039 },
    { lat: 48.6739574, lng: 21.3036694 },
    { lat: 48.6739616, lng: 21.3036933 },
    { lat: 48.6740013, lng: 21.3038914 },
    { lat: 48.6740333, lng: 21.3040533 },
    { lat: 48.673892, lng: 21.3041059 },
    { lat: 48.6737706, lng: 21.3041474 },
    { lat: 48.673647, lng: 21.3041916 },
    { lat: 48.6735235, lng: 21.3042351 },
    { lat: 48.6734023, lng: 21.304282 },
    { lat: 48.6732824, lng: 21.3043374 },
    { lat: 48.6731582, lng: 21.3043973 },
    { lat: 48.6730373, lng: 21.3044541 },
    { lat: 48.6730459, lng: 21.3045028 },
    { lat: 48.673078, lng: 21.3046852 },
    { lat: 48.6731351, lng: 21.3050067 },
    { lat: 48.6729871, lng: 21.3050622 },
    { lat: 48.672967, lng: 21.30507 },
    { lat: 48.6721793, lng: 21.3053655 },
    { lat: 48.6720785, lng: 21.3047886 },
    { lat: 48.6720668, lng: 21.3047374 },
    { lat: 48.672007, lng: 21.3043606 },
    { lat: 48.6720004, lng: 21.3043325 },
    { lat: 48.6719539, lng: 21.3040965 },
    { lat: 48.6719295, lng: 21.3039615 },
    { lat: 48.6719828, lng: 21.303794 },
    { lat: 48.6719281, lng: 21.3038797 },
    { lat: 48.6719268, lng: 21.303873 },
    { lat: 48.6718884, lng: 21.3036489 },
    { lat: 48.671967, lng: 21.3035686 },
    { lat: 48.6719923, lng: 21.3035428 },
    { lat: 48.672051, lng: 21.303483 },
    { lat: 48.6720696, lng: 21.3034654 },
    { lat: 48.6720871, lng: 21.3034492 },
    { lat: 48.6721101, lng: 21.303428 },
    { lat: 48.672156, lng: 21.3033817 },
    { lat: 48.6722291, lng: 21.3033094 },
    { lat: 48.6722445, lng: 21.3032958 },
    { lat: 48.6725772, lng: 21.3029515 },
    { lat: 48.672599, lng: 21.3029286 },
    { lat: 48.6726097, lng: 21.3029174 },
    { lat: 48.6730211, lng: 21.3024859 },
    { lat: 48.6730494, lng: 21.302455 },
    { lat: 48.6730722, lng: 21.3024307 },
    { lat: 48.6730984, lng: 21.3024032 },
    { lat: 48.6731072, lng: 21.3023937 },
    { lat: 48.6731202, lng: 21.3023796 },
    { lat: 48.6731506, lng: 21.3023465 },
    { lat: 48.6735494, lng: 21.3019125 },
    { lat: 48.6735443, lng: 21.3018601 },
    { lat: 48.6735029, lng: 21.3017192 },
    { lat: 48.6734778, lng: 21.3016347 },
    { lat: 48.6734215, lng: 21.3014464 },
    { lat: 48.6734102, lng: 21.3014091 },
    { lat: 48.6733661, lng: 21.3012548 },
    { lat: 48.6733102, lng: 21.301086 },
    { lat: 48.6732364, lng: 21.3007315 },
    { lat: 48.6731776, lng: 21.3004269 },
    { lat: 48.6731697, lng: 21.3003872 },
    { lat: 48.6730194, lng: 21.3003311 },
    { lat: 48.6727166, lng: 21.3002179 },
    { lat: 48.6728987, lng: 21.2996733 },
    { lat: 48.6729825, lng: 21.299567 },
    { lat: 48.6730222, lng: 21.2978565 },
    { lat: 48.6730316, lng: 21.2974538 },
    { lat: 48.6729501, lng: 21.2974001 },
    { lat: 48.672968, lng: 21.2967758 },
    { lat: 48.6729639, lng: 21.296603 },
    { lat: 48.6728378, lng: 21.2966004 },
    { lat: 48.6726789, lng: 21.2965923 },
    { lat: 48.6724275, lng: 21.2965792 },
    { lat: 48.6723922, lng: 21.296639 },
    { lat: 48.6722959, lng: 21.2967999 },
    { lat: 48.6722046, lng: 21.2969814 },
    { lat: 48.6721494, lng: 21.2970885 },
    { lat: 48.6719665, lng: 21.2973273 },
    { lat: 48.6719023, lng: 21.2973632 },
    { lat: 48.6718695, lng: 21.2973209 },
    { lat: 48.6718602, lng: 21.2973098 },
    { lat: 48.6718014, lng: 21.2972637 },
    { lat: 48.6718377, lng: 21.2963931 },
    { lat: 48.6718773, lng: 21.2954404 },
    { lat: 48.6718616, lng: 21.2954415 },
    { lat: 48.6718166, lng: 21.2954443 },
    { lat: 48.6717614, lng: 21.2954407 },
    { lat: 48.6717556, lng: 21.2956212 },
    { lat: 48.6717546, lng: 21.2957011 },
    { lat: 48.6716139, lng: 21.2956945 },
    { lat: 48.6716159, lng: 21.2956146 },
    { lat: 48.6716163, lng: 21.2955191 },
    { lat: 48.6716171, lng: 21.295471 },
    { lat: 48.6716757, lng: 21.295473 },
    { lat: 48.6716843, lng: 21.2952 },
    { lat: 48.6716957, lng: 21.2948378 },
    { lat: 48.6717006, lng: 21.294631 },
    { lat: 48.671707, lng: 21.2941431 },
    { lat: 48.6717359, lng: 21.293393 },
    { lat: 48.6717439, lng: 21.2931774 },
    { lat: 48.6717456, lng: 21.2931238 },
    { lat: 48.6718439, lng: 21.2929532 },
    { lat: 48.6720896, lng: 21.2924856 },
    { lat: 48.6721315, lng: 21.2923485 },
    { lat: 48.6720964, lng: 21.2923043 },
    { lat: 48.6720799, lng: 21.2922809 },
    { lat: 48.6720635, lng: 21.2922578 },
    { lat: 48.6720528, lng: 21.2917615 },
    { lat: 48.6720561, lng: 21.2916865 },
    { lat: 48.6720628, lng: 21.2914638 },
    { lat: 48.6720709, lng: 21.2913258 },
    { lat: 48.6720484, lng: 21.2913609 },
    { lat: 48.6720087, lng: 21.2914231 },
    { lat: 48.6719442, lng: 21.2915241 },
    { lat: 48.6719388, lng: 21.2915323 },
    { lat: 48.6719066, lng: 21.291583 },
    { lat: 48.6718495, lng: 21.2916664 },
    { lat: 48.6718334, lng: 21.2916913 },
    { lat: 48.6717947, lng: 21.2917524 },
    { lat: 48.6717732, lng: 21.2917853 },
    { lat: 48.6715549, lng: 21.2921523 },
    { lat: 48.6714502, lng: 21.2923348 },
    { lat: 48.6714107, lng: 21.2924038 },
    { lat: 48.6713039, lng: 21.2925924 },
    { lat: 48.6710957, lng: 21.2929605 },
    { lat: 48.6710296, lng: 21.293078 },
    { lat: 48.6708225, lng: 21.2934432 },
    { lat: 48.6707552, lng: 21.2935615 },
    { lat: 48.6704575, lng: 21.2941672 },
    { lat: 48.6701661, lng: 21.2947587 },
    { lat: 48.6700927, lng: 21.2948962 },
    { lat: 48.6697787, lng: 21.2954842 },
    { lat: 48.6697318, lng: 21.2955701 },
    { lat: 48.6695477, lng: 21.2959148 },
    { lat: 48.6694582, lng: 21.2960819 },
    { lat: 48.6692601, lng: 21.2964493 },
    { lat: 48.6691324, lng: 21.2966879 },
    { lat: 48.6689546, lng: 21.2970171 },
    { lat: 48.6687149, lng: 21.2974625 },
    { lat: 48.6684989, lng: 21.2978663 },
    { lat: 48.66837, lng: 21.2981064 },
    { lat: 48.6678267, lng: 21.2991828 },
    { lat: 48.6676994, lng: 21.2994348 },
    { lat: 48.6674914, lng: 21.2998484 },
    { lat: 48.667344, lng: 21.3001399 },
    { lat: 48.6671992, lng: 21.3004505 },
    { lat: 48.6669731, lng: 21.3009312 },
    { lat: 48.6667544, lng: 21.3013989 },
    { lat: 48.6666414, lng: 21.30164 },
    { lat: 48.6663149, lng: 21.3023385 },
    { lat: 48.6658995, lng: 21.3030338 },
    { lat: 48.6656002, lng: 21.3036617 },
    { lat: 48.6651846, lng: 21.3043517 },
    { lat: 48.6652279, lng: 21.3044049 },
    { lat: 48.6652641, lng: 21.3044487 },
    { lat: 48.6651019, lng: 21.3046992 },
    { lat: 48.6650339, lng: 21.3047602 },
    { lat: 48.6648839, lng: 21.3048966 },
    { lat: 48.6646977, lng: 21.3050648 },
    { lat: 48.6645943, lng: 21.3052525 },
    { lat: 48.6641239, lng: 21.3061026 },
    { lat: 48.6636908, lng: 21.3068799 },
    { lat: 48.6636597, lng: 21.3069326 },
    { lat: 48.6636243, lng: 21.3069906 },
    { lat: 48.6634354, lng: 21.3072508 },
    { lat: 48.6631157, lng: 21.3076924 },
    { lat: 48.6627992, lng: 21.3081245 },
    { lat: 48.6627538, lng: 21.3081846 },
    { lat: 48.6626488, lng: 21.3083243 },
    { lat: 48.6626196, lng: 21.3083627 },
    { lat: 48.6626257, lng: 21.3084171 },
    { lat: 48.6626378, lng: 21.3085152 },
    { lat: 48.6627203, lng: 21.3086035 },
    { lat: 48.6628645, lng: 21.3087551 },
    { lat: 48.6628971, lng: 21.3087891 },
    { lat: 48.66288, lng: 21.3088198 },
    { lat: 48.662781, lng: 21.3090033 },
    { lat: 48.6627618, lng: 21.3090388 },
    { lat: 48.6623367, lng: 21.3098234 },
    { lat: 48.6618476, lng: 21.3107267 },
    { lat: 48.6614186, lng: 21.3115289 },
    { lat: 48.6611928, lng: 21.3119853 },
    { lat: 48.6608234, lng: 21.3127193 },
    { lat: 48.6605724, lng: 21.313307 },
    { lat: 48.6603648, lng: 21.313881 },
    { lat: 48.6602102, lng: 21.314346 },
    { lat: 48.6600803, lng: 21.3148455 },
    { lat: 48.6600601, lng: 21.3149231 },
    { lat: 48.6600005, lng: 21.3151493 },
    { lat: 48.6597475, lng: 21.3164861 },
    { lat: 48.6596924, lng: 21.3168608 },
    { lat: 48.6596544, lng: 21.3171603 },
    { lat: 48.6596175, lng: 21.3179474 },
    { lat: 48.6595547, lng: 21.3187684 },
    { lat: 48.6595177, lng: 21.3194046 },
    { lat: 48.6595012, lng: 21.3203782 },
    { lat: 48.6593231, lng: 21.3208113 },
    { lat: 48.6593312, lng: 21.321151 },
    { lat: 48.6591042, lng: 21.3211618 },
    { lat: 48.6590083, lng: 21.3213051 },
    { lat: 48.6589836, lng: 21.3213417 },
    { lat: 48.6589245, lng: 21.3213244 },
    { lat: 48.6585567, lng: 21.3212203 },
    { lat: 48.6581192, lng: 21.321304 },
    { lat: 48.6575576, lng: 21.3214119 },
    { lat: 48.6572726, lng: 21.3215505 },
    { lat: 48.6571884, lng: 21.3215919 },
    { lat: 48.6569277, lng: 21.3219759 },
    { lat: 48.6565623, lng: 21.3222852 },
    { lat: 48.6560447, lng: 21.3227318 },
    { lat: 48.655851, lng: 21.3230616 },
    { lat: 48.6556362, lng: 21.3234363 },
    { lat: 48.6553869, lng: 21.323901 },
    { lat: 48.6553491, lng: 21.323988 },
    { lat: 48.654856, lng: 21.3253169 },
    { lat: 48.6549266, lng: 21.3253804 },
    { lat: 48.6548962, lng: 21.3254565 },
    { lat: 48.6548415, lng: 21.3253934 },
    { lat: 48.6547718, lng: 21.3255826 },
    { lat: 48.6544033, lng: 21.326678 },
    { lat: 48.6537515, lng: 21.3274868 },
    { lat: 48.653455, lng: 21.3277294 },
    { lat: 48.6532166, lng: 21.3278847 },
    { lat: 48.6528943, lng: 21.328055 },
    { lat: 48.6526683, lng: 21.3281347 },
    { lat: 48.65232, lng: 21.328194 },
    { lat: 48.6519578, lng: 21.3281952 },
    { lat: 48.651431, lng: 21.328071 },
  ],
  KošicemestskáčasťVyšnéOpátske: [
    { lat: 48.724539, lng: 21.276321 },
    { lat: 48.724266, lng: 21.276229 },
    { lat: 48.724073, lng: 21.276126 },
    { lat: 48.724045, lng: 21.276136 },
    { lat: 48.723986, lng: 21.276157 },
    { lat: 48.723439, lng: 21.275952 },
    { lat: 48.72318, lng: 21.275841 },
    { lat: 48.722933, lng: 21.275716 },
    { lat: 48.722721, lng: 21.275535 },
    { lat: 48.722463, lng: 21.275307 },
    { lat: 48.722367, lng: 21.275174 },
    { lat: 48.722378, lng: 21.275155 },
    { lat: 48.722362, lng: 21.275141 },
    { lat: 48.722234, lng: 21.27502 },
    { lat: 48.722238, lng: 21.275001 },
    { lat: 48.722221, lng: 21.275006 },
    { lat: 48.722078, lng: 21.274874 },
    { lat: 48.722079, lng: 21.274853 },
    { lat: 48.722061, lng: 21.27486 },
    { lat: 48.721928, lng: 21.274736 },
    { lat: 48.721881, lng: 21.274693 },
    { lat: 48.721872, lng: 21.274685 },
    { lat: 48.721865, lng: 21.274702 },
    { lat: 48.721856, lng: 21.274725 },
    { lat: 48.721821, lng: 21.274807 },
    { lat: 48.721813, lng: 21.274827 },
    { lat: 48.721804, lng: 21.27482 },
    { lat: 48.721777, lng: 21.274802 },
    { lat: 48.721736, lng: 21.274772 },
    { lat: 48.7216031, lng: 21.2750409 },
    { lat: 48.7213256, lng: 21.2754952 },
    { lat: 48.7210756, lng: 21.2757836 },
    { lat: 48.7208944, lng: 21.2759631 },
    { lat: 48.7206394, lng: 21.2760886 },
    { lat: 48.7204574, lng: 21.2762083 },
    { lat: 48.7192181, lng: 21.2764225 },
    { lat: 48.7187323, lng: 21.276504 },
    { lat: 48.717175, lng: 21.2767694 },
    { lat: 48.7164674, lng: 21.276926 },
    { lat: 48.7150313, lng: 21.2771771 },
    { lat: 48.7145631, lng: 21.2772503 },
    { lat: 48.7139605, lng: 21.2773378 },
    { lat: 48.7131608, lng: 21.2774659 },
    { lat: 48.7128991, lng: 21.2775163 },
    { lat: 48.7114615, lng: 21.2777569 },
    { lat: 48.7108488, lng: 21.2778911 },
    { lat: 48.7101914, lng: 21.277955 },
    { lat: 48.7095154, lng: 21.2780434 },
    { lat: 48.7089374, lng: 21.2779475 },
    { lat: 48.7083369, lng: 21.2777391 },
    { lat: 48.7077519, lng: 21.2774541 },
    { lat: 48.7070593, lng: 21.2769974 },
    { lat: 48.7066533, lng: 21.2766785 },
    { lat: 48.7057957, lng: 21.2760165 },
    { lat: 48.7054946, lng: 21.2758488 },
    { lat: 48.7048232, lng: 21.2757142 },
    { lat: 48.7044691, lng: 21.2756564 },
    { lat: 48.7038528, lng: 21.2757067 },
    { lat: 48.7035845, lng: 21.2758025 },
    { lat: 48.7034111, lng: 21.2757598 },
    { lat: 48.7033294, lng: 21.2757399 },
    { lat: 48.7032239, lng: 21.2757138 },
    { lat: 48.7027794, lng: 21.2761519 },
    { lat: 48.7023997, lng: 21.2764682 },
    { lat: 48.7020853, lng: 21.2768191 },
    { lat: 48.7018439, lng: 21.277095 },
    { lat: 48.7012274, lng: 21.277688 },
    { lat: 48.7006136, lng: 21.2784022 },
    { lat: 48.7003865, lng: 21.2786326 },
    { lat: 48.70002, lng: 21.2790456 },
    { lat: 48.699719, lng: 21.2793969 },
    { lat: 48.6994693, lng: 21.279728 },
    { lat: 48.699379, lng: 21.2798661 },
    { lat: 48.6992295, lng: 21.2800939 },
    { lat: 48.6991886, lng: 21.2801566 },
    { lat: 48.6990924, lng: 21.2804284 },
    { lat: 48.6990774, lng: 21.2804551 },
    { lat: 48.6990364, lng: 21.2806226 },
    { lat: 48.6990594, lng: 21.2806744 },
    { lat: 48.6990469, lng: 21.2807045 },
    { lat: 48.6989995, lng: 21.2808147 },
    { lat: 48.6989786, lng: 21.280866 },
    { lat: 48.6989887, lng: 21.2808639 },
    { lat: 48.6990951, lng: 21.2808464 },
    { lat: 48.6991062, lng: 21.2808439 },
    { lat: 48.6994925, lng: 21.2807801 },
    { lat: 48.6995071, lng: 21.2807784 },
    { lat: 48.6995721, lng: 21.2807688 },
    { lat: 48.6996057, lng: 21.2807629 },
    { lat: 48.699637, lng: 21.2807579 },
    { lat: 48.6996286, lng: 21.2807774 },
    { lat: 48.6994576, lng: 21.2812172 },
    { lat: 48.699305, lng: 21.2818203 },
    { lat: 48.6992893, lng: 21.2818959 },
    { lat: 48.699258, lng: 21.2820491 },
    { lat: 48.6992474, lng: 21.282104 },
    { lat: 48.6991374, lng: 21.2827017 },
    { lat: 48.6990597, lng: 21.2831047 },
    { lat: 48.6989411, lng: 21.2835669 },
    { lat: 48.6989873, lng: 21.2836043 },
    { lat: 48.6987691, lng: 21.2841229 },
    { lat: 48.6986092, lng: 21.2844857 },
    { lat: 48.6982912, lng: 21.285052 },
    { lat: 48.698018, lng: 21.2854676 },
    { lat: 48.6977889, lng: 21.2856999 },
    { lat: 48.6976358, lng: 21.2858855 },
    { lat: 48.6971464, lng: 21.2864373 },
    { lat: 48.6966248, lng: 21.2870064 },
    { lat: 48.6956514, lng: 21.2880469 },
    { lat: 48.695389, lng: 21.2883275 },
    { lat: 48.6945336, lng: 21.2892743 },
    { lat: 48.6940283, lng: 21.2898265 },
    { lat: 48.6939913, lng: 21.2898679 },
    { lat: 48.6939412, lng: 21.2899255 },
    { lat: 48.6938108, lng: 21.2900736 },
    { lat: 48.6937259, lng: 21.2901689 },
    { lat: 48.6934019, lng: 21.2905367 },
    { lat: 48.693, lng: 21.2909741 },
    { lat: 48.692129, lng: 21.2920063 },
    { lat: 48.6917969, lng: 21.2922966 },
    { lat: 48.6916714, lng: 21.2923891 },
    { lat: 48.6915844, lng: 21.2924518 },
    { lat: 48.6914784, lng: 21.2925155 },
    { lat: 48.6913679, lng: 21.2925799 },
    { lat: 48.6912461, lng: 21.2926471 },
    { lat: 48.6908434, lng: 21.292837 },
    { lat: 48.6907449, lng: 21.2928574 },
    { lat: 48.6905576, lng: 21.2929111 },
    { lat: 48.6903873, lng: 21.2929365 },
    { lat: 48.6903244, lng: 21.2929409 },
    { lat: 48.6900725, lng: 21.2929449 },
    { lat: 48.6900056, lng: 21.2931127 },
    { lat: 48.6899635, lng: 21.2932111 },
    { lat: 48.6898765, lng: 21.293312 },
    { lat: 48.6897439, lng: 21.2933896 },
    { lat: 48.6896447, lng: 21.293383 },
    { lat: 48.6896353, lng: 21.2933698 },
    { lat: 48.689652, lng: 21.2933276 },
    { lat: 48.6897675, lng: 21.293279 },
    { lat: 48.689899, lng: 21.2931611 },
    { lat: 48.6898585, lng: 21.2930144 },
    { lat: 48.6896139, lng: 21.2929634 },
    { lat: 48.6892615, lng: 21.2932192 },
    { lat: 48.6887888, lng: 21.2933608 },
    { lat: 48.6884482, lng: 21.2934117 },
    { lat: 48.6882008, lng: 21.293565 },
    { lat: 48.6881086, lng: 21.2937894 },
    { lat: 48.6881071, lng: 21.2940338 },
    { lat: 48.6881993, lng: 21.2941816 },
    { lat: 48.6885596, lng: 21.2940365 },
    { lat: 48.6885942, lng: 21.2940258 },
    { lat: 48.6887492, lng: 21.2939776 },
    { lat: 48.6889614, lng: 21.2939992 },
    { lat: 48.6891157, lng: 21.294008 },
    { lat: 48.6894211, lng: 21.2940201 },
    { lat: 48.6897715, lng: 21.2940341 },
    { lat: 48.6900378, lng: 21.2940195 },
    { lat: 48.6900949, lng: 21.294267 },
    { lat: 48.6904432, lng: 21.294103 },
    { lat: 48.6906893, lng: 21.2940894 },
    { lat: 48.6907112, lng: 21.2944754 },
    { lat: 48.6907309, lng: 21.2944954 },
    { lat: 48.6908611, lng: 21.2942249 },
    { lat: 48.690791, lng: 21.2945446 },
    { lat: 48.6908322, lng: 21.2946408 },
    { lat: 48.6923783, lng: 21.2949667 },
    { lat: 48.6925881, lng: 21.2954251 },
    { lat: 48.6927808, lng: 21.2952856 },
    { lat: 48.6928227, lng: 21.2952556 },
    { lat: 48.6929867, lng: 21.2952848 },
    { lat: 48.6932074, lng: 21.2953235 },
    { lat: 48.6932888, lng: 21.2953385 },
    { lat: 48.6933687, lng: 21.295303 },
    { lat: 48.6934166, lng: 21.2952829 },
    { lat: 48.6935847, lng: 21.2951882 },
    { lat: 48.6936697, lng: 21.2951352 },
    { lat: 48.6937161, lng: 21.2951063 },
    { lat: 48.6937978, lng: 21.2951626 },
    { lat: 48.6939941, lng: 21.2949573 },
    { lat: 48.6941319, lng: 21.2947564 },
    { lat: 48.6943724, lng: 21.2944899 },
    { lat: 48.6943323, lng: 21.2944264 },
    { lat: 48.6943008, lng: 21.2944279 },
    { lat: 48.6942681, lng: 21.2943889 },
    { lat: 48.6943005, lng: 21.294309 },
    { lat: 48.6947725, lng: 21.2935515 },
    { lat: 48.6950612, lng: 21.2930953 },
    { lat: 48.695295, lng: 21.2930832 },
    { lat: 48.6953404, lng: 21.293058 },
    { lat: 48.6958372, lng: 21.2927832 },
    { lat: 48.6963973, lng: 21.2924748 },
    { lat: 48.6969595, lng: 21.292168 },
    { lat: 48.6976491, lng: 21.2917931 },
    { lat: 48.6979525, lng: 21.2917039 },
    { lat: 48.6979559, lng: 21.291703 },
    { lat: 48.6993645, lng: 21.2912886 },
    { lat: 48.6998531, lng: 21.2911468 },
    { lat: 48.6998866, lng: 21.2911367 },
    { lat: 48.7000383, lng: 21.2910933 },
    { lat: 48.7005321, lng: 21.2928511 },
    { lat: 48.7006025, lng: 21.2929453 },
    { lat: 48.7007849, lng: 21.2931338 },
    { lat: 48.7009118, lng: 21.2933786 },
    { lat: 48.7011217, lng: 21.2937302 },
    { lat: 48.7012849, lng: 21.2939155 },
    { lat: 48.7013145, lng: 21.2939283 },
    { lat: 48.7014674, lng: 21.2939953 },
    { lat: 48.7015985, lng: 21.2940517 },
    { lat: 48.7016558, lng: 21.2940848 },
    { lat: 48.7017536, lng: 21.2941171 },
    { lat: 48.7018985, lng: 21.2941819 },
    { lat: 48.7020205, lng: 21.2942367 },
    { lat: 48.7021347, lng: 21.2942886 },
    { lat: 48.7022374, lng: 21.2943345 },
    { lat: 48.702363, lng: 21.2943917 },
    { lat: 48.7024132, lng: 21.2944138 },
    { lat: 48.7025601, lng: 21.2944364 },
    { lat: 48.7028659, lng: 21.2944293 },
    { lat: 48.7029671, lng: 21.2944236 },
    { lat: 48.7030728, lng: 21.294418 },
    { lat: 48.7031772, lng: 21.2944125 },
    { lat: 48.7032791, lng: 21.2943961 },
    { lat: 48.7035531, lng: 21.2942659 },
    { lat: 48.7035603, lng: 21.2942839 },
    { lat: 48.7035978, lng: 21.294329 },
    { lat: 48.7036874, lng: 21.2943908 },
    { lat: 48.7037768, lng: 21.2944419 },
    { lat: 48.7038757, lng: 21.2944742 },
    { lat: 48.7038793, lng: 21.2944818 },
    { lat: 48.7041376, lng: 21.2945742 },
    { lat: 48.704177, lng: 21.2945779 },
    { lat: 48.7042757, lng: 21.2945995 },
    { lat: 48.7044695, lng: 21.2946444 },
    { lat: 48.7045058, lng: 21.2946561 },
    { lat: 48.7047818, lng: 21.2947391 },
    { lat: 48.7048977, lng: 21.2947738 },
    { lat: 48.7050176, lng: 21.2947935 },
    { lat: 48.7050436, lng: 21.2947981 },
    { lat: 48.7052415, lng: 21.2948306 },
    { lat: 48.7053092, lng: 21.2949079 },
    { lat: 48.7054828, lng: 21.2952492 },
    { lat: 48.705671, lng: 21.2953686 },
    { lat: 48.7057407, lng: 21.2953666 },
    { lat: 48.7059701, lng: 21.2953602 },
    { lat: 48.7061392, lng: 21.2952622 },
    { lat: 48.7064346, lng: 21.2949843 },
    { lat: 48.7066585, lng: 21.294834 },
    { lat: 48.7068232, lng: 21.2947013 },
    { lat: 48.7069688, lng: 21.2945308 },
    { lat: 48.7072537, lng: 21.2941322 },
    { lat: 48.7074599, lng: 21.2939183 },
    { lat: 48.7078563, lng: 21.2933739 },
    { lat: 48.7079162, lng: 21.2932507 },
    { lat: 48.7079669, lng: 21.2932404 },
    { lat: 48.7080344, lng: 21.2932386 },
    { lat: 48.7088586, lng: 21.2938783 },
    { lat: 48.7092993, lng: 21.2964034 },
    { lat: 48.709316, lng: 21.2966175 },
    { lat: 48.7092964, lng: 21.2967133 },
    { lat: 48.7093209, lng: 21.296889 },
    { lat: 48.7092694, lng: 21.2971178 },
    { lat: 48.7090534, lng: 21.2974536 },
    { lat: 48.709218, lng: 21.2978319 },
    { lat: 48.7092826, lng: 21.2982151 },
    { lat: 48.7094306, lng: 21.2984549 },
    { lat: 48.7095387, lng: 21.2989383 },
    { lat: 48.7095715, lng: 21.2993458 },
    { lat: 48.7099241, lng: 21.30002 },
    { lat: 48.7101308, lng: 21.3012659 },
    { lat: 48.710448, lng: 21.301774 },
    { lat: 48.7106547, lng: 21.301983 },
    { lat: 48.7108339, lng: 21.3022902 },
    { lat: 48.7113219, lng: 21.3029753 },
    { lat: 48.7119204, lng: 21.3035999 },
    { lat: 48.712355, lng: 21.303162 },
    { lat: 48.71256, lng: 21.302867 },
    { lat: 48.712751, lng: 21.302652 },
    { lat: 48.712846, lng: 21.302384 },
    { lat: 48.713104, lng: 21.301825 },
    { lat: 48.713337, lng: 21.301586 },
    { lat: 48.713441, lng: 21.301422 },
    { lat: 48.713869, lng: 21.30039 },
    { lat: 48.714088, lng: 21.29979 },
    { lat: 48.714425, lng: 21.29898 },
    { lat: 48.715221, lng: 21.298242 },
    { lat: 48.715127, lng: 21.297859 },
    { lat: 48.715066, lng: 21.297199 },
    { lat: 48.715024, lng: 21.295968 },
    { lat: 48.714774, lng: 21.29606 },
    { lat: 48.714166, lng: 21.295929 },
    { lat: 48.71396, lng: 21.295136 },
    { lat: 48.713887, lng: 21.294544 },
    { lat: 48.713588, lng: 21.294409 },
    { lat: 48.713503, lng: 21.293596 },
    { lat: 48.713992, lng: 21.293683 },
    { lat: 48.714074, lng: 21.293697 },
    { lat: 48.714588, lng: 21.293704 },
    { lat: 48.715686, lng: 21.293559 },
    { lat: 48.716689, lng: 21.293582 },
    { lat: 48.717098, lng: 21.293592 },
    { lat: 48.71747, lng: 21.293656 },
    { lat: 48.717486, lng: 21.293659 },
    { lat: 48.717634, lng: 21.293624 },
    { lat: 48.717934, lng: 21.293733 },
    { lat: 48.718375, lng: 21.293885 },
    { lat: 48.718945, lng: 21.294101 },
    { lat: 48.719396, lng: 21.294332 },
    { lat: 48.719444, lng: 21.294344 },
    { lat: 48.720107, lng: 21.294797 },
    { lat: 48.720921, lng: 21.295465 },
    { lat: 48.7214888, lng: 21.295931 },
    { lat: 48.721605, lng: 21.296043 },
    { lat: 48.721722, lng: 21.296108 },
    { lat: 48.721907, lng: 21.296113 },
    { lat: 48.721961, lng: 21.296047 },
    { lat: 48.7220815, lng: 21.2959228 },
    { lat: 48.722406, lng: 21.295502 },
    { lat: 48.722562, lng: 21.295286 },
    { lat: 48.72285, lng: 21.294692 },
    { lat: 48.723073, lng: 21.294299 },
    { lat: 48.723254, lng: 21.294041 },
    { lat: 48.723528, lng: 21.293671 },
    { lat: 48.723656, lng: 21.293537 },
    { lat: 48.72372, lng: 21.293459 },
    { lat: 48.723857, lng: 21.293225 },
    { lat: 48.723858, lng: 21.293025 },
    { lat: 48.723711, lng: 21.292982 },
    { lat: 48.723234, lng: 21.292961 },
    { lat: 48.722867, lng: 21.292818 },
    { lat: 48.722605, lng: 21.2927 },
    { lat: 48.722072, lng: 21.292562 },
    { lat: 48.721313, lng: 21.291738 },
    { lat: 48.721215, lng: 21.290979 },
    { lat: 48.721104, lng: 21.290688 },
    { lat: 48.72119, lng: 21.289923 },
    { lat: 48.721331, lng: 21.289561 },
    { lat: 48.721657, lng: 21.289538 },
    { lat: 48.722374, lng: 21.289268 },
    { lat: 48.722647, lng: 21.289028 },
    { lat: 48.722871, lng: 21.288829 },
    { lat: 48.723007, lng: 21.288534 },
    { lat: 48.723318, lng: 21.288017 },
    { lat: 48.723479, lng: 21.287513 },
    { lat: 48.723612, lng: 21.287029 },
    { lat: 48.72381, lng: 21.286259 },
    { lat: 48.723997, lng: 21.285859 },
    { lat: 48.724241, lng: 21.285053 },
    { lat: 48.724505, lng: 21.284493 },
    { lat: 48.724871, lng: 21.284057 },
    { lat: 48.725284, lng: 21.283544 },
    { lat: 48.725751, lng: 21.283052 },
    { lat: 48.725934, lng: 21.282652 },
    { lat: 48.726113, lng: 21.282395 },
    { lat: 48.72616, lng: 21.282436 },
    { lat: 48.726265, lng: 21.282164 },
    { lat: 48.726396, lng: 21.281965 },
    { lat: 48.726528, lng: 21.281772 },
    { lat: 48.726646, lng: 21.281373 },
    { lat: 48.726652, lng: 21.281126 },
    { lat: 48.726576, lng: 21.281105 },
    { lat: 48.72655, lng: 21.280995 },
    { lat: 48.72665, lng: 21.280617 },
    { lat: 48.726615, lng: 21.280408 },
    { lat: 48.726592, lng: 21.280308 },
    { lat: 48.726532, lng: 21.280084 },
    { lat: 48.726494, lng: 21.279962 },
    { lat: 48.726472, lng: 21.279932 },
    { lat: 48.726402, lng: 21.279798 },
    { lat: 48.726382, lng: 21.279761 },
    { lat: 48.726399, lng: 21.27974 },
    { lat: 48.726323, lng: 21.279556 },
    { lat: 48.726311, lng: 21.279529 },
    { lat: 48.726293, lng: 21.279484 },
    { lat: 48.726165, lng: 21.279226 },
    { lat: 48.726118, lng: 21.279276 },
    { lat: 48.726101, lng: 21.279279 },
    { lat: 48.72609, lng: 21.279267 },
    { lat: 48.726038, lng: 21.279211 },
    { lat: 48.726025, lng: 21.279198 },
    { lat: 48.725993, lng: 21.279057 },
    { lat: 48.725979, lng: 21.279047 },
    { lat: 48.725863, lng: 21.278968 },
    { lat: 48.725856, lng: 21.27893 },
    { lat: 48.725854, lng: 21.278888 },
    { lat: 48.725848, lng: 21.278876 },
    { lat: 48.725787, lng: 21.278752 },
    { lat: 48.725743, lng: 21.278662 },
    { lat: 48.725723, lng: 21.278622 },
    { lat: 48.725666, lng: 21.278506 },
    { lat: 48.725591, lng: 21.278354 },
    { lat: 48.725572, lng: 21.278313 },
    { lat: 48.725545, lng: 21.27826 },
    { lat: 48.725518, lng: 21.278201 },
    { lat: 48.725442, lng: 21.278046 },
    { lat: 48.725395, lng: 21.277948 },
    { lat: 48.725353, lng: 21.277861 },
    { lat: 48.725343, lng: 21.277841 },
    { lat: 48.725294, lng: 21.277741 },
    { lat: 48.72528, lng: 21.27771 },
    { lat: 48.725272, lng: 21.277695 },
    { lat: 48.725214, lng: 21.27758 },
    { lat: 48.725205, lng: 21.277563 },
    { lat: 48.725203, lng: 21.277558 },
    { lat: 48.72516, lng: 21.277472 },
    { lat: 48.725136, lng: 21.277423 },
    { lat: 48.725085, lng: 21.277317 },
    { lat: 48.725009, lng: 21.277159 },
    { lat: 48.724919, lng: 21.277024 },
    { lat: 48.724899, lng: 21.277043 },
    { lat: 48.724858, lng: 21.276993 },
    { lat: 48.724848, lng: 21.276979 },
    { lat: 48.72471, lng: 21.27683 },
    { lat: 48.724469, lng: 21.276652 },
    { lat: 48.724479, lng: 21.276601 },
    { lat: 48.724485, lng: 21.276574 },
    { lat: 48.724506, lng: 21.276479 },
    { lat: 48.724532, lng: 21.276356 },
    { lat: 48.724539, lng: 21.276321 },
  ],
  KošicemestskáčasťBarca: [
    { lat: 48.650812, lng: 21.314231 },
    { lat: 48.650845, lng: 21.315057 },
    { lat: 48.650992, lng: 21.318725 },
    { lat: 48.651001, lng: 21.318967 },
    { lat: 48.651111, lng: 21.318773 },
    { lat: 48.651945, lng: 21.317567 },
    { lat: 48.652761, lng: 21.317311 },
    { lat: 48.652913, lng: 21.317926 },
    { lat: 48.652416, lng: 21.318895 },
    { lat: 48.651103, lng: 21.321448 },
    { lat: 48.651113, lng: 21.321571 },
    { lat: 48.65112, lng: 21.321663 },
    { lat: 48.651132, lng: 21.321789 },
    { lat: 48.651141, lng: 21.321906 },
    { lat: 48.651431, lng: 21.328071 },
    { lat: 48.6519578, lng: 21.3281952 },
    { lat: 48.65232, lng: 21.328194 },
    { lat: 48.6526683, lng: 21.3281347 },
    { lat: 48.6528943, lng: 21.328055 },
    { lat: 48.6532166, lng: 21.3278847 },
    { lat: 48.653455, lng: 21.3277294 },
    { lat: 48.6537515, lng: 21.3274868 },
    { lat: 48.6544033, lng: 21.326678 },
    { lat: 48.6547718, lng: 21.3255826 },
    { lat: 48.6548415, lng: 21.3253934 },
    { lat: 48.6548962, lng: 21.3254565 },
    { lat: 48.6549266, lng: 21.3253804 },
    { lat: 48.654856, lng: 21.3253169 },
    { lat: 48.6553491, lng: 21.323988 },
    { lat: 48.6553869, lng: 21.323901 },
    { lat: 48.6556362, lng: 21.3234363 },
    { lat: 48.655851, lng: 21.3230616 },
    { lat: 48.6560447, lng: 21.3227318 },
    { lat: 48.6565623, lng: 21.3222852 },
    { lat: 48.6569277, lng: 21.3219759 },
    { lat: 48.6571884, lng: 21.3215919 },
    { lat: 48.6572726, lng: 21.3215505 },
    { lat: 48.6575576, lng: 21.3214119 },
    { lat: 48.6581192, lng: 21.321304 },
    { lat: 48.6585567, lng: 21.3212203 },
    { lat: 48.6589245, lng: 21.3213244 },
    { lat: 48.6589836, lng: 21.3213417 },
    { lat: 48.6590083, lng: 21.3213051 },
    { lat: 48.6591042, lng: 21.3211618 },
    { lat: 48.6593312, lng: 21.321151 },
    { lat: 48.6593231, lng: 21.3208113 },
    { lat: 48.6595012, lng: 21.3203782 },
    { lat: 48.6595177, lng: 21.3194046 },
    { lat: 48.6595547, lng: 21.3187684 },
    { lat: 48.6596175, lng: 21.3179474 },
    { lat: 48.6596544, lng: 21.3171603 },
    { lat: 48.6596924, lng: 21.3168608 },
    { lat: 48.6597475, lng: 21.3164861 },
    { lat: 48.6600005, lng: 21.3151493 },
    { lat: 48.6600601, lng: 21.3149231 },
    { lat: 48.6600803, lng: 21.3148455 },
    { lat: 48.6602102, lng: 21.314346 },
    { lat: 48.6603648, lng: 21.313881 },
    { lat: 48.6605724, lng: 21.313307 },
    { lat: 48.6608234, lng: 21.3127193 },
    { lat: 48.6611928, lng: 21.3119853 },
    { lat: 48.6614186, lng: 21.3115289 },
    { lat: 48.6618476, lng: 21.3107267 },
    { lat: 48.6623367, lng: 21.3098234 },
    { lat: 48.6627618, lng: 21.3090388 },
    { lat: 48.662781, lng: 21.3090033 },
    { lat: 48.66288, lng: 21.3088198 },
    { lat: 48.6628971, lng: 21.3087891 },
    { lat: 48.6628645, lng: 21.3087551 },
    { lat: 48.6627203, lng: 21.3086035 },
    { lat: 48.6626378, lng: 21.3085152 },
    { lat: 48.6626257, lng: 21.3084171 },
    { lat: 48.6626196, lng: 21.3083627 },
    { lat: 48.6626488, lng: 21.3083243 },
    { lat: 48.6627538, lng: 21.3081846 },
    { lat: 48.6627992, lng: 21.3081245 },
    { lat: 48.6631157, lng: 21.3076924 },
    { lat: 48.6634354, lng: 21.3072508 },
    { lat: 48.6636243, lng: 21.3069906 },
    { lat: 48.6636597, lng: 21.3069326 },
    { lat: 48.6636908, lng: 21.3068799 },
    { lat: 48.6641239, lng: 21.3061026 },
    { lat: 48.6645943, lng: 21.3052525 },
    { lat: 48.6646977, lng: 21.3050648 },
    { lat: 48.6648839, lng: 21.3048966 },
    { lat: 48.6650339, lng: 21.3047602 },
    { lat: 48.6651019, lng: 21.3046992 },
    { lat: 48.6652641, lng: 21.3044487 },
    { lat: 48.6652279, lng: 21.3044049 },
    { lat: 48.6651846, lng: 21.3043517 },
    { lat: 48.6656002, lng: 21.3036617 },
    { lat: 48.6658995, lng: 21.3030338 },
    { lat: 48.6663149, lng: 21.3023385 },
    { lat: 48.6666414, lng: 21.30164 },
    { lat: 48.6667544, lng: 21.3013989 },
    { lat: 48.6669731, lng: 21.3009312 },
    { lat: 48.6671992, lng: 21.3004505 },
    { lat: 48.667344, lng: 21.3001399 },
    { lat: 48.6674914, lng: 21.2998484 },
    { lat: 48.6676994, lng: 21.2994348 },
    { lat: 48.6678267, lng: 21.2991828 },
    { lat: 48.66837, lng: 21.2981064 },
    { lat: 48.6684989, lng: 21.2978663 },
    { lat: 48.6687149, lng: 21.2974625 },
    { lat: 48.6689546, lng: 21.2970171 },
    { lat: 48.6691324, lng: 21.2966879 },
    { lat: 48.6692601, lng: 21.2964493 },
    { lat: 48.6694582, lng: 21.2960819 },
    { lat: 48.6695477, lng: 21.2959148 },
    { lat: 48.6697318, lng: 21.2955701 },
    { lat: 48.6697787, lng: 21.2954842 },
    { lat: 48.6700927, lng: 21.2948962 },
    { lat: 48.6701661, lng: 21.2947587 },
    { lat: 48.6704575, lng: 21.2941672 },
    { lat: 48.6707552, lng: 21.2935615 },
    { lat: 48.6708225, lng: 21.2934432 },
    { lat: 48.6710296, lng: 21.293078 },
    { lat: 48.6710957, lng: 21.2929605 },
    { lat: 48.6713039, lng: 21.2925924 },
    { lat: 48.6714107, lng: 21.2924038 },
    { lat: 48.6714502, lng: 21.2923348 },
    { lat: 48.6715549, lng: 21.2921523 },
    { lat: 48.6717732, lng: 21.2917853 },
    { lat: 48.6717947, lng: 21.2917524 },
    { lat: 48.6718334, lng: 21.2916913 },
    { lat: 48.6718495, lng: 21.2916664 },
    { lat: 48.6719066, lng: 21.291583 },
    { lat: 48.6719388, lng: 21.2915323 },
    { lat: 48.6719442, lng: 21.2915241 },
    { lat: 48.6720087, lng: 21.2914231 },
    { lat: 48.6720484, lng: 21.2913609 },
    { lat: 48.6720709, lng: 21.2913258 },
    { lat: 48.6720796, lng: 21.2913117 },
    { lat: 48.6722576, lng: 21.2912117 },
    { lat: 48.6724798, lng: 21.2907852 },
    { lat: 48.6726493, lng: 21.290443 },
    { lat: 48.6727992, lng: 21.2901541 },
    { lat: 48.6730615, lng: 21.289643 },
    { lat: 48.6731901, lng: 21.2893245 },
    { lat: 48.6732354, lng: 21.2894047 },
    { lat: 48.6732674, lng: 21.2894609 },
    { lat: 48.6732913, lng: 21.2895016 },
    { lat: 48.673339, lng: 21.2895548 },
    { lat: 48.6735895, lng: 21.289136 },
    { lat: 48.6743405, lng: 21.2877533 },
    { lat: 48.6742936, lng: 21.2876544 },
    { lat: 48.6746457, lng: 21.2868823 },
    { lat: 48.6747317, lng: 21.2868938 },
    { lat: 48.6748733, lng: 21.2866332 },
    { lat: 48.6751014, lng: 21.2862145 },
    { lat: 48.6751769, lng: 21.2860743 },
    { lat: 48.6757074, lng: 21.2849881 },
    { lat: 48.6759378, lng: 21.2845704 },
    { lat: 48.6764218, lng: 21.2836943 },
    { lat: 48.6765572, lng: 21.2834488 },
    { lat: 48.6768599, lng: 21.2829002 },
    { lat: 48.6772999, lng: 21.2821266 },
    { lat: 48.6772795, lng: 21.2820881 },
    { lat: 48.6780707, lng: 21.2808473 },
    { lat: 48.6783364, lng: 21.2804836 },
    { lat: 48.6786983, lng: 21.2799882 },
    { lat: 48.6786865, lng: 21.2799363 },
    { lat: 48.678758, lng: 21.2798426 },
    { lat: 48.6788058, lng: 21.2797869 },
    { lat: 48.6788448, lng: 21.2797422 },
    { lat: 48.6791597, lng: 21.2793675 },
    { lat: 48.6794929, lng: 21.2789689 },
    { lat: 48.6797991, lng: 21.2786034 },
    { lat: 48.6800975, lng: 21.2782456 },
    { lat: 48.6803787, lng: 21.2779104 },
    { lat: 48.6806349, lng: 21.2776046 },
    { lat: 48.6809324, lng: 21.2772497 },
    { lat: 48.680974, lng: 21.2772101 },
    { lat: 48.6813128, lng: 21.2768862 },
    { lat: 48.6816191, lng: 21.2765976 },
    { lat: 48.6817857, lng: 21.276452 },
    { lat: 48.681915, lng: 21.2763379 },
    { lat: 48.6819215, lng: 21.2763328 },
    { lat: 48.6820235, lng: 21.2762451 },
    { lat: 48.6821199, lng: 21.2761615 },
    { lat: 48.6822887, lng: 21.2760154 },
    { lat: 48.6822146, lng: 21.2757267 },
    { lat: 48.6820368, lng: 21.2757998 },
    { lat: 48.6820301, lng: 21.2758031 },
    { lat: 48.6812557, lng: 21.276127 },
    { lat: 48.68095, lng: 21.2762499 },
    { lat: 48.6809235, lng: 21.2760816 },
    { lat: 48.6808028, lng: 21.27534 },
    { lat: 48.6807846, lng: 21.2752234 },
    { lat: 48.6814703, lng: 21.2749725 },
    { lat: 48.6814627, lng: 21.2749168 },
    { lat: 48.6812905, lng: 21.2749161 },
    { lat: 48.6810258, lng: 21.2725639 },
    { lat: 48.680661, lng: 21.272655 },
    { lat: 48.6806043, lng: 21.2724861 },
    { lat: 48.6805264, lng: 21.2718584 },
    { lat: 48.6807017, lng: 21.2718155 },
    { lat: 48.6807265, lng: 21.2717967 },
    { lat: 48.6807424, lng: 21.2717511 },
    { lat: 48.6804644, lng: 21.2693854 },
    { lat: 48.6804998, lng: 21.2692755 },
    { lat: 48.6804644, lng: 21.2690367 },
    { lat: 48.6802784, lng: 21.2690904 },
    { lat: 48.6803316, lng: 21.2689268 },
    { lat: 48.6802536, lng: 21.2687927 },
    { lat: 48.6798785, lng: 21.2688608 },
    { lat: 48.6798843, lng: 21.2688326 },
    { lat: 48.6798938, lng: 21.2688113 },
    { lat: 48.6798877, lng: 21.2687939 },
    { lat: 48.6798858, lng: 21.2687293 },
    { lat: 48.6798813, lng: 21.268585 },
    { lat: 48.6798686, lng: 21.2685001 },
    { lat: 48.6798483, lng: 21.2682462 },
    { lat: 48.6798712, lng: 21.2682218 },
    { lat: 48.6798355, lng: 21.2681958 },
    { lat: 48.6797944, lng: 21.268142 },
    { lat: 48.6797743, lng: 21.2681082 },
    { lat: 48.6797611, lng: 21.2680814 },
    { lat: 48.6797488, lng: 21.2679398 },
    { lat: 48.6797383, lng: 21.2678173 },
    { lat: 48.6797264, lng: 21.2676857 },
    { lat: 48.679713, lng: 21.2675431 },
    { lat: 48.6796878, lng: 21.2672721 },
    { lat: 48.6796825, lng: 21.267213 },
    { lat: 48.6796094, lng: 21.2668499 },
    { lat: 48.679604, lng: 21.2668179 },
    { lat: 48.6795747, lng: 21.2666703 },
    { lat: 48.6795721, lng: 21.2666609 },
    { lat: 48.6797908, lng: 21.2666017 },
    { lat: 48.6799438, lng: 21.2665614 },
    { lat: 48.6802027, lng: 21.2664926 },
    { lat: 48.6803769, lng: 21.2664469 },
    { lat: 48.6805209, lng: 21.2664083 },
    { lat: 48.6806638, lng: 21.2663705 },
    { lat: 48.6808245, lng: 21.2663278 },
    { lat: 48.6809795, lng: 21.266282 },
    { lat: 48.6810643, lng: 21.2662604 },
    { lat: 48.6812178, lng: 21.2662353 },
    { lat: 48.6812491, lng: 21.2662296 },
    { lat: 48.681257, lng: 21.266228 },
    { lat: 48.6814553, lng: 21.2661645 },
    { lat: 48.6816855, lng: 21.2661102 },
    { lat: 48.6817358, lng: 21.2660978 },
    { lat: 48.6818213, lng: 21.2660604 },
    { lat: 48.6819259, lng: 21.2660238 },
    { lat: 48.6819617, lng: 21.2660156 },
    { lat: 48.6820275, lng: 21.2659968 },
    { lat: 48.6821725, lng: 21.2659559 },
    { lat: 48.6822606, lng: 21.2659291 },
    { lat: 48.6823487, lng: 21.2659022 },
    { lat: 48.6826027, lng: 21.2658206 },
    { lat: 48.6826501, lng: 21.2657108 },
    { lat: 48.6827519, lng: 21.2654771 },
    { lat: 48.6827814, lng: 21.2654088 },
    { lat: 48.682786, lng: 21.2653985 },
    { lat: 48.6828616, lng: 21.2652272 },
    { lat: 48.6828645, lng: 21.2652206 },
    { lat: 48.6828813, lng: 21.2651824 },
    { lat: 48.68295, lng: 21.265038 },
    { lat: 48.6829542, lng: 21.2649198 },
    { lat: 48.6829644, lng: 21.2646292 },
    { lat: 48.6829908, lng: 21.2646483 },
    { lat: 48.683034, lng: 21.2646613 },
    { lat: 48.6832287, lng: 21.2646594 },
    { lat: 48.6833012, lng: 21.2646404 },
    { lat: 48.6832771, lng: 21.2643692 },
    { lat: 48.6835234, lng: 21.2643538 },
    { lat: 48.6835108, lng: 21.2640661 },
    { lat: 48.6834947, lng: 21.2639084 },
    { lat: 48.6834803, lng: 21.2637684 },
    { lat: 48.6834557, lng: 21.2635201 },
    { lat: 48.6834824, lng: 21.2633651 },
    { lat: 48.6835339, lng: 21.2630644 },
    { lat: 48.6835694, lng: 21.2628625 },
    { lat: 48.6838582, lng: 21.2629205 },
    { lat: 48.6844348, lng: 21.263043 },
    { lat: 48.6846205, lng: 21.2630805 },
    { lat: 48.6847032, lng: 21.2630968 },
    { lat: 48.6848943, lng: 21.2631307 },
    { lat: 48.6850147, lng: 21.2630948 },
    { lat: 48.685076, lng: 21.2630737 },
    { lat: 48.6851074, lng: 21.2630669 },
    { lat: 48.6851099, lng: 21.2630806 },
    { lat: 48.6851157, lng: 21.2631176 },
    { lat: 48.6851795, lng: 21.2635092 },
    { lat: 48.6852432, lng: 21.2638964 },
    { lat: 48.6852529, lng: 21.2639186 },
    { lat: 48.6852711, lng: 21.2640355 },
    { lat: 48.6852475, lng: 21.2641451 },
    { lat: 48.6852852, lng: 21.264125 },
    { lat: 48.6853372, lng: 21.2640978 },
    { lat: 48.6853537, lng: 21.2640885 },
    { lat: 48.6853762, lng: 21.2641207 },
    { lat: 48.6854444, lng: 21.2641444 },
    { lat: 48.6855741, lng: 21.2640804 },
    { lat: 48.6855975, lng: 21.2640692 },
    { lat: 48.6856971, lng: 21.2640201 },
    { lat: 48.6857083, lng: 21.2640147 },
    { lat: 48.6857052, lng: 21.2639889 },
    { lat: 48.6857686, lng: 21.2639618 },
    { lat: 48.685894, lng: 21.263908 },
    { lat: 48.6860885, lng: 21.2638248 },
    { lat: 48.686114, lng: 21.2638145 },
    { lat: 48.6863118, lng: 21.2637333 },
    { lat: 48.6864785, lng: 21.2636649 },
    { lat: 48.6864986, lng: 21.2636557 },
    { lat: 48.6866739, lng: 21.2635753 },
    { lat: 48.6867595, lng: 21.2635475 },
    { lat: 48.6870398, lng: 21.2634348 },
    { lat: 48.6870143, lng: 21.2632674 },
    { lat: 48.6871715, lng: 21.2632193 },
    { lat: 48.6873308, lng: 21.2631675 },
    { lat: 48.687489, lng: 21.2631136 },
    { lat: 48.6876571, lng: 21.2630562 },
    { lat: 48.6877582, lng: 21.2630128 },
    { lat: 48.6877781, lng: 21.2630035 },
    { lat: 48.6877768, lng: 21.2629954 },
    { lat: 48.6877398, lng: 21.2627811 },
    { lat: 48.6876717, lng: 21.262468 },
    { lat: 48.6877461, lng: 21.2624598 },
    { lat: 48.6877943, lng: 21.2624554 },
    { lat: 48.687883, lng: 21.2624482 },
    { lat: 48.6880229, lng: 21.2624316 },
    { lat: 48.6881896, lng: 21.2623669 },
    { lat: 48.6881883, lng: 21.2622922 },
    { lat: 48.6881488, lng: 21.2603832 },
    { lat: 48.688147, lng: 21.2602955 },
    { lat: 48.6881494, lng: 21.2600076 },
    { lat: 48.6881518, lng: 21.2599073 },
    { lat: 48.6881582, lng: 21.2597861 },
    { lat: 48.6881874, lng: 21.2594909 },
    { lat: 48.6882109, lng: 21.2593407 },
    { lat: 48.6882455, lng: 21.2591477 },
    { lat: 48.6882891, lng: 21.258957 },
    { lat: 48.6882989, lng: 21.2589135 },
    { lat: 48.6883039, lng: 21.258889 },
    { lat: 48.6883167, lng: 21.25883 },
    { lat: 48.6883327, lng: 21.2587658 },
    { lat: 48.688346, lng: 21.2587214 },
    { lat: 48.6884173, lng: 21.2585198 },
    { lat: 48.6884982, lng: 21.2583314 },
    { lat: 48.6886004, lng: 21.2581443 },
    { lat: 48.6886315, lng: 21.2580944 },
    { lat: 48.688683, lng: 21.258012 },
    { lat: 48.688756, lng: 21.2579004 },
    { lat: 48.6886922, lng: 21.2578009 },
    { lat: 48.6886194, lng: 21.2577017 },
    { lat: 48.6884713, lng: 21.257498 },
    { lat: 48.6882721, lng: 21.2572421 },
    { lat: 48.6881781, lng: 21.2571127 },
    { lat: 48.688083, lng: 21.2569894 },
    { lat: 48.6880266, lng: 21.2569111 },
    { lat: 48.6879886, lng: 21.2568468 },
    { lat: 48.6879444, lng: 21.2567616 },
    { lat: 48.6879083, lng: 21.256684 },
    { lat: 48.6878723, lng: 21.2565765 },
    { lat: 48.6878523, lng: 21.2565137 },
    { lat: 48.6878561, lng: 21.2564928 },
    { lat: 48.6878285, lng: 21.2564335 },
    { lat: 48.6878013, lng: 21.2563948 },
    { lat: 48.6877817, lng: 21.2563787 },
    { lat: 48.6877658, lng: 21.2563738 },
    { lat: 48.6877508, lng: 21.2566534 },
    { lat: 48.6877269, lng: 21.2566451 },
    { lat: 48.6877163, lng: 21.2567025 },
    { lat: 48.6876665, lng: 21.2570187 },
    { lat: 48.6876738, lng: 21.2570378 },
    { lat: 48.6876697, lng: 21.2570502 },
    { lat: 48.6876469, lng: 21.2570803 },
    { lat: 48.6876247, lng: 21.2571602 },
    { lat: 48.6876016, lng: 21.2571761 },
    { lat: 48.687597, lng: 21.2571725 },
    { lat: 48.6875635, lng: 21.257146 },
    { lat: 48.6875381, lng: 21.2571264 },
    { lat: 48.6872827, lng: 21.2570925 },
    { lat: 48.6870362, lng: 21.2569453 },
    { lat: 48.6869278, lng: 21.256824 },
    { lat: 48.6868407, lng: 21.2567005 },
    { lat: 48.6868051, lng: 21.2565659 },
    { lat: 48.6867857, lng: 21.256486 },
    { lat: 48.6867621, lng: 21.2563762 },
    { lat: 48.6866912, lng: 21.2560525 },
    { lat: 48.6866297, lng: 21.2560661 },
    { lat: 48.6865069, lng: 21.2560929 },
    { lat: 48.6864359, lng: 21.2560944 },
    { lat: 48.6863285, lng: 21.2557126 },
    { lat: 48.6860429, lng: 21.2556484 },
    { lat: 48.6859749, lng: 21.2556347 },
    { lat: 48.6860138, lng: 21.2553235 },
    { lat: 48.6850755, lng: 21.2551895 },
    { lat: 48.6850835, lng: 21.255013 },
    { lat: 48.685084, lng: 21.2548452 },
    { lat: 48.6851646, lng: 21.2543594 },
    { lat: 48.6852934, lng: 21.2539774 },
    { lat: 48.6853471, lng: 21.2537849 },
    { lat: 48.6853529, lng: 21.2532766 },
    { lat: 48.6853412, lng: 21.2530495 },
    { lat: 48.6855261, lng: 21.2527686 },
    { lat: 48.6856554, lng: 21.2525817 },
    { lat: 48.685686, lng: 21.2524781 },
    { lat: 48.6857021, lng: 21.2523815 },
    { lat: 48.6857061, lng: 21.2519641 },
    { lat: 48.6858126, lng: 21.251952 },
    { lat: 48.685918, lng: 21.2516852 },
    { lat: 48.6859358, lng: 21.2516078 },
    { lat: 48.6859355, lng: 21.251414 },
    { lat: 48.6859699, lng: 21.2508175 },
    { lat: 48.686053, lng: 21.2500851 },
    { lat: 48.6860623, lng: 21.249729 },
    { lat: 48.685996, lng: 21.2495164 },
    { lat: 48.6858251, lng: 21.2493774 },
    { lat: 48.6857685, lng: 21.2492942 },
    { lat: 48.685766, lng: 21.2489962 },
    { lat: 48.6859961, lng: 21.2486096 },
    { lat: 48.6859534, lng: 21.2483252 },
    { lat: 48.6859177, lng: 21.2481168 },
    { lat: 48.6856948, lng: 21.2475335 },
    { lat: 48.6857729, lng: 21.2464232 },
    { lat: 48.6867212, lng: 21.2455708 },
    { lat: 48.6868816, lng: 21.24494 },
    { lat: 48.6871935, lng: 21.2445087 },
    { lat: 48.6872519, lng: 21.2444704 },
    { lat: 48.6874607, lng: 21.2439427 },
    { lat: 48.6875298, lng: 21.2435595 },
    { lat: 48.6877603, lng: 21.2430474 },
    { lat: 48.6879583, lng: 21.242788 },
    { lat: 48.6882518, lng: 21.2422732 },
    { lat: 48.6882856, lng: 21.2422024 },
    { lat: 48.6883266, lng: 21.2421097 },
    { lat: 48.6884283, lng: 21.2418703 },
    { lat: 48.6884314, lng: 21.2410288 },
    { lat: 48.6886045, lng: 21.2405136 },
    { lat: 48.6889777, lng: 21.2401366 },
    { lat: 48.6890813, lng: 21.2398479 },
    { lat: 48.6890089, lng: 21.2392219 },
    { lat: 48.6890972, lng: 21.2387996 },
    { lat: 48.689259, lng: 21.2381798 },
    { lat: 48.68925, lng: 21.2379243 },
    { lat: 48.6894183, lng: 21.2375465 },
    { lat: 48.6895405, lng: 21.2371724 },
    { lat: 48.68958, lng: 21.236812 },
    { lat: 48.6896517, lng: 21.2365832 },
    { lat: 48.6897468, lng: 21.2363856 },
    { lat: 48.6898593, lng: 21.2362736 },
    { lat: 48.6899386, lng: 21.236149 },
    { lat: 48.6899646, lng: 21.2360773 },
    { lat: 48.6900344, lng: 21.2357903 },
    { lat: 48.6900348, lng: 21.235548 },
    { lat: 48.6900147, lng: 21.2353379 },
    { lat: 48.6900471, lng: 21.2350755 },
    { lat: 48.6901484, lng: 21.2348596 },
    { lat: 48.6903442, lng: 21.2345704 },
    { lat: 48.6904807, lng: 21.234217 },
    { lat: 48.6907535, lng: 21.2336533 },
    { lat: 48.6909782, lng: 21.2332819 },
    { lat: 48.6910571, lng: 21.2330647 },
    { lat: 48.6912737, lng: 21.2325766 },
    { lat: 48.6913786, lng: 21.2324028 },
    { lat: 48.6914412, lng: 21.2322815 },
    { lat: 48.6914636, lng: 21.2319526 },
    { lat: 48.6915896, lng: 21.2316633 },
    { lat: 48.6916701, lng: 21.2315001 },
    { lat: 48.6916796, lng: 21.2314083 },
    { lat: 48.6916828, lng: 21.2314038 },
    { lat: 48.6917292, lng: 21.2313364 },
    { lat: 48.6917323, lng: 21.2313308 },
    { lat: 48.6917673, lng: 21.2312603 },
    { lat: 48.6918665, lng: 21.2310757 },
    { lat: 48.692004, lng: 21.230779 },
    { lat: 48.692007, lng: 21.230776 },
    { lat: 48.692009, lng: 21.230773 },
    { lat: 48.692096, lng: 21.230674 },
    { lat: 48.692168, lng: 21.230591 },
    { lat: 48.692137, lng: 21.230547 },
    { lat: 48.692104, lng: 21.230503 },
    { lat: 48.692029, lng: 21.230401 },
    { lat: 48.69188, lng: 21.230183 },
    { lat: 48.691846, lng: 21.230134 },
    { lat: 48.691721, lng: 21.229955 },
    { lat: 48.691651, lng: 21.229847 },
    { lat: 48.691604, lng: 21.229775 },
    { lat: 48.691523, lng: 21.229656 },
    { lat: 48.691482, lng: 21.229595 },
    { lat: 48.691356, lng: 21.2294 },
    { lat: 48.69135, lng: 21.229392 },
    { lat: 48.69122, lng: 21.22919 },
    { lat: 48.691097, lng: 21.228998 },
    { lat: 48.691035, lng: 21.228895 },
    { lat: 48.690974, lng: 21.228795 },
    { lat: 48.690851, lng: 21.228595 },
    { lat: 48.690727, lng: 21.228393 },
    { lat: 48.690614, lng: 21.228191 },
    { lat: 48.69054, lng: 21.228061 },
    { lat: 48.690494, lng: 21.22798 },
    { lat: 48.690377, lng: 21.22777 },
    { lat: 48.690263, lng: 21.227558 },
    { lat: 48.690214, lng: 21.227465 },
    { lat: 48.690151, lng: 21.227347 },
    { lat: 48.690067, lng: 21.227189 },
    { lat: 48.690037, lng: 21.227133 },
    { lat: 48.689923, lng: 21.226925 },
    { lat: 48.689895, lng: 21.226875 },
    { lat: 48.689809, lng: 21.226714 },
    { lat: 48.689756, lng: 21.226619 },
    { lat: 48.689693, lng: 21.226507 },
    { lat: 48.68958, lng: 21.2263 },
    { lat: 48.689509, lng: 21.226164 },
    { lat: 48.689469, lng: 21.226087 },
    { lat: 48.689358, lng: 21.225881 },
    { lat: 48.689259, lng: 21.225693 },
    { lat: 48.689242, lng: 21.225663 },
    { lat: 48.689123, lng: 21.225442 },
    { lat: 48.689014, lng: 21.225243 },
    { lat: 48.688912, lng: 21.225063 },
    { lat: 48.688786, lng: 21.224842 },
    { lat: 48.688665, lng: 21.224619 },
    { lat: 48.688531, lng: 21.224395 },
    { lat: 48.688502, lng: 21.224349 },
    { lat: 48.688349, lng: 21.224105 },
    { lat: 48.68802, lng: 21.223601 },
    { lat: 48.688005, lng: 21.223582 },
    { lat: 48.687827, lng: 21.223327 },
    { lat: 48.687585, lng: 21.222985 },
    { lat: 48.687578, lng: 21.222975 },
    { lat: 48.687572, lng: 21.222966 },
    { lat: 48.687549, lng: 21.222933 },
    { lat: 48.687359, lng: 21.222687 },
    { lat: 48.687253, lng: 21.222551 },
    { lat: 48.687057, lng: 21.222335 },
    { lat: 48.68698, lng: 21.222324 },
    { lat: 48.686883, lng: 21.22231 },
    { lat: 48.68679, lng: 21.222356 },
    { lat: 48.686725, lng: 21.222389 },
    { lat: 48.68669, lng: 21.222434 },
    { lat: 48.686689, lng: 21.222436 },
    { lat: 48.686596, lng: 21.222331 },
    { lat: 48.686592, lng: 21.222319 },
    { lat: 48.686583, lng: 21.222299 },
    { lat: 48.686565, lng: 21.222254 },
    { lat: 48.686554, lng: 21.222229 },
    { lat: 48.686558, lng: 21.222213 },
    { lat: 48.6865889, lng: 21.222083 },
    { lat: 48.686458, lng: 21.222112 },
    { lat: 48.686391, lng: 21.222127 },
    { lat: 48.686331, lng: 21.22214 },
    { lat: 48.6862361, lng: 21.2221099 },
    { lat: 48.6860204, lng: 21.221653 },
    { lat: 48.6851432, lng: 21.2194781 },
    { lat: 48.6824159, lng: 21.2160332 },
    { lat: 48.682295, lng: 21.216409 },
    { lat: 48.68178, lng: 21.21601 },
    { lat: 48.681727, lng: 21.215985 },
    { lat: 48.681457, lng: 21.215832 },
    { lat: 48.681367, lng: 21.215804 },
    { lat: 48.680218, lng: 21.215454 },
    { lat: 48.679608, lng: 21.21521 },
    { lat: 48.679199, lng: 21.215049 },
    { lat: 48.678778, lng: 21.214789 },
    { lat: 48.678083, lng: 21.214527 },
    { lat: 48.678034, lng: 21.214508 },
    { lat: 48.677642, lng: 21.214371 },
    { lat: 48.677123, lng: 21.21417 },
    { lat: 48.676284, lng: 21.213844 },
    { lat: 48.675551, lng: 21.213561 },
    { lat: 48.6708723, lng: 21.2114851 },
    { lat: 48.6708138, lng: 21.2162686 },
    { lat: 48.6689447, lng: 21.2166542 },
    { lat: 48.6641194, lng: 21.223892 },
    { lat: 48.6639524, lng: 21.2263181 },
    { lat: 48.6639757, lng: 21.2276953 },
    { lat: 48.6638607, lng: 21.2289368 },
    { lat: 48.6638367, lng: 21.2292218 },
    { lat: 48.6637547, lng: 21.2294448 },
    { lat: 48.6637047, lng: 21.2298938 },
    { lat: 48.6635697, lng: 21.2302158 },
    { lat: 48.6635357, lng: 21.2304138 },
    { lat: 48.6634467, lng: 21.2305228 },
    { lat: 48.6633977, lng: 21.2307828 },
    { lat: 48.6632097, lng: 21.2311148 },
    { lat: 48.6630768, lng: 21.2312788 },
    { lat: 48.6630298, lng: 21.2315438 },
    { lat: 48.6627908, lng: 21.2322663 },
    { lat: 48.6625012, lng: 21.2329216 },
    { lat: 48.6618199, lng: 21.2343612 },
    { lat: 48.6605486, lng: 21.2419065 },
    { lat: 48.6604761, lng: 21.242761 },
    { lat: 48.660389, lng: 21.2435845 },
    { lat: 48.66036, lng: 21.2438429 },
    { lat: 48.660327, lng: 21.2442304 },
    { lat: 48.6602604, lng: 21.2450251 },
    { lat: 48.6601845, lng: 21.2460197 },
    { lat: 48.6600991, lng: 21.2472215 },
    { lat: 48.6600132, lng: 21.2490264 },
    { lat: 48.660027, lng: 21.2490717 },
    { lat: 48.6600476, lng: 21.249083 },
    { lat: 48.6600441, lng: 21.2491132 },
    { lat: 48.6600305, lng: 21.2492572 },
    { lat: 48.6600193, lng: 21.2506742 },
    { lat: 48.6601606, lng: 21.2528734 },
    { lat: 48.6600871, lng: 21.2549704 },
    { lat: 48.6600033, lng: 21.255575 },
    { lat: 48.6600729, lng: 21.2566609 },
    { lat: 48.6600468, lng: 21.258543 },
    { lat: 48.6600495, lng: 21.260232 },
    { lat: 48.66022, lng: 21.2632387 },
    { lat: 48.660034, lng: 21.2633045 },
    { lat: 48.660241, lng: 21.2645013 },
    { lat: 48.6603225, lng: 21.2651356 },
    { lat: 48.6604508, lng: 21.2664128 },
    { lat: 48.6604665, lng: 21.2667032 },
    { lat: 48.6606579, lng: 21.2681287 },
    { lat: 48.6606246, lng: 21.2683617 },
    { lat: 48.6610436, lng: 21.2712595 },
    { lat: 48.6610527, lng: 21.2713383 },
    { lat: 48.6610692, lng: 21.2714695 },
    { lat: 48.6610756, lng: 21.2715314 },
    { lat: 48.6611535, lng: 21.2720524 },
    { lat: 48.661267, lng: 21.2728504 },
    { lat: 48.6614236, lng: 21.2739508 },
    { lat: 48.6618837, lng: 21.2770548 },
    { lat: 48.6618866, lng: 21.2771102 },
    { lat: 48.6618907, lng: 21.2772103 },
    { lat: 48.6618932, lng: 21.2772884 },
    { lat: 48.6621199, lng: 21.2795 },
    { lat: 48.6622526, lng: 21.2805633 },
    { lat: 48.6624887, lng: 21.2819116 },
    { lat: 48.6624713, lng: 21.2822574 },
    { lat: 48.6624808, lng: 21.282349 },
    { lat: 48.6628975, lng: 21.286501 },
    { lat: 48.6627329, lng: 21.2864146 },
    { lat: 48.6626617, lng: 21.2864441 },
    { lat: 48.6625986, lng: 21.2865176 },
    { lat: 48.6625465, lng: 21.2866857 },
    { lat: 48.6624944, lng: 21.2868247 },
    { lat: 48.6624175, lng: 21.2869596 },
    { lat: 48.6623121, lng: 21.2871158 },
    { lat: 48.662277, lng: 21.2873678 },
    { lat: 48.6622297, lng: 21.2875526 },
    { lat: 48.6620791, lng: 21.2877394 },
    { lat: 48.6618976, lng: 21.287876 },
    { lat: 48.6617092, lng: 21.2879354 },
    { lat: 48.6615287, lng: 21.288032 },
    { lat: 48.6614133, lng: 21.2880832 },
    { lat: 48.6612, lng: 21.2882487 },
    { lat: 48.6609423, lng: 21.2884681 },
    { lat: 48.6607146, lng: 21.2884577 },
    { lat: 48.6605384, lng: 21.2884711 },
    { lat: 48.6604484, lng: 21.2885083 },
    { lat: 48.6602968, lng: 21.2886684 },
    { lat: 48.6601997, lng: 21.2888774 },
    { lat: 48.660153, lng: 21.2891541 },
    { lat: 48.6601554, lng: 21.2893397 },
    { lat: 48.6599791, lng: 21.2896482 },
    { lat: 48.6598762, lng: 21.2897067 },
    { lat: 48.6597152, lng: 21.2897382 },
    { lat: 48.6588518, lng: 21.2905015 },
    { lat: 48.6586404, lng: 21.2906202 },
    { lat: 48.6584741, lng: 21.2908483 },
    { lat: 48.6584465, lng: 21.2909741 },
    { lat: 48.6584293, lng: 21.2914786 },
    { lat: 48.6583483, lng: 21.2915174 },
    { lat: 48.6581921, lng: 21.2915229 },
    { lat: 48.6579826, lng: 21.2914806 },
    { lat: 48.6579085, lng: 21.2915207 },
    { lat: 48.6575792, lng: 21.2916497 },
    { lat: 48.6574986, lng: 21.291147 },
    { lat: 48.6573592, lng: 21.2904548 },
    { lat: 48.6573232, lng: 21.2902722 },
    { lat: 48.6571373, lng: 21.2901201 },
    { lat: 48.6560407, lng: 21.2891843 },
    { lat: 48.6558613, lng: 21.2890203 },
    { lat: 48.6554332, lng: 21.2887135 },
    { lat: 48.6543557, lng: 21.2879238 },
    { lat: 48.6539363, lng: 21.2877182 },
    { lat: 48.6535842, lng: 21.2868056 },
    { lat: 48.6530927, lng: 21.2866336 },
    { lat: 48.6529586, lng: 21.2863692 },
    { lat: 48.6527101, lng: 21.2859734 },
    { lat: 48.6526876, lng: 21.2859369 },
    { lat: 48.6525697, lng: 21.2857258 },
    { lat: 48.6521896, lng: 21.2850377 },
    { lat: 48.652161, lng: 21.2849856 },
    { lat: 48.6518598, lng: 21.284781 },
    { lat: 48.6517081, lng: 21.2845671 },
    { lat: 48.6515873, lng: 21.284221 },
    { lat: 48.6515352, lng: 21.2842506 },
    { lat: 48.6513915, lng: 21.2845557 },
    { lat: 48.6513392, lng: 21.2846103 },
    { lat: 48.651285, lng: 21.284669 },
    { lat: 48.649754, lng: 21.286081 },
    { lat: 48.649416, lng: 21.286297 },
    { lat: 48.648394, lng: 21.286914 },
    { lat: 48.647703, lng: 21.287211 },
    { lat: 48.647182, lng: 21.287434 },
    { lat: 48.647129, lng: 21.287776 },
    { lat: 48.647092, lng: 21.288011 },
    { lat: 48.647018, lng: 21.288128 },
    { lat: 48.646958, lng: 21.288223 },
    { lat: 48.647057, lng: 21.288595 },
    { lat: 48.649128, lng: 21.294939 },
    { lat: 48.64943, lng: 21.295902 },
    { lat: 48.649821, lng: 21.297324 },
    { lat: 48.649969, lng: 21.297985 },
    { lat: 48.650165, lng: 21.299176 },
    { lat: 48.650345, lng: 21.300825 },
    { lat: 48.650392, lng: 21.302241 },
    { lat: 48.650507, lng: 21.305633 },
    { lat: 48.650515, lng: 21.305838 },
    { lat: 48.650812, lng: 21.314231 },
  ],
};

export default UnitsKošiceIV;
