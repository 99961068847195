const UnitsKošiceII = {
  KošicemestskáčasťPereš: [
    { lat: 48.6922959, lng: 21.2141885 },
    { lat: 48.6924248, lng: 21.2138159 },
    { lat: 48.6924735, lng: 21.2136457 },
    { lat: 48.6925564, lng: 21.2135643 },
    { lat: 48.6926244, lng: 21.2135046 },
    { lat: 48.6926408, lng: 21.2135269 },
    { lat: 48.6927912, lng: 21.2134058 },
    { lat: 48.6927667, lng: 21.2133765 },
    { lat: 48.6928915, lng: 21.2132632 },
    { lat: 48.6929418, lng: 21.2132169 },
    { lat: 48.6930142, lng: 21.2130863 },
    { lat: 48.6931154, lng: 21.2129064 },
    { lat: 48.6932081, lng: 21.2127376 },
    { lat: 48.6933135, lng: 21.2125467 },
    { lat: 48.6934948, lng: 21.2125113 },
    { lat: 48.6936286, lng: 21.212403 },
    { lat: 48.693756, lng: 21.2123003 },
    { lat: 48.6938865, lng: 21.2121926 },
    { lat: 48.6939649, lng: 21.2119982 },
    { lat: 48.6940177, lng: 21.2119239 },
    { lat: 48.6940673, lng: 21.2118534 },
    { lat: 48.6941858, lng: 21.2117217 },
    { lat: 48.6942141, lng: 21.2117261 },
    { lat: 48.6942956, lng: 21.2117402 },
    { lat: 48.6944014, lng: 21.2115973 },
    { lat: 48.6944258, lng: 21.2115499 },
    { lat: 48.69447, lng: 21.2114157 },
    { lat: 48.6945454, lng: 21.2113423 },
    { lat: 48.6945576, lng: 21.2112673 },
    { lat: 48.6946446, lng: 21.2111001 },
    { lat: 48.6947524, lng: 21.210983 },
    { lat: 48.6948552, lng: 21.2108495 },
    { lat: 48.6949442, lng: 21.2107121 },
    { lat: 48.6949678, lng: 21.2107446 },
    { lat: 48.6950509, lng: 21.2105969 },
    { lat: 48.6949861, lng: 21.2105003 },
    { lat: 48.6950726, lng: 21.2103175 },
    { lat: 48.6952192, lng: 21.2100038 },
    { lat: 48.6952982, lng: 21.209834 },
    { lat: 48.6953412, lng: 21.2097689 },
    { lat: 48.6953926, lng: 21.2096868 },
    { lat: 48.6954939, lng: 21.2095378 },
    { lat: 48.6956, lng: 21.2093728 },
    { lat: 48.6957078, lng: 21.2091917 },
    { lat: 48.6958019, lng: 21.209062 },
    { lat: 48.6958301, lng: 21.2089552 },
    { lat: 48.6958628, lng: 21.2088488 },
    { lat: 48.6959081, lng: 21.2087509 },
    { lat: 48.6959534, lng: 21.2086543 },
    { lat: 48.696027, lng: 21.2084873 },
    { lat: 48.695993, lng: 21.2080843 },
    { lat: 48.6958959, lng: 21.2074657 },
    { lat: 48.6957462, lng: 21.2068283 },
    { lat: 48.6955738, lng: 21.2064143 },
    { lat: 48.6954813, lng: 21.2062652 },
    { lat: 48.6952698, lng: 21.2058908 },
    { lat: 48.6947481, lng: 21.2050031 },
    { lat: 48.6946943, lng: 21.2049043 },
    { lat: 48.6946021, lng: 21.2047349 },
    { lat: 48.6947, lng: 21.2045832 },
    { lat: 48.6948417, lng: 21.2043634 },
    { lat: 48.6947162, lng: 21.2041834 },
    { lat: 48.6942904, lng: 21.2035676 },
    { lat: 48.6942325, lng: 21.2034773 },
    { lat: 48.6943678, lng: 21.2032101 },
    { lat: 48.6943491, lng: 21.2031796 },
    { lat: 48.6943795, lng: 21.2031404 },
    { lat: 48.6944034, lng: 21.2030796 },
    { lat: 48.6944361, lng: 21.2029732 },
    { lat: 48.6944639, lng: 21.2028209 },
    { lat: 48.6944627, lng: 21.2026735 },
    { lat: 48.6944501, lng: 21.2026295 },
    { lat: 48.694421, lng: 21.2025304 },
    { lat: 48.6943092, lng: 21.202304 },
    { lat: 48.6941088, lng: 21.2019789 },
    { lat: 48.6936358, lng: 21.2012469 },
    { lat: 48.6935316, lng: 21.2010858 },
    { lat: 48.6929351, lng: 21.2001896 },
    { lat: 48.692855, lng: 21.2001116 },
    { lat: 48.6928159, lng: 21.2000456 },
    { lat: 48.6928111, lng: 21.2000389 },
    { lat: 48.6927838, lng: 21.2000312 },
    { lat: 48.6927486, lng: 21.2000209 },
    { lat: 48.6923617, lng: 21.1999898 },
    { lat: 48.692236, lng: 21.200002 },
    { lat: 48.692126, lng: 21.2000466 },
    { lat: 48.6920607, lng: 21.2000812 },
    { lat: 48.6919867, lng: 21.2001293 },
    { lat: 48.6918883, lng: 21.2002197 },
    { lat: 48.6918451, lng: 21.2002805 },
    { lat: 48.6918225, lng: 21.200312 },
    { lat: 48.6916914, lng: 21.2005444 },
    { lat: 48.6916657, lng: 21.2005854 },
    { lat: 48.6915629, lng: 21.200751 },
    { lat: 48.6915295, lng: 21.2008004 },
    { lat: 48.6913832, lng: 21.2010146 },
    { lat: 48.6912961, lng: 21.2011434 },
    { lat: 48.6912852, lng: 21.2011584 },
    { lat: 48.6912457, lng: 21.2012243 },
    { lat: 48.6912297, lng: 21.2012514 },
    { lat: 48.6909687, lng: 21.2008591 },
    { lat: 48.6907383, lng: 21.200526 },
    { lat: 48.6905424, lng: 21.2002256 },
    { lat: 48.6904303, lng: 21.2000577 },
    { lat: 48.6901975, lng: 21.1997068 },
    { lat: 48.6901845, lng: 21.1996877 },
    { lat: 48.6901645, lng: 21.1996575 },
    { lat: 48.6900445, lng: 21.1998132 },
    { lat: 48.6900163, lng: 21.1998501 },
    { lat: 48.689773, lng: 21.2001632 },
    { lat: 48.6895902, lng: 21.2003993 },
    { lat: 48.6895193, lng: 21.2005073 },
    { lat: 48.6893937, lng: 21.2006976 },
    { lat: 48.6892679, lng: 21.2008879 },
    { lat: 48.689141, lng: 21.2010776 },
    { lat: 48.6891012, lng: 21.2011367 },
    { lat: 48.6890214, lng: 21.2012785 },
    { lat: 48.6889723, lng: 21.2013654 },
    { lat: 48.6889056, lng: 21.2014646 },
    { lat: 48.6887797, lng: 21.2016492 },
    { lat: 48.6886528, lng: 21.2018353 },
    { lat: 48.6885247, lng: 21.2020229 },
    { lat: 48.6883956, lng: 21.2022124 },
    { lat: 48.6882439, lng: 21.2024348 },
    { lat: 48.6881148, lng: 21.202624 },
    { lat: 48.6879804, lng: 21.202822 },
    { lat: 48.6879459, lng: 21.2028729 },
    { lat: 48.68792, lng: 21.2029093 },
    { lat: 48.6878287, lng: 21.2027623 },
    { lat: 48.6877198, lng: 21.2025892 },
    { lat: 48.6876034, lng: 21.2023945 },
    { lat: 48.6874965, lng: 21.2022141 },
    { lat: 48.6873905, lng: 21.2020272 },
    { lat: 48.6873266, lng: 21.2019299 },
    { lat: 48.6872711, lng: 21.2018448 },
    { lat: 48.6871543, lng: 21.2016737 },
    { lat: 48.6871342, lng: 21.2016466 },
    { lat: 48.6870333, lng: 21.2015121 },
    { lat: 48.6870238, lng: 21.2015003 },
    { lat: 48.6868746, lng: 21.2012973 },
    { lat: 48.6867215, lng: 21.2010821 },
    { lat: 48.6865773, lng: 21.2008299 },
    { lat: 48.6864635, lng: 21.2006439 },
    { lat: 48.6863543, lng: 21.2004633 },
    { lat: 48.6862386, lng: 21.2002889 },
    { lat: 48.6861507, lng: 21.2001288 },
    { lat: 48.6860945, lng: 21.2000263 },
    { lat: 48.686009, lng: 21.1998706 },
    { lat: 48.6859387, lng: 21.1997426 },
    { lat: 48.6858695, lng: 21.1996166 },
    { lat: 48.6858242, lng: 21.1995341 },
    { lat: 48.6857745, lng: 21.1994434 },
    { lat: 48.6857163, lng: 21.1993374 },
    { lat: 48.6856558, lng: 21.1992272 },
    { lat: 48.6855478, lng: 21.1990305 },
    { lat: 48.6854429, lng: 21.1988394 },
    { lat: 48.6853127, lng: 21.1986023 },
    { lat: 48.6851198, lng: 21.198279 },
    { lat: 48.685027, lng: 21.1981335 },
    { lat: 48.6849756, lng: 21.1983389 },
    { lat: 48.6849665, lng: 21.1983752 },
    { lat: 48.6848544, lng: 21.1988231 },
    { lat: 48.6848201, lng: 21.1991693 },
    { lat: 48.684794, lng: 21.1994324 },
    { lat: 48.6847903, lng: 21.1995033 },
    { lat: 48.6847848, lng: 21.1996059 },
    { lat: 48.6847754, lng: 21.1997846 },
    { lat: 48.6847511, lng: 21.2001898 },
    { lat: 48.6847167, lng: 21.2006482 },
    { lat: 48.6846978, lng: 21.2009003 },
    { lat: 48.6846743, lng: 21.2011802 },
    { lat: 48.6846223, lng: 21.2016491 },
    { lat: 48.6845884, lng: 21.2018894 },
    { lat: 48.6845841, lng: 21.2020402 },
    { lat: 48.6845938, lng: 21.202191 },
    { lat: 48.6846127, lng: 21.2023215 },
    { lat: 48.6846817, lng: 21.2024925 },
    { lat: 48.6847393, lng: 21.2026334 },
    { lat: 48.6847974, lng: 21.2027756 },
    { lat: 48.684867, lng: 21.2029459 },
    { lat: 48.6849034, lng: 21.2030351 },
    { lat: 48.6849295, lng: 21.2030988 },
    { lat: 48.6850068, lng: 21.2033067 },
    { lat: 48.6850195, lng: 21.2033409 },
    { lat: 48.6851099, lng: 21.2035272 },
    { lat: 48.6851358, lng: 21.2035807 },
    { lat: 48.6852193, lng: 21.2037545 },
    { lat: 48.6852716, lng: 21.2038238 },
    { lat: 48.6853484, lng: 21.2038721 },
    { lat: 48.6854749, lng: 21.2039084 },
    { lat: 48.6856259, lng: 21.2039518 },
    { lat: 48.6857622, lng: 21.203977 },
    { lat: 48.6860861, lng: 21.2040369 },
    { lat: 48.6862514, lng: 21.2041055 },
    { lat: 48.6863173, lng: 21.2041717 },
    { lat: 48.6863542, lng: 21.2042087 },
    { lat: 48.6863867, lng: 21.2042414 },
    { lat: 48.6864326, lng: 21.2043182 },
    { lat: 48.6864625, lng: 21.2043683 },
    { lat: 48.6865225, lng: 21.2044687 },
    { lat: 48.6866038, lng: 21.2046049 },
    { lat: 48.6866585, lng: 21.2046885 },
    { lat: 48.6866988, lng: 21.2047502 },
    { lat: 48.6867824, lng: 21.204878 },
    { lat: 48.6868304, lng: 21.2050194 },
    { lat: 48.6869604, lng: 21.2053976 },
    { lat: 48.686989, lng: 21.205481 },
    { lat: 48.687063, lng: 21.2058682 },
    { lat: 48.6870542, lng: 21.2060918 },
    { lat: 48.6870138, lng: 21.2063106 },
    { lat: 48.6868766, lng: 21.2064914 },
    { lat: 48.6867225, lng: 21.2066406 },
    { lat: 48.6866157, lng: 21.2068933 },
    { lat: 48.6865902, lng: 21.2071027 },
    { lat: 48.6865763, lng: 21.2072166 },
    { lat: 48.6865486, lng: 21.2074444 },
    { lat: 48.6865261, lng: 21.2076227 },
    { lat: 48.6865028, lng: 21.2078153 },
    { lat: 48.6864796, lng: 21.2081151 },
    { lat: 48.6864162, lng: 21.208463 },
    { lat: 48.6864206, lng: 21.2085522 },
    { lat: 48.6864024, lng: 21.2086458 },
    { lat: 48.6863073, lng: 21.2088337 },
    { lat: 48.6862194, lng: 21.2089703 },
    { lat: 48.6860882, lng: 21.209202 },
    { lat: 48.6860157, lng: 21.209329 },
    { lat: 48.6856341, lng: 21.2099731 },
    { lat: 48.6856158, lng: 21.2100033 },
    { lat: 48.6853818, lng: 21.2103993 },
    { lat: 48.6851722, lng: 21.2107847 },
    { lat: 48.6850359, lng: 21.2110352 },
    { lat: 48.6847307, lng: 21.211493 },
    { lat: 48.6846375, lng: 21.2116065 },
    { lat: 48.6846158, lng: 21.2116333 },
    { lat: 48.6841346, lng: 21.2122251 },
    { lat: 48.6839542, lng: 21.2124659 },
    { lat: 48.6838951, lng: 21.2125189 },
    { lat: 48.6837526, lng: 21.2126056 },
    { lat: 48.6836597, lng: 21.2126194 },
    { lat: 48.6836056, lng: 21.2126204 },
    { lat: 48.6835776, lng: 21.2126217 },
    { lat: 48.683554, lng: 21.2126227 },
    { lat: 48.6834775, lng: 21.2126979 },
    { lat: 48.6834656, lng: 21.212713 },
    { lat: 48.6833738, lng: 21.212836 },
    { lat: 48.6833208, lng: 21.2130817 },
    { lat: 48.683238, lng: 21.213293 },
    { lat: 48.6832321, lng: 21.2133081 },
    { lat: 48.683196, lng: 21.2134478 },
    { lat: 48.6831792, lng: 21.2136737 },
    { lat: 48.683178, lng: 21.2138782 },
    { lat: 48.683215, lng: 21.2141334 },
    { lat: 48.6832532, lng: 21.214379 },
    { lat: 48.6832731, lng: 21.2145097 },
    { lat: 48.6832528, lng: 21.2148652 },
    { lat: 48.6832318, lng: 21.2149514 },
    { lat: 48.6830165, lng: 21.2158778 },
    { lat: 48.6829807, lng: 21.2158821 },
    { lat: 48.6829526, lng: 21.2158856 },
    { lat: 48.6828876, lng: 21.2158927 },
    { lat: 48.6828854, lng: 21.2158932 },
    { lat: 48.6828652, lng: 21.215895 },
    { lat: 48.6828428, lng: 21.215898 },
    { lat: 48.6828124, lng: 21.2159009 },
    { lat: 48.6827687, lng: 21.2159055 },
    { lat: 48.6827541, lng: 21.2159065 },
    { lat: 48.6826341, lng: 21.2159186 },
    { lat: 48.6826273, lng: 21.2159191 },
    { lat: 48.6825925, lng: 21.2159223 },
    { lat: 48.6825892, lng: 21.2159229 },
    { lat: 48.6824591, lng: 21.2159354 },
    { lat: 48.6824478, lng: 21.2159369 },
    { lat: 48.6824159, lng: 21.2160332 },
    { lat: 48.6851432, lng: 21.2194781 },
    { lat: 48.6860204, lng: 21.221653 },
    { lat: 48.6862361, lng: 21.2221099 },
    { lat: 48.686331, lng: 21.22214 },
    { lat: 48.686391, lng: 21.222127 },
    { lat: 48.686458, lng: 21.222112 },
    { lat: 48.6865889, lng: 21.222083 },
    { lat: 48.686618, lng: 21.2220385 },
    { lat: 48.6866588, lng: 21.2219751 },
    { lat: 48.6868669, lng: 21.2218651 },
    { lat: 48.6870986, lng: 21.2219715 },
    { lat: 48.6871185, lng: 21.221886 },
    { lat: 48.6870016, lng: 21.2217113 },
    { lat: 48.6870328, lng: 21.2216656 },
    { lat: 48.6870404, lng: 21.2216544 },
    { lat: 48.687106, lng: 21.2215564 },
    { lat: 48.6871564, lng: 21.2214766 },
    { lat: 48.687181, lng: 21.2214374 },
    { lat: 48.6871897, lng: 21.2214236 },
    { lat: 48.6872079, lng: 21.2213246 },
    { lat: 48.6872448, lng: 21.2212851 },
    { lat: 48.6873063, lng: 21.22119 },
    { lat: 48.6873464, lng: 21.2211043 },
    { lat: 48.6874839, lng: 21.2208375 },
    { lat: 48.6875511, lng: 21.2207024 },
    { lat: 48.6875943, lng: 21.220562 },
    { lat: 48.6876132, lng: 21.2204356 },
    { lat: 48.6876359, lng: 21.2203082 },
    { lat: 48.6876828, lng: 21.2201461 },
    { lat: 48.6879153, lng: 21.2195778 },
    { lat: 48.6879685, lng: 21.2194804 },
    { lat: 48.6880448, lng: 21.2192904 },
    { lat: 48.6880564, lng: 21.2192337 },
    { lat: 48.6880929, lng: 21.2191777 },
    { lat: 48.6881885, lng: 21.2190294 },
    { lat: 48.688282, lng: 21.2189223 },
    { lat: 48.6883743, lng: 21.2188174 },
    { lat: 48.6885124, lng: 21.2185526 },
    { lat: 48.6887366, lng: 21.218419 },
    { lat: 48.6888378, lng: 21.2183431 },
    { lat: 48.6889313, lng: 21.2182719 },
    { lat: 48.6891144, lng: 21.2181556 },
    { lat: 48.6892998, lng: 21.2180027 },
    { lat: 48.689411, lng: 21.2178509 },
    { lat: 48.6895459, lng: 21.2177384 },
    { lat: 48.6895861, lng: 21.2176931 },
    { lat: 48.6896118, lng: 21.2176514 },
    { lat: 48.6895954, lng: 21.2176269 },
    { lat: 48.6896963, lng: 21.2173989 },
    { lat: 48.6898035, lng: 21.2173029 },
    { lat: 48.689912, lng: 21.2172068 },
    { lat: 48.6899638, lng: 21.2171718 },
    { lat: 48.6900177, lng: 21.2171351 },
    { lat: 48.6900481, lng: 21.2170988 },
    { lat: 48.6901533, lng: 21.2169705 },
    { lat: 48.6902661, lng: 21.2167623 },
    { lat: 48.6902714, lng: 21.2167509 },
    { lat: 48.6903629, lng: 21.216547 },
    { lat: 48.6903393, lng: 21.2165149 },
    { lat: 48.6904243, lng: 21.2162801 },
    { lat: 48.6904296, lng: 21.2162673 },
    { lat: 48.6905516, lng: 21.2161025 },
    { lat: 48.6905773, lng: 21.2161335 },
    { lat: 48.6905891, lng: 21.216149 },
    { lat: 48.690636, lng: 21.2160702 },
    { lat: 48.6907078, lng: 21.2159525 },
    { lat: 48.6908041, lng: 21.2157928 },
    { lat: 48.6908169, lng: 21.215773 },
    { lat: 48.690812, lng: 21.215757 },
    { lat: 48.6908084, lng: 21.2157487 },
    { lat: 48.690866, lng: 21.2156473 },
    { lat: 48.6909193, lng: 21.2155541 },
    { lat: 48.6909404, lng: 21.2155811 },
    { lat: 48.6909616, lng: 21.2156095 },
    { lat: 48.6910603, lng: 21.2155262 },
    { lat: 48.691158, lng: 21.2154495 },
    { lat: 48.691184, lng: 21.2154519 },
    { lat: 48.6912112, lng: 21.2154257 },
    { lat: 48.6912684, lng: 21.2153447 },
    { lat: 48.6912996, lng: 21.215299 },
    { lat: 48.6913404, lng: 21.2151618 },
    { lat: 48.6913795, lng: 21.2150123 },
    { lat: 48.6913985, lng: 21.2147541 },
    { lat: 48.6913062, lng: 21.2145373 },
    { lat: 48.6915012, lng: 21.2143663 },
    { lat: 48.6916937, lng: 21.2142254 },
    { lat: 48.6918639, lng: 21.2140904 },
    { lat: 48.6920571, lng: 21.2139686 },
    { lat: 48.6922278, lng: 21.214056 },
    { lat: 48.6922959, lng: 21.2141885 },
  ],
  KošicemestskáčasťLuníkIX: [
    { lat: 48.6922959, lng: 21.2141885 },
    { lat: 48.6922278, lng: 21.214056 },
    { lat: 48.6920571, lng: 21.2139686 },
    { lat: 48.6918639, lng: 21.2140904 },
    { lat: 48.6916937, lng: 21.2142254 },
    { lat: 48.6915012, lng: 21.2143663 },
    { lat: 48.6913062, lng: 21.2145373 },
    { lat: 48.6913985, lng: 21.2147541 },
    { lat: 48.6913795, lng: 21.2150123 },
    { lat: 48.6913404, lng: 21.2151618 },
    { lat: 48.6912996, lng: 21.215299 },
    { lat: 48.6912684, lng: 21.2153447 },
    { lat: 48.6912112, lng: 21.2154257 },
    { lat: 48.691184, lng: 21.2154519 },
    { lat: 48.691158, lng: 21.2154495 },
    { lat: 48.6910603, lng: 21.2155262 },
    { lat: 48.6909616, lng: 21.2156095 },
    { lat: 48.6909404, lng: 21.2155811 },
    { lat: 48.6909193, lng: 21.2155541 },
    { lat: 48.690866, lng: 21.2156473 },
    { lat: 48.6908084, lng: 21.2157487 },
    { lat: 48.690812, lng: 21.215757 },
    { lat: 48.6908169, lng: 21.215773 },
    { lat: 48.6908041, lng: 21.2157928 },
    { lat: 48.6907078, lng: 21.2159525 },
    { lat: 48.690636, lng: 21.2160702 },
    { lat: 48.6905891, lng: 21.216149 },
    { lat: 48.6905773, lng: 21.2161335 },
    { lat: 48.6905516, lng: 21.2161025 },
    { lat: 48.6904296, lng: 21.2162673 },
    { lat: 48.6904243, lng: 21.2162801 },
    { lat: 48.6903393, lng: 21.2165149 },
    { lat: 48.6903629, lng: 21.216547 },
    { lat: 48.6902714, lng: 21.2167509 },
    { lat: 48.6902661, lng: 21.2167623 },
    { lat: 48.6901533, lng: 21.2169705 },
    { lat: 48.6900481, lng: 21.2170988 },
    { lat: 48.6900177, lng: 21.2171351 },
    { lat: 48.6899638, lng: 21.2171718 },
    { lat: 48.689912, lng: 21.2172068 },
    { lat: 48.6898035, lng: 21.2173029 },
    { lat: 48.6896963, lng: 21.2173989 },
    { lat: 48.6895954, lng: 21.2176269 },
    { lat: 48.6896118, lng: 21.2176514 },
    { lat: 48.6895861, lng: 21.2176931 },
    { lat: 48.6895459, lng: 21.2177384 },
    { lat: 48.689411, lng: 21.2178509 },
    { lat: 48.6892998, lng: 21.2180027 },
    { lat: 48.6891144, lng: 21.2181556 },
    { lat: 48.6889313, lng: 21.2182719 },
    { lat: 48.6888378, lng: 21.2183431 },
    { lat: 48.6887366, lng: 21.218419 },
    { lat: 48.6885124, lng: 21.2185526 },
    { lat: 48.6883743, lng: 21.2188174 },
    { lat: 48.688282, lng: 21.2189223 },
    { lat: 48.6881885, lng: 21.2190294 },
    { lat: 48.6880929, lng: 21.2191777 },
    { lat: 48.6880564, lng: 21.2192337 },
    { lat: 48.6880448, lng: 21.2192904 },
    { lat: 48.6879685, lng: 21.2194804 },
    { lat: 48.6879153, lng: 21.2195778 },
    { lat: 48.6876828, lng: 21.2201461 },
    { lat: 48.6876359, lng: 21.2203082 },
    { lat: 48.6876132, lng: 21.2204356 },
    { lat: 48.6875943, lng: 21.220562 },
    { lat: 48.6875511, lng: 21.2207024 },
    { lat: 48.6874839, lng: 21.2208375 },
    { lat: 48.6873464, lng: 21.2211043 },
    { lat: 48.6873063, lng: 21.22119 },
    { lat: 48.6872448, lng: 21.2212851 },
    { lat: 48.6872079, lng: 21.2213246 },
    { lat: 48.6871897, lng: 21.2214236 },
    { lat: 48.687181, lng: 21.2214374 },
    { lat: 48.6871564, lng: 21.2214766 },
    { lat: 48.687106, lng: 21.2215564 },
    { lat: 48.6870404, lng: 21.2216544 },
    { lat: 48.6870328, lng: 21.2216656 },
    { lat: 48.6870016, lng: 21.2217113 },
    { lat: 48.6871185, lng: 21.221886 },
    { lat: 48.6870986, lng: 21.2219715 },
    { lat: 48.6868669, lng: 21.2218651 },
    { lat: 48.6866588, lng: 21.2219751 },
    { lat: 48.686618, lng: 21.2220385 },
    { lat: 48.6865889, lng: 21.222083 },
    { lat: 48.686558, lng: 21.222213 },
    { lat: 48.686554, lng: 21.222229 },
    { lat: 48.686565, lng: 21.222254 },
    { lat: 48.686583, lng: 21.222299 },
    { lat: 48.686592, lng: 21.222319 },
    { lat: 48.686596, lng: 21.222331 },
    { lat: 48.686689, lng: 21.222436 },
    { lat: 48.68669, lng: 21.222434 },
    { lat: 48.686725, lng: 21.222389 },
    { lat: 48.68679, lng: 21.222356 },
    { lat: 48.686883, lng: 21.22231 },
    { lat: 48.68698, lng: 21.222324 },
    { lat: 48.687057, lng: 21.222335 },
    { lat: 48.687253, lng: 21.222551 },
    { lat: 48.687359, lng: 21.222687 },
    { lat: 48.687549, lng: 21.222933 },
    { lat: 48.687572, lng: 21.222966 },
    { lat: 48.687578, lng: 21.222975 },
    { lat: 48.687585, lng: 21.222985 },
    { lat: 48.687827, lng: 21.223327 },
    { lat: 48.688005, lng: 21.223582 },
    { lat: 48.68802, lng: 21.223601 },
    { lat: 48.688349, lng: 21.224105 },
    { lat: 48.688502, lng: 21.224349 },
    { lat: 48.688531, lng: 21.224395 },
    { lat: 48.688665, lng: 21.224619 },
    { lat: 48.688786, lng: 21.224842 },
    { lat: 48.688912, lng: 21.225063 },
    { lat: 48.689014, lng: 21.225243 },
    { lat: 48.689123, lng: 21.225442 },
    { lat: 48.689242, lng: 21.225663 },
    { lat: 48.689259, lng: 21.225693 },
    { lat: 48.689358, lng: 21.225881 },
    { lat: 48.689469, lng: 21.226087 },
    { lat: 48.689509, lng: 21.226164 },
    { lat: 48.68958, lng: 21.2263 },
    { lat: 48.689693, lng: 21.226507 },
    { lat: 48.689756, lng: 21.226619 },
    { lat: 48.689809, lng: 21.226714 },
    { lat: 48.689895, lng: 21.226875 },
    { lat: 48.689923, lng: 21.226925 },
    { lat: 48.690037, lng: 21.227133 },
    { lat: 48.690067, lng: 21.227189 },
    { lat: 48.690151, lng: 21.227347 },
    { lat: 48.690214, lng: 21.227465 },
    { lat: 48.690263, lng: 21.227558 },
    { lat: 48.690377, lng: 21.22777 },
    { lat: 48.690494, lng: 21.22798 },
    { lat: 48.69054, lng: 21.228061 },
    { lat: 48.690614, lng: 21.228191 },
    { lat: 48.690727, lng: 21.228393 },
    { lat: 48.690851, lng: 21.228595 },
    { lat: 48.690974, lng: 21.228795 },
    { lat: 48.691035, lng: 21.228895 },
    { lat: 48.691097, lng: 21.228998 },
    { lat: 48.69122, lng: 21.22919 },
    { lat: 48.69135, lng: 21.229392 },
    { lat: 48.691356, lng: 21.2294 },
    { lat: 48.691482, lng: 21.229595 },
    { lat: 48.691523, lng: 21.229656 },
    { lat: 48.691604, lng: 21.229775 },
    { lat: 48.691651, lng: 21.229847 },
    { lat: 48.691721, lng: 21.229955 },
    { lat: 48.691846, lng: 21.230134 },
    { lat: 48.69188, lng: 21.230183 },
    { lat: 48.692029, lng: 21.230401 },
    { lat: 48.692104, lng: 21.230503 },
    { lat: 48.692137, lng: 21.230547 },
    { lat: 48.692168, lng: 21.230591 },
    { lat: 48.692096, lng: 21.230674 },
    { lat: 48.692009, lng: 21.230773 },
    { lat: 48.692007, lng: 21.230776 },
    { lat: 48.692004, lng: 21.230779 },
    { lat: 48.6918665, lng: 21.2310757 },
    { lat: 48.6940692, lng: 21.2332517 },
    { lat: 48.6942261, lng: 21.2331819 },
    { lat: 48.6942905, lng: 21.2331037 },
    { lat: 48.6943021, lng: 21.2330811 },
    { lat: 48.6943222, lng: 21.2330398 },
    { lat: 48.6943328, lng: 21.2330159 },
    { lat: 48.6943866, lng: 21.2329045 },
    { lat: 48.694392, lng: 21.2328956 },
    { lat: 48.6944671, lng: 21.2327392 },
    { lat: 48.6945013, lng: 21.2326826 },
    { lat: 48.6944887, lng: 21.2326764 },
    { lat: 48.6945915, lng: 21.2323285 },
    { lat: 48.6948087, lng: 21.2319294 },
    { lat: 48.6949896, lng: 21.2314492 },
    { lat: 48.6952323, lng: 21.2307484 },
    { lat: 48.6955982, lng: 21.2303936 },
    { lat: 48.6959299, lng: 21.2297348 },
    { lat: 48.6961051, lng: 21.2294709 },
    { lat: 48.6963193, lng: 21.2293359 },
    { lat: 48.6966427, lng: 21.2292039 },
    { lat: 48.6974797, lng: 21.2280037 },
    { lat: 48.6976739, lng: 21.227479 },
    { lat: 48.6977485, lng: 21.2273473 },
    { lat: 48.6979766, lng: 21.2272605 },
    { lat: 48.6985087, lng: 21.2268219 },
    { lat: 48.6987314, lng: 21.2264241 },
    { lat: 48.6987846, lng: 21.226105 },
    { lat: 48.6989348, lng: 21.2255828 },
    { lat: 48.6990228, lng: 21.2254814 },
    { lat: 48.6993517, lng: 21.2248751 },
    { lat: 48.6996717, lng: 21.2242766 },
    { lat: 48.6997282, lng: 21.2239947 },
    { lat: 48.6997529, lng: 21.2239199 },
    { lat: 48.6998082, lng: 21.2237464 },
    { lat: 48.6999123, lng: 21.2232922 },
    { lat: 48.6999995, lng: 21.2229144 },
    { lat: 48.7000502, lng: 21.2227726 },
    { lat: 48.7001041, lng: 21.2226234 },
    { lat: 48.7002049, lng: 21.2225339 },
    { lat: 48.7001883, lng: 21.2223644 },
    { lat: 48.7001413, lng: 21.2221932 },
    { lat: 48.7001003, lng: 21.2220337 },
    { lat: 48.7000623, lng: 21.2218967 },
    { lat: 48.7000384, lng: 21.221814 },
    { lat: 48.7000225, lng: 21.2217407 },
    { lat: 48.700003, lng: 21.2216914 },
    { lat: 48.6999307, lng: 21.2214657 },
    { lat: 48.6999244, lng: 21.2214469 },
    { lat: 48.6998956, lng: 21.2213507 },
    { lat: 48.6998139, lng: 21.2210797 },
    { lat: 48.6997954, lng: 21.2210261 },
    { lat: 48.6997771, lng: 21.2209811 },
    { lat: 48.6997488, lng: 21.2209058 },
    { lat: 48.6997342, lng: 21.2208673 },
    { lat: 48.6997146, lng: 21.2208192 },
    { lat: 48.6996602, lng: 21.2206613 },
    { lat: 48.6995554, lng: 21.2203681 },
    { lat: 48.6995044, lng: 21.2202114 },
    { lat: 48.6993847, lng: 21.220128 },
    { lat: 48.6993421, lng: 21.2200958 },
    { lat: 48.6991807, lng: 21.2199712 },
    { lat: 48.6991073, lng: 21.2199618 },
    { lat: 48.6990224, lng: 21.2200206 },
    { lat: 48.6990055, lng: 21.2200567 },
    { lat: 48.6989907, lng: 21.2201219 },
    { lat: 48.6989662, lng: 21.2202027 },
    { lat: 48.6989852, lng: 21.2203435 },
    { lat: 48.6989129, lng: 21.2204431 },
    { lat: 48.6989079, lng: 21.2204271 },
    { lat: 48.6987178, lng: 21.2203932 },
    { lat: 48.6984759, lng: 21.2201094 },
    { lat: 48.6983678, lng: 21.2198895 },
    { lat: 48.698274, lng: 21.2196757 },
    { lat: 48.6982236, lng: 21.219561 },
    { lat: 48.698141, lng: 21.2194386 },
    { lat: 48.698106, lng: 21.2193146 },
    { lat: 48.6983529, lng: 21.2189084 },
    { lat: 48.6980836, lng: 21.2185378 },
    { lat: 48.6978338, lng: 21.218945 },
    { lat: 48.6976435, lng: 21.2188606 },
    { lat: 48.6975885, lng: 21.2189001 },
    { lat: 48.6974517, lng: 21.2190315 },
    { lat: 48.6974344, lng: 21.2190476 },
    { lat: 48.6972579, lng: 21.2191952 },
    { lat: 48.6971038, lng: 21.2193087 },
    { lat: 48.6968433, lng: 21.2195775 },
    { lat: 48.6966895, lng: 21.2197362 },
    { lat: 48.6962077, lng: 21.2196598 },
    { lat: 48.6956054, lng: 21.2200286 },
    { lat: 48.6950973, lng: 21.2204321 },
    { lat: 48.6946779, lng: 21.2207504 },
    { lat: 48.6945185, lng: 21.2208652 },
    { lat: 48.6942046, lng: 21.2206913 },
    { lat: 48.6939483, lng: 21.2202803 },
    { lat: 48.6937777, lng: 21.2198912 },
    { lat: 48.6934208, lng: 21.2198249 },
    { lat: 48.6930663, lng: 21.2196849 },
    { lat: 48.6929542, lng: 21.2195924 },
    { lat: 48.6933258, lng: 21.2186301 },
    { lat: 48.6936965, lng: 21.217992 },
    { lat: 48.6937585, lng: 21.2175986 },
    { lat: 48.6938973, lng: 21.2171427 },
    { lat: 48.6938922, lng: 21.2169796 },
    { lat: 48.6942045, lng: 21.2166014 },
    { lat: 48.6947672, lng: 21.2158802 },
    { lat: 48.6946408, lng: 21.2154019 },
    { lat: 48.6944637, lng: 21.215064 },
    { lat: 48.6945084, lng: 21.2146946 },
    { lat: 48.6946892, lng: 21.2144654 },
    { lat: 48.694548, lng: 21.2141232 },
    { lat: 48.6943783, lng: 21.214421 },
    { lat: 48.6941746, lng: 21.2143126 },
    { lat: 48.6940349, lng: 21.2140223 },
    { lat: 48.6936734, lng: 21.2146259 },
    { lat: 48.6935695, lng: 21.214577 },
    { lat: 48.6934518, lng: 21.2146289 },
    { lat: 48.6931204, lng: 21.2145001 },
    { lat: 48.6932698, lng: 21.2142803 },
    { lat: 48.6931542, lng: 21.2141083 },
    { lat: 48.6927278, lng: 21.2147974 },
    { lat: 48.6925685, lng: 21.2147411 },
    { lat: 48.6924738, lng: 21.2145947 },
    { lat: 48.6924668, lng: 21.2145598 },
    { lat: 48.692452, lng: 21.2144821 },
    { lat: 48.6924424, lng: 21.2144521 },
    { lat: 48.6924197, lng: 21.2143334 },
    { lat: 48.6923993, lng: 21.2142776 },
    { lat: 48.6923706, lng: 21.2142269 },
    { lat: 48.6922959, lng: 21.2141885 },
  ],
  KošicemestskáčasťMyslava: [
    { lat: 48.6922959, lng: 21.2141885 },
    { lat: 48.6923706, lng: 21.2142269 },
    { lat: 48.6923993, lng: 21.2142776 },
    { lat: 48.6924197, lng: 21.2143334 },
    { lat: 48.6924424, lng: 21.2144521 },
    { lat: 48.692452, lng: 21.2144821 },
    { lat: 48.6924668, lng: 21.2145598 },
    { lat: 48.6924738, lng: 21.2145947 },
    { lat: 48.6925685, lng: 21.2147411 },
    { lat: 48.6927278, lng: 21.2147974 },
    { lat: 48.6931542, lng: 21.2141083 },
    { lat: 48.6932698, lng: 21.2142803 },
    { lat: 48.6931204, lng: 21.2145001 },
    { lat: 48.6934518, lng: 21.2146289 },
    { lat: 48.6935695, lng: 21.214577 },
    { lat: 48.6936734, lng: 21.2146259 },
    { lat: 48.6940349, lng: 21.2140223 },
    { lat: 48.6941746, lng: 21.2143126 },
    { lat: 48.6943783, lng: 21.214421 },
    { lat: 48.694548, lng: 21.2141232 },
    { lat: 48.6946892, lng: 21.2144654 },
    { lat: 48.6945084, lng: 21.2146946 },
    { lat: 48.6944637, lng: 21.215064 },
    { lat: 48.6946408, lng: 21.2154019 },
    { lat: 48.6947672, lng: 21.2158802 },
    { lat: 48.6942045, lng: 21.2166014 },
    { lat: 48.6938922, lng: 21.2169796 },
    { lat: 48.6938973, lng: 21.2171427 },
    { lat: 48.6937585, lng: 21.2175986 },
    { lat: 48.6936965, lng: 21.217992 },
    { lat: 48.6933258, lng: 21.2186301 },
    { lat: 48.6929542, lng: 21.2195924 },
    { lat: 48.6930663, lng: 21.2196849 },
    { lat: 48.6934208, lng: 21.2198249 },
    { lat: 48.6937777, lng: 21.2198912 },
    { lat: 48.6939483, lng: 21.2202803 },
    { lat: 48.6942046, lng: 21.2206913 },
    { lat: 48.6945185, lng: 21.2208652 },
    { lat: 48.6946779, lng: 21.2207504 },
    { lat: 48.6950973, lng: 21.2204321 },
    { lat: 48.6956054, lng: 21.2200286 },
    { lat: 48.6962077, lng: 21.2196598 },
    { lat: 48.6966895, lng: 21.2197362 },
    { lat: 48.6968433, lng: 21.2195775 },
    { lat: 48.6971038, lng: 21.2193087 },
    { lat: 48.6972579, lng: 21.2191952 },
    { lat: 48.6974344, lng: 21.2190476 },
    { lat: 48.6974517, lng: 21.2190315 },
    { lat: 48.6975885, lng: 21.2189001 },
    { lat: 48.6976435, lng: 21.2188606 },
    { lat: 48.6978338, lng: 21.218945 },
    { lat: 48.6980836, lng: 21.2185378 },
    { lat: 48.6983529, lng: 21.2189084 },
    { lat: 48.698106, lng: 21.2193146 },
    { lat: 48.698141, lng: 21.2194386 },
    { lat: 48.6982236, lng: 21.219561 },
    { lat: 48.698274, lng: 21.2196757 },
    { lat: 48.6983678, lng: 21.2198895 },
    { lat: 48.6984759, lng: 21.2201094 },
    { lat: 48.6987178, lng: 21.2203932 },
    { lat: 48.6989079, lng: 21.2204271 },
    { lat: 48.6989129, lng: 21.2204431 },
    { lat: 48.6989852, lng: 21.2203435 },
    { lat: 48.6989662, lng: 21.2202027 },
    { lat: 48.6989907, lng: 21.2201219 },
    { lat: 48.6990055, lng: 21.2200567 },
    { lat: 48.6990224, lng: 21.2200206 },
    { lat: 48.6991073, lng: 21.2199618 },
    { lat: 48.6991807, lng: 21.2199712 },
    { lat: 48.6993421, lng: 21.2200958 },
    { lat: 48.6993847, lng: 21.220128 },
    { lat: 48.6995044, lng: 21.2202114 },
    { lat: 48.6995554, lng: 21.2203681 },
    { lat: 48.6996602, lng: 21.2206613 },
    { lat: 48.6997146, lng: 21.2208192 },
    { lat: 48.6997342, lng: 21.2208673 },
    { lat: 48.6997488, lng: 21.2209058 },
    { lat: 48.6997771, lng: 21.2209811 },
    { lat: 48.6997954, lng: 21.2210261 },
    { lat: 48.6998139, lng: 21.2210797 },
    { lat: 48.6998956, lng: 21.2213507 },
    { lat: 48.6999244, lng: 21.2214469 },
    { lat: 48.6999307, lng: 21.2214657 },
    { lat: 48.700003, lng: 21.2216914 },
    { lat: 48.7000476, lng: 21.2216783 },
    { lat: 48.7000599, lng: 21.221676 },
    { lat: 48.7001135, lng: 21.2216643 },
    { lat: 48.7001705, lng: 21.2216517 },
    { lat: 48.7003528, lng: 21.2216124 },
    { lat: 48.7004064, lng: 21.2216007 },
    { lat: 48.7004389, lng: 21.2215938 },
    { lat: 48.700506, lng: 21.2215794 },
    { lat: 48.700565, lng: 21.2215631 },
    { lat: 48.7005947, lng: 21.2215392 },
    { lat: 48.7006387, lng: 21.2215044 },
    { lat: 48.7006518, lng: 21.2214935 },
    { lat: 48.700699, lng: 21.2214538 },
    { lat: 48.7007308, lng: 21.2214265 },
    { lat: 48.7007371, lng: 21.2214143 },
    { lat: 48.7007498, lng: 21.2213903 },
    { lat: 48.7007763, lng: 21.2213367 },
    { lat: 48.7008359, lng: 21.221226 },
    { lat: 48.7008519, lng: 21.2211949 },
    { lat: 48.7008688, lng: 21.2211623 },
    { lat: 48.7008742, lng: 21.2211534 },
    { lat: 48.7008943, lng: 21.2211135 },
    { lat: 48.7009103, lng: 21.2210849 },
    { lat: 48.7010559, lng: 21.2208102 },
    { lat: 48.7010708, lng: 21.2207817 },
    { lat: 48.7011013, lng: 21.2207499 },
    { lat: 48.7011361, lng: 21.2207122 },
    { lat: 48.7011547, lng: 21.2206938 },
    { lat: 48.7011794, lng: 21.2206927 },
    { lat: 48.7011906, lng: 21.2206926 },
    { lat: 48.7012143, lng: 21.2206917 },
    { lat: 48.7012537, lng: 21.2206906 },
    { lat: 48.7012728, lng: 21.2206925 },
    { lat: 48.7013032, lng: 21.2206946 },
    { lat: 48.7013359, lng: 21.2206972 },
    { lat: 48.7013901, lng: 21.2207012 },
    { lat: 48.7014195, lng: 21.2207034 },
    { lat: 48.7014394, lng: 21.2206956 },
    { lat: 48.7014739, lng: 21.2206817 },
    { lat: 48.7015206, lng: 21.2206627 },
    { lat: 48.701524, lng: 21.2206618 },
    { lat: 48.7015579, lng: 21.2206315 },
    { lat: 48.7015973, lng: 21.2205967 },
    { lat: 48.7018523, lng: 21.2202928 },
    { lat: 48.7020055, lng: 21.220083 },
    { lat: 48.7021889, lng: 21.219936 },
    { lat: 48.7025107, lng: 21.219824 },
    { lat: 48.7027743, lng: 21.2198722 },
    { lat: 48.7031277, lng: 21.2200487 },
    { lat: 48.7031912, lng: 21.2200655 },
    { lat: 48.7041284, lng: 21.2203457 },
    { lat: 48.7044395, lng: 21.2204326 },
    { lat: 48.7046072, lng: 21.2204673 },
    { lat: 48.7047156, lng: 21.2205177 },
    { lat: 48.7048058, lng: 21.2205576 },
    { lat: 48.7048373, lng: 21.2205959 },
    { lat: 48.7048584, lng: 21.2206215 },
    { lat: 48.7048693, lng: 21.2206464 },
    { lat: 48.7048946, lng: 21.2207009 },
    { lat: 48.7049256, lng: 21.2206802 },
    { lat: 48.7049563, lng: 21.2206555 },
    { lat: 48.7050463, lng: 21.2205828 },
    { lat: 48.7052769, lng: 21.2203975 },
    { lat: 48.7054304, lng: 21.2203078 },
    { lat: 48.7055421, lng: 21.2202429 },
    { lat: 48.7055564, lng: 21.2202343 },
    { lat: 48.7055898, lng: 21.2200763 },
    { lat: 48.705624, lng: 21.2199106 },
    { lat: 48.7056711, lng: 21.2196906 },
    { lat: 48.7056614, lng: 21.2195599 },
    { lat: 48.7057009, lng: 21.2195581 },
    { lat: 48.7056904, lng: 21.2195136 },
    { lat: 48.7056733, lng: 21.2194809 },
    { lat: 48.705664, lng: 21.2194631 },
    { lat: 48.7056411, lng: 21.2194512 },
    { lat: 48.7056109, lng: 21.2194526 },
    { lat: 48.7055831, lng: 21.2192123 },
    { lat: 48.7055719, lng: 21.2190681 },
    { lat: 48.7055578, lng: 21.2190502 },
    { lat: 48.705198, lng: 21.2185592 },
    { lat: 48.7052188, lng: 21.2185392 },
    { lat: 48.7052417, lng: 21.2184439 },
    { lat: 48.7052615, lng: 21.218358 },
    { lat: 48.7053271, lng: 21.2181168 },
    { lat: 48.7053358, lng: 21.2180716 },
    { lat: 48.705419, lng: 21.2176698 },
    { lat: 48.7054484, lng: 21.2175309 },
    { lat: 48.7054709, lng: 21.2174224 },
    { lat: 48.7054982, lng: 21.2172865 },
    { lat: 48.7055413, lng: 21.2172634 },
    { lat: 48.7056975, lng: 21.2171807 },
    { lat: 48.7057051, lng: 21.2171719 },
    { lat: 48.7058052, lng: 21.2170636 },
    { lat: 48.7059702, lng: 21.2168723 },
    { lat: 48.7061369, lng: 21.2166594 },
    { lat: 48.7062191, lng: 21.2165509 },
    { lat: 48.7062955, lng: 21.2164397 },
    { lat: 48.7063735, lng: 21.2163454 },
    { lat: 48.7063407, lng: 21.2163028 },
    { lat: 48.7063536, lng: 21.2161362 },
    { lat: 48.7063457, lng: 21.2160292 },
    { lat: 48.7062748, lng: 21.2157773 },
    { lat: 48.7062096, lng: 21.2156344 },
    { lat: 48.7063813, lng: 21.2150435 },
    { lat: 48.7064949, lng: 21.2146841 },
    { lat: 48.7066066, lng: 21.2143323 },
    { lat: 48.7066139, lng: 21.2143107 },
    { lat: 48.7066333, lng: 21.2142481 },
    { lat: 48.7066825, lng: 21.2140935 },
    { lat: 48.706708, lng: 21.2140133 },
    { lat: 48.7067937, lng: 21.213871 },
    { lat: 48.706844, lng: 21.2138247 },
    { lat: 48.7069005, lng: 21.2137629 },
    { lat: 48.7069581, lng: 21.2136954 },
    { lat: 48.7070101, lng: 21.2136332 },
    { lat: 48.7070522, lng: 21.2135761 },
    { lat: 48.7070717, lng: 21.2135501 },
    { lat: 48.7071764, lng: 21.2134054 },
    { lat: 48.7072844, lng: 21.2132587 },
    { lat: 48.7073901, lng: 21.2131129 },
    { lat: 48.7075022, lng: 21.2129578 },
    { lat: 48.7076091, lng: 21.2128108 },
    { lat: 48.7076306, lng: 21.2127818 },
    { lat: 48.7077722, lng: 21.2125911 },
    { lat: 48.7078507, lng: 21.2124786 },
    { lat: 48.7078971, lng: 21.2124133 },
    { lat: 48.7079627, lng: 21.2123159 },
    { lat: 48.7080208, lng: 21.2122312 },
    { lat: 48.7080746, lng: 21.2121526 },
    { lat: 48.7081369, lng: 21.2120569 },
    { lat: 48.7083149, lng: 21.2117752 },
    { lat: 48.708419, lng: 21.211612 },
    { lat: 48.7085294, lng: 21.2114366 },
    { lat: 48.7086153, lng: 21.2113019 },
    { lat: 48.708714, lng: 21.2111476 },
    { lat: 48.7088211, lng: 21.2109768 },
    { lat: 48.7089327, lng: 21.210801 },
    { lat: 48.7090625, lng: 21.2105968 },
    { lat: 48.7091514, lng: 21.210455 },
    { lat: 48.7092394, lng: 21.2103162 },
    { lat: 48.7093681, lng: 21.2101138 },
    { lat: 48.7094667, lng: 21.2099575 },
    { lat: 48.7095989, lng: 21.2097556 },
    { lat: 48.709704, lng: 21.2095931 },
    { lat: 48.7097835, lng: 21.2094705 },
    { lat: 48.7098596, lng: 21.2093511 },
    { lat: 48.7099702, lng: 21.2091814 },
    { lat: 48.7100561, lng: 21.2090473 },
    { lat: 48.7100926, lng: 21.2089909 },
    { lat: 48.710142, lng: 21.2089161 },
    { lat: 48.7102011, lng: 21.2088263 },
    { lat: 48.7102076, lng: 21.2088159 },
    { lat: 48.7103209, lng: 21.2086606 },
    { lat: 48.7103588, lng: 21.2086155 },
    { lat: 48.7103219, lng: 21.208549 },
    { lat: 48.7103698, lng: 21.2084982 },
    { lat: 48.710495, lng: 21.2083282 },
    { lat: 48.7105328, lng: 21.2082759 },
    { lat: 48.7105695, lng: 21.2082266 },
    { lat: 48.7105835, lng: 21.2082057 },
    { lat: 48.7105931, lng: 21.208189 },
    { lat: 48.7106015, lng: 21.2081705 },
    { lat: 48.7107676, lng: 21.2083026 },
    { lat: 48.7107731, lng: 21.2083343 },
    { lat: 48.7107761, lng: 21.2083932 },
    { lat: 48.7107779, lng: 21.2084124 },
    { lat: 48.7107795, lng: 21.2084301 },
    { lat: 48.71079, lng: 21.208512 },
    { lat: 48.7108019, lng: 21.2085689 },
    { lat: 48.7108241, lng: 21.2086667 },
    { lat: 48.7108447, lng: 21.2087475 },
    { lat: 48.7108636, lng: 21.2088139 },
    { lat: 48.7108789, lng: 21.2088684 },
    { lat: 48.7108977, lng: 21.2089319 },
    { lat: 48.7109201, lng: 21.2090023 },
    { lat: 48.7109784, lng: 21.2089931 },
    { lat: 48.7110356, lng: 21.208984 },
    { lat: 48.7110388, lng: 21.2089835 },
    { lat: 48.7110803, lng: 21.2089773 },
    { lat: 48.7110904, lng: 21.2089755 },
    { lat: 48.7112206, lng: 21.2088578 },
    { lat: 48.7110975, lng: 21.2085235 },
    { lat: 48.7110128, lng: 21.2082965 },
    { lat: 48.7109809, lng: 21.208209 },
    { lat: 48.7108948, lng: 21.2079771 },
    { lat: 48.710886, lng: 21.2077657 },
    { lat: 48.7109348, lng: 21.2077095 },
    { lat: 48.7109359, lng: 21.2077076 },
    { lat: 48.710951, lng: 21.2076873 },
    { lat: 48.7109595, lng: 21.2076703 },
    { lat: 48.7109678, lng: 21.2076483 },
    { lat: 48.7109707, lng: 21.2076374 },
    { lat: 48.7109737, lng: 21.2076226 },
    { lat: 48.7109752, lng: 21.2075993 },
    { lat: 48.710972, lng: 21.20757 },
    { lat: 48.7109687, lng: 21.2075353 },
    { lat: 48.710948, lng: 21.2073789 },
    { lat: 48.7110103, lng: 21.2073531 },
    { lat: 48.711058, lng: 21.2073329 },
    { lat: 48.7110981, lng: 21.2073165 },
    { lat: 48.7111091, lng: 21.2073118 },
    { lat: 48.711167, lng: 21.2072884 },
    { lat: 48.7111992, lng: 21.2072747 },
    { lat: 48.7112736, lng: 21.2072444 },
    { lat: 48.7112847, lng: 21.2072401 },
    { lat: 48.7113235, lng: 21.2072237 },
    { lat: 48.7113758, lng: 21.2072018 },
    { lat: 48.7114836, lng: 21.2071574 },
    { lat: 48.7114925, lng: 21.2071539 },
    { lat: 48.7119958, lng: 21.2069462 },
    { lat: 48.7122669, lng: 21.2068345 },
    { lat: 48.7123203, lng: 21.2068128 },
    { lat: 48.7123849, lng: 21.2067903 },
    { lat: 48.7124457, lng: 21.2067693 },
    { lat: 48.7125562, lng: 21.2067159 },
    { lat: 48.7122877, lng: 21.2060258 },
    { lat: 48.7121494, lng: 21.2056712 },
    { lat: 48.7120116, lng: 21.2053307 },
    { lat: 48.7119526, lng: 21.2050782 },
    { lat: 48.711955, lng: 21.205025 },
    { lat: 48.7120036, lng: 21.2050769 },
    { lat: 48.7122746, lng: 21.2051776 },
    { lat: 48.7124757, lng: 21.2052369 },
    { lat: 48.7125189, lng: 21.2052495 },
    { lat: 48.7128345, lng: 21.2054104 },
    { lat: 48.7130314, lng: 21.2053718 },
    { lat: 48.7132498, lng: 21.2054459 },
    { lat: 48.7133607, lng: 21.2054305 },
    { lat: 48.7133618, lng: 21.2054301 },
    { lat: 48.7138096, lng: 21.2065748 },
    { lat: 48.7138242, lng: 21.2066108 },
    { lat: 48.7141831, lng: 21.2065723 },
    { lat: 48.7141863, lng: 21.2066351 },
    { lat: 48.7142713, lng: 21.2066194 },
    { lat: 48.7142786, lng: 21.2066745 },
    { lat: 48.7153001, lng: 21.2063384 },
    { lat: 48.7159221, lng: 21.2061145 },
    { lat: 48.7161324, lng: 21.2060499 },
    { lat: 48.716184, lng: 21.2060514 },
    { lat: 48.7163793, lng: 21.2060822 },
    { lat: 48.7164921, lng: 21.2060931 },
    { lat: 48.7165468, lng: 21.2060774 },
    { lat: 48.7165579, lng: 21.2060744 },
    { lat: 48.7167029, lng: 21.2060334 },
    { lat: 48.7168112, lng: 21.2060029 },
    { lat: 48.717012, lng: 21.2059461 },
    { lat: 48.7170967, lng: 21.2059222 },
    { lat: 48.7172663, lng: 21.2058741 },
    { lat: 48.7174359, lng: 21.2058263 },
    { lat: 48.7176188, lng: 21.2057747 },
    { lat: 48.7181576, lng: 21.2056221 },
    { lat: 48.7182268, lng: 21.2056029 },
    { lat: 48.7183229, lng: 21.2055796 },
    { lat: 48.7183274, lng: 21.2055786 },
    { lat: 48.7184925, lng: 21.2055354 },
    { lat: 48.7186667, lng: 21.2054905 },
    { lat: 48.7188653, lng: 21.205436 },
    { lat: 48.7188887, lng: 21.2054293 },
    { lat: 48.7190762, lng: 21.2053781 },
    { lat: 48.7193016, lng: 21.2053153 },
    { lat: 48.7194856, lng: 21.2052647 },
    { lat: 48.7195147, lng: 21.2052566 },
    { lat: 48.7196391, lng: 21.2052045 },
    { lat: 48.719709, lng: 21.2051731 },
    { lat: 48.7197334, lng: 21.2051621 },
    { lat: 48.7200297, lng: 21.2050301 },
    { lat: 48.7203911, lng: 21.2048178 },
    { lat: 48.7205782, lng: 21.2047898 },
    { lat: 48.7207667, lng: 21.2048796 },
    { lat: 48.7210999, lng: 21.2048829 },
    { lat: 48.721263, lng: 21.2048808 },
    { lat: 48.7212613, lng: 21.2047544 },
    { lat: 48.7212636, lng: 21.2047186 },
    { lat: 48.7212812, lng: 21.2045994 },
    { lat: 48.7213483, lng: 21.2044417 },
    { lat: 48.7214926, lng: 21.2042342 },
    { lat: 48.7216646, lng: 21.2040865 },
    { lat: 48.7218546, lng: 21.2039691 },
    { lat: 48.722252, lng: 21.2037931 },
    { lat: 48.7224816, lng: 21.2036901 },
    { lat: 48.7225853, lng: 21.2036577 },
    { lat: 48.7227731, lng: 21.2035444 },
    { lat: 48.7229537, lng: 21.2034189 },
    { lat: 48.7231289, lng: 21.2032623 },
    { lat: 48.7232518, lng: 21.2031972 },
    { lat: 48.7233293, lng: 21.2030868 },
    { lat: 48.7234118, lng: 21.2030271 },
    { lat: 48.7235045, lng: 21.2029681 },
    { lat: 48.7237061, lng: 21.2028659 },
    { lat: 48.723943, lng: 21.2028083 },
    { lat: 48.7241852, lng: 21.2027101 },
    { lat: 48.7245463, lng: 21.2025609 },
    { lat: 48.7254403, lng: 21.2020024 },
    { lat: 48.7255723, lng: 21.2018668 },
    { lat: 48.725881, lng: 21.201165 },
    { lat: 48.726139, lng: 21.200661 },
    { lat: 48.726544, lng: 21.19955 },
    { lat: 48.726864, lng: 21.198944 },
    { lat: 48.726922, lng: 21.199027 },
    { lat: 48.727293, lng: 21.19854 },
    { lat: 48.727365, lng: 21.198427 },
    { lat: 48.727479, lng: 21.198329 },
    { lat: 48.727519, lng: 21.198239 },
    { lat: 48.727555, lng: 21.198062 },
    { lat: 48.727794, lng: 21.197708 },
    { lat: 48.727836, lng: 21.197588 },
    { lat: 48.727873, lng: 21.197393 },
    { lat: 48.727862, lng: 21.197089 },
    { lat: 48.727917, lng: 21.196878 },
    { lat: 48.728034, lng: 21.196652 },
    { lat: 48.728115, lng: 21.196521 },
    { lat: 48.728243, lng: 21.196218 },
    { lat: 48.728321, lng: 21.195921 },
    { lat: 48.728385, lng: 21.19559 },
    { lat: 48.728471, lng: 21.195538 },
    { lat: 48.728412, lng: 21.195501 },
    { lat: 48.72835, lng: 21.195334 },
    { lat: 48.728344, lng: 21.195103 },
    { lat: 48.728371, lng: 21.195012 },
    { lat: 48.728442, lng: 21.194919 },
    { lat: 48.728418, lng: 21.194639 },
    { lat: 48.728465, lng: 21.194502 },
    { lat: 48.728514, lng: 21.194126 },
    { lat: 48.728517, lng: 21.193794 },
    { lat: 48.728577, lng: 21.193389 },
    { lat: 48.7286297, lng: 21.1932564 },
    { lat: 48.728683, lng: 21.193177 },
    { lat: 48.728828, lng: 21.192846 },
    { lat: 48.728938, lng: 21.192688 },
    { lat: 48.729074, lng: 21.192414 },
    { lat: 48.729252, lng: 21.192113 },
    { lat: 48.729474, lng: 21.191972 },
    { lat: 48.729404, lng: 21.191762 },
    { lat: 48.729697, lng: 21.191374 },
    { lat: 48.729774, lng: 21.191338 },
    { lat: 48.729726, lng: 21.191145 },
    { lat: 48.729701, lng: 21.19097 },
    { lat: 48.729601, lng: 21.190414 },
    { lat: 48.729542, lng: 21.190145 },
    { lat: 48.729521, lng: 21.189931 },
    { lat: 48.729743, lng: 21.189205 },
    { lat: 48.729974, lng: 21.188818 },
    { lat: 48.730063, lng: 21.188787 },
    { lat: 48.730079, lng: 21.188666 },
    { lat: 48.730149, lng: 21.188574 },
    { lat: 48.730248, lng: 21.188395 },
    { lat: 48.730357, lng: 21.188181 },
    { lat: 48.730645, lng: 21.187765 },
    { lat: 48.730913, lng: 21.187245 },
    { lat: 48.731201, lng: 21.186977 },
    { lat: 48.731525, lng: 21.186597 },
    { lat: 48.73167, lng: 21.186497 },
    { lat: 48.731982, lng: 21.186409 },
    { lat: 48.732241, lng: 21.186254 },
    { lat: 48.732345, lng: 21.186156 },
    { lat: 48.732367, lng: 21.186069 },
    { lat: 48.732159, lng: 21.18583 },
    { lat: 48.731907, lng: 21.185671 },
    { lat: 48.731422, lng: 21.185068 },
    { lat: 48.731329, lng: 21.184783 },
    { lat: 48.731142, lng: 21.184531 },
    { lat: 48.731104, lng: 21.184357 },
    { lat: 48.731253, lng: 21.1838 },
    { lat: 48.731267, lng: 21.183461 },
    { lat: 48.731169, lng: 21.183089 },
    { lat: 48.731066, lng: 21.182366 },
    { lat: 48.731103, lng: 21.180833 },
    { lat: 48.731198, lng: 21.180711 },
    { lat: 48.731246, lng: 21.180621 },
    { lat: 48.731628, lng: 21.180785 },
    { lat: 48.731812, lng: 21.181008 },
    { lat: 48.732131, lng: 21.181007 },
    { lat: 48.732858, lng: 21.180937 },
    { lat: 48.733012, lng: 21.180942 },
    { lat: 48.733033, lng: 21.180941 },
    { lat: 48.73303, lng: 21.180789 },
    { lat: 48.733, lng: 21.18043 },
    { lat: 48.733079, lng: 21.180431 },
    { lat: 48.733094, lng: 21.179811 },
    { lat: 48.733108, lng: 21.179326 },
    { lat: 48.733276, lng: 21.178596 },
    { lat: 48.732969, lng: 21.178542 },
    { lat: 48.732705, lng: 21.178618 },
    { lat: 48.731684, lng: 21.179259 },
    { lat: 48.731676, lng: 21.178984 },
    { lat: 48.731536, lng: 21.1785 },
    { lat: 48.731533, lng: 21.178208 },
    { lat: 48.731625, lng: 21.177717 },
    { lat: 48.731623, lng: 21.177529 },
    { lat: 48.731319, lng: 21.176974 },
    { lat: 48.731242, lng: 21.176705 },
    { lat: 48.731192, lng: 21.176642 },
    { lat: 48.731196, lng: 21.176303 },
    { lat: 48.731124, lng: 21.176336 },
    { lat: 48.731047, lng: 21.17637 },
    { lat: 48.730869, lng: 21.176006 },
    { lat: 48.730871, lng: 21.175738 },
    { lat: 48.730897, lng: 21.175178 },
    { lat: 48.730917, lng: 21.174404 },
    { lat: 48.730203, lng: 21.174057 },
    { lat: 48.730197, lng: 21.173274 },
    { lat: 48.730314, lng: 21.172401 },
    { lat: 48.7303, lng: 21.172265 },
    { lat: 48.730334, lng: 21.171795 },
    { lat: 48.730233, lng: 21.171769 },
    { lat: 48.729568, lng: 21.171035 },
    { lat: 48.728877, lng: 21.170399 },
    { lat: 48.728747, lng: 21.170419 },
    { lat: 48.728649, lng: 21.170358 },
    { lat: 48.728456, lng: 21.170337 },
    { lat: 48.728395, lng: 21.170395 },
    { lat: 48.728282, lng: 21.17036 },
    { lat: 48.727983, lng: 21.170428 },
    { lat: 48.727292, lng: 21.170344 },
    { lat: 48.727222, lng: 21.170238 },
    { lat: 48.727119, lng: 21.170269 },
    { lat: 48.726642, lng: 21.169818 },
    { lat: 48.726492, lng: 21.169822 },
    { lat: 48.726449, lng: 21.169681 },
    { lat: 48.726368, lng: 21.169585 },
    { lat: 48.726243, lng: 21.169462 },
    { lat: 48.725979, lng: 21.169155 },
    { lat: 48.725538, lng: 21.16926 },
    { lat: 48.72517, lng: 21.169113 },
    { lat: 48.724035, lng: 21.168801 },
    { lat: 48.724029, lng: 21.16894 },
    { lat: 48.723953, lng: 21.169107 },
    { lat: 48.723907, lng: 21.16919 },
    { lat: 48.723864, lng: 21.169266 },
    { lat: 48.723876, lng: 21.169307 },
    { lat: 48.723811, lng: 21.169562 },
    { lat: 48.723798, lng: 21.169618 },
    { lat: 48.723782, lng: 21.16961 },
    { lat: 48.723776, lng: 21.169606 },
    { lat: 48.723586, lng: 21.170003 },
    { lat: 48.723564, lng: 21.169998 },
    { lat: 48.723538, lng: 21.169994 },
    { lat: 48.723475, lng: 21.169981 },
    { lat: 48.72345, lng: 21.169976 },
    { lat: 48.723519, lng: 21.170882 },
    { lat: 48.723627, lng: 21.171454 },
    { lat: 48.723676, lng: 21.171708 },
    { lat: 48.723798, lng: 21.172357 },
    { lat: 48.723804, lng: 21.17237 },
    { lat: 48.723894, lng: 21.172598 },
    { lat: 48.72396, lng: 21.172767 },
    { lat: 48.723968, lng: 21.172784 },
    { lat: 48.723975, lng: 21.172804 },
    { lat: 48.723995, lng: 21.172852 },
    { lat: 48.724021, lng: 21.172828 },
    { lat: 48.724062, lng: 21.172789 },
    { lat: 48.724326, lng: 21.173694 },
    { lat: 48.724342, lng: 21.174281 },
    { lat: 48.724303, lng: 21.174842 },
    { lat: 48.724146, lng: 21.175472 },
    { lat: 48.724091, lng: 21.175548 },
    { lat: 48.723975, lng: 21.175711 },
    { lat: 48.723889, lng: 21.17589 },
    { lat: 48.723769, lng: 21.176186 },
    { lat: 48.723698, lng: 21.176361 },
    { lat: 48.723646, lng: 21.17649 },
    { lat: 48.723527, lng: 21.176743 },
    { lat: 48.723488, lng: 21.176797 },
    { lat: 48.723442, lng: 21.1769 },
    { lat: 48.723227, lng: 21.177183 },
    { lat: 48.723115, lng: 21.177328 },
    { lat: 48.723046, lng: 21.177421 },
    { lat: 48.723009, lng: 21.177473 },
    { lat: 48.72289, lng: 21.177633 },
    { lat: 48.722799, lng: 21.17778 },
    { lat: 48.722641, lng: 21.177999 },
    { lat: 48.722565, lng: 21.178133 },
    { lat: 48.722354, lng: 21.178568 },
    { lat: 48.722224, lng: 21.178814 },
    { lat: 48.722125, lng: 21.179006 },
    { lat: 48.722132, lng: 21.179016 },
    { lat: 48.72215, lng: 21.179043 },
    { lat: 48.722139, lng: 21.179122 },
    { lat: 48.72204, lng: 21.179527 },
    { lat: 48.721998, lng: 21.179606 },
    { lat: 48.721937, lng: 21.179766 },
    { lat: 48.721902, lng: 21.17986 },
    { lat: 48.721921, lng: 21.179875 },
    { lat: 48.721863, lng: 21.179989 },
    { lat: 48.721847, lng: 21.179973 },
    { lat: 48.721817, lng: 21.179942 },
    { lat: 48.721781, lng: 21.179997 },
    { lat: 48.721763, lng: 21.179981 },
    { lat: 48.721724, lng: 21.180083 },
    { lat: 48.721695, lng: 21.180135 },
    { lat: 48.721663, lng: 21.180179 },
    { lat: 48.721621, lng: 21.180177 },
    { lat: 48.721499, lng: 21.180182 },
    { lat: 48.721408, lng: 21.180308 },
    { lat: 48.721257, lng: 21.180376 },
    { lat: 48.721208, lng: 21.180434 },
    { lat: 48.721127, lng: 21.180528 },
    { lat: 48.721068, lng: 21.180619 },
    { lat: 48.720963, lng: 21.180858 },
    { lat: 48.720795, lng: 21.181035 },
    { lat: 48.720646, lng: 21.181137 },
    { lat: 48.720593, lng: 21.181265 },
    { lat: 48.720582, lng: 21.181288 },
    { lat: 48.72048, lng: 21.18153 },
    { lat: 48.720464, lng: 21.18157 },
    { lat: 48.720445, lng: 21.181613 },
    { lat: 48.720393, lng: 21.181773 },
    { lat: 48.720332, lng: 21.181923 },
    { lat: 48.72032, lng: 21.181951 },
    { lat: 48.720319, lng: 21.181954 },
    { lat: 48.720324, lng: 21.182177 },
    { lat: 48.720325, lng: 21.182192 },
    { lat: 48.720325, lng: 21.182219 },
    { lat: 48.720249, lng: 21.182388 },
    { lat: 48.720174, lng: 21.182503 },
    { lat: 48.720053, lng: 21.182524 },
    { lat: 48.719985, lng: 21.18255 },
    { lat: 48.719796, lng: 21.18272 },
    { lat: 48.719748, lng: 21.182827 },
    { lat: 48.719738, lng: 21.182893 },
    { lat: 48.719696, lng: 21.18302 },
    { lat: 48.719659, lng: 21.183086 },
    { lat: 48.719597, lng: 21.183179 },
    { lat: 48.719576, lng: 21.183169 },
    { lat: 48.719565, lng: 21.183164 },
    { lat: 48.719018, lng: 21.182727 },
    { lat: 48.718987, lng: 21.182702 },
    { lat: 48.718937, lng: 21.182662 },
    { lat: 48.7189, lng: 21.182633 },
    { lat: 48.718807, lng: 21.182804 },
    { lat: 48.718662, lng: 21.182645 },
    { lat: 48.718579, lng: 21.182558 },
    { lat: 48.718533, lng: 21.182508 },
    { lat: 48.71848, lng: 21.182444 },
    { lat: 48.718386, lng: 21.182658 },
    { lat: 48.718414, lng: 21.182706 },
    { lat: 48.718397, lng: 21.182745 },
    { lat: 48.718352, lng: 21.182853 },
    { lat: 48.718334, lng: 21.182855 },
    { lat: 48.718274, lng: 21.182964 },
    { lat: 48.718283, lng: 21.182976 },
    { lat: 48.718241, lng: 21.18305 },
    { lat: 48.718254, lng: 21.183064 },
    { lat: 48.718242, lng: 21.183086 },
    { lat: 48.718223, lng: 21.183057 },
    { lat: 48.718179, lng: 21.183134 },
    { lat: 48.718199, lng: 21.183162 },
    { lat: 48.718159, lng: 21.183228 },
    { lat: 48.718112, lng: 21.183271 },
    { lat: 48.718013, lng: 21.183444 },
    { lat: 48.71797, lng: 21.183609 },
    { lat: 48.717907, lng: 21.18373 },
    { lat: 48.71786, lng: 21.18384 },
    { lat: 48.71789, lng: 21.183887 },
    { lat: 48.717816, lng: 21.184061 },
    { lat: 48.7178, lng: 21.184053 },
    { lat: 48.717752, lng: 21.184185 },
    { lat: 48.717723, lng: 21.184259 },
    { lat: 48.7177, lng: 21.184335 },
    { lat: 48.717665, lng: 21.18451 },
    { lat: 48.717674, lng: 21.184521 },
    { lat: 48.717689, lng: 21.184537 },
    { lat: 48.717685, lng: 21.184667 },
    { lat: 48.717684, lng: 21.184737 },
    { lat: 48.717699, lng: 21.184767 },
    { lat: 48.717644, lng: 21.184959 },
    { lat: 48.717576, lng: 21.185008 },
    { lat: 48.717588, lng: 21.185057 },
    { lat: 48.717554, lng: 21.185138 },
    { lat: 48.717494, lng: 21.185287 },
    { lat: 48.717486, lng: 21.18528 },
    { lat: 48.717441, lng: 21.185395 },
    { lat: 48.717412, lng: 21.185497 },
    { lat: 48.717463, lng: 21.185573 },
    { lat: 48.717444, lng: 21.185708 },
    { lat: 48.717426, lng: 21.185771 },
    { lat: 48.717413, lng: 21.185836 },
    { lat: 48.71736, lng: 21.18598 },
    { lat: 48.717331, lng: 21.186195 },
    { lat: 48.717281, lng: 21.186237 },
    { lat: 48.71725, lng: 21.186183 },
    { lat: 48.717163, lng: 21.186256 },
    { lat: 48.717112, lng: 21.186175 },
    { lat: 48.717035, lng: 21.186183 },
    { lat: 48.7169, lng: 21.186129 },
    { lat: 48.71672, lng: 21.185916 },
    { lat: 48.716376, lng: 21.186211 },
    { lat: 48.71616, lng: 21.186143 },
    { lat: 48.716131, lng: 21.186076 },
    { lat: 48.716062, lng: 21.18609 },
    { lat: 48.7159, lng: 21.185974 },
    { lat: 48.715738, lng: 21.18595 },
    { lat: 48.715308, lng: 21.185774 },
    { lat: 48.715245, lng: 21.185712 },
    { lat: 48.715196, lng: 21.18572 },
    { lat: 48.715012, lng: 21.185646 },
    { lat: 48.714761, lng: 21.185638 },
    { lat: 48.714586, lng: 21.185623 },
    { lat: 48.714394, lng: 21.18552 },
    { lat: 48.714341, lng: 21.185492 },
    { lat: 48.714272, lng: 21.185426 },
    { lat: 48.714227, lng: 21.185435 },
    { lat: 48.714015, lng: 21.185375 },
    { lat: 48.713882, lng: 21.185328 },
    { lat: 48.713792, lng: 21.185335 },
    { lat: 48.71363, lng: 21.185216 },
    { lat: 48.713565, lng: 21.185166 },
    { lat: 48.713473, lng: 21.185095 },
    { lat: 48.71339, lng: 21.185105 },
    { lat: 48.713309, lng: 21.185091 },
    { lat: 48.713224, lng: 21.185014 },
    { lat: 48.713115, lng: 21.184967 },
    { lat: 48.71299, lng: 21.184935 },
    { lat: 48.712958, lng: 21.185025 },
    { lat: 48.712836, lng: 21.185344 },
    { lat: 48.712668, lng: 21.185424 },
    { lat: 48.712432, lng: 21.185822 },
    { lat: 48.711853, lng: 21.186239 },
    { lat: 48.711581, lng: 21.186497 },
    { lat: 48.711321, lng: 21.186823 },
    { lat: 48.711287, lng: 21.186866 },
    { lat: 48.711242, lng: 21.186921 },
    { lat: 48.711229, lng: 21.186938 },
    { lat: 48.711191, lng: 21.186984 },
    { lat: 48.711153, lng: 21.187032 },
    { lat: 48.711143, lng: 21.187043 },
    { lat: 48.711101, lng: 21.187096 },
    { lat: 48.711068, lng: 21.187328 },
    { lat: 48.710871, lng: 21.187444 },
    { lat: 48.710836, lng: 21.187464 },
    { lat: 48.710547, lng: 21.187634 },
    { lat: 48.710437, lng: 21.187699 },
    { lat: 48.710221, lng: 21.187952 },
    { lat: 48.710192, lng: 21.187988 },
    { lat: 48.710052, lng: 21.18818 },
    { lat: 48.709635, lng: 21.188761 },
    { lat: 48.709231, lng: 21.188764 },
    { lat: 48.708679, lng: 21.188911 },
    { lat: 48.708658, lng: 21.188936 },
    { lat: 48.708639, lng: 21.188959 },
    { lat: 48.7086, lng: 21.189006 },
    { lat: 48.70858, lng: 21.18903 },
    { lat: 48.708565, lng: 21.189047 },
    { lat: 48.708398, lng: 21.189235 },
    { lat: 48.708312, lng: 21.18933 },
    { lat: 48.708202, lng: 21.189452 },
    { lat: 48.708025, lng: 21.18965 },
    { lat: 48.708016, lng: 21.189659 },
    { lat: 48.707993, lng: 21.189671 },
    { lat: 48.707688, lng: 21.189826 },
    { lat: 48.707541, lng: 21.19001 },
    { lat: 48.707246, lng: 21.190181 },
    { lat: 48.706942, lng: 21.191376 },
    { lat: 48.706664, lng: 21.191686 },
    { lat: 48.706554, lng: 21.191808 },
    { lat: 48.706291, lng: 21.19221 },
    { lat: 48.705803, lng: 21.192768 },
    { lat: 48.705304, lng: 21.193109 },
    { lat: 48.70499, lng: 21.193475 },
    { lat: 48.704429, lng: 21.193772 },
    { lat: 48.704217, lng: 21.193799 },
    { lat: 48.704172, lng: 21.193805 },
    { lat: 48.704018, lng: 21.194043 },
    { lat: 48.703952, lng: 21.194094 },
    { lat: 48.703882, lng: 21.194099 },
    { lat: 48.703661, lng: 21.19393 },
    { lat: 48.70351, lng: 21.193931 },
    { lat: 48.703479, lng: 21.193932 },
    { lat: 48.703467, lng: 21.194062 },
    { lat: 48.703419, lng: 21.194117 },
    { lat: 48.703252, lng: 21.19421 },
    { lat: 48.703032, lng: 21.194308 },
    { lat: 48.703015, lng: 21.194276 },
    { lat: 48.703003, lng: 21.194258 },
    { lat: 48.702803, lng: 21.193919 },
    { lat: 48.702774, lng: 21.193871 },
    { lat: 48.702773, lng: 21.19387 },
    { lat: 48.70276, lng: 21.193834 },
    { lat: 48.70275, lng: 21.193796 },
    { lat: 48.702613, lng: 21.19405 },
    { lat: 48.702294, lng: 21.194492 },
    { lat: 48.702186, lng: 21.194619 },
    { lat: 48.702024, lng: 21.194858 },
    { lat: 48.701788, lng: 21.195253 },
    { lat: 48.701664, lng: 21.195454 },
    { lat: 48.701492, lng: 21.195721 },
    { lat: 48.701263, lng: 21.196087 },
    { lat: 48.700979, lng: 21.196587 },
    { lat: 48.700556, lng: 21.197145 },
    { lat: 48.70025, lng: 21.197433 },
    { lat: 48.69998, lng: 21.197667 },
    { lat: 48.69972, lng: 21.197808 },
    { lat: 48.699376, lng: 21.197897 },
    { lat: 48.699016, lng: 21.19798 },
    { lat: 48.698582, lng: 21.198161 },
    { lat: 48.698285, lng: 21.198335 },
    { lat: 48.697913, lng: 21.198564 },
    { lat: 48.697589, lng: 21.198802 },
    { lat: 48.697426, lng: 21.198934 },
    { lat: 48.69715, lng: 21.199098 },
    { lat: 48.696735, lng: 21.199454 },
    { lat: 48.696326, lng: 21.199632 },
    { lat: 48.696174, lng: 21.199671 },
    { lat: 48.695866, lng: 21.199752 },
    { lat: 48.695586, lng: 21.199747 },
    { lat: 48.695403, lng: 21.199682 },
    { lat: 48.695191, lng: 21.19961 },
    { lat: 48.6951125, lng: 21.1995466 },
    { lat: 48.6951087, lng: 21.1995543 },
    { lat: 48.6950953, lng: 21.1995744 },
    { lat: 48.6950656, lng: 21.1996283 },
    { lat: 48.6950405, lng: 21.1996716 },
    { lat: 48.694953, lng: 21.1998219 },
    { lat: 48.6948936, lng: 21.1998823 },
    { lat: 48.694855, lng: 21.1999235 },
    { lat: 48.6948954, lng: 21.2002822 },
    { lat: 48.694911, lng: 21.2004292 },
    { lat: 48.6949199, lng: 21.2005115 },
    { lat: 48.6949344, lng: 21.200589 },
    { lat: 48.6949494, lng: 21.2006692 },
    { lat: 48.694975, lng: 21.2009167 },
    { lat: 48.6950151, lng: 21.2011749 },
    { lat: 48.695041, lng: 21.2013415 },
    { lat: 48.6950276, lng: 21.2015785 },
    { lat: 48.6950077, lng: 21.2017272 },
    { lat: 48.6950013, lng: 21.2018014 },
    { lat: 48.6949945, lng: 21.2018804 },
    { lat: 48.694877, lng: 21.2020339 },
    { lat: 48.6948678, lng: 21.2020631 },
    { lat: 48.694799, lng: 21.2022725 },
    { lat: 48.6947139, lng: 21.2026455 },
    { lat: 48.6946684, lng: 21.2028454 },
    { lat: 48.6946315, lng: 21.2028909 },
    { lat: 48.6946294, lng: 21.2028935 },
    { lat: 48.6945969, lng: 21.2029339 },
    { lat: 48.6943678, lng: 21.2032101 },
    { lat: 48.6942325, lng: 21.2034773 },
    { lat: 48.6942904, lng: 21.2035676 },
    { lat: 48.6947162, lng: 21.2041834 },
    { lat: 48.6948417, lng: 21.2043634 },
    { lat: 48.6947, lng: 21.2045832 },
    { lat: 48.6946021, lng: 21.2047349 },
    { lat: 48.6946943, lng: 21.2049043 },
    { lat: 48.6947481, lng: 21.2050031 },
    { lat: 48.6952698, lng: 21.2058908 },
    { lat: 48.6954813, lng: 21.2062652 },
    { lat: 48.6955738, lng: 21.2064143 },
    { lat: 48.6957462, lng: 21.2068283 },
    { lat: 48.6958959, lng: 21.2074657 },
    { lat: 48.695993, lng: 21.2080843 },
    { lat: 48.696027, lng: 21.2084873 },
    { lat: 48.6959534, lng: 21.2086543 },
    { lat: 48.6959081, lng: 21.2087509 },
    { lat: 48.6958628, lng: 21.2088488 },
    { lat: 48.6958301, lng: 21.2089552 },
    { lat: 48.6958019, lng: 21.209062 },
    { lat: 48.6957078, lng: 21.2091917 },
    { lat: 48.6956, lng: 21.2093728 },
    { lat: 48.6954939, lng: 21.2095378 },
    { lat: 48.6953926, lng: 21.2096868 },
    { lat: 48.6953412, lng: 21.2097689 },
    { lat: 48.6952982, lng: 21.209834 },
    { lat: 48.6952192, lng: 21.2100038 },
    { lat: 48.6950726, lng: 21.2103175 },
    { lat: 48.6949861, lng: 21.2105003 },
    { lat: 48.6950509, lng: 21.2105969 },
    { lat: 48.6949678, lng: 21.2107446 },
    { lat: 48.6949442, lng: 21.2107121 },
    { lat: 48.6948552, lng: 21.2108495 },
    { lat: 48.6947524, lng: 21.210983 },
    { lat: 48.6946446, lng: 21.2111001 },
    { lat: 48.6945576, lng: 21.2112673 },
    { lat: 48.6945454, lng: 21.2113423 },
    { lat: 48.69447, lng: 21.2114157 },
    { lat: 48.6944258, lng: 21.2115499 },
    { lat: 48.6944014, lng: 21.2115973 },
    { lat: 48.6942956, lng: 21.2117402 },
    { lat: 48.6942141, lng: 21.2117261 },
    { lat: 48.6941858, lng: 21.2117217 },
    { lat: 48.6940673, lng: 21.2118534 },
    { lat: 48.6940177, lng: 21.2119239 },
    { lat: 48.6939649, lng: 21.2119982 },
    { lat: 48.6938865, lng: 21.2121926 },
    { lat: 48.693756, lng: 21.2123003 },
    { lat: 48.6936286, lng: 21.212403 },
    { lat: 48.6934948, lng: 21.2125113 },
    { lat: 48.6933135, lng: 21.2125467 },
    { lat: 48.6932081, lng: 21.2127376 },
    { lat: 48.6931154, lng: 21.2129064 },
    { lat: 48.6930142, lng: 21.2130863 },
    { lat: 48.6929418, lng: 21.2132169 },
    { lat: 48.6928915, lng: 21.2132632 },
    { lat: 48.6927667, lng: 21.2133765 },
    { lat: 48.6927912, lng: 21.2134058 },
    { lat: 48.6926408, lng: 21.2135269 },
    { lat: 48.6926244, lng: 21.2135046 },
    { lat: 48.6925564, lng: 21.2135643 },
    { lat: 48.6924735, lng: 21.2136457 },
    { lat: 48.6924248, lng: 21.2138159 },
    { lat: 48.6922959, lng: 21.2141885 },
  ],
  KošicemestskáčasťŠaca: [
    { lat: 48.6270008, lng: 21.2214569 },
    { lat: 48.6303378, lng: 21.2175134 },
    { lat: 48.6315948, lng: 21.2159961 },
    { lat: 48.6334252, lng: 21.2137863 },
    { lat: 48.6360159, lng: 21.2106561 },
    { lat: 48.636234, lng: 21.211871 },
    { lat: 48.63631, lng: 21.212326 },
    { lat: 48.636394, lng: 21.212803 },
    { lat: 48.636441, lng: 21.213159 },
    { lat: 48.636609, lng: 21.214413 },
    { lat: 48.636884, lng: 21.216516 },
    { lat: 48.637218, lng: 21.218543 },
    { lat: 48.637255, lng: 21.218993 },
    { lat: 48.637317, lng: 21.219334 },
    { lat: 48.637341, lng: 21.219461 },
    { lat: 48.637355, lng: 21.219546 },
    { lat: 48.642439, lng: 21.217012 },
    { lat: 48.64253, lng: 21.217243 },
    { lat: 48.643697, lng: 21.220192 },
    { lat: 48.643698, lng: 21.220271 },
    { lat: 48.64376, lng: 21.220332 },
    { lat: 48.644623, lng: 21.221707 },
    { lat: 48.6448875, lng: 21.2221935 },
    { lat: 48.644893, lng: 21.2221546 },
    { lat: 48.6449132, lng: 21.2220087 },
    { lat: 48.6458967, lng: 21.2149853 },
    { lat: 48.6460648, lng: 21.2147833 },
    { lat: 48.6461551, lng: 21.2145757 },
    { lat: 48.6463522, lng: 21.2130374 },
    { lat: 48.6466818, lng: 21.2107696 },
    { lat: 48.6470733, lng: 21.2080702 },
    { lat: 48.6470824, lng: 21.2080026 },
    { lat: 48.6476461, lng: 21.2039477 },
    { lat: 48.6476569, lng: 21.2038971 },
    { lat: 48.6476667, lng: 21.2038466 },
    { lat: 48.6476784, lng: 21.2037906 },
    { lat: 48.6476834, lng: 21.2037365 },
    { lat: 48.647686, lng: 21.2037114 },
    { lat: 48.6476909, lng: 21.2036541 },
    { lat: 48.6476979, lng: 21.2036251 },
    { lat: 48.6477167, lng: 21.2035405 },
    { lat: 48.6477479, lng: 21.2034963 },
    { lat: 48.6477705, lng: 21.203463 },
    { lat: 48.6478502, lng: 21.2033487 },
    { lat: 48.6478804, lng: 21.2033067 },
    { lat: 48.6479116, lng: 21.2032614 },
    { lat: 48.6479207, lng: 21.2028703 },
    { lat: 48.6480019, lng: 21.2023734 },
    { lat: 48.6480215, lng: 21.2022812 },
    { lat: 48.6480498, lng: 21.2021414 },
    { lat: 48.6481961, lng: 21.201427 },
    { lat: 48.6482843, lng: 21.2009751 },
    { lat: 48.6483577, lng: 21.2005178 },
    { lat: 48.6483944, lng: 21.2001799 },
    { lat: 48.6484587, lng: 21.1997539 },
    { lat: 48.6485991, lng: 21.1989953 },
    { lat: 48.6486474, lng: 21.1984925 },
    { lat: 48.6487342, lng: 21.19803 },
    { lat: 48.648839, lng: 21.1974965 },
    { lat: 48.6490626, lng: 21.1966996 },
    { lat: 48.6491327, lng: 21.1961724 },
    { lat: 48.649265, lng: 21.1957639 },
    { lat: 48.6493129, lng: 21.1952811 },
    { lat: 48.6493577, lng: 21.1949198 },
    { lat: 48.6494161, lng: 21.1948078 },
    { lat: 48.6495263, lng: 21.1947298 },
    { lat: 48.6496023, lng: 21.1946414 },
    { lat: 48.6496278, lng: 21.1944894 },
    { lat: 48.64977, lng: 21.1936819 },
    { lat: 48.6498137, lng: 21.1934972 },
    { lat: 48.6498625, lng: 21.1933293 },
    { lat: 48.6499472, lng: 21.1932307 },
    { lat: 48.6501461, lng: 21.1926832 },
    { lat: 48.6502649, lng: 21.1925626 },
    { lat: 48.6505214, lng: 21.1924168 },
    { lat: 48.6508058, lng: 21.1923697 },
    { lat: 48.6512336, lng: 21.1918004 },
    { lat: 48.6513464, lng: 21.1913798 },
    { lat: 48.6517261, lng: 21.1904293 },
    { lat: 48.6517971, lng: 21.1899707 },
    { lat: 48.6520238, lng: 21.189518 },
    { lat: 48.6524802, lng: 21.1883612 },
    { lat: 48.6527907, lng: 21.1867884 },
    { lat: 48.6529777, lng: 21.1857605 },
    { lat: 48.6529855, lng: 21.185719 },
    { lat: 48.6530161, lng: 21.1855114 },
    { lat: 48.6531284, lng: 21.1847594 },
    { lat: 48.6534493, lng: 21.183299 },
    { lat: 48.653375, lng: 21.1831918 },
    { lat: 48.6536528, lng: 21.1818694 },
    { lat: 48.653704, lng: 21.1814204 },
    { lat: 48.6537332, lng: 21.1811692 },
    { lat: 48.6539952, lng: 21.1788843 },
    { lat: 48.6542158, lng: 21.178527 },
    { lat: 48.6544961, lng: 21.1779256 },
    { lat: 48.6546518, lng: 21.1775085 },
    { lat: 48.6548315, lng: 21.1764647 },
    { lat: 48.6549187, lng: 21.1760213 },
    { lat: 48.6554956, lng: 21.1756138 },
    { lat: 48.655537, lng: 21.175546 },
    { lat: 48.655567, lng: 21.175287 },
    { lat: 48.65557, lng: 21.175067 },
    { lat: 48.655573, lng: 21.174422 },
    { lat: 48.655557, lng: 21.173925 },
    { lat: 48.655576, lng: 21.173687 },
    { lat: 48.655577, lng: 21.172889 },
    { lat: 48.655578, lng: 21.172418 },
    { lat: 48.655578, lng: 21.171856 },
    { lat: 48.655558, lng: 21.17094 },
    { lat: 48.655557, lng: 21.170874 },
    { lat: 48.655556, lng: 21.170833 },
    { lat: 48.655552, lng: 21.170678 },
    { lat: 48.655551, lng: 21.170627 },
    { lat: 48.65555, lng: 21.170602 },
    { lat: 48.65553, lng: 21.169851 },
    { lat: 48.655505, lng: 21.169474 },
    { lat: 48.655497, lng: 21.169372 },
    { lat: 48.655528, lng: 21.16936 },
    { lat: 48.655497, lng: 21.169128 },
    { lat: 48.655542, lng: 21.16911 },
    { lat: 48.655556, lng: 21.168994 },
    { lat: 48.655419, lng: 21.168793 },
    { lat: 48.65533, lng: 21.167852 },
    { lat: 48.655322, lng: 21.16778 },
    { lat: 48.655317, lng: 21.167678 },
    { lat: 48.655315, lng: 21.167594 },
    { lat: 48.65531, lng: 21.167477 },
    { lat: 48.655303, lng: 21.167335 },
    { lat: 48.655302, lng: 21.167301 },
    { lat: 48.655281, lng: 21.166824 },
    { lat: 48.655315, lng: 21.165826 },
    { lat: 48.655297, lng: 21.165819 },
    { lat: 48.655352, lng: 21.160797 },
    { lat: 48.655369, lng: 21.1608 },
    { lat: 48.655825, lng: 21.160827 },
    { lat: 48.655876, lng: 21.159676 },
    { lat: 48.655922, lng: 21.159352 },
    { lat: 48.65609, lng: 21.158614 },
    { lat: 48.656118, lng: 21.158408 },
    { lat: 48.656148, lng: 21.158184 },
    { lat: 48.656191, lng: 21.157753 },
    { lat: 48.656225, lng: 21.157645 },
    { lat: 48.65625, lng: 21.157348 },
    { lat: 48.656257, lng: 21.157271 },
    { lat: 48.656288, lng: 21.1569 },
    { lat: 48.65642, lng: 21.155967 },
    { lat: 48.656671, lng: 21.155108 },
    { lat: 48.656784, lng: 21.15502 },
    { lat: 48.656841, lng: 21.154667 },
    { lat: 48.656817, lng: 21.15413 },
    { lat: 48.65684, lng: 21.152984 },
    { lat: 48.656831, lng: 21.152538 },
    { lat: 48.656865, lng: 21.152479 },
    { lat: 48.657037, lng: 21.152182 },
    { lat: 48.657149, lng: 21.152019 },
    { lat: 48.657375, lng: 21.151227 },
    { lat: 48.657917, lng: 21.149809 },
    { lat: 48.658148, lng: 21.149352 },
    { lat: 48.658365, lng: 21.149023 },
    { lat: 48.65866, lng: 21.148477 },
    { lat: 48.659284, lng: 21.147906 },
    { lat: 48.65995, lng: 21.14771 },
    { lat: 48.660777, lng: 21.147576 },
    { lat: 48.661211, lng: 21.147553 },
    { lat: 48.661639, lng: 21.147574 },
    { lat: 48.662218, lng: 21.147728 },
    { lat: 48.662865, lng: 21.147971 },
    { lat: 48.663193, lng: 21.148088 },
    { lat: 48.664003, lng: 21.148236 },
    { lat: 48.664521, lng: 21.148412 },
    { lat: 48.665003, lng: 21.148717 },
    { lat: 48.665232, lng: 21.148935 },
    { lat: 48.665555, lng: 21.149298 },
    { lat: 48.66587, lng: 21.149771 },
    { lat: 48.666014, lng: 21.150012 },
    { lat: 48.666618, lng: 21.150659 },
    { lat: 48.666983, lng: 21.150678 },
    { lat: 48.6679, lng: 21.150778 },
    { lat: 48.668112, lng: 21.150801 },
    { lat: 48.668434, lng: 21.150836 },
    { lat: 48.668273, lng: 21.150093 },
    { lat: 48.668036, lng: 21.149576 },
    { lat: 48.667876, lng: 21.149233 },
    { lat: 48.667765, lng: 21.148894 },
    { lat: 48.667496, lng: 21.148354 },
    { lat: 48.667168, lng: 21.147675 },
    { lat: 48.666914, lng: 21.147105 },
    { lat: 48.666815, lng: 21.146887 },
    { lat: 48.666567, lng: 21.14638 },
    { lat: 48.666255, lng: 21.145816 },
    { lat: 48.666189, lng: 21.145685 },
    { lat: 48.666003, lng: 21.145226 },
    { lat: 48.665754, lng: 21.144613 },
    { lat: 48.6654, lng: 21.143962 },
    { lat: 48.665077, lng: 21.143381 },
    { lat: 48.664614, lng: 21.142453 },
    { lat: 48.664294, lng: 21.141486 },
    { lat: 48.663911, lng: 21.140703 },
    { lat: 48.663604, lng: 21.140077 },
    { lat: 48.661878, lng: 21.136421 },
    { lat: 48.661655, lng: 21.135949 },
    { lat: 48.660869, lng: 21.134383 },
    { lat: 48.660827, lng: 21.1343 },
    { lat: 48.660725, lng: 21.134084 },
    { lat: 48.660149, lng: 21.132826 },
    { lat: 48.660002, lng: 21.132507 },
    { lat: 48.659483, lng: 21.131447 },
    { lat: 48.659466, lng: 21.131412 },
    { lat: 48.658773, lng: 21.129914 },
    { lat: 48.658374, lng: 21.12895 },
    { lat: 48.657225, lng: 21.126477 },
    { lat: 48.657097, lng: 21.126201 },
    { lat: 48.656747, lng: 21.125353 },
    { lat: 48.656671, lng: 21.125211 },
    { lat: 48.656484, lng: 21.124789 },
    { lat: 48.656302, lng: 21.124362 },
    { lat: 48.656165, lng: 21.124032 },
    { lat: 48.65598, lng: 21.123602 },
    { lat: 48.655872, lng: 21.123356 },
    { lat: 48.655656, lng: 21.122881 },
    { lat: 48.655572, lng: 21.122608 },
    { lat: 48.655449, lng: 21.122337 },
    { lat: 48.655192, lng: 21.121659 },
    { lat: 48.655136, lng: 21.121685 },
    { lat: 48.655201, lng: 21.122505 },
    { lat: 48.655005, lng: 21.122909 },
    { lat: 48.654757, lng: 21.123339 },
    { lat: 48.65462, lng: 21.123659 },
    { lat: 48.654573, lng: 21.123884 },
    { lat: 48.654374, lng: 21.123946 },
    { lat: 48.654285, lng: 21.124097 },
    { lat: 48.654225, lng: 21.124291 },
    { lat: 48.654113, lng: 21.124492 },
    { lat: 48.653991, lng: 21.124525 },
    { lat: 48.653851, lng: 21.124503 },
    { lat: 48.653691, lng: 21.124614 },
    { lat: 48.653548, lng: 21.124492 },
    { lat: 48.653197, lng: 21.124803 },
    { lat: 48.653031, lng: 21.124794 },
    { lat: 48.652835, lng: 21.124676 },
    { lat: 48.651812, lng: 21.124964 },
    { lat: 48.651513, lng: 21.124912 },
    { lat: 48.65135, lng: 21.124977 },
    { lat: 48.65125, lng: 21.1249 },
    { lat: 48.65092, lng: 21.124872 },
    { lat: 48.65048, lng: 21.124836 },
    { lat: 48.650337, lng: 21.124716 },
    { lat: 48.650115, lng: 21.124616 },
    { lat: 48.649973, lng: 21.124632 },
    { lat: 48.649584, lng: 21.124761 },
    { lat: 48.649405, lng: 21.124757 },
    { lat: 48.649101, lng: 21.124837 },
    { lat: 48.648855, lng: 21.124958 },
    { lat: 48.648642, lng: 21.125089 },
    { lat: 48.648366, lng: 21.125165 },
    { lat: 48.648101, lng: 21.125264 },
    { lat: 48.647746, lng: 21.125501 },
    { lat: 48.647508, lng: 21.125683 },
    { lat: 48.647384, lng: 21.125777 },
    { lat: 48.647253, lng: 21.125876 },
    { lat: 48.646784, lng: 21.126235 },
    { lat: 48.646062, lng: 21.126559 },
    { lat: 48.645972, lng: 21.1266 },
    { lat: 48.645325, lng: 21.126891 },
    { lat: 48.644557, lng: 21.127469 },
    { lat: 48.644371, lng: 21.127557 },
    { lat: 48.644247, lng: 21.127634 },
    { lat: 48.64389, lng: 21.127854 },
    { lat: 48.643584, lng: 21.127974 },
    { lat: 48.643391, lng: 21.12805 },
    { lat: 48.643049, lng: 21.128092 },
    { lat: 48.642709, lng: 21.128121 },
    { lat: 48.642692, lng: 21.12812 },
    { lat: 48.642586, lng: 21.128146 },
    { lat: 48.642535, lng: 21.128159 },
    { lat: 48.642377, lng: 21.128178 },
    { lat: 48.642053, lng: 21.128216 },
    { lat: 48.640704, lng: 21.128556 },
    { lat: 48.640505, lng: 21.128606 },
    { lat: 48.639939, lng: 21.128878 },
    { lat: 48.639242, lng: 21.129329 },
    { lat: 48.638677, lng: 21.12963 },
    { lat: 48.637978, lng: 21.129643 },
    { lat: 48.637466, lng: 21.129504 },
    { lat: 48.637068, lng: 21.129369 },
    { lat: 48.636885, lng: 21.129383 },
    { lat: 48.636606, lng: 21.129248 },
    { lat: 48.636226, lng: 21.129035 },
    { lat: 48.635304, lng: 21.128658 },
    { lat: 48.635126, lng: 21.128491 },
    { lat: 48.634517, lng: 21.128178 },
    { lat: 48.634019, lng: 21.127949 },
    { lat: 48.633678, lng: 21.127801 },
    { lat: 48.633328, lng: 21.127704 },
    { lat: 48.632384, lng: 21.127124 },
    { lat: 48.631801, lng: 21.126648 },
    { lat: 48.631563, lng: 21.126358 },
    { lat: 48.631223, lng: 21.125815 },
    { lat: 48.630895, lng: 21.125624 },
    { lat: 48.630659, lng: 21.12526 },
    { lat: 48.630599, lng: 21.125068 },
    { lat: 48.630409, lng: 21.124866 },
    { lat: 48.629984, lng: 21.124508 },
    { lat: 48.62957, lng: 21.12422 },
    { lat: 48.62947, lng: 21.124123 },
    { lat: 48.628656, lng: 21.123456 },
    { lat: 48.628571, lng: 21.123189 },
    { lat: 48.628347, lng: 21.122731 },
    { lat: 48.628196, lng: 21.122715 },
    { lat: 48.627728, lng: 21.122373 },
    { lat: 48.62744, lng: 21.1222 },
    { lat: 48.626811, lng: 21.12175 },
    { lat: 48.625994, lng: 21.121315 },
    { lat: 48.625728, lng: 21.121239 },
    { lat: 48.625072, lng: 21.121052 },
    { lat: 48.624654, lng: 21.120902 },
    { lat: 48.624273, lng: 21.120791 },
    { lat: 48.623778, lng: 21.120852 },
    { lat: 48.623431, lng: 21.120801 },
    { lat: 48.623099, lng: 21.120836 },
    { lat: 48.622374, lng: 21.121239 },
    { lat: 48.622324, lng: 21.121244 },
    { lat: 48.621741, lng: 21.121305 },
    { lat: 48.621333, lng: 21.121347 },
    { lat: 48.621308, lng: 21.12135 },
    { lat: 48.619962, lng: 21.12149 },
    { lat: 48.620226, lng: 21.126882 },
    { lat: 48.62025, lng: 21.127374 },
    { lat: 48.620253, lng: 21.127421 },
    { lat: 48.620257, lng: 21.127496 },
    { lat: 48.620258, lng: 21.127527 },
    { lat: 48.620263, lng: 21.127625 },
    { lat: 48.620266, lng: 21.127696 },
    { lat: 48.620401, lng: 21.130517 },
    { lat: 48.620401, lng: 21.130536 },
    { lat: 48.620405, lng: 21.130601 },
    { lat: 48.620435, lng: 21.131182 },
    { lat: 48.620523, lng: 21.132995 },
    { lat: 48.62053, lng: 21.133136 },
    { lat: 48.620536, lng: 21.133262 },
    { lat: 48.620685, lng: 21.136492 },
    { lat: 48.6207, lng: 21.136819 },
    { lat: 48.620707, lng: 21.136975 },
    { lat: 48.620728, lng: 21.137437 },
    { lat: 48.62091, lng: 21.141279 },
    { lat: 48.621099, lng: 21.145719 },
    { lat: 48.620999, lng: 21.147173 },
    { lat: 48.620996, lng: 21.147222 },
    { lat: 48.620993, lng: 21.147264 },
    { lat: 48.62098, lng: 21.147452 },
    { lat: 48.620971, lng: 21.14758 },
    { lat: 48.620962, lng: 21.147712 },
    { lat: 48.620664, lng: 21.151992 },
    { lat: 48.620663, lng: 21.151997 },
    { lat: 48.620658, lng: 21.152074 },
    { lat: 48.620481, lng: 21.15458 },
    { lat: 48.620315, lng: 21.156914 },
    { lat: 48.620306, lng: 21.157136 },
    { lat: 48.620209, lng: 21.158313 },
    { lat: 48.620201, lng: 21.158409 },
    { lat: 48.620195, lng: 21.158498 },
    { lat: 48.620133, lng: 21.159244 },
    { lat: 48.61984, lng: 21.16136 },
    { lat: 48.619841, lng: 21.161563 },
    { lat: 48.619781, lng: 21.164045 },
    { lat: 48.619765, lng: 21.164765 },
    { lat: 48.619848, lng: 21.165462 },
    { lat: 48.619754, lng: 21.165505 },
    { lat: 48.618798, lng: 21.165946 },
    { lat: 48.618462, lng: 21.166101 },
    { lat: 48.618296, lng: 21.166205 },
    { lat: 48.618212, lng: 21.1663 },
    { lat: 48.617806, lng: 21.166848 },
    { lat: 48.617343, lng: 21.167534 },
    { lat: 48.617296, lng: 21.167649 },
    { lat: 48.6173, lng: 21.167775 },
    { lat: 48.617349, lng: 21.168361 },
    { lat: 48.617355, lng: 21.168421 },
    { lat: 48.617367, lng: 21.168536 },
    { lat: 48.617469, lng: 21.169594 },
    { lat: 48.617486, lng: 21.169699 },
    { lat: 48.617514, lng: 21.169884 },
    { lat: 48.617521, lng: 21.16993 },
    { lat: 48.617374, lng: 21.170191 },
    { lat: 48.617332, lng: 21.170155 },
    { lat: 48.617305, lng: 21.170131 },
    { lat: 48.617164, lng: 21.170011 },
    { lat: 48.616912, lng: 21.170141 },
    { lat: 48.616729, lng: 21.169987 },
    { lat: 48.616315, lng: 21.17019 },
    { lat: 48.616283, lng: 21.170319 },
    { lat: 48.616321, lng: 21.170399 },
    { lat: 48.616197, lng: 21.170537 },
    { lat: 48.616212, lng: 21.170748 },
    { lat: 48.615953, lng: 21.170706 },
    { lat: 48.61567, lng: 21.170644 },
    { lat: 48.615673, lng: 21.170695 },
    { lat: 48.6158, lng: 21.171243 },
    { lat: 48.61524, lng: 21.1717 },
    { lat: 48.614324, lng: 21.171534 },
    { lat: 48.613965, lng: 21.171348 },
    { lat: 48.613905, lng: 21.171253 },
    { lat: 48.613951, lng: 21.171068 },
    { lat: 48.613777, lng: 21.170323 },
    { lat: 48.612897, lng: 21.170859 },
    { lat: 48.612668, lng: 21.170787 },
    { lat: 48.612454, lng: 21.171187 },
    { lat: 48.612106, lng: 21.171339 },
    { lat: 48.611479, lng: 21.170593 },
    { lat: 48.61077, lng: 21.170201 },
    { lat: 48.610483, lng: 21.169783 },
    { lat: 48.610292, lng: 21.169793 },
    { lat: 48.609819, lng: 21.170174 },
    { lat: 48.609018, lng: 21.169992 },
    { lat: 48.608376, lng: 21.170028 },
    { lat: 48.607733, lng: 21.170166 },
    { lat: 48.606228, lng: 21.170487 },
    { lat: 48.605595, lng: 21.173217 },
    { lat: 48.604809, lng: 21.172785 },
    { lat: 48.604447, lng: 21.172528 },
    { lat: 48.603978, lng: 21.172282 },
    { lat: 48.603376, lng: 21.171926 },
    { lat: 48.602559, lng: 21.171539 },
    { lat: 48.602504, lng: 21.171708 },
    { lat: 48.602032, lng: 21.171445 },
    { lat: 48.601697, lng: 21.171235 },
    { lat: 48.601604, lng: 21.1717 },
    { lat: 48.600825, lng: 21.171304 },
    { lat: 48.60053, lng: 21.171233 },
    { lat: 48.60037, lng: 21.171386 },
    { lat: 48.600077, lng: 21.171204 },
    { lat: 48.600016, lng: 21.171866 },
    { lat: 48.599766, lng: 21.171844 },
    { lat: 48.598613, lng: 21.171493 },
    { lat: 48.598306, lng: 21.171452 },
    { lat: 48.598191, lng: 21.171496 },
    { lat: 48.598089, lng: 21.171613 },
    { lat: 48.598002, lng: 21.17166 },
    { lat: 48.595919, lng: 21.171949 },
    { lat: 48.595796, lng: 21.171928 },
    { lat: 48.595292, lng: 21.172022 },
    { lat: 48.594589, lng: 21.171936 },
    { lat: 48.594159, lng: 21.171821 },
    { lat: 48.594005, lng: 21.173198 },
    { lat: 48.593821, lng: 21.174851 },
    { lat: 48.594372, lng: 21.174904 },
    { lat: 48.594491, lng: 21.175427 },
    { lat: 48.59474, lng: 21.175851 },
    { lat: 48.593878, lng: 21.176856 },
    { lat: 48.59378, lng: 21.176964 },
    { lat: 48.593611, lng: 21.176652 },
    { lat: 48.593403, lng: 21.176603 },
    { lat: 48.593128, lng: 21.175983 },
    { lat: 48.593099, lng: 21.176031 },
    { lat: 48.592375, lng: 21.17692 },
    { lat: 48.59192, lng: 21.17731 },
    { lat: 48.591849, lng: 21.177234 },
    { lat: 48.59143, lng: 21.177707 },
    { lat: 48.59124, lng: 21.177908 },
    { lat: 48.590819, lng: 21.177089 },
    { lat: 48.589503, lng: 21.17466 },
    { lat: 48.589368, lng: 21.174416 },
    { lat: 48.58932, lng: 21.174183 },
    { lat: 48.589069, lng: 21.174432 },
    { lat: 48.587331, lng: 21.176164 },
    { lat: 48.585829, lng: 21.177684 },
    { lat: 48.585005, lng: 21.178569 },
    { lat: 48.582965, lng: 21.180613 },
    { lat: 48.582876, lng: 21.180714 },
    { lat: 48.581376, lng: 21.182209 },
    { lat: 48.581207, lng: 21.182395 },
    { lat: 48.581028, lng: 21.182644 },
    { lat: 48.580906, lng: 21.182895 },
    { lat: 48.580812, lng: 21.18318 },
    { lat: 48.57974, lng: 21.1869 },
    { lat: 48.579697, lng: 21.187153 },
    { lat: 48.579689, lng: 21.187176 },
    { lat: 48.5788701, lng: 21.1899424 },
    { lat: 48.578668, lng: 21.190625 },
    { lat: 48.577598, lng: 21.194359 },
    { lat: 48.577261, lng: 21.195543 },
    { lat: 48.576575, lng: 21.197968 },
    { lat: 48.576569, lng: 21.197984 },
    { lat: 48.57597, lng: 21.200111 },
    { lat: 48.57578, lng: 21.200781 },
    { lat: 48.575689, lng: 21.201092 },
    { lat: 48.575192, lng: 21.202654 },
    { lat: 48.575172, lng: 21.202726 },
    { lat: 48.574224, lng: 21.206122 },
    { lat: 48.574192, lng: 21.206234 },
    { lat: 48.573605, lng: 21.20827 },
    { lat: 48.572813, lng: 21.211022 },
    { lat: 48.571357, lng: 21.21603 },
    { lat: 48.57128, lng: 21.216296 },
    { lat: 48.570039, lng: 21.220558 },
    { lat: 48.572398, lng: 21.226961 },
    { lat: 48.572479, lng: 21.22718 },
    { lat: 48.57303, lng: 21.228674 },
    { lat: 48.573051, lng: 21.228734 },
    { lat: 48.573309, lng: 21.229433 },
    { lat: 48.573467, lng: 21.229862 },
    { lat: 48.573723, lng: 21.230554 },
    { lat: 48.573739, lng: 21.230598 },
    { lat: 48.574198, lng: 21.231846 },
    { lat: 48.574258, lng: 21.232008 },
    { lat: 48.574433, lng: 21.232484 },
    { lat: 48.574659, lng: 21.233099 },
    { lat: 48.574689, lng: 21.233178 },
    { lat: 48.574755, lng: 21.233358 },
    { lat: 48.577294, lng: 21.240253 },
    { lat: 48.577311, lng: 21.240298 },
    { lat: 48.577331, lng: 21.240354 },
    { lat: 48.579781, lng: 21.238623 },
    { lat: 48.580273, lng: 21.238438 },
    { lat: 48.581711, lng: 21.237895 },
    { lat: 48.583612, lng: 21.237066 },
    { lat: 48.583648, lng: 21.23705 },
    { lat: 48.583686, lng: 21.237034 },
    { lat: 48.583797, lng: 21.236985 },
    { lat: 48.583885, lng: 21.236979 },
    { lat: 48.584047, lng: 21.236967 },
    { lat: 48.584317, lng: 21.236946 },
    { lat: 48.58447, lng: 21.236934 },
    { lat: 48.585207, lng: 21.236642 },
    { lat: 48.585511, lng: 21.23675 },
    { lat: 48.586606, lng: 21.236567 },
    { lat: 48.58767, lng: 21.236364 },
    { lat: 48.588769, lng: 21.236167 },
    { lat: 48.589196, lng: 21.236091 },
    { lat: 48.589163, lng: 21.235878 },
    { lat: 48.589063, lng: 21.235215 },
    { lat: 48.589127, lng: 21.235182 },
    { lat: 48.589558, lng: 21.234972 },
    { lat: 48.590685, lng: 21.234421 },
    { lat: 48.590767, lng: 21.234381 },
    { lat: 48.590589, lng: 21.23318 },
    { lat: 48.59063, lng: 21.23318 },
    { lat: 48.590765, lng: 21.233185 },
    { lat: 48.590907, lng: 21.23319 },
    { lat: 48.591073, lng: 21.233196 },
    { lat: 48.592165, lng: 21.232833 },
    { lat: 48.594085, lng: 21.232214 },
    { lat: 48.595052, lng: 21.231902 },
    { lat: 48.595282, lng: 21.231828 },
    { lat: 48.595288, lng: 21.231858 },
    { lat: 48.595314, lng: 21.231995 },
    { lat: 48.595317, lng: 21.232011 },
    { lat: 48.595373, lng: 21.232435 },
    { lat: 48.595421, lng: 21.232831 },
    { lat: 48.596558, lng: 21.232066 },
    { lat: 48.597396, lng: 21.231518 },
    { lat: 48.5978345, lng: 21.231215 },
    { lat: 48.6010312, lng: 21.2292988 },
    { lat: 48.6013464, lng: 21.2291702 },
    { lat: 48.6017285, lng: 21.2291263 },
    { lat: 48.6020397, lng: 21.2291898 },
    { lat: 48.6033298, lng: 21.2294046 },
    { lat: 48.6041915, lng: 21.22919 },
    { lat: 48.6047695, lng: 21.2289755 },
    { lat: 48.6045244, lng: 21.22698 },
    { lat: 48.609765, lng: 21.2242474 },
    { lat: 48.6104779, lng: 21.2239932 },
    { lat: 48.6108574, lng: 21.2261636 },
    { lat: 48.611921, lng: 21.2257181 },
    { lat: 48.6128436, lng: 21.2297207 },
    { lat: 48.6139588, lng: 21.2278747 },
    { lat: 48.616334, lng: 21.2279213 },
    { lat: 48.6173961, lng: 21.2271274 },
    { lat: 48.6209477, lng: 21.2285886 },
    { lat: 48.6209575, lng: 21.2285776 },
    { lat: 48.620976, lng: 21.2285567 },
    { lat: 48.6209901, lng: 21.2285415 },
    { lat: 48.6217165, lng: 21.2277244 },
    { lat: 48.6218306, lng: 21.227596 },
    { lat: 48.6223046, lng: 21.2270637 },
    { lat: 48.6223721, lng: 21.2269877 },
    { lat: 48.6223971, lng: 21.2269589 },
    { lat: 48.623772, lng: 21.2253615 },
    { lat: 48.6238872, lng: 21.2252274 },
    { lat: 48.626893, lng: 21.2215842 },
    { lat: 48.6270008, lng: 21.2214569 },
  ],
  KošicemestskáčasťPoľov: [
    { lat: 48.6450071, lng: 21.2487645 },
    { lat: 48.6463077, lng: 21.2486453 },
    { lat: 48.6478, lng: 21.248562 },
    { lat: 48.647656, lng: 21.247117 },
    { lat: 48.647502, lng: 21.246076 },
    { lat: 48.647393, lng: 21.24555 },
    { lat: 48.6528426, lng: 21.2430712 },
    { lat: 48.6529917, lng: 21.2438802 },
    { lat: 48.6571507, lng: 21.2427511 },
    { lat: 48.6571845, lng: 21.2422937 },
    { lat: 48.6605486, lng: 21.2419065 },
    { lat: 48.6618199, lng: 21.2343612 },
    { lat: 48.6625012, lng: 21.2329216 },
    { lat: 48.6627908, lng: 21.2322663 },
    { lat: 48.6630298, lng: 21.2315438 },
    { lat: 48.6630768, lng: 21.2312788 },
    { lat: 48.6632097, lng: 21.2311148 },
    { lat: 48.6633977, lng: 21.2307828 },
    { lat: 48.6634467, lng: 21.2305228 },
    { lat: 48.6635357, lng: 21.2304138 },
    { lat: 48.6635697, lng: 21.2302158 },
    { lat: 48.6637047, lng: 21.2298938 },
    { lat: 48.6637547, lng: 21.2294448 },
    { lat: 48.6638367, lng: 21.2292218 },
    { lat: 48.6638607, lng: 21.2289368 },
    { lat: 48.6639757, lng: 21.2276953 },
    { lat: 48.6639524, lng: 21.2263181 },
    { lat: 48.6641194, lng: 21.223892 },
    { lat: 48.6689447, lng: 21.2166542 },
    { lat: 48.6708138, lng: 21.2162686 },
    { lat: 48.6708723, lng: 21.2114851 },
    { lat: 48.675551, lng: 21.213561 },
    { lat: 48.676284, lng: 21.213844 },
    { lat: 48.677123, lng: 21.21417 },
    { lat: 48.677642, lng: 21.214371 },
    { lat: 48.678034, lng: 21.214508 },
    { lat: 48.678083, lng: 21.214527 },
    { lat: 48.678778, lng: 21.214789 },
    { lat: 48.679199, lng: 21.215049 },
    { lat: 48.679608, lng: 21.21521 },
    { lat: 48.680218, lng: 21.215454 },
    { lat: 48.681367, lng: 21.215804 },
    { lat: 48.681457, lng: 21.215832 },
    { lat: 48.681727, lng: 21.215985 },
    { lat: 48.68178, lng: 21.21601 },
    { lat: 48.682295, lng: 21.216409 },
    { lat: 48.6824159, lng: 21.2160332 },
    { lat: 48.6824478, lng: 21.2159369 },
    { lat: 48.6824591, lng: 21.2159354 },
    { lat: 48.6825892, lng: 21.2159229 },
    { lat: 48.6825925, lng: 21.2159223 },
    { lat: 48.6826273, lng: 21.2159191 },
    { lat: 48.6826341, lng: 21.2159186 },
    { lat: 48.6827541, lng: 21.2159065 },
    { lat: 48.6827687, lng: 21.2159055 },
    { lat: 48.6828124, lng: 21.2159009 },
    { lat: 48.6828428, lng: 21.215898 },
    { lat: 48.6828652, lng: 21.215895 },
    { lat: 48.6828854, lng: 21.2158932 },
    { lat: 48.6828876, lng: 21.2158927 },
    { lat: 48.6829526, lng: 21.2158856 },
    { lat: 48.6829807, lng: 21.2158821 },
    { lat: 48.6830165, lng: 21.2158778 },
    { lat: 48.6828762, lng: 21.2155644 },
    { lat: 48.682869, lng: 21.2155493 },
    { lat: 48.682852, lng: 21.2155102 },
    { lat: 48.6827577, lng: 21.2153001 },
    { lat: 48.6826269, lng: 21.215007 },
    { lat: 48.6825372, lng: 21.2147663 },
    { lat: 48.6825311, lng: 21.2147471 },
    { lat: 48.6824918, lng: 21.2145722 },
    { lat: 48.6824406, lng: 21.214339 },
    { lat: 48.6823768, lng: 21.2136676 },
    { lat: 48.6823822, lng: 21.213263 },
    { lat: 48.6823883, lng: 21.2127394 },
    { lat: 48.682391, lng: 21.2125009 },
    { lat: 48.682392, lng: 21.212426 },
    { lat: 48.6824066, lng: 21.2122466 },
    { lat: 48.6824148, lng: 21.2121473 },
    { lat: 48.6824635, lng: 21.2115487 },
    { lat: 48.6825021, lng: 21.2113074 },
    { lat: 48.6825633, lng: 21.2109247 },
    { lat: 48.6825766, lng: 21.2108437 },
    { lat: 48.6826073, lng: 21.2107129 },
    { lat: 48.6826697, lng: 21.210441 },
    { lat: 48.682755, lng: 21.2100713 },
    { lat: 48.6827688, lng: 21.2100119 },
    { lat: 48.6827236, lng: 21.2099339 },
    { lat: 48.6826963, lng: 21.2098881 },
    { lat: 48.6826797, lng: 21.2097557 },
    { lat: 48.6826327, lng: 21.2093684 },
    { lat: 48.6826227, lng: 21.2093342 },
    { lat: 48.6826188, lng: 21.2093192 },
    { lat: 48.6824938, lng: 21.2088491 },
    { lat: 48.6823121, lng: 21.2084397 },
    { lat: 48.6821423, lng: 21.2081915 },
    { lat: 48.6820468, lng: 21.2080519 },
    { lat: 48.6820317, lng: 21.2080366 },
    { lat: 48.6815362, lng: 21.2075631 },
    { lat: 48.6813457, lng: 21.2073726 },
    { lat: 48.6813411, lng: 21.2073683 },
    { lat: 48.6813341, lng: 21.2073607 },
    { lat: 48.6811586, lng: 21.2071855 },
    { lat: 48.6809248, lng: 21.2067991 },
    { lat: 48.6809005, lng: 21.2067417 },
    { lat: 48.6808872, lng: 21.2067113 },
    { lat: 48.6808726, lng: 21.2066764 },
    { lat: 48.6808385, lng: 21.2065966 },
    { lat: 48.6808179, lng: 21.2065492 },
    { lat: 48.680722, lng: 21.206253 },
    { lat: 48.6807067, lng: 21.2061612 },
    { lat: 48.6806777, lng: 21.2059873 },
    { lat: 48.6806297, lng: 21.2056766 },
    { lat: 48.6806161, lng: 21.2054233 },
    { lat: 48.6806114, lng: 21.2053439 },
    { lat: 48.680597, lng: 21.205247 },
    { lat: 48.6805974, lng: 21.2052227 },
    { lat: 48.6806007, lng: 21.2050035 },
    { lat: 48.6806002, lng: 21.2049864 },
    { lat: 48.6806097, lng: 21.2043237 },
    { lat: 48.6806059, lng: 21.203196 },
    { lat: 48.6806612, lng: 21.2024876 },
    { lat: 48.68072, lng: 21.2021739 },
    { lat: 48.6808826, lng: 21.2016495 },
    { lat: 48.6811038, lng: 21.2011332 },
    { lat: 48.6811302, lng: 21.2010797 },
    { lat: 48.6816652, lng: 21.2000182 },
    { lat: 48.6817712, lng: 21.199809 },
    { lat: 48.6817987, lng: 21.1997501 },
    { lat: 48.6820932, lng: 21.1991285 },
    { lat: 48.6822323, lng: 21.1988325 },
    { lat: 48.68237, lng: 21.1984847 },
    { lat: 48.6824304, lng: 21.1983657 },
    { lat: 48.6824714, lng: 21.1981658 },
    { lat: 48.6826175, lng: 21.1974441 },
    { lat: 48.6826333, lng: 21.1973706 },
    { lat: 48.6828125, lng: 21.1964133 },
    { lat: 48.6828731, lng: 21.1961571 },
    { lat: 48.6829226, lng: 21.1959438 },
    { lat: 48.6829365, lng: 21.195883 },
    { lat: 48.6829623, lng: 21.1957753 },
    { lat: 48.6829778, lng: 21.1956927 },
    { lat: 48.6829932, lng: 21.1956114 },
    { lat: 48.683059, lng: 21.1952 },
    { lat: 48.68306, lng: 21.1951949 },
    { lat: 48.6830752, lng: 21.1951044 },
    { lat: 48.6830759, lng: 21.195094 },
    { lat: 48.6830863, lng: 21.1950299 },
    { lat: 48.6831118, lng: 21.1948742 },
    { lat: 48.6831363, lng: 21.1947218 },
    { lat: 48.6831947, lng: 21.1943586 },
    { lat: 48.6832191, lng: 21.1938835 },
    { lat: 48.6832267, lng: 21.1937327 },
    { lat: 48.6832314, lng: 21.1933459 },
    { lat: 48.6832207, lng: 21.1923284 },
    { lat: 48.6832, lng: 21.1918127 },
    { lat: 48.6831398, lng: 21.1902236 },
    { lat: 48.6831467, lng: 21.1896191 },
    { lat: 48.6831546, lng: 21.1890105 },
    { lat: 48.6831371, lng: 21.1888864 },
    { lat: 48.6831314, lng: 21.1888466 },
    { lat: 48.6831069, lng: 21.1886774 },
    { lat: 48.6829999, lng: 21.1883154 },
    { lat: 48.6828973, lng: 21.1880264 },
    { lat: 48.6826805, lng: 21.1876438 },
    { lat: 48.682558, lng: 21.1875088 },
    { lat: 48.6825277, lng: 21.1874746 },
    { lat: 48.6825056, lng: 21.1874509 },
    { lat: 48.6824802, lng: 21.1874307 },
    { lat: 48.6823059, lng: 21.1872932 },
    { lat: 48.6821027, lng: 21.1871693 },
    { lat: 48.6818991, lng: 21.1870995 },
    { lat: 48.6817129, lng: 21.187047 },
    { lat: 48.6815968, lng: 21.1870066 },
    { lat: 48.6815548, lng: 21.1869939 },
    { lat: 48.6813386, lng: 21.186918 },
    { lat: 48.6809868, lng: 21.1867947 },
    { lat: 48.6804916, lng: 21.186648 },
    { lat: 48.6804188, lng: 21.1866262 },
    { lat: 48.6803928, lng: 21.1866184 },
    { lat: 48.6802053, lng: 21.1865625 },
    { lat: 48.68004, lng: 21.1864953 },
    { lat: 48.6797311, lng: 21.1862678 },
    { lat: 48.6795464, lng: 21.1860824 },
    { lat: 48.6790631, lng: 21.1853219 },
    { lat: 48.6788335, lng: 21.18489 },
    { lat: 48.6784047, lng: 21.1841427 },
    { lat: 48.6783107, lng: 21.1839786 },
    { lat: 48.6778247, lng: 21.1831311 },
    { lat: 48.6776676, lng: 21.1828614 },
    { lat: 48.6775955, lng: 21.1827494 },
    { lat: 48.6773471, lng: 21.1824055 },
    { lat: 48.6770782, lng: 21.1820509 },
    { lat: 48.6766542, lng: 21.1815273 },
    { lat: 48.67649, lng: 21.1812781 },
    { lat: 48.6764427, lng: 21.1812057 },
    { lat: 48.6764095, lng: 21.1811525 },
    { lat: 48.6761618, lng: 21.1807572 },
    { lat: 48.6761109, lng: 21.1806769 },
    { lat: 48.676068, lng: 21.180745 },
    { lat: 48.675995, lng: 21.180785 },
    { lat: 48.675939, lng: 21.180816 },
    { lat: 48.675886, lng: 21.180814 },
    { lat: 48.675415, lng: 21.181275 },
    { lat: 48.675004, lng: 21.1814 },
    { lat: 48.674374, lng: 21.181521 },
    { lat: 48.673069, lng: 21.18145 },
    { lat: 48.672413, lng: 21.181571 },
    { lat: 48.671678, lng: 21.182121 },
    { lat: 48.671432, lng: 21.182228 },
    { lat: 48.671038, lng: 21.182447 },
    { lat: 48.670471, lng: 21.182513 },
    { lat: 48.669827, lng: 21.182407 },
    { lat: 48.669359, lng: 21.182161 },
    { lat: 48.669007, lng: 21.181979 },
    { lat: 48.668527, lng: 21.181909 },
    { lat: 48.66847, lng: 21.181901 },
    { lat: 48.667904, lng: 21.181988 },
    { lat: 48.667903, lng: 21.181988 },
    { lat: 48.667691, lng: 21.181884 },
    { lat: 48.667411, lng: 21.181531 },
    { lat: 48.666986, lng: 21.181202 },
    { lat: 48.666729, lng: 21.181003 },
    { lat: 48.666583, lng: 21.180955 },
    { lat: 48.666406, lng: 21.180988 },
    { lat: 48.665934, lng: 21.180903 },
    { lat: 48.665268, lng: 21.1806635 },
    { lat: 48.66493, lng: 21.180542 },
    { lat: 48.664543, lng: 21.180578 },
    { lat: 48.664212, lng: 21.180519 },
    { lat: 48.663529, lng: 21.180404 },
    { lat: 48.662719, lng: 21.180631 },
    { lat: 48.662135, lng: 21.181079 },
    { lat: 48.661463, lng: 21.181143 },
    { lat: 48.660952, lng: 21.181094 },
    { lat: 48.660586, lng: 21.180852 },
    { lat: 48.660221, lng: 21.180332 },
    { lat: 48.659787, lng: 21.180006 },
    { lat: 48.659642, lng: 21.179922 },
    { lat: 48.659628, lng: 21.179914 },
    { lat: 48.659433, lng: 21.179774 },
    { lat: 48.659228, lng: 21.179384 },
    { lat: 48.658863, lng: 21.178999 },
    { lat: 48.658577, lng: 21.178813 },
    { lat: 48.658251, lng: 21.178477 },
    { lat: 48.657875, lng: 21.178348 },
    { lat: 48.657159, lng: 21.178011 },
    { lat: 48.65703, lng: 21.17796 },
    { lat: 48.656752, lng: 21.177252 },
    { lat: 48.656504, lng: 21.176801 },
    { lat: 48.656016, lng: 21.17637 },
    { lat: 48.655819, lng: 21.17617 },
    { lat: 48.655552, lng: 21.175888 },
    { lat: 48.6554956, lng: 21.1756138 },
    { lat: 48.6549187, lng: 21.1760213 },
    { lat: 48.6548315, lng: 21.1764647 },
    { lat: 48.6546518, lng: 21.1775085 },
    { lat: 48.6544961, lng: 21.1779256 },
    { lat: 48.6542158, lng: 21.178527 },
    { lat: 48.6539952, lng: 21.1788843 },
    { lat: 48.6537332, lng: 21.1811692 },
    { lat: 48.653704, lng: 21.1814204 },
    { lat: 48.6536528, lng: 21.1818694 },
    { lat: 48.653375, lng: 21.1831918 },
    { lat: 48.6534493, lng: 21.183299 },
    { lat: 48.6531284, lng: 21.1847594 },
    { lat: 48.6530161, lng: 21.1855114 },
    { lat: 48.6529855, lng: 21.185719 },
    { lat: 48.6529777, lng: 21.1857605 },
    { lat: 48.6527907, lng: 21.1867884 },
    { lat: 48.6524802, lng: 21.1883612 },
    { lat: 48.6520238, lng: 21.189518 },
    { lat: 48.6517971, lng: 21.1899707 },
    { lat: 48.6517261, lng: 21.1904293 },
    { lat: 48.6513464, lng: 21.1913798 },
    { lat: 48.6512336, lng: 21.1918004 },
    { lat: 48.6508058, lng: 21.1923697 },
    { lat: 48.6505214, lng: 21.1924168 },
    { lat: 48.6502649, lng: 21.1925626 },
    { lat: 48.6501461, lng: 21.1926832 },
    { lat: 48.6499472, lng: 21.1932307 },
    { lat: 48.6498625, lng: 21.1933293 },
    { lat: 48.6498137, lng: 21.1934972 },
    { lat: 48.64977, lng: 21.1936819 },
    { lat: 48.6496278, lng: 21.1944894 },
    { lat: 48.6496023, lng: 21.1946414 },
    { lat: 48.6495263, lng: 21.1947298 },
    { lat: 48.6494161, lng: 21.1948078 },
    { lat: 48.6493577, lng: 21.1949198 },
    { lat: 48.6493129, lng: 21.1952811 },
    { lat: 48.649265, lng: 21.1957639 },
    { lat: 48.6491327, lng: 21.1961724 },
    { lat: 48.6490626, lng: 21.1966996 },
    { lat: 48.648839, lng: 21.1974965 },
    { lat: 48.6487342, lng: 21.19803 },
    { lat: 48.6486474, lng: 21.1984925 },
    { lat: 48.6485991, lng: 21.1989953 },
    { lat: 48.6484587, lng: 21.1997539 },
    { lat: 48.6483944, lng: 21.2001799 },
    { lat: 48.6483577, lng: 21.2005178 },
    { lat: 48.6482843, lng: 21.2009751 },
    { lat: 48.6481961, lng: 21.201427 },
    { lat: 48.6480498, lng: 21.2021414 },
    { lat: 48.6480215, lng: 21.2022812 },
    { lat: 48.6480019, lng: 21.2023734 },
    { lat: 48.6479207, lng: 21.2028703 },
    { lat: 48.6479116, lng: 21.2032614 },
    { lat: 48.6478804, lng: 21.2033067 },
    { lat: 48.6478502, lng: 21.2033487 },
    { lat: 48.6477705, lng: 21.203463 },
    { lat: 48.6477479, lng: 21.2034963 },
    { lat: 48.6477167, lng: 21.2035405 },
    { lat: 48.6476979, lng: 21.2036251 },
    { lat: 48.6476909, lng: 21.2036541 },
    { lat: 48.647686, lng: 21.2037114 },
    { lat: 48.6476834, lng: 21.2037365 },
    { lat: 48.6476784, lng: 21.2037906 },
    { lat: 48.6476667, lng: 21.2038466 },
    { lat: 48.6476569, lng: 21.2038971 },
    { lat: 48.6476461, lng: 21.2039477 },
    { lat: 48.6470824, lng: 21.2080026 },
    { lat: 48.6470733, lng: 21.2080702 },
    { lat: 48.6466818, lng: 21.2107696 },
    { lat: 48.6463522, lng: 21.2130374 },
    { lat: 48.6461551, lng: 21.2145757 },
    { lat: 48.6460648, lng: 21.2147833 },
    { lat: 48.6458967, lng: 21.2149853 },
    { lat: 48.6449132, lng: 21.2220087 },
    { lat: 48.644893, lng: 21.2221546 },
    { lat: 48.6448875, lng: 21.2221935 },
    { lat: 48.645271, lng: 21.222897 },
    { lat: 48.645471, lng: 21.223756 },
    { lat: 48.645474, lng: 21.223849 },
    { lat: 48.645527, lng: 21.223912 },
    { lat: 48.6465, lng: 21.225834 },
    { lat: 48.646524, lng: 21.225882 },
    { lat: 48.646529, lng: 21.225894 },
    { lat: 48.646545, lng: 21.225933 },
    { lat: 48.646577, lng: 21.226011 },
    { lat: 48.646578, lng: 21.226013 },
    { lat: 48.646582, lng: 21.226022 },
    { lat: 48.646602, lng: 21.226073 },
    { lat: 48.646616, lng: 21.226105 },
    { lat: 48.646624, lng: 21.226126 },
    { lat: 48.64714, lng: 21.227391 },
    { lat: 48.647811, lng: 21.228485 },
    { lat: 48.648207, lng: 21.229131 },
    { lat: 48.64823, lng: 21.229167 },
    { lat: 48.648261, lng: 21.229359 },
    { lat: 48.648322, lng: 21.229554 },
    { lat: 48.648594, lng: 21.230979 },
    { lat: 48.649092, lng: 21.233811 },
    { lat: 48.649102, lng: 21.233869 },
    { lat: 48.649166, lng: 21.234229 },
    { lat: 48.649118, lng: 21.234433 },
    { lat: 48.649042, lng: 21.234683 },
    { lat: 48.648757, lng: 21.235597 },
    { lat: 48.648822, lng: 21.236379 },
    { lat: 48.64882, lng: 21.236458 },
    { lat: 48.648819, lng: 21.2365 },
    { lat: 48.648816, lng: 21.236617 },
    { lat: 48.648774, lng: 21.236919 },
    { lat: 48.648595, lng: 21.237433 },
    { lat: 48.648566, lng: 21.237515 },
    { lat: 48.648525, lng: 21.237635 },
    { lat: 48.648412, lng: 21.237959 },
    { lat: 48.648207, lng: 21.238356 },
    { lat: 48.648196, lng: 21.238379 },
    { lat: 48.648031, lng: 21.238698 },
    { lat: 48.647829, lng: 21.239154 },
    { lat: 48.647769, lng: 21.23925 },
    { lat: 48.647545, lng: 21.239475 },
    { lat: 48.647411, lng: 21.239615 },
    { lat: 48.647292, lng: 21.239751 },
    { lat: 48.647287, lng: 21.239756 },
    { lat: 48.647274, lng: 21.239789 },
    { lat: 48.647256, lng: 21.239835 },
    { lat: 48.647253, lng: 21.239842 },
    { lat: 48.647243, lng: 21.239868 },
    { lat: 48.647234, lng: 21.23989 },
    { lat: 48.64721, lng: 21.239949 },
    { lat: 48.646901, lng: 21.240729 },
    { lat: 48.64649, lng: 21.241818 },
    { lat: 48.646014, lng: 21.242636 },
    { lat: 48.645768, lng: 21.243828 },
    { lat: 48.645086, lng: 21.247221 },
    { lat: 48.645069, lng: 21.247386 },
    { lat: 48.645066, lng: 21.247639 },
    { lat: 48.6450071, lng: 21.2487645 },
  ],
  KošicemestskáčasťLorinčík: [
    { lat: 48.697232, lng: 21.178464 },
    { lat: 48.696873, lng: 21.178746 },
    { lat: 48.696772, lng: 21.178941 },
    { lat: 48.696539, lng: 21.179169 },
    { lat: 48.696478, lng: 21.17926 },
    { lat: 48.696341, lng: 21.17947 },
    { lat: 48.695999, lng: 21.179874 },
    { lat: 48.695996, lng: 21.179876 },
    { lat: 48.695688, lng: 21.180028 },
    { lat: 48.695494, lng: 21.180051 },
    { lat: 48.695192, lng: 21.180023 },
    { lat: 48.694257, lng: 21.180459 },
    { lat: 48.693591, lng: 21.18076 },
    { lat: 48.693532, lng: 21.180776 },
    { lat: 48.693269, lng: 21.180851 },
    { lat: 48.69312, lng: 21.180893 },
    { lat: 48.692919, lng: 21.180907 },
    { lat: 48.692388, lng: 21.181236 },
    { lat: 48.692109, lng: 21.181362 },
    { lat: 48.691919, lng: 21.181417 },
    { lat: 48.691689, lng: 21.181422 },
    { lat: 48.691467, lng: 21.181401 },
    { lat: 48.691261, lng: 21.181498 },
    { lat: 48.691057, lng: 21.181593 },
    { lat: 48.690639, lng: 21.181705 },
    { lat: 48.69032, lng: 21.181746 },
    { lat: 48.690037, lng: 21.181887 },
    { lat: 48.689705, lng: 21.181974 },
    { lat: 48.689323, lng: 21.182016 },
    { lat: 48.688941, lng: 21.181953 },
    { lat: 48.687386, lng: 21.181332 },
    { lat: 48.687051, lng: 21.181201 },
    { lat: 48.686972, lng: 21.1812 },
    { lat: 48.686838, lng: 21.181197 },
    { lat: 48.686585, lng: 21.181192 },
    { lat: 48.686334, lng: 21.18112 },
    { lat: 48.685698, lng: 21.180783 },
    { lat: 48.685447, lng: 21.180651 },
    { lat: 48.685422, lng: 21.180637 },
    { lat: 48.685339, lng: 21.180594 },
    { lat: 48.685139, lng: 21.180488 },
    { lat: 48.684712, lng: 21.180481 },
    { lat: 48.684222, lng: 21.180552 },
    { lat: 48.683784, lng: 21.180594 },
    { lat: 48.683574, lng: 21.18059 },
    { lat: 48.682817, lng: 21.180651 },
    { lat: 48.681626, lng: 21.180792 },
    { lat: 48.681355, lng: 21.180883 },
    { lat: 48.681082, lng: 21.180974 },
    { lat: 48.680628, lng: 21.181202 },
    { lat: 48.680392, lng: 21.181288 },
    { lat: 48.680317, lng: 21.181316 },
    { lat: 48.679885, lng: 21.181302 },
    { lat: 48.679619, lng: 21.1813 },
    { lat: 48.679193, lng: 21.181258 },
    { lat: 48.679169, lng: 21.181255 },
    { lat: 48.679135, lng: 21.18124 },
    { lat: 48.678503, lng: 21.180949 },
    { lat: 48.678158, lng: 21.180865 },
    { lat: 48.678012, lng: 21.18083 },
    { lat: 48.677912, lng: 21.18079 },
    { lat: 48.677513, lng: 21.180634 },
    { lat: 48.67717, lng: 21.180589 },
    { lat: 48.676784, lng: 21.180537 },
    { lat: 48.676576, lng: 21.18051 },
    { lat: 48.676376, lng: 21.180563 },
    { lat: 48.6763444, lng: 21.1805767 },
    { lat: 48.6761109, lng: 21.1806769 },
    { lat: 48.6761618, lng: 21.1807572 },
    { lat: 48.6764095, lng: 21.1811525 },
    { lat: 48.6764427, lng: 21.1812057 },
    { lat: 48.67649, lng: 21.1812781 },
    { lat: 48.6766542, lng: 21.1815273 },
    { lat: 48.6770782, lng: 21.1820509 },
    { lat: 48.6773471, lng: 21.1824055 },
    { lat: 48.6775955, lng: 21.1827494 },
    { lat: 48.6776676, lng: 21.1828614 },
    { lat: 48.6778247, lng: 21.1831311 },
    { lat: 48.6783107, lng: 21.1839786 },
    { lat: 48.6784047, lng: 21.1841427 },
    { lat: 48.6788335, lng: 21.18489 },
    { lat: 48.6790631, lng: 21.1853219 },
    { lat: 48.6795464, lng: 21.1860824 },
    { lat: 48.6797311, lng: 21.1862678 },
    { lat: 48.68004, lng: 21.1864953 },
    { lat: 48.6802053, lng: 21.1865625 },
    { lat: 48.6803928, lng: 21.1866184 },
    { lat: 48.6804188, lng: 21.1866262 },
    { lat: 48.6804916, lng: 21.186648 },
    { lat: 48.6809868, lng: 21.1867947 },
    { lat: 48.6813386, lng: 21.186918 },
    { lat: 48.6815548, lng: 21.1869939 },
    { lat: 48.6815968, lng: 21.1870066 },
    { lat: 48.6817129, lng: 21.187047 },
    { lat: 48.6818991, lng: 21.1870995 },
    { lat: 48.6821027, lng: 21.1871693 },
    { lat: 48.6823059, lng: 21.1872932 },
    { lat: 48.6824802, lng: 21.1874307 },
    { lat: 48.6825056, lng: 21.1874509 },
    { lat: 48.6825277, lng: 21.1874746 },
    { lat: 48.682558, lng: 21.1875088 },
    { lat: 48.6826805, lng: 21.1876438 },
    { lat: 48.6828973, lng: 21.1880264 },
    { lat: 48.6829999, lng: 21.1883154 },
    { lat: 48.6831069, lng: 21.1886774 },
    { lat: 48.6831314, lng: 21.1888466 },
    { lat: 48.6831371, lng: 21.1888864 },
    { lat: 48.6831546, lng: 21.1890105 },
    { lat: 48.6831467, lng: 21.1896191 },
    { lat: 48.6831398, lng: 21.1902236 },
    { lat: 48.6832, lng: 21.1918127 },
    { lat: 48.6832207, lng: 21.1923284 },
    { lat: 48.6832314, lng: 21.1933459 },
    { lat: 48.6832267, lng: 21.1937327 },
    { lat: 48.6832191, lng: 21.1938835 },
    { lat: 48.6831947, lng: 21.1943586 },
    { lat: 48.6831363, lng: 21.1947218 },
    { lat: 48.6831118, lng: 21.1948742 },
    { lat: 48.6830863, lng: 21.1950299 },
    { lat: 48.6830759, lng: 21.195094 },
    { lat: 48.6830752, lng: 21.1951044 },
    { lat: 48.68306, lng: 21.1951949 },
    { lat: 48.683059, lng: 21.1952 },
    { lat: 48.6829932, lng: 21.1956114 },
    { lat: 48.6829778, lng: 21.1956927 },
    { lat: 48.6829623, lng: 21.1957753 },
    { lat: 48.6829365, lng: 21.195883 },
    { lat: 48.6829226, lng: 21.1959438 },
    { lat: 48.6828731, lng: 21.1961571 },
    { lat: 48.6828125, lng: 21.1964133 },
    { lat: 48.6826333, lng: 21.1973706 },
    { lat: 48.6826175, lng: 21.1974441 },
    { lat: 48.6824714, lng: 21.1981658 },
    { lat: 48.6824304, lng: 21.1983657 },
    { lat: 48.68237, lng: 21.1984847 },
    { lat: 48.6822323, lng: 21.1988325 },
    { lat: 48.6820932, lng: 21.1991285 },
    { lat: 48.6817987, lng: 21.1997501 },
    { lat: 48.6817712, lng: 21.199809 },
    { lat: 48.6816652, lng: 21.2000182 },
    { lat: 48.6811302, lng: 21.2010797 },
    { lat: 48.6811038, lng: 21.2011332 },
    { lat: 48.6808826, lng: 21.2016495 },
    { lat: 48.68072, lng: 21.2021739 },
    { lat: 48.6806612, lng: 21.2024876 },
    { lat: 48.6806059, lng: 21.203196 },
    { lat: 48.6806097, lng: 21.2043237 },
    { lat: 48.6806002, lng: 21.2049864 },
    { lat: 48.6806007, lng: 21.2050035 },
    { lat: 48.6805974, lng: 21.2052227 },
    { lat: 48.680597, lng: 21.205247 },
    { lat: 48.6806114, lng: 21.2053439 },
    { lat: 48.6806161, lng: 21.2054233 },
    { lat: 48.6806297, lng: 21.2056766 },
    { lat: 48.6806777, lng: 21.2059873 },
    { lat: 48.6807067, lng: 21.2061612 },
    { lat: 48.680722, lng: 21.206253 },
    { lat: 48.6808179, lng: 21.2065492 },
    { lat: 48.6808385, lng: 21.2065966 },
    { lat: 48.6808726, lng: 21.2066764 },
    { lat: 48.6808872, lng: 21.2067113 },
    { lat: 48.6809005, lng: 21.2067417 },
    { lat: 48.6809248, lng: 21.2067991 },
    { lat: 48.6811586, lng: 21.2071855 },
    { lat: 48.6813341, lng: 21.2073607 },
    { lat: 48.6813411, lng: 21.2073683 },
    { lat: 48.6813457, lng: 21.2073726 },
    { lat: 48.6815362, lng: 21.2075631 },
    { lat: 48.6820317, lng: 21.2080366 },
    { lat: 48.6820468, lng: 21.2080519 },
    { lat: 48.6821423, lng: 21.2081915 },
    { lat: 48.6823121, lng: 21.2084397 },
    { lat: 48.6824938, lng: 21.2088491 },
    { lat: 48.6826188, lng: 21.2093192 },
    { lat: 48.6826227, lng: 21.2093342 },
    { lat: 48.6826327, lng: 21.2093684 },
    { lat: 48.6826797, lng: 21.2097557 },
    { lat: 48.6826963, lng: 21.2098881 },
    { lat: 48.6827236, lng: 21.2099339 },
    { lat: 48.6827688, lng: 21.2100119 },
    { lat: 48.682755, lng: 21.2100713 },
    { lat: 48.6826697, lng: 21.210441 },
    { lat: 48.6826073, lng: 21.2107129 },
    { lat: 48.6825766, lng: 21.2108437 },
    { lat: 48.6825633, lng: 21.2109247 },
    { lat: 48.6825021, lng: 21.2113074 },
    { lat: 48.6824635, lng: 21.2115487 },
    { lat: 48.6824148, lng: 21.2121473 },
    { lat: 48.6824066, lng: 21.2122466 },
    { lat: 48.682392, lng: 21.212426 },
    { lat: 48.682391, lng: 21.2125009 },
    { lat: 48.6823883, lng: 21.2127394 },
    { lat: 48.6823822, lng: 21.213263 },
    { lat: 48.6823768, lng: 21.2136676 },
    { lat: 48.6824406, lng: 21.214339 },
    { lat: 48.6824918, lng: 21.2145722 },
    { lat: 48.6825311, lng: 21.2147471 },
    { lat: 48.6825372, lng: 21.2147663 },
    { lat: 48.6826269, lng: 21.215007 },
    { lat: 48.6827577, lng: 21.2153001 },
    { lat: 48.682852, lng: 21.2155102 },
    { lat: 48.682869, lng: 21.2155493 },
    { lat: 48.6828762, lng: 21.2155644 },
    { lat: 48.6830165, lng: 21.2158778 },
    { lat: 48.6832318, lng: 21.2149514 },
    { lat: 48.6832528, lng: 21.2148652 },
    { lat: 48.6832731, lng: 21.2145097 },
    { lat: 48.6832532, lng: 21.214379 },
    { lat: 48.683215, lng: 21.2141334 },
    { lat: 48.683178, lng: 21.2138782 },
    { lat: 48.6831792, lng: 21.2136737 },
    { lat: 48.683196, lng: 21.2134478 },
    { lat: 48.6832321, lng: 21.2133081 },
    { lat: 48.683238, lng: 21.213293 },
    { lat: 48.6833208, lng: 21.2130817 },
    { lat: 48.6833738, lng: 21.212836 },
    { lat: 48.6834656, lng: 21.212713 },
    { lat: 48.6834775, lng: 21.2126979 },
    { lat: 48.683554, lng: 21.2126227 },
    { lat: 48.6835776, lng: 21.2126217 },
    { lat: 48.6836056, lng: 21.2126204 },
    { lat: 48.6836597, lng: 21.2126194 },
    { lat: 48.6837526, lng: 21.2126056 },
    { lat: 48.6838951, lng: 21.2125189 },
    { lat: 48.6839542, lng: 21.2124659 },
    { lat: 48.6841346, lng: 21.2122251 },
    { lat: 48.6846158, lng: 21.2116333 },
    { lat: 48.6846375, lng: 21.2116065 },
    { lat: 48.6847307, lng: 21.211493 },
    { lat: 48.6850359, lng: 21.2110352 },
    { lat: 48.6851722, lng: 21.2107847 },
    { lat: 48.6853818, lng: 21.2103993 },
    { lat: 48.6856158, lng: 21.2100033 },
    { lat: 48.6856341, lng: 21.2099731 },
    { lat: 48.6860157, lng: 21.209329 },
    { lat: 48.6860882, lng: 21.209202 },
    { lat: 48.6862194, lng: 21.2089703 },
    { lat: 48.6863073, lng: 21.2088337 },
    { lat: 48.6864024, lng: 21.2086458 },
    { lat: 48.6864206, lng: 21.2085522 },
    { lat: 48.6864162, lng: 21.208463 },
    { lat: 48.6864796, lng: 21.2081151 },
    { lat: 48.6865028, lng: 21.2078153 },
    { lat: 48.6865261, lng: 21.2076227 },
    { lat: 48.6865486, lng: 21.2074444 },
    { lat: 48.6865763, lng: 21.2072166 },
    { lat: 48.6865902, lng: 21.2071027 },
    { lat: 48.6866157, lng: 21.2068933 },
    { lat: 48.6867225, lng: 21.2066406 },
    { lat: 48.6868766, lng: 21.2064914 },
    { lat: 48.6870138, lng: 21.2063106 },
    { lat: 48.6870542, lng: 21.2060918 },
    { lat: 48.687063, lng: 21.2058682 },
    { lat: 48.686989, lng: 21.205481 },
    { lat: 48.6869604, lng: 21.2053976 },
    { lat: 48.6868304, lng: 21.2050194 },
    { lat: 48.6867824, lng: 21.204878 },
    { lat: 48.6866988, lng: 21.2047502 },
    { lat: 48.6866585, lng: 21.2046885 },
    { lat: 48.6866038, lng: 21.2046049 },
    { lat: 48.6865225, lng: 21.2044687 },
    { lat: 48.6864625, lng: 21.2043683 },
    { lat: 48.6864326, lng: 21.2043182 },
    { lat: 48.6863867, lng: 21.2042414 },
    { lat: 48.6863542, lng: 21.2042087 },
    { lat: 48.6863173, lng: 21.2041717 },
    { lat: 48.6862514, lng: 21.2041055 },
    { lat: 48.6860861, lng: 21.2040369 },
    { lat: 48.6857622, lng: 21.203977 },
    { lat: 48.6856259, lng: 21.2039518 },
    { lat: 48.6854749, lng: 21.2039084 },
    { lat: 48.6853484, lng: 21.2038721 },
    { lat: 48.6852716, lng: 21.2038238 },
    { lat: 48.6852193, lng: 21.2037545 },
    { lat: 48.6851358, lng: 21.2035807 },
    { lat: 48.6851099, lng: 21.2035272 },
    { lat: 48.6850195, lng: 21.2033409 },
    { lat: 48.6850068, lng: 21.2033067 },
    { lat: 48.6849295, lng: 21.2030988 },
    { lat: 48.6849034, lng: 21.2030351 },
    { lat: 48.684867, lng: 21.2029459 },
    { lat: 48.6847974, lng: 21.2027756 },
    { lat: 48.6847393, lng: 21.2026334 },
    { lat: 48.6846817, lng: 21.2024925 },
    { lat: 48.6846127, lng: 21.2023215 },
    { lat: 48.6845938, lng: 21.202191 },
    { lat: 48.6845841, lng: 21.2020402 },
    { lat: 48.6845884, lng: 21.2018894 },
    { lat: 48.6846223, lng: 21.2016491 },
    { lat: 48.6846743, lng: 21.2011802 },
    { lat: 48.6846978, lng: 21.2009003 },
    { lat: 48.6847167, lng: 21.2006482 },
    { lat: 48.6847511, lng: 21.2001898 },
    { lat: 48.6847754, lng: 21.1997846 },
    { lat: 48.6847848, lng: 21.1996059 },
    { lat: 48.6847903, lng: 21.1995033 },
    { lat: 48.684794, lng: 21.1994324 },
    { lat: 48.6848201, lng: 21.1991693 },
    { lat: 48.6848544, lng: 21.1988231 },
    { lat: 48.6849665, lng: 21.1983752 },
    { lat: 48.6849756, lng: 21.1983389 },
    { lat: 48.685027, lng: 21.1981335 },
    { lat: 48.6851198, lng: 21.198279 },
    { lat: 48.6853127, lng: 21.1986023 },
    { lat: 48.6854429, lng: 21.1988394 },
    { lat: 48.6855478, lng: 21.1990305 },
    { lat: 48.6856558, lng: 21.1992272 },
    { lat: 48.6857163, lng: 21.1993374 },
    { lat: 48.6857745, lng: 21.1994434 },
    { lat: 48.6858242, lng: 21.1995341 },
    { lat: 48.6858695, lng: 21.1996166 },
    { lat: 48.6859387, lng: 21.1997426 },
    { lat: 48.686009, lng: 21.1998706 },
    { lat: 48.6860945, lng: 21.2000263 },
    { lat: 48.6861507, lng: 21.2001288 },
    { lat: 48.6862386, lng: 21.2002889 },
    { lat: 48.6863543, lng: 21.2004633 },
    { lat: 48.6864635, lng: 21.2006439 },
    { lat: 48.6865773, lng: 21.2008299 },
    { lat: 48.6867215, lng: 21.2010821 },
    { lat: 48.6868746, lng: 21.2012973 },
    { lat: 48.6870238, lng: 21.2015003 },
    { lat: 48.6870333, lng: 21.2015121 },
    { lat: 48.6871342, lng: 21.2016466 },
    { lat: 48.6871543, lng: 21.2016737 },
    { lat: 48.6872711, lng: 21.2018448 },
    { lat: 48.6873266, lng: 21.2019299 },
    { lat: 48.6873905, lng: 21.2020272 },
    { lat: 48.6874965, lng: 21.2022141 },
    { lat: 48.6876034, lng: 21.2023945 },
    { lat: 48.6877198, lng: 21.2025892 },
    { lat: 48.6878287, lng: 21.2027623 },
    { lat: 48.68792, lng: 21.2029093 },
    { lat: 48.6879459, lng: 21.2028729 },
    { lat: 48.6879804, lng: 21.202822 },
    { lat: 48.6881148, lng: 21.202624 },
    { lat: 48.6882439, lng: 21.2024348 },
    { lat: 48.6883956, lng: 21.2022124 },
    { lat: 48.6885247, lng: 21.2020229 },
    { lat: 48.6886528, lng: 21.2018353 },
    { lat: 48.6887797, lng: 21.2016492 },
    { lat: 48.6889056, lng: 21.2014646 },
    { lat: 48.6889723, lng: 21.2013654 },
    { lat: 48.6890214, lng: 21.2012785 },
    { lat: 48.6891012, lng: 21.2011367 },
    { lat: 48.689141, lng: 21.2010776 },
    { lat: 48.6892679, lng: 21.2008879 },
    { lat: 48.6893937, lng: 21.2006976 },
    { lat: 48.6895193, lng: 21.2005073 },
    { lat: 48.6895902, lng: 21.2003993 },
    { lat: 48.689773, lng: 21.2001632 },
    { lat: 48.6900163, lng: 21.1998501 },
    { lat: 48.6900445, lng: 21.1998132 },
    { lat: 48.6901645, lng: 21.1996575 },
    { lat: 48.6901845, lng: 21.1996877 },
    { lat: 48.6901975, lng: 21.1997068 },
    { lat: 48.6904303, lng: 21.2000577 },
    { lat: 48.6905424, lng: 21.2002256 },
    { lat: 48.6907383, lng: 21.200526 },
    { lat: 48.6909687, lng: 21.2008591 },
    { lat: 48.6912297, lng: 21.2012514 },
    { lat: 48.6912457, lng: 21.2012243 },
    { lat: 48.6912852, lng: 21.2011584 },
    { lat: 48.6912961, lng: 21.2011434 },
    { lat: 48.6913832, lng: 21.2010146 },
    { lat: 48.6915295, lng: 21.2008004 },
    { lat: 48.6915629, lng: 21.200751 },
    { lat: 48.6916657, lng: 21.2005854 },
    { lat: 48.6916914, lng: 21.2005444 },
    { lat: 48.6918225, lng: 21.200312 },
    { lat: 48.6918451, lng: 21.2002805 },
    { lat: 48.6918883, lng: 21.2002197 },
    { lat: 48.6919867, lng: 21.2001293 },
    { lat: 48.6920607, lng: 21.2000812 },
    { lat: 48.692126, lng: 21.2000466 },
    { lat: 48.692236, lng: 21.200002 },
    { lat: 48.6923617, lng: 21.1999898 },
    { lat: 48.6927486, lng: 21.2000209 },
    { lat: 48.6927838, lng: 21.2000312 },
    { lat: 48.6928111, lng: 21.2000389 },
    { lat: 48.6928159, lng: 21.2000456 },
    { lat: 48.692855, lng: 21.2001116 },
    { lat: 48.6929351, lng: 21.2001896 },
    { lat: 48.6935316, lng: 21.2010858 },
    { lat: 48.6936358, lng: 21.2012469 },
    { lat: 48.6941088, lng: 21.2019789 },
    { lat: 48.6943092, lng: 21.202304 },
    { lat: 48.694421, lng: 21.2025304 },
    { lat: 48.6944501, lng: 21.2026295 },
    { lat: 48.6944627, lng: 21.2026735 },
    { lat: 48.6944639, lng: 21.2028209 },
    { lat: 48.6944361, lng: 21.2029732 },
    { lat: 48.6944034, lng: 21.2030796 },
    { lat: 48.6943795, lng: 21.2031404 },
    { lat: 48.6943491, lng: 21.2031796 },
    { lat: 48.6943678, lng: 21.2032101 },
    { lat: 48.6945969, lng: 21.2029339 },
    { lat: 48.6946294, lng: 21.2028935 },
    { lat: 48.6946315, lng: 21.2028909 },
    { lat: 48.6946684, lng: 21.2028454 },
    { lat: 48.6947139, lng: 21.2026455 },
    { lat: 48.694799, lng: 21.2022725 },
    { lat: 48.6948678, lng: 21.2020631 },
    { lat: 48.694877, lng: 21.2020339 },
    { lat: 48.6949945, lng: 21.2018804 },
    { lat: 48.6950013, lng: 21.2018014 },
    { lat: 48.6950077, lng: 21.2017272 },
    { lat: 48.6950276, lng: 21.2015785 },
    { lat: 48.695041, lng: 21.2013415 },
    { lat: 48.6950151, lng: 21.2011749 },
    { lat: 48.694975, lng: 21.2009167 },
    { lat: 48.6949494, lng: 21.2006692 },
    { lat: 48.6949344, lng: 21.200589 },
    { lat: 48.6949199, lng: 21.2005115 },
    { lat: 48.694911, lng: 21.2004292 },
    { lat: 48.6948954, lng: 21.2002822 },
    { lat: 48.694855, lng: 21.1999235 },
    { lat: 48.6948936, lng: 21.1998823 },
    { lat: 48.694953, lng: 21.1998219 },
    { lat: 48.6950405, lng: 21.1996716 },
    { lat: 48.6950656, lng: 21.1996283 },
    { lat: 48.6950953, lng: 21.1995744 },
    { lat: 48.6951087, lng: 21.1995543 },
    { lat: 48.6951125, lng: 21.1995466 },
    { lat: 48.695119, lng: 21.199524 },
    { lat: 48.6952932, lng: 21.1992017 },
    { lat: 48.695352, lng: 21.198896 },
    { lat: 48.695389, lng: 21.198727 },
    { lat: 48.695446, lng: 21.19862 },
    { lat: 48.695545, lng: 21.198436 },
    { lat: 48.695632, lng: 21.198077 },
    { lat: 48.695662, lng: 21.197956 },
    { lat: 48.695665, lng: 21.197736 },
    { lat: 48.69567, lng: 21.197431 },
    { lat: 48.695676, lng: 21.197082 },
    { lat: 48.695677, lng: 21.197064 },
    { lat: 48.695715, lng: 21.197005 },
    { lat: 48.695797, lng: 21.196879 },
    { lat: 48.695892, lng: 21.196732 },
    { lat: 48.695989, lng: 21.196584 },
    { lat: 48.696001, lng: 21.196455 },
    { lat: 48.696029, lng: 21.196162 },
    { lat: 48.696059, lng: 21.195844 },
    { lat: 48.6959495, lng: 21.1955633 },
    { lat: 48.695856, lng: 21.195326 },
    { lat: 48.695839, lng: 21.19528 },
    { lat: 48.6959308, lng: 21.1946775 },
    { lat: 48.696067, lng: 21.194598 },
    { lat: 48.696107, lng: 21.194544 },
    { lat: 48.696227, lng: 21.194384 },
    { lat: 48.69632, lng: 21.19426 },
    { lat: 48.69642, lng: 21.194139 },
    { lat: 48.696556, lng: 21.19399 },
    { lat: 48.696584, lng: 21.193958 },
    { lat: 48.696714, lng: 21.193802 },
    { lat: 48.696742, lng: 21.193735 },
    { lat: 48.697022, lng: 21.193062 },
    { lat: 48.697022, lng: 21.19275 },
    { lat: 48.697022, lng: 21.192633 },
    { lat: 48.697014, lng: 21.192579 },
    { lat: 48.6970967, lng: 21.1921573 },
    { lat: 48.6971297, lng: 21.1915522 },
    { lat: 48.697224, lng: 21.190807 },
    { lat: 48.697244, lng: 21.190607 },
    { lat: 48.697286, lng: 21.190163 },
    { lat: 48.697293, lng: 21.190096 },
    { lat: 48.697504, lng: 21.189551 },
    { lat: 48.697773, lng: 21.188903 },
    { lat: 48.698129, lng: 21.18808 },
    { lat: 48.698198, lng: 21.187919 },
    { lat: 48.698203, lng: 21.187908 },
    { lat: 48.6982924, lng: 21.1877044 },
    { lat: 48.6983197, lng: 21.187415 },
    { lat: 48.698279, lng: 21.186968 },
    { lat: 48.6982258, lng: 21.1865845 },
    { lat: 48.698211, lng: 21.186478 },
    { lat: 48.698192, lng: 21.186339 },
    { lat: 48.698085, lng: 21.185926 },
    { lat: 48.698043, lng: 21.185752 },
    { lat: 48.697987, lng: 21.185415 },
    { lat: 48.697927, lng: 21.1850392 },
    { lat: 48.697889, lng: 21.184801 },
    { lat: 48.697842, lng: 21.183918 },
    { lat: 48.697758, lng: 21.183334 },
    { lat: 48.697704, lng: 21.182955 },
    { lat: 48.697617, lng: 21.182562 },
    { lat: 48.697497, lng: 21.18219 },
    { lat: 48.697474, lng: 21.182115 },
    { lat: 48.697466, lng: 21.181834 },
    { lat: 48.697541, lng: 21.181475 },
    { lat: 48.697637, lng: 21.181021 },
    { lat: 48.697628, lng: 21.180632 },
    { lat: 48.697626, lng: 21.180475 },
    { lat: 48.697704, lng: 21.180098 },
    { lat: 48.697832, lng: 21.179741 },
    { lat: 48.69766, lng: 21.17943 },
    { lat: 48.697513, lng: 21.179182 },
    { lat: 48.697337, lng: 21.178854 },
    { lat: 48.697241, lng: 21.178497 },
    { lat: 48.697232, lng: 21.178464 },
  ],
  KošicemestskáčasťZápad: [
    { lat: 48.726345, lng: 21.238916 },
    { lat: 48.726251, lng: 21.238173 },
    { lat: 48.726168, lng: 21.238011 },
    { lat: 48.726152, lng: 21.238019 },
    { lat: 48.726138, lng: 21.237978 },
    { lat: 48.726135, lng: 21.237982 },
    { lat: 48.726112, lng: 21.237908 },
    { lat: 48.726073, lng: 21.237775 },
    { lat: 48.726074, lng: 21.237565 },
    { lat: 48.725996, lng: 21.237178 },
    { lat: 48.725951, lng: 21.237197 },
    { lat: 48.725929, lng: 21.237093 },
    { lat: 48.725929, lng: 21.237089 },
    { lat: 48.72584, lng: 21.2366535 },
    { lat: 48.725846, lng: 21.23665 },
    { lat: 48.725833, lng: 21.236574 },
    { lat: 48.725841, lng: 21.236571 },
    { lat: 48.725808, lng: 21.236393 },
    { lat: 48.725813, lng: 21.236392 },
    { lat: 48.725801, lng: 21.236316 },
    { lat: 48.725797, lng: 21.236035 },
    { lat: 48.725793, lng: 21.236035 },
    { lat: 48.725793, lng: 21.236002 },
    { lat: 48.7257955, lng: 21.236002 },
    { lat: 48.725803, lng: 21.235849 },
    { lat: 48.725801, lng: 21.235849 },
    { lat: 48.725804, lng: 21.235736 },
    { lat: 48.725812, lng: 21.235736 },
    { lat: 48.725821, lng: 21.235636 },
    { lat: 48.725812, lng: 21.235636 },
    { lat: 48.725814, lng: 21.235583 },
    { lat: 48.725828, lng: 21.235585 },
    { lat: 48.725837, lng: 21.235435 },
    { lat: 48.725812, lng: 21.235431 },
    { lat: 48.725815, lng: 21.235378 },
    { lat: 48.725839, lng: 21.235381 },
    { lat: 48.725846, lng: 21.235248 },
    { lat: 48.725844, lng: 21.2352475 },
    { lat: 48.725842, lng: 21.2352 },
    { lat: 48.725854, lng: 21.235199 },
    { lat: 48.725842, lng: 21.234945 },
    { lat: 48.725838, lng: 21.234945 },
    { lat: 48.725835, lng: 21.234899 },
    { lat: 48.725841, lng: 21.234541 },
    { lat: 48.725868, lng: 21.23454 },
    { lat: 48.725864, lng: 21.2343825 },
    { lat: 48.725868, lng: 21.234381 },
    { lat: 48.725866, lng: 21.234242 },
    { lat: 48.7259, lng: 21.234241 },
    { lat: 48.725895, lng: 21.233934 },
    { lat: 48.725889, lng: 21.233934 },
    { lat: 48.725808, lng: 21.233014 },
    { lat: 48.725798, lng: 21.233014 },
    { lat: 48.725786, lng: 21.232896 },
    { lat: 48.725775, lng: 21.232898 },
    { lat: 48.725755, lng: 21.232766 },
    { lat: 48.725617, lng: 21.232355 },
    { lat: 48.725613, lng: 21.232348 },
    { lat: 48.725387, lng: 21.231713 },
    { lat: 48.725256, lng: 21.231794 },
    { lat: 48.725194, lng: 21.231687 },
    { lat: 48.725193, lng: 21.231684 },
    { lat: 48.724889, lng: 21.231833 },
    { lat: 48.724848, lng: 21.231579 },
    { lat: 48.724826, lng: 21.231301 },
    { lat: 48.724569, lng: 21.230436 },
    { lat: 48.724498, lng: 21.23023 },
    { lat: 48.724426, lng: 21.230263 },
    { lat: 48.724373, lng: 21.230046 },
    { lat: 48.724769, lng: 21.229846 },
    { lat: 48.724756, lng: 21.229797 },
    { lat: 48.724704, lng: 21.229047 },
    { lat: 48.724702, lng: 21.228261 },
    { lat: 48.72476, lng: 21.227863 },
    { lat: 48.724888, lng: 21.227376 },
    { lat: 48.724881, lng: 21.226969 },
    { lat: 48.724771, lng: 21.227021 },
    { lat: 48.724728, lng: 21.22699 },
    { lat: 48.724717, lng: 21.226892 },
    { lat: 48.724753, lng: 21.226635 },
    { lat: 48.724794, lng: 21.226441 },
    { lat: 48.724859, lng: 21.226317 },
    { lat: 48.72486, lng: 21.226314 },
    { lat: 48.724853, lng: 21.226265 },
    { lat: 48.725014, lng: 21.225692 },
    { lat: 48.72472, lng: 21.225151 },
    { lat: 48.72454, lng: 21.22475 },
    { lat: 48.724775, lng: 21.224525 },
    { lat: 48.725686, lng: 21.223621 },
    { lat: 48.725704, lng: 21.22359 },
    { lat: 48.726114, lng: 21.222638 },
    { lat: 48.726342, lng: 21.222123 },
    { lat: 48.72645, lng: 21.221887 },
    { lat: 48.726602, lng: 21.221532 },
    { lat: 48.726686, lng: 21.221302 },
    { lat: 48.72669, lng: 21.221278 },
    { lat: 48.726683, lng: 21.220634 },
    { lat: 48.726684, lng: 21.220268 },
    { lat: 48.726927, lng: 21.21919 },
    { lat: 48.726966, lng: 21.219065 },
    { lat: 48.726951, lng: 21.219037 },
    { lat: 48.726887, lng: 21.219108 },
    { lat: 48.726584, lng: 21.219317 },
    { lat: 48.726174, lng: 21.219682 },
    { lat: 48.725937, lng: 21.219845 },
    { lat: 48.72577, lng: 21.219945 },
    { lat: 48.725584, lng: 21.220003 },
    { lat: 48.725565, lng: 21.219989 },
    { lat: 48.7255345, lng: 21.219924 },
    { lat: 48.725556, lng: 21.219913 },
    { lat: 48.725291, lng: 21.219016 },
    { lat: 48.72524, lng: 21.219016 },
    { lat: 48.725176, lng: 21.218756 },
    { lat: 48.725122, lng: 21.218608 },
    { lat: 48.724974, lng: 21.218262 },
    { lat: 48.724886, lng: 21.21809 },
    { lat: 48.724898, lng: 21.217825 },
    { lat: 48.725117, lng: 21.217476 },
    { lat: 48.725253, lng: 21.217278 },
    { lat: 48.725251, lng: 21.217276 },
    { lat: 48.72515, lng: 21.217213 },
    { lat: 48.725096, lng: 21.217024 },
    { lat: 48.724832, lng: 21.216934 },
    { lat: 48.724675, lng: 21.216939 },
    { lat: 48.724633, lng: 21.2168998 },
    { lat: 48.724585, lng: 21.216855 },
    { lat: 48.724513, lng: 21.216773 },
    { lat: 48.724467, lng: 21.216483 },
    { lat: 48.724353, lng: 21.216121 },
    { lat: 48.724342, lng: 21.216098 },
    { lat: 48.724243, lng: 21.216132 },
    { lat: 48.724033, lng: 21.216186 },
    { lat: 48.723678, lng: 21.216323 },
    { lat: 48.72306, lng: 21.216563 },
    { lat: 48.722532, lng: 21.216755 },
    { lat: 48.721837, lng: 21.217021 },
    { lat: 48.721782, lng: 21.2170412 },
    { lat: 48.7217765, lng: 21.2172947 },
    { lat: 48.7217809, lng: 21.2175474 },
    { lat: 48.7217815, lng: 21.2175663 },
    { lat: 48.7218585, lng: 21.2177237 },
    { lat: 48.7219122, lng: 21.2178236 },
    { lat: 48.721944, lng: 21.2178687 },
    { lat: 48.7220816, lng: 21.2181308 },
    { lat: 48.7222948, lng: 21.2186143 },
    { lat: 48.722359, lng: 21.2188321 },
    { lat: 48.7224218, lng: 21.2191858 },
    { lat: 48.722357, lng: 21.2192036 },
    { lat: 48.7223415, lng: 21.219209 },
    { lat: 48.7223114, lng: 21.219219 },
    { lat: 48.721849, lng: 21.2193682 },
    { lat: 48.72181, lng: 21.219381 },
    { lat: 48.7217209, lng: 21.2189411 },
    { lat: 48.7216532, lng: 21.2189348 },
    { lat: 48.7215583, lng: 21.2189263 },
    { lat: 48.7211453, lng: 21.2188891 },
    { lat: 48.7207333, lng: 21.2188525 },
    { lat: 48.7204287, lng: 21.2188272 },
    { lat: 48.7202707, lng: 21.2188128 },
    { lat: 48.7201545, lng: 21.2188019 },
    { lat: 48.7200225, lng: 21.2187892 },
    { lat: 48.7198598, lng: 21.218773 },
    { lat: 48.7194175, lng: 21.2187325 },
    { lat: 48.719289, lng: 21.2187638 },
    { lat: 48.7190759, lng: 21.2188232 },
    { lat: 48.7189599, lng: 21.2188543 },
    { lat: 48.7188628, lng: 21.2188826 },
    { lat: 48.7187703, lng: 21.218912 },
    { lat: 48.7186488, lng: 21.2189478 },
    { lat: 48.7185294, lng: 21.2189819 },
    { lat: 48.7183946, lng: 21.2190236 },
    { lat: 48.7182185, lng: 21.2190782 },
    { lat: 48.7179317, lng: 21.2191582 },
    { lat: 48.7176184, lng: 21.2192529 },
    { lat: 48.7170526, lng: 21.2195498 },
    { lat: 48.7170918, lng: 21.2197976 },
    { lat: 48.717103, lng: 21.2198666 },
    { lat: 48.7168105, lng: 21.2199078 },
    { lat: 48.7165046, lng: 21.2199471 },
    { lat: 48.7163712, lng: 21.2199641 },
    { lat: 48.7162944, lng: 21.2200665 },
    { lat: 48.716191, lng: 21.2201747 },
    { lat: 48.7161635, lng: 21.2201984 },
    { lat: 48.7160803, lng: 21.2202699 },
    { lat: 48.7160778, lng: 21.2201924 },
    { lat: 48.7160771, lng: 21.2201675 },
    { lat: 48.7157608, lng: 21.2202054 },
    { lat: 48.714992, lng: 21.2203436 },
    { lat: 48.7143985, lng: 21.2204376 },
    { lat: 48.7138894, lng: 21.2205337 },
    { lat: 48.7134385, lng: 21.2206165 },
    { lat: 48.7132873, lng: 21.2206444 },
    { lat: 48.713162, lng: 21.2206668 },
    { lat: 48.7131552, lng: 21.2205925 },
    { lat: 48.7131173, lng: 21.220495 },
    { lat: 48.7131027, lng: 21.220458 },
    { lat: 48.7130842, lng: 21.2204108 },
    { lat: 48.7129797, lng: 21.2202711 },
    { lat: 48.7129053, lng: 21.2202671 },
    { lat: 48.7127355, lng: 21.2201983 },
    { lat: 48.7126468, lng: 21.2201676 },
    { lat: 48.712243, lng: 21.2200674 },
    { lat: 48.7122316, lng: 21.2200646 },
    { lat: 48.7121881, lng: 21.220077 },
    { lat: 48.7120889, lng: 21.2201069 },
    { lat: 48.7117934, lng: 21.2201953 },
    { lat: 48.711517, lng: 21.2201348 },
    { lat: 48.7114287, lng: 21.2201211 },
    { lat: 48.7113313, lng: 21.2201003 },
    { lat: 48.7108272, lng: 21.2199944 },
    { lat: 48.7106845, lng: 21.2199647 },
    { lat: 48.7106402, lng: 21.2199543 },
    { lat: 48.7102176, lng: 21.2196441 },
    { lat: 48.7100601, lng: 21.2196111 },
    { lat: 48.7100055, lng: 21.2195933 },
    { lat: 48.7099531, lng: 21.2195714 },
    { lat: 48.7097169, lng: 21.2194688 },
    { lat: 48.7095819, lng: 21.219399 },
    { lat: 48.7094879, lng: 21.2193441 },
    { lat: 48.7092701, lng: 21.2195794 },
    { lat: 48.7092316, lng: 21.219533 },
    { lat: 48.7091603, lng: 21.2194832 },
    { lat: 48.7089138, lng: 21.2193052 },
    { lat: 48.708898, lng: 21.2193002 },
    { lat: 48.7087856, lng: 21.2192691 },
    { lat: 48.7086772, lng: 21.2192608 },
    { lat: 48.7084911, lng: 21.2192459 },
    { lat: 48.7084366, lng: 21.2192672 },
    { lat: 48.7083732, lng: 21.219292 },
    { lat: 48.708254, lng: 21.2193301 },
    { lat: 48.7081053, lng: 21.2193635 },
    { lat: 48.7078323, lng: 21.2194119 },
    { lat: 48.7057716, lng: 21.2195531 },
    { lat: 48.7057009, lng: 21.2195581 },
    { lat: 48.7056614, lng: 21.2195599 },
    { lat: 48.7056711, lng: 21.2196906 },
    { lat: 48.705624, lng: 21.2199106 },
    { lat: 48.7055898, lng: 21.2200763 },
    { lat: 48.7055564, lng: 21.2202343 },
    { lat: 48.7055421, lng: 21.2202429 },
    { lat: 48.7054304, lng: 21.2203078 },
    { lat: 48.7052769, lng: 21.2203975 },
    { lat: 48.7050463, lng: 21.2205828 },
    { lat: 48.7049563, lng: 21.2206555 },
    { lat: 48.7049256, lng: 21.2206802 },
    { lat: 48.7048946, lng: 21.2207009 },
    { lat: 48.7048693, lng: 21.2206464 },
    { lat: 48.7048584, lng: 21.2206215 },
    { lat: 48.7048373, lng: 21.2205959 },
    { lat: 48.7048058, lng: 21.2205576 },
    { lat: 48.7047156, lng: 21.2205177 },
    { lat: 48.7046072, lng: 21.2204673 },
    { lat: 48.7044395, lng: 21.2204326 },
    { lat: 48.7041284, lng: 21.2203457 },
    { lat: 48.7031912, lng: 21.2200655 },
    { lat: 48.7031277, lng: 21.2200487 },
    { lat: 48.7027743, lng: 21.2198722 },
    { lat: 48.7025107, lng: 21.219824 },
    { lat: 48.7021889, lng: 21.219936 },
    { lat: 48.7020055, lng: 21.220083 },
    { lat: 48.7018523, lng: 21.2202928 },
    { lat: 48.7015973, lng: 21.2205967 },
    { lat: 48.7015579, lng: 21.2206315 },
    { lat: 48.701524, lng: 21.2206618 },
    { lat: 48.7015206, lng: 21.2206627 },
    { lat: 48.7014739, lng: 21.2206817 },
    { lat: 48.7014394, lng: 21.2206956 },
    { lat: 48.7014195, lng: 21.2207034 },
    { lat: 48.7013901, lng: 21.2207012 },
    { lat: 48.7013359, lng: 21.2206972 },
    { lat: 48.7013032, lng: 21.2206946 },
    { lat: 48.7012728, lng: 21.2206925 },
    { lat: 48.7012537, lng: 21.2206906 },
    { lat: 48.7012143, lng: 21.2206917 },
    { lat: 48.7011906, lng: 21.2206926 },
    { lat: 48.7011794, lng: 21.2206927 },
    { lat: 48.7011547, lng: 21.2206938 },
    { lat: 48.7011361, lng: 21.2207122 },
    { lat: 48.7011013, lng: 21.2207499 },
    { lat: 48.7010708, lng: 21.2207817 },
    { lat: 48.7010559, lng: 21.2208102 },
    { lat: 48.7009103, lng: 21.2210849 },
    { lat: 48.7008943, lng: 21.2211135 },
    { lat: 48.7008742, lng: 21.2211534 },
    { lat: 48.7008688, lng: 21.2211623 },
    { lat: 48.7008519, lng: 21.2211949 },
    { lat: 48.7008359, lng: 21.221226 },
    { lat: 48.7007763, lng: 21.2213367 },
    { lat: 48.7007498, lng: 21.2213903 },
    { lat: 48.7007371, lng: 21.2214143 },
    { lat: 48.7007308, lng: 21.2214265 },
    { lat: 48.700699, lng: 21.2214538 },
    { lat: 48.7006518, lng: 21.2214935 },
    { lat: 48.7006387, lng: 21.2215044 },
    { lat: 48.7005947, lng: 21.2215392 },
    { lat: 48.700565, lng: 21.2215631 },
    { lat: 48.700506, lng: 21.2215794 },
    { lat: 48.7004389, lng: 21.2215938 },
    { lat: 48.7004064, lng: 21.2216007 },
    { lat: 48.7003528, lng: 21.2216124 },
    { lat: 48.7001705, lng: 21.2216517 },
    { lat: 48.7001135, lng: 21.2216643 },
    { lat: 48.7000599, lng: 21.221676 },
    { lat: 48.7000476, lng: 21.2216783 },
    { lat: 48.700003, lng: 21.2216914 },
    { lat: 48.7000225, lng: 21.2217407 },
    { lat: 48.7000384, lng: 21.221814 },
    { lat: 48.7000623, lng: 21.2218967 },
    { lat: 48.7001003, lng: 21.2220337 },
    { lat: 48.7001413, lng: 21.2221932 },
    { lat: 48.7001883, lng: 21.2223644 },
    { lat: 48.7002049, lng: 21.2225339 },
    { lat: 48.7001041, lng: 21.2226234 },
    { lat: 48.7000502, lng: 21.2227726 },
    { lat: 48.6999995, lng: 21.2229144 },
    { lat: 48.6999123, lng: 21.2232922 },
    { lat: 48.6998082, lng: 21.2237464 },
    { lat: 48.6997529, lng: 21.2239199 },
    { lat: 48.6997282, lng: 21.2239947 },
    { lat: 48.6996717, lng: 21.2242766 },
    { lat: 48.6993517, lng: 21.2248751 },
    { lat: 48.6990228, lng: 21.2254814 },
    { lat: 48.6989348, lng: 21.2255828 },
    { lat: 48.6987846, lng: 21.226105 },
    { lat: 48.6987314, lng: 21.2264241 },
    { lat: 48.6985087, lng: 21.2268219 },
    { lat: 48.6979766, lng: 21.2272605 },
    { lat: 48.6977485, lng: 21.2273473 },
    { lat: 48.6976739, lng: 21.227479 },
    { lat: 48.6974797, lng: 21.2280037 },
    { lat: 48.6966427, lng: 21.2292039 },
    { lat: 48.6963193, lng: 21.2293359 },
    { lat: 48.6961051, lng: 21.2294709 },
    { lat: 48.6959299, lng: 21.2297348 },
    { lat: 48.6955982, lng: 21.2303936 },
    { lat: 48.6952323, lng: 21.2307484 },
    { lat: 48.6949896, lng: 21.2314492 },
    { lat: 48.6948087, lng: 21.2319294 },
    { lat: 48.6945915, lng: 21.2323285 },
    { lat: 48.6944887, lng: 21.2326764 },
    { lat: 48.6945013, lng: 21.2326826 },
    { lat: 48.6944671, lng: 21.2327392 },
    { lat: 48.694392, lng: 21.2328956 },
    { lat: 48.6943866, lng: 21.2329045 },
    { lat: 48.6943328, lng: 21.2330159 },
    { lat: 48.6943222, lng: 21.2330398 },
    { lat: 48.6943021, lng: 21.2330811 },
    { lat: 48.6942905, lng: 21.2331037 },
    { lat: 48.6942261, lng: 21.2331819 },
    { lat: 48.6940692, lng: 21.2332517 },
    { lat: 48.6918665, lng: 21.2310757 },
    { lat: 48.696296, lng: 21.23559 },
    { lat: 48.696577, lng: 21.235885 },
    { lat: 48.696655, lng: 21.235969 },
    { lat: 48.696726, lng: 21.236034 },
    { lat: 48.696868, lng: 21.23616 },
    { lat: 48.697021, lng: 21.236317 },
    { lat: 48.697422, lng: 21.236696 },
    { lat: 48.697672, lng: 21.236933 },
    { lat: 48.6979, lng: 21.237117 },
    { lat: 48.697994, lng: 21.237194 },
    { lat: 48.698013, lng: 21.237211 },
    { lat: 48.698032, lng: 21.237229 },
    { lat: 48.698064, lng: 21.237257 },
    { lat: 48.698107, lng: 21.237294 },
    { lat: 48.698635, lng: 21.237769 },
    { lat: 48.698722, lng: 21.237849 },
    { lat: 48.698768, lng: 21.237888 },
    { lat: 48.699726, lng: 21.238708 },
    { lat: 48.69978, lng: 21.238754 },
    { lat: 48.699902, lng: 21.238856 },
    { lat: 48.699996, lng: 21.238937 },
    { lat: 48.700031, lng: 21.238967 },
    { lat: 48.700075, lng: 21.239005 },
    { lat: 48.700283, lng: 21.239183 },
    { lat: 48.700666, lng: 21.239516 },
    { lat: 48.701269, lng: 21.240068 },
    { lat: 48.701363, lng: 21.240155 },
    { lat: 48.701953, lng: 21.240695 },
    { lat: 48.702028, lng: 21.240764 },
    { lat: 48.70208, lng: 21.240813 },
    { lat: 48.70211, lng: 21.240839 },
    { lat: 48.702186, lng: 21.240911 },
    { lat: 48.703009, lng: 21.241661 },
    { lat: 48.703359, lng: 21.241985 },
    { lat: 48.703693, lng: 21.242266 },
    { lat: 48.703807, lng: 21.242368 },
    { lat: 48.703947, lng: 21.242493 },
    { lat: 48.703984, lng: 21.242534 },
    { lat: 48.70401, lng: 21.242572 },
    { lat: 48.704225, lng: 21.242768 },
    { lat: 48.704298, lng: 21.242833 },
    { lat: 48.704306, lng: 21.242813 },
    { lat: 48.70432, lng: 21.242779 },
    { lat: 48.704478, lng: 21.242935 },
    { lat: 48.704598, lng: 21.243041 },
    { lat: 48.704626, lng: 21.243066 },
    { lat: 48.704704, lng: 21.243136 },
    { lat: 48.704902, lng: 21.243319 },
    { lat: 48.704964, lng: 21.243378 },
    { lat: 48.705183, lng: 21.243574 },
    { lat: 48.705249, lng: 21.243633 },
    { lat: 48.705235, lng: 21.243667 },
    { lat: 48.705265, lng: 21.243693 },
    { lat: 48.705896, lng: 21.24424 },
    { lat: 48.706161, lng: 21.244458 },
    { lat: 48.70647, lng: 21.244709 },
    { lat: 48.706797, lng: 21.244948 },
    { lat: 48.706829, lng: 21.244966 },
    { lat: 48.706889, lng: 21.245007 },
    { lat: 48.7069687, lng: 21.2450638 },
    { lat: 48.706976, lng: 21.245069 },
    { lat: 48.707041, lng: 21.245104 },
    { lat: 48.7070469, lng: 21.2451078 },
    { lat: 48.7074217, lng: 21.2453487 },
    { lat: 48.707489, lng: 21.245392 },
    { lat: 48.70762, lng: 21.245477 },
    { lat: 48.7076295, lng: 21.2454833 },
    { lat: 48.7078384, lng: 21.2456211 },
    { lat: 48.708064, lng: 21.24577 },
    { lat: 48.708135, lng: 21.245819 },
    { lat: 48.708234, lng: 21.245887 },
    { lat: 48.708833, lng: 21.246281 },
    { lat: 48.70892, lng: 21.246335 },
    { lat: 48.708974, lng: 21.246369 },
    { lat: 48.709288, lng: 21.246564 },
    { lat: 48.709524, lng: 21.246708 },
    { lat: 48.709602, lng: 21.246754 },
    { lat: 48.709752, lng: 21.246837 },
    { lat: 48.709874, lng: 21.246914 },
    { lat: 48.709954, lng: 21.246959 },
    { lat: 48.710033, lng: 21.246986 },
    { lat: 48.710115, lng: 21.247004 },
    { lat: 48.71029, lng: 21.247021 },
    { lat: 48.710364, lng: 21.247043 },
    { lat: 48.710389, lng: 21.247056 },
    { lat: 48.710464, lng: 21.247025 },
    { lat: 48.710537, lng: 21.246994 },
    { lat: 48.710533, lng: 21.247076 },
    { lat: 48.710551, lng: 21.247068 },
    { lat: 48.710594, lng: 21.247048 },
    { lat: 48.711063, lng: 21.246737 },
    { lat: 48.711167, lng: 21.246627 },
    { lat: 48.711301, lng: 21.246512 },
    { lat: 48.711558, lng: 21.246409 },
    { lat: 48.712112, lng: 21.246389 },
    { lat: 48.712281, lng: 21.246391 },
    { lat: 48.712417, lng: 21.246406 },
    { lat: 48.712631, lng: 21.24644 },
    { lat: 48.712699, lng: 21.246445 },
    { lat: 48.713281, lng: 21.246425 },
    { lat: 48.713409, lng: 21.246485 },
    { lat: 48.71382, lng: 21.246723 },
    { lat: 48.71427, lng: 21.247099 },
    { lat: 48.714273, lng: 21.247102 },
    { lat: 48.714283, lng: 21.24711 },
    { lat: 48.714286, lng: 21.247113 },
    { lat: 48.714314, lng: 21.247135 },
    { lat: 48.714401, lng: 21.24722 },
    { lat: 48.714427, lng: 21.247242 },
    { lat: 48.714649, lng: 21.247421 },
    { lat: 48.714863, lng: 21.247556 },
    { lat: 48.715017, lng: 21.246996 },
    { lat: 48.7150726, lng: 21.2467444 },
    { lat: 48.7151307, lng: 21.2466673 },
    { lat: 48.715332, lng: 21.246516 },
    { lat: 48.715759, lng: 21.246158 },
    { lat: 48.715907, lng: 21.246097 },
    { lat: 48.715945, lng: 21.24612 },
    { lat: 48.7159905, lng: 21.2462085 },
    { lat: 48.71602, lng: 21.24648 },
    { lat: 48.716056, lng: 21.246325 },
    { lat: 48.716066, lng: 21.246295 },
    { lat: 48.716134, lng: 21.246327 },
    { lat: 48.716308, lng: 21.24643 },
    { lat: 48.716311, lng: 21.24642 },
    { lat: 48.71631, lng: 21.246401 },
    { lat: 48.716333, lng: 21.246379 },
    { lat: 48.71635, lng: 21.246303 },
    { lat: 48.716346, lng: 21.246037 },
    { lat: 48.716331, lng: 21.245635 },
    { lat: 48.716257, lng: 21.245231 },
    { lat: 48.7162789, lng: 21.2452252 },
    { lat: 48.7165799, lng: 21.245084 },
    { lat: 48.7167976, lng: 21.2455874 },
    { lat: 48.7168649, lng: 21.2457432 },
    { lat: 48.717262, lng: 21.245434 },
    { lat: 48.717247, lng: 21.245382 },
    { lat: 48.717118, lng: 21.245238 },
    { lat: 48.716977, lng: 21.245094 },
    { lat: 48.716906, lng: 21.245001 },
    { lat: 48.7167959, lng: 21.2448486 },
    { lat: 48.7167212, lng: 21.2447437 },
    { lat: 48.716609, lng: 21.244525 },
    { lat: 48.716605, lng: 21.244519 },
    { lat: 48.716225, lng: 21.243669 },
    { lat: 48.716243, lng: 21.24354 },
    { lat: 48.716319, lng: 21.2434185 },
    { lat: 48.716391, lng: 21.243518 },
    { lat: 48.716657, lng: 21.243793 },
    { lat: 48.716706, lng: 21.24368 },
    { lat: 48.71671, lng: 21.243683 },
    { lat: 48.716986, lng: 21.243079 },
    { lat: 48.717142, lng: 21.242858 },
    { lat: 48.717171, lng: 21.242914 },
    { lat: 48.717211, lng: 21.242888 },
    { lat: 48.717363, lng: 21.2428585 },
    { lat: 48.717518, lng: 21.243195 },
    { lat: 48.717675, lng: 21.243743 },
    { lat: 48.717695, lng: 21.243939 },
    { lat: 48.717864, lng: 21.244739 },
    { lat: 48.718222, lng: 21.244421 },
    { lat: 48.718375, lng: 21.244793 },
    { lat: 48.718756, lng: 21.244408 },
    { lat: 48.7188712, lng: 21.2442531 },
    { lat: 48.7191452, lng: 21.2440985 },
    { lat: 48.7192105, lng: 21.244146 },
    { lat: 48.719363, lng: 21.244419 },
    { lat: 48.719411, lng: 21.244435 },
    { lat: 48.719421, lng: 21.244313 },
    { lat: 48.719543, lng: 21.244346 },
    { lat: 48.71955, lng: 21.244286 },
    { lat: 48.719586, lng: 21.24412 },
    { lat: 48.7195654, lng: 21.2439429 },
    { lat: 48.719649, lng: 21.243921 },
    { lat: 48.720447, lng: 21.244026 },
    { lat: 48.720485, lng: 21.243388 },
    { lat: 48.720701, lng: 21.243295 },
    { lat: 48.721135, lng: 21.243121 },
    { lat: 48.722278, lng: 21.242681 },
    { lat: 48.722549, lng: 21.242615 },
    { lat: 48.722853, lng: 21.242464 },
    { lat: 48.7230745, lng: 21.2423146 },
    { lat: 48.7232874, lng: 21.2421352 },
    { lat: 48.7232942, lng: 21.2421295 },
    { lat: 48.723301, lng: 21.2421237 },
    { lat: 48.723652, lng: 21.241828 },
    { lat: 48.7241187, lng: 21.2412768 },
    { lat: 48.724138, lng: 21.241254 },
    { lat: 48.7241702, lng: 21.2412372 },
    { lat: 48.7243674, lng: 21.2411344 },
    { lat: 48.7245876, lng: 21.2410197 },
    { lat: 48.724658, lng: 21.240983 },
    { lat: 48.725248, lng: 21.240528 },
    { lat: 48.726059, lng: 21.2398845 },
    { lat: 48.7261664, lng: 21.2391815 },
    { lat: 48.726345, lng: 21.238916 },
  ],
  KošicemestskáčasťSídliskoKVP: [
    { lat: 48.721837, lng: 21.217021 },
    { lat: 48.7218, lng: 21.216232 },
    { lat: 48.722007, lng: 21.215478 },
    { lat: 48.722084, lng: 21.214718 },
    { lat: 48.72246, lng: 21.213429 },
    { lat: 48.722848, lng: 21.21258 },
    { lat: 48.723281, lng: 21.211959 },
    { lat: 48.723186, lng: 21.211848 },
    { lat: 48.723513, lng: 21.211447 },
    { lat: 48.724179, lng: 21.210351 },
    { lat: 48.724576, lng: 21.209788 },
    { lat: 48.724738, lng: 21.209298 },
    { lat: 48.724857, lng: 21.208546 },
    { lat: 48.724908, lng: 21.207831 },
    { lat: 48.724916, lng: 21.20717 },
    { lat: 48.724977, lng: 21.20636 },
    { lat: 48.725007, lng: 21.205459 },
    { lat: 48.725077, lng: 21.204659 },
    { lat: 48.725168, lng: 21.204229 },
    { lat: 48.725259, lng: 21.203755 },
    { lat: 48.725366, lng: 21.202967 },
    { lat: 48.725579, lng: 21.201932 },
    { lat: 48.7255723, lng: 21.2018668 },
    { lat: 48.7254403, lng: 21.2020024 },
    { lat: 48.7245463, lng: 21.2025609 },
    { lat: 48.7241852, lng: 21.2027101 },
    { lat: 48.723943, lng: 21.2028083 },
    { lat: 48.7237061, lng: 21.2028659 },
    { lat: 48.7235045, lng: 21.2029681 },
    { lat: 48.7234118, lng: 21.2030271 },
    { lat: 48.7233293, lng: 21.2030868 },
    { lat: 48.7232518, lng: 21.2031972 },
    { lat: 48.7231289, lng: 21.2032623 },
    { lat: 48.7229537, lng: 21.2034189 },
    { lat: 48.7227731, lng: 21.2035444 },
    { lat: 48.7225853, lng: 21.2036577 },
    { lat: 48.7224816, lng: 21.2036901 },
    { lat: 48.722252, lng: 21.2037931 },
    { lat: 48.7218546, lng: 21.2039691 },
    { lat: 48.7216646, lng: 21.2040865 },
    { lat: 48.7214926, lng: 21.2042342 },
    { lat: 48.7213483, lng: 21.2044417 },
    { lat: 48.7212812, lng: 21.2045994 },
    { lat: 48.7212636, lng: 21.2047186 },
    { lat: 48.7212613, lng: 21.2047544 },
    { lat: 48.721263, lng: 21.2048808 },
    { lat: 48.7210999, lng: 21.2048829 },
    { lat: 48.7207667, lng: 21.2048796 },
    { lat: 48.7205782, lng: 21.2047898 },
    { lat: 48.7203911, lng: 21.2048178 },
    { lat: 48.7200297, lng: 21.2050301 },
    { lat: 48.7197334, lng: 21.2051621 },
    { lat: 48.719709, lng: 21.2051731 },
    { lat: 48.7196391, lng: 21.2052045 },
    { lat: 48.7195147, lng: 21.2052566 },
    { lat: 48.7194856, lng: 21.2052647 },
    { lat: 48.7193016, lng: 21.2053153 },
    { lat: 48.7190762, lng: 21.2053781 },
    { lat: 48.7188887, lng: 21.2054293 },
    { lat: 48.7188653, lng: 21.205436 },
    { lat: 48.7186667, lng: 21.2054905 },
    { lat: 48.7184925, lng: 21.2055354 },
    { lat: 48.7183274, lng: 21.2055786 },
    { lat: 48.7183229, lng: 21.2055796 },
    { lat: 48.7182268, lng: 21.2056029 },
    { lat: 48.7181576, lng: 21.2056221 },
    { lat: 48.7176188, lng: 21.2057747 },
    { lat: 48.7174359, lng: 21.2058263 },
    { lat: 48.7172663, lng: 21.2058741 },
    { lat: 48.7170967, lng: 21.2059222 },
    { lat: 48.717012, lng: 21.2059461 },
    { lat: 48.7168112, lng: 21.2060029 },
    { lat: 48.7167029, lng: 21.2060334 },
    { lat: 48.7165579, lng: 21.2060744 },
    { lat: 48.7165468, lng: 21.2060774 },
    { lat: 48.7164921, lng: 21.2060931 },
    { lat: 48.7163793, lng: 21.2060822 },
    { lat: 48.716184, lng: 21.2060514 },
    { lat: 48.7161324, lng: 21.2060499 },
    { lat: 48.7159221, lng: 21.2061145 },
    { lat: 48.7153001, lng: 21.2063384 },
    { lat: 48.7142786, lng: 21.2066745 },
    { lat: 48.7142713, lng: 21.2066194 },
    { lat: 48.7141863, lng: 21.2066351 },
    { lat: 48.7141831, lng: 21.2065723 },
    { lat: 48.7138242, lng: 21.2066108 },
    { lat: 48.7138096, lng: 21.2065748 },
    { lat: 48.7133618, lng: 21.2054301 },
    { lat: 48.7133607, lng: 21.2054305 },
    { lat: 48.7132498, lng: 21.2054459 },
    { lat: 48.7130314, lng: 21.2053718 },
    { lat: 48.7128345, lng: 21.2054104 },
    { lat: 48.7125189, lng: 21.2052495 },
    { lat: 48.7124757, lng: 21.2052369 },
    { lat: 48.7122746, lng: 21.2051776 },
    { lat: 48.7120036, lng: 21.2050769 },
    { lat: 48.711955, lng: 21.205025 },
    { lat: 48.7119526, lng: 21.2050782 },
    { lat: 48.7120116, lng: 21.2053307 },
    { lat: 48.7121494, lng: 21.2056712 },
    { lat: 48.7122877, lng: 21.2060258 },
    { lat: 48.7125562, lng: 21.2067159 },
    { lat: 48.7124457, lng: 21.2067693 },
    { lat: 48.7123849, lng: 21.2067903 },
    { lat: 48.7123203, lng: 21.2068128 },
    { lat: 48.7122669, lng: 21.2068345 },
    { lat: 48.7119958, lng: 21.2069462 },
    { lat: 48.7114925, lng: 21.2071539 },
    { lat: 48.7114836, lng: 21.2071574 },
    { lat: 48.7113758, lng: 21.2072018 },
    { lat: 48.7113235, lng: 21.2072237 },
    { lat: 48.7112847, lng: 21.2072401 },
    { lat: 48.7112736, lng: 21.2072444 },
    { lat: 48.7111992, lng: 21.2072747 },
    { lat: 48.711167, lng: 21.2072884 },
    { lat: 48.7111091, lng: 21.2073118 },
    { lat: 48.7110981, lng: 21.2073165 },
    { lat: 48.711058, lng: 21.2073329 },
    { lat: 48.7110103, lng: 21.2073531 },
    { lat: 48.710948, lng: 21.2073789 },
    { lat: 48.7109687, lng: 21.2075353 },
    { lat: 48.710972, lng: 21.20757 },
    { lat: 48.7109752, lng: 21.2075993 },
    { lat: 48.7109737, lng: 21.2076226 },
    { lat: 48.7109707, lng: 21.2076374 },
    { lat: 48.7109678, lng: 21.2076483 },
    { lat: 48.7109595, lng: 21.2076703 },
    { lat: 48.710951, lng: 21.2076873 },
    { lat: 48.7109359, lng: 21.2077076 },
    { lat: 48.7109348, lng: 21.2077095 },
    { lat: 48.710886, lng: 21.2077657 },
    { lat: 48.7108948, lng: 21.2079771 },
    { lat: 48.7109809, lng: 21.208209 },
    { lat: 48.7110128, lng: 21.2082965 },
    { lat: 48.7110975, lng: 21.2085235 },
    { lat: 48.7112206, lng: 21.2088578 },
    { lat: 48.7110904, lng: 21.2089755 },
    { lat: 48.7110803, lng: 21.2089773 },
    { lat: 48.7110388, lng: 21.2089835 },
    { lat: 48.7110356, lng: 21.208984 },
    { lat: 48.7109784, lng: 21.2089931 },
    { lat: 48.7109201, lng: 21.2090023 },
    { lat: 48.7108977, lng: 21.2089319 },
    { lat: 48.7108789, lng: 21.2088684 },
    { lat: 48.7108636, lng: 21.2088139 },
    { lat: 48.7108447, lng: 21.2087475 },
    { lat: 48.7108241, lng: 21.2086667 },
    { lat: 48.7108019, lng: 21.2085689 },
    { lat: 48.71079, lng: 21.208512 },
    { lat: 48.7107795, lng: 21.2084301 },
    { lat: 48.7107779, lng: 21.2084124 },
    { lat: 48.7107761, lng: 21.2083932 },
    { lat: 48.7107731, lng: 21.2083343 },
    { lat: 48.7107676, lng: 21.2083026 },
    { lat: 48.7106015, lng: 21.2081705 },
    { lat: 48.7105931, lng: 21.208189 },
    { lat: 48.7105835, lng: 21.2082057 },
    { lat: 48.7105695, lng: 21.2082266 },
    { lat: 48.7105328, lng: 21.2082759 },
    { lat: 48.710495, lng: 21.2083282 },
    { lat: 48.7103698, lng: 21.2084982 },
    { lat: 48.7103219, lng: 21.208549 },
    { lat: 48.7103588, lng: 21.2086155 },
    { lat: 48.7103209, lng: 21.2086606 },
    { lat: 48.7102076, lng: 21.2088159 },
    { lat: 48.7102011, lng: 21.2088263 },
    { lat: 48.710142, lng: 21.2089161 },
    { lat: 48.7100926, lng: 21.2089909 },
    { lat: 48.7100561, lng: 21.2090473 },
    { lat: 48.7099702, lng: 21.2091814 },
    { lat: 48.7098596, lng: 21.2093511 },
    { lat: 48.7097835, lng: 21.2094705 },
    { lat: 48.709704, lng: 21.2095931 },
    { lat: 48.7095989, lng: 21.2097556 },
    { lat: 48.7094667, lng: 21.2099575 },
    { lat: 48.7093681, lng: 21.2101138 },
    { lat: 48.7092394, lng: 21.2103162 },
    { lat: 48.7091514, lng: 21.210455 },
    { lat: 48.7090625, lng: 21.2105968 },
    { lat: 48.7089327, lng: 21.210801 },
    { lat: 48.7088211, lng: 21.2109768 },
    { lat: 48.708714, lng: 21.2111476 },
    { lat: 48.7086153, lng: 21.2113019 },
    { lat: 48.7085294, lng: 21.2114366 },
    { lat: 48.708419, lng: 21.211612 },
    { lat: 48.7083149, lng: 21.2117752 },
    { lat: 48.7081369, lng: 21.2120569 },
    { lat: 48.7080746, lng: 21.2121526 },
    { lat: 48.7080208, lng: 21.2122312 },
    { lat: 48.7079627, lng: 21.2123159 },
    { lat: 48.7078971, lng: 21.2124133 },
    { lat: 48.7078507, lng: 21.2124786 },
    { lat: 48.7077722, lng: 21.2125911 },
    { lat: 48.7076306, lng: 21.2127818 },
    { lat: 48.7076091, lng: 21.2128108 },
    { lat: 48.7075022, lng: 21.2129578 },
    { lat: 48.7073901, lng: 21.2131129 },
    { lat: 48.7072844, lng: 21.2132587 },
    { lat: 48.7071764, lng: 21.2134054 },
    { lat: 48.7070717, lng: 21.2135501 },
    { lat: 48.7070522, lng: 21.2135761 },
    { lat: 48.7070101, lng: 21.2136332 },
    { lat: 48.7069581, lng: 21.2136954 },
    { lat: 48.7069005, lng: 21.2137629 },
    { lat: 48.706844, lng: 21.2138247 },
    { lat: 48.7067937, lng: 21.213871 },
    { lat: 48.706708, lng: 21.2140133 },
    { lat: 48.7066825, lng: 21.2140935 },
    { lat: 48.7066333, lng: 21.2142481 },
    { lat: 48.7066139, lng: 21.2143107 },
    { lat: 48.7066066, lng: 21.2143323 },
    { lat: 48.7064949, lng: 21.2146841 },
    { lat: 48.7063813, lng: 21.2150435 },
    { lat: 48.7062096, lng: 21.2156344 },
    { lat: 48.7062748, lng: 21.2157773 },
    { lat: 48.7063457, lng: 21.2160292 },
    { lat: 48.7063536, lng: 21.2161362 },
    { lat: 48.7063407, lng: 21.2163028 },
    { lat: 48.7063735, lng: 21.2163454 },
    { lat: 48.7062955, lng: 21.2164397 },
    { lat: 48.7062191, lng: 21.2165509 },
    { lat: 48.7061369, lng: 21.2166594 },
    { lat: 48.7059702, lng: 21.2168723 },
    { lat: 48.7058052, lng: 21.2170636 },
    { lat: 48.7057051, lng: 21.2171719 },
    { lat: 48.7056975, lng: 21.2171807 },
    { lat: 48.7055413, lng: 21.2172634 },
    { lat: 48.7054982, lng: 21.2172865 },
    { lat: 48.7054709, lng: 21.2174224 },
    { lat: 48.7054484, lng: 21.2175309 },
    { lat: 48.705419, lng: 21.2176698 },
    { lat: 48.7053358, lng: 21.2180716 },
    { lat: 48.7053271, lng: 21.2181168 },
    { lat: 48.7052615, lng: 21.218358 },
    { lat: 48.7052417, lng: 21.2184439 },
    { lat: 48.7052188, lng: 21.2185392 },
    { lat: 48.705198, lng: 21.2185592 },
    { lat: 48.7055578, lng: 21.2190502 },
    { lat: 48.7055719, lng: 21.2190681 },
    { lat: 48.7055831, lng: 21.2192123 },
    { lat: 48.7056109, lng: 21.2194526 },
    { lat: 48.7056411, lng: 21.2194512 },
    { lat: 48.705664, lng: 21.2194631 },
    { lat: 48.7056733, lng: 21.2194809 },
    { lat: 48.7056904, lng: 21.2195136 },
    { lat: 48.7057009, lng: 21.2195581 },
    { lat: 48.7057716, lng: 21.2195531 },
    { lat: 48.7078323, lng: 21.2194119 },
    { lat: 48.7081053, lng: 21.2193635 },
    { lat: 48.708254, lng: 21.2193301 },
    { lat: 48.7083732, lng: 21.219292 },
    { lat: 48.7084366, lng: 21.2192672 },
    { lat: 48.7084911, lng: 21.2192459 },
    { lat: 48.7086772, lng: 21.2192608 },
    { lat: 48.7087856, lng: 21.2192691 },
    { lat: 48.708898, lng: 21.2193002 },
    { lat: 48.7089138, lng: 21.2193052 },
    { lat: 48.7091603, lng: 21.2194832 },
    { lat: 48.7092316, lng: 21.219533 },
    { lat: 48.7092701, lng: 21.2195794 },
    { lat: 48.7094879, lng: 21.2193441 },
    { lat: 48.7095819, lng: 21.219399 },
    { lat: 48.7097169, lng: 21.2194688 },
    { lat: 48.7099531, lng: 21.2195714 },
    { lat: 48.7100055, lng: 21.2195933 },
    { lat: 48.7100601, lng: 21.2196111 },
    { lat: 48.7102176, lng: 21.2196441 },
    { lat: 48.7106402, lng: 21.2199543 },
    { lat: 48.7106845, lng: 21.2199647 },
    { lat: 48.7108272, lng: 21.2199944 },
    { lat: 48.7113313, lng: 21.2201003 },
    { lat: 48.7114287, lng: 21.2201211 },
    { lat: 48.711517, lng: 21.2201348 },
    { lat: 48.7117934, lng: 21.2201953 },
    { lat: 48.7120889, lng: 21.2201069 },
    { lat: 48.7121881, lng: 21.220077 },
    { lat: 48.7122316, lng: 21.2200646 },
    { lat: 48.712243, lng: 21.2200674 },
    { lat: 48.7126468, lng: 21.2201676 },
    { lat: 48.7127355, lng: 21.2201983 },
    { lat: 48.7129053, lng: 21.2202671 },
    { lat: 48.7129797, lng: 21.2202711 },
    { lat: 48.7130842, lng: 21.2204108 },
    { lat: 48.7131027, lng: 21.220458 },
    { lat: 48.7131173, lng: 21.220495 },
    { lat: 48.7131552, lng: 21.2205925 },
    { lat: 48.713162, lng: 21.2206668 },
    { lat: 48.7132873, lng: 21.2206444 },
    { lat: 48.7134385, lng: 21.2206165 },
    { lat: 48.7138894, lng: 21.2205337 },
    { lat: 48.7143985, lng: 21.2204376 },
    { lat: 48.714992, lng: 21.2203436 },
    { lat: 48.7157608, lng: 21.2202054 },
    { lat: 48.7160771, lng: 21.2201675 },
    { lat: 48.7160778, lng: 21.2201924 },
    { lat: 48.7160803, lng: 21.2202699 },
    { lat: 48.7161635, lng: 21.2201984 },
    { lat: 48.716191, lng: 21.2201747 },
    { lat: 48.7162944, lng: 21.2200665 },
    { lat: 48.7163712, lng: 21.2199641 },
    { lat: 48.7165046, lng: 21.2199471 },
    { lat: 48.7168105, lng: 21.2199078 },
    { lat: 48.717103, lng: 21.2198666 },
    { lat: 48.7170918, lng: 21.2197976 },
    { lat: 48.7170526, lng: 21.2195498 },
    { lat: 48.7176184, lng: 21.2192529 },
    { lat: 48.7179317, lng: 21.2191582 },
    { lat: 48.7182185, lng: 21.2190782 },
    { lat: 48.7183946, lng: 21.2190236 },
    { lat: 48.7185294, lng: 21.2189819 },
    { lat: 48.7186488, lng: 21.2189478 },
    { lat: 48.7187703, lng: 21.218912 },
    { lat: 48.7188628, lng: 21.2188826 },
    { lat: 48.7189599, lng: 21.2188543 },
    { lat: 48.7190759, lng: 21.2188232 },
    { lat: 48.719289, lng: 21.2187638 },
    { lat: 48.7194175, lng: 21.2187325 },
    { lat: 48.7198598, lng: 21.218773 },
    { lat: 48.7200225, lng: 21.2187892 },
    { lat: 48.7201545, lng: 21.2188019 },
    { lat: 48.7202707, lng: 21.2188128 },
    { lat: 48.7204287, lng: 21.2188272 },
    { lat: 48.7207333, lng: 21.2188525 },
    { lat: 48.7211453, lng: 21.2188891 },
    { lat: 48.7215583, lng: 21.2189263 },
    { lat: 48.7216532, lng: 21.2189348 },
    { lat: 48.7217209, lng: 21.2189411 },
    { lat: 48.72181, lng: 21.219381 },
    { lat: 48.721849, lng: 21.2193682 },
    { lat: 48.7223114, lng: 21.219219 },
    { lat: 48.7223415, lng: 21.219209 },
    { lat: 48.722357, lng: 21.2192036 },
    { lat: 48.7224218, lng: 21.2191858 },
    { lat: 48.722359, lng: 21.2188321 },
    { lat: 48.7222948, lng: 21.2186143 },
    { lat: 48.7220816, lng: 21.2181308 },
    { lat: 48.721944, lng: 21.2178687 },
    { lat: 48.7219122, lng: 21.2178236 },
    { lat: 48.7218585, lng: 21.2177237 },
    { lat: 48.7217815, lng: 21.2175663 },
    { lat: 48.7217809, lng: 21.2175474 },
    { lat: 48.7217765, lng: 21.2172947 },
    { lat: 48.721782, lng: 21.2170412 },
    { lat: 48.721837, lng: 21.217021 },
  ],
};

export default UnitsKošiceII;
