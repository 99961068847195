import { useEffect, useState } from "react";
import validator from "validator";
import Validation from "../Validation";
import { roleService } from "../../services/roleService";
import { registrationService } from "../../services/registrationAervice";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import AkcenLogo from "../../Images/logo/akcen_logo_v3_black_trans_edit.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function Registration() {
  const [user, setUser] = useState({
    email: "",
    password: "",
    role: 1,
    chckPass: "",
    phone: "",
    ico: "",
    company: "",
  });

  const [errorMessage, setErrorMessage] = useState({ password: "", email: "", phone: "", diffPass: "" });
  const [rolesList, setRolesList] = useState();
  const [regMessage, setRegMessage] = useState("");
  const [reg, setRegistration] = useState({ person: true, broker: false, company: false });
  const navigate = useNavigate();

  useEffect(() => {
    getRole();
  }, []);

  const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const validateEmail = (e) => {
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setErrorMessage({ ...errorMessage, email: "" });
    } else {
      setErrorMessage({ ...errorMessage, email: "e-mailova andresa nie je validna" });
    }
    if (e.target?.value === "") {
      setErrorMessage({ ...errorMessage, email: "" });
    }
  };

  const validatePassword = (e) => {
    if (
      validator.isStrongPassword(e.target?.value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage({ ...errorMessage, password: "" });
    } else {
      setErrorMessage({
        ...errorMessage,
        password: "Heslo musí obsahovať min. 8 znakov, 1 malé písmeno, 1 veľké písmeno, 1 číslo a 1 symbol",
      });
    }
    if (e.target?.value === "") {
      setErrorMessage({ ...errorMessage, password: "" });
    }
  };

  const getRole = async () => {
    const response = await roleService.getAllRoles();
    const data = await response.json();
    setRolesList(data);
  };

  const sendRegistration = async () => {
    const request = JSON.parse(
      JSON.stringify({
        email: user.email,
        password: user.password,
        type: user.role,
        phone: user.phone,
        ico: user.ico,
        // typeCompany: user.typeCompany,
        company: user.company,
      }),
    );
    console.log("pass = ", user.password, " || chckpass = ", user.chckPass);
    if (user.password === user.chckPass) {
      const response = await registrationService.setRegistration(request);
      const responseMessage = await response.json();
      if (responseMessage?.message?.includes("prebehla")) {
        setRegMessage("");
        navigate("/prihlasenie");
      }
      setRegMessage(responseMessage);
      setErrorMessage({ ...errorMessage, diffPass: "" });
    } else setErrorMessage({ ...errorMessage, diffPass: "Hesla sa nezhodujú" });
  };

  return (
    <>
      <div className="w-full absolute top-0 left-0 p-2 flex justify-between items-center">
        <a href="/">
          <img src={AkcenLogo} alt="Akcen" className="w-10 h-10" />
        </a>
        <a
          href="/"
          className="bg-sky-100 rounded-full w-7 h-7 border-2 border-gray-300 flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </a>
      </div>
      <div className="w-full h-full flex flex-col justify-start items-center pt-10 sm:p-10 gap-8">
        <div className="w-full sm:w-[448px] h-auto rounded-2xl flex flex-col justify-start items-center p-8 gap-2 shadow-md">
          <div className="w-full h-14 flex flex-row select-none border-1 border-blue-300">
            <div
              onClick={() => {
                setRegistration({ ...reg, person: true, broker: false, company: false });
                setUser({ ...user, role: "1" });
              }}
              className={clsx(
                "w-1/3 h-full flex justify-center items-center cursor-pointer rounded-l-xl border-2 border-gray-100 shadow-md",
                {
                  "bg-sky-200": reg.person,
                },
              )}
            >
              Osoba
            </div>
            <div
              onClick={() => {
                setRegistration({ ...reg, person: false, broker: true, company: false });
                setUser({ ...user, role: "15" });
              }}
              className={clsx(
                "w-1/3 h-full flex justify-center items-center cursor-pointer border-y-2 border-gray-100 shadow-md",
                {
                  "bg-sky-200": reg.broker,
                },
              )}
            >
              Maklér
            </div>
            <div
              onClick={() => {
                setRegistration({ ...reg, person: false, broker: false, company: true });
                setUser({ ...user, role: "" });
              }}
              className={clsx(
                "w-1/3 h-full flex justify-center items-center cursor-pointer rounded-r-xl border-2 border-gray-100 shadow-md",
                {
                  "bg-sky-200": reg.company,
                },
              )}
            >
              Firma
            </div>
          </div>
          <h1 className="text-2xl">Registrácia</h1>
          {/* Email */}
          <div className="w-full flex flex-col">
            <div className="flex justify-between">
              <p className="w-1/2">E-mail </p>
              <p>*</p>
            </div>
            <input
              name="email"
              value={user.email}
              onChange={(event) => {
                setUser({ ...user, email: event.target.value });
                validateEmail(event);
              }}
              min={10}
              className="bg-white border-gray-300 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-500"
              type="email"
            />
            <Validation errorMessage={errorMessage.email} />
          </div>
          {/* Email */}
          {/* Role */}
          {/* <div className="w-full flex flex-col">
          <div className="flex justify-between">
            <p>Role</p>
            <p>*</p>
          </div>
          <select
            name="role"
            id="role"
            value={user.role}
            onChange={(event) => {
              setUser({ ...user, role: event.target.value });
            }}
            className="border-gray-300 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-500 select-none"
          >
            <option disabled value="">
              Role
            </option>
            {rolesList?.slice(0, 3).map(({ id, value }) => (
              <option value={id} key={id}>
                {value}
              </option>
            ))}
          </select>
          <Validation errorMessage={""} />
        </div> */}
          {/* Role */}
          {/* Heslo */}
          <div className="w-full flex flex-col">
            <div className="flex justify-between">
              <p className="w-1/2">Heslo </p>
              <p>*</p>
            </div>
            <input
              name="password"
              value={user.password}
              onChange={(event) => {
                setUser({ ...user, password: event.target.value });
                validatePassword(event);
              }}
              min={10}
              className="bg-white border-gray-300 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-500"
              type="password"
            />
            <Validation errorMessage={errorMessage.password} />
          </div>
          {/* Heslo */}
          {/* Overenie Hesla */}
          <div className="w-full flex flex-col mb-4">
            <div className="flex justify-between">
              <p className="w-1/2">Overenie hesla</p>
              <p>*</p>
            </div>
            <input
              name="chckPassword"
              value={user.chckPass}
              onChange={(e) => {
                setUser({ ...user, chckPass: e.target.value });
              }}
              className="bg-white border-gray-300 rounded-lg shadow-sm focus:border-sky-500"
              type="password"
            />
            {errorMessage.diffPass !== "" ? <Validation errorMessage={errorMessage.diffPass} /> : null}
          </div>
          {/* Overenie Hesla */}
          {/* Makler firma */}
          {reg.broker ? (
            <div className="w-full flex flex-col mb-4">
              <div className="flex justify-between">
                <p className="w-1/2">Firma</p>
                <p>*</p>
              </div>
              <select
                name="role"
                id="role"
                value={user.company}
                onChange={(event) => {
                  setUser({ ...user, company: event.target.value });
                }}
                className="border-gray-300 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-500 select-none"
              >
                <option disabled value="">
                  Zvoľ firmu
                </option>
                {rolesList?.slice(0, 3).map(({ id, value }) => (
                  <option value={id} key={id}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          {/* Makler firma */}
          {/* Firma typ */}
          {reg.company ? (
            <div className="w-full flex flex-col mb-4">
              <div className="flex justify-between">
                <p className="w-1/2">Typ firmy</p>
                <p>*</p>
              </div>
              <select
                name="role"
                id="role"
                value={user.role}
                onChange={(event) => {
                  setUser({ ...user, role: event.target.value });
                }}
                className="border-gray-300 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-500 select-none"
              >
                <option disabled value="">
                  Zvoľ typ firmy
                </option>
                {rolesList?.slice(1, 3).map(({ id, value }) => (
                  <option value={id} key={id}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          {/* Firma typ */}
          {/* Tel. cislo */}
          {reg.company || reg.broker ? (
            <div className="w-full flex flex-col mb-4">
              <div className="flex justify-between">
                <p className="w-1/2">Tel. číslo</p>
                <p>*</p>
              </div>
              <input
                name="telNum"
                value={user.phone}
                onChange={(event) => {
                  setUser({ ...user, phone: event.target.value });
                }}
                className="bg-white border-gray-300 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-500"
                type="tel"
              />
            </div>
          ) : null}
          {/* Tel. cislo */}
          {/* ICO */}
          {reg.company ? (
            <div className="w-full flex flex-col mb-4">
              <div className="flex justify-between">
                <p className="w-1/2">IČO</p>
                <p>*</p>
              </div>
              <input
                name="ico"
                value={user.ico}
                onChange={(event) => {
                  setUser({ ...user, ico: event.target.value });
                }}
                maxLength={8}
                className="bg-white border-gray-300 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-500"
                type="number"
              />
            </div>
          ) : null}
          {/* ICO */}
          <button
            className="bg-sky-100 w-auto h-auto rounded-2xl p-2 hover:bg-sky-200 disabled:opacity-30 select-none"
            disabled={
              errorMessage.email !== "" ||
              errorMessage.password !== "" ||
              user.email === "" ||
              user.password === "" ||
              user.chckPass === "" ||
              ((user.company === "" || user.phone === "") && reg.broker) ||
              ((user.role === "" || user.phone === "" || user.ico === "") && reg.company)
              // user.role === ""
            }
            onClick={() => sendRegistration()}
          >
            Zaregistrovať sa
          </button>
        </div>
        <div className="flex flex-row gap-4">
          <p>Už máte účet?</p>
          <a href="/prihlasenie" className="underline underline-offset-1">
            Prihláste sa tu
          </a>
        </div>
        <div className="flex">
          <span className="text-red-500 text-lg">
            {regMessage?.message?.includes("prebehla") ? null : regMessage?.message}
          </span>
        </div>
      </div>
    </>
  );
}
