import { useState } from "react";
import Tarchy from "./Tarchy";
import Owner from "./Owner";
import PropertyInfo from "./PropertyInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";

export default function PropertyList({ propList, setShowLV, showLV, lvNumber }) {
  library.add(faArrowLeft);
  library.add(faChevronDown);
  library.add(faChevronUp);

  const [propertyInfo, setPropertyInfo] = useState(true);
  const [ownerInfo, setOwnerInfo] = useState(true);
  const [tarchy, setTarchy] = useState(true);

  function sizePropertyInfo() {
    setPropertyInfo(!propertyInfo);
  }

  function sizeOwnerInfo() {
    setOwnerInfo(!ownerInfo);
  }

  function sizeTarchy() {
    setTarchy(!tarchy);
  }

  function getLV() {
    setShowLV(!showLV);
  }

  function getList() {
    // console.log(
    //   "PropertyList",
    //   JSON.stringify(propList),
    //   " ||||||||||||| ",
    //   "ResponseArray = ",
    //   JSON.stringify(responseArray),
    // );
    if (propList.List instanceof Array) {
      return propList?.List[lvNumber];
    } else {
      return propList?.List;
    }
  }

  return (
    <>
      <div className="overflow-scroll">
        <button
          className={`absolute right-3 top-20 border-2 p-1 rounded-lg bg-sky-100 hover:bg-sky-200 z-40`}
          title="Naspäť"
          onClick={getLV}
        >
          <FontAwesomeIcon icon="arrow-left" /> Naspäť
        </button>
        <div className="flex justify-start items-center text-2xl p-0 sm:pl-20 h-14 bg-gradient-to-r from-lime-400  via-lime-300 to-lime-600 shadow-2xl">
          <h1>Výpis z listu vlastníctva</h1>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="bg-white min-h-screen w-full sm:w-3/4 p-3 shadow-2xl overflow-scroll">
            <div className="flex flex-col sm:flex-row justify-between mb-2">
              <div className="flex flex-col p-3 w-full items-center">
                <p>Okres</p>
                <p className="font-bold">{getList()?.nazov_okresu}</p>
              </div>
              <div className="flex flex-col p-3 w-full items-center">
                <p>Obec</p>
                <p className="font-bold">{getList()?.nazov_obce}</p>
              </div>
              <div className="flex flex-col p-3 w-full items-center">
                <p>Katastrálne územie</p>
                <p className="font-bold">{getList()?.kod_katastralneho_uzemia}</p>
              </div>
              <div className="flex flex-col p-3 w-full items-center">
                <p>Údaje platné k</p>
                <p className="font-bold">{getList()?.stav_ku_dnu}</p>
              </div>
            </div>
            <hr className="border border-salte-400 " />
            <div className="flex justify-center text-center py-8">
              <h1 className="text-3xl font-medium uppercase">
                Náhľad listu vlastníctva č. {getList()?.cislo_listu_vlastnictva}
              </h1>
            </div>
            <div className="">
              <div className="p-2 flex flex-row gap-x-2 font-bold">
                <p className="text-lg uppercase text-blue-800">Časť a: majetková podstata</p>
                <button onClick={sizePropertyInfo} className={`flex items-start z-10 w-8 p-1 text-black`}>
                  {propertyInfo ? (
                    <FontAwesomeIcon className="h-6" icon="chevron-up" />
                  ) : (
                    <FontAwesomeIcon className="h-6" icon="chevron-down" />
                  )}{" "}
                </button>
              </div>
            </div>
            <AnimatePresence>
              {propertyInfo ? (
                <motion.div
                  className="flex flex-col overflow-hidden px-2"
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  transition={{ duration: 0.5 }}
                  exit={{ height: 0 }}
                >
                  <PropertyInfo propList={propList} getList={getList} />
                </motion.div>
              ) : null}
            </AnimatePresence>
            <div className="">
              <div className="p-2 flex flex-row gap-x-2 font-bold">
                <p className="text-lg uppercase text-blue-800">
                  Časť B: Vlastníci a iné oprávnené osoby z práva k nehnuteľnosti
                </p>
                <button onClick={sizeOwnerInfo} className={`flex items-start z-10 w-8 p-1 text-black`}>
                  {ownerInfo ? (
                    <FontAwesomeIcon className="h-6" icon="chevron-up" />
                  ) : (
                    <FontAwesomeIcon className="h-6" icon="chevron-down" />
                  )}{" "}
                </button>
              </div>
            </div>
            <AnimatePresence>
              {ownerInfo ? (
                <motion.div
                  className="flex flex-col overflow-hidden px-2"
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  transition={{ duration: 0.5 }}
                  exit={{ height: 0 }}
                >
                  <Owner propList={propList} getList={getList} />
                </motion.div>
              ) : null}
            </AnimatePresence>
            <div className="">
              <div className="p-2 flex flex-row gap-x-2 font-bold">
                <p className="text-lg uppercase text-blue-800">Časť C: Ťarchy</p>
                <button onClick={sizeTarchy} className={`flex items-start z-10 w-8 p-1 text-black`}>
                  {tarchy ? (
                    <FontAwesomeIcon className="h-6" icon="chevron-up" />
                  ) : (
                    <FontAwesomeIcon className="h-6" icon="chevron-down" />
                  )}{" "}
                </button>
              </div>
            </div>
            <AnimatePresence>
              {tarchy ? (
                <motion.div
                  className="flex flex-col overflow-hidden px-2"
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  transition={{ duration: 0.5 }}
                  exit={{ height: 0 }}
                >
                  <Tarchy propList={propList} getList={getList} />
                </motion.div>
              ) : null}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </>
  );
}
