import { motion } from "framer-motion";
import PropertyEssenceFS from "../../ForSale/LvParts/PropertyEssenceFS";
import FsOwner from "../../ForSale/LvParts/FsOwner";
import FsTarcha from "../../ForSale/LvParts/FsTarcha";

export default function RLv({ subMenu, previous, lvSubMenu, setLvSubMenu, propList }) {
  return subMenu?.disp ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-auto flex flex-col items-start overflow-x-scroll text-xs"
      initial={{ x: previous !== "lv" ? -500 : 500 }}
      animate={{ x: previous === "" ? null : 0 }}
      transition={{ duration: previous === "" ? null : 0.5 }}
    >
      <>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col p-3 w-full items-center">
            <p>Okres</p>
            <p className="font-bold">{propList?.nazov_okresu}</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <p>Obec</p>
            <p className="font-bold">{propList?.nazov_obce}</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <p>Katastrálne územie</p>
            <p className="font-bold">{propList?.nazov_obce}</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <p>Údaje platné k</p>
            <p className="font-bold">{propList?.stav_ku_dnu}</p>
          </div>
        </div>
        <div className="flex justify-center text-center py-8">
          <h1 className="text-xl font-medium uppercase">
            Náhľad listu vlastníctva č. {propList?.cislo_listu_vlastnictva}
          </h1>
        </div>
        <PropertyEssenceFS setLvSubMenu={setLvSubMenu} lvSubMenu={lvSubMenu} lv={propList} />
        <FsOwner setLvSubMenu={setLvSubMenu} lvSubMenu={lvSubMenu} lv={propList} />
        <FsTarcha setLvSubMenu={setLvSubMenu} lvSubMenu={lvSubMenu} lv={propList} />
      </>
    </motion.div>
  ) : null;
}
